import BaseInput from 'app/components/forms/base_input/component'
import {
  firstElementOrEmptyString,
  withValidation,
} from 'app/components/forms/util'
import * as validations from 'app/shared_components/validations'

const validateNumber = (value) =>
  firstElementOrEmptyString(validations.validateNumber(value, 'Invalid number'))

const InputNumber = withValidation(BaseInput, validateNumber)

export default InputNumber
