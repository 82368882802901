import React from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'
import { variant } from 'styled-system'

import greenPlus from 'app/assets/icons/green-plus.png'
import redPlus from 'app/assets/icons/red-plus.png'
import yellowPlus from 'app/assets/icons/yellow-plus.svg'
import {
  Box,
  Button,
  ButtonWithLoading,
  Flex,
  LegacyButton,
  TextSpan,
} from 'app/components/design-system-components'
import SvgEditRounded from 'app/components/design-system-components/icons/image/EditRounded'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import checkMarkIconUrl from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingRegistrantCard/icons/check-mark.svg'
import crossMarkIconUrl from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingRegistrantCard/icons/cross-brown.svg'
import emailIconUrl from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingRegistrantCard/icons/email.svg'
import mobileIconUrl from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingRegistrantCard/icons/mobile.svg'
import ShortlistAttendee from 'app/sm/inspections/components/shortlist_attendee'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  loadingStatesIds,
} from 'app/utils/loading-states'
import { formatPhoneNumber } from 'app/utils/phonenumber/helpers'
import * as vaccinationHelpers from 'app/utils/vaccination/helpers'
import {
  SelfCheckInTypeQR,
  SelfCheckInTypeUndefined,
  StatusAttendeeCancelled,
  StatusAttendeeConfirmed,
  StatusAttendeeUndefined,
} from 'app/utils/viewings/helpers'

import 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingRegistrantCard/style.scss'

const RegistrantNameAndStatusFlex = styled(Flex)`
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space[1] + 'px'};
`

const RegistrantName = styled.div`
  color: ${({ theme }) => theme.colors.gray700};
  font-weight: ${({ theme }) => theme.fontWeights[6]};
  font-size: ${({ theme }) => theme.fontSizes[6] + 'px'};
`

const RegistrantPhone = styled(Box)`
  color: ${({ theme }) => theme.colors.gray500};
  font-weight: ${({ theme }) => theme.fontWeights[5]};
`

const ContactAnchor = styled.a`
  & + & {
    margin-left: 10.45px;
  }
`

const ConfirmationStatusIconCircle = styled(Flex)`
  height: 16px;
  width: 16px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray100};

  margin-right: ${({ theme }) => theme.space[3] + 'px'};

  ${variant({
    variants: {
      success: {
        backgroundColor: 'success200',
      },
    },
  })}
`

const ContactIcon = styled.img`
  width: 23px;

  &:hover {
    cursor: pointer;
  }
`

const StyledSlotInfo = styled.div`
  font-size: 12px;
  color: #666;
  line-height: 130%;
`

const statusesConf = {
  confirmed: {
    text: 'Conf.',
    textColor: 'success700',
    iconUrl: checkMarkIconUrl,
    iconVariant: 'success',
  },
  cancelled: {
    text: 'Cancelled',
    textColor: 'warning700',
    iconUrl: crossMarkIconUrl,
  },
  QRCheckIn: {
    text: 'QR',
    textColor: 'success700',
    iconUrl: checkMarkIconUrl,
    iconVariant: 'success',
  },
  applied: {
    text: 'Applied',
    textColor: 'success700',
    iconUrl: checkMarkIconUrl,
    iconVariant: 'success',
  },
}

function ConfirmationStatusIndicator({ conf }) {
  const { text, textColor, iconUrl, iconVariant } = conf
  return (
    <Flex alignItems="center" ml="1rem" fontSize={10}>
      <ConfirmationStatusIconCircle variant={iconVariant && iconVariant}>
        <img alt="" src={iconUrl} />
      </ConfirmationStatusIconCircle>
      <TextSpan color={textColor} fontSize="14px">
        {text}
      </TextSpan>
    </Flex>
  )
}

export default class ViewingRegistrantCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checkInLoadingStates: createInitialLoadingState(),
      interestLoadingStates: createInitialLoadingState(),
    }

    this.checkInLoadingUtils = createLoadingStateUtils((state) =>
      this.setState({
        checkInLoadingStates: state,
      }),
    )

    this.interestLoadingUtils = createLoadingStateUtils((state) =>
      this.setState({
        interestLoadingStates: state,
      }),
    )
  }

  onCheckInClicked(registrant) {
    const { handleCheckInBtn } = this.props
    this.checkInLoadingUtils.startLoading()
    return handleCheckInBtn(registrant)
      .then(() => this.checkInLoadingUtils.markDoneSuccessfully())
      .catch((err) => this.checkInLoadingUtils.setError(err))
  }

  onInterestClicked(registrant) {
    const { handleInterestedBtn } = this.props
    this.interestLoadingUtils.startLoading()
    return handleInterestedBtn(registrant)
      .then(() => this.interestLoadingUtils.markDoneSuccessfully())
      .catch((err) => this.interestLoadingUtils.setError(err))
  }

  renderConfirmationStatusPortion() {
    const { confirmationStatus } = this.props.registrant
    if (confirmationStatus === StatusAttendeeUndefined) {
      return null
    }

    if (confirmationStatus === StatusAttendeeConfirmed) {
      return <ConfirmationStatusIndicator conf={statusesConf.confirmed} />
    }

    if (confirmationStatus === StatusAttendeeCancelled) {
      return <ConfirmationStatusIndicator conf={statusesConf.cancelled} />
    }
  }

  renderAppliedIndicator() {
    const { appliedAt } = this.props.registrant
    return (
      <>
        {!!appliedAt && (
          <ConfirmationStatusIndicator conf={statusesConf.applied} />
        )}
      </>
    )
  }

  renderEnquirerActions() {
    const { registrant, checkInEnabled } = this.props
    const { checkInLoadingStates } = this.state

    return (
      <Flex mt="15px">
        <Box mr="5px" width="100%" />
        <ButtonWithLoading
          buttonCmp={LegacyButton}
          variant="solid"
          onClick={() => this.onCheckInClicked(registrant)}
          loading={checkInLoadingStates.state === loadingStatesIds.LOADING}
          disabled={!checkInEnabled}
          disableWhenLoading
        >
          Check In
        </ButtonWithLoading>
      </Flex>
    )
  }

  renderNotesButton(hasNote, isNotesButtonDisabled, handleNoteBtn, registrant) {
    const buttonVariant = hasNote ? 'outline' : 'outlineGreen'
    const buttonLabel = (
      <div>
        {!hasNote && (
          <div>
            <SvgEditRounded />
          </div>
        )}
        Notes
      </div>
    )
    return (
      <Button
        flex="1 1 25%"
        pr="0"
        pl="0"
        mr="5px"
        variant={buttonVariant}
        onClick={() => handleNoteBtn(registrant)}
        disabled={isNotesButtonDisabled}
      >
        {buttonLabel}
      </Button>
    )
  }

  renderRegistrantActions() {
    const {
      registrant,
      handleNoteBtn,
      checkInEnabled,
      interestedEnabled,
      noteEnabled,
    } = this.props
    const {
      checkIn: checkedIn,
      interested,
      __pendingChange,
      externalNote = '',
      comment = '',
      appliedAt,
    } = registrant
    const { checkInLoadingStates, interestLoadingStates } = this.state
    const hasNote =
      (externalNote && externalNote.length > 0) ||
      (comment && comment.length > 0)
    const isNotesButtonDisabled = __pendingChange || !noteEnabled
    const checkedInLabel = checkedIn && !appliedAt ? 'Invited' : 'Checked In'
    const checkInButtonLabel = checkedIn ? checkedInLabel : 'Check In'

    return (
      <Flex mt="15px">
        <ButtonWithLoading
          buttonCmp={LegacyButton}
          flex="1 1 25%"
          pr="0"
          pl="0"
          variant={interested ? 'outline' : 'outlineWarning'}
          mr="5px"
          onClick={() => this.onInterestClicked(registrant)}
          loading={interestLoadingStates.state === loadingStatesIds.LOADING}
          disabled={__pendingChange || !interestedEnabled}
          disableWhenLoading
        >
          {interested ? (
            <div>
              <div>?</div>
              Interest
            </div>
          ) : (
            'No Interest'
          )}
        </ButtonWithLoading>
        {this.renderNotesButton(
          hasNote,
          isNotesButtonDisabled,
          handleNoteBtn,
          registrant,
        )}
        <ButtonWithLoading
          buttonCmp={LegacyButton}
          flex="2 0.5 50%"
          variant={checkedIn ? 'solid' : ''}
          onClick={() => this.onCheckInClicked(registrant)}
          loading={checkInLoadingStates.state === loadingStatesIds.LOADING}
          disabled={__pendingChange || !checkInEnabled}
          disableWhenLoading
        >
          {checkInButtonLabel}
        </ButtonWithLoading>
      </Flex>
    )
  }

  renderSelfCheckInStatusPortion() {
    const { selfCheckinType } = this.props.registrant
    if (selfCheckinType === SelfCheckInTypeUndefined) {
      return null
    }

    if (selfCheckinType === SelfCheckInTypeQR) {
      return <ConfirmationStatusIndicator conf={statusesConf.QRCheckIn} />
    }
  }

  render() {
    const {
      registrant,
      isEnquirer,
      handleNoteBtn,
      noteEnabled,
      isViewingSlotEnabled,
      agencyGUID,
      fetchRegistrantsEnquirers,
      handleShortlist,
      viewingGUID,
    } = this.props
    const {
      fullName,
      email,
      mobilePhone,
      phone,
      slot,
      slotTime,
      shortlisted,
      guidID,
    } = registrant
    const phoneNumber = formatPhoneNumber(mobilePhone || phone)

    const confirmationStatusPortion =
      !isEnquirer && this.renderConfirmationStatusPortion()
    const selfCheckInStatusPortion =
      !isEnquirer && this.renderSelfCheckInStatusPortion()

    const actions = !isEnquirer
      ? this.renderRegistrantActions()
      : this.renderEnquirerActions()
    let slotInfo = ''
    if (isViewingSlotEnabled && slot !== 0) {
      slotInfo = (
        <StyledSlotInfo>
          Slot {slot} : {moment(slotTime).format(dateTimeHelpers.TIME_AM_PM)}
        </StyledSlotInfo>
      )
    }

    const renderVaccinationResult = () => {
      if (
        !registrant.covid19VaccinationStatus?.length ||
        ![
          vaccinationHelpers.FULLY_VACCINATED_STATUS,
          vaccinationHelpers.NOT_VACCINATED_STATUS,
          vaccinationHelpers.PREFER_NOT_TO_ANSWER_VACCINATED_STATUS,
        ].includes(registrant.covid19VaccinationStatus)
      ) {
        return
      }

      const config = {
        [vaccinationHelpers.FULLY_VACCINATED_STATUS]: {
          img: greenPlus,
          text: 'Self declared, fully vaccinated',
        },
        [vaccinationHelpers.NOT_VACCINATED_STATUS]: {
          img: redPlus,
          text: 'Self declared, not vaccinated',
        },
        [vaccinationHelpers.PREFER_NOT_TO_ANSWER_VACCINATED_STATUS]: {
          img: yellowPlus,
          text: 'Self declared, preferred not to answer',
        },
      }[registrant.covid19VaccinationStatus]

      return (
        <div className={'display-inline-block'}>
          <span className="font-weight600 font-size-14 display-block-mobile">
            <img
              data-tooltip-content={config.text}
              src={config.img}
              alt="vaccination status"
            />
            <ReactTooltip />
          </span>
        </div>
      )
    }

    const vaccinationResultElement = renderVaccinationResult()

    return (
      <Box pt="15px" pb="10px">
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <RegistrantNameAndStatusFlex>
              <RegistrantName
                onClick={() => noteEnabled && handleNoteBtn(registrant)}
              >
                <div className="name-container">
                  <span className="mr10">{fullName}</span>
                  {vaccinationResultElement}
                </div>
              </RegistrantName>
              {confirmationStatusPortion}
              {this.renderAppliedIndicator()}
              {selfCheckInStatusPortion}
            </RegistrantNameAndStatusFlex>

            <Flex alignItems="center">
              <RegistrantPhone mr={theme.space[4] + 'px'}>
                {phoneNumber}
              </RegistrantPhone>

              {slotInfo}
            </Flex>
          </Box>

          <Flex alignItems="center">
            <ShortlistAttendee
              shortlisted={shortlisted}
              attendeeGUID={guidID}
              agencyGUID={agencyGUID}
              showLabel={false}
              fetchRegistrantInfo={fetchRegistrantsEnquirers}
              handleShortlist={handleShortlist}
              viewingGUID={viewingGUID}
            />
            {phoneNumber && (
              <ContactAnchor href={'tel:' + phoneNumber}>
                <ContactIcon src={mobileIconUrl} />
              </ContactAnchor>
            )}
            {email && (
              <ContactAnchor href={'mailto:' + email}>
                <ContactIcon src={emailIconUrl} />
              </ContactAnchor>
            )}
          </Flex>
        </Flex>

        {actions}
      </Box>
    )
  }
}

ViewingRegistrantCard.propTypes = {
  registrant: PropTypes.object.isRequired,
  checkInEnabled: PropTypes.bool,
  handleCheckInBtn: PropTypes.func.isRequired,
  interestedEnabled: PropTypes.bool,
  handleInterestedBtn: PropTypes.func,
  noteEnabled: PropTypes.bool,
  handleNoteBtn: PropTypes.func,
  isEnquirer: PropTypes.bool,
}
