import React from 'react'

import moment from 'moment'

import { Button, Flex } from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import {
  COMPARE_AND_CONNECT,
  FLETCHERS_CONCIERGE,
  HOME_NOW,
  HOMENOW_CONNECT,
  PRD_SMARTER_CONNECT,
  THE_WHOLE_HOUSE,
  UCSProviderConfig,
  YOUR_PORTER,
} from 'app/constants/ucs.constants'
import { generateApplicantsText } from 'app/features/teams/applications/utility-referral/utils'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { cancelUtilityReferral } from 'app/services/http/team-applications'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import {
  isApplicationAccepted,
  isApplicationOffered,
  urlTo,
} from 'app/sm/helpers'
import { getApplicationWorkflowName } from 'app/sm/properties/components/property_search_filter_util'
import { TIME_DAY_HALF_MONTH } from 'app/utils/datetime/helpers'
import { isNewWorkflowEnabled } from 'config/features'

export const isProviderAllowedToCancel = (providerId) => {
  switch (providerId) {
    case HOME_NOW:
    case YOUR_PORTER:
    case PRD_SMARTER_CONNECT:
    case COMPARE_AND_CONNECT:
    case THE_WHOLE_HOUSE:
    case HOMENOW_CONNECT:
    case FLETCHERS_CONCIERGE:
      return true
    default:
      return false
  }
}

export const CancelUtilityReferralModal = ({
  utilityLead,
  application,
  onCloseModal,
  currentTeam,
  propertyGUID,
}) => {
  const {
    guidID: applicationGUID,
    applicants,
    status,
    hasLeaseOffer,
  } = application
  const primaryApplicant =
    applicants && applicants.find((applicant) => applicant.isPrimary)
  const statusText = getApplicationWorkflowName(status)
  const { provider_name: providerId } = utilityLead
  const ProviderIcon = UCSProviderConfig[providerId]?.icon
  const referralTime = moment(utilityLead.created_at).format(
    TIME_DAY_HALF_MONTH,
  )
  const leadText = `${utilityLead?.firstName || ''} ${
    utilityLead?.lastName || ''
  } ${referralTime}`
  const applicationText = `${generateApplicantsText(
    applicants,
  )} (Status: ${statusText})`
  const applicationOffered =
    isNewWorkflowEnabled(currentTeam.slug) &&
    (isApplicationOffered(application) || isApplicationAccepted(application))
  let linkForApplication =
    applicationOffered && hasLeaseOffer
      ? urlTo('leasingOffer', {
          applicationId: applicationGUID,
          agencyId: currentTeam.guid,
        })
      : urlTo('renterApplication', {
          propertyId: propertyGUID,
          applicationId: applicationGUID,
          applicantId: primaryApplicant?.guidID,
        })

  const sendCancelRequest = () => {
    const { guid: leadId } = utilityLead
    return cancelUtilityReferral(leadId)
      .then(() => {
        onCloseModal(true)
      })
      .catch(({ message }) => {
        snugNotifier.error(message)
      })
  }

  return (
    <GenericModal
      onDismiss={() => onCloseModal(false)}
      title={
        <Flex>
          Cancel utility referral{' '}
          {!!ProviderIcon && (
            <Flex
              alignItems="center"
              maxWidth="100px"
              ml={theme.space[6] + 'px'}
            >
              <ProviderIcon height="38px" width="auto" />
            </Flex>
          )}
        </Flex>
      }
      modalFooter={
        <GenericModalFooter
          primaryBtnConfig={{
            title: 'Confirm',
            onClick: sendCancelRequest,
          }}
          secondaryBtnConfig={{
            title: 'Cancel',
            onClick: () => onCloseModal(false),
          }}
        />
      }
    >
      <p>
        Send cancellation request to {providerId} for referral issued by{' '}
        {leadText} for application {applicationText}{' '}
        {currentTeam.isActive && !!linkForApplication && (
          <Button
            variant="linkDeepBlue"
            onClick={() => {
              history.push(linkForApplication)
            }}
          >
            view application
          </Button>
        )}
      </p>
    </GenericModal>
  )
}
