import { connect } from 'react-redux'

import { fetchApplications } from 'app/sm/applications/applications_actions'
import { fetchProperty } from 'app/sm/property_dashboard/property_dashboard_actions'
import PropertyDashboardContainer from 'app/sm/property_dashboard/property_dashboard_container'
import { fetchPropertyApplications } from 'app/sm/renter_applications/renter_applications_actions'

const mapStateToProps = ({ propertyDashboard, apply, ManagerViewings }) => ({
  property: propertyDashboard.property.property,
  viewings: propertyDashboard.property.viewings,
  applicationsCount: propertyDashboard.property.applicationsCount,
  comingUpViewings: ManagerViewings.comingUpViewings,
  acceptedApplication: propertyDashboard.property.acceptedApplication,
  error: apply.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchProperty: (id) => dispatch(fetchProperty(id)),
  fetchApplications: () => dispatch(fetchApplications()),
  fetchPropertyApplications: (propertyId, dispatchedFromTab = 'Applications') =>
    dispatch(fetchPropertyApplications(propertyId, dispatchedFromTab)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyDashboardContainer)
