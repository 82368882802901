import React from 'react'

import PostViewingFeedbackForm from 'app/sm/inspections/post_viewing_feedback/post_viewing_feedback_form'
import PostViewingResults from 'app/sm/inspections/post_viewing_feedback/post_viewing_results'

class PostViewingFeedback extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      property: {
        address: {
          friendlyName: '',
          suburb: '',
          state: '',
        },
      },
      viewingId: '',
      feedbackId: '',
      error: '',
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { propertyId, viewingId, feedbackId, shortCode },
      },
    } = this.props
    this.props.fetchFeedbackQuestions()
    if (shortCode) {
      this.props
        .fetchPropertyAndFeedbackRequestFromShortCode(shortCode)
        .then(({ guidID, viewingGUID, friendlyName, suburb, state }) => {
          this.setState({
            ...this.state,
            property: {
              address: {
                friendlyName: friendlyName,
                suburb: suburb,
                state: state,
              },
            },
            viewingId: viewingGUID,
            feedbackId: guidID,
          })
          this.props.fetchFeedbackStatus(viewingGUID, guidID)
        })
        .catch((error) => {
          this.setState({ error })
        })
    }
    if (propertyId) {
      this.props.fetchProperty(propertyId)
    }
    if ((viewingId, feedbackId)) {
      this.props.fetchFeedbackStatus(viewingId, feedbackId)
    }
  }

  handleViewingFeedbackFormSubmit = (payload) => {
    const { sendFeedback } = this.props
    let {
      match: {
        params: { viewingId, feedbackId, shortCode },
      },
    } = this.props
    if (shortCode) {
      ;({ feedbackId, viewingId } = this.state)
    }
    sendFeedback(payload, viewingId, feedbackId)
  }

  render() {
    const {
      currentUser,
      error,
      feedbackQuestions,
      feedbackStatus,
      match: {
        params: { propertyId, shortCode },
      },
    } = this.props
    let property = {}
    if (propertyId) {
      ;({ property } = this.props)
    }
    if (shortCode) {
      ;({ property } = this.state)
    }

    const hasFeedback = (feedbackStatus || {}).status === 1
    const noFeedback = (feedbackStatus || {}).status === 0

    return (
      <div>
        {hasFeedback && <PostViewingResults currentUser={currentUser} />}
        {noFeedback && (
          <PostViewingFeedbackForm
            feedbackQuestions={feedbackQuestions}
            error={error}
            handleViewingFeedbackFormSubmit={
              this.handleViewingFeedbackFormSubmit
            }
            property={property}
          />
        )}
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    )
  }
}

export default PostViewingFeedback
