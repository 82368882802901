import styled from 'styled-components'
import type { SpaceProps } from 'styled-system'

import shield from 'app/assets/icons/safety-centre-shield.svg'
import { Alert } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  AUTO_DELETE_SENSITIVE,
  KEEP_SAFE_ONLINE,
  SNUG_SAFETY_CENTRE_URL,
} from 'app/utils/text/helpers'

const StyledSafetyLink = styled.a`
  color: ${theme.colors.gray500};
  text-decoration: underline !important;

  &:hover,
  :focus {
    cursor: pointer;
    outline: none;
  }
`

const KeepSafeOnline = (
  { ...props }: SpaceProps,
  showAutoDeleteSensitiveMessage = false,
) => {
  return (
    <Alert
      actionIconBtnConfig={undefined}
      variant="grayWithBg"
      showLeftIcon={false}
      my={3}
      {...props}
    >
      <>
        <img src={shield} alt="shield icon" />
        <Text
          my={3}
          ml={3}
          px={4}
          fontWeight={`${theme.fontWeights['h3']}`}
          color={theme.colors.gray500}
        >
          {KEEP_SAFE_ONLINE}
          {showAutoDeleteSensitiveMessage && (
            <span> {AUTO_DELETE_SENSITIVE}</span>
          )}{' '}
          Visit our{' '}
          <StyledSafetyLink href={SNUG_SAFETY_CENTRE_URL} target="_blank">
            Safety Center
          </StyledSafetyLink>
        </Text>
      </>
    </Alert>
  )
}

export default KeepSafeOnline
