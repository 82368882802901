import React from 'react'

const StatusBar = ({ type, progress }) => (
  <div>
    {type === 'renter' ? (
      <RenterStatusBar progress={progress} />
    ) : (
      <ManagerStatusBar progress={progress} />
    )}
  </div>
)

const ManagerStatusBar = ({ progress }) => (
  <div className="sm-breadcrumbs full-width">
    <ul className="tabs">
      <li
        className={
          progress == '1'
            ? 'current'
            : parseInt(progress) > 1
            ? 'completed'
            : ''
        }
      >
        Start
      </li>
      <li
        className={
          progress == '2'
            ? 'current'
            : parseInt(progress) > 2
            ? 'completed'
            : ''
        }
      >
        Rate
      </li>
      <li
        className={
          progress == '3'
            ? 'current'
            : parseInt(progress) > 3
            ? 'completed'
            : ''
        }
      >
        Join
      </li>
      <li
        className={
          progress == '4'
            ? 'current'
            : parseInt(progress) > 4
            ? 'completed'
            : ''
        }
      >
        Sign
      </li>
    </ul>
    <div className="bar">
      <div className={'status-progress-bar active-tab-' + progress} />
    </div>
  </div>
)

const RenterStatusBar = ({ progress }) => (
  <div className="sm-breadcrumbs full-width">
    <ul className="tabs">
      <li
        className={
          progress == '1'
            ? 'current'
            : parseInt(progress) > 1
            ? 'completed'
            : ''
        }
      >
        Start
      </li>
      <li
        className={
          progress == '2'
            ? 'current'
            : parseInt(progress) > 2
            ? 'completed'
            : ''
        }
      >
        Join
      </li>
      <li
        className={
          progress == '3'
            ? 'current'
            : parseInt(progress) > 3
            ? 'completed'
            : ''
        }
      >
        Sign
      </li>
    </ul>
    <div className="bar">
      <div className={'status-progress-bar active-tab-3-' + progress} />
    </div>
  </div>
)

export const ApplicationStatusBar = ({ progress }) => (
  <div className="sm-breadcrumbs full-width">
    <ul className="tabs">
      <li
        className={
          progress == '1'
            ? 'current'
            : parseInt(progress) > 1
            ? 'completed'
            : ''
        }
      >
        Quick Apply
      </li>
      <li
        className={
          progress == '2'
            ? 'current'
            : parseInt(progress) > 2
            ? 'completed'
            : ''
        }
      >
        Complete Application
      </li>
      <li
        className={
          progress == '3'
            ? 'current'
            : parseInt(progress) > 3
            ? 'completed'
            : ''
        }
      >
        Accept
      </li>
    </ul>
    <div className="bar">
      <div className={'status-progress-bar active-tab-3-' + progress} />
    </div>
  </div>
)

export default StatusBar
