import React from 'react'

import styled from 'styled-components'

import Button from 'app/components/buttons/default/component'
import { Button as NewButton } from 'app/components/design-system-components'
import { Box, Flex } from 'app/components/design-system-components'
import { mediaQueries } from 'app/components/design-system-components/styles/variables'
import ApplicationNoteAdd from 'app/components/display/application_note_add/component'
import NoteButtons from 'app/components/display/application_note_buttons/component'
import theme from 'app/match/applicationReportPDF/assets/theme'

import 'app/components/display/text/standard_text/standard-headings/style.scss'

const StyledHeader = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  ${mediaQueries.md} {
    flex-direction: column;
  }
`

const StyledSideSection = styled(Flex)`
  align-items: center;
  ${mediaQueries.md} {
    margin-top: ${theme.space[5]}px;
    flex-direction: row-reverse;
    justify-content: left;
  }
`
const StyledLinks = styled(Flex)`
  ${mediaQueries.md} {
    margin-left: ${theme.space[8]}px;
  }
`

const StyledIconAndHeader = styled(Flex)`
  width: 100%;
`

export const MainHeader = ({
  text = '',
  textClass = '',
  componentClass = '',
}) => {
  return (
    <div className={`standard-main-title ${componentClass}`}>
      <div className={textClass}>{text}</div>
    </div>
  )
}

export const SectionHeader = ({
  text = '',
  textClass = '',
  componentClass = '',
  hasSeperator = true,
  children,
}) => {
  return (
    <div className={componentClass}>
      {text && <h3 className={`heading ${textClass}`}>{text}</h3>}
      {hasSeperator && <div className="standard-section-seperator" />}
      {children}
    </div>
  )
}

export const ContainerHeader = ({
  icon = '',
  text = '',
  textClass = '',
  isEditable = true,
  onEditClick = () => {},
  editTitle = '',
  componentClass = '',
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  displayedApplicant = {},
  section,
  enableNotesButton,
  editBtnClass = '',
  toggleRequestInfoModal = undefined,
  isManagerAdded = false,
  toggleEditRenterApplication = undefined,
  targetGUID,
  iconHeaderCmp,
}) => {
  return (
    <div className={`container-header ${componentClass} position-relative`}>
      <StyledHeader>
        <StyledIconAndHeader>
          {icon && (
            <img
              className="standard-icon-spacing"
              src={icon}
              alt="Summary Icon"
            />
          )}
          {!icon && iconHeaderCmp}
          <span className={`standard-subheading ${textClass}`}>{text}</span>
        </StyledIconAndHeader>
        <StyledSideSection>
          <StyledLinks>
            {!isManagerAdded && toggleEditRenterApplication && (
              <NewButton
                mr={2}
                style={{ fontSize: theme.fontSizes.pSmall12 }}
                onClick={() => toggleEditRenterApplication()}
                variant="linkBlueUnderlined"
              >
                Edit
              </NewButton>
            )}
            {enableNotesButton && toggleRequestInfoModal && (
              <NewButton
                mr={2}
                style={{
                  fontSize: theme.fontSizes.pSmall12,
                  whiteSpace: 'nowrap',
                }}
                onClick={() => toggleRequestInfoModal(text)}
                variant="linkBlueUnderlined"
              >
                Request Info
              </NewButton>
            )}
          </StyledLinks>
          {enableNotesButton && (
            <NoteButtons
              onNoteIconClicked={onNoteIconClicked(section, targetGUID)}
              noteStatus={interpreteNoteResult[section]}
            />
          )}
          {isEditable && (
            <div className="edit-link-container">
              <Button
                size="small"
                buttonType="secondary"
                className={editBtnClass || ''}
                onClick={onEditClick}
                role="button"
                tabIndex="0"
              >
                {editTitle || 'Edit'}
              </Button>
            </div>
          )}
        </StyledSideSection>
        {enableNotesButton && (
          <ApplicationNoteAdd
            onNewNoteUpdated={onNewNoteUpdated}
            newNotesContent={newNotesContent}
            section={section}
            onNewNoteAdded={onNewNoteAdded}
            onNoteCrossClicked={onNoteCrossClicked}
            name={displayedApplicant.firstName}
            targetGUID={targetGUID}
          />
        )}
      </StyledHeader>
    </div>
  )
}

export const GreySubheading = ({
  text = '',
  textClass = '',
  componentClass = '',
  children,
}) => {
  return (
    <div className={`standard-grey-subheading ${componentClass}`}>
      <span className={`${textClass}`}>{text}</span>
      {children}
    </div>
  )
}
