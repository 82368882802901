import React from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Box, Button, Flex } from 'app/components/design-system-components'
import NoteComponentFlatStyle from 'app/components/display/application_note/component_flatstyle'
import Note from 'app/pages/teams/messages/components/Note'

const StyledNotes = styled(Box)`
  .tooltip {
    width: 300px;
    word-break: break-all;
    overflow-wrap: break-word;
  }
`

const noNotes = <Box>No notes</Box>
const NUM_NOTES_TO_SHOW_IN_CELL = 1

function prepareNote(note, index, isInModal, trimMessage) {
  const { content: text, author, updatedAt: timestamp } = note
  return (
    <Note
      key={index}
      text={text}
      author={author}
      timestamp={timestamp}
      isInModal={isInModal}
      trimMessage={trimMessage}
      trimmedCharactersToDisplay={45}
    />
  )
}

function recentNumDefaultNotes(notes) {
  const numNotes = notes['enquiryNotes']?.length
  let note = noNotes
  if (numNotes > 0) {
    note = notes['enquiryNotes']?.map((noteInList, index) => {
      if (index < NUM_NOTES_TO_SHOW_IN_CELL) {
        const gotTheNote = prepareNote(noteInList, index, false, true)
        return gotTheNote
      } else return null
    })
  }
  return note
}

export function allNotes(notes, teamSlug = '') {
  const hasNotes =
    notes['enquiryNotes']?.length > 0 || notes['notesHistory']?.length > 0

  let notesList = noNotes
  if (hasNotes) {
    const combinedNotes = [
      ...(notes['enquiryNotes'] || []),
      ...(notes['notesHistory'] || []),
    ]
      .sort((left, right) =>
        moment.utc(right.updatedAt).diff(moment.utc(left.updatedAt)),
      )
      .filter(
        (val, index, array) =>
          array.findIndex((val2) =>
            ['guid_id'].every((k) => val2[k] === val[k]),
          ) === index,
      )

    notesList = (
      <Box>
        {combinedNotes?.map((note, index) => {
          return (
            <NoteComponentFlatStyle
              key={index}
              note={note}
              noteStyle="activityItem"
              teamSlug={teamSlug}
            />
          )
        })}
      </Box>
    )
  }

  return notesList
}

function NotesCell({
  notes,
  toggleInfoModal,
  messageGUID = '',
  isRowHovered,
  addPrivateNote,
  teamSlug = '',
}) {
  const lastNote = recentNumDefaultNotes(notes)
  const notesList = allNotes(notes, teamSlug)
  const notesID = `notes${messageGUID}`
  const hasNotes = notes && notes.length > 0
  return (
    <StyledNotes>
      <ReactTooltip id={notesID} className="tooltip">
        <span>{notesList}</span>
      </ReactTooltip>
      <Box
        onClick={() => toggleInfoModal('Notes', notesList)}
        cursor="pointer"
        fontSize="14px"
        data-tooltip-content=""
        data-tooltip-id={notesID}
      >
        <span>{lastNote}</span>
      </Box>
      <Button
        variant="linkBlueWithoutWeight"
        onClick={() => addPrivateNote(notesList)}
        style={
          isRowHovered ? { visibility: 'visible' } : { visibility: 'hidden' }
        }
      >
        <span>{hasNotes ? 'view/add note' : 'add note'}</span>
      </Button>
      {/* )} */}
    </StyledNotes>
  )
}

export default NotesCell
