import { connect } from 'react-redux'

import { changeTeam } from 'app/session/session_actions'
import {
  acceptTermsConditionsChange,
  cardSelect,
  createAdvertisement,
  fetchActiveAd,
  fetchAd,
  fetchAdvertisement,
  fetchCards,
  fetchPortals,
  payWithExistingCard,
  ping,
  registerPayment,
  resetAdvertisement,
  updateAdvertisement,
} from 'app/sm/advertisements/advertisement_actions'
import AdvertisementForm from 'app/sm/advertisements/advertisement_components/advertisement_form/advertisement_form_container'
import { fetchProperty } from 'app/sm/ppp/property_actions'

const mapStateToProps = ({ Advertisements, session, Ppp }) => ({
  portals: Advertisements.portals,
  listingFee: Advertisements.listingFee,
  advertisement: Advertisements.advertisement,
  cards: Advertisements.cards,
  acceptTermsAndConditions: Advertisements.acceptTermsAndConditions,
  selectedCreditCard: Advertisements.selectedCreditCard,
  ad: Advertisements.ad,
  error: Advertisements.error,
  teams: session.teams,
  property: Ppp.property,
})

const mapDispatchToProps = (dispatch) => ({
  resetAdvertisement: () => dispatch(resetAdvertisement()),
  fetchPortals: () => fetchPortals(),
  fetchAdvertisement: (id) => fetchAdvertisement(id),
  fetchAd: (id) => fetchAd(id),
  createAdvertisement: () => (id, ad) => createAdvertisement(id, ad),
  updateAdvertisement: (path) => (id, ad) => updateAdvertisement(id, ad, path),
  registerPayment: (id, token) => registerPayment(id, token),
  fetchCards: () => dispatch(fetchCards()),
  cardSelect: (selected) => dispatch(cardSelect(selected)),
  acceptTermsConditionsChange: () => dispatch(acceptTermsConditionsChange()),
  payWithExistingCard: () => dispatch(payWithExistingCard()),
  fetchActiveAd: (offerId) => dispatch(fetchActiveAd(offerId)),
  ping: () => dispatch(ping()),
  changeTeam: (currentTeam) => dispatch(changeTeam(currentTeam)),
  fetchProperty: (id) => dispatch(fetchProperty(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementForm)
