import React from 'react'

import { times } from 'app/sm/helpers'

class PostViewingFeedbackForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      questions: [
        { id: 0, code: 'advertised', value: 0 },
        { id: 1, code: 'market_value', value: 0 },
        { id: 2, code: 'condition', value: 0 },
        { id: 3, code: 'interested', value: 0 },
      ],
      comments: '',
      clicked: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error && this.state.clicked) {
      this.setState({ clicked: false })
    }
  }

  submit = () => {
    this.setState({ clicked: true })
    const { handleViewingFeedbackFormSubmit } = this.props
    const { questions, comments } = this.state
    const codes = questions.map((q) => ({
      code: q.code,
      rating: String(q.value),
    }))
    const payload = codes.concat({ code: 'comments', rating: comments })

    handleViewingFeedbackFormSubmit(payload)
  }

  updateRating = (id, value) => {
    let newState = Object.assign({}, this.state)
    newState.questions[id].value = value

    this.setState({ ...newState })
  }

  updateText = (value) => {
    this.setState({ comments: value })
  }

  render() {
    const { feedbackQuestions = [], error, property } = this.props
    const { questions, comments, clicked } = this.state
    const filteredQuestions = feedbackQuestions.filter(
      (f) => f.code !== 'comments',
    )
    const filteredComment = feedbackQuestions.filter(
      (c) => c.code === 'comments',
    )
    const hasQuestions = (filteredQuestions || []).length > 0
    const { address } = property || {}
    const disabled = questions.some((q) => q.value === 0)

    return (
      <div>
        <h3 className="mb20">
          What did you think of{' '}
          {`${address.friendlyName}, ${address.suburb} ${address.state}`}?
        </h3>

        <p className="mb30">
          It'll take <b>less than 1 minute</b> to review. Your feedback will be
          anonymous.
        </p>

        {hasQuestions &&
          filteredQuestions.map((q, index) => (
            <div className="mob-mb" key={q.guidID}>
              <div>
                <h4 className="mb50">{q.description}</h4>
              </div>
              <table className="v-table mb50 position-relative">
                <tbody>
                  <tr>
                    <td className="v-txt1 text-right">
                      <span>{q.minLabel}</span>
                    </td>
                    <td className="v-flex">
                      <div className="v-radio">
                        {times(5).map((i) => {
                          return (
                            <span className="position-relative v-rate" key={i}>
                              {}
                              <span className="v-val">{i + 1}</span>
                              <input
                                type="radio"
                                value={q.value}
                                checked={questions[index].value === i + 1}
                                onChange={() => this.updateRating(index, i + 1)}
                              />
                            </span>
                          )
                        })}
                      </div>
                    </td>
                    <td className="v-txt2">
                      <span>{q.maxLabel}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}

        {filteredComment[0] && (
          <div
            className="rating-categories-box"
            key={filteredComment[0].guidID}
          >
            <div className="rating-desc">{filteredComment[0].description}</div>
            <div className="input-box">
              {
                <textarea
                  required
                  rows="2"
                  onChange={(e) => this.updateText(e.target.value)}
                  value={comments}
                />
              }
              <label>Add a comment</label>
            </div>
          </div>
        )}

        {error && <div className="alert alert-danger">{error}</div>}

        {hasQuestions && (
          <button
            className="mt10 mb10"
            disabled={disabled}
            onClick={this.submit}
          >
            Continue <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
          </button>
        )}

        <p className="mt30 mb0 fs13 lh13 gray-color">
          Snug respects the privacy of rental community members and will only
          share your feedback with your permission and in accordance with our
          Privacy Policy.
        </p>
      </div>
    )
  }
}

export default PostViewingFeedbackForm
