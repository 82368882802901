import type { FunctionComponent } from 'react'

import type { SpaceProps } from 'styled-system'

import { Alert } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as textHelpers from 'app/utils/text/helpers'

export const BallotAlertDocumentUpload: FunctionComponent<SpaceProps> = ({
  ...props
}) => {
  return (
    <Alert
      actionIconBtnConfig={undefined}
      variant="blueWithBg"
      showLeftIcon={true}
      leftIconVariant="info"
      my={3}
      fontSize={theme.fontSizes.pRegular14}
      {...props}
    >
      <Text my={0} color={theme.colors.deepBlue}>
        {textHelpers.VARC_BALLOT_DOCUMENT_UPLOAD_INFO_ALERT}
      </Text>
    </Alert>
  )
}
