import { Box } from 'app/components/design-system-components'
import { CheckboxesList } from 'app/components/design-system-components/list-filters/CheckboxesList'
import { DropdownBodySectionHeader } from 'app/components/design-system-components/list-filters/DropdownBodySectionHeader'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const enquirerActionsFilterFields = {
  action: 'action',
}

export const enquirerActionsFilterDataExtractor = (filterValues) => {
  const { [enquirerActionsFilterFields.action]: actionObj } = filterValues
  return {
    actions: Object.values(actionObj || {}).reduce(
      (accValues, { option }) =>
        option !== actionsKeys.isWaiting
          ? { ...accValues, [option]: true }
          : { ...accValues, [actionsKeys.isRegistered]: false },
      {},
    ),
  }
}

export const EnquirerActionsAppliedFilterRenderer = ({ filterValues }) => {
  const { [enquirerActionsFilterFields.action]: actionObj } = filterValues
  return Object.values(actionObj)
    .map((actionOption) => actionOption.optionMeta.displayText)
    .join(', ')
}

export const isEnquirerActionsApplied = (filterValues) => {
  return (
    filterValues &&
    filterValues[enquirerActionsFilterFields.action] &&
    Object.values(filterValues[enquirerActionsFilterFields.action]).length
  )
}

const actionsKeys = {
  isShortlisted: 'isShortlisted',
  isWaiting: 'isWaiting',
  isRegistered: 'isRegistered',
  isInspected: 'isInspected',
  isApplied: 'isApplied',
  isArchived: 'isArchived',
}

const actions = {
  isShortlisted: 'Shortlisted',
  isWaiting: 'Viewing Waitlist',
  isRegistered: 'Registered',
  isInspected: 'Inspected',
  isApplied: 'Applied',
  isArchived: 'Archived',
}

export const EnquirerActionsFilterBody = ({
  formBag: { values, setFieldValue },
}) => {
  const options = Object.entries(actions).map(([id, label]) => {
    return {
      option: id,
      optionMeta: {
        id,
        displayText: label,
      },
    }
  })

  const selectedValues = values[enquirerActionsFilterFields.action] || {}

  const onCheckboxClicked = (checked, option) => {
    if (checked) {
      const allFilters = {
        ...selectedValues,
        [option.optionMeta.id]: option,
      }

      const { isWaiting, isRegistered, isInspected, ...restFilters } =
        allFilters
      setFieldValue(enquirerActionsFilterFields.action, {
        ...(option.optionMeta.id !== actionsKeys.isWaiting &&
          isRegistered && { isRegistered }),
        ...(option.optionMeta.id !== actionsKeys.isWaiting &&
          isInspected && { isInspected }),
        ...(option.optionMeta.id !== actionsKeys.isRegistered &&
          option.optionMeta.id !== actionsKeys.isInspected &&
          isWaiting && { isWaiting }),
        ...restFilters,
      })
    } else {
      const { [option.optionMeta.id]: uncheckedOption, ...otherOptions } =
        selectedValues
      setFieldValue(enquirerActionsFilterFields.action, {
        ...otherOptions,
      })
    }
  }

  return (
    <Box minWidth="200px" maxHeight="300px" overflowY="auto">
      <DropdownBodySectionHeader
        header="Enquirer Status"
        containerProps={{ mb: theme.space[4] + 'px' }}
      />

      <CheckboxesList
        options={options}
        checkedOptions={selectedValues}
        onCheckBoxClicked={onCheckboxClicked}
      />
    </Box>
  )
}
