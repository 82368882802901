import React from 'react'
import 'app/shared_components/general_bottom_btn/style.scss'

const GeneralBottomBtns = ({
  onBackButtonClicked,
  onConfirmButtonClicked,
  confirmBtnText = '',
  disableConfirmBtn = false,
  btnDangerStyle = false,
  hideConfirmButton = false,
}) => {
  return (
    <div className="display-flex general-bottom-btn-container">
      {onBackButtonClicked && (
        <div className="back-button-container">
          <button
            onClick={onBackButtonClicked}
            className="btn btn-transparent btn-left xs-text-center wa display-flex"
          >
            <i className="icon-arrow-left left" />
            <span>Back</span>
          </button>
        </div>
      )}

      {!hideConfirmButton && (
        <div className="confirm-btn-container display-flex stack">
          <button
            disabled={disableConfirmBtn}
            onClick={onConfirmButtonClicked}
            className={
              btnDangerStyle
                ? 'btn float-right hollow-button-green'
                : 'btn float-right'
            }
          >
            <span>{confirmBtnText ? confirmBtnText : 'Save'}</span>
            {disableConfirmBtn && (
              <i
                className={disableConfirmBtn ? 'fa fa-spinner fa-pulse' : ''}
              />
            )}
          </button>
        </div>
      )}
    </div>
  )
}

export default GeneralBottomBtns
