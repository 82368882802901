import { clearServerError } from 'app/bond_cover/agency/agency_actions'
import * as API from 'app/sm/remarketing/remarketing_access'

export function sendMessage(message, messageSent, failure) {
  return (dispatch) => {
    dispatch(clearServerError())
    return API.sendMessage(message).then(({ ok, statusCode, responseText }) => {
      if (ok) {
        messageSent(responseText)
        return Promise.resolve()
      } else {
        return responseText.then((error) => Promise.reject(error))
      }
    })
  }
}

export function fetchRecommendedProperties(
  agencyGUID,
  propGUID,
  limit,
  onFetchSuccess,
  onFetchFailure,
) {
  return (dispatch) => {
    dispatch(clearServerError())

    return API.getRecommendedProperties(
      agencyGUID,
      propGUID,
      limit,
      onFetchSuccess,
      onFetchFailure,
    ).then(({ ok, responseText, recommendedProps }) => {
      if (ok) {
        onFetchSuccess(recommendedProps)
      } else {
        onFetchFailure(responseText)
      }
    })
  }
}
