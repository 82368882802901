import React from 'react'

import { SNUG_PRIVACY_URL, SNUG_SAFETY_URL } from 'app/sm/helpers'

const bindCorrespondingCheck = (check, classes) =>
  (check === undefined && classes.neutral) ||
  (check === false && classes.fail) ||
  (check === true && classes.success)

const FAQBox = ({ children }) => <div className="faq-box">{children}</div>

const FAQPanel = ({ children, header }) => (
  <div className="panel">
    <p className="panel-header">{header}</p>
    <div className="collapse show">{children}</div>
  </div>
)

class FAQs extends React.Component {
  constructor(props) {
    super(props)
    const {
      faq1Init = false,
      faq2Init = false,
      faq3Init = false,
      faq4Init = false,
      faq5Init = false,
      faq6Init = false,
    } = this.props

    this.state = {
      faq1: faq1Init,
      faq2: faq2Init,
      faq3: faq3Init,
      faq4: faq4Init,
      faq5: faq5Init,
      faq6: faq6Init,
    }
  }

  backgroundCheck() {
    const { checks, whiteBackground = true } = this.props
    const classes = {
      success: 'background-check__status-success',
      fail: 'background-check__status-fail',
      neutral: 'background-check__status-neutral ',
    }

    return (
      <div className={`faq-box pt0 mt0 ${whiteBackground ? 'bg-white' : ''}`}>
        {checks.map(({ parole, message, check }, i) => (
          <div key={i} className="panel" onClick={this.update(`faq${i + 1}`)}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={this.state[`faq${i + 1}`] ? '' : 'collapsed'}
              role="button"
            >
              {parole}
              <span className={bindCorrespondingCheck(check, classes)} />
            </a>
            <div
              id={`faq${i + 1}`}
              className={
                this.state[`faq${i + 1}`] ? 'collapse show' : 'collapse'
              }
            >
              {message}
            </div>
          </div>
        ))}
      </div>
    )
  }

  employer() {
    return (
      <FAQBox>
        <FAQPanel header="Who is Snug?">
          Snug is a rental application and housing platform used by over 1.5
          million renters and 3000+ real estate agencies.
        </FAQPanel>

        <FAQPanel header="What happens when I confirm?">
          When you confirm the details, we'll update Justin’s employment details
          so that they continue their rental property application.
        </FAQPanel>

        <FAQPanel header="What happens if I can't confirm?">
          We give you the opportunity to let us know why you're unable to
          confirm if you click the link. If you can't confirm the details, they
          will remain unverified.
        </FAQPanel>

        <FAQPanel header="Need more information?">
          If you need more information you can visit{' '}
          <a href={'https://snug.com/'}>snug.com</a> or we can happily answer
          your queries if you visit our help centre:{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.snug.com"
          >
            snug.com/help
          </a>
          .
        </FAQPanel>

        <FAQPanel header="What is Snug’s privacy policy and approach with data security?">
          We take privacy and data security very seriously. These details are
          only shared with the Renter and included in applications that they
          choose to submit to property managers. Furthermore, profiles and
          applications are subject to auto-deletion. You can read our privacy
          policy at{' '}
          <a target="_blank" rel="noopener noreferrer" href={SNUG_PRIVACY_URL}>
            snug.com/privacy
          </a>{' '}
          and data security at{' '}
          <a target="_blank" rel="noopener noreferrer" href={SNUG_SAFETY_URL}>
            snug.com/safety
          </a>
          .
        </FAQPanel>
      </FAQBox>
    )
  }

  manager() {
    return (
      <div className="faq-box">
        <div className="panel">
          <p className="panel-header">Does this increase my risk?</p>
          <div className="collapse show">
            No, there is no change to your rights or obligations as an owner,
            property manager, or renter. <br />
            BondCover swaps the traditional cash bonds for a certificate of
            guarantee for valid bond claims up to the amount of the bond.
          </div>
        </div>

        <div className="panel">
          <p className="panel-header">
            How do I make a claim against the renter?
          </p>
          <div className="collapse show">
            <p>
              Owners or Managers can lodge bond claims online. We seek the
              Renter&rsquo;s agreement to the amount of the claim, but if they
              don&rsquo;t pay, Snug pays the Owner or Manager:
            </p>

            <ul>
              <li>Claims up to $500 within 2 business days, and</li>
              <li>
                Claims above $500 to the amount of the bond, within 5 business
                days
              </li>
            </ul>

            <p>
              In some cases, we may require further information and/or a
              Tribunal order to finalise the claim.
            </p>
          </div>
        </div>

        <div className="panel">
          <p className="panel-header">What information do I need to review?</p>
          <div className="collapse show">
            You just need to review and confirm the current lease and bond
            details provided by your Renter. Easy.
          </div>
        </div>

        <a
          className="manager-help-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://help.snug.com/hc/en-us/categories/115001642287-FAQs"
        >
          Help Centre
        </a>
      </div>
    )
  }

  renter() {
    let { faq1, faq2, faq3, faq4 } = this.state
    return (
      <div className="faq-box">
        <h3 className="mb30">Frequently Asked Questions</h3>
        {}
        <div className="panel" onClick={this.update('faq1')}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className={faq1 ? '' : 'collapsed'} role="button">
            What is BondCover?
          </a>
          <div id="faq1" className={faq1 ? 'collapse show' : 'collapse'}>
            BondCover swaps your traditional cash bond with a certificate of
            guarantee. It works by reviewing your address history and upon
            approval from your Property Manager/Owner allows you to unlock your
            current cash bond using a small fee as a substitute.
          </div>
        </div>
        <div className="panel" onClick={this.update('faq2')}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className={faq2 ? '' : 'collapsed'} role="button">
            What happens if a claim is made?
          </a>
          <div id="faq2" className={faq2 ? 'collapse show' : 'collapse'}>
            Should your Property Manager or Owner make a claim the usual process
            still applies. In true Snug spirit try to resolve it with them. If
            you can’t come to an agreement, refer to the Rental Tenancy
            Tribunal.
          </div>
        </div>
        <div className="panel" onClick={this.update('faq3')}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className={faq3 ? '' : 'collapsed'} role="button">
            What to happens if you can’t pay a claim?
          </a>
          <div id="faq3" className={faq3 ? 'collapse show' : 'collapse'}>
            The usual process applies, this means that should the Rental Tenancy
            Tribunal order you to pay a valid claim the onus is on you to pay
            it. If you don’t pay, Snug will pay the outstanding claim amount to
            your Property Manager/Owner. This however will impact your rental
            record. The claim amount will also still need to be paid and we’ll
            work with you to resolve the outstanding amount.
          </div>
        </div>
        <div className="panel" onClick={this.update('faq4')}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className={faq4 ? '' : 'collapsed'} role="button">
            How much does BondCover cost?
          </a>
          <div id="faq4" className={faq4 ? 'collapse show' : 'collapse'}>
            Snug BondCover is provided for a small fee, around 5-9% (including
            an admin fee) of your bond value.
          </div>
        </div>
      </div>
    )
  }

  update(field) {
    return (event) => {
      let value = !this.state[field]
      this.setState({ [field]: value })
    }
  }

  render() {
    let { type } = this.props
    return (
      <div>
        {type === 'renter' && this.renter()}
        {type === 'manager' && this.manager()}
        {type === 'backgroundCheck' && this.backgroundCheck()}
        {type === 'employer' && this.employer()}
      </div>
    )
  }
}

export default FAQs
