import * as React from 'react'

function SvgFlipToFrontRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3H9a2 2 0 00-2 2v10a2 2 0 002 2h10c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM3 9h2V7H3v2zm0 4h2v-2H3v2zm0 4h2v-2H3v2zm2 2v2a2 2 0 01-2-2h2zm12 2h-2v-2h2v2zm-7-6h8c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1h-8c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm1 6h2v-2h-2v2zm-2 0H7v-2h2v2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFlipToFrontRounded
