import styled from 'styled-components'
import { color, layout, space, typography } from 'styled-system'

const StyledParagraph = styled.p`
  ${color}
  ${typography}
  ${layout}
  ${space}
`

export const Text = ({ as = 'p', ...otherProps }) => {
  return <StyledParagraph as={as} {...otherProps} />
}
