import * as React from 'react'

function SvgApproved(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.002 2.01c-1.079.055-2.104.677-2.61 1.668l-.917 1.797-1.797.918A3.063 3.063 0 002.15 10.08L2.772 12l-.622 1.922a3.061 3.061 0 001.526 3.687l1.797.916.92 1.797c.675 1.322 2.277 1.988 3.685 1.53L12 21.229l1.92.62a3.064 3.064 0 003.687-1.527l.918-1.797 1.8-.918a3.063 3.063 0 001.525-3.687L21.228 12l.622-1.92a3.06 3.06 0 00-1.526-3.687l-1.797-.918-.92-1.797c-.673-1.321-2.278-1.985-3.685-1.528L12 2.771l-1.92-.619a2.988 2.988 0 00-1.078-.142zm.08 1.992c.126-.006.256.009.383.05l2.226.721c.201.065.419.065.62 0l2.228-.722a1.085 1.085 0 011.287.535l1.067 2.086c.096.187.246.34.433.435l2.088 1.067c.473.242.697.785.533 1.29l-.72 2.227a.995.995 0 000 .616l.1.308.62 1.92c.164.506-.059 1.05-.533 1.291l-2.088 1.067a.998.998 0 00-.433.433l-1.067 2.086a1.08 1.08 0 01-1.289.535l-2.228-.72a.986.986 0 00-.618 0l-2.228.722a1.088 1.088 0 01-1.29-.535L7.11 17.328a1.007 1.007 0 00-.435-.435l-2.086-1.067a1.074 1.074 0 01-.535-1.29l.72-2.227a.995.995 0 000-.616l-.72-2.228a1.072 1.072 0 01.535-1.291l2.086-1.067a.998.998 0 00.433-.433l1.067-2.086c.176-.347.53-.568.908-.586zm7.211 4.291L11 13.586l-2.293-2.293-1.414 1.414L11 16.414l6.707-6.707-1.414-1.414z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgApproved
