import React from 'react'

import { Link } from 'react-router-dom'

import headerImg01 from 'app/assets/icons/header-img-01.png'
import BackLink from 'app/shared_components/widgets/backLink'

class WithdrawConfirm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { title, context, backLink } = this.props
    return (
      <div>
        <div className="mobile-only">
          <BackLink link={backLink || '/home/dashboard'} />
        </div>
        <div className="ilustration-box">
          <img src={headerImg01} />
        </div>

        <h3 className="mb15">
          {title || 'This request has been withdrawn by the applicant.'}
        </h3>

        <p>
          {context ||
            'If you believe this BondCover request has been withdrawn in error or would like some more information, please contact us '}
          <a href="mailto:help@snug.com">help@snug.com</a>
        </p>

        <div className="row pt25 mobile-form-button">
          <BackLink link={backLink || '/home/dashboard'} />
          {/* <div className="col-sm-5 pb5 tablet-only float-right">
            
          </div> */}
        </div>
      </div>
    )
  }
}

export default WithdrawConfirm
