import * as React from 'react'

const SvgDeniedRounded = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={101}
    height={100}
    viewBox="0 0 101 100"
    fill="none"
    {...props}
  >
    <circle opacity={0.1} cx={50.5} cy={50} r={50} fill="#999999" />
    <circle
      opacity={0.1}
      cx={50.1603}
      cy={49.6603}
      r={44.6603}
      fill="#999999"
    />
    <circle cx={50.6963} cy={50.1962} r={38.1963} fill="#999999" />
    <path
      transform="translate(32,32)"
      d="M19.0002 2.98337C10.4488 2.98337 3.4835 9.94868 3.4835 18.5C3.4835 27.0514 10.4488 34.0167 19.0002 34.0167C27.5515 34.0167 34.5168 27.0514 34.5168 18.5C34.5168 9.94868 27.5515 2.98337 19.0002 2.98337ZM19.0002 6.26671C25.7747 6.26671 31.2335 11.7255 31.2335 18.5C31.2335 21.2928 30.2967 23.8543 28.7311 25.9096L11.5906 8.76907C13.6459 7.20348 16.2074 6.26671 19.0002 6.26671ZM6.76683 18.5C6.76683 15.7073 7.7036 13.1458 9.26919 11.0905L26.4097 28.231C24.3544 29.7966 21.7929 30.7334 19.0002 30.7334C12.2256 30.7334 6.76683 25.2746 6.76683 18.5Z"
      fill="white"
      stroke="white"
      strokeWidth={0.2}
    />
  </svg>
)

export default SvgDeniedRounded
