import React from 'react'

import moment from 'moment'

import { Alert } from 'app/components/design-system-components'
import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import { SearchInputBox } from 'app/dashboard/team_overview_container'
import EditConfirmationModal from 'app/pages/teams/viewings-run/components/edit-confirmation-modal'
import FilterOptions from 'app/pages/teams/viewings-run/components/filter-options'
import PublicHeader from 'app/pages/teams/viewings-run/components/header'
import PreviewContainer from 'app/pages/teams/viewings-run/components/preview-container'
import ScheduleListContainer, {
  MODE_DRIVING_NEW_VERSION,
  MODE_WALKING_NEW_VERSION,
  modeDriving,
  modeWalking,
} from 'app/pages/teams/viewings-run/components/schedule-list'
import HorizontalTabs from 'app/pages/teams/viewings-run/components/tabs'
import { getAgencyMembers } from 'app/services/http/agency'
import * as snugNotifier from 'app/services/snugNotifier'
import { changeTeam } from 'app/session/session_actions'
import isEmptyObject from 'app/shared_components/check_empty_object'
import GeneralBottomBtns from 'app/shared_components/general_bottom_btn/component'
import TwoColumnContainer, {
  LeftComponent,
} from 'app/shared_components/layout_component/two_column_layout_components'
import NotFound404 from 'app/shared_components/not_found_404/not_found_404'
import { history } from 'app/shared_components/router'
import Spinner from 'app/sm/common/spinner'
import { findChosenTeamBySlug, routes, urlIds, urlTo } from 'app/sm/helpers'
import {
  DropDownForManager,
  DropDownForViewingManager,
  PropertyStatusesFilter,
  PropertyStatusesFilterOptions,
} from 'app/sm/properties/components/property_search_filter_util'
import { RawAvailableDurations } from 'app/sm/team_viewing_preference/container'
import Loadmore from 'app/sm/viewings_new_run/load_more/component'
import PropertyItem, {
  PropertyItemListContainer,
} from 'app/sm/viewings_new_run/property_item/component'
import * as timeHelpers from 'app/utils/datetime/helpers'
import { isEmpty } from 'app/utils/objects/helpers'

export const PropertySearchContainer = ({ children }) => (
  <div className="properties-search-filter mb0">{children}</div>
)

const TIMER_TO_WAIT_FOR_EDIT_MS = 3000

const NO_MANAGER_SELECTED = 'At least one manager should be selected'
const ALERT_FOR_EDITING_MODE = `You're editing a Draft Run. Changes will require the run to be re-calculated.`

const afterOneHour = moment().add(1, 'hour')
const momentMinuteAfterOneHour = afterOneHour.minute()
const minute15After1Hour = 15 * Math.ceil(momentMinuteAfterOneHour / 15)
const setTime = moment(afterOneHour).set('minutes', minute15After1Hour)

const DEFAULT_MANAGER_PREFERENCES = {
  startTime: setTime,
  travelMode: modeDriving,
  scheduleBreaks: false,
  breakDuration: 5,
  viewingDuration: 15,
  startPropertyGUID: '',
  endPropertyGUID: '',
}

// sort selectedPropertyInfos given start, end guid
const sortSelectedPropertyInfos = (
  startPropertyGUID,
  endPropertyGUID,
  selectedPropertyInfos,
) => {
  return selectedPropertyInfos
    .filter((propertyInfo) => {
      return propertyInfo.property.guidID === startPropertyGUID
    })
    .concat(
      selectedPropertyInfos.filter((propertyInfo) => {
        return (
          propertyInfo.property.guidID !== startPropertyGUID &&
          propertyInfo.property.guidID !== endPropertyGUID
        )
      }),
    )
    .concat(
      selectedPropertyInfos.filter((propertyInfo) => {
        return propertyInfo.property.guidID === endPropertyGUID
      }),
    )
}

// choose the nearest one in future
export const selectSameDayOrFutureViewingInfos = (
  existingViewingInfos,
  startTime,
) => {
  if (!existingViewingInfos || existingViewingInfos.length === 0) {
    return []
  }

  const sortedExistingViewingInfos = existingViewingInfos.map((v) => v)
  sortedExistingViewingInfos.sort((v1, v2) =>
    v1.viewing.startDate.localeCompare(v2.viewing.startDate),
  )
  const latestFutureViewingInfos = sortedExistingViewingInfos.filter(
    (viewingInfo) =>
      moment(viewingInfo.viewing.startDate).isAfter(startTime) ||
      moment(viewingInfo.viewing.startDate).isSame(startTime, 'day'),
  )
  return latestFutureViewingInfos
}

// TODO: there's a bug in not detecting conflicts when scheduling exactly same viewing run
// find the conflicted viewing if any
export const findConflictedViewing = (
  existingViewingInfos,
  newViewingStartTime,
  newViewingDuration,
  newBreakDuration,
) => {
  if (
    !existingViewingInfos ||
    existingViewingInfos.length === 0 ||
    !newViewingStartTime
  ) {
    return []
  }

  const newViewingEndTime = newViewingStartTime
    .clone()
    .add(newViewingDuration + newBreakDuration, 'm')
  const conflicted = existingViewingInfos.filter((existingViewingInfo) => {
    const existingViewingStartTime = moment(
      existingViewingInfo.viewing.startDate,
    )
    const existingViewingEndTime = existingViewingStartTime
      .clone()
      .add(existingViewingInfo.viewing.duration, 'm')
    if (
      existingViewingStartTime.isBetween(
        newViewingStartTime,
        newViewingEndTime,
        null,
        '[]',
      ) ||
      newViewingStartTime.isBetween(
        existingViewingStartTime,
        existingViewingEndTime,
        null,
        '[]',
      )
    ) {
      return true
    }
    return false
  })
  return conflicted
}

class ViewingNewRun extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: { path },
    } = props

    const isEditMode = path === routes.viewingRuns.edit

    this.state = {
      isEditMode,
      managerList: [],
      propertyManagers: [],
      showAllTab: false,
      manager: '',
      searchText: '',
      propertyData: {
        data: [],
      },
      propertyStatus: PropertyStatusesFilterOptions[0],
      propertyViewingInfos: [],
      loadMoreSpinner: false,
      tableSpinner: false,
      showDropdownContent: false,
      managerInputValue: '',
      activeManagerTabGUID: '',
      managerPreferences: {},
      selectedPropertyInfos: [],
      startTime: moment().hour(9).minute(0).second(0),
      rawRoutes: [],
      waypoints: [],
      filteredManagerList: [],
      routedProperties: [],
      viewingRunEnabledForTeam: false,
      teamSettingFetchDone: false,
      showViewingSettingsNotice: false,
      viewingSettingsNoticeContent: '',
      showEditConfirmationModal: false,
      changesToEditMade: false,
      delayedAfterEdit: false,
      excludedOffMarket: false,
    }
  }

  componentDidMount() {
    const { allManagersSelected = [] } = this.props
    const { guid } = this.getChosenTeam()
    guid && this.fetchIfViewingRunIsEnabledForTeam()
    guid && this.loadManagerList(guid)
    guid && this.callFetchTeamProperties(guid, {})
    if (allManagersSelected && allManagersSelected.length > 0) {
      this.setState({
        activeManagerTabGUID: this.props.allManagersSelected[0].managerGUID,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { guid } = this.getChosenTeam()
    if (
      prevProps.match.params.teamSlug !== this.props.match.params.teamSlug ||
      prevProps.teams !== this.props.teams
    ) {
      guid && this.loadManagerList(guid)
      guid && this.callFetchTeamProperties(guid, {})
      guid && this.fetchIfViewingRunIsEnabledForTeam()
    }

    this.handleUpdateForEditMode(prevProps, prevState)

    if (
      Object.keys(prevProps.managerPreferences).length === 0 &&
      this.props.allManagersSelected.length > 0
    ) {
      if (
        this.props.allManagersSelected &&
        this.props.allManagersSelected.length > 0
      ) {
        this.setState({
          activeManagerTabGUID: this.props.allManagersSelected[0].managerGUID,
        })
      }
    }
  }

  handleUpdateForEditMode(prevProps, prevState) {
    const { isEditMode } = this.state
    if (!isEditMode) return

    const { selectedTeam } = this.props
    if (isEmpty(selectedTeam)) return

    const { managerList } = this.state
    if (!managerList.length) return

    const { managerList: prevManagerList } = prevState
    const { selectedTeam: prevSelectedTeam } = prevProps

    const isSelectedTeamChanged =
      selectedTeam.guid !== (prevSelectedTeam || {}).guid
    const isManagerListChanged = managerList !== prevManagerList

    if (isSelectedTeamChanged || isManagerListChanged) {
      this.fetchAndPopulateDataForEditMode()
    }
  }

  fetchAndPopulateDataForEditMode() {
    const { fetchNewViewingRunVersion, selectedTeam, match } = this.props
    const teamId = selectedTeam.guid
    const runId = match.params.viewingRunGUID
    return fetchNewViewingRunVersion(teamId, runId)
      .then(({ viewingRun }) => this.populateDataForEditMode(viewingRun))
      .catch((err) => {
        snugNotifier.error(err)
      })
  }

  populateDataForEditMode(runData) {
    const { populateViewingRunData } = this.props
    const { managerList } = this.state

    const allSelectedProperties = runData.groups
      .reduce((accProperties, { properties }) => {
        return [...accProperties, ...properties]
      }, [])
      .map((property) => ({ property }))

    const managersData = runData.original_request.inspectors.ids.map(
      (inspectorId) => {
        const inspector = managerList.find(
          ({ guidID }) => guidID === inspectorId,
        )
        const inspectorPreferences =
          runData.original_request.inspectors_preferences.find(
            ({ user_id }) => user_id === inspectorId,
          )
        const {
          start_time: startTime,
          travel_mode: travelMode,
          stopover_buffer_minutes: breakDuration,
          stopover_duration_minutes: viewingDuration,
          start_property_id: startPropertyGUID,
          end_property_id: endPropertyGUID,
          properties,
          isReadOnly,
          viewings_published: viewingsPublished,
        } = inspectorPreferences

        return {
          managerDetails: {
            ...inspector.profile,
            managerGUID: inspector.guidID,
          },
          preferences: {
            ...inspectorPreferences,
            startTime: moment(startTime, 'hh:mm'),
            travelMode:
              travelMode === MODE_DRIVING_NEW_VERSION
                ? modeDriving
                : modeWalking,
            scheduleBreaks: Boolean(breakDuration),
            breakDuration,
            viewingDuration,
            startPropertyGUID,
            endPropertyGUID,
            properties,
            isReadOnly,
            viewingsPublished,
          },
        }
      },
    )
    // this timeout is added only for edit mode
    // required because the dropdowns on the page are
    // calling onPreferenceOptionChanged which sets
    // changesToEditMade to true. If this is true,
    // the saved viewing run is discarded and the EditConfirmationModal
    // displayed even when no changes are made
    setTimeout(() => {
      this.setState({
        delayedAfterEdit: true,
      })
    }, TIMER_TO_WAIT_FOR_EDIT_MS)

    let viewingDate = this.props.match.params.viewingRunGUID
      ? runData?.original_request?.date
      : runData?.date

    populateViewingRunData({
      startTime: moment(viewingDate),
      selectedProperties: allSelectedProperties,
      managers: managersData,
    })
  }

  checkForEditModeAndSaveSettings = () => {
    const { isEditMode } = this.state
    if (isEditMode) {
      this.setState({
        changesToEditMade: true,
      })
    }
  }

  onAddButtonClicked = (propertyInfo) => {
    this.checkForEditModeAndSaveSettings()
    const { addSelectedPropertyInfos } = this.props
    const newSelectedPropertyInfos =
      this.props.selectedPropertyInfos.concat(propertyInfo)
    // todo: remove selectedPropertyInfos totally from component state as we are using redux state
    this.setState({
      selectedPropertyInfos: newSelectedPropertyInfos,
    })
    addSelectedPropertyInfos(newSelectedPropertyInfos)
  }

  onChangeManagerInputValue = (value) => {
    const { managerList } = this.state
    this.setState({
      managerInputValue: value,
      showDropdownContent: true,
    })
    const filteredManagers = managerList.filter((manager) =>
      [
        manager.profile.email,
        manager.profile.firstName,
        manager.profile.lastName,
        manager.profile.mobilePhone,
        manager.profile.phone,
        manager.profile.homePhone,
        manager.profile.workPhone,
      ].some((field) =>
        `${field || ''}`.toLowerCase().includes(value.toLowerCase()),
      ),
    )
    // this.setState({
    //   allManagersSelected: filteredManagers,
    // })

    this.setState({
      // managerInputValue: value,
      // showDropdownContent: true,
      filteredManagerList: filteredManagers,
    })
    if (!(value.length > 0)) {
      this.setState({
        showDropdownContent: false,
      })
    }
  }

  onClickManagerDropdown = (manager) => {
    const { addNewManagerToRun } = this.props
    addNewManagerToRun(manager)
    this.checkForEditModeAndSaveSettings()
    this.setState(
      {
        allManagersSelected: this.props.allManagersSelected.concat([manager]),
        showDropdownContent: false,
        managerInputValue: '',
        managerPreferences: {
          ...this.state.managerPreferences,
          [manager.managerGUID]: {
            ...DEFAULT_MANAGER_PREFERENCES,
          },
        },
      },
      () => {
        this.setState({
          activeManagerTabGUID: this.props.allManagersSelected[0].managerGUID,
        })
      },
    )
  }

  onClickTabInHorizontalTab = (managerGUID) => {
    this.setState({
      activeManagerTabGUID: managerGUID,
    })
  }

  onDateChanged = (value) => {
    const { updateStartTimeInViewingRun } = this.props
    this.checkForEditModeAndSaveSettings()
    const newStartTime = this.state.startTime.clone()
    newStartTime.year(value.year()).month(value.month()).date(value.date())
    this.setState({
      startTime: newStartTime,
    })
    updateStartTimeInViewingRun(newStartTime)
  }

  onDeleteButtonClicked = (index) => {
    const newSelectedPropertyInfos = [...this.props.selectedPropertyInfos]
    newSelectedPropertyInfos.splice(index, 1)
    this.setState({
      selectedPropertyInfos: newSelectedPropertyInfos,
    })
    const { removeSelectedPropertyInfos } = this.props
    removeSelectedPropertyInfos(newSelectedPropertyInfos)
  }

  onLoadMoreClicked = (offset) => {
    const { chosenFilterManger: manager = '', searchText = '' } = this.state
    const { guid } = this.getChosenTeam()
    const loadMore = true
    const status = ''
    guid &&
      this.callFetchTeamProperties(guid, {
        searchText,
        manager,
        status,
        offset,
        loadMore,
      })
  }

  onManagerFilterChange = (event) => {
    this.setState(
      {
        manager: event.target.value,
      },
      () => {
        const { searchText = '', manager = '' } = this.state
        const { guid } = this.getChosenTeam()
        if (!guid) {
          return
        }

        manager === ''
          ? this.setState({ showAllTab: false })
          : this.setState({ showAllTab: true })
        this.callFetchTeamProperties(guid, { searchText, manager })
      },
    )
  }

  onPropertyStatusFilterChange = (event) => {
    const value = event.target.value
    this.setState(
      {
        propertyStatus: value,
        excludedOffMarket: value === PropertyStatusesFilterOptions[1],
      },
      () => {
        const { searchText, manager, excludedOffMarket } = this.state
        const { guid } = this.getChosenTeam()
        if (!guid) {
          return
        }
        this.callFetchTeamProperties(guid, {
          searchText,
          manager,
          excludedOffMarket,
        })
      },
    )
  }

  onPreferenceOptionChanged = (managerGUID, field, text) => {
    const { isEditMode, delayedAfterEdit } = this.state
    if (isEditMode && delayedAfterEdit) {
      this.setState({
        changesToEditMade: true,
      })
    }
    const { updateManagerPreference } = this.props
    const { managerPreferences } = this.props
    if (field === 'startTime') {
      return (value) => {
        const newValue = moment(value, 'hh:mm A')
        if (newValue.isValid()) {
          this.setState({
            managerPreferences: {
              ...managerPreferences,
              [managerGUID]: {
                ...managerPreferences[managerGUID],
                startTime: newValue,
              },
            },
          })
          updateManagerPreference(managerGUID, field, newValue)
        }
      }
    } else if (field === 'travelMode') {
      this.setState({
        managerPreferences: {
          ...managerPreferences,
          [managerGUID]: {
            ...managerPreferences[managerGUID],
            travelMode: text,
          },
        },
      })
      updateManagerPreference(managerGUID, field, text)
    } else if (field === 'scheduleBreaks') {
      this.setState({
        managerPreferences: {
          ...managerPreferences,
          [managerGUID]: {
            ...managerPreferences[managerGUID],
            scheduleBreaks:
              !this.props.managerPreferences[managerGUID].scheduleBreaks,
          },
        },
      })
      updateManagerPreference(
        managerGUID,
        field,
        !this.props.managerPreferences[managerGUID].scheduleBreaks,
      )
      this.props.managerPreferences[managerGUID].scheduleBreaks
        ? updateManagerPreference(managerGUID, 'breakDuration', 0)
        : updateManagerPreference(managerGUID, 'breakDuration', 5)
    } else if (field === 'breakDuration') {
      return (event) => {
        this.setState({
          managerPreferences: {
            ...managerPreferences,
            [managerGUID]: {
              ...managerPreferences[managerGUID],
              breakDuration: Number(event.target.value),
            },
          },
        })
        updateManagerPreference(managerGUID, field, event.target.value)
      }
    } else if (field === 'viewingDuration') {
      // viewingDuration only receives the changed target value
      return (value) => {
        this.setState({
          managerPreferences: {
            ...managerPreferences,
            [managerGUID]: {
              ...managerPreferences[managerGUID],
              viewingDuration: Number(value),
            },
          },
        })
        updateManagerPreference(managerGUID, field, value)
      }
    } else if (field === 'startPropertyGUID') {
      return (value) => {
        const newSelectedPropertyInfos = sortSelectedPropertyInfos(
          value,
          '',
          this.state.selectedPropertyInfos,
        )
        this.setState({
          managerPreferences: {
            ...managerPreferences,
            [managerGUID]: {
              ...managerPreferences[managerGUID],
              startPropertyGUID: value,
            },
          },
          selectedPropertyInfos: newSelectedPropertyInfos,
        })
        updateManagerPreference(managerGUID, field, value)
      }
    } else if (field === 'endPropertyGUID') {
      return (value) => {
        const newSelectedPropertyInfos = sortSelectedPropertyInfos(
          '',
          value,
          this.state.selectedPropertyInfos,
        )
        this.setState({
          managerPreferences: {
            ...managerPreferences,
            [managerGUID]: {
              ...managerPreferences[managerGUID],
              endPropertyGUID: value,
            },
          },
          selectedPropertyInfos: newSelectedPropertyInfos,
        })
        updateManagerPreference(managerGUID, field, value)
      }
    }
  }

  previewSavedChanges = () => {
    const { allManagersSelected, managerPreferences, selectedPropertyInfos } =
      this.props
    const { isEditMode, changesToEditMade } = this.state
    if (!(allManagersSelected.length > 0)) {
      snugNotifier.error(NO_MANAGER_SELECTED)
      return
    }
    if (selectedPropertyInfos.length < 2 * allManagersSelected.length) {
      snugNotifier.error(
        'Please make sure enough properties are selected for all managers',
      )
      return
    }
    let allPropertyGUIDS = []
    let errorPresent = false
    const managerPreferencesValues = Object.values(managerPreferences)
    for (let managerPreference of managerPreferencesValues) {
      if (
        managerPreference.startPropertyGUID ===
          managerPreference.endPropertyGUID &&
        !!managerPreference.startPropertyGUID
      ) {
        snugNotifier.error(
          'Please make sure that the start and end properties are different',
        )
        errorPresent = true
        break
      }
      if (
        (allPropertyGUIDS.includes(managerPreference.startPropertyGUID) ||
          allPropertyGUIDS.includes(managerPreference.endPropertyGUID)) &&
        (managerPreference.startPropertyGUID !== '' ||
          managerPreference.endPropertyGUID !== '')
      ) {
        snugNotifier.error(
          'Please make sure that the start and end properties are distinct for all managers',
        )
        errorPresent = true
        break
      } else {
        allPropertyGUIDS = allPropertyGUIDS.concat(
          managerPreference.startPropertyGUID,
          managerPreference.startPropertyGUID,
        )
      }
    }
    if (errorPresent) {
      return
    }

    const { match } = this.props
    const teamSlug = match.params.teamSlug
    const url = isEditMode
      ? urlTo(urlIds.viewingRuns.editPreview, {
          teamSlug,
          id: match.params.viewingRunGUID,
          changesToEditMade,
        })
      : urlTo('newViewingsRunPreview', {
          teamSlug,
        })

    history.push(url)
  }

  onPreviewButtonClicked = () => {
    const { isEditMode, changesToEditMade } = this.state
    if (isEditMode && changesToEditMade) {
      this.setState({
        showEditConfirmationModal: true,
      })
      return
    }
    this.previewSavedChanges()
  }

  onSearchChange = (event) => {
    const { guid } = this.getChosenTeam()
    if (!guid) {
      return
    }

    const { value } = event.target
    clearInterval(this.propertySearch)

    this.setState({ searchText: value }, () => {
      const { searchText = '', manager = '' } = this.state
      this.propertySearch = setTimeout(
        () => this.callFetchTeamProperties(guid, { searchText, manager }),
        500,
      )
    })
  }

  // return team object (with property guid) or undefined if not found
  getChosenTeam = () => {
    const { teams, changeCurrentTeam, selectedTeam } = this.props
    const { teamSlug = '' } = this.props.match.params
    const detectedTeam = findChosenTeamBySlug(teams, teamSlug) || {}
    if (detectedTeam.guid && detectedTeam.guid !== (selectedTeam || {}).guid) {
      // we should always depend on the selected team of the global application state
      // after some changes and enhancements we can get rid of this function totally
      changeCurrentTeam(detectedTeam)
    }
    return detectedTeam || {}
  }

  addAllPropertiesToViewingRun = () => {
    const propertyList = this.state.propertyData.data
    this.setState({
      selectedPropertyInfos: propertyList,
    })
    const { addSelectedPropertyInfos } = this.props
    addSelectedPropertyInfos(propertyList)
  }

  callFetchTeamProperties = (
    teamID,
    {
      searchText,
      manager,
      status,
      offset,
      loadMore = false,
      excludedOffMarket = this.state.excludedOffMarket,
    },
  ) => {
    const { fetchTeamProperties } = this.props
    loadMore
      ? this.setState({ loadMoreSpinner: true })
      : this.setState({ tableSpinner: true })
    return fetchTeamProperties(teamID, {
      searchText,
      managerContact: manager,
      status,
      offset,
      loadMore,
      limit: 4,
      excludedOffMarket,
    })
      .then(({ properties }) => {
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })

        const previousProperties = loadMore ? this.state.propertyData.data : []
        const updatedProperties = previousProperties.concat(properties.data)
        this.setState({
          propertyData: {
            data: updatedProperties,
            response_metadata: properties.response_metadata,
          },
        })
        return Promise.resolve({ properties })
      })
      .catch((error) => {
        this.setState({ error })
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
        return Promise.reject(error)
      })
  }

  changeToMeFilter = () => {
    const { showAllTab, propertyManagers } = this.state
    const { guid } = this.getChosenTeam()
    if (!guid) {
      return
    }
    const currentManagerAgency =
      propertyManagers && propertyManagers.find((m) => m.isMe)
    const currentManagerAgencyGUID =
      currentManagerAgency && currentManagerAgency.contact.guidID
    const currentChoice =
      currentManagerAgencyGUID && !showAllTab ? currentManagerAgencyGUID : ''

    this.setState({ chosenFilterManger: currentChoice }, () => {
      const { chosenFilterManger: manager = '', searchText = '' } = this.state
      const status = ''
      this.callFetchTeamProperties(guid, { searchText, manager, status })
    })
    currentManagerAgencyGUID && showAllTab === false
      ? this.setState({ showAllTab: true })
      : this.setState({ showAllTab: false })
  }

  fetchIfViewingRunIsEnabledForTeam = () => {
    const { fetchTeamSettingInfo } = this.props
    const { guid } = this.getChosenTeam()
    if (guid) {
      fetchTeamSettingInfo(guid, 'viewings')
        .then(({ viewings }) => {
          if (!isEmptyObject(viewings)) {
            const {
              viewing_run_enabled: viewingRunEnabled = false,
              viewing_run_mode: viewingRunMode = 'single',
              viewing_cap_enabled,
              viewing_slot_enabled,
              viewing_cap,
              viewing_slot,
              default_selected_viewing_duration_enabled:
                defaultSelectedViewingDurationEnabled = 'false',
              default_selected_viewing_duration:
                defaultSelectedViewingDuration = 15,
            } = viewings

            this.setState({
              defaultSelectedViewingDurationEnabled:
                defaultSelectedViewingDurationEnabled === 'true',
              defaultSelectedViewingDuration: +defaultSelectedViewingDuration,
            })

            if (
              viewing_cap_enabled === 'true' ||
              viewing_slot_enabled === 'true'
            ) {
              let viewingSettingsNoticeContent = ''
              if (viewing_cap_enabled) {
                viewingSettingsNoticeContent = `Viewings Cap of ${viewing_cap} is enabled`
              }
              if (viewing_slot_enabled) {
                viewingSettingsNoticeContent = `${viewing_slot} minute Viewing Slots are enabled`
              }
              this.setState({
                showViewingSettingsNotice: true,
                viewingSettingsNoticeContent,
              })
            }
            this.setState({
              viewingRunEnabledForTeam: viewingRunEnabled === 'true',
              viewingRunModeForTeam: viewingRunMode,
            })
          }
        })
        .catch((error) => {
          this.setState({ error })
        })
        .finally(() =>
          this.setState({
            teamSettingFetchDone: true,
          }),
        )
    }
  }

  generateCrumbs = () => {
    const { teamSlug = '' } = this.props.match.params
    const curTeam =
      this.props.teams.find((team) => team.slug === teamSlug) || {}
    let crumbs = [
      {
        text: curTeam.name,
        link: urlTo('teamOverview', { teamSlug }),
      },
      {
        text: 'Viewings',
        link: `${urlTo('prospectSummary', { teamSlug })}?stage=Viewing`,
      },
      {
        text: 'Run',
        link: urlTo('newViewingsRunAll', { teamSlug }),
      },
      {
        text: 'Create',
        link: '#',
      },
    ]
    return crumbs
  }

  loadManagerList = (agencyGUID) => {
    if (!!agencyGUID) {
      this.props.fetchTeamManagerList(agencyGUID).then(({ managerList }) => {
        const managerNameList = managerList.map(
          (manager) =>
            manager.profile.firstName + ' ' + manager.profile.lastName,
        )
        this.setState({
          managerNameList: managerNameList,
          managerList: managerList,
          filteredManagerList: managerList,
        })
      })

      getAgencyMembers(agencyGUID).then((members) => {
        this.setState({
          propertyManagers: members,
        })
      })
    }
  }

  removeEmailFromList = (manager) => {
    // const { allManagersSelected } = this.state
    const { removeManagerFromRun, allManagersSelected } = this.props
    const deletedManagerGUID = allManagersSelected.filter(
      (_, index) => index === manager,
    )[0].managerGUID
    const filteredManagers = allManagersSelected.filter(
      (_, index) => index !== manager,
    )
    this.setState({
      allManagersSelected: filteredManagers,
    })
    removeManagerFromRun(deletedManagerGUID)
  }

  toggleEditConfirmationModal = () => {
    const { showEditConfirmationModal } = this.state
    this.setState({
      showEditConfirmationModal: !showEditConfirmationModal,
    })
  }

  undoChangesMadeToPage = () => {
    const { resetViewingRunState } = this.props
    resetViewingRunState()
    this.fetchAndPopulateDataForEditMode()
    this.setState({
      showEditConfirmationModal: false,
      changesToEditMade: false,
    })
  }

  render() {
    const {
      showAllTab,
      manager,
      searchText,
      propertyData = {},
      loadMoreSpinner,
      tableSpinner,
      schedulePreference,
      showDropdownContent,
      managerInputValue,
      activeManagerTabGUID,
      initialPreviewCallDone,
      rawRoutes,
      waypoints,
      gMapRoute,
      filteredManagerList,
      routedProperties,
      viewingRunModeForTeam,
      viewingRunEnabledForTeam,
      teamSettingFetchDone,
      showViewingSettingsNotice,
      viewingSettingsNoticeContent,
      isEditMode,
      showEditConfirmationModal,
      defaultSelectedViewingDurationEnabled,
      defaultSelectedViewingDuration,
      delayedAfterEdit,
      propertyManagers,
    } = this.state
    const { allManagersSelected, managerPreferences } = this.props
    const managerNameList =
      filteredManagerList &&
      filteredManagerList.map((manager) => {
        return { ...manager.profile, managerGUID: manager.guidID }
      })

    const { data: propertyList = [], response_metadata = {} } = propertyData
    const { total } = response_metadata
    const offset = propertyList.length

    const tabHeaders = allManagersSelected.map((manager) => {
      return {
        label: `${manager.firstName} ${manager.lastName}`,
        tabGUID: manager.managerGUID,
      }
    })

    return teamSettingFetchDone ? (
      viewingRunEnabledForTeam ? (
        <TwoColumnContainer>
          <LeftComponent>
            <Breadcrumbs crumbs={this.generateCrumbs()} />
            <PublicHeader
              title="Prepare run"
              text="Select the properties, configure your options and preview."
            />
            {isEditMode && (
              <Alert variant="warningWithBg">{ALERT_FOR_EDITING_MODE}</Alert>
            )}
            <FilterOptions
              onDateChanged={this.onDateChanged}
              date={this.props.startTime}
              managerList={managerNameList}
              showAvatarAndNameComponent={showDropdownContent}
              managerInputValue={managerInputValue}
              allManagersSelected={allManagersSelected}
              onClickManagerDropdown={this.onClickManagerDropdown}
              onChangeManagerInputValue={this.onChangeManagerInputValue}
              removeEmailFromList={this.removeEmailFromList}
              viewingRunModeForTeam={viewingRunModeForTeam}
            />
            <div className="mt50">
              <div className="sub-title">2. Search and select properties</div>
              <PropertySearchContainer>
                <SearchInputBox
                  searchText={searchText}
                  onSearchChange={this.onSearchChange}
                />
                {propertyManagers && (
                  <DropDownForViewingManager
                    label="Manager:"
                    options={propertyManagers}
                    value={manager}
                    onChange={this.onManagerFilterChange}
                    changeToMeFilter={this.changeToMeFilter}
                    showAllTab={showAllTab}
                  />
                )}
                <PropertyStatusesFilter
                  value={this.state.propertyStatus}
                  onChange={this.onPropertyStatusFilterChange}
                />
                <div
                  className="blue-link-style ml10"
                  onClick={this.addAllPropertiesToViewingRun}
                >
                  Add all
                </div>
              </PropertySearchContainer>
            </div>
            <PropertyItemListContainer>
              {tableSpinner ? (
                <Spinner />
              ) : (
                <div>
                  {propertyList.map((propertyInfo, index) => {
                    return (
                      <PropertyItem
                        key={index}
                        propertyInfo={propertyInfo}
                        onAddButtonClicked={this.onAddButtonClicked}
                        status="select" // properties to be selected
                        addAgain={this.props.selectedPropertyInfos.find(
                          (selectedPropertyInfo) =>
                            selectedPropertyInfo.property.guidID ===
                            propertyInfo.property.guidID,
                        )}
                        enableAddAgain={false}
                        viewingInfos={
                          this.state.propertyViewingInfos[
                            propertyInfo.property.guidID
                          ]
                        }
                        showStats
                      />
                    )
                  })}
                  {offset < total && (
                    <div className={`text-center mt20 ml20`}>
                      {loadMoreSpinner ? (
                        <Spinner />
                      ) : (
                        <Loadmore
                          offset={offset}
                          onLoadMoreClicked={this.onLoadMoreClicked}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}

              {this.props.selectedPropertyInfos.length !== 0 && (
                <div className="selected-section mt20">
                  <p>Selected</p>
                  <PropertyItemListContainer>
                    {this.props.selectedPropertyInfos.map(
                      (propertyInfo, index) => {
                        return (
                          <PropertyItem
                            propertyInfo={propertyInfo}
                            key={index}
                            onDeleteButtonClicked={() =>
                              this.onDeleteButtonClicked(index)
                            }
                            status="selected" // properties already selected
                            sameDayOrfutureViewingInfos={selectSameDayOrFutureViewingInfos(
                              this.state.propertyViewingInfos[
                                propertyInfo.property.guidID
                              ],
                              this.props.viewingRun.startTime,
                            )}
                            conflicted={findConflictedViewing(
                              this.state.propertyViewingInfos[
                                propertyInfo.property.guidID
                              ],
                              this.props.viewingRun.startTime,
                              this.props.viewingRun.viewingDuration,
                              this.props.viewingRun.scheduleBreaks
                                ? this.props.viewingRun.breakDuration
                                : 0,
                            )}
                            showStats
                          />
                        )
                      },
                    )}
                  </PropertyItemListContainer>
                </div>
              )}
            </PropertyItemListContainer>
            <div className="new-run-schedule-header mb10">
              <div className="sub-title mb10 mt25">3. Set preferences</div>
              {showViewingSettingsNotice && (
                <Alert variant="blueWithBg">
                  {viewingSettingsNoticeContent}
                </Alert>
              )}
            </div>
            {allManagersSelected && allManagersSelected.length === 0 && (
              <p className="mt10 mb20">
                Please add at least one Inspector in Step 1
              </p>
            )}
            {allManagersSelected && allManagersSelected.length > 0 && (
              <HorizontalTabs
                tabHeaders={tabHeaders}
                activeTabGUID={activeManagerTabGUID}
                onClickTabInHorizontalTab={this.onClickTabInHorizontalTab}
              />
            )}

            {allManagersSelected &&
              Object.keys(managerPreferences).length > 0 &&
              allManagersSelected.length > 0 &&
              allManagersSelected.map((manager, index) => {
                let selectedViewingDuration =
                  managerPreferences[manager.managerGUID].viewingDuration
                if (
                  defaultSelectedViewingDurationEnabled &&
                  RawAvailableDurations.indexOf(
                    defaultSelectedViewingDuration,
                  ) !== -1
                ) {
                  selectedViewingDuration = defaultSelectedViewingDuration
                }

                return manager.managerGUID === activeManagerTabGUID ? (
                  <ScheduleListContainer
                    key={manager.managerGUID}
                    managerGUID={manager.managerGUID}
                    schedulePreference={schedulePreference}
                    startTime={
                      managerPreferences[manager.managerGUID].startTime
                    }
                    travelMode={
                      managerPreferences[manager.managerGUID].travelMode
                    }
                    scheduleBreaks={
                      managerPreferences[manager.managerGUID].scheduleBreaks
                    }
                    breakDuration={
                      managerPreferences[manager.managerGUID].breakDuration
                    }
                    viewingDuration={
                      managerPreferences[manager.managerGUID].viewingDuration
                    }
                    startPropertyGUID={
                      managerPreferences[manager.managerGUID].startPropertyGUID
                    }
                    endPropertyGUID={
                      managerPreferences[manager.managerGUID].endPropertyGUID
                    }
                    viewingPropertyInfos={this.props.selectedPropertyInfos}
                    onPreferenceOptionChanged={this.onPreferenceOptionChanged}
                    managersSelected={allManagersSelected}
                    defaultSelectedViewingDurationEnabled={
                      defaultSelectedViewingDurationEnabled
                    }
                    defaultSelectedViewingDuration={
                      defaultSelectedViewingDuration
                    }
                  />
                ) : null
              })}
            {isEditMode && (
              <Alert variant="warningWithBg">{ALERT_FOR_EDITING_MODE}</Alert>
            )}

            <div className="mt30" />
            <GeneralBottomBtns
              onBackButtonClicked={() => history.goBack()}
              onConfirmButtonClicked={() => this.onPreviewButtonClicked()}
              confirmBtnText="Preview"
              disableConfirmBtn={isEditMode && !delayedAfterEdit}
            />
            {showEditConfirmationModal && (
              <EditConfirmationModal
                toggleEditConfirmationModal={this.toggleEditConfirmationModal}
                undoChangesMadeToPage={this.undoChangesMadeToPage}
                onConfirmChangesButtonClicked={this.previewSavedChanges}
              />
            )}
          </LeftComponent>
        </TwoColumnContainer>
      ) : (
        <NotFound404 />
      )
    ) : (
      <Spinner />
    )
  }
}

export default ViewingNewRun
