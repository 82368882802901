import React from 'react'

import conatctBlurred from 'app/assets/icons/contact-blurred.png'
import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import { history } from 'app/shared_components/router'
import SnugUpgradeAccount from 'app/shared_components/snug_account_upgrade'
import Spinner from 'app/sm/common/spinner'
import ContactItem from 'app/sm/contact_list/contact_item/component'
import Header from 'app/sm/contact_list/header/component'
import { urlTo } from 'app/sm/helpers'
import { findChosenTeam } from 'app/sm/team_contact/container'
import * as accountHelpers from 'app/utils/accounts/helpers'

const CONTACT_PAGE_SIZE = 5

const getContactType = (contactIndex) => {
  switch (contactIndex) {
    case 'sm_contact':
      return 'Manual contact'
    case 'sm_viewing_onsite_registrant':
      return 'On-site registration'
    case 'sm_viewing_registration':
      return 'Viewing pre-registration'
    case 'sm_message':
      return 'Enquired'
    default:
      return 'N/A'
  }
}

const ContactSearchContainer = ({ children }) => (
  <div className="properties-search-filter">{children}</div>
)

const SearchInputBox = ({ searchText, onSearchChange }) => (
  <div className="property-search-filter-widget pl0">
    <div className="input-box">
      <div className="prefix">
        <i className="icon-search"></i>
      </div>
      <input
        type="text"
        placeholder={'e.g. name, email, mobile'}
        value={searchText}
        onChange={onSearchChange}
      />
    </div>
  </div>
)

export const LoadMore = ({ onClickLoadMore }) => (
  <button
    className="textButton wa ha btnmr mt10 mb10"
    onClick={onClickLoadMore}
  >
    Load more
  </button>
)

class TeamContactListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      contactInfo: {
        contacts: [],
        total: 0,
      },
      searchText: '',
      loading: false,
    }
  }

  componentDidMount() {
    if (this.props.teams.length !== 0) {
      this.loadMore(0, CONTACT_PAGE_SIZE)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.teams.length !== this.props.teams.length) {
      this.loadMore(0, CONTACT_PAGE_SIZE)
    }
  }

  onClickSearch = () => {
    this.loadMore(0, CONTACT_PAGE_SIZE)
  }

  onSearchChange = (event) => {
    const { value } = event.target
    this.setState({ searchText: value })

    clearInterval(this.contactSearch)
    this.contactSearch = setTimeout(
      () => this.loadMore(0, CONTACT_PAGE_SIZE),
      500,
    )
  }

  hasMore = () => {
    return this.state.contactInfo.contacts.length < this.state.contactInfo.total
  }

  loadMore = (from, size) => {
    const { teams } = this.props
    const { teamSlug } = this.props.match.params
    const chosenTeam = findChosenTeam(teams, teamSlug)
    const { guid: teamGuid } = chosenTeam || {}
    this.setState({ loading: true })
    this.props
      .fetchSearchContacts(teamGuid, this.state.searchText.trim(), from, size)
      .then(({ contactInfo }) => {
        this.setState({
          contactInfo: {
            contacts:
              from === 0
                ? contactInfo.contacts
                : this.state.contactInfo.contacts.concat(contactInfo.contacts),
            total: contactInfo.total,
          },
        })
      })
      .catch((error) => {
        this.setState({ error })
      })
      .then(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    const {
      contactInfo: { contacts = [] },
      error,
    } = this.state

    const { teams } = this.props
    const { teamSlug } = this.props.match.params
    const chosenTeam = findChosenTeam(teams, teamSlug) || {}
    const { name: agencyName = '', slug = '', accountType = 0 } = chosenTeam
    let crumbs = []
    if (agencyName) {
      crumbs = [
        {
          text: agencyName || 'Team',
          link: urlTo('teamOverview', { teamSlug: slug }),
        },
        {
          text: 'Contacts',
          link: '#',
        },
      ]
    }
    return (
      <div>
        {accountType === accountHelpers.ACCOUNT_TYPE_LITE ? (
          <div>
            <img src={conatctBlurred} alt="blurred contacts" />
            <SnugUpgradeAccount
              typeOfMessage={accountHelpers.UPGRADE_TYPE_SETTINGS}
            />
          </div>
        ) : (
          <div className="two-col-box-flex-row sm64">
            <div className="col-first">
              <div className="mb20 pdf-hide">
                <Breadcrumbs crumbs={crumbs} />
              </div>
              <Header />
              <ContactSearchContainer>
                <SearchInputBox
                  searchText={this.state.searchText}
                  onSearchChange={this.onSearchChange}
                />
              </ContactSearchContainer>
              <div className="mb20">
                {(contacts || []).length === 0 && (
                  <div>No contacts, please add one.</div>
                )}
                {(contacts || []).map((contact, index) => {
                  const {
                    firstName,
                    lastName,
                    email,
                    phone,
                    updatedAt,
                    guidID,
                  } = contact
                  return (
                    <ContactItem
                      key={index}
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                      phone={phone}
                      updatedAt={updatedAt}
                      contactGUID={guidID}
                      teamSlug={teamSlug}
                      contactType={getContactType(contact.index)}
                    />
                  )
                })}
                {this.state.loading && <Spinner />}
                {this.hasMore() && (
                  <LoadMore
                    onClickLoadMore={() =>
                      this.loadMore(
                        this.state.contactInfo.contacts.length,
                        CONTACT_PAGE_SIZE,
                      )
                    }
                  />
                )}
              </div>
              {error && <div className="alert alert-danger"> {error} </div>}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default TeamContactListContainer
