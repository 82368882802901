import React from 'react'

import Button from 'app/components/buttons/default/component'
import { ModalContentWrapper } from 'app/modals/modal-styled'

const BasicModal = ({
  toggleModal,
  title,
  body,
  primaryButtonLabel,
  primaryButtonAction,
  secondaryButtonLabel,
  secondaryButtonAction,
  primaryButtonClassName = '',
  secondaryButtonClassName = '',
  buttonsContainerClass = '',
  children,
  primaryDisabled = false,
  hideButtonSection = false,
  secondaryButtonType = '',
  modalBodyClass = '',
  componentClassName = '',
  modalSubheaderClass = '',
  modalSubheadingClass = '',
  showModalCrossButton = true,
}) => (
  <div className="modal-fullscreen modal-layout-flex-container">
    <ModalContentWrapper className={`${componentClassName}`}>
      {showModalCrossButton && (
        <div className="modal-close">
          <i
            className="fa fa-times"
            role="button"
            tabIndex="0"
            onClick={toggleModal}
            onKeyPress={toggleModal}
          />
        </div>
      )}
      <div className={`modal-header ${modalSubheaderClass}`}>
        <h4
          className={`standard-subheading ${modalSubheadingClass}`}
          style={!showModalCrossButton ? { width: '100%' } : {}}
        >
          {title}
        </h4>
      </div>
      <div className={`modal-body ${modalBodyClass}`}>
        {body && <div className="mb20">{body}</div>}
        {children && <div className="mb20">{children}</div>}
        {!hideButtonSection && (
          <div
            className={`${buttonsContainerClass} display-flex modal-bottom-btn-container`}
          >
            <Button
              text={secondaryButtonLabel}
              onClick={secondaryButtonAction}
              size="large"
              componentClass={`width100 mt10 margin-profile-item-left ${secondaryButtonClassName}`}
              buttonType={secondaryButtonType || `secondary`}
            />
            <Button
              text={primaryButtonLabel}
              disabled={primaryDisabled}
              onClick={primaryButtonAction}
              size="large"
              componentClass={`width100 mt10 margin-profile-item-right ${primaryButtonClassName}`}
              isLoading={primaryDisabled}
            />
          </div>
        )}
      </div>
    </ModalContentWrapper>
  </div>
)

export default BasicModal
