import { LOCATION_CHANGE } from 'connected-react-router'

import {
  WITHDRAW_BUTTON_CLICKED,
  WITHDRAW_COMPLETE,
} from 'app/sm/withdraw_confirmation/withdraw_confirmation_actions'

const _defaultState = {
  withdrawButtonClicked: false,
}

const WithdrawConfirmation = (state = _defaultState, action) => {
  let { type, withdrawButtonClicked } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case WITHDRAW_BUTTON_CLICKED:
      return Object.assign(newState, { withdrawButtonClicked })
    case WITHDRAW_COMPLETE:
      return Object.assign(newState, { withdrawButtonClicked })
    case LOCATION_CHANGE:
      return _defaultState
    default:
      return newState
  }
}

export default WithdrawConfirmation
