import { NetworkConnectionError } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { sendLateViewingNotification } from 'app/sm/inspections/inspections_access'
import {
  getNewViewingRunInfo,
  getNewViewingsRun,
  getViewingRunDetail,
  listViewingRuns,
  postNewViewingRun,
  postNewViewingsRun,
  postNewViewingsRunManagerCheckOutKey,
  postViewingsRun,
} from 'app/sm/viewings_new_run/access'

export const RECEIVE_VIEWING_RUN = 'RECEIVE_VIEWING_RUN'
export const RESET_VIEWING_RUN = 'RESET_VIEWING_RUN'
const receiveViewingRun = (viewingRun) => ({
  type: RECEIVE_VIEWING_RUN,
  viewingRun,
})
const resetViewingRun = () => ({
  type: RESET_VIEWING_RUN,
})

export const createNewViewingRun = (viewingRunReqeust) => {
  return (dispatch) => {
    return postNewViewingRun(viewingRunReqeust).then(
      ({ ok, responseText, viewingRun }) => {
        if (ok) {
          return Promise.resolve({ viewingRun })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const generateViewingRun = (viewingRunRequest) => {
  return (dispatch) => {
    return Promise.resolve(dispatch(receiveViewingRun(viewingRunRequest)))
  }
}

export const generateResetViewingRun = () => {
  return (dispatch) => {
    return Promise.resolve(dispatch(resetViewingRun()))
  }
}

export const fetchViewingRuns = (
  agencyGUID,
  managerGUID,
  startTime,
  endTime,
  offset,
) => {
  return (dispatch) => {
    return listViewingRuns(
      agencyGUID,
      managerGUID,
      startTime,
      endTime,
      offset,
    ).then(({ ok, runSummaries, hasMore, responseText }) => {
      if (ok) {
        return Promise.resolve({ runSummaries, hasMore })
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    })
  }
}

export const fetchViewingRunDetail = (viewingRunGUID) => {
  return (dispatch) => {
    return getViewingRunDetail(viewingRunGUID).then(
      ({ ok, viewingRun, responseText }) => {
        if (ok) {
          return Promise.resolve({ viewingRun })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function notifyLateViewing(data, viewingGUID) {
  return (dispatch) => {
    return sendLateViewingNotification(data, viewingGUID).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function formNewViewingRun(teamGUID, managerPreferences) {
  return (dispatch) => {
    return postViewingsRun(teamGUID, managerPreferences).then(
      ({ ok, responseText, viewingRun }) => {
        if (ok) {
          return Promise.resolve({ viewingRun })
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) => {
            try {
              error = JSON.parse(error)
            } catch {}
            if (!!error?.detail?.message) {
              return Promise.reject(error.detail.message)
            } else if (!!error?.app_code) {
              return Promise.reject(translateErrorCodeToMessage(error.app_code))
            } else {
              return Promise.reject(translateErrorCodeToMessage(error))
            }
          })
        }
      },
    )
  }
}

export function createNewViewingRunVersion(teamGUID, managerPreferences) {
  return (dispatch) => {
    return postNewViewingsRun(teamGUID, managerPreferences).then(
      ({ ok, responseText, viewingRun }) => {
        if (ok) {
          return Promise.resolve({ viewingRun })
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) => {
            if (error && error.detail && error.detail.message) {
              return Promise.reject({
                errorMessage: error.detail.message,
                errorPropertyGUID: error.detail.property_guid,
              })
            } else {
              if (error && error.app_code) {
                return Promise.reject({
                  errorMessage: translateErrorCodeToMessage(error.app_code),
                  errorPropertyGUID: null,
                })
              } else {
                return Promise.reject({
                  errorMessage: translateErrorCodeToMessage(error),
                  errorPropertyGUID: null,
                })
              }
            }
          })
        }
      },
    )
  }
}

export function fetchAllNewViewingRunVersion(teamGUID) {
  return (dispatch) => {
    return getNewViewingsRun(teamGUID).then(
      ({ ok, responseText, viewingRun }) => {
        if (ok) {
          return Promise.resolve({ viewingRun })
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function fetchNewViewingRunVersion(teamGUID, viewingRunGUID) {
  return (dispatch) => {
    return getNewViewingRunInfo(teamGUID, viewingRunGUID).then(
      ({ ok, responseText, viewingRun }) => {
        if (ok) {
          return Promise.resolve({ viewingRun })
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function updatelNewViewingRunManagerKeyCheckOut(
  teamGUID,
  viewingGUID,
  managerGUID,
) {
  return (dispatch) => {
    return postNewViewingsRunManagerCheckOutKey(
      teamGUID,
      viewingGUID,
      managerGUID,
    ).then(({ ok, responseText, viewingRun }) => {
      if (ok) {
        return Promise.resolve({ viewingRun })
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    })
  }
}
