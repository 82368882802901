import localforage from 'localforage'

export const SETUP_NOT_STARTED = 'not_started_step'
export const SETUP_STARTED = 'naming_step'
export const SETUP_TEAM_MEMBERS = 'team_members_step'
export const SETUP_BRANDING = 'branding_step'
export const SETUP_COMPLETED = 'completed_step'

export const TEAM_ROLE_LEARN_MORE_LINK =
  'https://help.snug.com/hc/en-us/articles/360001019596'

export const findWhichStepToRouteTo = (status) => {
  switch (status) {
    case SETUP_NOT_STARTED:
      return 'stepLetsGetStarted'
    case SETUP_STARTED:
      return 'stepLetsGetStarted'
    case SETUP_TEAM_MEMBERS:
      return 'stepTeamMembers'
    case SETUP_BRANDING:
      return 'stepBranding'
    case SETUP_COMPLETED:
      return 'stepFinished'
    default:
      return ''
  }
}

export const findStepNumberBasedOnStep = (status) => {
  switch (status) {
    case SETUP_NOT_STARTED:
      return 0
    case SETUP_STARTED:
      return 1
    case SETUP_TEAM_MEMBERS:
      return 2
    case SETUP_BRANDING:
      return 3
    case SETUP_COMPLETED:
      return 4
    default:
      return ''
  }
}

export const findTextForNextStep = (status) => {
  switch (status) {
    case SETUP_STARTED:
      return 'Agency Details'
    case SETUP_TEAM_MEMBERS:
      return 'Team Members'
    case SETUP_BRANDING:
      return 'Branding'
    default:
      return ''
  }
}

export const dontShowSetupWizardPrompt = () => {
  localforage.setItem('dontShowWizardCompleteSteps', true, function (err) {
    console.log(err)
  })
}
