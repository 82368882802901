import React, { Component } from 'react'

import moment from 'moment'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import * as snugNotifier from 'app/services/snugNotifier'
import GeneralBottomBtns from 'app/shared_components/general_bottom_btn/component'
import { ErrorMessage } from 'app/shared_components/helpers'
import TwoColumnContainer, {
  LeftComponent,
} from 'app/shared_components/layout_component/two_column_layout_components'
import NotFound404 from 'app/shared_components/not_found_404/not_found_404'
import { history } from 'app/shared_components/router'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import * as helpers from 'app/sm/helpers'
import {
  findConflictedViewing,
  selectSameDayOrFutureViewingInfos,
} from 'app/sm/viewings_new_run/container'
import PublicHeader from 'app/sm/viewings_new_run/header/component'
import { roundViewingTime } from 'app/sm/viewings_new_run/preview/helpers'
import PropertyItem, {
  PropertyItemListContainer,
} from 'app/sm/viewings_new_run/property_item/component'
import { isViewingRunEnabled } from 'config/features'

// sydney cbd
const center = { lat: -33.87, lng: 151.2 }

export class Map extends Component {
  componentDidMount() {
    this.map = new window.google.maps.Map(document.getElementById('map'), {
      // set sydney cbd as center by default. The map center will changed automatically when direction routes are rendered to cover them all
      center,
      zoom: 14,
      scrollwheel: false,
      disableDoubleClickZoom: true,
    })
    this.directionsDisplay = new window.google.maps.DirectionsRenderer()
    this.directionsDisplay.setMap(this.map)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.directionResult !== nextProps.directionResult) {
      nextProps.directionResult &&
        this.directionsDisplay.setDirections(nextProps.directionResult)
    }
  }

  render() {
    return (
      <div id="map-container">
        <div id="map" />
      </div>
    )
  }
}

// https://developers.google.com/maps/documentation/javascript/reference/directions#Place
const getAddressForGoogleMap = (address) => {
  if (address.googleId) {
    return { placeId: address.googleId }
  } else if (address.lat && address.lng) {
    return { lat: address.lat, lng: address.lng }
  } else {
    let sb = ''
    sb += address.friendlyName
    sb += `, ${address.suburb}`
    sb += `, ${address.state}`
    return sb
  }
}

const markerLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

const getBreakDuration = (viewingRun) =>
  viewingRun && viewingRun.scheduleBreaks ? viewingRun.breakDuration : 0

class Preview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      directionResult: null,
      waypoints: [],
      // this stores the property GUIDs in the order of routes
      orderedPropertyGUIDs: [],
      // this stores the viewing start times calculated by routing algorithms. It could be updated
      // manually. Note: a same array exists in the prop but is only used in read-only mode for dashboard
      viewingStartTimes: [],
      // this stores the viewing durations initially set automatically with same value. it could be updated
      // manually
      viewingDurations: [],
      isSaving: false,
      savingError: '',
      viewingsUpdatedIndexCollection: [],
    }
  }

  componentDidMount() {
    this.directionService = new window.google.maps.DirectionsService()

    if (this.props.viewingRun.selectedPropertyInfos) {
      const orderedPropertyGUIDs =
        this.props.viewingRun.selectedPropertyInfos.map(
          (propertyInfo) => propertyInfo.property.guidID,
        )
      // initialise viewing durations. if exists under viewing run prop (meaning set by viewing run dash) then use it
      // otherwise use the uniform viewing duration chosen in the create viewing run
      let viewingDurations
      if (
        this.props.viewingRun.viewingDurations &&
        this.props.viewingRun.viewingDurations.length !== 0
      ) {
        viewingDurations = this.props.viewingRun.viewingDurations
      } else {
        viewingDurations = Array(orderedPropertyGUIDs.length).fill(
          this.props.viewingRun.viewingDuration,
        )
      }
      this.setState({ orderedPropertyGUIDs, viewingDurations }, () => {
        this.loadRoutes(true)
      })
      this.loadRoutes(true)
    } else {
      // when reloading (not navigating from viewing run dash or create run)
      const { teamSlug = '' } = this.props.match.params
      history.push(helpers.urlTo('newViewingRun', { teamSlug }))
    }
  }

  componentDidUpdate() {
    if (this.state.waypoints.length !== 0) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  onDeleteButtonClicked = (index) => {
    if (!window.confirm('Do you want to delete this viewing?')) {
      return
    }
    if (this.state.orderedPropertyGUIDs.length === 2) {
      window.alert('Cannot delete. You have to have more than 2 properties.')
      return
    }
    const newOrderedPropertyGUIDs = [...this.state.orderedPropertyGUIDs]
    newOrderedPropertyGUIDs.splice(index, 1)
    this.setState({ orderedPropertyGUIDs: newOrderedPropertyGUIDs }, () => {
      this.loadRoutes(false)
    })
  }

  onDragEndHandler = (result) => {
    const { destination, source, draggableId } = result
    if (!destination) {
      return
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const newOrderedPropertyGUIDs = this.state.orderedPropertyGUIDs.map(
      (id) => id,
    )
    newOrderedPropertyGUIDs.splice(source.index, 1)
    newOrderedPropertyGUIDs.splice(destination.index, 0, draggableId)

    this.setState({ orderedPropertyGUIDs: newOrderedPropertyGUIDs }, () => {
      this.loadRoutes(false)
    })
  }

  onEditClicked = (indexOfUpdatedViewing) => {
    const newWaypoints = this.state.waypoints.map((waypoint) => waypoint)
    newWaypoints[indexOfUpdatedViewing].editable =
      !newWaypoints[indexOfUpdatedViewing].editable
    // edit
    if (newWaypoints[indexOfUpdatedViewing].editable) {
      this.setState({ waypoints: newWaypoints })
      return
    }
    // save
    const { viewingsUpdatedIndexCollection = [] } = this.state
    if (
      viewingsUpdatedIndexCollection.find(
        (viewingIndex) => viewingIndex > indexOfUpdatedViewing,
      )
    ) {
      if (!window.confirm('Changes to later viewing times will be reset.')) {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.viewingStartTimes[indexOfUpdatedViewing] =
          this.state.lastSavedViewingStartTimes[indexOfUpdatedViewing]
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.viewingDurations[indexOfUpdatedViewing] =
          this.state.lastSavedViewingDuration[indexOfUpdatedViewing]
        this.updateWaypoints(
          this.state.orderedPropertyGUIDs,
          this.state.viewingStartTimes,
        )
        return
      }
    }
    viewingsUpdatedIndexCollection.push(indexOfUpdatedViewing)
    const newViewingsUpdatedIndexCollection = helpers.uniqueArrayGenerator(
      viewingsUpdatedIndexCollection.filter(
        (viewingIndex) => viewingIndex <= indexOfUpdatedViewing,
      ),
    )
    this.setState({
      viewingsUpdatedIndexCollection: newViewingsUpdatedIndexCollection,
    })
    const newViewingStartTimes = this.calculateViewingStartTimes(
      this.state.directionResult,
      indexOfUpdatedViewing,
      this.state.viewingStartTimes[indexOfUpdatedViewing],
    )
    this.setState({ viewingStartTimes: newViewingStartTimes })
    this.updateWaypoints(this.state.orderedPropertyGUIDs, newViewingStartTimes)
  }

  onSaveButtonClicked = () => {
    if (!window.confirm('Do you want to schedule viewings?')) {
      return
    }

    const { travelMode, managerGUID, agencyGUID } = this.props.viewingRun
    const viewingRunRequest = {
      managerGUID,
      agencyGUID,
      // startTime is used by BE to return sorted viewing runs
      startTime: this.state.viewingStartTimes[0].format(),
      travelMode,
      waypoints: this.state.waypoints.map((waypoint) => ({
        ...waypoint,
        viewingStartTime: waypoint.viewingStartTime.format(),
      })),
      breakDuration: getBreakDuration(this.props.viewingRun),
    }

    if (this.isThereConflictedViewingTime(viewingRunRequest)) {
      if (
        !window.confirm(
          'There are conflicted viewing time. Do you want to continue?',
        )
      ) {
        return
      }
    }

    this.setState({ isSaving: true })
    this.props
      .createNewViewingRun(viewingRunRequest)
      .then(() => {
        const { teamSlug = '' } = this.props.match.params
        // keep isSaving to true as unsaved message would trigger if set to false
        history.push(helpers.urlTo('viewingRuns', { teamSlug }))
      })
      .catch((error) => {
        this.setState({ isSaving: false, savingError: `${error}` })
      })
  }

  // this only update the individual viewing duration at index i.e. no propagation
  // event should be standard input event
  onViewingDurationChanged = (index, event) => {
    const {
      target: { value },
    } = event
    const newViewingDurations = Array.from(this.state.viewingDurations)
    const parsed = parseInt(value)
    newViewingDurations[index] = isNaN(parsed) ? 0 : parsed
    this.setState({ viewingDurations: newViewingDurations })
  }

  // this only update the individual viewing start time at index i.e. no propagation
  // value should be moment object
  onViewingStartTimeChanged = (index, value) => {
    const newViewingStartTimes = Array.from(this.state.viewingStartTimes)
    const newViewingDate = newViewingStartTimes[index].format('YYYY-MM-DD')
    const newViewingStartTime = value.format('hh:mm:ss A')
    newViewingStartTimes[index] = moment(
      newViewingDate + ' ' + newViewingStartTime,
    )
    this.setState({ viewingStartTimes: newViewingStartTimes })
  }

  // calculate viewing start times
  calculateViewingStartTimes = (
    directionResult,
    updatedIndex,
    updatedStartTime,
  ) => {
    // get viewing start time directly from saved results (dashboard)
    if (
      this.props.viewingRun.viewingStartTimes &&
      this.props.viewingRun.viewingStartTimes.length !== 0
    ) {
      return this.props.viewingRun.viewingStartTimes
    }

    // recalculate from direction api result
    if (updatedIndex === undefined && updatedStartTime === undefined) {
      return directionResult.routes[0].legs.reduce((acc, cur, curIndex) => {
        // for the first leg (1st property), use global start time
        if (curIndex === 0) {
          acc.push(this.props.viewingRun.startTime)
        }
        // for the 2nd and others, use previous start time plus viewing duration, break duration and travel duration
        acc.push(
          roundViewingTime(
            moment(acc[acc.length - 1])
              .add(this.state.viewingDurations[curIndex], 'm')
              .add(getBreakDuration(this.props.viewingRun), 'm')
              .add(cur.duration.value, 's'),
          ),
        )
        return acc
      }, [])
    }

    // recalculate from manually editing
    const viewingStartTimes = directionResult.routes[0].legs.reduce(
      (acc, cur, curIndex) => {
        // for the first leg (2nd property), use updated start time (if updated) or existing start time
        if (curIndex === 0) {
          if (updatedIndex === 0) {
            acc.push(updatedStartTime)
          } else {
            acc.push(this.state.viewingStartTimes[0])
          }
        }
        // for the 2nd and others, use previous start time plus viewing duration, break duration and travel duration
        let viewingStartTime
        const isUpdatedTimeAfterLastViewingTime = moment(
          updatedStartTime,
        ).isAfter(moment(this.state.viewingStartTimes[curIndex]))
        if (curIndex < updatedIndex - 1) {
          acc.push(this.state.viewingStartTimes[curIndex + 1])
          return acc
        } else if (
          curIndex === updatedIndex - 1 &&
          isUpdatedTimeAfterLastViewingTime
        ) {
          acc.push(updatedStartTime)
          return acc
        } else {
          viewingStartTime = acc[acc.length - 1]
        }
        acc.push(
          roundViewingTime(
            moment(viewingStartTime)
              .add(this.state.viewingDurations[curIndex], 'm')
              .add(getBreakDuration(this.props.viewingRun), 'm')
              .add(cur.duration.value, 's'),
          ),
        )
        return acc
      },
      [],
    )
    this.setState({
      lastSavedViewingStartTimes: viewingStartTimes,
      lastSavedViewingDuration: this.state.viewingDurations,
    })
    return viewingStartTimes
  }

  generateOrderedPropertyInfos = () => {
    if (
      this.props.viewingRun.selectedPropertyInfos.length === 0 ||
      this.state.orderedPropertyGUIDs.length === 0
    ) {
      return []
    }

    const propertyInfoMap = this.props.viewingRun.selectedPropertyInfos.reduce(
      (acc, propertyInfo) => {
        acc[propertyInfo.property.guidID] = propertyInfo
        return acc
      },
      {},
    )
    return this.state.orderedPropertyGUIDs.map(
      (propertyGUID) => propertyInfoMap[propertyGUID],
    )
  }

  generateRouteRequest = (optimizeWaypoints) => {
    const { selectedPropertyInfos = [], travelMode } = this.props.viewingRun
    if (
      selectedPropertyInfos.length === 0 ||
      this.state.orderedPropertyGUIDs.length < 2
    ) {
      return null
    }
    const propertyInfoMap = selectedPropertyInfos.reduce(
      (acc, propertyInfo) => {
        acc[propertyInfo.property.guidID] = propertyInfo
        return acc
      },
      {},
    )

    const startPropertyInfo =
      propertyInfoMap[this.state.orderedPropertyGUIDs[0]]
    const endPropertyInfo =
      propertyInfoMap[
        this.state.orderedPropertyGUIDs[
          this.state.orderedPropertyGUIDs.length - 1
        ]
      ]
    const intermediatePropertyInfos = this.state.orderedPropertyGUIDs
      .filter(
        (_, index) =>
          index !== 0 && index !== this.state.orderedPropertyGUIDs.length - 1,
      )
      .map((propertyGUID) => propertyInfoMap[propertyGUID])

    const originAddress = getAddressForGoogleMap(
      startPropertyInfo.property.address,
    )
    const destinationAddress = getAddressForGoogleMap(
      endPropertyInfo.property.address,
    )
    const intermediateAddresses = intermediatePropertyInfos.map(
      (propertyInfo) => ({
        location: getAddressForGoogleMap(propertyInfo.property.address),
      }),
    )

    return {
      origin: originAddress,
      destination: destinationAddress,
      waypoints: intermediateAddresses,
      travelMode: travelMode,
      optimizeWaypoints,
    }
  }

  isReadOnly = () => {
    return this.props.match.params.viewingRunGUID
  }

  isThereConflictedViewingTime = (viewingRunRequest) => {
    let conflicts = 0
    viewingRunRequest.waypoints.forEach((waypoint) => {
      conflicts += findConflictedViewing(
        this.props.viewingRun.propertyViewingInfos
          ? this.props.viewingRun.propertyViewingInfos[waypoint.propertyGUID]
          : [],
        moment(waypoint.viewingStartTime),
        viewingRunRequest.viewingDuration,
        getBreakDuration(this.props.viewingRun),
      ).length
    })
    return conflicts > 0
  }

  loadRoutes = (optimizeWaypoints) => {
    const routeRequest = this.generateRouteRequest(optimizeWaypoints)
    if (!routeRequest) {
      return
    }
    this.directionService.route(routeRequest, (directionResult, status) => {
      if (status === 'OK') {
        this.setState({ directionResult })

        // persist into state
        // assign order, duration and distance info to each propertyGUID (except start point)
        const startPropertyGUID = this.state.orderedPropertyGUIDs[0]
        const endPropertyGUID =
          this.state.orderedPropertyGUIDs[
            this.state.orderedPropertyGUIDs.length - 1
          ]
        const intermediatePropertyGUIDs = this.state.orderedPropertyGUIDs.slice(
          1,
          this.state.orderedPropertyGUIDs.length - 1,
        )
        const intermediateOrderedPropertyGUIDs = directionResult
          ? directionResult.routes[0].waypoint_order.map(
              (order) => intermediatePropertyGUIDs[order],
            )
          : []
        // only update state when optimizeWaypoints is true because it might reorder. it should be ok
        // to always call setState but that's not optimal
        const newOrderedPropertyGUIDs = [startPropertyGUID]
          .concat(intermediateOrderedPropertyGUIDs)
          .concat([endPropertyGUID])
        if (optimizeWaypoints) {
          this.setState({ orderedPropertyGUIDs: newOrderedPropertyGUIDs })
        }

        // calculate new viewing start times
        const newViewingStartTimes =
          this.calculateViewingStartTimes(directionResult)
        this.setState({ viewingStartTimes: newViewingStartTimes })
        // update waypoints
        this.updateWaypoints(newOrderedPropertyGUIDs, newViewingStartTimes)
      } else {
        snugNotifier.error('Directions request failed due to ' + status)
      }
    })
  }

  // if viewingStartTimes is updated (either by rerun routing or manually update), the waypoints' start times
  // should be recalculated accordingly
  updateWaypoints = (orderedPropertyGUIDs, viewingStartTimes) => {
    const waypoints = orderedPropertyGUIDs.map((propertyGUID, index) => {
      let waypoint = {
        // order is used by BE to return sorted waypoint
        order: index,
        propertyGUID,
        viewingStartTime: viewingStartTimes[index],
        viewingDuration: this.state.viewingDurations[index],
        // always false as this can be called either by clicking save or loading routes
        editable: false,
      }
      // only non-ending waypoint has duration and distance info
      if (index !== orderedPropertyGUIDs.length - 1) {
        waypoint = {
          ...waypoint,
          duration:
            this.state.directionResult.routes[0].legs[index].duration.value,
          durationFriendly:
            this.state.directionResult.routes[0].legs[index].duration.text,
          distance:
            this.state.directionResult.routes[0].legs[index].distance.value,
          distanceFriendly:
            this.state.directionResult.routes[0].legs[index].distance.text,
        }
      }
      return waypoint
    })

    this.setState({ waypoints })
  }

  render() {
    const orderedPropertyInfos = this.generateOrderedPropertyInfos()

    const routedPropertyInfos = orderedPropertyInfos.map(
      (propertyInfo, index) => ({
        ...propertyInfo,
        startTime:
          this.state.viewingStartTimes.length !== 0
            ? this.state.viewingStartTimes[index]
            : '',
        viewingDuration: this.state.viewingDurations[index],
        breakDuration: getBreakDuration(this.props.viewingRun),
        travelDuration:
          this.state.waypoints.length > index
            ? this.state.waypoints[index].durationFriendly
            : '',
        travelMode: this.props.viewingRun.travelMode,
        needTravelToNext: index !== orderedPropertyInfos.length - 1,
        editable:
          this.state.waypoints.length > index
            ? this.state.waypoints[index].editable
            : false,
      }),
    )

    const { managerProfile = {} } = this.props.viewingRun

    const firstViewingStartTime =
      this.state.viewingStartTimes.length !== 0
        ? this.state.viewingStartTimes[0]
        : ''
    const text =
      `Viewing run scheduled for ${moment(firstViewingStartTime).format(
        'ddd DD MMM',
      )} for ${managerProfile.firstName + ' ' + managerProfile.lastName}.` +
      ' Click save to schedule viewings and notify enquirers.'
    const { teamSlug = '' } = this.props.match.params

    return isViewingRunEnabled(teamSlug, this.props.teams) ? (
      <TwoColumnContainer>
        <LeftComponent>
          <PublicHeader title="Viewings run" text={text} />
          <Map directionResult={this.state.directionResult} />
          <div className="mb20"></div>
          <PublicHeader text="Drag and drop to change the viewing order." />
          <PropertyItemListContainer>
            <DragDropContext onDragEnd={this.onDragEndHandler}>
              <Droppable
                droppableId={'preview'}
                isDropDisabled={this.isReadOnly()}
              >
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {routedPropertyInfos.map((propertyInfo, index) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={propertyInfo.property.guidID}
                          index={index}
                          isDragDisabled={this.isReadOnly()}
                        >
                          {(provided, snapshot) => (
                            <div>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <PropertyItem
                                  propertyInfo={propertyInfo}
                                  status="scheduled"
                                  markerLabel={markerLabels.charAt(index)}
                                  onEditClicked={() =>
                                    this.onEditClicked(index)
                                  }
                                  onDeleteButtonClicked={() =>
                                    this.onDeleteButtonClicked(index)
                                  }
                                  onViewingStartTimeChanged={(value) =>
                                    this.onViewingStartTimeChanged(index, value)
                                  }
                                  onViewingDurationChanged={(value) =>
                                    this.onViewingDurationChanged(index, value)
                                  }
                                  sameDayOrfutureViewingInfos={selectSameDayOrFutureViewingInfos(
                                    this.props.viewingRun.propertyViewingInfos
                                      ? this.props.viewingRun
                                          .propertyViewingInfos[
                                          propertyInfo.property.guidID
                                        ]
                                      : [],
                                    propertyInfo.startTime,
                                  )}
                                  conflicted={findConflictedViewing(
                                    this.props.viewingRun.propertyViewingInfos
                                      ? this.props.viewingRun
                                          .propertyViewingInfos[
                                          propertyInfo.property.guidID
                                        ]
                                      : [],
                                    propertyInfo.startTime,
                                    propertyInfo.viewingDuration,
                                    this.props.viewingRun.scheduleBreaks
                                      ? this.props.viewingRun.breakDuration
                                      : 0,
                                  )}
                                  isReadOnly={this.isReadOnly()}
                                  teamSlug={teamSlug}
                                  viewingGUIDs={
                                    this.props.viewingRun.viewingGUIDs
                                  }
                                  notifyLateViewing={
                                    this.props.notifyLateViewing
                                  }
                                />
                              </div>
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </PropertyItemListContainer>

          <GeneralBottomBtns
            onBackButtonClicked={() => history.goBack()}
            onConfirmButtonClicked={this.onSaveButtonClicked}
            confirmBtnText="Save"
            disableConfirmBtn={this.state.isSaving}
            hideConfirmButton={this.isReadOnly()}
          />

          {this.state.savingError && (
            <ErrorMessage error={this.state.savingError} />
          )}
        </LeftComponent>
        <UnsavedMessage
          unsavedChanges={
            !this.isReadOnly() &&
            this.state.waypoints.length !== 0 &&
            this.state.isSaving === false
          }
          message="Viewing run is not saved. Do you want to continue?"
        />
      </TwoColumnContainer>
    ) : (
      <NotFound404 />
    )
  }
}

export default Preview
