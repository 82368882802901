import { connect } from 'react-redux'

import {
  fetchPropertyInfoByViewingId,
  registerForEmailViewing,
  unregisterForEnquiryViewing,
  updateEquiryViewingRegistrant,
  viewingAttendeeConfirmation,
} from 'app/match/viewings/enquiry_viewing_register/enquiry_viewing_register_actions'
import RegisterViewingConfimation from 'app/match/viewings/enquiry_viewing_register/register_viewing_confirmation'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  registerForEmailViewing: (viewingID, payload) =>
    dispatch(registerForEmailViewing(viewingID, payload)),
  updateEquiryViewingRegistrant: (data, registrantID) =>
    dispatch(updateEquiryViewingRegistrant(data, registrantID)),
  unregisterForEnquiryViewing: (registrantID) =>
    dispatch(unregisterForEnquiryViewing(registrantID)),
  fetchPropertyInfoByViewingId: (viewingId) =>
    dispatch(fetchPropertyInfoByViewingId(viewingId)),
  viewingAttendeeConfirmation: (viewingID, attendeeID) =>
    dispatch(viewingAttendeeConfirmation(viewingID, attendeeID)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterViewingConfimation)
