import PropTypes from 'prop-types'

import {
  Box,
  Button,
  ButtonWithLoading,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  loadingStatePropType,
  loadingStatesIds,
} from 'app/utils/loading-states'

export const BulkyActionsControls = ({
  selectedMessagesCount,
  markAsReadConfig,
  archiveConfig,
  sendMessageConfig,
  ...otherStyledProps
}) => {
  return (
    <Flex alignItems="center" {...otherStyledProps}>
      <Box mr={theme.space[4] + 'px'}>
        <TextSpan color="deepBlue" fontWeight="bold">
          {selectedMessagesCount}
        </TextSpan>{' '}
        Selected
      </Box>
      <ButtonWithLoading
        mr={theme.space[4] + 'px'}
        variant="outline"
        width="unset"
        onClick={archiveConfig.onClick}
        loading={archiveConfig.loadingStates.state === loadingStatesIds.LOADING}
        disableWhenLoading
      >
        Archive
      </ButtonWithLoading>
      <ButtonWithLoading
        mr={theme.space[4] + 'px'}
        variant="outline"
        width="unset"
        onClick={markAsReadConfig.onClick}
        loading={
          markAsReadConfig.loadingStates.state === loadingStatesIds.LOADING
        }
        disableWhenLoading
      >
        Mark As read
      </ButtonWithLoading>
      {sendMessageConfig.isEnabled && (
        <Button variant="outline" onClick={sendMessageConfig.onClick}>
          Send Message
        </Button>
      )}
    </Flex>
  )
}

const actionConfigPropType = PropTypes.shape({
  onClick: PropTypes.func,
  loadingStates: loadingStatePropType,
})

BulkyActionsControls.propTypes = {
  selectedMessagesCount: PropTypes.number,
  markAsReadConfig: actionConfigPropType,
  archiveConfig: actionConfigPropType,
}
