import React from 'react'

import 'app/components/forms/base_checkbox/style.scss'

const handleChange = ({
  event,
  eventProcessedByComponent,
  onChange,
  checked,
}) => {
  if (onChange) {
    if (eventProcessedByComponent) {
      onChange(event)
    } else {
      onChange({ value: !checked, error: '' })
    }
  } else {
    return false
  }
  return true
}

const Checkbox = ({
  label,
  checked,
  id,
  readOnly = false,
  componentClassName = '',
  enableEdit = true,
  eventProcessedByComponent,
  onChange,
  disabled = false,
  labelClass = '',
  toolTip = '',
  showDisabledGrey = false,
  checkboxInputClassName = '',
}) => {
  const isDisabledAndShowGrey = disabled && showDisabledGrey
  return (
    <label
      className={`form-checkbox ${componentClassName} ${
        !enableEdit && 'disabled-link'
      } display-flex`}
    >
      <input
        type="checkbox"
        checked={checked}
        onClick={
          !!readOnly
            ? undefined
            : (event) =>
                handleChange({
                  event,
                  eventProcessedByComponent,
                  onChange,
                  checked,
                })
        }
        id={id}
        disabled={disabled}
        className={`${checkboxInputClassName} ${
          isDisabledAndShowGrey && 'disabled-grey-bg'
        }`}
      />
      {label && (
        <span className={`${checked ? '' : 'color-unchecked'} ${labelClass}`}>
          {label}
        </span>
      )}
      {toolTip}
    </label>
  )
}

export default Checkbox
