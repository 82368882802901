import React from 'react'

import { uberCompNotShowingDay } from 'config/env'
import styled from 'styled-components'

import uberEatImg from 'app/assets/icons/application-details/uber-eat.png'
import uberImg from 'app/assets/icons/application-details/uber.png'
import { Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import Theme from 'app/pages/teams/viewings-mobile/theme-styled-components'
import * as datetimeHelpers from 'app/utils/datetime/helpers'

const UberCompWrapper = styled.div`
  margin-top: ${({ theme }) => `${theme.space[4]}px`};
`

const UberCompText = styled(Text)`
  color: ${({ theme }) => `${theme.colors.gray600}`};
  font-size: ${({ theme }) => `${theme.fontSizes[3]}px`};
  font-weight: ${({ theme }) => theme.fontWeights[3]};
  margin-bottom: ${({ theme }) => `${theme.space[0]}px`};
`

const UberImage = styled.img`
  margin-left: ${({ theme }) => `${theme.space[3]}px`};
  height: 25px;
  @media screen and (max-width: 576px) {
    margin: ${({ theme }) => `${theme.space[4]}px`}
      ${({ theme }) => `${theme.space[4]}px`}
      ${({ theme }) => `${theme.space[4]}px`}
      ${({ theme }) => `${theme.space[0]}`};
    height: 20px;
  }
`

const UberFeat = styled(Flex)`
  @media screen and (max-width: 576px) {
    flex-direction: column-reverse;
  }
`

const UBER_LINK =
  'https://www.uber.com/legal/en/document/?country=united-states&lang=en&name=uber-for-business-product-addendum---vouchers'

const UberCompContainer = () => {
  const uberComponentPromotionEndDate =
    datetimeHelpers.formatTimeFromGivenTimestamp(
      uberCompNotShowingDay,
      datetimeHelpers.HOUR_MINUTE_DATE_MONTH_YEAR,
      datetimeHelpers.DOT_DATE_FORMAT,
    )
  return (
    <Theme>
      <UberCompWrapper>
        <UberFeat mb={`${theme.space[4]}px`}>
          <UberCompText>
            Enjoy a $25 Uber voucher for moving with Direct Connect when you
            take up an electricity or gas connection with one of Direct
            Connect's energy providers.
          </UberCompText>
          <Flex justifyContent="start">
            <UberImage src={uberEatImg} alt="uber-eat" />
            <UberImage src={uberImg} alt="uber" />
          </Flex>
        </UberFeat>
        <UberCompText>
          *Offer available to lead received by Direct Connect until 11:59pm AEDT{' '}
          {uberComponentPromotionEndDate}. No cash value and non transferable.
          Vouchers expire 30 days from the date of issue.{' '}
          <a target="_blank" rel="noopener noreferrer" href={UBER_LINK}>
            Uber Terms & Conditions apply
          </a>
        </UberCompText>
      </UberCompWrapper>
    </Theme>
  )
}

export default UberCompContainer
