import moment from 'moment'

import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

const defaultPublishedStatus = false
const defaultNotifyStatus = false

export const _createViewing = (
  id,
  startdate,
  duration,
  fromScheduler,
  skipDoubleBookingCheck,
  viewingOptions = {},
) => {
  const {
    published = defaultPublishedStatus,
    notify = defaultNotifyStatus,
    notifyShortlistedApplicants = false,
    isNoneUser = false,
    inspectorsGUIDs = [],
  } = viewingOptions
  const url = `${apiBaseUrl}sm/offers/${id}/viewings?scheduler=${fromScheduler}&isnoneuser=${isNoneUser}&skipdoublebookingcheck=${skipDoubleBookingCheck}`
  const utcDate = startdate ? moment.utc(startdate).format() : ''
  const payload = {
    startdate: utcDate,
    duration,
    published,
    notify,
    notifyShortlistedApplicants,
    inspectorsGUIDs,
  }

  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (viewing) => ({ ok: true, viewing }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const _updateViewing = (
  id,
  viewingId,
  startdate,
  duration,
  skipDoubleBookingCheck,
  viewingOptions = {},
) => {
  const {
    published = defaultPublishedStatus,
    notify = defaultNotifyStatus,
    notifyShortlistedApplicants,
  } = viewingOptions
  const url = `${apiBaseUrl}sm/offers/${id}/viewings/${viewingId}?skipdoublebookingcheck=${skipDoubleBookingCheck}`
  const utcDate = startdate ? moment.utc(startdate).format() : ''
  const payload = {
    startdate: utcDate,
    duration,
    published,
    notify,
    notifyShortlistedApplicants,
  }

  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (viewing) => ({ ok: true, viewing }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const _getViewings = (id) => {
  const url = `${apiBaseUrl}sm/offers/${id}/viewings`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (viewings) => ({ ok: true, viewings }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
