import type { Ref } from 'react'

import type { FormikProps } from 'formik'

import BallotDisclosures from 'app/components/Ballot/Disclosures'
import type { BallotsHouseholdIncomeLimit } from 'app/components/Ballot/Disclosures/Formbody'
import { Alert, Box } from 'app/components/design-system-components'

type BallotDetailsProps = {
  formRef: Ref<FormikProps<object>>
  ballotsHouseholdIncomeLimit: BallotsHouseholdIncomeLimit
  ballotsHouseholdIncomeRegion: string
  ballotDisclosuresAnswers: any[]
}

function BallotDetails({
  formRef,
  ballotsHouseholdIncomeLimit,
  ballotsHouseholdIncomeRegion,
  ballotDisclosuresAnswers,
}: BallotDetailsProps) {
  return (
    <Alert variant="blueWithBg">
      <Box color="black">
        <h2>Ballot details</h2>
        <Box>
          Homes Victoria affordable Housing creates an alternative to the
          private rental market to help low to moderate income Victorian renters
          access high quality housing at an affordable rent. Please visit the
          Affordable Housing website at{' '}
          <a href="https://www.homes.vic.gov.au/homes-victoria-affordable">
            https://www.homes.vic.gov.au/homes-victoria-affordable
          </a>{' '}
          for information on who is eligible to apply and the ballot process.
        </Box>
        <Box mt={2}>
          {' '}
          To apply for a Homes Victoria affordable home, you will need to comply
          with the eligibility checklist below.
        </Box>
        <Box mt={2}>
          If you are successfully drawn from the ballot, you will be required
          within 2 days to provide proof of your eligibility in the form of
          recent payslips, identification and visa status.
        </Box>
        <Box mt={2}>
          If the checklist determines that you are unsuccessful, you will not
          progress to apply for a Homes Victoria affordable home.
        </Box>
        <BallotDisclosures
          formRef={formRef}
          ballotsHouseholdIncomeLimit={ballotsHouseholdIncomeLimit}
          ballotDisclosuresAnswers={ballotDisclosuresAnswers}
          ballotsHouseholdIncomeRegion={ballotsHouseholdIncomeRegion}
        />
      </Box>
    </Alert>
  )
}

export default BallotDetails
