import { connect } from 'react-redux'

import {
  fetchAgencyStatus,
  fetchTeamManagerList,
} from 'app/bond_cover/agency/agency_actions'
import {
  addAttachmentForAgency,
  fetchLeasingOfferInfo,
  fetchLeasingOfferInfoBySlug,
  removeAttachmentForAgency,
  sendLeaseOffer,
} from 'app/match/leaseAttachment/action'
import LeaseAttachmentContainer from 'app/match/leaseAttachment/container'
import { acceptApplication } from 'app/sm/apply/apply_actions'
import { getConsoleCloudIntegration } from 'app/sm/plugins/plugins_action'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'
import { withdrawOfferForApplication } from 'app/sm/renter_applications/renter_applications_actions'

const mapStateToProps = ({ session }) => ({
  currentAgency: session.currentAgency,
  teams: session.teams,
  currentUser: session.currentUser,
  currentTeam: session.currentTeam,
})

const mapDispatchToProps = (dispatch) => ({
  fetchLeasingOfferInfo: (applicationId, agencyId) =>
    dispatch(fetchLeasingOfferInfo(applicationId, agencyId)),
  fetchLeasingOfferInfoBySlug: (slug) =>
    dispatch(fetchLeasingOfferInfoBySlug(slug)),
  sendLeaseOffer: (applicationId, agencyId, payload) =>
    dispatch(sendLeaseOffer(applicationId, agencyId, payload)),
  fetchTeamManagerList: (teamGUID) => dispatch(fetchTeamManagerList(teamGUID)),
  addAttachmentForAgency: (applicationId, agencyId, scope) =>
    dispatch(addAttachmentForAgency(applicationId, agencyId, scope)),
  removeAttachmentForAgency: (applicationId, agencyId, key, scope) =>
    dispatch(removeAttachmentForAgency(applicationId, agencyId, key, scope)),
  acceptApplication: (offerId, applicationId, propertyId, newLeaseOffer) =>
    dispatch(
      acceptApplication(offerId, applicationId, propertyId, newLeaseOffer),
    ),
  withdrawOfferForApplication: (applicationId) =>
    dispatch(withdrawOfferForApplication(applicationId)),
  getIntegration: (teamGUID) => dispatch(getConsoleCloudIntegration(teamGUID)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
  fetchAgencyStatus: (agencyGUID) => dispatch(fetchAgencyStatus(agencyGUID)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeaseAttachmentContainer)
