import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const RentalHistoryStatusNotApplicable = 0
export const RentalHistoryStatusNew = 100
export const RentalHistoryStatusUploadLedger = 200
export const RentalHistoryStatusAwaitingVacate = 300 // Deprecated
export const RentalHistoryStatusAnswerVacate = 400 // Deprecated
export const RentalHistoryStatusCompleted = 500
export const RentalHistoryStatusDeclined = 600

export const RentalReferencesSidebarRetentionMaxCount = 7

export const getRentalHistoryMetadata = (status) => {
  switch (status) {
    case RentalHistoryStatusNotApplicable: {
      return {
        bgColor: theme.colors.warning100,
        textColor: theme.colors.warning700,
        text: 'Not Applicable',
        tooltip: 'Unknown reference status',
      }
    }
    case RentalHistoryStatusNew: {
      return {
        bgColor: theme.colors.warning100,
        textColor: theme.colors.warning700,
        text: 'Pending',
        tooltip: 'Awaiting pre-vacate reference',
      }
    }
    case RentalHistoryStatusUploadLedger: {
      return {
        bgColor: theme.colors.warning100,
        textColor: theme.colors.warning700,
        text: 'Upload Ledger',
        tooltip: 'Awaiting upload of Tenancy Ledger',
      }
    }
    case RentalHistoryStatusCompleted: {
      return {
        bgColor: theme.colors.success100,
        textColor: theme.colors.success700,
        text: 'Complete',
        tooltip: 'Reference completed',
      }
    }
    case RentalHistoryStatusDeclined: {
      return {
        bgColor: theme.colors.gray100,
        textColor: theme.colors.gray700,
        text: 'Declined',
        tooltip:
          'Reference not provided (e.g. incorrect details or wrong agency)',
      }
    }
    default: {
      return {
        bgColor: theme.colors.error400,
        textColor: theme.colors.white,
        text: '',
        tooltip: '',
      }
    }
  }
}

export const rentalHistoryVisibilityStatusColor = (visibility) => {
  switch (visibility) {
    case true:
      return {
        bgColor: theme.colors.success100,
        textColor: theme.colors.success700,
        text: 'Visible',
      }
    case false:
    default:
      return {
        bgColor: theme.colors.warning100,
        textColor: theme.colors.warning700,
        text: 'Private',
      }
  }
}
