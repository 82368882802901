import React from 'react'

import moment from 'moment'

import applicationDialogue from 'app/assets/icons/application_icon_dialogue.svg'
import {
  Box,
  Button,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'
import ActivityItemWithDot from 'app/components/design-system-components/ActivityItemWithDot'
import { PhoneRounded } from 'app/components/design-system-components/icons/communications'
import { history } from 'app/history'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  dateTimeStampFormatWithAt,
  noteActionCollection,
  noteActionType,
  targetSectionObject,
  urlTo,
} from 'app/sm/helpers'
import { formatTime, TIME_DAY_DATE } from 'app/utils/datetime/helpers'

import 'app/components/display/application_note/style.scss'

const NoteComponentFlatStyle = ({
  note,
  componentClass,
  from = 'default',
  infoTextClass = '',
  handleApplicationChange,
  noteStyle = null,
  teamSlug = '',
}) => {
  const now = moment()
  const end = moment(note.updatedAt)
  const duration = moment.duration(now.diff(end))
  const hours = Math.floor(duration.asHours())
  let durationText
  if (hours >= 24) {
    durationText = `${Math.floor(duration.asDays())} days ago`
  } else {
    durationText = `${hours} hours ago`
  }
  const {
    type,
    applicant = '',
    target = '',
    Metadata: metaData = '',
    assignedTeamMemberName = '',
    updatedAt = '',
    applicationDetails = null,
    messageDetails = null,
    viewingDetails = null,
    targetName = 'user',
  } = note || {}
  const sectionName = Object.keys(targetSectionObject)[target]
  const sectionText = targetSectionObject[sectionName]
    ? targetSectionObject[sectionName].text
    : ''
  const matchedAction = noteActionCollection.find(
    (action) => action.type === type,
  )

  let { text: actionText = '', actionImgSrc = applicationDialogue } =
    matchedAction || {}
  const parsedMetaData = !!metaData ? JSON.parse(metaData) : {}
  const { Images: images = [], location = '' } = parsedMetaData
  const isAssignedToTeamMember = !!assignedTeamMemberName
  const showSectionTxt =
    type !== noteActionType.requestRenterInfo &&
    type !== noteActionType.enquiry &&
    type !== noteActionType.viewingAttendee &&
    type !== noteActionType.viewingAttendeePrivate

  const renderHeader = () => {
    let actedPart
    if (showSectionTxt) {
      switch (type) {
        case noteActionType.changed:
          actedPart = `${applicant}'s ${sectionText}`
          break
        case noteActionType.redirected:
          actedPart = ''
          break
        default:
          actedPart = `${applicant}'s ${sectionText} section`
      }
    } else {
      actedPart = applicant
    }

    return (
      <Box fontSize={4}>
        <strong>{note.author}&nbsp;</strong>
        <TextSpan as="span">
          {actionText} {actedPart}
        </TextSpan>
      </Box>
    )
  }

  const renderHeaderForTargetName = () => {
    return (
      <Box fontSize={4}>
        <strong>{note.author}&nbsp;</strong>
        <TextSpan as="span">
          {actionText} {targetName || 'user'}
        </TextSpan>
      </Box>
    )
  }

  let noteDate = moment(updatedAt).format(dateTimeStampFormatWithAt)
  let commentInfo = renderHeaderForTargetName()

  let propertyAddress = ''
  let hasLink = false
  let linkLabel = ''
  let actionButtonClick = null
  if (applicationDetails) {
    propertyAddress = applicationDetails?.propertyAddress
    hasLink = true
    linkLabel = 'open application'
    const {
      propertyId = '',
      applicationId = '',
      applicantId = '',
    } = applicationDetails || {}

    if (handleApplicationChange) {
      actionButtonClick = () =>
        handleApplicationChange(propertyId, applicationId, applicantId)
    } else {
      actionButtonClick = () => {
        history.push(
          urlTo('renterApplication', {
            propertyId,
            applicationId,
            applicantId,
          }),
        )
        window.location.reload()
      }
    }
    commentInfo = renderHeader()
  }

  const renderActionOnly = () => {
    return (
      <Box fontSize={4}>
        <strong>{note.author}&nbsp;</strong>
        <TextSpan as="span">{actionText}</TextSpan>
      </Box>
    )
  }

  if (messageDetails) {
    propertyAddress = messageDetails?.propertyAddress
    commentInfo = renderHeaderForTargetName()
  }

  if (type === noteActionType.phoneCall) {
    commentInfo = renderActionOnly()
    noteDate = (
      <Flex alignItems="end">
        <TextSpan mr={2}>{noteDate}</TextSpan>
        <PhoneRounded fontSize={theme.fontSizes.h6} />
      </Flex>
    )
  }

  if (viewingDetails) {
    const { viewingGUID = '', propertyGUID = '' } = viewingDetails || {}
    propertyAddress = `${viewingDetails?.propertyAddress} - ${formatTime(
      viewingDetails?.startTime,
      TIME_DAY_DATE,
    )}`
    if (teamSlug) {
      hasLink = true
      linkLabel = 'open viewing'
      actionButtonClick = () => {
        history.push(
          urlTo('highlightedViewing', { teamSlug, propertyGUID, viewingGUID }),
        )
        window.location.reload()
      }
    }
    commentInfo = renderHeaderForTargetName()
  }

  return (
    <>
      {!noteStyle ? (
        <>
          <div className={`application-note-container-flat ${componentClass}`}>
            {from === 'propertyViewing' && (
              <div className="note-header display-flex justify-content-space-between">
                <div className="display-flex flex-direction-column">
                  <div>{note.author}</div>
                </div>
                <div className="note-time">{durationText}</div>
              </div>
            )}

            <div className="display-flex">
              {from === 'propertyViewing' && (
                <img
                  src={actionImgSrc}
                  alt="application approval"
                  className="mr10 application-notes-icon"
                />
              )}
              {from === 'default' && renderHeader()}
              {from === 'propertyViewing' && (
                <div>
                  <span>
                    {actionText} {location && `(${location})`}
                  </span>
                </div>
              )}
            </div>
            {note.content && (
              <div
                className={`note-content-text font-size-14 ${infoTextClass}`}
              >
                &quot;{note.content}&quot;
              </div>
            )}
            {from === 'default' && (
              <div
                className={`mt10 font-size-14 ${infoTextClass} color-unchecked `}
              >
                {noteDate}
              </div>
            )}
            {from === 'default' && !!applicationDetails && (
              <div
                className={`mt10 font-size-14 ${infoTextClass} color-unchecked `}
              >
                Application:&nbsp;
                <Button
                  variant="linkBlueUnderlined"
                  onClick={() =>
                    handleApplicationChange(
                      applicationDetails.propertyId,
                      applicationDetails.applicationId,
                      applicationDetails.applicantId,
                    )
                  }
                >
                  {applicationDetails.propertyAddress}
                </Button>
              </div>
            )}
            <div className="display-flex flex-wrap-wrap mt10">
              {images.length > 0 &&
                images.map((image, index) => (
                  <div className="mb5">
                    <a
                      key={index}
                      href={image.URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="img-upload-preview-container"
                    >
                      <img src={image.URL} alt="preview" />
                    </a>
                  </div>
                ))}
            </div>
            {isAssignedToTeamMember && (
              <div className="note-content-text">
                <strong className="text-black">Assigned to </strong>
                {assignedTeamMemberName}
              </div>
            )}
          </div>
        </>
      ) : (
        <ActivityItemWithDot
          dateInfo={noteDate}
          commentInfo={commentInfo}
          propertyAddress={propertyAddress}
          commentContent={note?.content}
          hasLink={hasLink}
          actionButtonClick={actionButtonClick}
          actionButtonLabel={linkLabel}
        />
      )}
    </>
  )
}

export default NoteComponentFlatStyle
