import React from 'react'

import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import headerImg01 from 'app/assets/icons/header-img-01.png'
import TenantItem from 'app/bond_cover/renters/tenants/tenant'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'

const TenantsForm = ({
  isNewLease,
  currentLease,
  tenants,
  addTenant,
  deleteTenant,
  splitBond,
  requestID,
  backUrl,
}) => (
  <div className="two-col-box-flex-row sm64">
    <div className="col-first">
      <div className="mobile-only">
        <Link
          to={urlTo('bondCoverApplyProgress', { requestID })}
          className="btn btn-transparent btn-left xs-text-center wa"
        >
          <i className="icon-arrow-left left"></i>
          <span>Back</span>
        </Link>
      </div>
      <div className="ilustration-box">
        <img src={headerImg01} />
      </div>

      <h3 className="mb15">Tenants</h3>
      <p className="mb15">
        If there are other people listed on the lease you'll need to add them to
        this application to issue BondCover.{' '}
        <a
          className="gray-dark-color tdu"
          target="_blank"
          href="http://snug.com/help/articles/115000841933-How-much-does-a-BondCover-cost-"
          rel="noreferrer"
        >
          Fees apply.
        </a>
      </p>

      {tenants && tenants.length && (
        <div>
          <div className="float-right mb10">
            <button className="btn btn-round" onClick={() => addTenant()}>
              <i className="icon-add" />
            </button>
          </div>

          <div className="table mt40 mb30">
            <div className="cell-m-100">
              <h4>Listed ({tenants.length})</h4>
            </div>
            <div className="cell-m wsnw text-right">
              <h6>Household Bond: </h6>
              <h4 className="gray-light-color">
                ${currentLease.bond.toFixed(2)}
              </h4>
            </div>
          </div>

          <ul className="list list-unstyled">
            {tenants.map((i) => {
              return (
                <TenantItem
                  isNewLease={isNewLease}
                  tenant={i}
                  key={i.guidID}
                  currentLease={currentLease}
                  deleteTenant={deleteTenant}
                />
              )
            })}
          </ul>

          <div className="row pt25 mobile-form-button">
            <div className="col-sm-5 pb5 tablet-only">
              <Link
                to={urlTo('bondCoverApplyProgress', { requestID })}
                className="btn btn-transparent btn-left xs-text-center wa"
              >
                <i className="icon-arrow-left left"></i>
                <span>Back</span>
              </Link>
            </div>
            <div className="col-sm-7 pb5">
              {isNewLease ? (
                <Link to={`/bc?requestid=${currentLease.requestID}`}>
                  <button className="btn">
                    {tenants.length <= 1 ? 'Return to Application' : 'Save'}
                  </button>
                </Link>
              ) : tenants.length <= 1 ? (
                <Link to={`/bc?requestid=${currentLease.requestID}`}>
                  <button className="btn"> Return to Application </button>
                </Link>
              ) : (
                <button className="btn" onClick={() => splitBond()}>
                  Set Bond Splits
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <ReactTooltip className="tooltip" />
    </div>
  </div>
)

export default TenantsForm
