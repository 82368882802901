import { getViewing } from 'app/services/http/viewings'

const actions = {
  ENTER: 'MOBILE_VIEWING_DETAILS_PAGE/enter',
  START_VIEWING_LOADING: 'MOBILE_VIEWING_DETAILS_PAGE/start-viewing-loading',
  VIEWING_LOADED: 'MOBILE_VIEWING_DETAILS_PAGE/viewing-loaded',
  VIEWING_LOADING_ERROR: 'MOBILE_VIEWING_DETAILS_PAGE/viewing-loading-error',
}

// action creators
const enterPage = () => ({ type: actions.ENTER })
const startViewingLoading = () => ({ type: actions.START_VIEWING_LOADING })
const viewingLoaded = (viewing) => ({ type: actions.VIEWING_LOADED, viewing })
const viewingLoadingError = (error) => ({
  type: actions.VIEWING_LOADING_ERROR,
  error,
})

export const actionFactory =
  (dispatch, actionCreatorFn) =>
  (...args) =>
    dispatch(actionCreatorFn(...args))

export const actionCreators = {
  enterPage,
  loadViewing: startViewingLoading,
  viewingLoaded,
  viewingLoadingError,
}

// actions thunk
export const fetchViewing = (teamID, viewingID) => {
  return (dispatch) => {
    dispatch(startViewingLoading())
    getViewing(teamID, viewingID)
      .then((viewing) => {
        dispatch(viewingLoaded(viewing))
      })
      .catch((err) => {
        dispatch(viewingLoadingError(err))
      })
  }
}

const defaultState = {
  viewing: null,
  viewingLoadingStates: {
    loading: false,
    error: null,
  },
}

export function mobileViewingDetails(state = defaultState, action) {
  const { type } = action
  switch (type) {
    case actions.ENTER:
      return {
        ...state,
        viewing: null,
      }
    case actions.START_VIEWING_LOADING:
      return {
        ...state,
        viewingLoadingStates: {
          loading: true,
          error: null,
        },
      }
    case actions.VIEWING_LOADED: {
      const { viewing } = action
      return {
        ...state,
        viewing,
        viewingLoadingStates: {
          loading: false,
          error: null,
        },
      }
    }
    case actions.VIEWING_LOADING_ERROR: {
      const { error } = action
      return {
        ...state,
        viewingLoadingStates: {
          loading: false,
          error,
        },
      }
    }
    default:
      return { ...state }
  }
}
