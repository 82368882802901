import React from 'react'

import { Link } from 'react-router-dom'

import { history } from 'app/shared_components/router'
import Spinner from 'app/sm/common/spinner'
import { urlTo } from 'app/sm/helpers'
import PropertyCard from 'app/sm/recently_viewed/components/property_card'

const isRecentlyViewed = () => /history/.test(history.location.pathname)

class RecentlyViewed extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchProperties()
  }

  render() {
    const { currentUser, properties, spinner = false } = this.props

    return spinner ? (
      <Spinner />
    ) : (
      <div>
        <div className="section-title">
          <div className="left">
            <h3>{isRecentlyViewed() ? 'History' : 'Saved Properties'}</h3>
          </div>
          <div className="right" />
        </div>
        <div className="sm-properties-card-list info-type">
          {properties.map((property, i) => (
            <PropertyCard
              key={i}
              property={property}
              currentUser={currentUser}
              addToSavedProperty={this.props.addToSavedProperty}
            />
          ))}
        </div>
      </div>
    )
  }
}

export default RecentlyViewed
