import React from 'react'

import styled from 'styled-components'

const BankruptcyRecords = styled.div`
  padding-top: 0px;
`
const Label = styled.div`
  justify-content: space-between;
  font-size: 14px;
  line-height: 1.6;
`
const Title = styled.p`
  color: #22212a;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
`
const BankruptcyRecord = styled.div`
  justify-content: space-between;
  font-size: 14px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f4f4f4;
`

const BankruptcyRecordsInfo = ({ title = '', bankruptcyRecordsData = [] }) => {
  return (
    <BankruptcyRecords>
      <Title>{title}</Title>
      {bankruptcyRecordsData.length > 0 &&
        bankruptcyRecordsData.map((info, index) => {
          const {
            Association = '',
            BankruptcyDate = '',
            BankruptcyType = '',
            CoBorrower = '',
            DischargeStatus = '',
            DischargeDate = '',
            Notes = '',
            ProceedingsNo = '',
          } = info
          return (
            <BankruptcyRecord key={index}>
              {Association && <Label>Association: {Association}</Label>}
              {BankruptcyDate && (
                <Label>Bankruptcy Date: {BankruptcyDate}</Label>
              )}
              {BankruptcyType && (
                <Label>Bankruptcy Type: {BankruptcyType}</Label>
              )}
              {CoBorrower && <Label>Co-Borrower: {CoBorrower}</Label>}
              {DischargeStatus && (
                <Label>Discharge Status: {DischargeStatus}</Label>
              )}
              {DischargeDate && <Label>Discharge Date: {DischargeDate}</Label>}
              {Notes && <Label>Notes: {Notes}</Label>}
              {ProceedingsNo && <Label>Proceedings No: {ProceedingsNo}</Label>}
            </BankruptcyRecord>
          )
        })}
    </BankruptcyRecords>
  )
}

export default BankruptcyRecordsInfo
