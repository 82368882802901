import React, { useContext } from 'react'

import { accountsBaseUrl } from 'config/env'
import { isValidNumber } from 'libphonenumber-js'
import { useHistory } from 'react-router-dom'

import { AlertInfo } from 'app/components/design-system-components/AlertInfo'
import Box from 'app/components/design-system-components/Box'
import {
  InputField,
  TelField,
} from 'app/components/design-system-components/inputs'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { checkAccountExistence } from 'app/services/http/session'
import {
  FIELD_EMAIL,
  FIELD_MOBILE,
  STEP_ENTER_DETAILS,
  STEP_ENTER_EMAIL,
  STEP_ENTER_MOBILE,
  STEP_ENTER_PASSWORD,
} from 'app/session/join/join-snug.constants'
import { JoinSnugContext } from 'app/session/join/JoinContext'
import { BaseForm } from 'app/session/join/partials'
import StyledFieldWrapper from 'app/session/join/partials/styled_field'
import {
  validateEmail,
  validateMobile,
} from 'app/shared_components/validations'
import { normaliseStr } from 'app/utils/strings/helper'
import {
  NEW_LOGIN_INFO_NOTICE,
  NEW_LOGIN_LEARN_MORE_LINK,
} from 'app/utils/text/helpers'

const CheckMobileOrEmail = () => {
  const history = useHistory()

  const {
    currentStep,
    setCurrentStep,
    accountCheckedKey,
    setAccountCheckedKey,
    setError,
    registerToken,
    internationalMobilesAllowed,
    gCaptchaRef,
    prefillDetails,
  } = useContext(JoinSnugContext)

  const initialValues = {
    [FIELD_EMAIL]: prefillDetails[FIELD_EMAIL] || '',
    [FIELD_MOBILE]: prefillDetails[FIELD_MOBILE] || '',
  }

  if (accountCheckedKey.name) {
    initialValues[accountCheckedKey.name] = accountCheckedKey.value
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setError('')

    const submitKey = {
      [STEP_ENTER_MOBILE]: FIELD_MOBILE,
      [STEP_ENTER_EMAIL]: FIELD_EMAIL,
    }
    values[submitKey[currentStep]] = normaliseStr(
      values[submitKey[currentStep]],
    )

    const redirectToSocialLogin = (provider) => {
      return () => {
        window.location = accountsBaseUrl + provider
      }
    }

    const actionMap = {
      password: () => setCurrentStep(STEP_ENTER_PASSWORD),
      register: () => setCurrentStep(STEP_ENTER_DETAILS),
      socialLoginGoogle: redirectToSocialLogin('google'),
      socialLoginFacebook: redirectToSocialLogin('facebook'),
    }
    gCaptchaRef?.current?.executeAsync().then((value) => {
      checkAccountExistence({
        [submitKey[currentStep]]: values[submitKey[currentStep]],
        registrationToken: registerToken,
        gRecaptchaResponse: value,
      })
        .then((res) => {
          if (!Object.keys(actionMap).includes(res.NextStep)) {
            setError('Not able to continue!')
            return
          }

          setAccountCheckedKey({
            name: submitKey[currentStep],
            value: values[submitKey[currentStep]],
          })

          actionMap[res.NextStep]()
        })
        .catch((err) => {
          setSubmitting(false)

          setError(err.message || 'Failed to process')
        })
    })
  }

  const _validateMobile = (value) => {
    if (!value) return []

    if (internationalMobilesAllowed) {
      return isValidNumber(value) ? [] : ['Please enter valid mobile number']
    }
    return validateMobile(value)
  }

  const validate = (values) => {
    return formValidationBuilder((field, val) => {
      if (field === FIELD_MOBILE && currentStep === STEP_ENTER_MOBILE) {
        return {
          required:
            !val && 'Please enter your mobile number, e.g +61 412 345 678',
          invalid:
            val &&
            _validateMobile(val)[0] &&
            'Please enter valid mobile number',
        }
      } else if (field === FIELD_EMAIL && currentStep === STEP_ENTER_EMAIL) {
        return {
          required: !val && 'Please enter your email',
          invalid:
            val && validateEmail(values.email)[0] && 'Please enter valid email',
        }
      }

      return {}
    })(values)
  }

  const bodyRenderer = () => {
    if (currentStep === STEP_ENTER_MOBILE) {
      return (
        <StyledFieldWrapper id={FIELD_MOBILE} name={FIELD_MOBILE}>
          <TelField
            name={FIELD_MOBILE}
            placeholder="e.g +61 412 345 678"
            onlyCountries={['au']}
            defaultCountry={'au'}
            international={internationalMobilesAllowed}
            height="50px"
          />
        </StyledFieldWrapper>
      )
    }

    return (
      <>
        <Box mb={theme.space[2]}>
          <AlertInfo
            message={NEW_LOGIN_INFO_NOTICE}
            learnMoreLink={NEW_LOGIN_LEARN_MORE_LINK}
          />
        </Box>
        <StyledFieldWrapper id={FIELD_EMAIL} name={FIELD_EMAIL}>
          <InputField
            id={FIELD_EMAIL}
            name={FIELD_EMAIL}
            inputProps={{
              placeholder: 'Email address',
              type: 'email',
              autoComplete: 'username',
            }}
            height="50px"
            autoFocus
          />
        </StyledFieldWrapper>
      </>
    )
  }

  return (
    <BaseForm
      initialValues={initialValues}
      submitHandler={handleSubmit}
      validator={validate}
      bodyRenderer={bodyRenderer}
    />
  )
}

export default CheckMobileOrEmail
