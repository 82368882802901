import React from 'react'

import { Wrapper } from '@googlemaps/react-wrapper'
import { developmentEnv, googleAPIKey } from 'config/env'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { connect, Provider } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import TeamApplicationsContainer from 'app/agency/applications_list/connection'
import AgencyPropertiesApplyPage from 'app/agency/public_properties/connection'
import Stocklist from 'app/agency/stocklist/connection'
import ApplicationRevampSummary from 'app/application_revamp/App'
import AdminDashboard from 'app/bond_cover/admin/admin_dashboard_connection'
import AdminDecline from 'app/bond_cover/admin/requests/admin_decline_connection'
import RequestSummary from 'app/bond_cover/admin/requests/request_form_connection'
import AdminSearch from 'app/bond_cover/admin/search/search_form_connection'
// import SemanticUIFormDemo from 'app/bond_cover/agency/semantic_ui_form_demo';
import AgencyInviteAccept from 'app/bond_cover/agency/agency_invite_accept_connection'
import AgencyInvite from 'app/bond_cover/agency/agency_invite_connection'
import CreateAgencyForm from 'app/bond_cover/agency/create_agency_form_connection'
import AgencyDashboard from 'app/bond_cover/agency/dashboard/agency_dashboard_connection'
import EditAgencyForm from 'app/bond_cover/agency/edit_agency_form_connection'
import RegisterAgencyForm from 'app/bond_cover/agency/register_agency_form_connection'
import SupplierSourceForm from 'app/bond_cover/agency/supplier_source_edit_connection'
import Agent from 'app/bond_cover/managers/review/agent'
import BCJoinManagerForm from 'app/bond_cover/managers/review/bc_join_form_connection'
import BCRatingForm from 'app/bond_cover/managers/review/bc_rating_form_connection'
import BCReviewForm from 'app/bond_cover/managers/review/bc_review_form_connection'
import BCDeclineForm from 'app/bond_cover/managers/review/decline_form_connection'
import BCManagerSign from 'app/bond_cover/managers/signature/bc_manager_sign_connection'
import BCManagerComplete from 'app/bond_cover/managers/status/manager_complete_connection'
import BCManagerWait from 'app/bond_cover/managers/status/manager_wait_connection'
import OwnerDecline from 'app/bond_cover/managers/status/owner_decline'
import BCRentalHistoryAdd from 'app/bond_cover/renters/history/history_add_connection'
import BCRentalHistoryList from 'app/bond_cover/renters/history/history_list_connection'
import HistoryRatingForm from 'app/bond_cover/renters/history/history_rating_connection'
import ReferralCodeRedirect from 'app/bond_cover/renters/history/referral_code_redirect_connection'
import BCHubForm from 'app/bond_cover/renters/hub/hub_form_connection'
import BCAccountAccountForm from 'app/bond_cover/renters/identity/bank_account_connection'
import BCIdentityDocForm from 'app/bond_cover/renters/identity/identity_doc_connection'
import BCNewCreditCard from 'app/bond_cover/renters/identity/new_credit_card_connection'
import BCPay from 'app/bond_cover/renters/identity/payment_connection'
import BCIdentityStatusCheck from 'app/bond_cover/renters/identity/status_check'
import EndTooClose from 'app/bond_cover/renters/request/end_too_close'
import BCManagerForm from 'app/bond_cover/renters/request/manager_form_connection'
import BCRentalForm from 'app/bond_cover/renters/request/rental_form_connection'
import BCRenterSign from 'app/bond_cover/renters/signature/signature_connection'
import BCLiveRentFree from 'app/bond_cover/renters/status/live_rent_free_connection'
import BCRenterComplete from 'app/bond_cover/renters/status/request_complete_connection'
import BCRenterDeclined from 'app/bond_cover/renters/status/request_declined_connection'
import BCRenterIdFail from 'app/bond_cover/renters/status/request_id_fail_connection'
import BCRenterWait from 'app/bond_cover/renters/status/request_in_progress_connection'
import BCWithdrawConfirm from 'app/bond_cover/renters/status/request_withdraw_confirm'
import BCRenterWithdraw from 'app/bond_cover/renters/status/request_withdraw_connection'
import BCBondSplitsForm from 'app/bond_cover/renters/tenants/bond_splits_form_connection'
import BCLeaseeBankAccount from 'app/bond_cover/renters/tenants/identity/bank_account_connection'
import BCLeaseeIdentityDoc from 'app/bond_cover/renters/tenants/identity/identity_doc_connection'
import BCLeaseeSignature from 'app/bond_cover/renters/tenants/identity/signature_connection'
import BCLeaseeJoin from 'app/bond_cover/renters/tenants/leasee_join_connection'
import BCLeaseeSign from 'app/bond_cover/renters/tenants/leasee_sign_connection'
import BCLeaseeStart from 'app/bond_cover/renters/tenants/leasee_start_connection'
import BCTenantForm from 'app/bond_cover/renters/tenants/tenant_form_connection'
import BCTenantsForm from 'app/bond_cover/renters/tenants/tenants_form_connection'
import StyleGuideDemo from 'app/components/standard_style_guide'
import StandardFormDemo from 'app/components/stardard_form_demo'
import {
  BGC_ANNIVERSARY_UNSUBSCRIBE_PATH,
  PROPERTY_MATCH_UNSUBSCRIBE_PATH,
  RENTAL_LEDGER_UNSUBSCRIBE_PATH,
} from 'app/constants/unsubscribe.constants'
import Dashboard from 'app/dashboard/dashboard_connection'
import ManagerBCDashboard from 'app/dashboard/manager_bc_dashboard_connection'
import ManagerDashboard from 'app/dashboard/manager_dashboard_connection'
import RenterDashboard from 'app/dashboard/renter_dashboard_connection'
import ShareWithOwner from 'app/dashboard/share_with_owner'
import TeamOverview from 'app/dashboard/team_overview_connection'
import UnsubscribeContainer from 'app/dashboard/unsubscribe_page'
import UnsubscribeSmsContainer from 'app/dashboard/unsubscribe_sms'
import ApplicationMergeApprovePage from 'app/features/teams/applications/merge-applications/MergeApproveMessage'
import BCQuote from 'app/landing/bondcover_quote_connection'
import DirectConnectCallBack from 'app/landing/direct_connect/connection'
import EstimatedRefund from 'app/landing/estimated_refund'
import ApplicationReportPDF from 'app/match/applicationReportPDF/connection'
import ApplyConfirmationSummary from 'app/match/apply/application_confirmation/connection'
import ApplyConfirmationBallot from 'app/match/apply/application_confirmation_ballot/connection'
import ApplicationSummary from 'app/match/apply/application_summary_before_submit/application_summary_before_submit_connection'
import LeadToSnug from 'app/match/leadToSnug/connection'
import LeaseAttachmentContainer from 'app/match/leaseAttachment/connection'
import ManagerRentalRatingForm from 'app/match/managerRentalReview/manager_rental_rating_connection'
import OwnerPropertyDisclosuresPage from 'app/match/ownerpropertydisclosures'
import PropertyReportContainer from 'app/match/propertyReport/connection'
import MatchRatingForm from 'app/match/rental_reference/rating_form/connection'
import SelfServiceRegistrationSuccess from 'app/match/selfServiceRegistration/success.jsx'
import AttendeeViewingConfirmation from 'app/match/viewings/attendee_viewing_confirmation/attendee_viewing_confirmation_connection'
import RegisterViewingConfimation from 'app/match/viewings/enquiry_viewing_register/register_viewing_connection'
import AgencyPublicViewings from 'app/pages/apply/teams/checkin/connection'
import IFrameNotAllowed from 'app/pages/IFrameNotAllowed'
import PersonalReferenceSurveyPage from 'app/pages/personalReference/survey'
import PersonalReferenceSurveyShortCode from 'app/pages/personalReference/survey/connection'
import StreamLineSSTeamContainer from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_connection'
import { STREAM_LINE_TEAM_URL } from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_const'
import { SystemMaintenancePage } from 'app/pages/SystemMaintenance'
import TeamsPages from 'app/pages/teams'
import OnboardingPage from 'app/pages/teams/onboarding/connection'
import Onlist from 'app/pages/teams/onlist/connection'
import TeamRentalReferences from 'app/pages/teams/rental-references/connection'
import RRToLiteLanding from 'app/pages/teams/rental-references/connection_rr_to_lite'
import { PropertyViewingsPage } from 'app/pages/teams/viewings/connection'
import Theme from 'app/pages/teams/viewings-mobile/theme-styled-components'
import AllNewViewingRuns from 'app/pages/teams/viewings-run/components/all-runs-connection'
import NewViewingsRunPreview from 'app/pages/teams/viewings-run/components/preview-connection'
import NewViewingsRun from 'app/pages/teams/viewings-run/connection'
import ProfileBuildSuccess from 'app/profile/profile_build_success/profile_build_success_connection'
import OtherIdentityDoc from 'app/profile/renter/identity_doc/other_identity_doc/connection'
import AboutUs from 'app/session/bc_profile/AboutUs'
import BCProfileAccount from 'app/session/bc_profile/account_connection'
import BCProfileAvatar from 'app/session/bc_profile/avatar_container'
import BCProfileBank from 'app/session/bc_profile/bank_connection'
import BCPasswordChange from 'app/session/bc_profile/change_password_connection'
import BCProfileCreditCards from 'app/session/bc_profile/credit_cards_connection'
import BCProfileNewPassword from 'app/session/bc_profile/new_password_connection'
import BCProfile from 'app/session/bc_profile/profile_connection'
import ProfileUpdate from 'app/session/bc_profile/profile_update/profile_update_container'
import JoinPages from 'app/session/join/JoinPages'
import ForgotEmail from 'app/session/login_components/forgotten_email'
import NewPassword from 'app/session/login_components/new_password_form_connection'
import Reset from 'app/session/login_components/reset'
import MobileVerification from 'app/session/register_components/mobile_verification'
import ConfirmRegister from 'app/session/register_components/register_confirm'
import UpdateRegisteredUserPage from 'app/session/register_components/UpdateRegisteredUserPage'
import App from 'app/shared_components/app'
import { LoadingScreen } from 'app/shared_components/loading_screen'
import NotFound404 from 'app/shared_components/not_found_404/not_found_404'
import RequestAppAccess from 'app/shared_components/request_app_access/connection'
import {
  AuthRoute,
  ProtectedRoute,
  ScrollTop,
} from 'app/shared_components/route_util'
import AdFlow from 'app/sm/advertise_flow/connection'
import AdvertisementForm from 'app/sm/advertisements/advertisement_components/advertisement_form/advertisement_form_connection'
import ConfirmationScreen from 'app/sm/advertisements/advertisement_components/advertisement_form/confirmation_screen'
import AlternateOwnerProfile from 'app/sm/alternate_owner_profile/owner_profile_connection'
import MyApplications from 'app/sm/applications/applications_connection'
import ShareApplicationConfirmation from 'app/sm/applications/shareApplication/application_owner_confirmation_connection'
import Apply from 'app/sm/apply/apply_container'
import ApplyAnywhere from 'app/sm/apply_anywhere/apply_anywhere_connection'
import ApplyJointConfirmationPage from 'app/sm/apply_joint/confirmation_page_connection'
import AskQuestion from 'app/sm/ask_question/ask_question_connection'
import HowBGCheckWorks from 'app/sm/background_check/how_bgc_works'
import IdentityConfirmation from 'app/sm/background_check/identity_confirmation/connection'
import IdentityDetails from 'app/sm/background_check/identity_details/connection'
import IdentityDocuments from 'app/sm/background_check/identity_documents/identity_documents_connection'
import IdentityOverview from 'app/sm/background_check/identity_overview/connection'
import IdentityPay from 'app/sm/background_check/identity_pay/connection'
import Chat from 'app/sm/chat/chat_connection'
import Inbox from 'app/sm/chat/components/inbox'
import Thanks from 'app/sm/common/thanks'
import TeamContactList from 'app/sm/contact_list/connection'
import Documents from 'app/sm/documents/connection'
import EmergencyContactAdd from 'app/sm/emergency_contact/emergency_contact_add_connection'
import EmergencyContactForm from 'app/sm/emergency_contact/emergency_contact_form_connection'
import Employment from 'app/sm/employment/connection'
import EmploymentList from 'app/sm/employment/employment_history_list_connection'
import EmploymentVerifyConfirmation from 'app/sm/employment/employment_verify_confirmation'
import EmploymentVerify from 'app/sm/employment/employment_verify_container'
import { pathsSchemas, routes, urlIds, urlTo } from 'app/sm/helpers'
import OwnerList from 'app/sm/inspections/inspections_connection'
import PostViewingFeedback from 'app/sm/inspections/post_viewing_feedback/post_viewing_feedback_connection'
import RenterUpdateOnsiteViewing from 'app/sm/inspections/renter_update_onsite_viewing'
import Landing from 'app/sm/landing/landing_connection'
import ManagerViewings from 'app/sm/manager_viewings/manager_viewings_connection'
import OaTenant from 'app/sm/oa_tenant/oa_tenant_connection'
import OnListDetailsContainer from 'app/sm/onlist_details/connection'
import PersonalReferenceAdd from 'app/sm/personal_reference/personal_reference_add_connection'
import PersonalReferenceForm from 'app/sm/personal_reference/personal_reference_form_connection'
import AddPetDetails from 'app/sm/pets_details/pets_details_add_connection'
import PetsDetails from 'app/sm/pets_details/pets_details_list_connection'
import PhDeposit from 'app/sm/ph_deposit/ph_deposit_connection'
import PluginsConnection from 'app/sm/plugins/plugins_connection'
import GetStarted from 'app/sm/ppp/property_components/get-started/connection'
import Preferences from 'app/sm/ppp/property_components/preferences/connection'
import PropertyForm from 'app/sm/ppp/property_components/property_form/property_form_connection'
import PublishingOptions from 'app/sm/ppp/property_components/PublishingOptions/connection'
import Share from 'app/sm/ppp/property_components/share/connection'
import PropertyViewings from 'app/sm/ppp/property_components/viewings/connection'
import ProgressApplication from 'app/sm/progress_application/connection'
import ProofOfIncome from 'app/sm/proof_of_income/proof_of_income_connection'
import PropertyChat from 'app/sm/property_chat/property_chat_connection'
import PropertyDashboard from 'app/sm/property_dashboard/property_dashboard_connection'
import PropertyDetails from 'app/sm/property_details/property_details_connection'
import PropertyNotAvailable from 'app/sm/property_details/property_not_available'
import PropertyDocuments from 'app/sm/property_documents/property_documents_connection'
import PropertyLeaseDetails from 'app/sm/property_lease_details/property_lease_details_connection'
import PropertyMaintenance from 'app/sm/property_maintenance/property_maintenance_connection'
import PropertyTodos from 'app/sm/property_todos/property_todos_connection'
import PropertyTransactions from 'app/sm/property_transactions/property_transactions_connection'
import RecentlyViewed from 'app/sm/recently_viewed/recently_viewed_connection'
import RemarketProperty from 'app/sm/remarketing/remarketing_connection'
import ApplyAnywhereApplication from 'app/sm/renter_applications/apply_anywhere_application'
import ApplyAnywhereEmailConfirmation from 'app/sm/renter_applications/apply_anywhere_email_confirmation'
import NewRenterApplication from 'app/sm/renter_applications/renter_application_detailed/new_renter_application_connection'
import RenterApplications from 'app/sm/renter_applications/renter_applications_connection'
import RenterBackgroundCheckReport from 'app/sm/renter_background_check_report/connection'
import Review from 'app/sm/review/review_connection'
import SavedProperties from 'app/sm/saved_properties/saved_properties_connection'
import SignLease from 'app/sm/sign_lease/sign_lease_connection'
import SignatureSign from 'app/sm/signature_sign/signature_sign_connection'
import AddStudentDetails from 'app/sm/student_details/student_details_add_connection'
import StudentDetails from 'app/sm/student_details/student_details_list_connection'
import TeamContact from 'app/sm/team_contact/connection'
import Branding from 'app/sm/team_viewing_preference/setup/Branding'
import LetsGetStarted from 'app/sm/team_viewing_preference/setup/letsGetStarted'
import StartReceivingApps from 'app/sm/team_viewing_preference/setup/startReceivingApps'
import TeamMembers from 'app/sm/team_viewing_preference/setup/TeamMembers'
import Viewings from 'app/sm/viewings/viewings_connection'
import ViewingNewRunContainer from 'app/sm/viewings_new_run/connection'
import ViewingRunDashboard from 'app/sm/viewings_new_run/dashboard/connection'
import ViewingRunPreview from 'app/sm/viewings_new_run/preview/connection'
import { persistor } from 'app/store'
import { isTeamMemberNotALeasingAgent } from 'app/utils/system/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import { RR_TO_LITE_URL_PART } from 'app/utils/url/helpers'
import { FeatureFlag } from 'config/features'
import 'react-toastify/dist/ReactToastify.css'

// History
export const history = createBrowserHistory()

// Added from https://github.com/ReactTraining/react-router/issues/2144#issuecomment-150939358
// To fix pushing page to top when changing routes.
history.listen((location) => {
  // Use setTimeout to make sure this runs after React Router's own listener
  setTimeout(() => {
    // Keep default behavior of restoring scroll position when user:
    // - clicked back button
    // - clicked on a link that programmatically calls `history.goBack()`
    // - manually changed the URL in the address bar (here we might want
    // to scroll to top, but we can't differentiate it from the others)
    if (location.action === 'POP') {
      return
    }
    // In all other cases, check fragment/scroll to top
    let hash = window.location.hash
    if (hash && hash !== '#_=_') {
      let element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    } else {
      window.scrollTo(0, 0)
    }
  })
})

const mapStateToProps = ({ session, core }) => ({
  currentAgency: session.currentAgency,
  maintenanceMessageConfig: core.maintenanceMessageConfig,
})

const mapDispatchToProps = () => ({})

// Router
const Index = ({ store, currentAgency, maintenanceMessageConfig }) => {
  let isNotALeasingAgent = isTeamMemberNotALeasingAgent(currentAgency)

  const isDevEnv = developmentEnv()

  if (window.self !== window.top) {
    return <IFrameNotAllowed />
  }

  const { forceMaintenancePage } = maintenanceMessageConfig || {}

  if (forceMaintenancePage) return <SystemMaintenancePage />

  const curQueries = window.location.search

  return (
    // todo: move redux provider on the top level of the app
    <Provider store={store}>
      <Wrapper apiKey={googleAPIKey} libraries={['geometry']}>
        <PersistGate loading={null} persistor={persistor}>
          <Theme>
            <ConnectedRouter history={history}>
              <Switch>
                <Redirect
                  exact
                  from="/register"
                  to={{
                    pathname: urlTo(urlIds.join.index),
                    search: curQueries,
                  }}
                />
                <AuthRoute
                  path="/update-registration"
                  component={UpdateRegisteredUserPage}
                />
                <AuthRoute path="/join" component={JoinPages} />
                <AuthRoute path="/confirm" component={ConfirmRegister} />
                <Redirect
                  exact
                  from="/login"
                  to={{
                    pathname: urlTo(urlIds.join.index),
                    search: curQueries,
                  }}
                />
                <AuthRoute path="/verify" component={MobileVerification} />
                <AuthRoute
                  path={`/verify${textHelpers.RENTAL_REFERENCE_URL_STR}`}
                  component={MobileVerification}
                />

                <AuthRoute
                  path="/reset/mobile-notify"
                  component={ForgotEmail}
                />
                <AuthRoute path="/reset" component={Reset} />
                <AuthRoute path="/forgot" component={NewPassword} />
                <AuthRoute
                  path={`${STREAM_LINE_TEAM_URL}/:jobId?`}
                  component={StreamLineSSTeamContainer}
                />
                <AuthRoute
                  path={STREAM_LINE_TEAM_URL}
                  component={StreamLineSSTeamContainer}
                />
                {/* apply anywhere application details */}
                <Route
                  exact
                  path={[
                    '/applications/:applicationId/applyanywhere',
                    '/applications/:applicationId/applyanywhere/verify',
                  ]}
                  component={ApplyAnywhereApplication}
                />

                <Redirect exact from="/" to={urlTo(urlIds.join.index)} />
                <ScrollTop>
                  <App>
                    <Switch>
                      <ProtectedRoute
                        exact
                        path="/agency/register"
                        component={RegisterAgencyForm}
                      />
                      <ProtectedRoute
                        exact
                        path="/agency/accept"
                        component={AgencyInviteAccept}
                      />
                      <ProtectedRoute
                        exact
                        path={`/agency/accept/${textHelpers.RENTAL_REFERENCE_URL_STR}`}
                        component={AgencyInviteAccept}
                      />
                      <ProtectedRoute
                        exact
                        path="/agency"
                        component={AgencyDashboard}
                      />
                      <Route
                        exact
                        path="/agency/invite"
                        component={AgencyInvite}
                      />
                      <ProtectedRoute
                        exact
                        path="/agency/create"
                        component={CreateAgencyForm}
                      />
                      <Route
                        path="/estimatedrefund"
                        component={EstimatedRefund}
                      />
                      <Route path="/bondcover/quote" component={BCQuote} />
                      {/* TODO: Remove /dashboard route once it has been fully retired. */}
                      <ProtectedRoute
                        exact
                        path="/home/dashboard"
                        component={Dashboard}
                      />
                      <ProtectedRoute exact path="/bc" component={BCHubForm} />
                      <ProtectedRoute
                        exact
                        path="/bc/profile"
                        component={BCProfile}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/profile/change-password"
                        component={BCPasswordChange}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/profile/update"
                        component={ProfileUpdate}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/profile/account"
                        component={BCProfileAccount}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/profile/new-password"
                        component={BCProfileNewPassword}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/profile/bank"
                        component={BCProfileBank}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/profile/credit-cards"
                        component={BCProfileCreditCards}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/profile/avatar"
                        component={BCProfileAvatar}
                      />
                      <ProtectedRoute
                        exact
                        path={urlTo(urlIds.profileAboutUs)}
                        component={AboutUs}
                      />
                      <ProtectedRoute
                        path="/bc/cards"
                        component={BCNewCreditCard}
                      />
                      <ProtectedRoute
                        path="/bc/lease"
                        component={ReferralCodeRedirect}
                      />
                      <ProtectedRoute
                        path="/bc/rental"
                        component={BCRentalForm}
                      />
                      <ProtectedRoute
                        path="/bc/status"
                        component={EndTooClose}
                      />
                      <Route path="/bc/manager" component={BCManagerForm} />
                      <ProtectedRoute
                        path="/bc/tenants"
                        component={BCTenantsForm}
                      />
                      <ProtectedRoute
                        path="/bc/tenant"
                        component={BCTenantForm}
                      />
                      <ProtectedRoute
                        path="/bc/bondsplits"
                        component={BCBondSplitsForm}
                      />
                      <ProtectedRoute
                        path="/bc/renter/signature"
                        component={BCRenterSign}
                      />
                      <ProtectedRoute
                        path="/bc/identitydoc"
                        component={BCIdentityDocForm}
                      />
                      <ProtectedRoute
                        path="/bc/bankaccount"
                        component={BCAccountAccountForm}
                      />
                      <ProtectedRoute
                        path="/bc/identitycheck"
                        component={BCIdentityStatusCheck}
                      />
                      <ProtectedRoute path="/bc/pay" component={BCPay} />
                      <ProtectedRoute
                        path="/bc/renterwait"
                        component={BCRenterWait}
                      />
                      <ProtectedRoute
                        path="/bc/renterwithdraw"
                        component={BCRenterWithdraw}
                      />
                      <ProtectedRoute
                        path="/bc/withdrawconfirm"
                        component={BCWithdrawConfirm}
                      />
                      <ProtectedRoute
                        path="/bc/secondarywithdrawconfirm"
                        component={BCWithdrawConfirm}
                      />
                      <ProtectedRoute
                        path="/bc/rentercomplete"
                        component={BCRenterComplete}
                      />
                      <ProtectedRoute
                        path="/bc/renterdeclined"
                        component={BCRenterDeclined}
                      />
                      <ProtectedRoute
                        path="/bc/liverentfree"
                        component={BCLiveRentFree}
                      />
                      <ProtectedRoute
                        path="/bc/renteridfail"
                        component={BCRenterIdFail}
                      />
                      <Route path="/bc/leaseestart" component={BCLeaseeStart} />
                      <Route path="/bc/leaseejoin" component={BCLeaseeJoin} />
                      <ProtectedRoute
                        path="/bc/leaseesign"
                        component={BCLeaseeSign}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/secondary/leaseeBankAccount"
                        component={BCLeaseeBankAccount}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/secondary/leaseeIdentityDoc"
                        component={BCLeaseeIdentityDoc}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/secondary/leaseeSignature"
                        component={BCLeaseeSignature}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/rental-history"
                        component={BCRentalHistoryList}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/rental-history/add"
                        component={BCRentalHistoryAdd}
                      />
                      <ProtectedRoute
                        path="/bc/rental-history/edit/:referenceId"
                        component={BCRentalHistoryAdd}
                      />
                      <Route
                        path="/bc/rental-history/mananger-rating/:referenceId"
                        component={ManagerRentalRatingForm}
                      />
                      <Route
                        path="/bc/rental-history/rating/:referenceId/secret/:secretGUID"
                        component={HistoryRatingForm}
                      />
                      <Route
                        path="/sm/rental-history/rating/:referenceId/secret/:secretGUID"
                        component={MatchRatingForm}
                      />
                      <Route
                        path={`/sm/rental-history/rating/:referenceId/secret/:secretGUID/${textHelpers.RENTAL_REFERENCE_URL_STR}`}
                        component={MatchRatingForm}
                      />
                      <Route
                        path="/sm/personal-reference/survey/:referenceGUID/secret/:secretGUID"
                        component={PersonalReferenceSurveyPage}
                      />
                      <ProtectedRoute
                        path={
                          pathsSchemas[urlIds.manualPersonalReferenceSurvey]
                        }
                        component={PersonalReferenceSurveyPage}
                      />
                      <Route
                        path="/sm/p/s/:shortCode"
                        component={PersonalReferenceSurveyShortCode}
                      />
                      <Route
                        path="/sm/r/c/:shortCode"
                        component={MatchRatingForm}
                      />
                      <Route
                        path="/sm/rental-history/rating/:referenceId"
                        component={MatchRatingForm}
                      />
                      <Route
                        exact
                        path="/sm/rental-history/completed"
                        component={LeadToSnug}
                      />
                      <Route
                        exact
                        path="/sm/rental-history/completed/owner"
                        render={(props) => (
                          <LeadToSnug isOwner={true} {...props} />
                        )}
                      />
                      <Route
                        exact
                        path="/sm/personal-reference/completed"
                        render={(props) => (
                          <LeadToSnug
                            isOwner={true}
                            successMsg="Personal reference submitted"
                            thanksMsg="Thanks for submitting the Personal Reference!"
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/sm/owner-disclosure/completed"
                        render={(props) => (
                          <LeadToSnug
                            isOwner={true}
                            successMsg="Disclosures have been submitted"
                            thanksMsg="Thanks for submitting the disclosures!"
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/sm/rental-history/completed/create-lite-account"
                        component={SelfServiceRegistrationSuccess}
                      />
                      <Route path="/bc/review" component={BCReviewForm} />
                      <Route path="/bc/rating" component={BCRatingForm} />
                      <Route path="/bc/join" component={BCJoinManagerForm} />
                      <ProtectedRoute
                        path="/bc/managersign"
                        component={BCManagerSign}
                      />
                      <ProtectedRoute
                        path="/bc/managerwait"
                        component={BCManagerWait}
                      />
                      <ProtectedRoute
                        path="/bc/managercomplete"
                        component={BCManagerComplete}
                      />
                      <Route
                        path="/agency/bc/review"
                        component={BCReviewForm}
                      />
                      <Route
                        path="/agency/bc/rating"
                        component={BCRatingForm}
                      />
                      <Route
                        path="/agency/bc/join"
                        component={BCJoinManagerForm}
                      />
                      <ProtectedRoute
                        path="/bc/managersign"
                        component={BCManagerSign}
                      />
                      <ProtectedRoute
                        path="/bc/managerwait"
                        component={BCManagerWait}
                      />
                      <ProtectedRoute
                        path="/bc/managercomplete"
                        component={BCManagerComplete}
                      />
                      <ProtectedRoute
                        path="/agency/bc/managersign"
                        component={BCManagerSign}
                      />
                      <ProtectedRoute
                        path="/agency/bc/managerwait"
                        component={BCManagerWait}
                      />
                      <ProtectedRoute
                        path="/agency/bc/managercomplete"
                        component={BCManagerComplete}
                      />
                      <Route path="/bc/decline" component={BCDeclineForm} />
                      <Route path="/bc/ownerdecline" component={OwnerDecline} />
                      <Route
                        path="/agency/bc/decline"
                        component={BCDeclineForm}
                      />
                      <Route
                        path="/agency/bc/ownerdecline"
                        component={OwnerDecline}
                      />
                      <Route path="/bc/agent" component={Agent} />
                      <ProtectedRoute
                        path="/admin/search"
                        component={AdminSearch}
                      />
                      <ProtectedRoute
                        path="/admin/request"
                        component={RequestSummary}
                      />
                      <ProtectedRoute
                        path="/admin/decline"
                        component={AdminDecline}
                      />
                      <ProtectedRoute
                        exact
                        path="/admin"
                        component={AdminDashboard}
                      />
                      {/* <Route exact path='/admin/formdemo-semanticui' component={SementicUIFormDemo} /> */}
                      <ProtectedRoute
                        exact
                        path="/admin/agency/edit"
                        component={EditAgencyForm}
                      />
                      <ProtectedRoute
                        path="/admin/agency/edit/:agencyGUID"
                        component={EditAgencyForm}
                      />
                      <ProtectedRoute
                        path="/agency/supplier-source"
                        component={SupplierSourceForm}
                      />
                      <Route
                        exact
                        path="/sm/property/:propertyId"
                        component={PropertyDetails}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/apply"
                        key="1"
                        component={Apply}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/review"
                        component={Review}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/thanks"
                        component={Thanks}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/question"
                        component={AskQuestion}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/property/:propertyId/offer/:applicationId/"
                        component={OaTenant}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/offer/:applicationId/pay-holding-deposit"
                        component={PhDeposit}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/offer/:applicationId/sign-lease"
                        component={SignLease}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/offer/:applicationId/signature-sign"
                        component={SignatureSign}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/applications"
                        component={MyApplications}
                      />
                      <ProtectedRoute
                        path="/sm/applications/:applicationId/merge-approve"
                        component={ApplicationMergeApprovePage}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.applyApplication}
                        key="2"
                        component={Apply}
                      />
                      {FeatureFlag.Advertisement.isOn && (
                        <ProtectedRoute
                          path="/sm/advertise"
                          component={AdFlow}
                        />
                      )}
                      <ProtectedRoute path="/sm/landing" component={Landing} />
                      {/* <Route path='/apply/:slug' component={ApplyRedirect} /> */}
                      <Route
                        exact
                        path="/apply/p/:propertyId"
                        component={PropertyDetails}
                      />
                      <Route
                        exact
                        path={urlTo('propertyNotAvailable')}
                        component={PropertyNotAvailable}
                      />
                      <Route
                        exact
                        path="/sm/:teamSlug/property/404/not-available"
                        component={PropertyNotAvailable}
                      />
                      <Route
                        exact
                        path="/apply/join/:slug"
                        component={ApplyJointConfirmationPage}
                      />
                      <Redirect
                        from="/apply/agencies/:agencySlug"
                        to="/apply/:agencySlug"
                      />
                      <Route
                        exact
                        path="/apply/:agencySlug"
                        component={AgencyPropertiesApplyPage}
                      />
                      <Route
                        exact
                        path="/apply/:agencySlug/b/:buildingSlug"
                        component={AgencyPropertiesApplyPage}
                      />
                      <Route
                        exact
                        path="/apply/:agencySlug/checkin"
                        component={AgencyPublicViewings}
                      />
                      <Route
                        exact
                        path="/stocklist/:agencySlug"
                        component={Stocklist}
                      />
                      <ProtectedRoute
                        path="/sm/properties/alternate_profile"
                        component={AlternateOwnerProfile}
                      />
                      <ProtectedRoute
                        path="/sm/history"
                        component={RecentlyViewed}
                      />
                      <ProtectedRoute
                        path="/sm/wishlist"
                        component={SavedProperties}
                      />
                      <ProtectedRoute
                        path="/sm/income"
                        component={ProofOfIncome}
                      />
                      <ProtectedRoute
                        path="/sm/profilebuild"
                        component={ProfileBuildSuccess}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/dashboard"
                        component={PropertyDashboard}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/todos"
                        component={PropertyTodos}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/transactions"
                        component={PropertyTransactions}
                      />
                      <ProtectedRoute
                        path="/sm/background-check/overview"
                        component={IdentityOverview}
                      />
                      <ProtectedRoute
                        path="/sm/background-check/how-it-works"
                        component={HowBGCheckWorks}
                      />
                      <ProtectedRoute
                        path="/sm/background-check/details"
                        component={IdentityDetails}
                      />
                      <ProtectedRoute
                        path="/sm/background-check/details/add"
                        component={IdentityDetails}
                      />
                      <ProtectedRoute
                        path="/sm/background-check/:id/details"
                        component={IdentityDetails}
                      />
                      <ProtectedRoute
                        path="/sm/background-check/pay"
                        component={IdentityPay}
                      />
                      <ProtectedRoute
                        path="/sm/background-check/confirmation"
                        component={IdentityConfirmation}
                      />
                      {/* This route should be removed when there is no external reference link come to this page. */}
                      <Redirect
                        exact
                        from="/sm/rental-reputation"
                        to="/bc/profile"
                      />
                      <ProtectedRoute
                        path="/sm/rental-reputation/documents"
                        component={Documents}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/lease-details"
                        component={PropertyLeaseDetails}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/documents"
                        component={PropertyDocuments}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/profile/employment"
                        component={Employment}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/profile/employment-list"
                        component={EmploymentList}
                      />
                      <ProtectedRoute
                        path="/bc/profile/employment/edit"
                        component={EmploymentList}
                      />
                      <ProtectedRoute
                        exact
                        path="/bc/profile/employment/:id/"
                        component={Employment}
                      />
                      <Route
                        path="/bc/profile/employment/:id/confirm"
                        component={EmploymentVerify}
                      />
                      <Route
                        path="/bc/profile/employment/slug/:slug/confirm"
                        component={EmploymentVerify}
                      />
                      <Route
                        path="/sm/employment-history/rating/:id"
                        component={EmploymentVerify}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/student-details"
                        component={StudentDetails}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/student-details/add"
                        component={AddStudentDetails}
                      />
                      <ProtectedRoute
                        path="/sm/student-details/edit/:studentId"
                        component={AddStudentDetails}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/emergency-contact/add"
                        component={EmergencyContactForm}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/emergency-contact/edit/:contactId"
                        component={EmergencyContactForm}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/emergency-contact-details"
                        component={EmergencyContactAdd}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/applications/:applicationId/apply-confirmation"
                        component={ApplyConfirmationSummary}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/applications/:applicationId/apply-confirmation-ballot"
                        component={ApplyConfirmationBallot}
                      />
                      <Route
                        exact
                        path="/employment/:id/thanks-for-verify"
                        component={EmploymentVerifyConfirmation}
                      />
                      <Route
                        exact
                        path="/employment/slug/:slug/thanks-for-verify"
                        component={EmploymentVerifyConfirmation}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/maintenance"
                        component={PropertyMaintenance}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/chat"
                        component={PropertyChat}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/pets-details"
                        component={PetsDetails}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/pets-details/add"
                        component={AddPetDetails}
                      />
                      <ProtectedRoute
                        path="/sm/pets-details/edit/:petId"
                        component={AddPetDetails}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/personal-reference/add"
                        component={PersonalReferenceForm}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/personal-reference/edit/:personalRefId"
                        component={PersonalReferenceForm}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/personal-reference-details"
                        component={PersonalReferenceAdd}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/identity-documents"
                        component={IdentityDocuments}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/identity-documents/other"
                        component={OtherIdentityDoc}
                      />
                      <ProtectedRoute path="/sm/chat" component={Chat} />
                      <ProtectedRoute
                        exact
                        path="/sm/property/:propertyId/viewings"
                        component={PropertyViewings}
                      />
                      <ProtectedRoute
                        path="/sm/property/:propertyId/viewings/owner/temporary"
                        component={OwnerList}
                      />
                      <ProtectedRoute
                        path="/sm/viewings"
                        component={Viewings}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/viewings/newrun"
                        component={(props) => {
                          // return isViewingRunEnabled(props.match.params.teamSlug) ? <ViewingNewRunContainer {...props} /> : <NotFound404 {...props} />;
                          return <ViewingNewRunContainer {...props} />
                        }}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/viewings/preview"
                        component={(props) => {
                          // return isViewingRunEnabled(props.match.params.teamSlug) ? <ViewingRunPreview {...props} /> : <NotFound404 {...props} />;
                          return <ViewingRunPreview {...props} />
                        }}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/viewings/runs"
                        component={(props) => {
                          // return isViewingRunEnabled(props.match.params.teamSlug) ? <ViewingRunDashboard {...props} /> : <NotFound404 {...props} />;
                          return <ViewingRunDashboard {...props} />
                        }}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/viewings/runs/:viewingRunGUID"
                        component={(props) => {
                          // return isViewingRunEnabled(props.match.params.teamSlug) ? <ViewingRunPreview {...props} /> : <NotFound404 {...props} />;
                          return <ViewingRunPreview {...props} />
                        }}
                      />
                      <Route
                        path="/property/:propertyId/viewing/:viewingId/feedback/:feedbackId"
                        component={PostViewingFeedback}
                      />
                      <Route
                        path="/sm/vf/:shortCode"
                        component={PostViewingFeedback}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/onsite/:onsiteregistrantId"
                        component={RenterUpdateOnsiteViewing}
                      />
                      <ProtectedRoute
                        path="/sm/offers/:offerId/applications/:applciationId/accepted"
                        component={(match) => (
                          <ConfirmationScreen isForApplicationAccepted={true} />
                        )}
                      />
                      <ProtectedRoute
                        path="/home/overview"
                        component={RenterDashboard}
                      />
                      {/* Manage properties */}
                      <ProtectedRoute
                        path="/portfolio/overview"
                        key="own"
                        component={ManagerDashboard}
                      />
                      <ProtectedRoute
                        path="/teams/:teamSlug/portfolio/overview"
                        key="team"
                        component={ManagerDashboard}
                      />
                      <ProtectedRoute
                        path="/teams/:teamSlug/overview"
                        key="team"
                        component={TeamOverview}
                      />
                      <ProtectedRoute
                        path="/teams/:teamSlug/quickstart/lets-get-started"
                        key="team"
                        component={LetsGetStarted}
                      />
                      <ProtectedRoute
                        path="/teams/:teamSlug/quickstart/add-team-members"
                        key="team"
                        component={TeamMembers}
                      />
                      <ProtectedRoute
                        path="/teams/:teamSlug/quickstart/branding"
                        key="team"
                        component={Branding}
                      />
                      <ProtectedRoute
                        path="/teams/:teamSlug/quickstart/finished"
                        key="team"
                        component={StartReceivingApps}
                      />
                      {isNotALeasingAgent && (
                        <ProtectedRoute
                          path="/teams/:teamSlug/contacts/add"
                          key="team"
                          component={TeamContact}
                        />
                      )}
                      {isNotALeasingAgent && (
                        <ProtectedRoute
                          path="/teams/:teamSlug/contacts/edit/:contactGUID"
                          key="team"
                          component={TeamContact}
                        />
                      )}
                      {isNotALeasingAgent && (
                        <ProtectedRoute
                          path="/teams/:teamSlug/contacts"
                          key="team"
                          component={TeamContactList}
                        />
                      )}
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/viewings/new-viewing-run"
                        component={NewViewingsRun}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/viewings/new-viewing-run/preview"
                        component={NewViewingsRunPreview}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.viewingRuns.edit}
                        component={NewViewingsRun}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/viewings/new-viewing-run/preview/:viewingRunGUID"
                        component={NewViewingsRunPreview}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.viewingRuns.editPreview}
                        component={NewViewingsRunPreview}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/viewings/new-viewing-run/all"
                        component={AllNewViewingRuns}
                      />
                      <ProtectedRoute
                        path="/sm/application/:applicationId/progressapplication"
                        key="team"
                        component={ProgressApplication}
                      />
                      <ProtectedRoute
                        path={routes.leaseAttachmentManager}
                        key="team"
                        component={LeaseAttachmentContainer}
                      />
                      <Route
                        path="/sm/applications/:applicationId/offer/:agencyId/renter"
                        key="team"
                        component={LeaseAttachmentContainer}
                      />
                      <Route
                        path="/sm/offer/:slug/renter"
                        key="team"
                        component={LeaseAttachmentContainer}
                      />
                      <ProtectedRoute
                        path="/sm/properties/get-started"
                        key="own"
                        component={GetStarted}
                      />
                      <ProtectedRoute
                        path="/teams/:teamSlug/sm/properties/get-started"
                        key="team"
                        component={GetStarted}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/properties/viewings"
                        component={ManagerViewings}
                      />
                      <ProtectedRoute
                        path="/sm/properties/create"
                        key="own"
                        component={PropertyForm}
                      />
                      <ProtectedRoute
                        path="/teams/:teamSlug/sm/properties/create"
                        key="team"
                        component={PropertyForm}
                      />
                      <ProtectedRoute
                        path="/teams/:teamSlug/sm/properties/create-archived"
                        key="team"
                        component={PropertyForm}
                      />
                      <ProtectedRoute
                        path="/sm/properties/update/:id"
                        key="own"
                        component={PropertyForm}
                      />
                      <ProtectedRoute
                        path="/sm/properties/:id/offers/create"
                        key="own"
                        component={AdvertisementForm}
                      />
                      <ProtectedRoute
                        path="/sm/properties/:id/offers/update/:offerId/"
                        key="own"
                        component={AdvertisementForm}
                      />
                      <ProtectedRoute
                        path="/sm/properties/:id/offers/:offerId/publishing-options/update"
                        component={PublishingOptions}
                      />
                      <ProtectedRoute
                        path="/sm/properties/:offerId/preferences"
                        key="own"
                        component={Preferences}
                      />
                      <ProtectedRoute
                        path="/sm/properties/:offerId/viewings"
                        key="own"
                        component={PropertyViewings}
                      />
                      <ProtectedRoute
                        path="/sm/properties/share/:encodedParams"
                        key="own"
                        component={Share}
                      />
                      {/* Manage applications */}
                      <ProtectedRoute
                        exact
                        path="/sm/property/:propertyId/applications"
                        key="own"
                        component={RenterApplications}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/sm/property/:propertyId/applications"
                        key="team"
                        component={RenterApplications}
                      />
                      {isNotALeasingAgent && (
                        <ProtectedRoute
                          exact
                          path="/sm/property/:propertyId/applications/:applicationId"
                          key="own"
                          component={NewRenterApplication}
                        />
                      )}
                      {isNotALeasingAgent && (
                        <ProtectedRoute
                          exact
                          path="/sm/property/:propertyId/applications/:applicationId/print"
                          key="own"
                          component={ApplicationReportPDF}
                        />
                      )}
                      <Route
                        exact
                        path="/backgroundcheckreport"
                        key="own"
                        component={RenterBackgroundCheckReport}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/applications/:applicationId/applicant/:applicantId/backgroundcheckreport"
                        key="own"
                        component={RenterBackgroundCheckReport}
                      />
                      <Route
                        exact
                        path="/sm/property/:propertyId/applications/:applicationId/applicant/:applicantId"
                        key="own"
                        component={NewRenterApplication}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/property/:propertyId/applications/:applicationId/applicant/:applicantId/print"
                        key="own"
                        component={ApplicationReportPDF}
                      />
                      {isNotALeasingAgent && (
                        <ProtectedRoute
                          exact
                          path="/sm/property/:propertyId/applications/:applicationId/applicant/:applicantId/temporary"
                          key="own"
                          component={NewRenterApplication}
                        />
                      )}
                      <ProtectedRoute
                        exact
                        path="/sm/property/:propertyId/ownerreport"
                        component={PropertyReportContainer}
                      />
                      <Route
                        exact
                        path="/sm/propertyreport/:propertyreportId/owner"
                        component={PropertyReportContainer}
                      />
                      <Route
                        exact
                        path="/sm/propertyreport/:propertyreportId"
                        component={PropertyReportContainer}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/editonlistdetails"
                        component={OnListDetailsContainer}
                      />
                      {/* Manage BC */}
                      <ProtectedRoute
                        exact
                        path="/portfolio/dashboard"
                        component={ManagerBCDashboard}
                      />
                      <ProtectedRoute
                        path="/teams/:teamSlug/portfolio/dashboard"
                        component={AgencyDashboard}
                      />
                      <ProtectedRoute
                        path="/teams/:teamSlug/dashboard"
                        component={AgencyDashboard}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/viewings"
                        component={PropertyViewingsPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/:applicationGUID/share"
                        component={ShareWithOwner}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:agencyGUID/property/:propertyGUID"
                        component={RemarketProperty}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/applications"
                        component={TeamApplicationsContainer}
                      />
                      <ProtectedRoute
                        exact
                        path="/confirmemail"
                        component={ApplyAnywhereEmailConfirmation}
                      />
                      <ProtectedRoute
                        exact
                        path="/applyanywhere/:applyAnywhereGUID"
                        component={ApplyAnywhere}
                      />
                      <ProtectedRoute
                        exact
                        path="/apply"
                        component={ApplyAnywhere}
                      />
                      <ProtectedRoute
                        exact
                        path="/sm/applications/:applicationId/rentersummary"
                        component={ApplicationSummary}
                      />
                      <ProtectedRoute
                        exact
                        path="/applicationrevamp"
                        component={ApplicationRevampSummary}
                      />
                      <ProtectedRoute
                        exact
                        path="/teams/:teamSlug/propertyoffer/:offerGUID/onlist"
                        component={Onlist}
                      />
                      <Route
                        exact
                        path="/get-connected/:applicationSlug"
                        component={DirectConnectCallBack}
                      />
                      <Route exact path="/loading" component={LoadingScreen} />
                      <Route
                        exact
                        path="/unsubscribe-property/:propertyID"
                        component={UnsubscribeContainer}
                      />
                      <Route
                        exact
                        path="/unsubscribe-team/:teamID"
                        component={UnsubscribeContainer}
                      />
                      <Route
                        exact
                        path={`/${BGC_ANNIVERSARY_UNSUBSCRIBE_PATH}/`}
                        component={UnsubscribeContainer}
                      />
                      <Route
                        exact
                        path={`/${PROPERTY_MATCH_UNSUBSCRIBE_PATH}/`}
                        component={UnsubscribeContainer}
                      />
                      <Route
                        exact
                        path={`/${RENTAL_LEDGER_UNSUBSCRIBE_PATH}/`}
                        component={UnsubscribeContainer}
                      />
                      <Route
                        exact
                        path="/unsub/s/:slug"
                        component={UnsubscribeSmsContainer}
                      />
                      <Route exact path="/inbox" component={Inbox} />
                      <Route
                        exact
                        path="/register-viewing/viewings/:viewingID/message/:messageID"
                        component={RegisterViewingConfimation}
                      />
                      <Route
                        exact
                        path="/property-disclosures/:guid/token/:token/contact/:contact"
                        component={OwnerPropertyDisclosuresPage}
                      />
                      <Route
                        exact
                        path="/sm/a/c/:shortcode/:status"
                        component={ShareApplicationConfirmation}
                      />
                      <Route
                        exact
                        path="/request-app-access"
                        component={RequestAppAccess}
                      />
                      <Route
                        exact
                        path="/reg/v/:viewingID/:messageID/:sms"
                        component={RegisterViewingConfimation}
                      />
                      <Route
                        exact
                        path="/register-viewing/viewings/:viewingID"
                        component={RegisterViewingConfimation}
                      />
                      <Route
                        exact
                        path="/sm/viewing-confirmation/viewings/:viewingID/attendee/:attendeeGUID"
                        component={AttendeeViewingConfirmation}
                      />
                      <Route
                        exact
                        path="/sm/v/c/:shortcode"
                        component={AttendeeViewingConfirmation}
                      />
                      {isNotALeasingAgent && (
                        <ProtectedRoute
                          exact
                          path="/teams/:teamSlug/academy"
                          component={OnboardingPage}
                        />
                      )}
                      {isNotALeasingAgent && (
                        <ProtectedRoute
                          exact
                          path="/teams/:teamSlug/rental-references"
                          component={TeamRentalReferences}
                        />
                      )}
                      {/* {isNotALeasingAgent && ( */}
                      <Route
                        exact
                        path={`/${RR_TO_LITE_URL_PART}/rating/:rating/secret/:secretGUID`}
                        component={RRToLiteLanding}
                      />
                      {/* )} */}
                      <ProtectedRoute
                        exact
                        path={routes.rentalReferenceInboxFirstTime}
                        component={TeamRentalReferences}
                      />
                      {/* not accessible in production */}
                      {isDevEnv &
                      (
                        <ProtectedRoute
                          exact
                          path="/admin/formdemo"
                          component={StandardFormDemo}
                        />
                      )}
                      {isDevEnv &
                      (
                        <ProtectedRoute
                          exact
                          path="/admin/styleguide"
                          component={StyleGuideDemo}
                        />
                      )}
                      {isDevEnv &
                      (
                        <ProtectedRoute
                          exact
                          path="/teams/:teamSlug/plugin/:pluginName"
                          component={PluginsConnection}
                        />
                      )}
                      <ProtectedRoute
                        path="/teams/:teamSlug"
                        component={TeamsPages}
                      />
                      <Route path="/404" component={NotFound404} />
                      {/*TODO: change it to the root or any other primary page instead of login after enhancing and organizing the routes*/}
                      <Redirect to={urlTo(urlIds.join.index)} />
                    </Switch>
                  </App>
                </ScrollTop>
              </Switch>
            </ConnectedRouter>
          </Theme>
        </PersistGate>
      </Wrapper>
    </Provider>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
