import { connect } from 'react-redux'

import {
  fetchDeclinedReason,
  fetchRequestSummary,
  getSecondaryTenantSummary,
} from 'app/bond_cover/bond_cover_actions'
import RenterDeclined from 'app/bond_cover/renters/status/request_declined'

const mapStateToProps = ({ bondCover, session }) => ({
  apiError: bondCover.error,
  currentLease: bondCover.currentLease,
  manager: bondCover.manager,
  declined: bondCover.declined,
})

const mapDispatchToProps = (dispatch) => ({
  fetchDeclinedReason: (requestID) => fetchDeclinedReason(requestID),
  fetchRequestSummary: (requestID) => fetchRequestSummary(requestID),
  getSecondaryTenantSummary: (requestID) =>
    getSecondaryTenantSummary(requestID),
})

export default connect(mapStateToProps, mapDispatchToProps)(RenterDeclined)
