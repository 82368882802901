import React from 'react'

import { pageLimit, propertyStatusCode } from 'app/sm/helpers'

class PropertyPageFooter extends React.Component {
  changePage = (cursor, limit) => {
    const {
      propertyStatus,
      searchText,
      fetchProperties,
      teamGuid = '',
      propertyManagerFilter,
      applicationYesNoFilter = 'any',
    } = this.props
    fetchProperties(
      teamGuid,
      cursor,
      propertyStatusCode[propertyStatus],
      searchText,
      limit,
      propertyManagerFilter,
      applicationYesNoFilter,
    )
  }

  render() {
    const {
      responseMetadata = {},
      className = '',
      firstPageCursor = {},
    } = this.props
    const showFirstPage =
      firstPageCursor.next_cursor !== responseMetadata.next_cursor
    return (
      <div className={`${className} ml20`}>
        {showFirstPage && (
          <button
            className="textButton wa ha mr30 mt10 mb10"
            onClick={() => this.changePage()}
          >
            First page
          </button>
        )}
        {responseMetadata.next_cursor && (
          <button
            className="textButton wa ha btnmr mt10 mb10"
            onClick={() =>
              this.changePage(responseMetadata.next_cursor, pageLimit)
            }
          >
            Next page
          </button>
        )}
      </div>
    )
  }
}

export default PropertyPageFooter
