import React from 'react'

import styled from 'styled-components'

import { Button, Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const StyledModalFooter = styled.div`
  margin-top: 35px;
`

const ModalFooter = (props) => {
  const { moveDisabled } = props

  return (
    <StyledModalFooter>
      <Flex alignItems="center" {...props}>
        <Button
          type="button"
          variant="solidSecondary"
          flex="1 1 50%"
          onClick={props.toggleModal}
          leading={false}
          mr={theme.space[3] + 'px'}
        >
          Cancel
        </Button>

        <Button type="submit" flex="1 1 70%">
          Move
        </Button>
      </Flex>
    </StyledModalFooter>
  )
}

export default ModalFooter
