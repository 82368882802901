import React, { useState } from 'react'

import { connect } from 'react-redux'
import styled from 'styled-components'
import { layout, typography } from 'styled-system'

import {
  Box,
  Button,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import { TooltipLikeModal } from 'app/components/design-system-components/modals/ToolTipLikeModal'
import {
  keySetStatusConfig,
  keySetStatuses,
  keySetTypesOptions,
} from 'app/constants/key-logger.constants'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledTrimmedTrigger = styled.span`
  overflow: hidden;
  ${(props) => props.width && `width: ${props.width}`};
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  text-align: left;

  ${layout}
  ${typography}
`

const PurePropertyKeysInfoModal = ({
  currentUser,
  tipId,
  keySets = [],
  onlistKeyCode,
  onClose,
  triggerVariant = 'linkBlueWithoutWeight',
  triggerProps,
  modalProps,
  openRight,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const closeModal = () => {
    setIsOpened(false)
    onClose && onClose()
  }
  const renderModal = () => (
    <TooltipLikeModal
      onDismiss={() => closeModal()}
      minWidth="250px"
      openRight={openRight}
      {...modalProps}
    >
      <Box p={5} color={theme.colors.black}>
        <KeysInfo keySets={keySets} onlistKey={onlistKeyCode} />
      </Box>
    </TooltipLikeModal>
  )
  const renderTriggerBtn = (onClick) => (
    <Button variant={triggerVariant} onClick={onClick}>
      <StyledTrimmedTrigger {...triggerProps}>
        {getTriggerKeyLabel(currentUser, keySets, onlistKeyCode)}
      </StyledTrimmedTrigger>
    </Button>
  )
  return (
    <Box style={{ position: 'relative' }}>
      {renderTriggerBtn(() => setIsOpened(!isOpened))}
      {isOpened && renderModal()}
    </Box>
  )
}

const mapStateToProps = ({ session }) => ({ currentUser: session.currentUser })
export const PropertyKeysInfoModal = connect(mapStateToProps)(
  PurePropertyKeysInfoModal,
)

const getCurrentUserKeys = (currentUser, keySets) =>
  keySets?.filter(
    (k) =>
      k.borrowing_data?.borrower?.email === currentUser.email ||
      k.borrowing_data?.borrower?.phone === currentUser.mobile,
  )

const getTriggerKeyLabel = (currentUser, keySets, onlistKeyCode) => {
  /* 
    Fall back through following display:
    Key checked out to me
    First available (returned, added) key
    Onlist key value
    N/A
  */
  const userKeys = getCurrentUserKeys(currentUser, keySets)
  if (userKeys?.length) return userKeys[0].label
  const triggerKey = keySets?.find((k) =>
    [keySetStatuses.returned, keySetStatuses.added].includes(k.status),
  )
  if (!!triggerKey) return triggerKey.label
  return onlistKeyCode || 'N/A'
}

const PureKeysInfo = ({ currentUser, keySets = [], onlistKey }) => {
  const renderKeySetRow = (keySet) => {
    const keySetType = keySetTypesOptions[keySet.set_type]
    const keyStatusConf = keySetStatusConfig[keySet.status]
    return renderKeyRow(keySet.label, keySetType, keyStatusConf)
  }
  const renderKeyRow = (label, type, statusConf) => (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      fontSize={theme.fontSizes.pLarge16}
      mb={3}
    >
      <TextSpan as="div">
        {label} ({type})
      </TextSpan>
      {!!statusConf?.title && (
        <Badge
          style={{ whiteSpace: 'nowrap' }}
          ml={2}
          variant={statusConf?.badgeVariant}
          text={statusConf?.title}
        />
      )}
    </Flex>
  )
  const currentUserKeys = getCurrentUserKeys(currentUser, keySets)
  const otherKeySets = keySets?.filter((k) => !currentUserKeys.includes(k))
  const otherKeysExist = !!otherKeySets?.length || !!onlistKey
  return (
    <>
      <Box textAlign="left">
        <TextSpan
          as="div"
          fontWeight={theme.fontWeights.h2}
          fontSize={theme.fontSizes.pLarge16}
          mb={3}
        >
          Checked out to me
        </TextSpan>
        <Box fontSize={theme.fontSizes.pLarge16}>
          {currentUserKeys?.length
            ? currentUserKeys.map(renderKeySetRow)
            : 'None'}
        </Box>
      </Box>
      <Box textAlign="left" mt={5}>
        <TextSpan
          as="div"
          fontWeight={theme.fontWeights.h2}
          fontSize={theme.fontSizes.pLarge16}
          mb={3}
        >
          Others
        </TextSpan>
        {otherKeySets?.map(renderKeySetRow)}
        {!!onlistKey && renderKeyRow(onlistKey, 'Onlist')}
        {!otherKeysExist && (
          <TextSpan as="div" fontSize={theme.fontSizes.pLarge16}>
            None
          </TextSpan>
        )}
      </Box>
    </>
  )
}

const mapKeyInfoStateToProps = ({ session }) => ({
  currentUser: session.currentUser,
})
export const KeysInfo = connect(mapKeyInfoStateToProps)(PureKeysInfo)
