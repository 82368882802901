import humanIcon01 from 'app/assets/icons/human01.jpg'

export const Documents = [
  {
    type: 'pdf',
    name: 'Signed Lease.pdf',
    avatarImage: humanIcon01,
    userFirstname: 'Anne',
    userLastname: 'Quincy',
    userSufix: '',
    date: '24 July 2017',
  },
  {
    type: 'img',
    name: 'Rent price breakdown.png',
    avatarImage: '',
    userFirstname: 'Justin',
    userLastname: 'Bieber',
    userSufix: 'you',
    date: '24 July 2017',
  },
  {
    type: 'doc',
    name: 'Signed Lease.doc',
    avatarImage: humanIcon01,
    userFirstname: 'Jozy',
    userLastname: 'Quincy',
    userSufix: '',
    date: '24 July 2017',
  },
  {
    type: 'pdf',
    name: 'Signed Lease.pdf',
    avatarImage: humanIcon01,
    userFirstname: 'Anne',
    userLastname: 'Quincy',
    userSufix: '',
    date: '24 July 2017',
  },
  {
    type: 'img',
    name: 'Rent price breakdown.png',
    avatarImage: '',
    userFirstname: 'Justin',
    userLastname: 'Bieber',
    userSufix: 'you',
    date: '24 July 2017',
  },
  {
    type: 'doc',
    name: 'Signed Lease.doc',
    avatarImage: humanIcon01,
    userFirstname: 'Jozy',
    userLastname: 'Quincy',
    userSufix: '',
    date: '24 July 2017',
  },
  {
    type: 'pdf',
    name: 'Signed Lease.pdf',
    avatarImage: humanIcon01,
    userFirstname: 'Anne',
    userLastname: 'Quincy',
    userSufix: '',
    date: '24 July 2017',
  },
  {
    type: 'img',
    name: 'Rent price breakdown.png',
    avatarImage: '',
    userFirstname: 'Justin',
    userLastname: 'Bieber',
    userSufix: 'you',
    date: '24 July 2017',
  },
  {
    type: 'doc',
    name: 'Signed Lease.doc',
    avatarImage: humanIcon01,
    userFirstname: 'Jozy',
    userLastname: 'Quincy',
    userSufix: '',
    date: '24 July 2017',
  },
]
