import React, { useState } from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import { LaunchRounded } from 'app/components/design-system-components/icons/actions'
import InviteToApplyModal from 'app/components/design-system-components/InviteToApply/InviteToApplyModal'
import StackedInfo from 'app/components/design-system-components/StackedInfo'
import PropertySummary from 'app/components/property/PropertySummary'
import theme from 'app/match/applicationReportPDF/assets/theme'
import BadgeAndLink from 'app/pages/teams/messages/components/BadgeAndLink'
import * as helpers from 'app/sm/helpers'
import { getApplicationWorkflowName } from 'app/sm/properties/components/property_search_filter_util'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

const StyledViewingLink = styled(Flex)`
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
`

const StyledAddress = styled(Box)`
  max-width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

function PropertyCell({
  viewings,
  property,
  preferences,
  onClickRegisterForViewing,
  isRowHovered,
  scheduleViewingAction,
  messageGUID,
  onInviteToApply,
  applicationDetails,
  teamGUID = '',
}) {
  let thirdLine = ''
  const hasViewings = viewings && viewings.length > 0

  const {
    status: appliedStatus = 0,
    guidID: applicationGUID,
    primaryApplicantGUID,
  } = applicationDetails || {}

  const isAlreadyRegistered = ((hasViewings && viewings) || []).some(
    ({ isInspected, isRegistered }) => {
      return isRegistered
    },
  )

  const isAlreadyInspected = ((hasViewings && viewings) || []).some(
    ({ isInspected, isRegistered }) => {
      return isInspected
    },
  )

  if (hasViewings) {
    thirdLine = (
      <Flex mt="8px">
        {/* {applied && <Badge variant="red" text="Applied" />} */}
        {/* {isRegistered && <Badge variant="blue" text="Registered" />} */}
        {/* {isInspected && <Badge variant="green" text="Inspected" />} */}
      </Flex>
    )
  }

  const {
    address,
    rent,
    available,
    slug = '',
    weeklyRent = 0,
    guidID: propertyGUID = '',
  } = property || {}
  const activeOffer = {
    availableFrom: available,
    weeklyRentDisplay: rent,
    weeklyRent,
  }
  const [showInviteToApplyModal, setShowInviteToApplyModal] = useState(false)
  const toggleShowInviteToApplyModal = () => {
    setShowInviteToApplyModal(!showInviteToApplyModal)
  }

  const firstLine = address ? (
    <Flex color="#333" alignItems="center">
      <StyledAddress data-tooltip-content="" data-tooltip-id={address}>
        {address}
      </StyledAddress>
      <ReactTooltip
        id={address}
        data-type="info"
        place="top"
        className="tool-tip-style"
      >
        <Box textAlign="center">{address}</Box>
      </ReactTooltip>
      {slug && (
        <Box height={theme.height[4] + 'px'} ml={1}>
          <a
            href={helpers.urlTo('applyRedirect', { slug })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LaunchRounded color={theme.colors.gray500} />
          </a>
        </Box>
      )}
    </Flex>
  ) : (
    <StyledAddress color="#333">General enquiry on waitlist</StyledAddress>
  )

  const secondLine =
    (address && (
      <PropertySummary
        property={property}
        activeOffer={activeOffer}
        preferences={preferences}
      />
    )) ||
    ''
  const getBadgeAndActionLabel = () => {
    let badgeAndActionLabel = {
      badgeLabel: '',
      actionLabel: '',
      action: null,
    }
    if (!hasViewings && !!propertyGUID) {
      badgeAndActionLabel = {
        badgeLabel: 'No times',
        badgeVariant: 'warning',
        actionLabel: 'book a time',
        action: scheduleViewingAction,
      }
    }
    if (hasViewings) {
      if (!isAlreadyRegistered && !isAlreadyInspected) {
        badgeAndActionLabel = {
          badgeLabel: 'Waiting',
          badgeVariant: 'warning',
          actionLabel: 'register',
          action: () => onClickRegisterForViewing(viewings),
        }
      }
      if (isAlreadyRegistered) {
        const registeredViewings = viewings.filter(
          ({ isInspected, isRegistered }) => {
            return isRegistered
          },
        )

        let badgeText = ''
        registeredViewings.forEach(({ viewing: viewingMap }, index) => {
          const { startDate } = viewingMap
          badgeText += `${moment(startDate).format(
            dateTimeHelpers.TIME_DAY_HALF_MONTH,
          )} ${index !== registeredViewings.length - 1 ? ' | ' : ''}`
        })

        badgeAndActionLabel = {
          badgeLabel: 'Registered',
          badgeVariant: 'secondary',
          actionLabel: 'book again',
          action: scheduleViewingAction,
          badgeTooltipText: badgeText,
          badgeTooltipID: messageGUID + badgeText,
        }
      }
      if (isAlreadyInspected) {
        const inspectedViewings = viewings.filter(
          ({ isInspected, isRegistered }) => {
            return isInspected
          },
        )

        let badgeText = ''
        inspectedViewings.forEach(({ viewing }, index) => {
          const { startDate } = viewing
          badgeText += `${moment(startDate).format(
            dateTimeHelpers.TIME_DAY_HALF_MONTH,
          )} ${index !== inspectedViewings.length - 1 ? ' | ' : ''}`
        })
        badgeAndActionLabel = {
          badgeLabel: 'Inspected',
          badgeVariant: 'green',
          actionLabel: 'invite to apply',
          action: toggleShowInviteToApplyModal,
          badgeTooltipText: badgeText,
          badgeTooltipID: messageGUID + badgeText,
        }
      }
      if (appliedStatus) {
        badgeAndActionLabel = {
          badgeLabel: 'Applied',
          badgeVariant: 'green',
          actionLabel: 'view application',
          action: () => {
            window
              .open(
                helpers.urlTo('renterApplication', {
                  propertyId: propertyGUID,
                  applicationId: applicationGUID,
                  applicantId: primaryApplicantGUID,
                }),
                '_blank',
              )
              .focus()
          },
          badgeTooltipText: getApplicationWorkflowName(appliedStatus),
          badgeTooltipID:
            messageGUID + getApplicationWorkflowName(appliedStatus),
        }
      }
    }
    return badgeAndActionLabel
  }

  const badgeAndActionLabel = getBadgeAndActionLabel()

  return (
    <>
      <StackedInfo
        firstLine={firstLine}
        secondLine={secondLine}
        thirdLine={thirdLine}
      />
      {address && (
        <Box fontSize="12px" mt="8px">
          <BadgeAndLink
            badgeAndActionLabel={badgeAndActionLabel}
            isRowHovered={isRowHovered}
          />
        </Box>
      )}
      {showInviteToApplyModal && (
        <InviteToApplyModal
          toggleModal={() => toggleShowInviteToApplyModal()}
          teamGUID={teamGUID}
          propertyDisplayText={address}
          propertyGUID={propertyGUID}
        />
      )}
    </>
  )
}

export default PropertyCell
