import moment from 'moment'

import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { parseSmViewing } from 'app/sm/helpers'
import {
  addFavouriteViewingFlag,
  assignViewing,
  deleteAssignedTeamMember,
  fetchAssignedTeamMembers,
  getManagersListForViewing,
  getViewings,
  remoteFavouriteViewingFlag,
  updateTeamMemberRelation,
} from 'app/sm/viewings/viewings_access'
import store from 'app/store'

export const PING = 'V_SM_PING'
export const TOGGLE_SPINNER = 'V_SM_TOGGLE_SPINNER'
export const RECEIVE_RESPONSE_TEXT = 'V_SM_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_VIEWINGS = 'V_SM_RECEIVE_VIEWINGS'
export const RECEIVE_ERROR = 'V_SM_RECEIVE_ERROR'
export const CLEAR_ERROR = 'V_SM_CLEAR_ERROR'
export const ADD_FAVOURITE_FLAG = 'V_SM_ADD_FAVOURITE_FLAG'
export const REMOVE_FAVOURITE_FLAG = 'V_SM_REMOVE_FAVOURITE_FLAG'
export const ON_SORTER_DROPDOWN_CHANGE = 'V_SM_ON_SORTER_DROPDOWN_CHANGE'

export const ping = () => ({ type: PING })
export const clearError = () => ({ type: CLEAR_ERROR })

const toggleSpinner = () => ({ type: TOGGLE_SPINNER })
const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})

const receiveViewings = (viewings) => ({ type: RECEIVE_VIEWINGS, viewings })
const addFavourite = (registerViewing) => ({
  type: ADD_FAVOURITE_FLAG,
  registerViewing,
})
const removeFavourite = (registerViewing) => ({
  type: REMOVE_FAVOURITE_FLAG,
  registerViewing,
})

function parseServerViewings(viewings) {
  return viewings.map((record) =>
    Object.assign({}, record, {
      viewing: parseSmViewing(
        record.viewing,
        record.property,
        record.snugFit,
        record.application,
        record.propertyOffer,
      ),
    }),
  )
}

export function fetchViewings() {
  return (dispatch) => {
    dispatch(toggleSpinner())
    getViewings().then(({ ok, responseText, viewings }) => {
      if (ok) {
        dispatch(
          receiveViewings(
            parseServerViewings(viewings).sort((a, b) =>
              moment(a.viewing.startDate, 'YYYY-MM-DD HH:mm:ss').isBefore(
                b.viewing.startDate,
                'YYYY-MM-DD HH:mm:ss',
              )
                ? 1
                : -1,
            ),
          ),
        )
        dispatch(ping())
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
      dispatch(toggleSpinner())
    })
  }
}

export function addFavouriteViewingFlagById(id) {
  store.dispatch(clearError())
  return (dispatch) => {
    addFavouriteViewingFlag(id).then(
      ({ ok, registerViewing, responseText }) => {
        if (ok) {
          dispatch(addFavourite(registerViewing))
          dispatch(ping())
        } else {
          responseText.then((t) => store.dispatch(receiveResponseText(t)))
        }
      },
    )
  }
}

export function removeFavouriteViewingFlagById(id) {
  store.dispatch(clearError())
  return (dispatch) => {
    remoteFavouriteViewingFlag(id).then(
      ({ ok, registerViewing, responseText }) => {
        if (ok) {
          dispatch(removeFavourite(registerViewing))
          dispatch(ping())
        } else {
          responseText.then((t) => store.dispatch(receiveResponseText(t)))
        }
      },
    )
  }
}

function sortViewings(sorter) {
  return (dispatch, getState) => {
    const { viewings } = getState().Viewings

    switch (sorter) {
      case 'snugfit':
        dispatch(
          receiveViewings(
            viewings.sort((a, b) =>
              a.viewing.score > b.viewing.score ? -1 : 1,
            ),
          ),
        )
        break
      case 'rent':
        dispatch(
          receiveViewings(
            viewings.sort((a, b) =>
              a.viewing.weeklyRent < b.viewing.weeklyRent ? -1 : 1,
            ),
          ),
        )
        break
      case 'time':
        dispatch(
          receiveViewings(
            viewings.sort((a, b) =>
              moment(a.viewing.startDate, 'YYYY-MM-DD HH:mm:ss').isBefore(
                b.viewing.startDate,
                'YYYY-MM-DD HH:mm:ss',
              )
                ? 1
                : -1,
            ),
          ),
        )
        break
      case 'timeDesc':
        dispatch(
          receiveViewings(
            viewings.sort((a, b) =>
              moment(a.viewing.startDate, 'YYYY-MM-DD HH:mm:ss').isAfter(
                b.viewing.startDate,
                'YYYY-MM-DD HH:mm:ss',
              )
                ? 1
                : -1,
            ),
          ),
        )
        break
      case 'address':
        dispatch(
          receiveViewings(
            viewings.sort((a, b) =>
              a.viewing.address < b.viewing.address ? -1 : 1,
            ),
          ),
        )
        break
      default:
        return viewings
    }
  }
}

export function onSorterDropdownChange(sorter) {
  return (dispatch) => {
    dispatch({ type: ON_SORTER_DROPDOWN_CHANGE, sorter })
    dispatch(sortViewings(sorter))
  }
}

export const assignViewingToTeamMember = (teamId, viewingID, teamMemberID) => {
  return (dispatch) => {
    return assignViewing(teamId, viewingID, teamMemberID).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function fetchViewingTeamMembers(teamGUID, viewingGUID) {
  return (dispatch) => {
    return fetchAssignedTeamMembers(teamGUID, viewingGUID).then(
      ({ ok, teamMemberInfo, responseText }) => {
        if (ok) {
          return Promise.resolve(teamMemberInfo)
        } else {
          responseText.then((t) => {})
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const deleteViewingTeamMember =
  (teamGUID, viewingGUID, teamMemberGUID) => () => {
    return deleteAssignedTeamMember(teamGUID, viewingGUID, teamMemberGUID).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((t) => {
            return Promise.reject(translateErrorCodeToMessage(t))
          })
        }
      },
    )
  }

export const updateViewingTeamMemberRelation = (
  teamId,
  viewingID,
  teamMemberID,
  requestBody,
) => {
  return (dispatch) => {
    return updateTeamMemberRelation(
      teamId,
      viewingID,
      teamMemberID,
      requestBody,
    ).then(({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    })
  }
}

export function getManagersListForViewingFilters(teamGUID, viewingGUID) {
  return (dispatch) => {
    return getManagersListForViewing(teamGUID, viewingGUID).then(
      ({ ok, managerList, responseText }) => {
        if (ok) {
          return Promise.resolve(managerList)
        } else {
          responseText.then((t) => {})
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}
