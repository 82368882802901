import React from 'react'

import * as FormComponents from 'app/components/forms/forms'
import * as Form from 'app/shared_components/form_component'
import * as viewingHelpers from 'app/utils/viewings/helpers'

import 'app/sm/team_viewing_preference/preference/style.scss'

const viewingCutoffOptions = viewingHelpers.viewingCutoffOptions

const ViewingScheduleOptions = ({
  isFixedTimePicked = false,
  isFlexibleTimePicked = false,
  onFixedTimeBoxChanged = undefined,
  onFlexibleTimeBoxChanged = undefined,
  viewingCutoffEnabled = false,
  changeViewingCutoffEnabled = undefined,
  changeViewingCutoffDropdownValue = undefined,
  viewingCutoffTime,
  onlyOneViewingPerDay = false,
  onOnlyOneViewingPerDayChanged,
  optimiseTravelTime = false,
  onOptimiseTravelTimeChanged,
  optimiseTravelOptionEnabled = false,
  viewingBookNearbyProperties = false,
  viewingBookNearbyPropertiesSelected = false,
  onViewingBookNearbyPropertiesChanged,
  PropertiesProximity,
  viewingBookNearbyPropertiesProximityOption,
  onFormUpdate,
  isActionDisabled,
}) => {
  return (
    <div className="preference-option-container">
      <p className="title">Set your preferred viewing booking methods</p>
      <div className="display-flex flex-direction-column">
        <Form.Checkbox
          id="fixedTime"
          label="Pre-scheduled time (default)"
          checked={isFixedTimePicked}
          onChange={onFixedTimeBoxChanged}
          componentClassName="mt10"
          enableEdit={false}
          showDisabledGrey={isActionDisabled}
        />
        <Form.Checkbox
          id="flexibleTime"
          label="Availability window"
          checked={isFlexibleTimePicked}
          onChange={onFlexibleTimeBoxChanged}
          componentClassName="mt10"
          enableEdit={!isActionDisabled}
          showDisabledGrey={isActionDisabled}
        />
        {isFlexibleTimePicked && (
          <>
            <div className="mt10  wa pt0">
              <FormComponents.CheckBoxGeneral
                label={
                  !!viewingCutoffEnabled
                    ? 'Booking lead time'
                    : 'Booking lead time (default 1 hour)'
                }
                checked={viewingCutoffEnabled}
                onChange={changeViewingCutoffEnabled}
                eventProcessedByComponent
                disabled={isActionDisabled}
                showDisabledGrey={isActionDisabled}
              />
              {!!viewingCutoffEnabled && (
                <div>
                  <FormComponents.Dropdown
                    label="Please select the max number of registrants"
                    value={viewingCutoffTime}
                    options={viewingCutoffOptions}
                    onChange={(event) =>
                      changeViewingCutoffDropdownValue(event)
                    }
                    idAsValue
                  />
                </div>
              )}
            </div>
            <FormComponents.CheckBoxGeneral
              label="Limit bookings to once per day per property"
              checked={onlyOneViewingPerDay}
              onChange={(event) => onOnlyOneViewingPerDayChanged(event)}
              componentClassName="mt10"
              disabled={isActionDisabled}
              showDisabledGrey={isActionDisabled}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default ViewingScheduleOptions
