import { connect } from 'react-redux'

import ProfileUpdate from 'app/session/bc_profile/profile_update/index'
import { fetchCurrentUserProfile, logout } from 'app/session/session_actions'

const mapStateToProps = ({ session }) => ({
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUserProfile: () => dispatch(fetchCurrentUserProfile()),
  logout: () => logout(),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpdate)
