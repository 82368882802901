import React from 'react'

import { getIn, useField, useFormikContext } from 'formik'
import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import * as getters from 'app/components/design-system-components/theme/getters'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const API_ERRORS_PROPERTY = 'apiErrors'

const LabelTextContainer = styled(Box)`
  line-height: 1.1;
  font-size: ${theme.fontSizes.pLarge16};
  font-weight: ${getters.fontWeight(6)};
  color: ${getters.color('gray700')};

  &.required {
    :after {
      content: '*';
      margin-left: ${getters.spacing(1)};
      color: ${getters.color('primary600')};
    }
  }
`

const LabelContainer = styled(Box)`
  margin-bottom: ${getters.spacing(4)};
`

export const SimpleFieldLabel = ({
  required,
  LabelTextRenderer = LabelTextContainer,
  subText,
  label,
  ...props
}) => {
  return (
    <LabelContainer {...props}>
      <LabelTextRenderer className={required && 'required'}>
        {label}
      </LabelTextRenderer>
      {subText}
    </LabelContainer>
  )
}

export const FieldWrapper = ({
  id,
  label = '',
  labelProps = undefined,
  LabelRenderer = SimpleFieldLabel,
  required = false,
  children,
  hint = '',
  ...props
}) => {
  const [field, meta, helpers] = useField(props)
  const { status } = useFormikContext()
  const error = meta.touched && meta.error

  // now we get an object of errors for each field, we may choose to show all errors at once, or maybe we show only one per time as for now
  const errors = error && Object.values(error)
  const shownError = (errors || [])[0]

  const apiErrors = getIn(status?.[API_ERRORS_PROPERTY], field.name) || []

  return (
    <Box {...props}>
      {label && (
        <label htmlFor={id}>
          <LabelRenderer label={label} required={required} {...labelProps} />
        </label>
      )}
      {children}
      {error && (
        <Box
          fontSize={theme.fontSizes[4] + 'px'}
          p={theme.space[3] + 'px'}
          id={id + '-error'}
          style={{ color: 'red' }}
        >
          {shownError}
        </Box>
      )}
      {!!apiErrors.length && (
        <Box
          fontSize={theme.fontSizes[4] + 'px'}
          p={theme.space[3] + 'px'}
          id={id + '-api-error'}
          style={{ color: 'red' }}
        >
          {apiErrors[0]}
        </Box>
      )}
      {hint}
    </Box>
  )
}
