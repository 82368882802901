import { Form } from 'formik'

import { Button } from 'app/components/design-system-components'
import {
  InputField,
  TelField,
} from 'app/components/design-system-components/inputs'
import { mobileTelPlaceholder } from 'app/components/design-system-components/inputs/Tel'
import { formFields } from 'app/features/teams/key-logger/CheckoutKeySetModal/AddressBorrowerSelection/NewBorrowerModal/form.utils'
import { StyledFieldWrapper } from 'app/features/teams/key-logger/components/StepLayoutContainer'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const FormBody = ({
  formBag: {
    isValid,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    status,
    values,
    dirty,
  },
}) => {
  return (
    <Form style={{ height: '100%' }}>
      <StyledFieldWrapper
        id={formFields.firstName}
        name={formFields.firstName}
        label="First name"
        required
      >
        <InputField
          id={formFields.firstName}
          name={formFields.firstName}
          inputProps={{ placeholder: 'Enter Borrower first name' }}
        />
      </StyledFieldWrapper>

      <StyledFieldWrapper
        id={formFields.lastName}
        name={formFields.lastName}
        label="Last name"
        required
      >
        <InputField
          id={formFields.lastName}
          name={formFields.lastName}
          inputProps={{ placeholder: 'Enter Borrower last name' }}
        />
      </StyledFieldWrapper>

      <StyledFieldWrapper
        id={formFields.email}
        name={formFields.email}
        label="Email"
      >
        <InputField
          id={formFields.email}
          name={formFields.email}
          inputProps={{ placeholder: 'Enter Borrower email' }}
        />
      </StyledFieldWrapper>

      <StyledFieldWrapper
        id={formFields.company}
        name={formFields.company}
        label="Company"
      >
        <InputField
          id={formFields.company}
          name={formFields.company}
          inputProps={{ placeholder: 'Enter Borrower Company' }}
        />
      </StyledFieldWrapper>

      <StyledFieldWrapper
        id={formFields.phone}
        name={formFields.phone}
        label="Phone"
      >
        <TelField name={formFields.phone} placeholder={mobileTelPlaceholder} />
      </StyledFieldWrapper>

      <Button
        type="submit"
        disabled={!isValid || isSubmitting}
        width="100%"
        mt={theme.space[6] + 'px'}
      >
        Continue
      </Button>
    </Form>
  )
}
