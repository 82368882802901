import { connect } from 'react-redux'

import {
  addCards,
  fetchCards,
  removeCard,
  setDefaultCard,
} from 'app/bond_cover/bond_cover_actions'
import ProfileCreditCardsForm from 'app/session/bc_profile/credit_cards_container'

const mapStateToProps = ({ bondCover, Shared, apply }) => ({
  paymentOptions: bondCover.paymentOptions,
  backUrl: Shared.backUrl,
  property: apply.property,
  error: bondCover.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCards: () => fetchCards(),
  addCards: () => addCards(),
  setDefaultCard: (guid) => setDefaultCard(guid),
  removeCard: (guid) => removeCard(guid),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileCreditCardsForm)
