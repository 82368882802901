export const appPageScrollContainerSelectors = {
  mobile: `#app-scrollable-container`,
  desktop: `.app-content`,
}

export const scrollToElement = (container, targetElem, config) => {
  const { topMarginInPx = 0, timeout = 100 } = config || {}
  return setTimeout(() => {
    const yPixel =
      targetElem.getBoundingClientRect().top +
      container.scrollTop -
      topMarginInPx
    container.scroll(0, yPixel)
  }, timeout)
}
