export const AccessDetailsFields = {
  KeyCode: {
    typeIndex: 1,
    typeKey: 'keyCode',
  },
  AlarmCode: {
    typeIndex: 2,
    typeKey: 'alarmCode',
  },
  AccessNotes: {
    typeIndex: 3,
    typeKey: 'accessNote',
  },
}
