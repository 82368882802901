import React from 'react'

import moment from 'moment'

import 'app/match/apply/application_summary_before_submit/components/preference_container/style.scss'
import infoIcon from 'app/assets/icons/summary_icons/info.svg'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import { isMobile } from 'app/shared_components/helpers'
import {
  defaultRentScheduleOptions,
  parseDataUtil,
  urlTo,
} from 'app/sm/helpers'
import {
  ApplicationDetailsLongTextComponent,
  ApplicationDetailsNumbersComponent,
  ApplicationDetailsNumbersComponentRowStyle,
} from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'
import * as vehicleHelpers from 'app/utils/applications/vehicles'

const PreferenceComponent = ({
  componentClassName = '',
  application = {},
  isSummaryEditable = true,
  onEditClick,
  totalEmployedWeeklyNetIncome = 0,
  totalWeeklyNetIncomeEditedByManager = 0,
  propertyOffer = {},
  enableNote = false,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  displayedApplicant,
  section,
  toggleRequestInfoModal = undefined,
  toggleEditRenterApplication = undefined,
  isManagerAdded = false,
  isManagerSummary = false,
  isLeaseUpdated = false,
  teamSettingInfo = {},
  excludeRequestVehicleRegistration,
}) => {
  let {
    weeklyRent = 0,
    term = '',
    leaseStartDate = '',
    aboutMe = '',
    requests = '',
    applicants = [],
    pets = 0,
    adults = 0,
    children = [],
    applicationType,
    rentScheduleType = 0,
    requestCarSpace = false,
    managerAddedRent = 0,
    managerAddedLeaseTerm = 0,
    managerAddedLeaseStartDate = '',
    managerAddedPets = 0,
    allVehicles,
    guidID,
  } = application
  const totalPets = managerAddedPets > 0 ? managerAddedPets : pets
  const originalValuesLeaseRequestedValues = {
    weeklyRent,
    leaseStartDate,
    term,
  }
  weeklyRent =
    isManagerSummary && managerAddedRent > 0 ? managerAddedRent : weeklyRent
  leaseStartDate =
    isManagerSummary && managerAddedLeaseStartDate !== ''
      ? managerAddedLeaseStartDate
      : leaseStartDate
  term =
    isManagerSummary && managerAddedLeaseTerm > 0
      ? String(managerAddedLeaseTerm)
      : term
  const {
    weeklyRent: offeredWeeklyRent = 0,
    availableFrom: advertLeaseStartDate,
  } = propertyOffer
  const formattedStartDate = moment(leaseStartDate).format('DD MMM YYYY')
  const originalFormattedStartDate = moment(
    originalValuesLeaseRequestedValues.leaseStartDate,
  ).format('DD MMM YYYY')
  const applicantNumber = applicants.length
  const rentDifference = weeklyRent - offeredWeeklyRent
  let rentDifferenceText = 0
  if (rentDifference !== 0) {
    rentDifferenceText =
      rentDifference > 0
        ? `+ $${rentDifference}`
        : `- $${Math.abs(rentDifference)}`
  }
  const leaseStartDateVariance = moment(leaseStartDate).diff(
    moment(advertLeaseStartDate),
    'days',
  )
  const leaseStartDateVarianceText =
    leaseStartDateVariance === 0
      ? ''
      : `${Math.abs(leaseStartDateVariance)} days ${
          leaseStartDateVariance > 0 ? 'after' : 'before'
        } `
  const showRentDifference = applicationType !== 2
  const rentScheduleOption =
    defaultRentScheduleOptions.find(
      (option) => option.index === rentScheduleType,
    ) || {}
  const rentScheduleText = rentScheduleOption.value || 'Not provided'
  const requestCarSpaceText = requestCarSpace ? 'Yes' : 'No'

  const { application: applicationSettings = {} } = teamSettingInfo

  const parsedTeamApplicationSettingInfo =
    parseDataUtil.convertObjValueFromStringToBoolean(applicationSettings)

  let { disable_children_option: disableChildrenOption = false } =
    parsedTeamApplicationSettingInfo

  // show children if application already submitted with children
  if (children?.length > 0) {
    disableChildrenOption = false
  }

  const occupantsCount = adults + (disableChildrenOption ? 0 : children.length)
  const occupantsText = [
    `Adults ${adults}`,
    disableChildrenOption ? '' : `Children ${children.length}`,
    `Pets ${totalPets}`,
  ]
    .filter((o) => o.length > 0)
    .join(', ')

  const totalVehicles = allVehicles && allVehicles.length
  const totalVehiclesText = vehicleHelpers.formTotalVehiclesText(
    allVehicles,
    excludeRequestVehicleRegistration,
  )

  return (
    <>
      {isLeaseUpdated && (
        <>
          <p className="standard-subheading mr20 ml20">Lease</p>
          <div className="application-details-numbers-box display-flex pdf-width-80 mr20 ml20">
            <ApplicationDetailsNumbersComponent
              title="Rent"
              text={`$${weeklyRent}`}
              componentClassName="width50p align-items-start flex-basis-25p"
              extraText="/w"
              titleClassName="preference-label"
              textClassName="preference-content pdf-font-size-10 pdf-margin-neg-10"
              titleTextClassName="pdf-font-size-10"
              showHookedText={showRentDifference && rentDifference !== 0}
              hookedText={rentDifferenceText}
            />
            <ApplicationDetailsNumbersComponent
              title="Term"
              text={term}
              componentClassName="width50p align-items-start flex-basis-25p"
              extraText="months"
              titleClassName="preference-label"
              textClassName="preference-content pdf-font-size-10 pdf-margin-neg-10"
              titleTextClassName="pdf-font-size-10"
            />
            <ApplicationDetailsNumbersComponent
              title="Lease Start"
              text={formattedStartDate}
              componentClassName="width50p align-items-start flex-basis-25p"
              titleClassName="preference-label"
              textClassName="preference-content pdf-font-size-10 pdf-margin-neg-10"
              titleTextClassName="pdf-font-size-10"
            />
            <ApplicationDetailsNumbersComponent
              title="Total Income"
              text={`$${totalWeeklyNetIncomeEditedByManager}`}
              extraText="/w (After tax)"
              componentClassName="width50p align-items-start flex-basis-25p"
              titleClassName="preference-label"
              textClassName="preference-content pdf-font-size-10 pdf-margin-neg-10"
              titleTextClassName="pdf-font-size-10"
            />
          </div>
        </>
      )}
      <div className={`container-component pdf-bbs ${componentClassName}`}>
        <div className="application-details-info-container">
          <ContainerHeader
            icon={infoIcon}
            text="Lease requested"
            isEditable={isSummaryEditable}
            onEditClick={() =>
              onEditClick(
                `${urlTo('application', {
                  applicationId: application.guidID,
                })}?from=rentersummary`,
              )
            }
            enableNote={enableNote}
            onNewNoteUpdated={onNewNoteUpdated}
            newNotesContent={newNotesContent}
            onNoteIconClicked={onNoteIconClicked}
            onNewNoteAdded={onNewNoteAdded}
            onNoteCrossClicked={onNoteCrossClicked}
            interpreteNoteResult={interpreteNoteResult}
            enableNotesButton={enableNotesButton}
            displayedApplicant={displayedApplicant}
            section={section}
            toggleRequestInfoModal={toggleRequestInfoModal}
            toggleEditRenterApplication={toggleEditRenterApplication}
            isManagerAdded={isManagerAdded}
            targetGUID={guidID}
          />
          <div className="application-details-numbers-box display-flex pdf-width-80">
            <ApplicationDetailsNumbersComponent
              title="Rent"
              text={`$${originalValuesLeaseRequestedValues.weeklyRent}`}
              componentClassName="width50p align-items-start flex-basis-20p"
              extraText="/w"
              titleClassName="preference-label"
              textClassName="preference-content pdf-font-size-10 pdf-margin-neg-10"
              titleTextClassName="pdf-font-size-10"
              showHookedText={showRentDifference && rentDifference !== 0}
              hookedText={rentDifferenceText}
              componentMinWidth={isMobile() ? 'min-width-75' : ''}
            />
            <ApplicationDetailsNumbersComponent
              title="Term"
              text={originalValuesLeaseRequestedValues.term}
              componentClassName="width50p align-items-start flex-basis-20p"
              extraText="months"
              titleClassName="preference-label"
              textClassName="preference-content pdf-font-size-10 pdf-margin-neg-10"
              titleTextClassName="pdf-font-size-10"
              componentMinWidth={isMobile() ? 'min-width-75' : ''}
            />
            <ApplicationDetailsNumbersComponent
              title="Lease Start"
              text={originalFormattedStartDate}
              componentClassName="width50p align-items-start flex-basis-20p"
              titleClassName="preference-label"
              textClassName="preference-content pdf-font-size-10 pdf-margin-neg-10"
              titleTextClassName="pdf-font-size-10"
              showHookedText={
                showRentDifference && leaseStartDateVariance !== 0
              }
              hookedText={leaseStartDateVarianceText}
            />
            <ApplicationDetailsNumbersComponent
              title="Total Income"
              text={`$${totalEmployedWeeklyNetIncome}`}
              extraText="/w (After tax)"
              componentClassName="width50p align-items-start flex-basis-40p"
              titleClassName="preference-label"
              textClassName="preference-content pdf-font-size-10 pdf-margin-neg-10"
              titleTextClassName="pdf-font-size-10"
            />
          </div>
          <div className="pdf-display-flex pdf-width-80">
            <ApplicationDetailsNumbersComponentRowStyle
              title="Total Applicants"
              text={applicantNumber}
              componentClassName="width50p align-items-start flex-basis-25p"
              textClassName="preference-content pdf-font-size-10"
              titleTextClassName="pdf-font-size-10"
            />
            <ApplicationDetailsNumbersComponentRowStyle
              title="Rent schedule"
              text={rentScheduleText}
              componentClassName="width50p align-items-start flex-basis-25p"
              textClassName="preference-content pdf-font-size-10"
              titleTextClassName="pdf-font-size-10"
            />
            {parsedTeamApplicationSettingInfo.request_car_space && (
              <>
                <ApplicationDetailsNumbersComponentRowStyle
                  title="Request a Car Space"
                  text={requestCarSpaceText}
                  componentClassName="width50p align-items-start flex-basis-25p"
                  textClassName="preference-content pdf-font-size-10"
                  titleTextClassName="pdf-font-size-10"
                />
                <ApplicationDetailsNumbersComponentRowStyle
                  title={`Vehicles (${totalVehicles})`}
                  text={totalVehiclesText}
                  componentClassName="width50p align-items-start flex-basis-25p"
                  textClassName="preference-content pdf-font-size-10"
                  titleTextClassName="pdf-font-size-10"
                />
              </>
            )}
            <ApplicationDetailsNumbersComponentRowStyle
              title={`Occupants (${occupantsCount})`}
              text={occupantsText}
              componentClassName="width50p align-items-start flex-basis-25p"
              textClassName="preference-content pdf-font-size-10"
              titleTextClassName="pdf-font-size-10"
            />
          </div>
          <div className="application-detials-request-container display-flex p0 flex-direction-column pdf-display-flex pdf-flex-direction-row">
            <ApplicationDetailsLongTextComponent
              title="About"
              text={aboutMe}
              componentClassName="pl0"
              titleClassName="preference-label pdf-font-size-10"
              contentClassName="preference-content"
            />
            <ApplicationDetailsLongTextComponent
              title="Request"
              text={requests}
              titleClassName="preference-label pdf-font-size-10"
              contentClassName="preference-content"
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default PreferenceComponent
