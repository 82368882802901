import {
  generateKeySetFilteringParams,
  generateKeySetPopulateParams,
  listKeySets,
} from 'app/services/http/teams/key-logger'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'

export const useKeySetOptionsForProperty = (teamId, allowedStatuses) => {
  const {
    state: keySetsOptions,
    setState: setKeySetsOptions,
    loadingStates: keySetsOptionsLoadingStates,
    loadingStatesHelpers: keySetsOptionsLoadingStatesHelpers,
  } = useStateWithLoading([])

  const loadKeySetsOptions = (property) => {
    if (!property) return

    keySetsOptionsLoadingStatesHelpers.startLoading()
    const listingParams = {
      ...generateKeySetFilteringParams({
        property: property.option.guidID,
        statuses: allowedStatuses,
      }),
      ...generateKeySetPopulateParams({
        property: true,
        borrower: true,
        issuer: true,
      }),
    }
    return listKeySets(teamId, listingParams)
      .then(({ items: keySets }) => {
        const options = keySets.map((keySet) => ({
          option: keySet,
          optionMeta: { id: keySet.guid, displayText: keySet.label },
        }))
        setKeySetsOptions(options)
        keySetsOptionsLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => keySetsOptionsLoadingStatesHelpers.setError(err))
  }

  return {
    keySetsOptions,
    keySetsOptionsLoadingStates,
    loadKeySetsOptions,
  }
}
