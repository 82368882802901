import React from 'react'

import $ from 'jquery'
import moment from 'moment'
import qs from 'qs'

import greenTick from 'app/assets/icons/green-tick.png'
import headerImg01 from 'app/assets/icons/header-img-01.png'
import DefaultButton from 'app/components/buttons/default/component'
import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import * as snugNotifier from 'app/services/snugNotifier'
import { ErrorMessage } from 'app/shared_components/helpers'
import Spinner from 'app/sm/common/spinner'
import * as helpers from 'app/sm/helpers'
import * as datetimeHelpers from 'app/utils/datetime/helpers'

const Status_Confirmed = 'confirmed'
const Status_Cancelled = 'cancelled'

const ShowPage = ({
  firstName,
  isPassedViewing,
  isConfirmed,
  isUnregister,
  propertyAddress,
  formattedDate,
  viewingDuration,
  registrantGuidID,
  toggleUpdateForm,
  attendeeConfirmation,
}) => {
  const showUpdateAndCancelLink = isConfirmed && !isUnregister
  return (
    <div>
      {isPassedViewing && (
        <Display.DefaultBodyText componentClass="text-align-center width80 ml-auto mr-auto">
          <p>
            Sorry, the viewing time has passed and registrations can not be
            updated. Please book another time.
          </p>
        </Display.DefaultBodyText>
      )}
      {!isPassedViewing && formattedDate && viewingDuration && (
        <div>
          <Display.DefaultBodyText componentClass="text-align-center width80 ml-auto mr-auto">
            <p>
              {showUpdateAndCancelLink
                ? `Hi ${firstName}, you've confirmed your attendance to view`
                : `You've cancelled your attendance to view`}
              <b> {propertyAddress}</b>
            </p>
          </Display.DefaultBodyText>
          <Display.SectionHeader
            text={`${formattedDate} (${viewingDuration} mins)`}
            componentClass="text-align-center"
            hasSeperator={false}
          />
          <Display.DefaultBodyText componentClass="text-align-center width80 ml-auto mr-auto">
            {showUpdateAndCancelLink && (
              <p>
                <span
                  className="blue-link-style undo-snooze"
                  onClick={toggleUpdateForm}
                  onKeyPress={() => this.unregisterViewing(registrantGuidID)}
                  target=""
                  role="link"
                  tabIndex="0"
                >
                  Update your details
                </span>{' '}
                or{' '}
                <span
                  className="blue-link-style undo-snooze"
                  onClick={() => attendeeConfirmation(Status_Cancelled)}
                  onKeyPress={() => attendeeConfirmation(Status_Cancelled)}
                  target=""
                  role="link"
                  tabIndex="0"
                  id="teamSlug here"
                >
                  cancel attendance
                </span>
              </p>
            )}
            {!isConfirmed && (
              <span
                onClick={() => attendeeConfirmation(Status_Confirmed)}
                onKeyPress={() => attendeeConfirmation(Status_Confirmed)}
                className="blue-link-style undo-snooze"
                target=""
                role="link"
                tabIndex="0"
              >
                Register and confirm again
              </span>
            )}
          </Display.DefaultBodyText>
        </div>
      )}
    </div>
  )
}
class AttendeeViewingConfirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      request: {
        firstName: '',
        registrantGuidID: '',
        propertyAddress: '',
        viewingDate: '',
        viewingDuration: '',
      },
      fields: {
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: '',
      },
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: '',
      },
      apiError: '',
      error: '',
      loading: true,
      isConfirmed: false,
      showUpdateForm: false,
      updated: false,
      isUnregister: false,
      isPassedViewing: false,
      showConfirmOrCancelModal: false,
      isShortCodeImplementation: false,
      viewingGUIDFromShortCode: '',
      attendeeGUIDFromShortCode: '',
      attendeeConfirmationButtonsDisabled: false,
      attendanceConfirmedLoading: false,
      attendanceNotConfirmedLoading: false,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { viewingID, shortcode },
      },
      fetchPropertyInfoByViewingId,
      fetchRegistrantInfoFromShortcode,
    } = this.props
    if (viewingID) {
      fetchPropertyInfoByViewingId(viewingID).then(({ data }) => {
        this.setState({ propertyInfo: data })
      })
      const curQueries = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      })
      const { confirmed = 'false', cancelled = 'false' } = curQueries

      if (confirmed === 'true') {
        this.attendeeConfirmation(Status_Confirmed)
        return
      }
      if (cancelled === 'true') {
        this.attendeeConfirmation(Status_Cancelled)
        return
      }
      if (Object.keys(curQueries).length === 0) {
        this.setState({
          loading: false,
          showConfirmOrCancelModal: true,
          // isConfirmedOrCancelled: false,
        })
        return
      }

      this.setState({
        loading: false,
      })
      this.props.history.replace('/404')
    }

    if (shortcode) {
      fetchRegistrantInfoFromShortcode(shortcode)
        .then(({ data }) => {
          this.setState({
            showConfirmOrCancelModal: true,
            isShortCodeImplementation: true,
            viewingGUIDFromShortCode: data.Viewing && data.Viewing.guidID,
            attendeeGUIDFromShortCode:
              data.ViewingOnsiteRegistrant &&
              data.ViewingOnsiteRegistrant.guidID,
            request: {
              propertyAddress:
                data.Property &&
                data.Property.address.friendlyName +
                  ', ' +
                  data.Property.address.suburb,
              viewingDate: data.Viewing && data.Viewing.startDate,
            },
          })
        })
        .catch((error) => {
          snugNotifier.error(translateErrorCodeToMessage(error))
        })
        .finally(() =>
          this.setState({
            loading: false,
          }),
        )
    }
  }

  componentWillUnmount() {
    $('#index').removeClass('no-navbar-menu')
  }

  attendeeConfirmation = (confirmationStatus) => {
    let {
      match: {
        params: { viewingID = '', attendeeGUID = '' },
      },
      fetchPropertyInfoByViewingId,
      viewingAttendeeConfirmation,
    } = this.props
    if (this.state.isShortCodeImplementation) {
      viewingID = this.state.viewingGUIDFromShortCode
      attendeeGUID = this.state.attendeeGUIDFromShortCode
      if (confirmationStatus === Status_Cancelled) {
        this.setState({
          attendeeConfirmationButtonsDisabled: true,
          attendanceNotConfirmedLoading: true,
        })
      }
      if (confirmationStatus === Status_Confirmed) {
        this.setState({
          attendeeConfirmationButtonsDisabled: true,
          attendanceConfirmedLoading: true,
        })
      }
    }
    this.setState({
      isConfirmed: confirmationStatus === Status_Confirmed,
      showUpdateForm: false,
    })
    fetchPropertyInfoByViewingId(viewingID)
      .then(({ data }) => {
        this.setState({ propertyInfo: data })
        viewingAttendeeConfirmation(
          { confirmationStatus: confirmationStatus },
          viewingID,
          attendeeGUID,
        )
          .then((response) => {
            const { ViewingOnsiteRegistrant, Viewing } = response.data
            this.setState({
              isPassedViewing: !Viewing === true,
              request: {
                firstName: ViewingOnsiteRegistrant.firstName,
                registrantGuidID: ViewingOnsiteRegistrant.guidID,
                propertyAddress:
                  data.address.friendlyName + ', ' + data.address.suburb,
                viewingDate:
                  ViewingOnsiteRegistrant && ViewingOnsiteRegistrant.slotTime,
                viewingDuration:
                  ViewingOnsiteRegistrant &&
                  ViewingOnsiteRegistrant.viewingDuration,
              },
              fields: {
                firstName: ViewingOnsiteRegistrant.firstName,
                lastName: ViewingOnsiteRegistrant.lastName,
                mobilePhone: ViewingOnsiteRegistrant.mobilePhone,
                email: ViewingOnsiteRegistrant.email,
              },
              loading: false,
              // isConfirmedOrCancelled: true,
              showConfirmOrCancelModal: false,
            })
          })
          .catch((apiError) => {
            snugNotifier.error(apiError)
            this.setState({ apiError, loading: false })
          })
      })
      .catch((apiError) => {
        this.setState({ apiError, loading: false })
        if (this.state.isShortCodeImplementation) {
          snugNotifier.error(translateErrorCodeToMessage(apiError))
        }
      })
      .finally(() => {
        this.setState({
          attendeeConfirmationButtonsDisabled: false,
          attendanceConfirmedLoading: false,
          attendanceNotConfirmedLoading: false,
        })
      })
  }

  showConfirmOrCancelModalFunction = () => {
    this.setState({
      showConfirmOrCancelModal: !this.state.showConfirmOrCancelModal,
    })
  }

  toggleUpdateForm = () => {
    this.setState({ showUpdateForm: !this.state.showUpdateForm })
  }

  unregisterViewing(registrantGuidID) {
    const { unregisterForEnquiryViewing } = this.props
    unregisterForEnquiryViewing(registrantGuidID)
      .then(this.setState({ isUnregister: true, showUpdateForm: false }))
      .catch((apiError) => this.setState({ apiError }))
  }

  update = (field) => {
    return (data) => {
      this.setState({
        fields: {
          ...this.state.fields,
          [field]: data.value,
        },
        errors: {
          ...this.state.errors,
          [field]: data.error,
        },
      })
    }
  }

  updateViewingRegistrant(registrantGuidID) {
    const { updateEquiryViewingRegistrant } = this.props
    const data = {
      firstName: this.state.fields.firstName,
      lastName: this.state.fields.lastName,
      email: this.state.fields.email,
      mobilePhone: this.state.fields.mobilePhone,
    }
    this.setState({ loading: true })

    updateEquiryViewingRegistrant(data, registrantGuidID)
      .then(this.setState({ loading: false, updated: true }))
      .catch((apiError) => {
        this.setState({
          loading: false,
          apiError,
        })
      })
  }

  render() {
    const {
      request: {
        firstName,
        registrantGuidID,
        propertyAddress,
        viewingDate,
        viewingDuration,
      },
      fields: {
        firstName: firstNameField,
        lastName: lastNameField,
        email: emailField,
        mobilePhone: mobilePhoneField,
      },
      errors: {
        firstName: firstNameError,
        lastName: lastNameError,
        email: emailError,
        mobilePhone: mobilePhoneError,
      },
      isConfirmed = false,
      loading,
      showUpdateForm = false,
      updated = false,
      isUnregister = false,
      isPassedViewing = false,
      showConfirmOrCancelModal = false,
      attendeeConfirmationButtonsDisabled = false,
      attendanceConfirmedLoading = false,
      attendanceNotConfirmedLoading = false,
    } = this.state
    const showSpinner = loading
    const showPage = !(this.state.apiError || this.state.error) && !showSpinner
    const isError = this.state.apiError || this.state.error
    const startDate = moment(viewingDate)
      .local()
      .format(helpers.viewingBETimeFormat)
    const formattedDate = moment(startDate).format(
      helpers.dateWithTimeAndWeekDayTimeFormatNoComma,
    )
    const confirmationModalMessage = `Please confirm or cancel your attendance to view ${propertyAddress} at ${moment(
      viewingDate,
    ).format(datetimeHelpers.TIME_DAY_HALF_MONTH)}`
    return (
      <Display.CenterContentContainer componentClass="width100 display-flex flex-direction-column text-align-center">
        <div className="ilustration-box">
          <img src={headerImg01} alt="Happy Cactus" />
        </div>
        {showPage && (
          <ShowPage
            firstName={firstName}
            isPassedViewing={isPassedViewing}
            isConfirmed={isConfirmed}
            isUnregister={isUnregister}
            propertyAddress={propertyAddress}
            formattedDate={formattedDate}
            viewingDuration={viewingDuration}
            registrantGuidID={registrantGuidID}
            toggleUpdateForm={this.toggleUpdateForm}
            attendeeConfirmation={this.attendeeConfirmation}
            showConfirmOrCancelModalFunction={
              this.showConfirmOrCancelModalFunction
            }
          />
        )}
        {showUpdateForm && (
          <div className="width100 display-flex flex-direction-column">
            <Form.InputName
              label="First name"
              value={firstNameField}
              error={firstNameError}
              onChange={this.update('firstName')}
              id="firstName"
              componentClass="col-md-5 col-xs-9 mr-auto ml-auto"
              inputClass="width100"
            />
            <Form.InputName
              label="Last name"
              value={lastNameField}
              error={lastNameError}
              onChange={this.update('lastName')}
              id="lastName"
              componentClass="col-md-5 col-xs-9 mr-auto ml-auto mt10"
              inputClass="width100"
            />
            <Form.InputEmail
              label="Email"
              value={emailField}
              error={emailError}
              onChange={this.update('email')}
              id="email"
              componentClass="col-md-5 col-xs-9 mr-auto ml-auto mt10"
              inputClass="width100"
            />
            <Form.InputPhoneNumber
              label="Mobile"
              value={mobilePhoneField}
              error={mobilePhoneError}
              onChange={this.update('mobilePhone')}
              id="mobilePhone"
              componentClass="col-md-5 col-xs-9 mr-auto ml-auto mt10"
              containerClassName="width100"
            />
            <DefaultButton
              text={updated ? 'Done' : 'Update'}
              onClick={() => this.updateViewingRegistrant(registrantGuidID)}
              disabled={this.state.loading || updated}
              isLoading={this.state.loading}
              size="jumbo"
              componentClass="col-md-5 col-xs-9 mr-auto ml-auto mt10"
            >
              {updated && (
                <img className="ml5 mr5 pb2" src={greenTick} alt="green tick" />
              )}
            </DefaultButton>
            <Display.DefaultBodyText
              text={updated ? 'Your details have been updated.' : ''}
              componentClass="text-align-center"
            />
          </div>
        )}
        {showConfirmOrCancelModal && (
          <Display.Modal
            toggleModal={() =>
              this.setState({ showConfirmOrCancelModal: false })
            }
            title="Confirm Attendance"
            showModalCrossButton={false}
            hideButtonSection={true}
          >
            <div>{confirmationModalMessage}</div>
            <Display.ModalButtonGroup
              primaryLabel="Attending"
              secondaryLabel="Not Attending"
              secondaryButtonType="tertiary"
              primaryAction={() => this.attendeeConfirmation(Status_Confirmed)}
              secondaryAction={() =>
                this.attendeeConfirmation(Status_Cancelled)
              }
              disable={attendeeConfirmationButtonsDisabled}
              secondaryDisabled={attendeeConfirmationButtonsDisabled}
              isLoading={attendanceConfirmedLoading}
              secondaryIsLoading={attendanceNotConfirmedLoading}
              secondaryLoaderClassName="color-inherit"
            />
          </Display.Modal>
        )}
        {isError && (
          <ErrorMessage error={this.state.apiError || this.state.error} />
        )}
        {showSpinner && <Spinner />}
      </Display.CenterContentContainer>
    )
  }
}

export default AttendeeViewingConfirmation
