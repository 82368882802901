import React, { useEffect, useState } from 'react'

import { EXCLUDE_DATE_RANGE_TYPE } from 'app/sm/properties/components/excluded_times/constants'
import DateDropdown from 'app/sm/properties/components/excluded_times/partials/date_dropdown'
import { StyledSelectorsContainer } from 'app/sm/properties/components/excluded_times/partials/styled_components'
import { formattedUTCDisplayDate } from 'app/utils/datetime/helpers'

const type = EXCLUDE_DATE_RANGE_TYPE

const PropertyDateRange = ({ onEntryChange, initialState }) => {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    if (initialState) {
      setStartDate(initialState.startDate || '')
      setEndDate(initialState.endDate || '')
    }
  }, [])

  useEffect(() => {
    onEntryChange({
      type,
      startDate,
      endDate,
    })
  }, [startDate, endDate])

  return (
    <StyledSelectorsContainer>
      <DateDropdown
        date={startDate}
        onDateChangedHandler={setStartDate}
        width="230px"
        height="40px"
        labelRenderer={(date) => {
          return `From: ${formattedUTCDisplayDate(date)}`
        }}
      />

      <DateDropdown
        date={endDate}
        onDateChangedHandler={setEndDate}
        width="230px"
        height="40px"
        labelRenderer={(date) => {
          return `To: ${formattedUTCDisplayDate(date)}`
        }}
      />
    </StyledSelectorsContainer>
  )
}

export default PropertyDateRange
