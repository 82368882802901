import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { EXCLUDE_DATETIME_RANGE_TYPE } from 'app/sm/properties/components/excluded_times/constants'
import DateDropdown from 'app/sm/properties/components/excluded_times/partials/date_dropdown'
import { StyledSelectorsContainer } from 'app/sm/properties/components/excluded_times/partials/styled_components'
import TimePicker from 'app/sm/properties/components/excluded_times/partials/time_picker'
import { formattedUTCDisplayDate } from 'app/utils/datetime/helpers'

const type = EXCLUDE_DATETIME_RANGE_TYPE

const StyledTimeRange = styled(Flex)`
  margin: ${theme.space[4]}px 0;

  @media screen and (max-width: 576px) {
    margin: 0 0 8px 0;
  }
`

const PropertyDateTimeRange = ({ onEntryChange, initialState }) => {
  const [startDate, setStartDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')

  useEffect(() => {
    if (initialState) {
      setStartDate(initialState.startDate || '')
      setStartTime(initialState.startTime || '')
      setEndTime(initialState.endTime || '')
    }
  }, [])

  useEffect(() => {
    onEntryChange({
      type,
      startDate,
      startTime,
      endTime,
    })
  }, [startDate, startTime, endTime])

  return (
    <StyledSelectorsContainer>
      <DateDropdown
        date={startDate}
        initDate={startDate}
        onDateChangedHandler={setStartDate}
        labelRenderer={(date) => {
          return `${date ? formattedUTCDisplayDate(date) : 'Select date ..'}`
        }}
      />

      <StyledTimeRange>
        <TimePicker
          placeholder="start"
          time={startTime}
          onChangeHandler={setStartTime}
        />
        <span className="mt3 ml5 mr5">to</span>
        <TimePicker
          placeholder="end"
          time={endTime}
          onChangeHandler={setEndTime}
        />
      </StyledTimeRange>
    </StyledSelectorsContainer>
  )
}

export default PropertyDateTimeRange
