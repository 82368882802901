import { BG_CHECK_RENTER_PRICE_NUM } from 'app/utils/text/helpers'

export const combineBankruptcyAndCourtRecordsCheck = (
  bankruptcyCheck = {},
  courtRecordsCheck = {},
) => {
  return {
    result:
      bankruptcyCheck &&
      bankruptcyCheck['result'] === true &&
      courtRecordsCheck &&
      courtRecordsCheck['result'] === true,
    message: `${bankruptcyCheck && bankruptcyCheck['message']}\n${
      courtRecordsCheck && courtRecordsCheck['message']
    }`,
  }
}

export const checkBankruptcyCourtRecordsASISFromBGCheckResponse = (
  backgroundCheckResponse = {},
  backgroundCheck = {},
) => {
  const { identityVerification = {}, tenanacyBlacklistCheck = {} } =
    backgroundCheck || {}
  const { ConsumerTenantCheck = {} } = backgroundCheckResponse

  const {
    Bankruptcies = {},
    CourtRecords = {},
    DirectorshipsProprietorships = {},
  } = ConsumerTenantCheck || {}

  const { Bankruptcy: bankruptcyRecordsList = [] } = Bankruptcies || {}

  const { CourtRecords: courtRecordsList = [] } = CourtRecords || {}

  const {
    CurrentDirectorships = [],
    PreviousDirectorships = [],
    CurrentProprietorship = null,
    DisqualifiedDirectorship = null,
  } = DirectorshipsProprietorships || {}

  const { result: identityVerificationResult = false } =
    identityVerification || {}
  const { result: tenanacyBlacklistCheckResult = false } =
    tenanacyBlacklistCheck || {}
  const isDirectorshipsAndProprietorshipsRecord = !(
    (!CurrentDirectorships || CurrentDirectorships.length === 0) &&
    (!PreviousDirectorships || PreviousDirectorships.length === 0) &&
    !CurrentProprietorship &&
    !DisqualifiedDirectorship
  )

  return {
    isIdentityVerificationResults: identityVerificationResult,
    isTenanacyBlacklistCheckResults: tenanacyBlacklistCheckResult,
    isBankruptciesResults: Boolean(
      bankruptcyRecordsList && bankruptcyRecordsList.length > 0,
    ),
    isCourtRecordsResults: Boolean(
      courtRecordsList && courtRecordsList.length > 0,
    ),
    isDirectorshipsProprietorshipsResult:
      isDirectorshipsAndProprietorshipsRecord,
  }
}

const BC_PROMO_URL_PREFIX =
  'https://snugco.s3.ap-southeast-2.amazonaws.com/assets/img/promo-bgc-onpage-small-'

export const getBGCheckPromoURL = () => {
  return BC_PROMO_URL_PREFIX + BG_CHECK_RENTER_PRICE_NUM + '.png'
}

export const BG_CHECK_EXAMPLE_REPORT_PDF_URL =
  'https://snugco.s3.ap-southeast-2.amazonaws.com/docs/example_background_check_report_sep22a.pdf'
