import React from 'react'

import PropTypes from 'prop-types'

class AgencySearchBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideResults: false,
      queryResults: [],
      pageSize: 15,
    }
  }

  onInputBlur = (event) => {
    this.props.handleInputBlur(event)
    setTimeout(() => {
      this.setState({ hideResults: true })
    }, 200)
  }

  onInputChange = (event) => {
    const { handleInputChange } = this.props
    const { value } = event.target

    this.setState({ hideResults: false })
    handleInputChange({ value })

    clearInterval(this.t)
    this.t = setTimeout(this.triggerSearchFunction(value), 500)
  }

  onInputFocus = (event) => {
    this.setState({ hideResults: false })
  }

  onItemClick = (data) => {
    this.props.handleInputChange(data, this.props.inputChangeCallback)
    this.setState({ hideResults: true })
  }

  setQueryResults = (queryResults) => {
    this.setState({ queryResults })
  }

  triggerSearchFunction = (value) => {
    return () => {
      const { pageSize } = this.state
      const { searchFunction } = this.props
      if (value.trim() !== '') {
        searchFunction(value, pageSize, this.setQueryResults)
      }
    }
  }

  render() {
    const { value, className, placeholder, SearchResultItem, extraClassName } =
      this.props
    const { queryResults } = this.state
    const showSearchResults =
      queryResults &&
      queryResults.length !== 0 &&
      value &&
      !this.state.hideResults
    const listClassName = `search-box-list ${extraClassName}`
    return (
      <div className="search-box">
        <input
          className={className}
          type="text"
          value={value}
          onChange={this.onInputChange}
          onBlur={this.onInputBlur}
          onFocus={this.onInputFocus}
          placeholder={placeholder}
        />

        <div
          className={listClassName}
          display={showSearchResults ? '' : 'none'}
        >
          {showSearchResults &&
            queryResults.map((result, i) => {
              return (
                <SearchResultItem
                  key={i}
                  result={result}
                  i={i}
                  onItemClick={this.onItemClick}
                />
              )
            })}
        </div>
      </div>
    )
  }
}

AgencySearchBox.propTypes = {
  value: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  handleInputBlur: PropTypes.func,
  inputChangeCallback: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
}

AgencySearchBox.defaultProps = {
  handleInputBlur: () => {},
  inputChangeCallback: () => {},
  className: '',
  placeholder: '',
}

export default AgencySearchBox
