import { useContext } from 'react'

import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import SvgClock from 'app/components/design-system-components/icons/snug-specific/Clock'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { ActivityFeedContext } from 'app/shared_components/activity_feed/ActivityFeedContext'
import { isMobile } from 'app/shared_components/helpers'
import * as gtmHelpers from 'app/utils/google-tag-manager/helpers'
import { isEnableActivityFeedIconEnabled } from 'config/features'

const StyledContainer = styled(Box)`
  display: inline-block;
  &::after {
    content: '';
    border-right: 1px solid ${theme.colors.gray200};
    margin-right: ${theme.space[3]}px;
  }
`

const StyledManagerContainer = styled(Box)`
  display: inline-block;
  color: ${theme.colors.gray500};
  background-color: ${theme.colors.gray100};
  border-radius: ${theme.radii[8]}px;
  padding: 2px ${theme.space[2]}px 0 ${theme.space[1]}px;
  font-size: ${theme.fontSizes.pLarge16};
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  .dot {
    height: 6px;
    width: 6px;
    border-radius: 100%;
    background: ${theme.colors.primary300};
    position: absolute;
    right: ${theme.space[1]}px;
    top: ${theme.space[1]}px;
  }
  svg {
    color: ${theme.colors.gray500};
    height: ${theme.height[14]}px;
    width: ${theme.width[20]}px;
  }
`

const ActivityFeedIconManager = () => {
  const {
    showNavigator,
    setShowNavigator,
    hasFeed,
    setHasFeed,
    fetchInitialActivityFeedItems,
    fetchNewActivities,
    tabUtilityMap,
    openTab,
    currentTeam,
  } = useContext(ActivityFeedContext)

  if (!currentTeam) {
    return null
  }
  const { slug = '' } = currentTeam || {}

  const currentTabName = openTab.key
  const { actRef } = tabUtilityMap[currentTabName]

  const renderActivityIcon = () => {
    const Icon = SvgClock

    return (
      <Flex
        alignItems="center"
        onClick={() => {
          setHasFeed(false)
          setShowNavigator(true)
          hasFeed && actRef.activities.length > 0
            ? fetchNewActivities()
            : fetchInitialActivityFeedItems()
        }}
      >
        <Box>
          <Icon
            width="1.5em"
            height="1.5em"
            className={gtmHelpers.GTM_ACTIVITY_BUTTON_NAVBAR}
          />
        </Box>
        {!isMobile() && (
          <Box ml={1} className={gtmHelpers.GTM_ACTIVITY_BUTTON_NAVBAR}>
            Activity
          </Box>
        )}
      </Flex>
    )
  }

  return (
    <StyledContainer>
      <StyledManagerContainer
        mr={4}
        showNavigator={showNavigator}
        hasFeed={hasFeed}
      >
        {renderActivityIcon()}
        {hasFeed && isEnableActivityFeedIconEnabled(slug) && (
          <Box className="dot" />
        )}
      </StyledManagerContainer>
    </StyledContainer>
  )
}

export default ActivityFeedIconManager
