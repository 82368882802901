import React from 'react'

import { getApplicationStatus } from 'app/shared_components/helpers'
import { IdentityStatus } from 'app/sm/helpers'

const Request = ({ request, managerDeclined, lease, payment }) => {
  let requestHtml = null
  if (request && request.guidID) {
    const {
      submissionStatus,
      SecondaryTenantsApproved,
      pauseState,
      idCheckStatus,
      exitStatus,
      managerAction,
      appCode,
      CurrentLease: { isNewLease },
    } = request
    const { isMultiTenantLease } = lease

    const applicationStatus = getApplicationStatus(request, payment, lease)

    function getDeclineReason(declineReason) {
      switch (declineReason) {
        case 1:
          return 'Details are wrong'
        case 2:
          return 'Bad Renter'
        case 3:
          return 'Do not trust Bond Cover'
        case 4:
          return 'Low Rating'
        case 5:
          return 'Other'
        default:
          return 'unknown'
      }
    }
    function getPauseStatus(pauseState) {
      switch (pauseState) {
        case 0:
          return 'Not Paused'
        case 1:
          return 'Paused After Renter Submission'
        case 2:
          return 'Paused After Manager Action'
        case 3:
          return 'Paused Before Renter ID Check'
        case 4:
          return 'Paused Before Generating Bond Cover Certificate'
        default:
          return 'unknown'
      }
    }
    requestHtml = (
      <div className="contentBlock">
        <header>
          <span>BondCover Application</span>
        </header>
        <content>
          <p>
            Application Status: {applicationStatus} <br />
            Pause Status: {getPauseStatus(pauseState)} <br />
            Application Code: {appCode} <br />
            {managerDeclined
              ? 'Decline Reason: ' +
                getDeclineReason(managerDeclined.declineReason)
              : ''}
            {managerDeclined && managerDeclined.description ? <br /> : ''}
            {managerDeclined && managerDeclined.description
              ? 'Decline Description: ' + managerDeclined.description
              : ''}
          </p>
        </content>
      </div>
    )
  }
  return <div>{requestHtml}</div>
}

export default Request
