import * as React from 'react'

function SvgFacebookRounded(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99992 2.0835C7.90029 2.0835 5.88665 2.91757 4.40199 4.40223C2.91733 5.8869 2.08325 7.90053 2.08325 10.0002C2.08325 12.0998 2.91733 14.1134 4.40199 15.5981C5.88665 17.0828 7.90029 17.9168 9.99992 17.9168C12.0995 17.9168 14.1132 17.0828 15.5978 15.5981C17.0825 14.1134 17.9166 12.0998 17.9166 10.0002C17.9166 7.90053 17.0825 5.8869 15.5978 4.40223C14.1132 2.91757 12.0995 2.0835 9.99992 2.0835V2.0835Z"
        fill="#1877F2"
      />
      <path
        d="M11.0718 12.0986H13.1205L13.4422 10.0173H11.0713V8.87982C11.0713 8.01523 11.3538 7.24857 12.1626 7.24857H13.4622V5.43232C13.2338 5.40148 12.7509 5.33398 11.8384 5.33398C9.93301 5.33398 8.81593 6.34023 8.81593 8.63273V10.0173H6.85718V12.0986H8.81593V17.819C9.20384 17.8773 9.59676 17.9169 10.0001 17.9169C10.3647 17.9169 10.7205 17.8836 11.0718 17.8361V12.0986Z"
        fill="white"
      />
    </svg>
  )
}

export default SvgFacebookRounded
