import { connect } from 'react-redux'

import { changeTeam } from 'app/session/session_actions'
import { resetAdvertisement } from 'app/sm/advertisements/advertisement_actions'
import { fetchPropertyEntryDetails } from 'app/sm/onlist_details/action'
import {
  createProperty,
  deletePhoto,
  fetchProperty,
  fetchPropertyAttributes,
  ping,
  resetProperty,
  setMainImage,
  updateProperty,
  uploadPhoto,
} from 'app/sm/ppp/property_actions'
import PropertyForm from 'app/sm/ppp/property_components/property_form/property_form'

const mapStateToProps = ({ Ppp, session }) => ({
  attributes: Ppp.attributes,
  property: Ppp.property,
  error: Ppp.error,
  spinner: Ppp.spinner,
  responseText: Ppp.responseText,
  updatePropertyClicked: Ppp.updatePropertyClicked,
  currentTeam: session.currentTeam,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  resetAdvertisement: () => dispatch(resetAdvertisement()),
  createProperty: (property) => dispatch(createProperty(property)),
  updateProperty: (id, path) => (property) =>
    dispatch(updateProperty(id, property, path)),
  resetProperty: () => dispatch(resetProperty()),
  fetchProperty: (id) => dispatch(fetchProperty(id)),
  fetchPropertyAttributes: () => fetchPropertyAttributes(),
  uploadPhoto: (id, files) => dispatch(uploadPhoto(id, files)),
  deletePhoto: (propertyId, photoId) =>
    dispatch(deletePhoto(propertyId, photoId)),
  setMainImage: (propertyId, photoId) =>
    dispatch(setMainImage(propertyId, photoId)),
  ping: () => dispatch(ping()),
  changeTeam: (currentTeam) => dispatch(changeTeam(currentTeam)),
  fetchPropertyEntryDetails: (agencyGUID, propertyGUID) =>
    dispatch(fetchPropertyEntryDetails(agencyGUID, propertyGUID)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PropertyForm)
