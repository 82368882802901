import localforage from 'localforage'
import styled from 'styled-components'

import rocketYellow from 'app/assets/icons/rocket-yellow.svg'
import {
  Alert,
  Box,
  Button,
  Flex,
} from 'app/components/design-system-components'
import { CloseRounded } from 'app/components/design-system-components/icons/navigation'
import { mediaQueries } from 'app/components/design-system-components/styles/variables'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as gtmHelpers from 'app/utils/google-tag-manager/helpers'
import { HIDE_AUTORESPONDER_MESSAGES_SCREEN_STORAGE_KEY } from 'app/utils/text/helpers'

interface AlertForEnquiryResponderProps {
  toggleEnquiryResponderModal: Function
  fetchHideAutoresponderMessagesScreenKey: Function
}

const StyledAlert = styled(Alert)`
  ${mediaQueries.sm} {
    display: none;
  }
`

function AlertForEnquiryResponder({
  toggleEnquiryResponderModal,
  fetchHideAutoresponderMessagesScreenKey,
}: AlertForEnquiryResponderProps) {
  const onClickDismissMessageAlert = () => {
    localforage
      .setItem(HIDE_AUTORESPONDER_MESSAGES_SCREEN_STORAGE_KEY, true)
      .then(() => {
        fetchHideAutoresponderMessagesScreenKey()
      })
  }

  const message = (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center" width="83%">
        <img
          src={rocketYellow}
          alt="snug success"
          width={theme.width[7] + 'px'}
          height={theme.height[7] + 'px'}
        />
        <Box ml={5}>
          <Text as="span">
            Automatically nurture prospects through the leasing process, saving
            time and maximising attendance at your viewings.
          </Text>{' '}
          <Text as="span" fontWeight={theme.fontWeights.h1}>
            Get started in 60 seconds
          </Text>
          .{' '}
          <a
            href="https://help.snug.com/hc/en-us/articles/360001957375"
            target="_blank"
            rel="noreferrer"
          >
            learn more
          </a>
        </Box>
        <Box ml={3}>
          <Button
            onClick={() => toggleEnquiryResponderModal()}
            variant="greenHover"
            className={gtmHelpers.MESSAGES_AUTO_RESPONDER_MODAL_COMPLETE_BUTTON}
            width={theme.width[9] + 'px'}
          >
            Get Started
          </Button>
        </Box>
      </Flex>
      <Flex width="2%">
        <CloseRounded
          width={theme.width[6] + 'px'}
          height={theme.height[6] + 'px'}
          style={{ cursor: 'pointer' }}
          onClick={() => onClickDismissMessageAlert()}
        />
      </Flex>
    </Flex>
  )
  return (
    <StyledAlert
      children={message}
      variant="lightWarningWithBg"
      mb={8}
      fontSize={theme.fontSizes.pRegular14}
    ></StyledAlert>
  )
}

export default AlertForEnquiryResponder
