export const feedActivityStatuses = {
  success: {
    id: 'success',
    label: 'Success',
    color: 'green-hollow',
  },
  warning: {
    id: 'warning',
    label: 'Warning',
    color: 'yellow-hollow',
  },
  failed: {
    id: 'failed',
    label: 'Failed',
    color: 'red-hollow',
  },
}

export const feedActivityTypes = {
  propertyRelist: {
    id: 'property-relisted',
    label: 'Relisted',
  },
  propertyImported: {
    id: 'property-imported',
    label: 'Created',
  },
  propertyUpdated: {
    id: 'property-updated',
    label: 'Updated',
  },
  propertyFTP: {
    id: 'import-ftp-file',
    label: 'FTP',
  },
  propertyIssued: {
    id: 'property-unknown',
    label: 'Unkown',
  },
  propertyGeneric: {
    id: 'property-skipped',
    label: 'Skipped',
  },
}
