import { connect } from 'react-redux'

import { fetchTeamManagerList } from 'app/bond_cover/agency/agency_actions'
import {
  fetchPropertyReport,
  fetchPublicPropertyReport,
  sendViewingReport,
} from 'app/match/propertyReport/action'
import PropertyReportContainer from 'app/match/propertyReport/container'
import {
  addCurrentOwners,
  fetchAgencyApplicantsElasticSearchResult,
  fetchCurrentOwners,
  removeCurrentOwners,
  updateCurrentOwners,
} from 'app/sm/inspections/inspections_actions'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({ session }) => ({
  currentAgency: session.currentAgency,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  fetchPropertyReport: (viewingId) => dispatch(fetchPropertyReport(viewingId)),
  fetchPublicPropertyReport: (propertyReportId) =>
    dispatch(fetchPublicPropertyReport(propertyReportId)),
  fetchTeamManagerList: (teamGUID) => dispatch(fetchTeamManagerList(teamGUID)),
  sendViewingReport: (payload) => dispatch(sendViewingReport(payload)),
  fetchCurrentOwners: (teamId, queryString) =>
    dispatch(fetchCurrentOwners(teamId, queryString)),
  fetchAgencyApplicantsElasticSearchResult: (teamId, queryString) =>
    dispatch(fetchAgencyApplicantsElasticSearchResult(teamId, queryString)),
  addCurrentOwners: (data, teamId) => dispatch(addCurrentOwners(data, teamId)),
  updateCurrentOwners: (teamId, ownerId, data) =>
    dispatch(updateCurrentOwners(teamId, ownerId, data)),
  removeCurrentOwners: (teamId, contactId, propertyId) =>
    dispatch(removeCurrentOwners(teamId, contactId, propertyId)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyReportContainer)
