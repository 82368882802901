import React, { useState } from 'react'

import styled from 'styled-components'

import { Button } from 'app/components/design-system-components/Button'
import TextSpan from 'app/components/design-system-components/TextSpan'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const LinkBtnWrapper = styled(Button)`
  font-size: calc(1em * 0.8);
  font-weight: initial;
`

export const CollapsingText = ({
  text = '',
  maxCharacters = 100,
  textProps,
  linkProps,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  if (text.length <= maxCharacters) {
    return <TextSpan {...textProps}>{text}</TextSpan>
  }

  const linkText = isCollapsed ? 'show more' : 'show less'

  const linkClicked = () => {
    setIsCollapsed(!isCollapsed)
  }

  const linkElem = (
    <LinkBtnWrapper
      variant="linkBlue"
      onClick={() => linkClicked()}
      {...linkProps}
    >
      {linkText}
    </LinkBtnWrapper>
  )

  const collapseText = (textToHandle) => {
    const toShowText = textToHandle.slice(0, maxCharacters)
    return toShowText + '...'
  }

  const shownText = isCollapsed ? collapseText(text) : text

  return (
    <>
      <TextSpan {...textProps} mr={theme.space[3] + 'px'}>
        {shownText}
      </TextSpan>
      {linkElem}
    </>
  )
}
