import React from 'react'

import * as moment from 'moment'

import { Box } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import ReferenceResultComponent from 'app/match/rental_reference/display/renter_address_history_detailed/rental_reference_detailed/rental_reference_result/rental_reference_result_item'
import {
  answerView,
  ratingVersions,
} from 'app/match/rental_reference/rating_categories.constants'
import * as helpers from 'app/sm/helpers'
import { QuestionType } from 'app/sm/helpers'

const ReferenceResultList = ({
  ratings,
  shouldShowAllRatings,
  categories,
  renterName = '',
}) => {
  if (!ratings || !categories) {
    return
  }

  const { ReferenceSetVersion } = categories[0]

  const bondDeductionRating = ratings.find(
    (rating) => rating.code === helpers.ratingType.bondDeduction,
  )

  // render the old versions as is, as they don't have definitions in FE
  if (ReferenceSetVersion < ratingVersions.march2021.ref) {
    return (
      <div>
        {categories.map((rc) => {
          return (
            <div>
              {helpers.isQuestionTypeInt(rc.QuestionType) && (
                <div>
                  {!ratings.find((rating) => rc.Code === rating.code) && (
                    <ReferenceResultComponent
                      description={rc.Description}
                      isTextRating
                      textAnswer="Not Answered"
                    />
                  )}
                  {helpers.exceptionStarRating(ratings, rc) && (
                    <ReferenceResultComponent
                      rating={helpers.getStarRating(ratings, rc).answerValue}
                      isStarRating
                      description={rc.Description}
                    />
                  )}
                  {!helpers.exceptionStarRating(ratings, rc) &&
                    helpers.getStarRating(ratings, rc) && (
                      <div
                        className={`${
                          shouldShowAllRatings ? '' : 'd-none pdf-show-block'
                        }`}
                      >
                        <ReferenceResultComponent
                          rating={
                            helpers.getStarRating(ratings, rc).answerValue
                          }
                          isStarRating
                          description={rc.Description}
                        />
                      </div>
                    )}
                </div>
              )}
            </div>
          )
        })}
        {categories.map(
          (rc) =>
            helpers.isQuestionTypeBoolean(rc.QuestionType) && (
              <div key={rc.code}>
                {!ratings.find((rating) => rc.Code === rating.code) && (
                  <ReferenceResultComponent
                    description={rc.Description}
                    isTextRating
                    textAnswer="Not Answered"
                  />
                )}
                {helpers.exceptionBooleanRating(ratings, rc) && (
                  <div>
                    <ReferenceResultComponent
                      key={rc.Code + rc.Description}
                      description={rc.Description}
                      isBooleanRating
                      exception
                      boolRatingValue={helpers.parseBooleanRating(ratings, rc)}
                    />
                    {rc.Code === helpers.ratingType.bondDeduction &&
                      bondDeductionRating && (
                        <div className="grey-body-text">
                          <p className="font-size-14 m0">
                            Bond deduction details:{' '}
                            {bondDeductionRating.comment
                              ? bondDeductionRating.comment
                              : 'Not provided'}
                          </p>
                        </div>
                      )}
                  </div>
                )}
                {helpers.getBooleanRating(ratings, rc) &&
                  helpers.getBooleanRating(ratings, rc).exception === false && (
                    <div
                      key={rc.code}
                      className={`${
                        shouldShowAllRatings ? '' : 'd-none pdf-show-block'
                      }`}
                    >
                      <ReferenceResultComponent
                        key={rc.Code + rc.Description}
                        description={rc.Description}
                        isBooleanRating
                        exception={false}
                        boolRatingValue={helpers.parseBooleanRating(
                          ratings,
                          rc,
                        )}
                      />
                    </div>
                  )}
              </div>
            ),
        )}
      </div>
    )
  }

  const uiCategoryDefs = Object.values(ratingVersions).find(
    (version) => version.ref === ReferenceSetVersion,
  )

  const mappedCategoriesWithAnswers = (categories, detail) => {
    return categories.map((category) => ({
      category,
      uiCategoryDef: Object.values(uiCategoryDefs.questions).find(
        (question) => question.id === category.Code,
      ),
      rating: ratings.find((rating) => rating.code === category.Code),
      detail: detail.includes(category.Code) ? true : false,
    }))
  }

  const generateAnswerView = (mappedCategory) => {
    const { rating, category, uiCategoryDef, detail } = mappedCategory
    if (!uiCategoryDef) {
      return
    }

    if (!rating) {
      return (
        <ReferenceResultComponent
          description={uiCategoryDef.description(renterName)}
          isNoAnswers
          isDetailAnswers={detail}
        />
      )
    }

    if (uiCategoryDef.answerView === answerView.stars) {
      return (
        <ReferenceResultComponent
          description={uiCategoryDef.description(renterName)}
          isStarRating
          exception={rating.exception}
          rating={rating.answerValue}
          isDetailAnswers={detail}
        />
      )
    }

    if (
      uiCategoryDef.answerView === answerView.boolean ||
      uiCategoryDef.type === QuestionType.Boolean
    ) {
      return (
        <ReferenceResultComponent
          description={uiCategoryDef.description(renterName)}
          isBooleanRating
          exception={rating.exception}
          boolRatingValue={helpers.parseBooleanRating([rating], category)}
          isDetailAnswers={detail}
        />
      )
    }

    if (uiCategoryDef.answerView === answerView.textBlock) {
      return (
        <ReferenceResultComponent
          description={uiCategoryDef.description(renterName)}
          isTextBlock
          exception={rating.exception}
          textAnswer={rating.answerValue}
          isDetailAnswers={detail}
        />
      )
    }

    if (uiCategoryDef.answerView === answerView.date) {
      return (
        <ReferenceResultComponent
          description={uiCategoryDef.description(renterName)}
          isTextRating
          exception={rating.exception}
          textAnswer={moment(rating.answerValue).format(
            helpers.dateDayShortStringMonthYear,
          )}
          isDetailAnswers={detail}
        />
      )
    }

    return (
      <ReferenceResultComponent
        description={uiCategoryDef.description(renterName)}
        isTextRating
        exception={rating.exception}
        textAnswer={rating.answerValue}
        isDetailAnswers={detail}
      />
    )
  }

  const renderQuestionsSection = ({ questions, label }) => {
    const generalCodes = questions.map((question) => question.key)

    const answeredGeneralCodes = questions
      .filter((question) =>
        ratings.map((rating) => rating.code).includes(question.key),
      )
      .map((question) => question.key)

    let detailQuestionsCode = []
    let onlyDetailsCode = []

    generalCodes.forEach((code) => {
      const detailCode = answeredGeneralCodes.includes(code)
        ? questions.find((question) => question.key === code).details
        : []

      detailQuestionsCode = [...detailQuestionsCode, code, ...detailCode]
      onlyDetailsCode = [...onlyDetailsCode, ...detailCode]
    })

    const generalQuestions = categories.filter((category) =>
      answeredGeneralCodes.includes(category['Code']),
    )

    const detailQuestions = categories.filter((category) =>
      detailQuestionsCode.includes(category['Code']),
    )

    const renderQuestions = shouldShowAllRatings
      ? [...generalQuestions]
      : [...detailQuestions]

    const uniqueIds = new Set()

    const removeDuplicateQuestions = renderQuestions.filter((element) => {
      const isDuplicate = uniqueIds.has(element.Code)
      uniqueIds.add(element.Code)

      if (!isDuplicate) {
        return true
      }

      return false
    })

    const questionsMapped = mappedCategoriesWithAnswers(
      removeDuplicateQuestions,
      onlyDetailsCode,
    )

    return (
      <>
        {questionsMapped.length > 0 && <div>{label}</div>}
        {questionsMapped.map((question) => generateAnswerView(question))}
      </>
    )
  }

  return (
    <>
      {Object.keys(helpers.questionsAccordingRentalHistoryReference).map(
        (rentalHistoryKey) => (
          <Box my={theme.space[6] + 'px'}>
            {renderQuestionsSection(
              helpers.questionsAccordingRentalHistoryReference[
                rentalHistoryKey
              ],
            )}
          </Box>
        ),
      )}
    </>
  )
}

export default ReferenceResultList
