import React from 'react'

import { Link } from 'react-router-dom'

import Amenities from 'app/sm/common/amenities'
import AvatarPlaceholder from 'app/sm/common/avatar_placeholder'
import {
  isPropertyArchived,
  isPropertyDraft,
  isPropertyLeased,
  isPropertyTakingApplications,
  NO_IMAGE_PLACEHOLDER_URL,
  PropertyAdStatus,
  PropertyStatus,
  urlTo,
} from 'app/sm/helpers'
import WithdrawAdModal from 'app/sm/properties/components/withdraw_ad_modal'
import { FeatureFlag } from 'config/features'

function formatTerm(termField) {
  //TODO: use the first return when backend is ready
  // return termField.split(';').join(', ')
  return termField
}
function isDraftAd(ad) {
  return (
    ad !== null &&
    (PropertyAdStatus.find((s) => s.id === ad.status) || {}).label === 'Draft'
  )
}

function checkIsAdvertised(ad) {
  return (
    ad !== null &&
    (PropertyAdStatus.find((s) => s.id === ad.status) || {}).label ===
      'Published'
  )
}

class PropertyCard extends React.Component {
  onArchiveProperty = (guidID, propertyStatus) => {
    if (
      propertyStatus !== 'TakingApplications' ||
      window.confirm(
        'The property is currently taking applications. Do you want to archive it?',
      )
    ) {
      this.props.archiveProperty(guidID)
    }
  }

  onUnarchiveProperty(guidID) {
    if (
      window.confirm(
        'The property is currently archived. Do you want to unarchive it?',
      )
    ) {
      this.props.unarchiveProperty(guidID)
    }
  }

  render() {
    const {
      currentUser,
      propertyInfo,
      withdrawAdvertisement,
      withdrawModalOn,
      withdrawModalOff,
      withdrawModalId,
    } = this.props
    const { property, acceptedApplication, renter } = propertyInfo
    const activeOffer = (property.offers || []).find((o) => o.isActive) || {}
    const userAvatar = currentUser.avatar
    const { acceptedLeaseLength = '/', weeklyRent = '/' } = activeOffer
    const { guidID: propertyId } = property
    const hasActiveOfferAds = activeOffer.ads && activeOffer.ads.length > 0
    const hasPropertyImages = property.images && property.images.length > 0
    const showAdModal =
      FeatureFlag.Advertisement.isOn && withdrawModalId === propertyId

    let ad = null
    let imageUrl = NO_IMAGE_PLACEHOLDER_URL
    let address = ''
    let suburb = ''

    if (hasActiveOfferAds) {
      ad = activeOffer.ads[0]
    }

    if (hasPropertyImages) {
      imageUrl = (
        property.images.find((image) => image.isMain === true) ||
        property.images[0]
      ).URL
    }

    if (property.address) {
      if (property.address.unitNumber) {
        address = `${property.address.unitNumber}/${property.address.streetNumber} ${property.address.streetName}`
      } else {
        address = `${property.address.streetNumber} ${property.address.streetName}`
      }
      suburb = property.address.suburb
    }

    if (showAdModal) {
      return (
        <WithdrawAdModal
          withdrawModalOff={withdrawModalOff}
          ok={() => withdrawAdvertisement(ad && ad.guidID)}
        />
      )
    }
    const isAdvertised =
      FeatureFlag.Advertisement.isOn &&
      (PropertyStatus[property.status] === 'Advertised' ||
        checkIsAdvertised(ad))

    return (
      <div className="sm-properties-card-list-item">
        <div className="sm-properties-card-list-item-wrapper">
          <Link
            to={urlTo('propertyDashboard', { propertyId })}
            className="image"
            style={{ backgroundImage: `url(${imageUrl})` }}
          ></Link>
          {isPropertyDraft(property.status) && (
            <div className="status s1">Draft</div>
          )}
          {isPropertyLeased(property.status) && (
            <div className="status s2">Rented</div>
          )}
          {isPropertyTakingApplications(property.status) && (
            <div className="status s3">Taking Applications</div>
          )}

          {isAdvertised && <div className="status s3">Advertised</div>}

          {isPropertyArchived(property.status) && (
            <div className="status s0">Archived</div>
          )}

          <div className="dropdown actions">
            <div className="dropdown-toggle" data-toggle="dropdown">
              <i className="icon-more"></i>
            </div>
            <ul className="dropdown-menu">
              <li>
                <Link to={urlTo('updatePropertyBasics', { id: propertyId })}>
                  Edit property
                </Link>
              </li>

              {property.archived === false && (
                <li
                  className="link-styles"
                  onClick={() =>
                    this.onArchiveProperty(
                      property.guidID,
                      PropertyStatus[property.status],
                    )
                  }
                >
                  Archive
                </li>
              )}

              {/* {PropertyStatus[property.status] === 'Archived' &&
                <li className="link-styles" onClick={() => this.onUnarchiveProperty(property.guidID)}>Unarchive</li>
              } */}

              {FeatureFlag.Advertisement.isOn &&
                activeOffer.guidID !== undefined &&
                ad === null && (
                  <li>
                    <Link
                      to={urlTo('updatePropertyDescription', {
                        propertyId,
                        offerId: activeOffer.guidID,
                      })}
                    >
                      Leased
                    </Link>
                  </li>
                )}
              {FeatureFlag.Advertisement.isOn && isAdvertised(ad) && (
                <li
                  className="link-styles"
                  onClick={() => withdrawModalOn(propertyId)}
                >
                  Withdraw Advert
                </li>
              )}

              {FeatureFlag.Advertisement.isOn && isDraftAd(ad) && (
                <li>
                  <Link
                    to={urlTo('updateOfferPortals', {
                      propertyId,
                      offerId: activeOffer.guidID,
                    })}
                  >
                    Edit Draft Ad
                  </Link>
                </li>
              )}
              {/* {isAdvertised(ad) &&
                <li>
                  <Link to={urlTo('updateOfferPortals', { propertyId, offerId: activeOffer.guidID })}>Edit Advert</Link>
                </li>
              } */}
            </ul>
          </div>
          <div className="meta">
            <div className="address p0">
              <div className="street">{address}</div>
              <div className="suburb mb10">
                {suburb}, {property.address.postcode}
              </div>
              <Amenities
                payload={property}
                wrapClassName={'amenities-widget'}
              />
            </div>

            {isPropertyLeased(property.status) && (
              <div className="user">
                {userAvatar ? (
                  <div
                    className="avatar avatar-xsmall"
                    style={{ backgroundImage: `url(${userAvatar})` }}
                  ></div>
                ) : (
                  <AvatarPlaceholder currentUser={renter} />
                )}
                <div className="name">{`${
                  renter.firstName
                } ${renter.lastName.substring(0, 1)}.`}</div>
                <a className="message" href="#">
                  {renter ? renter.mobilePhone : null}
                </a>
              </div>
            )}
          </div>
          {isPropertyLeased(property.status) ? (
            <ul className="options">
              <li>
                <i className="icon-date"></i>
                <span>
                  Term: {formatTerm(acceptedApplication.term)} month
                  {acceptedApplication.term > 1 ? 's' : ''}
                </span>
              </li>
              <li>
                <i className="icon-rent"></i>
                <span>${acceptedApplication.weeklyRent} p/w</span>
              </li>
            </ul>
          ) : (
            <ul className="options">
              <li>
                <i className="icon-date"></i>
                <span>
                  Term: {formatTerm(acceptedLeaseLength)}{' '}
                  {acceptedLeaseLength !== '/' ? 'months' : ''}
                </span>
              </li>
              <li>
                <i className="icon-rent"></i>
                <span>
                  Asking rent: {weeklyRent !== '/' ? '$' : ''}
                  {weeklyRent} {weeklyRent !== '/' ? '(p/w)' : ''}
                </span>
              </li>
            </ul>
          )}
          {[
            'TakingApplications',
            FeatureFlag.Advertisement.isOn ? 'Advertised' : '',
          ].includes(PropertyStatus[property.status]) && (
            <Link
              to={urlTo('propertyDashboard', { propertyId })}
              className="btn btn-pink"
            >
              <i className="icon-applications left white-color"></i>
              <span>View property</span>
              {propertyInfo.applicationsCount !== undefined ? (
                <div className="badge-large">
                  {propertyInfo.applicationsCount}
                </div>
              ) : (
                ''
              )}
            </Link>
          )}

          {isPropertyLeased(property.status) && (
            <Link
              to={urlTo('propertyDashboard', { propertyId })}
              className="btn btn-pink"
            >
              <i className="icon-applications left white-color"></i>
              <span>View property</span>
            </Link>
          )}
          {isPropertyDraft(property.status) && (
            <Link
              to={urlTo('updatePropertyBasics', { id: propertyId })}
              className="btn btn-gray"
            >
              <span>Edit and publish property</span>
            </Link>
          )}
        </div>
      </div>
    )
  }
}

export default PropertyCard
