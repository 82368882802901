import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'

export const formFields = {
  acceptBallotTermsAndConditions: 'acceptBallotTermsAndConditions',
  victorianResidentsOrAustralianCitizens:
    'victorianResidentsOrAustralianCitizens',
  householdMeetsIncomeLimits: 'householdMeetsIncomeLimits',
  noHouseholdMembersOwnProperty: 'noHouseholdMembersOwnProperty',
  documentsWithinDays: 'documentsWithinDays',
  householdCanMoveIn: 'householdCanMoveIn',
}

export const formLabel = {
  acceptBallotTermsAndConditions: 'Accept the Terms and Conditions',
  victorianResidentsOrAustralianCitizens:
    'All household members are Australian citizens or permanent residents',
  householdMeetsIncomeLimits:
    'Our household income falls under the relevant gross income threshold (see Table 1 below)',
  noHouseholdMembersOwnProperty: 'No household members own property',
  documentsWithinDays:
    'If successful, required documents and information will be supplied within 2 business days',
  householdCanMoveIn:
    'If successful, our household can move in within 30 days of accepting an offer',
}

export const getFieldLabel = (key) => {
  return formLabel[key]
}

const fieldValidation = (fieldName, val) => {
  // eslint-disable-next-line default-case
  switch (fieldName) {
    case formFields.victorianResidentsOrAustralianCitizens: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    case formFields.documentsWithinDays: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    case formFields.householdMeetsIncomeLimits: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    case formFields.noHouseholdMembersOwnProperty: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    case formFields.acceptBallotTermsAndConditions: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    case formFields.householdCanMoveIn: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
