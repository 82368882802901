import { connect } from 'react-redux'

import { addRentalReputationBackUrl } from 'app/shared_components/actions'
import {
  getEmergencyContacts,
  removeEmergencyContact,
} from 'app/sm/emergency_contact/emergency_contact_actions'
import EmergencyContactAdd from 'app/sm/emergency_contact/emergency_contact_add'
import { fetchCompleteness } from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({
  session,
  EmergencyContact,
  Shared,
  RentalReputation,
}) => ({
  emergencyContacts: EmergencyContact.emergencyContacts,
  backUrl: Shared.backUrl,
  currentUser: session.currentUser,
  completeness: RentalReputation.completeness,
})

const mapDispatchToProps = (dispatch) => ({
  getEmergencyContacts: () => dispatch(getEmergencyContacts()),
  removeEmergencyContact: (id) => dispatch(removeEmergencyContact(id)),
  addRentalReputationBackUrl: (rentalBackUrl) =>
    dispatch(addRentalReputationBackUrl(rentalBackUrl)),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyContactAdd)
