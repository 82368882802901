import React from 'react'

import moment from 'moment'

import humanIcon01 from 'app/assets/icons/human01.jpg'
import { BooleanAnswerComponent } from 'app/bond_cover/renters/history/components/rating_boolean_answer_component'
import { StarComponent } from 'app/bond_cover/renters/history/components/rating_star_component'
import { TextAnswerComponent } from 'app/bond_cover/renters/history/components/rating_text_component'
import DocumentList from 'app/shared_components/document_list/document_list'
import {
  isQuestionTypeBoolean,
  isQuestionTypeInt,
  isQuestionTypeText,
  stateConverter,
} from 'app/sm/helpers'

class RatingForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: '',
      showingToast: false,
    }
  }

  render() {
    const {
      isHistoryRating,
      referenceId,
      comment,
      address,
      requestCode,
      disabled,
      permissionClicked,
      declineClicked,
      ratingCategories,
      update,
      updateTenantRating,
      tenantRating,
      renter,
      proceed,
      decline,
      rentalHistoryReview: { RentalHistory = {} } = {},
      addRentalLedger,
      removeRentalLedger,
      showRentalLedgerSpinner = false,
      ledger = [],
      secretGUID,
      error,
      getAnswerValue,
      disabledRating,
      learnMoreFromSnug,
      onLearnMoreClicked,
    } = this.props
    const { firstName, lastName, mobilePhone, disclosureURL } = renter || {}
    let addressBinding = ''
    let { startDate, endDate } = RentalHistory

    startDate = moment(startDate).format('MMM YYYY')
    endDate = endDate ? moment(endDate).format('MMM YYYY') : 'present'

    if (address !== undefined) {
      let {
        friendlyName = '',
        suburb = '',
        state = '',
        postcode = '',
      } = address
      state = stateConverter(state)
      addressBinding = `${friendlyName}, ${suburb}, ${state} ${postcode}`
    }

    if (!ratingCategories || ratingCategories.length == null) {
      return <div />
    } else {
      return (
        <div className="p20">
          <h3 className="mb10">Help {firstName} with a rental reference</h3>

          <p className="mb30 fw600">
            Please help build {firstName}'s Rental Reputation by completing a
            reference.
          </p>
          <p className="mb30 fw600">
            {firstName} has authorised disclosure of their rental history.{' '}
            <a href={disclosureURL} target="_blank" rel="noreferrer">
              View Disclosure Authority
            </a>
          </p>

          <div className="">
            <div className="">
              <div className="font-size-18 mb30">
                <div className="flex-row-center pb10">
                  <span className="ibm fw600 pr10 mra w25p-mw45p">
                    Property:
                  </span>
                  <span className="ibm w75p-mw55p">{addressBinding}</span>
                </div>

                <div className="flex-row-center pb10">
                  <span className="ibm fw600 pr10 mra w25p-mw45p">
                    Lease Term:
                  </span>
                  <span className="ibm w75p-mw55p">
                    {startDate} to {endDate}
                  </span>
                </div>

                <div className="flex-row-center pb10">
                  <span className="ibm fw600 pr10 mra w25p-mw45p">Renter:</span>
                  <span className="ibm w75p-mw55p">
                    {firstName} (m {mobilePhone})
                  </span>
                </div>
              </div>
              <div className="mb30 pb0 pl0">
                <ul className="snug-infos mb0">
                  <li>
                    <h4>References &amp; privacy</h4>
                    Rental References are private. Only {firstName} can see and
                    share the details with authorised and verified Snug members
                    in accordance with our Privacy Policy.
                  </li>
                </ul>
              </div>
              {ratingCategories
                .filter((j) => isQuestionTypeInt(j.QuestionType))
                .map((j) => {
                  return (
                    <StarComponent
                      key={j.GuidID}
                      questionCategory={j}
                      updateQuestionAnswer={updateTenantRating}
                      existingAnswer={getAnswerValue(
                        j.Code,
                        tenantRating,
                        'code',
                      )}
                      referenceId={isHistoryRating ? requestCode : referenceId}
                      disabledRating={disabledRating}
                    />
                  )
                })}
              {ratingCategories
                .filter((j) => isQuestionTypeText(j.QuestionType))
                .map((j) => {
                  return (
                    <TextAnswerComponent
                      key={j.GuidID}
                      questionCategory={j}
                      updateQuestionAnswer={updateTenantRating}
                      referenceId={isHistoryRating ? referenceId : requestCode}
                      tenantRating={tenantRating}
                      getAnswerValue={getAnswerValue}
                      disabledRating={disabledRating}
                    />
                  )
                })}
              {ratingCategories
                .filter((j) => isQuestionTypeBoolean(j.QuestionType))
                .map((j) => {
                  return (
                    <BooleanAnswerComponent
                      key={j.GuidID}
                      questionCategory={j}
                      questionCategoryKey={j.Code + j.Description}
                      questionCategoryQuestionKey={j.Code}
                      existingAnswer={getAnswerValue(
                        j.Code,
                        tenantRating,
                        'code',
                      )}
                      getAnswerValue={getAnswerValue}
                      updateQuestionAnswer={updateTenantRating}
                      referenceId={isHistoryRating ? requestCode : referenceId}
                      tenantRating={tenantRating}
                      disabledRating={disabledRating}
                    />
                  )
                })}

              <TextAnswerComponent
                title="Add a comment"
                update={update('comment')}
                disabledRating={true}
                value={comment}
              />

              <div className="rating-categories-box">
                <div className="rating-desc">Upload tenant ledger</div>
                <DocumentList
                  documents={ledger}
                  addDocument={(documentType) =>
                    addRentalLedger(referenceId, secretGUID, documentType)
                  }
                  deleteDocument={(guidID) =>
                    removeRentalLedger(referenceId, secretGUID, guidID)
                  }
                  documentType={4}
                  spinner={showRentalLedgerSpinner}
                  responseText={error}
                />
              </div>

              <div className="mb0 pb0 pl0">
                <ul className="snug-infos mb0">
                  <li className="bank">
                    We use SSL encryption and best practices to secure your
                    rental reference information.
                  </li>
                </ul>
              </div>
              <div className="mt20">
                <label className="display-flex mb15">
                  <input
                    type="checkbox"
                    checked={learnMoreFromSnug}
                    onChange={() => onLearnMoreClicked()}
                  />
                  <span>
                    I accept the Privacy Policy, Terms of Use and to receive
                    important Snug platform messages
                  </span>
                </label>
              </div>

              <button
                className="mt40 mb10 rental-review-submitted"
                disabled={disabled}
                onClick={proceed}
              >
                {' '}
                Submit Reference{' '}
                <i
                  className={permissionClicked ? 'fa fa-spinner fa-pulse' : ''}
                />{' '}
              </button>
              <button
                className="btn btn-transparent rental-review-declined"
                disabled={disabled}
                onClick={isHistoryRating ? decline : proceed}
              >
                {' '}
                I am unable to provide a reference{' '}
                <i
                  className={declineClicked ? 'fa fa-spinner fa-pulse' : ''}
                />{' '}
              </button>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default RatingForm
