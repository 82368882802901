const filtersActionsTypes = {
  updateFilter: 'UPDATE_FILTER',
  updateSearch: 'UPDATE_SEARCH',
  resetAllFilters: 'RESET_ALL_FILTERS',
  updateAllFilters: 'UPDATE_ALL_FILTERS',
}

const updateFilter = (filterId, filterValues) => {
  return {
    type: filtersActionsTypes.updateFilter,
    filterId,
    filterValues,
  }
}

const updateAllFilters = (filtersValues) => {
  return {
    type: filtersActionsTypes.updateAllFilters,
    filtersValues,
  }
}

const updateSearch = (searchText) => {
  return {
    type: filtersActionsTypes.updateSearch,
    searchText,
  }
}

const resetAllFilters = () => {
  return {
    type: filtersActionsTypes.resetAllFilters,
  }
}

export const filtersActions = {
  updateFilter,
  updateAllFilters,
  updateSearch,
  resetAllFilters,
}

export const filterReducerFactory = (filtersInitialState) => {
  return (state, action) => {
    const { type } = action
    switch (type) {
      case filtersActionsTypes.updateFilter: {
        const { filterId, filterValues } = action
        return {
          ...state,
          filters: {
            ...state.filters,
            [filterId]: filterValues,
          },
        }
      }
      case filtersActionsTypes.updateSearch: {
        const { searchText } = action
        return {
          ...state,
          search: searchText,
        }
      }
      case filtersActionsTypes.resetAllFilters: {
        return {
          ...filtersInitialState,
        }
      }
      case filtersActionsTypes.updateAllFilters: {
        const { filtersValues } = action
        return {
          ...filtersInitialState,
          ...filtersValues,
          search: filtersValues.search || filtersInitialState.search,
          filters: {
            ...filtersInitialState.filters,
            ...filtersValues.filters,
          },
        }
      }
      default:
        return state
    }
  }
}
