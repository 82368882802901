import React from 'react'

import { connect } from 'react-redux'

import { websiteUrl } from 'app/shared_components/shared_base_url'
import { fetchEmployeeDetail } from 'app/sm/employment/actions'

const mapStateToProps = ({ Employment }) => ({
  employee: Employment.employee,
})

const mapDispatchToProps = (dispatch) => ({
  fetchEmployeeDetail: (id, slug, success, fail) =>
    dispatch(fetchEmployeeDetail(id, slug, success, fail)),
})

class EmploymentVerifyConfirmation extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const {
      match: {
        params: { id, slug },
      },
      fetchEmployeeDetail,
    } = this.props
    fetchEmployeeDetail(id, slug)
  }

  render() {
    const { employee } = this.props

    return (
      <div className="two-col-box sm64">
        <div className="col-first xs-second">
          <p>
            Thank you for confirming the employment details of&nbsp;
            {employee.renterFirstName} {employee.renterLastName}.
          </p>
          <p>
            Visit us at <a href={websiteUrl}>snug.com</a> to find out more about
            how we're making renting easier.
          </p>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmploymentVerifyConfirmation)
