import GenericButtonComponent from 'app/components/buttons/default/component'
import NoteComponent from 'app/components/display/application_note/component'
import ApplicationSectionDetailsComponent from 'app/components/display/application_section_details/component'
import AvatarComponent from 'app/components/display/avatar/component'
import BottomContentContainerComponent from 'app/components/display/bottom_content_container/component'
import BreadcrumbsComponent from 'app/components/display/breadcrums/component'
import CenterContentContainerComponent from 'app/components/display/center_content_container/component'
import * as contactLinks from 'app/components/display/contact_links/component'
import CrossSellItemComponent from 'app/components/display/cross_sell_item/component'
import DetailsBoxCardComponent from 'app/components/display/details_box_card/component'
import EditViewingModalComponent from 'app/components/display/edit_viewing_modal'
import ErrorMessageContainerComponent from 'app/components/display/error_message_container/component'
import DocumentListItemSecondVersionComponent from 'app/components/display/file_uploader/component'
import DropZoneBasicComponent from 'app/components/display/file_uploader_fe_independent/component'
import DropZoneSnugStandardComponent from 'app/components/display/file_uploader_snug_standard/component'
import FlexContainerComponent from 'app/components/display/flex_container/component'
import GreyContainerComponent from 'app/components/display/grey_container/component'
import ModalComponent from 'app/components/display/modal/component'
import ModalBottomButtonGroup from 'app/components/display/modal_bottom_buttons'
import ModalWithScrollComponent from 'app/components/display/modal_with_scroll/component'
import NoticeMessageContainerComponent from 'app/components/display/notice_message_container/component'
import PillComponent from 'app/components/display/pill/component'
import ProfilePagesBottomButtonsComponent from 'app/components/display/profile_pages_bottom_buttons/component'
import ProfilePagesRowLayoutComponent from 'app/components/display/profile_pages_row_layout/component'
import ProgressLineComponent from 'app/components/display/progress_line/component'
import PropertyLabelsComponent from 'app/components/display/property_labels/component'
import PropertyInfoDetailsComponent from 'app/components/display/propertyInfoDetails/component'
import ReportFeedbackMessageComponent from 'app/components/display/report_feedback_message/component'
import ResendReferenceComponent from 'app/components/display/resend_reference/component'
import SearchInputBoxComponent from 'app/components/display/search_input_box/component'
import SectionContentContainerComponent from 'app/components/display/section_content_container/component'
import SnugTipComponent from 'app/components/display/snug_tip/component'
import StandardContactInfoContainerComponent from 'app/components/display/standard_contact_info_container/standard_contact_info_container'
import StatusPillComponent from 'app/components/display/status_pill/component'
import * as standardBodyComponents from 'app/components/display/text/standard_text/standard-body/component'
import * as standardHeadingComponents from 'app/components/display/text/standard_text/standard-headings/component'
import ToolsButtonComponent, {
  ToolsButtonWithTextComponent,
} from 'app/components/display/tools_button/component'
import WhiteBoxContainerComponent from 'app/components/display/white-box-container/component'

export const CrossSellItem = CrossSellItemComponent
export const GreyContainer = GreyContainerComponent
export const ProfilePagesBottomButtons = ProfilePagesBottomButtonsComponent
export const ProfilePagesRowLayout = ProfilePagesRowLayoutComponent
export const SnugTip = SnugTipComponent
export const {
  GreyBodyText,
  DefaultBodyText,
  SmallCaption,
  SectionMinorTitle,
} = standardBodyComponents
export const { MainHeader, SectionHeader, ContainerHeader, GreySubheading } =
  standardHeadingComponents
export const CenterContentContainer = CenterContentContainerComponent
export const FlexContainer = FlexContainerComponent
export const BottomContentContainer = BottomContentContainerComponent
export const StandardContactInfoContainer =
  StandardContactInfoContainerComponent
export const Pill = PillComponent
export const Modal = ModalComponent
export const PropertyInfoDetails = PropertyInfoDetailsComponent
export const ReportFeedbackMessage = ReportFeedbackMessageComponent
export const WhiteBoxContainer = WhiteBoxContainerComponent
export const SectionContentContainer = SectionContentContainerComponent
export const ErrorMessageContainer = ErrorMessageContainerComponent
export const NoticeMessageContainer = NoticeMessageContainerComponent
export const DocumentListItemSecondVersion =
  DocumentListItemSecondVersionComponent
export const GenericButton = GenericButtonComponent
export const { EmailLink, TelLink } = contactLinks
export const ProgressLine = ProgressLineComponent
export const Breadcrumbs = BreadcrumbsComponent
export const GeneralSearchBox = SearchInputBoxComponent
export const Avatar = AvatarComponent
export const Note = NoteComponent
export const DropZoneBasic = DropZoneBasicComponent
export const DropZoneSnugStandard = DropZoneSnugStandardComponent
export const DetailsBoxCard = DetailsBoxCardComponent
export const ApplicationSectionDetails = ApplicationSectionDetailsComponent
export const ModalWithScroll = ModalWithScrollComponent
export const StatusPill = StatusPillComponent
export const ToolsButton = ToolsButtonComponent
export const ToolsButtonWithText = ToolsButtonWithTextComponent
export const EditViewingModal = EditViewingModalComponent
export const ResendReference = ResendReferenceComponent
export const ModalButtonGroup = ModalBottomButtonGroup
export const PropertyLabels = PropertyLabelsComponent
export * from './three_column_layout'
