import React from 'react'

import { getNumberType } from 'libphonenumber-js'
import metadata from 'libphonenumber-js/metadata.full.json'
import moment from 'moment'
import qs from 'qs'
import { connect } from 'react-redux'
import styled from 'styled-components'

import * as Form from 'app/components/forms/forms'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import * as snugNotifier from 'app/services/snugNotifier'
import FAQs from 'app/shared_components/faqs'
import {
  ErrorMessage,
  isValidLandlineNumber,
  isValidMobileNumber,
} from 'app/shared_components/helpers'
import RentalReputationHeader from 'app/shared_components/rental_reputation_header'
import * as validations from 'app/shared_components/validations'
import {
  disproveEmployee,
  fetchEmployee,
  verifyEmployee,
} from 'app/sm/employment/actions'
import EmployerContactBox from 'app/sm/employment/employer_contact_box'
import EmploymentBox from 'app/sm/employment/employment_box'
import Reasons from 'app/sm/employment/verify_fail_reasons'
import * as helpers from 'app/sm/helpers'
import { shouldCurrencyUpdate } from 'app/sm/helpers'

const MAX_CHAR_REASON_AFFECTING_EMPLOYMENT = 300
const MIN_CHAR_REASON_AFFECTING_EMPLOYMENT = 4

const CommentAreaContainer = styled.div`
  .standard-grey-subheading span {
    font-weight: 500;
    color: #000;
    font-size: 16px;
    line-height: 16px;
  }
`

const mapStateToProps = ({ Employment }) => ({
  employee: Employment.employee,
})

const mapDispatchToProps = (dispatch) => ({
  fetchEmployee: (
    confirmationRequestID,
    confirmationRequestSlug,
    isFromManager,
    success,
    fail,
  ) =>
    dispatch(
      fetchEmployee(
        confirmationRequestID,
        confirmationRequestSlug,
        isFromManager,
        success,
        fail,
      ),
    ),
  verifyEmployee: (payload, id, slug, success, fail, fromManager) =>
    dispatch(verifyEmployee(payload, id, slug, success, fail, fromManager)),
  disproveEmployee: (payload, id, slug, success, fail) =>
    dispatch(disproveEmployee(payload, id, slug, success, fail)),
})

class EmploymentVerifyContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      getValidEmployeeApiError: null,
      verifyButtonClicked: false,
      verifyEmployeeApiError: null,
      disproveButtonClicked: false,
      disproveEmployeeApiError: null,
      reasonsModalOpen: false,
      reason: null,
      comment: '',
      employeeDetails: {
        employerName: '',
        employerTradingName: '',
        employerABN: '',
        address: {},
        contactName: '',
        phoneNumber: '',
        email: '',
        jobTitle: '',
        startDate: '',
        endDate: '',
        stillEmployed: '',
        weeklyNetIncome: '',
        grossAnnualIncome: '',
        employmentType: '',
      },
      errors: {},
      employmentErrors: {},
      isContactDetailsUpdated: false,
      isEditModal: false,
      isEmployerEditModal: false,
      answers: {},
      reasonAffectingEmployment: '',
    }

    this.onSaveContactDetails = this.onSaveContactDetails.bind(this)
    this.updateAnswer = this.updateAnswer.bind(this)
    this.addQuestion = this.addQuestion.bind(this)
  }

  componentDidMount() {
    const {
      match: {
        params: { id, slug },
      },
    } = this.props

    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { manual } = curQueries

    const isFromManager = manual === 'true'
    this.props.fetchEmployee(id, slug, isFromManager, null, (responseText) => {
      this.setState({
        getValidEmployeeApiError: responseText,
      })
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      const { employeeDetails } = this.state
      const {
        employerName,
        employerTradingName,
        employerABN,
        address,
        contactName,
        phoneNumber,
        email,
        jobTitle,
        startDate,
        endDate,
        stillEmployed,
        weeklyNetIncome,
        grossAnnualIncome,
        employmentType,
      } = this.props.employee

      this.setState({
        employeeDetails: {
          ...employeeDetails,
          employerName,
          employerTradingName,
          employerABN,
          address,
          contactName,
          phoneNumber,
          email,
          jobTitle,
          startDate,
          endDate,
          stillEmployed,
          weeklyNetIncome,
          grossAnnualIncome,
          employmentType,
        },
      })
    }
  }

  onCheckboxChange = (reasonIndex) => {
    this.setState({ reason: reasonIndex })
  }

  onDisproveButtonClick = () => {
    const {
      match: {
        params: { id, slug },
      },
      disproveEmployee,
      employee,
    } = this.props
    const { reason, comment } = this.state
    this.setState({ disproveButtonClicked: true })
    const employeeDetails = Object.assign({}, employee)
    employeeDetails.startDate = employee.startDate
      ? moment(employee.startDate).format('YYYY-MM-DD')
      : ''
    employeeDetails.endDate = employee.endDate
      ? moment(employee.endDate).format('YYYY-MM-DD')
      : ''
    disproveEmployee(
      {
        employmentDetails: employeeDetails,
        reason,
        comment,
      },
      id,
      slug,
      null,
      (responseText) => {
        this.setState({
          disproveButtonClicked: false,
          disproveEmployeeApiError: responseText,
        })
      },
    )
  }

  onFormUpdate = (field) => {
    return (data) => {
      let value = data.value
      let error = data.error
      if (error === '' && (field === 'startDate' || field === 'endDate')) {
        value =
          data.value !== ''
            ? moment(data.value).format(helpers.dateWithDash)
            : ''
      }
      const { employeeDetails } = this.state
      const currencyFields = ['weeklyNetIncome', 'grossAnnualIncome']
      if (!shouldCurrencyUpdate(currencyFields, field, data.value)) {
        return
      }
      if (error === '' && field === 'stillEmployed' && data.value) {
        employeeDetails['endDate'] = ''
      }

      employeeDetails[field] = value
      this.setState({
        employeeDetails: {
          ...employeeDetails,
          [field]: value,
        },
        errors: {
          ...this.state.errors,
          [field]: error,
        },
      })
    }
  }

  onSaveContactDetails = () => {
    this.setState({
      clicked: true,
      isEditModal: false,
      isEmployerEditModal: false,
      isContactDetailsUpdated: true,
    })
  }

  onVerifyButtonClick = () => {
    const {
      match: {
        params: { id, slug },
      },
      employee,
    } = this.props
    const { verifyEmployee } = this.props
    const {
      isContactDetailsUpdated,
      employeeDetails,
      answers,
      reasonAffectingEmployment,
    } = this.state

    this.setState({ verifyButtonClicked: true })
    let employeeDetail = Object.assign({}, employee)
    if (isContactDetailsUpdated) {
      employeeDetail = Object.assign({}, employeeDetails)
    }
    if (reasonAffectingEmployment.length > 0) {
      if (
        reasonAffectingEmployment.length < MIN_CHAR_REASON_AFFECTING_EMPLOYMENT
      ) {
        snugNotifier.error(
          `Please update issues affecting employment (${MIN_CHAR_REASON_AFFECTING_EMPLOYMENT} to ${MAX_CHAR_REASON_AFFECTING_EMPLOYMENT} characters)`,
        )
        this.setState({ verifyButtonClicked: false })
        return
      }
      if (
        reasonAffectingEmployment.length > MAX_CHAR_REASON_AFFECTING_EMPLOYMENT
      ) {
        snugNotifier.error(
          `Please update issues affecting employment (${MIN_CHAR_REASON_AFFECTING_EMPLOYMENT} to ${MAX_CHAR_REASON_AFFECTING_EMPLOYMENT} characters)`,
        )
        this.setState({ verifyButtonClicked: false })
        return
      }
      employeeDetail.reasonAffectingEmployment = reasonAffectingEmployment
    }
    employeeDetail.startDate = employeeDetail.startDate
      ? moment(employeeDetail.startDate).format('YYYY-MM-DD')
      : ''
    employeeDetail.endDate = employeeDetail.endDate
      ? moment(employeeDetail.endDate).format('YYYY-MM-DD')
      : ''
    employeeDetail.grossAnnualIncome = parseInt(
      employeeDetail.grossAnnualIncome,
    )
    employeeDetail.weeklyNetIncome = parseInt(employeeDetail.weeklyNetIncome)
    employeeDetail.employmentType = parseInt(employeeDetail.employmentType)

    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { manual } = curQueries

    const isFromManager = manual === 'true'

    const payload = {
      employerRequest: employeeDetail,
      reasonAffectingEmployment,
      refereeType: isFromManager
        ? helpers.refereeTypeCollection
            .map(({ typeName }) => typeName)
            .indexOf(helpers.refereeTypeObject.manualReferee)
        : helpers.refereeTypeCollection
            .map(({ typeName }) => typeName)
            .indexOf(helpers.refereeTypeObject.nominatedReferee),
    }

    verifyEmployee(
      payload,
      id,
      slug,
      null,
      (responseText) => {
        this.setState({
          verifyButtonClicked: false,
          verifyEmployeeApiError: responseText,
        })
      },
      isFromManager,
    )
  }

  setValidationErrors = (field, error, onBlur) => {
    const { errors } = this.state
    if (error.length === 0) {
      delete errors[field]
      this.setState({ errors })
    } else if (onBlur) {
      errors[field] = error
      this.setState({ errors })
    }
  }

  addQuestion = (questionDef) => {
    this.setState(({ answers }) => {
      return {
        answers: {
          ...answers,
          [questionDef.id]: this.initializeRatingQuestion(questionDef),
        },
      }
    })
  }

  closeReasonsModal = () => {
    this.setState({ reasonsModalOpen: false })
  }

  initializeRatingQuestion = ({ id, type, defaultValue }) => {
    return {
      ratingCode: id,
      questionType: type,
      answerValue: defaultValue,
      isPrivate: true,
    }
  }

  openEditModal = () => {
    this.setState({
      isEditModal: !this.state.isEditModal,
      isContactDetailsUpdated: false,
    })
  }

  openEmployerEditModal = () => {
    this.setState({
      isEmployerEditModal: !this.state.isEmployerEditModal,
      isContactDetailsUpdated: false,
    })
  }

  openReasonsModal = () => {
    this.setState({ reasonsModalOpen: true })
  }

  update = (field, value) => {
    const { employeeDetails } = this.state
    this.setState(
      {
        employeeDetails: {
          ...employeeDetails,
          [field]: value,
        },
      },
      this.validate(field, false),
    )
  }

  updateAnswer = (questionDef, value) => {
    this.setState(({ answers }) => {
      return {
        answers: {
          ...answers,
          [questionDef.id]: {
            ...answers[questionDef.id],
            answerValue: value,
          },
        },
        verifyButtonClicked: false,
      }
    })
  }

  updateComment = (comment) => {
    this.setState({ comment })
  }

  validate = (field, onBlur) => {
    return () => {
      const { employeeDetails } = this.state
      switch (field) {
        case 'contactName':
          return this.setValidationErrors(
            field,
            validations.validateFirstName(employeeDetails.contactName),
            onBlur,
          )
        case 'email': {
          return this.setValidationErrors(
            field,
            validations.validateEmail(employeeDetails.email),
            onBlur,
          )
        }
        default:
          return true
      }
    }
  }

  validateInternationalNumber = (onBlur) => {
    const { employeeDetails } = this.state
    return (status, value, countryData, number) => {
      this.setState({
        employeeDetails: {
          ...employeeDetails,
          phoneNumber: number.trim(),
        },
      })
      const actualNumberType = getNumberType(number, metadata)
      this.setValidationErrors(
        'phoneNumber',
        isValidMobileNumber(actualNumberType) ||
          isValidLandlineNumber(actualNumberType) ||
          !value
          ? []
          : [ErrorMessages[Errors.ErrorValidatingMobile]],
        onBlur,
      )
    }
  }

  updateReasonAffectingEmployment = (data) => {
    const { value } = data
    this.setState({
      reasonAffectingEmployment: value,
    })
  }

  render() {
    const {
      getValidEmployeeApiError,
      verifyEmployeeApiError,
      disproveButtonClicked,
      disproveEmployeeApiError,
      reasonsModalOpen,
      reason,
      comment,
      verifyButtonClicked,
      employeeDetails,
      errors,
      isContactDetailsUpdated,
      isEditModal,
      isEmployerEditModal,
      answers,
      reasonAffectingEmployment,
    } = this.state
    const { employee } = this.props

    return (
      <div className="two-col-box sm64">
        <div className="col-first xs-second">
          <RentalReputationHeader title="Employment Confirmation" />
          {!getValidEmployeeApiError ? (
            <div>
              <div>
                <div className="mobile-form-button">
                  <h3 className="manager-review-header mb20">
                    <b>
                      {employee.renterFirstName} {employee.renterLastName}
                    </b>
                    &nbsp;has nominated you as their{' '}
                    {employee.hasAccountant
                      ? 'accountant'
                      : 'current or most recent employer'}{' '}
                    and has authorised disclosure and confirmation of their
                    employment details on Snug.
                  </h3>

                  <ul className="manager-review-list">
                    <li>
                      {employee.renterFirstName} has authorised disclosure of
                      their employment details.{' '}
                      <a
                        href={employee.disclosureURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Disclosure Authority
                      </a>
                    </li>
                    <li>
                      Property Managers use employment details to evaluate
                      whether a potential renter is suitable for a property.
                    </li>
                    <li>
                      If you confirm we will notify {employee.renterFirstName}{' '}
                      so they they can complete their rental application.
                    </li>
                  </ul>

                  <div className="mt50 mb60">
                    <EmployerContactBox
                      employer={employeeDetails}
                      disabled={true}
                      role={'employer'}
                      update={this.update}
                      validate={this.validate}
                      validateInternationalNumber={
                        this.validateInternationalNumber
                      }
                      onSaveContactDetails={this.onSaveContactDetails}
                      errors={errors}
                      employee={employee}
                      isContactDetailsUpdated={isContactDetailsUpdated}
                      openEditModal={this.openEditModal}
                      isEditModal={isEditModal}
                    />
                    <EmploymentBox
                      employer={employeeDetails}
                      disabled={true}
                      role={'employer'}
                      employee={employee}
                      isContactDetailsUpdated={isContactDetailsUpdated}
                      openEmployerEditModal={this.openEmployerEditModal}
                      isEmployerEditModal={isEmployerEditModal}
                      errors={errors}
                      onFormUpdate={this.onFormUpdate}
                      onSaveContactDetails={this.onSaveContactDetails}
                      isEditLink={true}
                    />
                  </div>
                  <CommentAreaContainer>
                    <Form.CommentArea
                      value={reasonAffectingEmployment}
                      label="Is there any work issue that might affect the applicant's
                    ongoing employment, income or ability to pay rent (eg.
                    reduced hours, probation, redundancy or other)?"
                      placeholder="Please describe"
                      rows={4}
                      onChange={this.updateReasonAffectingEmployment}
                      id="content"
                    />
                  </CommentAreaContainer>

                  <ul className="snug-tips">
                    <li>
                      Note: this confirmation is private and can be shared by{' '}
                      {employee.renterFirstName} with property managers when
                      they submit rental applications via Snug.
                    </li>
                  </ul>

                  <div className="pt15">
                    <button
                      className="btn employment-history-verified"
                      disabled={verifyButtonClicked}
                      onClick={this.onVerifyButtonClick}
                    >
                      Confirm
                      <i
                        className={
                          verifyButtonClicked ? 'fa fa-spinner fa-pulse' : ''
                        }
                      />
                    </button>
                  </div>
                </div>
                <div
                  className="gray-text linkStyle text-center mt20"
                  onClick={this.openReasonsModal}
                >
                  I can't confirm
                </div>
                <ErrorMessage error={verifyEmployeeApiError} />

                <FAQs type="employer" />
              </div>
            </div>
          ) : (
            <ErrorMessage error={getValidEmployeeApiError} />
          )}
          {reasonsModalOpen && (
            <Reasons
              closeReasonsModal={this.closeReasonsModal}
              onDisproveButtonClick={this.onDisproveButtonClick}
              disproveButtonClicked={disproveButtonClicked}
              disproveEmployeeApiError={disproveEmployeeApiError}
              checkedReason={reason}
              onCheckboxChange={this.onCheckboxChange}
              updateComment={this.updateComment}
              comment={comment}
              employee={employee}
            />
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmploymentVerifyContainer)
