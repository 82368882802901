import React from 'react'

import { ModalContentWrapper } from 'app/modals/modal-styled'

const options = [5, 10, 15, 30]

class LateForViewingModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sendClicked: false,
      lateTime: 5,
    }
  }

  componentDidMount() {
    this.props.clearError()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ sendClicked: false })
    }
  }

  sendNotification = (data) => {
    this.props.notifyLateViewing(data)
    this.setState({ sendClicked: true })
  }

  updateTime = (value) => {
    this.setState({ lateTime: Number(value) })
  }

  render() {
    const { sendClicked, lateTime } = this.state
    const {
      notifyLateViewing,
      totalCheckedInAttendeeCount = 0,
      error,
      showLateViewingModal,
    } = this.props
    const attendeesText =
      totalCheckedInAttendeeCount > 1 ? 'attendees' : 'attendee'

    return (
      <div className="modal-fullscreen modal-layout-flex-container">
        <ModalContentWrapper>
          <div className="modal-close">
            <i
              className="fa fa-times"
              onClick={() => showLateViewingModal(false)}
            />
          </div>
          <div className="modal-header no-border lr-modal-pad">
            <h4 className="pl20 pr20">Late Notification</h4>
          </div>
          <div className="modal-body display-flex flex-direction-column">
            <div className="mb20 signature-pad-wrapper">
              {options.map((t) => (
                <label className="mr10" key={t}>
                  <input
                    type="radio"
                    checked={lateTime === t}
                    value={t}
                    onChange={(e) => this.updateTime(e.target.value)}
                  />
                  <span className="height-auto vertical-align-middle">
                    {t}m
                  </span>
                </label>
              ))}
            </div>
            {error && (
              <div className="alert text-center  alert-danger">{error}</div>
            )}
            <div className="fw500 text-center mb25">
              Send an SMS & Email to {totalCheckedInAttendeeCount} registered{' '}
              {attendeesText}
            </div>
          </div>
          <div className="border-none width100 display-flex modal-bottom-btn-container">
            <div className="button-default secondary width100 mt10 margin-profile-item-left col-sm-6">
              <button onClick={() => showLateViewingModal(false)}>
                Cancel
              </button>
            </div>
            <div className="button-default  width100 mt10 margin-profile-item-right col-sm-6">
              <button
                disabled={false}
                className="btn"
                onClick={() => this.sendNotification(lateTime)}
              >
                Send
                <i className={sendClicked ? 'fa fa-spinner fa-pulse' : ''} />
              </button>
            </div>
          </div>
        </ModalContentWrapper>
      </div>
    )
  }
}

export default LateForViewingModal
