import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  createStudentDetails,
  fetchSingleStudent,
  putStudentDetail,
} from 'app/sm/student_details/student_details_add_access'

export const postStudentDetails = (formData) => (dispatch) => {
  return createStudentDetails(formData).then(({ ok, responseText }) => {
    if (ok) {
      return Promise.resolve()
    } else {
      return responseText.then((t) => {
        return Promise.reject(translateErrorCodeToMessage(t))
      })
    }
  })
}

export const updateStudentDetails =
  (formData, studentDetailId) => (dispatch) => {
    return putStudentDetail(formData, studentDetailId).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((t) => {
            return Promise.reject(translateErrorCodeToMessage(t))
          })
        }
      },
    )
  }

export const getSingleStudent = (studentId) => (dispatch) => {
  return fetchSingleStudent(studentId).then(
    ({ ok, responseText, studentInfo }) => {
      if (ok) {
        return Promise.resolve({ studentInfo })
      } else {
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      }
    },
  )
}
