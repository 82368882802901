import React, { useContext } from 'react'

import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Alert, Box, Flex } from 'app/components/design-system-components'
import ActivityItemWithDot from 'app/components/design-system-components/ActivityItemWithDot'
import { InfoRounded } from 'app/components/design-system-components/icons/actions'
import SvgForm from 'app/components/design-system-components/icons/snug-specific/Form'
import { Spinner } from 'app/components/design-system-components/Spinner'
import { TrimWithEllipsis } from 'app/components/design-system-components/TrimWithEllipsis'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { ActivityFeedContext } from 'app/shared_components/activity_feed/ActivityFeedContext'
import {
  getActivityLabel,
  getActivityNavigationURL,
} from 'app/shared_components/activity_feed/utils'
import { shortYearDateTimeWithAt } from 'app/utils/datetime/helpers'
import * as agencyRoles from 'app/utils/roles'
import { isEnableActivityFeedIconEnabled } from 'config/features'

const StyledActivitiesContainer = styled(Box)`
  box-sizing: border-box;
  width: 268px;
  min-height: 100px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;

  @media screen and (max-width: 576px) {
    .activity-feed-item:last-child {
      margin-bottom: 70px;
    }
  }
`

const StyledActivityItem = styled(Flex)`
  .forward-icon {
    color: white;
  }

  :hover {
    cursor: pointer;
    background: rgba(230, 230, 230, 0.25);

    .forward-icon {
      color: #5a9bee;
    }
  }
`

const ActivityFeedItem = ({ activity, lastItem }) => {
  const history = useHistory()

  const {
    openTab,
    currentUser,
    currentTeam,
    currentAgency = {},
  } = useContext(ActivityFeedContext)

  const { created_at: createdAt, data, guid } = activity
  const { property_name, actor_name } = data

  const config = {
    currentUser,
    currentTeam,
    openTab,
  }

  const action = getActivityLabel(activity, config)
  const feedTimestamp = moment(createdAt).format(shortYearDateTimeWithAt)

  const handleActivityClicked = () => {
    const url = getActivityNavigationURL(activity, config)
    if (url) {
      history.push(url)
    }
  }

  const renderPropertiesList = () => {
    const { property_list: properties } = data
    if (!properties) return
    const fullText = properties.map((p) => {
      return (
        <span>
          {p} <br />
        </span>
      )
    })

    return (
      <>
        <TrimWithEllipsis
          lines={2}
          color="#808080"
          data-tooltip-content=""
          data-tooltip-id={guid}
        >
          {fullText}
        </TrimWithEllipsis>
        <ReactTooltip id={guid} data-type="info" place="bottom">
          {fullText}
        </ReactTooltip>
      </>
    )
  }

  const userAuthorisedToView =
    !agencyRoles.isUserACasualInspectorOrLeasingConsultant(currentAgency?.roles)

  return (
    <>
      <ActivityItemWithDot
        className="activity-feed-item"
        dateInfo={feedTimestamp}
        propertyAddress={property_name}
        commentInfo={actor_name}
        commentContent={action}
        onActivityItemClick={() =>
          userAuthorisedToView && handleActivityClicked()
        }
        hasLink={userAuthorisedToView}
        actionButtonLabel="view"
        icon={<SvgForm sizeInPx={theme.fontSizes[3]} variant="default" />}
      />
    </>
  )
}

const ActivitiesContainer = () => {
  const {
    openTab,
    fetchingNew,
    fetchingOld,
    tabUtilityMap,
    currentTeam,
    currentAgency,
  } = useContext(ActivityFeedContext)

  const { slug = '' } = currentTeam || {}

  const _activities = tabUtilityMap[openTab.key].actRef.activities

  return (
    <StyledActivitiesContainer>
      {fetchingNew && (
        <Flex justifyContent="center">
          <Spinner fontSize={'50px'} />
        </Flex>
      )}

      <Box p={3} mt={3}>
        {isEnableActivityFeedIconEnabled(slug) ? (
          <>
            {_activities.map((activity, idx) => (
              <ActivityFeedItem
                activity={activity}
                lastItem={idx === _activities.length - 1}
                key={idx}
              />
            ))}
            {!_activities.length && (
              <Box m={4} textAlign="center">
                Populating application activities ...
              </Box>
            )}
          </>
        ) : (
          <Alert variant="blueWithBg">
            <Flex alignItems="center">
              <InfoRounded
                color={theme.colors.deepBlue}
                width={theme.width[7] + 'px'}
                height={theme.height[7] + 'px'}
              />
              <Box ml={4}>Populating activity. Available Tue 13th Sep.</Box>
            </Flex>
          </Alert>
        )}
      </Box>

      {fetchingOld && (
        <Flex justifyContent="center">
          <Spinner fontSize={'50px'} />
        </Flex>
      )}
    </StyledActivitiesContainer>
  )
}

export default ActivitiesContainer
