import BaseInputDate from 'app/components/forms/base_inputDate/component'
import {
  firstElementOrEmptyString,
  withValidation,
} from 'app/components/forms/util'
import * as helpers from 'app/sm/helpers'
import { getCurrentDate } from 'app/utils/datetime/helpers'
import { ValidateDateWithOnedayPastDate } from 'app/utils/viewings/helpers'
import * as viewingsHelpers from 'app/utils/viewings/helpers'

const validateFutureDateWithOneDayPast = (date) => {
  return ValidateDateWithOnedayPastDate(
    getCurrentDate(helpers.dateWithDash),
    date,
    'This field is required',
    viewingsHelpers.oneDayLaterErrorMessage,
  )
}

const validateFutureDateOneDayPast = (date) =>
  firstElementOrEmptyString(validateFutureDateWithOneDayPast(date))

const InputFutureDateOneDayPast = withValidation(
  BaseInputDate,
  validateFutureDateOneDayPast,
)

export default InputFutureDateOneDayPast
