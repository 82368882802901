import React from 'react'

import * as Form from 'app/shared_components/form_component'
import BlueLinkComponent from 'app/sm/progress_application/components/bluelink/component'
import CheckboxOptionContainer from 'app/sm/progress_application/components/checkbox_option_container/component'
import EmailLink from 'app/sm/progress_application/components/email_link/component'
import FlexContainer from 'app/sm/progress_application/components/flex-container/component'

class CheckboxOrEmailComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldDisplay: false,
    }
  }

  onLinkClicked = () => {
    this.setState({
      shouldDisplay: !this.state.shouldDisplay,
    })
  }

  render() {
    const {
      label = '',
      checked = false,
      onChange = undefined,
      showLink = false,
      mailTo = '',
      subject = '',
      linkText = '',
      normalText = '',
      instructionText = '',
      enableEdit = true,
    } = this.props
    const { shouldDisplay = false } = this.state
    return (
      <CheckboxOptionContainer>
        {showLink ? (
          <EmailLink
            linkText={linkText}
            normalText={normalText}
            mailTo={mailTo}
            subject={subject}
          />
        ) : (
          <div>
            <FlexContainer>
              <Form.Checkbox
                label={label}
                checked={checked}
                onChange={onChange}
                enableEdit={enableEdit}
              />
              {instructionText && (
                <BlueLinkComponent
                  label={shouldDisplay ? 'hide' : 'view'}
                  titleClass="ml10 mt3"
                  onClick={this.onLinkClicked}
                />
              )}
            </FlexContainer>
            {instructionText && shouldDisplay && (
              <p className="mt20">{instructionText}</p>
            )}
          </div>
        )}
      </CheckboxOptionContainer>
    )
  }
}

export default CheckboxOrEmailComponent
