import { useState } from 'react'

import { connect } from 'react-redux'

import { Box, TextSpan } from 'app/components/design-system-components'
import type { BreadCrumbConfig } from 'app/components/design-system-components/Breadcrumbs'
import { Breadcrumbs } from 'app/components/design-system-components/Breadcrumbs'
import { PageHeader } from 'app/components/design-system-components/page/Header'
import { PageSection } from 'app/components/design-system-components/page/Section'
import { PageSubHeading } from 'app/components/design-system-components/page/SubHeading'
import {
  FileImporter,
  ImportResp,
  UploadFileStatuses,
} from 'app/components/design-system-components/uploads'
import { importEnquirers } from 'app/services/http/teams/pm-messages/import'
import { urlTo } from 'app/sm/helpers'
import { importEnquirersLearnMore } from 'config/external-links'

type ImportSource = string

const IRESource: ImportSource = 'IREEnquiry'
// const SnugSource: ImportSource = 'Snug'

const EnquirersImport = ({ currentTeam, history }: any) => {
  const [importingState, setImportingState] = useState({
    status: UploadFileStatuses.ready,
    error: undefined,
    response: null,
    selectedFile: null,
    abortController: null,
    filedKeysFile: null,
  })

  const onDropFile = (files: File[]) => {
    const droppedFile = files[0]
    if (!droppedFile) {
      setImportingState((state: any) => ({
        ...state,
        status: UploadFileStatuses.error,
        error: 'Error while selecting enquirers file',
      }))
      return Promise.resolve()
    }

    const abortController = new AbortController()
    setImportingState((state: any) => ({
      ...state,
      status: UploadFileStatuses.loading,
      selectedFile: droppedFile,
      abortController: abortController,
    }))
    return importEnquirers(
      currentTeam.guid,
      droppedFile,
      IRESource,
      abortController.signal,
    )
      .then((response: ImportResp) => {
        const { summary } = response
        setImportingState((state: any) => ({
          ...state,
          status: !!summary.fail
            ? UploadFileStatuses.doneWithErrors
            : UploadFileStatuses.done,
          response,
          abortController: null,
        }))
      })
      .catch((err: any) => {
        setImportingState((state) => ({
          ...state,
          status: UploadFileStatuses.error,
          error: err.combinedErrorMessage || err.message,
        }))
      })
  }

  const changeStateToReady = () => {
    setImportingState((state: any) => ({
      ...state,
      status: UploadFileStatuses.ready,
      error: null,
      selectedFile: null,
      response: null,
    }))
  }

  const cancel = () =>
    importingState.abortController
      ? (importingState.abortController as AbortController).abort()
      : null

  const goToMessages = () =>
    history.push(urlTo('newMessagesPage', { teamSlug: currentTeam?.slug }))

  const breadcrumbsConfigs: BreadCrumbConfig[] = [
    {
      content: currentTeam?.name,
      link: urlTo('teamOverview', { teamSlug: currentTeam?.slug }),
    },
    {
      content: 'Messages',
      link: urlTo('newMessagesPage', { teamSlug: currentTeam?.slug }),
    },
    {
      content: 'Import',
    },
  ]

  return (
    <>
      <Breadcrumbs breadcrumbsConfigs={breadcrumbsConfigs} />
      <PageHeader mt={2} mb={4} title="Import Enquirers" />
      <Box mb={5}>
        <TextSpan lineHeight="1.4">
          Activate the Snug prospecting engine by importing your recent
          enquirers.{' '}
          <a href={importEnquirersLearnMore} target="_blank" rel="noreferrer">
            Learn more
          </a>
        </TextSpan>
      </Box>

      <PageSubHeading title="Upload" />

      <PageSection>
        <FileImporter
          statusConfig={{
            status: importingState.status,
            error: importingState.error,
            response: importingState.response,
          }}
          selectedFile={importingState.selectedFile}
          onDrop={onDropFile}
          onMoveToReady={changeStateToReady}
          onGoTo={goToMessages}
          onCancel={cancel}
          entity="enquirers"
        />
      </PageSection>
    </>
  )
}

const mapStateToProps = ({ session }: any) => {
  return {
    currentTeam: session.currentTeam,
  }
}

export const EnquirersImportPage = connect(mapStateToProps)(EnquirersImport)
