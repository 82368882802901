export const STEP_ENTER_MOBILE = 'ENTER_MOBILE'
export const STEP_ENTER_EMAIL = 'ENTER_EMAIL'
export const STEP_ENTER_PASSWORD = 'ENTER_PASSWORD'
export const STEP_ENTER_DETAILS = 'ENTER_DETAILS'
export const STEP_ENTER_DETAILS_CONFIRMATION = 'ENTER_DETAILS_CONFIRMATION'
export const STEP_UPDATE_REGISTRATION_INFO = 'UPDATE_REGISTRATION_INFO'

export const FIELD_MOBILE = 'mobile'
export const FIELD_MOBILE_CONFIRMATION = 'mobileConfirmation'
export const FIELD_EMAIL = 'email'
export const FIELD_EMAIL_CONFIRMATION = 'emailConfirmation'
export const FIELD_PASSWORD = 'password'
export const FIELD_FIRST_NAME = 'firstName'
export const FIELD_LAST_NAME = 'lastName'

export const AUTH_VERSION_2 = 2
export const AUTH_VERSION_1 = 1
