import { connect } from 'react-redux'

import ConsoleCloudContainer from 'app/sm/plugins/components/console_cloud/container'
import {
  activateIntegration,
  getConsoleCloudIntegration,
  getIntegrationActivity,
} from 'app/sm/plugins/plugins_action'

const mapStateToProps = ({ session }) => ({
  currentUser: session.currentUser,
  teams: session.teams,
  currentTeam: session.currentTeam,
})

const mapDispatchToProps = (dispatch) => ({
  activateIntegration: (teamGUID, data) =>
    dispatch(activateIntegration(teamGUID, data)),
  getIntegration: (teamGUID) => dispatch(getConsoleCloudIntegration(teamGUID)),
  getIntegrationActivity: (teamGUID) =>
    dispatch(getIntegrationActivity(teamGUID)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsoleCloudContainer)
