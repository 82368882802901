import React from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  AddressHistoryInfo,
  AddressHistoryManager,
  formatDate,
} from 'app/bond_cover/renters/history/components/history_box'
import {
  Alert,
  Box,
  Button,
  StatusPill,
} from 'app/components/design-system-components'
import { Heading } from 'app/components/design-system-components/typography'
import { ledgerAttachmentsCmpDomId } from 'app/constants/rental-history.constants'
import { LoadMoreButton } from 'app/dashboard/prospects_summary'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import ReferenceResultList from 'app/match/rental_reference/display/renter_address_history_detailed/rental_reference_detailed/rental_reference_result/rental_reference_result_list'
import { ratingVersions } from 'app/match/rental_reference/rating_categories.constants'
import { history as urlHistory } from 'app/shared_components/router'
import {
  isAddressHistoryOwned,
  isAddressHistoryTemporaryBnBArrangement,
  isAddressHistoryWithFamilyOrFriends,
  propertyArrangementLabels,
  TEMPORARY_BNB_ARRANGEMENT,
  totalTimePassed,
  urlTo,
} from 'app/sm/helpers'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'
import * as textHelpers from 'app/utils/text/helpers'

export class HistoryBoxDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonText: 'Show reference details',
      loadMore: false,
    }
  }

  onShowButtonClicked = () => {
    const { buttonText } = this.state
    this.setState({ loadMore: !this.state.loadMore })
    if (buttonText === 'Show reference details') {
      this.setState({ buttonText: 'Hide details' })
    } else {
      this.setState({ buttonText: 'Show reference details' })
    }
  }

  render() {
    const {
      history = {},
      ratingCategories = [],
      deleteRentalHistory,
      removeButtonClicked,
      editRentalHistory,
      ratingCategoriesVersion,
    } = this.props
    const { buttonText } = this.state
    const { years, months, days } = totalTimePassed(
      history.from,
      history.to,
      history.isRollingLease,
    )
    const suburbStateCountryArray = []
    history.address.suburb &&
      suburbStateCountryArray.push(history.address.suburb)
    history.address.state && suburbStateCountryArray.push(history.address.state)
    history.address.country &&
      suburbStateCountryArray.push(history.address.country)
    const suburbStateCountry = suburbStateCountryArray.join(', ')
    const { from, to, isRollingLease, ratings } = history
    const formattedDate = formatDate({ from, to, isRollingLease })
    const hasNonExceptionRatings = (ratings || []).some((r) => r.exception)

    const referenceVerified = history && history.managerRated
    const isReferenceRequired = history.shouldContactReference
    let street = history.address.streetNumber + ' ' + history.address.streetName
    if (history.address && history.address.unitNumber) {
      street = history.address.unitNumber + '/' + street
    }

    const ratingVersion = Object.values(ratingVersions).find(
      (version) => version.ref === ratingCategoriesVersion,
    )
    const toShowRatingCategories = (ratingCategories || []).filter((category) =>
      ratingVersion.tenancyQuestions.includes(category.Code),
    )

    // trivial: ledgers coming from BE for a renter should be ones added by him
    const ledgersByRenter = history.ledger?.filter(
      ({ addedByRenter }) => addedByRenter,
    )
    const showLedgers = !!ledgersByRenter?.length
    const showLedgersUploadBtn =
      !ledgersByRenter?.length &&
      !referenceVerified &&
      !isAddressHistoryWithFamilyOrFriends(history.propertyArrangement)

    const hideLedgers =
      isAddressHistoryOwned(history.propertyArrangement) ||
      isAddressHistoryTemporaryBnBArrangement(history.propertyArrangement)

    return (
      history.address && (
        <Box className="rental-history-box" mt="48px" mb={0}>
          <div className="rental-history-box-title-wrapper position-relative">
            {!referenceVerified ? (
              <Link
                className="title pb0 col-sm-8 pl0"
                to={urlTo('updateRentalReference', {
                  referenceId: history.guidID,
                })}
              >
                {street}
              </Link>
            ) : (
              <button
                className="title p0 col-sm-8 bg-white text-align-left m0 text-decoration-underline height-auto"
                onClick={() => editRentalHistory(history.guidID)}
              >
                {street}
              </button>
            )}
            <div className="position-absolute right0">
              <button
                className="textButton col-sm-4 width-auto pr0"
                onClick={() =>
                  !referenceVerified
                    ? urlHistory.push(
                        urlTo('updateRentalReference', {
                          referenceId: history.guidID,
                        }),
                      )
                    : editRentalHistory(history.guidID)
                }
              >
                Edit
              </button>
              <button
                className={`textButton col-sm-4 width-auto pr0 ${
                  removeButtonClicked ? 'text-align-center' : 'text-align-right'
                }`}
                onClick={() =>
                  deleteRentalHistory(history.guidID, referenceVerified)
                }
                disabled={removeButtonClicked}
              >
                Remove
              </button>
            </div>
          </div>

          <AddressHistoryInfo
            suburbStateCountry={suburbStateCountry}
            formattedDate={formattedDate}
            propertyArrangement={history.propertyArrangement}
            years={years}
            months={months}
            days={days}
            weeklyPrice={history.weeklyPrice}
          />
          <div>
            {toShowRatingCategories && toShowRatingCategories.length > 0 && (
              <ReferenceResultList
                categories={toShowRatingCategories}
                ratings={history.ratings}
                shouldShowAllRatings={this.state.loadMore}
              />
            )}

            {hasNonExceptionRatings && (
              <LoadMoreButton
                onClick={() => this.onShowButtonClicked()}
                positionClassName="ml0 mt20"
                textAlignClass="display-flex"
                buttonText={buttonText}
              />
            )}
          </div>

          {(showLedgers || showLedgersUploadBtn) && !hideLedgers && (
            <Box mt={7}>
              <Box my={4}>
                <Heading
                  level={5}
                  displayingLevel={5}
                  fontWeight={theme.fontWeights[6]}
                >
                  Rental ledger
                </Heading>
              </Box>
              {showLedgers && (
                <Box my={2}>
                  {ledgersByRenter.map((document, index) => (
                    <AppDocumentListItemSecondVersion
                      titleClass="m0"
                      document={document}
                      key={index}
                    />
                  ))}
                </Box>
              )}
              {showLedgersUploadBtn && (
                <Box my={2}>
                  <Alert variant="warningWithBg" mb={4}>
                    <span>
                      Add the rental ledger to speed up your application
                      processing.{' '}
                      <a
                        href={textHelpers.RENTAL_LEDGER_RENTER_LEARN_MORE_LINK}
                        target="_blank"
                        rel="noreferrer"
                      >
                        learn more
                      </a>
                    </span>
                  </Alert>
                  <Button
                    variant="outlineSuccess"
                    onClick={() =>
                      urlHistory.push(
                        urlTo('updateRentalReference', {
                          referenceId: history.guidID,
                        }) + `#${ledgerAttachmentsCmpDomId}`,
                      )
                    }
                  >
                    Upload
                  </Button>
                </Box>
              )}
            </Box>
          )}
          {!hideLedgers && (
            <Box mt={7}>
              <Box my={4}>
                <Heading
                  level={5}
                  displayingLevel={5}
                  fontWeight={theme.fontWeights[6]}
                >
                  Referee / Contact
                </Heading>
              </Box>
              <Box py={2}>
                <AddressHistoryManager
                  landlord={history.landlord}
                  agencyName={history.agencyName}
                  phoneNumber={history.phoneNumber}
                  officeNumber={history.officeNumber}
                  email={history.email}
                  referenceVerified={referenceVerified}
                  isReferenceRequired={isReferenceRequired}
                  declineReason={history.declineReason}
                  propertyArrangement={history.propertyArrangement}
                />
              </Box>
            </Box>
          )}
        </Box>
      )
    )
  }
}

export default HistoryBoxDetails
