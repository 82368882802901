import { Form, Formik } from 'formik'
import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import { Button } from 'app/components/design-system-components/Button'
import { Dropdown } from 'app/components/design-system-components/Dropdown'
import Flex from 'app/components/design-system-components/Flex'
import HorizontalLine from 'app/components/design-system-components/HorizontalLine'
import { ArrowDropDownRounded } from 'app/components/design-system-components/icons/navigation'
import * as themeGetter from 'app/components/design-system-components/theme/getters'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const FilterDropdownTrigger = ({
  filterConfig: {
    filterValues,
    filterTriggerPlaceholder,
    FilterTriggerPlaceholderCmp,
    isApplied,
    AppliedFilterRenderer,
  },
  dropdownControls,
  ...props
}) => {
  const triggerText = !isApplied ? (
    FilterTriggerPlaceholderCmp ? (
      <FilterTriggerPlaceholderCmp />
    ) : (
      filterTriggerPlaceholder
    )
  ) : (
    <AppliedFilterRenderer filterValues={filterValues} />
  )
  return (
    <Button
      variant={!isApplied ? 'outline' : 'outlineDeepBlue'}
      onClick={() => dropdownControls.toggle()}
      sizeVariant="small"
      {...props}
    >
      <Flex alignItems="center">
        {triggerText}{' '}
        <Box
          as={ArrowDropDownRounded}
          ml={theme.space[3] + 'px'}
          fontSize={theme.fontSizes.pExtraLarge18}
        />
      </Flex>
    </Button>
  )
}

const FilterDropdownBodyBox = styled(Box)`
  box-shadow: 0 4px 30px 0 ${themeGetter.color('dropdownDroppedShadow')};
  background-color: ${themeGetter.color('white')};
`

const FilterDropdownBodyWrapper = ({
  dropdownControls,
  filterConfig,
  filterBodyProps,
  disabled = false,
}) => {
  const { filterValues, setFilterValues, FilterBody } = filterConfig

  const onSubmit = (values) => {
    setFilterValues(values)
    dropdownControls.close()
  }

  return (
    <Formik initialValues={filterValues} onSubmit={onSubmit}>
      {(props) => {
        return (
          <FilterDropdownBodyBox as={Form}>
            <FilterBody formBag={props} {...filterBodyProps} />

            <HorizontalLine />
            <Box
              boxShadow="0px -4px 15px 0px #0000000d"
              p={theme.space[3] + 'px'}
            >
              <Button
                type="submit"
                sizeVariant="regular"
                width="100%"
                disabled={disabled}
              >
                Apply Filter
              </Button>
            </Box>
          </FilterDropdownBodyBox>
        )
      }}
    </Formik>
  )
}

export const FilterDropdown = ({
  filterConfig,
  filterBodyProps,
  triggerCmpProps,
  disabled = false,
}) => {
  return (
    <Dropdown
      triggerWrapperComponent={(dropdownProps) => (
        <FilterDropdownTrigger
          filterConfig={filterConfig}
          {...dropdownProps}
          {...triggerCmpProps}
        />
      )}
      dropdownComponent={(dropdownProps) => (
        <FilterDropdownBodyWrapper
          filterConfig={filterConfig}
          {...dropdownProps}
          filterBodyProps={filterBodyProps}
          disabled={disabled}
        />
      )}
      topSpaceInPx={9}
    />
  )
}
