import React from 'react'

import styled from 'styled-components'

import { Box, Flex, Modal } from 'app/components/design-system-components'

const StyleTableContainer = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

function BallotHouseholdIncomeModal({
  ballotsHouseholdIncomeLimit,
  toggleBallotHouseHoldModalIncome,
  ballotsHouseholdIncomeRegion,
  returnAsComp = false,
}) {
  const incomeLimits = ballotsHouseholdIncomeLimit || []
  const modalBody = (
    <Flex mb={5} width="100%" justifyContent="center" alignItems="center">
      {incomeLimits.length > 0 ? (
        <StyleTableContainer>
          <table>
            <tr>
              <th>Region</th>
              <th>Single</th>
              <th>Couple</th>
              <th>Family/Shared Group</th>
            </tr>
            <tr>
              <td>{ballotsHouseholdIncomeRegion}</td>
              <td>
                {' '}
                Up to $
                {incomeLimits.find(({ type }) => type === 'Single')?.income}
              </td>
              <td>
                {' '}
                Up to $
                {incomeLimits.find(({ type }) => type === 'Couple')?.income}
              </td>
              <td>
                {' '}
                Up to $
                {incomeLimits.find(({ type }) => type === 'Family')?.income}
              </td>
            </tr>
          </table>
        </StyleTableContainer>
      ) : (
        <Box>No income tables available</Box>
      )}
    </Flex>
  )
  if (returnAsComp) {
    return <Box>{modalBody}</Box>
  }
  return (
    <Modal
      modalHeading="Eligible income thresholds"
      modalBody={modalBody}
      modalFooter={undefined}
      primaryLabel={undefined}
      primaryAction={undefined}
      secondaryLabel={undefined}
      secondaryAction={undefined}
      toggleModal={toggleBallotHouseHoldModalIncome}
      showSecondaryButton={false}
      showPrimaryButton={false}
    />
  )
}

export default BallotHouseholdIncomeModal
