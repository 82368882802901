import { Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { FollowsGridHeader } from 'app/pages/teams/settings/personal/follows/FollowsGrid/FollowsGridHeader'
import { FollowsGridRow } from 'app/pages/teams/settings/personal/follows/FollowsGrid/FollowsGridRow'

export const FollowsGrid = ({
  teamMembersFollows,
  rowRenderer = FollowsGridRow,
  noTeamMembersText = '',
}) => {
  const mappedTeamMembersFollows = teamMembersFollows || []
  return (
    <section>
      <FollowsGridHeader />
      {mappedTeamMembersFollows.map((teamMemberFollows) =>
        rowRenderer({ teamMemberFollows }),
      )}

      {!mappedTeamMembersFollows.length && (
        <Flex
          alignItems="center"
          justifyContent="center"
          p={theme.space[5] + 'px'}
          color={theme.colors.gray700}
        >
          {noTeamMembersText}
        </Flex>
      )}
    </section>
  )
}
