import * as API from 'app/bond_cover/agency/agency_access'
import { postEnquirerWithPropertyAsManager } from 'app/bond_cover/agency/agency_access'
import { NetworkConnectionError } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { apiFailCallback } from 'app/shared_components/helpers'
import { composeAttachment } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import { fetchProperties } from 'app/sm/properties/properties_actions'
import { errorResponseHandler } from 'app/utils/api/helpers'

export const REGISTER_AGENCY_SUCCESSFUL = 'REGISTER_AGENCY_SUCCESSFUL'
export const RECEIVE_CREATE_ERROR = 'AGENCY_RECEIVE_CREATE_ERROR'
export const RECEIVE_REGISTER_ERROR = 'AGENCY_RECEIVE_REGISTER_ERROR'
export const RECEIVE_ERROR = 'AGENCY_RECEIVE_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const CREATE_AGENCY_SUCCESSFUL = 'CREATE_AGENCY_SUCCESSFUL'
export const RECEIVE_REA_LISTINGS = 'RECEIVE_REA_LISTINGS'
export const SHOW_AGENCY_DASHBOARD_SPINNER = 'SHOW_AGENCY_DASHBOARD_SPINNER'
export const HIDE_AGENCY_DASHBOARD_SPINNER = 'HIDE_AGENCY_DASHBOARD_SPINNER'
export const RECEIVE_AGENCY_INFO = 'RECEIVE_AGENCY_INFO'
export const RECEIVE_AGENCY_PROFILE = 'RECEIVE_AGENCY_PROFILE'
export const SHOW_LOAD_MORE_SPINNER = 'SHOW_LOAD_MORE_SPINNER'
export const HIDE_LOAD_MORE_SPINNER = 'HIDE_LOAD_MORE_SPINNER'

export const registerAgencySuccessful = (agencyReponse) => ({
  type: REGISTER_AGENCY_SUCCESSFUL,
  agencyReponse,
})
export const clearServerError = () => ({ type: CLEAR_ERROR })
export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })
export const receiveCreateError = (createError) => ({
  type: RECEIVE_CREATE_ERROR,
  createError,
})
export const receiveRegisterError = (registerError) => ({
  type: RECEIVE_REGISTER_ERROR,
  registerError,
})
export const createAgencySuccessful = (agency) => ({
  type: CREATE_AGENCY_SUCCESSFUL,
  agency,
})
export const showAgencyDashboardSpinner = () => ({
  type: SHOW_AGENCY_DASHBOARD_SPINNER,
})
export const hideAgencyDashboardSpinner = () => ({
  type: HIDE_AGENCY_DASHBOARD_SPINNER,
})

export const showLoadMoreSpinner = () => ({ type: SHOW_LOAD_MORE_SPINNER })
export const hideLoadMoreSpinner = () => ({ type: HIDE_LOAD_MORE_SPINNER })

const channelMapping = (channel) => {
  return channel === 'rent' ? 1 : 2
}

const parsePrice = (price) => {
  if (!price) {
    return null
  }

  if (price.value && typeof price.value === 'number') {
    return price.value
  }

  if (price.display && typeof price.display === 'string') {
    var searchMask = '[a-z$]'
    var regEx = new RegExp(searchMask, 'ig')
    const displayPrice = parseInt(price.display.replace(regEx, '').trim())
    return displayPrice
  }

  return null
}

const formatListings = (listings) =>
  listings.map((listing) => ({
    propertyListingImages: (
      (listing.images &&
        listing.images.map((image) => ({
          server: image.server,
          name: image.name,
          uri: image.uri,
          isAgentImage: false,
          isAgencyLogoImage: false,
          isListingImage: true,
          isMainImage: false,
        }))) ||
      []
    )
      .concat(
        (listing.mainImage && {
          server: listing.mainImage.server,
          name: listing.mainImage.name,
          uri: listing.mainImage.uri,
          isAgentImage: false,
          isAgencyLogoImage: false,
          isListingImage: false,
          isMainImage: true,
        }) ||
          [],
      )
      .concat(
        (listing.listers &&
          listing.listers.map((lister) => ({
            server: lister.mainPhoto && lister.mainPhoto.server,
            name: lister.mainPhoto && lister.mainPhoto.name,
            uri: lister.mainPhoto && lister.mainPhoto.uri,
            isAgentImage: true,
            isAgencyLogoImage: false,
            isListingImage: false,
            isMainImage: false,
          }))) ||
          [],
      )
      .concat(
        (listing.agency &&
          listing.agency.logo &&
          listing.agency.logo.images &&
          listing.agency.logo.images.map((image) => ({
            server: image.server,
            name: image.name,
            uri: image.uri,
            isAgentImage: false,
            isAgencyLogoImage: true,
            isListingImage: false,
            isMainImage: false,
          }))) ||
          [],
      ),

    listers: listing.listers,
    listingId: listing.listingId,
    listerId:
      listing.lister && listing.lister.id && Number.parseInt(listing.lister.id),
    listerName: listing.lister && listing.lister.name,
    listerPhoneNumber:
      listing.lister && listing.lister.phoneNumber.replace(/\s+/g, ''),
    listerEmail: listing.lister && listing.lister.email,
    isFeatured: listing.featured,

    isSignaturePresent: listing.signature,
    channel: channelMapping(listing.channel),
    description: listing.description,
    advertisingRegion: listing.advertising && listing.advertising.region,
    advertisingPriceRange:
      listing.advertising && listing.advertising.priceRange,
    title: listing.title,
    bedrooms: listing.features.general.bedrooms,
    bathrooms: listing.features.general.bathrooms,
    parkingSpaces: listing.features.general.parkingSpaces,
    price: listing.price && parsePrice(listing.price),
    propertyType: listing.propertyType,
    inspectionStartTime:
      listing.nextInspectionTime && listing.nextInspectionTime.startTime,
    inspectionEndTime:
      listing.nextInspectionTime && listing.nextInspectionTime.endTime,
    isAuction: listing.nextInspectionTime && listing.nextInspectionTime.auction,

    propertyListingAgencyId: listing.agency && listing.agency.agencyId,
    propertyListingAgencyName: listing.agency && listing.agency.name,
    propertyListingAgencyEmail: listing.agency && listing.agency.email,
    propertyListingAgencyAddress: listing.agency && listing.agency.address,
    propertyListingAgencyPhoneNumber:
      listing.agency && listing.agency.phoneNumber.replace(/\s+/g, ''),
    bondValue: listing.bond && listing.bond.value,
    dateAvailable: listing.dateAvailable && listing.dateAvailable.date,
    isRentCannel: listing.channel === 'rent',
    isSoldChannel: listing.channel === 'sold',
    propertyFeatures: listing.propertyFeatures,

    address: listing.address,
    modifiedDate: listing.modifiedDate && listing.modifiedDate.value,
    viewings: listing.inspectionsAndAuctions,
  }))
export const receiveAgencyInfo = (agency) => ({
  type: RECEIVE_AGENCY_INFO,
  agency,
})
export const receiveAgencyProfile = (agencyProfile) => ({
  type: RECEIVE_AGENCY_PROFILE,
  agencyResponse: agencyProfile,
})

export function register(agencyProfile) {
  return (dispatch) => {
    dispatch(clearServerError())
    return API.register(agencyProfile).then(
      ({ agencyProfile, ok, statusCode, responseText }) => {
        if (ok) {
          dispatch(registerAgencySuccessful(agencyProfile))
          history.push('/agency/create')
        } else {
          return responseText.then((t) => {
            dispatch(receiveError(t))
            return Promise.reject(translateErrorCodeToMessage(t))
          })
        }
      },
    )
  }
}

export function createAgency(agency, success) {
  return (dispatch) => {
    dispatch(clearServerError())
    return API.createAgency(agency).then(({ agency, ok, responseText }) => {
      if (ok) {
        if (success) {
          success()
        }
        dispatch(createAgencySuccessful(agency))
        history.push('/agency/register')
      } else {
        return responseText.then((t) => {
          const parsedErr = JSON.parse(t)
          return errorResponseHandler({ response: { data: parsedErr } })
        })
      }
    })
  }
}

export const addEnquirerWithPropertyAsManager = (payload) => {
  return () => {
    return postEnquirerWithPropertyAsManager(payload).then(
      ({ ok, responseText, statusCode }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) => {
            return Promise.reject(translateErrorCodeToMessage(error))
          })
        }
      },
    )
  }
}

export function validateInvite(inviteId) {
  return API.validateInvite(inviteId)
}

export function acceptInvite(inviteId) {
  return API.acceptInvite(inviteId)
}

export function clearError() {
  return (dispatch) => {
    dispatch(clearServerError())
  }
}

function fetchReaListingsPerPage(query, dispatch) {
  return API.fetchReaListings(query).then(
    ({ listings, ok, statusCode, responseText }) => {
      if (ok) {
        return Promise.resolve(listings)
      } else {
        responseText.then((t) => {
          dispatch(receiveError(t))
        })
        return Promise.reject(null)
      }
    },
  )
}

export function updateReaListings(query, success, fail) {
  return async (dispatch) => {
    dispatch(clearServerError())
    dispatch(showAgencyDashboardSpinner())

    let { pageSize, page } = query,
      listingResult = [],
      totalResultsCount = null,
      tieredResults = [],
      results = []

    while (!totalResultsCount || page * pageSize < totalResultsCount) {
      const listings = await fetchReaListingsPerPage(query, dispatch)
      if (!listings) {
        if (fail) {
          fail('Error when fetching REA listings')
        }
        return
      } else {
        ;({
          totalResultsCount,
          resolvedQuery: { pageSize, page },
          tieredResults,
        } = listings)
        if (tieredResults && tieredResults.length === 0) {
          return
        }
        ;({ results } = tieredResults[0])
        listingResult = listingResult.concat(results)
        query.page = Number.parseInt(page) + 1
      }
    }

    const formattedListingResult = formatListings(listingResult)
    API.updateReaListings(formattedListingResult, query.agencyGUID).then(
      ({ listings, ok, statusCode, responseText }) => {
        if (ok) {
          dispatch(fetchProperties())
          if (success) {
            success()
          }
        } else {
          apiFailCallback(responseText, fail)
        }
        dispatch(hideAgencyDashboardSpinner())
      },
    )
  }
}

export function searchAgencies(q, pageSize, searchSuccess) {
  return (dispatch) => {
    dispatch(clearServerError())
    API.searchAgencies(q, pageSize).then(({ ok, responseText, agencies }) => {
      if (ok) {
        const matchedAgencies =
          agencies &&
          agencies.map((agency) => ({
            value: agency.name,
            slug: agency.slug,
            address: agency.agencyAddress,
            guidID: agency.guidID,
            franchiseGroup: agency.franchiseGroup,
            crmID: agency.crmID,
          }))
        searchSuccess(matchedAgencies)
      } else {
        responseText.then((t) => {
          dispatch(receiveError(t))
        })
      }
    })
  }
}

export function adminSearchAgencies(q, pageSize, searchSuccess) {
  return (dispatch) => {
    dispatch(clearServerError())
    API.adminSearchAgencies(q, pageSize).then(
      ({ ok, responseText, agencies }) => {
        if (ok) {
          const matchedAgencies =
            agencies &&
            agencies.map((agency) => ({
              value: agency.agencyName,
              slug: agency.slug,
              address: agency.address,
              guidID: agency.agencyGUID,
              franchiseGroup: agency.franchiseGroup,
              crmID: agency.crmID,
              active: agency.active,
              accountType: agency.accountType,
              userCount: agency.userCount,
            }))
          searchSuccess(matchedAgencies)
        } else {
          responseText.then((t) => {
            dispatch(receiveError(t))
          })
        }
      },
    )
  }
}

export function searchProperties(address, pageSize, searchSuccess) {
  return (dispatch) => {
    dispatch(clearServerError())
    API.searchProperties(address, pageSize).then(
      ({ ok, responseText, properties }) => {
        if (ok) {
          const matchedProperties =
            properties &&
            properties.map((property) => ({
              friendlyName: property.address.friendlyName,
              suburb: property.address.suburb,
              state: property.address.state,
              postcode: property.address.postcode,
              slug: property.slug,
            }))
          searchSuccess(matchedProperties)
        } else {
          responseText.then((t) => {
            dispatch(receiveError(t))
          })
        }
      },
    )
  }
}

export function fetchAgencyProfile(agencyGUID, fetchSuccess) {
  return (dispatch) => {
    dispatch(clearServerError())
    API.fetchAgencyProfile(agencyGUID).then(
      ({ ok, responseText, agencyProfile }) => {
        if (ok) {
          fetchSuccess(agencyProfile)
        } else {
          responseText.then((t) => {
            dispatch(receiveRegisterError(t))
          })
        }
      },
    )
  }
}

export function fetchAgencyProfileForBackGroundCheck(agencyGUID) {
  return () => {
    return API.fetchAgencyProfileforBackgroundCheck(agencyGUID).then(
      ({ ok, responseText, agencyProfile }) => {
        if (ok) {
          return Promise.resolve({ agencyProfile })
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function fetchAgencyInfo(agencyGUID) {
  return (dispatch) => {
    return API.fetchAgencyInfo(agencyGUID).then(
      ({ ok, responseText, agency }) => {
        if (ok) {
          dispatch(receiveAgencyInfo(agency))
          return Promise.resolve({ agency })
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          responseText.then((t) => {})
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function fetchAgencyPublicProfile(agencyGUID) {
  return (dispatch) => {
    API.fetchAgencyPublicProfile(agencyGUID).then(
      ({ ok, responseText, agencyProfile }) => {
        if (ok) {
          dispatch(receiveAgencyProfile(agencyProfile))
        } else {
          responseText.then((t) => {})
        }
      },
    )
  }
}

export function updateAgency(agency) {
  return (dispatch) => {
    dispatch(clearServerError())
    return API.updateAgency(agency).then(({ ok, responseText, agency }) => {
      if (ok) {
        dispatch(receiveAgencyInfo(agency))
      } else {
        return responseText.then((t) => {
          const parsedErr = JSON.parse(t)
          return errorResponseHandler({ response: { data: parsedErr } })
        })
      }
    })
  }
}

export function updateAgencyProfile(agencyProfile, udpateSuccess) {
  return (dispatch) => {
    dispatch(clearServerError())
    return API.updateAgencyProfile(agencyProfile).then(
      ({ ok, responseText, agencyProfile }) => {
        if (ok) {
          udpateSuccess(agencyProfile)
        } else {
          return responseText.then((t) => {
            dispatch(receiveRegisterError(t))
            return Promise.reject(translateErrorCodeToMessage(t))
          })
        }
      },
    )
  }
}

export function updateSupplierSource(
  supplierSourceGUID,
  supplierSource,
  success,
  failure,
) {
  return (dispatch) => {
    dispatch(clearServerError())
    API.updateSupplierSource(supplierSourceGUID, supplierSource).then(
      ({ ok, responseText, supplierSource }) => {
        if (ok) {
          success(supplierSource)
        } else {
          responseText.then((t) => {
            failure(t)
          })
        }
      },
    )
  }
}

export function createSupplierSource(supplierSource, success, failure) {
  return (dispatch) => {
    dispatch(clearServerError())
    API.createSupplierSource(supplierSource).then(
      ({ ok, responseText, supplierSource }) => {
        if (ok) {
          success(supplierSource)
        } else {
          responseText.then((t) => {
            failure(t)
          })
        }
      },
    )
  }
}

export function fetchSupplierSource(supplierSourceGUID, success) {
  return (dispatch) => {
    dispatch(clearServerError())
    API.fetchSupplierSource(supplierSourceGUID).then(
      ({ ok, responseText, supplierSource }) => {
        if (ok) {
          success(supplierSource)
        } else {
          responseText.then((t) => {
            dispatch(receiveRegisterError(t))
          })
        }
      },
    )
  }
}

export function fetchAllSupplierSources(success) {
  return (dispatch) => {
    dispatch(clearServerError())
    API.fetchAllSupplierSources().then(
      ({ ok, responseText, supplierSources }) => {
        if (ok) {
          success(supplierSources)
        } else {
          responseText.then((t) => {
            dispatch(receiveRegisterError(t))
          })
        }
      },
    )
  }
}

export function updateAgencySupplierSource(agencySource, success) {
  return () => {
    return API.updateAgencySupplierSource(agencySource).then(
      ({ result, ok, responseText }) => {
        if (ok) {
          return Promise.resolve({ result })
        } else {
          return responseText.then((error) => {
            const parsedErr = JSON.parse(error)
            return errorResponseHandler({ response: { data: parsedErr } })
          })
        }
      },
    )
  }
}

export function addAgencySupplierSource(agencySource, success) {
  return () => {
    return API.addAgencySupplierSource(agencySource).then(
      ({ ok, responseText, agencySource }) => {
        if (ok) {
          return Promise.resolve(agencySource)
        } else {
          return responseText.then((error) => {
            const parsedErr = JSON.parse(error)
            return errorResponseHandler({ response: { data: parsedErr } })
          })
        }
      },
    )
  }
}

export function fetchSupplierSourceForAgency(agencyGUID, success) {
  return (dispatch) => {
    dispatch(clearServerError())
    API.fetchSupplierSourceForAgency(agencyGUID).then(
      ({ ok, responseText, supplierSource }) => {
        if (ok) {
          success(supplierSource)
        } else {
          responseText.then((t) => {
            dispatch(receiveRegisterError(t))
          })
        }
      },
    )
  }
}

export function deleteAgencyUser(agencyUser, agencyGUID, deleteSuccess) {
  return (dispatch) => {
    dispatch(clearServerError())
    API.deleteAgencyUser(agencyUser, agencyGUID).then(
      ({ ok, responseText, agencyUser }) => {
        if (ok) {
          deleteSuccess()
        } else {
          responseText.then((t) => {
            dispatch(receiveRegisterError(t))
          })
        }
      },
    )
  }
}

export function fetchReaAgencyId(agencyGUID, success, fail) {
  return (dispatch) => {
    API.fetchReaAgencyId(agencyGUID).then(
      ({ ok, responseText, reaAgencyId }) => {
        if (ok) {
          success(reaAgencyId)
        } else {
          responseText.then((t) => {
            fail(t)
          })
        }
      },
    )
  }
}

export function fetchTeamManagerList(agencyGUID) {
  return (dispatch) => {
    return API.fetchAgencyManagerList(agencyGUID).then(
      ({ ok, responseText, managerList }) => {
        if (ok) {
          return Promise.resolve({ managerList })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const fetchTeamManagerListPromise = (teamGUID) => (dispatch) => {
  return API.fetchAgencyManagerList(teamGUID).then(
    ({ ok, responseText, managerList }) => {
      if (ok) {
        return Promise.resolve(managerList)
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export function fetchTeamProperties(teamGUID, options) {
  return (dispatch) => {
    return API.fetchTeamProperties(teamGUID, options).then(
      ({ ok, responseText, properties }) => {
        if (ok) {
          return Promise.resolve({ properties })
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function fetchTeamPropertiesByRecentApplication(teamGUID, options) {
  return (dispatch) => {
    return API.fetchTeamPropertiesByRecentApplication(teamGUID, options).then(
      ({ ok, responseText, properties }) => {
        if (ok) {
          return Promise.resolve({ properties })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function fetchTeamMessages(teamGUID, options) {
  return (dispatch) => {
    return API.fetchTeamMessages(teamGUID, options).then(
      ({ ok, responseText, messages }) => {
        if (ok) {
          return Promise.resolve({ messages })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function markMessageAsRead(teamGUID, messageGUID) {
  return (dispatch) => {
    return API.postMarkMessageAsRead(teamGUID, messageGUID).then(
      ({ ok, responseText, data }) => {
        if (ok) {
          return Promise.resolve({ data })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function markMessageAsUnread(teamGUID, messageGUID) {
  return (dispatch) => {
    return API.postMarkMessageAsUnread(teamGUID, messageGUID).then(
      ({ ok, responseText, data }) => {
        if (ok) {
          return Promise.resolve({ data })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const fetchTeamProperty =
  (teamGUID, propertyGUID, filters) => (dispatch) => {
    return API.getTeamProperty(teamGUID, propertyGUID, filters).then(
      ({ ok, propertyOverview, responseText }) => {
        if (ok) {
          return Promise.resolve(propertyOverview)
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }

export const archiveMessage = (teamGUID, messageGUID, archiveStatus) => () => {
  return API.updateArchiveStatusMessage(
    teamGUID,
    messageGUID,
    archiveStatus,
  ).then(({ ok, responseText }) => {
    if (ok) {
      return Promise.resolve()
    } else {
      return responseText.then((error) =>
        Promise.reject(translateErrorCodeToMessage(error)),
      )
    }
  })
}

export const fetchAgencyPaymentGateways = (agencyGUID) => (dispatch) => {
  return API.getAgencyPaymentGateways(agencyGUID).then(
    ({ ok, paymentGateways, responseText }) => {
      if (ok) {
        return Promise.resolve(paymentGateways)
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const fetchUpdateAgencyUserUtilityProviderAgentID =
  (agencyUserGUID, body) => (dispatch) => {
    return API.putAgencyAgentUtilityProviderUserID(agencyUserGUID, body).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }

export const addAgencyBranding = (logoType, agencyGUID, documentType) => () => {
  let attachments = document.getElementById(`attachments-${documentType}`)
  let data = new FormData()
  composeAttachment(attachments, data)
  data.append('type', documentType)

  return API.uploadAgencyBranding(data, logoType, agencyGUID).then(
    ({ ok, responseText, brandingURL }) => {
      if (ok) {
        return Promise.resolve(brandingURL)
      } else {
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      }
    },
  )
}

export const removeAgencyBranding = (logoType, agencyGUID) => () => {
  return API.deleteAgencyBranding(logoType, agencyGUID).then(
    ({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      }
    },
  )
}

export const inviteToApplyEnquirer = (messageID) => () => {
  return API.inviteEnquirer(messageID).then(({ ok, responseText }) => {
    if (ok) {
      return Promise.resolve()
    } else {
      return responseText.then((error) =>
        Promise.reject(translateErrorCodeToMessage(error)),
      )
    }
  })
}

export const fetchAllPropertyFutureViewings = (teamGUID) => (dispatch) => {
  return API.allPropertyFutureViewings(teamGUID).then(
    ({ ok, propertyViewings, responseText }) => {
      if (ok) {
        return Promise.resolve(propertyViewings)
      } else {
        return responseText.then((error) => Promise.reject(error))
      }
    },
  )
}

export function fetchPropertiesForViewingsByProperty(teamGUID, options) {
  return (dispatch) => {
    return API.getPropertiesForViewingsByProperty(teamGUID, options).then(
      ({ ok, responseText, properties }) => {
        if (ok) {
          return Promise.resolve({ properties })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const refreshAgencySourceSupplier = (agencySourceGUID) => {
  return (dispatch) => {
    return API.refreshSourceSupplier(agencySourceGUID).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const fetchUtilityReferralStatus = (applicationGUID) => (dispatch) => {
  return API.getUtilityStatus(applicationGUID).then(
    ({ ok, utilityResponse, responseText }) => {
      if (ok) {
        return Promise.resolve(utilityResponse)
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const fetchPropertytreeTokenForAgency = (agencyGUID) => () => {
  return API.getPropertytreeTokenForAgency(agencyGUID).then(
    ({ ok, responseText, tokens }) => {
      if (ok) {
        return Promise.resolve({ tokens })
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const fetchAllPropertytreeTokens = () => () => {
  return API.getPropertytreeTokens().then(({ ok, responseText, tokens }) => {
    if (ok) {
      return Promise.resolve({ tokens })
    } else {
      if (responseText === undefined) {
        return Promise.reject(NetworkConnectionError)
      }
      return responseText.then((error) =>
        Promise.reject(translateErrorCodeToMessage(error)),
      )
    }
  })
}

export const refreshtreeTokenForAgency = (agencyGUID) => () => {
  return API.postRefreshPropertytreeTokens(agencyGUID).then(
    ({ ok, responseText, tokens }) => {
      if (ok) {
        return Promise.resolve({ tokens })
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const activatePMSIntegration =
  (agencyGUID, tokenAndAgency, pms) => () => {
    return API.postActivatePMSIntegration(agencyGUID, tokenAndAgency, pms).then(
      ({ ok, responseText, tokens }) => {
        if (ok) {
          return Promise.resolve({ tokens })
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }

export const deactivatePMSIntegration = (agencyGUID, pms) => () => {
  return API.postDeactivatePMSIntegration(agencyGUID, pms).then(
    ({ ok, responseText, tokens }) => {
      if (ok) {
        return Promise.resolve({ tokens })
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export function fetchAgencyStatus(teamGUID) {
  return (dispatch) => {
    return API.getAgencyStatus(teamGUID).then(
      ({ ok, responseText, integrationStatus }) => {
        if (ok) {
          return Promise.resolve({ integrationStatus })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const getPropertyBallotsDetail = (propertyGUID) => {
  return (dispatch) => {
    return API.getPropertyBallotsDetail(propertyGUID)
      .then((data) => Promise.resolve(data))
      .catch((code) => Promise.reject(code))
  }
}

export const postRunPropertyBallots = (propertyGUID) => {
  return (dispatch) => {
    return API.postRunPropertyBallots(propertyGUID)
      .then((data) => Promise.resolve(data))
      .catch((code) => Promise.reject(code))
  }
}
