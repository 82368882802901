import { connect } from 'react-redux'

import {
  fetchCards,
  fetchRequestSummary,
  registerPayment,
  verifyReferralCode,
} from 'app/bond_cover/bond_cover_actions'
import PaymentContainer from 'app/bond_cover/renters/identity/payment_container'

const mapStateToProps = ({ bondCover, session }) => ({
  currentUser: session.currentUser,
  currentLease: bondCover.currentLease,
  bankAccount: bondCover.bankAccount,
  quote: bondCover.quote,
  apiError: bondCover.error,
})

const mapDispatchToProps = () => ({
  fetchRequestSummary: (requestID) => fetchRequestSummary(requestID),
  fetchCards: () => fetchCards(),
  registerPayment: (card, requestID) => registerPayment(card, requestID),
  verifyReferralCode: (referralCode, requestID, verifySucceed, verifyFail) =>
    verifyReferralCode(referralCode, requestID, verifySucceed, verifyFail),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer)
