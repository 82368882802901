import { connect } from 'react-redux'

import {
  getSecondaryTenantSummary,
  secondaryTenantApprove,
} from 'app/bond_cover/bond_cover_actions'
import LeaseeSignContainer from 'app/bond_cover/renters/tenants/leasee_sign_container'

const mapStateToProps = ({ bondCover }) => ({
  apiError: bondCover.error,
  request: bondCover.request,
  currentLease: bondCover.currentLease,
  manager: bondCover.manager,
  tenant: bondCover.tenant,
  tenants: bondCover.tenants,
  quote: bondCover.quote,
  identityDoc: bondCover.identityDoc,
  bankAccount: bondCover.bankAccount,
  signature: bondCover.signature,
})

const mapDispatchToProps = (dispatch) => ({
  getSecondaryTenantSummary: (requestID) =>
    getSecondaryTenantSummary(requestID),
  secondaryTenantApprove: (requestID) => secondaryTenantApprove(requestID),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeaseeSignContainer)
