import * as sharedHelpers from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const applyJoinedApplication = (slug, data) => {
  const url = `${apiBaseUrl}sm/applications/${slug}/applicant/withdrawnandapply`
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
  })
    .then(sharedHelpers.checkStatus)
    .then(
      (application) => ({ ok: true, application }),
      sharedHelpers.returnAPIError,
    )
    .catch(sharedHelpers.logAPIError)
}
