import React, { useState } from 'react'
import { useEffect } from 'react'

import { Box, Modal } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'
import ConfirmExitTeamMember from 'app/pages/teams/rental-references/components/ConnectTeam/ConfirmExitTeamMember'
import InviteTeamMember from 'app/pages/teams/rental-references/components/ConnectTeam/InviteTeamMember'
import SuggestedTeamMember from 'app/pages/teams/rental-references/components/ConnectTeam/SuggestedTeamMember'
import { getTeamMembersList } from 'app/sm/team_viewing_preference/components/members_list/api'
import * as GTMHelpers from 'app/utils/google-tag-manager/helpers'

const RENTAL_REFERENCE_MODAL_STEP = {
  SUGGEST_TEAM_MEMBER: 1,
  INVITE_TEAM_MEMBER: 2,
  CONFIRM_EXIT_TEAM_MEMBER: 3,
}

const RentalReferenceModal = ({
  userEmail,
  toggleModal,
  teamGUID,
  toggleInviteTeammemberModal,
  invitedMembers,
  setInvitedMembers,
  fetchMemberList,
}) => {
  const [step, setStep] = useState(
    RENTAL_REFERENCE_MODAL_STEP['SUGGEST_TEAM_MEMBER'],
  )

  const [initialInvite, setInitialInvite] = useState('')

  const toggleInviteTeamMembersModal = () => {
    // setStep(RENTAL_REFERENCE_MODAL_STEP['INVITE_TEAM_MEMBER'])
    toggleModal(false)
    toggleInviteTeammemberModal()
  }

  const toggleSuggestTeamMembers = () => {
    setStep(RENTAL_REFERENCE_MODAL_STEP['SUGGEST_TEAM_MEMBER'])
  }

  const toggleExitConfirm = () => {
    setStep(RENTAL_REFERENCE_MODAL_STEP['CONFIRM_EXIT_TEAM_MEMBER'])
  }

  const onExitConfirm = (cancel) => {
    if (invitedMembers.length > 0) {
      toggleModal(true)
    } else {
      if (step === RENTAL_REFERENCE_MODAL_STEP['CONFIRM_EXIT_TEAM_MEMBER']) {
        cancel
          ? toggleModal(true)
          : setStep(RENTAL_REFERENCE_MODAL_STEP['SUGGEST_TEAM_MEMBER'])
      } else {
        toggleExitConfirm()
      }
    }
  }

  const [modalHeading, setModalHeading] = useState('Add team members')

  const renderModalContent = () => {
    switch (step) {
      case RENTAL_REFERENCE_MODAL_STEP['SUGGEST_TEAM_MEMBER']:
        return (
          <SuggestedTeamMember
            toggleInviteTeamMembersModal={toggleInviteTeamMembersModal}
            teamGUID={teamGUID}
            setInitialInvite={setInitialInvite}
            fetchMemberList={fetchMemberList}
            invitedMembers={invitedMembers}
            setInvitedMembers={setInvitedMembers}
            suggestTeamMemberButtonClassName={
              GTMHelpers.GTM_RR_LITE_SUGGEST_TEAM_MEMBER
            }
            setModalHeading={setModalHeading}
            showSuggestTeamMembersAsLink={true}
          />
        )

      case RENTAL_REFERENCE_MODAL_STEP['INVITE_TEAM_MEMBER']:
        return (
          <InviteTeamMember
            toggleSuggestTeamMembers={toggleSuggestTeamMembers}
            teamGUID={teamGUID}
            setInvitedMembers={setInvitedMembers}
            initialInvite={initialInvite}
          />
        )

      case RENTAL_REFERENCE_MODAL_STEP['CONFIRM_EXIT_TEAM_MEMBER']:
        return <ConfirmExitTeamMember />

      default:
        break
    }
  }

  const getPrimaryAndSecondaryClassName = () => {
    switch (step) {
      case RENTAL_REFERENCE_MODAL_STEP['SUGGEST_TEAM_MEMBER']:
        return {
          primaryClassName: GTMHelpers.GTM_RR_LITE_SUGGEST_TEAM_MEMBERS_PRIMARY,
          secondaryClassName:
            GTMHelpers.GTM_RR_LITE_SUGGEST_TEAM_MEMBERS_SECONDARY,
        }

      case RENTAL_REFERENCE_MODAL_STEP['INVITE_TEAM_MEMBER']:
        return {
          primaryClassName: GTMHelpers.GTM_RR_LITE_ADD_TEAM_MEMBERS_PRIMARY,
          secondaryClassName: GTMHelpers.GTM_RR_LITE_ADD_TEAM_MEMBERS_SECONDARY,
        }

      case RENTAL_REFERENCE_MODAL_STEP['CONFIRM_EXIT_TEAM_MEMBER']:
        return {
          primaryClassName:
            GTMHelpers.GTM_RR_LITE_CONFIRM_EXIT_TEAM_MEMBERS_PRIMARY,
          secondaryClassName:
            GTMHelpers.GTM_RR_LITE_CONFIRM_EXIT_TEAM_MEMBERS_SECONDARY,
        }

      default:
        return { primaryClassName: '', secondaryClassName: '' }
    }
  }

  return (
    <>
      <Modal
        modalBody={
          <Box fontSize={theme.fontSizes.pLarge16} width="100%">
            {renderModalContent()}
          </Box>
        }
        modalHeading={modalHeading}
        primaryLabel={
          step === RENTAL_REFERENCE_MODAL_STEP['CONFIRM_EXIT_TEAM_MEMBER']
            ? 'Invite team members'
            : 'Complete'
        }
        primaryAction={() => {
          onExitConfirm()
        }}
        secondaryLabel="Close"
        secondaryAction={() => {
          onExitConfirm(true)
        }}
        toggleModal={() => {
          onExitConfirm(true)
        }}
        primaryButtonClassName={
          getPrimaryAndSecondaryClassName().primaryClassName
        }
        secondaryButtonClassName={
          getPrimaryAndSecondaryClassName().secondaryClassName
        }
      />
    </>
  )
}

export default RentalReferenceModal
