import { LOCATION_CHANGE } from 'connected-react-router'

import {
  BC_API_COMPLETE,
  BC_CLICK_BUTTON,
  CLEAR_ERROR,
  NEW_BOND_COVER_REQUEST,
  RECEIVE_BANK_ACCOUNT,
  RECEIVE_BOND_COVER_COMPLETE_SUMMARY,
  RECEIVE_BOND_COVER_REQUEST,
  RECEIVE_CURRENT_LEASE,
  RECEIVE_DECLINED_REASON,
  RECEIVE_ERROR,
  RECEIVE_IDENTITY_DOC,
  RECEIVE_MANAGER,
  RECEIVE_MANAGER_REQUEST_SUMMARY,
  RECEIVE_PAYMENT_OPTIONS,
  RECEIVE_PROPERTY_ATTRIBUTES,
  RECEIVE_RATING_CATEGORIES,
  RECEIVE_RENTAL_HISTORY,
  RECEIVE_RENTAL_HISTORY_REVIEW,
  RECEIVE_RENTAL_REFERENCE,
  RECEIVE_REQUEST_REVIEW,
  RECEIVE_REQUEST_SUMMARY,
  RECEIVE_RESPONSE_TEXT,
  RECEIVE_SIGNATURE_SUMMARY,
  RECEIVE_TENANT,
  RECEIVE_TENANT_REVIEW,
  RECEIVE_TENANT_SUMMARY,
  RECEIVE_TENANTS,
  RENTAL_REFERENCE_EDIT_MODE,
  REQUEST_SUBMITTED,
  RESET_RENTAL_REFERENCE,
  SET_RENTAL_HISTORY_SECRET,
  SHOW_RENTAL_LEDGER_SPINNER,
  TENANT_DELETED,
} from 'app/bond_cover/bond_cover_actions'

const _defaultState = {
  request: null,
  currentLease: null,
  manager: null,
  signature: null,
  declined: null,
  versionsRatingCategories: null,
  tenantRating: null,
  tenants: null,
  tenant: null,
  quote: null,
  identityDoc: null,
  bankAccount: null,
  paymentOptions: null,
  card: null,
  renter: null,
  message: '',
  managerRequestSummary: null,
  bondCoverCompleteSummary: null,
  rentalHistory: [],
  attributes: {},
  rentalReference: {},
  rentalHistoryReview: {
    RentalHistory: {},
    Rating: [],
    Renter: {},
  },
  rentalHistoryEditMode: false,
  error: '',
  secretGUID: '',
  clicked: false,
  showRentalLedgerSpinner: false,
}

const BondCoverReducer = (state = _defaultState, action) => {
  let {
    type,
    guidID,
    error,
    request,
    currentLease,
    manager,
    signature,
    declined,
    versionsRatingCategories,
    tenant,
    tenantRating,
    tenants,
    quote,
    identityDoc,
    bankAccount,
    paymentOptions,
    card,
    renter,
    message,
    managerRequestSummary,
    bondCoverCompleteSummary,
    rentalHistory,
    attributes,
    reference,
    rentalHistoryReview,
    secretGUID,
    clicked,
    responseText,
    showRentalLedgerSpinner,
  } = action
  Object.freeze(state)
  let newState = Object.assign({}, state)
  switch (type) {
    case NEW_BOND_COVER_REQUEST:
      return Object.assign(_defaultState, {})
    case RECEIVE_REQUEST_SUMMARY:
      return Object.assign(newState, {
        request,
        currentLease,
        quote,
        manager,
        tenants,
        signature,
        identityDoc,
        bankAccount,
        card,
        error,
      })
    case RECEIVE_CURRENT_LEASE:
      return Object.assign(newState, { currentLease, error })
    case RECEIVE_MANAGER:
      return Object.assign(newState, { manager, error })
    case RECEIVE_TENANTS:
      return Object.assign(newState, { currentLease, tenants, error })
    case RECEIVE_TENANT:
      return Object.assign(newState, { tenant, error })
    case TENANT_DELETED:
      delete newState.tenants
      let index = state.tenants.findIndex((t) => t.guidID === guidID)
      tenants = [
        ...state.tenants.slice(0, index),
        ...state.tenants.slice(index + 1),
      ]
      return Object.assign(newState, { tenants })
    case RECEIVE_PAYMENT_OPTIONS:
      return Object.assign({}, newState, { paymentOptions, error })
    case RECEIVE_BANK_ACCOUNT:
      return Object.assign(newState, { bankAccount, error })
    case RECEIVE_IDENTITY_DOC:
      return Object.assign(newState, { identityDoc, error })
    case REQUEST_SUBMITTED:
      return Object.assign(newState, { request, error })
    case RECEIVE_TENANT_REVIEW:
      return Object.assign(newState, {
        request,
        currentLease,
        renter,
        tenant,
        error,
      })
    case RECEIVE_TENANT_SUMMARY:
      return Object.assign(newState, {
        request,
        currentLease,
        manager,
        tenant,
        tenants,
        quote,
        identityDoc,
        bankAccount,
        signature,
        error,
      })
    case RECEIVE_REQUEST_REVIEW:
      return Object.assign(newState, {
        request,
        currentLease,
        tenantRating,
        renter,
        message,
        error,
      })
    case RECEIVE_DECLINED_REASON:
      return Object.assign(newState, { declined })
    case RECEIVE_RATING_CATEGORIES:
      return Object.assign(newState, { versionsRatingCategories, error })
    case RECEIVE_BOND_COVER_REQUEST:
      return Object.assign(newState, { request, error })
    case RECEIVE_SIGNATURE_SUMMARY:
      return Object.assign(newState, {
        currentLease,
        quote,
        renter,
        signature,
        error,
      })
    case RECEIVE_MANAGER_REQUEST_SUMMARY:
      return Object.assign(newState, { managerRequestSummary, error })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    case RECEIVE_BOND_COVER_COMPLETE_SUMMARY:
      return Object.assign(newState, { bondCoverCompleteSummary, error })
    case LOCATION_CHANGE:
      error = ''
      return Object.assign(newState, { error })
    case RECEIVE_RENTAL_HISTORY:
      return Object.assign(newState, { rentalHistory })
    case RECEIVE_RENTAL_REFERENCE:
      return Object.assign(newState, { rentalReference: reference })
    case RECEIVE_PROPERTY_ATTRIBUTES:
      return Object.assign(newState, { attributes })
    case RENTAL_REFERENCE_EDIT_MODE:
      return Object.assign(newState, { rentalHistoryEditMode: true })
    case RESET_RENTAL_REFERENCE:
      return Object.assign(newState, {
        rentalReference: _defaultState.rentalReference,
        rentalHistoryEditMode: _defaultState.rentalHistoryEditMode,
      })
    case RECEIVE_RENTAL_HISTORY_REVIEW:
      return Object.assign(newState, { rentalHistoryReview })
    case SET_RENTAL_HISTORY_SECRET:
      return Object.assign(newState, { secretGUID })
    case BC_CLICK_BUTTON:
      return Object.assign(newState, { clicked })
    case BC_API_COMPLETE:
      return Object.assign(newState, { clicked })
    case SHOW_RENTAL_LEDGER_SPINNER:
      return Object.assign(newState, { showRentalLedgerSpinner })
    case RECEIVE_RESPONSE_TEXT:
      return Object.assign(newState, { error: responseText })
    default:
      return state
  }
}

export default BondCoverReducer
