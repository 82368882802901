import { connect } from 'react-redux'

import AddAccessDetailModalContainer from 'app/sm/inspections/components/add_access_details_modal_container'
import { fetchPropertyEntryDetails } from 'app/sm/onlist_details/action'

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
})

const mapDispatchToProps = (dispatch) => ({
  fetchPropertyEntryDetails: (agencyGUID, propertyGUID) =>
    dispatch(fetchPropertyEntryDetails(agencyGUID, propertyGUID)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddAccessDetailModalContainer)
