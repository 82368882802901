import React from 'react'

import moment from 'moment'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import directionIcon from 'app/assets/icons/direction-white.svg'
import { topNavHeightInPx } from 'app/components/design-system-components/styles/variables'
import * as Display from 'app/components/display/display'
import {
  defaultEditViewingModalValues,
  durationOptionsNumbers,
} from 'app/components/display/edit_viewing_modal/viewing_modal_data'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import ArchiveConfirmationModal from 'app/dashboard/components/modal_before_archive'
import AddEditViewing from 'app/pages/teams/viewings-mobile/components/AddViewing/add-edit-viewing'
import PropertyInfoDesc from 'app/pages/teams/viewings-mobile/components/PropertyInfoDesc'
import ViewingDetailsCMP from 'app/pages/teams/viewings-mobile/components/ViewingDetails'
import {
  AddViewingContainer,
  NoViewingsContainer,
  PropertyImageContainer,
  PropertyLabelsContainer,
  PropertyViewingContainer,
  ViewingsContainer,
} from 'app/pages/teams/viewings-mobile/styles'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import * as helpers from 'app/sm/helpers'
import AddOwnerModal from 'app/sm/inspections/components/add_owner_modal'
import AddTenantsModal from 'app/sm/inspections/components/add_tenants_modal'
import {
  appPageScrollContainerSelectors,
  scrollToElement,
} from 'app/utils/scroll'
import * as viewingsHelpers from 'app/utils/viewings/helpers'
import { VIEWINGS_BY_PROPERTY } from 'app/utils/viewings/helpers'

const ViewingDetails = withRouter(ViewingDetailsCMP)

const selectPropertyOfferId = (propertyData) =>
  propertyData && propertyData.guidID
const selectPropertyStatus = (propertyData) =>
  propertyData && propertyData.property.status
const selectPropertyGUID = (propertyData) =>
  propertyData && propertyData.property.guidID
const selectPropertySlug = (propertyData) =>
  propertyData && propertyData.property.slug
const selectPropertyImage = (propertyData) => {
  if (
    propertyData &&
    propertyData.property.images &&
    propertyData.property.images.length > 0
  ) {
    return propertyData.property.images.filter((prop) => prop.isMain)[0]
  }
}
const selectAddressFriendlyName = (propertyData) =>
  propertyData &&
  propertyData.property.address &&
  propertyData.property.address.friendlyName

const selectSuburb = (propertyData) =>
  propertyData &&
  propertyData.property.address &&
  propertyData.property.address.suburb

const propertyViewingsDateFilter = moment().subtract(72, 'hours')

const futurePropertyViewings = (propertyViewings) => {
  return propertyViewings.filter(
    (propertyViewing) =>
      moment(propertyViewing.startDate).isAfter(propertyViewingsDateFilter) &&
      propertyViewing.cancelled !== true,
  )
}

const pastAndCancelledPropertyViewings = (propertyViewings) => {
  return propertyViewings.filter(
    (propertyViewing) =>
      moment(propertyViewing.startDate).isBefore(propertyViewingsDateFilter) ||
      propertyViewing.cancelled === true,
  )
}

const createViewingItemId = (viewingID) => `viewing-item-${viewingID}`

const SINGLE_LINE_KEY_TEXT_HEIGHT_PX = 20

const KeyText = styled.div`
  max-width: ${(props) => (props.isMultilineOverflow ? '75%' : '87%')};
`

class PropertyViewing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allViewings: [],
      showAddOrEditViewingModal: false,
      addViewingModal: {
        ...defaultEditViewingModalValues,
      },
      viewingGUIDForEditViewing: '',
      isEditViewingModeOn: false,
      editViewingModalData: {
        ...defaultEditViewingModalValues,
      },
      viewingGUIDForRenterRows: '',
      isOwnerModalActive: false,
      isTenantModalActive: false,
      hasOldOrCancelledViewings: false,
      oldOrCancelledViewings: [],
      finalViewingsList: [],
      showOldOrDeclinedViewings: false,
      isArchiveModalActive: false,
      viewingsLoadingSpinner: false,
      disableAddEditSaveButton: false,
      markAsArchivedOptions: {
        cancelFutureViewings: true,
        declineUnsuccessfulApplications: false,
      },
      keyCodeTextHeight: SINGLE_LINE_KEY_TEXT_HEIGHT_PX,
    }
    this.propertyImgRef = React.createRef()
  }

  componentDidMount() {
    this.scrollToHighlightedViewing()
    this.viewingsListRefresh()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.finalViewingsList !== prevState.finalViewingsList) {
      this.scrollToHighlightedViewing()
    }
  }

  scrollToHighlightedViewing() {
    const { highlightedViewingId } = this.props
    if (!highlightedViewingId) return
    const scrollContainer = document.querySelector(
      appPageScrollContainerSelectors.mobile,
    )

    const { finalViewingsList } = this.state
    const highlightedViewingIsExisted = !!finalViewingsList.find(
      ({ GUID }) => GUID === highlightedViewingId,
    )
    if (!highlightedViewingIsExisted) return

    const propertyImgElem = this.propertyImgRef.current
    if (!propertyImgElem || !scrollContainer) return
    scrollToElement(scrollContainer, propertyImgElem, {
      topMarginInPx: topNavHeightInPx,
    })
  }

  onAddOwnerModalAddIconClicked = (
    nameSearchText = '',
    mobileSearchText = '',
  ) => {
    const addOwnerPreloadContent = { nameSearchText, mobileSearchText }
    this.setState({
      addOwnerPreloadContent,
    })
  }

  onChangeMarkAsArchivedOptions = (checkboxID) => {
    return (event) => {
      const { checked } = event.target
      const { markAsArchivedOptions } = this.state
      this.setState({
        ...this.state,
        markAsArchivedOptions: {
          ...markAsArchivedOptions,
          [checkboxID]: checked,
        },
      })
    }
  }

  onClickAddApplication = () => {
    const { propertyData } = this.props
    const offerId = selectPropertyOfferId(propertyData)
    history.push(
      `${helpers.urlTo('addApplicationByManager', {
        propertyId: offerId,
      })}`,
    )
  }

  onClickAddViewing = (viewingData) => {
    this.showViewingsLoadingSpinner(true)
    this.changeAddEditSaveDisableButtonStatus(true)
    const { addViewing, propertyData, currentTeam } = this.props
    let teamGUID = ''
    if (currentTeam && currentTeam.slug) {
      teamGUID = currentTeam.guid
    }
    const offerIDForViewing =
      propertyData && selectPropertyOfferId(propertyData)
    const {
      startDate,
      startTime,
      published,
      notify,
      notifyShortlistedApplicants,
      duration,
      inspectorsGUIDs,
    } = viewingData || {}
    const mergedStartDate = `${startDate} ${startTime}`
    const formattedStartDate = helpers.getStandardFormattedDate(
      moment(mergedStartDate, helpers.dateTimeWithDash),
      helpers.utcFormatTimeStamp,
    )
    const viewingOptions = {
      published,
      notify,
      notifyShortlistedApplicants,
      inspectorsGUIDs,
    }
    if (propertyData.guidID) {
      addViewing(
        offerIDForViewing,
        formattedStartDate,
        durationOptionsNumbers[duration],
        false,
        false,
        viewingOptions,
      )
        .then((data) => {
          snugNotifier.success('Viewing Successfully added!')
          this.viewingsListRefresh()
          this.openOrCloseAddViewingModal()
        })
        .catch((error) => {
          if (
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForProperty] ||
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForManager]
          ) {
            if (window.confirm(viewingsHelpers.conflictingTimeMessage)) {
              addViewing(
                offerIDForViewing,
                formattedStartDate,
                durationOptionsNumbers[duration],
                false,
                true,
                viewingOptions,
              )
                .then(() => {
                  snugNotifier.success('Viewing Successfully added!')
                  this.viewingsListRefresh()
                  this.openOrCloseAddViewingModal()
                })
                .catch((error) => {
                  snugNotifier.error(error)
                })
            }
          } else {
            snugNotifier.error(error)
          }
        })
        .finally(() => {
          this.changeAddEditSaveDisableButtonStatus(false)
          this.showViewingsLoadingSpinner(false)
        })
    }
  }

  onClickEdit = () => {
    const { propertyData, setBackUrl } = this.props
    const propertyGUID = selectPropertyGUID(propertyData)
    setBackUrl(window.location.pathname)
    history.push(helpers.urlTo('updatePropertyBasics', { id: propertyGUID }))
  }

  onClickEditOnlistDetails = () => {
    const { propertyData, currentTeam } = this.props
    let teamSlug = ''
    if (currentTeam && currentTeam.slug) {
      teamSlug = currentTeam.slug
    }
    const propertyId = selectPropertyGUID(propertyData)
    history.push(
      `${helpers.urlTo('onlistDetails', {
        teamSlug,
      })}?property=${propertyId}&offerGUID=${propertyData.guidID}`,
    )
  }

  onClickEditViewing = (modalData) => {
    this.showViewingsLoadingSpinner(true)
    this.changeAddEditSaveDisableButtonStatus(true)
    const { viewingGUIDForEditViewing } = this.state
    const { editViewing, propertyData, currentTeam } = this.props
    let teamGUID = ''
    if (currentTeam && currentTeam.slug) {
      teamGUID = currentTeam.guid
    }
    const {
      startDate,
      startTime,
      duration,
      published,
      notify,
      notifyShortlistedApplicants,
    } = modalData

    const viewingOptions = {
      published,
      notify,
      notifyShortlistedApplicants,
    }
    const selectedDuration = durationOptionsNumbers[duration]

    const offerIDForViewing =
      propertyData && selectPropertyOfferId(propertyData)
    const formattedStartDate = moment(startDate, helpers.dateWithDash).format(
      helpers.dateWithWeekDayTimeFormatNoComma,
    )
    const fullSelectedDate = `${formattedStartDate} ${startTime}`

    editViewing(
      offerIDForViewing,
      viewingGUIDForEditViewing,
      moment(fullSelectedDate, helpers.dateTimeStampFormat),
      selectedDuration,
      false,
      viewingOptions,
    )
      .then(() => {
        // this.showViewingsLoadingSpinner(false)
        this.viewingsListRefresh()
        snugNotifier.success('Successfully updated viewing')
        this.openOrCloseAddViewingModal()
      })
      .catch((error) => {
        if (
          error === ErrorMessages[Errors.ErrorViewingTimeExistsForProperty] ||
          error === ErrorMessages[Errors.ErrorViewingTimeExistsForManager]
        ) {
          if (window.confirm(viewingsHelpers.conflictingTimeMessage)) {
            editViewing(
              offerIDForViewing,
              viewingGUIDForEditViewing,
              moment(fullSelectedDate, helpers.dateTimeStampFormat),
              selectedDuration,
              true,
              viewingOptions,
            )
              .then(() => {
                snugNotifier.success('Successfully updated viewing!')
                this.viewingsListRefresh()
                this.openOrCloseAddViewingModal()
              })
              .catch((error) => {
                snugNotifier.error(error)
              })
          }
        } else {
          snugNotifier.error(error)
        }
      })
      .finally(() => {
        this.showViewingsLoadingSpinner(false)
        this.changeAddEditSaveDisableButtonStatus(false)
      })
  }

  onClickEditViewingInContextMenu = () => {
    const offerId = selectPropertyOfferId(this.props.propertyData)
    history.push(
      `${helpers.urlTo('profileViewings', { offerId })}?isEditViewing=true`,
    )
  }

  archiveProperty = () => {
    const propertyGUID = selectPropertyGUID(this.props.propertyData)
    const { archiveTimeAdder, archiveProperty } = this.props
    const { cancelFutureViewings, declineUnsuccessfulApplications } =
      this.state.markAsArchivedOptions
    archiveProperty(
      propertyGUID,
      cancelFutureViewings,
      declineUnsuccessfulApplications,
    )
      .then(() => {
        this.setState(
          {
            isArchiveModalActive: false,
          },
          () => {
            archiveTimeAdder()
          },
        )
      })
      .catch(this.props.failCallback)
  }

  changeAddEditSaveDisableButtonStatus = (disabled) => {
    this.setState({
      disableAddEditSaveButton: disabled,
    })
  }

  editViewingClicked = (viewing) => {
    this.openOrCloseAddViewingModal()
    this.setState({
      isEditViewingModeOn: true,
      viewingGUIDForEditViewing: viewing.GUID,
    })
    this.editViewingSelected(viewing)
  }

  editViewingSelected = (viewing) => {
    const {
      duration,
      notify,
      notifyShortlistedApplicants,
      published,
      startDate,
      startTime,
    } = viewing
    this.setState({
      editViewingModalData: {
        duration,
        notify,
        notifyShortlistedApplicants,
        published,
        startDate,
        startTime,
      },
    })
  }

  isAnyViewingOldOrCancelled = (viewings) => {
    return viewings.filter((propertyViewing) => {
      const { viewing } = propertyViewing
      return (
        viewing.cancelled ||
        !moment(viewing.startDate).isAfter(propertyViewingsDateFilter)
      )
    })
  }

  navigateToViewingDetailsTabs(viewingID) {
    const { history, match } = this.props
    const teamSlug = match.params.teamSlug
    history.push(`/mobile/teams/${teamSlug}/viewings/${viewingID}`)
  }

  openOrCloseAddViewingModal = () => {
    const { showAddOrEditViewingModal } = this.state
    this.setState({
      showAddOrEditViewingModal: !showAddOrEditViewingModal,
      editViewingModalData: {
        duration: '',
        notify: '',
        published: false,
        startDate: '',
        startTime: '',
      },
    })
  }

  selectViewingGUIDForRenterRows = (viewing) => {
    this.setState({
      viewingGUIDForRenterRows: viewing.GUID,
    })
  }

  showErrorMessage = (errorMessage) => {
    snugNotifier.error(errorMessage)
  }

  showViewingsLoadingSpinner = (showOrHide) => {
    this.setState({
      viewingsLoadingSpinner: showOrHide,
    })
  }

  toggleModal = (field, option = {}) => {
    const { text, tenantModalCategory, ownerModalCategory } = option
    this.setState({
      [field]: !this.state[field],
      chosenPropertyText: text,
      tenantModalCategory,
      ownerModalCategory,
    })
  }

  viewingsListRefresh = () => {
    const { preloadedPropertiesAndViewings, allViewings, viewingsType } =
      this.props

    const propertyViewingsGUIDs = allViewings
    let allViewingsForProperty = []
    propertyViewingsGUIDs.forEach((propertyViewingsGUID) => {
      if (preloadedPropertiesAndViewings.allViewings[propertyViewingsGUID]) {
        allViewingsForProperty.push(
          preloadedPropertiesAndViewings.allViewings[propertyViewingsGUID],
        )
      }
    })
    this.setState(
      {
        allViewings: allViewingsForProperty,
        loadingSpinner: false,
      },
      () => {
        if (viewingsType === VIEWINGS_BY_PROPERTY) {
          const futureViewings =
            this.state.allViewings &&
            futurePropertyViewings(this.state.allViewings)
          const pastAndCancelledViewings =
            this.state.allViewings &&
            pastAndCancelledPropertyViewings(this.state.allViewings)
          const pastViewingsPresent =
            pastAndCancelledViewings &&
            Object.keys(pastAndCancelledViewings).length !== 0

          this.setState({
            hasOldOrCancelledViewings: pastViewingsPresent,
            oldOrCancelledViewings: pastViewingsPresent
              ? pastAndCancelledViewings
              : [],
            finalViewingsList: futureViewings,
          })
        } else {
          this.setState({
            hasOldOrCancelledViewings: false,
            oldOrCancelledViewings: [],
            finalViewingsList: allViewingsForProperty,
          })
        }
      },
    )
  }

  setKeyCodeTextHeight = (height) => {
    this.setState({
      keyCodeTextHeight: height,
    })
  }

  render() {
    const {
      propertyData,
      addWalkInRenter,
      toggleWalkInModal,
      fetchAgencyApplicantsElasticSearchResult,
      currentTeam,
      clearError,
      showCommentModal,
      removeOnsiteViewingRenter,
      updateRenterStatus,
      sendIndividualInviteToApply,
      showLateViewingModal,
      notifyLateViewing,
      assignViewingToTeamMember,
      fetchViewingTeamMembers,
      deleteViewingTeamMember,
      updateViewingTeamMemberRelation,
      getManagersListForViewingFilters,
      sendInviteToApplyToAllRegistrants,
      requestFeedback,
      cancelViewing,
      fetchPropertyViewingsReportData,
      fetchCurrentTenants,
      fetchCurrentOwners,
      addCurrentTenants,
      addCurrentOwners,
      updateCurrentTenants,
      updateCurrentOwners,
      removeCurrentTenants,
      removeCurrentOwners,
      sendEntryNotice,
      viewingEntryNoticeEnabled,
      isViewingSlotEnabled,
      fetchRegistrantsForViewing,
      dataRegistrantsCurrent,
      createNote,
      isViewingReportSmsEnabled,
      isViewingReportEmailEnabled,
      viewingQRAutoCheckinEnabled,
    } = this.props
    const {
      showAddOrEditViewingModal,
      isEditViewingModeOn,
      editViewingModalData,
      viewingGUIDForRenterRows,
      isOwnerModalActive,
      addAttendeePreloadContent,
      ownerModalCategory,
      isTenantModalActive,
      tenantModalCategory,
      hasOldOrCancelledViewings,
      oldOrCancelledViewings,
      finalViewingsList,
      showOldOrDeclinedViewings,
      isArchiveModalActive,
      viewingsLoadingSpinner,
      disableAddEditSaveButton,
      markAsArchivedOptions,
      keyCodeTextHeight,
    } = this.state
    let teamGUID = ''
    if (currentTeam && currentTeam.slug) {
      teamGUID = currentTeam.guid
    }

    const propertyGUID = selectPropertyGUID(propertyData)
    const propertyStatus = selectPropertyStatus(propertyData)
    const propertySlug = selectPropertySlug(propertyData)
    const { address = {} } = propertyData.property || {}
    const friendlyName = selectAddressFriendlyName(propertyData) || ''
    const suburb = selectSuburb(propertyData) || ''
    const chosenPropertyText = friendlyName + ', ' + suburb
    const offers = { ...propertyData }

    const propertyTools = [
      {
        text: 'Edit',
        enableTool: helpers.isPropertyDraft(propertyStatus),
        onClick: () => {
          window.open(
            helpers.urlTo('updatePropertyBasics', { id: propertyGUID }),
          )
        },
      },
      {
        text: 'Preview',
        enableTool: helpers.isPropertyTakingApplications(propertyStatus),
        onClick: () => {
          window.open(helpers.urlTo('applyRedirect', { slug: propertySlug }))
        },
      },
      {
        text: 'Edit property details',
        enableTool: helpers.isPropertyTakingApplications(propertyStatus),
        onClick: this.onClickEdit,
      },
      {
        text: 'Edit access details',
        enableTool: helpers.isPropertyTakingApplications(propertyStatus),
        onClick: this.onClickEditOnlistDetails,
      },
      {
        text: 'Edit viewings',
        enableTool: helpers.isPropertyTakingApplications(propertyStatus),
        onClick: this.onClickEditViewingInContextMenu,
      },
      {
        text: 'Add owner',
        enableTool: true,
        onClick: () => {
          this.toggleModal(helpers.modalNameCollection.addOwnerModal, {
            text: selectAddressFriendlyName(propertyData),
            ownerModalCategory: helpers.OwnerModalCategory.addOwners,
          })
        },
      },
      {
        text: 'Add current tenants',
        enableTool:
          helpers.isPropertyTakingApplications(propertyStatus) &&
          viewingEntryNoticeEnabled,
        onClick: () =>
          this.toggleModal(helpers.modalNameCollection.addTenantsModal, {
            text: selectAddressFriendlyName(propertyData),
            tenantModalCategory: helpers.TenantModalCategory.addTenants,
          }),
      },
      {
        text: 'Send entry notice',
        enableTool:
          helpers.isPropertyTakingApplications(propertyStatus) &&
          viewingEntryNoticeEnabled,
        onClick: () =>
          this.toggleModal(helpers.modalNameCollection.addTenantsModal, {
            text: selectAddressFriendlyName(propertyData),
            tenantModalCategory: helpers.TenantModalCategory.sendEntryNotice,
          }),
      },
      {
        text: 'Add application',
        enableTool:
          helpers.isPropertyTakingApplications(propertyStatus) ||
          helpers.isPropertyArchived(propertyStatus) ||
          helpers.isPropertyLeased(propertyStatus),
        onClick: () => this.onClickAddApplication(),
      },
      {
        text: 'Add Viewing',
        enableTool: true,
        onClick: () => {
          this.openOrCloseAddViewingModal()
          this.setState({
            isEditViewingModeOn: false,
          })
        },
      },
      {
        text: 'Archive',
        enableTool: true,
        onClick: () => {
          this.setState({
            isArchiveModalActive: true,
          })
        },
      },
      {
        text: 'Edit Onlist Details',
        enableTool: true,
        onClick: () =>
          history.push(
            `${helpers.urlTo('onlistDetailsForProperty', {
              teamSlug: currentTeam.slug,
              offerGUID: propertyData.guidID,
              propertyGUID: propertyGUID,
            })}`,
          ),
      },
    ]
    const selectedProprtyImage = selectPropertyImage(propertyData)
    let backgroundImageURL = ''
    if (selectedProprtyImage) {
      backgroundImageURL = selectedProprtyImage.URL
    }

    let googleMapURL = ''
    if (
      propertyData &&
      propertyData.property &&
      propertyData.property.address
    ) {
      let {
        friendlyName = '',
        suburb = '',
        postcode = '',
        lat = '',
        lng = '',
        googleId = '',
      } = propertyData.property.address
      if (googleId.length === 0) {
        googleMapURL = `https://www.google.com/maps/search/?api=1&query=${friendlyName},${suburb},${postcode}`
      } else {
        googleMapURL = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
      }
    }

    const { highlightedViewingId } = this.props

    return (
      <div>
        <PropertyViewingContainer>
          <PropertyImageContainer
            bgImage={backgroundImageURL}
            ref={this.propertyImgRef}
          >
            <Display.ToolsButton tools={propertyTools} />
            <div className="property-info">
              <KeyText
                isMultilineOverflow={
                  keyCodeTextHeight >= SINGLE_LINE_KEY_TEXT_HEIGHT_PX * 3
                }
                className="property-info-address"
              >
                {chosenPropertyText}
              </KeyText>
              <a href={googleMapURL} target="_blank" rel="noopener noreferrer">
                <img className="ml5" src={directionIcon} alt="Direction Icon" />
              </a>
            </div>
            <PropertyInfoDesc
              setKeyCodeTextHeight={this.setKeyCodeTextHeight}
              propertyData={propertyData}
            />
            <PropertyLabelsContainer>
              <Display.PropertyLabels activeOffer={propertyData} />
            </PropertyLabelsContainer>
          </PropertyImageContainer>
          <ViewingsContainer viewingsLoadingSpinner={viewingsLoadingSpinner}>
            <i
              className={
                viewingsLoadingSpinner
                  ? 'fa fa-spinner fa-pulse viewing-container-spinner'
                  : ''
              }
            />
            {finalViewingsList &&
              finalViewingsList.length > 0 &&
              finalViewingsList.map((viewingData) => {
                const isHighlighted = viewingData.GUID === highlightedViewingId
                return (
                  <div
                    key={viewingData.GUID}
                    id={createViewingItemId(viewingData.GUID)}
                  >
                    <ViewingDetails
                      key={viewingData.GUID}
                      viewingData={viewingData}
                      viewingGUIDForRenterRows={viewingGUIDForRenterRows}
                      selectViewingGUIDForRenterRows={
                        this.selectViewingGUIDForRenterRows
                      }
                      addWalkInRenter={addWalkInRenter}
                      propertyGUID={propertyGUID}
                      toggleWalkInModal={toggleWalkInModal}
                      fetchAgencyApplicantsElasticSearchResult={
                        fetchAgencyApplicantsElasticSearchResult
                      }
                      currentTeam={currentTeam}
                      clearError={clearError}
                      showCommentModal={showCommentModal}
                      removeOnsiteViewingRenter={removeOnsiteViewingRenter}
                      updateRenterStatus={updateRenterStatus}
                      sendIndividualInviteToApply={sendIndividualInviteToApply}
                      showLateViewingModal={showLateViewingModal}
                      notifyLateViewing={notifyLateViewing}
                      assignViewingToTeamMember={assignViewingToTeamMember}
                      fetchViewingTeamMembers={fetchViewingTeamMembers}
                      deleteViewingTeamMember={deleteViewingTeamMember}
                      updateViewingTeamMemberRelation={
                        updateViewingTeamMemberRelation
                      }
                      getManagersListForViewingFilters={
                        getManagersListForViewingFilters
                      }
                      sendInviteToApplyToAllRegistrants={
                        sendInviteToApplyToAllRegistrants
                      }
                      requestFeedback={requestFeedback}
                      cancelViewing={cancelViewing}
                      fetchPropertyViewingsReportData={
                        fetchPropertyViewingsReportData
                      }
                      editViewingClicked={this.editViewingClicked}
                      showViewingsLoadingSpinner={
                        this.showViewingsLoadingSpinner
                      }
                      viewingsListRefresh={this.viewingsListRefresh}
                      isViewingSlotEnabled={isViewingSlotEnabled}
                      teamGUID={teamGUID}
                      fetchRegistrantsForViewing={fetchRegistrantsForViewing}
                      dataRegistrantsCurrent={dataRegistrantsCurrent}
                      createNote={createNote}
                      address={address}
                      onOpenDetails={() =>
                        this.navigateToViewingDetailsTabs(viewingData.GUID)
                      }
                      propertyData={propertyData}
                      viewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
                      isViewingReportSmsEnabled={isViewingReportSmsEnabled}
                      isViewingReportEmailEnabled={isViewingReportEmailEnabled}
                      viewingQRAutoCheckinEnabled={viewingQRAutoCheckinEnabled}
                      isHighlighted={isHighlighted}
                      showAddViewingModal={() => {
                        this.openOrCloseAddViewingModal()
                        this.setState({
                          isEditViewingModeOn: false,
                        })
                      }}
                    />
                  </div>
                )
              })}
            {hasOldOrCancelledViewings &&
              showOldOrDeclinedViewings &&
              oldOrCancelledViewings.length > 0 &&
              oldOrCancelledViewings.map((viewingData) => {
                const isHighlighted = viewingData.GUID === highlightedViewingId
                return (
                  <div key={viewingData.GUID}>
                    <ViewingDetails
                      key={viewingData.GUID}
                      viewingData={viewingData}
                      viewingGUIDForRenterRows={viewingGUIDForRenterRows}
                      selectViewingGUIDForRenterRows={
                        this.selectViewingGUIDForRenterRows
                      }
                      addWalkInRenter={addWalkInRenter}
                      propertyGUID={propertyGUID}
                      propertyData={propertyData}
                      toggleWalkInModal={toggleWalkInModal}
                      fetchAgencyApplicantsElasticSearchResult={
                        fetchAgencyApplicantsElasticSearchResult
                      }
                      currentTeam={currentTeam}
                      clearError={clearError}
                      showCommentModal={showCommentModal}
                      removeOnsiteViewingRenter={removeOnsiteViewingRenter}
                      updateRenterStatus={updateRenterStatus}
                      sendIndividualInviteToApply={sendIndividualInviteToApply}
                      showLateViewingModal={showLateViewingModal}
                      notifyLateViewing={notifyLateViewing}
                      assignViewingToTeamMember={assignViewingToTeamMember}
                      fetchViewingTeamMembers={fetchViewingTeamMembers}
                      deleteViewingTeamMember={deleteViewingTeamMember}
                      updateViewingTeamMemberRelation={
                        updateViewingTeamMemberRelation
                      }
                      getManagersListForViewingFilters={
                        getManagersListForViewingFilters
                      }
                      sendInviteToApplyToAllRegistrants={
                        sendInviteToApplyToAllRegistrants
                      }
                      requestFeedback={requestFeedback}
                      cancelViewing={cancelViewing}
                      fetchPropertyViewingsReportData={
                        fetchPropertyViewingsReportData
                      }
                      editViewingClicked={this.editViewingClicked}
                      showViewingsLoadingSpinner={
                        this.showViewingsLoadingSpinner
                      }
                      viewingsListRefresh={this.viewingsListRefresh}
                      isViewingSlotEnabled={isViewingSlotEnabled}
                      teamGUID={teamGUID}
                      fetchRegistrantsForViewing={fetchRegistrantsForViewing}
                      dataRegistrantsCurrent={dataRegistrantsCurrent}
                      createNote={createNote}
                      address={address}
                      viewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
                      isViewingReportSmsEnabled={isViewingReportSmsEnabled}
                      isViewingReportEmailEnabled={isViewingReportEmailEnabled}
                      isHighlighted={isHighlighted}
                      viewingQRAutoCheckinEnabled={viewingQRAutoCheckinEnabled}
                    />
                  </div>
                )
              })}
            {hasOldOrCancelledViewings && (
              <div
                className="blue-link-style wa ha btnmr show-old-cancelled-link"
                onClick={() =>
                  this.setState({
                    showOldOrDeclinedViewings: !showOldOrDeclinedViewings,
                  })
                }
              >
                {showOldOrDeclinedViewings ? 'Hide' : 'Show'} old and cancelled
              </div>
            )}
          </ViewingsContainer>

          {!hasOldOrCancelledViewings && !finalViewingsList.length && (
            <NoViewingsContainer>
              No viewings scheduled{' '}
              <AddViewingContainer
                onClick={() => {
                  this.openOrCloseAddViewingModal()
                  this.setState({
                    isEditViewingModeOn: false,
                  })
                }}
              >
                Add
              </AddViewingContainer>
            </NoViewingsContainer>
          )}
        </PropertyViewingContainer>
        {showAddOrEditViewingModal && (
          <AddEditViewing
            addViewing={!isEditViewingModeOn}
            cancelButtonFunc={this.openOrCloseAddViewingModal}
            startDate={editViewingModalData.startDate}
            duration={durationOptionsNumbers.indexOf(
              editViewingModalData.duration,
            )}
            published={editViewingModalData.published}
            notify={editViewingModalData.notify}
            notifyShortlistedApplicants={
              editViewingModalData.notifyShortlistedApplicants
            }
            confirmButtonFunc={
              isEditViewingModeOn
                ? this.onClickEditViewing
                : this.onClickAddViewing
            }
            disableAddEditSaveButton={disableAddEditSaveButton}
            teamGUID={teamGUID}
          />
        )}
        {isOwnerModalActive && (
          <AddOwnerModal
            fetchAgencyApplicantsElasticSearchResult={
              fetchAgencyApplicantsElasticSearchResult
            }
            teamId={teamGUID}
            address={address}
            toggleModal={this.toggleModal}
            ownerModalCategory={ownerModalCategory}
            fetchCurrentTenants={fetchCurrentTenants}
            chosenPropertyText={chosenPropertyText}
            chosenPropertyID={propertyGUID}
            addAttendeePreloadContent={addAttendeePreloadContent}
            fetchCurrentOwners={fetchCurrentOwners}
            currentTeam={currentTeam}
            addCurrentOwners={addCurrentOwners}
            updateCurrentOwners={updateCurrentOwners}
            removeCurrentOwners={removeCurrentOwners}
          />
        )}
        {isArchiveModalActive && (
          <ArchiveConfirmationModal
            closeModal={() => {
              this.setState({ isArchiveModalActive: false })
            }}
            clickArchive={() => this.archiveProperty()}
            markAsArchivedOptions={markAsArchivedOptions}
            onChangeMarkAsArchivedOptions={this.onChangeMarkAsArchivedOptions}
          />
        )}
        {isTenantModalActive && (
          <AddTenantsModal
            toggleModal={this.toggleModal}
            address={address}
            teamId={teamGUID}
            fetchAgencyApplicantsElasticSearchResult={
              fetchAgencyApplicantsElasticSearchResult
            }
            chosenPropertyID={propertyGUID}
            fetchCurrentTenants={fetchCurrentTenants}
            addCurrentTenants={addCurrentTenants}
            chosenPropertyText={chosenPropertyText}
            updateCurrentTenants={updateCurrentTenants}
            tenantModalCategory={tenantModalCategory}
            removeCurrentTenants={removeCurrentTenants}
            offers={[offers]}
            sendEntryNotice={sendEntryNotice}
          />
        )}
      </div>
    )
  }
}

export default PropertyViewing
