import { history } from 'app/shared_components/router'
import {
  deleteEmergencyContact,
  fetchEmergencyContact,
  fetchEmergencyContacts,
  sendEmergencyContact,
  updateEmergencyContact,
} from 'app/sm/emergency_contact/emergency_contact_access'
import { dispatchAPIResponse } from 'app/sm/helpers'

export const RECEIVE_EMERGENCY_CONTACTS =
  'EM_CONTACT_RECEIVE_EMERGENCY_CONTACTS'
export const RECEIVE_EMERGENCY_CONTACT = 'EM_CONTACT_RECEIVE_EMERGENCY_CONTACT'
export const RECEIVE_RESPONSE_TEXT = 'EM_CONTACT_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'EM_CONTACT_RECEIVE_ERROR'
export const CLEAR_ERROR = 'EM_CONTACT_CLEAR_ERROR'

export const receiveEmergencyContacts = (emergencyContacts) => ({
  type: RECEIVE_EMERGENCY_CONTACTS,
  emergencyContacts,
})
export const receiveEmergencyContact = (emergencyContact) => ({
  type: RECEIVE_EMERGENCY_CONTACT,
  emergencyContact,
})
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })
export const clearError = () => ({ type: CLEAR_ERROR })

export function removeEmergencyContact(id) {
  return (dispatch) => {
    dispatch(clearError())
    deleteEmergencyContact(id).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(getEmergencyContacts())
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}

export function createEmergencyContact(formData) {
  return (dispatch) => {
    dispatch(clearError())
    sendEmergencyContact(formData).then(({ ok, responseText }) => {
      if (ok) {
        history.goBack()
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}

export function getEmergencyContact(id) {
  return (dispatch) => {
    dispatch(clearError())
    fetchEmergencyContact(id).then(({ ok, emergencyContact, responseText }) => {
      if (ok) {
        dispatch(receiveEmergencyContact(emergencyContact))
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}

export function getEmergencyContacts() {
  return (dispatch) => {
    dispatch(clearError())
    fetchEmergencyContacts().then(({ ok, emergencyContacts, responseText }) => {
      if (ok) {
        dispatch(receiveEmergencyContacts(emergencyContacts))
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}

export function editEmergencyContact(formData, id) {
  return (dispatch, getState) => {
    dispatch(clearError())

    updateEmergencyContact(formData, id).then(({ ok, responseText }) => {
      if (ok) {
        history.goBack()
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}
