import React from 'react'

import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'

const SelectContainer = styled(Box)`
  width: 100%;
  height: 100%;
  .label-text {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
    display: block;
  }
  select {
    display: flex;
    height: 100%;
    padding: 0 ${(props) => props.theme.space[3]}px;
    color: #4d4d4d;
    font-size: ${(props) => props.theme.fontSizes[4]}px;
    font-weight: bold;
    width: 100%;
    border: ${(props) =>
      `${props.theme.borders[1]} ${props.theme.colors.grey200}`};
    border-radius: ${(props) => props.theme.radii[6]}px;
  }
`

const Select = ({
  onSelectChange,
  options,
  id,
  selectName,
  validate,
  label,
  value,
  labelText,
  showSelectLabel = true,
}) => {
  const handleChange = (event) => {
    const {
      target: { value, selectedIndex },
    } = event
    const error = value && validate ? validate(value.trim()) : ''
    onSelectChange({ value: Number(value), selectedIndex, error })
  }
  return (
    <SelectContainer>
      {labelText && <span className="label-text">{labelText}</span>}
      <select name={selectName} id={id} onChange={handleChange} value={value}>
        {showSelectLabel && (
          <option value="" disabled defaultValue>
            {label || 'Select'}
          </option>
        )}
        {options &&
          options.map((opt) => (
            <option id={opt.id} value={opt.id}>
              {opt.name}
            </option>
          ))}
      </select>
    </SelectContainer>
  )
}

export default Select
