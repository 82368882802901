import {
  postViewingRegistrationFromMessage,
  postViewingRegistrationFromSMSMessage,
} from 'app/match/viewings/enquiry_viewing_register/enquiry_viewing_register_access'
import { mapLegacyAccessPromiseToServiceFnPromise } from 'app/services/http/utils'
import { api } from 'app/utils/api/helpers'

export const getEnquiryMessage = (messageId) => {
  return api.get(`sm/enquiry/${messageId}`)
}

export const registerForEnquiryViewing = (
  viewingId,
  messageId,
  registrationData,
) =>
  mapLegacyAccessPromiseToServiceFnPromise(
    postViewingRegistrationFromMessage(viewingId, messageId, registrationData),
  )

export const registerForEnquirySMSViewing = (
  viewingId,
  messageId,
  registrationData,
) =>
  mapLegacyAccessPromiseToServiceFnPromise(
    postViewingRegistrationFromSMSMessage(
      viewingId,
      messageId,
      registrationData,
    ),
  )
