import React from 'react'

import tenancyIcon from 'app/assets/icons/background-report/tenancy.svg'
import SubHeader from 'app/sm/renter_background_check_report/components/sub-header/component'
import DatabasesCheckComponent, {
  CheckMark,
} from 'app/sm/renter_background_check_report/databases_check/component'
import { EquiFaxResponse } from 'app/sm/renter_background_check_report/new_backgroundcheck_report_container'

import 'app/sm/renter_background_check_report/tenancy/style.scss'

const TenancyComponent = ({
  resultInfo = {},
  Enquiries = {},
  updateDate = '',
}) => {
  const { result = false, message = '' } = resultInfo
  const { EnquiryMessage = '' } = Enquiries

  let statusClass = ''
  let statusText = ''

  if (result) {
    statusClass = 'identity-verification-unverified'
    statusText = 'Info'
  } else if (!result) {
    statusClass = 'Verified'
    statusText = 'Not Found'
  }

  const leftContent = (
    <>
      <CheckMark
        text="No entry found for this individual on the National Tenancy Database"
        iconType="verified"
      />
    </>
  )
  const rightContent = (
    <>
      <EquiFaxResponse isNotMb={true}>Equifax response:</EquiFaxResponse>
      <SubHeader status="Verified" text={EnquiryMessage} />
    </>
  )

  return (
    <DatabasesCheckComponent
      label="National Tenancy Database check"
      icon={tenancyIcon}
      statusText={statusText}
      statusClass={statusClass}
      updateDate={updateDate}
      leftBody={leftContent}
      rightBody={rightContent}
    />
  )
}

export default TenancyComponent
