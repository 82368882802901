import { api } from 'app/utils/api/helpers'

export const getCurrentStepForQuickStart = (teamGUID) => {
  return api.get(`/sm/team/${teamGUID}/setup`)
}

export const postNewStepForAgencyForQuickstart = (teamGUID, wizardID) => {
  return api.post(`/sm/team/${teamGUID}/wizard/${wizardID}/progress`)
}

export const postTradingNameAndIds = (teamGUID, payload) => {
  return api.post(`/sm/team/${teamGUID}/setup/started`, payload)
}

export const getSuggestedTeamMembersList = (teamGUID) => {
  return api.get(`/sm/team/${teamGUID}/setup/prefill/members`)
}

export const postInviteTeamMembers = (teamGUID, payload) => {
  return api.post(`/sm/team/${teamGUID}/invite`, payload)
}

export const getQuickWizardData = (teamGUID) => {
  return api.get(`/sm/team/${teamGUID}/setup/info`)
}

export const postBrandingInfo = (teamGUID, payload) => {
  return api.post(`/sm/team/${teamGUID}/setup/branding`, payload)
}

export const getAgencyMembers = (teamGUID) => {
  return api.get(`/sm/teams/${teamGUID}/preferences/members`)
}
