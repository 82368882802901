import React from 'react'

import 'app/components/display/center_content_container/style.scss'

const CenterContentContainer = ({
  children,
  componentClass,
  containerClass = '',
  sizeClass = '',
}) => (
  <div className={`width100 ${sizeClass}`}>
    <div className={`center-container ${containerClass}`}>
      <div
        className={`sm-apply__apply-offer application-wrapper ${componentClass}`}
      >
        <div className="address-rank m0 border-none min-width720-desktop">
          {children}
        </div>
      </div>
    </div>
  </div>
)

export default CenterContentContainer
