import React from 'react'

import moment from 'moment'
import DateTime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'

const TimePicker = ({
  inputProps,
  label,
  defaultValue,
  onBlur,
  onChange,
  value,
  viewMode,
  disableOnClickOutside = false,
  isActive = null,
  onFocus,
  className = '',
}) => {
  const placeholder = (inputProps && inputProps.placeholder) || '09:15am'
  return (
    <div className="input-time">
      <DateTime
        className={`input-box mb0 pt0 ${className}`}
        inputProps={{ placeholder: placeholder, readOnly: true }}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        defaultValue={defaultValue}
        viewMode={viewMode}
        dateFormat={false}
        timeFormat={'hh:mm A'}
        open={isActive}
        closeOnSelect={false}
        disableOnClickOutside={false}
      />
    </div>
  )
}

export default TimePicker
