import React from 'react'

import 'app/match/apply/application_summary_before_submit/shared_components/history_box/style.scss'
import { AddressHistoryDurationText } from 'app/bond_cover/renters/history/components/history_box'
import { Box } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import ContactDetails from 'app/match/apply/application_summary_before_submit/shared_components/contact_container/component'
import {
  formatDate,
  isAddressHistoryOwned,
  propertyArrangementLabels,
  totalTimePassed,
} from 'app/sm/helpers'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'
import { ApplicationDetailsNumbersComponent } from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'

const SummaryStaticHistoryBox = ({ H, hasGreyBackground = false }) => {
  const {
    startDate,
    endDate,
    isRollingLease,
    firstName = '',
    lastName = '',
    address = {},
    propertyArrangement,
    agencyName = '',
    phoneNumber = '',
    email = '',
    officeNumber = '',
    rate,
    ledger,
  } = H

  // trivial: ledgers coming from BE for a renter should be ones added by him
  const ledgersByRenter = ledger?.filter(({ addedByRenter }) => addedByRenter)

  const { years, months, days } = totalTimePassed(
    startDate,
    endDate,
    isRollingLease,
  )
  const { streetNumber, streetName, suburb, state, postcode } = address
  const suburbStateCountryArray = []
  if (suburb) {
    suburbStateCountryArray.push(suburb)
  }
  if (state) {
    suburbStateCountryArray.push(state)
  }

  if (postcode) {
    suburbStateCountryArray.push(postcode)
  }
  const suburbStateCountry = suburbStateCountryArray.join(', ')
  const formattedDate = formatDate(startDate, endDate, isRollingLease)
  const landLordName = { firstName: firstName, lastName: lastName }
  const durationText = AddressHistoryDurationText(
    formattedDate,
    years,
    months,
    days,
  )
  return address ? (
    <div
      className={`rental-history-box p25 ${
        hasGreyBackground && `grey-background`
      }`}
    >
      <div className="rental-history-box-title-wrapper">
        <div className="pb0 col-sm-8 pl0  history-address">
          {streetNumber} {streetName}, {suburbStateCountry}
        </div>
      </div>
      <div className="application-details-numbers-box display-flex flex-wrap">
        <ApplicationDetailsNumbersComponent
          title="Arrangement"
          text={`${propertyArrangementLabels[propertyArrangement]}`}
          componentClassName="width50p align-items-start flex-basis-25p flex-sp"
          titleClassName="history-label"
          textClassName="history-content"
        />
        <ApplicationDetailsNumbersComponent
          title="Rent"
          extraText="/w"
          text={rate}
          componentClassName="width50p align-items-start flex-basis-25p flex-sp"
          titleClassName="history-label"
          textClassName="history-content"
        />
        <ApplicationDetailsNumbersComponent
          title="Duration"
          text={durationText}
          componentClassName="width50p align-items-start flex-basis-25p flex-sp"
          titleClassName="history-label"
          textClassName="history-content"
        />
      </div>
      {!isAddressHistoryOwned(propertyArrangement) && (
        <ContactDetails
          firstName={landLordName.firstName}
          lastName={landLordName.lastName}
          phoneNumber={phoneNumber}
          email={email}
          extraName={agencyName}
          officeNumber={officeNumber}
        />
      )}
      {!!ledgersByRenter?.length && (
        <Box mt={8}>
          <Text
            as="div"
            my={4}
            fontSize={`${theme.fontSizes[6]}px`}
            fontWeight={theme.fontWeights[6]}
            color={theme.colors.gray400}
          >
            Rental ledger
          </Text>
          <Box>
            {ledgersByRenter.map((document, index) => (
              <AppDocumentListItemSecondVersion
                titleClass="m0"
                document={document}
                key={index}
              />
            ))}
          </Box>
        </Box>
      )}
    </div>
  ) : (
    <div />
  )
}

export default SummaryStaticHistoryBox
