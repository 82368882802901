import React from 'react'

import { Field, Form, Formik } from 'formik'
import styled from 'styled-components'

import {
  Box,
  Button,
  ButtonWithLoading,
  Flex,
  Modal,
} from 'app/components/design-system-components'
import { Chip } from 'app/components/design-system-components/chip'
import { TextArea } from 'app/components/design-system-components/inputs/TextArea/TextArea'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  formFields,
  formValidation,
} from 'app/sm/onlist_details/ownerPropertyDisclosure/requestModal/form.utils'

const BoxSeparator = styled(Box)`
  border-bottom: ${(props) =>
    `${props.theme.borders[1]} ${props.theme.colors.gray200}`};
`

const ModalFooter = ({ toggleRequestModal, isRequestSubmitInProgress }) => {
  return (
    <Flex alignItems="center">
      <Button
        type="button"
        variant="solidSecondary"
        flex="1 1 30%"
        onClick={toggleRequestModal}
        mr={theme.space[3] + 'px'}
      >
        Cancel
      </Button>
      <ButtonWithLoading
        type="submit"
        flex="1 1 70%"
        loading={isRequestSubmitInProgress}
      >
        Request
      </ButtonWithLoading>
    </Flex>
  )
}

function RequestModal({
  owners,
  toggleRequestModal,
  onSubmitForm,
  moveToOwnersStep,
  isRequestSubmitInProgress,
}) {
  const hasOwners = owners && owners.length > 0
  const initialValues = {
    [formFields.message]: '',
  }
  const modalBody = (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      validate={formValidation}
      validateOnBlur={true}
    >
      {(props) => (
        <Form style={{ width: '100%' }} formBag={props}>
          <div>
            Request the property disclosures to be displayed to applicants for
            the property.
          </div>
          <BoxSeparator mt={theme.baseSpace * 2 + 'px'}>
            <Box mr={theme.baseSpace + 'px'}>
              <FieldWrapper
                label="To"
                id={formFields.owners}
                name={formFields.owners}
                required="true"
              />
            </Box>
            <Box mb={theme.baseSpace * 4 + 'px'}>
              {!hasOwners && (
                <div>
                  No owner details have been added.{' '}
                  <Button variant="linkBlue" onClick={() => moveToOwnersStep()}>
                    add owner(s)
                  </Button>
                </div>
              )}
              <Flex>
                {hasOwners &&
                  owners.map((owner) => (
                    <Box mr={theme.baseSpace + 'px'}>
                      <Chip>{owner}</Chip>
                    </Box>
                  ))}
              </Flex>
            </Box>
          </BoxSeparator>
          <Box mt={theme.baseSpace * 2 + 'px'} mb={theme.baseSpace * 5 + 'px'}>
            <FieldWrapper
              id={formFields.message}
              label="Message"
              name={formFields.message}
            >
              <Field name={formFields.message} as={TextArea} rows={10} />
            </FieldWrapper>
          </Box>
          <ModalFooter
            toggleRequestModal={toggleRequestModal}
            onSubmitForm={onSubmitForm}
            isRequestSubmitInProgress={isRequestSubmitInProgress}
          />
        </Form>
      )}
    </Formik>
  )
  return (
    <Modal
      modalHeading="Request Disclosures"
      modalBody={modalBody}
      toggleModal={toggleRequestModal}
      customModalFooter={true}
    />
  )
}

export default RequestModal
