import React from 'react'

import { formatNumber } from 'libphonenumber-js'

import mailIcon from 'app/assets/icons/mail_rounded.svg'
import { ContactItemContainer } from 'app/pages/teams/rental-references/styles'

const ContactItem = ({
  firstName,
  lastName,
  email,
  phone,
  textBeforeName,
  textBeforeNameBold,
}) => {
  const formattedPhoneNumber = formatNumber(phone, 'AU', 'International')
  return (
    <ContactItemContainer>
      <div>
        <div>
          <span>{textBeforeName}: </span>
          <span className={textBeforeNameBold ? 'bold-text' : ''}>
            {' '}
            {firstName + ' ' + lastName}
          </span>
        </div>
        <div className="display-flex align-items-center">
          <div>
            Contact: <span className="phone-link">{formattedPhoneNumber}</span>
          </div>
          <div>
            <a href={`mailto:${email}`} className="email-link">
              <img src={mailIcon} alt="email icon" />
            </a>
          </div>
        </div>
      </div>
    </ContactItemContainer>
  )
}
export default ContactItem
