import {
  RECEIVE_ERROR,
  RECEIVE_OFFERED_APPLICATION,
  RECEIVE_PROPERTY,
} from 'app/sm/oa_tenant/oa_tenant_actions'

const _defaultState = {
  property: {
    images: [],
  },
  offeredApplication: {
    Application: {},
    Events: [],
  },
  error: '',
}

const OaTenantReducer = (state = _defaultState, action) => {
  let { type, property, offeredApplication, error } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case RECEIVE_PROPERTY:
      return Object.assign(newState, { property })
    case RECEIVE_OFFERED_APPLICATION:
      return Object.assign(newState, { offeredApplication })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    default:
      return newState
  }
}

export default OaTenantReducer
