import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import { Box, Modal } from 'app/components/design-system-components'
import { MailToLink } from 'app/components/design-system-components/links/MailTo'
import { Text } from 'app/components/design-system-components/typography'
import { TelLink } from 'app/components/display/contact_links'
import theme from 'app/match/applicationReportPDF/assets/theme'
import ManagersDropdown from 'app/pages/teams/rental-references/components/ManagersDropdown/ManagersDropdown'
import { reassignTeamRentalReference } from 'app/pages/teams/rental-references/helpers/http'
import * as snugNotifier from 'app/services/snugNotifier'
import * as helpers from 'app/sm/helpers'
import { urlIds } from 'app/sm/helpers'
import * as phoneNumberHelpers from 'app/utils/phonenumber/helpers'

function ReassignModal({
  recipient = {},
  property = {},
  renter = {},
  toggleReassignModal,
  managerListInfo = {},
  assignee = {},
  agencyGUID = '',
  teamRRGUID = '',
  teamSlug = '',
  getRentalReferencesDataPage,
}) {
  const [selectedManagerGUID, setSelectedManagerGUID] = useState('')

  const { property_address } = property || {}
  const {
    first_name: renterFirstName = '',
    last_name: renterLastName = '',
    phone: renterPhone = '',
    email: renterEmail = '',
  } = renter || {}
  const {
    agency_name: recipientAgencyName = '',
    first_name: recipientFirstName = '',
    last_name: recipientLastName = '',
    phone: recipientPhone = '',
    email: recipientEmail = '',
  } = recipient || {}
  const { managerList } = managerListInfo || {}

  const formattedContactNumber = (number) => {
    return phoneNumberHelpers.formatPhoneNumberWithoutCountryCode(number)
  }
  const onReassignClick = () => {
    const payload = { contactId: selectedManagerGUID }
    reassignTeamRentalReference(agencyGUID, teamRRGUID, payload)
      .then(() => {
        snugNotifier.success('Reassigned Successfully')
        toggleReassignModal()
        getRentalReferencesDataPage(agencyGUID)
      })
      .catch((error) => {
        snugNotifier.error(error?.message)
      })
  }

  const reassignRRDetails = (
    <Box className="width100" fontSize={theme.fontSizes.pLarge16}>
      <Box>
        <Text>
          You're in control with rental references on Snug, with optional
          responses and private visibility{' '}
          <a
            href="https://help.snug.com/hc/en-us/articles/360000931856"
            target="_blank"
            rel="noreferrer"
          >
            learn more
          </a>
          . Need to reassign?{' '}
          <Link
            to={helpers.urlTo(urlIds.teams.settings.following, { teamSlug })}
          >
            Add Team members
          </Link>{' '}
          for free.
        </Text>
      </Box>

      <Box>
        <Text fontWeight={theme.fontWeights.h2} className="mb0">
          Property:{' '}
          <Text as="span" fontWeight={theme.fontWeights[3]}>
            {property_address}
          </Text>
        </Text>
      </Box>

      <Box>
        <Text fontWeight={theme.fontWeights.h2} className="mb0">
          Renter:{' '}
          <Text as="span" fontWeight={theme.fontWeights[3]}>
            {renterFirstName} {renterLastName} (
            <TelLink phone={formattedContactNumber(renterPhone)}>
              {formattedContactNumber(renterPhone)}
            </TelLink>
            , <MailToLink email={renterEmail}>{renterEmail}</MailToLink>)
          </Text>
        </Text>
      </Box>

      <Box>
        <Text fontWeight={theme.fontWeights.h2}>
          Recipient:{' '}
          {recipient ? (
            <Text as="span" fontWeight={theme.fontWeights[3]}>
              {recipientAgencyName} ({recipientFirstName} {recipientLastName},{' '}
              <TelLink phone={formattedContactNumber(recipientPhone)}>
                {formattedContactNumber(recipientPhone)}
              </TelLink>
              , <MailToLink email={recipientEmail}>{recipientEmail}</MailToLink>
              )
            </Text>
          ) : (
            <Text as="span" fontWeight={theme.fontWeights[3]}>
              Profile Reference
            </Text>
          )}
        </Text>
      </Box>
      <Box>
        <ManagersDropdown
          managerListInfo={managerList}
          assignee={assignee}
          setSelectedManagerGUID={setSelectedManagerGUID}
        />
      </Box>
    </Box>
  )

  return (
    <Modal
      modalHeading="Reassign rental reference"
      modalBody={reassignRRDetails}
      primaryLabel="Reassign"
      secondaryLabel="Cancel"
      toggleModal={toggleReassignModal}
      secondaryAction={toggleReassignModal}
      primaryAction={() => {
        onReassignClick()
      }}
    />
  )
}

export default ReassignModal
