import React from 'react'

import { Route } from 'react-router-dom'
import { Link } from 'react-router-dom'

import SearchInline from 'app/sm/common/search-inline'
import AssignProviderModal from 'app/sm/property_maintenance/components/assign_provider_modal'
import MaintenanceReport from 'app/sm/property_maintenance/components/maintenance_report'
import MaintenanceRequestModal from 'app/sm/property_maintenance/components/maintenance_request_modal'
import * as MockData from 'app/sm/property_maintenance/property_maintenance_mock_data'

class PropertyMaintenanceContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      MaintenanceRequestModalActive: false,
      AssignProviderModalActive: false,
      MaintenanceReportActive: false,
    }
  }

  showAssignProviderModal() {
    this.setState({ AssignProviderModalActive: true })
  }

  showMaintenanceReport() {
    this.setState({ MaintenanceReportActive: true })
  }

  showMaintenanceRequestModal() {
    this.setState({ MaintenanceRequestModalActive: true })
  }

  render() {
    let path = this.props.match.path

    if (this.state.MaintenanceRequestModalActive) {
      return <MaintenanceRequestModal />
    }

    if (this.state.AssignProviderModalActive) {
      return <AssignProviderModal />
    }

    if (this.state.MaintenanceReportActive) {
      return <MaintenanceReport />
    }

    return (
      <div>
        <Route
          exact
          path={`${path}/report`}
          render={({ match }) => {
            return <MaintenanceReport />
          }}
        />
        <Route
          exact
          path={`${path}`}
          render={({ match }) => {
            return (
              <div className="sm-property-maintenance">
                <div className="section-title xs-row">
                  <div className="left">
                    <Link to={'/'} className="lh1">
                      <h3 className="lh1">
                        <i className="icon-arrow-left fs16 pt2 pr10 xs-ibm"></i>
                        <span className="ibm lh1">Maintenance (4)</span>
                      </h3>
                    </Link>
                  </div>
                  <div className="right">
                    <SearchInline placeholder="Search..." className="xs-hide" />
                    <button
                      onClick={() => this.showMaintenanceRequestModal()}
                      className="btn btn-small-xs wa ml20"
                    >
                      <i className="icon-add left ibm fs13"></i>
                      <span className="ibm xs-hide">Create Request</span>
                      <span className="xs-ibm">Add</span>
                    </button>
                  </div>
                </div>
                <table className="style4">
                  <thead>
                    <tr>
                      <td colSpan="2">Subject</td>
                      <td>Reported by</td>
                      <td className="sm-hide">Date Req.</td>
                      <td className="sm-hide">Assignee</td>
                      <td>Status</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {MockData.Maintenance.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className={`priority ${item.priority}`}></div>
                        </td>
                        <td
                          className="cp"
                          onClick={() => this.showMaintenanceReport()}
                        >
                          {item.subject}
                        </td>
                        <td className="wsnw">
                          <div className="table">
                            <div className="cell-m">
                              {item.reportedByImage != '' ? (
                                <div
                                  className="avatar avatar-xsmall mr10"
                                  style={{
                                    backgroundImage: `url('${item.reportedByImage}')`,
                                  }}
                                ></div>
                              ) : (
                                <div className="avatar avatar-xsmall mr10 blue-bg-light-rgba blue-color">
                                  {item.reportedByFirstname.charAt(0)}
                                  {item.reportedByLastname.charAt(0)}
                                </div>
                              )}
                            </div>
                            <div className="cell-m-100">
                              <div className="fw600">
                                {item.reportedByFirstname}{' '}
                                {item.reportedByLastname.charAt(0)}.
                              </div>
                              <div className="gray-color fs14 pt2">
                                {item.reportedByStatus}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="wsnw sm-hide">
                          <div className="fw600">{item.date}</div>
                          <div className="gray-color fs14 pt2">{item.time}</div>
                        </td>
                        <td className="wsnw sm-hide">
                          {item.assigneeFirstname == '' ? (
                            <a
                              className="gray-dark-color"
                              onClick={() => this.showAssignProviderModal()}
                            >
                              Assign Provider
                            </a>
                          ) : (
                            <div className="table">
                              <div className="cell-m">
                                {item.assigneeImage != '' ? (
                                  <div
                                    className="avatar avatar-xsmall mr10"
                                    style={{
                                      backgroundImage: `url('${item.assigneeImage}')`,
                                    }}
                                  ></div>
                                ) : (
                                  <div className="avatar avatar-xsmall mr10 blue-bg-light-rgba blue-color">
                                    {item.assigneeFirstname.charAt(0)}
                                    {item.assigneeFirstname.charAt(0)}
                                  </div>
                                )}
                              </div>
                              <div className="cell-m-100">
                                <div className="fw600">
                                  {item.assigneeFirstname}{' '}
                                  {item.assigneeLastname}
                                </div>
                                <div className="gray-color fs14 pt2">
                                  {item.assigneeStatus}
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                        <td className="wsnw">
                          <div
                            className={`status ${item.ststusColor}-bg-light-rgba02`}
                          >
                            {item.statusTitle}
                          </div>
                        </td>
                        <td>
                          <div className="dropdown actions">
                            <div
                              className="dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              <button className="btn btn-round btn-medium btn-gray-light">
                                <i className="icon-more"></i>
                              </button>
                            </div>
                            <ul className="dropdown-menu dropdown-menu-right mt5">
                              {item.assigneeFirstname == '' && (
                                <li
                                  onClick={() => this.showAssignProviderModal()}
                                >
                                  <Link to={'/'}>Assign Provider</Link>
                                </li>
                              )}
                              <li>
                                <Link to={'/'}>View</Link>
                              </li>
                              <li>
                                <Link to={'/'}>Edit</Link>
                              </li>
                              <li>
                                <Link to={'/'}>Delete</Link>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          }}
        />
      </div>
    )
  }
}

export default PropertyMaintenanceContainer
