import { connect } from 'react-redux'

import { searchAgencies } from 'app/bond_cover/agency/agency_actions'
import {
  addManager,
  fetchManager,
  updateManager,
} from 'app/bond_cover/bond_cover_actions'
import ManagerFormContainer from 'app/bond_cover/renters/request/manager_form_container'

const mapStateToProps = ({ session, bondCover, Shared }) => ({
  apiError: bondCover.error,
  currentUser: session.currentUser,
  manager: bondCover.manager,
  backUrl: Shared.backUrl,
})

const mapDispatchToProps = (dispatch) => ({
  addManager: (requestID, manager) => addManager(requestID, manager),
  fetchManager: (requestID, id) => fetchManager(requestID, id),
  updateManager: (requestID, manager, id) =>
    updateManager(requestID, manager, id),
  searchAgencies: (agencyName, pageSize, searchSuccess) =>
    dispatch(searchAgencies(agencyName, pageSize, searchSuccess)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManagerFormContainer)
