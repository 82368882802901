import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import NextText from 'app/bond_cover/renters/sharedComponent/next_text_component'
import * as Display from 'app/components/display/display'
import ProgressBarComponent from 'app/components/progress_bar/connection'
import BasicModal from 'app/modals/basic_modal'
import getParameter from 'app/shared_components/parameter_parser'
import Header from 'app/shared_components/rental_reputation_header_connection'
import { history } from 'app/shared_components/router'
import SnugTip from 'app/shared_components/snug_tips'
import EmergencyContactBox from 'app/sm/emergency_contact/components/emergency_contact_box'
import { isFromApplication, urlTo } from 'app/sm/helpers'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'

class EmergencyContactAdd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      SubmitBtnClicked: false,
      modalOpen: false,
    }
  }

  componentDidMount() {
    const {
      getEmergencyContacts,
      addRentalReputationBackUrl,
      fetchCompleteness,
    } = this.props
    getEmergencyContacts()
    const propertyId = getParameter('propertyId')
    const applicationId = getParameter('applicationId')
    this.setState({
      applicationIdFromUrl: applicationId,
      propertyIdFromUrl: propertyId,
    })
    fetchCompleteness()
  }

  btnTextChange = (isFromApplicationForm, applicant, SubmitBtnClicked) => {
    if (isFromApplicationForm) {
      if (!applicant.applied) {
        return (
          <div>
            Submit Application
            <i className={SubmitBtnClicked ? 'fa fa-spinner fa-pulse' : ''} />
          </div>
        )
      } else if (applicant.applied && applicant.isPrimary) {
        return (
          <div>
            Update
            <i className={SubmitBtnClicked ? 'fa fa-spinner fa-pulse' : ''} />
          </div>
        )
      }
    }
    return 'Complete'
  }

  checkForEmergencyContactLenth = (isFromApplicationForm = false) => {
    const { emergencyContacts = [] } = this.props
    const applicationId = getParameter('applicationId')
    const nextUrl = isFromApplicationForm
      ? `${urlTo('applicationSummary', { applicationId })}${
          window.location.search
        }`
      : urlTo('RentalReputation')
    if (emergencyContacts && emergencyContacts.length > 0) {
      history.push(nextUrl)
    } else {
      this.toggleModal()
    }
  }

  confirmRemoveContact = (guidID) => {
    if (window.confirm('Are you sure?')) {
      this.props.removeEmergencyContact(guidID)
    }
  }

  isFromApplicationPortal = () => {
    const { backUrl } = this.props
    const { applicationIdFromUrl, propertyIdFromUrl } = this.state
    return (
      isFromApplication(backUrl) || applicationIdFromUrl || propertyIdFromUrl
    )
  }

  toggleModal = () => {
    const { modalOpen } = this.state
    this.setState({ modalOpen: !modalOpen })
  }

  render() {
    const { modalOpen } = this.state
    const {
      emergencyContacts = [],
      backUrl,
      property,
      completeness,
    } = this.props
    const addNextOfKinModalBody =
      'Next of Kin and Emergency Contact required to complete a property rental application.'
    const isFromApplicationForm = this.isFromApplicationPortal()
    return (
      <Display.CenterContentContainer componentClass="width100">
        <ProgressBarComponent
          completeness={completeness}
          clickNextFunctionFromParentComponent={
            this.checkForEmergencyContactLenth
          }
        />
        <RentalReputationHeaderReminder
          property={property}
          backUrl={backUrl}
          remindText={'Current application'}
          isFromApplicationForm={isFromApplicationForm}
          title="Emergency Contact"
        />
        <Header
          title="Next of Kin and Emergency Contact"
          message="Add a Next of Kin (eg. parents, siblings) or Emergency Contact who does not reside with you, if required regarding your tenancy."
        />

        <div className="table mb20">
          <div className="cell-m-100">
            <h4>Add Next of Kin and Emergency Contact</h4>
          </div>
          <div className="cell-m">
            <Link className="btn btn-round" to={urlTo('emergencyContactAdd')}>
              <i className="icon-add" />
            </Link>
          </div>
        </div>

        <div className="mb50">
          {(emergencyContacts || []).length > 0 &&
            emergencyContacts.map((contact) => (
              <EmergencyContactBox
                confirmRemoveContact={this.confirmRemoveContact}
                contact={contact}
                key={contact.guidID}
              />
            ))}
        </div>
        <ReactTooltip className="tooltip" />

        <SnugTip tipContent="Next of Kin and Emergency Contacts are required to apply for a property and only shared with property managers on the rental applications you submit." />
        <Display.BottomContentContainer>
          <Display.CenterContentContainer componentClass="width100">
            <div className="submit-button-container">
              <BottomButton
                btnText={isFromApplicationForm ? 'Save & Continue' : 'Complete'}
                clickNextFunction={() =>
                  this.checkForEmergencyContactLenth(isFromApplicationForm)
                }
                bottomButtonsClass="pt0 mt0 summary-bottom-button"
                nextBtnClass="summary-green-button-class"
                backButtonClass="summary-grey-button-class"
                nextButtonPositionClass="pr20 pl0"
                showArrow={false}
              />
            </div>
          </Display.CenterContentContainer>
        </Display.BottomContentContainer>
        {modalOpen && (
          <BasicModal
            toggleModal={this.toggleModal}
            title={'Add Next of Kin/ Emergency Contact'}
            body={addNextOfKinModalBody}
            primaryButtonLabel={'Add Contact'}
            primaryButtonAction={() => this.toggleModal()}
            showSecondaryButton={false}
          />
        )}
      </Display.CenterContentContainer>
    )
  }
}

export default EmergencyContactAdd
