import React from 'react'

import PropTypes from 'prop-types'
import Rater from 'react-rater'

import Card from 'app/match/applicationReportPDF/components/Card'
import CustomCheckbox from 'app/match/applicationReportPDF/components/CustomCheckbox'
import InfoList from 'app/match/applicationReportPDF/components/InfoList'
import PDFBullet from 'app/match/applicationReportPDF/components/PDFBullet'
import Persona from 'app/match/applicationReportPDF/components/Persona'
import CustomStar from 'app/match/applicationReportPDF/components/Star'
import TitleHorizontalLine from 'app/match/applicationReportPDF/components/TitleHorizontalLine'
import * as helpers from 'app/sm/helpers'

import 'react-rater/lib/react-rater.css'

const propTypes = {
  data: PropTypes.array,
}

const rentTitles = {
  pleasantToDealWith: 'Pleasant to deal with',
  looksAfterTheProperty: 'Looks after the property',
  overall: 'Overall',
}

const AddressHistory = ({
  data = [],
  excludeRequestReasonForMoving = false,
}) => {
  return (
    <div className="address-history no-break-section">
      <Card
        type={'icon'}
        icon={'icon-home'}
        title={'Address History'}
        addClass={'bottom-border'}
      />
      {(!data || data.length === 0) && <p>No information is provided</p>}
      {data &&
        data.map((addressInfo, index) => {
          const { addressWithRating = false } = addressInfo
          const { address, rent, occupancy, reasonForLeaving, arrangement } =
            addressInfo.generalInfo
          let exceptionRatingQuestions = []
          if (addressWithRating) {
            exceptionRatingQuestions = addressInfo.exceptionRatingQuestions
          }

          const infoListData = excludeRequestReasonForMoving
            ? {
                rent,
                occupancy,
                arrangement,
              }
            : {
                rent,
                occupancy,
                arrangement,
                reasonForLeaving,
              }

          return (
            <div key={index} className="mb30 no-break-section">
              <h2>{address}</h2>
              <div className="custom-row">
                <div
                  className={`custom-column ${
                    addressWithRating ? 'equal-basis' : ''
                  }`}
                >
                  <InfoList data={infoListData} />
                </div>
                {addressWithRating && (
                  <div className="custom-column equal-basis">
                    <span className="text-gray">Rental Reference</span>
                    <ul className={'list-reset'}>
                      {Object.keys(addressInfo.rentalReference).map(
                        (item, index) => {
                          return (
                            <li
                              key={index}
                              className="pdf-flex-row space-between"
                            >
                              <span>{rentTitles[item]}</span>
                              <Rater
                                total={5}
                                rating={Number(
                                  addressInfo.rentalReference[item],
                                )}
                                interactive={false}
                              >
                                <CustomStar />
                              </Rater>
                            </li>
                          )
                        },
                      )}
                    </ul>
                  </div>
                )}
              </div>
              {addressWithRating && (
                <div>
                  <div>
                    <ul className="info-list text-gray-dark mt-5 t-transform-none">
                      {exceptionRatingQuestions.map((rating, index) => {
                        let textForAnswer = ''
                        const {
                          answerValue = '',
                          questionType,
                          exception,
                        } = rating
                        if (answerValue) {
                          if (helpers.isQuestionTypeBoolean(questionType)) {
                            textForAnswer =
                              answerValue === 'true' ? 'Yes' : 'No'
                          } else if (helpers.isQuestionTypeText(questionType)) {
                            textForAnswer = answerValue
                          }
                        } else {
                          textForAnswer = 'No answers'
                        }

                        return (
                          <li className="info-list__item" key={index}>
                            <div className="info-list__item--title">
                              {rating.description}
                            </div>
                            <div className="info-list__item--value">
                              {
                                <CustomCheckbox
                                  type={
                                    answerValue &&
                                    helpers.isQuestionTypeBoolean(questionType)
                                      ? exception
                                        ? 'cross'
                                        : 'check'
                                      : null
                                  }
                                  label={textForAnswer}
                                />
                              }
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                  <div className="custom-row">
                    <div className="custom-column equal-basis">
                      <TitleHorizontalLine title={'Contact'} />
                      <Persona info={addressInfo.contact} />
                      <PDFBullet
                        label={'Verified by'}
                        value={addressInfo.contact.verifier}
                        supportingText={addressInfo.contact.date}
                      />
                    </div>
                    <div className="custom-column equal-basis">
                      <span className="text-gray">Comment</span>
                      <div className="comments-archive">
                        <div className="comment-single">
                          <p>{addressInfo.comment}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        })}
    </div>
  )
}

AddressHistory.propTypes = propTypes

export default AddressHistory
