import { connect } from 'react-redux'

import { adminDecline, clearError } from 'app/bond_cover/admin/admin_actions'
import DeclineForm from 'app/bond_cover/admin/requests/admin_decline_container'

const mapStateToProps = ({ admin }) => ({
  request: admin.requestSummary.request,
  spinner: admin.spinner,
  responseText: admin.responseText,
})

const mapDispatchToProps = (dispatch) => ({
  adminDecline: (data, appCode) => adminDecline(data, appCode),
  clearError: () => clearError(),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeclineForm)
