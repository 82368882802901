import PropTypes from 'prop-types'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Circle } from 'app/components/design-system-components/Circle'
import Flex from 'app/components/design-system-components/Flex'
import TextSpan from 'app/components/design-system-components/TextSpan'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const StatusDot = ({
  id,
  labelText,
  variant,
  hoverHint,
  ...otherProps
}) => {
  return (
    <Flex
      alignItems="center"
      data-tooltip-content=""
      data-tooltip-id={id}
      {...otherProps}
    >
      <Circle
        sizeInPx={theme.fontSizes[4]}
        variant={variant}
        mr={theme.space[1] + 'px'}
      />
      <TextSpan color="gray600" fontSize={theme.fontSizes[3] + 'px'}>
        {labelText}
      </TextSpan>
      <ReactTooltip id={id}>{hoverHint}</ReactTooltip>
    </Flex>
  )
}

const statusPillProps = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  variant: PropTypes.string,
  hoverHint: PropTypes.string,
}

StatusDot.propTypes = statusPillProps

export const StatusDots = ({ pillConfigs, ...otherProps }) => {
  const notLastStatusPillsMargin = { mr: `${theme.space[3] + 'px'}` }
  return (
    <Flex alignItems="center" {...otherProps}>
      {pillConfigs.map((config, i) => {
        const last = i === pillConfigs.length - 1
        const marginProps = last ? {} : notLastStatusPillsMargin
        return (
          <StatusDot
            key={config.id}
            id={config.id}
            {...config}
            {...marginProps}
          />
        )
      })}
    </Flex>
  )
}

StatusDots.propTypes = {
  pillConfigs: PropTypes.arrayOf(PropTypes.exact(statusPillProps)),
}
