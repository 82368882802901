import React from 'react'

import PropTypes from 'prop-types'

import { Box, Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as helpers from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

import 'app/match/applicationReportPDF/pages/Snug-pdf-1/Header/style.scss'

const propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  data_become_member: PropTypes.string,
  advertised: PropTypes.array,
  app_number: PropTypes.string,
  submitTime: PropTypes.string,
}

const setIcon = (type) => {
  switch (type) {
    case 'bedCount':
      return 'bedroom'
    case 'showerCount':
      return 'bath-thub'
    case 'carCount':
      return 'car-front'
    case 'timeTo':
      return 'calendar'
    case 'maxRentPrice':
      return 'money'
    default:
      return null
  }
}

const Header = ({
  applicantInfo: {
    firstName = '',
    lastName = '',
    address = 'unknown',
    advertised,
    app_number = '000',
    submitTime = 'unknown',
  },
  agencyInfo = {},
  currentUser = {},
}) => {
  const { brandingLogoURL = '', agencyName = '' } = agencyInfo || {}
  const {
    firstName: loggedInUserFirstName = '',
    lastName: loggedInUserLastName = '',
    email: loggedInUserEmail = '',
  } = currentUser
  const agencyLogoOrName = (
    <>
      {brandingLogoURL && brandingLogoURL.length > 0 ? (
        <img src={brandingLogoURL} alt="logo" />
      ) : (
        <Text
          fontSize={theme.fontSizes.pExtraLarge18}
          mb={0}
          fontWeight={theme.fontWeights.h1}
        >
          {agencyName}
        </Text>
      )}
    </>
  )
  const downloadedInfoDateAndUser = (
    <Text
      color={theme.colors.pdfGray}
      fontSize={theme.fontSizes.pExtraSmall10}
      m={0}
    >
      <Box>
        Downloaded{' '}
        {dateTimeHelpers.getCurrentDate(
          dateTimeHelpers.TIME_AM_PM_HALF_DAY_HALF_MONTH,
        )}{' '}
      </Box>
      <Box>
        {loggedInUserFirstName} {loggedInUserLastName} ({loggedInUserEmail})
      </Box>
    </Text>
  )
  return (
    <header>
      <div className="applicant-info">
        <Flex alignItems="center" justifyContent="space-between">
          <Box>
            <div className=" text-gray">
              <Box>
                <div className="text-gray">{address}</div>
              </Box>
              <Box>
                {!helpers.isApplyAnywhere() && (
                  <div className="pdf-flex-row">
                    <span className="text-gray">Advertised</span>
                    <ul className={'list-horizontal'}>
                      {Object.keys(advertised).map((item, index) => {
                        return (
                          <li className="list-horizontal__item" key={index}>
                            <i className={`icon icon-${setIcon(item)}`}></i>
                            <span className={'counter'}>
                              {item === 'timeTo' && `${advertised[item]}m`}
                              {item === 'maxRentPrice' &&
                                `$${advertised[item]}/w`}
                              {item !== 'timeTo' &&
                                item !== 'maxRentPrice' &&
                                advertised[item]}
                            </span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )}
              </Box>
            </div>
          </Box>
          <Box textAlign="right">
            <Box>{agencyLogoOrName}</Box>
            <Box mt={3}>{downloadedInfoDateAndUser}</Box>
          </Box>
        </Flex>
        <div className="custom-row">
          <div className="custom-column">
            <div className="pdf-flex-row">
              <h1 className="applicant-info__title">{`${helpers.capitalizeFirstLetter(
                firstName,
              )} ${helpers.capitalizeFirstLetter(lastName)}`}</h1>
            </div>
          </div>
        </div>
        <div className="custom-row">
          <div className="custom-column ">
            <span>App: {app_number}</span>
            <br />
            <span>Submitted: {`${submitTime}`}</span>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = propTypes

export default Header
