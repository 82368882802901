import { Box, Flex, TextSpan } from 'app/components/design-system-components'
import Card from 'app/components/design-system-components/Card'
import theme from 'app/match/applicationReportPDF/assets/theme'

type Props = {
  header: string
  value: string
  icon?: any
}

const ActivityCubeListItem = ({ header, value, icon }: Props) => {
  const valueFontSize =
    value?.toString().length < 5
      ? theme.fontSizes.pGigantic100
      : theme.fontSizes.pHuge80

  return (
    <Card
      headerImage={null}
      forcedHeaderStyles={{
        paddingBottom: 0,
        fontSize: theme.fontSizes.pSuperLarge22,
        color: theme.colors.gray500,
        marginBottom: `${theme.space[5]}px`,
      }}
      forcedStyles={{
        flexBasis: '340px',
        height: '260px',
        padding: `${theme.space[7]}px ${theme.space[8]}px`,
        marginRight: `${theme.space[5]}px`,
        lineHeight: 'unset',
      }}
      headerTitle={
        <Flex width="100%" justifyContent="space-between" alignItems="start">
          {header}
          <Box maxWidth="80px">{icon}</Box>
        </Flex>
      }
      showHeaderBorder={false}
      internalComponent={
        <TextSpan as="div" fontSize={valueFontSize}>
          {value}
        </TextSpan>
      }
    />
  )
}

export default ActivityCubeListItem
