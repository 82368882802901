import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Box, Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import * as Form from 'app/components/forms/forms'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import OptionBox from 'app/sm/team_viewing_preference/components/optionBox/component'
import ViewingItem from 'app/sm/team_viewing_preference/components/viewingItem/component'

import 'app/sm/team_viewing_preference/availability/style.scss'
import 'react-datetime/css/react-datetime.css'

const Availability = ({
  availableDuration = [],
  weekDaySelectorArray = [],
  timeWindowAdder = {},
  onButtonClicked,
  onOptionBoxChange,
  onStartDatePickerChange,
  onEndDatePickerChange,
  onWeekdayAddedClick,
  configuredViewings = [],
  isFlexibleTimePicked,
  onStartDateFocus,
  onEndDateFocus,
  showAddLink,
  onAddLinkClicked,
  viewingsBuffers,
  viewingsBufferTime,
  onViewingsBufferTimeChange,
  showViewingScheduleBeforeVacant,
  onShowViewingScheduleBeforeVacantUpdated,
  updateViewingScheduleDropdown,
  componentClassName,
  tenantedPropertiesScheduledViewingLeadTimeOptions,
  tenantedPropertiesScheduledViewingLeadTime = 24,
  updateTenantedPropertiesScheduledViewingLeadTime,
  scheduleDays,
  scheduleDaysOptions,
  updateScheduleDays,
  isActionDisabled = false,
  isAccountLite = false,
}) => {
  return (
    <Box
      mt={8}
      className={`availability-container ${
        !isFlexibleTimePicked && 'disable-content'
      }`}
    >
      <Text as="h3" fontSize={theme.fontSizes[7] + 'px'} mt={8}>
        Availability
      </Text>
      <p
        className={`description ${
          !isFlexibleTimePicked && 'disable-content'
        } mt0`}
      >
        Set the schedule for when attendees can pick a viewing time. Once
        selected, a viewing time will be scheduled and interested enquirers will
        be notified.
      </p>
      <Flex flexDirection="row" alignItems="center">
        <Box mr={theme.space[1]}>
          <span>Schedule in advance:</span>
        </Box>
        <Form.Dropdown
          label="Lead Time"
          value={scheduleDaysOptions.indexOf(scheduleDays)}
          options={scheduleDaysOptions}
          onChange={({ value }) =>
            updateScheduleDays(scheduleDaysOptions[value])
          }
          inputClass="p0"
          extraOptionText="days"
          selectIsDisabled={isActionDisabled}
        />
      </Flex>
      <OptionBox
        availableDuration={availableDuration}
        chosenViewingDuration={timeWindowAdder.duration}
        onOptionBoxChange={onOptionBoxChange}
        label={'Default viewing duration:'}
        selectorStyle={'selectorForViewing'}
        extraStyle={!isFlexibleTimePicked && 'disable-content'}
        disabled={isActionDisabled}
      />
      <div>
        <ReactTooltip id="viewingsBuffer">
          <span>
            Selecting 0 minutes will allow simultaneous times to be scheduled
          </span>
        </ReactTooltip>
      </div>
      <OptionBox
        availableDuration={viewingsBuffers}
        chosenViewingDuration={viewingsBufferTime}
        onOptionBoxChange={onViewingsBufferTimeChange}
        label={'Time between viewings:'}
        selectorStyle={'selectorForViewing'}
        extraStyle={!isFlexibleTimePicked && 'disable-content'}
        optionUnit=" mins"
        toolTip={
          <div>
            <i
              className="icon-help-outline pl10 fs16 gray-light-color ibm"
              data-tooltip-content=""
              data-tooltip-id="viewingsBuffer"
            />
          </div>
        }
        disabled={isActionDisabled}
      />
      <div>
        <ReactTooltip id="scheduleForVacant">
          <span>Based on the availability date of the property</span>
        </ReactTooltip>
      </div>
      <Form.CheckBoxGeneral
        label="Display schedule for vacant properties only"
        checked={showViewingScheduleBeforeVacant}
        onChange={onShowViewingScheduleBeforeVacantUpdated}
        toolTip={
          <div>
            <i
              className="icon-help-outline fs16 gray-light-color ibm"
              data-tooltip-content=""
              data-tooltip-id="scheduleForVacant"
            />
          </div>
        }
        disabled={isActionDisabled}
        showDisabledGrey={isAccountLite}
      />
      {!showViewingScheduleBeforeVacant && (
        <Box mt={`${theme.space[3]}px`}>
          <Box mb={`${theme.space[4]}px`}>
            <span>Lead time for tenanted properties</span>
          </Box>
          <Form.Dropdown
            label="Lead Time"
            value={tenantedPropertiesScheduledViewingLeadTimeOptions.indexOf(
              tenantedPropertiesScheduledViewingLeadTime,
            )}
            options={tenantedPropertiesScheduledViewingLeadTimeOptions}
            onChange={({ value }) =>
              updateTenantedPropertiesScheduledViewingLeadTime(
                tenantedPropertiesScheduledViewingLeadTimeOptions[value],
              )
            }
            inputClass="p0"
            extraOptionText="hours"
            selectIsDisabled={isActionDisabled}
          />
        </Box>
      )}
      <div>
        {configuredViewings.map((viewingItem, index) => {
          return (
            <ViewingItem
              onStartDatePickerChange={onStartDatePickerChange(
                index,
                'StartTime',
              )}
              onEndDatePickerChange={onEndDatePickerChange(index, 'EndTime')}
              weekDaySelectorArray={weekDaySelectorArray}
              onWeekdayAddedClick={onWeekdayAddedClick}
              timeWindowAdder={viewingItem}
              onButtonClicked={onButtonClicked}
              key={index}
              index={index}
              mode={viewingItem.mode}
              extraStyle={!isFlexibleTimePicked ? 'disable-content' : ''}
              updateViewingScheduleDropdown={updateViewingScheduleDropdown}
              disabled={isActionDisabled}
            />
          )
        })}
      </div>
      {!showAddLink && (
        <ViewingItem
          onStartDatePickerChange={onStartDatePickerChange(
            undefined,
            'StartTime',
          )}
          onEndDatePickerChange={onEndDatePickerChange(undefined, 'EndTime')}
          weekDaySelectorArray={weekDaySelectorArray}
          onWeekdayAddedClick={onWeekdayAddedClick}
          timeWindowAdder={timeWindowAdder}
          onButtonClicked={onButtonClicked}
          mode={'create'}
          extraStyle={!isFlexibleTimePicked && 'disable-content'}
          onStartDateFocus={onStartDateFocus}
          onEndDateFocus={onEndDateFocus}
          updateViewingScheduleDropdown={updateViewingScheduleDropdown}
          disabled={isActionDisabled}
        />
      )}
      {showAddLink && !isActionDisabled && (
        <div
          className={`blue-link-style mt30 ${
            !isFlexibleTimePicked && 'disable-content'
          }`}
          onClick={onAddLinkClicked}
        >
          Add
        </div>
      )}
    </Box>
  )
}

export default Availability
