import domainLogo from 'app/assets/referrerLogos/domain-logo.svg'

export const portalStatuses = {
  // color is used as StatusPill component variant
  active: {
    id: 'active',
    label: 'Active',
    color: 'green',
  },
  inactive: {
    id: 'inactive',
    label: 'Inactive',
    color: 'grey',
  },
}

export const portalBrands = {
  domain: {
    id: 'Domain.com.au',
    label: 'Domain',
    manualActivationAllowed: true,
    manualDeactivationAllowed: true,
    logo: domainLogo,
  },
}
