import React from 'react'

export const Stronger = ({
  text = '',
  componentClass = '',
  textClass = '',
  imgSrc,
  imgAlt = 'icon',
}) => {
  return (
    <div className={`mb15 fw700 ${componentClass}`}>
      {imgSrc && <img className="mr5" src={imgSrc} alt={imgAlt} />}
      <span className={`${textClass}`}>{text}</span>
    </div>
  )
}

export const Strong = ({
  text = '',
  componentClass = '',
  textClass = '',
  imgSrc,
  imgAlt = 'icon',
  showBottomMargin = true,
}) => {
  return (
    <div
      className={`${showBottomMargin ? 'mb15' : ''} fw500 ${componentClass}`}
    >
      {imgSrc && <img className="mr5" src={imgSrc} alt={imgAlt} />}
      <span className={`${textClass}`}>{text}</span>
    </div>
  )
}

export const Normal = ({
  text = '',
  componentClass = '',
  textClass = '',
  imgSrc,
  imgAlt = 'icon',
  linkAdded = false,
  linkUrl = '',
  linkText = '',
}) => {
  return (
    <div className={`${componentClass}`}>
      {imgSrc && <img className="mr5" src={imgSrc} alt={imgAlt} />}
      <span className={`${textClass}`}>{text}</span>
      {linkAdded && (
        <a href={linkUrl} target="_blank" rel="noopener noreferrer">
          {linkText}
        </a>
      )}
    </div>
  )
}
