export const steps = {
  GetStarted: 1,
  Preferences: 2,
  LettingDetails: 3,
  Basics: 4,
  OfferDetails: 5,
  Amenities: 6,
  Description: 7,
  Viewings: 8,
  Share: 9,
}
