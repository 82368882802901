import React from 'react'

import Address from 'app/application_revamp/components/Address'
import Applicant from 'app/application_revamp/components/Applicant'
import Household from 'app/application_revamp/components/Household'
import Aside from 'app/application_revamp/components/Layout/Aside'
import Content from 'app/application_revamp/components/Layout/Content'

import 'app/application_revamp/App.scss'

function App() {
  return (
    <div className="App">
      <main className="main-content">
        <Aside>
          <Applicant />
        </Aside>
        <Content>
          <Address />
        </Content>
        <Aside>
          <Household />
        </Aside>
      </main>
    </div>
  )
}

export default App
