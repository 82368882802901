import styled from 'styled-components'

import { Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const StyledSelectorsContainer = styled(Flex)`
  align-items: center;

  @media screen and (max-width: 576px) {
    align-items: start !important;

    :not(.scheduleContainer) {
      flex-direction: column;
    }

    .scheduleContainer {
      flex-direction: row !important;
      margin: 8px 0 !important;
    }

    .dateDropdown {
      width: 100%;
      margin-bottom: ${theme.space[3]}px;
    }
  }
`

export { StyledSelectorsContainer }
