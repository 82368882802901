import React from 'react'

import { apiBaseUrl } from 'config/env'
import moment from 'moment'
import qs from 'qs'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { SearchedPropertyResults } from 'app/agency/enquiries/search_property_list_component'
import {
  ContactPropertiesContainer,
  PropertySearchAndDropDown,
} from 'app/agency/enquiries/team_enquiries_container'
import googleCalendarLogo from 'app/assets/icons/google-calendar-sample.png'
import outlookLogo from 'app/assets/icons/outlook-sample.png'
import { Box, Flex, TextSpan } from 'app/components/design-system-components'
import { Input } from 'app/components/design-system-components/inputs'
import { Text } from 'app/components/design-system-components/typography'
import UpgradeCTA from 'app/components/design-system-components/UpgradeCTA'
import * as Display from 'app/components/display/display'
import * as TextComp from 'app/components/display/text/text_component'
import * as Form from 'app/components/forms/forms'
import {
  rentFrequencyDBKey,
  rentFrequencyDefaultValue,
  rentFrequencyOptions,
} from 'app/constants/application-settings.constants'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { pmsBrands } from 'app/constants/pms.constants'
import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import DomainPortalPage from 'app/pages/DomainPortal'
import KolmeoActivityPage from 'app/pages/KolmeoActivity'
import ManagedAppActivityPage from 'app/pages/ManagedAppActivity'
import PropertyMeActivityPage from 'app/pages/PropertyMeActivity'
import PropertyTreeActivityPage from 'app/pages/PropertyTreeActivity'
import ScreeningIntegrationsPage from 'app/pages/ScreeningIntegrations'
import { FollowsSettings } from 'app/pages/teams/settings/personal/follows'
import VaultREActivityPage from 'app/pages/VaultREActivity'
import { updateViewingPreferences } from 'app/services/http/teams/settings'
import * as snugNotifier from 'app/services/snugNotifier'
import GeneralBottomBtns from 'app/shared_components/general_bottom_btn/component'
import TwoColumnContainer, {
  LeftComponent,
} from 'app/shared_components/layout_component/two_column_layout_components'
import { history } from 'app/shared_components/router'
import SnugUpgradeAccountModal from 'app/shared_components/snug_account_upgrade/modal'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import * as validations from 'app/shared_components/validations'
import Spinner from 'app/sm/common/spinner'
import {
  findChosenTeamBySlug,
  parseDataUtil,
  urlIds,
  urlTo,
} from 'app/sm/helpers'
import PluginsContainer from 'app/sm/plugins/plugins_container'
import PropertyExcludedTimes from 'app/sm/properties/components/excluded_times'
import {
  IdGenerator,
  transformExcludedDates,
  validateDateEntries,
} from 'app/sm/properties/components/excluded_times/helpers'
import {
  syncCalendar,
  unsyncCalendar,
} from 'app/sm/team_viewing_preference/action'
import ApplicationCap from 'app/sm/team_viewing_preference/applicationCap/component'
import { AutoresponderAttachments } from 'app/sm/team_viewing_preference/applications/AutoresponderAttachments'
import {
  applicationLimitPerApplicantDefault,
  applicationLimitPerApplicantOptions,
  applicationReserveBallotsSizeDefault,
  applicationReserveBallotsSizeOptions,
  applicationsAttributesIDs,
  applicationSuccessfulBallotsSizeDefault,
  applicationSuccessfulBallotsSizeOptions,
  payslipsMinWeeksDefault,
  payslipsMinWeeksRequiredOptions,
} from 'app/sm/team_viewing_preference/applications-attributes.constants'
import Availability from 'app/sm/team_viewing_preference/availability/component'
import TeamCalendarSync from 'app/sm/team_viewing_preference/calendarSync/component'
import CheckboxAndSelect from 'app/sm/team_viewing_preference/components/CheckboxAndSelect'
import StatusReport from 'app/sm/team_viewing_preference/components/status_report'
import FeedPreferences from 'app/sm/team_viewing_preference/feed/component'
import Header from 'app/sm/team_viewing_preference/header/component'
import CommunicationPreference from 'app/sm/team_viewing_preference/notifications/component'
import ViewingScheduleOptions from 'app/sm/team_viewing_preference/preference/component'
import QRCheckin from 'app/sm/team_viewing_preference/qrcheckin/component'
import SetupTab from 'app/sm/team_viewing_preference/setup'
import StatesDropdown from 'app/sm/team_viewing_preference/states_dropdown'
import {
  identityCapEnabledNumberOptions,
  payslipsRequiredNumberOptions,
  requireBankStatementOptions,
} from 'app/sm/team_viewing_preference/team_viewing_preference_const'
import TeamMembers from 'app/sm/team_viewing_preference/teamMembers'
import {
  LeadTime24Hours,
  LeadTime48Hours,
  LeadTime72Hours,
} from 'app/sm/team_viewing_preference/teamViewingPreference.constants'
import UserNotAuthorized from 'app/sm/team_viewing_preference/userNotAuthorized'
import UserNotAuthorizedShowTeamMembersList from 'app/sm/team_viewing_preference/userNotAuthorized/showTeamMembersList'
import { viewingsAttributesIDs } from 'app/sm/team_viewing_preference/viewings-attributes.constants'
import * as accountHelpers from 'app/utils/accounts/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import { DATE_WITH_DASH, FULL_HOUR_AM_PM } from 'app/utils/datetime/helpers'
import * as leaseOfferHelpers from 'app/utils/leaseOffer'
import * as roleHelpers from 'app/utils/roles'
import {
  consoleCloudFeatureText,
  covertBoolStringToBool,
} from 'app/utils/strings/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import * as viewingHelpers from 'app/utils/viewings/helpers'
import { SMS_REPORT_HELP_TEXT } from 'app/utils/viewings/helpers'
import * as Features from 'config/features'
import { isTravelTimeOptimisationEnabled } from 'config/features'

const CALENDAR_BASE_URL = `${apiBaseUrl}userprofile/calendar`
const DEFAULT_VIEWING_BUFFER = 60
const BLOCKED_DATE_V1 = 'v1'

const StyledReactTooltip = styled(ReactTooltip)`
  width: ${({ width }) => width};
`

const TabLink = styled.a`
  color: #212025;
  font-size: 16px;
  display: flex;
  width: 100%;
  line-height: 24px;
  padding: 0 7px;
  font-weight: normal;
  margin-bottom: 15px;

  &:hover {
    text-decoration: none;
  }

  &.active {
    background: #bdbcbf;
    color: #ffffff;
    border-radius: 3px;
    text-decoration: none;
  }
`
const TabTitle = styled.span`
  display: flex;
  width: 100%;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 24px;
  color: #212025;
  padding: 0;
`

export const StyledInput = styled.div`
  margin-right: 8px;

  & label {
    width: 240px;
    height: 50px;
    border: 1px solid #808080;
  }
`

const SettingTabContainer = ({
  selectedTab,
  tab,
  active,
  onTabClicked,
  isDisabled = false,
}) => {
  return (
    <TabLink
      onClick={onTabClicked}
      key={tab}
      className={`${selectedTab === tab ? active : ''} ${
        isDisabled ? 'background-color-gray-with-opacity' : ''
      }`}
    >
      {tab}
    </TabLink>
  )
}

export const RawAvailableDurations = [5, 10, 15, 20, 30, 45, 60, 90, 120]

export const AvailableDuration = RawAvailableDurations.map((d) => `${d} mins`)

export const PropertiesProximity = [500, 1000, 2000, 3000]

const registrationLimitNumbers = [1, 2, 3, 4, 5, 10, 15, 20, 25, 30]
const viewingSlotDurationOptions = [3, 5, 10, 15]
const viewingCutoffOptions = viewingHelpers.viewingCutoffOptions

export const ViewingsBuffers = [0, 15, 20, 30, 45, 60]

export const WeekDaySelectorArray = [
  { text: 'M', name: 'Mon' },
  { text: 'T', name: 'Tue' },
  {
    text: 'W',
    name: 'Wed',
  },
  { text: 'T', name: 'Thu' },
  { text: 'F', name: 'Fri' },
  { text: 'S', name: 'Sat' },
  { text: 'S', name: 'Sun' },
]

const weekDaySelectorArrayForEmailReport = [
  { text: 'Mon', name: 'Mon' },
  { text: 'Tue', name: 'Tue' },
  {
    text: 'Wed',
    name: 'Wed',
  },
  { text: 'Thu', name: 'Thu' },
  { text: 'Fri', name: 'Fri' },
  { text: 'Sat', name: 'Sat' },
  { text: 'Sun', name: 'Sun' },
]

const ViewingSchedule7Days = 7
const ViewingSchedule10Days = 10
const ViewingSchedule14Days = 14

const applicationCapNumbers = [1, 2, 3, 4, 5, 10, 15, 20]

export const DefaultViewingDuration = '15 mins'

const GOOGLE_CALENDAR = 'Google Calendar'
const OUTLOOK_CANLENDAR = 'Outlook Calendar'
const GOOGLE_CALENDAR_ABBR = 'google'
const OUTLOOK_CALENDAR_ABBR = 'outlook'

const calendarTypeWithGuidAndName = [
  {
    guid: 0,
    name: GOOGLE_CALENDAR,
  },
  {
    guid: 1,
    name: OUTLOOK_CANLENDAR,
  },
]

export const AtLeastOneDaySelected = (daysList) =>
  WeekDaySelectorArray.filter((weekday) => daysList[weekday.name] === true)
    .length === 0
const TabPersonalNotifications = 'Notifications'
const FollowingTab = 'Following'
const TabViewings = 'Viewings'
const SELECTED_QRCHECKIN_TAB = 'QR Check-in'
const AttributeKeysAll =
  'viewings,application,personalnotifications,integrations,feed,bundle'
const TabCalendarSync = 'Calendar Sync'
const typeExcludedProperties = 'excludedProperties'
const searchPropertyStatus = 3

const CONSOLE_CLOUD_TAB = consoleCloudFeatureText
const PROPERTY_TREE_TAB = pmsBrands.propertyTree.label
const PROPERTY_ME_TAB = pmsBrands.propertyMe.label
const VAULT_RE_TAB = pmsBrands.vaultRE.label
const KOLMEO_TAB = pmsBrands.kolmeo.label
const MANAGED_APP_TAB = pmsBrands.managedApp.label

const SELECTED_STATUS_TAB = 'Status'
const SELECTED_USERS_TAB = 'Members'
const SELECTED_APPLICATIONS_TAB = 'Applications'
const PROPERTY_FEED_TAB = 'Property Feed'
const SETUP_TAB = 'Setup'
const SELECTED_VIEWINGS_TAB = 'Viewings'

const PORTALS_TAB = 'Portals'
const SCREENING_TAB = 'Screening'

const emptyAdder = {
  StartTime: undefined,
  EndTime: undefined,
  Mon: false,
  Tue: false,
  Wed: false,
  Thu: false,
  Fri: false,
  Sat: false,
  Sun: false,
  duration: '15 mins',
  ViewingScheduleZone: 0,
}

const AdminAccessWrapper = ({ isAdmin, children }) =>
  isAdmin ? children : <UserNotAuthorized />

const DisplayedTabHeader = ({ header, ...props }) => (
  <TextSpan
    as="div"
    fontWeight={theme.fontWeights.h1}
    fontSize={theme.fontSizes.h1}
    mb={6}
    {...props}
  >
    {header}
  </TextSpan>
)

class TeamViewingPreferenceContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tableSpinner: false,
      apiError: '',
      isFixedTimePicked: true,
      isFlexibleTimePicked: false,
      optimiseTravelTime: false,
      viewingReportNoLostRent: false,
      viewingReportNoActivity: false,
      timeWindows: [],
      timeWindowAdder: {
        StartTime: undefined,
        EndTime: undefined,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
        Sun: false,
        duration: '15 mins',
        ViewingScheduleZone: 0,
      },
      configuredViewings: [],
      unsavedChanges: false,
      showAddLink: true,
      blockedDates: [],
      currentBlockedDates: [], // hold initial blocked dates to avoid infinite rendering
      viewingsBufferTime: 60,
      showViewingScheduleBeforeVacant: true,
      viewingCap: 0,
      defaultSelectedViewingDurationEnabled: false,
      defaultSelectedViewingDuration: 15,
      viewingCapEnabled: false,
      viewingSlotEnabled: false,
      viewingCapOption: 0,
      applicationCapEnabled: false,
      payslipsRequiredDurationEnabled: false,
      identityEnabled: false,
      applicationCapOption: 0,
      selectedTab: TabPersonalNotifications,
      enquiries_auto_response_enabled: false,
      onlist_send_notifications_enabled: false,
      viewings_new: false,
      viewings_updates: false,
      applications_new: false,
      applications_updates: false,
      applications_approved: false,
      daily_viewings_report: false,
      send_daily_digest_notification: false,
      weekly_digest_report: false,
      daily_keys_report: false,
      disableConfirmBtn: false,
      marketReportEnabled: false,
      hideResult: false,
      searchText: '',
      propertySearchResults: {},
      selectedPropertyIdError: '',
      searchSpinner: false,
      newListingExcludedProperties: [],
      excludedProperties: [],
      viewingCutoffEnabled: false,
      viewingCutoffTime: viewingCutoffOptions[0].guid,
      viewingRequestDisabled: false,
      statusData: [],
      teamMembersList: [],
      teamMemberSpinner: false,
      statusSpinner: false,
      currentTeam: {},
      agencyProfile: {
        agencyName: '',
        agencyAddress: '',
        agencyContactNumber: '',
        agencyEmail: '',
        brandingBannerURL: '',
        brandingLogoURL: '',
      },
      isCalendarSubmitted: false,
      activatingCalendarStage: false,
      successCalendarStage: false,
      calendarInfo: {
        CreatedAt: '',
        DeletedAt: '',
        ID: '',
        UpdatedAt: '',
        guidID: '',
        platform: '',
      },
      calendarTotalList: [],
      currentCalendarType: 0,
      showUpgradeAccountModal: false,
      sendViewingReportSMS: false,
      sendViewingReportEmail: false,
      sendNewViewingNoticeToOwner: false,
      viewingReportAvailability: {},
      payslipsRequire: false,
      isUserAdmin: false,
      requireAddressHistory: false,
      studentAttachmentsRequired: false,
      isAccountLite: true,
      isAccountTypeProductivityOrHigher: false,
      tenantedPropertiesScheduledViewingLeadTime: 24,
      tenantedPropertiesScheduledViewingLeadTimeOptions: [
        LeadTime24Hours,
        LeadTime48Hours,
        LeadTime72Hours,
      ],
      scheduleDays: ViewingSchedule10Days,
      viewingBookNearbyProperties: false,
      customiseViewingReportSelected: false,
      viewingQRAutoCheckinEnabled: false,
      viewingQRCheckinEnabled: true,
      filterPropertiesOnListingAgentEmailEnabled: false,
      filterPropertiesOnListingAgentEmail: '',
      filterPropertiesOnListingAgentEmailErr: '',
    }
    this.onIdentityCapCheckboxClicked =
      this.onIdentityCapCheckboxClicked.bind(this)
    this.onRequestViewingCheckboxClicked =
      this.onRequestViewingCheckboxClicked.bind(this)
  }

  componentDidMount() {
    const currentPathname = window.location.pathname
    if (currentPathname.includes('calendarsync')) {
      this.setState({
        selectedTab: TabCalendarSync,
      })
      const curQueries = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      })
      const platform = curQueries.platform
      if (curQueries.success === 'true') {
        snugNotifier.success(`Successfully connected ${platform} calendar`)
      } else if (curQueries.success === 'false') {
        const message =
          curQueries.message || `Failed to connect ${platform} calendar`
        snugNotifier.error(message)
      }
    }

    if (currentPathname.includes('following')) {
      this.setState({
        selectedTab: FollowingTab,
      })
    }

    if (currentPathname.includes('propertyme')) {
      this.setState({
        selectedTab: PROPERTY_ME_TAB,
      })
    }

    if (currentPathname.includes('setup')) {
      this.setState({
        selectedTab: SETUP_TAB,
      })
    }

    if (currentPathname.includes('portals')) {
      this.setState({
        selectedTab: PORTALS_TAB,
      })
    }

    if (currentPathname.includes('notifications')) {
      this.setState({
        selectedTab: TabPersonalNotifications,
      })
    }
    if (currentPathname.includes('applications')) {
      this.setState({
        selectedTab: SELECTED_APPLICATIONS_TAB,
      })
    }
    if (currentPathname.includes('screening')) {
      this.setState({
        selectedTab: SCREENING_TAB,
      })
    }

    if (currentPathname.includes('members')) {
      const curQueries = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      })
      const {
        firstName = '',
        lastName = '',
        email = '',
        mobile,
        agentUtilityProviderUserID,
      } = curQueries
      const inviteMemberModal = {
        isShown: !!curQueries.invite,
        inviteData: {
          firstName,
          lastName,
          email,
          mobile,
          agentUtilityProviderUserID,
        },
      }
      this.setState({
        selectedTab: SELECTED_USERS_TAB,
        inviteMemberModal: inviteMemberModal,
      })
    }

    const { teams, changeTeam } = this.props
    if (teams.length !== 0) {
      const { teamSlug = '' } = this.props.match.params
      const chosenTeam = findChosenTeamBySlug(teams, teamSlug)
      changeTeam(chosenTeam)
      const { accountType } = chosenTeam
      this.setState({
        isAccountLite: accountType === accountHelpers.ACCOUNT_TYPE_LITE,
        isAccountTypeProductivityOrHigher:
          accountType >= accountHelpers.ACCOUNT_TYPE_PLUS_MAR21,
      })
      this.fetchSetting()
    }
    const { currentAgency } = this.props
    this.checkIfUserIsAdmin(currentAgency)
  }

  componentDidUpdate(prevProps, prevState) {
    const { teams } = this.props
    const { teamSlug = '' } = this.props.match.params
    const chosenTeam = findChosenTeamBySlug(teams, teamSlug)
    const { guid = '' } = chosenTeam || {}
    if (!!chosenTeam && prevState.currentTeam?.guid !== guid) {
      this.setState({
        currentTeam: chosenTeam,
      })
    }
    if (prevProps.teams.length === 0 && teams.length !== 0) {
      this.fetchSetting()
      if (this.state.selectedTab === TabCalendarSync) {
        this.fetchCalendarList(guid)
      }
    }
    if (
      teams.length !== 0 &&
      prevState.selectedTab !== this.state.selectedTab &&
      this.state.selectedTab === TabCalendarSync
    ) {
      this.fetchCalendarList(guid)
    }
    if (
      prevState.selectedTab !== this.state.selectedTab &&
      this.state.selectedTab === SELECTED_STATUS_TAB
    ) {
      this.fetchAgencyReport(guid)
    }
    if (
      prevState.selectedTab !== this.state.selectedTab &&
      this.state.selectedTab === SELECTED_QRCHECKIN_TAB
    ) {
      //display:none; to support widget in print layout
      const widget = document.getElementById('launcher')
      const footer = document.getElementsByClassName('footer')
      if (widget !== null) {
        widget.classList.add('pdf-hide')
      }
      if (footer !== null) {
        const footer = document.getElementsByClassName('footer')
        footer[0].classList.add('pdf-hide')
      }
      this.callFetchAgencyPublicProperties(chosenTeam.slug)
    }
    if (prevProps.currentAgency !== this.props.currentAgency) {
      const { currentAgency } = this.props
      this.checkIfUserIsAdmin(currentAgency)
    }
  }

  checkIfUserIsAdmin = (currentAgency) => {
    if (currentAgency) {
      const { roles } = currentAgency
      const isTeamAdmin = roles.find(
        (role) => role.roleID === roleHelpers.AGENCY_USER_ADMIN,
      )
      this.setState({
        isUserAdmin: isTeamAdmin,
      })
    }
  }

  onActivateCaldendarSync = () => {
    const { agencyGUID, currentCalendarType } = this.state
    const calendarPlatform =
      parseInt(currentCalendarType) === 0
        ? GOOGLE_CALENDAR_ABBR
        : OUTLOOK_CALENDAR_ABBR
    if (agencyGUID) {
      window.open(
        `${CALENDAR_BASE_URL}/${agencyGUID}/${calendarPlatform}`,
        '_self',
      )
    }
  }

  onAddLinkClicked = () => {
    this.setState({ showAddLink: false })
  }

  onAddSearchedProperty = (category) => {
    return (guidID) => {
      const { propertySearchResults } = this.state

      if (
        propertySearchResults === undefined ||
        propertySearchResults.data === undefined
      ) {
        return
      }

      const data = propertySearchResults.data

      const selectedProperty = data.find(
        (propertyInfo) => propertyInfo.property.guidID === guidID,
      ).property

      this.state[category].push({
        ...selectedProperty,
        excludedPropertyDetails: {
          excludedPropertyDates: [],
        },
      })

      this.setState({
        [category]: this.state[category],
      })
    }
  }

  onApplicationCapCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    if (checked) {
      this.setState({
        applicationCapEnabled: checked,
      })
    } else {
      this.setState({
        applicationCapEnabled: checked,
      })
    }
  }

  onBDMEmailValueChange(e) {
    this.setState({
      bdmLeadEmail: e.target.value,
    })

    const [_, validateEmailErr] = this.validateEmail(e.target.value, 'bdm lead')
    this.setState({
      validateEmailErr,
    })
  }

  onBDMStatesChange(states) {
    this.setState({
      bdmLeadStates: states,
    })
  }

  onOperationEmailValueChange({ value, error, ...rest }) {
    this.setState({
      operationsEmail: value,
    })

    this.setState({
      validateOperationsEmailErr: error,
    })
  }

  onBackButtonClicked() {
    history.goBack()
  }

  onFeedFilterEmailValueChange = (e) => {
    this.setState({
      filterPropertiesOnListingAgentEmail: e.target.value,
    })

    const [_, validateEmailErr] = this.validateEmail(
      e.target.value,
      'feed filter',
    )
    this.setState({
      filterPropertiesOnListingAgentEmailErr: validateEmailErr,
    })
  }

  onButtonClicked = (btnText, index) => {
    const { timeWindowAdder } = this.state
    const emptyTimeWindowAdder = Object.assign({}, timeWindowAdder, {
      StartTime: undefined,
      EndTime: undefined,
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
      Sun: false,
      ViewingScheduleZone: 0,
    })
    if (btnText === 'save' && index === undefined) {
      if (this.viewingItemValidation(timeWindowAdder)) {
        const durationLength = parseInt(timeWindowAdder.duration)
        const newViewingItem = Object.assign(
          {},
          timeWindowAdder,
          { mode: 'view' },
          { duration: durationLength },
        )
        const updatedViewings =
          this.state.configuredViewings.concat(newViewingItem)
        this.setState({
          configuredViewings: updatedViewings,
          timeWindowAdder: emptyTimeWindowAdder,
          reservedViewing: this.state.reservedViewing.concat(
            Object.assign(
              {},
              timeWindowAdder,
              { mode: 'view' },
              { duration: durationLength },
            ),
          ),
          unsavedChanges: true,
          showAddLink: true,
        })
      }
    }

    if (btnText === 'cancel' && index === undefined) {
      this.setState({
        timeWindowAdder: emptyTimeWindowAdder,
        showAddLink: true,
      })
    }

    if (btnText === 'edit') {
      const { configuredViewings } = this.state
      configuredViewings[index]['mode'] = 'edit'
      this.setState({
        configuredViewings: configuredViewings,
        unsavedChanges: true,
      })
    }

    if (
      btnText === 'save' &&
      index !== undefined &&
      this.viewingItemValidation(this.state.configuredViewings[index])
    ) {
      const { configuredViewings } = this.state
      configuredViewings[index]['mode'] = 'view'
      this.setState({
        configuredViewings: configuredViewings,
        unsavedChanges: true,
      })
    }
    if (btnText === 'delete' && index !== undefined) {
      const { configuredViewings, reservedViewing } = this.state
      configuredViewings.splice(index, 1)
      reservedViewing.splice(index, 1)
      this.setState({
        configuredViewings: configuredViewings,
        reservedViewing: reservedViewing,
        unsavedChanges: true,
      })
    }
    if (btnText === 'cancel' && index !== undefined) {
      const { reservedViewing, configuredViewings } = this.state
      configuredViewings[index] = Object.assign({}, reservedViewing[index])
      this.setState({
        configuredViewings,
      })
    }
  }

  onCalendarTypeChange = () => {
    return (data) => {
      const { calendarTotalList } = this.state
      const { value } = data
      const calendarPlatform =
        parseInt(value) === 0 ? GOOGLE_CALENDAR_ABBR : OUTLOOK_CALENDAR_ABBR
      const currentCalendarInfo = calendarTotalList.filter(
        (calendar) => calendar.platform === calendarPlatform,
      )
      if (currentCalendarInfo.length > 0) {
        this.setState({
          currentCalendarType: value,
          calendarInfo: currentCalendarInfo[0],
          isCalendarSubmitted: true,
          successCalendarStage: true,
        })
      } else {
        this.setState({
          currentCalendarType: value,
          calendarInfo: {
            CreatedAt: '',
            DeletedAt: '',
            ID: '',
            UpdatedAt: '',
            guidID: '',
            platform: '',
          },
          isCalendarSubmitted: false,
          successCalendarStage: false,
        })
      }
    }
  }

  onClickDisabledLiteAccountOption = () => {
    const { showUpgradeAccountModal } = this.state
    this.setState({
      showUpgradeAccountModal: !showUpgradeAccountModal,
    })
  }

  onConfirmButtonClicked = () => {
    const {
      isFixedTimePicked,
      isFlexibleTimePicked,
      configuredViewings,
      blockedDates = [],
      viewingsBufferTime,
      showViewingScheduleBeforeVacant,
      selectedTab,
      error,
      excludedProperties = [],
      newListingExcludedProperties,
      viewingReportAvailability,
      onlyOneViewingPerDay,
      tenantedPropertiesScheduledViewingLeadTime,
      scheduleDays,
    } = this.state
    this.setState({
      disableConfirmBtn: true,
    })
    const { teams } = this.props
    const { teamSlug = '' } = this.props.match.params
    const chosenTeam = findChosenTeamBySlug(teams, teamSlug)
    const { guid = '' } = chosenTeam || {}
    let tenantedPropertiesScheduledViewingLeadTimeCopy =
      tenantedPropertiesScheduledViewingLeadTime

    if (
      showViewingScheduleBeforeVacant &&
      ![LeadTime24Hours, LeadTime48Hours, LeadTime72Hours].includes(
        tenantedPropertiesScheduledViewingLeadTime,
      )
    ) {
      if (tenantedPropertiesScheduledViewingLeadTime === 0) {
        tenantedPropertiesScheduledViewingLeadTimeCopy = LeadTime24Hours
      } else {
        snugNotifier.error('Please select lead time for tenanted properties')
        this.setState({
          disableConfirmBtn: false,
        })
        return
      }
    }

    if (error !== '') {
      snugNotifier.error(error)
      this.setState({
        disableConfirmBtn: false,
      })
      return
    }

    let validationErrors = validateDateEntries(
      blockedDates.filter((date) => !date.GUIDID),
    )
    if (validationErrors.length) {
      snugNotifier.error(validationErrors[0])
      this.setState({
        disableConfirmBtn: false,
      })
      return
    }

    let excludedPropertiesPayload = excludedProperties
      .concat(newListingExcludedProperties)
      .map((property) => {
        return {
          PropertyGUID: property.guidID,
          DateEntries: property.dateEntries,
          ExcludedPropertyGUID: property.excludedPropertyDetails?.GUIDID,
        }
      })

    for (const property of excludedPropertiesPayload) {
      let validationErrors = validateDateEntries(
        property.DateEntries?.filter((entry) => !entry.guid),
      )
      if (validationErrors.length) {
        snugNotifier.error(validationErrors[0])
        this.setState({
          disableConfirmBtn: false,
        })
        return
      }
    }

    const viewingInfo = Object.assign(
      {},
      { SpecifiedTime: isFixedTimePicked },
      { PickTime: isFlexibleTimePicked },
      { RequestTime: false },
      { Availabilities: isFlexibleTimePicked ? configuredViewings : [] },
      {
        BlockedDates: blockedDates.map((date) => ({
          ...date,
          GUID: date.GUIDID,
        })),
      },
      { ConcurrentViewingsEnabled: parseInt(viewingsBufferTime) === 0 },
      { ViewingsBuffer: parseInt(viewingsBufferTime) },
      { ShowViewingScheduleBeforeVacant: showViewingScheduleBeforeVacant },
      { scheduleDays: scheduleDays || ViewingSchedule10Days },
      {
        tenantedPropertiesScheduledViewingLeadTime:
          tenantedPropertiesScheduledViewingLeadTimeCopy,
      },
      {
        ExcludedProperties: excludedPropertiesPayload,
      },
      {
        OnlyOneViewingPerDay: onlyOneViewingPerDay,
      },
      {
        ViewingReportAvailability: viewingReportAvailability,
      },
    )
    if (
      configuredViewings.find((availability) => availability.mode === 'edit')
    ) {
      snugNotifier.error('There is updated viewing schedule not saved yet')
      this.setState({
        disableConfirmBtn: false,
      })
      return
    }
    if (!configuredViewings.length && isFlexibleTimePicked) {
      snugNotifier.error(
        'Please add an availability schedule or disable Availability window',
      )
      this.setState({
        disableConfirmBtn: false,
      })
      return
    }

    if (
      selectedTab === TabPersonalNotifications ||
      selectedTab === PROPERTY_FEED_TAB
    ) {
      this.onUpdateSettings()
        .then(() => {
          this.setState({
            disableConfirmBtn: false,
          })
          snugNotifier.success('Settings have been saved successfully.')
        })
        .catch((error) => {
          this.setState({
            disableConfirmBtn: false,
          })
          snugNotifier.error(error)
        })
    } else if (
      selectedTab === TabViewings ||
      selectedTab === SELECTED_APPLICATIONS_TAB ||
      selectedTab === SELECTED_QRCHECKIN_TAB
    ) {
      this.onUpdateSettings()
        .then(() => {
          updateViewingPreferences(guid, viewingInfo)
            .then((response) => {
              this.hydrateViewingPreferences(response)

              snugNotifier.success('Settings have been saved successfully.')
              this.setState({
                unsavedChanges: false,
                disableConfirmBtn: false,
                error: '',
              })
            })
            .catch((error) => {
              snugNotifier.error(error?.message || 'Unable to update settings')
            })
        })
        .catch((error) => {
          this.setState({
            disableConfirmBtn: false,
          })
          snugNotifier.error(error)
        })
    }
  }

  onDeactiveCaldendarSync = () => {
    const { calendarInfo } = this.state
    this.setState({
      activatingCalendarStage: true,
      successCalendarStage: false,
    })
    const calendarPlatform =
      parseInt(this.state.currentCalendarType) === 0
        ? GOOGLE_CALENDAR_ABBR
        : OUTLOOK_CALENDAR_ABBR
    unsyncCalendar(calendarPlatform, calendarInfo.guidID)
      .then(() => {
        this.setState((prevState) => ({
          isCalendarSubmitted: false,
          activatingCalendarStage: false,
          successCalendarStage: false,
          calendarTotalList: prevState.calendarTotalList.filter(
            (calendar) => calendar.platform !== calendarPlatform,
          ),
        }))
        snugNotifier.success(
          `Successfully disconnected ${calendarPlatform} calendar`,
        )
      })
      .catch((error) => {
        this.setState({
          successCalendarStage: false,
          activatingCalendarStage: false,
        })
        snugNotifier.error(error)
      })
  }

  onDisableOccupantsCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      disableOccupantsEnabled: checked,
    })
  }

  onEnableBDMLeadCheckboxClicked = (event, key) => {
    const { checked } = event.target || {}
    this.setState({
      [key]: checked,
    })
  }

  onEnableInternalShortlistingClicked = (event) => {
    this.setState({
      enableInternalShortlisting: event.target.checked,
    })
  }

  handleRentFrequencyChange = ({ value }) => {
    this.setState({
      rentFrequency: value,
    })
  }
  onDisableRemainingBalanceCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      disableRemainingBalance: checked,
    })
  }

  onRoundPaymentsDownNearestDollarClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      roundPaymentsDownNearestDollar: checked,
    })
  }
  onOperationEmailAsLeasingContactClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      operationEmailAsLeasingContact: checked,
    })
  }

  onAlwaysCopyOperationEmailClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      alwaysCopyOperationEmail: checked,
    })
  }

  onEnablePreApplyCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      enablePreApply: checked,
    })
  }

  onEndDateFocus = () => {
    const { timeWindowAdder } = this.state
    const { EndTime } = timeWindowAdder
    if (!EndTime) {
      this.setState({
        timeWindowAdder: Object.assign({}, this.state.timeWindowAdder, {
          EndTime: '09:00 AM',
        }),
      })
    }
  }

  onStartDateAndEndDatePickerChange = (index, field) => {
    return (time) => {
      if (time && index === undefined) {
        //TODO: validate and display error if exists. May use strict mode in moment() for such validation
        const formatTime = moment(time, 'HH:mm').format('HH:mm')
        this.setState({
          timeWindowAdder: Object.assign(this.state.timeWindowAdder, {
            [field]: formatTime,
          }),
          unsavedChanges: true,
        })
      } else if (time && index !== undefined) {
        //TODO: validate and display error if exists may use strict mode in moment() for such validation
        const formatTime = moment(time, 'HH:mm').format('HH:mm')
        const { configuredViewings } = this.state
        configuredViewings[index][field] = formatTime
        this.setState({
          configuredViewings: configuredViewings,
          unsavedChanges: true,
        })
      }
    }
  }

  onFixedTimeBoxChanged = () => {
    this.setState({
      isFixedTimePicked: !this.state.isFixedTimePicked,
      unsavedChanges: true,
    })
  }

  onFlexibleTimeBoxChanged = () => {
    this.setState((state) => {
      const isFlexibleTimePicked = !state.isFlexibleTimePicked
      return {
        isFlexibleTimePicked,
        unsavedChanges: true,
        searchText: '',
        excludedProperties: [],
        propertySearchResults: {},
        ...(!isFlexibleTimePicked ? { onlyOneViewingPerDay: false } : {}),
      }
    })
  }

  onOnlyOneViewingPerDayChanged = (target) => {
    const { value } = target || {}
    this.setState({
      onlyOneViewingPerDay: value,
      unsavedChanges: true,
    })
  }

  onViewingBookNearbyPropertiesChanged = () => {
    this.setState((state) => {
      const viewingBookNearbyPropertiesSelected =
        !state.viewingBookNearbyPropertiesSelected
      return {
        viewingBookNearbyPropertiesSelected,
        unsavedChanges: true,
      }
    })
  }

  onFormUpdate = (field) => {
    return (data) => {
      this.setState({
        [field]: Number(data.value),
      })
    }
  }

  onIdentityCapCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      identityCapEnabled: checked,
    })
  }

  onCheckBoxChanged = (field) => {
    return (event) => {
      this.setState({
        [field]: event.target.checked,
        errors: {
          ...this.state.errors,
          [field]: event.error,
        },
      })
    }
  }

  onOptionBoxChange = (event) => {
    this.setState({
      timeWindowAdder: Object.assign(this.state.timeWindowAdder, {
        duration: event.target.value,
      }),
      configuredViewings: this.state.configuredViewings.map((viewing) =>
        Object.assign({}, viewing, { Duration: parseInt(event.target.value) }),
      ),
      unsavedChanges: true,
    })
  }

  onRemoveProperty = (category) => {
    return (guidID) => {
      const { excludedProperties, newListingExcludedProperties } = this.state
      if (category === typeExcludedProperties) {
        if (excludedProperties.find((property) => property.guidID === guidID)) {
          this.setState({
            excludedProperties: excludedProperties.filter(
              (property) => property.guidID !== guidID,
            ),
          })
        } else if (
          newListingExcludedProperties.find(
            (property) => property.guidID === guidID,
          )
        ) {
          this.setState({
            newListingExcludedProperties: newListingExcludedProperties.filter(
              (property) => property.guidID !== guidID,
            ),
          })
        }
      }
    }
  }

  onRequestViewingCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      viewingRequestDisabled: checked,
    })
  }

  onRequireBankStatementCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      bankStatementRequire: checked,
    })
  }

  onRequireEnhancedDisclosuresClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      enableEnhancedDisclosure: checked,
    })
  }

  onRequireMostRecent2BankStatements = (event) => {
    const { checked } = event.target || {}
    this.setState({
      bankStatementRequireRecentTwo: checked,
    })
  }

  onRequireCurrentEmploymentOnly = (event) => {
    const { checked } = event.target || {}
    this.setState({
      requireCurrentEmploymentOnly: checked,
    })
  }

  onRequirePayslipsCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    let statePayload = {
      payslipsRequire: checked,
    }
    if (checked) {
      statePayload['payslipsRequiredDurationEnabled'] = false
    }
    this.setState(statePayload)
  }

  onRequirePayslipsDurationCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    let statePayload = {
      payslipsRequiredDurationEnabled: checked,
    }
    if (checked) {
      statePayload['payslipsRequire'] = false
    }
    this.setState(statePayload)
  }

  onRequireAddressHistoryCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      requireAddressHistory: checked,
    })
  }

  onRequireViewingCheckinCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      viewingCheckinRequire: checked,
    })
  }

  onRequireStudentAttachmentsClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      studentAttachmentsRequired: checked,
    })
  }

  onFilterIncompleteHouseholdApplications = (event) => {
    const { checked } = event.target || {}
    this.setState((state) => ({
      state,
      filterIncompleteHouseholdApplications: checked,
    }))
  }

  onSearchChange = (event) => {
    const teamSlug = this.props.match.params.teamSlug || ''
    const currentTeam = this.props.teams.find(
      (option) => option.slug === teamSlug,
    )

    const { guid: agencyGUID } = currentTeam
    const { value } = event.target
    clearInterval(this.propertySearch)

    this.setState({ searchText: value, searchSpinner: true }, () => {
      const { searchText = '' } = this.state
      this.propertySearch = setTimeout(
        () =>
          this.callFetchTeamProperties(agencyGUID, {
            searchText,
            status: searchPropertyStatus,
          })
            .then(({ properties = {} }) => {
              this.setState({
                propertySearchResults: properties,
                propertyCount: properties.response_metadata.total,
                searchSpinner: false,
              })
            })
            .catch((apiError) => {
              this.setState({
                apiError,
                searchSpinner: false,
              })
            }),
        500,
      )
    })
  }

  onShowViewingScheduleBeforeVacantUpdated = () => {
    this.setState((prevState) => ({
      showViewingScheduleBeforeVacant:
        !prevState.showViewingScheduleBeforeVacant,
    }))
  }

  updateScheduleDaysOption = (val) => {
    this.setState({
      scheduleDays: val,
    })
  }

  onStartDateFocus = () => {
    const { timeWindowAdder } = this.state
    const { StartTime } = timeWindowAdder
    if (!StartTime) {
      this.setState({
        timeWindowAdder: Object.assign({}, this.state.timeWindowAdder, {
          StartTime: '09:00 AM',
        }),
      })
    }
  }

  onTabClicked(tab) {
    const { teamSlug = '' } = this.props.match.params
    if (tab === TabCalendarSync) {
      history.push(urlTo('teamCalendarSync', { teamSlug }))
    } else if (tab === FollowingTab) {
      history.push(urlTo(urlIds.teams.settings.following, { teamSlug }))
    }

    this.setState({
      selectedTab: tab,
    })
  }

  onUpdateApplicationNotificationEnabled = (event, field) => {
    const { checked } = event.target || {}
    this.setState({
      [field]: checked,
    })
  }

  validateEmail = (email, name) => {
    if (!email.length) {
      return [false, `Please enter ${name} email`]
    }

    const validateEmailErr = validations.validateEmailOptional(
      email.trim(),
      `Please enter valid ${name} email`,
    )

    if (validateEmailErr.length > 0) {
      return [false, `Please enter valid ${name} email`]
    }

    return [true, '']
  }

  onUpdateSettings = () => {
    const {
      selectedTab,
      viewingCapEnabled,
      identityCapEnabled,
      identityCapEnabledOptions,
      defaultSelectedViewingDurationEnabled = false,
      defaultSelectedViewingDuration = '',
      viewingCapOption = 0,
      viewingSlotOption = 0,
      viewingSlotEnabled,
      applicationCapEnabled,
      applicationCapOption = 0,
      applicationLimitPerApplicantEnabled,
      applicationLimitPerApplicant = applicationLimitPerApplicantOptions.indexOf(
        applicationLimitPerApplicantDefault,
      ),
      payslipsRequiredDurationEnabled,
      payslipsMinDuration = applicationLimitPerApplicantOptions.indexOf(
        payslipsMinWeeksDefault,
      ),
      enquiries_auto_response_enabled,
      onlist_send_notifications_enabled,
      viewings_new,
      viewings_updates,
      applications_new,
      applications_updates,
      applications_approved,
      daily_viewings_report,
      send_daily_digest_notification,
      weekly_digest_report,
      daily_keys_report,
      viewingCutoffEnabled,
      viewingCutoffTime = 0,
      viewingRegistrantNotificationEnabled,
      applicationUpdatedNotificationEnabled,
      newlyReceivedApplicationNotificationEnabled,
      viewingRequestDisabled,
      enablePreApply,
      disableOccupantsEnabled,
      bankStatementRequire,
      enableEnhancedDisclosure,
      payslipsRequire,
      payslipsRequireNumber,
      requireAddressHistory,
      enableNewManagementBDMLead,
      enableRentvestorBDMLead,
      bdmLeadEmail,
      bdmLeadStates,
      enableInternalShortlisting,
      studentAttachmentsRequired,
      filterIncompleteHouseholdApplications,
      viewingCheckinRequire,
      sendViewingReportSMS,
      sendViewingReportEmail,
      sendNewViewingNoticeToOwner,
      viewingReportAvailability = {},
      rentFrequency,
      waterInclusion = 0,
      firstPaymentDefault = 1,
      leaseLengthDefault = 1,
      operationsEmail = '',
      validateOperationsEmailErr = '',
      disableRemainingBalance = false,
      roundPaymentsDownNearestDollar = false,
      alwaysCopyOperationEmail = false,
      operationEmailAsLeasingContact = false,
      autoArchiveProperty,
      autoAttendeesNoShow,
      optimiseTravelTime,
      viewingBookNearbyPropertiesSelected,
      viewingBookNearbyPropertiesProximityOption = 0,
      viewingBookNearbyPropertiesProximity,
      viewingReportNoActivity,
      viewingReportNoLostRent,
      viewingQRAutoCheckinEnabled,
      viewingQRCheckinEnabled,
      allowInternationalMobiles,
      enableApplicationBallots,
      idDocsNotRequested,
      autoArchiveLeasedProperties = false,
      filterPropertiesOnListingAgentEmailEnabled = false,
      filterPropertiesOnListingAgentEmail = '',
      applicationSuccessfulBallotsSize = applicationSuccessfulBallotsSizeOptions.indexOf(
        applicationSuccessfulBallotsSizeDefault,
      ),
      applicationReserveBallotsSize = applicationReserveBallotsSizeOptions.indexOf(
        applicationReserveBallotsSizeDefault,
      ),
      applicationNotifyListingAgents,
      viewingNotifyListingAgents = false,
      shareAppWithOwnerBgcApprovalEnabled = false,
      excludeRequestReasonForMoving = false,
      excludeRequestVehicleRegistration = false,
      bankStatementRequireTypeOptions,
      bankStatementRequireRecentTwo,
      shareAppWithOwnerBgcServiceFee = '0',
      requireCurrentEmploymentOnly = false,
    } = this.state

    const validViewingReportAvailability = (viewingReportAvailability) => {
      if (viewingReportAvailability === null) {
        return false
      }

      if (Object.keys(viewingReportAvailability).length === 0) {
        return false
      }

      let scheduleHasOneTrue = false
      weekDaySelectorArrayForEmailReport.forEach((item) => {
        if (!!viewingReportAvailability[item.name]) {
          scheduleHasOneTrue = true
        }
      })

      return scheduleHasOneTrue
    }

    if (
      sendViewingReportEmail &&
      !validViewingReportAvailability(viewingReportAvailability)
    ) {
      return Promise.reject('Please select day to send viewing email report')
    }

    if (operationsEmail && validateOperationsEmailErr) {
      return Promise.reject(`Invalid operation email`)
    }

    if (enableNewManagementBDMLead || enableRentvestorBDMLead) {
      const [valid, validateEmailErr] = this.validateEmail(
        bdmLeadEmail,
        'bdm lead',
      )

      if (!valid) {
        this.setState({
          validateEmailErr,
        })
        return Promise.reject(validateEmailErr)
      }

      if (selectedTab === SELECTED_APPLICATIONS_TAB && !bdmLeadStates.length) {
        return Promise.reject('Please select one or more states')
      }
    }
    if (filterPropertiesOnListingAgentEmailEnabled) {
      const [valid, validateEmailErr] = this.validateEmail(
        filterPropertiesOnListingAgentEmail,
        'feed filter',
      )

      if (!valid) {
        this.setState({
          filterPropertiesOnListingAgentEmailErr: validateEmailErr,
        })
        return Promise.reject(validateEmailErr)
      }
    }

    if (viewingCapEnabled && viewingSlotEnabled) {
      return Promise.reject(
        'Please select either limit registrant or enable slot',
      )
    }
    if (viewingCheckinRequire && enablePreApply) {
      return Promise.reject(
        'Pre-Apply and Require Viewing can not be both active. Please select one.',
      )
    }
    const { updateTeamViewingSettingInfo, teams } = this.props
    const { teamSlug = '' } = this.props.match.params
    const chosenTeam = findChosenTeamBySlug(teams, teamSlug)
    const { guid = '' } = chosenTeam || {}
    let viewingSettingInfo,
      applicationSettingInfo,
      personalNotificationSettingInfo,
      propertyFeedSettingInfo
    if (selectedTab === TabViewings || selectedTab === SELECTED_QRCHECKIN_TAB) {
      viewingSettingInfo = {
        default_selected_viewing_duration_enabled:
          defaultSelectedViewingDurationEnabled.toString(),
        default_selected_viewing_duration:
          RawAvailableDurations[defaultSelectedViewingDuration].toString(),
        viewing_cap_enabled: viewingCapEnabled.toString(),
        viewing_cap: registrationLimitNumbers[viewingCapOption].toString(),
        viewing_slot_enabled: viewingSlotEnabled.toString(),
        viewing_slot: viewingSlotDurationOptions[viewingSlotOption].toString(),
        viewing_cutoff_enabled: viewingCutoffEnabled.toString(),
        viewing_cutoff_time: viewingCutoffTime.toString(),
        viewing_request_disabled: viewingRequestDisabled.toString(),
        viewing_report_sms_enabled: sendViewingReportSMS.toString(),
        viewing_report_email_enabled: sendViewingReportEmail.toString(),
        new_viewing_notice_to_owner_enabled:
          sendNewViewingNoticeToOwner.toString(),
        viewing_noshow_not_checkedin_registrants:
          autoAttendeesNoShow.toString(),
        [viewingsAttributesIDs.pauseViewingsWhenApprovedApplication]: (
          this.state[
            viewingsAttributesIDs.pauseViewingsWhenApprovedApplication
          ] || false
        ).toString(),
        viewing_optimise_travel_time: optimiseTravelTime.toString(),
        viewing_book_nearby_properties_selected:
          viewingBookNearbyPropertiesSelected.toString(),
        viewing_book_nearby_properties_proximity:
          PropertiesProximity[
            viewingBookNearbyPropertiesProximityOption
          ].toString(),
        viewing_report_dont_include_activity:
          viewingReportNoActivity.toString(),
        viewing_report_dont_include_lost_rent:
          viewingReportNoLostRent.toString(),
        viewing_qr_auto_checkin_enabled: viewingQRAutoCheckinEnabled.toString(),
        viewing_qr_checkin_enabled: viewingQRCheckinEnabled.toString(),
        viewing_notify_listing_agents: viewingNotifyListingAgents.toString(),
      }
    } else if (selectedTab === SELECTED_APPLICATIONS_TAB) {
      applicationSettingInfo = {
        application_cap_enabled: applicationCapEnabled.toString(),
        application_cap: applicationCapNumbers[applicationCapOption].toString(),
        [applicationsAttributesIDs.applicationLimitPerApplicantEnabled]:
          applicationLimitPerApplicantEnabled.toString(),
        ...(applicationLimitPerApplicantEnabled && {
          [applicationsAttributesIDs.applicationLimitPerApplicant]:
            applicationLimitPerApplicantOptions[
              applicationLimitPerApplicant
            ].toString(),
        }),
        [applicationsAttributesIDs.payslipsRequiredDurationEnabled]:
          payslipsRequiredDurationEnabled.toString(),
        ...(payslipsRequiredDurationEnabled && {
          [applicationsAttributesIDs.payslipsRequiredDuration]:
            payslipsMinWeeksRequiredOptions[payslipsMinDuration].toString(),
        }),
        identity_cap_enabled: identityCapEnabled.toString(),
        identity_cap_enabled_options: identityCapEnabledOptions.toString(),
        bank_statement_require_type: bankStatementRequireTypeOptions.toString(),
        bank_statement_require_recent_two:
          bankStatementRequireRecentTwo.toString(),
        require_current_employment_only:
          requireCurrentEmploymentOnly.toString(),
        enable_pre_apply: enablePreApply.toString(),
        disable_occupants_enabled: disableOccupantsEnabled.toString(),
        bank_statement_require: bankStatementRequire.toString(),
        enable_enhanced_disclosure: enableEnhancedDisclosure.toString(),
        payslips_require: payslipsRequire.toString(),
        payslips_require_number: payslipsRequireNumber.toString(),
        address_history_require: requireAddressHistory.toString(),
        enable_bdm_lead: enableNewManagementBDMLead.toString(),
        enable_rentvestor_bdm_lead: enableRentvestorBDMLead.toString(),
        bdm_lead_email: bdmLeadEmail.toString(),
        bdm_lead_states: JSON.stringify(bdmLeadStates),
        enable_internal_shortlisting: enableInternalShortlisting.toString(),
        viewing_checkin_require: viewingCheckinRequire.toString(),
        [rentFrequencyDBKey]: rentFrequency,
        student_attachments_required: studentAttachmentsRequired.toString(),
        filter_incomplete_household_applications:
          filterIncompleteHouseholdApplications.toString(),
        water_inclusion: waterInclusion.toString(),
        application_lease_offer_operations_email: operationsEmail.toString(),
        first_payment_default: firstPaymentDefault.toString(),
        lease_length_default: leaseLengthDefault.toString(),
        disable_remaining_balance: disableRemainingBalance.toString(),
        round_payments_down_nearest_dollar:
          roundPaymentsDownNearestDollar.toString(),
        application_lease_offer_always_copy_lease_ops:
          alwaysCopyOperationEmail.toString(),
        application_lease_offer_lease_ops_as_contact:
          operationEmailAsLeasingContact.toString(),
        [applicationsAttributesIDs.compactWorkflowEnabled]: (
          this.state[applicationsAttributesIDs.compactWorkflowEnabled] || false
        ).toString(),
        application_allow_international_mobiles:
          allowInternationalMobiles.toString(),
        id_docs_not_requested: idDocsNotRequested.toString(),
        application_ballots_enabled: enableApplicationBallots.toString(),
        ...(enableApplicationBallots && {
          [applicationsAttributesIDs.applicationSuccessfulBallotsSize]:
            applicationSuccessfulBallotsSizeOptions[
              applicationSuccessfulBallotsSize
            ].toString(),
          [applicationsAttributesIDs.applicationReserveBallotsSize]:
            applicationReserveBallotsSizeOptions[
              applicationReserveBallotsSize
            ].toString(),
        }),
        application_notify_listing_agents:
          applicationNotifyListingAgents.toString(),
        share_app_with_owner_bgc_approval_enabled:
          shareAppWithOwnerBgcApprovalEnabled.toString(),
        exclude_request_reason_for_moving:
          excludeRequestReasonForMoving.toString(),
        exclude_request_vehicle_registration:
          excludeRequestVehicleRegistration.toString(),
        share_app_with_owner_bgc_service_fee:
          shareAppWithOwnerBgcServiceFee.toString(),
      }
    } else if (selectedTab === TabPersonalNotifications) {
      personalNotificationSettingInfo = {
        enquiries_auto_response_enabled:
          enquiries_auto_response_enabled.toString(),
        onlist_send_notifications_enabled:
          onlist_send_notifications_enabled.toString(),
        viewings_new: viewings_new.toString(),
        viewings_updates: viewings_updates.toString(),
        applications_new: applications_new.toString(),
        applications_updates: applications_updates.toString(),
        applications_approved: applications_approved.toString(),
        daily_viewings_report: daily_viewings_report.toString(),
        send_daily_digest_notification:
          send_daily_digest_notification.toString(),
        weekly_digest_report: weekly_digest_report.toString(),
        daily_keys_report: daily_keys_report.toString(),
        viewing_registrant_notification_enabled:
          viewingRegistrantNotificationEnabled.toString(),
        application_update_notification_enabled:
          applicationUpdatedNotificationEnabled.toString(),
        new_application_notification_enabled:
          newlyReceivedApplicationNotificationEnabled.toString(),
      }
    } else if (selectedTab === PROPERTY_FEED_TAB) {
      const whitelist =
        filterPropertiesOnListingAgentEmailEnabled &&
        filterPropertiesOnListingAgentEmail.length > 0
          ? filterPropertiesOnListingAgentEmail
          : ''
      propertyFeedSettingInfo = {
        auto_archive_property_from_feed: autoArchiveProperty.toString(),
        auto_archive_leased_properties: autoArchiveLeasedProperties.toString(),
        feed_listing_agent_emails_whitelist: whitelist,
      }
    }
    return updateTeamViewingSettingInfo(
      guid,
      viewingSettingInfo,
      applicationSettingInfo,
      personalNotificationSettingInfo,
      propertyFeedSettingInfo,
    )
      .then(() => {
        this.setState({
          disableConfirmBtn: false,
          propertySearchResults: {},
          searchText: '',
        })
        return Promise.resolve()
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  onUpdateViewingRegistrantNotificationEnabled = (event) => {
    const { checked } = event.target || {}
    this.setState({
      viewingRegistrantNotificationEnabled: checked,
    })
  }

  onDefaultSelectedViewingDurationClicked = (event) => {
    const { checked = false } = event.target || {}
    this.setState({
      defaultSelectedViewingDurationEnabled: !!checked,
    })
  }

  onViewingCapCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    if (checked) {
      this.setState({
        viewingCapEnabled: checked,
        viewingSlotEnabled: !checked,
      })
    } else {
      this.setState({
        viewingCapEnabled: checked,
      })
    }
  }

  onViewingSlotsCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    if (checked) {
      this.setState({
        viewingSlotEnabled: checked,
        viewingCapEnabled: !checked,
      })
    } else {
      this.setState({
        viewingSlotEnabled: checked,
      })
    }
  }

  onViewingsBufferTimeChange = (event) => {
    this.setState({
      viewingsBufferTime: event.target.value,
    })
  }

  onWeekdayAddedClick = (text, index) => {
    const { timeWindowAdder } = this.state
    if (index === undefined) {
      timeWindowAdder[text] = !timeWindowAdder[text]
      this.setState({
        timeWindowAdder: Object.assign(
          {},
          this.state.timeWindowAdder,
          timeWindowAdder,
        ),
        unsavedChanges: true,
      })
    } else if (index !== undefined) {
      const { configuredViewings } = this.state
      configuredViewings[index][text] = !configuredViewings[index][text]
      this.setState({
        configuredViewings: Object.assign(
          [],
          configuredViewings.map((configuredViewing) =>
            Object.assign({}, configuredViewing),
          ),
        ),
        unsavedChanges: true,
      })
    }
  }

  onWeekdayClickedForViewingReport = (text) => {
    let { viewingReportAvailability = {} } = this.state
    if (viewingReportAvailability) {
      viewingReportAvailability[text] = !viewingReportAvailability[text]
    } else {
      viewingReportAvailability = {
        [text]: true,
      }
    }
    this.setState({
      viewingReportAvailability: Object.assign(
        {},
        this.state.viewingReportAvailability,
        viewingReportAvailability,
      ),
      unsavedChanges: true,
    })
  }

  callFetchAgencyPublicProperties(agencySlug) {
    const { fetchAgencyPublicProperties } = this.props
    fetchAgencyPublicProperties(agencySlug)
      .then(({ properties }) => {
        const {
          contactAddress,
          brandingBannerURL,
          brandingLogoURL,
          contactNumber,
          contactEmail,
          contactName,
        } = properties
        this.setState({
          agencyProfile: {
            agencyName: contactName,
            agencyAddress: contactAddress,
            agencyContactNumber: contactNumber,
            agencyEmail: contactEmail,
            brandingBannerURL: brandingBannerURL,
            brandingLogoURL: brandingLogoURL,
          },
        })
      })
      .catch((error) => {
        snugNotifier.error(translateErrorCodeToMessage(error))
      })
  }

  callFetchTeamProperties = (
    agencyGUID,
    { searchText, manager, status, offset, loadMore = false },
  ) => {
    const { fetchTeamProperties } = this.props
    loadMore
      ? this.setState({ loadMoreSpinner: true })
      : this.setState({ searchSpinner: true })
    return fetchTeamProperties(agencyGUID, {
      searchText: searchText,
      manager,
      status,
      offset,
      loadMore,
    })
      .then(({ properties }) => {
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ searchSpinner: false })
        return Promise.resolve({ properties })
      })
      .catch((apiError) => {
        this.setState({ apiError })
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ searchSpinner: false })
        return Promise.reject(apiError)
      })
  }

  changeViewingCutoffDropdownValue = () => {
    this.onFormUpdate('viewingCutoffTime')
  }

  changeViewingCutoffEnabled = (event) => {
    this.setState({
      viewingCutoffEnabled: event.target.checked,
    })
  }

  classNameFormatForTab = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-')
  }

  fetchAgencyReport = (guid) => {
    const { fetchAgencyStatusReport } = this.props
    this.setState({
      statusSpinner: true,
    })
    fetchAgencyStatusReport(guid)
      .then(({ result }) => {
        this.setState({
          statusData: result.data,
        })
        if (result.error) {
          snugNotifier.error(result.error)
        }
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
      .finally(() => {
        this.setState({
          statusSpinner: false,
        })
      })
  }

  fetchCalendarList = (guid) => {
    syncCalendar(guid)
      .then(({ calendarList }) => {
        if (calendarList.length > 0) {
          this.setState({
            isCalendarSubmitted: true,
            successCalendarStage: true,
            calendarTotalList: calendarList,
          })
          if (calendarList.length > 1) {
            this.setState({
              calendarInfo: calendarList.filter(
                (calendar) => calendar.platform === GOOGLE_CALENDAR_ABBR,
              )[0],
              currentCalendarType: 0,
            })
          } else {
            this.setState({
              calendarInfo: calendarList[0],
              currentCalendarType:
                calendarList[0].platform === GOOGLE_CALENDAR_ABBR ? 0 : 1,
            })
          }
          const redirectedQuery = window.location.search
          if (redirectedQuery.length > 0) {
            if (redirectedQuery.includes('google')) {
              this.setState({
                calendarInfo: calendarList.filter(
                  (calendar) => calendar.platform === GOOGLE_CALENDAR_ABBR,
                )[0],
                currentCalendarType: 0,
              })
            } else {
              this.setState({
                calendarInfo: calendarList.filter(
                  (calendar) => calendar.platform === OUTLOOK_CALENDAR_ABBR,
                )[0],
                currentCalendarType: 1,
              })
            }
          }
        }
      })
      .catch((err) => snugNotifier.error(err))
  }

  fetchSetting = () => {
    const { fetchViewingPreference, teams, fetchTeamSettingInfo } = this.props
    const { teamSlug = '' } = this.props.match.params
    const chosenTeam = findChosenTeamBySlug(teams, teamSlug)
    const { guid = '' } = chosenTeam || {}
    this.setState({ tableSpinner: true, agencyGUID: guid })
    fetchTeamSettingInfo(guid, AttributeKeysAll)
      .then(
        ({
          viewings = {},
          application = {},
          personalnotifications = {},
          integrations = {},
          feed = {},
          bundle,
        }) => {
          const {
            default_selected_viewing_duration_enabled:
              defaultSelectedViewingDurationEnabled = false,
            default_selected_viewing_duration:
              defaultSelectedViewingDuration = 15,
            viewing_cap_enabled: viewingCapEnabled = false,
            viewing_cap: viewingCap = registrationLimitNumbers[0],
            viewing_slot_enabled: viewingSlotEnabled = false,
            viewing_slot: viewingSlot = viewingSlotDurationOptions[0],
            viewing_cutoff_enabled: viewingCutoffEnabled = false,
            viewing_request_disabled: viewingRequestDisabled = false,
            viewing_report_sms_enabled: sendViewingReportSMS = false,
            viewing_report_email_enabled: sendViewingReportEmail = false,
            new_viewing_notice_to_owner_enabled:
              sendNewViewingNoticeToOwner = false,
            viewing_cutoff_time: viewingCutoffTime = viewingCutoffOptions[0]
              .guid,
            viewing_noshow_not_checkedin_registrants:
              autoAttendeesNoShow = false,
            viewing_optimise_travel_time: optimiseTravelTime = false,
            // viewing_book_nearby_properties: viewingBookNearbyProperties = false,
            viewing_book_nearby_properties_enabled:
              viewingBookNearbyProperties = false,
            viewing_book_nearby_properties_selected:
              viewingBookNearbyPropertiesSelected = false,
            viewing_book_nearby_properties_proximity:
              viewingBookNearbyPropertiesProximity = PropertiesProximity[0],
            viewing_report_dont_include_activity:
              viewingReportNoActivity = false,
            viewing_report_dont_include_lost_rent:
              viewingReportNoLostRent = false,
            viewing_qr_auto_checkin_enabled:
              viewingQRAutoCheckinEnabled = false,
            viewing_qr_checkin_enabled: viewingQRCheckinEnabled = true,
            viewing_notify_listing_agents: viewingNotifyListingAgents,
          } = parseDataUtil.convertObjValueFromStringToBoolean(viewings)
          const {
            application_cap_enabled: applicationCapEnabled = false,
            identity_cap_enabled: identityCapEnabled = false,
            identity_cap_enabled_options: identityCapEnabledOptions = 0,
            bank_statement_require_type: bankStatementRequireTypeOptions = 0,
            bank_statement_require_recent_two:
              bankStatementRequireRecentTwo = false,
            require_current_employment_only:
              requireCurrentEmploymentOnly = false,
            enable_pre_apply: enablePreApply = false,
            application_cap: applicationCap = applicationCapNumbers[0],
            [applicationsAttributesIDs.applicationLimitPerApplicantEnabled]:
              applicationLimitPerApplicantEnabled = false,
            [applicationsAttributesIDs.applicationLimitPerApplicant]:
              applicationLimitPerApplicant = applicationLimitPerApplicantDefault,
            [applicationsAttributesIDs.payslipsRequiredDurationEnabled]:
              payslipsRequiredDurationEnabled = false,
            [applicationsAttributesIDs.payslipsRequiredDuration]:
              payslipsMinDuration = payslipsMinWeeksDefault,
            disable_occupants_enabled: disableOccupantsEnabled = false,
            bank_statement_require: bankStatementRequire = false,
            enable_enhanced_disclosure: enableEnhancedDisclosure = false,
            payslips_require: payslipsRequire = false,
            payslips_require_number: payslipsRequireNumber = 0,
            address_history_require: requireAddressHistory = false,
            enable_bdm_lead: enableNewManagementBDMLead = false,
            enable_rentvestor_bdm_lead: enableRentvestorBDMLead = false,
            bdm_lead_email: bdmLeadEmail = '',
            bdm_lead_states: _bdmLeadStates = '[]',
            enable_internal_shortlisting: enableInternalShortlisting = false,
            viewing_checkin_require: viewingCheckinRequire = false,
            [rentFrequencyDBKey]: rentFrequency = rentFrequencyDefaultValue,
            student_attachments_required: studentAttachmentsRequired = false,
            filter_incomplete_household_applications:
              filterIncompleteHouseholdApplications = false,
            water_inclusion: waterInclusion = 0,
            application_lease_offer_operations_email: operationsEmail = '',
            first_payment_default: firstPaymentDefault = 1,
            lease_length_default: leaseLengthDefault = 1,
            disable_remaining_balance: disableRemainingBalance = false,
            round_payments_down_nearest_dollar:
              roundPaymentsDownNearestDollar = false,
            application_lease_offer_always_copy_lease_ops:
              alwaysCopyOperationEmail = false,
            application_lease_offer_lease_ops_as_contact:
              operationEmailAsLeasingContact = false,
            application_allow_international_mobiles:
              allowInternationalMobiles = false,
            application_ballots_enabled: enableApplicationBallots = false,
            id_docs_not_requested: idDocsNotRequested = false,
            [applicationsAttributesIDs.applicationSuccessfulBallotsSize]:
              applicationSuccessfulBallotsSize = applicationSuccessfulBallotsSizeDefault,
            [applicationsAttributesIDs.applicationReserveBallotsSize]:
              applicationReserveBallotsSize = applicationReserveBallotsSizeDefault,
            application_notify_listing_agents: applicationNotifyListingAgents,
            share_app_with_owner_bgc_approval_enabled:
              shareAppWithOwnerBgcApprovalEnabled = false,
            exclude_request_reason_for_moving:
              excludeRequestReasonForMoving = false,
            exclude_request_vehicle_registration:
              excludeRequestVehicleRegistration = false,
            request_car_space: requestCarSpace = false,
            share_app_with_owner_bgc_service_fee:
              shareAppWithOwnerBgcServiceFee = '0',
          } = application
          let bdmLeadStates = []
          if (typeof _bdmLeadStates === 'string') {
            bdmLeadStates = JSON.parse(_bdmLeadStates)
          }
          const {
            enquiries_auto_response_enabled,
            viewings_new,
            viewings_updates,
            applications_new,
            applications_updates,
            applications_approved,
            daily_viewings_report = 'false',
            send_daily_digest_notification = 'false',
            weekly_digest_report = 'false',
            daily_keys_report = 'false',
            viewing_registrant_notification_enabled:
              viewingRegistrantNotificationEnabled = true,
            application_update_notification_enabled:
              applicationUpdatedNotificationEnabled = true,
            new_application_notification_enabled:
              newlyReceivedApplicationNotificationEnabled = true,
            onlist_send_notifications_enabled,
          } = personalnotifications
          const {
            console_cloud_enabled,
            property_tree_enabled,
            property_me_enabled,
            vault_re_enabled,
          } = integrations
          const {
            auto_archive_property_from_feed: autoArchiveProperty = false,
            auto_archive_leased_properties: autoArchiveLeasedProperties = false,
          } = parseDataUtil.convertObjValueFromStringToBoolean(feed)

          const {
            feed_listing_agent_emails_whitelist:
              filterPropertiesOnListingAgentEmail = '',
          } = feed

          const { integrations_bundle } =
            parseDataUtil.convertObjValueFromStringToBoolean(bundle)

          this.setState({
            tableSpinner: false,
            defaultSelectedViewingDurationEnabled,
            defaultSelectedViewingDuration: RawAvailableDurations.indexOf(
              +defaultSelectedViewingDuration,
            ),
            viewingCapEnabled,
            viewingCap,
            viewingRegistrantNotificationEnabled:
              viewingRegistrantNotificationEnabled === 'true',
            applicationUpdatedNotificationEnabled:
              applicationUpdatedNotificationEnabled === 'true',
            newlyReceivedApplicationNotificationEnabled:
              newlyReceivedApplicationNotificationEnabled === 'true',
            viewingCapOption: registrationLimitNumbers.findIndex(
              (item) => item === Number(viewingCap),
            ),
            viewingSlotEnabled,
            viewingSlotOption: viewingSlotDurationOptions.findIndex(
              (item) => item === Number(viewingSlot),
            ),
            viewingRequestDisabled,
            viewingCutoffEnabled,
            viewingCutoffTime: viewingCutoffOptions.findIndex(
              (item) => item.guid === Number(viewingCutoffTime),
            ),
            applicationCapEnabled: applicationCapEnabled === 'true',
            applicationCap,
            applicationLimitPerApplicantEnabled:
              applicationLimitPerApplicantEnabled === 'true',
            applicationLimitPerApplicant:
              applicationLimitPerApplicantOptions.indexOf(
                Number(applicationLimitPerApplicant),
              ),
            payslipsRequiredDurationEnabled: covertBoolStringToBool(
              payslipsRequiredDurationEnabled,
            ),
            payslipsMinDuration: payslipsMinWeeksRequiredOptions.indexOf(
              Number(payslipsMinDuration),
            ),
            identityCapEnabled: identityCapEnabled === 'true',
            identityCapEnabledOptions: identityCapEnabledOptions,
            bankStatementRequireTypeOptions: bankStatementRequireTypeOptions,
            bankStatementRequireRecentTwo:
              bankStatementRequireRecentTwo === 'true',
            requireCurrentEmploymentOnly:
              requireCurrentEmploymentOnly === 'true',
            disableOccupantsEnabled: disableOccupantsEnabled === 'true',
            bankStatementRequire: bankStatementRequire === 'true',
            enableEnhancedDisclosure: enableEnhancedDisclosure === 'true',
            payslipsRequire: payslipsRequire === 'true',
            payslipsRequireNumber: payslipsRequireNumber,
            requireAddressHistory: requireAddressHistory === 'true',
            enableNewManagementBDMLead: enableNewManagementBDMLead === 'true',
            enableRentvestorBDMLead: enableRentvestorBDMLead === 'true',
            bdmLeadEmail: bdmLeadEmail,
            bdmLeadStates: bdmLeadStates,
            enableInternalShortlisting: enableInternalShortlisting === 'true',
            viewingCheckinRequire: viewingCheckinRequire === 'true',
            rentFrequency,
            enablePreApply: enablePreApply === 'true',
            studentAttachmentsRequired: studentAttachmentsRequired === 'true',
            filterIncompleteHouseholdApplications:
              filterIncompleteHouseholdApplications === 'true',
            applicationCapOption: applicationCapNumbers.findIndex(
              (item) => item === Number(applicationCap),
            ),
            enquiries_auto_response_enabled:
              enquiries_auto_response_enabled === 'true',
            onlist_send_notifications_enabled:
              onlist_send_notifications_enabled === 'true',
            viewings_new: viewings_new === 'true',
            viewings_updates: viewings_updates === 'true',
            [viewingsAttributesIDs.pauseViewingsWhenApprovedApplication]:
              viewings[
                viewingsAttributesIDs.pauseViewingsWhenApprovedApplication
              ] === 'true',
            applications_new: applications_new === 'true',
            applications_updates: applications_updates === 'true',
            applications_approved: applications_approved === 'true',
            daily_viewings_report: daily_viewings_report === 'true',
            send_daily_digest_notification:
              send_daily_digest_notification === 'true',
            daily_keys_report: daily_keys_report === 'true',
            weekly_digest_report: weekly_digest_report === 'true',
            consoleCloudPluginEnabled: console_cloud_enabled === 'true',
            propertyTreePMSEnabled: property_tree_enabled === 'true',
            propertyMePMSEnabled: property_me_enabled === 'true',
            vaultREPMSEnabled: vault_re_enabled === 'true',
            sendViewingReportSMS,
            sendViewingReportEmail,
            sendNewViewingNoticeToOwner,
            firstPaymentDefault,
            leaseLengthDefault,
            waterInclusion,
            disableRemainingBalance,
            roundPaymentsDownNearestDollar:
              roundPaymentsDownNearestDollar === 'true',
            alwaysCopyOperationEmail: alwaysCopyOperationEmail === 'true',
            operationEmailAsLeasingContact:
              operationEmailAsLeasingContact === 'true',
            operationsEmail,
            autoArchiveProperty,
            autoAttendeesNoShow,
            optimiseTravelTime,
            viewingBookNearbyProperties,
            viewingBookNearbyPropertiesSelected,
            viewingReportNoActivity,
            viewingReportNoLostRent,
            viewingQRAutoCheckinEnabled,
            viewingQRCheckinEnabled,
            viewingNotifyListingAgents,
            viewingBookNearbyPropertiesProximityOption:
              PropertiesProximity.findIndex(
                (item) => item === Number(viewingBookNearbyPropertiesProximity),
              ),
            [applicationsAttributesIDs.compactWorkflowEnabled]:
              application[applicationsAttributesIDs.compactWorkflowEnabled] ===
              'true',
            allowInternationalMobiles: allowInternationalMobiles === 'true',
            enableApplicationBallots: enableApplicationBallots === 'true',
            applicationSuccessfulBallotsSize:
              applicationSuccessfulBallotsSizeOptions.findIndex(
                (item) => item === Number(applicationSuccessfulBallotsSize),
              ),
            applicationReserveBallotsSize:
              applicationReserveBallotsSizeOptions.findIndex(
                (item) => item === Number(applicationReserveBallotsSize),
              ),
            idDocsNotRequested: idDocsNotRequested === 'true',
            autoArchiveLeasedProperties,
            filterPropertiesOnListingAgentEmailEnabled:
              filterPropertiesOnListingAgentEmail.length > 0,
            filterPropertiesOnListingAgentEmail:
              filterPropertiesOnListingAgentEmail,
            applicationNotifyListingAgents:
              applicationNotifyListingAgents === 'true',
            shareAppWithOwnerBgcApprovalEnabled:
              shareAppWithOwnerBgcApprovalEnabled === 'true',
            shareAppWithOwnerBgcServiceFee,
            integrationsBundleIsActive: integrations_bundle,
            excludeRequestReasonForMoving:
              excludeRequestReasonForMoving === 'true',
            excludeRequestVehicleRegistration:
              excludeRequestVehicleRegistration === 'true',
            requestCarSpace: requestCarSpace === 'true',
          })
        },
      )
      .catch((error) => {
        this.setState({
          error,
          tableSpinner: false,
        })
      })

    fetchViewingPreference(guid, '')
      .then(({ preferenceInfo }) => {
        this.hydrateViewingPreferences(preferenceInfo)
      })
      .catch((err) => {
        this.setState({ error: translateErrorCodeToMessage(err) })
      })
    this.fetchAgencyReport(guid)
  }

  hydrateViewingPreferences = (preferenceInfo) => {
    const { fetchProperty } = this.props
    let {
      PickTime,
      SpecifiedTime,
      Availabilities = [],
      BlockedDates: blockedDates = [],
      ViewingsBuffer,
      ShowViewingScheduleBeforeVacant,
      ExcludedProperties = [],
      ViewingReportAvailability,
      OnlyOneViewingPerDay,
      tenantedPropertiesScheduledViewingLeadTime,
      scheduleDays,
    } = preferenceInfo

    // handling the case when scheduleDays is not stored in BE
    if (scheduleDays === 0) {
      scheduleDays = ViewingSchedule14Days
    }

    let transformedBlockedDates = (blockedDates || []).map((dateInfo) => {
      if (dateInfo.source === BLOCKED_DATE_V1) {
        // to maintain the old blockedDates behaviour.
        return {
          ...dateInfo,
          startDate: moment(dateInfo.startDate).local().format(DATE_WITH_DASH),
          endDate: moment(dateInfo.endDate).local().format(DATE_WITH_DASH),
        }
      }

      return dateInfo
    })

    const viewingDuration =
      (Availabilities &&
        Availabilities.length > 0 &&
        Availabilities[0].Duration + ' mins') ||
      DefaultViewingDuration
    const excludedProperties = []
    ExcludedProperties?.forEach((properties) => {
      properties._id = IdGenerator()
      fetchProperty(properties.PropertyGUID)
        .then((result) => {
          excludedProperties.push({
            ...result,
            excludedPropertyDetails: properties,
            dateEntries: transformExcludedDates(
              properties.excludedPropertyDates,
            ), // initial excludedPropertyDates entries, don't mutate
          })
          this.setState({
            excludedProperties,
          })
        })
        .catch((apiError) => {
          this.setState({
            apiError,
          })
        })
    })
    this.setState({
      isFixedTimePicked: SpecifiedTime,
      isFlexibleTimePicked: PickTime,
      configuredViewings: Object.assign(
        [],
        Availabilities &&
          Availabilities.map((availability) => Object.assign({}, availability)),
      ),
      reservedViewing: Object.assign(
        [],
        Availabilities &&
          Availabilities.map((availability) => Object.assign({}, availability)),
      ),
      viewingsBufferTime: ViewingsBuffers.includes(ViewingsBuffer)
        ? ViewingsBuffer
        : DEFAULT_VIEWING_BUFFER,
      showViewingScheduleBeforeVacant: ShowViewingScheduleBeforeVacant,
      tenantedPropertiesScheduledViewingLeadTime:
        tenantedPropertiesScheduledViewingLeadTime,
      scheduleDays: scheduleDays || ViewingSchedule10Days,
      error: '',
      currentBlockedDates: transformedBlockedDates,
      timeWindowAdder: Object.assign(
        {},
        {
          ...emptyAdder,
          duration: viewingDuration,
        },
      ),
      viewingReportAvailability: ViewingReportAvailability,
      onlyOneViewingPerDay: OnlyOneViewingPerDay,
    })
  }

  isPropertyAttached = (guidID) => {
    const { excludedProperties } = this.state
    return excludedProperties.some((property) => property.guidID === guidID)
  }

  updateViewingScheduleDropdown = (index, value) => {
    if (index === undefined || value === undefined) {
      this.setState({
        timeWindowAdder: Object.assign(this.state.timeWindowAdder, {
          ViewingScheduleZone: Number(value),
        }),
        unsavedChanges: true,
      })
    } else if (index !== undefined) {
      const { configuredViewings } = this.state
      configuredViewings[index]['ViewingScheduleZone'] = Number(value)
      this.setState({
        configuredViewings: configuredViewings,
        unsavedChanges: true,
      })
    }
  }

  viewingItemValidation(viewing) {
    if (!viewing.StartTime) {
      snugNotifier.error('Please choose start time')
      return false
    }
    if (!viewing.EndTime) {
      snugNotifier.error('Please choose end time')
      return false
    }
    if (
      moment(viewing.StartTime, FULL_HOUR_AM_PM).isAfter(
        moment(viewing.EndTime, FULL_HOUR_AM_PM),
      )
    ) {
      snugNotifier.error('End day needs to be after start day')
      return false
    }
    if (AtLeastOneDaySelected(viewing)) {
      snugNotifier.error('Please select at least one day')
      return false
    }
    return true
  }

  onAutomaticallySendSMSViewingReportCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      sendViewingReportSMS: checked,
    })
  }

  onAutomaticallySendEmailViewingReportCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      sendViewingReportEmail: checked,
    })
  }

  onSendNewViewingNoticeToOwnerCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    this.setState({
      sendNewViewingNoticeToOwner: checked,
    })
  }

  updateTenantedPropertiesScheduledViewingLeadTime(value) {
    this.setState({
      tenantedPropertiesScheduledViewingLeadTime: value,
    })
  }

  updateReportNoActivity = ({ target = {} }) => {
    const { checked } = target
    this.setState({
      viewingReportNoActivity: checked,
    })
  }

  updateCustomisedViewingReport = ({ target = {} }) => {
    const { checked } = target
    if (checked === false) {
      this.setState({
        viewingReportNoActivity: false,
        viewingReportNoLostRent: false,
      })
    }
    this.setState({
      customiseViewingReportSelected: checked,
    })
  }

  updateViewingQRAutoCheckinEnabled = ({ target = {} }) => {
    const { checked } = target
    this.setState({
      viewingQRAutoCheckinEnabled: checked,
    })
  }

  updateReportNoLostRent = ({ target = {} }) => {
    const { checked } = target
    this.setState({
      viewingReportNoLostRent: checked,
    })
  }

  updateQRCheckinEnabled = ({ target = {} }) => {
    const { checked } = target
    this.setState({
      viewingQRCheckinEnabled: checked,
    })
  }

  onExcludedPropertyDateEntriesChanged = (property, payload) => {
    let excludedProperties = this.state.excludedProperties

    excludedProperties.forEach((p) => {
      if (p.guidID === property.guidID) {
        p.dateEntries = payload
      }
    })

    this.setState({
      excludedProperties,
    })
  }

  onBlockedDatesChanges = (payload) => {
    this.setState({
      blockedDates: [...payload],
    })
  }

  renderViewingTab = () => {
    const {
      isFixedTimePicked,
      isFlexibleTimePicked,
      optimiseTravelTime,
      configuredViewings,
      timeWindowAdder,
      error,
      showAddLink,
      currentBlockedDates = [],
      viewingsBufferTime,
      showViewingScheduleBeforeVacant,
      defaultSelectedViewingDurationEnabled,
      defaultSelectedViewingDuration,
      viewingCapEnabled,
      viewingCapOption = 0,
      viewingSlotEnabled,
      viewingSlotOption = 0,
      disableConfirmBtn,
      tableSpinner,
      searchText,
      propertySearchResults,
      selectedPropertyIdError,
      newListingExcludedProperties,
      excludedProperties,
      searchSpinner,
      viewingCutoffEnabled,
      viewingCutoffTime,
      viewingRequestDisabled,
      sendViewingReportSMS,
      sendViewingReportEmail,
      sendNewViewingNoticeToOwner,
      viewingReportAvailability = {},
      onlyOneViewingPerDay,
      autoAttendeesNoShow,
      tenantedPropertiesScheduledViewingLeadTime,
      tenantedPropertiesScheduledViewingLeadTimeOptions,
      scheduleDays,
      [viewingsAttributesIDs.pauseViewingsWhenApprovedApplication]:
        pauseViewingsWhenApprovedApp = false,
      currentTeam,
      viewingBookNearbyProperties,
      viewingBookNearbyPropertiesSelected,
      viewingBookNearbyPropertiesProximityOption,
      viewingReportNoActivity,
      viewingReportNoLostRent,
      isAccountLite,
      isUserAdmin,
      isAccountTypeProductivityOrHigher,
      customiseViewingReportSelected = false,
      viewingNotifyListingAgents,
    } = this.state
    const { teamSlug = '' } = this.props.match.params

    const excludedPropertyCollection =
      newListingExcludedProperties.concat(excludedProperties)
    const isActionDisabled = isAccountLite || !isUserAdmin
    const customiseViewingReport =
      viewingReportNoActivity ||
      viewingReportNoLostRent ||
      customiseViewingReportSelected

    return (
      <div className={`sm-property-details__section`}>
        <Text as="h1" mb={4} mt={0} fontSize={theme.fontSizes[12] + 'px'}>
          Viewings
        </Text>
        {tableSpinner ? (
          <Spinner />
        ) : (
          <div>
            <Box mb={8} fontSize={5}>
              Customise viewing management settings for your team. Explore plans
              at{' '}
              <a
                href="https://snug.com/pricing/"
                target="_blank"
                rel="noreferrer"
              >
                snug.com/pricing
              </a>
            </Box>
            {!isUserAdmin && (
              <UserNotAuthorizedShowTeamMembersList teamSlug={teamSlug} />
            )}
            <Text as="h4" fontSize={theme.fontSizes[8] + 'px'} mb={5} mt={8}>
              Free Forever Plan
            </Text>
            <Form.CheckBoxGeneral
              label="Email notify property listing agents for new enquiries"
              checked={viewingNotifyListingAgents}
              onChange={this.onCheckBoxChanged('viewingNotifyListingAgents')}
              eventProcessedByComponent
            />

            <Flex mb={5} mt={8} alignItems="center">
              <Text as="h4" fontSize={theme.fontSizes[8] + 'px'}>
                Growth Plan
              </Text>
              {isAccountLite && (
                <Box ml={6}>
                  <UpgradeCTA alignRight={false} />
                </Box>
              )}
            </Flex>
            <div className="mb20">
              <Form.CheckBoxGeneral
                label="Enable default selected viewing duration"
                checked={defaultSelectedViewingDurationEnabled}
                onChange={this.onDefaultSelectedViewingDurationClicked}
                eventProcessedByComponent
                disabled={isActionDisabled}
                showDisabledGrey={isActionDisabled}
              />
              {!!defaultSelectedViewingDurationEnabled && (
                <div>
                  <Form.Dropdown
                    label="Please select the default viewing duration"
                    value={defaultSelectedViewingDuration}
                    options={RawAvailableDurations}
                    onChange={this.onFormUpdate(
                      'defaultSelectedViewingDuration',
                    )}
                    extraOptionText="mins"
                  />
                </div>
              )}
            </div>

            <div>
              <Form.CheckBoxGeneral
                label="Limit viewing registrations"
                checked={viewingCapEnabled}
                onChange={this.onViewingCapCheckboxClicked}
                eventProcessedByComponent
                disabled={isActionDisabled}
                showDisabledGrey={isActionDisabled}
              />
              {!!viewingCapEnabled && (
                <div>
                  <Form.Dropdown
                    label="Please select the max number of registrants"
                    value={viewingCapOption}
                    options={registrationLimitNumbers}
                    onChange={this.onFormUpdate('viewingCapOption')}
                    extraOptionText="registrants"
                  />
                </div>
              )}
            </div>
            <div className="mt20  wa pt0">
              <Form.CheckBoxGeneral
                label="Enable Viewing Slots"
                checked={viewingSlotEnabled}
                onChange={this.onViewingSlotsCheckboxClicked}
                eventProcessedByComponent
                toolTip={
                  <div>
                    <i
                      className="icon-help-outline fs16 gray-light-color ibm"
                      data-tooltip-content=""
                      data-tooltip-id="viewingsSlot"
                    />
                  </div>
                }
                disabled={isActionDisabled}
                showDisabledGrey={isActionDisabled}
              />
              {!!viewingSlotEnabled && (
                <div>
                  <Form.Dropdown
                    label="Please select the viewing slot duration"
                    value={viewingSlotOption}
                    options={viewingSlotDurationOptions}
                    onChange={this.onFormUpdate('viewingSlotOption')}
                    extraOptionText="mins"
                  />
                </div>
              )}
              <div>
                <ReactTooltip id="viewingsSlot">
                  <span>
                    Assign and limit registrations to available 5, 10 or 15
                    minute slots for viewing duration
                  </span>
                </ReactTooltip>
              </div>
            </div>
            <div className="mt20  wa pt0">
              <Form.CheckBoxGeneral
                id="disableViewingTip"
                label="Disable request a viewing"
                checked={viewingRequestDisabled}
                onChange={this.onRequestViewingCheckboxClicked}
                componentClassName="mt10"
                eventProcessedByComponent
                toolTip={
                  <div>
                    <i
                      className="icon-help-outline fs16 gray-light-color ibm"
                      data-tooltip-content=""
                      data-tooltip-id="disableViewingTip"
                    />
                  </div>
                }
                disabled={isActionDisabled}
                showDisabledGrey={isActionDisabled}
              />
              <div>
                <ReactTooltip id="disableViewingTip" data-type="info">
                  <div>
                    The request a viewing button will be disabled on the
                    property page.
                  </div>
                </ReactTooltip>
              </div>
            </div>

            <div>
              <ReactTooltip id="sendNewViewingNoticeToOwnerTooltip">
                <span>
                  A email will be sent to property owners (if added) notifying
                  them when new open or private viewing times are schedule
                </span>
              </ReactTooltip>
            </div>
            <div className="mt20  wa pt0">
              <Form.CheckBoxGeneral
                id="sendNewViewingNoticeToOwner"
                label="Send notice to owner when new times scheduled"
                checked={sendNewViewingNoticeToOwner}
                onChange={this.onSendNewViewingNoticeToOwnerCheckboxClicked}
                toolTip={
                  <div>
                    <i
                      className="icon-help-outline fs16 gray-light-color ibm"
                      data-tooltip-content=""
                      data-tooltip-id="sendNewViewingNoticeToOwnerTooltip"
                    />
                  </div>
                }
                componentClassName="mt10"
                eventProcessedByComponent
                disabled={isActionDisabled}
                showDisabledGrey={isActionDisabled}
              />
            </div>

            <div>
              <ReactTooltip id="enableAttendeesNoShowTooltip">
                <span>Processes within 15 minutes of viewing end time</span>
              </ReactTooltip>
            </div>
            <div className="mt20  wa pt0">
              <Form.CheckBoxGeneral
                id="enableAttendeesNoShow"
                label="Automatically No Show registered prospects that are not Checked-In"
                checked={autoAttendeesNoShow}
                onChange={this.onCheckBoxChanged('autoAttendeesNoShow')}
                toolTip={
                  <div>
                    <i
                      className="icon-help-outline fs16 gray-light-color ibm"
                      data-tooltip-content=""
                      data-tooltip-id="enableAttendeesNoShowTooltip"
                    />
                  </div>
                }
                componentClassName="mt10"
                eventProcessedByComponent
                disabled={isActionDisabled}
                showDisabledGrey={isActionDisabled}
              />
            </div>

            <div>
              <ReactTooltip id="enablePauseViewingsTooltip">
                <span>
                  Don't display open homes or viewing schedule for a property if
                  an application is at workflow status "Application approved" or
                  greater.
                </span>
              </ReactTooltip>
            </div>
            <div className="mt20 wa pt0">
              <Form.CheckBoxGeneral
                id={viewingsAttributesIDs.pauseViewingsWhenApprovedApplication}
                label="Pause viewings when application approved"
                checked={pauseViewingsWhenApprovedApp}
                onChange={this.onCheckBoxChanged(
                  viewingsAttributesIDs.pauseViewingsWhenApprovedApplication,
                )}
                toolTip={
                  <div>
                    <i
                      className="icon-help-outline fs16 gray-light-color ibm"
                      data-tooltip-content=""
                      data-tooltip-id="enablePauseViewingsTooltip"
                    />
                  </div>
                }
                componentClassName="mt10"
                eventProcessedByComponent
                disabled={isActionDisabled}
                showDisabledGrey={isActionDisabled}
              />
            </div>

            <Form.CheckBoxGeneral
              id="customiseViewingReport"
              label="Customise Viewing Report"
              checked={customiseViewingReport}
              onChange={this.updateCustomisedViewingReport}
              componentClassName="mt20"
              eventProcessedByComponent={true}
              showDisabledGrey={isActionDisabled}
              disabled={isActionDisabled}
            />

            {customiseViewingReport && (
              <>
                <div>
                  <ReactTooltip id="excludeActivityInViewingReport">
                    <span>
                      Default to excluding Activity from Viewing Report,
                      optionally include on report
                    </span>
                  </ReactTooltip>
                </div>
                <Box pt="0">
                  <Form.CheckBoxGeneral
                    id="viewingReportNoActivity"
                    label="Exclude activity"
                    checked={viewingReportNoActivity}
                    onChange={this.updateReportNoActivity}
                    componentClassName="mt20 pl30"
                    eventProcessedByComponent={true}
                    showDisabledGrey={isActionDisabled}
                    disabled={isActionDisabled}
                    toolTip={
                      <div>
                        <i
                          className="icon-help-outline fs16 gray-light-color ibm"
                          data-tooltip-content=""
                          data-tooltip-id="excludeActivityInViewingReport"
                        />
                      </div>
                    }
                  />
                </Box>

                <div>
                  <ReactTooltip id="excludeRentLostInViewingReport">
                    <span>
                      Default to excluding lost rent from Viewing Report,
                      optionally include on report
                    </span>
                  </ReactTooltip>
                </div>
                <Box pt="0" className="mt20">
                  <Form.CheckBoxGeneral
                    id="viewingReportNoLostRent"
                    label="Exclude lost rent"
                    checked={viewingReportNoLostRent}
                    componentClassName="mt10 pl30"
                    onChange={this.updateReportNoLostRent}
                    eventProcessedByComponent={true}
                    showDisabledGrey={isActionDisabled}
                    disabled={isActionDisabled}
                    toolTip={
                      <div>
                        <i
                          className="icon-help-outline fs16 gray-light-color ibm"
                          data-tooltip-content=""
                          data-tooltip-id="excludeRentLostInViewingReport"
                        />
                      </div>
                    }
                  />
                </Box>
              </>
            )}
            <Text as="h3" fontSize={theme.fontSizes[7] + 'px'} mt={8}>
              Viewing schedule
            </Text>
            <ViewingScheduleOptions
              isFixedTimePicked={isFixedTimePicked}
              isFlexibleTimePicked={isFlexibleTimePicked}
              onFixedTimeBoxChanged={this.onFixedTimeBoxChanged}
              onFlexibleTimeBoxChanged={this.onFlexibleTimeBoxChanged}
              viewingCutoffEnabled={viewingCutoffEnabled}
              changeViewingCutoffEnabled={this.changeViewingCutoffEnabled}
              changeViewingCutoffDropdownValue={this.onFormUpdate(
                'viewingCutoffTime',
              )}
              viewingCutoffTime={viewingCutoffTime}
              onlyOneViewingPerDay={onlyOneViewingPerDay}
              optimiseTravelTime={optimiseTravelTime}
              onOnlyOneViewingPerDayChanged={this.onOnlyOneViewingPerDayChanged}
              onOptimiseTravelTimeChanged={this.onCheckBoxChanged(
                'optimiseTravelTime',
              )}
              optimiseTravelOptionEnabled={isTravelTimeOptimisationEnabled(
                currentTeam,
              )}
              viewingBookNearbyProperties={viewingBookNearbyProperties}
              viewingBookNearbyPropertiesSelected={
                viewingBookNearbyPropertiesSelected
              }
              onViewingBookNearbyPropertiesChanged={
                this.onViewingBookNearbyPropertiesChanged
              }
              PropertiesProximity={PropertiesProximity}
              viewingBookNearbyPropertiesProximityOption={
                viewingBookNearbyPropertiesProximityOption
              }
              onFormUpdate={this.onFormUpdate}
              isActionDisabled={isActionDisabled}
              isAccountLite={isAccountLite}
            />
            <Availability
              configuredViewings={configuredViewings}
              availableDuration={AvailableDuration}
              weekDaySelectorArray={WeekDaySelectorArray}
              timeWindowAdder={timeWindowAdder}
              showAddLink={showAddLink || isActionDisabled}
              onOptionBoxChange={this.onOptionBoxChange}
              onStartDatePickerChange={this.onStartDateAndEndDatePickerChange}
              onEndDatePickerChange={this.onStartDateAndEndDatePickerChange}
              onWeekdayAddedClick={this.onWeekdayAddedClick}
              onButtonClicked={this.onButtonClicked}
              isFlexibleTimePicked={isFlexibleTimePicked}
              onStartDateFocus={this.onStartDateFocus}
              onEndDateFocus={this.onEndDateFocus}
              onAddLinkClicked={this.onAddLinkClicked}
              viewingsBuffers={ViewingsBuffers}
              viewingsBufferTime={viewingsBufferTime}
              onViewingsBufferTimeChange={this.onViewingsBufferTimeChange}
              showViewingScheduleBeforeVacant={!showViewingScheduleBeforeVacant}
              onShowViewingScheduleBeforeVacantUpdated={
                this.onShowViewingScheduleBeforeVacantUpdated
              }
              updateViewingScheduleDropdown={this.updateViewingScheduleDropdown}
              isPropertyAvailability={false}
              tenantedPropertiesScheduledViewingLeadTime={
                tenantedPropertiesScheduledViewingLeadTime
              }
              tenantedPropertiesScheduledViewingLeadTimeOptions={
                tenantedPropertiesScheduledViewingLeadTimeOptions
              }
              updateTenantedPropertiesScheduledViewingLeadTime={(val) =>
                this.updateTenantedPropertiesScheduledViewingLeadTime(val)
              }
              scheduleDaysOptions={[
                ViewingSchedule7Days,
                ViewingSchedule10Days,
                ViewingSchedule14Days,
              ]}
              scheduleDays={scheduleDays}
              updateScheduleDays={this.updateScheduleDaysOption}
              isActionDisabled={isActionDisabled}
              isAccountLite={isAccountLite}
            />
            <div
              className={`availability-container ${
                !isFlexibleTimePicked && 'disable-content'
              } mb40`}
            >
              <Text as="h3" fontSize={theme.fontSizes[7] + 'px'} mt={8}>
                Blocked Dates
              </Text>
              <p
                className={`description ${
                  !isFlexibleTimePicked && 'disable-content'
                } mt0`}
              >
                Block viewings being scheduled for future dates eg. school and
                public holidays.
              </p>

              <PropertyExcludedTimes
                currentExcludedDates={currentBlockedDates}
                onEntriesChanged={(entries) =>
                  this.onBlockedDatesChanges(entries)
                }
                showAllDatesLabelWhenEmpty={false}
                disabled={isActionDisabled}
              />
            </div>
            <div
              className={`availability-container ${
                !isFlexibleTimePicked && 'disable-content'
              } mb40`}
            >
              <Text as="h3" fontSize={theme.fontSizes[7] + 'px'} mt={8}>
                Excluded Properties
              </Text>
              <p
                className={`description ${
                  !isFlexibleTimePicked && 'disable-content'
                } mt0`}
              >
                Search and add properties that will not display the viewing
                schedule.
              </p>
              <ContactPropertiesContainer
                properties={excludedPropertyCollection}
                onRemoveButtonClick={this.onRemoveProperty(
                  typeExcludedProperties,
                )}
                isPropertyAttached={this.isPropertyAttached}
                title="Active exclusions"
                noPropertyText="NO PROPERTIES EXCLUDED"
                isFlexibleTimePicked={isFlexibleTimePicked}
                onExcludedPropertyDateEntriesChanged={(property, payload) => {
                  this.onExcludedPropertyDateEntriesChanged(property, payload)
                }}
                disabled={isActionDisabled}
              />
              {!isActionDisabled && (
                <div
                  className={`${
                    !isFlexibleTimePicked && 'disable-content'
                  } mb20 pt25`}
                >
                  Search and select property
                </div>
              )}
              <div
                className={`${!isFlexibleTimePicked && 'disable-content'} ${
                  isActionDisabled && 'disable-content'
                } mt10`}
              >
                {!isActionDisabled && (
                  <PropertySearchAndDropDown
                    placeholder="Search street name or suburb"
                    searchText={searchText}
                    onSearchChangeForEnquirer={this.onSearchChange}
                    propertySearchResults={propertySearchResults}
                    hideResults={false}
                    isFlexibleTimePicked={isFlexibleTimePicked}
                  />
                )}
                {selectedPropertyIdError && (
                  <div
                    className={`height30 mb10 ${
                      !isFlexibleTimePicked && 'disable-content'
                    }`}
                  >
                    <div
                      className={`invisible standard-error-box ${
                        !isFlexibleTimePicked && 'disable-content'
                      }`}
                    >
                      This field cannot be empty
                    </div>
                  </div>
                )}
              </div>
              {searchSpinner ? (
                <Spinner />
              ) : (
                <SearchedPropertyResults
                  propertySearchResults={propertySearchResults}
                  onAddButtonClick={this.onAddSearchedProperty}
                  hideResults={false}
                  isPropertyAttached={this.isPropertyAttached}
                  category={typeExcludedProperties}
                  isFlexibleTimePicked={isFlexibleTimePicked}
                />
              )}
            </div>
          </div>
        )}

        <Flex mb={5} mt={8} alignItems="center">
          <Text as="h4" fontSize={theme.fontSizes[8] + 'px'}>
            Productivity Bundle
          </Text>
          {!isAccountTypeProductivityOrHigher && (
            <Box ml={6}>
              <UpgradeCTA availableOn="Productivity Plan" alignRight={false} />
            </Box>
          )}
        </Flex>
        <>
          <div>
            <ReactTooltip id="enableOptimiseTravelTimeTooltip">
              <span>
                Automatically block travel time from viewing schedule for an
                inspector
              </span>
            </ReactTooltip>
          </div>
          <Form.CheckBoxGeneral
            id="enableOptimiseTravelTime"
            label="Optimise schedule for travel time"
            checked={optimiseTravelTime}
            onChange={this.onCheckBoxChanged('optimiseTravelTime')}
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="enableOptimiseTravelTimeTooltip"
                />
              </div>
            }
            componentClassName="mt10"
            eventProcessedByComponent
            disabled={
              !isUserAdmin || !isTravelTimeOptimisationEnabled(currentTeam)
            }
            showDisabledGrey={
              !isUserAdmin || !isTravelTimeOptimisationEnabled(currentTeam)
            }
          />
        </>
        <div>
          <ReactTooltip id="automaticallyScheduleNeighbouringProperties">
            <span>
              Automatically schedule viewing times for properties in the same
              zone and selected radius.
            </span>
          </ReactTooltip>
        </div>
        <>
          <Form.CheckBoxGeneral
            label="Automatically schedule neighbouring properties"
            checked={viewingBookNearbyPropertiesSelected}
            onChange={this.onViewingBookNearbyPropertiesChanged}
            componentClassName="mt10"
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="automaticallyScheduleNeighbouringProperties"
                />
              </div>
            }
            disabled={!isUserAdmin || !viewingBookNearbyProperties}
            showDisabledGrey={!isUserAdmin || !viewingBookNearbyProperties}
          />
          {viewingBookNearbyPropertiesSelected && (
            <>
              <Form.Dropdown
                label="Please select the default viewing duration"
                value={viewingBookNearbyPropertiesProximityOption}
                options={PropertiesProximity}
                onChange={this.onFormUpdate(
                  'viewingBookNearbyPropertiesProximityOption',
                )}
                extraOptionText="m"
                selectIsDisabled={!isUserAdmin || !viewingBookNearbyProperties}
              />
            </>
          )}
        </>
        <Form.CheckBoxGeneral
          id="sendViewingReportSMS"
          label="Automatically send SMS Viewing Report to Owner"
          checked={sendViewingReportSMS}
          onChange={this.onAutomaticallySendSMSViewingReportCheckboxClicked}
          componentClassName="mt10"
          eventProcessedByComponent
          disabled={isActionDisabled}
          showDisabledGrey={isActionDisabled}
          toolTip={
            <div>
              <i
                className="icon-help-outline fs16 gray-light-color ibm"
                data-tooltip-content=""
                data-tooltip-id="sendViewingReportSMS"
              />
            </div>
          }
        />
        <div>
          <ReactTooltip id="sendViewingReportSMS">
            <span>{SMS_REPORT_HELP_TEXT}</span>
          </ReactTooltip>
        </div>
        <Form.CheckBoxGeneral
          id="sendViewingReportEmail"
          label="Automatically send Email Viewing Report to Owner"
          checked={sendViewingReportEmail}
          onChange={this.onAutomaticallySendEmailViewingReportCheckboxClicked}
          toolTip={
            <div>
              <i
                className="icon-help-outline fs16 gray-light-color ibm"
                data-tooltip-content=""
                data-tooltip-id="sendViewingReportEmail"
              />
            </div>
          }
          componentClassName="mt10"
          eventProcessedByComponent
          disabled={isActionDisabled}
          showDisabledGrey={isActionDisabled}
        />
        <div>
          <ReactTooltip id="sendViewingReportEmail">
            <span>
              Dispatches at 8-9am on selected day(s) for prior day viewings.
            </span>
          </ReactTooltip>
        </div>
        {sendViewingReportEmail && (
          <div className="display-flex pl30 pt10 pb10 weekdaySelector">
            {weekDaySelectorArrayForEmailReport.map((day, index) => {
              return (
                <span
                  onClick={() =>
                    this.onWeekdayClickedForViewingReport(day.name)
                  }
                  className={`weekday pl5 pr5 ${
                    viewingReportAvailability &&
                    viewingReportAvailability[day.name]
                      ? 'chosen'
                      : 'not-chosen'
                  }`}
                  key={index}
                >
                  {day.text}
                </span>
              )
            })}
          </div>
        )}
        {!isActionDisabled && (
          <GeneralBottomBtns
            onBackButtonClicked={this.onBackButtonClicked}
            onConfirmButtonClicked={this.onConfirmButtonClicked}
            confirmBtnText={'Update'}
            disableConfirmBtn={disableConfirmBtn}
          />
        )}
      </div>
    )
  }

  renderApplicationTab = () => {
    const {
      applicationCapEnabled,
      applicationCapOption = 0,
      applicationLimitPerApplicantEnabled,
      applicationLimitPerApplicant = applicationLimitPerApplicantOptions.indexOf(
        applicationLimitPerApplicantDefault,
      ),
      disableConfirmBtn,
      identityCapEnabled,
      identityCapEnabledOptions,
      enablePreApply,
      disableOccupantsEnabled,
      bankStatementRequire,
      enableEnhancedDisclosure,
      enableNewManagementBDMLead,
      enableRentvestorBDMLead,
      enableInternalShortlisting,
      filterIncompleteHouseholdApplications = false,
      bdmLeadEmail = '',
      bdmLeadStates = [],
      validateEmailErr = '',
      viewingCheckinRequire,
      payslipsRequire,
      payslipsRequireNumber,
      payslipsRequiredDurationEnabled,
      payslipsMinDuration = payslipsMinWeeksRequiredOptions.indexOf(
        applicationLimitPerApplicantDefault,
      ),
      studentAttachmentsRequired,
      requireAddressHistory,
      rentFrequency,
      waterInclusion = 0,
      operationsEmail = '',
      validateOperationsEmailErr = '',
      firstPaymentDefault = 1,
      leaseLengthDefault = 1,
      disableRemainingBalance = false,
      roundPaymentsDownNearestDollar = false,
      alwaysCopyOperationEmail = false,
      operationEmailAsLeasingContact = false,
      isAccountLite,
      [applicationsAttributesIDs.compactWorkflowEnabled]:
        compactWorkflowEnabled = false,
      agencyGUID,
      isUserAdmin,
      allowInternationalMobiles,
      enableApplicationBallots,
      applicationSuccessfulBallotsSize = applicationSuccessfulBallotsSizeOptions.indexOf(
        applicationSuccessfulBallotsSizeDefault,
      ),
      applicationReserveBallotsSize = applicationReserveBallotsSizeOptions.indexOf(
        applicationReserveBallotsSizeDefault,
      ),
      idDocsNotRequested = false,
      applicationNotifyListingAgents,
      excludeRequestReasonForMoving,
      excludeRequestVehicleRegistration,
      bankStatementRequireTypeOptions,
      bankStatementRequireRecentTwo,
      requestCarSpace,
      requireCurrentEmploymentOnly,
    } = this.state
    const isActionDisabled = isAccountLite || !isUserAdmin
    const { teamSlug = '' } = this.props.match.params

    return (
      <>
        <div className="mb15">
          <Text as="h1" mb={4} mt={0} fontSize={theme.fontSizes[12] + 'px'}>
            Applications
          </Text>
          <Box mb={8} fontSize={5}>
            Customise application settings for your team. Explore plans at{' '}
            <a
              href="https://snug.com/pricing/"
              target="_blank"
              rel="noreferrer"
            >
              snug.com/pricing
            </a>
          </Box>
          {!isUserAdmin && (
            <UserNotAuthorizedShowTeamMembersList teamSlug={teamSlug} />
          )}
          <Text as="h4" fontSize={theme.fontSizes[8] + 'px'} mb={5} mt={8}>
            Free Forever Plan
          </Text>
          <Form.CheckBoxGeneral
            label="Limit applications per property"
            checked={applicationCapEnabled}
            onChange={this.onApplicationCapCheckboxClicked}
            eventProcessedByComponent
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="applicationCapInfo"
                />
              </div>
            }
            disabled={!isUserAdmin}
            showDisabledGrey={isActionDisabled}
          />
          <div>
            <ReactTooltip id="applicationCapInfo">
              <span>
                Limit the number of applications that can be submitted for
                properties
              </span>
            </ReactTooltip>
          </div>
          {!!applicationCapEnabled && (
            <ApplicationCap
              applicationCapOption={applicationCapOption}
              applicationCapNumbers={applicationCapNumbers}
              onApplicationCapOptionChanged={this.onFormUpdate(
                'applicationCapOption',
              )}
              disabled={isActionDisabled}
            />
          )}
        </div>
        <Box mb={`${theme.space[5]}px`}>
          <Form.CheckBoxGeneral
            label="Limit applications per primary applicant"
            checked={applicationLimitPerApplicantEnabled}
            onChange={this.onCheckBoxChanged(
              'applicationLimitPerApplicantEnabled',
            )}
            eventProcessedByComponent
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="applicationLimitPerApplicantInfo"
                />
              </div>
            }
            disabled={!isUserAdmin}
            showDisabledGrey={isActionDisabled}
          />
          <div>
            <ReactTooltip id="applicationLimitPerApplicantInfo">
              <span>
                The number of active, open applications for primary applicant
                for live properties.
              </span>
            </ReactTooltip>
          </div>
          {!!applicationLimitPerApplicantEnabled && (
            <ApplicationCap
              applicationCapOption={applicationLimitPerApplicant}
              applicationCapNumbers={applicationLimitPerApplicantOptions}
              onApplicationCapOptionChanged={this.onFormUpdate(
                'applicationLimitPerApplicant',
              )}
              disabled={isActionDisabled}
            />
          )}
        </Box>
        <div className="mb15">
          <Form.CheckBoxGeneral
            label="Require 2 years of address history"
            onChange={this.onRequireAddressHistoryCheckboxClicked}
            checked={requireAddressHistory}
            eventProcessedByComponent
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="twoYearsHistoryInfo"
                />
              </div>
            }
            disabled={!isUserAdmin}
            showDisabledGrey={isActionDisabled}
          />
          <div>
            <ReactTooltip id="twoYearsHistoryInfo">
              <span>
                Require 2 years of uninterrupted address history (Rented, Owned,
                etc) up to the <br /> date of the application.
              </span>
            </ReactTooltip>
          </div>
        </div>
        <CheckboxAndSelect
          checkboxLabel="Require"
          checkboxOnChange={this.onIdentityCapCheckboxClicked}
          disabled={!isUserAdmin}
          showDisabledGrey={isActionDisabled}
          checkboxChecked={identityCapEnabled}
          dropdownOptions={identityCapEnabledNumberOptions}
          dropdownValue={identityCapEnabledOptions}
          dropdownOnChange={this.onFormUpdate('identityCapEnabledOptions')}
          dropdownLabel="Please select the number of points required"
          toolTipID={'requireIdentityTooltip'}
          toolTipContent={
            <span>
              Require identity documents when applying. <br />A Primary ID is a
              Driver Licence or Passport.
            </span>
          }
        />
        <CheckboxAndSelect
          checkboxLabel="Require"
          checkboxOnChange={this.onRequirePayslipsCheckboxClicked}
          disabled={!isUserAdmin}
          showDisabledGrey={isActionDisabled}
          checkboxChecked={payslipsRequire}
          dropdownOptions={payslipsRequiredNumberOptions}
          dropdownValue={payslipsRequireNumber}
          dropdownExtraOptionText="Payslips"
          dropdownOnChange={this.onFormUpdate('payslipsRequireNumber')}
          dropdownLabel="Please select the number of payslips required"
          toolTipID={'requirePayslipsToolTip'}
          toolTipContent={
            <span>
              Require payslips when applying and applicant selects "Employed"
              status. <br /> In South Australia, applicants can choose an
              employment confirmation or payslips.
            </span>
          }
        />
        <Box mb={5}>
          <Form.CheckBoxGeneral
            label="Require payslips for time period"
            checked={payslipsRequiredDurationEnabled}
            onChange={this.onRequirePayslipsDurationCheckboxClicked}
            eventProcessedByComponent
            disabled={isActionDisabled}
            showDisabledGrey={isActionDisabled}
          />
          {!!payslipsRequiredDurationEnabled && (
            <Form.Dropdown
              label="Number of weeks"
              value={payslipsMinDuration}
              options={payslipsMinWeeksRequiredOptions}
              onChange={this.onFormUpdate('payslipsMinDuration')}
              extraOptionText="weeks"
            />
          )}
        </Box>
        <Form.CheckBoxGeneral
          label="Require current employment only"
          onChange={this.onRequireCurrentEmploymentOnly}
          checked={requireCurrentEmploymentOnly}
          toolTip={
            <div>
              <i
                className="icon-help-outline fs16 gray-light-color ibm"
                data-tooltip-content=""
                data-tooltip-id="requireCurrentEmploymentOnly"
              />
            </div>
          }
          disabled={!isUserAdmin}
          eventProcessedByComponent
          showDisabledGrey={isActionDisabled}
        />
        <div>
          <ReactTooltip id="requireCurrentEmploymentOnly">
            <span>Request current or most recent employment.</span>
          </ReactTooltip>
        </div>

        <div className="mb15">
          <CheckboxAndSelect
            checkboxLabel="Require"
            checkboxOnChange={this.onRequireBankStatementCheckboxClicked}
            disabled={!isUserAdmin}
            showDisabledGrey={isActionDisabled}
            checkboxChecked={bankStatementRequire}
            dropdownOptions={requireBankStatementOptions}
            dropdownValue={bankStatementRequireTypeOptions}
            dropdownOnChange={this.onFormUpdate(
              'bankStatementRequireTypeOptions',
            )}
          />
          {bankStatementRequire && (
            <Form.CheckBoxGeneral
              label="Require two most recent Bank Statements"
              onChange={this.onRequireMostRecent2BankStatements}
              checked={bankStatementRequireRecentTwo}
              eventProcessedByComponent
              disabled={!isUserAdmin}
              showDisabledGrey={isActionDisabled}
            />
          )}
        </div>
        <div className="mb15">
          <Form.CheckBoxGeneral
            label={
              <>
                Require Enhanced Disclosures on applications{' '}
                <a
                  target="_blank"
                  href="https://help.snug.com/hc/en-us/articles/5183474773263"
                  rel="noopener noreferrer"
                >
                  learn more
                </a>
              </>
            }
            onChange={this.onRequireEnhancedDisclosuresClicked}
            checked={enableEnhancedDisclosure}
            eventProcessedByComponent
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="enableEnhancedDisclosure"
                />
              </div>
            }
            disabled={!isUserAdmin}
            showDisabledGrey={isActionDisabled}
          />
          <div>
            <ReactTooltip id="enableEnhancedDisclosure">
              <span>Request extra disclosures before applying</span>
            </ReactTooltip>
          </div>
        </div>
        <div className="mb15">
          <Form.CheckBoxGeneral
            label={textHelpers.REQUIRE_VIEWING_BEFORE_APPLY_PREFERENCE}
            onChange={this.onRequireViewingCheckinCheckboxClicked}
            checked={viewingCheckinRequire}
            eventProcessedByComponent
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id={
                    textHelpers.REQUIRE_VIEWING_BEFORE_APPLY_PREFERENCE_TOOLTIP_ID
                  }
                />
              </div>
            }
            disabled={!isUserAdmin}
            showDisabledGrey={isActionDisabled}
          />
          <div>
            <StyledReactTooltip
              id={
                textHelpers.REQUIRE_VIEWING_BEFORE_APPLY_PREFERENCE_TOOLTIP_ID
              }
              width="325px"
            >
              {
                textHelpers.REQUIRE_VIEWING_BEFORE_APPLY_PREFERENCE_TOOLTIP_CONTENT
              }
            </StyledReactTooltip>
          </div>
        </div>
        <div className="mb15">
          <Form.CheckBoxGeneral
            label="Require all Adults to be applicants, not occupants"
            onChange={this.onDisableOccupantsCheckboxClicked}
            checked={disableOccupantsEnabled}
            eventProcessedByComponent
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="disableOccupantsInfo"
                />
              </div>
            }
            disabled={!isUserAdmin}
            showDisabledGrey={isActionDisabled}
          />
          <div>
            <ReactTooltip id="disableOccupantsInfo">
              <span>Require all adults to complete applications.</span>
            </ReactTooltip>
          </div>
        </div>
        <div className="mb15">
          <Form.CheckBoxGeneral
            label="Shortlist internal (blue star), without notification to applicant"
            onChange={(e) => this.onEnableInternalShortlistingClicked(e)}
            checked={enableInternalShortlisting}
            eventProcessedByComponent
            disabled={!isUserAdmin}
            showDisabledGrey={isActionDisabled}
          />
          <div>
            <ReactTooltip id="enableCompactWorkflowTooltip">
              <span>Show a compact workflow with the most common statuses</span>
            </ReactTooltip>
          </div>
        </div>
        <div className="mb15">
          <Form.CheckBoxGeneral
            id={applicationsAttributesIDs.compactWorkflowEnabled}
            label="Use Compact Application Workflow"
            checked={compactWorkflowEnabled}
            onChange={this.onCheckBoxChanged(
              applicationsAttributesIDs.compactWorkflowEnabled,
            )}
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="enableCompactWorkflowTooltip"
                />
              </div>
            }
            componentClassName="mt10"
            eventProcessedByComponent
            disabled={!isUserAdmin}
            showDisabledGrey={isActionDisabled}
          />
        </div>
        <div className="mb15">
          <Form.CheckBoxGeneral
            label="Enable Sighted ID"
            onChange={this.onCheckBoxChanged('idDocsNotRequested')}
            checked={idDocsNotRequested}
            eventProcessedByComponent
            disabled={!isUserAdmin}
            showDisabledGrey={isActionDisabled}
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="idDocsNotRequested"
                />
              </div>
            }
          />
          <div>
            <ReactTooltip id="idDocsNotRequested">
              <span>
                Online ID docs not requested online. Documents to be sighted in
                person.
              </span>
            </ReactTooltip>
          </div>
        </div>
        <Form.CheckBoxGeneral
          label="Email notify property listing agents for new applications"
          onChange={this.onCheckBoxChanged('applicationNotifyListingAgents')}
          checked={applicationNotifyListingAgents}
          eventProcessedByComponent
          disabled={!isUserAdmin}
          showDisabledGrey={isActionDisabled}
        />
        {requestCarSpace && (
          <div className="mb15">
            <Form.CheckBoxGeneral
              label="Do not request vehicle registration"
              onChange={this.onCheckBoxChanged(
                'excludeRequestVehicleRegistration',
              )}
              checked={excludeRequestVehicleRegistration}
              eventProcessedByComponent
              disabled={!isUserAdmin}
              showDisabledGrey={isActionDisabled}
            />
          </div>
        )}
        <div className="mb15">
          <Form.CheckBoxGeneral
            label="Do not request reason for moving"
            onChange={this.onCheckBoxChanged('excludeRequestReasonForMoving')}
            checked={excludeRequestReasonForMoving}
            eventProcessedByComponent
            showDisabledGrey={isActionDisabled}
            disabled={!isUserAdmin}
          />
        </div>
        <>
          <Flex mb={5} mt={8} alignItems="center">
            <Text as="h4" fontSize={theme.fontSizes[8] + 'px'}>
              Growth Plan
            </Text>
            {isAccountLite && (
              <Box ml={6}>
                <UpgradeCTA alignRight={false} />
              </Box>
            )}
          </Flex>
          <Form.CheckBoxGeneral
            label={textHelpers.PRE_APPLY_PREFERENCE}
            onChange={this.onEnablePreApplyCheckboxClicked}
            checked={enablePreApply}
            eventProcessedByComponent
            disabled={isActionDisabled}
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id={textHelpers.PRE_APPLY_PREFERENCE_TOOLTIP_ID}
                />
              </div>
            }
            showDisabledGrey={isActionDisabled}
          />
          <div>
            <ReactTooltip id={textHelpers.PRE_APPLY_PREFERENCE_TOOLTIP_ID}>
              {textHelpers.PRE_APPLY_PREFERENCE_TOOLTIP_CONTENT}
            </ReactTooltip>
          </div>
          <div className="mb15">
            <Form.CheckBoxGeneral
              label="Require Student letter of offer or ID card"
              onChange={this.onRequireStudentAttachmentsClicked}
              checked={studentAttachmentsRequired}
              eventProcessedByComponent
              disabled={isActionDisabled}
              showDisabledGrey={isActionDisabled}
            />
          </div>
          <div className="mb15">
            <Form.CheckBoxGeneral
              label="Filter partial household applications"
              onChange={this.onFilterIncompleteHouseholdApplications}
              checked={filterIncompleteHouseholdApplications}
              eventProcessedByComponent
              disabled={isActionDisabled}
              showDisabledGrey={isActionDisabled}
            />
          </div>
          <div className="mb15">
            <Form.CheckBoxGeneral
              label="Deliver self-managing owner leads"
              onChange={(e) =>
                this.onEnableBDMLeadCheckboxClicked(
                  e,
                  'enableNewManagementBDMLead',
                )
              }
              checked={enableNewManagementBDMLead}
              eventProcessedByComponent
              disabled={isActionDisabled}
              showDisabledGrey={isActionDisabled}
              toolTip={
                <div>
                  <i
                    className="icon-help-outline fs16 gray-light-color ibm"
                    data-tooltip-content=""
                    data-tooltip-id="self-managing-owner-leads"
                  />
                </div>
              }
            />
          </div>

          <div>
            <ReactTooltip id="self-managing-owner-leads">
              <Box maxWidth="240px" textAlign="center">
                Self-managing owners from Rental References. Growth plans can
                deliver to email. Premier plans can deliver to Messages inbox.
              </Box>
            </ReactTooltip>
          </div>
          <div className="mb15">
            <Form.CheckBoxGeneral
              label="Deliver property investor leads"
              onChange={(e) =>
                this.onEnableBDMLeadCheckboxClicked(
                  e,
                  'enableRentvestorBDMLead',
                )
              }
              checked={enableRentvestorBDMLead}
              eventProcessedByComponent
              disabled={isActionDisabled}
              showDisabledGrey={isActionDisabled}
              toolTip={
                <div>
                  <i
                    className="icon-help-outline fs16 gray-light-color ibm"
                    data-tooltip-content=""
                    data-tooltip-id="rentvestor-leads"
                  />
                </div>
              }
            />
          </div>

          <div>
            <ReactTooltip id="rentvestor-leads">
              <Box maxWidth="240px" textAlign="center">
                Rentvestor applicants. Growth plans can deliver to email.
                Premier plans can deliver to Messages inbox.
              </Box>
            </ReactTooltip>
          </div>
          {(!!enableNewManagementBDMLead || !!enableRentvestorBDMLead) && (
            <Flex mb={5}>
              <StyledInput>
                <Input
                  error={!!validateEmailErr.length}
                  inputProps={{
                    placeholder: 'e.g. leads@example.com',
                    onChange: (e) => this.onBDMEmailValueChange(e),
                    value: bdmLeadEmail,
                  }}
                />
              </StyledInput>

              <StatesDropdown
                onStatesChanged={(states) => this.onBDMStatesChange(states)}
                initStates={bdmLeadStates}
              />
            </Flex>
          )}
          <Box mb={`${theme.dividedSpace[3]}px`}>
            <Form.CheckBoxGeneral
              label="Allow International Mobiles"
              onChange={this.onCheckBoxChanged('allowInternationalMobiles')}
              checked={allowInternationalMobiles}
              eventProcessedByComponent
              disabled={isActionDisabled}
              showDisabledGrey={isActionDisabled}
            />
          </Box>
          {Features.isShowBallotsEnabledForTeam(teamSlug) && (
            <>
              <Form.CheckBoxGeneral
                label="Enable application ballots"
                onChange={this.onCheckBoxChanged('enableApplicationBallots')}
                checked={enableApplicationBallots}
                eventProcessedByComponent
                disabled={isActionDisabled}
                showDisabledGrey={isActionDisabled}
                toolTip={
                  <div>
                    <i
                      className="icon-help-outline fs16 gray-light-color ibm"
                      data-tooltip-content=""
                      data-tooltip-id="enableApplicationBallots"
                    />
                  </div>
                }
              />

              <div>
                <ReactTooltip id="enableApplicationBallots">
                  <span>
                    Enable running random ballot <br /> application selection
                    for <br />
                    properties activated in On-list
                  </span>
                </ReactTooltip>
              </div>
              {!!enableApplicationBallots && (
                <div>
                  <Text mt={3} mb={3}>
                    Allow activating random ballot processing for properties in
                    Onlist.{' '}
                    <a
                      href="https://help.snug.com/hc/en-us/articles/6059655033103"
                      target="_blank"
                      rel="noreferrer"
                    >
                      learn more
                    </a>
                  </Text>
                  <Text mb={0} fontWeight={theme.fontWeights[6]}>
                    Successful set size
                  </Text>
                  <Form.Dropdown
                    label="Successful set size"
                    value={applicationSuccessfulBallotsSize}
                    options={applicationSuccessfulBallotsSizeOptions}
                    onChange={this.onFormUpdate(
                      'applicationSuccessfulBallotsSize',
                    )}
                    extraOptionText="applications"
                  />
                  <Text mb={0} fontWeight={theme.fontWeights[6]}>
                    Reserve set size
                  </Text>
                  <Form.Dropdown
                    label="Reserve set size"
                    value={applicationReserveBallotsSize}
                    options={applicationReserveBallotsSizeOptions}
                    onChange={this.onFormUpdate(
                      'applicationReserveBallotsSize',
                    )}
                    extraOptionText="applications"
                  />
                </div>
              )}
            </>
          )}
        </>
        <>
          <TextComp.Stronger
            text="Lease Offer"
            textClass="fs20"
            componentClass="mt30"
          />
          <div className="mb20">
            <Form.CheckBoxGeneral
              label="Disable Remaining balance in Lease Offer"
              onChange={this.onDisableRemainingBalanceCheckboxClicked}
              checked={disableRemainingBalance}
              eventProcessedByComponent
              disabled={isActionDisabled}
              showDisabledGrey={isActionDisabled}
            />
          </div>
          <Box my="15px">
            <Box style={{ fontWeight: '500' }}>Rent Frequency</Box>
            <Box>
              <Form.Dropdown
                label="Rent Frequency"
                value={rentFrequency}
                options={rentFrequencyOptions}
                onChange={this.handleRentFrequencyChange}
                useLabelValueOptions
                selectClassName="w300"
                selectIsDisabled={isActionDisabled}
              />
            </Box>
          </Box>
          <div className="mb15 mt10">
            <TextComp.Normal
              text="First Payment default"
              textClass="fs18 color-unchecked fw500"
            />
            <Form.Dropdown
              frameLabelClass="lease-attachment-setby-dropdown-label"
              componentClass="lease-attachment-setby-dropdown"
              value={firstPaymentDefault}
              options={leaseOfferHelpers.leaseDetailsRentFrequency}
              onChange={this.onFormUpdate('firstPaymentDefault')}
              id="firstPaymentDefault"
              idAsValue={true}
              selectClassName="w300"
              selectIsDisabled={isActionDisabled}
            />
          </div>
          <div className="mb15 mt10">
            <TextComp.Normal
              text="Lease length default"
              textClass="fs18 color-unchecked fw500"
            />
            <Form.Dropdown
              frameLabelClass="lease-attachment-setby-dropdown-label"
              componentClass="lease-attachment-setby-dropdown"
              value={leaseLengthDefault}
              options={leaseOfferHelpers.leaseLengthOptions}
              onChange={this.onFormUpdate('leaseLengthDefault')}
              id="leaseLengthDefault"
              idAsValue={true}
              selectClassName="w300"
              selectIsDisabled={isActionDisabled}
            />
          </div>
          <div className="mb15">
            <TextComp.Normal
              text="Water usage charges"
              textClass="fs18 color-unchecked fw500"
            />

            <Form.Dropdown
              frameLabelClass="lease-attachment-setby-dropdown-label"
              componentClass="lease-attachment-setby-dropdown"
              value={waterInclusion}
              options={leaseOfferHelpers.waterInclusionOptions}
              onChange={this.onFormUpdate('waterInclusion')}
              id="waterInclusion"
              idAsValue={true}
              selectClassName="w300"
              selectIsDisabled={isActionDisabled}
            />
          </div>
          <div className="mb20">
            <Form.CheckBoxGeneral
              label="Round payments down to the nearest dollar"
              onChange={this.onRoundPaymentsDownNearestDollarClicked}
              checked={roundPaymentsDownNearestDollar}
              eventProcessedByComponent
              disabled={isActionDisabled}
              showDisabledGrey={isActionDisabled}
            />
          </div>
          <div className="mb15">
            <Flex alignItems="center">
              <TextComp.Normal
                text="Lease operations email"
                textClass="fs18 color-unchecked fw500"
              />
              <Box ml={3}>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="operationsEmail"
                />
              </Box>
            </Flex>

            <div className="ml5">
              <ReactTooltip id="operationsEmail">
                <span>
                  Default email address that will be sent a copy when sending
                  Lease Offer emails
                </span>
              </ReactTooltip>
            </div>
            <div className="mb20">
              <Form.InputEmail
                frameLabelClass="lease-attachment-setby-dropdown-label"
                componentClass="lease-attachment-setby-dropdown"
                inputClass="w300"
                value={operationsEmail}
                placeholder="e.g. operations@example.com"
                options={leaseOfferHelpers.waterInclusionOptions}
                onChange={(e) => this.onOperationEmailValueChange(e)}
                error={validateOperationsEmailErr}
                id="operationsEmail"
                idAsValue={true}
                isOptional
              />
            </div>
            <>
              <div className="mb20">
                <Form.CheckBoxGeneral
                  label="Always copy operations email"
                  onChange={this.onAlwaysCopyOperationEmailClicked}
                  checked={alwaysCopyOperationEmail}
                  eventProcessedByComponent
                  disabled={
                    isActionDisabled ||
                    !(operationsEmail && !validateOperationsEmailErr)
                  }
                  showDisabledGrey={
                    isActionDisabled ||
                    !(operationsEmail && !validateOperationsEmailErr)
                  }
                />
              </div>
              <div className="mb20">
                <Form.CheckBoxGeneral
                  label="Use operations email as Leasing contact"
                  onChange={this.onOperationEmailAsLeasingContactClicked}
                  checked={operationEmailAsLeasingContact}
                  eventProcessedByComponent
                  disabled={
                    isActionDisabled ||
                    !(operationsEmail && !validateOperationsEmailErr)
                  }
                  showDisabledGrey={
                    isActionDisabled ||
                    !(operationsEmail && !validateOperationsEmailErr)
                  }
                />
              </div>{' '}
            </>
          </div>
        </>
        <AutoresponderAttachments
          teamId={agencyGUID}
          disabled={isActionDisabled}
        />
        {isUserAdmin && (
          <div className="mt20">
            <GeneralBottomBtns
              onBackButtonClicked={this.onBackButtonClicked}
              onConfirmButtonClicked={this.onConfirmButtonClicked}
              confirmBtnText={'Update'}
              disableConfirmBtn={disableConfirmBtn}
            />
          </div>
        )}
      </>
    )
  }

  render() {
    const {
      error,
      unsavedChanges,
      selectedTab,
      enquiries_auto_response_enabled,
      viewings_new,
      viewings_updates,
      applications_new,
      applications_updates,
      applications_approved,
      daily_viewings_report,
      send_daily_digest_notification,
      daily_keys_report,
      weekly_digest_report,
      disableConfirmBtn,
      tableSpinner,
      viewingRegistrantNotificationEnabled,
      statusData,
      statusSpinner,
      currentTeam,
      agencyProfile,
      consoleCloudPluginEnabled,
      propertyTreePMSEnabled,
      propertyMePMSEnabled,
      vaultREPMSEnabled,
      isCalendarSubmitted,
      activatingCalendarStage,
      successCalendarStage,
      calendarInfo,
      currentCalendarType,
      showUpgradeAccountModal,
      applicationUpdatedNotificationEnabled = false,
      newlyReceivedApplicationNotificationEnabled = false,
      isUserAdmin,
      autoArchiveProperty,
      autoArchiveLeasedProperties,
      filterPropertiesOnListingAgentEmailEnabled,
      filterPropertiesOnListingAgentEmail,
      filterPropertiesOnListingAgentEmailErr,
      inviteMemberModal = {},
      agencyGUID,
      viewingQRAutoCheckinEnabled,
      viewingQRCheckinEnabled,
      applicationNotifyListingAgents,
      onlist_send_notifications_enabled,
      integrationsBundleIsActive,
    } = this.state

    const { teams, currentUser } = this.props
    const { teamSlug = '' } = this.props.match.params
    const chosenTeam = findChosenTeamBySlug(teams, teamSlug)
    const { name = '', accountType, guid: teamID } = chosenTeam || {}
    let crumbs = []
    let accountTabs = [TabPersonalNotifications, FollowingTab, TabCalendarSync]
    let teamTabs = [
      SELECTED_STATUS_TAB,
      SELECTED_USERS_TAB,
      SELECTED_APPLICATIONS_TAB,
      'Viewings',
      PROPERTY_FEED_TAB,
      SELECTED_QRCHECKIN_TAB,
    ]
    const accountTabsForLite = [TabPersonalNotifications]
    const teamTabsForLite = [
      SELECTED_STATUS_TAB,
      SELECTED_USERS_TAB,
      SELECTED_QRCHECKIN_TAB,
      SELECTED_APPLICATIONS_TAB,
      SELECTED_VIEWINGS_TAB,
    ]
    const { firstName = '' } = currentUser
    const isKolmeoEnabled = Features.isKolmeoEnabled(teamSlug)
    const isManagedAppEnabled = Features.isManagedAppEnabled(teamSlug)

    const integrationTabs = [
      CONSOLE_CLOUD_TAB,
      isKolmeoEnabled && KOLMEO_TAB,
      isManagedAppEnabled && MANAGED_APP_TAB,
      PORTALS_TAB,
      PROPERTY_ME_TAB,
      PROPERTY_TREE_TAB,
      SCREENING_TAB,
      VAULT_RE_TAB,
    ].filter(Boolean)
    const isNotificationTab = selectedTab === TabPersonalNotifications
    const isViewingTab = selectedTab === TabViewings
    const isQRCheckinTabSelected = selectedTab === SELECTED_QRCHECKIN_TAB
    const isConsoleCloudTabSelected = selectedTab === CONSOLE_CLOUD_TAB
    const isPropertyTreePMSSelected = selectedTab === PROPERTY_TREE_TAB
    const isPropertyMePMSSelected = selectedTab === PROPERTY_ME_TAB
    const isVaultREPMSSelected = selectedTab === VAULT_RE_TAB
    const isFeedTabSelected = selectedTab === PROPERTY_FEED_TAB
    const isKolmeoPMSSelected = selectedTab === KOLMEO_TAB
    const isManagedAppPMSSelected = selectedTab === MANAGED_APP_TAB

    const isStatusTabSelected = selectedTab === SELECTED_STATUS_TAB
    const isMembersTabSelected = selectedTab === SELECTED_USERS_TAB
    const isApplicationsTabSelected = selectedTab === SELECTED_APPLICATIONS_TAB
    const isCaldendarSyncTab = selectedTab === TabCalendarSync
    const isSetupTabSelected = selectedTab === SETUP_TAB
    const active = 'active'

    const isPortalsTabSelected = selectedTab === PORTALS_TAB
    const isScreeningTabSelected = selectedTab === SCREENING_TAB

    const viewingTabComponent = this.renderViewingTab()
    const applicationTabComponent = this.renderApplicationTab()

    const enquiryNotificationsSettingsEnabled =
      Features.isEnquiryNotificationsSettingEnabled()
    const viewingNotificationsSettingsEnabled =
      Features.isViewingNotificationsSettingsEnabled(teamSlug)
    const applicationNotificationsSettingsEnabled =
      Features.isApplicationNotificationsSettingsEnabled(teamSlug)
    const reportNotificationsSettingsEnabled =
      Features.isReportNotificationsSettingsEnabled(teamSlug)
    if (name) {
      crumbs = [
        {
          text: name,
          link: urlTo('teamOverview', { teamSlug }),
        },
        {
          text: 'Settings',
          link: urlTo('teamsViewing', { teamSlug }),
        },
        {
          text: selectedTab,
          link: '#',
        },
      ]
    }
    const isAccountLite = accountType === accountHelpers.ACCOUNT_TYPE_LITE
    const isActionDisabled = isAccountLite || !isUserAdmin
    const calendarExpiredAt =
      calendarInfo?.expiredAt &&
      moment(calendarInfo.expiredAt).format(dateTimeHelpers.TIME_DAY_HALF_MONTH)

    return (
      <TwoColumnContainer>
        <div className="two-col-box sm55 pdf-hide">
          <div className="col-first">
            <div className="settings__tabs mb10">
              {Features.isSetupTabEnabled(teamSlug) && isUserAdmin && (
                <TabTitle
                  onClick={() =>
                    history.push(
                      urlTo('teamViewingPreferenceSetup', { teamSlug }),
                    )
                  }
                  style={{ cursor: 'pointer' }}
                >
                  Setup
                </TabTitle>
              )}

              <TabTitle>Personal</TabTitle>
              {accountTabs.map((tab) => {
                const shouldTabClickOpenUpgradeModal =
                  isAccountLite && !accountTabsForLite.includes(tab)

                if (
                  tab === FollowingTab &&
                  !Features.isFollowingEnabled(teamSlug)
                )
                  return null
                return (
                  <SettingTabContainer
                    key={tab}
                    isDisabled={shouldTabClickOpenUpgradeModal}
                    selectedTab={selectedTab}
                    tab={tab}
                    active={active}
                    onTabClicked={() =>
                      shouldTabClickOpenUpgradeModal
                        ? this.onClickDisabledLiteAccountOption()
                        : this.onTabClicked(tab)
                    }
                  />
                )
              })}
              <TabTitle>Team</TabTitle>
              {teamTabs.map((tab) => {
                const shouldTabClickOpenUpgradeModal =
                  isAccountLite && !teamTabsForLite.includes(tab)
                return (
                  <SettingTabContainer
                    key={tab}
                    isDisabled={shouldTabClickOpenUpgradeModal}
                    selectedTab={selectedTab}
                    tab={tab}
                    active={active}
                    onTabClicked={() =>
                      shouldTabClickOpenUpgradeModal
                        ? this.onClickDisabledLiteAccountOption()
                        : this.onTabClicked(tab)
                    }
                  />
                )
              })}
              <TabTitle>Integrations</TabTitle>
              {integrationTabs.map((tab) => {
                return (
                  <SettingTabContainer
                    key={tab}
                    selectedTab={selectedTab}
                    tab={tab}
                    active={active}
                    onTabClicked={() => this.onTabClicked(tab)}
                  />
                )
              })}
            </div>
          </div>
        </div>
        <LeftComponent>
          <div className="mb10 mt30-mobile pdf-hide">
            <Breadcrumbs crumbs={crumbs} />
          </div>
          {isNotificationTab && (
            <div
              className={`sm-property-details__section ${this.classNameFormatForTab(
                accountTabs[0],
              )}`}
              id={this.classNameFormatForTab(accountTabs[0])}
            >
              <div className="preference-header-container">
                <Display.MainHeader text="Notifications" />
              </div>
              {tableSpinner ? (
                <Spinner />
              ) : (
                <CommunicationPreference
                  enquiries_auto_response_enabled={
                    enquiries_auto_response_enabled
                  }
                  onlist_send_notifications_enabled={
                    onlist_send_notifications_enabled
                  }
                  viewings_new={viewings_new}
                  viewings_updates={viewings_updates}
                  applications_new={applications_new}
                  applications_updates={applications_updates}
                  applications_approved={applications_approved}
                  daily_viewings_report={daily_viewings_report}
                  send_daily_digest_notification={
                    send_daily_digest_notification
                  }
                  weekly_digest_report={weekly_digest_report}
                  enquiryNotificationsSettingsEnabled={
                    enquiryNotificationsSettingsEnabled
                  }
                  viewingNotificationsSettingsEnabled={
                    viewingNotificationsSettingsEnabled
                  }
                  applicationNotificationsSettingsEnabled={
                    applicationNotificationsSettingsEnabled
                  }
                  reportNotificationsSettingsEnabled={
                    reportNotificationsSettingsEnabled
                  }
                  onNotificationsBoxChanged={this.onCheckBoxChanged}
                  viewingRegistrantNotificationEnabled={
                    viewingRegistrantNotificationEnabled
                  }
                  onUpdateViewingRegistrantNotificationEnabled={
                    this.onUpdateViewingRegistrantNotificationEnabled
                  }
                  onUpdateApplicationNotificationEnabled={
                    this.onUpdateApplicationNotificationEnabled
                  }
                  applicationUpdatedNotificationEnabled={
                    applicationUpdatedNotificationEnabled
                  }
                  newlyReceivedApplicationNotificationEnabled={
                    newlyReceivedApplicationNotificationEnabled
                  }
                  isUserAdmin={isUserAdmin}
                  daily_keys_report={daily_keys_report}
                />
              )}
              <GeneralBottomBtns
                onBackButtonClicked={this.onBackButtonClicked}
                onConfirmButtonClicked={this.onConfirmButtonClicked}
                confirmBtnText={'Save changes'}
                disableConfirmBtn={disableConfirmBtn}
              />
            </div>
          )}
          {isViewingTab && <>{viewingTabComponent}</>}
          {isApplicationsTabSelected && <>{applicationTabComponent}</>}
          {isStatusTabSelected && (
            <div>
              <div className="display-flex align-items-center">
                <Display.MainHeader
                  hasSeperator={false}
                  text="Status"
                  textClass="p0"
                  componentClass="mb0"
                />
                {statusSpinner && (
                  <span>
                    <i className="fa fa-spinner fa-pulse ml5" />
                  </span>
                )}
              </div>

              <Display.DefaultBodyText>
                <p className="mb0 mt8">
                  Configuration status of your Snug team. Please contact{' '}
                  <a href="mailto:help@snug.com">help@snug.com</a> to request
                  changes.
                </p>
                {chosenTeam && chosenTeam.guid && (
                  <StatusReport
                    statusData={statusData}
                    teamGUID={chosenTeam.guid}
                    teamSlug={teamSlug}
                    fetchAgencyReport={this.fetchAgencyReport}
                    isUserAdmin={isUserAdmin}
                  />
                )}
              </Display.DefaultBodyText>
            </div>
          )}
          {isMembersTabSelected && (
            <TeamMembers
              teamGUID={agencyGUID}
              isAdmin={isUserAdmin}
              invite={inviteMemberModal}
            />
          )}
          {isFeedTabSelected && (
            <FeedPreferences
              autoArchiveProperty={autoArchiveProperty}
              autoArchiveLeasedProperties={autoArchiveLeasedProperties}
              filterPropertiesOnListingAgentEmailEnabled={
                filterPropertiesOnListingAgentEmailEnabled
              }
              filterPropertiesOnListingAgentEmail={
                filterPropertiesOnListingAgentEmail
              }
              onFeedFilterEmailValueChange={this.onFeedFilterEmailValueChange}
              onUpdateCheckbox={this.onCheckBoxChanged}
              disableConfirmBtn={disableConfirmBtn}
              onBackButtonClicked={this.onBackButtonClicked}
              onConfirmButtonClicked={this.onConfirmButtonClicked}
              feedFilterEmailValidationError={
                filterPropertiesOnListingAgentEmailErr
              }
              isActionDisabled={isActionDisabled}
              isUserAdmin={isUserAdmin}
              teamSlug={teamSlug}
            />
          )}
          {isSetupTabSelected && Features.isSetupTabEnabled(teamSlug) && (
            <SetupTab
              teamSlug={teamSlug}
              agencyProfile={agencyProfile}
              agencyGUID={agencyGUID}
              firstName={firstName}
            />
          )}
          {selectedTab === FollowingTab &&
            Features.isFollowingEnabled(teamSlug) && <FollowsSettings />}
          {isQRCheckinTabSelected && (
            <>
              <QRCheckin
                teamslug={currentTeam.slug}
                agencyProfile={agencyProfile}
                updateQRCheckinEnabled={this.updateQRCheckinEnabled}
                viewingQRCheckinEnabled={viewingQRCheckinEnabled}
                viewingQRAutoCheckinEnabled={viewingQRAutoCheckinEnabled}
                updateViewingQRAutoCheckinEnabled={
                  this.updateViewingQRAutoCheckinEnabled
                }
                isActionDisabled={isActionDisabled}
              />

              {!isActionDisabled && (
                <Box className="pdf-hide">
                  <GeneralBottomBtns
                    onBackButtonClicked={this.onBackButtonClicked}
                    onConfirmButtonClicked={this.onConfirmButtonClicked}
                    confirmBtnText={'Update'}
                    disableConfirmBtn={disableConfirmBtn}
                  />
                </Box>
              )}
            </>
          )}
          {isConsoleCloudTabSelected && (
            <AdminAccessWrapper isAdmin={isUserAdmin}>
              <PluginsContainer teamSlug={teamSlug} />
            </AdminAccessWrapper>
          )}
          {isPropertyTreePMSSelected && (
            <PropertyTreeActivityPage
              isAdmin={isUserAdmin}
              integrationsBundleIsActive={integrationsBundleIsActive}
            />
          )}
          {isPropertyMePMSSelected && (
            <PropertyMeActivityPage
              isAdmin={isUserAdmin}
              integrationsBundleIsActive={integrationsBundleIsActive}
            />
          )}
          {isKolmeoPMSSelected && isKolmeoEnabled && (
            <KolmeoActivityPage
              isAdmin={isUserAdmin}
              integrationsBundleIsActive={integrationsBundleIsActive}
            />
          )}
          {isManagedAppPMSSelected && isManagedAppEnabled && (
            <ManagedAppActivityPage
              isAdmin={isUserAdmin}
              integrationsBundleIsActive={integrationsBundleIsActive}
            />
          )}
          {isVaultREPMSSelected && (
            <VaultREActivityPage
              isAdmin={isUserAdmin}
              integrationsBundleIsActive={integrationsBundleIsActive}
            />
          )}
          {isPortalsTabSelected && (
            <AdminAccessWrapper isAdmin={isUserAdmin}>
              <DisplayedTabHeader header="Portals" />
              <TextSpan as="div" mb={8}>
                Configure portal integrations for your team.
              </TextSpan>
              <DomainPortalPage />
            </AdminAccessWrapper>
          )}
          {isScreeningTabSelected && (
            <AdminAccessWrapper isAdmin={isUserAdmin}>
              <DisplayedTabHeader header="Screening" />
              <ScreeningIntegrationsPage />
            </AdminAccessWrapper>
          )}
          {isCaldendarSyncTab && (
            <div
              className={`sm-property-details__section ${this.classNameFormatForTab(
                accountTabs[1],
              )}`}
              id={this.classNameFormatForTab(accountTabs[1])}
            >
              <Header title="Calendar Sync" />

              {tableSpinner ? (
                <Spinner />
              ) : (
                <div>
                  <Form.Dropdown
                    label="Type"
                    frameLabel="Calendar Type"
                    frameLabelClass="mr3 mt-4"
                    componentClass="mb15"
                    value={currentCalendarType}
                    options={calendarTypeWithGuidAndName}
                    onChange={this.onCalendarTypeChange()}
                    id="chosenCalendarType"
                    isRequired={true}
                    idAsValue={true}
                  />
                  <TeamCalendarSync
                    isSubmitted={isCalendarSubmitted}
                    pendingStage={activatingCalendarStage}
                    successStage={successCalendarStage}
                    activateBtnOnClick={() => this.onActivateCaldendarSync()}
                    deactivateBtnOnClick={() => this.onDeactiveCaldendarSync()}
                    updatedAt={moment(calendarInfo.UpdatedAt).format(
                      dateTimeHelpers.DATE_DAY_SHORT_STRING_MONTH_YEAR,
                    )}
                    expiredAt={calendarExpiredAt}
                    startedAt={moment().format(
                      dateTimeHelpers.DATE_DAY_SHORT_STRING_MONTH_YEAR,
                    )}
                    calendarType={
                      calendarTypeWithGuidAndName[parseInt(currentCalendarType)]
                        .name
                    }
                    logoImg={
                      parseInt(currentCalendarType) === 0
                        ? googleCalendarLogo
                        : outlookLogo
                    }
                  />
                </div>
              )}
            </div>
          )}
          {error && <div className="alert alert-danger"> {error} </div>}
        </LeftComponent>
        {unsavedChanges && <UnsavedMessage unsavedChanges={unsavedChanges} />}
        {showUpgradeAccountModal && (
          <SnugUpgradeAccountModal
            isNotToBeEmbedded={false}
            toggleModal={this.onClickDisabledLiteAccountOption}
            typeOfMessage={accountHelpers.UPGRADE_TYPE_SETTINGS}
          />
        )}
      </TwoColumnContainer>
    )
  }
}

export default TeamViewingPreferenceContainer
