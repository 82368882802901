import React, { useContext } from 'react'

import { accountsBaseUrl } from 'config/env'
import styled from 'styled-components'

import { MailOutlineRounded } from 'app/components/design-system-components/icons/communications/index'
import { PhoneIphoneRounded } from 'app/components/design-system-components/icons/hardware/index'
import SvgFacebookRounded from 'app/components/design-system-components/icons/social/FacebookRounded'
import SvgGoogleRounded from 'app/components/design-system-components/icons/social/GoogleRounded'
import {
  Box,
  ButtonWithIcon,
} from 'app/components/design-system-components/index'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  STEP_ENTER_EMAIL,
  STEP_ENTER_MOBILE,
} from 'app/session/join/join-snug.constants'
import { JoinSnugContext } from 'app/session/join/JoinContext'

const StyledJoinButton = styled(ButtonWithIcon)`
  border: 1px solid ${theme.colors.gray400};
  border-radius: 5px;
  margin-bottom: 18px;
  height: 50px;
  width: 100%;
  font-weight: 700;
`

const SocialButtons = () => {
  const { currentStep, setCurrentStep } = useContext(JoinSnugContext)

  const redirectToSocialLogin = (provider) => {
    return () => {
      window.location = accountsBaseUrl + provider
    }
  }

  return (
    <Box>
      <StyledJoinButton
        variant="outline"
        IconCmp={SvgGoogleRounded}
        onClick={redirectToSocialLogin('google')}
      >
        Continue with Google
      </StyledJoinButton>
      <StyledJoinButton
        variant="outline"
        IconCmp={SvgFacebookRounded}
        onClick={redirectToSocialLogin('facebook')}
      >
        Continue with Facebook
      </StyledJoinButton>
      {currentStep === STEP_ENTER_MOBILE && (
        <StyledJoinButton
          variant="outline"
          IconCmp={MailOutlineRounded}
          onClick={() => setCurrentStep(STEP_ENTER_EMAIL)}
        >
          Continue with Email
        </StyledJoinButton>
      )}
      {currentStep === STEP_ENTER_EMAIL && (
        <StyledJoinButton
          variant="outline"
          IconCmp={PhoneIphoneRounded}
          onClick={() => setCurrentStep(STEP_ENTER_MOBILE)}
        >
          Continue with Mobile
        </StyledJoinButton>
      )}
    </Box>
  )
}

export default SocialButtons
