import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'

export const formFields = {
  property: 'property',
  keySet: 'keySet',
  notes: 'notes',
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.property: {
      return {
        required: !val && 'Please select a property address',
      }
    }
    case formFields.keySet: {
      return {
        required: !val && 'Please select a key set',
      }
    }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
