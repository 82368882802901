import React from 'react'

import DateTime from 'react-datetime'
import styled from 'styled-components'

import * as themeGetters from 'app/components/design-system-components/theme/getters'
import { CustomInputWrapper } from 'app/forms/CustomInputWrapper'

const StyledDateTime = styled(DateTime)`
  position: static;
  width: 100%;

  input {
    margin: unset;
    border: unset;
    box-shadow: unset;

    border: 1px solid ${themeGetters.color('grey200')};
    border-radius: ${themeGetters.radius(6)}px;
    padding: ${themeGetters.spacing(4)};

    &:focus {
      border: unset;
      box-shadow: unset;
      border: 1px solid ${themeGetters.color('grey200')};
      border-radius: ${themeGetters.radius(6)}px;
    }
  }

  .rdtPicker {
    width: 250px;
  }
`

export const DateTimeInput = ({
  changeHandler,
  blurHandler,
  value,
  touched,
  error,
  placeholder,
  ...props
}) => {
  return (
    <StyledDateTime
      className="date-time"
      inputProps={{
        placeholder: placeholder,
        autoComplete: 'off',
      }}
      onBlur={blurHandler}
      onChange={changeHandler}
      value={value}
      closeOnSelect
      disableOnClickOutside
      {...props}
    />
  )
}

export const DateTimeField = (props) => {
  const { name } = props

  return (
    <CustomInputWrapper
      name={name}
      customInputCmp={(customInputProps) => (
        <DateTimeInput {...customInputProps} {...props} />
      )}
    />
  )
}
