import { connect } from 'react-redux'

import {
  fetchTeamManagerList,
  fetchTeamProperties,
} from 'app/bond_cover/agency/agency_actions'
import {
  addNewManagerToRun,
  addSelectedPropertyInfos,
  deleteManagerState,
  populateViewingRunData,
  removeManagerFromRun,
  removeSelectedPropertyInfos,
  resetViewingRunState,
  updateManagerPreference,
  updateStartTimeInViewingRun,
} from 'app/pages/teams/viewings-run/action'
import NewViewingsRun from 'app/pages/teams/viewings-run/container'
import { changeTeam } from 'app/session/session_actions'
import { fetchPropertyViewings } from 'app/sm/inspections/inspections_actions'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'
import {
  fetchAllNewViewingRunVersion,
  fetchNewViewingRunVersion,
  formNewViewingRun,
} from 'app/sm/viewings_new_run/action'

const mapStateToProps = ({ session, ViewingRun, NewViewingsRunReducer }) => ({
  teams: session.teams || [],
  selectedTeam: session.currentTeam,
  viewingRun: ViewingRun.viewingRun,
  viewingsRun: NewViewingsRunReducer,
  selectedPropertyInfos: NewViewingsRunReducer.selectedPropertyInfos,
  managerPreferences: NewViewingsRunReducer.managerPreferences,
  allManagersSelected: NewViewingsRunReducer.allManagersSelected,
  startTime: NewViewingsRunReducer.startTime,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTeamManagerList: (agencyGUID) =>
    dispatch(fetchTeamManagerList(agencyGUID)),
  fetchTeamProperties: (agencyGUID, filters) =>
    dispatch(fetchTeamProperties(agencyGUID, filters)),
  fetchPropertyViewings: (params) => dispatch(fetchPropertyViewings(params)),
  formNewViewingRun: (teamGUID, managerPreferences) =>
    dispatch(formNewViewingRun(teamGUID, managerPreferences)),
  addNewManagerToRun: (managerDetails, preferences) =>
    dispatch(addNewManagerToRun(managerDetails, preferences)),
  populateViewingRunData: (runData) =>
    dispatch(populateViewingRunData(runData)),
  resetViewingRunState: () => dispatch(resetViewingRunState()),
  updateManagerPreference: (
    managerGUID,
    managerPreferenceField,
    managerPreferenceValue,
  ) =>
    dispatch(
      updateManagerPreference(
        managerGUID,
        managerPreferenceField,
        managerPreferenceValue,
      ),
    ),
  addSelectedPropertyInfos: (properties) =>
    dispatch(addSelectedPropertyInfos(properties)),
  removeSelectedPropertyInfos: (properties) =>
    dispatch(removeSelectedPropertyInfos(properties)),
  updateStartTimeInViewingRun: (time) =>
    dispatch(updateStartTimeInViewingRun(time)),
  fetchAllNewViewingRunVersion: (teamGUID) =>
    dispatch(fetchAllNewViewingRunVersion(teamGUID)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
  removeManagerFromRun: (managerGUID) =>
    dispatch(removeManagerFromRun(managerGUID)),
  deleteManagerState: () => dispatch(deleteManagerState()),
  fetchNewViewingRunVersion: (teamId, runId) =>
    dispatch(fetchNewViewingRunVersion(teamId, runId)),
  changeCurrentTeam: (team) => dispatch(changeTeam(team)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewViewingsRun)
