import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import LaunchRounded from 'app/components/design-system-components/icons/actions/LaunchRounded'
import AttachMoneyRounded from 'app/components/design-system-components/icons/editor/AttachMoneyRounded'
import PeopleRounded from 'app/components/design-system-components/icons/social/PeopleRounded'
import CheckBoxOutlineBlankRounded from 'app/components/design-system-components/icons/toggle/CheckBoxOutlineBlankRounded'
import CheckBoxRounded from 'app/components/design-system-components/icons/toggle/CheckBoxRounded'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { selectAccountType } from 'app/utils/accounts/helpers'
import { trimAndAddEllipsesPastSpecifiedLength } from 'app/utils/strings/helpers'

const LabelInactive = 'Inactive'
const LabelActive = 'Active'
const LabelNotAvailable = 'N/A'

const AdminAgencySearchResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`

const AdminAgencySearchItemDetailsWrapper = styled.div`
  display: flex;
  padding: ${theme.space[4]}px ${theme.space[6]}px 0 ${theme.space[6]}px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.space[3]}px;
  align-self: stretch;
`

const StyledLine = styled.div`
  height: 0.5px;
  width: 512px;
  background: ${theme.colors.grey200};
`

const StyledAgencyNameRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.space[3]}px;
  align-self: stretch;
  color: ${theme.colors.gray800};
`

const AgencyNameRow = ({ name, suburb }: { name: string; suburb: string }) => {
  const displaySuburb = suburb ? suburb : 'N/A'
  return (
    <StyledAgencyNameRow>
      <Box>{name}</Box> <Box>({displaySuburb})</Box>
    </StyledAgencyNameRow>
  )
}

const StyledAgencyAttribute = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${theme.space[2]}px;
`

const StyledAgencyAttributeRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
`

const StyledIcon = styled.div`
  width: 16px;
  height: 16px;
  color: ${theme.colors.gray300};
`

const StyledAttributeName = styled.div`
  color: ${theme.colors.gray500};
`

const AgencyAttribute = ({
  icon,
  value,
}: {
  icon: 'plan' | 'activation' | 'users' | 'slug'
  value: string
}) => {
  let displayIcon = <div />
  let activeIcon = <CheckBoxRounded />

  if (icon === 'activation' && value === LabelInactive) {
    activeIcon = <CheckBoxOutlineBlankRounded />
  }

  switch (icon) {
    case 'plan': {
      displayIcon = <AttachMoneyRounded />
      break
    }
    case 'activation': {
      displayIcon = activeIcon
      break
    }
    case 'users': {
      displayIcon = <PeopleRounded />
      break
    }
    case 'slug': {
      displayIcon = <LaunchRounded />
      break
    }
  }
  return (
    <div>
      <StyledAgencyAttribute>
        <StyledIcon>{displayIcon}</StyledIcon>
        <StyledAttributeName>{value}</StyledAttributeName>
      </StyledAgencyAttribute>
    </div>
  )
}

const AdminAgencySearchResult = ({
  result,
  key,
  onItemClick,
}: {
  result: {
    accountType: number
    active: boolean
    slug: string
    value: string
    address: {
      suburb: string
    }
    userCount: number
  }
  key: string
  onItemClick: any
}) => {
  const { value: name, address, active, slug, userCount, accountType } = result
  const suburb = address.suburb ? address.suburb : LabelNotAvailable
  const activeStr = active ? LabelActive : LabelInactive
  const accountTypeStr = trimAndAddEllipsesPastSpecifiedLength(
    selectAccountType(accountType),
    20,
  )
  const userCountStr = userCount ? userCount.toString() : LabelNotAvailable
  const slugStr = trimAndAddEllipsesPastSpecifiedLength(slug, 18)

  return (
    <AdminAgencySearchResultWrapper
      key={key}
      onClick={() => onItemClick(result)}
    >
      <AdminAgencySearchItemDetailsWrapper>
        <AgencyNameRow name={name} suburb={suburb} />
        <StyledAgencyAttributeRow>
          <AgencyAttribute icon={'plan'} value={accountTypeStr} />
          <AgencyAttribute icon={'activation'} value={activeStr} />
          <AgencyAttribute icon={'users'} value={userCountStr} />
          <AgencyAttribute icon={'slug'} value={slugStr} />
        </StyledAgencyAttributeRow>
        <StyledLine />
      </AdminAgencySearchItemDetailsWrapper>
    </AdminAgencySearchResultWrapper>
  )
}

export default AdminAgencySearchResult
