import { connect } from 'react-redux'

import AddPetDetails from 'app/profile/renter/pet/pets_details_add_container'
import {
  addPet,
  editPet,
  fetchPet,
  removePet,
  removePetReference,
  uploadPetReference,
} from 'app/sm/pets_details/pets_details_add_actions'

const mapStateToProps = ({ AddPetDetails, session }) => ({
  petList: AddPetDetails.petList,
  pet: AddPetDetails.pet,
  spinner: AddPetDetails.spinner,
  responseText: AddPetDetails.responseText,
  user: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  uploadPetReference: (id) => dispatch(uploadPetReference(id)),
  removePetReference: (id, referenceID) =>
    dispatch(removePetReference(id, referenceID)),
  addPet: (data) => dispatch(addPet(data)),
  removePet: (id) => dispatch(removePet(id)),
  editPet: (data, id) => dispatch(editPet(data, id)),
  fetchPet: (id) => dispatch(fetchPet(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddPetDetails)
