import React, { useState } from 'react'
import { useEffect } from 'react'

import { Box } from 'app/components/design-system-components'
import { SearchRounded } from 'app/components/design-system-components/icons/actions'
import { Input } from 'app/components/design-system-components/inputs'
import { CheckboxesList } from 'app/components/design-system-components/list-filters/CheckboxesList'
import { DropdownBodySectionHeader } from 'app/components/design-system-components/list-filters/DropdownBodySectionHeader'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { useSearchPropertyField } from 'app/utils/hooks/useSearchPropertyField'

export const propertyFiltersFields = {
  property: 'property',
}

export const propertyFilterDataExtractor = (filterValues) => {
  const { [propertyFiltersFields.property]: propertiesObj } = filterValues
  return {
    properties: Object.values(propertiesObj || {}).map(({ option }) => option),
  }
}

export const generatePropertyFilterData = ({ properties }) => {
  return {
    [propertyFiltersFields.property]: properties.reduce(
      (accProperties, { guidID, address }) => {
        return {
          ...accProperties,
          [guidID]: {
            option: { guidID },
            optionMeta: { id: guidID, displayText: address.friendlyName },
          },
        }
      },
      {},
    ),
  }
}

export const PropertyAppliedFilterRenderer = ({ filterValues }) => {
  const { [propertyFiltersFields.property]: property } = filterValues
  return Object.values(property)
    .map((propertyOption) => propertyOption.optionMeta.displayText)
    .join(', ')
}

export const isPropertyFilterApplied = (propertyFilterValues) =>
  propertyFilterValues &&
  propertyFilterValues[propertyFiltersFields.property] &&
  Object.values(propertyFilterValues[propertyFiltersFields.property]).length

export const PropertyFilterBody = ({
  formBag: { values, setFieldValue },
  teamId,
}) => {
  const { onSearchProperties, propertiesOptions, loadingProperties } =
    useSearchPropertyField(teamId)

  const [searchInput, setSearchInput] = useState('')

  const searchProperty = ({ target: { value } }) => {
    setSearchInput(value)
    onSearchProperties(value)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onSearchProperties(''), [])

  const onCheckBoxClicked = (checked, option) => {
    const propertyValues = values[propertyFiltersFields.property]

    if (checked) {
      setFieldValue(propertyFiltersFields.property, {
        ...propertyValues,
        [option.optionMeta.id]: option,
      })
    } else {
      const { [option.optionMeta.id]: uncheckedOption, ...otherOptions } =
        propertyValues
      setFieldValue(propertyFiltersFields.property, {
        ...otherOptions,
      })
    }
  }

  const propertyValues = values[propertyFiltersFields.property] || {}
  const propertyValuesArr = Object.values(propertyValues)

  const selectedOptionsElem = !!propertyValuesArr.length && (
    <>
      <DropdownBodySectionHeader
        header="Selected"
        containerProps={{ mb: theme.space[4] + 'px' }}
      />
      <CheckboxesList
        options={propertyValuesArr}
        checkedOptions={propertyValues}
        onCheckBoxClicked={onCheckBoxClicked}
      />
    </>
  )

  const searchResultOptionsElem = !!propertiesOptions.length && (
    <>
      <DropdownBodySectionHeader
        header="Results"
        containerProps={{ mb: theme.space[4] + 'px' }}
      />
      <CheckboxesList
        options={propertiesOptions}
        checkedOptions={propertyValues}
        onCheckBoxClicked={onCheckBoxClicked}
      />
    </>
  )

  return (
    <>
      <Box p={theme.space[4] + 'px'}>
        <Input
          icon={<SearchRounded style={{ color: theme.colors.gray400 }} />}
          isLoading={loadingProperties}
          inputProps={{
            onChange: searchProperty,
            value: searchInput,
            placeholder: 'Search for a property',
          }}
        />
      </Box>

      {/*{selectedOptionsElem}*/}

      {searchResultOptionsElem}
    </>
  )
}
