import { LOCATION_CHANGE } from 'connected-react-router'

import {
  RECEIVE_DASHBOARD,
  RECEIVE_ERROR,
} from 'app/dashboard/dashboard_actions'

const _defaultState = {
  bondCovers: [],
  invitesToApply: [],
  error: {},
}

const DashboardReducer = (state = _defaultState, action) => {
  let { dashboard = {}, type, error } = action
  Object.freeze(state)
  let newState = Object.assign({}, state)
  switch (type) {
    case RECEIVE_DASHBOARD:
      let { bondCovers = [], invitesToApply = [] } = dashboard
      return Object.assign(newState, { bondCovers, invitesToApply, error })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case LOCATION_CHANGE:
      error = ''
      return Object.assign(newState, { error })
    default:
      return state
  }
}

export default DashboardReducer
