import React from 'react'

import Box from 'app/components/design-system-components/Box'
import { IconButton } from 'app/components/design-system-components/Button'
import ButtonWithLoading from 'app/components/design-system-components/ButtonWithLoading'
import Flex from 'app/components/design-system-components/Flex'
import HorizontalLine from 'app/components/design-system-components/HorizontalLine'
import { ClearRounded } from 'app/components/design-system-components/icons/content'
import { Heading } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  ModalContentContainer,
  ModalContentWrapper,
} from 'app/modals/modal-styled'

const secondaryBtnPercentageWidth = 33
const primaryBtnPercentageWidth = 100 - secondaryBtnPercentageWidth
const defaultMaxWidth = '597px'

const secondaryBtnDefaultProps = {
  variant: 'solid',
  width: '100%',
}

const primaryBtnDefaultProps = {
  width: '100%',
}

const renderBtn = ({ title, onClick, props }) => {
  return (
    <ButtonWithLoading onClick={onClick} {...props}>
      {title}
    </ButtonWithLoading>
  )
}

export const GenericModalFooter = ({
  secondaryBtn,
  secondaryBtnConfig,
  primaryBtn,
  primaryBtnConfig,
}) => {
  const secondaryBtnElem =
    secondaryBtn ||
    renderBtn({
      ...secondaryBtnConfig,
      props: {
        ...secondaryBtnDefaultProps,
        ...secondaryBtnConfig.props,
      },
    })
  const primaryBtnElem =
    primaryBtn ||
    (!!primaryBtnConfig &&
      renderBtn({
        ...primaryBtnConfig,
        props: {
          ...primaryBtnDefaultProps,
          ...primaryBtnConfig.props,
        },
      }))

  return (
    <Flex alignItems="center">
      <Box width={secondaryBtnPercentageWidth + '%'} mr={theme.space[3] + 'px'}>
        {secondaryBtnElem}
      </Box>
      <Box width={primaryBtnPercentageWidth + '%'}>
        {!!primaryBtnElem && primaryBtnElem}
      </Box>
    </Flex>
  )
}

export const GenericModal = ({
  title,
  onDismiss,
  modalFooter,
  footerContainerProps,
  children,
  maxWidth = defaultMaxWidth,
  withHeading = true,
  takeMaxHeight = false,
}) => {
  return (
    <Box className="modal-fullscreen" bg={theme.colors.overlay}>
      <ModalContentWrapper maxWidth={maxWidth} p={7}>
        <ModalContentContainer takeMaxHeight={takeMaxHeight}>
          <Flex alignItems="center" justifyContent="space-between">
            {withHeading && <Heading level={3}>{title}</Heading>}
            <IconButton
              ml="auto"
              IconCmp={ClearRounded}
              variant="flat"
              fontSize={theme.fontSizes.h3}
              onClick={onDismiss}
            />
          </Flex>
          {withHeading && <HorizontalLine mb={7} mt={6} />}
          {children}
        </ModalContentContainer>
        <Box mt="auto" {...footerContainerProps}>
          {modalFooter}
        </Box>
      </ModalContentWrapper>
    </Box>
  )
}
