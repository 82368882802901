import React from 'react'

import PropTypes from 'prop-types'

import { Box } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import Card from 'app/match/applicationReportPDF/components/Card'
import Persona from 'app/match/applicationReportPDF/components/Persona'
import { EMERGENCY_CONTACT } from 'app/match/applicationReportPDF/container'
import { PersonalReferenceAnswersList } from 'app/match/apply/application_summary_before_submit/shared_components/personal_reference_box/component'
import * as helpers from 'app/sm/helpers'
import { formatAndPrefix, LANDLINE_PREFIX } from 'app/utils/phonenumber/helpers'

const propTypes = {
  contacts: PropTypes.object,
}

const Contacts = ({ contacts = {} }) => {
  const contactsCategory = Object.keys(contacts)

  return (
    <div className="contacts no-break-section">
      <Card
        type={'icon'}
        icon={'icon-contacts'}
        title={'Contacts'}
        addClass={'card-bottom-bordered'}
      />
      <ul className="list-reset">
        {contactsCategory &&
          contactsCategory.map((category, index) => {
            const contactCollection = contacts[category]
            return (
              <li key={index}>
                <strong className="list-title">{category}</strong>
                {(!contactCollection || contactCollection.length === 0) && (
                  <p>No information is provided</p>
                )}
                {contactCollection &&
                  contactCollection.map((contact, index) => {
                    const {
                      firstName = '',
                      lastName = '',
                      email = '',
                      phoneNumber = '',
                      relationship = '',
                      verifyStatus = '',
                      response,
                    } = contact
                    const formattedPrefixedPhoneNumber = formatAndPrefix(
                      phoneNumber,
                      LANDLINE_PREFIX,
                      true,
                    )
                    const contactInfo = {
                      email: `E: ${email}`,
                      status:
                        category === EMERGENCY_CONTACT
                          ? helpers.relationshipTypesEmergencyContact[
                              relationship
                            ]
                          : helpers.relationshipTypesTitles[relationship],
                      mobile: formattedPrefixedPhoneNumber,
                      type: 'contact',
                      name: `${helpers.capitalizeFirstLetter(
                        firstName,
                      )} ${helpers.capitalizeFirstLetter(lastName)}`,
                      verifyStatus:
                        helpers.EmploymentVerifyStatus[verifyStatus],
                    }
                    return (
                      <Box mb={`${theme.space[7]}px`}>
                        <Persona info={contactInfo} key={index} />
                        {response && !!response.length && (
                          <Box>
                            <Text
                              as="div"
                              color={theme.colors.gray400}
                              fontSize={theme.fontSizes.pRegular14}
                            >
                              Answers
                            </Text>
                            <PersonalReferenceAnswersList
                              answers={response}
                              singleAnswerContainerClass="m0"
                              textBlockContainerClass="m0"
                            />
                          </Box>
                        )}
                      </Box>
                    )
                  })}
              </li>
            )
          })}
      </ul>
    </div>
  )
}

Contacts.propTypes = propTypes

export default Contacts
