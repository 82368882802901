import React from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import ReferenceResultList from 'app/match/rental_reference/display/renter_address_history_detailed/rental_reference_detailed/rental_reference_result/rental_reference_result_list'
import {
  march2021Questions,
  ratingVersions,
} from 'app/match/rental_reference/rating_categories.constants'
import * as snugNotifier from 'app/services/snugNotifier'
import {
  capitalizeFirstLetter,
  ratingType,
  refereeTypeCollection,
  refereeTypeObject,
  trimByWord,
} from 'app/sm/helpers'

import 'app/match/rental_reference/display/renter_address_history_detailed/rental_reference_detailed/rental_reference_detailed.scss'

const HIDE_DETAILS_TEXT = 'Hide details'
const SHOW_DETAILS_TEXT = 'Show Details'

const overallRating = {
  [ratingVersions.originalVersion.ref]: ratingType.overall,
  [ratingVersions.march2021.ref]: march2021Questions.overall.id,
}

const positiveComment = {
  [ratingVersions.originalVersion.ref]: ratingType.positiveComment,
  [ratingVersions.march2021.ref]: march2021Questions.positivecomments.id,
}

const feedbackComment = {
  [ratingVersions.originalVersion.ref]: ratingType.feedback,
  [ratingVersions.march2021.ref]: march2021Questions.areasforconcerncomments.id,
}

const getAlreadyShownAnswers = (ratingVersionRef) => {
  return [
    positiveComment[ratingVersionRef],
    feedbackComment[ratingVersionRef],
  ].filter((code) => Boolean(code))
}

const findRatingByCode = (ratings = [], code) => {
  if (!code) {
    // return object to be backward compatible and not to break the API of the method
    return {}
  }
  return ratings.find((rating) => rating.code === code) || {}
}

export class RentalReferenceDetailsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonText: SHOW_DETAILS_TEXT,
      spinner: false,
    }
  }

  onRemoveClicked = (rentalHistoryId) => {
    const { onRemoveRentalReference } = this.props
    this.setState({ spinner: 'true' })
    onRemoveRentalReference(rentalHistoryId)
      .then(() => {
        this.setState({ spinner: 'false' })
      })
      .catch((error) => {
        this.setState({ spinner: 'false' })
        snugNotifier.error(error)
      })
  }

  onShowButtonClicked = () => {
    const { buttonText } = this.state
    if (buttonText === SHOW_DETAILS_TEXT) {
      this.setState({ buttonText: HIDE_DETAILS_TEXT })
    } else {
      this.setState({ buttonText: SHOW_DETAILS_TEXT })
    }
  }

  render() {
    const {
      title = 'Rental reference',
      text = '',
      componentClassName = '',
      titleIcon = '',
      ratings = [],
      ratingCategories = [],
      referenceSetVersion,
      commentClassName = '',
      iconClassName = '',
      matchingIP,
      requestIP,
      addressInfo,
      renterName = '',
    } = this.props
    const { buttonText, spinner } = this.state
    const overAllRating = findRatingByCode(
      ratings,
      overallRating[referenceSetVersion],
    )
    const refereeType = overAllRating
      ? refereeTypeCollection[overAllRating.refereeType]?.typeName
      : ''
    const positiveCommentRating = findRatingByCode(
      ratings,
      positiveComment[referenceSetVersion],
    )
    const feedbackRating = findRatingByCode(
      ratings,
      feedbackComment[referenceSetVersion],
    )
    let finalContext
    if (buttonText === SHOW_DETAILS_TEXT) {
      finalContext = trimByWord(text)
    } else {
      finalContext = text
    }
    const shouldShowAllRatings = buttonText === HIDE_DETAILS_TEXT
    const shouldShowExceptionRatings =
      referenceSetVersion < ratingVersions.march2021.ref
        ? true
        : buttonText === SHOW_DETAILS_TEXT
    const hideShowDetailsButton =
      buttonText === SHOW_DETAILS_TEXT &&
      !ratings.find((rating) => rating.exception === false)

    const ratingVersionDef = Object.values(ratingVersions).find(
      (v) => v.ref === referenceSetVersion,
    )

    const { conditionalShownAnswers } = ratingVersionDef

    const allowedCategories = ratingCategories
      .filter((category) => {
        const alreadyShownAnswers = getAlreadyShownAnswers(referenceSetVersion)
        return !alreadyShownAnswers.includes(category.Code)
      })
      .filter((category) => {
        if (!conditionalShownAnswers) return true
        const conditionalShownFn = conditionalShownAnswers[category.Code]
        return !conditionalShownFn || conditionalShownFn(ratings)
      })

    const overallCategories = allowedCategories.filter((ratingCategory) =>
      ratingVersionDef.overallQuestions.includes(ratingCategory.Code),
    )
    const tenancyQuestionsCategories = allowedCategories.filter(
      (ratingCategory) =>
        ratingVersionDef.tenancyQuestions.includes(ratingCategory.Code),
    )
    const toShowCategories = [
      ...overallCategories,
      ...tenancyQuestionsCategories,
    ]
    const exceptionRatings = ratings.filter((rating) => rating.exception)
    const normalRatings = ratings.filter((rating) => !rating.exception)
    const exceptionAnswers = (
      <div>
        <ReferenceResultList
          ratings={ratings}
          shouldShowAllRatings={shouldShowExceptionRatings}
          categories={ratingCategories}
          renterName={renterName}
        />
      </div>
    )

    let rentalReferenceError = ''
    if (matchingIP && requestIP) {
      rentalReferenceError += 'IP address match. '
    }
    if (exceptionRatings.length > 0) {
      rentalReferenceError += 'Answers to review. See below.'
    }

    return (
      <div className={`application-detials-request ${componentClassName}`}>
        <div className="title mb10 display-flex justify-content-space-between">
          <span>
            {title}
            {titleIcon && (
              <>
                <img
                  className={`ml5 mr5 pb2 ${iconClassName}`}
                  src={titleIcon}
                  alt="Status Icon"
                  data-tooltip-content=""
                  data-tooltip-id={addressInfo.guidID}
                />
                <ReactTooltip id={addressInfo.guidID}>
                  <span>{rentalReferenceError}</span>
                </ReactTooltip>
              </>
            )}
          </span>
          {refereeType === refereeTypeObject.manualReferee && (
            <span
              className="linkStyle pdf-hide"
              onClick={() => this.onRemoveClicked(addressInfo.guidID)}
              role="button"
              tabIndex="0"
            >
              <span className="ml5">Remove</span>
              {spinner && <i className="fa fa-spinner fa-pulse" />}
            </span>
          )}
        </div>
        <div className="content">
          <div className="text pdf-show reference-results-container">
            {matchingIP && requestIP && (
              <div className="mb20">
                This reference was submitted on the same IP Address ({requestIP}
                ) as the rental applicant.
              </div>
            )}
            {refereeType === 'manualReferee' && (
              <div className="mb20">
                This reference was submitted manually by{' '}
                {capitalizeFirstLetter(overAllRating.refereeFirstName)}{' '}
                {capitalizeFirstLetter(overAllRating.refereeLastName)} at{' '}
                {moment(overAllRating.createdAt).format('LT Do, MMM')}.
              </div>
            )}
            {finalContext && (
              <div className={`${commentClassName} mb20`}>
                <div className="title mb10">
                  <span>Comments</span>
                </div>
                {finalContext || 'No comment provided'}
              </div>
            )}
            <div className={`${commentClassName} mb20`}>
              <div className="title mb10">
                <span>Positive comments</span>
              </div>
              {positiveCommentRating.answerValue || 'No comment provided'}
            </div>
            <div className={`${commentClassName} mb20`}>
              <div className="title mb10">
                <span>Feedback</span>
              </div>
              {feedbackRating.answerValue || 'No comment provided'}
            </div>
            <div className="title mb10">
              <span>Answers</span>
            </div>

            {exceptionAnswers}
          </div>
          {!hideShowDetailsButton && (
            <button
              className="blue-item-button-lg mt10 pdf-hide"
              onClick={() => this.onShowButtonClicked()}
            >
              {buttonText}
            </button>
          )}
        </div>
      </div>
    )
  }
}
