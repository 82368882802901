import React from 'react'

import Signature from 'app/bond_cover/admin/requests/signature_widget'

const Tenant = ({ tenant }) => {
  let tenantHtml = (
    <div className="">
      <header>
        <span>No tenant Record Found</span>
      </header>
    </div>
  )
  if (tenant.Tenant && tenant.Tenant.email) {
    const { firstName, lastName, email, mobile, isPrimary } = tenant.Tenant
    tenantHtml = (
      <div className="contentBlock">
        <header>{isPrimary ? 'Primary Renter' : 'Other Renters'}</header>
        <content>
          <p className="mb0">
            Full Name: {firstName} {lastName}
            <br />
            Email: {email}
            <br />
            Mobile:{mobile ? mobile : ' Not Provided'}
            <br />
          </p>
          <Signature
            signature={tenant.Signature}
            type={isPrimary ? 'Primary Tenant ' : 'Other Tenant '}
          />
        </content>
      </div>
    )
  }
  return <div>{tenantHtml}</div>
}

export default Tenant
