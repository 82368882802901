import React from 'react'

import homeIcon from 'app/assets/icons/summary_icons/home.svg'
import { Box } from 'app/components/design-system-components'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import SummaryStaticHistoryBox from 'app/match/apply/application_summary_before_submit/shared_components/history_box/component'
import { AddressHistoryDetailsComponent } from 'app/match/rental_reference/display/renter_address_history_detailed/component'
import Checkbox from 'app/shared_components/checkbox'
import { noteActionType, urlTo } from 'app/sm/helpers'

const AddressHistoryComponent = ({
  componentClassName = '',
  rentalHistory = [],
  optOutRentalHistory = false,
  isAddressHistoryEditable = true,
  onEditClick,
  enableNote = false,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  displayedApplicant,
  section,
  ballotsEnabled = false,
  renterName = '',
  excludeRequestReasonForMoving = false,
}) => {
  return (
    <div className={`container-component ${componentClassName}`}>
      <ContainerHeader
        icon={homeIcon}
        text="Address History"
        isEditable={isAddressHistoryEditable}
        onEditClick={() =>
          onEditClick(
            `${urlTo('bondCoverRentalHistory')}?from=rentersummary${
              ballotsEnabled && `&ballot=true`
            }`,
          )
        }
        enableNote={enableNote}
        onNewNoteUpdated={onNewNoteUpdated}
        newNotesContent={newNotesContent}
        onNoteIconClicked={onNoteIconClicked}
        onNewNoteAdded={onNewNoteAdded}
        onNoteCrossClicked={onNoteCrossClicked}
        interpreteNoteResult={interpreteNoteResult}
        enableNotesButton={enableNotesButton}
        displayedApplicant={displayedApplicant}
        section={section}
      />
      <div>
        {rentalHistory.length > 0 &&
          rentalHistory.map((addressInfo) => {
            return (
              <SummaryStaticHistoryBox
                H={addressInfo}
                key={addressInfo.guidID}
                hasGreyBackground
              />
            )
          })}
        {rentalHistory.length === 0 && !optOutRentalHistory && (
          <div>Not provided</div>
        )}
        {rentalHistory.length === 0 && optOutRentalHistory && (
          <div>Does not have residences</div>
        )}
        {rentalHistory.length !== 0 && optOutRentalHistory && (
          <div className="disabled-link">
            <Checkbox
              label="No further address history"
              value={optOutRentalHistory}
              readOnly={true}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export const ManagerAddressHistoryComponent = ({
  componentClassName = '',
  rentalHistory = [],
  optOutRentalHistory = false,
  hasRentalBondsAccount = false,
  isAddressHistoryEditable = true,
  onEditClick,
  enableNote = false,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  displayedApplicant,
  section,
  handleSubmitRentalReviewRequest,
  versionsRatingCategories,
  selectedApplicantGUID,
  applicationId,
  onRemoveRentalReference,
  agencyGUID,
  id = '',
  toggleRequestInfoModal = undefined,
  ballotsEnabled = false,
  toggleDocumentsViewer,
  renterName = '',
  excludeRequestReasonForMoving = false,
}) => {
  const rentalBondsAccountNote = (
    <>
      <b>
        {displayedApplicant.firstName} {displayedApplicant.lastName}{' '}
      </b>
      {hasRentalBondsAccount && (
        <>
          has a <b> rental bond account</b>
        </>
      )}{' '}
      {!hasRentalBondsAccount && (
        <>
          doesn't have a<b> rental bond account</b>
        </>
      )}
    </>
  )

  const getRentalReferenceFilteredLastNotes = (addressInfo) => {
    const noteResult = JSON.parse(JSON.stringify(interpreteNoteResult || {}))
    const allNotes = JSON.parse(
      JSON.stringify(noteResult.addressHistory?.categorizedAllNotes || {}),
    )
    let filteredCategorizedLastNotes = {}

    if (!Object.keys(noteResult).length) {
      return
    }

    // - split notes over multiple rental histories.
    // - find the latest notes for each action type with targetGUID = addressInfo.guidID
    // - or the latest with empty targetGUID to fall back the old record
    const getLatestTypeRecord = (type) => {
      let typeNotes = allNotes[type]?.filter((note) => {
        return note.targetGUID === addressInfo.guidID
      })

      if (!typeNotes.length) {
        typeNotes = allNotes[type]?.filter((note) => {
          return note.targetGUID.length === 0
        })
      }

      if (!typeNotes.length) return undefined

      return typeNotes.reduce(
        (n1, n2) =>
          new Date(n1?.updatedAt) > new Date(n2?.updatedAt) ? n1 : n2,
        undefined,
      )
    }

    if (allNotes) {
      filteredCategorizedLastNotes[noteActionType.approve] =
        getLatestTypeRecord(noteActionType.approve)
      filteredCategorizedLastNotes[noteActionType.decline] =
        getLatestTypeRecord(noteActionType.decline)
      filteredCategorizedLastNotes[noteActionType.comment] =
        getLatestTypeRecord(noteActionType.comment)

      // find the latest note among variant action types
      const latestTargetNote = Object.values(filteredCategorizedLastNotes)
        .filter((n) => !!n)
        .reduce(
          (n1, n2) =>
            new Date(n1?.updatedAt) > new Date(n2?.updatedAt) ? n1 : n2,
          undefined,
        )

      noteResult.addressHistory.type = latestTargetNote?.type || 0

      noteResult.addressHistory.categorizedLastNotes =
        filteredCategorizedLastNotes
    }

    return noteResult
  }

  return (
    <div id={id} className={`container-component ${componentClassName}`}>
      <div className={`container-header position-relative`}>
        <img
          className="standard-icon-spacing"
          src={homeIcon}
          alt="Summary Icon"
        />
        <span className={`standard-subheading`}>Address History</span>
      </div>

      {!ballotsEnabled && <Box mt="20px">{rentalBondsAccountNote}</Box>}
      <div>
        {rentalHistory.length > 0 &&
          rentalHistory.map((addressInfo) => {
            return (
              versionsRatingCategories &&
              versionsRatingCategories[addressInfo.referenceSetVersion] && (
                <Box key={addressInfo.guidID} mt={`${theme.space[4]}px`}>
                  <ContainerHeader
                    isEditable={isAddressHistoryEditable}
                    onEditClick={() =>
                      onEditClick(
                        `${urlTo('bondCoverRentalHistory')}?from=rentersummary`,
                      )
                    }
                    enableNote={enableNote}
                    onNewNoteUpdated={onNewNoteUpdated}
                    newNotesContent={newNotesContent}
                    onNoteIconClicked={onNoteIconClicked}
                    onNewNoteAdded={onNewNoteAdded}
                    onNoteCrossClicked={onNoteCrossClicked}
                    interpreteNoteResult={getRentalReferenceFilteredLastNotes(
                      addressInfo,
                    )}
                    enableNotesButton={enableNotesButton}
                    displayedApplicant={displayedApplicant}
                    section={section}
                    toggleRequestInfoModal={toggleRequestInfoModal}
                    targetGUID={addressInfo.guidID}
                  />
                  <AddressHistoryDetailsComponent
                    addressInfo={addressInfo}
                    key={addressInfo.guidID}
                    handleSubmitRentalReviewRequest={
                      handleSubmitRentalReviewRequest
                    }
                    ratingCategories={
                      versionsRatingCategories[addressInfo.referenceSetVersion]
                    }
                    referenceSetVersion={addressInfo.referenceSetVersion}
                    selectedApplicantGUID={selectedApplicantGUID}
                    applicationId={applicationId}
                    onRemoveRentalReference={onRemoveRentalReference}
                    agencyGUID={agencyGUID}
                    toggleDocumentsViewer={toggleDocumentsViewer}
                    renterName={renterName}
                    excludeRequestReasonForMoving={
                      excludeRequestReasonForMoving
                    }
                  />
                </Box>
              )
            )
          })}
        {rentalHistory.length === 0 && !optOutRentalHistory && (
          <div>Not provided</div>
        )}
        {rentalHistory.length === 0 && optOutRentalHistory && (
          <div>Does not have residences</div>
        )}
        {rentalHistory.length !== 0 && optOutRentalHistory && (
          <div className="disabled-link">
            <Checkbox
              label="No further address history"
              value={optOutRentalHistory}
              readOnly={true}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default AddressHistoryComponent
