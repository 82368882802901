import React from 'react'

const BlueSafetyMessage = ({ message = '', showDeleteTip }) => (
  <div className="col-second xs-first">
    <ul className="snug-infos mt0">
      <li>{message}</li>
      {showDeleteTip && <li className="delete">Delete any time</li>}
    </ul>
  </div>
)

export default BlueSafetyMessage
