import React, { useEffect, useState } from 'react'

import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ManagedAppLogo from 'app/assets/pmsLogos/managed-app.png'
import { Alert, Box } from 'app/components/design-system-components'
import { InputField } from 'app/components/design-system-components/inputs'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { PageSection } from 'app/components/design-system-components/page/Section'
import { Text } from 'app/components/design-system-components/typography'
import { ManagedAppId } from 'app/constants/pms.constants'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import theme from 'app/match/applicationReportPDF/assets/theme'
import PMSActivity, {
  useActivatePMS,
  useDeactivatePMS,
  usePMSStatus,
} from 'app/PMSActivity'
import * as snugNotifier from 'app/services/snugNotifier'
import { loadingStatesIds } from 'app/utils/loading-states'

const formFields = {
  agencyId: 'agency_id',
}

const fieldValidation = (fieldName: string, value: string): any => {
  switch (fieldName) {
    case formFields.agencyId: {
      return {
        required: !value && 'This field is required',
      }
    }
  }
}

const formValidation = formValidationBuilder(fieldValidation)

const ManagedAppActivity = ({
  currentTeam,
  isAdmin,
  integrationsBundleIsActive,
}: any): React.ReactElement<any, any> => {
  const { loadPMSStatus, pmsStatusInfo, pmsStatusLoadingStates } = usePMSStatus(
    currentTeam,
    ManagedAppId,
    integrationsBundleIsActive,
  )
  const {
    activatePMS,
    activatePMSLoadingStates,
    activatePMSLoadingStatesHelpers,
  } = useActivatePMS(currentTeam, ManagedAppId)

  const { deactivatePMS, deactivatePMSLoadingStates } = useDeactivatePMS(
    currentTeam,
    ManagedAppId,
  )

  const [activationModal, setActivationModal] = useState({ isShown: false })

  useEffect(() => {
    loadPMSStatus()
  }, [])

  const openActivationModal = () => {
    activatePMSLoadingStatesHelpers.reset()
    setActivationModal({ isShown: true })
  }

  const closeActivateModal = () => {
    activatePMSLoadingStatesHelpers.reset()
    setActivationModal({ isShown: false })
  }

  const onActivate = ({ agency_id }: any) => {
    return activatePMS({ agency_id: agency_id })
      .then(() => {
        loadPMSStatus()
        closeActivateModal()
      })
      .catch(() => {})
  }

  const renderActivationModalFooter = (
    onClickActivate: Function,
    onCancel: Function,
    isSubmitting: boolean,
  ) => {
    return (
      <GenericModalFooter
        primaryBtnConfig={{
          title: 'Activate',
          onClick: onClickActivate,
          props: {
            loading: isSubmitting,
          },
        }}
        secondaryBtnConfig={{
          title: 'Cancel',
          onClick: onCancel,
        }}
      />
    )
  }
  const renderActivationModal = () => {
    if (!activationModal.isShown) return null

    const modalBody = (
      <Box>
        <Form>
          <Text>
            Please enter the activation key provided by ManagedApp support.
          </Text>
          <PageSection>
            {activatePMSLoadingStates.state === loadingStatesIds.ERROR && (
              <Alert variant="warningWithBg" mb={theme.space[4] + 'px'}>
                {(activatePMSLoadingStates as any).error.message}
              </Alert>
            )}

            <FieldWrapper
              id={formFields.agencyId}
              name={formFields.agencyId}
              label="Agency ID"
              required
            >
              <InputField id={formFields.agencyId} name={formFields.agencyId} />
            </FieldWrapper>
          </PageSection>
        </Form>
      </Box>
    )

    return (
      <Formik
        initialValues={{ [formFields.agencyId]: '' }}
        onSubmit={onActivate}
        validate={formValidation}
        validateOnBlur
      >
        {({ isSubmitting, submitForm }) => {
          return (
            <GenericModal
              onDismiss={() => closeActivateModal()}
              title="Activate ManagedApp"
              modalFooter={renderActivationModalFooter(
                submitForm,
                () => closeActivateModal(),
                isSubmitting,
              )}
            >
              {modalBody}
            </GenericModal>
          )
        }}
      </Formik>
    )
  }

  const statusProps = {
    statusInfo: pmsStatusInfo,
    statusLoadingStates: pmsStatusLoadingStates,
    reloadStatusInfo: loadPMSStatus,
  }

  const activationProps = {
    activationFn: openActivationModal,
    activateLoadingStates: activatePMSLoadingStates,
  }

  const deactivateProps = {
    deactivationFn: () =>
      deactivatePMS()
        .then(() => loadPMSStatus())
        .catch(({ message }: any) => snugNotifier.error(message)),
    deactivateLoadingStates: deactivatePMSLoadingStates,
  }

  return (
    <>
      <PMSActivity
        statusProps={statusProps}
        activateProps={activationProps}
        deactivateProps={deactivateProps}
        pmsBrand={ManagedAppId}
        isAdmin={isAdmin}
        logo={ManagedAppLogo}
        integrationsBundleIsActive={integrationsBundleIsActive}
      />
      {renderActivationModal()}
    </>
  )
}

const mapStateToProps = ({ session }: any) => ({
  currentTeam: session.currentTeam,
})

const ManagedAppActivityPage = withRouter(
  connect(mapStateToProps)(ManagedAppActivity),
)
export default ManagedAppActivityPage
