import ErrorMessages from 'app/constants/error_messages'
import {
  RECEIVE_RESPONSE_TEXT,
  receiveError,
} from 'app/dashboard/dashboard_actions'

const DashboardMiddleware = (store) => (next) => (action) => {
  const { type, responseText } = action
  const errorCode = parseInt(responseText)
  const errorMessage = ErrorMessages[errorCode]

  switch (type) {
    case RECEIVE_RESPONSE_TEXT:
      if (errorMessage) {
        store.dispatch(receiveError(errorMessage))
        return next(action)
      }
      if (isNaN(responseText)) {
        store.dispatch(receiveError(responseText))
        return next(action)
      }

      switch (errorCode) {
        default:
          store.dispatch(
            receiveError('There was a problem. Error code: ' + responseText),
          )
          return next(action)
      }
    default:
      return next(action)
  }
}

export default DashboardMiddleware
