import React from 'react'

import styled, { keyframes } from 'styled-components'
import { color, fontSize, typography, variant } from 'styled-system'

const PureSpinner = ({ className }) => {
  return <i className={`fa fa-spinner fa-pulse ${className}`} />
}

const LegacySpinner = styled(PureSpinner)`
  ${fontSize}
  ${color}
`

export const SpinnerVariants = {
  size: {},
}

const spin = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
  }
`

const mainCircleClass = 'main-circle'
const backgroundCircleClass = 'background-circle'

const themeVariant = {
  prop: 'colorVariant',
  variants: {
    primary: {
      [`.${mainCircleClass}`]: {
        stroke: 'primary300',
      },
      [`.${backgroundCircleClass}`]: {
        stroke: 'grey200',
      },
    },
    success: {
      [`.${mainCircleClass}`]: {
        stroke: 'success700',
      },
      [`.${backgroundCircleClass}`]: {
        stroke: 'success200',
      },
    },
    secondary: {
      [`.${mainCircleClass}`]: {
        stroke: 'gray900',
      },
      [`.${backgroundCircleClass}`]: {
        stroke: 'gray300',
      },
    },
    invertedSuccess: {
      [`.${mainCircleClass}`]: {
        stroke: 'success200',
      },
      [`.${backgroundCircleClass}`]: {
        stroke: 'success700',
      },
    },
  },
}

const SvgSpinnerContainer = styled.svg`
  @keyframe spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  animation: ${spin} 1s linear infinite;

  ${typography}
  ${variant(themeVariant)}
`

export const Spinner = ({ colorVariant = 'primary', ...props }) => {
  return (
    <SvgSpinnerContainer
      colorVariant={colorVariant}
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className={backgroundCircleClass}
        d="M41.834 9.327A23 23 0 118.167 40.672 23 23 0 0141.834 9.327h0z"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={mainCircleClass}
        opacity={0.5}
        d="M25 2a23 23 0 0123 23"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgSpinnerContainer>
  )
}

export default LegacySpinner
