import { api } from 'app/utils/api/helpers'

export const getRecentEnquiries = (teamId, propertyId, searchQ) => {
  // include all properties if we have a search term
  const allProperties = !!searchQ
  return api
    .get(`/sm/teams/${teamId}/property/${propertyId}/recentenquiries`, {
      params: {
        q: searchQ,
        unique: true,
        allProperties,
      },
    })
    .then(({ enquirers }) =>
      (enquirers || []).map((e) => ({
        ...e,
        fullName: `${e.firstName} ${e.lastName}`,
      })),
    )
}

export const updatePropertyListingAgent = (
  agencyGUID,
  propertyGUID,
  agentID,
  role,
) => {
  return api.put(
    `/sm/teams/${agencyGUID}/property/${propertyGUID}/update-listing-agent`,
    {
      agentID,
      role,
    },
  )
}

export const getPropertyManagers = (agencyGUID) => {
  return api.get(`/agencies/${agencyGUID}/users`)
}

export const getPropertyManagersForProperty = (agencyGUID, propertyGUID) => {
  return api.get(
    `sm/agency/${agencyGUID}/property/${propertyGUID}/manager-users`,
  )
}

export const postAssignPropertyManagersForProperty = (
  agencyGUID,
  propertyGUID,
  payload,
) => {
  return api.post(
    `sm/agency/${agencyGUID}/property/${propertyGUID}/manager-users`,
    payload,
  )
}

export const deleteUnassignPropertyManagersForProperty = (
  agencyGUID,
  propertyGUID,
  managerUserGUID,
) => {
  return api.delete(
    `sm/agency/${agencyGUID}/property/${propertyGUID}/manager-users/${managerUserGUID}`,
  )
}
