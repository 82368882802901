import { LOCATION_CHANGE } from 'connected-react-router'

import {
  CANCEL_MODAL,
  CHANGE_PREFERENCES_METHOD,
  PING,
  RECEIVE_ERROR,
  RECEIVE_PROFILE,
  RECEIVE_PROPERTY,
  RECEIVE_RESPONSE_TEXT,
  RESET_PROFILE,
  TOGGLE_SPINNER,
  TRIGGER_MODAL,
  UPDATE_FIELD,
} from 'app/sm/profile/profile_actions'

const _defaultState = {
  profile: {
    conditionPreferenceWeighting: 0,
    occupancyPreferenceWeighting: 0,
    yieldPreferenceWeighting: 0,
    allowPets: true,
    takeHoldingDeposit: false,
  },
  property: {},
  spinner: false,
  modal: false,
  preferencesMethod: 'POST',
  error: '',
}

const Profile = (state = _defaultState, action) => {
  let {
    type,
    profile,
    key,
    val,
    property,
    preferencesMethod,
    error,
    responseText,
  } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case PING:
      return Object.assign(newState, { error: '' })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case LOCATION_CHANGE:
      return Object.assign({}, _defaultState)
    case RECEIVE_PROFILE:
      return Object.assign(newState, { profile })
    case TOGGLE_SPINNER:
      return Object.assign(newState, { spinner: !newState.spinner })
    case UPDATE_FIELD:
      let p = Object.assign({}, newState.profile, { [key]: val })
      return Object.assign({}, newState, { profile: p })
    case RECEIVE_PROPERTY:
      return Object.assign(newState, { property })
    case TRIGGER_MODAL:
      return Object.assign(newState, { modal: true })
    case CANCEL_MODAL:
      return Object.assign(newState, { modal: false })
    case CHANGE_PREFERENCES_METHOD:
      return Object.assign(newState, { preferencesMethod })
    case RECEIVE_RESPONSE_TEXT:
      return Object.assign(newState, { responseText })
    case RESET_PROFILE:
      return Object.assign(newState, { profile: _defaultState.profile })
    default:
      return newState
  }
}

export default Profile
