import React from 'react'

import 'app/dashboard/team_activity/activity_list_row/style.scss'
import moment from 'moment'

const ActivityListRow = ({ date, friendlyName = '', suburb = '', action }) => {
  return (
    <div className="activity-content-list-row">
      <div className="activity-content-field">
        {moment(date).format('HH:mm DD MMM YYYY')}
      </div>
      <div className="activity-content-field">
        {friendlyName ? `${friendlyName}, ${suburb}` : `Unknown`}
      </div>
      <div className="activity-content-field">{action}</div>
    </div>
  )
}

export default ActivityListRow
