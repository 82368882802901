import { connect } from 'react-redux'

import { AcceptDeclineApplication } from 'app/sm/applications/applications_actions'
import ShareApplicationConfirmation from 'app/sm/applications/shareApplication/application_owner_confirmation'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  AcceptDeclineApplication: (shortcode, req) =>
    dispatch(AcceptDeclineApplication(shortcode, req)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShareApplicationConfirmation)
