import React from 'react'

import styled from 'styled-components'

import { Flex } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledApplicantScore = styled(Flex)`
  border-radius: 100%;
  height: ${theme.height[18]}px;
  min-width: ${theme.baseSpace * 5}px;
  justify-content: center !important;
  align-items: center;
  font-weight: 700;
  background-color: #d9d9d9;
`

function ApplicantScore({ score }) {
  return <StyledApplicantScore>{score}</StyledApplicantScore>
}

export default ApplicantScore
