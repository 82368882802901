import React from 'react'

import { IdentityDocsErrors } from 'app/constants/error_codes'
import PayContainer from 'app/shared_components/payment_form/pay_container'
import { urlTo } from 'app/sm/helpers'

class Pay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      apiError: '',
    }
  }

  componentDidMount() {
    this.props.fetchCards()
    this.props.fetchPaymentAmount()
    this.props.fetchIdentityDocs().catch((error) =>
      this.setState({
        apiError: error,
      }),
    )
  }

  isPaymentDisabled() {
    const { acceptTermsAndConditions } = this.props
    return !acceptTermsAndConditions
  }
  render() {
    const { paymentAmount, renderAllCards, identityDoc, error, errorCode } =
      this.props

    const { apiError } = this.state

    const isIdDocErr =
      error && Object.values(IdentityDocsErrors).includes(errorCode)

    const errMessage =
      error && isIdDocErr ? `Invalid Identity document: ${error}` : error

    const urlToGoBack = `${urlTo('BackgroundCheck')}${window.location.search}`

    return (
      <div>
        <PayContainer
          {...this.props}
          backUrl={urlToGoBack}
          showCards={true}
          autoSave={true}
          payForWhat={'Pay for Background check'}
          amount={paymentAmount}
          buttonParole={'Pay and Run Check'}
          error={errMessage || apiError}
          renderAllCards={renderAllCards}
        />
      </div>
    )
  }
}

export default Pay
