import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'

import localforage from 'localforage'
import moment from 'moment'
import qs from 'qs'
import styled from 'styled-components'

import {
  Box,
  Button,
  ButtonWithIcon,
  Flex,
} from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import { SearchRounded } from 'app/components/design-system-components/icons/actions'
import { TuneRounded } from 'app/components/design-system-components/icons/image'
import { Input } from 'app/components/design-system-components/inputs'
import Modal from 'app/components/design-system-components/Modal'
import { Text } from 'app/components/design-system-components/typography'
import * as Display from 'app/components/display/display'
import { durationOptionsNumbers } from 'app/components/display/edit_viewing_modal/viewing_modal_data'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import {
  MAX_ALLOWED_RECEIVERS,
  MAX_ALLOWED_RECEIVERS_ERROR_MESSAGE,
} from 'app/constants/messages.constants'
import {
  newBulkyConfig,
  newIdentifiedMessagesConfig,
} from 'app/features/pm-messages/new-message/constants'
import { NewMessageModal } from 'app/features/pm-messages/new-message/NewMessageModal'
import * as messagesFilters from 'app/features/teams/messages/table-filters'
import { hasNewFeaturedFilters } from 'app/features/teams/messages/table-filters/filters.config'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import AddEnquirerModal from 'app/pages/teams/messages/components/AddEnquirerModal'
import AlertForEnquiryResponder from 'app/pages/teams/messages/components/AlertForEnquiryResponder'
import { BulkyActionsControls } from 'app/pages/teams/messages/components/BulkyActionsControls'
import EnquiryAutoResponderModal from 'app/pages/teams/messages/components/EnquiryAutoResponderModal'
import EnquiryNotesModal from 'app/pages/teams/messages/components/EnquiryNotesModal'
import { allNotes } from 'app/pages/teams/messages/components/NotesCell'
import Table from 'app/pages/teams/messages/components/Table'
import { SelectAllAlerts } from 'app/pages/teams/messages/select-all/SelectAllAlerts'
import * as selectAll from 'app/pages/teams/messages/select-all/selectAllReducer'
import { getAgencyStatus } from 'app/services/http/agency'
import messagesSvc, {
  shortlistMessage,
  unShortlistMessage,
} from 'app/services/http/messages'
import { getApplicationDetails } from 'app/services/http/team-applications'
import { getTeamMembers } from 'app/services/http/teams/managers'
import { getPropertySummary } from 'app/services/http/teams/properties'
import { registerEnquirerForViewing } from 'app/services/http/viewings'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import Spinner from 'app/sm/common/spinner'
import * as helpers from 'app/sm/helpers'
import { getAllRenterPropertyNotes } from 'app/sm/inspections/helpers/api'
import { mapCovidAlerts } from 'app/utils/covid-alerts'
import { REGISTER_DATETIME } from 'app/utils/datetime/helpers'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { useMultiSelect } from 'app/utils/hooks/useMultiSelect'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { useUpdateFilters } from 'app/utils/hooks/useUpdateFilters'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
} from 'app/utils/loading-states'
import {
  filterReducerFactory,
  filtersActions,
} from 'app/utils/reducers/filtersReducer'
import { AGENCY_USER_ADMIN } from 'app/utils/roles'
import { covertBoolStringToBool } from 'app/utils/strings/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import * as viewingsHelpers from 'app/utils/viewings/helpers'

const filterByTextElem = (
  <Text
    as="span"
    fontWeight={theme.fontWeights[6]}
    fontSize={theme.fontSizes.pSmall12}
    color={theme.colors.gray600}
    mr={theme.space[4] + 'px'}
  >
    FILTER BY:{' '}
  </Text>
)

const AcceptHtmlStyledSpan = styled.span`
  white-space: pre-wrap;
`

const MESSAGES_LIMIT = 9

const NewMessages = ({
  fetchNotes,
  inviteToApplyEnquirer,
  markMessageAsRead,
  markMessageAsUnread,
  archiveMessage,
  createNote,
  sendPropertyList,
  fetchAllPropertyFutureViewings,
  addViewing,
  registerForEnquiryViewing,
  currentTeam,
  currentAgency,
}) => {
  const {
    state: basicData,
    setState: setBasicData,
    loadingStates: basicDataLoadingStates,
    loadingStatesHelpers: basicDataLoadingHelpers,
  } = useStateWithLoading({
    managers: [],
  })
  const {
    loadingStates: appliedFiltersLoadingStates,
    loadingStatesHelpers: appliedFiltersLoadingHelpers,
  } = useLoadingStates()
  const [toggleInfoRow, setToggleInfoRow] = useState({})
  const [messagesForTeam, setMessagesForTeam] = useState({})
  const [messageGUIDs, setMessageGUIDs] = useState([])
  const [tableData, setTableData] = useState([])
  const [messagesApplicationDetails, setMessagesApplicationDetails] = useState(
    {},
  )
  const [infoModalOpen, setInfoModalOpen] = useState({
    modalOpen: false,
    title: '',
    description: '',
  })
  const [notesForTeamMessages, setNotesForTeamMessages] = useState({})
  const [messagesMetaData, setMessagesMetaData] = useState({
    nextCursor: '',
    count: 0,
    filteredCount: 0,
  })
  const [registerForViewingModal, setRegisterForViewingModal] = useState({
    modalOpen: false,
    viewings: [],
    description: '',
    title: '',
    messageGUID: '',
    chosenViewing: {},
  })
  const [sendPropertyListModal, setSendPropertyListModal] = useState({
    modalOpen: false,
    description: '',
  })
  const [fetchingData, setFetchingData] = useState(true)
  const [addPrivateNoteModal, setAddPrivateNoteModal] = useState({
    open: false,
    content: '',
    allNotes: '',
  })
  const [scheduleViewingModal, setScheduleViewingModal] = useState({
    modalOpen: false,
    topText: '',
  })
  const [displayAddEnquirerModal, setDisplayAddEnquirerModal] = useState(false)
  const [newMessageModal, setNewMessageModal] = useState({
    isShown: false,
    modeConfig: undefined,
  })
  const [loadMoreSpinner, setLoadMoreSpinner] = useState(false)
  const {
    selectedItemsIds,
    selectedItemsMap,
    toggleItemSelection,
    setSelectedItemsSetIds,
    filterOutNonExistingItems,
  } = useMultiSelect()

  const [selectAllState, selectAllDispatch] = useReducer(
    selectAll.selectAllReducer,
    selectAll.selectAllDefaultState,
  )

  const [bulkReadLoadingStates, setBulkReadLoadingStates] = useState(
    createInitialLoadingState(),
  )
  const [bulkArchiveLoadingState, setBulkArchiveLoadingStates] = useState(
    createInitialLoadingState(),
  )

  const [isUserAdmin, setIsUserAdmin] = useState(false)

  const [filtersActionsState, setFiltersActionsState] = useState({
    filtersExpanded: false,
    sortExpanded: false,
  })

  const [filtersState, filtersDispatch] = useReducer(
    filterReducerFactory(messagesFilters.filtersInitialState),
    null,
  )

  const [showAutoresponderMessagesScreen, setShowAutoresponderMessagesScreen] =
    useState(false)
  const [
    hideAutoresponderMessagesScreenStorageKey,
    setHideAutoresponderMessagesScreenStorageKey,
  ] = useState(false)
  const [enquiryResponderModalOpen, setEnquiryResponderModalOpen] =
    useState(false)
  const [teamStatusData, setTeamStatusData] = useState({})

  const getBasicData = () => {
    basicDataLoadingHelpers.startLoading()
    return Promise.all([getTeamMembers(currentTeamGUID)])
      .then(([{ managerList }]) => {
        setBasicData({ managers: managerList })
        basicDataLoadingHelpers.markDoneSuccessfully()
      })
      .catch((err) => basicDataLoadingHelpers.setError(err))
  }

  useEffect(() => {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { property } = queryParams
    if (property) {
      const selectedPropertiesIds =
        typeof property === 'string' ? [property] : property
      fetchAndUpdateAppliedFiltersData({
        propertyIds: selectedPropertiesIds,
      }).then()
    } else {
      filtersDispatch(
        filtersActions.updateAllFilters(messagesFilters.filtersInitialState),
      )
    }
    fetchHideAutoresponderMessagesScreenKey()
  }, [])

  useEffect(() => {
    checkIfUserIsAdmin(currentAgency)
  }, [currentAgency])

  const fetchHideAutoresponderMessagesScreenKey = () => {
    localforage.getItem(
      textHelpers.HIDE_AUTORESPONDER_MESSAGES_SCREEN_STORAGE_KEY,
      (err, value) => {
        if (value) {
          const hideAutoresponderMessagesScreenKey = value === true
          setHideAutoresponderMessagesScreenStorageKey(
            hideAutoresponderMessagesScreenKey,
          )
        }
      },
    )
  }

  const checkIfUserIsAdmin = (currentAgency) => {
    if (currentAgency) {
      const { roles } = currentAgency
      const isTeamAdmin = roles.find(
        (role) => role.roleID === AGENCY_USER_ADMIN,
      )
      setIsUserAdmin(isTeamAdmin)
    }
  }

  useUpdateFilters(filtersState, {
    queryBuilder: (filters) => {
      if (!filters) return {}
      const filtersData = messagesFilters.getFilters(filters)
      return {
        property: filtersData.properties.map((property) => property.guidID),
      }
    },
  })

  useEffect(() => {
    getBasicData()
    if (currentTeam?.guid) {
      getTeamStausData()
    }
  }, [currentTeam])

  const getTeamStausData = () => {
    if (currentTeam?.guid) {
      getAgencyStatus(currentTeam?.guid).then(({ data }) => {
        const showAutoresponderMessagesScreenValue =
          data?.filter(
            (statusItem) =>
              statusItem.name ===
              textHelpers.SHOW_AUTORESPONDER_MESSAGES_SCREEN,
          )[0]?.description === 'On'
        setShowAutoresponderMessagesScreen(showAutoresponderMessagesScreenValue)
        setTeamStatusData(data)
      })
    }
  }

  const fetchAndUpdateAppliedFiltersData = ({ propertyIds }) => {
    appliedFiltersLoadingHelpers.startLoading()
    // current API is available only to get only one property
    const propertyPromise = propertyIds
      ? getPropertySummary(propertyIds[0])
      : Promise.resolve()
    return Promise.all([propertyPromise])
      .then(([propertyData]) => ({
        propertyData,
      }))
      .then(({ propertyData }) => {
        const properties = !propertyData
          ? []
          : [propertyData.managerSummary.property]
        const filtersValues = messagesFilters.generateSelectedFilters({
          properties,
        })
        filtersDispatch(filtersActions.updateAllFilters(filtersValues))
        appliedFiltersLoadingHelpers.markDoneSuccessfully()
      })
      .catch((err) => {
        appliedFiltersLoadingHelpers.setError(err)
      })
  }

  const filtersDataParams = useMemo(() => {
    if (!filtersState) return filtersState
    const filtersData = messagesFilters.getFilters(filtersState)
    const {
      properties,
      managers,
      propertyType,
      enquirerActions,
      readStatus,
      source,
    } = filtersData
    return {
      properties: properties.map((property) => property.guidID),
      managers: managers.map((manager) => manager.contact?.guidID),
      ...propertyType,
      ...enquirerActions,
      ...readStatus,
      source,
      search: filtersState.search,
    }
  }, [filtersState])

  useEffect(() => {
    if (!filtersDataParams) return
    fetchMessagesForTeam({})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersDataParams, currentTeam])

  useEffect(() => onClearSelection(), [filtersState])

  useEffect(() => {
    filterOutNonExistingItems(messageGUIDs)
  }, [messageGUIDs])

  const fetchMessagesForTeam = (filtersParam, concat = false) => {
    if (currentTeam && currentTeam.guid) {
      if (filtersParam && filtersParam.loadMore) {
        setLoadMoreSpinner(true)
      } else {
        setFetchingData(true)
      }

      const { loadMore, ...params } = {
        ...filtersParam,
        ...filtersDataParams,
        limit: MESSAGES_LIMIT,
      }

      messagesSvc
        .getMessages(currentTeam.guid, params)
        .then(({ data, response_metadata }) => {
          let allMessages = {}
          let allMessagesGUIDs = []
          data.forEach((messageInfo) => {
            const {
              message,
              property,
              viewings,
              preferences,
              snugVerificationStatus,
              applicationDetails = {},
              bdmApplicationURL,
            } = messageInfo || {}
            fetchMessageApplicationDetails(messageInfo)
            const { snugUserVerifiedAt, snugBackgroundCheckedAt } =
              snugVerificationStatus || {}
            const { guidid } = message
            allMessagesGUIDs = allMessagesGUIDs.concat(guidid)
            const messageComment = (message && message.comment) || ''
            const { hasColdSymptoms, recentOverseasVisit } = message
            allMessages[guidid] = {
              applicationDetails,
              property: {
                ...property,
                per: '/w',
                address: message.address,
              },
              preferences,
              messages: {
                desc: messageComment,
                ...message,
              },
              enquirer: {
                firstName: message.firstName,
                lastName: message.lastName,
                email: message.email,
                phone: message.phone,
                covidAlerts: mapCovidAlerts({
                  hasColdSymptoms: covertBoolStringToBool(hasColdSymptoms),
                  recentOverseasVisit:
                    covertBoolStringToBool(recentOverseasVisit),
                }),
              },
              status: {
                snugUserVerifiedAt: snugUserVerifiedAt,
                snugBackgroundCheckedAt: snugBackgroundCheckedAt,
              },
              comment: {
                desc: messageComment,
              },
              viewings,
              bdmApplicationURL,
            }
          })
          if (concat) {
            setMessagesForTeam({ ...messagesForTeam, ...allMessages })
            setMessageGUIDs(messageGUIDs.concat(allMessagesGUIDs))
          } else {
            setMessagesForTeam({ ...allMessages })
            setMessageGUIDs(allMessagesGUIDs)
          }

          setMessagesMetaData({
            nextCursor: response_metadata.next_cursor,
            filteredCount: response_metadata.filtered_count,
            count: response_metadata.total,
          })
          allMessagesGUIDs.forEach((messageGUID) =>
            fetchNotesForMessage(messageGUID, allMessages),
          )
        })
        .catch((error) => {
          snugNotifier.error(error.message)
        })
        .finally(() => {
          setFetchingData(false)
          setLoadMoreSpinner(false)
        })
    }
  }

  const { guid: currentTeamGUID } = currentTeam || {}

  useEffect(() => {
    const dataToBeEntered = messageGUIDs
      .map((messageGUID) => {
        const message = messagesForTeam[messageGUID]
        const notes = notesForTeamMessages[messageGUID]
        const isSelected = selectedItemsMap[messageGUID]
        const applicationDetails = messagesApplicationDetails[messageGUID] || {}
        if (!message) return

        const { messages } = message

        return {
          ...message,
          applicationDetails: message.applicationDetails && {
            ...message.applicationDetails,
            ...applicationDetails,
          },
          notes,
          messageGUID,
          isRowHighlighted: messages.readAt === null,
          isSelected,
        }
      })
      .filter((m) => Boolean(m))
    setTableData(dataToBeEntered)
  }, [
    messageGUIDs,
    messagesForTeam,
    notesForTeamMessages,
    messagesApplicationDetails,
    selectedItemsMap,
  ])

  useEffect(() => {
    const { isModeActive, allSelected } = selectAllState
    if (isModeActive && allSelected) {
      setSelectedItemsSetIds(messageGUIDs)
    }

    reEvaluateSelectAllActivation({ activateSelectAll: allSelected })
  }, [messageGUIDs])

  const fetchMessageApplicationDetails = (messageData) => {
    const { guidID: applicationId } = messageData.applicationDetails || {}
    if (!applicationId) return
    const { message } = messageData
    return getApplicationDetails(currentTeam.guid, applicationId)
      .then((applicationDetails) => {
        setMessagesApplicationDetails((prevState) => ({
          ...prevState,
          [message.guidid]: applicationDetails,
        }))
      })
      .catch(({ message }) =>
        snugNotifier.error(
          `failed to get application details \n for app: [${applicationId}] \n ${message}`,
        ),
      )
  }

  const fetchNotesForMessage = (messageGUID, allMessages = {}) => {
    fetchNotes(messageGUID, 'sm_message')
      .then((responseData) => {
        setNotesForTeamMessages((prevState) => ({
          ...prevState,
          [messageGUID]: { enquiryNotes: responseData },
        }))
      })
      .then(() => {
        const messageForFetchingNotes =
          allMessages[messageGUID] || messagesForTeam[messageGUID]

        const { enquirer = {} } = messageForFetchingNotes || {}
        const { email = '', phone = '' } = enquirer || {}
        if (currentTeamGUID && (email || phone)) {
          getAllRenterPropertyNotes(currentTeamGUID, email, phone).then(
            (data) => {
              setNotesForTeamMessages((prevState) => {
                return {
                  ...prevState,
                  [messageGUID]: {
                    ...prevState[messageGUID],
                    notesHistory: [...data],
                  },
                }
              })
            },
          )
        }
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  const onConfirmSendPropertyList = () => {
    const { original } = toggleInfoRow
    const { messageGUID } = original
    if (messageGUID) {
      sendPropertyList(messageGUID).then(() => {
        const notePayload = {
          content: 'Sent property list',
          type: helpers.noteActionType.enquiry,
          target: helpers.targetSectionObject.enquiry['index'],
        }
        createNote(notePayload, messageGUID, 'sm_message').then(() => {
          fetchNotesForMessage(messageGUID)
        })
        snugNotifier.success(`Successfully sent property list`)
        setSendPropertyListModal({
          modalOpen: false,
        })
      })
    }
  }

  const viewAllNotes = () => {
    const { original } = toggleInfoRow
    const { messageGUID } = original
    const notes = allNotes(notesForTeamMessages[messageGUID])

    setInfoModalOpen({
      modalOpen: true,
      title: 'Notes',
      description: notes,
    })
  }

  const toggleInfoModal = (title = '', description = '') => {
    if (infoModalOpen.modalOpen) {
      setInfoModalOpen({
        modalOpen: false,
        title: '',
        description: '',
      })
    } else {
      setInfoModalOpen({
        modalOpen: true,
        title,
        description,
      })
    }
  }

  const onArchiveMessage = (messageGUID) => {
    if (window.confirm('Are you sure you want to archive the message?')) {
      archiveMessage(currentTeamGUID, messageGUID)
        .then(() => {
          const updatedMessageGUIDs = messageGUIDs.filter(
            (guid) => guid !== messageGUID,
          )
          setMessageGUIDs(updatedMessageGUIDs)
          snugNotifier.success('Message has been archived successfully')
        })
        .catch((error) => snugNotifier.error(error))
    }
  }

  const markAsRead = (messageGUID) => {
    markMessageAsRead(currentTeamGUID, messageGUID)
      .then(({ data }) => {
        snugNotifier.success('Message marked as Read')
        setMessagesForTeam({
          ...messagesForTeam,
          [messageGUID]: {
            ...messagesForTeam[messageGUID],
            messages: {
              ...messagesForTeam[messageGUID]['messages'],
              readAt: data.readAt,
            },
          },
        })
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  const markAsUnread = (messageGUID) => {
    markMessageAsUnread(currentTeamGUID, messageGUID)
      .then(({ data }) => {
        snugNotifier.success('Message marked as Unread')
        setMessagesForTeam({
          ...messagesForTeam,
          [messageGUID]: {
            ...messagesForTeam[messageGUID],
            messages: {
              ...messagesForTeam[messageGUID]['messages'],
              readAt: data.readAt,
            },
          },
        })
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  const shortlist = (messageID) => {
    return shortlistMessage(currentTeamGUID, messageID)
      .then(({ shortlisted }) => {
        snugNotifier.success('Message shortlisted')
        setMessagesForTeam({
          ...messagesForTeam,
          [messageID]: {
            ...messagesForTeam[messageID],
            messages: {
              ...messagesForTeam[messageID].messages,
              shortlisted,
            },
          },
        })
      })
      .catch((err) => {
        snugNotifier.error(err.message)
      })
  }

  const unShortlist = (messageID) => {
    return unShortlistMessage(currentTeamGUID, messageID)
      .then(({ shortlisted }) => {
        snugNotifier.success('Message un-shortlisted')
        setMessagesForTeam({
          ...messagesForTeam,
          [messageID]: {
            ...messagesForTeam[messageID],
            messages: {
              ...messagesForTeam[messageID].messages,
              shortlisted,
            },
          },
        })
      })
      .catch((err) => {
        snugNotifier.error(err.message)
      })
  }

  const updateForms = (data) => {
    const { value } = data
    setAddPrivateNoteModal({ ...addPrivateNoteModal, content: value })
  }

  const onConfirmRegisterForViewing = () => {
    const { chosenViewing, messageGUID } = registerForViewingModal
    const viewingTime = helpers.getStandardFormattedDate(
      moment(chosenViewing.startDate),
      helpers.enquiryViewingDateFormat,
    )
    if (chosenViewing.guidID) {
      registerEnquirerForViewing(
        currentTeamGUID,
        chosenViewing.guidID,
        messageGUID,
      )
        .then((data) => {
          const toastMessage = data.isRegistered
            ? `Successfully registered for viewing`
            : `Enquirer has already been registered`
          snugNotifier.success(toastMessage)
          if (data.isRegistered) {
            const note = {
              content: `Registered for ${viewingTime}`,
              type: helpers.noteActionType.enquiry,
              target: helpers.targetSectionObject.enquiry['index'],
            }
            createNote(note, messageGUID, 'sm_message').then(() => {
              fetchNotesForMessage(messageGUID)
              setRegisterForViewingModal({
                ...registerForViewingModal,
                modalOpen: false,
              })
              setMessagesForTeam({
                ...messagesForTeam,
                [messageGUID]: {
                  ...messagesForTeam[messageGUID],
                  viewings: [
                    ...(messagesForTeam[messageGUID].viewngs || []),
                    {
                      isInspected: false,
                      isRegistered: true,
                      viewing: chosenViewing,
                    },
                  ],
                },
              })
            })
          }
        })
        .catch((error) => {
          snugNotifier.error(error?.message)
        })
    } else {
      snugNotifier.error('Please select viewing time')
    }
  }

  const onClickRegisterForViewing = (viewings) => {
    const { original } = toggleInfoRow
    const { messageGUID } = original
    const enquirer = messagesForTeam[messageGUID].enquirer
    const message = messagesForTeam[messageGUID].messages
    const composedFullNameWithEmailOrMobileNumber = `${helpers.capitalizeFirstLetter(
      enquirer.firstName,
    )} ${helpers.capitalizeFirstLetter(enquirer.lastName)} (${
      enquirer.email || enquirer.phone
    })`

    const description = `Register ${composedFullNameWithEmailOrMobileNumber} for ${message.address}?`
    setRegisterForViewingModal({
      modalOpen: true,
      title: 'Register for viewing',
      description,
      viewings,
      chosenViewing: '',
      messageGUID,
    })
  }

  const savePrivateNote = () => {
    const { original } = toggleInfoRow
    const { messageGUID } = original
    const payload = {
      content: addPrivateNoteModal.content,
      type: helpers.noteActionType.enquiry,
      target: helpers.targetSectionObject.enquiry['index'],
    }
    createNote(payload, messageGUID, 'sm_message')
      .then(() => {
        fetchNotesForMessage(messageGUID)
        setAddPrivateNoteModal({ ...addPrivateNoteModal, open: false })
      })
      .catch((error) => snugNotifier.error(error))
  }

  const onInviteToApply = () => {
    const { original } = toggleInfoRow
    const { messageGUID } = original
    const email =
      messagesForTeam[messageGUID].enquirer &&
      messagesForTeam[messageGUID].enquirer.email
    if (email.length === 0) {
      snugNotifier.error('Email is required for sending an invite')
      return
    }
    inviteToApplyEnquirer(messageGUID)
      .then(() => {
        snugNotifier.success('Invite sent successfully')
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  const sendPropertyListAction = () => {
    const { original } = toggleInfoRow
    const { messageGUID } = original
    const email =
      messagesForTeam[messageGUID].enquirer &&
      messagesForTeam[messageGUID].enquirer.email
    if (email.length === 0) {
      snugNotifier.error('Email is required for sending an invite')
      return
    }
    const firstName = messagesForTeam[messageGUID].enquirer.firstName
    const description = (
      <div>
        Send {firstName} ({email}) the property list?
      </div>
    )
    setSendPropertyListModal({
      ...sendPropertyListModal,
      modalOpen: true,
      description,
    })
  }

  const onClickInviteToProperty = () => {
    const { original } = toggleInfoRow
    const { messages = {} } = original
    const { propertyGUID, email } = messages || {}
    const sharePropertyUrl = `/teams/${currentTeamGUID}/property/${propertyGUID}?email=${email}`
    history.push(sharePropertyUrl)
  }

  const toggleAdditionalMenuForRow = (_, row, state) => {
    if (state) {
      setToggleInfoRow(row)
    } else {
    }
  }

  const onClickLoadMore = () => {
    let filtersCopy = Object.assign({}, filtersDataParams)
    filtersCopy.cursor = messagesMetaData.nextCursor
    filtersCopy.loadMore = true
    fetchMessagesForTeam(filtersCopy, true)
  }

  const callRegisterForViewing = (viewing) => {
    const { original } = toggleInfoRow
    const { messageGUID } = original
    const viewingTime = helpers.getStandardFormattedDate(
      moment(viewing.startDate),
      helpers.enquiryViewingDateFormat,
    )
    if (messageGUID && viewing.guidID) {
      registerEnquirerForViewing(currentTeamGUID, viewing.guidID, messageGUID)
        .then((data) => {
          const toastMessage = data.isRegistered
            ? `Successfully registered for viewing`
            : `Enquirer has already been registered`
          snugNotifier.success(toastMessage)
          if (data.isRegistered) {
            setMessagesForTeam({
              ...messagesForTeam,
              [messageGUID]: {
                ...messagesForTeam[messageGUID],
                viewings: [
                  ...(messagesForTeam[messageGUID].viewngs || []),
                  {
                    isInspected: false,
                    isRegistered: true,
                    viewing,
                  },
                ],
              },
            })
            const note = {
              content: `Registered for ${viewingTime}`,
              type: helpers.noteActionType.enquiry,
              target: helpers.targetSectionObject.enquiry['index'],
            }
            createNote(note, messageGUID, 'sm_message').then(() => {
              fetchNotesForMessage(messageGUID)
            })
          }
        })
        .catch((error) => {
          snugNotifier.error(error?.message)
        })
    }
  }

  function closeScheduleViewingModal() {
    setScheduleViewingModal({
      ...scheduleViewingModal,
      modalOpen: false,
    })
  }

  const updateScheduleViewingModal = (modalValues) => {
    const {
      startDate,
      startTime,
      published,
      notify,
      duration,
      inspectorsGUIDs,
    } = modalValues
    const { original } = toggleInfoRow
    const { messageGUID } = original
    const property = messagesForTeam[messageGUID].property
    const mergedStartDate = `${moment(startDate).format(
      helpers.dateWithDash,
    )} ${startTime}`
    const formattedStartDate = helpers.getStandardFormattedDate(
      moment(mergedStartDate, helpers.dateTimeWithDash).utc(),
      helpers.utcFormatTimeStamp,
    )
    const viewingOptions = {
      published,
      notify,
      inspectorsGUIDs,
    }
    if (property && property.offerGUID) {
      addViewing(
        property.offerGUID,
        formattedStartDate,
        durationOptionsNumbers[duration],
        false,
        false,
        viewingOptions,
      )
        .then((data) => {
          callRegisterForViewing(data.viewing)
          closeScheduleViewingModal()
        })
        .catch((error) => {
          if (
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForProperty] ||
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForManager]
          ) {
            if (window.confirm(viewingsHelpers.conflictingTimeMessage)) {
              addViewing(
                property.offerGUID,
                formattedStartDate,
                durationOptionsNumbers[duration],
                false,
                true,
                viewingOptions,
              )
                .then((data) => {
                  callRegisterForViewing(data.viewing)
                  closeScheduleViewingModal()
                })
                .catch((error) => {
                  snugNotifier.error(error)
                })
            }
          } else {
            snugNotifier.error(error)
          }
        })
    }
  }

  const scheduleViewingAction = () => {
    const { original } = toggleInfoRow
    const { messageGUID } = original
    const enquirer = messagesForTeam[messageGUID].enquirer
    const message = messagesForTeam[messageGUID].messages
    const composedFullNameWithEmailOrMobileNumber = `${helpers.capitalizeFirstLetter(
      toggleInfoRow.firstName,
    )} ${helpers.capitalizeFirstLetter(enquirer.lastName)} (${
      enquirer.email || enquirer.phone
    })`

    const scheduleViewingModalText = `Register ${composedFullNameWithEmailOrMobileNumber} for
    ${message.address}`
    setScheduleViewingModal({
      modalOpen: true,
      topText: scheduleViewingModalText,
    })
  }

  function openAddEnquirerModal() {
    setDisplayAddEnquirerModal(true)
  }

  function closeAddEnquirerModal() {
    setDisplayAddEnquirerModal(false)
  }

  const toggleMessageSelection = (message) => {
    const { guidid } = message
    toggleItemSelection(guidid)
    reEvaluateSelectAllActivation()
  }

  const toggleAllMessagesSelection = () => {
    const isGlobalSelection = !!selectedItemsIds.length
    const toBeSelectedMessages = isGlobalSelection ? [] : [...messageGUIDs]
    setSelectedItemsSetIds(toBeSelectedMessages)
    reEvaluateSelectAllActivation({ activateSelectAll: !isGlobalSelection })
  }

  const onSelectAll = () => {
    selectAllDispatch(selectAll.updateSelection(true))
  }

  const onClearSelection = () => {
    selectAllDispatch(selectAll.resetState())
    setSelectedItemsSetIds([])
  }

  const reEvaluateSelectAllActivation = ({ activateSelectAll } = {}) => {
    const isActivated =
      activateSelectAll && messagesMetaData.filteredCount > messageGUIDs.length
    if (selectAllState.isModeActive !== isActivated) {
      selectAllDispatch(selectAll.updateActivation(isActivated))
    }
  }

  const messagesSelectionOptions = [
    {
      id: 'all',
      label: 'All',
      filterMessages: () => [...tableData],
      activateSelectAll: true,
    },
    {
      id: 'none',
      label: 'None',
      filterMessages: () => [],
    },
    {
      id: 'read',
      label: 'Read',
      filterMessages: () => tableData.filter(({ messages }) => messages.readAt),
    },
    {
      id: 'unread',
      label: 'Unread',
      filterMessages: () =>
        tableData.filter(({ messages }) => !messages.readAt),
    },
  ]

  const selectionConfigs = {
    options: messagesSelectionOptions.map((option) => {
      const toBeSelectedMessagesIds = option
        .filterMessages()
        .map(({ messageGUID }) => messageGUID)
      return {
        ...option,
        onClick: () => {
          setSelectedItemsSetIds(toBeSelectedMessagesIds)
          reEvaluateSelectAllActivation(option)
        },
      }
    }),
    toggleAllMessagesSelection,
    hasSelection: !!selectedItemsIds.length,
  }

  const generateBulkActionPayload = () => {
    return selectAllState.allSelected
      ? { filters: filtersDataParams }
      : { ids: selectedItemsIds }
  }

  const bulkReadLoadingStatesUtils = createLoadingStateUtils(
    setBulkReadLoadingStates,
  )
  const bulkRead = () => {
    bulkReadLoadingStatesUtils.startLoading()
    const payload = generateBulkActionPayload()
    return messagesSvc
      .bulkRead(currentTeamGUID, payload)
      .then(() => {
        fetchMessagesForTeam({}, false)
        bulkReadLoadingStatesUtils.markDoneSuccessfully()
        onClearSelection()
        snugNotifier.success(
          'Selected messages have been marked as read successfully',
        )
      })
      .catch((error) => {
        snugNotifier.error(error.message)
        bulkReadLoadingStatesUtils.setError(error)
      })
  }

  const bulkArchiveLoadingStatesUtils = createLoadingStateUtils(
    setBulkArchiveLoadingStates,
  )
  const bulkArchive = () => {
    bulkArchiveLoadingStatesUtils.startLoading()
    const payload = generateBulkActionPayload()
    return messagesSvc
      .bulkArchive(currentTeamGUID, payload)
      .then(() => {
        fetchMessagesForTeam({}, false)
        bulkArchiveLoadingStatesUtils.markDoneSuccessfully()
        onClearSelection()
        snugNotifier.success(
          'Selected messages have been archived successfully',
        )
      })
      .catch((err) => {
        snugNotifier.error(err.message)
        bulkArchiveLoadingStatesUtils.setError(err)
      })
  }

  const bulkReadConfig = {
    onClick: () => bulkRead(),
    loadingStates: bulkReadLoadingStates,
  }

  const bulkArchiveConfig = {
    onClick: () => bulkArchive(),
    loadingStates: bulkArchiveLoadingState,
  }

  const bulkSendMessageDisplayedSelection = () => {
    const selectedMessages =
      selectedItemsIds && selectedItemsIds.map((id) => messagesForTeam[id])
    const validMessages = selectedMessages.filter(
      (message) => !!message.enquirer.email,
    )

    if (validMessages.length > MAX_ALLOWED_RECEIVERS) {
      return snugNotifier.error(MAX_ALLOWED_RECEIVERS_ERROR_MESSAGE)
    }

    if (!validMessages.length) {
      const isPlural = selectedMessages.length > 1
      snugNotifier.error(
        `Selected ${
          isPlural ? "messages' enquirers have no" : "message's enquirer has"
        } no email address to send to`,
      )
      return
    }

    setNewMessageModal({
      isShown: true,
      modeConfig: newIdentifiedMessagesConfig(selectedMessages),
    })
  }

  const bulkSendMessageAllSelected = () => {
    return setNewMessageModal({
      isShown: true,
      modeConfig: newBulkyConfig(
        filtersDataParams,
        messagesMetaData.filteredCount,
      ),
    })
  }

  const bulkSendMessage = {
    isEnabled: true,
    onClick: () => {
      return selectAllState.allSelected
        ? bulkSendMessageAllSelected()
        : bulkSendMessageDisplayedSelection()
    },
  }

  const dismissSendMessageModalHandler = () => {
    setNewMessageModal({ isShown: false, modeConfig: null })
  }

  const onSendBulkMessageSuccessfully = (res) => {
    snugNotifier.success('Emails requested')
    setNewMessageModal({ isShown: false, modeConfig: null })
  }

  const hasFiltersApplied = messagesFilters.hasAppliedFilters(filtersState)
  const onFiltersButtonClicked = () => {
    const { filtersExpanded } = filtersActionsState
    setFiltersActionsState({
      sortExpanded: false,
      filtersExpanded: !filtersExpanded,
    })
  }

  const showNewBadge = hasNewFeaturedFilters()

  const toggleEnquiryResponderModal = () => {
    setEnquiryResponderModalOpen(!enquiryResponderModalOpen)
  }

  const filtersSortActionsElem = (
    <Flex alignItems="center" justifyContent="flex-end">
      <ButtonWithIcon
        variant={
          filtersActionsState.filtersExpanded ? 'outlineDeepBlue' : 'outline'
        }
        IconCmp={TuneRounded}
        leading={false}
        mr={theme.space[3] + 'px'}
        onClick={onFiltersButtonClicked}
      >
        {hasFiltersApplied ? 'Filters Applied' : 'Filter'}
        {showNewBadge && <Badge variant="blue" text="New" ml={3} />}
      </ButtonWithIcon>
      <Button
        variant="outline"
        onClick={() => filtersDispatch(filtersActions.resetAllFilters())}
      >
        Clear
      </Button>
    </Flex>
  )

  const [searchTextState, setSearchTextState] = useState(
    filtersState?.search || '',
  )

  useEffect(
    () => setSearchTextState(filtersState?.search || ''),
    [filtersState?.search],
  )

  const debouncedOnSearchTextChange = useCallback(
    debounce(
      (value) => filtersDispatch(filtersActions.updateSearch(value)),
      STANDARD_TIMEOUT,
    ),
    [],
  )

  const onSearchTextChange = ({ target: { value } }) => {
    setSearchTextState(value)
    debouncedOnSearchTextChange(value)
  }

  const renderFiltersAndSortElem = () => {
    return (
      <>
        <Flex alignItems="center" justifyContent="space-between">
          <Input
            icon={
              <SearchRounded
                style={{
                  color: theme.colors.gray400,
                }}
              />
            }
            inputProps={{
              placeholder: 'Search messages',
              value: searchTextState,
              onChange: onSearchTextChange,
            }}
            maxWidth="400px"
          />

          {filtersSortActionsElem}
        </Flex>
      </>
    )
  }

  const renderSelectAllElem = () => {
    const { isModeActive, allSelected } = selectAllState
    const totalCount = messagesMetaData.filteredCount
    const displayedCount = tableData.length
    return (
      <SelectAllAlerts
        isActive={isModeActive}
        allSelected={allSelected}
        onSelectAll={onSelectAll}
        onClearSelection={onClearSelection}
        counts={{ total: totalCount, displayed: displayedCount }}
      />
    )
  }

  const _renderRegiterViewingModalBody = (viewings, description) => {
    return (
      <Box>
        {registerForViewingModal.description}
        <Box mt={5}>
          {(viewings || []).map(({ viewing = {} }) => {
            const { guidID: viewingGUID = '' } = viewing
            return (
              <>
                <label className="display-flex mb15">
                  <input
                    type="radio"
                    name="manager"
                    value={viewingGUID}
                    onClick={() =>
                      setRegisterForViewingModal({
                        ...registerForViewingModal,
                        chosenViewing: viewing,
                      })
                    }
                  />
                  <span className="height-auto">
                    {moment(viewing.startDate).format(REGISTER_DATETIME)}
                  </span>
                </label>
              </>
            )
          })}
        </Box>
      </Box>
    )
  }

  const { slug: teamSlug } = currentTeam || {}

  const extraOptionsMenuItems = [
    {
      text: 'Import Enquirers',
      enableTool: true,
      onClick:
        isUserAdmin &&
        (() =>
          history.push(
            helpers.urlTo(helpers.urlIds.messagesImport, {
              teamSlug: currentTeam.slug,
            }),
          )),
      tooltipId: !isUserAdmin && 'admin-only',
      tooltipText: !isUserAdmin && 'A team admin can import enquirers',
    },
  ]

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <h1>Messages</h1>
        <Flex alignItems="center" justifyContent="end">
          <Button
            width="150px"
            height="42px"
            p="12px 16px"
            mr={3}
            onClick={openAddEnquirerModal}
          >
            Add Enquirer +
          </Button>
          <Display.ToolsButton tools={extraOptionsMenuItems} />
        </Flex>
      </Flex>
      <Box mt="10px" mb="30px">
        Prospects are sent alerts about new, matching or price drop property
        listings as well as new, changed or cancelled Viewing times.
      </Box>
      {showAutoresponderMessagesScreen &&
        !hideAutoresponderMessagesScreenStorageKey && (
          <AlertForEnquiryResponder
            teamSlug={teamSlug}
            toggleEnquiryResponderModal={toggleEnquiryResponderModal}
            fetchHideAutoresponderMessagesScreenKey={
              fetchHideAutoresponderMessagesScreenKey
            }
          />
        )}

      {renderFiltersAndSortElem()}

      <Box mt={theme.space[5] + 'px'}>
        {filtersActionsState.filtersExpanded && (
          <Flex alignItems="center">
            {filterByTextElem}
            <messagesFilters.MessagesFilters
              currentTeam={currentTeam}
              managers={basicData.managers}
              filters={filtersState.filters}
              updateFilter={(filterId, filterValues) =>
                filtersDispatch(
                  filtersActions.updateFilter(filterId, filterValues),
                )
              }
            />
          </Flex>
        )}

        {!filtersActionsState.filtersExpanded && hasFiltersApplied && (
          <Flex alignItems="center">
            {filterByTextElem}
            <messagesFilters.MessageAppliedFilters
              filters={filtersState.filters}
              onDismissFilter={(filterId) =>
                filtersDispatch(filtersActions.updateFilter(filterId, {}))
              }
            />
          </Flex>
        )}
      </Box>

      {fetchingData ? (
        <Spinner />
      ) : (
        <>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            mt={theme.space[4] + 'px'}
          >
            <Box minWidth="50%">{renderSelectAllElem()}</Box>
            {!!selectedItemsIds.length && (
              <BulkyActionsControls
                justifyContent="end"
                selectedMessagesCount={
                  selectAllState.allSelected
                    ? messagesMetaData.filteredCount
                    : selectedItemsIds.length
                }
                archiveConfig={bulkArchiveConfig}
                markAsReadConfig={bulkReadConfig}
                sendMessageConfig={bulkSendMessage}
              />
            )}
          </Flex>

          {tableData && (
            <Box mt="24px">
              <Table
                onClickRegisterForViewing={onClickRegisterForViewing}
                toggleInfoModal={toggleInfoModal}
                dataToBeEntered={tableData}
                toggleAdditionalMenuForRow={toggleAdditionalMenuForRow}
                toggleInfoRow={toggleInfoRow}
                onInviteToApply={onInviteToApply}
                viewAllNotes={viewAllNotes}
                markAsRead={markAsRead}
                markAsUnread={markAsUnread}
                onArchiveMessage={onArchiveMessage}
                shortlistMessage={shortlist}
                unshortlistMessage={unShortlist}
                propertyInfo={
                  toggleInfoRow &&
                  toggleInfoRow.original &&
                  toggleInfoRow.original.property
                }
                sendPropertyListAction={sendPropertyListAction}
                onClickInviteToProperty={onClickInviteToProperty}
                addPrivateNote={(allNotes) => {
                  setAddPrivateNoteModal({
                    open: true,
                    content: '',
                    allNotes,
                  })
                }}
                enquirerInfo={
                  toggleInfoRow &&
                  toggleInfoRow.original &&
                  toggleInfoRow.original.enquirer
                }
                scheduleViewingAction={scheduleViewingAction}
                onClickLoadMore={onClickLoadMore}
                loadMoreSpinner={loadMoreSpinner}
                messagesMetaData={messagesMetaData}
                fetchingData={fetchingData}
                toggleMessageSelection={toggleMessageSelection}
                selectionConfigs={selectionConfigs}
                teamGUID={currentTeamGUID}
                teamSlug={teamSlug}
              />
            </Box>
          )}

          {infoModalOpen.modalOpen && (
            <Modal
              modalHeading={infoModalOpen.title}
              modalBody={
                typeof infoModalOpen.description === 'String' ? (
                  <AcceptHtmlStyledSpan
                    dangerouslySetInnerHTML={{
                      __html: infoModalOpen.description,
                    }}
                  />
                ) : (
                  infoModalOpen.description
                )
              }
              toggleModal={() => toggleInfoModal()}
              secondaryLabel="Cancel"
              primaryLabel="Save"
              customModalFooter={true}
            />
          )}
          {scheduleViewingModal.modalOpen && (
            <Display.EditViewingModal
              title="Schedule Viewing"
              primaryActionLabel="Schedule"
              secondaryActionLabel="Cancel"
              textToBeDisplayed={scheduleViewingModal.topText}
              primaryButtonActionInParent={updateScheduleViewingModal}
              secondaryButtonAction={() =>
                setScheduleViewingModal({
                  modalOpen: !scheduleViewingModal.modalOpen,
                  topText: '',
                })
              }
              toggleModal={() =>
                setScheduleViewingModal({
                  modalOpen: !scheduleViewingModal.modalOpen,
                  topText: '',
                })
              }
              isScheduleViewing={true}
              fetchAllPropertyFutureViewings={fetchAllPropertyFutureViewings}
              teamGUID={currentTeamGUID}
            />
          )}
          {addPrivateNoteModal.open && (
            <EnquiryNotesModal
              createNote={createNote}
              messageGUID={toggleInfoRow.original.messageGUID}
              allNotes={addPrivateNoteModal.allNotes}
              toggleModal={() =>
                setAddPrivateNoteModal({
                  ...addPrivateNoteModal,
                  open: !addPrivateNoteModal.open,
                  allNotes: '',
                })
              }
              postSave={() =>
                fetchNotesForMessage(toggleInfoRow.original.messageGUID)
              }
            />
          )}
          {registerForViewingModal.modalOpen && (
            <Modal
              modalHeading={registerForViewingModal.title}
              modalBody={_renderRegiterViewingModalBody(
                registerForViewingModal.viewings,
                registerForViewingModal.description,
              )}
              toggleModal={() =>
                setRegisterForViewingModal({
                  ...registerForViewingModal,
                  modalOpen: !registerForViewingModal.modalOpen,
                })
              }
              secondaryLabel="Cancel"
              primaryLabel="Save"
              customModalFooter={false}
              primaryAction={onConfirmRegisterForViewing}
              secondaryAction={() => {
                setRegisterForViewingModal({
                  ...registerForViewingModal,
                  modalOpen: !registerForViewingModal.modalOpen,
                })
              }}
            />
          )}

          {sendPropertyListModal.modalOpen && (
            <Modal
              modalHeading="Send Property List"
              modalBody={sendPropertyListModal.description}
              toggleModal={() =>
                setSendPropertyListModal({
                  ...sendPropertyListModal,
                  modalOpen: !sendPropertyListModal.modalOpen,
                })
              }
              secondaryLabel="Cancel"
              primaryLabel="Save"
              customModalFooter={false}
              primaryAction={onConfirmSendPropertyList}
              secondaryAction={() => {
                setSendPropertyListModal({
                  ...sendPropertyListModal,
                  modalOpen: !sendPropertyListModal.modalOpen,
                })
              }}
            />
          )}

          {displayAddEnquirerModal && (
            <AddEnquirerModal
              teamGUID={currentTeamGUID}
              closeModal={closeAddEnquirerModal}
              refreshMessages={() => fetchMessagesForTeam()}
              filtersState={filtersState.filters}
            />
          )}

          {newMessageModal.isShown && (
            <NewMessageModal
              teamId={currentTeamGUID}
              modeConfig={newMessageModal.modeConfig}
              onDismissClick={dismissSendMessageModalHandler}
              onSendSuccessfully={onSendBulkMessageSuccessfully}
            />
          )}
          {enquiryResponderModalOpen && (
            <EnquiryAutoResponderModal
              toggleModal={toggleEnquiryResponderModal}
              teamStatusData={teamStatusData}
              getTeamStausData={getTeamStausData}
              teamID={currentTeamGUID}
              isUserAdmin={isUserAdmin}
            />
          )}
        </>
      )}
    </>
  )
}

export default NewMessages
