import React from 'react'

import { Link } from 'react-router-dom'

import { urlTo } from 'app/sm/helpers'

class Thanks extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { propertyId } = this.props.match.params

    return (
      <div className="thanks">
        <button className="mt25 widget-btn without-icon">
          <label className="btn-text">Thanks</label>
        </button>
        <div className="row mt25">
          <div className="col-xs-12">
            <label className="thanks-parole">Thanks!</label>
            <br />
            <label className="thanks-moto">
              The snug community thanks you.
            </label>
          </div>
        </div>
        <div className="row thanks-incentive mt25">
          <div className="col-xs-2 thanks-incentive-icon">
            <i className="fa fa-shield" aria-hidden="true" />
          </div>
          <div className="col-xs-10">
            <label className="thanks-parole">First Review Badge</label>
            <br />
            <label className="thanks-moto">Submit you first review</label>
          </div>
        </div>
        <div className="row thanks-incentive mt25">
          <div className="col-xs-2 thanks-incentive-icon">
            <i className="fa fa-heart" aria-hidden="true" />
          </div>
          <div className="col-xs-10">
            <label className="thanks-parole">+30 Points</label>
            <br />
            <label className="thanks-moto">You now have 745 points</label>
          </div>
        </div>
        <div className="thanks-back text-center mt50">
          <Link
            to={urlTo('propertyDetails', { propertyId })}
            className="thanks-moto underline"
          >
            Back to property listing
          </Link>
        </div>
      </div>
    )
  }
}

export default Thanks
