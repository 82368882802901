import { toast, ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

// TODO: Add some checks and use snugLogger to report issues when not receiving the correct type

export function error(string) {
  toast.error(string)
}

export function success(string) {
  toast.success(string)
}

export function info(string) {
  toast.info(string)
}

export function dismiss(ref) {
  toast.dismiss(ref)
}

export function SnugNotifierWrapper() {
  return <ToastContainer theme="colored" autoClose={5000} icon={false} />
}
