import parsePhoneNumber from 'libphonenumber-js'
import parseMax from 'libphonenumber-js/max'
import type { NumberType } from 'libphonenumber-js/types'

import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'

export const LANDLINE_PREFIX = 'T:'
export const MOBILE_PREFIX = 'M:'
export const OFFICE_PREFIX = 'O:'

export const formatPhoneNumber = (contactNumber: any) => {
  if (typeof contactNumber !== 'string') {
    return ''
  }
  // defensively catching possible exceptions in parsePhoneNumber, although ensuring string input
  try {
    const parsedNumber = parsePhoneNumber(contactNumber)
    return parsedNumber?.formatInternational() || contactNumber
  } catch (error) {
    return contactNumber
  }
}

export const formatPhoneNumberE164 = (phone: any) => {
  if (typeof phone !== 'string') {
    return ''
  }
  // defensively catching possible exceptions in parsePhoneNumber, although ensuring string input
  try {
    const parsedNumber = parsePhoneNumber(phone)
    return parsedNumber?.number || phone
  } catch (error) {
    return phone
  }
}

export const NUMBER_TYPE_MOBILE = 'SNUG_MOBILE'
export const NUMBER_TYPE_LANDLINE = 'SNUG_LANDLINE'

const mobileTypes: NumberType[] = ['FIXED_LINE_OR_MOBILE', 'MOBILE']
const landlineTypes: NumberType[] = ['FIXED_LINE']

export const phoneTypePrefixMap: { [key: string]: string } = {
  ...Object.fromEntries(mobileTypes.map((t) => [t, MOBILE_PREFIX])),
  ...Object.fromEntries(landlineTypes.map((t) => [t, LANDLINE_PREFIX])),
}

export const phoneTypeErrorMap: { [key: string]: string } = {
  ...Object.fromEntries(
    mobileTypes.map((t) => [t, ErrorMessages[Errors.ErrorValidatingMobile]]),
  ),
  [NUMBER_TYPE_MOBILE]: ErrorMessages[Errors.ErrorValidatingMobile],
  ...Object.fromEntries(
    landlineTypes.map((t) => [t, 'The number is not a landline number']),
  ),
  [NUMBER_TYPE_LANDLINE]: 'The number is not a landline number',
}

export const getPhoneTypePrefix = (phone: any, defaultPrefix = ''): string => {
  const parsedPhone = !!phone && parsePhoneNumber(phone)
  if (!parsedPhone) return defaultPrefix
  return phoneTypePrefixMap[parsedPhone.getType() as string] || defaultPrefix
}

export const formatAndPrefix = (
  phone: any,
  defaultPrefix = '',
  forceDefault = false,
): string => {
  const formattedPhoneNumber = formatPhoneNumber(phone)
  const prefix = forceDefault
    ? defaultPrefix
    : getPhoneTypePrefix(phone, defaultPrefix)
  return formattedPhoneNumber ? `${prefix} ${formattedPhoneNumber}` : ''
}

export const formatPhoneNumberWithoutCountryCode = (phone: any): string => {
  const BUSINESS_NUMBER_LENGTH = 6
  const parsedNumber = parsePhoneNumber(phone)
  const formattedPhoneNumber = parsedNumber?.formatNational() || phone
  if (formattedPhoneNumber?.length === BUSINESS_NUMBER_LENGTH) {
    return formattedPhoneNumber.replace(/(.{2})/g, '$1 ')
  } else {
    return formattedPhoneNumber
  }
}

const getPhoneNumberType = (phone: string): NumberType => {
  const parsedPhone = !!phone && parseMax(phone)
  if (!parsedPhone) return
  return parsedPhone.getType()
}

export const validateNumberType = (num: string, numType: string): boolean => {
  if (!num || !numType) return false
  const parsedType = getPhoneNumberType(num)
  switch (numType) {
    case NUMBER_TYPE_MOBILE:
      return mobileTypes.includes(parsedType)
    case NUMBER_TYPE_LANDLINE:
      return landlineTypes.includes(parsedType)
    default:
      return false
  }
}

export const getPhoneTypeErrorMessage = (phoneType: string) =>
  phoneTypeErrorMap[phoneType]

// relaxed validation via isPossible() instead of isValid()
// Ref:https://github.com/catamphetamine/libphonenumber-js#using-phone-number-validation-feature
export const relaxedValidateNumberNoType = (phone: string): boolean => {
  const parsedPhone = !!phone && parseMax(phone)
  if (!parsedPhone) return false
  return parsedPhone.isPossible()
}
