import React from 'react'

import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'

const MAX_INPUT_WIDTH = 256

const Container = styled.div`
  .required:after {
    content: ' *';
    color: red;
  }
  .label-text {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
    display: block;
  }
  ${({ takeMaxWidth }) =>
    takeMaxWidth &&
    `
      max-width: ${MAX_INPUT_WIDTH}px;
    `}
`

const BoxInputContainer = styled.label`
  display: flex;
  flex: 1 1 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: ${(props) => props.theme.space[4]}px;

  & input {
    border: 0;
    display: flex;
    flex: 1 1 100%;
    width: 100%;
    font-size: ${(props) => props.theme.fontSizes[4]};
  }
`

function BoxInput({
  placeholder,
  icon,
  id,
  onChange,
  initialValue,
  disabled,
  handleAsValueAndError = false,
  validate,
  labelText,
  required,
  takeMaxWidth,
  labelClassName = '',
}) {
  const handleChange = (event) => {
    if (!handleAsValueAndError) {
      onChange(event.target.value)
    } else {
      const {
        target: { value },
      } = event
      const error = value && validate ? validate(value.trim()) : ''
      onChange({ value, error })
    }
  }

  return (
    <Container takeMaxWidth={takeMaxWidth}>
      {labelText && (
        <span
          className={`label-text ${required && 'required'} ${labelClassName}`}
        >
          {labelText}
        </span>
      )}
      <BoxInputContainer htmlFor={id}>
        {icon && <Box mr="1rem">{icon}</Box>}
        <input
          id={id}
          placeholder={placeholder}
          onChange={handleChange}
          value={initialValue}
          disabled={disabled}
          required={required}
        />
      </BoxInputContainer>
    </Container>
  )
}

export default BoxInput
