import React from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import DefaultButton from 'app/components/buttons/default/component'
import {
  Alert,
  Box,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'
import { HelpOutlineRounded } from 'app/components/design-system-components/icons/actions'
import { TextArea } from 'app/components/design-system-components/inputs'
import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import theme from 'app/match/applicationReportPDF/assets/theme'
import Checkbox from 'app/shared_components/checkbox'
import ApplySectionWrapper from 'app/sm/apply/components/apply_page_section_wrapper_container'
import ApplySegmentWrapper from 'app/sm/apply/components/apply_page_segment_wrapper_container'
import Household from 'app/sm/apply/components/household'
import HouseholdYearlyIncome from 'app/sm/apply/components/household_yealy_income'
import { constructChosenGroupType } from 'app/sm/helpers'
import { SouthAustralianAlert } from 'app/utils/state/helpers'
import * as textHelpers from 'app/utils/text/helpers'

const StyledSnugTip = styled(Box)`
  top: -150px;
  padding: ${theme.space[7]}px;
  @media screen and (max-width: 1159px) {
    top: ${theme.space[7]}px;
    padding: 0;
  }
`

class OfferDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {
        group: {
          ok: true,
          parole: '',
        },
        people: {
          ok: true,
          parole: '',
        },
        pets: {
          ok: true,
          parole: '',
        },
        requests: {
          ok: true,
          parole: '',
        },
        children: {
          ok: true,
          parole: '',
        },
      },
    }
  }

  onAddValue = (value) => {
    return (valueLimit) => {
      if (valueLimit.hasMaxValue) {
        return value >= valueLimit.maxValue ? valueLimit.maxValue : value + 1
      }
      return value + 1
    }
  }

  onChangeAboutYou = (e) => {
    this.props.aboutMeInput(e.target.value)
    this.props.setUnsavedChanges()
  }

  onChangeAboutYou = (e) => {
    this.props.aboutMeInput(e.target.value)
    this.props.setUnsavedChanges()
  }

  onChangeAdults = () => {
    return (data) => {
      this.props.adultInput(data.value)
      this.props.peopleInput(0)
    }
  }

  onChangeChildren = () => {
    return (data) => {
      this.props.childrenInput(data.value)
      this.props.setUnsavedChanges()
    }
  }

  onChangePets = () => {
    return (data) => {
      this.props.petsInput(data.value)
      this.props.setUnsavedChanges()
    }
  }

  onChangeSpecialRequests = (e) => {
    this.props.requestsInput(e.value)
    this.props.setUnsavedChanges()
  }

  onChangeYearlyHouseholdIncome = (e) => {
    const value = e?.value
    if (isNaN(value)) {
      return
    }
    this.props.householdYearlyInput(Number(value))
    this.props.setUnsavedChanges()
  }

  onReduceValue = (value) => {
    return (valueLimit) => {
      if (valueLimit.hasMinValue) {
        return value <= valueLimit.minValue ? valueLimit.minValue : value - 1
      }
      return value <= 0 ? 0 : value - 1
    }
  }

  validate(field, value) {
    let error = {
      parole: '',
      ok: true, // passed validations
    }

    const { setValidation } = this.props

    switch (field) {
      case 'group':
        error.ok = ['Family', 'Couple', 'Single', 'Group'].includes(value)
        if (!error.ok) {
          error.parole =
            'Group type must be either: Family, Single, Couple or Group'
        }
        break
      case 'people':
        error.ok = value !== '' && !isNaN(Number(value))
        if (!error.ok) {
          error.parole = 'People is mandatory'
        }
        break
      case 'children':
        error.ok = value !== '' && !isNaN(Number(value))
        if (!error.ok) {
          error.parole = 'Children is mandatory'
        }
        break
      case 'pets':
        error.ok = value !== '' && !isNaN(Number(value))
        if (!error.ok) {
          error.parole = 'Pets is required and it must be a number'
        }
        break
      default:
        return
    }

    setValidation(field, error.ok)

    this.setState({
      errors: Object.assign({}, this.state.errors, {
        [field]: error,
      }),
    })
  }

  renderSaveAboutUsCheckboxCmp() {
    const {
      isCurrentApplicantSecondary,
      isSaveAboutToProfileEnabled,
      onEnableSaveAboutToProfileChanged,
    } = this.props
    if (isCurrentApplicantSecondary) {
      return
    }

    const onChange = (e) => onEnableSaveAboutToProfileChanged(e.target.checked)

    return (
      <Flex alignItems="center">
        <Checkbox
          label="Save to my profile"
          value={isSaveAboutToProfileEnabled}
          onChange={onChange}
          id="saveAboutToProfile"
        />
        <HelpOutlineRounded
          color={theme.colors.gray400}
          fontSize={theme.fontSizes.pSuperLarge22}
          data-tooltip-id="usageTooltip"
          data-tooltip-content=""
        />
        <ReactTooltip id="usageTooltip" data-type="info">
          <Box
            maxWidth="180px"
            textAlign="center"
            fontSize={theme.fontSizes.pSmall12}
            lineHeight={theme.lineHeights.encrption}
          >
            Save to your profile for use on other applications. Edit or remove
            at any time.
          </Box>
        </ReactTooltip>
      </Flex>
    )
  }

  render() {
    const { errors } = this.state
    const {
      application,
      isApplicationOffered,
      isApplicationDeclined,
      newTenant,
      removeTenant,
      isApplicationEditable,
      onOtherApplicantFormOnBlur,
      onJointApplicationClicked,
      addCurrentNewTenant,
      clearCurrentNewTenant,
      setUnsavedChanges,
      applicants,
      onApplicantFieldChange,
      onAddButtonClick,
      onRemoveButtonClick,
      validateApplicant,
      applicantsErrors,
      showValidationError,
      onFocusHideValidationError,
      onBlurShowValidationError,
      teamSlug,
      children = [],
      onChildrenInfoChanged,
      onChildrenDateOfBirthChanged,
      onChildrenInfoBlured,
      onChildrenFocus,
      onChildDelete,
      onAddAnotherClicked,
      currentApplicant,
      isApplicationCreatedByManager,
      applicationSettingInfo,
      ballotsEnabled,
      ballotsHouseholdIncomeLimit,
      ballotsHouseholdIncomeRegion,
    } = this.props

    const { disable_children_option: disableChildrenOption = false } =
      applicationSettingInfo || {}

    const labelClassname =
      isApplicationOffered || isApplicationDeclined
        ? 'label-floating-fixture'
        : ''
    const classNameForTextArea = isApplicationEditable
      ? ' application_placeholder'
      : ' disabled-link'
    const childrenNumber =
      application && !isNaN(application.children) && application.children > 0
        ? application.children
        : 0
    const groupOptions =
      application && constructChosenGroupType(application.group)
    const adultsNumber = !!application.adults
      ? application.adults
      : application.people
    return (
      <ApplySectionWrapper>
        <ApplySegmentWrapper>
          <div className="col-sm-12 mr0 ml0 p0">
            <Display.SectionHeader
              text="Our household"
              hasSeperator={false}
              textClass="position-relative p0"
              componentClass="mb40 apply-snug-tip-display"
            />
          </div>
        </ApplySegmentWrapper>
        {ballotsEnabled && (
          <ApplySegmentWrapper>
            <div className="col-sm-12 pr0 pl0">
              <Form.MultiSelect
                title="Group"
                options={groupOptions}
                onChange={this.props.groupInput}
                className="p0"
                isEditable={isApplicationEditable}
              />
            </div>
          </ApplySegmentWrapper>
        )}
        <ApplySegmentWrapper componentClass="household-input-bottom-spacing pt0">
          <div className="snug-tip-position col-sm-12 pr0 pl0">
            <Display.SnugTip
              text="Please count pets for all household members."
              componentClass="apply-snug-tip"
            />
          </div>
          <div className="col-sm-4 pr0 pl0">
            <Form.DigitAdderWidget
              text="Adults"
              value={adultsNumber}
              onChange={this.onChangeAdults()}
              onAdd={this.onAddValue(
                application.adults ? application.adults : 0,
              )}
              onMinus={this.onReduceValue(adultsNumber)}
              leftIcon="-"
              rightIcon="+"
              isEditable={isApplicationEditable}
              hasMaxValue
              maxValue={12}
              hasMinValue
              minValue={1}
            />
          </div>
          {!disableChildrenOption && (
            <div className="col-sm-4 pr0 pl0">
              <Form.DigitAdderWidget
                text="Children"
                value={childrenNumber}
                onChange={this.onChangeChildren()}
                onAdd={this.onAddValue(childrenNumber ? childrenNumber : 0)}
                onMinus={this.onReduceValue(childrenNumber)}
                leftIcon="-"
                rightIcon="+"
                isEditable={isApplicationEditable}
                hasMaxValue
                maxValue={11}
                hasMinValue={false}
              />
            </div>
          )}
          <div className="col-sm-4 pr0 pl0">
            <Form.DigitAdderWidget
              text="Pets"
              value={application.pets}
              onChange={this.onChangePets()}
              onAdd={this.onAddValue(application.pets ? application.pets : 0)}
              onMinus={this.onReduceValue(application.pets)}
              leftIcon="-"
              rightIcon="+"
              isEditable={isApplicationEditable}
              hasMaxValue
              maxValue={6}
              hasMinValue={false}
            />
          </div>
        </ApplySegmentWrapper>
        <Household
          isJointApplication={application.isJoint}
          applicants={applicants}
          newTenant={newTenant}
          removeTenant={removeTenant}
          isApplicationEditable={isApplicationEditable}
          onOtherApplicantFormOnBlur={onOtherApplicantFormOnBlur}
          onJointApplicationClicked={onJointApplicationClicked}
          addCurrentNewTenant={addCurrentNewTenant}
          clearCurrentNewTenant={clearCurrentNewTenant}
          setUnsavedChanges={setUnsavedChanges}
          onApplicantFieldChange={onApplicantFieldChange}
          onAddButtonClick={onAddButtonClick}
          onRemoveButtonClick={onRemoveButtonClick}
          validateApplicant={validateApplicant}
          applicantsErrors={applicantsErrors}
          showValidationError={showValidationError}
          onBlurShowValidationError={onBlurShowValidationError}
          onFocusHideValidationError={onFocusHideValidationError}
          teamSlug={teamSlug}
          currentApplicant={currentApplicant}
          isApplicationCreatedByManager={isApplicationCreatedByManager}
          applicationSettingInfo={applicationSettingInfo}
        />
        {!disableChildrenOption && children.length > 0 && (
          <ApplySegmentWrapper componentClass="household-input-bottom-spacing pt0">
            <Display.GreySubheading text="Children (Less than 18yo)" />
            {children.map((child, index) => {
              return (
                <ChildrenItem
                  key={index}
                  child={child}
                  nth={index}
                  onChildrenInfoChanged={onChildrenInfoChanged}
                  onChildrenDateOfBirthChanged={onChildrenDateOfBirthChanged(
                    index,
                  )}
                  onChildDelete={onChildDelete(index)}
                  index={index}
                />
              )
            })}
            <div className="text-center col-sm-5 p0">
              <DefaultButton
                text="Add Another child"
                onClick={() => onAddAnotherClicked()}
                size="large"
                buttonType="default"
              />
            </div>
          </ApplySegmentWrapper>
        )}

        {ballotsEnabled && (
          <>
            <HouseholdYearlyIncome
              application={application}
              onChangeYearlyHouseholdIncome={this.onChangeYearlyHouseholdIncome}
              ballotsHouseholdIncomeLimit={ballotsHouseholdIncomeLimit}
              ballotsHouseholdIncomeRegion={ballotsHouseholdIncomeRegion}
              teamSlug={teamSlug}
            />
            <div className="col-sm-12 pr0 pl0 mb10">
              <StyledSnugTip className="snug-tip-position ">
                <Display.SnugTip
                  text="Please note that your income will be verified and assessed for affordability should you be successful in the ballot"
                  componentClass="apply-snug-tip"
                />
              </StyledSnugTip>
            </div>
          </>
        )}

        {!isApplicationCreatedByManager && (
          <ApplySegmentWrapper>
            <div className="col-sm-12 pr0 pl0">
              <Flex
                alignItems="center"
                className="standard-grey-subheading standard-form-text-area-component standard-input-box"
              >
                <TextSpan mr={8}>
                  About us {ballotsEnabled ? '(optional)' : '(important)'}
                </TextSpan>
                {this.renderSaveAboutUsCheckboxCmp()}
              </Flex>
              <Flex
                mb={8}
                className="apply-snug-tip-display about-us-tip-display"
              >
                <TextArea
                  containerProps={{
                    padding: 0,
                    className: 'standard-form-text-area-component',
                  }}
                  value={application.aboutMe}
                  onChange={this.onChangeAboutYou}
                  rows="4"
                  placeholder={
                    textHelpers.APPLICATION_SUBMIT_ABOUT_US_PLACEHOLDER
                  }
                  disabled={!isApplicationEditable}
                  style={{ padding: '12px 16px' }}
                  className="standard-text-area"
                />
                {!ballotsEnabled && (
                  <div className="snug-tip-position">
                    <Display.SnugTip
                      text="Be personal! This is your chance to say hi to the owner."
                      componentClass="apply-snug-tip"
                    />
                  </div>
                )}
              </Flex>
              <Form.CommentArea
                label="Special requests (optional)"
                readOnly={!isApplicationEditable}
                value={application.requests}
                onBlur={(e) => this.validate('requests', e.target.value)}
                onChange={this.onChangeSpecialRequests}
                rows="4"
                cols="70"
                placeholder="Add any special requests for Property Manager."
                inputBoxClass="pt0"
              />
            </div>
          </ApplySegmentWrapper>
        )}
      </ApplySectionWrapper>
    )
  }
}

const ChildrenItem = ({
  onChildrenInfoChanged,
  nth,
  child = {},
  onChildrenDateOfBirthChanged,
  onChildDelete,
}) => {
  const { firstName, lastName, dob = moment(), error } = child
  return (
    <Display.GreyContainer containerClass="mb20 col-sm-12">
      <div className="secondary-applicant-form-position">
        <div className="field-container row">
          <div className="col-sm-6">
            <Display.GreySubheading
              text={`Child ${nth + 1}`}
              componentClass="applicant-heading-spacing"
            />
          </div>
          <div className="col-sm-6">
            <DefaultButton
              text="Remove"
              componentClass="remove-tenant-record destructive-hollow"
              size="small"
              onClick={() => onChildDelete(nth)}
              buttonType="destructive"
            />
          </div>
        </div>

        <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
          <div className="col-md-6 p0 width100">
            <Form.InputName
              label="First Name (optional)"
              value={firstName}
              onChange={onChildrenInfoChanged(nth, 'firstName')}
              inputClass="width100"
              componentClass="margin-profile-item-left"
              isOptional
            />
          </div>
          <div className="col-md-6 p0 width100">
            <Form.InputName
              label="Last Name (optional)"
              value={lastName}
              onChange={onChildrenInfoChanged(nth, 'lastName')}
              inputClass="width100"
              componentClass="margin-profile-item-right"
              isOptional
            />
          </div>
        </Form.ProfilePagesRowLayout>
        <SouthAustralianAlert />
        <Form.ProfilePagesRowLayout>
          <div className="col-md-6 p0">
            <Form.InputDate
              label="Year of Birth (optional)"
              value={dob ? moment(dob).format('YYYY') : ''}
              onChange={onChildrenDateOfBirthChanged}
              id="dob"
              componentClass="margin-profile-item-left"
              containerClassName="width100"
              viewMode="years"
              readOnly={false}
              customisedDateFormat={'YYYY'}
              placeholder={'yyyy'}
            />
          </div>
        </Form.ProfilePagesRowLayout>
      </div>
    </Display.GreyContainer>
  )
}

export default OfferDetails
