import React from 'react'

import PropTypes from 'prop-types'

const propTypes = {
  isActive: PropTypes.bool,
  isActiveHalf: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

const CustomStar = (props) => {
  const starProps = Object.assign({}, props)
  const nameMap = {
    isDisabled: 'is-disabled',
    isActive: 'is-active',
    isActiveHalf: 'is-active-half',
  }
  const className = Object.keys(nameMap)
    // eslint-disable-next-line no-sequences
    .filter((prop) => (delete starProps[prop], props[prop]))
    .map((prop) => nameMap[prop])
    .join(' ')
  return (
    <div className={`react-rater-star ${className}`}>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 16 15">
        <path
          id="Path"
          fill="currentColor"
          d="M15.4,5.5l-4.9-0.7L8.3,0.4C8.2,0.3,8.1,0.2,8,0.2c-0.1,0-0.2,0.1-0.3,0.2L5.5,4.8L0.6,5.5
	c-0.1,0-0.2,0.1-0.3,0.2s0,0.3,0.1,0.3L4,9.5l-0.8,4.9c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.4,0L8,12.5l4.4,2.3
	c0.1,0.1,0.2,0,0.4,0c0.1-0.1,0.2-0.2,0.1-0.3L12,9.5l3.5-3.5c0.1-0.1,0.1-0.2,0.1-0.3C15.6,5.6,15.5,5.5,15.4,5.5L15.4,5.5z"
        />
      </svg>
    </div>
  )
}

CustomStar.defaultProps = {
  isActive: false,
  isActiveHalf: false,
  isDisabled: false,
}

CustomStar.propTypes = propTypes

export default CustomStar
