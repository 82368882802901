import { connect } from 'react-redux'

import RegisterEmailForm from 'app/session/register_components/register_email_form'
import { clearError, register } from 'app/session/session_actions'
import { checkForAgencyRules } from 'app/sm/property_details/property_details_actions'

const mapStateToProps = ({ session }) => ({
  error: session.error,
  attempts: session.attempts,
})

const mapDispatchToProps = (dispatch) => ({
  register: (user, isFromRR) => register(user, isFromRR),
  clearError: () => clearError(),
  getAgencyRules: (agencyGUID) => dispatch(checkForAgencyRules(agencyGUID)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterEmailForm)
