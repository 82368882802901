import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  getExcludedDates,
  updateExcludedDates,
} from 'app/services/http/teams/properties'
import * as snugNotifier from 'app/services/snugNotifier'
import Spinner from 'app/sm/common/spinner'
import PropertyExcludedTimes from 'app/sm/properties/components/excluded_times'
import { validateDateEntries } from 'app/sm/properties/components/excluded_times/helpers'

const StyledCheckBoxContainer = styled.label`
  input[type='checkbox'] {
    margin-right: ${theme.baseSpace}px;
  }
  span {
    font-size: ${theme.space[5]}px !important;
    font-weight: ${theme.fontWeights[3]} !important;
  }
  .property-name {
    font-weight: ${theme.fontWeights[6]};
  }
`

const ExcludeViewingScheduleModal = ({ toggleModal, teamGUID, property }) => {
  const [fetchingDates, setFetchingDates] = useState(true)
  const [dateEntries, setDateEntries] = useState([])
  const [posting, setPosting] = useState(false)
  const [currentExcludedDates, setCurrentExcludedDates] = useState([])
  const [excludePropertyChecked, setExcludePropertyChecked] = useState(false)

  useEffect(() => {
    const { guidID: propertyGUID } = property

    if (propertyGUID && teamGUID) {
      getExcludedDates(teamGUID, propertyGUID).then((response) => {
        setCurrentExcludedDates(response.data?.dates || [])
        setFetchingDates(false)
        setExcludePropertyChecked(response.data?.propertyExcluded || false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property])

  const propertyName = () => {
    if (!property.address) {
      return
    }

    return `${property.address.friendlyName}${
      property.address.suburb ? `, ${property.address.suburb}` : ''
    }`
  }

  const updateDateEntries = () => {
    const errors = validateDateEntries(
      dateEntries.filter((entry) => !entry.guid),
    )
    if (errors.length) {
      snugNotifier.error(errors[0])
      return
    }

    setPosting(true)

    updateExcludedDates(teamGUID, property.guidID, {
      DateEntries: dateEntries,
      PropertyGUID: property.guidID,
      PropertyExcluded: excludePropertyChecked,
    })
      .then((response) => {
        setCurrentExcludedDates(response.data.dates)
        setExcludePropertyChecked(response.data?.propertyExcluded || false)
        snugNotifier.success('Dates updated successfully')
      })
      .catch(({ message }) => {
        snugNotifier.error(message)
      })
      .finally(() => setTimeout(() => setPosting(false), 500))
  }

  return (
    <GenericModal
      maxWidth="800px"
      onDismiss={toggleModal}
      title="Exclude from viewing schedule"
      modalHeading="Exclude from viewing schedule"
      children={
        <div>
          {fetchingDates && <Spinner />}
          {!fetchingDates && property && (
            <>
              <StyledCheckBoxContainer className="ibm filter-today-checkbox">
                <input
                  type="checkbox"
                  className="input-box wa pt0"
                  onChange={() =>
                    setExcludePropertyChecked(!excludePropertyChecked)
                  }
                  onClick={(event) => event.stopPropagation()}
                  checked={excludePropertyChecked}
                />
                <span>
                  Exclude{' '}
                  <strong className="property-name">{propertyName()}</strong>{' '}
                  from the viewing schedule?
                </span>
              </StyledCheckBoxContainer>

              {excludePropertyChecked && (
                <PropertyExcludedDatesContainer
                  onEntriesChanged={setDateEntries}
                  currentExcludedDates={currentExcludedDates}
                />
              )}
            </>
          )}
        </div>
      }
      toggleModal={() => toggleModal()}
      secondaryLabel="Cancel"
      primaryLabel="Save"
      modalFooter={
        <GenericModalFooter
          secondaryBtnConfig={{
            title: 'Cancel',
            onClick: () => toggleModal(),
            props: {
              disabled: posting,
            },
          }}
          primaryBtnConfig={{
            title: `Save`,
            onClick: () => updateDateEntries(),
            props: {
              loading: posting,
              disabled: posting,
            },
          }}
        />
      }
    />
  )
}

export default ExcludeViewingScheduleModal

const PropertyExcludedDatesContainer = ({
  onEntriesChanged,
  currentExcludedDates,
}) => {
  return (
    <PropertyExcludedTimes
      currentExcludedDates={currentExcludedDates}
      onEntriesChanged={onEntriesChanged}
    />
  )
}
