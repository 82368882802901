import React from 'react'

import styled from 'styled-components'

const CourtRecords = styled.div`
  padding-top: 0px;
`
const Label = styled.div`
  justify-content: space-between;
  font-size: 14px;
  line-height: 1.6;
`
const Title = styled.p`
  color: #22212a;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
`
const CourtRecord = styled.div`
  justify-content: space-between;
  font-size: 14px;
  padding: 13px 0;
  border-bottom: 1px solid #f4f4f4;
`

const CourtRecordsInfo = ({ title = '', courtRecordsData = [] }) => {
  return (
    <CourtRecords>
      <Title>{title}</Title>
      {courtRecordsData.length > 0 &&
        courtRecordsData.map((info, index) => {
          const {
            Amount = '',
            Association = '',
            CoBorrower = '',
            CourtRecordType = '',
            CourtType = '',
            Creditor = '',
            Date = '',
            PlaintiffNumber = '',
            Status = '',
            StatusDate = '',
          } = info
          return (
            <CourtRecord key={index}>
              {Amount && <Label>Amount: {Amount}</Label>}
              {Association && <Label>Association: {Association}</Label>}
              {CoBorrower && <Label>Co-Borrower: {CoBorrower}</Label>}
              {CourtRecordType && (
                <Label>CourtRecordType: {CourtRecordType}</Label>
              )}
              {CourtType && <Label>Court Type: {CourtType}</Label>}
              {Creditor && <Label>Creditor: {Creditor}</Label>}
              {Date && <div>Date: {Date}</div>}
              {PlaintiffNumber && (
                <div>Plaintiff Number: {PlaintiffNumber}</div>
              )}
              {Status && <div>Status: {Status}</div>}
              {StatusDate && <div>Status Date: {StatusDate}</div>}
            </CourtRecord>
          )
        })}
    </CourtRecords>
  )
}

export default CourtRecordsInfo
