import React from 'react'

import { Link } from 'react-router-dom'

import AssignedTo from 'app/sm/property_maintenance/components/report/assigned_to'
import Chat from 'app/sm/property_maintenance/components/report/chat'
import Description from 'app/sm/property_maintenance/components/report/description'
import Documents from 'app/sm/property_maintenance/components/report/documents'
import MaintenanceLog from 'app/sm/property_maintenance/components/report/maintenance_log'
import ReportedBy from 'app/sm/property_maintenance/components/report/reported_by'
import Status from 'app/sm/property_maintenance/components/report/status'

function MaintenanceReport() {
  return (
    <div className="sm-maintenance-report">
      <div className="section-title xs-row">
        <div className="left">
          <Link to={'/'} className="lh1">
            <h3 className="lh1">
              <i className="icon-arrow-left fs16 pt2 pr10 xs-ibm"></i>
              <span className="ibm lh1">
                <span className="ibm">Maintenece</span>{' '}
                <span className="ibm xs-hide">Report</span>
              </span>
            </h3>
          </Link>
        </div>
        <div className="right">
          <div className="dropdown actions">
            <div className="dropdown-toggle" data-toggle="dropdown">
              <button className="btn btn-small-xs">
                <i className="icon-more left ibm"></i>
                <span className="ibm">Actions</span>
              </button>
            </div>
            <ul className="dropdown-menu dropdown-menu-right mt5">
              <li>
                <Link to={'/'}>Preview</Link>
              </li>
              <li>
                <Link to={'/'}>Edit</Link>
              </li>
              <li className="divider"></li>
              <li>
                <Link to={'/'}>Maintenece</Link>
              </li>
              <li>
                <Link to={'/'}>Reports</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="two-col-box sm55">
        <div className="col-first">
          <Description />
          <Status />
          <Documents />
        </div>
        <div className="col-second">
          <ReportedBy />
          <Chat />
          <AssignedTo />
        </div>
      </div>
      <MaintenanceLog />
    </div>
  )
}

export default MaintenanceReport
