import React, { useEffect, useState } from 'react'

import { Button } from 'app/components/design-system-components/Button'
import { Chip } from 'app/components/design-system-components/chip'
import Flex from 'app/components/design-system-components/Flex'
import { CustomInputWrapper } from 'app/forms/CustomInputWrapper'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const defaultExpandedLabel = (itemsCount, maxCount) => 'show less'
const defaultCollapsedLabel = (itemsCount, maxCount) =>
  `+ ${itemsCount - maxCount} others`

const useShowMore = ({
  maxCount,
  getExpandedLabel = defaultExpandedLabel,
  getCollapsedLabel = defaultCollapsedLabel,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [items, setItems] = useState([])
  const [config, setConfig] = useState({
    shownItems: [],
    showControlBtn: false,
    btnText: '',
    handleControlClick: () => {},
  })

  useEffect(() => {
    const itemsCount = items.length
    if (itemsCount <= maxCount) {
      setConfig({
        shownItems: items,
        showControlBtn: false,
      })
    } else {
      const buttonText = expanded
        ? getExpandedLabel(itemsCount, maxCount)
        : getCollapsedLabel(itemsCount, maxCount)
      const toShowItems = expanded ? items : items.slice(0, maxCount)
      setConfig({
        shownItems: toShowItems,
        showControlBtn: true,
        btnText: buttonText,
        handleControlClick: () => setExpanded(!expanded),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, items])

  return [config, setItems]
}

export const Chips = ({
  maxShownSelections = 4,
  getExpandedLabel,
  getCollapsedLabel,
  value,
  changeHandler,
  blurHandler,
  dismissEnabled = true,
  ...props
}) => {
  const [config, setItems] = useShowMore({
    maxCount: maxShownSelections,
    getExpandedLabel,
    getCollapsedLabel,
  })

  useEffect(() => {
    setItems(value || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const selectedItems = value || []

  const onDismissItem = ({ optionMeta: itemMeta }) => {
    const toBeDeletedItemIndex = selectedItems.findIndex(
      ({ optionMeta }) => optionMeta.id === itemMeta.id,
    )

    const newItemsList = [...selectedItems]
    newItemsList.splice(toBeDeletedItemIndex, 1)
    changeHandler(newItemsList)
    setTimeout(() => blurHandler())
  }

  const { shownItems, showControlBtn, btnText, handleControlClick } = config

  return (
    <Flex flexWrap="wrap" alignItems="center">
      {shownItems.map((item, index) => (
        <Chip
          key={item.optionMeta.id}
          showDismissBtn={dismissEnabled}
          onDismissClicked={() => onDismissItem(item)}
          mt={theme.space[1] + 'px'}
          mb={theme.space[1] + 'px'}
          {...(index + 1 !== selectedItems.length
            ? { mr: theme.space[3] + 'px' }
            : {})}
        >
          {item.optionMeta.displayText}
        </Chip>
      ))}

      {showControlBtn && (
        <Button
          variant="linkDeepBlue"
          ml={theme.space[3] + 'px'}
          onClick={(e) => {
            // todo: what's wrong with this! clicking this button for some reason is firing touched for different inputs!!
            e.preventDefault()
            handleControlClick()
          }}
        >
          {btnText}
        </Button>
      )}
    </Flex>
  )
}

export const ChipsField = (props) => {
  const { name } = props
  return (
    <CustomInputWrapper
      name={name}
      customInputCmp={(customInputProps) => (
        <Chips {...customInputProps} {...props} />
      )}
    />
  )
}
