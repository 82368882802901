import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const createContact = (teamId, formData) => {
  const url = `${apiBaseUrl}sm/teams/${teamId}/contacts`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(formData),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const putContact = (teamId, contactGUID, formData) => {
  const url = `${apiBaseUrl}sm/teams/${teamId}/contacts/${contactGUID}`
  return fetch(url, {
    credentials: 'include',
    method: 'PUT',
    body: JSON.stringify(formData),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getContacts = (teamId) => {
  const url = `${apiBaseUrl}sm/teams/${teamId}/contacts`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (contactInfo) => ({ ok: true, contactInfo }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const searchContacts = (teamId, searchStr, from, size) => {
  const url = `${apiBaseUrl}sm/teams/${teamId}/contacts/search?q=${searchStr}&from=${from}&size=${size}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (contactInfo) => ({ ok: true, contactInfo }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getContact = (teamId, contactGUID) => {
  const url = `${apiBaseUrl}sm/teams/${teamId}/contacts/${contactGUID}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (contactInfo) => ({ ok: true, contactInfo }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
