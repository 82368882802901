import React from 'react'

import { connect } from 'react-redux'

import { fetchTeamManagerList } from 'app/bond_cover/agency/agency_actions'
import { Alert, Box, Flex } from 'app/components/design-system-components'
import { ModalBasicFooter } from 'app/components/design-system-components/Modal'
import { sendShareWithOwner } from 'app/dashboard/dashboard_actions'
import {
  mapTeamMembersOptions,
  OWNER_PILL_TEXT,
  TEAM_MEMBER_PILL_TEXT,
} from 'app/match/propertyReport/container'
import {
  getTeamBGCheckPrice,
  getTeamSettings,
} from 'app/services/http/teams/settings'
import * as snugNotifier from 'app/services/snugNotifier'
import * as validations from 'app/shared_components/validations'
import { fetchApplicationNew } from 'app/sm/apply/apply_actions'
import { parseDataUtil } from 'app/sm/helpers'
import { fetchCurrentOwners } from 'app/sm/inspections/inspections_actions'
import {
  ErrorContainer,
  Message,
} from 'app/sm/remarketing/remarketing_container'
import { fetchApplicationApplicantBackgroundCheckReportDetails } from 'app/sm/renter_background_check_report/action'
import { ACCOUNT_TYPE_LITE } from 'app/utils/accounts/helpers'
import { getBackgroundCheck } from 'app/utils/backgroundcheck/api'
import * as leaseOfferHelpers from 'app/utils/leaseOffer'
import { currencyFormat } from 'app/utils/numbers/helpers'
import * as objectHelpers from 'app/utils/objects/helpers'
import * as textHelpers from 'app/utils/text/helpers'

const APPLICATIONS_SHARED_SUCCESS_MESSAGE =
  'Application summary has been shared by email'
const VALIDATION_FAILED_NO_RECIPIENTS_MESSAGE = 'Please add a recipient'
export const SELECTOR_SHARE_WITH_ACCEPT_LINK = 'Share with Approve Link'
export const SELECTOR_SHARE_ONLY = 'Share Only'

class ShareWithOwnerContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      message: {
        recipientAddress: '',
        body: '',
      },
      isCheckedWithOwner: false,
      errors: {},
      application: null,
      apiError: '',
      showSpinner: false,
      isOwnerAcceptEnabled: false,
      isLiteAccount: false,
      isEquifaxActivated: false,
      allowBGCEnabled: false,
      requestBGCApproval: false,
      selectShareWitOwnerOption: [
        {
          value: SELECTOR_SHARE_ONLY,
          picked: false,
        },
        {
          value: SELECTOR_SHARE_WITH_ACCEPT_LINK,
          picked: true,
        },
      ],
      combinedOwnerAndManagersList: [],
      emailList: [],
      filteredSearchResults: [],
      alreadyHasBackgroundCheck: false,
    }
  }

  componentDidMount() {
    const { applicationGUID } = this.props
    const { fetchCurrentOwners } = this.props

    this.preloadTeamSettings()

    this.props
      .fetchApplicationNew(applicationGUID)
      .then((application) => {
        this.setState({ application }, () => {
          const { application } = this.state
          const { offer = {}, applicants = [] } = application || {}
          const { property = {} } = offer || {}
          const { guidID = '', agencyID: teamGUID = '' } = property || {}
          const primaryApplicant = applicants?.find(
            (applicant) => applicant.isPrimary,
          )
          const { guidID: applicantGUID = '' } = primaryApplicant || {}
          getBackgroundCheck(applicationGUID, applicantGUID).then((result) => {
            this.setState({
              alreadyHasBackgroundCheck: !!result,
              requestBGCApproval: false,
            })
          })
          if (teamGUID) {
            fetchCurrentOwners(teamGUID, guidID).then((result) => {
              let ownersInEmailList = []
              let allOwnersWithPillText = []

              result?.forEach((owner) => {
                let ownerWithPillText = { ...owner }
                const ownerEmailListFormat = {
                  text: `${owner.email} (${OWNER_PILL_TEXT})`,
                  status: 'valid',
                }
                ownerWithPillText.pillText = OWNER_PILL_TEXT
                ownersInEmailList.push(ownerEmailListFormat)
                allOwnersWithPillText.push(ownerWithPillText)
              })
              this.setState(
                {
                  combinedOwnerAndManagersList: [
                    ...this.state.combinedOwnerAndManagersList,
                    ...[...allOwnersWithPillText],
                  ],
                  emailList: ownersInEmailList,
                },
                () => {
                  this.fetchManagers(teamGUID)
                },
              )
            })
          }
        })
      })
      .catch((error) =>
        this.setState({
          apiError: `Failed to retrieve the application. Error: ${error}`,
        }),
      )
  }

  componentDidUpdate(prevProps) {
    const { currentTeam: prevCurrentTeam } = prevProps
    const { currentTeam } = this.props

    if (
      !!currentTeam &&
      (!prevCurrentTeam || prevCurrentTeam.guid !== currentTeam.guid)
    ) {
      this.preloadTeamSettings()
    }
  }

  preloadTeamSettings = () => {
    const { currentTeam } = this.props
    this.setState({
      isEquifaxActivated: currentTeam.enableBackgroundCheckBundle,
      isLiteAccount: currentTeam?.accountType === ACCOUNT_TYPE_LITE,
    })

    if (currentTeam.enableBackgroundCheckBundle) {
      getTeamSettings(currentTeam.guid, {
        keys: ['application'],
      }).then(({ settingInfo }) => {
        const { application } = settingInfo
        const { share_app_with_owner_bgc_approval_enabled } =
          parseDataUtil.convertObjValueFromStringToBoolean(application)

        this.setState({
          allowBGCEnabled: share_app_with_owner_bgc_approval_enabled,
          requestBGCApproval: share_app_with_owner_bgc_approval_enabled,
        })

        if (share_app_with_owner_bgc_approval_enabled) {
          getTeamBGCheckPrice(currentTeam?.guid).then(({ displayPrice }) => {
            const { priceIncGST = '' } = displayPrice
            this.setState({
              bgcPrice: currencyFormat(Number(priceIncGST), 2),
            })
          })
        }
      })
    }
  }

  buildTeamContactOptions = (allTeamMembers) => {
    const sortScore = (member) => {
      return (member.isListingAgent ? 10 : 0) + (member.isInspector ? 1 : 0)
    }
    let sortedMembers = allTeamMembers.map((member) => {
      const { profile } = member
      return {
        ...member,
        ...profile,
      }
    })
    if (allTeamMembers && allTeamMembers.length > 0) {
      sortedMembers = objectHelpers.alphabeticallySortObjectsBasedOnKey(
        sortedMembers,
        'firstName',
      )
    }
    return sortedMembers.sort((memberA, memberB) => {
      return sortScore(memberB) - sortScore(memberA)
    })
  }

  fetchManagers = (teamGUID) => {
    const { fetchTeamManagerList } = this.props
    if (teamGUID) {
      fetchTeamManagerList(teamGUID)
        .then(({ managerList = [] }) => {
          const mappedAssignedTeamMembers =
            this.buildTeamContactOptions(managerList)

          const chosenManager = mappedAssignedTeamMembers.findIndex(
            (member) => !!member.isListingAgent,
          )

          const allList = [
            ...this.state.combinedOwnerAndManagersList,
            ...this.addManagersProfileToCombinedList(managerList),
          ]

          const deDuplicatedRows = this.deDuplicatedRows(allList)

          this.setState({
            managerList,
            assignedTeamMembers: mappedAssignedTeamMembers,
            chosenManager: chosenManager === -1 ? '' : chosenManager,
            combinedOwnerAndManagersList: allList,
            teamMemberOptionsList: mapTeamMembersOptions(
              mappedAssignedTeamMembers,
            ),
            filteredSearchResults: deDuplicatedRows,
          })
        })
        .catch((error) => {
          snugNotifier.error(error)
        })
    }
  }

  addManagersProfileToCombinedList = (managersList = []) => {
    return managersList?.map((manager) => {
      return { ...manager.profile, pillText: TEAM_MEMBER_PILL_TEXT }
    })
  }

  emailKeyDown = (event) => {
    const { value } = event.target
    const { keyCode } = event
    const enterButtonKeyCode = 13
    if (keyCode === enterButtonKeyCode) {
      this.emailOnBlur(value)
    }
  }

  onSendMessage = () => {
    const { sendShareWithOwner, toggleShareWithOwnerModal } = this.props
    const { applicationGUID } = this.props
    const { selectShareWitOwnerOption, emailList, requestBGCApproval } =
      this.state
    if (emailList.length === 0) {
      snugNotifier.error('Please specify at least one recipient.')
      return
    } else if (emailList.find((email) => email.status === 'invalid')) {
      snugNotifier.error('Invalid email address')
      return
    }
    const recipients = emailList.map((email) => email.text.split(' ')[0])
    const messageBody = this.state.message['body']
    let withOwnerStatus = this.state.isCheckedWithOwner
    let isOwnerAcceptEnabled = false

    // NOTE: map above returns an array of 1 empty item.
    if (recipients.length === 0 || recipients[0].length === 0) {
      snugNotifier.error(VALIDATION_FAILED_NO_RECIPIENTS_MESSAGE)
      return
    }
    const optionPicked = selectShareWitOwnerOption.filter(
      (option) => option.picked,
    )
    if (optionPicked.length) {
      if (optionPicked[0].value === SELECTOR_SHARE_WITH_ACCEPT_LINK) {
        isOwnerAcceptEnabled = true
      }
    }

    this.setState({ showSpinner: true })
    sendShareWithOwner(
      applicationGUID,
      recipients,
      messageBody,
      withOwnerStatus,
      isOwnerAcceptEnabled,
      requestBGCApproval,
    )
      .then(() => {
        snugNotifier.success(APPLICATIONS_SHARED_SUCCESS_MESSAGE)
      })
      .then(() => {
        toggleShareWithOwnerModal()
      })
      .catch((apiError) => {
        snugNotifier.error(apiError)
      })
      .finally(
        this.setState({
          showSpinner: false,
        }),
      )
  }

  setValidationErrors = (field, error = []) => {
    let { errors } = this.state
    if (error.length === 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  isDisabled = (message) => {
    if (message === undefined) {
      return true
    }
    if (message.recipientAddress === undefined) {
      return true
    }
    if (message.recipientAddress.trim().length === 0) {
      return true
    }
    if (this.state.showSpinner) {
      return true
    }
    if (
      this.state.errors &&
      this.state.errors.recipientAddress &&
      this.state.errors.recipientAddress.length > 0
    ) {
      return true
    }
    return !!(
      this.state.errors &&
      this.state.errors.body &&
      this.state.errors.body.length > 0
    )
  }

  updateApplicationStatus = (event) => {
    const { checked } = event.target || {}
    this.setState({
      isCheckedWithOwner: checked,
    })
  }

  updateRequestBGCApproval = (event) => {
    const { checked } = event.target || {}
    this.setState({ requestBGCApproval: checked })
  }

  deDuplicatedRows = (rows = []) => {
    return rows.filter((searchResultRow) => {
      return !this.state.emailList.find((email) => {
        return email.text.split(' ')[0] === searchResultRow.email
      })
    })
  }

  emailOnchange = (value) => {
    const { combinedOwnerAndManagersList } = this.state
    this.setState({
      message: {
        ...this.state.message,
        recipientAddress: value,
      },
      showEmailResults: true,
    })
    let searchResultRow = combinedOwnerAndManagersList?.filter(
      ({
        email,
        firstName,
        lastName,
        mobilePhone,
        phone,
        homePhone,
        workPhone,
      }) =>
        [
          email,
          firstName,
          lastName,
          mobilePhone,
          phone,
          homePhone,
          workPhone,
        ].some((field) =>
          `${field || ''}`.toLowerCase().includes(value.toLowerCase()),
        ),
    )
    if (searchResultRow && searchResultRow.length > 0) {
      searchResultRow = objectHelpers.alphabeticallySortObjectsBasedOnKey(
        searchResultRow,
        'firstName',
      )
    }
    const deDuplicatedRows = this.deDuplicatedRows(searchResultRow)
    this.setState({
      filteredSearchResults: deDuplicatedRows,
    })
  }

  updateMessage = (event, field) => {
    const { value } = event.target
    const { message } = this.state
    switch (field) {
      case 'body':
        message.body = value
        break
      case 'sendSMS':
        message.sendSMS = !message.sendSMS
        break
      default:
        break
    }
    this.setState({
      message: message,
      unsavedChanges: true,
    })
  }

  validate = (field) => {
    return () => {
      const { message } = this.state
      switch (field) {
        case 'recipientAddress':
          return this.setValidationErrors(
            field,
            validations.validateEmailAddresses(
              message.recipientAddress,
              'Invalid email address',
            ),
          )
        default:
          return false
      }
    }
  }
  onChangeMultiSelect = (val) => {
    const { selectShareWitOwnerOption } = this.state
    const targetOption = selectShareWitOwnerOption.find(
      (option) => option.value === val,
    )
    let shareWithAcceptLinkOption
    selectShareWitOwnerOption.forEach((option) => {
      if (option.value !== val) {
        option.picked = false
      }
      if (option.value === SELECTOR_SHARE_WITH_ACCEPT_LINK) {
        shareWithAcceptLinkOption = option
      }
    })
    targetOption.picked = !targetOption.picked
    this.setState({
      selectShareWitOwnerOption,
    })

    if (!shareWithAcceptLinkOption.picked) {
      this.setState({ requestBGCApproval: false })
    }
  }

  emailOnFocus = () => {
    const { combinedOwnerAndManagersList } = this.state
    const deDuplicatedRows = this.deDuplicatedRows(combinedOwnerAndManagersList)
    this.setState({
      showEmailResults: true,
      filteredSearchResults: deDuplicatedRows,
    })
  }

  emailOnBlur = (value) => {
    if (!value || !value.trim()) {
      this.setState({
        showEmailResults: false,
      })
      return
    }
    const emailValueWithoutSpaces = leaseOfferHelpers.removeSpacesInText(value)
    const isEmailValid = !validations.validateEmail(emailValueWithoutSpaces)[0]
    const { emailList } = this.state
    const targetEmail = {
      text: emailValueWithoutSpaces,
      status: isEmailValid ? 'valid' : 'invalid',
    }
    emailList.push(targetEmail)
    this.setState({
      emailList,
      message: {
        ...this.state.message,
        recipientAddress: '',
      },
      showEmailResults: false,
    })
  }

  filteredItemSelected = (item) => {
    this.emailOnBlur(item.email)
  }

  removeEmailFromList = (emailIndex) => {
    const { emailList } = this.state
    const filteredEmailList = emailList.filter(
      (_, index) => Number(index) !== Number(emailIndex),
    )
    this.setState({
      emailList: filteredEmailList,
    })
  }

  render() {
    const {
      showSpinner,
      isCheckedWithOwner,
      selectShareWitOwnerOption,
      allowBGCEnabled,
      isLiteAccount,
      isEquifaxActivated,
      bgcPrice,
      requestBGCApproval,
      showEmailResults,
      filteredSearchResults,
      emailList,
      alreadyHasBackgroundCheck,
    } = this.state
    const {
      teamSlug,
      toggleShareWithOwnerModal,
      showUpdatedEmailNotice = false,
    } = this.props

    return (
      <div className="profile-screen">
        {showUpdatedEmailNotice && (
          <Alert
            actionIconBtnConfig={undefined}
            variant="blueWithBg"
            showLeftIcon={false}
            mb={7}
            mt={2}
          >
            <Flex>
              <Box mr={4}>🎉</Box>
              <Box>{textHelpers.SHARE_APPLICATION_WITH_OWNER_NOTICE_TEXT}</Box>
            </Flex>
          </Alert>
        )}
        <Message
          message={this.state.message}
          validate={this.validate}
          updateMessage={this.updateMessage}
          isDisabled={this.isDisabled}
          errors={this.state.errors}
          application={this.state.application}
          isMessageOptional={true}
          isCheckedWithOwner={isCheckedWithOwner}
          updateApplicationStatus={this.updateApplicationStatus}
          showManagerEditedSummary={true}
          selectShareWitOwnerOption={selectShareWitOwnerOption}
          isEquifaxActivated={isEquifaxActivated}
          isLiteAccount={isLiteAccount}
          allowBGCOption={allowBGCEnabled}
          bgcPrice={bgcPrice}
          updateRequestBGCApproval={this.updateRequestBGCApproval}
          requestBGCApproval={requestBGCApproval}
          onChangeMultiSelect={this.onChangeMultiSelect}
          teamSlug={teamSlug}
          emailOnchange={this.emailOnchange}
          showEmailResults={showEmailResults}
          filteredSearchResults={filteredSearchResults}
          emailOnBlur={this.emailOnBlur}
          filteredItemSelected={this.filteredItemSelected}
          emailList={emailList}
          removeEmailFromList={this.removeEmailFromList}
          emailKeyDown={this.emailKeyDown}
          emailOnFocus={this.emailOnFocus}
          alreadyHasBackgroundCheck={alreadyHasBackgroundCheck}
        />
        <ModalBasicFooter
          primaryLabel="Send Email"
          secondaryLabel="Cancel"
          showSecondaryButton={true}
          primaryButtonDisabled={showSpinner}
          primaryButtonLoading={showSpinner}
          primaryAction={this.onSendMessage}
          secondaryAction={toggleShareWithOwnerModal}
        />

        {this.state.apiError && <ErrorContainer error={this.state.apiError} />}
        {/* </div> */}
        {/* </div> */}
      </div>
    )
  }
}

const mapStateToProps = ({ session, dashboard, agency, MyProperties }) => ({
  currentAgency: session.currentAgency,
  currentTeam: session.currentTeam,
})

const mapDispatchToProps = (dispatch) => ({
  sendShareWithOwner: (
    applicationGUID,
    recipients,
    message,
    withOwnerStatus,
    isOwnerAcceptEnabled,
    requestBGCApproval,
  ) =>
    dispatch(
      sendShareWithOwner(
        applicationGUID,
        recipients,
        message,
        withOwnerStatus,
        isOwnerAcceptEnabled,
        requestBGCApproval,
      ),
    ),
  fetchApplicationNew: (applicationGUID, success) =>
    dispatch(fetchApplicationNew(applicationGUID, success)),
  fetchCurrentOwners: (teamId, queryString) =>
    dispatch(fetchCurrentOwners(teamId, queryString)),
  fetchTeamManagerList: (teamGUID) => dispatch(fetchTeamManagerList(teamGUID)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShareWithOwnerContainer)
