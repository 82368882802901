// Form Compoennt
// Layout Component

import ApplicationNoteAddComponent from 'app/components/display/application_note_add/component'
import ProfilePagesBottomButtonsComponent from 'app/components/display/profile_pages_bottom_buttons/component'
import ProfilePagesRowLayoutComponent from 'app/components/display/profile_pages_row_layout/component'
import AdderWidget from 'app/components/forms/adder_widget/adder_widget'
import CheckBoxGeneralComponent from 'app/components/forms/base_checkbox/component'
import FuzzySearchDropdownComponent from 'app/components/forms/base_fuzzy_search/component'
import InputPhoneNumberComponent from 'app/components/forms/base_phoneNumber/component'
import DropdownCountryComponent from 'app/components/forms/dropdown_country/dropdown_country'
import DropdownGenderComponent from 'app/components/forms/dropdown_gender/dropdown_gender'
import DropdownGenericComponent from 'app/components/forms/dropdown_generic/dropdown'
import DropdownRelationshipComponent from 'app/components/forms/dropdown_relationship/dropdown_relationship'
import DropdownStateComponent from 'app/components/forms/dropdown_state/dropdown_state'
import InputABNComponent from 'app/components/forms/input_abn/input_abn'
import InputAlphabeticComponent from 'app/components/forms/input_alphabetic/input_alphabetic'
import InputEmailComponent from 'app/components/forms/input_email/input_email'
import InputNameComponent from 'app/components/forms/input_name/input_name'
import InputNumberComponent from 'app/components/forms/input_number/input_number'
import InputNumberWithTwoDecimalsComponent from 'app/components/forms/input_number_with_two_decimals/input_number_with_two_decimals'
import InputNumberWithTwoDecimalsAndZeroComponent from 'app/components/forms/input_number_with_two_decimals_and_zero/input_number_with_two_decimals_and_zero'
import InputTextArea from 'app/components/forms/input_text_area/input_text_area'
import InputTextOptionalComponent from 'app/components/forms/input_text_optional/input_text_optional'
import InputTextRequiredComponent from 'app/components/forms/input_text_required/input_text_required'
import InputTimePickerComponent from 'app/components/forms/input_time/input_time'
import InputFutureDateComponent from 'app/components/forms/inputDate_future/inputDate_future'
import InputFutureDateOneDayLaterComponenr from 'app/components/forms/inputDate_oneDayLater'
import InputFutureDateOneDayPastComponent from 'app/components/forms/inputDate_oneDayPast'
import InputPastDateComponent from 'app/components/forms/inputDate_past/inputDate_past'
import InputDateComponent from 'app/components/forms/inputDate_startDate/inputDate_startDate'
import MultiSelectComponent from 'app/components/forms/multi_select/component'
import {
  YesOrNoCheckboxComponent,
  YesOrNoRadioGroupComponent,
} from 'app/components/forms/radio_group/component'

export const InputTextRequired = InputTextRequiredComponent
export const InputTextOptional = InputTextOptionalComponent
export const InputName = InputNameComponent
export const InputEmail = InputEmailComponent
export const InputABN = InputABNComponent
export const DropdownGender = DropdownGenderComponent
export const DropdownCountry = DropdownCountryComponent
export const DropdownState = DropdownStateComponent
export const DropdownRelationship = DropdownRelationshipComponent
export const FuzzySearchDropdown = FuzzySearchDropdownComponent
export const InputAlphabetic = InputAlphabeticComponent
export const InputNumber = InputNumberComponent
export const InputNumberWithTwoDecimals = InputNumberWithTwoDecimalsComponent
export const InputNumberWithTwoDecimalsAndZero =
  InputNumberWithTwoDecimalsAndZeroComponent
export const InputDate = InputDateComponent
export const InputPastDate = InputPastDateComponent
export const InputFutureDate = InputFutureDateComponent
export const InputPhoneNumber = InputPhoneNumberComponent
export const DigitAdderWidget = AdderWidget
export const Dropdown = DropdownGenericComponent
export const MultiSelect = MultiSelectComponent
export const CommentArea = InputTextArea
export const CheckBoxGeneral = CheckBoxGeneralComponent
export const TimePicker = InputTimePickerComponent

export const InputFutureDateOneDayLater = InputFutureDateOneDayLaterComponenr
export const InputFutureDateOneDayPast = InputFutureDateOneDayPastComponent

// Export layout component
export const ProfilePagesRowLayout = ProfilePagesRowLayoutComponent
export const ApplicationNoteAdd = ApplicationNoteAddComponent
export const ProfilePagesBottomButtons = ProfilePagesBottomButtonsComponent

export const YesOrNoRadioGroup = YesOrNoRadioGroupComponent
export const YesOrNoCheckbox = YesOrNoCheckboxComponent
