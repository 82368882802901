import React from 'react'

import moment from 'moment'

import { Box } from 'app/components/design-system-components'
import ContactDetails from 'app/match/apply/application_summary_before_submit/shared_components/contact_container/component'
import { durationTypeToText, studentTypeToText } from 'app/sm/helpers'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'
import { ApplicationDetailsNumbersComponent } from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'

import 'app/match/apply/application_summary_before_submit/shared_components/student_box/style.scss'

class SummaryStudentBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  transformAttachments(attachments) {
    // name and URL fields are used in DocumentList component
    if (!attachments) return
    return attachments.map(({ displayName: DName, name, url, ...rest }) => ({
      name: DName,
      URL: url,
      ...rest,
    }))
  }

  render() {
    const { student, toggleDocumentsViewer = undefined } = this.props

    const {
      placeOfStudy,
      duration,
      durationType,
      startDate,
      enrolmentNumber,
      studentType,
      contactName,
      contactEmail,
      contactPhone,
      coordinatorName,
      coordinatorEmail,
      coordinatorMobile,

      studentIDAttachments,
      offerLetterAttachments,
    } = student

    const durationText = `${duration} ${
      durationTypeToText[durationType]
    } start from ${moment(startDate).format('DD MMM YYYY')}`

    const transformedStudentIDAttachments =
      this.transformAttachments(studentIDAttachments)
    const transformedOfferLetterAttachments = this.transformAttachments(
      offerLetterAttachments,
    )

    return (
      <div className="student-container">
        <div className="card-details">
          <header>
            <span className="student-place-of-study">{placeOfStudy}</span>
          </header>
          <content>
            <div className="application-details-numbers-box display-flex flex-wrap">
              <ApplicationDetailsNumbersComponent
                title="Type"
                text={studentTypeToText[studentType]}
                componentClassName="width50p align-items-start flex-basis-25p flex-sp"
                titleClassName="student-label"
                textClassName="student-content"
              />
              <ApplicationDetailsNumbersComponent
                title="Enrolment Number"
                text={enrolmentNumber}
                componentClassName="width50p align-items-start flex-basis-25p flex-sp"
                titleClassName="student-label"
                textClassName="student-content"
              />
              <ApplicationDetailsNumbersComponent
                title="Duration"
                text={durationText}
                componentClassName="width50p align-items-start flex-basis-25p flex-sp"
                titleClassName="student-label"
                textClassName="student-content"
              />
            </div>
            <div>
              {contactName && (
                <ContactDetails
                  firstName={contactName}
                  phoneNumber={contactPhone}
                  email={contactEmail}
                  extraName="Campus Contact"
                />
              )}
              {coordinatorName && (
                <ContactDetails
                  firstName={coordinatorName}
                  phoneNumber={coordinatorMobile}
                  email={coordinatorEmail}
                  extraName="Course Coordinator"
                  showContactLabel={!contactName}
                  componentClass={contactName && 'mt20'}
                />
              )}
            </div>
            {transformedStudentIDAttachments && (
              <Box my="24px">
                <Box my="16px">Student ID</Box>
                {transformedStudentIDAttachments.map((attachment) => (
                  <AppDocumentListItemSecondVersion
                    titleClass="mt0 pl0"
                    document={attachment}
                    key={attachment.guid}
                    toggleDocumentsViewer={
                      toggleDocumentsViewer
                        ? () => toggleDocumentsViewer(attachment.guid)
                        : undefined
                    }
                  />
                ))}
              </Box>
            )}
            {transformedOfferLetterAttachments && (
              <Box my="24px">
                <Box my="16px">Offer Letter</Box>
                {transformedOfferLetterAttachments.map((attachment) => (
                  <AppDocumentListItemSecondVersion
                    titleClass="mt0 pl0"
                    document={attachment}
                    key={attachment.guid}
                    toggleDocumentsViewer={
                      toggleDocumentsViewer
                        ? () => toggleDocumentsViewer(attachment.guid)
                        : undefined
                    }
                  />
                ))}
              </Box>
            )}
          </content>
        </div>
      </div>
    )
  }
}

export default SummaryStudentBox
