import { clearError, receiveResponseText } from 'app/sm/apply/apply_actions'
import { getPropertyManagerSummary } from 'app/sm/property_details/property_details_access'
import store from 'app/store'

export const RECEIVE_PROPERTY = 'AP_RECEIVE_PROPERTY'

const receiveProperty = (property) => ({
  type: RECEIVE_PROPERTY,
  property,
})

export function fetchProperty(id) {
  return (dispatch) => {
    dispatch(clearError())
    getPropertyManagerSummary(id).then(
      ({ managerSummary, ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(receiveProperty(managerSummary))
        } else {
          responseText.then((t) => {
            store.dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}
