import React from 'react'

import { Link } from 'react-router-dom'

import humanIcon1 from 'app/assets/icons/human01.jpg'
import humanIcon2 from 'app/assets/icons/human02.jpg'
import humanIcon3 from 'app/assets/icons/human03.jpg'
import iconDocumentPdf from 'app/assets/icons/icon-document-pdf.svg'

class Messages extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.scrollToBottom()
  }

  componentDidUpdate() {
    this.scrollToBottom()
  }

  scrollToBottom() {
    this.last.scrollIntoView({ behaviour: 'smooth' })
  }

  render() {
    return (
      <ul className="sm-chat-messages">
        <li className="in">
          <div className="user">
            <div
              className="avatar avatar-msmall"
              style={{ backgroundImage: `url(${humanIcon2})` }}
            ></div>
            <div className="status green-bg"></div>
          </div>
          <div className="message">
            <div className="header">
              <div>Justin B.</div>
              <div>1:13pm</div>
            </div>
            <p>
              Hi there Justin, we need the lease documents we signed when we
              entered your apartment. Where can we find that?
            </p>
          </div>
          <div className="dropdown options">
            <div className="dropdown-toggle" data-toggle="dropdown">
              <button className="btn btn-round btn-medium btn-white">
                <i className="icon-more"></i>
              </button>
            </div>
            <ul className="dropdown-menu">
              <li>
                <Link to={'/'}>Add a Todo</Link>
              </li>
              <li>
                <Link to={'/'}>Add a Maintenence Request</Link>
              </li>
              <li className="divider"></li>
              <li>
                <Link to={'/'}>Copy</Link>
              </li>
              <li>
                <Link to={'/'}>Edit Message</Link>
              </li>
              <li>
                <Link to={'/'}>Remove Message</Link>
              </li>
              <li>
                <Link to={'/'}>Quote Message</Link>
              </li>
            </ul>
          </div>
        </li>

        <li className="in">
          <div className="user">
            <div
              className="avatar avatar-msmall"
              style={{ backgroundImage: `url(${humanIcon3})` }}
            ></div>
            <div className="status green-bg"></div>
          </div>
          <div className="message">
            <div className="header">
              <div>Andrew S.</div>
              <div>1:14pm</div>
            </div>
            <p>
              Yeah, i know we have a documents area here in snug, but can’t
              quite find where they are located. Did you upload them?
            </p>
          </div>
          <div className="dropdown options">
            <div className="dropdown-toggle" data-toggle="dropdown">
              <button className="btn btn-round btn-medium btn-white">
                <i className="icon-more"></i>
              </button>
            </div>
            <ul className="dropdown-menu">
              <li>
                <Link to={'/'}>Add a Todo</Link>
              </li>
              <li>
                <Link to={'/'}>Add a Maintenence Request</Link>
              </li>
              <li className="divider"></li>
              <li>
                <Link to={'/'}>Copy</Link>
              </li>
              <li>
                <Link to={'/'}>Edit Message</Link>
              </li>
              <li>
                <Link to={'/'}>Remove Message</Link>
              </li>
              <li>
                <Link to={'/'}>Quote Message</Link>
              </li>
            </ul>
          </div>
        </li>

        <li className="out">
          <div className="user">
            <div
              className="avatar avatar-msmall"
              style={{ backgroundImage: `url(${humanIcon1})` }}
            ></div>
            <div className="status green-bg"></div>
          </div>
          <div className="message">
            <div className="header">
              <div>Justin B.</div>
              <div>1:16pm</div>
            </div>
            <p>
              Hi guys. I’m sending them over right now. Just give me a moment to
              find them on the comp.
            </p>
          </div>
          <div className="dropdown options">
            <div className="dropdown-toggle" data-toggle="dropdown">
              <button className="btn btn-round btn-medium btn-white">
                <i className="icon-more"></i>
              </button>
            </div>
            <ul className="dropdown-menu">
              <li>
                <Link to={'/'}>Add a Todo</Link>
              </li>
              <li>
                <Link to={'/'}>Add a Maintenence Request</Link>
              </li>
              <li className="divider"></li>
              <li>
                <Link to={'/'}>Copy</Link>
              </li>
              <li>
                <Link to={'/'}>Edit Message</Link>
              </li>
              <li>
                <Link to={'/'}>Remove Message</Link>
              </li>
              <li>
                <Link to={'/'}>Quote Message</Link>
              </li>
            </ul>
          </div>
        </li>

        <li className="out">
          <div className="user">
            <div
              className="avatar avatar-msmall"
              style={{ backgroundImage: `url(${humanIcon1})` }}
            ></div>
            <div className="status green-bg"></div>
          </div>
          <div className="message">
            <div className="header">
              <div>Justin B.</div>
              <div>1:18pm</div>
            </div>
            <p>
              <a className="attachment">
                <img className="icon" src={iconDocumentPdf} height="26" />
                <span className="name">Signed Lease.pdf</span>
              </a>
            </p>
          </div>
          <div className="dropdown options">
            <div className="dropdown-toggle" data-toggle="dropdown">
              <button className="btn btn-round btn-medium btn-white">
                <i className="icon-more"></i>
              </button>
            </div>
            <ul className="dropdown-menu">
              <li>
                <Link to={'/'}>Add a Todo</Link>
              </li>
              <li>
                <Link to={'/'}>Add a Maintenence Request</Link>
              </li>
              <li className="divider"></li>
              <li>
                <Link to={'/'}>Copy</Link>
              </li>
              <li>
                <Link to={'/'}>Edit Message</Link>
              </li>
              <li>
                <Link to={'/'}>Remove Message</Link>
              </li>
              <li>
                <Link to={'/'}>Quote Message</Link>
              </li>
            </ul>
          </div>
        </li>

        <li className="in">
          <div className="user">
            <div
              className="avatar avatar-msmall"
              style={{ backgroundImage: `url(${humanIcon3})` }}
            ></div>
            <div className="status green-bg"></div>
          </div>
          <div className="message">
            <div className="header">
              <div>Andrew S.</div>
              <div>1:20pm</div>
            </div>
            <p>Thanks !</p>
          </div>
          <div className="dropdown options">
            <div className="dropdown-toggle" data-toggle="dropdown">
              <button className="btn btn-round btn-medium btn-white">
                <i className="icon-more"></i>
              </button>
            </div>
            <ul className="dropdown-menu">
              <li>
                <Link to={'/'}>Add a Todo</Link>
              </li>
              <li>
                <Link to={'/'}>Add a Maintenence Request</Link>
              </li>
              <li className="divider"></li>
              <li>
                <Link to={'/'}>Copy</Link>
              </li>
              <li>
                <Link to={'/'}>Edit Message</Link>
              </li>
              <li>
                <Link to={'/'}>Remove Message</Link>
              </li>
              <li>
                <Link to={'/'}>Quote Message</Link>
              </li>
            </ul>
          </div>
        </li>

        <li
          className="last"
          ref={(el) => {
            this.last = el
          }}
        ></li>
      </ul>
    )
  }
}

export default Messages
