import React from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  Alert,
  Box,
  ButtonWithLoading,
  Flex,
  FullWidthLinksTabs,
  HorizontalLine,
} from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import * as TextComp from 'app/components/display/text/text_component'
import Dropdown from 'app/components/forms/base_dropdown/component'
import { CheckBoxGeneral } from 'app/components/forms/forms'
import { propertyDisclosureInputTypes } from 'app/constants/disclosure.constants'
import {
  allowedManagementTypes,
  housingCategoriesLabels,
  housingCategoryOptions,
} from 'app/constants/offer.constants'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { saveManagementType } from 'app/pages/teams/onlist/access'
import AddOwnerAndTenant from 'app/pages/teams/onlist/components/AddOwnerAndTenant'
import DisclosureQuestions from 'app/pages/teams/onlist/components/Disclosures'
import { PropertyDisclosureHeader } from 'app/pages/teams/onlist/components/Disclosures/PropertyDisclosureHeader'
import OnlistQRCode from 'app/pages/teams/onlist/components/OnlistQRCode'
import * as onlistConstants from 'app/pages/teams/onlist/onlist.constants'
import * as onlistSteps from 'app/pages/teams/onlist/onlist.constants'
import {
  updateOfferBallots,
  updateOfferHousingCategory,
  updateOfferOnlistStatus,
} from 'app/services/http/offers'
import { addPropertyOfferDisclosureAttachments } from 'app/services/http/properties/disclosures'
import {
  getPropertyDisclosuresStatusForAgency,
  removePropertyDisclosure,
} from 'app/services/http/propertydisclosures'
import { getIncomeTableForAgency } from 'app/services/http/teams/income-table'
import { getTeamSettings } from 'app/services/http/teams/settings'
import * as snugNotifier from 'app/services/snugNotifier'
import DocumentList from 'app/shared_components/document_list/document_list'
import { composeAttachment, isMobile } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import Stepper from 'app/shared_components/stepper'
import Spinner from 'app/sm/common/spinner'
import * as helpers from 'app/sm/helpers'
import OnListDetailsContainer from 'app/sm/onlist_details/connection'
import OwnerPropertyDisclosures from 'app/sm/onlist_details/ownerPropertyDisclosure'
import * as constants from 'app/sm/onlist_details/ownerPropertyDisclosure/helpers/constants'
import * as routerHelper from 'app/sm/router_helpers'
import PropertyItem from 'app/sm/viewings_new_run/property_item/component'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  loadingStatesIds,
} from 'app/utils/loading-states'
import { convertYesOrNoToBool } from 'app/utils/onlist/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import { isShowBallotsEnabledForTeam } from 'config/features'

const failedValidationMessage = 'Please answer this question'

const FinishedStep = () => {
  return (
    <div className="text-align-center">
      <TextComp.Stronger
        text="All Steps Finished. Thank You!"
        textClass="fs32"
      />
      <TextComp.Normal text="Please click on Finished to go to the Team Overview Page" />
    </div>
  )
}

const allowedManagementTypesLabels = allowedManagementTypes.map(
  (type) => type.label,
)

const actionClickWrapperFactory =
  (onClickHandler) =>
  ({ children }) =>
    <div onClick={() => onClickHandler()}>{children}</div>

class OnlistPropertyOffer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      property: {},
      compliesWithMinimumStandards: null,
      isBeingSold: null,
      isRegisteredPlace: null,
      hasAsbestos: null,
      isAffectedWithRelevantAuthorities: null,
      dateOfHomicide: '',
      dateOfRepairNotice: '',
      dateOfGasCheck: '',
      dateOfElectricityCheck: '',
      dateOfPoolCheck: '',
      dateOfDrugUsage: '',
      embeddedNetworkName: '',
      embeddedNetworkAbn: '',
      embeddedNetworkPhone: '',
      embeddedNetworkPricing: '',
      hasHomicide: null,
      areDisclosuresEditable: true,
      hasEmbeddedNetwork: null,
      embeddedNetworkWebsite: '',
      disclosureErrors: {
        compliesWithMinimumStandards: '',
        isBeingSold: '',
        isRegisteredPlace: '',
        hasAsbestos: '',
        isAffectedWithRelevantAuthorities: '',
        dateOfHomicide: '',
        dateOfRepairNotice: '',
        dateOfGasCheck: '',
        dateOfElectricityCheck: '',
        dateOfPoolCheck: '',
        dateOfDrugUsage: '',
        embeddedNetworkName: '',
        embeddedNetworkAbn: '',
        embeddedNetworkPhone: '',
        embeddedNetworkPricing: '',
        hasHomicide: '',
        hasEmbeddedNetwork: '',
        embeddedNetworkWebsite: '',
      },
      managementTypeSelectedIndex: '',
      managementTypeLoadingStates: {
        loading: false,
        error: null,
      },
      selectedDisclosureTabId: propertyDisclosureInputTypes.form.tabID,
      disclosureAttachments: [],
      disclosureAttachmentLoadingStates: createInitialLoadingState(),
      disclosureInputTypeId: null,
      offerInfo: {},
      selectedHousingCategory: 0,
      ballotsEnabled: false,
      ballotsIncomeTable: '',
      ballotsState: '',
      applicationTeamSettings: {},
      incomeTables: [],
      isIncomeTableFetching: false,
    }

    this.disclosureAttachmentLoadingStateUtils = createLoadingStateUtils(
      (state) => {
        this.setState({
          disclosureAttachmentLoadingStates: state,
        })
      },
    )
  }

  UNSAFE_componentWillMount() {
    this.fetchInformationForSteps()
  }

  componentDidMount() {
    const { currentTeam } = this.props
    const { guid = '' } = currentTeam || {}
    if (guid) {
      this.fetchTeamSettings(guid)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.teams !== prevProps.teams) {
      this.fetchInformationForSteps()

      const teamGUID = this.getCurrentTeamGUID()
      if (teamGUID) {
        this.fetchTeamSettings(teamGUID)
      }
    }
  }

  getCurrentTeamGUID = () => {
    const teamSlug = routerHelper.extractTeamSlug(this.props.location.pathname)
    const currentTeam = this.props.teams.find((team) => team.slug === teamSlug)

    const { guid = '' } = currentTeam || {}
    return guid
  }

  fetchTeamSettings = (teamGUID) => {
    getTeamSettings(teamGUID, {
      keys: ['application'],
    }).then(({ settingInfo = {} }) => {
      const { application = {} } = settingInfo || {}
      const applicationTeamSettings =
        helpers.parseDataUtil.convertObjValueFromStringToBoolean(application)
      this.setState({
        applicationTeamSettings,
      })
    })
  }

  onChangeRadioGroup = (field) => {
    return (event) => {
      const { target } = event
      const { id: val } = target

      let updatedDisclosureErrors = { ...this.state.disclosureErrors }
      let unsavedChanges = false
      let fieldVal = null

      // if not toggling, then we have a valid bool
      if (this.state[field] !== convertYesOrNoToBool(val)) {
        fieldVal = convertYesOrNoToBool(val)
        unsavedChanges = true
        updatedDisclosureErrors = {
          ...updatedDisclosureErrors,
          [field]: '',
        }
      }

      if (field === 'hasHomicide' && !convertYesOrNoToBool(val)) {
        updatedDisclosureErrors = {
          ...updatedDisclosureErrors,
          dateOfHomicide: '',
        }
      }

      if (field === 'hasEmbeddedNetwork' && !convertYesOrNoToBool(val)) {
        updatedDisclosureErrors = {
          ...updatedDisclosureErrors,
          embeddedNetworkName: '',
          embeddedNetworkAbn: '',
          embeddedNetworkPhone: '',
          embeddedNetworkPricing: '',
          embeddedNetworkWebsite: '',
        }
      }

      if (this.state[field] === convertYesOrNoToBool(val)) {
        fieldVal = null
      }

      this.setState({
        [field]: fieldVal,
        disclosureErrors: updatedDisclosureErrors,
        unsavedChanges,
      })
    }
  }

  getChosenTeam() {
    const { teams } = this.props
    const { teamSlug = '' } = this.props.match.params
    return helpers.findChosenTeamBySlug(teams, teamSlug) || {}
  }

  getIncomeTableNames() {
    const { incomeTables, ballotsState } = this.state
    const options = []
    const names = (incomeTables || [])
      .filter((incomeTable) => {
        const { jurisdiction = '' } = incomeTable
        return jurisdiction === onlistSteps.stateOptionsArray[ballotsState]
      })
      .forEach((incomeTable, index) => {
        const { region = '', year = '' } = incomeTable || {}
        options.push({ label: region, extraOptionText: year, value: index })
      })

    return options
  }

  isAllEmpty = () => {
    const boolValues = [
      'compliesWithMinimumStandards',
      'isBeingSold',
      'isRegisteredPlace',
      'hasAsbestos',
      'isAffectedWithRelevantAuthorities',
      'hasHomicide',
      'hasEmbeddedNetwork',
    ]
    const stringValues = [
      'dateOfHomicide',
      'dateOfRepairNotice',
      'dateOfGasCheck',
      'dateOfElectricityCheck',
      'dateOfPoolCheck',
      'dateOfDrugUsage',
      'embeddedNetworkName',
      'embeddedNetworkAbn',
      'embeddedNetworkPhone',
      'embeddedNetworkPricing',
      'embeddedNetworkWebsite',
    ]
    return (
      boolValues.every((val) => this.state[val] === null) &&
      stringValues.every((val) => !this.state[val])
    )
  }

  fetchInformationForSteps = () => {
    const { fetchPropertyOfferInfoWithDetails } = this.props
    const offerGUID = this.props.match.params.offerGUID
    const { guid: agencyGUID } = this.getChosenTeam()
    this.setState({ isLoadingDisplayInfo: true })
    if (offerGUID && agencyGUID) {
      fetchPropertyOfferInfoWithDetails(agencyGUID, offerGUID)
        .then(({ offerInfo = {} }) => {
          this.setState({
            property: offerInfo.property,
            selectedHousingCategory: housingCategoryOptions.findIndex(
              (e) => e.value === offerInfo?.housingCategory,
            ),
            offerInfo,
          })
          if (offerInfo.managementType) {
            const allowedType = allowedManagementTypes.findIndex(
              (type) => type.value === offerInfo.managementType,
            )
            this.setState({
              managementTypeSelectedIndex: allowedType === -1 ? 1 : allowedType,
            })
          }

          if (
            offerInfo.ballotsEnabled &&
            offerInfo.ballotsState &&
            offerInfo.ballotsIncomeTableID
          ) {
            getIncomeTableForAgency(agencyGUID).then((data = []) => {
              this.setState({
                ballotsEnabled: true,
                ballotsState: onlistSteps.stateOptionsArray.findIndex(
                  (state) => state === offerInfo.ballotsState,
                ),
                ballotsIncomeTable: data.findIndex(
                  ({ id }) => id === offerInfo.ballotsIncomeTableID,
                ),
                incomeTables: data,
              })
            })
          }
        })
        .catch((error) => {
          snugNotifier.error(error)
          this.setState({ loadingError: error })
        })
        .finally({ isLoadingDisplayData: false })
      this.fetchAndSetDisclosure()
    }
  }

  reloadDisclosure = () => {
    this.resetDisclosureStateWithCB(() => this.fetchAndSetDisclosure())
  }

  fetchAndSetDisclosure() {
    this.setState({ isLoadingDisclosure: true })
    const offerGUID = this.props.match.params.offerGUID
    const { guid: agencyGUID } = this.getChosenTeam()
    return getPropertyDisclosuresStatusForAgency(agencyGUID, offerGUID).then(
      (disclosureResp) => {
        this.setState({ isLoadingDisclosure: false })
        if (
          disclosureResp.completionStatus === constants.DISCLOSURE_COMPLETED
        ) {
          this.setState({
            version: disclosureResp.propertyDisclosure.version,
            disclosureInputTypeId:
              disclosureResp.propertyDisclosure.disclosureType,
          })
          if (
            disclosureResp.propertyDisclosure.disclosureType ===
            propertyDisclosureInputTypes.attachments.id
          ) {
            const attachments = this.transformAttachments(
              disclosureResp.propertyDisclosure.disclosureAttachments,
            )
            this.setState({
              disclosureAttachments: attachments,
              selectedDisclosureTabId:
                propertyDisclosureInputTypes.attachments.tabID,
              areDisclosuresEditable: false,
            })
          } else {
            const propertyDisclosure = disclosureResp.propertyDisclosure
            this.setState({
              compliesWithMinimumStandards:
                propertyDisclosure.compliesWithMinimumStandards,
              isBeingSold: propertyDisclosure.isBeingSold,
              isRegisteredPlace: propertyDisclosure.isRegisteredPlace,
              hasAsbestos: propertyDisclosure.hasAsbestos,
              isAffectedWithRelevantAuthorities:
                propertyDisclosure.isAffectedWithRelevantAuthorities,
              dateOfHomicide: propertyDisclosure.dateOfHomicide,
              dateOfRepairNotice: propertyDisclosure.dateOfRepairNotice,

              dateOfGasCheck: propertyDisclosure.dateOfGasCheck,
              dateOfElectricityCheck: propertyDisclosure.dateOfElectricityCheck,
              dateOfPoolCheck: propertyDisclosure.dateOfPoolCheck,
              dateOfDrugUsage: propertyDisclosure.dateOfDrugUsage,
              embeddedNetworkName: propertyDisclosure.embeddedNetworkName,
              embeddedNetworkAbn: propertyDisclosure.embeddedNetworkAbn,
              embeddedNetworkPhone: propertyDisclosure.embeddedNetworkPhone,
              embeddedNetworkPricing: propertyDisclosure.embeddedNetworkPricing,
              embeddedNetworkWebsite: propertyDisclosure.embeddedNetworkWebsite,
              hasHomicide: propertyDisclosure.hasHomicide,
              areDisclosuresEditable: false,
              hasEmbeddedNetwork: propertyDisclosure.hasEmbeddedNetwork,
            })
          }
        }
      },
    )
  }

  resetDisclosureStateWithCB(cb) {
    this.setState(
      {
        areDisclosuresEditable: true,
        disclosureInputTypeId: null,

        disclosureAttachments: [],

        compliesWithMinimumStandards: null,
        isBeingSold: null,
        isRegisteredPlace: null,
        hasAsbestos: null,
        isAffectedWithRelevantAuthorities: null,
        dateOfHomicide: null,
        dateOfRepairNotice: null,
        dateOfGasCheck: null,
        dateOfElectricityCheck: null,
        dateOfPoolCheck: null,
        dateOfDrugUsage: null,
        embeddedNetworkName: null,
        embeddedNetworkAbn: null,
        embeddedNetworkPhone: null,
        embeddedNetworkPricing: null,
        embeddedNetworkWebsite: null,
        hasHomicide: null,
        hasEmbeddedNetwork: null,
      },
      cb,
    )
  }

  transformAttachments(attachments) {
    // name and URL fields are used in DocumentList component
    return attachments.map(({ displayName: DName, name, url, ...rest }) => ({
      name: DName,
      URL: url,
      ...rest,
    }))
  }

  handleManagementTypeChange({ value, error }) {
    this.setState({
      managementTypeSelectedIndex: value,
    })
    const offerGUID = this.props.match.params.offerGUID
    if (!offerGUID) {
      return Promise.reject('Offer not found!')
    }
    this.setState({
      managementTypeLoadingStates: { loading: true, error: null },
    })
    const submittedValue = allowedManagementTypes[value].value
    return saveManagementType(submittedValue, offerGUID)
      .then(() => {
        snugNotifier.success(`Management type has been updated successfully`)
        this.setState({
          managementTypeLoadingStates: { loading: false, error: null },
        })
      })
      .catch((err) => {
        this.setState({
          managementTypeLoadingStates: { loading: false, error: err },
        })
        snugNotifier.error(err)
      })
  }

  inputValueChange = (field) => {
    const { hasEmbeddedNetwork } = this.state
    return (event) => {
      const { value } = event
      if (hasEmbeddedNetwork) {
        if (field === 'embeddedNetworkAbn' && value !== '') {
          this.setState((prevState) => {
            return {
              ...prevState,
              disclosureErrors: {
                ...prevState.disclosureErrors,
                embeddedNetworkAbn: '',
              },
            }
          })
        }
        if (field === 'embeddedNetworkPricing' && value !== '') {
          this.setState((prevState) => {
            return {
              ...prevState,
              disclosureErrors: {
                ...prevState.disclosureErrors,
                embeddedNetworkPricing: '',
              },
            }
          })
        }
        if (field === 'embeddedNetworkName' && value !== '') {
          this.setState((prevState) => {
            return {
              ...prevState,
              disclosureErrors: {
                ...prevState.disclosureErrors,
                embeddedNetworkName: '',
              },
            }
          })
        }
        if (field === 'embeddedNetworkPhone' && value !== '') {
          this.setState((prevState) => {
            return {
              ...prevState,
              disclosureErrors: {
                ...prevState.disclosureErrors,
                embeddedNetworkPhone: '',
              },
            }
          })
        }
        if (field === 'mbeddedNetworkWebsite' && value !== '') {
          this.setState((prevState) => {
            return {
              ...prevState,
              disclosureErrors: {
                ...prevState.disclosureErrors,
                embeddedNetworkWebsite: failedValidationMessage,
              },
            }
          })
        }
      }
      if (
        field === 'embeddedNetworkName' ||
        field === 'embeddedNetworkPricing'
      ) {
        this.setState({
          [field]: value,
        })
        return
      }
      this.setState({
        [field]: value,
        disclosureErrors: {
          ...this.state.disclosureErrors,
          [field]: event.error,
        },
      })
    }
  }

  redirectToPageAfterFinished = () => {
    const { teamSlug = '' } = this.props.match.params
    history.push(helpers.urlTo('teamOverview', { teamSlug }))
  }

  saveDisclosures = () => {
    const { addPropertyDisclosuresForOffer } = this.props
    const {
      compliesWithMinimumStandards,
      isBeingSold,
      isRegisteredPlace,
      hasAsbestos,
      isAffectedWithRelevantAuthorities,
      dateOfHomicide,
      dateOfRepairNotice,
      dateOfGasCheck,
      dateOfElectricityCheck,
      dateOfPoolCheck,
      dateOfDrugUsage,
      embeddedNetworkName,
      embeddedNetworkAbn,
      embeddedNetworkPhone,
      embeddedNetworkPricing,
      embeddedNetworkWebsite,
      hasHomicide,
      hasEmbeddedNetwork,
    } = this.state
    const data = {
      compliesWithMinimumStandards,
      isBeingSold,
      isRegisteredPlace,
      hasAsbestos,
      isAffectedWithRelevantAuthorities,
      hasHomicide,
      hasEmbeddedNetwork,
      dateOfHomicide: moment(dateOfHomicide),
      dateOfRepairNotice: moment(dateOfRepairNotice),
      dateOfGasCheck: moment(dateOfGasCheck),
      dateOfElectricityCheck: moment(dateOfElectricityCheck),
      dateOfPoolCheck: moment(dateOfPoolCheck),
      dateOfDrugUsage: moment(dateOfDrugUsage),
      embeddedNetworkName,
      embeddedNetworkAbn,
      embeddedNetworkPhone,
      embeddedNetworkPricing,
      embeddedNetworkWebsite,
    }
    const offerGUID = this.props.match.params.offerGUID
    const { guid: agencyGUID } = this.getChosenTeam()
    if (agencyGUID && offerGUID) {
      addPropertyDisclosuresForOffer(agencyGUID, offerGUID, data)
        .then(() => {
          snugNotifier.success('Successfully saved Disclosures')
          this.setState({
            areDisclosuresEditable: false,
          })
          this.updateOnlistStatus()
          this.refs.child.updateCurrentComponentPlusOne()
        })
        .catch((error) => snugNotifier.error(error))
    }
  }

  updateDateInput = (field) => {
    return (data) => {
      let error = data.error
      this.setState({
        [field]: data.value,

        unsavedChanges: true,
        disclosureErrors: {
          ...this.state.disclosureErrors,
          [field]: error,
        },
      })
    }
  }

  validateResponsesAndSetErrors = () => {
    const {
      compliesWithMinimumStandards,
      isBeingSold,
      isRegisteredPlace,
      hasAsbestos,
      isAffectedWithRelevantAuthorities,
      dateOfHomicide,
      hasHomicide,
      hasEmbeddedNetwork,
      embeddedNetworkName,
      embeddedNetworkAbn,
      embeddedNetworkPhone,
      embeddedNetworkPricing,
      embeddedNetworkWebsite,
    } = this.state
    let errorsPresented = false
    let errors = { ...this.state.disclosureErrors }
    if (hasHomicide === null) {
      errors = { ...errors, hasHomicide: failedValidationMessage }
      errorsPresented = true
    }
    if (hasHomicide && !dateOfHomicide) {
      errors = { ...errors, dateOfHomicide: failedValidationMessage }
      errorsPresented = true
    }
    if (isRegisteredPlace === null) {
      errors = { ...errors, isRegisteredPlace: failedValidationMessage }
      errorsPresented = true
    }
    if (isAffectedWithRelevantAuthorities === null) {
      errors = {
        ...errors,
        isAffectedWithRelevantAuthorities: failedValidationMessage,
      }
      errorsPresented = true
    }
    if (hasAsbestos === null) {
      errors = { ...errors, hasAsbestos: failedValidationMessage }
      errorsPresented = true
    }

    if (compliesWithMinimumStandards === null) {
      errors = {
        ...errors,
        compliesWithMinimumStandards: failedValidationMessage,
      }
      errorsPresented = true
    }
    if (isBeingSold === null) {
      errors = { ...errors, isBeingSold: failedValidationMessage }
      errorsPresented = true
    }
    if (hasEmbeddedNetwork === null) {
      errors = { ...errors, hasEmbeddedNetwork: failedValidationMessage }
      errorsPresented = true
    }
    if (hasEmbeddedNetwork) {
      if (!embeddedNetworkAbn) {
        errors = { ...errors, embeddedNetworkAbn: failedValidationMessage }
        errorsPresented = true
      }
      if (!embeddedNetworkPricing) {
        errors = { ...errors, embeddedNetworkPricing: failedValidationMessage }
        errorsPresented = true
      }
      if (!embeddedNetworkName) {
        errors = { ...errors, embeddedNetworkName: failedValidationMessage }
        errorsPresented = true
      }
      if (!embeddedNetworkPhone) {
        errors = { ...errors, embeddedNetworkPhone: failedValidationMessage }
        errorsPresented = true
      }
      if (!embeddedNetworkWebsite) {
        errors = { ...errors, embeddedNetworkWebsite: failedValidationMessage }
        errorsPresented = true
      }
    }

    this.setState({ disclosureErrors: errors })

    return errorsPresented
  }

  selectDisclosureTab(tabId) {
    this.setState({
      selectedDisclosureTabId: tabId,
    })
  }

  renderDisclosureTabs() {
    const tabs = [
      {
        id: propertyDisclosureInputTypes.form.tabID,
        displayName: propertyDisclosureInputTypes.form.tabLabel,
        ActionWrapper: actionClickWrapperFactory(() =>
          this.selectDisclosureTab(propertyDisclosureInputTypes.form.tabID),
        ),
      },
      {
        id: propertyDisclosureInputTypes.attachments.tabID,
        displayName: propertyDisclosureInputTypes.attachments.tabLabel,
        ActionWrapper: actionClickWrapperFactory(() =>
          this.selectDisclosureTab(
            propertyDisclosureInputTypes.attachments.tabID,
          ),
        ),
      },
    ]
    const { selectedDisclosureTabId, areDisclosuresEditable } = this.state
    return (
      <Flex width="100%" flex="1 1" overflowY="auto">
        <Box width="100%">
          <Box>
            <Flex flex="1 1 100%">
              <Box width="100%">
                <FullWidthLinksTabs
                  items={tabs}
                  selectedItemId={selectedDisclosureTabId}
                />
              </Box>
            </Flex>
            <HorizontalLine />
          </Box>
          {selectedDisclosureTabId ===
            propertyDisclosureInputTypes.form.tabID && (
            <Box pt="20px">{this.renderDisclosureFormTab()}</Box>
          )}

          {selectedDisclosureTabId ===
            propertyDisclosureInputTypes.attachments.tabID && (
            <Box p="20px">
              {this.renderDisclosureAttachmentsTab(areDisclosuresEditable)}
            </Box>
          )}
        </Box>
      </Flex>
    )
  }
  renderDisclosureFormTab() {
    const {
      compliesWithMinimumStandards,
      isBeingSold,
      isRegisteredPlace,
      hasAsbestos,
      isAffectedWithRelevantAuthorities,
      dateOfHomicide,
      dateOfRepairNotice,
      dateOfGasCheck,
      dateOfElectricityCheck,
      dateOfPoolCheck,
      dateOfDrugUsage,
      embeddedNetworkName,
      embeddedNetworkAbn,
      embeddedNetworkPhone,
      embeddedNetworkPricing,
      embeddedNetworkWebsite,
      hasHomicide,
      areDisclosuresEditable,
      disclosureErrors,
      hasEmbeddedNetwork,
      property,
      disclosureInputTypeId,
      version,
    } = this.state
    const { address } = property
    const { friendlyName, suburb } = address || ''
    const propertyAddress = `${friendlyName}, ${suburb}`
    const showTypeAlert =
      disclosureInputTypeId === propertyDisclosureInputTypes.attachments.id
    return (
      <>
        {showTypeAlert && (
          <Alert mb="20px" variant="warningWithBg">
            PDF uploads will be used for disclosures
          </Alert>
        )}
        <PropertyDisclosureHeader propertyAddress={propertyAddress} />
        <DisclosureQuestions
          answers={{
            dateOfHomicide,
            compliesWithMinimumStandards,
            isBeingSold,
            dateOfRepairNotice,
            dateOfGasCheck,
            dateOfElectricityCheck,
            dateOfPoolCheck,
            isRegisteredPlace,
            dateOfDrugUsage,
            hasAsbestos,
            isAffectedWithRelevantAuthorities,
            embeddedNetworkName,
            embeddedNetworkAbn,
            embeddedNetworkPhone,
            embeddedNetworkPricing,
            embeddedNetworkWebsite,
            hasHomicide,
            hasEmbeddedNetwork,
          }}
          onChangeRadioGroup={this.onChangeRadioGroup}
          updateDateInput={this.updateDateInput}
          inputValueChange={this.inputValueChange}
          areDisclosuresEditable={areDisclosuresEditable}
          disclosureErrors={disclosureErrors}
          version={version}
        />
      </>
    )
  }
  renderDisclosureAttachmentsTab(allowUpload = true) {
    const { disclosureAttachments, disclosureAttachmentLoadingStates } =
      this.state
    return (
      <>
        {allowUpload && (
          <Box mb="20px">
            <h4>Upload Disclosure Attachments</h4>
          </Box>
        )}

        <DocumentList
          documents={disclosureAttachments}
          showUploader={allowUpload}
          addDocument={this.uploadPropertyDisclosureAttachment}
          documentType={helpers.DocumentTypes.propertyDisclosure}
          spinner={
            disclosureAttachmentLoadingStates.state === loadingStatesIds.LOADING
          }
          availableFileTypesText="(PDF)"
          // TODO: Implement deletion (at least to be used before pressing save), then allow it here
          allowDeletion={false}
          // responseText={responseText}
          // fileTypeError={this.state.fileTypeError}
          // setDocumentsChanged={this.setOtherDocumentsChanged}
        />
      </>
    )
  }

  disclosureSubmitted = () => {
    const { selectedDisclosureTabId } = this.state
    if (selectedDisclosureTabId === propertyDisclosureInputTypes.form.tabID)
      return this.submitDisclosureForm()
    return this.submitDisclosureAttachments()
  }

  submitDisclosureForm() {
    const { disclosureInputTypeId } = this.state
    if (disclosureInputTypeId === propertyDisclosureInputTypes.attachments.id) {
      snugNotifier.error(
        'Please use attachments tab, PDF uploads will be used for disclosures',
      )
      return
    }
    const allEmpty = this.isAllEmpty()
    if (allEmpty) {
      if (
        window.confirm(
          'Disclosures have not been entered. Are you sure you want to continue?',
        )
      ) {
        this.updateOnlistStatus()
        this.refs.child.updateCurrentComponentPlusOne()
        return
      }
    }

    if (
      window.confirm(
        'Please ensure that the details entered are correct. These cannot be changed later.',
      )
    ) {
      const errorsPresent = this.validateResponsesAndSetErrors()
      if (errorsPresent) {
        snugNotifier.error(
          'There was an error in submission. Please check your responses again',
        )
      } else {
        this.saveDisclosures()
      }
    }
  }

  removeDisclosureClicked() {
    const offerGUID = this.props.match.params.offerGUID
    const { guid: agencyGUID } = this.getChosenTeam()
    if (agencyGUID && offerGUID) {
      if (window.confirm('Are you sure?')) {
        return removePropertyDisclosure(agencyGUID, offerGUID)
          .then(() => {
            this.reloadDisclosure()
          })
          .catch((err) => snugNotifier.error(err.message))
      }
    }
  }

  submitDisclosureAttachments() {
    const { disclosureAttachments } = this.state
    if (disclosureAttachments.length > 0) {
      snugNotifier.success(
        'Attachments uploaded, and disclosure saved successfully',
      )
    }
    this.updateOnlistStatus()
    this.refs.child.updateCurrentComponentPlusOne()
  }

  uploadPropertyDisclosureAttachment = (documentType) => {
    const offerGUID = this.props.match.params.offerGUID
    const { guid: agencyGUID } = this.getChosenTeam()
    if (agencyGUID && offerGUID) {
      this.disclosureAttachmentLoadingStateUtils.startLoading()
      const attachments = document.getElementById(`attachments-${documentType}`)
      let formData = new FormData()

      composeAttachment(attachments, formData)

      addPropertyOfferDisclosureAttachments(agencyGUID, offerGUID, formData)
        .then(() => {
          snugNotifier.success('Successfully uploaded disclosure files')
          this.setState({ areDisclosuresEditable: false })
          this.fetchAndSetDisclosure().then(
            this.disclosureAttachmentLoadingStateUtils.markDoneSuccessfully(),
          )
        })
        .catch((error) => {
          this.disclosureAttachmentLoadingStateUtils.setError(error.message)
          snugNotifier.error(error.message)
        })
    }
  }

  validateAndJumpToStep(step) {
    if (!onlistSteps.validate(step)) {
      snugNotifier.error('Invalid step')

      return
    }

    this.refs.child.jumpToComponent(step)
  }

  handleVacateDateUpdated({ availableFrom, vacateDate }) {
    this.setState({
      offerInfo: {
        ...this.state.offerInfo,
        availableFrom,
        vacateDate,
      },
    })
  }

  updateOnlistStatus = () => {
    const offerGUID = this.props.match.params.offerGUID
    const { guid: teamGUID } = this.getChosenTeam()
    updateOfferOnlistStatus(teamGUID, offerGUID, {
      onlistStatus: textHelpers.STATUS_ONLIST_COMPLETE,
    }).catch((error) => {
      snugNotifier.error(error.message)
    })
  }

  onHousingCategoryChanged = ({ value }) => {
    const {
      match: {
        params: { offerGUID = '' },
      },
    } = this.props
    const id = housingCategoryOptions[value].value
    this.setState({ selectedHousingCategory: value })
    return updateOfferHousingCategory(offerGUID, id)
      .then(() => {
        snugNotifier.success(`Housing category has been updated successfully`)
      })
      .catch((err) => {
        if (err.message) {
          snugNotifier.error(err.message)
        }
      })
  }

  onBallotsEnabled = () => {
    const teamGUID = this.getCurrentTeamGUID()

    const {
      match: {
        params: { offerGUID = '' },
      },
    } = this.props
    if (this.state.ballotsEnabled) {
      const payload = {
        ballotsEnabled: false,
      }
      updateOfferBallots(offerGUID, payload)
        .then(() => {
          snugNotifier.success('Ballots has been updated successfully')
          this.setState({
            ballotsEnabled: !this.state.ballotsEnabled,
            ballotsIncomeTable: '',
            ballotsState: '',
          })
        })
        .catch((err) => {
          if (err.message) {
            snugNotifier.error(err.message)
          }
        })
    } else {
      this.setState({
        ballotsEnabled: !this.state.ballotsEnabled,
      })
      this.setState({
        isIncomeTableFetching: true,
      })
      getIncomeTableForAgency(teamGUID)
        .then((incomeTableData) => {
          this.setState({
            incomeTables: incomeTableData || [],
            isIncomeTableFetching: false,
          })
        })
        .catch(() => {
          snugNotifier.error('Error while fetching income table')
          this.setState({
            isIncomeTableFetching: false,
          })
        })
    }
  }

  onBallotsStateChanged = ({ value }) => {
    this.setState({ ballotsState: value, ballotsIncomeTable: '' })
  }

  onBallotsIncomeTableChanged = ({ value }) => {
    const { ballotsState, incomeTables } = this.state
    const {
      match: {
        params: { offerGUID = '' },
      },
    } = this.props
    this.setState({ ballotsIncomeTable: value })

    const incomeTableID = incomeTables[Number(value)]?.id || ''

    if (ballotsState && incomeTableID) {
      const payload = {
        ballotsEnabled: true,
        ballotsState: onlistSteps.stateOptionsArray[ballotsState],
        ballotsIncomeTableID: incomeTableID,
      }
      return updateOfferBallots(offerGUID, payload)
        .then(() => {
          snugNotifier.success('Ballots has been updated successfully')
        })
        .catch((err) => {
          if (err.message) {
            snugNotifier.error(err.message)
          }
        })
    } else {
      snugNotifier.error('Ballot state and income table are required')
    }
  }

  renderNoIncomeTables = () => {
    return (
      <Alert mt={3} variant="blueWithBg">
        <Box>
          Please contact <a href="mailto:help@snug.com">help@snug.com</a> to add
          an income table
        </Box>
      </Alert>
    )
  }

  render() {
    const {
      fetchAgencyApplicantsElasticSearchResult,
      fetchCurrentOwners,
      addCurrentOwners,
      updateCurrentOwners,
      removeCurrentOwners,
      ownerModalCategory,
      currentAgency,
      fetchCurrentTenants,
      addCurrentTenants,
      updateCurrentTenants,
      removeCurrentTenants,
      sendEntryNotice,
    } = this.props
    const { guid: teamGUID } = this.getChosenTeam()
    const {
      areDisclosuresEditable,
      isLoadingDisclosure,
      disclosureInputTypeId,
      offerInfo,
      selectedHousingCategory,
      ballotsEnabled,
      ballotsIncomeTable,
      ballotsState,
      incomeTables,
      applicationTeamSettings: { application_ballots_enabled },
      isIncomeTableFetching,
    } = this.state
    const incomeTableOptions = this.getIncomeTableNames()
    const agencyIncomeTableLength = (incomeTables || []).length
    const submittedDisclosureComponent =
      disclosureInputTypeId === propertyDisclosureInputTypes.attachments.id
        ? this.renderDisclosureAttachmentsTab(false)
        : this.renderDisclosureFormTab()
    const offerGUID = this.props.match.params.offerGUID

    const { property, managementTypeSelectedIndex } = this.state
    const {
      address,
      chosenPropertyID = property.guidID,
      slug: propertySlug,
    } = property
    const { friendlyName, suburb } = address || ''
    const propertyAddress = `${friendlyName}, ${suburb}`
    const { teamSlug = '' } = this.props.match.params
    const stepperSettings = {
      0: {
        primaryButtonLabel: 'Next',
        // workaround to allow submitting management type (via next button) even if disclosures are not editable
        forceUsePrimaryButtonAction: true,
        primaryButtonAction: () => {
          if (
            typeof managementTypeSelectedIndex === 'undefined' ||
            managementTypeSelectedIndex < 0
          ) {
            snugNotifier.error('Please select management type')
            return
          }
          this.refs.child.updateCurrentComponentPlusOne()
        },
      },
      1: {
        primaryButtonLabel: 'Next',
        primaryButtonAction: () => {
          this.refs.child.updateCurrentComponentPlusOne()
        },
      },
      3: {
        primaryButtonLabel: 'Next',
        forceUsePrimaryButtonAction: true,
        primaryButtonAction: () => {
          this.updateOnlistStatus()
          this.refs.child.updateCurrentComponentPlusOne()
        },
      },
      2: {
        primaryButtonLabel: 'Next',
        primaryButtonAction: () => {
          this.refs.child.updateCurrentComponentPlusOne()
        },
      },
      4: {
        primaryButtonLabel: 'Finished',
        primaryButtonAction: () => {
          this.redirectToPageAfterFinished()
        },
      },
    }
    const allComponents = [
      <div>
        <TextComp.Strong
          text="Property Details"
          textClass="fs24"
          componentClass="mb30"
        />
        <PropertyItem
          location={this.props.location}
          match={this.props.match}
          propertyInfo={{ property }}
          status="selected"
          onEditButtonClicked={() => {
            window.open(
              helpers.urlTo('updatePropertyBasics', { id: chosenPropertyID }),
            )
          }}
          isEditContainer
        />
        <Flex
          justifyContent="space-between"
          flexDirection={isMobile() ? 'column' : 'row'}
        >
          <Box>
            <div className="pt30">
              <span className="mb5">Select a Management Type</span>
              <Dropdown
                name="managementType"
                label="N/A"
                value={managementTypeSelectedIndex}
                options={allowedManagementTypesLabels}
                validate={() => {}}
                onChange={({ value, err }) =>
                  this.handleManagementTypeChange({ value, err })
                }
              />
            </div>
            <Box mt={6}>
              <label>
                Select housing category{' '}
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="housingCategoryTooltip"
                />
              </label>
              <ReactTooltip id="housingCategoryTooltip">
                <span>Add housing category attachments in Team Settings</span>
              </ReactTooltip>
              <Dropdown
                label="Housing category"
                options={housingCategoriesLabels}
                value={selectedHousingCategory}
                onChange={this.onHousingCategoryChanged}
              />
            </Box>
            {isShowBallotsEnabledForTeam(teamSlug) &&
              application_ballots_enabled && (
                <>
                  <Box mt={6}>
                    <CheckBoxGeneral
                      label="Enable application ballots"
                      onChange={this.onBallotsEnabled}
                      checked={ballotsEnabled}
                      eventProcessedByComponent
                      toolTip={
                        <div>
                          <i
                            className="icon-help-outline fs16 gray-light-color ibm"
                            data-tooltip-content=""
                            data-tooltip-id="enableApplicationBallots"
                          />
                        </div>
                      }
                    />
                  </Box>
                  <div>
                    <ReactTooltip id="enableApplicationBallots">
                      <span>
                        Enable running random ballot application
                        <br />
                        selection for property
                        <br />
                      </span>
                    </ReactTooltip>
                  </div>
                  {!!ballotsEnabled &&
                    (isIncomeTableFetching ? (
                      <Spinner />
                    ) : !(agencyIncomeTableLength > 0) ? (
                      <>{this.renderNoIncomeTables()}</>
                    ) : (
                      <div>
                        <Text mb={0} fontWeight={theme.fontWeights[6]}>
                          State
                        </Text>
                        <Dropdown
                          label="State"
                          value={ballotsState}
                          options={onlistSteps.stateOptionsArray}
                          onChange={this.onBallotsStateChanged}
                        />
                        <Text mb={0} fontWeight={theme.fontWeights[6]}>
                          Income Table
                        </Text>
                        <>
                          {!Boolean(incomeTableOptions?.length > 0) &&
                          ballotsState ? (
                            <>{this.renderNoIncomeTables()}</>
                          ) : (
                            <>
                              <Dropdown
                                label="Income Table"
                                value={ballotsIncomeTable}
                                options={incomeTableOptions}
                                onChange={this.onBallotsIncomeTableChanged}
                                useLabelValueOptions={true}
                                extraOptionTextFromOptions={true}
                              />
                            </>
                          )}
                        </>
                      </div>
                    ))}
                </>
              )}
          </Box>
        </Flex>
        <Text as="h5" mt={9} mb={3}>
          Drive more inspections and applications: add this unique Property QR
          Code to your portal advert image carousel
        </Text>
        <Flex alignItems="center">
          <OnlistQRCode slug={propertySlug} address={address} />
        </Flex>
      </div>,
      <AddOwnerAndTenant
        fetchAgencyApplicantsElasticSearchResult={
          fetchAgencyApplicantsElasticSearchResult
        }
        handleVacateDateUpdated={(e) => this.handleVacateDateUpdated(e)}
        teamGUID={teamGUID}
        address={address}
        ownerModalCategory={ownerModalCategory}
        chosenPropertyText={propertyAddress}
        chosenPropertyID={chosenPropertyID}
        fetchCurrentOwners={fetchCurrentOwners}
        currentTeam={currentAgency}
        addCurrentOwners={addCurrentOwners}
        updateCurrentOwners={updateCurrentOwners}
        removeCurrentOwners={removeCurrentOwners}
        fetchCurrentTenants={fetchCurrentTenants}
        addCurrentTenants={addCurrentTenants}
        updateCurrentTenants={updateCurrentTenants}
        tenantModalCategory={helpers.TenantModalCategory.addTenants}
        removeCurrentTenants={removeCurrentTenants}
        sendEntryNotice={sendEntryNotice}
        offerGUID={offerGUID}
        offerInfo={offerInfo}
      />,
      <OnListDetailsContainer
        location={this.props.location}
        match={this.props.match}
        isFromOnlistPropertyDisclosures={true}
      />,
      //  TODO: Create PropertyDisclosurePage component wrapping all components and calling disclosure APIs
      <>
        {!isLoadingDisclosure && (
          <>
            <OwnerPropertyDisclosures
              disclosureTabs={this.renderDisclosureTabs()}
              agencyGUID={teamGUID}
              address={address}
              match={this.props.match}
              jumpToStep={(step) => this.validateAndJumpToStep(step)}
              disclosureSubmitted={this.disclosureSubmitted}
              reloadDisclosure={this.reloadDisclosure}
            />
            {!areDisclosuresEditable && (
              <>
                <ButtonWithLoading
                  variant="outlineWarning"
                  onClick={() => this.removeDisclosureClicked()}
                >
                  Remove Disclosure
                </ButtonWithLoading>
                <Box mt={`${theme.space[6]}px`}>
                  {submittedDisclosureComponent}
                </Box>
              </>
            )}
          </>
        )}
      </>,

      <FinishedStep />,
    ]
    return (
      <Stepper
        allComponentsToRender={allComponents}
        location={this.props.location}
        match={this.props.match}
        redirectToPageAfterFinished={this.redirectToPageAfterFinished}
        stepperSettings={stepperSettings}
        useProvidedSettings={areDisclosuresEditable}
        ref="child"
        callFunctionOnSpecificStep={this.reloadDisclosure}
        callFunctionOnSpecificStepNum={onlistConstants.DISCLOSURES_LIST_STEP}
      />
    )
  }
}

export default OnlistPropertyOffer
