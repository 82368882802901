import {
  ADD_VIEWING_TO_PROPERTY_ERROR,
  ADD_VIEWING_TO_PROPERTY_SUCCESS,
  RECEIVE_ERROR_PRELOAD,
  UPDATE_PRELOAD_SPINNER_HIDE,
  UPDATE_PRELOAD_SPINNER_SHOW,
} from 'app/pages/teams/viewings-mobile/action'

const defaultState = {
  showSpinner: true,
  preloadFetchErrors: '',
  addViewingError: '',
  addViewingSuccess: false,
}

const PageViewings = (state = defaultState, action) => {
  let { preloadFetchErrors, addViewingError, addViewingSuccess, type } = action
  switch (type) {
    case UPDATE_PRELOAD_SPINNER_SHOW:
      return {
        ...state,
        showSpinner: true,
      }

    case UPDATE_PRELOAD_SPINNER_HIDE:
      return {
        ...state,
        showSpinner: false,
      }

    case RECEIVE_ERROR_PRELOAD:
      return {
        ...state,
        preloadFetchErrors,
      }

    case ADD_VIEWING_TO_PROPERTY_SUCCESS:
      return {
        ...state,
        addViewingError: '',
        addViewingSuccess,
      }

    case ADD_VIEWING_TO_PROPERTY_ERROR:
      return {
        ...state,
        addViewingError,
      }

    default:
      return state
  }
}

export default PageViewings
