import { LOCATION_CHANGE } from 'connected-react-router'
import merge from 'lodash/merge'

import {
  RECEIVE_AGENCY_REQUESTS,
  RECEIVE_RESPONSE_TEXT,
  TOGGLE_SPINNER,
} from 'app/bond_cover/agency/dashboard/agency_dashboard_actions'

const _defaultState = {
  responseText: '',
  spinner: false,
}

const AgencyDashboardReducer = (state = _defaultState, action) => {
  let { type, bondCovers, responseText } = action
  Object.freeze(state)
  let newState = merge({}, state)
  switch (type) {
    case RECEIVE_AGENCY_REQUESTS:
      return Object.assign({}, newState, { bondCovers })
    case RECEIVE_RESPONSE_TEXT:
      return Object.assign({}, newState, { responseText })
    case TOGGLE_SPINNER:
      return Object.assign(newState, { spinner: !newState.spinner })
    default:
      return state
  }
}

export default AgencyDashboardReducer
