import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Box, Flex } from 'app/components/design-system-components'
import { HelpOutlineRounded } from 'app/components/design-system-components/icons/actions'
import { DIRECT_CONNECT } from 'app/constants/ucs.constants'

export function getUtilityProviderDisclosureConsent(
  providerName: string,
): string {
  return `I consent to ${providerName} contacting me about moving services and utility connection (electricity, gas, internet etc). If I opt out, the property manager may contact me about mandatory services such as water connection.`
}

const DEFAULT_UTILITY_PROVIDER = DIRECT_CONNECT
export const DEFAULT_PROVIDER_TEXT = '(Default Provider)'

export const HelpIconComponentAndTooltip = () => {
  return (
    <>
      <HelpOutlineRounded
        data-tooltip-content=""
        data-tooltip-id="default-utility-referral"
      />
      <ReactTooltip
        id="default-utility-referral"
        data-type="info"
        place="top"
        className="tool-tip-style"
      >
        <Box maxWidth="222px" textAlign="center">
          The default utility partner can be updated via Snug Support.
        </Box>
      </ReactTooltip>
    </>
  )
}

export function DefaultUtilityProviderComponent() {
  return (
    <Flex alignItems="center">
      <Box mr={3}>
        Send {DEFAULT_UTILITY_PROVIDER} {DEFAULT_PROVIDER_TEXT} referral (if
        opted in)
      </Box>
      <HelpIconComponentAndTooltip />
    </Flex>
  )
}
