import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import lockIcon from 'app/assets/icons/lock.svg'
import * as Display from 'app/components/display/display'
import * as FormComponent from 'app/shared_components/form_component'
import * as helpers from 'app/sm/helpers'
import { NO } from 'app/sm/helpers'

const ViewingContactItem = ({
  contact,
  isEnquiry = false,
  isSearch = false,
  enableRemove = false,
  onRemoveClicked,
  onClick = () => {},
  disableRemoveButton = false,
  containerClass = '',
  isInspector,
  onChangeInspector,
  isInspectorChecked,
}) => {
  const checkInspector = isInspectorChecked
    ? isInspectorChecked[contact.guidID]
    : false
  const lockMessage =
    contact.isLocked &&
    contact.source &&
    `Contact is imported from ${contact.source}. To change contact details, update in ${contact.source}`
  return (
    <div className="meta">
      <div className="meta-wrapper owner-section">
        <div className="info flex-direction-row-under500 display-flex align-items-center width100">
          <div
            className="mr-auto display-flex align-items-center"
            onClick={() => onClick(contact)}
            style={
              isInspector && {
                width: '55%',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }
            }
          >
            <Display.Avatar
              avatarText={helpers.firstAndLastNameInitials(contact, 'both')}
            />
            {isSearch && (
              <Display.StandardContactInfoContainer
                mainTitle={`${helpers.capitalizeFirstLetter(
                  contact.firstName,
                )} ${helpers.capitalizeFirstLetter(contact.lastName)}`}
                firstLineText={contact.phone}
                secondLineText={contact.email}
                thirdLineText={
                  isEnquiry && (
                    <div className="offer">
                      Enquired at{' '}
                      {helpers.dateUtil.processDateText(
                        contact.enquiryDate,
                        helpers.dateWithWeekDayTimeFormat,
                      )}
                    </div>
                  )
                }
                fourthLineText={
                  contact.tenantOptedInToElectronicCommunication === NO && (
                    <p className="applicant-info-text mb0 pdf-font-size-10 pink-text">
                      No electronic tenancy notices
                    </p>
                  )
                }
              />
            )}
            {!isSearch && (
              <Display.StandardContactInfoContainer
                mainTitle={`${helpers.capitalizeFirstLetter(
                  contact.firstName,
                )} ${helpers.capitalizeFirstLetter(contact.lastName)}`}
                firstLineText={contact.phone}
                secondLineText={contact.email}
                thirdLineText={
                  isEnquiry && (
                    <div className="offer">
                      Enquired at{' '}
                      {helpers.dateUtil.processDateText(
                        contact.enquiryDate,
                        helpers.dateWithWeekDayTimeFormat,
                      )}
                    </div>
                  )
                }
                fourthLineText={
                  contact.tenantOptedInToElectronicCommunication === NO && (
                    <p className="applicant-info-text mb0 pdf-font-size-10 pink-text">
                      No electronic tenancy notices
                    </p>
                  )
                }
                containerClass={containerClass}
              />
            )}
          </div>
          {isInspector && (
            <div style={{ width: isInspector && '30%' }}>
              <FormComponent.Checkbox
                id={contact.guidID}
                label="Inspector"
                onChange={(event) => onChangeInspector(event, contact)}
                checked={checkInspector}
              />
            </div>
          )}
          {contact.isLocked && (
            <div>
              <img
                src={lockIcon}
                alt="lock icon"
                data-tooltip-id={`lockMsg${contact.guidID}`}
                data-tooltip-content=""
              />
              {lockMessage && (
                <ReactTooltip
                  id={`lockMsg${contact.guidID}`}
                  data-type="info"
                  place="bottom"
                  className="tool-tip-style"
                >
                  <span>{lockMessage}</span>
                </ReactTooltip>
              )}
            </div>
          )}
          {!contact.isLocked && enableRemove && (
            <div>
              <Display.GenericButton
                text="Remove"
                onClick={(event) => onRemoveClicked(event, contact)}
                buttonType={'destructive-hollow'}
                disabled={disableRemoveButton}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ViewingContactItem
