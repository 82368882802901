import React from 'react'

import styled from 'styled-components'

import { HelpColored } from 'app/components/design-system-components/icons/actions/Colored'
import DoneRounded from 'app/components/design-system-components/icons/actions/DoneRounded'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'
import { RentalReputationItems } from 'app/sm/helpers'

import 'app/components/progress_bar/style.scss'

const StyledDoneRoundedIcon = styled(DoneRounded)`
  height: 24px;
  width: 24px;
  min-width: 24px;
  background: ${theme.colors.success600};
  border-radius: 50%;
  color: white;
`

const StyledHelpIcon = styled(HelpColored)`
  border-radius: 50%;
  background-color: ${theme.colors.success600};
  color: white;
`

const ProfileCompletenessCollection = [
  {
    generalName: 'Important',
  },
  {
    generalName: 'Support',
  },
  {
    generalName: 'Extra',
  },
]

const CurrentPositionCollection = [
  'application preference',
  'important',
  'support',
  'extra',
  'application summary',
]

const getCurrentPosition = () => {
  if (
    (window.location.pathname.includes('/sm/applications') ||
      window.location.pathname.includes('/sm/property')) &&
    !window.location.pathname.includes('rentersummary')
  ) {
    return 0
  } else if (window.location.pathname.includes('rentersummary')) {
    return 4
  }
  const curentItem =
    RentalReputationItems.find((item) =>
      window.location.pathname.includes(item.url),
    ) || {}
  return curentItem.category
}
class ProgressBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onClickNextStep = (currentStepIndex) => {
    const rentalBackUrl = window.location.pathname + window.location.search

    const { applicationSummaryUrl, clickNextFunctionFromParentComponent } =
      this.props
    if (clickNextFunctionFromParentComponent) {
      clickNextFunctionFromParentComponent()
    } else {
      this.props.addRentalReputationBackUrl(rentalBackUrl)

      if (applicationSummaryUrl) {
        history.push(applicationSummaryUrl)
      } else if (currentStepIndex < RentalReputationItems.length - 1) {
        history.push(
          `${RentalReputationItems[currentStepIndex + 1].url}${
            window.location.search
          }`,
        )
      }
    }
  }

  render() {
    const { completeness, applied } = this.props
    const addressName = getParameter('address')
    const applicationStatus = getParameter('isApplied')
    const isAtBeginning =
      (window.location.pathname.includes('sm/applications') ||
        window.location.pathname.includes('sm/property')) &&
      !window.location.pathname.includes('/rentersummary')
    const atApplicationPreference =
      window.location.pathname.includes('/sm/applications') ||
      window.location.pathname.includes('/sm/property')

    const currentPosition = getCurrentPosition()

    let lastBubbleClass = 'incomplete'
    let lastBarClass = 'incomplete'
    if (window.location.pathname.includes('/rentersummary')) {
      lastBubbleClass = 'current'
      lastBarClass = 'current'
    } else if (applied || applicationStatus === 'Submitted') {
      lastBubbleClass = 'success'
    }

    if (CurrentPositionCollection[currentPosition] === 'application summary') {
      lastBarClass = 'success'
    }
    const currentStepIndex = RentalReputationItems.findIndex((item) =>
      window.location.pathname.includes(item.url),
    )

    return (
      <div>
        {completeness && (addressName || atApplicationPreference) && (
          <div className="">
            <div className="progress-bar-container display-flex">
              <div className="progress-spot-component">
                <div
                  className={`display-flex progress-spot-dot_${
                    isAtBeginning ? 'current' : 'success'
                  }`}
                >
                  {isAtBeginning ? (
                    <span className="number-spacing">1</span>
                  ) : (
                    <StyledDoneRoundedIcon />
                  )}
                </div>
              </div>
              {ProfileCompletenessCollection.map((completeNessItem, index) => {
                let bubbleStyle = 'incomplete'
                let barStyle = 'incomplete'
                let showCurrentStyle = false
                let showIncompleteStyle = true
                const itemOrder = index + 1
                if (completeNessItem.generalName === 'Important') {
                  showCurrentStyle = !!RentalReputationItems.filter(
                    (item) => item.category === 1,
                  ).find((item) => window.location.pathname.includes(item.url))
                  showIncompleteStyle = RentalReputationItems.filter(
                    (item) => item.category === 1,
                  ).find((item) => !completeness[item.key])
                }
                if (completeNessItem.generalName === 'Support') {
                  showCurrentStyle = !!RentalReputationItems.filter(
                    (item) => item.category === 2,
                  ).find((item) => window.location.pathname.includes(item.url))
                  showIncompleteStyle = RentalReputationItems.filter(
                    (item) => item.category === 2,
                  ).find((item) => !completeness[item.key])
                }
                if (completeNessItem.generalName === 'Extra') {
                  showCurrentStyle = !!RentalReputationItems.filter(
                    (item) => item.category === 3,
                  ).find((item) => window.location.pathname.includes(item.url))
                  showIncompleteStyle = RentalReputationItems.filter(
                    (item) => item.category === 3,
                  ).find((item) => !completeness[item.key])
                }
                if (showCurrentStyle) {
                  bubbleStyle = 'current'
                  barStyle = 'current'
                } else if (!showIncompleteStyle) {
                  bubbleStyle = 'success'
                }
                if (currentPosition >= index + 1) {
                  barStyle = 'success'
                }
                return (
                  <div className="progress-spot-component">
                    <div className={`progress-spot-line_${barStyle}`} />
                    <div
                      className={`display-flex progress-spot-dot_${bubbleStyle}`}
                    >
                      {bubbleStyle === 'success' && <StyledDoneRoundedIcon />}
                      {bubbleStyle === 'current' && (
                        <span className="number-spacing">{itemOrder + 1}</span>
                      )}
                      {bubbleStyle === 'incomplete' &&
                        (currentPosition >= index + 1 ? (
                          <StyledHelpIcon />
                        ) : (
                          <span className="number-spacing">
                            {itemOrder + 1}
                          </span>
                        ))}
                    </div>
                  </div>
                )
              })}
              <div className="progress-spot-component">
                <div className={`progress-spot-line_${lastBarClass}`} />
                <div
                  className={`display-flex progress-spot-dot_${lastBubbleClass}`}
                >
                  {lastBubbleClass === 'success' && <StyledDoneRoundedIcon />}
                  {lastBubbleClass === 'incomplete' && (
                    <span className="number-spacing">5</span>
                  )}
                  {lastBubbleClass === 'current' && (
                    <span className="number-spacing">5</span>
                  )}
                </div>
              </div>
            </div>
            {!atApplicationPreference && (
              <div className="progress-status display-flex justify-content-space-between">
                <span>
                  {applicationStatus} Application:{' '}
                  <span className="progress-status_address">{addressName}</span>
                </span>
                <span
                  role="button"
                  className="next-step text-align-right"
                  onClick={() => this.onClickNextStep(currentStepIndex)}
                  tabIndex={0}
                >
                  {currentStepIndex < RentalReputationItems.length - 1
                    ? RentalReputationItems[currentStepIndex + 1].name
                    : 'Application Summary'}
                  <i className="icon-arrow-right pl5" />
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default ProgressBar
