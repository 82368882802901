import isEmptyObject from 'app/shared_components/check_empty_object'

export default function MultiTenantErrors(currentLease, tenants, isNewLease) {
  if (!currentLease || !tenants) {
    return null
  }

  let noSecondaryTenants = tenants.length < 2

  let error = ''

  if (noSecondaryTenants) {
    return (error = 'Tenants')
  }

  if (isNewLease) {
    return null
  }

  let bondSplitSum = 0

  if (!isEmptyObject(tenants)) {
    tenants.forEach((tenant) => {
      let bondAmount = tenant.bondAmount
      if (isNaN(bondAmount)) {
        bondAmount = 0
      }
      bondSplitSum += parseFloat(bondAmount)
    })
  }
  if (bondSplitSum !== currentLease.bond) {
    return (error = 'BondSplit')
  }

  return null
}
