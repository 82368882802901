import { api } from 'app/utils/api/helpers'

export const resendReferenceRequest = (
  employmentHistoryId,
  applicationId,
  payload,
  teamGUID,
) => {
  return api.post(
    `/sm/team/${teamGUID}/application/${applicationId}/employer/${employmentHistoryId}/sendconfirmation`,
    payload,
  )
}
