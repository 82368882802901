import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  Box,
  Button,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'

type Props = {
  selectedKeysCount: number
  checkoutConfig: IActionConfigProp
}

export const BulkyActionsControls = ({
  selectedKeysCount,
  checkoutConfig,
  ...otherStyledProps
}: Props) => {
  return (
    <Flex alignItems="center" {...otherStyledProps}>
      <Box mr={4}>
        <TextSpan color="deepBlue" fontWeight="bold">
          {selectedKeysCount}
        </TextSpan>{' '}
        Selected
      </Box>
      <Box data-tooltip-id="bulkCheckoutBtn" data-tooltip-content="">
        <Button
          mr={4}
          variant="outlineSuccess"
          width="unset"
          onClick={checkoutConfig.onClick}
          disabled={!checkoutConfig.isEnabled}
        >
          Checkout
        </Button>
      </Box>
      {!checkoutConfig.isEnabled && checkoutConfig.disableMsg && (
        <ReactTooltip id="bulkCheckoutBtn">
          <span>{checkoutConfig.disableMsg}</span>
        </ReactTooltip>
      )}
    </Flex>
  )
}

interface IActionConfigProp {
  onClick: () => void
  isEnabled: boolean
  disableMsg: string
}
