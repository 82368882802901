import {
  CompareConnect,
  ConnectNow,
  DirectConnect,
  FastConnect,
  FletchersConcierge,
  Foxie,
  HomeNow,
  HomenowConnect,
  HOODIcon,
  LjHookerAssist,
  LSREIcon,
  MovingHub,
  MyConnect,
  PrdSmarterConnect,
  RaineAndHorne,
  TenAntsIcon,
  TheWholeHouse,
  UCSPartnerDemo,
  YourPorter,
} from 'app/components/design-system-components/icons/ucs-logos'

export const FOXIE = 'Foxie'
export const FAST_CONNECT = 'FastConnect'
export const MOVING_HUB = 'MovingHub'
export const DIRECT_CONNECT = 'Direct Connect'
export const PRD_SMARTER_CONNECT = 'PRD Smarter Connect'
export const COMPARE_AND_CONNECT = 'Compare & Connect'
export const ON_THE_MOVE = 'On the Move'
export const MY_CONNECT = 'My Connect'
export const YOUR_PORTER = 'YourPorter'
export const HOME_NOW = 'Ray White Home Now'
export const CONNECT_NOW = 'ConnectNow'
export const THE_WHOLE_HOUSE = 'The Whole House'
export const LJ_HOOKER_ASSIST = 'LJ Hooker Assist'
export const UCS_PARTNER_DEMO = 'UCS Partner (Demo)'
export const RAINE_HORNE = 'Raine & Horne Connect'
export const HOOD = 'HOOD'
export const LAING_SIMMONDS_CONNECT = 'Laing+Simmons Connect'
export const TEN_ANTS = 'Ten Ants'
export const HOMENOW_CONNECT = 'HomeNow Connect'
export const FLETCHERS_CONCIERGE = 'Fletchers Concierge'

export const UCSProviderConfig = {
  [FOXIE]: {
    icon: Foxie,
  },
  [FAST_CONNECT]: {
    icon: FastConnect,
  },
  [MOVING_HUB]: {
    icon: MovingHub,
  },
  [DIRECT_CONNECT]: {
    icon: DirectConnect,
  },
  [PRD_SMARTER_CONNECT]: {
    icon: PrdSmarterConnect,
  },
  [COMPARE_AND_CONNECT]: {
    icon: CompareConnect,
  },
  [MY_CONNECT]: {
    icon: MyConnect,
  },
  [YOUR_PORTER]: {
    icon: YourPorter,
  },
  [HOME_NOW]: {
    icon: HomeNow,
  },
  [CONNECT_NOW]: {
    icon: ConnectNow,
  },
  [THE_WHOLE_HOUSE]: {
    icon: TheWholeHouse,
  },
  [LJ_HOOKER_ASSIST]: {
    icon: LjHookerAssist,
  },
  [UCS_PARTNER_DEMO]: {
    icon: UCSPartnerDemo,
  },
  [RAINE_HORNE]: {
    icon: RaineAndHorne,
  },
  [HOOD]: {
    icon: HOODIcon,
  },
  [LAING_SIMMONDS_CONNECT]: {
    icon: LSREIcon,
  },
  [TEN_ANTS]: {
    icon: TenAntsIcon,
  },
  [HOMENOW_CONNECT]: {
    icon: HomenowConnect,
  },
  [FLETCHERS_CONCIERGE]: {
    icon: FletchersConcierge,
  },
}
