import React, { useEffect, useReducer, useState } from 'react'

import moment from 'moment'
import { connect } from 'react-redux'

import activityBlurred from 'app/assets/icons/activity-blurred.png'
import { fetchTeamManagerList } from 'app/bond_cover/agency/agency_actions'
import { Box, LoadingSection } from 'app/components/design-system-components'
import ActivityCubeList from 'app/dashboard/team_activity/activity_cube_list/component'
import ActivityListRow from 'app/dashboard/team_activity/activity_list_row/component'
import * as actFilters from 'app/dashboard/team_activity/ActivityFilters'
import { activityPeriods } from 'app/dashboard/team_activity/ActivityFilters/DateRangeFilter/constants'
import { fetchTeamActivities } from 'app/dashboard/team_activity/team_activity_action'
import { LoadMore } from 'app/dashboard/team_overview_container'
import { getTeamManagers } from 'app/services/http/teams/managers'
import { setBackUrl } from 'app/shared_components/actions'
import SnugUpgradeAccount from 'app/shared_components/snug_account_upgrade'
import Spinner from 'app/sm/common/spinner'
import { persistManager } from 'app/sm/properties/properties_actions'
import { SessionStorageUtils } from 'app/storage_utils'
import * as accountHelpers from 'app/utils/accounts/helpers'
import { DATE_WITH_DASH } from 'app/utils/datetime/helpers'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { loadingStatesIds } from 'app/utils/loading-states'
import {
  filterReducerFactory,
  filtersActions,
} from 'app/utils/reducers/filtersReducer'

const Filters = ({ filtersState, filtersDispatch, currentTeam, managers }) => (
  <Box ml={2}>
    <actFilters.ActivitiesFilters
      teamId={currentTeam.guid}
      initialPeriod={activityPeriods.lastSeven}
      filters={filtersState.filters}
      managers={managers}
      includeManagersFilter
      updateFilter={(filterId, filterValues) =>
        filtersDispatch(filtersActions.updateFilter(filterId, filterValues))
      }
    />
  </Box>
)

export const StandardActivityReporting = ({
  currentTeam,
  fetchTeamActivities,
  persistedManager = '',
  setBackUrl,
}) => {
  const {
    state: filtersLoadedData,
    setState: setFiltersLoadedData,
    loadingStates: filtersLoadingStates,
    loadingStatesHelpers: setFiltersLoadingStatesHelpers,
  } = useStateWithLoading({})

  const {
    state: activitiesData,
    setState: setActivitiesData,
    loadingStates: actLoadingStates,
    loadingStatesHelpers: setActLoadingStatesHelpers,
  } = useStateWithLoading([])

  const [filtersState, filtersDispatch] = useReducer(
    filterReducerFactory(actFilters.filtersInitialState),
    actFilters.filtersInitialState,
  )

  const [nextCursor, setNextCursor] = useState('')

  useEffect(() => {
    if (currentTeam && currentTeam.guid) {
      loadFiltersBasicData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeam])

  useEffect(() => {
    setNextCursor('')
  }, [filtersState])

  const [showAllTab, setShowAllTab] = useState(false)
  const [enquiriesTotal, setEnquiriesTotal] = useState(0)
  const [viewersTotal, setViewersTotal] = useState(0)
  const [applicationsTotal, setApplicationsTotal] = useState(0)
  const [viewingsTotal, setViewingsTotal] = useState(0)
  const [propertyOffersTotal, setPropertyOffersTotal] = useState(0)
  const [sentUtilityReferrals, setSentUtilityReferrals] = useState(0)
  const [vacantProperties, setVacantProperties] = useState(0)
  const [manager, setManager] = useState('Any')

  useEffect(() => {
    if (filtersLoadingStates.state !== loadingStatesIds.LOADED) return
    !!persistedManager && setShowAllTab(true)
    setBackUrl(window.location.pathname)
    SessionStorageUtils.setItem('forwardUrl', window.location.pathname)
    setManager(persistedManager)

    if (currentTeam && currentTeam.guid) {
      callFetchTeamActivities()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersState])

  const loadFiltersBasicData = () => {
    setFiltersLoadingStatesHelpers.startLoading()
    getTeamManagers(currentTeam.guid)
      .then((managersRes) => {
        setFiltersLoadedData({ managers: managersRes.managerList })
        setFiltersLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => setFiltersLoadingStatesHelpers.setError(err))
  }

  const onGetActivities = (activities) => {
    setActivitiesData([...activitiesData, ...activities.data])
    setNextCursor(activities.response_metadata.next_cursor)
    setEnquiriesTotal(activities.response_metadata.enquiriesTotal)
    setViewersTotal(activities.response_metadata.viewingsAttendeesTotal)
    setApplicationsTotal(activities.response_metadata.applicationsTotal)
    setViewingsTotal(activities.response_metadata.viewingsTotal)
    setPropertyOffersTotal(activities.response_metadata.propertyOffersTotal)
    setSentUtilityReferrals(activities.response_metadata.utilityReferralsSent)
    setVacantProperties(activities.response_metadata.vacantPropertiesCount)
  }

  const callFetchTeamActivities = () => {
    setActLoadingStatesHelpers.startLoading()
    const {
      range,
      managers = [],
      sortDir,
      sort,
    } = actFilters.getFilters(filtersState)

    const formattedRange = {
      ...(range?.startDate && {
        startDate: moment(range.startDate).format(DATE_WITH_DASH),
      }),
      ...(range?.endDate && {
        endDate: moment(range.endDate).format(DATE_WITH_DASH),
      }),
    }

    return fetchTeamActivities(currentTeam.guid, {
      ...formattedRange,
      manager: managers.map((manager) => manager.guidID),
      sortDir,
      sort,
      cursor: nextCursor,
    })
      .then(({ activities }) => {
        onGetActivities(activities)
        setActLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((error) => {
        setActLoadingStatesHelpers.setError(error)
      })
  }

  let isAccountLite = true
  if (currentTeam && currentTeam.name) {
    isAccountLite = currentTeam.accountType === accountHelpers.ACCOUNT_TYPE_LITE
  }

  const utilityProvider =
    currentTeam?.utilityProviderConfigured && currentTeam?.providerName

  const tableSpinner =
    actLoadingStates.state === loadingStatesIds.LOADING && !nextCursor
  const loadMoreSpinner =
    actLoadingStates.state === loadingStatesIds.LOADING && !!nextCursor

  return (
    <div>
      {isAccountLite ? (
        <div>
          <img src={activityBlurred} alt="blurred activities" />
          <SnugUpgradeAccount
            typeOfMessage={accountHelpers.UPGRADE_TYPE_ACTIVITY}
          />
        </div>
      ) : (
        <div>
          <LoadingSection loadingState={filtersLoadingStates}>
            <Filters
              currentTeam={currentTeam}
              filtersState={filtersState}
              managers={filtersLoadedData?.managers}
              filtersDispatch={filtersDispatch}
            />
          </LoadingSection>
          <div className="col-first">
            <div>
              <ActivityCubeList
                enquiriesTotal={enquiriesTotal}
                viewersTotal={viewersTotal}
                applicationsTotal={applicationsTotal}
                viewingsTotal={viewingsTotal}
                propertyOffersTotal={propertyOffersTotal}
                utilityProvider={utilityProvider}
                sentUtilityReferrals={sentUtilityReferrals}
                vacantProperties={vacantProperties}
              />
              {tableSpinner ? (
                <Spinner />
              ) : (
                <div className="mt20">
                  {activitiesData &&
                    activitiesData.length > 0 &&
                    activitiesData.map((activity, index) => {
                      return (
                        <ActivityListRow
                          key={index}
                          date={activity.date}
                          friendlyName={activity.address.friendlyName}
                          suburb={activity.address.suburb}
                          action={activity.action}
                        />
                      )
                    })}
                </div>
              )}
              {nextCursor && (
                <div className={`text-center mt20`}>
                  {loadMoreSpinner ? (
                    <Spinner />
                  ) : (
                    <LoadMore
                      cursor={nextCursor}
                      loadMore={callFetchTeamActivities}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ session, MyProperties }) => ({
  currentTeam: session.currentTeam,
  persistedManager: MyProperties.persistedManager,
})

const mapDispatchToProps = (dispatch) => ({
  setBackUrl: (backUrl) => dispatch(setBackUrl(backUrl)),
  fetchTeamActivities: (teamGUID, filters) =>
    dispatch(fetchTeamActivities(teamGUID, filters)),
  fetchTeamManagerList: (teamGUID) => dispatch(fetchTeamManagerList(teamGUID)),
  persistManager: (manager) => dispatch(persistManager(manager)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StandardActivityReporting)
