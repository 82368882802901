import React from 'react'

import $ from 'jquery'
import moment from 'moment'
import qs from 'qs'
import ReactDOM from 'react-dom'

import calendarDownload from 'app/assets/icons/calendar-download.svg'
import * as Form from 'app/components/forms/forms'
import isEmptyObject from 'app/shared_components/check_empty_object'
import DatePicker from 'app/shared_components/date_picker'
import DownloadIcs from 'app/shared_components/download_ics'
import { history } from 'app/shared_components/router'
import TimePicker from 'app/shared_components/time_picker'
import {
  validateStartDate,
  validateStartTime,
} from 'app/shared_components/validations'
import Spinner from 'app/sm/common/spinner'
import { convertDateToUTCAndFormat } from 'app/sm/helpers'
import { filterViewings, urlTo } from 'app/sm/helpers'
import Breadcrumbs from 'app/sm/ppp/breadcrumbs'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

export const durations = {
  10: '10m',
  15: '15m',
  20: '20m',
  30: '30m',
  45: '45m',
  60: '60m',
}

class PropertyViewings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeUpcomingTimes: true,
      activePastTimes: false,
      currentDate: moment().format('DD MMM YYYY'),
      curentTime: moment().format('hh:mm A'),
      selectedDuration:
        (!props.updatedViewing && props.updatedViewing.duration) || 30,
      selectedEndTime:
        (!props.updatedViewing && props.updatedViewing.endTime) || '',
      selectedDate:
        (!props.updatedViewing && props.updatedViewing.listedDate) || '',
      selectedStartTime:
        (!props.updatedViewing && props.updatedViewing.startTime) || '',
      disabled: true,
      errors: {},
      inputErrors: {},
      isActive: false,
      isDatePickerActive: false,
      published: false,
      notify: false,
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.props.fetchViewings(params.offerId)
    // if the user bookmarked and viewed this page
    // we need additional resources to be fetched
    this.props.fetchProfile(params.offerId)
    this.props.fetchOffer(params.offerId)
    this.simulateOnBlurEvent()

    if (this.props.property && this.props.property.guidID) {
      const propertyAgencyID =
        this.props.property && this.props.property.agencyID
      const currentTeam = this.props.teams.find(
        (team) => team.guid === propertyAgencyID,
      )
      currentTeam && this.props.changeTeam(currentTeam)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.property && nextProps.property.guidID) {
      const currentPropertyAgencyID =
        this.props.property && this.props.property.agencyID
      const nextPropertyAgencyID =
        nextProps.property && nextProps.property.agencyID
      if (
        nextProps.teams !== this.props.teams ||
        nextPropertyAgencyID !== currentPropertyAgencyID
      ) {
        const currentTeam = nextProps.teams.find(
          (team) => team.guid === nextPropertyAgencyID,
        )
        currentTeam && this.props.changeTeam(currentTeam)
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.updatedViewing !== nextProps.updatedViewing) {
      this.setState(
        {
          selectedDuration: nextProps.updatedViewing.duration,
          selectedEndTime: nextProps.updatedViewing.endTime,
          selectedDate: nextProps.updatedViewing.listedDate,
          selectedStartTime: nextProps.updatedViewing.startTime,
        },
        () => {
          if (!isEmptyObject(nextProps.updatedViewing)) {
            this.validate('startDate', true)
            this.validate('startTime', true)
            this.validate('endTime', true)
          }
        },
      )
    }
  }

  onBackClicked = () => {
    const {
      match: { params },
    } = this.props
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { isEditViewing } = curQueries
    if (isEditViewing) {
      history.goBack()
    } else {
      history.push(urlTo('profilePreferences', { offerId: params.offerId }))
    }
  }

  onContinueClicked = () => {
    const { update } = this.props
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { isEditViewing } = curQueries
    if (isEditViewing) {
      history.goBack()
    } else {
      update(this.props.steps.Viewings)
    }
  }

  onTimePickerOnFocus = () => {
    this.setState({
      isActive: true,
    })
  }

  setInputValidationErrors = (field, error) => {
    let { inputErrors } = this.state
    if (error.length === 0) {
      delete inputErrors[field]
      this.setState({ inputErrors })
      let errors = this.state.errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      inputErrors[field] = error
      this.setState({ inputErrors })
      return false
    }
  }

  setValidationErrors = (field, error) => {
    let { errors } = this.state
    if (error.length === 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  activatePastTimes() {
    this.setState({ activeUpcomingTimes: false, activePastTimes: true })
  }

  activateUpcomingTimes() {
    this.setState({ activeUpcomingTimes: true, activePastTimes: false })
  }

  addViewing() {
    const {
      addViewing,
      updateViewing,
      match: { params },
      updatedViewing,
    } = this.props
    const {
      selectedDuration,
      published,
      notify,
      selectedDate,
      selectedStartTime,
    } = this.state
    const formattedStartDate = moment(selectedDate).format('ddd DD MMM YYYY')
    const isUpdate = !isEmptyObject(this.props.updatedViewing)
    const fullSelectedDate = `${formattedStartDate} ${selectedStartTime}`
    if (isUpdate) {
      updateViewing(
        params.offerId,
        this.props.updatedViewing.guidID,
        moment(fullSelectedDate).format('DD MMM YYYY hh:mm A'),
        selectedDuration,
        false,
        {
          published,
          notify,
        },
      )
    } else {
      addViewing(
        params.offerId,
        moment(fullSelectedDate, 'DD MMM YYYY hh:mm A'),
        selectedDuration,
        false,
        {
          published,
          notify,
        },
      )
    }
    this.setState({
      published: false,
      notify: false,
    })
  }

  confirmViewing = (name, offerId, guidOrViewing) => {
    if (window.confirm(`Are you sure you want to ${name} the viewing`)) {
      if (name === 'cancel') {
        this.props.cancelViewing(offerId, guidOrViewing)
      } else {
        this.fillTopForm(offerId, guidOrViewing)
      }
    }
  }

  fillTopForm = (offerId, viewing) => {
    this.props.editViewing(offerId, viewing)
    if (viewing && !isEmptyObject(viewing)) {
      this.setState({
        published: viewing.published,
        notify: viewing.notify,
      })
    }
    // scroll on the top of the page
    // so the customer can view the edited viewing
    ReactDOM.findDOMNode(this).scrollIntoView()
  }

  modifyViewingDuration = (time) => {
    const selectedStartTime = time.format('hh:mm A')
    const selectedEndTime =
      this.state.selectedDuration > 0
        ? moment(selectedStartTime, 'hh:mm A')
            .add(this.state.selectedDuration, 'minutes')
            .format('hh:mm A')
        : this.state.selectedEndTime
    this.setState({
      selectedStartTime: selectedStartTime,
      selectedEndTime: selectedEndTime,
    })
    return selectedEndTime
  }

  simulateOnBlurEvent = () => {
    $('.app-content').on('click', (e) => {
      if (
        !(
          $(e.target).find('.rdtBtn').length > 0 ||
          $(e.target).hasClass('rdtBtn') ||
          $(e.target).hasClass('rdtCount') ||
          $(e.target).hasClass('rdtCounterSeparator') ||
          $(e.target).parents('.rdtDays').length > 0 ||
          $(e.target).parents('.rdtMonths').length > 0 ||
          $(e.target).parents('.rdtYears').length > 0
        )
      ) {
        this.setState({
          isActive: false,
        })
      }
    })
  }

  toggle = (field) => {
    return (data) => {
      this.setState({
        [field]: data.value,
      })
    }
  }

  updateEndTime = (e) => {
    const selectedValue = parseInt(e.target.value, 10)
    const selectedEndTime = moment(
      this.state.selectedStartTime || this.state.curentTime,
      'hh:mm A',
    )
      .add(selectedValue, 'minutes')
      .format('hh:mm A')

    this.setState({ selectedDuration: selectedValue, selectedEndTime }, () => {
      this.validate('endTime', true)
    })
  }

  updateStartDate = (date) => {
    let { value, error } = date
    let { errors } = this.state
    if (error === '') {
      value =
        value !== '' ? moment(value).format(dateTimeHelpers.DATE_WITH_DASH) : ''
    }
    errors['startDate'] = error
    if (error === '') {
      delete errors['startDate']
    }
    this.setState(
      {
        selectedDate: value,
        errors,
      },
      () => {
        // this.validate('startDate', true)
      },
    )
  }

  updateStartTime = (time) => {
    const selectedEndTime = this.modifyViewingDuration(time)
    this.setState(
      {
        selectedStartTime: time.format('hh:mm A'),
        selectedEndTime,
        isActive: false,
      },
      () => {
        this.validate('startTime', true)
      },
    )
  }

  validate = (field, onBlur, value) => {
    const { selectedDate, selectedStartTime, selectedEndTime } = this.state

    switch (field) {
      case 'startDate':
        onBlur
          ? this.setValidationErrors(
              field,
              validateStartDate(
                selectedDate,
                'Start date must be present and must be today or later',
              ),
            )
          : this.setInputValidationErrors(
              field,
              validateStartDate(
                value,
                'Start start must be present and must be today or later',
              ),
            )
        break
      case 'startTime':
        onBlur
          ? this.setValidationErrors(
              field,
              validateStartTime(
                selectedDate,
                selectedStartTime,
                'Start time must be present and cannot be before End time',
              ),
            )
          : this.setInputValidationErrors(
              field,
              validateStartTime(
                selectedDate,
                value,
                'Start time must be present and cannot be before End time',
              ),
            )
        break
      case 'endTime':
        onBlur
          ? this.setValidationErrors(
              field,
              validateStartDate(selectedEndTime, 'End time must be present'),
            )
          : this.setInputValidationErrors(
              field,
              validateStartDate(value, 'End time must be present'),
            )
        break
      default:
        return null
    }

    return false
  }

  renderViewingDownload(viewing) {
    let address = ''
    let suburb = ''
    const { property } = this.props
    const hasPropertyAddress = property && property.address

    if (hasPropertyAddress) {
      if (property.address.unitNumber) {
        address = `${property.address.unitNumber}/${property.address.streetNumber} ${property.address.streetName}`
      } else {
        address = `${property.address.streetNumber} ${property.address.streetName}`
      }
      suburb = `${property.address.suburb} ${property.address.state} ${property.address.postcode}`
    }

    const dt = moment(viewing.startDate, 'YYYY-MM-DD HH:mm:ss')

    const startDate = viewing.startDate
    const endDate = dt
      .add(viewing.duration, 'minutes')
      .format('YYYY-MM-DD HH:mm:ss')

    const event = {
      title: `Viewing at ${address}`,
      description: [
        `Viewing at ${address} ${suburb}`,
        '',
        '\t○ Good luck with the viewing!',
        '',
        '\t○ Be the best you, complete your Snug profile',
        '',
        '\t○ Visit the help centre https://help.snug.com/hc for tips on being safe online',
        '',
        '\t○ Thanks for using Snug, please rate us online https://www.facebook.com/snugcom',
      ].join('\\n'),
      location: `${address} ${suburb}`,
      startTime: convertDateToUTCAndFormat(startDate),
      endTime: convertDateToUTCAndFormat(endDate),
    }

    return <DownloadIcs event={event} imageSrc={calendarDownload} />
  }

  render() {
    const {
      error,
      update,
      spinner,
      match: { params },
      updatedViewing,
    } = this.props
    const {
      errors,
      selectedDate,
      selectedStartTime,
      selectedEndTime,
      isActive,
      published,
      notify,
    } = this.state

    const isDisabled = !(
      selectedDate &&
      selectedStartTime &&
      selectedEndTime &&
      isEmptyObject(errors)
    )
    const formattedStartDate =
      selectedDate && errors.startDate !== dateTimeHelpers.INVALID_DATE_STRING
        ? moment(selectedDate).format(dateTimeHelpers.DATE_WITH_SLASH)
        : selectedDate
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { isEditViewing = false } = curQueries
    let { activeUpcomingTimes, activePastTimes } = this.state

    const viewingCreatedTagClass = 'manager-viewing-created'

    return (
      <div className="sm-inspections-book">
        {!isEditViewing && <Breadcrumbs activeBreadCrumb="viewings" />}
        <div className="mobile-only">
          <button
            className="btn btn-transparent btn-left xs-text-center wa"
            onClick={() =>
              history.push(
                urlTo('profilePreferences', { offerId: params.offerId }),
              )
            }
          >
            <i className="icon-arrow-left left"></i>
            <span>Back</span>
          </button>
        </div>
        <div className="section-title">
          <div className="left column">
            <h3>Viewing times</h3>
          </div>
        </div>
        <div className="two-col-box sm64">
          <div className="col-first">
            <div>
              <div className="input-date-time">
                <div className="add-viweing-container">
                  <div className="date">
                    <div className="font-size-18 mr5">Date</div>
                    <Form.InputFutureDateOneDayPast
                      onBlur={(value) => {
                        return this.validate('startDate', true, value)
                      }}
                      value={formattedStartDate}
                      inputProps={{ placeholder: this.state.currentDate }}
                      viewMode="days"
                      dateFormat="DD/MM/YYYY"
                      onChange={this.updateStartDate}
                      customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                      readOnly={false}
                    />
                  </div>

                  <div className="time mr5" tabIndex={0}>
                    <div className="font-size-18" tabIndex={0}>
                      Time
                    </div>
                    <TimePicker
                      value={selectedStartTime}
                      onBlur={(value) => {
                        this.setState({
                          isActive: false,
                        })
                        if (value !== '') {
                          this.updateStartTime(value)
                        }
                      }}
                      onFocus={this.onTimePickerOnFocus}
                      inputProps={{ placeholder: this.state.curentTime }}
                      viewMode="time"
                      onChange={this.modifyViewingDuration}
                      isActive={isActive}
                    />

                    <div className="pl10 pr10">-</div>
                    <div className="input-time">
                      <div className="input-box input-box mb0 pt0">
                        <select
                          className="end-time-select"
                          onBlur={(value) =>
                            this.validate('endTime', true, value)
                          }
                          value={selectedEndTime}
                          onChange={this.updateEndTime}
                        >
                          <option key={'endTimeOption'} value={selectedEndTime}>
                            {selectedEndTime}
                          </option>
                          {Object.keys(durations).map((key, index) => (
                            <option key={index} value={key}>
                              {durations[key]}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={isDisabled}
                    className={`btn btn-round btn-medium btn-add ${viewingCreatedTagClass}`}
                    onClick={() => this.addViewing()}
                  >
                    <i className="icon-add" />
                  </button>
                </div>
                <div>
                  <p className="gray-color mt10 mb0 white-space-pre-wrap font-size-16">
                    Add viewing times to enable renters to visit the property
                    and help you select the most suitable renter.
                    <br />
                    We'll send notifications automatically if the viewing
                    details change.
                    <br />
                    You can create a private viewing or publish an open home
                    viewing time.
                  </p>
                </div>
                <div className="viewing-options-container">
                  <Form.CheckBoxGeneral
                    label="Publish viewing time"
                    componentClassName="mt10 mr20"
                    checked={published}
                    onChange={this.toggle('published')}
                  />
                  <Form.CheckBoxGeneral
                    label="Notify interested parties"
                    componentClassName="mt10"
                    checked={notify}
                    onChange={this.toggle('notify')}
                  />
                </div>
              </div>
            </div>
            <div
              className={
                errors['startDate'] && isDisabled
                  ? 'alert alert-danger'
                  : 'hide-alert'
              }
            >
              <div>{errors['startDate']}</div>
            </div>

            <div
              className={
                errors['startTime'] && isDisabled
                  ? 'alert alert-danger'
                  : 'hide-alert'
              }
            >
              <div>{errors['startTime']}</div>
            </div>

            <div
              className={
                errors['endTime'] && isDisabled
                  ? 'alert alert-danger'
                  : 'hide-alert'
              }
            >
              <div>{errors['endTime']}</div>
            </div>

            <div className={error ? 'alert alert-danger' : 'hidden'}>
              {error}
            </div>

            <div className="times">
              <div className="tabs">
                <a
                  className={activeUpcomingTimes && 'active'}
                  onClick={() => this.activateUpcomingTimes()}
                >
                  Upcoming Times
                </a>
                <a
                  className={activePastTimes && 'active'}
                  onClick={() => this.activatePastTimes()}
                >
                  Past Times
                </a>
              </div>
              {spinner && <Spinner />}
              {!spinner && activeUpcomingTimes && (
                <table>
                  <tbody>
                    {filterViewings(this.props.viewings, false).map(
                      (viewing, index) => (
                        <tr key={index}>
                          <td colSpan="2">
                            {moment().day(viewing.nameOfDay).format('ddd')}{' '}
                            {viewing.listedDate}, {viewing.startTime} (
                            {`${viewing.duration} min ${
                              viewing.published ? '' : ', private'
                            }`}
                            )
                          </td>
                          {!viewing.cancelled && (
                            <td className="td-btn-mob">
                              <button
                                className="btn btn-red btn-small-xs btn-mob min-width-100"
                                onClick={() =>
                                  this.confirmViewing(
                                    'cancel',
                                    params.offerId,
                                    viewing.guidID,
                                  )
                                }
                              >
                                Cancel
                              </button>
                            </td>
                          )}
                          {!viewing.cancelled && (
                            <td className="td-btn-mob">
                              <button
                                className="btn btn-small-xs btn-mob min-width-100"
                                onClick={() =>
                                  this.confirmViewing(
                                    'edit',
                                    params.offerId,
                                    viewing,
                                  )
                                }
                              >
                                Edit
                              </button>
                            </td>
                          )}
                          {viewing.cancelled && (
                            <td colSpan="2" className=" text-center">
                              <span className="cancel-txt">Cancelled</span>
                            </td>
                          )}
                          <td>{this.renderViewingDownload(viewing)}</td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              )}
              {!spinner && activePastTimes && (
                <table>
                  <tbody>
                    {filterViewings(this.props.viewings, true).map(
                      (viewing, index) => (
                        <tr key={index}>
                          <td colSpan="2">
                            {moment().day(viewing.nameOfDay).format('ddd')}{' '}
                            {viewing.listedDate}, {viewing.startTime} (
                            {`${viewing.duration} min`})
                          </td>
                          <td>{/*placeholder*/}</td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <div className="row mt25 mobile-form-button">
              <div className="col-sm-5 pb5 tablet-only">
                <button
                  className="btn btn-transparent btn-left xs-text-center wa"
                  onClick={() => this.onBackClicked()}
                >
                  <i className="icon-arrow-left left"></i>
                  <span>Back</span>
                </button>
              </div>
              <div className="col-sm-7 pb5">
                <button
                  className="btn"
                  onClick={() => this.onContinueClicked()}
                >
                  {' '}
                  Continue{' '}
                </button>
              </div>
            </div>
          </div>
          <div className="col-second xs-pt30">
            <ul className="snug-tips">
              <li>
                Try to arrange viewings around the times of similar properties
                located nearby for the best attendance.
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default PropertyViewings
