import React from 'react'

import PropTypes from 'prop-types'

import DownloadAttach from 'app/match/applicationReportPDF/components/DownloadAttach'
import { isDocAutoDeleted } from 'app/sm/docs_helpers'
import * as helpers from 'app/sm/helpers'

export const tagPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
  PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({
        $$typeof: PropTypes.symbol,
        render: PropTypes.func,
      }),
    ]),
  ),
])

export const iconsCustom = [
  'icon-attach',
  'icon-bath-thub',
  'icon-bedroom',
  'icon-books',
  'icon-calendar',
  'icon-car-front',
  'icon-contacts',
  'icon-dog-leash',
  'icon-flag-points',
  'icon-geometry',
  'icon-home',
  'icon-i-touch-id',
  'icon-lock',
  'icon-income',
  'icon-money',
  'icon-new-construction',
  'icon-check',
  'icon-s-check',
  'icon-star',
  'icon-telegram',
  'icon-users',
  'icon-security-guard',
  'icon-note-code',
]

export const attachList = (obj = {}, isTitle = true) => {
  return Object.keys(obj).map((item, index) => {
    return (
      Array.isArray(obj[item]) && (
        <div className="attach-list-wrapper" key={index}>
          {isTitle && (
            <span className={'list-title'} key={index}>
              {item.replace(/_/g, ' ')}
            </span>
          )}

          <ul className="list-reset">
            {obj[item].map((item, index) => {
              return (
                <li key={index}>
                  {
                    <DownloadAttach
                      attachFileName={helpers.trimFileLength(30)(item.name)}
                      linkLabel={item.URL}
                      isAutoDeleted={isDocAutoDeleted(item)}
                    />
                  }
                </li>
              )
            })}
          </ul>
        </div>
      )
    )
  })
}

export const dateObj = (date) => {
  if (date === null) {
    return ''
  } else {
    const generateDateObj = new Date(date)

    const numToMonth = {
      1: 'Jan',
      2: 'Feb',
      3: 'Mar',
      4: 'Apr',
      5: 'May',
      6: 'Jun',
      7: 'Jul',
      8: 'Aug',
      9: 'Sep',
      10: 'Oct',
      11: 'Nov',
      12: 'Dec',
    }

    const zeroFormat = (item) => {
      if (item < 10) {
        return `0${item}`
      }
      return item
    }

    return {
      month: () => {
        return numToMonth[generateDateObj.getMonth() + 1]
      },
      year: () => generateDateObj.getFullYear(),
      day: () => zeroFormat(generateDateObj.getDate()),
      min: () => zeroFormat(generateDateObj.getMinutes()),
      hour: () => zeroFormat(generateDateObj.getHours()),
    }
  }
}

export const formatDateTime = (date) => {
  return `${dateObj(date).hour()}:${dateObj(date).min()} ${dateObj(
    date,
  ).day()} ${dateObj(date).month()} ${dateObj(date).year()}`
}
