import React, { useState } from 'react'

import { Field, Form, Formik } from 'formik'
import styled from 'styled-components'

import {
  AutocompleteField,
  TextArea,
} from 'app/components/design-system-components/inputs'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { debounce } from 'app/helpers/debounce'
import { searchProperty } from 'app/services/http/teams/properties'
import {
  moveApplicationFormFields,
  moveApplicationFormValidation,
} from 'app/sm/applications/components/move_application/form.utils'
import ModalFooter from 'app/sm/applications/components/move_application/ModalFooter'

const StyledFieldWrapper = styled(FieldWrapper)`
  margin-bottom: 25px;
`

const MoveApplicationFormBody = (props) => {
  const {
    teamGUID,
    toggleModal,
    formBag: { isSubmitting },
    property: applicationProperty,
  } = props

  const [propertiesOptions, setPropertiesOptions] = useState([])
  const [loadingProperties, setLoadingProperties] = useState(false)

  const onSearchProperties = debounce((searchText) => {
    setLoadingProperties(true)

    return searchProperty(teamGUID, searchText)
      .then((response) => {
        const filteredProperties = response?.properties?.data || []
        return filteredProperties
          .map(({ property }) => ({
            optionMeta: {
              id: property.guidID,
              displayText: property.address.friendlyName,
            },
            option: property,
          }))
          .filter(({ option }) => option?.guidID !== applicationProperty.guidID)
      })
      .then((filteredPropertyOptions) =>
        setPropertiesOptions(filteredPropertyOptions),
      )
      .finally(() => setLoadingProperties(false))
  })

  return (
    <Form>
      <p className="fw400 mb25 mt15">
        Move an application to another property. The applicants will be notified
        by email and you will be cc’d.
      </p>

      <StyledFieldWrapper
        id={moveApplicationFormFields.property}
        label="Property"
        required={true}
        name={moveApplicationFormFields.property}
      >
        <AutocompleteField
          name={moveApplicationFormFields.property}
          placeholder="Add property"
          options={propertiesOptions}
          onChangeText={onSearchProperties}
          isLoading={loadingProperties}
        />
      </StyledFieldWrapper>

      <StyledFieldWrapper
        id={moveApplicationFormFields.message}
        label="Message"
        name={moveApplicationFormFields.message}
      >
        <Field
          name={moveApplicationFormFields.message}
          as={TextArea}
          rows={5}
          placeholder="Type a message (optional)"
        />
      </StyledFieldWrapper>

      <ModalFooter toggleModal={toggleModal} moveDisabled={isSubmitting} />
    </Form>
  )
}

export default MoveApplicationFormBody
