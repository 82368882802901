import React from 'react'

import { recaptchaSiteKey } from 'config/env'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Alert } from 'app/components/design-system-components'
import TermsAndConditions from 'app/session/login_components/terms_and_conditions'
import * as validations from 'app/shared_components/validations'

const StyledRememberEmailLink = styled.div`
  text-align: right;
  line-height: 0;
  margin-bottom: 24px;
`

class ResetForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      errors: {},
      disabled: true,
      clicked: false,
      captcha: '',
    }
  }

  UNSAFE_componentWillMount() {
    let { clearError } = this.props
    clearError()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ clicked: false })
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    nextState.disabled = !(
      nextState.email && this.isEmptyObject(nextState.errors)
    )
  }

  setValidationErrors(field, error) {
    let errors = this.state.errors
    if (error.length === 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      document.getElementById(field).className = ''
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      document.getElementById(field).className = 'error'
      return false
    }
  }

  isEmptyObject(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false
      }
    }
    return true
  }

  submit = () => {
    let { forgotPassword } = this.props
    const { email, captcha: gCaptcha } = this.state
    forgotPassword({ email, gCaptcha })
    this.setState({
      disabled: true,
      clicked: true,
      submittedEmail: this.state.email,
    })
  }

  update(field) {
    return (event) => {
      let { value } = event.target
      value = value.toLowerCase()
      this.setState({ [field]: value })
    }
  }

  validate(field) {
    return () => {
      let value = this.state[field]
      switch (field) {
        case 'email':
          this.setValidationErrors(field, validations.validateEmail(value))
          break
        default:
          return false
      }
    }
  }

  updateRecaptcha = (response) => {
    this.setState({ captcha: response })
  }

  render() {
    let { email, disabled, clicked, submittedEmail, captcha } = this.state
    let { success, error } = this.props
    disabled = disabled || !captcha.length

    const renderSuccessMessage = () => {
      if (error || !success || !submittedEmail) {
        return null
      }

      const resetMessage = `We have sent a message to ${submittedEmail} if the account exists.`

      return (
        <Alert variant="blueWithBg" mb="40px">
          {resetMessage}
        </Alert>
      )
    }

    return (
      <div className="panel panel-default">
        <div className="panel-body">
          <p className="mb40">
            If you need help resetting your password, we can help by sending you
            a link to reset it on your email address.
          </p>
          {renderSuccessMessage()}
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="input-box">
            <input
              type="email"
              required
              id="email"
              value={email}
              onChange={this.update('email')}
              onBlur={this.validate('email')}
            />
            <label>Email Address</label>
          </div>
          <StyledRememberEmailLink>
            <Link className="fs14" to="/reset/mobile-notify">
              Can't remember your email?
            </Link>
          </StyledRememberEmailLink>
          <label className="mb10">Click below to confirm you're human.</label>
          <ReCAPTCHA
            ref="recaptcha"
            sitekey={recaptchaSiteKey}
            onChange={this.updateRecaptcha}
            onExpired={() => this.updateRecaptcha('')}
          />
          <button
            className="btn mt20 mb30"
            disabled={disabled}
            onClick={this.submit}
          >
            Reset password{' '}
            <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
          </button>

          <TermsAndConditions />
        </div>
      </div>
    )
  }
}

export default ResetForm
