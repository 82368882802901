import React from 'react'

import { Box, Modal } from 'app/components/design-system-components'

function AttachBGCheckModal({ primaryAction, toggleModal, secondaryAction }) {
  return (
    <Modal
      modalBody={
        <Box>
          You have not attached your Background Check to your profile. Attach it
          to include it in your application.
        </Box>
      }
      modalHeading="Attach your Background Check"
      secondaryLabel="Skip"
      toggleModal={toggleModal}
      secondaryAction={secondaryAction}
      primaryAction={primaryAction}
      primaryLabel="Attach"
    />
  )
}

export default AttachBGCheckModal
