export const actionTypes = {
  started: 'APP/STARTED',
  updateConnectionStatus: 'APP/UPDATE_CONNECTION_STATUS',
  connectionAlertDismissed: 'APP/DISMISS_CONNECTION_ALERT',
  startNewGlobalLoading: 'APP/NEW_GLOBAL_LOADING',
  endOneGlobalLoading: 'APP/END_ONE_GLOBAL_LOADING',
  checkConnection: 'APP/CHECK_CONNECTION',
  appVisibilityUpdate: 'APP/VISIBILITY_UPDATE',
  updateNewVersionToDownloadStatus: 'APP/UPDATE_NEW_VERSION_TO_DOWNLOAD_STATUS',
  updateNewVersionToActivateStatus: 'APP/UPDATE_NEW_VERSION_TO_ACTIVATE_STATUS',
  notifyNewServiceWorkerRegistered: 'APP/NOTIFY_NEW_VERSION_TO_ACTIVATE',
  checkNewVersion: 'APP/CHECK_NEW_VERSION',
  updateLastNewVersionMessageTime: 'APP/UPDATE_LAST_NEW_VERSION_MESSAGE_TIME',
  maintenanceMessageCheck: 'APP/MAINTENANCE_CHECK',
  maintenanceMessageUpdate: 'APP/MAINTENANCE_UPDATE',
  newActivityFeedCheck: 'APP/ACTIVITY_FEED_CHECK',
  hasNewActivityFeedUpdate: 'APP/ACTIVITY_FEED_UPDATE',
  hasDismissedTeamInactiveModal: 'APP/TEAM_INACTIVE_MODAL_DISMISS',
}

export const connectionStatuses = {
  online: 'online',
  offline: 'offline',
  becameOnline: 'becameOnline',
}

export const actions = {
  started: () => ({ type: actionTypes.started }),
  updateConnectionStatus: (status) => ({
    type: actionTypes.updateConnectionStatus,
    status,
  }),
  dismissAlert: () => ({
    type: actionTypes.connectionAlertDismissed,
  }),
  startNewGlobalLoading: () => ({ type: actionTypes.startNewGlobalLoading }),
  endOneGlobalLoading: () => ({ type: actionTypes.endOneGlobalLoading }),
  checkConnection: () => ({ type: actionTypes.checkConnection }),
  appVisibilityUpdate: (isVisible) => ({
    type: actionTypes.appVisibilityUpdate,
    isVisible,
  }),
  updateNewVersionToDownloadStatus: (showMessage) => ({
    type: actionTypes.updateNewVersionToDownloadStatus,
    showMessage,
  }),
  updateNewVersionToActivateStatus: (showMessage, waitingRegistration) => ({
    type: actionTypes.updateNewVersionToActivateStatus,
    showMessage,
    waitingRegistration,
  }),
  notifyNewServiceWorkerRegistered: (waitingRegistration) => ({
    type: actionTypes.notifyNewServiceWorkerRegistered,
    waitingRegistration,
  }),
  checkNewVersion: () => ({ type: actionTypes.checkNewVersion }),
  updateLastNewVersionMessageTime: (timestamp) => ({
    type: actionTypes.updateLastNewVersionMessageTime,
    timestamp,
  }),
  maintenanceMessageCheck: () => ({
    type: actionTypes.maintenanceMessageCheck,
  }),
  maintenanceMessageUpdate: (messageConfig) => ({
    type: actionTypes.maintenanceMessageUpdate,
    messageConfig,
  }),
  newActivityFeedCheck: () => ({
    type: actionTypes.newActivityFeedCheck,
  }),
  hasNewActivityFeedUpdate: (result) => ({
    type: actionTypes.hasNewActivityFeedUpdate,
    ...result,
  }),
  dismissTeamInactiveModal: () => ({
    type: actionTypes.hasDismissedTeamInactiveModal,
  }),
}

const defaultState = {
  connectionStatus: connectionStatuses.online,
  connectionAlertDismissed: false,
  lastOfflineTime: null,
  globalLoadingConnections: 0,
  appIsVisible: true,
  showNewVersionToDownload: false,
  showNewVersionToActivate: false,
  waitingRegistration: null,
  lastUpdateVersionMessageTime: null,
  maintenanceMessageConfig: null,
  hasNewActivityFeedConfig: null,
  hasDismissedInactiveTeamAlert: false,
}

export function core(state = defaultState, action) {
  const { type, ...payload } = action
  switch (type) {
    case actionTypes.updateConnectionStatus: {
      const { status } = payload

      const updateLastOfflineTimeObj =
        status === connectionStatuses.offline
          ? { lastOfflineTime: new Date().valueOf() }
          : {}

      return {
        ...state,
        ...updateLastOfflineTimeObj,
        connectionStatus: status,
        connectionAlertDismissed: false,
      }
    }

    case actionTypes.connectionAlertDismissed:
      return {
        ...state,
        connectionAlertDismissed: true,
      }

    case actionTypes.hasDismissedTeamInactiveModal:
      return {
        ...state,
        hasDismissedInactiveTeamAlert: true,
      }

    case actionTypes.startNewGlobalLoading:
      return {
        ...state,
        globalLoadingConnections: state.globalLoadingConnections + 1,
      }

    case actionTypes.endOneGlobalLoading:
      return {
        ...state,
        globalLoadingConnections: state.globalLoadingConnections - 1,
      }

    case actionTypes.appVisibilityUpdate: {
      const { isVisible } = payload
      return {
        ...state,
        appIsVisible: isVisible,
      }
    }

    case actionTypes.updateNewVersionToDownloadStatus: {
      const { showMessage } = payload
      return {
        ...state,
        showNewVersionToDownload: showMessage,
      }
    }

    case actionTypes.updateNewVersionToActivateStatus: {
      const { showMessage, waitingRegistration } = payload
      return {
        ...state,
        showNewVersionToActivate: showMessage,
        waitingRegistration: waitingRegistration,
      }
    }

    case actionTypes.updateLastNewVersionMessageTime: {
      const { timestamp } = payload
      return {
        ...state,
        lastUpdateVersionMessageTime: timestamp,
      }
    }

    case actionTypes.maintenanceMessageUpdate: {
      const { messageConfig } = payload
      return {
        ...state,
        maintenanceMessageConfig: messageConfig,
      }
    }

    case actionTypes.hasNewActivityFeedUpdate: {
      const { hasNewActivityFeed } = payload
      return {
        ...state,
        hasNewActivityFeedConfig: {
          hasNewActivityFeed,
          timestamp: new Date().getTime(),
        },
      }
    }

    default:
      return state
  }
}

export const connectionStatusChange = (isOnline, prevState) => {
  if (prevState === connectionStatuses.offline && isOnline) {
    return { changed: true, connectionStatus: connectionStatuses.becameOnline }
  }

  if (
    (prevState === connectionStatuses.online ||
      prevState === connectionStatuses.becameOnline) &&
    !isOnline
  ) {
    return {
      changed: true,
      connectionStatus: connectionStatuses.offline,
    }
  }

  return {
    changed: false,
  }
}
