import React from 'react'

import { Link } from 'react-router-dom'

// TODO: Close modal when clicked outside the wrapper on the overlay
const DashboardModal = ({ toggleModal }) => (
  <div className="modal-fullscreen">
    <div className="modal-wrapper">
      <div className="modal-close">
        <i className="fa fa-times" onClick={() => toggleModal()} />
      </div>
      <div className="modal-header">
        <h4>Apply for BondCover</h4>
      </div>
      <div className="modal-body">
        <Link className="btn mt10" to="/bc?type=new">
          + New Lease
        </Link>
        <Link className="btn mt10" to="/bc?type=refund">
          + Refund
        </Link>
      </div>
    </div>
  </div>
)

export default DashboardModal
