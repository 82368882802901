import { connect } from 'react-redux'

import { sendBondCoverReferral } from 'app/dashboard/dashboard_actions'
import BCQuote from 'app/landing/bondcover_quote'

const mapStateToProps = ({ session, dashboard }) => ({
  currentUser: session.currentUser,
  apiError: dashboard.error,
})

const mapDispatchToProps = (dispatch) => ({
  sendBondCoverReferral: (
    inviteType,
    inviterName,
    inviterEmail,
    inviteeEmail,
    sendSuccess,
    sendFail,
  ) =>
    dispatch(
      sendBondCoverReferral(
        inviteType,
        inviterName,
        inviterEmail,
        inviteeEmail,
        sendSuccess,
        sendFail,
      ),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(BCQuote)
