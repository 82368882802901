import ErrorMessages from 'app/constants/error_messages'
import {
  ADD_FAVOURITE_FLAG,
  CLEAR_ERROR,
  ON_SORTER_DROPDOWN_CHANGE,
  PING,
  RECEIVE_ERROR,
  RECEIVE_RESPONSE_TEXT,
  RECEIVE_VIEWINGS,
  REMOVE_FAVOURITE_FLAG,
  TOGGLE_SPINNER,
} from 'app/sm/viewings/viewings_actions'

const _defaultState = {
  viewings: [],
  spinner: false,
  sortBy: 'time',
  error: '',
  responseText: '',
}

function parseResponseText(responseText) {
  return responseText ? responseText.replace(/\n/, '') : ''
}

const Viewings = (state = _defaultState, action) => {
  let { type, viewings, error, responseText, registerViewing, sorter } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case PING:
      return Object.assign(newState, { error: '', responseText: '' })
    case RECEIVE_VIEWINGS:
      return Object.assign(newState, { viewings })
    case ON_SORTER_DROPDOWN_CHANGE:
      return Object.assign(newState, { sortBy: sorter })
    case ADD_FAVOURITE_FLAG:
    case REMOVE_FAVOURITE_FLAG:
      const updatedViewingIndex = state.viewings.findIndex(
        (v) =>
          v.registerViewing.guidID === registerViewing.guidID &&
          v.registerViewing.viewingID === registerViewing.viewingID,
      )

      const updatedViewing = Object.assign(
        {},
        state.viewings[updatedViewingIndex],
        { registerViewing },
      )

      return Object.assign(newState, {
        viewings: [
          ...state.viewings.slice(0, updatedViewingIndex),
          updatedViewing,
          ...state.viewings.slice(updatedViewingIndex + 1),
        ],
      })
    case TOGGLE_SPINNER:
      return Object.assign(newState, { spinner: !newState.spinner })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case RECEIVE_RESPONSE_TEXT:
      const errorCode = parseInt(parseResponseText(responseText))
      const errorMessage = ErrorMessages[errorCode]
      return Object.assign(newState, {
        responseText: errorCode,
        error: errorMessage,
      })
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    default:
      return newState
  }
}

export default Viewings
