import React from 'react'

import { Link } from 'react-router-dom'

import Messages from 'app/sm/chat/components/timeline/messages'
import Write from 'app/sm/chat/components/timeline/write'

function Chat() {
  return (
    <div className="sm-shadow-card chat">
      <div className="header xs-mb20">
        <h4>Quick Chat</h4>
      </div>
      <div className="content">
        <div className="inner sm-chat">
          <Messages />
        </div>
        <div className="inner sm-chat bts">
          <Write />
        </div>
      </div>
    </div>
  )
}

export default Chat
