import React from 'react'

import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'

const StyledRow = styled(Box)`
  ${({ textColor }) =>
    textColor &&
    `
    color: ${textColor};
  `};
  ${({ textSize }) =>
    textSize &&
    `
    font-size: ${textSize}px;
  `};
  ${({ textFontWeight }) =>
    textFontWeight &&
    `
    font-weight: ${textFontWeight};
  `};
  ${({ textLineHeight }) =>
    textLineHeight &&
    `
    line-height: ${textLineHeight};
  `};
`

function DisplayText({ dataDisplayedAndStyle = [] }) {
  return (
    <>
      {dataDisplayedAndStyle.map((data) => {
        return (
          <StyledRow
            textColor={data?.textColor}
            textSize={data?.textSize}
            textFontWeight={data?.textFontWeight}
            textLineHeight={data?.textLineHeight}
          >
            {data?.text}
          </StyledRow>
        )
      })}
    </>
  )
}

export default DisplayText
