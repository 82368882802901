import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'

import { connect } from 'react-redux'

import {
  Box,
  Button,
  Flex,
  LoadingSection,
} from 'app/components/design-system-components'
import { SearchRounded } from 'app/components/design-system-components/icons/actions'
import { Input } from 'app/components/design-system-components/inputs'
import Pagination from 'app/components/design-system-components/Pagination'
import { Text } from 'app/components/design-system-components/typography'
import * as actFilters from 'app/dashboard/team_activity/ActivityFilters'
import { activityPeriods } from 'app/dashboard/team_activity/ActivityFilters/DateRangeFilter/constants'
import { BackgroundChecksTable } from 'app/dashboard/team_activity/BackgroundChecksReporting/BackgroundChecksTable'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { listBackgroundChecks } from 'app/services/http/backgroundCheck/reporting'
import { PaidPlanActivationAlert } from 'app/shared_components/backgroundCheck/PaidPlanActivationAlert'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import {
  filterReducerFactory,
  filtersActions,
} from 'app/utils/reducers/filtersReducer'
import { backgroundCheckLearnMore } from 'config/external-links'

const PAGINATION_LIMIT = 8

const FiltersAndSearch = ({
  filtersState,
  filtersDispatch,
  currentTeam,
  onFiltersInitialized,
}) => {
  const [searchTextState, setSearchTextState] = useState('')

  const debouncedOnSearchTextChange = useCallback(
    debounce(
      (value) => filtersDispatch(filtersActions.updateSearch(value)),
      STANDARD_TIMEOUT,
    ),
    [],
  )

  const onSearchTextChange = ({ target: { value } }) => {
    setSearchTextState(value)
    debouncedOnSearchTextChange(value)
  }

  const hasFiltersApplied = actFilters.hasAppliedFilters(filtersState)
  const filterByTextElem = (
    <Text
      as="span"
      fontWeight={theme.fontWeights[6]}
      fontSize={theme.fontSizes.pSmall12}
      color={theme.colors.gray600}
      mr={4}
    >
      FILTER BY:{' '}
    </Text>
  )

  const filtersSortActionsElem = (
    <Button
      ml={2}
      height="40px"
      variant="outline"
      onClick={() => {
        setSearchTextState('')
        filtersDispatch(filtersActions.resetAllFilters())
      }}
    >
      Clear
    </Button>
  )

  const filtersInputs = (
    <Box ml={2}>
      <actFilters.ActivitiesFilters
        teamId={currentTeam.guid}
        filters={filtersState.filters}
        initialPeriod={activityPeriods.currentMonth}
        updateFilter={(filterId, filterValues) =>
          filtersDispatch(filtersActions.updateFilter(filterId, filterValues))
        }
        onFiltersInitialized={onFiltersInitialized}
      />
    </Box>
  )
  return (
    <Flex>
      <Flex width="100%">
        <Input
          icon={
            <SearchRounded
              style={{
                color: theme.colors.gray400,
              }}
            />
          }
          inputProps={{
            placeholder: 'Search name, address, report ID',
            value: searchTextState,
            onChange: onSearchTextChange,
            height: '20px',
            fontSize: theme.fontSizes.pSmall12,
          }}
          maxWidth="230px"
          height="40px"
        />
        {filtersInputs}
        {/*{(hasFiltersApplied || !!searchTextState) && filtersSortActionsElem}*/}
      </Flex>
    </Flex>
  )
}

const BackgroundChecksList = ({ currentTeam, isEnabled }) => {
  const {
    state: backgroundChecks,
    setState: setBackgroundChecks,
    loadingStates: setBGCsLoadingStates,
    loadingStatesHelpers: setBGCsLoadingStatesHelpers,
  } = useStateWithLoading([])

  const [filtersState, filtersDispatch] = useReducer(
    filterReducerFactory(actFilters.filtersInitialState),
    actFilters.filtersInitialState,
  )

  const [filtersInitializationReady, setFiltersInitializationReady] =
    useState(false)

  const [paginationOffset, setPaginationOffset] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [totalCost, setTotalCost] = useState(0)
  const [totalPriceIncGST, setTotalPriceIncGST] = useState(0)
  const [totalServiceFeeExGST, setTotalServiceFeeExGST] = useState(0)

  useEffect(() => {
    setPaginationOffset(0)
  }, [filtersState])

  useEffect(() => {
    if (filtersInitializationReady) {
      loadBackgroundChecks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, filtersState])

  const loadBackgroundChecks = () => {
    setBGCsLoadingStatesHelpers.startLoading()
    const { range = {} } = actFilters.getFilters(filtersState)
    return listBackgroundChecks(currentTeam.guid, {
      offset: paginationOffset,
      limit: PAGINATION_LIMIT,
      ...range,
      q: filtersState.search,
    })
      .then(
        ({
          items,
          count,
          totalCost,
          totalPriceIncGST,
          totalServiceFeeExGST,
        }) => {
          setBackgroundChecks(items)
          setTotalCount(count)
          setTotalCost(totalCost)
          setTotalPriceIncGST(totalPriceIncGST)
          setTotalServiceFeeExGST(totalServiceFeeExGST)
          setBGCsLoadingStatesHelpers.markDoneSuccessfully()
        },
      )
      .catch((err) => setBGCsLoadingStatesHelpers.setError(err))
  }

  const onChangePage = (selectedPage) => {
    setPaginationOffset(selectedPage * PAGINATION_LIMIT)
  }

  const searchAndFiltersElem = (
    <FiltersAndSearch
      currentTeam={currentTeam}
      filtersState={filtersState}
      filtersDispatch={filtersDispatch}
      onFiltersInitialized={() => setFiltersInitializationReady(true)}
    />
  )

  const totalCostElem = (cost, totalServiceFeeExGST, totalPriceIncGST) => (
    <Flex
      py={3}
      px={5}
      borderRadius={2}
      color={theme.colors.gray600}
      fontSize={5}
      fontWeight={theme.fontWeights[6]}
      lineHeight={1.4}
      backgroundColor={theme.colors.paleBlue}
      variant="blackWithBlueBg"
    >
      Total<Box ml={5}>${cost}</Box>
      <Box ml={5}>${totalServiceFeeExGST}</Box>
      <Box ml={5}>${totalPriceIncGST}</Box>
    </Flex>
  )

  const emptyStateMessage = (
    <>
      <Box>No results</Box>
      <Box mt={8}>
        Background Checks can reduce risk and save time,{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={backgroundCheckLearnMore}
        >
          learn more
        </a>
        .
      </Box>
    </>
  )

  const currentPage = Math.floor(paginationOffset / PAGINATION_LIMIT) + 1
  const pagesCount = Math.ceil(totalCount / PAGINATION_LIMIT)
  const paginationElem =
    pagesCount > 1 ? (
      <Pagination
        canNextPage={pagesCount > currentPage}
        canPreviousPage={currentPage > 1}
        currentPageNum={currentPage}
        maxPageNumbers={pagesCount}
        onPaginationButtonClicked={onChangePage}
      />
    ) : null

  const memoizedTable = useMemo(
    () => <BackgroundChecksTable bgChecksData={backgroundChecks} />,
    [backgroundChecks],
  )

  if (!isEnabled) {
    return <PaidPlanActivationAlert width="450px" margin="auto" />
  }

  return (
    <div>
      <Flex justifyContent="space-between" alignItems="center" mb={5}>
        <Flex flexDirection="column" flex="1">
          {searchAndFiltersElem}
        </Flex>
        <Box mb={4}>
          {totalCostElem(totalCost, totalServiceFeeExGST, totalPriceIncGST)}
        </Box>
      </Flex>

      <LoadingSection
        loadingState={setBGCsLoadingStates}
        actionHandler={loadBackgroundChecks}
        loaderProps={{ fontSize: '48px' }}
      >
        {memoizedTable}
        {!backgroundChecks?.length && (
          <Box textAlign="center" lineHeight={1.4} m="auto" mt={8} width="30%">
            {emptyStateMessage}
          </Box>
        )}
      </LoadingSection>

      {paginationElem}
    </div>
  )
}

const mapStateToProps = ({ session }) => {
  return {
    currentTeam: session.currentTeam,
    currentAgency: session.currentAgency,
  }
}

export const BackgroundChecksListPage =
  connect(mapStateToProps)(BackgroundChecksList)
