import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import plusIconUrl from 'app/assets/icons/add_white_24px.svg'
import formIconUrl from 'app/assets/icons/form.svg'
import messageCheckIconUrl from 'app/assets/icons/message-check.svg'
import searchIconUrl from 'app/assets/icons/search.svg'
import {
  Box,
  BoxInput,
  Button,
  CollapsableSection,
  Flex,
  HorizontalLine,
  IconBox,
  LoadingSection,
  TextSpan,
} from 'app/components/design-system-components'
import { Modal, ModalButtonGroup } from 'app/components/display/display'
import * as Display from 'app/components/display/display'
import { connectionStatuses } from 'app/core/coreSlice'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import * as attendeesData from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingDetailsAttendeesTab/attendees-data'
import { orderEnquirers } from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingDetailsAttendeesTab/order-attendees'
import { actionCreators } from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingDetailsAttendeesTab/viewingAttendeesTabSlice'
import ViewingRegistrantCard from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingRegistrantCard'
import { searchAgencyContact } from 'app/services/http/agency'
import { checkInEnquirer } from 'app/services/http/enquirers'
import {
  updateCheckInForRegistrant,
  updateInterestForRegistrant,
  updateViewingRegistrant,
} from 'app/services/http/onsiteRegistrants'
import { getRecentEnquiries } from 'app/services/http/properties'
import {
  getViewingRegistrants,
  sendApplyInvitationForRegistrant,
} from 'app/services/http/viewings'
import * as snugNotifier from 'app/services/snugNotifier'
import * as helpers from 'app/sm/helpers'
import WalkinRenterModal from 'app/sm/inspections/components/walkin_renter_modal'
import { deleteOnsiteViewingRenter } from 'app/sm/inspections/inspections_access'
import { checkIfNetworkError } from 'app/utils/api/helpers'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  loadingStatesIds,
} from 'app/utils/loading-states'

const CollapsableSectionTitleText = styled(TextSpan)`
  font-weight: ${({ theme }) => theme.fontWeights[6]};
  font-size: ${({ theme }) => theme.fontSizes[7] + 'px'};
  color: ${({ theme }) => theme.colors.gray700};
  line-height: 21px;
`

export function CollapsableSectionTitle({ iconUrl, title }) {
  return (
    <Flex alignItems="center">
      {!!iconUrl && (
        <IconBox mr="8px">
          <img alt="" src={iconUrl} />
        </IconBox>
      )}
      <CollapsableSectionTitleText>{title}</CollapsableSectionTitleText>
    </Flex>
  )
}

const registeredSectionTitle = (
  <CollapsableSectionTitle iconUrl={formIconUrl} title="Registered" />
)

const enquiredSectionTitle = (
  <CollapsableSectionTitle iconUrl={messageCheckIconUrl} title="Enquired" />
)

const enabledOptionsForAttendeeModal = {
  enableContactInfomation: true,
  enableCheckedInAndInterested: true,
  enablePreference: true,
  enableNote: true,
  enableViewingBottomButtons: true,
}

const RegistrantsErrorComponent = ({ error, actionHandler }) => {
  const isConnectionError = checkIfNetworkError(error)
  if (isConnectionError) {
    return (
      <Display.NoticeMessageContainer text="Add attendees to synchronise once online" />
    )
  } else {
    const actionHandlerWrapper = (event) => {
      event.preventDefault()
      actionHandler()
    }
    const actionElem = (
      <a onClick={actionHandlerWrapper} href="/#">
        Please Try again
      </a>
    )
    const errorBody = (
      <span>
        Something went wrong! {error.message} {actionElem}
      </span>
    )
    return <Display.ErrorMessageContainer error={errorBody} />
  }
}

export class PureViewingDetailsAttendeesTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      enableSearch: props.isOnline,
      registrantsLoadingStates: createInitialLoadingState(),
      enquirersLoadingStates: createInitialLoadingState(),
      enquirers: [],
      isAddAttendeeModalOpen: false,
      isEditAttendeeModalOpen: false,
      editAttendeeModalLoadingStates: createInitialLoadingState(),
      toEditRegistrant: null,
      showAttendeeWithoutSlotModal: false,
      contacts: [],
      contactsLoadingStates: createInitialLoadingState(),
    }

    this.registrantsLoadingStateUtils = createLoadingStateUtils((state) =>
      this.setState({
        registrantsLoadingStates: state,
      }),
    )

    this.enquirersLoadingStateUtils = createLoadingStateUtils((state) =>
      this.setState({
        enquirersLoadingStates: state,
      }),
    )

    this.editAttendeeModalLoadingStateUtils = createLoadingStateUtils((state) =>
      this.setState({
        editAttendeeModalLoadingStates: state,
      }),
    )

    this.contactsLoadingStateUtils = createLoadingStateUtils((state) =>
      this.setState({ contactsLoadingStates: state }),
    )

    const { viewing, setViewingId } = props
    setViewingId(viewing.GUID)
  }

  componentDidMount() {
    this.fetchRegistrantsEnquirers()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOnline, searchContactQ } = this.props
    const { isOnline: prevIsOnline, searchContactQ: prevSearchQ } = prevProps
    /*
     * reload registrants & enquirers when search query updated
     * */
    if (prevSearchQ !== searchContactQ) {
      if (searchContactQ === '') this.fetchRegistrantsEnquirers()
      else
        Promise.all([this.fetchRegistrants(), this.searchContacts()]).then(() =>
          this.filterOutRegisteredEnquirers(),
        )
    }

    /*
     * take care of searchBox in case of !isOnline
     * */
    if (isOnline !== prevIsOnline) {
      this.updateSearchInConnectionStatus()
    }
  }

  fetchRegistrantsEnquirers = (withLoader = true) =>
    Promise.all([
      this.fetchRegistrants(withLoader),
      this.fetchEnquirers(withLoader),
    ]).then(() => this.filterOutRegisteredEnquirers())

  searchContacts = () => {
    const {
      searchContactQ,
      currentTeam: { guid: teamId },
    } = this.props
    if (searchContactQ === '') return Promise.resolve(null)
    this.contactsLoadingStateUtils.startLoading()
    return searchAgencyContact(teamId, searchContactQ)
      .then((contacts = []) => {
        const mappedEnquirers = contacts.map(attendeesData.mapEnquirer)
        this.setState({
          contacts: mappedEnquirers?.sort(
            helpers.sortArrayAlphabeticallyByFirstName,
          ),
        })
        this.contactsLoadingStateUtils.markDoneSuccessfully()
      })
      .catch((err) => this.contactsLoadingStateUtils.setError(err))
  }

  updateSearchInConnectionStatus() {
    const { isOnline, updateRegistrantSearchQ } = this.props
    this.updateEnableSearchState(isOnline)
    if (!isOnline) {
      updateRegistrantSearchQ('')
    }
  }

  updateEnableSearchState = (isOnline) => {
    return this.setState({
      enableSearch: isOnline,
    })
  }

  onCheckInBtnClicked = (registrant) => {
    const updatedCheckInVal = !registrant.checkIn
    return updateCheckInForRegistrant(registrant, updatedCheckInVal)
      .then((updatedRegistrant) => this.onRegistrantUpdated(updatedRegistrant))
      .then((registrant) => {
        if (
          registrant.checkIn &&
          !registrant.appliedAt &&
          !registrant.sentApplyNotificationDate
        ) {
          return this.sendApplyInvitation(registrant)
        }
      })
      .catch((err) => {
        snugNotifier.error(
          `Failed to update ${registrant.fullName} data! ${err.message}`,
        )
        return Promise.reject(err)
      })
  }

  onCheckInEnquirer(enquirer) {
    const { viewing } = this.props
    return checkInEnquirer(enquirer, viewing.GUID)
      .then((createdRegistrant) => {
        this.onEnquirerCheckedIn(enquirer, createdRegistrant)
        return createdRegistrant
      })
      .then((registrant) => this.sendApplyInvitation(registrant))
      .catch((err) => {
        snugNotifier.error(
          `Failed to update ${enquirer.fullName} data! ${err.message}`,
        )
        return Promise.reject(err)
      })
  }

  onEnquirerCheckedIn(enquirer, createdRegistrant) {
    const { addSyncedRegistrant } = this.props
    const { enquirers, contacts } = this.state
    const mappedCreatedRegistrant =
      attendeesData.mapRegistrant(createdRegistrant)
    const updatedEnquirersIndex = enquirers.findIndex(
      ({ uniqueKey }) => uniqueKey === mappedCreatedRegistrant.uniqueKey,
    )
    const updatedContactIdx = contacts.findIndex(
      ({ uniqueKey }) => uniqueKey === mappedCreatedRegistrant.uniqueKey,
    )
    if (updatedEnquirersIndex !== -1) {
      enquirers.splice(updatedEnquirersIndex, 1)
      this.setState(
        {
          enquirers: [...enquirers],
        },
        this.pushOnChanges.bind(this),
      )
    }
    if (updatedContactIdx !== -1) {
      contacts.splice(updatedContactIdx, 1)
      this.setState(
        {
          contacts,
        },
        this.pushOnChanges.bind(this),
      )
    }
    addSyncedRegistrant(createdRegistrant)
  }

  onInterestedBtnClicked(registrant) {
    const updateInterestedVal = !registrant.interested
    return updateInterestForRegistrant(registrant, updateInterestedVal)
      .then((updatedRegistrant) => this.onRegistrantUpdated(updatedRegistrant))
      .catch((err) => {
        snugNotifier.error(
          `Failed to update ${registrant.fullName} data! ${err.message}`,
        )
        return Promise.reject(err)
      })
  }

  onRegistrantUpdated(updatedRegistrant) {
    const { updateSyncedRegistrant } = this.props
    updateSyncedRegistrant(updatedRegistrant)
    this.pushOnChanges()
    return updatedRegistrant
  }

  closeAddAttendeeModal() {
    const {
      updateIsAddRegistrantModalOpened,
      updateRegistrantSearchText,
      updateRegistrantSearchQ,
      showViewingAttendeesModalHeading,
    } = this.props
    if (showViewingAttendeesModalHeading) {
      showViewingAttendeesModalHeading()
    }
    updateIsAddRegistrantModalOpened(false)
    updateRegistrantSearchQ('')
    updateRegistrantSearchText('')
  }

  closeEditAttendeeModal() {
    const { showViewingAttendeesModalHeading } = this.props
    if (showViewingAttendeesModalHeading) {
      showViewingAttendeesModalHeading()
    }
    this.setState({
      isEditAttendeeModalOpen: false,
    })
  }

  createRegistrant(registrantPayload, sendInvite) {
    const { addRegistrant, showViewingAttendeesModalHeading } = this.props
    const viewingId = this.props.viewing.GUID
    const mappedPayload = attendeesData.mapRegistrantPayload(
      registrantPayload,
      !!sendInvite,
    )
    addRegistrant(mappedPayload, viewingId)
    if (showViewingAttendeesModalHeading) {
      showViewingAttendeesModalHeading()
    }
  }

  editRegistrant(registrantPayload, registrantId) {
    const { viewing, showViewingAttendeesModalHeading } = this.props
    const mappedPayload = attendeesData.mapRegistrantPayload(registrantPayload)
    this.editAttendeeModalLoadingStateUtils.startLoading()
    return updateViewingRegistrant(viewing.GUID, registrantId, mappedPayload)
      .then((updatedRegistrant) => {
        if (showViewingAttendeesModalHeading) {
          showViewingAttendeesModalHeading()
        }
        this.setState({
          isEditAttendeeModalOpen: false,
          toEditRegistrant: null,
        })
        this.editAttendeeModalLoadingStateUtils.markDoneSuccessfully()
        snugNotifier.success(
          `${updatedRegistrant.fullName} info has been updated successfully`,
        )
        this.fetchRegistrants().then(() => this.pushOnChanges())
        return updatedRegistrant
      })
      .catch((err) => {
        snugNotifier.error(err.message)
        this.editAttendeeModalLoadingStateUtils.setError(err)
      })
  }

  removeOnsiteRegistrant = (registrantId) => {
    const { viewing, showViewingAttendeesModalHeading } = this.props
    const confirmation = window.confirm(
      'This can not be undone. Would you like to continue?',
    )
    if (!confirmation) {
      return
    }
    this.editAttendeeModalLoadingStateUtils.startLoading()
    return deleteOnsiteViewingRenter(viewing.GUID, registrantId)
      .then(() => {
        if (showViewingAttendeesModalHeading) {
          showViewingAttendeesModalHeading()
        }
        this.setState({
          isEditAttendeeModalOpen: false,
          toEditRegistrant: null,
        })
        this.editAttendeeModalLoadingStateUtils.markDoneSuccessfully()
        this.fetchRegistrants().then(() => this.pushOnChanges())
      })
      .catch((err) => {
        snugNotifier.error(err.message)
      })
  }

  fetchEnquirers(withloader = true) {
    const { currentTeam, propertyId, searchContactQ } = this.props
    if (withloader) {
      this.enquirersLoadingStateUtils.startLoading()
    }
    return getRecentEnquiries(currentTeam.guid, propertyId, searchContactQ)
      .then((enquirers) => {
        const sortedEnquirers = orderEnquirers(enquirers)
        const mappedEnquirers = sortedEnquirers.map(attendeesData.mapEnquirer)
        this.setState({
          enquirers: mappedEnquirers,
        })
        if (withloader) {
          this.enquirersLoadingStateUtils.markDoneSuccessfully()
        }
      })
      .catch((err) => this.enquirersLoadingStateUtils.setError(err))
  }

  fetchRegistrants(withloader = true) {
    const { currentTeam, viewing, syncedRegistrantsLoaded, searchContactQ } =
      this.props

    if (withloader) {
      this.registrantsLoadingStateUtils.startLoading()
    }
    return getViewingRegistrants(currentTeam.guid, viewing.GUID, searchContactQ)
      .then((registrants) => {
        syncedRegistrantsLoaded(registrants)
        if (withloader) {
          this.registrantsLoadingStateUtils.markDoneSuccessfully()
        }
      })
      .catch((err) => this.registrantsLoadingStateUtils.setError(err))
  }

  filterOutRegisteredEnquirers() {
    // todo: remove this logic after moving filtering not registered enquirers to BE
    const { enquirers, contacts } = this.state
    const { registrants } = this.props
    const registrantsUniqueKeys = registrants.map(attendeesData.mapToUniqueKey)
    const notRegisteredEnquirers = enquirers.filter(
      ({ uniqueKey }) => registrantsUniqueKeys.indexOf(uniqueKey) === -1,
    )
    const notRegisteredContacts = contacts.filter(
      ({ uniqueKey }) => registrantsUniqueKeys.indexOf(uniqueKey) === -1,
    )
    this.setState({
      enquirers: [...notRegisteredEnquirers],
      contacts: notRegisteredContacts,
    })
  }

  debouncedSearch = debounce((search) => {
    const { updateRegistrantSearchQ } = this.props
    updateRegistrantSearchQ(search)
  }, STANDARD_TIMEOUT)

  handleSearchContactInputChange(search) {
    const { updateRegistrantSearchText } = this.props

    updateRegistrantSearchText(search)
    this.debouncedSearch(search)
  }

  openAddAttendeeModal(initialRegistrant) {
    const {
      updateIsAddRegistrantModalOpened,
      updateRegistrantSearchText,
      searchContactQ,
      hideViewingAttendeesModalHeading,
    } = this.props
    if (hideViewingAttendeesModalHeading) {
      hideViewingAttendeesModalHeading()
    }
    updateIsAddRegistrantModalOpened(true)
    updateRegistrantSearchText(searchContactQ)
    this.setState({ populateRegistrant: initialRegistrant })
  }

  openEditAttendeeModal(toEditRegistrant) {
    const { hideViewingAttendeesModalHeading } = this.props
    this.editAttendeeModalLoadingStateUtils.reset()
    if (hideViewingAttendeesModalHeading) {
      hideViewingAttendeesModalHeading()
    }
    this.setState({
      isEditAttendeeModalOpen: true,
      toEditRegistrant,
    })
  }

  toggleAttendeeWithoutSlotModal = () => {
    const { showAttendeeWithoutSlotModal } = this.state
    const {
      hideViewingAttendeesModalHeading,
      showViewingAttendeesModalHeading,
    } = this.props

    if (showAttendeeWithoutSlotModal && showViewingAttendeesModalHeading) {
      showViewingAttendeesModalHeading()
    } else if (
      !showAttendeeWithoutSlotModal &&
      hideViewingAttendeesModalHeading
    ) {
      hideViewingAttendeesModalHeading()
    }
    this.setState({
      showAttendeeWithoutSlotModal: !showAttendeeWithoutSlotModal,
    })
  }

  pushOnChanges() {
    const { onChanges, registrants } = this.props
    onChanges({
      registrants,
    })
  }

  sendApplyInvitation(registrant) {
    // todo: remove this when these follows are handled in BE
    // we shouldn't handle this in FE

    const viewingId = this.props.viewing.GUID
    return sendApplyInvitationForRegistrant(viewingId, registrant.guidID).catch(
      (err) =>
        snugNotifier.error(
          `Something went wrong! Couldn't send invitation for ${registrant.fullName}`,
        ),
    )
  }

  renderAddAttendeeModal() {
    const {
      isAddRegistrantModalOpened: isAddAttendeeModalOpen,
      addRegistrantLoadingStates: attendeeModalLoadingStates,
      registrantSearch = '',
      currentTeam: { guid: agencyGUID, slug: teamSlug = '' },
    } = this.props
    const { populateRegistrant } = this.state
    if (!isAddAttendeeModalOpen) {
      return null
    }
    let searchHasDigits = /^\d+$/.test(registrantSearch)
    let addAttendeePreloadContent = { mobileSearchText: '', nameSearchText: '' }
    if (searchHasDigits) {
      addAttendeePreloadContent = { mobileSearchText: registrantSearch }
    } else {
      addAttendeePreloadContent = { nameSearchText: registrantSearch }
    }
    return (
      <WalkinRenterModal
        toggleWalkInModal={() => this.closeAddAttendeeModal()}
        walkinModalStatus="create"
        addWalkInRenter={(...params) =>
          this.createRegistrant(params[0], params[3])
        }
        enableOptions={enabledOptionsForAttendeeModal}
        disableSaveButton={
          attendeeModalLoadingStates.state === loadingStatesIds.LOADING
        }
        addAttendeePreloadContent={addAttendeePreloadContent}
        renterInfo={populateRegistrant}
        agencyGUID={agencyGUID}
        teamSlug={teamSlug}
      />
    )
  }

  renderCannotFindResultMessage() {
    const { searchContactQ } = this.props
    const { contacts = [] } = this.state
    return (
      searchContactQ &&
      contacts.length === 0 && (
        <Box mt="24px">
          <Flex justifyContent="center">
            <TextSpan color="gray600" fontWeight={3}>
              Can't find who you're looking for?
            </TextSpan>
          </Flex>

          <Button
            variant="flatBlue"
            onClick={() => this.openAddAttendeeModal()}
            width="100%"
          >
            Add attendee
          </Button>
        </Box>
      )
    )
  }

  renderEditAttendeeModal = () => {
    const {
      isEditAttendeeModalOpen,
      editAttendeeModalLoadingStates,
      toEditRegistrant,
    } = this.state
    const { propertyId = '' } = this.props
    if (!isEditAttendeeModalOpen || !toEditRegistrant) {
      return null
    }
    const modalOptions = {
      ...enabledOptionsForAttendeeModal,
      enableShowRemoveButton: true,
    }
    const {
      currentTeam: { guid: agencyGUID, slug: teamSlug = '' },
    } = this.props
    return (
      <WalkinRenterModal
        walkinModalStatus={'edit'}
        toggleWalkInModal={() => this.closeEditAttendeeModal()}
        error={
          editAttendeeModalLoadingStates.error &&
          editAttendeeModalLoadingStates.error.message
        }
        targetAttendee={toEditRegistrant}
        onUpdateRenterStatus={(updatedRegistrant) =>
          this.editRegistrant(updatedRegistrant, toEditRegistrant.guidID)
        }
        onRemoveRegistrant={() =>
          this.removeOnsiteRegistrant(toEditRegistrant.guidID)
        }
        enableOptions={modalOptions}
        disableSaveButton={
          editAttendeeModalLoadingStates.state === loadingStatesIds.LOADING
        }
        propertyId={propertyId}
        agencyGUID={agencyGUID}
        teamSlug={teamSlug}
      />
    )
  }

  renderSearchBox = () => {
    const { isOnline, registrantSearch } = this.props

    const onChange = (search) => this.handleSearchContactInputChange(search)

    return (
      <BoxInput
        placeholder="Search for mobile or name"
        icon={
          <IconBox>
            <img alt="search" src={searchIconUrl} />
          </IconBox>
        }
        initialValue={registrantSearch}
        onChange={onChange}
        disabled={!isOnline}
      />
    )
  }

  renderConfirmAddAttendeeWithoutSlotsModal = () => {
    if (!this.state.showAttendeeWithoutSlotModal) {
      return null
    }

    return (
      <Modal
        title="Fully booked"
        hideButtonSection={true}
        toggleModal={() => this.toggleAttendeeWithoutSlotModal()}
      >
        <div className="text-center">
          The viewing is fully booked. Update the viewing duration to allocate
          more slots or the viewing cap to increase the limit. Continue to add
          attendee?
        </div>
        <ModalButtonGroup
          primaryLabel="Add"
          secondaryLabel="Cancel"
          secondaryButtonType="tertiary"
          primaryAction={() => {
            this.toggleAttendeeWithoutSlotModal()
            this.openAddAttendeeModal()
          }}
          secondaryAction={() => this.toggleAttendeeWithoutSlotModal()}
        />
      </Modal>
    )
  }

  renderRegistrantsCollapsableSection = () => {
    const {
      currentTeam: { guid: agencyGUID },
      handleShortlist,
      viewing,
    } = this.props
    const { GUID = '' } = viewing || {}
    const { registrants, isOnline, isViewingSlotEnabled } = this.props
    const { registrantsLoadingStates } = this.state

    const sortedRegistrants = registrants.sort((a, b) =>
      String(a.firstName).toLowerCase() > String(b.firstName).toLowerCase()
        ? 1
        : String(b.firstName).toLowerCase() > String(a.firstName).toLowerCase()
        ? -1
        : 0,
    )

    return (
      <CollapsableSection TitleSection={registeredSectionTitle} variant="blue">
        <LoadingSection
          actionHandler={() => this.fetchRegistrants()}
          loadingState={registrantsLoadingStates}
          loaderProps={{ fontSize: '20px' }}
          ErrorComponent={RegistrantsErrorComponent}
          showChildrenWhenError={
            registrantsLoadingStates.state === loadingStatesIds.ERROR &&
            checkIfNetworkError(registrantsLoadingStates.error)
          }
        >
          {!!sortedRegistrants.length &&
            sortedRegistrants.map((registrant) => (
              <Box key={registrant.guidID}>
                <ViewingRegistrantCard
                  registrant={registrant}
                  handleCheckInBtn={() => this.onCheckInBtnClicked(registrant)}
                  handleInterestedBtn={() =>
                    this.onInterestedBtnClicked(registrant)
                  }
                  handleNoteBtn={() => this.openEditAttendeeModal(registrant)}
                  checkInEnabled={isOnline}
                  interestedEnabled={isOnline}
                  noteEnabled={isOnline}
                  isViewingSlotEnabled={isViewingSlotEnabled}
                  agencyGUID={agencyGUID}
                  fetchRegistrantsEnquirers={() =>
                    this.fetchRegistrantsEnquirers(false)
                  }
                  handleShortlist={handleShortlist}
                  viewingGUID={GUID}
                />
                <HorizontalLine />
              </Box>
            ))}
        </LoadingSection>
      </CollapsableSection>
    )
  }

  render() {
    const { enquirers, contacts } = this.state
    const {
      isOnline,
      isViewingSlotEnabled,
      searchContactQ,
      currentTeam: { guid: agencyGUID },
      handleShortlist,
    } = this.props

    const cannotFindResultMessage = this.renderCannotFindResultMessage()

    const searchContactInput = this.renderSearchBox()

    const registrantsCollapsableSection =
      this.renderRegistrantsCollapsableSection()

    const registrantModal = this.renderAddAttendeeModal()

    const editRegistrantModal = this.renderEditAttendeeModal()

    const confirmAddAttendeeWithoutSlotsModal =
      this.renderConfirmAddAttendeeWithoutSlotsModal()

    const { enquirersLoadingStates } = this.state

    const { viewing } = this.props
    const { fullyBooked: isFullyBooked = false } = viewing

    const allEnquirers = !!searchContactQ ? contacts : enquirers
    const enquirersUniqueKeys = allEnquirers.map((e) => e.uniqueKey)
    const uniqueEnquirers = allEnquirers.filter(
      (e, idx) => enquirersUniqueKeys.indexOf(e.uniqueKey) === idx,
    )
    const { GUID = '' } = viewing || {}
    return (
      <Box p="20px">
        {registrantModal}

        {editRegistrantModal}

        <Flex>
          {searchContactInput}

          <Button
            ml="6px"
            p="0 18px"
            fontSize="14px"
            width="auto"
            onClick={() =>
              isFullyBooked
                ? this.toggleAttendeeWithoutSlotModal()
                : this.openAddAttendeeModal()
            }
          >
            <Flex alignItems="center">
              Add
              <IconBox ml="9px">
                <img alt="plus" src={plusIconUrl} />
              </IconBox>
            </Flex>
          </Button>
        </Flex>

        <Box mt="1rem">{registrantsCollapsableSection}</Box>

        <Box mt="24px">
          <CollapsableSection TitleSection={enquiredSectionTitle}>
            <LoadingSection
              actionHandler={() => this.fetchEnquirers()}
              loadingState={enquirersLoadingStates}
              loaderProps={{ fontSize: '20px' }}
              ErrorComponent={RegistrantsErrorComponent}
              showChildrenWhenError={
                enquirersLoadingStates.state === loadingStatesIds.ERROR &&
                checkIfNetworkError(enquirersLoadingStates.error)
              }
            >
              {!!uniqueEnquirers.length &&
                uniqueEnquirers.map((enquirer, idx) => (
                  <Box key={enquirer.uniqueKey || idx}>
                    <ViewingRegistrantCard
                      registrant={enquirer}
                      isEnquirer
                      handleCheckInBtn={() => this.onCheckInEnquirer(enquirer)}
                      checkInEnabled={isOnline}
                      isViewingSlotEnabled={isViewingSlotEnabled}
                      agencyGUID={agencyGUID}
                      fetchRegistrantsEnquirers={() =>
                        this.fetchRegistrantsEnquirers(false)
                      }
                      handleShortlist={handleShortlist}
                      viewingGUID={GUID}
                    />
                    <HorizontalLine />
                  </Box>
                ))}
            </LoadingSection>
          </CollapsableSection>
        </Box>

        {cannotFindResultMessage}

        {confirmAddAttendeeWithoutSlotsModal}
      </Box>
    )
  }
}

PureViewingDetailsAttendeesTab.propTypes = {
  viewing: PropTypes.object,
  currentTeam: PropTypes.object,
  propertyId: PropTypes.string,
  onChanges: PropTypes.func,
  registrants: PropTypes.array,
}

const mapDispatchToProps = (dispatch) => {
  return {
    setViewingId: (viewingId) =>
      dispatch(actionCreators.setViewingId(viewingId)),
    syncedRegistrantsLoaded: (registrants) =>
      dispatch(actionCreators.syncedRegistrantsLoaded(registrants)),
    addRegistrant: (registrant, viewingId) =>
      dispatch(actionCreators.addRegistrant(registrant, viewingId)),
    updateIsAddRegistrantModalOpened: (isOpened) =>
      dispatch(actionCreators.updateIsAddRegistrantModalOpened(isOpened)),
    updateRegistrantSearchText: (registrantSearchText) => {
      return dispatch(
        actionCreators.updateRegistrantSearchText(registrantSearchText),
      )
    },
    updateRegistrantSearchQ: (searchContactQ) =>
      dispatch(actionCreators.updateRegistrantSearchQ(searchContactQ)),
    updateSyncedRegistrant: (registrant) =>
      dispatch(actionCreators.updateSyncedRegistrant(registrant)),
    addSyncedRegistrant: (registrant) =>
      dispatch(actionCreators.addSyncedRegistrant(registrant)),
  }
}

const mapStateToProps = ({ uiState, core }) => {
  const {
    registrants,
    addRegistrantLoadingStates,
    isAddRegistrantModalOpened,
    registrantSearch,
    searchContactQ,
  } = uiState.viewingAttendeesTab
  return {
    registrants: Object.values(registrants),
    addRegistrantLoadingStates,
    isOnline:
      core.connectionStatus === connectionStatuses.online ||
      core.connectionStatus === connectionStatuses.becameOnline,
    isAddRegistrantModalOpened,
    registrantSearch,
    searchContactQ,
  }
}

const ViewingDetailsAttendeesTab = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PureViewingDetailsAttendeesTab)

export default ViewingDetailsAttendeesTab
