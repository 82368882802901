import { connect } from 'react-redux'

import PropertyForm from 'app/sm/advertise_flow/components/property_form'
import {
  createProperty,
  deletePhoto,
  fetchProperty,
  fetchPropertyAttributes,
  ping,
  resetProperty,
  updateProperty,
  uploadPhoto,
} from 'app/sm/ppp/property_actions'

const mapStateToProps = ({ Ppp }) => ({
  attributes: Ppp.attributes,
  property: Ppp.property,
  error: Ppp.error,
  spinner: Ppp.spinner,
})

const mapDispatchToProps = (dispatch) => ({
  createProperty: (property) => dispatch(createProperty(property)),
  updateProperty: (id, path) => (property) =>
    dispatch(updateProperty(id, property, path)),
  resetProperty: () => dispatch(resetProperty()),
  fetchProperty: (id) => dispatch(fetchProperty(id)),
  fetchPropertyAttributes: () => fetchPropertyAttributes(),
  uploadPhoto: (id, files) => dispatch(uploadPhoto(id, files)),
  deletePhoto: (id) => dispatch(deletePhoto(id)),
  ping: () => dispatch(ping()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PropertyForm)
