import { connect } from 'react-redux'

import {
  addTenant,
  fetchTenant,
  updateTenant,
} from 'app/bond_cover/bond_cover_actions'
import TenantFormContainer from 'app/bond_cover/renters/tenants/tenant_form_container'

const mapStateToProps = ({ session, bondCover }) => ({
  apiError: bondCover.error,
  tenant: bondCover.tenant,
})

const mapDispatchToProps = (dispatch) => ({
  addTenant: (requestID, tenant) => addTenant(requestID, tenant),
  fetchTenant: (requestID, id) => fetchTenant(requestID, id),
  updateTenant: (requestID, id, tenant) => updateTenant(requestID, id, tenant),
})

export default connect(mapStateToProps, mapDispatchToProps)(TenantFormContainer)
