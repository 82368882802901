import React from 'react'

import moment from 'moment'

import { history } from 'app/shared_components/router'
import * as helpers from 'app/sm/helpers'
import { ApplicationEvents } from 'app/sm/helpers'

// It's a closure to keep its state once initialized
const adaptTopPosition = (start, step) => () => {
  start -= step
  return start
}
const eventsThatNeverHappened = (lastEvent, FilteredApplicationEvents) =>
  [...FilteredApplicationEvents].filter((e) => e[0] > lastEvent.event)
const filterExcludedEvents = (excluded) =>
  new Map(
    [...ApplicationEvents].filter(
      (eventEntry) => !excluded.includes(eventEntry[0]),
    ),
  )

function formatDate(date) {
  return moment(date).format('D MMMM YYYY')
}

function sortEventsByDate(Events) {
  return Events.sort((a, b) =>
    moment(a.CreatedAt).isBefore(moment(b.CreatedAt)) ? -1 : 1,
  )
}

function renderCompletion(
  key,
  date,
  label,
  topStyle,
  level = 3,
  isLast = false,
  applicationId = '',
  agencyId = '',
) {
  return (
    <li
      className={`${level === 3 ? 'completed' : level === 2 ? 'current' : ''}`}
      key={key}
    >
      <div className="display-flex flex-direction-column-mobile">
        <div className="text">
          <h4>{label}</h4>
          {level === 3 && <div className="date">{formatDate(date)}</div>}
        </div>
        {level === 3 && label === 'Offer accepted' && agencyId && (
          <div
            className="gray-text linkStyle offer-lease-link-wrapper"
            onClick={() =>
              history.push(
                helpers.urlTo('leasingOfferRenter', {
                  applicationId,
                  agencyId,
                }),
              )
            }
          >
            View lease offer
          </div>
        )}
      </div>
      <div className="vline">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-15.9 -4.4 6.9 49.5">
          <title>Path 3</title>
          <path d="M-13.6 45.2l-1.9-.7c.2-.6.5-1.2.7-1.8l1.9.7c-.2.5-.4 1.1-.7 1.8zm1.4-3.9l-1.9-.6c.2-.6.4-1.3.6-1.9l1.9.6c-.2.7-.4 1.3-.6 1.9zm1.1-3.9l-1.9-.5c.2-.6.3-1.3.5-1.9l2 .4c-.2.7-.4 1.4-.6 2zm.9-3.9l-2-.4.3-1.9 2 .3c0 .7-.1 1.3-.3 2zm.7-4l-2-.3c.1-.7.2-1.3.2-2l2 .2c-.1.7-.1 1.4-.2 2.1zm.4-4.1l-2-.1c0-.7.1-1.3.1-2l2 .1c-.1.7-.1 1.4-.1 2zm.1-4h-2v-2l2-.1v2.1zm-2.1-4c0-.7-.1-1.3-.2-2l2-.2c.1.7.1 1.4.2 2l-2 .2zm-.4-3.9l-.3-2 2-.3.3 2-2 .3zm-.6-3.9c-.1-.6-.3-1.3-.4-1.9l2-.4c.1.7.3 1.3.4 2l-2 .3zm-.9-3.8c-.2-.6-.3-1.3-.5-1.9l1.9-.5c.2.7.4 1.3.5 2l-1.9.4zM-14 2c-.2-.6-.4-1.3-.6-1.9l1.9-.6c.2.6.4 1.3.6 1.9L-14 2zm-1.2-3.8c-.2-.6-.5-1.2-.7-1.9l1.9-.7c.2.6.5 1.3.7 1.9l-1.9.7z" />
        </svg>
      </div>
    </li>
  )
}

function Completions(props) {
  const events = props.Events
  const takeHoldingDeposit = props.takeHoldingDeposit
  const { agencyId, applicationId } = props
  /* 
    This 'excludedEvents' array is a general approach to filter the events that shouldn't be shown.
   For now the only usecase for this is when the manager doesn't take holding deposit, so 
   the DepositWasPaid event should be filtered out.
   But in the future if there is a need for another usecase like this,
   you should populate the array depending on the usecase.
   This array just stores the id of the event (see ApplicationEvents in the helpers module)
  */

  let excludedEvents = []

  if (!takeHoldingDeposit) {
    ApplicationEvents.set(80, {
      token: 'OfferAccepted',
      label: 'Offer accepted',
    })
  }

  const FilteredApplicationEvents = filterExcludedEvents(excludedEvents)
  const Events = sortEventsByDate(events)
  const lastEvent = Events[Events.length - 1] || { event: 0 }
  const futureEvents = eventsThatNeverHappened(
    lastEvent,
    FilteredApplicationEvents,
  ) //  TODO: Handle the case when there are no future events so the vertical bar to be disclosed at a green circle
  const topStyle = adaptTopPosition(-3, 3)

  return (
    <ul className="sm-completions">
      {Events.map((it, i) =>
        renderCompletion(
          i,
          it.CreatedAt,
          ApplicationEvents.get(it.event).label,
          topStyle(),
          3,
          futureEvents.length === 0 && Events.length - 1 === i,
          applicationId,
          agencyId,
        ),
      )}
      {futureEvents
        .slice(0, 1)
        .map((it, i) =>
          renderCompletion(
            i + Events.length,
            '',
            it[1].label,
            topStyle(),
            2,
            futureEvents.length - 1 === i,
          ),
        )}
      {futureEvents
        .slice(1)
        .map((it, i) =>
          renderCompletion(
            i + Events.length + 1,
            '',
            it[1].label,
            topStyle(),
            1,
            futureEvents.length - 2 === i,
          ),
        )}
    </ul>
  )
}

export default Completions
