import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import encryption from 'app/assets/icons/application-details/encryption.png'
import equifax from 'app/assets/icons/application-details/equifax.svg'
import shield from 'app/assets/icons/application-details/shield.svg'
import questionIcon from 'app/assets/icons/question-icon.svg'
import bgcheckIcon from 'app/assets/icons/summary_icons/backgroundcheck.svg'
import WelcomeImage from 'app/assets/icons/Welcome.svg'
import {
  Alert,
  Box,
  Button,
  Flex,
} from 'app/components/design-system-components'
import { InfoRounded } from 'app/components/design-system-components/icons/actions'
import { Heading } from 'app/components/design-system-components/typography'
import * as Display from 'app/components/display/display'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import { history } from 'app/history'
import theme from 'app/match/applicationReportPDF/assets/theme'
import BackgroundCheckStatusComponent from 'app/match/apply/application_summary_before_submit/components/background_check_container/background_check_status_container'
import ExampleReportModal from 'app/sm/background_check/exampleReportModal'
import SelfTenancyDocList from 'app/sm/background_check/SelfTenancyCheck/DocList'
import Spinner from 'app/sm/common/spinner'
import { capitalizeFirstLetter, urlTo } from 'app/sm/helpers'
import { checkIfBGCheckNotSupportedInState } from 'app/utils/address/helpers'
import * as applicationHelpers from 'app/utils/applications/helpers'
import {
  checkBankruptcyCourtRecordsASISFromBGCheckResponse,
  combineBankruptcyAndCourtRecordsCheck,
} from 'app/utils/backgroundcheck'
import { BGCheckPriceForManagers } from 'app/utils/text/helpers'

import 'app/match/apply/application_summary_before_submit/components/background_check_container/style.scss'

const BG_CHECK_STATUS_VERIFIED = 'verified'
const BG_CHECK_STATUS_UNVERIFIED = 'unverified'

const TicaBackgroundCheck = styled.p`
  margin-top: -28px;
  display: block;
  position: absolute;
  margin-left: 36px;
  font-size: 12px;
`

const StatusLabel = styled.div`
  margin-top: 0 !important;
`
const bgCGeneralCondition =
  'To process a Background Check, the application must include a valid Passport or Australian Driver Licence.'

const bgCRestrictedCondition =
  ' To process a Background Check, the application must be pre-approved, include a valid Passport or Australian Driver Licence.'

const BackgroundCheckContainer = ({
  componentClassName = '',
  optOutBackgroundCheck = false,
  attachedToProfile = false,
  onEditClick,
  backgroundCheck = {},
  selectedApplicant = {},
  backgroundCheckDate,
  enableNote = false,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  displayedApplicant,
  section,
  readyForBackgroundCheck = false,
  backgroundCheckProcessingEnabled = false,
  bgCRestrictionEnabled = false,
  currentApplicantId = '',
  onBackgroundCheckClicked,
  isBackgroundCheckLoading = false,
  backgroundCheckApiError = '',
  id = '',
  toggleRequestInfoModal = undefined,
  fetchBackgroundCheckReportDetails,
  fetchApplicationApplicantBackgroundCheckReportDetails,
  selfTenancyCheck = {},
  isTicaActive,
  applicationPropertyState = '',
  teamSlug = '',
  managerBGCCheckPrice = 0,
  excludeBGCBankruptcyReport = false,
}) => {
  const [backgroundCheckDetail, setBackgroundCheckDetail] = useState('')
  const [showExampleBGCheckReport, setShowExampleBGCheckReport] =
    useState(false)

  const toggleShowExampleBGCheckReport = () => {
    setShowExampleBGCheckReport(!showExampleBGCheckReport)
  }
  const {
    asicCompanyCheck,
    banckruptcyCheck,
    courtRecordsCheck,
    identityVerification,
    tenanacyBlacklistCheck,
    applicationGUID,
    status,
    checkStatus,
    message = '',
  } = backgroundCheck || {}

  const { backgroundCheckResponse = {} } = backgroundCheckDetail || {}
  const {
    isIdentityVerificationResults,
    isTenanacyBlacklistCheckResults,
    isBankruptciesResults,
    isCourtRecordsResults,
    isDirectorshipsProprietorshipsResult,
  } = checkBankruptcyCourtRecordsASISFromBGCheckResponse(
    backgroundCheckResponse,
    backgroundCheck,
  )

  const isManagerApplicationPage =
    window.location.pathname.includes('/applications') &&
    (window.location.pathname.includes('/property') ||
      window.location.pathname.includes('/applyanywhere'))
  const isRenterApplicationPage =
    window.location.pathname.includes('/rentersummary')
  const { applicationID } = selectedApplicant
  const isBackgroundCheckInfoAvailable =
    applicationHelpers.shouldShowBackgroundCheckReport(
      status,
      attachedToProfile,
      optOutBackgroundCheck,
      applicationGUID,
    )
  const bankruptcyAndCourtRecordsCheck = combineBankruptcyAndCourtRecordsCheck(
    banckruptcyCheck,
    courtRecordsCheck,
  )
  const isBGCheckStatusValid =
    checkStatus === BG_CHECK_STATUS_VERIFIED ||
    checkStatus === BG_CHECK_STATUS_UNVERIFIED

  let showBGC = isBackgroundCheckInfoAvailable && isBGCheckStatusValid
  const bgCheckCompletedByManager =
    applicationHelpers.isBGCheckCompletedByManager(
      status,
      attachedToProfile,
      optOutBackgroundCheck,
      applicationGUID,
    )

  if (
    checkIfBGCheckNotSupportedInState(applicationPropertyState) &&
    !bgCheckCompletedByManager
  ) {
    showBGC = false
  }
  useEffect(() => {
    if (showBGC) {
      if (applicationID && currentApplicantId) {
        fetchApplicationApplicantBackgroundCheckReportDetails &&
          fetchApplicationApplicantBackgroundCheckReportDetails(
            applicationID,
            currentApplicantId,
          ).then(({ details }) => {
            setBackgroundCheckDetail(details)
          })
      }
      fetchBackgroundCheckReportDetails &&
        fetchBackgroundCheckReportDetails().then(({ details }) => {
          setBackgroundCheckDetail(details)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicationID,
    currentApplicantId,
    isBackgroundCheckInfoAvailable,
    isBGCheckStatusValid,
  ])

  const { SelfTenancyCheckDoc = [] } = selfTenancyCheck

  const hasSelfTenancyCheck =
    SelfTenancyCheckDoc && SelfTenancyCheckDoc.length > 0

  const bankrupcyAndCourtRecordsLabel = excludeBGCBankruptcyReport
    ? 'Court Records:'
    : 'Bankruptcy / Court Records:'

  return (
    <div id={id} className={`container-component ${componentClassName}`}>
      <ContainerHeader
        icon={bgcheckIcon}
        text="Background Check"
        isEditable={false}
        editTitle="Edit"
        enableNote={enableNote}
        onNewNoteUpdated={onNewNoteUpdated}
        newNotesContent={newNotesContent}
        onNoteIconClicked={onNoteIconClicked}
        onNewNoteAdded={onNewNoteAdded}
        onNoteCrossClicked={onNoteCrossClicked}
        interpreteNoteResult={interpreteNoteResult}
        enableNotesButton={enableNotesButton}
        displayedApplicant={displayedApplicant}
        section={section}
        toggleRequestInfoModal={toggleRequestInfoModal}
      />
      <div className="application-container-padding">
        {isManagerApplicationPage && (
          <>
            <TicaBackgroundCheck>
              {/*TODO: refactor tica part legacy code*/}
              (powered by Equifax{' '}
              {!isTicaActive && (
                <>
                  , or visit{' '}
                  <a
                    href="https://www.tica.com.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TICA <i className="fa fa-external-link text-blue" />
                  </a>
                </>
              )}
              )
            </TicaBackgroundCheck>
          </>
        )}
        {isBackgroundCheckInfoAvailable && !isBGCheckStatusValid && (
          <div className="alert alert-danger mt20">
            {message && message.length > 0 ? (
              <div>Error: {message}</div>
            ) : (
              <div>There was an error processing the Background Check</div>
            )}
          </div>
        )}
        {showBGC && (
          <div className="application-identification-sub-container flex-direction-column p0 application-background-container">
            <Flex
              alignItems="center"
              className="application-info-common-component application-background-header display-flex no-page-break"
            >
              <Flex
                alignItems="center"
                className="background-check-header-left"
              >
                <Heading level={5}>Background Check</Heading>

                {selectedApplicant && selectedApplicant.applicationID && (
                  <a
                    className="household-applicant-status Applied application-view-report-btn pdf-hide"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={urlTo('applicationApplicantBackgroundCheckReport', {
                      applicationGUID: selectedApplicant.applicationID,
                      applicantGUID: selectedApplicant.guidID,
                    })}
                  >
                    View Report
                  </a>
                )}
              </Flex>
              <Flex alignItems="center" className="date-wrapper">
                <div className="date">{`(${backgroundCheckDate})`}</div>
                <StatusLabel className="household-applicant-status Applied">
                  Complete
                </StatusLabel>
              </Flex>
            </Flex>

            <div className="no-page-break">
              <div className="application-seperator" />
              <BackgroundCheckStatusComponent
                title="Identity Verification:"
                resultInfo={identityVerification}
                result={isIdentityVerificationResults}
              />
              <BackgroundCheckStatusComponent
                title="National Tenancy Database:"
                resultInfo={tenanacyBlacklistCheck}
                result={isTenanacyBlacklistCheckResults}
              />
              <BackgroundCheckStatusComponent
                title={bankrupcyAndCourtRecordsLabel}
                resultInfo={bankruptcyAndCourtRecordsCheck}
                result={isBankruptciesResults && isCourtRecordsResults}
              />
              <BackgroundCheckStatusComponent
                title="ASIC Directorships:"
                resultInfo={asicCompanyCheck}
                result={isDirectorshipsProprietorshipsResult}
              />
              <div>
                <div className="application-seperator" />
                <div className="background-check-pic-container">
                  <div className="display-flex">
                    <img
                      className="img-margin"
                      src={shield}
                      alt="shield icon"
                    />
                    <img
                      className="img-margin"
                      src={encryption}
                      alt="encryption icon"
                    />
                    <img
                      className="img-margin"
                      src={equifax}
                      alt="equifax icon"
                    />
                  </div>
                  <div className="background-check-term-text">
                    Powered by Equifax on these terms:{' '}
                    <a href="https://www.tenancydatabase.com.au/terms">
                      tenancydatabase.com.au/terms
                    </a>
                    .
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isBackgroundCheckInfoAvailable &&
        isRenterApplicationPage &&
        !hasSelfTenancyCheck ? (
          <div className="display-flex bg-check-button-on-mobile">
            <div className="background-check-text">
              Improve your Match Score by adding a Background Check
            </div>
            <div
              className="background-check-button"
              onClick={() =>
                onEditClick(`${urlTo('BackgroundCheck')}?from=rentersummary`)
              }
              role="button"
              tabIndex="0"
            >
              <span>Add Background Check</span>
            </div>
          </div>
        ) : (
          <SelfTenancyDocList
            selfTenancyCheckDoc={SelfTenancyCheckDoc}
            showRemoveButtons={false}
            showAlert={true}
          />
        )}
        {!showBGC && isManagerApplicationPage && !hasSelfTenancyCheck && (
          <div className="bg-check-button-on-mobile">
            {isBackgroundCheckLoading && (
              <div>
                <div className="background-check-text pdf-hide">
                  Background Check requested. This can take up to 30 seconds.
                  You can continue and come back later.
                </div>
                <div className="background-check-text pdf-show">
                  {capitalizeFirstLetter(selectedApplicant.firstName)} has not
                  included a Background Check
                </div>
              </div>
            )}
            {!backgroundCheckProcessingEnabled && (
              <div>
                <div className="background-check-text">
                  {capitalizeFirstLetter(selectedApplicant.firstName)} has not
                  included a Background Check.
                </div>
                <Alert variant="blackWithBlueBg" mt={5}>
                  <Flex alignItems="center">
                    <Box mt={1}>
                      <img src={WelcomeImage} alt="welcome" />
                    </Box>
                    <Box ml={5}>
                      Activate real-time background checks with integrated NTD,
                      identity checks and more for ${managerBGCCheckPrice} per
                      report.
                      <Box mt={3}>
                        <Button
                          variant="outlineGreenWhiteBg"
                          onClick={() => {
                            history.push(
                              urlTo('teamViewingPreference', {
                                teamSlug,
                              }),
                            )
                            window.location.reload()
                          }}
                        >
                          Open Settings
                        </Button>
                      </Box>
                    </Box>
                  </Flex>
                </Alert>
              </div>
            )}
            {backgroundCheckProcessingEnabled &&
              !isBackgroundCheckLoading &&
              !hasSelfTenancyCheck && (
                <div>
                  <div className="background-check-text">
                    {capitalizeFirstLetter(selectedApplicant.firstName)} has not
                    included a Background Check.{' '}
                    <Button
                      variant="linkDeepBlue"
                      onClick={toggleShowExampleBGCheckReport}
                    >
                      View Example Report
                    </Button>
                  </div>
                  {readyForBackgroundCheck && (
                    <Flex alignItems="center" mt={4}>
                      <Button
                        onClick={() =>
                          onBackgroundCheckClicked(
                            currentApplicantId,
                            applicationID,
                          )
                        }
                      >
                        Process Background Check
                      </Button>
                      <Box ml={3} color={theme.colors.gray700}>
                        ${managerBGCCheckPrice} (ex GST)
                      </Box>
                      <Box
                        ml={3}
                        data-tooltip-id="bg-processing"
                        data-tooltip-content=""
                      >
                        <img src={questionIcon} alt="bg processing help text" />
                        <ReactTooltip id="bg-processing">
                          Individual Applicant Report. Billed monthly in arrears
                          for checks that complete.
                        </ReactTooltip>
                      </Box>
                    </Flex>
                  )}
                  {!readyForBackgroundCheck && (
                    <div className="mt20 pdf-hide">
                      <Alert variant="blueWithBg">
                        <Flex>
                          <Box mt={1}>
                            <InfoRounded
                              width={`${theme.width[5]}px`}
                              height={`${theme.width[5]}px`}
                            />
                          </Box>
                          <Box ml={5}>
                            {bgCRestrictionEnabled
                              ? bgCRestrictedCondition
                              : bgCGeneralCondition}
                          </Box>
                        </Flex>
                      </Alert>
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
        {showExampleBGCheckReport && (
          <ExampleReportModal
            toggleExampleReportModal={toggleShowExampleBGCheckReport}
          />
        )}

        <Display.ErrorMessageContainer error={backgroundCheckApiError} />
        {isBackgroundCheckLoading && <Spinner />}
        {isBackgroundCheckLoading && (
          <Display.NoticeMessageContainer text="Background check status: pending" />
        )}
      </div>
    </div>
  )
}

export default BackgroundCheckContainer
