import React from 'react'

import {
  Box,
  Flex,
  HorizontalLine,
  IconButton,
} from 'app/components/design-system-components'
import { ClearRounded } from 'app/components/design-system-components/icons/content'
import { SimpleFieldLabel } from 'app/forms/FieldWrapper'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const ModalHeader = ({ onDismiss, subject, registrantsCount = 0 }) => {
  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mb={theme.space[6] + 'px'}
      >
        <h3>New Message</h3>
        <IconButton
          IconCmp={ClearRounded}
          iconProps={{ fontSize: theme.fontSizes[10] + 'px' }}
          variant="flat"
          onClick={onDismiss}
        />
      </Flex>
      <HorizontalLine />
      <Box mt={theme.space[3] + 'px'} mb={theme.space[3] + 'px'}>
        <SimpleFieldLabel label="To:" />
        <Box>
          {registrantsCount} attendees {subject}
        </Box>
      </Box>
      <HorizontalLine />
    </>
  )
}
