import ErrorMessages from 'app/constants/error_messages'
import {
  RECEIVE_RESPONSE_TEXT,
  receiveError,
} from 'app/sm/background_check/background_check_actions'

const BackgroundCheckMiddleware = (store) => (next) => (action) => {
  let { type, responseText } = action
  let errorCode = parseInt(responseText)
  let errorMessage = ErrorMessages[errorCode]

  if (type !== RECEIVE_RESPONSE_TEXT) {
    next(action)
    return
  }

  if (errorMessage) {
    store.dispatch(receiveError({ errorMessage, errorCode }))
    return next(action)
  }

  if (isNaN(responseText)) {
    store.dispatch(receiveError(responseText))
    return next(action)
  }

  store.dispatch(receiveError(`There was a problem. Error code: ${errorCode}`))
  return next(action)
}

export default BackgroundCheckMiddleware
