import { connect } from 'react-redux'

import {
  addApplyAnywhere,
  apply,
  fetchApplyAnywhereListing,
  updateApplyAnywhere,
} from 'app/sm/apply_anywhere/apply_anywhere_actions'
import ApplyAnywhereContainer from 'app/sm/apply_anywhere/apply_anywhere_container'

const mapStateToProps = ({ session }) => ({
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  fetchApplyAnywhereListing: (guid, success, failure) =>
    dispatch(fetchApplyAnywhereListing(guid, success, failure)),
  addApplyAnywhere: (
    applyAnywhereListing,
    addApplyAnywhereSuccess,
    addApplyAnywhereFailure,
  ) =>
    dispatch(
      addApplyAnywhere(
        applyAnywhereListing,
        addApplyAnywhereSuccess,
        addApplyAnywhereFailure,
      ),
    ),
  updateApplyAnywhere: (
    applyAnywhereListing,
    addApplyAnywhereSuccess,
    addApplyAnywhereFailure,
  ) =>
    dispatch(
      updateApplyAnywhere(
        applyAnywhereListing,
        addApplyAnywhereSuccess,
        addApplyAnywhereFailure,
      ),
    ),
  applyAnywhereApply: (applyAnywhereGUID, applySuccessful, applyFailed) =>
    dispatch(apply(applyAnywhereGUID, applySuccessful, applyFailed)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplyAnywhereContainer)
