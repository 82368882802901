import React from 'react'

import { IntlTelInputWrapper } from 'app/components/design-system-components'
import isEmptyObject from 'app/shared_components/check_empty_object'
import { ErrorMessage } from 'app/shared_components/helpers'
import getParameter from 'app/shared_components/parameter_parser'
import * as validations from 'app/shared_components/validations'

class ConfirmRegisterForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      confirmedMobile: '',
      socialAlias: '',
      networkName: 'facebook',
      disabled: true,
      clicked: false,
      validateErrorMessage: '',
      errors: {},
    }
  }

  UNSAFE_componentWillMount() {
    let { clearError } = this.props
    clearError()
  }

  componentDidMount() {
    this.getDetails()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ clicked: false })
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    nextState.disabled = !(
      nextState.firstName &&
      nextState.lastName &&
      nextState.email &&
      nextState.mobile &&
      nextState.confirmedMobile &&
      nextState.mobile === nextState.confirmedMobile &&
      isEmptyObject(nextState.errors) &&
      !nextState.clicked
    )
  }

  onConfirmedMobileBlured = () => {
    this.state.errors.confirmedMobile
      ? this.setState({ validateErrorMessage: `Mobile numbers don't match` })
      : this.setState({ validateErrorMessage: '' })
  }

  getDetails() {
    this.state.socialAlias = getParameter('socialAlias')
    this.state.firstName = getParameter('firstName')
    this.state.lastName = getParameter('lastName')
    this.state.email = getParameter('email')
    this.state.networkName = getParameter('networkName')
    this.setState(this.state)
  }

  setValidationErrors(field, error) {
    let errors = this.state.errors
    if (error.length === 0) {
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  submit = () => {
    const { confirm } = this.props
    const { firstName, lastName, email, mobile, confirmedMobile, errors } =
      this.state
    this.setState({
      disabled: true,
      showError: true,
    })
    const fieldsNeedsValidate = [
      'firstName',
      'lastName',
      'email',
      'mobile',
      'confirmedMobile',
    ]
    fieldsNeedsValidate.map((field) => {
      this.validate(field, this.state[field])()
    })
    if (
      firstName &&
      lastName &&
      email &&
      mobile &&
      confirmedMobile &&
      isEmptyObject(errors)
    ) {
      confirm(this.state)
      this.setState({
        clicked: true,
      })
    }
  }

  trimField = (field) => {
    return () => {
      if (!field) {
        const value = this.state[field].trim()
        this.validate(field, value)()
        this.setState({
          [field]: value,
          showError: false,
        })
      }
    }
  }

  update(field) {
    return (event) => {
      const { value } = event.target
      this.validate(field, value)()
      this.setState({
        [field]: value,
        showError: false,
      })
    }
  }

  updateEmail(field) {
    return (event) => {
      let { value } = event.target
      this.validate(field, value)()
      value = value.toLowerCase()
      this.setState({
        [field]: value,
        showError: false,
      })
    }
  }

  validate = (field, value) => {
    return () => {
      switch (field) {
        case 'firstName':
          return this.setValidationErrors(
            field,
            validations.validateFirstName(value),
          )
        case 'lastName':
          return this.setValidationErrors(
            field,
            validations.validateLastName(value),
          )
        case 'email':
          return this.setValidationErrors(
            field,
            validations.validateEmail(value),
          )
        case 'mobile':
          return this.setValidationErrors(
            field,
            validations.validateMobile(value),
          )
        case 'confirmedMobile':
          return this.setValidationErrors(
            field,
            validations.validateMobile(value),
          )
        case 'password':
          return this.setValidationErrors(
            field,
            validations.validateNewPassword(value),
          )
      }
    }
  }

  validateConfirmNumber = (status, value, countryData, number, id) => {
    this.setState(
      { confirmedMobile: number.trim() },
      this.validate('confirmedMobile', number),
    )
  }

  validateInternationalNumber = (status, value, countryData, number, id) => {
    this.setState({ mobile: number.trim() }, this.validate('mobile', number))
  }

  render() {
    const {
      disabled,
      clicked,
      firstName,
      lastName,
      email,
      mobile,
      errors,
      confirmedMobile,
      validateErrorMessage,
      showError = false,
    } = this.state
    const {
      firstName: firstNameError = '',
      lastName: lastNameError = '',
      mobile: mobileError = '',
      email: emailError = '',
    } = errors
    const { error } = this.props
    return (
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-xs-6">
              <div className="input-box">
                <input
                  type="text"
                  required
                  className={errors.firstName ? 'error' : ''}
                  value={firstName}
                  onChange={this.update('firstName')}
                  onBlur={this.trimField('firstName')}
                />
                <label>First Name</label>
              </div>
            </div>
            <div className="col-xs-6">
              <div className="input-box">
                <input
                  type="text"
                  required
                  className={errors.lastName ? 'error' : ''}
                  value={lastName}
                  onChange={this.update('lastName')}
                  onBlur={this.trimField('lastName')}
                />
                <label>Last Name</label>
              </div>
            </div>
          </div>

          <div className="input-box">
            <input
              type="text"
              required
              className={errors.email ? 'error' : ''}
              value={email}
              onChange={this.updateEmail('email')}
              onBlur={this.trimField('email')}
            />
            <label>Email Address</label>
          </div>

          <div
            className={`input-box register-form-mobile-input-box ${
              errors.mobile ? 'error' : ''
            }`}
          >
            <IntlTelInputWrapper
              css={[
                'intl-tel-input country-list',
                'register-form-mobile-input',
              ]}
              utilsScript={'libphonenumber.js'}
              defaultCountry={'au'}
              onPhoneNumberChange={this.validateInternationalNumber}
              onPhoneNumberBlur={this.trimField('mobile')}
              value={mobile}
              onlyCountries={['au']}
              placeholder={'+61 412 345 678'}
            />
            <label>Mobile Number</label>
          </div>

          <div
            className={`input-box register-form-mobile-input-box ${
              errors.confirmedMobile ||
              (confirmedMobile !== mobile && confirmedMobile !== '')
                ? 'error'
                : ''
            }`}
          >
            <IntlTelInputWrapper
              css={[
                'intl-tel-input country-list',
                'register-form-mobile-input',
              ]}
              utilsScript={'libphonenumber.js'}
              defaultCountry={'au'}
              onPhoneNumberChange={this.validateConfirmNumber}
              onPhoneNumberBlur={this.onConfirmedMobileBlured}
              value={confirmedMobile}
              onlyCountries={['au']}
              placeholder={'+61 412 345 678'}
            />
            <label>Confirmed Mobile Number</label>
          </div>

          <button
            className="mt15 mb30"
            onClick={this.submit}
            disabled={disabled}
          >
            Join Snug{' '}
            <i
              className={disabled && clicked ? 'fa fa-spinner fa-pulse' : ''}
            />
          </button>

          {error && <ErrorMessage error={error} />}
          {!isEmptyObject(errors) && showError && (
            <ErrorMessage
              error={`${firstNameError} ${lastNameError} ${emailError} ${mobileError}`}
            />
          )}
          {validateErrorMessage && (
            <ErrorMessage error={validateErrorMessage} />
          )}
        </div>
      </div>
    )
  }
}

export default ConfirmRegisterForm
