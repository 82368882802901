import React from 'react'

import { CSSTransitionGroup } from 'react-transition-group'

import 'app/shared_components/animation_wrapper/bottom_to_top.scss'

// All options that can be passed to CSSTransitionGroup can be found here:
// https://github.com/reactjs/react-transition-group/tree/v1.2.1

const AnimationWrapper = ({
  component,
  isOpened = false,
  transitionOptions = {},
}) => {
  return (
    <CSSTransitionGroup {...transitionOptions}>
      {isOpened ? component : ''}
    </CSSTransitionGroup>
  )
}

export default AnimationWrapper
