import React from 'react'

import styled from 'styled-components'

import checkmarkWithBG from 'app/assets/icons/checkmark-with-bg.svg'
import { Box, Flex } from 'app/components/design-system-components'

const StyledReportSent = styled(Flex)`
  font-size: ${(props) => props.theme.fontSizes[4]}px;
  align-items: center;
  line-height: ${(props) => props.theme.lineHeights.copy};
  img {
    width: ${(props) => props.theme.space[5]}px;
    height: ${(props) => props.theme.space[5]}px;
  }
`

function ReportSent({
  showSMSSentSection = false,
  showEmailReportSentSection = false,
}) {
  return (
    <StyledReportSent>
      <>
        <Box>Sent:</Box>
        <Flex>
          {showSMSSentSection && (
            <Box ml="8px">
              <Flex alignItems="center">
                <Box>SMS</Box>
                <Box ml="5px">
                  <img src={checkmarkWithBG} alt="sms report sent" />
                </Box>
              </Flex>
            </Box>
          )}
          {showEmailReportSentSection && (
            <Box ml="8px">
              <Flex alignItems="center">
                <Box>Email</Box>
                <Box ml="5px">
                  <img src={checkmarkWithBG} alt="email report sent" />
                </Box>
              </Flex>
            </Box>
          )}
        </Flex>
      </>
    </StyledReportSent>
  )
}

export default ReportSent
