import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

const IdentityDoc = ({ identityDoc, request, currentLease, role }) => {
  let stepNumber = 4
  if (role === 'secondary') {
    stepNumber = 1
  } else if (currentLease && currentLease.isMultiTenantLease) {
    stepNumber = 5
  }

  let requestID = ''
  if (request) {
    requestID = request.guidID
  }

  let docHtml = null,
    id = ''
  if (identityDoc && identityDoc.dateOfBirth) {
    let {
      firstName,
      middleName,
      lastName,
      guidID,
      docType,
      docNumber,
      state,
      expiryDate,
      dateOfBirth,
    } = identityDoc
    id = guidID
    docHtml = (
      <p>
        {firstName} {middleName} {lastName} <br />
        {docType === 1 ? 'Driver Licence Number' : 'Passport Number'}:{' '}
        {docNumber}
        <br />
        Expiration: {moment(expiryDate).format('Do MMM YYYY')}
        <br />
        DOB: {moment(dateOfBirth).format('Do MMM YYYY')}
      </p>
    )
  }

  let secondaryClassName = 'bc-hub-item-link'
  if (request) {
    if (docHtml) {
      secondaryClassName += ' completed'
    }
  } else {
    secondaryClassName += ' disabled'
  }

  return (
    <div>
      {(request && request.submissionStatus !== 2) || role === 'secondary' ? (
        <Link
          className={
            docHtml ? 'bc-hub-item-link completed' : 'bc-hub-item-link'
          }
          to={
            role === 'primary'
              ? `/bc/identitydoc?requestid=${requestID}&id=${id}`
              : `/bc/secondary/leaseeIdentityDoc?requestid=${requestID}&id=${id}`
          }
        >
          <header>
            <i className="icon-id" />
            <span>{stepNumber}. ID Check</span>
          </header>
          <content>{docHtml}</content>
        </Link>
      ) : (
        <div className={secondaryClassName}>
          <header>
            <i className="icon-id" />
            <span>{stepNumber}. ID Check</span>
          </header>
          <content>{docHtml}</content>
        </div>
      )}
    </div>
  )
}

export default IdentityDoc
