import React from 'react'

import 'app/components/buttons/default/style.scss'

// button types (default is primary)
// secondary, default, tertiary, ghost, destructive, destructive-hollow
// size option: small, large, jumbo
const DefaultButton = ({
  componentClass = '',
  text = '',
  onClick = () => {},
  size = '',
  disabled = false,
  buttonType = '',
  isLoading = false,
  children,
  buttonStyleClass = '',
  loaderClassName = '',
}) => {
  return (
    <div className={`button-default ${buttonType} ${componentClass}`}>
      <button
        className={
          size ? `${size}-button ${buttonStyleClass}` : `${buttonStyleClass}`
        }
        onClick={onClick}
        disabled={disabled}
      >
        {text}
        {isLoading && (
          <i className={`fa fa-spinner fa-pulse ${loaderClassName}`} />
        )}
        {children}
      </button>
    </div>
  )
}
export default DefaultButton
