import React from 'react'

import { Link } from 'react-router-dom'

import Gallery from 'app/sm/common/gallery'
import {
  CategoryTypes,
  NO_IMAGE_PLACEHOLDER_URL,
  PropertyStatus,
  urlTo,
} from 'app/sm/helpers'

const PropertyStatusTag = ({ statusCode }) => {
  const status = PropertyStatus[statusCode]
  if (status === 'Draft') {
    return <div className="status s1">Draft</div>
  } else if (status === 'Leased') {
    return <div className="status s2">Rented</div>
  } else if (status === 'TakingApplications') {
    return <div className="status s3">Taking Applications</div>
  } else {
    return <div></div>
  }
}

const PreviewButton = ({ property }) => {
  if (property.status === 3) {
    return (
      <Link
        className="btn btn-gray"
        to={urlTo('applyRedirect', { slug: property.slug })}
      >
        Preview
      </Link>
    )
  } else {
    return <div />
  }
}

const EditButton = ({ property }) => {
  return (
    <Link
      className="btn btn-gray"
      to={urlTo('updatePropertyBasics', { id: property.guidID })}
    >
      Edit Property
    </Link>
  )
}

class PropertyDashboardHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clickedPrint: false,
    }
  }

  printButtonClicked = (clickedPrint) => {
    this.setState({ clickedPrint })
  }

  render() {
    const { property = {}, error } = this.props
    const { clickedPrint } = this.state
    const hasPropertyImages = (property.images || []).length > 0

    const { acceptedLeaseLength = 'N/A', weeklyRent = 'N/A' } =
      (property.offers || []).find((o) => o.isActive) || {}

    let propertyImage = NO_IMAGE_PLACEHOLDER_URL
    if (hasPropertyImages) {
      propertyImage = (
        property.images.find((image) => image.isMain === true) ||
        property.images[0]
      ).URL
    }

    let address = ''
    let suburb = ''
    if (property.address) {
      address = `${property.address.streetNumber} ${property.address.streetName}`
      suburb = `${property.address.suburb} ${property.address.state} ${property.address.postcode}`
    }

    return (
      <div className="sm-property-dashboard-header">
        <div
          className="images"
          style={{ backgroundImage: `url(${propertyImage})` }}
        >
          <div className="controls-right">
            <Gallery
              images={(property.images || []).map(({ URL }) => ({ src: URL }))}
            />
          </div>
        </div>
        <div className="meta">
          <PropertyStatusTag statusCode={property.status} />
          {/*<div className="status s1">Draft</div>*/}
          {/* <div className="status s2">Rented</div> */}
          {/*<div className="status s3">Advertised</div>*/}
          <div className="address">
            <div className="street">{address}</div>
            <div className="suburb">{suburb}</div>
          </div>
          <ul className="amenities">
            <li>
              <span>{CategoryTypes[property.type]}</span>
            </li>
            <li>
              <i className="icon-bedroom"></i>
              <span>{property.bedrooms}</span>
            </li>
            <li>
              <i className="icon-bathroom"></i>
              <span>{property.bathrooms}</span>
            </li>
            <li>
              <i className="icon-cars"></i>
              <span>{property.parking}</span>
            </li>
          </ul>
          {/* <ul className="options">
            <li>
              <i className="icon-date"></i>
              <span>{acceptedLeaseLength} months left</span>
              <span className="gray-color">(24/07/2018)</span>
            </li>
            <li>
              <i className="yellow-bg">!</i>
              <i className="icon-rent"></i>
              <span>${weeklyRent} p/w</span>
              <span className="gray-color">(overdue 9 days)</span>
            </li>
          </ul> */}
          <div className="actions floated">
            <PreviewButton property={property} />
            <EditButton property={property} />
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
      </div>
    )
  }
}

export default PropertyDashboardHeader
