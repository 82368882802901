export const reduceArrayToObjectWithIdKeys = (
  arr = [],
  keyExtractor = (elem) => elem.id,
) => {
  return arr.reduce((accObj, elem) => {
    return {
      ...accObj,
      [keyExtractor(elem)]: elem,
    }
  }, {})
}
