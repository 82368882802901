import React from 'react'

import moment from 'moment'

import Badge from 'app/components/design-system-components/Badges'
import { Select } from 'app/components/design-system-components/inputs/Select/Select'
import { generateBorrowerOption } from 'app/features/teams/key-logger/CheckoutKeySetModal/AddressBorrowerSelection/FormBody'
import * as stringHelpers from 'app/utils/strings/helpers'
import { isKeyLoggerEnabled } from 'config/features'
import 'app/sm/viewings_new_run/viewingrun_item/style.scss'

class ViewingRunItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checkingOutManager: undefined,
    }
  }

  onClickRow = (draft) => {
    this.props.onClickRow(draft)
  }

  onCheckingOutMangerChange = (option) => {
    const {
      checkedOutManagers = [],
      managerGUIDS = [],
      managerList = [],
    } = this.props
    const selectedManagersForViewingRun = managerList.filter((manager) =>
      managerGUIDS.includes(manager.guidID),
    )
    const selectedManager = selectedManagersForViewingRun.find(
      ({ guidID }) => guidID === option.option,
    )

    const selectedManagerProfile = generateBorrowerOption({
      first_name: selectedManager.profile.firstName,
      last_name: selectedManager.profile.lastName,
      phone: selectedManager.profile.mobilePhone,
      email: selectedManager.profile.email,
      company: '',
    })

    if (!checkedOutManagers.includes(option.option)) {
      this.props.onClickKeyCheckOut(option.option, selectedManagerProfile)
    }
  }

  render() {
    const {
      managerGUIDS = [],
      managerList = [],
      checkedOutManagers = [],
      originalRequest,
      registrantsCount,
      runCreatedAt,
      runCumulativeDuration,
      currentTeam,
    } = this.props
    const selectedManagersForViewingRun = managerList.filter((manager) =>
      managerGUIDS.includes(manager.guidID),
    )
    const managerAndGUIDS = selectedManagersForViewingRun.map((manager) => {
      return {
        fullName: `${manager.profile.firstName} ${manager.profile.lastName}`,
        guidID: manager.guidId,
        mobilePhone: `${manager.profile.mobilePhone}`,
      }
    })
    const fullNameText = stringHelpers.commaSeparatedString(
      managerAndGUIDS,
      'fullName',
    )
    const fullMobilePhoneText = stringHelpers.commaSeparatedString(
      managerAndGUIDS,
      'mobilePhone',
    )

    const { draft } = originalRequest

    const managerOptions = selectedManagersForViewingRun.map((manager) => ({
      option: manager.guidID,
      optionMeta: {
        id: manager.guidID,
        displayText: `${manager.profile.firstName} ${
          manager.profile.lastName
        } ${
          checkedOutManagers.includes(manager.guidID) ? '(checked out)' : ''
        }`,
      },
    }))

    const isPastViewingRuns = moment(originalRequest.date)
      .set({ hour: 23, minute: 59, second: 59 })
      .isBefore(moment())

    return (
      <div className="display-flex flex-direction-column border-bottom mb20 pb20">
        <div className="display-flex">
          <div className="viewing-run-row">
            <div className="profile-section">
              <div className="team-width-45p display-flex justify-content-center align-items-left text-align-left flex-direction-column">
                <div className="font-size-18" title={fullNameText}>
                  {fullNameText}
                </div>
                <div className="font-size-16 grey-text mt10">
                  {fullMobilePhoneText}
                </div>
              </div>
            </div>
            <div className="viewing-run-info">
              <div className="viewing-run-date-time align-items-center">
                <div className="viewing-run-date">
                  <div className="">
                    <i className="fa fa-calendar-check-o" />
                    <span className="font-size-18 pl5">
                      {moment(originalRequest.date).format('ddd D MMM')}{' '}
                      {draft && <Badge text="Draft" variant="gray" />}
                    </span>
                  </div>
                </div>
                <div className="viewing-run-time">
                  <div className="display-flex justify-content-center flex-direction-column">
                    <div className="">
                      <i className="fa fa-clock-o" />
                      <span className="font-size-18 pl5">
                        {moment(runCreatedAt).format('h:mm A')}
                      </span>
                    </div>
                    <div className="font-size-16 grey-text mt10">
                      {runCumulativeDuration}
                    </div>
                  </div>
                </div>
                <div className="viewing-run-stops-registrants">
                  <div className="display-flex flex-direction-column">
                    <div className="">
                      <i className="fa fa-home" />
                      <span className="font-size-18 pl5">
                        {originalRequest.properties.ids.length}
                      </span>
                    </div>
                    <div className="mt10">
                      <i className="fa fa-user-o" />
                      <span className="font-size-18 pl5">
                        {registrantsCount}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="viewing-run-show-button">
                  <button
                    className="hollow-button-green p0"
                    onClick={() => this.onClickRow(draft)}
                  >
                    {draft ? 'Edit Draft' : 'Show'}
                  </button>
                </div>
                {!draft &&
                  !isPastViewingRuns &&
                  isKeyLoggerEnabled(currentTeam) && (
                    <div className="ml5 viewing-run-show-bulk-keys-button">
                      <Select
                        options={managerOptions}
                        value={this.state.checkingOutManager}
                        changeHandler={this.onCheckingOutMangerChange}
                        label="Key Check Out"
                        minWidth="150px"
                        TriggerWrapperCmp={(toogleDropdown) => () =>
                          (
                            <button
                              className="hollow-button-green p0"
                              onClick={() => toogleDropdown()}
                            >
                              {'Key Check Out'}
                            </button>
                          )}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ViewingRunItem
