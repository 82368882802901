import $ from 'jquery'

import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getDashboard = (success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}dashboard?cachebuster=${Date.now().toString()}`,
    success,
    error,
  })
}

export const getManagerDashboard = () => {
  const url = `${apiBaseUrl}dashboard/manager`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (properties) => ({ ok: true, properties }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getSubscribeTargetInfo = (token) => {
  const url = `${apiBaseUrl}subscribe/info?token=${token}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (subscribeTargetInfo) => ({ ok: true, subscribeTargetInfo }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getRenterDashboard = () => {
  const url = `${apiBaseUrl}dashboard/renter`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (dashboard) => ({ ok: true, dashboard }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postResubscribeRequest = (token, formData) => {
  const url = `${apiBaseUrl}subscribe?token=${token}`
  const payload = formData
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (data) => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postSmsUnsubscribeRequest = (slug) => {
  const url = `${apiBaseUrl}/unsub/${slug}`
  const options = {
    method: 'POST',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (data) => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postSmsResubscribeRequest = (slug) => {
  const url = `${apiBaseUrl}/unsub/${slug}/cancel`
  const options = {
    method: 'POST',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (data) => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getPropertyOfferInfo = (offerId) => {
  const url = `${apiBaseUrl}sm/offers/${offerId}/info`
  return fetch(url, { credentials: 'include' })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (offerInfo) => ({ ok: true, offerInfo }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getPropertyOfferInfoWithDetails = (agencyGUID, offerGUID) => {
  const url = `${apiBaseUrl}sm/agency/${agencyGUID}/offer/${offerGUID}/info`
  return fetch(url, { credentials: 'include' })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (offerInfo) => ({ ok: true, offerInfo }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postUnsubscribeFeedback = (token, formData) => {
  const url = `${apiBaseUrl}unsubscribe?token=${token}`
  const payload = formData
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (data) => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const sendBondCoverReferral = (
  inviteType,
  inviterName,
  inviterEmail,
  inviteeEmail,
) => {
  const url = `${apiBaseUrl}bc/discount`

  const payload = {
    inviteType,
    inviterName,
    inviterEmail,
    inviteeEmail,
  }

  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (data) => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postShareWithOwner = (
  applicationGUID,
  recipients,
  message,
  withOwnerStatus,
  isOwnerAcceptEnabled,
  requestBGCApproval,
) => {
  const url = `${apiBaseUrl}sm/applications/${applicationGUID}/sharewithowner`
  const payload = {
    recipients,
    message,
    withOwnerStatus,
    isOwnerAcceptEnabled,
    requestBGCApproval,
  }

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(payload),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
