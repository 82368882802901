import React from 'react'

import styled from 'styled-components'

import { Alert, Box } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { history } from 'app/shared_components/router'
import * as smHelpers from 'app/sm/helpers'

const StyledUL = styled.ul`
  padding-left: ${theme.space[7]}px;

  li {
    cursor: pointer;
  }
`

function AddDetails({
  hasCurrentAddress = false,
  hasDriverLicenseCardNumber = false,
  hasIdInfo = false,
  hasValidIdentityDocs = false,
  hasDriversLicense = false,
  hasIdentityDocs = false,
}) {
  return (
    <Alert variant="blueWithBg" mb={6}>
      <Box>
        <Text
          fontWeight={theme.fontWeights[3]}
          mb={0}
          color="skyBlue"
          fontSize={theme.fontSizes[4] + 'px'}
        >
          <Text mb={0} color="pdfGray">
            To process a Background Check, click to add:
          </Text>
          <StyledUL pl={0}>
            {!hasCurrentAddress && (
              <li
                onClick={() => {
                  history.push(smHelpers.urlTo('rentalHistorySummary'))
                }}
              >
                Your current address
              </li>
            )}
            {!hasIdInfo && (
              <li
                onClick={() => {
                  history.push(smHelpers.urlTo('identityDocument'))
                }}
              >
                {`Identity document details ${
                  hasIdentityDocs && !hasValidIdentityDocs ? '(outdated)' : ''
                }`}
              </li>
            )}
            {hasDriversLicense && !hasDriverLicenseCardNumber && (
              <li
                onClick={() => {
                  history.push(smHelpers.urlTo('identityDocument'))
                }}
              >
                Your Driver Licence card number
              </li>
            )}
          </StyledUL>
        </Text>
      </Box>
    </Alert>
  )
}

export default AddDetails
