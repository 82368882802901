import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import {
  validateEmail,
  validateMobile,
  validateName,
  validatePhoneNumber,
} from 'app/shared_components/validations'

export const formFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phone: 'phone',
  officePhone: 'officePhone',
}

export const fieldValidation = (fieldName, val, values) => {
  const isBothPhoneEmpty =
    !values[formFields.phone] && !values[formFields.officePhone]
  switch (fieldName) {
    case formFields.firstName: {
      return {
        required: !val && 'Please enter first name',
        pattern: validateName(val)[0],
      }
    }
    case formFields.lastName: {
      return {
        required: !val && 'Please enter last name',
        pattern: validateName(val)[0],
      }
    }
    case formFields.email: {
      return {
        required: !val && 'Please enter email',
        pattern: val && validateEmail(val)[0],
      }
    }
    case formFields.phone: {
      return {
        required:
          isBothPhoneEmpty && 'Please enter your mobile phone of office phone',
        pattern: val && validateMobile(val)[0],
      }
    }
    case formFields.officePhone: {
      return {
        required:
          isBothPhoneEmpty && 'Please enter your mobile phone of office phone',
        pattern: val && validatePhoneNumber(val)[0],
      }
    }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
