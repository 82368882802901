import { merge as lodashMerge } from 'lodash'

import {
  ADD_REGISTRANT,
  ADD_VIEWING,
  ARCHIVE_PROPERTY,
  CANCEL_VIEWING,
  PRELOAD_PROPERTIES_AND_VIEWINGS_ERROR,
  RECEIVE_PRELOAD,
  RECEIVE_REGISTRANTS_FOR_VIEWING,
  REMOVE_REGISTRANT,
  RESET_PRELOAD_DATA,
  SHOW_FETCH_SPINNER,
  UPDATE_REGISTRANT,
  UPDATE_VIEWING,
} from 'app/pages/teams/viewings-mobile/action'

const defaultState = {
  dataPropertiesCurrent: {},
  dataPropertiesCurrentListAll: [],
  allViewings: {},
  allViewingsGUIDs: [],
  dataRegistrantsCurrent: {},
  dataRegistrantsCurrentListAll: [],
  viewingsByDate: [],
  totalRecords: 0,
  recordsOffset: 0,
}

const normalizeObjectByKeyName = (givenObject, keyName) => {
  let constructedNormalizedObject = {}
  let arrayContainingAllGUIDs = []
  givenObject.forEach((obj) => {
    const guidID = obj[keyName]
    constructedNormalizedObject = {
      ...constructedNormalizedObject,
      [guidID]: { ...obj, allViewings: [] },
    }
    arrayContainingAllGUIDs.push(guidID)
  })
  return { constructedNormalizedObject, arrayContainingAllGUIDs }
}

const DataPropertiesAndViewings = (state = defaultState, action) => {
  let {
    type,
    preloadedPropertiesAndViewings,
    error,
    showSpinner,
    viewingData,
    propertyData,
    registrants,
    registrantData,
  } = action
  switch (type) {
    case SHOW_FETCH_SPINNER:
      return { ...state, showSpinner }

    case RESET_PRELOAD_DATA:
      return defaultState

    case RECEIVE_PRELOAD:
      const {
        dataPropertiesCurrent,
        dataPropertiesCurrentListAll,
        allViewings,
        allViewingsGUIDs,
      } = state
      const {
        data: { propertyOffers, viewings, viewingGUIDsByDate } = {},
        meta: {
          pagination: { total, next_cursor },
        },
      } = preloadedPropertiesAndViewings
      const normalizedPropertyOffersAndGUIDs = normalizeObjectByKeyName(
        propertyOffers || [],
        'guidID',
      )
      const normalizedViewingsAndGUIDs = normalizeObjectByKeyName(
        viewings || [],
        'GUID',
      )
      normalizedViewingsAndGUIDs.arrayContainingAllGUIDs.forEach(
        (viewingGUID) => {
          normalizedPropertyOffersAndGUIDs['constructedNormalizedObject'][
            normalizedViewingsAndGUIDs.constructedNormalizedObject[viewingGUID]
              .offerGUID
          ]['allViewings'].push(viewingGUID)
        },
      )

      const preloadedData = {
        dataPropertiesCurrent: {
          ...dataPropertiesCurrent,
          ...normalizedPropertyOffersAndGUIDs.constructedNormalizedObject,
        },
        dataPropertiesCurrentListAll: dataPropertiesCurrentListAll.concat(
          normalizedPropertyOffersAndGUIDs.arrayContainingAllGUIDs,
        ),
        allViewings: {
          ...allViewings,
          ...normalizedViewingsAndGUIDs.constructedNormalizedObject,
        },
        allViewingsGUIDs: allViewingsGUIDs.concat(
          normalizedViewingsAndGUIDs.arrayContainingAllGUIDs,
        ),
      }
      return {
        ...defaultState,
        ...preloadedData,
        totalRecords: total,
        recordsOffset: Number(next_cursor),
        viewingsByDate: viewingGUIDsByDate || [],
      }

    case PRELOAD_PROPERTIES_AND_VIEWINGS_ERROR:
      return { ...state, mobilePropertiesError: error }

    case UPDATE_VIEWING:
      return {
        ...state,
        allViewings: {
          ...state.allViewings,
          [viewingData.guidID]: {
            ...state.allViewings[viewingData.guidID],
            startDate: viewingData.startDate,
            duration: viewingData.duration,
            published: viewingData.published,
            notify: viewingData.notify,
          },
        },
      }

    case ADD_VIEWING:
      const offerGUID = viewingData.offerGUID
      return {
        ...state,
        dataPropertiesCurrent: {
          ...state.dataPropertiesCurrent,
          [offerGUID]: {
            ...state.dataPropertiesCurrent[offerGUID],
            allViewings: state.dataPropertiesCurrent[
              offerGUID
            ].allViewings.concat(viewingData.guidID),
          },
        },
        allViewings: {
          ...state.allViewings,
          [viewingData.guidID]: {
            GUID: viewingData.guidID,
            ...viewingData,
          },
        },
        allViewingsGUIDs: state.allViewingsGUIDs.concat(viewingData.guidID),
      }

    case CANCEL_VIEWING:
      return {
        ...state,
        allViewings: {
          ...state.allViewings,
          [viewingData.guidID]: {
            ...state.allViewings[viewingData.guidID],
            cancelled: true,
          },
        },
      }

    case ARCHIVE_PROPERTY:
      const { guidID = '' } = propertyData.offers[0] || {}
      let viewingsOfOffer = state.dataPropertiesCurrent[guidID].allViewings
      let withRemovedViewings = Object.keys(state.allViewings).reduce(
        (object, key) => {
          if (!viewingsOfOffer.includes(key)) {
            object[key] = state.allViewings[key]
          }
          return object
        },
        {},
      )
      const withRemovedViewingGUIDs = state.allViewingsGUIDs.filter(
        (viewingGUID) => !viewingsOfOffer.includes(viewingGUID),
      )
      const withRemovedPropertyOffer = Object.keys(
        state.dataPropertiesCurrent,
      ).reduce((object, key) => {
        if (key !== guidID) {
          object[key] = state.dataPropertiesCurrent[key]
        }
        return object
      }, {})
      const withRemovedViewingsByDate = state.viewingsByDate.filter(
        (viewing) => !viewingsOfOffer.includes(viewing.GUID),
      )

      return {
        ...state,
        allViewingsGUIDs: withRemovedViewingGUIDs,
        allViewings: withRemovedViewings,
        dataPropertiesCurrentListAll: state.dataPropertiesCurrentListAll.filter(
          (offerGUID) => offerGUID !== guidID,
        ),
        dataPropertiesCurrent: withRemovedPropertyOffer,
        viewingsByDate: withRemovedViewingsByDate,
      }

    case RECEIVE_REGISTRANTS_FOR_VIEWING:
      const viewingRegistrants = [].concat(
        ...(registrants.data['viewingOnsiteRegistrants'] || []),
        ...(registrants.data['viewingRegistrants'] || []),
      )
      const newState = { ...state }
      const normalizedRegistrantsAndGUIDs = normalizeObjectByKeyName(
        viewingRegistrants,
        'guidID',
      )
      normalizedRegistrantsAndGUIDs.arrayContainingAllGUIDs.forEach(
        (registrantGUID) => {
          const { viewingID } =
            normalizedRegistrantsAndGUIDs.constructedNormalizedObject[
              registrantGUID
            ]
          if (state.allViewings[viewingID]['allRegistrants']) {
            if (
              !state.allViewings[viewingID]['allRegistrants'].includes(
                registrantGUID,
              )
            ) {
              newState.allViewings[viewingID]['allRegistrants'] =
                state.allViewings[viewingID]['allRegistrants'].concat(
                  registrantGUID,
                )
            }
          } else {
            newState.allViewings[viewingID]['allRegistrants'] = [registrantGUID]
          }
        },
      )
      const mergedDataCurrent = lodashMerge(
        {},
        newState.dataRegistrantsCurrent,
        normalizedRegistrantsAndGUIDs.constructedNormalizedObject,
      )
      const mergedDataListAll = lodashMerge(
        [],
        newState.dataRegistrantsCurrentListAll,
        normalizedRegistrantsAndGUIDs.arrayContainingAllGUIDs,
      )
      return {
        ...newState,
        dataRegistrantsCurrent: mergedDataCurrent,
        dataRegistrantsCurrentListAll: mergedDataListAll,
      }

    case ADD_REGISTRANT:
      const { guidID: registrantGUID, viewingID } = registrantData
      let concatenatedRegistrantsListForViewing = []
      if (state.allViewings[viewingID].allRegistrants) {
        concatenatedRegistrantsListForViewing =
          state.allViewings[viewingID].allRegistrants.concat(registrantGUID)
      } else {
        concatenatedRegistrantsListForViewing = [registrantGUID]
      }
      return {
        ...state,
        dataRegistrantsCurrent: {
          ...state.dataRegistrantsCurrent,
          [registrantGUID]: {
            ...registrantData,
          },
        },
        dataRegistrantsCurrentListAll:
          state.dataRegistrantsCurrentListAll.concat(registrantGUID),
        allViewings: {
          ...state.allViewings,
          [viewingID]: {
            ...state.allViewings[viewingID],
            allRegistrants: concatenatedRegistrantsListForViewing,
          },
        },
      }

    case UPDATE_REGISTRANT:
      const { guidID: updateRegistrantGUID } = registrantData
      return {
        ...state,
        dataRegistrantsCurrent: {
          ...state.dataRegistrantsCurrent,
          [updateRegistrantGUID]: {
            ...registrantData,
          },
        },
      }

    case REMOVE_REGISTRANT:
      const { registrationGUID, viewingGUID } = registrantData
      const withRemovedRegistrant = Object.keys(
        state.dataRegistrantsCurrent,
      ).reduce((object, key) => {
        if (key !== registrationGUID) {
          object[key] = state.dataRegistrantsCurrent[key]
        }
        return object
      }, {})
      const removedRegistrantGUIDFromViewing = state.allViewings[
        viewingGUID
      ].allRegistrants.filter(
        (registrantGUID) => registrantGUID !== registrationGUID,
      )
      return {
        ...state,
        allViewings: {
          ...state.allViewings,
          [viewingGUID]: {
            ...state.allViewings[viewingGUID],
            allRegistrants: removedRegistrantGUIDFromViewing,
          },
        },
        dataRegistrantsCurrentListAll:
          state.dataRegistrantsCurrentListAll.filter(
            (registrantGUID) => registrantGUID !== registrationGUID,
          ),
        dataRegistrantsCurrent: withRemovedRegistrant,
      }

    default:
      return state
  }
}

export default DataPropertiesAndViewings
