import React from 'react'

import Contacts from 'app/match/applicationReportPDF/pages/Snug-pdf-1/Contacts'
import Compliance from 'app/match/applicationReportPDF/pages/Snug-pdf-4/Compliance'
import Income from 'app/match/applicationReportPDF/pages/Snug-pdf-4/Income'
import Other from 'app/match/applicationReportPDF/pages/Snug-pdf-4/Other'
import OtherAttachments from 'app/match/applicationReportPDF/pages/Snug-pdf-4/OtherAttachments'
import Pets from 'app/match/applicationReportPDF/pages/Snug-pdf-4/Pets'
import StudentDetails from 'app/match/applicationReportPDF/pages/Snug-pdf-4/StudentDetails'
import TeamNotes from 'app/match/applicationReportPDF/pages/Snug-pdf-4/TeamNotes'

const SnugPdf4 = ({
  incomeInfo = {},
  studentsInfo = [],
  otherAttachmentsInfo,
  otherInfo = {},
  petInfo = [],
  petsNote,
  complianceInfo = {},
  contacts = [],
  applicationNotes = [],
  chosenApplicant,
  enableEnhancedDisclosure,
  agencyInfo,
}) => {
  return (
    <div>
      <div className="custom-row flex-direction-column-mobile">
        <div className="custom-column equal-basis no-break-section">
          <Income data={incomeInfo} />
          {studentsInfo && studentsInfo.length > 0 && (
            <StudentDetails data={studentsInfo} />
          )}
        </div>
        <div className="custom-column equal-basis ml0-mobile">
          <Contacts contacts={contacts} />
          <Pets data={petInfo} note={petsNote} />
          <OtherAttachments data={otherAttachmentsInfo} />
          <Other data={otherInfo} />
        </div>
      </div>
      <Compliance
        data={complianceInfo}
        chosenApplicant={chosenApplicant}
        enableEnhancedDisclosure={enableEnhancedDisclosure}
        agencyInfo={agencyInfo}
      />
      <TeamNotes data={applicationNotes} />
    </div>
  )
}

export default SnugPdf4
