import React, { useState } from 'react'

import { Form, Formik, useField, useFormikContext } from 'formik'
import moment from 'moment'

import { Alert, Box } from 'app/components/design-system-components'
import {
  Dropdown,
  InputDate,
  YesOrNoRadioGroup,
} from 'app/components/forms/forms'
import InputNumber from 'app/components/forms/input_number/input_number'
import { YesOrNoCheckboxComponent } from 'app/components/forms/radio_group/component'
import {
  originalVersionQuestions,
  questionsCodes,
} from 'app/constants/personal_reference/survey/survey-questions.constants'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { No, Yes } from 'app/match/rental_reference/rating_form/component'
import { CommentArea } from 'app/shared_components/form_component'
import { ErrorMessage } from 'app/shared_components/helpers'

const initialValues = {
  [questionsCodes.relationship]: 0,
  [questionsCodes.doSpentTimeAtHome]: '',
  [questionsCodes.relationshipStartYear]: '',
  [questionsCodes.wouldRentThem]: '',
  [questionsCodes.petsCount]: '',
  [questionsCodes.propertyCondition]: '',
  [questionsCodes.generalComment]: '',
}

const BooleanQuestion = ({ inputName, questionBody, errors }) => {
  const [field, meta, helpers] = useField(inputName)
  const { setValue } = helpers
  const inputError = Object.values(errors || {})[0]
  return (
    <YesOrNoRadioGroup
      id={inputName}
      label={questionBody}
      error={inputError}
      childrenWrapperClassName="pt0"
    >
      <YesOrNoCheckboxComponent
        id={Yes}
        label={Yes}
        checked={field.value === 'true'}
        onChange={() => setValue(field.value === 'true' ? 'false' : 'true')}
        componentClassName="yes left"
      />
      <YesOrNoCheckboxComponent
        id={No}
        label={No}
        checked={field.value === 'false'}
        onChange={() => setValue(field.value === 'false' ? 'true' : 'false')}
        componentClassName="no right"
      />
    </YesOrNoRadioGroup>
  )
}

const IntQuestion = ({ inputName, questionBody, errors }) => {
  const [field, meta, helpers] = useField(inputName)
  const { setValue } = helpers
  const inputError = Object.values(errors || {})[0]
  return (
    <InputNumber
      value={field.value}
      error={inputError}
      onChange={({ value }) => setValue(value)}
      inputClass="width100"
      id={inputName}
      label={questionBody}
      isOptional
    />
  )
}

const DateQuestion = ({ inputName, questionBody, errors }) => {
  const [field, meta, helpers] = useField(inputName)
  const { setValue } = helpers
  const pastDatesValidation = function (current) {
    return current.isBefore(moment())
  }
  const inputError = Object.values(errors || {})[0]
  return (
    <InputDate
      label={questionBody}
      value={field.value}
      onChange={({ value }) => {
        const year = new Date(value).getFullYear()
        setValue(!isNaN(year) ? year.toString() : value)
      }}
      isValidDate={pastDatesValidation}
      customisedDateFormat="YYYY"
      placeholder="Select a year"
      id={inputName}
      containerClassName="width100"
      componentClass="rental-date-wrapper"
      error={inputError}
      readOnly={false}
    />
  )
}

const SelectQuestion = ({ inputName, questionBody, options }) => {
  const [field, meta, helpers] = useField(inputName)
  const { setValue } = helpers
  return (
    <Dropdown
      label={questionBody}
      value={field.value}
      options={options}
      onChange={({ value }) => setValue(value)}
      id={inputName}
      valueType="number"
      inputClass="width100"
    />
  )
}

const TextQuestion = ({
  inputName,
  questionBody,
  rows = 1,
  errors,
  placeholder = '',
}) => {
  const [field, meta, helpers] = useField(inputName)
  const { setValue } = helpers
  const inputError = Object.values(errors || {})[0]
  return (
    <CommentArea
      label={questionBody}
      name={inputName}
      rows={rows}
      value={field.value}
      onChange={({ value }) => setValue(value)}
      error={inputError}
      placeholder={placeholder}
    />
  )
}

const ReferenceSurveyForm = ({ onSubmit }) => {
  const [responseErr, setResponseErr] = useState('')
  const submitSurveyForm = (values) => {
    const readyForm = transformFormData(values)
    return onSubmit(readyForm)
      .then(() => setResponseErr(''))
      .catch(({ message }) => setResponseErr(message))
  }
  return (
    <>
      {responseErr && <Alert variant="warningWithBg">{responseErr}</Alert>}
      <Formik
        initialValues={initialValues}
        validate={formValidation}
        onSubmit={submitSurveyForm}
        validateOnMount
      >
        <FormBody />
      </Formik>
    </>
  )
}

const FormBody = ({ responseError }) => {
  const formikContext = useFormikContext()
  const { values, errors, submitForm, isSubmitting, isValid, touched } =
    formikContext
  const getInputErr = (inputName) => touched[inputName] && errors[inputName]

  const relationshipQuestion = {
    ...originalVersionQuestions[questionsCodes.relationship],
    code: questionsCodes.relationship,
  }
  const doSpentQuestion = {
    ...originalVersionQuestions[questionsCodes.doSpentTimeAtHome],
    code: questionsCodes.doSpentTimeAtHome,
  }
  const wouldRentQuestion = {
    ...originalVersionQuestions[questionsCodes.wouldRentThem],
    code: questionsCodes.wouldRentThem,
  }
  const petsCountQuestion = {
    ...originalVersionQuestions[questionsCodes.petsCount],
    code: questionsCodes.petsCount,
  }
  const relationStartYearQuestion = {
    ...originalVersionQuestions[questionsCodes.relationshipStartYear],
    code: questionsCodes.relationshipStartYear,
  }
  const propertyConditionQuestion = {
    ...originalVersionQuestions[questionsCodes.propertyCondition],
    code: questionsCodes.propertyCondition,
    display:
      values[questionsCodes.doSpentTimeAtHome] &&
      values[questionsCodes.doSpentTimeAtHome] === 'true',
  }
  const generalCommentQuestion = {
    ...originalVersionQuestions[questionsCodes.generalComment],
    code: questionsCodes.generalComment,
  }

  return (
    <Form>
      <Box my="20px">
        <span className="fw500">{relationshipQuestion.body}</span>
        <SelectQuestion
          inputName={relationshipQuestion.code}
          questionBody={relationshipQuestion.body}
          options={relationshipQuestion.options}
        />
      </Box>
      <Box my="20px">
        <div className="fw500 mb10">{relationStartYearQuestion.body}</div>
        <DateQuestion
          inputName={relationStartYearQuestion.code}
          questionBody={'Since'}
          errors={getInputErr(relationStartYearQuestion.code)}
        />
      </Box>
      <Box my="20px">
        {doSpentQuestion && (
          <BooleanQuestion
            inputName={doSpentQuestion.code}
            questionBody={doSpentQuestion.body}
            errors={getInputErr(doSpentQuestion.code)}
          />
        )}
      </Box>
      {propertyConditionQuestion.display && (
        <Box my="20px">
          <span className="fw500">{propertyConditionQuestion.body}</span>
          <TextQuestion
            inputName={propertyConditionQuestion.code}
            errors={getInputErr(propertyConditionQuestion.code)}
          />
        </Box>
      )}
      <Box my="20px">
        <span className="fw500">{petsCountQuestion.body}</span>
        <TextQuestion
          inputName={petsCountQuestion.code}
          errors={getInputErr(petsCountQuestion.code)}
          placeholder="eg. 1 dog, 1 cat"
        />
      </Box>
      <Box my="20px">
        {wouldRentQuestion && (
          <BooleanQuestion
            inputName={wouldRentQuestion.code}
            questionBody={wouldRentQuestion.body}
            errors={getInputErr(wouldRentQuestion.code)}
          />
        )}
      </Box>
      <Box my="20px">
        <span className="fw500">{generalCommentQuestion.body}</span>
        <TextQuestion
          inputName={generalCommentQuestion.code}
          errors={getInputErr(generalCommentQuestion.code)}
        />
      </Box>

      <button
        className="mt15 mb30"
        disabled={isSubmitting}
        onClick={submitForm}
      >
        Submit
        <i className={isSubmitting ? 'fa fa-spinner fa-pulse' : ''} />
      </button>
    </Form>
  )
}

const transformFormData = (form) => {
  let answers = []
  for (const [code, formAnswer] of Object.entries(form)) {
    const answerValue =
      code === questionsCodes.relationship
        ? originalVersionQuestions[questionsCodes.relationship].options[
            formAnswer
          ]
        : formAnswer.toString()
    answers = [
      ...answers,
      {
        code,
        answerValue,
        questionType: originalVersionQuestions[code].type,
      },
    ]
  }
  return answers.filter(({ answerValue }) => !!answerValue)
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case questionsCodes.wouldRentThem: {
      return {
        required: !val && 'Please answer this question',
      }
    }
    case questionsCodes.relationshipStartYear: {
      return {
        required: !val && 'Please answer this question',
      }
    }
    default:
      return {}
  }
}

export const formValidation = formValidationBuilder(fieldValidation)

export default ReferenceSurveyForm
