import { Alert, Box } from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { deleteBorrower } from 'app/services/http/teams/key-logger'
import * as snugNotifier from 'app/services/snugNotifier'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

type Props = {
  borrower: any
  teamID: string
  onSubmission: () => void
  closeModal: (resp?: any) => void
}

export const DeleteBorrowerConfirmation = ({
  borrower,
  teamID,
  onSubmission,
  closeModal,
}: Props) => {
  const {
    loadingStates: deletionLoadingStates,
    loadingStatesHelpers: deletionLoadingStateHelpers,
  } = useLoadingStates()

  const onSubmit = () => {
    deletionLoadingStateHelpers.startLoading()
    return deleteBorrower(teamID, borrower.guid)
      .then((response: any) => {
        deletionLoadingStateHelpers.markDoneSuccessfully()
        snugNotifier.success('Borrower deleted successfully')
        onSubmission()
        closeModal(response)
      })
      .catch((err: any) => {
        deletionLoadingStateHelpers.setError(err)
      })
  }

  const isSubmitting = deletionLoadingStates.state === loadingStatesIds.LOADING

  return (
    <GenericModal
      onDismiss={() => closeModal()}
      title="Delete borrower"
      modalFooter={
        <GenericModalFooter
          primaryBtnConfig={{
            title: 'Confirm',
            onClick: onSubmit,
            props: {
              loading: isSubmitting,
            },
          }}
          secondaryBtnConfig={{
            title: 'Cancel',
            onClick: () => closeModal(),
          }}
        />
      }
    >
      {!!deletionLoadingStates.error && (
        <Alert variant="primaryWithBg" mb={4}>
          {(deletionLoadingStates.error as any).message}
        </Alert>
      )}
      <Box mb={2} lineHeight={theme.lineHeights.encrption}>
        Are you sure you wish to delete the borrower {borrower.first_name}{' '}
        {borrower.last_name}? This can not be undone.
      </Box>
    </GenericModal>
  )
}
