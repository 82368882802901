import React from 'react'

import { Link } from 'react-router-dom'

import filesListIcon from 'app/assets/icons/files-list.svg'
import mellonIcon from 'app/assets/icons/mellon.png'
import DashboardItem from 'app/dashboard/components/dashboard_item'
import DashboardModal from 'app/dashboard/components/dashboard_modal'
import * as utils from 'app/utils'

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }

  componentDidMount() {
    const { fetchDashboard } = this.props
    fetchDashboard()
  }

  toggleModal() {
    return (event) => {
      const { modalOpen } = this.state
      this.setState({ modalOpen: !modalOpen })
    }
  }

  render() {
    let { modalOpen } = this.state
    let { currentUser, bondCovers } = this.props
    if (bondCovers.length == null) {
      return (
        <div className="two-col-box-flex-row sm64">
          <div className="col-first">
            <h3 className="mb25">Welcome to Snug!</h3>
            <p>
              Hi {currentUser && currentUser.firstName}, it seems your dashboard
              is empty and you currently have no BondCover Certificates.
            </p>
            <button className="btn mb35" onClick={this.toggleModal()}>
              + Apply for BondCover
            </button>

            {modalOpen && <DashboardModal toggleModal={this.toggleModal()} />}

            <div className="img-full mt20 mb20">
              <img src={mellonIcon} />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <h3 className="mb15">Dashboard</h3>
          <button className="btn mb35" onClick={this.toggleModal()}>
            + Create BondCover
          </button>

          {modalOpen && <DashboardModal toggleModal={this.toggleModal()} />}

          <div className="table mb35">
            <div className="cell-m pr15">
              <img src={filesListIcon} height="32" />
            </div>
            <div className="cell-m-100">
              <h4 className="fs20">
                BondCover Certificates ({bondCovers.length && bondCovers.length}
                )
              </h4>
            </div>
          </div>

          {bondCovers.map((i) => (
            <DashboardItem
              friendlyName={i.address.friendlyName}
              suburb={i.address.suburb}
              state={i.address.state}
              postcode={i.address.postcode}
              leaseStart={i.startDate}
              leaseEnd={i.endDate}
              request={i.request}
              role={i.role}
              requestID={i.requestID}
              key={i.requestID}
              applicationStatus={i.status}
              tenant={i.tenant}
            />
          ))}
        </div>
      </div>
    )
  }
}

export default DashboardContainer
