import styled from 'styled-components'

import { FieldWrapper } from 'app/forms/FieldWrapper'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const StyledFieldWrapper = styled(FieldWrapper)`
  label:nth-child(2) {
    margin-bottom: 5px;
    height: 50px;
  }

  label:not(.error):nth-child(2) {
    border: 1px solid
      ${(props) =>
        props.hasOwnProperty('validConfirmation')
          ? !props.validConfirmation
            ? theme.colors.warning500
            : theme.colors.success600
          : theme.colors.gray500} !important;
  }

  /* internal tel component classes manipulation */

  .iti-arrow {
    display: none !important;
  }

  .flag-container {
    pointer-events: none;
  }
`

export default StyledFieldWrapper
