import React from 'react'

import { Formik } from 'formik'
import styled from 'styled-components'

import {
  Box,
  Flex,
  HorizontalLine,
  IconButton,
} from 'app/components/design-system-components'
import { ClearRounded } from 'app/components/design-system-components/icons/content'
import { Heading } from 'app/components/design-system-components/typography'
import {
  formFields,
  formValidation,
} from 'app/features/teams/key-logger/CheckoutKeySetModal/AddressBorrowerSelection/NewBorrowerModal/form.utils'
import { FormBody } from 'app/features/teams/key-logger/CheckoutKeySetModal/AddressBorrowerSelection/NewBorrowerModal/FormBody'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ModalContentWrapper } from 'app/modals/modal-styled'

const StyledModalContentWrapper = styled(ModalContentWrapper)`
  max-width: 516px;

  @media (min-width: 768px) {
    width: 516px;
  }
`

export const NewBorrowerModal = ({ borrower, closeModal }) => {
  const onDismiss = () => {
    closeModal()
  }

  const onSubmit = (values) => {
    closeModal(values)
  }

  const initialValues = borrower || {
    [formFields.firstName]: '',
    [formFields.lastName]: '',
    [formFields.email]: '',
    [formFields.phone]: '',
    [formFields.company]: '',
  }

  return (
    <Box className="modal-fullscreen" bg={theme.colors.overlay}>
      <StyledModalContentWrapper maxWidth="516px">
        <Box p={theme.space[6] + 'px'}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            mb={theme.space[6] + 'px'}
          >
            <Heading level={3} displayingLevel={3}>
              New Borrower
            </Heading>

            <IconButton
              IconCmp={ClearRounded}
              iconProps={{ fontSize: theme.fontSizes[10] + 'px' }}
              variant="flat"
              onClick={onDismiss}
            />
          </Flex>
          <HorizontalLine />
        </Box>

        <Box p={theme.space[6] + 'px'} pt="0">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={formValidation}
            validateOnBlur
            validateOnChange
          >
            {(props) => <FormBody formBag={props} />}
          </Formik>
        </Box>
      </StyledModalContentWrapper>
    </Box>
  )
}
