import React from 'react'

import { Link } from 'react-router-dom'

import BankAccountWidget from 'app/bond_cover/renters/hub/bankaccount_widget'
import IdentityDocWidget from 'app/bond_cover/renters/hub/identitydoc_widget'
import LeaseWidget from 'app/bond_cover/renters/hub/lease_widget'
import ManagerWidget from 'app/bond_cover/renters/hub/manager_widget'
import PaymentWidget from 'app/bond_cover/renters/hub/payment_widget'
import SignatureWidget from 'app/bond_cover/renters/hub/signature_widget'
import TenantsWidget from 'app/bond_cover/renters/hub/tenants_widget'
import WithdrawButton from 'app/bond_cover/renters/sharedComponent/withdraw_button'
import UnsavedMessage from 'app/shared_components/unsaved_message'

const HubForm = ({
  type,
  isNewLease,
  request,
  currentUser,
  currentLease,
  quote,
  manager,
  tenants,
  signature,
  identityDoc,
  bankAccount,
  card,
  disabled,
  submitClicked,
  apiError,
  show,
  toggleShow,
  submit,
  unsavedChanges,
  onWithdrawClicked,
}) => {
  return (
    <div className="two-col-box-flex-row sm64">
      <div className="col-first">
        <h3>
          Hi{currentUser ? ' ' + currentUser.firstName : ''}, welcome to your
          Snug BondCover application.
        </h3>
        <p className="pt10">
          We need a few details about you and your rental to process your
          application.
        </p>
        <LeaseWidget
          type={type}
          currentLease={currentLease}
          request={request}
        />
        <ManagerWidget
          manager={manager}
          request={request}
          currentLease={currentLease}
        />
        {currentLease && currentLease.isMultiTenantLease ? (
          <TenantsWidget
            request={request}
            currentLease={currentLease}
            tenants={tenants}
          />
        ) : (
          ''
        )}
        <SignatureWidget
          signature={signature}
          tenants={tenants}
          request={request}
          currentLease={currentLease}
          role="primary"
        />

        <div className="faq-box bg-white mt0 pt10">
          <div className="panel" onClick={toggleShow()}>
            <a className={show ? '' : 'collapsed'} role="button">
              {type !== 'new' && !isNewLease
                ? 'Want your refund faster?'
                : 'Want to get BondCover faster?'}
            </a>
            <p className="mb0">Fill out your ID and Payment Details</p>
          </div>
        </div>

        {show && (
          <div>
            <IdentityDocWidget
              identityDoc={identityDoc}
              request={request}
              currentLease={currentLease}
              role="primary"
            />
            {isNewLease ? (
              <PaymentWidget
                card={card}
                currentLease={currentLease}
                request={request}
              />
            ) : (
              <BankAccountWidget
                bankAccount={bankAccount}
                request={request}
                currentLease={currentLease}
                role="primary"
              />
            )}
          </div>
        )}
        <div className="h0"></div>

        <ul className="snug-tips">
          <li className={identityDoc && bankAccount ? 'hidden' : ''}>
            Filling out your ID details and payment details is mandatory, but
            you can do it later. Doing it now it will speed up the process.
          </li>
          <li
            className={
              request && request.submissionStatus !== 0 ? 'hidden' : ''
            }
          >
            Note that your application will be sent to your property manager for
            approval.
          </li>
        </ul>

        {!!request && request.idCheckStatus === 0 ? (
          <WithdrawButton onWithdrawClicked={onWithdrawClicked} />
        ) : (
          ''
        )}

        <div className={apiError ? 'alert alert-danger xs-mb10' : ''}>
          {apiError}
        </div>

        <div className="bc-refund-box">
          <div className="bc-refund-box-summary-wrapper">
            <div className="text">
              <div className="bc-refund-header">
                {!isNewLease ? 'You will receive' : 'BondCover fee:'}
              </div>
              <div className="bc-refund-amount">
                <span>
                  $
                  {quote && quote.guidID
                    ? !isNewLease
                      ? (quote.bondAmount - quote.premium.toFixed(2)).toFixed(2)
                      : quote.premium.toFixed(2)
                    : '0.00'}
                </span>
              </div>
              <div className="bc-refund-footer">
                {!isNewLease
                  ? quote && quote.guidID
                    ? 'Includes Snug fee: $' + quote.premium.toFixed(2)
                    : 'fill out your application'
                  : 'Fee for first year of lease, renewal discounts apply'}
              </div>
            </div>
            <div className="controls">
              <button
                disabled={disabled}
                className={submitClicked ? 'clicked' : ''}
                onClick={() => submit()}
              >
                Submit
                <i className={submitClicked ? 'fa fa-spinner fa-pulse' : ''} />
              </button>
            </div>
          </div>
        </div>
        <UnsavedMessage
          unsavedChanges={unsavedChanges}
          message={
            'Are you sure? You will need to submit a new application if you wish to continue with this application'
          }
        />
      </div>
    </div>
  )
}

export default HubForm
