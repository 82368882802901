import { X } from 'app/sm/helpers'
import {
  PERSIST_MANAGER_NAME,
  RECEIVE_APPLICATIONS_COUNT,
  RECEIVE_ARCHIVED_PROPERTIES,
  RECEIVE_FIRST_PAGE_CURSOR,
  RECEIVE_PROPERTIES,
  RECEIVE_PUBLIC_PROPERTIES,
  TOGGLE_SPINNER,
  WITHDRAW_MODAL_OFF,
  WITHDRAW_MODAL_ON,
} from 'app/sm/properties/properties_actions'

const _defaultState = {
  properties: [],
  publicProperties: [],
  archivedProperties: [],
  withdrawModalId: null, // There should go an id for a specific property
  error: '',
  applicationsCount: {},
  spinner: false,
  responseMetadata: {},
  firstPageCursor: {},
  persistedManager: '',
}

const ApplicationsReducer = (state = _defaultState, action) => {
  let {
    type,
    properties,
    propertyId,
    applicationsCount,
    spinner,
    responseMetadata,
    firstPageCursor,
    manager,
  } = action

  Object.freeze(state)

  let newState = X({}, state)

  switch (type) {
    case RECEIVE_PROPERTIES:
      return X(newState, { properties, responseMetadata })
    case RECEIVE_PUBLIC_PROPERTIES:
      return X(newState, { publicProperties: properties, responseMetadata })
    case RECEIVE_ARCHIVED_PROPERTIES:
      return X(newState, { archivedProperties: properties, responseMetadata })
    case WITHDRAW_MODAL_OFF:
      return X(newState, { withdrawModalId: null })
    case WITHDRAW_MODAL_ON:
      return X(newState, { withdrawModalId: propertyId })
    case RECEIVE_APPLICATIONS_COUNT:
      return Object.assign(newState, { applicationsCount })
    case TOGGLE_SPINNER:
      return Object.assign(newState, { spinner })
    case RECEIVE_FIRST_PAGE_CURSOR:
      return Object.assign(newState, { firstPageCursor })
    case PERSIST_MANAGER_NAME:
      return Object.assign(newState, { persistedManager: manager })
    default:
      return state
  }
}

export default ApplicationsReducer
