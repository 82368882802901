import React from 'react'

import DeclineForm from 'app/bond_cover/managers/review/decline_form'

class DeclineFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      declineReason: null,
      disabled: false,
      description: '',
      secret: '',
    }
  }

  UNSAFE_componentWillMount() {
    let { fetchBondCoverReview, location } = this.props
    let search = new URLSearchParams(location.search)
    let requestID = search.get('requestid')
    let secret = search.get('secret')
    this.setState({ secret: secret })
    fetchBondCoverReview(requestID, secret)
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ disabled: false })
  }

  decline = () => {
    this.setState({ disabled: true })
    let { decline, backUrl } = this.props
    let { secret } = this.state
    let navigateUrl =
      window.location.href.indexOf('agency') > 0 ? backUrl : '/bc/ownerdecline'
    let search = new URLSearchParams(window.location.search)
    let requestId = search.get('requestid')
    decline(this.state, requestId, secret, '', navigateUrl)
  }

  update = (field) => {
    return (event) => {
      let { value } = event.target
      this.setState({ [field]: value })
    }
  }

  updateNumber = (field) => {
    return (event) =>
      this.setState({
        [field]: parseInt(event.currentTarget.value),
      })
  }

  render() {
    let { decline } = this.props
    let { declineReason, description, disabled, secret } = this.state
    return (
      <DeclineForm
        declineReason={declineReason}
        description={description}
        disabled={disabled}
        update={this.update}
        updateNumber={this.updateNumber}
        decline={decline}
        secret={secret}
      />
    )
  }
}

export default DeclineFormContainer
