import { AGENCY_USER_LEASE_CONSULTANT } from 'app/utils/roles'

export const isMobile = () => window.innerWidth < 576

export const isTeamMemberNotALeasingAgent = (agencyUser) => {
  let isNotLeaseConsultant = false
  if (agencyUser !== null) {
    isNotLeaseConsultant = agencyUser.roles.some((agency) => {
      return !(agency.roleID === AGENCY_USER_LEASE_CONSULTANT)
    })
    if (agencyUser.roles.length === 0) {
      isNotLeaseConsultant = true
    }
  }
  return isNotLeaseConsultant
}
