import React from 'react'

import { PropertyItem } from 'app/agency/enquiries/search_property_list_component'

export const PropertySearchAndDropDown = (propertySearchArgs) => {
  const {
    searchText,
    onSearchChangeForEnquirer,
    isFlexibleTimePicked = false,
  } = propertySearchArgs
  return (
    <div>
      <div className="property-search-filter-widget mt10">
        <div className="input-box mb20">
          <div className="prefix">
            {/*<i className="icon-search" />*/}
            <i
              className={`icon-search ${
                !isFlexibleTimePicked && 'disable-content'
              }`}
            />
          </div>
          <input
            type="text"
            placeholder="Property address"
            value={searchText}
            onChange={onSearchChangeForEnquirer}
          />
        </div>
      </div>
    </div>
  )
}

export const ContactPropertiesContainer = ({
  properties,
  onRemoveButtonClick,
  isPropertyAttached,
  title = '',
  noPropertyText = '',
  isFlexibleTimePicked = false,
  onExcludedPropertyDateEntriesChanged,
  disabled = false,
}) => {
  return (
    <div>
      <h5
        className={`mb10 mt10 ${!isFlexibleTimePicked && 'disable-content'} ${
          disabled && 'disable-content'
        }`}
      >
        {title}
      </h5>
      {properties === undefined ||
        (properties.length === 0 && <h6>{noPropertyText}</h6>)}
      {properties &&
        properties.length > 0 &&
        properties.map((propertyData, index) => {
          return (
            properties && (
              <PropertyItem
                key={index}
                propertyItem={propertyData}
                weeklyRent={propertyData.offers[0].weeklyRent}
                availableFrom={propertyData.offers[0].availableFrom}
                onRemoveButtonClick={onRemoveButtonClick}
                showRemoveButton={true}
                showAddButton={false}
                isPropertyAttached={isPropertyAttached}
                showExcludeScheduleSection={true}
                onExcludedPropertyDateEntriesChanged={
                  onExcludedPropertyDateEntriesChanged
                }
                disabled={disabled}
              />
            )
          )
        })}
    </div>
  )
}
