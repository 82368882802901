import React, { useEffect, useState } from 'react'

import {
  PropertyItem,
  SearchedPropertyResults,
} from 'app/agency/enquiries/search_property_list_component'
import { PropertySearchAndDropDown } from 'app/agency/enquiries/team_enquiries_container'
import containerClosed from 'app/assets/icons/container-closed.svg'
import containerOpen from 'app/assets/icons/container-open.svg'
import {
  addEnquirerWithPropertyAsManager,
  fetchTeamProperties,
} from 'app/bond_cover/agency/agency_actions'
import { Box } from 'app/components/design-system-components'
import Modal from 'app/components/design-system-components/Modal'
import * as Form from 'app/components/forms/forms'
import { filtersIds } from 'app/features/teams/messages/table-filters/filters.config'
import { propertyFilterDataExtractor } from 'app/features/teams/messages/table-filters/PropertyFilter'
import { bedroomsDropdown } from 'app/pages/teams/messages/helpers/constants'
import * as constants from 'app/pages/teams/messages/helpers/constants'
import * as snugNotifier from 'app/services/snugNotifier'
import * as FormComponent from 'app/shared_components/form_component'
import Spinner from 'app/sm/common/spinner'
import { getPropertyManagerSummary } from 'app/sm/property_details/property_details_access'
import { PreQualifyInfoContainer } from 'app/sm/property_details/property_details_container'
import { yearsFrom1950WithGuidAndName } from 'app/utils/datetime/helpers'
import { STANDARD_TIMEOUT, useDebounce } from 'app/utils/hooks/debounce'
import { isEmpty } from 'app/utils/objects/helpers'
import { NUMBER_TYPE_MOBILE } from 'app/utils/phonenumber/helpers'
import * as vaccinationHelpers from 'app/utils/vaccination/helpers'
import VaccinationRadioComponent from 'app/utils/vaccination/radio_component'

const AddEnquirerModal = ({
  teamGUID,
  closeModal,
  refreshMessages,
  filtersState,
}) => {
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebounce(searchText, STANDARD_TIMEOUT)
  const [searchInProgress, setSearchInProgress] = useState(false)
  const [propertySearchResults, setPropertySearchResults] = useState({})
  const [selectedPropertyGUID, setSelectedPropertyGUID] = useState('')
  const [selectedPropertyDetails, setSelectedPropertyDetails] = useState({})
  const [
    vaccinationQuestionsContainerOpened,
    setVaccinationQuestionsContainerOpened,
  ] = useState(false)
  const [addEnquirerModalValues, setAddEnquirerModalValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    weeklyIncome: '',
    currentResidenceStartYear: '',
    currentResidenceStartMonth: '',
    weeklyHousingPayment: '',
    recentOverseasVisit: '',
    hasColdSymptoms: '',
    bedrooms: '',
    rent: '',
    postcode: '',
    existedInterestedProperties: [],
    existedOwnedProperties: [],
    covid19VaccinationStatus: '',
  })
  const [inputErrors, setInputErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    weeklyIncome: '',
    currentResidenceStartYear: '',
    currentResidenceStartMonth: '',
    weeklyHousingPayment: '',
    recentOverseasVisit: '',
    hasColdSymptoms: '',
    bedrooms: '',
    rent: '',
    postcode: '',
    covid19VaccinationStatus: '',
  })
  const [submittingForm, setSubmittingForm] = useState(false)

  useEffect(
    () => {
      if (debouncedSearchText) {
        setSearchInProgress(true)
        // NOTE: fetchTeamProperties is defined with redux dispatch in mind
        // so we need to call the returned function without a parameter
        fetchTeamProperties(teamGUID, {
          searchText,
        })(undefined)
          .then((properties) => {
            setSearchInProgress(false)
            setPropertySearchResults(properties.properties)
          })
          .catch((error) => {
            snugNotifier.error(error)
          })
          .finally(() => {
            setSearchInProgress(false)
          })
      } else {
        setPropertySearchResults([])
        setSearchInProgress(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchText], // Only call effect if debounced search term changes
  )

  useEffect(() => {
    if (filtersState?.[filtersIds.property]) {
      const properties = propertyFilterDataExtractor(
        filtersState[filtersIds.property],
      ).properties

      if (properties?.length > 0) fetchFilteredProperties(properties)
    }
  }, [filtersState])

  const onModalValueChange = (field) => {
    return (data) => {
      setAddEnquirerModalValues({
        ...addEnquirerModalValues,
        [field]: data.value,
      })
      setInputErrors({
        ...inputErrors,
        [field]: data.error,
      })
    }
  }

  const onChangeRadioGroup = (radioGroupID) => {
    return (event) => {
      const { target } = event
      const { id } = target
      setAddEnquirerModalValues({
        ...addEnquirerModalValues,
        [radioGroupID]: id,
      })
      setInputErrors({
        ...inputErrors,
        [radioGroupID]: '',
      })
    }
  }

  const onChangeRadioGroupCovid19VaccinationStatus = (radioGroupID) => {
    return (event) => {
      const { target } = event
      const { id } = target
      setAddEnquirerModalValues({
        ...addEnquirerModalValues,
        [radioGroupID]: vaccinationHelpers.vaccinationStatusMap[id],
      })
      setInputErrors({
        ...inputErrors,
        [radioGroupID]: '',
      })
    }
  }

  const onSearchChange = (event) => {
    const { value } = event.target
    setSearchText(value)
  }

  function onAddProperty() {
    return (guidID) => {
      const { data: propertiesWithExtendedDetails } =
        propertySearchResults || {}
      const propertyWithExtendedDetails =
        propertiesWithExtendedDetails &&
        propertiesWithExtendedDetails.find(
          (propertyInfo) => propertyInfo.property.guidID === guidID,
        )

      setSelectedPropertyGUID(guidID)
      setSelectedPropertyDetails(propertyWithExtendedDetails.property)
    }
  }

  function onRemoveProperty() {
    setSelectedPropertyGUID('')
    setSelectedPropertyDetails({})
  }

  function isFormValid() {
    let isValid = true
    let errors = {}
    const emailOrMobileMessage = 'Email or mobile is required'
    const requiredMessage = 'Required'
    const mandatoryFields = ['firstName', 'lastName']

    Object.keys(addEnquirerModalValues)
      .filter((value) => mandatoryFields.includes(value))
      .forEach((value) => {
        if (addEnquirerModalValues[value] === '') {
          isValid = false
          errors[value] = requiredMessage
        }
      })

    if (
      addEnquirerModalValues['email'] === '' &&
      addEnquirerModalValues['contactNumber'] === ''
    ) {
      isValid = false
      errors.email = emailOrMobileMessage
      errors.contactNumber = emailOrMobileMessage
    }

    if (!isValid) {
      setInputErrors({
        ...inputErrors,
        ...errors,
      })
    }

    return isValid
  }

  function preparePayload() {
    // a copy, originally from here: https://github.com/snugco/snug-client-reactjs/blob/c486379fa8e54caf4ff75c6f6b2f23899fb409d9/src/app/agency/enquiries/team_enquiries_container.jsx#L754

    let form = { ...addEnquirerModalValues }
    form.propertyGUID = selectedPropertyGUID
    form.agencyID = teamGUID
    form.bedrooms = form.bedrooms ? bedroomsDropdown[form.bedrooms] : null
    form.bedrooms = form.bedrooms === '5+' ? 6 : form.bedrooms

    // Remove null, undefined, and empty strings
    const cleanForm = Object.keys(form)
      .filter((k) => form[k] !== null && form[k] !== '')
      .reduce((obj, key) => {
        obj[key] = form[key]
        return obj
      }, {})

    return {
      ...cleanForm,
      ...(cleanForm.bedrooms && { bedrooms: parseInt(cleanForm.bedrooms) }),
      ...(cleanForm.rent && { rent: parseFloat(cleanForm.rent) }),
    }
  }

  function onSaveEnquirer() {
    const isValid = isFormValid()
    if (!isValid) {
      const updateRequiredMessage = 'Please update fields'
      snugNotifier.error(updateRequiredMessage)
      return
    }
    const payload = preparePayload()
    addEnquirerWithPropertyAsManager(payload)()
      .then(() => {
        snugNotifier.success('Success')
        closeModal()
        refreshMessages()
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
      .finally(() => {
        setSubmittingForm(false)
      })
  }

  function isEmptySearchResults(propertySearchResults) {
    return (
      propertySearchResults &&
      propertySearchResults.data &&
      propertySearchResults.data.length === 0
    )
  }

  const fetchFilteredProperties = async (properties) => {
    setSearchInProgress(true)
    try {
      const propertiesSummary = await Promise.all(
        properties.map(async ({ guidID = '' }) => {
          if (guidID) {
            const propertyManagerSummary = await getPropertyManagerSummary(
              guidID,
            )
            const { managerSummary } = propertyManagerSummary || {}
            const { property } = managerSummary || {}
            return property
          }
        }),
      )

      if (propertiesSummary.length > 0) {
        setSelectedPropertyGUID(propertiesSummary[0].guidID)
        setSelectedPropertyDetails(propertiesSummary[0])
      }
      setSearchInProgress(false)
    } catch (error) {
      setSearchInProgress(false)
      snugNotifier.error(error?.message || 'Error fetching filtered property')
    }
  }

  const displaySearch =
    !!propertySearchResults &&
    !(selectedPropertyDetails && selectedPropertyGUID)
  const displaySearchResults =
    displaySearch && !searchInProgress && !!propertySearchResults
  const displayNoSearchResults =
    displaySearch &&
    !searchInProgress &&
    debouncedSearchText !== '' &&
    isEmptySearchResults(propertySearchResults)

  const displaySelectedProperty =
    !!selectedPropertyDetails &&
    !!selectedPropertyGUID &&
    !isEmpty(selectedPropertyDetails)

  const modalBody = (
    <div>
      <Box>
        <h5>Search and select property (optional)</h5>
      </Box>
      {displaySearch && (
        <div className="mt10">
          <PropertySearchAndDropDown
            placeholder="Search street name or suburb"
            value={searchText}
            onSearchChangeForEnquirer={onSearchChange}
            propertySearchResults={propertySearchResults}
            onInputBlur={undefined}
            hideResults={false}
          />
        </div>
      )}
      {searchInProgress && <Spinner />}
      {displaySearchResults && (
        <SearchedPropertyResults
          propertySearchResults={propertySearchResults}
          onAddButtonClick={onAddProperty}
          hideResults={false}
          isPropertyAttached={() => {}}
        />
      )}
      {displayNoSearchResults && <Box mb="24px">No results</Box>}
      {displaySelectedProperty && (
        <PropertyItem
          propertyItem={selectedPropertyDetails}
          weeklyRent={selectedPropertyDetails.offers[0].weeklyRent}
          availableFrom={selectedPropertyDetails.offers[0].availableFrom}
          onRemoveButtonClick={onRemoveProperty}
          showRemoveButton={true}
          showAddButton={false}
          isPropertyAttached={true}
        />
      )}
      <Box mb="16px">
        <h5>Enquirer details</h5>
      </Box>
      <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
        <div className="col-md-6 p0 width100">
          <Form.InputName
            value={addEnquirerModalValues.firstName}
            error={inputErrors.firstName}
            label="First name*"
            onChange={onModalValueChange('firstName')}
            labelClass="top18"
            inputClass="width100"
            componentClass="margin-profile-item-left"
          />
        </div>
        <div className="col-md-6 p0 width100">
          <Form.InputTextOptional
            value={addEnquirerModalValues.lastName}
            error={inputErrors.lastName}
            label="Last name"
            onChange={onModalValueChange('lastName')}
            labelClass="top18"
            inputClass="width100"
            componentClass="margin-profile-item-right"
            isOptional
          />
        </div>
      </Form.ProfilePagesRowLayout>
      <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
        <div className="col-md-6 p0 width100">
          <Form.InputEmail
            value={addEnquirerModalValues.email}
            error={inputErrors.email}
            label="Email"
            onChange={onModalValueChange('email')}
            labelClass="top18"
            inputClass="width100"
            componentClass="margin-profile-item-left"
            isOptional
          />
        </div>
        <div className="col-md-6 p0 width100">
          <Form.InputPhoneNumber
            label="Mobile number"
            value={addEnquirerModalValues.contactNumber}
            error={inputErrors.contactNumber}
            onChange={onModalValueChange('contactNumber')}
            id="contactNumber"
            componentClass="margin-profile-item-right"
            containerClassName="width100"
            numberType={NUMBER_TYPE_MOBILE}
          />
        </div>
      </Form.ProfilePagesRowLayout>
      <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
        <div className="col-md-4 pl0 pr10 width100">
          <span className="font-size-16">
            Weekly income (after tax)
            <sup>#</sup>
          </span>
          <Form.InputNumber
            value={addEnquirerModalValues.weeklyIncome}
            error={inputErrors.weeklyIncome}
            label="$"
            onChange={onModalValueChange('weeklyIncome')}
            labelClass="top18"
            inputClass="width100"
            componentClass="margin-profile-item-left"
            isOptional
          />
        </div>
        <div className="col-md-8 pl0 pr10 width100">
          <span className="font-size-16">
            Start date at current address
            <sup>#</sup>
          </span>
          <div className="col-md-6 p0 width70">
            <Form.Dropdown
              label="Year"
              value={addEnquirerModalValues.currentResidenceStartYear}
              error={inputErrors.currentResidenceStartYear}
              options={yearsFrom1950WithGuidAndName}
              onChange={onModalValueChange('currentResidenceStartYear')}
              id="chosenYear"
              inputClass="width100"
              componentClass="margin-profile-item-left"
              isRequired={true}
              idAsValue={true}
            />
          </div>
          <div className="col-md-6 p0 width70">
            <Form.Dropdown
              label="Month"
              value={addEnquirerModalValues.currentResidenceStartMonth}
              error={inputErrors.currentResidenceStartMonth}
              options={constants.months}
              onChange={onModalValueChange('currentResidenceStartMonth')}
              id="chosenMonth"
              inputClass="width100"
              componentClass="margin-profile-item-left"
              isRequired={true}
              idAsValue={true}
            />
          </div>
        </div>
      </Form.ProfilePagesRowLayout>
      <Form.ProfilePagesRowLayout>
        <div>
          <span className="font-size-16">
            Weekly rent or mortgage payments
            <sup>#</sup>
          </span>
        </div>
        <div className="col-md-5 pl0 pr0">
          <Form.InputNumber
            value={addEnquirerModalValues.weeklyHousingPayment}
            error={inputErrors.weeklyHousingPayment}
            label="$"
            onChange={onModalValueChange('weeklyHousingPayment')}
            labelClass="top18"
            inputClass="width100"
            componentClass="margin-profile-item-left"
            isOptional
          />
        </div>
      </Form.ProfilePagesRowLayout>
      <PreQualifyInfoContainer>
        <Box
          onClick={() =>
            setVaccinationQuestionsContainerOpened(
              !vaccinationQuestionsContainerOpened,
            )
          }
        >
          <span>COVID disclosures (optional)</span>
          <img
            className="cursor-pointer"
            src={
              vaccinationQuestionsContainerOpened
                ? containerOpen
                : containerClosed
            }
            alt="vaccination status"
          />
        </Box>
        {vaccinationQuestionsContainerOpened && (
          <>
            <div className="mt30 width100 mb10">
              <FormComponent.RadioGroup
                label="Has the enquirer been overseas or in a COVID affected area in the last 14 days?"
                id="recentOverseasVisit"
                field="recentOverseasVisit"
                error={inputErrors.recentOverseasVisit}
                componentClassName="property-details-radio-group"
              >
                <FormComponent.Checkbox
                  id="false"
                  label="No"
                  checked={
                    addEnquirerModalValues.recentOverseasVisit ===
                    constants.False
                  }
                  onChange={onChangeRadioGroup('recentOverseasVisit')}
                />
                <FormComponent.Checkbox
                  id="true"
                  label="Yes"
                  checked={
                    addEnquirerModalValues.recentOverseasVisit ===
                    constants.True
                  }
                  onChange={onChangeRadioGroup('recentOverseasVisit')}
                />
              </FormComponent.RadioGroup>
            </div>

            <div className="mt10">
              <FormComponent.RadioGroup
                label="Does the enquirer have any cold or flu symptoms?"
                id="hasColdSymptoms"
                field="hasColdSymptoms"
                error={inputErrors.hasColdSymptoms}
                componentClassName="property-details-radio-group"
              >
                <FormComponent.Checkbox
                  id="false"
                  label="No"
                  checked={
                    addEnquirerModalValues.hasColdSymptoms === constants.False
                  }
                  onChange={onChangeRadioGroup('hasColdSymptoms')}
                />
                <FormComponent.Checkbox
                  id="true"
                  label="Yes"
                  checked={
                    addEnquirerModalValues.hasColdSymptoms === constants.True
                  }
                  onChange={onChangeRadioGroup('hasColdSymptoms')}
                />
              </FormComponent.RadioGroup>
            </div>
            <VaccinationRadioComponent
              onChangeRadioGroupCovid19VaccinationStatus={(event) =>
                onChangeRadioGroupCovid19VaccinationStatus(
                  'covid19VaccinationStatus',
                )(event)
              }
              covid19VaccinationStatus={
                addEnquirerModalValues.covid19VaccinationStatus
              }
              covid19VaccinationStatusError={
                inputErrors.covid19VaccinationStatus
              }
            />
          </>
        )}
      </PreQualifyInfoContainer>

      <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items mt20">
        <div className="col-md-6 p0 mt2 width100">
          <Box mb="16px">
            <h5>Property preferences</h5>
          </Box>
          <div className="col-xs-6 col-md-4 pl0">
            <Form.Dropdown
              options={constants.bedroomsDropdown}
              value={addEnquirerModalValues.bedrooms}
              error={inputErrors.bedrooms}
              label="Bedrooms"
              onChange={onModalValueChange('bedrooms')}
              labelClass="top18"
              inputClass="width100"
              componentClass="margin-profile-item-left"
              isOptional
            />
          </div>
          <div className="col-xs-6 col-md-4 pl0">
            <Form.InputNumber
              value={addEnquirerModalValues.rent}
              error={inputErrors.rent}
              label="Rent $ p/w"
              onChange={onModalValueChange('rent')}
              labelClass="top18"
              inputClass="width100"
              componentClass="margin-profile-item-left"
              isOptional
            />
          </div>
          <div className="col-xs-12 col-md-4 pl0">
            <Form.InputNumber
              value={addEnquirerModalValues.postcode}
              error={inputErrors.postcode}
              label="Postcode"
              onChange={onModalValueChange('postcode')}
              labelClass="top18"
              inputClass="width100"
              componentClass="margin-profile-item-left"
              isOptional
            />
          </div>
        </div>
      </Form.ProfilePagesRowLayout>
    </div>
  )
  return (
    <Modal
      modalHeading="Add Enquirer"
      primaryLabel="Save"
      primaryAction={onSaveEnquirer}
      primaryButtonLoading={submittingForm}
      secondaryLabel="Cancel"
      secondaryAction={closeModal}
      toggleModal={closeModal}
      modalBody={modalBody}
    />
  )
}

export default AddEnquirerModal
