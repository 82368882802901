import React, { useEffect, useState } from 'react'

import RolePrompt from 'app/session/join/role_prompt/role_prompt'

const RolePromptWrapper = (props) => {
  const [showPrompt, setShowPrompt] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const { rolePromptOn, currentAgency, applicationsCount = {} } = props

    const _showPrompt =
      !!(
        (currentAgency || {}).agencyID ||
        applicationsCount.myPortofolioProperties > 0 ||
        applicationsCount.managerBondCoverCount > 0
      ) && rolePromptOn

    setShowPrompt(_showPrompt)
  })

  return showPrompt && <RolePrompt {...props} />
}

export default RolePromptWrapper
