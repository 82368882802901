import React, { useState } from 'react'

import moment from 'moment'
import styled from 'styled-components'

import { Box, Button } from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import TableComponent from 'app/components/design-system-components/Table'
import { Text } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { pdfStandardDisplayTimeFormat } from 'app/sm/helpers'
import { formatPhoneNumber } from 'app/utils/phonenumber/helpers'

const StyledWordWrapBox = styled(Box)`
  white-space: pre-wrap;
  word-wrap: break-word;
`

const notAvailableElem = (
  <Text
    as="span"
    color={theme.colors.gray400}
    fontSize={theme.fontSizes.pRegular14}
  >
    N/A
  </Text>
)

export const SMSTable = ({ smsListData }) => {
  const [contentModal, setContentModal] = useState({
    isOpened: false,
    config: {},
  })
  const renderContentModal = () => {
    const { isOpened, config } = contentModal
    if (!isOpened) return
    return (
      <GenericModal
        onDismiss={() => setContentModal({ isOpened: false, config: {} })}
        withHeading={false}
        footerContainerProps={{ mt: 3 }}
        modalFooter={
          <GenericModalFooter
            secondaryBtnConfig={{
              title: 'Close',
              onClick: () => setContentModal({ isOpened: false, config: {} }),
            }}
          />
        }
      >
        {
          <StyledWordWrapBox mb={4} lineHeight={theme.lineHeights.encrption}>
            {config.content}
          </StyledWordWrapBox>
        }
      </GenericModal>
    )
  }
  const colDefs = [
    {
      accessor: 'createdAt',
      Header: 'Date',
      Cell: ({ value: creationDate }) => {
        if (!creationDate) return notAvailableElem
        const reportDate = moment(creationDate).format(
          pdfStandardDisplayTimeFormat,
        )
        return <Text as="div">{reportDate}</Text>
      },
    },
    {
      accessor: 'phone',
      Header: 'Recipient',
      Cell: ({ value: phone }) => {
        return formatPhoneNumber(phone)
      },
    },
    {
      accessor: 'messageType',
      Header: 'Message Type',
      Cell: ({ value: type, row: { original: sms } }) => {
        if (!type) return notAvailableElem
        return (
          <Button
            variant="linkBlue"
            onClick={() =>
              setContentModal({
                isOpened: true,
                config: { content: sms.message },
              })
            }
          >
            {type}
          </Button>
        )
      },
    },
    {
      accessor: 'sender',
      Header: 'Sender',
      Cell: ({ value: manager }) => {
        if (!manager) return notAvailableElem
        const managerName = `${manager.first_name} ${manager.last_name}`

        return (
          <Box mb={1}>
            <Text as="span">{managerName}</Text>
          </Box>
        )
      },
    },
  ]

  return (
    <>
      <Box width="100%" overflowX="auto">
        <TableComponent
          columns={colDefs}
          headerBgColor={theme.colors.white}
          dataToBeEntered={smsListData}
          textColor={theme.colors.gray600}
          showHeaderBottomBorder
          showRowBottomBorder
          thPadding={`${theme.space[5]}px`}
          fontSize={theme.fontSizes.pRegular14}
          verticalAlignTop
        />
      </Box>
      {renderContentModal()}
    </>
  )
}
