import { apiBaseUrl } from 'config/env'
import qs from 'qs'

import { FilterAny, FilterUnAssigned } from 'app/bond_cover/agency/agency_utils'
import momentTimeZone from 'app/constants/moment_timezone'
import { LogFetchError } from 'app/shared_components/helpers'
import * as sharedHelpers from 'app/shared_components/helpers'
import { dateUtil, messageStatus } from 'app/sm/helpers'
import { api } from 'app/utils/api/helpers'
import * as textHelpers from 'app/utils/text/helpers'

export const register = (agencyProfile) => {
  const { agencyId } = agencyProfile
  const url = `${apiBaseUrl}admin/agencies/${agencyId}/profile`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(agencyProfile),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (agency) => ({ ok: true, agencyProfile: agency }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const validateInvite = (inviteId) => {
  const url = `${apiBaseUrl}agencies/invite/${inviteId}`

  return fetch(url, {
    credentials: 'include',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          })
    })
    .then(
      (invite) => {
        return { ok: true, invite }
      },
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const acceptInvite = (inviteId) => {
  const url = `${apiBaseUrl}agencies/invite/${inviteId}/accept`

  return fetch(url, {
    credentials: 'include',
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          })
    })
    .then(
      (invite) => {
        return { ok: true, invite }
      },
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const createAgency = (agency) => {
  const url = `${apiBaseUrl}admin/agencies`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(agency),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (agency) => ({ ok: true, agency }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const searchAgencies = (query, pageSize) => {
  const url = `${apiBaseUrl}agencies/search?q=${encodeURIComponent(
    query,
  )}&pageSize=${pageSize}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (agencies) => ({ ok: true, agencies }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const adminSearchAgencies = (query, pageSize) => {
  const url = `${apiBaseUrl}admin/agencies/search?q=${encodeURIComponent(
    query,
  )}&pageSize=${pageSize}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (agencies) => ({ ok: true, agencies }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const searchProperties = (query, pageSize) => {
  const url = `${apiBaseUrl}sm/properties/search?searchStr=${query}&pageSize=${pageSize}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (properties) => ({ ok: true, properties }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const fetchAgencyProfile = (agencyGUID) => {
  const url = `${apiBaseUrl}admin/agencies/${agencyGUID}/profile`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (agencyProfile) => ({ ok: true, agencyProfile }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const fetchAgencyProfileforBackgroundCheck = (agencyGUID) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/profile`
  return fetch(url, {
    credentials: 'include',
  })
    .then(sharedHelpers.checkStatus)
    .then(
      (agencyProfile) => ({ ok: true, agencyProfile }),
      sharedHelpers.returnAPIError,
    )
    .catch(sharedHelpers.logAPIError)
}

export const fetchSupplierSource = (supplierSourceGUID) => {
  const url = `${apiBaseUrl}feeds/suppliersource/${supplierSourceGUID}`

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .catch((error) => LogFetchError(error))
    .then(
      (supplierSource) => ({ ok: true, supplierSource }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const updateSupplierSource = (supplierSourceGUID, supplierSource) => {
  const url = `${apiBaseUrl}feeds/suppliersource/${supplierSourceGUID}`

  return fetch(url, {
    credentials: 'include',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    method: 'PUT',
    body: JSON.stringify(supplierSource),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .catch((error) => LogFetchError(error))
    .then(
      (supplierSource) => ({ ok: true, supplierSource }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const createSupplierSource = (supplierSource) => {
  const url = `${apiBaseUrl}feeds/suppliersource`

  return fetch(url, {
    credentials: 'include',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    method: 'POST',
    body: JSON.stringify(supplierSource),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .catch((error) => LogFetchError(error))
    .then(
      (supplierSource) => ({ ok: true, supplierSource }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const addAgencySupplierSource = (agencySource) => {
  const url = `${apiBaseUrl}agency/agencysource`
  const options = {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(agencySource),
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then(
      (agencySource) => ({ ok: true, agencySource }),
      sharedHelpers.returnAPIError,
    )
}

export const updateAgencySupplierSource = (agencySource) => {
  const agencySourceGUID = agencySource.guidID
  const url = `${apiBaseUrl}agency/agencysource/${agencySourceGUID}`
  const options = {
    credentials: 'include',
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(agencySource),
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

//agency access fetch supplier source
export const fetchAllSupplierSources = () => {
  const url = `${apiBaseUrl}feeds/suppliersource`

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .catch((error) => LogFetchError(error))
    .then(
      (supplierSources) => {
        return { ok: true, supplierSources }
      },
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const fetchSupplierSourceForAgency = (agencyGUID, success) => {
  const url = `${apiBaseUrl}agency/${agencyGUID}/agencysource`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (supplierSource) => ({ ok: true, supplierSource }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const fetchAgencyInfo = (agencyGUID) => {
  const url = `${apiBaseUrl}agencies/${agencyGUID}/info`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (agency) => ({ ok: true, agency }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const fetchAgencyPublicProfile = (agencyGUID) => {
  const url = `${apiBaseUrl}agencies/${agencyGUID}/publicprofile`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (agencyProfile) => ({ ok: true, agencyProfile }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const updateAgency = (agency) => {
  const { agencyGUID } = agency
  const url = `${apiBaseUrl}admin/agencies/${agencyGUID}`

  return fetch(url, {
    credentials: 'include',
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(agency),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (agency) => ({ ok: true, agency }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const fetchReaListings = (query) => {
  const url = `https://services.realestate.com.au/services/listings/search?query=${JSON.stringify(
    query,
  )}`
  return fetch(url, {
    method: 'GET',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: Promise.resolve('Error when fetch listings from REA'),
          }),
    )
    .then(
      (listings) => ({ ok: true, listings }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const updateReaListings = (listings, agencyGUID) => {
  //TODO: get from agency source
  const url = `${apiBaseUrl}feeds/${agencyGUID}/d2efcac2-78e8-11e8-a4ec-0242ac120002/listings/upload?token=VY4nqbsdWndnxMFtjk`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(listings),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (listings) => ({ ok: true, listings }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const updateAgencyProfile = (profile) => {
  const { agencyId } = profile
  const url = `${apiBaseUrl}admin/agencies/${agencyId}/profile`

  return fetch(url, {
    credentials: 'include',
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(profile),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (agencyProfile) => ({ ok: true, agencyProfile }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deleteAgencyUser = (agencyUser, agencyGUID) => {
  const url = `${apiBaseUrl}admin/agencies/${agencyGUID}/users`

  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(agencyUser),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const fetchReaAgencyId = (agencyGUID) => {
  const url = `${apiBaseUrl}/admin/agencies/${agencyGUID}/profile`

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }).append(
      'Content-Type',
      'text',
    ),
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({ statusCode: res.status, responseText: res.text() })
    })
    .then((body) => {
      return { ok: true, reaAgencyId: body.reaAgencyId }
    })
    .catch((error) => {
      return {
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }
    })
}

export const fetchAgencyManagerList = (agencyGUID) => {
  const url = `${apiBaseUrl}agencies/${agencyGUID}/users`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({ statusCode: res.status, responseText: res.text() })
    })
    .then((managerList) => {
      return { ok: true, managerList }
    })
    .catch((error) => {
      return {
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }
    })
}

export const fetchTeamProperties = (teamGUID, options) => {
  const {
    searchText = '',
    manager = '',
    status = '',
    offset = '',
    limit = 8,
    stage = '',
    workflow = -1,
    todayOnly = false,
    timeZone = encodeURIComponent(momentTimeZone.tz.guess()),
    datePickerValue = '',
    managerContact = '',
    sortBy = '',
    excludedOffMarket = false,
  } = options

  const finalStartDate =
    datePickerValue && !todayOnly
      ? dateUtil.getStandardUTCDateAtStart(datePickerValue)
      : ''
  const finalEndDate =
    datePickerValue && !todayOnly
      ? dateUtil.getStandardUTCDateAtEnd(datePickerValue)
      : ''
  let url = `${apiBaseUrl}sm/teams/${teamGUID}/properties?archived=false&offset=${offset}&limit=${limit}&status=${status}&address=${searchText}&manager=${manager}&applicants=any&stage=${stage}&workflow=${workflow}&todayOnly=${todayOnly}&timeZone=${timeZone}&viewingStartDate=${finalStartDate}&viewingEndDate=${finalEndDate}&sortBy=${sortBy}&managerContact=${managerContact}&excludedOffMarket=${excludedOffMarket}`
  if (
    status === textHelpers.ARCHIVED_STATUS ||
    workflow === textHelpers.ARCHIVED_STATUS
  ) {
    url = `${apiBaseUrl}sm/teams/${teamGUID}/properties?archived=true&offset=${offset}&limit=${limit}&status=&address=${searchText}&manager=${manager}&applicants=any&stage=${stage}&workflow=-1&todayOnly=${todayOnly}&timeZone=${timeZone}&viewingStartDate=${finalStartDate}&viewingEndDate=${finalEndDate}&sortBy=${sortBy}&managerContact=${managerContact}&excludedOffMarket=${excludedOffMarket}`
  }
  if (
    status === textHelpers.ONLIST_INCOMPLETE ||
    workflow === textHelpers.ONLIST_INCOMPLETE
  ) {
    url = `${apiBaseUrl}sm/teams/${teamGUID}/properties?archived=false&offset=${offset}&limit=${limit}&status=&address=${searchText}&manager=${manager}&applicants=any&stage=${stage}&workflow=-1&todayOnly=${todayOnly}&timeZone=${timeZone}&viewingStartDate=${finalStartDate}&viewingEndDate=${finalEndDate}&onlistStatus=${textHelpers.STATUS_ONLIST_INCOMPLETE}&sortBy=${sortBy}&managerContact=${managerContact}&excludedOffMarket=${excludedOffMarket}`
  }
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }).append(
      'Content-Type',
      'text',
    ),
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({ statusCode: res.status, responseText: res.text() })
    })
    .then((properties) => {
      return { ok: true, properties }
    })
    .catch((error) => {
      return {
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }
    })
}

export const fetchTeamPropertiesByRecentApplication = (teamGUID, options) => {
  const {
    searchText = '',
    manager = '',
    status = '',
    cursor = '',
    limit = 8,
    stage = '',
    applicantEmail = '',
  } = options
  let url = `${apiBaseUrl}sm/teams/${teamGUID}/properties/applications?archived=false&cursor=${cursor}&limit=${limit}&status=${status}&q=${searchText}&applicants=any&stage=${stage}&applicantEmail=${applicantEmail}`
  if (status === 5) {
    url = `${apiBaseUrl}sm/teams/${teamGUID}/properties/applications?archived=true&cursor=${cursor}&limit=${limit}&status=&q=${searchText}&applicants=any&stage=${stage}&applicantEmail=${applicantEmail}`
  }
  if (manager === FilterAny || manager === FilterUnAssigned) {
    url += `&manager=${manager}`
  } else {
    url += `&managerContact=${manager}`
  }
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }).append(
      'Content-Type',
      'text',
    ),
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({ statusCode: res.status, responseText: res.text() })
    })
    .then((properties) => {
      return { ok: true, properties }
    })
    .catch((error) => {
      return {
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }
    })
}

export const fetchTeamMessages = (teamGUID, options) => {
  const {
    searchText = '',
    manager = '',
    cursor = '',
    limit = 9,
    property = '',
    archived = false,
    unRegistered = false,
    noNotes = false,
    viewingAvailable = false,
    hasMobileNumber = false,
  } = options
  // just for backward compatibility
  // but I think this should be removed with the legacy Messages page
  const params = qs.stringify({
    manager,
    search: searchText,
    cursor,
    limit,
    propertyGUID: property,
    archived,
    unRegistered,
    noNotes,
    viewingAvailable,
    hasMobileNumber,
  })
  let url = `${apiBaseUrl}sm/teams/${teamGUID}/messages?${params}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({ statusCode: res.status, responseText: res.text() })
    })
    .then((messages) => {
      return { ok: true, messages }
    })
    .catch((error) => {
      return {
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }
    })
}

export const getTeamProperty = (teamGUID, propertyGUID, filters) => {
  let url = `${apiBaseUrl}sm/teams/${teamGUID}/properties/${propertyGUID}/overview`

  const { manager, searchText, applicantEmail } = filters || {}

  const params = [
    { key: 'q', value: searchText },
    { key: 'manager', value: manager },
    { key: 'applicantEmail', value: applicantEmail },
  ]
    .filter((p) => !!p.value)
    .map((p) => `${p.key}=${p.value}`)
  url += !params.length ? '' : `?${params.join('&')}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (propertyOverview) => ({ ok: true, propertyOverview }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postEnquirerWithPropertyAsManager = (payload) => {
  const url = `${apiBaseUrl}sm/message/addenquirer`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
  })
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

/**
 * @deprecated
 */
export const postArchiveMessage = (teamGUID, messageGUID) => {
  const url = `${apiBaseUrl}sm/teams/${teamGUID}/messages/${messageGUID}/archive`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
  })
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (propertyOverview) => ({ ok: true, propertyOverview }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

// default value to archive, for soon to be deprecated message page
export const updateArchiveStatusMessage = (
  teamGUID,
  messageGUID,
  archiveStatus = messageStatus.archive,
) => {
  const url = `${apiBaseUrl}sm/teams/${teamGUID}/messages/${messageGUID}/${archiveStatus}`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
  })
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (propertyOverview) => ({ ok: true, propertyOverview }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getAgencyPaymentGateways = (agencyGUID) => {
  const url = `${apiBaseUrl}admin/agencies/${agencyGUID}/payments`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (paymentGateways) => ({ ok: true, paymentGateways }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const putAgencyAgentUtilityProviderUserID = (agencyUserGUID, body) => {
  const url = `${apiBaseUrl}admin/agencyusers/${agencyUserGUID}`

  return fetch(url, {
    credentials: 'include',
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const uploadAgencyBranding = (formData, logoType, agencyGUID) => {
  const url = `${apiBaseUrl}admin/agencies/${agencyGUID}/profile/logo/${logoType}`

  return fetch(url, {
    method: 'POST',
    body: formData,
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (brandingURL) => ({ ok: true, brandingURL }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deleteAgencyBranding = (logoType, agencyGUID) => {
  const url = `${apiBaseUrl}admin/agencies/${agencyGUID}/profile/logo/${logoType}`

  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const inviteEnquirer = (messageGUID) => {
  const url = `${apiBaseUrl}sm/message/${messageGUID}/sendapplylink`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const allPropertyFutureViewings = (teamGUID) => {
  const url = `${apiBaseUrl}sm/teams/${teamGUID}/teamviewings`

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
  })
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (propertyViewings) => ({ ok: true, propertyViewings }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getPropertiesForViewingsByProperty = (teamGUID, options) => {
  const {
    searchText = '',
    manager = '',
    status = '',
    cursor = '',
    limit = 8,
    stage = '',
    workflow = -1,
    todayOnly = false,
    timeZone = momentTimeZone.tz.guess(),
    datePickerValue = '',
    propertyGUID = '',
  } = options
  const finalStartDate =
    datePickerValue && !todayOnly
      ? dateUtil.getStandardUTCDateAtStart(datePickerValue)
      : ''
  const finalEndDate =
    datePickerValue && !todayOnly
      ? dateUtil.getStandardUTCDateAtEnd(datePickerValue)
      : ''
  const url = new URL(`${apiBaseUrl}sm/teams/${teamGUID}/properties/viewings`)
  let params = {
    archived: false,
    q: searchText,
    applicants: 'any',
    viewingStartDate: finalStartDate,
    viewingEndDate: finalEndDate,
    guid: propertyGUID,
    cursor,
    limit,
    status,
    manager,
    stage,
    workflow,
    todayOnly,
    timeZone,
  }
  if (status === 5 || workflow === 5) {
    params.archived = true
    params.status = ''
    params.workflow = -1
  }
  url.search = new URLSearchParams(params).toString()
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }).append(
      'Content-Type',
      'text',
    ),
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({ statusCode: res.status, responseText: res.text() })
    })
    .then((properties) => {
      return { ok: true, properties }
    })
    .catch((error) => {
      return {
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }
    })
}

export const refreshSourceSupplier = (agencySourceGUID) => {
  const url = `${apiBaseUrl}feeds/agencysource/${agencySourceGUID}/trigger`
  return fetch(url, {
    credentials: 'include',
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then(
      (agencySource) => ({ ok: true, agencySource }),
      sharedHelpers.returnAPIError,
    )
}

export const getUtilityStatus = (applicationGUID) => {
  const url = `${apiBaseUrl}sm/applications/${applicationGUID}/referralstatus`

  return fetch(url, {
    credentials: 'include',
  })
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (utilityResponse) => ({ ok: true, utilityResponse }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getPropertytreeTokens = () => {
  const url = `${apiBaseUrl}pms/adapters/PropertyTree.com/tokens`
  return fetch(url, {
    credentials: 'include',
  })
    .then(sharedHelpers.checkStatus)
    .then((tokens) => ({ ok: true, tokens }), sharedHelpers.returnAPIError)
    .catch(sharedHelpers.logAPIError)
}

export const getPropertytreeTokenForAgency = (agencyGUID) => {
  const url = `${apiBaseUrl}pms/adapters/PropertyTree.com/token/${agencyGUID}`
  return fetch(url, {
    credentials: 'include',
  })
    .then(sharedHelpers.checkStatus)
    .then((tokens) => ({ ok: true, tokens }), sharedHelpers.returnAPIError)
    .catch(sharedHelpers.logAPIError)
}

export const postRefreshPropertytreeTokens = () => {
  const url = `${apiBaseUrl}pms/adapters/PropertyTree.com/refresh-tokens`
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
    .then(sharedHelpers.checkStatus)
    .then((tokens) => ({ ok: true, tokens }), sharedHelpers.returnAPIError)
    .catch(sharedHelpers.logAPIError)
}

export const postDeactivatePMSIntegration = (agencyGUID, pms) => {
  const url = `${apiBaseUrl}pms/${agencyGUID}/deactivate/${pms}`
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
    .then(sharedHelpers.checkStatus)
    .then((tokens) => ({ ok: true, tokens }), sharedHelpers.returnAPIError)
    .catch(sharedHelpers.logAPIError)
}

export const postActivatePMSIntegration = (agencyGUID, tokenAndAgency, pms) => {
  const url = `${apiBaseUrl}pms/${agencyGUID}/activate/${pms}`
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(tokenAndAgency),
  })
    .then(sharedHelpers.checkStatus)
    .then(() => ({ ok: true }), sharedHelpers.returnAPIError)
    .catch(sharedHelpers.logAPIError)
}

export const postMarkMessageAsRead = (agencyGUID, messageGUID) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/messages/${messageGUID}/read`
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
    .then(sharedHelpers.checkStatus)
    .then((data) => ({ ok: true, data }), sharedHelpers.returnAPIError)
    .catch(sharedHelpers.logAPIError)
}

export const postMarkMessageAsUnread = (agencyGUID, messageGUID) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/messages/${messageGUID}/unread`
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
    .then(sharedHelpers.checkStatus)
    .then((data) => ({ ok: true, data }), sharedHelpers.returnAPIError)
    .catch(sharedHelpers.logAPIError)
}

export const getAgencyStatus = (agencyGUID) => {
  const url = `${apiBaseUrl}pms/${agencyGUID}/status`
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
  })
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (integrationStatus) => ({ ok: true, integrationStatus }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getPropertyBallotsDetail = (propertyGUID) => {
  return api.get(`/sm/properties/${propertyGUID}/ballotdetails`)
}

export const postRunPropertyBallots = (propertyGUID) => {
  return api.post(`/sm/properties/${propertyGUID}/ballots/run`)
}
