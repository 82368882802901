import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import { validateEmailOptional } from 'app/shared_components/validations'

export class BondCoverReferral extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      sentEmail: '',
      isSending: false,
      sent: '', // fail, success
      showEmailError: false,
    }
  }

  onChange = (event) => {
    this.setState({ email: event.target.value })
  }

  onClick = () => {
    this.setState({ isSending: true })
    const { inviterName, inviterEmail, sendBondCoverReferral } = this.props

    // check self sending
    if (inviterEmail.toLowerCase() === this.state.email.toLowerCase()) {
      this.sendSuccess()
      return
    }
    // 1 is for $25 referral
    sendBondCoverReferral(
      1,
      inviterName,
      inviterEmail,
      this.state.email.trim(),
      this.sendSuccess,
      this.sendFail,
    )
    this.setState({
      sentEmail: this.state.email,
      showEmailError: false,
    })
  }

  getButtonText() {
    const { sent } = this.state
    const { bondText } = this.props
    // if (bondText && bondText.length > 0) {
    //   return bondText;
    // }
    switch (sent) {
      case '':
        return bondText || 'Send $25 credit'
      case 'success':
        return 'Invite another'
      default:
        return 'Try again'
    }
  }

  hideError = () => {
    this.setState({ showEmailError: false })
  }

  sendFail = (error) => {
    this.setState({ isSending: false, sent: error })
  }

  sendSuccess = () => {
    this.setState({ email: '', isSending: false, sent: 'success' })
  }

  showError = () => {
    this.setState({ showEmailError: true })
  }

  render() {
    const buttonText = this.getButtonText()
    const isEmailValid = validateEmailOptional(this.state.email).length === 0
    const { role, apiError, focus } = this.props
    const duplicateInvite =
      ErrorMessages[Errors.DuplicateInvitesExist] === apiError
    const { showEmailError, email } = this.state

    return (
      <div>
        <div className="renter-invite-email">
          <div className="pl0 lgpl0 lgpr0">
            <div className="input-box">
              <input
                ref={this.props.inputRef}
                type="text"
                required
                value={this.state.email}
                onChange={this.onChange}
                className={!isEmailValid && showEmailError ? 'error' : ''}
                onFocus={this.hideError}
                onBlur={this.showError}
              />
              <label>
                {role === 'renter' ? 'Friend' : 'Renter'}'s email address
              </label>
              {this.state.sent !== '' && this.state.sent !== 'success' && (
                <div className="alert alert-danger">{`${apiError}${
                  duplicateInvite ? this.state.sentEmail : ''
                }`}</div>
              )}
            </div>
            <div
              className={
                !isEmailValid && showEmailError
                  ? 'alert alert-danger'
                  : 'hidden'
              }
            >
              Invalid email
            </div>
          </div>
          <div className="pr0 lgpl0 lgpr0">
            <button
              className="pl10 pr10"
              disabled={
                isEmailValid === false || !email || this.state.isSending
              }
              onClick={this.onClick}
            >
              {buttonText}
              <i
                className={this.state.isSending ? 'fa fa-spinner fa-pulse' : ''}
              />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

BondCoverReferral.propTypes = {
  inviterName: PropTypes.string,
  inviterEmail: PropTypes.string,
}
