import React from 'react'

import { Link } from 'react-router-dom'

import headerImg03 from 'app/assets/icons/header-img-03.png'
import AgencySearchItem from 'app/bond_cover/agency/agency_search_item'
import AgencySearchBox from 'app/bond_cover/agency/search_box'
import { IntlTelInputWrapper } from 'app/components/design-system-components'
import { intTelTop10List } from 'app/shared_components/helpers'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import { urlTo } from 'app/sm/helpers'

const ManagerForm = ({
  managerType,
  agencyName,
  updateAgency,
  firstName,
  lastName,
  email,
  mobile,
  clicked,
  errors,
  apiError,
  updateNumber,
  updateEmail,
  trimField,
  update,
  submit,
  disabled,
  unsavedChanges,
  backUrl,
  requestID,
  searchFunction,
  onCountrySelected,
  validatePhoneNumber,
  onMobileChange,
}) => (
  <div className="two-col-box-flex-row sm64">
    <div className="col-first">
      <div className="mobile-only">
        <Link
          to={urlTo('bondCoverApplyProgress', { requestID })}
          className="btn btn-transparent btn-left xs-text-center wa"
        >
          <i className="icon-arrow-left left"></i>
          <span>Back</span>
        </Link>
      </div>
      <div className="ilustration-box">
        <img src={headerImg03} />
      </div>

      <h3 className="mb15">Who's the property manager?</h3>
      <p>
        We need approval from your property manager to process your application.
      </p>

      <div className={errors.managerType ? 'alert alert-danger' : ''}>
        {errors.managerType}
      </div>

      <div className="snug-tabs">
        <label>
          <input
            type="radio"
            name="manager"
            value={1}
            checked={managerType === 1}
            onChange={updateNumber('managerType')}
          />
          <span>Agent</span>
        </label>
        <label>
          <input
            type="radio"
            name="manager"
            value={2}
            checked={managerType === 2}
            onChange={updateNumber('managerType')}
          />
          <span>Owner</span>
        </label>
      </div>

      <h4 className="pb15">Property Manager:</h4>

      <div className="row">
        <div className="col-xs-6">
          <div className="input-box">
            <input
              type="text"
              required
              id="firstName"
              className={errors.firstName ? 'error' : ''}
              value={firstName}
              onChange={update('firstName')}
              onBlur={trimField('firstName')}
            />
            <label>First Name</label>
          </div>
        </div>
        <div className="col-xs-6">
          <div className="input-box">
            <input
              type="text"
              required
              id="lastName"
              className={errors.lastName ? 'error' : ''}
              value={lastName}
              onChange={update('lastName')}
              onBlur={trimField('lastName')}
            />
            <label>Last Name</label>
          </div>
        </div>
      </div>

      <div className={errors.firstName ? 'alert alert-danger' : ''}>
        <div>{errors.firstName}</div>
      </div>

      <div className={errors.lastName ? 'alert alert-danger' : ''}>
        <div>{errors.lastName}</div>
      </div>

      <div className="input-box">
        <input
          type="email"
          required
          id="email"
          className={errors.email ? 'error' : ''}
          value={email}
          onChange={updateEmail('email')}
          onBlur={trimField('email')}
        />
        <label>Email address</label>
      </div>

      <div className={errors.email ? 'alert alert-danger' : ''}>
        <div>{errors.email}</div>
      </div>

      <div className={`input-box ${errors.mobile && disabled ? 'error' : ''}`}>
        <IntlTelInputWrapper
          css={['intl-tel-input', '']}
          utilsScript={'libphonenumber.js'}
          defaultCountry={'au'}
          preferredCountries={intTelTop10List}
          onPhoneNumberChange={onMobileChange()}
          onPhoneNumberBlur={validatePhoneNumber()}
          onSelectFlag={onCountrySelected}
          value={mobile}
          placeholder={'e.g. +61 412 345 678'}
        />
        <p>Phone Number</p>
      </div>
      <div
        className={
          errors.mobile && disabled ? 'alert alert-danger' : 'hide-alert'
        }
      >
        <div>{errors.mobile}</div>
      </div>

      <div className={managerType === 1 ? '' : 'hidden'}>
        <div className="input-box">
          <AgencySearchBox
            searchFunction={searchFunction}
            placeholder="Search Real Estate Agency"
            value={agencyName}
            handleInputChange={updateAgency}
            handleInputBlur={trimField('agencyName')}
            className={errors.agencyName ? 'error' : ''}
            SearchResultItem={AgencySearchItem}
          />
        </div>
      </div>

      <div className={managerType === 1 ? '' : 'hidden'}>
        <div className={errors.agencyName ? 'alert alert-danger' : ''}>
          <div>{errors.agencyName}</div>
        </div>
      </div>

      <div className={apiError ? 'alert alert-danger' : ''}>
        <div>{apiError}</div>
      </div>

      <div className="row pt25 mobile-form-button">
        <div className="col-sm-5 pb5 tablet-only">
          <Link
            to={urlTo('bondCoverApplyProgress', { requestID })}
            className="btn btn-transparent btn-left xs-text-center wa"
          >
            <i className="icon-arrow-left left"></i>
            <span>Back</span>
          </Link>
        </div>
        <div className="col-sm-7 pb5">
          <button className="btn" disabled={disabled} onClick={() => submit()}>
            Save
            <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
          </button>
        </div>
      </div>

      <UnsavedMessage unsavedChanges={unsavedChanges} />
    </div>
  </div>
)

export default ManagerForm
