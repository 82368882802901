import React from 'react'

import incomeIcon from 'app/assets/icons/summary_icons/income.svg'
import { Box } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import ApplicationNoteAdd from 'app/components/display/application_note_add/component'
import NoteButtons from 'app/components/display/application_note_buttons/component'
import * as Display from 'app/components/display/display'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import { isDocAutoDeleted } from 'app/sm/docs_helpers'
import { IncomeAttachments, urlTo } from 'app/sm/helpers'
import { formatBalance } from 'app/sm/proof_of_income/helper'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'
import { ApplicationDetailsNumbersComponent } from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'

import 'app/match/apply/application_summary_before_submit/components/income_container/style.scss'

const no = 'No'
const yes = 'Yes'
const notAnswered = 'Not answered'

const IncomeContainer = ({
  componentClassName = '',
  proofOfIncome = {},
  isIncomeEditable = true,
  onEditClick,
  enableNote = false,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  displayedApplicant,
  section,
  id = '',
  toggleRequestInfoModal = undefined,
  toggleEditRenterApplication,
  isManagerAdded,
  ballotsEnabled = false,
  toggleDocumentsViewer = undefined,
}) => {
  const {
    BankStatements = [],
    Payslips = [],
    GovernmentBenefitDocuments = [],
    OtherIncomeDocuments = [],
  } = proofOfIncome
  return (
    <div id={id} className={`container-component ${componentClassName}`}>
      <ContainerHeader
        icon={incomeIcon}
        text="Income"
        isEditable={isIncomeEditable}
        onEditClick={() =>
          onEditClick(
            `${urlTo('ProofOfIncome')}?from=rentersummary${
              ballotsEnabled && `&ballot=true`
            }`,
          )
        }
        enableNote={enableNote}
        onNewNoteUpdated={onNewNoteUpdated}
        newNotesContent={newNotesContent}
        onNoteIconClicked={onNoteIconClicked}
        onNewNoteAdded={onNewNoteAdded}
        onNoteCrossClicked={onNoteCrossClicked}
        interpreteNoteResult={interpreteNoteResult}
        enableNotesButton={enableNotesButton}
        displayedApplicant={displayedApplicant}
        section={section}
        toggleRequestInfoModal={toggleRequestInfoModal}
        toggleEditRenterApplication={toggleEditRenterApplication}
        isManagerAdded={isManagerAdded}
      />
      <div>
        <ProofOfIncomComponent
          proofOfIncome={proofOfIncome}
          Payslips={Payslips}
          BankStatements={BankStatements}
          OtherIncomeDocuments={OtherIncomeDocuments}
          GovernmentBenefitDocuments={GovernmentBenefitDocuments}
          displayTitle={false}
          labelClassName="income-label"
          ballotsEnabled={ballotsEnabled}
          toggleDocumentsViewer={toggleDocumentsViewer}
        />
      </div>
    </div>
  )
}

const ProofOfIncomComponent = ({
  proofOfIncome,
  displayTitle = true,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  displayedApplicant,
  interpreteNoteResult = {},
  enableNotesButton,
  ballotsEnabled = false,
  toggleDocumentsViewer = undefined,
}) => {
  const {
    optedToHavePropertyInvestor,
    optedToHavePropertyOwnership,
    SavingsBalance = 0,
  } = proofOfIncome
  let propertyInvestor = notAnswered
  if (optedToHavePropertyInvestor === 1) {
    propertyInvestor = no
  } else if (optedToHavePropertyInvestor === 2) {
    propertyInvestor = yes
  }
  let propertyOwner = notAnswered
  if (optedToHavePropertyOwnership === 1) {
    propertyOwner = no
  }
  if (optedToHavePropertyOwnership === 2) {
    propertyOwner = yes
  }

  const savingsBalanceDisplay = formatBalance(SavingsBalance)

  return (
    <div className="application-income-container">
      {displayTitle && (
        <div className="position-relative">
          <h4 className="identity-title pdf-mt0 display-flex justify-content-space-between">
            <span>Income</span>
            {enableNotesButton && (
              <NoteButtons
                onNoteIconClicked={onNoteIconClicked('income')}
                noteStatus={interpreteNoteResult.income}
              />
            )}
          </h4>
          <ApplicationNoteAdd
            onNewNoteUpdated={onNewNoteUpdated}
            onNewNoteAdded={onNewNoteAdded}
            newNotesContent={newNotesContent}
            section="income"
            onNoteCrossClicked={onNoteCrossClicked}
            name={displayedApplicant.firstName}
          />
          <div className="application-seperator" />
        </div>
      )}
      <Box mb="5px">
        <Display.SectionMinorTitle text="Savings Balance" />
        <Text as="span">{savingsBalanceDisplay}</Text>
      </Box>
      <div className="application-identification-container application-income-proof-container width100 flex-direction-row">
        {IncomeAttachments.map((attachment) => {
          const filteredAttachments =
            (proofOfIncome && proofOfIncome[attachment.docType]) || []
          return (
            <div
              className="application-identification-sub-container width-50"
              key={attachment.name}
            >
              <div className="application-info-common-component">
                <div className="title width100">
                  <Display.SectionMinorTitle text={attachment.name} />
                  {filteredAttachments.length > 0 &&
                    filteredAttachments.map((doc) => {
                      return (
                        <AppDocumentListItemSecondVersion
                          titleClass="mt0"
                          document={doc}
                          key={doc.guidID}
                          toggleDocumentsViewer={
                            toggleDocumentsViewer
                              ? () => toggleDocumentsViewer(doc.guidID)
                              : undefined
                          }
                          disabled={isDocAutoDeleted(doc)}
                          isAutoDeleted={isDocAutoDeleted(doc)}
                          isSensitive
                        />
                      )
                    })}
                  {filteredAttachments.length === 0 && (
                    <div className="no-info-provided">
                      No information provided
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        })}
        {!ballotsEnabled && (
          <div>
            <div className="application-info-common-component">
              <div className="title width100">
                <Display.SectionMinorTitle
                  text={'Property Investment'}
                  componentClass="width100"
                />
                <ApplicationDetailsNumbersComponent
                  title="Are you a property investor or are you interested in becoming an investor?"
                  text={propertyInvestor}
                  componentClassName="justify-content-unset ha"
                  textClassName="pdf-font-size-10"
                  titleTextClassName="pdf-font-size-10"
                  titleClassName="width100"
                />
              </div>
            </div>
          </div>
        )}
        {ballotsEnabled && (
          <div>
            <div className="application-info-common-component">
              <div className="title width100">
                <Display.SectionMinorTitle
                  text={'Property Ownership'}
                  componentClass="width100"
                />
                <ApplicationDetailsNumbersComponent
                  title="Do you own a whole or part of a property in Australia?"
                  text={propertyOwner}
                  componentClassName="justify-content-unset ha"
                  textClassName="pdf-font-size-10"
                  titleTextClassName="pdf-font-size-10"
                  titleClassName="width100"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default IncomeContainer
