import React from 'react'

import PropTypes from 'prop-types'

import Box from 'app/components/design-system-components/Box'
import { Button } from 'app/components/design-system-components/Button'
import Flex from 'app/components/design-system-components/Flex'
import { Spinner } from 'app/components/design-system-components/Spinner'
import { FlexBox } from 'app/shared_components/shared_styled_component'

// just a map to button styled-system variants and spinner colors
const buttonSpinnerVariants = {
  solid: {
    button: 'solid',
    spinner: 'gray900',
  },
  solidSuccess: {
    button: 'solidSuccess',
  },
  solidSecondary: {
    button: 'solidSecondary',
    spinner: 'secondary',
  },
  outline: {
    button: 'outline',
    spinner: 'secondary',
  },
  outlineSuccess: {
    button: 'outlineSuccess',
    spinner: 'invertedSuccess',
  },
  outlineWarning: {
    button: 'outlineWarning',
  },
  linkSuccess: {
    button: 'linkSuccess',
    spinner: 'success',
  },
}

function ButtonWithLoading(props) {
  const {
    loading = false,
    disableWhenLoading = false,
    buttonVariant,
    spinnerColor,
    variant,
    children,
    showSpinnerBesideText = false,
    ButtonCmp = Button,
  } = props

  const variantDef = buttonSpinnerVariants[variant || ''] || {}
  const { button = '', spinner = 'primary' } = variantDef

  const spinnerBox = (
    <FlexBox justifyContent="center" alignItems="center">
      <Spinner
        fontSize="1.5rem"
        colorVariant={spinnerColor || spinner}
        {...props}
      />
    </FlexBox>
  )

  let buttonContent
  if (!loading) {
    buttonContent = children
  } else if (!showSpinnerBesideText) {
    buttonContent = spinnerBox
  } else {
    buttonContent = (
      <Flex alignItems="center">
        <Box mr="0.5em">{children}</Box>
        {spinnerBox}
      </Flex>
    )
  }

  const disabled = disableWhenLoading && loading

  return (
    <ButtonCmp disabled={disabled} variant={buttonVariant || button} {...props}>
      {buttonContent}
    </ButtonCmp>
  )
}

ButtonWithLoading.propTypes = {
  ...Button.PropTypes,
  loading: PropTypes.bool,
  disableWhenLoading: PropTypes.bool,
  showSpinnerBesideText: PropTypes.bool,
  buttonVariant: PropTypes.string,
  spinnerColor: PropTypes.string,
  variant: PropTypes.string,
  ButtonCmp: PropTypes.func,
}

export default ButtonWithLoading
