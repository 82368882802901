import { VARC_TEAM_NAME, VARC_TEAM_NAME2 } from 'app/utils/text/helpers'

export const FeatureFlag = {
  Advertisement: { isOn: false },
  Agency: { isOn: true },
  Viewings: { isOn: true },
  BondCover: { isOn: false },
  Contacts: { isOn: true },
  Activity: { isOn: true },
  Notes: { isOn: true },
  ViewingSchedule: { isOn: true },
  ManualRentalReference: { isOn: true },
  ManualEmploymentVerification: { isOn: true },
  ViewingCommentTemplates: { isOn: true },
  AddEnquirersToViewings: { isOn: true },
  FreeBackgroundCheck: { isOn: false },
}

export const isNewWorkflowEnabled = () => {
  return true
}

const settingsNotificationsViewings = {}

const settingsNotificationsApplications = {}

const settingsReportsNotificationsDisabled = {}

const teamReferenceCountDisabled = {
  snugtour: true,
}

const maintenanceWindowEnabled = {
  snug: true,
  snugtour: true,
}

const showBallotsEnabledForTeam = {
  snug: true,
  snugtour: true,
  [VARC_TEAM_NAME]: true,
  [VARC_TEAM_NAME2]: true,
  ballarat: true,
}

export const isMaintenanceWindowEnabled = (teamSlug) => {
  return !!maintenanceWindowEnabled[teamSlug]
}

export const isViewingRunEnabled = (teamSlug, teams = []) => {
  const foundTeam = teams.find((team) => team.slug === teamSlug)
  return foundTeam && foundTeam.viewingRunEnabled
}

export const isEnquiryNotificationsSettingEnabled = () => {
  return true
}

export const isViewingNotificationsSettingsEnabled = (teamSlug) => {
  return settingsNotificationsViewings[teamSlug]
}

export const isApplicationNotificationsSettingsEnabled = (teamSlug) => {
  return settingsNotificationsApplications[teamSlug]
}

export const isReportNotificationsSettingsEnabled = (teamSlug) => {
  return !settingsReportsNotificationsDisabled[teamSlug]
}

export const isOfflineActionsEnabled = () => {
  return true
}

export const isApplicantCommunicationLogsEnabled = () => {
  return true
}

export const isKeyLoggerEnabled = (currentTeam) => {
  return currentTeam && currentTeam.keyManagementEnabled
}

export const isAdvancedReportingEnabled = (currentTeam) => {
  return currentTeam && currentTeam.advancedActivityEnabled
}

export const isBackgroundChecksReportingEnabled = (currentTeam) => {
  return (
    currentTeam &&
    currentTeam.backgroundChecksReportingEnabled &&
    currentTeam.enableBackgroundCheckBundle
  )
}

export const isSmsReportingEnabled = () => {
  return true
}

export const isShareApplicationWithOwnerEnabled = () => {
  return true
}

export const isTeamReferenceCountEnabled = (teamSlug) => {
  return !teamReferenceCountDisabled[teamSlug]
}

export const isSetupTabEnabled = () => {
  return true
}

export const isFollowingEnabled = () => {
  return true
}

export const isTravelTimeOptimisationEnabled = (currentTeam) => {
  return currentTeam && currentTeam.travelTimeOptimisationEnabled
}

export const isViewingMergeEnabled = () => {
  return true
}

export const isEnableActivityFeedIconEnabled = () => {
  return true
}

export const isShowBallotsEnabledForTeam = (teamSlug) => {
  return showBallotsEnabledForTeam[teamSlug]
}

export const isFeedEmailFilterEnabled = () => {
  return false
}

export const isPMSortEnabled = (currentTeam) => {
  return true
}

export const isKolmeoEnabled = (teamSlug) => {
  return true
}

export const isManagedAppEnabled = (teamSlug) => {
  return true
}

export const isOwnerOptInForBGCEnabled = (teamSlug) => {
  return true
}

export const isSendApplicantEnabled = (pms, teamSlug) => {
  return true
}
