import { connect } from 'react-redux'

import {
  attachToProfile,
  discardResult,
  fetchBackgroundCheck,
  fetchIdentityDocs,
  fetchPaymentAmount,
  receivePollingStopped,
  resetSpinner,
  steps,
} from 'app/sm/background_check/background_check_actions'
import IdentityConfirmation from 'app/sm/background_check/identity_confirmation/container'
import { fetchBackgroundCheckReportDetails } from 'app/sm/renter_background_check_report/action'

const mapStateToProps = ({ BackgroundCheck, Shared }) => ({
  backgroundCheck: BackgroundCheck.backgroundCheck,
  spinner: BackgroundCheck.spinner,
  steps,
  error: BackgroundCheck.error,
  backUrl: Shared.backUrl,
})

const mapDispatchToProps = (dispatch) => ({
  fetchBackgroundCheckReportDetails: (id) =>
    dispatch(fetchBackgroundCheckReportDetails(id)),
  attachToProfile: () => dispatch(attachToProfile()),
  fetchIdentityDocs: () => dispatch(fetchIdentityDocs()),
  fetchPaymentAmount: () => dispatch(fetchPaymentAmount()),
  discardResult: () => dispatch(discardResult()),
  fetchBackgroundCheck: () => dispatch(fetchBackgroundCheck()),
  receivePollingStopped: (isPollingStopped) =>
    dispatch(receivePollingStopped(isPollingStopped)),
  resetSpinner: () => dispatch(resetSpinner()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IdentityConfirmation)
