import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { variant } from 'styled-system'

import plusIconUrl from 'app/assets/icons/add_24px.svg'
import minusIconUrl from 'app/assets/icons/minimize_24px.svg'
import Flex from 'app/components/design-system-components/Flex'
import HorizontalLine from 'app/components/design-system-components/HorizontalLine'
import IconBox from 'app/components/design-system-components/IconBox'

const SectionHeader = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  padding: 8px 0px;
  font-size: ${({ theme }) => theme.fontSizes.pLarge16};
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  color: ${({ theme }) => theme.colors.gray700};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${variant({
    variants: {
      blue: {
        backgroundColor: 'paleBlue',
      },
      transparent: {
        backgroundColor: 'transparent',
      },
    },
  })}
`

export default class CollapsableSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: !!props.initiallyCollapsed,
    }
  }

  handleToggleClick() {
    this.setState(({ collapsed }) => ({
      collapsed: !collapsed,
    }))
  }

  render() {
    const { TitleSection, variant, children } = this.props
    const { collapsed } = this.state
    const icon = collapsed ? plusIconUrl : minusIconUrl
    return (
      <div>
        <SectionHeader
          variant={variant}
          onClick={() => this.handleToggleClick()}
        >
          {TitleSection}

          <IconBox>
            <img alt="" src={icon} />
          </IconBox>
        </SectionHeader>
        <HorizontalLine />

        {!collapsed && children}
      </div>
    )
  }
}

CollapsableSection.propTypes = {
  TitleSection: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  variant: PropTypes.oneOf(['blue']),
}
