import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import headerImg04 from 'app/assets/icons/header-img-04.png'
import {
  AlertSensitiveInfo,
  Box,
} from 'app/components/design-system-components'
import { Alert, Flex, TextSpan } from 'app/components/design-system-components'
import * as Layout from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import { ErrorBox, firstElementOrEmptyString } from 'app/components/forms/util'
import ErrorMessages from 'app/constants/error_messages'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { DRIVER_LICENCE_DOC_TYPE } from 'app/profile/renter/identity_doc/identity-doc.constants'
import ReviewDataModal from 'app/profile/renter/identity_doc/review_data_modal'
import * as snugLogger from 'app/services/snugLogger'
import isEmptyObject from 'app/shared_components/check_empty_object'
import Checkbox from 'app/shared_components/checkbox'
import DocumentList from 'app/shared_components/document_list/document_list'
import { ErrorMessage } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import * as validations from 'app/shared_components/validations'
import {
  DocumentTypes,
  urlTo,
  validateAgeForRentalApplication,
} from 'app/sm/helpers'
import * as helpers from 'app/sm/helpers'
import { isCurrentRoute } from 'app/sm/router_helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import { SHORT_STANDARD_DATETIME } from 'app/utils/datetime/helpers'
import { SouthAustralianAlert } from 'app/utils/state/helpers'

const defaultIdentityPayload = (userGuidID, docType) => ({
  guidID: userGuidID,
  docType: Number(docType),
  docNumber: 'initial',
  state: 'initial',
  country: 'AUS',
  expiryDate: '2000-01-01',
  dateOfBirth: null,
  firstName: 'initial',
  middleName: 'initial',
  middleNameRequired: true,
  lastName: 'initial',
})

const isPassport = (value) => value === 2
const isLicence = (value) => value === 1

const watchedDocErrorCodes = ['900335', '300166']

const logDocErrors = (error) => {
  const isWatchedError = watchedDocErrorCodes.some((code) =>
    error.includes(code),
  )
  if (isWatchedError) {
    snugLogger.error(error)
  }
  return Promise.reject(error)
}

class IdentityDoc extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      guidID: undefined,
      docType: null,
      docNumber: '',
      state: '',
      country: 'AUS',
      expiryDate: null,
      dateOfBirth: null,
      firstName: '',
      middleName: '',
      middleNameRequired: false,
      lastName: '',
      cardNumber: '',
      showErrors: {},
      inputErrors: {
        firstName: '',
        middleName: '',
        lastName: '',
        docNumber: '',
        expiryDate: '',
        dateOfBirth: '',
        country: '',
        state: '',
        cardNumber: '',
      },
      unsavedChanges: false,
      saveClicked: false,
      apiError: '',
      attachmentApiError: '',
      docSpinner: false,
      identityDocAttachments: [],
      addIdentityDocMode: isCurrentRoute('addIdentityDocDetails'),
      showReviewDataModel: true,
      sanitizedAt: '',
      hashedDateOfBirth: '',
    }
  }

  componentDidMount() {
    const {
      fetchIdentityDoc,
      currentUser,
      match: {
        params: { id },
      },
    } = this.props
    if (currentUser) {
      this.setState({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
      })

      this.setState({
        initDetails: {
          firstName: currentUser.firstName?.trim(),
          lastName: currentUser.lastName?.trim(),
          middleName: '',
        },
      })
    }
    if (id && !isCurrentRoute('addIdentityDocDetails')) {
      fetchIdentityDoc(id)
        .then((identityDoc) => {
          this.setState({
            initDetails: {
              firstName: identityDoc.firstName,
              middleName: identityDoc.middleName,
              lastName: identityDoc.lastName,
            },
          })
          const dob = dateTimeHelpers.isDateEmpty(identityDoc?.dateOfBirth)
            ? null
            : identityDoc.dateOfBirth
          this.setState({
            guidID: identityDoc.guidID,
            docType: identityDoc.docType,
            docNumber: identityDoc.docNumber,
            state: identityDoc.state,
            expiryDate: identityDoc.expiryDate,
            dateOfBirth: dob,
            firstName: identityDoc.firstName,
            middleName: identityDoc.middleName,
            lastName: identityDoc.lastName,
            country: identityDoc.country,
            middleNameRequired: identityDoc.middleNameRequired,
            apiError: '',
            cardNumber: identityDoc.cardNumber,
            sanitizedAt: identityDoc.sanitizedAt,
            hashedDateOfBirth: identityDoc.hashedDateOfBirth,
          })
        })
        .catch((error) => this.setState({ apiError: error }))
      this.callFetchIdentityDocAttachments(id)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: {
        params: { id },
      },
    } = this.props

    const {
      match: {
        params: { id: prevId },
      },
    } = prevProps

    if (id !== prevId && id !== this.state.guidID) {
      this.setState({ errors: {}, guidID: id })
      if (id) {
        this.callFetchIdentityDocAttachments(id)
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnloadHandler)
    this.cleanUpComponent()
  }

  onUnloadHandler = (event) => {
    event.preventDefault()

    event.returnValue = ''
  }

  addIdentityDocAttachment = (documentType) => {
    const { guidID } = this.state
    const { addIdentityDocAttachment } = this.props
    this.setState({ docSpinner: true })
    addIdentityDocAttachment(documentType, guidID)
      .then(() => {
        this.setState({ attachmentApiError: '', docSpinner: false })
        this.callFetchIdentityDocAttachments(guidID)
      })
      .catch(logDocErrors)
      .catch((error) =>
        this.setState({ docSpinner: false, attachmentApiError: error }),
      )
  }

  callFetchIdentityDocAttachments = (guidID) => {
    const { fetchIdentityDocAttachments } = this.props
    fetchIdentityDocAttachments(guidID)
      .then((attachments) => {
        this.setState({
          identityDocAttachments: attachments,
        })
      })
      .catch((error) =>
        this.setState({ docSpinner: false, attachmentApiError: error }),
      )
  }

  cleanUpComponent = () => {
    const { guidID, saveClicked, addIdentityDocMode } = this.state
    if (addIdentityDocMode && guidID && !saveClicked) {
      this.props.removeIdentityDoc(guidID)
      this.setState({ guidID: '' })
    }
  }

  deleteIdentityDocAttachment = (id) => {
    const { deleteIdentityDocAttachment } = this.props
    const { guidID } = this.state
    this.setState({ docSpinner: true })
    deleteIdentityDocAttachment(guidID, id)
      .then(() => {
        this.setState({ attachmentApiError: '', docSpinner: false })
        this.callFetchIdentityDocAttachments(guidID)
      })
      .catch((error) => this.setState({ attachmentApiError: error }))
  }

  focusAndScrollToFirstError = (errors) => {
    const field = Object.keys(errors)
      .filter((fieldName) => this.state.inputErrors[fieldName])
      .shift()
    if (field) {
      const element = document.getElementById(field)
      if (element) {
        element.focus()
        element.scrollIntoView(true)
      }
    }
  }

  renderDateOfBirthInput = (formattedDateOfBirth) => {
    const { sanitizedAt } = this.state
    const _Input = !sanitizedAt ? Form.InputDate : Form.InputName

    return (
      <>
        <_Input
          label="Date of Birth"
          value={formattedDateOfBirth}
          error={this.state.inputErrors.dateOfBirth}
          onChange={this.update('dateOfBirth')}
          validate={validateAgeForRentalApplication}
          id="dateOfBirth"
          containerClassName="width100"
          componentClass="margin-profile-item-left"
          viewMode="years"
          customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
          readOnly={!!sanitizedAt}
          disabled={!!sanitizedAt}
        />
        {!!sanitizedAt && (
          <TextSpan fontSize="80%" ml={`${theme.space[4]}px`}>
            Auto-hashed: {moment(sanitizedAt).format(SHORT_STANDARD_DATETIME)}
          </TextSpan>
        )}
      </>
    )
  }

  form() {
    const {
      firstName,
      lastName,
      docType,
      docNumber,
      state,
      country,
      expiryDate,
      dateOfBirth,
      inputErrors,
      middleName,
      middleNameRequired,
      saveClicked,
      apiError,
      attachmentApiError,
      docSpinner,
      identityDocAttachments,
      cardNumber,
      sanitizedAt,
    } = this.state
    const validatedMiddleNameField =
      (middleNameRequired && middleName) || !middleNameRequired

    const disabled =
      docType === 1
        ? !(
            docNumber &&
            state &&
            firstName &&
            lastName &&
            expiryDate &&
            dateOfBirth &&
            isEmptyObject(inputErrors) &&
            validatedMiddleNameField
          )
        : !(
            docNumber &&
            country &&
            firstName &&
            lastName &&
            expiryDate &&
            dateOfBirth &&
            isEmptyObject(inputErrors) &&
            validatedMiddleNameField
          )
    const addIdentityDocTagClass = isCurrentRoute('addBackgroundDetails')
      ? 'identity-document-added'
      : ''
    const formattedExpiryDate =
      this.state.inputErrors.expiryDate !== dateTimeHelpers.INVALID_DATE_STRING
        ? expiryDate &&
          moment(expiryDate).format(dateTimeHelpers.DATE_WITH_SLASH)
        : expiryDate
    const formattedDateOfBirth =
      this.state.inputErrors.dateOfBirth !== dateTimeHelpers.INVALID_DATE_STRING
        ? dateOfBirth &&
          moment(dateOfBirth).format(dateTimeHelpers.DATE_WITH_SLASH)
        : dateOfBirth

    const tipText = `IMPORTANT: please ensure your details match your ${
      docType === DRIVER_LICENCE_DOC_TYPE ? 'Driver Licence' : 'Passport'
    } exactly including full name and date of birth`

    return (
      <div>
        <div className="col-second xs-first mb25">
          <Alert variant="warningWithBg" className="width100">
            {tipText}
          </Alert>
        </div>

        <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
          <div className="col-md-6 p0 width100">
            <Form.InputName
              label="First Name"
              value={firstName}
              error={inputErrors.firstName}
              onChange={this.update('firstName')}
              id="firstName"
              inputClass="width100"
              componentClass="margin-profile-item-left"
            />
          </div>
          <div className="col-md-6 p0 width100">
            <Form.InputName
              label="Last Name"
              value={lastName}
              error={inputErrors.lastName}
              onChange={this.update('lastName')}
              id="lastName"
              inputClass="width100"
              componentClass="margin-profile-item-right"
            />
          </div>
        </Form.ProfilePagesRowLayout>

        <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
          <div className="col-md-6 p0 width100">
            <Checkbox
              value={middleNameRequired}
              onChange={this.updateMiddleNameRequired}
              spanClassName="fs18"
              label="I have a middle name"
              componentClassName="margin-profile-item-left mt20 mb20"
            />
          </div>
          {middleNameRequired && (
            <div className="col-md-6 p0 width100">
              <Form.InputName
                label="Middle Name"
                value={middleName}
                error={inputErrors.middleName}
                onChange={this.update('middleName')}
                id="middleName"
                inputClass="width100"
                componentClass="margin-profile-item-right"
              />
            </div>
          )}
        </Form.ProfilePagesRowLayout>

        <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
          <div className="col-md-6 p0 width100">
            <Form.InputTextRequired
              label={
                docType === 1 ? 'Australian Driver Licence No.' : 'Passport No.'
              }
              id="docNumber"
              value={docNumber}
              error={inputErrors.docNumber}
              onChange={this.update('docNumber')}
              inputClass="width100"
              componentClass="margin-profile-item-left mt0"
              disableOnblurValidation
              disabled={!!sanitizedAt}
              readonly={!!sanitizedAt}
            />
            {!!sanitizedAt && (
              <TextSpan fontSize="80%">
                Auto-hashed:{' '}
                {moment(sanitizedAt).format(SHORT_STANDARD_DATETIME)}
              </TextSpan>
            )}
          </div>
          <div className="col-md-6 p0 width100">
            {docType === 1 ? (
              <>
                <Form.DropdownState
                  inputClass="country-state-dropdown-spacing"
                  name="Select State"
                  onChange={(e) =>
                    this.updateStateCountry('state', e.target.value)
                  }
                  value={this.state.state}
                  componentClass="width100"
                  containerClass="margin-profile-item-right"
                  error={inputErrors.docNumber}
                />
                <div className="ml10">
                  <ErrorBox
                    error={inputErrors.state}
                    showError={!!inputErrors.state.length}
                  />
                </div>
              </>
            ) : (
              <>
                <Form.DropdownCountry
                  inputClass="country-state-dropdown-spacing"
                  name="Country of Issue"
                  onChange={(e) =>
                    this.updateStateCountry('country', e.target.value)
                  }
                  value={this.state.country}
                  componentClass="width100"
                  containerClass="margin-profile-item-right"
                />
                <div className="ml10">
                  <ErrorBox
                    error={inputErrors.country}
                    showError={!!inputErrors.country.length}
                  />
                </div>
              </>
            )}
          </div>
        </Form.ProfilePagesRowLayout>

        <Form.ProfilePagesRowLayout>
          <div className="col-md-6 p0 width50">
            <Form.InputFutureDate
              label="Expiry Date"
              value={formattedExpiryDate}
              error={this.state.inputErrors.expiryDate}
              onChange={this.update('expiryDate')}
              id="expiryDate"
              inputClass="width100"
              containerClassName="width100"
              componentClass="margin-profile-item-left"
              viewMode="months"
              customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
              readOnly={false}
            />
          </div>
        </Form.ProfilePagesRowLayout>
        <Form.ProfilePagesRowLayout>
          <div className="col-md-6 p0 width50">
            {this.renderDateOfBirthInput(formattedDateOfBirth)}
          </div>
        </Form.ProfilePagesRowLayout>
        <SouthAustralianAlert />

        {docType === 1 ? (
          <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
            <div className="col-md-6 p0 width100">
              <Form.InputTextRequired
                label="Card Number"
                value={cardNumber}
                error={this.state.inputErrors.cardNumber}
                onChange={this.update('cardNumber')}
                id="cardNumber"
                inputClass="width100"
                containerClassName="width100"
                componentClass="margin-profile-item-left"
              />
            </div>
            <div className="col-md-6 p0 width100">
              <Flex alignItems="center" height="50px">
                <Box pt={3}>
                  <a
                    href={
                      'https://help.snug.com/hc/en-us/articles/5375224290447'
                    }
                    target="_blank"
                    className="linkStyle-blue"
                    rel="noopener noreferrer"
                  >
                    Find your card number
                  </a>
                </Box>
              </Flex>
            </div>
          </Form.ProfilePagesRowLayout>
        ) : null}

        <div className="row" />
        <Layout.ContainerHeader
          text="Upload a copy of the identity document"
          isEditable={false}
        />
        <Layout.SnugTip
          text="Where possible, please include the front and back of your ID document (e.g. Driver Licence or ID card"
          componentClass="mt25"
        />
        <DocumentList
          documents={identityDocAttachments}
          addDocument={this.addIdentityDocAttachment}
          deleteDocument={this.deleteIdentityDocAttachment}
          documentType={DocumentTypes.IdentityDocument}
          spinner={docSpinner}
          responseText={attachmentApiError}
          disabled={!!sanitizedAt}
          allowDeletion={true}
          showAutoDeleteWarning={true}
        />

        {apiError && (
          <ErrorMessage
            error={
              ErrorMessages[parseInt(apiError, 10)]
                ? ErrorMessages[parseInt(apiError, 10)]
                : `There is an error: ${apiError}`
            }
          />
        )}

        <AlertSensitiveInfo />

        <div className="row pt25">
          <div className="col-sm-5 pb5 tablet-only">
            <button
              onClick={() => {
                history.goBack()
              }}
              className="btn btn-transparent btn-left xs-text-center wa"
            >
              <i className="icon-arrow-left left" />
              <span>Back</span>
            </button>
          </div>
          <div className="col-sm-7 pb5">
            <button
              disabled={saveClicked || !!sanitizedAt}
              className={`btn ${addIdentityDocTagClass}`}
              onClick={this.submit}
            >
              Save
              <i className={saveClicked ? 'fa fa-spinner fa-pulse' : ''} />
            </button>
          </div>
        </div>
      </div>
    )
  }

  submit = () => {
    const {
      firstName = '',
      lastName = '',
      docNumber = '',
      middleName = '',
      guidID = '',
      dateOfBirth = '',
      expiryDate = '',
      middleNameRequired,
      cardNumber = '',
    } = this.state
    if (!this.validateRequiredFields()) {
      this.focusAndScrollToFirstError(this.state.inputErrors)
      return
    }
    this.setState({ unsavedChanges: false })
    const data = !this.state.middleNameRequired
      ? Object.assign({}, this.state, {
          firstName: firstName.trim(),
          middleName: '',
          middleNameRequired: false,
          lastName: lastName.trim(),
          docNumber: docNumber.trim(),
          dateOfBirth: dateOfBirth,
          expiryDate: expiryDate,
          cardNumber,
        })
      : Object.assign({}, this.state, {
          firstName: firstName.trim(),
          middleName: middleName.trim(),
          lastName: lastName.trim(),
          docNumber: docNumber.trim(),
          dateOfBirth: dateOfBirth,
          expiryDate: expiryDate,
          cardNumber,
        })

    const {
      initDetails: {
        firstName: initFirstName,
        lastName: initLastName,
        middleName: initMiddleName,
      },
    } = this.state

    if (
      initFirstName !== data.firstName ||
      initLastName !== data.lastName ||
      (middleNameRequired && initMiddleName !== data.middleName)
    ) {
      this.setState({
        showReviewDataModel: true,
        finalDocData: data,
      })
    } else {
      this.updateOrCreateDoc(data, guidID)
    }
  }

  updateOrCreateDoc = (data, guidID) => {
    const { createIdentityDoc, updateIdentityDoc } = this.props

    this.setState({ saveClicked: true })

    if (guidID !== undefined && !isCurrentRoute('addIdentityDocDetails')) {
      updateIdentityDoc(data, guidID)
        .then(() => {
          this.setState({
            saveClicked: true,
            apiError: '',
            guidID: '',
          })
        })
        .catch(logDocErrors)
        .catch((error) =>
          this.setState({ saveClicked: false, apiError: error }),
        )
    } else {
      createIdentityDoc(data, false)
        .then(() => {
          this.setState({
            saveClicked: true,
            apiError: '',
          })
          window.removeEventListener('beforeunload', this.onUnloadHandler)
          history.goBack()
        })
        .catch(logDocErrors)
        .catch((error) =>
          this.setState({ saveClicked: false, apiError: error }),
        )
    }
  }

  update = (field) => {
    return (data) => {
      const { country, docType, docNumber, state } = this.state
      let updatedError = data.error
      let value = data.value
      if (field === 'docNumber' && isPassport(docType)) {
        updatedError = firstElementOrEmptyString(
          validations.validatePassport(data.value, country),
        )
      } else if (field === 'docNumber' && isLicence(docType)) {
        updatedError = firstElementOrEmptyString(
          validations.validateLicence(data.value),
        )
      }
      if (field === 'cardNumber' && isLicence(docType)) {
        updatedError = firstElementOrEmptyString(
          validations.validateDriverLicenceCardNumber(data.value, state),
        )
        if (data.value && data.value === docNumber) {
          updatedError = 'Card number should differ from Licence number'
        }
      }
      if (
        updatedError === '' &&
        (field === 'expiryDate' || field === 'dateOfBirth')
      ) {
        value =
          data.value !== ''
            ? moment(data.value).format(helpers.dateWithDash)
            : ''
      }
      this.setState({
        [field]: value,
        inputErrors: {
          ...this.state.inputErrors,
          [field]: updatedError,
        },
      })
    }
  }

  updateDocumentType = (field) => {
    const { createIdentityDoc, currentUser } = this.props
    const { docNumber, country } = this.state
    if (isCurrentRoute('addIdentityDocDetails')) {
      window.addEventListener('beforeunload', this.onUnloadHandler)
    }
    return (event) => {
      this.setState(
        {
          [field]: parseInt(event.target.value, 10),
          unsavedChanges: true,
        },
        () => {
          let docNumberError = ''
          if (docNumber && isPassport(this.state.docType)) {
            docNumberError = validations.validatePassport(docNumber, country)
          } else if (docNumber && isLicence(this.state.docType)) {
            docNumberError = validations.validateLicence(docNumber)
          }
          this.setState({
            inputErrors: {
              ...this.state.inputErrors,
              docNumber: docNumberError,
            },
          })
        },
      )
      if (field === 'docType' && this.state.guidID === undefined) {
        createIdentityDoc(
          defaultIdentityPayload(currentUser.guidID, event.target.value),
          true,
        )
          .then((identityDoc) => {
            this.setState({
              guidID: identityDoc.guidID,
              apiError: '',
            })
          })
          .catch(logDocErrors)
          .catch((error) => this.setState({ apiError: error }))
      }
    }
  }

  updateMiddleNameRequired = () => {
    this.setState({
      middleNameRequired: !this.state.middleNameRequired,
      inputErrors: { ...this.state.inputErrors, middleName: '' },
    })

    if (!this.state.middleNameRequired) {
      this.setState({
        middleName: '',
      })
    }
  }

  updateStateCountry = (field, value) => {
    const { docNumber } = this.state
    const newInputErrors = Object.assign({}, this.state.inputErrors)
    if (field === 'country' && docNumber !== '') {
      newInputErrors.country = firstElementOrEmptyString(
        validations.validateCountry(value),
      )
      newInputErrors.docNumber = firstElementOrEmptyString(
        validations.validatePassport(docNumber, value),
      )
    } else if (field === 'state') {
      if (value === '') {
        newInputErrors.state = 'Invalid State'
      } else {
        newInputErrors.state = ''
      }
    }
    this.setState({
      [field]: value,
      unsavedChanges: true,
      inputErrors: newInputErrors,
    })
  }

  validateRequiredFields = () => {
    const inputErrors = {}
    const { docType } = this.state || {}
    Object.keys(this.state.inputErrors)
      .filter(
        (field) => field !== 'middleName' || this.state.middleNameRequired,
      )
      .filter((field) => field !== 'cardNumber' || !isPassport(docType))
      .map((field) => {
        if (this.state[field] === '' || this.state[field] === null) {
          inputErrors[field] = 'This field is required'
        }
        if (isPassport(this.state.docType)) {
          delete inputErrors.state
        }
        if (field === 'dateOfBirth' && !!!this.state[field]) {
          delete inputErrors.dateOfBirth
        }
        return ''
      })
    this.setState({
      inputErrors: {
        ...this.state.inputErrors,
        ...inputErrors,
      },
    })
    return Object.keys(inputErrors).length === 0
  }

  toggleReviewDataModal = () => {
    this.setState({
      showReviewDataModel: !this.state.showReviewDataModel,
    })
  }

  render() {
    const {
      docType,
      unsavedChanges,
      showReviewDataModel,
      finalDocData,
      guidID,
    } = this.state

    const renderReviewDataModal = showReviewDataModel && finalDocData

    return (
      <div className="sm-property-create basics">
        <div className="mobile-only">
          <Link
            to={urlTo('BackgroundCheck')}
            className="btn btn-transparent btn-left xs-text-center wa"
          >
            <i className="icon-arrow-left left" />
            <span>Back</span>
          </Link>
        </div>
        <Layout.SectionHeader
          text="Add Identity Document"
          hasSeperator={false}
          textClass="pl0"
        />

        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <div className="row">
              <div className="col-sm-12">
                <Layout.GreyBodyText
                  text={
                    <div>
                      Snug needs a current <b> Australian Driver Licence</b> or{' '}
                      <b>Passport</b> to verify your identity (each is worth 50
                      points).
                    </div>
                  }
                />
                <div className="ilustration-box">
                  <img src={headerImg04} alt="Identity Document card" />
                </div>
              </div>
            </div>
            <div>
              <Layout.GreyBodyText
                text={
                  docType
                    ? ''
                    : 'Please select valid identity document to continue:'
                }
              />

              <div className="row">
                <div className="col-sm-12">
                  <div className="snug-tabs">
                    <label>
                      <input
                        type="radio"
                        name="docType"
                        value={1}
                        checked={docType === 1}
                        onChange={this.updateDocumentType('docType')}
                      />
                      <span>Driver Licence</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="docType"
                        value={2}
                        checked={docType === 2}
                        onChange={this.updateDocumentType('docType')}
                      />
                      <span>Passport</span>
                    </label>
                  </div>
                </div>
              </div>
              {!docType && <AlertSensitiveInfo />}
              {docType ? this.form() : ''}
            </div>
          </div>
          <div className="col-second xs-first">
            <Layout.SnugTip text="Snug uses bank-level 256-bit security to protect your data." />
          </div>
        </div>
        <UnsavedMessage unsavedChanges={unsavedChanges} />
        <ReactTooltip className="tooltip" />

        {renderReviewDataModal && (
          <ReviewDataModal
            updateOrCreateDoc={this.updateOrCreateDoc}
            toggleReviewDataModal={this.toggleReviewDataModal}
            finalDocData={finalDocData}
            guidID={guidID}
            docType={docType}
          />
        )}
      </div>
    )
  }
}

export default IdentityDoc
