import React from 'react'

import moment from 'moment'

import TenantForm from 'app/bond_cover/renters/tenants/tenant_form.jsx'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import isEmptyObject from 'app/shared_components/check_empty_object'
import { ErrorMessage } from 'app/shared_components/helpers'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'
import * as validations from 'app/shared_components/validations'

class TenantFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      guidID: null,
      requestID: '',
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      disabled: true,
      clicked: false,
      errors: {},
      apiError: '',
    }
  }

  componentDidMount() {
    let { fetchTenant } = this.props

    let requestID = getParameter('requestid')
    this.setState({ requestID: requestID })

    let guidID = getParameter('id')
    this.setState({ guidID: guidID })

    if (guidID) {
      fetchTenant(requestID, guidID)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { tenant, apiError } = nextProps
    let errors = {}

    this.setState({ clicked: false, apiError: apiError })

    if (tenant && tenant.guidID) {
      this.setState({
        firstName: tenant.firstName,
        lastName: tenant.lastName,
        email: tenant.email,
        mobile: tenant.mobile,
      })
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    nextState.disabled = !(
      nextState.firstName &&
      nextState.lastName &&
      nextState.email &&
      !nextState.clicked &&
      isEmptyObject(nextState.errors)
    )
  }

  onCountrySelected = () => {
    this.setState({
      errors: {
        mobile: [
          ErrorMessages[Errors.ErrorWhenConvertingMobileToInternationalFormat],
        ],
      },
    })
  }

  onMobileChange = () => {
    return (status, value, countryData, number, id) => {
      this.setState({ mobile: number.trim() })
      this.validatePhoneNumber()
    }
  }

  setValidationErrors = (field, error) => {
    let { errors } = this.state
    if (error.length == 0) {
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  submit = () => {
    let { addTenant, updateTenant } = this.props
    let { requestID, guidID } = this.state
    if (this.validateAll()) {
      this.setState({ clicked: true })
      if (this.state.guidID) {
        updateTenant(requestID, guidID, this.state)
      } else {
        addTenant(requestID, this.state)
      }
    }
  }

  trimField = (field) => {
    return (event) => {
      let value = this.state[field].trim()
      this.validate(field, value)()
      this.setState({ [field]: value })
    }
  }

  update = (field) => {
    return (event) => {
      let { value } = event.target
      this.setState({ [field]: value }, this.validate(field, value))
    }
  }

  updateEmail = (field) => {
    return (event) => {
      let { value } = event.target
      value = value.toLowerCase()
      this.setState({ [field]: value }, this.validate(field, value))
    }
  }

  validate = (field, value) => {
    return (event) => {
      switch (field) {
        case 'firstName':
          return this.setValidationErrors(
            field,
            validations.validateName(value),
          )
        case 'lastName':
          return this.setValidationErrors(
            field,
            validations.validateName(value),
          )
        case 'email':
          return this.setValidationErrors(
            field,
            validations.validateEmail(value),
          )
      }
    }
  }

  validateAll = () => {
    let valid = true
    let fields = ['firstName', 'lastName', 'email']

    fields.forEach((field) => {
      if (!this.validate(field, this.state[field])()) {
        valid = false
      }
    })
    return valid
  }

  validatePhoneNumber = () => {
    return (status, value, countryData, number, id) => {
      this.setValidationErrors(
        'mobile',
        status
          ? []
          : [
              ErrorMessages[
                Errors.ErrorWhenConvertingMobileToInternationalFormat
              ],
            ],
      )
    }
  }

  render() {
    return (
      <TenantForm
        firstName={this.state.firstName}
        lastName={this.state.lastName}
        email={this.state.email}
        mobile={this.state.mobile}
        errors={this.state.errors}
        apiError={this.state.apiError}
        clicked={this.state.clicked}
        updateEmail={this.updateEmail}
        trimField={this.trimField}
        update={this.update}
        submit={this.submit}
        requestID={this.state.requestID}
        disabled={this.state.disabled}
        onCountrySelected={this.onCountrySelected}
        validatePhoneNumber={this.validatePhoneNumber}
        onMobileChange={this.onMobileChange}
      />
    )
  }
}

export default TenantFormContainer
