import React from 'react'

import { urlTo, wait, windowUrl } from 'app/sm/helpers'

class NoApplicationsHelper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      linkCopied: false,
    }
  }

  copyLinkToClipboard(event) {
    const target = event.target
    try {
      target.select()
      document.execCommand('copy')
      this.setState({ linkCopied: true })
    } catch (err) {
      // TODO: Deal with crash ( but this is a little bit overly defensive, we probably would never enter here)
    } finally {
      wait(3000).then(() => this.setState({ linkCopied: false })) // Just to be in sync with the animation
    }
  }

  render() {
    const { linkCopied } = this.state
    const { propertySlug } = this.props
    const shareLink = `${windowUrl()}${urlTo('applyRedirect', {
      slug: propertySlug,
    })}`

    return (
      <div className="no-applications-helper">
        <h2 className="mb10">Share your link to invite rental applications</h2>
        <div className="mb15">
          <h4 className="ibm">Click to copy and share:</h4>
          {linkCopied && (
            <span className={`ibm pink-color pl15 pt2`}>
              (Link copied, now you can share it)
            </span>
          )}
        </div>
        <input
          title="Click to copy"
          className="shareLink mb50"
          type="text"
          value={shareLink}
          onClick={(e) => this.copyLinkToClipboard(e)}
          readOnly
        />
      </div>
    )
  }
}

export default NoApplicationsHelper
