import React from 'react'

import { formatRent } from 'app/sm/helpers'
import { ApplicationDetailsNumbersComponent } from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'

import 'app/match/apply/apply_form/components/property_manager_preference/style.scss'

const PMPreferenceBox = ({
  acceptedLeaseLength = 0,
  weeklyRent = '',
  allowPets = false,
  ballotsEnabled = false,
}) => {
  const formattedRent = formatRent(weeklyRent)
  let textForTerm = acceptedLeaseLength || 'N/A'
  let extraTextForTerm = acceptedLeaseLength && 'months'
  if (ballotsEnabled) {
    textForTerm = 'up to 3 years'
    extraTextForTerm = ''
  }
  return (
    <div className="property-manager-preference-container col-sm-6">
      <div className="property-manager-preference-inner-container">
        <span className="property-manager-heading">
          Property Manager&apos;s Preferences
        </span>
        <div className="property-manager-preference-text-container">
          <ApplicationDetailsNumbersComponent
            title="Rent"
            text={formattedRent}
            titleTextClassName="property-manager-preference-label"
            textClassName="property-manager-preference-content"
            componentClassName="property-manager-preference-details"
            componentMinWidth="width-auto"
            disableWidthSetting
          />
          <ApplicationDetailsNumbersComponent
            title="Term"
            text={textForTerm}
            extraText={extraTextForTerm}
            titleTextClassName="property-manager-preference-label"
            textClassName="property-manager-preference-content"
            componentClassName="property-manager-preference-details"
            componentMinWidth="width-auto"
            disableWidthSetting
          />
          <ApplicationDetailsNumbersComponent
            title="Pets"
            text="On Application"
            titleTextClassName="property-manager-preference-label"
            textClassName="property-manager-preference-content"
            componentClassName="property-manager-preference-details"
            componentMinWidth="width-auto"
            disableWidthSetting
          />
        </div>
      </div>
    </div>
  )
}

export default PMPreferenceBox
