import { useEffect, useState } from 'react'

import styled from 'styled-components'

import { Button, Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  EXCLUDE_DATE_RANGE_TYPE,
  EXCLUDE_DATETIME_RANGE_TYPE,
  EXCLUDE_SCHEDULE_TYPE,
} from 'app/sm/properties/components/excluded_times/constants'
import OptionsDropdown from 'app/sm/properties/components/excluded_times/options_dropdown'
import PropertyDateRange from 'app/sm/properties/components/excluded_times/selectors/date_range'
import PropertyDateTimeRange from 'app/sm/properties/components/excluded_times/selectors/date_time_range'
import PropertySchedule from 'app/sm/properties/components/excluded_times/selectors/schedule'

const StyledPropertyOptionRowContainer = styled(Flex)`
  .deleteButton {
    margin-left: ${theme.space[5]}px;
    text-decoration: underline;
    font-weight: ${theme.fontWeights[3]} !important;
    font-size: ${theme.space[5]}px !important;
  }

  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;

    .dateDropdownButton {
      min-width: 100% !important;
    }

    .optionsDropdown {
      margin-bottom: 8px;
      width: 100% !important;

      button:not(.deleteButton) {
        width: 100% !important;
      }
    }

    .deleteButtonContainer {
      display: inline-block;
      text-align: center;
      margin-top: 8px;
    }
  }
`

const SelectorsMap = {
  [EXCLUDE_SCHEDULE_TYPE]: PropertySchedule,
  [EXCLUDE_DATETIME_RANGE_TYPE]: PropertyDateTimeRange,
  [EXCLUDE_DATE_RANGE_TYPE]: PropertyDateRange,
}

const PropertyOptionRow = ({
  deleteEntry,
  onEntryChanged,
  entryInitState,
  disabled = false,
}) => {
  const [currentSelector, setCurrentSelector] = useState(null)

  useEffect(() => {
    const { type } = entryInitState

    if (!!type) {
      setCurrentSelector(type)
    }
  }, [])

  const renderSelectorComponent = () => {
    if (!currentSelector) return null

    const Selected = SelectorsMap[currentSelector]

    return (
      <Selected initialState={entryInitState} onEntryChange={onEntryChanged} />
    )
  }

  return (
    <StyledPropertyOptionRowContainer>
      <Flex flexDirection="column">
        <OptionsDropdown
          entryInitState={entryInitState}
          onSelectorChanged={setCurrentSelector}
        />
      </Flex>

      {renderSelectorComponent()}

      {!disabled && (
        <Flex className="deleteButtonContainer">
          <Button
            variant="linkBlue"
            onClick={deleteEntry}
            className="deleteButton"
            width="50px"
          >
            Delete
          </Button>
        </Flex>
      )}
    </StyledPropertyOptionRowContainer>
  )
}

export default PropertyOptionRow
