import { connect } from 'react-redux'

import { fetchTeamManagerList } from 'app/bond_cover/agency/agency_actions'
import {
  resetPropertyFilters,
  setPropertyFilters,
} from 'app/dashboard/team_overview_actions'
import AgencySearchSectionContainer from 'app/pages/teams/viewings-mobile/components/AgencyFilter/index'
import { persistManager } from 'app/sm/properties/properties_actions'
import { getManagersListForViewingFilters } from 'app/sm/viewings/viewings_actions'

const mapStateToProps = ({ session, MyProperties, TeamOverview }) => ({
  teams: session.teams,
  currentTeam: session.currentTeam,
  persistedManager: MyProperties.persistedManager,
  filters: TeamOverview.filters,
})

const mapDispatchToProps = (dispatch) => ({
  persistManager: (manager) => dispatch(persistManager(manager)),
  setPropertyFilters: (filters) => dispatch(setPropertyFilters(filters)),
  resetPropertyFilters: () => dispatch(resetPropertyFilters()),
  getManagersListForViewingFilters: (teamGUID) =>
    dispatch(getManagersListForViewingFilters(teamGUID)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgencySearchSectionContainer)
