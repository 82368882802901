import styled from 'styled-components'

export const StyledVehicleContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 16px;
  margin-left: 20px;
  margin-right: 20px;
`

export const StyledVehicleInfo = styled.div`
  width: 80%;
  margin: 24px auto 24px auto;

  p {
    color: #686d71;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0;
  }
`
