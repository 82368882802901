import qs from 'qs'

const YoutubeHost = 'www.youtube.com'

export const isValidURL = (url) => {
  let valid = false
  const expression =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  const pattern = new RegExp(expression)
  if (url.match(pattern)) {
    const newUrl = new URL(url)
    const hostname = newUrl.hostname
    valid = hostname === YoutubeHost
  }
  return valid
}

const youtube_parser = (url) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  var match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}

export const adjustYoutubeURL = (url) => {
  if (url === '') return ''
  const realYoutubeURL = youtube_parser(url)
  if (realYoutubeURL === false) return false
  return `https://www.youtube.com/embed/${realYoutubeURL}`
}

export const RR_TO_LITE_URL_PART = 'rental-references-rr-l'

export const APPLICATION_APPLY_URL = '/sm/property/:propertyId/apply'

export const isRRToLiteURL = () => {
  return window.location.href.indexOf(RR_TO_LITE_URL_PART) > -1
}

export const isRRToLiteGuest = () => {
  const curQueries = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  return String(curQueries.guest).toLowerCase() === 'true'
}
