import { connect } from 'react-redux'

import { fetchPropertyOfferInfoWithDetails } from 'app/dashboard/dashboard_actions'
import { addPropertyDisclosuresForOffer } from 'app/pages/teams/onlist/action'
import OnlistPropertyOffer from 'app/pages/teams/onlist/index'
import {
  addCurrentOwners,
  addCurrentTenants,
  fetchAgencyApplicantsElasticSearchResult,
  fetchCurrentOwners,
  fetchCurrentTenants,
  removeCurrentOwners,
  removeCurrentTenants,
  sendEntryNotice,
  updateCurrentOwners,
  updateCurrentTenants,
} from 'app/sm/inspections/inspections_actions'

const mapStateToProps = ({ session }) => ({
  teams: session.teams,
  currentTeam: session.currentTeam,
  currentUser: session.currentUser,
  currentAgency: session.currentAgency,
})

const mapDispatchToProps = (dispatch) => ({
  fetchAgencyApplicantsElasticSearchResult: (teamId, queryString) =>
    dispatch(fetchAgencyApplicantsElasticSearchResult(teamId, queryString)),
  fetchPropertyOfferInfoWithDetails: (agencyGUID, offerGUID) =>
    dispatch(fetchPropertyOfferInfoWithDetails(agencyGUID, offerGUID)),
  fetchCurrentOwners: (teamId, queryString) =>
    dispatch(fetchCurrentOwners(teamId, queryString)),
  addCurrentOwners: (data, teamId) => dispatch(addCurrentOwners(data, teamId)),
  updateCurrentOwners: (teamId, ownerId, data) =>
    dispatch(updateCurrentOwners(teamId, ownerId, data)),
  removeCurrentOwners: (teamId, contactId, propertyId) =>
    dispatch(removeCurrentOwners(teamId, contactId, propertyId)),
  fetchCurrentTenants: (teamId, queryString) =>
    dispatch(fetchCurrentTenants(teamId, queryString)),
  addCurrentTenants: (data, propertyId) =>
    dispatch(addCurrentTenants(data, propertyId)),
  updateCurrentTenants: (propertyId, tenantId, data) =>
    dispatch(updateCurrentTenants(propertyId, tenantId, data)),
  removeCurrentTenants: (propertyId, tenantId) =>
    dispatch(removeCurrentTenants(propertyId, tenantId)),
  sendEntryNotice: (offerId, data) => dispatch(sendEntryNotice(offerId, data)),
  addPropertyDisclosuresForOffer: (agencyGUID, offerGUID, data) =>
    dispatch(addPropertyDisclosuresForOffer(agencyGUID, offerGUID, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OnlistPropertyOffer)
