import React, { useEffect, useState } from 'react'

import { Form, Formik } from 'formik'
import styled from 'styled-components'

import { Alert, Box } from 'app/components/design-system-components'
import { CheckboxesInputField } from 'app/components/design-system-components/inputs/Checkboxes'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { PageSection } from 'app/components/design-system-components/page/Section'
import { Scrollbar } from 'app/components/design-system-components/styles/mixins/Scrollbar'
import { followsCategoriesIds } from 'app/features/follows/constants'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { updateFollowedTeamMembers } from 'app/services/http/teams/settings/follows'

const formFields = {
  members: 'members',
}

const TeamMembersOptionsContainer = styled(Box)`
  max-height: 300px;
  overflow-y: auto;

  ${Scrollbar()}
`

export const UpdateFollowedMembersModal = ({
  currentTeam,
  teamMembers,
  followedTeamMembers,
  onCloseModal,
}) => {
  const { guid: teamId } = currentTeam
  const [initialValues, setInitialValues] = useState({
    [formFields.members]: {},
  })

  const [errorMessage, setErrorMessage] = useState('')

  const teamMembersOptions = (teamMembers || [])
    .filter(({ isMe }) => !isMe)
    .map(({ contact }) => {
      const { guidID, firstName, lastName } = contact
      return {
        optionMeta: {
          id: guidID,
          displayText: `${firstName} ${lastName}`,
        },
        option: contact,
      }
    })

  useEffect(() => {
    const followedTeamMembersMap = (followedTeamMembers || []).reduce(
      (accMap, followedTeamMemberFollows) => {
        const { team_member } = followedTeamMemberFollows
        return {
          ...accMap,
          [team_member.id]: followedTeamMemberFollows,
        }
      },
      {},
    )

    const teamMembersFollowsValue = teamMembersOptions.reduce(
      (accMembersFollowsValue, teamMemberOption) => {
        const { id } = teamMemberOption.optionMeta
        const followsData = followedTeamMembersMap[id]

        // for now, we don't care of which category is followed
        const isFollowed = !!(followsData?.follows || []).length

        if (!isFollowed) return accMembersFollowsValue
        return {
          ...accMembersFollowsValue,
          [id]: teamMemberOption,
        }
      },
      {},
    )

    setInitialValues({ [formFields.members]: teamMembersFollowsValue })
  }, [teamMembers, followedTeamMembers])

  const onSubmit = (values) => {
    setErrorMessage('')
    const followedTeamMembers = values[formFields.members] || {}
    const unfollowedTeamMembers = teamMembersOptions.filter(
      ({ optionMeta }) => !followedTeamMembers[optionMeta.id],
    )

    const followedTeamMembersPayload = Object.values(followedTeamMembers).map(
      ({ option }) => ({
        followed_member_id: option.guidID,
        categories: [followsCategoriesIds.applications],
      }),
    )

    const unfollowedTeamMembersPayload = Object.values(
      unfollowedTeamMembers,
    ).map(({ option }) => ({
      followed_member_id: option.guidID,
      categories: [],
    }))

    return updateFollowedTeamMembers(teamId, {
      followed_members: [
        ...followedTeamMembersPayload,
        ...unfollowedTeamMembersPayload,
      ],
    })
      .then(() => onCloseModal(true))
      .catch(({ combinedErrorMessage, ...error }) => {
        setErrorMessage(combinedErrorMessage)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <GenericModal
            title="Select team members"
            onDismiss={() => onCloseModal()}
            modalFooter={
              <GenericModalFooter
                primaryBtnConfig={{
                  title: 'Save',
                  props: {
                    loading: !!isSubmitting,
                    type: 'submit',
                  },
                }}
                secondaryBtnConfig={{
                  title: 'Cancel',
                  onClick: () => onCloseModal(),
                }}
              />
            }
          >
            {!!errorMessage && (
              <Alert variant="warningWithBg" mb={theme.space[3] + 'px'}>
                {errorMessage}
              </Alert>
            )}

            <PageSection lineHeight="1.2">
              Choose the the team members to follow. You will receive
              Application notifications for the selected team members.
            </PageSection>
            <TeamMembersOptionsContainer>
              <CheckboxesInputField
                id={formFields.members}
                name={formFields.members}
                options={teamMembersOptions}
                optionRenderer={({ optionMeta }) => optionMeta.displayText}
              />
            </TeamMembersOptionsContainer>
          </GenericModal>
        </Form>
      )}
    </Formik>
  )
}
