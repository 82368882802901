import moment from 'moment'

import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import { stateTypes } from 'app/constants/identity-docs.constants'
import * as helpers from 'app/sm/helpers'
import { getCurrentDate } from 'app/utils/datetime/helpers'

function validateRegex(value, regex, message) {
  const errors = []
  if (!regex.exec(value)) {
    errors.push(message)
  }
  return errors
}

function validateMobileMatch(confirmedMobile, mobile = '', message) {
  const errors = []

  if ((confirmedMobile || '').trim() !== (mobile || '').trim()) {
    errors.push(message)
  }
  return errors
}

function validateEmailMatch(confirmedEmail, email = '', message) {
  const errors = []
  if (email && confirmedEmail) {
    // eslint-disable-next-line no-unused-expressions
    confirmedEmail.trim() !== email.trim() ? errors.push(message) : ''
  } else if (!confirmedEmail || !email) {
    errors.push(message)
  }
  return errors
}

export function validateName(name, errorMessage = 'Invalid Name') {
  return validateRegex(
    name,
    /^(\s)*[A-Za-z]+((\s)?((['\-.])?([A-Za-z])+))*(\s)*$/,
    errorMessage,
  )
}

export function validateTextWithoutNumber(value, message) {
  return validateRegex(
    value,
    /^(\s)*[A-Za-z]+((\s)?((['\-.])?([A-Za-z])+))*(\s)*$/,
    message || 'Invalid field',
  )
}

export function validateNameOptional(name) {
  return validateRegex(
    name,
    /(^$)|(^(\s)*[A-Za-z]+((\s)?((['\-.])?([A-Za-z])+))*(\s)*$)/,
    'Invalid Name',
  )
}

export function validateAgencyName(agency) {
  return validateRegex(agency, /.+/, 'Invalid Agency Name')
}

export function validateNotEmpty(value) {
  return validateRegex(value, /.+/, 'This field is required')
}

export function validateNumber(number, message) {
  return validateRegex(number, /(^\d{1,9}$)/, message)
}

export function validateCurrency(number, message) {
  return validateRegex(number, /(^\s*-?[1-9]\d*(\.\d{1,2})?\s*$)/, message)
}

export function validateNumberWithTwoDecimals(number, message) {
  return validateRegex(number, /(^\s*-?[1-9]\d*(\.\d{1,2})?\s*$)/, message)
}

export function validateNumberWithTwoDecimalsAndZero(number, message) {
  return validateRegex(number, /(^\s*-?[0-9]\d*(\.\d{1,2})?\s*$)/, message)
}

export function validateNumberWithDecimals(number, message) {
  return validateRegex(number, /^\d*\.?\d*$/, message)
}

export function validateREAAgencyID(value, message) {
  return validateRegex(value.trim(), /(^$)|(^[0-9a-zA-Z_-]{1,30}$)/, message)
}

export function validateStreetNumber(number, message) {
  const errors = [message]
  if (!number) {
    return errors
  }
  return validateRegex(number, /^.*/, message)
}

export function validateAddressName(name, message) {
  const errors = [message]
  if (!name) {
    return errors
  }
  return validateRegex(
    name,
    /(^$)|(^(\s)*[A-Za-z]+((\s)?((['\-.])?([A-Za-z])+))*(\s)*$)/,
    message,
  )
}

export function validateStateName(name, message) {
  const errors = [message]
  if (!name || name === '' || name === 'State') {
    return errors
  }
  return []
}

export function validateSuburb(name, message) {
  const errors = [message]
  if (!name || name === '') {
    return errors
  }
  return []
}

export function validatePostcode(postcode, message) {
  const errors = [message]
  if (!postcode) {
    return errors
  }
  return validateRegex(postcode, /^[0-9]{3,4}$/, message)
}

export function validateBond(
  minimum,
  value,
  maximum,
  emptyMessage,
  minimumMessage,
  maximumMessage,
) {
  const errors = []
  const regex = /(^\s*-?[1-9]\d*(\.\d{1,2})?\s*$)/

  if (!value || value === '') {
    errors.push(emptyMessage)
  } else if (value < minimum) {
    errors.push(minimumMessage)
  } else if (value > maximum) {
    errors.push(maximumMessage)
  } else if (!regex.exec(value)) {
    errors.push('bond amount invalid')
  }

  return errors
}

export function validateEmail(email, userInfo) {
  if (email === userInfo) {
    return ErrorMessages[Errors.ErrorManagerCannotBeTenant]
  }
  return validateRegex(
    email,
    /^\w+([.-]\w+)*(['+]\w+)*@\w+([.-]\w+)*\.\w{2,}$/,
    'Invalid Email',
  )
}

// Quick dirty fix to solve optional email. Needs refactoring
export function validateEmailOptional(email) {
  if (!email) {
    return []
  }

  return validateRegex(
    email,
    /^\w+([.-]?\w+)*([']?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})$/,
    'Invalid Email',
  )
}

export function validateMobile(mobile, userInfo = '') {
  const formattedUserInfo = userInfo.replace(/\s+/g, '')
  let formattedMobile = mobile
  if (formattedMobile) {
    formattedMobile = formattedMobile.replace(/\s+/g, '')
  }
  if (formattedMobile && formattedMobile === formattedUserInfo) {
    return ErrorMessages[Errors.ErrorManagerCannotBeTenant]
  }
  return validateRegex(
    formattedMobile,
    /(^$)|(^04\d{8}$)|(^\+614\d{8}$)/,
    ErrorMessages[Errors.ErrorValidatingMobile],
  )
}

export function validateConfirmedMobile(confirmedMobile, mobile) {
  return validateMobileMatch(
    confirmedMobile,
    mobile,
    `Mobile Numbers don't match`,
  )
}

export function validateConfirmedEmail(confirmedEmail, email) {
  return validateEmailMatch(confirmedEmail, email, `Emails don't match`)
}

export function validateMobileOptional(mobile, userInfo) {
  let formattedUserinfo = userInfo
  let formattedMobile = mobile
  if (formattedUserinfo) {
    formattedUserinfo = userInfo.replace(/\s+/g, '')
  }
  if (formattedMobile) {
    formattedMobile = mobile.replace(/\s+/g, '')
  }
  if (formattedMobile === formattedUserinfo) {
    return ErrorMessages[Errors.ErrorManagerCannotBeTenant]
  }
  return validateRegex(
    mobile,
    /(^$)|(^04\d{8}$)|(^\+61\d{9}$)/,
    'Invalid Mobile',
  )
}

export function validateDate(date) {
  return validateRegex(date, /(^(\d{4}-\d{2}-\d{2})$)/, 'Invalid Date')
}

export function validateBondReference(bondReference) {
  return validateRegex(
    bondReference,
    /^.{1,15}$/,
    'Bond Reference is 1-15 characters',
  )
}

export function validateApplicationReference(applicationReference) {
  return validateRegex(
    applicationReference,
    /.{1,15}$/,
    'Application Reference is 1-15 characters',
  )
}

export function validatePassword(password) {
  return validateRegex(
    password,
    /^.{6,}$/,
    'Password length needs to be at least 6 characters',
  )
}

export const PASSWORD_INVALID_MSG =
  'Password should contain 8-32 uppercase, lowercase, numeric, and special characters'

export function validateNewPassword(password) {
  return validateRegex(
    password,
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*-_+=]).{8,32}$/,
    PASSWORD_INVALID_MSG,
  )
}

export function validateBSB(bsb) {
  return validateRegex(bsb, /(^\d{6}$)/, 'BSB must be 6 numbers')
}

export function validateBankAccount(account) {
  return validateRegex(
    account,
    /(^\d{6,10}$)/,
    'Bank Account Number is between 6-10 numbers',
  )
}

export const validateAccountName = (accountName) => {
  return validateRegex(
    accountName,
    /^[\w\-\s]+$/,
    'Bank Account Name is alphanumeric with spaces',
  )
}

export function validateBillerCode(account) {
  return validateRegex(account, /^\d+$/, 'BPAY Biller code is numbers only')
}

export function validateLicence(license) {
  const errors = []

  if (
    validateRegex(
      license,
      /^[a-zA-Z0-9]{2}[0-9]{2}[a-zA-Z0-9]{0,5}$/,
      'Invalid Licence',
    ).length > 0
  ) {
    errors.push(
      validateRegex(
        license,
        /^[a-zA-Z0-9]{2}[0-9]{2}[a-zA-Z0-9]{0,5}$/,
        'Invalid Licence',
      ),
    )
  } else if (
    validateRegex(license, /[a-zA-Z]{0,2}/, 'Invalid Licence').length > 0
  ) {
    errors.push(validateRegex(license, /[a-zA-Z]{0,2}/, 'Invalid Licence'))
  } else if (
    validateRegex(license, /[0-9]{4,9}/, 'Invalid Licence').length > 0
  ) {
    errors.push(validateRegex(license, /[0-9]{4,9}/, 'Invalid Licence'))
  }

  return errors
}

export function validatePassport(passport, issueCountry = 'AUS') {
  if (issueCountry === 'AUS') {
    return validateRegex(
      passport,
      /^([abcdefghjklmnprtuvwxyzABCDEFGHJKLMNPRTUVWXYZ]{1}|[pP]{1}[a-zA-Z]{1}|[rR]{1}[a-zA-Z]{1})[0-9]{7}$/,
      'Example valid AUS passport A1234567',
    )
  } else {
    // According to link below, password number format is subject to issuing country and there's no public
    // 3rd party library I find could do the trick accurately so just use an loose check
    // http://www.highprogrammer.com/alan/numbers/mrp.html
    return validateRegex(
      passport,
      /[a-zA-Z0-9]{5,}$/,
      'Minimum 5 alphanumeric characters',
    )
  }
}

export function validateCountry(country) {
  return validateRegex(country, /^[A-Z]{3}$/, 'Invalid Country')
}

// date1 should be less than date2
// Refactor this at the nearest opportunity
export function compareDates(date1, date2, emptyMessage, message) {
  const errors = []
  if (!date1 || date1 === '') {
    errors.push(emptyMessage)
  } else if (!date2 || date2 === '') {
    errors.push(emptyMessage)
  } else if (date1 > date2) {
    errors.push(message)
  }

  return errors
}

export const compareDatesOneDayLater = (
  date1,
  date2,
  emptyMessage,
  message,
) => {
  const errors = []
  const dateOneDayLater = moment().add('days', 1).format(helpers.dateWithDash)
  if (!date1 || date1 === '') {
    errors.push(emptyMessage)
  } else if (!date2 || date2 === '') {
    errors.push(emptyMessage)
  } else if (dateOneDayLater > date2) {
    errors.push(message)
  }

  return errors
}

export function validateExpiryDate(date1, date2, emptyMessage, message) {
  const errors = []
  if (!date1 || date1 === '') {
    errors.push(emptyMessage)
  } else if (!date2 || date2 === '') {
    errors.push(emptyMessage)
  } else if (date1 > date2) {
    errors.push(message)
  }
  return errors
}

export function validateEmployerName(name, message) {
  return validateRegex(name, /.+$/, message)
}

export function validateJobTitle(job, message) {
  return validateRegex(job, /.+$/, message)
}

export function validateFloat(number, message) {
  return validateRegex(number, /^[0-9]+([.][0-9]{1,2})?$/, message)
}

export function validateInt(number, message) {
  return validateRegex(number, /^[0-9]+?$/, message)
}

export function validatePositiveNumber(number, message) {
  return validateRegex(
    number,
    /^((0\.[1-9][0-9]*)|(0\.[0]+[1-9]+[0-9]*)|([1-9]\d*\.{1}\d+)|([1-9]+\d*))$/,
    message,
  )
}

export function validateWeeklyRent(weeklyRent, message, isOptional = false) {
  const errors = []

  if (isOptional && Number.isNaN(Number(weeklyRent))) {
    errors.push(message)
  }

  if (
    !isOptional &&
    (weeklyRent === '' ||
      !weeklyRent ||
      Number.isNaN(Number(weeklyRent)) ||
      Number(weeklyRent < 0))
  ) {
    errors.push(message)
  }

  return errors
}

export function validateRentalBond(bond, message, isBondPaid = false) {
  const errors = []
  if (
    isBondPaid &&
    (bond === '' || Number.isNaN(Number(bond)) || Number(bond <= 0))
  ) {
    errors.push(message)
  }

  return errors
}

export function validateLeaseStartDate(startDate, message) {
  const errors = []

  if (!startDate || moment(startDate).isBefore(moment())) {
    errors.push(message)
  }
  return errors
}

export function validateStartDate(startDate, message) {
  const errors = []

  if (!startDate) {
    errors.push(message)

    return errors
  }

  if (
    moment(startDate, 'DD MMM YYYY').isBefore(
      moment(moment().format('DD MMM YYYY'), 'DD MMM YYYY'),
      'day',
    )
  ) {
    errors.push(message)
  }

  return errors
}

export function validateStartTime(selectedDate, startTime, message) {
  let errors = []

  if (!startTime) {
    errors.push(message)

    return errors
  }

  let currentDate = getCurrentDate('DD MMM YYYY')
  if (selectedDate) {
    currentDate = selectedDate
  }

  const selectedStartDate = `${currentDate} ${startTime}`
  if (
    moment(selectedStartDate, 'DD MMM YYYY hh:mm A').isBefore(
      moment(moment().format('DD MMM YYYY hh:mm A'), 'DD MMM YYYY hh:mm A'),
      'second',
    )
  ) {
    errors.push(message)
  }

  return errors
}

export function validateAcceptedLeaseLength() {
  return []
}

export function validateSlug(name) {
  return validateRegex(
    name,
    /^(\s)*[A-Za-z0-9]+((\s)?((['\-_])?([A-Za-z0-9])+))*(\s)*$/,
    'Invalid Slug Name',
  )
}

export function validateSMSSenderName(str, fieldName) {
  return validateRegex(str, /^[A-Za-z_][A-Za-z\d_]*$/, 'Invalid ' + fieldName)
}

export function validateAlphaNumeric(str, fieldName) {
  return validateRegex(str, /^[a-zA-Z0-9]*$/, 'Invalid ' + fieldName)
}

export function validateFirstName(name) {
  return validateRegex(
    name,
    /^(\s)*[A-Za-z]+((\s)?((['\-.])?([A-Za-z])+))*(\s)*$/,
    'Invalid First Name',
  )
}

export function validateLastName(name) {
  return validateRegex(
    name,
    /^(\s)*[A-Za-z]+((\s)?((['\-.])?([A-Za-z])+))*(\s)*$/,
    'Invalid Last Name',
  )
}

export function validateBedroomCount(count, message) {
  return validateRegex(count, /^[1-9]+(\+)?/, message)
}

export function validatePhoneNumber(phone, message) {
  let formattedPhone = phone
  if (formattedPhone) {
    formattedPhone = formattedPhone.replace(/\s+/g, '')
  }
  return validateRegex(
    formattedPhone,
    /^\({0,1}((0|\+61)([24378])){0,1}\){0,1}[0-9]{2}[0-9]{2}[0-9]{1}[0-9]{3}$/,
    message,
  )
}

export function validatePhoneOrMobileNumber(number, userInfo = '') {
  let formattedUserinfo = userInfo
  let formattedNumber = number
  if (formattedUserinfo) {
    formattedUserinfo = userInfo.replace(/\s+/g, '')
  }
  if (formattedNumber) {
    formattedNumber = formattedNumber.replace(/\s+/g, '')
  }
  if (formattedUserinfo && formattedNumber === formattedUserinfo) {
    return ErrorMessages[Errors.ErrorManagerCannotBeTenant]
  }

  return validateRegex(
    formattedNumber,
    /(^((\+61|0)([24378])\d{8})$)|(^\(0([24378])\)\d{8}$)|(^\(\+61\)([24378])\d{8}$)/,
    ErrorMessages[Errors.ErrorWhenConvertingMobileToInternationalFormat],
  )
}

export function validateDuplicatedUserEmail(managerEmail, currentUserEmail) {
  managerEmail = managerEmail.trim()
  if (managerEmail === currentUserEmail) {
    return `You can't be the Manager or Owner for BondCover application.`
  }
  return ''
}

export function validateCompanyName(name, message) {
  return validateRegex(name, /.+$/, message)
}

export function validateCrmIDRequiredAndNumber(id, message) {
  return validateRegex(id, /^[0-9]+$/, message)
}

export function validateFranchiseGroupOptional(group, message) {
  return []
}

export function validateTradingName(name, message) {
  return validateRegex(name, /.+$/, message)
}

export function validateABN(value, message) {
  const abn = Array.from(value)
  let errors = []
  if (abn.length !== 11) {
    errors.push(message)
    return errors
  }

  let weightedTotal = 0
  const abnWeightings = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]
  let number = 0
  abn.forEach((n, i) => {
    number = Number.parseInt(n, 10)
    if (i === 0) {
      number = number - 1
    }
    weightedTotal = weightedTotal + number * abnWeightings[i]
  })

  if (weightedTotal % 89 !== 0) {
    errors.push(message)
  }
  return errors
}

export function validateACN(value, message) {
  const acn = Array.from(value)
  let errors = []
  if (acn.length !== 9) {
    errors.push(message)
    return errors
  }

  let weightedTotal = 0
  const acnWeightings = [8, 7, 6, 5, 4, 3, 2, 1, 0]

  acn.forEach((n, i) => {
    weightedTotal = weightedTotal + Number.parseInt(n, 10) * acnWeightings[i]
  })

  const finalValue = Number.parseInt(acn[acn.length - 1], 10)

  if ((weightedTotal % 10) + finalValue !== 0) {
    errors.push(message)
  }

  return errors
}

export function validateACNOptional(value, message) {
  let errors = []
  if (value.length === 0) {
    return errors
  }
  const acn = Array.from(value)
  if (acn.length !== 9) {
    errors.push(message)
    return errors
  }

  let weightedTotal = 0
  const acnWeightings = [8, 7, 6, 5, 4, 3, 2, 1, 0]

  acn.forEach((n, i) => {
    weightedTotal = weightedTotal + Number.parseInt(n, 10) * acnWeightings[i]
  })

  const finalValue = Number.parseInt(acn[acn.length - 1], 10)

  if ((weightedTotal % 10) + finalValue !== 0) {
    errors.push(message)
  }

  return errors
}

export function validateREALicense(value, message) {
  return validateRegex(value, /^[0-9]{6,9}$/, message)
}

export function validateSupplierSourceName(value, message) {
  return validateRegex(value, /^(\w+\S+)$/, message)
}

export function validateSupplierSourceToken(value, message) {
  return validateRegex(value, /^(\w+\S+)$/, message)
}

export function validateMessageBody(value, message) {
  const errors = []
  if (value === undefined || value.trim().length < 5) {
    errors.push(message)
  }
  return errors
}

export function validateEmailAddresses(commaSepEmailAddresses, errorMsg) {
  var error = false
  const errors = []
  if (
    commaSepEmailAddresses === undefined ||
    commaSepEmailAddresses.trim().length === 0
  ) {
    errors.push(errorMsg)
    return
  }

  var emailAddrArr
  emailAddrArr = commaSepEmailAddresses.split(',')

  var regex = /^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,}$/

  for (var i = 0; i < emailAddrArr.length; i++) {
    if (!regex.exec(emailAddrArr[i].trim())) {
      error = true
      break
    }
  }
  if (error) {
    errors.push(errorMsg)
  }

  return errors
}

export function isDateTodayOrEarlier(date) {
  return moment(date).isSameOrBefore(moment(), 'day')
}

export function validateDriverLicenceCardNumber(cardNumber, state) {
  switch (state) {
    case stateTypes.ACT:
      return validateRegex(
        cardNumber,
        /^[a-zA-Z0-9]{10}$/,
        ErrorMessages[
          Errors.IdentityDocsErrors.ErrorIdentityDocAusDLACTCardNumberInvalid
        ],
      )
    case stateTypes.NT:
      return validateRegex(
        cardNumber,
        /^[0-9]{6,8}$/,
        ErrorMessages[
          Errors.IdentityDocsErrors.ErrorIdentityDocAusDLNTCardNumberInvalid
        ],
      )
    case stateTypes.QLD:
      return validateRegex(
        cardNumber,
        /^[a-zA-Z0-9]{10}$/,
        ErrorMessages[
          Errors.IdentityDocsErrors.ErrorIdentityDocAusDLQLDCardNumberInvalid
        ],
      )
    case stateTypes.NSW:
      return validateRegex(
        cardNumber,
        /^[0-9]{10}$/,
        ErrorMessages[
          Errors.IdentityDocsErrors.ErrorIdentityDocAusDLNSWCardNumberInvalid
        ],
      )
    case stateTypes.SA:
      return validateRegex(
        cardNumber,
        /^[a-zA-Z0-9]{9}$/,
        ErrorMessages[
          Errors.IdentityDocsErrors.ErrorIdentityDocAusDLSACardNumberInvalid
        ],
      )
    case stateTypes.TAS:
      return validateRegex(
        cardNumber,
        /^[a-zA-Z0-9]{9}$/,
        ErrorMessages[
          Errors.IdentityDocsErrors.ErrorIdentityDocAusDLTASCardNumberInvalid
        ],
      )
    case stateTypes.VIC:
      return validateRegex(
        cardNumber,
        /^[a-zA-Z0-9]{8}$/,
        ErrorMessages[
          Errors.IdentityDocsErrors.ErrorIdentityDocAusDLVICCardNumberInvalid
        ],
      )
    case stateTypes.WA:
      return validateRegex(
        cardNumber,
        /^[a-zA-Z0-9]{8,10}$/,
        ErrorMessages[
          Errors.IdentityDocsErrors.ErrorIdentityDocAusDLWACardNumberInvalid
        ],
      )
    default:
      return ErrorMessages[
        Errors.IdentityDocsErrors.ErrorIdentityDocAusDLStateInvalid
      ]
  }
}
