import blue_MarkerA from 'app/assets/icons/google-maps-markers/blue_MarkerA.png'
import blue_MarkerB from 'app/assets/icons/google-maps-markers/blue_MarkerB.png'
import blue_MarkerC from 'app/assets/icons/google-maps-markers/blue_MarkerC.png'
import blue_MarkerD from 'app/assets/icons/google-maps-markers/blue_MarkerD.png'
import blue_MarkerE from 'app/assets/icons/google-maps-markers/blue_MarkerE.png'
import blue_MarkerF from 'app/assets/icons/google-maps-markers/blue_MarkerF.png'
import blue_MarkerG from 'app/assets/icons/google-maps-markers/blue_MarkerG.png'
import blue_MarkerH from 'app/assets/icons/google-maps-markers/blue_MarkerH.png'
import blue_MarkerI from 'app/assets/icons/google-maps-markers/blue_MarkerI.png'
import blue_MarkerJ from 'app/assets/icons/google-maps-markers/blue_MarkerJ.png'
import blue_MarkerK from 'app/assets/icons/google-maps-markers/blue_MarkerK.png'
import blue_MarkerL from 'app/assets/icons/google-maps-markers/blue_MarkerL.png'
import blue_MarkerM from 'app/assets/icons/google-maps-markers/blue_MarkerM.png'
import blue_MarkerN from 'app/assets/icons/google-maps-markers/blue_MarkerN.png'
import blue_MarkerO from 'app/assets/icons/google-maps-markers/blue_MarkerO.png'
import blue_MarkerP from 'app/assets/icons/google-maps-markers/blue_MarkerP.png'
import blue_MarkerQ from 'app/assets/icons/google-maps-markers/blue_MarkerQ.png'
import blue_MarkerR from 'app/assets/icons/google-maps-markers/blue_MarkerR.png'
import blue_MarkerS from 'app/assets/icons/google-maps-markers/blue_MarkerS.png'
import blue_MarkerT from 'app/assets/icons/google-maps-markers/blue_MarkerT.png'
import blue_MarkerU from 'app/assets/icons/google-maps-markers/blue_MarkerU.png'
import blue_MarkerV from 'app/assets/icons/google-maps-markers/blue_MarkerV.png'
import blue_MarkerW from 'app/assets/icons/google-maps-markers/blue_MarkerW.png'
import blue_MarkerX from 'app/assets/icons/google-maps-markers/blue_MarkerX.png'
import blue_MarkerY from 'app/assets/icons/google-maps-markers/blue_MarkerY.png'
import blue_MarkerZ from 'app/assets/icons/google-maps-markers/blue_MarkerZ.png'
import brown_MarkerA from 'app/assets/icons/google-maps-markers/brown_MarkerA.png'
import brown_MarkerB from 'app/assets/icons/google-maps-markers/brown_MarkerB.png'
import brown_MarkerC from 'app/assets/icons/google-maps-markers/brown_MarkerC.png'
import brown_MarkerD from 'app/assets/icons/google-maps-markers/brown_MarkerD.png'
import brown_MarkerE from 'app/assets/icons/google-maps-markers/brown_MarkerE.png'
import brown_MarkerF from 'app/assets/icons/google-maps-markers/brown_MarkerF.png'
import brown_MarkerG from 'app/assets/icons/google-maps-markers/brown_MarkerG.png'
import brown_MarkerH from 'app/assets/icons/google-maps-markers/brown_MarkerH.png'
import brown_MarkerI from 'app/assets/icons/google-maps-markers/brown_MarkerI.png'
import brown_MarkerJ from 'app/assets/icons/google-maps-markers/brown_MarkerJ.png'
import brown_MarkerK from 'app/assets/icons/google-maps-markers/brown_MarkerK.png'
import brown_MarkerL from 'app/assets/icons/google-maps-markers/brown_MarkerL.png'
import brown_MarkerM from 'app/assets/icons/google-maps-markers/brown_MarkerM.png'
import brown_MarkerN from 'app/assets/icons/google-maps-markers/brown_MarkerN.png'
import brown_MarkerO from 'app/assets/icons/google-maps-markers/brown_MarkerO.png'
import brown_MarkerP from 'app/assets/icons/google-maps-markers/brown_MarkerP.png'
import brown_MarkerQ from 'app/assets/icons/google-maps-markers/brown_MarkerQ.png'
import brown_MarkerR from 'app/assets/icons/google-maps-markers/brown_MarkerR.png'
import brown_MarkerS from 'app/assets/icons/google-maps-markers/brown_MarkerS.png'
import brown_MarkerT from 'app/assets/icons/google-maps-markers/brown_MarkerT.png'
import brown_MarkerU from 'app/assets/icons/google-maps-markers/brown_MarkerU.png'
import brown_MarkerV from 'app/assets/icons/google-maps-markers/brown_MarkerV.png'
import brown_MarkerW from 'app/assets/icons/google-maps-markers/brown_MarkerW.png'
import brown_MarkerX from 'app/assets/icons/google-maps-markers/brown_MarkerX.png'
import brown_MarkerY from 'app/assets/icons/google-maps-markers/brown_MarkerY.png'
import brown_MarkerZ from 'app/assets/icons/google-maps-markers/brown_MarkerZ.png'
import green_MarkerA from 'app/assets/icons/google-maps-markers/green_MarkerA.png'
import green_MarkerB from 'app/assets/icons/google-maps-markers/green_MarkerB.png'
import green_MarkerC from 'app/assets/icons/google-maps-markers/green_MarkerC.png'
import green_MarkerD from 'app/assets/icons/google-maps-markers/green_MarkerD.png'
import green_MarkerE from 'app/assets/icons/google-maps-markers/green_MarkerE.png'
import green_MarkerF from 'app/assets/icons/google-maps-markers/green_MarkerF.png'
import green_MarkerG from 'app/assets/icons/google-maps-markers/green_MarkerG.png'
import green_MarkerH from 'app/assets/icons/google-maps-markers/green_MarkerH.png'
import green_MarkerI from 'app/assets/icons/google-maps-markers/green_MarkerI.png'
import green_MarkerJ from 'app/assets/icons/google-maps-markers/green_MarkerJ.png'
import green_MarkerK from 'app/assets/icons/google-maps-markers/green_MarkerK.png'
import green_MarkerL from 'app/assets/icons/google-maps-markers/green_MarkerL.png'
import green_MarkerM from 'app/assets/icons/google-maps-markers/green_MarkerM.png'
import green_MarkerN from 'app/assets/icons/google-maps-markers/green_MarkerN.png'
import green_MarkerO from 'app/assets/icons/google-maps-markers/green_MarkerO.png'
import green_MarkerP from 'app/assets/icons/google-maps-markers/green_MarkerP.png'
import green_MarkerQ from 'app/assets/icons/google-maps-markers/green_MarkerQ.png'
import green_MarkerR from 'app/assets/icons/google-maps-markers/green_MarkerR.png'
import green_MarkerS from 'app/assets/icons/google-maps-markers/green_MarkerS.png'
import green_MarkerT from 'app/assets/icons/google-maps-markers/green_MarkerT.png'
import green_MarkerU from 'app/assets/icons/google-maps-markers/green_MarkerU.png'
import green_MarkerV from 'app/assets/icons/google-maps-markers/green_MarkerV.png'
import green_MarkerW from 'app/assets/icons/google-maps-markers/green_MarkerW.png'
import green_MarkerX from 'app/assets/icons/google-maps-markers/green_MarkerX.png'
import green_MarkerY from 'app/assets/icons/google-maps-markers/green_MarkerY.png'
import green_MarkerZ from 'app/assets/icons/google-maps-markers/green_MarkerZ.png'
import orange_MarkerA from 'app/assets/icons/google-maps-markers/orange_MarkerA.png'
import orange_MarkerB from 'app/assets/icons/google-maps-markers/orange_MarkerB.png'
import orange_MarkerC from 'app/assets/icons/google-maps-markers/orange_MarkerC.png'
import orange_MarkerD from 'app/assets/icons/google-maps-markers/orange_MarkerD.png'
import orange_MarkerE from 'app/assets/icons/google-maps-markers/orange_MarkerE.png'
import orange_MarkerF from 'app/assets/icons/google-maps-markers/orange_MarkerF.png'
import orange_MarkerG from 'app/assets/icons/google-maps-markers/orange_MarkerG.png'
import orange_MarkerH from 'app/assets/icons/google-maps-markers/orange_MarkerH.png'
import orange_MarkerI from 'app/assets/icons/google-maps-markers/orange_MarkerI.png'
import orange_MarkerJ from 'app/assets/icons/google-maps-markers/orange_MarkerJ.png'
import orange_MarkerK from 'app/assets/icons/google-maps-markers/orange_MarkerK.png'
import orange_MarkerL from 'app/assets/icons/google-maps-markers/orange_MarkerL.png'
import orange_MarkerM from 'app/assets/icons/google-maps-markers/orange_MarkerM.png'
import orange_MarkerN from 'app/assets/icons/google-maps-markers/orange_MarkerN.png'
import orange_MarkerO from 'app/assets/icons/google-maps-markers/orange_MarkerO.png'
import orange_MarkerP from 'app/assets/icons/google-maps-markers/orange_MarkerP.png'
import orange_MarkerQ from 'app/assets/icons/google-maps-markers/orange_MarkerQ.png'
import orange_MarkerR from 'app/assets/icons/google-maps-markers/orange_MarkerR.png'
import orange_MarkerS from 'app/assets/icons/google-maps-markers/orange_MarkerS.png'
import orange_MarkerT from 'app/assets/icons/google-maps-markers/orange_MarkerT.png'
import orange_MarkerU from 'app/assets/icons/google-maps-markers/orange_MarkerU.png'
import orange_MarkerV from 'app/assets/icons/google-maps-markers/orange_MarkerV.png'
import orange_MarkerW from 'app/assets/icons/google-maps-markers/orange_MarkerW.png'
import orange_MarkerX from 'app/assets/icons/google-maps-markers/orange_MarkerX.png'
import orange_MarkerY from 'app/assets/icons/google-maps-markers/orange_MarkerY.png'
import orange_MarkerZ from 'app/assets/icons/google-maps-markers/orange_MarkerZ.png'
import red_MarkerA from 'app/assets/icons/google-maps-markers/red_MarkerA.png'
import red_MarkerB from 'app/assets/icons/google-maps-markers/red_MarkerB.png'
import red_MarkerC from 'app/assets/icons/google-maps-markers/red_MarkerC.png'
import red_MarkerD from 'app/assets/icons/google-maps-markers/red_MarkerD.png'
import red_MarkerE from 'app/assets/icons/google-maps-markers/red_MarkerE.png'
import red_MarkerF from 'app/assets/icons/google-maps-markers/red_MarkerF.png'
import red_MarkerG from 'app/assets/icons/google-maps-markers/red_MarkerG.png'
import red_MarkerH from 'app/assets/icons/google-maps-markers/red_MarkerH.png'
import red_MarkerI from 'app/assets/icons/google-maps-markers/red_MarkerI.png'
import red_MarkerJ from 'app/assets/icons/google-maps-markers/red_MarkerJ.png'
import red_MarkerK from 'app/assets/icons/google-maps-markers/red_MarkerK.png'
import red_MarkerL from 'app/assets/icons/google-maps-markers/red_MarkerL.png'
import red_MarkerM from 'app/assets/icons/google-maps-markers/red_MarkerM.png'
import red_MarkerN from 'app/assets/icons/google-maps-markers/red_MarkerN.png'
import red_MarkerO from 'app/assets/icons/google-maps-markers/red_MarkerO.png'
import red_MarkerP from 'app/assets/icons/google-maps-markers/red_MarkerP.png'
import red_MarkerQ from 'app/assets/icons/google-maps-markers/red_MarkerQ.png'
import red_MarkerR from 'app/assets/icons/google-maps-markers/red_MarkerR.png'
import red_MarkerS from 'app/assets/icons/google-maps-markers/red_MarkerS.png'
import red_MarkerT from 'app/assets/icons/google-maps-markers/red_MarkerT.png'
import red_MarkerU from 'app/assets/icons/google-maps-markers/red_MarkerU.png'
import red_MarkerV from 'app/assets/icons/google-maps-markers/red_MarkerV.png'
import red_MarkerW from 'app/assets/icons/google-maps-markers/red_MarkerW.png'
import red_MarkerX from 'app/assets/icons/google-maps-markers/red_MarkerX.png'
import red_MarkerY from 'app/assets/icons/google-maps-markers/red_MarkerY.png'
import red_MarkerZ from 'app/assets/icons/google-maps-markers/red_MarkerZ.png'
import yellow_MarkerA from 'app/assets/icons/google-maps-markers/yellow_MarkerA.png'
import yellow_MarkerB from 'app/assets/icons/google-maps-markers/yellow_MarkerB.png'
import yellow_MarkerC from 'app/assets/icons/google-maps-markers/yellow_MarkerC.png'
import yellow_MarkerD from 'app/assets/icons/google-maps-markers/yellow_MarkerD.png'
import yellow_MarkerE from 'app/assets/icons/google-maps-markers/yellow_MarkerE.png'
import yellow_MarkerF from 'app/assets/icons/google-maps-markers/yellow_MarkerF.png'
import yellow_MarkerG from 'app/assets/icons/google-maps-markers/yellow_MarkerG.png'
import yellow_MarkerH from 'app/assets/icons/google-maps-markers/yellow_MarkerH.png'
import yellow_MarkerI from 'app/assets/icons/google-maps-markers/yellow_MarkerI.png'
import yellow_MarkerJ from 'app/assets/icons/google-maps-markers/yellow_MarkerJ.png'
import yellow_MarkerK from 'app/assets/icons/google-maps-markers/yellow_MarkerK.png'
import yellow_MarkerL from 'app/assets/icons/google-maps-markers/yellow_MarkerL.png'
import yellow_MarkerM from 'app/assets/icons/google-maps-markers/yellow_MarkerM.png'
import yellow_MarkerN from 'app/assets/icons/google-maps-markers/yellow_MarkerN.png'
import yellow_MarkerO from 'app/assets/icons/google-maps-markers/yellow_MarkerO.png'
import yellow_MarkerP from 'app/assets/icons/google-maps-markers/yellow_MarkerP.png'
import yellow_MarkerQ from 'app/assets/icons/google-maps-markers/yellow_MarkerQ.png'
import yellow_MarkerR from 'app/assets/icons/google-maps-markers/yellow_MarkerR.png'
import yellow_MarkerS from 'app/assets/icons/google-maps-markers/yellow_MarkerS.png'
import yellow_MarkerT from 'app/assets/icons/google-maps-markers/yellow_MarkerT.png'
import yellow_MarkerU from 'app/assets/icons/google-maps-markers/yellow_MarkerU.png'
import yellow_MarkerV from 'app/assets/icons/google-maps-markers/yellow_MarkerV.png'
import yellow_MarkerW from 'app/assets/icons/google-maps-markers/yellow_MarkerW.png'
import yellow_MarkerX from 'app/assets/icons/google-maps-markers/yellow_MarkerX.png'
import yellow_MarkerY from 'app/assets/icons/google-maps-markers/yellow_MarkerY.png'
import yellow_MarkerZ from 'app/assets/icons/google-maps-markers/yellow_MarkerZ.png'

export const google_map_markers = {
  blue_MarkerA,
  blue_MarkerB,
  blue_MarkerC,
  blue_MarkerD,
  blue_MarkerE,
  blue_MarkerF,
  blue_MarkerG,
  blue_MarkerH,
  blue_MarkerI,
  blue_MarkerJ,
  blue_MarkerK,
  blue_MarkerL,
  blue_MarkerM,
  blue_MarkerN,
  blue_MarkerO,
  blue_MarkerP,
  blue_MarkerQ,
  blue_MarkerR,
  blue_MarkerS,
  blue_MarkerT,
  blue_MarkerU,
  blue_MarkerV,
  blue_MarkerW,
  blue_MarkerX,
  blue_MarkerY,
  blue_MarkerZ,
  red_MarkerA,
  red_MarkerB,
  red_MarkerC,
  red_MarkerD,
  red_MarkerE,
  red_MarkerF,
  red_MarkerG,
  red_MarkerH,
  red_MarkerI,
  red_MarkerJ,
  red_MarkerK,
  red_MarkerL,
  red_MarkerM,
  red_MarkerN,
  red_MarkerO,
  red_MarkerP,
  red_MarkerQ,
  red_MarkerR,
  red_MarkerS,
  red_MarkerT,
  red_MarkerU,
  red_MarkerV,
  red_MarkerW,
  red_MarkerX,
  red_MarkerY,
  red_MarkerZ,
  green_MarkerA,
  green_MarkerB,
  green_MarkerC,
  green_MarkerD,
  green_MarkerE,
  green_MarkerF,
  green_MarkerG,
  green_MarkerH,
  green_MarkerI,
  green_MarkerJ,
  green_MarkerK,
  green_MarkerL,
  green_MarkerM,
  green_MarkerN,
  green_MarkerO,
  green_MarkerP,
  green_MarkerQ,
  green_MarkerR,
  green_MarkerS,
  green_MarkerT,
  green_MarkerU,
  green_MarkerV,
  green_MarkerW,
  green_MarkerX,
  green_MarkerY,
  green_MarkerZ,
  yellow_MarkerA,
  yellow_MarkerB,
  yellow_MarkerC,
  yellow_MarkerD,
  yellow_MarkerE,
  yellow_MarkerF,
  yellow_MarkerG,
  yellow_MarkerH,
  yellow_MarkerI,
  yellow_MarkerJ,
  yellow_MarkerK,
  yellow_MarkerL,
  yellow_MarkerM,
  yellow_MarkerN,
  yellow_MarkerO,
  yellow_MarkerP,
  yellow_MarkerQ,
  yellow_MarkerR,
  yellow_MarkerS,
  yellow_MarkerT,
  yellow_MarkerU,
  yellow_MarkerV,
  yellow_MarkerW,
  yellow_MarkerX,
  yellow_MarkerY,
  yellow_MarkerZ,
  darkcyan_MarkerA: brown_MarkerA,
  darkcyan_MarkerB: brown_MarkerB,
  darkcyan_MarkerC: brown_MarkerC,
  darkcyan_MarkerD: brown_MarkerD,
  darkcyan_MarkerE: brown_MarkerE,
  darkcyan_MarkerF: brown_MarkerF,
  darkcyan_MarkerG: brown_MarkerG,
  darkcyan_MarkerH: brown_MarkerH,
  darkcyan_MarkerI: brown_MarkerI,
  darkcyan_MarkerJ: brown_MarkerJ,
  darkcyan_MarkerK: brown_MarkerK,
  darkcyan_MarkerL: brown_MarkerL,
  darkcyan_MarkerM: brown_MarkerM,
  darkcyan_MarkerN: brown_MarkerN,
  darkcyan_MarkerO: brown_MarkerO,
  darkcyan_MarkerP: brown_MarkerP,
  darkcyan_MarkerQ: brown_MarkerQ,
  darkcyan_MarkerR: brown_MarkerR,
  darkcyan_MarkerS: brown_MarkerS,
  darkcyan_MarkerT: brown_MarkerT,
  darkcyan_MarkerU: brown_MarkerU,
  darkcyan_MarkerV: brown_MarkerV,
  darkcyan_MarkerW: brown_MarkerW,
  darkcyan_MarkerX: brown_MarkerX,
  darkcyan_MarkerY: brown_MarkerY,
  darkcyan_MarkerZ: brown_MarkerZ,
  orange_MarkerA,
  orange_MarkerB,
  orange_MarkerC,
  orange_MarkerD,
  orange_MarkerE,
  orange_MarkerF,
  orange_MarkerG,
  orange_MarkerH,
  orange_MarkerI,
  orange_MarkerJ,
  orange_MarkerK,
  orange_MarkerL,
  orange_MarkerM,
  orange_MarkerN,
  orange_MarkerO,
  orange_MarkerP,
  orange_MarkerQ,
  orange_MarkerR,
  orange_MarkerS,
  orange_MarkerT,
  orange_MarkerU,
  orange_MarkerV,
  orange_MarkerW,
  orange_MarkerX,
  orange_MarkerY,
  orange_MarkerZ,
}

export const MARKER_LETTER_NUMBER = 26
