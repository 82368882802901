import React, { Component } from 'react'

const reasons = [
  'Company name',
  'Job title',
  'Employment dates',
  'Salary',
  'I’m not the correct person',
  'Other',
]

class Reasons extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      closeReasonsModal,
      disproveButtonClicked,
      disproveEmployeeApiError,
      onDisproveButtonClick,
      checkedReason,
      onCheckboxChange,
      updateComment,
      comment,
      employee,
    } = this.props
    return (
      <div className="modal-fullscreen">
        <div className="modal-wrapper sm-height-90 sm-top-5">
          <div className="modal-close">
            <i className="fa fa-times" onClick={() => closeReasonsModal()} />
          </div>
          <div className="modal-header sm-height-10">
            Let {employee.renterFirstName} know what you can't confirm
          </div>
          <div className="modal-body sm-height-90">
            {reasons.map((reason, i) => (
              <label
                key={i}
                className="mb15 display-flex justify-content-flex-start"
              >
                <input
                  type="radio"
                  name="failReason"
                  checked={i === checkedReason}
                  onChange={() => onCheckboxChange(i)}
                />
                <span className="xs-fs16 height-auto">{reason}</span>
              </label>
            ))}

            <div className="input-box">
              <textarea
                rows="4"
                placeholder="Leave a comment"
                value={comment}
                onChange={(e) => updateComment(e.target.value)}
              />
              <label>Comment</label>
            </div>

            <button
              className="btn"
              disabled={disproveButtonClicked || checkedReason === null}
              onClick={onDisproveButtonClick}
            >
              Submit
              <i
                className={
                  disproveButtonClicked ? 'fa fa-spinner fa-pulse' : ''
                }
              />
            </button>

            <div
              className={
                disproveEmployeeApiError ? 'alert alert-danger' : 'hidden'
              }
            >
              {disproveEmployeeApiError}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Reasons
