import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import { Box, Button } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import TableComponent from 'app/components/design-system-components/Table'
import { TrimWithEllipsis } from 'app/components/design-system-components/TrimWithEllipsis'
import { Text } from 'app/components/design-system-components/typography'
import {
  keySetStatusConfig,
  keySetStatuses,
} from 'app/constants/key-logger.constants'
import { SelectKeySetCell } from 'app/features/teams/key-logger/components/SelectKeySetCell'
import { SelectKeySetHeader } from 'app/features/teams/key-logger/components/SelectKeySetHeader'
import { ActionsCell } from 'app/features/teams/key-logger/KeyLoggerTable/ActionsCell'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  humanizedDateDuration,
  toTimeAmPmDateFormat,
} from 'app/utils/datetime/helpers'
import { formatPhoneNumber } from 'app/utils/phonenumber/helpers'

const colSizes = {
  extraSmall: 40,
  small: 90,
  preMedium: 120,
  medium: 200,
}

const LOAN_EXTENDED_NOTE = 'Loan extended'

const notAvailableElem = (
  <Text
    as="span"
    color={theme.colors.gray400}
    fontSize={theme.fontSizes.pRegular14}
  >
    N/A
  </Text>
)

const PhoneLink = styled.a`
  font-weight: bold;
  white-space: nowrap;
`

export const KeyLoggerTable = ({ keySetsData, actions, selectionConfigs }) => {
  const colDefs = [
    {
      style: {
        minWidth: colSizes.extraSmall + 'px',
        padding: `0 0 0 ${theme.space[3]}px`,
      },
      Header: () => (
        <SelectKeySetHeader
          isSelected={selectionConfigs.isAllSelected}
          toggleSelection={selectionConfigs.toggleAllKeysSelection}
        />
      ),
      Cell: ({
        row: {
          original: { guid, isSelected },
        },
      }) => {
        return (
          <SelectKeySetCell
            isSelected={isSelected}
            toggleKeySelection={() => selectionConfigs.toggleKeySelection(guid)}
          />
        )
      },
      accessor: 'isSelected',
    },
    {
      accessor: 'status',
      Header: 'Status',
      style: { minWidth: colSizes.small + 'px' },
      Cell: ({ value: status, row: { original: keySet } }) => {
        const statusConfig = keySetStatusConfig[status]
        if (!statusConfig) return null

        const renderDurationText = (showDuration) => {
          if (!showDuration) return null

          const periodInDays = humanizedDateDuration(
            keySet.borrowing_data?.due_date,
          )

          return (
            <Text
              as="div"
              color={statusConfig.textColor}
              textAlign="center"
              mt={theme.space[2] + 'px'}
              fontSize={theme.fontSizes.pSmall12}
            >
              {periodInDays}
            </Text>
          )
        }

        const { showDuration } = statusConfig

        const durationElem = renderDurationText(showDuration)

        return (
          <Box>
            <Badge
              variant={statusConfig.badgeVariant}
              text={statusConfig.title}
              textAlign="center"
              width="100%"
              whiteSpace="pre-wrap"
            />
            {durationElem}
          </Box>
        )
      },
    },
    {
      accessor: 'label',
      Header: 'Key set',
      style: {
        minWidth: colSizes.small + 'px',
        fontWeight: theme.fontWeights[6],
      },
    },
    {
      accessor: 'issued',
      Header: 'Issued / Due',
      style: { minWidth: colSizes.preMedium + 'px' },
      Cell: ({ value, row: { original: keySet } }) => {
        const { borrowing_data } = keySet
        if (!borrowing_data) return notAvailableElem
        const { issued_date, due_date } = borrowing_data
        const format = 'DD MMM YY'
        const issuedDate = moment(issued_date).format(format)
        const dueDate = moment(moment(due_date).format('YYYY-MM-DD')).format(
          format,
        )
        return (
          <>
            <Text
              as="div"
              fontWeight={theme.fontWeights[6]}
              mb={theme.space[2] + 'px'}
            >
              {issuedDate}
            </Text>
            <Text as="div" fontSize={theme.fontSizes.pSmall12}>
              {dueDate}
            </Text>
          </>
        )
      },
    },
    {
      accessor: 'property.address.friendlyName',
      Header: 'Details',
      style: { minWidth: colSizes.medium + 'px' },
      Cell: ({ value: address, row: { original: keySet } }) => {
        let addressCopy = address
        const { borrowing_data, property, manager = {} } = keySet

        const { address: propertyAddress = {} } = property || {}
        const { suburb = '' } = propertyAddress || {}
        const managerName = `${manager?.first_name} ${manager?.last_name}`

        if (!address.includes(',')) {
          addressCopy += `, ${suburb}`
        }

        const issuerName = !!borrowing_data
          ? `${borrowing_data.issuer.first_name} ${borrowing_data.issuer.last_name}`
          : notAvailableElem
        return (
          <Box>
            <Box mb={2}>
              <Text as="span" fontWeight={theme.fontWeights[6]}>
                {addressCopy}
              </Text>
            </Box>
            <Box mb={1}>
              <Text
                as="span"
                mr={theme.space[2] + 'px'}
                fontWeight={theme.fontWeights[6]}
              >
                Manager:
              </Text>
              <Text as="span">{managerName}</Text>
            </Box>
            <Text
              as="span"
              mr={theme.space[2] + 'px'}
              fontWeight={theme.fontWeights[6]}
            >
              Issuer:
            </Text>
            <Text as="span">{issuerName}</Text>
          </Box>
        )
      },
    },
    {
      accessor: 'notes',
      Header: 'Notes & Access',
      style: { minWidth: colSizes.medium + 'px' },
      Cell: ({ value: notes, row: { original: keySet } }) => {
        const { last_extended_by, status, reservations } = keySet
        const hasExtendNote =
          last_extended_by && status === keySetStatuses.onLoan
        const hasReservationNote = !!reservations?.[0]
        const hasValidPMNote =
          notes &&
          !!Object.keys(notes).length &&
          Object.values(notes).some((note) => !!note)

        if (!hasValidPMNote && !hasExtendNote && !hasReservationNote) {
          return notAvailableElem
        }
        const {
          check_out_notes: checkoutNotes,
          check_in_notes: checkInNotes,
          remove_notes: removeNotes,
        } = notes || {}

        const txPMNote = checkInNotes
          ? checkInNotes
          : checkoutNotes
          ? checkoutNotes
          : removeNotes
          ? removeNotes
          : null

        const cellNote = txPMNote
          ? txPMNote
          : hasExtendNote && LOAN_EXTENDED_NOTE

        const cellNoteCmp = !!cellNote && (
          <Box>
            <Text as="span">{cellNote}</Text>
          </Box>
        )
        return (
          <>
            <TrimWithEllipsis>{cellNoteCmp}</TrimWithEllipsis>
            <Button
              onClick={() =>
                actions.notesDetails(keySet, notes, 'Notes', txPMNote)
              }
              variant="linkBlue"
              mt={2}
            >
              view details
            </Button>
            {hasReservationNote && (
              <Box my={2}>{renderReservationLabel(keySet)}</Box>
            )}
          </>
        )
      },
    },
    {
      accessor: 'borrower',
      Header: 'Borrower',
      style: { minWidth: colSizes.preMedium + 'px' },
      Cell: ({ value, row: { original: keySet } }) => {
        const { borrowing_data } = keySet
        if (!borrowing_data) return notAvailableElem
        const { borrower } = borrowing_data
        if (!borrower) return notAvailableElem

        const borrowerName = `${borrower.first_name} ${borrower.last_name}`
        const formattedPhone = formatPhoneNumber(borrower.phone)

        const shouldShowCompanyName =
          !!borrower.company &&
          borrower.company.replace(/ /g, '').toUpperCase() !==
            borrowerName.replace(/ /g, '').toUpperCase()
        return (
          <>
            <Text as="div" mb={theme.space[2] + 'px'}>
              {borrowerName}
            </Text>
            {shouldShowCompanyName && (
              <Text as="div" mb={theme.space[2] + 'px'}>
                {borrower.company}
              </Text>
            )}
            <PhoneLink href={`tel:${formattedPhone}`}>
              {formattedPhone}
            </PhoneLink>
          </>
        )
      },
    },
    {
      accessor: 'actions',
      Header: '',
      style: { minWidth: colSizes.preMedium + 'px' },
      Cell: ActionsCell(actions),
    },
  ]

  const renderReservationLabel = (keySet) => {
    const reservation = keySet.reservations?.[0]
    if (!reservation) return
    const { creator, created_at: reservedAt } = reservation
    const tooltipTxt = `By ${creator.first_name} ${
      creator.last_name
    } ${toTimeAmPmDateFormat(reservedAt)}`
    return (
      <Badge
        text="Reserved"
        variant="blue"
        tooltipId={reservation.id}
        tooltipText={tooltipTxt}
      />
    )
  }

  return (
    <Box width="100%" overflow="auto" pb={`${theme.dividedSpace[10]}px`}>
      <TableComponent
        columns={colDefs}
        dataToBeEntered={keySetsData}
        textColor="#4d4d4d"
        showRowBottomBorder
        fontSize={theme.fontSizes.pRegular14}
        verticalAlignTop
      />
    </Box>
  )
}
