import moment from 'moment'

import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'

export const formFields = {
  reason: 'reason',
  dueDate: 'dueDate',
  notes: 'notes',
  accessNotes: 'accessNotes',
  alarmCode: 'alarmCode',
}

const isValidMinDate = (val) => !moment(val).isBefore(moment().startOf('d'))
export const isValidDueDate = isValidMinDate

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.reason: {
      return {
        required: !val && 'Please select a reason',
      }
    }

    case formFields.dueDate: {
      return {
        required: !val && 'Please select a due date',
        minDate: !isValidMinDate(val) && 'Please select a valid due date',
      }
    }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
