import React, { useEffect, useState } from 'react'

import {
  Box,
  ButtonWithLoading,
  Flex,
} from 'app/components/design-system-components'
import * as Text from 'app/components/display/text/text_component'
import * as onlistSteps from 'app/pages/teams/onlist/onlist.constants'
import {
  getPropertyDisclosuresStatusForAgency,
  savePropertyDisclosureRequestForOwner,
} from 'app/services/http/propertydisclosures'
import * as snugNotifier from 'app/services/snugNotifier'
import * as constants from 'app/sm/onlist_details/ownerPropertyDisclosure/helpers/constants'
import RequestModal from 'app/sm/onlist_details/ownerPropertyDisclosure/requestModal'
import ReminderNotice from 'app/sm/onlist_details/ownerPropertyDisclosure/sendVariants/ReminderNotice'
import SendVariants from 'app/sm/onlist_details/ownerPropertyDisclosure/sendVariants/SendVariants'
import UploadContinueModal from 'app/sm/onlist_details/ownerPropertyDisclosure/uploadContinueModal'
import UploadModal from 'app/sm/onlist_details/ownerPropertyDisclosure/uploadModal'

function OwnerPropertyDisclosures({
  agencyGUID,
  match,
  disclosureTabs,
  address,
  jumpToStep,
  disclosureSubmitted,
  reloadDisclosure,
}) {
  const [disclosureResponse, setDisclosureResponse] = useState({
    completionStatus: constants.DISCLOSURE_UNFINISHED,
    recipient: [],
    completer: '',
  })
  const [propertyDisclosureRequests, setPropertyDisclosureRequests] = useState(
    [],
  )

  const { offerGUID } = match.params
  const [isRequestSubmitInProgress, setIsRequestSubmitInProgress] =
    useState(false)
  useEffect(() => {
    getPropertyDisclosuresStatusForAgency(agencyGUID, offerGUID)
      .then((data) => {
        setDisclosureResponse({ ...disclosureResponse, ...data })
        setPropertyDisclosureRequests(
          data?.propertyDisclosureRequests?.reverse(),
        )
      })
      .catch((error) => snugNotifier.error(error.message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmitForm = (values) => {
    if (!disclosureResponse.recipient?.length) {
      snugNotifier.error('Please add property owners first')
      return
    }
    setIsRequestSubmitInProgress(true)

    return savePropertyDisclosureRequestForOwner(agencyGUID, offerGUID, values)
      .then(() => {
        snugNotifier.success('Disclosure requested successfully')
        toggleRequestModal()
        reloadDisclosure()
      })
      .catch((err) => {
        snugNotifier.error(err?.message || 'Failed to request the disclosure')
      })
      .finally(() => setIsRequestSubmitInProgress(false))
  }
  //TODO: Handling disclosures in modals needs some fixes. For example, we're uploading and saving attachments without using upload btn, i.e, upload and cancel btns are useless in that case!
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [isUploadContinueModalOpen, setIsUploadContinueModalOpen] =
    useState(false)
  const toggleRequestModal = () => {
    setIsRequestModalOpen(!isRequestModalOpen)
  }
  const toggleUploadModal = () => {
    setIsUploadModalOpen(!isUploadModalOpen)
  }

  const uploadModalClicked = () => {
    if (
      disclosureResponse.completionStatus === constants.DISCLOSURE_REQUESTED
    ) {
      setIsUploadContinueModalOpen(true)
      return
    }
    toggleUploadModal()
  }

  const toggleUploadContinueModal = () => {
    setIsUploadContinueModalOpen(!isUploadContinueModalOpen)
  }

  const uploadContinueClicked = () => {
    toggleUploadModal()
    toggleUploadContinueModal()
  }

  let requestText =
    disclosureResponse.completionStatus !== constants.DISCLOSURE_COMPLETED
      ? 'Request'
      : 'Resend'
  const uploadText =
    disclosureResponse.completionStatus !== constants.DISCLOSURE_COMPLETED
      ? 'Upload'
      : 'Cancel'

  if (disclosureResponse.completionStatus === constants.DISCLOSURE_REQUESTED) {
    requestText = 'Resend'
  }

  const moveToContactsStep = () => {
    jumpToStep(onlistSteps.CONTACTS_STEP)
  }

  const remindersAvailable =
    propertyDisclosureRequests && propertyDisclosureRequests.length > 1

  const isCompleted =
    disclosureResponse.completionStatus === constants.DISCLOSURE_COMPLETED

  const renderReminders = () => {
    return propertyDisclosureRequests
      .slice(1) // first element is the initial request
      .map((disclosureRequest) => (
        <ReminderNotice timeStamp={disclosureRequest.createdAt} />
      ))
  }

  return (
    <Box>
      <Text.Stronger
        text="Disclosures"
        textClass="fs24"
        componentClass="mb20"
      />
      <Box>
        Disclosures by the Rental Provider to the Renter for{' '}
        {address && `${address.friendlyName}, ${address.suburb}`}
      </Box>
      <SendVariants
        variantNum={disclosureResponse.completionStatus}
        owners={disclosureResponse.recipient}
        disclosureResponse={disclosureResponse}
        completer={disclosureResponse.completer}
      />

      {!isCompleted && remindersAvailable && renderReminders()}

      {disclosureResponse.completionStatus !==
        constants.DISCLOSURE_COMPLETED && (
        <Flex>
          <ButtonWithLoading
            mr="8px"
            variant="brightGreenOutline"
            onClick={uploadModalClicked}
          >
            {uploadText}
          </ButtonWithLoading>
          <ButtonWithLoading onClick={toggleRequestModal}>
            {requestText}
          </ButtonWithLoading>
        </Flex>
      )}
      {isRequestModalOpen && (
        <RequestModal
          toggleRequestModal={toggleRequestModal}
          owners={disclosureResponse.recipient}
          onSubmitForm={onSubmitForm}
          moveToOwnersStep={moveToContactsStep}
          isRequestSubmitInProgress={isRequestSubmitInProgress}
        />
      )}
      {isUploadModalOpen && (
        <UploadModal
          disclosureTabs={disclosureTabs}
          toggleUploadModal={toggleUploadModal}
          disclosureSubmitted={disclosureSubmitted}
        />
      )}
      {isUploadContinueModalOpen && (
        <UploadContinueModal
          toggleUploadContinueModal={toggleUploadContinueModal}
          uploadContinueClicked={uploadContinueClicked}
        />
      )}
    </Box>
  )
}

export default OwnerPropertyDisclosures
