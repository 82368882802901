import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import rocket from 'app/assets/icons/rocket.png'
import { Box, Button, Flex } from 'app/components/design-system-components'
import Card from 'app/components/design-system-components/Card'
import {
  FlightTakeoffRounded,
  SettingsRounded,
} from 'app/components/design-system-components/icons/actions'
import { PersonRounded } from 'app/components/design-system-components/icons/social'
import { mediaQueries } from 'app/components/design-system-components/styles/variables'
import { Text } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import { getCurrentStepForQuickStart } from 'app/sm/team_viewing_preference/setup/api'
import OptimiseContent from 'app/sm/team_viewing_preference/setup/card_components/optimiseContent'
import SetupContent from 'app/sm/team_viewing_preference/setup/card_components/setupContent'
import TeamStatus from 'app/sm/team_viewing_preference/setup/TeamStatus'
import WizardForNonValid from 'app/sm/team_viewing_preference/setup/WizardForNonValid'

export const StyledSetupContainerSteps = styled(Box)`
  max-width: 720px;
  margin: 0 auto;
  line-height: ${({ theme }) => theme.lineHeights.title};
`

export const StyledSetup = styled(Box)`
  line-height: ${({ theme }) => theme.lineHeights.title};
  h5 {
    margin-bottom: ${({ theme }) => theme.space[3]}px;
  }
`

export const StyledHeading = styled(Box)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.space[8]}px;
  margin-top: ${({ theme }) => theme.space[5]}px;
`

export const StyledButtonContent = styled(Box)`
  text-align: left;
  margin-left: ${({ theme }) => theme.space[4]}px;
`

const StyledCTAButtons = styled(Button)`
  width: ${theme.width[24]}px;
  margin-top: ${({ theme }) => theme.space[4]}px;
`

const StyledCTAButtonsContainer = styled(Flex)`
  justify-content: space-between;
  ${mediaQueries.sm} {
    flex-direction: column;
  }
`

function SetupTab({ agencyGUID = '', teamSlug = '', firstName = '' }) {
  const [wizardData, setWizardData] = useState({
    showQuickStartWizard: true,
    currentStep: 0,
    wizardGUID: '',
  })
  useEffect(() => {
    getCurrentStepForQuickStart(agencyGUID)
      .then((data) => {
        const { Invalid, current_step_id, id } = data
        setWizardData({
          currentStep: current_step_id,
          wizardGUID: id,
          showQuickStartWizard: !Invalid,
        })
      })
      .catch((error) => snugNotifier.error(error.message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <StyledSetup>
      <StyledHeading mt={3}>
        <img src={rocket} alt="welcome to snug" />
        <h2>Welcome to Snug, {firstName}</h2>
      </StyledHeading>
      <Box mb={theme.space[8] + 'px'}>
        It's quick to get started with Snug and streamline your leasing journey
        with better applications and viewings. Then configure Snug to perfectly
        suit your business. We're here to help so click Support to chat with our
        team or{' '}
        <a
          href="https://snugcom.pipedrive.com/scheduler/49oqjuq/lets-get-you-set-up"
          target="_blank"
          rel="noreferrer"
        >
          book a custom onboarding call
        </a>
        .
      </Box>
      {wizardData.showQuickStartWizard && (
        <TeamStatus
          agencyGUID={agencyGUID}
          teamSlug={teamSlug}
          wizardData={wizardData}
        />
      )}
      {!wizardData.showQuickStartWizard && (
        <WizardForNonValid teamSlug={teamSlug} />
      )}
      <Card
        headerTitle="Set up"
        headerImage={<SettingsRounded />}
        internalComponent={<SetupContent teamSlug={teamSlug} />}
        isCollapsible
        initialCollapsedState={true}
      />
      <Card
        headerTitle="Optimise"
        headerImage={<FlightTakeoffRounded />}
        internalComponent={<OptimiseContent />}
        isCollapsible
        initialCollapsedState={true}
      />
      <StyledCTAButtonsContainer>
        <StyledCTAButtons
          variant="greenHover"
          onClick={() => {
            window.open(
              'https://snugcom.pipedrive.com/scheduler/37MoEtj/assistance-call',
            )
          }}
        >
          <Flex alignItems="center">
            <Box mt={2}>
              <PersonRounded height="36px" width="36px" />
            </Box>
            <StyledButtonContent>
              <Text as="span" fontWeight="700" fontSize="18px">
                Free to Onboard
              </Text>
              <Box>Book an onboarding call</Box>
            </StyledButtonContent>
          </Flex>
        </StyledCTAButtons>

        <StyledCTAButtons
          variant="greenHover"
          onClick={() => {
            window.open(
              'https://snugcom.pipedrive.com/scheduler/E4xvlSO2/15-minute-check-in',
            )
          }}
        >
          <Flex alignItems="center">
            <Box mt={2}>
              <PersonRounded height="36px" width="36px" />
            </Box>
            <StyledButtonContent>
              <Text as="span" fontWeight="700" fontSize="18px">
                Explore Plans
              </Text>
              <Box>Talk to Taylor, your Account Manager</Box>
            </StyledButtonContent>
          </Flex>
        </StyledCTAButtons>
      </StyledCTAButtonsContainer>
    </StyledSetup>
  )
}

export default SetupTab
