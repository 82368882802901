import moment from 'moment'

import {
  EXCLUDE_DATE_RANGE_TYPE,
  EXCLUDE_DATETIME_RANGE_TYPE,
  EXCLUDE_SCHEDULE_TYPE,
} from 'app/sm/properties/components/excluded_times/constants'
import {
  beforeToday,
  DATE_WITH_DASH,
  FULL_TIME_24H,
  isToday,
} from 'app/utils/datetime/helpers'

export const IdGenerator = () => Math.floor(Math.random() * 1000000)

export const transformExcludedDates = (entries = []) => {
  if (!entries?.length) return entries

  // ISSUE: moment don't properly parse time with format `0000-01-01T10:00:00Z`, so better to use JS Date object.
  return entries.map((entry) => ({
    ...entry,
    _id: IdGenerator(),
    startDate:
      entry.startDate &&
      moment(new Date(entry.startDate), DATE_WITH_DASH)
        .utc()
        .format(DATE_WITH_DASH),
    endDate:
      entry.endDate &&
      moment(new Date(entry.endDate), DATE_WITH_DASH)
        .utc()
        .format(DATE_WITH_DASH),
    startTime:
      entry.startTime &&
      moment(new Date(entry.startTime), FULL_TIME_24H)
        .utc()
        .format(FULL_TIME_24H),
    endTime:
      entry.endTime &&
      moment(new Date(entry.endTime), FULL_TIME_24H)
        .utc()
        .format(FULL_TIME_24H),
  }))
}

export const validateDateEntries = (entries = []) => {
  let errors = []

  if (!entries || entries.length === 0) return errors

  const isToBeforeFrom = (from, to) => {
    return moment(to, FULL_TIME_24H).isBefore(
      moment(from, FULL_TIME_24H),
      'hour',
    )
  }

  entries.map((entry) => {
    const {
      days = [],
      startTime = '',
      endTime = '',
      startDate = '',
      endDate = '',
      type,
    } = entry

    if (type === EXCLUDE_DATE_RANGE_TYPE) {
      !startDate && errors.push('Date: Start date is required')
      !endDate && errors.push('Date: End date is required')
      beforeToday(startDate) &&
        errors.push('Date: Start date must be today or later')
      beforeToday(endDate) &&
        errors.push('Date: Start date must be today or later')
      moment(endDate).isBefore(moment(startDate)) &&
        errors.push('Date: End date must be after start date')
    }
    if (type === EXCLUDE_SCHEDULE_TYPE) {
      days.length === 0 && errors.push('Schedule: Must have at least one day')
      !startTime && errors.push('Schedule: Start time is required')
      !endTime && errors.push('Schedule: End time is required')
      isToBeforeFrom(startTime, endTime) &&
        errors.push('Schedule: End time must be greater than start time')
    }
    if (type === EXCLUDE_DATETIME_RANGE_TYPE) {
      !startTime && errors.push('Date & Time: Start time is required')
      !endTime && errors.push('Date & Time: End time is required')
      !startDate && errors.push('Date & Time: Start date is required')
      beforeToday(startDate) &&
        errors.push('Date & Time: Start date must be today or later')
      isToBeforeFrom(startTime, endTime) &&
        errors.push('Date & Time: End time must be greater than start time')
      {
        isToday(startDate) &&
          moment(`${startDate} ${startTime}`).isBefore(new Date(), 'minute') &&
          errors.push('Date & Time: Time range must be after current time')
      }
    }
  })

  return errors
}
