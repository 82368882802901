import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  fetchCallBackInformation,
  postCallBackInformation,
} from 'app/landing/direct_connect/direct_connect_callback_access'

export const getCallBackInformation = (applicationSlug) => () => {
  return fetchCallBackInformation(applicationSlug).then(
    ({ ok, responseText, dcCallBackInfo }) => {
      if (ok) {
        return Promise.resolve(dcCallBackInfo)
      }
      return responseText.then((t) => {
        return Promise.reject(translateErrorCodeToMessage(t))
      })
    },
  )
}

export const sendCallBackInformation =
  (applicationID, callBackDateTime) => () => {
    return postCallBackInformation(applicationID, callBackDateTime).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        }
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      },
    )
  }
