import { useEffect, useRef } from 'react'

export const useComponentWillUnmount = (cleanUpCb) => {
  const trackedStateRef = useRef({})

  const setAndMergeTrackedState = (state) => {
    trackedStateRef.current = {
      ...trackedStateRef.current,
      ...state,
    }
  }

  useEffect(() => {
    return () => {
      cleanUpCb(trackedStateRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { setAndMergeTrackedState }
}
