import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  Box,
  Button,
  LoadingSection,
} from 'app/components/design-system-components'
import { PageHeader } from 'app/components/design-system-components/page/Header'
import { PageSection } from 'app/components/design-system-components/page/Section'
import { PageSubHeading } from 'app/components/design-system-components/page/SubHeading'
import * as getters from 'app/components/design-system-components/theme/getters'
import * as Display from 'app/components/display/display'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { FollowsGrid } from 'app/pages/teams/settings/personal/follows/FollowsGrid'
import { FollowsGridRow } from 'app/pages/teams/settings/personal/follows/FollowsGrid/FollowsGridRow'
import { UnfollowMemberModal } from 'app/pages/teams/settings/personal/follows/UnfollowMemberModal'
import { UpdateFollowedMembersModal } from 'app/pages/teams/settings/personal/follows/UpdateFollowedMembersModal'
import { getTeamMembers } from 'app/services/http/teams/managers'
import {
  getFollowedTeamMembers,
  getFollowersTeamMembers,
  unFollowTeamMember,
} from 'app/services/http/teams/settings/follows'
import * as snugNotifier from 'app/services/snugNotifier'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'

const ContextMenuWrapper = styled(Display.ToolsButton)`
  .stop {
    color: ${getters.color('error600')};
  }
`

export const PureFollowsSettings = ({ currentTeam }) => {
  const { guid: teamId } = currentTeam

  const {
    state: teamMembers,
    setState: setTeamMembers,
    loadingStates: teamMembersLoadingStates,
    promiseWrapper: teamMembersPromiseWrapper,
  } = useStateWithLoading([])

  const {
    state: followedTeamMembers,
    setState: setFollowedTeamMembers,
    loadingStates: followedTeamMembersLoadingStates,
    promiseWrapper: followedTeamMembersPromiseWrapper,
  } = useStateWithLoading([])

  const {
    state: followersTeamMembers,
    setState: setFollowersTeamMembers,
    loadingStates: followersTeamMembersLoadingStates,
    promiseWrapper: followersTeamMembersPromiseWrapper,
  } = useStateWithLoading([])

  const [updateFollowedMembersModal, setUpdateFollowedMembersModal] = useState({
    isOpened: false,
    config: {},
  })

  const [unfollowMemberModal, setUnfollowMemberModal] = useState({
    isOpened: false,
    config: {},
  })

  useEffect(() => {
    loadTeamMembers()
  }, [])

  const loadTeamMembers = () => {
    const teamMembersPromise = getTeamMembers(teamId).then(({ managerList }) =>
      setTeamMembers(managerList),
    )
    return teamMembersPromiseWrapper(teamMembersPromise, true)
      .then(() => {
        loadFollowersTeamMembers()
        loadFollowedTeamMembers()
      })
      .catch()
  }

  const loadFollowedTeamMembers = () => {
    const followedTeamMembersPromise = getFollowedTeamMembers(teamId).then(
      setFollowedTeamMembers,
    )
    return followedTeamMembersPromiseWrapper(followedTeamMembersPromise)
  }

  const loadFollowersTeamMembers = () => {
    const followersTeamMembersPromise = getFollowersTeamMembers(teamId).then(
      setFollowersTeamMembers,
    )
    return followersTeamMembersPromiseWrapper(followersTeamMembersPromise)
  }

  const openUpdateFollowedMembersModal = () => {
    setUpdateFollowedMembersModal({
      isOpened: true,
      config: {
        onClose: onCloseUpdateFollowedMembersModal,
      },
    })
  }

  const onCloseUpdateFollowedMembersModal = (response) => {
    setUpdateFollowedMembersModal({ isOpened: false, config: {} })
    if (response) {
      snugNotifier.success('Following members have been updated')
      loadFollowedTeamMembers()
    }
  }

  const openUnfollowMemberModal = (teamMember) => {
    setUnfollowMemberModal({
      isOpened: true,
      config: {
        onClose: onCloseUnfollowMemberModal,
        onUnfollow: () => unFollowTeamMember(teamId, teamMember.id),
        teamMember,
      },
    })
  }

  const onCloseUnfollowMemberModal = (unfollowedMember) => {
    setUnfollowMemberModal({ isOpened: false, config: {} })
    if (unfollowedMember) {
      const { first_name, last_name } = unfollowedMember || {}
      snugNotifier.success(`Unfollowed ${first_name} ${last_name} successfully`)
      loadFollowedTeamMembers()
    }
  }

  const updateFollowedMembersModalElem =
    updateFollowedMembersModal.isOpened && (
      <UpdateFollowedMembersModal
        currentTeam={currentTeam}
        teamMembers={teamMembers}
        followedTeamMembers={followedTeamMembers}
        onCloseModal={updateFollowedMembersModal.config.onClose}
      />
    )

  const unFollowMemberModal = unfollowMemberModal.isOpened && (
    <UnfollowMemberModal
      teamMember={unfollowMemberModal.config.teamMember}
      onUnfollow={unfollowMemberModal.config.onUnfollow}
      onCloseModal={unfollowMemberModal.config.onClose}
    />
  )

  const followedMemberActionsRenderer = (teamMember) => {
    return (
      <ContextMenuWrapper
        tools={[
          {
            text: 'Stop',
            className: 'stop',
            enableTool: true,
            onClick: () => openUnfollowMemberModal(teamMember),
          },
        ]}
      />
    )
  }

  const followingSectionElem = (
    <PageSection>
      <PageSubHeading title="Following" />
      <LoadingSection
        loadingState={followedTeamMembersLoadingStates}
        actionHandler={() => loadFollowedTeamMembers()}
      >
        <FollowsGrid
          teamMembersFollows={followedTeamMembers}
          rowRenderer={({ teamMemberFollows }) => (
            <FollowsGridRow
              teamMemberFollows={teamMemberFollows}
              actionsColRenderer={() =>
                followedMemberActionsRenderer(teamMemberFollows?.team_member)
              }
            />
          )}
          noTeamMembersText="Not following any team members."
        />
      </LoadingSection>
    </PageSection>
  )

  const followedBySectionElem = (
    <PageSection>
      <PageSubHeading title="Followed by" />
      <LoadingSection
        loadingState={followersTeamMembersLoadingStates}
        actionHandler={() => loadFollowersTeamMembers()}
      >
        <FollowsGrid
          teamMembersFollows={followersTeamMembers}
          noTeamMembersText="No followers."
        />
      </LoadingSection>
    </PageSection>
  )

  return (
    <section>
      <PageHeader title="Following" />

      <LoadingSection
        loadingState={teamMembersLoadingStates}
        actionHandler={() => loadTeamMembers()}
        loaderProps={{ fontSize: '50px' }}
      >
        <PageSection lineHeight="1.2">
          Follow team members to subscribe to notifications for applications.
          See who is following your notifications.
          <Box mt={theme.space[8] + 'px'}>
            <Button
              sizeVariant="large"
              onClick={() => openUpdateFollowedMembersModal()}
            >
              Follow Team Member
            </Button>
          </Box>
        </PageSection>

        {followingSectionElem}

        {followedBySectionElem}
      </LoadingSection>

      {updateFollowedMembersModalElem}

      {unFollowMemberModal}
    </section>
  )
}

export const FollowsSettings = connect(({ session }) => ({
  currentTeam: session.currentTeam,
}))(PureFollowsSettings)
