import type { FC } from 'react'

import { messagesListLeadsFilterReleaseDate } from 'config/env'
import type { FormikProps } from 'formik'

import {
  Alert,
  Box,
  Flex,
  HorizontalLine,
  TextSpan,
} from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import { CheckboxesList } from 'app/components/design-system-components/list-filters/CheckboxesList'
import { DropdownBodySectionHeader } from 'app/components/design-system-components/list-filters/DropdownBodySectionHeader'
import UpgradeButton from 'app/components/design-system-components/UpgradeButton'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { ageInDays } from 'app/utils/datetime/helpers'

const featureAgeInDaysConsideredNew = 60

export const leadSourceFiltersFields = {
  source: 'source',
}

export const isLeadSourceFilterApplied = (sourceFilterValues: any) =>
  sourceFilterValues &&
  sourceFilterValues[leadSourceFiltersFields.source] &&
  Object.values(sourceFilterValues[leadSourceFiltersFields.source]).length

export const isLeadSourceFilterFeatureNew = () =>
  ageInDays(messagesListLeadsFilterReleaseDate) <= featureAgeInDaysConsideredNew

export const leadsSourceDataExtractor = (filterValues: any) => {
  const { [leadSourceFiltersFields.source]: sourcesObj = {} } = filterValues
  const sources = Object.keys(sourcesObj)
  const allIdx = sources.indexOf(allSourceOption.optionMeta.id)
  if (allIdx > -1) {
    sources.splice(allIdx, 1)
  }
  return {
    source: sources,
  }
}

export const leadsSourceAppliedFilterRenderer = ({ filterValues }: any) => {
  const { [leadSourceFiltersFields.source]: source } = filterValues
  if (source[allSourceOption.optionMeta.id])
    return allSourceOption.optionMeta.displayText
  return Object.values(source)
    .map((sourceObj: any) => sourceObj.optionMeta.displayText)
    .join(', ')
}

const allSourceOption = {
  option: 'all',
  optionMeta: { id: 'all', displayText: 'All' },
}

const sources = {
  selfManagingOwner: 'self-managing-owner',
  rentvestor: 'rentvestor',
}

const sourceKeys = {
  [sources.selfManagingOwner]: 'Self Managing Owner',
  [sources.rentvestor]: 'Rentvestor',
}

interface IFormBodyProps {
  formBag: FormikProps<any>
  disabled: boolean
}

export const LeadSourceFilterBody: FC<IFormBodyProps> = ({
  formBag: { values, setFieldValue },
  disabled,
}) => {
  const onCheckBoxClicked = (checked: boolean, option: any) => {
    if (option.optionMeta.id === allSourceOption.optionMeta.id) {
      toggleAll(checked)
      return
    }
    const sourceValues = values[leadSourceFiltersFields.source] || {}

    if (checked) {
      setFieldValue(leadSourceFiltersFields.source, {
        ...sourceValues,
        [option.optionMeta.id]: option,
      })
      console.log({
        ...sourceValues,
        [option.optionMeta.id]: option,
      })
    } else {
      const {
        [option.optionMeta.id]: uncheckedOption,
        [allSourceOption.optionMeta.id]: allOption,
        ...otherOptions
      } = sourceValues
      setFieldValue(leadSourceFiltersFields.source, {
        ...otherOptions,
      })
    }
  }

  const toggleAll = (checked: boolean) => {
    if (checked) {
      setFieldValue(
        leadSourceFiltersFields.source,
        Object.assign(
          {},
          ...sourcesOptions.map((option) => ({
            [option.optionMeta.id]: option,
          })),
        ),
      )
    } else {
      setFieldValue(leadSourceFiltersFields.source, {})
    }
  }

  const sourceValues = values[leadSourceFiltersFields.source] || {}

  const sourcesOptions = Object.values(sources).map((source) => ({
    option: source,
    optionMeta: {
      id: source,
      displayText: sourceKeys[source],
    },
  }))

  sourcesOptions.unshift(allSourceOption)

  return (
    <>
      <Box style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <DropdownBodySectionHeader
          header="LEADS"
          containerProps={{ mb: theme.space[4] + 'px' }}
        />
        <CheckboxesList
          options={sourcesOptions}
          checkedOptions={sourceValues}
          onCheckBoxClicked={onCheckBoxClicked}
        />
        <HorizontalLine mb={3} />
        {disabled && (
          <Alert variant="warningWithBg" mb={4} p={3}>
            <Flex
              alignItems="center"
              maxWidth="280px"
              color={theme.colors.pdfGray}
            >
              <UpgradeButton
                fontSize={theme.fontSizes.pExtraSmall10}
                padding={`${theme.space[1]}px ${theme.space[3]}px`}
              />
              <TextSpan
                as="span"
                fontSize={theme.fontSizes.pSmall12}
                lineHeight={theme.lineHeights.link}
                ml={3}
                mr={1}
              >
                Available on Premier plans. <br /> Click Upgrade to enquire.
              </TextSpan>
            </Flex>
          </Alert>
        )}
      </Box>
    </>
  )
}

export const LeadFilterTriggerPlaceholder = () => (
  <Flex alignItems="center">
    Leads
    {isLeadSourceFilterFeatureNew() && (
      <Badge variant="blue" text="New" ml={3} />
    )}
  </Flex>
)
