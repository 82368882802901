import React, { Component } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'

import helpersClass from 'app/shared_components/helpers'
import { isMobile } from 'app/shared_components/helpers'

const helpers = new helpersClass()

export default class DownloadIcs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isCrappyIE: false,
    }
  }

  UNSAFE_componentWillMount() {
    // polyfill for startsWith to fix IE bug
    if (!String.prototype.startsWith) {
      String.prototype.startsWith = function (searchString, position) {
        position = position || 0
        return this.indexOf(searchString, position) === position
      }
    }

    let isCrappyIE = false
    if (
      typeof window !== 'undefined' &&
      window.navigator.msSaveOrOpenBlob &&
      window.Blob
    ) {
      isCrappyIE = true
    }

    this.setState({ isCrappyIE })
  }

  handleDownloadLinkClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const url = e.currentTarget.getAttribute('href')

    if (!isMobile() && (url.startsWith('data') || url.startsWith('BEGIN'))) {
      const filename = 'download.ics'
      const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' })

      if (this.state.isCrappyIE) {
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        /** **************************************************************
          // many browsers do not properly support downloading data URIs
          // (even with "download" attribute in use) so this solution
          // ensures the event will download cross-browser
          *************************************************************** */
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } else {
      window.open(url, '_blank')
    }
  }

  renderDownloadLink() {
    return (
      <a
        className={this.props.linkWrapperClass}
        onClick={this.handleDownloadLinkClick}
        href={helpers.buildUrl(this.props.event, this.state.isCrappyIE)}
        target="_blank"
        rel="noreferrer"
      >
        {this.props.imageSrc ? (
          <img
            className="icon"
            src={this.props.imageSrc}
            height={this.props.imageSrcHeight}
          />
        ) : (
          <span className="blue-link-style">{this.props.linkText}</span>
        )}
      </a>
    )
  }

  render() {
    let linkToCalendar = null
    if (this.props.event) {
      linkToCalendar = this.renderDownloadLink()
    }

    return <div className={this.props.rootClass}>{linkToCalendar}</div>
  }
}

DownloadIcs.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }).isRequired,
  rootClass: PropTypes.string,
  linkWrapperClass: PropTypes.string,
}

DownloadIcs.defaultProps = {
  event: {
    title: 'Viewing Event',
    description: 'Viewing Event',
    location: 'Address of the property',
    startTime: '2016-09-16T20:15:00-04:00',
    endTime: '2016-09-16T21:45:00-04:00',
  },
  imageSrc: '',
  imageSrcHeight: '24',
  linkWrapperClass: '',
}
