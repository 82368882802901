import ErrorMessages from 'app/constants/error_messages'
import {
  RECEIVE_RESPONSE_TEXT,
  receiveError,
} from 'app/sm/ph_deposit/ph_deposit_actions'

const PhDepositMiddleware = (store) => (next) => (action) => {
  let { type, responseText } = action
  let errorCode = parseInt(responseText)
  let errorMessage = ErrorMessages[errorCode]

  switch (type) {
    case RECEIVE_RESPONSE_TEXT:
      if (errorMessage) {
        store.dispatch(receiveError(errorMessage))
        return next(action)
      }
      if (isNaN(responseText)) {
        store.dispatch(receiveError(responseText))
        return next(action)
      }

      store.dispatch(
        receiveError(`There was a problem. Error code: ${errorCode}`),
      )
      return next(action)

    default:
      return next(action)
  }
}

export default PhDepositMiddleware
