import React from 'react'

import { Link } from 'react-router-dom'

import AutocompletePeople from 'app/sm/common/autocomplete_people'

class AssignProviderModal extends React.Component {
  closeModal() {
    window.location.href = `/sm/`
  }

  render() {
    return (
      <div className="sm-modal">
        <div className="sm-modal-wrapper sm-modal-medium">
          <div onClick={() => this.closeModal()} className="sm-modal-close" />
          <div className="sm-modal-content overflow-visible">
            <h3 className="mb30">
              <i className="icon-person ibm mr10"></i>
              <span className="ibm">Assign Provider</span>
            </h3>

            <div className="row mb30">
              <div className="col-sm-12">
                <div className="bbs">
                  <AutocompletePeople
                    title="Search providers"
                    icon="icon-mail"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="input-box">
                  <textarea required rows="4"></textarea>
                  <label>Message</label>
                </div>
              </div>
            </div>

            <div className="row mt40">
              <div className="col-sm-6">
                <button className="btn btn-gray mb10">Cancel</button>
              </div>
              <div className="col-sm-6">
                <button className="btn mb10">Assign</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AssignProviderModal
