import React, { useEffect, useState } from 'react'

import moment from 'moment'
import qs from 'qs'
import OtpInput from 'react-otp-input'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  Alert,
  Box,
  Button,
  Flex,
  Modal,
} from 'app/components/design-system-components'
import PasswordInput from 'app/components/design-system-components/inputs/Password'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import AccessReferences from 'app/match/rental_reference/access_references/component'
import {
  LITE_ACCOUNT_JULY_2022_FLOW,
  LITE_ACCOUNT_JUNE_2023_FLOW,
} from 'app/match/rental_reference/rating_form/component'
import MatchRatingForm from 'app/match/rental_reference/rating_form/connection'
import ContactItem from 'app/pages/teams/rental-references/components/ContactItem'
import ContextMenu from 'app/pages/teams/rental-references/components/ContextMenu/ContextMenu'
import ReassignModal from 'app/pages/teams/rental-references/components/ReassignModal/ReassignModal'
import { StyledMetadataContainer } from 'app/pages/teams/rental-references/components/styled'
import VerifyOrContinueAsGuestModal from 'app/pages/teams/rental-references/components/VerifyOrContinueAsGuestModal'
import * as rentalReferenceHelpers from 'app/pages/teams/rental-references/helpers'
import {
  acceptInvite,
  archiveTeamRentalReference,
  connectTeamFromRRToLiteAccount,
  selfRegistration,
  unarchiveTeamRentalReference,
  verifyAccountFromRRToLiteAccount,
} from 'app/pages/teams/rental-references/helpers/http'
import {
  ActionContainer,
  StyledPill,
} from 'app/pages/teams/rental-references/styles'
import { resendOTPSMS } from 'app/services/http/session'
import * as snugNotifier from 'app/services/snugNotifier'
import { OtpInputContainer } from 'app/session/register_components/mobile_verification_form'
import { history } from 'app/shared_components/router'
import Spinner from 'app/sm/common/spinner'
import * as helpers from 'app/sm/helpers'
import EmailLink from 'app/sm/progress_application/components/email_link/component'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as GTMHelpers from 'app/utils/google-tag-manager/helpers'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'
import { normaliseStr } from 'app/utils/strings/helper'
import * as systemHelpers from 'app/utils/system/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import { isRRToLiteURL } from 'app/utils/url/helpers'

const isStacked = systemHelpers.isMobile()

export const PropertyCell = ({ property, renter }) => {
  const { property_address } = property
  const { first_name, last_name, phone, email } = renter
  return (
    <div>
      <div className="bold-text large-text lh14">{property_address}</div>
      <ContactItem
        firstName={first_name}
        lastName={last_name}
        email={email}
        phone={phone}
        textBeforeName={'Renter'}
        textBeforeNameBold
      />
    </div>
  )
}

export const RecipientHeader = () => {
  return <div>Recipient</div>
}

export const RecipientCell = ({ recipient, forProfilePreference }) => {
  const {
    agency_name = '',
    email = '',
    first_name = '',
    last_name = '',
    phone = '',
  } = recipient || {}
  if (forProfilePreference) {
    return (
      <div>
        {isStacked && <span>Recipient</span>}
        <div className="bold-text large-text lh14 grey-text display-flex align-items-center">
          Profile Reference
        </div>
        Application not submitted
      </div>
    )
  }
  return (
    <div>
      {isStacked && <span>Recipient</span>}
      <div className="bold-text large-text lh14 grey-text">{agency_name}</div>
      <ContactItem
        firstName={first_name}
        lastName={last_name}
        email={email}
        phone={phone}
        textBeforeName={'Manager'}
      />
    </div>
  )
}

export const AssignedToCell = ({ assignee }) => {
  const { first_name = '', last_name = '' } = assignee || {}
  return (
    <div>
      {isStacked && <span>Assignee</span>}

      <div>{`${first_name} ${last_name}`}</div>
    </div>
  )
}

export const VisibilityCell = ({ visibility }) => {
  const { bgColor, textColor, text } =
    rentalReferenceHelpers.rentalHistoryVisibilityStatusColor(visibility)
  return (
    <StyledPill bgColor={bgColor} textColor={textColor}>
      {text}
    </StyledPill>
  )
}

export const StatusCell = ({ status, statusTime }) => {
  const { bgColor, textColor, text, tooltip } =
    rentalReferenceHelpers.getRentalHistoryMetadata(status)
  return (
    <StyledMetadataContainer>
      <StyledPill
        data-tooltip-id={text}
        data-tooltip-content=""
        bgColor={bgColor}
        textColor={textColor}
      >
        {text}
      </StyledPill>
      <ReactTooltip id={text}>
        <span>{tooltip}</span>
      </ReactTooltip>
      <div className="timestamp">
        {moment(statusTime).format(dateTimeHelpers.TIME_AM_PM_DATE)}
      </div>
    </StyledMetadataContainer>
  )
}

export const ActionCell = ({
  action,
  status,
  recipient,
  property,
  renter,
  redirectToAuthenticatedRentalReference,
  agencyGUID = '',
  managerListInfo = {},
  assignee = {},
  teamRRGUID = '',
  isArchived = false,
  getRentalReferencesDataPage,
  teamSlug,
  location,
}) => {
  const { guid, secret } = action

  const {
    agency_name = '',
    email = '',
    first_name = '',
    last_name = '',
    phone = '',
  } = recipient || {}

  const [showCompletedRentalReference, setShowCompletedReference] =
    useState(false)
  const [exitSetupModal, setExitSetupModal] = useState(false)
  const [showConnectTeamModal, setShowConnectTeamModal] = useState(false)
  const [showVerifyAccountModal, setShowVerifyAccountModal] = useState(false)
  const [showAccessReferencesModal, setShowAccessReferencesModal] =
    useState(false)
  const [teamAlreadyExistsInfo, setTeamAlreadyExistsInfo] = useState({
    exists: false,
    agencyName: '',
    adminContact: '',
    showModal: false,
  })
  const [connectTeamInvalid, setConnectTeamInvalid] = useState(false)
  const [accessReferenceUsers, setAccessReferenceUsers] = useState([])
  const [showReassignModal, setShowReassignModal] = useState(false)
  const [
    verifyOrContinueAsGuestModalOpen,
    setVerifyOrContinueAsGuestModalOpen,
  ] = useState(false)
  const [continueAsGuest, setContinueAsGuest] = useState(false)
  const [agencyIdAfterSelfRegistration, setAgencyIdAfterSelfRegistration] =
    useState('')

  useEffect(() => {
    const isRRToLite = isRRToLiteURL()
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { liteAccountEligibility = LITE_ACCOUNT_JULY_2022_FLOW } = queryParams

    if (isRRToLite) {
      if (
        liteAccountEligibility &&
        liteAccountEligibility === String(LITE_ACCOUNT_JUNE_2023_FLOW)
      ) {
        toggleVerifyOrContinueAsGuestModal()
      } else {
        continueAsGuestAction(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleCompletedReferenceModal = () => {
    setShowCompletedReference(!showCompletedRentalReference)
  }

  const toggleAccessReferencesModal = () => {
    setShowAccessReferencesModal(!showAccessReferencesModal)
  }

  const toggleVerifyOrContinueAsGuestModal = () => {
    setVerifyOrContinueAsGuestModalOpen(!verifyOrContinueAsGuestModalOpen)
  }

  const continueAsGuestAction = (
    toggleVerifyOrContinueAsGuestModalAction = true,
  ) => {
    const queryString = location.search
    const queryParams =
      qs.parse(queryString, {
        ignoreQueryPrefix: true,
      }) || {}

    const newQueries = { ...queryParams, guest: true }

    history.push({ search: qs.stringify(newQueries) })
    if (toggleVerifyOrContinueAsGuestModalAction) {
      toggleVerifyOrContinueAsGuestModal()
    }
    toggleCompletedReferenceModal()
    setContinueAsGuest(true)
  }

  const isStatusUploadLedger =
    status > rentalReferenceHelpers.RentalHistoryStatusNew &&
    status < rentalReferenceHelpers.RentalHistoryStatusCompleted
  const toggleConnectTeamMemberModal = () => {
    setShowConnectTeamModal(!showConnectTeamModal)
  }

  const toggleVerifyAccountModal = () => {
    setShowVerifyAccountModal(!showVerifyAccountModal)
  }

  const toggleExitSetupModal = () => {
    setExitSetupModal(!exitSetupModal)
  }

  const toggleReassignModal = () => {
    setShowReassignModal(!showReassignModal)
  }
  const [verificationCode, setVerificationCode] = useState('')
  const updateVerificationCode = (code) => {
    setVerificationCode(code)
  }

  const [password, setPassword] = useState('')
  const updatePassword = ({ target: { value } }) => {
    setPassword(value)
  }
  const [inviteCode, setInviteCode] = useState('')

  const {
    loadingStates: connectTeamLoading,
    loadingStatesHelpers: connectTeamLoadingHelpers,
  } = useLoadingStates()

  const {
    loadingStates: createTeamLoading,
    loadingStatesHelpers: createTeamLoadingHelpers,
  } = useLoadingStates()

  const primaryActionConnectTeam = () => {
    if (!connectTeamInvalid && continueAsGuest) {
      snugNotifier.error('Error validation on invite team member email')
      return
    }
    if (
      accessReferenceUsers.filter(
        (accessReferenceUser) => accessReferenceUser.email === email,
      )?.length > 0
    ) {
      snugNotifier.error(
        'You email address is not required, please only include other team members',
      )
      return
    }
    const campaignName = !continueAsGuest
      ? textHelpers.RENTAL_REFERNCES_CAMPAIGN_JUNE_2023
      : textHelpers.RENTAL_REFERNCES_CAMPAIGN_JULY_2022
    const selfRegisterPayload = {
      name: agency_name,
      addressHistoryGUID: guid,
      agencyUserInvites: [
        {
          firstName: first_name,
          email: email,
          lastName: last_name,
          mobile: phone,
          isAdmin: true,
          isFinance: false,
          isLeasing: false,
        },
        ...accessReferenceUsers,
      ],
      source: 'RentalReference',
      meta: {
        generateDummyData: true, // not working in current PR
        tracking: {
          source: 'Snug.com',
          medium: 'leads/rental-references',
          campaign: campaignName,
          content: guid,
        },
      },
    }
    connectTeamLoadingHelpers.startLoading()
    selfRegistration(selfRegisterPayload)
      .then((data) => {
        if (!!data.userInviteLink) {
          let regex = /\/agency\/invite\?code=([\w-]{36})/
          let results = data.userInviteLink.match(regex)
          let code = results && results.length === 2 && results[1]
          setInviteCode(code)

          const payload = {
            email: normaliseStr(email),
            firstname: first_name,
            lastname: last_name,
            mobile: phone,
            invitationID: code,
            errors: {},
          }

          createTeamLoadingHelpers.startLoading()
          connectTeamFromRRToLiteAccount(payload)
            .then(() => {
              snugNotifier.success('Team Successfully Registered')
              connectTeamLoadingHelpers.markDoneSuccessfully()
              createTeamLoadingHelpers.markDoneSuccessfully()
              if (showConnectTeamModal) toggleConnectTeamMemberModal()
              if (showAccessReferencesModal) toggleAccessReferencesModal()
              toggleVerifyAccountModal()
            })
            .catch((error) => {
              snugNotifier.error(error?.message)
              createTeamLoadingHelpers.setError(error)
            })
        } else {
          if (showConnectTeamModal) toggleConnectTeamMemberModal()
          if (showAccessReferencesModal) toggleAccessReferencesModal()
          setTeamAlreadyExistsInfo({
            exists: true,
            agencyName: data?.agencyName,
            adminContact: data?.joinRequest?.sentTo?.email,
            showModal: true,
          })
        }
      })
      .catch((error) => {
        snugNotifier.error(error?.message || 'Unable to create agency profile')
        connectTeamLoadingHelpers.setError(error)
      })
  }

  const primaryActionVerifyAccount = () => {
    let payload = {
      code: verificationCode,
    }

    if (password) {
      payload = {
        ...payload,
        password,
      }
    }

    verifyAccountFromRRToLiteAccount(payload)
      .then(() => {
        acceptInvite(inviteCode)
          .then((a) => {
            toggleVerifyAccountModal()
            snugNotifier.success('Registration Successful')

            if (!continueAsGuest) {
              toggleCompletedReferenceModal()
              setAgencyIdAfterSelfRegistration(a.agencyId)
              return
            }
            // add redirect code here
            redirectToAuthenticatedRentalReference(a.agencyId)
          })
          .catch((error) => snugNotifier.error(error?.message))
      })
      .catch((error) => snugNotifier.error(error?.message))
  }

  let isStatusCompleted =
    status === rentalReferenceHelpers.RentalHistoryStatusCompleted
  let actionLabel = 'Review'
  if (status === rentalReferenceHelpers.RentalHistoryStatusDeclined) {
    return null
  }
  if (isStatusCompleted) {
    actionLabel = 'View'
  }
  const submitTenancyLedger =
    status === rentalReferenceHelpers.RentalHistoryStatusUploadLedger

  const isRRToLite = isRRToLiteURL()
  const hasConnectTeamError =
    connectTeamLoading.state === loadingStatesIds.ERROR

  const toggleInviteSentModal = () => {
    setTeamAlreadyExistsInfo({
      ...teamAlreadyExistsInfo,
      showModal: !teamAlreadyExistsInfo.showModal,
    })
  }

  const creatingTeamLoading =
    createTeamLoading.state === loadingStatesIds.LOADING

  const onArchiveClick = () => {
    archiveTeamRentalReference(agencyGUID, teamRRGUID)
      .then(() => {
        snugNotifier.success('Archive reference successfully')
        getRentalReferencesDataPage(agencyGUID)
      })
      .catch((error) => {
        snugNotifier.error(error.message)
      })
  }
  const onUnarchiveClick = () => {
    unarchiveTeamRentalReference(agencyGUID, teamRRGUID)
      .then(() => {
        snugNotifier.success('Unrchive reference successfully')
        getRentalReferencesDataPage(agencyGUID)
      })
      .catch((error) => {
        snugNotifier.error(error.message)
      })
  }

  const redirectToRRTable = () => {
    redirectToAuthenticatedRentalReference(agencyIdAfterSelfRegistration)
  }

  const verifyAccountModalHeading = !continueAsGuest
    ? 'Set password and verify'
    : 'Verify your contact details'
  return (
    <>
      <Flex mt="10px">
        <ActionContainer
          href={
            isRRToLite || isStatusCompleted
              ? null
              : `${helpers.urlTo('rentalReferenceURL', {
                  ratingGUID: guid,
                  secret,
                  createLiteAccount: false,
                  submitTenancyLedger,
                })}&fromRRInbox=true`
          }
          target="_blank"
          onClick={() =>
            (isStatusCompleted || isRRToLite) && toggleCompletedReferenceModal()
          }
        >
          {actionLabel}
        </ActionContainer>
        <Box ml="8px">
          <ContextMenu
            isArchived={isArchived}
            isSubmitReference={isStatusUploadLedger && !isStatusCompleted}
            toggleCompletedReferenceModal={toggleCompletedReferenceModal}
            toggleReassignModal={toggleReassignModal}
            onArchiveClick={onArchiveClick}
            onUnarchiveClick={onUnarchiveClick}
          />
        </Box>
      </Flex>

      <>
        {/* code to show */}
        {verifyOrContinueAsGuestModalOpen && (
          <VerifyOrContinueAsGuestModal
            toggleVerifyOrContinueAsGuestModal={
              toggleVerifyOrContinueAsGuestModal
            }
            toggleCompletedReferenceModal={toggleCompletedReferenceModal}
            toggleVerifyAccountModal={toggleVerifyAccountModal}
            primaryActionConnectTeam={() => primaryActionConnectTeam()}
            continueAsGuestAction={continueAsGuestAction}
            managerEmail={email}
          />
        )}
        {showCompletedRentalReference && (
          <Modal
            toggleModal={toggleCompletedReferenceModal}
            modalHeading="Complete Reference"
            modalBody={
              <MatchRatingForm
                toggleCompletedReferenceModal={toggleCompletedReferenceModal}
                toggleConnectTeamMemberModal={toggleAccessReferencesModal}
                ratingGUID={guid}
                secret={secret}
                redirectToRRTable={redirectToRRTable}
              />
            }
            showPrimaryButton={false}
            secondaryLabel="Close"
            secondaryAction={toggleCompletedReferenceModal}
            standardWidth={true}
            takeIncreasedWidth={true}
            takeMaxWidth={true}
            showSecondaryButton={false}
            contentClass="justify-content-center"
            customModalFooter={true}
            modalFooter={
              <Box>
                <Box height="50px"></Box>
              </Box>
            }
          />
        )}
        {showConnectTeamModal && (
          <Modal
            modalBody={
              <>
                {connectTeamLoading.state === loadingStatesIds.LOADING ? (
                  <Flex justifyContent="center">
                    <Spinner />
                  </Flex>
                ) : (
                  <>
                    {!hasConnectTeamError ? (
                      <Box fontSize={theme.fontSizes.pLarge16}>
                        <Text>
                          {first_name}, did you know you can add other team
                          members to reassign or view past references?
                        </Text>

                        <Text mb={0}>It's easy and takes just 1 minute!</Text>
                      </Box>
                    ) : (
                      <Alert variant="warningWithBg" mt={theme.space[3] + 'px'}>
                        {connectTeamLoading?.error?.message}
                      </Alert>
                    )}
                  </>
                )}
              </>
            }
            modalHeading="Reference submitted, thank you!"
            primaryLabel="Add Team Members"
            primaryAction={primaryActionConnectTeam}
            secondaryLabel="Close"
            secondaryAction={() => toggleExitSetupModal()}
            toggleModal={() => toggleExitSetupModal()}
            primaryButtonClassName={
              GTMHelpers.GTM_RR_LITE_REFRENCE_SUBMITTED_PRIMARY
            }
            secondaryButtonClassName={
              GTMHelpers.GTM_RR_LITE_REFRENCE_SUBMITTED_SECONDARY
            }
          />
        )}
        {showVerifyAccountModal && (
          <Modal
            modalBody={
              <Box fontSize={theme.fontSizes.pLarge16}>
                <Text>
                  Before you add your team, please enter the code sent via email
                  to {email}
                </Text>
                <Box mb={5}>
                  <PasswordInput
                    passwordValue={password}
                    onPasswordChange={updatePassword}
                  />
                </Box>
                <OtpInputContainer>
                  <OtpInput
                    containerStyle="container-style"
                    onChange={updateVerificationCode}
                    numInputs={6}
                    separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                    value={verificationCode}
                    shouldAutoFocus={true}
                    isInputNum={true}
                  />
                </OtpInputContainer>
                <Text mt={6} mb={0}>
                  Code valid for 5 minutes. Didn’t get the code?{' '}
                  <Button variant="linkBlue" onClick={resendOTPSMS}>
                    Resend
                  </Button>
                </Text>
              </Box>
            }
            modalHeading={verifyAccountModalHeading}
            primaryLabel="Verify"
            primaryAction={primaryActionVerifyAccount}
            secondaryLabel="Close"
            secondaryAction={() => toggleExitSetupModal()}
            toggleModal={() => toggleExitSetupModal()}
            primaryButtonClassName={
              GTMHelpers.GTM_RR_LITE_VERIFY_DETAILS_PRIMARY
            }
            secondaryButtonClassName={
              GTMHelpers.GTM_RR_LITE_VERIFY_DETAILS_SECONDARY
            }
          />
        )}
        {exitSetupModal && (
          <Modal
            modalBody={
              <Box fontSize={theme.fontSizes.pLarge16}>
                <Text mb={0}>
                  You have not finished connecting your team. Do you want to
                  close or continue?
                </Text>
              </Box>
            }
            modalHeading="Are you sure?"
            primaryLabel="Continue"
            primaryAction={() => toggleExitSetupModal()}
            secondaryLabel="Close"
            secondaryAction={() => {
              setExitSetupModal(false)
              setShowConnectTeamModal(false)
              setShowVerifyAccountModal(false)
            }}
            toggleModal={() => toggleExitSetupModal()}
            primaryButtonClassName={GTMHelpers.GTM_RR_LITE_ARE_YOU_SURE_PRIMARY}
            secondaryButtonClassName={
              GTMHelpers.GTM_RR_LITE_ARE_YOU_SURE_SECONDARY
            }
          />
        )}
        {teamAlreadyExistsInfo.showModal && (
          <Modal
            modalBody={
              <Box fontSize={theme.fontSizes.pLarge16} width="100%">
                <Text mb={0}>
                  Your request to join {teamAlreadyExistsInfo.agencyName} and
                  access your past references has been sent. Please contact{' '}
                  <EmailLink
                    linkText={teamAlreadyExistsInfo.adminContact}
                    // normalText={normalText}
                    mailTo={teamAlreadyExistsInfo.adminContact}
                    // subject={subject}
                  />
                  if you do not receive an invite.
                </Text>
              </Box>
            }
            toggleModal={toggleInviteSentModal}
            modalHeading="Invite requested"
            showPrimaryButton={false}
            secondaryLabel="Close"
            secondaryAction={toggleInviteSentModal}
            secondaryButtonClassName={
              GTMHelpers.GTM_RR_LITE_INVITE_REQUESTED_SECONDARY
            }
          />
        )}
        {/* CONNECT TEAM MEMBER */}
        {showAccessReferencesModal && (
          <Modal
            modalBody={
              <AccessReferences
                setAccessReferenceUsers={setAccessReferenceUsers}
                markDirty={setConnectTeamInvalid}
              />
            }
            primaryButtonLoading={creatingTeamLoading}
            primaryLabel={textHelpers.PrimaryAccessReference}
            primaryAction={() => primaryActionConnectTeam()}
            modalHeading="Reference submitted, verify your email"
            showPrimaryButton={true}
            secondaryLabel="Close"
            secondaryAction={() => toggleExitSetupModal()}
            toggleModal={() => toggleExitSetupModal()}
            standardWidth={true}
            takeIncreasedWidth={true}
          />
        )}
        {showReassignModal && (
          <ReassignModal
            toggleReassignModal={toggleReassignModal}
            recipient={recipient}
            property={property}
            renter={renter}
            managerListInfo={managerListInfo}
            assignee={assignee}
            agencyGUID={agencyGUID}
            teamRRGUID={teamRRGUID}
            getRentalReferencesDataPage={getRentalReferencesDataPage}
            teamSlug={teamSlug}
          />
        )}
      </>
    </>
  )
}
