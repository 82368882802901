import { connect } from 'react-redux'

import { fetchTeamApplications } from 'app/agency/applications_list/action'
import ApplicationsTable from 'app/agency/applications_list/applications_table/component'
import { fetchAllPropertyFutureViewings } from 'app/bond_cover/agency/agency_actions'
import { setPropertyFilters } from 'app/dashboard/team_overview_actions'
import { fetchLeasingOfferInfo } from 'app/match/leaseAttachment/action'
import { addViewing } from 'app/sm/ppp/viewing_actions'
import {
  fetchApplicationNotesWithLimit,
  fetchNotesWithLimit,
  markProgressApplicationAsApproved,
  markProgressApplicationAsLeased,
  sendScheduleViewingFromApplication,
  sendUtilityReferral,
} from 'app/sm/progress_application/action'
import {
  declineApplication,
  moveTo,
  offerApplication,
  shortlistApplication,
  unshortlistApplication,
  withdrawOfferForApplication,
} from 'app/sm/renter_applications/renter_applications_actions'

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
  currentAgency: session.currentAgency,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTeamApplications: (teamGUID, filters) =>
    dispatch(fetchTeamApplications(teamGUID, filters)),
  shortListApplication: (applicationID) =>
    dispatch(shortlistApplication(applicationID)),
  removeShortListApplication: (applicationID) =>
    dispatch(unshortlistApplication(applicationID)),
  declineApplication: (
    agencyGUID,
    applicationID,
    declineReasons,
    notifyApplicant,
    otherReasonComment,
  ) =>
    dispatch(
      declineApplication(
        agencyGUID,
        applicationID,
        declineReasons,
        notifyApplicant,
        otherReasonComment,
      ),
    ),
  offerApplication: (applicationID) =>
    dispatch(offerApplication(applicationID)),
  withdrawOfferForApplication: (applicationID) =>
    dispatch(withdrawOfferForApplication(applicationID)),
  moveTo: (applicationId, newStatus) =>
    dispatch(moveTo(applicationId, newStatus)),
  fetchProgressApplication: (applicationId, agencyId) =>
    dispatch(fetchLeasingOfferInfo(applicationId, agencyId)),
  markProgressApplicationAsLeased: (
    applicationId,
    applicationInfo,
    markAsLeasedOptions,
  ) =>
    dispatch(
      markProgressApplicationAsLeased(
        applicationId,
        applicationInfo,
        markAsLeasedOptions,
      ),
    ),
  markProgressApplicationAsApproved: (
    applicationId,
    applicationInfo,
    agencyGUID,
    markAsApplicationApprovedOptions,
  ) =>
    dispatch(
      markProgressApplicationAsApproved(
        applicationId,
        applicationInfo,
        agencyGUID,
        markAsApplicationApprovedOptions,
      ),
    ),
  sendUtilityReferral: (applicationId) =>
    dispatch(sendUtilityReferral(applicationId)),
  fetchNotesWithLimit: (ownerGUID, ownerType, notesLimit) => {
    dispatch(fetchNotesWithLimit(ownerGUID, ownerType, notesLimit))
  },
  fetchApplicationNotesWithLimit: (applicationGUID, notesLimit) =>
    dispatch(fetchApplicationNotesWithLimit(applicationGUID, notesLimit)),
  sendScheduleViewingFromApplication: (
    applicationGUID,
    applicantGUID,
    viewingGUID,
  ) =>
    dispatch(
      sendScheduleViewingFromApplication(
        applicationGUID,
        applicantGUID,
        viewingGUID,
      ),
    ),
  addViewing: (
    id,
    startdate,
    duration,
    fromScheduler,
    skipDoubleBookingCheck,
    options,
  ) =>
    dispatch(
      addViewing(
        id,
        startdate,
        duration,
        fromScheduler,
        skipDoubleBookingCheck,
        options,
      ),
    ),
  fetchAllPropertyFutureViewings: (teamGUID) =>
    dispatch(fetchAllPropertyFutureViewings(teamGUID)),
  setPropertyFilters: (filters) => dispatch(setPropertyFilters(filters)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsTable)
