import React from 'react'

import { GreyBodyText } from 'app/components/display/text/standard_text/standard-body/component'

import 'app/components/display/snug_tip/style.scss'

const SnugTip = ({ text = '', componentClass = '', textClass = '' }) => {
  return (
    <div className={`snug-tip-container ${componentClass}`}>
      <div className="snug-tip-logo">
        <img
          src="https://s3-ap-southeast-2.amazonaws.com/snugco/assets/logo/snug-logo-icon-pink.svg"
          alt="Snug Logo"
        />
      </div>
      <div className={`snug-tip-message ${textClass}`}>
        <GreyBodyText text={text} />
      </div>
    </div>
  )
}
export default SnugTip
