import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  Overlay,
  Summary,
} from 'app/application_revamp/components/Applicant/shared_styles'
import Chart from 'app/application_revamp/components/Common/Chart'
import WidgetHeading from 'app/application_revamp/components/Common/WidgetHeading'
import ApplicantRow from 'app/application_revamp/components/Layout/ApplicantRow'
import ApplicationHolder from 'app/application_revamp/components/Layout/ApplicationHolder'
import approvalImg from 'app/assets/icons/application_green_tick.png'
import errorImg from 'app/assets/icons/application_red_error.png'
import warningImg from 'app/assets/icons/application_yellow_warning.png'
import * as Helpers from 'app/sm/helpers'
import { Invited } from 'app/utils/applications/renters'
import * as datetime from 'app/utils/datetime/helpers'
import * as phoneNumberHelpers from 'app/utils/phonenumber/helpers'

const propTypes = {
  applicant: PropTypes.object,
  change: PropTypes.func,
}

// NOTE: Remains for when notice icons are included.
// const Icon = styled.i`
//   margin-right: 3px;
//   vertical-align: sub;
//   display: inline-block;
//
//   &.icon-warning,
//   &.icon-error {
//     margin: -4px 3px -10px 0;
//     font-size: 22px;
//   }
// `

const ContactDetails = styled.div`
  line-height: 1.57;
  margin-bottom: 8px;
`

const SentLabel = styled.span`
  margin-left: 8px;
  color: var(--pdf-gray-text);
`

const Name = styled.p`
  font-weight: bolder;
  font-size: 18px !important;
  color: #212025;
  line-height: 16px;
`

const summary = [
  {
    id: 'list-01',
    title: 'Preferences',
    info: true,
  },
  {
    id: 'list-02',
    title: 'Address history',
    error: true,
  },
  {
    id: 'list-03',
    title: 'Employment details',
    warning: true,
  },
  {
    id: 'list-04',
    title: 'Income and financials',
    warning: true,
  },
  {
    id: 'list-05',
    title: 'Identity documents',
  },
  {
    id: 'list-06',
    title: 'Background check',
  },
  {
    id: 'list-07',
    title: 'Emergency contacts',
  },
  {
    id: 'list-08',
    title: 'Personal Reference',
  },
  {
    id: 'list-09',
    title: 'Pet details',
    warning: true,
  },
  {
    id: 'list-10',
    title: 'Student details',
  },
  {
    id: 'list-11',
    title: 'Other info',
    warning: true,
  },
  {
    id: 'list-12',
    title: 'Compilance',
  },
]

function SecondaryApplicant({
  applicant,
  change,
  onRequestSubmitApplication,
  lastSubmitApplicationRequestTime,
  isReminderSent,
  isManagerAdded,
}) {
  const {
    lastName,
    firstName,
    isPrimary,
    applicationID,
    score,
    guidID,
    applied,
    mobileNumber,
    email,
    dob,
    ballotsEnabled = false,
  } = applicant
  const name = `${firstName} ${lastName}`
  const infoCounter = summary.filter((item) => item.info).length
  const warningCounter = summary.filter((item) => item.warning).length
  const errorCounter = summary.filter((item) => item.error).length
  const typeText = Helpers.getTenantTypeTextByStatus(isPrimary, applicationID)
  const emptyScore = 0
  const showScore = Math.floor(score) > emptyScore && !ballotsEnabled
  const statusText = applicant && applicant.applied ? 'Applied' : 'Invited'
  const formattedMobileNumber =
    phoneNumberHelpers.formatPhoneNumber(mobileNumber)
  const lastReminderSentTimeStamp = datetime.nowOrProvidedTimestamp(
    isReminderSent,
    lastSubmitApplicationRequestTime,
  )
  const lastReminderSentTimeFormatted = datetime.standardFormattedTimeStamp(
    lastReminderSentTimeStamp,
  )
  const dateOfBirthStr = datetime.formattedDateOfBirth(dob)

  // noinspection PointlessBooleanExpressionJS
  return (
    <ApplicantRow>
      {showScore && <Chart snug_match_score={Math.floor(score)} />}
      <ApplicationHolder>
        <Name>{name}</Name>
        <WidgetHeading title={typeText} />
        <ContactDetails>
          <a href={'tel:' + mobileNumber}>{formattedMobileNumber}</a>
          <br />
          <a href={'mailto:' + email}>{email}</a>
        </ContactDetails>
        {!isManagerAdded && (
          <div>
            <WidgetHeading title={statusText} />
            {dob && <WidgetHeading title={dateOfBirthStr} />}
            {statusText === Invited && isReminderSent && (
              <SentLabel>Reminder Sent</SentLabel>
            )}
            {statusText === Invited && !isReminderSent && (
              <span
                className="blue-link-style"
                onClick={onRequestSubmitApplication}
              >
                Send Reminder
              </span>
            )}
          </div>
        )}
        {!isManagerAdded &&
          statusText === Invited &&
          (lastSubmitApplicationRequestTime || isReminderSent) && (
            <p className="resend-reference-request-info mt-auto mb-auto">
              Last reminder: {lastReminderSentTimeFormatted}
            </p>
          )}

        {/* TODO: enable once summary icons are ready */}
        {false && (
          <Summary>
            {infoCounter ? (
              <li>
                <img
                  className="width20 mr5"
                  src={approvalImg}
                  alt="approvalImg"
                />
                {infoCounter}
              </li>
            ) : (
              ''
            )}
            {warningCounter ? (
              <li>
                <img
                  className="width20 mr5"
                  src={warningImg}
                  alt="warningImg"
                />
                {warningCounter}
              </li>
            ) : (
              ''
            )}
            {errorCounter ? (
              <li>
                <img className="width20 mr5" src={errorImg} alt="errorImg" />
                {errorCounter}
              </li>
            ) : (
              ''
            )}
          </Summary>
        )}
      </ApplicationHolder>
      <Overlay onClick={() => change(guidID, applied)} />
    </ApplicantRow>
  )
}

SecondaryApplicant.propTypes = propTypes

export default SecondaryApplicant
