import { composeAttachment } from 'app/shared_components/helpers'
import {
  getDocuments,
  removeDocument,
  uploadDocument,
} from 'app/sm/documents/access'

export const RECEIVE_DOCUMENTS = 'D_RECEIVE_DOCUMENTS'
export const TOGGLE_SPINNER = 'D_TOGGLE_SPINNER'
export const RECEIVE_RESPONSE_TEXT = 'D_RECEIVE_RESPONSE_TEXT'

const receiveDocuments = (documents) => ({ type: RECEIVE_DOCUMENTS, documents })
const toggleSpinner = () => ({ type: TOGGLE_SPINNER })
const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
// Cause we use a diffrent naming

export const fetchDocuments = () => {
  return (dispatch) => {
    getDocuments().then(
      ({ ok, Record, responseText, statusText, statusCode }) => {
        if (ok) {
          dispatch(receiveDocuments(Record))
        } else {
          responseText.then((t) => {
            if (!t) {
              dispatch(receiveResponseText(`${statusCode} ${statusText}`))
            } else {
              dispatch(receiveResponseText(t))
            }
          })
        }
      },
    )
  }
}

// This is an upload functionality
export const addDocument = (documentType) => {
  return (dispatch) => {
    let attachments = document.getElementById(`attachments-${documentType}`)
    let f = new FormData()

    composeAttachment(attachments, f)
    dispatch(toggleSpinner())

    uploadDocument(f).then(({ ok, statusCode, responseText, statusText }) => {
      if (ok) {
        dispatch(fetchDocuments())
      } else {
        responseText.then((t) => {
          if (!t) {
            dispatch(receiveResponseText(`${statusCode} ${statusText}`))
          } else {
            dispatch(receiveResponseText(t))
          }
        })
      }
      dispatch(toggleSpinner())
    })
  }
}

export const deleteDocument = (id) => {
  return (dispatch) => {
    removeDocument(id).then(({ ok, statusCode, responseText, statusText }) => {
      if (ok) {
        dispatch(fetchDocuments())
      } else {
        responseText.then((t) => {
          if (!t) {
            dispatch(receiveResponseText(`${statusCode} ${statusText}`))
          } else {
            dispatch(receiveResponseText(t))
          }
        })
      }
    })
  }
}

export const deleteManagerAttachment = (id) => {
  return (dispatch) => {
    return removeDocument(id).then(
      ({ ok, statusCode, responseText, statusText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) => Promise.reject(error))
        }
      },
    )
  }
}
