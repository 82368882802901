import { history } from 'app/history'
import { isPropertyDraft, isPropertyLeased, urlTo } from 'app/sm/helpers'

export const NoApplicationRow = ({ property, leased_application_guid }) => {
  return (
    <div className="display-flex flex-direction-column border-bottom height80 text-align-center no-applicant-container mb20">
      {isPropertyDraft(property.status) && (
        <div className="status-draft-tag">
          <span className="big-tag-text">Draft</span>
          <span
            className="linkStyle ml10"
            onClick={() =>
              history.push(
                urlTo('updatePropertyBasics', { id: property.guidID }),
              )
            }
          >
            Add property details
          </span>
        </div>
      )}
      {isPropertyLeased(property.status) && (
        <div className="status-leased-tag">
          <span className="big-tag-text">Leased</span>
          {leased_application_guid && (
            <span
              className="linkStyle ml10"
              onClick={() =>
                history.push(
                  urlTo('partialApplication', {
                    propertyId: property.guidID,
                    applicationId: leased_application_guid,
                  }),
                )
              }
            >
              View successful application
            </span>
          )}
        </div>
      )}
      {!isPropertyDraft(property.status) &&
        !isPropertyLeased(property.status) &&
        'No activities'}
    </div>
  )
}
