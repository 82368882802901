import React from 'react'

import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import NextText from 'app/bond_cover/renters/sharedComponent/next_text_component'
import * as Display from 'app/components/display/display'
import ProgressBarComponent from 'app/components/progress_bar/connection'
import getParameter from 'app/shared_components/parameter_parser'
import Header from 'app/shared_components/rental_reputation_header_connection'
import { history } from 'app/shared_components/router'
import SnugTip from 'app/shared_components/snug_tips'
import { isFromApplication, urlTo } from 'app/sm/helpers'
import PersonalReferenceBox from 'app/sm/personal_reference/components/personal_reference_box'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'

class PersonalReferenceAdd extends React.Component {
  componentDidMount() {
    this.props.getPersonalReferences()
    this.props.fetchCompleteness()
  }

  confirmRemovePersonalReference = (guidID) => {
    if (window.confirm('Are you sure?')) {
      this.props.removePersonalReference(guidID)
    }
  }

  render() {
    const { personalReferences, property, backUrl, completeness } = this.props
    const propertyId = getParameter('propertyId')
    const applicationId = getParameter('applicationId')
    const isFromApplicationForm =
      isFromApplication(backUrl) || propertyId || applicationId

    return (
      <Display.CenterContentContainer componentClass="width100">
        <ProgressBarComponent completeness={completeness} />
        <RentalReputationHeaderReminder
          property={property}
          backUrl={backUrl}
          remindText={'Current application'}
          isFromApplicationForm={isFromApplicationForm}
          title="Personal Reference"
        />

        <Header
          title="Personal Reference"
          message="Adding two personal references can help support your application eg. colleague at work, community leader, friend or family."
        />

        <div className="table mb20">
          <div className="cell-m-100">
            <h4>Add Personal Reference</h4>
          </div>
          <div className="cell-m">
            <Link className="btn btn-round" to={urlTo('personalReferenceAdd')}>
              <i className="icon-add" />
            </Link>
          </div>
        </div>

        <div className="mb50">
          {(personalReferences || []).length > 0 &&
            personalReferences.map((personalRef) => (
              <PersonalReferenceBox
                confirmRemovePersonalReference={
                  this.confirmRemovePersonalReference
                }
                personalRef={personalRef}
                key={personalRef.guidID}
              />
            ))}
        </div>

        <ReactTooltip className="tooltip" />

        <SnugTip tipContent="Adding a friend, colleague or family member can help support your application." />
        <Display.BottomContentContainer>
          <Display.CenterContentContainer componentClass="width100">
            <div className="submit-button-container">
              <BottomButton
                nextUrl={`${urlTo('studentDetails')}${window.location.search}`}
                btnText={
                  isFromApplicationForm
                    ? 'Save & Continue'
                    : 'Next: Students Details'
                }
                bottomButtonsClass="pt0 mt0 summary-bottom-button"
                nextBtnClass={`summary-green-button-class`}
                backButtonClass="summary-grey-button-class p0"
                nextButtonPositionClass="pr20 pl0"
                showArrow={false}
              />
            </div>
          </Display.CenterContentContainer>
        </Display.BottomContentContainer>
      </Display.CenterContentContainer>
    )
  }
}

export default PersonalReferenceAdd
