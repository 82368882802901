import moment from 'moment'

// round viewing time to next 15 minute increment e.g. 9:15 -> 9:30, 9:55 -> 10:00
// accepts a time string
// returns Moment object
export const roundViewingTime = (timeString) => {
  const time = moment(timeString)

  // NOTE: There are algorithmic ways to do this but for clarity and to make
  // changes easier in the future, elected for conditional approach.
  if (time.minute() === 0) {
    return time
  }

  if (time.minute() <= 15) {
    return time.minute(15).second(0)
  }

  if (time.minute() <= 30) {
    return time.minute(30).second(0)
  }

  if (time.minute() <= 45) {
    return time.minute(45).second(0)
  }

  if (time.minute() > 45) {
    return time
      .hour(time.hour() + 1)
      .minute(0)
      .second(0)
  }

  // if no condition met
  return time
}
