import React from 'react'

import $ from 'jquery'
import moment from 'moment'
import DateTime from 'react-datetime'

import { ErrorBox, getCurrentValidationStatus } from 'app/components/forms/util'
import * as helpers from 'app/sm/helpers'

import 'react-datetime/css/react-datetime.css'
import 'app/components/forms/base_time_picker/style.scss'

class BaseInputTime extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false,
    }
  }
  componentDidMount() {
    this.simulateOnBlurEvent()
  }
  componentWillUnmount() {
    $('.app-content').unbind('click')
  }

  onHandleBlur = () => {
    const { onBlur = () => {} } = this.props
    this.setState({ isActive: false })
    onBlur()
  }

  onHandleFocus = () => {
    const { onFocus = () => {} } = this.props
    this.setState({ isActive: true })
    onFocus()
  }

  handleChange = (date) => {
    const value = moment(date).format(helpers.timeFormat)
    let error = value ? this.props.validate(value) : ''
    this.props.onChange({ value, error })
  }

  simulateOnBlurEvent = () => {
    $('.app-content').on('click', (e) => {
      if (
        !(
          $(e.target).hasClass('rdtBtn') ||
          $(e.target).hasClass('rdtCount') ||
          $(e.target).hasClass('rdtCounterSeparator')
        )
      ) {
        if (this.state.isActive) {
          this.onHandleBlur()
        }
      }
    })
  }

  render() {
    const {
      label,
      value,
      error,
      id,
      containerClassName,
      componentClass,
      viewMode,
      placeholder,
      defaultValue = '',
      isScheduleViewing,
      fiveMinuteIntervals = false,
    } = this.props
    const timeIncrements = {
      minutes: {
        step: 5,
      },
    }
    const fiveMinutesTimeConstraint =
      isScheduleViewing || fiveMinuteIntervals ? timeIncrements : {}

    const { isActive } = this.state
    const validationClass = getCurrentValidationStatus(value, error)
    const defaultPlaceHolder = moment().format(helpers.timeFormat)
    return (
      <div className={`${componentClass}`}>
        <div className={`standard-form-components mb10 ${containerClassName}`}>
          <div className="input-box standard-input-box mb5">
            <div className="input-date pos-initial pt0 mb0">
              <div className="input-time">
                <DateTime
                  className={`input-box mb0 pt0`}
                  inputProps={{
                    className: `standard-inputbox ${validationClass}`,
                    placeholder: `${
                      placeholder ? placeholder : defaultPlaceHolder
                    }`,
                    readOnly: true,
                    id,
                  }}
                  onChange={this.handleChange}
                  onBlur={this.onHandleBlur}
                  onFocus={this.onHandleFocus}
                  value={value}
                  defaultValue={defaultValue}
                  viewMode={viewMode}
                  dateFormat={false}
                  timeFormat={helpers.timeFormat}
                  open={isActive}
                  closeOnSelect={false}
                  disableOnClickOutside={false}
                  timeConstraints={fiveMinutesTimeConstraint}
                />
              </div>
              <label className="input-label">{label}</label>
            </div>
          </div>
          <ErrorBox error={error} showError={!!error} />
        </div>
      </div>
    )
  }
}

export default BaseInputTime
