import { connect } from 'react-redux'

import {
  createNote,
  fetchAllNotesForPropertyAndViewing,
  fetchNotes,
} from 'app/agency/agency_action'
import { fetchTeamManagerList } from 'app/bond_cover/agency/agency_actions'
import AddNoteModalContainer from 'app/sm/inspections/components/add_notes_modal_container'

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
})

const mapDispatchToProps = (dispatch) => ({
  fetchNotes: (ownerGUID, ownerType) =>
    dispatch(fetchNotes(ownerGUID, ownerType)),
  createNote: (payload, ownerGUID, ownerType, files) =>
    dispatch(createNote(payload, ownerGUID, ownerType, files)),
  fetchAllNotesForPropertyAndViewing: (propertyId, viewingId) =>
    dispatch(fetchAllNotesForPropertyAndViewing(propertyId, viewingId)),
  fetchTeamManagerList: (teamGUID) => dispatch(fetchTeamManagerList(teamGUID)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddNoteModalContainer)
