export const SET_BACK_URL = 'SET_BACK_URL'
export const CLOSE_SIDE_BAR = 'CLOSE_SIDE_BAR'
export const RESET_SIDE_BAR = 'RESET_SIDE_BAR'
export const SET_FORWARD_URL = 'SET_FORWARD_URL'
export const ADD_RENTAL_REPUTATION_BACK_URL = 'ADD_RENTAL_REPUTATION_BACK_URL'
export const DELETE_LAST_RENTAL_BACK_URL = 'DELETE_LAST_RENTAL_BACK_URL'
export const CLEAR_RENTAL_BACK_URL = 'CLEAR_RENTAL_BACK_URL'

export const setBackUrl = (backUrl) => ({ type: SET_BACK_URL, backUrl })

export const closeSideBar = () => ({ type: CLOSE_SIDE_BAR, close: true })

export const resetSideBar = () => ({ type: RESET_SIDE_BAR, close: false })

export const addRentalReputationBackUrl = (rentalBackUrl) => ({
  type: ADD_RENTAL_REPUTATION_BACK_URL,
  rentalBackUrl,
})

export const deleteLastRentalBackUrl = () => ({
  type: DELETE_LAST_RENTAL_BACK_URL,
})

export const clearRentalBackUrl = () => ({ type: CLEAR_RENTAL_BACK_URL })
