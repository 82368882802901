import React from 'react'

import humanImg01 from 'app/assets/icons/apply_anywhere/verified-human01.png'
import humanImg02 from 'app/assets/icons/apply_anywhere/verified-human02.png'
import humanImg04 from 'app/assets/icons/apply_anywhere/verified-human04.png'
import {
  Button,
  ImgBox,
  Text,
  Wrapper,
} from 'app/match/applicationReportPDF/components/Banner/styles'

const Banner = ({ onClick }) => {
  return (
    <Wrapper>
      <ImgBox>
        <img className="human01 human-img" src={humanImg01} alt="human01" />
        <img className="human02 human-img" src={humanImg02} alt="human02" />
        <img className="human03 human-img" src={humanImg04} alt="human03" />
      </ImgBox>
      <Text>Want more verified tenant applications?</Text>
      <Button onClick={onClick}>Learn More</Button>
    </Wrapper>
  )
}

export default Banner
