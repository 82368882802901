import React from 'react'

import humanIcon02 from 'app/assets/icons/human02.jpg'
import humanIcon03 from 'app/assets/icons/human03.jpg'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      titleReadOnly: true,
    }
  }

  moveCaretAtEnd(e) {
    var temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }

  toggleTitleReadOnly() {
    let { titleReadOnly } = this.state
    this.setState({ titleReadOnly: !titleReadOnly })
    this.refs.title.focus()
  }

  render() {
    let { titleReadOnly } = this.state

    return (
      <div className="sm-chat-header">
        <div className="options">
          <a href="/sm/chat">
            <i className="icon-back"></i>
          </a>
          <span className="spacer">/</span>
          <a>
            <div className="avatar-group xs-hide">
              <div
                className="avatar avatar-xsmall"
                style={{ backgroundImage: `url(${humanIcon02})` }}
              ></div>
              <div
                className="avatar avatar-xsmall"
                style={{ backgroundImage: `url(${humanIcon03})` }}
              ></div>
            </div>
          </a>
        </div>
        <div className={`title ${titleReadOnly ? '' : 'edit'}`}>
          <input
            ref="title"
            type="text"
            readOnly={titleReadOnly}
            defaultValue="We need the lease documents"
            onFocus={this.moveCaretAtEnd}
          />
          <i
            className="icon-edit2"
            onClick={() => this.toggleTitleReadOnly()}
            title="Edit Subject"
          ></i>
        </div>
        <div className="options">
          <a>
            <i className="icon-star-outline"></i>
          </a>
          <a>
            <i className="icon-person-add"></i>
          </a>
          <a>
            <i className="icon-settings"></i>
          </a>
        </div>
      </div>
    )
  }
}

export default Header
