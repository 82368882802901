import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import questionIcon from 'app/assets/icons/question-icon.svg'
import { Alert, Box, Flex } from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as helpers from 'app/sm/helpers'

interface IBallotsRunModalProps {
  isEligible: boolean
  householdApplications: number
  toggleModal: () => void
  successAction: () => void
  applicationReserveBallotsSize: number
  applicationSuccessfulBallotsSize: number
  loading: boolean
  error?: string
}

export const BallotsRunModal: React.FC<IBallotsRunModalProps> = ({
  isEligible,
  householdApplications,
  toggleModal,
  successAction,
  applicationReserveBallotsSize,
  applicationSuccessfulBallotsSize,
  loading,
  error = '',
}) => {
  return (
    <GenericModal
      onDismiss={() => toggleModal()}
      title={'Run Application Ballot'}
      modalFooter={
        <GenericModalFooter
          primaryBtnConfig={{
            title: 'Run Ballot',
            onClick: successAction,
            props: {
              loading: !!loading,
            },
          }}
          secondaryBtnConfig={{
            title: 'Cancel',
            onClick: () => toggleModal(),
          }}
          secondaryBtn={undefined}
          primaryBtn={!isEligible ? <></> : undefined}
        />
      }
    >
      {error && (
        <Alert
          variant="warningWithBg"
          mt={theme.space[3] + 'px'}
          actionIconBtnConfig={undefined}
        >
          <Box fontSize={`${theme.fontSizes.pRegular14}`}>{error}</Box>
        </Alert>
      )}
      <p>
        The random ballot will run on {householdApplications} completed
        household applications and generate:
        <ul>
          <li>{applicationSuccessfulBallotsSize} selected</li>
          <li>{applicationReserveBallotsSize} reserved</li>
        </ul>
        No notifications will be sent.
      </p>
    </GenericModal>
  )
}

interface IBallotsRunRecorderProps {
  ranAt: string
  ranBy: { firstName: string; lastName: string }
  id: string
  ballotRanRecord: {
    errorCode: string
    status: string
  }
}

export const BallotsRunRecorder: React.FC<IBallotsRunRecorderProps> = ({
  ballotRanRecord,
  ranAt,
  ranBy,
  id,
}) => {
  const tooltipId = 'ballots_run_recorder' + id
  const { firstName, lastName } = ranBy || {
    firstName: '',
    lastName: '',
  }

  const { errorCode = '', status = '' } = ballotRanRecord || {}

  const _renderBallotStatus = () => {
    switch (status) {
      case 'Succeeded':
        return 'Successful'

      case 'Failed':
        return `Error: ${translateErrorCodeToMessage(errorCode)}`

      default:
        return `Job status unknown`
    }
  }

  return (
    <Box>
      <Flex alignItems="center">
        <Box mr={2}>
          {' '}
          Ballot run{' '}
          {moment(ranAt).format(helpers.pdfStandardDisplayTimeFormat)}{' '}
        </Box>
        <img
          data-tooltip-content=""
          data-tooltip-id={tooltipId}
          src={questionIcon}
          alt={'icon'}
        />
      </Flex>
      <ReactTooltip id={tooltipId} place="bottom" className="tool-tip-style">
        <Box>
          Run by: {firstName} {lastName}
        </Box>
        <Box>Status: {_renderBallotStatus()}</Box>
      </ReactTooltip>
    </Box>
  )
}
