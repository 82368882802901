import styled from 'styled-components'

import { Box, Modal } from 'app/components/design-system-components'
import { MailToLink } from 'app/components/design-system-components/links'
import { TelLink } from 'app/components/display/contact_links'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as textHelpers from 'app/utils/text/helpers'

const StyledUL = styled.ul`
  padding: ${theme.space[3]}px ${theme.space[5]}px !important;

  li {
    display: list-item !important;
  }
`

interface IOtherTeamsWithSameDomainIDInfo {
  agencyName: ''
  contactEmail: ''
  officeNumber: ''
  externalAgencyID: ''
}

interface IActivationConfirmationModal {
  toggleActivationConfirmationModal: Function
  continueWithActivationFn: void
  otherTeamsWithSameDomainIDInfo: IOtherTeamsWithSameDomainIDInfo[]
  isAdminForCurrentAndOtherTeams?: boolean
  continueLabel?: string
  secondaryAction: Function
}

const ActivationConfirmationModal = ({
  toggleActivationConfirmationModal,
  continueWithActivationFn,
  otherTeamsWithSameDomainIDInfo,
  isAdminForCurrentAndOtherTeams = false,
  continueLabel = 'Activate',
  secondaryAction = toggleActivationConfirmationModal,
}: IActivationConfirmationModal) => {
  const modalBody = (
    <Box>
      This Domain Agency ID is already in use by:
      <StyledUL>
        {otherTeamsWithSameDomainIDInfo &&
          otherTeamsWithSameDomainIDInfo.map((info) => {
            return (
              <li>
                {`${info?.agencyName} (${info?.externalAgencyID}) `}
                <MailToLink email={info?.contactEmail}>
                  {info?.contactEmail}
                </MailToLink>{' '}
                <TelLink phone={info?.officeNumber}>
                  {info?.officeNumber}
                </TelLink>
              </li>
            )
          })}
      </StyledUL>
      {isAdminForCurrentAndOtherTeams
        ? textHelpers.ADMIN_ACTIVE_DOMAIN_MODAL_TEXT
        : textHelpers.NON_ADMIN_ACTIVE_DOMAIN_MODAL_TEXT}
    </Box>
  )
  return (
    <Modal
      modalHeading="Agency ID Already In Use"
      modalBody={modalBody}
      primaryLabel={continueLabel}
      secondaryLabel="Cancel"
      showPrimaryButton={isAdminForCurrentAndOtherTeams}
      primaryAction={continueWithActivationFn}
      toggleModal={toggleActivationConfirmationModal}
      secondaryAction={secondaryAction}
      modalFooter={undefined}
    />
  )
}

export default ActivationConfirmationModal
