import React from 'react'

import moment from 'moment'

import * as Display from 'app/components/display/display'
import * as Text from 'app/components/display/text/text_component'
import {
  disclosureVersionedQuestions,
  getDisclosureMissingVersion,
} from 'app/pages/teams/onlist/components/Disclosures/questions'
import * as helpers from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as onlistHelpers from 'app/utils/onlist/helpers'
import { DISCLOSURE_QUESTIONS_DISPLAY } from 'app/utils/text/helpers'

const convertToReadableDate = (date) =>
  moment(date).format(dateTimeHelpers.DATE_DAY_SHORT_STRING_MONTH_YEAR)

class DisclosureQuestionsDisplay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disclosureContainerOpened: false,
      showDisclosures: false,
    }
  }
  componentDidMount() {
    const { openDisclosuresModal = false } = this.props
    if (openDisclosuresModal) {
      this.setState({
        showDisclosures: true,
      })
    }
  }

  renderAttachments = (disclosureAttachments) => {
    let attachmentListedEl = []
    disclosureAttachments.forEach((attachment, index) =>
      attachmentListedEl.push(
        <li>
          <a target="_blank" href={attachment.url} rel="noreferrer">
            {`Disclosures ${index + 1}`}
          </a>
        </li>,
      ),
    )

    return <ul>{attachmentListedEl}</ul>
  }

  render() {
    const {
      propertyDisclosure = {},
      hasPropertyDisclosure = false,
      showDisclosureLink = true,
    } = this.props
    const { showDisclosures } = this.state
    const {
      compliesWithMinimumStandards = '',
      isBeingSold = '',
      dateOfDrugUsage = '',
      dateOfElectricityCheck = '',
      dateOfGasCheck = '',
      dateOfHomicide = '',
      dateOfPoolCheck = '',
      dateOfRepairNotice = '',
      embeddedNetworkAbn = '',
      embeddedNetworkName = '',
      embeddedNetworkPhone = '',
      embeddedNetworkPricing = '',
      hasAsbestos = '',
      hasEmbeddedNetwork = '',
      hasHomicide = '',
      isAffectedWithRelevantAuthorities = '',
      isRegisteredPlace = '',
      embeddedNetworkWebsite = '',
      disclosureAttachments,
      version = getDisclosureMissingVersion(),
    } = propertyDisclosure || {}

    const showAttachments =
      disclosureAttachments && disclosureAttachments.length > 0

    const {
      consumer_renting_guide,
      property_disclosures,
      statement_of_information,
    } = DISCLOSURE_QUESTIONS_DISPLAY

    return (
      <div>
        Please review:{' '}
        <a
          href={helpers.CONSUMER_GUIDE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {consumer_renting_guide}
        </a>
        {showDisclosureLink && (
          <span
            onClick={() => {
              this.setState({ showDisclosures: !showDisclosures })
            }}
            className="blue-link-style htu"
          >
            , {property_disclosures}
          </span>
        )}{' '}
        and the{' '}
        <a
          href="https://help.snug.com/hc/en-us/articles/360000771916"
          target="_blank"
          rel="noopener noreferrer"
        >
          {statement_of_information}
        </a>
        {showDisclosures && (
          <Display.Modal
            toggleModal={() =>
              this.setState({ showDisclosures: !showDisclosures })
            }
            title="Disclosure Information"
            hideButtonSection={true}
          >
            {hasPropertyDisclosure ? (
              <div>
                <Text.Strong text="Please click to download the disclosures for this property:" />
                {showAttachments &&
                  this.renderAttachments(disclosureAttachments)}
                {!showAttachments && (
                  <>
                    {hasEmbeddedNetwork && (
                      <Text.Strong text="Embedded Network" />
                    )}
                    {hasEmbeddedNetwork && (
                      <ul>
                        {disclosureVersionedQuestions.embeddedNetworkName.versions.includes(
                          version,
                        ) && (
                          <li>
                            {
                              disclosureVersionedQuestions.embeddedNetworkName
                                .text
                            }
                            {embeddedNetworkName}
                          </li>
                        )}
                        {disclosureVersionedQuestions.embeddedNetworkAbn.versions.includes(
                          version,
                        ) && (
                          <li>
                            {
                              disclosureVersionedQuestions.embeddedNetworkAbn
                                .text
                            }
                            {embeddedNetworkAbn}
                          </li>
                        )}
                        {disclosureVersionedQuestions.embeddedNetworkPhone.versions.includes(
                          version,
                        ) && (
                          <li>
                            {
                              disclosureVersionedQuestions.embeddedNetworkPhone
                                .text
                            }{' '}
                            {embeddedNetworkPhone}
                          </li>
                        )}
                        {disclosureVersionedQuestions.embeddedNetworkPricing.versions.includes(
                          version,
                        ) && (
                          <li>
                            {
                              disclosureVersionedQuestions
                                .embeddedNetworkPricing.text
                            }{' '}
                            {embeddedNetworkPricing}
                          </li>
                        )}
                        {disclosureVersionedQuestions.embeddedNetworkWebsite.versions.includes(
                          version,
                        ) && (
                          <li>
                            {
                              disclosureVersionedQuestions
                                .embeddedNetworkWebsite.text
                            }{' '}
                            {embeddedNetworkWebsite}
                          </li>
                        )}
                      </ul>
                    )}
                    <ul>
                      {disclosureVersionedQuestions.isBeingSold.versions.includes(
                        version,
                      ) && (
                        <li>
                          {disclosureVersionedQuestions.isBeingSold.text}
                          {onlistHelpers.convertBoolToYesOrNo(isBeingSold)}
                        </li>
                      )}
                      {disclosureVersionedQuestions.hasHomicide.versions.includes(
                        version,
                      ) && (
                        <li>
                          {disclosureVersionedQuestions.hasHomicide.text}
                          {onlistHelpers.convertBoolToYesOrNo(hasHomicide)}
                        </li>
                      )}
                      {hasHomicide &&
                        disclosureVersionedQuestions.dateOfHomicide.versions.includes(
                          version,
                        ) && (
                          <li>
                            {disclosureVersionedQuestions.dateOfHomicide.text}
                            {convertToReadableDate(dateOfHomicide)}
                          </li>
                        )}
                      {disclosureVersionedQuestions.isRegisteredPlace.versions.includes(
                        version,
                      ) && (
                        <li>
                          {disclosureVersionedQuestions.isRegisteredPlace.text}
                          {onlistHelpers.convertBoolToYesOrNo(
                            isRegisteredPlace,
                          )}
                        </li>
                      )}
                      {dateOfDrugUsage &&
                        disclosureVersionedQuestions.dateOfDrugUsage.versions.includes(
                          version,
                        ) && (
                          <li>
                            {disclosureVersionedQuestions.dateOfDrugUsage.text}
                            {convertToReadableDate(dateOfDrugUsage)}
                          </li>
                        )}
                      {dateOfRepairNotice &&
                        disclosureVersionedQuestions.dateOfRepairNotice.versions.includes(
                          version,
                        ) && (
                          <li>
                            {
                              disclosureVersionedQuestions.dateOfRepairNotice
                                .text
                            }
                            {convertToReadableDate(dateOfRepairNotice)}
                          </li>
                        )}
                      {disclosureVersionedQuestions.compliesWithMinimumStandards.versions.includes(
                        version,
                      ) && (
                        <li>
                          {
                            disclosureVersionedQuestions
                              .compliesWithMinimumStandards.text
                          }
                          {onlistHelpers.convertBoolToYesOrNo(
                            compliesWithMinimumStandards,
                          )}
                        </li>
                      )}
                      {dateOfGasCheck &&
                        disclosureVersionedQuestions.dateOfGasCheck.versions.includes(
                          version,
                        ) && (
                          <li>
                            {disclosureVersionedQuestions.dateOfGasCheck.text}
                            {convertToReadableDate(dateOfGasCheck)}
                          </li>
                        )}
                      {dateOfElectricityCheck &&
                        disclosureVersionedQuestions.dateOfElectricityCheck.versions.includes(
                          version,
                        ) && (
                          <li>
                            {
                              disclosureVersionedQuestions
                                .dateOfElectricityCheck.text
                            }
                            {convertToReadableDate(dateOfElectricityCheck)}
                          </li>
                        )}
                      {dateOfPoolCheck &&
                        disclosureVersionedQuestions.dateOfPoolCheck.versions.includes(
                          version,
                        ) && (
                          <li>
                            {disclosureVersionedQuestions.dateOfPoolCheck.text}
                            {convertToReadableDate(dateOfPoolCheck)}
                          </li>
                        )}
                      {disclosureVersionedQuestions.hasAsbestos.versions.includes(
                        version,
                      ) && (
                        <li>
                          {disclosureVersionedQuestions.hasAsbestos.text}
                          {onlistHelpers.convertBoolToYesOrNo(hasAsbestos)}
                        </li>
                      )}
                      {disclosureVersionedQuestions.isAffectedWithRelevantAuthorities.versions.includes(
                        version,
                      ) && (
                        <li>
                          {
                            disclosureVersionedQuestions
                              .isAffectedWithRelevantAuthorities.text
                          }
                          {onlistHelpers.convertBoolToYesOrNo(
                            isAffectedWithRelevantAuthorities,
                          )}
                        </li>
                      )}
                    </ul>
                  </>
                )}
              </div>
            ) : (
              <div>
                <Text.Strong text="Property Disclosures" />
                <Text.Normal text="There are no disclosures provided for this property" />
              </div>
            )}
          </Display.Modal>
        )}
      </div>
    )
  }
}
export default DisclosureQuestionsDisplay
