import React from 'react'

const avatarStyleSizes = {
  xssmall: 'avatar-xsmall',
  msmall: 'avatar-msmall',
  small: 'avatar-small',
  big: 'avatar-big',
  standard: 'avatar-standard',
  default: '',
}

const Avatar = ({
  avatar,
  avatarText,
  size = 'default',
  componentClassName = '',
  avatarTextClassName = '',
}) => {
  const sizeStyle = avatarStyleSizes[size]
  return (
    <div className={componentClassName}>
      {avatar ? (
        <div
          className={`mr20 avatar great relative ${sizeStyle}`}
          style={{ backgroundImage: `url(${avatar})` }}
        />
      ) : (
        <div className={`mr20 avatar-container ${avatarTextClassName}`}>
          <div className={`avatar application-avatar ${sizeStyle}`}>
            <span className="default-font-family">{avatarText}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default Avatar
