import React from 'react'

import moment from 'moment'

import removeIcon from 'app/assets/icons/cross-sign.png'
import { Flex } from 'app/components/design-system-components'
import { getWeeklyRentDisplay, NO_IMAGE_PLACEHOLDER_URL } from 'app/sm/helpers'

export const SearchedPropertyResults = ({
  propertySearchResults,
  onAddButtonClick,
  hideResults,
  isPropertyAttached,
}) => {
  const showResults =
    !hideResults && propertySearchResults && propertySearchResults.data
  return (
    <div>
      {showResults &&
        propertySearchResults.data
          .filter((propertyData) => propertyData.property.offers.length > 0)
          .map((propertyData, index) => {
            return (
              <PropertyItem
                key={index}
                propertyItem={propertyData.property}
                weeklyRent={
                  propertyData.property &&
                  propertyData.property.offers &&
                  propertyData.property.offers.length > 0 &&
                  propertyData.property.offers[0].weeklyRent
                }
                availableFrom={
                  propertyData.property &&
                  propertyData.property.offers &&
                  propertyData.property.offers[0].availableFrom
                }
                onAddButtonClick={onAddButtonClick}
                showRemoveButton={false}
                showAddButton={true}
                isBtnDisplayToggled={true}
                isPropertyAttached={isPropertyAttached}
              />
            )
          })}
    </div>
  )
}

export const PropertyItem = ({
  propertyItem,
  weeklyRent,
  availableFrom,
  onAddButtonClick,
  onRemoveButtonClick,
  src,
  showAddButton,
  showRemoveButton,
  isPropertyAttached,
}) => {
  const {
    guidID,
    images,
    address: { friendlyName },
    bathrooms,
    bedrooms,
    parking,
  } = propertyItem
  return (
    <div className="mb40">
      <div className="display-flex overview-property-info space-between">
        <div className="display-flex">
          <div
            className="team-image relative"
            style={{
              backgroundImage: `url("${
                images[0] ? images[0].URL : NO_IMAGE_PLACEHOLDER_URL
              }")`,
            }}
          ></div>
          <Flex flexDirection="column" justifyContent="center" pl={3}>
            <div className="font-size-18 mb10">
              <strong>{friendlyName}</strong>
            </div>
            <ul className="amenities-widget mb5">
              <li className="font-size-14 pr10">
                <i className="icon-bedroom"></i>
                <span>{bedrooms}</span>
              </li>
              <li className="font-size-14 pr10">
                <i className="icon-bathroom"></i>
                <span>{bathrooms}</span>
              </li>
              <li className="font-size-14 pr10">
                <i className="icon-cars"></i>
                <span>{parking}</span>
              </li>
            </ul>
            <div className="mb5 font-size-14">
              {getWeeklyRentDisplay(weeklyRent)}
            </div>
            <div className="font-size-14">
              Avail:{' '}
              {availableFrom
                ? moment(availableFrom).format('ddd DD MMM')
                : 'N/A'}
            </div>
          </Flex>
        </div>
        <div className="display-flex justify-content-flex-end align-items-center">
          {showAddButton && !isPropertyAttached(guidID) && (
            <div className="display-flex flex-direction-column">
              <AddPropertyButton
                onAddButtonClick={onAddButtonClick(
                  'existedInterestedProperties',
                )}
                guidID={guidID}
                text="Add to interested property"
              />
              <AddPropertyButton
                onAddButtonClick={onAddButtonClick('existedOwnedProperties')}
                guidID={guidID}
                text="Add to owned property"
              />
            </div>
          )}
          {showRemoveButton && (
            <RemovePropertyButton
              onRemoveButtonClick={onRemoveButtonClick}
              guidID={guidID}
              src={src}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const RemovePropertyButton = ({ onRemoveButtonClick, guidID, src }) => {
  return (
    <div className="removeIcon">
      <img
        src={removeIcon}
        onClick={() => onRemoveButtonClick(guidID)}
        alt="remove icon"
      ></img>
    </div>
  )
}

const AddPropertyButton = ({ onAddButtonClick, guidID, text }) => {
  return (
    <div className="linkStyle mb5" onClick={() => onAddButtonClick(guidID)}>
      {text}
    </div>
  )
}
