import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { validateMobile } from 'app/shared_components/validations'

export const formFields = {
  mobile: 'mobile',
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.mobile: {
      return {
        required: !val && 'Please enter your mobile number!',
        pattern: validateMobile(val)[0],
      }
    }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
