import React from 'react'

import styled from 'styled-components'

import { mediaQueries } from 'app/components/design-system-components/styles/variables'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledGalleryContainer = styled.div`
  ${mediaQueries.sm} {
    max-width: 150px;
    padding-top: ${theme.space[5]}px;
    padding-left: ${theme.space[5]}px;
  }
`

const StyledGalleryButton = styled.button`
  ${mediaQueries.sm} {
    i.left {
      font-size: ${theme.fontSizes.pSmall12}!important;
    }
    img {
      max-width: 75px;
    }
  }
`

//legacy - to be refactored
const ReferrerBackButton = ({ referrer, referrerBackUrl }) => {
  return (
    <StyledGalleryContainer className="gallery-back-link-container">
      <div className="gallery">
        <StyledGalleryButton
          onClick={() => (window.location.href = referrerBackUrl)}
          className="sm-gallery-button"
        >
          <i className="icon-arrow-left left" />
          <img width="142px" src={referrer?.logo} alt="logo" />
        </StyledGalleryButton>
      </div>
    </StyledGalleryContainer>
  )
}

export default ReferrerBackButton
