import React, { useEffect, useState } from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Box, Button, Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { updateVacateAndAvailabilityDates } from 'app/pages/teams/onlist/components/AvailabilityAndVacantDate/api'
import * as snugNotifier from 'app/services/snugNotifier'
import DateDropDown from 'app/sm/properties/components/excluded_times/partials/date_dropdown'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

const StyledTable = styled.table`
  td {
    padding-top: ${theme.space[2]}px;
    padding-bottom: ${theme.space[2]}px;
  }
`

const StyledDateDropdownContainer = styled(Flex)`
  align-items: center;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    button {
      margin-top: ${theme.space[3]}px;
    }
  }
`

const CANNOT_CHANGES_DATE_MSG =
  'Cannot change dates for manually added properties'

const AVAILABILITY_DATE = 'availableFrom'
const VACATE_DATE = 'vacateDate'
const FEED_RESET = 'useFeedAvailableFrom'
const AVAILABLE_DATE_RESET = 'vacateDateFollowAvailableFrom'

const dateKeysToTextMessage = {
  [AVAILABILITY_DATE]: 'Available From date updated',
  [VACATE_DATE]: 'Vacate date updated',
  [FEED_RESET]: 'Date successfully updated to feed value',
  [AVAILABLE_DATE_RESET]: 'Date reset to available date',
}

function AvailabilityAndVacantDate({
  offerGUID,
  offerInfo,
  onVacateDateUpdated,
}) {
  const [availableDate, setAvailableDate] = useState('')
  const [vacateDate, setVacateDate] = useState('')
  const [useFeedAvailableFrom, setUseFeedAvailableFrom] = useState(true)
  const [vacateDateFollowAvailableFrom, setVacateDateFollowAvailableFrom] =
    useState(true)
  const [isFeedOffer, setIsFeedOffer] = useState(false)

  useEffect(() => {
    const {
      isFeedOffer,
      useFeedAvailableFrom: initialUseFeedAvailableFrom,
      vacateDateFollowAvailableFrom: initialVacateDateFollowAvailableFrom,
      vacateDate: initialVacateDate,
      availableFrom: initialAvailableDate,
    } = offerInfo

    setAvailableDate(initialAvailableDate)
    setVacateDate(initialVacateDate)
    setUseFeedAvailableFrom(initialUseFeedAvailableFrom)
    setVacateDateFollowAvailableFrom(initialVacateDateFollowAvailableFrom)
    setIsFeedOffer(isFeedOffer)
  }, [offerInfo])

  const updateDates = (type, value) => {
    let payload = {
      [AVAILABILITY_DATE]: availableDate,
      [VACATE_DATE]: vacateDate,
      [FEED_RESET]: false,
      [AVAILABLE_DATE_RESET]: false,
    }
    payload = {
      ...payload,
      [type]: value,
    }
    if (type === FEED_RESET) {
      payload = {
        [FEED_RESET]: true,
      }
    }
    if (type === AVAILABLE_DATE_RESET) {
      payload = {
        [AVAILABLE_DATE_RESET]: true,
      }
    }
    updateVacateAndAvailabilityDates(offerGUID, payload)
      .then((data) => {
        snugNotifier.success(`${dateKeysToTextMessage[type]}`)
        const {
          availableFrom,
          vacateDate,
          useFeedAvailableFrom,
          vacateDateFollowAvailableFrom,
        } = data
        setAvailableDate(availableFrom)
        setVacateDate(vacateDate)
        setUseFeedAvailableFrom(useFeedAvailableFrom)
        setVacateDateFollowAvailableFrom(vacateDateFollowAvailableFrom)

        onVacateDateUpdated({
          availableFrom,
          vacateDate,
        })
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  const showResetToFeedLink = isFeedOffer && !useFeedAvailableFrom
  const showResetToAvailableDate = isFeedOffer && !vacateDateFollowAvailableFrom

  return (
    <Box mb={7}>
      <StyledTable>
        <tr>
          <td>
            <Box mr={4}>Availability Date:</Box>
          </td>
          <td>
            <StyledDateDropdownContainer>
              <DateDropDown
                date={availableDate}
                onDateChangedHandler={(date) => {
                  if (isFeedOffer) {
                    setAvailableDate(date)
                    updateDates(AVAILABILITY_DATE, date)
                    return
                  }
                  snugNotifier.error(CANNOT_CHANGES_DATE_MSG)
                }}
                width={theme.width[14] + 'px'}
                labelRenderer={(date) => {
                  return `${dateTimeHelpers.formattedUTCDisplayDate(date)}`
                }}
                disable={isFeedOffer}
              />
              {showResetToFeedLink && (
                <Button
                  variant="linkBlueWithoutWeight"
                  onClick={() => updateDates(FEED_RESET, true)}
                >
                  reset to feed
                </Button>
              )}
            </StyledDateDropdownContainer>
          </td>
        </tr>
        <tr>
          <td>
            <Flex>
              <Box mr={2}>Vacate Date:</Box>

              <i
                className="icon-help-outline fs16 gray-light-color ibm cursor-pointer"
                data-tooltip-content
                data-tooltip-id="viewingVacateNotice"
              />
              <ReactTooltip id="viewingVacateNotice">
                <span>Viewing entry notices will cease on the vacate date</span>
              </ReactTooltip>
            </Flex>
          </td>
          <td>
            <StyledDateDropdownContainer>
              <DateDropDown
                date={vacateDate}
                onDateChangedHandler={(date) => {
                  if (isFeedOffer) {
                    setVacateDate(date)
                    updateDates(VACATE_DATE, date)
                    return
                  }
                  snugNotifier.error(CANNOT_CHANGES_DATE_MSG)
                }}
                width={theme.width[14] + 'px'}
                labelRenderer={(date) => {
                  return `${dateTimeHelpers.formattedUTCDisplayDate(date)}`
                }}
                disable={isFeedOffer}
              />
              {showResetToAvailableDate && (
                <Button
                  variant="linkBlueWithoutWeight"
                  onClick={() => updateDates(AVAILABLE_DATE_RESET, true)}
                >
                  reset to available date
                </Button>
              )}
            </StyledDateDropdownContainer>
          </td>
        </tr>
      </StyledTable>
    </Box>
  )
}

export default AvailabilityAndVacantDate
