import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import CreationStep from 'app/pages/SteamLineSeftServiceTeam/creation-step/component'
import { HeadingText } from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_container'
import type { RegisteringTeamStatus } from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_type'
import { ErrorMessage } from 'app/shared_components/helpers'

const RegisteringTeamContainer = styled(Box)``

const RegisteringTeam = ({
  registeringStatus,
  registeringError,
}: {
  registeringStatus: RegisteringTeamStatus
  registeringError: string
}) => {
  return (
    <RegisteringTeamContainer>
      <HeadingText>Registering Team</HeadingText>
      {registeringError ? (
        <ErrorMessage error={registeringError} />
      ) : (
        <CreationStep registeringStatus={registeringStatus} />
      )}
    </RegisteringTeamContainer>
  )
}

export default RegisteringTeam
