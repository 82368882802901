import React from 'react'

import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import Card from 'app/components/design-system-components/Card'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { urlIds, urlTo } from 'app/sm/helpers'
import WithPaidPlanOnlyBadge from 'app/sm/team_viewing_preference/setup/card_components/paidplanonlyBadge'
import StepsHeading from 'app/sm/team_viewing_preference/setup/card_components/stepHeading'

import { StyledSetup } from '..'

const StyledUL = styled.ul`
  padding-left: 16px;
  padding-top: 4px;
`

function SetupContent({ teamSlug }) {
  return (
    <StyledSetup>
      <Box mt={theme.space[5] + 'px'}>
        For Free Trial & paid plan customers, continue with these steps to
        streamline your leasing journey (takes 5-10 minutes).
      </Box>
      <Box mt={theme.space[7] + 'px'} mb={theme.space[7] + 'px'}>
        <Card
          headerTitle={
            <StepsHeading text="Step 4. Turn on applicant screening" />
          }
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          isCollapsible={true}
          internalComponent={
            <>
              Activate the Equifax National Tenancy Database (NTD) or TICA{' '}
              <a
                href={urlTo(urlIds.teams.settings.screening, { teamSlug })}
                rel="noreferrer"
              >
                here
              </a>
              .
            </>
          }
        />
      </Box>
      <Box mt={theme.space[7] + 'px'}>
        <Card
          headerTitle={
            <StepsHeading
              text="Step 5. Connect Utility Provider"
              videoURL="https://youtu.be/MryJp_hVPKo"
            />
          }
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          isCollapsible={true}
          internalComponent={
            <>
              {' '}
              Please send an email to{' '}
              <a href="mailto:help@snug.com?subject=Connect Utility Provider">
                help@snug.com
              </a>{' '}
              with your provider and team or individual referral ID.
            </>
          }
        />
      </Box>
      <Box mt={theme.space[7] + 'px'}>
        <Card
          headerTitle={
            <StepsHeading
              text="Step 6: Connect Property Uploader"
              videoURL="https://youtu.be/pQRoeRjvNUA"
            />
          }
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          isCollapsible={true}
          internalComponent={
            <>
              We start with a web feed to see your listings in Snug, then we
              connect your property uploader to automatically add listings
              and/or viewings (runs every 15mins).{' '}
              <a
                href="https://help.snug.com/hc/en-us/articles/360001231935-Setting-up-your-Property-Uploader?utm_campaign=PM+Onboarding+Checklist&utm_medium=app&utm_source=setup_page"
                target="_blank"
                rel="noreferrer"
              >
                Learn more here.
              </a>
            </>
          }
        />
      </Box>
      <Box mt={theme.space[7] + 'px'}>
        <Card
          headerTitle={<StepsHeading text="Step 7. Get Snug on your phone" />}
          isCollapsible={true}
          initialCollapsedState={true}
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          internalComponent={
            <Box>
              Visit Snug.com on your mobile and save your Snug viewings as a
              bookmark to create an app icon. Practice a Check-in and see how we
              send the SMS and Email apply link to your viewing attendees.{' '}
              <a
                href="https://help.snug.com/hc/en-us/articles/360001232556-Adding-Snug-icon-to-your-mobile-device-home-screen?utm_campaign=PM+Onboarding+Checklist&utm_medium=app&utm_source=setup_page"
                target="_blank"
                rel="noreferrer"
              >
                Learn more here.
              </a>
            </Box>
          }
        />
      </Box>
      <Box mt={theme.space[7] + 'px'}>
        <Card
          headerTitle={
            <StepsHeading text="Step 8. Book training for your Team" />
          }
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          isCollapsible={true}
          internalComponent={
            <>
              It's best to complete the Set Up Call (get properties and
              enquiries flowing) before the training but feel free to book
              training in advance (you can reschedule at anytime). Select your
              preferred date/time for a live video and screen-share Webinar
              Group Training:
              <StyledUL>
                <li>
                  <a
                    href="https://us02web.zoom.us/webinar/register/WN_9MCsqeXHQ-aNf2ONfs2HvA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Tuesday 11am
                  </a>{' '}
                  (Sydney Time)
                </li>
                <li>
                  <a
                    href="https://us02web.zoom.us/webinar/register/WN_OdimWjmLT-yKHe4k5qm2OQ"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Thursday 2pm
                  </a>{' '}
                  (Sydney Time)
                </li>
              </StyledUL>
              <Box mt={theme.space[7] + 'px'}>
                For large teams with 350+ portfolio or 7+ team members,{' '}
                <a
                  href="https://snugcom.pipedrive.com/scheduler/L4JvpSz/snug-training"
                  target="_blank"
                  rel="noreferrer"
                >
                  book a custom training here.
                </a>
              </Box>
              <Box mt={theme.space[7] + 'px'}>
                Switching from an Inspection Booking System? Let's help you in 3
                easy stages to ensure a smooth transition for your team and
                business{' '}
                <a
                  href="https://help.snug.com/hc/en-us/articles/360001367715-Transitioning-your-Inspection-Bookings-to-Snug-in-three-easy-steps"
                  target="_blank"
                  rel="noreferrer"
                >
                  here.
                </a>
              </Box>
            </>
          }
        />
      </Box>
      <Box mt={theme.space[7] + 'px'}>
        <Card
          headerTitle={
            <StepsHeading text="Step 9. Turn on the enquiry Auto Responder" />
          }
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          isCollapsible={true}
          internalComponent={
            <>
              <Box>
                Build your database and save time: forward portal enquiries to
                your Snug inbox and turn ON the Auto Responder. Note: this
                requires Admin login details to your Property Uploader Listing
                Agent Profiles, REA and Domain Admin.{' '}
                <a
                  href="https://help.snug.com/hc/en-us/articles/360002031376-Automated-replies-to-portal-enquiries?utm_campaign=PM+Onboarding+Checklist&utm_medium=app&utm_source=setup_page"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more here.
                </a>
              </Box>
              <Box mt={theme.space[7] + 'px'}>
                We'll help with setting up your teams autoresponder, property
                uploader, utility partner and answer any questions about
                settings.
              </Box>
              <Box mt={theme.space[7] + 'px'}>
                Have questions or need help with email forwarding?{' '}
                <a
                  href="https://snugcom.pipedrive.com/scheduler/37MoEtj/assistance-call"
                  target="_blank"
                  rel="noreferrer"
                >
                  Book a 15 min Set Up call
                </a>
              </Box>
            </>
          }
        />
      </Box>
      <Box mt={theme.space[7] + 'px'} mb={theme.space[7] + 'px'}>
        <Card
          headerTitle={
            <WithPaidPlanOnlyBadge
              comp={
                <StepsHeading text="Step 10. Settings to suit your business" />
              }
            />
          }
          isCollapsible={true}
          initialCollapsedState={true}
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          internalComponent={
            <>
              <Box>
                Applications {'>'} set your preferences to manage the number and
                quality of applications eg. require 100 points of ID or enable
                Pre-Apply.{' '}
                <a
                  href="https://help.snug.com/hc/en-us/articles/360002732595-Pre-Apply-on-Snug-before-viewing-the-property?utm_campaign=PM+Onboarding+Checklist&utm_medium=app&utm_source=setup_page"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more here.
                </a>
              </Box>
              <Box mt={theme.space[7]}>
                {' '}
                Viewings {'>'} Snug imports your Open home viewings from your
                uploader. Use Snug Settings for managing Ideal Week, Viewing
                Slots/Caps, QR Code for COVID Check-in, Blocked dates and
                Excluded properties as well as Entry Notices.{' '}
                <a
                  href="https://help.snug.com/hc/en-us/articles/360001289756-Viewings-management-on-Snug?utm_campaign=PM+Onboarding+Checklist&utm_medium=app&utm_source=setup_page"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more here.
                </a>
              </Box>
            </>
          }
        />
      </Box>
    </StyledSetup>
  )
}

export default SetupContent
