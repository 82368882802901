import React from 'react'

import RatingForm from 'app/bond_cover/renters/history/bc_rating_form'
import * as Display from 'app/components/display/display'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import * as snugLogger from 'app/services/snugLogger'
import { ErrorMessage } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import StatusBar from 'app/shared_components/status_bar'
import { parseAnswer, urlTo } from 'app/sm/helpers'

class RatingFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: false,
      permissionClicked: false,
      declineClicked: false,
      comment: '',
      disabledRating: false,
      learnMoreFromSnug: false,
    }
  }

  componentDidMount() {
    const {
      isHistoryRating,
      fetchRentalHistoryRatingCategories,
      fetchBondCoverReview,
    } = this.props
    this.setState({
      disabled: false,
      permissionClicked: false,
      declineClicked: false,
    })

    fetchRentalHistoryRatingCategories() // We are using this both at bondCover and rentalHistory ratings
    if (isHistoryRating) {
      const {
        match: {
          params: { referenceId, secretGUID },
        },
      } = this.props
      this.props.setRentalHistorySecret(secretGUID)
      this.props.fetchRentalHistoryReview(referenceId)
    } else {
      let requestCode = this.getParameter('requestcode')
      fetchBondCoverReview(requestCode)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { tenantRating } = nextProps

    const comment = (
      tenantRating.find((T) => T.Code === 'overall') || { comment: undefined }
    ).comment
    if (comment !== undefined) {
      this.setState({ comment })
    }
  }

  onLearnMoreClicked = () => {
    this.setState({
      learnMoreFromSnug: !this.state.learnMoreFromSnug,
    })
  }

  getAnswerValue = (code, tenantRating, prop) => {
    const targetRating = tenantRating.find(
      (ratingAnswer) => ratingAnswer[prop] === code,
    )
    if (targetRating) return parseAnswer(targetRating)
  }

  getParameter(field) {
    let url = window.location.href
    field = field.replace(/[[\]]/g, '\\$&')
    let regex = new RegExp('[?&]' + field + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  getRating(code, tenantRating, prop) {
    for (var i = 0; i < tenantRating.length; i++) {
      if (tenantRating[i][prop] === code) {
        return tenantRating[i].rating
      }
    }
    return -1
  }

  decline = () => {
    const { isHistoryRating, RentalHistory } = this.props
    this.setState({ disabled: true, declineClicked: true })

    if (isHistoryRating) {
      const { guidID: referenceId } = RentalHistory
      this.props.decline(referenceId, {
        DeclineReason: 5 /* Other Reason */,
        Description: this.state.comment,
      })
      return
    }
    history.push(urlTo('join'))
  }

  proceed = () => {
    const { isHistoryRating, proceed, bondCoverRequest, RentalHistory } =
      this.props
    const { learnMoreFromSnug } = this.state
    if (learnMoreFromSnug) {
      snugLogger.warn('No contact exporter')
    }

    let { comment } = this.state
    let commentPost = { comment: comment }
    this.setState({ disabled: true, permissionClicked: true })

    if (isHistoryRating) {
      proceed(RentalHistory.guidID, commentPost)
      return
    }

    proceed(bondCoverRequest.guidID, commentPost)
  }

  update = (field) => {
    return (event) => this.setState({ [field]: event.target.value })
  }

  updateTenantRating = (ratingCode, answerValue, requestCode, questionType) => {
    const {
      isHistoryRating,
      createTenantRating,
      updateTenantRating,
      tenantRating,
    } = this.props
    if (this.state.disabledRating) return

    this.setState({ disabledRating: true })
    if (isHistoryRating) {
      const {
        match: {
          params: { referenceId },
        },
      } = this.props
      const shouldUpdate = !!tenantRating.find((T) => T.code === ratingCode)
      if (shouldUpdate) {
        updateTenantRating(referenceId, {
          ratingCode,
          comment: '',
          answerValue: answerValue,
          questionType: questionType,
        }).then(() => {
          this.setState({ disabledRating: false })
        })
      } else {
        createTenantRating(referenceId, {
          ratingCode: ratingCode,
          comment: '',
          answerValue: answerValue,
          questionType: questionType,
        }).then(() => {
          this.setState({ disabledRating: false })
        })
      }

      return
    }

    // This executes at bond cover, the above one is for RentalHistoryRating
    if (this.getRating(ratingCode, tenantRating, 'code') === -1) {
      createTenantRating(ratingCode, tenantRating, requestCode)
    } else {
      updateTenantRating(ratingCode, tenantRating, requestCode)
    }
  }

  render() {
    const {
      isHistoryRating,
      RentalHistory = {},
      ratingCategories,
      tenantRating,
      renter,
      error,
      currentLease,
      bondCoverRequest,
      message,
      addRentalLedger,
      removeRentalLedger,
      showRentalLedgerSpinner,
      match: {
        params: { secretGUID },
      },
      rentalHistoryReview,
    } = this.props

    const { ledger } = RentalHistory
    if (isHistoryRating && message) {
      return <div className="alert alert-success"> {message} </div>
    }

    let { address } = isHistoryRating ? RentalHistory : currentLease

    let { guidID } = isHistoryRating ? RentalHistory : bondCoverRequest

    const {
      disabled,
      permissionClicked,
      declineClicked,
      comment,
      disabledRating,
      learnMoreFromSnug,
    } = this.state

    return (
      <Display.CenterContentContainer componentClass="application-standard-shadow-box col-xs-12 summary-box-on-mobile">
        {!isHistoryRating && <StatusBar progress="2" />}

        {guidID && (
          <RatingForm
            isHistoryRating={isHistoryRating}
            address={address}
            tenantRating={tenantRating}
            getRating={this.getRating}
            update={this.update}
            comment={comment}
            updateTenantRating={this.updateTenantRating}
            disabled={disabled}
            renter={renter}
            ratingCategories={ratingCategories}
            requestCode={guidID}
            referenceId={guidID}
            permissionClicked={permissionClicked}
            declineClicked={declineClicked}
            proceed={this.proceed}
            addRentalLedger={addRentalLedger}
            removeRentalLedger={removeRentalLedger}
            showRentalLedgerSpinner={showRentalLedgerSpinner}
            ledger={ledger}
            secretGUID={secretGUID}
            rentalHistoryReview={rentalHistoryReview}
            decline={this.decline}
            disabledRating={disabledRating}
            getAnswerValue={this.getAnswerValue}
            learnMoreFromSnug={learnMoreFromSnug}
            onLearnMoreClicked={this.onLearnMoreClicked}
          />
        )}

        {error && <ErrorMessage error={translateErrorCodeToMessage(error)} />}
      </Display.CenterContentContainer>
    )
  }
}

export default RatingFormContainer
