import React from 'react'

import styled from 'styled-components'

import { ModalContentWrapper } from 'app/modals/modal-styled'

const ModalHeader = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 57px;
  z-index: 100;
  border-bottom: 1px solid #e8e8e9;
  display: flex;
  padding: 0 20px;
`

const ModalCloser = styled.div`
  z-index: 101;
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
  position: absolute;
  top: 5px;
  right: 7px;
  cursor: pointer;

  i {
    color: $gray;
  }

  &:hover i {
    color: $gray-dark;
  }
`

const ModalBody = styled.div`
  margin-top: 57px;
  height: calc(100% - 54px);
  padding: 10px;
  overflow: auto;
  line-height: 1.5;
  width: 100%;
  .add-viewing-notes-container {
    .notes-header {
      display: flex;
      height: 60px;
      align-items: center;
    }
  }
`

const ModalBottom = styled.div`
  width: 100%;
`

const ModalWithScroll = ({
  modalHeader,
  modalBody,
  modalBottom,
  modalCloser,
  toggleModal,
  containerClass,
  wrapperClass = '',
  flexibleHeight = false,
  takeMinHeight = false,
  showModalClose = true,
}) => {
  return (
    <div
      className={`modal-fullscreen modal-layout-flex-container ${containerClass}`}
    >
      <ModalContentWrapper
        className={`${wrapperClass}`}
        takeMinHeight={takeMinHeight}
      >
        {!modalCloser && showModalClose && (
          <ModalCloser>
            <i
              className="fa fa-times"
              role="button"
              tabIndex="0"
              onClick={toggleModal}
              onKeyPress={toggleModal}
            />
          </ModalCloser>
        )}
        {modalCloser && <ModalCloser>{modalCloser}</ModalCloser>}
        {modalHeader && <ModalHeader>{modalHeader}</ModalHeader>}
        {modalBody && <ModalBody>{modalBody}</ModalBody>}
        {modalBottom && <ModalBottom>{modalBottom}</ModalBottom>}
      </ModalContentWrapper>
    </div>
  )
}

export default ModalWithScroll
