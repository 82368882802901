import React from 'react'

import { Link } from 'react-router-dom'
import { Pie, PieChart } from 'recharts'

import isEmptyObject from 'app/shared_components/check_empty_object'
import Spinner from 'app/sm/common/spinner'
import { ApplicationCategory, urlTo } from 'app/sm/helpers'
import { formatGroupName } from 'app/sm/inspections/components/owner-list'
import ViewingSorterDropdown from 'app/sm/viewings/components/viewings_sorter_dropdown'

function RankPieChart(props) {
  const { rank } = props
  let fillColor = '#37c977'
  if (rank < 75) {
    fillColor = '#24C9D0'
  }
  if (rank < 50) {
    fillColor = '#579FFB'
  }
  if (rank < 25) {
    fillColor = '#ffe757'
  }
  const data = [
    { name: 'rank', value: rank, fill: fillColor },
    { name: 'blank', value: 100 - rank, fill: '#ffffff' },
  ]
  return (
    <PieChart width={40} height={40}>
      <Pie
        dataKey="value"
        data={data}
        innerRadius={16}
        outerRadius={20}
        labelLine={false}
      />
    </PieChart>
  )
}

function getButtonClass(application) {
  if (isEmptyObject(application)) {
    return ''
  }

  const status = application.status

  return `${
    status === 1
      ? 'btn-blue'
      : status === 4 || status === 5
      ? 'btn-red'
      : 'btn-light'
  }`
}

function getButtonText(application) {
  if (isEmptyObject(application)) {
    return 'Apply'
  }

  const status = application.status
  return ApplicationCategory[status]
}

function getButtonLink(application = {}, propertyOffer = {}) {
  if (isEmptyObject(application)) {
    return urlTo('apply', { propertyId: propertyOffer.guidID })
  }

  return urlTo('application', { applicationId: application.guidID })
}

const isViewingFavourite = (viewing) =>
  viewing.registerViewing ? viewing.registerViewing.isFavourite : false
const isPreregisteredViewing = (viewing) => viewing.registerViewing

class ViewingsListing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isTimeSortedAsc: true,
    }
  }

  componentDidMount() {
    this.props.fetchViewings()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.sortBy !== nextProps.sortBy && nextProps.sortBy === 'time') {
      this.setState({ isTimeSortedAsc: true })
    }
  }

  handleClickOnArrow = () => {
    const sorter = !this.state.isTimeSortedAsc ? 'time' : 'timeDesc'
    this.setState({ isTimeSortedAsc: !this.state.isTimeSortedAsc }, () => {
      this.props.onSorterDropdownChange(sorter)
    })
    return false
  }

  handleClickOnFavourite = (isFavourite, id) => {
    isFavourite ? this.props.removeFavourite(id) : this.props.addFavourite(id)
    return false
  }

  render() {
    return (
      <div className="sm-inspections-list">
        <div className="section-title xs-row">
          <div className="left">
            <h3 className="ibm lh1">Viewings ({this.props.viewings.length})</h3>
          </div>
          <div className="right">
            {/* <SearchInline placeholder="Search" className="xs-hide" /> */}
            <ViewingSorterDropdown
              sortyBy={this.props.sortBy}
              onChange={this.props.onSorterDropdownChange}
            />
          </div>
        </div>

        <table className="style6">
          <thead>
            <tr>
              <td />
              <td colSpan="2">Property</td>
              <td>Rent</td>
              <td>Snug Match</td>
              <td>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="active" onClick={() => this.handleClickOnArrow()}>
                  <span>Viewing Date</span>
                  <i
                    className={
                      this.state.isTimeSortedAsc
                        ? 'icon-arrow-down'
                        : 'icon-arrow-up'
                    }
                  />
                </a>
              </td>
              <td />
            </tr>
          </thead>
          <tbody>
            {this.props.spinner && (
              <tr>
                <td />
                <td colSpan="2" />
                <td />
                <td>
                  <Spinner />
                </td>
                <td />
                <td />
              </tr>
            )}
            {!this.props.spinner &&
              this.props.viewings.map((item, index) => (
                <tr key={index}>
                  <td>
                    <button className="btn btn-link">
                      <i
                        className={
                          isViewingFavourite(item)
                            ? 'icon-heart-filled'
                            : 'icon-heart-outline'
                        }
                        onClick={() => {
                          if (isPreregisteredViewing(item)) {
                            this.handleClickOnFavourite(
                              item.registerViewing.isFavourite,
                              item.registerViewing.guidID,
                            )
                          }
                        }}
                      />
                    </button>
                  </td>
                  <td>
                    <div
                      className="avatar avatar-small"
                      style={{
                        backgroundImage: `url(${item.viewing.mainImage})`,
                      }}
                    />
                  </td>
                  <td>
                    <b className="fs20">{item.viewing.address}</b>
                    <div className="pt3">{item.viewing.suburb}</div>
                  </td>
                  <td>{`$${item.viewing.weeklyRent} p/w`}</td>
                  <td>
                    <div className="rank-chart-wrapper">
                      <RankPieChart rank={item.viewing.score} />
                      <div className="rank-chart-text">
                        {item.viewing.score}
                      </div>
                    </div>
                  </td>
                  <td>
                    {item.viewing.cancelled ? (
                      <div className="cancelled">Cancelled</div>
                    ) : (
                      formatGroupName(item.viewing)
                    )}
                  </td>
                  <td>
                    <Link
                      className={`btn btn-medium btn-wide wa mr20 ${getButtonClass(
                        item.application,
                      )}`}
                      to={getButtonLink(item.application, item.propertyOffer)}
                    >
                      {getButtonText(item.application)}
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ViewingsListing
