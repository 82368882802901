import React from 'react'

import moment from 'moment'

import { Alert, Box, Modal } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  DRIVER_LICENCE_DOC_TYPE,
  PASSPORT_DOC_TYPE,
} from 'app/profile/renter/identity_doc/identity-doc.constants'
import { standardDateWithoutTimeAndComma } from 'app/sm/helpers'
import { getDateOfBirthText } from 'app/utils/datetime/helpers'

const ReviewDataModal = ({
  finalDocData,
  guidID,
  docType,
  updateOrCreateDoc,
  toggleReviewDataModal,
}) => {
  const {
    firstName,
    lastName,
    middleName,
    state,
    country,
    dateOfBirth,
    expiryDate,
    docNumber,
  } = finalDocData

  const fullName = `${firstName} ${middleName} ${lastName}`.replace(/  +/g, ' ')
  const parsedBirthDate = moment(dateOfBirth).format(
    standardDateWithoutTimeAndComma,
  )
  const parsedExpiryDate = moment(expiryDate).format('D/M/Y')

  const docConfig = {
    [PASSPORT_DOC_TYPE]: {
      label: 'Passport No',
      place: 'Country',
      value: country,
      number: docNumber,
      type: 'Passport',
    },
    [DRIVER_LICENCE_DOC_TYPE]: {
      label: 'Australian Driver Licence No',
      place: 'State',
      value: state,
      number: docNumber,
      type: 'Driver Licence',
    },
  }

  const modalConfirmationText = `Please ensure your details match your ${docConfig[docType].type} exactly`

  let dobText = getDateOfBirthText(parsedBirthDate)
  const confirmationDetails = (
    <Box className="width100">
      <Box className="mb25">
        <Alert variant="blueWithBg">{modalConfirmationText}</Alert>
      </Box>

      <Box>
        <Text color={theme.colors.gray400}>
          Name:{' '}
          <Text as="span" color={theme.colors.black}>
            {fullName}
          </Text>
        </Text>
      </Box>

      <Box>
        <Text color={theme.colors.gray400}>
          {`${docConfig[docType].label}: `}
          <Text as="span" color={theme.colors.black}>
            {`${docConfig[docType].number} `}
          </Text>
        </Text>
      </Box>

      <Box>
        <Text color={theme.colors.gray400}>
          {`${docConfig[docType].place}: `}
          <Text as="span" color={theme.colors.black}>
            {docConfig[docType].value}
          </Text>
        </Text>
      </Box>

      <Box>
        <Text color={theme.colors.gray400}>
          Date of Birth:{' '}
          <Text as="span" color={theme.colors.black}>
            {dobText}
          </Text>
        </Text>
      </Box>

      <Box>
        <Text color={theme.colors.gray400}>
          Document:{' '}
          <Text as="span" color={theme.colors.black}>
            {docConfig[docType].type}
          </Text>
        </Text>
      </Box>

      <Box>
        <Text color={theme.colors.gray400}>
          Expiry Date:{' '}
          <Text as="span" color={theme.colors.black}>
            {parsedExpiryDate}
          </Text>
        </Text>
      </Box>
    </Box>
  )

  return (
    <Modal
      modalHeading="Confirm Details"
      modalBody={confirmationDetails}
      primaryLabel="Save"
      primaryAction={() => {
        updateOrCreateDoc(finalDocData, guidID)
        toggleReviewDataModal()
      }}
      secondaryLabel="No"
      secondaryAction={toggleReviewDataModal}
      toggleModal={toggleReviewDataModal}
    />
  )
}

export default ReviewDataModal
