type EnvTypes = 'development' | 'test' | 'production'

export type EnvConfigValue = string

// Base app
export const ENV = String(process.env.REACT_APP_ENV) as EnvTypes
export const version = process.env.REACT_APP_BUILD_VERSION as EnvConfigValue

// URLs
export const apiNoVersionBase = process.env.REACT_APP_URL_API as EnvConfigValue
export const apiBaseUrl = `${apiNoVersionBase}/v1/` as EnvConfigValue
export const accountsBaseUrl = process.env
  .REACT_APP_URL_ACCOUNTS as EnvConfigValue
export const appURL = process.env.REACT_APP_URL_APP as EnvConfigValue
export const websiteUrl = process.env.REACT_APP_WEBSITE_URL as EnvConfigValue
export const mailDomain = process.env.REACT_APP_MAIL_DOMAIN as EnvConfigValue

// Secrets
export const stripeAPIKey = process.env
  .REACT_APP_API_KEY_STRIPE as EnvConfigValue
export const googleAPIKey = process.env
  .REACT_APP_API_KEY_GOOGLE as EnvConfigValue
export const recaptchaSiteKey = process.env
  .REACT_APP_RECAPTCHA_SITE_KEY as EnvConfigValue
export const productFruitWorkspaceCode = process.env
  .REACT_APP_PRODUCT_FRUIT_WORKSPACE_CODE as EnvConfigValue

// Environment keys
export const productionEnv = (): boolean => {
  return ENV === 'production'
}
export const developmentEnv = (): boolean => {
  return ENV === 'development'
}

// General config
export const kolmeoPartnerGUID = process.env
  .REACT_APP_KOLMEO_PARTNER_GUID as EnvConfigValue

export const uberCompNotShowingDay = process.env
  .REACT_APP_UBER_COMP_NOT_SHOWING_DAY as EnvConfigValue

export const messagesListLeadsFilterReleaseDate = process.env
  .REACT_APP_ENQUIRY_LIST_LEADS_FILTER_RELEASE_DATE as EnvConfigValue
