import { Formik } from 'formik'
import moment from 'moment'
import { toast } from 'react-toastify'

import { Box, Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import { sendMessage } from 'app/services/http/viewings'
import { ModalHeader } from 'app/sm/inspections/viewing_registrants_message_modal/components/ModalHeader'
import {
  formFields,
  formValidation,
} from 'app/sm/inspections/viewing_registrants_message_modal/form.utils'
import { FormBody } from 'app/sm/inspections/viewing_registrants_message_modal/FormBody'

const ViewingRegistrantsMessagesModal = ({
  onDismissClick,
  friendlyName,
  viewingGUID,
  startDate,
  agencyGUID,
  registrantsCount = 0,
}) => {
  const defaultSubject = () => {
    let subject = friendlyName
    if (startDate) {
      subject = moment(startDate).format('ddd hh:mma') + ' ' + subject
    }
    return subject
  }

  const initialValues = {
    [formFields.subject]: `re: ${defaultSubject()}`,
    [formFields.message]: '',
    [formFields.template]: '',
    [formFields.email]: true,
    [formFields.sms]: false,
  }

  const sendMessageRequest = (
    { message, sms, email, template, subject },
    { setSubmitting },
  ) => {
    const assertions = [
      [!registrantsCount, 'No attendees registered'],
      [!sms && !email, 'Please select Email or SMS'],
    ]

    for (const [cond, message] of assertions) {
      if (cond) {
        toast.error(message)
        setSubmitting(false)
        return
      }
    }

    return sendMessage(agencyGUID, viewingGUID, {
      message,
      subject,
      sms,
      email,
    })
      .then(() => {
        toast.success('Your message is currently processing!')
        onDismissClick()
      })
      .catch((err) => {
        toast.error(err.message || 'Failed to send message')
      })
  }

  return (
    <Box className="modal-fullscreen" bg={theme.colors.overlay}>
      <ModalContentWrapper>
        <Flex width="100%" flex="1 1" overflowY="auto">
          <Box p={theme.space[7] + 'px'} width="100%">
            <ModalHeader
              onDismiss={onDismissClick}
              subject={defaultSubject()}
              registrantsCount={registrantsCount}
            />
            <Box pb={theme.space[5] + 'px'} mt={theme.space[5] + 'px'}>
              <Formik
                initialValues={initialValues}
                onSubmit={sendMessageRequest}
                validate={formValidation}
                validateOnBlur={false}
              >
                {(props) => (
                  <FormBody
                    formBag={props}
                    agencyGUID={agencyGUID}
                    subject={defaultSubject()}
                  />
                )}
              </Formik>
            </Box>
          </Box>
        </Flex>
      </ModalContentWrapper>
    </Box>
  )
}

export default ViewingRegistrantsMessagesModal
