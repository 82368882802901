import { useMemo } from 'react'

import { Field, Form, Formik } from 'formik'
import { parsePhoneNumber } from 'libphonenumber-js'
import qs from 'qs'

import { Box } from 'app/components/design-system-components'
import { ArrowDropDownRounded } from 'app/components/design-system-components/icons/navigation'
import { InputField } from 'app/components/design-system-components/inputs'
import {
  formFields,
  formValidation,
} from 'app/components/ViewingRegisterRequestFormModal/form-utils'
import {
  CustomMobileInput,
  StyledFieldWrapper,
  StyledInputWrapper,
} from 'app/components/ViewingRegisterRequestFormModal/styled-form-component'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as phoneNumberHelpers from 'app/utils/phonenumber/helpers'

const AU_COUNTRY_CODE = 'AU'

const RegisterViewingModalBody = ({
  formRef,
  currentUser,
  selectedViewing,
}) => {
  const baseInitialValues = useMemo(() => {
    const {
      enquirer_first_name = '',
      enquirer_last_name = '',
      enquirer_email = '',
      enquirer_mobile = '',
    } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    const parsedNumber = currentUser.mobile
      ? parsePhoneNumber(currentUser.mobile.replace(/\s/g, ''), AU_COUNTRY_CODE)
      : ''

    const {
      email: currentUserEmail,
      firstName: currentUserFirstName,
      lastName: currentUserLastName,
    } = currentUser

    return {
      [formFields.firstName]: currentUserFirstName || enquirer_first_name || '',
      [formFields.lastName]: currentUserLastName || enquirer_last_name || '',
      [formFields.email]: currentUserEmail || enquirer_email || '',
      [formFields.contactNumber]:
        phoneNumberHelpers.formatPhoneNumber(parsedNumber.number) ||
        phoneNumberHelpers.formatPhoneNumber(enquirer_mobile) ||
        '',
      [formFields.preferredDate]:
        `${dateTimeHelpers.formattedUTCDisplayDateWithTime(
          selectedViewing.preferredDate,
        )} (${selectedViewing.duration} min)` || '',
    }
  }, [currentUser, selectedViewing])

  return (
    <Box color="black">
      <Formik
        enableReinitialize={true}
        initialValues={baseInitialValues}
        onSubmit={() => {}}
        innerRef={formRef}
        validate={formValidation}
      >
        {(props) => <RegisterFormBody formBag={props} />}
      </Formik>
    </Box>
  )
}

const RegisterFormBody = ({ formBag }) => {
  return (
    <Form>
      <StyledInputWrapper>
        <StyledFieldWrapper
          id={formFields.firstName}
          name={formFields.firstName}
        >
          <Field
            name={formFields.firstName}
            as={InputField}
            id={formFields.firstName}
            placeholder={`First name`}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper id={formFields.lastName} name={formFields.lastName}>
          <Field
            name={formFields.lastName}
            as={InputField}
            id={formFields.lastName}
            placeholder={`Last name`}
          />
        </StyledFieldWrapper>
      </StyledInputWrapper>
      <StyledInputWrapper>
        <StyledFieldWrapper id={formFields.email} name={formFields.email}>
          <Field
            name={formFields.email}
            as={InputField}
            id={formFields.email}
            placeholder={`e.g email@example.com`}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper
          id={formFields.contactNumber}
          name={formFields.contactNumber}
        >
          <Field
            name={formFields.contactNumber}
            as={CustomMobileInput}
            id={formFields.contactNumber}
            placeholder={`+61 412 345 678`}
          />
        </StyledFieldWrapper>
      </StyledInputWrapper>
      <StyledInputWrapper>
        <StyledFieldWrapper
          id={formFields.preferredDate}
          name={formFields.preferredDate}
        >
          <Field
            name={formFields.preferredDate}
            id={formFields.preferredDate}
            render={({ field }) => (
              <InputField
                disabled={true}
                {...field}
                endedIcon={<ArrowDropDownRounded />}
              />
            )}
          />
        </StyledFieldWrapper>
      </StyledInputWrapper>
    </Form>
  )
}

export default RegisterViewingModalBody
