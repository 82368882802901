import React from 'react'

import qs from 'qs'

import BackButton from 'app/bond_cover/renters/sharedComponent/back_button'
import NextButton from 'app/bond_cover/renters/sharedComponent/Next_button'
import { history } from 'app/shared_components/router'

import 'app/bond_cover/renters/sharedComponent/bottom_button_widget.scss'

const BottomButtonsWidget = ({
  btnText,
  onClickNextUp,
  onClickBack,
  nextBtnId,
  nextBtnDisabled,
  nextBtnClass,
  backButtonClass = '',
  fromRenterSummary = false,
  bottomButtonsClass = '',
  isSummary = false,
  nextButtonPositionClass = '',
  showArrow,
}) => (
  <div
    className={`row mobile-form-button bottom-buttom-class ${bottomButtonsClass}`}
  >
    <div className={`pb5 col-sm-7`}>
      <BackButton
        clickBackFunction={onClickBack}
        backButtonClass={backButtonClass}
        showArrow={showArrow}
      />
    </div>
    {btnText && !fromRenterSummary && (
      <div className={`pb5 col-sm-5 ${nextButtonPositionClass}`}>
        <NextButton
          btnText={btnText}
          clickFunction={onClickNextUp}
          nextBtnId={nextBtnId}
          nextBtnDisabled={nextBtnDisabled}
          nextBtnClass={nextBtnClass}
        />
      </div>
    )}
  </div>
)

const TopButtonsWidget = ({ onClickBack }) => (
  <div className="mobile-form-button mobile-only">
    <div className="pr10">
      <BackButton clickBackFunction={onClickBack} showArrow={true} />
    </div>
  </div>
)

const isFromRenterSummary = () => {
  const curQueries = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  return (
    curQueries.from === 'rentersummary' &&
    !window.location.pathname.includes('/rentersummary')
  )
}

class BottomButtons extends React.Component {
  onClickBackFunction = () => {
    const fromRenterSummary = isFromRenterSummary()
    const {
      rentalBackUrlList,
      backUrl,
      deleteLastRentalBackUrl,
      customisedBackClick,
    } = this.props
    const rentalBackUrl =
      rentalBackUrlList[rentalBackUrlList.length - 1] || backUrl
    const atRenterSummaryPage =
      window.location.pathname.includes('/rentersummary')
    const defaultRentalBackUrl = '/'
    deleteLastRentalBackUrl()
    if (customisedBackClick) {
      customisedBackClick()
    } else if (
      fromRenterSummary ||
      atRenterSummaryPage ||
      rentalBackUrl === defaultRentalBackUrl ||
      !rentalBackUrl
    ) {
      history.goBack()
    } else {
      history.push(rentalBackUrl)
    }
  }

  onClickNextUp = () => {
    const rentalBackUrl = window.location.pathname + window.location.search
    const { nextUrl, btnText, rentalBackUrlList } = this.props
    if (btnText === 'Complete' && rentalBackUrlList) {
      rentalBackUrlList[0].includes('/bc/profile')
        ? history.push(rentalBackUrlList[0])
        : history.push(nextUrl)
      return
    }
    this.props.addRentalReputationBackUrl(rentalBackUrl)
    if (nextUrl) {
      history.push(nextUrl)
    } else {
      history.goBack()
    }
  }

  render() {
    const {
      btnText,
      clickNextFunction,
      isMobile,
      nextBtnId,
      nextBtnDisabled,
      nextBtnClass,
      bottomButtonsClass,
      backButtonClass,
      isSummary,
      nextButtonPositionClass,
      showArrow = true,
    } = this.props
    const fromRenterSummary = isFromRenterSummary()
    const isRenterSummaryPage =
      window.location.pathname.includes('/rentersummary')
    return isMobile ? (
      <TopButtonsWidget
        btnText={btnText}
        onClickNextUp={clickNextFunction || this.onClickNextUp}
        onClickBack={this.onClickBackFunction}
      />
    ) : (
      <BottomButtonsWidget
        btnText={btnText || 'Update'}
        onClickNextUp={clickNextFunction || this.onClickNextUp}
        onClickBack={this.onClickBackFunction}
        nextBtnId={nextBtnId}
        nextBtnDisabled={nextBtnDisabled}
        nextBtnClass={nextBtnClass}
        fromRenterSummary={fromRenterSummary}
        isSummary={isRenterSummaryPage}
        nextButtonPositionClass={nextButtonPositionClass}
        backButtonClass={backButtonClass}
        showArrow={showArrow}
        bottomButtonsClass={bottomButtonsClass}
      />
    )
  }
}

export default BottomButtons
