import React from 'react'

import styled from 'styled-components'

import BCCertification from 'app/assets/icons/bc-certificate-bg.png'
import blueTick from 'app/assets/icons/blue-filled-info.svg'
import greenTick from 'app/assets/icons/green-filled-info.svg'
import yellowTick from 'app/assets/icons/yellow-filled-info.svg'
import { Box, Flex } from 'app/components/design-system-components'
import SectionHeader from 'app/sm/renter_background_check_report/components/section-header/component'
import { PageText } from 'app/sm/renter_background_check_report/new_backgroundcheck_report_container'

const BackgroundCheckCertificationWrapper = styled(Box)`
  margin: ${({ theme }) =>
    `${theme.dividedSpace[4]}px 0 ${theme.dividedSpace[5]}px 0`};
  padding: ${({ theme }) => `${theme.dividedSpace[5]}px`};
  position: relative;
  overflow: hidden !important;
  border-radius: 10px;
  border: 0.5px solid #bdbbca;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.85),
      rgba(255, 255, 255, 0.85)
    ),
    url(${BCCertification}) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  overflow: scroll !important;
`

const ContentContainer = styled(Flex)`
  padding: ${({ theme }) => `0 ${theme.dividedSpace[6]}px 0 52px`};
  @media screen and (max-width: 576px) {
    display: block;
    padding: ${({ theme }) =>
      `0 ${theme.dividedSpace[2]}px 0 ${theme.dividedSpace[2]}px`};
  }
`
const LeftContentContainer = styled(Box)`
  width: 50%;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`
const RightContentContainer = styled(Box)`
  width: 50%;
  margin-left: ${({ theme }) => `${theme.dividedSpace[10]}px`};

  @media screen and (max-width: 576px) {
    width: 100%;
    margin-left: 0px;
  }
`

const CheckMarkWrapper = styled(Box)`
  display: flex;
  margin: ${({ theme }) =>
    `${theme.dividedSpace[2]}px 0 ${theme.dividedSpace[2]}px 0`};
  align-items: flex-start;
`

export const CheckMark = ({ text = '', iconType = 'verified' }) => {
  return (
    <CheckMarkWrapper>
      {iconType === 'verified' ? (
        <img src={greenTick} alt="submitted" />
      ) : iconType === 'not-verified' ? (
        <img src={blueTick} alt="submitted" />
      ) : (
        <img src={yellowTick} alt="submitted" />
      )}
      <PageText ml={'15px'} isNotMb={true} isNotAlign={true}>
        {text}
      </PageText>
    </CheckMarkWrapper>
  )
}

const DatabasesCheckComponent = ({
  icon = '',
  label = '',
  subLabel = '',
  result = '',
  statusClass = '',
  statusText = '',
  updateDate = '',
  leftBody = <></>,
  rightBody = <></>,
  mh,
}) => {
  return (
    <BackgroundCheckCertificationWrapper>
      <SectionHeader
        title={label}
        subTitle={subLabel}
        image={icon}
        status={result}
        showStatus={true}
        statusClass={statusClass}
        statusText={statusText}
        updateDate={updateDate}
      />
      <ContentContainer>
        <LeftContentContainer>{leftBody}</LeftContentContainer>
        <RightContentContainer>{rightBody}</RightContentContainer>
      </ContentContainer>
    </BackgroundCheckCertificationWrapper>
  )
}

export default DatabasesCheckComponent
