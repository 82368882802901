import React from 'react'

import PDFJSMain from '@bundled-es-modules/pdfjs-dist/build/pdf'

import { Box, Button, Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import * as Form from 'app/components/forms/forms'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  invalidDocExtensionExists,
  invalidDocSizeExists,
} from 'app/shared_components/helpers'
import Spinner from 'app/sm/common/spinner'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'
import * as stringHelpers from 'app/utils/strings/helpers'
import { errorMessageForInvalidFileTypeDoc } from 'app/utils/text/helpers'
PDFJSMain.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJSMain.version}/pdf.worker.js`

const fileExtensions = (f) =>
  (f || {}).name && f.name.substring(f.name.lastIndexOf('.') + 1, f.name.length)

const onGreenRoundClicked = (documentType) => {
  document.getElementById(`attachments-${documentType}`).click()
}

class DocumentListItemSecondVersion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      validationError: '',
      newDocuments: [],
    }
  }

  onDocumentsDelete = (documentName, docuidID) => {
    const { setDocumentsChanged } = this.props
    const array = [...this.state.newDocuments]
    const index = array.indexOf(documentName)
    array.splice(index, 1)
    this.setState({ newDocuments: array }, () => {
      setDocumentsChanged && setDocumentsChanged(this.state.newDocuments.length)
    })
    this.props.deleteDocument(docuidID)
  }

  onDocumentsUpdate = (event) => {
    event.persist()
    this.setState({ validationError: '' })
    const {
      addDocument,
      documentType,
      fileType,
      setDocumentsChanged,
      documents,
    } = this.props
    const attachments = document.getElementById(`attachments-${documentType}`)
    const attachmentsArr = Object.keys(attachments.files).map(
      (k) => attachments.files[k],
    )
    const findInvalidDocExtension = invalidDocExtensionExists(
      attachmentsArr,
      'doc',
    )
    const findInvalidDocSize = invalidDocSizeExists(attachmentsArr)
    const hasPdfFiles = attachmentsArr.some(
      (doc) => fileExtensions(doc) === 'pdf',
    )
    const isAttachmentExist = documents.some(
      (attach) => attachmentsArr[0].name === attach.name,
    )
    if (isAttachmentExist) {
      if (!window.confirm(stringHelpers.isAttachmentExistMessage)) {
        event.target.value = null
        return
      }
    }

    if (findInvalidDocExtension) {
      this.setState({
        validationError: errorMessageForInvalidFileTypeDoc(
          findInvalidDocExtension.name,
        ),
      })
    } else if (findInvalidDocSize) {
      this.setState({
        validationError: `The file size of ${findInvalidDocSize.name} can not exceed 10mb`,
      })
    } else {
      if (hasPdfFiles) {
        this.isPdfEncrypted(attachmentsArr, event)
      } else {
        addDocument(documentType, fileType)
        !!attachmentsArr[0] &&
          this.setState(
            {
              newDocuments: [
                ...this.state.newDocuments,
                attachmentsArr[0].name,
              ],
            },
            () => {
              setDocumentsChanged &&
                setDocumentsChanged(this.state.newDocuments.length)
            },
          )
        event.target.value = null
      }
    }
  }

  checkPdfAttachments(promiseArr, numOfAttachments) {
    const { addDocument, documentType, fileType } = this.props

    if (promiseArr.length === numOfAttachments) {
      Promise.all(promiseArr)
        .then(() => addDocument(documentType, fileType))
        .catch((e) =>
          this.setState({
            validationError: `The PDF file is password protected (${e.name})`,
          }),
        )
    }
  }

  isPdfEncrypted = (attachmentsArr, parentEvent) => {
    const pdfAttachmentsArr =
      (attachmentsArr || []).filter((a) => fileExtensions(a) === 'pdf') || []
    const promiseArr = []

    // eslint-disable-next-line array-callback-return
    pdfAttachmentsArr.map((pdf) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        const typedarray = new Uint8Array(event.target.result)
        const pdfData = PDFJSMain.getDocument(typedarray).promise
        pdfData
          .then((data) => {
            parentEvent.target.value = null
            return data
          })
          .catch((e) => {
            this.setState({
              validationError: `The PDF file is password protected (${e.name})`,
            })
          })

        promiseArr.push(pdfData.promise)
        this.checkPdfAttachments(promiseArr, pdfAttachmentsArr.length)
      }
      reader.readAsArrayBuffer(pdf)
    })
  }

  render() {
    const {
      documents = [],
      documentType,
      label = 'Add file',
      labelFontWeight,
      fileBodyClass,
      showRentalLedgerSpinner,
      titleClass = '',
      enableCheckbox = false,
      onCheckboxToggled = undefined,
      documentWrapperClass = '',
      hideInstructions,
      docBadgeRenderer,
      textButton = false,
      textButtonLabel = 'Upload',
    } = this.props
    const { validationError } = this.state

    return (
      <div>
        <div className="sm-file-upload single">
          <div className="display-flex mb10">
            {!textButton ? (
              <div
                role="button"
                tabIndex={0}
                className="btn btn-round pt5 btn-round-small"
                onClick={() => onGreenRoundClicked(documentType)}
              >
                <i className="icon-add" />
              </div>
            ) : (
              <Button
                sizeVariant="large"
                onClick={() => onGreenRoundClicked(documentType)}
              >
                {textButtonLabel}
              </Button>
            )}
            <input
              multiple
              id={`attachments-${documentType}`}
              type="file"
              onChange={(event) => this.onDocumentsUpdate(event)}
              className="d-none"
              onClick={(event) => (event.target.value = null)}
            />
            <div className="pl10">
              <Text
                as="div"
                mt={5}
                fontWeight={labelFontWeight || theme.fontWeights[6]}
                fontSize={theme.fontSizes.pRegular14}
                color={theme.colors.gray600}
              >
                {label}
              </Text>
            </div>
          </div>
          {showRentalLedgerSpinner && <Spinner />}
          {documents.map((doc, index) => {
            const docBadgeCmp =
              typeof docBadgeRenderer === 'function' && docBadgeRenderer(doc)
            return (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                className={documentWrapperClass}
                key={index}
              >
                {enableCheckbox && (
                  <Form.CheckBoxGeneral
                    checked={doc.included}
                    onChange={() => onCheckboxToggled(doc.Key)}
                    componentClassName="mr10 mb0"
                  />
                )}
                <AppDocumentListItemSecondVersion
                  titleClass={`mt0 ${titleClass}`}
                  document={doc}
                  key={index}
                  fileBodyClass={fileBodyClass}
                />

                <Flex alignItems="center">
                  {docBadgeCmp && <Box>{docBadgeCmp}</Box>}

                  <div className="actionButtonWrapper height100 mt10 mb10">
                    <button
                      onClick={() => {
                        this.onDocumentsDelete(doc.name, doc.guidID || doc.Key)
                      }}
                      className="remove-btn hollow-button-red height30-mobile pr10-mobile pl10-mobile"
                    >
                      Remove
                    </button>
                  </div>
                </Flex>
              </Flex>
            )
          })}
          {documents.length === 0 && !hideInstructions && (
            <div className="mt10">Click to upload documents</div>
          )}
        </div>
        <div className={validationError ? 'alert alert-danger' : 'hide-alert'}>
          <div>{validationError}</div>
        </div>
      </div>
    )
  }
}

export default DocumentListItemSecondVersion
