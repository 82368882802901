import React from 'react'
import 'app/shared_components/layout_component/center_content_component/style.scss'

const CenterContentContainer = ({
  children,
  componentClass,
  containerClass = '',
}) => (
  <div className="width100">
    <div className={`center-container ${containerClass}`}>
      <div
        className={`sm-apply__apply-offer application-wrapper ${componentClass}`}
      >
        <div className="address-rank m0 border-none">{children}</div>
      </div>
    </div>
  </div>
)

export default CenterContentContainer
