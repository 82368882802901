import { connect } from 'react-redux'

import {
  acceptInvite,
  validateInvite,
} from 'app/bond_cover/agency/agency_actions'
import { fetchAgencyInfo } from 'app/bond_cover/agency/agency_actions'
import AgencyInviteAcceptContainer from 'app/bond_cover/agency/agency_invite_accept_container'
import { fetchTeams, logout } from 'app/session/session_actions'

const mapStateToProps = ({ session }) => ({
  currentUser: session.currentUser,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => logout(),
  validateInvite,
  acceptInvite,
  fetchTeams: () => dispatch(fetchTeams()),
  fetchAgencyInfo: (agencyId) => dispatch(fetchAgencyInfo(agencyId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgencyInviteAcceptContainer)
