import React from 'react'

import { Modal } from 'app/components/design-system-components'

function UploadModal({
  toggleUploadModal,
  disclosureTabs,
  disclosureSubmitted,
}) {
  const modalBody = disclosureTabs
  return (
    <Modal
      primaryLabel="Upload"
      secondaryLabel="Cancel"
      modalHeading="Upload Disclosures"
      modalBody={modalBody}
      toggleModal={toggleUploadModal}
      secondaryAction={toggleUploadModal}
      primaryAction={disclosureSubmitted}
    />
  )
}

export default UploadModal
