import { declineReasonsIDs } from 'app/constants/rental-references.constants'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { nestedFieldNameGeneratorFactory } from 'app/forms/utils/nestedForms'
import {
  validateEmail,
  validateMobile,
  validateName,
  validatePhoneNumber,
} from 'app/shared_components/validations'

export const agentContactFormFieldName = 'agentContact'

export const agentContactFormFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  mobile: 'mobile',
  officeNumber: 'officeNumber',
}

const nameValidation = (fieldName) => {
  return (val) => ({
    required: !val && `Please enter ${fieldName}`,
    pattern: validateName(val)[0],
  })
}

const firstNameValidator = nameValidation('first name')
const lastNameValidator = nameValidation('lastName')

const agentContactFormFieldValidation = (fieldName, val, values) => {
  switch (fieldName) {
    case agentContactFormFields.firstName: {
      const firstNameVal = values[agentContactFormFields.firstName]
      return firstNameValidator(firstNameVal)
    }
    case agentContactFormFields.lastName: {
      const lastNameVal = values[agentContactFormFields.lastName]
      return lastNameValidator(lastNameVal)
    }
    case agentContactFormFields.email: {
      const mobileVal = values[agentContactFormFields.mobile]
      return {
        required: !val && !mobileVal && minRequiredContactDetailsError,
        pattern: !!val && validateEmail(val)[0],
      }
    }
    case agentContactFormFields.mobile: {
      const emailVal = values[agentContactFormFields.email]
      return {
        required: !val && !emailVal && minRequiredContactDetailsError,
        pattern: !!val && validateMobile(val)[0],
      }
    }
    case agentContactFormFields.officeNumber: {
      return {
        pattern:
          !!val && validatePhoneNumber(val, 'Please enter a valid number')[0],
      }
    }
  }
}

const agentContactFormValidation = formValidationBuilder(
  agentContactFormFieldValidation,
)

export const agentContactFormFieldNameGenerator =
  nestedFieldNameGeneratorFactory(agentContactFormFieldName)

export const formFields = {
  declineReason: 'declineReason',
  redirectReference: 'redirectReference',
  agentContact: {
    firstName: agentContactFormFieldNameGenerator(
      agentContactFormFields.firstName,
    ),
    lastName: agentContactFormFieldNameGenerator(
      agentContactFormFields.lastName,
    ),
    email: agentContactFormFieldNameGenerator(agentContactFormFields.email),
    mobile: agentContactFormFieldNameGenerator(agentContactFormFields.mobile),
    officeNumber: agentContactFormFieldNameGenerator(
      agentContactFormFields.officeNumber,
    ),
  },
}

const minRequiredContactDetailsError =
  'Please provide a contact number or email address'

const fieldValidation = (fieldName, val, values) => {
  switch (fieldName) {
    case formFields.declineReason: {
      return {
        required: !val && 'One reason should be selected',
      }
    }
    case agentContactFormFieldName: {
      const needAgentContact =
        Object.values(values[formFields.declineReason])[0]?.option ===
          declineReasonsIDs.NOT_THE_PROPERTY_MANAGER &&
        values[formFields.redirectReference]
      if (!needAgentContact) return {}
      return agentContactFormValidation(val)
    }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
