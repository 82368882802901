import $ from 'jquery'
import moment from 'moment'

import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getPortals = (success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}sm/properties/listingportals`,
    success,
    error,
  })
}

export const createAdvertisement = (id, data, success, error) => {
  const formatted = Object.assign({}, data, {
    startDate: undefined,
    availableFrom: moment(data.startDate).format('YYYY-MM-DD'),
  })

  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}sm/properties/${id}/offers`,
    data: JSON.stringify(formatted),
    success,
    error,
  })
}

export const updateAdvertisement = (id, data, success, error) => {
  const formatted = Object.assign({}, data, {
    startDate: undefined,
    availableFrom: moment(data.startDate).format('YYYY-MM-DD'),
  })

  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'PUT',
    url: `${apiBaseUrl}sm/offers/${id}`,
    data: JSON.stringify(formatted),
    success,
    error,
  })
}

export const getAdvertisement = (offerId, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}sm/offers/${offerId}`,
    success,
    error,
  })
}

export const registerPayment = (id, data, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}/properties/${id}/payments/register`,
    data,
    success,
    error,
  })
}

export const createAd = (offerId, data, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}sm/offers/${offerId}/ads`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const updateAd = (id, data, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'put',
    url: `${apiBaseUrl}sm/ads/${id}`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const getAds = (id, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}sm/ads/${id}`,
    success,
    error,
  })
}

export const getActiveAd = (offerId) => {
  const url = `${apiBaseUrl}sm/offers/${offerId}/ad`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (ad) => ({ ok: true, ad }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const payWithExistingAccount = (creditCardId, adId) => {
  const payload = {
    stripeToken: '',
    creditCardId,
  }

  const url = `${apiBaseUrl}sm/ads/${adId}/payments`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const payWithNewAccount = (adId, data) => {
  // TODO do the logic if the user wants to save for future use
  const payload = {
    stripeToken: data.stripeToken,
  }

  // The id is offerId in this context
  const url = data.saveCard
    ? `${apiBaseUrl}sm/ads/${adId}/payments`
    : `${apiBaseUrl}sm/ads/${adId}/payments/register`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
