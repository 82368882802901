export const studentDetailsAttachmentTypes = {
  studentId: {
    id: 1,
    label: 'Student ID',
  },
  offerLetter: {
    id: 2,
    label: 'Letter of Offer',
  },
}
