import React from 'react'

import * as Display from 'app/components/display/display'
import SnugUpgradeAccount from 'app/shared_components/snug_account_upgrade/index'

const SnugUpgradeAccountModal = ({
  isNotToBeEmbedded = true,
  toggleModal,
  typeOfMessage = '',
}) => {
  return (
    <Display.ModalWithScroll
      modalBody={
        <SnugUpgradeAccount
          isNotToBeEmbedded={isNotToBeEmbedded}
          typeOfMessage={typeOfMessage}
        />
      }
      toggleModal={toggleModal}
    />
  )
}

export default SnugUpgradeAccountModal
