import { isValidNumber } from 'libphonenumber-js'

import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { validateEmail } from 'app/shared_components/validations'

export const formFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  mobile: 'mobile',
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.firstName: {
      return {
        required: !val && 'First name is required',
      }
    }
    case formFields.lastName: {
      return {
        required: !val && 'Last name is required',
      }
    }
    case formFields.email: {
      return {
        required: !val && 'Email is required',
        invalid: validateEmail(val)[0],
      }
    }
    case formFields.mobile: {
      return {
        required: !val && 'Mobile is required',
        invalid: val && !isValidNumber(val) && 'Invalid phone number',
      }
    }
    default:
      return {}
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
