import React, { useEffect, useState } from 'react'

import qs from 'qs'

import {
  Alert,
  Box,
  Button,
  Flex,
  LegacySpinner,
  ProgressBar,
} from 'app/components/design-system-components'
import HorizontalTabs from 'app/components/design-system-components/HorizontalTabs'
import { ModalButtonGroup } from 'app/components/display/display'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'
import {
  getCurrentStepForQuickStart,
  getSuggestedTeamMembersList,
  postNewStepForAgencyForQuickstart,
} from 'app/sm/team_viewing_preference/setup/api'
import * as setupHelpers from 'app/sm/team_viewing_preference/setup/helpers'
import { StyledSetupContainerSteps } from 'app/sm/team_viewing_preference/setup/index'
import InviteTeamMembersModal from 'app/sm/team_viewing_preference/setup/inviteTeamMemberModal'
import TeamList from 'app/sm/team_viewing_preference/setup/letsGetStarted/TeamList'
import AllTeamMembers from 'app/sm/team_viewing_preference/setup/TeamMembers/allTeamMembers'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

const THIS_STEP = setupHelpers.SETUP_TEAM_MEMBERS

function TeamMembers({ match }) {
  const {
    loadingStates: teamMembersLoadingStates,
    loadingStatesHelpers: teamMembersLoadingStateHelpers,
  } = useLoadingStates()

  const {
    loadingStates: suggestedTeamMembersLoadingStates,
    loadingStatesHelpers: suggestedTeamMembersLoadingStateHelpers,
  } = useLoadingStates()

  const { params } = match
  const { teamSlug = '' } = params
  const curQueries = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  const { teamGUID = '' } = curQueries
  const [activeTabGUID, setActiveTabGUID] = useState('3')
  const onClickTabInHorizontalTab = (tabGUID) => {
    setActiveTabGUID(tabGUID)
  }

  const [isInviteTeamMembersModalOpen, setIsInviteTeamMembersModalOpen] =
    useState(false)
  const [wizardGUID, setWizardGUID] = useState('')
  const [currentStep, setCurrentStep] = useState(THIS_STEP)

  const [suggestedTeamMembersList, setSuggestedTeamMembersList] = useState([])

  const [initialInvite, setInitialInvite] = useState('')
  const toggleInviteTeamMembersModal = () => {
    setIsInviteTeamMembersModalOpen(!isInviteTeamMembersModalOpen)
  }

  const onClickInvite = (member) => {
    toggleInviteTeamMembersModal()
    setInitialInvite(member)
  }

  useEffect(() => {
    teamMembersLoadingStateHelpers.startLoading()
    suggestedTeamMembersLoadingStateHelpers.startLoading()

    getSuggestedTeamMembersList(teamGUID)
      .then((data) => {
        setSuggestedTeamMembersList(data)
        suggestedTeamMembersLoadingStateHelpers.markDoneSuccessfully()
      })
      .catch((error) => suggestedTeamMembersLoadingStateHelpers.setError(error))

    getCurrentStepForQuickStart(teamGUID)
      .then((data) => {
        const { id, current_step_id } = data
        setWizardGUID(id)
        setCurrentStep(current_step_id)
        teamMembersLoadingStateHelpers.markDoneSuccessfully()
      })
      .catch((error) => teamMembersLoadingStateHelpers.setError(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToNextStep = () => {
    history.push(urlTo('stepBranding', { teamSlug, agencyGUID: teamGUID }))
  }

  const onNextClicked = () => {
    if (currentStep === THIS_STEP) {
      postNewStepForAgencyForQuickstart(teamGUID, wizardGUID)
        .then(({ current_step }) => {
          const { ID } = current_step
          const step = setupHelpers.findWhichStepToRouteTo(ID)
          history.push(urlTo(step, { teamSlug, agencyGUID: teamGUID }))
        })
        .catch((error) => snugNotifier.error(error.message))
    } else {
      goToNextStep()
    }
  }

  return (
    <StyledSetupContainerSteps>
      <Box mb={theme.space[8] + 'px'}>
        <ProgressBar totalStepsCount={3} lastCheckedStep={2} />
      </Box>
      <h2>Team Members</h2>
      <Box mt={theme.space[5] + 'px'} mb={theme.space[8] + 'px'}>
        Click to invite your team members from the suggested list below or click
        Invite New Team Member. We'll send them an email to join your Snug team.
      </Box>

      {teamMembersLoadingStates.state === loadingStatesIds.LOADING ? (
        <Flex justifyContent="center">
          <LegacySpinner />
        </Flex>
      ) : (
        <>
          {teamMembersLoadingStates.state !== loadingStatesIds.ERROR ? (
            <>
              <Box mb={theme.space[5] + 'px'}>
                <Button
                  sizeVariant="large"
                  onClick={toggleInviteTeamMembersModal}
                >
                  Invite New Team Member
                </Button>
              </Box>
              <HorizontalTabs
                tabHeaders={[
                  { tabGUID: '1', label: 'Members' },
                  // { tabGUID: '2', label: 'Invites' },
                  { tabGUID: '3', label: 'Suggested' },
                ]}
                activeTabGUID={activeTabGUID}
                onClickTabInHorizontalTab={onClickTabInHorizontalTab}
              />

              <Box>
                {activeTabGUID === '1' && (
                  <div>
                    <AllTeamMembers teamGUID={teamGUID} />
                  </div>
                )}
                {/* {activeTabGUID === '2' && <div>Invites</div>} */}
                {activeTabGUID === '3' && (
                  <div>
                    {suggestedTeamMembersLoadingStates.state ===
                    loadingStatesIds.LOADING ? (
                      <Flex justifyContent="center">
                        <LegacySpinner />
                      </Flex>
                    ) : (
                      <>
                        {suggestedTeamMembersLoadingStates !==
                        loadingStatesIds.ERROR ? (
                          <>
                            {suggestedTeamMembersList &&
                            suggestedTeamMembersList.length > 0 ? (
                              <TeamList
                                membersList={suggestedTeamMembersList}
                                showInviteButtons={true}
                                onClickInvite={onClickInvite}
                              />
                            ) : (
                              <Alert>
                                Could not find any team members. Please invite
                                team members by clicking the button above.
                              </Alert>
                            )}
                          </>
                        ) : (
                          <>
                            <Alert
                              variant="warningWithBg"
                              mt={theme.space[6] + 'px'}
                            >
                              <div>
                                {
                                  suggestedTeamMembersLoadingStates.error
                                    .message
                                }
                              </div>
                            </Alert>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </Box>
              {isInviteTeamMembersModalOpen && (
                <InviteTeamMembersModal
                  toggleModal={toggleInviteTeamMembersModal}
                  teamGUID={teamGUID}
                  initialInvite={initialInvite}
                />
              )}
              <ModalButtonGroup
                primaryLabel="Next"
                secondaryLabel="Back"
                secondaryButtonType="tertiary"
                primaryAction={onNextClicked}
                secondaryAction={() =>
                  history.push(
                    urlTo('stepLetsGetStarted', {
                      teamSlug,
                      agencyGUID: teamGUID,
                    }),
                  )
                }
                justifyContent="left"
              />
            </>
          ) : (
            <Alert variant="warningWithBg" mt={theme.space[3] + 'px'}>
              <div>{teamMembersLoadingStates.error.message}</div>
            </Alert>
          )}
        </>
      )}
    </StyledSetupContainerSteps>
  )
}

export default TeamMembers
