import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'
import { api } from 'app/utils/api/helpers'

export const getTeamActivities = (teamGUID, options) => {
  const {
    startDate = '',
    endDate = '',
    manager = [],
    sort_dir = '',
    sort = '',
    propertyGUID = '',
    cursor = '',
    limit = 10,
  } = options

  const url = `${apiBaseUrl}sm/teams/${teamGUID}/activity?startTime=${startDate}&endTime=${endDate}&sort_dir=${sort_dir}&sort=${sort}&limit=${limit}&cursor=${cursor}&property=${propertyGUID}`
  let managerParams = new URLSearchParams()
  manager.forEach((managerGUID) => managerParams.append('manager', managerGUID))
  return fetch(url + `&${managerParams}`, {
    credentials: 'include',
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }).append(
      'Content-Type',
      'text',
    ),
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({ statusCode: res.status, responseText: res.text() })
    })
    .then((activities) => {
      return { ok: true, activities }
    })
    .catch((error) => {
      return {
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }
    })
}

export const getAdvanceTeamActivities = (teamGUID, options) => {
  const {
    startDate = '',
    endDate = '',
    manager = [],
    sort_dir = '',
    sort = '',
    propertyGUID = '',
    cursor = '',
    limit = 10,
  } = options

  const url = `${apiBaseUrl}sm/teams/${teamGUID}/advancedactivity?startTime=${startDate}&endTime=${endDate}&sort_dir=${sort_dir}&sort=${sort}&limit=${limit}&cursor=${cursor}&property=${propertyGUID}`
  let managerParams = new URLSearchParams()
  manager.forEach((managerGUID) => managerParams.append('manager', managerGUID))
  return fetch(url + `&${managerParams}`, {
    credentials: 'include',
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }).append(
      'Content-Type',
      'text',
    ),
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({ statusCode: res.status, responseText: res.text() })
    })
    .then((advanceActivities) => {
      return { ok: true, advanceActivities }
    })
    .catch((error) => {
      return {
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }
    })
}

export const getPropertiesActivitiesReport = (teamGUID, options) => {
  return api.get(`/sm/teams/${teamGUID}/propertiesactivities`, {
    params: { ...options },
  })
}
