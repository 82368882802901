import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  getApplicationNotesWithLimit,
  getNotesWithLimit,
  getProgressApplication,
  postProgressApplication,
  postProgressApplicationAsApproved,
  postProgressApplicationAsLeased,
  postScheduleViewingFromApplication,
  sendUtilityReferralToLead,
} from 'app/sm/progress_application/access'

export const fetchProgressApplication =
  (applicationId = '') =>
  (dispatch) => {
    return getProgressApplication(applicationId).then(
      ({ ok, applicationInfo, responseText }) => {
        if (ok) {
          return Promise.resolve({ applicationInfo })
        } else {
          return responseText.then((t) => {
            return Promise.reject(t)
          })
        }
      },
    )
  }

export const updateProgressApplication = (
  applicationId = '',
  applicationInfo = {},
) => {
  return (dispatch) => {
    return postProgressApplication(applicationId, applicationInfo).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export const markProgressApplicationAsLeased = (
  applicationId = '',
  applicationInfo = {},
  markAsLeasedOptions = {},
) => {
  return (dispatch) => {
    return postProgressApplicationAsLeased(
      applicationId,
      applicationInfo,
      markAsLeasedOptions,
    ).then(({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText.then((error) => Promise.reject(error))
      }
    })
  }
}

export const markProgressApplicationAsApproved = (
  applicationId = '',
  applicationInfo = {},
  agencyGUID = '',
  markAsApplicationApprovedOptions = {},
) => {
  return (dispatch) => {
    return postProgressApplicationAsApproved(
      applicationId,
      applicationInfo,
      agencyGUID,
      markAsApplicationApprovedOptions,
    ).then(({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText.then((error) => Promise.reject(error))
      }
    })
  }
}

export const sendUtilityReferral = (applicationId = '', force = false) => {
  return (dispatch) => {
    return sendUtilityReferralToLead(applicationId, force).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const fetchNotesWithLimit = (ownerGUID, ownerType, notesLimit) => () => {
  return getNotesWithLimit(ownerGUID, ownerType, notesLimit).then(
    ({ responseData, ok, responseText }) => {
      if (ok) {
        return Promise.resolve(responseData)
      } else {
        return responseText.then((error) => {
          return Promise.reject(translateErrorCodeToMessage(error))
        })
      }
    },
  )
}

export const fetchApplicationNotesWithLimit =
  (applicationGUID, notesLimit) => () => {
    return getApplicationNotesWithLimit(applicationGUID, notesLimit).then(
      ({ responseData, ok, responseText }) => {
        if (ok) {
          return Promise.resolve(responseData)
        } else {
          return responseText.then((error) => {
            return Promise.reject(translateErrorCodeToMessage(error))
          })
        }
      },
    )
  }

export const sendScheduleViewingFromApplication =
  (applicationGUID, applicantGUID, viewingGUID) => () => {
    return postScheduleViewingFromApplication(
      applicationGUID,
      applicantGUID,
      viewingGUID,
    ).then(({ ok, responseText, data }) => {
      if (ok) {
        return Promise.resolve({ data })
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    })
  }
