import React from 'react'

import { Link } from 'react-router-dom'

import humanIcon from 'app/assets/icons/human01.jpg'
import * as validations from 'app/shared_components/validations'

function ProfileAccountForm() {
  return (
    <div className="two-col-box-flex-row sm64">
      <div className="col-first">
        {/* <button className="btn btn-picture" title="Edit image">MS</button> */}
        <button
          className="btn btn-picture"
          title="Edit image"
          style={{ backgroundImage: `url(${humanIcon})` }}
        ></button>

        <div className="text-center mb30">
          <div className="fs20  gray-dark-color fw700">Jon Johansen</div>
          <div className="fs16 gray-light-color pt10 pb20">
            johansenjon@gmail.com
          </div>
          <Link
            className="btn btn-small btn-blue wa"
            to="/bc/profile/change-password"
          >
            Reset Password
          </Link>
        </div>

        <h4 className="mb25">Contact Info</h4>

        <div className="row">
          <div className="col-xs-6">
            <div className="input-box">
              <input type="text" required defaultValue={'Jon'} />
              <label>First Name</label>
            </div>
          </div>
          <div className="col-xs-6">
            <div className="input-box">
              <input type="text" required defaultValue={'Johansen'} />
              <label>Last Name</label>
            </div>
          </div>
        </div>

        <div className="input-box">
          <input type="email" required defaultValue={'johansenjon@gmail.com'} />
          <label>Email Address</label>
        </div>

        <div className="input-box">
          <input type="text" required defaultValue={'0400 123 1245'} />
          <label>Mobile Phone Number</label>
        </div>

        <h4 className="mb25 mt50">Linked Accounts</h4>

        <Link className="table fw600 wsnw gray-dark-color tdn" to="/bc/profile">
          <div className="cell-m pr15 pb5">
            <i className="fa fa-facebook-square facebook-color"></i>
          </div>
          <div className="cell-m-100">Jon Johansen</div>
          <div className="cell-m pink-color">Unlink</div>
        </Link>

        <hr className="small" />

        <Link className="table fw600 wsnw gray-light-color" to="/bc/profile">
          <div className="cell-m pr15 pb5">
            <i className="fa fa-linkedin-square linkedin-color"></i>
          </div>
          <div className="cell-m-100">LinkedIn</div>
          <div className="cell-m gray-dark-color">
            <i className="icon-arrow-right"></i>
          </div>
        </Link>

        <hr className="small" />

        <Link className="table fw600 wsnw gray-light-color" to="/bc/profile">
          <div className="cell-m pr15 pb5">
            <i className="fa fa-twitter-square twitter-color"></i>
          </div>
          <div className="cell-m-100">Twitter</div>
          <div className="cell-m gray-dark-color">
            <i className="icon-arrow-right"></i>
          </div>
        </Link>

        <hr className="small" />

        <button className="mt25">Save</button>
      </div>
    </div>
  )
}

export default ProfileAccountForm
