import React from 'react'

import styled from 'styled-components'

import equifax from 'app/assets/icons/application-details/equifax.svg'
import DataUsageRounded from 'app/assets/icons/design-system-icons/device/DataUsageRounded'
import { Box, Flex } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import {
  AccountBalanceRounded,
  WorkOutlineRounded,
} from 'app/components/design-system-components/icons/actions'
import { PersonOutlineRounded } from 'app/components/design-system-components/icons/social'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

const StyledReport = styled(Box)`
  background-color: ${theme.colors.bgCheckGrey};
  color: ${theme.colors.skyBlue};
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.15));
  width: 325px;
  padding: ${theme.space[3]}px ${theme.space[5]}px;
`

const StyledVerificationHeader = styled(Flex)`
  svg {
    width: ${theme.width[17]}px;
    height: ${theme.height[13]}px;
    color: ${theme.colors.gray300};
  }
  line-height: ${theme.lineHeights.encrption};
  font-size: ${theme.fontSizes[4]}px;
  align-items: center;
`

const StyledHeading = styled.div`
  font-size: ${theme.fontSizes[7]}px;
  color: ${theme.colors.skyBlue};
  font-weight: ${theme.fontWeights[6]}px;
  margin-bottom: ${theme.space[3]}px;
  line-height: ${theme.lineHeights.copy};
`

export const idVerificationStatusToText = {
  [true]: 'Verified',
  [false]: 'Not Verified',
}

export const negativeStatusToText = {
  [false]: 'Not Found',
  [true]: 'Info',
}

export const statusToBadgeVariant = {
  [true]: 'success600',
  [false]: 'warning500',
}

export const negativeStatusToBadgeVariant = {
  [false]: 'success600',
  [true]: 'warning500',
}

function NewBGCheckReport({
  idVerified = false,
  presentOnNTD = false,
  bankruptcyOrCourtFound = false,
  asicFound = false,
  renterName = '',
  backgroundCheckDate = '',
  validityPeriod,
  excludeBGCBankruptcyReport = false,
}) {
  const bankrupcyAndCourtRecordsLabel = excludeBGCBankruptcyReport
    ? 'Court Records:'
    : 'Bankruptcy / Court Records:'
  return (
    <StyledReport mt={6}>
      <StyledHeading>Background Check Summary</StyledHeading>
      <Box>
        <Text
          fontWeight={theme.fontWeights[3]}
          fontSize={theme.fontSizes.pLarge16}
          color="gray600"
          ml={1}
          mb={3}
        >
          {renterName}
        </Text>
        <Flex alignItems="center" justifyContent="space-between" mb={3} mt={3}>
          <StyledVerificationHeader>
            <PersonOutlineRounded />
            <Box ml={4}>Identity Verification</Box>
          </StyledVerificationHeader>
          <Badge
            variant={statusToBadgeVariant[idVerified]}
            text={`${idVerificationStatusToText[idVerified]}`}
          />
        </Flex>

        <Flex alignItems="center" justifyContent="space-between" mb={3}>
          <StyledVerificationHeader>
            <DataUsageRounded />
            <Box ml={4}>National Tenancy Database</Box>
          </StyledVerificationHeader>
          <Badge
            variant={negativeStatusToBadgeVariant[presentOnNTD]}
            text={negativeStatusToText[presentOnNTD]}
          />
        </Flex>

        <Flex alignItems="center" justifyContent="space-between" mb={3}>
          <StyledVerificationHeader>
            <AccountBalanceRounded />
            <Box ml={4}>{bankrupcyAndCourtRecordsLabel}</Box>
          </StyledVerificationHeader>
          <Badge
            variant={negativeStatusToBadgeVariant[bankruptcyOrCourtFound]}
            text={negativeStatusToText[bankruptcyOrCourtFound]}
          />
        </Flex>

        <Flex alignItems="center" justifyContent="space-between" mb={3}>
          <StyledVerificationHeader>
            <WorkOutlineRounded />
            <Box ml={4}>ASIC Directorships</Box>
          </StyledVerificationHeader>
          <Badge
            variant={negativeStatusToBadgeVariant[asicFound]}
            text={negativeStatusToText[asicFound]}
          />
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" mt={4}>
          <Box>
            <img src={equifax} alt="equifax icon" height="12px" />
          </Box>
          <Flex alignItems="center">
            <Text
              as="span"
              fontWeight={theme.fontWeights[3]}
              fontSize={theme.fontSizes.pRegular14}
              color="gray600"
            >
              Valid:
            </Text>
            <Text
              as="span"
              fontWeight={theme.fontWeights[3]}
              fontSize={theme.fontSizes.pRegular14}
              color="gray600"
              ml={1}
            >
              {dateTimeHelpers.formatStdDateStamp(
                dateTimeHelpers.addMonthsToDate(backgroundCheckDate, 6),
              )}
            </Text>
            <Text
              as="span"
              fontWeight={theme.fontWeights[3]}
              fontSize={theme.fontSizes.pRegular14}
              color="gray400"
              ml={1}
            >
              ({validityPeriod} months)
            </Text>
          </Flex>
        </Flex>
        <Box mt={3}></Box>
      </Box>
    </StyledReport>
  )
}

export default NewBGCheckReport
