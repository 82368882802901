import React from 'react'

import styled from 'styled-components'
import { variant } from 'styled-system'

import Box from 'app/components/design-system-components/Box'

const StatusPillContainer = styled(Box)(
  variant({
    variants: {
      success: {
        color: 'success700',
        bg: 'success100',
      },
      warning: {
        color: 'warning700',
        bg: 'warning100',
      },
    },
  }),
  {
    padding: '4px 10px',
    borderRadius: '5px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '120%',
    textAlign: 'center',
  },
)

const StatusPill = ({ text, variant }) => {
  return <StatusPillContainer variant={variant}>{text}</StatusPillContainer>
}

export default StatusPill
