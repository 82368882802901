import {
  RECEIVE_RESPONSE_TEXT,
  receiveError,
} from 'app/bond_cover/bond_cover_actions'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import { history } from 'app/shared_components/router'

const BondCoverMiddleware = (store) => (next) => (action) => {
  let { type, responseText } = action
  let errorCode = parseInt(responseText)
  let errorMessage = ErrorMessages[errorCode]

  switch (type) {
    case RECEIVE_RESPONSE_TEXT:
      if (errorMessage) {
        store.dispatch(receiveError(errorMessage))
        return next(action)
      }
      if (isNaN(responseText)) {
        store.dispatch(receiveError(responseText))
        return next(action)
      }

      switch (errorCode) {
        case Errors.TooCloseToTheEndOfLeaseOrAlreadyEnded:
          history.push('/bc/status')
          return next(action)
        default:
          store.dispatch(
            receiveError('There was a problem. Error code: ' + responseText),
          )
          return next(action)
      }
    default:
      return next(action)
  }
}

export default BondCoverMiddleware
