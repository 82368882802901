import React from 'react'

import { Link } from 'react-router-dom'

import AccordionStrap from 'app/shared_components/accordion_strap'
import GreenStateTimings from 'app/shared_components/green_state_timings'
import getParameter from 'app/shared_components/parameter_parser'
import SocialShare from 'app/shared_components/social_share'

class EstimatedRefund extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bond: '',
      bondCoverAnnualFeeEstimate: '',
      bondCoverWeeklyFeeEstimate: '',
      bondCoverRefundEstimate: '',
      postCode: '',
      available: false,
      state: '',
      currentLease: false,
      identification: false,
      bankAccount: false,
      firstName: '',
      type: '',
    }
  }

  componentDidMount() {
    this.getDetails()
    this.checkAvailable()
    this.setBondCoverEstimate()
  }

  getDetails = () => {
    const queryRent = getParameter('rent')
    const queryPostCode = getParameter('postcode')
    const firstName = getParameter('name') ? getParameter('name') : ''
    const type = getParameter('type') ? getParameter('type') : 'refund'
    this.setState({
      bond: 4 * queryRent,
      postCode: queryPostCode,
      firstName: firstName,
      type: type,
    })
  }

  setBondCoverEstimate = () => {
    const { bond } = this.state
    const minimumFee = 35
    let estimatedAnnualFee = (bond * 0.074).toFixed(2)
    if (estimatedAnnualFee < minimumFee) {
      estimatedAnnualFee = minimumFee
    }
    const estimatedWeeklyFee = (estimatedAnnualFee / 52).toFixed(2)
    const estimatedRefund = (bond - estimatedAnnualFee).toFixed(2)

    this.setState({
      bondCoverAnnualFeeEstimate: estimatedAnnualFee,
      bondCoverWeeklyFeeEstimate: estimatedWeeklyFee,
      bondCoverRefundEstimate: estimatedRefund,
    })
  }

  checkAvailable() {
    const { postCode } = this.state

    switch (true) {
      // NSW 2000-2999, 2620-2899, 2921—2999
      case (postCode >= 2000 && postCode <= 2999) ||
        (postCode >= 2620 && postCode <= 2899) ||
        (postCode >= 2921 && postCode <= 2999):
        this.setState({ available: true, state: 'New South Wales' })
        break
      // ACT 2600—2619, 2900—2920
      case (postCode >= 2600 && postCode <= 2619) ||
        (postCode >= 2900 && postCode <= 2920):
        this.setState({
          available: true,
          state: 'Australian Capital Territory',
        })
        break
      // VIC 3000—3999
      case postCode >= 3000 && postCode <= 3999:
        this.setState({ available: true, state: 'Victoria' })
        break
      // QLD 4000—4999
      case postCode >= 4000 && postCode <= 4999:
        this.setState({ available: false, state: 'Queensland' })
        break
      // SA  5000—5799
      case postCode >= 5000 && postCode <= 5799:
        this.setState({ available: true, state: 'South Australia' })
        break
      // WA  6000—6797
      case postCode >= 6000 && postCode <= 6797:
        this.setState({ available: true, state: 'Western Australia' })
        break
      // TAS 7000—7799
      case postCode >= 7000 && postCode <= 7799:
        this.setState({ available: true, state: 'Tasmania' })
        break
      // NT  0800—0899
      case postCode >= 800 && postCode <= 899:
        this.setState({ available: false, state: 'Northern Territory' })
        break
      default:
        this.setState({ available: false })
    }
  }

  toggle(field) {
    return () => {
      const value = !this.state[field]
      this.setState({ [field]: value })
    }
  }

  render() {
    const {
      bond,
      available,
      state,
      firstName,
      type,
      bondCoverAnnualFeeEstimate,
      bondCoverRefundEstimate,
    } = this.state
    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <div className="pt20">
            <div className={available ? '' : 'hidden'}>
              {state && state === 'Victoria' ? (
                <div>
                  <h3 className="center mb20">
                    Great news{firstName !== '' && ` ${firstName}`}, you can
                    pre-apply for BondCover in Victoria.
                  </h3>
                  <p className="fs10">
                    Pending our discussions with the State Government, when
                    BondCover rolls out you could get:
                  </p>
                </div>
              ) : (
                <div>
                  <h3 className="center mb20">
                    Great news{firstName !== '' && ` ${firstName}`}, your
                    estimated refund is:
                  </h3>
                </div>
              )}
            </div>

            {!available && (
              <div>
                <h3 className="mb25">
                  Sorry, BondCover is not available in{' '}
                  {state && state === 'Northern Territory'
                    ? `the ${state}`
                    : state}{' '}
                  at the moment.
                </h3>
                <p className="fs16">
                  We&apos;re campaigning to get the State government to accept
                  it. Join the cause. When it rolls out, you could get:
                </p>
              </div>
            )}

            <div className="confetti-box">
              <div className="confetti-row">
                <p>Your bond{type === 'new' ? ' coverage:' : ':'}</p>
                <p>${Math.round(bond)}</p>
              </div>
              {type !== 'new' && (
                <div className="confetti-row">
                  <p>BondCover Fee:</p>
                  <p>${Math.round(bondCoverAnnualFeeEstimate)}</p>
                </div>
              )}
              <div className="confetti-row">
                <hr />
              </div>
              {type === 'new' ? (
                <div className="confetti-row">
                  <h3>Fee Estimate:</h3>
                  <h3>${Math.round(bondCoverAnnualFeeEstimate)}</h3>
                </div>
              ) : (
                <div className="confetti-row">
                  <h3>Your refund:</h3>
                  <h3>${Math.round(bondCoverRefundEstimate)}</h3>
                </div>
              )}
            </div>

            <p className="renewal-text mt25">
              Enjoy up to 60% discounts on annual renewals
            </p>

            <p className="fs12 mt25 mb10 gray-light-color">
              <b>Note:</b> you&apos;re still required to pay valid bond claims.
              See full terms for details. Applying takes 2 minutes with your
              current lease details.
            </p>

            <Link
              className={available ? 'btn mt25 mb15' : 'hidden'}
              to="register"
            >
              {state && state === 'Victoria'
                ? 'Sign Up & Pre-Apply Now'
                : 'Sign Up & Apply Now'}
            </Link>

            <p className="renewal-text">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://snug.com/bondcover/terms/"
              >
                *BondCover Terms &amp; Conditions
              </a>
            </p>

            <ul className="fs14 snug-tips mb50">
              <li>
                Our estimate assumes your bond is 4 weeks of your weekly rent
                and is based on a single tenant, 1 bedroom Sydney metro
                apartment that is owner managed and it may change based on your
                application details.
              </li>
            </ul>

            <SocialShare />

            <GreenStateTimings />

            {available && <AccordionStrap />}
          </div>
        </div>
      </div>
    )
  }
}

export default EstimatedRefund
