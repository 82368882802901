import React, { Component } from 'react'

import moment from 'moment'
import styled from 'styled-components'

import aluminumBagIcon from 'app/assets/icons/aluminum-bag.svg'
import atSymbolIcon from 'app/assets/icons/at-symbol.svg'
import briefCaseIcon from 'app/assets/icons/briefcase.svg'
import businessAreaIcon from 'app/assets/icons/business-area.svg'
import phoneIcon from 'app/assets/icons/phone.svg'
import userIcon from 'app/assets/icons/user.svg'
import walletIcon from 'app/assets/icons/wallet.svg'
import {
  Box,
  BoxInput,
  Flex,
  Select,
} from 'app/components/design-system-components'
import * as Layout from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import { ErrorBox } from 'app/components/forms/util'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import AddressForm from 'app/shared_components/address_form/address_form'
import isEmptyObject from 'app/shared_components/check_empty_object'
import { ErrorMessage } from 'app/shared_components/helpers'
import getParameter from 'app/shared_components/parameter_parser'
import Header from 'app/shared_components/rental_reputation_header'
import { history } from 'app/shared_components/router'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import {
  EmploymentTypeCollection,
  EmploymentVerifyStatus,
  isAustralianAddress,
  isEmploymentStatusVerified,
  isSelfEmployed,
  isSelfEmployedNoAccountant,
  shouldCurrencyUpdate,
  validateAddress,
  validateMinimumContactDetailProvided,
} from 'app/sm/helpers'
import * as helpers from 'app/sm/helpers'
import { isSouthAustralia } from 'app/utils/address/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import {
  MAX_ANNUAL_INCOME,
  MAX_FORTNIGHTLY_INCOME,
  MAX_MONTHLY_INCOME,
  MAX_WEEKLY_INCOME,
} from 'app/utils/numbers/helpers'
import {
  AUSTRALIAN_DOLLAR_PLACEHOLDER,
  EMPLOYMENT_CONFIRMATION_NOTICE_SOUTH_AUSTRALIA,
} from 'app/utils/text/helpers'

const isBeforeDate = (date1, date2) => {
  return moment(date1).isBefore(moment(date2))
}

const incomeTypeOptions = {
  weekly: { name: 'Weekly', id: 0 },
  fortnightly: { name: 'Fortnightly', id: 1 },
  monthly: { name: 'Calendar Monthly', id: 2 },
}

const EmpInputContainer = styled.div`
  width: 215px;
`

class Employment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        address2: '',
        suburb: '',
        state: '',
        postcode: '',
        country: 'AUS',
        lat: null,
        lng: null,
        friendlyName: '',
        googleId: '',
      },
      details: {
        EmployerName: {
          value: '',
          icon: businessAreaIcon,
          label: 'Employer (company) name',
        },
        EmployerTradingName: {
          value: '',
          icon: '',
          label: 'Trading Name',
        },
        EmployerABN: {
          value: '',
          icon: '',
          label: 'ABN',
        },
        JobTitle: {
          value: '',
          icon: briefCaseIcon,
          label: 'Job title',
        },
        EmploymentType: {
          value: '',
          icon: aluminumBagIcon,
          label: 'Employment type',
        },
        StillEmployed: {
          value: false,
          icon: '',
          label: "I'm currently employed here",
        },
        StartDate: {
          value: '',
          icon: '',
          label: 'Start Date',
        },
        EndDate: {
          value: '',
          icon: '',
          label: 'End Date',
        },
        IncomeType: {
          value: '',
          icon: '',
          label: 'Income Type',
        },
        WeeklyNetIncome: {
          value: '',
          icon: walletIcon,
          label: 'Weekly income after tax',
        },
        GrossAnnualIncome: {
          value: '',
          icon: walletIcon,
          label: 'Annual income before tax',
        },
        ContactName: {
          value: '',
          icon: userIcon,
          label: 'Contact name',
        },
        PhoneNumber: {
          value: '',
          icon: phoneIcon,
          label: 'Contact number',
        },
        ContactEmail: {
          value: '',
          icon: atSymbolIcon,
          label: 'Contact email',
        },
        OfficeNumber: {
          value: '',
          icon: phoneIcon,
          label: 'Office number',
        },
      },
      verifyStatus: EmploymentVerifyStatus.Pending,
      hasAccountant: false,
      unsavedChanges: false,
      errors: {
        ContactEmail: '',
        ContactName: '',
        EmployerName: '',
        EmployerTradingName: '',
        EmployerABN: '',
        EmploymentType: '',
        EndDate: '',
        GrossAnnualIncome: '',
        JobTitle: '',
        PhoneNumber: '',
        StartDate: '',
        StillEmployed: '',
        WeeklyNetIncome: '',
        IncomeType: '',
      },
      inputErrors: {},
      clicked: false,
      id: '',
      countryCode: 'au',
      officeCountryCode: 'au',
      apiError: '',
      removeButtonClicked: false,
      contactErrorMessage: '',
      requestEmploymentConfirmation: true,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props
    if (id !== undefined) {
      this.props.fetchEmployer(id)
    }

    if (isSouthAustralia(getParameter('addr_state'))) {
      this.setRequestEmploymentConfirmation(false)
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { employer } = newProps

    if (this.isViewMode()) {
      this.setState({ id: employer.guidID })
    }

    if (!isEmptyObject(employer)) {
      this.setState({
        details: this.deserialize(employer),
        address: employer.address,
        verifyStatus: employer.verifyStatus,
        hasAccountant: employer.hasAccountant,
      })
    }
  }

  onCountrySelected = (field) => {
    const numberError =
      field === 'PhoneNumber'
        ? [ErrorMessages[Errors.ErrorWhenConvertingMobileToInternationalFormat]]
        : ['Invalid landline number, e.g. 2 1234 5678']

    const errors = Object.assign({}, this.state.errors, {
      [field]: numberError,
    })
    this.setState({ errors })
  }

  validateIncomes = () => {
    const incomeOverflowErrMsg = 'Income is exceeding maximum limit'
    const errors = { netIncome: '', grossAnnualIncome: '' }
    const { WeeklyNetIncome, GrossAnnualIncome, IncomeType } =
      this.state.details
    const netIncomeValue = WeeklyNetIncome?.value
    switch (IncomeType.value) {
      case incomeTypeOptions.weekly.id:
        if (netIncomeValue > MAX_WEEKLY_INCOME) {
          errors.netIncome = incomeOverflowErrMsg
        }
        break
      case incomeTypeOptions.monthly.id:
        if (netIncomeValue > MAX_MONTHLY_INCOME) {
          errors.netIncome = incomeOverflowErrMsg
        }
        break
      case incomeTypeOptions.fortnightly.id:
        if (netIncomeValue > MAX_FORTNIGHTLY_INCOME) {
          errors.netIncome = incomeOverflowErrMsg
        }
    }
    if (GrossAnnualIncome?.value > MAX_ANNUAL_INCOME) {
      errors.grossAnnualIncome = incomeOverflowErrMsg
    }
    this.setState({
      errors: {
        ...this.state.errors,
        WeeklyNetIncome: errors.netIncome,
        GrossAnnualIncome: errors.grossAnnualIncome,
      },
    })
    return !errors.netIncome && !errors.grossAnnualIncome
  }

  onFormUpdate = (field) => {
    return (data) => {
      let value = data.value
      let error = data.error
      if (error === '' && (field === 'StartDate' || field === 'EndDate')) {
        value =
          data.value !== ''
            ? moment(data.value).format(helpers.dateWithDash)
            : ''
      }
      const { details } = this.state
      const currencyFields = ['WeeklyNetIncome', 'GrossAnnualIncome']
      if (!shouldCurrencyUpdate(currencyFields, field, data.value)) {
        return
      }
      if (field === 'WeeklyNetIncome' && data.value !== '') {
        this.setState({ contactErrorMessage: '' })
      }
      if (field === 'IncomeType' && data.value !== '') {
        this.setState({ contactErrorMessage: '' })
      }
      if (field === 'GrossAnnualIncome' && data.value !== '') {
        this.setState({ contactErrorMessage: '' })
      }
      if (field === 'EmploymentType' && isSelfEmployed(data.value)) {
        this.setState({ hasAccountant: true })
      }
      if (field === 'EmploymentType' && !isSelfEmployed(data.value)) {
        this.setState({ hasAccountant: false })
      }
      if (field === 'PhoneNumber' || field === 'ContactEmail') {
        this.setState({ contactErrorMessage: '' })
      }

      details[field].value = value
      this.setState({
        details,
        errors: {
          ...this.state.errors,
          [field]: error,
        },
      })
    }
  }

  onToggleHasAccountant = () => {
    const { details } = this.state
    this.setState(
      {
        hasAccountant: !this.state.hasAccountant,
      },
      () => {
        if (
          isSelfEmployed(details.EmploymentType.value) &&
          !this.state.hasAccountant
        ) {
          this.setState({
            errors: {
              ...this.state.errors,
              ContactName: '',
              PhoneNumber: '',
              ContactEmail: '',
            },
          })
        }
      },
    )
  }

  setRequestEmploymentConfirmation = (newValue) => {
    // newValue boolean
    this.setState({
      requestEmploymentConfirmation: newValue,
    })
  }

  setValidationErrors = (field, error, onBlur) => {
    const { errors, inputErrors } = this.state
    if (error.length === 0) {
      delete inputErrors[field]
      this.setState({ inputErrors })
      delete errors[field]
      this.setState({ errors })
    } else if (onBlur) {
      errors[field] = error
      this.setState({ errors })
    } else {
      inputErrors[field] = error
      this.setState({ inputErrors })
    }
  }

  deserialize(employer) {
    const { details } = this.state
    const endTime = !employer.stillEmployed
      ? Object.assign({}, details.EndDate, { value: employer.endDate })
      : Object.assign({}, details.EndDate, { value: '' })

    return {
      EmployerName: Object.assign({}, details.EmployerName, {
        value: employer.employerName,
      }),
      EmployerTradingName: Object.assign({}, details.EmployerTradingName, {
        value: employer.employerTradingName,
      }),
      EmployerABN: Object.assign({}, details.EmployerABN, {
        value: employer.employerABN,
      }),
      JobTitle: Object.assign({}, details.JobTitle, {
        value: employer.jobTitle,
      }),
      EmploymentType: Object.assign({}, details.EmploymentType, {
        value: employer.employmentType,
      }),
      StillEmployed: Object.assign({}, details.StillEmployed, {
        value: employer.stillEmployed,
      }),
      StartDate: Object.assign({}, details.StartDate, {
        value: employer.startDate,
      }),
      EndDate: endTime,
      WeeklyNetIncome: Object.assign({}, details.WeeklyNetIncome, {
        value: employer.weeklyNetIncome,
      }),
      IncomeType: Object.assign({}, details.IncomeType, {
        value: employer.incomeType,
      }),
      GrossAnnualIncome: Object.assign({}, details.GrossAnnualIncome, {
        value: employer.grossAnnualIncome,
      }),
      ContactName: Object.assign({}, details.ContactName, {
        value: employer.contactName || '',
      }),
      PhoneNumber: Object.assign({}, details.PhoneNumber, {
        value: employer.phoneNumber || '',
      }),
      ContactEmail: Object.assign({}, details.ContactEmail, {
        value: employer.email || '',
      }),
    }
  }

  isCreate() {
    const { employer } = this.props
    return isEmptyObject(employer)
  }

  isViewMode() {
    const { params, path } = this.props.match
    return !(params.id === undefined && !/\/profile\/employment\/.+/.test(path))
  }

  nextAction = () => {
    const { guidID: id } = this.props.employer || {}
    this.validate('address', true)
    if (!this.validateRequiredFields() || !this.validateForm()) {
      return
    }
    if (this.isCreate()) {
      this.setState({ clicked: true })
      this.props.addEmployer(
        this.serialize(),
        () => {
          this.setState({
            clicked: false,
            apiError: '',
          })
        },
        (responseText) => {
          this.setState({
            clicked: false,
            apiError: responseText,
          })
        },
      )
      this.setState({ unsavedChanges: false })
    } else {
      this.setState({ clicked: true })
      this.props.updateEmployer(this.serialize(), id, null, (responseText) => {
        this.setState({
          apiError: responseText,
          clicked: false,
        })
      })
      this.setState({ unsavedChanges: false })
    }
  }

  removeEmployer = () => {
    const { removeEmployer } = this.props
    const { id } = this.state

    removeEmployer(
      id,
      () => {
        this.setState({
          removeButtonClicked: true,
          apiError: '',
        })
      },
      (responseText) => {
        this.setState({
          removeButtonClicked: false,
          apiError: responseText,
        })
      },
    )
  }

  resetAddress() {
    this.setState({
      address: {
        friendlyName: '',
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        address2: '',
        suburb: '',
        state: '',
        postcode: '',
        country: 'Australia',
        lat: null,
        lng: null,
        googleId: '',
      },
    })
  }

  serialize() {
    const { details, countryCode, officeCountryCode, address, hasAccountant } =
      this.state

    let { requestEmploymentConfirmation } = this.state

    if (
      helpers.isSelfEmployed(details.EmploymentType.value) &&
      !hasAccountant
    ) {
      requestEmploymentConfirmation = false
    }

    return {
      address,
      employerName: details.EmployerName.value.trim(),
      employerTradingName: details.EmployerTradingName.value.trim(),
      employerABN: details.EmployerABN.value.trim(),
      jobTitle: details.JobTitle.value.trim(),
      employmentType: parseInt(details.EmploymentType.value, 10),
      phoneNumber: details.PhoneNumber.value.replace(/\s/g, ''),
      weeklyNetIncome: parseFloat(details.WeeklyNetIncome.value),
      incomeType: parseFloat(details.IncomeType.value),
      grossAnnualIncome: parseFloat(details.GrossAnnualIncome.value),
      contactName: details.ContactName.value.trim(),
      email: details.ContactEmail.value.trim(),
      countryCode: countryCode && countryCode.toUpperCase(),
      officeCountryCode: officeCountryCode && officeCountryCode.toUpperCase(),
      StillEmployed: details.StillEmployed.value,
      startDate: details.StartDate.value,
      endDate: details.StillEmployed.value ? '' : details.EndDate.value,
      hasAccountant,
      requestEmploymentConfirmation,
    }
  }

  updateAddress = (field) => {
    return (event) => {
      const { address } = this.state
      address[field] = event.target.value
      this.setState({ address, unsavedChanges: true })
    }
  }

  updateGoogleAddress = (address) => {
    this.setState(
      { address, unsavedChanges: true },
      this.validate('address', true),
    )
  }

  validate = (field, onBlur) => {
    return () => {
      switch (field) {
        case 'address': {
          const addressValidation = validateAddress(this.state.address)
          return this.setValidationErrors(
            field,
            !addressValidation.ok
              ? [ErrorMessages[addressValidation.errorCode]]
              : [],
            onBlur,
          )
        }
        default:
          return true
      }
    }
  }

  validateForm() {
    const errors = []
    Object.keys(this.state.errors)
      .filter(
        (field) =>
          field !== 'EndDate' || !!this.state.details.StillEmployed.value,
      )
      .filter((field) => field !== 'address')
      .filter(
        (field) =>
          field !== 'ContactName' ||
          (this.state.hasAccountant &&
            isSelfEmployed(this.state.details.EmploymentType.value)) ||
          !isSelfEmployed(this.state.details.EmploymentType.value),
      )
      .filter(
        (field) =>
          field !== 'ContactEmail' &&
          field !== 'OfficeNumber' &&
          field !== 'PhoneNumber',
      )
      .map((field) => {
        const error = this.state.errors[field]
        return error && errors.push(error)
      })
    return errors.length === 0
  }

  validateRequiredFields() {
    const errors = {}
    if (
      !isSelfEmployedNoAccountant(
        this.state.details.EmploymentType.value,
        this.state.hasAccountant,
      )
    ) {
      if (!this.validateContactDetails()) return false
    }

    const { WeeklyNetIncome, GrossAnnualIncome, IncomeType } =
      this.state.details
    if (
      WeeklyNetIncome.value === '' ||
      GrossAnnualIncome.value === '' ||
      IncomeType.value === ''
    ) {
      const contactErrorMessage = 'Please fill in all Income fields'
      this.setState({
        contactErrorMessage,
      })
      return false
    }

    const isValid = this.validateIncomes()
    if (!isValid) return false

    Object.keys(this.state.errors)
      .filter(
        (field) =>
          field !== 'EndDate' || !this.state.details.StillEmployed.value,
      )
      .filter((field) => field !== 'address')
      .filter(
        (field) =>
          field !== 'ContactName' ||
          (this.state.hasAccountant &&
            isSelfEmployed(this.state.details.EmploymentType.value)) ||
          !isSelfEmployed(this.state.details.EmploymentType.value),
      )
      .filter(
        (field) =>
          field !== 'ContactEmail' &&
          field !== 'OfficeNumber' &&
          field !== 'PhoneNumber',
      )
      .filter((field) =>
        !isSelfEmployed(this.state.details.EmploymentType.value)
          ? field !== 'EmployerTradingName' && field !== 'EmployerABN'
          : true,
      )
      .map((field) => {
        if (this.state.details[field].value === '') {
          errors[field] = 'This field is required'
        }
        return ''
      })
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    })
    return Object.keys(errors).length === 0
  }

  validateContactDetails() {
    const { details } = this.state
    const { PhoneNumber, ContactEmail, StillEmployed } = details
    let errorMsg

    if (StillEmployed?.value) {
      // require full details for current employment
      if (!PhoneNumber.value || !ContactEmail.value) {
        errorMsg = 'Please provide contact number and email address.'
      }
    } else {
      errorMsg = validateMinimumContactDetailProvided([
        PhoneNumber.value,
        ContactEmail.value,
      ])
    }

    this.setState({
      contactErrorMessage: errorMsg,
    })
    return !errorMsg
  }

  renderBusinessNumberInput() {
    const { address, details, errors } = this.state
    return isAustralianAddress(address) ? (
      <Form.InputABN
        value={details.EmployerABN.value}
        error={errors.EmployerABN}
        label="ABN"
        placeholder="e.g. 51 824 753 556"
        onChange={this.onFormUpdate('EmployerABN')}
        labelClass="top20"
        inputClass="width100"
        componentClass="margin-profile-item-right"
        isOptional
      />
    ) : (
      <Form.InputTextRequired
        value={details.EmployerABN.value}
        error={errors.EmployerABN}
        label="Business registration number"
        placeholder="Business registration number"
        onChange={this.onFormUpdate('EmployerABN')}
        labelClass="top20"
        inputClass="width100"
        componentClass="margin-profile-item-right"
        isOptional
      />
    )
  }

  render() {
    const {
      details,
      unsavedChanges,
      errors,
      inputErrors,
      verifyStatus,
      removeButtonClicked,
      apiError,
      clicked,
      address,
      hasAccountant,
      contactErrorMessage,
      requestEmploymentConfirmation,
    } = this.state
    const { StartDate = {}, EndDate = {}, StillEmployed = {} } = details || {}

    const dates = StillEmployed.value
      ? StartDate.value !== ''
      : StartDate.value &&
        EndDate.value !== '' &&
        isBeforeDate(StartDate.value, EndDate.value)
    const disabled =
      !(
        details.EmployerName.value &&
        details.JobTitle.value &&
        details.EmploymentType.value &&
        details.WeeklyNetIncome.value &&
        details.IncomeType.value !== 0 &&
        details.GrossAnnualIncome.value &&
        details.ContactName.value &&
        details.EmploymentType.value !== 0 &&
        !!dates &&
        !!isEmptyObject(inputErrors)
      ) || !validateAddress(address).ok

    const inputDisabled = this.isViewMode()
    const formattedStartDate =
      this.state.errors.StartDate !== dateTimeHelpers.INVALID_DATE_STRING
        ? details.StartDate.value &&
          moment(details.StartDate.value).format(
            dateTimeHelpers.DATE_WITH_SLASH,
          )
        : details.StartDate.value
    const formattedEndDate =
      this.state.errors.EndDate !== dateTimeHelpers.INVALID_DATE_STRING
        ? details.EndDate.value &&
          moment(details.EndDate.value).format(dateTimeHelpers.DATE_WITH_SLASH)
        : details.EndDate.value

    const contactLabel = hasAccountant ? 'Accountant' : 'Employer'

    const showOptionToNotRequestEmploymentConfirmation = isSouthAustralia(
      getParameter('addr_state'),
    )

    const showEmployerContactForm = !isSelfEmployedNoAccountant(
      details.EmploymentType.value,
      hasAccountant,
    )

    return (
      <div className="two-col-box sm64">
        <div className="col-first xs-second">
          <div className="employment-container">
            <div className="mobile-only">
              <button
                onClick={() => history.goBack()}
                className="btn btn-transparent btn-left xs-text-center wa"
              >
                <i className="icon-arrow-left left" />
                <span>Back</span>
              </button>
            </div>

            <Header
              title="Add employment details"
              message={
                'Add employment details to demonstrate rental affordability. We’ll send an Employment Confirmation request to your employer. ' +
                EMPLOYMENT_CONFIRMATION_NOTICE_SOUTH_AUSTRALIA
              }
            />

            <div className="row mobile-form-button pl20 pr20">
              <div className="employment-content pl0 pr0">
                <div className="input-box">
                  <Box>
                    <Layout.ContainerHeader
                      text="Current or recent employer"
                      isEditable={false}
                      componentClass="mt10"
                    />
                    <Form.ProfilePagesRowLayout containerClass="width-100 ml0">
                      <div className="col-md-6 p0 width-100">
                        <Form.InputTextRequired
                          value={details.EmployerName.value}
                          error={errors.EmployerName}
                          label="Company Name"
                          placeholder="eg. ABC Pty Ltd"
                          onChange={this.onFormUpdate('EmployerName')}
                          labelClass="top20"
                          inputClass="width100"
                          componentClass="margin-profile-item-left"
                          isOptional
                        />
                      </div>
                    </Form.ProfilePagesRowLayout>
                  </Box>
                </div>
                <div
                  className={
                    errors.address && disabled
                      ? 'alert alert-danger'
                      : 'hide-alert'
                  }
                >
                  <div>{errors.address}</div>
                </div>

                <div>
                  <Layout.ContainerHeader
                    text="What was your role?"
                    isEditable={false}
                    componentClass="mt10"
                  />
                  <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                    <div className="col-md-6 p0 width100">
                      <Form.InputTextRequired
                        value={details.JobTitle.value}
                        error={errors.JobTitle}
                        label="Job Title"
                        onChange={this.onFormUpdate('JobTitle')}
                        labelClass="top20"
                        inputClass="width100"
                        componentClass="margin-profile-item-left"
                      />
                    </div>
                    <div className="col-md-6 p0 width100">
                      <Form.Dropdown
                        label="Job Type"
                        value={details.EmploymentType.value}
                        error={errors.EmploymentType}
                        options={EmploymentTypeCollection}
                        onChange={this.onFormUpdate('EmploymentType')}
                        inputClass="width100 pt0"
                        componentClass="margin-profile-item-right"
                      />
                    </div>
                  </Form.ProfilePagesRowLayout>
                  {isSelfEmployed(details.EmploymentType.value) && (
                    <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                      <div className="col-md-6 p0 width100">
                        <Form.InputTextOptional
                          value={details.EmployerTradingName.value}
                          error={errors.EmployerTradingName}
                          label="Trading Name"
                          placeholder="eg. Jay's Plumbing"
                          onChange={this.onFormUpdate('EmployerTradingName')}
                          labelClass="top20"
                          inputClass="width100"
                          componentClass="margin-profile-item-left"
                          isOptional
                        />
                      </div>
                      <div className="col-md-6 p0 width100">
                        {this.renderBusinessNumberInput()}
                      </div>
                    </Form.ProfilePagesRowLayout>
                  )}
                  <Form.CheckBoxGeneral
                    label={details.StillEmployed.label}
                    onChange={this.onFormUpdate('StillEmployed')}
                    checked={details.StillEmployed.value}
                    componentClassName="mb20"
                  />
                  <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                    <div className="col-md-6 p0 width100">
                      <Form.InputPastDate
                        label="Start Date"
                        value={formattedStartDate}
                        error={errors.StartDate}
                        onChange={this.onFormUpdate('StartDate')}
                        containerClassName="width100"
                        componentClass="margin-profile-item-left"
                        viewMode="months"
                        customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                        readOnly={false}
                      />
                    </div>
                    <div className="col-md-6 p0 width100">
                      {!details.StillEmployed.value && (
                        <Form.InputPastDate
                          label="End Date"
                          value={formattedEndDate}
                          error={errors.EndDate}
                          onChange={this.onFormUpdate('EndDate')}
                          containerClassName="width100"
                          componentClass="margin-profile-item-right"
                          viewMode="months"
                          customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                          readOnly={false}
                        />
                      )}
                    </div>
                  </Form.ProfilePagesRowLayout>
                  <Layout.ContainerHeader
                    text="Income"
                    isEditable={false}
                    componentClass="mt10"
                  />
                  <Form.ProfilePagesRowLayout>
                    <Layout.GreySubheading
                      text={`Please enter your average income (Australian Dollars), evidenced by your bank statement and payslips for this employer.
                      Note, any Centrelink/Govt benefits are entered on the next page.`}
                      textClass="font-size-18"
                      componentClass="mt10"
                    />
                    <Flex alignItems="center" mb="16px">
                      <EmpInputContainer>
                        <Flex width="100%" flex="1">
                          <Box mt="12px" mr="8px">
                            $
                          </Box>
                          <BoxInput
                            onChange={this.onFormUpdate('WeeklyNetIncome')}
                            p="2"
                            handleAsValueAndError={true}
                            initialValue={details.WeeklyNetIncome.value}
                            placeholder={AUSTRALIAN_DOLLAR_PLACEHOLDER}
                          />
                        </Flex>
                        {/*using legacy error component for consistency with other legacy input fields*/}
                        <ErrorBox
                          error={errors.WeeklyNetIncome}
                          showError={!!errors.WeeklyNetIncome}
                        />
                      </EmpInputContainer>
                      <div className="ml10 mr10"> received</div>
                      <div className="">
                        <Flex width="100%" flex="2" height="50px">
                          <Select
                            icon=""
                            onSelectChange={this.onFormUpdate('IncomeType')}
                            options={Object.values(incomeTypeOptions)}
                            value={details.IncomeType.value}
                            label="Select period"
                            error={errors.IncomeType}
                          />
                        </Flex>
                      </div>
                      <div className=" ml10"> AFTER tax</div>
                    </Flex>
                    <Flex alignItems="center">
                      <EmpInputContainer>
                        <Flex alignItems="center">
                          <Box mr="8px">$</Box>
                          <BoxInput
                            onChange={this.onFormUpdate('GrossAnnualIncome')}
                            p="2"
                            handleAsValueAndError={true}
                            initialValue={details.GrossAnnualIncome.value}
                          />
                        </Flex>
                        {/*using legacy error component for consistency with other legacy input fields*/}
                        <ErrorBox
                          error={errors.GrossAnnualIncome}
                          showError={!!errors.GrossAnnualIncome}
                        />
                      </EmpInputContainer>
                      <div className=" ml10">
                        {' '}
                        Annual Gross (BEFORE Tax) eg. 60000
                      </div>
                    </Flex>
                  </Form.ProfilePagesRowLayout>
                  {isSelfEmployed(details.EmploymentType.value) && (
                    <Box mt={8} mb={7}>
                      <Form.CheckBoxGeneral
                        checked={hasAccountant}
                        label="I have an accountant"
                        eventProcessedByComponent
                        onChange={this.onToggleHasAccountant}
                      />
                    </Box>
                  )}
                  {showEmployerContactForm && (
                    <Box mt={8} mb={8}>
                      <Layout.ContainerHeader
                        text={contactLabel + ' contact details'}
                        isEditable={false}
                        componentClass="pt10"
                      />
                      <Layout.GreySubheading
                        text={
                          `Please provide your ${contactLabel.toLowerCase()} contact details. ` +
                          'Snug will send a request to the contact provided to confirm employment details for the property manager. ' +
                          EMPLOYMENT_CONFIRMATION_NOTICE_SOUTH_AUSTRALIA
                        }
                        textClass="font-size-18"
                        componentClass="mt10"
                      />
                      {showOptionToNotRequestEmploymentConfirmation && (
                        <Box mt={7} mb={7}>
                          <Form.CheckBoxGeneral
                            checked={requestEmploymentConfirmation}
                            label={'Request employment confirmation'}
                            eventProcessedByComponent
                            onChange={() =>
                              this.setRequestEmploymentConfirmation(
                                !requestEmploymentConfirmation,
                              )
                            }
                          />
                        </Box>
                      )}
                      <div>
                        <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                          <div className="col-md-6 p0 width100">
                            <Form.InputName
                              value={details.ContactName.value}
                              error={errors.ContactName}
                              label="Contact Name"
                              onChange={this.onFormUpdate('ContactName')}
                              labelClass="top20"
                              inputClass="width100"
                              componentClass="margin-profile-item-left"
                            />
                          </div>
                          <div className="col-md-6 p0 width100">
                            <Form.InputEmail
                              label="Email"
                              value={details.ContactEmail.value}
                              error={errors.ContactEmail}
                              onChange={this.onFormUpdate('ContactEmail')}
                              inputClass="width100"
                              componentClass="margin-profile-item-right"
                              isOptional
                            />
                          </div>
                        </Form.ProfilePagesRowLayout>
                        <Form.ProfilePagesRowLayout>
                          <div className="col-md-6 p0 width50">
                            <Form.InputPhoneNumber
                              label="Phone Number"
                              value={details.PhoneNumber.value}
                              error={errors.PhoneNumber}
                              onChange={this.onFormUpdate('PhoneNumber')}
                              labelClass="top20"
                              containerClassName="width100"
                              componentClass="margin-profile-item-left"
                            />
                          </div>
                        </Form.ProfilePagesRowLayout>
                      </div>
                    </Box>
                  )}
                </div>
              </div>

              <Box>
                <Layout.ContainerHeader
                  text="Enter employer address"
                  isEditable={false}
                  componentClass="mt10"
                />
                <AddressForm
                  address={address}
                  updateAddress={this.updateAddress}
                  updateGoogleAddress={this.updateGoogleAddress}
                  resetAddress={this.resetAddress}
                  error={errors.address}
                  disabled={disabled}
                  inputDisabled={inputDisabled}
                  onBlur={this.validate('address', true)}
                  country={[]}
                  showManualAddressFirst={true}
                  showSuggestAddress={false}
                />
              </Box>

              <ErrorMessage error={apiError || contactErrorMessage} />

              <Form.ProfilePagesBottomButtons
                clickNextFunction={this.nextAction}
                clickNextText={this.isCreate() ? 'Add' : 'Save'}
                clicked={clicked}
                buttonClass="employment-history-added"
              />

              {this.isViewMode() && !isEmploymentStatusVerified(verifyStatus) && (
                <div className="row">
                  <div className="col-sm-12">
                    <div
                      className={`gray-text linkStyle text-center mt20 pr15 ${
                        removeButtonClicked && 'disabled'
                      }`}
                      onClick={this.removeEmployer}
                      role="button"
                      tabIndex={0}
                    >
                      Remove
                    </div>
                  </div>
                </div>
              )}

              <UnsavedMessage unsavedChanges={unsavedChanges} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Employment
