import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Flex } from 'app/components/design-system-components'
import SwitchButton from 'app/components/design-system-components/inputs/Switch'

interface IEnquiryResponderToggleProps {
  onChange: Function
  value: boolean
  disabled: boolean
  buttonID: string
  isUserAdmin: boolean
}

function EnquiryResponderToggle({
  onChange,
  value,
  disabled,
  buttonID,
  isUserAdmin,
}: IEnquiryResponderToggleProps) {
  return (
    <Flex data-tooltip-content="" data-tooltip-id="autoresponderSwitchTooltip">
      <SwitchButton
        onChange={onChange}
        value={value}
        disabled={disabled}
        buttonID={buttonID}
      />
      {!isUserAdmin && (
        <ReactTooltip id="autoresponderSwitchTooltip">
          <span>Contact your team admin to change setting</span>
        </ReactTooltip>
      )}
    </Flex>
  )
}

export default EnquiryResponderToggle
