import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import coloredWarningIcon from 'app/assets/icons/colored-warning.svg'
import greenPlus from 'app/assets/icons/green-plus.png'
import greenTick from 'app/assets/icons/green-tick.png'
import redPlus from 'app/assets/icons/red-plus.png'
import shieldTickGreen from 'app/assets/icons/shield-tick-green.svg'
import yellowPlus from 'app/assets/icons/yellow-plus.svg'
import {
  Box,
  Flex,
  IconBox,
  StatusDots,
} from 'app/components/design-system-components'
import StackedInfo from 'app/components/design-system-components/StackedInfo'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { isLeadSource } from 'app/pages/teams/messages/helpers/helpers'
import { getApplicationCompletenessConfig } from 'app/utils/applications/completeness-statuses'
import * as dateTime from 'app/utils/datetime/helpers'
import * as phoneNumberHelpers from 'app/utils/phonenumber/helpers'
import * as stringHelpers from 'app/utils/strings/helpers'
import * as vaccinationHelpers from 'app/utils/vaccination/helpers'

const StyledPhoneAndStatus = styled(Flex)`
  img {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 5px;
    align-items: center;
  }
`

const StyledStackedInfo = styled(Flex)`
  min-width: 165px;
`

function EnquirerCell({
  messageGUID,
  firstName,
  lastName,
  phone,
  snugUserVerifiedAt,
  snugBackgroundCheckedAt,
  messageSource,
  email,
  createdAt,
  covidAlerts = {},
  applicationDetails,
  covid19VaccinationStatus,
}) {
  const shieldID = `shield${messageGUID}`
  const mobileVerifiedID = `mobileVerifiedID${messageGUID}`
  const mobileVerifiedText = `Mobile Verified ${dateTime.formatStdTimeStamp(
    snugUserVerifiedAt,
  )}`

  const firstLine = <Box color="#333">{`${firstName} ${lastName}`} </Box>
  const displayMsgSSource = isLeadSource(messageSource) ? 'Lead' : messageSource
  const secondLine = `${dateTime.formatStdDateStamp(createdAt)}${
    displayMsgSSource ? `, ${displayMsgSSource}` : ''
  }`
  const formattedMobileNumber = phoneNumberHelpers.formatPhoneNumber(phone)
  const thirdLine = (
    <div>
      {phone && (
        <StyledPhoneAndStatus className="is-link">
          <a href={`tel:${formattedMobileNumber}`}>{formattedMobileNumber}</a>
          {!!snugUserVerifiedAt && (
            <>
              <ReactTooltip id={mobileVerifiedID}>
                <span>{mobileVerifiedText}</span>
              </ReactTooltip>
              <img
                src={greenTick}
                data-tooltip-content=""
                data-tooltip-id={mobileVerifiedID}
                alt="mobile verified"
              />
            </>
          )}
        </StyledPhoneAndStatus>
      )}
      <div className="is-email">
        <a href={`mailto:${email}`}>
          {stringHelpers.trimAndAddEllipsesPastSpecifiedLength(email, 25)}
        </a>
      </div>
    </div>
  )
  const showBackgroundCheckShield = !!snugBackgroundCheckedAt
  const backgroundCheckIcon = shieldTickGreen
  const backgroundCheckToolTipText = `Background Check verified ${dateTime.formatStdTimeStamp(
    snugBackgroundCheckedAt,
  )}`

  const renderCovidAlert = () => {
    const { message: covidAlertMessage } = covidAlerts
    const covidWarningId = `covid-warning-${messageGUID}`

    return (
      covidAlertMessage && (
        <>
          <IconBox size={20} mt={theme.space[1] + 'px'}>
            <img
              src={coloredWarningIcon}
              alt="covid warning icon"
              width="20px"
              height="20px"
              data-tooltip-content=""
              data-tooltip-id={covidWarningId}
            />
          </IconBox>
          <ReactTooltip id={covidWarningId}>
            <span>{covidAlertMessage}</span>
          </ReactTooltip>
        </>
      )
    )
  }

  const renderCovid19VaccinationStatusTooltip = () => {
    if (
      !covid19VaccinationStatus?.length ||
      ![
        vaccinationHelpers.FULLY_VACCINATED_STATUS,
        vaccinationHelpers.NOT_VACCINATED_STATUS,
        vaccinationHelpers.PREFER_NOT_TO_ANSWER_VACCINATED_STATUS,
      ].includes(covid19VaccinationStatus)
    ) {
      return
    }

    const config = {
      [vaccinationHelpers.FULLY_VACCINATED_STATUS]: {
        img: greenPlus,
        text: 'Self declared, fully vaccinated',
      },
      [vaccinationHelpers.NOT_VACCINATED_STATUS]: {
        img: redPlus,
        text: 'Self declared, not vaccinated',
      },
      [vaccinationHelpers.PREFER_NOT_TO_ANSWER_VACCINATED_STATUS]: {
        img: yellowPlus,
        text: 'Self declared, preferred not to answer',
      },
    }[covid19VaccinationStatus]

    const id = Math.ceil(Math.random()) * 1000000

    return (
      <div>
        <IconBox size={20} mt="2px">
          <img
            src={config.img}
            alt="covid warning icon"
            width="20px"
            height="20px"
            data-tooltip-content={config.text}
            data-tooltip-id={`full-vaccination-${id}`}
          />
        </IconBox>
        <ReactTooltip id={`full-vaccination-${id}`} />
      </div>
    )
  }

  const completenessConfig =
    applicationDetails &&
    getApplicationCompletenessConfig(applicationDetails).map(
      ({ label, variant, message }) => ({
        id: label,
        labelText: label,
        hoverHint: message,
        variant,
      }),
    )

  const covidAlertElem = renderCovidAlert()

  const covid19VaccinationStatusElem = renderCovid19VaccinationStatusTooltip()

  return (
    <Flex flexDirection="row">
      <Box>
        <StyledStackedInfo>
          <StackedInfo
            firstLine={firstLine}
            secondLine={secondLine}
            thirdLine={thirdLine}
            style={{ minWidth: '50px' }}
          />
        </StyledStackedInfo>
        {applicationDetails && (
          <StatusDots
            mt={theme.space[4] + 'px'}
            pillConfigs={completenessConfig}
          />
        )}
      </Box>
      <Flex flexDirection="column">
        {covid19VaccinationStatusElem}

        {covidAlertElem}

        {showBackgroundCheckShield && (
          <>
            <ReactTooltip id={shieldID}>
              <span>{backgroundCheckToolTipText}</span>
            </ReactTooltip>
            <IconBox width="20px" height="20px" mt="5px">
              <img
                src={backgroundCheckIcon}
                alt="not verified"
                width="20px"
                height="20px"
                data-tooltip-content=""
                data-tooltip-id={shieldID}
              />
            </IconBox>
          </>
        )}
      </Flex>{' '}
    </Flex>
  )
}

export default EnquirerCell
