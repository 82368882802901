import React from 'react'

import * as Display from 'app/components/display/display'

const ViewingContactSearchSection = ({
  nameSearchText,
  mobileSearchText,
  onSearchTextChange,
  onAddClicked,
  isNotAssignTeamMember = true,
}) => {
  return (
    <div className="display-flex align-items-center">
      <div className="flex-1">
        <Display.GeneralSearchBox
          placeholder="Name"
          value={nameSearchText}
          onChange={onSearchTextChange('nameSearchText')}
          iconType="user"
        />
        <Display.GeneralSearchBox
          placeholder="Mobile"
          value={mobileSearchText}
          onChange={onSearchTextChange('mobileSearchText')}
          iconType="phone"
          inputType="tel"
        />
      </div>
      {isNotAssignTeamMember && (
        <Display.GenericButton
          componentClass="min-100px"
          text="Add"
          onClick={() => onAddClicked(nameSearchText, mobileSearchText)}
        />
      )}
    </div>
  )
}

export default ViewingContactSearchSection
