import React from 'react'

import styled from 'styled-components'

const Holder = styled.div`
  overflow: hidden;
  width: 100%;
`

function ApplicationHolder(props) {
  return <Holder>{props.children}</Holder>
}

export default ApplicationHolder
