import moment from 'moment'
import DateTime from 'react-datetime'
import styled from 'styled-components'

import { Box, Dropdown, Flex } from 'app/components/design-system-components'
import { ArrowDropDownRounded } from 'app/components/design-system-components/icons/navigation'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  DropdownBodyWrapper,
  StyledDropdownButton,
} from 'app/sm/team_viewing_preference/states_dropdown'
import { DATE_WITH_DASH } from 'app/utils/datetime/helpers'

const StyledDatePicker = styled(DateTime)`
  .pickerInput {
    display: none !important;
  }
`

const DateDropdown = ({
  date,
  labelRenderer,
  onDateChangedHandler,
  width = '202px',
  height = '50px',
  disable = false,
  format = DATE_WITH_DASH,
  closeOnChange,
  mr = 4,
  ...props
}) => {
  return (
    <Dropdown
      mr={mr}
      className="dateDropdown"
      triggerWrapperComponent={({ dropdownControls }) => (
        <StyledDropdownButton
          className="dateDropdownButton"
          variant="outline"
          onClick={() => dropdownControls.toggle()}
          width={width}
          height={height}
        >
          <Flex justifyContent="space-between">
            <Box>{labelRenderer(date)}</Box>
            <Box
              as={ArrowDropDownRounded}
              ml={theme.space[3] + 'px'}
              fontSize={theme.fontSizes.pExtraLarge18}
            />
          </Flex>
        </StyledDropdownButton>
      )}
      dropdownComponent={({ dropdownControls }) => (
        <DropdownBodyWrapper>
          <StyledDatePicker
            open={true}
            dateFormat={format}
            timeFormat={false}
            inputProps={{
              placeholder: '',
              className: `pickerInput`,
              readOnly: disable,
            }}
            onChange={(date) => {
              onDateChangedHandler(moment(date, format).format(format))
              closeOnChange && dropdownControls.close()
            }}
            value={date && moment(date, format).format(format)}
          />
        </DropdownBodyWrapper>
      )}
      {...props}
    />
  )
}

export default DateDropdown
