import React from 'react'

const Header = ({ label = 'Add Contact' }) => {
  return (
    <div className="section-title pl0">
      <div className="left-column">
        <h3>{label}</h3>
      </div>
    </div>
  )
}

export default Header
