import { Link } from 'react-router-dom'

import WelcomeImage from 'app/assets/icons/Welcome.svg'
import { Alert, Box, Flex } from 'app/components/design-system-components'
import { BGCheckPriceForManagers } from 'app/utils/text/helpers'

export const PaidPlanActivationAlert = (props) => (
  <Box {...props}>
    <Alert variant="blackWithBlueBg" mt={5}>
      <Flex alignItems="center">
        <Box mt={1}>
          <img src={WelcomeImage} alt="welcome" />
        </Box>
        <Box ml={5}>
          Enquire about processing an instant Background Check for $
          {BGCheckPriceForManagers} (ex GST) Contact{' '}
          <Link
            to="mailto:help@snug.com?subject=Paid plan upagrade"
            target="_blank"
          >
            help@snug.com
          </Link>{' '}
        </Box>
      </Flex>
    </Alert>
  </Box>
)
