import { connect } from 'react-redux'

import { fetchTeamProperties } from 'app/bond_cover/agency/agency_actions'
import AgencySearchSectionContainer from 'app/components/agency_search_section/component'
import {
  resetPropertyFilters,
  setPropertyFilters,
} from 'app/dashboard/team_overview_actions'
import { persistManager } from 'app/sm/properties/properties_actions'
import { getManagersListForViewingFilters } from 'app/sm/viewings/viewings_actions'

const mapStateToProps = ({ session, MyProperties, TeamOverview }) => ({
  teams: session.teams,
  currentTeam: session.currentTeam,
  persistedManager: MyProperties.persistedManager,
  filters: TeamOverview.filters,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTeamManagerList: (teamGUID) =>
    dispatch(getManagersListForViewingFilters(teamGUID)),
  fetchTeamProperties: (teamGUID, filters) =>
    dispatch(fetchTeamProperties(teamGUID, filters)),
  persistManager: (manager) => dispatch(persistManager(manager)),
  setPropertyFilters: (filters) => dispatch(setPropertyFilters(filters)),
  resetPropertyFilters: () => dispatch(resetPropertyFilters()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgencySearchSectionContainer)
