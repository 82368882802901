import { api } from 'app/utils/api/helpers'

export const getPortalStatus = (teamGUID, portal) => {
  return api
    .get(`/portals/${teamGUID}/status`)
    .then((statuses) => statuses.find((s) => s.portal === portal))
}

export const activatePortal = (teamGUID, portal, payload) => {
  return api.post(`/portals/${teamGUID}/activate/${portal}`, payload)
}

export const checkIfPortalIDExistsForOtherAgencies = (
  portal,
  teamGUID,
  payload,
) => {
  return api.get(`/portals/check/${teamGUID}/${portal}`, { params: payload })
}

export const deactivatePortal = (teamGUID, portal) => {
  return api.post(`/portals/${teamGUID}/deactivate/${portal}`)
}
