import React, { Component } from 'react'

import ReactImageVideoLightbox from 'react-image-video-lightbox'
import styled from 'styled-components'

import theme from 'app/match/applicationReportPDF/assets/theme'

const Container = styled.div`
  div {
    background-color: ${theme.colors.gray100} !important;
    color: ${theme.colors.black} !important;

    svg {
      color: ${theme.colors.black} !important;
      background: ${theme.colors.gray100};
    }
    iframe {
      height: 500px;
      width: 70%;
    }
  }
`

class VideoGallery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lightboxOpen: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { startVirtualTour } = this.props
    if (startVirtualTour !== prevProps.startVirtualTour) {
      this.setState({ lightboxOpen: true })
    }
  }

  closeLightBox = () => {
    this.setState({ lightboxOpen: false })
  }

  render() {
    const { videos } = this.props
    const propertyVideos = []
    if (videos && videos.length > 0) {
      videos.forEach((video) => {
        const videoObj = {
          url: video.src,
          type: 'video',
          altTag: 'property video',
        }
        propertyVideos.push(videoObj)
      })
    }
    const extraClass = this.state.lightboxOpen ? 'background-dark-grey' : ''
    return (
      <div>
        {videos && (
          <div className="controls-right r100 mr20">
            <button
              className={`sm-gallery-button ${extraClass}`}
              onClick={() => {
                this.setState({ lightboxOpen: true })
              }}
            >
              <span>{videos.length}</span>
              <i className="fa fa-play fs16"></i>
            </button>
          </div>
        )}
        <Container className="container">
          {this.state.lightboxOpen && (
            <ReactImageVideoLightbox
              data={propertyVideos}
              startIndex={0}
              showResourceCount={true}
              onCloseCallback={this.closeLightBox}
              className="bg-white"
            />
          )}
        </Container>
      </div>
    )
  }
}

export default VideoGallery
