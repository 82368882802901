import moment from 'moment'

import BaseInputDate from 'app/components/forms/base_inputDate/component'
import {
  firstElementOrEmptyString,
  withValidation,
} from 'app/components/forms/util'
import * as validations from 'app/shared_components/validations'
import * as helpers from 'app/sm/helpers'

const pastDateValidator = (date) =>
  validations.compareDates(
    date,
    moment().format(helpers.dateWithDash),
    'This field is required',
    'The date must be in the past',
  )
const validatePastDateFlattened = (date) =>
  firstElementOrEmptyString(pastDateValidator(date))

const InputPastDate = withValidation(BaseInputDate, validatePastDateFlattened)

export default InputPastDate
