import React, { Component } from 'react'

import BankAccountForm from 'app/bond_cover/renters/identity/bank_account_form'
import isEmptyObject from 'app/shared_components/check_empty_object'
import getParameter from 'app/shared_components/parameter_parser'
import * as validations from 'app/shared_components/validations'
import { SessionStorageUtils } from 'app/storage_utils'

class BankAccountFormContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requestID: null,
      isNewLease: false,
      referralCode: '',
      accountName: '',
      bsb: '',
      accountNumber: '',
      currentLease: {},
      bondReferenceNumber: '',
      disabled: true,
      clicked: false,
      errors: {},
      unsavedChanges: false,
    }
  }

  UNSAFE_componentWillMount() {
    let { fetchBankAccount, fetchRequestSummary } = this.props
    let requestID = getParameter('requestid')
    fetchRequestSummary(requestID)
    let type = getParameter('type')
    this.setState({ requestID: requestID, type: type })
    let guidID = this.guid()
    if (guidID) {
      fetchBankAccount(requestID, guidID)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { currentLease, bankAccount, request, apiError } = nextProps
    let errors = {}
    this.setState({
      currentLease: currentLease,
      errors,
      disabled: false,
      clicked: false,
      apiError: apiError,
    })

    if (request && request.referralCode) {
      this.setState({ referralCode: request.referralCode })
    }

    if (currentLease) {
      this.setState({
        isNewLease: currentLease.isNewLease,
        bondReferenceNumber: currentLease.bondReferenceNumber,
      })
    }

    if (bankAccount && bankAccount.guidID) {
      this.setState({
        accountName: bankAccount.accountName,
        bsb: bankAccount.bsb,
        accountNumber: bankAccount.accountNumber,
      })
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    let { isNewLease, address } = nextState
    let state
    !!address && (state = { address })

    if (isNewLease || state === 'Australian Capital Territory') {
      nextState.disabled = !(
        nextState.accountName &&
        nextState.bsb &&
        nextState.accountNumber &&
        isEmptyObject(nextState.errors) &&
        !nextState.clicked
      )
    } else {
      nextState.disabled = !(
        nextState.accountName &&
        nextState.bsb &&
        nextState.accountNumber &&
        nextState.bondReferenceNumber &&
        isEmptyObject(nextState.errors) &&
        !nextState.clicked
      )
    }
  }

  setValidationErrors(field, error) {
    let { errors } = this.state
    if (error.length === 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  guid() {
    return getParameter('id')
  }

  submit = () => {
    let { bankAccount, createBankAccount, updateBankAccount } = this.props
    let {
      requestID,
      accountName,
      bsb,
      accountNumber,
      bondReferenceNumber,
      referralCode,
    } = this.state
    let isPrimary = true
    if (this.validateAll()) {
      this.setState({ clicked: true, unsavedChanges: false })
      let formDetails = {
        accountName: accountName,
        bsb: bsb,
        accountNumber: accountNumber,
        bondReferenceNumber: bondReferenceNumber,
        referralCode,
      }
      if (bankAccount && bankAccount.accountNumber) {
        updateBankAccount(requestID, formDetails, bankAccount.guidID, isPrimary)
      } else {
        createBankAccount(requestID, formDetails, isPrimary)
      }
    }
  }

  trimField = (field) => {
    return () => {
      let value = ''
      if (!!this.state[field]) {
        value = this.state[field].trim()
      }
      this.validate(field, value)()
      this.setState({ [field]: value })
    }
  }

  update = (field) => {
    return (event) => {
      let { value } = event.target
      this.setState({ [field]: value, unsavedChanges: true })
    }
  }

  validate = (field, value) => {
    return () => {
      switch (field) {
        case 'accountName':
          return this.setValidationErrors(
            field,
            validations.validateName(value),
          )
        case 'bsb':
          return this.setValidationErrors(field, validations.validateBSB(value))
        case 'accountNumber':
          return this.setValidationErrors(
            field,
            validations.validateBankAccount(value),
          )
        case 'bondReferenceNumber':
          return this.setValidationErrors(
            field,
            validations.validateBondReference(value),
          )
        default:
          return false
      }
    }
  }

  validateAll() {
    let valid = true
    let fields = ['accountName', 'bsb', 'accountNumber']
    fields.forEach((field) => {
      if (!this.validate(field, this.state[field])()) {
        valid = false
      }
    })
    return valid
  }

  render() {
    let {
      isNewLease,
      accountName,
      bsb,
      accountNumber,
      currentLease,
      bondReferenceNumber,
      referralCode,
      disabled,
      clicked,
      errors,
      apiError,
      unsavedChanges,
    } = this.state
    const { address = '' } = currentLease || {}
    let { quote, verifyReferralCode } = this.props
    const discountCode = SessionStorageUtils.getItem('discountcode')
    return (
      <BankAccountForm
        isNewLease={isNewLease}
        referralCode={referralCode || discountCode}
        requestID={this.state.requestID}
        quote={quote}
        address={address}
        bsb={bsb}
        accountName={accountName}
        accountNumber={accountNumber}
        bondReferenceNumber={bondReferenceNumber}
        errors={errors}
        apiError={apiError}
        disabled={disabled}
        clicked={clicked}
        update={this.update}
        trimField={this.trimField}
        submit={this.submit}
        unsavedChanges={unsavedChanges}
        verifyReferralCode={verifyReferralCode}
      />
    )
  }
}

export default BankAccountFormContainer
