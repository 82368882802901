import React from 'react'

import qs from 'qs'
import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import PropertyReportContainer from 'app/match/propertyReport/connection'
import SendSmsReportModal from 'app/sm/inspections/components/send_sms_report_modal_connection'

const EMAIL_REPORT_VIEW = 'email'
const SMS_REPORT_VIEW = 'sms'

const SwitcherContainer = styled.div`
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  height: 38px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  .selected {
    height: 28px;
    background: #5a9bee;
    border-radius: 4px;
    color: #fff;
  }
  & > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

class ReportContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showEmailReportView: false,
    }
  }

  onChangeReportView = (view) => {
    this.setState({
      showEmailReportView: view === EMAIL_REPORT_VIEW,
    })
  }

  render() {
    const {
      smsReportData,
      smsReportHandleClose,
      smsReportShowSendSmsReportModal,
      smsReportClearError,
      smsReportIsSendSmsReportModalOn,
      propertyGUID,
      viewingGUID,
      cancelled = false,
      viewingReportSMSSent,
      isViewingReportSmsEnabled,
      isViewingReportEmailEnabled,
      viewingReportEmailSent,
      subject = '',
      viewingData = {},
    } = this.props
    const match = {
      params: {
        propertyId: propertyGUID,
      },
    }
    const { propertyReportGUID = '' } = viewingData
    const { showEmailReportView } = this.state
    const cancelledViewingMessage =
      'Reports cannot be sent for cancelled viewings'
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    let emailReportTabLabel = 'Email to owner/s'
    if (propertyReportGUID || curQueries?.propertyreportId) {
      emailReportTabLabel = 'View Email Report'
    }
    return (
      <div>
        {cancelled ? (
          <div className="text-align-center mt20">
            {cancelledViewingMessage}
          </div>
        ) : (
          <div>
            <SwitcherContainer>
              <div
                onClick={() => this.onChangeReportView(EMAIL_REPORT_VIEW)}
                className={showEmailReportView ? 'selected' : ''}
              >
                {emailReportTabLabel}
              </div>
              <div
                onClick={() => this.onChangeReportView(SMS_REPORT_VIEW)}
                className={showEmailReportView ? '' : 'selected'}
              >
                Send as Text
              </div>
            </SwitcherContainer>
            {!showEmailReportView && (
              <div className="p30">
                <SendSmsReportModal
                  renderAsModal={false}
                  data={smsReportData}
                  handleClose={smsReportHandleClose}
                  showSendSmsReportModal={smsReportShowSendSmsReportModal}
                  clearError={smsReportClearError}
                  sendSmsReportModalOn={smsReportIsSendSmsReportModalOn}
                  isViewingReportSmsEnabled={isViewingReportSmsEnabled}
                  viewingReportSMSSent={viewingReportSMSSent}
                  showUpdatedSMSReportButton={true}
                  subject={subject}
                />
              </div>
            )}
            {showEmailReportView && (
              <Box mt={6}>
                <PropertyReportContainer
                  match={match}
                  viewingGUID={viewingGUID}
                  showBreadcrumbs={false}
                  isViewingReportEmailEnabled={isViewingReportEmailEnabled}
                  viewingReportEmailSent={viewingReportEmailSent}
                  isFromMobileViewings={true}
                  propertyReportGUID={propertyReportGUID}
                />
              </Box>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default ReportContent
