import React from 'react'

import { Box, Flex } from 'app/components/design-system-components'
import { ToolTip } from 'app/components/design-system-components/Tooltip'
import { CheckBoxGeneral } from 'app/components/forms/forms'
import * as Form from 'app/components/forms/forms'

function CheckboxAndSelect({
  checkboxLabel = '',
  checkboxChecked = false,
  checkboxOnChange,
  dropdownValue = '',
  dropdownOptions = [],
  dropdownOnChange,
  dropdownExtraOptionText = '',
  dropdownLabel = '',
  disabled = false,
  showDisabledGrey = false,
  toolTipID = '',
  toolTipContent,
}) {
  const showToolTip = toolTipID && toolTipContent

  return (
    <Flex alignItems="baseline">
      <CheckBoxGeneral
        label={checkboxLabel}
        checked={checkboxChecked}
        onChange={checkboxOnChange}
        eventProcessedByComponent
        disabled={disabled}
        showDisabledGrey={showDisabledGrey}
      />
      <Form.Dropdown
        label={dropdownLabel}
        value={dropdownValue}
        options={dropdownOptions}
        onChange={dropdownOnChange}
        extraOptionText={dropdownExtraOptionText}
        useLabelValueOptions={true}
        disabled={disabled}
        showDisabledGrey={showDisabledGrey}
      />
      {showToolTip && (
        <Box ml={3}>
          <ToolTip toolTipID={toolTipID} content={toolTipContent} />
        </Box>
      )}
    </Flex>
  )
}

export default CheckboxAndSelect
