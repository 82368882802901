import { useEffect } from 'react'

import moment from 'moment'

import {
  Box,
  Flex,
  LoadingSection,
} from 'app/components/design-system-components'
import { PdfPrintSection } from 'app/components/design-system-components/page/PdfPrintSection'
import { activityTabCategories } from 'app/features/teams/applications/application-activities/ApplicationSideTabs/ApplicationActivityTab'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { getApplicationStatusActivity } from 'app/services/http/applications/activity'
import * as helpers from 'app/sm/helpers'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'

const StatusActivityPrintItem = ({ item }) => {
  const { created_at: createdAt, actor, description } = item
  const creationStr = moment(createdAt).format(helpers.dateTimeStampFormat)
  return (
    <Flex p={5}>
      <Box color={theme.colors.gray600}>{creationStr}</Box>
      <Box ml={3}>
        {actor && <strong>{actor}&nbsp;</strong>}
        {description}
      </Box>
    </Flex>
  )
}

export const ApplicationTimelinePrint = ({
  applicationId,
  applicantId,
  teamId,
}) => {
  const {
    state: activities,
    setState: setActivities,
    loadingStates: activitiesLoadingStates,
    loadingStatesHelpers: activitiesLoadingStatesHelpers,
  } = useStateWithLoading([])

  useEffect(() => {
    loadAppActivities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadAppActivities = () => {
    activitiesLoadingStatesHelpers.startLoading()
    const eventsCategoryFilter = { [activityTabCategories.app_event.id]: true }
    return getApplicationStatusActivity(
      applicationId,
      applicantId,
      teamId,
      eventsCategoryFilter,
      null,
      null,
    )
      .then(({ logs: appActivities }) => {
        activitiesLoadingStatesHelpers.markDoneSuccessfully()
        setActivities(appActivities || [])
      })
      .catch((err) => activitiesLoadingStatesHelpers.setError(err))
  }

  return (
    <LoadingSection
      loadingState={activitiesLoadingStates}
      loaderProps={{ fontSize: '50px' }}
      actionHandler={loadAppActivities}
    >
      <PdfPrintSection mt={5}>
        <Box
          borderBottom={theme.borders[1]}
          borderBottomColor={theme.colors.gray200}
          p={2}
        >
          <h2>Application Timeline</h2>
        </Box>
        {activities.map((activity, idx) => (
          <StatusActivityPrintItem item={activity} key={idx} />
        ))}
      </PdfPrintSection>
    </LoadingSection>
  )
}
