import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const postLeaseOffer = (applicationId, agencyId, payload) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/offer/${agencyId}`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getLeasingOfferInfo = (applicationId, agencyId) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/offer/${agencyId}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => {
        return {
          ok: false,
          statusCode: error.statusCode,
          responseText: error.responseText,
        }
      },
    )
}

export const getLeasingOfferInfoBySlug = (slug) => {
  const url = `${apiBaseUrl}sm/applications/offers/${slug}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => {
        return {
          ok: false,
          statusCode: error.statusCode,
          responseText: error.responseText,
        }
      },
    )
}

export const uploadAgencyAttachment = (
  formData,
  applicationId,
  agencyId,
  scope,
) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/offer/${agencyId}/attachments?scope=${scope}`
  const options = {
    method: 'POST',
    body: formData,
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
            statusText: res.statusText,
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const deleteAgencyAttachment = (applicantId, agencyId, key, scope) => {
  const url = `${apiBaseUrl}sm/applications/${applicantId}/offer/${agencyId}/attachments/${key}?scope=${scope}`
  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
