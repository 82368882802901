import React from 'react'

import moment from 'moment'

const IdentityDoc = ({ identityDoc }) => {
  let identityDocHtml = (
    <div className="contentBlock">
      <header>
        <span>No Identity Doc Found</span>
      </header>
    </div>
  )
  if (identityDoc && identityDoc.guidID) {
    let { guidID, docType, docNumber, state, expiryDate, dateOfBirth } =
      identityDoc
    identityDocHtml = (
      <div className="contentBlock">
        <header>
          <span>Identity Doc</span>
        </header>
        <content>
          <p>
            {docType === 1 ? 'Driver Licence Number' : 'Passport Number'}:{' '}
            {docNumber}
            <br />
            Expiration: {moment(expiryDate).format('Do MMM YYYY')}
            <br />
            DOB: {moment(dateOfBirth).format('Do MMM YYYY')}
            <br />
          </p>
        </content>
      </div>
    )
  }
  return <div>{identityDocHtml}</div>
}

export default IdentityDoc
