import React from 'react'

import styled from 'styled-components'

import { Box, Button, Flex } from 'app/components/design-system-components'
import { Heading } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'

const InviteToApplyContainer = styled(Flex)`
  margin: ${theme.dividedSpace[2]}px ${theme.dividedSpace[3]}px;
  padding: ${theme.dividedSpace[6]}px;
  background: $white;
  box-shadow: 0 ${theme.radii[1]}px ${theme.space[4]}px 0 rgba(34, 33, 42, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ApplyToProperty = ({ onsiteViewing = {}, propertyOffer = {} }) => {
  const { property } = propertyOffer || {}
  const { address } = property || {}
  const { friendlyName = '', suburb = '' } = address

  const applyClick = (propertyId) => {
    history.push(urlTo('apply', { propertyId: propertyOffer.guidID || '' }))
  }

  return (
    <Flex mb={`${theme.dividedSpace[2]}px`} alignItems="center">
      <Button onClick={() => applyClick()} mr={`${theme.dividedSpace[1]}px`}>
        Apply
      </Button>
      {`${friendlyName}, ${suburb}`}
    </Flex>
  )
}

const InviteToApply = ({ invitesToApply = [] }) => {
  return (
    <InviteToApplyContainer>
      <Heading level={4} displayingLevel={4} fontWeight={theme.fontWeights[5]}>
        You've been Invited to Apply
      </Heading>
      <Box mt={`${theme.dividedSpace[5]}px`} mb={`${theme.dividedSpace[3]}px`}>
        {(invitesToApply || []).map(
          ({ onsiteViewing, property, propertyOffer }) => (
            <ApplyToProperty
              onsiteViewing={onsiteViewing}
              property={property}
              propertyOffer={propertyOffer}
            />
          ),
        )}
      </Box>
    </InviteToApplyContainer>
  )
}

export default InviteToApply
