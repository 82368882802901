import {
  MAX_ALLOWED_RECEIVERS,
  MAX_ALLOWED_RECEIVERS_ERROR_MESSAGE,
} from 'app/constants/messages.constants'
import { newMessagesModes } from 'app/features/pm-messages/new-message/constants'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { loadingStatesIds } from 'app/utils/loading-states'

export const formFields = {
  enquirerMessages: 'receivers',
  property: 'property',
  subject: 'subject',
  message: 'message',
  attachments: 'attachments',
}

export const MAX_ALLOWED_ATTACHMENTS = 5

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.enquirerMessages: {
      return {
        min:
          (val || []).length < 1 && 'at least one receiver should be selected',
        max:
          (val || []).length > MAX_ALLOWED_RECEIVERS &&
          MAX_ALLOWED_RECEIVERS_ERROR_MESSAGE,
      }
    }
    case formFields.subject: {
      return {
        min: val.length < 10 && 'this subject is too short',
        max: val.length > 200 && 'this subject is too long',
      }
    }
    case formFields.message: {
      return {
        min: val.length < 2 && 'this message is too short',
        max: val.length > 5000 && 'this message is too long',
      }
    }
    case formFields.attachments: {
      return {
        max:
          Object.values(val || {}).length > MAX_ALLOWED_ATTACHMENTS &&
          `you cannot add more attachments than ${MAX_ALLOWED_ATTACHMENTS}`,
        serverError:
          Object.values(val || {}).some(
            ({ loadingStates }) =>
              loadingStates.state === loadingStatesIds.ERROR,
          ) && 'some attachment has an error',
      }
    }
    default:
      return {}
  }
}

export const formValidation = formValidationBuilder(fieldValidation)

export const buildRequestPayload = (formValues, modeConfig) => {
  const { mode, ...modeRelatedConfig } = modeConfig

  const modeRelatedData =
    mode === newMessagesModes.bulky
      ? {
          messages_filters: modeRelatedConfig.filters,
        }
      : {
          messages_guids: formValues[formFields.enquirerMessages].map(
            ({ option }) => option.messages.guidid,
          ),
        }

  const selectedProperty = formValues[formFields.property]
  const attachmentsIds = Object.values(
    formValues[formFields.attachments] || {},
  ).map(({ guid }) => guid)

  return {
    ...modeRelatedData,
    ...(selectedProperty
      ? { property_guid: selectedProperty.option.guidID }
      : {}),
    subject: formValues[formFields.subject],
    custom_message: formValues[formFields.message],
    attachments: attachmentsIds,
  }
}

export const transformApiErrorToFields = ({
  messages_guids,
  property_guid,
  custom_message,
  attachments,
}) => {
  return {
    [formFields.enquirerMessages]: messages_guids,
    [formFields.message]: custom_message,
    [formFields.property]: property_guid,
    [formFields.attachments]: attachments,
  }
}
