import React from 'react'

import { Link } from 'react-router-dom'

import headerImg04 from 'app/assets/icons/header-img-04.png'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'

class RenterIdFail extends React.Component {
  UNSAFE_componentWillMount() {
    let { fetchRequestSummary, getSecondaryTenantSummary } = this.props
    let role = getParameter('role')
    let requestID = getParameter('requestid')
    if (role === 'secondary') {
      getSecondaryTenantSummary(requestID)
    } else {
      fetchRequestSummary(requestID)
    }
  }

  render() {
    let { request } = this.props
    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <div className="ilustration-box">
            <img src={headerImg04} />
          </div>

          <h3 className="mb15">There was an issue verifying your ID</h3>

          <p>
            Unfortunately there was a problem validating your ID. Our customer
            support crew will reach out shortly to try and resolve this.
          </p>

          <div className="bc-app-id">
            <h4>Application ID: {request ? request.appCode : ''}</h4>
          </div>

          <p className="mb25">Feel free to contact us</p>

          <a href="mailto:help@snug.com" className="pink-color">
            help@snug.com
          </a>

          <Link className="btn mt50" to={'/home/overview'}>
            Return to dashboard
          </Link>
        </div>
      </div>
    )
  }
}

export default RenterIdFail
