import React from 'react'

const Tenant = ({ currentLease, tenant }) => {
  let tenantHtml = null
  if (tenant && tenant.guidID) {
    let { firstName, lastName } = tenant
    let percentage = ((tenant.bondAmount / currentLease.bond) * 100).toFixed(0)
    tenantHtml = (
      <div className="table mb15">
        <div className="cell-m-100 gray-dark-color fs16 fw600 lh16">
          <b>
            {firstName} {lastName}
          </b>
          <br />
          {!currentLease.isNewLease && (
            <div className="pt8">
              Bond ${tenant.bondAmount.toFixed(2)} ({percentage}%)
            </div>
          )}
        </div>
        <div className="cell-m">
          {tenant.isPrimary && <div className="badge red">Primary</div>}
        </div>
      </div>
    )
  }
  return <div>{tenantHtml}</div>
}

export default Tenant
