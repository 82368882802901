import React from 'react'

import PropTypes from 'prop-types'

import backedByIag from 'app/assets/icons/backed-by-iag.png'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import isEmptyObject from 'app/shared_components/check_empty_object'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'
import { validateEmail, validateName } from 'app/shared_components/validations'
import { urlTo } from 'app/sm/helpers'
import { SessionStorageUtils } from 'app/storage_utils'
import { openZendeskWidget } from 'app/utils/widgets/helpers'

const toggleChat = () => {
  openZendeskWidget()
}

const BottomAsterisk = ({ isFromAct }) => {
  return (
    <div>
      {!isFromAct && (
        <p className="fs12 mt25 mb10">
          **<b>Terms:</b> A friend referral credit of maximum $25 is available
          for bonds over $500 value.
        </p>
      )}
    </div>
  )
}

const HeaderContext = ({
  bond,
  bondPrice,
  refund,
  available,
  state,
  promotion,
  bondCoverPrice,
  isFromAct,
}) => {
  return (
    <div>
      {isFromAct ? (
        <div>
          <h2 className="center mb20 bcquote-head">
            Just ${bondPrice} covers a ${bond} cash Bond.*
          </h2>
          <div className="mt15">
            <p>
              Snug has campaigned for a better bond system, and it’s nearly
              here! While we’re further engaging with stakeholders and applying
              for a licence, you can pre-register your interest.
            </p>
            <p>
              <b>${bondPrice}</b>&nbsp;could save you finding ${bond} for a bond
              on a <b>new lease</b> or refunds ${refund} of your existing bond.*
              Apply for final pricing.
            </p>
            <p>
              Launch Special pricing of ${bondPrice} (includes $50 discount,
              usually ${bondCoverPrice}) when you send a $25 BondCover coupon to
              two friends.
            </p>
          </div>

          {/* {promotion ?
          <p className="last fs16">
            Special intro pricing of ${bondPrice} (usually ${bondCoverPrice}) when you send a $25 BondCover coupon to a friend.
          </p>
          :
          <p className="last fs16">
            Enter your details to {available && 'apply and '} send a $25 BondCover coupon to a friend.**
          </p>
        } */}
        </div>
      ) : (
        <div>
          <h2 className="center mb20 bcquote-head">
            Just ${bondPrice} covers a ${bond} cash Bond.*
          </h2>
          <div className="mt15">
            <p>
              <b>${bondPrice}</b>&nbsp;could save you finding ${bond} for a bond
              on a <b>new lease</b> or refunds ${refund} of your existing bond.*
              Apply for final pricing.
            </p>
          </div>
          <div className={!available ? '' : 'hidden'}>
            <h3 className="center mb20">
              Sorry, we are not accepting applications for BondCover{' '}
              {state ? `in ${state} ` : ''}yet.
            </h3>
          </div>
          {promotion ? (
            <p className="last fs16">
              Special intro pricing of ${bondPrice} (usually ${bondCoverPrice})
              when you send a $25 BondCover coupon to a friend.
            </p>
          ) : (
            <p className="last fs16">
              Add friends emails to send a $25 BondCover coupon.**
            </p>
          )}
        </div>
      )}
    </div>
  )
}

class BCQuote extends React.Component {
  static calculateBondRefund(bond, bondCoverPrice) {
    return bondCoverPrice >= bond ? 0 : Math.round(bond - bondCoverPrice)
  }

  constructor(props) {
    super(props)
    this.state = {
      bond: '',
      postCode: '',
      available: false,
      promotion: false,
      state: '',
      discount: 50,
      discountBondCoverPrice: 0,
      bondCoverPrice: 0,
      bondRefund: 0,
      discountBondRefund: 0,
      name: '',
      email: '',
      friendEmail: '',
      secondFriendEmail: '',
      applied: false,
      errors: {},
      inputErrors: {},
      clicked: false,
      firstName: '',
      lastName: '',
    }
  }

  componentDidMount() {
    this.getDetails()
    SessionStorageUtils.setItem('discountcode', getParameter('discountcode'))

    const {
      location: { pathname, search },
    } = history
    SessionStorageUtils.setItem('forwardUrl', `${pathname}${search}`)
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      clicked: false,
    })
  }

  getDetails = () => {
    const queryRent = getParameter('rent')
    const queryPostCode = getParameter('postcode')

    const { discount } = this.state

    const bond = queryRent >= 0 ? Math.round(4 * queryRent) : 0
    const postCode = queryPostCode
    const adminFee = 52
    const backgroundCheckProcessingFee = 11
    const suretyFee = queryRent >= 0 ? Math.round(bond * 0.033) : 0
    const bondCoverPrice = Math.round(
      suretyFee + adminFee + backgroundCheckProcessingFee,
    )
    const discountBondCoverPrice = Math.round(bondCoverPrice - discount)
    const bondRefund = this.calculateBondRefund(bond, bondCoverPrice)
    const discountBondRefund = this.calculateBondRefund(
      bond,
      discountBondCoverPrice,
    )

    this.setState(
      {
        bond,
        postCode,
        bondCoverPrice,
        discountBondCoverPrice,
        bondRefund,
        discountBondRefund,
      },
      () => {
        this.checkAvailable()
      },
    )
  }

  setValidationErrors(field, error, onBlur) {
    const { disabled, errors, inputErrors } = this.state
    if (!disabled) {
      this.setState(
        {
          errors: {},
          inputErrors: {},
        },
        () => {
          if (onBlur) {
            if (error.length === 0) {
              // No validation errors
              delete errors[field]
              this.setState({ errors })
              return true
            }
            // Validation errors
            errors[field] = error
            this.setState({ errors })
            return false
          }
          if (error.length === 0) {
            delete inputErrors[field]
            this.setState({ inputErrors })
            delete errors[field]
            this.setState({ errors })
            return true
          }
          inputErrors[field] = error
          this.setState({ inputErrors })
          return false
        },
      )
    }
  }

  apply() {
    this.setState({ applied: false })
    const { currentUser } = this.props
    const { firstName, lastName, available, friendEmail, secondFriendEmail } =
      this.state
    let { name, email } = this.state
    name = `${firstName} ${lastName}`
    let formDataSecondReferredFriend = ''

    this.setState({ clicked: true })

    if (this.isUserLoggedIn()) {
      if (
        friendEmail.toLowerCase() === currentUser.email.toLowerCase() ||
        secondFriendEmail.toLowerCase() === currentUser.email.toLowerCase()
      ) {
        this.applySuccess()
        return
      }
      name = `${currentUser.firstName} ${currentUser.lastName}`
      ;({ email } = currentUser)
    }

    const formDataReferredFriend = {
      email: friendEmail.trim(),
      stringField: {
        referrerName: name.trim(),
        referrerEmail: email.trim(),
      },
    }

    if (secondFriendEmail) {
      formDataSecondReferredFriend = {
        email: secondFriendEmail.trim(),
        stringField: {
          referrerName: name.trim(),
          referrerEmail: email.trim(),
        },
      }
    }

    // check self sending
    if (
      formDataReferredFriend.referrerEmail.toLowerCase() ===
      formDataReferredFriend.email.toLowerCase()
    ) {
      this.applySuccess()
      return
    }

    if (friendEmail) {
      this.props.sendBondCoverReferral(
        1,
        formDataReferredFriend.referrerName,
        formDataReferredFriend.referrerEmail,
        formDataReferredFriend.email,
        this.applySuccess,
        () => {},
      )
    }

    if (secondFriendEmail) {
      this.props.sendBondCoverReferral(
        1,
        formDataSecondReferredFriend.referrerName,
        formDataSecondReferredFriend.referrerEmail,
        formDataSecondReferredFriend.email,
        this.applySuccess,
        () => {},
      )
    }

    if (!friendEmail && !secondFriendEmail) {
      this.applySuccess()
    }

    if (!available) {
      this.setState({ clicked: false })
    }
  }

  applySuccess = () => {
    const { firstName, lastName, email } = this.state
    SessionStorageUtils.setItem('forwardUrl', urlTo('homeoverview'))
    this.setState({
      applied: true,
      disabled: true,
    })
    if (!this.isUserLoggedIn()) {
      history.push(
        urlTo('join') +
          `?firstname=${firstName}&lastname=${lastName}&email=${email}`,
      )
    } else {
      history.push('/home/overview')
    }
  }

  checkAvailable() {
    const { postCode } = this.state
    switch (true) {
      // NSW 2000-2599, 2620-2899, 2921—2999
      case (postCode >= 2000 && postCode <= 2599) ||
        (postCode >= 2620 && postCode <= 2899) ||
        (postCode >= 2921 && postCode <= 2999):
        this.setState({ available: true, state: 'New South Wales' })
        break
      // ACT 2600—2619, 2900—2920
      case (postCode >= 2600 && postCode <= 2619) ||
        (postCode >= 2900 && postCode <= 2920):
        this.setState({
          available: true,
          promotion: true,
          state: 'Australian Capital Territory',
        })
        break
      // VIC 3000—3999
      case postCode >= 3000 && postCode <= 3999:
        this.setState({ available: true, state: 'Victoria' })
        break
      // QLD 4000—4999
      case postCode >= 4000 && postCode <= 4999:
        this.setState({ available: false, state: 'Queensland' })
        break
      // SA  5000—5799
      case postCode >= 5000 && postCode <= 5799:
        this.setState({ available: true, state: 'South Australia' })
        break
      // WA  6000—6797
      case postCode >= 6000 && postCode <= 6797:
        this.setState({ available: true, state: 'Western Australia' })
        break
      // TAS 7000—7799
      case postCode >= 7000 && postCode <= 7799:
        this.setState({ available: false, state: 'Tasmania' })
        break
      // NT  0800—0899
      case postCode >= 800 && postCode <= 899:
        this.setState({ available: false, state: 'Northern Territory' })
        break
      default:
        this.setState({ available: false })
    }
  }

  isFormEmpty() {
    const { name, email, friendEmail } = this.state
    return name === '' && email === '' && friendEmail === ''
  }

  isUserLoggedIn() {
    const { currentUser } = this.props
    return (
      currentUser &&
      !(currentUser.isUserFetching || currentUser.isUserProfileFetching) &&
      currentUser.email
    )
  }

  noValidationErrors() {
    const {
      firstName,
      lastName,
      email,
      friendEmail,
      inputErrors,
      errors,
      secondFriendEmail,
    } = this.state
    const valiatedFriendsEmailsFields =
      (!friendEmail || (!inputErrors.friendEmail && !errors.friendEmail)) &&
      (!secondFriendEmail ||
        (!inputErrors.secondFriendEmail && !errors.secondFriendEmail))
    if (this.isUserLoggedIn()) {
      return valiatedFriendsEmailsFields
    }

    // if (this.isFormEmpty()) {
    //   return true;
    // }
    return (
      isEmptyObject(inputErrors) &&
      firstName &&
      lastName &&
      email &&
      valiatedFriendsEmailsFields
    )
  }

  update(field) {
    return (event) => {
      this.setState({ applied: false })
      const { value } = event.target
      this.setState(
        {
          [field]: value,
        },
        () => {
          this.validate(field, value, false)
        },
      )
    }
  }

  validate(field, value, onBlur) {
    switch (field) {
      case 'name':
        this.setValidationErrors(field, validateName(value), onBlur)
        break
      case 'firstName':
        this.setValidationErrors(field, validateName(value), onBlur)
        break
      case 'lastName':
        this.setValidationErrors(field, validateName(value), onBlur)
        break
      case 'email':
        this.setValidationErrors(field, validateEmail(value), onBlur)
        break
      case 'friendEmail':
        this.setValidationErrors(field, validateEmail(value), onBlur)
        break
      case 'secondFriendEmail':
        this.setValidationErrors(field, validateEmail(value), onBlur)
        break
      default:
        break
    }
  }

  render() {
    const {
      bond,
      available,
      promotion,
      state,
      bondCoverPrice,
      discountBondCoverPrice,
      bondRefund,
      discountBondRefund,
      email,
      friendEmail,
      applied,
      errors,
      clicked,
      firstName,
      lastName,
      secondFriendEmail,
    } = this.state
    const { apiError } = this.props

    const isLoggedIn = this.isUserLoggedIn()
    const disabled = !this.noValidationErrors()
    const bondPrice = !promotion ? bondCoverPrice : discountBondCoverPrice
    const refund = !promotion ? bondRefund : discountBondRefund
    const duplicateInvite =
      ErrorMessages[Errors.DuplicateInvitesExist] === apiError
    const isFromAct = state === 'Australian Capital Territory'

    const registerButtonText = isFromAct
      ? 'Pre-register in 2 mins'
      : available
      ? 'Apply now in 2 minutes'
      : 'Send BondCover discount coupon'

    return (
      <div className="bcquote two-col-box-flex-row sm64">
        <div className="col-first">
          <div className="">
            <HeaderContext
              bondPrice={bondPrice}
              bond={bond}
              refund={refund}
              available={available}
              state={state}
              promotion={promotion}
              bondCoverPrice={bondCoverPrice}
              isFromAct={isFromAct}
            />
          </div>

          <div className={!isLoggedIn ? 'input-box' : 'hidden'}>
            <label htmlFor="firstName">
              <input
                type="text"
                required
                value={firstName}
                className={errors.name ? 'error' : ''}
                onChange={this.update('firstName')}
                onBlur={(e) => this.validate('firstName', e.target.value, true)}
              />
              Your First Name
            </label>
          </div>
          <div
            className={errors.firstName ? 'alert alert-danger' : 'hide-alert'}
          >
            <div>{errors.firstName}</div>
          </div>
          <div className={!isLoggedIn ? 'input-box' : 'hidden'}>
            <label htmlFor="lastName">
              <input
                type="text"
                required
                value={lastName}
                className={errors.name && disabled ? 'error' : ''}
                onChange={this.update('lastName')}
                onBlur={(e) => this.validate('lastName', e.target.value, true)}
              />
              Your Last Name
            </label>
          </div>
          <div
            className={
              errors.lastName && disabled ? 'alert alert-danger' : 'hide-alert'
            }
          >
            <div>{errors.lastName}</div>
          </div>

          <div className={!isLoggedIn ? 'input-box' : 'hidden'}>
            <label htmlFor="email">
              <input
                type="text"
                required
                value={email}
                className={errors.email && disabled ? 'error' : ''}
                onChange={this.update('email')}
                onBlur={(e) => this.validate('email', e.target.value, true)}
              />
              Your Email
            </label>
          </div>
          <div
            className={
              errors.email && disabled ? 'alert alert-danger' : 'hide-alert'
            }
          >
            <div>{errors.email}</div>
          </div>

          <div className="input-box">
            <label htmlFor="firstFriend">
              <input
                type="text"
                required
                value={friendEmail}
                className={errors.friendEmail ? 'error' : ''}
                onChange={this.update('friendEmail')}
                onBlur={(e) =>
                  this.validate('friendEmail', e.target.value, true)
                }
              />
              First Friend&apos;s Email
            </label>
          </div>
          <div
            className={errors.friendEmail ? 'alert alert-danger' : 'hide-alert'}
          >
            <div>{errors.friendEmail}</div>
          </div>

          <div className="input-box">
            <label htmlFor="secondFriend">
              <input
                type="text"
                required
                value={secondFriendEmail}
                className={errors.secondFriendEmail ? 'error' : ''}
                onChange={this.update('secondFriendEmail')}
                onBlur={(e) =>
                  this.validate('secondFriendEmail', e.target.value, true)
                }
              />
              Second Friend&apos;s Email
            </label>
          </div>
          <div
            className={
              errors.secondFriendEmail ? 'alert alert-danger' : 'hide-alert'
            }
          >
            <div>{errors.secondFriendEmail}</div>
          </div>

          {applied && !this.isFormEmpty() && (
            <div className="alert alert-success">Invite successfully sent.</div>
          )}
          {applied && this.isFormEmpty() && (
            <div className="alert alert-danger">
              Please complete the contact details.
            </div>
          )}

          <div className={apiError ? 'alert alert-danger xs-mb10' : ''}>
            {`${apiError}${duplicateInvite ? friendEmail : ''}`}
          </div>

          <button
            className="mt15 mb15"
            type="button"
            disabled={disabled || clicked}
            onClick={() => this.apply()}
          >
            {registerButtonText}
            <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
          </button>

          <div className="bcquote-img-wrapper">
            <img
              src={backedByIag}
              className="bcquote-img"
              alt="backed by iag"
            />
          </div>

          <p className="renewal-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://snug.com/bondcover/terms/"
            >
              *BondCover Terms &amp; Conditions
            </a>
          </p>

          <ul className="fs14 snug-tips mb30">
            <li>
              *Pricing as at 10 May 2018. Estimate based on single tenant, 1
              bedroom, metro apartment with bond amount equal to four weeks.
              Renewals assume the same lease details, includes ‘no claim’ and
              ‘loyalty’ discounts on the Surety Fee and Administration Fee,
              excludes Partner Fees and location loading. Terms apply. Request a
              quote with your details for pricing.
            </li>
          </ul>

          {isFromAct && (
            <ul className="fs14 snug-tips mb50">
              <li>
                *Pre-register for BondCover before 30 June 2018 for the Launch
                Special ($50 discount on first year BondCover Fee) and refer two
                friends who rent. Final BondCover terms and issuance are
                currently awaiting Snug licensing in the ACT. A friend referral
                credit of maximum $25 is available for bonds over $500 value.
                Offer ends 30 June 2018 or if withdrawn earlier.
              </li>
            </ul>
          )}

          <h3 className="mb30">What is BondCover?</h3>
          <p>
            BondCover makes renting easier. Instead of paying a cash Bond, which
            is then tied up doing nothing, you can now swap your Bond for a Snug
            BondCover Certificate. You can apply for BondCover before starting a
            new lease or refund your existing cash Bond if you’re already in a
            lease.
          </p>
          <p>
            You’re still required to pay valid bond claims, but it’s fast,
            simpler and fairer. See terms for details.
          </p>

          <h3 className="mb15">What you need</h3>
          <ul className="lh13 mb25">
            <li>Your rental lease</li>
            <li>Your cash bond details</li>
            <li>Identification (Driver Licence or Passport)</li>
            <li>Bank details for refund or credit card for payment</li>
          </ul>

          <h3 className="mb15">Find out more</h3>
          <iframe
            title="video"
            className="mb20"
            src="https://www.youtube.com/embed/Brj3ySeY53c"
            frameBorder="0"
            allowFullScreen
          />
          <p>
            Visit our{' '}
            <a
              href="http://help.snug.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              help center
            </a>{' '}
            or <button onClick={() => toggleChat()}>Chat with us</button>
          </p>

          <button
            className="mt15 mb15"
            type="button"
            disabled={disabled || clicked}
            onClick={() => this.apply()}
          >
            {registerButtonText}
            <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
          </button>

          <BottomAsterisk promotion={promotion} isFromAct={isFromAct} />
        </div>
      </div>
    )
  }
}

BCQuote.propTypes = {
  currentUser: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.objectOf([
            PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.bool,
              PropTypes.number,
            ]),
          ]),
        ]),
      ),
    ]),
  ).isRequired,
}

export default BCQuote
