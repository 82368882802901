import React, { useEffect } from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Box, Button, Flex } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import { HelpOutlineRounded } from 'app/components/design-system-components/icons/actions'
import { UCSProviderConfig } from 'app/constants/ucs.constants'
import { generateApplicantsText } from 'app/features/teams/applications/utility-referral/utils'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { TIME_DAY_HALF_MONTH } from 'app/utils/datetime/helpers'

export const SendUtilityReferralToolbar = ({ config }) => {
  if (!config) return null

  const {
    allApplications,
    eligibleApplications,
    sentReferrals,
    providerId,
    property,
    onSendClicked,
    sendDisabled,
    platformDefaultProvider = false,
  } = config

  const tooltipPostfix = property.guidID

  const ProviderIcon = UCSProviderConfig[providerId]?.icon
  const ProviderIconElem = !!ProviderIcon && (
    <Flex alignItems="center" maxWidth="100px">
      <ProviderIcon height="50px" width="auto" />
    </Flex>
  )

  const defaultTextToDisplay =
    'Move application to "Application approved" and send utility referral'

  const renderUnReadyToSendState = () => {
    const instructionsToBeReadyTooltip = platformDefaultProvider
      ? `${defaultTextToDisplay}. The default utility partner integration can be updated via Snug Support.`
      : defaultTextToDisplay
    const tooltipId = `instructions-to-send-${tooltipPostfix}`
    return (
      <>
        <Flex
          alignItems="center"
          fontSize={theme.fontSizes.h3}
          color={theme.colors.gray600}
        >
          <HelpOutlineRounded
            data-tooltip-content={instructionsToBeReadyTooltip}
            data-tooltip-id={tooltipId}
          />
        </Flex>

        <ReactTooltip
          id={tooltipId}
          data-type="info"
          place="top"
          className="tool-tip-style"
        />
        {/* <Box maxWidth="222px" textAlign="center">
            {instructionsToBeReadyTooltip}
          </Box>
        </ReactTooltip> */}
      </>
    )
  }

  const renderReadyToSendState = () => {
    const sendUtilityReferralTooltipMessage =
      'Send utility referral. Duplicates prevented. Alternatively move to stage with “$” label.'
    const tooltipId = `send-utility-referral-${tooltipPostfix}`
    return (
      <>
        <Button
          variant="outlineSuccess"
          sizeVariant="regular"
          onClick={onSendClicked}
          mr={theme.space[3] + 'px'}
          ml={theme.space[3] + 'px'}
          disabled={sendDisabled}
        >
          Send
        </Button>
        <Flex
          alignItems="center"
          fontSize={theme.fontSizes.h3}
          color={theme.colors.gray600}
        >
          <HelpOutlineRounded
            data-tooltip-content=""
            data-tooltip-id={tooltipId}
          />
        </Flex>

        <ReactTooltip
          id={tooltipId}
          data-type="info"
          place="top"
          className="tool-tip-style"
        >
          <Box maxWidth="222px" textAlign="center">
            {sendUtilityReferralTooltipMessage}
          </Box>
        </ReactTooltip>
      </>
    )
  }

  const renderSentState = () => {
    const groupedReferralsByApplication = sentReferrals.reduce(
      (accGroupedReferrals, referral) => {
        const applicationReferrals =
          accGroupedReferrals[referral.application_guid] || []
        return {
          ...accGroupedReferrals,
          [referral.application_guid]: [...applicationReferrals, referral],
        }
      },
      {},
    )
    let showTooltip = false

    const applicationsReferrals = allApplications
      .filter((app) =>
        Object.keys(groupedReferralsByApplication).includes(app.guidID),
      )
      .map((application) => {
        return {
          application,
          referrals: groupedReferralsByApplication[application.guidID] || [],
        }
      })

    const applicationReferralText =
      applicationsReferrals &&
      applicationsReferrals.map(({ application, referrals }) => {
        const applicationText = generateApplicantsText(application.applicants)
        let lastReferral = ''
        let lastReferralCreator = ''
        let referralTime = ''
        if (referrals && referrals.length > 0) {
          lastReferral = referrals.sort(
            (refA, refB) =>
              moment(refB.created_at).valueOf() -
              moment(refA.created_at).valueOf(),
          )[0]
          lastReferralCreator = lastReferral && lastReferral.creator
          referralTime = moment(lastReferral.created_at).format(
            TIME_DAY_HALF_MONTH,
          )
          showTooltip = true
        }

        const referredBy = lastReferralCreator
        const referredByText = `${referredBy?.firstName || ''} ${
          referredBy?.lastName || ''
        }`

        return `${applicationText} referred by ${referredByText} ${referralTime}`
      })

    const tooltipContent = applicationReferralText.map((title) => (
      <Box>{title}</Box>
    ))

    const tooltipId = `sent-referrals-${tooltipPostfix}`

    return (
      <Flex alignItems="center">
        <Badge
          variant="success"
          ml={theme.space[3] + 'px'}
          text="Sent"
          data-tooltip-content=""
          data-tooltip-id={tooltipId}
          style={{ cursor: 'pointer' }}
        />
        {showTooltip && (
          <ReactTooltip
            id={tooltipId}
            data-type="info"
            place="top"
            className="tool-tip-style"
          >
            <Box maxWidth="222px" textAlign="center">
              {tooltipContent}
            </Box>
          </ReactTooltip>
        )}
      </Flex>
    )
  }

  let actionsCmp
  if (sentReferrals && sentReferrals.length) {
    actionsCmp = renderSentState()
  } else if (!eligibleApplications || !eligibleApplications.length)
    actionsCmp = renderUnReadyToSendState()
  else {
    actionsCmp = renderReadyToSendState()
  }

  return (
    <Flex alignItems="center">
      {ProviderIconElem} {actionsCmp}
    </Flex>
  )
}
