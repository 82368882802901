import React from 'react'

import { Link } from 'react-router-dom'

import sofaConfetti from 'app/assets/icons/sofa-confetti.png'

function RenterLiveRentFree() {
  return (
    <div className="two-col-box-flex-row sm64">
      <div className="col-first">
        <div className="img-full mb30">
          <img src={sofaConfetti} />
        </div>

        <h3 className="mb15">Live rent free for a month!</h3>

        <p className="mb30">
          Snug is helping to make housing more affordable by giving away 12
          prizes to live rent free for a month.
        </p>

        <h3 className="mb15">How to enter?</h3>

        <p className="mb30">There are 3 different ways to enter:</p>

        <ul className="bc-waiting-status no-lines mb30">
          <li>
            <div className="icon-box">
              <div className="icon">
                <i className="fa fa-facebook"></i>
              </div>
            </div>
            <div className="text-box">
              <h4>Follow us on Facebook</h4>
              <p>Join the movement to make renting in Australia better.</p>
            </div>
          </li>
          <li className="completed">
            <div className="icon-box">
              <div className="icon"></div>
            </div>
            <div className="text-box">
              <h4>Bond dreams</h4>
              <p>Tell us your plans for your bond refund #bonddreams</p>
            </div>
          </li>
          <li>
            <div className="icon-box">
              <div className="icon">
                <i className="fa fa-comments-o"></i>
              </div>
            </div>
            <div className="text-box">
              <h4>Tell your friend</h4>
              <p>
                Tell your friends about renting bond free with Snug BondCover
              </p>
            </div>
          </li>
        </ul>

        <small className="gray-light-color">
          Prizes to be drawn on 12/12/2017 09:00am AEST. 12 prizes worth $4000
          each to be won. Entries close 12/12/2017.
        </small>
      </div>
    </div>
  )
}

export default RenterLiveRentFree
