import { uberCompNotShowingDay } from 'config/env'
import moment from 'moment'

import * as helpers from 'app/sm/helpers'

export const STANDARD_DATETIME = 'hh:mma, ddd DD MMM YYYY'
export const SHORT_STANDARD_DATETIME = 'hh:mma DD MMM YYYY'
export const STANDARD_DATE = 'DD MMM YYYY'
export const AUSTRALIAN_HUMAN_READABLE_DATETIME = 'DD MMM YYYY, h:mm a'
export const TIME_AM_PM_FULL_DAY = 'hh:mmA dddd'
export const TIME_AM_PM_HALF_DAY_HALF_MONTH = 'hh:mm A, ddd DD MMM YYYY'
export const HALF_DAY_HALF_MONTH_TIME_AM_PM = 'DD MMM YYYY, hh:mm A'
export const HALF_DAY_HALF_MONTH_TIME_SMALL_AM_PM = 'D MMM YYYY, h:mma'
export const REGISTER_DATETIME = 'ddd DD MMM h:mma'

export const DATE_WITH_DASH = 'YYYY-MM-DD'
export const YEAR_DASH_MONTH = 'YYYY-MM'
export const DATE_DAY_SHORT_STRING_MONTH_YEAR = 'D MMM YYYY'
export const HALF_DATE_WITHOUT_YEAR_MILITARY_TIME = 'D MMM H:ss'
export const HALF_DATE_WITHOUT_YEAR = 'D MMM'
export const HALF_DAY_FULL_MONTH = 'D MMMM'
export const HALF_DAY = 'ddd'
export const TIME_AM_PM = 'h:mma'
export const DATE_NO_YEAR = 'DD MMM'
export const DATE_WITH_SLASH = 'DD/MM/YYYY'
export const TIME_AM_PM_WITHOUT_FULL_HOUR = 'h:mm A'
export const HUMAN_READABLE_DATE = 'ddd, DD MMM'
export const HOUR_DAY_MONTH_YEAR_FORMAT = 'HH:MM DD MMM YYYY'
export const INVITED_DATE_FORMAT = HOUR_DAY_MONTH_YEAR_FORMAT
export const TIME_DAY_HALF_MONTH = 'h:mm A, DD MMM'
export const FULL_TIME_24H = 'HH:mm'
export const FULL_TIME_24H_Z = 'HH:mm:ssZ'
export const TRIMMED_DAY_MONTH_YEAR_FORMAT = 'DDMMMYYYY'
export const TIME_AM_PM_DATE = 'hh:mm A DD MMM YYYY'
export const SHORT_DAY_FULL_DATE = 'ddd DD MMM YYYY'
export const DisplayDateFormat = 'ddd D MMM YYYY'
export const DisplayDateWithTimeFormat = 'ddd D MMM, h:mm A'
export const TIME_DAY_DATE = 'h:mm a, DD MMM YYYY'
export const DOT_DATE_FORMAT = 'D.MM.YY'
export const HOUR_MINUTE_DATE_MONTH_YEAR = 'HH:mm  DD MM YYYY'

export const shortYearDateTime = 'DD MMM YY HH:mm'
export const shortYearDateTimeWithAt = 'DD MMM YY [at] HH:mm'
export const FULL_HOUR_AM_PM = 'hh:mm A'

export const DEFAULT_UNIX_TIME = '0001-01-01T00:00:00Z'
export const DEFAULT_UNIX_TIME_Z = 'YYYY-MM-DDTHH:mm:ss[Z]'
export const DATE_TIME_Z = 'YYYY-MM-DDTHH:mm:ssZ'

export const INVALID_DATE_STRING = 'Invalid date'

export const QR_CODE_FORMAT_FILENAME = 'YYYYMMDDHm'

export const YEAR_ONLY = 'YYYY'

export const AEDT_UTC = +11
export const MONTHS_GUIDs = [
  { guid: 1, name: 'Jan' },
  { guid: 2, name: 'Feb' },
  { guid: 3, name: 'Mar' },
  { guid: 4, name: 'Apr' },
  { guid: 5, name: 'May' },
  { guid: 6, name: 'Jun' },
  { guid: 7, name: 'Jul' },
  { guid: 8, name: 'Aug' },
  { guid: 9, name: 'Sep' },
  { guid: 10, name: 'Oct' },
  { guid: 11, name: 'Nov' },
  { guid: 12, name: 'Dec' },
]

const replaceTimePeriods = {
  seconds: 'secs',
  second: 'sec',
  minute: 'min',
  minutes: 'mins',
  hour: 'hr',
  hours: 'hrs',
  month: 'mth',
  months: 'mths',
  year: 'yr',
  years: 'yrs',
  'a ': '1 ',
}

const UBER_COMP_SHOWING_DAY = '00:00 22/09/2022'

export const hourDayMonthOrYearDifference = (date) => {
  const momentPeriodFromNow = moment(date).fromNow(true)
  const formattedDateAndTime = moment(date).format(
    HALF_DATE_WITHOUT_YEAR_MILITARY_TIME,
  )
  const regexForTimePeriods = new RegExp(
    Object.keys(replaceTimePeriods).join('|'),
    'gi',
  )
  const formattedTimePeriodFromNow = momentPeriodFromNow.replace(
    regexForTimePeriods,
    (matched) => replaceTimePeriods[matched],
  )
  return `${formattedDateAndTime} (${formattedTimePeriodFromNow})`
}

export const nowOrProvidedTimestamp = (isNow, timestamp) => {
  let result = moment()
  if (!isNow && timestamp) result = moment(timestamp)
  return result
}

export const standardFormattedTimeStamp = (timestamp) => {
  if (!!timestamp) return timestamp.local().format(STANDARD_DATETIME)
}

export const convertDateToDayDateAndTime = (date) => {
  const dt = moment(date)
  let dayOrDate = dt.format(HALF_DATE_WITHOUT_YEAR)
  const time = dt.format(TIME_AM_PM)
  const diffBetweenDates = dt.diff(moment(), 'days', true)
  if (diffBetweenDates >= 0 && Math.round(diffBetweenDates) < 7) {
    dayOrDate = dt.format(HALF_DAY)
  }
  if (moment(dt).isSame(moment(), 'day')) {
    dayOrDate = 'Today'
  }
  return {
    dayOrDate,
    time,
  }
}

export const convertDateToDayAndDate = (date) => {
  const dt = moment(date)
  let day = dt.format('dddd')
  const dateWithoutYear = dt.format(DATE_NO_YEAR)
  if (moment(dt).isSame(moment(), 'day')) {
    day = 'Today'
  }
  return {
    day,
    dateWithoutYear,
  }
}

export const calculateAge = (dateOfBirth) => {
  return dateOfBirth ? moment().diff(dateOfBirth, 'years').toString() : ''
}

export const formatDateOfBirth = (dateOfBirth) => {
  return dateOfBirth
    ? moment(dateOfBirth, DATE_WITH_DASH).format(
        DATE_DAY_SHORT_STRING_MONTH_YEAR,
      )
    : ''
}

export const formatYearOfBirth = (dateOfBirth) => {
  return dateOfBirth
    ? moment(dateOfBirth, DATE_WITH_DASH).format(YEAR_ONLY)
    : ''
}

// Use formats from datetime/helpers, e.g. STANDARD_DATETIME
export function formatTime(timeStamp, format) {
  return !!timeStamp && !!format ? moment(timeStamp).format(format) : ''
}

// Use formats from datetime/helpers, e.g. STANDARD_DATETIME
export function formatLocalTime(timeStamp, format) {
  return !!timeStamp && !!format ? moment(timeStamp).local().format(format) : ''
}

export function formatStdTimeStamp(timeStamp) {
  return formatTime(timeStamp, AUSTRALIAN_HUMAN_READABLE_DATETIME)
}

export function formatStdDateStamp(timeStamp) {
  return formatTime(timeStamp, DATE_DAY_SHORT_STRING_MONTH_YEAR)
}

export function formatTimeFromGivenTimestamp(
  timeStamp,
  givenTimeStamp,
  format,
) {
  return !!timeStamp && !!format
    ? moment(timeStamp, givenTimeStamp).format(format)
    : ''
}

// Could be moved to a standard component if styling is required
export const formattedDateOfBirth = (dateOfBirth, isChild = false) => {
  const formattedDate = isChild
    ? formatYearOfBirth(dateOfBirth)
    : formatDateOfBirth(dateOfBirth)
  const parsedDateOfBirth = dateOfBirth ? formattedDate : ''
  const age = dateOfBirth ? calculateAge(dateOfBirth) : ''
  return dateOfBirth
    ? `Year of Birth: ${parsedDateOfBirth} ${!isNaN(age) ? `(${age} yrs)` : ''}`
    : ''
}

export const convertBETimeFormatToGivenFormat = (date, givenTimeFormat) => {
  if (date) {
    return moment(date, DATE_WITH_DASH).format(givenTimeFormat)
  }
  return ''
}

export const getCurrentDate = (format) => {
  return moment().format(format)
}

export const getCurrentUTCTime = () => moment().utc()

export const getDateWithoutYear = (date) => {
  return moment(date).format(DATE_NO_YEAR)
}

export const getStartOfLastCalendarYear = () => {
  return moment()
    .startOf('year')
    .subtract(1, 'year')
    .format(DEFAULT_UNIX_TIME_Z)
}

export const getEndOfLastCalendarYear = () => {
  return moment().endOf('year').subtract(1, 'year').format(DEFAULT_UNIX_TIME_Z)
}

export const getStartOfPriorWeekend = () => {
  // -1 is day number for previous Saturday
  // https://momentjs.com/docs/#/get-set/day/
  return moment().day(-1).startOf('day').format(DEFAULT_UNIX_TIME_Z)
}

export const getEndOfPriorWeekend = () => {
  // 0 is day number for previous Sunday
  return moment().day(0).endOf('day').format(DEFAULT_UNIX_TIME_Z)
}

// Copied from sm helpers, TODO: should be moved here
const yearArrayFromStartToCurrent = (start) => {
  let yearStart = start
  let yearEnd = parseInt(moment().year())
  return Array(yearEnd - yearStart + 1)
    .fill()
    .map(() => yearEnd--)
}

// TODO: Refactor, then reuse exported yearsFrom1950WithGuidAndName instead of duplicating the calculation in multiple places
const yearsFrom1950 = yearArrayFromStartToCurrent(1950)
export const yearsFrom1950WithGuidAndName = yearsFrom1950.map((year) => ({
  guid: year,
  name: year,
}))

export const getDiffInDays = (startDate, endDate = moment()) => {
  return moment(endDate).diff(moment(startDate), 'd')
}

export const getMostRecentTwoYearsFromNow = () => {
  return moment().utc().subtract(2, 'y').format('YYYY-MM-DD')
}

export const aggregateTotalRentingDurationInTheLast2Years = (rentalHistory) => {
  const mostRecent2YearsFrom = getMostRecentTwoYearsFromNow()
  if (rentalHistory) {
    const days = rentalHistory.reduce((total, history) => {
      const fromMoment = moment(history.from)
      const toMoment = moment(history.to)
      if (!history.isRollingLease && toMoment.isBefore(mostRecent2YearsFrom))
        return total

      if (history.isRollingLease) return moment().diff(fromMoment, 'd') + total

      const startPeriodMoment = fromMoment.isBefore(mostRecent2YearsFrom)
        ? moment(mostRecent2YearsFrom)
        : fromMoment
      const endPeriodMoment = toMoment.isAfter(moment()) ? moment() : toMoment
      const periodInDays = endPeriodMoment.diff(startPeriodMoment, 'd')
      return total + periodInDays
    }, 0)

    return helpers.getDurationInYearsMonths(days)
  }
  return 0
}

export const humanizedDateDuration = (endDate, startDate) => {
  const diff = moment(startDate)
    .startOf('day')
    .diff(moment(endDate).startOf('day'))
  return moment.duration(diff).humanize()
}

export const formattedUTCDisplayDate = (date) => {
  return !!date ? moment.utc(date).format(DisplayDateFormat) : date
}

export const formattedUTCDisplayDateWithTime = (date) => {
  return !!date ? moment.utc(date).format(DisplayDateWithTimeFormat) : date
}

export const toTimeAmPmDateFormat = (date) => {
  return moment(date).format(TIME_AM_PM_DATE)
}

export const beforeToday = (date) => moment(date).isBefore(new Date(), 'day')

export const isToday = (date) =>
  moment(date).isSame(moment(new Date(), DATE_WITH_DASH), 'day')

export const addMonthsToDate = (date, months) => {
  return !!date || !!months ? moment(date).add(months, 'months').calendar() : ''
}

export const getPropertyAvailabilityDate = (
  date,
  format = helpers.dateWithNoYearFormat,
) =>
  moment(date).isSameOrBefore(moment()) ? 'Now' : moment(date).format(format)

export const isShowUberComp = (submittedDate, appliedAt) => {
  if (
    moment()
      .utcOffset(AEDT_UTC)
      .isAfter(
        moment(uberCompNotShowingDay, 'HH:mm  DD MM YYYY').utcOffset(AEDT_UTC),
        'minute',
      )
  ) {
    return false
  }
  if (
    !submittedDate &&
    moment(appliedAt)
      .utcOffset(AEDT_UTC)
      .isBefore(
        moment(UBER_COMP_SHOWING_DAY, 'HH:mm  DD MM YYYY').utcOffset(AEDT_UTC),
        'minute',
      )
  ) {
    return false
  }
  if (
    submittedDate &&
    moment(submittedDate)
      .utcOffset(AEDT_UTC)
      .isBefore(
        moment(UBER_COMP_SHOWING_DAY, 'HH:mm  DD MM YYYY').utcOffset(AEDT_UTC),
        'minute',
      )
  ) {
    return false
  }
  return true
}

export const checkIfPetYOBIsValid = (petDate) => {
  if (petDate) {
    const invalidDate = moment(DEFAULT_UNIX_TIME, 'YYYY-MM-DD')
    const isBirthDateValid = moment(petDate, DATE_WITH_DASH).isAfter(
      invalidDate,
    )
    return isBirthDateValid
  } else return false
}

export const getDaysHoursNumFromHours = (numHours) => {
  var days = Math.floor(numHours / 24)
  var hours = Math.floor(numHours) % 24
  return { days, hours }
}

export const getStartOfCurrentWeek = () =>
  moment().startOf('week').format(DEFAULT_UNIX_TIME_Z)

export const getStartOfPriorWeek = () =>
  moment().startOf('week').subtract(1, 'week').format(DEFAULT_UNIX_TIME_Z)

export const getEndOfPriorWeek = () =>
  moment().endOf('week').subtract(1, 'week').format(DEFAULT_UNIX_TIME_Z)

export const getStartOfCurrentMonth = () =>
  moment().startOf('month').format(DEFAULT_UNIX_TIME_Z)

export const getStartOfNextMonth = () =>
  moment().startOf('month').add(1, 'month')

export const getStartOfPriorMonth = () =>
  moment().startOf('month').subtract(1, 'month').format(DEFAULT_UNIX_TIME_Z)

export const getEndOfPriorMonth = () =>
  moment().endOf('month').subtract(1, 'month').format(DEFAULT_UNIX_TIME_Z)

export const getStartOfCurrentYear = () =>
  moment().startOf('year').format(DEFAULT_UNIX_TIME_Z)

export const getHalfDayHalfMonthFormattedDate = (date) =>
  moment(date).format(HALF_DAY_HALF_MONTH_TIME_AM_PM)

export const getDateValue = (date) => moment(date).toDate()

export const formatPayloadDate = (date) => moment(date).format(DATE_WITH_DASH)

export const isTooLongTimeDiff = (date) =>
  moment().diff(moment(date), 'years') > 1000

export const isBefore = (refDate, compDate) =>
  moment(compDate).isBefore(moment(refDate))

export const ageInDays = (date) => moment().diff(moment(date), 'd')

export const nanoSecToMinute = (nano) => {
  return Math.floor(nano / 1000 / 1000 / 1000 / 60)
}

export const isDateEmpty = (date) =>
  date === INVALID_DATE_STRING || date.includes('0001')

export const getDateOfBirthText = (dateOfBirth) =>
  isDateEmpty(dateOfBirth) ? 'Not Available' : dateOfBirth
