import React, { useEffect, useState } from 'react'

import { Alert, Box } from 'app/components/design-system-components'
import { SearchRounded } from 'app/components/design-system-components/icons/actions'
import { Input } from 'app/components/design-system-components/inputs'
import { CheckboxesList } from 'app/components/design-system-components/list-filters/CheckboxesList'
import { DropdownBodySectionHeader } from 'app/components/design-system-components/list-filters/DropdownBodySectionHeader'
import { debounce } from 'app/helpers/debounce'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as keySetsHttp from 'app/services/http/teams/key-logger'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { loadingStatesIds } from 'app/utils/loading-states'

export const borrowerFiltersFields = {
  borrower: 'borrower',
}

export const isBorrowerFilterApplied = (borrowerFilterValues) =>
  borrowerFilterValues &&
  borrowerFilterValues[borrowerFiltersFields.borrower] &&
  Object.values(borrowerFilterValues[borrowerFiltersFields.borrower]).length

export const borrowerDataExtractor = (filterValues) => {
  const { [borrowerFiltersFields.borrower]: borrowersObj } = filterValues
  return {
    borrowers: Object.values(borrowersObj || {}).map(({ option }) => option),
  }
}

export const BorrowerAppliedFilterRenderer = ({ filterValues }) => {
  const { [borrowerFiltersFields.borrower]: borrower } = filterValues
  return Object.values(borrower)
    .map((borrowerObj) => borrowerObj.optionMeta.displayText)
    .join(', ')
}

// todo: move this to a separate hook
const useSearchBorrower = (teamId) => {
  const {
    state: borrowersOptions,
    setState,
    loadingStates: borrowerLoadingStates,
    promiseWrapper,
  } = useStateWithLoading()

  const onSearchBorrower = debounce((searchText) => {
    const searchPromise = keySetsHttp
      .searchBorrowers(teamId, searchText)
      .then(({ items: borrowers }) => {
        const mappedBorrowers = borrowers.map((borrower) => ({
          option: borrower,
          optionMeta: {
            id: borrower.guid,
            displayText: `${borrower.first_name} ${borrower.last_name}`,
          },
        }))
        setState(mappedBorrowers)
        return mappedBorrowers
      })
    return promiseWrapper(searchPromise)
  })

  return { borrowerLoadingStates, borrowersOptions, onSearchBorrower }
}

export const BorrowerFilterBody = ({
  formBag: { values, setFieldValue },
  teamId,
}) => {
  const { onSearchBorrower, borrowersOptions, borrowerLoadingStates } =
    useSearchBorrower(teamId)

  const [searchInput, setSearchInput] = useState('')

  const searchBorrower = ({ target: { value } }) => {
    setSearchInput(value)
    onSearchBorrower(value)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onSearchBorrower(''), [])

  const onCheckBoxClicked = (checked, option) => {
    const borrowerValues = values[borrowerFiltersFields.borrower]

    if (checked) {
      setFieldValue(borrowerFiltersFields.borrower, {
        ...borrowerValues,
        [option.optionMeta.id]: option,
      })
    } else {
      const { [option.optionMeta.id]: uncheckedOption, ...otherOptions } =
        borrowerValues
      setFieldValue(borrowerFiltersFields.borrower, {
        ...otherOptions,
      })
    }
  }

  const borrowerValues = values[borrowerFiltersFields.borrower] || {}
  const borrowerValuesArr = Object.values(borrowerValues)

  const selectedOptionsElem = borrowerValuesArr &&
    !!borrowerValuesArr.length && (
      <>
        <DropdownBodySectionHeader
          header="Selected"
          containerProps={{ mb: theme.space[4] + 'px' }}
        />
        <CheckboxesList
          options={borrowerValuesArr}
          checkedOptions={borrowerValues}
          onCheckBoxClicked={onCheckBoxClicked}
        />
      </>
    )

  const searchResultOptionsElem = borrowersOptions &&
    !!borrowersOptions.length && (
      <>
        <DropdownBodySectionHeader
          header="Results"
          containerProps={{ mb: theme.space[4] + 'px' }}
        />
        <CheckboxesList
          options={borrowersOptions}
          checkedOptions={borrowerValues}
          onCheckBoxClicked={onCheckBoxClicked}
        />
      </>
    )

  return (
    <>
      <Box p={theme.space[4] + 'px'}>
        <Input
          icon={<SearchRounded style={{ color: theme.colors.gray400 }} />}
          isLoading={borrowerLoadingStates.state === loadingStatesIds.LOADING}
          inputProps={{
            onChange: searchBorrower,
            value: searchInput,
            placeholder: 'Search for a borrower',
          }}
        />
      </Box>

      <Box style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {!!borrowerLoadingStates.error && (
          <Alert variant="primaryWithBg" m={theme.space[4] + 'px'}>
            {borrowerLoadingStates.error.message}
          </Alert>
        )}

        {selectedOptionsElem}
        {searchResultOptionsElem}
      </Box>
    </>
  )
}
