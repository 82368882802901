import React from 'react'

import { DragDropContext, DragDropContextProvider, DropTarget } from 'react-dnd'
import HTML5Backend, { NativeTypes } from 'react-dnd-html5-backend'

import { Box } from 'app/components/design-system-components'
import DocumentListText from 'app/shared_components/document_list/component/document_list_text'
import PhotosListItem from 'app/sm/ppp/property_components/property_form/photos_list_item'
import PhotosListMainItem from 'app/sm/ppp/property_components/property_form/photos_list_main_item'
import { SUBTEXT_FILE_UPLOAD_IMAGE_FILES } from 'app/utils/text/helpers'

const listTarget = {
  drop: function (props, monitor) {
    props.updatePhotos(monitor.getItem().files)
  },
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
  }
}

class UploadPlaceholder extends React.Component {
  render() {
    const { connectDropTarget } = this.props

    return connectDropTarget(
      <input
        id="photos"
        type="file"
        onChange={(event) => {
          this.props.updatePhotos()
          event.target.value = null
        }}
        multiple
      />,
    )
  }
}

const WrappedUploadPlaceholder = DropTarget(
  NativeTypes.FILE,
  listTarget,
  collect,
)(UploadPlaceholder)

class PhotosList extends React.Component {
  render() {
    const { images = [{}] } = this.props

    return (
      <DragDropContextProvider backend={HTML5Backend}>
        <ul className="sm-property-photos__list">
          {images
            .filter((e) => Boolean(e.isMain))
            .map((photo, index) => (
              <PhotosListMainItem
                photo={photo}
                key={index}
                deletePhoto={this.props.deletePhoto}
                setMainImage={this.props.setMainImage}
                property={this.props.property}
              />
            ))}
          {images
            .filter((e) => !Boolean(e.isMain))
            .map((photo, index) => (
              <PhotosListItem
                photo={photo}
                key={index}
                deletePhoto={this.props.deletePhoto}
                property={this.props.property}
                setMainImage={this.props.setMainImage}
              />
            ))}
          <li className={images.length > 0 ? 'add-new' : 'add-new single'}>
            <form id="property-photos-upload">
              <WrappedUploadPlaceholder
                images={images}
                updatePhotos={this.props.updatePhotos}
              />
              <label htmlFor="photos">
                {images.length > 0 ? (
                  <div>
                    <i className="icon-add green-color"></i>
                    <span>Add more</span>
                  </div>
                ) : (
                  <div>
                    <i className="icon-image"></i>
                    <b>Upload manually</b>
                    <span>or drag and drop a photo here.</span>
                    <Box mt={3}>
                      <DocumentListText
                        textToDisplay={SUBTEXT_FILE_UPLOAD_IMAGE_FILES}
                      />
                    </Box>
                  </div>
                )}
              </label>
            </form>
          </li>
        </ul>
      </DragDropContextProvider>
    )
  }
}

export default DragDropContext(HTML5Backend)(PhotosList)
