import React from 'react'

import styled from 'styled-components'

import {
  Alert,
  Box,
  Button,
  Flex,
} from 'app/components/design-system-components'
import { InfoRounded } from 'app/components/design-system-components/icons/actions'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledRRNavbar = styled(Flex)`
  background-color: #faf8f8;
  color: #4d4d4d;
  flex-direction: column;
  padding: 24px;
  position: absolute;
  margin: -10px;
  height: 100%;
  width: 100%;
`

const toggleChat = () => {
  const { zE } = window
  zE?.activate()
}

function RRNavbar({}) {
  return (
    <StyledRRNavbar>
      <Box pl={6}>
        <i class="icon-documents"></i>
        <Text as="span" pl={3}>
          References
        </Text>
      </Box>
      <Box mt={5}>
        <Alert variant="blueWithBg">
          <Flex>
            <Box mr={3} mt="2px">
              <InfoRounded color={theme.colors.deepBlue} />
            </Box>
            <Box fontSize={theme.fontSizes.pRegular14}>
              <Text
                color={theme.colors.deepBlue}
                fontWeight={theme.fontWeights[6]}
                mb={0}
              >
                Learn More
              </Text>
              <Text color={theme.colors.skyBlue} mb={4}>
                Chat to our Customer Success Team
              </Text>
              <Button
                variant="outlinePrimary"
                sizeVariant="small"
                onClick={() => toggleChat()}
              >
                Contact Us
              </Button>
            </Box>
          </Flex>
        </Alert>
      </Box>
    </StyledRRNavbar>
  )
}

export default RRNavbar
