import { connect } from 'react-redux'

import { createNote } from 'app/agency/agency_action'
import { fetchAllPropertyFutureViewings } from 'app/bond_cover/agency/agency_actions'
import ViewingDetails from 'app/pages/teams/viewings/ViewingDetails/index'
import {
  addWalkInRenter,
  cancelViewing,
  clearError,
  editViewing,
  fetchAgencyApplicantsElasticSearchResult,
  fetchPropertyViewings,
  notifyLateViewing,
  removeOnsiteViewingRenter,
  requestFeedback,
  sendIndividualInviteToApply,
  sendInviteToApplyToAllRegistrants,
  showCommentModal,
  showEditViewingModal,
  showLateViewingModal,
  toggleWalkInModal,
  updateRenterStatus,
  updateRenterStatusComment,
} from 'app/sm/inspections/inspections_actions'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({ session, ViewingsReducer }) => ({
  currentTeam: session.currentTeam,
  renterInfo: ViewingsReducer.renterInfo,
  checkboxSpinnerGuidArray: ViewingsReducer.checkboxSpinnerGuidArray,
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  toggleWalkInModal: (isWalkInModalOn, viewing) =>
    dispatch(toggleWalkInModal(isWalkInModalOn, viewing)),
  showEditViewingModal: (isEditViewModalOn, viewingTime) =>
    dispatch(showEditViewingModal(isEditViewModalOn, viewingTime)),
  sendInviteToApplyToAllRegistrants: (viewingGUID) =>
    dispatch(sendInviteToApplyToAllRegistrants(viewingGUID)),
  requestFeedback: (viewingGUID, success) =>
    dispatch(requestFeedback(viewingGUID, success)),
  cancelViewing: (viewingGUID, propertyId) =>
    dispatch(cancelViewing(viewingGUID, propertyId)),
  removeOnsiteViewingRenter: (viewingGUID, registrationGUID) =>
    dispatch(removeOnsiteViewingRenter(viewingGUID, registrationGUID)),
  sendIndividualInviteToApply: (viewingId, registrantGUID) =>
    dispatch(sendIndividualInviteToApply(viewingId, registrantGUID)),
  updateRenterStatus: (payload, renterType, id, registrationGUID, propertyId) =>
    dispatch(
      updateRenterStatus(payload, renterType, id, registrationGUID, propertyId),
    ),
  addWalkInRenter: (data, propertyId, viewingId) =>
    dispatch(addWalkInRenter(data, propertyId, viewingId)),
  fetchAgencyApplicantsElasticSearchResult: (teamId, queryString) =>
    dispatch(fetchAgencyApplicantsElasticSearchResult(teamId, queryString)),
  showCommentModal: (isCommentModalOn, renterInfo) =>
    dispatch(showCommentModal(isCommentModalOn, renterInfo)),
  showLateViewingModal: (isLateModalOn, viewing) =>
    dispatch(showLateViewingModal(isLateModalOn, viewing)),
  clearError: () => dispatch(clearError()),
  notifyLateViewing: (data) => dispatch(notifyLateViewing(data)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
  updateRenterStatusComment: (payload) =>
    dispatch(updateRenterStatusComment(payload)),
  editViewing: (
    propertyId,
    offerId,
    viewingId,
    startdate,
    duration,
    skipDoubleBookingCheck,
    viewingOptions,
  ) =>
    dispatch(
      editViewing(
        propertyId,
        offerId,
        viewingId,
        startdate,
        duration,
        skipDoubleBookingCheck,
        viewingOptions,
      ),
    ),
  createNote: (payload, ownerGUID, ownerType) =>
    dispatch(createNote(payload, ownerGUID, ownerType)),
  fetchAllPropertyFutureViewings: (teamGUID) =>
    dispatch(fetchAllPropertyFutureViewings(teamGUID)),
  fetchPropertyViewings: (params) => dispatch(fetchPropertyViewings(params)),
})

export const ViewingDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewingDetails)
