import React from 'react'

import { ApplicantItem } from 'app/match/apply/application_summary_before_submit/components/household_container/component'
import HeaderLg from 'app/sm/progress_application/components/header_lg/component'
import SectionContainer from 'app/sm/progress_application/components/section_container/component'
import { sortApplicants } from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'

const Household = ({ applicants = [], application = {} }) => {
  return (
    <SectionContainer>
      <HeaderLg title="Household" />
      <div className="household-applicants-container">
        {applicants &&
          applicants.sort(sortApplicants).map((applicant, index) => {
            return (
              <ApplicantItem
                applicant={applicant}
                application={application}
                key={index}
              />
            )
          })}
      </div>
    </SectionContainer>
  )
}

export default Household
