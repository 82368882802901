import { updateOfferPublishingOptions } from 'app/services/http/offers'
import { history } from 'app/shared_components/router'
import { encodeShareParams, urlIds, urlTo, wait } from 'app/sm/helpers'
import {
  addSettings,
  getSettings,
  shouldUpdateOfferAd,
} from 'app/sm/profile/profile_access'
import { getPropertySummary } from 'app/sm/property_details/property_details_access'
import store from 'app/store'
import { FeatureFlag } from 'config/features'

export const PING = 'PO_PING'
export const RECEIVE_PROFILE = 'PO_RECEIVE_PROFILE'
export const TOGGLE_SPINNER = 'PO_TOGGLE_SPINNER'
export const UPDATE_FIELD = 'PO_UPDATE_FIELD'
export const RECEIVE_PROPERTY = 'PO_RECEIVE_PROPERTY'
export const TRIGGER_MODAL = 'PO_TRIGGER_MODAL'
export const CANCEL_MODAL = 'PO_CANCEL_MODAL'
export const CHANGE_PREFERENCES_METHOD = 'PO_CHANGE_PREFERENCES_METHOD'
export const RECEIVE_ERROR = 'PO_RECEIVE_ERROR'
export const CLEAR_ERROR = 'PO_CLEAR_ERROR'
export const RECEIVE_RESPONSE_TEXT = 'PO_RECEIVE_RESPONSE_TEXT'
export const RESET_PROFILE = 'PO_RESET_PROFILE'

export const steps = {
  GetStarted: 1,
  Preferences: 2,
  LettingDetails: 3,
  Basics: 4,
  OfferDetails: 5,
  Amenities: 6,
  Description: 7,
  Viewings: 8,
  Share: 9,
  PublishingOptions: 'publishingOptions',
}

export const ping = () => ({ type: PING })
export const updateField = (key, val) => ({ type: UPDATE_FIELD, key, val })

const receiveProfile = (profile) => ({ type: RECEIVE_PROFILE, profile })
const receiveProperty = (property) => ({ type: RECEIVE_PROPERTY, property })
const toggleSpinner = () => ({ type: TOGGLE_SPINNER })
const alterPreferencesMethod = (preferencesMethod) => ({
  type: CHANGE_PREFERENCES_METHOD,
  preferencesMethod,
})
const resetProfile = () => ({ type: RESET_PROFILE })

export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })
export const clearError = () => ({ type: CLEAR_ERROR })
export const triggerModal = () => ({ type: TRIGGER_MODAL })
export const cancelModal = () => ({ type: CANCEL_MODAL })

function move(path) {
  history.push(path)
}

function transact(step, data) {
  // For now we save the settings on Preferences step

  if (step === steps.Preferences) {
    const { preferencesMethod } = store.getState().Profile
    return addSettings(data, preferencesMethod).then(({ ok, responseText }) => {
      if (ok) {
        store.dispatch(receiveResponseText(''))
        return Promise.resolve('some outcome')
      } else {
        responseText.then((t) => {
          store.dispatch(receiveResponseText(t))
        })

        return responseText.then((t) => Promise.reject(t))
      }
    })
  }

  if (step === steps.PublishingOptions) {
    const { guid, publishingOption } = data
    const publishOptionBody = {
      publishingOption,
    }
    receiveResponseText('')
    return updateOfferPublishingOptions(guid, publishOptionBody).catch(
      ({ message }) => {
        store.dispatch(receiveResponseText(message))
      },
    )
  }

  // Just a simulation that something is happening in future this should be removed
  // because the real api calls would give some latency
  return wait(50)
}

export function update(step, data) {
  return (dispatch, getState) => {
    dispatch(toggleSpinner())
    transact(step, data)
      .then(() => {
        dispatch(toggleSpinner())
        switch (step) {
          case steps.GetStarted: {
            const { currentTeam } = getState().session
            const teamSlug = currentTeam && currentTeam.slug
            move(urlTo('createProperty', { teamSlug }))
            break
          }
          case steps.PublishingOptions:
            const { guid } = data
            move(urlTo(urlIds.offerPreferences, { offerId: guid }))
            break
          case steps.Preferences: {
            let { advertisement: prefsPropertyOffer } =
              getState().Advertisements
            let { guidID: prefsPropertyOfferId } = prefsPropertyOffer
            const {
              property: { slug: propertySlug },
            } = prefsPropertyOffer ? prefsPropertyOffer : { property: {} }
            const nextStepUrl = FeatureFlag.Viewings.isOn
              ? urlTo('profileViewings', { offerId: prefsPropertyOfferId })
              : encodeShareParams(prefsPropertyOfferId, propertySlug)
            move(nextStepUrl)
            break
          }
          case steps.Viewings: {
            let { advertisement: viewingsPropertyOffer } =
              getState().Advertisements
            let {
              guidID: viewingsPropertyOfferId,
              property: { slug: propertySlug },
            } = viewingsPropertyOffer.property
              ? viewingsPropertyOffer
              : { property: {} }
            move(encodeShareParams(viewingsPropertyOfferId, propertySlug))
            break
          }
          case steps.Share: {
            let {
              property: { guidID },
            } = getState().Profile
            const { currentTeam } = getState().session
            const teamSlug = currentTeam && currentTeam.slug
            teamSlug
              ? move(urlTo('teamOverview', { teamSlug }))
              : move(urlTo('propertyDashboard', { propertyId: guidID }))
            break
          }
          default:
            move(urlTo('applications'))
        }
      })
      .catch((t) => {
        store.dispatch(receiveResponseText(t))
        dispatch(toggleSpinner())
      })
  }
}

export function fetchProfile(offerId) {
  return (dispatch) => {
    // dispatch(receiveProfile(mock.profile))

    getSettings(offerId).then(({ ok, responseText, settings }) => {
      if (ok) {
        dispatch(receiveProfile(settings))
        dispatch(alterPreferencesMethod('PUT'))
      } else {
        dispatch(resetProfile())
        // responseText.then(t => store.dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function fetchProperty(slug) {
  return (dispatch) => {
    getPropertySummary(slug).then(({ ok, summary }) => {
      if (ok) {
        const { property } = summary
        dispatch(receiveProperty(property))
      }
    })
  }
}

export function shouldUpdateAd(propertyId, should = true) {
  store.dispatch(clearError())
  return (dispatch) => {
    if (should) {
      return shouldUpdateOfferAd(propertyId).then(({ ok }) => {
        dispatch(cancelModal())

        if (ok) {
          move(urlTo('applications'))
        }
      })
    }

    // In case no is pressed just redirect to applications and invalidate the modal
    dispatch(cancelModal())
    move(urlTo('applications'))
  }
}
