import React from 'react'

import styled from 'styled-components'

import account1 from 'app/assets/icons/account1.svg'
import bgCheckExample from 'app/assets/icons/new-bg-check-example.png'
import pay from 'app/assets/icons/pay.svg'
import purchaseOrder from 'app/assets/icons/purchase_order.svg'
import {
  Alert,
  Box,
  Button,
  Flex,
} from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { history } from 'app/shared_components/router'
import * as smHelpers from 'app/sm/helpers'
import * as textHelpers from 'app/utils/text/helpers'

const StyledStep = styled(Flex)`
  img {
    height: ${theme.width[5]}px;
    width: ${theme.width[5]}px;
  }
  line-height: ${theme.lineHeights.title};
`

const StyledImageContainer = styled(Flex)`
  height: ${theme.height[8]}px;
  width: ${theme.width[8]}px;
  align-items: center;
  justify-content: center;
`

function BGStep({ step, stepImg, stepDescription }) {
  return (
    <StyledStep alignItems="center" mb={4}>
      <StyledImageContainer>
        <img src={stepImg} alt="step" />
      </StyledImageContainer>
      <Box>
        <Box>
          <Text
            as="span"
            fontSize={theme.fontSizes[5] + 'px'}
            fontWeight={theme.fontWeights[6]}
          >
            {step}
          </Text>
        </Box>
        <Box>
          <Text
            as="span"
            fontSize={theme.fontSizes[4] + 'px'}
            fontWeight={theme.fontWeights[3]}
            color="gray600"
          >
            {stepDescription}
          </Text>
        </Box>
      </Box>
    </StyledStep>
  )
}

function HowBGCheckWorks() {
  return (
    <Box>
      <Text
        fontSize={theme.fontSizes[6] + 'px'}
        fontWeight={theme.fontWeights[6]}
        mb={0}
      >
        How the Background Check works
      </Text>
      <Text>
        Snug generates an instant report using the Equifax National Tenancy
        Database (NTD) including the Australian Government’s Document
        Verification Service (DVS) to confirm your identity, tenancy database
        (blacklist), company directorships, court, visa and bankruptcy records.
        Full details can be found in Equifax’s{' '}
        <a
          href="https://www.equifax.com.au/sites/default/files/Equifax%20Terms%20of%20Supply%2015062022.pdf"
          target="_blank"
          rel="noreferrer"
        >
          terms of supply
        </a>{' '}
        and{' '}
        <a
          href="https://www.tenancydatabase.com.au/terms"
          target="_blank"
          rel="noreferrer"
        >
          NTD
        </a>
        .
      </Text>
      <BGStep
        step="1. Enter your details"
        stepImg={account1}
        stepDescription="Enter your current address and identity document details"
      />

      <BGStep
        step="2. Purchase the instant report"
        stepImg={pay}
        stepDescription="Easily pay online with credit card"
      />

      <BGStep
        step="3. View your results"
        stepImg={purchaseOrder}
        stepDescription="You can view the results and choose to attach or discard them"
      />

      <Alert variant="blueWithBg" mb={13}>
        <Box>
          <Text fontWeight={theme.fontWeights[6]} color="deepBlue" mb={1}>
            You’re in control
          </Text>
          <Text fontWeight={theme.fontWeights[3]} mb={0}>
            It’s your choice to include the report results on your profile. A
            positive result helps strengthen your application and stand out,{' '}
            <a
              href={textHelpers.BGC_LEARN_MORE_URL}
              target="_blank"
              rel="noreferrer"
            >
              learn more
            </a>
            .
          </Text>
        </Box>
      </Alert>

      <Text
        fontSize={theme.fontSizes[6] + 'px'}
        fontWeight={theme.fontWeights[6]}
        mb={4}
      >
        What's included?
      </Text>
      <img src={bgCheckExample} alt="Example Background Check" />
      <Text
        fontSize={theme.fontSizes[4] + 'px'}
        fontWeight={theme.fontWeights[3]}
        mt={8}
        color="pdfGray"
      >
        *Based on Snug traffic to June 2022.
      </Text>

      <Button
        variant="solidSuccessStrong"
        onClick={() => {
          history.push(smHelpers.urlTo('BackgroundCheck'))
        }}
      >
        Continue with Background Check
      </Button>
    </Box>
  )
}

export default HowBGCheckWorks
