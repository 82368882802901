import React from 'react'

import { mailDomain } from 'config/env'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import BrandingUploader from 'app/agency/branding_uploader/component'
import AgencySearchItem from 'app/bond_cover/agency/agency_search_item'
import AgencySourceForm from 'app/bond_cover/agency/agency_source_form_connection'
import * as constants from 'app/bond_cover/agency/constants'
import EFTPaymentsSetting from 'app/bond_cover/agency/EFT_payments_setting'
import InviteForm from 'app/bond_cover/agency/invite_form'
import { KolmeoIntegration } from 'app/bond_cover/agency/kolmeo_integration'
import LastAdminRemoveModal from 'app/bond_cover/agency/last_admin_remove_modal'
import { ManagedAppIntegration } from 'app/bond_cover/agency/managed_app_integration'
import PaidPlanContinueModal from 'app/bond_cover/agency/modal-paid-plan-continue'
import AgencySearchBox from 'app/bond_cover/agency/search_box'
import * as utils from 'app/bond_cover/agency/utils'
import {
  Alert,
  Box,
  Flex,
  IntlTelInputWrapper,
  Modal,
  TextSpan,
} from 'app/components/design-system-components'
import { LaunchRounded } from 'app/components/design-system-components/icons/actions'
import { WarningRounded } from 'app/components/design-system-components/icons/alert'
import { Input } from 'app/components/design-system-components/inputs'
import { Text } from 'app/components/design-system-components/typography'
import * as NewForm from 'app/components/forms/forms'
import {
  CheckBoxGeneral,
  Dropdown,
  InputTextRequired,
} from 'app/components/forms/forms'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages, {
  translateErrorCodeToMessage,
} from 'app/constants/error_messages'
import { portalBrands } from 'app/constants/portals.constants'
import {
  COMPARE_AND_CONNECT,
  CONNECT_NOW,
  DIRECT_CONNECT,
  FAST_CONNECT,
  FLETCHERS_CONCIERGE,
  FOXIE,
  HOME_NOW,
  HOMENOW_CONNECT,
  HOOD,
  LAING_SIMMONDS_CONNECT,
  LJ_HOOKER_ASSIST,
  MOVING_HUB,
  MY_CONNECT,
  ON_THE_MOVE,
  PRD_SMARTER_CONNECT,
  RAINE_HORNE,
  TEN_ANTS,
  THE_WHOLE_HOUSE,
  UCS_PARTNER_DEMO,
  YOUR_PORTER,
} from 'app/constants/ucs.constants'
import theme from 'app/match/applicationReportPDF/assets/theme'
import ActivationConfirmationModal from 'app/pages/DomainPortal/ActivationConfirmationModal'
import {
  addAgencyAliasId,
  deleteAgencyAliasId,
  getActivationLatestLog,
} from 'app/services/http/agency/admin'
import { getPUM } from 'app/services/http/pms'
import { checkIfPortalIDExistsForOtherAgencies } from 'app/services/http/portals'
import { getSmsUsageStats } from 'app/services/http/sms'
import * as snugNotifier from 'app/services/snugNotifier'
import * as Form from 'app/shared_components/form_component'
import {
  CommentArea,
  InputOptional,
  UtilityProviderDropdown,
} from 'app/shared_components/form_component'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import * as validations from 'app/shared_components/validations'
import {
  AgencyBrandingType,
  entryNoticeRegulationsOptions,
  parseDataUtil,
} from 'app/sm/helpers'
import { applicationsAttributesIDs } from 'app/sm/team_viewing_preference/applications-attributes.constants'
import * as accountHelpers from 'app/utils/accounts/helpers'
import {
  formatTime,
  HALF_DAY_HALF_MONTH_TIME_AM_PM,
} from 'app/utils/datetime/helpers'
import * as leaseOfferHelpers from 'app/utils/leaseOffer'
import * as PMSHelpers from 'app/utils/pms/helpers'
import {
  covertBoolStringToBool,
  covertBoolToString,
} from 'app/utils/strings/helpers'

export const DIRECT_CONNECT_DEFAULT_PROVIDER =
  DIRECT_CONNECT + ' (Default Provider)'

// Index needs to match the order in the options in
// utilityConnectionOptions for UtilityProviderDropdown component as
// it expects the value to be the index. If index changes,
// value changes as well and would lead to incorrect info
// being shown and cause issues.
// Do not change the order. Only append to the array.
const DIRECT_CONNECT_DEFAULT_PROVIDER_INDEX = 21

const utilityConnectionOptions = [
  '<Empty>',
  FOXIE,
  FAST_CONNECT,
  MOVING_HUB,
  DIRECT_CONNECT,
  ON_THE_MOVE,
  COMPARE_AND_CONNECT,
  PRD_SMARTER_CONNECT,
  MY_CONNECT,
  YOUR_PORTER,
  HOME_NOW,
  CONNECT_NOW,
  THE_WHOLE_HOUSE,
  LJ_HOOKER_ASSIST,
  UCS_PARTNER_DEMO,
  RAINE_HORNE,
  HOOD,
  LAING_SIMMONDS_CONNECT,
  TEN_ANTS,
  HOMENOW_CONNECT,
  FLETCHERS_CONCIERGE,
  DIRECT_CONNECT_DEFAULT_PROVIDER,
]

const applicationCustomisationCheckBoxes = [
  {
    id: 'postingClause',
    label: 'Posting clause',
    field: 'postClauseEnabled',
    enableEdit: true,
  },
  {
    id: 'bondloan',
    label: 'Bond loan',
    field: 'bondLoanEnabled',
    enableEdit: true,
  },
  {
    id: 'smokerDeclaration',
    label: 'Smoker declaration',
    field: 'smokerDeclarationEnabled',
    enableEdit: true,
  },
  {
    id: 'sendingPersonalReferenceRequest',
    label: 'Enable sending personal reference request',
    field: 'sendingPersonalReferenceRequest',
    enableEdit: true,
  },
  {
    id: 'requestCarSpace',
    label: 'Request a car space',
    field: 'requestCarSpace',
    enableEdit: true,
  },
  {
    id: 'disableChildrenOption',
    label: 'Disable children option',
    field: 'disableChildrenOption',
    enableEdit: true,
  },
  {
    id: 'filterIncompleteApplicationsFromPartnerAPI',
    label: 'Filter incomplete applications from partner applications API',
    field: 'filterIncompleteApplicationsFromPartnerAPI',
    enableEdit: true,
  },
]
const utilityConnectionCollection = [
  {
    name: '<Empty>',
    value: 0,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: FOXIE,
    value: 1,
    requirementMsg: '',
    isProviderAgentIDRequired: true,
    isRefIDRequired: true,
  },
  {
    name: FAST_CONNECT,
    value: 2,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: true,
  },
  {
    name: MOVING_HUB,
    value: 3,
    requirementMsg: '',
    isProviderAgentIDRequired: true,
    isRefIDRequired: false,
  },
  {
    name: DIRECT_CONNECT,
    value: 4,
    requirementMsg:
      'Please ensure the Direct Connect user IDs are added to each user below',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: ON_THE_MOVE,
    value: 5,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: COMPARE_AND_CONNECT,
    value: 6,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: PRD_SMARTER_CONNECT,
    value: 7,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: MY_CONNECT,
    value: 8,
    requirementMsg: '',
    isProviderAgentIDRequired: true,
    isRefIDRequired: false,
  },
  {
    name: YOUR_PORTER,
    value: 9,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: HOME_NOW,
    value: 10,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: CONNECT_NOW,
    value: 11,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isProviderAgentIDOptional: true,
    isRefIDRequired: false,
  },
  {
    name: THE_WHOLE_HOUSE,
    value: 12,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: LJ_HOOKER_ASSIST,
    value: 13,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: UCS_PARTNER_DEMO,
    value: 14,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: RAINE_HORNE,
    value: 15,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: HOOD,
    value: 16,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: LAING_SIMMONDS_CONNECT,
    value: 17,
    requirementMsg: '',
    isProviderAgentIDRequired: true,
    isRefIDRequired: true,
    customAgentIdLabel: 'Office API Key',
    customAgentRefIdLabel: 'Referrer Code',
  },
  {
    name: TEN_ANTS,
    value: 18,
    requirementMsg: '',
    isProviderAgentIDRequired: true,
    isRefIDRequired: false,
    customAgentIdLabel: 'Partner Code',
  },
  {
    name: HOMENOW_CONNECT,
    value: 19,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: FLETCHERS_CONCIERGE,
    value: 20,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
  {
    name: DIRECT_CONNECT_DEFAULT_PROVIDER,
    value: DIRECT_CONNECT_DEFAULT_PROVIDER_INDEX,
    requirementMsg: '',
    isProviderAgentIDRequired: false,
    isRefIDRequired: false,
  },
]

const isUtilityProviderSelected = (utilityProvider) =>
  utilityProvider.name && utilityProvider.name !== '<Empty>'

const enquiryResponseModeMap = {
  0: 'none',
  1: 'selected',
  2: 'all',
}

const enquiryResponseModeNumber2String = (modeNumber) => {
  const id = Object.keys(enquiryResponseModeMap).find(
    (key) => parseInt(key) === modeNumber,
  )
  return id !== undefined ? enquiryResponseModeMap[id] : undefined
}

const enquiryResponseModeString2Number = (modeString) => {
  const id = Object.keys(enquiryResponseModeMap).find(
    (key) => enquiryResponseModeMap[key] === modeString,
  )
  return id !== undefined ? parseInt(id) : undefined
}

const bondDurationOptionArray = [
  { guid: 4, name: '4 weeks' },
  { guid: 6, name: '6 weeks' },
]

const domainIntegrationLabel = 'Domain.com.au'

const getDomainAgencyId = (aliases = []) =>
  aliases && aliases.find((alias) => alias.label === domainIntegrationLabel)

class RegisterAgencyForm extends React.Component {
  constructor(props) {
    super(props)
    const {
      companyName = '',
      slug = '',
      ABN = '',
      ACN = '',
      contactNumber = '',
      contactEmail = '',
      realEstateLicenseNumber = '',
      enquiryEmail = '',
      smsRegistrationConfirmation = false,
      smsViewingCreated = false,
      smsViewingChanges = false,
      smsEnNewCancelledViewing = false,
      isActive = false,
      agency = { name: '' },
      utilityProvider = {},
      receiveEnquiry = false,
      enquiryResponseMode = 2,
      whitelistedProperties = '',
      emailLocalPart = '',
      externalSnugTeamID = '',
      aliases = [],
      suggestedPropertiesEnabled = true,
      activityTabEnabled = false,
      defaultListingProfile = true,
      defaultListingProfileFirstName = '',
      defaultListingProfileLastName = '',
      defaultListingProfileEmail = '',
      defaultListingProfileMobile = '',
      forceDefaultListingProfile = false,
      brandingEnabled = false,
      brandingBannerURL = '',
      brandingLogoURL = '',
      viewingRunEnabled = true,
      advancedActivityEnabled = false,
      attributes: {
        leaseattachments = {},
        branding = {},
        notifications = {},
        application = {},
        integrations = {},
        viewings = {},
        properties = {},
        sms = {},
        bundle = {},
        users = {},
        billing = {},
      } = {},
      paidPlansEnabled = true,
      integrationsEnabled = true,
      configureInbox = true,
      connectUtilityProvider = true,
      connectPropertyUploader = true,
      setupLeaseOffer = true,
      smsViewingAttendeeDayBeforeEnabled = false,
      smsLeaseOffer = false,
      smsRequestInformation = false,
      enableBackgroundCheckBundle = false,
      backgroundChecksReportingEnabled = false,
      enableKeyManagement = false,
      enquiry_sms_response = false,
      accountType = accountHelpers.ACCOUNT_TYPE_LITE,
      enableTravelTimeOptimisation: enableTravelTimeOptimisationOption = false,
      enableNeighbouringProperties: enableNeighbouringPropertiesOption = false,
      rentRoll,
      // stripeSubscriptionID = '',
      stripeCustomerIDModalOpen = false,
      stripeSubscriptionIDModalOpen = false,
    } = this.props.initialState || {}

    const external_agency_id_enabled =
      integrations &&
      covertBoolStringToBool(integrations.external_agency_id_enabled)
    let parsedApplication
    parsedApplication = application
      ? parseDataUtil.convertObjValueFromStringToBoolean(application)
      : {}
    const domainExternalId = getDomainAgencyId(aliases)?.teamID
    this.state = {
      isEasyBondpayOrBondLoanEnabled: false,
      clicked: false,
      errors: {},
      invitesErrors: [{}],
      companyName,
      agencySlug: slug,
      contactNumber,
      contactEmail,
      ABN,
      ACN,
      realEstateLicenseNumber,
      utilityProvider: utilityProvider || {},
      utilityProviderError: '',
      receiveEnquiry,
      smsRegistrationConfirmation,
      smsViewingCreated,
      smsViewingChanges,
      smsEnNewCancelledViewing:
        sms && sms.sms_en_new_cancelled_viewing === 'true',
      smsViewingAttendeeDayBeforeEnabled,
      smsLeaseOffer,
      smsRequestInformation,
      enableBackgroundCheckBundle,
      backgroundChecksReportingEnabled,
      enableKeyManagement,
      enquiryEmail,
      enquiryResponseMode,
      whitelistedProperties,
      emailLocalPart,
      externalSnugTeamID,
      domainExternalId,
      enableDomainIntegration: !!domainExternalId && external_agency_id_enabled,
      domainIntegrationUnsavedChanges: false,
      isActive,
      invites: [
        {
          firstName: '',
          lastName: '',
          email: '',
          isAdmin: false,
          isFinance: false,
          isLeasingConsultant: false,
          agentUtilityProviderUserID: '',
        },
      ],
      invitesNumber: 1,
      agency,
      agencyExist: true,
      unsavedChanges: false,
      agencyGUID: this.props.agencyGUID,
      agencyProfile: this.props.agencyProfile,
      suggestedPropertiesEnabled,
      activityTabEnabled,
      defaultListingProfile: true,
      defaultListingProfileFirstName,
      defaultListingProfileLastName,
      defaultListingProfileEmail,
      defaultListingProfileMobile,
      forceDefaultListingProfile,
      brandingEnabled,
      brandingBannerURL,
      brandingLogoURL,
      bannerUploadError: '',
      logoUploadError: '',
      logoValidationError: '',
      bannerValidationError: '',
      viewingRunEnabled,
      advancedActivityEnabled,
      external_agency_id_enabled,
      console_cloud_enabled:
        integrations &&
        covertBoolStringToBool(integrations.console_cloud_enabled),
      propertyTreeEnabled:
        integrations &&
        covertBoolStringToBool(integrations.property_tree_enabled),
      propertyMeEnabled:
        integrations &&
        covertBoolStringToBool(integrations.property_me_enabled),
      vaultREEnabled:
        integrations && covertBoolStringToBool(integrations.vault_re_enabled),
      productivityBundleEnabled:
        bundle && covertBoolStringToBool(bundle.productivity_bundle),
      integrationsBundleEnabled:
        bundle && covertBoolStringToBool(bundle.integrations_bundle),
      smsBundleEnabled: bundle && covertBoolStringToBool(bundle.sms_bundle),
      bgcAllInclusiveBundleEnabled:
        bundle && covertBoolStringToBool(bundle.bgc_all_inclusive_bundle),
      console_cloud_external_agency_id:
        integrations && integrations.console_cloud_external_agency_id
          ? integrations.console_cloud_external_agency_id
          : '',
      easy_bond_pay_enabled:
        integrations &&
        covertBoolStringToBool(integrations.easy_bond_pay_enabled),
      easy_bond_pay_broker_login_id:
        integrations && integrations.easy_bond_pay_broker_login_id
          ? integrations.easy_bond_pay_broker_login_id
          : '',
      easy_bond_pay_broker_login_password:
        integrations && integrations.easy_bond_pay_broker_login_password
          ? integrations.easy_bond_pay_broker_login_password
          : '',
      viewing_run_enabled:
        viewings && covertBoolStringToBool(viewings.viewing_run_enabled),
      viewing_run_mode:
        viewings && viewings.viewing_run_mode
          ? viewings.viewing_run_mode
          : 'notSelected',
      displayExcludedMarketFeedback:
        viewings &&
        covertBoolStringToBool(viewings.display_excluded_market_feedback),
      EFTPaymentEnabled:
        leaseattachments && leaseattachments.EFT
          ? JSON.parse(leaseattachments.EFT)
          : false,
      DEFTPaymentEnabled:
        leaseattachments && leaseattachments.DEFT
          ? JSON.parse(leaseattachments.DEFT)
          : false,
      BPAYPaymentEnabled:
        leaseattachments && leaseattachments.BPAY
          ? JSON.parse(leaseattachments.BPAY)
          : false,
      snugCCPaymentEnabled:
        leaseattachments && leaseattachments.snug_pay
          ? JSON.parse(leaseattachments.snug_pay)
          : false,
      EFTPaymentBSB:
        leaseattachments && leaseattachments.rent_bsb
          ? leaseattachments.rent_bsb
          : '',
      EFTPaymentAccountNum:
        leaseattachments && leaseattachments.rent_account
          ? leaseattachments.rent_account
          : '',
      EFTPaymentAccountName:
        leaseattachments && leaseattachments.rent_eft_account_name
          ? leaseattachments.rent_eft_account_name
          : '',

      EFTPaymentsV2: utils.convertToEFTValues(leaseattachments),

      BPAYPaymentBillerCode:
        leaseattachments && leaseattachments.rent_biller
          ? leaseattachments.rent_biller
          : '',
      offerEmailMessage:
        leaseattachments && leaseattachments.email_message
          ? leaseattachments.email_message
          : '',
      congratulationsMessage:
        leaseattachments && leaseattachments.message
          ? leaseattachments.message
          : '',
      initialRentPaymentInstructions:
        leaseattachments && leaseattachments.deposit_message
          ? leaseattachments.deposit_message
          : '',
      bondPaymentInstructions:
        leaseattachments && leaseattachments.bond_message
          ? leaseattachments.bond_message
          : '',
      remainingRentPaymentInstructions:
        leaseattachments && leaseattachments.rent_message
          ? leaseattachments.rent_message
          : '',
      leaseSigningInstructions:
        leaseattachments && leaseattachments.lease_signing_message
          ? leaseattachments.lease_signing_message
          : '',
      importantInformationMessage:
        leaseattachments && leaseattachments.important_message
          ? leaseattachments.important_message
          : '',
      brandingSMSSender:
        branding && branding.branding_sms_sender
          ? branding.branding_sms_sender
          : '',
      sendPriceDropNotification:
        notifications &&
        covertBoolStringToBool(notifications.send_price_drop_notification),
      sendDailyDigestNotification:
        notifications &&
        covertBoolStringToBool(notifications.send_daily_digest_notification),
      sendWeeklyDigestNotification:
        notifications &&
        covertBoolStringToBool(notifications.weekly_digest_report),
      rayWhiteConciergeEnabled:
        integrations &&
        covertBoolStringToBool(integrations.ray_white_concierge_enabled),
      enable_assign_property_to_building:
        properties &&
        covertBoolStringToBool(properties.enable_assign_property_to_building),
      viewing_book_nearby_properties_enabled:
        viewings &&
        covertBoolStringToBool(viewings.viewing_book_nearby_properties_enabled),
      enquiry_sms_response:
        notifications &&
        covertBoolStringToBool(notifications.enquiry_sms_response),
      viewingEntryNoticeMessage:
        notifications &&
        covertBoolStringToBool(notifications.viewing_entry_notice_enabled)
          ? notifications.viewing_entry_notice_message
          : '',
      viewingEntryNoticeEnabled:
        notifications &&
        covertBoolStringToBool(notifications.viewing_entry_notice_enabled),
      viewingEntryNoticeRegulationEnabled:
        notifications &&
        covertBoolStringToBool(
          notifications.viewing_entry_notice_regulation_enabled,
        ),
      viewingEntryNoticeRegulation:
        notifications && notifications.viewing_entry_notice_regulation
          ? parseInt(notifications.viewing_entry_notice_regulation)
          : 0,
      standardBondDurationWeeks:
        leaseattachments && leaseattachments.standard_bond_duration_weeks
          ? leaseattachments.standard_bond_duration_weeks
          : leaseOfferHelpers.DEFAULT_STANDARD_BOND_DURATION_WEEKS,
      applicationCustomisation: {
        postClauseEnabled: !!parsedApplication.posting_clause_enabled,
        bondLoanEnabled: !!parsedApplication.display_bond_loan_enabled,
        smokerDeclarationEnabled:
          !!parsedApplication.smoker_declaration_enabled,
        sendingPersonalReferenceRequest:
          !!parsedApplication.sending_personal_reference_request_enabled,
        requestCarSpace: !!parsedApplication.request_car_space,
        disableChildrenOption: !!parsedApplication.disable_children_option,
        filterIncompleteApplicationsFromPartnerAPI:
          !!parsedApplication.filter_incomplete_applications_from_partner_api,
      },
      bgCWorkflowRestrictionEnabled:
        parsedApplication[
          applicationsAttributesIDs.bgCWorkflowRestrictionEnabled
        ],
      paidPlanFeatures: {
        paidPlansEnabled: paidPlansEnabled,
        integrationsEnabled: integrationsEnabled,
      },
      systemEmailRemindersToAdmin: {
        configureInbox: configureInbox,
        connectUtilityProvider: connectUtilityProvider,
        connectPropertyUploader: connectPropertyUploader,
        setupLeaseOffer: setupLeaseOffer,
      },
      consoleEditable:
        integrations && integrations.console_cloud_external_agency_id,
      showRayWhiteSetting: this.props.showRayWhiteSetting,
      accountType,
      allPTreeTokens: [],
      selectedTokenForAgency: '',
      fetchForPropertyTreeTokensDone: false,
      agencyHasActivatedPropertyTree: false,
      pTreeLoadingSpinners: {
        refresh: false,
        activate: false,
        deactivate: false,
      },
      propertyTreeUnsavedChanges: false,
      checkForEasyBondPayLoginIDPassword: false,
      enableTravelTimeOptimisationOption,
      enableViewingExcludeCalendarEventsTime:
        viewings &&
        covertBoolStringToBool(viewings.viewing_exclude_calendar_events_time),
      enableNeighbouringPropertiesOption,
      isPaidPlanContinueModalOpen: false,
      fieldForPaidPlanContinueModal: '',
      acceptedInvites: (this.props.existingAgencyUserInvites || []).filter(
        (invite) => {
          return invite.validityStatus === 3
        },
      ),
      confirmRemovingLastAdminModalDisplay: false,
      rentRoll,
      stripeCustomerID: billing?.team_billing_stripe_customer_id || '',
      stripeSubscriptionID: billing?.team_billing_stripe_subscription_id || '',
      stripeSubscriptionBGCItemID:
        billing?.team_billing_stripe_bgc_subscription_item_id || '',
      stripeCustomerIDModalOpen,
      stripeSubscriptionIDModalOpen,
      initialStripeCustomerID: billing?.team_billing_stripe_customer_id || '',
      initialStripeSubscriptionID:
        billing?.team_billing_stripe_subscription_id || '',
      initialStripeSubscriptionBGCItemID:
        billing?.team_billing_stripe_bgc_subscription_item_id || '',
      smsUsage: {
        used: null,
        monthlyAllocation: null,
      },
      openDomainIDDuplicateModal: false,
      contWithDupDomainActivateFuncClicked: false,
      newUtilityProviderSelected: false,
      userMFAConfigSms: users && users.user_mfa_config_sms === 'true',
      userMFAConfigEmail: users && users.user_mfa_config_email === 'true',
      initialBGCheckEnabled: enableBackgroundCheckBundle,
    }

    this.updateApplicationConfiguration =
      this.updateApplicationConfiguration.bind(this)
    this.updateStandardFormField = this.updateStandardFormField.bind(this)
    this.updateCheckBox = this.updateCheckBox.bind(this)
  }

  UNSAFE_componentWillMount() {
    let { clearError } = this.props

    clearError()
  }

  componentDidMount() {
    const { agencyGUID } = this.props
    if (agencyGUID) {
      this.fetchAgencyPMSIntegrations(agencyGUID)
      this.fetchAgencyActivationLog(agencyGUID)
      this.fetchAgencySmsUsage(agencyGUID)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ clicked: false })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      prevProps.existingAgencyUserInvites !==
        this.props.existingAgencyUserInvites
    ) {
      this.setState({
        invites: [
          {
            firstName: '',
            lastName: '',
            email: '',
            isAdmin: false,
            isFinance: false,
            agentUtilityProviderUserID: '',
          },
        ],
        invitesNumber: 1,
      })
    }

    if (
      prevProps &&
      prevProps.existingAgencyUserInvites !==
        this.props.existingAgencyUserInvites
    ) {
      this.setState({
        acceptedInvites: (this.props.existingAgencyUserInvites || []).filter(
          (invite) => {
            return invite.validityStatus === 3
          },
        ),
      })
    }
  }

  fetchAgencyActivationLog = (agencyGUID) => {
    getActivationLatestLog(agencyGUID).then((auditLog) => {
      if (!auditLog) return
      const { creator, createdAt } = auditLog
      this.setState({
        activationChangedAt: createdAt,
        activationChangedUser: creator,
      })
    })
  }

  fetchAgencyPMSIntegrations = (agencyGUID) => {
    const { fetchAllPropertytreeTokens, fetchPropertytreeTokenForAgency } =
      this.props
    this.setState({
      fetchForPropertyTreeTokensDone: true,
    })
    fetchAllPropertytreeTokens()
      .then(({ tokens }) => this.setState({ allPTreeTokens: tokens }))
      .catch((error) => snugNotifier.error(error))
    fetchPropertytreeTokenForAgency(agencyGUID)
      .then((tokens) => {
        if (tokens) {
          this.setState({
            selectedTokenForAgency: tokens.tokens.id,
            allPTreeTokens: [{ ...tokens.tokens }],
            agencyHasActivatedPropertyTree: true,
          })
        }
      })
      .catch((error) => snugNotifier.error(error))
      .finally(() => {
        this.setState({
          fetchForPropertyTreeTokensDone: true,
        })
      })

    getPUM(agencyGUID)
      .then(({ pms, pum }) => {
        pms && this.setState({ pum: { pms, count: pum } })
      })
      .catch((err) => snugNotifier.error(err))
  }

  fetchAgencySmsUsage = (agencyGUID) => {
    return getSmsUsageStats(agencyGUID)
      .then((stats) => {
        this.setState({ smsUsage: stats })
      })
      .catch((err) => snugNotifier.error(err))
  }

  refreshPTreeTokens = () => {
    const { refreshtreeTokenForAgency } = this.props
    this.modifyPTreeLoadingSpinners('refresh', true)
    refreshtreeTokenForAgency()
      .then(({ tokens }) => {
        this.setState({
          allPTreeTokens: tokens,
        })
        snugNotifier.success('The tokens have been refreshed')
      })
      .catch((error) => snugNotifier.error(error))
      .finally(() => this.modifyPTreeLoadingSpinners('refresh', false))
  }

  updatePropertyTreeSelect = (field, data) => {
    const { value } = data
    this.setState({
      [field]: value,
    })
    this.unsavedChangesInPropertyTree(true)
  }

  activatePTreeForAgency = () => {
    const { activatePMSIntegration, agencyGUID } = this.props
    const { selectedTokenForAgency } = this.state
    if (selectedTokenForAgency === '') {
      snugNotifier.error('Please select a token first')
      return
    }
    this.modifyPTreeLoadingSpinners('activate', true)
    const tokenAndAgency = {
      token_id: selectedTokenForAgency,
    }
    activatePMSIntegration(
      agencyGUID,
      tokenAndAgency,
      PMSHelpers.PMSPropertyTree,
    )
      .then(() => {
        snugNotifier.success('PropertyTree has been activated for agency')
        this.setState({ agencyHasActivatedPropertyTree: true })
      })
      .catch((error) => snugNotifier.error(error))
      .finally(() => {
        this.modifyPTreeLoadingSpinners('activate', false)
        this.unsavedChangesInPropertyTree(false)
      })
  }

  modifyPTreeLoadingSpinners = (field, value) => {
    this.setState({
      pTreeLoadingSpinners: {
        ...this.state.pTreeLoadingSpinners,
        [field]: value,
      },
    })
  }

  deactivatePTreeForAgency = () => {
    const { deactivatePMSIntegration, agencyGUID } = this.props
    this.modifyPTreeLoadingSpinners('deactivate', true)
    deactivatePMSIntegration(agencyGUID, PMSHelpers.PMSPropertyTree)
      .then(() => {
        this.setState({ agencyHasActivatedPropertyTree: false })
        snugNotifier.success('PropertyTree has been deactivated for agency')
      })
      .catch(() =>
        snugNotifier.error('There was an error deactivating PropertyTree'),
      )
      .finally(() => {
        this.modifyPTreeLoadingSpinners('deactivate', false)
        this.unsavedChangesInPropertyTree(false)
      })
  }

  onRemoveAgencyBranding = (logoType, agencyGUID) => {
    const { removeAgencyBranding } = this.props
    const { brandingEnabled, brandingBannerURL, brandingLogoURL } = this.state
    let emptyLogo = brandingLogoURL === ''
    let emptyBanner = brandingBannerURL === ''
    removeAgencyBranding(logoType, agencyGUID)
      .then(() => {
        if (logoType === AgencyBrandingType.Banner) {
          this.setState({
            brandingBannerURL: '',
            bannerUploadError: '',
          })
          emptyBanner = true
        } else if (logoType === AgencyBrandingType.Logo) {
          this.setState({
            brandingLogoURL: '',
            logoUploadError: '',
          })
          emptyLogo = true
        }
      })
      .then(() => {
        this.setBrandingValidationError(brandingEnabled, emptyLogo, emptyBanner)
      })
  }

  onUploadBranding = (logoType, agencyGUID, documentType) => {
    const { addAgencyBranding } = this.props
    const { brandingEnabled, brandingBannerURL, brandingLogoURL } = this.state
    let emptyBanner = brandingBannerURL === ''
    let emptyLogo = brandingLogoURL === ''
    addAgencyBranding(logoType, agencyGUID, documentType)
      .then((brandingURL) => {
        if (logoType === AgencyBrandingType.Banner) {
          this.setState(
            {
              brandingBannerURL: brandingURL,
              bannerUploadError: '',
            },
            () => {
              emptyBanner = false
              this.setBrandingValidationError(
                brandingEnabled,
                emptyLogo,
                emptyBanner,
              )
            },
          )
        } else if (logoType === AgencyBrandingType.Logo) {
          this.setState(
            {
              brandingLogoURL: brandingURL,
              logoUploadError: '',
            },
            () => {
              emptyLogo = false
              this.setBrandingValidationError(
                brandingEnabled,
                emptyLogo,
                emptyBanner,
              )
            },
          )
        }
      })
      .catch((error) => {
        if (logoType === AgencyBrandingType.Banner) {
          this.setState({ bannerUploadError: error })
        } else if (logoType === AgencyBrandingType.Logo) {
          this.setState({ logoUploadError: error })
        }
      })
  }

  getUtilityProvideIndex = () => {
    const { agencyProfile = {} } = this.props
    const { utilityProvider = {} } = agencyProfile || {}
    const {
      platformDefaultProvider: isPlatformDefaultProviderEnabled = false,
    } = utilityProvider || {}
    const { newUtilityProviderSelected } = this.state
    const utilityIndex = utilityConnectionCollection.find(
      (item) => item.name === this.state.utilityProvider.name,
    )
    if (isPlatformDefaultProviderEnabled && !newUtilityProviderSelected) {
      return DIRECT_CONNECT_DEFAULT_PROVIDER_INDEX
    }
    return utilityIndex ? utilityIndex.value : 0
  }

  setBrandingValidationError = (brandingEnabled, emptyLogo, emptyBanner) => {
    if (brandingEnabled) {
      this.setState({
        logoValidationError: emptyLogo ? 'Please upload logo' : '',
        bannerValidationError: emptyBanner ? 'Please upload banner' : '',
      })
    } else if (!brandingEnabled) {
      this.setState({
        logoValidationError: '',
        bannerValidationError: '',
      })
    }
  }

  setInviteValidationErrors(field, error, i) {
    let invitesErrors = this.state.invitesErrors
    if (error.length === 0) {
      // No validation errors
      delete invitesErrors[i][field]
      this.setState({ invitesErrors })
      return true
    } else {
      // Validation errors
      invitesErrors[i][field] = error
      this.setState({ invitesErrors })
      return false
    }
  }

  setValidationErrors = (field, error) => {
    let errors = this.state.errors
    if (error.length === 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  clearValidationError = (field) => {
    let { errors } = this.state
    delete errors[field]
    this.setState({ errors })
  }

  clearValue = (field) => {
    this.setState({ [field]: '' })
  }

  enableAdvancedActivityReport = () => {
    this.setState({
      advancedActivityEnabled: !this.state.advancedActivityEnabled,
    })
  }

  enableBranding = () => {
    const { brandingBannerURL, brandingLogoURL, brandingEnabled } = this.state
    this.setState(
      {
        brandingEnabled: !brandingEnabled,
      },
      () => {
        this.setBrandingValidationError(
          brandingEnabled,
          brandingLogoURL === '',
          brandingBannerURL === '',
        )
      },
    )
  }

  hasPrincipal() {
    const { invites } = this.state
    const { existingAgencyUserInvites = [] } = this.props
    const allInvites = invites.concat(existingAgencyUserInvites)

    return !!(
      allInvites.length > 0 &&
      allInvites.filter((invite) => invite.isAdmin === true).length
    )
  }

  isEmptyObject(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false
      }
    }
    return true
  }

  isFormEmpty() {
    const {
      agency,
      companyName,
      agencySlug,
      ABN,
      ACN,
      realEstateLicenseNumber,
    } = this.state

    return !(
      agency.name ||
      agency.address ||
      agency.agencyId ||
      agency.crmID ||
      companyName ||
      agencySlug ||
      ABN ||
      ACN ||
      realEstateLicenseNumber ||
      !this.isInviteEmpty()
    )
  }

  isPropertyTreeFormChanged = () => {
    const { propertyTreeEnabled, selectedTokenForAgency } = this.state
    if (propertyTreeEnabled && selectedTokenForAgency === '') {
      return true
    }
    return false
  }

  isEasyBondPayChanged = () => {
    const {
      easy_bond_pay_broker_login_password,
      easy_bond_pay_broker_login_id,
      easy_bond_pay_enabled,
    } = this.state
    if (
      easy_bond_pay_enabled &&
      easy_bond_pay_broker_login_password === '' &&
      easy_bond_pay_broker_login_id === ''
    ) {
      return true
    }
    return false
  }

  isDomainIntegrationChanged = () => {
    const {
      initialState: {
        aliases = [],
        attributes: { integrations: { external_agency_id_enabled } = {} },
      } = {},
    } = this.props
    const externalAgencyIdEnabled = covertBoolStringToBool(
      external_agency_id_enabled,
    )
    const initialDomainId = getDomainAgencyId(aliases)?.teamID
    const { enableDomainIntegration, domainExternalId } = this.state
    return (
      enableDomainIntegration !==
        (!!initialDomainId && externalAgencyIdEnabled) ||
      (enableDomainIntegration && initialDomainId !== domainExternalId)
    )
  }

  isInviteEmpty() {
    const { invites } = this.state

    for (const i in invites) {
      if (
        invites[i].firstName ||
        invites[i].lastName ||
        invites[i].email ||
        invites[i].isAdmin
      ) {
        return false
      }
    }

    return true
  }

  isUtilityProviderValid = () => {
    const { utilityProvider } = this.state
    const selectedUtilityProvider =
      utilityConnectionCollection.find(
        (utility) => utility.name === utilityProvider.name,
      ) || utilityConnectionCollection[0]
    if (selectedUtilityProvider.value === 0) {
      this.setState({ utilityProviderError: '' })
      return true
    }
    if (
      selectedUtilityProvider.isProviderAgentIDRequired &&
      !selectedUtilityProvider.isProviderAgentIDOptional &&
      utilityProvider.agentID === ''
    ) {
      this.setState({
        utilityProviderError: 'Utility provider agent ID is required',
      })
      return false
    }
    if (
      selectedUtilityProvider.isRefIDRequired &&
      utilityProvider.agentRefID === ''
    ) {
      this.setState({
        utilityProviderError: 'Utility Provider Agent Ref ID is required',
      })
      return false
    }
    this.setState({ utilityProviderError: '' })
    return true
  }

  isValidBranding = () => {
    const { brandingEnabled, brandingLogoURL, brandingBannerURL } = this.state
    return !(
      brandingEnabled &&
      brandingLogoURL === '' &&
      brandingBannerURL === ''
    )
  }

  isValidExternalID = () => {
    //the only validation now is that it's required if external agency ID option enabled
    const { externalSnugTeamID, external_agency_id_enabled } = this.state
    return externalSnugTeamID || !external_agency_id_enabled
  }

  isValidCRMID = () => {
    const { edit, initialStateAgencyInfo = {} } = this.props
    const { crmID = '' } = initialStateAgencyInfo || {}
    if (edit && !crmID) {
      return false
    }
    return true
  }

  newInviteField = () => {
    const { invites, invitesErrors } = this.state
    invites.push({
      firstName: '',
      lastName: '',
      email: '',
      isAdmin: false,
      isFinance: false,
      isLeasingConsultant: false,
      agentUtilityProviderUserID: '',
    })
    invitesErrors.push({})
    this.setState({ invites }, () => this.setState({ invitesErrors }))
    const { invitesNumber } = this.state
    this.setState({
      invitesNumber: invitesNumber + 1,
    })
  }

  noInvitesErrors() {
    const { invites, invitesErrors } = this.state
    const { edit } = this.props

    for (const i in invitesErrors) {
      if (!this.isEmptyObject(invitesErrors[i])) {
        return false
      }
      if (
        !edit &&
        !(invites[i].firstName && invites[i].lastName && invites[i].email)
      ) {
        return false
      }
    }
    return true
  }

  removeInvite = (i) => {
    const { invitesNumber, invites, invitesErrors } = this.state
    if (invitesNumber > 1) {
      const newInvites = invites.filter((value, index) => index !== i)
      const newInvitesErrors = invitesErrors.filter(
        (value, index) => index !== i,
      )
      this.setState({
        invitesNumber: invitesNumber - 1,
        invites: newInvites,
        invitesErrors: newInvitesErrors,
      })
    }
  }

  submit = () => {
    const { register, agencyGUID = '' } = this.props
    const {
      companyName,
      contactNumber,
      contactEmail,
      agencySlug,
      ABN,
      ACN,
      realEstateLicenseNumber,
      enquiryEmail,
      smsRegistrationConfirmation,
      smsViewingChanges,
      smsViewingCreated,
      smsEnNewCancelledViewing,
      isActive,
      agency,
      invites,
      receiveEnquiry,
      enquiryResponseMode,
      whitelistedProperties,
      suggestedPropertiesEnabled,
      activityTabEnabled,
      defaultListingProfile,
      defaultListingProfileFirstName,
      defaultListingProfileLastName,
      defaultListingProfileEmail,
      defaultListingProfileMobile,
      forceDefaultListingProfile,
      brandingEnabled,
      viewingRunEnabled,
      advancedActivityEnabled,
      EFTPaymentEnabled,
      EFTPaymentBSB,
      EFTPaymentAccountNum,
      EFTPaymentAccountName,
      BPAYPaymentEnabled,
      BPAYPaymentBillerCode,
      snugCCPaymentEnabled,
      DEFTPaymentEnabled,
      offerEmailMessage,
      congratulationsMessage,
      initialRentPaymentInstructions,
      bondPaymentInstructions,
      remainingRentPaymentInstructions,
      leaseSigningInstructions,
      importantInformationMessage,
      brandingSMSSender,
      sendPriceDropNotification,
      sendDailyDigestNotification,
      sendWeeklyDigestNotification,
      rayWhiteConciergeEnabled,
      viewingEntryNoticeMessage,
      viewingEntryNoticeEnabled = true,
      viewingEntryNoticeRegulationEnabled = false,
      viewingEntryNoticeRegulation,
      standardBondDurationWeeks,
      applicationCustomisation: {
        postClauseEnabled: posting_clause_enabled,
        bondLoanEnabled: display_bond_loan_enabled,
        smokerDeclarationEnabled: smoker_declaration_enabled,
        sendingPersonalReferenceRequest:
          sending_personal_reference_request_enabled,
        requestCarSpace: request_car_space,
        disableChildrenOption: disable_children_option,
        filterIncompleteApplicationsFromPartnerAPI:
          filter_incomplete_applications_from_partner_api,
      },
      external_agency_id_enabled,
      externalSnugTeamID,
      console_cloud_enabled,
      console_cloud_external_agency_id,
      easy_bond_pay_enabled,
      easy_bond_pay_broker_login_id,
      easy_bond_pay_broker_login_password,
      displayExcludedMarketFeedback = true,
      smsViewingAttendeeDayBeforeEnabled = false,
      smsLeaseOffer = false,
      smsRequestInformation = false,
      enableBackgroundCheckBundle = false,
      bgCWorkflowRestrictionEnabled = false,
      backgroundChecksReportingEnabled = false,
      enableKeyManagement = false,
      viewing_run_enabled,
      viewing_run_mode,
      accountType,
      propertyTreeEnabled,
      propertyMeEnabled,
      vaultREEnabled,
      smsBundleEnabled,
      bgcAllInclusiveBundleEnabled,
      integrationsBundleEnabled,
      productivityBundleEnabled,
      enable_assign_property_to_building,
      enableTravelTimeOptimisationOption,
      enableViewingExcludeCalendarEventsTime,
      enableNeighbouringPropertiesOption,
      userMFAConfigSms,
      userMFAConfigEmail,
      viewing_book_nearby_properties_enabled,
      domainIntegrationUnsavedChanges,
      enableDomainIntegration,
      enquiry_sms_response,
      rentRoll,
      otherTeamsWithSameDomainIDInfo,
      contWithDupDomainActivateFuncClicked,
      utilityProvider,
      newUtilityProviderSelected,
      stripeCustomerID,
      stripeSubscriptionID,
      stripeSubscriptionBGCItemID,
      initialBGCheckEnabled,
      EFTPaymentsV2,
    } = this.state

    for (const i in invites) {
      invites[i].firstName = invites[i].firstName.trim()
      invites[i].lastName = invites[i].lastName.trim()
      invites[i].email = invites[i].email.trim().toLowerCase()
      invites[i].agentUtilityProviderUserID =
        invites[i].agentUtilityProviderUserID.trim()
    }
    if (!this.isUtilityProviderValid()) {
      return
    }
    const userInvites = invites.filter(
      (inv) =>
        inv.email.length > 0 &&
        inv.lastName.length > 0 &&
        inv.firstName.length > 0,
    )

    const validDomainIDChangesMade =
      domainIntegrationUnsavedChanges &&
      enableDomainIntegration &&
      external_agency_id_enabled &&
      !this.validateDomainAgencyId()

    let bgCheckDeactivated = false

    if (initialBGCheckEnabled && !enableBackgroundCheckBundle) {
      bgCheckDeactivated = true
    }

    const teamsWithSameDomainIDAndNoActivation =
      otherTeamsWithSameDomainIDInfo?.filter(
        (agency) => agency.agencyGUID !== agencyGUID,
      )?.length > 0 && !contWithDupDomainActivateFuncClicked

    if (validDomainIDChangesMade && teamsWithSameDomainIDAndNoActivation) {
      return
    }

    if (!this.isValidCRMID()) {
      snugNotifier.error('Please add a valid CRM ID')
      return
    }

    let selectedUtilityProvider = isUtilityProviderSelected(utilityProvider)
      ? utilityProvider
      : undefined
    if (
      this.getUtilityProvideIndex() === DIRECT_CONNECT_DEFAULT_PROVIDER_INDEX &&
      !newUtilityProviderSelected
    ) {
      selectedUtilityProvider = undefined
    }

    register({
      companyName: companyName.trim(),
      slug: agencySlug.trim(),
      ABN: ABN.replace(/\s+/g, ''),
      ACN: ACN.replace(/\s+/g, ''),
      contactNumber,
      contactEmail,
      realEstateLicenseNumber: realEstateLicenseNumber.replace(/\s+/g, ''),
      enquiryEmail: enquiryEmail.trim().toLowerCase(),
      smsRegistrationConfirmation,
      smsViewingCreated,
      smsViewingChanges,
      smsViewingAttendeeDayBeforeEnabled,
      smsLeaseOffer,
      smsRequestInformation,
      enableBackgroundCheckBundle,
      backgroundChecksReportingEnabled,
      enableKeyManagement,
      isActive: isActive,
      agencyId: agency.agencyId || agencyGUID,
      agencyUserInvites: userInvites,
      utilityProvider: selectedUtilityProvider,
      receiveEnquiry,
      enquiryResponseMode,
      defaultListingProfile,
      defaultListingProfileFirstName,
      defaultListingProfileLastName,
      defaultListingProfileEmail,
      defaultListingProfileMobile,
      forceDefaultListingProfile,
      whitelistedProperties,
      suggestedPropertiesEnabled,
      activityTabEnabled,
      brandingEnabled,
      advancedActivityEnabled,
      ...(external_agency_id_enabled && { externalSnugTeamID }),
      attributes: {
        leaseattachments: {
          EFT: covertBoolToString(EFTPaymentEnabled),

          ...utils.formatBeforeSubmitEFTValues(EFTPaymentsV2),

          BPAY: covertBoolToString(BPAYPaymentEnabled),
          rent_biller: BPAYPaymentBillerCode,
          snug_pay: covertBoolToString(snugCCPaymentEnabled),
          DEFT: covertBoolToString(DEFTPaymentEnabled),
          email_message: offerEmailMessage,
          message: congratulationsMessage,
          deposit_message: initialRentPaymentInstructions,
          bond_message: bondPaymentInstructions,
          rent_message: remainingRentPaymentInstructions,
          lease_signing_message: leaseSigningInstructions,
          important_message: importantInformationMessage,
          standard_bond_duration_weeks: standardBondDurationWeeks,
        },
        branding: {
          branding_sms_sender: brandingSMSSender,
        },
        notifications: {
          send_price_drop_notification: sendPriceDropNotification.toString(),
          send_daily_digest_notification:
            sendDailyDigestNotification.toString(),
          weekly_digest_report: sendWeeklyDigestNotification.toString(),
          viewing_entry_notice_enabled: covertBoolToString(
            viewingEntryNoticeEnabled,
          ),
          viewing_entry_notice_message: viewingEntryNoticeEnabled
            ? viewingEntryNoticeMessage
            : '',
          viewing_entry_notice_regulation_enabled: covertBoolToString(
            viewingEntryNoticeRegulationEnabled,
          ),
          viewing_entry_notice_regulation: viewingEntryNoticeRegulation
            ? viewingEntryNoticeRegulation.toString()
            : '',
          enquiry_sms_response: covertBoolToString(enquiry_sms_response),
        },
        application: {
          posting_clause_enabled: posting_clause_enabled.toString(),
          display_bond_loan_enabled: display_bond_loan_enabled.toString(),
          smoker_declaration_enabled: smoker_declaration_enabled.toString(),
          sending_personal_reference_request_enabled:
            sending_personal_reference_request_enabled.toString(),
          request_car_space: request_car_space.toString(),
          disable_children_option: disable_children_option.toString(),
          filter_incomplete_applications_from_partner_api:
            filter_incomplete_applications_from_partner_api.toString(),
          [applicationsAttributesIDs.bgCWorkflowRestrictionEnabled]:
            covertBoolToString(bgCWorkflowRestrictionEnabled),
        },
        integrations: {
          external_agency_id_enabled: covertBoolToString(
            external_agency_id_enabled,
          ),
          console_cloud_enabled: covertBoolToString(console_cloud_enabled),
          console_cloud_external_agency_id: console_cloud_external_agency_id,
          easy_bond_pay_enabled: covertBoolToString(easy_bond_pay_enabled),
          easy_bond_pay_broker_login_id: easy_bond_pay_broker_login_id,
          easy_bond_pay_broker_login_password:
            easy_bond_pay_broker_login_password,
          property_tree_enabled: covertBoolToString(propertyTreeEnabled),
          property_me_enabled: covertBoolToString(propertyMeEnabled),
          vault_re_enabled: covertBoolToString(vaultREEnabled),
          ray_white_concierge_enabled: covertBoolToString(
            rayWhiteConciergeEnabled,
          ),
        },
        viewings: {
          display_excluded_market_feedback: covertBoolToString(
            displayExcludedMarketFeedback,
          ),
          viewing_run_enabled: covertBoolToString(viewing_run_enabled),
          viewing_run_mode: viewing_run_enabled
            ? viewing_run_mode === 'multi'
              ? 'multi'
              : 'single'
            : '',
          viewing_exclude_calendar_events_time: covertBoolToString(
            enableViewingExcludeCalendarEventsTime,
          ),
          viewing_book_nearby_properties_enabled: covertBoolToString(
            viewing_book_nearby_properties_enabled,
          ),
        },
        properties: {
          enable_assign_property_to_building: covertBoolToString(
            enable_assign_property_to_building,
          ),
        },
        sms: {
          sms_en_new_cancelled_viewing: smsEnNewCancelledViewing
            ? 'true'
            : 'false',
        },
        bundle: {
          integrations_bundle: integrationsBundleEnabled.toString(),
          productivity_bundle: productivityBundleEnabled.toString(),
          sms_bundle: smsBundleEnabled.toString(),
          bgc_all_inclusive_bundle: bgcAllInclusiveBundleEnabled.toString(),
        },
        users: {
          user_mfa_config_sms: userMFAConfigSms ? 'true' : 'false',
          user_mfa_config_email: userMFAConfigEmail ? 'true' : 'false',
        },
        billing: {
          team_billing_stripe_customer_id: stripeCustomerID,
          team_billing_stripe_subscription_id: stripeSubscriptionID,
          team_billing_stripe_bgc_subscription_item_id:
            stripeSubscriptionBGCItemID,
        },
      },
      attributes_removed: {
        leaseattachments: utils.getAttrRemoved(
          this.props?.initialState?.attributes?.leaseattachments,
          utils.formatBeforeSubmitEFTValues(EFTPaymentsV2),
        ),
      },
      viewingRunEnabled,
      bgCheckDeactivated,
      accountType: parseInt(accountType),
      enableTravelTimeOptimisationOption,
      enableNeighbouringPropertiesOption,
      ...(rentRoll && { rentRoll: parseInt(rentRoll) }),
    })
      .then(() => {
        if (
          domainIntegrationUnsavedChanges &&
          external_agency_id_enabled &&
          this.isDomainIntegrationChanged()
        ) {
          return this.saveDomainIntegration()
        }
      })
      .catch((err) => snugNotifier.error(err))
      .finally(() => {
        this.setState({
          unsavedChanges: false,
          domainIntegrationUnsavedChanges: false,
        })
        this.unsavedChangesInPropertyTree(false)
      })
    this.setState({ clicked: true })
  }

  saveDomainIntegration = () => {
    const { agencyGUID = '', initialState: { aliases = [] } = {} } = this.props
    const { domainExternalId, enableDomainIntegration } = this.state
    const initialDomainId = getDomainAgencyId(aliases)?.teamID
    if (enableDomainIntegration) {
      return addAgencyAliasId(
        agencyGUID,
        domainExternalId,
        domainIntegrationLabel,
      ).catch((err) => snugNotifier.error(err?.message))
    } else {
      return deleteAgencyAliasId(agencyGUID, initialDomainId).catch((err) =>
        snugNotifier.error(err?.message),
      )
    }
  }

  unsavedChangesInPropertyTree = (value) => {
    this.setState({
      propertyTreeUnsavedChanges: value,
    })
  }

  reflectBackgroundCheckReportingEnabled() {
    const { enableBackgroundCheckBundle } = this.state
    if (enableBackgroundCheckBundle) {
      this.setState({
        backgroundChecksReportingEnabled: true,
      })
    }
  }

  update = (field) => {
    return (event) => {
      const { value } = event.target
      this.setState({
        [field]: value,
        unsavedChanges: true,
      })
      if (field === 'stripeCustomerID' && value !== '') {
        this.setState({
          stripeCustomerIDChanged: true,
        })
      }
      if (field === 'stripeSubscriptionID' && value !== '') {
        this.setState({
          stripeSubscriptionIDChanged: true,
        })
      }
      if (field === 'stripeSubscriptionBGCItemID' && value !== '') {
        this.setState({
          stripeSubscriptionItemIDBGCChanged: true,
        })
      }
    }
  }

  toggleStripeCustomerIDModal = () => {
    const { stripeCustomerIDModalOpen } = this.state
    this.setState({
      stripeCustomerIDModalOpen: !stripeCustomerIDModalOpen,
    })
  }
  toggleStripeSubscriptionTeamIDModal = () => {
    const { stripeSubscriptionIDModalOpen } = this.state
    this.setState({
      stripeSubscriptionIDModalOpen: !stripeSubscriptionIDModalOpen,
    })
  }

  toggleStripeSubscriptionItemTeamIDModal = () => {
    const { stripeSubscriptionItemIDBGCModalOpen } = this.state
    this.setState({
      stripeSubscriptionItemIDBGCModalOpen:
        !stripeSubscriptionItemIDBGCModalOpen,
    })
  }

  resetStripeCustomerID = () => {
    const { initialStripeCustomerID } = this.state
    this.setState({
      stripeCustomerID: initialStripeCustomerID,
    })
  }

  resetStripeTeamSubscriptionID = () => {
    const { initialStripeSubscriptionID } = this.state
    this.setState({
      stripeSubscriptionID: initialStripeSubscriptionID,
    })
  }

  resetStripeItemSubscriptionID = () => {
    const { initialStripeSubscriptionBGCItemID } = this.state
    this.setState({
      stripeSubscriptionBGCItemID: initialStripeSubscriptionBGCItemID,
    })
  }

  updateActivationStatus() {
    const { isActive } = this.state
    this.setState({
      isActive: !isActive,
      unsavedChanges: true,
      activationChangedUser: {},
      activationChangedAt: null,
    })
  }

  updateAgency = (agency, callback) => {
    this.setState(
      {
        agency: {
          name: agency.value,
          address: agency.address,
          agencyId: agency.guidID,
          crmdID: agency.crmID,
        },
        unsavedChanges: true,
      },
      callback,
    )
  }

  updateApplicationConfiguration = (field) => {
    return () => {
      if (field === 'bondLoanEnabled' && this.state.easy_bond_pay_enabled) {
        this.setState({
          easy_bond_pay_enabled: false,
        })
      }
      this.setState({
        applicationCustomisation: {
          ...this.state.applicationCustomisation,
          [field]: !this.state.applicationCustomisation[field],
        },
        paidPlanFeatures: {
          ...this.state.paidPlanFeatures,
          [field]: !this.state.paidPlanFeatures[field],
        },
        systemEmailRemindersToAdmin: {
          ...this.state.systemEmailRemindersToAdmin,
          [field]: !this.state.systemEmailRemindersToAdmin[field],
        },
      })
    }
  }

  updateBondPaymentInstructions = (data) => {
    const { value } = data
    this.setState({
      bondPaymentInstructions: value,
      unsavedChanges: true,
    })
  }

  continueWithSelectingCheckbox = (field) => {
    this.setState({
      [field]: !this.state[field],
    })
    this.togglePaidPlanContinueModal()
  }

  togglePaidPlanContinueModal = () => {
    const { isPaidPlanContinueModalOpen } = this.state
    this.setState({
      isPaidPlanContinueModalOpen: !isPaidPlanContinueModalOpen,
      fieldForPaidPlanContinueModal: '',
    })
  }

  updateCheckBox = (field) => {
    if (!this.state[field] && constants.paidPlans.includes(field)) {
      this.setState({
        isPaidPlanContinueModalOpen: true,
        fieldForPaidPlanContinueModal: field,
      })
      return
    }

    this.setState(
      {
        [field]: !this.state[field],
      },
      () => {
        if (field === 'easy_bond_pay_enabled' && this.state[field]) {
          const { applicationCustomisation } = this.state
          let tempData = applicationCustomisation
          tempData.bondLoanEnabled = false
          this.setState({
            applicationCustomisation: tempData,
            isEasyBondpayOrBondLoanEnabled:
              !this.state.isEasyBondpayOrBondLoanEnabled,
            checkForEasyBondPayLoginIDPassword: true,
          })
        }
        if (field === 'property_tree_enabled' && this.state[field]) {
          this.unsavedChangesInPropertyTree(true)
        }
        if (field === 'enableBackgroundCheckBundle') {
          this.reflectBackgroundCheckReportingEnabled()
        }
        if (field === 'external_agency_id_enabled' && this.state[field]) {
          this.setState({
            enableDomainIntegration: !!this.state.domainExternalId,
          })
        }
      },
    )
  }

  updateMFACheckbox = (event, key) => {
    const { value = false } = event || {}

    this.setState({
      userMFAConfigEmail: key === 'user_mfa_config_email' && value,
      userMFAConfigSms: key === 'user_mfa_config_sms' && value,
    })
  }

  updateIntegrateDomain = () => {
    const { enableDomainIntegration } = this.state
    this.setState({
      enableDomainIntegration: !enableDomainIntegration,
      domainIntegrationUnsavedChanges: true,
    })
  }

  updateDomainAgencyId = (event) => {
    const { value } = event
    this.setState({
      domainExternalId: value,
      domainIntegrationUnsavedChanges: true,
    })
  }

  validateDomainAgencyId = () => {
    const { domainExternalId } = this.state

    let err,
      valid = true
    if (!domainExternalId || domainExternalId?.length === 0) {
      err = 'Domain Agency ID required'
      valid = false
    } else if (domainExternalId?.length < 3) {
      err = 'More than 3 characters required'
      valid = false
    }
    this.setState({ domainExternalIdErr: err })

    return valid
  }

  toggleActivationConfirmationModal = () => {
    const { openDomainIDDuplicateModal } = this.state
    this.setState({
      openDomainIDDuplicateModal: !openDomainIDDuplicateModal,
    })
  }

  clearDomainIdValue = () => {
    this.setState({
      domainExternalId: '',
    })
  }

  continueWithActivationFn = () => {
    this.setState({
      contWithDupDomainActivateFuncClicked: true,
    })
    this.toggleActivationConfirmationModal()
  }

  updateCheckBoxMultiRun = (field) => {
    this.setState({
      [field]: !this.state[field],
    })
    if (field === 'viewing_run_enabled') {
      this.setState({
        viewing_run_mode: 'single',
      })
    }
  }

  updateCongratulationsMessage = (data) => {
    const { value } = data
    this.setState({
      congratulationsMessage: value,
      unsavedChanges: true,
    })
  }

  updateEmail(field) {
    return (event) => {
      let { value } = event.target
      value = value.toLowerCase()
      this.setState({
        [field]: value.trim(),
        unsavedChanges: true,
      })
    }
  }

  updateEnableActivityReport = () => {
    this.setState({ activityTabEnabled: !this.state.activityTabEnabled })
  }

  updateEnableBPAYPayment = () => {
    const { BPAYPaymentEnabled } = this.state
    const newBPAYPaymentEnabled = !BPAYPaymentEnabled
    this.setState({
      BPAYPaymentEnabled: newBPAYPaymentEnabled,
    })
    if (!newBPAYPaymentEnabled) {
      this.clearValue('BPAYPaymentBillerCode')
      this.clearValidationError('BPAYPaymentBillerCode')
    }
  }

  updateEnableDEFTPayment = () => {
    const { DEFTPaymentEnabled } = this.state
    this.setState({
      DEFTPaymentEnabled: !DEFTPaymentEnabled,
    })
  }

  updateEnableEFTPayment = () => {
    const { EFTPaymentEnabled } = this.state
    const newEFTPaymentEnabled = !EFTPaymentEnabled
    this.setState({
      EFTPaymentEnabled: newEFTPaymentEnabled,
    })
    if (!newEFTPaymentEnabled) {
      this.clearValue('EFTPaymentBSB')
      this.clearValidationError('EFTPaymentBSB')
      this.clearValue('EFTPaymentAccountNum')
      this.clearValidationError('EFTPaymentAccountNum')
      this.clearValue('EFTPaymentAccountName')
      this.clearValidationError('EFTPaymentAccountName')
    }
  }

  updateEnableSnugCCPayment = () => {
    const { snugCCPaymentEnabled } = this.state
    this.setState({
      snugCCPaymentEnabled: !snugCCPaymentEnabled,
    })
  }

  updateAssigningPropertiesToBuildings = () => {
    const { enable_assign_property_to_building = false } = this.state
    if (!enable_assign_property_to_building) {
      this.setState({
        isPaidPlanContinueModalOpen: true,
        fieldForPaidPlanContinueModal:
          constants.enable_assign_property_to_building,
      })
      return
    }
    this.setState({
      enable_assign_property_to_building: !enable_assign_property_to_building,
    })
  }

  updateBookNearbyProperties = () => {
    const { viewing_book_nearby_properties_enabled = false } = this.state
    if (!viewing_book_nearby_properties_enabled) {
      this.setState({
        isPaidPlanContinueModalOpen: true,
        fieldForPaidPlanContinueModal:
          constants.viewing_book_nearby_properties_enabled,
      })
      return
    }
    this.setState({
      viewing_book_nearby_properties_enabled:
        !viewing_book_nearby_properties_enabled,
    })
  }

  updateEnableSuggestedProperties = () => {
    this.setState({
      suggestedPropertiesEnabled: !this.state.suggestedPropertiesEnabled,
    })
  }

  updateEnableViewingRun = () => {
    this.setState({ viewingRunEnabled: !this.state.viewingRunEnabled })
  }

  updateEnquiryResponseMode = (event) => {
    const { target } = event
    const { id } = target
    this.setState({ enquiryResponseMode: enquiryResponseModeString2Number(id) })
  }

  updateForceListingProfile = () => {
    this.setState({
      forceDefaultListingProfile: !this.state.forceDefaultListingProfile,
    })
  }

  updateImportantInformationMessage = (data) => {
    const { value } = data
    this.setState({
      importantInformationMessage: value,
      unsavedChanges: true,
    })
  }

  updateInitialRentPaymentInstructions = (data) => {
    const { value } = data
    this.setState({
      initialRentPaymentInstructions: value,
      unsavedChanges: true,
    })
  }

  updateInvite = (field, value, i) => {
    const { invites } = this.state
    invites[i][field] = value
    const roleArray = ['isAdmin', 'isFinance', 'isLeasingConsultant']
    roleArray
      .filter((role) => role !== field)
      .forEach((leftRole) => {
        invites[i][leftRole] = false
      })

    this.setState({
      invites,
      unsavedChanges: true,
    })
  }

  updateLeaseSigningInstructions = (data) => {
    const { value } = data
    this.setState({
      leaseSigningInstructions: value,
      unsavedChanges: true,
    })
  }

  updateMultiRunCheckboxSingleOrMulti = (field) => {
    if (field === 'viewing_run_enabled_single') {
      this.setState({
        viewing_run_mode: 'single',
        viewing_run_enabled_single: !this.state.viewing_run_enabled_single,
      })
    }
    if (field === 'viewing_run_enabled_multi') {
      this.setState({
        viewing_run_mode: 'multi',
        viewing_run_enabled_multi: !this.state.viewing_run_enabled_multi,
      })
    }
  }

  updateName(field) {
    return (event) => {
      const { value } = event.target
      this.setState({
        [field]: value,
        unsavedChanges: true,
      })
    }
  }

  updateOfferEmailMessage = (data) => {
    const { value } = data
    this.setState({
      offerEmailMessage: value,
      unsavedChanges: true,
    })
  }

  updateRayWhiteConciergeEnabled = () => {
    const { rayWhiteConciergeEnabled } = this.state
    this.setState({
      rayWhiteConciergeEnabled: !rayWhiteConciergeEnabled,
    })
  }

  updateReceiveEnquiry = () => {
    this.setState({ receiveEnquiry: !this.state.receiveEnquiry })
  }

  updateRemainingRentPaymentInstructions = (data) => {
    const { value } = data
    this.setState({
      remainingRentPaymentInstructions: value,
      unsavedChanges: true,
    })
  }

  updateSendDailyDigestNotification = () => {
    const { sendDailyDigestNotification } = this.state
    this.setState({
      sendDailyDigestNotification: !sendDailyDigestNotification,
    })
  }

  updateSendWeeklyDigestNotification = () => {
    const { sendWeeklyDigestNotification } = this.state
    this.setState({
      sendWeeklyDigestNotification: !sendWeeklyDigestNotification,
    })
  }

  updateSendPriceDropNotification = () => {
    const { sendPriceDropNotification } = this.state
    this.setState({
      sendPriceDropNotification: !sendPriceDropNotification,
    })
  }

  updateStandardBondDurationWeeks = (data) => {
    const { value } = data
    this.setState({
      standardBondDurationWeeks: value,
      unsavedChanges: true,
    })
  }

  updateStandardFormField = (field) => {
    return (data) => {
      const { value } = data
      this.setState({
        [field]: value,
        unsavedChanges: true,
      })
    }
  }

  updateUtilityProvider = (field) => {
    // FIXME: This doesn't clear old values in state. E.g. when changing
    // to the provider with a field that's not required, empty the old field
    // in the database by sending an empty value
    return (data) => {
      let { value } = data
      if (field === 'name') {
        value = utilityConnectionCollection[value].name
      }
      const newState = {
        utilityProvider: {
          ...this.state.utilityProvider,
          [field]: value,
        },
        newUtilityProviderSelected: true,
      }
      this.setState(newState)
      this.isUtilityProviderValid()
    }
  }

  updateWhitelistedProperties = (data) => {
    const { value } = data
    this.setState({ whitelistedProperties: value })
  }

  validate = (field) => {
    return () => {
      let value = this.state[field]
      if (value) {
        switch (field) {
          case 'companyName':
            return this.setValidationErrors(
              field,
              validations.validateCompanyName(
                value.trim(),
                'Invalid Company Name',
              ),
            )
          case 'ABN':
            return this.setValidationErrors(
              field,
              validations.validateABN(value.replace(/\s+/g, ''), 'Invalid ABN'),
            )
          case 'ACN':
            return this.setValidationErrors(
              field,
              validations.validateACNOptional(
                value.replace(/\s+/g, ''),
                'Invalid ACN',
              ),
            )
          case 'realEstateLicenseNumber':
            return this.setValidationErrors(
              field,
              validations.validateREALicense(
                value.replace(/\s+/g, ''),
                'Invalid Real Estate License Number',
              ),
            )
          case 'agency':
            return this.setValidationErrors(
              field,
              value.agencyId
                ? ''
                : 'Please select an agency from the dropdown list',
            )
          case 'agencySlug':
            return this.setValidationErrors(
              field,
              validations.validateSlug(value.trim()),
            )
          case 'enquiryEmail':
            return this.setValidationErrors(
              field,
              validations.validateEmailOptional(value.trim(), 'Invalid Email'),
            )
          case 'contactEmail':
            return this.setValidationErrors(
              field,
              validations.validateEmailOptional(value.trim(), 'Invalid Email'),
            )
          case 'BPAYPaymentBillerCode':
            return this.setValidationErrors(
              field,
              validations.validateBillerCode(
                value.replace(/\D/g, ''),
                'Invalid BPAY Payment Biller Code',
              ),
            )
          case 'brandingSMSSender':
            if (value && value.length > 11) {
              return this.setValidationErrors(
                field,
                'Branding SMS Sender cannot be longer than 11 characters',
              )
            }
            return this.setValidationErrors(
              field,
              validations.validateSMSSenderName(
                value.trim(),
                'Branding SMS Sender',
              ),
              'Invalid Branding SMS Sender',
            )
          default:
            return false
        }
      }
    }
  }

  validateInternationalNumber = (status, value, countryData, number, id) => {
    this.setState({
      contactNumber: number.trim(),
    })
    if (value) {
      this.setValidationErrors(
        'contactNumber',
        status || validations.validateMobile(number).length === 0
          ? []
          : [
              ErrorMessages[
                Errors.ErrorWhenConvertingMobileToInternationalFormat
              ],
            ],
      )
    } else {
      this.setValidationErrors('contactNumber', [])
    }
  }

  validateInvite = (field, value, i) => {
    const { invites } = this.state
    const { edit } = this.props
    const invite = invites[i]
    if (
      edit &&
      invite.firstName === '' &&
      invite.lastName === '' &&
      invite.email === ''
    )
      return
    switch (field) {
      case 'firstName':
        return this.setInviteValidationErrors(
          field,
          validations.validateFirstName(value.trim(), 'Invalid First Name'),
          i,
        )
      case 'lastName':
        return this.setInviteValidationErrors(
          field,
          validations.validateLastName(value.trim(), 'Invalid First Name'),
          i,
        )
      case 'email':
        return this.setInviteValidationErrors(
          field,
          validations.validateEmail(value.trim(), 'Invalid Email'),
          i,
        )
      default:
        return false
    }
  }

  checkForPropertyTreeUnsavedMessages = () => {
    const { propertyTreeEnabled } = this.state
    const { selectedTokenForAgency } = this.state
    if (propertyTreeEnabled && selectedTokenForAgency === '') {
      return true
    }
  }

  updateAcceptedMemberRole = (role, value, index) => {
    const { acceptedInvites = [] } = this.state

    this.setState({
      acceptedInvites: acceptedInvites.map((invite, inviteIndex) => {
        const roleArray = ['isAdmin', 'isFinance', 'isLeasingConsultant']

        if (inviteIndex === index) {
          roleArray.forEach((leftRole) => {
            if (leftRole !== role) {
              invite[leftRole] = false
            } else {
              invite[leftRole] = value
            }
          })
        }

        return invite
      }),
    })
  }

  deleteAcceptedMemberRole = (userId, agencyUser) => {
    const { acceptedInvites } = this.state
    const isLastAdminAccepted =
      (acceptedInvites || []).filter((invite) => invite.isAdmin).length <= 1

    const lastAdminGuidID = (acceptedInvites || []).find(
      (invite) => invite.isAdmin,
    ).guidID
    const { guidID = '' } = agencyUser

    if (isLastAdminAccepted && guidID === lastAdminGuidID) {
      this.toggleConfirmRemovingLastAdminModal()
    } else {
      this.props.deleteAgencyUser(userId, agencyUser)
    }
  }

  toggleConfirmRemovingLastAdminModal = () => {
    this.setState({
      confirmRemovingLastAdminModalDisplay:
        !this.state.confirmRemovingLastAdminModalDisplay,
    })
  }

  checkIfDomainIdExists = () => {
    const {
      enableDomainIntegration,
      external_agency_id_enabled,
      domainExternalId,
    } = this.state
    const { agencyGUID } = this.props
    const domainIDValid = this.validateDomainAgencyId()
    if (
      enableDomainIntegration &&
      external_agency_id_enabled &&
      !domainIDValid
    ) {
      return
    }

    if (agencyGUID) {
      checkIfPortalIDExistsForOtherAgencies(
        portalBrands.domain.id,
        agencyGUID,
        {
          external_ids: [domainExternalId],
        },
      ).then((data) => {
        const { otherTeams = [] } = data || {}
        if (
          otherTeams?.filter((agency) => agency.agencyGUID !== agencyGUID)
            ?.length > 0
        ) {
          this.setState({
            openDomainIDDuplicateModal: true,
            otherTeamsWithSameDomainIDInfo: otherTeams,
          })
        }
      })
    }
  }

  handleStripeCustomerIDBlur = () => {
    const { stripeCustomerID } = this.state
    if (stripeCustomerID) {
      if (!stripeCustomerID.startsWith('cus_')) {
        snugNotifier.error(
          'Invalid Stripe customer ID, should start with cus_...',
        )
        return
      }

      if (stripeCustomerID) {
        this.setState({
          stripeCustomerIDModalOpen: true,
        })
      }
    }
  }

  handleStripeSubscriptionID = () => {
    const { stripeSubscriptionID } = this.state
    if (stripeSubscriptionID) {
      if (!stripeSubscriptionID.startsWith('sub_')) {
        snugNotifier.error(
          'Invalid Stripe subscription ID, should start with sub_...',
        )
        return
      }

      if (stripeSubscriptionID) {
        this.setState({
          stripeSubscriptionIDModalOpen: true,
        })
      }
    }
  }

  handleStripeBGCItemIDBlur = () => {
    const { stripeSubscriptionBGCItemID } = this.state

    if (stripeSubscriptionBGCItemID) {
      if (!stripeSubscriptionBGCItemID.startsWith('si_')) {
        snugNotifier.error('Please check if the subscription item ID is valid')
        return
      }

      if (stripeSubscriptionBGCItemID) {
        this.setState({
          stripeSubscriptionItemIDBGCModalOpen: true,
        })
      }
    }
  }

  render() {
    let {
      clicked,
      invitesErrors,
      errors,
      companyName,
      agencySlug,
      ABN,
      ACN,
      isActive,
      activationChangedUser,
      activationChangedAt,
      smsRegistrationConfirmation,
      smsViewingChanges,
      smsEnNewCancelledViewing,
      smsViewingCreated,
      realEstateLicenseNumber,
      invites,
      invitesNumber,
      agencyExist,
      agency,
      defaultListingProfile,
      defaultListingProfileFirstName,
      defaultListingProfileLastName,
      defaultListingProfileEmail,
      defaultListingProfileMobile,
      contactNumber,
      contactEmail,
      forceDefaultListingProfile,
      brandingEnabled,
      brandingBannerURL,
      brandingLogoURL,
      bannerUploadError,
      logoUploadError,
      logoValidationError,
      bannerValidationError,
      EFTPaymentEnabled,
      DEFTPaymentEnabled,
      BPAYPaymentEnabled,
      snugCCPaymentEnabled,
      EFTPaymentBSB,
      EFTPaymentAccountNum,
      EFTPaymentAccountName,
      BPAYPaymentBillerCode,
      initialRentPaymentInstructions,
      bondPaymentInstructions,
      remainingRentPaymentInstructions,
      offerEmailMessage,
      importantInformationMessage,
      leaseSigningInstructions,
      congratulationsMessage,
      brandingSMSSender,
      sendPriceDropNotification,
      sendDailyDigestNotification,
      sendWeeklyDigestNotification,
      rayWhiteConciergeEnabled,
      utilityProviderError,
      applicationCustomisation,
      viewingEntryNoticeMessage,
      viewingEntryNoticeEnabled,
      viewingEntryNoticeRegulationEnabled,
      viewingEntryNoticeRegulation,
      standardBondDurationWeeks,
      external_agency_id_enabled,
      displayExcludedMarketFeedback,
      smsViewingAttendeeDayBeforeEnabled,
      smsLeaseOffer,
      smsRequestInformation,
      console_cloud_enabled,
      console_cloud_external_agency_id,
      consoleEditable,
      easy_bond_pay_enabled,
      easy_bond_pay_broker_login_id,
      easy_bond_pay_broker_login_password,
      viewing_run_enabled,
      viewing_run_mode,
      advancedActivityEnabled,
      enableBackgroundCheckBundle,
      bgCWorkflowRestrictionEnabled,
      backgroundChecksReportingEnabled,
      enableKeyManagement,
      accountType,
      propertyTreeEnabled,
      propertyMeEnabled,
      vaultREEnabled,
      allPTreeTokens,
      selectedTokenForAgency,
      agencyHasActivatedPropertyTree,
      pTreeLoadingSpinners,
      propertyTreeUnsavedChanges,
      checkForEasyBondPayLoginIDPassword,
      enable_assign_property_to_building,
      enableTravelTimeOptimisationOption,
      enableViewingExcludeCalendarEventsTime,
      viewing_book_nearby_properties_enabled,
      isPaidPlanContinueModalOpen,
      fieldForPaidPlanContinueModal,
      enableDomainIntegration,
      domainExternalId,
      domainExternalIdErr,
      domainIntegrationUnsavedChanges,
      enquiry_sms_response,
      pum,
      rentRoll,
      smsUsage,
      smsBundleEnabled,
      bgcAllInclusiveBundleEnabled,
      integrationsBundleEnabled,
      productivityBundleEnabled,
      openDomainIDDuplicateModal,
      otherTeamsWithSameDomainIDInfo,
      userMFAConfigSms,
      userMFAConfigEmail,
      stripeCustomerID,
      stripeSubscriptionID,
      stripeSubscriptionBGCItemID,
      initialStripeCustomerID,
      initialStripeSubscriptionID,
      initialStripeSubscriptionBGCItemID,
      stripeCustomerIDChanged,
      stripeSubscriptionIDChanged,
      stripeSubscriptionItemIDBGCChanged,
      stripeCustomerIDModalOpen,
      stripeSubscriptionIDModalOpen,
      stripeSubscriptionItemIDBGCModalOpen,
    } = this.state
    const { agencyGUID, agencyProfile } = this.props

    let { error, edit, searchAgencies } = this.props
    const hasInvite = !edit
      ? invites.length &&
        invites[0].firstName &&
        invites[0].lastName &&
        invites[0].email &&
        this.isEmptyObject(!invitesErrors[0])
      : true

    const { pms: pumPMS, count: pumValue } = pum || {}
    const disabled = !(
      agencySlug &&
      this.isEmptyObject(errors) &&
      this.noInvitesErrors() &&
      this.isValidBranding() &&
      this.isValidExternalID()
    )

    const activationChangedBy =
      activationChangedUser &&
      `${activationChangedUser.firstName} ${activationChangedUser.lastName}`
    const activationChangedTime =
      activationChangedAt &&
      formatTime(activationChangedAt, HALF_DAY_HALF_MONTH_TIME_AM_PM)

    const unsavedChanges = !this.isFormEmpty() && this.state.unsavedChanges
    const unsavedChangesPTree =
      this.isPropertyTreeFormChanged() || propertyTreeUnsavedChanges
    const unsavedChangesEasyBondPay =
      this.isEasyBondPayChanged() && checkForEasyBondPayLoginIDPassword
    const unsavedDomainIntegration =
      domainIntegrationUnsavedChanges && this.isDomainIntegrationChanged()

    const { existingAgencyUserInvites = [] } = this.props
    const pendingInvites = existingAgencyUserInvites.filter((invite) => {
      return invite.validityStatus === 1
    })
    const notSentInvites = existingAgencyUserInvites.filter((invite) => {
      return invite.validityStatus === 0
    })
    const { acceptedInvites, confirmRemovingLastAdminModalDisplay } = this.state
    const selectedUtilityProvider =
      utilityConnectionCollection.find(
        (utility) => utility.name === this.state.utilityProvider.name,
      ) || utilityConnectionCollection[0]
    const isRayWhiteFranchise = this.state.showRayWhiteSetting

    const noPropertyTreeTokensAvailable =
      allPTreeTokens && allPTreeTokens.length === 0

    const teamOverviewPath = `/teams/${agencySlug}/overview`

    const { utilityProvider = {} } = agencyProfile || {}
    const {
      platformDefaultProvider: isPlatformDefaultProviderEnabled = false,
    } = utilityProvider || {}

    return (
      <div>
        <h4 className="mb25">
          {this.props.edit ? 'Agency Details' : 'Register Agency'}
        </h4>
        <div className="two-col-box-flex-row sm64">
          <div className="col-first">
            <div className="input-box">
              {this.props.disableSearchBox !== true && (
                <AgencySearchBox
                  searchFunction={searchAgencies}
                  placeholder="Search Real Estate Agency"
                  value={agency.name}
                  handleInputChange={this.updateAgency}
                  inputChangeCallback={this.validate('agency')}
                  handleInputBlur={this.validate('agency')}
                  className={errors.agency ? 'error' : ''}
                  SearchResultItem={AgencySearchItem}
                />
              )}
            </div>
            <div
              className={
                errors.agency && disabled ? 'alert alert-danger' : 'hide-alert'
              }
            >
              <div>{errors.agency}</div>
            </div>
            <div className="input-box">
              <input
                disabled={!agencyExist}
                type="text"
                required
                className={errors.companyName ? 'error' : ''}
                value={companyName}
                onChange={this.updateName('companyName')}
                onBlur={this.validate('companyName')}
              />
              <label>Company Name</label>
            </div>
            <div
              className={
                errors.companyName && disabled
                  ? 'alert alert-danger'
                  : 'hide-alert'
              }
            >
              <div>{errors.companyName}</div>
            </div>
            <div
              className={`input-box register-form-mobile-input-box ${
                errors.contactNumber ? 'error' : ''
              }`}
            >
              <IntlTelInputWrapper
                css={[
                  'intl-tel-input country-list',
                  'register-form-mobile-input',
                ]}
                utilsScript={'libphonenumber.js'}
                defaultCountry={'au'}
                onPhoneNumberChange={this.validateInternationalNumber}
                onPhoneNumberBlur={this.validateInternationalNumber}
                value={contactNumber}
                onlyCountries={['au']}
                placeholder={'+61 412 345 678 (Optional)'}
              />
              <label>Contact Number</label>
            </div>
            <div className="input-box">
              <input
                disabled={!agencyExist}
                type="text"
                required
                className={errors.contactEmail ? 'error' : ''}
                value={contactEmail}
                onChange={this.updateEmail('contactEmail')}
                onBlur={this.validate('contactEmail')}
              />
              <label>Contact email</label>
            </div>
            <div
              className={
                errors.contactEmail && disabled
                  ? 'alert alert-danger'
                  : 'hide-alert'
              }
            >
              <div>{errors.contactEmail}</div>
            </div>
            <Flex>
              <div className="input-box">
                <input
                  disabled={!agencyExist}
                  type="text"
                  required
                  className={errors.agencySlug ? 'error' : ''}
                  value={agencySlug}
                  onChange={this.updateName('agencySlug')}
                  onBlur={this.validate('agencySlug')}
                />
                <label>Agency Slug (e.g. rhnewtown)</label>
              </div>
              <Link
                to={teamOverviewPath}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LaunchRounded />
              </Link>
            </Flex>
            <div
              className={
                errors.agencySlug && disabled
                  ? 'alert alert-danger'
                  : 'hide-alert'
              }
            >
              <div>{errors.agencySlug}</div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="input-box">
                  <input
                    disabled={!agencyExist}
                    type="text"
                    required
                    className={errors.ABN && disabled ? 'error' : ''}
                    value={ABN}
                    onChange={this.update('ABN')}
                    onBlur={this.validate('ABN')}
                  />
                  <label>ABN</label>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-box">
                  <input
                    disabled={!agencyExist}
                    type="text"
                    required
                    className={errors.ACN && disabled ? 'error' : ''}
                    value={ACN}
                    onChange={this.update('ACN')}
                    onBlur={this.validate('ACN')}
                  />
                  <label>ACN (Optional)</label>
                </div>
              </div>
            </div>
            <div
              className={
                errors.ABN && disabled ? 'alert alert-danger' : 'hide-alert'
              }
            >
              <div>{errors.ABN}</div>
            </div>
            <div
              className={
                errors.ACN && disabled ? 'alert alert-danger' : 'hide-alert'
              }
            >
              <div>{errors.ACN}</div>
            </div>
            <div className="input-box">
              <input
                disabled={!agencyExist}
                type="text"
                required
                className={errors.realEstateLicenseNumber ? 'error' : ''}
                value={realEstateLicenseNumber}
                onChange={this.update('realEstateLicenseNumber')}
                onBlur={this.validate('realEstateLicenseNumber')}
              />
              <label>License Number</label>
            </div>
            <div
              className={
                errors.realEstateLicenseNumber && disabled
                  ? 'alert alert-info'
                  : 'hide-alert'
              }
            >
              <div>{errors.realEstateLicenseNumber}</div>
            </div>
            <div className="mb20 pb20 border-bottom-1px">
              <div className="table">
                <div className="cell-m">
                  <label className="wsnw">
                    <input
                      disabled={!agencyExist}
                      type="checkbox"
                      defaultChecked={isActive}
                      onChange={() => this.updateActivationStatus()}
                    />
                    <span>Activate</span>
                  </label>
                  {activationChangedBy && activationChangedTime && (
                    <Text
                      ml={4}
                      as="span"
                      fontStyle="italic"
                      color={theme.colors.gray500}
                    >
                      {activationChangedTime} {activationChangedBy}
                    </Text>
                  )}
                </div>
              </div>
            </div>

            <div className="mb20 pb20 border-bottom-1px">
              <h4 className="mb25">Billing</h4>
              <Flex alignItems="center" mb={4}>
                <Box>
                  <TextSpan as="div" mb={4} fontWeight={theme.fontWeights.h4}>
                    Plan
                  </TextSpan>
                  <NewForm.Dropdown
                    id="accountType"
                    name="accountType"
                    value={accountType}
                    onChange={this.updateStandardFormField('accountType')}
                    options={accountHelpers.accountTypes}
                    inputClass="height50"
                    idAsValue
                  />
                </Box>
                <Box ml={4}>
                  <TextSpan as="div" mb={4} fontWeight={theme.fontWeights.h4}>
                    PUM / rentroll
                  </TextSpan>
                  <Input
                    inputProps={{
                      placeholder: 'e.g. 350',
                      value: rentRoll,
                      onChange: this.update('rentRoll'),
                      maxWidth: '90px',
                      maxHeight: '20px',
                    }}
                    width="130px"
                    height="40px"
                  />
                </Box>
              </Flex>
              <Flex alignItems="center" mb={4}>
                <Box>
                  <TextSpan as="div" mb={4} fontWeight={theme.fontWeights.h4}>
                    Stripe customer ID
                  </TextSpan>
                  <Input
                    inputProps={{
                      placeholder: 'e.g. cus_Ep3r...',
                      value: stripeCustomerID,
                      onChange: this.update('stripeCustomerID'),
                      maxHeight: '20px',
                    }}
                    width="130px"
                    height="40px"
                    onBlur={this.handleStripeCustomerIDBlur}
                    containerProps={{
                      width: '200px',
                    }}
                  />
                </Box>
                <Box ml={4}>
                  <TextSpan as="div" mb={4} fontWeight={theme.fontWeights.h4}>
                    Stripe subscription ID
                  </TextSpan>
                  <Input
                    inputProps={{
                      placeholder: 'e.g. sub_10uQ....',
                      value: stripeSubscriptionID,
                      onChange: this.update('stripeSubscriptionID'),
                      maxHeight: '20px',
                    }}
                    width="130px"
                    height="40px"
                    onBlur={this.handleStripeSubscriptionID}
                    containerProps={{
                      width: '200px',
                    }}
                  />
                </Box>
              </Flex>
              {pum && (
                <Box>
                  <Text as="span">
                    PUM: {pumValue} (source: {pumPMS})
                  </Text>
                </Box>
              )}
              {smsUsage?.used && smsUsage.monthlyAllocation && (
                <Box mt={2}>
                  <Text as="span">
                    SMS Usage: {smsUsage.used}/{smsUsage.monthlyAllocation}
                  </Text>
                </Box>
              )}
              <Box mt={2}>
                <Text as="div" my={5} fontWeight={theme.fontWeights.h4}>
                  Bundle
                </Text>
                <Form.Checkbox
                  id="integrationBundle"
                  label="Integration Bundle"
                  checked={integrationsBundleEnabled}
                  onChange={() =>
                    this.updateCheckBox('integrationsBundleEnabled')
                  }
                  enableEdit
                />
                <Form.Checkbox
                  id="productivityBundle"
                  label="Productivity Bundle"
                  checked={productivityBundleEnabled}
                  onChange={() =>
                    this.updateCheckBox('productivityBundleEnabled')
                  }
                  enableEdit
                />
                <Form.Checkbox
                  id="smsBundle"
                  label="SMS Bundle"
                  checked={smsBundleEnabled}
                  onChange={() => this.updateCheckBox('smsBundleEnabled')}
                  enableEdit
                />
              </Box>
            </div>

            <div className="mb20 pb20 border-bottom-1px">
              <div className="table">
                <div className="cell-m">
                  <h4 className="mb25">Paid Plan Features</h4>
                  <Alert variant="warningWithBg" mb={6} fontSize={4}>
                    <Flex alignItems="center">
                      <WarningRounded
                        width={`${theme.width[4]}px`}
                        height={`${theme.width[4]}px`}
                        mr={3}
                      />
                      <Box ml={4}>
                        Please ensure the customer has authorised costs in
                        writing via Zendesk.{' '}
                        <a
                          href="https://snug.com/pricing/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          snug.com/pricing
                        </a>
                      </Box>
                    </Flex>
                  </Alert>
                  <Box mb={3}>
                    <Form.Checkbox
                      id="smsRegistrationConfirmed"
                      label="Send SMS viewing registration confirmations"
                      checked={smsRegistrationConfirmation}
                      onChange={() =>
                        this.updateCheckBox(
                          constants.smsRegistrationConfirmation,
                        )
                      }
                      enableEdit
                    />
                  </Box>
                  <Box mb={3}>
                    <Form.Checkbox
                      id="smsViewingCreated"
                      label="Send SMS viewing created notifications"
                      checked={smsViewingCreated}
                      onChange={() =>
                        this.updateCheckBox(constants.smsViewingCreated)
                      }
                      enableEdit
                    />
                  </Box>
                  <Box mb={3}>
                    <Form.Checkbox
                      id="smsViewingChanges"
                      label="Send SMS viewing updated notifications"
                      checked={smsViewingChanges}
                      onChange={() =>
                        this.updateCheckBox(constants.smsViewingChanges)
                      }
                      enableEdit
                    />
                  </Box>
                  <Box mb={3}>
                    <Form.Checkbox
                      id="smsEnNewCancelledViewing"
                      label="Send SMS viewing new/cancelled entry notice to tenants"
                      checked={smsEnNewCancelledViewing}
                      onChange={() =>
                        this.updateCheckBox(constants.smsEnNewCancelledViewing)
                      }
                      enableEdit
                    />
                    <div className="mb10">
                      <Form.Checkbox
                        id="smsViewingAttendeeDayBeforeEnabled"
                        label="Send prior day SMS reminders to registrants"
                        checked={smsViewingAttendeeDayBeforeEnabled}
                        onChange={() =>
                          this.updateCheckBox(
                            constants.smsViewingAttendeeDayBeforeEnabled,
                          )
                        }
                        enableEdit
                      />
                    </div>
                  </Box>
                  <Box mb={3}>
                    <Form.Checkbox
                      id="smsLeaseOffer"
                      label="Send SMS lease offer"
                      checked={smsLeaseOffer}
                      onChange={() =>
                        this.updateCheckBox(constants.smsLeaseOffer)
                      }
                      enableEdit
                    />
                  </Box>
                  <Box mb={3}>
                    <Form.Checkbox
                      id="smsRequestInformation"
                      label="Send SMS request information"
                      checked={smsRequestInformation}
                      onChange={() =>
                        this.updateCheckBox(constants.smsRequestInformation)
                      }
                      enableEdit
                    />
                  </Box>
                  <Box mb={3}>
                    <Form.Checkbox
                      id="enableBackgroundCheckBundle"
                      label="Enable Background Check"
                      checked={enableBackgroundCheckBundle}
                      onChange={() =>
                        this.updateCheckBox(
                          constants.enableBackgroundCheckBundle,
                        )
                      }
                      enableEdit
                    />
                    {enableBackgroundCheckBundle && (
                      <>
                        <TextSpan
                          as="div"
                          mb={4}
                          fontWeight={theme.fontWeights.h4}
                        >
                          Stripe Subscription Item ID
                        </TextSpan>
                        <Input
                          inputProps={{
                            placeholder: 'e.g. si_d1GF...',
                            value: stripeSubscriptionBGCItemID,
                            onChange: this.update(
                              'stripeSubscriptionBGCItemID',
                            ),
                            maxHeight: '20px',
                          }}
                          width="130px"
                          height="40px"
                          onBlur={this.handleStripeBGCItemIDBlur}
                          containerProps={{
                            width: '200px',
                          }}
                        />
                      </>
                    )}
                  </Box>
                  {enableBackgroundCheckBundle && (
                    <Box mb={3}>
                      <Form.Checkbox
                        id="bgCWorkflowRestrictionEnabled"
                        label="Require application is pre-approved+ to run Background Check"
                        checked={bgCWorkflowRestrictionEnabled}
                        onChange={() =>
                          this.updateCheckBox('bgCWorkflowRestrictionEnabled')
                        }
                        enableEdit
                      />
                    </Box>
                  )}
                  {enableBackgroundCheckBundle && (
                    <Box mb={3}>
                      <Form.Checkbox
                        id="bgcAllInclusiveBundleEnabled"
                        label="All included BGC bundle (Temporary - Do Not Change)"
                        checked={bgcAllInclusiveBundleEnabled}
                        onChange={() =>
                          this.updateCheckBox('bgcAllInclusiveBundleEnabled')
                        }
                        enableEdit
                      />
                    </Box>
                  )}
                  <Box mb={3}>
                    <Form.Checkbox
                      id="backgroundChecksReportingEnabled"
                      label="Enable background check activity reporting"
                      checked={backgroundChecksReportingEnabled}
                      onChange={() =>
                        this.updateCheckBox('backgroundChecksReportingEnabled')
                      }
                      enableEdit
                    />
                  </Box>
                  <Box mb={3}>
                    <Form.Checkbox
                      id="enableKeyManagement"
                      label="Enable key management"
                      checked={enableKeyManagement}
                      onChange={() =>
                        this.updateCheckBox(constants.enableKeyManagement)
                      }
                      enableEdit
                    />
                  </Box>
                  <Box mb={3}>
                    <Form.Checkbox
                      id="enquiry_sms_response"
                      label="Enquiry SMS response"
                      checked={enquiry_sms_response}
                      onChange={() =>
                        this.updateCheckBox(constants.enquiry_sms_response)
                      }
                      enableEdit
                    />
                  </Box>
                  <Box mb={3}>
                    <Form.Checkbox
                      id="enable_assign_property_to_building"
                      label="Enable building assignment in on-list"
                      checked={enable_assign_property_to_building}
                      onChange={this.updateAssigningPropertiesToBuildings}
                      enableEdit
                    />
                  </Box>
                  <Box mb={3}>
                    <Form.Checkbox
                      id="enableTravelTimeOptimisationOption"
                      label="Allow option for travel time optimisation in viewing schedule"
                      checked={enableTravelTimeOptimisationOption}
                      onChange={() =>
                        this.updateCheckBox(
                          constants.enableTravelTimeOptimisationOption,
                        )
                      }
                      enableEdit
                    />
                  </Box>
                  <div>
                    <Form.Checkbox
                      id="enableViewingExcludeCalendarEventsTime"
                      label="Exclude calendar events time from viewing schedule"
                      checked={enableViewingExcludeCalendarEventsTime}
                      onChange={() =>
                        this.updateCheckBox(
                          constants.enableViewingExcludeCalendarEventsTime,
                        )
                      }
                      enableEdit
                    />
                  </div>
                  <div className="mb25">
                    <Form.Checkbox
                      id="enableTravelTimeOptimisationOption"
                      label="Enable viewing schedule neighbouring properties option"
                      checked={viewing_book_nearby_properties_enabled}
                      onChange={this.updateBookNearbyProperties}
                      enableEdit
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.props.edit && (
              <div className="mb20 pb20 border-bottom-1px">
                <h4 className="mb25">MFA</h4>
                <div className="mb10">
                  <CheckBoxGeneral
                    id="userMFAConfigEmail"
                    label="Email"
                    checked={userMFAConfigEmail}
                    onChange={(event) =>
                      this.updateMFACheckbox(
                        event,
                        constants.userMFAConfigEmail,
                      )
                    }
                    eventProcessedByComponent
                    disabled={true}
                    showDisabledGrey={true}
                    toolTip={
                      <div>
                        <i
                          className="icon-help-outline fs16 gray-light-color ibm"
                          data-tooltip-content=""
                          data-tooltip-id="enableEmailMFA"
                        />
                      </div>
                    }
                  />
                  <div>
                    <ReactTooltip id="enableEmailMFA">
                      <span>Email MFA is not available for agencies</span>
                    </ReactTooltip>
                  </div>
                </div>
                <div className="mb10">
                  <CheckBoxGeneral
                    id="userMFAConfigSms"
                    label="SMS"
                    checked={userMFAConfigSms}
                    onChange={(event) =>
                      this.updateMFACheckbox(event, constants.userMFAConfigSms)
                    }
                    eventProcessedByComponent
                    disabled={true}
                    showDisabledGrey={true}
                    toolTip={
                      <div>
                        <i
                          className="icon-help-outline fs16 gray-light-color ibm"
                          data-tooltip-content=""
                          data-tooltip-id="enableSMSMFA"
                        />
                      </div>
                    }
                  />
                  <div>
                    <ReactTooltip id="enableSMSMFA">
                      <span>SMS MFA is enabled for all agencies</span>
                    </ReactTooltip>
                  </div>
                </div>
              </div>
            )}
            <div className="mb20 pb20 border-bottom-1px">
              <div className="table">
                <div className="cell-m">
                  <h4 className="mb25">Notifications</h4>
                  <Form.Checkbox
                    id="sendDailyDigestNotification"
                    label="Send Daily Digest Notification"
                    checked={sendDailyDigestNotification}
                    onChange={this.updateSendDailyDigestNotification}
                    enableEdit
                  />
                  <Form.Checkbox
                    id="sendWeeklyDigestNotification"
                    label="Send Weekly Digest Notification"
                    checked={sendWeeklyDigestNotification}
                    onChange={this.updateSendWeeklyDigestNotification}
                    enableEdit
                  />
                  <Form.Checkbox
                    id="sendPriceDropNotification"
                    label="Send Price Drop Notification"
                    checked={sendPriceDropNotification}
                    onChange={this.updateSendPriceDropNotification}
                    enableEdit
                  />
                </div>
              </div>
            </div>
            <div className="mb20 pb20 border-bottom-1px">
              <div className="table">
                <div className="cell-m">
                  <h4 className="mb25">Application configuration</h4>
                  <div>
                    {applicationCustomisationCheckBoxes.map(
                      (checkbox, index) => {
                        return (
                          <Form.Checkbox
                            key={index}
                            id={checkbox.id}
                            label={
                              checkbox.id === 'bondloan' &&
                              easy_bond_pay_enabled
                                ? checkbox.label +
                                  ' (easyBondPay showing instead)'
                                : checkbox.label
                            }
                            checked={applicationCustomisation[checkbox.field]}
                            onChange={this.updateApplicationConfiguration(
                              checkbox.field,
                            )}
                            enableEdit={checkbox.enableEdit}
                          />
                        )
                      },
                    )}
                  </div>
                </div>
              </div>
            </div>
            {agencyGUID && (
              <div className="mb20 pb20 border-bottom-1px">
                <div className="table">
                  <div className="cell-m">
                    <h4 className="mb25">Activity Report</h4>
                    <div className="mb40">
                      <Form.Checkbox
                        id="enableAdvancedActivityReport"
                        label="Enable advanced activity report tab"
                        checked={advancedActivityEnabled}
                        onChange={this.enableAdvancedActivityReport}
                        enableEdit
                      />
                    </div>
                    <h4 className="mb25">Branding</h4>
                    <p>
                      Enable branding to provide custom brand imagery on public
                      pages and communications, including agency name as SMS
                      sender.
                    </p>
                    <div className="mb40">
                      <Form.Checkbox
                        id="enableBranding"
                        label="Enable"
                        checked={brandingEnabled}
                        onChange={this.enableBranding}
                        enableEdit
                      />
                    </div>
                    {brandingEnabled && (
                      <div>
                        <BrandingUploader
                          header="Banner"
                          logoType={AgencyBrandingType.Banner}
                          brandingURL={brandingBannerURL}
                          agencyGUID={agencyGUID}
                          error={bannerUploadError}
                          onUploadBranding={this.onUploadBranding}
                          onRemoveBranding={this.onRemoveAgencyBranding}
                          brandingImgClass="banner-size"
                          brandingRequirement="510x120px png or jpg"
                          validationError={bannerValidationError}
                        />
                        <BrandingUploader
                          header="Logo"
                          logoType={AgencyBrandingType.Logo}
                          brandingURL={brandingLogoURL}
                          agencyGUID={agencyGUID}
                          error={logoUploadError}
                          onUploadBranding={this.onUploadBranding}
                          onRemoveBranding={this.onRemoveAgencyBranding}
                          brandingImgClass="logo-size"
                          brandingRequirement="200x50px png or jpg"
                          validationError={logoValidationError}
                        />
                        <div className="input-box">
                          <input
                            disabled={!agencyExist}
                            type="text"
                            required
                            className={errors.brandingSMSSender ? 'error' : ''}
                            value={brandingSMSSender}
                            onChange={this.update('brandingSMSSender')}
                            onBlur={this.validate('brandingSMSSender')}
                          />
                          <label>Branded SMS Sending Name</label>
                        </div>
                        <p>
                          SMS Sending Name will be the Sender for Agency
                          specific SMS Messages.
                        </p>
                        <div>
                          <div
                            className={
                              errors.brandingSMSSender && disabled
                                ? 'alert alert-danger'
                                : 'hide-alert'
                            }
                          >
                            <div>{errors.brandingSMSSender}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* always keeping this as true as we need to make sure an error is raised */}
            {/* while creating or editing the agency */}
            <div className="mb20 pb20 border-bottom-1px">
              <div className="table">
                <div className="cell-m">
                  <h4 className="mb25">Default listing profile</h4>
                  <div className="mb40">
                    <Form.Checkbox
                      id="defaultListingProfile"
                      label="Use default listing profile"
                      checked={true}
                      onChange={null}
                      enableEdit
                    />
                    <p>
                      Default listing will take effect if there are no team
                      members
                    </p>
                    <br />
                    <Form.Checkbox
                      id="forceDefaultListingProfile"
                      label="Force default listing profile on all listings"
                      checked={forceDefaultListingProfile}
                      onChange={this.updateForceListingProfile}
                      enableEdit
                    />
                    <p>
                      Force default listing assigns these details as the PM for
                      all properties, irrespective of team members. Force take
                      priority over default listing profile.
                    </p>
                  </div>
                  {(this.state.defaultListingProfile ||
                    this.state.forceDefaultListingProfile) && (
                    <div>
                      <div className="alert alert-danger">
                        To avoid an email loop, please ensure default listing
                        email address is different from email address on portal
                        listings.
                      </div>

                      <div className="col-sm-6">
                        <div className="input-box">
                          <input
                            disabled={!agencyExist}
                            type="text"
                            required
                            className={
                              errors.defaultListingProfileFirstName && disabled
                                ? 'error'
                                : ''
                            }
                            value={defaultListingProfileFirstName}
                            onChange={this.updateName(
                              'defaultListingProfileFirstName',
                            )}
                            onBlur={this.validate(
                              'defaultListingProfileFirstName',
                            )}
                          />
                          <label>Listing Profile First Name</label>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-box">
                          <input
                            disabled={!agencyExist}
                            type="text"
                            required
                            className={
                              errors.defaultListingProfileLastName && disabled
                                ? 'error'
                                : ''
                            }
                            value={defaultListingProfileLastName}
                            onChange={this.updateName(
                              'defaultListingProfileLastName',
                            )}
                            onBlur={this.validate(
                              defaultListingProfileLastName,
                            )}
                          />
                          <label>Listing Profile Last Name</label>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-box">
                          <input
                            disabled={!agencyExist}
                            type="text"
                            required
                            className={
                              errors.defaultListingProfileEmail ? 'error' : ''
                            }
                            value={defaultListingProfileEmail}
                            onChange={this.updateName(
                              'defaultListingProfileEmail',
                            )}
                          />
                          <label>Listing Profile Email</label>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-box">
                          <input
                            disabled={!agencyExist}
                            type="text"
                            required
                            className={
                              errors.defaultListingProfileMobile ? 'error' : ''
                            }
                            value={defaultListingProfileMobile}
                            onChange={this.updateName(
                              'defaultListingProfileMobile',
                            )}
                          />
                          <label>Listing Profile Mobile No.</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mb20 pb20 border-bottom-1px">
              <div className="table">
                <div className="cell-m">
                  <h4 className="mb25">Utility connection provider</h4>
                  <div className="two-col-box-flex-row sm64">
                    <div className="col-first">
                      <div className="input-box">
                        <UtilityProviderDropdown
                          label="Utility Provider"
                          name="name"
                          value={this.getUtilityProvideIndex()}
                          onChange={this.updateUtilityProvider('name')}
                          options={utilityConnectionOptions}
                          error={''}
                        />
                        {!isPlatformDefaultProviderEnabled &&
                          selectedUtilityProvider &&
                          selectedUtilityProvider.requirementMsg && (
                            <div className="alert alert-danger">
                              {selectedUtilityProvider.requirementMsg}
                            </div>
                          )}
                        {selectedUtilityProvider &&
                          (selectedUtilityProvider.isProviderAgentIDRequired ||
                            selectedUtilityProvider.isProviderAgentIDOptional) && (
                            <InputOptional
                              label={
                                selectedUtilityProvider.customAgentIdLabel ||
                                'Utility Provider Agent ID'
                              }
                              name="agentID"
                              value={this.state.utilityProvider.agentID}
                              onChange={this.updateUtilityProvider('agentID')}
                              error={utilityProviderError}
                            />
                          )}
                        {selectedUtilityProvider &&
                          selectedUtilityProvider.isRefIDRequired && (
                            <InputOptional
                              label={
                                selectedUtilityProvider.customAgentRefIdLabel ||
                                'Utility Provider Agent Ref ID'
                              }
                              name="agentRefID"
                              value={this.state.utilityProvider.agentRefID}
                              onChange={this.updateUtilityProvider(
                                'agentRefID',
                              )}
                              error={utilityProviderError}
                            />
                          )}
                      </div>
                      {isRayWhiteFranchise && (
                        <Form.Checkbox
                          id="rayWhiteConciergeEnabled"
                          label="Ray White Concierge"
                          checked={rayWhiteConciergeEnabled}
                          onChange={this.updateRayWhiteConciergeEnabled}
                          enableEdit
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb20 pb20 border-bottom-1px">
              <div className="table">
                <div className="cell-m">
                  <h4 className="mb25">Email auto-responder</h4>
                  <div className="mb40">
                    <Form.Checkbox
                      id="receiveEnquiry"
                      label="Receiving Enquiry Email"
                      checked={this.state.receiveEnquiry}
                      onChange={this.updateReceiveEnquiry}
                      enableEdit
                    />
                    {this.state.receiveEnquiry && this.state.emailLocalPart && (
                      <p>
                        Your team email will be{' '}
                        {`${this.state.emailLocalPart}@${mailDomain}`}
                      </p>
                    )}
                  </div>
                  <div className="mb40">
                    <Form.Checkbox
                      id="sendSuggestedProperties"
                      label="Send suggested properties"
                      checked={this.state.suggestedPropertiesEnabled}
                      onChange={this.updateEnableSuggestedProperties}
                      enableEdit
                    />
                  </div>
                  {this.state.receiveEnquiry && (
                    <div className="input-box">
                      <input
                        disabled={!agencyExist}
                        type="text"
                        required
                        className={errors.enquiryEmail ? 'error' : ''}
                        value={this.state.enquiryEmail}
                        onChange={this.update('enquiryEmail')}
                        onBlur={this.validate('enquiryEmail')}
                      />
                      <label>
                        Email address for onforwarding enquiries (e.g. agency
                        archive)
                      </label>
                    </div>
                  )}
                  {this.state.receiveEnquiry && (
                    <div
                      className={
                        errors.enquiryEmail && disabled
                          ? 'alert alert-danger'
                          : 'hide-alert'
                      }
                    >
                      <div>{errors.enquiryEmail}</div>
                    </div>
                  )}
                  {this.state.receiveEnquiry && (
                    <Form.RadioGroup
                      label="Enable enquiry auto responder"
                      id="enquiryResponseModeRadioGroup"
                      error=""
                    >
                      <Form.Checkbox
                        key="none"
                        id="none"
                        label="Disabled"
                        checked={
                          enquiryResponseModeNumber2String(
                            this.state.enquiryResponseMode,
                          ) === 'none'
                        }
                        onChange={this.updateEnquiryResponseMode}
                        enableEdit
                      />
                      <Form.Checkbox
                        key="selected"
                        id="selected"
                        label="Selected properties"
                        checked={
                          enquiryResponseModeNumber2String(
                            this.state.enquiryResponseMode,
                          ) === 'selected'
                        }
                        onChange={this.updateEnquiryResponseMode}
                        enableEdit
                      />
                      <Form.Checkbox
                        key="all"
                        id="all"
                        label="Enabled"
                        checked={
                          enquiryResponseModeNumber2String(
                            this.state.enquiryResponseMode,
                          ) === 'all'
                        }
                        onChange={this.updateEnquiryResponseMode}
                        enableEdit
                      />
                    </Form.RadioGroup>
                  )}
                  {this.state.receiveEnquiry &&
                    enquiryResponseModeNumber2String(
                      this.state.enquiryResponseMode,
                    ) === 'selected' && (
                      <CommentArea
                        label="Whitelisted Properties"
                        placeholder="Comma seperated property GUIDs"
                        name="whitelistedProperties"
                        rows={8}
                        value={this.state.whitelistedProperties}
                        onChange={this.updateWhitelistedProperties}
                        error={''}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className="mb20 pb20 border-bottom-1px">
              <div className="table">
                <div className="cell-m">
                  <h4 className="mb25">Viewing Run configuration</h4>
                  <div className="mb25">
                    <Form.Checkbox
                      id="viewingRunEnabled"
                      label="Enable viewing run (OLD - please use New)"
                      checked={this.state.viewingRunEnabled}
                      onChange={() => this.updateCheckBox('viewingRunEnabled')}
                      enableEdit
                    />
                  </div>
                  <div className="mb25 col-sm-12 pl0">
                    <Form.Checkbox
                      id="viewing_run_enabled"
                      label="Enable Viewing Run (NEW)"
                      checked={viewing_run_enabled}
                      onChange={() =>
                        this.updateCheckBoxMultiRun('viewing_run_enabled')
                      }
                      enableEdit
                    />
                    {viewing_run_enabled && (
                      <div className="ml30">
                        <Form.Checkbox
                          id="viewing_run_enabled_single"
                          label="Allow single team member"
                          checked={viewing_run_mode === 'single'}
                          onChange={() =>
                            this.updateMultiRunCheckboxSingleOrMulti(
                              'viewing_run_enabled_single',
                            )
                          }
                          enableEdit
                        />
                        <Form.Checkbox
                          id="viewing_run_enabled_multi"
                          label="Allow multiple team members (selected paid plans only)"
                          checked={viewing_run_mode === 'multi'}
                          onChange={() =>
                            this.updateMultiRunCheckboxSingleOrMulti(
                              'viewing_run_enabled_multi',
                            )
                          }
                          enableEdit
                        />
                      </div>
                    )}
                  </div>
                  <h4 className="mb25">Viewing entry notice configuration</h4>
                  <div className="mb25 col-sm-12 pl0">
                    <Form.Checkbox
                      id="entryNoticeMessageEnabled"
                      label="Enable viewing entry notice"
                      checked={viewingEntryNoticeEnabled}
                      onChange={() =>
                        this.updateCheckBox('viewingEntryNoticeEnabled')
                      }
                      enableEdit
                    />
                    {viewingEntryNoticeEnabled && (
                      <Form.CommentArea
                        value={viewingEntryNoticeMessage || ''}
                        placeholder="Please type your entry notice message here (optional)"
                        rows={3}
                        onChange={this.updateStandardFormField(
                          'viewingEntryNoticeMessage',
                        )}
                        id="entry_notice_message"
                      />
                    )}
                    {viewingEntryNoticeEnabled && (
                      <Form.Checkbox
                        id="viewingEntryNoticeRegulatiobEnabled"
                        label="Include regulated entry notice"
                        checked={viewingEntryNoticeRegulationEnabled}
                        onChange={() =>
                          this.updateCheckBox(
                            'viewingEntryNoticeRegulationEnabled',
                          )
                        }
                        enableEdit
                      />
                    )}

                    {viewingEntryNoticeRegulationEnabled && (
                      <NewForm.Dropdown
                        componentClass="col-sm-6 pl0"
                        inputClass="width100"
                        options={entryNoticeRegulationsOptions}
                        value={viewingEntryNoticeRegulation}
                        onChange={this.updateStandardFormField(
                          'viewingEntryNoticeRegulation',
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mb20 pb20 border-bottom-1px">
              <div className="table">
                <div className="cell-m">
                  <h4 className="mb25">Viewing Report</h4>
                  <div className="mb25 col-sm-12 pl0">
                    <Form.Checkbox
                      label="Allow market feedback to be excluded"
                      onChange={() =>
                        this.updateCheckBox('displayExcludedMarketFeedback')
                      }
                      checked={displayExcludedMarketFeedback}
                      toolTip={
                        <div>
                          <i
                            className="icon-help-outline fs16 gray-light-color ibm"
                            data-tooltip-content
                            data-tooltip-id="displayExcludedMarketFeedback"
                          />
                        </div>
                      }
                    />
                    <div>
                      <ReactTooltip id="displayExcludedMarketFeedback">
                        <span>Include Market Feedback in viewing report</span>
                      </ReactTooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb20 pb20 border-bottom-1px">
              <div className="table">
                <div className="cell-m">
                  <h4 className="mb25">Lease offer payment options</h4>
                  <p>
                    Configure the team payment options for sending Lease Offers.
                  </p>
                  <p className="mb0">Default Bond Amount</p>
                  <div className="row mb20">
                    <Dropdown
                      options={bondDurationOptionArray}
                      value={standardBondDurationWeeks}
                      onChange={this.updateStandardBondDurationWeeks}
                      label="Standard Bond Duration"
                      idAsValue={true}
                      inputClass="width100"
                      componentClass="col-sm-6 pl0 ml15"
                    />
                  </div>
                  <CommentArea
                    label="Default email message"
                    placeholder="Message"
                    name="offerEmailMessage"
                    rows={2}
                    value={offerEmailMessage}
                    onChange={this.updateOfferEmailMessage}
                    error=""
                  />
                  <CommentArea
                    label="Congratulations message"
                    placeholder="Message"
                    name="congratulationsMessage"
                    rows={2}
                    value={congratulationsMessage}
                    onChange={this.updateCongratulationsMessage}
                    error=""
                  />
                  <CommentArea
                    label="Initial payment / holdind deposit instructions"
                    placeholder="Instructions"
                    name="initialRentPaymentInstructions"
                    rows={2}
                    value={initialRentPaymentInstructions}
                    onChange={this.updateInitialRentPaymentInstructions}
                    error=""
                  />
                  <CommentArea
                    label="Bond payment instructions"
                    placeholder="Instructions"
                    name="bondPaymentInstructions"
                    rows={2}
                    value={bondPaymentInstructions}
                    onChange={this.updateBondPaymentInstructions}
                    error=""
                  />
                  <CommentArea
                    label="Remaining rent payment instructions"
                    placeholder="Instructions"
                    name="remainingRentPaymentInstructions"
                    rows={2}
                    value={remainingRentPaymentInstructions}
                    onChange={this.updateRemainingRentPaymentInstructions}
                    error=""
                  />
                  <CommentArea
                    label="Lease Signing instructions"
                    placeholder="Instructions"
                    name="leaseSigningInstructions"
                    rows={2}
                    value={leaseSigningInstructions}
                    onChange={this.updateLeaseSigningInstructions}
                    error=""
                  />
                  <CommentArea
                    label="Important information"
                    placeholder="Instructions"
                    name="importantInformationMessage"
                    rows={2}
                    value={importantInformationMessage}
                    onChange={this.updateImportantInformationMessage}
                    error=""
                  />
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="mb40">
                        <Form.Checkbox
                          id="enableEFTPayment"
                          label="Enable EFT"
                          checked={EFTPaymentEnabled}
                          onChange={this.updateEnableEFTPayment}
                          enableEdit
                        />
                      </div>
                    </div>

                    {EFTPaymentEnabled && (
                      <EFTPaymentsSetting
                        EFTPaymentEnabled={EFTPaymentEnabled}
                        disabled={disabled}
                        errors={errors}
                        paymentsSetting={this.state.EFTPaymentsV2}
                        control={{
                          update: this.update,
                          setValidationErrors: this.setValidationErrors,
                        }}
                      />
                    )}
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="mb40">
                        <Form.Checkbox
                          id="enableBPAYPayment"
                          label="Enable BPAY"
                          checked={BPAYPaymentEnabled}
                          onChange={this.updateEnableBPAYPayment}
                          enableEdit
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      {BPAYPaymentEnabled && (
                        <div className="input-box">
                          <input
                            type="text"
                            required
                            className={
                              errors.BPAYPaymentBillerCode && disabled
                                ? 'error'
                                : ''
                            }
                            value={BPAYPaymentBillerCode}
                            onChange={this.update('BPAYPaymentBillerCode')}
                            onBlur={this.validate('BPAYPaymentBillerCode')}
                          />
                          <label>Biller code</label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="mb40">
                        <Form.Checkbox
                          id="enableDEFTPayment"
                          label="Enable DEFT"
                          checked={DEFTPaymentEnabled}
                          onChange={this.updateEnableDEFTPayment}
                          enableEdit
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="mb40">
                        <Form.Checkbox
                          id="enableSnugCCPayment"
                          label="Enable Snug CC"
                          checked={snugCCPaymentEnabled}
                          onChange={this.updateEnableSnugCCPayment}
                          enableEdit
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb20 pb20 border-bottom-1px">
              <div className="table">
                <div className="cell-m">
                  <h4 className="mb25">Integrations</h4>
                  <div className="mb10 col-sm-12 pl0">
                    <Form.Checkbox
                      id="external_agency_id_enabled"
                      label="Snug Team ID"
                      checked={external_agency_id_enabled}
                      onChange={() =>
                        this.updateCheckBox('external_agency_id_enabled')
                      }
                      enableEdit
                    />
                    <p>Enables the partner API for this team.</p>
                    {external_agency_id_enabled && (
                      <div className="input-box">
                        {!this.isValidExternalID() && (
                          <Alert variant="primaryWithBg">
                            Please enter a Snug Team ID
                          </Alert>
                        )}
                        <input
                          type="text"
                          required
                          value={this.state.externalSnugTeamID}
                          onChange={this.update('externalSnugTeamID')}
                          className={!this.isValidExternalID() ? 'error' : ''}
                        />
                      </div>
                    )}
                  </div>
                  {external_agency_id_enabled && (
                    <Box mb={4} className={'col-sm-12'} pl={0}>
                      <Form.Checkbox
                        id="enable_domain_integration"
                        label="Activate Domain.com.au integration"
                        enableEdit
                        checked={enableDomainIntegration}
                        onChange={this.updateIntegrateDomain}
                        toolTip={
                          <div>
                            <i
                              className="icon-help-outline fs16 gray-light-color ibm"
                              data-tooltip-content=""
                              data-tooltip-id="domainIntegration"
                            />
                          </div>
                        }
                      />
                      {enableDomainIntegration && (
                        <Box fontSize={5} mb={7}>
                          <InputTextRequired
                            value={domainExternalId}
                            onChange={this.updateDomainAgencyId}
                            placeholder="Domain Agency ID"
                            error={domainExternalIdErr}
                            onBlurFunc={this.checkIfDomainIdExists}
                          />
                        </Box>
                      )}
                      <ReactTooltip id="domainIntegration">
                        <span>
                          Activate apply and viewing registration links for
                          Domain.com.au with Domain Agency ID. Domain must also
                          activate.
                        </span>
                      </ReactTooltip>
                    </Box>
                  )}
                  <div className="mb25 col-sm-12 pl0">
                    <Form.Checkbox
                      id="console_cloud_enabled"
                      label="Enable Console Cloud Portal"
                      checked={console_cloud_enabled}
                      onChange={() =>
                        this.updateCheckBox('console_cloud_enabled')
                      }
                      enableEdit
                    />
                    {consoleEditable && (
                      <div className="alert alert-danger">
                        Office Key is added by the office
                      </div>
                    )}
                    {console_cloud_enabled && (
                      <div className="input-box">
                        <input
                          disabled={consoleEditable}
                          type="text"
                          required
                          className={
                            errors.console_cloud_external_agency_id
                              ? 'error'
                              : ''
                          }
                          value={console_cloud_external_agency_id}
                          onChange={this.update(
                            'console_cloud_external_agency_id',
                          )}
                          onBlur={this.validate(
                            'console_cloud_external_agency_id',
                          )}
                        />
                        {!consoleEditable && (
                          <label>
                            Office Key (From Console Cloud Marketplace)
                          </label>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="mb25 col-sm-12 pl0">
                    <Form.Checkbox
                      id="propertyMeEnabled"
                      label="Enable PropertyMe.com"
                      checked={propertyMeEnabled}
                      onChange={() => this.updateCheckBox('propertyMeEnabled')}
                      enableEdit
                    />
                  </div>

                  <div className="mb25 col-sm-12 pl0">
                    <Form.Checkbox
                      id="propertyTreeEnabled"
                      label="Enable PropertyTree.com (BETA)"
                      checked={propertyTreeEnabled}
                      onChange={() =>
                        this.updateCheckBox('propertyTreeEnabled')
                      }
                      enableEdit
                    />
                    <div className="alert alert-danger">
                      In Beta testing, please contact eng team before activating
                    </div>
                    {propertyTreeEnabled && (
                      <>
                        {noPropertyTreeTokensAvailable && (
                          <div className="alert alert-danger">
                            Activate on PropertyTree
                          </div>
                        )}
                        <Flex alignItems="center" mb="20px">
                          <NewForm.Dropdown
                            id="accountType"
                            name="accountType"
                            value={selectedTokenForAgency}
                            onChange={(event) => {
                              this.updatePropertyTreeSelect(
                                'selectedTokenForAgency',
                                event,
                              )
                            }}
                            label="Select"
                            options={
                              allPTreeTokens &&
                              allPTreeTokens.map((token) => {
                                return {
                                  guid: token.id,
                                  name: token.company_name,
                                }
                              })
                            }
                            idAsValue
                            selectIsDisabled={agencyHasActivatedPropertyTree}
                          />
                          <div
                            className="blue-link-style ml10"
                            onClick={() => this.refreshPTreeTokens(agencyGUID)}
                          >
                            Refresh Tokens
                            {pTreeLoadingSpinners.refresh && (
                              <i className="fa fa-spinner fa-pulse ml5" />
                            )}
                          </div>
                        </Flex>
                        <Form.Button
                          className="mb10 mr10"
                          onClick={
                            agencyHasActivatedPropertyTree
                              ? () => this.deactivatePTreeForAgency()
                              : () => this.activatePTreeForAgency()
                          }
                          disabled={noPropertyTreeTokensAvailable}
                          label={
                            agencyHasActivatedPropertyTree
                              ? 'Deactivate'
                              : 'Activate'
                          }
                          loading={
                            pTreeLoadingSpinners.activate ||
                            pTreeLoadingSpinners.deactivate
                          }
                        />
                      </>
                    )}
                  </div>

                  <KolmeoIntegration
                    agencyId={agencyGUID}
                    integrationsBundleEnabled={integrationsBundleEnabled}
                  />

                  <ManagedAppIntegration
                    agencyId={agencyGUID}
                    integrationsBundleEnabled={integrationsBundleEnabled}
                  />

                  <div className="mb25 col-sm-12 pl0">
                    <Form.Checkbox
                      id="vaultREEnabled"
                      label="Enable VaultRE.com.au"
                      checked={vaultREEnabled}
                      onChange={() => this.updateCheckBox('vaultREEnabled')}
                      enableEdit
                    />
                  </div>

                  <div className="mb25 col-sm-12 pl0">
                    <Form.Checkbox
                      id="easy_bondpay"
                      label="Enable easyBondpay for team"
                      checked={easy_bond_pay_enabled}
                      onChange={() =>
                        this.updateCheckBox('easy_bond_pay_enabled')
                      }
                      enableEdit
                    />
                    {easy_bond_pay_enabled && (
                      <>
                        <div className="input-box">
                          <input
                            disabled={false}
                            type="text"
                            required
                            className={
                              errors.easy_bond_pay_broker_login_id
                                ? 'error'
                                : ''
                            }
                            value={easy_bond_pay_broker_login_id}
                            onChange={this.update(
                              'easy_bond_pay_broker_login_id',
                            )}
                            onBlur={this.validate(
                              'easy_bond_pay_broker_login_id',
                            )}
                          />
                          <label>Broker Login ID</label>
                        </div>
                        <div className="input-box">
                          <input
                            disabled={false}
                            type="password"
                            required
                            className={
                              errors.easy_bond_pay_broker_login_password
                                ? 'error'
                                : ''
                            }
                            value={easy_bond_pay_broker_login_password}
                            onChange={this.update(
                              'easy_bond_pay_broker_login_password',
                            )}
                            onBlur={this.validate(
                              'easy_bond_pay_broker_login_password',
                            )}
                          />
                          <label>Broker Login Password</label>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mb20 pb20 border-bottom-1px">
              <div className="table">
                <div className="cell-m">
                  <h4 className="mb25">Invite team members</h4>
                  <InviteForm
                    title="Invite"
                    invitesNumber={invitesNumber}
                    invites={invites}
                    editable={true}
                    updateInvite={this.updateInvite}
                    validateInvite={this.validateInvite}
                    removeInvite={this.removeInvite}
                    errors={invitesErrors}
                  />
                  <button
                    className="btn btn-round mb20"
                    onClick={this.newInviteField}
                  >
                    <i className="icon-add" />
                  </button>
                  <div className={error ? 'alert alert-danger' : 'hide-alert'}>
                    <div>{translateErrorCodeToMessage(error)}</div>
                  </div>
                  <button
                    className="mt15 mb30"
                    onClick={this.submit}
                    disabled={disabled}
                  >
                    {this.props.edit ? 'Update Profile' : 'Join Snug'}{' '}
                    <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
                  </button>
                </div>
              </div>
            </div>
            {pendingInvites.length !== 0 && (
              <InviteForm
                title="Pending"
                invitesNumber={pendingInvites.length}
                invites={pendingInvites}
                editable={false}
                updateInvite={() => {}}
                validateInvite={() => {}}
                removeInvite={undefined}
                errors={pendingInvites.map(() => ({}))}
              />
            )}
            {acceptedInvites.length !== 0 && (
              <InviteForm
                title="Accepted"
                invitesNumber={acceptedInvites.length}
                invites={acceptedInvites}
                editable={false}
                roleEditable={true}
                updateInvite={this.updateAcceptedMemberRole}
                validateInvite={() => {}}
                removeInvite={this.deleteAcceptedMemberRole}
                errors={acceptedInvites.map(() => ({}))}
              />
            )}
            {notSentInvites.length !== 0 && (
              <InviteForm
                title="Not Sent"
                invitesNumber={notSentInvites.length}
                invites={notSentInvites}
                editable={false}
                updateInvite={() => {}}
                validateInvite={() => {}}
                removeInvite={undefined}
                errors={notSentInvites.map(() => ({}))}
              />
            )}
            {isPaidPlanContinueModalOpen && (
              <PaidPlanContinueModal
                primaryAction={this.continueWithSelectingCheckbox}
                secondaryAction={this.togglePaidPlanContinueModal}
                fieldForPaidPlanContinueModal={fieldForPaidPlanContinueModal}
              />
            )}
            {confirmRemovingLastAdminModalDisplay && (
              <LastAdminRemoveModal
                toggleConfirmRemovingLastAdminModal={
                  this.toggleConfirmRemovingLastAdminModal
                }
              />
            )}
            {agencyGUID && (
              <AgencySourceForm
                edit={edit}
                initialState={this.state.agencyInfo}
                clearError={this.props.clearError}
                error={this.props.createError}
                agencyGUID={agencyGUID}
              />
            )}
            <UnsavedMessage unsavedChanges={unsavedChanges} />
            <UnsavedMessage
              unsavedChanges={unsavedChangesPTree}
              message="PropertyTree Changes will be lost"
            />
            <UnsavedMessage
              unsavedChanges={unsavedChangesEasyBondPay}
              message="Please enter easyBondPay Login ID and Password. Click Cancel to enter."
            />
            <UnsavedMessage unsavedChanges={unsavedDomainIntegration} />
            {openDomainIDDuplicateModal && (
              <ActivationConfirmationModal
                toggleActivationConfirmationModal={
                  this.toggleActivationConfirmationModal
                }
                secondaryAction={() => {
                  this.clearDomainIdValue()
                  this.toggleActivationConfirmationModal()
                }}
                continueWithActivationFn={this.continueWithActivationFn}
                otherTeamsWithSameDomainIDInfo={otherTeamsWithSameDomainIDInfo}
                isAdminForCurrentAndOtherTeams={true}
                continueLabel="Continue"
              />
            )}
            {stripeCustomerIDModalOpen && stripeCustomerIDChanged && (
              <Modal
                modalHeading="Are you sure?"
                modalBody={
                  <Box>
                    <Box>
                      You are changing the billing configuration for this team.
                      Please double check that the changes are correct before
                      proceeding.
                    </Box>
                    {initialStripeCustomerID && (
                      <Box mt={4}>Old Value: {initialStripeCustomerID}</Box>
                    )}
                    <Box>New value: {stripeCustomerID}</Box>
                  </Box>
                }
                primaryLabel="Confirm"
                secondaryLabel="Cancel"
                toggleModal={() => {
                  this.resetStripeCustomerID()
                  this.toggleStripeCustomerIDModal()
                }}
                secondaryAction={() => {
                  this.resetStripeCustomerID()
                  this.toggleStripeCustomerIDModal()
                }}
                primaryAction={this.toggleStripeCustomerIDModal}
              />
            )}
            {stripeSubscriptionIDModalOpen && stripeSubscriptionIDChanged && (
              <Modal
                modalHeading="Are you sure?"
                modalBody={
                  <Box>
                    <Box>
                      You are changing the billing configuration for this team.
                      Please double check that the changes are correct before
                      proceeding.
                    </Box>
                    {initialStripeSubscriptionID && (
                      <Box mt={4}>Old Value: {initialStripeSubscriptionID}</Box>
                    )}
                    <Box>New value: {stripeSubscriptionID}</Box>
                  </Box>
                }
                primaryLabel="Confirm"
                secondaryLabel="Cancel"
                toggleModal={() => {
                  this.resetStripeTeamSubscriptionID()
                  this.toggleStripeSubscriptionTeamIDModal()
                }}
                secondaryAction={() => {
                  this.resetStripeTeamSubscriptionID()
                  this.toggleStripeSubscriptionTeamIDModal()
                }}
                primaryAction={this.toggleStripeSubscriptionTeamIDModal}
              />
            )}
            {stripeSubscriptionItemIDBGCModalOpen &&
              stripeSubscriptionItemIDBGCChanged && (
                <Modal
                  modalHeading="Are you sure?"
                  modalBody={
                    <Box>
                      <Box>
                        You are changing the billing configuration for this
                        team. Please double check that the changes are correct
                        before proceeding.
                      </Box>
                      {initialStripeSubscriptionBGCItemID && (
                        <Box mt={4}>
                          Old Value: {initialStripeSubscriptionBGCItemID}
                        </Box>
                      )}
                      <Box>New value: {stripeSubscriptionBGCItemID}</Box>
                    </Box>
                  }
                  primaryLabel="Confirm"
                  secondaryLabel="Cancel"
                  toggleModal={() => {
                    this.resetStripeItemSubscriptionID()
                    this.toggleStripeSubscriptionItemTeamIDModal()
                  }}
                  secondaryAction={() => {
                    this.resetStripeItemSubscriptionID()
                    this.toggleStripeSubscriptionItemTeamIDModal()
                  }}
                  primaryAction={this.toggleStripeSubscriptionItemTeamIDModal}
                />
              )}
          </div>
        </div>
      </div>
    )
  }
}

RegisterAgencyForm.propTypes = {
  error: PropTypes.string,
  clearError: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
}

RegisterAgencyForm.defaultProps = {
  error: null,
}

export default RegisterAgencyForm
