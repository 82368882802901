import React, { useCallback, useEffect, useState } from 'react'

import styled from 'styled-components'

import { Flex } from 'app/components/design-system-components/index'
import { Input } from 'app/components/design-system-components/inputs'
import { FilterDropdown } from 'app/components/design-system-components/list-filters/FilterDropdown'
import { Text } from 'app/components/design-system-components/typography'
import {
  buildRenderedFilterConfig,
  filtersIds,
} from 'app/features/teams/key-logger/KeysListFilters/filters.config'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const defaultTriggerProps = {
  mr: theme.space[3] + 'px',
  mb: theme.space[3] + 'px',
}

const VerticalLineCmp = styled(Flex)`
  border-left: 2px solid ${(props) => props.theme.colors.gray100};
`

export const KeysFilters = ({
  filters,
  updateFilter,
  teamId,
  ...containerProps
}) => {
  const {
    [filtersIds.borrower]: borrowerFilterConfig,
    [filtersIds.status]: statusFilterConfig,
  } = buildRenderedFilterConfig(filters, updateFilter)
  const [keyFilterText, setKeyFilterText] = useState(
    filters[filtersIds.keySearch],
  )

  useEffect(() => {
    if (filters[filtersIds.keySearch] !== keyFilterText)
      setKeyFilterText(filters[filtersIds.keySearch])
  }, [filters[filtersIds.keySearch]])

  const debounceUpdateFilter = useCallback(
    debounce(
      (text) => updateFilter(filtersIds.keySearch, text),
      STANDARD_TIMEOUT,
    ),
    [],
  )

  const updateKeyFilter = ({ target: { value } }) => {
    setKeyFilterText(value)
    debounceUpdateFilter(value)
  }

  return (
    <Flex
      flexWrap="wrap"
      mt={theme.space[3] + 'px'}
      alignItems="center"
      {...containerProps}
    >
      <Text
        as="span"
        fontWeight={theme.fontWeights[6]}
        fontSize={theme.fontSizes.pSmall12}
        color={theme.colors.black}
        mr={3}
        mb={3}
      >
        Key:{' '}
      </Text>
      <Input
        inputProps={{
          placeholder: 'Key #',
          value: keyFilterText,
          onChange: updateKeyFilter,
          maxWidth: '64px',
        }}
        maxWidth="80px"
        mr={3}
        mb={1}
      />

      <VerticalLineCmp alignItems="center" ml={2} pl={5}>
        <Text
          as="span"
          fontWeight={theme.fontWeights[6]}
          fontSize={theme.fontSizes.pSmall12}
          color={theme.colors.black}
          mr={3}
          mb={3}
        >
          Borrower:{' '}
        </Text>
        <FilterDropdown
          filterConfig={borrowerFilterConfig}
          triggerCmpProps={defaultTriggerProps}
          filterBodyProps={{ teamId }}
        />
      </VerticalLineCmp>

      <VerticalLineCmp alignItems="center" ml={2} pl={5}>
        <Text
          as="span"
          fontWeight={theme.fontWeights[6]}
          fontSize={theme.fontSizes.pSmall12}
          color={theme.colors.black}
          mr={3}
          mb={3}
        >
          Status:{' '}
        </Text>
        <FilterDropdown
          filterConfig={statusFilterConfig}
          triggerCmpProps={defaultTriggerProps}
        />
      </VerticalLineCmp>
    </Flex>
  )
}
