import { websiteUrl } from 'config/env'
import moment from 'moment'
import qs from 'qs'

import applicationDialogue from 'app/assets/icons/application_icon_dialogue.svg'
import applicationApprove from 'app/assets/icons/application_note_approve.svg'
import applicationDecline from 'app/assets/icons/application_note_decline.svg'
import noImagePlaceholderUrl from 'app/assets/icons/no-image-placeholder.jpg'
import * as ErrorCodes from 'app/constants/error_codes'
import { declineReasonsIDs } from 'app/constants/rental-references.constants'
import isEmptyObject from 'app/shared_components/check_empty_object'
import { isMobileOrTablet } from 'app/shared_components/helpers'
import { LocalStorageUtils, SessionStorageUtils } from 'app/storage_utils'
import {
  isAutoWithdrawn,
  RECENTLY_DECLINED_THRESHOLD_IN_DAYS,
} from 'app/utils/applications/helpers'
import { getDiffInDays } from 'app/utils/datetime/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import { RR_TO_LITE_URL_PART } from 'app/utils/url/helpers'
import { FeatureFlag } from 'config/features'

export const SNUG_TERMS_URL = `${websiteUrl}/terms`
export const SNUG_BONDCOVER_TERMS_URL = `${websiteUrl}/bondcover/terms`
export const SNUG_PRIVACY_URL = `${websiteUrl}/privacy`
export const SNUG_SAFETY_URL = `${websiteUrl}/safety`
export const SNUG_REFERRAL_AGREEMENT_TEMPLATE =
  'https://snugco.s3.ap-southeast-2.amazonaws.com/assets/docs/20221101+-+Snug+Subscription+and+Referral+Agreement+-+Final+-+Published.pdf'
export const DISCLOSURE_AUTHORITY_TEMPLATE =
  'https://s3-ap-southeast-2.amazonaws.com/snugco/assets/docs/disclosure_blank.pdf'

export const CONSUMER_GUIDE_URL =
  'https://help.snug.com/hc/en-us/articles/360003856536'
export const DISCRIMINATION_STATEMENT_URL =
  'https://help.snug.com/hc/en-us/articles/360000771916'

export const PETS_ARTICLE_URL =
  'https://help.snug.com/hc/en-us/articles/360001354735'

export const SNUG_SUBMIT_REQUEST_URL =
  'https://help.snug.com/hc/en-us/requests/new'
export const YES = 'true'
export const NO = 'false'

export const checkForWindowWidth = () => {
  return window.innerWidth
}

export const isBreakpointReached = (bpWidth) =>
  window.innerWidth <= parseInt(bpWidth)

const teamsRoutes = {
  properties: {
    createArchived: '/teams/:teamSlug/sm/properties/create-archived',
  },
  settings: {
    following: 'teams/:teamSlug/settings/following',
    members: 'teams/:teamSlug/settings/members',
  },
}

export const routes = {
  applyApplication: '/sm/applications/:applicationId',
  leaseAttachmentManager:
    '/sm/applications/:applicationId/offer/:agencyId/manager',
  rentalReferenceInboxFirstTime: '/teams/:teamSlug/rental-references-f',
  viewingRuns: {
    edit: '/teams/:teamSlug/viewings/viewing-run/:viewingRunGUID/edit',
    editPreview:
      '/teams/:teamSlug/viewings/viewing-run/:viewingRunGUID/preview/edit',
  },
  teams: teamsRoutes,
}

export const urlIds = {
  propertyPublishingOptions: 'propertyPublishingOptions',
  updateOfferBase: 'updateOfferBase',
  offerPreferences: 'profilePreferences',
  notFound: 'NotFound',
  updateRegistrationInfo: 'updateRegistrationInfo',
  viewingRuns: {
    edit: 'viewingRuns.edit',
    editPreview: 'viewingRuns.preview',
  },
  keyLogger: 'keyLogger',
  importKeys: 'importKeys',
  messagesImport: 'messagesImport',
  manualPersonalReferenceSurvey: 'manualPersonalReferenceSurvey',
  personalReferenceSurveySubmitted: 'personalReferenceSurveySubmitted',
  ownerDisclosuresSubmitted: 'ownerDisclosuresSubmitted',
  teams: {
    properties: {
      createArchived: 'teams.properties.createArchived',
    },
    settings: {
      following: 'teams.settings.following',
      members: 'teams.settings.members',
      screening: 'teams.settings.screening',
    },
    activity: {
      backgroundChecks: 'activity.backgroundChecks',
    },
  },
  join: {
    index: 'index',
    updateRegistration: 'updateRegistration',
    rr: 'rr',
    verify: {
      mobile: 'join.verify.mobile',
      twoFA: { email: 'join.verify.2fa.email', sms: 'join.verify.2fa.sms' },
      rr: 'join.verify.rr',
    },
  },
  profileAboutUs: 'profileAboutUs',
}

export const pathsSchemas = {
  [urlIds.manualPersonalReferenceSurvey]:
    '/sm/personal-reference/:referenceGUID/survey/manual',
}

export function urlTo(what, payload = {}) {
  const routes = {
    login: `/login`,
    join: `/join`,
    [urlIds.join.rr]: '/join/rr',
    [urlIds.join.index]: '/join',
    [urlIds.join.updateRegistration]: '/join/update-registration',
    [urlIds.join.verify.mobile]: '/join/verify',
    [urlIds.join.verify.rr]: '/join/verify/rr',
    [urlIds.join.verify.twoFA.email]: `/join/verify/2fa/email`,
    [urlIds.join.verify.twoFA.sms]: `/join/verify/2fa/sms`,
    updateRegistrationInfo: `/update-registration`,
    review: `/sm/property/${payload.propertyId}/review`,
    question: `/sm/property/${payload.propertyId}/question`,
    apply:
      payload && payload.applyAnywhere
        ? `/sm/property/${payload.propertyId}/apply?applyanywhere=${payload.applyAnywhere}`
        : `/sm/property/${payload.propertyId}/apply` + referrerParams(payload),
    applyConfirmationSummary: `/sm/applications/${payload.applicationId}/apply-confirmation`,
    applyConfirmationSummaryBallot: `/sm/applications/${payload.applicationId}/apply-confirmation-ballot`,
    propertyDetails: `/sm/property/${payload.propertyId}`,
    thanks: `/sm/property/${payload.propertyId}/thanks`,
    applications: '/sm/applications',
    application:
      `/sm/applications/${payload.applicationId}` + referrerParams(payload),
    renterApplications: `/sm/property/${payload.propertyId}/applications`,
    propertyDashboard: `/sm/property/${payload.propertyId}/dashboard`,

    //the temporary and primary url has been temporarily exchanged. Should be tidy up or reverted when the final version is completed.
    renterApplication: `/sm/property/${payload.propertyId}/applications/${payload.applicationId}/applicant/${payload.applicantId}`,
    renterApplicationTemporary: `/sm/property/${payload.propertyId}/applications/${payload.applicationId}/applicant/${payload.applicantId}/temporary`,
    renterApplicationPortal: `/sm/property/${payload.propertyId}/applications/${payload.applicationId}`,
    registerBtnLinkTo: `/sm/property/${payload.propertyId}/viewings/owner/temporary`,
    registerBtnLinkToTemporary: `/sm/property/${payload.propertyId}/viewings/owner`,
    managerDashboard:
      payload && payload.teamSlug
        ? `/teams/${payload.teamSlug}/portfolio/dashboard`
        : '/portfolio/dashboard',
    managerDashboardTemporary:
      payload && payload.teamSlug
        ? `/teams/${payload.teamSlug}/dashboard`
        : '/portfolio/dashboard',

    myProperties: '/sm/properties',
    listingManagerViewings: '/sm/properties/viewings',
    offeredApplication: `/sm/property/${payload.propertyId}/offer/${payload.applicationId}`,
    withdrawApplication: `/sm/applications/${payload.applicationId}/withdraw-confirmation`,
    payHoldingDeposit: `/sm/property/${payload.propertyId}/offer/${payload.applicationId}/pay-holding-deposit`,
    signLease: `/sm/property/${payload.propertyId}/offer/${payload.applicationId}/sign-lease`,
    signatureSign: `/sm/property/${payload.propertyId}/offer/${payload.applicationId}/signature-sign`,
    updatePropertyBasics: `/sm/properties/update/${payload.id}/basics`,
    updatePropertyAmenities: FeatureFlag.Advertisement.isOn
      ? `/sm/advertise/amenities/${payload.propertyId || payload.id}`
      : '',
    updatePropertyPhotos:
      payload && payload.teamSlug
        ? `/teams/${payload.teamSlug}/sm/properties/update/${payload.id}/photos`
        : `/sm/properties/update/${payload.id}/photos`,
    updatePropertyDescription: FeatureFlag.Advertisement.isOn
      ? `/sm/advertise/description/${payload.propertyId || payload.id}`
      : '',
    createProperty:
      payload && payload.teamSlug
        ? `/teams/${payload.teamSlug}/sm/properties/create`
        : '/sm/properties/create',
    [urlIds.teams.properties
      .createArchived]: `/teams/${payload.teamSlug}/sm/properties/create-archived`,
    createOffer:
      payload && payload.teamSlug
        ? `/teams/${payload.teamSlug}/sm/properties/${payload.propertyId}/offers/create`
        : `/sm/properties/${payload.propertyId}/offers/create`,
    [urlIds.updateOfferBase]: `/sm/properties/${payload.propertyId}/offers/update/${payload.offerId}`,
    updateOfferPortals: FeatureFlag.Advertisement.isOn
      ? `/sm/advertise/portals/${payload.propertyId}/${payload.offerId}`
      : '',
    updateOfferPayment: FeatureFlag.Advertisement.isOn
      ? `/sm/advertise/pay/${payload.propertyId}/${payload.offerId}`
      : '',
    updateOfferConfirmation: `/sm/properties/${payload.propertyId}/offers/update/${payload.offerId}/confirmation`,
    propertyGetStarted:
      payload && payload.teamSlug
        ? `/teams/${payload.teamSlug}/sm/properties/get-started`
        : `/sm/properties/get-started`,
    [urlIds.offerPreferences]: `/sm/properties/${payload.offerId}/preferences`,
    profileViewings: `/sm/properties/${payload.offerId}/viewings`,
    profileLettingDetails: '/sm/properties/profile/letting-details',
    profilePropertyBasics: '/sm/properties/profile/property-basics',
    [urlIds.propertyPublishingOptions]: `/sm/properties/${payload.propertyId}/offers/${payload.offerId}/publishing-options/update`,
    profileUpdatePropertyBasics: `/sm/properties/profile/property-basics/update/${payload.propertyId}`,
    profileOfferDetails: `/sm/properties/profile/offer-details/${payload.propertyId}`,
    profileUpdateOfferDetails: `/sm/properties/profile/offer-details/update/${payload.propertyId}/${payload.offerId}`,
    profilePhotos:
      payload && payload.teamSlug
        ? `/teams/${payload.teamSlug}/sm/properties/profile/photos/${payload.propertyId}`
        : `/sm/properties/profile/photos/${payload.propertyId}`,
    adFlowAmenities: FeatureFlag.Advertisement.isOn
      ? `/sm/advertise/amenities/${payload.propertyId}`
      : '',
    adFlowDescription: FeatureFlag.Advertisement.isOn
      ? `/sm/advertise/description/${payload.propertyId}`
      : '',
    adFlowPortals: FeatureFlag.Advertisement.isOn
      ? `/sm/advertise/portals/${payload.propertyId}/${payload.offerId}`
      : '',
    adFlowPayment: FeatureFlag.Advertisement.isOn
      ? `/sm/advertise/pay/${payload.propertyId}/${payload.offerId}`
      : '',
    profileShare: `/sm/properties/share/${payload.encodedParams}`,
    bondCoverApply: '/bc',
    bondCoverApplyProgress: `/bc?requestid=${payload.requestID}`,
    bondCoverRentalAddress: `/bc/rental?requestid=${payload.requestID}&id=${payload.ID}&type=null`,
    bondCoverRentalHistory: '/bc/rental-history',
    bondCoverTenants: `/bc/tenants?requestid=${payload.requestID}`,
    applyRedirect: `/apply/p/${payload.slug}`,
    propertyNotAvailable: `/sm/property/404/not-available`,
    propertyNotAvailableWithTeamsSlug: `/sm/${payload.teamSlug}/property/404/not-available`,
    landing: '/sm/landing',
    dashboard: '/home/dashboard',
    register: '/register',
    registerRR: `/register/${textHelpers.RENTAL_REFERENCE_URL_STR}`,
    savedProperties: '/sm/wishlist',
    recentlyViewed: '/sm/history',
    rentalHistorySummary: '/bc/rental-history',
    addRentalHistory: '/bc/rental-history/add',
    addRentalHistoryRequestReasonForMoving:
      '/bc/rental-history/add?requestReasonForMoving=false',
    addRentalHistoryBallot: '/bc/rental-history/add?ballot=true',
    updateRentalReference: `/bc/rental-history/edit/${payload.referenceId}`,
    ProofOfIncome: '/sm/income',
    RentalReputation: '/bc/profile',
    BackgroundCheck: '/sm/background-check/overview',
    BackgroundCheckDetails: `/sm/background-check/${payload.idDocGuid}/details`,
    AccountInformation: '/bc/profile/account',
    EmploymentDetailsList: '/bc/profile/employment-list',
    profileAvatar: '/bc/profile/avatar',
    [urlIds.profileAboutUs]: '/bc/profile/about-us',
    ProfileBuildSuccess: '/sm/profilebuild',
    EmploymentDetails: '/bc/profile/employment',
    EmploymentUpdate: `/bc/profile/employment/${payload.guidID}`,
    EmploymentCreate: '/bc/profile/employment/edit',
    NotFound: '/404', // This can be whatever but it is more semantical like this
    BCProfile: '/bc/profile',
    UpdateProfile: '/bc/profile/update',
    ChangePassword: '/bc/profile/change-password',
    smViewings: '/sm/viewings',
    applicationAcceptedConfirmation: `/sm/offers/${payload.offerId}/applications/${payload.applicationId}/accepted`,
    bondCoverLeaseeSign: `/bc/leaseesign?requestid=${payload.requestID}`,
    registerAgency: '/agency/register',
    createAgency: '/agency/create',
    homeoverview: '/home/overview',
    portfolioOverview:
      payload && payload.teamSlug
        ? `/teams/${payload.teamSlug}/portfolio/overview`
        : '/portfolio/overview',
    PetDetails: '/sm/pets-details',
    PetDetailsAdd: '/sm/pets-details/add',
    PetDetailsEdit: `/sm/pets-details/edit/${payload.guidID}`,
    RentalReputationDocuments: `/sm/rental-reputation/documents`,
    RentalReputationCreditCards: `/bc/profile/credit-cards`,
    identityDocument: `/sm/identity-documents`,
    identityDocumentAdd: `/sm/background-check/details/add`,
    supportingIdentityDocumentAdd: `/sm/identity-documents/other`,
    backgroundCheckPayment: `/sm/background-check/pay`,
    EmploymentVerifyConfirmation: `/employment/${payload.guidID}/thanks-for-verify`,
    EmploymentSlugVerifyConfirmation: `/employment/slug/${payload.slug}/thanks-for-verify`,
    editAgency: `/admin/agency/edit/${payload.agencyGUID}`,
    personalReferenceDetails: '/sm/personal-reference-details',
    [urlIds.manualPersonalReferenceSurvey]: `/sm/personal-reference/${payload.referenceGUID}/survey/manual`,
    [urlIds.personalReferenceSurveySubmitted]:
      '/sm/personal-reference/completed',
    [urlIds.ownerDisclosuresSubmitted]: '/sm/owner-disclosure/completed',
    personalReferenceAdd: '/sm/personal-reference/add',
    personalReferenceEdit: `/sm/personal-reference/edit/${payload.guidID}`,
    rentalReferenceSignupAfterSubmission: `/sm/rental-history/completed`,
    rentalReferenceSignupAfterSubmissionOwner: `/sm/rental-history/completed/owner`,
    rentalReferenceCreateLiteAccount: `/sm/rental-history/completed/create-lite-account?name=${payload.firstName}&email=${payload.email}&agencyName=${payload.agencyName}`,
    emergencyContactDetails: '/sm/emergency-contact-details',
    emergencyContactAdd: '/sm/emergency-contact/add',
    emergencyContactEdit: `/sm/emergency-contact/edit/${payload.guidID}`,
    supplierSource: '/agency/supplier-source',
    partialApplication: `/sm/property/${payload.propertyId}/applications/${payload.applicationId}`,
    studentDetails: `/sm/student-details`,
    studentDetailsAdd: `/sm/student-details/add`,
    studentDetailsEdit: `/sm/student-details/edit/${payload.studentId}`,

    prospectSummary:
      payload && payload.teamSlug && `/teams/${payload.teamSlug}`,
    teamApplicantApplicationsReport: `/teams/${payload.teamSlug}?applicantEmail=${payload.applicantEmail}`,
    teamsApplication: `/teams/${payload.teamSlug}?stage=Application`,
    teamApplicationsByStage: `/teams/${payload.teamSlug}?stage=applicationbystage`,
    teamsViewing: `/teams/${payload.teamSlug}?stage=Viewing`,
    teamViewingsByDate: `/teams/${payload.teamSlug}/viewings`,
    teamOverview: `/teams/${payload.teamSlug}/overview`,
    teamViewingPreference: `/teams/${payload.teamSlug}/settings`,
    teamCalendarSync: `/teams/${payload.teamSlug}/settings/calendarsync`,
    [urlIds.teams.settings
      .following]: `/teams/${payload.teamSlug}/settings/following`,
    [urlIds.teams.settings
      .members]: `/teams/${payload.teamSlug}/settings/members`,
    teamOldMessages: `/teams/${payload.teamSlug}/oldmessages`,
    teamMessages: `/teams/${payload.teamSlug}/messages`,
    teamActivity: `/teams/${payload.teamSlug}/activity`,
    [urlIds.keyLogger]: `/teams/${payload.teamSlug}/keys`,
    [urlIds.importKeys]: `/teams/${payload.teamSlug}/keys/import`,
    teamPublicPage: `/apply/${payload.teamSlug}`,

    remarketProperty: `/teams/${payload.agencyGUID}/property/${payload.propertyGUID}?email=${payload.primaryApplicantEmailAddress}`,
    loading: '/loading',
    shareApplication: `/teams/${payload.teamSlug}/${payload.applicationId}/share`,
    applyAnywhere: payload.applyAnywhereGUID
      ? `/applyanywhere/${payload.applyAnywhereGUID}`
      : `/apply`,
    applicationSummary: `/sm/applications/${payload.applicationId}/rentersummary`,
    progressApplication: `/sm/application/${payload.applicationId}/progressapplication`,
    backgroundCheckReport: payload.applicationGUID
      ? `/backgroundcheckreport?applicationId=${payload.applicationGUID}`
      : `/backgroundcheckreport`,
    contacts: `/teams/${payload.teamSlug}/contacts/add`,
    contactList: `/teams/${payload.teamSlug}/contacts`,
    editContact: `/teams/${payload.teamSlug}/contacts/edit/${payload.contactGUID}`,
    applicationApplicantBackgroundCheckReport: `/sm/applications/${payload.applicationGUID}/applicant/${payload.applicantGUID}/backgroundcheckreport`,
    addManualRentalReference: `/sm/rental-history/rating/${payload.rentalHistoryId}`,
    addManualEmploymentVerification: `/sm/employment-history/rating/${payload.employmentConfirmationId}`,
    unRegister: `/unregister-viewing`,
    registerViewingConfirmation: '/register-viewing',
    ownerReport: `/sm/property/${payload.propertyId}/ownerreport`,
    publicOwnerReport: `/sm/propertyreport/${payload.propertyreportId}/owner`,
    managerOnlyPropertyReport: `/sm/propertyreport/${payload.propertyreportId}`,
    viewingRunPreview: `/teams/${payload.teamSlug}/viewings/preview`,
    newViewingRun: `/teams/${payload.teamSlug}/viewings/newrun`,
    viewingRuns: `/teams/${payload.teamSlug}/viewings/runs`,
    [urlIds.viewingRuns
      .edit]: `/teams/${payload.teamSlug}/viewings/viewing-run/${payload.id}/edit`,
    [urlIds.viewingRuns
      .editPreview]: `/teams/${payload.teamSlug}/viewings/viewing-run/${payload.id}/preview/edit?changesToEditMade=${payload.changesToEditMade}`,
    shareProperty: `/teams/${payload.agencyId}/property/${payload.propertyId}?email=${payload.email}`,
    viewingRunReadOnly: `/teams/${payload.teamSlug}/viewings/runs/${payload.viewingRunGUID}`,
    leasingOffer: `/sm/applications/${payload.applicationId}/offer/${payload.agencyId}/manager`,
    leasingOfferRenter: `/sm/applications/${payload.applicationId}/offer/${payload.agencyId}/renter`,
    propertyList: `/stocklist/${payload.teamSlug}`,
    onlistDetails: `/teams/${payload.teamSlug}/editonlistdetails`,
    teamViewingsMobile: `/teams/${payload.teamSlug}/viewings/mobile`,
    onboarding: `/teams/${payload.teamSlug}/academy`,
    plugin: `/teams/${payload.teamSlug}/plugin/${payload.pluginSlug}`,
    teamMobileViewings: `/teams/${payload.teamSlug}/viewings/mobile`,
    teamsViewingUpdated: `/teams/${payload.teamSlug}?stage=Viewing`,
    selfService: `/team/register`,
    newViewingsRunPreview: `/teams/${payload.teamSlug}/viewings/new-viewing-run/preview`,
    newViewingsRunCreate: `/teams/${payload.teamSlug}/viewings/new-viewing-run`,
    newViewingsRunAll: `/teams/${payload.teamSlug}/viewings/new-viewing-run/all`,
    newViewingsRunPreviewWithGuid: `/teams/${payload.teamSlug}/viewings/new-viewing-run/preview/${payload.viewingRunGUID}`,
    onlistDetailsForProperty: `/teams/${payload.teamSlug}/propertyoffer/${payload.offerGUID}/onlist?property=${payload.propertyGUID}&offerGUID=${payload.offerGUID}`,
    rentalReferencesForTeam: `/teams/${payload.teamSlug}/rental-references`,
    rentalReferencesForTeamFirstTime: payload.isFromRR
      ? `/teams/${payload.teamSlug}/rental-references-f?isFromRR=true`
      : `/teams/${payload.teamSlug}/rental-references-f`,
    rentalReferenceURL:
      payload && payload.createLiteAccount
        ? `/sm/rental-history/rating/${payload.ratingGUID}/secret/${payload.secret}?utm_source=RentalReviewRequest&utm_medium=email&utm_content=review&createLiteAccount=true`
        : `/sm/rental-history/rating/${payload.ratingGUID}/secret/${payload.secret}?submitTenancyLedger=${payload.submitTenancyLedger}&liteAccountEligibility=${payload.liteAccountEligibility}&utm_source=RentalReviewRequest&utm_medium=email&utm_content=review&createLiteAccount=false`,
    rentalReferenceURLRR: `/sm/rental-history/rating/${payload.ratingGUID}/secret/${payload.secret}/${textHelpers.RENTAL_REFERENCE_URL_STR}?utm_source=RentalReviewRequest&utm_medium=email&utm_content=review&createLiteAccount=true`,
    newMessagesPage: `/teams/${payload.teamSlug}/messages`,
    [urlIds.messagesImport]: `/teams/${payload.teamSlug}/messages/import`,
    shareApplicationDecline: `/sm/a/c/${payload.shortcode}/decline`,
    shareApplicationAccept: `/sm/a/c/${payload.shortcode}/accept`,
    stepLetsGetStarted: `/teams/${payload.teamSlug}/quickstart/lets-get-started?teamGUID=${payload.agencyGUID}`,
    stepTeamMembers: `/teams/${payload.teamSlug}/quickstart/add-team-members?teamGUID=${payload.agencyGUID}`,
    stepBranding: `/teams/${payload.teamSlug}/quickstart/branding?teamGUID=${payload.agencyGUID}`,
    stepFinished: `/teams/${payload.teamSlug}/quickstart/finished?teamGUID=${payload.agencyGUID}`,
    teamViewingPreferenceSetup: `/teams/${payload.teamSlug}/settings/setup`,
    teamViewingPreferencePortals: `/teams/${payload.teamSlug}/settings/portals`,
    teamViewingPreferenceNotifications: `/teams/${payload.teamSlug}/settings/notifications`,

    teamViewingPreferenceApplications: `/teams/${payload.teamSlug}/settings/applications`,
    addApplicationByManager: `/sm/property/${payload.propertyId}/apply?manageradded=true`,
    editApplicationByManager: `/sm/applications/${payload.applicationId}?manageradded=true`,
    howBGCheckWorks: `/sm/background-check/how-it-works`,
    liteAccountRRPage: `/${RR_TO_LITE_URL_PART}/rating/${payload.ratingGUID}/secret/${payload.secret}?liteAccountEligibility=${payload?.liteAccountEligibility}&guest=${payload.guest}`,
    [urlIds.teams.activity
      .backgroundChecks]: `/teams/${payload.teamSlug}/activity/backgroundcheck`,
    [urlIds.teams.settings
      .screening]: `/teams/${payload.teamSlug}/settings/screening`,
    highlightedViewing: `/teams/${payload.teamSlug}?stage=Viewing&property=${payload.propertyGUID}&highlightedViewingId=${payload.viewingGUID}`,
  }

  return routes[what]
}

const referrerParams = (payload) => {
  if (payload) {
    let params = '?'
    if (payload.referrer_group)
      params += `referrer_group=${payload.referrer_group}&`
    if (payload.id) params += `referrer_id=${payload.referrer_id}&`
    if (payload.referrer_id) params += `referrer_id=${payload.referrer_id}&`
    if (payload.referrer_url) params += `referrer_url=${payload.referrer_url}`
    return params
  }
  return ''
}

export const addOnScrollLoadListeners = (loadMore = () => {}, element = {}) => {
  element &&
    element.addEventListener(
      isMobileOrTablet ? 'touchmove' : 'scroll',
      loadMore,
    )
}

export const removeOnScrollLoadListeners = (
  loadMore = () => {},
  element = {},
) => {
  element &&
    element.removeEventListener(
      isMobileOrTablet ? 'touchmove' : 'scroll',
      loadMore,
    )
}

export const isScrollAtEnd = (bottomRect = 0, docHeight = 0, winHeight = 0) => {
  if (bottomRect - 150 <= (winHeight || docHeight)) return true
  return false
}

export const capitalizeFirstLetter = (string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const deCapitalizeFirstLetter = (string = '') => {
  return string.charAt(0).toLowerCase() + string.slice(1).toLowerCase()
}

export const trimByWord = (sentence = '') => {
  let result = sentence
  let resultArray = result ? result.split(' ') : []
  const wordLimit = 48
  if (resultArray.length > wordLimit) {
    resultArray = resultArray.slice(0, wordLimit)
    result = resultArray.join(' ') + '...'
  }
  return result
}

export const getSentenceWords = (sentence = '') => {
  return sentence ? sentence.split(' ').length : 0
}

export const trimFileLengthTo30 = (fileName) => {
  return trimFileLength(30)(fileName)
}

export const trimFileLength = (limit) => {
  return (fileName) => {
    if (fileName.length > limit) {
      fileName = fileName.slice(0, limit)
      fileName = fileName.concat('...')
    }
    return fileName
  }
}

// sync with BE sm/application
export const ApplicationCategory = [
  'Draft',
  'Applied',
  'Shortlisted',
  'Offered',
  'Declined',
  'Withdrawn',
  'Accepted',
  'Leased',
  'Interested',
  'Applied', //TODO: fix this as BE has no such status
  'Applied',
  'Applied',
  'Applied',
  'Payment Pending',
  'Applied',
  'PM Withdrawn',
  'Declined (Flag)',
  'Approved',
  'Deposit Received',
  '',
  'Collecting References',
]

export const getCurrentApplicationStage = (status) => {
  if (
    ApplicationCategory[status] === 'Offered' ||
    ApplicationCategory[status] === 'Payment Pending'
  ) {
    return 'Offer'
  }
  return 'Unknown'
}

export const isCurrentApplicationStageOffer = (status) => {
  return getCurrentApplicationStage(status) === 'Offer'
}

export const ApplicationBtnClass = (status) => {
  switch (ApplicationCategory[status]) {
    case 'Applied':
      return 'btn btn-blue btn-light'
    case 'Declined':
      return 'btn btn-gray btn-light'
    case 'Withdrawn':
      return 'btn btn-red btn-light'
    case 'Draft':
      return 'btn btn-gray btn-light'
    case 'Interested':
      return 'btn btn-pink'
    default:
      return ''
  }
}

export const STATUS_DRAFT = 0
export const STATUS_SUBMITTED = 1
export const STATUS_OFFERED = 3
export const STATUS_DECLINED = 4
export const STATUS_WITHDRAWN = 5
export const STATUS_ACCEPTED = 6
export const STATUS_PAYMENT_PENDING = 13

export const myApplicationCardBtnClass = (status, hasApplied) => {
  switch (status) {
    case STATUS_DRAFT:
      return 'btn'
    case STATUS_DECLINED:
      return 'btn btn-pink'
    case STATUS_WITHDRAWN:
      return 'btn-empty'
    case STATUS_SUBMITTED: {
      if (!hasApplied) return 'btn'
      return 'btn btn-gray btn-light'
    }
    default:
      return 'btn btn-gray btn-light'
  }
}

export const petTypes = {
  1: 'Dog',
  2: 'Cat',
  3: 'Other',
}

export const PropertyStatuses = {
  leased: 2,
  takingApplications: 3,
  offMarket: 5,
}

export const PropertyStatus = [
  undefined,
  'Draft',
  'Leased',
  'TakingApplications',
  'Advertised',
  'Archived',
]

export const bcRenterStatus = {
  Draft: 'DRAFT',
  Pending: 'PENDING',
  Declined: 'DECLINED',
  Active: 'ACTIVE',
}

export const CategoryTypes = [
  undefined,
  'House',
  'Unit',
  'Townhouse',
  'Villa',
  'Apartment',
  'Flat',
  'Studio',
  'Warehouse',
  'Duplex Semi Detached',
  'Alpine',
  'Acreage Semi Rural',
  'Block Of Units',
  'Terrace',
  'Retirement',
  'Serviced Apartment',
  'Other',
]

export const RESEND_REFERENCE_CATEGORY = {
  RENTAL_REFERENCE: 'rentalReference',
  EMPLOYMENT_REFERENCE: 'employmentReference',
}

export const ApplicationEvents = new Map()
  .set(10, {
    token: 'RenterSubmittedApplication',
    label: 'Applied for Property',
  })
  .set(20, { token: 'RenterUpdatedApplication', label: 'Application Updated' })
  .set(30, {
    token: 'ManagerViewedApplication',
    label: 'Manager viewed the application',
  })
  .set(40, {
    token: 'ManagerShortlistedApplication',
    label: 'Application was shortlisted',
  })
  .set(50, {
    token: 'RenterWithdrewApplication',
    label: 'Application was withdrawn',
  })
  .set(60, {
    token: 'ManagerDeclinedApplication',
    label: 'Application was declined',
  })
  .set(70, { token: 'ManagerOfferedProperty', label: 'Property was offered' })
  .set(80, { token: 'RenterPaidDeposit', label: 'Deposit was paid' })
  .set(90, { token: 'RenterSignedLease', label: 'Signed lease' })
  .set(100, { token: 'ManagerSignedLease', label: 'Manager signed lease' })

export const monthsEnum = [
  { measure: ' Jan', days: 31 },
  { measure: ' Feb', days: 28 },
  { measure: ' Mar', days: 31 },
  { measure: ' Apr', days: 30 },
  { measure: ' May', days: 31 },
  { measure: ' Jun', days: 30 },
  { measure: ' Jul', days: 31 },
  { measure: ' Aug', days: 31 },
  { measure: ' Sep', days: 30 },
  { measure: ' Oct', days: 31 },
  { measure: ' Nov', days: 30 },
  { measure: ' Dec', days: 31 },
]

export const defaultGroupTypes = [
  {
    value: 'Single',
    picked: true,
  },
  {
    value: 'Couple',
    picked: false,
  },
  {
    value: 'Family',
    picked: false,
  },
  {
    value: 'Group',
    picked: false,
  },
]

export function constructChosenGroupType(groupType) {
  const newGroupTypes = defaultGroupTypes.map((groupChosen) => {
    if (groupChosen.value === groupType) {
      return {
        ...groupChosen,
        picked: true,
      }
    } else {
      return {
        ...groupChosen,
        picked: false,
      }
    }
  })
  return newGroupTypes
}

export const defaulAvailableLeases = [
  {
    length: 6,
    picked: false,
  },
  {
    length: 12,
    picked: false,
  },
  {
    length: 24,
    picked: false,
  },
  {
    length: 36,
    picked: false,
  },
]

export function constructAvailableLeaseLengthFromAdderEntity(term) {
  const lengthOptions = []
  if (typeof term.quantity === 'number') {
    lengthOptions.push(term.quantity)
  } else {
    const strs = term.quantity.split(',')
    // eslint-disable-next-line array-callback-return
    strs.map((str) => {
      lengthOptions.push(parseInt(str))
    })
  }
  const newAvailableLeases = defaulAvailableLeases.map((option) => {
    if (lengthOptions.includes(option.length)) {
      return {
        ...option,
        picked: true,
      }
    } else {
      return {
        ...option,
        picked: false,
      }
    }
  })
  return newAvailableLeases
}

function splitTheDate(date) {
  const [year, month, day] = date ? date.split('-') : ''
  return [year, month, day]
}

export function constructAdderEntityFromDate(date) {
  const [year, month, day] = splitTheDate(date)
  const monthMeasure = (
    monthsEnum.find((m, i) => i + 1 === parseInt(month)) || {}
  ).measure

  return monthMeasure !== undefined
    ? {
        year: parseInt(year),
        measure: monthMeasure,
        quantity: parseInt(day),
      }
    : {
        year: parseInt(year),
        measure: '',
        quantity: 1,
      }
}

export function clusterApplications(applications) {
  return applications.reduce((clusters, a) => {
    if (clusters[a.Category] === undefined) {
      clusters[a.Category] = []
    }

    clusters[a.Category].push(a)
    return clusters
  }, {})
}

// This is a simple local storage abstraction
// Serialization/Deserialization is done inside it
// For now just a json  object is a valid value type

export const db = {
  save: (k, v) => SessionStorageUtils.setItem(k, JSON.stringify(v)),
  has: (k) => !!SessionStorageUtils.getItem(k),
  get: (k) => (db.has(k) ? JSON.parse(SessionStorageUtils.getItem(k)) : null),
}

export const dbKeys = {
  newProperty: 'NEW_PROPERTY',
  applyLinkUnauthenticated: 'APPLY_LINK_UNAUTHENTICATED',
  property_details: 'PROPERTY_DETAILS',
}

export const Portals = {
  serialize: (portals) => {
    let p

    try {
      p = JSON.stringify(portals.split(';'))
    } catch (err) {
      p = '[]'
    }

    return p
  },
  deserialize: (portals) => {
    let p
    try {
      p = JSON.parse(portals).join(';')
    } catch (err) {
      p = ''
    }
    return p
  },
}

export function parseManagerViewing(viewing = {}, property = {}) {
  const dt = moment(viewing.startDate, viewingBETimeFormat)
  // Friday
  const nameOfDay = dt.format('dddd')
  // 23 Aug 2018
  const listedDate = dt.format('DD MMM YYYY')
  // 09:12 am
  const startTime = dt.format('hh:mm A')
  const endTime = dt.add(viewing.duration, 'minutes').format('hh:mm A')

  const address = getAddress(property)
  const suburb = getSuburb(property)
  const mainImage = getMainImage(property)

  return Object.assign({}, viewing, {
    nameOfDay,
    listedDate,
    startTime,
    endTime,
    serverDate: viewing.startDate,
    address,
    suburb,
    mainImage,
  })
}

export function getMainImage(property = {}) {
  if (isEmptyObject(property)) {
    return NO_IMAGE_PLACEHOLDER_URL
  }

  const mainImage = property.images.find((image) => image.isMain === true)
  if (!isEmptyObject(mainImage)) {
    return mainImage.URL
  }

  return NO_IMAGE_PLACEHOLDER_URL
}

export function getAddress(property = {}) {
  let address = ''
  if (property && property.address) {
    if (property.address.unitNumber) {
      address = `${property.address.unitNumber}/${property.address.streetNumber} ${property.address.streetName}`
    } else {
      address = `${property.address.streetNumber} ${property.address.streetName}`
    }
  }

  return address
}

export function getSuburb(property = {}) {
  let suburb = ''
  if (property && property.address) {
    suburb = `${property.address.suburb} ${property.address.state} ${property.address.postcode}`
  }

  return suburb
}

export function parseViewing(viewing = {}) {
  const startDate = moment(viewing.startDate)
    .local()
    .format(viewingBETimeFormat)
  const dt = moment(startDate)
  // Friday
  const nameOfDay = dt.format('dddd')
  // 23 Aug 2018
  const listedDate = dt.format('DD MMM YYYY')
  // 09:12 am
  const startTime = dt.format('hh:mm A')
  const endTime = dt.add(viewing.duration, 'minutes').format('hh:mm A')

  return Object.assign({}, viewing, {
    nameOfDay,
    listedDate,
    startTime,
    endTime,
    serverDate: startDate,
    startDate,
  })
}

export function parseUtcViewing(viewing) {
  const { startDate } = viewing || {}
  const formattedDate = startDate
    ? moment(startDate).local().format(viewingBETimeFormat)
    : ''
  return Object.assign({}, viewing, { startDate: formattedDate })
}

export function parseSmViewing(
  viewing = {},
  property = {},
  snugFit = {},
  application = {},
  propertyOffer = {},
) {
  const dt = moment(viewing.startDate, viewingBETimeFormat)
  // Friday
  const nameOfDay = dt.format('dddd')
  // 23 Aug 2018
  const listedDate = dt.format('DD MMM YYYY')
  // 09:12 am
  const startTime = dt.format('hh:mm A')
  const endTime = dt.add(viewing.duration, 'minutes').format('hh:mm A')

  const address = getAddress(property)
  const suburb = getSuburb(property)
  const score = (snugFit && Math.round(snugFit.score)) || 0
  const weeklyRent =
    (application && application.weeklyRent) || propertyOffer.weeklyRent
  const mainImage = getMainImage(property)

  return Object.assign({}, viewing, {
    nameOfDay,
    listedDate,
    startTime,
    endTime,
    serverDate: viewing.startDate,
    address,
    suburb,
    score,
    weeklyRent,
    mainImage,
  })
}

export function filterViewings(viewings, isPastTime = false) {
  const currentDate = moment(new Date(), viewingBETimeFormat)
  return isPastTime
    ? viewings.filter((viewing) =>
        moment(viewing.serverDate, viewingBETimeFormat).isBefore(currentDate),
      )
    : viewings.filter((viewing) =>
        moment(viewing.serverDate, viewingBETimeFormat).isSameOrAfter(
          currentDate,
        ),
      )
}

export const viewingReqistrantType = ['', 'Manual', 'Pre-registered']

export function filterManagerViewings(viewings, isPastTime = false) {
  const currentDate = moment(new Date(), viewingBETimeFormat)
  return isPastTime
    ? viewings.filter((record) =>
        moment(record.viewing.serverDate, viewingBETimeFormat).isBefore(
          currentDate,
        ),
      )
    : viewings.filter((record) =>
        moment(record.viewing.serverDate, viewingBETimeFormat).isSameOrAfter(
          currentDate,
        ),
      )
}

export const isAustralianAddress = (address) =>
  address?.country?.toUpperCase() === 'AUSTRALIA' ||
  address?.country?.toUpperCase() === 'AUS'

export const getBusinessNumberLabel = (address, abnPrefix = '') =>
  isAustralianAddress(address)
    ? abnPrefix + 'ABN'
    : 'Business Registration Number'

function validateBaseAddress(address) {
  const result = { ok: true, errorCode: -1 }
  const {
    streetNumber = '',
    streetName = '',
    suburb = '',
    postcode = '',
    country = '',
  } = address || {}

  if (streetName.length <= 1 || streetName.trim() === '') {
    result.ok = false
    result.errorCode = ErrorCodes.StreetNameRequired
  }

  if (streetNumber.trim() === '') {
    result.ok = false
    result.errorCode = ErrorCodes.StreetNumberRequired
  }

  if (suburb.trim() === '') {
    result.ok = false
    result.errorCode = ErrorCodes.SuburbRequired
  }

  if (postcode.trim() === '') {
    result.ok = false
    result.errorCode = ErrorCodes.PostcodeRequired
  }

  if (country.trim() === '') {
    result.ok = false
    result.errorCode = ErrorCodes.CountryRequired
  }

  return result
}

function validateAustralianAddress(address = {}) {
  var result = validateBaseAddress(address)

  // TODO: quick fix as Google form submits different state representation than manual selector
  const shortAustralianStates = [
    'ACT',
    'NSW',
    'NT',
    'QLD',
    'SA',
    'TAS',
    'VIC',
    'WA',
  ]
  const longAustralianStates = [
    'Australian Capital Territory',
    'New South Wales',
    'Northern Territory',
    'Queensland',
    'South Australia',
    'Tasmania',
    'Victoria',
    'Western Australia',
  ]
  const australianStates = shortAustralianStates.concat(longAustralianStates)

  if (
    address &&
    address.postcode &&
    address.postcode !== '' &&
    address.postcode.length < 4
  ) {
    result.ok = false
    result.errorCode = ErrorCodes.AusPostcodeMinimumLength
  }
  if (!australianStates.includes(address.state)) {
    result.ok = false
    result.errorCode = ErrorCodes.StateRequired
  }
  return result
}

export const stateConverter = (state) => {
  switch (state) {
    case 'Australian Capital Territory':
      return 'ACT'
    case 'New South Wales':
      return 'NSW'
    case 'Northern Territory':
      return 'NT'
    case 'Queensland':
      return 'QLD'
    case 'South Australia':
      return 'SA'
    case 'Tasmania':
      return 'TAS'
    case 'Victoria':
      return 'VIC'
    case 'Western Australia':
      return 'WA'
    default:
      return state
  }
}

function validateInternationalAddress(address) {
  return validateBaseAddress(address)
}

export function validateAddress(address) {
  const { country = '' } = address || {}
  if (country === '') {
    return { ok: false, errorCode: ErrorCodes.CountryRequired }
  }

  if (isAustralianAddress(address)) {
    return validateAustralianAddress(address)
  } else {
    return validateInternationalAddress(address)
  }
}

export function validateAdvertisement(advertisement) {
  const result = { ok: true, errorCode: -1 }

  if (!advertisement.weeklyRent) {
    result.ok = false
    result.errorCode = ErrorCodes.OfferWeeklyRentIsRequired
  }

  if (advertisement.acceptedLeaseLength.length === 0) {
    result.ok = false
    result.errorCode = ErrorCodes.AcceptedLeaseLengthIsRequired
  }

  return result
}

export const prepareOfferRequest = (request) => {
  if (request.displayRent === undefined && request.weeklyRent !== undefined) {
    request.displayRent = `$${request.weeklyRent} p/w`
  }
  return request
}

export function validateProperty(property, pathname) {
  const result = { ok: true, errorCode: -1 }
  const [isBasics, isDescription] = [
    /basics|create/.test(pathname),
    /amenities/.test(pathname),
    /photos/.test(pathname),
    /description/.test(pathname),
  ]

  if (isBasics && (property.type < 1 || property.type > 16)) {
    result.ok = false
    result.errorCode = ErrorCodes.PropertyTypeIsRequired
  }

  if (isDescription && property.title.length === 0) {
    result.ok = false
    result.errorCode = ErrorCodes.PropertyTitleIsRequired
  }

  return result
}

export function validateQuestion(question) {
  const result = { ok: true, errorCode: -1 }
  if (question.text.length === 0) {
    result.ok = false
    result.errorCode = ErrorCodes.QuestionTextRequired
  }

  return result
}

export function validateEmploymentDate(startDate) {
  // Currently, the back end will return '01-01-0001' if the date is null. Therefore,
  // I used includes to catch this exception.
  return startDate && !startDate.includes('0001')
}

export function validateApplication(application) {
  const result = { ok: true, errorCode: -1 }

  if (application.rent <= 0) {
    result.ok = false
    result.errorCode = ErrorCodes.OfferRentIsRequired
  }

  if (application.term <= 0) {
    result.ok = false
    result.errorCode = ErrorCodes.OfferTermIsRequired
  }

  if (application.people <= 0 && application.adults <= 0) {
    result.ok = false
    result.errorCode = ErrorCodes.PeopleIsRequired
  }

  if (application.pets === '' || isNaN(application.pets)) {
    result.ok = false
    result.errorCode = ErrorCodes.PetsIsRequired
  }

  return result
}

// TODO:  Make it contingent of the current protocol (we won't use http in production)
export const NO_IMAGE_PLACEHOLDER_URL = noImagePlaceholderUrl

export const Profile = {
  mostImportant: [
    {
      id: 'yieldPreferenceWeighting',
      text: 'Maximising your rental yield',
      link: 'Occupancy',
    },
    {
      id: 'occupancyPreferenceWeighting',
      text: 'Keeping your property tenanted',
      link: 'Rent',
    },
    {
      id: 'conditionPreferenceWeighting',
      text: 'Ensuring the property is maintained',
      link: 'Condition',
    },
  ],
}

export function wait(ms) {
  return new Promise((r) => setTimeout(r, ms))
}

export function windowUrl() {
  return window.location.host
}

export function X(o1, o2) {
  return Object.assign({}, o1, o2)
}

export function unique(collection = [], field) {
  return collection.reduce(
    (filtered, current, i) =>
      filtered.slice(0, i).find((f) => f[field] === current[field])
        ? filtered
        : [...filtered, current],
    [],
  )
}

export const PropertyAdStatus = [
  { id: 1, label: 'Draft' },
  { id: 2, label: 'Published' },
  { id: 3, label: 'Withdrawn' },
  { id: 4, label: 'Expired' },
]

export function hasAdvertisedAd(property) {
  const activeOffer = (property.offers || []).find((o) => o.isActive) || {}
  const ad =
    activeOffer.ads && activeOffer.ads.length > 0 ? activeOffer.ads[0] : null
  return (
    ad !== null &&
    (PropertyAdStatus.find((s) => s.id === ad.status) || {}).label ===
      'Published'
  )
}

export const starType = {
  full: 'fa fa-star star-colored',
  halfEmpty: 'fa fa-star-half-empty star-colored',
  empty: 'fa fa-star-o',
}

export function classifyStar(nth, rate, rest) {
  if (nth <= rate) {
    return starType.full
  } else if (rest && nth === rate + 1) {
    return starType.halfEmpty
  }

  return starType.empty
}

export function isThereAHalfStar(score) {
  return score - Math.floor(score) === 0.5
}

export function getDurationInYearsMonths(days) {
  const total = {
    years: 0,
    months: 0,
    days: 0,
    flattened: {
      days: 0,
      months: 0,
      years: 0,
    },
  }
  total.years = Math.floor(days / 365)
  total.months = Math.floor((days - total.years * 365) / 30)
  total.days = days - total.years * 365 - total.months * 30

  total.flattened.days = days
  total.flattened.months = Math.floor(days / 30)
  total.flattened.years = Math.floor(days / 365)

  return total
}

export const DeclineReasons = [
  undefined,
  'DetailsAreWrong',
  'BadRenter',
  'DoNotTrustBondCover',
  'LowRating',
  'OtherReason',
]

export const DocumentTypes = {
  ReferenceDocument: 1,
  BankStatements: 2,
  Payslips: 3,
  ArbitraryAttachment: 4,
  IdentityDocument: 5,
  OtherIncomeDocuments: 6,
  GovernmentBenefitDocuments: 7,
  PMSupportAttachment: 8,
  NoteAttachment: 9,
  propertyDisclosure: 10, // document type is not send to BE, just used in DocumentList component that should be refactored!
  studentID: 11,
  studentOfferLetter: 12,
  rentalLedger: 13,
}

export const InverseDocumentTypes = new Map()
  .set(1, 'ReferenceDocument')
  .set(2, 'BankStatements')
  .set(3, 'Payslips')
  .set(6, 'OtherIncomeDocuments')
  .set(7, 'GovernmentBenefitDocuments')

export const CompletenessSorted = [
  'profileAvatar',
  'rentalHistory',
  'identityDocument',
  'backgroundCheck',
  'income',
  'employmentDetails',
  'pets',
  'creditCard',
  'documents',
]

export const RentalReputationCategories = {
  Important: 1,
  Supporting: 2,
  Other: 3,
}

export const RentalReputationItems = [
  {
    key: 'rentalHistory',
    name: 'Address History',
    category: RentalReputationCategories.Important,
    icon: 'icon-home-overview',
    url: urlTo('bondCoverRentalHistory'),
    stage: 'essentials',
  },
  {
    key: 'employmentDetails',
    name: 'Employment',
    category: RentalReputationCategories.Important,
    icon: 'icon-id',
    url: urlTo('EmploymentDetailsList'),
    stage: 'essentials',
  },
  {
    key: 'income',
    name: 'Income',
    category: RentalReputationCategories.Important,
    icon: 'icon-money',
    url: urlTo('ProofOfIncome'),
    stage: 'essentials',
  },
  {
    key: 'identityDocument',
    name: 'Identity Documents',
    category: RentalReputationCategories.Important,
    icon: 'icon-user',
    url: urlTo('identityDocument'),
    stage: 'essentials',
  },
  {
    key: 'aboutUs',
    name: 'About Us',
    category: RentalReputationCategories.Supporting,
    icon: 'icon-info-outline',
    url: urlTo(urlIds.profileAboutUs),
    stage: 'supporting',
  },
  {
    key: 'profileAvatar',
    name: 'Profile picture',
    category: RentalReputationCategories.Supporting,
    icon: 'icon-emojis',
    url: urlTo('profileAvatar'),
    stage: 'supporting',
  },
  {
    key: 'pets',
    name: 'Pet Details',
    category: RentalReputationCategories.Supporting,
    icon: 'icon-pets',
    url: urlTo('PetDetails'),
    stage: 'supporting',
  },
  {
    key: 'documents',
    name: 'Other Documents',
    category: RentalReputationCategories.Supporting,
    icon: 'icon-attach',
    url: urlTo('RentalReputationDocuments'),
    stage: 'supporting',
  },
  {
    key: 'personalReference',
    name: 'Personal Reference',
    category: RentalReputationCategories.Supporting,
    icon: 'icon-applications',
    url: urlTo('personalReferenceDetails'),
    stage: 'supporting',
  },
  {
    key: 'studentDetails',
    name: 'Student Details',
    category: RentalReputationCategories.Supporting,
    icon: 'icon-id',
    url: urlTo('studentDetails'),
    stage: 'supporting',
  },
  {
    key: 'backgroundCheck',
    name: 'Background Check (Recommended)',
    category: RentalReputationCategories.Important,
    icon: 'icon-bondcover',
    url: urlTo('BackgroundCheck'),
    stage: 'essentials',
  },
  {
    key: 'emergencyContact',
    name: 'Next of Kin / Emergency Contacts',
    category: RentalReputationCategories.Other,
    icon: 'icon-phone',
    url: urlTo('emergencyContactDetails'),
    stage: 'final',
  },
]

export const IdentityStatus = {
  IDAdded: 0,
  IDPaid: 1,
  IDCheckFailed: 2,
  IDCheckSucceeded: 3,
}

export const guidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export const times = (n) => Array.from(new Array(n), (_, i) => i)

export function encodeShareParams(offerId, propertySlug) {
  const encodedUrlParam = btoa(
    JSON.stringify({
      offerId,
      propertySlug,
    }),
  )

  return urlTo('profileShare', { encodedParams: encodedUrlParam })
}

export const getFileExtensions = (f) =>
  (f || {}).name && f.name.substring(f.name.lastIndexOf('.') + 1, f.name.length)

export function setFileIcon(extension) {
  extension = extension.toLowerCase()
  if (extension === 'pdf') {
    return 'pdf'
  } else if (
    extension === 'png' ||
    extension === 'jpg' ||
    extension === 'jpeg' ||
    extension === 'gif' ||
    extension === 'tif'
  ) {
    return 'img'
  }
  return 'doc'
}

export const isFileImage = (file) => {
  const fileExtension = getFileExtensions(file)
  if (
    fileExtension === 'png' ||
    fileExtension === 'jpg' ||
    fileExtension === 'jpeg'
  ) {
    return true
  }
  return false
}

export const isApplicationOffered = (application) =>
  application && ApplicationCategory[application.status] === 'Offered'
export const isApplicationDeclined = (application) =>
  application && ApplicationCategory[application.status] === 'Declined'
export const isApplicationAccepted = (application) =>
  application && ApplicationCategory[application.status] === 'Accepted'
export const isApplicationWithdrawn = (application) =>
  application && ApplicationCategory[application.status] === 'Withdrawn'
export const isApplicationPMWithdrawn = (application) =>
  application && ApplicationCategory[application.status] === 'PM Withdrawn'
export const isDraftApplication = (application) =>
  application && ApplicationCategory[application.status] === 'Draft'
export const isAppliedApplication = (application) =>
  application && ApplicationCategory[application.status] === 'Applied'
export const isApplicationAppliedNotWithdrawn = (
  application,
  applicationStatus,
  applicant,
) =>
  !isStatusWithdrawn(applicationStatus) &&
  isApplicantApplied(applicant) &&
  !isStatusOffered(applicationStatus) &&
  !isStatusAccepted(applicationStatus) &&
  !isStatusDeclined(applicationStatus) &&
  !isStatusPaymentPending(applicationStatus) &&
  !isAutoWithdrawn(application)
export const isApplicationExpired = (application) =>
  application && moment(application.expiredAt).isBefore(moment())

export const isApplicationEditable = (application) =>
  !isApplicationAccepted(application) &&
  !isApplicationDeclined(application) &&
  !isApplicationWithdrawn(application) &&
  !isApplicationOffered(application)

export const isJointApplication = (application) =>
  application && application.isJoint

export const isStatusNew = (applicationStatus) =>
  applicationStatus === undefined
export const isStatusDraft = (applicationStatus) =>
  applicationStatus === 'Draft'
export const isStatusApplied = (applicationStatus) =>
  applicationStatus === 'Applied'
export const isStatusQuickApplied = (applicationStatus) =>
  applicationStatus === 'Interested'
export const isStatusAccepted = (applicationStatus) =>
  applicationStatus === 'Accepted'
export const isStatusDeclined = (applicationStatus) =>
  applicationStatus === 'Declined' || applicationStatus === 'Declined (Flag)'
export const isRecentlyDeclinedTime = (statusUpdateTime) => {
  const statusUpdateDuration = getDiffInDays(statusUpdateTime)
  return statusUpdateDuration < RECENTLY_DECLINED_THRESHOLD_IN_DAYS
}
export const isStatusOffered = (applicationStatus) =>
  applicationStatus === 'Offered'
export const isStatusPaymentPending = (applicationStatus) =>
  applicationStatus === 'Payment Pending'
export const isStatusWithdrawn = (applicationStatus) =>
  applicationStatus === 'Withdrawn'
export const isStatusPMWithdrawn = (applicationStatus) =>
  applicationStatus === 'PM Withdrawn'
export const isStatusShortlisted = (applicationStatus) =>
  applicationStatus === 'Shortlisted'
export const isStatusLeased = (applicationStatus) =>
  applicationStatus === 'Leased'
export const isStatusApproved = (applicationStatus) =>
  applicationStatus === 'Approved'
export const isStatusDepositReceived = (applicationStatus) =>
  applicationStatus === 'Deposit Received'

export const isStatusDeletable = (applicationStatus) =>
  isStatusDraft(applicationStatus)
export const isStatusWithdrawableByRenter = (applicationStatus) =>
  isStatusOffered(applicationStatus) ||
  isStatusApplied(applicationStatus) ||
  isStatusQuickApplied(applicationStatus) ||
  isStatusShortlisted(applicationStatus)
export const isStatusOfferable = (applicationStatus) =>
  isStatusApplied(applicationStatus) || isStatusShortlisted(applicationStatus)
export const isStatusDeclinableByManager = (applicationStatus) =>
  isStatusQuickApplied(applicationStatus) ||
  isStatusApplied(applicationStatus) ||
  isStatusShortlisted(applicationStatus)

export const isApplicationUpdateable = (applicationStatus) =>
  !isStatusNew(applicationStatus) &&
  !isStatusWithdrawn(applicationStatus) &&
  !isStatusAccepted(applicationStatus) &&
  !isStatusOffered(applicationStatus) &&
  !isStatusDeclined(applicationStatus) &&
  !isStatusLeased(applicationStatus)

export const isApplicationSubmitable = (applicationStatus) =>
  isStatusNew(applicationStatus) || isStatusDraft(applicationStatus)

export const isNewApplyAnywhere = (applicationStatus, isApplyAnywhere) =>
  isStatusNew(applicationStatus) && isApplyAnywhere
export const isPrimaryApplicant = (applicant) =>
  applicant && applicant.isPrimary
export const isSecondaryApplicant = (applicant) =>
  applicant && applicant.isPrimary === false
export const isApplicantApplied = (applicant) => applicant && applicant.applied

export const isEmploymentStatusPending = (verifyStatus) =>
  verifyStatus && EmploymentVerifyStatus[verifyStatus] === 'Pending'
export const isEmploymentStatusVerified = (verifyStatus) =>
  verifyStatus && EmploymentVerifyStatus[verifyStatus] === 'Verified'
export const isEmploymentStatusUnverified = (verifyStatus) =>
  verifyStatus && EmploymentVerifyStatus[verifyStatus] === 'Unverified'

export const isEmploymentDeclineReasonJobTitle = (reason) =>
  reason && EmploymentDeclineReasons[reason] === 'Job title'
export const isEmploymentDeclineReasonCompanyName = (reason) =>
  reason && EmploymentDeclineReasons[reason] === 'Company name'
export const isEmploymentDeclineEmploymentDate = (reason) =>
  reason && EmploymentDeclineReasons[reason] === 'Employment dates'
export const isEmploymentDeclineReasonSalary = (reason) =>
  reason && EmploymentDeclineReasons[reason] === 'Salary'
export const isEmploymentDeclineContactPerson = (reason) =>
  reason && EmploymentDeclineReasons[reason] === 'Contact person'
export const isEmploymentDeclineOther = (reason) =>
  reason && EmploymentDeclineReasons[reason] === 'Other reasons'

export const isApplicationApplyAnywhere = (applicationType) =>
  applicationType === applicationTypeCollection.applyAnywhere

export const getValidApplication = (allApplications = []) => {
  return allApplications.filter((application) => {
    return (
      !isApplicationDeclined(application) &&
      !isApplicationWithdrawn(application) &&
      application.isPrimary === true
    )
  })
}

export const isQuestionTypeInt = (questionType) =>
  questionType === QuestionType.Int
export const isQuestionTypeBoolean = (questionType) =>
  questionType === QuestionType.Boolean
export const isQuestionTypeText = (questionType) =>
  questionType === QuestionType.Text

export const isRatingForRenterWhitelisted = (code) => {
  const whitelist = [
    ratingType.payOnTime,
    ratingType.maintenance,
    ratingType.complaints,
    ratingType.condition,
    ratingType.termination,
    ratingType.unpaidAmount,
    ratingType.petsOnProperty,
    ratingType.breachNotices,
  ]
  return whitelist.find((ratingCode) => ratingCode === code)
}

export const getBooleanRating = (ratings, question) =>
  ratings.find(
    (rating) =>
      isQuestionTypeBoolean(rating.questionType) &&
      question.Code === rating.code,
  )
export const parseBooleanRating = (ratings, question) =>
  getBooleanRating(ratings, question) &&
  getBooleanRating(ratings, question).answerValue === 'true'
    ? 'Yes'
    : 'No'
export const exceptionBooleanRating = (ratings, question) =>
  ratings.find(
    (rating) =>
      isQuestionTypeBoolean(rating.questionType) &&
      question.Code === rating.code &&
      rating.exception === true,
  )

export const parseAnswer = (rating) => {
  if (isQuestionTypeInt(rating.QuestionType)) {
    return parseInt(rating.answerValue)
  } else {
    return rating.answerValue
  }
}

export const isReferenceRequestNotClicked = (requestStatus) =>
  requestStatus === 'Not clicked'
export const isReferenceRequestPending = (requestStatus) =>
  requestStatus === 'Pending'
export const isReferenceRequestSent = (requestStatus) =>
  requestStatus === 'Successful'
export const isReferenceRequestFailed = (requestStatus) =>
  requestStatus === 'Failed'

export const rentalReferenceVerifyStatus = (
  rated,
  ratings,
  reportedRent,
  reportedTerm,
  completed,
  declined,
  declineReason,
) => {
  if (declined) {
    return declineReason === declineReasonsIDs.PREFER_VERBAL_REFERENCE
      ? 'Please call'
      : 'Declined'
  } else if (!rated) {
    return 'Pending'
  } else if (
    !!rated &&
    (!completed || isRentalReferenceWithExceptionRatings(ratings))
  ) {
    return 'Info'
  } else {
    return 'Verified'
  }
}

export const isRentalReferenceWithExceptionRatings = (ratings) => {
  if (ratings.filter((rating) => rating.exception === true).length > 1) {
    return true
  }
  return false
}

export const isRentalReferenceVerified = (status) => status === 'Verified'
export const isRentalReferenceInfoState = (status) => status === 'Info'

export const getRentalReferenceRentalAmount = (ratings) => {
  return (
    ratings.find((rating) => rating.code === 'rental-amount') &&
    Number.parseInt(
      ratings.find((rating) => rating.code === 'rental-amount').answerValue,
    )
  )
}
export const getRentalReferenceTenanacyTerm = (ratings) => {
  return (
    ratings.find((rating) => rating.code === 'tenancy-term') &&
    Number.parseInt(
      ratings.find((rating) => rating.code === 'tenancy-term').answerValue,
    )
  )
}

export const isRentToIncomeNA = (ratio) => ratio === 'N/A'

export const isAgent = (managerType) => managerType === 1
export const isOwner = (managerType) => managerType === 2

export const SubmissionStatus = {
  NotSubmitted: 0,
  PartiallySubmitted: 1,
  CompletelySubmitted: 2,
}

export const durationCriteria = {
  0: 'defaultPeriod',
  1: 'lastSevenDays',
  2: 'lastWeek',
  3: 'lastMonth',
  4: 'priorMonth',
  5: 'calendarYear',
  6: 'lastCalendarYear',

  7: 'priorWeekend',
}

export const durationCategories = [
  { text: 'Any', value: 0 },
  { text: 'Last 7 Days', value: 1 },
  { text: 'Last Weekend', value: 7 },
  { text: 'Last week', value: 2 },
  { text: 'Last month', value: 3 },
  { text: 'Prior month', value: 4 },
  { text: 'This calendar year', value: 5 },
  { text: 'Last calendar year', value: 6 },
]

export const isApplicationActivityType = (activityType) => activityType === 1
export const isEquiryActivityType = (activityType) => activityType === 2
export const isViewingActivityType = (activityType) =>
  activityType === 4 || activityType === 5

export const franchiseGroups = [
  'Ray White',
  'LJ Hooker',
  'Raine & Horne',
  'McGrath',
  'Professionals',
  'Richardson and Wrench',
  'Harcourts',
  'Hocking Stuart',
  'Stockdale & Leggo',
  'AusNet real estate network',
  'Jellis Craig',
  'Laing & Simmons',
  'Elders',
  'Century 21',
  'Shead',
  'PRD',
  'Starr',
  'Buxton',
  'Biggin & Scott',
  'Barry Plant',
  'Thompson',
  'Peet & Co',
  'Summit Realty',
  'Roberts ',
  'Realway',
  'Hodges',
  'Buxton',
  'First National',
]

export const HistorySubscriptions = {
  MANAGE_BACK_BUTTON: 'MANAGE_BACK_BUTTON',
}

const routeKeys = {
  PushStateControlProperty: 'push-state-control-property',
  PushStateControlOffer: 'push-state-control-offer',
}

const manageBackButtonSubscription = (location, action, routerExport) => {
  if (
    /\/sm\/properties\/.+\/photos/.test(location.pathname) &&
    action === 'PUSH'
  ) {
    const propertyId = location.pathname
      .split('/')
      .find((routePart) => guidRegex.test(routePart))
    LocalStorageUtils.setItem(routeKeys.PushStateControlProperty, propertyId)
  }

  if (/sm\/properties\/create/.test(location.pathname) && action === 'POP') {
    const propertyId = LocalStorageUtils.getItem(
      routeKeys.PushStateControlProperty,
    )
    if (propertyId) {
      routerExport.history.replace(
        urlTo('updatePropertyBasics', { id: propertyId }),
      )
      LocalStorageUtils.removeItem(routeKeys.PushStateControlProperty)
    }
  }

  if (
    /sm\/properties\/.+\/preferences/.test(location.pathname) &&
    action === 'PUSH'
  ) {
    const offerId = location.pathname
      .split('/')
      .find((routePart) => guidRegex.test(routePart))
    LocalStorageUtils.setItem(routeKeys.PushStateControlOffer, offerId)
  }

  if (
    /sm\/properties\/.+\/offers\/create/.test(location.pathname) &&
    action === 'POP'
  ) {
    const propertyId = location.pathname
      .split('/')
      .find((routePart) => guidRegex.test(routePart))
    const offerId = LocalStorageUtils.getItem(routeKeys.PushStateControlOffer)
    if (offerId) {
      routerExport.history.replace(
        urlTo('updateOfferBase', { propertyId, offerId }),
      )
      LocalStorageUtils.removeItem(routeKeys.PushStateControlOffer)
    }
  }
}

export const historySubscription = (location, action, routerExport) => ({
  subscribe: (subscriptionKey) => {
    switch (subscriptionKey) {
      case HistorySubscriptions.MANAGE_BACK_BUTTON:
        return manageBackButtonSubscription(location, action, routerExport)
      default:
        return null
    }
  },
})

export const dispatchAPIResponse = (
  dispatch,
  responseText,
  receiveResponseText,
) => {
  if (responseText) {
    responseText.then((t) => dispatch(receiveResponseText(t)))
  } else {
    dispatch(receiveResponseText('404'))
  }
}

export function formatDate(date) {
  return moment(date).format(dateDayShortStringMonthYear)
}

export const convertDateToUTCAndFormat = (date) => {
  return moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
}

export const convertDateToUTCTimestampFormat = (date) => {
  return moment(date, utcFormatTimeStamp).utc()
}

export const convertDateToDayAndDate = (date, isDayAbbreviation) => {
  const dt = moment(date, dateWithDash)
  let day = dt.format('dddd')
  let dayAbbr = dt.format('ddd')
  const dateWithoutYear = dt.format(dateWithNoYearFormat)
  if (moment(dt).isSame(moment(), 'day')) {
    day = 'Today'
    dayAbbr = 'Today'
  }
  if (isDayAbbreviation) {
    return { dayAbbr, dateWithoutYear }
  } else {
    return {
      day,
      dateWithoutYear,
    }
  }
}

export const convertDateTimeToTime = (
  dateTime,
  customTimeFormat = timeFormat,
) => {
  const dt = moment(dateTime, utcFormatTimeStamp)
  return dt.format(customTimeFormat)
}

export const convertUTCDateToViewingTime = (dateTime) => {
  return moment(dateTime, utcFormatTimeStamp).format(viewingBETimeFormat)
}

export function formatLastEventAt(lastEventAt) {
  const days = moment().diff(moment(lastEventAt), 'days')

  switch (days) {
    case 0:
      return `today`
    case 1:
      return `yesterday`
    default:
      return `${days} days ago`
  }
}

export function formatEventHappenedAt(lastEventAt) {
  return moment(lastEventAt).format('Do MMM YYYY')
}

export const EmploymentVerifyStatus = ['Pending', 'Verified', 'Unverified']

export const EmploymentDeclineReasons = [
  'Company name',
  'Job title',
  'Employment dates',
  'Salary',
  'Contact person',
  'Other reasons',
]

export const EmploymentTypes = {
  0: 'Not specified',
  1: 'Full time',
  2: 'Part time',
  3: 'Casual',
  4: 'Self Employed',
}

export const EmploymentTypeCollection = [
  'Select',
  'Full time',
  'Part time',
  'Casual',
  'Self Employed',
]

export const isSelfEmployed = (employmentType) =>
  EmploymentTypeCollection[employmentType] === 'Self Employed'

export const isSelfEmployedNoAccountant = (employmentType, hasAccountant) =>
  isSelfEmployed(employmentType) && !hasAccountant

export const QuestionType = {
  Int: 1,
  Boolean: 2,
  Text: 3,
  Date: 4,
}

export const AgencyBrandingType = {
  Banner: 1,
  Logo: 2,
}

export const sortHistory = (historyList, type) => {
  let current = []
  let previous = []
  if (type === 'rentalHistory') {
    current = historyList.filter((a) => a.isCurrentAddress)
    previous = historyList.filter((a) => !a.isCurrentAddress)
  } else if (type === 'employment') {
    current = historyList.filter((e) => e.stillEmployed === true)
    previous = historyList.filter((e) => e.stillEmployed === false)
  }
  current.sort((a, b) => {
    if (moment(a.endDate).isAfter(b.endDate)) {
      return -1
    } else if (moment(b.endDate).isAfter(a.endDate)) {
      return 1
    }
    return 0
  })
  previous.sort((a, b) => {
    if (moment(a.endDate).isAfter(b.endDate)) {
      return -1
    } else if (moment(b.endDate).isAfter(a.endDate)) {
      return 1
    }
    return 0
  })
  return { current, previous }
}

export const relationshipTypesIds = {
  Parent: 1,
  Sibling: 2,
  Grandparent: 3,
  Child: 4,
  ExtendedFamily: 5,
  Spouse: 6,
  Partner: 7,
  Friend: 8,
  Colleague: 9,
  Other: 10,
  FamilyFriend: 11,
  CommunityLeader: 12,
}

export const relationshipTypesTitles = {
  [relationshipTypesIds.Parent]: 'Parent',
  [relationshipTypesIds.Sibling]: 'Sibling',
  [relationshipTypesIds.Grandparent]: 'Grand parent',
  [relationshipTypesIds.Child]: 'Child',
  [relationshipTypesIds.ExtendedFamily]: 'Extended Family',
  [relationshipTypesIds.Spouse]: 'Spouse',
  [relationshipTypesIds.Partner]: 'Partner',
  [relationshipTypesIds.Friend]: 'Friend',
  [relationshipTypesIds.Colleague]: 'Colleague',
  [relationshipTypesIds.Other]: 'Other',
  [relationshipTypesIds.FamilyFriend]: 'Family Friend',
  [relationshipTypesIds.CommunityLeader]: 'Community Leader',
}

export const relationshipTypesEmergencyContact = [
  '',
  'Parent',
  'Sibling',
  'Grandparent',
  'Child',
  'Extended Family',
  'Spouse',
  'Partner',
  'Friend',
  'Colleague',
  'Other',
]

export function canInvite(date = false) {
  return date ? moment().isAfter(moment(date)) : false
}

export function firstAndLastNameInitials(user, cases) {
  switch (cases) {
    case 'both':
      return `${user.firstName?.charAt(0).toUpperCase()}${user.lastName
        ?.charAt(0)
        .toUpperCase()}`
    case 'firstName':
      return `${user.firstName?.charAt(0).toUpperCase()}.`
    case 'lastName':
      return `${user.lastName?.charAt(0).toUpperCase()}.`
    default:
      return ''
  }
}

export const isPropertyDraft = (status) => PropertyStatus[status] === 'Draft'
export const isPropertyLeased = (status) => PropertyStatus[status] === 'Leased'
export const isPropertyTakingApplications = (status) =>
  PropertyStatus[status] === 'TakingApplications'
export const isPropertyArchived = (status) =>
  PropertyStatus[status] === 'Archived'

export const formatLeaseTerm = (term) => {
  if (term) {
    return term.split(',').join(', ')
  }
}

export const getApplicationCount = (applications = []) => {
  return applications.filter(
    (application) =>
      ApplicationCategory[application.status] !== 'Withdrawn' &&
      ApplicationCategory[application.status] !== 'Declined',
  ).length
}

export const getApplicationStatus = (
  status,
  propertyId = '',
  acceptedApplicationId = '',
) => {
  switch (status) {
    case 'TakingApplications':
      return urlTo('renterApplications', { propertyId })
    case 'Advertised':
      return urlTo('renterApplications', { propertyId })
    case 'Leased':
      return urlTo('renterApplication', {
        propertyId: propertyId,
        applicationId: acceptedApplicationId,
      })
    case 'Draft':
      return urlTo('updatePropertyBasics', { id: propertyId })
    default:
      return null
  }
}

export const propertyStatusCode = {
  'Any (Non-archived)': '',
  Draft: 1,
  Leased: 2,
  'Taking Applications': 3,
  Advertised: 4,
  Archived: 5,
}

export const pageLimit = 8

export const TEMPORARY_BNB_ARRANGEMENT = 'Temporary / BnB'

export const propertyArrangementLabels = [
  '',
  'Rented',
  'Boarded',
  'Lived with Friends',
  'Lived with Family',
  'Owned',
  'Subletted',
  'Lived with Family/Friends',
  'Rented with an agent',
  'Rented with private owner',
  TEMPORARY_BNB_ARRANGEMENT,
]

export const isAddressHistoryOwned = (status) =>
  propertyArrangementLabels[status] === 'Owned'
export const isAddressHistoryWithFamilyOrFriends = (status) =>
  propertyArrangementLabels[status] === 'Lived with Family/Friends'
export const isSubletted = (status) =>
  propertyArrangementLabels[status] === 'Subletted'
export const isBoarded = (status) =>
  propertyArrangementLabels[status] === 'Boarded'
export const isRented = (status) =>
  ['Rented with an agent', 'Rented with private owner'].includes(
    propertyArrangementLabels[status],
  )
export const isAddressHistoryTemporaryBnBArrangement = (status) =>
  propertyArrangementLabels[status] === TEMPORARY_BNB_ARRANGEMENT

export const listPropertiesNotShowStatusLabel = [TEMPORARY_BNB_ARRANGEMENT]

export const propertyArrangementNotShowStatusPill =
  propertyArrangementLabels.reduce(
    (accumulator, currentValue, currentIndex) => {
      if (listPropertiesNotShowStatusLabel.includes(currentValue)) {
        accumulator.push(currentIndex)
      }
      return accumulator
    },
    [],
  )

export const requireOtherTenantsAndLedger = (status) =>
  !isAddressHistoryWithFamilyOrFriends(status) &&
  !isSubletted(status) &&
  !isBoarded(status)

export const getDisplayedRent = (rent, propertyArrangement) => {
  return isAddressHistoryOwned(propertyArrangement) ||
    (isAddressHistoryTemporaryBnBArrangement(propertyArrangement) &&
      rent === 0) ||
    (isAddressHistoryWithFamilyOrFriends(propertyArrangement) && rent === 0)
    ? 'N/A'
    : `$${rent}p/w`
}

export function hasActiveOfferGuid(property) {
  const activeOffer = (property.offers || []).find((o) => o.isActive) || {}
  return activeOffer.guidID
}

export function removeGuidFromArray(array = '', id) {
  return Array.from(array).filter((guid) => guid !== id)
}

export const getUnsubscribeType = (propertyID, teamID) => {
  if (!!propertyID) {
    return 'viewing'
  } else if (!!teamID) {
    return 'team'
  }
}

export const isUnsubscribeTypeViewing = (unsubscribeType) =>
  unsubscribeType === 'viewing'
export const isUnsubscribeTypeTeam = (unsubscribeType) =>
  unsubscribeType === 'team'

export const getTargetText = (propertyID, teamID) => {
  const unsubscribeType = getUnsubscribeType(propertyID, teamID)
  if (isUnsubscribeTypeViewing(unsubscribeType)) {
    return 'ViewingAlerts'
  } else if (isUnsubscribeTypeTeam(unsubscribeType)) {
    return 'SuggestedPropertyAlerts'
  } else {
    return 'UnknownAlerts'
  }
}

export const getQueryString = (field) => {
  const curQueries = parseURLQuery()
  return curQueries[field] || ''
}

export const parseURLQuery = (search = '') => {
  if (!search) {
    search = window.location.search
  }

  return qs.parse(search, { ignoreQueryPrefix: true })
}

export const stringifyQuery = (queryParams = {}) => {
  return qs.stringify(queryParams, { addQueryPrefix: true })
}

export const findChosenTeamBySlug = (teams, targetTeamSlug) =>
  teams.find((team) => team.slug === targetTeamSlug)

export const trackingSource = (utmSource, targetSource) => {
  if (utmSource && targetSource) {
    if (typeof utmSource === 'string') {
      return utmSource.toLowerCase() === targetSource.toLowerCase()
    } else if (Array.isArray(utmSource)) {
      return utmSource.find(
        (source) => source.toLowerCase() === targetSource.toLowerCase(),
      )
    }
  }
  return false
}

export const setStringLength = (string, length) => {
  return string.length > length
    ? string.substring(0, length - 1) + '...'
    : string
}

export const formatActivityDate = (updatedAt) =>
  moment(updatedAt).format('ddd DD MMM HH:mm YYYY')

export const durationTypeToText = ['months', 'years']

export const studentTypeToText = ['Full time', 'Part time']

export const isRelationshipOther = (relationship) => relationship === 10
export const isPersonalReferenceRelationshipOther = (relationship) =>
  relationship === 4

export const validateRelationship = (relationship, comment) => {
  const errorMessage = 'This field is required'
  const errors = {}
  if (isRelationshipOther(relationship) && comment === '') {
    errors.comment = errorMessage
  } else if (relationship === 0) {
    errors.relationship = errorMessage
  }
  return errors
}
export const validatePersonalReferenceRelationship = (
  relationship,
  comment,
) => {
  const errorMessage = 'This field is required'
  const errors = {}
  if (isPersonalReferenceRelationshipOther(relationship) && comment === '') {
    errors.comment = errorMessage
  } else if (relationship === 0) {
    errors.relationship = errorMessage
  }
  return errors
}

export const validateAgeForRentalApplication = (date) => {
  const validAgeDate = moment().subtract(16, 'years')
  if (moment(date).isAfter(validAgeDate)) {
    return 'You must be 16 or older'
  }
  return ''
}

export const calculateTotalWeeklyIncome = (employers) => {
  return employers.map((e) => e.weeklyNetIncome).reduce((a, b) => a + b, 0)
}

export const getWeeklyRentDisplay = (weeklyRent) => {
  return `Rent: ${weeklyRent ? '$' + weeklyRent + ' /week' : 'N/A'}`
}

export const isFromApplication = (url) => {
  return (
    url.includes('/sm/applications') ||
    url.includes('/apply') ||
    url.includes('sm/property')
  )
}

export const refereeTypeObject = {
  unknown: 'unknown',
  nominatedReferee: 'nominatedReferee',
  manualReferee: 'manualReferee',
}

export const PropertiesActivitiesLabel = {
  1: 'Vacant Managements',
  2: 'Tenanted Managements',
  3: 'Total',
}

export const getRoundedNumber = (number = 0) => {
  if (isNaN(number)) {
    return 'N/A'
  }
  return Math.round(number)
}

export const selectProperty = (propertyOverview) =>
  propertyOverview && propertyOverview.property
export const selectApplications = (propertyOverview) =>
  propertyOverview && propertyOverview.applications
export const selectAddressFriendlyName = (property) =>
  property && property.address && property.address.friendlyName
export const selectPropertyGUID = (property) => property && property.guidID
export const selectApplicationGUID = (application) =>
  application && application.guidID
export const selectApplicants = (application) =>
  application && application.applicants
export const selectWeeklyRent = (application) =>
  application && application.weeklyRent
export const selectRentToIncomeRatio = (application) =>
  application && application.rentToIncomeRatio
export const selectTerm = (application) => application && application.term
export const selectPeople = (application) =>
  application && (application.people || application.adults)
export const selectPets = (application) => application && application.pets
export const selectApplicationStatus = (application) =>
  application && ApplicationCategory[application.status]
export const selectApplicationActivity = (application) =>
  application && application.ApplicationActivity
export const selectSubmittedDate = (application) =>
  application && application.submittedDate
export const selectAgencyGUID = (property) => property && property.agencyID
export const selectPrimaryApplicant = (applicants) =>
  applicants && applicants.find((applicant) => applicant.isPrimary)

export const isReversedRatingQuestions = (ratingCode) =>
  ratingCode === ratingType.petsOnProperty ||
  ratingCode === ratingType.complaints ||
  ratingCode === ratingType.breachNotices

export const ratingType = {
  positiveComment: 'positive-comment',
  feedback: 'feedback',
  overall: 'overall',
  pleasant: 'pleasant',
  takesCare: 'takes-care',
  payOnTime: 'pay-on-time',
  maintenance: 'maintenance',
  petsOnProperty: 'pets-on-property',
  complaints: 'complaints',
  breachNotices: 'breach-notices',
  condition: 'condition',
  termination: 'termination',
  unpaidAmount: 'unpaid-amount',
  tenancyTerm: 'tenancy-term',
  recommendation: 'recommendation',
  bondDeduction: 'bond-deduction',
  rentalAmount: 'rental-amount',
}

export const ratingCodeCollection = {
  leaseDetails: [ratingType.rentalAmount, ratingType.tenancyTerm],
  overallQuestions: [
    ratingType.overall,
    ratingType.pleasant,
    ratingType.takesCare,
    ratingType.recommendation,
    ratingType.bondDeduction,
  ],
  comments: [ratingType.positiveComment, ratingType.feedback],
  tenancyQuestions: [
    ratingType.payOnTime,
    ratingType.maintenance,
    ratingType.petsOnProperty,
    ratingType.complaints,
    ratingType.breachNotices,
    ratingType.condition,
    ratingType.termination,
    ratingType.unpaidAmount,
  ],
}

export const getStarRating = (ratings, question) =>
  ratings.find(
    (rating) =>
      isQuestionTypeInt(rating.questionType) && question.Code === rating.code,
  )
export const exceptionStarRating = (ratings, question) =>
  getStarRating(ratings, question) &&
  getStarRating(ratings, question).exception === true

export const getTextRating = (ratings, question) => {
  const targetRating = ratings.find(
    (rating) =>
      isQuestionTypeText(rating.questionType) && rating.code === question.Code,
  )
  const defaultTextRatingAnswer = { answerValue: 'Not Answered' }
  return targetRating || defaultTextRatingAnswer
}

export const exceptionTextRating = (ratings, question) =>
  getTextRating(ratings, question) &&
  getTextRating(ratings, question).exception === true

export const smokingText = ['Not completed', 'Yes', 'No']

export const smokingType = {
  NotCompleted: 0,
  Smoker: 1,
  NonSmoker: 2,
}

export const getApplicantStatus = (applicant, application) => {
  let applicantStatus = ''
  if (
    isStatusApplied(ApplicationCategory[application.status]) ||
    isStatusOffered(ApplicationCategory[application.status])
  ) {
    applicantStatus = applicant.applied ? 'Applied' : 'Invited'
  } else if (isStatusQuickApplied(ApplicationCategory[application.status])) {
    applicantStatus = applicant.isPrimary ? 'Interested' : ''
  } else if (isStatusDraft(ApplicationCategory[application.status])) {
    applicantStatus = 'Draft'
  } else if (isStatusAccepted(ApplicationCategory[application.status])) {
    applicantStatus = 'Accepted'
  } else if (isStatusShortlisted(ApplicationCategory[application.status])) {
    applicantStatus = 'Shortlisted'
  }
  return applicantStatus
}

export const idDocumentTypeTextConverter = (documentType, defaultText = '') => {
  const supportingIDDocument = SupportingIDDocuments.find(
    (document) => document.docType === documentType,
  )
  if (supportingIDDocument) {
    return supportingIDDocument.name
  } else {
    return defaultText
  }
}

export const calculatePercentage = (totalPoints, recommendedPoints) => {
  let progress = 100

  if (totalPoints > recommendedPoints) {
    return `${progress}%`
  }

  progress = (totalPoints * 100) / recommendedPoints
  return `${progress}%`
}

// http://myunlessor.github.io/blog/2015/12/19/promise-solve-continuous-request-and-respone-not-in-order-problem/
export const promisify = (fn) => {
  return fn.then
    ? fn
    : function () {
        var args = Array.from(arguments)
        return new Promise((resolve) => void fn(...args.concat(resolve)))
      }
}

export const isNoErrorExist = (errors) => {
  const errorFieldArray = Object.keys(errors)
  if (errorFieldArray.length === 0) {
    return true
  }
  return !errorFieldArray.find((fieldName) => {
    return errors[fieldName]
  })
}

export const defaultRentScheduleOptions = [
  {
    index: 1,
    value: 'Weekly',
  },
  {
    index: 2,
    value: 'Fortnightly',
    picked: false,
  },
  {
    index: 3,
    value: 'Monthly',
    picked: false,
  },
]

export const defaultRequestCarSpaceOptions = [
  {
    index: 1,
    value: 'Yes',
    picked: false,
    model: true,
  },
  {
    index: 2,
    value: 'No',
    picked: false,
    model: false,
  },
]

export const findTeamByAgencyId = (teams, agencyId) =>
  teams.find((team) => team.guid === agencyId)

export const validateMinimumContactDetailProvided = (contactDetails = []) => {
  if (
    contactDetails.length ===
    contactDetails.filter((contactDetail) => contactDetail === '').length
  ) {
    return 'Please provide a contact number or email address.'
  }
  return ''
}

export const shouldCurrencyUpdate = (fields, targetField, value) => {
  const isFieldCurrency = fields.find((f) => f === targetField)
  if (isFieldCurrency && !Math.sign(value) && value !== '0' && value !== '') {
    return false
  }
  return true
}

export const findRatingResultByCode = (ratings = [], targetCode) => {
  return ratings.find((rating) => rating.code === targetCode) || {}
}

export const findPrimaryDocumentByType = (documents = [], docType) => {
  return documents.find((document) => document.docType === docType)
}

export const hasSupportingDocumentByType = (documents = [], docType) => {
  return documents.find((document) => document.documentType === docType)
}

export const findSupportingDocumentByType = (documents = [], docType) => {
  return documents.filter((document) => document.documentType === docType)
}

// datetime helpers
// -------------------
export const viewingStandardDisplayTimeFormat = 'ddd, DD MMM hh:mm A YYYY'
export const viewingNoYearTimeFormat = 'ddd, DD MMM hh:mm A'
export const pdfStandardDisplayTimeFormat = 'HH:mm A DD MMM YYYY'
export const standardDateWithoutTime = 'DD MMM, YYYY'
export const standardDateWithoutTimeAndComma = 'DD MMM YYYY'
export const dateWithWeekDayTimeFormat = 'ddd, DD MMM YYYY'
export const dateWithNoYearFormat = 'DD MMM'
export const enquiryViewingDateFormat = 'hh:mm A ddd'
export const timeFormat = 'hh:mm A'
export const dateTimeStampFormat = 'DD MMM YYYY hh:mm A'
export const utcFormatTimeStamp = 'YYYY-MM-DDTHH:mm:ssZ'
export const dateWithDash = 'YYYY-MM-DD'
export const dateWithDashWithoutDay = 'YYYY-MM'
export const dateTimeStampFormatWithAt = 'DD MMM YYYY [at] hh:mm A'
export const dateTimeWithDash = 'YYYY-MM-DD hh:mm A'
export const standardMilitaryTime = 'HH:mm DD MMM YYYY'
export const viewingBETimeFormat = 'YYYY-MM-DD HH:mm:ss'
export const standardUTCFormat = 'YYYY-MM-DDTHH:mm:ss[Z]'
export const dateDayShortStringMonthYear = 'DD MMM YYYY'
export const dateWithWeekDayTimeFormatNoComma = 'ddd DD MMM YYYY'
export const dateWithTimeAndWeekDayTimeFormatNoComma = 'hh:mm a ddd DD MMM'
export const lowerCaseTimeFormat = 'hh:mma'

const todayReference = moment()
const TODAY = todayReference.clone().startOf('day')
const YESTERDAY = todayReference.clone().subtract(1, 'days').startOf('day')

export const dateUtil = {
  isToday: (date) => moment(date).isSame(TODAY, 'day'),
  isYesterday: (date) => moment(date).isSame(YESTERDAY, 'day'),
  processDateText: (date, format = pdfStandardDisplayTimeFormat) => {
    if (dateUtil.isToday(date)) {
      return 'today'
    } else if (dateUtil.isYesterday(date)) {
      return 'yesterday'
    } else {
      return getStandardFormattedDate(date, format)
    }
  },
  getStandardUTCDateAtStart: (dateTime = '') => {
    return dateTime
      ? moment.utc(moment(dateTime).startOf('day')).format(standardUTCFormat)
      : moment.utc(moment().startOf('day')).format(standardUTCFormat)
  },
  getStandardUTCDateAtEnd: (dateTime = '') => {
    return dateTime
      ? moment.utc(moment(dateTime).endOf('day')).format(standardUTCFormat)
      : moment.utc(moment().endOf('day')).format(standardUTCFormat)
  },
  getDurationsWithType: (fromDate, toDate, isTodayToDate) => {
    const to = moment(toDate)
    const from = moment(fromDate)
    let durationsWithType = []
    const duration = moment.duration(to.diff(from))
    const seconds = Math.floor(duration.asSeconds())
    const minutes = Math.floor(duration.asMinutes())
    const hours = Math.floor(duration.asHours())
    const { years, months, days } = totalTimePassed(
      fromDate,
      toDate,
      isTodayToDate,
    )
    durationsWithType.push(
      {
        duration: seconds,
        durationType: timeUnitsText.seconds,
      },
      {
        duration: minutes,
        durationType: timeUnitsText.minutes,
      },
      {
        duration: hours,
        durationType: timeUnitsText.hours,
      },
      {
        duration: days,
        durationType: timeUnitsText.days,
      },
      {
        duration: months,
        durationType: timeUnitsText.months,
      },
      {
        duration: years,
        durationType: timeUnitsText.years,
      },
    )
    return durationsWithType
  },
  getLatestDuration: (fromDate, toDate, isTodayToDate) => {
    const durations = dateUtil.getDurationsWithType(
      fromDate,
      toDate,
      isTodayToDate,
    )
    var maxDuration = durations.reduce((prev, current) =>
      prev.duration < timeUnitHelper[prev.durationType] &&
      current.duration === 0
        ? prev
        : current,
    )
    return {
      duration: maxDuration.duration,
      durationType: modifyToPlural(
        maxDuration.duration,
        maxDuration.durationType,
      ),
    }
  },
}
export const invalidDate = 'Invalid date'
export const timeUnitHelper = {
  second: 60,
  minute: 60,
  hour: 24,
  day: 30,
  month: 12,
}
export const timeUnitsText = {
  seconds: 'second',
  minutes: 'minute',
  hours: 'hour',
  days: 'day',
  months: 'month',
  years: 'year',
}

export function totalTimePassed(from, to, isRollingLease) {
  return getDurationInYearsMonths(
    (isRollingLease || moment(to).isAfter(moment())
      ? moment()
      : moment(to)
    ).diff(moment(from), 'days'),
  )
}

export const isDateBeforeTargetDate = (newEndDate) => {
  return (currentTime) => {
    const today = moment()
    const targetDate =
      newEndDate && moment(newEndDate).isBefore(today)
        ? moment(newEndDate)
        : today
    return currentTime.isBefore(targetDate)
  }
}

export const isDateAfterTodayAndBeforeTargerDate = (newEndDate) => {
  return (currentTime) => {
    const today = moment()
    const targetDate =
      newEndDate && moment(newEndDate).isAfter(today)
        ? moment(newEndDate)
        : today
    return currentTime.isBefore(targetDate) && currentTime.isAfter(today)
  }
}

export const validateDateFormat = (newDate) => {
  const formattedDate = getStandardFormattedDate(newDate, dateWithDash)
  return formattedDate !== invalidDate
}

export const validateStartDate = (newEndDate) => {
  return (currentTime) => {
    if (newEndDate) {
      return currentTime.isBefore(moment(newEndDate))
    }
    return true
  }
}

export const validateEndDate = (newStartDate) => {
  return (currentTime) => {
    if (newStartDate) {
      return currentTime.isAfter(moment(newStartDate))
    }
    return true
  }
}

export const validateFutureDate = (startDate) => {
  return (currentTime) => {
    const today = moment()
    const targetDate = startDate ? startDate : today
    return currentTime.isAfter(targetDate)
  }
}
export const getStandardFormattedDate = (
  date = '',
  format = pdfStandardDisplayTimeFormat,
) => {
  return moment(date).format(format)
}

export const parseStringToNumber = (string) => {
  if (!isNaN(string) && string > 0) {
    return Number(string)
  }
  return
}

export const parseStringToRoundedDownNumber = (string) => {
  if (!isNaN(string) && string > 0) {
    const num = Number(string)
    return Math.floor(num)
  }
  return
}

export function constructDateFromAdderEntity(entity) {
  const { year, measure, quantity: day } = entity
  const monthNum = monthsEnum.findIndex((m) => m.measure === measure)
  let date = moment().year(year).date(day).month(monthNum)
  return date.format('YYYY-MM-DD').toString()
}

export function convertMoveInDate(moveInDate) {
  const { measure, quantity: day, year } = moveInDate || {}
  const monthNum = monthsEnum.findIndex((m) => m.measure === measure)
  let date = moment().year(year).date(day).month(monthNum)

  return date.format('YYYY-MM-DD').toString()
}

export function totalTimeInMonths(from, to, isRollingLease) {
  const { years, months } = totalTimePassed(from, to, isRollingLease)
  return years * 12 + months
}

export function calculateDuration(startDate, endDate, stillActive) {
  const isDateValid = validateEmploymentDate(startDate)
  startDate = moment(startDate)
  endDate = stillActive ? moment() : moment(endDate)
  const { years, months } = totalTimePassed(startDate, endDate, stillActive)
  const yearOrYears = `${years} ${years > 1 ? 'years' : 'year'}`
  const monthOrMonths = `${months} ${months > 1 ? 'months' : 'month'}`
  const formattedEndDate = stillActive ? 'Present' : formatDate(endDate)
  const itemDateContext = `${years ? yearOrYears + ' ' : ''}${
    months ? monthOrMonths : ''
  } (${formatDate(startDate)} - ${formattedEndDate})`
  return isDateValid && itemDateContext
}

export function getDurationInDays(startDate, endDate, stillActive) {
  const isDateValid = validateEmploymentDate(startDate)
  if (!isDateValid) return 0
  startDate = moment(startDate)
  endDate = stillActive ? moment() : moment(endDate)
  return endDate.diff(startDate, 'days')
}

export function formatDurationInYearsMonths(days) {
  const { years, months } = getDurationInYearsMonths(days)
  const yearOrYears = `${years} ${years > 1 ? 'years' : 'year'}`
  const monthOrMonths = `${months} ${months > 1 ? 'months' : 'month'}`
  const itemDateContext = `${years ? yearOrYears + ' ' : ''}${
    months ? monthOrMonths : ''
  }`
  return itemDateContext
}

export const aggregateTotalEmploymentDuration = (employers) => {
  if (employers) {
    const days = employers.reduce(
      (total, employer) =>
        (employer.stillEmployed || moment(employer.endDate).isAfter(moment())
          ? moment()
          : moment(employer.endDate)
        ).diff(moment(employer.startDate), 'days') + total,
      0,
    )
    const total = getDurationInYearsMonths(days)
    return total
  }
  return
}

export function aggregateTotalRentingDuration(rentalHistory) {
  if (rentalHistory) {
    const days = rentalHistory.reduce(
      (total, history) =>
        (history.isRollingLease || moment(history.to).isAfter(moment())
          ? moment()
          : moment(history.to)
        ).diff(moment(history.from), 'days') + total,
      0,
    )

    // TODO: make this more precise and pretty
    let total = getDurationInYearsMonths(days)
    return total
  }
  return
}

// end of datetime helpers
// -------------------

export const modifyToPlural = (number, unitText) => {
  if (number > 1) {
    return `${unitText}s`
  }
  return unitText
}

export const isOverTwoDecials = (value) => {
  if (value.toString().includes('.')) {
    const decimals = value.toString().split('.')[1]
    if (decimals.length > 2) {
      return true
    }
  }
  return false
}

export const filtersHaveChanged = (prevState, newState) => {
  if (!prevState || !newState) {
    return false
  }
  if (prevState.filters.searchText !== newState.filters.searchText) {
    return true
  }
  if (prevState.filters.manager !== newState.filters.manager) {
    return true
  }
  if (prevState.filters.workflow !== newState.filters.workflow) {
    return true
  }
  if (prevState.filters.todayOnly !== newState.filters.todayOnly) {
    return true
  }
  if (prevState.filters.datePickerValue !== newState.filters.datePickerValue) {
    return true
  }
  if (prevState.filters.archived !== newState.filters.archived) {
    return true
  }
  if (prevState.filters.unRegistered !== newState.filters.unRegistered) {
    return true
  }
  if (prevState.filters.noNotes !== newState.filters.noNotes) {
    return true
  }
  if (
    prevState.filters.viewingAvailable !== newState.filters.viewingAvailable
  ) {
    return true
  }
  if (prevState.filters.hasMobileNumber !== newState.filters.hasMobileNumber) {
    return true
  }
  if (prevState.filters.applicantEmail !== newState.filters.applicantEmail) {
    return true
  }
  if (
    prevState.filters.showDeclinedAndWithdrawnApplications !==
    newState.filters.showDeclinedAndWithdrawnApplications
  ) {
    return true
  }
  if (prevState.filters.sortBy !== newState.filters.sortBy) {
    return true
  }
  return false
}

// array helpers
//-------------------------
// will return an array without duplicated element. PS: only suitable for array composed by primitive variables
export const uniqueArrayGenerator = (array = []) => {
  return [...new Set(array)]
}

export const sortArrayAlphabeticallyByFirstName = (a, b) => {
  if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1
  if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1
  return 0
}

export const sortArrayByDate = (a, b) => {
  return new Date(b.updatedAt) - new Date(a.updatedAt)
}

export const splitArrayByIndex = (array = [], index) => {
  let firstArray = []
  let secondArray = []
  if (!array || array.length === 0) {
    return { firstArray, secondArray }
  }
  firstArray = array.slice(0, index)
  secondArray = array.slice(index)
  return { firstArray, secondArray }
}

export const getFirstItem = (array = []) => {
  if (array.length > 0) {
    return array[0]
  }
  return {}
}

// end of array helpers
//-------------------------

export const isApplyAnywhere = () => {
  return window.location.pathname.includes('/applyanywhere')
}

export const parseDataUtil = {
  convertObjValueFromStringToBoolean: (obj) => {
    let targetObj = {}
    Object.keys(obj).forEach((key) => {
      switch (obj[key]) {
        case 'true':
          targetObj[key] = true
          break
        case 'false':
          targetObj[key] = false
          break
        default:
          targetObj[key] = obj[key]
      }
    })
    return targetObj
  },
}

// number helpers
//-------------------------

export const numberWithCommas = (number) => {
  var parts = number.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const isOddNumber = (number) => {
  return !!number % 2
}

// end of number helpers
//-------------------------

// collections
//-------------------------

export const entryNoticeRegulationsOptions = [
  '<Empty>',
  'QLD Entry notice Form 9',
]

export const messageStatus = {
  archive: 'archive',
  unarchive: 'unarchive',
}

export const applicationTypeCollection = {
  unknown: 0,
  standard: 1,
  applyAnywhere: 2,
}

export const DownloadOptions = [
  {
    text: 'Application',
    value: 1,
    pdfAttribute: 'targetApplicantApplicationPDF',
  },
  {
    text: 'Attachments',
    value: 2,
    pdfAttribute: 'targetApplicantAttachmentsPDF',
  },
  {
    text: 'App & Attachments',
    value: 3,
    pdfAttribute: 'targetApplicantAllPDFs',
  },
]

export const PDFTypes = {
  Application: 0,
  Attachments: 1,
  AllPDFs: 2,
  BetaAllPDFs: 3,
}

// identity documents
//-----------------------

export const IDDocumentDocType = {
  DriverLicense: 1,
  Passport: 2,
  Primary: 3,
  SecondaryHigh: 4,
  SecondaryMedium: 5,
  SecondaryLow: 6,
}

export const SupportingIDDocuments = [
  {
    name: 'Category A (40 points)',
    docType: IDDocumentDocType.Primary,
    points: 40,
    infoText:
      "Add items such as a Birth Certificate or Citizenship Certificate. One primary document is required and counted. If you've already added a passport, you don't need to add another primary document.",
    exampleTitle: '40 points',
    examples: [
      'Birth certificate',
      'Citizenship certificate',
      'Proof of Age Card',
      'Medicare card',
      'Current phone, water, gas or electricity bill',
    ],
  },
  {
    name: 'Category B (30 points)',
    docType: IDDocumentDocType.SecondaryHigh,
    points: 30,
    infoText: `Add items such as signed documents from Authorised Deposit-Taking Institutions (ADIs),
    e.g. your bank, credit union etc.`,
    exampleTitle: '30 points',
    examples: ['Government- issued ID', 'Council rates notice'],
  },
  {
    name: 'Secondary (35 points)',
    docType: IDDocumentDocType.SecondaryMedium,
    points: 35,
    infoText: `Add items such as a Rates Notice, Loan Document etc.`,
  },
  {
    name: 'Category C (10 points)',
    docType: IDDocumentDocType.SecondaryLow,
    points: 10,
    infoText: `Add items such as a Student ID card, Lease/Rent agreement etc.`,
    exampleTitle: `10 points`,
    examples: [
      'Student ID card',
      'Foreign driver licence',
      'Document from current or previous employer (last 2 years)',
    ],
  },
  {
    name: 'Driver Licence and Passport (50 points)',
    docType: IDDocumentDocType.DriverLicense,
    points: 50,
    infoText: ``,
    exampleTitle: ``,
    examples: [],
  },
]

export const SupportingIDDocumentsOptions = [
  '',
  'Category A (40 points)',
  'Category B (30 points)',
  'Category C (10 points)',
]

export const identityPointsList = [10, 35, 30, 50]

export const IncomeAttachments = [
  {
    name: 'Bank Statements',
    docType: 'BankStatements',
  },
  {
    name: 'Payslips',
    docType: 'Payslips',
  },

  {
    name: 'Other Income Documents',
    docType: 'OtherIncomeDocuments',
  },
  {
    name: 'Government Benefit Documents',
    docType: 'GovernmentBenefitDocuments',
  },
]

export const ApplicationStatusCategory = {
  Draft: 0,
  Applied: 1,
  Shortlisted: 2,
  Offered: 3,
  Declined: 4,
  Withdrawn: 5,
  Accepted: 6,
  Leased: 7,
  Interested: 8,
  PaymentPending: 13,
  PMWithdrawn: 15,
  Approved: 17,
  DepositReceived: 18,
  Processing: 9,
  AwaitingInfo: 10,
  PreApproval: 11,
  OwnerApproval: 12,
  LeaseSent: 14,
  DeclinedFlaggedUnsuitable: 16,
  WithOwner: 19,
  CollectingReferences: 20,
}

export const iconCollection = {
  attach: 'icon-attach',
  bathTub: 'icon-bath-thub',
  bathrooms: 'icon-bathroom',
  bedroom: 'icon-bedroom',
  books: 'icon-books',
  calendar: 'icon-calendar',
  carFront: 'icon-car-front',
  cars: 'icon-cars',
  contacts: 'icon-contacts',
  dogLeash: 'icon-dog-leash',
  flag: 'icon-flag-points',
  geometry: 'icon-geometry',
  home: 'icon-home',
  iTouchID: 'icon-i-touch-id',
  lock: 'icon-lock',
  money: 'icon-money',
  construction: 'icon-new-construction',
  check: 'icon-check',
  scheck: 'icon-s-check',
  star: 'icon-star',
  telegram: 'icon-telegram',
  users: 'icon-users',
  guard: 'icon-security-guard',
  note: 'icon-note-code',
}
// end of identity documents
//-----------------------

// note helpers
// -------------------

export const contactRelationshipCollection = {
  owner: 'Owner',
  interested: 'Interested',
  follower: 'Follower',
  manager: 'Manager',
  renter: 'Renter',
  viewingAttendee: 'ViewingAttendee',
  occupant: 'Occupant',
  teamMember: 'TeamMember',
  tenant: 'Tenant',
  applicant: 'Applicant',
}

export const noteOwnerType = {
  Application: 'sm_application',
  Viewing: 'sm_viewing',
  Enquiry: 'sm_message',
  Property: 'sm_property',
  Unspecified: 'unspecified',
  ViewingAttendee: 'sm_viewing_onsite_registrant',
}

export const noteActionType = {
  unspecified: 0,
  approve: 1,
  decline: 2,
  comment: 3,
  // eslint-disable-next-line no-dupe-keys
  decline: 4,
  requestRenterInfo: 5,
  changed: 6,
  redirected: 7,
  property: 10,
  tenancy: 11,
  viewing: 20,
  enquiry: 30,
  phoneCall: 31,
  viewingAttendee: 40,
  viewingAttendeePrivate: 41,
  viewingAttendeeAddShortlist: 42,
  viewingAttendeeRemoveShortlist: 43,
}

export const noteActionCollection = [
  {
    type: noteActionType.unspecified,
    text: 'unspecified',
    ownerType: noteOwnerType.Unspecified,
    actionImgSrc: applicationDialogue,
  },
  {
    type: noteActionType.approve,
    text: 'approved',
    ownerType: noteOwnerType.Application,
    actionImgSrc: applicationApprove,
  },
  {
    type: noteActionType.decline,
    text: 'declined',
    ownerType: noteOwnerType.Application,
    actionImgSrc: applicationDecline,
  },
  {
    type: noteActionType.comment,
    text: 'commented on',
    ownerType: noteOwnerType.Application,
    actionImgSrc: applicationDialogue,
  },
  {
    type: noteActionType.property,
    text: 'Property',
    ownerType: noteOwnerType.Property,
    actionImgSrc: applicationDialogue,
  },
  {
    type: noteActionType.tenancy,
    text: 'Tenancy',
    ownerType: noteOwnerType.Property,
    actionImgSrc: applicationDialogue,
  },
  {
    type: noteActionType.viewing,
    text: 'Viewing',
    ownerType: noteOwnerType.Viewing,
    actionImgSrc: applicationDialogue,
  },
  {
    type: noteActionType.enquiry,
    text: 'added a message note for ',
    ownerType: noteOwnerType.Enquiry,
    actionImgSrc: applicationDialogue,
  },
  {
    type: noteActionType.phoneCall,
    text: 'called',
    ownerType: noteOwnerType.Enquiry,
  },
  {
    type: noteActionType.viewingAttendee,
    text: 'added a viewing report note for ',
    ownerType: noteOwnerType.ViewingAttendee,
    actionImgSrc: applicationDialogue,
  },
  {
    type: noteActionType.viewingAttendeePrivate,
    text: 'added a viewing private note for ',
    ownerType: noteOwnerType.ViewingAttendee,
    actionImgSrc: applicationDialogue,
  },
  {
    type: noteActionType.viewingAttendeeAddShortlist,
    text: 'added to viewing shortlist: ',
    ownerType: noteOwnerType.ViewingAttendee,
    actionImgSrc: applicationDialogue,
  },
  {
    type: noteActionType.viewingAttendeeRemoveShortlist,
    text: 'removed from viewing shortlist: ',
    ownerType: noteOwnerType.ViewingAttendee,
    actionImgSrc: applicationDialogue,
  },
  {
    type: noteActionType.requestRenterInfo,
    text: 'requested the following information from',
    ownerType: noteOwnerType.Property,
    actionImgSrc: applicationDialogue,
  },
  {
    type: noteActionType.changed,
    text: 'changed',
    ownerType: noteOwnerType.Application,
    actionImgSrc: '',
  },
  {
    type: noteActionType.redirected,
    text: 'redirected reference',
    ownerType: noteOwnerType.Application,
    actionImgSrc: '',
  },
]

export const Source = (source) => {
  switch (source) {
    case 'Realestate':
      return 'Realestate.com.au'
    case 'Domain':
      return 'Domain.com.au'
    case 'Property Page':
      return 'Snug.com'
    case 'Homely':
      return 'Homely.com.au'
    case 'Allhomes':
      return 'Allhomes.com.au'
    case 'REIWA':
      return 'Reiwa.com.au'
    case 'Rent':
      return 'Rent.com.au'
    default:
      return source
  }
}

export const targetSectionObject = {
  unspecified: {
    index: 0,
    text: 'unspecified',
  },
  applicationSummary: {
    index: 1,
    text: 'application summary',
    pageID: 'application-section-summary',
  },
  addressHistory: {
    index: 2,
    text: 'address history',
    pageID: 'application-section-address-history',
  },
  employmentDetails: {
    index: 3,
    text: 'employment details',
    pageID: 'application-section-employment',
  },
  income: {
    index: 4,
    text: 'income',
    pageID: 'application-section-income',
  },
  identity: {
    index: 5,
    text: 'identity',
    pageID: 'application-section-identity',
  },
  emergencyContact: {
    index: 6,
    text: 'emergency contact',
    pageID: 'application-section-emergency-contacts',
  },
  personalReference: {
    index: 7,
    text: 'personal reference',
    pageID: 'application-section-personal-references',
  },
  backgroundCheck: {
    index: 8,
    text: 'background check',
    pageID: 'application-section-background-check',
  },
  petDetails: {
    index: 9,
    text: 'pet details',
    pageID: 'application-section-pets',
  },
  studentDetails: {
    index: 10,
    text: 'student details',
    pageID: 'application-section-student-details',
  },
  otherDocuments: {
    index: 11,
    text: 'other document',
    pageID: 'application-section-other',
  },
  compliance: {
    index: 12,
    text: 'compliance',
    pageID: 'application-section-compliance',
  },
  rentalReference: {
    index: 13,
    text: 'rental reference',
    pageID: 'application-section-address-history',
  },
  managerAttachments: {
    index: 14,
    text: 'manager attachments',
    pageID: 'application-section-other',
  },
  preferences: {
    index: 15,
    text: 'preferences',
    pageID: 'application-section-preference',
  },
  requestRenterInfo: {
    index: 16,
    text: 'request renter info',
    pageId: '',
  },
  leaseSummary: {
    index: 17,
    text: 'lease summary',
    pageId: '',
  },
  enquiry: {
    index: 18,
    text: 'enquiry',
    pageId: '',
  },
  viewingReport: {
    index: 19,
    text: 'viewing report',
    pageId: '',
  },
  viewingPrivateNote: {
    index: 20,
    text: 'viewing attendee private note',
    pageId: '',
  },
  shareApplication: {
    index: 21,
    text: 'share application',
    pageId: '',
  },
  ticaCheck: {
    index: 22,
    text: 'TICA check',
    pageID: 'application-section-tica-check',
  },
  viewingAttendeeAddShortlist: {
    index: 23,
    text: 'viewing attendee add shortlist',
    pageID: '',
  },
  viewingAttendeeRemoveShortlist: {
    index: 24,
    text: 'viewing attendee remove shortlist',
    pageID: '',
  },
}

// end of note helpers
// -------------------

export const refereeTypeCollection = [
  {
    typeName: 'unknown',
    typeText: 'unknown',
  },
  {
    typeName: 'nominatedReferee',
    typeText: 'nominated referee',
  },
  {
    typeName: 'manualReferee',
    typeText: 'manual referee',
  },
]

// end of collections
//-------------------------

// Modal helper
export const modalNameCollection = {
  noteModal: 'isAddNoteModalOn',
  attendeeModal: 'isAddAttendeeHubModalOn',
  addTenantsModal: 'isTenantModalActive',
  addViewingModal: 'isAddViewingModalOn',
  addOwnerModal: 'isOwnerModalActive',
  addAccessModal: 'isAccessDetailModalOn',
}

export const TenantModalCategory = {
  addTenants: 'addTenants',
  sendEntryNotice: 'sendEntryNotice',
}

export const onlistModalCodes = {
  keyCode: 1,
  alarmCode: 2,
  accessNote: 3,
}

export const easyBondpayLeadIssuedType = {
  notRequested: 0,
  success: 1,
  fail: 2,
}

export const OwnerModalCategory = {
  addOwners: 'addOwners',
  sendEntryNotice: 'sendEntryNotice',
}
// end of modal helper
//-------------------------

// string helpers
//-------------------------
export const stripHTMLTags = (text = '') => {
  return text.replace(/<[^>]*>?/gm, '')
}
// end of string helper
//-------------------------

// Application revamp helper function

export const getTenantTypeTextByStatus = (
  isPrimary = false,
  applicationID = '',
) => {
  let typeText = ''
  if (isPrimary) {
    typeText = 'Primary applicant'
  } else if (!isPrimary && applicationID) {
    typeText = 'Secondary applicant'
  } else {
    typeText = 'Occupant'
  }
  return typeText
}

export const yearArrayFromStartToCurrent = (start) => {
  let yearStart = start
  let yearEnd = parseInt(moment().year())
  return Array(yearEnd - yearStart + 1)
    .fill()
    .map(() => yearEnd--)
}

export const getCarSpaceCountForProperty = ({ parking, garages, carports }) => {
  return garages + carports + parking
}

export const formatRent = (rentDisplay) => {
  return rentDisplay === '' || rentDisplay === '0' || rentDisplay === 0
    ? '$ Enquire'
    : rentDisplay
}

export const formatRentWithDollarAndPWeek = (rentDisplay) => {
  return rentDisplay === '' || rentDisplay === '0' || rentDisplay === 0
    ? '$ Enquire'
    : `$ ${rentDisplay} p/w`
}

export const pickRentDisplayAndFormat = (
  weeklyRent = 0,
  weeklyRentDisplay = '',
) => {
  let formattedRent = formatRent(weeklyRentDisplay)

  if (weeklyRentDisplay?.length > 10) {
    formattedRent = formatRentWithDollarAndPWeek(weeklyRent)
  }

  return formattedRent
}

export const getRentAgainAnswerValue = (value) => {
  switch (value) {
    case 'Yes':
      return 1
    case 'No':
      return 2
    case 'Maybe':
      return 3
    default:
      return 0
  }
}

export const questionsAccordingRentalHistoryReference = {
  quickReferenceQuestions: {
    questions: [
      {
        key: 'namedonlease',
        details: [],
      },
      {
        key: 'rentalamount',
        details: [],
      },
      {
        key: 'tenancyterm',
        details: [],
      },
      {
        key: 'rentontime',
        details: ['reasonableconditioncomments'],
      },
      {
        key: 'rentagain',
        details: ['rentagaincomments'],
      },
      {
        key: 'overall',
        details: ['positivecomments', 'areasforconcerncomments'],
      },
    ],

    label: 'Quick Reference',
  },
  supportingQuestions: {
    questions: [
      {
        key: 'lastinspecteddate',
        details: [],
      },
      {
        key: 'tenanthaspets',
        details: [
          'petsauthorised',
          'petsauthorisedcomments',
          'petsdamage',
          'petsdamagecomments',
        ],
      },
      {
        key: 'reasonablecondition',
        details: ['reasonableconditioncomments'],
      },
      {
        key: 'respectneighbours',
        details: ['respectneighbourscomments'],
      },
    ],
    label: 'Supporting Questions',
  },
}

export const publishingOptions = {
  PublishedOption: 1,
  OffMarketOption: 2,
  LeasedOption: 3,
}
