import React from 'react'

import styled from 'styled-components'

const ThreeSectionLayoutContainer = styled.div`
  display: flex;
  ${(props) => !props.noMarginTop && `margin-top: 40px`};
  button {
    width: 250px;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
  ${(props) => props.noWrap && `white-space: nowrap;`};
`
const IndividualSection = styled.div`
  width: 33%;
  margin-bottom: 16px;
  margin-right: 16px;
  @media (max-width: 576px) {
    width: 100%;
    margin-bottom: 0;
  }
`

const ThreeSectionLayout = ({
  sectionOne,
  sectionTwo,
  sectionThree,
  noMarginTop,
  noWrap,
}) => {
  return (
    <ThreeSectionLayoutContainer noMarginTop={noMarginTop} noWrap={noWrap}>
      <IndividualSection>{sectionOne}</IndividualSection>
      <IndividualSection>{sectionTwo}</IndividualSection>
      <IndividualSection>{sectionThree}</IndividualSection>
    </ThreeSectionLayoutContainer>
  )
}

export default ThreeSectionLayout
