import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Box, Flex } from 'app/components/design-system-components'
import { HelpOutlineRounded } from 'app/components/design-system-components/icons/actions'
import { CheckBoxGeneral } from 'app/components/forms/forms'
import {
  getPMSBrand,
  SendApplicantIsRequiredTxt,
  sendApplicantPMSSetting,
} from 'app/constants/pms.constants'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { parseDataUtil } from 'app/sm/helpers'
import { isSendApplicantEnabled } from 'config/features'

export const SendApplicationToPMS = ({
  pms,
  teamSlug,
  integrationSettings,
  pmsConfig,
  onChange,
  sendOption,
}: any) => {
  const brand = getPMSBrand(pms) as any
  if (!brand || !isSendApplicantEnabled(pms, teamSlug)) return

  const teamSetting = `${sendApplicantPMSSetting}.${brand.id}`
  const parsedIntegrationSettings =
    parseDataUtil.convertObjValueFromStringToBoolean(integrationSettings)
  const disable = !(parsedIntegrationSettings as any)[teamSetting]
  const optionKey = brand[sendOption]

  return (
    <Flex alignItems="center">
      <CheckBoxGeneral
        label={`Send application details to ${brand.label}`}
        id={brand.applicationConfig}
        onChange={onChange}
        checked={pmsConfig[optionKey] && !disable}
        showDisabledGrey={disable}
        eventProcessedByComponent={true}
        disabled={disable}
      />
      {disable && (
        <Box
          color={theme.colors.gray500}
          fontSize={theme.fontSizes.pLarge16}
          style={{ zIndex: 2 }}
          mb={2}
        >
          <HelpOutlineRounded
            data-tooltip-id="sendApplicationDisabled"
            data-tooltip-content=""
          />
          <ReactTooltip id="sendApplicationDisabled">
            {SendApplicantIsRequiredTxt}
          </ReactTooltip>
        </Box>
      )}
    </Flex>
  )
}
