import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

const logAPIError = (error) => LogFetchError(error)
const checkStatus = (response) => {
  if (response.ok) return Promise.resolve(response.json())

  return Promise.reject({
    statusCode: response.statusCode,
    responseText: response.text(),
  })
}
const returnData = (data) => ({ ok: true, data })

const returnAPIError = (error) => ({
  ok: false,
  responseText: error.responseText,
  statusCode: error.statusCode,
})

export const fetchAllSupportingIDAttachments = () => {
  const url = `${apiBaseUrl}sm/backgroundcheck/attachments/otheridentitydocs `
  const options = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(url, options)
    .then(checkStatus)
    .then(returnData, returnAPIError)
    .catch(logAPIError)
}
