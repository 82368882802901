import React, { useState } from 'react'

import { Button, Flex } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'
import TeamActivationModal, {
  ACCOUNTS_ACTIVATION_TITLE,
} from 'app/utils/teamActivation/TeamActivationModal'

export const TeamActivationMessage = ({
  hasDismissedInactiveTeamAlert,
  dismissTeamInactiveModal,
}) => {
  const [teamActivationModalOpen, setTeamActivationModalOpen] = useState(false)
  const toggleTeamActivationModal = () => {
    setTeamActivationModalOpen(!teamActivationModalOpen)
  }

  return (
    <>
      <Flex alignItems="center">
        Please{' '}
        <Button
          variant="linkBlueWithoutWeight"
          onClick={() => toggleTeamActivationModal()}
          mx={2}
          sizeVariant=""
          fontSize={theme.fontSizes.pLarge16}
        >
          Call your {ACCOUNTS_ACTIVATION_TITLE}
        </Button>{' '}
        to activate
      </Flex>
      {!hasDismissedInactiveTeamAlert && (
        <TeamActivationModal
          toggleTeamActivationModal={dismissTeamInactiveModal}
        />
      )}
      {teamActivationModalOpen && (
        <TeamActivationModal
          toggleTeamActivationModal={toggleTeamActivationModal}
        />
      )}
    </>
  )
}
