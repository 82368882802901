import React from 'react'

import { Link } from 'react-router-dom'

const ErrorPage = ({ message }) => (
  <div>
    <h3> Oops! Something went wrong! </h3>

    <div className={message ? 'alert alert-danger' : ''}>{message}</div>

    <Link to="/sm/applications">
      <button type="button"> Return to My Applications</button>
    </Link>
  </div>
)

export default ErrorPage
