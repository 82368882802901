import React from 'react'

import styled from 'styled-components'

import adrianA from 'app/assets/icons/adriana.png'
import { Box, Flex, Modal } from 'app/components/design-system-components'
import { mediaQueries } from 'app/components/design-system-components/styles/variables'
import { Text } from 'app/components/design-system-components/typography'
import { TelLink } from 'app/components/display/contact_links'
import { EmailLink } from 'app/components/display/display'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledModalContainer = styled(Flex)`
  ${mediaQueries.sm} {
    flex-direction: column;
  }
  align-items: center;
  font-size: ${theme.fontSizes.pSurplusLarge24};
`

const StyledImageContainer = styled(Box)`
  width: 40%;
  margin-right: ${theme.space[8]}px;
  margin-top: ${theme.space[5]}px;
  ${mediaQueries.sm} {
    width: 100%;
    margin-bottom: ${theme.space[5]}px;
  }
  img {
    width: 100%;
  }
`

const ACCOUNTS_MANAGER_FULL_NAME = 'Adrian Angelica'
const ACCOUNTS_MANAGER_EMAIL = 'adrianangelica@snug.com'
const ACCOUNTS_MANAGER_PHONE = '0491 944 589'
export const ACCOUNTS_ACTIVATION_TITLE = 'National Account Executive'

function TeamActivationModal({ toggleTeamActivationModal }) {
  const modalBody = (
    <Box>
      <StyledModalContainer>
        <StyledImageContainer>
          <img src={adrianA} alt={`Call your ${ACCOUNTS_ACTIVATION_TITLE}`} />
        </StyledImageContainer>
        <Box>
          <Text
            fontWeight={theme.fontWeights.h3}
            fontSize={theme.fontSizes.pSurplusLarge24}
            pb={0}
            mb={3}
          >
            Call your {ACCOUNTS_ACTIVATION_TITLE}
          </Text>
          <a href={`mailto:${ACCOUNTS_MANAGER_EMAIL}`}>
            {ACCOUNTS_MANAGER_FULL_NAME}
          </a>
          <Box mb={3}>
            <EmailLink
              linkText={ACCOUNTS_MANAGER_FULL_NAME}
              mailTo={ACCOUNTS_MANAGER_EMAIL}
            />
          </Box>
          <Box>
            <TelLink phone={ACCOUNTS_MANAGER_PHONE} />
          </Box>
        </Box>
      </StyledModalContainer>
    </Box>
  )
  return (
    <Modal
      toggleModal={toggleTeamActivationModal}
      modalHeading="Activate to Access Applications"
      modalBody={modalBody}
      showPrimaryButton={false}
      showSecondaryButton={false}
    />
  )
}

export default TeamActivationModal
