import { connect } from 'react-redux'

import {
  createVirtualTour,
  fetchVirtualTourLinks,
} from 'app/sm/onlist_details/action'
import VirtualTour from 'app/sm/onlist_details/virtual_tour'

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  fetchVirtualTourLinks: (agencyGUID, propertyGUID) =>
    dispatch(fetchVirtualTourLinks(agencyGUID, propertyGUID)),
  createVirtualTour: (agencyGUID, propertyGUID, payload) =>
    dispatch(createVirtualTour(agencyGUID, propertyGUID, payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VirtualTour)
