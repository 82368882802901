import React from 'react'

import moment from 'moment'

import { Box, Flex } from 'app/components/design-system-components'
import {
  MailOutlineRounded,
  PhoneRounded,
} from 'app/components/design-system-components/icons/communications'
import { MailToLink } from 'app/components/design-system-components/links/MailTo'
import {
  Heading,
  Text,
} from 'app/components/design-system-components/typography'
import * as Display from 'app/components/display/display'
import { declineReasonsIDs } from 'app/constants/rental-references.constants'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  getDisplayedRent,
  isAddressHistoryOwned,
  isAddressHistoryWithFamilyOrFriends,
  propertyArrangementLabels,
  propertyArrangementNotShowStatusPill,
  totalTimePassed,
} from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

const VERIFIED_STRING = 'Verified'
const PENDING_STRING = 'Pending'
const CONTACT_REFEREE = 'Contact referee'
const NOT_APPLICABLE_STRING = 'N/A' // Case refrence is not required, e.g, owner case

const verificationStatusMap = {
  verified: {
    text: VERIFIED_STRING,
    pillStyle: 'hollow',
  },
  pending: {
    text: PENDING_STRING,
    pillStyle: 'white-grey',
  },
  notApplicable: {
    text: NOT_APPLICABLE_STRING,
    pillStyle: 'grey',
  },
  contactReferee: {
    text: CONTACT_REFEREE,
    pillStyle: 'yellow',
  },
}

const isDeclineReasonNeedsContact = (reason) =>
  reason === declineReasonsIDs.TENANT_DID_NOT_LEASE_PROPERTY ||
  reason === declineReasonsIDs.PROPERTY_NOT_UNDER_AGENCY_MANAGEMENT

const getVerificationStatus = (
  isReferenceRequired,
  referenceVerified,
  declineReason,
) => {
  if (!isReferenceRequired) return 'notApplicable'
  if (
    referenceVerified ||
    declineReason === declineReasonsIDs.UNWILLING_TO_PROVIDE_ANY_REFERENCE
  )
    return 'verified'
  if (isDeclineReasonNeedsContact(declineReason)) return 'contactReferee'
  return 'pending'
}

export const formatDate = ({ from, to, isRollingLease }) => {
  const [From, To] = [
    moment(from).format(dateTimeHelpers.DATE_DAY_SHORT_STRING_MONTH_YEAR),
    isRollingLease || moment(to).isAfter(moment())
      ? 'Present'
      : moment(to).format(dateTimeHelpers.DATE_DAY_SHORT_STRING_MONTH_YEAR),
  ]

  return `${From} - ${To}`
}

export const AddressHistoryInfo = ({
  suburbStateCountry,
  formattedDate,
  propertyArrangement,
  years,
  months,
  days,
  weeklyPrice,
}) => {
  return (
    <div className="info">
      {suburbStateCountry}
      <br />
      {formattedDate}
      <br />
      {years > 0 && <span className="mr10">{years} Years</span>}
      {months > 0 && <span className="mr10">{months} Months</span>}
      {days > 0 && <span className="mr10">{days} Days</span>}
      <div>
        {!!propertyArrangement &&
          `Arrangement: ${propertyArrangementLabels[propertyArrangement]}`}
      </div>

      <AddressHistoryWeeklyRent
        weeklyPrice={weeklyPrice}
        propertyArrangement={propertyArrangement}
      />
    </div>
  )
}

export function AddressHistoryDurationText(
  formattedDate = '',
  years = 0,
  months = 0,
  days = 0,
) {
  const yearsText = `${years} Years`
  const monthsText = months > 0 && `${months} Months`
  const daysText = days > 0 && `${days} Days`
  const durationArray = []
  years > 0 && durationArray.push(yearsText)
  monthsText && durationArray.push(monthsText)
  daysText && durationArray.push(daysText)
  const duration = durationArray.join(' ')
  const durationWithDate = `${duration} (${formattedDate})`
  return durationWithDate
}

export const AddressHistoryWeeklyRent = ({
  weeklyPrice,
  propertyArrangement,
}) => {
  const displayedRent = getDisplayedRent(weeklyPrice, propertyArrangement)
  return (
    <Box>
      <Box>
        <Text
          as="span"
          fontSize={`${theme.fontSizes[6]}px`}
          fontWeight={theme.fontWeights[6]}
        >
          Rent: {`${displayedRent}`}
        </Text>
      </Box>
    </Box>
  )
}

export const AddressHistoryManager = ({
  landlord = {},
  agencyName = '',
  phoneNumber,
  officeNumber = '',
  email,
  propertyArrangement,
  isReferenceRequired,
  referenceVerified,
  declineReason,
}) => {
  const verificationStatus =
    verificationStatusMap[
      getVerificationStatus(
        isReferenceRequired,
        referenceVerified,
        declineReason,
      )
    ]
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex>
        <Box>
          <Heading level={4} displayingLevel={4}>
            {landlord.firstName} {landlord.lastName}
          </Heading>
          {agencyName && (
            <Text
              as="div"
              mt={1}
              fontSize={`${theme.fontSizes[5]}px`}
              fontWeight={theme.fontWeights[4]}
              color={theme.colors.gray300}
            >
              {agencyName}
            </Text>
          )}
        </Box>
        {!propertyArrangementNotShowStatusPill.includes(
          propertyArrangement,
        ) && (
          <Flex alignItems="baseline">
            <Display.StatusPill
              pillType={verificationStatus.pillStyle}
              text={verificationStatus.text}
              pillTextClass="grey-text"
              componentClass="ml5"
            />
            {isDeclineReasonNeedsContact(declineReason) && (
              <Text as="span" ml={2}>
                Details incorrect
              </Text>
            )}
          </Flex>
        )}
      </Flex>
      <Flex>
        {(phoneNumber || officeNumber) && (
          <a href={`tel:${phoneNumber || officeNumber}`}>
            <PhoneRounded
              fontSize={theme.fontSizes[9]}
              color={theme.colors.gray400}
            />
          </a>
        )}
        {email && (
          <Box ml={2}>
            <MailToLink email={email}>
              <MailOutlineRounded
                fontSize={theme.fontSizes[9]}
                color={theme.colors.gray400}
              />
            </MailToLink>
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

export const StaticHistoryBox = ({ H }) => {
  const { years, months, days } = totalTimePassed(
    H.startDate,
    H.endDate,
    H.isRollingLease,
  )
  const suburbStateCountryArray = []
  H.address.suburb && suburbStateCountryArray.push(H.address.suburb)
  H.address.state && suburbStateCountryArray.push(H.address.state)
  H.address.country && suburbStateCountryArray.push(H.address.country)
  const suburbStateCountry = suburbStateCountryArray.join(', ')
  const formattedDate = formatDate(H.startDate, H.endDate, H.isRollingLease)
  const landLordName = { firstName: H.firstName, lastName: H.lastName }
  return !!H.address ? (
    <div className="rental-history-box">
      <div className="rental-history-box-title-wrapper">
        <div className="title pb0 col-sm-8 pl0">
          {H.address.streetNumber} {H.address.streetName}
        </div>
      </div>

      <AddressHistoryInfo
        suburbStateCountry={suburbStateCountry}
        formattedDate={formattedDate}
        propertyArrangement={H.propertyArrangement}
        years={years}
        months={months}
        days={days}
      />
      {!isAddressHistoryOwned(H.propertyArrangement) && (
        <AddressHistoryManager
          landlord={landLordName}
          agencyName={H.agencyName}
          phoneNumber={H.phoneNumber}
          email={H.email}
        />
      )}
    </div>
  ) : (
    <div />
  )
}
