import { Flex } from 'app/components/design-system-components/index'
import { AppliedFilter } from 'app/components/design-system-components/list-filters/AppliedFilter'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const BasicAppliedFilters = ({ onDismissFilter, filtersConfig }) => {
  const mappedAppliedFilters = Object.entries(filtersConfig).map(
    ([filterId, config]) => {
      if (!config.isApplied) return null
      return (
        <AppliedFilter
          key={filterId}
          filterTitle={config.appliedFilterTitle}
          renderedAppliedFilters={
            <config.AppliedFilterRenderer filterValues={config.filterValues} />
          }
          onDismiss={() => onDismissFilter(filterId)}
          m={`0 ${theme.space[3]}px ${theme.space[3]}px 0`}
        />
      )
    },
  )

  return (
    <Flex alignItems="center" flexWrap="wrap" mt={theme.space[3] + 'px'}>
      {mappedAppliedFilters}
    </Flex>
  )
}
