import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { validateEmail } from 'app/shared_components/validations'

const MAX_MESSAGE_CHARS_ALLOWED = 100
const MAX_MESSAGE_CHARS_ALLOWED_ERROR_MESSAGE = `Message cannot be greater than ${MAX_MESSAGE_CHARS_ALLOWED} characters`

export const formFields = {
  applicants: 'applicants',
  property: 'property',
  message: 'message',
  allowWithoutViewingProperty: 'allowWithoutViewingProperty',
  contact: 'contact',
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.applicants: {
      const invalidEmails = (val || [])
        .filter(({ option }) => {
          return validateEmail(option.renterEmail)[0]
        })
        .map(({ option }) => option.renterEmail)

      return {
        required:
          (!val || !val.length) && 'at least on recipient should be added',
        pattern:
          invalidEmails.length &&
          `Following email(s) are invalid "${invalidEmails.join(', ')}"`,
      }
    }
    case formFields.property: {
      return {
        required: !val && 'Please select a property address',
      }
    }
    case formFields.message: {
      return {
        max:
          (val || '').length > MAX_MESSAGE_CHARS_ALLOWED &&
          MAX_MESSAGE_CHARS_ALLOWED_ERROR_MESSAGE,
      }
    }
    default: {
      return {}
    }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
