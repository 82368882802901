import { accountsApi, api } from 'app/utils/api/helpers'

export const connectTeamFromRRToLiteAccount = (payload) => {
  return accountsApi.post('/register-lite-registration', payload)
}

export const verifyAccountFromRRToLiteAccount = (payload) => {
  return accountsApi.post('/verify-lite-registration', payload)
}

export const selfRegistration = (payload) => {
  let data = new FormData()
  data.append('data', JSON.stringify(payload))
  return api.post('/sm/team/register', data)
}

export const acceptInvite = (inviteId) => {
  return api.put(`/agencies/invite/${inviteId}/accept`)
}

export const getAgencySlugByGUID = (agencyGUID) => {
  return api.get(`/agency/${agencyGUID}/get-slug`)
}

export const postStartedSuggestTeamMembers = (teamGUID, payload) => {
  return api.post(`/sm/team/${teamGUID}/setup/started/lite`, payload)
}

export const archiveTeamRentalReference = (teamGUID, teamRRGUID) => {
  return api.post(
    `/sm/teams/${teamGUID}/rentalreferences/${teamRRGUID}/archive`,
  )
}
export const unarchiveTeamRentalReference = (teamGUID, teamRRGUID) => {
  return api.post(
    `/sm/teams/${teamGUID}/rentalreferences/${teamRRGUID}/unarchive`,
  )
}
export const reassignTeamRentalReference = (teamGUID, teamRRGUID, payload) => {
  return api.post(
    `/sm/teams/${teamGUID}/rentalreferences/${teamRRGUID}/reassign`,
    payload,
  )
}
