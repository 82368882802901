import React, { useEffect, useState } from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Box, Button, Flex } from 'app/components/design-system-components'
import Dropdown from 'app/components/forms/base_dropdown/component'
import * as Form from 'app/components/forms/forms'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { updateApplicationSettings } from 'app/services/http/offers'
import * as snugNotifier from 'app/services/snugNotifier'
import LeaseEndDate from 'app/sm/onlist_details/applications_settings/lease_end_date'
import * as textHelpers from 'app/utils/text/helpers'

const ApplicationsLimits = [1, 2, 3, 4, 5, 10, 15, 20]

const toBool = (str) => str === 'true'

const StyledReactTooltip = styled(ReactTooltip)`
  width: ${({ width }) => width};
  font-size: ${theme.fontSizes[3]} !important;
  background-color: ${theme.colors.gray500} !important;
  border-radius: ${theme.space[1]}px;
  color: ${theme.colors.white};

  ::after {
    border-top-color: ${theme.colors.gray500} !important;
  }
`

const ApplicationsSettings = ({
  guidDetails,
  offer,
  applicationsTeamSettings,
}) => {
  const {
    enable_pre_apply: requirePreApplyBeforeViewingTeamSetting = 'false',
    viewing_checkin_require: requireViewingBeforeApplyTeamSetting = 'false',
    application_cap_enabled: limitApplicationsTeamSetting = 'false',
    application_cap: applicationsLimitTeamSetting = '1',
  } = applicationsTeamSettings

  const [settings, setSettings] = useState({
    limitApplications: false,
    overrideLimitApplications: false,
    applicationsLimit: 1,
    requireViewingBeforeApply: false,
    overrideRequireViewingBeforeApply: false,
    requirePreApplyBeforeViewing: false,
    overrideRequirePreApplyBeforeViewing: false,
  })

  useEffect(() => {
    mapSettings()
  }, [offer])

  useEffect(() => {
    mapSettings()
  }, [applicationsTeamSettings])

  const mapSettings = () => {
    if (!offer || !applicationsTeamSettings) {
      return
    }

    let mappedSettings = {
      overrideLimitApplications: offer.overrideLimitApplications,
      overrideRequireViewingBeforeApply:
        offer.overrideRequireViewingBeforeApply,
      overrideRequirePreApplyBeforeViewing:
        offer.overrideRequirePreApplyBeforeViewing,

      limitApplications: offer.overrideLimitApplications
        ? offer.limitApplications
        : toBool(limitApplicationsTeamSetting),
      applicationsLimit: offer.overrideLimitApplications
        ? offer.applicationsLimit
        : +applicationsLimitTeamSetting,
      requireViewingBeforeApply: offer.overrideRequireViewingBeforeApply
        ? offer.requireViewingBeforeApply
        : toBool(requireViewingBeforeApplyTeamSetting),
      requirePreApplyBeforeViewing: offer.overrideRequirePreApplyBeforeViewing
        ? offer.requirePreApplyBeforeViewing
        : toBool(requirePreApplyBeforeViewingTeamSetting),
    }

    setSettings({
      ...mappedSettings,
    })
  }

  const validateApplyOptions = (key, val) => {
    if (
      key === 'requirePreApplyBeforeViewing' &&
      val &&
      settings.requireViewingBeforeApply
    ) {
      return [
        true,
        'Please disable require viewing before apply option to enable this one.',
      ]
    } else if (
      key === 'requireViewingBeforeApply' &&
      val &&
      settings.requirePreApplyBeforeViewing
    ) {
      return [
        true,
        'Please disable pre apply before viewing option to enable this one.',
      ]
    }

    return [false, '']
  }

  const updateSetting = (newSettings) => {
    for (const [key, value] of Object.entries(newSettings)) {
      const [invalid, msg] = validateApplyOptions(key, value)
      if (invalid) {
        snugNotifier.error(msg)
        return
      }
    }

    const updatedSettings = {
      ...settings,
      ...newSettings,
    }

    const { offerGUID, agencyGUID } = guidDetails

    updateApplicationSettings(agencyGUID, offerGUID, updatedSettings)
      .then(() => {
        snugNotifier.success('Settings updated')

        setSettings(updatedSettings)
      })
      .catch((err) => {
        snugNotifier.error(err.message || 'Failed to update settings')
      })
  }

  return (
    <Box>
      <Box mb={`${theme.space[4]}px`}>
        <h5>Applications</h5>
      </Box>

      <Box>
        <Flex mb={`${theme.baseSpace}px`}>
          <Form.CheckBoxGeneral
            label="Limit applications for property"
            onChange={(e) =>
              updateSetting({
                limitApplications: e.target.checked,
                overrideLimitApplications: true,
              })
            }
            checked={settings.limitApplications}
            eventProcessedByComponent
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="limitApplications"
                />
              </div>
            }
          />
          <div>
            <StyledReactTooltip
              id="limitApplications"
              width="215px"
              height="46px"
            >
              <span>
                Limit the number of applications that can be submitted for this
                property
              </span>
            </StyledReactTooltip>
          </div>

          {settings.overrideLimitApplications && (
            <ResetButton
              onClickHandler={() =>
                updateSetting({
                  limitApplications: toBool(limitApplicationsTeamSetting),
                  overrideLimitApplications: false,
                  applicationsLimit: +applicationsLimitTeamSetting,
                })
              }
            />
          )}
        </Flex>

        {settings.limitApplications && (
          <Flex mb="8px">
            <Dropdown
              name="applicationsLimit"
              label=""
              value={ApplicationsLimits.indexOf(settings.applicationsLimit)}
              options={ApplicationsLimits}
              validate={() => {}}
              onChange={({ value }) =>
                updateSetting({
                  applicationsLimit: ApplicationsLimits[value],
                  limitApplications: true,
                  overrideLimitApplications: true,
                })
              }
              extraOptionText="applications"
            />
          </Flex>
        )}
      </Box>

      <Flex mb={`${theme.baseSpace}px`}>
        <Form.CheckBoxGeneral
          label={textHelpers.REQUIRE_VIEWING_BEFORE_APPLY_PREFERENCE}
          onChange={(e) =>
            updateSetting({
              requireViewingBeforeApply: e.target.checked,
              overrideRequireViewingBeforeApply: true,
            })
          }
          checked={settings.requireViewingBeforeApply}
          eventProcessedByComponent
          toolTip={
            <div>
              <i
                className="icon-help-outline fs16 gray-light-color ibm"
                data-tooltip-content=""
                data-tooltip-id={
                  textHelpers.REQUIRE_VIEWING_BEFORE_APPLY_PREFERENCE_TOOLTIP_ID
                }
              />
            </div>
          }
        />
        <div>
          <StyledReactTooltip
            id={textHelpers.REQUIRE_VIEWING_BEFORE_APPLY_PREFERENCE_TOOLTIP_ID}
            width="325px"
          >
            {
              textHelpers.REQUIRE_VIEWING_BEFORE_APPLY_PREFERENCE_TOOLTIP_CONTENT
            }
          </StyledReactTooltip>
        </div>

        {settings.overrideRequireViewingBeforeApply && (
          <ResetButton
            onClickHandler={() =>
              updateSetting({
                requireViewingBeforeApply: toBool(
                  requireViewingBeforeApplyTeamSetting,
                ),
                overrideRequireViewingBeforeApply: false,
              })
            }
          />
        )}
      </Flex>

      <Flex mb={`${theme.baseSpace}px`}>
        <Form.CheckBoxGeneral
          label={textHelpers.PRE_APPLY_PREFERENCE}
          onChange={(e) =>
            updateSetting({
              requirePreApplyBeforeViewing: e.target.checked,
              overrideRequirePreApplyBeforeViewing: true,
            })
          }
          checked={settings.requirePreApplyBeforeViewing}
          eventProcessedByComponent
          toolTip={
            <i
              className="icon-help-outline fs16 gray-light-color ibm"
              data-tooltip-content=""
              data-tooltip-id={textHelpers.PRE_APPLY_PREFERENCE_TOOLTIP_ID}
            />
          }
        />
        <div>
          <StyledReactTooltip
            id={textHelpers.PRE_APPLY_PREFERENCE_TOOLTIP_ID}
            width="382px"
          >
            {textHelpers.PRE_APPLY_PREFERENCE_TOOLTIP_CONTENT}
          </StyledReactTooltip>
        </div>

        {settings.overrideRequirePreApplyBeforeViewing && (
          <ResetButton
            onClickHandler={() =>
              updateSetting({
                requirePreApplyBeforeViewing: toBool(
                  requirePreApplyBeforeViewingTeamSetting,
                ),
                overrideRequirePreApplyBeforeViewing: false,
              })
            }
          />
        )}
      </Flex>

      <Box>
        <LeaseEndDate guidDetails={guidDetails} offer={offer} />
      </Box>
    </Box>
  )
}

const StyledResetButton = styled(Button)`
  margin-left: ${theme.baseSpace}px;
  font-size: ${theme.fontSizes[4]}px !important;
  font-weight: ${theme.fontWeights[3]} !important;
  margin-top: -${theme.space[1]}px;
`

const ResetButton = ({ onClickHandler }) => {
  return (
    <StyledResetButton
      variant="linkBlueWithoutWeight"
      onClick={() => onClickHandler()}
    >
      reset to team defaults
    </StyledResetButton>
  )
}

export default ApplicationsSettings
