import * as React from 'react'

function SvgUpDownArrows(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85612 6.14502L8.64612 3.35502C8.83612 3.16502 9.15612 3.16502 9.34612 3.35502L12.1361 6.14502C12.4561 6.45502 12.2361 6.99502 11.7861 6.99502H9.99612V13.005C9.99612 13.555 9.54612 14.005 8.99612 14.005C8.44612 14.005 7.99612 13.555 7.99612 13.005V6.99502H6.20612C5.75612 6.99502 5.53612 6.45502 5.85612 6.14502ZM15.9961 11.005V17.015H17.7961C18.2361 17.015 18.4661 17.555 18.1461 17.865L15.3561 20.645C15.1561 20.835 14.8461 20.835 14.6461 20.645L11.8561 17.865C11.5361 17.555 11.7561 17.015 12.2061 17.015H13.9961V11.005C13.9961 10.455 14.4461 10.005 14.9961 10.005C15.5461 10.005 15.9961 10.455 15.9961 11.005Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgUpDownArrows
