import React from 'react'

import { Box } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

import { StyledBranding } from '.'

function NoBannerAndLogo({ agencyName = '' }) {
  return (
    <StyledBranding>
      <Box mt={theme.space[5] + 'px'}>
        <Box mt={theme.space[3] + 'px'} mb={theme.baseSpace * 5 + 'px'}>
          Complete your branding setup, add your agency logo and SMS sender
          name.
        </Box>
        <Box>
          <b>Logo</b>
          <Box mb={theme.space[8] + 'px'} mt={theme.baseSpace / 2 + 'px'}>
            Please email your logo to{' '}
            <a
              href={`mailto:help@snug.com?subject=Branding Logo Request:${agencyName}&body=Please update the branding for ${agencyName} with the attached logo. Thank you.`}
            >
              help@snug.com
            </a>{' '}
            and our customer success team will upload it.
          </Box>
        </Box>
      </Box>
    </StyledBranding>
  )
}

export default NoBannerAndLogo
