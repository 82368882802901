import React from 'react'

import { FeatureFlag } from 'config/features'

const steps = [
  { show: true, no: 1, path: 'get-started', name: 'Add a property' },
  { show: true, no: 2, path: 'property-basics', name: 'Property details' },
  { show: true, no: 3, path: 'property-photos', name: 'Add Photos' },
  { show: true, no: 4, path: 'offer-details', name: 'Letting Details' },
  { show: true, no: 5, path: 'publishing-options', name: 'Publishing Options' },
  { show: true, no: 6, path: 'preferences', name: 'Preferences' },
  {
    show: FeatureFlag.Viewings.isOn,
    no: 7,
    path: 'viewings',
    name: 'Viewings',
  },
  { show: true, no: 8, path: 'share', name: 'Share' },
]

function renderStep(step, state) {
  return (
    <li className={state} key={step.path}>
      <span>{step.name}</span>
    </li>
  )
}

class Breadcrumbs extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { activeBreadCrumb } = this.props

    const shownSteps = steps.filter((s) => s.show)
    const currentStep = shownSteps.filter((s) => s.path === activeBreadCrumb)[0]
    const completedSteps = shownSteps.filter((s) => s.no < currentStep.no)
    const nextSteps = shownSteps.filter((s) => s.no > currentStep.no)

    return (
      <div className="sm-breadcrumbs full-width">
        <ul>
          {completedSteps.map((step, i) => renderStep(step, 'completed'))}
          {renderStep(currentStep, 'current')}
          {nextSteps.map((step, i) => renderStep(step))}
        </ul>
      </div>
    )
  }
}

export default Breadcrumbs
