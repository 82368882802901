import { useEffect, useState } from 'react'

import { Link, useLocation, useParams } from 'react-router-dom'

import {
  Box,
  Flex,
  LoadingSection,
  TextSpan,
} from 'app/components/design-system-components'
import {
  ApprovedRounded,
  DeniedRounded,
} from 'app/components/design-system-components/icons/snug-specific'
import * as Display from 'app/components/display/display'
import {
  ApplicantNotFound,
  ErrorMergeRequestExpired,
} from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { approveApplicationMergeRequest } from 'app/services/http/applications'
import { urlTo } from 'app/sm/helpers'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'

type MergeApproveParams = {
  applicationId: string
}

const EXPIRED_ERROR_MSG =
  'This merge request has expired. Please contact your property manager.'
const NOT_LOGGED_IN_ERROR_MSG = 'Log in as recipient to approve.'

const MergeErrorComponent = ({ error }: any) => {
  const { response = {} as any } = error?.plainError || {}
  const errorCode = response.data
  let message
  switch (errorCode) {
    case ErrorMergeRequestExpired:
      message = EXPIRED_ERROR_MSG
      break
    case ApplicantNotFound:
      message = NOT_LOGGED_IN_ERROR_MSG
      break
    default:
      const defaultMessage = `Please make sure you have not already accepted this request or check your email for a more recent request (${errorCode}).`
      message = translateErrorCodeToMessage(errorCode, defaultMessage)
  }
  const errorBody = (
    <TextSpan as="span" mb={6} lineHeight={theme.lineHeights.encrption}>
      {message}
    </TextSpan>
  )
  return <Display.ErrorMessageContainer error={errorBody} />
}

const MergeApproveMessage = () => {
  const {
    loadingStates: approveLoadingStates,
    loadingStatesHelpers: approveLoadingHelpers,
  } = useLoadingStates()

  const { applicationId } = useParams<MergeApproveParams>()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const mergeRequestToken = searchParams.get('code')

  const [succeeded, setSucceeded] = useState(false)
  // const [errorCode, setErrorCode] = useState(null)

  useEffect(() => {
    approveLoadingHelpers.startLoading()
    approveApplicationMergeRequest(applicationId, mergeRequestToken)
      .then(() => {
        setSucceeded(true)
        approveLoadingHelpers.markDoneSuccessfully()
      })
      .catch(approveLoadingHelpers.setError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const approveSucceededElem = (
    <Box>
      <TextSpan as="div" mb={6} lineHeight={theme.lineHeights.encrption}>
        Merge application request approved. You may withdraw your original
        application at any time.
      </TextSpan>
      View <Link to={urlTo('applications')}>my applications</Link>
    </Box>
  )

  const iconElem = (
    <>
      {succeeded ? (
        <ApprovedRounded
          color={theme.colors.success600}
          width={100}
          height={100}
        />
      ) : (
        <DeniedRounded color={theme.colors.gray400} width={100} height={100} />
      )}
    </>
  )

  return (
    <Flex flexDirection="column" width="100%" alignItems="center">
      <Box width="50%">
        <LoadingSection
          loadingState={approveLoadingStates}
          showChildrenWhenError
          ErrorComponent={MergeErrorComponent}
        >
          <Box textAlign="center" mb={5}>
            {iconElem}
          </Box>
          {succeeded && approveSucceededElem}
        </LoadingSection>
      </Box>
    </Flex>
  )
}

export default MergeApproveMessage
