import React, { useEffect, useState } from 'react'

import { Formik } from 'formik'
import { connect } from 'react-redux'

import {
  formFields,
  formValidation,
} from 'app/features/teams/key-logger/CheckoutKeySetModal/AddressBorrowerSelection/form.utils'
import { FormBody } from 'app/features/teams/key-logger/CheckoutKeySetModal/AddressBorrowerSelection/FormBody'

const defaultInitialValues = {
  [formFields.property]: null,
  [formFields.keySet]: null,
  [formFields.bulkKeySets]: null,
  [formFields.borrower]: null,
}

const generateInitialValues = (checkOutData) => {
  const { property, keySet, bulkKeySets, borrower } = checkOutData
  return {
    ...(property && {
      [formFields.property]: {
        option: property,
        optionMeta: {
          id: property.guidID,
          displayText: property?.address?.friendlyName,
          isInitialValue: true,
        },
      },
    }),
    ...(keySet && {
      [formFields.keySet]: {
        option: keySet,
        optionMeta: {
          id: keySet.guid,
          displayText: keySet?.label,
        },
      },
    }),
    ...(keySet && {
      [formFields.borrower]: {
        option: keySet,
        optionMeta: {
          id: keySet.guid,
          displayText: keySet?.label,
        },
      },
    }),
    [formFields.bulkKeySets]: bulkKeySets,
    [formFields.borrower]: borrower,
  }
}

export const PureAddressBorrowerSelection = ({
  teamId,
  checkOutData,
  proceed,
  bulkCheckoutCommentCmp,
  bulkCheckoutSelectedCmp,
}) => {
  const [initialValues, setInitialValues] = useState(defaultInitialValues)
  const { isBorrowerDisabled = false } = checkOutData || {}

  useEffect(() => {
    if (!checkOutData) return
    const generatedInitialValues = generateInitialValues(checkOutData)
    setInitialValues(generatedInitialValues)
  }, [checkOutData])

  const onSubmit = (values) => {
    return Promise.resolve({
      keySet: values[formFields.keySet]?.option,
      property: values[formFields.property]?.option,
      bulkKeySets: values[formFields.bulkKeySets],
      borrower: values[formFields.borrower].option,
    }).then(proceed)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
      validate={formValidation}
      validateOnBlur
      validateOnChange
    >
      {(props) => (
        <FormBody
          isBorrowerDisabled={isBorrowerDisabled}
          formBag={props}
          teamId={teamId}
          bulkCheckoutCommentCmp={bulkCheckoutCommentCmp}
          bulkCheckoutSelectedCmp={bulkCheckoutSelectedCmp}
        />
      )}
    </Formik>
  )
}

const mapStateToProps = ({ session }) => ({ teamId: session.currentTeam.guid })
export const AddressBorrowerSelection = connect(mapStateToProps)(
  PureAddressBorrowerSelection,
)
