import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'
import { flex, layout, space, typography, variant } from 'styled-system'

export const badgeVariants = {
  variants: {
    red: {
      backgroundColor: 'primary100',
      color: 'primary400',
    },
    blue: {
      backgroundColor: 'paleBlue',
      color: 'deepBlue',
    },
    green: {
      backgroundColor: 'success100',
      color: 'success600',
    },
    gray: {
      backgroundColor: 'borderGray',
      color: 'gray600',
    },
    darkGray: {
      backgroundColor: 'gray300',
      color: 'gray600',
    },
    success: {
      color: 'success700',
      backgroundColor: 'success100',
    },
    primary: {
      color: 'primary300',
      backgroundColor: 'primary100',
    },
    warning: {
      color: 'warning700',
      backgroundColor: 'warning100',
    },
    error: {
      color: 'error600',
      backgroundColor: 'error100',
    },
    secondary: {
      color: 'gray600',
      backgroundColor: 'gray100',
    },
    notice: {
      color: 'skyBlue',
      backgroundColor: 'paleBlue',
    },
    success600: {
      backgroundColor: 'success600',
      color: 'white',
    },
    warning500: {
      backgroundColor: 'warning500',
      color: 'white',
    },
  },
}

const BadgeContainer = styled.div`
  font-weight: bold;
  font-size: 10px;
  line-height: 120%;
  padding: 4px 8px;
  border-radius: 5px;
  margin-right: 5px;
  width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${variant(badgeVariants)}
  ${layout}
  ${flex}
  ${space}
  ${typography}
`

// Badge with optional tooltip
const Badge = ({ variant = 'green', text, ...props }) => {
  const addTooltip = props.tooltipId && props.tooltipText
  return (
    <>
      <BadgeContainer
        variant={variant}
        {...(addTooltip && {
          'data-tooltip-content': '',
          'data-tooltip-id': props.tooltipId,
        })}
        {...props}
      >
        {text}
      </BadgeContainer>
      {props.tooltipText && (
        <ReactTooltip id={props.tooltipId}>{props.tooltipText}</ReactTooltip>
      )}
    </>
  )
}

export default Badge
