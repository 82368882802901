import React from 'react'

import { Flex } from 'app/components/design-system-components/index'
import { Heading } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const PageSubHeading = ({ title = 'Undefined', ...props }) => {
  return (
    <Flex
      alignItems="start"
      justifyContent="space-between"
      mb={theme.baseSpace * 3 + 'px'}
    >
      <Heading level={3} displayingLevel={3} {...props}>
        {title}
      </Heading>
    </Flex>
  )
}
