import React from 'react'

import PhotoListItemImage from 'app/sm/ppp/property_components/property_form/photos_list_item_image'

class PhotosListItem extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { photo, property, deletePhoto, setMainImage } = this.props
    return (
      <li key={photo.guidID}>
        <PhotoListItemImage
          photo={photo}
          property={property}
          deletePhoto={deletePhoto}
          setMainImage={setMainImage}
        />
        <input type="text" className="name" defaultValue={photo.name} />
      </li>
    )
  }
}

export default PhotosListItem
