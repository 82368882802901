import { useState } from 'react'

import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import Flex from 'app/components/design-system-components/Flex'
import KeyboardArrowDownRounded from 'app/components/design-system-components/icons/hardware/KeyboardArrowDownRounded'
import KeyboardArrowUpRounded from 'app/components/design-system-components/icons/hardware/KeyboardArrowUpRounded'

const StyledCard = styled(Box)`
  border-radius: ${({ theme }) => theme.radii[6]}px;
  line-height: ${({ theme }) => `${theme.lineHeights.copy}em`};
  border: 1px solid ${({ theme }) => `${theme.colors['moon-gray']}`};
  padding: ${({ theme }) => `${theme.space[5]}px ${theme.space[5]}px`};
  padding-bottom: 0;
  box-shadow: 5px 5px 12px -6px ${({ theme }) => `${theme.colors['moon-gray']}`};
  margin-bottom: ${({ theme }) => theme.space[8]}px;
`

const StyledHeader = styled(Flex)`
  border-bottom: ${(props) =>
    props.showBottomBorder ? '1px solid #cccccc' : 'none'};

  padding-bottom: ${({ theme }) => `${theme.space[4]}px`};
  font-weight: bold;
  font-size: ${({ theme }) => `${theme.fontSizes[8]}px`};
  line-height: 120%;
  align-items: center;
`

const StyledSVGContainer = styled(Box)`
  line-height: ${({ theme }) => theme.lineHeights.title};
  height: 18px;
  margin-bottom: 2px;
  margin-right: ${({ theme }) => `${theme.space[4]}px`};
`

const StyledInternalComponent = styled(Box)`
  padding: ${({ theme }) => theme.space[5]}px 0;
`

const StyledCollapse = styled(Box)`
  margin-left: auto;
`

function Card({
  headerTitle,
  headerImage,
  internalComponent,
  isCollapsible = false,
  forcedStyles = {},
  initialCollapsedState = false,
  forcedHeaderStyles = {},
  showHeaderBorder = true,
}) {
  const hasHeader = !!headerTitle
  const [isCollapsed, setIsCollapsed] = useState(initialCollapsedState)
  const headerBorder = showHeaderBorder && (!isCollapsed || !isCollapsible)
  return (
    <StyledCard style={forcedStyles}>
      {hasHeader && (
        <StyledHeader
          showBottomBorder={headerBorder}
          style={forcedHeaderStyles}
          onClick={() => isCollapsible && setIsCollapsed(!isCollapsed)}
        >
          {!!headerImage && (
            <StyledSVGContainer>{headerImage}</StyledSVGContainer>
          )}
          {headerTitle}
          {isCollapsible && (
            <StyledCollapse>
              {isCollapsed ? (
                <KeyboardArrowUpRounded />
              ) : (
                <KeyboardArrowDownRounded />
              )}
            </StyledCollapse>
          )}
        </StyledHeader>
      )}
      {isCollapsible ? (
        !isCollapsed && (
          <StyledInternalComponent>{internalComponent}</StyledInternalComponent>
        )
      ) : (
        <StyledInternalComponent>{internalComponent}</StyledInternalComponent>
      )}
    </StyledCard>
  )
}

export default Card
