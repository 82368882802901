import { connect } from 'react-redux'

import { fetchAdvertisement } from 'app/sm/advertisements/advertisement_actions'
import { OfferPublishingOptions } from 'app/sm/ppp/property_components/PublishingOptions/index'
import { steps, update } from 'app/sm/profile/profile_actions'

const mapStateToProps = ({ Profile, Advertisements, session }) => {
  const offer = Advertisements.advertisement || {}
  return {
    error: Profile.error,
    spinner: Profile.spinner,
    steps,
    offer,
    responseText: Profile.responseText,
    teams: session.teams,
  }
}

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(update(steps.PublishingOptions, data)),
  fetchOffer: (offerId) => fetchAdvertisement(offerId),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OfferPublishingOptions)
