import { connect } from 'react-redux'

import {
  clearError,
  createSupplierSource,
  fetchAllSupplierSources,
  fetchSupplierSource,
  updateSupplierSource,
} from 'app/bond_cover/agency/agency_actions'
import SupplierSourceFormContainer from 'app/bond_cover/agency/supplier_source_edit_form_container'

const mapStateToProps = ({ agency }) => ({
  error: agency.error,
  agency,
})

const mapDispatchToProps = (dispatch) => ({
  createSupplierSource: (supplierSource, success, failure) =>
    dispatch(createSupplierSource(supplierSource, success, failure)),
  updateSupplierSource: (
    supplierSourceGUID,
    supplierSource,
    success,
    failure,
  ) =>
    dispatch(
      updateSupplierSource(
        supplierSourceGUID,
        supplierSource,
        success,
        failure,
      ),
    ),
  fetchSupplierSource: (supplierSourceGUID, success) =>
    dispatch(fetchSupplierSource(supplierSourceGUID, success)),
  fetchAllSupplierSources: (success) =>
    dispatch(fetchAllSupplierSources(success)),
  clearError: () => dispatch(clearError()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupplierSourceFormContainer)
