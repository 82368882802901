import { PropertyStatuses, publishingOptions } from 'app/sm/helpers'
import { api } from 'app/utils/api/helpers'

export const updateOfferPublishingOptions = (id, offerPayload) => {
  return api.put(`/sm/offers/${id}/publishing-options`, offerPayload)
}

export const receivePublishOption = (offer) => {
  const { property } = offer || {}
  const { status: propertyStatus } = property || {}
  switch (propertyStatus) {
    case PropertyStatuses.takingApplications:
      return publishingOptions.PublishedOption

    case PropertyStatuses.offMarket:
      return publishingOptions.OffMarketOption

    case PropertyStatuses.leased:
      return publishingOptions.LeasedOption

    default:
      return publishingOptions.OffMarketOption
  }
}

export const updateOfferLeaseEndDate = (agencyGUID, offerGUID, requestBody) => {
  return api.put(
    `/sm/agency/${agencyGUID}/offers/${offerGUID}/leaseenddate`,
    requestBody,
  )
}

export const updateApplicationSettings = (agencyGUID, offerGUID, payload) => {
  return api.put(
    `/sm/agency/${agencyGUID}/offers/${offerGUID}/application-settings`,
    payload,
  )
}

export const updateOfferOnlistStatus = (agencyGUID, offerGUID, payload) => {
  return api.put(
    `/sm/agency/${agencyGUID}/offers/${offerGUID}/onliststatus`,
    payload,
  )
}

export const updateOfferHousingCategory = (offerGUID, category) => {
  return api.put(`/sm/offers/${offerGUID}/housing-category`, {
    housingCategory: parseInt(category),
  })
}

export const updateOfferBallots = (offerGUID, payload) => {
  return api.put(`/sm/offers/${offerGUID}/ballots`, payload)
}

export const listOfferAttachments = (offerGUID) => {
  return api.get(`/sm/offers/${offerGUID}/attachments`)
}
