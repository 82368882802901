import type { ReactNode } from 'react'

import styled, { StyledComponent } from 'styled-components'
import { flexbox, layout, space } from 'styled-system'

import Box from 'app/components/design-system-components/Box'

interface IStyledAnchorProps {
  inheritStyles: string
}

const StyledAnchor = styled.a<IStyledAnchorProps>`
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  :link,
  :visited,
  :hover,
  :active {
    color: inherit;
    text-decoration: inherit;
    font-weight: inherit;
  }
  ${(props) => props.inheritStyles}
  ${layout}
  ${flexbox}
  ${space}
`

interface IMaskedLinkProps {
  link: string
  disable: boolean
  LayoutWrapperCmp?: StyledComponent<any, any>
  children: ReactNode
}

export const MaskedWrappingLink = ({
  link,
  disable,
  LayoutWrapperCmp = Box,
  children,
  ...props
}: IMaskedLinkProps) => {
  if (disable) {
    return <LayoutWrapperCmp {...props}>{children}</LayoutWrapperCmp>
  }
  //FIXME: use a more proper way to access component styles when introduced
  const layoutStyles = LayoutWrapperCmp.componentStyle?.rules?.join('')
  return (
    <StyledAnchor href={link} inheritStyles={layoutStyles} {...props}>
      {children}
    </StyledAnchor>
  )
}
