import React from 'react'

import getParameter from 'app/shared_components/parameter_parser'
import PayContainer from 'app/shared_components/payment_form/pay_container'
import { history } from 'app/shared_components/router'
class PaymentContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cards: [{ id: 'New', number: 'New Credit Card' }],
      selectedCreditCard: 'New',
      acceptTermsAndConditions: false,
      error: '',
    }
  }

  UNSAFE_componentWillMount() {
    let { fetchRequestSummary } = this.props
    this.requestID = getParameter('requestid')
    fetchRequestSummary(this.requestID)
  }

  acceptTermsConditionsChange = () => {
    let { acceptTermsAndConditions } = this.state
    this.setState({ acceptTermsAndConditions: !acceptTermsAndConditions })
  }

  registerPayment = (data) => {
    let { registerPayment } = this.props
    registerPayment(Object.assign({}, data), this.requestID)
  }

  render() {
    let {
      quote,
      registerPayment,
      payWithExistingCard,
      verifyReferralCode,
      apiError,
    } = this.props
    let { cards, selectedCreditCard, acceptTermsAndConditions, error } =
      this.state

    return (
      <PayContainer
        cards={cards}
        selectedCreditCard={selectedCreditCard}
        acceptTermsAndConditions={acceptTermsAndConditions}
        payForWhat={'Pay for BondCover'}
        payText={'Save Payment Details'}
        amount={quote ? quote.premium : ''}
        buttonParole={`Pay $${quote ? quote.premium : ''} for BondCover`}
        acceptTermsConditionsChange={this.acceptTermsConditionsChange}
        registerPayment={this.registerPayment}
        showCards={false}
        autoSave={true}
        shouldShowReferralCode={true}
        backUrl={'/bc' + history.location.search}
        verifyReferralCode={verifyReferralCode}
        apiError={apiError}
      />
    )
  }
}

export default PaymentContainer
