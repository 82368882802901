import React from 'react'

import { history } from 'app/shared_components/router'

const NextButton = ({
  btnText,
  clickFunction,
  nextBtnId,
  nextBtnDisabled,
  nextBtnClass,
}) => (
  <button
    onClick={clickFunction}
    className={`btn float-right ${nextBtnClass}`}
    id={nextBtnId}
    disabled={nextBtnDisabled}
  >
    <span>{btnText}</span>
  </button>
)

export default NextButton
