import { connect } from 'react-redux'

import { addRentalReputationBackUrl } from 'app/shared_components/actions'
import { setBackUrl } from 'app/shared_components/actions'
import { fetchApplication } from 'app/sm/apply/apply_actions'
import {
  attachToProfile,
  continueAction,
  discardResult,
  fetchIdentityDocs,
  fetchPaymentAmount,
  receivePollingStopped,
  steps,
} from 'app/sm/background_check/background_check_actions'
import IdentityOverview from 'app/sm/background_check/identity_overview/container'
import {
  changeReputationPreference,
  fetchCompleteness,
  retrieveReputationPreference,
} from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({
  BackgroundCheck,
  Shared,
  bondCover,
  RentalReputation,
  apply,
  session,
}) => ({
  identityDoc: BackgroundCheck.identityDoc,
  identityDocList: BackgroundCheck.identityDocList,
  backgroundCheck: BackgroundCheck.backgroundCheck,
  spinner: BackgroundCheck.spinner,
  paymentAmount: BackgroundCheck.paymentAmount,
  steps,
  error: BackgroundCheck.error,
  backUrl: Shared.backUrl,
  completeness: RentalReputation.completeness,
  property: apply.property,
  reputationPreference: RentalReputation.preference,
  currentUser: session.currentUser,
  applicationDetails: apply.application,
})

const mapDispatchToProps = (dispatch) => ({
  fetchIdentityDocs: () => dispatch(fetchIdentityDocs()),
  continueAction: (currentStep) => dispatch(continueAction(currentStep)),
  fetchPaymentAmount: () => dispatch(fetchPaymentAmount()),
  receivePollingStopped: (isPollingStopped) =>
    dispatch(receivePollingStopped(isPollingStopped)),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
  addRentalReputationBackUrl: (rentalBackUrl) =>
    dispatch(addRentalReputationBackUrl(rentalBackUrl)),
  retrieveReputationPreference: () => dispatch(retrieveReputationPreference()),
  changeReputationPreference: (preference) =>
    dispatch(changeReputationPreference(preference)),
  discardResult: () => dispatch(discardResult()),
  attachToProfile: () => dispatch(attachToProfile()),
  fetchApplication: (applicationId) =>
    dispatch(fetchApplication(applicationId)),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IdentityOverview)
