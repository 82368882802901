import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import * as helpers from 'app/sm/helpers'

const HeaderApp = styled.header`
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  color: #fff;
  font-size: 14px;
  padding: 5px 0 6px;
  margin-bottom: 22px;
  .container {
    background: rgba(153, 153, 153, 0.5);
  }
`
const HeaderText = styled.div`
  width: 100% !important;
  @media print {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: rgba(153, 153, 153, 0.5) !important;
    color-adjust: exact !important;
    font-size: 14px;
    padding: 5px 0 6px;
    margin-bottom: 22px;
  }
`

const PropHeading = styled.h2`
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #fff !important;
`

const PropTime = styled.time`
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #fff !important;
`

const Header = () => {
  const today = moment().format(helpers.dateWithWeekDayTimeFormat)
  return (
    <HeaderApp className="header">
      <div className="container">
        <HeaderText className="headertext d-flex justify-between">
          <PropHeading>Rental Properties</PropHeading>
          <PropTime>{today}</PropTime>
        </HeaderText>
      </div>
    </HeaderApp>
  )
}

export default Header
