import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import applicationDialogue from 'app/assets/icons/application_icon_dialogue.svg'
import applicationDialogueEmpty from 'app/assets/icons/application_icon_dialogue_empty.svg'
import applicationApprove from 'app/assets/icons/application_note_approve.svg'
import applicationApproveEmpty from 'app/assets/icons/application_note_approve_empty.svg'
import applicationDecline from 'app/assets/icons/application_note_decline.svg'
import applicationDeclineEmpty from 'app/assets/icons/application_note_decline_empty.svg'
import ApplicationNoteFlatStyle from 'app/components/display/application_note/component_flatstyle'
import * as HOC from 'app/components/highOrderComponents/highOrderComponents'
import { noteActionType } from 'app/sm/helpers'

const NoteButtons = ({ onNoteIconClicked, noteStatus }) => {
  const { approve, decline, comment } = noteActionType
  var approvalLastNote, declineLastNote, commentLastNote
  if (noteStatus && noteStatus.categorizedLastNotes) {
    approvalLastNote = noteStatus.categorizedLastNotes[approve]
    declineLastNote = noteStatus.categorizedLastNotes[decline]
    commentLastNote = noteStatus.categorizedLastNotes[comment]
  }

  return noteStatus ? (
    <span className="action-button-container display-flex pdf-hide">
      <div
        className="mr20"
        onClick={() => onNoteIconClicked(approve)}
        role="button"
        tabIndex={0}
      >
        <img
          data-tooltip-content=""
          data-tooltip-id={approvalLastNote && approvalLastNote.guid_id}
          src={
            noteStatus.type === approve
              ? applicationApprove
              : applicationApproveEmpty
          }
          alt="approve"
        />
        {!!approvalLastNote && (
          <ReactTooltip
            id={approvalLastNote.guid_id}
            place="bottom"
            className="tool-tip-style"
          >
            <ApplicationNoteFlatStyle
              note={approvalLastNote}
              key={approvalLastNote.guid_id}
              componentClass="text-white"
              infoTextClass="text-white"
            />
          </ReactTooltip>
        )}
      </div>
      <div
        className="mr20"
        onClick={() => onNoteIconClicked(decline)}
        role="button"
        tabIndex={0}
      >
        <img
          data-tooltip-content=""
          data-tooltip-id={declineLastNote && declineLastNote.guid_id}
          src={
            noteStatus.type === decline
              ? applicationDecline
              : applicationDeclineEmpty
          }
          alt="decline"
        />
        {!!declineLastNote && (
          <ReactTooltip
            id={declineLastNote.guid_id}
            place="bottom"
            className="tool-tip-style"
          >
            <ApplicationNoteFlatStyle
              note={declineLastNote}
              key={declineLastNote.guid_id}
              componentClass="text-white"
              infoTextClass="text-white"
            />
          </ReactTooltip>
        )}
      </div>
      <div
        onClick={() => onNoteIconClicked(comment)}
        role="button"
        tabIndex={0}
      >
        <img
          data-tooltip-content=""
          data-tooltip-id={commentLastNote && commentLastNote.guid_id}
          src={
            noteStatus.hasComment
              ? applicationDialogue
              : applicationDialogueEmpty
          }
          alt="add comment"
        />
        {!!commentLastNote && (
          <ReactTooltip
            id={commentLastNote.guid_id}
            place="bottom"
            className="tool-tip-style"
          >
            <ApplicationNoteFlatStyle
              note={commentLastNote}
              key={commentLastNote.guid_id}
              componentClass="text-white"
              infoTextClass="text-white"
            />
          </ReactTooltip>
        )}
      </div>
    </span>
  ) : null
}
export default HOC.ResponseStatusWrapper('Notes')(NoteButtons)
