import React from 'react'

import * as Form from 'app/components/forms/forms'
import { isAustralianAddress } from 'app/sm/helpers'
import {
  countriesListAlpha3,
  getCountryCodeBasedFromName,
} from 'app/utils/country/helpers'

const RentalHistoryAddressForm = ({
  address = {},
  placeholder,
  update,
  clear,
  onBlur,
  inputDisabled = false,
  error,
  suggest,
  toggleSuggest,
  country = address.country || 'AUS',
  showSuggestAddress = true,
  updateAddressText,
}) => {
  let countryCode = country
  if (countriesListAlpha3.indexOf(country) < 0) {
    countryCode = getCountryCodeBasedFromName(country)
  }
  return (
    <div className="input-address-manual" onBlur={onBlur}>
      <div className="row">
        <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
          <div className="col-sm-6">
            <Form.InputTextRequired
              required
              id="unitNumber"
              disabled={inputDisabled}
              value={address.unitNumber}
              onChange={updateAddressText('unitNumber')}
              autoComplete="off"
              label="Unit Number"
              inputClass="width100"
            />
          </div>
          <div className="col-sm-6">
            <Form.InputTextRequired
              required
              id="unitNumber"
              disabled={inputDisabled}
              value={address.streetNumber}
              onChange={updateAddressText('streetNumber')}
              autoComplete="off"
              label="Street Number"
              inputClass="width100"
            />
          </div>
        </Form.ProfilePagesRowLayout>
      </div>

      <div className="row">
        <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
          <div className="col-sm-6">
            <Form.InputTextRequired
              required
              id="streetName"
              disabled={inputDisabled}
              value={address.streetName}
              onChange={updateAddressText('streetName')}
              autoComplete="off"
              label="Street Name"
              inputClass="width100"
            />
          </div>

          <div className="col-sm-6">
            <Form.InputTextRequired
              required
              id="address2"
              disabled={inputDisabled}
              value={address.address2}
              onChange={updateAddressText('address2')}
              autoComplete="off"
              label="Street address 2"
              inputClass="width100"
            />
          </div>
        </Form.ProfilePagesRowLayout>
      </div>

      <div className="row">
        <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
          <div className="col-sm-6">
            <Form.InputTextRequired
              required
              id="suburb"
              disabled={inputDisabled}
              value={address.suburb}
              onChange={updateAddressText('suburb')}
              autoComplete="off"
              label="Suburb"
              inputClass="width100"
            />
          </div>
          <div className="col-sm-6">
            <Form.InputTextRequired
              id="postcode"
              disabled={inputDisabled}
              value={address.postcode}
              onChange={updateAddressText('postcode')}
              label="Postcode"
              inputClass="width100"
            />
          </div>
        </Form.ProfilePagesRowLayout>
      </div>

      <div className="row">
        <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
          <div className="col-sm-6">
            {address.country && isAustralianAddress(address) ? (
              <div>
                <Form.DropdownState
                  componentClass={'width100'}
                  name="State"
                  value={address.state}
                  onChange={update('state')}
                  firstOption="State"
                />
              </div>
            ) : (
              <Form.InputTextRequired
                required
                id="state"
                disabled={inputDisabled}
                value={address.state}
                onChange={updateAddressText('state')}
                autoComplete="off"
                label="State/Province/Region"
                inputClass="width100"
                componentClass={'width100'}
              />
            )}
          </div>
          <div className="col-sm-6">
            <Form.DropdownCountry
              name="Country"
              componentClass={'mb30 width100'}
              value={countryCode}
              onChange={update('country')}
              placeholder="Select country"
            />
          </div>
        </Form.ProfilePagesRowLayout>
      </div>
    </div>
  )
}

export default RentalHistoryAddressForm
