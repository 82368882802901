import { FC, useEffect, useState } from 'react'

import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'
import styled from 'styled-components'

import {
  Box,
  Button,
  Flex,
  Modal,
} from 'app/components/design-system-components'
import { AttachmentRounded } from 'app/components/design-system-components/icons/file'
import { PersonOutlineRounded } from 'app/components/design-system-components/icons/social'
import { fontWeight } from 'app/components/design-system-components/styles/variables'
import { StyledEllipse } from 'app/components/property/PropertySummary'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { formatPhoneNumber } from 'app/utils/phonenumber/helpers'
import { trimAndAddEllipsesPastSpecifiedLength } from 'app/utils/strings/helpers'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

interface IDocumentViewerAttachments {
  url: string
  name: string
  typeOfDocument?: string
  guidID: string
}

interface IDocumentViewerProps {
  toggleDocumentsViewer: () => void
  currentApplicantFullName?: string
  currentApplicantEmail?: string
  currentApplicantPhone?: string
  documentViewerAttachments: IDocumentViewerAttachments[]
  documentsViewerGUID: string
}

const StyledDocument = styled(Box)`
  border: 1px solid ${theme.colors.gray400};
  margin-top: ${theme.space[4]}px;
`

const DocumentsViewer: FC<IDocumentViewerProps> = ({
  currentApplicantFullName = '',
  currentApplicantEmail = '',
  currentApplicantPhone = '',
  toggleDocumentsViewer,
  documentViewerAttachments,
  documentsViewerGUID,
}) => {
  const totalAttachments = documentViewerAttachments?.length
  const [currentNumAttachment, setCurrentNumAttachment] = useState(0)
  useEffect(() => {
    if (documentsViewerGUID) {
      const selectedAttachment =
        documentViewerAttachments.filter((documentViewerAttachment) => {
          return documentViewerAttachment.guidID === documentsViewerGUID
        }) || documentViewerAttachments[0]
      if (selectedAttachment && selectedAttachment.length > 0) {
        const selectedAttachmentIndex = documentViewerAttachments.indexOf(
          selectedAttachment[0]!,
        )
        setCurrentNumAttachment(selectedAttachmentIndex)
      }
    }
  }, [documentViewerAttachments, documentsViewerGUID])
  const nextAttachment = () => {
    if (currentNumAttachment < totalAttachments)
      setCurrentNumAttachment(currentNumAttachment + 1)
  }
  const prevAttachment = () => {
    if (currentNumAttachment > 0)
      setCurrentNumAttachment(currentNumAttachment - 1)
  }
  const [numPages, setNumPages] = useState(null)

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages)
  }

  const currentAttachment =
    documentViewerAttachments && documentViewerAttachments[currentNumAttachment]
  const typeOfAttachment = currentAttachment?.name.split('.')[1]
  const attachmentFileName = currentAttachment?.name.split('.')[0]
  const attachmentFileNameToDisplay = trimAndAddEllipsesPastSpecifiedLength(
    attachmentFileName,
    30,
  )
  let atttachmentRender = <Box></Box>
  if (typeOfAttachment === 'pdf') {
    atttachmentRender = (
      <>
        <Document
          file={{
            url: currentAttachment?.url,
          }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.apply(null, Array(numPages))
            .map((_, i) => i + 1)
            .map((page) => (
              <StyledDocument>
                <Page pageNumber={page} />
              </StyledDocument>
            ))}
        </Document>
      </>
    )
  } else {
    atttachmentRender = (
      <StyledDocument>
        <img
          src={currentAttachment?.url || ''}
          alt="attachment rendered"
          width="100%"
        />
      </StyledDocument>
    )
  }
  const applicantDetails = (
    <Flex alignItems="center">
      <PersonOutlineRounded color={theme.colors.gray500} />{' '}
      <Box color={theme.colors.gray800} ml={2} fontWeight={fontWeight[4]}>
        {currentApplicantFullName}
      </Box>
      <Box mr={2} ml={2} fontSize={theme.fontSizes.pRegular14}>
        <a href={`tel:${currentApplicantPhone}`}>
          {formatPhoneNumber(currentApplicantPhone)}
        </a>
      </Box>
      <StyledEllipse />
      <Box ml={2} fontSize={theme.fontSizes.pRegular14}>
        <a href={`mailTo:${currentApplicantPhone}`}>{currentApplicantEmail}</a>
      </Box>
    </Flex>
  )
  const modalBody = (
    <Box width="100%">
      <Flex justifyContent="space-between">
        <Box>
          <Box mb={5}>{applicantDetails}</Box>
          <Flex alignItems="center">
            <AttachmentRounded color={theme.colors.gray800} />
            <Flex ml={2} alignItems="center">
              <Box
                color={theme.colors.skyBlue}
                onClick={() => window.open(currentAttachment?.url)}
                style={{ cursor: 'pointer' }}
              >
                {attachmentFileNameToDisplay}.{typeOfAttachment}
              </Box>
              <Box ml={2}>({currentAttachment?.typeOfDocument})</Box>
            </Flex>
          </Flex>
        </Box>
        <Box textAlign="right">
          <Button
            sizeVariant="extraSmall"
            variant="outlineSuccess"
            onClick={() => {
              window.open(currentAttachment?.url)
              // TODO: Add Print Function
              // wind.onload = function () {
              //   console.log('page is fully loaded')
              //   wind.print()
              // }
            }}
          >
            Print
          </Button>
          <Box mt={2}>
            {currentNumAttachment + 1} of {totalAttachments} attachments
          </Box>
        </Box>
      </Flex>
      <Box mt={5}>{atttachmentRender}</Box>
    </Box>
  )

  const showPrimaryButton = currentNumAttachment < totalAttachments - 1
  const showSecondaryButton = currentNumAttachment > 0
  return (
    <Box>
      <Modal
        modalHeading="Attachments"
        modalBody={modalBody}
        modalFooter={undefined}
        primaryLabel="Next"
        primaryAction={nextAttachment}
        secondaryLabel="Previous"
        secondaryAction={prevAttachment}
        toggleModal={toggleDocumentsViewer}
        primaryButtonDisabled={!showPrimaryButton}
        secondaryButtonDisabled={!showSecondaryButton}
        takeIncreasedWidth
        contentClass="min-height-78vh"
      />
    </Box>
  )
}

export default DocumentsViewer
