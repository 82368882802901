import React from 'react'

import iconErrorRed from 'app/assets/icons/error-red.svg'
import * as Text from 'app/components/display/text/text_component'
import { LabelWrapper } from 'app/components/forms/util'

import 'app/components/forms/radio_group/style.scss'

export const YesOrNoRadioGroupComponent = ({
  label,
  error,
  children,
  childrenWrapperClassName,
  required = false,
  labelClass,
}) => {
  return (
    <div>
      {label && (
        <LabelWrapper required={required}>
          <Text.Strong text={label} textClass={labelClass} />
        </LabelWrapper>
      )}
      <div
        className={`form-radio-group-controls display-flex border-bottom-none ${childrenWrapperClassName}`}
      >
        {children}
      </div>

      {error && (
        <>
          <img className="ibm pr10" src={iconErrorRed} height="25" alt="" />
          <error>{error}</error>
        </>
      )}
    </div>
  )
}

export const YesOrNoCheckboxComponent = ({
  label,
  checked,
  onChange,
  id,
  readOnly,
  componentClassName = '',
  enableEdit = true,
  error = '',
}) => {
  return (
    <label
      onClick={readOnly ? undefined : onChange}
      id={id}
      className={`${componentClassName} ${
        !enableEdit && 'disabled-link'
      } display-flex flex width100 justify-content-center align-items-center radio-select-option-container height-64 ${
        checked ? 'picked' : ''
      }`}
    >
      {label}
      {error && <error>{error}</error>}
    </label>
  )
}
