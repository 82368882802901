import React from 'react'

import greenTick from 'app/assets/icons/green-filled-checked.svg'
import { Alert, Box, Flex } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import * as constants from 'app/sm/onlist_details/ownerPropertyDisclosure/helpers/constants'
import { formatStdTimeStamp } from 'app/utils/datetime/helpers'

function SendVariants({ variantNum, owners, disclosureResponse, completer }) {
  const completedAt = disclosureResponse?.submissionTime
    ? formatStdTimeStamp(disclosureResponse.submissionTime)
    : ''
  const requestedBy = disclosureResponse && disclosureResponse.requestor
  const requestedAt =
    disclosureResponse &&
    disclosureResponse.requestTime &&
    formatStdTimeStamp(disclosureResponse.requestTime)
  const owner = owners && owners.join(', ')
  switch (variantNum) {
    case constants.DISCLOSURE_UNFINISHED:
    default:
      return (
        <Box my="16px">
          <Alert variant="greyWithBg">
            <Flex width="100%" justifyContent="space-between">
              <Box>No Property Disclosures</Box>
              <Box> Manually upload or request from the owner</Box>
            </Flex>
          </Alert>
        </Box>
      )

    case constants.DISCLOSURE_COMPLETED:
      return (
        <Box my="16px">
          <Alert variant="greyWithBg">
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>Completed</Box>
              <Box>
                <Flex alignItems="center">
                  <Box style={{ textAlign: 'right' }}>
                    <Box>By {completer || owner}</Box>
                    <Box>at {completedAt}</Box>
                  </Box>
                  <Box ml="16px">
                    <img src={greenTick} alt="submitted" />
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Alert>
        </Box>
      )

    case constants.DISCLOSURE_REQUESTED:
      return (
        <Box my="16px">
          <Alert variant="greyWithBg">
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex alignItems="center">
                <Box>Requested</Box>
                <Box ml="8px">
                  <Badge text="Sent" />
                </Box>
              </Flex>
              <Box style={{ textAlign: 'right' }}>
                <Box>From {owner}</Box>
                <Box>by {requestedBy}</Box>
                <Box>at {requestedAt}</Box>
              </Box>
            </Flex>
          </Alert>
        </Box>
      )
  }
}

export default SendVariants
