import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  getPropertyReport,
  getPublicPropertyReport,
  postPropertyReport,
} from 'app/match/propertyReport/access'

export const fetchPropertyReport = (viewingId) => {
  return () => {
    return getPropertyReport(viewingId).then(({ ok, result, responseText }) => {
      if (ok) {
        return Promise.resolve({
          ...result,
          assignedTeamMembers: result.assignedTeamMembers || [],
        })
      }
      return responseText.then((t) => {
        return Promise.reject(translateErrorCodeToMessage(t))
      })
    })
  }
}

export const fetchPublicPropertyReport = (propertyReportId) => {
  return () => {
    return getPublicPropertyReport(propertyReportId).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve({
            ...result,
            assignedTeamMembers: result.assignedTeamMembers || [],
          })
        }
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      },
    )
  }
}

export const sendViewingReport = (payload) => {
  return () => {
    return postPropertyReport(payload).then(({ ok, result, responseText }) => {
      if (ok) {
        return Promise.resolve(result)
      }
      return responseText.then((t) => {
        return Promise.reject(translateErrorCodeToMessage(t))
      })
    })
  }
}
