import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

const propTypes = {
  title: PropTypes.string,
}

const Heading = styled.h3`
  font-family: Cera PRO;
  color: #212025;
  margin: 0 0 5px;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
`

function WidgetHeading({ title }) {
  return <Heading>{title}</Heading>
}

WidgetHeading.propTypes = propTypes

export default WidgetHeading
