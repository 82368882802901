import React, { useState } from 'react'

import moment from 'moment'
import styled from 'styled-components'

import disclosureIcon from 'app/assets/icons/summary_icons/attachment.svg'
import { Box, Flex } from 'app/components/design-system-components'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import * as Text from 'app/components/display/text/text_component'
import * as Form from 'app/components/forms/forms'
import { DIRECT_CONNECT_PROVIDER_NAME } from 'app/match/apply/application_summary_before_submit/application_summary_before_submit.constants'
import DirectConnectTermsModal from 'app/match/apply/application_summary_before_submit/components/disclosure_consent_container/direct_connection_terms_modal'
import {
  Yes,
  yesOrNoDisclosureQuestions,
  YesOrNoOptions,
} from 'app/match/apply/application_summary_before_submit/components/disclosure_consent_container/disclosure_questions.constants'
import UberCompContainer from 'app/match/apply/application_summary_before_submit/components/uber_comp_container/component'
import Checkbox from 'app/shared_components/checkbox'
import * as FormComponent from 'app/shared_components/form_component'
import {
  CONSUMER_GUIDE_URL,
  DISCLOSURE_AUTHORITY_TEMPLATE,
  DISCRIMINATION_STATEMENT_URL,
  parseDataUtil,
  PETS_ARTICLE_URL,
} from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import { EASY_BONDPAY_LEARN_MORE_LINK } from 'app/utils/easybondpay/helpers'
import { getUtilityProviderDisclosureConsent } from 'app/utils/utilityprovider/helpers'

export const SingleAcceptanceType = 0
export const PhysicalAndRemoteAcceptanceType = 1

export const InspectionTypeNotSet = 0
export const InspectionTypePhysical = 1
export const InspectionTypeRemote = 2
export const INSPECTION_TYPE_PRE_APPLY = 3

const StyledDetailsContainer = styled(Box)`
  .more-details-text::after {
    color: red;
    content: ' *';
  }
`

const DisclosureConsentContainer = ({
  isPrimaryApplicant = false,
  optedInAsSmoker = false,
  toggleOptedInAsSmoker,
  componentClassName = '',
  optedToAcceptCondition = false,
  optedToAcceptDisclosureConsent = true,
  optedToReceiveUtilityConnection = true,
  optedToReceiveRayWhiteConciergeService = false,
  optedToReceiveBondLoanInformation = false,
  optedInToPetConsent = false,
  optedToReceiveEasyBondpay = false,
  directConnectTermsViewed = false,
  utilityProviderName = '',
  teamSettingInfo = {},
  toggleDisclosureOptions,
  onChangeInspectionType,
  onPropertyInspectedDateUpdate,
  optedToRequirePostingClause = false,
  conditionAcceptanceType = PhysicalAndRemoteAcceptanceType,
  propertyInspectionType = InspectionTypeNotSet,
  propertyInspectedDate,
  error,
  pets = [],
  propertyOffer = {},
  onChangeDisclosureYesOrNoQuestions,
  disclosureYesOrNoAnswers,
  teamName,
  submittedDate,
  appliedAt,
  allowApplicantToSkipViewingDisclosures = false,
  optedToSkipViewingDisclosures = false,
}) => {
  const [showDirectConnectTermsModal, setShowDirectConnectTermsModal] =
    useState(false)

  const { application = {}, integrations = {} } = teamSettingInfo

  const parsedTeamSettingInfo =
    parseDataUtil.convertObjValueFromStringToBoolean(application)

  const parsedIntegrationSettingInfo =
    parseDataUtil.convertObjValueFromStringToBoolean(integrations)
  // integrations.ray_white_concierge_enabled comes from BE as a string ('true' or 'false')
  const rayWhiteEnabled =
    integrations.ray_white_concierge_enabled === 'true' ||
    integrations.ray_white_concierge_enabled === true
  const {
    display_bond_loan_enabled: displayBondLoadEnabled,
    posting_clause_enabled: postingClauseEnabled,
    smoker_declaration_enabled: smokerDeclarationEnabled,
    enable_pre_apply: preApplyEnabled,
    enable_enhanced_disclosure: enableEnhancedDisclosure,
  } = parsedTeamSettingInfo
  const {
    requirePreApplyBeforeViewing = false,
    overrideRequirePreApplyBeforeViewing = false,
  } = propertyOffer
  const preApplyEnabledForAgencyOrProperty =
    overrideRequirePreApplyBeforeViewing
      ? requirePreApplyBeforeViewing
      : preApplyEnabled
  const { easy_bond_pay_enabled: easyBondyPayEnabled } =
    parsedIntegrationSettingInfo

  const displayPhysicalAndRemoteAcceptance =
    conditionAcceptanceType === PhysicalAndRemoteAcceptanceType
  const displaySingleAcceptance =
    conditionAcceptanceType === SingleAcceptanceType
  const physicalInspectionSelected =
    optedToAcceptCondition && propertyInspectionType === InspectionTypePhysical
  const remoteInspectionSelected =
    optedToAcceptCondition && propertyInspectionType === InspectionTypeRemote
  const preApplySelected =
    optedToAcceptCondition &&
    propertyInspectionType === INSPECTION_TYPE_PRE_APPLY
  const formattedPropertyInspectedDate =
    propertyInspectedDate && error !== dateTimeHelpers.INVALID_DATE_STRING
      ? moment(propertyInspectedDate).format(dateTimeHelpers.DATE_WITH_SLASH)
      : propertyInspectedDate

  const renderDirectConnectLabel = () => {
    return (
      <div>
        Yes, I consent to my personal information being provided to{' '}
        {utilityProviderName}, including name, address, email and phone number,
        for the purpose of contacting me to connect electricity, gas and other
        services in my new home in accordance with the Data Sharing and Terms (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.preventDefault()
            toggleDirectConnectTermsModal()
          }}
        >
          Link
        </a>
        ). If 'No', you won't be contacted about utilities and connection
        services. Direct Connect will automatically process water connection
        services for successful applicants based in Victoria.
        {dateTimeHelpers.isShowUberComp(submittedDate, appliedAt) && (
          <UberCompContainer />
        )}
      </div>
    )
  }

  const toggleDirectConnectTermsModal = () => {
    setShowDirectConnectTermsModal(!showDirectConnectTermsModal)
    onTermsViewed()
  }

  const onTermsViewed = () => {
    if (!directConnectTermsViewed) {
      toggleDisclosureOptions('directConnectTermsViewed')()
    }
  }

  const directConnectLabel = renderDirectConnectLabel()

  const checkboxes = [
    {
      label: getUtilityProviderDisclosureConsent(utilityProviderName),
      value: optedToReceiveUtilityConnection,
      enabled:
        isPrimaryApplicant &&
        utilityProviderName !== DIRECT_CONNECT_PROVIDER_NAME,
      field: 'optedToReceiveUtilityConnection',
      componentClassName: '',
      customToggle: undefined,
      id: '',
    },
    {
      label: directConnectLabel,
      value: optedToReceiveUtilityConnection,
      enabled:
        isPrimaryApplicant &&
        utilityProviderName === DIRECT_CONNECT_PROVIDER_NAME,
      field: 'optedToReceiveUtilityConnection',
      componentClassName: '',
      customToggle: undefined,
      id: '',
    },
    {
      label: `Yes, I'd love to be contacted by Ray White Concierge about Contents Insurance for my rental property, removal assistance, gift vouchers for artwork, discounts for spa treatments and more.`,
      value: optedToReceiveRayWhiteConciergeService,
      enabled: rayWhiteEnabled,
      field: 'optedToReceiveRayWhiteConciergeService',
      componentClassName: '',
      customToggle: undefined,
      id: '',
    },
    {
      label: 'I will require a posting clause',
      value: optedToRequirePostingClause,
      enabled: isPrimaryApplicant && postingClauseEnabled,
      field: 'optedToRequirePostingClause',
      componentClassName: 'mobile-utility-checkbox-min-height',
      customToggle: undefined,
      id: '',
    },
    {
      label: 'I will need a Bond Loan',
      value: optedToReceiveBondLoanInformation,
      enabled: isPrimaryApplicant && displayBondLoadEnabled,
      field: 'optedToReceiveBondLoanInformation',
      componentClassName: '',
      customToggle: undefined,
      id: '',
    },
    {
      label: 'I am a smoker',
      value: optedInAsSmoker,
      enabled: smokerDeclarationEnabled,
      field: 'optedToReceiveBondLoanInformation',
      componentClassName: '',
      customToggle: toggleOptedInAsSmoker,
      id: '',
    },
  ]

  const buildLinkElem = (body, url) => (
    <a target="_blank" rel="noopener noreferrer" href={url}>
      {body}
    </a>
  )

  const disclosureStatement = (
    <div>
      I accept the Personal Information Collection and{' '}
      {buildLinkElem('Disclosure Authority', DISCLOSURE_AUTHORITY_TEMPLATE)} and
      have received the {buildLinkElem('Consumer Guide', CONSUMER_GUIDE_URL)},{' '}
      {buildLinkElem('Discrimination Statement', DISCRIMINATION_STATEMENT_URL)}{' '}
      and Property Disclosures
    </div>
  )

  return (
    <div className={`container-component ${componentClassName}`}>
      <ContainerHeader
        icon={disclosureIcon}
        text="Disclosures"
        isEditable={false}
      />
      <>
        <Checkbox
          label={disclosureStatement}
          value={optedToAcceptDisclosureConsent}
          onChange={toggleDisclosureOptions('optedToAcceptDisclosureConsent')}
          componentClassName="mt30"
          spanClassName="gray-color fs16 height-auto width100"
        />

        <div>
          {checkboxes.map((checkbox, index) => {
            return (
              <div key={index}>
                {checkbox.enabled && (
                  <Checkbox
                    label={checkbox.label}
                    value={checkbox.value}
                    onChange={
                      checkbox.customToggle
                        ? checkbox.customToggle
                        : toggleDisclosureOptions(checkbox.field)
                    }
                    componentClassName={`mt30 ${checkbox.componentClassName}`}
                    spanClassName="gray-color fs16 height-auto width100"
                    id={checkbox.id}
                  />
                )}
              </div>
            )
          })}
          {isPrimaryApplicant && easyBondyPayEnabled && (
            <div>
              <Checkbox
                label={
                  <div>
                    I would like easyBondpay.{' '}
                    <a
                      href={EASY_BONDPAY_LEARN_MORE_LINK}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {' '}
                      Learn more
                    </a>
                    .
                  </div>
                }
                value={optedToReceiveEasyBondpay}
                onChange={toggleDisclosureOptions('optedToReceiveEasyBondpay')}
                componentClassName="mt30"
                spanClassName="gray-color fs16 height-auto width100"
              />
            </div>
          )}
          <div>
            <Checkbox
              label={
                <div>
                  I acknowledge that accepting a pet is at the property owner's
                  discretion in accordance with&nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={PETS_ARTICLE_URL}
                  >
                    residential tenancy regulations
                  </a>
                </div>
              }
              value={optedInToPetConsent}
              onChange={toggleDisclosureOptions('optedInToPetConsent')}
              componentClassName="mt30"
              spanClassName="gray-color fs16 height-auto width100"
            />
          </div>
          {displaySingleAcceptance && (
            <Checkbox
              label="I have inspected and accept the property condition"
              value={optedToAcceptCondition}
              onChange={toggleDisclosureOptions('optedToAcceptCondition')}
              componentClassName="mt30"
            />
          )}

          {enableEnhancedDisclosure && (
            <Flex mt="30px" flexDirection="column">
              {yesOrNoDisclosureQuestions.map((question) => (
                <Box mb="12px">
                  <div className="mb30">
                    <Text.Strong
                      text={question.label.replace('[Team Name]', teamName)}
                    />
                    <Form.MultiSelect
                      options={[...YesOrNoOptions].map((option) => ({
                        ...option,
                        picked:
                          disclosureYesOrNoAnswers[question.id] ===
                          option.value,
                      }))}
                      className="p0"
                      onChange={(answer) => {
                        onChangeDisclosureYesOrNoQuestions(question.id, answer)
                      }}
                      bottomSpacingClass="pb0"
                    />
                  </div>

                  {question.hasMoreDetails &&
                    disclosureYesOrNoAnswers[question.id] === Yes && (
                      <StyledDetailsContainer mt="12px">
                        <Text.Strong
                          text={'Please provide more details'}
                          textClass="more-details-text"
                        />
                        <Form.CommentArea
                          value={
                            disclosureYesOrNoAnswers[question.moreDetailsId]
                          }
                          placeholder={'Details ... '}
                          onChange={({ value }) =>
                            onChangeDisclosureYesOrNoQuestions(
                              question.moreDetailsId,
                              value,
                            )
                          }
                          id={question.moreDetailsId}
                          rows={5}
                          inputBoxClass="pt0"
                          required={true}
                        />
                      </StyledDetailsContainer>
                    )}
                </Box>
              ))}
            </Flex>
          )}
        </div>
      </>

      {!allowApplicantToSkipViewingDisclosures ? (
        <>
          {displayPhysicalAndRemoteAcceptance && (
            <div className="flex-direction-column p0 mt30">
              <FormComponent.RadioGroup
                label="I accept the property condition as presented:"
                id="propertyCondition"
                field="propertyCondition"
                componentClassName="physically-inspected pr0 mb0 mt0"
              >
                <FormComponent.Checkbox
                  id="true"
                  label={`I have physically inspected on this date`}
                  checked={physicalInspectionSelected}
                  onChange={onChangeInspectionType(InspectionTypePhysical)}
                  checkboxLabelSpanClassName="fs16"
                  componentClassName={'mr8 mb20 float-left'}
                  className="flex-direction-column"
                />
                <Form.InputPastDate
                  componentClass={`inspected-date float-left mb35`}
                  label=""
                  viewMode="days"
                  onChange={onPropertyInspectedDateUpdate('date')}
                  value={formattedPropertyInspectedDate}
                  customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                  readOnly={false}
                  error={error}
                />

                <FormComponent.Checkbox
                  id="false"
                  label="I inspected online and indemnify the agent/Snug from related claims"
                  checked={remoteInspectionSelected}
                  onChange={onChangeInspectionType(InspectionTypeRemote)}
                  checkboxLabelSpanClassName="fs16"
                  componentClassName={'mr0 width100 mb20'}
                />
                {preApplyEnabledForAgencyOrProperty && (
                  <FormComponent.Checkbox
                    label="I am Pre-Applying and accept the property as presented online, pending an inspection"
                    checked={preApplySelected}
                    onChange={onChangeInspectionType(INSPECTION_TYPE_PRE_APPLY)}
                    checkboxLabelSpanClassName="fs16"
                    componentClassName={'mr0 width100'}
                  />
                )}
              </FormComponent.RadioGroup>
            </div>
          )}
        </>
      ) : (
        <>
          <FormComponent.RadioGroup
            label="I accept the property condition as presented:"
            id="propertyCondition"
            field="propertyCondition"
            componentClassName="physically-inspected pr0 mb0 mt0"
          >
            <Checkbox
              label="I have not inspected and indemnify the agent/Snug from related claims"
              value={optedToSkipViewingDisclosures}
              onChange={toggleDisclosureOptions(
                'optedToSkipViewingDisclosures',
              )}
              spanClassName="gray-color fs16 height-auto width100"
            />
          </FormComponent.RadioGroup>
        </>
      )}
      <DirectConnectTermsModal
        toggleModal={toggleDirectConnectTermsModal}
        showDirectConnectTermsModal={showDirectConnectTermsModal}
        onTermsViewed={onTermsViewed}
      />
    </div>
  )
}

export default DisclosureConsentContainer
