import React from 'react'

import petIcon from 'app/assets/icons/summary_icons/pet.svg'
import { Box } from 'app/components/design-system-components'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import SummaryPetsDetailsBox from 'app/match/apply/application_summary_before_submit/shared_components/pet_box/component'
import { urlTo } from 'app/sm/helpers'

const PetsContainer = ({
  componentClassName = '',
  pets = [],
  applicantName,
  onEditClick,
  isPetsEditable = true,
  // is displayed for a renter (not a PM)
  isRenterDisplay = false,
  optOutPets = false,
  enableNote = false,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  displayedApplicant,
  section,
  id = '',
  toggleRequestInfoModal = undefined,
  toggleDocumentsViewer = undefined,
}) => {
  const optOutPetsNote = isRenterDisplay ? (
    <>
      You have noted you <b>do not have any pets</b>
    </>
  ) : (
    <>
      <b>{applicantName}</b> has noted they <b>do not have any pets</b>
    </>
  )
  return (
    <div id={id} className={`container-component ${componentClassName}`}>
      <ContainerHeader
        icon={petIcon}
        text="Pet Details"
        isEditable={isPetsEditable}
        onEditClick={() =>
          onEditClick(`${urlTo('PetDetails')}?from=rentersummary`)
        }
        enableNote={enableNote}
        onNewNoteUpdated={onNewNoteUpdated}
        newNotesContent={newNotesContent}
        onNoteIconClicked={onNoteIconClicked}
        onNewNoteAdded={onNewNoteAdded}
        onNoteCrossClicked={onNoteCrossClicked}
        interpreteNoteResult={interpreteNoteResult}
        enableNotesButton={enableNotesButton}
        displayedApplicant={displayedApplicant}
        section={section}
        toggleRequestInfoModal={toggleRequestInfoModal}
      />
      <div className="mt10 pdf-display-flex flex-wrap-wrap">
        {pets.length > 0 &&
          pets.map((pet) => (
            <SummaryPetsDetailsBox
              pet={pet}
              key={pet.guidID}
              from="renterSummary"
              toggleDocumentsViewer={toggleDocumentsViewer}
            />
          ))}
        {pets.length === 0 && !optOutPets && (
          <div className="application-container-padding pdf-font-size-10">
            Not provided
          </div>
        )}
        {pets.length === 0 && optOutPets && (
          <Box mb="10px">{optOutPetsNote}</Box>
        )}
      </div>
    </div>
  )
}

export default PetsContainer
