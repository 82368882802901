import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import Button from 'app/components/buttons/default/component'
import { Flex } from 'app/components/design-system-components'
import {
  ArrowBackRounded,
  ArrowForwardRounded,
} from 'app/components/design-system-components/icons/navigation'
import { isMobile } from 'app/shared_components/helpers'
import PotentialViewing from 'app/sm/property_details/potential_viewing'

const StyledIcon = styled(Flex)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #1ab25d;
  background-color: #d9d9d9;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const transparentBGAndColor = {
  backgroundColor: 'transparent',
  color: 'transparent',
}

class PotentialViewings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDateIndex: 0,
      showMoreViewingsButtonShown: false,
      showAllViewings: true,
    }
  }

  componentDidMount() {
    if (isMobile()) {
      this.setState({
        showMoreViewingsButtonShown: true,
        showAllViewings: false,
      })
    }
  }

  toggleShowAllViewings = () => {
    const { showAllViewings } = this.state
    this.setState({
      showAllViewings: !showAllViewings,
    })
  }

  onTitleArrowClicked = (direction) => {
    if (direction === 'left') {
      this.setState({ selectedDateIndex: this.state.selectedDateIndex - 1 })
    } else if (direction === 'right') {
      this.setState({ selectedDateIndex: this.state.selectedDateIndex + 1 })
    }
  }

  shouldEnableArrow = (direction, availableDates) => {
    const { selectedDateIndex } = this.state
    if (direction === 'left') {
      return (
        selectedDateIndex !== 0 && selectedDateIndex < availableDates.length
      )
    }
    if (direction === 'right') {
      return selectedDateIndex < availableDates.length - 1
    }
    return false
  }

  render() {
    const { selectedDateIndex, showMoreViewingsButtonShown, showAllViewings } =
      this.state
    const {
      potentialViewings,
      teamSlug,
      registerViewing,
      spinnerTarget,
      title,
      renderButtonsSection,
    } = this.props
    const formattedPotentialViewings = potentialViewings.reduce(
      (schedule, potentialViewing) => {
        const fieldName =
          potentialViewing.nameOfDay + ' ' + potentialViewing.listedDate
        schedule[fieldName] = schedule[fieldName] || []
        schedule[fieldName].push(potentialViewing)
        return schedule
      },
      {},
    )

    let availableDates = Object.keys(formattedPotentialViewings)

    const emptyDate = availableDates.find(
      (date) =>
        formattedPotentialViewings[date].filter((viewing) =>
          moment(viewing.startDate).subtract(1, 'hours').isAfter(moment()),
        ).length === 0,
    )
    if (emptyDate) {
      delete formattedPotentialViewings[emptyDate]
      availableDates = Object.keys(formattedPotentialViewings)
    }

    const chosenDate =
      selectedDateIndex >= availableDates.length
        ? availableDates[availableDates.length - 1]
        : availableDates[selectedDateIndex]
    const spinner = spinnerTarget ? (
      <i className="fa fa-spinner fa-pulse spin" />
    ) : (
      ''
    )
    const showPotentialViewings =
      formattedPotentialViewings && formattedPotentialViewings[chosenDate]
    const showLeftNavButton = this.shouldEnableArrow('left', availableDates)
    const showRightNavButton = this.shouldEnableArrow('right', availableDates)

    const allViewings =
      formattedPotentialViewings &&
      formattedPotentialViewings[chosenDate] &&
      formattedPotentialViewings[chosenDate].filter((viewing) =>
        moment(viewing.startDate).subtract(1, 'hours').isAfter(moment()),
      )
    let viewingsShown = allViewings
    let buttonText = 'Show more times'

    if (isMobile() && allViewings && allViewings.length > 0) {
      viewingsShown = allViewings.slice(0, 3)
    }
    if (isMobile() && showAllViewings) {
      viewingsShown = allViewings
      buttonText = 'Show less times'
    }
    const leftNavButtonStyles = showLeftNavButton ? {} : transparentBGAndColor
    const rightNavButtonStyles = showRightNavButton ? {} : transparentBGAndColor
    return (
      <div>
        <h4 className="mb30">{title}</h4>
        <div className="potential-viewings-title justify-content-space-between mb20 display-flex align-items-center">
          <StyledIcon style={leftNavButtonStyles}>
            {showLeftNavButton && (
              <ArrowBackRounded
                onClick={() => this.onTitleArrowClicked('left')}
              />
            )}
          </StyledIcon>
          <span>
            <strong>{chosenDate}</strong>
          </span>
          <StyledIcon style={rightNavButtonStyles}>
            <ArrowForwardRounded
              onClick={() => this.onTitleArrowClicked('right')}
            />
          </StyledIcon>
        </div>
        {showPotentialViewings &&
          viewingsShown &&
          viewingsShown.map((viewing, index) => {
            const showSpinner = spinnerTarget === viewing.startDate
            return (
              <PotentialViewing
                key={index}
                viewing={viewing}
                isBtnRegisteredText={'Register'}
                showSpinner={showSpinner}
                spinner={showSpinner && spinner}
                btnStyles={'hollow-button-green'}
                teamSlug={teamSlug}
                onActionBtnClicked={registerViewing}
                changeText={undefined}
              />
            )
          })}
        <Flex>
          {showMoreViewingsButtonShown && (
            <Button
              size="large"
              onClick={this.toggleShowAllViewings}
              text={buttonText}
              buttonType="secondary"
              buttonStyleClass="col-sm-3 mr15"
            />
          )}{' '}
          {renderButtonsSection && renderButtonsSection()}
        </Flex>
      </div>
    )
  }
}

export default PotentialViewings
