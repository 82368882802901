import React from 'react'

import { Box, Flex } from 'app/components/design-system-components'
import { OndemandVideoRounded } from 'app/components/design-system-components/icons/notification'

function StepsHeading({ text, videoURL }) {
  return (
    <Flex alignItems="center">
      <h5>{text}</h5>
      {videoURL && (
        <Box ml={3} mb={1}>
          <a href={videoURL} target="_blank" rel="noreferrer">
            <OndemandVideoRounded width="22px" height="18px" />
          </a>
        </Box>
      )}
    </Flex>
  )
}

export default StepsHeading
