import * as React from 'react'

import { Svg } from 'app/components/design-system-components/Svg'

function SvgLockOutlineRounded(props) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 8.5h1c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-10c0-1.1.9-2 2-2h1v-2c0-2.76 2.24-5 5-5s5 2.24 5 5v2zm-5-5c-1.66 0-3 1.34-3 3v2h6v-2c0-1.66-1.34-3-3-3zm-5 17c-.55 0-1-.45-1-1v-8c0-.55.45-1 1-1h10c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1H7zm7-5c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default SvgLockOutlineRounded
