import { connect } from 'react-redux'

import { fetchProperty } from 'app/sm/property_todos/property_todos_actions'
import PropertyTodosContainer from 'app/sm/property_todos/property_todos_container'

const mapStateToProps = ({ propertyTodos }) => ({
  property: propertyTodos.property,
})

const mapDispatchToProps = (dispatch) => ({
  fetchProperty: (id) => dispatch(fetchProperty(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyTodosContainer)
