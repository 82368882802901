import React from 'react'

import styled from 'styled-components'

import iconGreenTick from 'app/assets/icons/round-green-tick.svg'
import { Box, Flex } from 'app/components/design-system-components'
import Status from 'app/components/design-system-components/status'
import { Text } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const StyledAllDone = styled(Flex)`
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  img {
    margin-left: ${({ theme }) => theme.space[4]}px;
  }
`

function Finished({ teamSlug = '' }) {
  return (
    <Box>
      <Status
        leftContentToShow={
          <Text fontSize={theme.fontSizes[6] + 'px'} as="div">
            Complete
          </Text>
        }
        rightContentToShow={
          <StyledAllDone>
            All done!
            <img
              src={iconGreenTick}
              width="18px"
              height="18px"
              alt="all done"
            />
          </StyledAllDone>
        }
        totalSteps={4}
        currentStep={4}
      />
      <Box mt={theme.space[5] + 'px'} mb={theme.space[5] + 'px'}>
        You’re ready to start processing better quality applications. Use your
        Apply Link (
        <a href={`/apply/${teamSlug}`} target="_blank" rel="noreferrer">
          snug.com/apply/{teamSlug}
        </a>
        ) everywhere: adverts, your website, stockists, etc.{' '}
        <a
          href="https://help.snug.com/hc/en-us/articles/360001724195?utm_campaign=PM+Onboarding+Checklist&utm_medium=app&utm_source=setup_page"
          target="_blank"
          rel="noreferrer"
        >
          learn more
        </a>
      </Box>
    </Box>
  )
}

export default Finished
