import React from 'react'

const TextareaBox = ({
  placeholder,
  value,
  validate,
  onInputChange,
  label,
  rows = '2',
  field,
}) => {
  return (
    <div className="input-box">
      <textarea
        type="text"
        placeholder={placeholder}
        value={value}
        multiple="true"
        onBlur={validate}
        onChange={(event) => onInputChange(event, field)}
        rows={rows}
      ></textarea>
      <label>{label}</label>
    </div>
  )
}

export default TextareaBox
