import { NetworkConnectionError } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { composeAttachment, LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'
import { ApplicationCategory, sortHistory } from 'app/sm/helpers'
import { addSettings } from 'app/sm/profile/profile_access'
import { fetchProfile } from 'app/sm/profile/profile_actions'
import { getProperty } from 'app/sm/property_details/property_details_access'
import {
  alterApplicationStatus,
  callDeclineApplication,
  editRentAndEmploymentSummary,
  getActiveOffer,
  getApplyAnywhereManagerSummaryWithSecret,
  getBackgroundCheckForManager,
  getGeneratePDF,
  getPMSupportAttachments,
  getPropertyApplications,
  getRentalHistoryRatings,
  getRentAndEmploymentSummary,
  getRenterApplication,
  getRequestApplyAnywhereApplicationAccess,
  getVerifyApplicationAccess,
  inviteApplicant,
  managerPostBackgroundCheck,
  putMoveTo,
  removePMSupportAttachment,
  sendRenterInformation,
  sendSubmitApplicationReminder,
  uploadPMSupportAttachment,
} from 'app/sm/renter_applications/renter_applications_access'
import store from 'app/store'

export const RECEIVE_APPLICATION = 'RRA_RECEIVE_APPLICATION'
export const RECEIVE_APPLICATIONS = 'RRA_RECEIVE_APPLICATIONS'
export const RECEIVE_ACTIVE_OFFER = 'RRA_RECEIVE_ACTIVE_OFFER'
// export const RECEIVE_SHOWN_APPLICATIONS = 'RPA_RECEIVE_SHOWN_APPLICATIONS'
export const ON_TAB_CHANGE = 'RRA_ON_TAB_CHANGE'
export const ON_TAB_COUNTS_ALTER = 'RRA_ON_TAB_COUNT_ALTER'
export const SET_PROPERTY_ID = 'RRA_SET_PROPERTY_ID'
export const RESET = 'RRA_RESET'
export const RECEIVE_RESPONSE_TEXT = 'RRA_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'RRA_RECEIVE_ERROR'
export const CLEAR_ERROR = 'RRA_CLEAR_ERROR'
export const RECEIVE_PROPERTY = 'RRA_RECEIVE_PROPERTY'
export const PING = 'RRA_PING'
export const TOGGLE_SEND_REQUEST_INFO = 'TOGGLE_SEND_REQUEST_INFO'
export const TOGGLE_SPINNER = 'RRA_TOGGLE_SPINNER'
export const TOGGLE_APPLICATIONS_SPINNER = 'RRA_TOGGLE_APPLICATIONS_SPINNER'
export const RECEIVE_RATING_CATEGORIES = 'RECEIVE_RATING_CATEGORIES'

export const receiveApplication = (application) => ({
  type: RECEIVE_APPLICATION,
  application,
})
export const receiveApplications = (applications) => ({
  type: RECEIVE_APPLICATIONS,
  applications,
})
export const receiveActiveOffer = (activeOffer) => ({
  type: RECEIVE_ACTIVE_OFFER,
  activeOffer,
})
export const receiveProperty = (property) => ({
  type: RECEIVE_PROPERTY,
  property,
})
// export const receiveShownApplications = shown => ({ type: RECEIVE_SHOWN_APPLICATIONS, shown })
export const onTabCountsAlter = (tabsCtrl) => ({
  type: ON_TAB_COUNTS_ALTER,
  tabsCtrl,
})
export const setPropertyId = (propertyId) => ({
  type: SET_PROPERTY_ID,
  propertyId,
})
export const reset = () => ({ type: RESET })
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })
export const ping = () => ({ type: PING })
export const clearError = () => ({ type: CLEAR_ERROR })
export const toggleSendRequestInfo = () => ({ type: TOGGLE_SEND_REQUEST_INFO })
export const toggleSpinner = (spinner) => ({ type: TOGGLE_SPINNER, spinner })
export const toggleApplicationsSpinner = (applicationsSpinner) => ({
  type: TOGGLE_APPLICATIONS_SPINNER,
  applicationsSpinner,
})
export const receiveRentalHistoryRatingCategories = (ratingCategories) => ({
  type: RECEIVE_RATING_CATEGORIES,
  ratingCategories,
})

const calculateTabCounts = (tabsCtrl, applications) => {
  const numberOfApplications = {}
  tabsCtrl.map((tab) => {
    numberOfApplications[tab.id] = applications.filter(
      (app) => ApplicationCategory[app.status] === tab.id,
    ).length
  })
  return numberOfApplications
}

export function managerProcessBackgroundCheck(applicantId, applicationId) {
  return (dispatch, getState) => {
    return managerPostBackgroundCheck(applicantId, applicationId).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((t) =>
            Promise.reject(translateErrorCodeToMessage(t)),
          )
        }
      },
    )
  }
}

export function fetchBackgroundForManager(applicantId, applicationId) {
  return (dispatch) => {
    return getBackgroundCheckForManager(applicantId, applicationId).then(
      ({ ok, backgroundCheck, responseText }) => {
        if (ok) {
          return Promise.resolve(backgroundCheck)
        } else {
          return responseText
            .then((error) =>
              Promise.resolve(translateErrorCodeToMessage(error)),
            )
            .then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export function fetchRenterApplication(
  applicationId,
  applicantId,
  setting = {},
) {
  const { calculateScore = false, enableSpinner = true } = setting
  return (dispatch) => {
    enableSpinner && dispatch(toggleSpinner(true))
    return getRenterApplication(
      applicationId,
      applicantId,
      calculateScore,
    ).then(({ application, ok, responseText, statusCode }) => {
      if (ok) {
        enableSpinner && dispatch(toggleSpinner(false))
        const {
          rentalHistory,
          employers,
          propertyOffer,
          lastApplicationSubmitReminder,
          totalWeeklyIncome,
          application: renterApplication = {},
        } = application

        const { isManagerAdded = false } = renterApplication

        if (isManagerAdded) {
          dispatch(receiveApplication(application))
          return
        }

        const sortedRentalHistory = sortHistory(rentalHistory, 'rentalHistory')
        const sortedEmployers = sortHistory(employers, 'employment')

        const currentAddress = sortedRentalHistory.current
        const previousAddress = sortedRentalHistory.previous
        const currentEmployers = sortedEmployers.current
        const previousEmployers = sortedEmployers.previous

        application = Object.assign({}, application, {
          currentAddress,
          previousAddress,
          currentEmployers,
          previousEmployers,
          lastApplicationSubmitReminder,
          totalWeeklyIncome,
        })
        ;(propertyOffer || {}).guidID &&
          dispatch(fetchProfile(propertyOffer.guidID))
        dispatch(receiveApplication(application))
        return Promise.resolve({ application })
      } else {
        enableSpinner && dispatch(toggleSpinner(false))
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    })
  }
}

export function fetchRentalHistoryRatingCategories() {
  return (dispatch) => {
    getRentalHistoryRatings().then(({ ok, ratingCategories, responseText }) => {
      if (ok) {
        dispatch(receiveRentalHistoryRatingCategories(ratingCategories))
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function fetchPropertyApplications(
  propertyId,
  dispatchedFromTab,
  onStatusChange = false,
  calculateScore = false,
) {
  return (dispatch, getState) => {
    if (!onStatusChange) {
      dispatch(toggleSpinner(true))
    }
    if (calculateScore) {
      dispatch(toggleApplicationsSpinner(true))
    }

    dispatch(fetchProperty(propertyId))
    return getPropertyApplications(propertyId, calculateScore).then(
      ({ applications, ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(toggleSpinner(false))
          dispatch(toggleApplicationsSpinner(false))
          dispatch(receiveApplications(applications))
          const { tabsCtrl } = getState().RenterApplications
          const numberOfApplications = calculateTabCounts(
            tabsCtrl,
            applications,
          )
          const newTabsCtrl = tabsCtrl.map((tab) => ({
            ...tab,
            count: numberOfApplications[tab.id],
          }))
          dispatch(onTabCountsAlter(newTabsCtrl))
          const firstTabWithApplication = newTabsCtrl.find(
            (tab) => tab.count !== 0,
          )
          dispatch(
            onTabChange(
              firstTabWithApplication ? firstTabWithApplication.id : 0,
            ),
          )
          return Promise.resolve()
        } else {
          dispatch(toggleSpinner(false))
          dispatch(toggleApplicationsSpinner(false))
          return responseText.then((t) => {
            dispatch(receiveResponseText(t))
            return Promise.reject(t)
          })
        }
      },
    )
  }
}

export function fetchProperty(id) {
  return (dispatch) => {
    getProperty(id).then(({ property, ok, responseText }) => {
      if (ok) {
        dispatch(receiveProperty(property))
      } else {
        responseText.then((t) => {
          store.dispatch(receiveResponseText(t))
        })
      }
    })
  }
}

export function fetchActiveOffer(propertyId) {
  return (dispatch) => {
    getActiveOffer(propertyId).then(
      ({ offer, ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(receiveActiveOffer(offer))
        } else {
          responseText.then((t) => {
            store.dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

export function onTabChange(id) {
  return (dispatch, getState) => {
    dispatch({ type: ON_TAB_CHANGE, id })
  }
}

export function offerAll(cluster) {
  return (dispatch, getState) => {
    const { applications } = getState().RenterApplications
    let grouped = applications
      .filter((a) => a.Category === cluster)
      .map((a) => alterApplicationStatus('offer', a.guidID))

    Promise.all(grouped).then((all) => {
      const failed = all.filter((r) => !r.ok)

      if (failed.length !== 0) {
        Promise.all(failed.map((f) => f.responseText)).then((errors) => {
          store.dispatch(receiveResponseText(errors[0]))
        })
      }
    })
  }
}

export function onStatusDropdownChange(
  value,
  applicationId,
  dispatchedFromTab,
) {
  return (dispatch, getState) => {
    dispatch(clearError())
    const { propertyId } = getState().RenterApplications

    return alterApplicationStatus(value, applicationId).then(
      ({ ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(fetchRenterApplication(applicationId))
          if (propertyId) {
            dispatch(
              fetchPropertyApplications(propertyId, dispatchedFromTab, true),
            )
          }
          return Promise.resolve()
        } else {
          return responseText.then((t) => {
            dispatch(receiveResponseText(t))
            return Promise.reject(t)
          })
        }
      },
    )
  }
}

// helper function. shouldn't be exported
const changeApplication = (action, applicationGUID, params) => {
  const actions = {
    shortlist: 'shortlist',
    unshortlist: 'unshortlist',
    decline: 'decline',
    offer: 'offer',
    withdrawoffer: 'withdrawoffer',
  }

  if (!Object.keys(actions).includes(action)) {
    return Promise.reject(
      'Action',
      action,
      ' is not supported by changeApplication',
    )
  }

  return alterApplicationStatus(action, applicationGUID, params).then(
    ({ ok, application, responseText }) => {
      if (ok) {
        return Promise.resolve(application?.status)
      } else {
        return responseText.then((error) => Promise.reject(error))
      }
    },
  )
}

export function declineApplication(
  agencyGUID,
  applicationID,
  declineReasons,
  notifyApplicant,
  otherReasonComment,
) {
  return (dispatch, getState) => {
    dispatch(clearError())
    const { propertyId } = getState().RenterApplications

    return callDeclineApplication(
      agencyGUID,
      applicationID,
      declineReasons,
      notifyApplicant,
      otherReasonComment,
    ).then(({ ok, responseText, statusCode }) => {
      if (ok) {
        dispatch(fetchRenterApplication(applicationID))
        if (propertyId) {
          dispatch(fetchPropertyApplications(propertyId, 'Applications', true))
        }
        return Promise.resolve()
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((t) => {
          dispatch(receiveResponseText(t))
          return Promise.reject()
        })
      }
    })
  }
}

export const offerApplication = (applicationGUID) => (dispatch) => {
  return changeApplication('offer', applicationGUID)
}
export const shortlistApplication = (applicationGUID) => (dispatch) => {
  return changeApplication('shortlist', applicationGUID)
}
export const unshortlistApplication = (applicationGUID) => (dispatch) => {
  return changeApplication('unshortlist', applicationGUID)
}
export const withdrawOfferForApplication = (applicationGUID) => (dispatch) => {
  return changeApplication('withdrawoffer', applicationGUID)
}

export function requestRenterInformation(applicationId, applicantId, content) {
  return (dispatch) => {
    dispatch(clearError())
    return sendRenterInformation(applicationId, applicantId, content).then(
      ({ ok, responseText }) => {
        if (ok) {
          return dispatch(toggleSendRequestInfo())
        } else {
          return responseText.then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export function getApplicationPDF(applicationID, applicantId, downloadOption) {
  return (dispatch) => {
    return getGeneratePDF(applicationID, applicantId, downloadOption).then(
      ({ ok, applicationPDF, responseText }) => {
        if (ok) {
          return Promise.resolve(applicationPDF.url)
        } else {
          return responseText.then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export function updatePreferences(
  id,
  payload,
  applicationId = '',
  applicantId = '',
) {
  return (dispatch) => {
    return addSettings(payload, 'PUT').then(({ ok, responseText }) => {
      if (ok) {
        dispatch(fetchProfile(payload.offerId))
        dispatch(fetchPropertyApplications(id, 'Applications', true, true))
        applicationId &&
          applicantId &&
          dispatch(fetchRenterApplication(applicationId, applicantId, true))
      } else {
        responseText.then((t) => dispatch(receiveResponseText(t)))
      }
    })
  }
}

export const requestApplyAnywhereApplicationAccess =
  (applicationId, query) => (dispatch) => {
    return getRequestApplyAnywhereApplicationAccess(applicationId, query).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return Promise.reject(responseText)
        }
      },
    )
  }

export const fetchApplyAnywhereManagerSummaryWithSecret =
  (applicationId, token, applicantId) => (dispatch) => {
    dispatch(toggleSpinner(true))
    return getApplyAnywhereManagerSummaryWithSecret(
      applicationId,
      token,
      applicantId,
    ).then(({ ok, application, responseText }) => {
      if (ok) {
        dispatch(toggleSpinner(false))
        const { rentalHistory, employers, propertyOffer } = application

        const sortedRentalHistory = sortHistory(
          rentalHistory || [],
          'rentalHistory',
        )
        const sortedEmployers = sortHistory(employers || [], 'employment')

        const currentAddress = sortedRentalHistory.current
        const previousAddress = sortedRentalHistory.previous
        const currentEmployers = sortedEmployers.current
        const previousEmployers = sortedEmployers.previous

        application = Object.assign({}, application, {
          currentAddress,
          previousAddress,
          currentEmployers,
          previousEmployers,
        })
        ;(propertyOffer || {}).guidID &&
          dispatch(fetchProfile(propertyOffer.guidID))

        dispatch(receiveApplication(application))
        return Promise.resolve(application)
      } else {
        dispatch(toggleSpinner(false))
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    })
  }

export const fetchApplyAnywhereManagerSummaryWithSecretWithoutDispatch =
  (applicationId, token, applicantId) => (dispatch) => {
    return getApplyAnywhereManagerSummaryWithSecret(
      applicationId,
      token,
      applicantId,
    ).then(({ ok, application, responseText }) => {
      if (ok) {
        return Promise.resolve(application)
      } else {
        return responseText.then((t) => {
          return Promise.reject(t)
        })
      }
    })
  }

export const fetchVerifyApplicationAccess =
  (applicationId, token) => (dispatch) => {
    return getVerifyApplicationAccess(applicationId, token).then(
      ({ ok, application, responseText }) => {
        if (ok) {
          return Promise.resolve(application)
        } else {
          return responseText.then((t) => {
            return Promise.reject(t)
          })
        }
      },
    )
  }

export const moveTo = (applicationId, newStatus) => () => {
  return putMoveTo(applicationId, newStatus).then(({ ok, responseText }) => {
    if (ok) {
      return Promise.resolve()
    } else {
      return responseText.then((t) => {
        return Promise.reject(t)
      })
    }
  })
}

export const addPMSupportDoc = (documentType, applicantID) => () => {
  const attachments = document.getElementById(`attachments-${documentType}`)
  let form = new FormData()

  composeAttachment(attachments, form)

  return uploadPMSupportAttachment(form, applicantID).then(
    ({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const fetchPMSupportAttachments = (applicantID) => () => {
  return getPMSupportAttachments(applicantID).then(
    ({ ok, pmSupportDocs, responseText }) => {
      if (ok) {
        return Promise.resolve(pmSupportDocs)
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const deletePMSupportAttachments = (attachmentid) => () => {
  return removePMSupportAttachment(attachmentid).then(
    ({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const requestSubmitApplication = (applicationGUID) => () => {
  return sendSubmitApplicationReminder(applicationGUID).then(
    ({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const inviteToApplyApplicant = (applicationID) => () => {
  return inviteApplicant(applicationID).then(({ ok, responseText }) => {
    if (ok) {
      return Promise.resolve()
    } else {
      return responseText.then((error) =>
        Promise.reject(translateErrorCodeToMessage(error)),
      )
    }
  })
}

export const fetchRentAndEmploymentSummary = (applicationGUID) => () => {
  return getRentAndEmploymentSummary(applicationGUID).then(
    ({ ok, summary, responseText }) => {
      if (ok) {
        return Promise.resolve(summary)
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const managerEditRentAndEmploymentSummary =
  (applicationGUID, request) => () => {
    return editRentAndEmploymentSummary(applicationGUID, request).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
