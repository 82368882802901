import React from 'react'

import moment from 'moment'
import 'app/sm/viewings_new_run/viewingrun_item/style.scss'

const selectFirstName = (managerProfile) =>
  managerProfile && managerProfile.firstName ? managerProfile.firstName : ''
const selectLastName = (managerProfile) =>
  managerProfile && managerProfile.lastName ? managerProfile.lastName : ''
const selectAvatar = (managerProfile) =>
  managerProfile && managerProfile.avatar ? managerProfile.avatar : ''
const selectPhone = (managerProfile) =>
  managerProfile && managerProfile.mobilePhone ? managerProfile.mobilePhone : ''

class ViewingRunItem extends React.Component {
  onClickRow = () => {
    this.props.onClickRow()
  }

  render() {
    const { manager = {}, runSummary = {} } = this.props
    const { profile: managerProfile } = manager
    const fullName =
      selectFirstName(managerProfile) + ' ' + selectLastName(managerProfile)
    const phone = selectPhone(managerProfile)
    return (
      <div className="display-flex flex-direction-column border-bottom mb20 pb20">
        <div className="display-flex">
          <div className="viewing-run-row">
            <div className="profile-section">
              <div className="team-width-30p display-flex justify-content-center align-items-center relative">
                <div className={`meta display-flex align-items-center`}>
                  {!selectAvatar(managerProfile) ? (
                    <div
                      className={`avatar team-avatar ok relative`}
                      style={{
                        backgroundImage: `url(${selectAvatar(managerProfile)})`,
                      }}
                    >
                      <span className="app-avatar-initial-position-v2 team-avatar-text">
                        {selectFirstName(managerProfile).substring(0, 1)}
                        {selectLastName(managerProfile).substring(0, 1)}
                      </span>
                    </div>
                  ) : (
                    <div
                      className={`avatar team-avatar great relative`}
                      style={{
                        backgroundImage: `url(${selectAvatar(managerProfile)})`,
                      }}
                    ></div>
                  )}
                </div>
              </div>
              <div className="team-width-45p display-flex justify-content-center align-items-left text-align-left flex-direction-column">
                <div className="font-size-18" title={fullName}>
                  {fullName}
                </div>
                <div className="font-size-16 grey-text mt10">{phone}</div>
              </div>
            </div>
            <div className="viewing-run-info">
              <div className="viewing-run-date-time">
                <div className="viewing-run-date">
                  <div className="">
                    <i className="fa fa-calendar-check-o" />
                    <span className="font-size-18 pl5">
                      {moment(runSummary.startTime).format('ddd D MMM')}
                    </span>
                  </div>
                </div>
                <div className="viewing-run-time">
                  <div className="display-flex flex-direction-column">
                    <div className="">
                      <i className="fa fa-clock-o" />
                      <span className="font-size-18 pl5">
                        {moment(runSummary.startTime).format('h:mm A')}
                      </span>
                    </div>
                    <div className="font-size-16 grey-text mt10">
                      {moment
                        .duration(runSummary.totalTime, 'minutes')
                        .humanize()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="viewing-run-stops-registrants">
                <div className="display-flex flex-direction-column">
                  <div className="">
                    <i className="fa fa-home" />
                    <span className="font-size-18 pl5">
                      {runSummary.totalWaypoints}
                    </span>
                  </div>
                  <div className="mt10">
                    <i className="fa fa-user-o" />
                    <span className="font-size-18 pl5">
                      {runSummary.totalRegistrants}
                    </span>
                  </div>
                </div>
              </div>
              <div className="viewing-run-show-button">
                <button
                  className="hollow-button-green p0"
                  onClick={this.onClickRow}
                >
                  Show
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ViewingRunItem
