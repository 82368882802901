import React from 'react'

import {
  Flex,
  HorizontalLine,
  IconButton,
} from 'app/components/design-system-components'
import { ClearRounded } from 'app/components/design-system-components/icons/content'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const ModalHeader = ({ onDismiss }) => {
  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mb={theme.space[6] + 'px'}
      >
        <h3>New Message</h3>
        <IconButton
          IconCmp={ClearRounded}
          iconProps={{ fontSize: theme.fontSizes[10] + 'px' }}
          variant="flat"
          onClick={onDismiss}
        />
      </Flex>
      <HorizontalLine />
    </>
  )
}
