import { connect } from 'react-redux'

import {
  addPropertyLookup,
  fetchPropertyLookup,
  removePropertyLookup,
} from 'app/sm/onlist_details/action'
import PropertyLookup from 'app/sm/onlist_details/property_lookup_container'

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  fetchPropertyLookup: (agencyGUID, propertyGUID) =>
    dispatch(fetchPropertyLookup(agencyGUID, propertyGUID)),
  addPropertyLookup: (agencyGUID, propertyGUID, payload) =>
    dispatch(addPropertyLookup(agencyGUID, propertyGUID, payload)),
  removePropertyLookup: (agencyGUID, propertyGUID, propertyLookupGUID) =>
    dispatch(
      removePropertyLookup(agencyGUID, propertyGUID, propertyLookupGUID),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(PropertyLookup)
