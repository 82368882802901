import { connect } from 'react-redux'

import { addRentalReputationBackUrl } from 'app/shared_components/actions'
import { fetchApplication } from 'app/sm/apply/apply_actions'
import {
  addExtraIncome,
  addProofOfIncome,
  deleteProofOfIncome,
  editExtraIncome,
  fetchExtraIncome,
  fetchProofOfIncome,
} from 'app/sm/proof_of_income/proof_of_income_actions'
import Container from 'app/sm/proof_of_income/proof_of_income_container'
import {
  fetchCompleteness,
  fetchTeamSettingInformation,
} from 'app/sm/rental_reputation/actions'
import { retrieveReputationPreference } from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({
  ProofOfIncome,
  Shared,
  apply,
  RentalReputation,
}) => ({
  documents: ProofOfIncome.documents,
  spinners: ProofOfIncome.spinners,
  responseTexts: ProofOfIncome.responseTexts,
  backUrl: Shared.backUrl,
  property: apply.property,
  completeness: RentalReputation.completeness,
  applicationDetails: apply.application,
  applyRules: apply.applyRules,
})

const mapDispatchToProps = (dispatch) => ({
  fetchProofOfIncome: () => dispatch(fetchProofOfIncome()),
  addProofOfIncome: (documentType, fileType) =>
    dispatch(addProofOfIncome(documentType, fileType)),
  deleteProofOfIncome: (id, fileType) =>
    dispatch(deleteProofOfIncome(id, fileType)),
  addRentalReputationBackUrl: (rentalBackUrl) =>
    dispatch(addRentalReputationBackUrl(rentalBackUrl)),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
  addExtraIncome: (extraIncome) => dispatch(addExtraIncome(extraIncome)),
  fetchExtraIncome: () => dispatch(fetchExtraIncome()),
  fetchTeamSettingInformation: (id, type) =>
    dispatch(fetchTeamSettingInformation(id, type)),
  editExtraIncome: (referenceId, extraIncome) =>
    dispatch(editExtraIncome(referenceId, extraIncome)),
  retrieveReputationPreference: () => dispatch(retrieveReputationPreference()),
  fetchApplication: (applicationId) =>
    dispatch(fetchApplication(applicationId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
