import React, { useCallback, useEffect, useState } from 'react'

import moment from 'moment'
import qs from 'qs'

import { Modal } from 'app/components/design-system-components'
import { debounce } from 'app/helpers/debounce'
import { setURLQuery } from 'app/helpers/query_string'
import AccessReferences from 'app/match/rental_reference/access_references/component'
import ConnectTeammemberModal from 'app/pages/teams/rental-references/components/ConnectTeam'
import RRTable from 'app/pages/teams/rental-references/components/RRTable'
import Theme from 'app/pages/teams/viewings-mobile/theme-styled-components'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import SnugUpgradeCTA from 'app/shared_components/SnugUpgradeCTA'
import { findChosenTeamBySlug, routes } from 'app/sm/helpers'
import { getTeamMembersList } from 'app/sm/team_viewing_preference/components/members_list/api'
import InviteTeamMembersModal from 'app/sm/team_viewing_preference/setup/inviteTeamMemberModal'
import { STANDARD_TIMEOUT, useDebounce } from 'app/utils/hooks/debounce'

export const PAGINATION_PAGE_SIZE = 10

export const sortOptions = [
  {
    value: '',
    name: 'Sort',
  },
  {
    value: 'asc',
    name: 'Oldest First',
  },
  {
    value: 'desc',
    name: 'Newest First',
  },
]

export const statusOptions = [
  {
    value: '',
    name: 'Status',
  },
  {
    value: '100',
    name: 'New',
  },
  {
    value: '200',
    name: 'Upload Ledger',
  },
  {
    value: '300',
    name: 'Awaiting Vacate',
  },
  {
    value: '400',
    name: 'Answer Vacate',
  },
  {
    value: '500',
    name: 'Completed',
  },
  {
    value: '600',
    name: 'Declined',
  },
  {
    value: '700',
    name: 'Archived',
  },
]

const TeamRentalReferences = ({
  changeTeam,
  fetchRentalReferencesForAgency,
  fetchCountForRentalReferencesForAgency,
  getManagersListForViewingFilters,
  teams,
  match,
  currentUser,
  location,
}) => {
  const queryString = window.location.search
  const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true })
  const { q: querySearchString = '', manager: managerParam = '' } = queryParams
  const [fetchingData, setFetchingData] = useState(true)
  const [rentalReferencesCount, setRentalReferencesCount] = useState(0)
  const [showConnectTeammemberModal, setShowConnectTeammemberModal] =
    useState(false)
  const [showInviteTeammemberModal, setShowInviteTeammemberModal] =
    useState(false)

  const { teamSlug } = match.params
  const currentTeam = findChosenTeamBySlug(teams, teamSlug) || {}

  const [currentPageNum, setCurrentPageNum] = useState(1)
  const [allRentalReferencesForAgency, setAllRentalReferencesForAgency] =
    useState({ data: [] })

  const [showCompletedRentalReference, setShowCompletedReference] =
    useState(false)
  const toggleCompletedReferenceModal = () => {
    setShowCompletedReference(!showCompletedRentalReference)
  }
  const defaultFilterValues = {
    searchValue: '',
    managerValue: '',
    statusValue: '',
    dateValue: '',
  }
  const [filterValues, setFilterValues] = useState(defaultFilterValues)
  const [managerListInfo, setManagerListInfo] = useState({
    managerNameList: [],
    managerList: [],
  })
  const debouncedSearchTerm = useDebounce(filterValues, STANDARD_TIMEOUT)
  const [prevFilterValues, setPrevFilterValues] = useState(defaultFilterValues)

  function isFirstTime() {
    return match.path === routes.rentalReferenceInboxFirstTime
  }
  const [readyToStart, setReadyToStart] = useState(!isFirstTime()) // We're ready to start immediately if not first time

  const [invitedMembers, setInvitedMembers] = useState([])

  const [isAddTeamMembersModalOpen, setAddTeamMembersModalOpen] =
    useState(false)

  const toggleIsAddTeamMembersModalOpen = () => {
    setAddTeamMembersModalOpen(!isAddTeamMembersModalOpen)
  }

  const [accessReferenceUsers, setAccessReferenceUsers] = useState([])
  const [connectTeamInvalid, setConnectTeamInvalid] = useState(false)

  const toggleConnectTeammemberModal = (changeUrl) => {
    setShowConnectTeammemberModal(!showConnectTeammemberModal)
    if (changeUrl) {
      const { isFromRR, ...withoutIsFromRR } = queryParams
      history.push({ search: qs.stringify(withoutIsFromRR) })
    }
  }

  const toggleInviteTeammemberModal = () => {
    setShowInviteTeammemberModal(!showInviteTeammemberModal)
  }

  useEffect(() => {
    if (isFirstTime()) {
      // If first time, we're only ready to start after a delay
      const delayMilliseconds = 10000
      debounce(() => {
        setReadyToStart(true)
      }, delayMilliseconds)() // call immediately
    }
  }, []) // [] = Run only first time, don't monitor for state changes

  useEffect(() => {
    const currentTeam = teams.find((team) => team.slug === teamSlug) || {}
    if (currentTeam && readyToStart && Object.keys(queryParams).length === 0) {
      const { guid = '' } = currentTeam
      if (guid) {
        setPrevFilterValues(filterValues)
        changeTeam(currentTeam)
        setFetchingData(true)
        fetchCountForRentalReferencesForAgency(guid)
          .then((result) => {
            setRentalReferencesCount(result.count)
          })
          .catch((error) => snugNotifier.error(error))
        loadManagerList(guid)
        const curQueries = qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        })
        if (curQueries && curQueries.page) {
          const currentQueryPage = Number(curQueries.page)
          setCurrentPageNum(currentQueryPage)
          onPaginationButtonClicked(currentQueryPage - 1, currentQueryPage)
          return
        }
        fetchRentalReferencesForAgency(guid, PAGINATION_PAGE_SIZE)
          .then((result) => {
            setAllRentalReferencesForAgency(result)
          })
          .catch((error) => snugNotifier.error(error))
          .finally(() => setFetchingData(false))
      }
    }
  }, [teams, readyToStart])

  useEffect(() => {
    const { teamSlug } = match.params
    const currentTeam = teams.find((team) => team.slug === teamSlug) || {}
    if (
      currentTeam &&
      readyToStart &&
      JSON.stringify(debouncedSearchTerm) !== JSON.stringify(prevFilterValues)
    ) {
      setPrevFilterValues(debouncedSearchTerm)
      const { guid = '' } = currentTeam
      if (guid) {
        setFetchingData(true)
        fetchCountForRentalReferencesForAgency(guid, filterValues)
          .then((result) => {
            setRentalReferencesCount(result.count)
          })
          .catch((error) => snugNotifier.error(error))
        loadManagerList(guid)
        fetchRentalReferencesForAgency(
          guid,
          PAGINATION_PAGE_SIZE,
          '',
          filterValues,
        )
          .then((result) => {
            setAllRentalReferencesForAgency(result)
          })
          .catch((error) => snugNotifier.error(error))
          .finally(() => setFetchingData(false))
      }
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    setFilterValues((prev) => ({ ...prev, searchValue: querySearchString }))
  }, [querySearchString])

  useEffect(() => {
    setFilterValues((prev) => ({ ...prev, managerValue: managerParam }))
  }, [managerParam])

  const getRentalReferencesDataPage = useCallback(
    (guid) => {
      fetchRentalReferencesForAgency(
        guid,
        PAGINATION_PAGE_SIZE,
        '',
        filterValues,
      )
        .then((result) => {
          setAllRentalReferencesForAgency(result)
        })
        .catch((error) => snugNotifier.error(error))
        .finally(() => setFetchingData(false))
    },
    [fetchRentalReferencesForAgency, filterValues],
  )

  const onChangeFilterValues = (event) => {
    const { target } = event
    const { value, id } = target
    setFilterValues({
      ...filterValues,
      [id]: value,
    })
  }

  const onClearFilterValues = () => {
    setFilterValues(defaultFilterValues)
  }

  const loadManagerList = (agencyGUID) => {
    agencyGUID &&
      currentUser &&
      getManagersListForViewingFilters(agencyGUID).then((managerList) => {
        const managerNameListWithGUID =
          managerList &&
          managerList
            .map((manager) => {
              if (manager.contact) {
                if (manager.isMe) {
                  return {
                    name: 'Me',
                    guid: 'me',
                  }
                }
                return {
                  name:
                    manager.contact.firstName + ' ' + manager.contact.lastName,
                  guid: manager.contact.guidID,
                }
              } else return null
            })
            .filter((manager) => manager)
        setManagerListInfo({
          managerNameList: managerNameListWithGUID,
          managerList: managerList,
        })
      })
  }

  const onPaginationButtonClicked = (
    offsetForPaginationMultiplier,
    updatedPageNum,
  ) => {
    const { pathname } = window.location
    const { teamSlug } = match.params
    const currentTeam = teams.find((team) => team.slug === teamSlug) || {}
    setFetchingData(true)
    if (currentTeam) {
      const { guid = '' } = currentTeam
      if (guid) {
        const offsetForPagination =
          PAGINATION_PAGE_SIZE * offsetForPaginationMultiplier
        fetchRentalReferencesForAgency(
          guid,
          PAGINATION_PAGE_SIZE,
          offsetForPagination,
        )
          .then((result) => {
            setAllRentalReferencesForAgency(result)
            setCurrentPageNum(updatedPageNum)
            setURLQuery(history, pathname, { page: updatedPageNum })
          })
          .catch((error) => snugNotifier.error(error))
          .finally(() => setFetchingData(false))
      }
    }
  }

  const fetchMemberList = () => {
    if (currentTeam?.guid) {
      getTeamMembersList(currentTeam.guid)
        .then(({ data }) => {
          if (data && data.length > 0) {
            setInvitedMembers(
              data.filter((arr) => arr.email !== currentUser.email),
            )
          }
        })
        .catch(() => {
          setInvitedMembers([])
        })
    }
  }

  const dataToBeEntered = allRentalReferencesForAgency.data.map((tableData) => {
    return {
      time: moment.now(),
      property: tableData.lease,
      renter: tableData.renter,
      assignee: tableData.assignee,
      status: tableData.status,
      action: tableData.rental,
      recipient: tableData.recipient,
      statusTime: tableData.status_time,
      visibility: false,
      forProfilePreference: tableData.for_profile_reference,
      guid: tableData.guid,
      isArchived: tableData.is_archived,
    }
  })

  const maxPageNumbers = Math.ceil(rentalReferencesCount / PAGINATION_PAGE_SIZE)
  const pageNumArray = [...Array(maxPageNumbers).keys()].map((num) => num + 1)
  const canPreviousPage =
    pageNumArray &&
    pageNumArray.length > 0 &&
    currentPageNum !== pageNumArray[0]
  const canNextPage =
    pageNumArray &&
    pageNumArray.length > 0 &&
    currentPageNum !== pageNumArray[pageNumArray.length - 1]
  const referencesAvailable = rentalReferencesCount > 0

  const showSpinner = fetchingData
  const showNoResultsMessage = !fetchingData && !referencesAvailable
  const showResults = !fetchingData && referencesAvailable

  useEffect(() => {
    if (queryParams['isFromRR'] && !showInviteTeammemberModal) {
      setShowConnectTeammemberModal(true)
      fetchMemberList()
    }
  }, [])

  // const { teamSlug } = match.params
  // const currentTeam = teams.find((team) => team.slug === teamSlug) || {}
  const { guid = '' } = currentTeam
  return (
    <div>
      <Theme>
        <>
          <RRTable
            showSearchSection={true}
            showSpinner={showSpinner}
            filterValues={filterValues}
            onChangeFilterValues={onChangeFilterValues}
            managerListInfo={managerListInfo}
            onClearFilterValues={onClearFilterValues}
            isFirstTime={isFirstTime}
            showNoResultsMessage={showNoResultsMessage}
            showResults={showResults}
            // columns={columns}
            dataToBeEntered={dataToBeEntered}
            rentalReferencesCount={rentalReferencesCount}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            pageNumArray={pageNumArray}
            currentPageNum={currentPageNum}
            onPaginationButtonClicked={onPaginationButtonClicked}
            maxPageNumbers={maxPageNumbers}
            agencyGUID={currentTeam?.guid}
            teamSlug={currentTeam?.slug}
            getRentalReferencesDataPage={getRentalReferencesDataPage}
          />
        </>
        {showConnectTeammemberModal &&
          currentTeam?.guid &&
          currentUser?.email && (
            <ConnectTeammemberModal
              toggleModal={toggleConnectTeammemberModal}
              teamGUID={currentTeam.guid}
              userEmail={currentUser.email}
              toggleInviteTeammemberModal={toggleInviteTeammemberModal}
              invitedMembers={invitedMembers}
              setInvitedMembers={setInvitedMembers}
              fetchMemberList={fetchMemberList}
            />
          )}
        {showInviteTeammemberModal && (
          <InviteTeamMembersModal
            toggleModal={toggleInviteTeammemberModal}
            teamGUID={currentTeam?.guid}
            toggleConnectTeammemberModal={() => {
              toggleConnectTeammemberModal(false)
            }}
            primaryAction={(email) => {
              setInvitedMembers((oldState) => [...oldState, { email }])
              toggleConnectTeammemberModal(false)
            }}
            cancelAction={() => {
              toggleConnectTeammemberModal(false)
            }}
          />
        )}
        {isFirstTime() && <SnugUpgradeCTA teamGUID={guid} />}
        {isAddTeamMembersModalOpen && (
          <Modal
            modalBody={
              <AccessReferences
                setAccessReferenceUsers={setAccessReferenceUsers}
                markDirty={setConnectTeamInvalid}
              />
            }
            modalHeading="Invite your team mates"
            standardWidth={true}
            takeIncreasedWidth={true}
            secondaryAction={toggleIsAddTeamMembersModalOpen}
            secondaryLabel="Close"
            primaryLabel="Invite team mates"
            toggleModal={toggleIsAddTeamMembersModalOpen}
          />
        )}
      </Theme>
    </div>
  )
}

export default TeamRentalReferences
