import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import {
  Alert,
  Box,
  Button,
  Flex,
} from 'app/components/design-system-components'
import { LockOutlineRounded } from 'app/components/design-system-components/icons/actions'
import { CheckboxesInput } from 'app/components/design-system-components/inputs/Checkboxes'
import Modal from 'app/components/design-system-components/Modal'
import { Text } from 'app/components/design-system-components/typography'
import * as Display from 'app/components/display/display'
import { durationOptionsNumbers } from 'app/components/display/edit_viewing_modal/viewing_modal_data'
import { ErrorAlreadyRegisteredForViewing } from 'app/constants/error_codes'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  listViewings,
  registerApplicantsToViewings,
} from 'app/services/http/viewings'
import * as snugNotifier from 'app/services/snugNotifier'
import * as helpers from 'app/sm/helpers'
import { parseDataUtil } from 'app/sm/helpers'
import { addViewing } from 'app/sm/ppp/viewing_actions'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'
import { formatTime } from 'app/utils/datetime/helpers'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { loadingStatesIds } from 'app/utils/loading-states'

const StyledIcon = styled(Flex)`
  svg {
    color: ${({ theme }) => theme.colors.gray500};
    font-size: ${({ theme }) => theme.fontSizes.pExtraLarge18};
    font-weight: ${({ theme }) => theme.fontWeights[3]};
  }
`

export const RegisterApplicantModal = ({
  offerID,
  applicantsIDs,
  onDismiss,
  property,
  fetchAllFutureViewings,
}) => {
  const dispatch = useDispatch()

  const { loadingStates: registerLoadingStates, promiseWrapper } =
    useStateWithLoading({})

  const [selectedViewingsIDs, setSelectedViewingsIDs] = useState([])
  const [viewingsOptions, setViewingsOptions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState({})
  const [addViewingModal, setAddViewingModal] = useState({
    isOpen: false,
    config: {},
  })
  const [defaultViewingDuration, setDefaultViewingDuration] = useState(null)

  const hasFutureViewings = viewingsOptions.length > 0

  const loadBasicData = () => {
    listViewings(offerID)
      .then((viewings) => {
        const options = viewings
          .filter(({ startDate }) => moment(startDate).isAfter(moment()))
          .map((v) => ({
            option: v,
            optionMeta: { id: v.guidID },
          }))
        setViewingsOptions(options)
      })
      .then(() => {
        if (viewingsOptions.length !== 0) return
        dispatchFetchTeamSettings(property.agencyID, 'viewings').then(
          ({ viewings = {} }) => {
            const viewingsSettings =
              parseDataUtil.convertObjValueFromStringToBoolean(viewings)
            if (viewingsSettings.default_selected_viewing_duration_enabled) {
              setDefaultViewingDuration(
                viewingsSettings.default_selected_viewing_duration,
              )
            }
          },
        )
      })
  }

  useEffect(loadBasicData, [offerID])

  const updateViewingsOptions = (selected) => {
    setSelectedOptions(selected)
    const selectedIDs = Object.values(selected).map((o) => o.option.guidID)
    setSelectedViewingsIDs(selectedIDs)
  }

  const getViewingCmp = ({ startDate, duration, published }) => {
    const formattedStart = formatTime(startDate, 'h:mma ddd DD MMM')
    return (
      <Flex mb={2}>
        <Text as="span" color="black" ml={2}>
          {formattedStart} ({duration}m){' '}
        </Text>
        {!published && (
          <StyledIcon m={1}>
            <LockOutlineRounded />
          </StyledIcon>
        )}
      </Flex>
    )
  }

  const renderModalBody = () => {
    const propertyName = `${property.address.friendlyName}, ${property.address.suburb}`
    return (
      <>
        {hasFutureViewings && (
          <Box>
            <Box mb={5} lineHeight="140%">
              Register the primary applicant(s) for the selected applications in
              the upcoming viewing times for{' '}
              <Text as="span" fontWeight={theme.fontWeights[6]}>
                {propertyName}
              </Text>
              .
            </Box>
            <CheckboxesInput
              options={viewingsOptions}
              value={selectedOptions}
              optionRenderer={({ option }) => getViewingCmp(option)}
              changeHandler={updateViewingsOptions}
              isRadioGroup={false}
              customLabelClass=""
            />
            {selectedViewingsIDs.length > 1 && (
              <Alert variant="warningWithBg" mt={theme.space[4] + 'px'}>
                Are you sure you want to register for multiple times?
              </Alert>
            )}
          </Box>
        )}

        {!hasFutureViewings && (
          <Box>
            There are no upcoming viewing times for {propertyName}.{' '}
            <Button
              variant="linkBlueWithoutWeight"
              onClick={toggleAddViewingModal}
            >
              Add one
            </Button>
          </Box>
        )}
      </>
    )
  }

  const dispatchAddViewing = (
    id,
    startdate,
    duration,
    fromScheduler,
    skipDoubleBookingCheck,
    options,
  ) => {
    return dispatch(
      addViewing(
        id,
        startdate,
        duration,
        fromScheduler,
        skipDoubleBookingCheck,
        options,
      ),
    )
  }

  const dispatchFetchTeamSettings = (teamId, queryString) => {
    return dispatch(fetchTeamSettingInfo(teamId, queryString))
  }

  const toggleAddViewingModal = () => {
    setAddViewingModal({
      isOpen: !addViewingModal.isOpen,
      config: addViewingModal.config,
    })
  }

  const onSubmitAddViewingModal = ({
    startDate,
    startTime,
    duration,
    ...viewingOptions
  }) => {
    const mergedStartDate = `${moment(startDate).format(
      helpers.dateWithDash,
    )} ${startTime}`
    const formattedStartDate = helpers.getStandardFormattedDate(
      moment(mergedStartDate, helpers.dateTimeWithDash).utc(),
      helpers.utcFormatTimeStamp,
    )
    if (property && property.guidID) {
      dispatchAddViewing(
        offerID,
        formattedStartDate,
        durationOptionsNumbers[duration],
        false,
        false,
        viewingOptions,
      )
        .then(() => {
          toggleAddViewingModal()
          loadBasicData()
          snugNotifier.success('Viewing Successfully added!')
        })
        .catch((error) => {
          snugNotifier.error(error)
        })
    }
  }

  const registerApplicants = () => {
    return promiseWrapper(
      registerApplicantsToViewings(
        property.agencyID,
        selectedViewingsIDs,
        applicantsIDs,
      )
        .then(() => {
          snugNotifier.success('Registration completed')
          onDismiss()
        })
        .catch(({ plainError = {} }) => {
          const { response: { data } = {} } = plainError
          if (data === ErrorAlreadyRegisteredForViewing) {
            snugNotifier.error(`Already registered`)
          }
          snugNotifier.error(`Unable to register (${data})`)
        }),
    )
  }

  const renderAddViewingModal = () => {
    return (
      addViewingModal.isOpen && (
        <Display.EditViewingModal
          title="Add Viewing"
          primaryActionLabel="Add"
          secondaryActionLabel="Cancel"
          fetchAllPropertyFutureViewings={fetchAllFutureViewings}
          defaultSelectedViewingDurationEnabled={!!defaultViewingDuration}
          defaultSelectedViewingDuration={defaultViewingDuration}
          teamGUID={property.agencyID}
          toggleModal={toggleAddViewingModal}
          primaryButtonActionInParent={onSubmitAddViewingModal}
          secondaryButtonAction={toggleAddViewingModal}
        />
      )
    )
  }

  return (
    <>
      <Modal
        modalHeading="Register to view"
        modalBody={renderModalBody()}
        toggleModal={onDismiss}
        primaryLabel="Register"
        secondaryLabel="Cancel"
        showPrimaryButton={hasFutureViewings}
        showSecondaryButton={hasFutureViewings}
        primaryAction={registerApplicants}
        secondaryAction={onDismiss}
        primaryButtonLoading={
          registerLoadingStates.state === loadingStatesIds.LOADING
        }
      />
      {renderAddViewingModal()}
    </>
  )
}
