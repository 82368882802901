import React from 'react'

import iconDocumentDoc from 'app/assets/icons/icon-document-doc.svg'
import iconDocumentImg from 'app/assets/icons/icon-document-img.svg'
import iconDocumentPdf from 'app/assets/icons/icon-document-pdf.svg'
class AttachedFiles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [{ name: 'Signed Lease.pdf', type: 'pdf' }],
    }
  }

  removeFile(file) {
    this.setState({ files: this.state.files.filter((e) => e !== file) })
  }

  render() {
    let { files } = this.state
    const documentIcons = {
      doc: iconDocumentDoc,
      img: iconDocumentImg,
      pdf: iconDocumentPdf,
    }

    return (
      <div className="sm-attached-files">
        {files.map((file, index) => (
          <div className="file-box" key={index}>
            {file.type != '' && (
              <img
                className="icon"
                src={documentIcons[file.type]}
                height="18"
              />
            )}
            <div className="name">{file.name}</div>
            <div className="remove" onClick={() => this.removeFile(file)}>
              <i className="icon-remove"></i>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default AttachedFiles
