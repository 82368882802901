import React from 'react'

import { Link } from 'react-router-dom'

import { history } from 'app/shared_components/router'
import Spinner from 'app/sm/common/spinner'
import {
  encodeShareParams,
  NO_IMAGE_PLACEHOLDER_URL,
  Profile,
  urlIds,
  urlTo,
  X,
} from 'app/sm/helpers'
import Breadcrumbs from 'app/sm/ppp/breadcrumbs'

const toggler = (val) => (val === 0 && 100) || (val === 100 && 0) || 0

class Preferences extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMostImportantTickboxWarning: false,
      clicked: false,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { offerId = '' },
      },
    } = this.props
    this.props.fetchProfile(offerId)
    this.props.fetchOffer(offerId)

    if (
      this.props.offer &&
      this.props.offer.property &&
      this.props.offer.property.guidID
    ) {
      const propertyAgencyID =
        this.props.offer &&
        this.props.offer.property &&
        this.props.offer.property.agencyID
      const currentTeam = this.props.teams.find(
        (team) => team.slug === propertyAgencyID,
      )
      currentTeam && this.props.changeTeam(currentTeam)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.responseText) {
      this.setState({
        clicked: false,
      })
    }

    if (
      nextProps.offer &&
      nextProps.offer.property &&
      nextProps.offer.property.guidID
    ) {
      const currentPropertyAgencyID =
        this.props.offer &&
        this.props.offer.property &&
        this.props.offer.property.agencyID
      const nextPropertyAgencyID =
        nextProps.offer &&
        nextProps.offer.property &&
        nextProps.offer.property.agencyID
      if (
        nextProps.teams !== this.props.teams ||
        nextPropertyAgencyID !== currentPropertyAgencyID
      ) {
        const currentTeam = nextProps.teams.find(
          (team) => team.guid == nextPropertyAgencyID,
        )
        currentTeam && this.props.changeTeam(currentTeam)
      }
    }
  }

  onContinueClick(mostImportantOptionTicked) {
    const {
      profile,
      steps,
      update,
      match: {
        params: { offerId = '' },
      },
    } = this.props

    if (mostImportantOptionTicked) {
      update(steps.Preferences, Object.assign({}, profile, { offerId }))
      this.setState({ clicked: true })
    } else {
      this.setState({
        showMostImportantTickboxWarning: true,
      })
    }
  }

  updateFieldProxy(key, value) {
    const { updateField } = this.props
    const mostImportantKeys = Profile.mostImportant.map((o) => o.id)
    mostImportantKeys.forEach((k) =>
      k === key ? updateField(k, value) : updateField(k, 0),
    )
  }

  render() {
    const {
      profile,
      steps,
      update,
      spinner,
      updateField,
      match: {
        params: { offerId = '' },
      },
      offer,
      responseText,
    } = this.props
    const propertyId = (offer.property || {}).guidID
    const {
      conditionPreferenceWeighting,
      occupancyPreferenceWeighting,
      yieldPreferenceWeighting,
    } = profile
    let mostImportantOptionTicked =
      !!conditionPreferenceWeighting ||
      !!occupancyPreferenceWeighting ||
      !!yieldPreferenceWeighting
    let showMostImportantTickboxWarning =
      this.state.showMostImportantTickboxWarning
    let clicked = this.state.clicked

    const disabled =
      showMostImportantTickboxWarning ||
      (conditionPreferenceWeighting === 0 &&
        occupancyPreferenceWeighting === 0 &&
        yieldPreferenceWeighting === 0) ||
      spinner
    return (
      <div className="profile-screen">
        <Breadcrumbs activeBreadCrumb="preferences" />
        <div className="mobile-only">
          <button
            onClick={() =>
              history.push(
                urlTo(urlIds.propertyPublishingOptions, {
                  propertyId,
                  offerId,
                }),
              )
            }
            className="btn btn-transparent btn-left xs-text-center wa"
          >
            <i className="icon-arrow-left left"></i>
            <span>Back</span>
          </button>
        </div>
        <div className="section-title">
          <div className="left column">
            <h3>Owner preferences</h3>
            <div className="row">
              <div className="col-sm-6">
                <p className="gray-color mt10 mb0">
                  Find and select the best renter for your property by adding
                  your preferences and letting options. Snug calculates the best
                  Match.
                </p>
              </div>
            </div>
          </div>
          {/*<div className="right"></div>*/}
        </div>

        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <div>
              <h4 className="mb20">
                What is most important when letting your property?
              </h4>
              {Profile.mostImportant.map((option) => (
                <label key={option.id} className="block mb15">
                  <input
                    type="radio"
                    name="mostImportant"
                    checked={profile[option.id] === 100}
                    onChange={() =>
                      this.updateFieldProxy(
                        option.id,
                        toggler(profile[option.id]),
                      )
                    }
                  />
                  <span className="xs-fs16">{option.text}</span>
                </label>
              ))}

              {showMostImportantTickboxWarning && (
                <div className="alert alert-danger">
                  <div>
                    You must choose the most important factor when letting your
                    property.
                  </div>
                </div>
              )}

              <h4 className="mt30 mb20">Letting options</h4>

              <label className="block mb15">
                <input
                  type="checkbox"
                  name="allowPets"
                  checked={profile.allowPets}
                  onChange={() =>
                    this.props.updateField('allowPets', !profile.allowPets)
                  }
                />
                <span className="xs-fs16">Pets allowed</span>
              </label>

              <label className="block mb15">
                <input
                  type="checkbox"
                  name="takeHoldingDeposit"
                  checked={profile.takeHoldingDeposit}
                  onChange={() =>
                    this.props.updateField(
                      'takeHoldingDeposit',
                      !profile.takeHoldingDeposit,
                    )
                  }
                />
                <span className="xs-fs16">Process holding deposit</span>
              </label>
              <label className="block mb15 shaded-text position-relative">
                <input
                  type="checkbox"
                  name="acceptBondCover"
                  checked={false}
                  onChange={() => false}
                  disabled={true}
                />
                <span className="xs-fs16">
                  Accept BondCover (<b>Coming soon</b>)
                </span>
                <div className="shaded-block"></div>
              </label>
            </div>
            {responseText && (
              <div className="alert alert-danger">{responseText}</div>
            )}

            <div className="row mt25 mobile-form-button">
              <div className="col-sm-5 pb5 tablet-only">
                <button
                  onClick={() =>
                    history.push(
                      urlTo(urlIds.propertyPublishingOptions, {
                        propertyId,
                        offerId,
                      }),
                    )
                  }
                  className="btn btn-transparent btn-left xs-text-center wa"
                >
                  <i className="icon-arrow-left left"></i>
                  <span>Back</span>
                </button>
              </div>
              <div className="col-sm-7 pb5">
                <button
                  disabled={disabled}
                  onClick={() =>
                    this.onContinueClick(mostImportantOptionTicked)
                  }
                >
                  Continue{' '}
                  <i className={spinner ? 'fa fa-spinner fa-pulse' : ''} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Preferences
