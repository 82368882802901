import React from 'react'

import PropTypes from 'prop-types'

import RatingForm from 'app/bond_cover/managers/review/bc_rating_form'
import ReviewedForm from 'app/bond_cover/managers/review/bc_reviewed_form'
import isEmptyObject from 'app/shared_components/check_empty_object'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'
import StatusBar from 'app/shared_components/status_bar'
import { urlTo } from 'app/sm/helpers'
import { SessionStorageUtils } from 'app/storage_utils'

function getRating(code, tenantRating, prop) {
  if (tenantRating) {
    for (let i = 0; i < tenantRating.length; i += 1) {
      if (tenantRating[i][prop] === code) {
        return tenantRating[i].rating
      }
    }
    return -1
  }
  return -1
}

class RatingFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disabledContinue: false,
      disabledCannotRate: false,
      permissionClicked: false,
      declineClicked: false,
      comment: '',
      secret: '',
    }
  }

  UNSAFE_componentWillMount() {
    const { fetchBondCoverRatingCategories, fetchBondCoverReview } = this.props
    const requestID = getParameter('requestid')
    const secret = getParameter('secret')
    this.setState({ permissionClicked: false, declineClicked: false, secret })

    fetchBondCoverReview(requestID, secret)
    fetchBondCoverRatingCategories()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentUser } = nextProps

    const requestID = getParameter('requestid')
    const secret = getParameter('secret')
    if (
      currentUser.isUserFetching ||
      currentUser.isUserProfileFetching ||
      currentUser.isVerified
    ) {
      SessionStorageUtils.removeItem('forwardUrl')
    } else {
      if (!!requestID && !!secret) {
        let forwardUrl = `/bc/managersign?requestid=${requestID}&secret=${secret}`
        SessionStorageUtils.setItem('forwardUrl', forwardUrl)
      }
    }

    const { tenantRating } = nextProps

    this.setState({ disabledContinue: true })
    if (this.state.declineClicked || this.state.permissionClicked) {
      this.setState({ disabledContinue: true })
      this.setState({ disabledCannotRate: true })
    }

    if (!isEmptyObject(tenantRating)) {
      tenantRating.forEach((rating) => {
        if (rating.Code === 'overall') {
          this.setState({ disabledContinue: false })
        }
      })
    }

    return false
  }

  getRating(code, tenantRating, prop) {
    if (!!tenantRating) {
      for (let i = 0; i < tenantRating.length; i++) {
        if (tenantRating[i][prop] === code) {
          return tenantRating[i].rating
        }
      }
    }
    return -1
  }

  decline = () => {
    this.setState({ declineClicked: true })
    history.push(urlTo('join'))
  }

  proceed = () => {
    const { currentUser, proceed, bondCoverRequest } = this.props
    const { comment, secret } = this.state
    const { isVerified } = currentUser
    const commentPost = { comment }
    this.setState({ permissionClicked: true })
    proceed(bondCoverRequest.guidID, secret, commentPost, isVerified)
  }

  update = (field) => {
    return (event) => this.setState({ [field]: event.target.value })
  }

  updateTenantRating = (ratingCode, rating, requestID, secret) => {
    return () => {
      const { createTenantRating, updateTenantRating, tenantRating } =
        this.props
      if (getRating(ratingCode, tenantRating, 'Code') === -1) {
        createTenantRating(ratingCode, rating, requestID, secret)
      } else {
        updateTenantRating(ratingCode, rating, requestID, secret)
      }
    }
  }

  render() {
    let {
      currentUser,
      ratingCategories,
      tenantRating,
      renter,
      error,
      message,
      currentLease,
      bondCoverRequest,
    } = this.props
    let { isVerified } = currentUser
    let address = currentLease ? currentLease.address : ''
    let guidID = bondCoverRequest ? bondCoverRequest.guidID : ''
    let {
      disabledContinue,
      disabledCannotRate,
      permissionClicked,
      declineClicked,
      secret,
    } = this.state
    let dashboardUrl =
      window.location.href.indexOf('agency') > 0
        ? '/agency/4824a732-2475-4ac7-8bfb-d27b5e35a45e/bc/dashboard'
        : '/portfolio/overview'

    if (error || message) {
      return (
        <ReviewedForm
          error={error}
          isVerified={isVerified}
          dashboardUrl={dashboardUrl}
          message={message}
        />
      )
    } else {
      return (
        <div className="two-col-box-flex-row sm64">
          <div className="col-first">
            <StatusBar progress="2" />
            <RatingForm
              currentUser={currentUser}
              address={address}
              tenantRating={tenantRating}
              getRating={this.getRating}
              update={this.update}
              updateTenantRating={this.updateTenantRating}
              disabledContinue={disabledContinue}
              disabledCannotRate={disabledCannotRate}
              renter={renter}
              ratingCategories={ratingCategories}
              requestID={guidID}
              secret={secret}
              permissionClicked={permissionClicked}
              declineClicked={declineClicked}
              proceed={this.proceed}
              decline={this.decline}
            />
          </div>
        </div>
      )
    }
  }
}

RatingFormContainer.propTypes = {
  fetchBondCoverRatingCategories: PropTypes.func.isRequired,
  fetchBondCoverReview: PropTypes.func.isRequired,
  bondCoverRequest: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  currentUser: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ).isRequired,
  proceed: PropTypes.func.isRequired,
  createTenantRating: PropTypes.func.isRequired,
  updateTenantRating: PropTypes.func.isRequired,
  tenantRating: PropTypes.oneOfType([
    PropTypes.objectOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      ),
    ),
    PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      ),
    ),
  ]),
  ratingCategories: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      ),
    ]),
  ),
  renter: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  currentLease: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      ),
    ]),
  ),
}

RatingFormContainer.defaultProps = {
  bondCoverRequest: null,
  tenantRating: null,
  ratingCategories: null,
  renter: null,
  currentLease: null,
  error: undefined,
  message: undefined,
}

export default RatingFormContainer
