import moment from 'moment'
import qs from 'qs'

import * as helpers from 'app/sm/helpers'
import * as APIHelpers from 'app/utils/api/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as viewingsHelpers from 'app/utils/viewings/helpers'

const defaultPublishedStatus = false
const defaultNotifyStatus = false

export const getPropertyReportData = (viewingGUID) => {
  const url = `sm/propertyreport/viewing/${viewingGUID}/manager`
  return APIHelpers.request({ url, method: 'GET' })
}

export const postAddViewing = (
  offerGUID,
  startDate,
  duration,
  fromScheduler,
  skipDoubleBookingCheck,
  viewingOptions = {},
) => {
  const {
    published = defaultPublishedStatus,
    notify = defaultNotifyStatus,
    notifyShortlistedApplicants,
    isNoneUser = false,
    inspectorsGUIDs,
  } = viewingOptions
  const url = `sm/offers/${offerGUID}/viewings?scheduler=${fromScheduler}&isnoneuser=${isNoneUser}&skipdoublebookingcheck=${skipDoubleBookingCheck}`
  const utcDate = startDate ? moment.utc(startDate).format() : ''
  const data = {
    startDate: utcDate,
    duration,
    published,
    notify,
    notifyShortlistedApplicants,
    inspectorsGUIDs,
  }
  return APIHelpers.request({ url, method: 'POST', data })
}

export const putEditViewing = (
  offerGUID,
  viewingGUID,
  startDate,
  duration,
  skipDoubleBookingCheck,
  viewingOptions = {},
) => {
  const {
    published = defaultPublishedStatus,
    notify = defaultNotifyStatus,
    notifyShortlistedApplicants,
  } = viewingOptions
  const url = `sm/offers/${offerGUID}/viewings/${viewingGUID}?skipdoublebookingcheck=${skipDoubleBookingCheck}`
  const utcDate = startDate ? moment.utc(startDate).format() : ''
  const data = {
    startDate: utcDate,
    duration,
    published,
    notify,
    notifyShortlistedApplicants,
  }

  return APIHelpers.request({ url, method: 'PUT', data })
}

export const getAllPropertyOffersForTeam = (teamGUID) => {
  const url = `sm/teams/${teamGUID}/propertyoffers`
  return APIHelpers.request({ url, method: 'GET' })
}

export const getAllCurrentViewingsForTeam = (teamGUID) => {
  const url = `sm/teams/${teamGUID}/currentviewings`
  return APIHelpers.request({ url, method: 'GET' })
}

export const getPreloadedPropertiesAndViewingsData = (
  teamGUID,
  options = {},
) => {
  const { searchParams, ...restOptions } = options

  const preparedParams = {
    ...(searchParams ? prepareSearchParams(searchParams) : {}),
    ...restOptions,
  }

  const url = `sm/team/${teamGUID}/preload`
  return APIHelpers.request({ url, method: 'GET', params: preparedParams })
}

export const putCancelViewing = (viewingGUID) => {
  const url = `sm/viewing/${viewingGUID}/cancel`
  return APIHelpers.request({ url, method: 'PUT' })
}

export const postArchiveProperty = (
  propertyGUID,
  cancelFutureViewings,
  declineUnsuccessfulApplications,
) => {
  const options = {
    cancelFutureViewings,
    declineUnsuccessfulApplications,
  }
  const url = `sm/properties/${propertyGUID}/archive`
  return APIHelpers.request({ url, method: 'POST', data: options })
}

export const getRegistrantsForViewing = (teamGUID, viewingGUID) => {
  const url = `sm/team/${teamGUID}/viewing/${viewingGUID}/registrants`
  return APIHelpers.request({ url, method: 'GET' })
}

export const postWalkInRenter = (data, viewingGUID) => {
  const url = `sm/viewing/${viewingGUID}/onsiteregistrant`
  return APIHelpers.request({ url, method: 'POST', data })
}

export const putWalkInRenter = (
  data,
  renterType,
  viewingGUID,
  registrationGUID,
) => {
  const url =
    renterType === 'renter'
      ? `sm/viewing/${viewingGUID}/viewingregistration/${registrationGUID}`
      : `sm/viewing/${viewingGUID}/onsiteregistrant/${registrationGUID}`
  return APIHelpers.request({ url, method: 'PUT', data })
}

export const deleteWalkInRenter = (viewingGUID, registrationGUID) => {
  const url = `sm/viewing/${viewingGUID}/onsiteregistrant/${registrationGUID}`
  return APIHelpers.request({ url, method: 'DELETE' })
}

const prepareSearchParams = (options) => {
  const {
    searchText = '',
    sortBy = viewingsHelpers.SORT_BY_ADDRESS,
    datePickerValue = '',
    todayOnly = false,
    assigned = '',
    property,
  } = options
  let finalStartDate =
    datePickerValue && !todayOnly
      ? helpers.dateUtil.getStandardUTCDateAtStart(datePickerValue)
      : ''
  let finalEndDate =
    datePickerValue && !todayOnly
      ? helpers.dateUtil.getStandardUTCDateAtEnd(datePickerValue)
      : ''
  if (todayOnly) {
    finalStartDate = helpers.dateUtil.getStandardUTCDateAtStart(
      moment().format(dateTimeHelpers.DATE_DAY_SHORT_STRING_MONTH_YEAR),
    )
    finalEndDate = helpers.dateUtil.getStandardUTCDateAtEnd(
      moment().format(dateTimeHelpers.DATE_DAY_SHORT_STRING_MONTH_YEAR),
    )
  }
  let assignedManager = assigned
  if (assigned === 'Any') {
    assignedManager = ''
  }

  return {
    q: searchText,
    sort_by: sortBy,
    start_time: finalStartDate,
    end_time: finalEndDate,
    assigned: assignedManager,
    property,
  }
}
