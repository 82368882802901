import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import warningImg from 'app/assets/icons/application_yellow_warning.png'
import statusActiveImg from 'app/assets/icons/round-green-tick.svg'
import { Box, Button, Flex } from 'app/components/design-system-components'
import SwitchButton from 'app/components/design-system-components/inputs/Switch'
import EnquiryResponderToggle from 'app/pages/teams/messages/components/EnquiryResponderToggle'
import { toggleEnquiryResponder } from 'app/services/http/messages'
import { getAliases } from 'app/services/http/teams/team-external-id-aliases'
import * as snugNotifier from 'app/services/snugNotifier'
import { FlexBox } from 'app/shared_components/shared_styled_component'
import { TeamIDAliasesModal } from 'app/sm/team_viewing_preference/components/status_report/TeamIDAliases'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { loadingStatesIds } from 'app/utils/loading-states'
import * as stringHelpers from 'app/utils/strings/helpers'
import * as texthelpers from 'app/utils/text/helpers'
import {
  DEFAULT_PROVIDER_TEXT,
  HelpIconComponentAndTooltip,
} from 'app/utils/utilityprovider/helpers'

const STATUS_ACTIVE = 'active'
const STATUS_ACTIVE_DEFAULT = 'active-default'
const EXTERNAL_TEAM_ID_STATUS_NAME = 'Snug Team ID'
const BACKGROUND_CHECK_STATUS_NAME = 'Background Check processing'
const UILITY_CONNECTION_PROVIDER_STATUS_NAME = 'Utility connection provider'
const AUTO_RESPONDER_STATUS_NAME = 'Autoresponder'

const StatusRow = styled.div`
  margin-top: 16px;
  line-height: 24px;
`

const StatusReportRow = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    height: 20px;
    width: 20px;
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`

const StatusReportRowSubtext = styled.div`
  margin-left: 36px;
  margin-top: 8px;
`

const StatusReport = ({
  statusData,
  teamGUID,
  teamSlug,
  fetchAgencyReport,
  isUserAdmin,
}) => {
  useEffect(() => {
    loadAliases()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    state: aliases,
    setState: setAliases,
    loadingStates: setAliasesLoadingStates,
    loadingStatesHelpers: setAliasesLoadingStatesHelpers,
  } = useStateWithLoading([])

  const loadAliases = () => {
    setAliasesLoadingStatesHelpers.startLoading()
    return getAliases(teamGUID)
      .then(({ data }) => {
        setAliases(data)
        setAliasesLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => setAliasesLoadingStatesHelpers.setError(err))
  }

  const [aliasesModal, setAliasesModal] = useState({
    isOpened: false,
    config: {},
  })

  const onOpenAliasesModal = (aliases) => {
    setAliasesModal({
      isOpened: true,
      config: {
        aliases,
        closeModal: () => onCloseAliasesModal(),
      },
    })
  }
  const onCloseAliasesModal = () => {
    setAliasesModal({ isOpened: false, config: {} })
  }

  const aliasesModalComponent = aliasesModal.isOpened && (
    <TeamIDAliasesModal
      aliases={aliasesModal.config.aliases}
      onCloseModal={aliasesModal.config.closeModal}
    />
  )

  function generateBackgroundCheckActivityURL() {
    return `/teams/${teamSlug}/activity/backgroundcheck`
  }

  const tooltipAndDefaultProviderText = (
    <>
      <Box px={1}>{DEFAULT_PROVIDER_TEXT}</Box> <HelpIconComponentAndTooltip />
    </>
  )

  const filteredStatusData = statusData?.filter(
    (statusItem) =>
      statusItem.name !== texthelpers.SHOW_AUTORESPONDER_MESSAGES_SCREEN,
  )

  const autoResponderStatusItem = statusData?.filter(
    (statusItem) => statusItem?.name === 'Autoresponder',
  )[0]

  const autoResponderActive =
    autoResponderStatusItem?.currentStatus === 'active' || false

  const toggleEnquiryResponderStatus = () => {
    toggleEnquiryResponder(teamGUID, !autoResponderActive)
      .then(() => {
        fetchAgencyReport(teamGUID)
      })
      .then(() =>
        snugNotifier.success(
          autoResponderActive
            ? 'Autoresponder deactivated'
            : 'Autoresponder activated',
        ),
      )
      .catch((error) => snugNotifier.error(error?.message))
  }

  const renderAutoresponderToggle = () => {
    return (
      <>
        <EnquiryResponderToggle
          onChange={toggleEnquiryResponderStatus}
          value={autoResponderActive}
          disabled={!isUserAdmin}
          buttonID=""
          isUserAdmin={isUserAdmin}
        />
      </>
    )
  }

  const getStatusNameClassName = (name) => {
    return `fs18 fw500 ${name !== AUTO_RESPONDER_STATUS_NAME ? 'ml20' : 'ml5'}`
  }

  return (
    <div>
      {filteredStatusData &&
        filteredStatusData.map((oneStatus, index) => {
          const isStringAValidURL = stringHelpers.isStringAValidURL(
            oneStatus.description,
          )
          return (
            <StatusRow key={index}>
              <StatusReportRow>
                <Flex alignItems="center">
                  <span>
                    {oneStatus.name === AUTO_RESPONDER_STATUS_NAME &&
                      renderAutoresponderToggle()}
                    {oneStatus.name !== AUTO_RESPONDER_STATUS_NAME && (
                      <>
                        {oneStatus.currentStatus === STATUS_ACTIVE ||
                        oneStatus.currentStatus === STATUS_ACTIVE_DEFAULT ? (
                          <img src={statusActiveImg} alt="status active" />
                        ) : (
                          <img src={warningImg} alt="status notice" />
                        )}
                      </>
                    )}
                  </span>
                  <span className={getStatusNameClassName()}>
                    {oneStatus.name}
                  </span>
                </Flex>
                <div>
                  {}{' '}
                  {!isStringAValidURL &&
                    oneStatus.name === BACKGROUND_CHECK_STATUS_NAME && (
                      <span>
                        <Link to={generateBackgroundCheckActivityURL()}>
                          {oneStatus.description}
                        </Link>
                      </span>
                    )}
                  {!isStringAValidURL &&
                    oneStatus.name ===
                      UILITY_CONNECTION_PROVIDER_STATUS_NAME && (
                      <FlexBox justifyContent="end">
                        <Flex alignItems="center">
                          {oneStatus.description}{' '}
                          {oneStatus.currentStatus ===
                            STATUS_ACTIVE_DEFAULT && (
                            <>{tooltipAndDefaultProviderText}</>
                          )}
                        </Flex>
                      </FlexBox>
                    )}
                  {!isStringAValidURL &&
                    oneStatus.name !== BACKGROUND_CHECK_STATUS_NAME &&
                    oneStatus.name !==
                      UILITY_CONNECTION_PROVIDER_STATUS_NAME && (
                      <span>{oneStatus.description}</span>
                    )}
                  {oneStatus.name === EXTERNAL_TEAM_ID_STATUS_NAME &&
                    setAliasesLoadingStates.state ===
                      loadingStatesIds.LOADED && (
                      <FlexBox justifyContent="end">
                        <Button
                          variant="linkBlue"
                          onClick={() => onOpenAliasesModal(aliases)}
                        >
                          aliases
                        </Button>
                      </FlexBox>
                    )}
                  {isStringAValidURL && (
                    <a
                      href={oneStatus.description}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{oneStatus.description}</span>
                      <i className="fa fa-external-link text-black ml5" />
                    </a>
                  )}
                </div>
              </StatusReportRow>
              {oneStatus.message.length > 0 && (
                <StatusReportRowSubtext>
                  {oneStatus.message}
                </StatusReportRowSubtext>
              )}
            </StatusRow>
          )
        })}
      {aliasesModalComponent}
    </div>
  )
}

export default StatusReport
