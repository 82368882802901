import { Box, Modal } from 'app/components/design-system-components'
import * as textHelpers from 'app/utils/text/helpers'

interface CRATermsModalProps {
  toggleCRATermsModal: () => void
}

function CRATermsModal({ toggleCRATermsModal }: CRATermsModalProps) {
  const modalBody = (
    <Box>
      <Box>{textHelpers.CRA_POLICY_TEXT}</Box>
      <Box>
        Being selected in the ballot would not impact your rental assistance
        payment. Rather, only at the point of accepting an offer of housing and
        signing a renter agreement would your CRA status change. At that point,
        it would be your responsibility to update Centrelink that your
        accommodation arrangements have changed.
      </Box>
    </Box>
  )
  return (
    <Modal
      modalHeading="Commonwealth Rent Assistance"
      modalBody={modalBody}
      showPrimaryButton={false}
      toggleModal={toggleCRATermsModal}
      secondaryAction={toggleCRATermsModal}
      secondaryLabel="Close"
      modalFooter={undefined}
      primaryLabel={undefined}
      primaryAction={undefined}
    />
  )
}

export default CRATermsModal
