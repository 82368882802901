import React from 'react'

import moment from 'moment'

import { DetailsBoxCard } from 'app/components/display/display'
import { urlTo } from 'app/sm/helpers'
import { petTypes } from 'app/sm/helpers'
import { DATE_WITH_DASH, DEFAULT_UNIX_TIME } from 'app/utils/datetime/helpers'

const PetsDetailsBox = ({ removePet, pet, readOnly = false, from = '' }) => {
  //TODO: fix hack
  const invalidDate = moment(DEFAULT_UNIX_TIME, 'YYYY-MM-DD')
  const isBirthDateValid = moment(pet.dateOfBirth, DATE_WITH_DASH).isAfter(
    invalidDate,
  )
  const petYears = moment().diff(pet.dateOfBirth, 'years')
  const outdoorAndIndoor = pet.outdoor && pet.indoor
  const fromRenterSummary = from === 'renterSummary'
  const details = [
    `${petTypes[pet.type]} (${pet.breed})`,
    ...(isBirthDateValid ? [`${petYears} y.o.`] : []),
    `${pet.outdoor ? 'Outdoor' : ''}${outdoorAndIndoor ? '/' : ''}${
      pet.indoor ? 'Indoor' : ''
    }`,
    `References? ${pet.petReferences.length !== 0 ? 'Yes' : 'No'}`,
    `Desexed? ${pet.isDesexed ? 'Yes' : 'No'}`,
    `Registered? ${pet.isRegistered ? 'Yes' : 'No'}`,
    `${
      pet.isRegistered ? `Registration number: ${pet.registrationNumber}` : ''
    }`,
    `${pet.description ? `Description: ${pet.description}` : ''}`,
  ]
  return (
    <DetailsBoxCard
      title={pet.name}
      titleClass={fromRenterSummary ? 'ml0' : 'ml20'}
      details={details}
      icon={fromRenterSummary ? '' : 'icon-pets'}
      componentClass={`${fromRenterSummary && 'no-box-shadow p0'}`}
      disabled={readOnly}
      onRemove={() => removePet(pet.guidID)}
      editUrl={urlTo('PetDetailsEdit', pet)}
    />
  )
}

export default PetsDetailsBox
