import React from 'react'

import { Flex } from 'app/components/design-system-components'
import { UCSProviderConfig } from 'app/constants/ucs.constants'
import ActivityCubeListItem from 'app/dashboard/team_activity/activity_cube_list_item/component'

const ActivityCubeList = ({
  enquiriesTotal = 0,
  viewersTotal = 0,
  applicationsTotal = 0,
  viewingsTotal = 0,
  propertyOffersTotal = 0,
  utilityProvider,
  sentUtilityReferrals = 0,
  vacantProperties = 0,
}) => {
  const ucsProviderConfig = UCSProviderConfig[utilityProvider]
  const UcsProviderIcon = ucsProviderConfig?.icon

  return (
    <Flex mt={6} flexWrap="wrap">
      <ActivityCubeListItem
        header={'Properties listed'}
        value={propertyOffersTotal}
      />
      <ActivityCubeListItem header={'Enquiries'} value={enquiriesTotal} />
      <ActivityCubeListItem header={'Viewings'} value={viewingsTotal} />
      <ActivityCubeListItem header={'Attendees'} value={viewersTotal} />
      <ActivityCubeListItem header={'Applications'} value={applicationsTotal} />
      <ActivityCubeListItem
        header="Vacant Properties (Now)"
        value={vacantProperties}
      />
      <ActivityCubeListItem
        header={'Utility Referrals'}
        value={sentUtilityReferrals}
        icon={UcsProviderIcon && <UcsProviderIcon width="auto" height="38px" />}
      />
    </Flex>
  )
}

export default ActivityCubeList
