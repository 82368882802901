import { LogFetchError } from 'app/shared_components/helpers'
import * as sharedHelpers from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'
import { stringifyQuery } from 'app/sm/helpers'

export const getPropertySummary = (id, queryParams) => {
  const url =
    `${apiBaseUrl}sm/properties/${id}/summary` + stringifyQuery(queryParams)

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (summary) => ({ ok: true, summary }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getPropertyManagerSummary = (id) => {
  const url = `${apiBaseUrl}sm/properties/${id}/managersummary`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (managerSummary) => ({ ok: true, managerSummary }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getProperty = (id) => {
  const url = `${apiBaseUrl}sm/properties/${id}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (property) => ({ ok: true, property }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getOffer = (id) => {
  const url = `${apiBaseUrl}sm/offers/${id}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (offer) => ({ ok: true, offer }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const saveFavorite = (propertyId) => {
  const url = `${apiBaseUrl}sm/properties/${propertyId}/favorite`

  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({}),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve() // We get nothing for now, when a entity is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const removeFavorite = (propertyId) => {
  const url = `${apiBaseUrl}sm/properties/${propertyId}/favorite`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text,
          }),
    )
    .then(
      (_) => ({ ok: true, _ }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postForViewing = (payload) => {
  const url = `${apiBaseUrl}sm/enquiry/viewingrequest`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
  })
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const registerForViewing = (id) => {
  const url = `${apiBaseUrl}sm/viewing/${id}/register`

  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({}),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve() // We get nothing for now, when a entity is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const unregisterForViewing = (id) => {
  const url = `${apiBaseUrl}sm/viewing/${id}/unregister`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (_) => ({ ok: true, _ }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getAgencyRules = (agencyGUID) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/rules`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}
