import React from 'react'

import moment from 'moment'

import * as Display from 'app/components/display/display'
import { durationOptionsNumbers } from 'app/components/display/edit_viewing_modal/viewing_modal_data'
import * as helpers from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

class EditViewingTime extends React.Component {
  constructor(props) {
    super(props)
    const selectedTime = props.viewingTime
      ? helpers.parseViewing(props.viewingTime)
      : {}
    this.state = {
      updateClicked: false,
      isActive: false,
      duration: durationOptionsNumbers.indexOf(selectedTime.duration),
      startDate: moment(
        selectedTime.listedDate,
        dateTimeHelpers.DATE_DAY_SHORT_STRING_MONTH_YEAR,
      ).format(dateTimeHelpers.DATE_WITH_DASH),
      startTime: selectedTime.startTime || '',
      notify: selectedTime.notify,
      notifyShortlistedApplicants: selectedTime.notifyShortlistedApplicants,
      published: selectedTime.published,
      errors: {
        editViewingTimeModal: {
          duration: '',
          notify: '',
          published: '',
          startDate: '',
          startTime: '',
        },
      },
    }
  }

  componentDidMount() {
    this.props.clearError()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error && this.state.updateClicked) {
      this.setState({ updateClicked: false })
    }
  }

  isTimePickerActive(isActive) {
    this.setState({ isActive })
  }

  updateEditViewingModal = (modalValues) => {
    this.setState(
      {
        ...this.state,
        ...modalValues,
      },
      () => this.updateViewing(),
    )
  }

  updateViewing() {
    const {
      startTime,
      startDate,
      duration,
      published,
      notify,
      notifyShortlistedApplicants,
    } = this.state
    const { editViewing, viewingTime, offers = [], propertyId } = this.props
    const formattedStartDate = moment(
      startDate,
      dateTimeHelpers.DATE_WITH_DASH,
    ).format(helpers.dateWithWeekDayTimeFormatNoComma)
    const activeOffer = offers.find((o) => o.isActive) || {}
    const fullSelectedDate = `${formattedStartDate} ${startTime}`
    this.setState({ updateClicked: true })
    const selectedDuration = durationOptionsNumbers[duration]
    editViewing(
      propertyId,
      activeOffer.guidID,
      viewingTime.guidID,
      moment(fullSelectedDate, helpers.dateTimeStampFormat),
      selectedDuration,
      { published, notify, notifyShortlistedApplicants },
    )
  }

  render() {
    const { showEditViewingModal, fetchAllPropertyFutureViewings, teamGUID } =
      this.props
    const {
      startDate,
      startTime,
      duration,
      published,
      notifyShortlistedApplicants,
    } = this.state
    const viewingOptions = {
      startDate,
      startTime,
      duration,
      published,
      notifyShortlistedApplicants,
    }
    return (
      <Display.EditViewingModal
        title="Edit Viewing Time"
        primaryActionLabel="Update"
        secondaryActionLabel="Cancel"
        primaryButtonActionInParent={this.updateEditViewingModal}
        secondaryButtonAction={() => showEditViewingModal(false)}
        toggleModal={() => showEditViewingModal(false)}
        {...viewingOptions}
        modalUpdated="editViewingModal"
        teamGUID={teamGUID}
        fetchAllPropertyFutureViewings={fetchAllPropertyFutureViewings}
        editing={true}
      />
    )
  }
}

export default EditViewingTime
