import { connect } from 'react-redux'

import { updateOfferViewingRegistrantsLimit } from 'app/sm/onlist_details/action'
import LimitViewingRegistrants from 'app/sm/onlist_details/limit_viewing_registrant_details'

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  updateOfferViewingRegistrantsLimit: (
    agencyGUID,
    offerGUID,
    viewingScheduleZone,
  ) =>
    dispatch(
      updateOfferViewingRegistrantsLimit(
        agencyGUID,
        offerGUID,
        viewingScheduleZone,
      ),
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LimitViewingRegistrants)
