import { apiNoVersionBase } from 'config/env'

import { api } from 'app/utils/api/helpers'

export const healthCheck = () => {
  return api.get(`/health-check`, {
    baseURL: `${apiNoVersionBase}`,
  })
}

export const maintenanceMessage = () => {
  return api.get(
    `https://snugco.s3.ap-southeast-2.amazonaws.com/app.snug.com/config/system_maintenance.json`,
    {
      baseURL: `${apiNoVersionBase}`,
      'Cache-Control': 'no-cache',
      params: {
        timestamp: new Date().valueOf(),
      },
    },
  )
}
