import { connect } from 'react-redux'

import OnboardingPage from 'app/pages/teams/onboarding/index'

const mapStateToProps = ({ session }) => ({
  teams: session.teams,
  currentTeam: session.currentTeam,
  currentUser: session.currentUser,
})

export default connect(mapStateToProps)(OnboardingPage)
