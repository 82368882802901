import React from 'react'

import { SNUG_PRIVACY_URL, SNUG_TERMS_URL } from 'app/sm/helpers'
import * as textHelpers from 'app/utils/text/helpers'

const TermsAndConditions = () => {
  return (
    <>
      <p className="pt20 fs12 mb0 lh14 gray-light-color">
        By continuing, you agree to Snug&rsquo;s{' '}
        <a target="_blank" rel="noopener noreferrer" href={SNUG_TERMS_URL}>
          Terms of Use
        </a>{' '}
        and{' '}
        <a target="_blank" rel="noopener noreferrer" href={SNUG_PRIVACY_URL}>
          Privacy Policy
        </a>
        {textHelpers.TRADEMARK_FOOTER_CONTENT_LOGIN_COMPS_TEXT}
      </p>
    </>
  )
}

export default TermsAndConditions
