import BaseInput from 'app/components/forms/base_input/component'
import {
  firstElementOrEmptyString,
  withValidation,
} from 'app/components/forms/util'
import * as validations from 'app/shared_components/validations'

const notNullValidation = (value) =>
  firstElementOrEmptyString(validations.validateNotEmpty(value))

const InputTextRequired = withValidation(BaseInput, notNullValidation)

export default InputTextRequired
