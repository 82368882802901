import {
  ApplicationCategory,
  ApplicationStatusCategory,
  isRecentlyDeclinedTime,
  isStatusDeclined,
  isStatusPMWithdrawn,
  isStatusWithdrawn,
} from 'app/sm/helpers'
import { getDiffInDays } from 'app/utils/datetime/helpers'
import { isUserAnAdmin } from 'app/utils/roles'

export const APPLY_ANYWHERE_APPLICATION = 2

export const RECENTLY_DECLINED_THRESHOLD_IN_DAYS = 7

export const AUTO_WITHDRAWN_APPLICATION_EXPLANATION =
  'Auto-withdrawn 60 days after applied. Accessible by team Admin.'
export const AUTO_WITHDRAWN_UNAPPROVED_APPLICATION_EXPLANATION =
  'Auto-withdrawn 14 days after applied as not Shortlisted or moved to Pre-approval / With PM.'
export const AUTO_WITHDRAWN_SHORTLISTED_APPLICATION_EXPLANATION =
  'Auto-withdrawn 28 days after applied as Shortlisted.'

export const getApplicationStatus = (application) => {
  return application && ApplicationCategory[application.status]
}

export const isAnyWithdrawnOrDeclinedStatus = (applications = []) =>
  applications.filter(
    (application) =>
      isStatusDeclined(getApplicationStatus(application)) ||
      isStatusWithdrawn(getApplicationStatus(application)) ||
      isStatusPMWithdrawn(getApplicationStatus(application)),
  )

export const shouldShowBackgroundCheckReport = (
  status,
  attachedToProfile,
  optOutBackgroundCheck,
  applicationGUID,
) => {
  const isRenterBackgroundCheckCompleted =
    status === 'complete' && attachedToProfile && !optOutBackgroundCheck
  const isManagerBackgroundCheckCompleted =
    status === 'complete' && applicationGUID
  return isRenterBackgroundCheckCompleted || isManagerBackgroundCheckCompleted
}

export const isBGCheckCompletedByManager = (
  status,
  attachedToProfile,
  optOutBackgroundCheck,
  applicationGUID,
) => {
  const isRenterBackgroundCheckCompleted =
    status === 'complete' && attachedToProfile && !optOutBackgroundCheck
  const isManagerBackgroundCheckCompleted =
    status === 'complete' && applicationGUID
  return !isRenterBackgroundCheckCompleted && isManagerBackgroundCheckCompleted
}

export const SELECT_REASON_BEFORE_DECLINING_APPLICATION =
  'Please select a reason before declining the application'
export const ADD_COMMENT_BEFORE_DECLINING_APPLICATION =
  'Please add a comment for declining the application'

export const successfullyRegisteredForScheduledViewing = (
  firstName,
  lastName,
) => {
  return `Successfully registered ${firstName} ${lastName} for the viewing`
}

export const isAnyApplicantInApplicationsNull = (applications = []) => {
  let anyApplicationNull = false
  applications.forEach((application) => {
    if (!application.applicants) anyApplicationNull = true
  })
  return anyApplicationNull
}

export const considerRentingToThem = (value) => {
  switch (value) {
    case 1:
      return 'Yes'
    case 2:
      return 'No'
    case 3:
      return 'Maybe'
    default:
      return 'N/A'
  }
}

export const mapApplicationWithRecentlyDeclinedFlag = (application) => {
  const applicationStatusStr = getApplicationStatus(application)
  const statusUpdateDuration = getDiffInDays(application.lastEventAt)
  const remainingDays =
    RECENTLY_DECLINED_THRESHOLD_IN_DAYS - statusUpdateDuration
  return {
    ...application,
    isRecentlyDeclined:
      isStatusDeclined(applicationStatusStr) &&
      isRecentlyDeclinedTime(application.lastEventAt),
    remainingDays,
  }
}

export const shouldDelaySendingAAApplication = (
  rentalHistoryVerified,
  employmentHistoryPresent,
  employmentHistoryVerified,
) => {
  if (!rentalHistoryVerified) {
    return true
  }
  return employmentHistoryPresent && !employmentHistoryVerified
}

export const isApplicationDetailsAccessible = (
  application,
  currentAgency,
  currentTeam,
) => {
  const applicationStatusStr = getApplicationStatus(application)
  if (
    isStatusDeclined(applicationStatusStr) ||
    isManuallyWithdrawn(application) ||
    !currentTeam.isActive
  )
    return false
  const isAdmin = !!currentAgency && isUserAnAdmin(currentAgency)
  return (
    !application.autoWithdrawnAt ||
    (isAdmin &&
      isInApplicationStatusesAllowedForAdminAccessAfterAutoWithdraw(
        application,
      ))
  )
}

export const shouldShowApplicationMoveTo = (application) => {
  const applicationStatusStr = getApplicationStatus(application)
  return (
    !isManuallyWithdrawn(application) &&
    (!isStatusDeclined(applicationStatusStr) || application.isRecentlyDeclined)
  )
}

export const isManuallyWithdrawn = (application) => {
  const applicationStatusStr = getApplicationStatus(application)
  return (
    isStatusWithdrawn(applicationStatusStr) ||
    isStatusPMWithdrawn(applicationStatusStr)
  )
}

export const isAutoWithdrawn = (application) => !!application.autoWithdrawnAt

export const isWithdrawn = (application) => {
  return isManuallyWithdrawn(application) || isAutoWithdrawn(application)
}

export const isAnyApprovedStatus = (application) => {
  return [
    ApplicationStatusCategory.PreApproval,
    ApplicationStatusCategory.WithOwner,
    ApplicationStatusCategory.OwnerApproval,
    ApplicationStatusCategory.Approved,
    ApplicationStatusCategory.Offered,
    ApplicationStatusCategory.PaymentPending,
    ApplicationStatusCategory.Accepted,
    ApplicationStatusCategory.DepositReceived,
    ApplicationStatusCategory.LeaseSent,
    ApplicationStatusCategory.Leased,
  ].includes(application.status)
}

// needs to be updated here as well: pkg/sm/application.go
export const isAnyApplicationStatusesAllowedForAdminAccessAfterAutoWithdrawn = (
  application,
) => {
  return [
    ApplicationStatusCategory.Accepted,
    ApplicationStatusCategory.DepositReceived,
    ApplicationStatusCategory.LeaseSent,
    ApplicationStatusCategory.Leased,
  ].includes(application.status)
}

export const isStatusEligibleForUtilityReferral = (application) =>
  application &&
  [
    ApplicationStatusCategory.Approved,
    ApplicationStatusCategory.Offered,
    ApplicationStatusCategory.PaymentPending,
    ApplicationStatusCategory.DepositReceived,
    ApplicationStatusCategory.Accepted,
    ApplicationStatusCategory.LeaseSent,
  ].includes(application.status)

export const isShortlistedStatus = (application) =>
  application && application.status === ApplicationStatusCategory.Shortlisted

//TODO: Make number of days in explanation dynamic (BE autoWithdrawnAfterElapsedHours)
export const autoWithdrawnExplanation = (application) => {
  if (isShortlistedStatus(application))
    return AUTO_WITHDRAWN_SHORTLISTED_APPLICATION_EXPLANATION
  if (!isAnyApprovedStatus(application))
    return AUTO_WITHDRAWN_UNAPPROVED_APPLICATION_EXPLANATION
  return AUTO_WITHDRAWN_APPLICATION_EXPLANATION
}

export const isInApplicationStatusesAllowedForAdminAccessAfterAutoWithdraw = (
  application,
) => {
  if (
    isAnyApplicationStatusesAllowedForAdminAccessAfterAutoWithdrawn(application)
  )
    return true
  return false
}

export const shortWorkflowStatuses = () => [
  ApplicationStatusCategory.Processing,
  ApplicationStatusCategory.AwaitingInfo,
  ApplicationStatusCategory.Shortlisted,
  ApplicationStatusCategory.Declined,
  ApplicationStatusCategory.PreApproval,
  ApplicationStatusCategory.WithOwner,
  ApplicationStatusCategory.Approved,
  ApplicationStatusCategory.Offered,
  ApplicationStatusCategory.Leased,
]

export const isStatusBgCEligible = (app) =>
  [
    ApplicationStatusCategory.OwnerApproval,
    ApplicationStatusCategory.PreApproval,
    ApplicationStatusCategory.Offered,
    ApplicationStatusCategory.PaymentPending,
    ApplicationStatusCategory.Accepted,
    ApplicationStatusCategory.LeaseSent,
    ApplicationStatusCategory.Leased,
    ApplicationStatusCategory.Approved,
    ApplicationStatusCategory.DepositReceived,
  ].includes(app.status)

export const isApplicationMergeable = (app) =>
  !!app &&
  !isWithdrawn(app) &&
  ![
    ApplicationStatusCategory.Draft,
    ApplicationStatusCategory.Declined,
  ].includes(app.status)
