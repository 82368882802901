import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Button } from 'app/components/design-system-components'
import { Box } from 'app/components/design-system-components'

type Props = {
  key: string
}

const TOOLTIP_MESSAGE =
  'Requires team to be Active and on a Growth Plan or higher to enable'

export const InactiveTeamActivationButton = ({ key }: Props) => (
  <Box data-tooltip-content="" data-tooltip-id={key}>
    <Button height="40px" disabled ml={3}>
      Activate
    </Button>
    <ReactTooltip id={key} data-type="info">
      {TOOLTIP_MESSAGE}
    </ReactTooltip>
  </Box>
)
