import React from 'react'

import localforage from 'localforage'
import moment from 'moment'
import qs from 'qs'
import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import ApplicationLeftColumn from 'app/application_revamp/components/Applicant'
import DocumentsViewer from 'app/application_revamp/components/AttachmentViewerModal'
import Dropdown from 'app/application_revamp/components/Common/Dropdown/index'
import InsightsPanel from 'app/application_revamp/components/Household'
import ApplicationToolbar from 'app/application_revamp/components/Toolbar'
import applicationEye from 'app/assets/icons/application-eye.png'
import mailIcon from 'app/assets/icons/email-alert.png'
import exclamation from 'app/assets/icons/exclamation.png'
import greenTick from 'app/assets/icons/green-tick.png'
import mosaicIcon from 'app/assets/icons/mosaic.png'
import starBlue from 'app/assets/icons/star-blue.svg'
import starEmpty from 'app/assets/icons/star-empty.png'
import starFilled from 'app/assets/icons/star-filled.png'
import jobIcon from 'app/assets/icons/summary_icons/job.svg'
import Button from 'app/components/buttons/default/component'
import {
  Button as NewButton,
  Spinner as NewSpinner,
} from 'app/components/design-system-components'
import {
  Box,
  ButtonWithIcon,
  Flex,
} from 'app/components/design-system-components'
import { ChevronLeftRounded } from 'app/components/design-system-components/icons/navigation'
import { mediaQueries } from 'app/components/design-system-components/styles/variables'
import SwitchToNew from 'app/components/design-system-components/SwitchToNew'
import * as Display from 'app/components/display/display'
import {
  defaultEditViewingModalValues,
  durationOptionsNumbers,
} from 'app/components/display/edit_viewing_modal/viewing_modal_data'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import * as Headers from 'app/components/display/text/standard_text/standard-headings/component'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import { integrationBrands } from 'app/constants/integrations.constants'
import { getActiveIntegrationsSendingTenancyOnboard } from 'app/constants/pms.constants'
import ShareWithOwnerModal from 'app/dashboard/shareWithOwnerModal'
import ApplicationSideTabs from 'app/features/teams/applications/application-activities/ApplicationSideTabs'
import { ApplicationFollowers } from 'app/features/teams/applications/ApplicationFollowers'
import { DownloadApplicationModal } from 'app/features/teams/applications/download-application/DownloadApplicationModal'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { StyledCopyableAppIDContainer } from 'app/match/apply/application_confirmation/component'
import { ManagerAddressHistoryComponent } from 'app/match/apply/application_summary_before_submit/components/address_history_container/component'
import BackgroundCheckContainer from 'app/match/apply/application_summary_before_submit/components/background_check_container/component'
import ManagerComplianceContainer from 'app/match/apply/application_summary_before_submit/components/compliance_container/component'
import EmergencyContactContainer from 'app/match/apply/application_summary_before_submit/components/emergency_container/component'
import IdentityDocumentsContainer from 'app/match/apply/application_summary_before_submit/components/identity_container/component'
import IncomeContainer from 'app/match/apply/application_summary_before_submit/components/income_container/component'
import ManagerAttachment from 'app/match/apply/application_summary_before_submit/components/manager_attachments_component/component'
import ManagerOtherContainer from 'app/match/apply/application_summary_before_submit/components/manager_other_info_container/component'
import PetsContainer from 'app/match/apply/application_summary_before_submit/components/pet_container/component'
import PreferenceComponent from 'app/match/apply/application_summary_before_submit/components/preference_container/component'
import PersonalReferenceContainer from 'app/match/apply/application_summary_before_submit/components/reference_container/component'
import StudentContainer from 'app/match/apply/application_summary_before_submit/components/student_container/component'
import TicaCheck from 'app/match/apply/application_summary_before_submit/components/TicaCheck'
import ReferenceResultComponent from 'app/match/rental_reference/display/renter_address_history_detailed/rental_reference_detailed/rental_reference_result/rental_reference_result_item'
import BasicModal from 'app/modals/basic_modal'
import {
  getApplicationsDetails,
  shortListApplication,
} from 'app/services/http/applications'
import { updateStatusWithSideEffectActions } from 'app/services/http/applications/workflow'
import { resendReferenceRequest } from 'app/services/http/employment'
import * as snugNotifier from 'app/services/snugNotifier'
import CopyableText from 'app/shared_components/CopyableText'
import * as Form from 'app/shared_components/form_component'
import CenterContentContainer from 'app/shared_components/layout_component/center_content_component/component'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import MoveApplicationPropertyModal from 'app/sm/applications/components/move_application/move_application_property_modal'
import Spinner from 'app/sm/common/spinner'
import {
  ApplicationCategory,
  calculateDuration,
  calculateTotalWeeklyIncome,
  capitalizeFirstLetter,
  deCapitalizeFirstLetter,
  DownloadOptions,
  EmploymentDeclineReasons,
  EmploymentTypes,
  EmploymentVerifyStatus,
  getApplicantStatus,
  getBusinessNumberLabel,
  getSentenceWords,
  hasActiveOfferGuid,
  isApplicantApplied,
  isEmploymentDeclineContactPerson,
  isEmploymentDeclineEmploymentDate,
  isEmploymentDeclineOther,
  isEmploymentDeclineReasonCompanyName,
  isEmploymentDeclineReasonJobTitle,
  isEmploymentDeclineReasonSalary,
  isEmploymentStatusUnverified,
  isEmploymentStatusVerified,
  isPrimaryApplicant,
  isSecondaryApplicant,
  isSelfEmployed,
  isSelfEmployedNoAccountant,
  isStatusApplied,
  isStatusDeclined,
  isStatusOffered,
  isStatusQuickApplied,
  isStatusShortlisted,
  noteActionType,
  parseDataUtil,
  PDFTypes,
  Profile,
  selectAgencyGUID,
  selectApplicants,
  selectApplicationGUID,
  selectPrimaryApplicant,
  selectPropertyGUID,
  trimByWord,
  urlTo,
} from 'app/sm/helpers'
import * as helpers from 'app/sm/helpers'
import {
  ApplicationMoveToButton,
  ApplicationWorkflowContextMenu,
  ApplicationWorkflowProgressChart,
  ApplicationWorkflowSaveButton,
  ClickableStageOption,
  getRequestedInfoSubstage,
  getSelectedSubStage,
  isWorkflowDeclined,
  isWorkflowDeclinedFlagged,
  isWorkflowLeaseSigned,
  isWorkflowOffered,
  isWorkflowReviewStage,
} from 'app/sm/properties/components/property_search_filter_util'
import ApplicationApprovedModal from 'app/sm/renter_applications/components/application_approved'
import DeclineApplicationModal from 'app/sm/renter_applications/components/decline_application'
import DepositReceivedOptionsModal from 'app/sm/renter_applications/components/depositReceivedOptionsModal'
import { MarkAsLeasedModal } from 'app/sm/renter_applications/components/markAsLeasedModal'
import EditSummaryModal from 'app/sm/renter_applications/renter_application_detailed/components/edit-summary-modal'
import FloatingSet from 'app/sm/renter_applications/renter_application_detailed/components/FloatingSet'
import RequestInfoModal from 'app/sm/renter_applications/renter_application_detailed/components/request-info-modal'
import { applicationsAttributesIDs } from 'app/sm/team_viewing_preference/applications-attributes.constants'
import { checkIfBGCheckNotSupportedInState } from 'app/utils/address/helpers'
import {
  isStatusBgCEligible,
  shouldShowApplicationMoveTo,
} from 'app/utils/applications/helpers'
import * as applicationHelpers from 'app/utils/applications/helpers'
import {
  HOUR_DAY_MONTH_YEAR_FORMAT,
  TRIMMED_DAY_MONTH_YEAR_FORMAT,
} from 'app/utils/datetime/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as phoneNumberHelpers from 'app/utils/phonenumber/helpers'
import * as stringHelpers from 'app/utils/strings/helpers'
import {
  covertBoolStringToBool,
  currentEmploymentStatus,
} from 'app/utils/strings/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import * as viewingsHelpers from 'app/utils/viewings/helpers'
import { isFollowingEnabled } from 'config/features'
import * as Features from 'config/features'

import 'app/sm/renter_applications/renter_application_detailed/scss/new_applicant-details.scss'

const ownerPreferencesToastifyText = (offerId) => (
  <div>
    We have updated the{' '}
    <Link to={urlTo('profilePreferences', { offerId })}>owner preferences</Link>
  </div>
)

const StyledBreadcrumbs = styled(Box)`
  display: none;
  ${mediaQueries.lg} {
    display: block;
    ${(props) =>
      props.isManagerAdded
        ? `margin-top: -${props.theme.space[7] + 2}px;
        margin-bottom: -${props.theme.space[3] + 2}px;`
        : `margin-top: -${props.theme.space[1]}px;
        margin-bottom: -${props.theme.space[2]}px;`}
  }
`

const StyledDisplayContent = styled(Box)`
  ${mediaQueries.lg} {
    margin-top: -35px;
  }
`

export const sortApplicants = (a, b) => {
  if (a.isPrmary) return 1
  else if (b.isPrimary) return 1
  return -1
}
const DefaultAvailableLease = [
  { name: '6 months', id: 0 },
  { name: '12 months', id: 1 },
  { name: '24 months', id: 2 },
  { name: '36 months', id: 3 },
]
const SUCCESS_SUMMARY_UPDATED = 'Requested Summary Updated'

const HEADER_HEIGHT = 70
const APP_TOOLBAR_FOOTER_HEIGHT = 80
const LEFT_SWITCHER_SPACE = HEADER_HEIGHT + APP_TOOLBAR_FOOTER_HEIGHT
const LEFT_SWITCER_GUTTER_SPACE = 15
const HEADER_AND_FOOTER_HEIGHT = 340

const optimisingInsightsDropdownContent = [
  {
    id: 'occupancyPreferenceWeighting',
    label: 'Occupancy',
  },
  {
    id: 'yieldPreferenceWeighting',
    label: 'Rent',
  },
  {
    id: 'conditionPreferenceWeighting',
    label: 'Condition',
  },
]

export function IconNameValue(props) {
  const { icon, name, value, link } = props
  const wrappedValue = link ? <a href={`${link}:${value}`}>{value}</a> : value
  return (
    <div className="flex-row-center pb10">
      {icon && <i className={`${icon} ibm`} />}
      <span className="ibm fw600 pr10 mra w45p">{name}:</span>
      <span className="ibm w65p">{wrappedValue}</span>
    </div>
  )
}

export function rentToIncomeRatioCalculator(applicants, weeklyRent) {
  if (!applicants) {
    return
  }
  const tenantEmployersArrays = applicants.map((a) => a.employers)
  const tenantEmployersArray = [].concat(...tenantEmployersArrays)
  const currentEmployersArray = tenantEmployersArray.filter(
    (e) => e.stillEmployed,
  )
  const totalEmployedWeeklyNetIncome = calculateTotalWeeklyIncome(
    currentEmployersArray,
  )
  return totalEmployedWeeklyNetIncome === 0
    ? 'N/A'
    : ((weeklyRent / totalEmployedWeeklyNetIncome) * 100).toFixed(0)
}

export const ApplicationDetailsNumbersComponent = ({
  image = '',
  icon = '',
  title = '',
  text = '',
  titleClassName = '',
  titleTextClassName = '',
  textClassName = '',
  extraText = '',
  extraTextClassName = '',
  componentClassName = '',
  hookedText = '',
  componentMinWidth = '',
  disableWidthSetting = false,
  showHookedText = false,
  iconText = '',
  rentalReferenceRentTitleIconTooltipTextGUID = '',
  titleAndTextClass = '',
}) => {
  return (
    <div
      className={`pdf-mb0 application-detials-numbers-component ${componentClassName} ${
        componentMinWidth ? componentMinWidth : `min-width100p`
      }`}
    >
      <div
        className={`info-frame relative ${
          disableWidthSetting ? '' : `width100`
        } ${titleAndTextClass}`}
      >
        <div className={`title mb5 ${titleClassName}`}>
          {image && <img className="mr5 pb2" src={image} alt="icon" />}
          {iconText && (
            <ReactTooltip id={rentalReferenceRentTitleIconTooltipTextGUID}>
              <span>{iconText}</span>
            </ReactTooltip>
          )}
          <div className={titleTextClassName}>{title}</div>
          {icon && (
            <img
              className="ml5 mr5 pb2 icon-size"
              src={icon}
              alt="icon"
              data-tooltip-content=""
              data-tooltip-id={rentalReferenceRentTitleIconTooltipTextGUID}
            />
          )}
        </div>
        <p
          className={`content fw500 pdf-mb0 pdf-margin-neg-10 ${textClassName}`}
        >
          {text} <span className={`${extraTextClassName}`}>{extraText}</span>
        </p>
        {showHookedText && <div className="hook-text">{hookedText}</div>}
      </div>
    </div>
  )
}

export const ApplicationDetailsNumbersComponentRowStyle = ({
  image = '',
  icon = '',
  title = '',
  text = '',
  titleClassName = '',
  titleTextClassName = '',
  textClassName = '',
  extraText = '',
  extraTextClassName = '',
  componentClassName = '',
  hookedText = '',
  componentMinWidth = '',
  disableWidthSetting = false,
}) => {
  return (
    <div
      className={`pdf-mb0 application-detials-numbers-component-row-style ${componentClassName} ${
        componentMinWidth ? componentMinWidth : `min-width100p`
      }`}
    >
      <div
        className={`display-flex info-frame relative ${
          disableWidthSetting ? '' : `width100`
        }`}
      >
        <div className={`title mb5 ${titleClassName}`}>
          {image && <img className="mr5 pb2" src={image} alt="icon" />}
          <div className={titleTextClassName}>{title}: &nbsp;</div>
          {icon && (
            <img className="ml5 mr5 pb2 icon-size" src={icon} alt="icon" />
          )}
        </div>
        <p className={`content fw500 pdf-mb0 ${textClassName}`}>
          {text} <span className={`${extraTextClassName}`}>{extraText}</span>
        </p>
        {hookedText && <div className="hook-text">{hookedText}</div>}
      </div>
    </div>
  )
}

export const ApplicationDetailsIconComponent = ({
  title = '',
  iconSrc,
  titleClassName = '',
  textClassName = '',
  extraTextClassName = '',
  componentClassName = '',
  hookedText = '',
}) => {
  return (
    <div
      className={`application-detials-numbers-component ${componentClassName} min-width100p`}
    >
      <div className="info-frame relative width100">
        <div className={`title mb5 ${titleClassName}`}>{title}</div>
        <div className={`content fw500 ${textClassName}`}>
          <span className={`${extraTextClassName}`}>
            <img src={iconSrc} alt="details icon" />
          </span>
        </div>
        {hookedText && <div className="hook-text">{hookedText}</div>}
      </div>
    </div>
  )
}

export class ApplicationDetailsLongTextComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonText: '(read more)',
    }
  }

  onShowButtonClicked = () => {
    const { buttonText } = this.state
    if (buttonText === '(read more)') {
      this.setState({ buttonText: '(read less)' })
    } else {
      this.setState({ buttonText: '(read more)' })
    }
  }

  render() {
    const {
      title = '',
      text = '',
      componentClassName = '',
      titleIcon = '',
      titleClassName = '',
      contentClassName = '',
    } = this.props
    const { buttonText } = this.state
    const wordCount = getSentenceWords(text)
    const showButton = wordCount > 48
    let finalContext
    if (buttonText === '(read more)') {
      finalContext = trimByWord(text)
    } else {
      finalContext = text
    }

    return (
      <div className={`application-detials-request ${componentClassName}`}>
        <div className={`${titleClassName} title`}>
          {title}
          {titleIcon && (
            <img className="ml5 mr5 pb2" src={titleIcon} alt="icon" />
          )}
        </div>
        <div className={`content pdf-margin-neg-10 ${contentClassName}`}>
          <div className="text pdf-hide pdf-font-size-10">
            {finalContext ? finalContext : 'No information provided'}
          </div>
          <div className="text pdf-show pdf-font-size-10">
            {text ? text : 'No information provided'}
          </div>
          {showButton && (
            <div
              className="showMore mt5 pdf-hide"
              onClick={() => this.onShowButtonClicked()}
            >
              {buttonText}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export const ApplicantListComponent = ({
  applicants,
  application,
  avatarContainerClass,
  showStatus = true,
}) => (
  <div className="applicants-container">
    {applicants &&
      applicants.sort(sortApplicants).map((applicant) => {
        const applicantStatus = getApplicantStatus(applicant, application)
        return (
          <div className="applicant-container" key={applicant.guidID}>
            <div className="applicant-upper-container">
              <div
                className={`household-applicant-table avatar-container mt0 ${avatarContainerClass}`}
              >
                <div
                  className="avatar application-avatar"
                  style={{ backgroundImage: `url(${applicant.avatar})` }}
                >
                  {!applicant.avatar && (
                    <span className="app-avatar-initial-position default-font-family">
                      {capitalizeFirstLetter(
                        applicant.firstName.substring(0, 1),
                      )}
                      {capitalizeFirstLetter(
                        applicant.lastName.substring(0, 1),
                      )}
                    </span>
                  )}
                </div>
              </div>
              <div className="applicant-info-container">
                <h4 className="fw500 font-size-18">
                  {capitalizeFirstLetter(applicant.firstName)}{' '}
                  {capitalizeFirstLetter(applicant.lastName)}{' '}
                  <span className="fw500 color-light-grey">(Applicant)</span>
                </h4>
                <div className="applicant-info-sub-container">
                  <div>{applicant.mobileNumber}</div>
                  {applicant.mobileNumber && applicant.email && (
                    <div className="spot-seperator" />
                  )}
                  <div>{applicant.email}</div>
                </div>
              </div>
            </div>
            <div className="applicant-status-container">
              {showStatus && (
                <div
                  className={`household-applicant-status ${applicantStatus}`}
                >
                  {applicantStatus}
                </div>
              )}
            </div>
          </div>
        )
      })}
  </div>
)

export const HouseholdSummary = ({
  application,
  totalNetIncome,
  hideNetIncome,
}) => {
  return (
    <div className="display-flex household-statistic-info-container">
      <div className="display-flex household-statistic-info">
        <ApplicationDetailsNumbersComponent
          title="Applicants"
          text={application && application.applicants.length}
          componentClassName="width50p house-component-position"
        />
        {!hideNetIncome && (
          <ApplicationDetailsNumbersComponent
            title="Total income"
            text={`$${Math.round(totalNetIncome)}`}
            extraText="/w (After tax)"
            extraTextClassName="text-grey"
            componentClassName="width50p house-component-position"
          />
        )}
        {hideNetIncome && (
          <ApplicationDetailsIconComponent
            title="Total income"
            iconSrc={mosaicIcon}
            extraText="/w"
            extraTextClassName="text-grey"
            textClassName="mb25"
            componentClassName="width50p house-component-position"
          />
        )}
      </div>
      <div className="display-flex household-statistic-info">
        {!application.adults && (
          <ApplicationDetailsNumbersComponent
            title="Occupants"
            text={application && application.people}
            componentClassName="width50p house-component-position"
          />
        )}
        {!!application.adults && (
          <ApplicationDetailsNumbersComponent
            title="Adults"
            text={application && application.adults}
            componentClassName="width50p house-component-position"
          />
        )}
        {!!application.adults && (
          <ApplicationDetailsNumbersComponent
            title="Children"
            text={
              (application &&
                application.children &&
                application.children.length) ||
              0
            }
            componentClassName="width50p house-component-position"
          />
        )}
        <ApplicationDetailsNumbersComponent
          title="Pets"
          text={application && application.pets}
          componentClassName="width50p house-component-position"
        />
      </div>
    </div>
  )
}

const DownloadApplicationDropdown = ({
  displayedApplicant = {},
  generatingPDF = false,
  onClickDownloadPDF,
  componentClassName = '',
  dropUp = false,
  disclosureURL,
  onApplicationDownload,
  utilityProviderConfigured,
}) => {
  const classNames = dropUp
    ? componentClassName + ' dropup'
    : componentClassName
  const onClickDownloadItem = (type) =>
    utilityProviderConfigured
      ? () => onApplicationDownload(type)
      : () => onClickDownloadPDF(type)

  return (
    <div className={`dropdown actions ${classNames}`}>
      <div
        className={`dropdown-toggle ${generatingPDF && 'disabled-link'}`}
        data-toggle="dropdown"
      >
        <NewButton
          variant="greenHover"
          disabled={generatingPDF}
          sizeVariant="regular"
          width={theme.width[12] + 'px'}
        >
          Download {generatingPDF && <NewSpinner ml={3} />}
        </NewButton>
      </div>
      <ul className="dropdown-menu drop-down">
        <ClickableStageOption
          value={DownloadOptions[PDFTypes.AllPDFs].text}
          onClick={onClickDownloadItem(PDFTypes.AllPDFs)}
        />
        <ClickableStageOption
          value={DownloadOptions[PDFTypes.Application].text}
          onClick={onClickDownloadItem(PDFTypes.Application)}
        />
        <ClickableStageOption
          value={DownloadOptions[PDFTypes.Attachments].text}
          onClick={onClickDownloadItem(PDFTypes.Attachments)}
        />

        {disclosureURL && (
          <a
            href={disclosureURL}
            target="_blank"
            rel="noreferrer"
            className="tdn"
          >
            <ClickableStageOption value="Disclosure Authority" />
          </a>
        )}
      </ul>
    </div>
  )
}

export class ApplicationSummary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      spinnerName: '',
      showingToast: false,
      initialSet: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { profile } = nextProps
    const updateState =
      profile &&
      (profile.conditionPreferenceWeighting !==
        this.state.conditionPreferenceWeighting ||
        profile.occupancyPreferenceWeighting !==
          this.state.occupancyPreferenceWeighting ||
        profile.yieldPreferenceWeighting !==
          this.state.yieldPreferenceWeighting)
    if (updateState) {
      this.initialSet = true
      this.setState({
        conditionPreferenceWeighting: profile.conditionPreferenceWeighting,
        occupancyPreferenceWeighting: profile.occupancyPreferenceWeighting,
        yieldPreferenceWeighting: profile.yieldPreferenceWeighting,
        allowPets: profile.allowPets,
        takeHoldingDeposit: profile.takeHoldingDeposit,
      })
    }
  }

  reactToastify = () => {
    this.setState({ spinnerName: '' })
    const { property, propertyOffer } = this.props
    const activeOfferGuid = hasActiveOfferGuid(property)
    const offerId = (propertyOffer || {}).guidID || activeOfferGuid

    this.state.showingToast === false &&
      snugNotifier.success(ownerPreferencesToastifyText(offerId), {
        onOpen: () => this.setState({ showingToast: true }),
        onClose: () => this.setState({ showingToast: false }),
      })
  }

  update = (field, value) => {
    this.setState({ spinnerName: field })
    const {
      updatePreferences,
      property = {},
      propertyOffer,
      applicationId,
      applicantId,
    } = this.props
    const activeOfferGuid = hasActiveOfferGuid(property)
    const offerId = (propertyOffer || {}).guidID || activeOfferGuid

    const mostImportantKeys = Profile.mostImportant.map((o) => o.id)
    mostImportantKeys.forEach((f) =>
      f === field ? this.setState({ [f]: value }) : this.setState({ [f]: 0 }),
    )

    this.setState(
      { offerId },
      () =>
        property.guidID &&
        updatePreferences(
          property.guidID,
          this.state,
          applicationId,
          applicantId,
        ).then(this.reactToastify),
    )
  }

  render() {
    const { id, application } = this.props || {}
    return (
      <div
        id={id}
        className="sm-applications-detailed-applicants application-details-container pt0 pr0 pl0 border-top-0 pdf-mt0 mt05 pdf-hide"
      >
        {/* <div className="application-summary-container"> */}
        <Headers.SectionHeader hasSeperator={false}>
          <Flex
            justifyContent="space-between"
            className="application-summary-container"
          >
            <h3 className="heading summary-heading">Summary</h3>

            <StyledCopyableAppIDContainer>
              App ID: <CopyableText text={application.slug} />
            </StyledCopyableAppIDContainer>
          </Flex>
          <div className="standard-section-seperator" />
        </Headers.SectionHeader>
      </div>
    )
  }
}

const EmploymentComponent = ({
  currentEmployers = [],
  previousEmployers = [],
  displayedApplicant = {},
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  enableNote = false,
  section,
  applicationId = '',
  id = '',
  toggleRequestInfoModal = undefined,
  agencyGUID = '',
  reputationPreference = {},
  toggleEditRenterApplication = undefined,
  isManagerAdded = false,
  requireCurrentEmploymentOnly = false,
}) => {
  const {
    preference: { optOutEmploymentHistory },
    preference,
    firstName,
    lastName,
  } = displayedApplicant
  const getCurrentStatus = currentEmploymentStatus(preference)
  const displayCurrentStatus = getCurrentStatus !== ''
  const applicantName = firstName + ' ' + lastName
  const getEmploymentHistoryFilteredLastNotes = (employer) => {
    const noteResult = JSON.parse(JSON.stringify(interpreteNoteResult || {}))
    const allNotes = JSON.parse(
      JSON.stringify(noteResult.employmentDetails?.categorizedAllNotes || {}),
    )
    let filteredCategorizedLastNotes = {}

    if (!Object.keys(noteResult).length) {
      return
    }

    // - split notes over multiple rental histories.
    // - find the latest notes for each action type with targetGUID = employer.guidID
    // - or the latest with empty targetGUID to fall back the old record
    const getLatestTypeRecord = (type) => {
      let typeNotes = allNotes[type]?.filter((note) => {
        return note.targetGUID === employer.guidID
      })

      if (!typeNotes.length) {
        typeNotes = allNotes[type]?.filter((note) => {
          return note.targetGUID.length === 0
        })
      }

      if (!typeNotes.length) return undefined

      return (
        typeNotes.reduce((n1, n2) =>
          new Date(n1?.updatedAt) > new Date(n2?.updatedAt) ? n1 : n2,
        ) || undefined
      )
    }

    if (allNotes) {
      filteredCategorizedLastNotes[noteActionType.approve] =
        getLatestTypeRecord(noteActionType.approve)
      filteredCategorizedLastNotes[noteActionType.decline] =
        getLatestTypeRecord(noteActionType.decline)
      filteredCategorizedLastNotes[noteActionType.comment] =
        getLatestTypeRecord(noteActionType.comment)

      // find the latest note among variant action types
      const latestTargetNote = Object.values(filteredCategorizedLastNotes)
        .filter((n) => !!n)
        .reduce(
          (n1, n2) =>
            new Date(n1?.updatedAt) > new Date(n2?.updatedAt) ? n1 : n2,
          undefined,
        )

      noteResult.employmentDetails.type = latestTargetNote?.type || 0

      noteResult.employmentDetails.categorizedLastNotes =
        filteredCategorizedLastNotes
    }

    return noteResult
  }

  return (
    <div
      id={id}
      className="container-component bbs pdf-mt0 default-font-family"
    >
      <div className={`container-header position-relative`}>
        <img
          className="standard-icon-spacing"
          src={jobIcon}
          alt="Summary Icon"
        />
        <span className={`standard-subheading`}>Employment Details</span>
      </div>

      <div className="application-employment">
        <div className="application-identification-container pdf-display-flex flex-wrap-wrap pdf-flex-direction-row">
          {displayCurrentStatus && (
            <div className="width-100 mb10">
              <b>{applicantName}</b> is currently: <b>{getCurrentStatus}</b>
            </div>
          )}
          {currentEmployers.length > 0 &&
            currentEmployers.map((employer) => {
              return (
                <Box key={employer.guidID}>
                  <ContainerHeader
                    isEditable={false}
                    enableNote={enableNote}
                    onNewNoteUpdated={onNewNoteUpdated}
                    newNotesContent={newNotesContent}
                    onNoteIconClicked={onNoteIconClicked}
                    onNewNoteAdded={onNewNoteAdded}
                    onNoteCrossClicked={onNoteCrossClicked}
                    interpreteNoteResult={getEmploymentHistoryFilteredLastNotes(
                      employer,
                    )}
                    enableNotesButton={enableNotesButton}
                    displayedApplicant={displayedApplicant}
                    section={section}
                    toggleRequestInfoModal={toggleRequestInfoModal}
                    toggleEditRenterApplication={toggleEditRenterApplication}
                    isManagerAdded={isManagerAdded}
                    targetGUID={employer.guidID}
                  />

                  <EmploymentDetailsComponent
                    employer={employer}
                    title="Current"
                    applicationId={applicationId}
                    agencyGUID={agencyGUID}
                    displayedApplicant={displayedApplicant}
                  />
                </Box>
              )
            })}
          {previousEmployers.length > 0 &&
            !requireCurrentEmploymentOnly &&
            previousEmployers.map((employer) => {
              return (
                <EmploymentDetailsComponent
                  key={employer.guidID}
                  employer={employer}
                  title="Previous"
                  applicationId={applicationId}
                  agencyGUID={agencyGUID}
                  displayedApplicant={displayedApplicant}
                />
              )
            })}
        </div>
        {previousEmployers.length + currentEmployers.length === 0 &&
          !optOutEmploymentHistory && (
            <div className="no-info-provided">Not provided</div>
          )}
        {previousEmployers.length + currentEmployers.length === 0 &&
          optOutEmploymentHistory && (
            <div className="no-info-provided">
              Does not have employment history
            </div>
          )}
      </div>
    </div>
  )
}

const EmploymentDetailsComponent = ({
  employer = {},
  applicationId = '',
  agencyGUID = '',
  displayedApplicant = {},
}) => {
  const {
    employerName,
    employerTradingName,
    employerABN,
    verifyDate,
    verifyStatus,
    address,
    employerConfirmation,
    guidID: employmentHistoryId,
    hasAccountant,
    lastConfirmationRequest = {},
    confirmedContactName = '',
    confirmedEmail = '',
    confirmedEmploymentType = 0,
    confirmedEndDate = '',
    confirmedGrossAnnualIncome,
    confirmedJobTitle,
    confirmedPhoneNumber,
    confirmedStartDate,
    confirmedWeeklyNetIncome,
    confirmedStillEmployed,
    reasonAffectingEmployment,
    pendingEmployerConfirmationGuidID,
  } = employer || {}
  const employmentText = `${stringHelpers.capitalizeFirstLetterOfString(
    confirmedJobTitle,
  )} at ${stringHelpers.capitalizeFirstLetterOfString(employerName)}`
  const duration = calculateDuration(
    confirmedStartDate,
    confirmedEndDate,
    confirmedStillEmployed,
  )
  const addressText =
    address.friendlyName +
    ', ' +
    address.suburb +
    ', ' +
    address.state +
    ', ' +
    address.postcode +
    ', ' +
    address.country
  const { comment = '', reason, refereeType = '' } = employerConfirmation || {}
  const type = EmploymentTypes[confirmedEmploymentType] || 'N/A'
  const formattedPhoneNumber =
    phoneNumberHelpers.formatPhoneNumber(confirmedPhoneNumber)
  const employmentTitleIcon = isEmploymentStatusVerified(verifyStatus)
    ? greenTick
    : ''

  const jobTitleCompanyTextIcon =
    isEmploymentStatusUnverified(verifyStatus) &&
    (isEmploymentDeclineReasonJobTitle(reason) ||
      isEmploymentDeclineReasonCompanyName(reason))
      ? exclamation
      : employmentTitleIcon
  const tenureTextIcon =
    isEmploymentStatusUnverified(verifyStatus) &&
    isEmploymentDeclineEmploymentDate(reason)
      ? exclamation
      : employmentTitleIcon
  const incomeTextIcon =
    isEmploymentStatusUnverified(verifyStatus) &&
    isEmploymentDeclineReasonSalary(reason)
      ? exclamation
      : employmentTitleIcon

  const isReasonWithEmploymentDetails =
    isEmploymentDeclineReasonJobTitle(reason) ||
    isEmploymentDeclineReasonCompanyName(reason) ||
    isEmploymentDeclineEmploymentDate(reason) ||
    isEmploymentDeclineReasonSalary(reason)

  const onResend = (newEmail, message) => {
    const payload = {
      overrideRecipient: !!newEmail,
      recipientEmail: newEmail || confirmedEmail,
      includePersonalMessage: !!message,
      personalMessage: message,
    }
    return resendReferenceRequest(
      employmentHistoryId,
      applicationId,
      payload,
      agencyGUID,
    )
      .then(() => snugNotifier.success('Requested successfully'))
      .catch((error) => {
        snugNotifier.error(error.message)
        return Promise.reject(error)
      })
  }

  return (
    <div className="mb20 pdf-address-history-container">
      <div className="new-manager-empployment-section-container">
        <div className="application-history-header">
          <div className="left-header pdf-font-size-10">
            {employmentText}
            {jobTitleCompanyTextIcon && (
              <img
                className="ml5 mr5 pb2 icon-size"
                src={jobTitleCompanyTextIcon}
                alt="icon"
              />
            )}
          </div>
          {!isSelfEmployedNoAccountant(
            confirmedEmploymentType,
            hasAccountant,
          ) && (
            <div className="right-header">
              <div className="verified-date pdf-font-size-10">
                {verifyDate
                  ? `  ${moment(verifyDate).format('HH:mm DD MMM YYYY')}`
                  : ''}
              </div>
              <div
                className={`verified-status ${EmploymentVerifyStatus[verifyStatus]} pdf-hide`}
              >
                {EmploymentVerifyStatus[verifyStatus]}
              </div>
            </div>
          )}
        </div>
        <div className="application-seperator" />
        <div className="application-identification-sub-container p0 mt15 application-employment-layout pdf-flex-direction-column">
          <div className="display-flex">
            <ApplicationDetailsNumbersComponent
              title="Type"
              text={type}
              componentClassName={'justify-content-unset'}
              componentMinWidth={'min-width120p'}
              textClassName="pdf-font-size-10"
              titleTextClassName="pdf-font-size-10"
            />
            <ApplicationDetailsNumbersComponent
              icon={tenureTextIcon}
              title="Tenure"
              text={duration}
              componentClassName={'justify-content-unset flex-2'}
              componentMinWidth={'min-width120p'}
              textClassName="pdf-font-size-10"
              titleTextClassName="pdf-font-size-10"
            />
          </div>
          <div className="display-flex">
            <ApplicationDetailsNumbersComponent
              icon={incomeTextIcon}
              title="Gross income"
              text={`$${confirmedGrossAnnualIncome}`}
              extraText={'p/a'}
              titleTextClassName={'employment-title-text pdf-font-size-10'}
              componentClassName={'justify-content-unset'}
              componentMinWidth={'min-width120p'}
              textClassName="pdf-font-size-10"
            />
            <ApplicationDetailsNumbersComponent
              icon={incomeTextIcon}
              title="After tax"
              text={`$${confirmedWeeklyNetIncome}`}
              extraText={'p/w'}
              titleTextClassName={'employment-title-text pdf-font-size-10'}
              componentClassName={'justify-content-unset'}
              componentMinWidth={'min-width120p'}
              textClassName="pdf-font-size-10"
            />
          </div>
        </div>
        <div>
          <ApplicationDetailsNumbersComponent
            title="Address"
            text={addressText}
            componentClassName={'justify-content-unset'}
            textClassName="pdf-font-size-10"
            titleTextClassName="pdf-font-size-10"
          />
        </div>
        {helpers.refereeTypeCollection[refereeType]?.typeName ===
          'manualReferee' && (
          <div>
            <ApplicationDetailsNumbersComponent
              title="Employment Vertification"
              text={`Manual verification submitted by ${confirmedContactName} at ${moment(
                verifyDate,
              ).format('LT Do, MMM')}`}
              componentClassName={'justify-content-unset'}
              textClassName="pdf-font-size-10"
              titleTextClassName="pdf-font-size-10"
            />
          </div>
        )}
        {reasonAffectingEmployment && (
          <div>
            <ApplicationDetailsNumbersComponent
              title="Is there any work issue that might affect the applicant's ongoing employment, income or ability to pay rent?"
              text={reasonAffectingEmployment}
              componentClassName={'justify-content-unset'}
              textClassName="pdf-font-size-10"
              titleTextClassName="pdf-font-size-10"
            />
          </div>
        )}
        {isSelfEmployed(confirmedEmploymentType) && (
          <>
            <div className="application-detials-numbers-component height-auto">
              <div className="title mb5">
                <div className="pdf-font-size-10">Company details</div>
              </div>
            </div>
            <div className="fw500 mb5">
              {employerName ? `Name: ${employerName}` : ''}
            </div>
            <div className="fw500 mb5">
              {employerTradingName
                ? `Trading name: ${employerTradingName}`
                : ''}
            </div>
            <div className="fw500 mb10">
              {employerABN
                ? `${getBusinessNumberLabel(address)}: ${employerABN}`
                : ''}
            </div>
          </>
        )}
        <div className="application-detials-numbers-component height-auto">
          <div className="title mb5">
            <div className="pdf-font-size-10">
              {isSelfEmployed(confirmedEmploymentType)
                ? 'Accountant'
                : 'Contact'}
            </div>
          </div>
        </div>
        {isSelfEmployedNoAccountant(confirmedEmploymentType, hasAccountant) ? (
          <div>No accountant details</div>
        ) : (
          <div className="applicant-container">
            <div className="applicant-upper-container display-flex">
              <div className="household-applicant-table avatar-container mt0">
                <div className="avatar application-avatar">
                  <span className="app-avatar-initial-position default-font-family">
                    {confirmedContactName !== ''
                      ? capitalizeFirstLetter(
                          confirmedContactName.substring(0, 1),
                        )
                      : ''}
                  </span>
                </div>
              </div>
              <Display.StandardContactInfoContainer
                mainTitle={
                  confirmedContactName !== ''
                    ? capitalizeFirstLetter(confirmedContactName)
                    : ''
                }
                linesText={[formattedPhoneNumber, confirmedEmail]}
                statusIcon={tenureTextIcon}
              />
            </div>
          </div>
        )}
        {!employerConfirmation?.guidID && (
          <Display.ResendReference
            from="renterSummary"
            onSubmitResend={onResend}
            lastSend={{
              email: lastConfirmationRequest?.requestReceiver,
              senderEmail: lastConfirmationRequest?.requestSender,
              sentAt: lastConfirmationRequest?.requestSentTime,
            }}
            manualReferenceLink={`${urlTo('addManualEmploymentVerification', {
              employmentConfirmationId: pendingEmployerConfirmationGuidID,
            })}?applicationId=${applicationId}&applicantId=${
              displayedApplicant.guidID
            }&manual=true`}
            enableManualReference={
              Features.FeatureFlag.ManualRentalReference.isOn
            }
          />
        )}
        {!!isEmploymentStatusUnverified(verifyStatus) && (
          <div>
            <div className="application-seperator" />
            <div className="application-employment-verification-container">
              <div className="title">Verification</div>
              <div className="employer-comment-container">
                {comment ? comment : 'No information provided'}
              </div>
              {!!isReasonWithEmploymentDetails && (
                <ReferenceResultComponent
                  description={`Confirmed ${deCapitalizeFirstLetter(
                    EmploymentDeclineReasons[reason],
                  )}`}
                  isBooleanRating
                  exception
                  boolRatingValue="No"
                />
              )}
              {!!isEmploymentDeclineContactPerson(reason) && (
                <ReferenceResultComponent
                  description={`I am the correct ${deCapitalizeFirstLetter(
                    EmploymentDeclineReasons[reason],
                  )}`}
                  isBooleanRating
                  exception
                  boolRatingValue="No"
                />
              )}
              {!!isEmploymentDeclineOther(reason) && (
                <ReferenceResultComponent
                  description={EmploymentDeclineReasons[reason]}
                  isBooleanRating
                  exception
                  boolRatingValue="Yes"
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const correspondingSectionInRequestInfoModal = (text) => {
  switch (text) {
    case 'Preference': {
      return 'Other'
    }
    case 'Address History': {
      return 'Address History'
    }
    case 'Employment Details': {
      return 'Employment'
    }
    case 'Identity Documents': {
      return 'Identification'
    }
    case 'Income': {
      return 'Employment'
    }
    case 'Background Check': {
      return 'Identification'
    }
    case 'Next of Kin / Emergency Contacts': {
      return 'Other'
    }
    case 'Pet Reference': {
      return 'Other'
    }
    case 'Personal Reference': {
      return 'Other'
    }
    case 'Student Details': {
      return 'Other'
    }
    case 'Other Info': {
      return 'Other'
    }
    default: {
      return ''
    }
  }
}

const CONNECTION_REFERRAL_SENT = 'Requested utility connection referral'

class ApplicantDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      generatingPDF: false,
      generatedPDFURL: '',
      isRequestInfoModalActive: false,
      chosenApplicant: 0,
      isShortListed: undefined,
      disableShortlistButton: false,
      spinnerForTeamMember: false,
      referenceResendError: '',
      targetApplication: this.props.application,
      applicationWorkflowStatus: 0,
      prevApplicationWorkflowStatus: 0,
      workflowSaveButtonBusy: false,
      showMoveToText: true,
      pmSupportAttachments: [],
      pmDocSpinner: false,
      pmAttachmentsApiError: '',
      activeModal: '',
      sendReminderIcon: '',
      isSubmitApplicationRequestLoading: false,
      isReminderSent: false,
      teamMembersList: [],
      teamMembersListDropDownContent: [],
      selectedTeamMember: '',
      windowWidth: window.innerWidth,
      conditionPreferenceWeighting: 0,
      occupancyPreferenceWeighting: 0,
      yieldPreferenceWeighting: 0,
      markAsLeasedOptions: {
        declineUnsuccessfulApplications: true,
        cancelFutureViewings: true,
        archiveProperty: true,
        archiveEnquirers: false,
      },
      markAsApplicationApprovedOptions: {
        declineUnsuccessfulApplications: false,
        cancelFutureViewings: false,
        sendUtilityReferrals: true,
        sendApplicationToPT: false,
        sendApplicationToKolmeo: false,
        sendApplicationToManagedApp: false,
        archiveProperty: false,
      },
      pmsIntegrationStatus: [],
      corrRequestInfoModalSection: '',
      requestInfoFromTica: false,
      numberOfReqInfoItemsSelected: 0,
      requestInfoFormInfoData: {},
      requestForRequestInfoSent: false,
      disableRequestInfoButton: false,
      showScheduleViewingModal: false,
      scheduleViewingModal: {
        ...defaultEditViewingModalValues,
      },
      defaultDisplay: {
        registeredViewings: [],
        viewings: [],
        notes: [],
      },
      declineApplicationOptions: {
        ownerSelectedAnAlternative: false,
        householdAffordabilityIssue: false,
        petNotSuitable: false,
        rentalReferenceIssue: false,
        insufficientDocumentation: false,
        notSuitableProperty: false,
        ownerWithdrawnProperty: false,
        noSpecificReason: false,
      },
      declineApplicationNotifyApplicant: true,
      declineComment: '',
      declineApplicationInProgress: false,
      enableBackgroundCheckBundle: false,
      isEditRenterApplication: false,
      isMoveInDatePickerVisible: false,
      rentAndEmploymentDetails: {},
      managerEditedSummary: {
        weeklyRent: 0,
        leaseStart: '',
        leaseTerm: 0,
        applicantsEmployment: [],
        isLeaseUpdated: false,
        pets: 0,
      },
      errors: {
        weeklyRent: '',
        leaseStart: '',
        leaseTerm: '',
      },
      selectedLeaseTermIndex: '',
      enableSaveEditSummary: true,
      editSummaryInProgress: false,
      connectionReferralSelected: false,
      downloadOption: '',
      isConsoleCloudEnabled: false,
      isTicaActive: false,
      showBackButton: false,
      backButtonType: '',
      agencyInfo: {},
      appsDetails: {},
      appsDetailsLoading: true,
      showDocumentsViewer: false,
      documentsViewerGUID: '',
      isShareWithOwnerModalOpen: false,
    }

    this.fetchAndSetTeamMembersList = this.fetchAndSetTeamMembersList.bind(this)
    this.onFetchAgencyProfile = this.onFetchAgencyProfile.bind(this)
    this.onTeamMemberUpdate = this.onTeamMemberUpdate.bind(this)
  }

  componentDidMount() {
    const { agencyId, application, teamSettingInfo } = this.props
    this.checkForBackButtonAvailability()

    if (agencyId) {
      this.onFetchAgencyProfile(agencyId)
      this.fetchAndSetTeamMembersList(agencyId)
    }
    if (teamSettingInfo) {
      this.checkConsoleCloudStatus()
      this.checkTicaStatus()
    }
    if (application && application.guidID) {
      this.onfetchRentAndEmploymentSummary(application.guidID)
    }

    window.addEventListener('wheel', this.checkForScrollOrPositionChange)
    window.addEventListener('resize', this.handleApplicationPageResize)
    this.intervalToCheckPositionChange = setInterval(
      this.checkForScrollOrPositionChange.bind(this),
      1000,
    )
    this.setState({
      ...this.state,
      leftSwitcherMaxHeight:
        window.innerHeight -
        LEFT_SWITCHER_SPACE -
        5 * LEFT_SWITCER_GUTTER_SPACE,
    })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { agencyId } = this.props
    if (newProps.agencyId !== agencyId) {
      this.onFetchAgencyProfile(newProps.agencyId)
    }
    const { profile } = newProps
    const updateState =
      profile &&
      (profile.conditionPreferenceWeighting !==
        this.state.conditionPreferenceWeighting ||
        profile.occupancyPreferenceWeighting !==
          this.state.occupancyPreferenceWeighting ||
        profile.yieldPreferenceWeighting !==
          this.state.yieldPreferenceWeighting)
    if (updateState) {
      this.setState({
        conditionPreferenceWeighting: profile.conditionPreferenceWeighting,
        occupancyPreferenceWeighting: profile.occupancyPreferenceWeighting,
        yieldPreferenceWeighting: profile.yieldPreferenceWeighting,
        allowPets: profile.allowPets,
        takeHoldingDeposit: profile.takeHoldingDeposit,
      })
    }
    if (newProps.isRequestInfoDone) {
      this.setState({ isRequestInfoModalActive: false })
      this.props.toggleSendRequestInfo()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { agencyId, teamSettingInfo, fetchApplicationTeamMember } = this.props
    if (prevProps.agencyId !== agencyId) {
      this.fetchAndSetTeamMembersList(agencyId)
    }
    if (
      prevState.enableBackgroundCheckBundle !==
      this.state.enableBackgroundCheckBundle
    ) {
      this.onFetchAgencyProfile(agencyId)
    }

    if (prevProps.teamSettingInfo !== teamSettingInfo) {
      this.checkConsoleCloudStatus()
      this.checkTicaStatus()
    }
    if (prevProps.rentalStatus !== this.props.rentalStatus) {
      const { rentalStatus } = this.props
      this.setState({
        isShortListed: isStatusShortlisted(ApplicationCategory[rentalStatus]),
      })
    }

    if (
      prevProps.application !== this.props.application &&
      this.props.application
    ) {
      this.onfetchRentAndEmploymentSummary(this.props.application.guidID)

      const { teamMembersList: managerList } = this.state

      agencyId &&
        this.props.application?.guidID &&
        fetchApplicationTeamMember(
          agencyId,
          this.props.application.guidID,
        ).then((teamMemberInfo) => {
          managerList.forEach((teamMember) => {
            if (teamMemberInfo && teamMember.contact) {
              if (teamMember.contact.email === teamMemberInfo.email) {
                this.setState({
                  selectedTeamMember: teamMember.contact.guidID,
                  spinnerForTeamMember: false,
                })
              }
            }
          })
        })

      this.setState({
        applicationWorkflowStatus: this.props.application.status,
        prevApplicationWorkflowStatus: this.props.application.status,
      })
    }
    if (prevProps.application !== this.props.application) {
      this.setState({
        targetApplication: this.props.application,
      })
      this.checkForBackButtonAvailability()
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null
    window.removeEventListener('wheel', this.checkForScrollOrPositionChange)
    window.removeEventListener('resize', this.handleApplicationPageResize)
    clearInterval(this.intervalToCheckPositionChange)
  }

  getDetails = (agencyGUID, offerGUID, applicationGUID) => {
    this.setState({
      appsDetailsLoading: true,
    })
    getApplicationsDetails(agencyGUID, offerGUID, applicationGUID)
      .then((data) => {
        this.setState({
          appsDetails: data,
          appsDetailsLoading: false,
        })
      })
      .catch((error) => {
        snugNotifier.error(error?.message)
      })
      .finally(() => {
        this.setState({
          appsDetailsLoading: false,
        })
      })
  }

  checkForBackButtonAvailability = () => {
    const queries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    const { from = '' } = queries
    if (
      from &&
      from.length > 0 &&
      (from === textHelpers.APPLICATION_BY_STAGE ||
        from === textHelpers.PROSPECT_SUMMARY)
    ) {
      this.setState({
        showBackButton: true,
        backButtonType: from,
      })
    }
  }

  onfetchRentAndEmploymentSummary(applicationGUID) {
    const { fetchRentAndEmploymentSummary } = this.props
    fetchRentAndEmploymentSummary(applicationGUID).then((summary) => {
      if (summary) {
        let { applicantsEmployment, leaseTerm } = summary
        if (applicantsEmployment && applicantsEmployment.length > 0) {
          applicantsEmployment = applicantsEmployment.sort(
            (applicantA, applicantB) => {
              return applicantB.isPrimary - applicantA.isPrimary
            },
          )
          summary.applicantsEmployment = applicantsEmployment

          DefaultAvailableLease.forEach((term, ind) => {
            if (term === `${leaseTerm} months`) {
              this.setState({
                selectedLeaseTermIndex: ind,
              })
            }
          })
        }
        this.setState({
          rentAndEmploymentDetails: summary,
        })
      }
    })
  }

  onAddShortListedApplication = () => {
    const { applicationId, teamSettingInfo, agencyId } = this.props

    const applicationSettings =
      teamSettingInfo.application &&
      parseDataUtil.convertObjValueFromStringToBoolean(
        teamSettingInfo.application,
      )

    const { enable_internal_shortlisting: enableInternalShortlisting = false } =
      applicationSettings

    this.setState({
      disableShortlistButton: true,
    })

    shortListApplication(applicationId, {
      sendEmail: !enableInternalShortlisting,
    })
      .then(async () => {
        await this.reloadApplicationDetails()

        this.setState({
          isShortListed: true,
          disableShortlistButton: false,
        })
        snugNotifier.success(
          `Application shortlisted ${
            applicationSettings?.enable_internal_shortlisting
              ? ''
              : 'and applicant notified'
          }`,
        )
      })
      .catch((error) => {
        this.setState({ disableShortlistButton: false })
        snugNotifier.error(error, {
          onOpen: () => this.setState({ showingToast: true }),
          onClose: () => this.setState({ showingToast: false }),
        })
      })
  }

  onApplicationWorkflowChange = (newStatus) => {
    return this.props
      .moveTo(this.props.application.guidID, newStatus)
      .then(() => {
        this.setState({ applicationWorkflowStatus: newStatus })
      })
  }

  onChangeDeclineApplicationOptions = (checkboxID) => {
    return (event) => {
      const { checked } = event.target
      const { declineApplicationOptions } = this.state
      this.setState({
        ...this.state,
        declineApplicationOptions: {
          ...declineApplicationOptions,
          [checkboxID]: checked,
        },
      })
    }
  }

  onChangeDeclineComment = () => {
    return (event) => {
      this.setState({
        declineComment: event.target.value,
      })
    }
  }

  onChangeMarkAsApplicationApprovedOptions = (checkboxID) => {
    return (event) => {
      const { checked } = event.target
      const { markAsApplicationApprovedOptions } = this.state
      const { currentTeam = {} } = this.props
      const { platformDefaultProvider = false } = currentTeam || {}
      if (checkboxID === 'sendUtilityReferrals' && platformDefaultProvider) {
        this.setState({
          ...this.state,
          markAsApplicationApprovedOptions: {
            ...markAsApplicationApprovedOptions,
            [checkboxID]: true,
          },
        })
      } else {
        this.setState({
          ...this.state,
          markAsApplicationApprovedOptions: {
            ...markAsApplicationApprovedOptions,
            [checkboxID]: checked,
          },
        })
      }
    }
  }

  onChangeNotifyApplicant = () => {
    return (event) => {
      const { checked } = event.target
      this.setState({
        declineApplicationNotifyApplicant: checked,
      })
    }
  }

  onChangeWorkflow = (newStatus) => {
    if (newStatus === helpers.ApplicationStatusCategory.Approved) {
      this.setState({
        activeModal: 'markAsApplicationApproved',
      })
      return
    }
    if (newStatus === helpers.ApplicationStatusCategory.DepositReceived) {
      this.setState({
        activeModal: 'markAsDepositReceived',
      })
      return
    }
    if (isWorkflowDeclined(newStatus) || isWorkflowDeclinedFlagged(newStatus)) {
      this.setState({
        activeModal: 'markAsApplicationDeclined',
        applicationWorkflowStatus: newStatus,
      })
      return
    }
    this.setState({
      applicationWorkflowStatus: newStatus,
      showMoveToText: false,
    })
  }

  onChangemarkAsLeasedOptions = (checkboxID) => {
    return (event) => {
      const { checked } = event.target
      const { markAsLeasedOptions } = this.state
      this.setState({
        ...this.state,
        markAsLeasedOptions: {
          ...markAsLeasedOptions,
          [checkboxID]: checked,
        },
      })
    }
  }

  updateSendUtilityConnection = () => {
    return (data) => {
      this.setState({
        connectionReferralSelected: !this.state.connectionReferralSelected,
      })
    }
  }

  onClickDownloadPDF = (downloadOption) => {
    const { selectedApplicantGUID } = this.props
    const { targetApplication } = this.state
    const targetPDF = DownloadOptions[downloadOption]
    const targetApplicant =
      targetApplication &&
      targetApplication.applicants.find(
        (applicant) => applicant.guidID === selectedApplicantGUID,
      )
    if (
      this.state.generatingPDF === false &&
      targetApplicant[targetPDF.pdfAttribute] === undefined
    ) {
      this.generateApplicationPDF(downloadOption, (ok) => {
        const url = targetApplicant[targetPDF.pdfAttribute]
        if (ok && !!url && url !== 'not exist') {
          window.open(targetApplicant[targetPDF.pdfAttribute], '_blank')
        }
      })
      return
    }
    if (
      targetApplicant[targetPDF.pdfAttribute] !== undefined &&
      targetApplicant[targetPDF.pdfAttribute] !== 'not exist'
    ) {
      window.open(targetApplicant[targetPDF.pdfAttribute], '_blank')
    } else if (targetApplicant[targetPDF.pdfAttribute] === 'not exist') {
      snugNotifier.error(
        `Error PDF attachment for ${targetApplicant.firstName} does not exist`,
        {
          onOpen: () => this.setState({ showingToast: true }),
          onClose: () => this.setState({ showingToast: false }),
        },
      )
    }
  }

  onApplicationDownload = (downloadOption) => {
    this.setState({
      activeModal: 'downloadApplication',
      downloadOption,
    })
  }

  sendUtilityReferral = () => {
    const { sendUtilityReferral, currentTeam = {} } = this.props
    const { downloadOption, targetApplication, connectionReferralSelected } =
      this.state
    const sendReferral = currentTeam?.platformDefaultProvider
      ? true
      : connectionReferralSelected
    const { guidID: applicationGUID } = targetApplication
    if (sendReferral) {
      sendUtilityReferral(applicationGUID)
        .then(() => {
          snugNotifier.success(CONNECTION_REFERRAL_SENT)
        })
        .catch((err) => {
          snugNotifier.error(err)
        })
    }
    this.onClickDownloadPDF(downloadOption)
    this.setState({
      activeModal: '',
    })
  }

  onClickSave = (newStatus, getMessage) => {
    const { agencyId } = this.props
    this.setState({ showMoveToText: true })

    if (isWorkflowOffered(newStatus)) {
      history.push(
        `${urlTo('leasingOffer', {
          applicationId: this.props.application.guidID,
          agencyId,
        })}`,
      )
      return
    }

    if (this.state.prevApplicationWorkflowStatus !== newStatus) {
      this.setState({ workflowSaveButtonBusy: true })
      this.onApplicationWorkflowChange(newStatus)
        .then(() => {
          if (isWorkflowLeaseSigned(newStatus)) {
            this.setState({
              activeModal: 'markAsLeased',
              workflowSaveButtonBusy: false,
            })
            return
          }

          this.setState({
            workflowSaveButtonBusy: false,
            applicationWorkflowStatus: newStatus,
            prevApplicationWorkflowStatus: newStatus,
          })
          getMessage &&
            this.reactToastify('success', getMessage(this.props.application))
        })
        .catch((error) => {
          this.setState({ workflowSaveButtonBusy: false })
          this.reactToastify(
            'fail',
            `There was a problem updating the status (${error})`,
          )
        })
    }
  }

  onClickScheduleViewingPrimaryButton() {
    const { scheduleViewingModal = {} } = this.state

    const { startDate, startTime, published, duration, ...viewingOptions } =
      scheduleViewingModal || {}
    const { addViewing, property = {}, propertyOffer = {} } = this.props
    const activeOfferGuid = hasActiveOfferGuid(property)
    const offerId = (propertyOffer || {}).guidID || activeOfferGuid
    const mergedStartDate = `${moment(startDate).format(
      helpers.dateWithDash,
    )} ${startTime}`
    const formattedStartDate = helpers.getStandardFormattedDate(
      moment(mergedStartDate, helpers.dateTimeWithDash).utc(),
      helpers.utcFormatTimeStamp,
    )
    if (property && offerId) {
      addViewing(
        offerId,
        formattedStartDate,
        durationOptionsNumbers[duration],
        false,
        false,
        viewingOptions,
      )
        .then((data) => {
          this.callRegisterForViewing(data.viewing)
          this.toggleScheduleViewingModal()
        })
        .catch((error) => {
          if (
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForProperty] ||
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForManager]
          ) {
            if (window.confirm(viewingsHelpers.conflictingTimeMessage)) {
              addViewing(
                offerId,
                formattedStartDate,
                durationOptionsNumbers[duration],
                false,
                true,
                viewingOptions,
              )
                .then((data) => {
                  this.callRegisterForViewing(data.viewing)
                  this.toggleScheduleViewingModal()
                })
                .catch((error) => {
                  snugNotifier.error(error)
                })
            }
          } else {
            snugNotifier.error(error)
          }
        })
    }
  }

  onClickShare = () => {
    const { property, application } = this.props
    const propertyGUID = selectPropertyGUID(property)
    const agencyGUID = selectAgencyGUID(property)
    const applicants = selectApplicants(application)
    const primaryApplicant = selectPrimaryApplicant(applicants)

    const primaryApplicantEmailAddress =
      primaryApplicant && primaryApplicant.email
    const sharePropertyUrl = urlTo('shareProperty', {
      agencyId: agencyGUID,
      propertyId: propertyGUID,
      email: primaryApplicantEmailAddress,
    })
    const applicationGUID = selectApplicationGUID(this.props.application)
    this.setState({
      activeModal: 'share',
      sharePropertyUrl,
      applicationGUID,
    })
  }

  toggleEditRenterApplication = () => {
    this.setState({
      isEditRenterApplication: !this.state.isEditRenterApplication,
    })
  }

  onEditSummary = (field, data) => {
    const { errors } = this.state
    let { value = 0, selectedIndex = 0 } = data
    if (field === 'leaseTerm') {
      let leaseLength = DefaultAvailableLease[selectedIndex - 1]['name']
      let leaseTerm = 0
      if (leaseLength !== '') {
        leaseTerm = Number(leaseLength.split(' ')[0])
      }
      this.setState({
        selectedLeaseTermIndex: selectedIndex - 1,
      })
      value = leaseTerm
    }
    if (field === 'weeklyRent' || field === 'pets') {
      let parsedInt = parseInt(value, 10)
      if (isNaN(parsedInt)) {
        parsedInt = 0
      }
      if (Number(value) < 0 || value === '-') {
        return
      }
      value = parsedInt
    }
    this.setState({
      managerEditedSummary: {
        ...this.state.managerEditedSummary,
        [field]: value,
      },
      rentAndEmploymentDetails: {
        ...this.state.rentAndEmploymentDetails,
        [field]: value,
      },
      errors: {
        ...errors,
        [field]: data.error,
      },
    })
  }

  onEditEmploymentSummary = (field, nth, data) => {
    let { rentAndEmploymentDetails } = this.state
    const { applicantsEmployment } = rentAndEmploymentDetails || {}
    const value = data.value ? data.value : 0
    if (applicantsEmployment.length > 0) {
      applicantsEmployment[nth][field] = parseInt(value, 10)
      if (isNaN(applicantsEmployment[nth][field])) {
        applicantsEmployment[nth][field] = 0
      }
      this.setState({
        managerEditedSummary: {
          ...this.state.managerEditedSummary,
          applicantsEmployment: applicantsEmployment,
        },
        rentAndEmploymentDetails: {
          ...this.state.rentAndEmploymentDetails,
          applicantsEmployment: applicantsEmployment,
        },
      })
    }
  }

  onSaveManagerEdited = () => {
    const { rentAndEmploymentDetails } = this.state
    const { managerEditRentAndEmploymentSummary, application } = this.props
    const isEditedSummary = this.validateManagerEditedSummary()
    if (!isEditedSummary) {
      this.setState({
        isEditRenterApplication: !this.state.isEditRenterApplication,
      })
      return
    }
    if (application && application.guidID) {
      this.setState({
        editSummaryInProgress: true,
      })
      managerEditRentAndEmploymentSummary(
        application.guidID,
        rentAndEmploymentDetails,
      )
        .then(() => {
          snugNotifier.success(SUCCESS_SUMMARY_UPDATED)
          this.setState({
            isEditRenterApplication: !this.state.isEditRenterApplication,
          })
        })
        .catch((err) => {
          snugNotifier.error(err)
        })
        .finally(() => this.setState({ editSummaryInProgress: false }))
    }
  }

  validateManagerEditedSummary() {
    let hasEdited = true
    const { managerEditedSummary } = this.state
    if (
      managerEditedSummary.weeklyRent === 0 &&
      managerEditedSummary.leaseTerm === 0 &&
      managerEditedSummary.leaseStart === '' &&
      managerEditedSummary.applicantsEmployment.length === 0 &&
      managerEditedSummary.pets === 0
    ) {
      hasEdited = false
    }

    return hasEdited
  }

  onConfirmButtonClicked() {
    const { onMarkAsLeasedBtnClick } = this.props
    onMarkAsLeasedBtnClick(this.state.markAsLeasedOptions)
      .then(() => {
        this.setState({
          applicationWorkflowStatus: 7,
          prevApplicationWorkflowStatus: 7,
          activeModal: '',
          markAsLeasedOptions: {
            declineUnsuccessfulApplications: true,
            cancelFutureViewings: true,
            archiveProperty: true,
            archiveEnquirers: false,
          },
        })
        snugNotifier.success(`Moved to 'Mark as leased'`)
      })
      .catch(() => {
        return
      })
  }

  onConfirmDeclineApplicationClicked = () => {
    const {
      declineApplicationOptions,
      declineApplicationNotifyApplicant,
      declineComment,
    } = this.state
    const optionsSelected = Object.keys(declineApplicationOptions).filter(
      (item) => {
        return declineApplicationOptions[item] === true
      },
    )

    if (
      isWorkflowDeclined(this.state.applicationWorkflowStatus) &&
      !window.confirm('Are you sure you want to decline this application?')
    ) {
      return
    }
    if (
      isWorkflowDeclinedFlagged(this.state.applicationWorkflowStatus) &&
      !window.confirm(
        'Are you sure you would like to decline and flag submitted applicants unsuitable for 90 days?',
      )
    ) {
      return
    }

    this.setState({
      declineApplicationInProgress: true,
      workflowSaveButtonBusy: false,
    })
    const applicationGUID = selectApplicationGUID(this.props.application)
    const agencyGUID = selectAgencyGUID(this.props.property)
    this.setState({ declineApplicationInProgress: true })
    this.props
      .declineApplication(
        agencyGUID,
        applicationGUID,
        optionsSelected,
        declineApplicationNotifyApplicant,
        declineComment,
      )
      .then(() => {
        let message = getSelectedSubStage(
          this.state.applicationWorkflowStatus,
          this.props.application,
        )
        if (!declineApplicationNotifyApplicant) {
          message = message.split('.')[0]
        }
        this.toggleModal('')
        snugNotifier.success(message)
        this.clearDeclineApplicationModalOptions()
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
      .finally(() => {
        this.setState({ declineApplicationInProgress: false })
      })
  }

  onConfirmMarkAsApplicationApprovedClicked = () => {
    const { onMarkAsApplicationApproved } = this.props
    this.setState({
      workflowSaveButtonBusy: false,
    })

    const { currentTeam = {} } = this.props
    const { platformDefaultProvider = false } = currentTeam || {}
    const markAsApprovedOptions = {
      ...this.state.markAsApplicationApprovedOptions,
      sendUtilityReferrals: platformDefaultProvider
        ? true
        : this.state.markAsApplicationApprovedOptions.sendUtilityReferrals,
    }

    onMarkAsApplicationApproved(markAsApprovedOptions)
      .then(() => {
        this.toggleModal('')
      })
      .then(() => {
        this.setState({
          ...this.state,
          workflowSaveButtonBusy: false,
          markAsApplicationApprovedOptions: {
            declineUnsuccessfulApplications: false,
            cancelFutureViewings: false,
            sendUtilityReferrals: true,
            sendApplicationToPT: false,
            sendApplicationToKolmeo: false,
            sendApplicationToManagedApp: false,
            archiveProperty: false,
          },
          prevApplicationWorkflowStatus:
            helpers.ApplicationStatusCategory.Approved,
          currentWorkflowStatus: helpers.ApplicationStatusCategory.Approved,
        })
        this.onApplicationWorkflowChange(
          helpers.ApplicationStatusCategory.Approved,
        )
      })
  }

  onContextMenuDecline = () => {
    this.setState({
      activeModal: 'markAsApplicationDeclined',
      applicationWorkflowStatus: helpers.ApplicationStatusCategory['Declined'],
    })
  }

  checkConsoleCloudStatus = () => {
    const isConsoleCloudActive = this.isIntegrationActive(
      'console_cloud_enabled',
    )
    if (isConsoleCloudActive !== this.state.isConsoleCloudEnabled) {
      this.setState({ isConsoleCloudEnabled: isConsoleCloudActive })
    }
  }

  checkTicaStatus = () => {
    const isTicaActive = this.isIntegrationActive(
      integrationBrands.tica.attributeId,
    )
    if (isTicaActive !== this.state.isTicaActive) {
      this.setState({ isTicaActive })
    }
  }

  isIntegrationActive(integrationKey) {
    const integration =
      this.props.teamSettingInfo?.integrations?.[integrationKey]
    return !!integration && covertBoolStringToBool(integration)
  }

  onFetchAgencyProfile = (agencyId) => {
    this.props.fetchAgencyStatus(agencyId).then(({ integrationStatus }) => {
      this.setState({
        pmsIntegrationStatus: integrationStatus,
      })
    })
    this.props
      .fetchAgencyProfileForBackGroundCheck(agencyId)
      .then(({ agencyProfile }) => {
        if (agencyProfile.errors && agencyProfile.errors.length > 0) {
          agencyProfile.errors.map((error) => snugNotifier.error(error))
          return
        }
        this.setState({
          enableBackgroundCheckBundle:
            agencyProfile.data.enableBackgroundCheckBundle,
        })
      })
      .catch((error) => snugNotifier.error(error))
  }

  onRemoveShortListedApplication = () => {
    const { removeShortListApplication, applicationId, agencyId } = this.props
    this.setState({ disableShortlistButton: true })
    removeShortListApplication(applicationId)
      .then(() => {
        this.setState({
          isShortListed: false,
          disableShortlistButton: false,
        })
        snugNotifier.success('Application removed from shortlist')
      })
      .catch((error) => {
        this.setState({ disableShortlistButton: false })
        snugNotifier.error(error, {
          onOpen: () => this.setState({ showingToast: true }),
          onClose: () => this.setState({ showingToast: false }),
        })
      })
  }

  onRequestSubmitApplication = () => {
    const { requestSubmitApplication, application } = this.props
    const applicationGUID = selectApplicationGUID(application)
    if (window.confirm('Email household and SMS unsubmitted?')) {
      this.setState({ isSubmitApplicationRequestLoading: true })
      requestSubmitApplication(applicationGUID)
        .then(() => {
          snugNotifier.success(
            'Email to household and SMS to unsubmitted applicants requested',
          )
          this.setState({
            isSubmitApplicationRequestLoading: false,
            sendReminderIcon: greenTick,
            isReminderSent: true,
          })
        })
        .catch((error) => {
          snugNotifier.error(error)
          this.setState({
            isSubmitApplicationRequestLoading: false,
            isReminderSent: false,
          })
        })
    }
  }

  onTeamMemberUpdate = (id) => {
    const { teamMembersList } = this.state
    const { assignApplicationToTeamMember, agencyId, application } = this.props
    this.setState({ spinnerForTeamMember: true })
    let selectedTeamMember = ''
    if (teamMembersList.length > 0) {
      teamMembersList.forEach((teamMember) => {
        if (teamMember.contact && teamMember.contact.guidID === id) {
          return (selectedTeamMember = teamMember)
        }
      })
    }
    if (selectedTeamMember && selectedTeamMember.contact) {
      assignApplicationToTeamMember(
        agencyId,
        application.guidID,
        selectedTeamMember.contact.guidID,
      )
        .then(() => {
          this.setState({
            selectedTeamMember: id,
            spinnerForTeamMember: false,
          })
          snugNotifier.success(
            'Successfully assigned team member, notification requested',
          )
        })
        .catch((apiError) => {
          this.setState({
            spinnerForTeamMember: false,
          })
          snugNotifier.error(apiError)
        })
    }
    this.setState({ spinnerName: id })
  }

  onUnloadHandler = (event) => {
    event.preventDefault()
    event.returnValue = ''
  }

  callRegisterForViewing = (viewing) => {
    const { application, sendScheduleViewingFromApplication } = this.props
    const primaryApplicant = selectPrimaryApplicant(application.applicants)

    const primaryApplicantGUID = primaryApplicant && primaryApplicant.guidID
    if (
      application &&
      application.guidID &&
      viewing.guidID &&
      primaryApplicantGUID
    ) {
      sendScheduleViewingFromApplication(
        application.guidID,
        primaryApplicantGUID,
        viewing.guidID,
      )
        .then(({ data }) => {
          snugNotifier.success(
            applicationHelpers.successfullyRegisteredForScheduledViewing(
              data.firstName,
              data.lastName,
            ),
          )
        })
        .catch((error) => {
          snugNotifier.error(error)
        })
    }
  }

  checkForScrollOrPositionChange = () => {
    const appContent = document.querySelector('.app-content')
    const leftSwitcher = document.querySelector('.applicant-widget')
    const appContainerHeight = document.querySelector(
      '.app-content-container',
    ).offsetHeight
    const summaryDetailsList = document.querySelector('.summary-details-list')
    if (leftSwitcher && summaryDetailsList) {
      const leftSwitcherHeight = leftSwitcher.offsetHeight
      if (appContent.scrollTop > HEADER_HEIGHT) {
        if (appContent.scrollTop + leftSwitcherHeight > appContainerHeight) {
          leftSwitcher.style.top = '0px'
        } else {
          const maxTopValue =
            appContainerHeight -
            HEADER_AND_FOOTER_HEIGHT -
            summaryDetailsList.offsetHeight -
            leftSwitcherHeight
          const calculatedTopValue =
            appContent.scrollTop -
            HEADER_HEIGHT -
            summaryDetailsList.offsetHeight
          const calculatedValue = Math.min(maxTopValue, calculatedTopValue)
          leftSwitcher.style.top = `${calculatedValue + theme.height[12]}px`
        }
      } else {
        leftSwitcher.style.top = '0px'
      }
      if (
        leftSwitcher.scrollHeight -
          leftSwitcher.scrollTop -
          leftSwitcher.clientHeight <
        1
      ) {
        if (leftSwitcher.classList.contains('left-nav-switch'))
          leftSwitcher.classList.remove('left-nav-switch')
      } else {
        leftSwitcher.classList.add('left-nav-switch')
      }
    }
  }

  clearDeclineApplicationModalOptions = () => {
    this.setState({
      declineApplicationOptions: {
        ownerSelectedAnAlternative: false,
        householdAffordabilityIssue: false,
        petNotSuitable: false,
        rentalReferenceIssue: false,
        insufficientDocumentation: false,
        notSuitableProperty: false,
        ownerWithdrawnProperty: false,
        noSpecificReason: false,
      },
      declineApplicationNotifyApplicant: true,
      declineComment: '',
      showMoveApplicationPropertyModal: false,
    })
  }

  closeDeclineApplicationModal = () => {
    this.toggleModal('')
    this.clearDeclineApplicationModalOptions()
  }

  confirmAction(action, text, guidID, onConfirm) {
    if (window.confirm(`Are you sure you want to ${text} this offer?`)) {
      this.props
        .onStatusDropdownChange(action, guidID, 'Applications')
        .then(onConfirm)
    }
  }

  toggleMoveApplicationPropertyModal() {
    this.setState({
      showMoveApplicationPropertyModal:
        !this.state.showMoveApplicationPropertyModal,
    })
  }

  reloadApplicationDetails() {
    const { application, fetchRenterApplication } = this.props
    return fetchRenterApplication(application.guidID)
  }

  confirmOfferApplication = (guidID) => {
    history.push(urlTo('progressApplication', { applicationId: guidID }))
  }

  fetchAndSetTeamMembersList = (agencyId) => {
    const {
      fetchAgencyTeamMembers,
      fetchApplicationTeamMember,
      applicationId,
    } = this.props
    if (agencyId) {
      this.setState({ spinnerForTeamMember: true })
      new Promise((resolve, reject) => {
        fetchAgencyTeamMembers(agencyId).then((managerList = []) => {
          const teamMembersListDropDownContent = []
          if (managerList?.length > 0) {
            managerList.forEach((teamMember) => {
              if (teamMember.contact && teamMember.contact !== null) {
                const teamMemberObj = {
                  id: teamMember.contact.guidID,
                  label: `${teamMember.contact.firstName} ${teamMember.contact.lastName}`,
                }
                teamMembersListDropDownContent.push(teamMemberObj)
              }
            })
          }
          this.setState({
            teamMembersList: managerList,
            teamMembersListDropDownContent,
            spinnerForTeamMember: false,
          })
          resolve(managerList)
        })
      }).then((managerList) => {
        fetchApplicationTeamMember(agencyId, applicationId).then(
          (teamMemberInfo) => {
            managerList.forEach((teamMember) => {
              if (teamMemberInfo && teamMember.contact) {
                if (teamMember.contact.email === teamMemberInfo.email) {
                  this.setState({
                    selectedTeamMember: teamMember.contact.guidID,
                    spinnerForTeamMember: false,
                  })
                }
              }
            })
          },
        )
      })
    }
  }

  generateApplicationPDF = (downloadOption, callback = undefined) => {
    const isGeneratePDFDisabled = getParameter('disableGeneratePDF') === 'true'
    if (
      this.props.application &&
      this.props.application.guidID &&
      !isGeneratePDFDisabled
    ) {
      this.setState({ generatingPDF: true })
      const { application, selectedApplicantGUID } = this.props
      const { targetApplication } = this.state
      const { applicants } = application
      const targetPDF = DownloadOptions[downloadOption]

      this.setState({ generatingPDF: true })
      window.onbeforeunload = () => true
      if (callback === undefined) {
        applicants
          .filter((applicant) => applicant.applied)
          .forEach((applicant) => {
            this.props.getApplicationPDF &&
              this.props
                .getApplicationPDF(
                  this.props.application.guidID,
                  applicant.guidID,
                  targetPDF.value,
                )
                .then((url) => {
                  applicants.forEach((targetApplicant) => {
                    if (targetApplicant.guidID === applicant.guidID) {
                      targetApplicant[targetPDF.pdfAttribute] = url
                      this.setState(
                        {
                          targetApplication: application,
                        },
                        () => {
                          this.setState({ generatingPDF: false })
                          window.onbeforeunload = null
                        },
                      )
                    }
                  })
                })
                .catch((error) => {
                  applicants.forEach((targetApplicant) => {
                    if (targetApplicant.guidID === applicant.guidID) {
                      targetApplicant[targetPDF.pdfAttribute] = 'not exist'
                      this.setState({ targetApplication: application }, () => {
                        this.setState({ generatingPDF: false })
                        window.onbeforeunload = null
                      })
                      if (!!targetApplicant.applied) {
                        snugNotifier.error(
                          `Error while preparing PDF download for ${targetApplicant.firstName} (${error})`,
                          {
                            onOpen: () => this.setState({ showingToast: true }),
                            onClose: () =>
                              this.setState({ showingToast: false }),
                          },
                        )
                      }
                    }
                  })
                })
          })
      } else {
        this.props.getApplicationPDF &&
          this.props
            .getApplicationPDF(
              this.props.application.guidID,
              selectedApplicantGUID,
              targetPDF.value,
            )
            .then((url) => {
              // eslint-disable-next-line array-callback-return
              targetApplication.applicants.map((targetApplicant) => {
                if (targetApplicant.guidID === selectedApplicantGUID) {
                  targetApplicant[targetPDF.pdfAttribute] = url
                  this.setState(
                    {
                      targetApplication,
                    },
                    () => {
                      this.setState({ generatingPDF: false })
                      window.onbeforeunload = null
                      callback && callback(true)
                    },
                  )
                }
              })
            })
            .catch((error) => {
              targetApplication &&
                targetApplication.applicants.forEach((targetApplicant) => {
                  if (targetApplicant.guidID === selectedApplicantGUID) {
                    targetApplicant[targetPDF.pdfAttribute] = 'not exist'
                    this.setState({ targetApplication }, () => {
                      this.setState({ generatingPDF: false })
                      window.onbeforeunload = null
                      callback && callback(false)
                    })
                    if (!!targetApplicant.applied) {
                      snugNotifier.error(
                        `Error while preparing PDF download for ${targetApplicant.firstName} (${error})`,
                        {
                          onOpen: () => this.setState({ showingToast: true }),
                          onClose: () => this.setState({ showingToast: false }),
                        },
                      )
                    }
                  }
                })
            })
      }
    }
  }

  handleApplicationPageResize = () => {
    this.setState({
      ...this.state,
      leftSwitcherMaxHeight:
        window.innerHeight -
        LEFT_SWITCHER_SPACE -
        5 * LEFT_SWITCER_GUTTER_SPACE,
      windowWidth: window.innerWidth,
    })
  }

  isDownloadablePDF = (status, displayedApplicant) => {
    return !isStatusDeclined(ApplicationCategory[status]) && displayedApplicant
  }

  reactToastify = (category, message) => {
    if (category === 'success') {
      snugNotifier.success(message || 'success')
    } else if (category === 'fail') {
      snugNotifier.error(message || 'failed')
    }
  }

  rentalReferenceRequestErrorHandler = (error) => {
    this.setState({ referenceResendError: error })
  }

  requestRenterInfo = (content, guidID, primaryApplicant, teamSlug) => {
    const applicantID = this.props.selectedApplicantGUID
      ? this.props.selectedApplicantGUID
      : primaryApplicant.guidID
    const { allRequestedString } = this.state
    this.setState({
      disableRequestInfoButton: true,
    })
    this.props
      .requestRenterInformation(guidID, applicantID, content)
      .then(() => {
        snugNotifier.success('Request for information sent')
        this.setState({
          numberOfReqInfoItemsSelected: 0,
          disableRequestInfoButton: false,
        })
        if (
          Features.isNewWorkflowEnabled(teamSlug) &&
          isWorkflowReviewStage(this.state.applicationWorkflowStatus) &&
          this.state.applicationWorkflowStatus !==
            getRequestedInfoSubstage().status
        ) {
          const { status, getMessage } = getRequestedInfoSubstage()
          this.onClickSave(status, getMessage)
        }
        const payload = {
          type: noteActionType['requestRenterInfo'],
          target: 16,
          content: allRequestedString,
        }
        this.updateRequestForRequestInfoSent(true)
        this.props.onNewRequestedInfoAdded(payload)
      })
      .catch((error) => {
        this.setState({
          disableRequestInfoButton: false,
        })
        snugNotifier.error(error)
      })
  }

  toggleModal(modal = '') {
    this.setState({
      activeModal: modal,
    })
  }

  toggleRequestInfoModal = (text, fromTICA = false) => {
    this.setState({
      isRequestInfoModalActive: !this.state.isRequestInfoModalActive,
      requestInfoFromTica: fromTICA,
    })
    if (text && typeof text === 'string') {
      const corrSection = correspondingSectionInRequestInfoModal(text)
      this.setState({
        corrRequestInfoModalSection: corrSection,
      })
    }
  }

  toggleScheduleViewingModal = () => {
    this.setState({
      showScheduleViewingModal: !this.state.showScheduleViewingModal,
    })
  }

  update = (field, value) => {
    this.setState({ spinnerName: field })
    const {
      updatePreferences,
      property = {},
      propertyOffer,
      applicationId,
      applicantId,
    } = this.props
    const activeOfferGuid = hasActiveOfferGuid(property)
    const offerId = (propertyOffer || {}).guidID || activeOfferGuid

    const dropdownIds = optimisingInsightsDropdownContent.map((o) => o.id)
    dropdownIds.forEach((dropdownId) =>
      dropdownId === field
        ? this.setState({ [dropdownId]: value })
        : this.setState({ [dropdownId]: 0 }),
    )

    this.setState(
      { offerId },
      () =>
        property.guidID &&
        updatePreferences(
          property.guidID,
          this.state,
          applicationId,
          applicantId,
        ).then(this.reactToastify),
    )
  }

  updateNumReqInfoItems = (numItems, allRequestedData, formInfoData) => {
    const allRequested = allRequestedData
      .map((req) => {
        return req.name
      })
      .join(', ')
    this.setState({
      numberOfReqInfoItemsSelected: numItems,
      requestInfoFormInfoData: formInfoData,
      allRequestedString: allRequested,
    })
  }

  updateOwnerMatchPreferences = (id) => {
    this.update(id, 100)
  }

  updateRequestForRequestInfoSent = (value) => {
    this.setState({
      requestForRequestInfoSent: value,
    })
  }

  updateScheduleViewingModal = (modalValues) => {
    this.setState(
      {
        ...this.state,
        scheduleViewingModal: {
          ...this.state.scheduleViewingModal,
          ...modalValues,
        },
      },
      () => this.onClickScheduleViewingPrimaryButton(),
    )
  }

  onApplicationStatusUpdateWithActions = (status, actions) => {
    const { guidID } = this.props.application
    updateStatusWithSideEffectActions(guidID, status, actions)
      .then(() => {
        this.toggleModal('')
        snugNotifier.success(`Moved to "Deposit Received"`)
        this.setState({
          prevApplicationWorkflowStatus: status,
          applicationWorkflowStatus: status,
        })
      })
      .catch((error) => {
        snugNotifier.error(error.message)
      })
  }

  renderDownloadApplicationModal = () => {
    const { activeModal, connectionReferralSelected } = this.state
    if (activeModal !== 'downloadApplication') return

    const { currentTeam } = this.props

    const config = {
      providerId: currentTeam.providerName,
      platformDefaultProvider: currentTeam?.platformDefaultProvider,
    }

    return (
      <DownloadApplicationModal
        sendReferralSelected={
          currentTeam?.platformDefaultProvider
            ? true
            : connectionReferralSelected
        }
        config={config}
        onToggleSendUtilityOption={this.updateSendUtilityConnection()}
        onSaveClicked={() => this.sendUtilityReferral()}
        onCloseModal={() => this.toggleModal('')}
      />
    )
  }

  onClickShortlistWithEmail = () => {
    const { application } = this.props
    const { teamSettingInfo } = this.props
    const { isShortListed } = this.state
    const applicationSettings =
      teamSettingInfo.application &&
      parseDataUtil.convertObjValueFromStringToBoolean(
        teamSettingInfo.application,
      )
    const { enable_internal_shortlisting: enableInternalShortlisting = false } =
      applicationSettings
    if (
      enableInternalShortlisting &&
      application.shortlistingEmailSent &&
      isShortListed
    ) {
      snugNotifier.success('Already shortlisted and email sent')
      return
    }

    const { guidID, shortlistingEmailSent } = application
    if (shortlistingEmailSent) {
      snugNotifier.success('Already shortlisted and email sent')
      return
    }

    shortListApplication(guidID, { sendEmail: true }).then(async () => {
      await this.reloadApplicationDetails()

      this.setState({
        isShortListed: true,
        disableShortlistButton: false,
      })
      snugNotifier.success('Application shortlisted and email sent')
    })
  }

  renderAssignTeamMember = () => {
    const {
      selectedTeamMember,
      teamMembersListDropDownContent,
      spinnerForTeamMember,
    } = this.state
    return (
      <Flex alignItems="center">
        <Box mr={4}>
          <strong>Assigned</strong>
        </Box>
        <Dropdown
          dropdownSelectedItem={selectedTeamMember}
          dropdownContent={teamMembersListDropDownContent}
          updateDropdown={this.onTeamMemberUpdate}
          waitingForData={spinnerForTeamMember}
        />
      </Flex>
    )
  }

  toggleDocumentsViewer = (documentsViewerGUID) => {
    const { showDocumentsViewer } = this.state
    this.setState({
      showDocumentsViewer: !showDocumentsViewer,
      documentsViewerGUID,
    })
  }

  getAttachmentInfo = (attachments, typeOfDocument) => {
    return attachments.map((attachment) => {
      const {
        name = '',
        URL = '',
        url = '',
        guidID = '',
        guid = '',
      } = attachment || {}
      const attachmentURL = URL || url
      const attachmentGUID = guidID || guid
      return {
        name,
        url: attachmentURL,
        typeOfDocument,
        guidID: attachmentGUID,
      }
    })
  }

  toggleShareWithOwnerModal = () => {
    const { isShareWithOwnerModalOpen } = this.state
    this.setState({
      isShareWithOwnerModalOpen: !isShareWithOwnerModalOpen,
      activeModal: '',
    })
  }

  render() {
    const {
      propertyOffer = { isActive: false },
      primaryApplicant = {},
      application = {},
      currentEmployers = [],
      previousEmployers = [],
      spinner,
      disableSpinner,
      teamSlug,
      proofOfIncome,
      rentalHistory,
      idDocumentsSummary = {},
      employers = [],
      backgroundCheck,
      attachments,
      pets = [],
      error,
      emergencyContacts = [],
      personalReferences = [],
      renter = {},
      reputationPreference = {},
      viewingCheckInSummary = {},
      selectedApplicantGUID,
      updatePreferences,
      applicationId,
      applicantId,
      property = {},
      profile,
      isApplyAnywhereApplication,
      versionsRatingCategories,
      studentDetails,
      newNotesContent,
      onNewNoteUpdated,
      onNoteIconClicked,
      onNewNoteAdded,
      applicationNotes = [],
      onNoteCrossClicked,
      spinnerForNotes,
      interpreteNoteResult,
      totalWeeklyIncome = 0,
      totalWeeklyIncomeEditedByManager = 0,
      pmSupportAttachments = [],
      pmDocSpinner,
      addPMSupportAttachment,
      pmAttachmentsApiError,
      deletePMattachment,
      onRemoveRentalReference,
      onBackgroundCheckClicked,
      isBackgroundCheckLoading,
      backgroundCheckApiError,
      teamSettingInfo,
      managerApplicationSupportingFiles = [],
      managerApplicationFiles = [],
      householdInsights,
      lastSubmitApplicationRequestTime,
      currentTeam,
      fetchAllPropertyFutureViewings,
      agencyId,
      handleApplicationChange,
      fetchApplicationApplicantBackgroundCheckReportDetails,
      selfTenancyCheck = {},
      scroller = {},
      appsDetailsLoading,
      appsDetails,
      renderBreadcrumbComponent,
      managerBGCCheckPrice,
    } = this.props
    const {
      providerName = '',
      utilityProviderConfigured = false,
      platformDefaultProvider = false,
    } = currentTeam || {}
    const {
      activeModal = '',
      prevApplicationWorkflowStatus,
      leftSwitcherMaxHeight,
      windowWidth,
      teamMembersListDropDownContent,
      selectedTeamMember,
      spinnerForTeamMember,
      markAsLeasedOptions,
      corrRequestInfoModalSection,
      requestInfoFromTica,
      numberOfReqInfoItemsSelected,
      requestInfoFormInfoData,
      requestForRequestInfoSent,
      disableRequestInfoButton,
      markAsApplicationApprovedOptions,
      pmsIntegrationStatus,
      showScheduleViewingModal,
      declineApplicationOptions,
      declineApplicationNotifyApplicant,
      declineComment,
      declineApplicationInProgress,
      enableBackgroundCheckBundle,
      isEditRenterApplication,
      rentAndEmploymentDetails,
      editSummaryInProgress,
      isConsoleCloudEnabled,
      isTicaActive,
      showMoveApplicationPropertyModal,
      showBackButton,
      backButtonType,
      targetApplication,
      showMoveToText,
      showDocumentsViewer,
      documentsViewerGUID,
      isShareWithOwnerModalOpen,
    } = this.state

    const { isLeaseUpdated } = rentAndEmploymentDetails
    const pmAttachmentsProps = {
      pmSupportAttachments,
      pmDocSpinner,
      addPMSupportAttachment,
      pmAttachmentsApiError,
      deletePMattachment,
    }

    let {
      weeklyRent,
      status,
      guidID,
      submittedDate,
      applicants,
      occupants,
      managerAddedRent = 0,
    } = application || ''
    const { disclosureURL } = renter || ''

    const { viewingID, checkInAt } = viewingCheckInSummary || {}
    const disclosureDate = moment(submittedDate).format(
      TRIMMED_DAY_MONTH_YEAR_FORMAT,
    )

    const disclosureDateTime = moment(submittedDate).format(
      HOUR_DAY_MONTH_YEAR_FORMAT,
    )
    const checkedInTime = viewingID
      ? moment(checkInAt).format('hh:mm DD MMM YYYY')
      : 'N/A'
    const backgroundCheckDate =
      backgroundCheck &&
      backgroundCheck.updatedAt &&
      backgroundCheck.updatedAt !== '0001-01-01T00:00:00Z'
        ? moment(backgroundCheck.updatedAt).format('hh:mm DD MMM YYYY')
        : ''
    const {
      isRequestInfoModalActive,
      isShortListed,
      disableShortlistButton,
      referenceResendError,
      generatingPDF,
      isReminderSent,
    } = this.state
    const isThereAnotherApplicantThatAcceptedTheOffer =
      propertyOffer && !propertyOffer.isActive
    const selectedApplicant =
      application && application.applicants
        ? application.applicants.find(
            (applicant) =>
              applicant.guidID === this.props.selectedApplicantGUID,
          )
        : undefined
    const displayedApplicant = selectedApplicantGUID
      ? selectedApplicant
      : primaryApplicant

    weeklyRent = managerAddedRent > 0 ? managerAddedRent : weeklyRent
    const ratio = rentToIncomeRatioCalculator(applicants, weeklyRent)
    const formattedStartDate =
      application && moment(application.leaseStartDate).format('DD MMM YYYY')

    const enableNotesButton =
      Features.FeatureFlag.Notes.isOn &&
      application &&
      application.applicationType !== 2

    const showwSpinner = !disableSpinner && spinner

    const currentApplicant =
      applicants &&
      applicants.sort(sortApplicants).find((applicant) => {
        return selectedApplicantGUID
          ? selectedApplicantGUID === applicant.guidID
          : applicant.isPrimary
      })
    const currentApplicantFullName =
      currentApplicant &&
      capitalizeFirstLetter(currentApplicant.firstName) +
        ' ' +
        capitalizeFirstLetter(currentApplicant.lastName)

    const applicationSettings =
      teamSettingInfo.application &&
      parseDataUtil.convertObjValueFromStringToBoolean(
        teamSettingInfo.application,
      )

    const bgCRestrictionEnabled =
      applicationSettings?.[
        applicationsAttributesIDs.bgCWorkflowRestrictionEnabled
      ]

    const statusBgCEligible =
      !bgCRestrictionEnabled || isStatusBgCEligible(application)

    const readyForBackgroundCheck =
      statusBgCEligible &&
      rentalHistory?.length > 0 &&
      idDocumentsSummary?.identityDocs?.length > 0

    const backgroundCheckProcessingEnabled = enableBackgroundCheckBundle
    let currentApplicantId = ''
    if (currentApplicant) {
      currentApplicantId = currentApplicant.guidID
    }

    const easyBondpayOptIn =
      (primaryApplicant && primaryApplicant.optedToReceiveEasyBondpay) || false

    const activeSendingOnboardIntegrations =
      getActiveIntegrationsSendingTenancyOnboard(pmsIntegrationStatus)

    // keeping code for later if this is to be added
    // let showRequestSubmitApplicationReminder = false
    // const unsubmittedApplicants =
    //   applicants && applicants.filter(applicant => !applicant.applied)
    // if (unsubmittedApplicants && unsubmittedApplicants.length >= 1) {
    //   showRequestSubmitApplicationReminder = true
    // }

    const isUtilityProviderAvailable = !!(
      currentTeam &&
      currentTeam.utilityProviderConfigured &&
      currentTeam.providerName !== ''
    )

    const isConciergeServiceEnabled = !!(
      currentTeam && currentTeam.conciergeServiceEnabled
    )

    const agencyGUID = selectAgencyGUID(property)
    const { isManagerAdded, rentToIncomeRatio } = application
    const dropdownItems = [
      'conditionPreferenceWeighting',
      'occupancyPreferenceWeighting',
      'yieldPreferenceWeighting',
    ]
    const dropdownSelectedItem = dropdownItems.filter((item) => {
      return this.state[item] === 100
    })
    const dropdownSelectedItemID =
      dropdownSelectedItem.length && dropdownSelectedItem[0]

    const isMobile = windowWidth <= 968
    const preferencePageID = helpers.targetSectionObject['preferences'].pageID

    const ticaPageID = helpers.targetSectionObject.ticaCheck.pageID

    const targetApplicant =
      targetApplication &&
      targetApplication.applicants &&
      targetApplication.applicants.find(
        (applicant) => applicant.guidID === selectedApplicantGUID,
      )

    const { preference = {} } = targetApplicant || {}
    const {
      optOutBackgroundCheck = false,
      optOutEmploymentHistory = false,
      optOutRentalHistory = false,
      hasRentalBondsAccount = false,
      optInStudentDetails = false,
      optOutPets = false,
    } = preference || {}

    const downloadPDFButton = (
      <div
        className="applicant-details-container pdf-mt-n-50 desktop-only"
        id="profileContainer"
      >
        {/* don't render download button if action not available */}
        {this.props.getApplicationPDF && (
          <div className="display-flex pdf-hide">
            {this.isDownloadablePDF(status, displayedApplicant) && (
              <DownloadApplicationDropdown
                displayedApplicant={displayedApplicant}
                generatingPDF={generatingPDF}
                onClickDownloadPDF={this.onClickDownloadPDF}
                onApplicationDownload={this.onApplicationDownload}
                teamSlug={teamSlug}
                dropUp={true}
                componentClassName={'mt16'}
                disclosureURL={disclosureURL}
                utilityProviderConfigured={
                  utilityProviderConfigured && providerName
                }
              />
            )}
          </div>
        )}
      </div>
    )

    const composedFullNameWithEmailOrMobileNumber = `${helpers.capitalizeFirstLetter(
      primaryApplicant.firstName,
    )} ${helpers.capitalizeFirstLetter(primaryApplicant.lastName)} (${
      primaryApplicant.email || primaryApplicant.phone
    })`

    let scheduleViewingModalText = ''
    if (property && property.address && property.address.friendlyName) {
      scheduleViewingModalText = `Register ${composedFullNameWithEmailOrMobileNumber} for
    ${property.address.friendlyName}, ${property.address.suburb}`
    }

    let { leaseStart = '', applicantsEmployment = [] } =
      rentAndEmploymentDetails || {}

    if (leaseStart) {
      leaseStart = moment(leaseStart).format(dateTimeHelpers.DATE_WITH_SLASH)
    }
    let backButtonURL = ''
    if (showBackButton) {
      if (backButtonType === textHelpers.PROSPECT_SUMMARY) {
        backButtonURL = `${helpers.urlTo('prospectSummary', {
          teamSlug,
        })}?stage=Application&property=${property.guidID}`
      } else if (backButtonType === textHelpers.APPLICATION_BY_STAGE) {
        backButtonURL = `${helpers.urlTo('teamApplicationsByStage', {
          teamSlug,
        })}`
      }
    }

    const { address = {} } = property || {}
    const { state = '' } = address || {}

    const isBackgroundChecked =
      backgroundCheck &&
      backgroundCheck.identityVerification &&
      backgroundCheck.identityVerification.result &&
      backgroundCheck.attachedToProfile &&
      !requestInfoFromTica &&
      !checkIfBGCheckNotSupportedInState(state)

    const { publicConfig = {}, config: applicationConfig = {} } =
      application || {}
    const { agencyOptions = {} } = publicConfig || {}
    const { applicationsSettingsConfig = {} } = applicationConfig || {}
    const { solicitedAbovePrice = {} } = applicationConfig
    const {
      isAboveDefaultPrice = false,
      isSolicitedAbovePriceChecked = false,
    } = solicitedAbovePrice || {}
    const { idDocsNotRequested = false } = agencyOptions || {}
    const { acknowledgeSupplyOfIDDocuments = false } = displayedApplicant || {}

    const { ballotsEnabled = false } = propertyOffer || {}
    const disabledSections = [
      ...(!isTicaActive ? ['ticaCheck'] : []),
      ...(ballotsEnabled ? ['backgroundCheck'] : []),
    ]

    const {
      exclude_bankruptcy_report: excludeBGCBankruptcyReport = false,
      exlude_request_reason_for_moving: excludeRequestReasonForMoving = false,
      require_current_employment_only: requireCurrentEmploymentOnly = false,
    } = applicationsSettingsConfig || {}

    let isCompactWorkflow = false
    if (applicationSettings) {
      isCompactWorkflow =
        applicationSettings[applicationsAttributesIDs.compactWorkflowEnabled]
    }

    let documentViewerAttachments = []
    if (rentalHistory?.length) {
      rentalHistory.forEach((rh) => {
        const { ledger = [] } = rh || {}
        const ledgerAttachmentsDocs = this.getAttachmentInfo(
          ledger,
          'Rental Ledger',
        )
        documentViewerAttachments.push(...ledgerAttachmentsDocs)
      })
    }
    if (proofOfIncome) {
      const {
        BankStatements = [],
        Payslips = [],
        GovernmentBenefitDocuments = [],
        OtherIncomeDocuments = [],
      } = proofOfIncome
      if (BankStatements?.length) {
        const bankStatementDocuments = this.getAttachmentInfo(
          BankStatements,
          'Bank Statement',
        )
        documentViewerAttachments.push(...bankStatementDocuments)
      }
      if (Payslips?.length) {
        const payslipDocuments = this.getAttachmentInfo(Payslips, 'Payslip')
        documentViewerAttachments.push(...payslipDocuments)
      }

      if (GovernmentBenefitDocuments?.length) {
        const governmentBenefitDocs = this.getAttachmentInfo(
          GovernmentBenefitDocuments,
          'Govt. Benefit Docs',
        )
        documentViewerAttachments.push(...governmentBenefitDocs)
      }
      if (OtherIncomeDocuments?.length) {
        const otherIncomeDocs = this.getAttachmentInfo(
          OtherIncomeDocuments,
          'Other Income Docs',
        )
        documentViewerAttachments.push(...otherIncomeDocs)
      }
    }
    const { identityDocs = [], supportingIdentityDocs = [] } =
      idDocumentsSummary || {}
    if (identityDocs?.length) {
      identityDocs?.forEach((identityDoc) => {
        const { identityDocAttachments = [] } = identityDoc || {}
        const mappedIdentityDocs = identityDocAttachments?.length
          ? this.getAttachmentInfo(identityDocAttachments, 'Identity Doc')
          : []
        documentViewerAttachments.push(...mappedIdentityDocs)
      })
    }
    if (supportingIdentityDocs?.length) {
      const mappedIdentityDocs = this.getAttachmentInfo(
        supportingIdentityDocs,
        'Supporting Identity Doc',
      )
      documentViewerAttachments.push(...mappedIdentityDocs)
    }
    if (pets) {
      pets &&
        pets.forEach((pet) => {
          const { petReferences = [] } = pet
          const petReferencesDocuments = petReferences?.length
            ? this.getAttachmentInfo(petReferences, 'Pet')
            : []
          documentViewerAttachments.push(...petReferencesDocuments)
        })
    }

    if (studentDetails) {
      studentDetails &&
        studentDetails.forEach((pet) => {
          const { offerLetterAttachments = [], studentIDAttachments = [] } = pet
          const offerLetterAttachmentsDocuments = offerLetterAttachments?.length
            ? this.getAttachmentInfo(offerLetterAttachments, 'Offer Letter')
            : []

          const studentIDAttachmentsDocuments = studentIDAttachments?.length
            ? this.getAttachmentInfo(studentIDAttachments, 'Student ID')
            : []
          documentViewerAttachments.push(...offerLetterAttachmentsDocuments)
          documentViewerAttachments.push(...studentIDAttachmentsDocuments)
        })
    }

    if (attachments?.length) {
      const attachmentsDocuments = this.getAttachmentInfo(
        attachments,
        'Renter Attachment',
      )
      documentViewerAttachments.push(...attachmentsDocuments)
    }
    if (pmSupportAttachments?.length) {
      const pmSupportAttachmentsDocs = this.getAttachmentInfo(
        pmSupportAttachments,
        'PM Att. (Private)',
      )
      documentViewerAttachments.push(...pmSupportAttachmentsDocs)
    }

    if (disclosureURL) {
      const disclosureURLAttachment = {
        name: `Disclosure_${currentApplicantFullName}_${disclosureDate}.pdf`,
        url: disclosureURL,
        guidID: 'disclosure',
        typeOfDocument: 'Renter Disclosure',
      }
      documentViewerAttachments.push(disclosureURLAttachment)
    }

    const currentApplicantEmail = currentApplicant?.email
    const currentApplicantPhone = currentApplicant?.mobileNumber

    const toggleDocumentsViewerFunc = this.toggleDocumentsViewer
    return (
      <div className={`sm-applications-detailed-applicant-details mt100`}>
        {!isMobile && (
          <>
            <ApplicationToolbar
              property={property}
              activeOffer={propertyOffer}
              onClickMoveApplication={() =>
                this.toggleMoveApplicationPropertyModal()
              }
              onClickWithdrawOffer={() =>
                this.confirmAction('withdrawoffer', 'withdraw', guidID, () =>
                  history.push(
                    `${urlTo('prospectSummary', {
                      teamSlug,
                    })}?property=${property.guidID}`,
                  ),
                )
              }
              onClickDecline={this.onContextMenuDecline}
              currentWorkflowStatus={prevApplicationWorkflowStatus}
              containerClass="mr10"
              onClickScheduleViewing={this.toggleScheduleViewingModal}
              applicationSettings={applicationSettings}
              onClickShortlistWithEmail={this.onClickShortlistWithEmail}
              appsDetails={appsDetails}
              onClickShare={this.onClickShare}
              onClickItem={this.onChangeWorkflow}
              compactWorkflow={isCompactWorkflow}
              showMoveToText={showMoveToText}
              isShortListed={isShortListed}
              onAddShortListedApplication={this.onAddShortListedApplication}
              onRemoveShortListedApplication={
                this.onRemoveShortListedApplication
              }
              renderAssignTeamMember={this.renderAssignTeamMember}
              teamSlug={teamSlug}
              onClickSave={this.onClickSave}
              appsDetailsLoading={appsDetailsLoading}
              disableShortlistButton={disableShortlistButton}
            />
          </>
        )}

        <div className="col-first xs-first position-relative">
          <div className="mobile-tablet-only">
            <ApplicationWorkflowProgressChart
              currentWorkflowStatus={prevApplicationWorkflowStatus}
              containerClass="height40 mt20 mb20"
            />
            {numberOfReqInfoItemsSelected > 0 && (
              <Button
                componentClass="mb10"
                text={`Send Request (${numberOfReqInfoItemsSelected})`}
                disabled={disableRequestInfoButton}
                onClick={() => {
                  this.requestRenterInfo(
                    requestInfoFormInfoData,
                    guidID,
                    primaryApplicant,
                    teamSlug,
                  )
                }}
              >
                {disableRequestInfoButton && (
                  <i className="mr10 fa fa-spinner fa-pulse" />
                )}
              </Button>
            )}
          </div>
          <div className="position-relative main-content">
            <Display.Aside>
              <ApplicationLeftColumn
                occupants={occupants}
                applicants={applicants}
                children={application.children}
                selectedApplicantGUID={selectedApplicantGUID}
                handleApplicantChange={this.props.handleApplicantChange}
                downloadPDFButton={downloadPDFButton}
                leftSwitcherMaxHeight={leftSwitcherMaxHeight}
                interpreteNoteResult={interpreteNoteResult}
                onRequestSubmitApplication={this.onRequestSubmitApplication}
                lastSubmitApplicationRequestTime={
                  lastSubmitApplicationRequestTime
                }
                isReminderSent={isReminderSent}
                isManagerAdded={isManagerAdded}
                idDocsNotRequested={idDocsNotRequested}
                disabledSections={disabledSections}
                ballotsEnabled={ballotsEnabled}
                toggleRequestInfoModal={this.toggleRequestInfoModal}
                numberOfReqInfoItemsSelected={numberOfReqInfoItemsSelected}
                sendRequestInfo={() =>
                  this.requestRenterInfo(
                    requestInfoFormInfoData,
                    guidID,
                    primaryApplicant,
                    teamSlug,
                  )
                }
              />
            </Display.Aside>
            <StyledDisplayContent>
              <Display.Content>
                {isMobile && (
                  <InsightsPanel
                    rentToIncomeRatio={rentToIncomeRatio}
                    rentalHistory={rentalHistory}
                    optimisingInsightsDropdownContent={
                      optimisingInsightsDropdownContent
                    }
                    updateOwnerMatchPreferences={
                      this.updateOwnerMatchPreferences
                    }
                    dropdownSelectedItem={dropdownSelectedItemID}
                    householdInsights={householdInsights}
                  />
                )}
                {application && (
                  <div className={`blue-link-style text-align-right mb10 mt10`}>
                    {isManagerAdded && (
                      <Link
                        className="mr10"
                        to={`${urlTo('editApplicationByManager', {
                          applicationId,
                        })}`}
                      >
                        Edit Application
                      </Link>
                    )}
                  </div>
                )}
                <StyledBreadcrumbs isManagerAdded={isManagerAdded}>
                  {renderBreadcrumbComponent()}
                </StyledBreadcrumbs>
                <CenterContentContainer componentClass="application-standard-shadow-box summary-box-on-mobile position-relative z-index-auto pdf-bts max-width-600px">
                  {application && (
                    <>
                      <ApplicationSummary
                        ratio={ratio}
                        application={application}
                        formattedStartDate={formattedStartDate}
                        hasOptimiseForButtons
                        updatePreferences={updatePreferences}
                        propertyOffer={propertyOffer}
                        applicationId={applicationId}
                        applicantId={applicantId}
                        property={property}
                        profile={profile}
                        teamSlug={teamSlug}
                        id={
                          helpers.targetSectionObject['applicationSummary']
                            .pageID
                        }
                      />
                    </>
                  )}
                  {application && applicants && (
                    <PreferenceComponent
                      application={application}
                      applicants={applicants}
                      isSummaryEditable={false}
                      totalEmployedWeeklyNetIncome={totalWeeklyIncome}
                      totalWeeklyNetIncomeEditedByManager={
                        totalWeeklyIncomeEditedByManager
                      }
                      propertyOffer={propertyOffer}
                      enableNote={true}
                      displayedApplicant={displayedApplicant}
                      onNewNoteUpdated={onNewNoteUpdated}
                      newNotesContent={newNotesContent}
                      onNoteIconClicked={onNoteIconClicked}
                      onNewNoteAdded={onNewNoteAdded}
                      onNoteCrossClicked={onNoteCrossClicked}
                      interpreteNoteResult={interpreteNoteResult}
                      enableNotesButton={enableNotesButton}
                      section={'preferences'}
                      id={preferencePageID}
                      toggleRequestInfoModal={this.toggleRequestInfoModal}
                      isManagerAdded={isManagerAdded}
                      toggleEditRenterApplication={
                        this.toggleEditRenterApplication
                      }
                      isManagerSummary={true}
                      isLeaseUpdated={isLeaseUpdated}
                      teamSettingInfo={teamSettingInfo}
                    />
                  )}
                </CenterContentContainer>
                <CenterContentContainer componentClass="application-standard-shadow-box col-xs-12 summary-box-on-mobile mt30 z-index-auto">
                  {!isManagerAdded && (
                    <Headers.SectionHeader hasSeperator={false}>
                      <div className="application-summary-container pdf-hide">
                        <h3 className="heading pdf-p0">
                          Details{' '}
                          {currentApplicantFullName &&
                            `: ${currentApplicantFullName}`}
                        </h3>
                      </div>
                      <div className="standard-section-seperator" />
                    </Headers.SectionHeader>
                  )}
                  {!isManagerAdded && (
                    <div
                      className="applicant-details-container pdf-mt-n-50"
                      id="profileContainer"
                    />
                  )}

                  {showwSpinner && <Spinner />}
                  {!showwSpinner &&
                    displayedApplicant &&
                    ((isPrimaryApplicant(displayedApplicant) &&
                      isStatusQuickApplied(ApplicationCategory[status]) ===
                        false) ||
                      (isSecondaryApplicant(displayedApplicant) &&
                        isApplicantApplied(displayedApplicant))) && (
                      <div className="profile-section">
                        {!isManagerAdded && (
                          <ManagerAddressHistoryComponent
                            rentalHistory={rentalHistory}
                            optOutRentalHistory={optOutRentalHistory}
                            hasRentalBondsAccount={hasRentalBondsAccount}
                            isAddressHistoryEditable={false}
                            componentClassName="bbs"
                            enableNote={true}
                            displayedApplicant={displayedApplicant}
                            onNewNoteUpdated={onNewNoteUpdated}
                            newNotesContent={newNotesContent}
                            onNoteIconClicked={onNoteIconClicked}
                            onNewNoteAdded={onNewNoteAdded}
                            onNoteCrossClicked={onNoteCrossClicked}
                            interpreteNoteResult={interpreteNoteResult}
                            enableNotesButton={enableNotesButton}
                            section={'addressHistory'}
                            handleSubmitRentalReviewRequest={
                              this.props.handleSubmitRentalReviewRequest
                            }
                            rentalReferenceRequestErrorHandler={
                              this.rentalReferenceRequestErrorHandler
                            }
                            error={referenceResendError}
                            versionsRatingCategories={versionsRatingCategories}
                            selectedApplicantGUID={selectedApplicantGUID}
                            applicationId={applicationId}
                            onRemoveRentalReference={onRemoveRentalReference}
                            agencyGUID={agencyGUID}
                            id={
                              helpers.targetSectionObject['addressHistory']
                                .pageID
                            }
                            toggleRequestInfoModal={this.toggleRequestInfoModal}
                            ballotsEnabled={ballotsEnabled}
                            toggleDocumentsViewer={toggleDocumentsViewerFunc}
                            renterName={currentApplicant?.firstName}
                            excludeRequestReasonForMoving={
                              excludeRequestReasonForMoving
                            }
                          />
                        )}
                        {!isManagerAdded && (
                          <EmploymentComponent
                            employers={employers}
                            optOutEmploymentHistory={optOutEmploymentHistory}
                            isEmploymentEditable={false}
                            componentClassName="bbs"
                            enableNote={true}
                            displayedApplicant={displayedApplicant}
                            onNewNoteUpdated={onNewNoteUpdated}
                            newNotesContent={newNotesContent}
                            onNoteIconClicked={onNoteIconClicked}
                            onNewNoteAdded={onNewNoteAdded}
                            onNoteCrossClicked={onNoteCrossClicked}
                            interpreteNoteResult={interpreteNoteResult}
                            enableNotesButton={enableNotesButton}
                            section={'employmentDetails'}
                            currentEmployers={currentEmployers}
                            previousEmployers={previousEmployers}
                            applicationId={applicationId}
                            id={
                              helpers.targetSectionObject['employmentDetails']
                                .pageID
                            }
                            toggleRequestInfoModal={this.toggleRequestInfoModal}
                            agencyGUID={agencyGUID}
                            reputationPreference={reputationPreference}
                            isManagerAdded={isManagerAdded}
                            toggleEditRenterApplication={
                              this.toggleEditRenterApplication
                            }
                            requireCurrentEmploymentOnly={
                              requireCurrentEmploymentOnly
                            }
                          />
                        )}
                        {!isManagerAdded && (
                          <IncomeContainer
                            proofOfIncome={proofOfIncome}
                            isIncomeEditable={false}
                            componentClassName="bbs"
                            enableNote={true}
                            displayedApplicant={displayedApplicant}
                            onNewNoteUpdated={onNewNoteUpdated}
                            newNotesContent={newNotesContent}
                            onNoteIconClicked={onNoteIconClicked}
                            onNewNoteAdded={onNewNoteAdded}
                            onNoteCrossClicked={onNoteCrossClicked}
                            interpreteNoteResult={interpreteNoteResult}
                            enableNotesButton={enableNotesButton}
                            section={'income'}
                            id={helpers.targetSectionObject['income'].pageID}
                            toggleRequestInfoModal={this.toggleRequestInfoModal}
                            toggleEditRenterApplication={
                              this.toggleEditRenterApplication
                            }
                            isManagerAdded={isManagerAdded}
                            toggleDocumentsViewer={toggleDocumentsViewerFunc}
                          />
                        )}
                        {!isManagerAdded && (
                          <IdentityDocumentsContainer
                            identityDocs={idDocumentsSummary?.identityDocs}
                            supportingIdentityDocs={
                              idDocumentsSummary?.supportingIdentityDocs
                            }
                            identityCheckPoints={
                              idDocumentsSummary?.identityCheckPoints
                            }
                            isIdentityDocumentsEditable={false}
                            componentClassName="no-box-shadow summary-container-padding pb0 bbs"
                            enableNote={true}
                            displayedApplicant={displayedApplicant}
                            onNewNoteUpdated={onNewNoteUpdated}
                            newNotesContent={newNotesContent}
                            onNoteIconClicked={onNoteIconClicked}
                            onNewNoteAdded={onNewNoteAdded}
                            onNoteCrossClicked={onNoteCrossClicked}
                            interpreteNoteResult={interpreteNoteResult}
                            enableNotesButton={enableNotesButton}
                            section={'identity'}
                            id={helpers.targetSectionObject['identity'].pageID}
                            toggleRequestInfoModal={this.toggleRequestInfoModal}
                            idDocsNotRequested={idDocsNotRequested}
                            acknowledgeSupplyOfIDDocuments={
                              acknowledgeSupplyOfIDDocuments
                            }
                            showManagerAlertTextForIDDocs={true}
                            selfTenancyCheck={selfTenancyCheck}
                            toggleDocumentsViewer={toggleDocumentsViewerFunc}
                          />
                        )}
                        {!isManagerAdded &&
                          !ballotsEnabled &&
                          applicationSettings && (
                            <BackgroundCheckContainer
                              optOutBackgroundCheck={optOutBackgroundCheck}
                              attachedToProfile={
                                backgroundCheck?.attachedToProfile
                              }
                              backgroundCheck={backgroundCheck}
                              backgroundCheckDate={backgroundCheckDate}
                              isBackgroundCheckEditable={false}
                              selectedApplicant={displayedApplicant}
                              enableNote={true}
                              displayedApplicant={displayedApplicant}
                              onNewNoteUpdated={onNewNoteUpdated}
                              newNotesContent={newNotesContent}
                              onNoteIconClicked={onNoteIconClicked}
                              onNewNoteAdded={onNewNoteAdded}
                              onNoteCrossClicked={onNoteCrossClicked}
                              interpreteNoteResult={interpreteNoteResult}
                              enableNotesButton={enableNotesButton}
                              section={'backgroundCheck'}
                              readyForBackgroundCheck={readyForBackgroundCheck}
                              backgroundCheckProcessingEnabled={
                                backgroundCheckProcessingEnabled
                              }
                              bgCRestrictionEnabled={bgCRestrictionEnabled}
                              currentApplicantId={currentApplicantId}
                              onBackgroundCheckClicked={
                                onBackgroundCheckClicked
                              }
                              isBackgroundCheckLoading={
                                isBackgroundCheckLoading
                              }
                              backgroundCheckApiError={backgroundCheckApiError}
                              componentClassName="bbs"
                              id={
                                helpers.targetSectionObject['backgroundCheck']
                                  .pageID
                              }
                              toggleRequestInfoModal={
                                this.toggleRequestInfoModal
                              }
                              fetchApplicationApplicantBackgroundCheckReportDetails={
                                fetchApplicationApplicantBackgroundCheckReportDetails
                              }
                              isTicaActive={isTicaActive}
                              applicationPropertyState={state}
                              teamSlug={teamSlug}
                              managerBGCCheckPrice={managerBGCCheckPrice}
                              excludeBGCBankruptcyReport={
                                excludeBGCBankruptcyReport
                              }
                            />
                          )}
                        {!isManagerAdded && agencyId && isTicaActive && (
                          <TicaCheck
                            teamId={agencyId}
                            enableNote={true}
                            onNewNoteUpdated={onNewNoteUpdated}
                            newNotesContent={newNotesContent}
                            onNoteIconClicked={onNoteIconClicked}
                            onNewNoteAdded={onNewNoteAdded}
                            onNoteCrossClicked={onNoteCrossClicked}
                            interpreteNoteResult={interpreteNoteResult}
                            enableNotesButton={enableNotesButton}
                            applicantId={currentApplicantId}
                            id={ticaPageID}
                            section={'ticaCheck'}
                            toggleRequestInfoModal={() =>
                              this.toggleRequestInfoModal(
                                'Identity Documents',
                                true,
                              )
                            }
                          />
                        )}
                        {!isManagerAdded && (
                          <EmergencyContactContainer
                            emergencyContacts={emergencyContacts}
                            isEmergencyContactsEditable={false}
                            componentClassName="bbs"
                            enableNote={true}
                            displayedApplicant={displayedApplicant}
                            onNewNoteUpdated={onNewNoteUpdated}
                            newNotesContent={newNotesContent}
                            onNoteIconClicked={onNoteIconClicked}
                            onNewNoteAdded={onNewNoteAdded}
                            onNoteCrossClicked={onNoteCrossClicked}
                            interpreteNoteResult={interpreteNoteResult}
                            enableNotesButton={enableNotesButton}
                            section={'emergencyContact'}
                            id={
                              helpers.targetSectionObject['emergencyContact']
                                .pageID
                            }
                            toggleRequestInfoModal={this.toggleRequestInfoModal}
                          />
                        )}
                        {!isManagerAdded && (
                          <PersonalReferenceContainer
                            teamGUID={agencyId}
                            personalReferences={personalReferences}
                            isPersonalReferenceEditable={false}
                            componentClassName="bbs"
                            enableNote={true}
                            displayedApplicant={displayedApplicant}
                            onNewNoteUpdated={onNewNoteUpdated}
                            newNotesContent={newNotesContent}
                            onNoteIconClicked={onNoteIconClicked}
                            onNewNoteAdded={onNewNoteAdded}
                            onNoteCrossClicked={onNoteCrossClicked}
                            interpreteNoteResult={interpreteNoteResult}
                            enableNotesButton={enableNotesButton}
                            section={'personalReference'}
                            id={
                              helpers.targetSectionObject['personalReference']
                                .pageID
                            }
                            toggleRequestInfoModal={this.toggleRequestInfoModal}
                            teamSettingInfo={teamSettingInfo}
                          />
                        )}
                        {!isManagerAdded && (
                          <PetsContainer
                            pets={pets}
                            applicantName={currentApplicantFullName}
                            optOutPets={optOutPets}
                            isPetsEditable={false}
                            componentClassName="bbs"
                            enableNote={true}
                            displayedApplicant={displayedApplicant}
                            onNewNoteUpdated={onNewNoteUpdated}
                            newNotesContent={newNotesContent}
                            onNoteIconClicked={onNoteIconClicked}
                            onNewNoteAdded={onNewNoteAdded}
                            onNoteCrossClicked={onNoteCrossClicked}
                            interpreteNoteResult={interpreteNoteResult}
                            enableNotesButton={enableNotesButton}
                            section={'petDetails'}
                            id={
                              helpers.targetSectionObject['petDetails'].pageID
                            }
                            toggleRequestInfoModal={this.toggleRequestInfoModal}
                            toggleDocumentsViewer={toggleDocumentsViewerFunc}
                          />
                        )}
                        {!isManagerAdded && (
                          <StudentContainer
                            students={studentDetails}
                            optInStudentDetails={optInStudentDetails}
                            isStudentEditable={false}
                            componentClassName="bbs"
                            enableNote={true}
                            displayedApplicant={displayedApplicant}
                            onNewNoteUpdated={onNewNoteUpdated}
                            newNotesContent={newNotesContent}
                            onNoteIconClicked={onNoteIconClicked}
                            onNewNoteAdded={onNewNoteAdded}
                            onNoteCrossClicked={onNoteCrossClicked}
                            interpreteNoteResult={interpreteNoteResult}
                            enableNotesButton={enableNotesButton}
                            section={'studentDetails'}
                            id={
                              helpers.targetSectionObject['studentDetails']
                                .pageID
                            }
                            toggleRequestInfoModal={this.toggleRequestInfoModal}
                            toggleDocumentsViewer={toggleDocumentsViewerFunc}
                          />
                        )}
                        {!isManagerAdded && (
                          <ManagerOtherContainer
                            attachments={attachments}
                            selectedApplicant={displayedApplicant}
                            disclosureDate={disclosureDate}
                            disclosureDateTime={disclosureDateTime}
                            checkedInTime={checkedInTime}
                            enableNote={true}
                            displayedApplicant={displayedApplicant}
                            onNewNoteUpdated={onNewNoteUpdated}
                            newNotesContent={newNotesContent}
                            onNoteIconClicked={onNoteIconClicked}
                            onNewNoteAdded={onNewNoteAdded}
                            onNoteCrossClicked={onNoteCrossClicked}
                            interpreteNoteResult={interpreteNoteResult}
                            enableNotesButton={enableNotesButton}
                            pmAttachmentsProps={pmAttachmentsProps}
                            section={'otherDocuments'}
                            isApplyAnywhereApplication={
                              isApplyAnywhereApplication
                            }
                            teamSettingInfo={teamSettingInfo}
                            id={
                              helpers.targetSectionObject['otherDocuments']
                                .pageID
                            }
                            toggleRequestInfoModal={this.toggleRequestInfoModal}
                            toggleDocumentsViewer={toggleDocumentsViewerFunc}
                          />
                        )}
                        {isManagerAdded && (
                          <ManagerAttachment
                            applicationAttachments={managerApplicationFiles}
                            supportingAttachments={
                              managerApplicationSupportingFiles
                            }
                            selectedApplicant={displayedApplicant}
                            disclosureDate={disclosureDate}
                            disclosureDateTime={disclosureDateTime}
                            checkedInTime={checkedInTime}
                            componentClassName="bbs"
                            enableNote={true}
                            displayedApplicant={displayedApplicant}
                            onNewNoteUpdated={onNewNoteUpdated}
                            newNotesContent={newNotesContent}
                            onNoteIconClicked={onNoteIconClicked}
                            onNewNoteAdded={onNewNoteAdded}
                            onNoteCrossClicked={onNoteCrossClicked}
                            interpreteNoteResult={interpreteNoteResult}
                            enableNotesButton={enableNotesButton}
                            pmAttachmentsProps={pmAttachmentsProps}
                            section={'managerAttachments'}
                            isApplyAnywhereApplication={
                              isApplyAnywhereApplication
                            }
                            teamSettingInfo={teamSettingInfo}
                            id={
                              helpers.targetSectionObject['managerAttachments']
                                .pageID
                            }
                            toggleRequestInfoModal={this.toggleRequestInfoModal}
                          />
                        )}
                      </div>
                    )}
                  <UnsavedMessage
                    unsavedChanges={numberOfReqInfoItemsSelected > 0}
                    message="Unsaved changes will be lost. Continue?"
                  />
                </CenterContentContainer>
                <CenterContentContainer componentClass="application-standard-shadow-box col-xs-12 summary-box-on-mobile mt30 z-index-auto">
                  {!showwSpinner &&
                    displayedApplicant &&
                    ((isPrimaryApplicant(displayedApplicant) &&
                      isStatusQuickApplied(ApplicationCategory[status]) ===
                        false) ||
                      (isSecondaryApplicant(displayedApplicant) &&
                        isApplicantApplied(displayedApplicant))) && (
                      <div>
                        <ManagerComplianceContainer
                          disclosureURL={disclosureURL}
                          attachments={attachments}
                          selectedApplicant={displayedApplicant}
                          disclosureDate={disclosureDate}
                          disclosureDateTime={disclosureDateTime}
                          checkedInTime={checkedInTime}
                          enableNote={true}
                          displayedApplicant={displayedApplicant}
                          onNewNoteUpdated={onNewNoteUpdated}
                          newNotesContent={newNotesContent}
                          onNoteIconClicked={onNoteIconClicked}
                          onNewNoteAdded={onNewNoteAdded}
                          onNoteCrossClicked={onNoteCrossClicked}
                          interpreteNoteResult={interpreteNoteResult}
                          enableNotesButton={enableNotesButton}
                          pmAttachmentsProps={pmAttachmentsProps}
                          section={'compliance'}
                          teamSettingInfo={teamSettingInfo}
                          id={helpers.targetSectionObject['compliance'].pageID}
                          isApplyAnywhereApplication={
                            isApplyAnywhereApplication
                          }
                          toggleRequestInfoModal={this.toggleRequestInfoModal}
                          agencyInfo={this.props.currentTeam}
                          isAboveDefaultPrice={isAboveDefaultPrice}
                          isSolicitedAbovePriceChecked={
                            isSolicitedAbovePriceChecked
                          }
                          toggleDocumentsViewer={toggleDocumentsViewerFunc}
                        />
                      </div>
                    )}
                  {!spinner &&
                    displayedApplicant &&
                    isSecondaryApplicant(displayedApplicant) &&
                    !isApplicantApplied(displayedApplicant) && (
                      <div className="profile-section pl20 pt20 pb20">
                        {capitalizeFirstLetter(displayedApplicant.firstName)}{' '}
                        was invited to apply at{' '}
                        {moment(displayedApplicant.updatedAt).format(
                          'HH:mm DD MMM YYYY',
                        )}
                      </div>
                    )}
                </CenterContentContainer>
              </Display.Content>
            </StyledDisplayContent>

            <Display.Aside>
              <div>
                {!isMobile && (
                  <InsightsPanel
                    rentToIncomeRatio={rentToIncomeRatio}
                    rentalHistory={rentalHistory}
                    optimisingInsightsDropdownContent={
                      optimisingInsightsDropdownContent
                    }
                    updateOwnerMatchPreferences={
                      this.updateOwnerMatchPreferences
                    }
                    dropdownSelectedItem={dropdownSelectedItemID}
                    householdInsights={householdInsights}
                  />
                )}
                <div className="display-flex align-items-center">
                  <div className="notes-column-subheading pb0 font-size-16 color-unchecked mb10">
                    <img
                      src={applicationEye}
                      alt="private icon"
                      className="mr10 application-notes-icon application-icon-box"
                    />
                    This section is <strong>private</strong> to your team
                  </div>
                </div>
                {isMobile && <Box p={5}>{this.renderAssignTeamMember()}</Box>}

                {!!currentTeam &&
                  !!selectedTeamMember &&
                  isFollowingEnabled(teamSlug) && (
                    <Box
                      as="section"
                      p={theme.space[5] + 'px'}
                      mb={theme.space[6] + 'px'}
                    >
                      <ApplicationFollowers
                        teamID={currentTeam.guid}
                        assignedTeamMember={selectedTeamMember}
                        applicationID={applicationId}
                      />
                    </Box>
                  )}

                <ApplicationSideTabs
                  notesLoading={spinnerForNotes}
                  notes={applicationNotes}
                  changeApplication={handleApplicationChange}
                  applicationId={applicationId}
                  applicantId={applicantId}
                  showNotes={enableNotesButton}
                  teamSlug={teamSlug}
                />
              </div>
            </Display.Aside>
            {!isMobile && <FloatingSet scroller={scroller} />}
          </div>
        </div>

        {isMobile && (
          <div className="">
            <Display.BottomContentContainer>
              {!isThereAnotherApplicantThatAcceptedTheOffer && (
                <Display.CenterContentContainer componentClass="width100">
                  <div>
                    {!Features.isNewWorkflowEnabled(teamSlug) && (
                      <div className="apply-buttons-box application-bottom-buttons-wrapper width100">
                        {!isApplyAnywhereApplication &&
                          (isStatusApplied(ApplicationCategory[status]) ||
                            isStatusShortlisted(
                              ApplicationCategory[status],
                            )) && (
                            <span className="button-container mr10">
                              {disableShortlistButton && (
                                <i className="mt10 mr10 fa fa-spinner fa-pulse" />
                              )}
                              {!disableShortlistButton && (
                                <div>
                                  {isShortListed && (
                                    <img
                                      className={`application-star-style ${
                                        disableShortlistButton &&
                                        'disabled-link'
                                      }`}
                                      src={
                                        !application?.shortlistingEmailSent
                                          ? starBlue
                                          : starFilled
                                      }
                                      onClick={() => {
                                        this.onRemoveShortListedApplication()
                                      }}
                                      alt="icon"
                                    />
                                  )}
                                  {!isShortListed && (
                                    <img
                                      className={`application-star-style ${
                                        disableShortlistButton &&
                                        'disabled-link'
                                      }`}
                                      src={starEmpty}
                                      onClick={() => {
                                        this.onAddShortListedApplication()
                                      }}
                                      alt="icon"
                                    />
                                  )}
                                </div>
                              )}
                            </span>
                          )}
                        {(isStatusApplied(ApplicationCategory[status]) ||
                          isStatusShortlisted(ApplicationCategory[status])) && (
                          <div className="button-container mr10">
                            <button
                              className="btn btn-gray application-button"
                              onClick={this.onContextMenuDecline}
                            >
                              Decline
                            </button>
                          </div>
                        )}
                        {(isStatusApplied(ApplicationCategory[status]) ||
                          isStatusShortlisted(ApplicationCategory[status])) && (
                          <div className="button-container application-button mr10">
                            <button
                              className="btn offer-application application-button"
                              id={teamSlug}
                              onClick={() =>
                                this.confirmOfferApplication(guidID)
                              }
                            >
                              Progress
                            </button>
                          </div>
                        )}
                        {(isStatusOffered(ApplicationCategory[status]) ||
                          isStatusDeclined(ApplicationCategory[status])) && (
                          <div
                            className={
                              isStatusOffered(ApplicationCategory[status])
                                ? 'alert alert-success m0 p0 application-button-modifier'
                                : 'alert alert-danger m0 p0 application-button-modifier'
                            }
                          >
                            {isStatusOffered(ApplicationCategory[status])
                              ? 'Offered'
                              : 'Declined'}
                          </div>
                        )}
                        {isStatusOffered(ApplicationCategory[status]) && (
                          <div>
                            <button
                              className="btn btn-red btn-light application-button-modifier"
                              onClick={() =>
                                this.confirmAction(
                                  'withdrawoffer',
                                  'withdraw',
                                  guidID,
                                  () => {},
                                )
                              }
                            >
                              Withdraw offer
                            </button>
                          </div>
                        )}
                        {ApplicationCategory[status] === 'Accepted' && (
                          <div className="alert alert-success">Accepted</div>
                        )}
                      </div>
                    )}
                    {Features.isNewWorkflowEnabled(teamSlug) &&
                      !isStatusDeclined(ApplicationCategory[status]) && (
                        <Flex className="apply-buttons-box application-bottom-buttons-wrapper width100">
                          {showBackButton && (
                            <Flex className="desktop-only" mr={4}>
                              <ButtonWithIcon
                                type="button"
                                variant="greenHover"
                                sizeVariant="small"
                                leading={false}
                                IconCmp={ChevronLeftRounded}
                                iconContainerProps={{ m: 0 }}
                                mr={theme.space[3] + 'px'}
                                onClick={() => history.push(backButtonURL)}
                              />
                            </Flex>
                          )}
                          <div className="desktop-only width100 pr20">
                            <ApplicationWorkflowProgressChart
                              currentWorkflowStatus={
                                this.state.prevApplicationWorkflowStatus
                              }
                            />
                          </div>
                          {!isApplyAnywhereApplication &&
                            (isStatusApplied(ApplicationCategory[status]) ||
                              isStatusShortlisted(
                                ApplicationCategory[status],
                              )) && (
                              <span className="button-container mr10">
                                {disableShortlistButton && (
                                  <i className="mt10 mr10 fa fa-spinner fa-pulse" />
                                )}
                                {!disableShortlistButton && (
                                  <div className="icon-button-placeholder">
                                    {isShortListed && (
                                      <img
                                        className={`${
                                          disableShortlistButton &&
                                          'disabled-link'
                                        }`}
                                        src={
                                          !application?.shortlistingEmailSent
                                            ? starBlue
                                            : starFilled
                                        }
                                        onClick={() => {
                                          this.onRemoveShortListedApplication()
                                        }}
                                        alt="icon"
                                      />
                                    )}
                                    {!isShortListed && (
                                      <img
                                        className={`${
                                          disableShortlistButton &&
                                          'disabled-link'
                                        }`}
                                        src={starEmpty}
                                        onClick={() => {
                                          this.onAddShortListedApplication()
                                        }}
                                        alt="icon"
                                      />
                                    )}
                                  </div>
                                )}
                              </span>
                            )}
                          <div className="display-flex justify-items-center cursor-pointer icon-button-placeholder mt3 mr10">
                            <img
                              src={mailIcon}
                              onClick={this.onClickShare}
                              alt="icon"
                            />
                          </div>
                          {shouldShowApplicationMoveTo(application) && (
                            <>
                              {applicationSettings && (
                                <ApplicationMoveToButton
                                  showMoveToText={this.state.showMoveToText}
                                  onClickItem={this.onChangeWorkflow}
                                  currentWorkflowStatus={
                                    this.state.applicationWorkflowStatus
                                  }
                                  dropup={true}
                                  compactWorkflow={isCompactWorkflow}
                                />
                              )}

                              <ApplicationWorkflowSaveButton
                                isLoading={this.state.workflowSaveButtonBusy}
                                onClick={this.onClickSave}
                                prevWorkflowStatus={
                                  this.state.prevApplicationWorkflowStatus
                                }
                                currentWorkflowStatus={
                                  this.state.applicationWorkflowStatus
                                }
                                isSaveDisabled={
                                  isWorkflowDeclined(
                                    this.state.applicationWorkflowStatus,
                                  ) ||
                                  isWorkflowDeclinedFlagged(
                                    this.state.applicationWorkflowStatus,
                                  )
                                }
                              />
                            </>
                          )}
                          <ApplicationWorkflowContextMenu
                            onClickMoveApplication={() =>
                              this.toggleMoveApplicationPropertyModal()
                            }
                            onClickWithdrawOffer={() =>
                              this.confirmAction(
                                'withdrawoffer',
                                'withdraw',
                                guidID,
                                () =>
                                  history.push(
                                    `${urlTo('prospectSummary', {
                                      teamSlug,
                                    })}?property=${property.guidID}`,
                                  ),
                              )
                            }
                            onClickDecline={this.onContextMenuDecline}
                            currentWorkflowStatus={
                              this.state.applicationWorkflowStatus
                            }
                            containerClass="mr10"
                            onClickScheduleViewing={
                              this.toggleScheduleViewingModal
                            }
                            applicationSettings={applicationSettings}
                            onClickShortlistWithEmail={
                              this.onClickShortlistWithEmail
                            }
                          />
                          {numberOfReqInfoItemsSelected > 0 && (
                            <Button
                              size="large"
                              text={`Send Request (${numberOfReqInfoItemsSelected})`}
                              onClick={() => {
                                this.requestRenterInfo(
                                  requestInfoFormInfoData,
                                  guidID,
                                  primaryApplicant,
                                  teamSlug,
                                )
                              }}
                              disabled={disableRequestInfoButton}
                              buttonStyleClass="width-max-content"
                              componentClass="desktop-only"
                            >
                              {disableRequestInfoButton && (
                                <i className="mr10 fa fa-spinner fa-pulse" />
                              )}
                            </Button>
                          )}
                        </Flex>
                      )}

                    {Features.isNewWorkflowEnabled(teamSlug) &&
                      isStatusDeclined(ApplicationCategory[status]) && (
                        <div className="apply-buttons-box application-bottom-buttons-wrapper width100">
                          <DeclinedLabel />
                        </div>
                      )}
                  </div>
                </Display.CenterContentContainer>
              )}
            </Display.BottomContentContainer>
          </div>
        )}
        <RequestInfoModal
          title="Request Information"
          error={error}
          showRequestInfoModal={isRequestInfoModalActive && displayedApplicant}
          firstName={
            displayedApplicant && displayedApplicant.firstName
              ? displayedApplicant.firstName
              : ''
          }
          lastName={
            displayedApplicant && displayedApplicant.lastName
              ? displayedApplicant.lastName
              : ''
          }
          toggleModal={this.toggleRequestInfoModal}
          corrRequestInfoModalSection={corrRequestInfoModalSection}
          updateNumReqInfoItems={this.updateNumReqInfoItems}
          isFromApplicationDetailsPageManager={true}
          updateRequestForRequestInfoSent={this.updateRequestForRequestInfoSent}
          requestForRequestInfoSent={requestForRequestInfoSent}
          requestRenterInformation={(content) => {
            this.requestRenterInfo(content, guidID, primaryApplicant, teamSlug)
          }}
          isBackgroundChecked={isBackgroundChecked}
          requestInfoFromTica={requestInfoFromTica}
          applicationPropertyState={state}
        />
        {activeModal === 'share' && (
          <BasicModal
            toggleModal={() => this.toggleModal('')}
            title="Share"
            body={
              <div>
                <p>
                  Invite this applicant to another property or share the
                  application with someone.
                </p>
              </div>
            }
            primaryButtonLabel="Invite to Property"
            primaryButtonAction={() =>
              history.push(this.state.sharePropertyUrl)
            }
            secondaryButtonLabel="Share Application"
            secondaryButtonAction={() => this.toggleShareWithOwnerModal()}
            secondaryButtonClassName="primaryButtonStyle p0"
            primaryButtonClassName="p0"
          />
        )}

        {this.renderDownloadApplicationModal()}

        {activeModal === 'markAsLeased' && (
          <MarkAsLeasedModal
            toggleModal={() => this.toggleModal('')}
            easyBondpayOptIn={easyBondpayOptIn}
            isConciergeServiceEnabled={isConciergeServiceEnabled}
            isConsoleCloudEnabled={isConsoleCloudEnabled}
            activeSendingOnboardIntegrations={activeSendingOnboardIntegrations}
            onChangemarkAsLeasedOptions={(field) =>
              this.onChangemarkAsLeasedOptions(field)
            }
            markAsLeasedOptions={markAsLeasedOptions}
            onConfirmButtonClicked={() => this.onConfirmButtonClicked()}
            teamSlug={currentTeam.slug}
          />
        )}
        {activeModal === 'markAsDepositReceived' && (
          <DepositReceivedOptionsModal
            isConsoleCloudEnabled={isConsoleCloudEnabled}
            activeSendingOnboardIntegrations={activeSendingOnboardIntegrations}
            isUtilityProviderAvailable={isUtilityProviderAvailable}
            isConciergeServiceEnabled={isConciergeServiceEnabled}
            isEasyBondpayOptIn={easyBondpayOptIn}
            providerName={providerName}
            toggleModal={() => this.toggleModal('')}
            onSubmit={(actions) =>
              this.onApplicationStatusUpdateWithActions(
                helpers.ApplicationStatusCategory.DepositReceived,
                actions,
              )
            }
            platformDefaultProvider={platformDefaultProvider}
            teamSlug={currentTeam.slug}
            integrationSettings={teamSettingInfo.integrations}
          />
        )}
        {activeModal === 'markAsApplicationApproved' && (
          <ApplicationApprovedModal
            markAsApplicationApprovedOptions={markAsApplicationApprovedOptions}
            pmsIntegrationStatus={pmsIntegrationStatus}
            onChangeMarkAsApplicationApprovedOptions={
              this.onChangeMarkAsApplicationApprovedOptions
            }
            toggleModal={() => this.toggleModal('')}
            onConfirmMarkAsApplicationApprovedClicked={
              this.onConfirmMarkAsApplicationApprovedClicked
            }
            currentTeam={currentTeam}
            easyBondpayOptIn={easyBondpayOptIn}
            integrationSettings={teamSettingInfo.integrations}
          />
        )}
        {showScheduleViewingModal && (
          <Display.EditViewingModal
            title="Schedule Viewing"
            primaryActionLabel="Schedule"
            secondaryActionLabel="Cancel"
            textToBeDisplayed={scheduleViewingModalText}
            primaryButtonActionInParent={this.updateScheduleViewingModal}
            secondaryButtonAction={() => this.toggleScheduleViewingModal()}
            toggleModal={() => this.toggleScheduleViewingModal()}
            isScheduleViewing={true}
            fetchAllPropertyFutureViewings={fetchAllPropertyFutureViewings}
            teamGUID={currentTeam.guid}
          />
        )}
        {activeModal === 'markAsApplicationDeclined' && (
          <DeclineApplicationModal
            declineApplicationOptions={declineApplicationOptions}
            declineApplicationNotifyApplicant={
              declineApplicationNotifyApplicant
            }
            onChangeDeclineApplicationOptions={
              this.onChangeDeclineApplicationOptions
            }
            onChangeNotifyApplicant={this.onChangeNotifyApplicant}
            toggleModal={() => this.closeDeclineApplicationModal()}
            onConfirmDeclineApplicationClicked={
              this.onConfirmDeclineApplicationClicked
            }
            currentTeam={currentTeam}
            declineComment={declineComment}
            onChangeDeclineComment={this.onChangeDeclineComment}
            declineApplicationInProgress={declineApplicationInProgress}
          />
        )}
        {isEditRenterApplication && (
          <EditSummaryModal
            onInputChange={this.onEditSummary}
            managerEditedSummary={rentAndEmploymentDetails}
            toggleEditRenterApplication={this.toggleEditRenterApplication}
            onEditEmploymentSummary={this.onEditEmploymentSummary}
            applicantsEmployment={applicantsEmployment}
            primaryButtonAction={this.onSaveManagerEdited}
            secondaryButtonAction={this.toggleEditRenterApplication}
            editSummaryInProgress={editSummaryInProgress}
          />
        )}

        {showMoveApplicationPropertyModal && (
          <MoveApplicationPropertyModal
            toggleModal={() => this.toggleMoveApplicationPropertyModal()}
            teamGUID={currentTeam.guid}
            application={application}
            property={property}
            refresh={() => this.reloadApplicationDetails()}
          />
        )}
        {showDocumentsViewer && (
          <DocumentsViewer
            toggleDocumentsViewer={this.toggleDocumentsViewer}
            documentsViewerGUID={documentsViewerGUID}
            currentApplicantFullName={currentApplicantFullName}
            currentApplicantEmail={currentApplicantEmail}
            currentApplicantPhone={currentApplicantPhone}
            documentViewerAttachments={documentViewerAttachments}
          />
        )}
        {isShareWithOwnerModalOpen && (
          <ShareWithOwnerModal
            teamSlug={teamSlug}
            applicationGUID={application?.guidID}
            toggleShareWithOwnerModal={this.toggleShareWithOwnerModal}
          />
        )}
      </div>
    )
  }
}

const DeclinedLabel = () => <div className="alert alert-danger">Declined</div>

export default ApplicantDetails
