import * as React from 'react'

function SvgWrapTextRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 6.35h14c.55 0 1-.45 1-1s-.45-1-1-1h-14c-.55 0-1 .45-1 1s.45 1 1 1zm11.83 4H4.5c-.55 0-1 .45-1 1s.45 1 1 1h12.13c1 0 1.93.67 2.09 1.66.21 1.25-.76 2.34-1.97 2.34H14.5v-.79c0-.45-.54-.67-.85-.35L11.86 17c-.2.2-.2.51 0 .71l1.79 1.79c.32.32.85.09.85-.35v-.8h2c2.34 0 4.21-2.01 3.98-4.39-.2-2.08-2.06-3.61-4.15-3.61zm-11.83 6h4c.55 0 1 .45 1 1s-.45 1-1 1h-4c-.55 0-1-.45-1-1s.45-1 1-1z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgWrapTextRounded
