import React from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  Box,
  Button,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'
import StatusPill from 'app/components/display/status_pill/component'
import { integrationsStatuses } from 'app/constants/integrations.constants'
import { ActivationButton } from 'app/Integrations/components/ActivationButton'
import { InactiveTeamActivationButton } from 'app/Integrations/components/InactiveTeamsActivationButton'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ACCOUNT_TYPE_LITE } from 'app/utils/accounts/helpers'
import { STANDARD_DATE } from 'app/utils/datetime/helpers'
import { loadingStatesIds } from 'app/utils/loading-states'

const ContainerBox = styled(Flex)`
  box-shadow: 0px 3px 5px rgba(34, 33, 42, 0.08);
  border-radius: 2px;
`

const IntegrationLogo = (logo) => {
  return (
    <Box width="150px">
      <img width="100%" src={logo} alt="logo" />
    </Box>
  )
}

const LastActionDetails = ({ action, actedAt, actedBy, ...props }) => {
  const formattedActivationTime = moment(actedAt).format(STANDARD_DATE)
  const actorName = actedBy && `${actedBy.firstName} ${actedBy.lastName}`
  return (
    <TextSpan
      as="span"
      fontSize={theme.fontSizes.pRegular14}
      color={theme.colors.pdfGray}
    >
      {action}: {formattedActivationTime} {actorName && `by ${actorName}`}
    </TextSpan>
  )
}

const StatusBlock = ({
  currentTeam,
  brandId,
  status,
  activationInstructions,
  onActivation,
  onDeactivation,
  activateLoadingStates,
  deactivateLoadingStates,
  actedAt,
  actedBy,
  logo,
  statuses = integrationsStatuses,
}) => {
  const statusObj = Object.values(statuses).find(({ id }) => id === status)
  const isActive = status === statuses.active.id
  const isActedBefore = !!actedBy && !!actedBy
  const lastAction = isActive
    ? 'Activated'
    : isActedBefore
    ? 'Deactivated'
    : null
  const renderActivationCmp = () => {
    if (
      !currentTeam?.isActive ||
      currentTeam?.accountType === ACCOUNT_TYPE_LITE
    )
      return (
        <InactiveTeamActivationButton key={`${brandId}-${currentTeam.guid}`} />
      )
    return (
      !!onActivation && (
        <ActivationButton
          onActivate={onActivation}
          disabled={activateLoadingStates.state === loadingStatesIds.LOADING}
        />
      )
    )
  }
  return (
    <ContainerBox>
      {statusObj && (
        <Flex p={5} flex="60%" borderRight="1px solid #DFDFDF">
          <Box width="100%">
            {isActive && (
              <Flex
                justifyContent="space-between"
                alignItems="start"
                width="100%"
              >
                <Flex>
                  <TextSpan
                    as="span"
                    fontSize={theme.fontSizes.pLarge16}
                    fontWeight={theme.fontWeights[4]}
                    lineHeight={theme.lineHeights.encrption}
                    mr={3}
                  >
                    Status
                  </TextSpan>
                  <StatusPill
                    pillType={statusObj.color}
                    text={statusObj.label}
                    pillTextClass="fw500 fs16 pl15 pr15"
                  />
                </Flex>
                {onDeactivation && (
                  <Button
                    variant="linkBlackUnderlinedWithoutWeight"
                    sizeVariant="large"
                    onClick={onDeactivation}
                    disabled={
                      deactivateLoadingStates.state === loadingStatesIds.LOADING
                    }
                  >
                    Deactivate
                  </Button>
                )}
              </Flex>
            )}
            {!isActive && (
              <Box>
                <Box fontSize={theme.fontSizes.pRegular14}>
                  {activationInstructions}
                </Box>
                {renderActivationCmp()}
              </Box>
            )}
            {isActedBefore && (
              <Box mt={3}>
                <LastActionDetails
                  action={lastAction}
                  actedAt={actedAt}
                  actedBy={actedBy}
                />
              </Box>
            )}
          </Box>
        </Flex>
      )}
      {logo && (
        <Flex flex="45%" justifyContent="center" alignItems="center">
          {IntegrationLogo(logo)}
        </Flex>
      )}
    </ContainerBox>
  )
}

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
})

export const IntegrationStatusBlock = connect(mapStateToProps)(StatusBlock)
