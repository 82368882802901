import { api } from 'app/utils/api/helpers'

export const updateTenantInfo = (propertyId, tenantId, data) => {
  return api.put(`/sm/properties/${propertyId}/tenants/${tenantId}`, {
    ...data,
  })
}

export const getAllRenterPropertyNotes = (
  agencyGUID = '',
  email = '',
  phone = '',
) => {
  return api.get(
    `/sm/note/agency/${agencyGUID}/user?user_email=${encodeURIComponent(
      email,
    )}&user_phone=${encodeURIComponent(phone)}`,
  )
}

export const unshortlistRegistrant = (
  agencyGUID = '',
  registrationGUID = '',
  viewingGUID = '',
) => {
  const payload = {
    registrationGUID,
    viewingGUID,
  }
  return api.delete(
    `/sm/team/${agencyGUID}/viewingregistration/unshortlist`,
    payload,
  )
}

export const shortlistRegistrant = (
  agencyGUID = '',
  registrationGUID = '',
  viewingGUID = '',
) => {
  const payload = {
    registrationGUID,
    viewingGUID,
  }
  return api.post(
    `/sm/team/${agencyGUID}/viewingregistration/shortlist`,
    payload,
  )
}
