import { call, put, select, takeEvery } from 'redux-saga/effects'

import { offlineActionIsEnabledSelector } from 'app/core/pendingTasksSaga'
import { actionCreators as pendingTasksActions } from 'app/core/pendingTasksSlice'
import { checkIfNetworkError } from 'app/utils/api/helpers'

/**
 * @param {{actionType: string, onLiveSuccess: ((function(*, *=): Generator<SimpleEffect<"SELECT", SelectEffectDescriptor>|SimpleEffect<"PUT", PutEffectDescriptor<Action>>|SimpleEffect<"PUT", ChannelPutEffectDescriptor<unknown>>|SimpleEffect<"PUT", PutEffectDescriptor<{isOpened: *, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{registrant: *, state: *, type: string, viewingId: *}>>, void, ?>)|*), onSyncSuccess: ((function(*, *=): Generator<SimpleEffect<"SELECT", SelectEffectDescriptor>|SimpleEffect<"PUT", PutEffectDescriptor<{registrant: *, type: string}>>|SimpleEffect<"PUT", ChannelPutEffectDescriptor<unknown>>|SimpleEffect<"PUT", PutEffectDescriptor<{registrant: *, state: *, type: string, viewingId: *}>>, void, ?>)|*), actionStarter: ((function(): Generator<never, void, ?>)|*), loadingStatesUtils: null, liveActionStarter: ((function(): Generator<SimpleEffect<"PUT", PutEffectDescriptor<Action>> | SimpleEffect<"PUT", ChannelPutEffectDescriptor<unknown>>, void, ?>)|*), syncTaskNameCreator: (function({registrantPayload: *}): string), registrant: null, onLiveFailed: ((function(*): Generator<SimpleEffect<"PUT", PutEffectDescriptor<{registrant: {__localId: string, fullName: string, __viewingId: *, __pendingChange: boolean}, type: string}>>|SimpleEffect<"PUT", ChannelPutEffectDescriptor<unknown>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string, viewingId: *}>>|SimpleEffect<"PUT", PutEffectDescriptor<Action>>|SimpleEffect<"PUT", PutEffectDescriptor<{isOpened: *, type: string}>>, void, ?>)|*), asyncCallParams: (function(*): [((function(*=, *): Promise<unknown>)|*), undefined, undefined])}} config
 */
export function generateOfflineActionSagaWatcherAndWorker(
  config = {
    actionType: '',
    syncTaskNameCreator: (action) => `${action.type} failed!`,
    actionStarter: null,

    asyncCallParams: () => [],

    onSuccess: null,
    onLiveSuccess: null,
    onSyncSuccess: null,

    onFailed: null,
    onLiveFailed: null,
    onSyncFailed: null,

    onError: null,
  },
) {
  const worker = function* (action) {
    const isOfflineActionsEnabled = yield select(offlineActionIsEnabledSelector)

    const { __pendingTaskId } = action
    const isLiveAction = !__pendingTaskId
    yield* config.actionStarter(action)

    if (isLiveAction) {
      yield* config.liveActionStarter(action)
    }

    try {
      const asyncCallParams = config.asyncCallParams(action)
      const result = yield call(...asyncCallParams)
      if (isLiveAction) {
        yield* config.onLiveSuccess(action, result)
      } else {
        yield put(pendingTasksActions.taskSynced(action))
        yield* config.onSyncSuccess(action, result)
      }
    } catch (e) {
      const networkIssues = checkIfNetworkError(e)
      if (isLiveAction && networkIssues && isOfflineActionsEnabled) {
        const syncActionName = config.syncTaskNameCreator(action)
        yield put(pendingTasksActions.pushPendingTask(action, syncActionName))
        yield* config.onLiveFailed(action)
      } else if (!isLiveAction) {
        yield put(pendingTasksActions.taskFailed(action))
        if (config.onSyncFailed) {
          yield* config.onSyncFailed(action)
        }
      } else {
        yield* config.onError(action, e)
      }
    }
  }

  const watcher = function* () {
    yield takeEvery(config.actionType, worker)
  }

  return { worker, watcher }
}
