import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

function Write() {
  return (
    <div className="sm-chat-write">
      <div className="attachemnt-box">
        <button className="btn-link" data-tooltip-content="Add File">
          <i className="icon-attach"></i>
        </button>
      </div>
      <div className="input-box">
        <input type="text" placeholder="Write comment" />
      </div>
      <div className="emoji-box">
        <button className="btn-link" data-tooltip-content="Emojis">
          <i className="icon-emojis"></i>
        </button>
      </div>
      <div className="send-box">
        <button className="btn-link" data-tooltip-content="Send message">
          <span className="send-text">Send</span>
          <i className="icon-send"></i>
        </button>
      </div>
      <ReactTooltip className="tooltip" />
    </div>
  )
}

export default Write
