import React from 'react'

import { WidthFlex, WidthPercentage } from 'app/pages/teams/viewings/styles'

const ViewingsHeader = () => {
  return (
    <div className="desktop-tablet-only pdf-show-block-default mb10 border-bottom-1px pb10">
      <WidthFlex>
        <WidthPercentage amount="80px">Date</WidthPercentage>
        <WidthPercentage amount="calc(100% - 80px)">
          <WidthFlex>
            <WidthFlex flexGrow={2} className="ml20" maxWidth="100px">
              Time
            </WidthFlex>
            <WidthFlex flexGrow={5} flexGrowPrint={6}>
              Address
            </WidthFlex>
            <WidthFlex flexGrow={3} flexGrowPrint={2} className="ml25">
              Assigned
            </WidthFlex>
            <WidthFlex flexGrow={1} flexGrowPrint={1} overflowPrint="visible">
              Private
            </WidthFlex>
            <WidthFlex flexGrow={4} flexGrowPrint={3} className="ml20" />
          </WidthFlex>
        </WidthPercentage>
      </WidthFlex>
    </div>
  )
}

export default ViewingsHeader
