import React, { Component } from 'react'

import moment from 'moment'

import { IntlTelInputWrapper } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import * as Layout from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import Text from 'app/components/text/text'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import { intTelTop10List } from 'app/shared_components/helpers'
import * as helpers from 'app/sm/helpers'
import { EmploymentTypeCollection } from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

class EmploymentEditModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clicked: false,
      modalType: 'Employment',
    }
  }

  render() {
    const {
      closeReasonsModal,
      details,
      update,
      validate,
      validateInternationalNumber,
      onSaveContactDetails,
      errors,
      onFormUpdate,
      isEditModal,
      isEmployerEditModal,
    } = this.props

    const { clicked } = this.state

    const {
      contactName,
      phoneNumber,
      email,
      jobTitle,
      startDate,
      endDate,
      weeklyNetIncome,
      grossAnnualIncome,
      employmentType,
      stillEmployed,
    } = details

    const disabled =
      !(
        helpers.isNoErrorExist(errors) &&
        contactName &&
        (phoneNumber || email)
      ) || clicked

    const formattedStartDate =
      errors.startDate !== dateTimeHelpers.INVALID_DATE_STRING
        ? startDate && moment(startDate).format(dateTimeHelpers.DATE_WITH_SLASH)
        : startDate
    const formattedEndDate =
      errors.endDate !== dateTimeHelpers.INVALID_DATE_STRING
        ? endDate && moment(endDate).format(dateTimeHelpers.DATE_WITH_SLASH)
        : endDate

    return (
      <div className="modal-fullscreen modal-layout-flex-container">
        <ModalContentWrapper takeMinHeight>
          <div className="modal-close top5">
            <i
              className="fa fa-times font-size-24"
              onClick={() => closeReasonsModal()}
            />
          </div>
          <div className="modal-header text-align-left">
            <h4 className="display-flex align-items-center justify-content-space-between">
              <span>
                Edit {isEditModal ? `Employment` : `Employer`} Details
              </span>
            </h4>
          </div>
          {isEditModal && (
            <div className="modal-body p15 pb80">
              <div className="row text-align-left">
                <div className="col-xs-6">
                  <div
                    className={`input-box ${
                      errors.contactName && disabled ? 'error' : ''
                    }`}
                  >
                    <input
                      type="text"
                      required
                      id="contactName"
                      value={contactName}
                      onChange={(e) => update('contactName', e.target.value)}
                      onBlur={validate('contactName', true)}
                    />
                    <p>Full Name</p>
                  </div>
                </div>
                <div className="col-xs-6">
                  <div
                    className={`input-box ${
                      errors.phoneNumber && disabled ? 'error' : ''
                    }`}
                  >
                    <IntlTelInputWrapper
                      css={['intl-tel-input', '']}
                      utilsScript={'libphonenumber.js'}
                      defaultCountry={'au'}
                      preferredCountries={intTelTop10List}
                      onPhoneNumberChange={validateInternationalNumber(false)}
                      onPhoneNumberBlur={validateInternationalNumber(true)}
                      value={phoneNumber}
                      placeholder={'e.g. +61 412 345 678'}
                    />
                    <p className="text-align-left">Phone Number</p>
                  </div>
                </div>
              </div>
              <div
                className={
                  errors.contactName && disabled
                    ? 'alert alert-danger'
                    : 'hide-alert'
                }
              >
                <div>{errors.contactName}</div>
              </div>
              <div
                className={
                  errors.phoneNumber && disabled
                    ? 'alert alert-danger'
                    : 'hide-alert'
                }
              >
                <div>{errors.phoneNumber}</div>
              </div>
              <div className="row text-align-left">
                <div className="col-xs-6">
                  <div
                    className={`input-box ${
                      errors.email && disabled ? 'error' : ''
                    }`}
                  >
                    <input
                      type="email"
                      required
                      id="email"
                      value={email}
                      onChange={(e) => update('email', e.target.value)}
                      onBlur={validate('email', true)}
                    />
                    <p className="text-align-left">Email</p>
                  </div>
                </div>
              </div>
              <div
                className={
                  errors.email && disabled ? 'alert alert-danger' : 'hide-alert'
                }
              >
                <div>{errors.email}</div>
              </div>
            </div>
          )}
          {isEmployerEditModal && (
            <div className="modal-body p15 pb80">
              <div>
                <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                  <div className="col-md-6 p0 width100">
                    <Form.InputTextRequired
                      value={jobTitle}
                      error={errors.jobTitle}
                      label="Job Title"
                      onChange={onFormUpdate('jobTitle')}
                      labelClass="top20"
                      inputClass="width100"
                      componentClass="margin-profile-item-left"
                    />
                  </div>
                  <div className="col-md-6 width100">
                    <Form.Dropdown
                      label="Job Type"
                      value={employmentType}
                      error={errors.employmentType}
                      options={EmploymentTypeCollection}
                      onChange={onFormUpdate('employmentType')}
                      inputClass="width100 pt0"
                      componentClass="margin-profile-item-right"
                    />
                  </div>
                </Form.ProfilePagesRowLayout>
                {helpers.isSelfEmployed(details.employmentType) && (
                  <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                    <div className="col-md-6 p0 width100">
                      <Form.InputTextRequired
                        value={details.employerTradingName}
                        error={errors.employerTradingName}
                        label="Trading Name"
                        placeholder="eg. Jay's Plumbing"
                        onChange={onFormUpdate('employerTradingName')}
                        labelClass="top20"
                        inputClass="width100"
                        componentClass="margin-profile-item-left"
                        isOptional
                      />
                    </div>
                    <div className="col-md-6 p0 width100">
                      <Form.InputABN
                        value={details.employerABN}
                        error={errors.employerABN}
                        label="ABN"
                        placeholder="e.g. 51 824 753 556"
                        onChange={onFormUpdate('employerABN')}
                        labelClass="top20"
                        inputClass="width100"
                        componentClass="margin-profile-item-right"
                        isOptional
                      />
                    </div>
                  </Form.ProfilePagesRowLayout>
                )}
                <Form.CheckBoxGeneral
                  label="Currently employed here"
                  onChange={onFormUpdate('stillEmployed')}
                  checked={stillEmployed}
                  componentClassName="mb20"
                />
                <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                  <div className="col-md-6 p0 width100">
                    <Form.InputPastDate
                      label="Start Date"
                      value={formattedStartDate}
                      error={errors.startDate}
                      onChange={onFormUpdate('startDate')}
                      containerClassName="width100"
                      componentClass="margin-profile-item-left"
                      viewMode="months"
                      customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                      readOnly={false}
                    />
                  </div>
                  <div className="col-md-6 p0 width100">
                    {!stillEmployed && (
                      <Form.InputPastDate
                        label="End Date"
                        value={formattedEndDate}
                        error={errors.endDate}
                        onChange={onFormUpdate('endDate')}
                        containerClassName="width100"
                        componentClass="margin-profile-item-right"
                        viewMode="months"
                        customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                        readOnly={false}
                      />
                    )}
                  </div>
                </Form.ProfilePagesRowLayout>
                <Layout.ContainerHeader
                  text="Income"
                  isEditable={false}
                  componentClass="mt10"
                />
                <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                  <div className="col-md-6 p0 width100">
                    <Form.InputTextRequired
                      value={weeklyNetIncome}
                      error={errors.weeklyNetIncome}
                      label={Text.placeholder.weeklyIncomePlaceholder}
                      onChange={onFormUpdate('weeklyNetIncome')}
                      labelClass="top20 small-label"
                      inputClass="width100"
                      componentClass="margin-profile-item-left"
                    />
                  </div>
                  <div className="col-md-6 p0 width100">
                    <Form.InputTextRequired
                      value={grossAnnualIncome}
                      error={errors.grossAnnualIncome}
                      label={Text.placeholder.annualIncomePlaceholder}
                      onChange={onFormUpdate('grossAnnualIncome')}
                      labelClass="top20 small-label"
                      inputClass="width100"
                      componentClass="margin-profile-item-right"
                    />
                  </div>
                </Form.ProfilePagesRowLayout>
              </div>
            </div>
          )}
          <div className=" border-none width100 display-flex modal-bottom-btn-container-walk-in-renter">
            <div className="col-sm-6 div-order-1 width100">
              <Display.GenericButton
                text="Close"
                onClick={() => closeReasonsModal()}
                buttonType="secondary"
              />
            </div>
            <div className="col-sm-6 div-order-2 width100">
              <Display.GenericButton
                onClick={() => onSaveContactDetails()}
                text="Update"
                disabled={disabled}
              />
            </div>
          </div>
        </ModalContentWrapper>
      </div>
    )
  }
}

export default EmploymentEditModal
