import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

const Payment = ({ card, request, currentLease }) => {
  let stepNumber = 5
  if (currentLease && currentLease.isMultiTenantLease) {
    stepNumber = 6
  }

  let requestID = ''
  if (request) {
    requestID = request.guidID
  }

  let paymentHtml = null,
    id = ''
  if (card) {
    paymentHtml = (
      <Link
        className={
          request ? 'bc-hub-item-link completed' : 'bc-hub-item-link disabled'
        }
        to={`/bc/pay?requestid=${requestID}`}
      >
        <header>
          <i className="icon-bank" />
          <span>{stepNumber}. Payment Details</span>
        </header>
      </Link>
    )
  }
  return (
    <div>
      {
        !paymentHtml ? (
          <Link
            className="bc-hub-item-link"
            to={`/bc/pay?requestid=${requestID}`}
          >
            <header>
              <i className="icon-bank" />
              <span>{stepNumber}. Payment Details</span>
            </header>
          </Link>
        ) : (
          paymentHtml
        )
        // <div className={request ? 'bc-hub-item-link completed' : 'bc-hub-item-link disabled'}>
        //   <header>
        //     <i className='icon-bank' />
        //     <span>{stepNumber}. Payment Details</span>
        //   </header>
        //   <content>
        //     {paymentHtml}
        //   </content>
        // </div>
      }
    </div>
  )
}

export default Payment
