import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const _saveCard = (card) => {
  const url = `${apiBaseUrl}profile/cards`

  return fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
    body: JSON.stringify(card),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getIdentityDocs = () => {
  const url = `${apiBaseUrl}sm/backgroundcheck/identitydocs`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve(res.json())
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (identityDocList) => ({ ok: true, identityDocList }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getBackgroundCheck = () => {
  const url = `${apiBaseUrl}sm/backgroundcheck`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (backgroundCheck) => ({ ok: true, backgroundCheck }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const _attachToProfile = () => {
  const url = `${apiBaseUrl}sm/backgroundcheck/attachtoprofile`

  return fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const _discardResult = () => {
  const url = `${apiBaseUrl}sm/backgroundcheck`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (resp) => ({ ok: true, responseText: '' }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const addIdentityDoc = (data, isDraft) => {
  const url = `${apiBaseUrl}sm/backgroundcheck/identitydocs?draft=${isDraft}`

  return fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(data),
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve(res.json()) // We get nothing for now, when a question is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (identityDoc) => ({ ok: true, identityDoc }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deleteIdentityDoc = (id) => {
  const url = `${apiBaseUrl}sm/backgroundcheck/identitydocs/${id}`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const alterIdentityDoc = (data, id) => {
  const url = `${apiBaseUrl}sm/backgroundcheck/identitydocs/${id}`

  return fetch(url, {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(data),
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve() // We get nothing for now, when a question is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const payWithNewAccount = (data, applicationGUID = '') => {
  const payload = {
    stripeToken: data.stripeToken,
    saveCreditCard: data.saveCard,
  }

  const url = applicationGUID
    ? `${apiBaseUrl}sm/backgroundcheck?applicationId=${applicationGUID}`
    : `${apiBaseUrl}sm/backgroundcheck`

  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getPaymentAmount = () => {
  // /sm/backgroundcheck/identitydocs
  const url = `${apiBaseUrl}sm/backgroundcheck/amount`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (paymentAmount) => ({ ok: true, paymentAmount }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getCards = () => {
  const url = `${apiBaseUrl}profile/cards`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (cards) => ({ ok: true, cards }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const payWithExistingAccount = (applicationID = '') => {
  const url = applicationID
    ? `${apiBaseUrl}sm/backgroundcheck?applicationId=${applicationID}`
    : `${apiBaseUrl}sm/backgroundcheck`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

//attachments
export const getIdentityDocuments = (id) => {
  const url = `${apiBaseUrl}sm/backgroundcheck/identitydocs/${id}/attachments`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
            statusText: res.statusText,
          }),
    )
    .then(
      (attachments) => ({ ok: true, attachments }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const uploadIdentityDocuments = (
  formData,
  id = '',
  isIdentityDoc = true,
  docType = '',
) => {
  const url = `${apiBaseUrl}sm/backgroundcheck/identitydocs/attachments?isIdentityDoc=${isIdentityDoc}&documentType=${docType}&identityDocId=${id}`
  const options = {
    method: 'POST',
    body: formData,
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
            statusText: res.statusText,
          }),
    )
    .then(
      (_) => ({ ok: true, _ }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const removeIdentityDocuments = (attachmentid) => {
  const url = `${apiBaseUrl}sm/backgroundcheck/identitydocs/attachments/${attachmentid}`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
            statusText: res.statusText,
          }),
    )
    .then(
      (_) => ({ ok: true, _ }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const getIdentityDoc = (id) => {
  const url = `${apiBaseUrl}sm/backgroundcheck/identitydocs/${id}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (identityDoc) => ({ ok: true, identityDoc }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
