import { connect } from 'react-redux'

import {
  createBankAccount,
  fetchBankAccount,
  fetchRequestSummary,
  updateBankAccount,
  verifyReferralCode,
} from 'app/bond_cover/bond_cover_actions'
import BankAccountFormContainer from 'app/bond_cover/renters/identity/bank_account_container'

const mapStateToProps = ({ bondCover }) => ({
  request: bondCover.request,
  currentLease: bondCover.currentLease,
  bankAccount: bondCover.bankAccount,
  quote: bondCover.quote,
  apiError: bondCover.error,
})

const mapDispatchToProps = (dispatch) => ({
  createBankAccount: (requestID, data, isPrimary) =>
    createBankAccount(requestID, data, isPrimary),
  fetchBankAccount: (requestID, guid) => fetchBankAccount(requestID, guid),
  updateBankAccount: (requestID, data, guid, isPrimary) =>
    updateBankAccount(requestID, data, guid, isPrimary),
  fetchRequestSummary: (requestID) => fetchRequestSummary(requestID),
  verifyReferralCode,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BankAccountFormContainer)
