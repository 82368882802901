import { connect } from 'react-redux'

import AdvertisementForm from 'app/sm/advertise_flow/components/advertisement_form'
import {
  acceptTermsConditionsChange,
  cardSelect,
  createAdvertisement,
  fetchActiveAd,
  fetchAd,
  fetchAdvertisement,
  fetchCards,
  fetchPortals,
  payWithExistingCard,
  ping,
  registerPayment,
  resetAdvertisement,
  updateAdvertisement,
} from 'app/sm/advertisements/advertisement_actions'

const mapStateToProps = ({ Advertisements }) => ({
  portals: Advertisements.portals,
  listingFee: Advertisements.listingFee,
  advertisement: Advertisements.advertisement,
  cards: Advertisements.cards,
  acceptTermsAndConditions: Advertisements.acceptTermsAndConditions,
  selectedCreditCard: Advertisements.selectedCreditCard,
  ad: Advertisements.ad,
  error: Advertisements.error,
  step: 'payment',
})

const mapDispatchToProps = (dispatch) => ({
  resetAdvertisement: () => dispatch(resetAdvertisement()),
  fetchPortals: () => fetchPortals(),
  fetchAdvertisement: (id) => fetchAdvertisement(id),
  fetchAd: (id) => fetchAd(id),
  createAdvertisement: () => (id, ad) => createAdvertisement(id, ad),
  updateAdvertisement: (path) => (id, ad) => updateAdvertisement(id, ad, path),
  registerPayment: (id, token) => registerPayment(id, token),
  fetchCards: () => dispatch(fetchCards()),
  cardSelect: (selected) => dispatch(cardSelect(selected)),
  acceptTermsConditionsChange: () => dispatch(acceptTermsConditionsChange()),
  payWithExistingCard: () => dispatch(payWithExistingCard()),
  fetchActiveAd: (offerId) => dispatch(fetchActiveAd(offerId)),
  ping: () => dispatch(ping()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementForm)
