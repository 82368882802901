import ErrorMessages from 'app/constants/error_messages'
import {
  RECEIVE_RESPONSE_TEXT,
  receiveResponseText,
} from 'app/sm/withdraw_confirmation/withdraw_confirmation_actions'

const WithdrawConfirmationMiddleware = (store) => (next) => (action) => {
  let { type, responseText } = action
  let errorCode = parseInt(responseText)
  let errorMessage = ErrorMessages[errorCode]

  switch (type) {
    case RECEIVE_RESPONSE_TEXT:
      if (errorMessage) {
        store.dispatch(receiveResponseText(errorMessage))
        return next(action)
      }
      if (isNaN(responseText)) {
        store.dispatch(receiveResponseText(responseText))
        return next(action)
      }

      store.dispatch(
        receiveResponseText(`There was a problem. Error code: ${errorCode}`),
      )
      return next(action)

    default:
      return next(action)
  }
}

export default WithdrawConfirmationMiddleware
