import React, { useEffect, useState } from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Box } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import { getPMSBrand } from 'app/constants/pms.constants'
import * as Form from 'app/shared_components/form_component'
import { SendApplicationToPMS } from 'app/utils/pms/sendApplicationToPMS'
import { RAY_WHITE_CONCIERGE_SEND_IF_NOT_SENT_LABEL } from 'app/utils/raywhiteconcierge/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import { DefaultUtilityProviderComponent } from 'app/utils/utilityprovider/helpers'

function DepositReceivedOptionsModal({
  isConsoleCloudEnabled,
  isUtilityProviderAvailable,
  isConciergeServiceEnabled,
  activeSendingOnboardIntegrations,
  isEasyBondpayOptIn = false,
  providerName,
  toggleModal,
  onSubmit,
  platformDefaultProvider = false,
  teamSlug,
  integrationSettings,
}) {
  const [declineUnsuccessfulApplications, setDeclineUnsuccessfulApplications] =
    useState(true)
  const [cancelFutureViewings, setCancelFutureViewings] = useState(true)
  const [sendUtilityReferrals, setSendUtilityReferrals] = useState(true)

  const [pmsConfig, setPMSConfig] = useState({})
  const [sendApplicationToConsoleCloud, setSendApplicationToConsoleCloud] =
    useState(isConsoleCloudEnabled)
  const [archiveProperty, setArchiveProperty] = useState(false)
  const [pmsBrand, setPMSBrand] = useState(null)

  const utilityProviderCheckboxLabel = platformDefaultProvider ? (
    <DefaultUtilityProviderComponent />
  ) : (
    <Box>{`Send connection referral to ${providerName} (if opted in)`}</Box>
  )

  useEffect(() => {
    activeSendingOnboardIntegrations &&
      activeSendingOnboardIntegrations.map(({ pms }) => {
        const brand = getPMSBrand(pms)
        setPMSBrand(brand)
        brand && setPMSConfig({ [brand.applicationConfig]: true })
      })
  }, [activeSendingOnboardIntegrations])

  const renderPMSConfig = ({ pms }) => (
    <SendApplicationToPMS
      pms={pms}
      teamSlug={teamSlug}
      integrationSettings={integrationSettings}
      pmsConfig={pmsConfig}
      onChange={(ev) =>
        setPMSConfig({ [pmsBrand.applicationConfig]: ev.target.checked })
      }
      sendOption="applicationConfig"
    />
  )

  const submit = () => {
    onSubmit({
      declineUnsuccessfulApplications,
      cancelFutureViewings,
      archiveProperty,
      ...pmsConfig,
      sendUtilityReferrals: platformDefaultProvider
        ? true
        : sendUtilityReferrals,
      ...(isConsoleCloudEnabled && { sendApplicationToConsoleCloud }),
    })
  }

  return (
    <Display.Modal
      toggleModal={() => toggleModal('')}
      title="Mark Application as Deposit Received"
      hideButtonSection
      modalSubheadingClass="fs18"
    >
      <div className="fs16">
        <p className="mb10 fw500">Marking as Deposit Received will:</p>
        <ul>
          <li>Label the application as Deposit Received</li>
          <li>
            <b>Not</b> send an offer email
          </li>
          {isUtilityProviderAvailable && (
            <li>
              SMS the primary applicant to expect a call from {providerName} (if
              opted in)
            </li>
          )}
          {isConciergeServiceEnabled && (
            <li>{RAY_WHITE_CONCIERGE_SEND_IF_NOT_SENT_LABEL}</li>
          )}
          {isEasyBondpayOptIn && <li>Send easyBondpay lead</li>}
        </ul>
        <div className="mt30 mb30">
          {isConsoleCloudEnabled && (
            <Form.Checkbox
              label="Send application details to Console Cloud"
              id="sendApplicationToConsoleCloud"
              onChange={(ev) =>
                setSendApplicationToConsoleCloud(ev.target.checked)
              }
              checked={sendApplicationToConsoleCloud}
              checkboxLabelSpanClassName="fs16"
            />
          )}
          {activeSendingOnboardIntegrations &&
            activeSendingOnboardIntegrations.map(renderPMSConfig)}
          {isUtilityProviderAvailable && (
            <Form.Checkbox
              label={utilityProviderCheckboxLabel}
              id="sendUtilityConnectionReferral"
              onChange={(ev) =>
                platformDefaultProvider
                  ? true
                  : setSendUtilityReferrals(ev.target.checked)
              }
              checked={sendUtilityReferrals}
              checkboxLabelSpanClassName="fs16"
              showDisabledGrey={platformDefaultProvider}
              enableEdit={!platformDefaultProvider}
            />
          )}
          <Form.Checkbox
            label="Cancel future viewings, notify enquirers/registered and tenants"
            id="cancelFutureViewings"
            onChange={(ev) => setCancelFutureViewings(ev.target.checked)}
            checked={cancelFutureViewings}
            checkboxLabelSpanClassName="fs16 height-auto"
          />
          <div>
            <ReactTooltip id="declineTimeOutDesciption">
              {textHelpers.DECLINE_OTHER_HOUSEHOLD_TOOLTIP_CONTENT}
            </ReactTooltip>
          </div>
          <Form.Checkbox
            label="Decline other household groups for this property (except Approved Applicants)"
            id="declineUnsuccessfulApplications"
            onChange={(ev) =>
              setDeclineUnsuccessfulApplications(ev.target.checked)
            }
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="declineTimeOutDesciption"
                />
              </div>
            }
            checked={declineUnsuccessfulApplications}
            checkboxLabelSpanClassName="fs16 height-auto"
          />
          <Form.Checkbox
            label="Archive now"
            id="archiveProperty"
            onChange={(ev) => setArchiveProperty(ev.target.checked)}
            checked={archiveProperty}
            checkboxLabelSpanClassName="fs16 height-auto"
          />
        </div>
      </div>
      <Display.ModalButtonGroup
        primaryLabel="Mark as Deposit Received"
        secondaryLabel="Cancel"
        secondaryButtonType="tertiary"
        primaryAction={submit}
        secondaryAction={() => toggleModal('')}
      />
    </Display.Modal>
  )
}

export default DepositReceivedOptionsModal
