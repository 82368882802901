import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const createEmployer = (payload) => {
  const url = `${apiBaseUrl}sm/employers`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getEmployers = () => {
  const url = `${apiBaseUrl}sm/employers`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (employers) => ({ ok: true, employers }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getEmployer = (id) => {
  const url = `${apiBaseUrl}sm/employers/${id}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (employer) => ({ ok: true, employer }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const alterEmployer = (payload, id) => {
  const url = `${apiBaseUrl}sm/employers/${id}`

  return fetch(url, {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve() // We get nothing for now, when a employer is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deleteEmployer = (id) => {
  const url = `${apiBaseUrl}sm/employers/${id}`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getEmployee = (
  confirmationRequestID,
  confirmationRequestSlug,
  isFromManager = false,
) => {
  let url = ''
  if (confirmationRequestID) {
    url = `${apiBaseUrl}sm/employers/${confirmationRequestID}/verify`
  } else if (confirmationRequestSlug) {
    url = `${apiBaseUrl}sm/employers/slug/${confirmationRequestSlug}/verify`
  }

  if (isFromManager) {
    url = url + `?manual=${isFromManager}`
  }

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (employee) => ({ ok: true, employee }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const verify = (payload, id, slug) => {
  let url = ''
  if (id) {
    url = `${apiBaseUrl}sm/employers/${id}/verify`
  } else if (slug) {
    url = `${apiBaseUrl}sm/employers/slug/${slug}/verify`
  }

  return fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const disprove = (payload, id, slug) => {
  let url = ''
  if (id) {
    url = `${apiBaseUrl}sm/employers/${id}/disprove`
  } else if (slug) {
    url = `${apiBaseUrl}sm/employers/slug/${slug}/disprove`
  }
  return fetch(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getEmployeeDetail = (id, slug) => {
  let url = ''
  if (id) {
    url = `${apiBaseUrl}sm/employers/${id}/detail`
  } else if (slug) {
    url = `${apiBaseUrl}sm/employers/slug/${slug}/detail`
  }

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (employee) => ({ ok: true, employee }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
