import { Box, Modal } from 'app/components/design-system-components'

interface CollectionPolicyModalProps {
  toggleCollectionPolicyModal: () => void
}

function CollectionPolicyModal({
  toggleCollectionPolicyModal,
}: CollectionPolicyModalProps) {
  const modalBody = (
    <Box>
      <Box mt={4}>
        You will be asked some demographic questions. This information will be
        used for research purposes only.
      </Box>

      <Box mt={4}>
        Data collected from applications to these homes will be used to evaluate
        and improve the Affordable Housing Rental Scheme.
      </Box>

      <Box mt={4}>
        Data will be securely stored (electronically) for seven years. After
        seven years unit record data will be securely destroyed in accordance
        with the Public Records Act 1973 (Vic) and the Privacy and Data
        Protection Act 2014 (Vic) Victorian Protective Data Security Framework.
        The Department of Families, Fairness and Housing Records Management Unit
        and Cyber Security Unit will supervise the data destruction.
      </Box>

      <Box mt={4}>
        Homes Victoria will not disclose any information for a purpose other
        than evaluating the Affordable Housing Rental Scheme, unless your
        express prior consent is sought or are required to do so by an
        Australian law.
      </Box>

      <Box mt={4}>
        Any information published from program evaluation will be de-identified.
      </Box>
    </Box>
  )
  return (
    <Modal
      modalHeading="Homes Victoria Data Collection Policy"
      modalBody={modalBody}
      showPrimaryButton={false}
      toggleModal={toggleCollectionPolicyModal}
      secondaryAction={toggleCollectionPolicyModal}
      secondaryLabel="Close"
      modalFooter={undefined}
      primaryLabel={undefined}
      primaryAction={undefined}
    />
  )
}

export default CollectionPolicyModal
