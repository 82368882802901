export const identityCapEnabledNumberOptions = [
  { value: 0, label: '100 points' },
  { value: 1, label: 'One Primary ID' },
]

export const HUNDRED_POINTS_OPTION_VALUE_STRING = '0'
export const ONE_PRIMARY_ID_OPTION_VALUE_STRING = '1'

export const identityCapEnabledNumberOptionsMap = {
  0: '100 points',
  1: 'One Primary ID',
}

export const NUMBER_OF_IDS_REQUIRED = 1

export const payslipsRequiredNumberOptions = [
  { value: 0, label: 'Three' },
  { value: 1, label: 'Two' },
  { value: 2, label: 'One' },
]

export const payslipReqOptions = {
  0: 3,
  1: 2,
  2: 1,
}

export const requireBankStatementOptions = [
  { value: 0, label: 'Bank Statement' },
  { value: 1, label: 'Bank Statement (Transactions)' },
  { value: 2, label: 'Bank Statement (Balance)' },
]

export const requireBankStatementOptionsMap = {
  0: '',
  1: ' (with Transactions)',
  2: ' (with Balance)',
}

export const requireBankStatementTypeOptions = [
  { value: 0, label: 'Two Years' },
  { value: 1, label: 'Most recent two' },
]

export const requireBankStatementTypeOptionsMap = {
  0: 'Two Years',
  1: 'Most recent two',
}
