import { LogFetchError } from 'app/shared_components/helpers'
import * as sharedHelpers from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getRentalReferencesForAgency = (
  agencyGUID,
  limit = '',
  offset = '',
  filterValues = {},
) => {
  const {
    managerValue = '',
    statusValue = '',
    dateValue = '',
    searchValue = '',
  } = filterValues
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/rentalreferences?q=${searchValue}&limit=${limit}&offset=${offset}&sort_dir=${dateValue}&assignee=${managerValue}&status=${statusValue}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

// Legacy! use one from src/app/services/http/rentalReferences/index.jsx instead
export const getCountForRentalReferencesForAgency = (
  agencyGUID,
  filterValues = {},
) => {
  const {
    managerValue = '',
    statusValue = '',
    dateValue = '',
    searchValue = '',
    retention = 0,
  } = filterValues
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/rentalreferences/count?q=${searchValue}&sort_dir=${dateValue}&assignee=${managerValue}&status=${statusValue}&retention=${retention}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}
