import { Box, Button } from 'app/components/design-system-components'

export const SelectAllAlerts = ({
  isActive = false,
  allSelected = false,
  counts: { displayed = 0, total = 0 },
  onSelectAll,
  onClearSelection,
}) => {
  if (!isActive) return null

  if (!allSelected)
    return (
      <Box>
        All {displayed} messages on this page are selected.
        <Button variant="flatBlue" onClick={onSelectAll}>
          Select all {total} matching messages
        </Button>
      </Box>
    )

  return (
    <Box>
      All {total} matching messages are selected
      <Button variant="flatBlue" onClick={onClearSelection}>
        Clear selection
      </Button>
    </Box>
  )
}
