import {
  CLEAR_ERROR,
  RECEIVE_EMERGENCY_CONTACT,
  RECEIVE_EMERGENCY_CONTACTS,
  RECEIVE_ERROR,
} from 'app/sm/emergency_contact/emergency_contact_actions'

const defaultState = {
  emergencyContact: {},
  emergencyContacts: [],
  error: '',
}

const EmergencyContact = (state = defaultState, action) => {
  let { type, emergencyContact, emergencyContacts, error } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case RECEIVE_EMERGENCY_CONTACTS:
      return Object.assign(newState, { emergencyContacts })
    case RECEIVE_EMERGENCY_CONTACT:
      return Object.assign(newState, { emergencyContact })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    default:
      return newState
  }
}

export default EmergencyContact
