import { api } from 'app/utils/api/helpers'

export const getIntegrationStatus = (teamGUID, brand) => {
  return api.get(`/integrations/${brand}/agency/${teamGUID}/status`)
}

export const activateIntegration = (teamGUID, brand, payload) => {
  return api.post(`/integrations/${brand}/agency/${teamGUID}/activate`, payload)
}

export const deactivateIntegration = (teamGUID, brand) => {
  return api.post(`/integrations/${brand}/agency/${teamGUID}/deactivate`)
}

export const getApplicantTicaCheck = (teamGUID, applicantId) => {
  return api.get(
    `/integrations/tica/agency/${teamGUID}/applicants/${applicantId}`,
  )
}

export const runTicaCheck = (teamGUID, applicantId) => {
  return api.post(
    `/integrations/tica/agency/${teamGUID}/applicants/${applicantId}`,
  )
}
