import React from 'react'

import { filterViewings } from 'app/sm/helpers'
import ViewingItemContainer from 'app/sm/property_details/viewingItem'

class Viewings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      btnGuid: '',
      btnText: 'Register',
      viewings: [],
      loginUserActionsCounter: 0,
      scrolledToViewing: false,
    }
  }

  componentDidMount() {
    const { viewings = [] } = this.props
    this.setState({ viewings })
  }

  componentDidUpdate(prevProp) {
    const { viewings = [] } = this.props
    if (JSON.stringify(viewings) !== JSON.stringify(prevProp.viewings)) {
      this.setState({
        viewings,
      })
    }
  }

  onActionBtnClicked = (viewing, isBtnRegisteredText) => {
    // const { isUserLoggedIn } = this.props
    if (viewing.registered) {
      isBtnRegisteredText = 'Register'
      return
      /* temporarily removed for COVID-19  Precautions (ch33975) */
      // if (!isUserLoggedIn) {
      // }
      // this.props.unregisterViewing(viewing.guidID)
    } else {
      isBtnRegisteredText = 'Registered'
      this.props.registerViewing(viewing.guidID)
    }
  }

  changeText = (btnGuid, btnText) => {
    this.setState({ btnGuid, btnText })
  }

  render() {
    const { viewings, btnGuid, btnText } = this.state
    const {
      rsvpSpinnerGuidArray,
      teamSlug,
      isUserLoggedIn,
      nonLoggedInRegisteredViewings = [],
    } = this.props
    viewings.forEach((viewing) => {
      nonLoggedInRegisteredViewings.forEach((v) => {
        if (viewing.guidID === v) {
          viewing.registered = true
        }
      })
    })
    return (
      <div id="property-viewings-container">
        <h4 className="mb20">Upcoming home viewings</h4>
        {filterViewings(viewings, false).map((viewing, key) => {
          let isBtnRegisteredText = viewing.registered
            ? 'Registered'
            : 'Register'
          const btnStyles =
            btnText === 'Unregister' &&
            btnGuid === viewing.guidID &&
            viewing.registered
              ? 'hollow-button-red'
              : 'hollow-button-green'
          const showSpinner = (rsvpSpinnerGuidArray || []).some(
            (a) => a === viewing.guidID,
          )
          const spinner = showSpinner ? (
            <i className="fa fa-spinner fa-pulse spin" />
          ) : (
            ''
          )
          return (
            <ViewingItemContainer
              key={key}
              viewing={viewing}
              isBtnRegisteredText={isBtnRegisteredText}
              showSpinner={showSpinner}
              spinner={spinner}
              btnStyles={btnStyles}
              teamSlug={teamSlug}
              btnGuid={btnGuid}
              btnText={btnText}
              onActionBtnClicked={this.onActionBtnClicked}
              changeText={this.changeText}
              isUserLoggedIn={isUserLoggedIn}
            />
          )
        })}
      </div>
    )
  }
}

export default Viewings
