import React from 'react'

import qs from 'qs'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { ErrorMessage, LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'
import Spinner from 'app/sm/common/spinner'
import { SessionStorageUtils } from 'app/storage_utils'

const STATUS_VERIFIED = 'verified'
const STATUS_BUSY = 'busy'
const STATUS_GUEST = 'guest'
const STATUS_UNAUTHORIZED = 'unauthorized'

const Screen = ({ children }) => (
  <div className="sm-applications-detailed">
    <h2 className="mb30 fw600 pl20">Application</h2>
    {children}
  </div>
)

class ApplyAnywhereEmailConfirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: '',
      message: '',
    }
  }

  componentDidMount() {
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const redirectURL = queries.redirect_url
    SessionStorageUtils.setItem('forwardUrl', redirectURL)

    if (!queries.token) {
      this.setState({ status: '' })
      return
    }
    this.setState({ status: STATUS_BUSY })
    this.props
      .verifyApplyAnywhereEmailLink(queries.token)
      .then(() => {
        this.setState({ status: STATUS_VERIFIED })
      })
      .catch((resp) => {
        this.setState({
          status: STATUS_UNAUTHORIZED,
          message: translateErrorCodeToMessage(resp),
        })
      })
  }

  render() {
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const redirectURL = `${queries.redirect_url}?token=${queries.token}`
    return (
      <Screen>
        {this.state.status === STATUS_BUSY && 'Verifying...'}
        {this.state.status === STATUS_BUSY && <Spinner />}
        {this.state.status === STATUS_VERIFIED &&
          (redirectURL ? <Redirect to={redirectURL} /> : 'Congratulations!')}
        {this.state.status === STATUS_UNAUTHORIZED && (
          <ErrorMessage error={this.state.message} />
        )}
      </Screen>
    )
  }
}

const verifyApplyAnywhereEmailLink = (token) => (dispatch) => {
  const url = `${apiBaseUrl}confirmemail?token=${token}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch(LogFetchError)
    .then((res) => {
      if (res.ok) {
        return Promise.resolve()
      } else {
        return res.json().then((data) => {
          return Promise.reject(data)
        })
      }
    })
}

const mapDispatchToProps = (dispatch) => ({
  verifyApplyAnywhereEmailLink: (token) =>
    dispatch(verifyApplyAnywhereEmailLink(token)),
})

export default connect(null, mapDispatchToProps)(ApplyAnywhereEmailConfirmation)
