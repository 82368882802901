import React from 'react'

import PropTypes from 'prop-types'

const ErrorMessageContainer = ({ error }) => (
  <div className={error ? 'alert alert-danger' : 'hidden'}>{error || ''}</div>
)

ErrorMessageContainer.propTypes = {
  error: PropTypes.string | React.ReactNode,
}

export default ErrorMessageContainer
