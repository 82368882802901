import { connect } from 'react-redux'

import { changeTeam } from 'app/session/session_actions'
import Share from 'app/sm/ppp/property_components/share/share'
import {
  cancelModal,
  fetchProfile,
  fetchProperty,
  ping,
  shouldUpdateAd,
  steps,
  update,
  updateField,
} from 'app/sm/profile/profile_actions'

const mapStateToProps = ({ Profile, session }) => ({
  error: Profile.error,
  profile: Profile.profile,
  spinner: Profile.spinner,
  steps,
  modal: Profile.modal,
  property: Profile.property,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  ping: () => dispatch(ping()),
  update: (step, data) => dispatch(update(step, data)),
  fetchProfile: () => dispatch(fetchProfile()),
  updateField: (key, val) => dispatch(updateField(key, val)),
  fetchProperty: (slug) => dispatch(fetchProperty(slug)),
  shouldUpdateAd: (propertyId, should) =>
    dispatch(shouldUpdateAd(propertyId, should)),
  cancelModal: () => dispatch(cancelModal()),
  changeTeam: (currentTeam) => dispatch(changeTeam(currentTeam)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Share)
