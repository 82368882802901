import {
  checkIfViewingSlotIsEnabled,
  orderRegistrantsBasedOnSlots,
  StatusAttendeeCancelled,
  StatusAttendeeConfirmed,
  StatusAttendeeUndefined,
} from 'app/utils/viewings/helpers'

export const orderRegistrants = (registrants) => {
  /*
   * sort in the following order
   * - by confirmed/not confirmed/ cancelled
   * - by checked in
   * - sort by name
   * */

  const confirmationStatusOrder = [
    StatusAttendeeCancelled,
    StatusAttendeeUndefined,
    StatusAttendeeConfirmed,
  ]

  const getConfirmationStatusIndex = (registrant) =>
    confirmationStatusOrder.findIndex(
      (status) => status === registrant.confirmationStatus,
    )

  const isSlotsEnabled = checkIfViewingSlotIsEnabled(registrants)
  if (isSlotsEnabled) {
    let slotOrderedRegistrants = orderRegistrantsBasedOnSlots(registrants)
    return slotOrderedRegistrants
  }

  const calculateRegistrantStatusesScore = (registrant) => {
    const confirmationScore = getConfirmationStatusIndex(registrant) * 1000
    const checkedInScore = registrant.checkIn ? 0 : 100
    return confirmationScore + checkedInScore
  }

  return registrants.sort((regA, regB) => {
    const regAStatusesScore = calculateRegistrantStatusesScore(regA)
    const regBStatusesScore = calculateRegistrantStatusesScore(regB)

    let regANameScore = 0
    let regBNameScore = 0
    if (regA.fullName < regB.fullName) {
      regANameScore = 10
    } else {
      regBNameScore = 10
    }

    return (
      regBStatusesScore + regBNameScore - (regAStatusesScore + regANameScore)
    )
  })
}

export const orderEnquirers = (enquirers) => {
  return [...enquirers].sort((eA, eB) => {
    const aFullName = eA.fullName
    const bFullName = eB.fullName
    return aFullName > bFullName ? 1 : aFullName < bFullName ? -1 : 0
  })
}
