import React from 'react'

import moment from 'moment'

import { noteActionCollection, targetSectionObject } from 'app/sm/helpers'
import * as helpers from 'app/sm/helpers'

import 'app/components/display/application_note/style.scss'

const PDFNoteComponent = ({ note = {}, componentClass = '' }) => {
  const { type, applicant = '', target, updatedAt } = note || {}
  const sectionName = target ? Object.keys(targetSectionObject)[target] : ''
  const sectionText = sectionName ? targetSectionObject[sectionName].text : ''
  const matchedAction = type
    ? noteActionCollection.find((action) => action.type === type)
    : ''
  const { text: actionText = '' } = matchedAction || {}

  return (
    <div className={`application-note-container-pdf ${componentClass}`}>
      <div className="display-flex">
        <div>
          <span className="mr10 color-unchecked">
            {moment(updatedAt).format(helpers.dateTimeStampFormat)}
          </span>
          <strong>{note.author}&nbsp;</strong>
          {actionText}&nbsp;
          <span>
            {applicant}&apos;s {sectionText} section
          </span>
          {note.content && (
            <span className="ml10 note-content-text">
              &#45; <span className="ml10">&quot;{note.content}&quot;</span>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default PDFNoteComponent
