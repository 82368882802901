import React from 'react'

import * as Display from 'app/components/display/display'
import { capitalizeFirstLetter } from 'app/sm/helpers'
import { formatAndPrefix } from 'app/utils/phonenumber/helpers'

import 'app/match/apply/application_summary_before_submit/shared_components/contact_container/style.scss'

const ContactDetails = ({
  firstName = '',
  lastName = '',
  phoneNumber = '',
  email = '',
  extraName = '',
  showContactLabel = true,
  componentClass = '',
  officeNumber = '',
}) => {
  const contactNumber = officeNumber ? officeNumber : phoneNumber
  const formattedPhoneNumber = formatAndPrefix(phoneNumber)
  const formattedOfficeNumber = formatAndPrefix(officeNumber)

  return (
    <div className={`display-flex flex-direction-column ${componentClass}`}>
      {showContactLabel && (
        <div className="contact-title">
          <div>Contact</div>
        </div>
      )}
      <div className="display-flex">
        <div className="household-applicant-table avatar-container mt5 align-items-center">
          <div className="avatar application-avatar">
            <span className="app-avatar-initial-position default-font-family">
              {capitalizeFirstLetter(firstName.substring(0, 1))}
              {capitalizeFirstLetter(lastName.substring(0, 1))}
            </span>
          </div>
        </div>
        <Display.StandardContactInfoContainer
          mainTitle={`${capitalizeFirstLetter(
            firstName,
          )} ${capitalizeFirstLetter(lastName)}`}
          linesText={[
            formattedPhoneNumber,
            formattedOfficeNumber,
            email,
            extraName,
          ]}
        />
        <div className="contact-icons-wrapper ml-auto pdf-hide">
          <a href={`tel:${contactNumber}`}>
            <i className="icon-phone" />
          </a>
          <a href={`mailto:${email}`}>
            <i className="icon-mail" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactDetails
