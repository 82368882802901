import { connect } from 'react-redux'

import EmergencyContactForm from 'app/profile/renter/emergency_contact/emergency_contact_form'
import {
  createEmergencyContact,
  editEmergencyContact,
  getEmergencyContact,
  removeEmergencyContact,
} from 'app/sm/emergency_contact/emergency_contact_actions'

const mapStateToProps = ({ EmergencyContact }) => ({
  emergencyContact: EmergencyContact.emergencyContact,
  error: EmergencyContact.error,
})

const mapDispatchToProps = (dispatch) => ({
  createEmergencyContact: (formData) =>
    dispatch(createEmergencyContact(formData)),
  editEmergencyContact: (formData, id) =>
    dispatch(editEmergencyContact(formData, id)),
  removeEmergencyContact: (id) => dispatch(removeEmergencyContact(id)),
  getEmergencyContact: (id) => dispatch(getEmergencyContact(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmergencyContactForm)
