import React from 'react'

import { Link } from 'react-router-dom'

import SearchInline from 'app/sm/common/search-inline'
import ToDoCard from 'app/sm/property_todos/components/todo_card'
import ToDoModal from 'app/sm/property_todos/components/todo_modal'
import * as MockData from 'app/sm/property_todos/property_todos_mock_data'

class PropertyTodos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ToDoModalActive: false,
    }
  }

  componentDidMount() {
    const { propertyId } = this.props.match.params
    this.props.fetchProperty(propertyId)
  }

  showModal() {
    this.setState({ ToDoModalActive: true })
  }

  render() {
    const { property } = this.props
    const propertyId = property.guidID

    if (this.state.ToDoModalActive) {
      return <ToDoModal propertyId={propertyId} />
    }

    return (
      <div className="sm-property-todos">
        <div className="section-title xs-row">
          <div className="left">
            <Link to={'/'} className="lh1">
              <h3 className="lh1">
                <i className="icon-arrow-left fs16 pt2 pr10 xs-ibm"></i>
                <span className="ibm lh1">ToDo's (12)</span>
              </h3>
            </Link>
          </div>
          <div className="right">
            <SearchInline placeholder="Search..." className="xs-hide" />
            <button
              onClick={() => this.showModal()}
              className="btn btn-small-xs wa ml20"
            >
              <i className="icon-add left ibm fs13"></i>
              <span className="ibm">Add To Do</span>
            </button>
          </div>
        </div>
        <div className="sm-property-todos-list">
          <div className="sm-property-todos-list-coll">
            <div className="todos-header yellow-bg">
              Overdue ({MockData.OverdueToDos.length})
            </div>
            <ul className="todos-list">
              {MockData.OverdueToDos.map((todo, index) => (
                <li className="todos-list-item" key={index}>
                  <ToDoCard todo={todo} />
                </li>
              ))}
            </ul>
          </div>

          <div className="sm-property-todos-list-coll">
            <div className="todos-header">
              Due in 7 days ({MockData.InSevenDaysToDos.length})
            </div>
            <ul className="todos-list">
              {MockData.InSevenDaysToDos.map((todo, index) => (
                <li className="todos-list-item" key={index}>
                  <ToDoCard todo={todo} />
                </li>
              ))}
            </ul>
          </div>

          <div className="sm-property-todos-list-coll">
            <div className="todos-header">
              Over 7 days ({MockData.OverSevenDaysToDos.length})
            </div>
            <ul className="todos-list">
              {MockData.OverSevenDaysToDos.map((todo, index) => (
                <li className="todos-list-item" key={index}>
                  <ToDoCard todo={todo} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default PropertyTodos
