import { useEffect } from 'react'

import $ from 'jquery'
import qs from 'qs'

import { Button } from 'app/components/design-system-components'
import SvgChevronLeftRounded from 'app/components/design-system-components/icons/navigation/ChevronLeftRounded'
import { getReferrer } from 'app/constants/referrer.constants'
import JoinSnugFormConnection from 'app/session/join/join_snug_form_connection'
import {
  JoinSnugContext,
  JoinSnugContextProvider,
} from 'app/session/join/JoinContext'
import TermsAndConditions from 'app/session/login_components/terms_and_conditions'
import CenterContentContainer from 'app/shared_components/layout_component/center_content_component/component'
import SnugLogo from 'app/shared_components/snug_logo'
import ApplyOffer from 'app/sm/apply/components/apply_offer'

const JoinSnug = (props) => {
  const curQueries = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  })

  useEffect(() => {
    $('#index').addClass('no-navbar-menu')
    return () => {
      $('#index').removeClass('no-navbar-menu')
    }
  }, [])

  const referrerGroup = curQueries?.referrer_group
  const referrerID = curQueries?.referrer_id

  const referrer = getReferrer(referrerGroup, referrerID) || {
    referrer_group: referrerGroup,
    referrer_id: referrerID,
  }

  return (
    <JoinSnugContextProvider>
      <div className="auth-wrapper">
        <div className="auth-screen panel panel-default">
          <div className="panel-heading">
            <div className="app-header">
              <div className="app-header_left">
                <JoinSnugContext.Consumer>
                  {({ showStepBack, stepBack }) =>
                    !!showStepBack && (
                      <Button variant="flat" p="0" onClick={stepBack}>
                        <SvgChevronLeftRounded height="30px" width="30px" />
                      </Button>
                    )
                  }
                </JoinSnugContext.Consumer>
              </div>
              <div className="app-header_center">
                <SnugLogo />
              </div>
              <div className="app-header_right" />
            </div>
          </div>
          <div className="app-content background-grey mt0">
            <div className="app-content-container">
              <div className="sm-apply">
                {!!curQueries.listing_image && (
                  <ApplyOffer
                    property={{}}
                    isApplyAnywhere={true}
                    imageUrlFromUrl={curQueries.listing_image}
                    referrerBackUrl={curQueries.referrer_url}
                    referrer={referrer}
                  />
                )}
                <CenterContentContainer
                  containerClass="apply-form-mobile-display"
                  componentClass={`application-standard-shadow-box login-container 
                  ${curQueries.listing_image ? 'padding-for-card' : ''}
                  `}
                >
                  <div className="panel-body">
                    <JoinSnugFormConnection search={props.location.search} />

                    <TermsAndConditions />
                  </div>
                </CenterContentContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </JoinSnugContextProvider>
  )
}

export default JoinSnug
