import {
  ApplicationAssigned,
  ApplicationUpdated,
  CalendarExpired,
  EmploymentVerified,
  LeasedPropertiesAutoArchived,
  ReferenceAdded,
  ReferenceArchived,
  ReferenceDeclined,
  ReferenceReassigned,
  ReferenceUnarchived,
} from 'app/shared_components/activity_feed/activity_feed.const'
import { urlTo } from 'app/sm/helpers'

export const getActivityLabel = (activity, { openTab }) => {
  switch (activity.type) {
    case ApplicationAssigned: {
      return `Application assigned to ${
        openTab.key === 'team' ? activity.data?.notified_name : 'you'
      }`
    }
    case ApplicationUpdated: {
      return `Application updated`
    }
    case ReferenceDeclined: {
      return 'Reference Declined'
    }
    case ReferenceAdded: {
      return 'Reference Added'
    }
    case EmploymentVerified: {
      return 'Employment Verified'
    }
    case LeasedPropertiesAutoArchived: {
      return 'Auto-archived leased properties'
    }
    case ReferenceArchived: {
      return 'Reference Archived'
    }
    case ReferenceUnarchived: {
      return 'Reference Unarchived'
    }
    case ReferenceReassigned: {
      return `Reference re-assigned to ${
        openTab.key === 'team' ? activity.data?.notified_name : 'you'
      }`
    }
    case CalendarExpired: {
      return `Calendar Sync expired: ${activity.data?.calendar_platform} calendar needs to be reconnected`
    }
  }
}

export const getActivityNavigationURL = (activity, { currentTeam }) => {
  const { data = {} } = activity

  switch (activity.type) {
    case ApplicationUpdated:
    case ApplicationAssigned:
    case EmploymentVerified: {
      if (
        !data?.application_guid ||
        !data?.primary_applicant_guid ||
        !data?.property_guid
      ) {
        return ''
      }

      return `/sm/property/${data.property_guid}/applications/${data.application_guid}/applicant/${data.primary_applicant_guid}`
    }
    case ReferenceDeclined:
    case ReferenceAdded: {
      if (!currentTeam) return ''

      return `/teams/${currentTeam.slug}/rental-references`
    }
    case ReferenceArchived:
    case ReferenceUnarchived:
    case ReferenceReassigned: {
      if (!currentTeam) return ''
      return `/teams/${currentTeam.slug}/rental-references${
        data.property_name && `?q=${data.property_name}`
      }`
    }
    case CalendarExpired: {
      return urlTo('teamCalendarSync', { teamSlug: currentTeam.slug })
    }
    default:
      return ''
  }
}
