import * as React from 'react'

function SvgPeopleOutlineRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 8.5c0 1.93-1.57 3.5-3.5 3.5s-3.5-1.57-3.5-3.5S7.07 5 9 5s3.5 1.57 3.5 3.5zm-2 0C10.5 7.67 9.83 7 9 7s-1.5.67-1.5 1.5S8.17 10 9 10s1.5-.67 1.5-1.5zM9 13.75c-2.34 0-7 1.17-7 3.5V18c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-.75c0-2.33-4.66-3.5-7-3.5zm0 2c-1.79 0-3.82.67-4.66 1.25h9.32c-.84-.58-2.87-1.25-4.66-1.25zm7.04-1.94c1.16.84 1.96 1.96 1.96 3.44V19h3c.55 0 1-.45 1-1v-.75c0-2.02-3.5-3.17-5.96-3.44zM18.5 8.5c0 1.93-1.57 3.5-3.5 3.5-.54 0-1.04-.13-1.5-.35.63-.89 1-1.98 1-3.15s-.37-2.26-1-3.15c.46-.22.96-.35 1.5-.35 1.93 0 3.5 1.57 3.5 3.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPeopleOutlineRounded
