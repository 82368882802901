import React from 'react'

import { NavLink } from 'react-router-dom'

import ApplicationsCount from 'app/shared_components/navbar/components/applications_count/container'
import { urlTo } from 'app/sm/helpers'

const FindHome = ({ applicationsCount }) => (
  <div className="collapse-box">
    <button
      data-target="#FindHomeMenu"
      data-menutoggle="true"
      className="collapsed"
      data-toggle="collapse"
    >
      Find a Home
    </button>
    <ul id="FindHomeMenu" className="collapse in">
      <li>
        <NavLink to={urlTo('recentlyViewed')}>
          <i className="icon-recent" />
          <span>History</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={urlTo('smViewings')}>
          <i className="icon-calendar" />
          <span>Viewings</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={urlTo('savedProperties')}>
          <i className="icon-saved" />
          <span>Saved Properties</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={urlTo('homeoverview')}>
          <i className="icon-bondcover" />
          <span>BondCover</span>
        </NavLink>
      </li>
      <ApplicationsCount applicationsCount={applicationsCount} />
    </ul>
  </div>
)

export default FindHome
