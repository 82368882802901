import React from 'react'

import PropTypes from 'prop-types'

import Card from 'app/match/applicationReportPDF/components/Card'
import Persona from 'app/match/applicationReportPDF/components/Persona'

const propTypes = {
  household: PropTypes.array,
}

const Household = ({ household }) => {
  return (
    <div className="household-container">
      <Card
        type="icon"
        icon="icon-users"
        title="Household"
        addClass={'card-bottom-bordered'}
      />
      <ul className="list-reset">
        {household.map((item, index) => {
          return (
            <li key={index}>
              <Persona info={item} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Household.propTypes = propTypes

export default Household
