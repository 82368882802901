import { connect } from 'react-redux'

import { clearRentalBackUrl, setBackUrl } from 'app/shared_components/actions'
import {
  createRenterProfilePDF,
  fetchCompleteness,
  fetchRentalReputation,
} from 'app/sm/rental_reputation/actions'
import RentalReputation from 'app/sm/rental_reputation/container'

const mapStateToProbs = ({ RentalReputation, session, Shared }) => ({
  rentalReputation: RentalReputation.rentalReputation,
  completeness: RentalReputation.completeness,
  pdf: RentalReputation.pdf,
  currentUser: session.currentUser,
  error: RentalReputation.error,
  backUrl: Shared.backUrl,
})

const mapDispatchToProbs = (dispatch) => ({
  fetchCompleteness: () => dispatch(fetchCompleteness()),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
  createRenterProfilePDF: (success, fail) =>
    dispatch(createRenterProfilePDF(success, fail)),
  clearRentalBackUrl: () => dispatch(clearRentalBackUrl()),
})

export default connect(mapStateToProbs, mapDispatchToProbs)(RentalReputation)
