import { connect } from 'react-redux'

import { addRentalReputationBackUrl } from 'app/shared_components/actions'
import {
  acceptJointApplicationAsSecondary,
  submitFinalApplication,
} from 'app/sm/apply/apply_actions'
import { fetchApplication } from 'app/sm/apply/apply_actions'
import {
  addEmployer,
  fetchEmployers,
  removeEmployer,
} from 'app/sm/employment/actions'
import EmploymentHistoryList from 'app/sm/employment/employment_history_list'
import { fetchCompleteness } from 'app/sm/rental_reputation/actions'
import {
  changeReputationPreference,
  retrieveReputationPreference,
} from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({
  Employment,
  session,
  Shared,
  apply,
  RentalReputation,
}) => ({
  employers: Employment.employers,
  error: Employment.error,
  clicked: Employment.clicked,
  currentUser: session.currentUser,
  backUrl: Shared.backUrl,
  property: apply.property,
  reputationPreference: RentalReputation.preference,
  completeness: RentalReputation.completeness,
  applicationDetails: apply.application,
})

const mapDispatchToProps = (dispatch) => ({
  addEmployer: (payload) => dispatch(addEmployer(payload)),
  fetchEmployers: () => dispatch(fetchEmployers()),
  removeEmployer: (id, success, fail) =>
    dispatch(removeEmployer(id, false, success, fail)),
  retrieveReputationPreference: () => dispatch(retrieveReputationPreference()),
  changeReputationPreference: (preference) =>
    dispatch(changeReputationPreference(preference)),
  addRentalReputationBackUrl: (rentalBackUrl) =>
    dispatch(addRentalReputationBackUrl(rentalBackUrl)),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
  submitFinalApplication: (applicationId, data) =>
    dispatch(submitFinalApplication(applicationId, data)),
  acceptJointApplicationAsSecondary: (
    applicationId,
    applicantId,
    applicantSummary,
    apply,
    data,
  ) =>
    dispatch(
      acceptJointApplicationAsSecondary(
        applicationId,
        applicantId,
        applicantSummary,
        apply,
        data,
      ),
    ),
  fetchApplication: (applicationId) =>
    dispatch(fetchApplication(applicationId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmploymentHistoryList)
