import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  ButtonWithLoading,
  Flex,
  IconButton,
  Spinner,
} from 'app/components/design-system-components'
import { OpenInNewRounded } from 'app/components/design-system-components/icons/actions'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  getPropertyBuilding,
  removePropertyBuilding,
} from 'app/services/http/buildings'
import * as snugNotifier from 'app/services/snugNotifier'
import AddPropertyToBuildingModal from 'app/sm/onlist_details/AddPropertyToBuilding/components/modal'

function AddPropertyToBuilding({ propertyName, guidDetails, teamSlug }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [hasAssignedBuilding, setHasAssignedBuilding] = useState(false)
  const [buildingInfo, setBuildingInfo] = useState({})
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const [fetchingBuildingInfo, setFetchingBuildingInfo] = useState(false)
  const { property = '', agencyGUID = '' } = guidDetails
  const fetchBuildingInfoForProperty = () => {
    const { property = '', agencyGUID = '' } = guidDetails
    setFetchingBuildingInfo(true)
    getPropertyBuilding(agencyGUID, property)
      .then((data) => {
        if (data && data.building) {
          setHasAssignedBuilding(true)
          setBuildingInfo(data.building)
        } else {
          setBuildingInfo({})
          setHasAssignedBuilding(false)
        }
      })
      .catch((error) => {
        snugNotifier.error(error.message)
      })
      .finally(() => {
        setFetchingBuildingInfo(false)
      })
  }

  useEffect(() => {
    fetchBuildingInfoForProperty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickRemoveAssignedBuilding = () => {
    removePropertyBuilding(agencyGUID, property)
      .then(() => {
        fetchBuildingInfoForProperty()
      })
      .catch((error) => {
        snugNotifier.error(error.message)
      })
  }

  return (
    <div className="mt30" style={{ clear: 'both' }}>
      <h5>Assigned Building</h5>
      {hasAssignedBuilding && (
        <Flex my="16px" justifyContent="space-between" alignItems="center">
          {fetchingBuildingInfo && <Spinner />}
          {!fetchingBuildingInfo && (
            <Box>
              {buildingInfo && buildingInfo.name}
              <a
                href={`/apply/${teamSlug}/b/${buildingInfo.slug}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  IconCmp={OpenInNewRounded}
                  iconProps={{
                    fontSize: theme.fontSizes[6] + 'px',
                  }}
                  variant="flatBlack"
                  col
                  mb="2px"
                  ml={theme.space[1] + 'px'}
                />
              </a>
            </Box>
          )}
          <Box>
            <ButtonWithLoading
              variant="brightGreenOutline"
              onClick={onClickRemoveAssignedBuilding}
            >
              Remove
            </ButtonWithLoading>
          </Box>
        </Flex>
      )}
      {!hasAssignedBuilding && (
        <>
          <div className="mt10 mb20">Assign a building to this property.</div>
          <Button onClick={toggleModal} variant="brightGreenOutline">
            Assign a building
          </Button>
        </>
      )}
      {isModalOpen && (
        <AddPropertyToBuildingModal
          toggleModal={toggleModal}
          propertyName={propertyName}
          guidDetails={guidDetails}
          fetchBuildingInfoForProperty={fetchBuildingInfoForProperty}
          teamSlug={teamSlug}
        />
      )}
    </div>
  )
}

export default AddPropertyToBuilding
