import React from 'react'

import 'app/components/display/pill/style.scss'

const Pill = ({ text, onClick }) => {
  return (
    <div
      tabIndex={0}
      role="button"
      className="pill-container"
      onClick={onClick}
    >
      {text}
    </div>
  )
}

export default Pill
