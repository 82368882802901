import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import {
  Box,
  ButtonWithLoading,
  TextSpan,
} from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { integrationBrands } from 'app/constants/integrations.constants'
import IntegrationActivity, {
  useActivateIntegration,
  useDeactivateIntegration,
  useIntegrationStatus,
} from 'app/Integrations'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  activateIntegration,
  deactivateIntegration,
  getIntegrationStatus,
} from 'app/services/http/integrations'
import * as snugNotifier from 'app/services/snugNotifier'
import { loadingStatesIds } from 'app/utils/loading-states'
import { ticaIntegrationLearnMore } from 'config/external-links'

const ticaBrandId = integrationBrands.tica.id
const ticaBrandLogo = integrationBrands.tica.logo

const TicaIntegration = ({ currentTeam }) => {
  const {
    loadIntegrationStatus,
    integrationStatusInfo,
    integrationStatusLoadingStates,
  } = useIntegrationStatus(currentTeam, ticaBrandId, getIntegrationStatus)

  const {
    activateIntegration: activateTica,
    activateIntegrationLoadingStates,
    activateIntegrationLoadingStatesHelpers: activateTicaLoadingHelpers,
  } = useActivateIntegration(
    currentTeam,
    ticaBrandId,
    activateIntegration,
    'TICA activated successfully!',
  )

  const {
    deactivateIntegration: deactivateTica,
    deactivateIntegrationLoadingStates,
  } = useDeactivateIntegration(
    currentTeam,
    ticaBrandId,
    deactivateIntegration,
    'TICA deactivated successfully!',
  )

  const [activationModal, setActivationModal] = useState({ isShown: false })

  useEffect(() => {
    loadIntegrationStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const statusProps = {
    statusInfo: integrationStatusInfo,
    statusLoadingStates: integrationStatusLoadingStates,
    reloadStatusInfo: loadIntegrationStatus,
  }

  const openActivationModal = () => {
    activateTicaLoadingHelpers.reset()
    setActivationModal({ isShown: true })
  }

  const closeActivateModal = () => {
    activateTicaLoadingHelpers.reset()
    setActivationModal({ isShown: false })
  }

  const renderActivationModal = () =>
    activationModal.isShown && (
      <GenericModal
        onDismiss={() => closeActivateModal()}
        title="Confirm TICA activation"
        modalFooter={
          <GenericModalFooter
            primaryBtnConfig={{
              title: 'Activate',
              onClick: () =>
                activateTica()
                  .then(() => {
                    closeActivateModal()
                    loadIntegrationStatus()
                  })
                  .catch(({ message }) => snugNotifier.error(message)),
              props: {
                loading:
                  activateIntegrationLoadingStates.state ===
                  loadingStatesIds.LOADING,
              },
            }}
            secondaryBtnConfig={{
              title: 'Cancel',
              onClick: () => closeActivateModal(),
            }}
          />
        }
      >
        <TextSpan lineHeight={theme.lineHeights.encrption} mb={6}>
          Running checks requires a TICA subscription. Reports are accessible
          with valid TICA login details.
        </TextSpan>
      </GenericModal>
    )

  const activationModalElem = renderActivationModal()

  const activationProps = {
    activationFn: openActivationModal,
    activateLoadingStates: activateIntegrationLoadingStates,
  }

  const deactivateProps = {
    deactivationFn: () =>
      deactivateTica()
        .then(() => loadIntegrationStatus())
        .catch(({ message }) => snugNotifier.error(message)),
    deactivateLoadingStates: deactivateIntegrationLoadingStates,
  }

  return (
    <>
      <IntegrationActivity
        integrationBrand={ticaBrandId}
        logo={ticaBrandLogo}
        statusProps={statusProps}
        activateProps={activationProps}
        deactivateProps={deactivateProps}
        brandsList={integrationBrands}
      />
      <Box mt={5} fontSize={theme.fontSizes.pRegular14}>
        <TextSpan as="span">
          The TICA integration is available in the Integrations Bundle.{' '}
        </TextSpan>
        <a target="_blank" href={ticaIntegrationLearnMore} rel="noreferrer">
          Learn more
        </a>
      </Box>

      {activationModalElem}
    </>
  )
}

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
})

export default connect(mapStateToProps)(TicaIntegration)
