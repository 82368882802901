import React from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Flex } from 'app/components/design-system-components'
import {
  MailOutlineRounded,
  MessageRounded,
} from 'app/components/design-system-components/icons/communications'
import * as Display from 'app/components/display/display'
import { entryNoticeMessageStatus } from 'app/constants/entry-notice.constatns'
import { WidthPercentage } from 'app/pages/teams/viewings/styles'
import ViewingContactItem from 'app/sm/inspections/components/viewing_contact_item'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

const allStatusDescriptions = `Sending - Email is being processed\n
Delivered - Email delivered to recipient\n
Opened - Email opened by recipient\n
Failed - Email failed to deliver\n
Opted out - Tenant not opted in to electronic communications\n
Not Sent - Property already vacant, unsubscribed from all email communications, or tenant added after the last viewing update`

const allStatusSMSDescriptions = `Requested - SMS delivery was requested from SMS provider.`

export const DATE_ENTRY_NOTICES_RELEASED = moment('2021-02-06')

export const getAllFailedOrUnopenedStatuses = (status) => {
  return status !== 'opened'
}

export const EmptyEntryNoticeIconContainer = styled.div`
  width: 28px;
`

export const getStatusPillColorAndStatusName = (status) => {
  switch (status) {
    case 'requested': {
      return {
        pillColor: 'orange-hollow',
        status: 'Sending',
      }
    }
    case 'delivered': {
      return {
        pillColor: 'hollow',
        status: 'Delivered',
      }
    }
    case entryNoticeMessageStatus.opened: {
      return {
        pillColor: 'green',
        status: 'Opened',
      }
    }
    case 'failed': {
      return {
        pillColor: 'red-hollow',
        status: 'Failed',
      }
    }
    case 'not-sent': {
      return {
        pillColor: 'yellow',
        status: 'Not Sent',
      }
    }
    case 'opted-out': {
      return {
        pillColor: 'grey',
        status: 'Opted out',
      }
    }
    default: {
      return {
        pillColor: 'grey',
        status: '',
      }
    }
  }
}

class TenantReadModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      noTenantsView: true,
    }
  }

  componentDidMount() {
    const { tenantsWhoReadTheEntryNotice } = this.props
    if (
      tenantsWhoReadTheEntryNotice &&
      tenantsWhoReadTheEntryNotice.length > 0
    ) {
      this.setState({
        noTenantsView: false,
      })
    }
  }

  render() {
    const { tenantsWhoReadTheEntryNotice = [], toggleModal } = this.props
    const recipients = tenantsWhoReadTheEntryNotice || [] // handles case where null, which does not receive default assignment

    const mappedRecipients = recipients.map((recipient) => {
      const { contact } = recipient
      if (!contact) {
        return recipient
      }
      return {
        ...recipient,
        contact: {
          ...contact,
          firstName: contact.first_name,
          lastName: contact.last_name,
          email: recipient.recipientEmail,
        },
      }
    })

    const { noTenantsView } = this.state
    return (
      <Display.Modal
        toggleModal={() => toggleModal()}
        title="Entry Notices"
        hideButtonSection
      >
        <div>
          {noTenantsView ? (
            <div className="text-align-center">No Tenants</div>
          ) : (
            <div>
              {mappedRecipients &&
                mappedRecipients.map((recipient, index) => {
                  const { pillColor, status } = getStatusPillColorAndStatusName(
                    recipient.emailStatus,
                  )
                  return (
                    <Flex alignItems="center" key={index}>
                      <WidthPercentage displayType="inline-block" amount="60%">
                        <div className="viewing-items pt0 pb0">
                          <div className="content-wrapper pt5 pb5 cursor-pointer">
                            {recipient.contact ? (
                              <ViewingContactItem contact={recipient.contact} />
                            ) : (
                              <div>{recipient.recipientEmail}</div>
                            )}
                            <div className="controls comment-wrap w45p xs-w100p" />
                          </div>
                        </div>
                      </WidthPercentage>
                      <WidthPercentage
                        displayType="inline-block"
                        amount="40%"
                        style={{ textAlign: 'right' }}
                      >
                        <div className="info flex-direction-row-under500 display-flex align-items-center width100 pb2">
                          <MailOutlineRounded className="gray-light-color mr5" />
                          <Display.StatusPill
                            pillType={pillColor}
                            pillTextClass="font-size-12"
                            // componentClass="mr15"
                            text={status}
                          />
                          <ReactTooltip
                            id="snug-match-label-email"
                            data-type="info"
                            className="tool-tip-style"
                          >
                            <span>{allStatusDescriptions}</span>
                          </ReactTooltip>
                          <i
                            className="icon-help-outline fs12 gray-light-color ml5"
                            data-tooltip-content=""
                            data-tooltip-id="snug-match-label-email"
                          />
                          <div className="font-size-12 ml5">
                            {dateTimeHelpers.getHalfDayHalfMonthFormattedDate(
                              recipient.emailStatusTimestamp,
                            )}
                          </div>
                        </div>
                        {!dateTimeHelpers.isTooLongTimeDiff(
                          recipient.smsStatusTimestamp,
                        ) && (
                          <div className="info flex-direction-row-under500 display-flex align-items-center width100 pt2">
                            <MessageRounded className="gray-light-color mr5" />
                            <Display.StatusPill
                              pillType={'green'}
                              pillTextClass="font-size-12"
                              text={'Requested'}
                            />
                            <ReactTooltip
                              id="snug-match-label-sms"
                              data-type="info"
                              className="tool-tip-style"
                            >
                              <span>{allStatusSMSDescriptions}</span>
                            </ReactTooltip>
                            <i
                              className="icon-help-outline fs12 gray-light-color ml5"
                              data-tooltip-content=""
                              data-tooltip-id="snug-match-label-sms"
                            />
                            <div className="font-size-12 ml5">
                              {dateTimeHelpers.getHalfDayHalfMonthFormattedDate(
                                recipient.smsStatusTimestamp,
                              )}
                            </div>
                          </div>
                        )}
                      </WidthPercentage>
                    </Flex>
                  )
                })}
            </div>
          )}
        </div>
      </Display.Modal>
    )
  }
}

export default TenantReadModal
