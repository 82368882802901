const fileOrderedUnits = ['B', 'K', 'MB', 'GB']

export const calculateFileSize = (sizeInBytes) => {
  let unitsIndex = 0
  let val = sizeInBytes
  do {
    if (val < 1024) return [val, fileOrderedUnits[unitsIndex]]

    unitsIndex++
    val = val / 1024
  } while (unitsIndex < fileOrderedUnits.length - 1)
}

export const getFilenameAndExtension = (file) => {
  const index = file.lastIndexOf('.')
  const filename = file.slice(0, index)
  const extension = file.slice(index + 1)
  return { filename, extension }
}
