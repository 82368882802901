import React from 'react'

import AgencyInvite from 'app/bond_cover/agency/agency_invite'
import ErrorMessages from 'app/constants/error_messages'
import getParameter from 'app/shared_components/parameter_parser'
import { SessionStorageUtils } from 'app/storage_utils'

const validatingStatuses = {
  notChecked: 'NOT_CHECKED',
  checking: 'CHECKING',
  done: 'DONE',
}

export class AgencyInviteContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      invite: '',
      loggedIn: 'false',
      error: '',
      errorCode: 0,
      inviteeEmail: '',
      currentUserEmail: '',
      validatingStatus: validatingStatuses.notChecked,
      createLiteAccount: false,
    }
  }

  componentDidMount() {
    if (this.isReadyToValidate()) {
      this.validateInvitation()
    }
  }

  componentDidUpdate() {
    if (this.isReadyToValidate()) {
      this.validateInvitation()
    }
  }

  isReadyToValidate() {
    const { currentUser } = this.props
    const { validatingStatus } = this.state

    return (
      !currentUser.isUserFetching &&
      validatingStatus === validatingStatuses.notChecked
    )
  }

  validateInvitation() {
    const inviteId = getParameter('code')
    const agencyId = getParameter('secret')
    const inviteeEmail = getParameter('inviteemail')
    const createLiteAccountParameter = getParameter('createLiteAccount')
    if (createLiteAccountParameter && createLiteAccountParameter === 'true') {
      this.setState({
        createLiteAccount: true,
      })
    }
    const acceptUrl = `/agency/accept${window.location.search}`
    const { validateInvite, currentUser, logout } = this.props
    const { email } = this.props.currentUser

    this.setState({
      validatingStatus: validatingStatuses.checking,
    })

    return validateInvite(inviteId).then(
      ({ ok, invite: inviteData, statusCode, responseText }) => {
        this.setState({
          validatingStatus: validatingStatuses.done,
        })
        const invite = ok ? 'valid' : 'invalid'
        const loggedIn = currentUser && currentUser.isVerified ? true : false
        if (!loggedIn) {
          SessionStorageUtils.setItem('forwardUrl', acceptUrl)
        }

        this.setState({
          invite,
          inviteData,
          loggedIn,
          inviteeEmail,
          currentUserEmail: email,
          logout,
        })

        if (!ok) {
          responseText.then((t) => {
            this.setState({
              errorCode: parseInt(t),
              error: ErrorMessages[parseInt(t)]
                ? ErrorMessages[parseInt(t)]
                : `There is an error: ${t}`,
            })
          })
        }
      },
    )
  }

  render() {
    return <AgencyInvite {...this.state} />
  }
}
