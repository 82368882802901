import {
  CLEAR_ERROR,
  RECEIVE_ERROR,
  RECEIVE_FEEDBACK_QUESTIONS,
  RECEIVE_FEEDBACK_STATUS,
  RECEIVE_PROPERTY_VIEWINGS,
  RECEIVE_VIEWING_ERROR,
  SHOW_CHECKBOX_SPINNER,
  SHOW_COMMENT_MODAL,
  SHOW_EDIT_VIEWING_MODAL,
  SHOW_LATE_VIEWING_MODAL,
  TOGGLE_WALKIN_MODAL,
} from 'app/sm/inspections/inspections_actions'

const _defaultState = {
  propertyViewings: [],
  viewing: {},
  error: '',
  viewingErrorGuid: '',
  viewingTime: {},
  isEditViewModalOn: false,
  isWalkInModalOn: false,
  isLateModalOn: false,
  isCommentModalOn: false,
  renterInfo: {},
  feedbackQuestions: [],
  feedbackStatus: {},
  checkboxSpinnerGuidArray: [],
}

const ViewingsReducer = (state = _defaultState, action) => {
  let {
    type,
    propertyViewings,
    error,
    viewing,
    viewingErrorGuid,
    isEditViewModalOn,
    isWalkInModalOn,
    isLateModalOn,
    viewingTime,
    isCommentModalOn,
    renterInfo,
    feedbackQuestions,
    feedbackStatus,
    checkboxSpinnerGuidArray,
  } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case RECEIVE_PROPERTY_VIEWINGS:
      return Object.assign(newState, { propertyViewings })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case RECEIVE_VIEWING_ERROR:
      return Object.assign(newState, { viewingErrorGuid })
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    case TOGGLE_WALKIN_MODAL:
      return Object.assign(newState, { isWalkInModalOn, viewing })
    case SHOW_LATE_VIEWING_MODAL:
      return Object.assign(newState, { isLateModalOn, viewing })
    case SHOW_EDIT_VIEWING_MODAL:
      return Object.assign(newState, { isEditViewModalOn, viewingTime })
    case SHOW_COMMENT_MODAL:
      return Object.assign(newState, { isCommentModalOn, renterInfo })
    case RECEIVE_FEEDBACK_QUESTIONS:
      return Object.assign(newState, { feedbackQuestions })
    case RECEIVE_FEEDBACK_STATUS:
      return Object.assign(newState, { feedbackStatus })
    case SHOW_CHECKBOX_SPINNER:
      return Object.assign(newState, { checkboxSpinnerGuidArray })
    default:
      return newState
  }
}

export default ViewingsReducer
