import React from 'react'

import { Modal } from 'app/components/design-system-components'
import { Iframe } from 'app/match/leadToSnug/styles'

function UpgradeModalWithPipedriveForm({ toggleShowUpgradeForm }) {
  return (
    <Modal
      modalHeading="Upgrade Snug Account"
      toggleModal={toggleShowUpgradeForm}
      customModalFooter={true}
      modalBody={
        <>
          <Iframe
            src="https://webforms.pipedrive.com/f/1sVDk1A9oN7yAEb4ATo78ENHWrS9mjOb57CbPZkEa7ZcFUX7TFRZmumsRjWJfV57B"
            width="100%"
            minHeight="656px"
          />
        </>
      }
    />
  )
}

export default UpgradeModalWithPipedriveForm
