import React from 'react'

const SnugTip = ({ tipContent = '' }) => (
  <div className="col-second xs-first">
    <ul className="snug-tips">
      <div>
        <li>{tipContent}</li>
      </div>
    </ul>
  </div>
)

export default SnugTip
