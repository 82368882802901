export const SNUG_MATCH_LABEL =
  'Snug Match Score is based on property owner preferences, property data, rental application attributes (start date, rent and term) and renter profile completion (non-discriminatory universal platform wide weighted contribution from the renter profile).'

export const DISCRIMINATION_LEARN_MORE_LINK =
  'https://help.snug.com/hc/en-us/articles/360000771916-Discrimination'

export const RENTER_DISCRIMINATION_LEARN_MORE_LINK =
  'https://help.snug.com/hc/en-us/articles/360003681475'

export const RENTAL_REFERENCE_URL_STR = 'rr'
export const SLASH_RENTAL_REFERENCE_URL_STR = '/rr'

export const hasRRUrlStrInPathname = () => {
  return window.location.pathname.endsWith(SLASH_RENTAL_REFERENCE_URL_STR)
}

export const APPLICATION_BY_STAGE = 'applicationbystage'
export const PROSPECT_SUMMARY = 'prospectSummary'

const basePMNumber = '73,000'
const baseAgencyNumber = '34,000'

export const MarketingPMCount = basePMNumber + '*'
export const MarketingPMCountPlus = basePMNumber + '+*'
export const MarketingPMCountCaveatText =
  '*Real estate agents and owners who have used Snug for rental references, applications or leasing software in the 12 months to 31 Dec 2023.'
export const MarketingAgencyCount = baseAgencyNumber + '*'
export const MarketingAgencyText =
  '*Real estate agency personnel who have used Snug for rental references, applications or leasing software in the 12 months to 31 Dec 2023.'

export const BG_CHECK_USUAL_PRICE = '$25'
export const BG_CHECK_RENTER_PRICE_NUM = '19.95'
export const BG_CHECK_SPECIAL_PRICE = '$' + BG_CHECK_RENTER_PRICE_NUM

export const ARCHIVED_STATUS = 100
export const ONLIST_INCOMPLETE = 101

export const STATUS_ONLIST_UNKNOWN = 0
export const STATUS_ONLIST_INCOMPLETE = 10
export const STATUS_ONLIST_COMPLETE = 100

export const ONLIST_INCOMPLETE_TEXT = 'Onlist incomplete'

export const RENTAL_REFERNCES_CAMPAIGN_JULY_2022 = 'rental_references_July2022'
export const RENTAL_REFERNCES_CAMPAIGN_JUNE_2023 = 'rental_references_June2023'

export const MarketingRRLiteVideoLink =
  'https://player.vimeo.com/video/744846308?h=ea63311084'

export const PrimaryAccessReference = 'Access my references'

// sensitive docs
export const AUTO_REMOVE_OUTDATED_DOCUMENTS_DAYS = 60
export const DOCUMENT_SENSITIVE_WARNING = `To enhance data security and privacy, sensitive profile data and appplications are autowithdrawn and deleted.`
export const UPLOADED_SENSITIVE_DOCUMENTS_NOTE =
  'Sensitive documents deleted after 60 days'
export const PRIVACY_SECURITY_LEARN_MORE_LINK =
  'https://help.snug.com/hc/en-us/articles/4633796966799-Data-Security-and-Privacy'

export const BGCheckPriceForManagers = 11.95

export const AUTO_DELETE_SENSITIVE = `We automatically delete Sensitive Information and Documents after ${AUTO_REMOVE_OUTDATED_DOCUMENTS_DAYS} days.`

export const DISCLOSURE_QUESTIONS_DISPLAY = {
  consumer_renting_guide: 'consumer renting guide',
  property_disclosures: 'property disclosures',
  statement_of_information: 'statement of information',
}

export const SNUG_SAFETY_CENTRE_URL = 'https://snug.com/safety/'

export const KEEP_SAFE_ONLINE =
  'Keep Safe Online: be alert to phishing, fake ads or calls and report suspicious activity. Snug never requests login, rent or bond payments.'

export const TRADEMARK_FOOTER_CONTENT_LOGIN_COMPS_TEXT = (
  <span>
    . &copy; Snug Technologies Pty Ltd. 'Snug' is a Trademark. All rights
    reserved.
  </span>
)

export const TRADEMARK_FOOTER_CONTENT_TEXT = (
  <span>
    &copy; Snug Technologies Pty Ltd ABN 82 616 484 292. 'Snug' is a Trademark.
    All rights reserved.
  </span>
)

export const ADVERTISED_RENT_TOOLTIP_TEXT = `Rent bidding is banned in most states: property managers must not ask for or invite rent offers above that advertised but they may accept a higher, unprompted offer made by an applicant. Rent adjustment may be warranted with a lease start after the property's available date or with inclusions such as gardening.`

export const RENTAL_HISTORY_NORMAL_APPLICATION_MESSAGE_NOTE =
  ' Note: a rental reference request will be sent to past property managers.'
export const RENTAL_HISTORY_BALLOT_APPLICATION_MESSAGE_NOTE =
  ' Note: a rental reference request will be sent to past property managers if you are successfully selected in the ballot.'

export const RENTAL_HISTORY_ADD_NORMAL_APP_SEND_RR_NOW_MSG =
  'Yes, contact my residential references now (untick to defer until application submitted)'

export const RENTAL_HISTORY_CONSENT_DISCLOSURE =
  'By adding your referee details you confirm they have consented to be contacted.'

export const RENTAL_HISTORY_ADD_BALLOT_APP_SEND_RR_NOW_MSG =
  'Yes, contact my residential reference immediately (if not now, they will be contacted if you are selected in the ballot)'

export const VARC_BALLOT_APPLICATION_START_PAGE_TERM_AND_CONDITIONS = [
  'Eligible applications will enter the ballot once. This allows all successful, eligible applicants an equal chance of securing their chosen home.',
  'You may choose to apply for multiple homes for which you are eligible, however it is important that applicants only apply for properties that meet their needs.',
  <div>
    On the Snug Platform, for privacy and security Sensitive Data is removed
    automatically after 60 days{' '}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={PRIVACY_SECURITY_LEARN_MORE_LINK}
    >
      learn more
    </a>
  </div>,
]

export const VARC_BALLOT_TEXT_PROPERTY_PAGE = (
  <>
    <p>
      This is a Homes Victoria affordable home. Your application will ask for
      information to make sure you are eligible.
    </p>
    <br />
    <p>
      Once you correctly submit your application, your application will enter a
      ballot for that home. A ballot is where applications will be randomly
      chosen from a list. In this way, all eligible applications have an equal
      chance of being offered an affordable home.
    </p>
    <br />
    <p>
      To start the application process, please click on the green button ‘Enter
      Ballot’.
    </p>
  </>
)

export const VARC_POST_APPLICATION_SUBMISSION_TEXT =
  'If you’re successful in the ballot, you and your co-applicants will be required to finalise an application with 48 hours. Get started early to make sure you’re ready.'

export const APPLICATION_LEASE_TERM_TITLE =
  'Lease Term (select your preference)'

export const VARC_BALLOT_DOCUMENT_UPLOAD_INFO_ALERT =
  'Document upload is optional and will be required within 48 hours if you’re successful. Successful applicants will have the opportunity for their documents to be sighted in person if they choose not to upload.'

//VARC and normal renter header guidance
export const ADDRESS_HISTORY_HEADER_GUIDANCE =
  'Add your current and previous residential history (whether it be renting, owning or living with parents) and contact details to enable verification and reference.'

export const ADDRESS_HISTORY_HEADER_ALERT =
  'Please include all living arrangements e.g. renting, living with family or friends, ownership etc.'

export const EMPLOYMENT_CONFIRMATION_NOTICE_SOUTH_AUSTRALIA =
  'In South Australia, an employment confirmation or payslips may be used to confirm income.'
export const EMPLOYMENT_HISTORY_HEADER_GUIDANCE =
  'Add your current employment details and for self-employed add accountant to enable verification. ' +
  EMPLOYMENT_CONFIRMATION_NOTICE_SOUTH_AUSTRALIA

export const INCOME_HEADER_GUIDANCE =
  'Demonstrate your affordability with proof of income sources (eg. payslip, bank statement/balance or Centrelink benefits).'

export const PETS_DETAIL_HEADER_GUIDANCE =
  'Add your pet details to complete your renter profile.'
export const SHARED_PETS_ALERT =
  'Do you have shared pets? Add pets to one profile only, either the Pet Owner or the Primary Applicant’s profile. Avoid duplicates, incorrect pets may affect your application.'

export const STUDENT_DETAIL_HEADER_GUIDANCE =
  'Are you studying currently? Add your course details.'

export const BALLOT_PROPERTY_APPLY_BUTTON_LABEL = 'Enter Ballot'
export const rentalTermForBallotApplications = 36

export const VARC_TEAM_NAME = 'varc'
export const VARC_TEAM_NAME2 = 'varc2'
export const APPLICATION_SUBMIT_ABOUT_US_PLACEHOLDER =
  'Feel free to provide a few sentences about your household for the property manager.'

export const BANK_STATEMENT_BALANCE_ONLY_GUIDANCE =
  'In Victoria, South Australia and Queensland, property providers may not request daily transaction statements, only a savings balance statement header or redacted (blanked out) statement can be requested.'

export const BANK_STATEMENT_TEXT_PROFILE_PAGE =
  'Providing a savings balance and bank statements helps confirm your capacity and history of paying rent. ' +
  BANK_STATEMENT_BALANCE_ONLY_GUIDANCE

export const OTHER_DOCUMENT_PROFILE_TEXT =
  'Please attach other documents to support your rental application where relevant e.g. employment Letter of Offer, proof of property ownership (Council Rates Notice), self-employment (Letter from Accountant or Financial Statements) etc.'

export const BALLOT_SURVEY_INTRODUCTION_TEXT =
  'Help Homes Victoria improve the affordable housing programmes by completing this short, anonymous survey. This survey responses does not affect your application.'

export const INCOME_PAYSLIPS_COUNT_RECOMMEND =
  'Payslips confirm your current income. If you are changing jobs, you might like to include the last payslip. ' +
  EMPLOYMENT_CONFIRMATION_NOTICE_SOUTH_AUSTRALIA

export const incomePayslipsDurationRecommendTxt = (duration) =>
  `Payslips confirm your current income. This property manager requires ${duration} weeks of Payslips to apply.`

export const RENT_SCHEDULE_SECTION_TITLE =
  'Would you like to pay rent weekly, fortnightly or monthly?'

// Value taken from BE
export const DefaultDisplayPrice = '$ Enquire'

export const VARC_AGENCY_GUID = '3ad8ee58-91c4-4233-b7b1-c470731e4455'

export const VARC_NO_PROPERIES_TEXT =
  'Our next affordable homes at Heidelberg West & Hawthorn will be released early 2024.'

export const AGENCY_NO_PROPERTIES_SUFFIX =
  'does not currently have any properties available. Please click “Enquire” to get in touch.'

export const CONSOLE_CLOUD_ACTIVE = 'ACTIVE'

export const BGCHECK_ANNIVERSARY_EMAIL_URL = 'unsubscribe-bgc'

export const MODAL_BODY_APPLICATION_CLOSED =
  'This property is no longer taking applications. Please view the list of available properties and re-use your profile to apply.'

export const NOT_SOLICITED_TO_RENT_BID_MSG =
  'Please confirm that you have not been solicited by this agent/agency to rent bid.'

export const NOT_SOLICITED_TO_RENT_BID_MODAL_MSG = `By submitting an application with a rent offer above the advertised rent for this property, you confirm the declaration: I have not been solicited by this agent/agency to rent bid.`

export const BGC_LEARN_MORE_URL =
  'https://help.snug.com/hc/en-us/articles/360000132475'

export const PRE_APPLY_PREFERENCE =
  'Require Pre-Apply before viewing, not after'
export const PRE_APPLY_PREFERENCE_TOOLTIP_ID = 'preApplyInfo'
export const PRE_APPLY_PREFERENCE_TOOLTIP_CONTENT = (
  <span>
    Display pre-apply details in enquiry responses and property page. Hides open
    homes and viewing schedule.
  </span>
)

export const REQUIRE_VIEWING_BEFORE_APPLY_PREFERENCE =
  'Require viewing before applying'
export const REQUIRE_VIEWING_BEFORE_APPLY_PREFERENCE_TOOLTIP_ID =
  'requireViewingBeforeApply'
export const REQUIRE_VIEWING_BEFORE_APPLY_PREFERENCE_TOOLTIP_CONTENT = (
  <span>
    Require one of the applicants to have been checked in at a viewing for this
    property before an application can be submitted.
  </span>
)

export const DECLINE_OTHER_HOUSEHOLD_TOOLTIP_CONTENT = (
  <span>Takes a moment to process. If required, reload applications list.</span>
)

export const ADMIN_ACTIVE_DOMAIN_MODAL_TEXT =
  "Activating this Domain Agency ID may result in incorrect properties linking. Please confirm you're authorised and would like to activate for your team."

export const NON_ADMIN_ACTIVE_DOMAIN_MODAL_TEXT =
  'Only users who are administrators in both teams can activate.'

export const CRA_POLICY_TEXT =
  'As the homes are owned by the Victorian Government, Homes Victoria affordable properties do not qualify for Commonwealth Rent Assistance under current Commonwealth Government policy.'

export const RENTAL_LEDGER_RENTER_LEARN_MORE_LINK =
  'https://help.snug.com/hc/en-us/articles/7792805830927'

export const SHARE_APPLICATION_WITH_OWNER_NOTICE_TEXT =
  'Check out the new share application summary released 12 Oct 2023'

export const SHOW_AUTORESPONDER_MESSAGES_SCREEN =
  'showAutoresponderMessagesScreen'

export const HIDE_AUTORESPONDER_MESSAGES_SCREEN_STORAGE_KEY =
  'hideAutoresponderMessagesScreenKey'

export const NEW_LOGIN_INFO_NOTICE =
  "We've improved the login experience with Two-Factor verification."
export const NEW_LOGIN_MIGRATION_WARNING_NOTICE =
  'The next time you log in you will be prompted for Two-Factor verification. Please ensure you have access to the mobile number and email address registered for your user account.'
export const NEW_LOGIN_LEARN_MORE_LINK =
  'https://help.snug.com/hc/en-us/articles/8570304568591'

export const AUSTRALIAN_DOLLAR_PLACEHOLDER = 'Australian dollars'

export const errorMessageForInvalidFileTypeDoc = (name = '') =>
  `The file extension of ${name} is invalid, supported: pdf, doc, xlsx, png, jpg, jpeg`

export const errorMessageForInvalidFileTypeImage = (name = '') =>
  `The file extension of ${name} is invalid, supported: png, jpg, jpeg`

export const SUBTEXT_FILE_UPLOAD_ALL_FILES =
  '(max 10mb - pdf, doc, xlsx, png, jpg, jpeg)'

export const SUBTEXT_FILE_UPLOAD_IMAGE_FILES = '(max 10mb - png, jpg, jpeg)'

export const NOT_AVAILABLE_TEXT = 'Not Available'
