import React from 'react'

import { Link } from 'react-router-dom'

import { urlTo } from 'app/sm/helpers'

class Landing extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.setBackUrl(urlTo('landing'))
  }

  render() {
    return (
      <div className="sm-landing pt25">
        <h2 className="text-center gray-text">Welcome to snug</h2>
        <h5 className="text-center gray-text fw-escape mt15">
          Let's make renting better
        </h5>
        <div className="row mt25">
          <div className="col-xs-6">
            <h3 className="gray-text">Renters</h3>
            <p className="parole gray-text mt25">
              Elevate your renting experience by saving time and finding a
              property that's the perfect fit for you.
            </p>
            <div className="links">
              <Link to={urlTo('bondCoverApply')}>
                Apply for BondCover
                <i className="fa fa-play" aria-hidden="true" />
              </Link>
              <br />
              <Link to={'#'}>
                Apply for a property
                <i className="fa fa-play" aria-hidden="true" />
              </Link>
            </div>
          </div>
          <div className="col-xs-6">
            <h3 className="gray-text">Owners & Managers</h3>
            <p className="parole gray-text mt25">
              Use our innovating matching platform to find your Renter that's a
              great fit for your property
            </p>
            <div className="links">
              <Link to={urlTo('dashboard')}>
                Review BondCover applications
                <i className="fa fa-play" aria-hidden="true" />
              </Link>
              <br />
              <Link to={urlTo('propertyGetStarted')}>
                Let a property
                <i className="fa fa-play" aria-hidden="true" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Landing
