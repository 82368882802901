import translateErrorCodeToMessage from 'app/constants/error_messages'
import {
  getAdvanceTeamActivities,
  getPropertiesActivitiesReport,
  getTeamActivities,
} from 'app/dashboard/team_activity/team_activity_access'

export function fetchTeamActivities(teamGUID, options) {
  return (dispatch) => {
    return getTeamActivities(teamGUID, options).then(
      ({ ok, responseText, activities }) => {
        if (ok) {
          return Promise.resolve({ activities })
        } else {
          return responseText.then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export function fetchAdvanceTeamActivities(teamGUID, options) {
  return () => {
    return getAdvanceTeamActivities(teamGUID, options).then(
      ({ ok, responseText, advanceActivities }) => {
        if (ok) {
          return Promise.resolve({ advanceActivities })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function fetchPropertiesActivitiesReport(teamGUID, options) {
  return getPropertiesActivitiesReport(teamGUID, options)
}
