import React, { useEffect, useState } from 'react'

import { Formik } from 'formik'

import {
  Box,
  Flex,
  HorizontalLine,
  IconButton,
  LoadingSection,
} from 'app/components/design-system-components'
import { ClearRounded } from 'app/components/design-system-components/icons/content'
import {
  formFields,
  formValidation,
} from 'app/features/teams/applications/share-applications/ShareApplicationsModal/form.utils'
import { FormBody } from 'app/features/teams/applications/share-applications/ShareApplicationsModal/FormBody'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import { shareMultipleWithOwner } from 'app/services/http/team-applications'
import { getPropertyOwners } from 'app/services/http/teams/properties/owners'
import { getTeamBGCheckPrice } from 'app/services/http/teams/settings'
import { parseDataUtil } from 'app/sm/helpers'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { currencyFormat } from 'app/utils/numbers/helpers'

export const ShareApplicationsModal = ({
  teamId,
  property,
  applications,
  onSendSuccessfully,
  onDismissClick,
  currentTeam,
  applicationSettings = {},
}) => {
  const {
    state: basicData,
    setState: setBasicData,
    loadingStates: basicDataLoadingStates,
    promiseWrapper: basicDataPromiseWrapper,
  } = useStateWithLoading({
    owners: [],
    initialValues: null,
  })
  const [bgcPrice, setBgcPrice] = useState(0)

  const submitShare = (values, { setStatus }) => {
    const payload = {
      [formFields.recipients]: values[formFields.recipients].map(
        ({ option }) => option,
      ),
      [formFields.message]: values[formFields.message],
      [formFields.withOwnerStatus]: values[formFields.withOwnerStatus],
      [formFields.isOwnerAcceptEnabled]:
        values[formFields.isOwnerAcceptEnabled],
      [formFields.requestBGCApproval]: values[formFields.requestBGCApproval],
      applicationsIds: applications.map(({ guidID }) => guidID),
    }

    return shareMultipleWithOwner(teamId, payload)
      .then(() => onSendSuccessfully())
      .catch(({ message }) => setStatus({ apiGeneralError: message }))
  }

  useEffect(() => {
    loadBasicData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property, teamId])

  const loadBasicData = () => {
    const { share_app_with_owner_bgc_approval_enabled } =
      parseDataUtil.convertObjValueFromStringToBoolean(applicationSettings)
    if (share_app_with_owner_bgc_approval_enabled) {
      getTeamBGCheckPrice(teamId).then(({ displayPrice }) => {
        const { priceIncGST = '' } = displayPrice || {}
        setBgcPrice(currencyFormat(Number(priceIncGST), 2))
      })
    }
    const basicDataPromise = getPropertyOwners(teamId, property.guidID)
    return basicDataPromiseWrapper(basicDataPromise).then((response) => {
      const owners = response?.result || []
      return setBasicData({
        owners,
        initialValues: {
          [formFields.recipients]:
            owners.map(({ email }) => ({
              option: email,
              optionMeta: {
                id: email,
                displayText: email,
              },
            })) || [],
          [formFields.message]: '',
          [formFields.withOwnerStatus]: false,
          [formFields.isOwnerAcceptEnabled]: false,
          [formFields.requestBGCApproval]: false,
        },
      })
    })
  }

  const { enableBackgroundCheckBundle } = currentTeam || {}
  const showRequestBGCApproval = enableBackgroundCheckBundle
  const { share_app_with_owner_bgc_approval_enabled } =
    parseDataUtil.convertObjValueFromStringToBoolean(applicationSettings)
  const disableRequestBGCOption =
    !enableBackgroundCheckBundle || !share_app_with_owner_bgc_approval_enabled
  return (
    <Box className="modal-fullscreen" bg={theme.colors.overlay}>
      <ModalContentWrapper>
        <Flex width="100%" flex="1 1" overflowY="auto">
          <Box p={theme.space[7] + 'px'} width="100%">
            <>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb={theme.space[6] + 'px'}
              >
                <h3>Share Applications</h3>
                <IconButton
                  IconCmp={ClearRounded}
                  iconProps={{ fontSize: theme.fontSizes[10] + 'px' }}
                  variant="flat"
                  onClick={onDismissClick}
                />
              </Flex>
              <HorizontalLine />
            </>
            <Box pb={theme.space[5] + 'px'}>
              <LoadingSection
                loadingState={basicDataLoadingStates}
                actionHandler={() => loadBasicData()}
              >
                {!!basicData.initialValues && (
                  <Formik
                    initialValues={basicData.initialValues}
                    enableReinitialize
                    onSubmit={submitShare}
                    validate={formValidation}
                    validateOnBlur={true}
                    validateOnChange={true}
                  >
                    {(props) => (
                      <FormBody
                        formBag={props}
                        disableRequestBGCOption={disableRequestBGCOption}
                        teamSlug={currentTeam?.slug}
                        bgcPrice={bgcPrice}
                      />
                    )}
                  </Formik>
                )}
              </LoadingSection>
            </Box>
          </Box>
        </Flex>
      </ModalContentWrapper>
    </Box>
  )
}
