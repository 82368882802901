import React from 'react'

import { Box, Flex } from 'app/components/design-system-components'

const StandardContactInfocContainer = ({
  containerClass = '',
  mainTitle = '',
  firstLineText = '',
  secondLineText = '',
  thirdLineText = '',
  fourthLineText = '',
  linesText = [],
  mainTitleSupportiveText = '',
  statusIcon = '',
  dataTip = '',
  dataFor = '',
  badgeCmp,
}) => {
  return (
    <div className={`applicant-info-container ${containerClass}`}>
      <Flex>
        <h4
          className="applicant-name default-font-family pdf-font-size-10"
          data-tooltip-content={dataTip}
          data-tooltip-id={dataFor}
        >
          {mainTitle}
          <span>
            {mainTitleSupportiveText}
            {statusIcon && (
              <img src={statusIcon} className="ml5 pb3" alt="status" />
            )}
          </span>
        </h4>
        <Box ml={2}>{badgeCmp}</Box>
      </Flex>

      {firstLineText && (
        <div className="applicant-info-sub-container mt0 grey-body-text">
          <p className="applicant-info-text mb0 pdf-font-size-10">
            {firstLineText}
          </p>
        </div>
      )}
      {secondLineText && (
        <div className="applicant-info-sub-container mt0 grey-body-text">
          <p className="applicant-info-text mb0 pdf-font-size-10">
            {secondLineText}
          </p>
        </div>
      )}
      {thirdLineText && (
        <div className="applicant-info-sub-container mt0 grey-body-text">
          <p className="applicant-info-text mb0 pdf-font-size-10">
            {thirdLineText}
          </p>
        </div>
      )}
      {fourthLineText && (
        <div className="applicant-info-sub-container mt0 grey-body-text">
          <p className="applicant-info-text mb0 pdf-font-size-10">
            {fourthLineText}
          </p>
        </div>
      )}
      {linesText.map((line, index) => {
        if (!line) return
        return (
          <div
            key={index}
            className="applicant-info-sub-container mt0 grey-body-text"
          >
            <p className="applicant-info-text mb0 pdf-font-size-10">{line}</p>
          </div>
        )
      })}
    </div>
  )
}

export default StandardContactInfocContainer
