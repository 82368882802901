import React from 'react'

import { useSortBy, useTable } from 'react-table'
import styled from 'styled-components'

const DefaultStyledTable = styled.table`
  width: 100%;
  ${(props) => props.fontSize && `font-size: ${props.fontSize}`};
  ${(props) => props.textColor && `color: ${props.textColor}`};

  line-height: 1.2;

  padding-right: 8px;
  @media screen and (max-width: 576px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  thead:nth-child(1) {
    box-shadow: none;
    background-color: ${(props) =>
      props.headerBgColor || props.theme.colors.borderGray};
    color: ${(props) => props.theme.colors.grey600};
    border-radius: ${(props) => props.theme.borderRadii.table};
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSizes[4]}px;
  }
  th {
    padding: ${(props) => `${props.thPadding}`};
    border-right: none;
    ${(props) =>
      props.showHeaderBottomBorder &&
      `border-bottom: 1px solid ${props.theme.colors.grey200}`};
  }
  td:nth-child(1) {
    border-right: none;
  }
  .bold-text {
    color: ${(props) => props.theme.colors.gray900};
    font-weight: bold;
  }
  .large-text {
    font-size: ${(props) => props.theme.fontSizes[5]}px;
  }
  tbody {
    position: relative;
    td {
      padding: ${(props) => props.cellPadding};
      ${(props) => props.verticalAlignTop && `vertical-align: top;`};
    }
    tr.is-highlighted {
      background-color: ${(props) => props.theme.colors.paleBlue};
    }
    tr:hover {
      ${(props) =>
        props.showShadowOnHover &&
        `box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);`};
    }
    tr {
      ${(props) =>
        props.showRowBottomBorder &&
        `border-bottom: 1px solid ${props.theme.colors.grey200}`};
    }
  }
  tfoot {
    td {
      padding: ${(props) => props.cellPadding};
      ${(props) => props.verticalAlignTop && `vertical-align: top;`};
    }
  }
`

const defaultPropGetter = () => ({})

const TableComponent = ({
  styledTable = <DefaultStyledTable />,
  dataToBeEntered,
  columns,
  getCustomHeaderProps = defaultPropGetter,
  getCustomRowProps = defaultPropGetter,
  getCustomColumnProps = defaultPropGetter,
  getCustomCellProps = defaultPropGetter,
  textColor,
  headerBgColor,
  fontSize,
  // todo: discuss this change with Akram and Rishabh
  cellPadding = '24px 16px',
  thPadding = '8px 16px',
  onMouseEnter = undefined,
  onMouseLeave = undefined,
  showExtraInfo = false,
  extraInfo = undefined,
  extraInfoRow = undefined,
  showHeaderBottomBorder = false,
  showRowBottomBorder = false,
  verticalAlignTop = false,
  showShadowOnHover = false,
  maxRowHeight = 'unset',
  sortable = false,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data: dataToBeEntered,
    },
    useSortBy,
  )

  const getColHeaderProps = (col) =>
    sortable
      ? col.getSortByToggleProps()
      : [
          {
            className: col.className,
            style: col.style,
          },
          getCustomColumnProps(col),
          getCustomHeaderProps(col),
        ]

  const tableCore = (
    <>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(getColHeaderProps(column))}>
                {column.render('Header')}{' '}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <span>&#8595;</span>
                  ) : (
                    <span>&#8593;</span>
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <>
              <tr
                {...row.getRowProps(getCustomRowProps(row))}
                className={
                  row.original.isRowHighlighted ? 'is-highlighted' : ''
                }
                {...row.getRowProps()}
                {...(showExtraInfo
                  ? {
                      onMouseEnter: (event) => onMouseEnter(event, row),
                      onMouseLeave: (event) => onMouseLeave(event, row),
                    }
                  : {})}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getCustomColumnProps(cell.column),
                        getCustomCellProps(cell),
                      ])}
                    >
                      {cell.render('Cell', {
                        isRowHovered:
                          showExtraInfo && extraInfoRow === row.index,
                      })}
                    </td>
                  )
                })}
              </tr>

              {showExtraInfo && extraInfoRow === row.index && (
                <div
                  onMouseEnter={(event) => onMouseEnter(event, row)}
                  onMouseLeave={(event) => onMouseLeave(event, row)}
                >
                  {extraInfo}
                </div>
              )}
            </>
          )
        })}
      </tbody>
      <tfoot>
        {footerGroups.map((group) => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map((column) => (
              <td {...column.getFooterProps()}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>
    </>
  )

  return (
    <>
      {React.cloneElement(
        styledTable,
        {
          ...getTableProps(),
          textColor,
          headerBgColor,
          fontSize,
          cellPadding,
          thPadding,
          showHeaderBottomBorder,
          showRowBottomBorder,
          verticalAlignTop,
          showShadowOnHover,
          maxRowHeight,
        },
        tableCore,
      )}
    </>
  )
}

export default TableComponent
