import React from 'react'

import { Modal } from 'app/components/design-system-components'
import { TeamActivationMessage } from 'app/utils/teamActivation'

export const InactiveTeamModal = ({ closeModal }) => {
  return (
    <Modal
      modalHeading="Activate team"
      showSecondaryButton={false}
      primaryLabel="OK"
      primaryAction={closeModal}
      toggleModal={closeModal}
      standardWidth={true}
      modalBody={<TeamActivationMessage />}
    />
  )
}
