import { connect } from 'react-redux'

import AttendeeViewingConfirmation from 'app/match/viewings/attendee_viewing_confirmation/attendee_viewing_confirmation'
import {
  fetchPropertyInfoByViewingId,
  fetchRegistrantInfoFromShortcode,
  unregisterForEnquiryViewing,
  updateEquiryViewingRegistrant,
  viewingAttendeeConfirmation,
} from 'app/match/viewings/enquiry_viewing_register/enquiry_viewing_register_actions'
import { fetchPropertyViewings } from 'app/sm/inspections/inspections_actions'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  fetchPropertyInfoByViewingId: (viewingId) =>
    dispatch(fetchPropertyInfoByViewingId(viewingId)),
  fetchPropertyViewings: (params) => dispatch(fetchPropertyViewings(params)),
  viewingAttendeeConfirmation: (payload, viewingId, attendeeGUID) =>
    dispatch(viewingAttendeeConfirmation(payload, viewingId, attendeeGUID)),
  updateEquiryViewingRegistrant: (data, registrantID) =>
    dispatch(updateEquiryViewingRegistrant(data, registrantID)),
  unregisterForEnquiryViewing: (registrantID) =>
    dispatch(unregisterForEnquiryViewing(registrantID)),
  fetchRegistrantInfoFromShortcode: (shortcode) =>
    dispatch(fetchRegistrantInfoFromShortcode(shortcode)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttendeeViewingConfirmation)
