import moment from 'moment'

import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  acceptDeclineApplication,
  assignApplication,
  fetchAssignedTeamMember,
  getApplications,
  moveApplication,
} from 'app/sm/applications/applications_access'
import {
  removeFavorite,
  saveFavorite,
} from 'app/sm/property_details/property_details_access'

export const RECEIVE_APPLICATIONS = 'APPS_RECEIVE_APPLICATIONS'
export const ON_SORTER_DROPDOWN_CHANGE = 'APPS_ON_SORTER_DROPDOWN_CHANGE'
export const TOGGLE_FAVORITE_MARK = 'APPS_TOGGLE_FAVORITE_MARK'
export const TOGGLE_SPINNER = 'APPS_TOGGLE_SPINNER'

// Action Creators
const receiveApplications = (applications) => ({
  type: RECEIVE_APPLICATIONS,
  applications,
})
const toggleSpinner = (spinner) => ({ type: TOGGLE_SPINNER, spinner })
// Methods

const sortByRank = (applications) => {
  return applications.sort((a, b) => (a.score > b.score ? -1 : 1))
}
const sortByLeaseStartDate = (applications) => {
  return applications.sort((a, b) =>
    moment(a.leaseStartDate).isAfter(b.leaseStartDate) ? 1 : -1,
  )
}
const sortByWeeklyRent = (applications) => {
  return applications.sort((a, b) => (a.weeklyRent < b.weeklyRent ? -1 : 1))
}

export function fetchApplications(savedApplications = false) {
  return (dispatch) => {
    if (!savedApplications) {
      dispatch(toggleSpinner(true))
    }

    getApplications().then(({ applications, ok, responseText, statusCode }) => {
      if (ok) {
        dispatch(toggleSpinner(false))
        dispatch(receiveApplications(sortByRank(applications)))
      } else {
        dispatch(toggleSpinner(false))
      }
    })
  }
}

export function onSorterDropdownChange(sorter) {
  return (dispatch) => {
    dispatch({ type: ON_SORTER_DROPDOWN_CHANGE, sorter })
    dispatch(sortApplications(sorter))
  }
}

function sortApplications(sorter) {
  return (dispatch, getState) => {
    const { applications } = getState().MyApplications

    switch (sorter) {
      case 'rank':
        dispatch(receiveApplications(sortByRank(applications)))
        break
      case 'lease-start-date':
        dispatch(receiveApplications(sortByLeaseStartDate(applications)))
        break
      case 'weekly-rent':
        dispatch(receiveApplications(sortByWeeklyRent(applications)))
        break
      default:
        return applications
    }
  }
}

export function addToSavedProperty(propertyId) {
  return (dispatch, getState) => {
    const isFavourite = getState().MyApplications.applications.find(
      (a) => a.offer.property.guidID === propertyId,
    ).offer.property.isFavourite
    if (!isFavourite) {
      saveFavorite(propertyId).then(({ ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(fetchApplications(true))
        }
      })
    } else {
      removeFavorite(propertyId).then(({ ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(fetchApplications(true))
        }
      })
    }
  }
}

export const assignApplicationToTeamMember = (
  teamId,
  applicationID,
  teamMemberID,
) => {
  return (dispatch) => {
    return assignApplication(teamId, applicationID, teamMemberID).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function fetchApplicationTeamMember(teamGUID, applicationGUID) {
  return (dispatch) => {
    return fetchAssignedTeamMember(teamGUID, applicationGUID).then(
      ({ ok, teamMemberInfo, responseText }) => {
        if (ok) {
          return Promise.resolve(teamMemberInfo)
        } else {
          responseText.then((t) => {})
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const AcceptDeclineApplication = (shortcode, req) => {
  return (dispatch) => {
    return acceptDeclineApplication(shortcode, req).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function moveApplicationToProperty(teamGUID, applicationGUID, payload) {
  return () => {
    return moveApplication(teamGUID, applicationGUID, payload).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}
