import React from 'react'

import 'app/sm/progress_application/address_header/style.scss'
import HeaderLg from 'app/sm/progress_application/components/header_lg/component'
import SectionContainer from 'app/sm/progress_application/components/section_container/component'

const AddressHeader = ({
  friendlyName = '',
  bedrooms = 0,
  carports = 0,
  weeklyRent = 0,
  availableFrom = '',
}) => {
  return (
    <SectionContainer>
      <HeaderLg title={friendlyName} />
      <div className="display-flex property-info">
        <div className="mr15">
          <i className="icon-bedroom mr5"></i>
          <span>{bedrooms}</span>
        </div>
        <div className="mr15">
          <i className="icon-cars mr5"></i>
          <span>{carports}</span>
        </div>
        <div className="mr15">
          <i className="icon-rent mr5"></i>
          <span>{weeklyRent}/w</span>
        </div>
        <div className="mr15">
          <i className="icon-calendar mr5"></i>
          <span>{availableFrom}</span>
        </div>
      </div>
    </SectionContainer>
  )
}

export default AddressHeader
