import React from 'react'

import AvatarPlaceholder from 'app/sm/common/avatar_placeholder'
import ReviewStars from 'app/sm/common/review-stars'
import { NO_IMAGE_PLACEHOLDER_URL } from 'app/sm/helpers'

function renderProperty(parole, payload) {
  let imageUrl = NO_IMAGE_PLACEHOLDER_URL
  let address = ''
  let suburb = ''

  if (payload.images && payload.images.length > 0) {
    imageUrl = payload.images.filter((image) => image.isMain === true)[0].URL
  }

  if (payload.address) {
    suburb = `${payload.address.suburb} ${payload.address.state} ${payload.address.postcode}`
    address = `${payload.address.streetNumber} ${payload.address.streetName}`
  }

  return (
    <div className="rating-widget">
      <h3 className="ibm wa pl0">{parole}</h3>
      <div className="row review-star-wrapper mt25">
        <div className="col-xs-12">
          <ReviewStars onRateChange={(rate) => this.onRateChange(rate)} />
        </div>
      </div>
      <div className="input-box">
        <input
          required
          onChange={(e) => this.onInputChange('title', e.target.value)}
          type="text"
        />
        <label>Review Title</label>
      </div>
      <div className="input-box">
        <textarea
          className="review-box"
          onChange={(e) => this.onInputChange('description', e.target.value)}
          placeholder="Write something..."
        ></textarea>
      </div>
      <div className="col-xs-12 p0">
        <input
          onChange={(e) => this.onCheckboxChange('isPrivate')}
          id="rating-widget-private-review"
          type="checkbox"
          name="isPrivate"
        />
        <span className="xs-fs16">
          Private review only (only agent will see)
        </span>
      </div>
    </div>
  )
}

function renderAgent(parole, payload) {
  let address = ''
  let suburb = ''

  if (payload.address) {
    suburb = payload.address.suburb
    address = `${payload.address.address1} ${payload.address.address2}`
  }

  return (
    <div className="rating-widget">
      <h3 className="mb30">{parole}</h3>
      <div className="row review-star-wrapper mt25">
        <div className="col-xs-12">
          <ReviewStars onRateChange={(rate) => this.onRateChange(rate)} />
        </div>
      </div>
      <div className="input-box">
        <input
          required
          onChange={(e) => this.onInputChange('title', e.target.value)}
          type="text"
        />
        <label>Review Title</label>
      </div>
      <div className="input-box">
        <textarea
          className="review-box"
          onChange={(e) => this.onInputChange('description', e.target.value)}
          placeholder="Write something..."
        ></textarea>
      </div>
      <div className="col-xs-12 p0">
        <input
          onChange={(e) => this.onCheckboxChange('isPrivate')}
          id="rating-widget-private-review"
          type="checkbox"
          name="isPrivate"
        />
        <span className="xs-fs16 mb40">
          Private review only (only agent will see)
        </span>
      </div>
    </div>
  )
}

class RatingWidget extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  onCheckboxChange(key) {
    const { role } = this.props

    this.props.somethingChanged('checkbox', role, key)
  }

  onInputChange(key, value) {
    const { role } = this.props

    this.props.somethingChanged('input', role, key, value)
  }

  onRateChange(rate) {
    const { role } = this.props

    this.props.somethingChanged('rate', role, 'score', rate)
  }

  render() {
    const { role, parole, payload } = this.props

    if (role === 'property') {
      return renderProperty.call(this, parole, payload)
    }

    return renderAgent.call(this, parole, payload)
  }
}

export default RatingWidget
