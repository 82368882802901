import React from 'react'

import { Link } from 'react-router-dom'

import headerImg01 from 'app/assets/icons/header-img-01.png'
import usersAvatars from 'app/assets/icons/users-avatars.png'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'

class RenterComplete extends React.Component {
  UNSAFE_componentWillMount() {
    let {
      fetchRequestSummary,
      getSecondaryTenantSummary,
      getBondCoverCompleteSummary,
    } = this.props
    let role = getParameter('role')
    let requestID = getParameter('requestid')
    if (role === 'secondary') {
      getSecondaryTenantSummary(requestID)
    } else {
      fetchRequestSummary(requestID)
    }
    getBondCoverCompleteSummary(requestID)
  }

  render() {
    let { currentUser, quote, bondCoverRequest } = this.props
    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <div className="ilustration-box">
            <img src={headerImg01} />
          </div>

          <h3 className="mb50">Congratulations! </h3>

          <p>
            {' '}
            Your application for a refund of{' '}
            {quote && quote.guidID
              ? '$' + (quote.bondAmount - quote.premium.toFixed(2)).toFixed(2)
              : ''}{' '}
            has been successful and will be submitted to your rental bond
            authority.
          </p>

          <div className="bc-app-id">
            <h4>
              Application ID: {bondCoverRequest && bondCoverRequest.appCode}
            </h4>
          </div>

          <p className="mb50">
            You will receive a copy of the Snug BondCover Certificate and
            supporting documents in your email:{' '}
            <b>{currentUser && currentUser.email}</b>
          </p>

          {/* PDFs - Waiting for PDF generation from the backend  */}
          {/*
          <tr>
            <td className='bond-back-cell'>

              <a href={ bondCoverCompleteSummary && bondCoverCompleteSummary.bcCertURL }>
                <div className="table">
                  <div className="cell-m pr20">
                    <i className="fa fa-file-pdf-o pink-color fs48" ></i>
                  </div>
                  <div className="cell-m-100">
                    <h4>BondCover Certificate</h4>
                    <small className="gray-light-color"> { bondCoverCompleteSummary && moment(bondCoverCompleteSummary.bcCertStartDate).format('DD MMM YYYY') } - { bondCoverCompleteSummary && moment(bondCoverCompleteSummary.bcCertEndDate).format('DD MMM YYYY') } </small>
                  </div>
                  <div className="cell-m pl10">
                    <i className="fa fa-angle-right gray-dark-color fs32"></i>
                  </div>
                </div>
              </a>

              <hr />

              <a href={ bondCoverCompleteSummary && bondCoverCompleteSummary.productURL }>
                <div className="table">
                  <div className="cell-m pr20">
                    <i className="fa fa-file-pdf-o pink-color fs48" ></i>
                  </div>
                  <div className="cell-m-100">
                    <h4>PDS</h4>
                  </div>
                  <div className="cell-m pl10">
                    <i className="fa fa-angle-right gray-dark-color fs32"></i>
                  </div>
                </div>
              </a>

            </td>
          </tr> */}

          <div className="bc-social-share">
            <img src={usersAvatars} />
          </div>

          {/* Commented out for future use  */}
          {/* <h3 className="mb15">What's Next?</h3>

          <p>Share your plans on what you want to do with your bond!</p>

          <div className="bc-social-share">
            <img src={usersAvatars} />
            <h1>#bondcover</h1>
            <p>Spread the word, tell your friends about BondCover and help make renting more affordable.</p>
            <a className="btn btn-facebook" href="https://www.facebook.com/sharer/sharer.php?u=https%3A//snug.com">
              <i className="icon-facebook"></i>
              <span>Share on Facebook</span>
            </a>
            <a className="btn btn-twitter" href="https://twitter.com">
              <i className="icon-twitter"></i>
              <span>Share on Twitter</span>
            </a>
          </div>  */}
        </div>
      </div>
    )
  }
}

export default RenterComplete
