import React from 'react'

import { connect } from 'react-redux'

import {
  Alert,
  Box,
  Button,
  Flex,
  HorizontalLine,
} from 'app/components/design-system-components'
import { Heading } from 'app/components/design-system-components/typography'
import { keySetTypesOptions } from 'app/constants/key-logger.constants'
import { extractBorrowingInfo } from 'app/features/teams/key-logger/CheckInKeySetModal/utils'
import { StepLayoutContainer } from 'app/features/teams/key-logger/components/StepLayoutContainer'
import { SuccessModalSimpleDescriptionList } from 'app/features/teams/key-logger/components/SuccessModal'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as keySetsHttp from 'app/services/http/teams/key-logger'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

const PureConfirmation = ({ teamId, checkInData, afterSave }) => {
  const {
    loadingStates: checkInLoadingStates,
    loadingStatesHelpers: checkInLoadingStatesHelpers,
  } = useLoadingStates()

  const onCheckIn = () => {
    checkInLoadingStatesHelpers.startLoading()
    const payload = {
      notes: checkInData.notes,
    }
    keySetsHttp
      .checkInKeySet(teamId, checkInData.keySet.guid, payload)
      .then(() => {
        checkInLoadingStatesHelpers.markDoneSuccessfully()
        afterSave(checkInData.keySet.guid)
      })
      .catch((err) => checkInLoadingStatesHelpers.setError(err))
  }

  if (!checkInData) return null

  const { property, keySet } = checkInData
  const borrowingInfo = extractBorrowingInfo(keySet.borrowing_data)

  const descriptionConfig = [
    { title: 'Property Address', description: property.address.friendlyName },
    { title: 'Key set', description: keySet.label },
    { title: 'Key set type', description: keySetTypesOptions[keySet.set_type] },
    { title: 'Borrower', description: borrowingInfo?.borrower || 'N/A' },
    { title: 'Checked out', description: borrowingInfo?.checkOutDate || 'N/A' },
  ]

  const checkInDescriptionElem = (
    <SuccessModalSimpleDescriptionList
      listConfig={{ listData: descriptionConfig }}
    />
  )

  const actionsElem = (
    <Button
      type="submit"
      disabled={checkInLoadingStates.state === loadingStatesIds.LOADING}
      width="100%"
      onClick={onCheckIn}
    >
      Check in
    </Button>
  )

  return (
    <StepLayoutContainer actions={actionsElem}>
      <Flex flexDirection="column" justifyContent="space-between">
        <Box p="0 33px 74px">
          <Flex
            flexDirection="column"
            alignItems="center"
            m={theme.space[4] + 'px 0'}
          >
            <Heading level={4} displayingLevel={4}>
              Confirmation check in of
            </Heading>
          </Flex>
          <HorizontalLine mt={theme.space[8]} />
          {checkInDescriptionElem}
          <HorizontalLine />
          {checkInLoadingStates.state === loadingStatesIds.ERROR && (
            <Alert variant="warningWithBg" mt={theme.space[3] + 'px'}>
              {checkInLoadingStates.error.message}
            </Alert>
          )}
        </Box>
      </Flex>
    </StepLayoutContainer>
  )
}

const mapStateToProps = ({ session }) => ({ teamId: session.currentTeam.guid })
export const Confirmation = connect(mapStateToProps)(PureConfirmation)
