import { useState } from 'react'

import { Field, Form } from 'formik'
import styled from 'styled-components'

import {
  formFields,
  getFieldLabel,
} from 'app/components/Ballot/Disclosures/form.utils'
import BallotHouseholdIncomeModal from 'app/components/Ballot/Modals/BallotHouseholdIncomeModal'
import { Box } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import { CheckboxInput } from 'app/features/teams/applications/share-applications/ShareApplicationsModal/FormBody'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledFieldWrapper = styled(FieldWrapper)`
  line-height: ${theme.lineHeights.encrption};
  margin-bottom: ${theme.space[3]}px;
`

const StyledDisclosureLabel = styled(Text)`
  margin-bottom: 0;
`

export type BallotsHouseholdIncomeLimit = {
  income: number
  rentCap: number
  type: string
}[]

export const FormBody = ({
  ballotsHouseholdIncomeLimit,
  ballotsHouseholdIncomeRegion,
}: {
  ballotsHouseholdIncomeLimit: BallotsHouseholdIncomeLimit
  ballotsHouseholdIncomeRegion: string
}) => {
  const [
    isBallotHouseHoldModalIncomeOpened,
    setIsBallotHouseHoldModalIncomeOpened,
  ] = useState<boolean>(false)

  const toggleBallotHouseHoldModalIncome = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.preventDefault()
    setIsBallotHouseHoldModalIncomeOpened(!isBallotHouseHoldModalIncomeOpened)
  }

  const _renderDisclosureCheckbox = (disclosureKey: string) => {
    return (
      <StyledFieldWrapper id={disclosureKey} name={disclosureKey}>
        <Field
          name={disclosureKey}
          as={CheckboxInput}
          label={
            <StyledDisclosureLabel>
              {getFieldLabel(disclosureKey)}{' '}
            </StyledDisclosureLabel>
          }
        />
      </StyledFieldWrapper>
    )
  }

  return (
    <Box mt={4}>
      <Form>
        {_renderDisclosureCheckbox(formFields.acceptBallotTermsAndConditions)}

        {_renderDisclosureCheckbox(
          formFields.victorianResidentsOrAustralianCitizens,
        )}

        {_renderDisclosureCheckbox(formFields.householdMeetsIncomeLimits)}

        {_renderDisclosureCheckbox(formFields.noHouseholdMembersOwnProperty)}

        {_renderDisclosureCheckbox(formFields.documentsWithinDays)}

        {_renderDisclosureCheckbox(formFields.householdCanMoveIn)}

        <Box mb={0} textAlign="center">
          Table 1: Eligible gross income thresholds
        </Box>
        <BallotHouseholdIncomeModal
          ballotsHouseholdIncomeLimit={ballotsHouseholdIncomeLimit}
          toggleBallotHouseHoldModalIncome={toggleBallotHouseHoldModalIncome}
          ballotsHouseholdIncomeRegion={ballotsHouseholdIncomeRegion}
          returnAsComp={true}
        />
      </Form>
    </Box>
  )
}
