import React from 'react'

import styled from 'styled-components'
import { layout } from 'styled-system'

import Box from 'app/components/design-system-components/Box'
import { IconButton } from 'app/components/design-system-components/Button'
import { ClearRounded } from 'app/components/design-system-components/icons/content'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const StyledTooltipWrapper = styled(Box)`
  position: absolute;
  z-index: 20;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
  width: max-content;
  max-width: 350px;
  min-width: 250px;
  ${(props) => !props.openRight && `right: 0`};
  white-space: normal;
  line-height: ${(props) => props.theme.lineHeights.encrption};

  ${layout}
`

export const TooltipLikeModal = ({ onDismiss, children, ...props }) => {
  return (
    <StyledTooltipWrapper bg={theme.colors.white} {...props}>
      <IconButton
        IconCmp={ClearRounded}
        variant="flat"
        fontSize={10}
        onClick={onDismiss}
        style={{ float: 'right' }}
      />
      {children}
    </StyledTooltipWrapper>
  )
}
