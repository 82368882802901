import { formatNumber, parseNumber } from 'libphonenumber-js'

import isEmptyObject from 'app/shared_components/check_empty_object'
import { apiFailCallback } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import {
  alterEmployer,
  createEmployer,
  deleteEmployer,
  disprove,
  getEmployee,
  getEmployeeDetail,
  getEmployer,
  getEmployers,
  verify,
} from 'app/sm/employment/access'
import { sortHistory, urlTo } from 'app/sm/helpers'

export const RECEIVE_EMPLOYERS = 'RECEIVE_EMPLOYERS'
export const RECEIVE_EMPLOYER = 'RECEIVE_EMPLOYER'
export const RECEIVE_EMPLOYEE = 'RECEIVE_EMPLOYEE'
export const RECEIVE_RESPONSE_TEXT = 'RECEIVE_RESPONSE_TEXT'
export const CLICK_SAVE_BUTTON = 'CLICK_SAVE_BUTTON'

const receiveEmployers = (employers) => ({
  type: RECEIVE_EMPLOYERS,
  employers,
})

const receiveEmployer = (employer) => ({
  type: RECEIVE_EMPLOYER,
  employer,
})

const receiveEmployee = (employee) => ({
  type: RECEIVE_EMPLOYEE,
  employee,
})

function formatEmployerPhoneNumber(employer) {
  let parsedNumber = employer.phoneNumber
    ? parseNumber(employer.phoneNumber)
    : ''
  let phoneNumber = employer.phoneNumber
  if (isEmptyObject(parsedNumber)) {
    parsedNumber = parseNumber(employer.phoneNumber, 'AU')
    phoneNumber = formatNumber(parsedNumber.phone, 'AU', 'International')
  }
  return {
    ...employer,
    phoneNumber,
  }
}

export function fetchEmployers() {
  return (dispatch) => {
    getEmployers().then(({ ok, employers = [], responseText }) => {
      if (ok) {
        const { current, previous } = sortHistory(employers, 'employment')
        dispatch(receiveEmployers(current.concat(previous)))
      }
    })
  }
}

export function fetchEmployer(id) {
  return (dispatch) => {
    getEmployer(id).then(({ ok, employer, responseText }) => {
      if (ok) {
        dispatch(receiveEmployer(formatEmployerPhoneNumber(employer)))
      }
    })
  }
}

export function addEmployer(payload, success, fail) {
  return (dispatch) => {
    createEmployer(payload).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(fetchEmployers())
        if (success) {
          success()
        }
        history.goBack()
      } else {
        apiFailCallback(responseText, fail)
      }
    })
  }
}

export function updateEmployer(payload, id, success, fail) {
  return (dispatch, getState) => {
    alterEmployer(payload, id).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(fetchEmployers())
        if (success) {
          success()
        }
        history.goBack()
      } else {
        apiFailCallback(responseText, fail)
      }
    })
  }
}

export function fetchEmployee(
  confirmationRequestID,
  confirmationRequestSlug,
  isFromManager,
  success,
  fail,
) {
  return (dispatch) => {
    getEmployee(
      confirmationRequestID,
      confirmationRequestSlug,
      isFromManager,
    ).then(({ ok, employee, responseText }) => {
      if (ok) {
        dispatch(receiveEmployee(employee))
        if (success) {
          success()
        }
      } else {
        apiFailCallback(responseText, fail)
      }
    })
  }
}

export function verifyEmployee(
  payload,
  id,
  slug,
  success,
  fail,
  fromManager = false,
) {
  return () => {
    verify(payload, id, slug).then(({ ok, responseText }) => {
      if (ok) {
        if (fromManager) {
          history.goBack()
        } else {
          if (id) {
            history.push(urlTo('EmploymentVerifyConfirmation', { guidID: id }))
          } else if (slug) {
            history.push(
              urlTo('EmploymentSlugVerifyConfirmation', { slug: slug }),
            )
          }
        }

        if (success) {
          success()
        }
      } else {
        apiFailCallback(responseText, fail)
      }
    })
  }
}

export function disproveEmployee(payload, id, slug, success, fail) {
  return (dispatch) => {
    disprove(payload, id, slug).then(({ ok, employee, responseText }) => {
      if (ok) {
        if (id) {
          history.push(urlTo('EmploymentVerifyConfirmation', { guidID: id }))
        } else if (slug) {
          history.push(
            urlTo('EmploymentSlugVerifyConfirmation', { slug: slug }),
          )
        }
        if (success) {
          success()
        }
      } else {
        apiFailCallback(responseText, fail)
      }
    })
  }
}
export function removeEmployer(
  id,
  fromEmploymentDetails = false,
  success,
  fail,
) {
  return (dispatch) => {
    deleteEmployer(id).then(({ ok, responseText }) => {
      if (ok) {
        if (fromEmploymentDetails) {
          history.goBack()
        }

        dispatch(fetchEmployers())
        if (success) {
          success()
        }
      } else {
        apiFailCallback(responseText, fail)
      }
    })
  }
}

export function fetchEmployeeDetail(id, slug) {
  return (dispatch) => {
    getEmployeeDetail(id, slug).then(({ ok, employee, responseText }) => {
      if (ok) {
        dispatch(receiveEmployee(employee))
      }
    })
  }
}
