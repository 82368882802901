import React from 'react'

import $ from 'jquery'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { LoadingSection } from 'app/components/design-system-components'
import { TextArea } from 'app/components/design-system-components/inputs'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import AddressForm from 'app/shared_components/address_form/address_form'
import { urlTo, validateAddress } from 'app/sm/helpers'
import PropertyListingAgent from 'app/sm/onlist_details/property_listing_agent_container'
import Breadcrumbs from 'app/sm/ppp/breadcrumbs'
import { isCurrentRouteFrom } from 'app/sm/router_helpers'

class Basics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      listingAgentId: null,
    }
  }
  componentDidMount() {
    $('.app-content').scrollTop(0)
    this.setState({ listingAgentId: this.props.managingAgentId })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.managingAgentId !== prevProps.managingAgentId) {
      this.setState({ listingAgentId: this.props.managingAgentId })
    }
  }

  async onManagingAgentIdChanged(newManager, callback) {
    this.setState({ listingAgentId: newManager })
    await this.props.onManagingAgentUpdated?.()
    callback()
  }

  render() {
    const {
      ad,
      state,
      params,
      history,
      attributes,
      error,
      updateNumber,
      updateDescriptionText,
      updateAddress,
      updateGoogleAddress,
      resetAddress,
      updateProperty,
      createProperty,
      addressValidation,
      updatePropertyClicked,
      onBackClicked,
      showBreadcrumbs = true,
      managingAgentLoadingStates,
    } = this.props
    const { listingAgentId } = this.state
    let {
      address,
      bedrooms,
      bathrooms,
      parking,
      carports,
      garages,
      type,
      landSize,
      floorspace,
      description,
    } = state
    let propertyTypes = attributes.propertyTypes || []

    let { id } = params

    let submit =
      id === undefined
        ? createProperty
        : updateProperty(
            id,
            isCurrentRouteFrom('Profile')
              ? urlTo('profilePhotos', { propertyId: id })
              : urlTo('updatePropertyPhotos', { id }),
          )

    const disabled =
      !addressValidation.ok ||
      !validateAddress(address).ok ||
      updatePropertyClicked

    const { currentTeam } = this.props
    const teamSlug = currentTeam && currentTeam.slug

    const defaultOnBackClicked = () =>
      history.push(urlTo('propertyGetStarted', { teamSlug }))

    const backHandler = onBackClicked || defaultOnBackClicked

    const replaceBrWithNewline = (text) => {
      return text.replace(/<br\s*[/]?>/gi, '\n')
    }

    return (
      <div className="sm-property-create basic">
        {!isCurrentRouteFrom('Profile') && !!showBreadcrumbs && (
          <Breadcrumbs activeBreadCrumb={'property-basics'} />
        )}
        <div className="mobile-only">
          <a
            onClick={backHandler}
            className="btn btn-transparent btn-left xs-text-center wa"
            href="/#"
          >
            <i className="icon-arrow-left left"></i>
            <span>Back</span>
          </a>
        </div>
        <div className="section-title">
          <div className="left column">
            <h3>Property details</h3>
            <p className="gray-color mt10 mb0">
              Enter your property details to enjoy the full benefits of the Snug
              rental community.
            </p>
          </div>
          {/*<div className="right"></div>*/}
        </div>
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <div className="row">
              <div className="col-sm-12 pb10">
                <AddressForm
                  address={address}
                  updateAddress={updateAddress}
                  updateGoogleAddress={updateGoogleAddress}
                  resetAddress={resetAddress()}
                  placeholder="Enter rental address"
                  error={!addressValidation.ok}
                  country={['au']}
                />
              </div>
            </div>
            <div
              className={
                !addressValidation.ok ? 'alert alert-danger' : 'hide-alert'
              }
            >
              <div>{addressValidation.error}</div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="input-box">
                  <div className="prefix">
                    <i className="icon-bedroom"></i>
                  </div>
                  <select
                    required
                    value={bedrooms || ''}
                    onChange={updateNumber('bedrooms')}
                  >
                    <option className="blank"></option>
                    {['1', '2', '3', '4', '5', '6', '7', '8', '9', '9+'].map(
                      (state) => (
                        <option key={state}>{state}</option>
                      ),
                    )}
                  </select>
                  <label>No. of Bedrooms</label>
                  <i
                    className="icon-help-outline"
                    data-tooltip-id="property-tooltip"
                    data-tooltip-content="Please select number of bedrooms in property"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-box">
                  <div className="prefix">
                    <i className="icon-bathroom"></i>
                  </div>
                  {/* <input
                    required
                    value={bathrooms || ''}
                    onChange={updateNumber('bathrooms')}
                  />
                  <label>No. of Bathrooms</label>
                  <i
                    className="icon-help-outline"
                    data-tooltip-id="property-tooltip"
                    data-tooltip-content="Please enter number of bathrooms in property"
                  /> */}
                  <select
                    required
                    value={bathrooms || ''}
                    onChange={updateNumber('bathrooms')}
                  >
                    <option className="blank"></option>
                    {['1', '2', '3', '4', '5', '6', '7', '8', '9', '9+'].map(
                      (state) => (
                        <option key={state}>{state}</option>
                      ),
                    )}
                  </select>
                  <label>No. of Bathrooms</label>
                  <i
                    className="icon-help-outline"
                    data-tooltip-id="property-tooltip"
                    data-tooltip-content="Please select number of bathrooms in the property"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="input-box">
                  <div className="prefix">
                    <i className="icon-cars"></i>
                  </div>
                  <select
                    required
                    value={parking || ''}
                    onChange={updateNumber('parking')}
                  >
                    <option className="blank"></option>
                    {['1', '2', '3', '4', '5', '6', '7', '8', '9', '9+'].map(
                      (state) => (
                        <option key={state}>{state}</option>
                      ),
                    )}
                  </select>
                  <label>Parking spots</label>
                  <i
                    className="icon-help-outline"
                    data-tooltip-id="property-tooltip"
                    data-tooltip-content="Please select number of parking spots, if any"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-box">
                  <div className="prefix">
                    <i className="icon-cars"></i>
                  </div>
                  <select
                    required
                    value={carports || ''}
                    onChange={updateNumber('carports')}
                  >
                    <option className="blank"></option>
                    {['1', '2', '3', '4', '5', '6', '7', '8', '9', '9+'].map(
                      (state) => (
                        <option key={state}>{state}</option>
                      ),
                    )}
                  </select>
                  <label>Carports</label>
                  <i
                    className="icon-help-outline"
                    data-tooltip-id="property-tooltip"
                    data-tooltip-content="Please select number of carports in property, if any"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-box">
                  <div className="prefix">
                    <i className="icon-cars"></i>
                  </div>
                  <select
                    required
                    value={garages || ''}
                    onChange={updateNumber('garages')}
                  >
                    <option className="blank"></option>
                    {['1', '2', '3', '4', '5', '6', '7', '8', '9', '9+'].map(
                      (state) => (
                        <option key={state}>{state}</option>
                      ),
                    )}
                  </select>
                  <label>Garages</label>
                  <i
                    className="icon-help-outline"
                    data-tooltip-id="property-tooltip"
                    data-tooltip-content="Please select number of garages in property, if any"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 pb10">
                <Text
                  mb={`${theme.space[2]}px`}
                  fontWeight={theme.fontWeights.h6}
                  fontSize={theme.fontSizes.pSmall12}
                  color={theme.colors.pdfGray}
                >
                  DESCRIPTION
                </Text>
                <TextArea
                  placeholder="Enter Description"
                  value={replaceBrWithNewline(description) || ''}
                  onChange={updateDescriptionText()}
                  resizedQuerySelectorId="resized-textarea"
                />
                <Text my={3} fontSize={theme.fontSizes.pRegular14}>
                  Property description will be replaced by property feed updates
                </Text>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 pt20 pb10">
                <h4>Property Type</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-box">
                  <select required value={type} onChange={updateNumber('type')}>
                    {propertyTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <div className="input-box">
                  <div className="prefix">
                    <span>
                      m<sup>2</sup>
                    </span>
                  </div>
                  <input
                    required
                    value={landSize || ''}
                    onChange={updateNumber('landSize')}
                  />
                  <label>Land Size</label>
                </div>
              </div>
              <div className="col-xs-6">
                <div className="input-box">
                  <div className="prefix">
                    <span>
                      m<sup>2</sup>
                    </span>
                  </div>
                  <input
                    required
                    value={floorspace || ''}
                    onChange={updateNumber('floorspace')}
                  />
                  <label>Floor Space</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className={error ? 'alert alert-danger' : ''}>
                  <div>{error}</div>
                </div>
              </div>
            </div>
            <LoadingSection loadingState={managingAgentLoadingStates}>
              <PropertyListingAgent
                managingAgentID={listingAgentId}
                guidDetails={{
                  property: id,
                  agencyGUID: currentTeam?.guid,
                }}
                onManagingAgentUpdated={(newManager, callback) =>
                  this.onManagingAgentIdChanged(newManager, callback)
                }
              />
            </LoadingSection>
            <div className="row pt25 mobile-form-button">
              <div className="col-sm-5 pb5 tablet-only">
                <a
                  onClick={backHandler}
                  className="btn btn-transparent btn-left xs-text-center wa"
                  href="/#"
                >
                  <i className="icon-arrow-left left"></i>
                  <span>Back</span>
                </a>
              </div>
              <div className="col-sm-7 pb5">
                <button
                  className="btn"
                  disabled={disabled}
                  onClick={() => {
                    submit({ ...state, managingAgentId: listingAgentId })
                  }}
                >
                  Continue{' '}
                  <i
                    className={
                      updatePropertyClicked ? 'fa fa-spinner fa-pulse' : ''
                    }
                  />
                </button>
              </div>
            </div>
          </div>
          {ad !== null && (
            <div className="col-second xs-first">
              <ul className="snug-tips">
                <li>
                  We will update the ad automatically after the property is
                  changed
                </li>
              </ul>
            </div>
          )}
          <ReactTooltip className="tooltip" id="property-tooltip" />
        </div>
      </div>
    )
  }
}

export default Basics
