import React from 'react'

class ReferralCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      validating: false,
      validity: 'unverified',
    }
  }

  onClick = () => {
    this.setState({ validating: true })
    this.props.verify(
      () => {
        this.setState({ validating: false, validity: 'valid' })
      },
      () => {
        this.setState({ validating: false, validity: 'invalid' })
      },
    )
  }

  render() {
    return (
      <div className="referralCodeContainer">
        <input
          placeholder="Enter Promotion Code"
          value={this.props.referralCode}
          onChange={this.props.update}
        />
        <label>Promotion Code</label>
        <div className="p0 pt10">
          {this.state.validating ? (
            <i className="fa fa-spinner fa-pulse" />
          ) : this.state.validity === 'valid' ? (
            <span className="green-color">{this.state.validity}</span>
          ) : this.state.validity === 'invalid' ? (
            <span className="red-color">{this.state.validity}</span>
          ) : (
            ''
          )}
        </div>
        <div className="link-styles p0 pt10 " onClick={this.onClick}>
          &nbsp; Apply
        </div>
      </div>
    )
  }
}

export default ReferralCode
