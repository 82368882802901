import React from 'react'

import documentIcon from 'app/assets/icons/summary_icons/attachment.svg'
import * as Display from 'app/components/display/display'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import { isDocAutoDeleted } from 'app/sm/docs_helpers'
import { parseDataUtil, smokingText } from 'app/sm/helpers'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'
import { ApplicationDetailsNumbersComponentRowStyle } from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'

const ManagerOtherContainer = ({
  attachments = [],
  componentClassName,
  enableNote = true,
  displayedApplicant,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  section,
  pmAttachmentsProps,
  isApplyAnywhereApplication = undefined,
  teamSettingInfo,
  id = '',
  toggleRequestInfoModal = undefined,
  toggleDocumentsViewer = undefined,
}) => {
  const { application: applicationSettingInfo = {} } = teamSettingInfo
  const parsedApplicationSettingInfo =
    parseDataUtil.convertObjValueFromStringToBoolean(applicationSettingInfo)
  const { smoker_declaration_enabled: enableSmokerOptionDisplay } =
    parsedApplicationSettingInfo
  return (
    <div id={id} className={`container-component ${componentClassName}`}>
      <ContainerHeader
        icon={documentIcon}
        text="Other Info"
        isEditable={false}
        enableNote={enableNote}
        onNewNoteUpdated={onNewNoteUpdated}
        newNotesContent={newNotesContent}
        onNoteIconClicked={onNoteIconClicked}
        onNewNoteAdded={onNewNoteAdded}
        onNoteCrossClicked={onNoteCrossClicked}
        interpreteNoteResult={interpreteNoteResult}
        enableNotesButton={enableNotesButton}
        displayedApplicant={displayedApplicant}
        section={section}
        toggleRequestInfoModal={toggleRequestInfoModal}
      />
      <div className="application-container-padding">
        <div className="application-identification-container application-income-proof-container">
          <div className="application-identification-sub-container">
            <div className="application-info-common-component">
              <div className="title width100">
                {enableSmokerOptionDisplay && (
                  <div>
                    <Display.SectionMinorTitle text="Personal Information" />
                    <div className="display-flex">
                      <ApplicationDetailsNumbersComponentRowStyle
                        title="Smoker"
                        text={smokingText[displayedApplicant.smokingInfo]}
                        componentClassName="width50p align-items-start flex-basis-25p"
                        titleClassName="preference-label"
                        textClassName="preference-content"
                      />
                    </div>
                  </div>
                )}

                <Display.SectionMinorTitle text="Renter Attachments" />
                {attachments.length > 0 &&
                  attachments.map((attachment) => (
                    <AppDocumentListItemSecondVersion
                      titleClass="mt0"
                      document={attachment}
                      key={attachment.guidID}
                      toggleDocumentsViewer={
                        toggleDocumentsViewer
                          ? () => toggleDocumentsViewer(attachment.guidID)
                          : undefined
                      }
                      disabled={isDocAutoDeleted(attachment)}
                      isAutoDeleted={isDocAutoDeleted(attachment)}
                      isSensitive
                    />
                  ))}
                {attachments.length === 0 && (
                  <div className="no-info-provided">
                    No information provided
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ManagerOtherContainer
