import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'

export const formFields = {
  identifyAsAboriginalOrTorresStraitIslander:
    'identifyAsAboriginalOrTorresStraitIslander',
  isOnVictorianHousingRegisterWaitingList:
    'isOnVictorianHousingRegisterWaitingList',
  resideSocialOrPublicHousing: 'resideSocialOrPublicHousing',
  applicantGender: 'applicantGender',
  identifyAsCulturallyAndLinguisticallyDiverse:
    'identifyAsCulturallyAndLinguisticallyDiverse',
  identifyAsPersonWithDisability: 'identifyAsPersonWithDisability',
  ageRange: 'ageRange',
}

export const formQuestion = {
  [formFields.identifyAsAboriginalOrTorresStraitIslander]:
    'Do you identify as Aboriginal or Torres Strait Islander?',
  [formFields.isOnVictorianHousingRegisterWaitingList]:
    'Are you currently on the Victorian Housing Register waiting list?',
  [formFields.resideSocialOrPublicHousing]:
    'Do you currently reside in social or public housing?',
  [formFields.applicantGender]: 'What is your gender?',
  [formFields.identifyAsCulturallyAndLinguisticallyDiverse]:
    'Do you identify as culturally and linguistically diverse?',
  [formFields.identifyAsPersonWithDisability]:
    'Do you identify as a person with a disability?',
  [formFields.ageRange]: 'Age Range',
}

export const formAnswers = {
  [formFields.identifyAsAboriginalOrTorresStraitIslander]: [
    'Yes',
    'No',
    'Prefer not to say',
    'otherComments',
  ],
  [formFields.isOnVictorianHousingRegisterWaitingList]: ['Yes', 'No'],
  [formFields.resideSocialOrPublicHousing]: ['Yes', 'No'],
  [formFields.applicantGender]: [
    'Man',
    'Woman',
    'Self-described',
    'Prefer not to say',
  ],
  [formFields.identifyAsCulturallyAndLinguisticallyDiverse]: [
    'Yes',
    'No',
    'Prefer not to say',
    'otherComments',
  ],
  [formFields.identifyAsPersonWithDisability]: [
    'Yes',
    'No',
    'Prefer not to say',
    'otherComments',
  ],
}

export const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.identifyAsAboriginalOrTorresStraitIslander: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    case formFields.isOnVictorianHousingRegisterWaitingList: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    case formFields.resideSocialOrPublicHousing: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    case formFields.applicantGender: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    case formFields.identifyAsCulturallyAndLinguisticallyDiverse: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    case formFields.identifyAsPersonWithDisability: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    case formFields.ageRange: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    default: {
      return {
        required: 'This field is required to continue',
      }
    }
  }
}

export const ageRangeOptions = [
  {
    value: '18 - 29',
    optionMeta: {
      id: '18 - 29',
      displayText: '18 - 29',
    },
  },
  {
    value: '30 - 39',
    optionMeta: {
      id: '30 - 39',
      displayText: '30 - 39',
    },
  },
  {
    value: '40 - 49',
    optionMeta: {
      id: '40 - 49',
      displayText: '40 - 49',
    },
  },
  {
    value: '50 - 59',
    optionMeta: {
      id: '50 - 59',
      displayText: '50 - 59',
    },
  },
  {
    value: '60 - 69',
    optionMeta: {
      id: '60 - 69',
      displayText: '60 - 69',
    },
  },
  {
    value: '70 - 79',
    optionMeta: {
      id: '70 - 79',
      displayText: '70 - 79',
    },
  },
  {
    value: '80+',
    optionMeta: {
      id: '80+',
      displayText: '80+',
    },
  },
]

export const formValidation = formValidationBuilder(fieldValidation)
