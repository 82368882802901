import BaseInput from 'app/components/forms/base_input/component'
import {
  firstElementOrEmptyString,
  withValidation,
} from 'app/components/forms/util'
import * as validations from 'app/shared_components/validations'

const validateWithoutNum = (value) =>
  firstElementOrEmptyString(
    validations.validateTextWithoutNumber(value, 'Invalid Value'),
  )

const InputText = withValidation(BaseInput, validateWithoutNum)

export default InputText
