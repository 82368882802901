import React from 'react'

import { Link } from 'react-router-dom'

import { urlTo } from 'app/sm/helpers'

class SignLeaseContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { applicationId, propertyId } = this.props.match.params

    return (
      <div>
        <div className="section-title">
          <div className="left column">
            <h3>Lease</h3>
          </div>
        </div>
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to make a type specimen book.
            </p>
            <p>
              It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
            <p>
              It was popularised in the 1960s with the release of Letraset
              sheets containing Lorem Ipsum passages, and more recently with
              desktop publishing software like Aldus PageMaker including
              versions of Lorem Ipsum.
            </p>
            <Link
              to={urlTo('signatureSign', { propertyId, applicationId })}
              className="btn mt30"
            >
              Continue to Sign Lease
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default SignLeaseContainer
