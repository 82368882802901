import { connectRouter } from 'connected-react-router'
import localforage from 'localforage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

import admin from 'app/bond_cover/admin/admin_reducer'
import agency from 'app/bond_cover/agency/agency_reducer'
import AgencyDashboard from 'app/bond_cover/agency/dashboard/agency_dashboard_reducer'
import bondCover from 'app/bond_cover/bond_cover_reducer'
import { core } from 'app/core/coreSlice'
import { localEntities } from 'app/core/localEntitiesSlice'
import { pendingTasks } from 'app/core/pendingTasksSlice'
import dashboard from 'app/dashboard/dashboard_reducer'
import TeamOverview from 'app/dashboard/team_overview_reducer'
import { history } from 'app/history'
import DataPropertiesAndViewings from 'app/pages/teams/viewings-mobile/reducers/properties'
import PageViewings from 'app/pages/teams/viewings-mobile/reducers/viewings'
import NewViewingsRunReducer from 'app/pages/teams/viewings-run/reducer'
import { viewingAttendeesTab } from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingDetailsAttendeesTab/viewingAttendeesTabSlice'
import { mobileViewingDetails } from 'app/pages/Viewings/MobileViewings/ViewingDetails/mobileViewingDetailsSlice'
// Reducers
import session from 'app/session/session_reducer'
import Shared from 'app/shared_components/reducer'
import Advertisements from 'app/sm/advertisements/advertisements_reducer'
import AlternateOwnerProfile from 'app/sm/alternate_owner_profile/owner_profile_reducer'
import MyApplications from 'app/sm/applications/applications_reducer'
import apply from 'app/sm/apply/apply_reducer'
import askQuestion from 'app/sm/ask_question/ask_question_reducer'
import BackgroundCheck from 'app/sm/background_check/background_check_reducer'
import Documents from 'app/sm/documents/reducer'
import EmergencyContact from 'app/sm/emergency_contact/emergency_contact_reducer'
import Employment from 'app/sm/employment/reducer'
import ViewingsReducer from 'app/sm/inspections/inspections_reducer'
import ManagerViewings from 'app/sm/manager_viewings/manager_viewings_reducer'
import oaTenant from 'app/sm/oa_tenant/oa_tenant_reducer'
import PersonalReference from 'app/sm/personal_reference/personal_reference_reducer'
import AddPetDetails from 'app/sm/pets_details/pets_details_add_reducer'
import PhDeposit from 'app/sm/ph_deposit/ph_deposit_reducer'
import Ppp from 'app/sm/ppp/properties_reducer'
import Viewing from 'app/sm/ppp/viewing_reducer'
import Profile from 'app/sm/profile/profile_reducer'
import ProofOfIncome from 'app/sm/proof_of_income/proof_of_income_reducer'
import MyProperties from 'app/sm/properties/properties_reducer'
import propertyDashboard from 'app/sm/property_dashboard/property_dashboard_reducer'
import propertyDetails from 'app/sm/property_details/property_details_reducer'
import propertyTodos from 'app/sm/property_todos/property_todos_reducer'
import propertyTransactions from 'app/sm/property_transactions/property_transactions_reducer'
import PropertiesLists from 'app/sm/recently_viewed/recently_viewed_reducer'
import RentalReputation from 'app/sm/rental_reputation/reducer'
import RenterApplications from 'app/sm/renter_applications/renter_applications_reducer'
import review from 'app/sm/review/review_reducer'
import SignLease from 'app/sm/sign_lease/sign_lease_reducer'
import SignatureSign from 'app/sm/signature_sign/signature_sign_reducer'
import Viewings from 'app/sm/viewings/viewings_reducer'
import ViewingRun from 'app/sm/viewings_new_run/reducer'
import WithdrawConfirmation from 'app/sm/withdraw_confirmation/withdraw_confirmation_reducer'

const appReducer = combineReducers({
  session,
  bondCover,
  propertyDetails,
  propertyDashboard,
  propertyTodos,
  propertyTransactions,
  review,
  admin,
  dashboard,
  agency,
  router: connectRouter(history),
  askQuestion,
  oaTenant,
  apply,
  MyApplications,
  MyProperties,
  RenterApplications,
  WithdrawConfirmation,
  PhDeposit,
  SignLease,
  SignatureSign,
  Ppp,
  Viewing,
  Viewings,
  Advertisements,
  ManagerViewings,
  Profile,
  AlternateOwnerProfile,
  PropertiesLists,
  ProofOfIncome,
  BackgroundCheck,
  RentalReputation,
  Documents,
  Employment,
  Shared,
  ViewingsReducer,
  AgencyDashboard,
  AddPetDetails,
  PersonalReference,
  EmergencyContact,
  ViewingRun,
  TeamOverview,
  PageViewings,
  DataPropertiesAndViewings,
  NewViewingsRunReducer,
  mobileViewingDetails,
  core: persistReducer(
    {
      key: 'core',
      storage: localforage,
      whitelist: ['lastUpdateVersionMessageTime'],
    },
    core,
  ),
  pendingTasks,
  localEntities,
  uiState: combineReducers({
    viewingAttendeesTab,
  }),
})

const rootReducer = (state, action) => {
  let returnedState = state
  if (action.type === 'RECEIVE_LOGOUT') {
    returnedState = undefined
  }
  return appReducer(returnedState, action)
}

export default rootReducer
