import React from 'react'

import PropTypes from 'prop-types'

import 'app/match/applicationReportPDF/components/PDFBullet/style.scss'

const propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}

const PDFBullet = (props) => {
  const { label, value, supportingText } = props
  const isStatusPending = label.toLowerCase() === 'pending'
  return (
    <div className="verified-button">
      {isStatusPending ? (
        <i className="icon icon-question light-grey-text" />
      ) : (
        <i className="icon icon-check" />
      )}
      <div className="display-flex flex-direction-column">
        <div>
          {label} <strong>{value}</strong>
        </div>
        <div>{supportingText}</div>
      </div>
    </div>
  )
}

PDFBullet.propTypes = propTypes

export default PDFBullet
