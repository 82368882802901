import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import {
  validateEmail,
  validateMobile,
  validateName,
} from 'app/shared_components/validations'

export const formFields = {
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
  phone: 'phone',
  company: 'company',
}

export const fieldValidation = (fieldName, val, values) => {
  const isEmailAndPhoneEmpty =
    !values[formFields.email] && !values[formFields.phone]
  switch (fieldName) {
    case formFields.firstName: {
      return {
        required: !val && 'Please enter first name',
        pattern: validateName(val)[0],
      }
    }
    case formFields.lastName: {
      return {
        required: !val && 'Please enter last name',
        pattern: validateName(val)[0],
      }
    }
    case formFields.email: {
      return {
        required:
          isEmailAndPhoneEmpty && 'Please enter an email or mobile phone',
        pattern: val && validateEmail(val)[0],
      }
    }
    case formFields.phone: {
      return {
        required:
          isEmailAndPhoneEmpty && 'Please enter an email or mobile phone',
        pattern: val && validateMobile(val)[0],
      }
    }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
