import React from 'react'

import { Box } from 'app/components/design-system-components'
import Card from 'app/components/design-system-components/Card'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import WithPaidPlanOnlyBadge from 'app/sm/team_viewing_preference/setup/card_components/paidplanonlyBadge'
import StepsHeading from 'app/sm/team_viewing_preference/setup/card_components/stepHeading'

import { StyledSetup } from '..'

function OptimiseContent() {
  return (
    <StyledSetup>
      <Box mt={theme.space[2] + 'px'}>Get the most out of Snug.</Box>
      <Box mt={theme.space[5] + 'px'}>
        <Card
          headerTitle={
            <StepsHeading
              text="Step 11. Disable other online application forms"
              videoURL="https://youtu.be/PbS3cXyboKQ"
            />
          }
          isCollapsible={true}
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          internalComponent={
            <>
              Contact your REA account manager to disable your online
              application forms, and remove other online application links from
              your website.{' '}
              <a
                href="https://help.snug.com/hc/en-us/articles/360000864096-How-to-disable-1form-on-realestate-com-au-REA-and-Domain-to-enable-Snug?utm_campaign=PM+Onboarding+Checklist&utm_medium=app&utm_source=setup_page"
                target="_blank"
                rel="noreferrer"
              >
                Learn more here.
              </a>
            </>
          }
        />
      </Box>
      <Box mt={theme.space[5] + 'px'}>
        <Card
          headerTitle={
            <WithPaidPlanOnlyBadge
              comp={
                <StepsHeading
                  text="Step 12. Connect your Property Management System"
                  videoURL="https://youtu.be/I0i3kqcyBl0"
                />
              }
            />
          }
          isCollapsible={true}
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          internalComponent={
            <>
              Check out the bundle upgrade options at snug.com/pricing to sync
              properties and contacts, drive efficiency and experience. Email{' '}
              <a href="mailto:help@snug.com?subject=Connect Property Management System">
                help@snug.com
              </a>
            </>
          }
        />
      </Box>
      <Box mt={theme.space[7] + 'px'} mb={theme.space[5] + 'px'}>
        <Card
          headerTitle={
            <StepsHeading
              text="Step 13. Update your website with your Snug Apply Link"
              videoURL="https://youtu.be/JTi_dzjFFTc"
            />
          }
          isCollapsible={true}
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          internalComponent={
            <>
              Update the apply link on your office or franchise website. We're
              integrated with most major groups and systems.{' '}
              <a
                href="https://help.snug.com/hc/en-us/articles/360003644416-Add-the-Snug-Apply-Link-and-Viewings-Button-to-your-Website&utm_medium=app&utm_source=setup_page"
                target="_blank"
                rel="noreferrer"
              >
                Learn more here.
              </a>
            </>
          }
        />
      </Box>
      <Box mt={theme.space[7] + 'px'} mb={theme.space[5] + 'px'}>
        <Card
          headerTitle={
            <WithPaidPlanOnlyBadge
              comp={
                <StepsHeading
                  text="Step 14: Lease Offer"
                  videoURL="https://youtu.be/ljhP1yTPSx0"
                />
              }
            />
          }
          isCollapsible={true}
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          internalComponent={
            <>
              {' '}
              Streamline sending your congratulations email template via Snug
              with your custom content and payment instructions.{' '}
              <a
                href="https://help.snug.com/hc/en-us/articles/360001087616-Sending-a-lease-offer&utm_medium=app&utm_source=setup_page"
                target="_blank"
                rel="noreferrer"
              >
                Learn more here.
              </a>
            </>
          }
        />
      </Box>
    </StyledSetup>
  )
}

export default OptimiseContent
