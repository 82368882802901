import {
  PING,
  RECEIVE_SLIDERS,
} from 'app/sm/alternate_owner_profile/owner_profile_actions'

const _defaultState = {
  sliders: [],
}

const OwnerProfile = (state = _defaultState, action) => {
  let { type, sliders } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case PING:
      return Object.assign(newState)
    case RECEIVE_SLIDERS:
      return Object.assign(newState, { sliders })
    default:
      return newState
  }
}

export default OwnerProfile
