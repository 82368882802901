import { connect } from 'react-redux'

import ApplicationSummaryContainer from 'app/match/apply/application_summary_before_submit/application_summary_before_submit_container'
import {
  addRentalReputationBackUrl,
  clearRentalBackUrl,
  setBackUrl,
} from 'app/shared_components/actions'
import {
  acceptJointApplicationAsSecondary,
  fetchApplication,
  fetchRenterSummary,
  submitFinalApplication,
  updateApplicantDisclosures,
} from 'app/sm/apply/apply_actions'
import { submitJoinedApplication } from 'app/sm/apply_joint/actions'
import {
  fetchCompleteness,
  fetchTeamSettingInfo,
  fetchTeamSettingInformation,
  retrieveReputationPreference,
} from 'app/sm/rental_reputation/actions'
import { fetchBackgroundCheckReportDetails } from 'app/sm/renter_background_check_report/action'

const mapStateToProps = ({
  session,
  BackgroundCheck,
  apply,
  RentalReputation,
}) => ({
  currentUser: session.currentUser,
  backgroundCheck: BackgroundCheck.backgroundCheck,
  applicant: apply.applicant,
  submittedApplication: apply.application,
  completeness: RentalReputation.completeness,
  propertyDisclosure: apply.propertyDisclosure,
})

const mapDispatchToProps = (dispatch) => ({
  fetchBackgroundCheckReportDetails: (id) =>
    dispatch(fetchBackgroundCheckReportDetails(id)),
  fetchRenterSummary: (applicationId) =>
    dispatch(fetchRenterSummary(applicationId)),
  retrieveReputationPreference: () => dispatch(retrieveReputationPreference()),
  addRentalReputationBackUrl: (backUrl) =>
    dispatch(addRentalReputationBackUrl(backUrl)),
  clearRentalBackUrl: () => dispatch(clearRentalBackUrl()),
  setBackUrl: (backUrl) => dispatch(setBackUrl(backUrl)),
  fetchApplication: (applicationId) =>
    dispatch(fetchApplication(applicationId)),
  submitFinalApplication: (applicationId, data) =>
    dispatch(submitFinalApplication(applicationId, data)),
  acceptJointApplicationAsSecondary: (
    applicationId,
    applicantId,
    applicantSummary,
    apply,
    data,
  ) =>
    dispatch(
      acceptJointApplicationAsSecondary(
        applicationId,
        applicantId,
        applicantSummary,
        apply,
        data,
      ),
    ),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
  fetchTeamSettingInformation: (teamId, queryString) =>
    dispatch(fetchTeamSettingInformation(teamId, queryString)),
  updateApplicantDisclosures: (applicantGUID, data) =>
    dispatch(updateApplicantDisclosures(applicantGUID, data)),
  submitJoinedApplication: (teamGUID, data) =>
    dispatch(submitJoinedApplication(teamGUID, data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationSummaryContainer)
