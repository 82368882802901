import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box } from 'app/components/design-system-components/index'
import * as getters from 'app/components/design-system-components/theme/getters'
import { CustomInputWrapper } from 'app/forms/CustomInputWrapper'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const StyledCheckboxInput = styled.input`
  input[type='checkbox']:checked {
    background-color: ${getters.color('success700')} !important;
    border-color: ${getters.color('success700')} !important;
  }
`

export const CheckboxInput = ({
  value,
  changeHandler,
  blurHandler,
  children,
}) => {
  const toggleChecked = () => {
    changeHandler(!value)
  }

  return (
    <Box>
      <label className="ibm filter-today-checkbox">
        <StyledCheckboxInput
          type="checkbox"
          className="input-box wa pt0"
          onClick={() => toggleChecked()}
          checked={value}
        />
        <Box display="inline-block" ml={theme.space[3] + 'px'}>
          {children}
        </Box>
      </label>
    </Box>
  )
}

CheckboxInput.propTypes = {
  value: PropTypes.bool,
  changeHandler: PropTypes.func,
  blurHandler: PropTypes.func,
  children: PropTypes.element,
}

export const CheckboxInputField = (props) => {
  const { name } = props

  return (
    <CustomInputWrapper
      name={name}
      customInputCmp={(customInputProps) => (
        <CheckboxInput {...customInputProps} {...props} />
      )}
    />
  )
}
