import React, { useEffect, useState } from 'react'

import moment from 'moment'

import WelcomeImage from 'app/assets/icons/Welcome.svg'
import {
  Alert,
  Box,
  Button,
  Flex,
  Spinner,
} from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import RRTable from 'app/pages/teams/rental-references/components/RRTable'
import { RentalHistoryStatusNew } from 'app/pages/teams/rental-references/helpers'
import { getAgencySlugByGUID } from 'app/pages/teams/rental-references/helpers/http'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import * as helpers from 'app/sm/helpers'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'
import { isRRToLiteGuest } from 'app/utils/url/helpers'

function RRToLiteLanding({
  fetchRentalHistoryReviewWithCatchableError,
  match,
  renter,
  RentalHistory,
  setRentalHistorySecret,
  fetchAgencyInfo,
  fetchTeams,
  fetchCurrentUser,
  location,
  rentalHistoryReview,
}) {
  const {
    loadingStates: tenantDetailsLoadingStates,
    loadingStatesHelpers: tenantDetailsLoadingStatesHelpers,
  } = useLoadingStates()

  const [rentalHistoryColumnInfo, setRentalHistoryColumnInfo] = useState({
    property: {
      property_address: '123',
    },
    recipient: {
      agency_name: '',
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
    },
    assignee: {
      first_name: '',
      last_name: '',
    },
    action: {
      guid: '',
      secret: '',
      created_at: '',
    },
    status: RentalHistoryStatusNew,
    statusTime: '',
  })

  const [renterColumnInfo, setRenterColumnInfo] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
  })
  useEffect(() => {
    let {
      params: { rating, secretGUID, shortCode },
    } = match
    setRentalHistorySecret(secretGUID)
    tenantDetailsLoadingStatesHelpers.startLoading()
    fetchRentalHistoryReviewWithCatchableError(rating, secretGUID)
      .then(() => {
        tenantDetailsLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => tenantDetailsLoadingStatesHelpers.setError(err))
  }, [])

  const hasError = tenantDetailsLoadingStates.state === loadingStatesIds.ERROR

  useEffect(() => {
    if (Object.keys(renter).length) {
      setRenterColumnInfo({
        ...renterColumnInfo,
        first_name: renter.firstName,
        last_name: renter.lastName,
        phone: renter.mobilePhone,
        email: renter.email,
      })
    }
  }, [renter])

  useEffect(() => {
    let {
      params: { rating, secretGUID, shortCode },
    } = match
    const columnsInfo = { ...rentalHistoryColumnInfo }
    if (RentalHistory) {
      const {
        address,
        email = '',
        firstName = '',
        lastName = '',
        phoneNumber = '',
        agencyName = '',
        CreatedAt,
        managerRated,
      } = RentalHistory || {}
      if (managerRated) {
        history.push(
          `${helpers.urlTo('rentalReferenceURL', {
            ratingGUID: rating,
            secret: secretGUID,
            createLiteAccount: false,
          })}`,
        )
      }
      const { friendlyName = '', suburb = '' } = address || {}
      const fullAddress = friendlyName + ', ' + suburb
      Object.assign(columnsInfo, {
        property: {
          property_address: fullAddress,
        },
        recipient: {
          agency_name: agencyName,
          email,
          first_name: firstName,
          last_name: lastName,
          phone: phoneNumber,
        },
        assignee: {
          agency_name: agencyName,
          email,
          first_name: firstName,
          last_name: lastName,
          phone: phoneNumber,
        },
        action: {
          guid: rating,
          secret: secretGUID,
          created_at: CreatedAt,
        },
      })
    }

    const { ReferenceStatus = 0, StatusTime } = rentalHistoryReview || {}
    if (ReferenceStatus > 0) {
      Object.assign(columnsInfo, {
        status: ReferenceStatus,
        statusTime: StatusTime,
      })
    }

    setRentalHistoryColumnInfo(columnsInfo)
  }, [RentalHistory, rentalHistoryReview])

  const [showCompletedRentalReference, setShowCompletedReference] =
    useState(false)
  const toggleCompletedReferenceModal = () => {
    setShowCompletedReference(!showCompletedRentalReference)
  }

  const dataToBeEntered = [
    {
      time: moment.now(),
      property: rentalHistoryColumnInfo.property,
      renter: renterColumnInfo,
      assignee: rentalHistoryColumnInfo.assignee,
      status: rentalHistoryColumnInfo.status,
      action: rentalHistoryColumnInfo.action,
      recipient: rentalHistoryColumnInfo.recipient,
      statusTime: rentalHistoryColumnInfo.statusTime,
      visibility: false,
      // forProfilePreference: tableData.for_profile_reference,
    },
  ]

  const redirectToAuthenticatedRentalReference = async (agencyId) => {
    await fetchCurrentUser()
    await fetchTeams()
    getAgencySlugByGUID(agencyId).then((slug) => {
      if (slug) {
        const url = helpers.urlTo('rentalReferencesForTeamFirstTime', {
          teamSlug: slug,
        })
        history.push(url)
      } else {
        snugNotifier.error('Could not find the agency slug')
      }
    })
  }
  const continueAsGuest = isRRToLiteGuest()

  return (
    <>
      {tenantDetailsLoadingStates.state === loadingStatesIds.LOADING ? (
        <Spinner />
      ) : (
        <>
          <Box>
            <RRTable
              showSearchSection={false}
              dataToBeEntered={dataToBeEntered}
              showResults={true}
              toggleCompletedReferenceModal={toggleCompletedReferenceModal}
              fetchAgencyInfo={fetchAgencyInfo}
              redirectToAuthenticatedRentalReference={
                redirectToAuthenticatedRentalReference
              }
              location={location}
              showConnectTeamMembersModal={showCompletedRentalReference}
            />
          </Box>
          {hasError && (
            <Alert variant="warningWithBg" mt={theme.space[3] + 'px'}>
              {tenantDetailsLoadingStates.error.message}
            </Alert>
          )}
        </>
      )}
    </>
  )
}

export default RRToLiteLanding
