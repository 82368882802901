// Flag categories
export const applicationCategory = 1

// Flag types
export const unsuitableApplicantCondition = 1

// Helper functions
export const isFlaggedUnsuitable = (applicantFlagsArr) => {
  return applicantFlagsArr.filter(
    (flag) =>
      flag['category'] === applicationCategory &&
      flag['condition'] === unsuitableApplicantCondition,
  )
}
