import { getOfferedApplication } from 'app/sm/oa_tenant/oa_tenant_access'
import store from 'app/store'

export const PING = 'OAT_PING'
export const SET_PROPERTY_ID = 'OAT_SET_PROPERTY_ID'
export const RECEIVE_PROPERTY = 'OAT_RECEIVE_PROPERTY'
export const RECEIVE_OFFERED_APPLICATION = 'OAT_RECEIVE_OFFERED_APPLICATION'
export const RECEIVE_RESPONSE_TEXT = 'OAT_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'OAT_RECEIVE_ERROR'

// Action Creators

export const ping = (text) => ({ type: PING })
export const setPropertyId = (propertyId) => ({
  type: SET_PROPERTY_ID,
  propertyId,
})
export const receiveProperty = (property) => ({
  type: RECEIVE_PROPERTY,
  property,
})
export const receiveOfferedApplication = (offeredApplication) => ({
  type: RECEIVE_OFFERED_APPLICATION,
  offeredApplication,
})
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })

// Methods

export function fetchOfferedApplication(applicationId) {
  return (dispatch) => {
    getOfferedApplication(applicationId).then(
      ({ application, ok, responseText, statusCode }) => {
        if (ok) {
          const { property } = application.Application.offer

          dispatch(setPropertyId(property.guidID))
          dispatch(receiveProperty(property))
          dispatch(receiveOfferedApplication(application))
        } else {
          responseText.then((t) => {
            store.dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}
