import { connect } from 'react-redux'

import { fetchPersonalReferenceReviewByShortCode } from 'app/pages/personalReference/survey/actions'
import ShortCodeRedirect from 'app/pages/personalReference/survey/components/ShortCodeRedirect'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  fetchPersonalReferenceReviewByShortCode: (shortCode) =>
    dispatch(fetchPersonalReferenceReviewByShortCode(shortCode)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShortCodeRedirect)
