import React from 'react'

import PropTypes from 'prop-types'

import Card from 'app/match/applicationReportPDF/components/Card'

const propTypes = {
  requests: PropTypes.string,
}

function Requests({ requests }) {
  return (
    <div className="requests">
      <Card
        type={'icon'}
        tag={'div'}
        title={'Requests'}
        addClass={'card-bottom-bordered'}
        icon={'icon-telegram'}
      />
      <p>{requests || 'No information is provided'}</p>
    </div>
  )
}

Requests.propTypes = propTypes

export default Requests
