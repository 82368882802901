import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'
import { Pie, PieChart } from 'recharts'

import {
  CategoryTypes,
  isPropertyDraft,
  NO_IMAGE_PLACEHOLDER_URL,
  urlTo,
} from 'app/sm/helpers'

function RankPieChart(props) {
  const { rank } = props
  let fillColor = '#37c977'
  if (rank < 75) {
    fillColor = '#24C9D0'
  }
  if (rank < 50) {
    fillColor = '#579FFB'
  }
  if (rank < 25) {
    fillColor = '#ffe757'
  }
  const data = [
    { name: 'rank', value: rank, fill: fillColor },
    { name: 'blank', value: 100 - rank, fill: '#ffffff' },
  ]
  return (
    <PieChart width={50} height={50}>
      <Pie
        dataKey="value"
        data={data}
        innerRadius={21}
        outerRadius={25}
        labelLine={false}
      />
    </PieChart>
  )
}

class PropertyCard extends React.Component {
  constructor(props) {
    super(props)
  }

  setFavorite(event, propertyId) {
    this.props.addToSavedProperty(propertyId)
    event.preventDefault()
  }

  render() {
    const { currentUser, property } = this.props
    const snugFit = property.snugFit
    const activeOffer = (property.offers || []).find((o) => o.isActive) || {}
    const { weeklyRent = 'N/A' } = activeOffer
    const { guidID: propertyId } = property
    let imageUrl = NO_IMAGE_PLACEHOLDER_URL
    let address = ''
    let suburb = ''

    // const rand = Math.round(20 + Math.random() * (80));

    if (property.images && property.images.length > 0) {
      imageUrl = (
        property.images.find((image) => image.isMain === true) ||
        property.images[0]
      ).URL
    }

    if (property.address) {
      if (property.address.unitNumber) {
        address = `${property.address.unitNumber}/${property.address.streetNumber} ${property.address.streetName}`
      } else {
        address = `${property.address.streetNumber} ${property.address.streetName}`
      }
      suburb = property.address.suburb
    }

    return (
      <div className="sm-properties-card-list-item">
        <div className="sm-properties-card-list-item-wrapper">
          <Link
            to={urlTo('propertyDetails', { propertyId })}
            className="image"
            style={{ backgroundImage: `url(${imageUrl})` }}
          >
            <div className="controls">
              <div className="left">
                <button onClick={(e) => this.setFavorite(e, propertyId)}>
                  <i
                    className={
                      property.isFavourite
                        ? 'icon-heart-filled red-color'
                        : 'icon-heart-outline'
                    }
                  ></i>
                </button>
              </div>
              {/*<div className="right">
                  <button>Apply</button>
                 </div>*/}
            </div>
          </Link>
          <div className="meta">
            <div className="address">
              <Link
                to={urlTo('propertyDetails', { propertyId })}
                className="street"
                title={address}
              >
                {address}
              </Link>
              {/*<div className="street">{address}</div>*/}
              <div className="suburb">{suburb}</div>
            </div>
            <div className="rank-chart">
              <div className="rank-chart-wrapper">
                <RankPieChart rank={Math.round(snugFit.score)} />
                <div className="rank-chart-text fontSize-13">
                  {Math.round(snugFit.score)}
                </div>
              </div>
            </div>
          </div>
          <div className="info">
            <div>
              <span>{CategoryTypes[property.type]}</span>{' '}
              <span>
                <i className="icon-dot"></i> ${weeklyRent} p/w
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PropertyCard
