import { transparentize } from 'polished'
import { css } from 'styled-components'

import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const HighlightedBox = ({ backgroundColor = theme.colors.green }) => css`
  background-color: ${transparentize(0.9, backgroundColor)};
  border: 1px solid ${backgroundColor};
  border-left: 5px solid ${backgroundColor};
  }
`
