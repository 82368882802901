import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'
import SignaturePad from 'snugco_signature_pad/dist/signature_pad.js'

import { history } from 'app/shared_components/router'
import StatusBar from 'app/shared_components/status_bar'

class ManagerSign extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      request: '',
      secret: '',
      agreedToTerms: false,
      disabled: false,
      approveDisabled: true,
      approveClicked: false,
      declineClicked: false,
      signError: false,
    }
  }

  UNSAFE_componentWillMount() {
    let { fetchManagerSignatureSummary } = this.props
    let requestID = this.getParameter('requestid')
    let secret = this.getParameter('secret')
    this.setState({ request: requestID, secret: secret })
    this.fetchSignatureSummary(requestID)
  }

  componentDidMount() {
    var canvas = document.querySelector('canvas')
    if (canvas !== null) {
      this.signaturePad = new SignaturePad(canvas)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      disabled: false,
      approveDisabled: false,
      approveClicked: false,
      declineClicked: false,
    })
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    nextState.approveDisabled = !(
      nextState.agreedToTerms &&
      !(nextState.approveClicked || nextState.declineClicked)
    )
    nextState.disabled = nextState.approveClicked || nextState.declineClicked
  }

  getParameter = (field) => {
    let url = window.location.href
    field = field.replace(/[\[\]]/g, '\\$&')
    let regex = new RegExp('[?&]' + field + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  clear() {
    this.signaturePad.clear()
  }

  decline = (request, secret) => {
    let navigateUrl =
      window.location.href.indexOf('agency') > 0
        ? '/agency/bc/decline'
        : '/bc/decline'
    return () => {
      let { decline } = this.props
      this.setState({ declineClicked: true })
      history.push(`${navigateUrl}?requestid=` + request + '&secret=' + secret)
    }
  }

  fetchSignatureSummary(requestID) {
    const { fetchManagerSignatureSummary, fetchAgencyManagerSignatureSummary } =
      this.props
    const isAgencyManagerSignatureSummary =
      window.location.pathname.includes('agency')
    isAgencyManagerSignatureSummary
      ? fetchAgencyManagerSignatureSummary(requestID)
      : fetchManagerSignatureSummary(requestID)
  }

  signByManager = (request) => {
    return () => {
      let { signByManager, backUrl, signByAgencyManager } = this.props
      let { agreedToTerms } = this.state
      let forwardUrl =
        window.location.href.indexOf('agency') > 0
          ? backUrl
          : `/bc/managerwait?requestid=${request}`
      const isAgencyApproval = window.location.pathname.includes('agency')
      if (this.signaturePad.isEmpty()) {
        this.setState({ signError: true })
      } else {
        this.setState({ approveClicked: true })
        let signature = {
          signatureSvg: this.signaturePad.toDataURL('image/svg+xml'),
          agreedToTerms: agreedToTerms,
        }
        isAgencyApproval
          ? signByAgencyManager(request, signature, forwardUrl)
          : signByManager(request, signature, forwardUrl)
      }
    }
  }

  toggleCheckbox(field) {
    return (event) => {
      let { checked } = event.target
      this.setState({ [field]: checked })
    }
  }

  render() {
    let { currentLease, quote, renter, signature, signByManager, error } =
      this.props
    let { address, bondReferenceNumber } = currentLease ? currentLease : ''
    let { friendlyName, streetNumber, streetName, suburb, state, postcode } =
      address ? address : ''
    let { premium, bondAmount } = quote ? quote : ''
    let refund = bondAmount - premium
    let { firstName, lastName } = renter ? renter : ''
    let {
      request,
      secret,
      agreedToTerms,
      disabled,
      approveDisabled,
      approveClicked,
      declineClicked,
      signError,
    } = this.state

    if (false) {
      return (
        <div className="two-col-box-flex-row sm64">
          <div className="col-first">
            <div className="alert alert-danger"> {error} </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="two-col-box-flex-row sm64">
          <div className="col-first">
            <StatusBar progress="4" />

            <h3 className="mb30">Review &amp; Sign</h3>

            <p>
              Please confirm the details below and sign to approve{' '}
              <b>
                {firstName} {lastName}
              </b>
              &#8217;s application for a Snug BondCover Certificate.
            </p>

            <ul className="snug-infos mb30">
              <li>
                Snug uses bank-level 256-bit security to protect your data.
              </li>
            </ul>

            <div className="bc-hub-item-link completed">
              <header>
                <i className="icon-bank" />
                <span>BondCover</span>
              </header>
              <content>
                <h2>{bondAmount ? `$${bondAmount.toFixed(2)}` : `$0.00`}</h2>
                <p>
                  BondCover replaces your existing bond, recognises and rewards
                  good renting.
                </p>
              </content>
            </div>

            <div className="bc-hub-item-link completed">
              <header>
                <i className="icon-couch" />
                <span>Current Lease</span>
              </header>
              <content>
                <p>
                  {address && address.friendlyName}, {address && address.suburb}
                  , {address && address.state} {address && address.postcode}{' '}
                  <br />
                  {currentLease
                    ? moment(currentLease.startDate).format('Do MMM YYYY')
                    : ''}{' '}
                  -{' '}
                  {currentLease && currentLease.endDate
                    ? moment(currentLease.endDate).format('Do MMM YYYY')
                    : 'Rolling Lease'}
                  <br />${currentLease ? currentLease.rate : ''} per week <br />
                  ${currentLease ? currentLease.bond : ''} bond <br />
                </p>
                <p className={bondReferenceNumber ? '' : 'hidden'}>
                  {' '}
                  Bond Reference Number: {bondReferenceNumber}{' '}
                </p>
              </content>
            </div>

            <div className="bc-hub-item-link completed">
              <header>
                <i className="icon-signature" />
                <span>Renter Signature</span>
              </header>
              <content>
                <img
                  className="signature-card-img"
                  src={signature ? signature.signature_svg : ''}
                />
                <p>
                  {renter && renter.firstName && renter.lastName
                    ? renter.firstName + ' ' + renter.lastName
                    : ''}
                  <br />
                  {signature && signature.dateSigned
                    ? moment(signature.dateSigned).format('Do MMM YYYY')
                    : ''}
                </p>
              </content>
            </div>

            <h3 className="mb20 mt40">Your Signature, please.</h3>

            <p className="mb5">Please sign to :</p>

            <ul className="pl20 mb25">
              <li className="pb5 lh14">
                {' '}
                Approve {renter ? renter.firstName + ' ' + renter.lastName : ''}
                's application
              </li>
              <li className="lh14">
                {' '}
                Amend the lease to accept and replace the cash bond with a Snug
                BondCover Certificate, issued by Snug Financial Services and
                underwritten by Insurance Australia Limited (IAG)
              </li>
            </ul>

            <small className="gray-light-color">
              *based on actuarial projections
            </small>

            <div className="text-right pb20">
              <button
                className="btn btn-small btn-gray wa pl20 pr20"
                onClick={() => this.clear()}
              >
                Clear
              </button>
            </div>

            <div className="signature-pad-wrapper">
              <canvas className="canvas"></canvas>
            </div>

            <div className="cb-wrapper mt15">
              <p> By signing you agree: </p>
              <div className="cb-wrapper-row">
                <input
                  type="checkbox"
                  checked={agreedToTerms}
                  onChange={this.toggleCheckbox('agreedToTerms')}
                />
                <div>
                  To the{' '}
                  <a
                    target="_blank"
                    href="http://snug.com/bondcover/terms"
                    rel="noreferrer"
                  >
                    BondCover Terms &amp; Condition
                  </a>
                </div>
              </div>
            </div>

            {signError ? (
              <div className="alert alert-danger"> Please Sign Above </div>
            ) : (
              ''
            )}

            <div className="row mt20 mb15 mobile-form-button">
              <div className="col-sm-2 pb5 tablet-only"></div>
              <div className="col-sm-7 margin-desktop-only">
                <button
                  className="mt40 mb15"
                  disabled={approveDisabled}
                  onClick={this.signByManager(request)}
                >
                  {' '}
                  Approve BondCover{' '}
                  <i
                    className={approveClicked ? 'fa fa-spinner fa-pulse' : ''}
                  />{' '}
                </button>
              </div>
            </div>
            <div className="row mb25 mobile-form-button">
              <div className="col-sm-2 pb5 tablet-only"></div>
              <div className="col-sm-7 margin-desktop-only">
                <button
                  className="btn btn-gray-dark mb25"
                  disabled={disabled}
                  onClick={this.decline(request, secret)}
                >
                  {' '}
                  Decline{' '}
                  <i
                    className={declineClicked ? 'fa fa-spinner fa-pulse' : ''}
                  />{' '}
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default ManagerSign
