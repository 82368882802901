import {
  RECEIVE_PROOF_OF_INCOME,
  RECEIVE_RESPONSE_TEXT,
  TOGGLE_SPINNER,
} from 'app/sm/proof_of_income/proof_of_income_actions'

const _defaultState = {
  documents: {
    paySlips: [],
    bankStatements: [],
    otherIncomeDocuments: [],
    governmentBenefitDocuments: [],
  },
  spinners: {
    Payslips: false,
    BankStatements: false,
    OtherIncomeDocuments: false,
    GovernmentBenefitDocuments: false,
  },
  responseTexts: {
    Payslips: false,
    BankStatements: false,
    OtherIncomeDocuments: false,
    GovernmentBenefitDocuments: false,
  },
  error: '',
}

const ProofOfIncome = (state = _defaultState, action) => {
  let { type, documents, documentType, responseText, fileType, spinnerOnOff } =
    action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case RECEIVE_PROOF_OF_INCOME:
      return Object.assign(newState, { documents, responseTexts: '' })
    case TOGGLE_SPINNER:
      return Object.assign(newState, {
        spinners: Object.assign({}, newState.spinners, {
          [documentType]: spinnerOnOff,
        }),
      })
    case RECEIVE_RESPONSE_TEXT:
      return Object.assign(newState, {
        responseTexts: Object.assign({}, newState.responseTexts, {
          [fileType]: responseText,
        }),
      })
    default:
      return state
  }
}

export default ProofOfIncome
