import React from 'react'

import { DetailsBoxCard } from 'app/components/display/display'
import { relationshipTypesEmergencyContact, urlTo } from 'app/sm/helpers'

const EmergencyContactBox = ({
  confirmRemoveContact,
  contact,
  readOnly = false,
  from = '',
}) => {
  const fromRenterSummary = from === 'renterSummary'
  const details = [
    `Relationship: ${relationshipTypesEmergencyContact[contact.relationship]}`,
    `Phone number: ${contact.phoneNumber || 'N/A'}`,
    `Email: ${contact.email || 'N/A'}`,
    `${contact.comment ? `Comment: ${contact.comment}` : ''}`,
  ]
  return (
    <DetailsBoxCard
      title={`${contact.firstName} ${contact.lastName}`}
      details={details}
      componentClass={fromRenterSummary ? 'no-box-shadow p0' : ''}
      disabled={readOnly}
      onRemove={() => confirmRemoveContact(contact.guidID)}
      editUrl={urlTo('emergencyContactEdit', contact)}
    />
  )
}

export default EmergencyContactBox
