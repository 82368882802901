import { connect } from 'react-redux'

import {
  fetchAgencyRequests,
  searchAgencyRequests,
} from 'app/bond_cover/agency/dashboard/agency_dashboard_actions'
import AgencyDashboard from 'app/bond_cover/agency/dashboard/agency_dashboard_container'
import { setBackUrl } from 'app/shared_components/actions'

const mapStateToProps = ({ AgencyDashboard, bondCover, session }) => ({
  bondCovers: AgencyDashboard.bondCovers,
  responseText: AgencyDashboard.responseText,
  spinner: AgencyDashboard.spinner,
  currentTeam: session.currentTeam,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  fetchAgencyRequests: (searchType, manager, agencyID, cursor) =>
    dispatch(fetchAgencyRequests(searchType, manager, agencyID, cursor)),
  searchAgencyRequests: (filterType, manager, addressSearchText, cursor) =>
    dispatch(
      searchAgencyRequests(filterType, manager, addressSearchText, cursor),
    ),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AgencyDashboard)
