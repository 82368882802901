import { connect } from 'react-redux'

import {
  fetchSliders,
  ping,
  updateSlider,
} from 'app/sm/alternate_owner_profile/owner_profile_actions'
import OwnerProfileContainer from 'app/sm/alternate_owner_profile/owner_profile_container'

const mapStateToProps = ({ AlternateOwnerProfile }) => ({
  error: AlternateOwnerProfile.error,
  sliders: AlternateOwnerProfile.sliders,
})

const mapDispatchToProps = (dispatch) => ({
  ping: () => dispatch(ping()),
  fetchSliders: () => dispatch(fetchSliders()),
  updateSlider: (id, value) => dispatch(updateSlider(id, value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OwnerProfileContainer)
