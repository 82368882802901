import React from 'react'

import { formatNumber, parseNumber } from 'libphonenumber-js'

import { SectionHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import * as NewForm from 'app/components/forms/forms'
import { ErrorMessage } from 'app/shared_components/helpers'
import StandardScreen from 'app/shared_components/layout_component/standard_screen_layout_component'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import {
  isRelationshipOther,
  validateMinimumContactDetailProvided,
  validateRelationship,
} from 'app/sm/helpers'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import { isCurrentRoute } from 'app/sm/router_helpers'

import 'app/profile/renter/emergency_contact/style.scss'

class EmergencyContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      countryCode: '',
      relationship: 0,
      comment: '',
      disabled: true,
      clicked: false,
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        relationship: 0,
        phoneNumber: '',
        comment: '',
      },
      unsavedChanges: false,
      showValidationStatus: true,
      error: '',
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { contactId },
      },
      getEmergencyContact,
    } = this.props
    if (contactId) {
      getEmergencyContact(contactId)
      this.setState({
        showValidationStatus: false,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { emergencyContact } = nextProps
    this.setState({ clicked: false })

    if (emergencyContact && emergencyContact.guidID) {
      const parsedNumber = parseNumber(emergencyContact.phoneNumber)
      const formattedPhoneNumber = formatNumber(
        emergencyContact.phoneNumber,
        'AU',
        'International',
      )
      this.setState({
        firstName: emergencyContact.firstName,
        lastName: emergencyContact.lastName,
        email: emergencyContact.email,
        phoneNumber: formattedPhoneNumber,
        relationship: emergencyContact.relationship,
        comment: emergencyContact.comment || '',
        countryCode: parsedNumber && parsedNumber.country,
      })
    }
  }

  setValidationErrors(field, error) {
    const { errors } = this.state
    if ((error && error.length === 0) || error === undefined || error === '') {
      delete errors[field]
      this.setState({ errors })
    }
  }

  submit = () => {
    const {
      createEmergencyContact,
      editEmergencyContact,
      match: {
        params: { contactId },
      },
    } = this.props
    const { firstName, lastName, email, phoneNumber, relationship, comment } =
      this.state

    const isValid = this.validateRequiredFields()
    if (isValid) {
      const data = {
        firstName,
        lastName,
        email,
        phoneNumber,
        countryCode:
          phoneNumber && parseNumber(phoneNumber).country !== undefined
            ? parseNumber(phoneNumber).country.toUpperCase()
            : '',
        relationship,
        comment: isRelationshipOther(this.state.relationship) ? comment : '',
      }

      this.setState({ clicked: true, unsavedChanges: false })
      if (contactId) {
        editEmergencyContact(data, contactId)
      } else {
        createEmergencyContact(data)
      }
    }
  }

  updateField = (field) => {
    return (data) => {
      if (field === 'relationship') {
        this.setState({
          [field]: parseInt(data.value, 10),
          showValidationStatus: true,
          errors: {
            ...this.state.errors,
            [field]: '',
            comment: '',
          },
        })
      } else {
        this.setState({
          [field]: data.value,
          showValidationStatus: true,
          errors: {
            ...this.state.errors,
            [field]: data.error,
          },
        })
      }
    }
  }

  validateRequiredFields() {
    const { phoneNumber, email } = this.state
    const errorMessage = validateMinimumContactDetailProvided([
      phoneNumber,
      email,
    ])
    if (errorMessage !== '') {
      this.setState({
        error: errorMessage,
      })
      return false
    }
    const errors = {}
    Object.keys(this.state.errors).forEach((field) => {
      if (field === 'relationship' || field === 'comment') {
        const errorMessage = validateRelationship(
          this.state.relationship,
          this.state.comment,
        )[field]
        if (errorMessage) {
          errors[field] = errorMessage
        }
      } else if (
        this.state[field] === '' &&
        field !== 'phoneNumber' &&
        field !== 'email'
      ) {
        errors[field] = 'This field is required'
      } else if (this.state.errors[field] !== '') {
        errors[field] = this.state.errors[field]
      }
    })
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    })
    return Object.keys(errors).length === 0
  }

  render() {
    const {
      match: {
        params: { contactId },
      },
      apiError,
    } = this.props
    const { clicked, unsavedChanges, error } = this.state
    const addEmergencyContactTagClass = isCurrentRoute('addEmergencyContact')
      ? 'emergency-contact-added'
      : ''
    const { property } = this.props
    const isContactEditable = `${contactId ? 'Edit' : 'Add'}`
    const HeaderText = `${isContactEditable} Next of Kin / Emergency Contacts`
    return (
      <StandardScreen>
        <div>
          <div className="row">
            <RentalReputationHeaderReminder
              property={property}
              backUrl="/"
              remindText="Current application"
              isFromApplicationForm=""
              title="Next of Kin"
              thirdTierText={isContactEditable}
            />
            <SectionHeader
              text={HeaderText}
              hasSeperator={false}
              textClass="pl0"
            />
          </div>
          <div className="row">
            <NewForm.ProfilePagesRowLayout containerClass="profile-layout-two-items">
              <div className="width100">
                <NewForm.InputName
                  label="First Name"
                  value={this.state.firstName}
                  error={this.state.errors.firstName}
                  onChange={this.updateField('firstName')}
                  id="firstName"
                  inputClass="width100"
                  componentClass="margin-profile-item-left"
                  showValidationStatus={this.state.showValidationStatus}
                />
              </div>
              <div className="width100">
                <NewForm.InputName
                  label="Last Name"
                  value={this.state.lastName}
                  error={this.state.errors.lastName}
                  onChange={this.updateField('lastName')}
                  id="lastName"
                  inputClass="width100"
                  componentClass="margin-profile-item-right"
                  showValidationStatus={this.state.showValidationStatus}
                />
              </div>
            </NewForm.ProfilePagesRowLayout>
            <NewForm.ProfilePagesRowLayout containerClass="profile-layout-two-items">
              <div className="width100">
                <NewForm.InputEmail
                  label="Email"
                  value={this.state.email}
                  error={this.state.errors.email}
                  onChange={this.updateField('email')}
                  id="email"
                  inputClass="width100"
                  componentClass="margin-profile-item-left"
                  showValidationStatus={this.state.showValidationStatus}
                  isOptional
                />
              </div>
              <div className="width100">
                <NewForm.InputPhoneNumber
                  label="Phone Number"
                  value={this.state.phoneNumber}
                  error={this.state.errors.phoneNumber}
                  onChange={this.updateField('phoneNumber')}
                  id="phoneNumber"
                  containerClassName="width100"
                  componentClass="margin-profile-item-right"
                  showValidationStatus={this.state.showValidationStatus}
                />
              </div>
            </NewForm.ProfilePagesRowLayout>
            <NewForm.ProfilePagesRowLayout containerClass="profile-layout-two-items justify-content-flex-start">
              <div className="col-sm-6 p0">
                <NewForm.DropdownRelationship
                  label="Relationship"
                  value={this.state.relationship}
                  error={this.state.errors.relationship}
                  options={[
                    'Select Relationship',
                    'Parent',
                    'Sibling',
                    'Grandparent',
                    'Child',
                    'Extended Family',
                    'Spouse',
                    'Partner',
                    'Friend',
                    'Colleague',
                    'Other',
                  ]}
                  onChange={this.updateField('relationship')}
                  id="relationship"
                  inputClass="width100"
                  componentClass="margin-profile-item-left"
                />
              </div>
            </NewForm.ProfilePagesRowLayout>
          </div>
          {isRelationshipOther(this.state.relationship) && (
            <div className="row emergency-text-area">
              <NewForm.CommentArea
                value={this.state.comment}
                error={this.state.errors.comment}
                placeholder="Insert text"
                onChange={this.updateField('comment')}
                id="comment"
                rows={1}
                componentClass="col-sm-5 pl0"
                isOptional={false}
              />
            </div>
          )}
        </div>
        <ErrorMessage error={apiError || error} />
        <NewForm.ProfilePagesBottomButtons
          clickNextFunction={this.submit}
          clickNextText="Save"
          clicked={clicked}
          buttonClass={addEmergencyContactTagClass}
        />
        <UnsavedMessage unsavedChanges={unsavedChanges} />
      </StandardScreen>
    )
  }
}

export default EmergencyContactForm
