import { connect } from 'react-redux'

import { fetchApplication } from 'app/sm/apply/apply_actions'
import {
  addDocument,
  deleteDocument,
  fetchDocuments,
} from 'app/sm/documents/actions'
import Container from 'app/sm/documents/container'
import { fetchCompleteness } from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({ Documents, Shared, apply, RentalReputation }) => ({
  documents: Documents.documents,
  spinner: Documents.spinner,
  responseText: Documents.responseText,
  backUrl: Shared.backUrl,
  property: apply.property,
  completeness: RentalReputation.completeness,
  offer: apply.application?.offer,
  applicationDetails: apply.application,
})

const mapDispatchToProps = (dispatch) => ({
  fetchDocuments: () => dispatch(fetchDocuments()),
  addDocument: (documentType) => dispatch(addDocument(documentType)),
  deleteDocument: (id) => dispatch(deleteDocument(id)),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
  fetchApplication: (applicationId) =>
    dispatch(fetchApplication(applicationId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
