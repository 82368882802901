import React, { useState } from 'react'

import {
  Box,
  Button,
  Flex,
  Modal,
} from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { whyAddBGCheck } from 'app/sm/background_check/bg_check'
import ExampleReportModal from 'app/sm/background_check/exampleReportModal'
import UploadOwnTenancyCheckModal from 'app/sm/background_check/SelfTenancyCheck/uploadOwnTenancyCheckModal'

function AddBGCheckModal({
  toggleModal,
  secondaryAction,
  primaryAction,
  onClickSaveForSelfTenancyCheck,
}) {
  const [showExampleBGCheckReport, setShowExampleBGCheckReport] =
    useState(false)
  const [showUploadOwnTenancyCheckModal, setShowUploadOwnTenancyCheckModal] =
    useState(false)

  const toggleShowExampleBGCheckReport = () => {
    setShowExampleBGCheckReport(!showExampleBGCheckReport)
  }
  const toggleShowUploadOwnTenancyCheckModal = () => {
    setShowUploadOwnTenancyCheckModal(!showUploadOwnTenancyCheckModal)
  }
  return (
    <>
      <Modal
        modalBody={
          <Box>
            <Box>
              <Flex alignItems="center">
                <Box mr={2}>
                  You have not added a Background Check to your profile.
                </Box>
                <Button
                  variant="linkBlue"
                  onClick={toggleShowExampleBGCheckReport}
                  fontSize={theme.fontSizes.pLarge16}
                >
                  See Example Report
                </Button>
              </Flex>
            </Box>
            <Box mt={5}>{whyAddBGCheck}</Box>
          </Box>
        }
        modalHeading="Add a Background Check"
        secondaryLabel="Skip"
        toggleModal={toggleModal}
        secondaryAction={secondaryAction}
        primaryAction={primaryAction}
        primaryLabel="Add Background Check"
      />
      {showExampleBGCheckReport && (
        <ExampleReportModal
          toggleExampleReportModal={toggleShowExampleBGCheckReport}
        />
      )}
      {showUploadOwnTenancyCheckModal && (
        <UploadOwnTenancyCheckModal
          toggleShowUploadOwnTenancyCheckModal={
            toggleShowUploadOwnTenancyCheckModal
          }
          onClickSaveForSelfTenancyCheck={onClickSaveForSelfTenancyCheck}
        />
      )}
    </>
  )
}

export default AddBGCheckModal
