import React from 'react'

import * as Display from 'app/components/display/display'
import 'app/sm/team_viewing_preference/header/style.scss'

const Header = ({ title }) => {
  return (
    <div className="preference-header-container">
      <Display.MainHeader text={title} />
    </div>
  )
}

export default Header
