import { connect } from 'react-redux'

import RegisterConfirmForm from 'app/session/register_components/register_confirm_form'
import { clearError, confirm } from 'app/session/session_actions'

const mapStateToProps = ({ session }) => ({
  error: session.error,
  attempts: session.attempts,
})

const mapDispatchToProps = (dispatch) => ({
  confirm: (user) => confirm(user),
  clearError: () => clearError(),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterConfirmForm)
