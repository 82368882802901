import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'
import { color } from 'styled-system'

import { Box, Flex } from 'app/components/design-system-components'
import {
  StarRoundedOutline,
  StarRoundedSolid,
} from 'app/components/design-system-components/icons/social'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import {
  shortlistRegistrant,
  unshortlistRegistrant,
} from 'app/sm/inspections/helpers/api'

const StyledShortlist = styled(Flex)`
  svg {
    width: 1.3em;
    height: 1.3em;
    stroke-width: 1.5;
    ${color}
  }
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  margin-top: 2px;
`

function ShortlistAttendee({
  shortlisted = false,
  attendeeGUID = '',
  agencyGUID = '',
  fetchRegistrantInfo,
  showLabel = true,
  clickable = true,
  handleShortlist,
  viewingGUID = '',
}: {
  shortlisted: boolean
  fetchRegistrantInfo: Function
  attendeeGUID: string
  agencyGUID: string
  showLabel: boolean
  clickable: boolean
  handleShortlist: Function
  viewingGUID: string
}) {
  const onClickShortlist = () => {
    if (shortlisted) {
      unshortlistRegistrant(agencyGUID, attendeeGUID, viewingGUID)
        .then(() => {
          fetchRegistrantInfo?.()
          handleShortlist?.(attendeeGUID, shortlisted)
        })
        .catch((err: any) => snugNotifier.error(err?.message))
    } else {
      shortlistRegistrant(agencyGUID, attendeeGUID, viewingGUID)
        .then(() => {
          fetchRegistrantInfo?.()
          handleShortlist?.(attendeeGUID, shortlisted)
        })
        .catch((err: any) => snugNotifier.error(err?.message))
    }
  }

  return (
    <StyledShortlist
      alignItems="center"
      data-tooltip-id={`shortlist-icon-${attendeeGUID}`}
      data-tooltip-content=""
      onClick={clickable ? () => onClickShortlist() : null}
    >
      <Flex mr={3}>
        {shortlisted ? (
          <StarRoundedSolid color={theme.colors.gray300} />
        ) : (
          <StarRoundedOutline color={theme.colors.gray300} />
        )}
        <ReactTooltip id={`shortlist-icon-${attendeeGUID}`}>
          {shortlisted
            ? 'Shortlisted attendee without notification.'
            : 'Shortlist attendee without notification.'}
        </ReactTooltip>
      </Flex>
      {showLabel && <Box>Shortlisted</Box>}
    </StyledShortlist>
  )
}

export default ShortlistAttendee
