import React, { useState } from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import warningIcon from 'app/assets/icons/warning-lighter.svg'
import { Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import { STANDARD_TIMEOUT } from 'app/helpers/debounce'
import { updateTenantSettings } from 'app/services/http/properties/tenants'
import * as snugNotifier from 'app/services/snugNotifier'
import * as FormComponent from 'app/shared_components/form_component'
import Spinner from 'app/sm/common/spinner'
import * as helpers from 'app/sm/helpers'
import { NO, YES } from 'app/sm/helpers'
import ViewingContactItem from 'app/sm/inspections/components/viewing_contact_item'
import ViewingContactSearchSection from 'app/sm/inspections/components/viewing_contact_search_section'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import {
  NUMBER_TYPE_LANDLINE,
  NUMBER_TYPE_MOBILE,
} from 'app/utils/phonenumber/helpers'

let totalSearchRequestCount = 0
const Stages = {
  display: 'display',
  search: 'search',
  add: 'add',
}

const TenantModalCategory = {
  addTenants: 'addTenants',
  sendEntryNotice: 'sendEntryNotice',
}

const MODAL_TITLE_TEXT = {
  TENANTS: 'Tenants',
  SEND_CAMPAIGN_ENTRY_NOTICE: 'Send campaign entry notice',
  SEARCH_AND_ADD: 'Search and add',
  EDIT_TENANT: 'Edit tenant',
  ADD_NEW_TENANT: 'Add new tenant',
}

const modalTitlesCollection = [
  {
    conditionMetFunction: ({ stage, tenantModalCategory }) =>
      stage === Stages.display &&
      tenantModalCategory === TenantModalCategory.addTenants,
    titleText: MODAL_TITLE_TEXT.TENANTS,
  },
  {
    conditionMetFunction: ({ stage, tenantModalCategory }) =>
      stage === Stages.display &&
      tenantModalCategory === TenantModalCategory.sendEntryNotice,
    titleText: MODAL_TITLE_TEXT.SEND_CAMPAIGN_ENTRY_NOTICE,
  },
  {
    conditionMetFunction: ({ stage }) => stage === Stages.search,
    titleText: MODAL_TITLE_TEXT.SEARCH_AND_ADD,
  },
  {
    conditionMetFunction: ({ stage, from }) =>
      stage === Stages.add && from === Stages.display,
    titleText: MODAL_TITLE_TEXT.EDIT_TENANT,
  },
  {
    conditionMetFunction: ({ stage, from }) =>
      stage === Stages.add && from === Stages.search,
    titleText: MODAL_TITLE_TEXT.ADD_NEW_TENANT,
  },
]

export const SendEntryNoticesToTenant = ({
  tenant,
  onUpdateTenantClicked,
  chosenPropertyID,
}) => {
  const [sendEntryNotices, setSendEntryNotices] = useState(
    tenant.sendEntryNotices,
  )
  const onChangeSendEntryNotices = ({ value }) => {
    updateTenantSettings(chosenPropertyID, tenant.guid, {
      sendEntryNotices: value,
    })
      .then(() => {
        setSendEntryNotices(value)

        snugNotifier.success('Send entry notice updated')
      })
      .catch((err) => {
        snugNotifier.error(err.message || 'Failed to update entry notice')
      })
  }

  const { optedInToElectronicCommunication, guid = '', isLocked } = tenant
  const showDisabledTenantVersion = optedInToElectronicCommunication
  const tooltipIDForWarning = guid
  const tooltipIDForUpdate = guid + 'update'
  const tooltipTextForUpdate =
    'Please update in your Property Management System (e.g. PropertyMe)'
  return (
    <Flex alignItems="center" ml="80px" mb={5}>
      {showDisabledTenantVersion && (
        <Form.CheckBoxGeneral
          label="Send Entry Notices"
          id="sendEntryNotices"
          checked={sendEntryNotices}
          checkboxLabelSpanClassName="fs16 height-auto"
          labelClass="fs16 mb0 fw-normal"
          componentClassName="mb0"
          onChange={onChangeSendEntryNotices}
        />
      )}
      {!showDisabledTenantVersion && (
        <Flex alignItems="center" fontSize={5}>
          <Text mb={0} mr={3}>
            Send Entry Notices?
          </Text>
          <img
            src={warningIcon}
            alt="warning icon"
            width="18px"
            height="16px"
            data-tooltip-content=""
            data-tooltip-id={tooltipIDForWarning}
          />
          <ReactTooltip
            id={tooltipIDForWarning}
            data-type="info"
            place="bottom"
            className="tool-tip-style"
          >
            <span>
              Opted out from sending communications. Please update the
              preference for the tenant
            </span>
          </ReactTooltip>
          <Text mb={0}>Opted Out</Text>
          <Text
            onClick={() => (isLocked ? null : onUpdateTenantClicked(tenant))}
            mb={0}
            color="skyBlue"
            ml={3}
            style={{ cursor: 'pointer' }}
            data-tooltip-content=""
            data-tooltip-id={tooltipIDForUpdate}
          >
            update
          </Text>
          {isLocked && (
            <ReactTooltip
              id={tooltipIDForUpdate}
              data-type="info"
              place="bottom"
              className="tool-tip-style"
            >
              <span>{tooltipTextForUpdate}</span>
            </ReactTooltip>
          )}
        </Flex>
      )}
    </Flex>
  )
}

const ModalBottomButtonContainer = ({
  primaryButtonAction,
  primaryButtonText,
  secondaryButtonText,
  secondaryButtonAction,
  secondaryButtonType,
}) => {
  return (
    <div className="modal-footer border-none width100 display-flex">
      <div className="col-sm-6 div-order-1 width100">
        <Display.GenericButton
          text={secondaryButtonText}
          onClick={secondaryButtonAction}
          buttonType={secondaryButtonType}
        />
      </div>
      <div className="col-sm-6 div-order-2 width100">
        <Display.GenericButton
          text={primaryButtonText}
          onClick={primaryButtonAction}
        />
      </div>
    </div>
  )
}

export const DisplayTenants = ({
  chosenPropertyText,
  onStageChange,
  tenants,
  onListItemClicked,
  onRemoveClicked,
  removableTenantID,
  showSpinner,
  showAddButton = true,
  chosenPropertyID = '',
  showLeadingAndPropertyText = true,
}) => {
  const hasAssignedOwners = tenants && tenants.length > 0
  const leadingText = hasAssignedOwners
    ? 'Tenants for '
    : 'Please add tenants for '
  return (
    <div>
      {showLeadingAndPropertyText && (
        <p>
          {leadingText} <span className="fw500">{chosenPropertyText}</span>
        </p>
      )}
      {showAddButton && (
        <div className="display-flex">
          <Display.GenericButton
            text="Add tenant"
            componentClass="col-xs-4 pl0"
            onClick={() => {
              onStageChange(Stages.search)
            }}
          />
        </div>
      )}
      <div className="mt20 searched-result-section ra-card__list">
        {showSpinner && <Spinner />}
        {!showSpinner &&
          tenants.length > 0 &&
          tenants.map((tenant, index) => {
            return (
              <div className="viewing-items pt0 pb0" key={index}>
                <div className="content-wrapper pt5 pb5 cursor-pointer">
                  <ViewingContactItem
                    contact={tenant}
                    enableRemove
                    onClick={onListItemClicked}
                    onRemoveClicked={onRemoveClicked}
                    disableRemoveButton={removableTenantID === tenant.guid}
                  />
                  <div className="controls comment-wrap w45p xs-w100p" />
                </div>
                <SendEntryNoticesToTenant
                  tenant={tenant}
                  onUpdateTenantClicked={onListItemClicked}
                  chosenPropertyID={chosenPropertyID}
                />
              </div>
            )
          })}
      </div>
    </div>
  )
}

const SendEntryNotice = ({
  startDate,
  endDate,
  onStageChange,
  tenants,
  onListItemClicked,
  update,
  showSpinner,
  entryNoticeStartDate,
  entryNoticeEndDate,
  entryNoticeLastSent,
  inputErrors,
  chosenPropertyID = '',
  chosenPropertyText = '',
  onRemoveClicked,
}) => {
  const formattedStartDate =
    inputErrors.startDate !== dateTimeHelpers.INVALID_DATE_STRING
      ? startDate && moment(startDate).format(dateTimeHelpers.DATE_WITH_SLASH)
      : startDate
  const formattedEndDate =
    inputErrors.endDate !== dateTimeHelpers.INVALID_DATE_STRING
      ? endDate && moment(endDate).format(dateTimeHelpers.DATE_WITH_SLASH)
      : endDate
  return (
    <div>
      <div className="display-flex flex-direction-column">
        <p>
          Send a campaign entry notice to consenting tenants and cc the assigned
          team member. Send without tenants to receive a printable copy by
          email.
        </p>
        {entryNoticeLastSent.length > 0 && (
          <span>Last Sent: {entryNoticeLastSent}</span>
        )}
        <h4 className="display-flex fw500 flex-1 mt10 mb20">
          <span>Campaign dates</span>
        </h4>
        <div className="mb10">
          <div className="col-md-6 p0">
            <Form.InputFutureDate
              label="Start"
              value={formattedStartDate}
              error={inputErrors.startDate}
              onChange={update('startDate')}
              id="startDate"
              containerClassName="width100"
              componentClass="margin-profile-item-left"
              isValidDate={
                endDate
                  ? helpers.isDateAfterTodayAndBeforeTargerDate(endDate)
                  : helpers.validateFutureDate()
              }
              viewMode="days"
              customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
              readOnly={false}
            />
          </div>
          <div className="col-md-6 p0">
            <Form.InputDate
              label="End"
              value={formattedEndDate || null}
              error={inputErrors.endDate}
              onChange={update('endDate')}
              id="endDate"
              containerClassName="width100"
              componentClass="margin-profile-item-right"
              isValidDate={
                startDate
                  ? helpers.validateEndDate(startDate)
                  : helpers.validateFutureDate()
              }
              viewMode="days"
              customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
              readOnly={false}
              after={startDate}
            />
          </div>
        </div>
        <div className="mt20 searched-result-section ra-card__list">
          <div className="display-flex align-items-center mb20">
            <h4 className="display-flex fw500 mr20">
              <span>Tenants</span>
            </h4>
            <Display.GenericButton
              text="Add"
              buttonType="secondary"
              onClick={() => onStageChange(Stages.search)}
              buttonStyleClass={'height34px'}
            />
          </div>
          {showSpinner && <Spinner />}
          <DisplayTenants
            chosenPropertyText={chosenPropertyText}
            chosenPropertyID={chosenPropertyID}
            onStageChange={onStageChange}
            tenants={tenants}
            onListItemClicked={onListItemClicked}
            onRemoveClicked={onRemoveClicked}
            showSpinner={showSpinner}
            showAddButton={false}
            showLeadingAndPropertyText={false}
          />
        </div>
      </div>
    </div>
  )
}

const SearchSection = ({
  nameSearchText,
  mobileSearchText,
  onSearchTextChange,
  onAddClicked,
  contacts = [],
  onListItemClicked,
  showSpinner = false,
}) => {
  return (
    <div>
      <ViewingContactSearchSection
        nameSearchText={nameSearchText}
        mobileSearchText={mobileSearchText}
        onSearchTextChange={onSearchTextChange}
        onAddClicked={() => onAddClicked()}
      />
      <div className="searched-result-section ra-card__list">
        {showSpinner && <Spinner />}
        {!showSpinner &&
          contacts.length > 0 &&
          contacts.map((contact, index) => {
            return (
              <div className="viewing-items pt0 pb0" key={index}>
                <div className="content-wrapper pt5 pb5 cursor-pointer">
                  <ViewingContactItem
                    contact={contact}
                    onClick={onListItemClicked}
                  />
                  <div className="controls comment-wrap w45p xs-w100p" />
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

const AddTenantForm = ({
  form,
  inputErrors,
  update,
  onChangeRadioGroup,
  isLocked,
}) => {
  const {
    firstName,
    lastName,
    mobile,
    landline,
    email,
    tenantOptedInToElectronicCommunication,
  } = form
  return (
    <div className="display-flex flex-direction-column">
      <div className="mb10">
        <div className="col-md-6 p0">
          <Form.InputName
            label="First Name"
            value={firstName}
            error={inputErrors.firstName}
            onChange={update('firstName')}
            id="firstName"
            inputClass="width100"
            componentClass="margin-profile-item-left"
            labelClass="top15"
            disabled={isLocked}
          />
        </div>
        <div className="col-md-6 p0">
          <Form.InputName
            label="Last Name"
            value={lastName}
            error={inputErrors.lastName}
            onChange={update('lastName')}
            id="firstName"
            inputClass="width100"
            componentClass="margin-profile-item-right"
            labelClass="top15"
            disabled={isLocked}
          />
        </div>
      </div>
      <div className="mb10">
        <div className="col-md-6 p0">
          <Form.InputPhoneNumber
            label="Mobile Number"
            value={mobile}
            error={inputErrors.mobile}
            onChange={update('mobile')}
            labelClass="top20"
            containerClassName="width100"
            componentClass="margin-profile-item-left"
            disabled={isLocked}
            required
          />
        </div>
        <div className="col-md-6 p0">
          <Form.InputPhoneNumber
            label="Home Phone"
            value={landline}
            error={inputErrors.landline}
            placeholder="02 1234 1234"
            onChange={update('landline')}
            labelClass="top20"
            containerClassName="width100"
            componentClass="margin-profile-item-right"
            numberType={NUMBER_TYPE_LANDLINE}
            required
          />
        </div>
      </div>
      <div className="mb10">
        <div className="col-md-6 p0">
          <Form.InputEmail
            label="Email"
            value={email}
            error={inputErrors.email}
            onChange={update('email')}
            inputClass="width100"
            componentClass="margin-profile-item-left"
            labelClass="top15"
            disabled={isLocked}
            isOptional
          />
        </div>
      </div>
      <div className="mb10 width100">
        <div className="col-md-12 p0">
          <FormComponent.RadioGroup
            label="Tenant has opted out of receiving electronic tenancy notices"
            id="tenantOptedInToElectronicCommunication"
            field="tenantOptedInToElectronicCommunication"
            error={inputErrors.tenantOptedInToElectronicCommunication}
            componentClassName="property-details-radio-group"
          >
            <FormComponent.Checkbox
              id="false"
              label="Yes"
              checked={tenantOptedInToElectronicCommunication === NO}
              onChange={onChangeRadioGroup(
                'tenantOptedInToElectronicCommunication',
              )}
            />

            <FormComponent.Checkbox
              id="true"
              label="No"
              checked={tenantOptedInToElectronicCommunication === YES}
              onChange={onChangeRadioGroup(
                'tenantOptedInToElectronicCommunication',
              )}
            />
          </FormComponent.RadioGroup>
        </div>
      </div>
    </div>
  )
}
class AddTenantsModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stage: Stages.display,
      mobileSearchText: '',
      nameSearchText: '',
      form: {
        firstName: '',
        lastName: '',
        mobile: '',
        landline: '',
        email: '',
        tenantOptedInToElectronicCommunication: '',
        sendEntryNotices: false,
      },
      inputErrors: {},
    }
    this.onStageChange = this.onStageChange.bind(this)
    this.onListItemClicked = this.onListItemClicked.bind(this)
    this.onAddClicked = this.onAddClicked.bind(this)
    this.onBackIconClicked = this.onBackIconClicked.bind(this)
    this.clearTenantStateInAddForm = this.clearTenantStateInAddForm.bind(this)
    this.onRemoveClicked = this.onRemoveClicked.bind(this)
    this.onNoticeSendClicked = this.onNoticeSendClicked.bind(this)
  }

  componentDidMount() {
    this.onFetchCurrentTenants()

    if (!!this.props.bridgedTenantToUpdate) {
      this.onListItemClicked(this.props.bridgedTenantToUpdate)
    }
  }

  onAddClicked = () => {
    this.onStageChange(Stages.add)
    this.clearTenantStateInAddForm()
    this.setState({ from: Stages.search })
  }

  onBackIconClicked = () => {
    const { from = Stages.display } = this.state
    this.onStageChange(from)
    this.setState({ from: Stages.display })
  }

  onChangeRadioGroup = (radioGroupID) => {
    return (event) => {
      const { target } = event
      const { id } = target
      this.setState({
        form: {
          ...this.state.form,
          [radioGroupID]: id,
        },
        errors: { ...this.state.inputErrors, [radioGroupID]: '' },
      })
    }
  }

  onChangeSendEntryNotices = () => {
    const { sendEntryNotices } = this.state.form
    this.setState({
      form: {
        ...this.state.form,
        sendEntryNotices: !sendEntryNotices,
      },
      errors: { ...this.state.inputErrors, sendEntryNotices: '' },
    })
  }

  onFetchCurrentTenants = () => {
    const { fetchCurrentTenants, teamId, chosenPropertyID } = this.props
    this.setState({
      showTenantSpinner: true,
    })
    fetchCurrentTenants(teamId, chosenPropertyID)
      .then((result) => {
        const { tenants = [] } = result
        this.setState({
          tenants: tenants.map((value) =>
            Object.assign(value, {
              tenantOptedInToElectronicCommunication:
                value.optedInToElectronicCommunication.toString(),
            }),
          ),
          showTenantSpinner: false,
        })
      })
      .catch((error) => {
        snugNotifier.error(error)
        this.setState({
          showTenantSpinner: false,
        })
      })
  }

  onListItemClicked = (ppl) => {
    const {
      email,
      firstName,
      lastName,
      phone,
      isLocked,
      guid: tenantId = '',
      landline = '',
      tenantOptedInToElectronicCommunication = '',
      sendEntryNotices,
    } = ppl
    this.setState({
      form: {
        ...this.state.form,
        email,
        firstName,
        lastName,
        mobile: phone,
        landline,
        tenantOptedInToElectronicCommunication,
        sendEntryNotices,
      },
      isFormLocked: isLocked,
      tenantId,
      from: tenantId ? Stages.display : Stages.search,
    })
    this.onStageChange(Stages.add)
  }

  onNoticeSendClicked = () => {
    const {
      form: { startDate, endDate },
    } = this.state
    const { offers, sendEntryNotice, toggleModal } = this.props
    const { guidID: offerId = '' } =
      offers && offers.length > 0 ? offers[0] : {}
    const data = {
      entryNoticeStartDate: moment(startDate).format(),
    }
    if (endDate) {
      data['entryNoticeEndDate'] = moment(endDate).format()
    }

    if (offerId === '') {
      snugNotifier.error('Offer does not exist')
      return
    }

    if (!startDate) {
      snugNotifier.error('Start date cannot be empty')
      return
    }

    if (window.confirm('Send campaign entry notice?')) {
      sendEntryNotice(offerId, data)
        .then(() => {
          snugNotifier.success(
            'Send campaign entry notice requested successfully',
          )
          toggleModal(helpers.modalNameCollection.addTenantsModal)
        })
        .catch((error) => {
          snugNotifier.error(error)
        })
    }
  }

  onRemoveClicked = (event, tenant) => {
    const { removeCurrentTenants, chosenPropertyID } = this.props
    const { guid: tenantId } = tenant
    this.setState({
      removableTenantID: tenantId,
    })
    removeCurrentTenants(chosenPropertyID, tenantId)
      .then(() => {
        this.onFetchCurrentTenants()
        this.setState({ removableTenantID: '' })
      })
      .catch((error) => {
        this.setState({ removableTenantID: '' })
        snugNotifier.error(error)
      })
    event.preventDefault()
  }

  onSaveTenantClicked = () => {
    const { form, tenantId, from = Stages.display, inputErrors } = this.state
    const {
      firstName,
      lastName,
      mobile,
      landline,
      email,
      tenantOptedInToElectronicCommunication,
      sendEntryNotices,
    } = form
    const { chosenPropertyID, addCurrentTenants, updateCurrentTenants } =
      this.props
    const optedInToElectronicCommunication =
      tenantOptedInToElectronicCommunication === YES
    const data = {
      email,
      firstName,
      lastName,
      phone: mobile,
      landline,
      optedInToElectronicCommunication,
      sendEntryNotices,
    }

    if (Object.values(inputErrors).find((error) => error !== '')) {
      snugNotifier.error('Please enter proper information for this tenant')
      return
    }

    if (!firstName) {
      snugNotifier.error('Please enter first name information')
      return
    }

    if (!mobile && !email) {
      snugNotifier.error('Please enter either mobile number or email address')
      return
    }

    if (!tenantOptedInToElectronicCommunication) {
      snugNotifier.error('Please select electronic tenancy notice consent')
      return
    }

    if (tenantId) {
      updateCurrentTenants(chosenPropertyID, tenantId, data)
        .then(() => {
          this.onFetchCurrentTenants()
          this.onStageChange(Stages.display)
          this.clearStateField(from)
          snugNotifier.success('The tenant has been updated')
        })
        .catch((error) => {
          snugNotifier.error(error)
        })
    } else {
      addCurrentTenants(data, chosenPropertyID)
        .then(() => {
          this.onFetchCurrentTenants()
          this.onStageChange(Stages.display)
          this.clearStateField(from)
          snugNotifier.success('The tenant has been added')
        })
        .catch((error) => {
          snugNotifier.error(error)
        })
    }
  }

  onSearchTextChange = (field) => {
    return (event) => {
      const { fetchAgencyApplicantsElasticSearchResult, teamId } = this.props

      clearInterval(this.searchContacts)

      this.setState(
        {
          [field]: event.target.value,
        },
        () => {
          const { mobileSearchText = '', nameSearchText = '' } = this.state
          const formattedMobileText =
            mobileSearchText.indexOf('04') === 0
              ? mobileSearchText.replace(/^.{2}/g, '+614')
              : mobileSearchText
          const finalSearchText =
            `${formattedMobileText} ${nameSearchText}`.trim()
          let currentSearchRequestCount = ++totalSearchRequestCount
          this.setState({
            showContactSpinner: true,
          })
          this.searchContacts = setTimeout(
            () =>
              fetchAgencyApplicantsElasticSearchResult(teamId, finalSearchText)
                .then((result) => {
                  if (currentSearchRequestCount === totalSearchRequestCount) {
                    this.setState({
                      contacts: result.contacts.sort(
                        helpers.sortArrayAlphabeticallyByFirstName,
                      ),
                      total: result.total,
                      showContactSpinner: false,
                    })
                  }
                })
                .catch((error) => {
                  this.setState({
                    apiError: error,
                    showContactSpinner: false,
                  })
                }),
            STANDARD_TIMEOUT,
          )
        },
      )
    }
  }

  onStageChange = (stage) => {
    this.setState({ stage })
  }

  clearStateField = (field, defaultValue = '') => {
    this.setState({
      [field]: defaultValue,
    })
  }

  clearTenantStateInAddForm = () => {
    this.setState({
      form: {
        email: '',
        firstName: '',
        lastName: '',
        mobile: '',
        landline: '',
      },
      tenantId: '',
    })
  }

  update = (field) => {
    return (data) => {
      let updatedError = data.error
      this.setState({
        form: {
          ...this.state.form,
          [field]: data.value,
        },
        inputErrors: {
          ...this.state.inputErrors,
          [field]: updatedError,
        },
      })
    }
  }

  render() {
    const {
      stage = Stages.display,
      nameSearchText = '',
      mobileSearchText = '',
      contacts = [],
      form = {},
      inputErrors = {},
      tenants = [],
      from = Stages.display,
      isFormLocked,
      removableTenantID,
      showContactSpinner = false,
      showTenantSpinner = false,
    } = this.state
    const { chosenPropertyID } = this.props
    const { startDate, endDate } = form
    const { toggleModal, chosenPropertyText, tenantModalCategory, offers } =
      this.props || {}
    const offerExists = offers && offers.length > 0
    const entryNoticeStartDate = offerExists
      ? offers[0].entryNoticeStartDate
      : null
    const entryNoticeEndDate = offerExists ? offers[0].entryNoticeEndDate : null
    const entryNoticeLastSent =
      offerExists && offers[0].entryNoticeLastSent.length > 0
        ? moment(offers[0].entryNoticeLastSent).format(
            helpers.dateWithTimeAndWeekDayTimeFormatNoComma,
          )
        : ''
    let modalTitleText = 'Tenants'
    const modalStatus = {
      stage,
      tenantModalCategory,
      from,
    }
    modalTitleText = modalTitlesCollection.find((modalTitle) =>
      modalTitle.conditionMetFunction(modalStatus),
    ).titleText

    const bottomButtons = [
      {
        displayCondition: stage === Stages.add,
        primaryButtonText: 'Save',
        primaryButtonAction: () => this.onSaveTenantClicked(),
        secondaryButtonText: 'Cancel',
        secondaryButtonAction: () => this.onStageChange(Stages.display),
        secondaryButtonType: 'secondary',
      },
      {
        displayCondition:
          stage === Stages.display &&
          tenantModalCategory === TenantModalCategory.sendEntryNotice,
        primaryButtonText: 'Send',
        primaryButtonAction: () => this.onNoticeSendClicked(),
        secondaryButtonText: 'Cancel',
        secondaryButtonAction: () =>
          toggleModal(helpers.modalNameCollection.addTenantsModal),
        secondaryButtonType: 'secondary',
      },
    ]

    const modalCloser = (
      <i
        className="fa fa-times font-size-24"
        onClick={() => toggleModal(helpers.modalNameCollection.addTenantsModal)}
      />
    )

    const modalHeader = (
      <div className="display-flex align-items-center width100">
        {stage !== Stages.display && (
          <div
            className="cursor-pointer"
            onClick={() => this.onBackIconClicked()}
          >
            <i className="icon-arrow-left" />
          </div>
        )}
        <h4 className="display-flex align-items-center justify-content-center fw500 flex-1">
          <span>{modalTitleText}</span>
        </h4>
      </div>
    )

    const modalBody = (
      <div className="p15 pb80">
        {stage === Stages.display &&
          tenantModalCategory === TenantModalCategory.addTenants && (
            <DisplayTenants
              chosenPropertyText={chosenPropertyText}
              onStageChange={this.onStageChange}
              tenants={tenants}
              onListItemClicked={this.onListItemClicked}
              onRemoveClicked={this.onRemoveClicked}
              removableTenantID={removableTenantID}
              showSpinner={showTenantSpinner}
              chosenPropertyID={chosenPropertyID}
            />
          )}
        {stage === Stages.display &&
          tenantModalCategory === TenantModalCategory.sendEntryNotice && (
            <SendEntryNotice
              startDate={startDate}
              endDate={endDate}
              onStageChange={this.onStageChange}
              tenants={tenants}
              onListItemClicked={this.onListItemClicked}
              update={this.update}
              showSpinner={showTenantSpinner}
              entryNoticeStartDate={entryNoticeStartDate}
              entryNoticeEndDate={entryNoticeEndDate}
              entryNoticeLastSent={entryNoticeLastSent}
              inputErrors={inputErrors}
              chosenPropertyText={chosenPropertyText}
              // onStageChange={this.onStageChange}
              // tenants={tenants}
              // onListItemClicked={this.onListItemClicked}
              onRemoveClicked={this.onRemoveClicked}
              removableTenantID={removableTenantID}
              // showSpinner={showTenantSpinner}
              chosenPropertyID={chosenPropertyID}
            />
          )}
        {stage === Stages.search && (
          <SearchSection
            nameSearchText={nameSearchText}
            mobileSearchText={mobileSearchText}
            onSearchTextChange={this.onSearchTextChange}
            onAddClicked={this.onAddClicked}
            contacts={contacts}
            onListItemClicked={this.onListItemClicked}
            showSpinner={showContactSpinner}
          />
        )}
        {stage === Stages.add && (
          <AddTenantForm
            inputErrors={inputErrors}
            form={form}
            update={this.update}
            onChangeRadioGroup={this.onChangeRadioGroup}
            isLocked={isFormLocked}
            onChangeSendEntryNotices={this.onChangeSendEntryNotices}
          />
        )}
      </div>
    )

    const modalBottom = bottomButtons.map((bottomButton) => {
      const {
        displayCondition,
        primaryButtonText,
        primaryButtonAction,
        secondaryButtonText,
        secondaryButtonAction,
        secondaryButtonType,
      } = bottomButton
      if (displayCondition) {
        return (
          <ModalBottomButtonContainer
            primaryButtonText={primaryButtonText}
            primaryButtonAction={primaryButtonAction}
            secondaryButtonText={secondaryButtonText}
            secondaryButtonAction={secondaryButtonAction}
            secondaryButtonType={secondaryButtonType}
          />
        )
      }
      return <div />
    })

    return (
      <div>
        <Display.ModalWithScroll
          modalCloser={modalCloser}
          modalHeader={modalHeader}
          modalBody={modalBody}
          modalBottom={modalBottom}
          takeMinHeight
        />
      </div>
    )
  }
}

export default AddTenantsModal
