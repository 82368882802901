import React from 'react'

import moment from 'moment'
import qs from 'qs'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import HistoryBoxDetails from 'app/bond_cover/renters/history/components/history_box_details'
import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import GenericButtonComponent from 'app/components/buttons/default/component'
import {
  Alert,
  Box,
  Button,
  Flex,
  HorizontalLine,
  LoadingSection,
  Spinner,
  TextSpan,
} from 'app/components/design-system-components'
import { AlertInfo } from 'app/components/design-system-components/AlertInfo'
import { BallotAlertDocumentUpload } from 'app/components/design-system-components/BallotAlertDocumentUpload'
import * as Display from 'app/components/display/display'
import * as NewForm from 'app/components/forms/forms'
import ProgressBarComponent from 'app/components/progress_bar/connection'
import ErrorMessages from 'app/constants/error_messages'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ratingVersions } from 'app/match/rental_reference/rating_categories.constants'
import BasicModal from 'app/modals/basic_modal'
import UpdateCurrentModal from 'app/profile/renter/updateCurrentModal'
import { checkIfAddressIsLessThanSpecifiedMonths } from 'app/profile/renter/updateCurrentModal/helpers'
import { UPDATE_MODAL_TYPE_ADDRESS } from 'app/profile/renter/updateCurrentModal/helpers'
import { getApplicationConfigForProperty } from 'app/services/http/teams/settings'
import { updateAddressHistoryEndDate } from 'app/services/http/updateCurrentData'
import * as snugNotifier from 'app/services/snugNotifier'
import Checkbox from 'app/shared_components/checkbox'
import getParameter from 'app/shared_components/parameter_parser'
import Header from 'app/shared_components/rental_reputation_header_connection'
import { history } from 'app/shared_components/router'
import {
  aggregateTotalRentingDuration,
  isFromApplication,
  parseDataUtil,
  urlTo,
} from 'app/sm/helpers'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import { STANDARD_DATE } from 'app/utils/datetime/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  loadingStatesIds,
} from 'app/utils/loading-states'
import * as textHelpers from 'app/utils/text/helpers'
import { ADDRESS_HISTORY_HEADER_ALERT } from 'app/utils/text/helpers'

const requiredDurationByMonths = 22
const historiesGapToleranceInDays = 30
const addressHistoryRequired = 'address_history_require'

const StyledFlex = styled(Flex)`
  @media (max-width: 576px) {
    justify-content: center;
  }
`

function isRecent2YearsRequirementMet(rentalHistory = []) {
  const {
    flattened: { months },
  } =
    dateTimeHelpers.aggregateTotalRentingDurationInTheLast2Years(rentalHistory)
  if (months < requiredDurationByMonths || rentalHistory.length === 0) {
    return false
  }
  return true
}

function isAddressHistoryRequirementsMet(
  rentalHistory = [],
  noFurtherAddressHistory,
) {
  const {
    flattened: { months },
  } = aggregateTotalRentingDuration(rentalHistory)
  if (
    (months < requiredDurationByMonths && !noFurtherAddressHistory) ||
    rentalHistory.length === 0
  ) {
    return false
  }
  return true
}

// Used in MultiSelect
const YES_LABEL = 'Yes'
const NO_LABEL = 'No'

class RentalHistoryList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      removeButtonClicked: false,
      apiError: null,
      modalOpen: false,
      noFurtherAddressHistory: false,
      confirmDeleteModal: false,
      confirmEditModal: false,
      rentalHistoryId: '',
      referenceVerified: false,
      hasRentalBondsAccount: false,
      reputationRetrieveLoadingStates: createInitialLoadingState(),
      reputationUpdateLoadingStates: createInitialLoadingState(),
      twoYearsAddressHistoryEnabled: false,
      isLoadingTeamSettings: false,
      showUpdateCurrentHistoryModal: false,
      currentAddress: {},
      numMonthsMinusSpecifiedMonths: 0,
      hasErrorUpdatingEndDate: false,
      isBallotApplication: false,
    }

    this.reputationRetrieveLoadingStateUtils = createLoadingStateUtils(
      (state) =>
        this.setState({
          reputationRetrieveLoadingStates: state,
        }),
    )

    this.reputationUpdateLoadingStateUtils = createLoadingStateUtils((state) =>
      this.setState({
        reputationUpdateLoadingStates: state,
      }),
    )

    this.addMoreRentalHistoriesModalConfigs = {
      typicalModalConfig: {
        body: 'Typically, property managers require 2 years of address history.',
        secondaryButtonLabel: 'No further history',
        secondaryButtonAction: () => this.onModalSecondaryButtonClicked(),
      },
      mostRecentRentalHistoriesReqConfig: {
        body: 'This property manager requires the last 2 years of continuous residential address history (including renting, ownership or other arrangements).',
        secondaryButtonLabel: 'Close',
        secondaryButtonAction: () => this.toggleModal('modalOpen'),
      },
    }
  }

  componentDidMount() {
    this.props.fetchRentalHistory()
    this.props.fetchRentalHistoryRatingCategories()
    this.reputationRetrieveLoadingStateUtils.startLoading()
    this.props
      .retrieveReputationPreference()
      .then(({ preference }) => {
        this.reputationRetrieveLoadingStateUtils.markDoneSuccessfully()
        this.setState({
          noFurtherAddressHistory: preference.optOutRentalHistory,
          hasRentalBondsAccount: preference.hasRentalBondsAccount,
        })
      })
      .catch(this.reputationRetrieveLoadingStateUtils.setError)
    this.props.fetchCompleteness()

    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { id: agencyGUID, ballot = false, applicationId } = curQueries || {}
    if (
      agencyGUID &&
      agencyGUID !== '' &&
      applicationId &&
      applicationId !== ''
    ) {
      const applicationGUID = applicationId?.toString()?.split(',')[0]
      this.props.fetchApplication(applicationGUID)
      this.callFetchTeamSettingInfo(agencyGUID, applicationGUID)
    }
    if (ballot && ballot === 'true') {
      this.setState({
        isBallotApplication: true,
      })
    }
  }

  onAddressHistoryCheckboxChange = () => {
    this.setState({
      noFurtherAddressHistory: !this.state.noFurtherAddressHistory,
    })
  }

  onBondAccountBoolValueChange = (val) => {
    this.setState({
      hasRentalBondsAccount: val === YES_LABEL,
    })
  }

  onModalSecondaryButtonClicked = () => {
    const { addRentalReputationBackUrl } = this.props
    const { pathname, search } = window.location

    this.setState({ noFurtherAddressHistory: true }, () => {
      this.callChangeReputationPreference()
        .then(() => {
          addRentalReputationBackUrl(pathname + search)
          history.push(`${urlTo('EmploymentDetailsList')}${search}`)
        })
        .catch(snugNotifier.error)
    })
  }

  callChangeReputationPreference = () => {
    const { reputationPreference, changeReputationPreference } = this.props
    const { noFurtherAddressHistory, hasRentalBondsAccount } = this.state
    this.reputationUpdateLoadingStateUtils.startLoading()
    return changeReputationPreference({
      ...reputationPreference,
      optOutRentalHistory: noFurtherAddressHistory,
      hasRentalBondsAccount,
    }).then(({ preference }) => {
      this.reputationUpdateLoadingStateUtils.markDoneSuccessfully()
      snugNotifier.success('Address History updated successfully!')
      this.setState({
        noFurtherAddressHistory: preference.optOutRentalHistory,
        hasRentalBondsAccount: preference.hasRentalBondsAccount,
      })
    })
  }

  isValidRentalHistories = () => {
    const { rentalHistory = [] } = this.props
    const { twoYearsAddressHistoryEnabled, noFurtherAddressHistory } =
      this.state

    if (
      twoYearsAddressHistoryEnabled &&
      !isRecent2YearsRequirementMet(rentalHistory)
    ) {
      return false
    }

    return isAddressHistoryRequirementsMet(
      rentalHistory,
      noFurtherAddressHistory,
    )
  }

  checkAddressHistoryBeforeNext = (isFromApplicationForm = false) => {
    const { addRentalReputationBackUrl } = this.props
    const validHistory = this.isValidRentalHistories()
    this.setState({ showUpdateCurrentHistoryModal: false })

    if (!validHistory) {
      this.toggleModal('modalOpen')
    } else {
      this.callChangeReputationPreference()
        .then(() => {
          addRentalReputationBackUrl(
            window.location.pathname + window.location.search,
          )

          history.push(
            `${urlTo('EmploymentDetailsList')}${window.location.search}`,
          )
        })
        .catch(snugNotifier.error)
    }
  }

  confirmDeleteRentalHistory = (rentalHistoryId, referenceVerified) => {
    this.setState({
      confirmDeleteModal: true,
      referenceVerified: referenceVerified,
      rentalHistoryId: rentalHistoryId,
    })
  }

  confirmEditRentalHistory = (rentalHistoryId) => {
    this.setState({ confirmEditModal: true, rentalHistoryId: rentalHistoryId })
  }

  deleteRentalHistory = () => {
    const { deleteRentalHistory, fetchRentalHistory } = this.props
    const { rentalHistoryId } = this.state
    if (rentalHistoryId !== '') {
      this.setState({ removeButtonClicked: true, apiError: null })
      deleteRentalHistory(
        rentalHistoryId,
        () => {
          this.setState({
            removeButtonClicked: false,
            confirmDeleteModal: false,
          })
          fetchRentalHistory()
        },
        (responseText) => {
          this.setState({
            removeButtonClicked: false,
            apiError: responseText,
            rentalHistoryId: '',
            confirmDeleteModal: false,
          })
        },
      )
    }
  }

  callFetchTeamSettingInfo = (teamId, applicationID) => {
    const { fetchTeamSettingInformation } = this.props
    this.setState({ isLoadingTeamSettings: true })
    fetchTeamSettingInformation(teamId, 'application')
      .then((teamSettingInfo) => {
        const { application: applicationSettingInfo = {} } = teamSettingInfo
        const parsedApplicationSettingInfo =
          parseDataUtil.convertObjValueFromStringToBoolean(
            applicationSettingInfo,
          )
        const addressHistoryEnabled =
          parsedApplicationSettingInfo[addressHistoryRequired]
        this.setState({
          twoYearsAddressHistoryEnabled: addressHistoryEnabled,
        })
      })
      .catch((error) => {
        this.setState({ error })
      })
      .finally(() => this.setState({ isLoadingTeamSettings: false }))
  }

  toggleModal = (toggleField) => {
    this.setState({
      [toggleField]: !this.state[toggleField],
      rentalHistoryId: '',
    })
  }

  checkShowUpdateCurrentHistory = () => {
    const { rentalHistory } = this.props
    const { hasErrorUpdatingEndDate } = this.state
    const {
      isLessThanSpecifiedMonths,
      currentAddress,
      numMonthsMinusSpecifiedMonths,
    } = checkIfAddressIsLessThanSpecifiedMonths(rentalHistory)
    this.setState({
      showUpdateCurrentHistoryModal:
        currentAddress && currentAddress.address && isLessThanSpecifiedMonths,
      currentAddress,
      numMonthsMinusSpecifiedMonths: numMonthsMinusSpecifiedMonths,
    })
    if (!isLessThanSpecifiedMonths && !hasErrorUpdatingEndDate) {
      this.checkAddressHistoryBeforeNext()
    }
  }

  toggleUpdateCurrentModal = () => {
    const { showUpdateCurrentHistoryModal } = this.state
    this.setState({
      showUpdateCurrentHistoryModal: !showUpdateCurrentHistoryModal,
    })
  }

  clickNextFunctionFromParentComponent = () => {
    this.checkShowUpdateCurrentHistory()
    this.checkAddressHistoryBeforeNext()
  }

  continueActionInUpdateCurrentModal = (data) => {
    const { fetchRentalHistory, backUrl } = this.props
    const { currentAddress } = this.state
    const { guidID = '' } = currentAddress
    const { needsToUpdateAndCallEndpoint, newEndDate, needsToGoToAddPage } =
      data
    const { value: newEndDateValue = '' } = newEndDate
    const payload = {
      rentalHistoryGUID: guidID,
      endDate: moment(newEndDateValue, dateTimeHelpers.DATE_WITH_DASH).format(
        dateTimeHelpers.DEFAULT_UNIX_TIME_Z,
      ),
    }
    const propertyId = getParameter('propertyId')
    const applicationId = getParameter('applicationId')
    const isFromApplicationForm =
      isFromApplication(backUrl) || propertyId || applicationId
    let hasError = false
    if (needsToUpdateAndCallEndpoint) {
      updateAddressHistoryEndDate(payload)
        .then(() => {
          snugNotifier.success(
            'End date for rental history successfully updated',
          )
          this.setState({
            hasErrorUpdatingEndDate: false,
          })
          if (needsToGoToAddPage) {
            history.push(urlTo('addRentalHistory'))
            return
          }
          fetchRentalHistory()
        })
        .catch((error) => {
          snugNotifier.error(error.message)
          hasError = true
          this.setState({
            hasErrorUpdatingEndDate: true,
          })
        })
        .finally(() => {
          if (!hasError) {
            this.toggleUpdateCurrentModal()
            if (!needsToGoToAddPage) {
              this.checkAddressHistoryBeforeNext(isFromApplicationForm)
            }
          }
        })
    } else {
      this.checkAddressHistoryBeforeNext(isFromApplicationForm)
    }
  }

  renderAddMoreRentalHistoriesModal = () => {
    const { modalOpen, twoYearsAddressHistoryEnabled } = this.state
    const { rentalHistory } = this.props
    const { mostRecentRentalHistoriesReqConfig, typicalModalConfig } =
      this.addMoreRentalHistoriesModalConfigs

    const modalConfig = twoYearsAddressHistoryEnabled
      ? mostRecentRentalHistoriesReqConfig
      : typicalModalConfig

    const { body, secondaryButtonAction, secondaryButtonLabel } = modalConfig

    const isRentalHistoryEmpty = !rentalHistory || !rentalHistory.length

    return (
      modalOpen && (
        <BasicModal
          toggleModal={() => this.toggleModal('modalOpen')}
          title={'Add More Address History'}
          body={body}
          primaryButtonLabel={'Add History'}
          primaryButtonAction={() => history.push(urlTo('addRentalHistory'))}
          secondaryButtonLabel={secondaryButtonLabel}
          secondaryButtonAction={secondaryButtonAction}
          secondaryButtonClassName={isRentalHistoryEmpty && 'd-none'}
        />
      )
    )
  }

  renderHistoryGapAlert(olderHist, newerHist) {
    if (!olderHist || !olderHist.endDate) return
    const gapStart = moment(olderHist.endDate)
    const gapEnd = newerHist?.startDate ? moment(newerHist.startDate) : moment() // if no gap end we set it to now
    if (gapEnd.diff(gapStart, 'days') < historiesGapToleranceInDays) return
    let gapPeriodText
    if (newerHist?.startDate) {
      const formattedGapStart = gapStart.format(STANDARD_DATE)
      const formattedGapEnd = gapEnd.format(STANDARD_DATE)
      gapPeriodText = `between ${formattedGapStart} and ${formattedGapEnd}`
    } else {
      // no gap end, so we alert for a gap since gap start
      const formattedGapStart = gapStart.format(STANDARD_DATE)
      gapPeriodText = `since ${formattedGapStart}`
    }

    return (
      <Alert variant="blueWithBg">
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <TextSpan as="div">
            Hey {this.props.currentUser?.firstName}, where were you living{' '}
            {gapPeriodText}?
            <br />
            Please add details to meet applications requirements
          </TextSpan>
          <Link to={urlTo('addRentalHistory')}>
            <Button variant="solidSuccessStrong">Add</Button>
          </Link>
        </Flex>
      </Alert>
    )
  }

  render() {
    const {
      rentalHistory = [],
      backUrl,
      property,
      ratingCategories,
      completeness,
      applicationDetails = {},
    } = this.props
    const { days, months, years } = aggregateTotalRentingDuration(rentalHistory)
    const propertyId = getParameter('propertyId')
    const applicationId = getParameter('applicationId')
    const isFromApplicationForm =
      isFromApplication(backUrl) || propertyId || applicationId
    const {
      removeButtonClicked,
      apiError,
      noFurtherAddressHistory,
      confirmDeleteModal,
      confirmEditModal,
      referenceVerified,
      hasRentalBondsAccount,
      reputationRetrieveLoadingStates,
      reputationUpdateLoadingStates,
      isLoadingTeamSettings,
      showUpdateCurrentHistoryModal,
      currentAddress,
      numMonthsMinusSpecifiedMonths,
      isBallotApplication,
      // requestReasonForMoving,
    } = this.state
    const { config: applicationConfig = {} } = applicationDetails || {}
    const { applicationsSettingsConfig = {} } = applicationConfig || {}
    const {
      exclude_request_reason_for_moving: excludeRequestReasonForMoving = false,
    } = applicationsSettingsConfig || {}
    const showAddLink = !noFurtherAddressHistory
    const totalTenure = years * 365 + months * 31 + days
    const recommendedTenure = 365 * 2
    const deleteVerifiedAddressText =
      'Reference has been provided for Address History. Are you sure?'
    const deleteUnverifiedAddressText =
      'Are you sure you would like to remove this address?'
    const editVerifiedAddressText =
      'A reference has been provided for this Address History and can not be edited. Please remove the reference and resend if updates are required.'
    const modalText = confirmEditModal
      ? editVerifiedAddressText
      : referenceVerified
      ? deleteVerifiedAddressText
      : deleteUnverifiedAddressText

    let bondAccountYesNoOptions = [
      { value: YES_LABEL, picked: hasRentalBondsAccount },
      { value: NO_LABEL, picked: !hasRentalBondsAccount },
    ]

    const addMoreRentalHistoriesModal = this.renderAddMoreRentalHistoriesModal()

    return (
      <Display.CenterContentContainer componentClass="width100">
        <ProgressBarComponent
          completeness={completeness}
          clickNextFunctionFromParentComponent={() => {
            this.clickNextFunctionFromParentComponent()
          }}
        />
        <RentalReputationHeaderReminder
          property={property}
          backUrl={backUrl}
          remindText={'Current application'}
          isFromApplicationForm={isFromApplicationForm}
          title="Address History"
        />

        <Header
          title="Address History"
          message={
            <>
              {isBallotApplication && <BallotAlertDocumentUpload />}
              <div>
                {textHelpers.ADDRESS_HISTORY_HEADER_GUIDANCE}
                {!isBallotApplication &&
                  textHelpers.RENTAL_HISTORY_BALLOT_APPLICATION_MESSAGE_NOTE}
              </div>
            </>
          }
        />

        <AlertInfo mb={8} message={ADDRESS_HISTORY_HEADER_ALERT} />

        {/* {showAddLink && (
          <div className="table height60 mb30">
            <div className="cell-m">
              <Link
                className="btn btn-round"
                to={urlTo('addRentalHistory')}
                title="Add address history"
              >
                <i className="icon-add" />
              </Link>
            </div>
            <div className="cell-m-100 pl20">
              <h4>
                {rentalHistory.length > 0
                  ? `Add more history`
                  : `Add address history`}
              </h4>
            </div>
          </div>
        )} */}

        {!isBallotApplication && (
          <LoadingSection
            actionHandler={() => this.props.retrieveReputationPreference()}
            loadingState={reputationRetrieveLoadingStates}
            loaderProps={{ fontSize: '20px' }}
          >
            <h3>Rental bonds online</h3>
            <Box mt="30px">
              <NewForm.MultiSelect
                title="Do you have an account with rental bonds online in the State where the property is located?"
                titleTextClass="font-size-18"
                options={bondAccountYesNoOptions}
                className="p0"
                onChange={this.onBondAccountBoolValueChange}
              />
            </Box>
          </LoadingSection>
        )}

        <Flex alignItems="center" justifyContent="space-between">
          <h3>Address History</h3>
          {showAddLink && (
            <Link
              className="btn btn-round"
              to={
                !isBallotApplication
                  ? excludeRequestReasonForMoving
                    ? urlTo('addRentalHistoryRequestReasonForMoving')
                    : urlTo('addRentalHistory')
                  : urlTo('addRentalHistoryBallot')
              }
              title="Add address history"
            >
              <i className="icon-add" />
            </Link>
          )}
        </Flex>

        <Display.ProgressLine
          children={
            <div>
              {years} <sub>year</sub> {months}
              <sub> months</sub> {days} <sub>days</sub>
            </div>
          }
          totalPoints={totalTenure}
          recommendedPoints={recommendedTenure}
        />

        <Box mt={8}>
          <Checkbox
            label="No further address history"
            value={noFurtherAddressHistory}
            onChange={this.onAddressHistoryCheckboxChange}
          />
        </Box>

        {rentalHistory.map((history, index) => {
          let hasRatingCategory = false
          if (
            ratingCategories &&
            history &&
            history.ratings &&
            history.ratings[0] &&
            history.ratings[0].referenceSetVersion
          ) {
            hasRatingCategory = true
          }
          let referenceSetVersionForQuestions = ratingVersions.previousVersions
          let rentalReferenceQuestions = []
          if (hasRatingCategory) {
            rentalReferenceQuestions =
              ratingCategories[history.ratings[0].referenceSetVersion]
            referenceSetVersionForQuestions =
              history.ratings[0].referenceSetVersion
          }
          const lastRow = index === rentalHistory.length - 1
          return (
            <>
              <Box mt={6}>
                {this.renderHistoryGapAlert(
                  rentalHistory[index],
                  rentalHistory[index - 1],
                )}
              </Box>
              <HistoryBoxDetails
                key={index}
                history={history}
                ratingCategories={rentalReferenceQuestions}
                deleteRentalHistory={this.confirmDeleteRentalHistory}
                editRentalHistory={this.confirmEditRentalHistory}
                removeButtonClicked={removeButtonClicked}
                ratingCategoriesVersion={referenceSetVersionForQuestions}
              />
              {!lastRow && <HorizontalLine my={8} />}
            </>
          )
        })}

        <Display.BottomContentContainer>
          <Display.CenterContentContainer componentClass="width100">
            {/* TODO: Handle spinner position better than that: for now  BottomButton is forcing the children buttons, so spinner is outside*/}
            <StyledFlex justifyContent="flex-end" py={`${theme.space[6]}px`}>
              <BottomButton
                clickNextFunction={() => {
                  this.checkShowUpdateCurrentHistory()
                }}
                btnText={
                  isFromApplicationForm
                    ? 'Save & Continue'
                    : 'Next: Employment Details'
                }
                bottomButtonsClass="pt0 mt0 summary-bottom-button"
                nextBtnClass="summary-green-button-class"
                nextBtnDisabled={isLoadingTeamSettings}
                backButtonClass="summary-grey-button-class"
                nextButtonPositionClass="pr20 pl0"
                showArrow={false}
              />
              {reputationUpdateLoadingStates.state ===
                loadingStatesIds.LOADING && <Spinner />}
            </StyledFlex>
          </Display.CenterContentContainer>
        </Display.BottomContentContainer>

        <div className={apiError ? 'alert alert-danger' : 'hidden'}>
          {ErrorMessages[parseInt(apiError)]
            ? ErrorMessages[parseInt(apiError)]
            : `There is an error: ${apiError}`}
        </div>

        {addMoreRentalHistoriesModal}
        {showUpdateCurrentHistoryModal && (
          <UpdateCurrentModal
            modalType={UPDATE_MODAL_TYPE_ADDRESS}
            currentTextToAppend={
              currentAddress &&
              currentAddress.address &&
              currentAddress.address.friendlyName
            }
            timeFromNow={numMonthsMinusSpecifiedMonths}
            toggleModal={this.toggleUpdateCurrentModal}
            continueAction={this.continueActionInUpdateCurrentModal}
          />
        )}

        {(confirmDeleteModal || confirmEditModal) && (
          <Display.Modal
            toggleModal={() =>
              this.toggleModal(
                confirmDeleteModal ? 'confirmDeleteModal' : 'confirmEditModal',
              )
            }
            title={
              confirmDeleteModal
                ? 'Remove Address History'
                : 'Edit Address History'
            }
            hideButtonSection
            modalSubheadingClass="ml-auto mr-auto"
          >
            <div className="fs16">
              <Display.DefaultBodyText
                text={modalText}
                textClass="text-align-center"
              />
            </div>
            {confirmDeleteModal ? (
              <Display.ModalButtonGroup
                primaryLabel="Remove"
                secondaryLabel="Cancel"
                secondaryButtonType="tertiary"
                primaryAction={this.deleteRentalHistory}
                secondaryAction={() => this.toggleModal('confirmDeleteModal')}
              />
            ) : (
              <GenericButtonComponent
                onClick={() => this.toggleModal('confirmEditModal')}
                buttonType="tertiary"
                buttonStyleClass="height-48 desktop-std-btn mt0 mb0 ml-auto mr-auto display-table"
              >
                Close
              </GenericButtonComponent>
            )}
          </Display.Modal>
        )}
      </Display.CenterContentContainer>
    )
  }
}
export default RentalHistoryList
