import * as dateTimeHelpers from 'app/utils/datetime/helpers'

export const hashSensitiveDate: (date: string) => string = (date: string) => {
  const dateString = dateTimeHelpers.formatTime(
    date,
    dateTimeHelpers.STANDARD_DATE,
  )
  return `${dateString.substring(0, 2).replace(/./g, '#')}${dateString.substr(
    2,
  )}`
}

export const hashSensitiveNumber: (number: string) => string = (
  number: string,
) => {
  if (number.length === 0) {
    return ''
  }

  if (number.length <= 3) {
    return `${number.replace(/./g, '#')}`
  }

  return `${number
    .substring(0, number.length - 3)
    .replace(/./g, '#')}${number.substr(number.length - 3)}`
}
