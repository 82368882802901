import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

const CurrentLease = ({ currentLease, request, type }) => {
  let leaseHtml = null,
    id = '',
    requestID = ''

  if (request) {
    requestID = request.guidID
  }

  if (currentLease && currentLease.address) {
    let { guidID, rate, bond, startDate, endDate } = currentLease
    let { friendlyName, suburb, state, postcode } = currentLease.address
    id = guidID
    startDate = moment(startDate).format('Do MMM YYYY')
    leaseHtml = (
      <p>
        {friendlyName}, {suburb} {state} {postcode}
        <br />${rate} per week
        <br />${bond} bond
        <br />
        {startDate} -{' '}
        {endDate ? moment(endDate).format('Do MMM YYYY') : 'Rolling Lease'}
      </p>
    )
  }
  return (
    <div>
      {!request || (request && request.submissionStatus === 0) ? (
        //If not signed, link to item
        <Link
          className={
            leaseHtml ? 'bc-hub-item-link completed' : 'bc-hub-item-link'
          }
          to={`/bc/rental?requestid=${requestID}&id=${id}&type=${type}`}
        >
          <header>
            <i className="icon-couch" />
            <span>1. Rental Details</span>
          </header>
          <content>{leaseHtml}</content>
        </Link>
      ) : (
        //Signed, Remove the link
        <div className="bc-hub-item-link completed">
          <header>
            <i className="icon-couch" />
            <span>1. Rental Details</span>
          </header>
          <content>{leaseHtml}</content>
        </div>
      )}
    </div>
  )
}

export default CurrentLease
