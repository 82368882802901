import React from 'react'

import {
  Box,
  HorizontalLine,
} from 'app/components/design-system-components/index'
import { StyledCheckbox } from 'app/components/design-system-components/list-filters/StyledCheckbox'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const CheckboxesList = ({
  options,
  checkedOptions,
  onCheckBoxClicked,
  containerProps = {},
}) => {
  return options.map((option, index) => {
    const { optionMeta } = option
    const checked = !!checkedOptions[optionMeta.id]
    const isLast = index === options.length - 1
    const addSeparator = !isLast && !optionMeta.noSeparator
    return (
      <React.Fragment key={optionMeta.id}>
        <Box m={`0 ${theme.space[4]}px`} {...containerProps}>
          <StyledCheckbox
            id={optionMeta.id}
            label={optionMeta.displayText}
            onChange={(event) => {
              const {
                target: { checked },
              } = event
              onCheckBoxClicked(checked, option)
              event.stopPropagation()
            }}
            checked={checked}
          />
        </Box>
        {addSeparator && <HorizontalLine mb="10px" />}
      </React.Fragment>
    )
  })
}
