import styled from 'styled-components'

export const WidthFlex = styled.div`
  display: flex;
  width: 100%;
  flex: ${(props) => props.flexGrow} 1;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'inherit')};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'row'};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'left'};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : 'inherit')};
  position: ${(props) => (props.positionType ? props.positionType : 'inherit')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'inherit')};
  ${(props) =>
    props.nextLine &&
    `
    @media only screen and (max-width: 768px) {
      justify-content: center;
    }
  `}
  span,
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media print {
    flex: ${(props) =>
        props.flexGrowPrint ? props.flexGrowPrint : props.flexGrow}
      1;
    max-width: ${(props) =>
      props.maxWidthPrint
        ? props.maxWidthPrint
        : props.maxWidth
        ? props.maxWidth
        : 'inherit'};
    align-self: ${(props) =>
      props.alignSelfPrint
        ? props.alignSelfPrint
        : props.alignSelf
        ? props.alignSelf
        : 'inherit'};
    overflow: ${(props) =>
      props.overflowPrint ? `${props.overflowPrint} !important` : 'hidden'};
  }
`

export const WidthPercentage = styled.div`
  width: ${(props) => (props.amount ? props.amount : '100%')};
  display: ${(props) => (props.displayType ? props.displayType : 'block')};
  @media only screen and (max-width: 768px) {
    width: ${(props) => (props.mobileWidth ? props.mobileWidth : '100%')};
    display: ${(props) =>
      props.mobileDisplayType ? props.mobileDisplayType : 'block'};
  }
  @media print {
    width: ${(props) =>
      props.amountPrint
        ? props.amountPrint
        : props.amount
        ? props.amount
        : '100%'};
  }
`

export const RenterRows = styled.div`
  li {
    list-style-type: none;
    position: relative;
    padding: 20px 0;
    border-bottom: 1px solid #e8e8e9;
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
    .content-wrapper {
      display: flex;
      align-items: center;
    }
    .avatar {
      width: 64px;
      height: 64px;
    }
    .text {
      padding-left: 16px;
      min-width: 190px;
      vertical-align: top;
    }
    .info {
      display: flex;
      justify-content: space-around;
      padding-left: 12px;
    }
    .info > div:first-child {
      width: 64px;
    }
    .comment-wrap {
      white-space: normal !important;
      word-break: break-word !important;
    }
    .controls {
      position: relative;
      white-space: nowrap;
      display: flex;
    }
    @media (max-width: 1200px) {
      .content-wrapper {
        flex-direction: column;
        align-items: flex-start;
      }
      .meta-wrapper {
        flex-direction: column;
        align-items: flex-start;
      }
      .info {
        padding-left: 0;
      }
      .avatar {
        width: 50px;
        height: 50px;
      }
      .text {
        min-width: 150px;
      }
    }
  }
`

export const Row = styled.div`
  width: 100%;
  padding: 8px 16px;
  box-shadow: 0px 3px 5px rgba(34, 33, 42, 0.08);
  margin: 8px 8px;
  font-size: 14px;
  border-radius: 2px;
  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media print {
    padding: 8px 0px !important;
    margin: 8px 0px 8px 15px !important;
  }
`

export const BoldText = styled.span`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
  margin-right: 4px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '18')}px;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24')}px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'inherit')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ViewingsButtonsContainer = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    width: 15%;
    margin: 0 8px;
  }
  @media (max-width: 768px) {
    button {
      width: 30%;
      padding: 0 15px;
    }
  }
`

export const MobileTabletOnly = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: ${(props) => (props.displayType ? props.displayType : 'block')};
  }
`

export const DesktopOnly = styled.div`
  display: ${(props) => (props.displayType ? props.displayType : 'block')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'inherit')};
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const DateTimeInRow = styled.div`
  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    div {
      margin-right: 8px;
    }
  }
`

export const OpenCloseIconViewingRow = styled.div`
  @media only screen and (max-width: 768px) {
    img {
      position: absolute;
      top: 35%;
      right: 5%;
    }
  }
`

export const RegisteredAndAttendeesContainer = styled.div`
  display: flex;
  color: #686d71;
  margin-bottom: 3px;
  div {
    display: flex;
    align-items: center;
    width: max-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media only screen and (max-width: 991px) {
    div {
      margin-right: 0;
    }
  }
  @media only screen and (min-width: 1350px) {
    div {
      margin-right: 2px;
    }
  }
  @media only screen and (max-width: 768px) {
    div {
      margin-right: 8px;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 1350px) {
    display: block;
  }
  @media print {
    div {
      margin-right: 2px;
    }
  }
`

export const ViewingFurtherActionsContainer = styled.div`
  vertical-align: middle;
  span {
    margin-right: 8px;
  }
  a span {
    vertical-align: unset;
  }
  @media only screen and (max-width: 768px) {
    span {
      display: inline-block;
      margin-right: 8px;
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 1300px) {
    span {
      display: block;
      margin-bottom: 5px;
    }
  }
`

export const ViewingsContainer = styled.div`
  display: flex;
  div {
    margin-top: 3px;
  }
`
export const AlertIcon = styled.div`
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px;
  overflow: unset;
`
