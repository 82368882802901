import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'

export const formFields = {
  subject: 'subject',
  message: 'message',
  template: 'template',
  email: 'email',
  sms: 'sms',
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.message: {
      return {
        required: !val && 'message is required',
      }
    }
    case formFields.subject: {
      return {
        required: !val && 'subject is required',
      }
    }
    default:
      return {}
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
