import React from 'react'

import $ from 'jquery'
import moment from 'moment'
import qs from 'qs'
import styled from 'styled-components'

import Button from 'app/components/buttons/default/component'
import {
  Alert,
  Box,
  Flex,
  Modal,
} from 'app/components/design-system-components/index'
import { topNavHeightInPx } from 'app/components/design-system-components/styles/variables'
import { VIEWING_FORM_TYPE } from 'app/components/ViewingRegisterRequestFormModal/form-utils'
import NewRegisterRequestViewingModal from 'app/components/ViewingRegisterRequestFormModal/new-request-register-viewing-modal'
import { RequestedPropertyNotExist } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  getReferrer,
  isReferrerDomainComAU,
} from 'app/constants/referrer.constants'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import DisclosureQuestionsDisplay from 'app/shared_components/DisclosureQuestionsDisplay'
import KeepSafeOnline from 'app/shared_components/keepSafeOnline'
import { history } from 'app/shared_components/router'
import * as helpers from 'app/sm/helpers'
import { db, dbKeys, filterViewings, urlTo } from 'app/sm/helpers'
import AuthenticationModal from 'app/sm/property_details/authentication_modal'
import PotentialViewings from 'app/sm/property_details/potentialViewings'
import PropertyBallots from 'app/sm/property_details/property_ballots'
import PropertyDescription from 'app/sm/property_details/property_description'
import PropertyHeader from 'app/sm/property_details/property_header'
import PropertyInfo from 'app/sm/property_details/property_info'
import Viewings from 'app/sm/property_details/viewings'
import { SessionStorageUtils } from 'app/storage_utils'
import { checkIfBGCheckNotSupportedInState } from 'app/utils/address/helpers'
import { getBGCheckPromoURL } from 'app/utils/backgroundcheck'
import {
  appPageScrollContainerSelectors,
  scrollToElement,
} from 'app/utils/scroll'
import { isMobile } from 'app/utils/system/helpers'

function isLoggedIn(currentUser) {
  return currentUser && !currentUser.isUserFetching && currentUser.email
}

const scrollToViewingsElement = (container, viewingsElem) => {
  scrollToElement(container, viewingsElem, {
    topMarginInPx: topNavHeightInPx,
  })
}

const StyledBCPromoImg = styled.img`
  max-width: 100%;
  cursor: pointer;
`

const BC_PROMO_URL = getBGCheckPromoURL()

export const PreQualifyInfoContainer = styled.div`
  box-shadow: 0 3px 5px rgba(34, 33, 42, 0.08);
  width: 100%;
  padding: 15px;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
  }

  img {
    float: right;
  }
`

export const IS_FROM_SCHEDULE = true
export const SKIP_DOUBLE_BOOKING_CHECK = false
const VIEWING_NA = 'NA'

const VIEWING_NOT_AVAILABLE =
  'That viewing time is no longer available, select another time or request a viewing'
const VIEWING_NOT_FOUND_MODAL_TEXT =
  'This viewing does not exist. Please request a viewing'
const VIEWING_PAST_MODAL_TEXT =
  'This viewing has passed. Please check for new viewings'
const VIEWING_FULLY_BOOKED_MODAL_TEXT = 'This viewing is fully booked'

const VIEWING_ACTION_REGISTER = 'register'
const VIEWING_ACTION_REQUEST = 'request'

// const nameValidatorFactory = (fieldName) => {
//   return (value) => {
//     const basicError = validateNameFlattened(value)
//     if (!!basicError) return basicError

//     if (!value) return `${fieldName} is required`

//     if (value.length < 2) return `please enter a proper ${fieldName} value`

//     return ''
//   }
// }

// const firstNameValidator = nameValidatorFactory('first name')
// const lastNameValidator = nameValidatorFactory('last name')
// const mobileValidator = (value) => (!value ? 'Mobile number is required' : '')

const viewingsTabId = 'Viewings'
const listingTabId = 'Description'
const ballotsTabId = 'Ballots'

class PropertyDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: viewingsTabId,
      spinnerTarget: '',
      activeComponents: {
        modal: false,
      },
      openDisclosuresInModal: false,
      vaccinationQuestionsContainerOpened: false,
      disabledButtons: {
        modalSend: false,
      },
      error: '',
      unsavedChanges: false,
      registeringViewingId: '',
      isUserLoggedIn: false,
      nonLoggedInRegisteredViewings: [],
      preQualifyInfoContainerOpened: false,
      startVirtualTour: false,
      isViewingRequestDisabled: false,
      isPreApplyEnabled: false,
      isEasyBondPayEnabled: false,
      viewingNotFoundModalOpen: false,
      viewingNotFoundModalText: VIEWING_NOT_FOUND_MODAL_TEXT,
      isViewingNAModal: true,
      scrolledToViewing: false,
      showNewViewingRegisterRequestModalConfig: {},
      isDomainReferrer: false,
      selectedViewing: {},
    }
  }

  componentDidMount() {
    const curQueries = helpers.parseURLQuery()
    const { propertyId } = this.props.match.params
    const {
      viewingGUID = '',
      viewingAction = '',
      referrer_group,
      referrer_id,
      referrer_url,
    } = curQueries

    const referrer = getReferrer(referrer_group, referrer_id)
    const isDomainReferrer = isReferrerDomainComAU({
      referrer_group,
      referrer_id,
    })
    this.setState({ referrer, referrerBackUrl: referrer_url, isDomainReferrer })
    this.props
      .fetchProperty(propertyId, { referrer_group, referrer_id, referrer_url })
      .catch((error) => {
        parseInt(error) === RequestedPropertyNotExist &&
          history.push(urlTo('propertyNotAvailable'))
      })
    $('#index').addClass('no-navbar-menu')

    this.setState({
      openDisclosuresInModal: curQueries.showDisclosures === 'true',
    })

    if (db.has(dbKeys.property_details)) {
      SessionStorageUtils.removeItem(dbKeys.property_details)
    }

    if (viewingAction === VIEWING_ACTION_REGISTER && viewingGUID) {
      this.registerForViewing(viewingGUID)
    } else if (viewingAction === VIEWING_ACTION_REQUEST) {
      this.toggleNewViewingRegisterRequestModal(VIEWING_FORM_TYPE.request)
    }
    this.chooseActiveTabOnLoad()

    SessionStorageUtils.setItem('applicationBackUrl', window.location.pathname)
  }

  UNSAFE_componentWillReceiveProps({ currentUser, property, summary }) {
    const { propertyId } = this.props.match.params
    const isUserLoggedIn =
      currentUser &&
      !(currentUser.isUserFetching || currentUser.isUserProfileFetching)
    if (!this.oneMoreFetch && propertyId) {
      this.oneMoreFetch = true
      this.props.fetchProperty(propertyId).catch((error) => {
        snugNotifier.error(translateErrorCodeToMessage(error))
      })
    }

    if (isUserLoggedIn && !currentUser.email) {
      this.saveApplyRedirectIfNotLoggedIn()
    }

    // WHEN USER LOGGED IN
    if (isUserLoggedIn && currentUser.email) {
      this.setState({ isUserLoggedIn })
      this.deleteApplyRedirectIfLoggedIn()
    }
  }

  componentDidUpdate(prevProps) {
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { viewingGUID = '', viewingAction = '' } = curQueries
    if (
      this.props.viewings !== prevProps.viewings &&
      viewingGUID &&
      this.props.viewings.length > 0 &&
      viewingAction === VIEWING_ACTION_REGISTER
    ) {
      this.registerForViewing(viewingGUID)
    }

    const { scrolledToViewing: scrolled } = this.state
    if (!scrolled) {
      this.scrollToViewings()
    }
    if (prevProps.summary !== this.props.summary) {
      this.chooseActiveTabOnLoad()
    }
  }

  componentWillUnmount() {
    $('#index').removeClass('no-navbar-menu')
  }

  chooseActiveTabOnLoad = () => {
    const {
      summary: {
        property: { offers = [] },
      },
    } = this.props
    const activeOffer =
      offers && offers.find((offer) => offer.isActive === true)
    if (activeOffer && activeOffer?.ballotsEnabled) {
      this.setState({
        selectedTab: ballotsTabId,
      })
    } else {
      this.setState({
        selectedTab: viewingsTabId,
      })
    }
  }

  scrollToViewings = () => {
    const scroll = (el) => {
      if (el) {
        if (!isMobile()) {
          const container = document.querySelector(
            appPageScrollContainerSelectors.desktop,
          )
          if (!container || !el) return
          scrollToViewingsElement(container, el)
        } else {
          const container = document.querySelector(
            appPageScrollContainerSelectors.mobile,
          )
          if (!container || !el) return
          scrollToViewingsElement(container, el)
        }

        this.setState({
          scrolledToViewing: true,
        })
      }
    }

    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { viewingGUID, referrer_group } = curQueries || {}

    if (viewingGUID && viewingGUID !== 'NA') {
      const viewingElement = document.querySelector(`#viewing-${viewingGUID}`)
      scroll(viewingElement)
    } else if (referrer_group) {
      const viewingsTab = document.getElementById(
        'sm-property-details-Viewings',
      )
      scroll(viewingsTab)
    }
  }

  onPotentialViewingRegisterClicked = (viewing) => {
    this.toggleNewViewingRegisterRequestModal(
      VIEWING_FORM_TYPE.potential_register,
    )
    this.setState({
      selectedViewing: {
        viewingID: viewing.guidID,
        preferredDate: viewing.startDate,
        duration: viewing.duration,
      },
    })
  }

  onTabClicked(tab) {
    this.setState({
      selectedTab: tab,
    })
  }

  //TODO: keeping this old validation logic and check this validation match new modal

  // checkForErrorsInForm = () => {
  //   const { modalFormValue } = this.state
  //   let errors = {}
  //   Object.keys(modalFormValue)
  //     .filter((value) => value !== 'viewing')
  //     .filter((value) => value !== 'weeklyIncome')
  //     .filter((value) => value !== 'currentResidenceStartMonth')
  //     .filter((value) => value !== 'currentResidenceStartYear')
  //     .filter((value) => value !== 'weeklyHousingPayment')
  //     .filter((value) => value !== 'preferredDate')
  //     .filter((value) => value !== 'preferredTime')
  //     .filter((value) => value !== 'covid19VaccinationStatus')
  //     .filter((value) => value !== 'hasColdSymptoms')
  //     .filter((value) => value !== 'recentOverseasVisit')
  //     .forEach((value) => {
  //       if (this.state.modalFormValue[value] === '') {
  //         errors = Object.assign(errors, {
  //           [value]: 'This field cannot be empty',
  //         })
  //       }
  //     })
  //   if (
  //     this.state.errors.preferredDate ===
  //     viewingsHelpers.oneDayLaterErrorMessage
  //   ) {
  //     return errors
  //   } else if (
  //     (this.state.modalFormValue['preferredTime'] === '' &&
  //       this.state.modalFormValue['preferredDate'] !== '' &&
  //       this.state.errors.preferredDate !==
  //         viewingsHelpers.oneDayLaterErrorMessage) ||
  //     (this.state.modalFormValue['preferredDate'] === '' &&
  //       this.state.modalFormValue['preferredTime'] !== '')
  //   ) {
  //     errors = Object.assign(errors, {
  //       preferredTime: 'Both time and date required',
  //       preferredDate: 'Both time and date required',
  //     })
  //   } else {
  //     errors = Object.assign(errors, {
  //       preferredDate: '',
  //       preferredTime: '',
  //     })
  //   }
  //   const { firstName, lastName, contactNumber } = this.state.modalFormValue
  //   return {
  //     ...errors,
  //     firstName: errors.firstName || firstNameValidator(firstName),
  //     lastName: errors.lastName || lastNameValidator(lastName),
  //     contactNumber: errors.contactNumber || mobileValidator(contactNumber),
  //   }
  // }

  deleteApplyRedirectIfLoggedIn() {
    if (db.has(dbKeys.applyLinkUnauthenticated)) {
      SessionStorageUtils.removeItem(dbKeys.applyLinkUnauthenticated)
    }
  }

  finalizeRequestViewing = () => {
    this.setState({
      activeComponents: {
        ...this.state.activeComponents,
        modal: false,
      },
    })
    this.setState({
      disabledButtons: {
        ...this.state.disabledButtons,
        modalSend: false,
      },
    })
  }

  registerForViewing = (viewingID) => {
    if (viewingID === VIEWING_NA) {
      this.setState({
        viewingNotFoundModalText: VIEWING_NOT_AVAILABLE,
        viewingNotFoundModalOpen: true,
        isViewingNAModal: false,
      })
      return
    }
    let selectedViewing = {}
    const { viewings } = this.props
    if (viewings.length > 0) {
      selectedViewing = viewings.find((viewing) => viewing.guidID === viewingID)
      if (selectedViewing) {
        if (
          selectedViewing &&
          moment(moment.now()).isAfter(selectedViewing.startDate)
        ) {
          this.setState({
            viewingNotFoundModalText: VIEWING_PAST_MODAL_TEXT,
            viewingNotFoundModalOpen: true,
          })
          return
        }
        if (selectedViewing && selectedViewing.isFullyBooked) {
          this.setState({
            viewingNotFoundModalText: VIEWING_FULLY_BOOKED_MODAL_TEXT,
            viewingNotFoundModalOpen: true,
          })
          return
        }

        this.toggleNewViewingRegisterRequestModal(VIEWING_FORM_TYPE.register)
        this.setState({
          selectedViewing: {
            viewingID: selectedViewing.guidID,
            preferredDate: selectedViewing.startDate,
            duration: selectedViewing.duration,
          },
        })
      } else {
        this.setState({
          viewingNotFoundModalOpen: true,
        })
      }
    }
  }

  registerModalSuccessCb = (data) => {
    const { nonLoggedInRegisteredViewings = [] } = this.state
    const { propertyId } = this.props.match.params
    const { fetchProperty } = this.props
    fetchProperty(propertyId)
      .then(() => {
        this.setState({ spinnerTarget: '' })
      })
      .catch((error) => {
        snugNotifier.error(translateErrorCodeToMessage(error))
      })
    const updatedViewings = nonLoggedInRegisteredViewings.concat(data.viewingID)
    this.setState({
      nonLoggedInRegisteredViewings: updatedViewings,
    })
  }

  saveApplyRedirectIfNotLoggedIn() {
    db.save(dbKeys.applyLinkUnauthenticated, window.location.pathname)
  }

  unregisterViewing = (id) => {
    const { unregisterForEnquiryViewing } = this.props
    return unregisterForEnquiryViewing(id)
  }

  toggleViewingNotFoundModal = () => {
    const { viewingNotFoundModalOpen } = this.state
    this.setState({
      viewingNotFoundModalOpen: !viewingNotFoundModalOpen,
    })
  }

  toggleNewViewingRegisterRequestModal = (type) => {
    this.setState({
      showNewViewingRegisterRequestModalConfig: type
        ? {
            isOpened: true,
            type,
          }
        : {},
    })
  }

  renderPausedViewingsMessage = () => {
    return (
      <Alert variant="blueWithBg" mb={theme.space[4] + 'px'}>
        An application has been approved, no viewings are available
      </Alert>
    )
  }

  renderNewViewingRequestModal() {
    const {
      property = {},
      currentUser,
      requestForViewing,
      summary,
      registerForEmailViewing,
      addViewing,
    } = this.props

    const {
      showNewViewingRegisterRequestModalConfig,
      selectedViewing,
      disabledButtons: { modalSend },
    } = this.state
    const {
      address = {},
      teamName,
      agencyID = '',
      managingAgentId = '',
      guidID: propertyId,
    } = property
    const {
      manager: { email: managerEmail },
      property: { offers = [] },
    } = summary
    const activeOffer = offers.find((offer) => offer.isActive === true) || {}
    const { guidID: offerId } = activeOffer

    if (!Boolean(showNewViewingRegisterRequestModalConfig?.isOpened))
      return null

    return (
      <div key="newViewingRequestModal">
        <NewRegisterRequestViewingModal
          toggleModal={this.toggleNewViewingRegisterRequestModal}
          agencyName={teamName}
          addressFriendlyName={address.friendlyName}
          currentUser={currentUser}
          requestForViewing={requestForViewing}
          agencyID={agencyID}
          managingAgentId={managingAgentId}
          propertyId={propertyId}
          managerEmail={managerEmail}
          type={showNewViewingRegisterRequestModalConfig.type}
          selectedViewing={selectedViewing}
          registerForEmailViewing={registerForEmailViewing}
          addViewing={addViewing}
          offerId={offerId}
          registerModalSuccessCb={this.registerModalSuccessCb}
          finalizeRequestViewing={this.finalizeRequestViewing}
          modalSend={modalSend}
        />
      </div>
    )
  }

  renderButtonsSection = () => {
    const { summary } = this.props
    const { isViewingsPausedForApprovedApplications: isViewingsPaused } =
      summary.ViewingsOptions || {}
    const { isViewingRequestDisabled, propertyVideos } = summary
    return (
      <>
        {!isViewingsPaused && (
          <div className="mb20 col-sm-12 mobile-request-viewing">
            {!isViewingRequestDisabled && (
              <Button
                size="large"
                onClick={() =>
                  this.toggleNewViewingRegisterRequestModal(
                    VIEWING_FORM_TYPE.request,
                  )
                }
                text="Request a viewing"
                buttonType="secondary"
                buttonStyleClass="col-sm-4 pl0 pl10-mobile ml15"
              />
            )}
            {propertyVideos && propertyVideos.length > 0 && (
              <Button
                size="large"
                onClick={() => {
                  this.setState({ startVirtualTour: true })
                }}
                text="Virtual Viewing"
                buttonType="secondary"
                buttonStyleClass="col-sm-4 ml15"
              />
            )}
          </div>
        )}
      </>
    )
  }

  renderKeepSafeAndBGCheck = () => {
    const { summary, property } = this.props
    const { propertyDisclosure = {} } = summary
    const {
      hasPropertyDisclosure = false,
      propertyDisclosure: disclosures = {},
    } = propertyDisclosure
    const { openDisclosuresInModal } = this.state

    const { offers = [], address = {} } = property
    const { state = '' } = address || {}
    const activeOffer = offers.find((offer) => offer.isActive === true)

    return (
      <>
        <div className="mt20 bts pt20">
          <DisclosureQuestionsDisplay
            propertyDisclosure={disclosures}
            hasPropertyDisclosure={hasPropertyDisclosure}
            openDisclosuresModal={openDisclosuresInModal}
          />
        </div>
        <Box mt={5}>
          <KeepSafeOnline />
        </Box>
        {!activeOffer?.ballotsEnabled &&
          !checkIfBGCheckNotSupportedInState(state) && (
            <Flex justifyContent="center" width="100%" mt={6}>
              <StyledBCPromoImg
                onClick={() => history.push(urlTo('BackgroundCheck'))}
                src={BC_PROMO_URL}
                alt="bc_promo"
              />
            </Flex>
          )}
      </>
    )
  }

  render() {
    const {
      currentUser,
      authenticationModal,
      property,
      viewings = [],
      summary,
      error,
      shouldRender,
      // unregisterViewing,
      backUrl,
      agency = {},
      rsvpSpinnerGuidArray,
      potentialViewings,
      logout,
      checkForAgencyRules,
    } = this.props
    const {
      selectedTab,
      spinnerTarget,
      openDisclosuresInModal,
      isUserLoggedIn,
      nonLoggedInRegisteredViewings,
      startVirtualTour = false,
      viewingNotFoundModalOpen,
      viewingNotFoundModalText,
      isViewingNAModal,
      referrer,
      referrerBackUrl,
    } = this.state

    let tabs = [viewingsTabId, listingTabId]

    const isViewingTab = selectedTab === viewingsTabId
    const isListingTab = selectedTab === listingTabId
    const isBallotsTab = selectedTab === ballotsTabId

    const hasViewings =
      isViewingTab && filterViewings(viewings, false).length > 0

    const { offers = [] } = property
    const activeOffer = offers.find((offer) => offer.isActive === true)

    if (activeOffer?.ballotsEnabled) {
      tabs = [...tabs, ballotsTabId]
    }
    const agencyName = agency.name
    const hasPotentialViewings =
      potentialViewings && potentialViewings.length > 0

    if (authenticationModal) {
      return (
        <AuthenticationModal
          ok={() => {
            this.props.cancelModal()
            history.push(urlTo('register'))
          }}
          cancelModal={() => {
            this.props.cancelModal()
          }}
        />
      )
    }

    if (!shouldRender) {
      return null
    }

    const { agencyID = '' } = property

    const {
      propertyVideos,
      isViewingRequestDisabled,
      isPreApplyEnabled = false,
      isEasyBondPayEnabled = false,
      propertyDisclosure = {},
    } = summary

    const { isViewingsPausedForApprovedApplications: isViewingsPaused } =
      summary.ViewingsOptions || {}

    const showPotentialViewings =
      hasPotentialViewings && !isPreApplyEnabled && !isViewingsPaused

    const preApplyText = () => {
      return `
        ${(() => {
          if ((hasViewings || hasPotentialViewings) && isViewingRequestDisabled)
            return 'Choose your preferred open home inspection below. '

          if (
            (hasViewings || hasPotentialViewings) &&
            !isViewingRequestDisabled
          )
            return 'Choose your preferred open home inspection below or request a time. '

          return (
            'Sorry, there are no open home times advertised. ' +
            (!isViewingRequestDisabled
              ? 'Please request a viewing to be emailed when times become available.'
              : 'Please enquire through a property portal to be emailed when times become available.')
          )
        })()}
      `
    }

    return (
      <div className="sm-property-details">
        <PropertyHeader
          backUrl={backUrl}
          referrer={referrer}
          referrerBackUrl={referrerBackUrl}
          remindText={agencyName}
          property={property}
          addToSavedProperty={
            isLoggedIn(currentUser)
              ? () => this.props.addToSavedProperty(property.guidID)
              : () => this.props.triggerModal()
          }
          propertyVideos={propertyVideos}
          startVirtualTour={startVirtualTour}
        />
        <PropertyInfo
          property={property}
          summary={summary}
          currentUser={currentUser}
          logout={logout}
          checkForAgencyRules={checkForAgencyRules}
          agencyID={agencyID}
          isEasyBondPayEnabled={isEasyBondPayEnabled}
          propertyDisclosure={propertyDisclosure}
          openDisclosuresInModal={openDisclosuresInModal}
          renderKeepSafeAndBGCheck={this.renderKeepSafeAndBGCheck}
        />
        <div className="sm-property-details__tabs mb10">
          {tabs.map((tab) => (
            <a
              id={`sm-property-details-${tab}`}
              onClick={() => this.onTabClicked(tab)}
              key={tab}
              href={`#${tab.toLowerCase()}`}
              className={selectedTab === tab ? 'active' : ''}
            >
              {tab}
            </a>
          ))}
        </div>
        {isViewingTab && (
          <div className="two-col-box sm55 sm-property-details__sections">
            <div className="col-first">
              {!isPreApplyEnabled && (
                <div className="mb20">
                  <p>{preApplyText()}</p>
                </div>
              )}
              {isPreApplyEnabled && (
                <div className="mb20">
                  <p>
                    <b>Pre-Apply then view the property</b>
                  </p>
                  <p>
                    Shortlisted applicants will be contacted to view the
                    property.
                  </p>
                </div>
              )}
              {hasViewings && !isPreApplyEnabled && !isViewingsPaused && (
                <div
                  className={`sm-property-details__section ${tabs[0].toLowerCase()}`}
                  id={tabs[0].toLowerCase()}
                >
                  <Viewings
                    viewings={viewings}
                    registerViewing={(id) => this.registerForViewing(id)}
                    // unregisterViewing={unregisterViewing}
                    rsvpSpinnerGuidArray={rsvpSpinnerGuidArray}
                    teamSlug={property.teamSlug}
                    isUserLoggedIn={isUserLoggedIn}
                    nonLoggedInRegisteredViewings={
                      nonLoggedInRegisteredViewings
                    }
                  />
                </div>
              )}
              {showPotentialViewings && (
                <div>
                  <PotentialViewings
                    title={
                      !hasViewings && hasPotentialViewings
                        ? 'Book a viewing'
                        : 'Book another viewing'
                    }
                    potentialViewings={potentialViewings}
                    teamSlug={property.teamSlug}
                    registerViewing={this.onPotentialViewingRegisterClicked}
                    spinnerTarget={spinnerTarget}
                    renderButtonsSection={this.renderButtonsSection}
                  />
                </div>
              )}
              {!showPotentialViewings && (
                <Box>{this.renderButtonsSection()}</Box>
              )}
              {isViewingsPaused && this.renderPausedViewingsMessage()}

              <div className="row">
                <div className="col-sm-12">
                  <div className={error ? 'alert alert-danger' : ''}>
                    <div>{error}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isMobile() && this.renderKeepSafeAndBGCheck()}

        {isListingTab && (
          <div
            className={`sm-property-details__section ${tabs[1].toLowerCase()}`}
            id={tabs[1].toLowerCase()}
          >
            <PropertyDescription property={property} width="50%" />
          </div>
        )}

        {isBallotsTab && <PropertyBallots />}

        {viewingNotFoundModalOpen && (
          <Modal
            modalBody={viewingNotFoundModalText}
            primaryLabel={!isViewingNAModal ? 'Close' : 'Request a Viewing'}
            primaryAction={() => {
              isViewingNAModal &&
                this.toggleNewViewingRegisterRequestModal(
                  VIEWING_FORM_TYPE.request,
                )
              this.toggleViewingNotFoundModal()
            }}
            secondaryLabel="Close"
            secondaryAction={() => this.toggleViewingNotFoundModal()}
            toggleModal={() => this.toggleViewingNotFoundModal()}
            showSecondaryButton={isViewingNAModal}
            modalHeading={!isViewingNAModal ? 'Viewing not available' : ''}
            standardWidth={true}
          />
        )}
        {this.renderNewViewingRequestModal()}
      </div>
    )
  }
}

export default PropertyDetails
