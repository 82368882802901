import { connect } from 'react-redux'

import { setBackUrl } from 'app/shared_components/actions'
import LandingContainer from 'app/sm/landing/landing_container'

const mapDispatchToProps = (dispatch) => ({
  setBackUrl: (url) => dispatch(setBackUrl(url)),
})

export default connect(null, mapDispatchToProps)(LandingContainer)
