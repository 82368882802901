import React from 'react'

import { Link } from 'react-router-dom'

import PropertySearchBox from 'app/bond_cover/agency/search_box'
import { Box, Flex } from 'app/components/design-system-components'
import BondCoverReferral from 'app/dashboard/components/bond_cover_referral_connection'
import DashboardModal from 'app/dashboard/components/dashboard_modal'
import InviteToApply from 'app/dashboard/components/invite_to_apply'
import { ResourcePanel } from 'app/dashboard/components/resource_panel'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { history } from 'app/shared_components/router'
import { ProfileCompletenessComponent } from 'app/sm/applications/components/progress_bar'
import { ApplicationCategory, urlTo } from 'app/sm/helpers'
import { FeatureFlag } from 'config/features'

const isApplicationInProgress = (application) => {
  const status = ApplicationCategory[application.status]
  return (
    application.expiredAt === null &&
    (status === 'Draft' || status === 'Applied' || status === 'Interested')
  )
}

const propertySearchResult = ({ result, i }) => {
  return (
    <div
      key={i}
      className="search-box-list-item mb5 ml5"
      onClick={() =>
        history.push(urlTo('applyRedirect', { slug: result.slug }))
      }
    >
      {`${result.friendlyName} ${result.suburb} ${result.state} ${result.postcode}`}
    </div>
  )
}

class RenterDashboardContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inviteModalOpen: false,
      modalOpen: false,
      propertyName: '',
      errors: {},
    }
  }

  UNSAFE_componentWillMount() {
    const { fetchRenterDashboard, fetchCompleteness, fetchApplications } =
      this.props
    fetchRenterDashboard()
    fetchCompleteness()
    fetchApplications()
  }

  componentDidMount() {
    this.props.setBackUrl(this.props.match.path)
  }

  closeInviteModal = () => {
    this.setState({ inviteModalOpen: false })
  }

  openInviteModal = () => {
    this.setState({ inviteModalOpen: true })
  }

  toggleModal() {
    return () => {
      const { modalOpen } = this.state
      this.setState({ modalOpen: !modalOpen })
    }
  }

  trimField = (field) => {
    let value = ''
    return (event) => {
      if (!!event.target.value) {
        value = this.state[field].trim()
      }
      this.setState({ [field]: value })
    }
  }

  updateProperty = (propertyName) => {
    let { value } = propertyName
    this.setState({ propertyName: value })
  }

  render() {
    const { modalOpen, inviteModalOpen, propertyName } = this.state
    const {
      currentUser,
      bondCovers = [],
      invitesToApply = [],
      completeness,
      applications = [],
      searchProperties,
      properties = [],
    } = this.props

    const inProgressApplicationCount = applications.filter(
      isApplicationInProgress,
    ).length

    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          {/* Welcome message */}
          <div className="renter-welcome">
            <h3 className="pb10">Welcome {currentUser.firstName}.</h3>
            <p>
              We're making renting easier. Build your Snug profile and use it to
              apply anywhere.
            </p>
          </div>

          {/* Invite */}
          {inviteModalOpen && (
            <div className="modal-fullscreen">
              <div className="modal-wrapper renter-dashboard-modal-wrapper pb30">
                <div className="modal-close">
                  <i
                    className="fa fa-times"
                    onClick={() => this.closeInviteModal()}
                  />
                </div>
                <div className="modal-header no-bottom-border">
                  <h4 className="mb15">Invite a friend</h4>
                  <p className="mb0">
                    We’ll send them a promotion code via email.
                  </p>
                </div>
                <BondCoverReferral
                  inviterName={`${currentUser.firstName} ${currentUser.lastName}`}
                  inviterEmail={currentUser.email}
                  role={'renter'}
                />
              </div>
            </div>
          )}

          {modalOpen && <DashboardModal toggleModal={this.toggleModal()} />}

          <div className="sm-property-dashboard">
            <div className="sm-property-dashboard-content">
              <ul>
                {invitesToApply.length > 0 && (
                  <InviteToApply invitesToApply={invitesToApply} />
                )}
                <li className="renter-dashboard-li">
                  <header>
                    <i className="icon-documents"></i>
                    <h4>Your Applications</h4>
                  </header>
                  <main>
                    <Flex flexDirection="column">
                      <p>
                        <>
                          You currently have{' '}
                          {inProgressApplicationCount > 0 ? (
                            <Link to={urlTo('applications')}>
                              {inProgressApplicationCount} applications in
                              progress
                            </Link>
                          ) : (
                            'no applications in progress'
                          )}
                        </>
                      </p>
                      <Box mt={`${theme.space[5]}px`}>
                        <p>
                          <>
                            Applying with others?{' '}
                            <Link to={urlTo('applications')}>
                              Add a Joint Applicant
                            </Link>
                          </>
                        </p>
                      </Box>
                    </Flex>
                  </main>
                  <footer className="dashboard-tile-footer-footer">
                    {inProgressApplicationCount > 0 && (
                      <button
                        className="btn"
                        onClick={() => history.push(urlTo('applications'))}
                      >
                        Continue application
                      </button>
                    )}
                    {inProgressApplicationCount === 0 && (
                      <p>
                        <Link to={urlTo('RentalReputation')}>
                          Build your profile
                        </Link>
                      </p>
                    )}
                  </footer>
                </li>
                <li className="renter-dashboard-li">
                  <header>
                    <i className="icon-person"></i>
                    <h4>Your Snug profile</h4>
                  </header>
                  <ProfileCompletenessComponent
                    value={completeness.percentage}
                  />
                  <footer className="dashboard-tile-footer">
                    <p>
                      <Link to={urlTo('RentalReputation')}>
                        View your profile
                      </Link>
                    </p>
                  </footer>
                </li>
                {/* May add it back in the future */}
                {/* {
                  bondCovers.length === 0 &&
                  <li className="renter-dashboard-li">
                    <header>
                      <i className="icon-bondcover"></i>
                      <h4>BondCover for Renters</h4>
                    </header>
                    <main>

                      <div className="renter-dashboard-video">
                        <iframe
                          src="https://www.youtube.com/embed/Brj3ySeY53c"
                          height="100%"
                          width="100%"
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </main>
                  </li>
                } */}
                <li className="renter-dashboard-li">
                  <header>
                    <h4>Apply Anywhere</h4>
                  </header>
                  <main>
                    <p>Re-use your Snug profile and apply for any property.</p>
                  </main>
                  <footer className="dashboard-tile-footer-footer">
                    <button
                      className="btn"
                      onClick={() => history.push(urlTo('applyAnywhere'))}
                    >
                      Apply anywhere &gt;
                    </button>
                  </footer>
                </li>
                <li className="renter-dashboard-li input-box">
                  <header className="mb50">
                    <i className="icon-search"></i>
                    <h4>Search for a property</h4>
                  </header>
                  <div>
                    <PropertySearchBox
                      searchFunction={searchProperties}
                      placeholder="Search Properties"
                      value={propertyName}
                      handleInputChange={this.updateProperty}
                      handleInputBlur={this.trimField('propertyName')}
                      className={''}
                      SearchResultItem={propertySearchResult}
                      extraClassName={'width-property-search'}
                    />
                  </div>
                </li>
                <li
                  className={
                    FeatureFlag.BondCover.isOn
                      ? 'renter-dashboard-li'
                      : 'renter-dashboard-li opacity-none'
                  }
                >
                  <div>
                    {FeatureFlag.BondCover.isOn && (
                      <div>
                        <header>
                          <i className="icon-bondcover"></i>
                          <h4>BondCover</h4>
                        </header>
                        <footer className="pt25 dashboard-tile-footer">
                          <p>
                            <a onClick={this.toggleModal()}>
                              Apply for BondCover
                            </a>
                          </p>
                          <p>
                            <a onClick={() => this.openInviteModal()}>
                              Send a $25 discount to a friend
                            </a>
                          </p>
                          <p>
                            <Link to={urlTo('dashboard')}>
                              View BondCover certificates
                            </Link>
                          </p>
                        </footer>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {FeatureFlag.BondCover.isOn && (
          //right panel
          <ResourcePanel bondCovers={bondCovers} role={'Renters'} />
        )}
      </div>
    )
  }
}

export default RenterDashboardContainer
