import React from 'react'

import { Link } from 'react-router-dom'

import { urlTo } from 'app/sm/helpers'

class AskQuestion extends React.Component {
  componentDidMount() {
    const { propertyId } = this.props.match.params
    this.props.setPropertyId(propertyId)
  }
  render() {
    const { propertyId } = this.props.match.params
    const { error } = this.props

    // if (error) {
    //     return (
    //         <div className='alert alert-danger disposable-alert'>
    //             <i onClick={() => this.props.ping()} className="fa fa-chevron-left mr15" aria-hidden="true" />{error}
    //         </div>
    //     )
    // }
    return (
      <div className="two-col-box sm64">
        <div className="col-first xs-second">
          <div className="ask-question">
            <div className="section-title">
              <div className="left column">
                <h3>Ask a Question</h3>
                <br />
                <p className="gray-color mt10 mb0">
                  Questions will be shared with other renters so everyone
                  benefits.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="input-box">
                  <textarea
                    onChange={(e) => this.props.textChanged(e.target.value)}
                    className="ask-question-text"
                    placeholder="Write something..."
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className={error ? 'alert alert-danger' : ''}>
                  <div>{error}</div>
                </div>
              </div>
            </div>
            <div className="row pt25">
              <div className="col-sm-5 pb5">
                <Link
                  to={urlTo('propertyDetails', { propertyId })}
                  className="btn btn-transparent btn-left xs-text-center wa"
                >
                  <i className="icon-arrow-left left"></i>
                  <span>Back</span>
                </Link>
              </div>
              <div className="col-sm-7 pb5">
                <button onClick={this.props.sendQuestion} className="btn">
                  Send Question
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AskQuestion
