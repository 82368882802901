export const VEHICLE_TYPE_UNSET = 0
export const VEHICLE_TYPE_CAR = 1
export const VEHICLE_TYPE_MOTORCYCLE = 2
export const VEHICLE_TYPE_BOAT = 3
export const VEHICLE_TYPE_OTHER = 4

export const vehicleOptions = [
  { guid: VEHICLE_TYPE_UNSET, name: 'Not Set' },
  { guid: VEHICLE_TYPE_CAR, name: 'Car' },
  { guid: VEHICLE_TYPE_MOTORCYCLE, name: 'Motorcycle' },
  { guid: VEHICLE_TYPE_BOAT, name: 'Boat' },
  { guid: VEHICLE_TYPE_OTHER, name: 'Other' },
]

export const mappedVehicleTypeWithName = {
  [VEHICLE_TYPE_UNSET]: 'Unset',
  [VEHICLE_TYPE_CAR]: 'Car',
  [VEHICLE_TYPE_MOTORCYCLE]: 'Motorcycle',
  [VEHICLE_TYPE_BOAT]: 'Boat',
  [VEHICLE_TYPE_OTHER]: 'Other',
}

export const VEHICLES_KEY_TYPE = 'vehicleType'
export const VEHICLES_KEY_REG_NUMBER = 'registrationNumber'

export const REG_NUM_NOT_PROVIDED_TEXT = 'Not Provided'

export const vehicleObject = {
  [VEHICLES_KEY_TYPE]: 0,
  [VEHICLES_KEY_REG_NUMBER]: '',
}

export const ERROR_TYPE_NOT_SELECTED =
  'Entered vehicle information is incorrect. Please select the type of vehicle.'

export const formTotalVehiclesText = (
  allVehicles,
  excludeRequestVehicleRegistration = false,
) => {
  let totalVehiclesText = ''
  if (allVehicles && allVehicles.length > 0) {
    let vehicleNameText = ''
    vehicleNameText = allVehicles
      .map((vehicle) => {
        return excludeRequestVehicleRegistration
          ? mappedVehicleTypeWithName[vehicle[VEHICLES_KEY_TYPE]]
          : `${mappedVehicleTypeWithName[vehicle[VEHICLES_KEY_TYPE]]} - ${
              vehicle[VEHICLES_KEY_REG_NUMBER]
            }`
      })
      .join(', ')
    totalVehiclesText += vehicleNameText
  }
  return totalVehiclesText
}

export const fillNotProvidedIfEmptyRegNum = (
  allVehicles,
  excludeRequestVehicleRegistration = false,
) => {
  let allVehiclesCopy = allVehicles.map((vehicle) => {
    return {
      ...vehicle,
      [VEHICLES_KEY_REG_NUMBER]: excludeRequestVehicleRegistration
        ? ''
        : vehicle[VEHICLES_KEY_REG_NUMBER] || REG_NUM_NOT_PROVIDED_TEXT,
    }
  })
  return allVehiclesCopy
}
