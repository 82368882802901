import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import {
  ButtonWithLoading,
  Flex,
  LegacyButton,
  LoadingSection,
} from 'app/components/design-system-components'
import ActivityLogsTable from 'app/components/design-system-components/ActivityLogsTable'
import { Text } from 'app/components/design-system-components/typography'
import { getFeedStatusActivities } from 'app/services/http/feed/activitylogs'
import {
  feedActivityStatuses,
  feedActivityTypes,
} from 'app/sm/team_viewing_preference/feed/feed.constants'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  loadingStatesIds,
} from 'app/utils/loading-states'

const FeedStatusActivities = ({ currentTeam }) => {
  const [activities, setActivities] = useState([])
  const [cursor, setCursor] = useState('')
  const [activitiesLoadingStates, setActivitiesLoadingStates] = useState(
    createInitialLoadingState(),
  )

  const activitiesLoadingStateUtils = createLoadingStateUtils(
    setActivitiesLoadingStates,
  )

  useEffect(() => {
    loadActivities()
  }, [])

  const loadActivities = () => {
    activitiesLoadingStateUtils.startLoading()
    getFeedStatusActivities(currentTeam.guid, cursor)
      .then(({ data, cursor: nextCursor }) => {
        activitiesLoadingStateUtils.markDoneSuccessfully()
        setActivities((prevState) => [...prevState, ...data])
        setCursor(nextCursor)
      })
      .catch((err) => activitiesLoadingStateUtils.setError(err))
  }

  return (
    <LoadingSection
      actionHandler={() => loadActivities()}
      loadingState={activitiesLoadingStates}
      loaderProps={{ fontSize: '20px' }}
      showChildrenWhileLoading={activities.length > 0}
    >
      {activities && activities.length ? (
        <ActivityLogsTable
          activityList={activities}
          activityTypes={feedActivityTypes}
          activityStatuses={feedActivityStatuses}
        />
      ) : (
        <Text>No activity</Text>
      )}
      {cursor && (
        <Flex mt="10px" justifyContent="center">
          <ButtonWithLoading
            buttonCmp={LegacyButton}
            width="150px"
            height="40px"
            variant="outlineSuccess"
            onClick={() => loadActivities()}
            loading={activitiesLoadingStates.state === loadingStatesIds.LOADING}
            disableWhenLoading
          >
            Load more
          </ButtonWithLoading>
        </Flex>
      )}
    </LoadingSection>
  )
}

const mapStateToProps = ({ session }) => ({ currentTeam: session.currentTeam })

export default connect(mapStateToProps)(FeedStatusActivities)
