// Rental Reference to Lite Flow

export const GTM_RR_LITE_REFRENCE_SUBMITTED_PRIMARY =
  'RR_LITE_JULY_2022_Ref_Submitted_Join_Team'
export const GTM_RR_LITE_REFRENCE_SUBMITTED_SECONDARY =
  'RR_LITE_JULY_2022_Ref_Submitted_Close'

export const GTM_RR_LITE_INVITE_REQUESTED_SECONDARY =
  'RR_LITE_JULY_2022_Invite_Requested_Close'

export const GTM_RR_LITE_VERIFY_DETAILS_PRIMARY =
  'RR_LITE_JULY_2022_Verify_Details_Verify'
export const GTM_RR_LITE_VERIFY_DETAILS_SECONDARY =
  'RR_LITE_JULY_2022_Verify_Details_Close'

export const GTM_RR_LITE_ARE_YOU_SURE_PRIMARY =
  'RR_LITE_JULY_2022_Are_You_Sure_Continue'
export const GTM_RR_LITE_ARE_YOU_SURE_SECONDARY =
  'RR_LITE_JULY_2022_Are_You_Sure_Close'

export const GTM_RR_LITE_ADD_TEAM_MEMBERS_PRIMARY =
  'RR_LITE_JULY_2022_Add_Team_Members_Complete'
export const GTM_RR_LITE_ADD_TEAM_MEMBERS_SECONDARY =
  'RR_LITE_JULY_2022_Add_Team_Members_Close'

export const GTM_RR_LITE_SUGGEST_TEAM_MEMBERS_PRIMARY =
  'RR_LITE_JULY_2022_Suggest_Team_Members_Complete'
export const GTM_RR_LITE_SUGGEST_TEAM_MEMBERS_SECONDARY =
  'RR_LITE_JULY_2022_Suggest_Team_Members_Close'

export const GTM_RR_LITE_CONFIRM_EXIT_TEAM_MEMBERS_PRIMARY =
  'RR_LITE_JULY_2022_Confirm_Exit_Team_Members_Continue'
export const GTM_RR_LITE_CONFIRM_EXIT_TEAM_MEMBERS_SECONDARY =
  'RR_LITE_JULY_2022_Confirm_Exit_Team_Members_Close'

export const GTM_RR_LITE_SUGGEST_TEAM_MEMBER =
  'RR_LITE_JULY_2022_Suggest_Team_Member_Button'

export const GTM_RR_LITE_CONNECT_TEAM_MEMBERS_INVITE =
  'RR_LITE_JULY_2022_Connect_Team_Members_Invite_Team_Member'

// Activity Button Navbar

export const GTM_ACTIVITY_BUTTON_NAVBAR = 'Activity_Button_Navbar'

// Background Check
export const GTM_SEE_EXAMPLE_REPORT_BUTTON_RENTER = 'See_Example_Report_Renter'

// GTM 2023 Flow
export const RR_LITE_JUNE_2023_GTM_CONTINUE_AS_GUEST =
  'RR_LITE_JUNE_2023_Continue_As_Guest_Button'
export const RR_LITE_JUNE_2023_GTM_VERIFY_BUTTON =
  'RR_LITE_JUNE_2023_Verify_Button'
export const RR_LITE_JUNE_2023_GTM_CTA_BUTTON =
  'RR_LITE_JUNE_2023_GTM_CTA_Button'
export const RR_LITE_JUNE_2023_GTM_INVITE_TEAM_MEMBER =
  'RR_LITE_JUNE_2023_GTM_Invite_Team_Member'

// Application Toolbar Button
export const NEW_APPLICATION_TOOLBAR_BUTTON = 'New_Application_Toolbar_Button'

// Autoresponder alert and modal on messages page
export const MESSAGES_AUTO_RESPONDER_MODAL_COMPLETE_BUTTON =
  'Messages_autoresponder_complete_button'

export const MESSAGES_AUTO_RESPONDER_TOGGLE_BUTTON =
  'Messages_autoresponder_toggle'
