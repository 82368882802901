import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import { LegacyButton } from 'app/components/design-system-components/Button'

const ELLIPSES = '...'

const PaginationContainer = styled(Box)`
  display: flex;
  margin-top: ${(props) => props.theme.space[8]}px;

  button {
    padding: ${(props) => props.theme.space[0]}px;
    width: 45px;
    height: 40px;
    border: ${(props) =>
      `${props.theme.borders[1]} ${props.theme.colors.grey200}`};
    border-radius: ${(props) => props.theme.radii[0]};
    font-weight: bold;

    &:disabled {
      background-color: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.grey200};
      border: ${(props) =>
        `${props.theme.borders[1]} ${props.theme.colors.grey200}`};
    }
  }

  button:first-child {
    border-radius: 4px 0 0 4px;
  }

  button:last-child {
    border-radius: 0 4px 4px 0;
  }
`

const Pagination = ({
  canNextPage,
  canPreviousPage,
  currentPageNum,
  onPaginationButtonClicked,
  maxPageNumbers,
}) => {
  useEffect(() => {
    setVisiblePages(getVisiblePages(currentPageNum, maxPageNumbers))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxPageNumbers, currentPageNum])

  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages)
  }

  const getVisiblePages = (page, total) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total)
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, ELLIPSES, page - 1, page, page + 1, ELLIPSES, total]
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, ELLIPSES, total - 3, total - 2, total - 1, total]
      } else {
        return [1, 2, 3, 4, ELLIPSES, total - 1, total]
      }
    }
  }
  const [visiblePages, setVisiblePages] = useState(
    getVisiblePages(null, maxPageNumbers),
  )

  return (
    <PaginationContainer>
      <LegacyButton
        variant={'pagination'}
        onClick={() =>
          onPaginationButtonClicked(currentPageNum - 2, currentPageNum - 1)
        }
        disabled={!canPreviousPage}
      >
        {'<'}
      </LegacyButton>
      {visiblePages.map((pageNumber) => {
        return (
          <LegacyButton
            variant={
              currentPageNum === pageNumber ? 'paginationActive' : 'pagination'
            }
            onClick={() =>
              currentPageNum === pageNumber || pageNumber === ELLIPSES
                ? null
                : onPaginationButtonClicked(pageNumber - 1, pageNumber)
            }
            key={pageNumber}
          >
            {pageNumber}
          </LegacyButton>
        )
      })}
      <LegacyButton
        variant={'pagination'}
        onClick={() =>
          onPaginationButtonClicked(currentPageNum, currentPageNum + 1)
        }
        disabled={!canNextPage}
      >
        {'>'}
      </LegacyButton>{' '}
      <LegacyButton
        variant={'pagination'}
        onClick={() =>
          onPaginationButtonClicked(maxPageNumbers - 1, maxPageNumbers)
        }
        disabled={!canNextPage}
      >
        {'>|'}
      </LegacyButton>{' '}
    </PaginationContainer>
  )
}

export default Pagination
