import React from 'react'

import bathIcon from 'app/assets/icons/summary_icons/bath.svg'
import bedIcon from 'app/assets/icons/summary_icons/bed.svg'
import carIcon from 'app/assets/icons/summary_icons/car.svg'
import { MainHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import PMPreferences from 'app/match/apply/apply_form/components/property_manager_preference/component'
import { CategoryTypes } from 'app/sm/helpers'
import 'app/match/apply/apply_form/components/property_info/style.scss'
import * as helpers from 'app/sm/helpers'

const PropertyInfoBox = ({
  address = '',
  suburb = '',
  property = {},
  acceptedLeaseLength = '',
  weeklyRent = '',
  isApplyAnywhere = false,
  isPetsAllowed = false,
  ballotsEnabled = false,
}) => {
  const {
    type = 0,
    bedrooms = 0,
    bathrooms = 0,
    garages = 0,
    parking = 0,
    carports = 0,
  } = property || {}
  const carCountSpaces = { garages, parking, carports }
  return (
    <div>
      <div className="property-info-upper-container">
        <div className="col-sm-6">
          <MainHeader text="Application" />
          <div className="address-body">
            {address}, {suburb}
          </div>
          {!isApplyAnywhere && (
            <div className="property-details-list">
              <ul>
                <li>
                  <span className="property-info-summary-number">
                    {CategoryTypes[type]}
                  </span>
                </li>
                <li>
                  <img
                    className="property-info-icon"
                    src={bedIcon}
                    alt="Bath Icon"
                  />
                  <span className="property-info-summary-number">
                    {bedrooms}
                  </span>
                </li>
                <li>
                  <img
                    className="property-info-icon"
                    src={bathIcon}
                    alt="Bath Icon"
                  />
                  <span className="property-info-summary-number">
                    {bathrooms}
                  </span>
                </li>
                <li>
                  <img
                    className="property-info-icon"
                    src={carIcon}
                    alt="Bath Icon"
                  />
                  <span className="property-info-summary-number">
                    {helpers.getCarSpaceCountForProperty(carCountSpaces)}
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>

        {!isApplyAnywhere && (
          <PMPreferences
            acceptedLeaseLength={acceptedLeaseLength}
            weeklyRent={weeklyRent}
            allowPets={isPetsAllowed}
            ballotsEnabled={ballotsEnabled}
          />
        )}
      </div>
      <div className="form-seperator" />
    </div>
  )
}

export default PropertyInfoBox
