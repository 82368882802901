import React from 'react'

import PropTypes from 'prop-types'

import { Box } from 'app/components/design-system-components'
import Card from 'app/match/applicationReportPDF/components/Card'
import InfoList from 'app/match/applicationReportPDF/components/InfoList'

const propTypes = { data: PropTypes.array }

const Pets = ({ data, note }) => {
  return (
    <div className="no-break-section">
      <Card
        type={'icon'}
        icon="icon-dog-leash"
        title="Pets"
        addClass="bottom-border"
      />
      {note && <Box mb="10px">{note}</Box>}
      {data &&
        data.map((pet, index) => {
          const { label, text, registration_number, references } = pet
          return (
            <div key={index} className="display-flex flex-direction-column">
              {' '}
              <strong className="list-title">{label}</strong>
              <span>
                {text}
                <br />
              </span>
              <InfoList data={{ registration_number, references }} />
            </div>
          )
        })}
    </div>
  )
}

Pets.propTypes = propTypes

export default Pets
