import { useEffect, useState } from 'react'

import styled from 'styled-components'

import {
  Box,
  Button,
  Flex,
  LoadingSection,
} from 'app/components/design-system-components'
import { Input } from 'app/components/design-system-components/inputs'
import { Select } from 'app/components/design-system-components/inputs/Select/Select'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  createAutoresponderAttachment,
  deleteAutoresponderAttachment,
  listAutoresponderAttachments,
  updateAutoresponderAttachment,
} from 'app/services/http/team-applications'
import * as snugNotifier from 'app/services/snugNotifier'
import DocumentList from 'app/shared_components/document_list/document_list'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'
import { attachmentCategoriesOptions } from 'app/sm/team_viewing_preference/applications-attributes.constants'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
} from 'app/utils/loading-states'

const attachmentsKey = 'autoresponder'

const StyledAutoResponderItem = styled(Flex)`
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const StyledAutoResponderItemInternals = styled(Flex)`
  margin-left: ${theme.space[4]}px;
  select {
    border: 1px solid ${theme.colors.grey200} !important;
  }
  button {
    height: 48px !important;
  }
  label {
    border: 1px solid #cccccc !important;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: ${theme.space[3]}px;
    margin-left: 0;
  }
`

const AutoresponderItem = ({
  attachment,
  updateAttachment,
  deleteAttachment,
  disabled = false,
}) => {
  const { id: attachmentId, displayName, housingCategory } = attachment
  const [attachmentName, setAttachmentName] = useState(displayName)
  const housingCategoryOption = attachmentCategoriesOptions.find(
    (o) => o.value === housingCategory,
  )

  const onCategoryChanged = ({ value }) => {
    updateAttachment(attachmentId, value, attachmentName)
  }

  const updateAttachmentDisplay = () => {
    if (!attachmentName) {
      snugNotifier.error(`Display name can't be empty`)
      return
    }

    if (attachmentName === displayName) return
    updateAttachment(attachmentId, housingCategory, attachmentName)
  }

  return (
    <StyledAutoResponderItem mt={4}>
      <StyledAutoResponderItemInternals width="37%">
        <AppDocumentListItemSecondVersion
          document={attachment}
          key={attachmentId}
          titleClass="width100 height-48 m0"
        />
      </StyledAutoResponderItemInternals>
      {!disabled && (
        <>
          <StyledAutoResponderItemInternals flexGrow={3}>
            <Input
              flexBasis="100%"
              height={`${theme.height[12]}px`}
              inputProps={{
                value: attachmentName,
                onChange: (event) => setAttachmentName(event.target.value),
                onBlur: updateAttachmentDisplay,
              }}
            />
          </StyledAutoResponderItemInternals>
          <StyledAutoResponderItemInternals width="20%">
            <Select
              options={attachmentCategoriesOptions}
              changeHandler={onCategoryChanged}
              value={housingCategoryOption}
              label="Select category"
              resultsLabel="Categories"
              width="100%"
            />
          </StyledAutoResponderItemInternals>
          <StyledAutoResponderItemInternals flexGrow={1}>
            <Button
              onClick={() => deleteAttachment(attachmentId)}
              variant="linkBlueWithoutWeight"
            >
              remove
            </Button>
          </StyledAutoResponderItemInternals>
        </>
      )}
    </StyledAutoResponderItem>
  )
}

export const AutoresponderAttachments = ({ teamId, disabled = false }) => {
  const [attachments, setAttachments] = useState([])
  const [loadingStates, setLoadingStates] = useState(
    createInitialLoadingState(),
  )
  const loadingStatesUtils = createLoadingStateUtils(setLoadingStates)

  const loadAttachments = () => {
    loadingStatesUtils.startLoading()
    return listAutoresponderAttachments(teamId)
      .then((res) => {
        setAttachments(res)
        loadingStatesUtils.markDoneSuccessfully()
      })
      .catch((err) => {
        err?.message && snugNotifier.error(err.message)
        loadingStatesUtils.setError(err)
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadAttachments, [teamId])

  const uploadAttachment = () => {
    const filesElem = document.getElementById(`attachments-${attachmentsKey}`)
    const files = [...filesElem.files]
    loadingStatesUtils.startLoading()
    return createAutoresponderAttachment(teamId, files)
      .then(() => {
        snugNotifier.success('Uploaded attachment successfully')
        loadingStatesUtils.markDoneSuccessfully()
        loadAttachments()
      })
      .catch((err) => {
        loadingStatesUtils.setError(err)
        err?.message && snugNotifier.error(err.message)
      })
  }

  const deleteAttachment = (attachmentId) => {
    loadingStatesUtils.startLoading()
    return deleteAutoresponderAttachment(teamId, attachmentId)
      .then(() => {
        loadingStatesUtils.markDoneSuccessfully()
        loadAttachments()
        snugNotifier.success('Attachment removed successfully')
      })
      .catch((err) => {
        err?.message && snugNotifier.error(err.message)
        loadingStatesUtils.setError(err)
      })
  }

  const updateAttachment = (attachmentId, newCategory, newDisplayName) => {
    loadingStatesUtils.startLoading()
    return updateAutoresponderAttachment(
      teamId,
      attachmentId,
      newCategory,
      newDisplayName,
    )
      .then(() => {
        loadingStatesUtils.markDoneSuccessfully()
        loadAttachments()
        snugNotifier.success('Attachment updated successfully')
      })
      .catch((err) => {
        loadingStatesUtils.setError(err)
        err?.message && snugNotifier.error(err.message)
      })
  }

  return (
    <Box mt={8}>
      <Text
        as="span"
        fontSize={theme.fontSizes[7] + 'px'}
        fontWeight={theme.fontWeights[6]}
      >
        Attachments
      </Text>
      <Text mt={3}>
        Add auto-responder attachments based on the housing category set in
        onlist.
      </Text>

      <DocumentList
        addDocument={uploadAttachment}
        documentType={attachmentsKey}
        disableMultiple={true}
        showUploader={!disabled}
      />

      <LoadingSection
        loadingState={loadingStates}
        actionHandler={loadAttachments}
      >
        {attachments &&
          attachments.map((attachment) => (
            <AutoresponderItem
              attachment={attachment}
              deleteAttachment={deleteAttachment}
              updateAttachment={updateAttachment}
              disabled={disabled}
            />
          ))}
      </LoadingSection>
    </Box>
  )
}
