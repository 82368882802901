import React from 'react'

import moment from 'moment'

import AdminMenu from 'app/bond_cover/admin/menu.js'
import ApplicationBtn from 'app/bond_cover/admin/requests/application_button_widget'
import BankAccount from 'app/bond_cover/admin/requests/bankaccount_widget'
import Identity from 'app/bond_cover/admin/requests/identity_doc_widget'
import Lease from 'app/bond_cover/admin/requests/lease_widget'
import Manager from 'app/bond_cover/admin/requests/manager_widget'
import PricingVariationWidget from 'app/bond_cover/admin/requests/pricing_variation_widget'
import Quote from 'app/bond_cover/admin/requests/quote_widget'
import Ratings from 'app/bond_cover/admin/requests/ratings_widget'
import Renter from 'app/bond_cover/admin/requests/renter_widget'
import Request from 'app/bond_cover/admin/requests/request_widget'
import Signature from 'app/bond_cover/admin/requests/signature_widget'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'
import { SubmissionStatus } from 'app/sm/helpers'

const isPricingVariationValueValid = (bondCoverAmount, premium) => (value) => {
  const totalPricingVariation = premium + Number(value)
  return !(totalPricingVariation < 0 || totalPricingVariation > bondCoverAmount)
}

const reasons = [
  { name: '', reason: '' },
  { name: 'Manager Verbally Approved', reason: 1 },
  { name: 'ID Verified Manually', reason: 2 },
  { name: 'Other Reason', reason: 3 },
]

const validationHelpers = {
  ok: () => ({ ok: true, error: '' }),
  error: (text) => ({ ok: false, error: text }),
}

class RequestForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      reopenClicked: false,
      releaseClicked: false,
      approveClicked: false,
      reason: '',
      description: '',
      validation: {
        reason: validationHelpers.ok(),
        description: validationHelpers.ok(),
      },
      discounts: [],
    }
  }

  UNSAFE_componentWillMount() {
    let { getRequestSummary } = this.props
    let guidID = this.guid()
    if (guidID) {
      getRequestSummary(guidID)
    }
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      releaseClicked: false,
      reopenClicked: false,
      approveClicked: false,
    })
  }

  onApprove = () => {
    if (
      window.confirm(
        `Are you sure you want to approve the application - Reason: ${
          reasons[this.state.reason].name
        }`,
      )
    ) {
      const {
        approveApplication,
        admin: {
          requestSummary: { request },
        },
      } = this.props
      const { description, reason } = this.state
      const appCode = (request || {}).appCode
      const guidID = this.guid()
      const data = { reason: Number(reason), description }
      this.setState({ approveClicked: true })
      if (guidID) {
        approveApplication(appCode, data, guidID)
      }
      this.reset()
    }
  }

  onDecline = () => {
    // history.push(`/admin/decline`)
    const { redirectToDeclineForm } = this.props
    redirectToDeclineForm()
  }

  onProcessPayment = () => {
    let { processPayment } = this.props
    let guidID = this.guid()
    processPayment(guidID)
  }

  onRelease = () => {
    let { releaseApplication } = this.props
    let guidID = this.guid()
    this.manageReleaseState()
    if (guidID) {
      releaseApplication(guidID)
    }
  }

  onReopen = (text) => {
    let { reopenApplication } = this.props
    let appCode = this.props.admin.requestSummary.request.appCode
    let guidID = this.guid()
    this.manageReopenState()
    if (appCode && guidID) {
      reopenApplication(appCode, guidID)
    }
  }

  onSend = () => {
    let { resendApplication } = this.props
    let appCode = this.props.admin.requestSummary.request.appCode
    let guidID = this.guid()
    this.manageReleaseState()
    if (appCode) {
      resendApplication(appCode, guidID)
    }
  }

  onUnpause = (currentStage) => {
    let { releaseBackToRenter, releaseToIDCheck, releaseToCertificate } =
      this.props
    let guidID = this.guid()
    if (currentStage === 'Release Back to Renter') {
      releaseBackToRenter(guidID)
    } else if (currentStage === 'Release to ID check') {
      releaseToIDCheck(guidID)
    } else if (currentStage === 'Release to Certificate') {
      releaseToCertificate(guidID)
    }
  }

  setDescription = (e) => {
    this.setState({ description: e.target.value })
  }

  setReason = (e) => {
    this.setState({ reason: e.target.value })
  }

  confirmDeletingDiscounts = () => {
    if (window.confirm('Are you sure you want to remove all discounts')) {
      const {
        admin: { requestSummary },
      } = this.props
      const requestId = requestSummary.request.guidID
      const guids = requestSummary.pricingVariations
        .filter((pv) => pv.deletedAt === null)
        .map((pv) => pv.guidID)

      this.props.removeAllDiscounts(requestId, guids)
    }
  }

  guid() {
    let search = getParameter('id')
    return search
  }

  manageReleaseState() {
    this.setState({
      releaseClicked: true,
    })
  }

  manageReopenState() {
    this.setState({
      reopenClicked: true,
    })
  }

  reset() {
    this.setState({ reason: '', description: '' })
  }

  validate = (field, value) => {
    let previous = this.state.validation
    let next
    let errorMessage
    switch (field) {
      case 'description':
        if (value.length === 0) {
          errorMessage = `${field.charAt(0).toUpperCase()}${field.slice(
            1,
          )} is a required field`
        }
        break
      case 'reason':
        if (value === '') {
          errorMessage = `${field.charAt(0).toUpperCase()}${field.slice(
            1,
          )} is a required field`
        }
      default:
        break
    }
    if (errorMessage !== undefined) {
      next = { [field]: validationHelpers.error(errorMessage) }
    } else {
      next = { [field]: validationHelpers.ok() }
    }
    this.setState({ validation: Object.assign({}, previous, next) })
  }

  render() {
    let reopenState = this.state.reopenClicked
    let releaseState = this.state.releaseClicked
    let errorAmount = null
    let resetField = null

    let { admin = {}, spinner, responseText } = this.props
    let {
      request = null,
      lease = null,
      quote = null,
      manager = null,
      renter = null,
      tenants = null,
      bankAccount = null,
      identityDoc = null,
      renterSignature = null,
      renterRatings = null,
      managerDeclined = null,
      payment = null,
    } = admin.requestSummary || {}

    const managerSignature = (manager || {}).Signature
    manager = (manager || {}).Manager

    errorAmount = admin.errorAmount
    resetField = admin.resetField

    let { pricingVariations = [] } = (admin || {}).requestSummary || {}
    pricingVariations = pricingVariations.map((d) =>
      Object.assign({}, d, {
        amount: d.value,
        date: moment(d.createdAt).format('YYYY-MM-DD'),
      }),
    )

    const { approveClicked, description, reason, validation } = this.state
    const { submissionStatus, pauseState } = admin.requestSummary.request || {}
    const approveBtn = description.length === 0

    const isFeeDiscounted = pricingVariations.some(
      (pv) => pv.deletedAt === null,
    )
    const isPricingVariationValid = isPricingVariationValueValid(
      (quote || { bondAmount: 0 }).bondAmount,
      (quote || { premium: 0 }).premium,
    )
    const isPricingVariationFormReadOnly =
      (request || {}).submissionStatus === SubmissionStatus.CompletelySubmitted

    let pauseButtonContext = ''
    if (request && request.pauseState) {
      switch (request.pauseState) {
        case 2:
          pauseButtonContext = 'Release Back to Renter'
          break
        case 3:
          pauseButtonContext = 'Release to ID check'
          break
        case 4:
          pauseButtonContext = 'Release to Certificate'
          break
        default:
          pauseButtonContext = ''
          break
      }
    }

    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <AdminMenu />
          <div className="content">
            <ApplicationBtn
              lease={lease}
              request={request}
              payment={payment}
              reopenState={reopenState}
              releaseState={releaseState}
              onReopen={this.onReopen}
              onRelease={this.onRelease}
              onSend={this.onSend}
              pauseButtonContext={pauseButtonContext}
              onUnpause={this.onUnpause}
              spinner={spinner}
              responseText={responseText}
              onProcessPayment={this.onProcessPayment}
              onDecline={this.onDecline}
            />
            <hr />
            <Request
              request={request}
              lease={lease}
              managerDeclined={managerDeclined}
              payment={payment}
            />
            <hr />
            <Lease lease={lease} />
            <hr />
            <Quote
              quote={quote}
              isFeeDiscounted={isFeeDiscounted}
              isPricingVariationFormReadOnly={isPricingVariationFormReadOnly}
              removeDiscountsClicked={this.confirmDeletingDiscounts}
            />
            <hr />
            <Manager manager={manager} managerSignature={managerSignature} />
            <hr />
            {tenants &&
              tenants.map((tenant, index) => (
                <div key={index + 1}>
                  <Renter tenant={tenant} />
                  <hr />
                </div>
              ))}
            <BankAccount bankAccount={bankAccount} />
            <hr />
            <Identity identityDoc={identityDoc} />
            <hr />
            {/* {
              tenantSignatures && tenantSignatures.map((tenant, index)=> (
                <div key={index} >
                  <Signature signature={ tenant.Signature } type={ tenant.Tenant.isPrimary ? 'Primary Tenant ': 'Other Tenant ' } />
                  <hr/>
                </div>
              ))
            } */}

            {/* <Signature signature={ managerSignature } type={ "Manager" } />
            <hr/> */}
            <Ratings ratings={renterRatings} />
            <hr />
            <PricingVariationWidget
              isPricingVariationFormReadOnly={isPricingVariationFormReadOnly}
              isPricingVariationValid={isPricingVariationValid}
              request={request}
              addPricingVariation={this.props.addPricingVariation}
              deletePricingVariation={this.props.deletePricingVariation}
              pricingVariations={pricingVariations}
              renter={renter}
              lease={lease}
              errorAmount={errorAmount}
              resetField={resetField}
            />
            <hr />
            {submissionStatus === 2 && pauseState !== 0 && (
              <div>
                <h3>Approve application</h3>
                <div className="discount-form mt25 p10">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="input-box">
                        <select
                          required
                          value={reason}
                          onChange={this.setReason}
                          onBlur={(e) =>
                            this.validate('reason', e.target.value)
                          }
                        >
                          {reasons.map((r, i) => (
                            <option value={r.reason} disabled={!i} key={r.name}>
                              {r.name}
                            </option>
                          ))}
                        </select>
                        <label>Reason</label>
                      </div>
                      {!validation.reason.ok && approveBtn && (
                        <div className="alert alert-danger">
                          {validation.reason.error}
                        </div>
                      )}

                      <div className="input-box">
                        <textarea
                          value={description}
                          onChange={this.setDescription}
                          onBlur={(e) =>
                            this.validate('description', e.target.value)
                          }
                        />
                        {!validation.description.ok && approveBtn && (
                          <div className="alert alert-danger">
                            {validation.description.error}
                          </div>
                        )}
                      </div>

                      <div className="requestBtnBox">
                        {
                          <button
                            className="requestBtn"
                            onClick={this.onApprove}
                            disabled={approveBtn}
                          >
                            Approve Application
                            <i
                              className={
                                approveClicked ? 'fa fa-spinner fa-pulse' : ''
                              }
                            />
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default RequestForm
