import React, { useState } from 'react'

import styled from 'styled-components'

import rocket from 'app/assets/icons/rocket.png'
import {
  Alert,
  Box,
  Button,
  Flex,
} from 'app/components/design-system-components'
import { mediaQueries } from 'app/components/design-system-components/styles/variables'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import InviteTeamMembersModal from 'app/sm/team_viewing_preference/setup/inviteTeamMemberModal'
import * as gtmHelpers from 'app/utils/google-tag-manager/helpers'

const StyledAlertContainer = styled(Flex)`
  ${mediaQueries.sm} {
    flex-direction: column;
    text-align: center;
  }
  width: 100%;
`

function SnugUpgradeCTA({ teamGUID }) {
  const [inviteTeamMemberModalOpen, setInviteTeamMemberModalOpen] =
    useState(false)

  const toggleInviteTeamMemberModalOpen = () => {
    setInviteTeamMemberModalOpen(!inviteTeamMemberModalOpen)
  }

  return (
    <>
      <Flex justifyContent="center" mt={9}>
        <Flex style={{ maxWidth: theme.width[19] + 'px' }}>
          <Alert
            variant="blueWithBg"
            style={{ minWidth: theme.width[19] + 'px' }}
          >
            <StyledAlertContainer alignItems="center">
              <img
                src={rocket}
                width="100px"
                height="100px"
                alt="curious about snug"
              />
              <Box ml={6}>
                <Text mb={3}>
                  Invite your team to manage all your agency’s Snug rental
                  references.
                </Text>
                <Button
                  onClick={toggleInviteTeamMemberModalOpen}
                  className={gtmHelpers.RR_LITE_JUNE_2023_GTM_CTA_BUTTON}
                >
                  Invite team
                </Button>
                <Text mt={3} mb={0}>
                  <a
                    href="https://snug.com/manager/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Find out more
                  </a>{' '}
                  about Snug
                </Text>
              </Box>
            </StyledAlertContainer>
          </Alert>
        </Flex>
      </Flex>

      {inviteTeamMemberModalOpen && (
        <InviteTeamMembersModal
          teamGUID={teamGUID}
          toggleModal={toggleInviteTeamMemberModalOpen}
          primarButtonClass={
            gtmHelpers.RR_LITE_JUNE_2023_GTM_INVITE_TEAM_MEMBER
          }
        />
      )}
    </>
  )
}

export default SnugUpgradeCTA
