import React from 'react'

import documentIcon from 'app/assets/icons/summary_icons/attachment.svg'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import { urlTo } from 'app/sm/helpers'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'

const OtherDocumentContainer = ({
  componentClassName = '',
  attachments = [],
  isOtherDocumentEditable = true,
  onEditClick,
}) => {
  return (
    <div className={`container-component ${componentClassName}`}>
      <ContainerHeader
        icon={documentIcon}
        text="Other Documents"
        isEditable={isOtherDocumentEditable}
        onEditClick={() =>
          onEditClick(
            `${urlTo('RentalReputationDocuments')}?from=rentersummary`,
          )
        }
      />
      <div className="mt30 mb30">
        {attachments.length > 0 &&
          attachments.map((attachment) => (
            <AppDocumentListItemSecondVersion
              titleClass="mt0 width-60"
              document={attachment}
              key={attachment.guidID}
            />
          ))}
        {attachments.length === 0 && <div>Not provided</div>}
      </div>
    </div>
  )
}
export default OtherDocumentContainer
