import { delay, put, select, takeEvery } from 'redux-saga/effects'

import {
  actions as coreActions,
  actionTypes as coreActionTypes,
} from 'app/core/coreSlice'
import { checkForNewFeed } from 'app/services/http/activityfeed'

const recheckNewActivityFeedInSeconds = 15

function* checkNewActivityFeed() {
  const { currentTeam } = yield select((state) => state.session)

  if (!currentTeam) return

  try {
    const result = yield checkForNewFeed(currentTeam.guid)
    yield put(
      coreActions.hasNewActivityFeedUpdate({ hasNewActivityFeed: result }),
    )
  } catch {}

  yield delay(recheckNewActivityFeedInSeconds * 1000)
  yield put(coreActions.newActivityFeedCheck())
}

export function* watchNewActivityFeedCheck() {
  yield takeEvery(coreActionTypes.newActivityFeedCheck, checkNewActivityFeed)
}
