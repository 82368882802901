import React from 'react'

import moment from 'moment'
// import data from './data.json'
import styled from 'styled-components'

import Dropdown from 'app/application_revamp/components/Common/Dropdown'

// const Btn = styled.button`
//   transition: all 0.3s ease;
//   background: #fff;
//   border: 2px solid #37c977;
//   border-radius: 4px;
//   font-size: 18px;
//   line-height: 1.3;
//   color: #68696b;
//   padding: 6px 16px 7px;
//   letter-spacing: 1px;
//   min-width: 182px;
//   text-align: center;
//   cursor: pointer;

//   &:hover {
//     color: #fff;
//     background: #37c977;
//   }
// `

// const Description = styled.div`
//   padding: 24px 0 16px;
// `

const HouseholdWidget = styled.div`
  border: 1px solid var(--pdf-gray-middle);
  border-top: 8px solid var(--pdf-red);
  border-radius: 3px;
  padding: 15px 24px 24px;
  margin-bottom: 24px;
  font-size: 16px;

  h2 {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .opposite-sides {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    span:nth-child(2n) {
      font-weight: bold;
    }
  }

  p {
    margin: 0;
  }
`

const findAverageForRentalHistory = (totalMonths, numProperties) => {
  if (!!!numProperties) {
    return 'N/A'
  }

  const averageTenure = Math.round(totalMonths / numProperties)
  const yearsAndMonths = {
    years: moment.duration(averageTenure, 'months').years(),
    months: moment.duration(averageTenure, 'months').months(),
  }
  const { years, months } = yearsAndMonths
  let averageAddressTenure = ''

  if (years > 0) {
    if (years > 1) averageAddressTenure += years + ' years '
    else averageAddressTenure += years + ' year '
  }

  if (months > 0) {
    if (months > 1) averageAddressTenure += months + ' months '
    else averageAddressTenure += months + ' month'
  }

  return averageAddressTenure
}

const Household = ({
  rentToIncomeRatio,
  rentalHistory,
  optimisingInsightsDropdownContent,
  updateOwnerMatchPreferences,
  dropdownSelectedItem,
  householdInsights = { months: 0, numProperties: 1 },
}) => {
  // const { household } = data
  // const { rating } = household
  // let position = rating.position
  const { months, numProperties } = householdInsights

  const avgTenure =
    rentalHistory && findAverageForRentalHistory(months, numProperties)
  const rentToIncomeRationStr = !!rentToIncomeRatio
    ? `${rentToIncomeRatio}%`
    : 'N/A'
  const avgAddressTenureStr = !!avgTenure ? avgTenure : `N/A`
  // if (position === 1) position += 'st'
  // else if (position === 2) position += 'nd'
  // else if (position === 3) position += 'rd'
  // else position += 'th'

  return (
    <HouseholdWidget className="household-widget">
      <h2>Household Insights</h2>
      <div>
        <div className="opposite-sides">
          <div>
            Optimising
            <Dropdown
              dropdownSelectedItem={dropdownSelectedItem}
              dropdownContent={optimisingInsightsDropdownContent}
              updateDropdown={updateOwnerMatchPreferences}
            />
          </div>
        </div>
        {/* <dd>
          <span role="img" aria-label="winner">
            🏆
          </span>{' '}
          {position} of {rating.total}
        </dd> */}
        <div className="opposite-sides">
          <span>Rent to income ratio</span>
          <span>{rentToIncomeRationStr}</span>
        </div>

        <div className="opposite-sides">
          <span>Avg address tenure</span>
          <span>{avgAddressTenureStr}</span>
        </div>
        {/* <dt>Reference quality</dt>
        <dd>{household.quality || '-'}</dd>
        <dt>Risk ratio</dt>
        <dd>{household.risk_ratio || '-'}</dd>
        <dt>Rent stability</dt>
        <dd>{household.rent_stability || '-'}</dd> */}
      </div>
      {/* <Description>
        <p>Need to make quicker leasing decisions?</p>
        <p>
          <b>Unlock more Insights with Snug Plus</b>
        </p>
      </Description>
      <Btn>Try Snug Plus</Btn> */}
    </HouseholdWidget>
  )
}

export default Household
