// must be equivalent to the BE const
export const DefaultActivitiesListingLimit = 10

export const ApplicationAssigned = 'APPLICATION_ASSIGNED'
export const ApplicationUpdated = 'APPLICATION_UPDATED'
export const ReferenceDeclined = 'REFERENCE_DECLINED'
export const ReferenceAdded = 'REFERENCE_ADDED'
export const EmploymentVerified = 'EMPLOYMENT_VERIFIED'
export const LeasedPropertiesAutoArchived = 'LEASED_PROPERTIES_AUTO_ARCHIVED'
export const ReferenceArchived = 'REFERENCE_ARCHIVED'
export const ReferenceUnarchived = 'REFERENCE_UNARCHIVED'
export const ReferenceReassigned = 'REFERENCE_REASSIGNED'
export const CalendarExpired = 'CALENDAR_EXPIRED'

export const MeTab = 'me'
export const TeamTab = 'team'

export const allTab = {
  key: TeamTab,
  header: 'Team',
}
export const meTab = {
  key: MeTab,
  header: 'Me',
}
