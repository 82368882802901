import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'

export const formFields = {
  property: 'property',
  subject: 'subject',
  message: 'message',
}

export interface IBallotMessageForm {
  property: string
  subject: string
  message: string
}

// @ts-ignore
const fieldValidation = (fieldName: string, val: string) => {
  switch (fieldName) {
    case formFields.subject: {
      return {
        required: (!val || !val.length) && 'subject is required',
      }
    }
    case formFields.message: {
      return {
        required: (!val || !val.length) && 'message is required',
      }
    }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
