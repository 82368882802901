import { connect } from 'react-redux'

import AccessDetails from 'app/sm/onlist_details/AccessDetails/index'
import {
  createEntryDetails,
  updateEntryDetails,
} from 'app/sm/onlist_details/action'

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  createEntryDetails: (agencyGUID, propertyGUID, requestBody) =>
    dispatch(createEntryDetails(agencyGUID, propertyGUID, requestBody)),
  updateEntryDetails: (agencyGUID, propertyGUID, requestBody) =>
    dispatch(updateEntryDetails(agencyGUID, propertyGUID, requestBody)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccessDetails)
