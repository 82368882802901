import { useEffect } from 'react'

import styled from 'styled-components'
import { layout, space, typography } from 'styled-system'

import Box from 'app/components/design-system-components/Box'
import * as themeGetters from 'app/components/design-system-components/theme/getters'

const TextAreaContainer = styled(Box)`
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;

  background-color: ${themeGetters.color('white')};
  padding: ${themeGetters.spacing(4)};

  & textarea {
    border: 0;
    display: flex;
    flex: 1 1;
  }

  border: 1px solid ${themeGetters.color('grey200')};
  border-radius: ${themeGetters.radius(6)}px;

  &.error {
    border-color: ${themeGetters.color('primary600')};
    color: ${themeGetters.color('primary600')};
  }

  ${typography}
  ${layout}
  ${space}
`

export const TextArea = ({
  containerProps,
  resizedQuerySelectorId = undefined,
  ...props
}) => {
  useEffect(() => {
    if (resizedQuerySelectorId) {
      const textarea = document.getElementById(resizedQuerySelectorId)
      textarea.style.overflow = 'hidden'
      textarea.style.height = 'inherit'
      textarea.style.height = textarea.scrollHeight + 'px'
    }
  }, [resizedQuerySelectorId, props.value])
  return (
    <TextAreaContainer {...containerProps}>
      <textarea id={resizedQuerySelectorId} {...props} />
    </TextAreaContainer>
  )
}
