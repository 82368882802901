import React from 'react'

import moment from 'moment'

import * as Display from 'app/components/display/display'
import { DetailsBoxCard } from 'app/components/display/display'
import Text from 'app/components/text/text'
import { ErrorMessage } from 'app/shared_components/helpers'
import EmploymentEditModal from 'app/sm/employment/employment_edit_modal'
import * as helpers from 'app/sm/helpers'
import { getBusinessNumberLabel } from 'app/sm/helpers'
import { formatAddress } from 'app/utils/address/helpers'
import { currencyFormat } from 'app/utils/numbers/helpers'
import * as stringHelpers from 'app/utils/strings/helpers'

const VERIFIED_STRING = 'Verified'

class EmploymentBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      removeButtonClicked: false,
      removeApiError: '',
      confirmDeleteModal: false,
    }
  }

  confirmDeleteEmploymentHistory = () => {
    this.setState({
      confirmDeleteModal: true,
    })
  }

  removeEmployer = (guidID) => {
    const { removeEmployer } = this.props
    this.setState({ removeButtonClicked: true })
    removeEmployer(
      guidID,
      () => {
        this.setState({
          removeApiError: '',
          removeButtonClicked: false,
          confirmDeleteModal: false,
        })
      },
      (responseText) => {
        this.setState({
          removeApiError: responseText,
          removeButtonClicked: false,
          confirmDeleteModal: false,
        })
      },
    )
  }

  toggleModal(toggleField) {
    this.setState({
      [toggleField]: !this.state[toggleField],
    })
  }

  render() {
    const {
      employer,
      disabled,
      role,
      isContactDetailsUpdated,
      errors,
      onFormUpdate,
      isEmployerEditModal,
      openEmployerEditModal,
      onSaveContactDetails,
      isEditLink = false,
    } = this.props

    let {
      employerName,
      employerTradingName,
      employerABN,
      jobTitle,
      startDate,
      endDate,
      stillEmployed,
      weeklyNetIncome,
      grossAnnualIncome,
      contactName,
      phoneNumber,
      email,
      verifyStatus,
      verifyDate,
      employmentType,
      address = {},
      officeNumber,
      hasAccountant,
      isReferenceRequestSent,
      incomeType,
      netIncome,
    } = employer || {}

    if (isContactDetailsUpdated) {
      employerName = employer.employerName
      address = employer.address
      contactName = employer.contactName
      jobTitle = employer.jobTitle
      startDate = employer.startDate
      weeklyNetIncome = employer.weeklyNetIncome
      grossAnnualIncome = employer.grossAnnualIncome
      employmentType = employer.employmentType
      endDate = employer.endDate
      verifyStatus = employer.verifyStatus
      isReferenceRequestSent = employer.isReferenceRequestSent
      verifyDate = employer.verifyDate
      stillEmployed = employer.stillEmployed
      officeNumber = employer.officeNumber
      hasAccountant = employer.hasAccountant
      incomeType = employer.incomeType
      netIncome = employer.netIncome
    }

    const { removeButtonClicked, removeApiError, confirmDeleteModal } =
      this.state
    const modalText =
      helpers.EmploymentVerifyStatus[verifyStatus] === VERIFIED_STRING
        ? 'Employment Details have been verified. Are you sure?'
        : 'Are you sure?'
    const employmentDuration = helpers.calculateDuration(
      startDate,
      endDate,
      stillEmployed,
    )
    const showRenterWeeklyIncome = role !== 'employer'

    const isIncomePresent = (income) => {
      return income && parseInt(income) && income >= 0
    }
    const hasGrossAnnualIncome = isIncomePresent(grossAnnualIncome)
    const showEmployerWeeklyIncome =
      !showRenterWeeklyIncome && isIncomePresent(weeklyNetIncome)

    const formatLabelAndValue = (label, value) => {
      return `${label}: ${value}`
    }
    const grossAnnualIncomeText = formatLabelAndValue(
      Text.placeholder.annualIncomePlaceholder,
      currencyFormat(grossAnnualIncome, 0),
    )
    const weeklyNetIncomeText = formatLabelAndValue(
      Text.placeholder.weeklyIncomePlaceholder,
      currencyFormat(weeklyNetIncome, 0),
    )

    const incomePeriodType =
      incomeType === 1
        ? 'Fortnightly'
        : incomeType === 2
        ? 'Calendar Monthly'
        : 'Weekly'
    const income = showRenterWeeklyIncome
      ? `${incomePeriodType} ${
          Text.placeholder.incomePlaceholderForPeriod
        }: ${currencyFormat(netIncome, 0)}` +
        `${
          !grossAnnualIncome
            ? `(Annual: $${
                weeklyNetIncome && (weeklyNetIncome * 52).toFixed(2)
              })`
            : ''
        }`
      : ''
    let details = []

    const employmentDetails = [
      `Employer address: ${formatAddress(address)}`,
      `Job title: ${stringHelpers.capitalizeFirstLetterOfString(jobTitle)}`,
      `Employment type: ${helpers.EmploymentTypes[employmentType]}`,
      `Date: ${employmentDuration}`,
      `${hasGrossAnnualIncome ? grossAnnualIncomeText : ''}`,
      `${showEmployerWeeklyIncome ? weeklyNetIncomeText : ''}`,
      `${showRenterWeeklyIncome ? income : ''}`,
    ]

    const companyDetail = [
      `${employerName ? `Company name: ${employerName}` : ''}`,
      `${
        employerTradingName
          ? `Company trading name: ${employerTradingName}`
          : ''
      }`,
      `${
        employerABN
          ? `${getBusinessNumberLabel(address, 'Company ')}: ${employerABN}`
          : ''
      }`,
    ]
    const referenceDetails = !helpers.isSelfEmployed(employmentType)
      ? [
          `${`${
            hasAccountant ? 'Accountant Name' : 'Contact name'
          }`}: ${contactName}`,
          `${phoneNumber ? `Contact number: ${phoneNumber}` : ''}`,
          `${officeNumber ? `Office phone: ${officeNumber}` : ''}`,
          `${email ? `Contact email: ${email}` : ''}`,
        ]
      : companyDetail

    let statusText = 'Not sent'
    let pillType = 'orange-hollow'
    let sendStatusInfo = false
    if (isReferenceRequestSent) {
      statusText = `${helpers.EmploymentVerifyStatus[verifyStatus]} ${
        helpers.isEmploymentStatusVerified(verifyStatus)
          ? `${
              verifyDate &&
              `(${moment(verifyDate).format(helpers.standardMilitaryTime)})`
            }`
          : ''
      }`
      pillType =
        helpers.EmploymentVerifyStatus[verifyStatus] === VERIFIED_STRING
          ? 'hollow'
          : 'orange-hollow'
    }
    details = details.concat(employmentDetails)
    if (
      !helpers.isSelfEmployedNoAccountant(employmentType, hasAccountant) ||
      helpers.isSelfEmployed(employmentType)
    ) {
      if (!isEditLink) {
        details = details.concat(referenceDetails)
      }

      if (isEditLink) {
        details = details.concat(companyDetail)
      }
      if (showRenterWeeklyIncome) {
        sendStatusInfo = true
      }
    }
    return (
      <div>
        <DetailsBoxCard
          title={`Employer: ${employerName} ${
            stillEmployed ? '(Current)' : ''
          }`}
          icon="fa fa-id-badge"
          titleClass="ml20"
          details={details}
          disabled={disabled || removeButtonClicked}
          editable={false}
          onRemove={() => this.confirmDeleteEmploymentHistory()}
          isEmploymentDetail={sendStatusInfo}
          pillType={pillType}
          statusText={statusText}
          isEditLink={isEditLink}
          isEditText={'Details incorrect'}
          isEditLinkText={'Update details'}
          openEditModal={openEmployerEditModal}
        />
        <ErrorMessage error={removeApiError} />
        {confirmDeleteModal && (
          <Display.Modal
            toggleModal={() => this.toggleModal('confirmDeleteModal')}
            title="Remove Employment History"
            hideButtonSection
            modalBodyClass="mt10"
            modalSubheadingClass="ml-auto mr-auto"
            modalSubheaderClass="position-relative height-auto"
          >
            <div className="fs16 ">
              <Display.DefaultBodyText
                text={modalText}
                textClass="text-align-center"
              />
            </div>
            <Display.ModalButtonGroup
              primaryLabel="Remove"
              secondaryLabel="Cancel"
              secondaryButtonType="tertiary"
              primaryAction={() => this.removeEmployer(employer.guidID)}
              secondaryAction={() => this.toggleModal('confirmDeleteModal')}
            />
          </Display.Modal>
        )}
        {isEmployerEditModal && (
          <EmploymentEditModal
            closeReasonsModal={openEmployerEditModal}
            details={employer}
            errors={errors}
            onFormUpdate={onFormUpdate}
            isEmployerEditModal={isEmployerEditModal}
            onSaveContactDetails={onSaveContactDetails}
          />
        )}
      </div>
    )
  }
}

export default EmploymentBox
