import { useEffect } from 'react'

import qs from 'qs'
import { useHistory } from 'react-router-dom'

const noopFn = () => {}

export const useUpdateFilters = (filters, updater) => {
  const history = useHistory()
  useEffect(() => {
    const { queryBuilder = noopFn } = updater
    const query = queryBuilder(filters)
    const queryStr = qs.stringify(query, { arrayFormat: 'repeat' })
    history.push({ search: queryStr })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])
}
