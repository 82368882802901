import type React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import TextSpan from 'app/components/design-system-components/TextSpan'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

interface IBallotsContentProps {
  ballotID: string
  ballotEnteredAt: string
}

export const StyledLabel = styled(Box)`
  color: ${theme.colors.gray500};
`

export const StyledContent = styled(Box)`
  color: ${theme.colors.black};
  font-weight: ${theme.fontWeights.h2};
`

export const BallotsContent: React.FC<IBallotsContentProps> = ({
  ballotID,
  ballotEnteredAt,
}) => {
  return (
    <Box>
      <Flex>
        <Box mb={theme.space[2]}>
          <StyledLabel>Ballot ticket #:</StyledLabel>
          <TextSpan ml={theme.space[2]}>
            <StyledContent>{ballotID}</StyledContent>
          </TextSpan>
        </Box>
      </Flex>
      <Flex>
        <StyledLabel>Entered:</StyledLabel>
        <TextSpan ml={theme.space[2]}>
          <StyledContent>
            {moment(ballotEnteredAt).format(dateTimeHelpers.STANDARD_DATETIME)}
          </StyledContent>
        </TextSpan>
      </Flex>
    </Box>
  )
}
