import React from 'react'

import PropTypes from 'prop-types'

import Card from 'app/match/applicationReportPDF/components/Card'
import InfoList from 'app/match/applicationReportPDF/components/InfoList'
import PDFBullet from 'app/match/applicationReportPDF/components/PDFBullet'
import Persona from 'app/match/applicationReportPDF/components/Persona'
import TitleHorizontalLine from 'app/match/applicationReportPDF/components/TitleHorizontalLine'

const propTypes = { data: PropTypes.array.isRequired }

const EmploymentDetails = ({ data = [] }) => {
  return (
    <div className="employment-details no-break-section">
      <Card
        type={'icon'}
        icon={'icon-new-construction'}
        title={'Employment Details'}
        addClass={'bottom-border mt-0'}
      />
      <div className="custom-row flex-wrap-wrap justify-content-space-between print__display_block">
        {data.length === 0 && <p>No information is provided</p>}
        {data.map((item, index) => {
          const {
            type,
            tenure,
            address,
            addressObj,
            gross_income,
            net_income,
            position,
            employer_abn,
            employer_trading_name,
            show_reason_affecting_employment_line = false,
            reason_affecting_employment,
            employer_name,
          } = item.generalInfo
          const verifyStatusText =
            item.contact.verifyStatus.toLowerCase() === 'pending'
              ? item.contact.verifyStatus
              : `${item.contact.verifyStatus} by`
          const verifyStatusDate =
            item.contact.verifyDate.length && item.contact.verifyDate

          let dataForInfoList = {
            type,
            tenure,
            address,
            gross_income,
            net_income,
          }
          let companyDetail = {
            employer_abn,
            employer_trading_name,
            type,
            employer_name,
            address: addressObj,
          }
          return (
            <div
              className={`width50p employment-pdf-row-container mb20 no-break-section print__display_inline_block`}
              key={index}
            >
              <h2>{position}</h2>
              <InfoList
                data={dataForInfoList}
                marked={'gross_income'}
                companyDetail={companyDetail}
              />
              <TitleHorizontalLine title={'Contact'} />
              <Persona info={item.contact} />
              <PDFBullet
                label={verifyStatusText}
                value={item.contact.name}
                supportingText={verifyStatusDate}
              />
              {show_reason_affecting_employment_line && (
                <div className="mt10">
                  <TitleHorizontalLine title="Reason Affecting Employment" />
                  <div>{reason_affecting_employment}</div>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

EmploymentDetails.propTypes = propTypes

export default EmploymentDetails
