import styled from 'styled-components'

export const Iframe = styled.iframe`
  width: ${(props) => props.width && props.width};
  min-height: ${(props) => props.minHeight && props.minHeight};
  border: none;
  margin: 20px 0;
`

export const MaxWidth = styled.div`
  max-width: ${(props) => props.maxWidth && props.maxWidth};
`

export const FormWrapper = styled.div`
  margin: ${(props) => (props.margin ? props.margin : '0 auto')};
  max-width: 450px;
`

export const Wrapper = styled.div`
  padding: 40px;
  background-color: ${(props) => props.darkTheme && '#252e3f'};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'unset')};
  height: 100%;
  display: ${(props) => props.displayType && props.displayType};
  align-items: ${(props) => props.alignItems && props.alignItems};
  justify-content: ${(props) => props.justifyContent && props.justifyContent};
  flex-direction: ${(props) => props.flexDirection && props.flexDirection};

  * {
    color: ${(props) => props.darkTheme && 'white'};
  }

  h2,
  h3 {
    color: ${(props) => !props.darkTheme && '#252e3f'};
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
  }

  @media (max-width: 991px) {
    padding: 40px 20px;
  }

  @media (max-width: 576px) {
    flex-direction: column;

    h2,
    h3 {
      text-align: center;
    }

    .snug-logo {
      text-align: center;
    }
  }
`

export const MainHeading = styled.h2`
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: ${(props) => props.textAlign && props.textAlign};

  @media (max-width: 576px) {
    font-size: ${(props) => props.fontSizeMobile && props.fontSizeMobile};
    line-height: ${(props) => props.lineHeightMobile && props.lineHeightMobile};
  }
`
export const SemiHeading = styled.h3`
  font-weight: normal;
  line-height: ${(props) => props.lineHeight && props.lineHeight};
  font-size: ${(props) => props.fontSize && props.fontSize};
  margin: ${(props) => props.margin && props.margin};

  @media (max-width: 576px) {
    font-size: ${(props) => props.fontSizeMobile && props.fontSizeMobile};
    line-height: ${(props) => props.lineHeightMobile && props.lineHeightMobile};
    margin: ${(props) => props.marginMobile && props.marginMobile};
  }
`

export const LinkList = styled.li`
  display: inline-flex;
  align-items: center;
  width: 50%;
  margin-bottom: 12px;

  span {
    padding: 0 10px 0 9px;
    font-size: 18px;
    line-height: 25px;
  }

  .arrow-right {
    transition: opacity 0.3s ease-in;
    width: 7px;
    opacity: 0.3;
  }

  a {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: white;
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.3s;
    }

    &:hover {
      .arrow-right {
        opacity: 1;
      }
      &:after {
        transform-origin: bottom left;
        transform: scaleX(1);
      }
    }
  }

  @media (max-width: 1370px) {
    span {
      width: ${(props) => props.width && props.width};
    }
  }

  @media (max-width: 991px) {
    span {
      font-size: 16px;
    }
  }

  @media (max-width: 576px) {
    width: 100%;
    justify-content: center;

    .arrow-right {
      display: none;
    }

    span {
      width: auto;
      font-size: 18px;
    }
  }
`
export const ExtraInfo = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`

export const InfoBox = styled.div`
  background: #f8f8f9;
  border-radius: 5px;
  padding: 8px;
  text-align: center;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #252e3f;
  }
`

export const Img = styled.img`
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'unset')};

  @media (max-width: 576px) {
    max-width: ${(props) =>
      props.maxWidthMobile
        ? props.maxWidthMobile
        : props.maxWidth
        ? props.maxWidth
        : 'unset'};
  }
`

export const PhoneImgWrapper = styled.div`
  position: relative;
  margin-top: ${(props) => props.marginTop && props.marginTop};
  margin-left: ${(props) => props.marginLeft && props.marginLeft};

  &:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 10px 100px 120px rgba(255, 255, 255, 0.12);
  }

  @media (max-width: 576px) {
    margin-top: 37px;
    margin-left: ${(props) => props.marginLeftMobile && props.marginLeftMobile};
  }
`
export const SubmitButton = styled.button`
  margin: 20px 0 30px;
  @media (max-width: 576px) {
    max-width: 218px;
  }
`
export const ResultWrapper = styled.div`
  background-color: rgba(0, 128, 57, 0.1);
  border-radius: 5px;
  padding: 20px 14px;
  margin: 20px 0 40px;
  display: flex;
  align-items: flex-start;

  img {
    margin-top: 9px;
    margin-right: 6px;
  }

  span {
    font-size: 18px;
    line-height: 25px
    color: #252E3F;
  }
`
export const LearnMoreWrapper = styled.div`
  display: none;

  @media (max-width: 576px) {
    display: block;
    text-align: center;
    margin-bottom: 16px;

    p {
      font-size: 14px;
      line-height: 130%;
      color: #7c8188;
      margin-bottom: 9px;
    }

    img {
      animation: arrow-movement 1.4s ease-in-out infinite;

      @keyframes arrow-movement {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.7;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
`
export const LinkToSnugWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0 34px;

  button,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    margin-right: 27px;
    max-width: 234px;
  }

  a {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #a6f1c7;
    transition: 0.2s ease-in color;

    &:hover {
      color: #37c977;
      text-decoration: none;
    }
  }

  svg {
    margin-left: 10px;
  }

  @media (max-width: 576px) {
    flex-direction: column;

    button {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
`
export const SecurityTextWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 14px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    color: #b7babd;
    margin-bottom: 0;
  }
`
