import React from 'react'

import {
  Box,
  Flex,
  HorizontalLine,
  IconButton,
  ProgressBar,
} from 'app/components/design-system-components'
import { ClearRounded } from 'app/components/design-system-components/icons/content'
import { ArrowBackIosRounded } from 'app/components/design-system-components/icons/navigation'
import { Heading } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const actionButtonsProps = {
  fontSize: theme.fontSizes[10],
}

export const StepsModalHeader = ({
  actions,
  progressBarConfig,
  title,
  children,
}) => {
  return (
    <Box>
      <ProgressBar {...progressBarConfig} />
      <Flex justifyContent="space-between" p={theme.space[7]}>
        <IconButton
          IconCmp={ArrowBackIosRounded}
          {...actionButtonsProps}
          variant="flat"
          onClick={actions.onBack}
        />
        <Flex flexDirection="column" alignItems="center">
          <Heading level={2}>{title}</Heading>
          <Heading level={5} color={theme.colors.gray600}>
            {children}
          </Heading>
        </Flex>
        <IconButton
          IconCmp={ClearRounded}
          {...actionButtonsProps}
          variant="flat"
          onClick={actions.onDismiss}
        />
      </Flex>
      <HorizontalLine />
    </Box>
  )
}
