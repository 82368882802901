import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const sendMessage = (message) => {
  const url = `${apiBaseUrl}sm/remarketing/sendMessage`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(message),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getRecommendedProperties = (agencyGUID, propGUID, limit) => {
  const url = `${apiBaseUrl}sm/remarketing/${agencyGUID}/recommendedProperties/${propGUID}?limit=${limit}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.statusCode,
            responseText: res.text(),
          }),
    )
    .then(
      (recommendedProperties) => ({
        ok: true,
        recommendedProps: recommendedProperties,
      }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.text,
      }),
    )
}
