import React from 'react'

import * as Form from 'app/components/forms/forms'
import { ErrorMessage } from 'app/shared_components/helpers'
import { QuestionType } from 'app/sm/helpers'
export class TextAnswerComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      errorMessage: '',
    }
  }

  changeText = (e) => {
    this.setState({ text: e.target.value })
  }

  updateTextRating = () => {
    const { questionCategory, referenceId, updateQuestionAnswer } = this.props
    const { text } = this.state
    if (this.validateRentalAmount(text) === false) {
      return
    }
    this.setState({ errorMessage: '' })
    updateQuestionAnswer(
      questionCategory.Code,
      text,
      referenceId,
      QuestionType.Text,
    )
  }

  validateRentalAmount(text) {
    if (isNaN(Number(text))) {
      this.setState({ errorMessage: 'Input amount must be digits' })
      return false
    }
    return true
  }

  render() {
    const {
      questionCategory = {},
      disabledRating,
      title = '',
      update,
      value = '',
    } = this.props
    const { Code, Description } = questionCategory || {}
    const { errorMessage } = this.state
    return (
      <div
        className="rating-categories-box"
        key={questionCategory && Code + Description}
      >
        <div className="rating-desc"> {Description ? Description : title} </div>
        <div
          className="col-sm-12 p0"
          key={questionCategory && questionCategory.Code}
        >
          <div className="input-box">
            <Form.CommentArea
              value={value ? value : this.state.text}
              rows={5}
              eventProcessedByComponent={true}
              onBlurCustomized={true}
              onChange={update ? update : this.changeText}
              onBlur={disabledRating ? () => {} : () => this.updateTextRating()}
              error={errorMessage}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default TextAnswerComponent
