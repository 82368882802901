import { connect } from 'react-redux'

import {
  addToSavedProperty,
  fetchRecentlyViewedProperties,
} from 'app/sm/recently_viewed/recently_viewed_actions'
import PropertiesContainer from 'app/sm/recently_viewed/recently_viewed_container'

const mapStateToProps = ({ PropertiesLists, session }) => ({
  properties: PropertiesLists.properties,
  currentUser: session.currentUser,
  spinner: PropertiesLists.spinner,
})

const mapDispatchToProps = (dispatch) => ({
  fetchProperties: () => dispatch(fetchRecentlyViewedProperties()),
  addToSavedProperty: (id) => dispatch(addToSavedProperty(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesContainer)
