import React, { Component } from 'react'

const ResponseStatusWrapper = () => (WrappedComponent) => {
  return class ResponseStatus extends Component {
    componentDidMount() {}
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

export default ResponseStatusWrapper
