import * as React from 'react'

function SvgUnfoldMoreRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.46 8.29L12 5.83 9.53 8.29a.996.996 0 11-1.41-1.41l3.17-3.18a.996.996 0 011.41 0l3.17 3.18a.996.996 0 11-1.41 1.41zm-4.92 7.42L12 18.17l2.47-2.45a.996.996 0 011.41 0c.39.39.39 1.02 0 1.41l-3.17 3.17a.996.996 0 01-1.41 0l-3.17-3.18a.996.996 0 111.41-1.41z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgUnfoldMoreRounded
