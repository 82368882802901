import { connect } from 'react-redux'

import { acceptApplication } from 'app/sm/apply/apply_actions'
import {
  fetchOfferedApplication,
  ping,
} from 'app/sm/oa_tenant/oa_tenant_actions'
import OaTenantContainer from 'app/sm/oa_tenant/oa_tenant_container'
import { acceptWithdraw } from 'app/sm/withdraw_confirmation/withdraw_confirmation_actions'

const mapStateToProps = ({ oaTenant, WithdrawConfirmation }) => ({
  property: oaTenant.property,
  offeredApplication: oaTenant.offeredApplication,
  error: oaTenant.error,
  withdrawButtonClicked: WithdrawConfirmation.withdrawButtonClicked,
})

const mapDispatchToProps = (dispatch) => ({
  ping: () => dispatch(ping()),
  acceptWithdraw: (id) => dispatch(acceptWithdraw(id)),
  fetchOfferedApplication: (id) => dispatch(fetchOfferedApplication(id)),
  acceptApplication: (offerId, applicationId, propertyId) =>
    dispatch(acceptApplication(offerId, applicationId, propertyId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OaTenantContainer)
