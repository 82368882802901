import * as React from 'react'

function SvgArrowUpwardRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 18.791V7.621l4.88 4.88c.39.39 1.03.39 1.42 0a.996.996 0 000-1.41l-6.59-6.59a.996.996 0 00-1.41 0l-6.6 6.58a.996.996 0 101.41 1.41L11 7.621v11.17c0 .55.45 1 1 1s1-.45 1-1z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrowUpwardRounded
