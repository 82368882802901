import { connect } from 'react-redux'

import { changeTeam } from 'app/session/session_actions'
import { fetchAdvertisement } from 'app/sm/advertisements/advertisement_actions'
import PropertyViewings from 'app/sm/ppp/property_components/viewings/viewings'
import {
  addViewing,
  cancelViewing,
  editViewing,
  fetchViewings,
  ping,
  updateField,
  updateViewing,
} from 'app/sm/ppp/viewing_actions'
import { fetchProfile, steps, update } from 'app/sm/profile/profile_actions'

const mapStateToProps = ({ Viewing, Advertisements, session }) => ({
  error: Viewing.error,
  viewings: Viewing.viewings,
  spinner: Viewing.spinner,
  property: Advertisements.advertisement.property,
  steps,
  updatedViewing: Viewing.updatedViewing,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  ping: () => dispatch(ping()),
  addViewing: (id, startdate, duration, fromScheduler, options) =>
    dispatch(addViewing(id, startdate, duration, fromScheduler, options)),
  cancelViewing: (offerId, viewingId) =>
    dispatch(cancelViewing(offerId, viewingId)),
  editViewing: (id, viewing) => dispatch(editViewing(id, viewing)),
  updateViewing: (id, viewingId, startdate, duration, options) =>
    dispatch(updateViewing(id, viewingId, startdate, duration, options)),
  fetchViewings: (id) => dispatch(fetchViewings(id)),
  update: (step, data) => dispatch(update(step, data)),
  updateField: (key, val) => dispatch(updateField(key, val)),
  fetchProfile: (offerId) => dispatch(fetchProfile(offerId)),
  fetchOffer: (offerId) => fetchAdvertisement(offerId),
  changeTeam: (currentTeam) => dispatch(changeTeam(currentTeam)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PropertyViewings)
