import React from 'react'

import styled from 'styled-components'

const StyledRightAngledArrowIcon = styled.i`
  color: ${({ theme }) => theme.colors.blue};
  margin-left: 5px;
  font-size: 18px;
`

const RightAngledArrowIcon = () => {
  return <StyledRightAngledArrowIcon className="fa fa-angle-right" />
}

export default RightAngledArrowIcon
