import React from 'react'

import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import AgencyFilterSection from 'app/components/agency_search_section/connection'
import {
  Box,
  Button,
  Flex,
  HorizontalLine,
} from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import InviteToApplyModal from 'app/components/design-system-components/InviteToApply/InviteToApplyModal'
import * as Display from 'app/components/display/display'
import PropertySummary from 'app/components/property/PropertySummary'
import ArchiveConfirmationModal from 'app/dashboard/components/modal_before_archive'
import {
  TeamOverviewGridActionsContainer,
  TeamOverviewGridContainer,
  TeamOverviewGridPropertyContainer,
} from 'app/dashboard/StyledTeamOverview'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import { setURLQuery } from 'app/helpers/query_string'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import Spinner from 'app/sm/common/spinner'
import {
  addOnScrollLoadListeners,
  filtersHaveChanged,
  isPropertyDraft,
  isPropertyLeased,
  isPropertyTakingApplications,
  isScrollAtEnd,
  NO_IMAGE_PLACEHOLDER_URL,
  PropertyStatus,
  propertyStatusCode,
  PropertyStatuses,
  removeOnScrollLoadListeners,
  urlTo,
} from 'app/sm/helpers'
import { ApplicationWorkflowStatusLabel } from 'app/sm/properties/components/property_search_filter_util'
import { isAccountLite } from 'app/utils/accounts/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import { isNewWorkflowEnabled } from 'config/features'

const GreenDot = styled.span`
  width: 7px;
  height: 7px;
  background: #37c977;
  right: -8px;
  top: 0;
  border-radius: 4px;
`
const DaysOnMarketWrapper = styled.div`
  font-size: ${theme.fontSizes[3]}px;
  line-height: ${theme.dividedSpace[3]}px;
  margin-top: ${theme.dividedSpace[2]}px;
  color: ${theme.colors.pdfGray};
`

const DaysOnMarketHeader = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #686d71;
`

export const StyledPropertyImage = styled(Box)`
  border-radius: ${theme.radii[2]}px;
  width: ${theme.width[18]}px;
  height: ${theme.height[8]}px;
  background-size: cover;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25));
`

export const StyledPropertyHeader = styled(Box)`
  font-size: ${theme.fontSizes.pExtraLarge18};
  font-weight: ${theme.fontWeights.h2};
  margin-bottom: ${theme.space[3]}px;
  line-height: 1.35;
`

const SortIconContainer = styled(Flex)`
  cursor: pointer;
  justify-content: flex-end;
`

const SortIconWrapper = styled(Box)`
  padding: ${theme.radii[1]}px ${theme.radii[2]}px;
  border-radius: 100%;
  background-color: ${({ isSorted }) =>
    isSorted ? theme.colors.gray200 : 'none'};
`

const PointerHeader = styled(Flex)`
  cursor: pointer;
  min-width: ${theme.width[8]}px;
`

const PointerContainer = styled(Flex)`
  cursor: pointer !important;
`

const BEFORE_ARCHIVE_MODAL = 'modalBeforeArchive'

const PropertyStatistic = ({
  label = '',
  count = 0,
  textClass = '',
  onClickCount = () => {},
  disableOnClick = true,
  showGreenDot = false,
  showDOM = false,
  daysOnMonth = 0,
  BadgeCmp,
  onlistStatus = textHelpers.STATUS_ONLIST_UNKNOWN,
  showOnlist = false,
  onlistFn = () => {},
  viewStatLinkText = '',
}) => {
  const displayStat = {
    statClass: `${textClass} ${count === 0 ? 'light-grey-text' : ''}`,
    linkClass: `${
      disableOnClick || count === 0 ? 'disabled-link' : 'pointer-style'
    }`,
    statText: `${count === 0 ? '-' : count}`,
  }
  const showViewStatLinkText = count !== 0
  return (
    <div
      className={`display-flex justify-content-flex-start align-items-center mobile-font-size-16`}
    >
      <span className="text-align-center">
        <span className="mobile-show">
          {label} <br />
        </span>
        <span className={`${displayStat.statClass}`} onClick={onClickCount}>
          <Flex
            alignItems="center"
            className={`${displayStat.linkClass}`}
            onClick={onClickCount}
            justifyContent="center"
            style={{ fontSize: theme.fontSizes.pExtraLarge18 }}
          >
            <strong>{displayStat.statText}</strong>
            {BadgeCmp && (
              <Box ml={theme.space[2] + 'px'}>
                <BadgeCmp />
              </Box>
            )}
          </Flex>
          {viewStatLinkText && showViewStatLinkText && (
            <Button
              variant="linkBlueWithoutWeight"
              mt={1}
              style={{ fontSize: theme.fontSizes.pSmall12 }}
              onClick={onClickCount}
            >
              {viewStatLinkText}
            </Button>
          )}

          {showDOM && (
            <DaysOnMarketWrapper>{`${daysOnMonth} DoM`}</DaysOnMarketWrapper>
          )}
          {showOnlist && onlistStatus === textHelpers.STATUS_ONLIST_INCOMPLETE && (
            <Box mt={theme.space[1] + 'px'}>
              <Button variant="linkBlueWithoutWeight" onClick={onlistFn}>
                Onlist
              </Button>
            </Box>
          )}
        </span>

        {showGreenDot && <GreenDot></GreenDot>}
      </span>
    </div>
  )
}

const PropertyRecentNewApplicationsBadge = ({
  guidID,
  recentNewApplications,
}) => {
  const tooltipId = `${guidID}_new-badge`
  return (
    <>
      <Badge
        text="New"
        data-tooltip-content=""
        data-tooltip-id={tooltipId}
        variant="success"
      />
      <ReactTooltip
        id={tooltipId}
        data-type="info"
        place="top"
        className="tool-tip-style"
      >
        <Box maxWidth="222px" textAlign="center">
          {recentNewApplications.count} new application(s)
        </Box>
      </ReactTooltip>
    </>
  )
}

const PropertyItem = (propertyInfo) => {
  const {
    propertyInfo: {
      activeOffer,
      viewedCount,
      viewedActivity,
      messageCount,
      messageActivity,
      applicationsCount,
      applicationsActivity,
      days,
      mostProgressedApplicationStatus,
      sentUtilityLeads = [],
      recentNewApplications,
      property: {
        slug,
        archived,
        status,
        guidID,
        images,
        address: { friendlyName = '', suburb = '' },
        daysOnTheMarket,
      },
      property,
    },
    utilityProviderConfigured,
    toggleInviteToApplyModal,
    inviteToApplyModalOpen,
    inviteToApplyModalOpenPropertyGUID,
  } = propertyInfo

  const { onlistStatus = textHelpers.STATUS_ONLIST_UNKNOWN } = activeOffer || {}
  const {
    onArchiveProperty,
    teamSlug,
    onUnarchiveProperty,
    toggleModal,
    activeModal,
    markAsArchivedOptions,
    onChangeMarkAsArchivedOptions,
    teamGUID,
  } = propertyInfo
  const applicationStatus = applicationsCount
    ? mostProgressedApplicationStatus
    : -1
  let daysTextClassName = ''
  if (days < 0) {
    daysTextClassName = 'color-red'
  } else if (days < 10) {
    daysTextClassName = 'color-yellow'
  }

  const propertyStats = [
    {
      label: 'Days:',
      count: days < -60 ? 0 : days,
      textClass: daysTextClassName,
      onClickCount: () => {},
      disableOnClick: true,
      showGreenDot: false,
      daysOnMonth: daysOnTheMarket,
      showDOM: daysOnTheMarket > 0,
      showOnlist: true,
      viewStatLinkText: '',
    },
    {
      label: 'Messages:',
      count: messageCount,
      textClass: '',
      onClickCount: () =>
        history.push(
          `${urlTo('newMessagesPage', {
            teamSlug,
          })}?property=${guidID}`,
        ),
      disableOnClick: false,
      showGreenDot: messageActivity > 0,
      daysOnMonth: 0,
      showDOM: false,
      showOnlist: false,
      viewStatLinkText: 'View',
    },
    {
      label: 'Viewings:',
      count: viewedCount,
      textClass: '',
      onClickCount: () =>
        history.push(
          `${urlTo('teamsViewing', {
            teamSlug,
          })}&property=${guidID}`,
        ),
      disableOnClick: false,
      showGreenDot: viewedActivity > 0,
      daysOnMonth: 0,
      showDOM: false,
      showOnlist: false,
      viewStatLinkText: 'View',
    },
    {
      label: 'Applied:',
      count: applicationsCount,
      textClass: '',
      onClickCount: () =>
        history.push(
          `${urlTo('prospectSummary', {
            teamSlug,
          })}?property=${guidID}`,
        ),
      disableOnClick: false,
      showGreenDot: applicationsActivity > 0,
      daysOnMonth: 0,
      showDOM: false,
      showOnlist: false,
      viewStatLinkText: 'View',
      BadgeCmp:
        !!recentNewApplications &&
        !!recentNewApplications.count &&
        (() => (
          <PropertyRecentNewApplicationsBadge
            recentNewApplications={recentNewApplications}
            guidID={guidID}
          />
        )),
    },
  ]
  const mainImage = images.find((image) => image.isMain === true)
  const propertyImage = mainImage ? mainImage.URL : NO_IMAGE_PLACEHOLDER_URL

  const onlistFn = () =>
    history.push(
      `${urlTo('onlistDetailsForProperty', {
        teamSlug,
        offerGUID: activeOffer.guidID,
        propertyGUID: guidID,
      })}`,
    )
  const propertyTools = [
    {
      text: 'Edit',
      enableTool: !archived,
      onClick: () => {
        window.open(urlTo('updatePropertyBasics', { id: guidID }))
      },
    },
    {
      text: 'Preview',
      enableTool: isPropertyTakingApplications(status) && !archived,
      onClick: () => {
        window.open(urlTo('applyRedirect', { slug }), '_blank')
      },
    },
    {
      text: 'Unarchive',
      enableTool: archived,
      onClick: () => onUnarchiveProperty(guidID, PropertyStatus[status]),
    },
    {
      text: 'Archive',
      enableTool: !archived,
      onClick: () =>
        isPropertyLeased(status)
          ? onArchiveProperty(guidID, PropertyStatus[status])
          : toggleModal(BEFORE_ARCHIVE_MODAL, guidID),
    },
    {
      text: 'Edit Onlist Details',
      enableTool: true,
      onClick: () => {
        if (activeOffer?.guidID) {
          onlistFn()
        } else {
          history.push(
            `${urlTo('onlistDetails', {
              teamSlug,
            })}?property=${guidID}`,
          )
        }
      },
    },
    {
      text: 'Invite to Apply',
      enableTool: true,
      onClick: () => toggleInviteToApplyModal(guidID),
    },
  ]
  const propertyText = `${friendlyName}, ${suburb}`

  return (
    <div key={guidID}>
      <TeamOverviewGridContainer>
        <TeamOverviewGridPropertyContainer>
          <Link
            className="display-flex overview-property-info width100-mobile"
            to={`${urlTo('prospectSummary', {
              teamSlug,
            })}?property=${guidID}&stage=Application`}
          >
            <div className="display-flex display-flex flex-direction-column width100-mobile">
              <StyledPropertyHeader>{propertyText}</StyledPropertyHeader>
              <div className="display-flex">
                <StyledPropertyImage
                  style={{
                    backgroundImage: `url("${propertyImage}")`,
                  }}
                >
                  {isPropertyLeased(status) && (
                    <div className="status-leased-tag">
                      <span className="tag-text">Leased</span>
                    </div>
                  )}
                  {isPropertyDraft(status) && (
                    <div className="status-draft-tag">
                      <span className="tag-text">Draft</span>
                    </div>
                  )}
                  {status === PropertyStatuses.offMarket && (
                    <div className="status-draft-tag">
                      <span className="tag-text">Off Market</span>
                    </div>
                  )}
                </StyledPropertyImage>
                <Box pl={4}>
                  <PropertySummary
                    sentUtilityLeads={sentUtilityLeads}
                    utilityProviderConfigured={utilityProviderConfigured}
                    property={property}
                    activeOffer={activeOffer}
                  />
                </Box>
              </div>
            </div>
          </Link>
        </TeamOverviewGridPropertyContainer>

        {propertyStats.map((propertyStat, index) => {
          const {
            label,
            count,
            textClass,
            onClickCount,
            disableOnClick,
            showGreenDot,
            showDOM,
            showOnlist,
            daysOnMonth,
            BadgeCmp,
            viewStatLinkText,
          } = propertyStat
          return (
            <PropertyStatistic
              key={index}
              label={label}
              count={count}
              textClass={textClass}
              onClickCount={onClickCount}
              disableOnClick={disableOnClick}
              showGreenDot={showGreenDot}
              showDOM={showDOM}
              daysOnMonth={daysOnMonth}
              BadgeCmp={BadgeCmp}
              onlistStatus={onlistStatus}
              showOnlist={showOnlist}
              onlistFn={onlistFn}
              viewStatLinkText={viewStatLinkText}
            />
          )
        })}

        <TeamOverviewGridActionsContainer>
          {isNewWorkflowEnabled(teamSlug) && (
            <div className="team-width-45p display-flex mobile-mt10 justify-content-flex-start">
              <div
                className={`display-flex justify-content-center align-items-center font-size-22 mobile-font-size-16`}
              >
                <ApplicationWorkflowStatusLabel
                  applicationStatus={applicationStatus}
                />
              </div>
            </div>
          )}

          <div className="flex-2 display-flex justify-content-center align-items-center team-over-button">
            <Link
              className="btn btn-success btn-offer p0 font-size-14 height40"
              to={`${urlTo('prospectSummary', {
                teamSlug,
              })}?property=${guidID}&stage=Application`}
            >
              <span className="color-white mt-10">View</span>
            </Link>
          </div>
          <div className="flex-1 display-flex justify-content-center align-items-center team-over-button">
            <Display.ToolsButton tools={propertyTools} />
          </div>
        </TeamOverviewGridActionsContainer>
      </TeamOverviewGridContainer>

      <HorizontalLine />

      {activeModal === BEFORE_ARCHIVE_MODAL && (
        <ArchiveConfirmationModal
          closeModal={() => toggleModal('', '')}
          clickArchive={() => onArchiveProperty(guidID, PropertyStatus[status])}
          markAsArchivedOptions={markAsArchivedOptions}
          onChangeMarkAsArchivedOptions={onChangeMarkAsArchivedOptions}
        />
      )}
      {inviteToApplyModalOpen &&
        inviteToApplyModalOpenPropertyGUID === guidID && (
          <InviteToApplyModal
            toggleModal={() => toggleInviteToApplyModal()}
            teamGUID={teamGUID}
            propertyDisplayText={friendlyName}
            propertyGUID={guidID}
          />
        )}
    </div>
  )
}

const OverviewDashboardTitle = ({
  propertyCount = 0,
  teamSlug = '',
  sortByMarketDate,
  mainDateHeader,
  currentMarketDateSorting,
}) => {
  const subDateHeader = mainDateHeader === 'Vacancy' ? 'DoM' : 'Vacancy'
  const tooltipId = `${teamSlug}_sort-market-date`
  return (
    <div className="mobile-hide">
      <TeamOverviewGridContainer className="mb10">
        <TeamOverviewGridPropertyContainer>
          <div className="sort-option pr5">
            ({propertyCount}) Apply Link{' '}
            <a
              href={`/apply/${teamSlug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-external-link text-black pl5" />
            </a>
          </div>
        </TeamOverviewGridPropertyContainer>
        <div className="sort-option">
          <PointerContainer>
            <PointerHeader
              textAlign="center"
              cursor="pointer"
              onClick={sortByMarketDate}
            >
              <Box>
                {mainDateHeader}
                <DaysOnMarketHeader>{subDateHeader}</DaysOnMarketHeader>
              </Box>
            </PointerHeader>
            <SortIconContainer>
              <Box>
                <SortIconWrapper
                  onClick={sortByMarketDate}
                  data-tooltip-content=""
                  data-tooltip-id={tooltipId}
                  isSorted={currentMarketDateSorting !== 'Default'}
                >
                  <img
                    src={
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAIBAMAAAA/ygPCAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAGFBMVEUAAACWmZyWnp6VmZyVmJ2ZmZ+hoaEAAABBL5wZAAAAB3RSTlMAVSLb5ygTNj0YsQAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAtSURBVAjXYxBiAAJFBpMABgZWZ4YQVwYQZnUJACIQEyQA5II4YBkQYEtgYAAAtcMGcm4776MAAAAASUVORK5CYII='
                    }
                    alt="Icon"
                  />
                </SortIconWrapper>
              </Box>
            </SortIconContainer>
          </PointerContainer>
        </div>
        <div className="sort-option">Messages</div>
        <div className="sort-option">Attendees</div>
        <div className="sort-option">Applications</div>
        <div className="sort-option"></div>
      </TeamOverviewGridContainer>
      <div className="border-bottom-1px" />
    </div>
  )
}

const OverviewBreadcomb = ({ currentTeam }) => (
  <div className="rental-notification display-flex height35px-mobile align-items-center">
    <span className="btn btn-transparent btn-left rental-header-mobile-layout width-auto align-items-center height15 ">
      <span className="font-size-14 pb0-mobile">
        {currentTeam ? currentTeam.name : ''}
        <i className="icon-arrow-right font-size-12" />
      </span>
    </span>
    <span className="btn btn-transparent btn-left rental-header-mobile-layout width-auto align-items-center height15">
      <span className="font-size-14 pb0-mobile">Overview</span>
    </span>
  </div>
)

export const SearchInputBox = ({ searchText, onSearchChange }) => (
  <div className="property-search-filter-widget pl0">
    <div className="input-box">
      <div className="prefix">
        <i className="icon-search"></i>
      </div>
      <input
        type="text"
        placeholder={'property address'}
        value={searchText}
        onChange={onSearchChange}
      />
    </div>
  </div>
)

export const LoadMore = ({ cursor, loadMore }) => (
  <button
    className="textButton wa ha btnmr mt10 mb10"
    onClick={() => loadMore(cursor)}
  >
    Load more
  </button>
)

export const PropertySearchContainer = ({ children, componentClass = '' }) => (
  <div className={`properties-search-filter ${componentClass}`}>{children}</div>
)

const NoPropertyContainer = () => (
  <div className="display-flex property-item-container pb10 pt10 border-bottom-1px height80 justify-content-center align-items-center">
    There are no properties.
  </div>
)

const ErrorContainer = ({ error }) => (
  <div className="alert alert-danger">{error}</div>
)

class ManagerDashboardContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      spinner: false,
      manager: 'Any',
      searchText: '',
      workflow: -1,
      error: '',
      tableSpinner: false,
      showingToast: false,
      showAllTab: false,
      currentPropertyCount: 0,
      cursor: '',
      activeModal: '',
      targetArchivePropertyId: '',
      markAsArchivedOptions: {
        cancelFutureViewings: true,
        declineUnsuccessfulApplications: false,
      },
      currentMarketDateSorting: 'Default',
      mainDateHeader: 'Vacancy',
      inviteToApplyModalOpen: false,
      inviteToApplyModalOpenPropertyGUID: '',
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.onChangeMarkAsArchivedOptions =
      this.onChangeMarkAsArchivedOptions.bind(this)
  }

  componentDidMount() {
    const { currentTeam, filters } = this.props

    this.props.setBackUrl(window.location.pathname)
    this.setupScrolling()

    this.setURLQueryAndFetchProperties(currentTeam, filters)
  }

  componentDidUpdate = (prevProps) => {
    const { currentTeam, filters } = this.props
    if (
      filtersHaveChanged(prevProps, this.props) ||
      prevProps.currentTeam !== currentTeam
    ) {
      this.setURLQueryAndFetchProperties(currentTeam, filters)
    }
  }

  componentWillUnmount() {
    const element = document.querySelector('.app-content')
    element && removeOnScrollLoadListeners(this.loadOnScroll, element)
  }

  onArchiveProperty = (propertyID, status) => {
    const { archiveProperty } = this.props
    const { propertyData, markAsArchivedOptions } = this.state
    const { cancelFutureViewings, declineUnsuccessfulApplications } =
      markAsArchivedOptions
    let targetArchivePropertyId =
      this.state.targetArchivePropertyId !== ''
        ? this.state.targetArchivePropertyId
        : propertyID
    const propertyList = propertyData.data
    if (
      status !== 'TakingApplications' ||
      window.confirm(
        'The property is currently taking applications. Do you still want to archive it?',
      )
    ) {
      archiveProperty(
        targetArchivePropertyId,
        cancelFutureViewings,
        declineUnsuccessfulApplications,
      )
        .then(() => {
          propertyData.data = propertyList.filter(
            (propertyInfo) =>
              propertyInfo.property.guidID !== targetArchivePropertyId,
          )
          this.setState({
            propertyData,
            propertyCount: this.state.propertyCount - 1,
            activeModal: '',
            targetArchivePropertyId: '',
          })
          this.reactToastify(
            'success',
            'The property has been archived successfully',
          )
        })
        .catch((error) => {
          this.reactToastify('fail', `error: ${error}`)
        })
    }
  }

  onChangeMarkAsArchivedOptions = (checkboxID) => {
    return (event) => {
      const { checked } = event.target
      const { markAsArchivedOptions } = this.state
      this.setState({
        ...this.state,
        markAsArchivedOptions: {
          ...markAsArchivedOptions,
          [checkboxID]: checked,
        },
      })
    }
  }

  onUnarchiveProperty = (propertyID, status) => {
    const { unarchiveProperty } = this.props
    const { propertyData } = this.state
    const propertyList = propertyData.data
    if (
      status !== 'TakingApplications' ||
      window.confirm(
        'The property is currently taking applications. Do you want to unarchive it?',
      )
    ) {
      unarchiveProperty(propertyID)
        .then(() => {
          propertyData.data = propertyList.filter(
            (propertyInfo) => propertyInfo.property.guidID !== propertyID,
          )
          this.setState({
            propertyData,
            propertyCount: this.state.propertyCount - 1,
          })
          this.reactToastify(
            'success',
            'The property has been unarchived successfully',
          )
        })
        .catch((error) => {
          this.reactToastify('fail', `error: ${error}`)
        })
    }
  }

  toggleInviteToApplyModal = (propertyGUID) => {
    const { inviteToApplyModalOpen, inviteToApplyModalOpenPropertyGUID } =
      this.state
    if (!inviteToApplyModalOpen) {
      this.setState({
        inviteToApplyModalOpen: !inviteToApplyModalOpen,
        inviteToApplyModalOpenPropertyGUID: propertyGUID,
      })
    } else {
      this.setState({
        inviteToApplyModalOpen: !inviteToApplyModalOpen,
        inviteToApplyModalOpenPropertyGUID: '',
      })
    }
  }

  getCurrentTeam = () => {
    const teamSlug =
      (this.props.match &&
        this.props.match.params &&
        this.props.match.params.teamSlug) ||
      ''
    return this.props.teams.find((option) => option.slug === teamSlug)
  }

  setURLQueryAndFetchProperties = debounce((currentTeam, filters) => {
    if (currentTeam && filters) {
      const { pathname } = window.location
      const { guid } = currentTeam
      setURLQuery(history, pathname, filters)
      this.callFetchTeamProperties(guid, filters)
        .then(({ properties }) => {
          this.setState({
            propertyData: properties,
            propertyCount: properties.response_metadata.total,
            defaultPropertyData: properties,
          })
        })
        .catch((error) => {
          this.setState({ error })
        })
    }
  }, STANDARD_TIMEOUT) // debounce

  setupScrolling = () => {
    window.scrollTo(0, 0)
    const element = document.querySelector('.app-content')
    element && addOnScrollLoadListeners(this.loadOnScroll, element)
  }

  callFetchTeamProperties = (
    teamID,
    { searchText, manager, status, workflow, offset, loadMore = false, sortBy },
  ) => {
    const { fetchTeamProperties } = this.props
    loadMore
      ? this.setState({ loadMoreSpinner: true })
      : this.setState({ tableSpinner: true })
    return fetchTeamProperties(teamID, {
      searchText,
      managerContact: manager,
      status,
      workflow,
      offset,
      loadMore,
      sortBy,
    })
      .then(({ properties }) => {
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
        return Promise.resolve({ properties })
      })
      .catch((error) => {
        this.setState({ error })
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
        return Promise.reject(error)
      })
  }

  loadOnScroll = () => {
    const {
      propertyData = {},
      propertyCount = 0,
      propertyStatus = '',
    } = this.state
    const { data = [] } = propertyData
    if (data.length >= propertyCount) {
      return
    }
    const { currentTeam, filters } = this.props

    const offset = propertyData.data.length
    const loadMore = true

    const docHeight = document.body.clientHeight
    const winHeight = window.innerHeight
    const bottomElement = document.getElementById('content-end')
    const elementRect = bottomElement && bottomElement.getBoundingClientRect()
    const rectBottom = elementRect && elementRect.bottom

    if (
      isScrollAtEnd(rectBottom, docHeight, winHeight) &&
      !this.state.loadMoreSpinner
    ) {
      const status = propertyStatusCode[propertyStatus]
      this.callFetchTeamProperties(currentTeam.guid, {
        status,
        offset,
        loadMore,
        ...filters,
      })
        .then(({ properties }) => {
          const previousProperties = this.state.propertyData.data
          const updatedProperties = previousProperties.concat(properties.data)
          this.setState({
            propertyData: {
              data: updatedProperties,
              response_metadata: properties.response_metadata,
            },
          })
        })
        .catch((error) => {
          this.setState({ error })
        })
    }
  }

  reactToastify = (category, message) => {
    if (category === 'success') {
      this.state.showingToast === false &&
        snugNotifier.success(message || 'success', {
          onOpen: () => this.setState({ showingToast: true }),
          onClose: () => this.setState({ showingToast: false }),
        })
    } else if (category === 'fail') {
      this.state.showingToast === false &&
        snugNotifier.error(message || 'failed', {
          onOpen: () => this.setState({ showingToast: true }),
          onClose: () => this.setState({ showingToast: false }),
        })
    }
  }

  toggleModal = (modalCategory, targetArchivePropertyId) => {
    this.setState({
      activeModal: modalCategory,
      targetArchivePropertyId,
    })
  }

  toggleOffSpinner = () => {
    this.setState({ spinner: false })
  }

  toggleOnSpinner = () => {
    this.setState({ spinner: true })
  }

  sortByMarketDate = () => {
    const sortingOrder = ['Default', 'DoM', 'Vacancy']
    const { currentMarketDateSorting } = this.state
    this.setState(
      {
        mainDateHeader:
          currentMarketDateSorting === 'Default' ? 'DoM' : 'Vacancy',
        currentMarketDateSorting:
          currentMarketDateSorting === sortingOrder[2]
            ? sortingOrder[0]
            : sortingOrder[
                sortingOrder.findIndex(
                  (order) => currentMarketDateSorting === order,
                ) + 1
              ],
      },
      () => {
        const { filters, setPropertyFilters } = this.props
        const { currentMarketDateSorting } = this.state

        const newFilters = Object.assign({}, filters, {
          sortBy:
            currentMarketDateSorting === 'DoM'
              ? 'daysOnTheMarket'
              : currentMarketDateSorting === 'Default'
              ? ''
              : 'days',
        })
        setPropertyFilters(newFilters)
      },
    )
  }

  render() {
    const { currentTeam } = this.props
    const { utilityProviderConfigured = false } = currentTeam || {}
    const {
      propertyData = {},
      error,
      propertyCount,
      tableSpinner,
      loadMoreSpinner,
      activeModal,
      markAsArchivedOptions,
      mainDateHeader,
      currentMarketDateSorting,
      inviteToApplyModalOpen,
      inviteToApplyModalOpenPropertyGUID,
    } = this.state
    const propertyList = propertyData.data
    const offset = propertyList?.length
    const teamSlug =
      (this.props.match &&
        this.props.match.params &&
        this.props.match.params.teamSlug) ||
      ''

    const { guid = '' } = currentTeam || {}

    return (
      <div>
        <div className="col-first">
          <OverviewBreadcomb currentTeam={currentTeam} />
          <AgencyFilterSection
            disableSearchTodayOnly
            disableSortby
            disableDatePicker
          />
          <OverviewDashboardTitle
            propertyCount={propertyCount}
            teamSlug={teamSlug}
            sortByMarketDate={this.sortByMarketDate}
            mainDateHeader={mainDateHeader}
            currentMarketDateSorting={currentMarketDateSorting}
          />
          {tableSpinner ? (
            <Spinner />
          ) : (
            <div className="display-flex flex-direction-column">
              {propertyList && propertyList.length === 0 && (
                <NoPropertyContainer />
              )}
              {propertyList &&
                propertyList.length > 0 &&
                propertyList.map((propertyInfo, index) => {
                  return (
                    <PropertyItem
                      key={propertyInfo.property.guidID}
                      propertyInfo={propertyInfo}
                      onArchiveProperty={this.onArchiveProperty}
                      teamSlug={teamSlug}
                      onUnarchiveProperty={this.onUnarchiveProperty}
                      toggleModal={this.toggleModal}
                      activeModal={activeModal}
                      utilityProviderConfigured={utilityProviderConfigured}
                      index={index}
                      markAsArchivedOptions={markAsArchivedOptions}
                      onChangeMarkAsArchivedOptions={
                        this.onChangeMarkAsArchivedOptions
                      }
                      toggleInviteToApplyModal={this.toggleInviteToApplyModal}
                      teamGUID={guid}
                      inviteToApplyModalOpen={inviteToApplyModalOpen}
                      inviteToApplyModalOpenPropertyGUID={
                        inviteToApplyModalOpenPropertyGUID
                      }
                    />
                  )
                })}
            </div>
          )}
          {offset < propertyCount && (
            <div id="content-end" className={`text-center mt20 ml20`}>
              {loadMoreSpinner && <Spinner />}
            </div>
          )}
          {error && <ErrorContainer error={error} />}
        </div>
      </div>
    )
  }
}

export default ManagerDashboardContainer
