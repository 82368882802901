import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'

export const StyledReferencesInformationHeader = styled(Box)`
  line-height: 1.25;
`

export const StyledRentalReferenceTable = styled(Box)`
  @media (max-width: 576px) {
    table {
      display: flex;
      width: 100%;
    }
    tbody {
      width: 100%;
    }
    th {
      display: none;
    }
    tr {
      display: flex;
      flex-direction: column;
      border-bottom: ${(props) =>
        `${props.theme.borders[1]} ${props.theme.colors.gray300}`};
      margin-bottom: ${(props) => props.theme.space[5]}px;
      padding-bottom: ${(props) => props.theme.space[5]}px;
    }
    tbody td {
      padding: ${(props) => props.theme.space[3]}px;
    }
  }
`

export const StyledMetadataContainer = styled.div`
  @media screen and (min-width: 577px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .timestamp {
      margin-top: ${(props) => props.theme.space[5]}px;
    }
  }
  @media screen and (max-width: 576px) {
    div {
      white-space: break-spaces;
    }
    .timestamp {
      margin-left: ${(props) => props.theme.space[3]}px;
    }
    display: flex;
    align-items: center;
    flex-direction: row;
  }
`
