import BaseInput from 'app/components/forms/base_input/component'
import {
  firstElementOrEmptyString,
  withValidation,
} from 'app/components/forms/util'
import * as validations from 'app/shared_components/validations'

const validateABNFlattened = (value) =>
  firstElementOrEmptyString(validations.validateABN(value, 'Invalid ABN'))

const InputABN = withValidation(BaseInput, validateABNFlattened)

export default InputABN
