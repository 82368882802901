import React from 'react'

import $ from 'jquery'
import { Route } from 'react-router-dom'

import Breadcrumbs from 'app/sm/advertise_flow/components/breadcrumbs'
import Amenities from 'app/sm/ppp/property_components/property_form/amenities/amenities_container'
import Basics from 'app/sm/ppp/property_components/property_form/property_basics'
import Description from 'app/sm/ppp/property_components/property_form/property_description'

const U = Object.assign
const isArray = (a) => typeof a === 'object' && a.length !== undefined
const boolToLetter = (b) => (b ? 't' : 'f')
const typeNum = (val) =>
  (val === undefined && 1) ||
  (isArray(val) && val.length === 0 && 2) ||
  (isArray(val) && 3)

class PropertyForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      bedrooms: null,
      bathrooms: null,
      garages: null,
      carports: null,
      type: 1,
      landSize: null,
      floorspace: null,
      commonAmenities: {},
      moreAmenities: {},
      photos:
        'https://i3.au.reastatic.net/456x342/ffc41daa142bdae5668a66ef7bf84403e76a86cd7a9e4608aad2a055d8dc1cb4/image2.jpg;https://i2.au.reastatic.net/456x342/15bbb6353b6fb356303ba7d1ecb85cc7bb45bbc7d306bddfffbe3cca813b8c63/main.jpg',
      title: '',
      topQualities: ';;',
      idealFor: '',
      livingAreaDesc: '',
      bedroomsDesc: '',
      buildingDesc: '',
      streetDesc: '',
      summary: '50;50;50;50',
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        address2: '',
        suburb: '',
        state: '',
        postcode: '',
        country: 'Australia',
        lat: null,
        lng: null,
        friendlyName: '',
        googleId: '',
      },
      images: [],
    }
  }

  componentDidMount() {
    let { fetchPropertyAttributes, fetchProperty, resetProperty } = this.props
    let { propertyId } = this.props.match.params
    fetchPropertyAttributes()
    if (propertyId) {
      fetchProperty(propertyId)
      window.onbeforeunload = () => true
    } else {
      resetProperty()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { property } = nextProps
    this.setState(property)
  }

  componentWillUnmount() {
    window.onbeforeunload = null
    this.props.resetProperty()
  }

  _addCheck(checks, id) {
    let index = checks.indexOf(id)
    if (index == -1) {
      checks.push(id)
    }
    return checks
  }

  _removeChecks(checks, ids) {
    return checks.filter((id) => !ids.includes(id))
  }

  _toggleCheck(checks, id, parentId, subIds) {
    let index = checks.indexOf(id)
    if (index == -1) {
      checks.push(id)
      if (parentId) {
        checks = this._addCheck(checks, parentId)
      }
    } else {
      checks.splice(index, 1)
      if (subIds) {
        checks = this._removeChecks(checks, subIds)
      }
    }
    return checks
  }

  legacyUpdateCheckbox = (parentId, subIds) => {
    return (event) => {
      let { name, id } = event.currentTarget
      let checks = this.state[name].split(';')
      checks = this._toggleCheck(checks, id, parentId, subIds)
      checks = checks.filter((a) => a != '').join(';')
      this.setState({ [name]: checks })
    }
  }

  resetAddress = () => {
    return () => {
      this.setState({
        address: {
          unitNumber: '',
          streetNumber: '',
          streetName: '',
          address2: '',
          suburb: '',
          state: '',
          postcode: '',
          country: 'Australia',
          lat: null,
          lng: null,
          friendlyName: '',
          googleId: '',
        },
      })
    }
  }

  update = (field) => {
    return (event) =>
      this.setState({
        [field]: event.target.value,
      })
  }

  updateAddress = (field) => {
    return (event) => {
      let { address } = this.state
      address[field] = event.target.value
      this.setState({ address })
    }
  }

  updateCheckbox = (id, isCommonAmenitie, parentId, isSub = false) => {
    return (event) => {
      const states = new Map()
      const { commonAmenities, moreAmenities } = this.state
      const amenity = isCommonAmenitie ? commonAmenities : moreAmenities

      states.set('t1', (P) => U(P.previousState, { [P.parentId]: [P.id] }))

      states.set('t2', (P) => states.get('t1')(P))

      states.set('t3', (P) =>
        U(P.previousState, {
          [P.parentId]:
            P.previousState[P.parentId].find((k) => k.toString() === P.id) !==
            undefined
              ? P.previousState[P.parentId].filter((k) => k.toString() !== P.id)
              : P.previousState[P.parentId].concat(id.toString()),
        }),
      )

      states.set('f1', (P) => U(P.previousState, { [P.id]: [] }))

      states.set('f2', (P) => U(P.previousState, { [P.id]: undefined }))

      states.set('f3', (P) => states.get('f2')(P))

      const nextState = states.get(
        [
          boolToLetter(isSub),
          typeNum(
            isSub ? amenity[parentId.toString()] : amenity[id.toString()],
          ),
        ].join(''),
      )({
        previousState: amenity,
        parentId: parentId && parentId.toString(),
        id: id.toString(),
      })

      this.setState({
        [isCommonAmenitie ? 'commonAmenities' : 'moreAmenities']: nextState,
      })
    }
  }

  updateGoogleAddress = (address) => {
    this.setState({ address })
  }

  updateList = (name) => {
    return (event) => {
      let list = this.state[name].split(';')
      let { id, value } = event.target
      list[id] = value
      list = list.join(';')
      this.setState({ [name]: list })
    }
  }

  updateNumber = (field) => {
    return (event) =>
      this.setState({
        [field]: parseInt(event.target.value),
      })
  }

  updatePhotos = (files) => {
    const { id } = this.props.match.params
    this.props.uploadPhoto(id, files)
  }

  render() {
    let {
      component,
      steps,
      match,
      createProperty,
      updateProperty,
      attributes,
      deletePhoto,
      error,
      spinner,
      property,
    } = this.props
    const activeOffer = (property.offers || []).find((o) => o.isActive) || {}
    let ad = null

    if (activeOffer.ads && activeOffer.ads.length > 0) {
      ad = activeOffer.ads[0]
    }

    // if (error) {
    //   return (
    //     <div className='alert alert-danger disposable-alert'>
    //       <i onClick={() => this.props.ping()} className="fa fa-chevron-left mr15" aria-hidden="true" />{error}
    //     </div>
    //   )
    // }

    const bindBreadCrumb = (c) =>
      (c === 'property-basics' && 'basics') ||
      (c === 'ammenities' && 'ammenities') ||
      (c === 'description' && 'description')

    return (
      <div className="profile-screen">
        <Breadcrumbs activeBreadCrumb={bindBreadCrumb(component)} />
        <div className="ps-content">
          {component === 'ammenities' && (
            <Amenities
              state={this.state}
              params={match.params}
              attributes={attributes}
              error={error}
              updateCheckbox={this.updateCheckbox}
              updateProperty={updateProperty}
              ad={ad}
            />
          )}

          {component === 'description' && (
            <Description
              state={this.state}
              params={match.params}
              error={error}
              update={this.update}
              updateCheckbox={this.legacyUpdateCheckbox}
              updateList={this.updateList}
              updateProperty={updateProperty}
              ad={ad}
            />
          )}
        </div>
      </div>
    )
  }
}

export default PropertyForm
