import React from 'react'

import studentIcon from 'app/assets/icons/summary_icons/student-id.svg'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import SummaryStudentBox from 'app/match/apply/application_summary_before_submit/shared_components/student_box/component'
import { urlTo } from 'app/sm/helpers'

const StudentContainer = ({
  componentClassName = '',
  students = [],
  optInStudentDetails = false,
  isStudentEditable = true,
  onEditClick,
  enableNote = false,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  displayedApplicant,
  section,
  id = '',
  toggleRequestInfoModal = undefined,
  toggleDocumentsViewer = undefined,
}) => {
  return (
    <div id={id} className={`container-component ${componentClassName}`}>
      <ContainerHeader
        icon={studentIcon}
        text="Student Details"
        isEditable={isStudentEditable}
        onEditClick={() =>
          onEditClick(`${urlTo('studentDetails')}?from=rentersummary`)
        }
        enableNote={enableNote}
        onNewNoteUpdated={onNewNoteUpdated}
        newNotesContent={newNotesContent}
        onNoteIconClicked={onNoteIconClicked}
        onNewNoteAdded={onNewNoteAdded}
        onNoteCrossClicked={onNoteCrossClicked}
        interpreteNoteResult={interpreteNoteResult}
        enableNotesButton={enableNotesButton}
        displayedApplicant={displayedApplicant}
        section={section}
        toggleRequestInfoModal={toggleRequestInfoModal}
      />
      <div className="application-container-padding">
        {students.length > 0 &&
          optInStudentDetails &&
          students.map((student) => (
            <SummaryStudentBox
              student={student}
              key={student.guidID}
              toggleDocumentsViewer={toggleDocumentsViewer}
            />
          ))}
        <div className="application-container-padding">
          {!optInStudentDetails && <div className="mt5">Not provided</div>}
          {students.length === 0 && optInStudentDetails && (
            <div className="mt5">Does not have student details</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StudentContainer
