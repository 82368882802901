import React from 'react'

const AgencySearchItem = ({ result, i, category, onItemClick }) => {
  const suburb = result.address.suburb ? result.address.suburb : 'No address'
  return (
    <div
      key={i}
      className="search-box-list-item"
      onClick={() => {
        onItemClick(result)
      }}
    >
      {`${result.value} (${suburb})`}
      {result.info && <span>{'  ' + result.info}</span>}
    </div>
  )
}

export const SearchSampleItem = ({ result, i, category, onItemClick }) => (
  <div
    key={i}
    className="search-box-list-item"
    onClick={() => {
      onItemClick && onItemClick(result)
    }}
  >
    {result}
  </div>
)

export default AgencySearchItem
