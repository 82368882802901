import React from 'react'

import styled from 'styled-components'

import { Flex } from 'app/components/design-system-components'
import Status from 'app/sm/renter_background_check_report/components/status-sector/component'

import 'app/sm/renter_background_check_report/components/section-header/style.scss'

const Title = styled(Flex)`
  p {
    margin-left: ${({ theme }) => `${theme.dividedSpace[1]}px`};
    font-size: ${({ theme }) => `${theme.fontSizes[7]}px`};
  }
`

const SectionHeader = ({
  title = '',
  image = '',
  showStatus = false,
  statusClass = '',
  statusText = '',
  updateDate = '',
  subTitle = '',
}) => {
  return (
    <div className="header background-check-report-header">
      <div className="header-title">
        <img className="header-image" alt="" src={image} />
        {!subTitle ? (
          title
        ) : (
          <Title>
            {`${title}`}
            <p>{` (${subTitle})`}</p>
          </Title>
        )}
      </div>
      <div className="right-side-content min-200px display-flex justify-content-flex-end">
        {updateDate && <span className="date mr5">{updateDate}</span>}
        {showStatus && (
          <Status statusText={statusText} statusClass={statusClass} />
        )}
      </div>
    </div>
  )
}

export default SectionHeader
