import React from 'react'

import { ErrorBox, SearchSampleItem } from 'app/components/forms/util'

import 'app/components/forms/base_fuzzy_search/style.scss'

class FuzzySearchDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideResults: false,
      queryResults: [],
      pageSize: 5,
    }

    this.setQueryResults = this.setQueryResults.bind(this)
  }

  componentDidMount() {
    const { queryResults = [] } = this.props
    if (queryResults.length > 0) {
      this.setQueryResults(queryResults)
    }
  }

  onInputBlur = (event) => {
    this.props.handleInputBlur(event)
    setTimeout(() => {
      this.setState({ hideResults: true })
    }, 200)
  }

  onInputChange = (event) => {
    const { handleInputChange } = this.props
    const { value } = event.target

    this.setState({ hideResults: false })
    handleInputChange({ value })

    clearInterval(this.t)
    this.t = setTimeout(this.triggerSearchFunction(value), 500)
  }

  onInputFocus = () => {
    this.setState({ hideResults: false })
  }

  onItemClick = (value) => {
    this.props.handleInputChange({ value }, this.props.inputChangeCallback)
    this.setState({ hideResults: true })
  }

  setQueryResults = (queryResults) => {
    this.setState({ queryResults })
  }

  setResultState(queryResults = []) {
    this.setState({
      queryResults,
    })
  }

  triggerSearchFunction = (value) => {
    return () => {
      const { pageSize } = this.state
      const { searchFunction } = this.props
      if (value.trim() !== '' && searchFunction) {
        searchFunction(value, pageSize, this.setQueryResults)
      }
    }
  }

  render() {
    const {
      value,
      placeholder,
      extraClassName,
      error,
      componentClass,
      inputClass,
    } = this.props
    const { queryResults } = this.state
    const showSearchResults =
      queryResults &&
      queryResults.length !== 0 &&
      value &&
      !this.state.hideResults
    const listClassName = `search-box-list ${extraClassName}`
    return (
      <div className={`${componentClass}`}>
        <div className={`standard-form-components ${inputClass}`}>
          <div className="input-box  standard-input-box mb0">
            <input
              className="mb5 standard-inputbox"
              type="text"
              required
              value={value}
              onChange={this.onInputChange}
              onBlur={this.onInputBlur}
              onFocus={this.onInputFocus}
              id="fuzzySearchComponent"
            />
            <label className="input-label" htmlFor="fuzzySearchComponent">
              {placeholder}
            </label>
            {showSearchResults && (
              <div
                className={listClassName}
                display={showSearchResults ? '' : 'none'}
              >
                {queryResults.map((result, i) => {
                  return (
                    SearchSampleItem && (
                      <SearchSampleItem
                        key={i}
                        result={result}
                        i={i}
                        onItemClick={this.onItemClick}
                      />
                    )
                  )
                })}
              </div>
            )}
            <ErrorBox error={error} showError={!!error} />
          </div>
        </div>
      </div>
    )
  }
}

export default FuzzySearchDropdown
