import { fetchAgencyInfo } from 'app/bond_cover/agency/agency_actions'
import { getApplicationsCount } from 'app/shared_components/navbar/components/applications_count/access'
import { dispatchAPIResponse } from 'app/sm/helpers'
import {
  addArchiveProperty,
  getAgencyPublicProperties,
  getProperties,
  getPublicProperties,
  restoreArchiveProperty,
  withdrawAd,
} from 'app/sm/properties/properties_access'

export const RECEIVE_PROPERTIES = 'PROPS_RECEIVE_PROPERTIES'
export const RECEIVE_PUBLIC_PROPERTIES = 'PROPS_RECEIVE_PUBLIC_PROPERTIES'
export const RECEIVE_ARCHIVED_PROPERTIES = 'PROPS_RECEIVE_ARCHIVED_PROPERTIES'
export const WITHDRAW_MODAL_ON = 'PROPS_WITHDRAW_MODAL_ON'
export const WITHDRAW_MODAL_OFF = 'PROPS_WITHDRAW_MODAL_OFF'
export const RECEIVE_RESPONSE_TEXT = 'PROPS_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_APPLICATIONS_COUNT = 'RP_RECEIVE_APPLICATIONS_COUNT'
export const TOGGLE_SPINNER = 'RP_TOGGLE_SPINNER'
export const RECEIVE_FIRST_PAGE_CURSOR = 'RP_RECEIVE_FIRST_PAGE_CURSOR'
export const PERSIST_MANAGER_NAME = 'PERSIST_MANAGER_NAME'

// Action Creators
const receiveProperties = (properties, responseMetadata) => ({
  type: RECEIVE_PROPERTIES,
  properties,
  responseMetadata,
})
const receivePublicProperties = (properties, responseMetadata) => ({
  type: RECEIVE_PUBLIC_PROPERTIES,
  properties,
  responseMetadata,
})
const receiveArchivedProperties = (properties, responseMetadata) => ({
  type: RECEIVE_ARCHIVED_PROPERTIES,
  properties,
  responseMetadata,
})
export const withdrawModalOff = () => ({ type: WITHDRAW_MODAL_OFF })
export const withdrawModalOn = (propertyId) => ({
  type: WITHDRAW_MODAL_ON,
  propertyId,
})
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
const toggleSpinner = (spinner) => ({ type: TOGGLE_SPINNER, spinner })
const receiveFirstPageCursor = (firstPageCursor) => ({
  type: RECEIVE_FIRST_PAGE_CURSOR,
  firstPageCursor,
})

const receiveApplicationsCount = (applicationsCount) => ({
  type: RECEIVE_APPLICATIONS_COUNT,
  applicationsCount,
})

export const persistManager = (manager) => ({
  type: PERSIST_MANAGER_NAME,
  manager,
})
// Methods
export function fetchProperties(
  teamGUID,
  cursor,
  status,
  address,
  limit,
  managerID,
  hasApplicant = 'any',
) {
  return (dispatch) => {
    const archived = status === 5
    dispatch(toggleSpinner(true))

    getProperties(
      teamGUID,
      archived,
      cursor,
      status,
      address,
      limit,
      managerID,
      hasApplicant.toLowerCase(),
    ).then(({ properties, ok, responseText, statusCode }) => {
      if (ok) {
        dispatch(toggleSpinner(false))
        !cursor &&
          dispatch(receiveFirstPageCursor(properties.response_metadata))
        dispatch(
          archived
            ? receiveArchivedProperties(
                properties.data,
                properties.response_metadata,
              )
            : receiveProperties(properties.data, properties.response_metadata),
        )
      } else {
        dispatch(toggleSpinner(false))
      }
    })
  }
}

export function fetchArchivedProperties(teamGUID) {
  return fetchProperties(teamGUID, '', 5)
}

export function fetchPublicProperties(agencySlug) {
  return (dispatch) => {
    dispatch(toggleSpinner(true))
    getPublicProperties(agencySlug).then(
      ({ properties, ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(toggleSpinner(false))
          dispatch(
            receivePublicProperties(properties, properties.response_metadata),
          )
          properties.length !== 0 &&
            dispatch(fetchAgencyInfo(properties[0].property.agencyID))
          // properties.length !== 0 && dispatch(fetchAgencyPublicProfile(properties[0].property.agencyID));
        } else {
          dispatch(toggleSpinner(false))
          responseText.then((t) => {
            dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

export function fetchAgencyPublicProperties(
  agencySlug,
  address,
  sort,
  sort_dir,
  bedroom,
  cursor,
  limit,
  buildingSlug,
) {
  return (dispatch) => {
    return getAgencyPublicProperties(
      agencySlug,
      address,
      sort,
      sort_dir,
      bedroom,
      cursor,
      limit,
      buildingSlug,
    ).then(({ ok, responseText, properties }) => {
      if (ok) {
        dispatch(
          receivePublicProperties(properties, properties.response_metadata),
        )
        return Promise.resolve({ properties })
      } else {
        // dispatch(toggleSpinner(false));
        return responseText.then((error) => Promise.reject(error))
      }
    })
  }
}

export function withdrawAdvertisement(adId) {
  return (dispatch) => {
    // TODO: Handle api call

    withdrawAd(adId).then(({ ok, responseText, statusCode }) => {
      if (ok) {
        dispatch(fetchProperties())
        dispatch(withdrawModalOff())
      } else {
        // responseText.then(t => {
        //     store.dispatch(receiveResponseText(t))
        // })
      }
    })
  }
}

export function changeRole(role) {
  return (dispatch) => {
    dispatch(fetchProperties())
  }
}

export function archiveProperty(
  id,
  cancelFutureViewings = false,
  declineUnsuccessfulApplications = false,
) {
  return (dispatch) => {
    return addArchiveProperty(
      id,
      cancelFutureViewings,
      declineUnsuccessfulApplications,
    ).then(({ ok, responseText, statusCode }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
        return Promise.reject()
      }
    })
  }
}

export function unarchiveProperty(id) {
  return (dispatch) => {
    return restoreArchiveProperty(id).then(
      ({ ok, responseText, statusCode }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          dispatchAPIResponse(dispatch, responseText, receiveResponseText)
          return Promise.reject()
        }
      },
    )
  }
}

export function fetchApplicationsCount(success) {
  return (dispatch) => {
    getApplicationsCount().then(
      ({ ok, applicationsCount, responseText, statusCode }) => {
        if (ok) {
          dispatch(receiveApplicationsCount(applicationsCount))
          !!success && success()
        } else {
          dispatchAPIResponse(dispatch, responseText, receiveResponseText)
        }
      },
    )
  }
}
