import { connect } from 'react-redux'

import { fetchTeamProperties } from 'app/bond_cover/agency/agency_actions'
import { fetchProperty } from 'app/sm/ppp/property_actions'
import {
  addContact,
  fetchContact,
  updateContact,
} from 'app/sm/team_contact/action'
import TeamContactContainer from 'app/sm/team_contact/container'

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTeamProperties: (teamGUID, filters) =>
    dispatch(fetchTeamProperties(teamGUID, filters)),
  addContact: (teamId, formData) => dispatch(addContact(teamId, formData)),
  fetchContact: (teamId, contactGUID) =>
    dispatch(fetchContact(teamId, contactGUID)),
  updateContact: (teamId, contactGUID, formData) =>
    dispatch(updateContact(teamId, contactGUID, formData)),
  fetchProperty: (propertyId) => dispatch(fetchProperty(propertyId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamContactContainer)
