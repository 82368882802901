import BaseInput from 'app/components/forms/base_input/component'
import {
  firstElementOrEmptyString,
  withValidation,
} from 'app/components/forms/util'
import * as validations from 'app/shared_components/validations'

export const validateNameFlattened = (value) =>
  firstElementOrEmptyString(validations.validateName(value))

const InputName = withValidation(BaseInput, validateNameFlattened)

export default InputName
