import { connect } from 'react-redux'

import ProfileBuildSuccess from 'app/profile/profile_build_success/profile_build_success'
import { fetchCompleteness } from 'app/sm/rental_reputation/actions'

const mapStateToProps = null

const mapDispatchToProps = (dispatch) => ({
  fetchCompleteness: () => dispatch(fetchCompleteness()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBuildSuccess)
