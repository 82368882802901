import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { getTeamSettingInformation } from 'app/sm/rental_reputation/access'
import { api } from 'app/utils/api/helpers'

// just a wrapper around the legacy function to use same API to replace it later with axios based one
export const getAgencySettings = (agencyId, attrId) => {
  return getTeamSettingInformation(agencyId, attrId)
    .then(({ ok, ...rest }) => {
      return ok ? Promise.resolve(rest) : Promise.reject(rest)
    })
    .catch(({ statusCode }) => {
      return Promise.reject({
        message: translateErrorCodeToMessage(statusCode),
      })
    })
}

export const getAgencyMembers = (agencyGUID) => {
  return api.get(`/sm/team/${agencyGUID}/members`)
}

export const getAgencyInfo = (agencyGUID) => {
  return api.get(`/agencies/${agencyGUID}/info`)
}

export const getAgencyProfileAndDetails = (agencyGUID) => {
  return api.get(`/agency/${agencyGUID}/get-agency-details`)
}

export const searchAgencyContact = (teamId, queryString) => {
  return api
    .get(`sm/teams/${teamId}/search`, { params: { q: queryString } })
    .then(({ contacts }) =>
      (contacts || []).map((e) => ({
        ...e,
        fullName: `${e.firstName} ${e.lastName}`,
      })),
    )
}

export const getAgencyStatus = (agencyGUID) => {
  return api.get(`sm/teams/${agencyGUID}/preferences/status`)
}
