import React from 'react'

import { recaptchaSiteKey } from 'config/env'
import { isValidNumber } from 'libphonenumber-js'
import qs from 'qs'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link } from 'react-router-dom'

import { IntlTelInputWrapper } from 'app/components/design-system-components'
import Separator from 'app/components/design-system-components/separator'
import { AUTH_VERSION_1 } from 'app/session/join/join-snug.constants'
import RegisterFacebook from 'app/session/register_components/register_facebook'
import RegisterGoogle from 'app/session/register_components/register_google'
import getParameter from 'app/shared_components/parameter_parser'
import * as validations from 'app/shared_components/validations'
import { SNUG_PRIVACY_URL, SNUG_TERMS_URL, urlTo } from 'app/sm/helpers'
import { RegisterAgencyIDKey, SessionStorageUtils } from 'app/storage_utils'
import * as textHelpers from 'app/utils/text/helpers'

const validateMobile = (value, isOffshore, internationalAllowed) => {
  if (isOffshore || internationalAllowed) {
    if (!value) return []
    const isValid = isValidNumber(value)
    return isValid ? [] : ['Please enter a valid number']
  }
  return validations.validateMobile(value)
}

class RegisterEmail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      password: '',
      recaptcha: false,
      show: false,
      errors: {},
      gRecaptchaResponse: '',
      confirmedMobileNumber: '',
      validateErrorMessage: '',
      fromRentalReferencesCampaign: false,
      isOffshore: false,
      invitationID: null,
      internationalMobilesAllowed: false,
    }
  }

  UNSAFE_componentWillMount() {
    let { clearError } = this.props
    clearError()

    let firstname =
      getParameter('firstname') && this.sanitiseValue(getParameter('firstname'))
    let lastname =
      getParameter('lastname') && this.sanitiseValue(getParameter('lastname'))
    let email =
      getParameter('email') && this.sanitiseEmail(getParameter('email'))
    let mobile =
      getParameter('mobile') && this.sanitiseValue(getParameter('mobile'))
    this.setState({ firstname, lastname, email, mobile }, () => {
      firstname && this.validate('firstName')()
      lastname && this.validate('lastName')()
      email && this.validate('email')()
      mobile && this.validate('mobile')()
    })
  }

  componentDidMount() {
    const { offshore, invitation } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    this.setState({ isOffshore: !!offshore, invitationID: invitation })
    const { changeToRentalReferenceVersion } = this.props
    const forwardURL = SessionStorageUtils.getItem('forwardUrl')
    if (forwardURL) {
      const parsedForwardURL = qs.parse(forwardURL, { ignoreQueryPrefix: true })
      if (
        Object.keys(parsedForwardURL).length > 0 &&
        'invitefirstname' in parsedForwardURL
      ) {
        const isFromRentalReferencesCampaign =
          parsedForwardURL.utm_medium === 'leads/rental-references'
        this.setState({
          firstname: parsedForwardURL.invitefirstname,
          lastname: parsedForwardURL.invitelastname,
          email: parsedForwardURL.inviteemail,
          mobile: parsedForwardURL.invitemobile,
          fromRentalReferencesCampaign:
            parsedForwardURL.utm_medium === 'leads/rental-references',
        })
        if (isFromRentalReferencesCampaign) {
          changeToRentalReferenceVersion()
        }
      }
    }

    const registerForAgency = SessionStorageUtils.getItem(RegisterAgencyIDKey)
    if (registerForAgency) {
      const { getAgencyRules } = this.props
      getAgencyRules(registerForAgency).then(
        ({ internationalMobilesAllowed }) =>
          this.setState({ internationalMobilesAllowed }),
      )
    }

    const registerToken = this.parseToken()
    this.setState({ registerToken })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ clicked: false })
  }

  setValidationErrors(field, error) {
    let errors = this.state.errors
    if (error.length === 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  expiredRecaptcha = () => {
    this.setState({ recaptcha: false, gRecaptchaResponse: '' })
  }

  isEmptyObject(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false
      }
    }
    return true
  }

  sanitiseEmail(value) {
    return this.sanitiseValue(value).toLowerCase()
  }

  sanitiseValue(value) {
    return value.trim().replace(/\s+/g, '')
  }

  parseToken = () => {
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    return curQueries && curQueries.token
  }

  submit = () => {
    const { register } = this.props
    const {
      firstname,
      lastname,
      email,
      mobile,
      invitationID,
      gRecaptchaResponse,
      password,
      registerToken,
    } = this.state
    const isFromRR = window.location.pathname.endsWith(
      textHelpers.SLASH_RENTAL_REFERENCE_URL_STR,
    )
    register(
      {
        firstname,
        lastname,
        email,
        mobile,
        invitationID,
        gRecaptchaResponse,
        password,
        registrationToken: registerToken,
        authVersion: AUTH_VERSION_1,
      },
      isFromRR,
    )
    this.setState({ clicked: true })
  }

  toggleShow() {
    return () => {
      this.setState({ show: !this.state.show })
    }
  }

  update(field) {
    return (event) => {
      let { value } = event.target
      this.setState({ [field]: value.trim() })
    }
  }

  updateEmail(field) {
    return (event) => {
      let { value } = event.target
      value = value.toLowerCase()
      this.setState({ [field]: value.trim() })
    }
  }

  updatePassword(field) {
    return (event) => {
      this.setState({ [field]: event.target.value })
    }
  }

  updateRecaptcha = (response) => {
    this.setState({ recaptcha: true, gRecaptchaResponse: response })
  }

  validate = (field) => {
    return () => {
      let value = this.state[field]
      switch (field) {
        case 'firstname':
          return this.setValidationErrors(
            field,
            validations.validateName(value),
          )
        case 'lastname':
          return this.setValidationErrors(
            field,
            validations.validateName(value),
          )
        case 'email':
          return this.setValidationErrors(
            field,
            validations.validateEmail(value),
          )
        case 'confirmedMobile':
          return this.setValidationErrors(
            field,
            validations.validateConfirmedMobile(value, this.state.mobile),
          )
        case 'mobile': {
          const { isOffshore, internationalMobilesAllowed } = this.state
          return (
            this.setValidationErrors(
              field,
              validateMobile(value, isOffshore, internationalMobilesAllowed),
            ) &&
            this.state.mobile &&
            this.setValidationErrors(
              'confirmedMobile',
              validations.validateConfirmedMobile(
                this.state.confirmedMobile,
                value,
              ),
            )
          )
        }
        case 'password':
          return this.setValidationErrors(
            field,
            validations.validateNewPassword(value),
          )
        default:
          return false
      }
    }
  }

  validateConfirmedlNumber = (status, value, countryData, number, id) => {
    this.setState({ confirmedMobile: number })
  }

  validateInternationalNumber = (status, value, countryData, number, id) => {
    this.setState({ mobile: number.trim() })
  }

  render() {
    let {
      clicked,
      firstname,
      lastname,
      email,
      mobile,
      password,
      errors,
      confirmedMobile,
      fromRentalReferencesCampaign,
      isOffshore,
      internationalMobilesAllowed,
    } = this.state
    let { error } = this.props
    const unverifiedProfileRegistrationTagClass =
      'match-profile-unverified-registration'
    let joinSnugLabel = 'Join Snug'
    if (fromRentalReferencesCampaign) {
      joinSnugLabel = 'Submit'
    }

    const phoneCommonProps = {
      placeholder: 'e.g. +61 491 570 156',
      ...(isOffshore || internationalMobilesAllowed
        ? {}
        : { onlyCountries: ['au'] }),
    }

    return (
      <div className="panel panel-default pt20">
        <div className="panel-body">
          <div className="row">
            <div className="col-xs-6">
              <div className="input-box">
                <input
                  type="text"
                  required
                  className={errors.firstname ? 'error' : ''}
                  value={firstname}
                  onChange={this.update('firstname')}
                  onBlur={this.validate('firstname')}
                />
                <label>First Name</label>
              </div>
            </div>
            <div className="col-xs-6">
              <div className="input-box">
                <input
                  type="text"
                  required
                  className={errors.lastname ? 'error' : ''}
                  value={lastname}
                  onChange={this.update('lastname')}
                  onBlur={this.validate('lastname')}
                />
                <label>Last Name</label>
              </div>
            </div>
          </div>

          <div className="input-box">
            <input
              type="email"
              required
              className={errors.email ? 'error' : ''}
              value={email}
              onChange={this.updateEmail('email')}
              onBlur={this.validate('email')}
            />
            <label>Email Address</label>
          </div>

          <div
            className={`input-box register-form-mobile-input-box ${
              errors.mobile ? 'error' : ''
            }`}
          >
            <IntlTelInputWrapper
              css={[
                'intl-tel-input country-list',
                'register-form-mobile-input',
              ]}
              utilsScript={'libphonenumber.js'}
              defaultCountry={'au'}
              onPhoneNumberChange={this.validateInternationalNumber}
              onPhoneNumberBlur={this.validate('mobile')}
              value={mobile}
              {...phoneCommonProps}
            />
            <label>Mobile Number</label>
          </div>

          <div
            className={`input-box register-form-mobile-input-box ${
              errors.confirmedMobile ? 'error' : ''
            }`}
          >
            <IntlTelInputWrapper
              css={[
                'intl-tel-input country-list',
                'register-form-mobile-input',
              ]}
              utilsScript={'libphonenumber.js'}
              defaultCountry={'au'}
              onPhoneNumberChange={this.validateConfirmedlNumber}
              onPhoneNumberBlur={this.validate('confirmedMobile')}
              value={confirmedMobile}
              {...phoneCommonProps}
            />
            <label>Confirm Mobile Number</label>
          </div>

          <div className="input-box">
            <input
              type={this.state.show ? 'text' : 'password'}
              required
              className={errors.password ? 'error' : ''}
              value={password}
              onChange={this.update('password')}
              onBlur={this.validate('password')}
            />
            <label>Choose a password</label>
            <span className="show-password" onClick={this.toggleShow()}>
              Show
            </span>
          </div>

          <label className="mb10">Click below to confirm you're human.</label>
          <ReCAPTCHA
            ref="recaptcha"
            sitekey={recaptchaSiteKey}
            onChange={this.updateRecaptcha}
            onExpired={this.expiredRecaptcha}
          />

          <div
            className={
              !this.isEmptyObject(errors) || error ? 'alert alert-danger' : ''
            }
          >
            {error} {errors.firstname} {errors.lastname} {errors.email}{' '}
            {errors.mobile} {errors.password} {errors.confirmedMobile}
          </div>

          <button
            className={`mt15 mb30 ${unverifiedProfileRegistrationTagClass}`}
            onClick={this.submit}
          >
            {joinSnugLabel}{' '}
            <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
          </button>

          {!isOffshore && (
            <>
              <div className="text-right pb30">
                <Separator text="OR" />
              </div>

              <div>
                <RegisterFacebook form="Sign up" />
                <RegisterGoogle form="Sign up" />
              </div>
            </>
          )}

          <div className="text-center mb10 fs16">
            Already have a Snug account?{' '}
            <Link
              className="fw600 "
              to={{ pathname: urlTo('join'), search: window.location.search }}
            >
              Log in
            </Link>
          </div>

          <p className="pt15 fs12 mb0 lh14 gray-light-color">
            By joining Snug you agree to our{' '}
            <a target="_blank" rel="noopener noreferrer" href={SNUG_TERMS_URL}>
              Terms of Use
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={SNUG_PRIVACY_URL}
            >
              Privacy Policy
            </a>
            {textHelpers.TRADEMARK_FOOTER_CONTENT_LOGIN_COMPS_TEXT}
          </p>
        </div>
      </div>
    )
  }
}

export default RegisterEmail
