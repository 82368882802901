import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { validateEmail } from 'app/shared_components/validations'

export const formFields = {
  recipient: 'recipient',
  message: 'message',
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.recipient: {
      return {
        required: !val && 'Recipient should be added',
        pattern: validateEmail(val)[0] && 'This email is invalid',
      }
    }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)

export const transformApiErrorToFields = ({ email }) => {
  return {
    [formFields.recipient]: email,
  }
}
