import React, { useRef } from 'react'

import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import { useOutsideAlerter } from 'app/components/design-system-components/Modal'
import * as Display from 'app/components/display/display'
import {
  BallotsContent,
  StyledContent,
  StyledLabel,
} from 'app/shared_components/ballots-content'
import * as sharedHelpers from 'app/shared_components/helpers'
import * as helpers from 'app/sm/helpers'
import { urlTo } from 'app/sm/helpers'
import {
  formatAndPrefix,
  formatPhoneNumber,
  MOBILE_PREFIX,
} from 'app/utils/phonenumber/helpers'

const AllSearchResultsContainer = styled.div`
  border-top: none;
  position: absolute;
  width: max-content;
  background-color: white;
  z-index: 45;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
`

const DropdownItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  strong {
    display: block;
    padding-bottom: 5px;
  }
  .name-pill-info {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
  }
  .phone-email-info {
    margin-top: 4px;
  }
  ${(props) => props.dropdownItemClickEventEnabled && `cursor: pointer;`}
  @media (max-width: 768px) {
    .phone-email-info {
      display: flex;
      flex-direction: column;
    }
  }
`

const CountButtonContainer = styled.span`
  margin-left: auto !important;
`

const StyledEmptyAvatar = styled.div`
  width: 60px;
`

const StyledContextMenu = styled(Flex)`
  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-end;
  }
`

const STATUS_ACCEPTED = 'Accepted'
const STATUS_INVITED = 'Invited'

export const DropdownItem = ({
  item,
  showStatusPills = false,
  showDates = false,
  fullNameClassName = '',
  dropdownItemClickEventEnabled = false,
  teamSlug,
  showToolsButton = false,
  memberTools = [],
  onClickHandler,
  showAvatar = true,
}) => {
  const shownMemberTools = memberTools.map((tool) => {
    return { ...tool, onClick: () => onClickHandler && onClickHandler(item) }
  })
  const {
    firstName,
    lastName,
    avatar,
    mobilePhone = '',
    phone = '',
    homePhone = '',
    workPhone = '',
    email = '',
    pillText = '',
    mobileNumber = '',
    isAdmin = false,
    statusAccepted = false,
    statusDate = '',
    phoneNumber = '',
    officeNumber = '',
    agencyName = '',
    applicationsCount = 0,
  } = item
  const user = { firstName, lastName }
  const contactNumber =
    mobilePhone ||
    phone ||
    homePhone ||
    workPhone ||
    mobileNumber ||
    phoneNumber ||
    officeNumber

  const textForTeamContactNumber = formatAndPrefix(contactNumber, MOBILE_PREFIX)
  const teamContactNumber = formatPhoneNumber(contactNumber)

  const renderApplicationsCountLink = () => {
    if (applicationsCount <= 1) {
      return
    }

    const mouseDownEventHandler = (e) => {
      e.preventDefault()
      e.stopPropagation()

      window.open(
        urlTo('teamApplicantApplicationsReport', {
          teamSlug: teamSlug,
          applicantEmail: item.email,
        }),
        '_blank',
      )
    }

    return (
      <CountButtonContainer
        className="blue-link-style cursor-pointer plus-show"
        onMouseDown={(e) => mouseDownEventHandler(e)}
      >
        <span className="lh1">
          <span className="ibm lh1">+{applicationsCount - 1} Apps</span>
        </span>
      </CountButtonContainer>
    )
  }
  const hasNameDetails = firstName && lastName

  const applicationsCountLinkElement = renderApplicationsCountLink()

  const nameAndEmail = () => {
    return (
      <div className="phone-email-info">
        <a href={`tel:${teamContactNumber}`}>{textForTeamContactNumber}</a>
        <a
          className={textForTeamContactNumber.length > 0 ? 'ml10' : ''}
          href={`mailto:${email}`}
        >
          {email}
        </a>
        {agencyName && agencyName.length > 0 && (
          <div>
            <span>{agencyName}</span>
          </div>
        )}
      </div>
    )
  }

  return (
    <DropdownItemContainer
      dropdownItemClickEventEnabled={dropdownItemClickEventEnabled}
    >
      {showAvatar && (
        <Display.Avatar
          avatar={avatar}
          avatarText={helpers.firstAndLastNameInitials(user, 'both')}
          size={'msmall'}
        />
      )}
      <div className="width-100">
        <div className="name-pill-info width-100">
          {hasNameDetails ? (
            <div className="display-flex width-100">
              <strong className={`${fullNameClassName}`}>
                {`${firstName} ${lastName}`}
              </strong>
              {showStatusPills && pillText.length > 0 && (
                <Display.StatusPill
                  componentClass="ml10 mr10"
                  pillType="hollow"
                  text={pillText}
                  pillTextClass="fs12"
                />
              )}
              {isAdmin && (
                <span className="fs18 ml5 mr10 grey-text">(Admin)</span>
              )}

              {applicationsCountLinkElement}
            </div>
          ) : (
            <>{nameAndEmail()}</>
          )}
          <StyledContextMenu>
            <div className="fs14 fw500">
              {showDates && (
                <div className="display-flex align-items-center">
                  <Display.StatusPill
                    componentClass="ml10"
                    pillType={statusAccepted ? 'green' : 'yellow'}
                    text={statusAccepted ? STATUS_ACCEPTED : STATUS_INVITED}
                    pillTextClass="fs12"
                  />
                  <span className="ml5">{statusDate}</span>
                </div>
              )}
            </div>
            {showToolsButton && statusAccepted && (
              <Display.ToolsButton tools={shownMemberTools} />
            )}
          </StyledContextMenu>
        </div>
        {hasNameDetails && <>{nameAndEmail()}</>}
      </div>
    </DropdownItemContainer>
  )
}

function AvatarAndNameDropdown(props) {
  const wrapperRef = useRef(null)

  const {
    showDropdown = false,
    dropdownItems = [],
    noResultFoundText = 'No results Found',
    dropdownItemClickEventEnabled,
    dropdownItemClickEventHandler,
    closeAvatarAndNameDropdown,
    showStatusPills = false,
    showSpinner = false,
    showSpinnerFeatureEnabled = false,
    teamSlug,
    isApplicantReport = false,
    showBallot = false,
    ballotDetails = {},
    applicationSlug = '',
  } = props || {}

  useOutsideAlerter(wrapperRef, () => {
    closeAvatarAndNameDropdown && closeAvatarAndNameDropdown()
  })

  const { slug = '', createdAt = '' } = ballotDetails || {}

  const dropdownContent = (
    <div>
      {dropdownItems.length > 0 ? (
        dropdownItems.map((item, index) => {
          return (
            <div
              key={index}
              {...(dropdownItemClickEventEnabled && {
                onMouseDown: () => dropdownItemClickEventHandler(item),
              })}
            >
              <DropdownItem
                teamSlug={teamSlug}
                item={item}
                showStatusPills={showStatusPills}
                dropdownItemClickEventEnabled={dropdownItemClickEventEnabled}
                isApplicantReport={isApplicantReport}
              />
            </div>
          )
        })
      ) : (
        <DropdownItemContainer>{noResultFoundText}</DropdownItemContainer>
      )}
    </div>
  )
  return (
    <div ref={wrapperRef}>
      {showDropdown && (
        <>
          <AllSearchResultsContainer>
            {showBallot && (
              <DropdownItemContainer>
                <BallotsContent ballotID={slug} ballotEnteredAt={createdAt} />
              </DropdownItemContainer>
            )}
            {!showBallot && applicationSlug && (
              <DropdownItemContainer>
                <Flex mb={2}>
                  <StyledLabel>App ID:</StyledLabel>
                  <StyledContent ml={2}>{applicationSlug}</StyledContent>
                </Flex>
              </DropdownItemContainer>
            )}
            {showSpinnerFeatureEnabled ? (
              showSpinner ? (
                <div className="text-align-center min-200px p20">
                  <i className="fa fa-spinner fa-pulse" />
                </div>
              ) : (
                dropdownContent
              )
            ) : (
              dropdownContent
            )}
          </AllSearchResultsContainer>
        </>
      )}
    </div>
  )
}

export default AvatarAndNameDropdown
