import { connect } from 'react-redux'

import { fetchTeamProperties } from 'app/bond_cover/agency/agency_actions'
import { changeTeam } from 'app/session/session_actions'
import { fetchProperty } from 'app/sm/ppp/property_actions'
import { fetchAgencyPublicProperties } from 'app/sm/properties/properties_actions'
import {
  fetchTeamSettingInfo,
  updateTeamViewingSettingInfo,
} from 'app/sm/rental_reputation/actions'
import {
  fetchAgencyMembersList,
  fetchAgencyStatusReport,
  fetchViewingPreference,
  updateViewingPreference,
} from 'app/sm/team_viewing_preference/action'
import TeamViewingPreferenceContainer from 'app/sm/team_viewing_preference/container'

const mapStateToProps = ({ session }) => ({
  teams: session.teams,
  currentAgency: session.currentAgency,
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  changeTeam: (team) => dispatch(changeTeam(team)),
  updateViewingPreference: (teamId, preferenceInfo) =>
    dispatch(updateViewingPreference(teamId, preferenceInfo)),
  fetchViewingPreference: (teamId, propertyGUID) =>
    dispatch(fetchViewingPreference(teamId, propertyGUID)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
  updateTeamViewingSettingInfo: (
    teamId,
    viewingSetting,
    applicationSetting,
    personalNotificationSettings,
    feedSettings,
  ) =>
    dispatch(
      updateTeamViewingSettingInfo(
        teamId,
        viewingSetting,
        applicationSetting,
        personalNotificationSettings,
        feedSettings,
      ),
    ),
  fetchTeamProperties: (teamGUID, filters) =>
    dispatch(fetchTeamProperties(teamGUID, filters)),
  fetchProperty: (id) => dispatch(fetchProperty(id)),
  fetchAgencyMembersList: (agencyGUID) =>
    dispatch(fetchAgencyMembersList(agencyGUID)),
  fetchAgencyStatusReport: (agencyGUID) =>
    dispatch(fetchAgencyStatusReport(agencyGUID)),
  fetchAgencyPublicProperties: (
    agencySlug,
    address,
    sort,
    sortDir,
    bedroom,
    cursor,
  ) =>
    dispatch(
      fetchAgencyPublicProperties(
        agencySlug,
        address,
        sort,
        sortDir,
        bedroom,
        cursor,
      ),
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamViewingPreferenceContainer)
