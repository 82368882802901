import React from 'react'

import localforage from 'localforage'
import qs from 'qs'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import rocketIcon from 'app/assets/icons/rocket.svg'
import { Box, Button, Flex } from 'app/components/design-system-components'
import Badges from 'app/components/design-system-components/Badges'
import { Key } from 'app/components/design-system-components/icons/snug-specific'
import * as sharedHelpers from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import SnugUpgradeAccountModal from 'app/shared_components/snug_account_upgrade/modal'
import { urlIds, urlTo } from 'app/sm/helpers'
import * as setupHelpers from 'app/sm/team_viewing_preference/setup/helpers'
import * as accountHelpers from 'app/utils/accounts/helpers'
import { UserAdminFinance, UserLeasingConsultant } from 'app/utils/roles'
import {
  FeatureFlag,
  isKeyLoggerEnabled,
  isSetupTabEnabled,
  isTeamReferenceCountEnabled,
} from 'config/features'

const MAX_RENTAL_REFERENCES_COUNT = 10
const generateURL = (teamSlug, data) => {
  if (data.stage === 'Viewing' && window.innerWidth < 576) {
    return urlTo('teamViewingsMobile', { teamSlug })
  }
  return urlTo('prospectSummary', { teamSlug }) + '?' + qs.stringify(data)
}
const getStartedNavLabel = 'Get Started'

const TeamNavbarItem = ({ to, label, isActive }) => {
  return (
    <li>
      <NavLink to={to} isActive={isActive}>
        {label === 'Overview' && <i className="icon-dasshboard" />}
        {label === 'Bondcover' && <i className="icon-bondcover" />}
        {label === 'Applications' && <i className="icon-applications" />}
        {label === 'Viewings' && <i className="icon-calendar" />}
        {label === 'Messages' && <i className="icon-mail" />}
        {label === 'Settings' && <i className="icon-settings" />}
        {label === 'Contacts' && <i className="icon-person-add" />}
        {label === 'Activity' && <i className="icon-reports" />}
        {label === 'Keys' && (
          <Box fontSize="18px" mr="14px">
            <Key />
          </Box>
        )}
        {label === 'Academy' && <i className="fa fa-graduation-cap" />}
        {label === 'References' && <i className="icon-documents" />}
        {label === getStartedNavLabel && (
          <Box mr="15px">
            <img
              height="22px"
              width="22px"
              src={rocketIcon}
              alt="rocket icon"
            />
          </Box>
        )}
        <span>{label}</span>
      </NavLink>
    </li>
  )
}

function SetupWizardToaster({ finalTeamSlug }) {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box>Start taking applications in 2 minutes</Box>
      <Flex alignItems="center">
        <Button
          sizeVariant="extraSmall"
          onClick={() =>
            history.push(
              urlTo('teamViewingPreferenceSetup', {
                teamSlug: finalTeamSlug,
              }),
            )
          }
          mr="8px"
        >
          Do it!
        </Button>
        <a onClick={() => setupHelpers.dontShowSetupWizardPrompt()}>
          don’t show again
        </a>
      </Flex>
    </Flex>
  )
}

class TeamNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showUpgradeAccountModal: false,
    }
  }

  componentDidMount() {
    const { isQuickStartWizardForTeamInvalid, currentTeam } = this.props
    const { teamSlug } = this.props.match.params
    const { slug = '' } = currentTeam || {}
    const finalTeamSlug = teamSlug || slug
    const tabEnabled = isSetupTabEnabled(finalTeamSlug)
    if (tabEnabled) {
      let showToast = true
      localforage.getItem('dontShowWizardCompleteSteps', function (err, value) {
        if (value) {
          showToast = false
        }
        if (window.location.href.includes('settings')) {
          showToast = false
        }
        if (isQuickStartWizardForTeamInvalid === false && showToast) {
          sharedHelpers.openToaster(
            'warning',
            <SetupWizardToaster finalTeamSlug={finalTeamSlug} />,
          )
        }
      })
    }
  }

  onClickDisabledLiteAccountOption = () => {
    const { showUpgradeAccountModal } = this.state
    this.setState({
      showUpgradeAccountModal: !showUpgradeAccountModal,
    })
  }

  render() {
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const { stage, property } = queries
    const { teamSlug } = this.props.match.params
    const { slug } = this.props.currentTeam || {}
    const finalTeamSlug = teamSlug || slug
    const isAccountLite =
      this.props.currentTeam &&
      this.props.currentTeam.accountType &&
      this.props.currentTeam.accountType === accountHelpers.ACCOUNT_TYPE_LITE
    const currentPath = window.location.pathname
    const stageForApplications =
      stage &&
      (stage.toLowerCase() === 'application' ||
        stage.toLowerCase() === 'applicationbystage')
    const setupPathMatched =
      currentPath?.includes('settings') && currentPath?.includes('setup')
    const { agencyUserRole, rentalReferencesCount } = this.props
    const { showUpgradeAccountModal } = this.state
    const { currentTeam } = this.props
    const showReferenceCount =
      isTeamReferenceCountEnabled(finalTeamSlug) && rentalReferencesCount > 0
    const rentalReferenceCountText = `${
      rentalReferencesCount > MAX_RENTAL_REFERENCES_COUNT
        ? '10+'
        : rentalReferencesCount
    }`

    return (
      <div className="navLinks">
        {agencyUserRole === UserLeasingConsultant && (
          <ul>
            <TeamNavbarItem
              label="Overview"
              to={urlTo('teamOverview', { teamSlug: finalTeamSlug })}
            />
            <TeamNavbarItem
              label="Messages"
              to={urlTo('teamMessages', { teamSlug: finalTeamSlug })}
            />
            <TeamNavbarItem
              label="Viewings"
              to={generateURL(finalTeamSlug, { stage: 'Viewing' })}
              isActive={() =>
                (stage && stage.toLowerCase() === 'viewing') ||
                this.props.location.pathname ===
                  `/teams/${finalTeamSlug}/viewings`
              }
            />
            {!isAccountLite ? (
              <TeamNavbarItem
                label="+ Add Property"
                to={urlTo('propertyGetStarted', { teamSlug: finalTeamSlug })}
              />
            ) : (
              <li
                className="background-color-gray-with-opacity"
                onClick={this.onClickDisabledLiteAccountOption}
              >
                <a href="javascript:void(0)">+ Add Property</a>
              </li>
            )}
            {!isAccountLite ? (
              <TeamNavbarItem
                label="+ Stocklist"
                to={urlTo('propertyList', { teamSlug: finalTeamSlug })}
              />
            ) : (
              <li
                className="background-color-gray-with-opacity"
                onClick={this.onClickDisabledLiteAccountOption}
              >
                <a href="javascript:void(0)">+ Stocklist</a>
              </li>
            )}
          </ul>
        )}
        {agencyUserRole === UserAdminFinance && (
          <ul>
            <TeamNavbarItem
              label="Overview"
              to={urlTo('teamOverview', { teamSlug: finalTeamSlug })}
            />
            <TeamNavbarItem
              label="Messages"
              to={urlTo('newMessagesPage', { teamSlug: finalTeamSlug })}
            />
            {FeatureFlag.BondCover.isOn && (
              <TeamNavbarItem
                label="Bondcover"
                to={urlTo('managerDashboardTemporary', {
                  teamSlug: finalTeamSlug,
                })}
              />
            )}
            <TeamNavbarItem
              label="Viewings"
              to={generateURL(finalTeamSlug, { stage: 'Viewing' })}
              isActive={() =>
                (stage && stage.toLowerCase() === 'viewing') ||
                this.props.location.pathname ===
                  `/teams/${finalTeamSlug}/viewings`
              }
            />
            <TeamNavbarItem
              label="Applications"
              to={generateURL(finalTeamSlug, { stage: 'Application' })}
              isActive={() => stageForApplications}
            />
            <Flex>
              <TeamNavbarItem
                label="References"
                to={urlTo('rentalReferencesForTeam', {
                  teamSlug: finalTeamSlug,
                })}
              />
              {showReferenceCount && (
                <Box my="auto">
                  <Badges
                    data-tooltip-content=""
                    data-tooltip-id="rentalReferenceCountText"
                    variant="primary"
                    text={rentalReferenceCountText}
                  />

                  <ReactTooltip
                    id="rentalReferenceCountText"
                    place="top"
                    className="tool-tip-style"
                  >
                    <div>References received in last 7 days</div>
                  </ReactTooltip>
                </Box>
              )}
            </Flex>
            {FeatureFlag.Contacts.isOn && (
              <TeamNavbarItem
                label="Contacts"
                to={urlTo('contactList', { teamSlug: finalTeamSlug })}
              />
            )}
            {FeatureFlag.Activity.isOn && (
              <TeamNavbarItem
                label="Activity"
                to={urlTo('teamActivity', { teamSlug: finalTeamSlug })}
              />
            )}
            {isKeyLoggerEnabled(currentTeam) && (
              <TeamNavbarItem
                label="Keys"
                to={urlTo(urlIds.keyLogger, { teamSlug: finalTeamSlug })}
              />
            )}
            {FeatureFlag.ViewingSchedule.isOn && (
              <TeamNavbarItem
                label="Settings"
                to={urlTo('teamViewingPreference', { teamSlug: finalTeamSlug })}
              />
            )}
            <TeamNavbarItem
              label="+ Add Property"
              to={urlTo('propertyGetStarted', { teamSlug: finalTeamSlug })}
            />
            {!isAccountLite ? (
              <TeamNavbarItem
                label="+ Stocklist"
                to={urlTo('propertyList', { teamSlug: finalTeamSlug })}
              />
            ) : (
              <li
                className="background-color-gray-with-opacity"
                onClick={this.onClickDisabledLiteAccountOption}
              >
                <a href="javascript:void(0)">+ Stocklist</a>
              </li>
            )}
            <TeamNavbarItem
              label="Academy"
              to={urlTo('onboarding', { teamSlug: finalTeamSlug })}
            />
            <TeamNavbarItem
              label={getStartedNavLabel}
              to={urlTo('teamViewingPreferenceSetup', {
                teamSlug: finalTeamSlug,
              })}
              isActive={false}
            />
          </ul>
        )}
        {showUpgradeAccountModal && (
          <SnugUpgradeAccountModal
            isNotToBeEmbedded={false}
            toggleModal={this.onClickDisabledLiteAccountOption}
            typeOfMessage={accountHelpers.UPGRADE_TYPE_MESSAGES}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
  rentalReferencesCount: session.rentalReferencesCount,
  isQuickStartWizardForTeamInvalid: session.isQuickStartWizardForTeamInvalid,
})

export default connect(mapStateToProps, null)(TeamNavbar)
