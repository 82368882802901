import React from 'react'

import styled from 'styled-components'

import TopBackButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import { Box } from 'app/components/design-system-components'
import { Heading } from 'app/components/design-system-components/typography'

const Message = styled.p`
  margin-top: 10px;
  margin-bottom: 0;
  color: #686d71;
`

const RentalReputationHeader = ({ title, message, showTopBack = false }) => {
  return (
    <Box mb={13} mt={4}>
      <div className="width100 display-flex">
        {showTopBack && <TopBackButton isMobile={true} />}
        <Box mb={4}>
          <Heading level={1}>{title}</Heading>
        </Box>
      </div>
      <Message>{message}</Message>
    </Box>
  )
}

export default RentalReputationHeader
