import React, { useEffect, useState } from 'react'

import {
  Alert,
  Flex,
  LegacySpinner,
} from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import MembersList from 'app/sm/team_viewing_preference/components/members_list'
import { getAgencyMembers } from 'app/sm/team_viewing_preference/setup/api'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

function AllTeamMembers({ teamGUID }) {
  const [membersList, setMembersList] = useState([])
  const {
    loadingStates: allTeamMembersLoadingStates,
    loadingStatesHelpers: allTeamMembersLoadingStateHelpers,
  } = useLoadingStates()

  useEffect(() => {
    allTeamMembersLoadingStateHelpers.startLoading()
    getAgencyMembers(teamGUID)
      .then(({ data }) => {
        setMembersList(data)
        allTeamMembersLoadingStateHelpers.markDoneSuccessfully()
      })
      .catch((error) => allTeamMembersLoadingStateHelpers.setError(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {allTeamMembersLoadingStates.state === loadingStatesIds.LOADING ? (
        <Flex justifyContent="center" mt={theme.space[6] + 'px'}>
          <LegacySpinner />
        </Flex>
      ) : (
        <>
          {allTeamMembersLoadingStates.state !== loadingStatesIds.ERROR ? (
            <>
              {membersList && membersList.length > 0 && (
                <>
                  <MembersList teamMembersList={membersList} />
                </>
              )}
            </>
          ) : (
            <Alert variant="warningWithBg" mt={theme.space[6] + 'px'}>
              <div>{allTeamMembersLoadingStates.error.message}</div>
            </Alert>
          )}
        </>
      )}
    </>
  )
}

export default AllTeamMembers
