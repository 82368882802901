import React from 'react'

import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button } from 'app/components/design-system-components'
import { updateRegistrationInfo } from 'app/services/http/session'
import { UpdateRegisteredUserForm } from 'app/session/register_components/UpdateRegisteredUserForm'
import { registerSuccess } from 'app/session/session_actions'
import Footer from 'app/shared_components/footer/footer'
import SnugLogo from 'app/shared_components/snug_logo'

const UpdateRegisteredUserPage = ({ user, onRegistrationUpdated }) => {
  const history = useHistory()

  const onUpdateRegistrationInfo = (updatedInfo) => {
    return updateRegistrationInfo(updatedInfo).then((updatedUser) => {
      onRegistrationUpdated(updatedUser)
    })
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-screen panel panel-default">
        <div className="panel-heading">
          <div className="app-header">
            <div className="app-header_left">
              <Button variant="flat" p="0" onClick={() => history.goBack()}>
                <i className="fa fa-chevron-left"></i>
              </Button>
            </div>
            <div className="app-header_center">
              <SnugLogo />
            </div>
            <div className="app-header_right"></div>
          </div>
        </div>
        <div className="panel-body">
          <h3 className="mb30">Update Registration Info</h3>
          <UpdateRegisteredUserForm
            user={user}
            onUpdateRegistrationInfo={onUpdateRegistrationInfo}
          />
          <p className="pt15 fs12 mb0 lh14 gray-light-color">
            By joining Snug you agree to our Terms of Use and Privacy Policy.
          </p>
          <Footer />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ session }) => {
  return {
    user: session.currentUser,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onRegistrationUpdated: (updatedUser) =>
      registerSuccess('/verify')(updatedUser),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateRegisteredUserPage)
