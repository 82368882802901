import React from 'react'

import CreateAgencyFormContainer from 'app/bond_cover/agency/create_agency_form_container'
import RegisterAgencyFormContainer from 'app/bond_cover/agency/register_agency_form_container'
import AgencySearchBox from 'app/bond_cover/agency/search_box'
import AdminAgencySearchResult from 'app/components/admin/AdminAgencySearchResult'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'

const RWG = 'Ray White'

class EditAgencyFormContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      agencyInfo: {
        agencyName: '',
        agencySlug: '',
        agencyAddress: '',
        agencyGUID: '',
        crmID: '',
        franchiseGroup: '',
      },
      agencyProfile: undefined,
      showRayWhiteSetting: false,
    }
  }

  componentDidMount() {
    const { params } = this.props.match
    if (params.agencyGUID) {
      this.props.fetchAgencyInfo(params.agencyGUID)
      this.props.fetchAgencyProfile(params.agencyGUID, (agencyProfile) => {
        this.setState({
          agencyProfile,
        })
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { agency } = nextProps
    if (this.props.agency !== agency && agency) {
      this.setState({
        agencyInfo: {
          agencyName: agency.name,
          agencySlug: agency.slug,
          agencyAddress: agency.agencyAddress,
          agencyGUID: agency.guidID,
          crmID: agency.crmID,
          franchiseGroup: agency.franchiseGroup,
        },
        showRayWhiteSetting: agency.franchiseGroup === RWG,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { registerError = '' } = this.props
    if (
      prevProps.match.params.agencyGUID !== this.props.match.params.agencyGUID
    ) {
      this.callAgencyProfileSettings()
    }

    if (prevProps.registerError !== registerError && registerError) {
      snugNotifier.error(translateErrorCodeToMessage(registerError))
    }
  }

  onDeleteAgencyUser = (index, agencyUser) => {
    this.props.deleteAgencyUser(
      agencyUser,
      this.state.agencyInfo.agencyGUID,
      () => {
        this.fetchAgencyProfile()
      },
    )
  }

  onSearchBoxChange = (agency, callback) => {
    this.setState(
      {
        agencyInfo: {
          agencyName: agency.value,
          agencySlug: agency.slug,
          agencyAddress: agency.address,
          agencyGUID: agency.guidID,
          crmID: agency.crmID,
          franchiseGroup: agency.franchiseGroup,
        },
        agencyProfile: undefined,
        showRayWhiteSetting: agency.franchiseGroup === RWG,
      },
      () => {
        const {
          agencyInfo: { agencyGUID },
        } = this.state
        callback && callback()
        agencyGUID && this.props.fetchAgencyInfo(agencyGUID)
        agencyGUID && this.fetchAgencyProfile()
      },
    )
  }

  onUpdateAgency = (agency) => {
    const { updateAgency } = this.props
    const { agencyInfo: { agencyGUID } = {} } = this.state
    const fullAgency = {
      agencyGUID,
      ...agency,
    }
    return updateAgency(fullAgency)
  }

  onUpdateAgencyProfile = (agencyProfile, success) => {
    agencyProfile.agencyId = this.state.agencyInfo.agencyGUID
    return this.props.updateAgencyProfile(agencyProfile, () => {
      setTimeout(() => {
        this.fetchAgencyProfile()
      }, 1500)
    })
  }

  callAgencyProfileSettings = () => {
    const { params } = this.props.match
    if (params.agencyGUID) {
      this.setState({
        agencyProfile: undefined,
      })
      this.props.fetchAgencyInfo(params.agencyGUID).then(({ agency }) => {
        this.props.fetchAgencyProfile(agency.guidID, (agencyProfile) => {
          this.setState({
            agencyProfile,
          })
        })
      })
    }
  }

  fetchAgencyProfile = () => {
    this.props.fetchAgencyProfile(
      this.state.agencyInfo.agencyGUID,
      (agencyProfile) => {
        this.setState(
          {
            agencyProfile,
          },
          () => {
            const { agencyGUID } = this.state.agencyInfo
            history.push(urlTo('editAgency', { agencyGUID }))
          },
        )
      },
    )
  }

  render() {
    const editMode = true
    const {
      match: {
        params: { agencyGUID },
      },
      fetchPropertytreeTokenForAgency,
      fetchAllPropertytreeTokens,
      refreshtreeTokenForAgency,
      activatePMSIntegration,
      deactivatePMSIntegration,
    } = this.props
    const { agencyProfile } = this.state || {}
    const { agencyUserInvites } = agencyProfile || {}
    const { showRayWhiteSetting } = this.state

    return (
      <div>
        <div className="two-col-box-flex-row sm64">
          <div className="col-first">
            <div className="input-box">
              <AgencySearchBox
                placeholder="Search Real Estate Agency"
                value={this.state.agencyInfo.agencyName}
                handleInputChange={this.onSearchBoxChange}
                inputChangeCallback={() => {}}
                handleInputBlur={() => {}}
                className=""
                searchFunction={this.props.searchAgencies}
                SearchResultItem={AdminAgencySearchResult}
              />
            </div>
          </div>
        </div>

        {agencyGUID && this.state.agencyInfo.agencyGUID && (
          <CreateAgencyFormContainer
            edit={true}
            initialState={this.state.agencyInfo}
            createAgency={this.onUpdateAgency}
            clearError={this.props.clearError}
            error={this.props.createError}
          />
        )}

        {agencyGUID && this.state.agencyInfo.agencyGUID && (
          <div className="two-col-box-flex-row sm64">
            <div className="col-first">
              <hr />
            </div>
          </div>
        )}

        {agencyGUID && this.state.agencyProfile && (
          <RegisterAgencyFormContainer
            edit={editMode}
            disableSearchBox={editMode}
            initialState={this.state.agencyProfile}
            existingAgencyUserInvites={
              this.state.agencyProfile.agencyUserInvites
            }
            register={this.onUpdateAgencyProfile}
            deleteAgencyUser={this.onDeleteAgencyUser}
            clearError={this.props.clearError}
            error={this.props.registerError}
            agencyGUID={agencyGUID}
            addAgencyBranding={this.props.addAgencyBranding}
            removeAgencyBranding={this.props.removeAgencyBranding}
            showRayWhiteSetting={showRayWhiteSetting}
            fetchPropertytreeTokenForAgency={fetchPropertytreeTokenForAgency}
            fetchAllPropertytreeTokens={fetchAllPropertytreeTokens}
            refreshtreeTokenForAgency={refreshtreeTokenForAgency}
            activatePMSIntegration={activatePMSIntegration}
            deactivatePMSIntegration={deactivatePMSIntegration}
            initialStateAgencyInfo={this.state.agencyInfo}
            agencyProfile={agencyProfile}
          />
        )}
      </div>
    )
  }
}

export default EditAgencyFormContainer
