import { connect } from 'react-redux'

import { getAllSupportingIDAttachments } from 'app/profile/renter/identity_doc/other_identity_doc/actions'
import OtherIdentityDoc from 'app/profile/renter/identity_doc/other_identity_doc/container'
import {
  addIdentityDocAttachment,
  deleteIdentityDocAttachment,
} from 'app/sm/background_check/background_check_actions'

const mapStateToProps = null

const mapDispatchToProps = (dispatch) => ({
  addIdentityDocAttachment: (documentType, id, isIdentityDoc) =>
    dispatch(addIdentityDocAttachment(documentType, id, isIdentityDoc)),
  getAllSupportingIDAttachments: () =>
    dispatch(getAllSupportingIDAttachments()),
  deleteIdentityDocAttachment: (id, attachmentrID, isIdentityDoc) =>
    dispatch(deleteIdentityDocAttachment(id, attachmentrID, isIdentityDoc)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OtherIdentityDoc)
