import { apiBaseUrl } from 'config/env'

import { api } from 'app/utils/api/helpers'

export const uploadSelfTenancyCheck = (file) => {
  let formData = new FormData()
  for (let i = 0; i < file.length; i++) {
    formData.append('file', file[i])
  }
  formData.append('type', 20)

  return api.post(`${apiBaseUrl}sm/reputation/selftenancycheck`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getSelfTenancyCheck = () => {
  return api.get(`${apiBaseUrl}sm/reputation/selftenancycheck`)
}

export const deleteSelfTenancyCheck = (id) => {
  return api.delete(`${apiBaseUrl}sm/reputation/selftenancycheck/${id}`)
}
