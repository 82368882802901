import React from 'react'

import { Link } from 'react-router-dom'

import ConfirmRegisterForm from 'app/session/register_components/register_confirm_connection'
import Footer from 'app/shared_components/footer/footer'
import SnugLogo from 'app/shared_components/snug_logo'
import { urlTo } from 'app/sm/helpers'

const RegisterConfirm = () => (
  <div className="auth-wrapper">
    <div className="auth-screen panel panel-default">
      <div className="panel-heading">
        <div className="app-header">
          <div className="app-header_left">
            <Link to={urlTo('join')}>
              <i className="fa fa-chevron-left"></i>
            </Link>
          </div>
          <div className="app-header_center">
            <SnugLogo />
          </div>
          <div className="app-header_right"></div>
        </div>
      </div>
      <div className="panel-body">
        <h3 className="mb30">Confirm your details</h3>
        <ConfirmRegisterForm />
        <p className="pt15 fs12 mb0 lh14 gray-light-color">
          By joining Snug you agree to our Terms of Use and Privacy Policy.
        </p>
        <Footer />
      </div>
    </div>
  </div>
)

export default RegisterConfirm
