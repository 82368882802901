import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const pushReview = (propertyId, review) => {
  const strip = (o) => ({
    property: {
      title: review.property.title,
      text: review.property.description,
      isPrivate: review.property.isPrivate,
      rating: review.property.score,
    },
    agent: {
      title: review.agent.title,
      text: review.agent.description,
      isPrivate: review.agent.isPrivate,
      rating: review.agent.score,
    },
  })

  const url = `${apiBaseUrl}sm/properties/${propertyId}/review`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(strip(review)),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve() // We get nothing for now, when a review is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true, review }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deleteRentalReference = (rentalHistoryId) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${rentalHistoryId}/manual/ratings`
  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postNote = (applicationId, applicantId, payload) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/applicant/${applicantId}/note`
  return fetch(url, {
    credentials: 'include',
    body: JSON.stringify(payload),
    method: 'POST',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (notes) => ({ ok: true, notes }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
