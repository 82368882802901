import React from 'react'

import moment from 'moment'

import * as Form from 'app/components/forms/forms'
import { searchAddressEmailNamePhonePlaceholder } from 'app/components/text/placeholderText'
import {
  defaultPropertyFilters,
  propertyFilters,
} from 'app/dashboard/team_overview_reducer'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import {
  FilterHeading,
  FilterIconContainer,
  SearchAndFilterContainer,
  SearchInputContainer,
  SortByContainer,
} from 'app/pages/teams/viewings-mobile/styles'
import * as helpers from 'app/sm/helpers'
import * as viewingsHelpers from 'app/utils/viewings/helpers'

const selectDateOptions = ['All', 'Today only', 'Select a date']
const selectDateOptionToIndex = {
  all: '0',
  todayOnly: '1',
  selectADate: '2',
}

export const SearchInputBox = ({
  searchText,
  onSearchChange,
  onCancelClick,
  showCancelIconInSearchBox,
  viewingUpdateMeFilter,
  filters,
}) => (
  <SearchInputContainer>
    <div className="input-box ">
      <div className="icon-container search-icon-container">
        <i className="icon-search" />
      </div>
      <input
        type="text"
        placeholder={searchAddressEmailNamePhonePlaceholder}
        value={searchText}
        onChange={onSearchChange}
      />
      {showCancelIconInSearchBox && (
        <div
          className="icon-container cancel-icon-container"
          onClick={onCancelClick}
        />
      )}
      <span
        className="textButton linkStyle-blue ml10"
        onClick={viewingUpdateMeFilter}
      >
        {filters.showAllTab ? 'All' : 'Me'}
      </span>
    </div>
  </SearchInputContainer>
)

class AgencySearchSectionContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewingManagerList: [],
      filtersEnabled: false,
      filtersExpanded: false,
      searchText: '',
    }
  }

  componentDidMount() {
    const { currentTeam, filters } = this.props
    if (currentTeam) {
      this.props
        .getManagersListForViewingFilters(currentTeam.guid)
        .then((managerList) => {
          this.setState({ viewingManagerList: managerList })
        })
    }

    if (filters?.searchText) {
      this.setState({ searchText: filters.searchText })
    }
  }

  componentDidUpdate = (prevProps) => {
    const { currentTeam } = this.props
    if (prevProps.currentTeam !== currentTeam) {
      if (currentTeam) {
        this.props
          .getManagersListForViewingFilters(currentTeam.guid)
          .then((managerList) => {
            this.setState({ viewingManagerList: managerList })
          })
      }
    }
  }

  onCancelClick = () => {
    this.setState({
      filtersEnabled: false,
      searchText: '',
    })
    const { filters, setPropertyFilters } = this.props
    const newFilters = Object.assign({}, filters)
    newFilters.searchText = ''
    setPropertyFilters(newFilters)
  }

  onDateDropdownUpdated = (data) => {
    this.setState({
      filtersEnabled: true,
    })
    const { value } = data
    const { filters, setPropertyFilters } = this.props
    let newFilters = {}
    switch (value) {
      case selectDateOptionToIndex.all: {
        newFilters = Object.assign({}, filters, {
          todayOnly: false,
          dateOption: value,
          datePickerValue: '',
        })
        setPropertyFilters(newFilters)
        break
      }
      case selectDateOptionToIndex.todayOnly: {
        newFilters = Object.assign({}, filters, {
          todayOnly: true,
          dateOption: value,
          datePickerValue: '',
        })
        setPropertyFilters(newFilters)
        break
      }
      case selectDateOptionToIndex.selectADate: {
        setTimeout(function () {
          document.querySelector('#date-picker-input').focus()
        }, 500)

        break
      }
      default:
        return
    }
  }

  onDatePickerChange = (data) => {
    const { value } = data
    const datePickerValue = moment(value).format(
      helpers.standardDateWithoutTimeAndComma,
    )
    const { filters, setPropertyFilters } = this.props
    const newFilters = Object.assign({}, filters, {
      todayOnly: false,
      datePickerValue,
    })
    setPropertyFilters(newFilters)
  }

  onPropertyManagerFilterChange = ({ value, error }) => {
    this.setState({
      filtersEnabled: true,
    })
    const { filters, setPropertyFilters, persistManager } = this.props
    const newFilters = Object.assign({}, filters)
    const specificManagerNotSelected = !(
      value === '' || value === propertyFilters.MANAGER_DEFAULT
    )

    newFilters.manager = value
    newFilters.showAllTab = specificManagerNotSelected

    persistManager(value) // When navigating between different team pages
    setPropertyFilters(newFilters)
  }

  onResetFilters = () => {
    this.setState({
      filtersEnabled: false,
    })
    this.props.resetPropertyFilters()
    this.props.resetPropertyFilterInViewings()
  }

  debounceOnTextChange = debounce((value) => {
    const { filters, setPropertyFilters } = this.props
    const newFilters = Object.assign({}, filters)
    newFilters.searchText = value
    setPropertyFilters(newFilters)
    this.setState({
      filtersEnabled: true,
    })
  }, STANDARD_TIMEOUT)

  onSearchChange = (event) => {
    const { value } = event.target
    this.setState({ searchText: value })
    this.debounceOnTextChange(value)
  }

  showDeclinedAndWithdrawnApplicationsFilter() {
    const { filters, setPropertyFilters } = this.props
    const newFilters = Object.assign({}, filters, {
      showDeclinedAndWithdrawnApplications:
        !filters.showDeclinedAndWithdrawnApplications,
    })
    setPropertyFilters(newFilters)
  }

  viewingUpdateMeFilter = () => {
    this.setState({
      filtersEnabled: true,
    })
    const { viewingManagerList } = this.state
    const { filters, setPropertyFilters, persistManager } = this.props
    const newFilters = Object.assign({}, filters)
    const loggedInManager =
      viewingManagerList && viewingManagerList.find((m) => m.isMe)

    if (filters.showAllTab === true) {
      newFilters.showAllTab = false
      newFilters.manager = propertyFilters.MANAGER_DEFAULT
    } else {
      newFilters.showAllTab = true
      newFilters.manager = loggedInManager
        ? loggedInManager.contact.guidID
        : propertyFilters.MANAGER_DEFAULT
    }

    persistManager(newFilters.manager) // When navigating between different team pages
    setPropertyFilters(newFilters)
  }

  render() {
    const {
      filters = defaultPropertyFilters,
      changeViewingsByType,
      currentViewingsType,
    } = this.props
    const { viewingManagerList, filtersEnabled, filtersExpanded, searchText } =
      this.state
    let preparedManagersListForDropdown = [{ guid: '', name: 'All' }]
    const showCancelIconInSearchBox = filters.searchText.length > 0
    const allViewingManagersWithID =
      viewingManagerList &&
      viewingManagerList.map((opt) => {
        const newObj = {
          guid: opt.contact && opt.contact.guidID,
          name: `${opt.contact && opt.contact.firstName} ${
            opt.contact &&
            helpers.firstAndLastNameInitials(opt.contact, 'lastName')
          }`,
        }
        return newObj
      })
    if (allViewingManagersWithID) {
      preparedManagersListForDropdown = [
        ...preparedManagersListForDropdown,
        ...allViewingManagersWithID,
      ]
    }

    return (
      <div>
        <SearchAndFilterContainer>
          <SearchInputBox
            searchText={searchText}
            onSearchChange={this.onSearchChange}
            onCancelClick={this.onCancelClick}
            showCancelIconInSearchBox={showCancelIconInSearchBox}
            viewingUpdateMeFilter={this.viewingUpdateMeFilter}
            filters={filters}
          />
          <FilterIconContainer
            filtersEnabled={filtersEnabled}
            onClick={() =>
              this.setState({
                filtersExpanded: !filtersExpanded,
              })
            }
          />
        </SearchAndFilterContainer>
        {filtersExpanded && (
          <div>
            <FilterHeading>Sort by: </FilterHeading>
            <SortByContainer>
              <div
                className={
                  currentViewingsType === viewingsHelpers.VIEWINGS_BY_DATE
                    ? 'is-selected'
                    : ''
                }
                onClick={() =>
                  changeViewingsByType(viewingsHelpers.VIEWINGS_BY_DATE)
                }
              >
                Date
              </div>
              <div
                className={
                  currentViewingsType === viewingsHelpers.VIEWINGS_BY_PROPERTY
                    ? 'is-selected'
                    : ''
                }
                onClick={() =>
                  changeViewingsByType(viewingsHelpers.VIEWINGS_BY_PROPERTY)
                }
              >
                Properties
              </div>
            </SortByContainer>
            <FilterHeading>Team Member</FilterHeading>
            <div className="display-flex align-items-center">
              <Form.Dropdown
                inputBoxClass="pt0"
                onChange={this.onPropertyManagerFilterChange}
                options={preparedManagersListForDropdown}
                id="select-manager-dropdown"
                value={filters.manager}
                idAsValue
                label="All"
              />
              <span
                className="textButton linkStyle-blue ml10"
                onClick={this.viewingUpdateMeFilter}
              >
                {filters.showAllTab ? 'All' : 'Me'}
              </span>
            </div>

            <FilterHeading>Date:</FilterHeading>
            <div className="display-flex align-items-center mb25">
              <Form.Dropdown
                inputBoxClass="pt0"
                value={filters.datePickerValue ? '' : filters.dateOption}
                options={selectDateOptions}
                onChange={this.onDateDropdownUpdated}
                label={filters.datePickerValue}
              />
              <Form.InputDate
                componentClass="mt-10000 position-absolute date-picker-fullscreen"
                onChange={this.onDatePickerChange}
                viewMode="days"
                closeOnSelect
                id="date-picker-input"
                readOnly={false}
              />
            </div>

            <div className="blue-link-style" onClick={this.onResetFilters}>
              Clear Filters
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default AgencySearchSectionContainer
