import React from 'react'

import { connect } from 'react-redux'

import headerImg01 from 'app/assets/icons/header-img-01.png'
import Button from 'app/components/buttons/default/component'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  sendResubscribeSmsRequest,
  sendUnsubscribeSmsRequest,
} from 'app/dashboard/dashboard_actions'
import * as snugNotifier from 'app/services/snugNotifier'
import { ErrorMessage } from 'app/shared_components/helpers'

class UnsubscribeSmsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slug: '',
      loadingError: '',
      resubscribed: false,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { slug },
      },
      sendUnsubscribeSmsRequest,
    } = this.props
    sendUnsubscribeSmsRequest(slug)
      .then(() => {
        this.setState({
          resubscribed: false,
          slug,
        })
      })
      .catch((error) => {
        this.setState({ resubscribed: false })
        snugNotifier.error(translateErrorCodeToMessage(error))
      })
  }

  onResubscribeButton = () => {
    const {
      match: {
        params: { slug },
      },
      sendResubscribeSmsRequest,
    } = this.props
    sendResubscribeSmsRequest(slug)
      .then(() => {
        this.setState({
          resubscribed: true,
        })
      })
      .catch((error) => {
        this.setState({ resubscribed: false })
        snugNotifier.error(translateErrorCodeToMessage(error))
      })
  }

  render() {
    const { loadingError, resubscribed } = this.state
    const confirmationText = !resubscribed
      ? "We've unsubscribed you from SMS alerts."
      : "You've been successfully resubscribed to SMS alerts."
    const headerText = !resubscribed ? 'Farewell' : 'Welcome back!'

    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first fw600">
          {!loadingError ? (
            <div>
              <div className="two-col-box-flex-row sm64">
                <div className="col-first fw600">
                  <div className="ilustration-box">
                    <img src={headerImg01} />
                  </div>
                  <h2 className="fw400 text-align-center">{headerText}</h2>
                  <p className="text-align-center mt30 mb0 fw400">
                    {confirmationText}
                  </p>
                  <br />
                  <div className="display-flex align-items-center justify-content-center pb10">
                    <Button
                      text={!resubscribed ? 'Resubscribe' : 'Subscribed'}
                      disabled={resubscribed}
                      onClick={() => this.onResubscribeButton()}
                      size="large"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ErrorMessage error={loadingError} />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = null

const mapDispatchToProps = (dispatch) => ({
  sendResubscribeSmsRequest: (slug) =>
    dispatch(sendResubscribeSmsRequest(slug)),
  sendUnsubscribeSmsRequest: (slug) =>
    dispatch(sendUnsubscribeSmsRequest(slug)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnsubscribeSmsContainer)
