import React from 'react'

import ThirdpartyIntegration from 'app/sm/team_viewing_preference/thirdpartyIntegration/component'

const TEXT_PENDING = 'Disconnecting'
const TEXT_ACTIVE = 'Active'
const TEXT_FAILED = 'Failed'
const TEXT_EXPIRED = 'Expired'
const COLOR_GREEN = 'green'
const COLOR_YELLOW = 'yellow'
const COLOR_ORANGE = 'orange-hollow'
const COLOR_RED = 'red'

const TeamCalendarSync = ({
  isSubmitted,
  initialStage,
  pendingStage,
  successStage,
  updatedAt,
  startedAt,
  expiredAt,
  tableSpinner,
  activateBtnOnClick,
  deactivateBtnOnClick,
  calendarType,
  logoImg,
}) => {
  let pillColour, pillText, statusBottomText, integrationInfo
  let inactive = false
  if (expiredAt) {
    pillColour = COLOR_ORANGE
    pillText = TEXT_EXPIRED
    statusBottomText = `Expired: ${expiredAt}`
    inactive = true
  } else if (successStage) {
    pillColour = COLOR_GREEN
    pillText = TEXT_ACTIVE
    statusBottomText = 'Activated: ' + updatedAt
    integrationInfo = `Synchronising your assigned viewing times with ${calendarType}`
  } else if (pendingStage) {
    pillColour = COLOR_YELLOW
    pillText = TEXT_PENDING
    statusBottomText = 'Started: ' + startedAt
    integrationInfo = ''
  } else {
    pillColour = COLOR_RED
    pillText = TEXT_FAILED
    statusBottomText = 'Failed to deactivate. Please try again.'
    integrationInfo = ''
  }

  if (!isSubmitted) {
    integrationInfo = `Synchronise your assigned viewing times with ${calendarType}`
  }

  return (
    <div>
      {tableSpinner ? (
        <i className="fa fa-spinner fa-pulse" />
      ) : (
        <ThirdpartyIntegration
          initialStage={initialStage}
          activateBtnOnClick={activateBtnOnClick}
          deactivateBtnOnClick={deactivateBtnOnClick}
          isSubmitted={isSubmitted}
          pendingStage={pendingStage}
          integrationInfo={integrationInfo}
          logoImg={logoImg}
          pillColour={pillColour}
          pillText={pillText}
          statusBottomText={statusBottomText}
          reactivateOnClick={activateBtnOnClick}
          reactivateText="Reconnect"
          inactiveStage={inactive}
        />
      )}
    </div>
  )
}

TeamCalendarSync.defaultProps = {
  isSubmitted: false,
  initialStage: true,
  pendingStage: false,
  successStage: false,
  updatedAt: '',
  startedAt: '',
  tableSpinner: false,
}

export default TeamCalendarSync
