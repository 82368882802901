import { connect } from 'react-redux'

import {
  checkboxChanged,
  fetchPropertyMetadata,
  inputChanged,
  ping,
  rate,
  submitReview,
} from 'app/sm/review/review_actions'
import ReviewContainer from 'app/sm/review/review_container'

const mapStateToProps = ({ review }) => ({
  property: review.property,
  error: review.error,
})

const mapDispatchToProps = (dispatch) => ({
  ping: () => dispatch(ping()),
  rate: (role, score) => dispatch(rate(role, score)),
  inputChanged: (role, key, value) => dispatch(inputChanged(role, key, value)),
  checkboxChanged: (role, key) => dispatch(checkboxChanged(role, key)),
  submitReview: () => dispatch(submitReview()),
  fetchPropertyMetadata: (propertyId) =>
    dispatch(fetchPropertyMetadata(propertyId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewContainer)
