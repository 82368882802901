import React, { useContext, useState } from 'react'

import OtpInput from 'react-otp-input'
import { Link, useHistory } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import styled from 'styled-components'

import {
  Box,
  Button,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { resendOTPEmail, resendOTPSMS, verify } from 'app/services/http/session'
import { Error } from 'app/session/join/partials'
import RolePrompt from 'app/session/join/role_prompt/role_prompt_connection'
import { verifySuccess } from 'app/session/session_actions'
import Footer from 'app/shared_components/footer/footer'
import SnugLogo from 'app/shared_components/snug_logo'
import { urlIds, urlTo } from 'app/sm/helpers'
import { SessionStorageUtils } from 'app/storage_utils'

const SMS_TIMEOUT_MINUTES = 5

const OtpInputContainer = styled(Flex)`
  input {
    border: ${({ theme }) => `1px solid ${theme.colors.gray500}`};

    width: ${theme.width[13]}px !important;
    height: ${theme.height[12]}px;
    font-size: ${({ theme }) => theme.fontSizes[11] + 'px'};
    font-weight: ${({ theme }) => theme.fontWeights[6]};
    border-radius: ${({ theme }) => theme.radii[6] + 'px'};
  }
`

const ContactInfoText = styled(TextSpan)`
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.fontWeights[4]};
`

const OtpInputContainerStyle = {
  width: '100%',
  display: 'flex',
}

const StyledResendButton = styled(Button)`
  font-size: 16px !important;
  font-weight: 400;
`

const MobileVerificationForm = ({ currentUser }) => {
  const history = useHistory()

  const [smsCode, setSMSCode] = useState('')
  const [posting, setPosting] = useState(false)
  const [verifyError, setVerifyError] = useState('')

  const isEmailVerification =
    window.location.pathname.includes('email') || currentUser.isOffshore

  const submit = () => {
    const { networkType, guidID } = currentUser
    setVerifyError('')
    const forwardUrl =
      SessionStorageUtils.getItem('forwardUrl') || urlTo('homeoverview')

    setPosting(true)

    verify({ guidID, networkType, smsCode })
      .then((res) => {
        verifySuccess(forwardUrl)(res)
      })
      .catch((err) => {
        setVerifyError(err.message || 'Failed to verify')
      })
      .finally(() => setPosting(false))
  }

  const resend = () => {
    setPosting(true)
    setVerifyError('')

    isEmailVerification
      ? resendOTPEmail()
          .then(() => {
            toast.success('Sent a new verification code')
          })
          .catch((err) => {
            toast.error(err.message)
          })
          .finally(() => setPosting(false))
      : resendOTPSMS()
          .then(() => {
            toast.success('Sent a new verification code')
          })
          .catch((err) => {
            toast.error(err.message)
          })
          .finally(() => setPosting(false))
  }

  const showUpdateDetailsLink = () => {
    const path = document.location.pathname
    return !path.includes('2fa')
  }

  const update = (smsCode) => setSMSCode(smsCode)

  return (
    <div className="auth-wrapper">
      <div className="auth-screen panel panel-default">
        <div className="panel-heading">
          <div className="app-header">
            <div className="app-header_left">
              <Button variant="flat" p="0" onClick={() => history.goBack()}>
                <i className="fa fa-chevron-left" />
              </Button>
            </div>
            <div className="app-header_center">
              <SnugLogo />
            </div>
            <div className="app-header_right" />
          </div>
        </div>
        <div className="panel-body">
          <div className="panel panel-default">
            <h3 className="mb30">Verify Account</h3>
            <div className="panel-body">
              <TextSpan>
                Please enter code we sent to{' '}
                <ContactInfoText>
                  {isEmailVerification ? currentUser.email : currentUser.mobile}
                </ContactInfoText>
              </TextSpan>

              <OtpInputContainer mt="18px" mb="22px">
                <OtpInput
                  containerStyle={OtpInputContainerStyle}
                  onChange={update}
                  numInputs={6}
                  separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                  value={smsCode}
                  shouldAutoFocus={true}
                  isInputNum={true}
                />
              </OtpInputContainer>

              <Box>
                Code valid for {SMS_TIMEOUT_MINUTES} minutes, Didn't get the
                code?{' '}
                <StyledResendButton
                  variant="linkBlue"
                  disableWhenLoading
                  onClick={resend}
                  showSpinnerBesideText={true}
                  disabled={posting}
                >
                  Resend
                </StyledResendButton>
              </Box>

              {showUpdateDetailsLink() && (
                <Box>
                  Details incorrect?{' '}
                  <Link to={urlTo(urlIds.join.updateRegistration)}>Update</Link>
                </Box>
              )}

              <Error errorFromComp={verifyError} />

              <Button
                disabled={posting}
                type="button"
                onClick={submit}
                mt={`${theme.space[4]}px`}
                width="100%"
                height="50px"
              >
                Continue
                <i className={posting ? 'fa fa-spinner fa-pulse' : ''} />{' '}
              </Button>
            </div>
            {<RolePrompt />}
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default MobileVerificationForm
