import {
  dateRangeFiltersFields,
  isDateRangeFilterApplied,
} from 'app/dashboard/team_activity/ActivityFilters/DateRangeFilter'
import {
  isManagerFilterApplied,
  ManagerAppliedFilterRenderer,
  ManagerFilterBody,
  managerFilterDataExtractor,
} from 'app/dashboard/team_activity/ActivityFilters/ManagerFilter'
import { standardUTCFormat } from 'app/sm/helpers'

export const filtersIds = {
  dateRange: 'dateRange',
  manager: 'manager',
  dateFilterBy: 'dateFilterBy',
}

export const LEASED_DATE_FILTER_KEY = 'leased'
export const LISTED_DATE_FILTER_KEY = 'listed'

export const filtersInitialState = {
  filters: {
    [filtersIds.dateRange]: {
      [dateRangeFiltersFields.start]: null,
      [dateRangeFiltersFields.end]: null,
    },
    [filtersIds.manager]: [],
    [filtersIds.dateFilterBy]: LISTED_DATE_FILTER_KEY,
  },
}

const filtersAppliedChecker = {
  [filtersIds.dateRange]: isDateRangeFilterApplied,
  [filtersIds.manager]: isManagerFilterApplied,
}

export const hasAppliedFilters = (filtersState) => {
  if (!filtersState) return false
  return Object.values(filtersIds).some((filterId) => {
    const filterValue = filtersState.filters[filterId]
    const filterAppliedChecker = filtersAppliedChecker[filterId]
    return filterAppliedChecker?.(filterValue)
  })
}

export const getFilters = (filtersState) => {
  const range = filtersState.filters[filtersIds.dateRange]?.dateRange
  const dateFilterBy = filtersState.filters[filtersIds.dateFilterBy]
  const managers = managerFilterDataExtractor(
    filtersState.filters[filtersIds.manager],
  ).managers
  return {
    range,
    managers,
    dateFilterBy,
  }
}

const getRenderedFiltersConfig = (filterId, filtersValues, updateFilterFn) => {
  const filterValues = filtersValues[filterId]
  return {
    filterValues,
    isApplied: filtersAppliedChecker[filterId](filterValues),
    setFilterValues: (values) => updateFilterFn(filterId, values),
  }
}

export const buildRenderedFilterConfig = (
  filtersValues,
  updateFilterFn,
  includeManagersFilter,
) => {
  return {
    [filtersIds.dateRange]: {
      filterId: filtersIds.dateRange,
      startFieldName: dateRangeFiltersFields.start,
      endFieldName: dateRangeFiltersFields.end,
      format: standardUTCFormat,
      ...getRenderedFiltersConfig(
        filtersIds.dateRange,
        filtersValues,
        updateFilterFn,
      ),
    },
    ...(includeManagersFilter && {
      [filtersIds.manager]: {
        AppliedFilterRenderer: ManagerAppliedFilterRenderer,
        FilterBody: ManagerFilterBody,
        filterTriggerPlaceholder: 'Property Manager',
        appliedFilterTitle: 'PM',
        ...getRenderedFiltersConfig(
          filtersIds.manager,
          filtersValues,
          updateFilterFn,
        ),
      },
    }),
  }
}
