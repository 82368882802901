import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import * as Display from 'app/components/display/display'
import * as Form from 'app/shared_components/form_component'
import * as textHelpers from 'app/utils/text/helpers'

const ArchiveConfirmationModal = ({
  clickArchive,
  closeModal,
  markAsArchivedOptions,
  onChangeMarkAsArchivedOptions,
}) => {
  const archivePropertyModalHeader = (
    <div>
      <div className="standard-subheading" />
    </div>
  )
  const archivePropertyModalBody = (
    <div className="pl10-desktop pr10-desktop mt20">
      <div className="mb20">
        <p>
          <p className="modal-title mb20">
            No renter has been marked as leased, are you sure you want to
            archive this property?
          </p>
          <p className="mb10 fw500">
            Why should I mark an applicant as leased?
          </p>
          <ul>
            <li>The property will be labeled as leased</li>
            <li>The property will stop receiving applications</li>
            <li>The renter will receive a utility referral</li>
            <li>
              The renter <span className="fw500">won’t</span> receive an offer
              email
            </li>
          </ul>
          <div>
            <Form.Checkbox
              label="Cancel future viewings, notify enquirers/registered and tenants"
              id="cancelFutureViewings"
              checked={markAsArchivedOptions.cancelFutureViewings}
              checkboxLabelSpanClassName="fs16 height-auto"
              onChange={onChangeMarkAsArchivedOptions('cancelFutureViewings')}
            />
            <div>
              <ReactTooltip id="declineTimeOutDesciption">
                {textHelpers.DECLINE_OTHER_HOUSEHOLD_TOOLTIP_CONTENT}
              </ReactTooltip>
            </div>
            <Form.Checkbox
              label="Decline household groups, except Approved Applicants"
              id="declineUnsuccessfulApplications"
              checkboxLabelSpanClassName="fs16 height-auto"
              checked={markAsArchivedOptions.declineUnsuccessfulApplications}
              onChange={onChangeMarkAsArchivedOptions(
                'declineUnsuccessfulApplications',
              )}
              toolTip={
                <div>
                  <i
                    className="icon-help-outline fs16 gray-light-color ibm"
                    data-tooltip-content=""
                    data-tooltip-id="declineTimeOutDesciption"
                  />
                </div>
              }
            />
          </div>
          <p className="pb10 mt40">
            Tip: This helps the accuracy of your business reporting and the
            earlier a renter receives a utility referral, the more likely they
            are to take it up.
          </p>
        </p>
        <div className="width100 display-flex modal-bottom-btn-container">
          <Display.GenericButton
            buttonType="tertiary"
            componentClass="width100 mt10 margin-profile-item-left col-sm-6"
            onClick={clickArchive}
            text="Archive anyway"
          />
          <Display.GenericButton
            componentClass="width100 mt10 margin-profile-item-right col-sm-6"
            onClick={() => closeModal()}
            text="Select applicant"
          />
        </div>
      </div>
    </div>
  )
  return (
    <Display.ModalWithScroll
      toggleModal={closeModal}
      modalHeader={archivePropertyModalHeader}
      modalBody={archivePropertyModalBody}
    />
  )
}

export default ArchiveConfirmationModal
