import type { FunctionComponent } from 'react'

import styled from 'styled-components'
import type { SpaceProps } from 'styled-system'

import { Alert } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  DOCUMENT_SENSITIVE_WARNING,
  PRIVACY_SECURITY_LEARN_MORE_LINK,
} from 'app/utils/text/helpers'

const StyledLearnMore = styled.a`
  text-decoration: underline !important;
`

type AlertSensitiveInfoProps = {
  hideLearnMore?: boolean
}

export const AlertSensitiveInfo: FunctionComponent<
  AlertSensitiveInfoProps & SpaceProps
> = ({ hideLearnMore = false, ...props }) => {
  return (
    <Alert
      actionIconBtnConfig={undefined}
      variant="blueWithBg"
      showLeftIcon={true}
      leftIconVariant="info"
      my={3}
      fontSize={theme.fontSizes.pRegular14}
      {...props}
    >
      <Text my={0} color={theme.colors.deepBlue}>
        {DOCUMENT_SENSITIVE_WARNING}{' '}
        {!hideLearnMore && (
          <StyledLearnMore
            target="_blank"
            rel="noopener noreferrer"
            href={PRIVACY_SECURITY_LEARN_MORE_LINK}
          >
            learn more
          </StyledLearnMore>
        )}{' '}
      </Text>
    </Alert>
  )
}
