import React from 'react'

import styled from 'styled-components'
import { color, typography, variant } from 'styled-system'

import Flex from 'app/components/design-system-components/Flex'

const FullWidthTabWrapper = ({ key, isSelected, children, ...props }) => {
  const selectionBgColor = props.selectionBgColor || 'paleBlue'
  const blurBgColor = props.blurBgColor
  return (
    <Flex
      key={key}
      flex="1 1 100%"
      justifyContent="center"
      alignItems="center"
      fontWeight={6}
      p="11px 20px"
      backgroundColor={isSelected ? selectionBgColor : blurBgColor}
    >
      {children}
    </Flex>
  )
}

function FullWidthDisplayNameWrapper({ isSelected, displayName, ...props }) {
  const variant = props.variant || 'blueBold'
  return (
    <NavLinkDisplayName
      variant={isSelected ? `${variant}.selected` : `${variant}.default`}
    >
      {displayName}
    </NavLinkDisplayName>
  )
}

function FullWidthLinksTabs({
  items,
  selectedItemId,
  propertyTools,
  className,
  ...props
}) {
  const mappedItems = items.map((i) => ({
    ...i,
    isSelected: i.id === selectedItemId,
  }))

  return (
    <Flex alignItems="center" justifyContent="center" className={className}>
      {mappedItems.map((item) => (
        <FullWidthTabWrapper
          key={item.id}
          isSelected={item.isSelected}
          {...props}
        >
          {item.ActionWrapper ? (
            <item.ActionWrapper {...item.actionProps}>
              <FullWidthDisplayNameWrapper
                isSelected={item.isSelected}
                displayName={item.displayName}
                {...props}
              />
            </item.ActionWrapper>
          ) : (
            <FullWidthDisplayNameWrapper
              isSelected={item.isSelected}
              displayName={item.displayName}
            />
          )}
        </FullWidthTabWrapper>
      ))}
    </Flex>
  )
}

const linkVariants = {
  variants: {
    blueBold: {
      selected: {
        color: 'deepBlue',
        fontWeight: 'bold',
      },
      default: {
        color: 'gray500',
        fontWeight: 'bold',
      },
    },
    graySelectedBold: {
      selected: {
        color: 'gray900',
        fontWeight: 'bold',
      },
      default: {
        color: 'gray900',
        fontWeight: 'normal',
      },
    },
  },
}

const NavLinkDisplayName = styled.span(
  {
    cursor: 'pointer',
  },
  variant(linkVariants),
)

export default FullWidthLinksTabs
