import React, { useEffect, useState } from 'react'

import { Formik } from 'formik'

import { Box, Flex } from 'app/components/design-system-components/index'
import { ModalHeader } from 'app/features/pm-messages/new-message/components/ModalHeader'
import { newMessagesModes } from 'app/features/pm-messages/new-message/constants'
import {
  buildRequestPayload,
  formFields,
  formValidation,
  transformApiErrorToFields,
} from 'app/features/pm-messages/new-message/form.utils'
import {
  FormBody,
  newBulkyFormBodyConfig,
  newIdentifiedMessagesFormBodyConfig,
} from 'app/features/pm-messages/new-message/FormBody'
import { API_ERRORS_PROPERTY } from 'app/forms/FieldWrapper'
import { convertTransApiErrorToStatus } from 'app/forms/utils/convertTransApiErrorToStatus'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import { sendBulkMessage } from 'app/services/http/teams/pm-messages'

const groupValidAndInvalidMessages = (messages) => {
  return messages.reduce(
    (accFilteredMessages, message) => {
      const isValid = !!message.enquirer.email
      return isValid
        ? {
            ...accFilteredMessages,
            valid: [...accFilteredMessages.valid, message],
          }
        : {
            ...accFilteredMessages,
            invalid: [...accFilteredMessages.invalid, message],
          }
    },
    { valid: [], invalid: [] },
  )
}

const modeDependentData = (modeConfig, baseInitialValues) => {
  const { mode, ...relatedConfig } = modeConfig
  if (mode === newMessagesModes.bulky) {
    return {
      initialValues: { ...baseInitialValues },
      formBodyConfig: newBulkyFormBodyConfig(relatedConfig.messagesCount),
    }
  }

  const { messages } = relatedConfig
  const { valid: validMessages, invalid: invalidMessages } =
    groupValidAndInvalidMessages(messages)

  const enquirerMessagesOptions = validMessages.map((m) => ({
    optionMeta: {
      id: m.messages.guidid,
      displayText: m.enquirer.email,
    },
    option: m,
  }))
  const initialValues = {
    ...baseInitialValues,
    [formFields.enquirerMessages]: enquirerMessagesOptions,
  }

  return {
    initialValues,
    formBodyConfig: newIdentifiedMessagesFormBodyConfig(invalidMessages),
  }
}

export const NewMessageModal = ({
  modeConfig,
  onDismissClick,
  onSendSuccessfully,
  teamId,
}) => {
  const [saveRes, setSaveRes] = useState(null)

  // to trigger closing modal only after "saveRes" is set
  useEffect(() => {
    if (!saveRes) return
    onSendSuccessfully(saveRes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveRes])

  const baseInitialValues = {
    [formFields.property]: null,
    [formFields.message]: '',
    [formFields.attachments]: [],
  }

  const { initialValues, formBodyConfig } = modeDependentData(
    modeConfig,
    baseInitialValues,
  )

  const sendMessage = (values, { setStatus }) => {
    setStatus({ apiErrors: {}, apiGeneralError: '' })
    const payload = buildRequestPayload(values, modeConfig)
    return sendBulkMessage(teamId, {
      ...payload,
    })
      .then((res) => {
        setSaveRes(res)
      })
      .catch(({ message, detailedError }) => {
        if (detailedError) {
          const transformedApiErrors = transformApiErrorToFields(detailedError)
          setStatus({
            [API_ERRORS_PROPERTY]:
              convertTransApiErrorToStatus(transformedApiErrors),
          })
        } else {
          setStatus({ apiGeneralError: message })
        }
      })
  }

  return (
    <Box className="modal-fullscreen" bg={theme.colors.overlay}>
      <ModalContentWrapper>
        <Flex width="100%" flex="1 1" overflowY="auto">
          <Box p={theme.space[7] + 'px'} width="100%">
            <ModalHeader onDismiss={onDismissClick} />
            <Box pb={theme.space[5] + 'px'}>
              <Formik
                initialValues={initialValues}
                onSubmit={sendMessage}
                validate={formValidation}
                validateOnBlur={true}
              >
                {(props) => (
                  <FormBody
                    formBag={props}
                    teamId={teamId}
                    saved={saveRes}
                    modeConfig={formBodyConfig}
                  />
                )}
              </Formik>
            </Box>
          </Box>
        </Flex>
      </ModalContentWrapper>
    </Box>
  )
}
