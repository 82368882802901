import React from 'react'

import $ from 'jquery'
import moment from 'moment'
import qs from 'qs'

import confirmationDetails from 'app/assets/icons/confirmation-details.svg'
import paperPlane from 'app/assets/icons/paper-plane.png'
import ConfirmationAlert from 'app/components/Ballot/Confirmation/ConfirmationAlert'
import DefaultButton from 'app/components/buttons/default/component'
import * as Display from 'app/components/display/display'
import * as Headers from 'app/components/display/text/standard_text/standard-headings/component'
import { StyledCopyableAppIDContainer } from 'app/match/apply/application_confirmation/component'
import CopyableText from 'app/shared_components/CopyableText'
import { ErrorMessage, isMobile } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import { DashboardModal } from 'app/sm/apply/components/apply_form'
import Spinner from 'app/sm/common/spinner'
import {
  ApplicationCategory,
  isStatusDeclined,
  isStatusWithdrawableByRenter,
  isStatusWithdrawn,
  urlTo,
} from 'app/sm/helpers'
import {
  ApplicantListComponent,
  ApplicationDetailsNumbersComponent,
} from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'

import 'app/match/apply/application_confirmation/style.scss'

class ApplyConfirmationContainerBallot extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      applyButtonClicked: false,
      modalOpened: '',
      spinner: false,
    }
  }

  componentDidMount() {
    const { fetchApplicantConfirmationSummary, fetchRenterSummary } = this.props
    const { applicationId } = this.props.match.params
    $('#index').addClass('no-navbar-menu')
    $('.app-content-container').addClass('background-grey')
    if (applicationId) {
      this.setState({
        spinner: true,
      })
      fetchRenterSummary(applicationId)
        .then(({ renterSummary }) => {
          this.setState({ renterSummary }, () => {
            const { application = {} } = this.state.renterSummary
            this.setState({
              application,
              spinner: false,
            })
          })
        })
        .catch((error) => {
          this.setState(
            { loadingError: error, spinner: false },
            this.reactToastify,
          )
        })
      fetchApplicantConfirmationSummary(applicationId).then(
        (confirmationSummary) => {
          const applicationStatus = confirmationSummary
            ? ApplicationCategory[confirmationSummary.application_status]
            : ''
          const shouldRedirect =
            isStatusDeclined(applicationStatus) ||
            isStatusWithdrawn(applicationStatus) ||
            !confirmationSummary
          if (shouldRedirect) {
            history.push(urlTo('NotFound'))
          }
        },
      )
    }

    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    if (
      curQueries.hasOwnProperty('action') &&
      curQueries.action === 'movedApplicationWithdrawConfirmation'
    ) {
      this.toggleModal('withdrawOrDelete')
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    nextProps.setBackUrl('/')
  }

  componentWillUnmount() {
    $('#index').removeClass('no-navbar-menu')
    $('.app-content-container').removeClass('background-grey')
  }

  onApplyButtonClick = (applicationGUID, applicantId, isSecondaryApplicant) => {
    const { confirmationSummary } = this.props
    const applicationStatus = confirmationSummary.application_status
      ? ApplicationCategory[confirmationSummary.application_status]
      : undefined
    if (
      isStatusWithdrawableByRenter(applicationStatus) &&
      !isSecondaryApplicant
    ) {
      this.withdrawApplication(applicationGUID)
    } else if (
      isStatusWithdrawableByRenter(applicationStatus) &&
      isSecondaryApplicant
    ) {
      this.withdrawSecondaryApplicant(applicationGUID, applicantId)
    }
  }

  onContinueButtonClick() {
    this.props.resetState()
    history.push(urlTo('applications'))
  }

  toggleModal = (modalName) => {
    if (this.state.modalOpened === modalName) {
      this.setState({ modalOpened: '' })
    } else if (this.state.modalOpened === '') {
      this.setState({ modalOpened: modalName })
    }
  }

  withdrawApplication = (applicationGuid) => {
    const { acceptWithdraw } = this.props
    this.setState({
      applyButtonClicked: true,
    })
    acceptWithdraw(
      applicationGuid,
      () =>
        this.setState({
          applyButtonClicked: false,
        }),
      () =>
        this.setState({
          applyButtonClicked: false,
        }),
    )
  }

  withdrawSecondaryApplicant(applicationGuid, applicantId) {
    const { acceptSecondaryWithdraw } = this.props
    acceptSecondaryWithdraw(
      applicationGuid,
      applicantId,
      () =>
        this.setState({
          applyButtonClicked: false,
        }),
      () =>
        this.setState({
          applyButtonClicked: false,
        }),
    )
  }

  handleBackgroundCheckClick() {
    history.push(`${urlTo('BackgroundCheck')}?from=rentersummary`)
  }

  render() {
    const { confirmationSummary, backgroundCheck } = this.props
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const {
      applicant_guid: applicantGuid,
      application_guid: applicationGuid,
      application_slug: applicationSlug,
      applicants,
      address,
      applicant_primary: applicantPrimary,
    } = confirmationSummary || {}
    const applicationStatus =
      confirmationSummary &&
      ApplicationCategory[confirmationSummary.application_status]
    const { modalOpened, application = {}, loadingError, spinner } = this.state
    const { weeklyRent = 0, term = '', leaseStartDate = '' } = application
    const formattedStartDate = moment(leaseStartDate).format('DD MMM YYYY')

    const { identityVerification = {} } = backgroundCheck

    const backgroundCheckHelpText = identityVerification.result
      ? `You have a Background Check that's not attached to your application.`
      : 'Improve your application by adding a Background Check.'

    const backgroundCheckButtonText = identityVerification.result
      ? 'Review & Attach'
      : 'Add Background Check'

    const isExpired =
      confirmationSummary.expiredAt &&
      moment(confirmationSummary.expiredAt).isBefore(moment())

    const onPressFinalizeApplication = () => {
      history.push(
        `${urlTo('ProofOfIncome')}?${new URLSearchParams(
          curQueries,
        ).toString()}`,
      )
    }

    return (
      <div>
        <Display.CenterContentContainer componentClass="col-xs-12 background-grey p0">
          <div className="ilustration-box">
            <img className="img-size" src={paperPlane} alt="Confirmation" />
          </div>

          <h3 className="heading fw600 pl0 pr0">
            Your ballot entry has been submitted
          </h3>

          {loadingError && <ErrorMessage error={loadingError} />}
        </Display.CenterContentContainer>

        {spinner && <Spinner />}

        {!spinner && (
          <div>
            <Display.CenterContentContainer componentClass="col-xs-12 background-grey p0">
              <div className="mt15">
                <p>
                  The property manager has been notified of your application{' '}
                  <StyledCopyableAppIDContainer>
                    (App ID: <CopyableText text={applicationSlug} />)
                  </StyledCopyableAppIDContainer>{' '}
                  for {address.friendlyName}. We’ll let you know when they
                  respond.{' '}
                  <a
                    href={urlTo('applicationSummary', {
                      applicationId: applicationGuid,
                    })}
                  >
                    View your application
                  </a>
                </p>
              </div>
            </Display.CenterContentContainer>
            <Display.CenterContentContainer
              containerClass="mt30"
              componentClass="background-grey text-align-center"
            >
              {isMobile() ? (
                <img
                  src="https://snugco.s3.ap-southeast-2.amazonaws.com/assets/img/HV_Affordable_Ballot_Timeline_Web_350x400.png"
                  alt="varc process mobile"
                />
              ) : (
                <img
                  src="https://snugco.s3.ap-southeast-2.amazonaws.com/assets/img/HV_Affordable_Ballot_Timeline_Web_600x350.png"
                  alt="varc process desktop"
                />
              )}
            </Display.CenterContentContainer>
            <Display.CenterContentContainer containerClass="mt30">
              <ConfirmationAlert
                onPressFinalizeApplication={onPressFinalizeApplication}
              />
            </Display.CenterContentContainer>

            <Display.CenterContentContainer
              componentClass="application-standard-shadow-box col-xs-12 summary-box-on-mobile"
              containerClass="mt30"
            >
              <Headers.SectionHeader text="My Details" textClass="fw600" />
              <Display.GreyContainer containerClass="application-details-container ml25">
                <Display.ContainerHeader
                  text="Preference"
                  isEditable={false}
                  icon={confirmationDetails}
                />
                <div className="display-flex flex-wrap-wrap">
                  <ApplicationDetailsNumbersComponent
                    title="Rent"
                    text={`${weeklyRent}`}
                    componentClassName="width50p align-items-start flex-basis-25p"
                    extraText="/w"
                    titleClassName="preference-label"
                    textClassName="preference-content"
                  />
                  <ApplicationDetailsNumbersComponent
                    title="Term"
                    text={term}
                    componentClassName="width50p align-items-start flex-basis-25p"
                    extraText="months"
                    titleClassName="preference-label"
                    textClassName="preference-content"
                  />
                  <ApplicationDetailsNumbersComponent
                    title="Lease Start"
                    text={formattedStartDate}
                    componentClassName="width50p align-items-start flex-basis-25p"
                    titleClassName="preference-label"
                    textClassName="preference-content"
                  />
                  <ApplicationDetailsNumbersComponent
                    title="Application ID"
                    text={applicationSlug}
                    componentClassName="width50p align-items-start flex-basis-25p"
                    titleClassName="preference-label"
                    textClassName="preference-content"
                  />
                </div>
                {applicants.length > 0 && (
                  <ApplicantListComponent
                    application={application}
                    applicants={applicants}
                    avatarContainerClass="mobile-hide align-items-center"
                  />
                )}
              </Display.GreyContainer>
            </Display.CenterContentContainer>
            <Display.BottomContentContainer>
              <Display.CenterContentContainer componentClass="width100">
                <div className="bottom-buttons-mobile-wrapper">
                  {!isExpired && (
                    <div>
                      {isStatusWithdrawableByRenter(applicationStatus) && (
                        <div className="apply-form-buttons">
                          <button
                            className="btn btn-left xs-text-center back-button-link summary-grey-button-class width-auto withdraw-button-spacing"
                            onClick={() => this.toggleModal('withdrawOrDelete')}
                          >
                            <span className="mt8">Withdraw</span>
                          </button>
                        </div>
                      )}
                      {modalOpened === 'withdrawOrDelete' && (
                        <DashboardModal
                          toggleModal={() =>
                            this.toggleModal('withdrawOrDelete')
                          }
                          onButtonClick={this.onApplyButtonClick}
                          applicationId={applicationGuid}
                          label="Withdraw"
                          disabled={this.state.applyButtonClicked}
                          applicantId={applicantGuid}
                          isSecondaryApplicant={!applicantPrimary}
                        />
                      )}
                    </div>
                  )}
                  <DefaultButton
                    text="My applications"
                    onClick={() => this.onContinueButtonClick()}
                    size="large"
                    buttonType="default"
                  />
                </div>
              </Display.CenterContentContainer>
            </Display.BottomContentContainer>
          </div>
        )}
      </div>
    )
  }
}

export default ApplyConfirmationContainerBallot
