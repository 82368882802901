export const activityPeriods = {
  lastSeven: 'lastSeven',
  lastWeek: 'lastWeek',
  currentMonth: 'currentMonth',
  lastMonth: 'lastMonth',
  all: 'all',
  custom: 'custom',
}

export const activityPeriodConfig = {
  [activityPeriods.lastSeven]: {
    title: 'Last 7 days',
  },
  [activityPeriods.lastWeek]: {
    title: 'Last week',
  },
  [activityPeriods.currentMonth]: {
    title: 'Month to date',
  },
  [activityPeriods.lastMonth]: {
    title: 'Last calendar month',
  },
  [activityPeriods.all]: {
    title: 'All time',
  },
  [activityPeriods.custom]: {
    title: 'Custom',
  },
}
