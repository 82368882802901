import styled from 'styled-components'
import {
  border,
  boxShadow,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  typography,
} from 'styled-system'

const Box = styled.div(
  {
    boxSizing: 'border-box',
  },
  position,
  space,
  color,
  typography,
  border,
  boxShadow,
  layout,
  flexbox,
  grid,
)

export default Box
