export const DEFAULT_STANDARD_BOND_DURATION_WEEKS = '4'

export const STANDARD_BOND_DURATION_4_WEEKS = '4'
export const STANDARD_BOND_DURATION_6_WEEKS = '6'

const CORR_4_WEEKS_BOND_DURATION_ARRAY_INDEX = 4
const CORR_6_WEEKS_BOND_DURATION_ARRAY_INDEX = 5

export const calcBondAmount = (weeklyRent, bondDurationWeeks) => {
  return weeklyRent * Number(bondDurationWeeks)
}

export const convertBondDurationToCorrespondingArrayIndex = (bondDuration) => {
  return bondDuration === STANDARD_BOND_DURATION_6_WEEKS
    ? CORR_6_WEEKS_BOND_DURATION_ARRAY_INDEX
    : CORR_4_WEEKS_BOND_DURATION_ARRAY_INDEX
}

export const removeSpacesInText = (text) => {
  return text.split(' ').join('')
}

export const leaseDetailsRentFrequency = [
  { guid: 0, name: 'Unset' },
  { guid: 1, name: '1 week' },
  { guid: 2, name: '2 weeks' },
  { guid: 3, name: '1 month' },
]

export const leaseLengthOptions = [
  { guid: 1, name: '12 months', value: 12 },
  { guid: 2, name: '6 months', value: 6 },
  { guid: 3, name: '52 weeks', value: 52 },
  { guid: 4, name: '26 weeks', value: 26 },
]

export const waterInclusionOptions = [
  { guid: 0, name: 'Unset' },
  { guid: 1, name: 'Excluded, renter pays all costs' },
  { guid: 2, name: 'Included, owner pays costs' },
  { guid: 3, name: 'Split, renter pays usage charges' },
]

export const FirstPaymentDefaultUnset = 0
export const FirstPaymentDefaultWeekly = 1
export const FirstPaymentDefaultFortnightly = 2
export const FirstPaymentDefaultMonthly = 3

export const WaterInclusionUnset = 0
export const WaterInclusionExcluded = 1
export const WaterInclusionIncluded = 2
export const WaterInclusionSplit = 3
