import React from 'react'

import { Alert, Button, Flex } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { history } from 'app/shared_components/router'
import { urlIds, urlTo } from 'app/sm/helpers'

function UserNotAuthorizedShowTeamMembersList({ teamSlug = '' }) {
  return (
    <Alert variant="blueWithBg" mb={5}>
      <Flex alignItems="center">
        Team administrators can change these settings.{' '}
        <Button
          variant="linkDeepBlue"
          onClick={() => {
            history.push(urlTo(urlIds.teams.settings.members, { teamSlug }))
          }}
          fontSize={theme.fontSizes[6] + 'px'}
          ml={3}
        >
          Team members {'>'}
        </Button>
      </Flex>
    </Alert>
  )
}

export default UserNotAuthorizedShowTeamMembersList
