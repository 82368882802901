import React from 'react'

import { Link } from 'react-router-dom'

import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'
import Amenity from 'app/sm/ppp/property_components/property_form/amenities/amenity'
import { isCurrentRoute } from 'app/sm/router_helpers'

function bindCorrespondingContinueUrl(id, state) {
  if (isCurrentRoute('adFlowAmenities')) {
    const { guidID: offerId } = (state.offers || []).find((o) => o.isActive)
    if (offerId !== undefined) {
      return urlTo('adFlowPortals', { propertyId: id, offerId })
    }
  }
  return urlTo('updatePropertyPhotos', { id })
}

function bindCorrespondingBackUrl(id) {
  return isCurrentRoute('adFlowAmenities')
    ? urlTo('adFlowDescription', { propertyId: id })
    : urlTo('updatePropertyBasics', { id })
}

const Amenities = ({
  ad,
  id,
  error,
  updateCheckbox,
  updateProperty,
  onContinueClicked,
  openSubs,
  toggleOpenSubs,
  commonAmenities,
  moreAmenities,
  isChecked,
  state,
  clicked,
}) => (
  <div className="sm-property-create amenities">
    <div className="mobile-only">
      <Link
        className="btn btn-transparent btn-left xs-text-center wa"
        to={bindCorrespondingBackUrl(id)}
      >
        <i className="icon-arrow-left left"></i>
        <span>Back</span>
      </Link>
    </div>
    <div className="section-title">
      <div className="left column">
        <h3>Amenities</h3>
        <p className="gray-color mt10 mb0">
          Select the amenities your property makes available to renters.
        </p>
      </div>
      {/*<div className="right"></div>*/}
    </div>
    <div className="two-col-box sm64">
      <div className="col-first xs-second">
        <div className="row">
          <div className="col-sm-12">
            <div className="sm-checkbox-list">
              {[commonAmenities, moreAmenities].map((amenities, i) => (
                <div className="sm-checkbox-list__section" key={i}>
                  <h4>{i === 0 ? 'Most Common' : 'More Amenitieis'}</h4>
                  {amenities.map(({ id, name, subs }) => (
                    <Amenity
                      key={id}
                      id={id}
                      name={name}
                      subs={subs}
                      openSubs={openSubs}
                      toggleOpenSubs={toggleOpenSubs}
                      updateCheckbox={updateCheckbox}
                      isChecked={isChecked}
                      isCommonAmenitie={i === 0}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className={error ? 'alert alert-danger' : ''}>
              <div>{error}</div>
            </div>
          </div>
        </div>
        <div className="row pt25 mobile-form-button">
          <div className="col-sm-5 pb5 tablet-only">
            <Link
              className="btn btn-transparent btn-left xs-text-center wa"
              to={bindCorrespondingBackUrl(id)}
            >
              <i className="icon-arrow-left left"></i>
              <span>Back</span>
            </Link>
          </div>
          <div className="col-sm-7 pb5">
            <button
              className="btn"
              disabled={clicked}
              onClick={() => {
                onContinueClicked()
                updateProperty(
                  id,
                  bindCorrespondingContinueUrl(id, state),
                )(state)
              }}
            >
              Continue <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
            </button>
          </div>
        </div>
      </div>
      <div className="col-second xs-first">
        <ul className="snug-tips">
          <li>Avoid disputes</li>
          {ad !== null && (
            <li>
              We will update the ad automatically after the property is changed
            </li>
          )}
        </ul>
      </div>
    </div>
  </div>
)

export default Amenities
