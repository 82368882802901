import React, { useContext } from 'react'

import { InputField } from 'app/components/design-system-components/inputs'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { login } from 'app/services/http/session'
import { FIELD_PASSWORD } from 'app/session/join/join-snug.constants'
import { JoinSnugContext } from 'app/session/join/JoinContext'
import { BaseForm } from 'app/session/join/partials'
import StyledFieldWrapper from 'app/session/join/partials/styled_field'
import { authSuccess } from 'app/session/session_actions'
import getParameter from 'app/shared_components/parameter_parser'
import { urlTo } from 'app/sm/helpers'
import { SessionStorageUtils } from 'app/storage_utils'

const EnterPasswordForm = () => {
  const { accountCheckedKey, setError } = useContext(JoinSnugContext)

  const initialValues = {
    password: '',
  }

  const getSearchParam = (field) => {
    let value = getParameter(field)
    return value ? value : urlTo('homeoverview')
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setError('')

    const credentials = {
      password: values.password,
      [accountCheckedKey.name]: accountCheckedKey.value,
    }

    let forwardUrl = SessionStorageUtils.getItem('forwardUrl')
    let redirect = forwardUrl || getSearchParam('redirect')

    return login(credentials)
      .then((res) => {
        authSuccess(redirect)(res)
      })
      .catch((err) => {
        setSubmitting(false)

        setError(err.message || 'Login error')
      })
  }

  const validate = (values) => {
    return formValidationBuilder((field, val) => {
      return {
        required: !val && 'Please enter your account password',
      }
    })(values)
  }

  const bodyRenderer = () => {
    return (
      <>
        <StyledFieldWrapper id={FIELD_PASSWORD} name={FIELD_PASSWORD}>
          <InputField
            id={FIELD_PASSWORD}
            name={FIELD_PASSWORD}
            inputProps={{
              placeholder: 'Password',
              type: 'password',
              autoComplete: 'password',
            }}
            height="50px"
            autoFocus
          />
        </StyledFieldWrapper>
      </>
    )
  }

  return (
    <BaseForm
      initialValues={initialValues}
      submitHandler={handleSubmit}
      validator={validate}
      bodyRenderer={bodyRenderer}
    />
  )
}

export default EnterPasswordForm
