import React from 'react'

import { Redirect } from 'react-router-dom'

import AgencyInviteAccept from 'app/bond_cover/agency/agency_invite_accept_connection'
import { ErrorCurrentUserIsNotInvitee } from 'app/constants/error_codes'
import { ErrorMessage } from 'app/shared_components/helpers'
import { urlIds, urlTo } from 'app/sm/helpers'

export default function AgencyInvite(props) {
  const {
    invite,
    inviteData,
    inviteeEmail,
    currentUserEmail,
    loggedIn,
    error,
    errorCode,
    logout,
    createLiteAccount,
  } = props

  const { guidID, isOffshore } = inviteData || {}
  let registrationUrl = `${urlTo(urlIds.join.index)}?invitation=${guidID}`
  if (isOffshore) {
    registrationUrl += `&offshore=true`
  }

  let errorMsg = error
  if (errorCode === ErrorCurrentUserIsNotInvitee) {
    errorMsg = (
      <p>
        {`${errorMsg}. The invite is for ${inviteeEmail} and you are logged in as ${currentUserEmail}. `}
        <span className="linkStyle-blue linkStyle" onClick={logout}>
          Log out.
        </span>
      </p>
    )
  }
  return (
    <div>
      {invite === '' && <div>We're verifying your invitation...</div>}
      {invite === 'valid' && loggedIn === true && (
        <AgencyInviteAccept createLiteAccount={createLiteAccount} />
      )}
      {invite === 'valid' && loggedIn === false && (
        <Redirect push to={registrationUrl} />
      )}
      {invite === 'invalid' && (
        <div className="two-col-box-flex-row sm64">
          <div className="col-first">
            <ErrorMessage error={errorMsg} />
          </div>
        </div>
      )}
    </div>
  )
}
