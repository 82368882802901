import { connect } from 'react-redux'

import {
  fetchTeamManagerList,
  fetchTeamProperties,
} from 'app/bond_cover/agency/agency_actions'
import { fetchPropertyViewings } from 'app/sm/inspections/inspections_actions'
import { generateViewingRun } from 'app/sm/viewings_new_run/action'
import ViewingNewRunContainer from 'app/sm/viewings_new_run/container'

const mapStateToProps = ({ session, ViewingRun }) => ({
  teams: session.teams || [],
  viewingRun: ViewingRun.viewingRun,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTeamManagerList: (agencyGUID) =>
    dispatch(fetchTeamManagerList(agencyGUID)),
  fetchTeamProperties: (agencyGUID, filters) =>
    dispatch(fetchTeamProperties(agencyGUID, filters)),
  generateViewingRun: (viewingRunRequest) =>
    dispatch(generateViewingRun(viewingRunRequest)),
  fetchPropertyViewings: (params) => dispatch(fetchPropertyViewings(params)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewingNewRunContainer)
