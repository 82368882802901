import React from 'react'

import * as helpers from 'app/sm/helpers'

const OccupantListComponent = ({ applicants, avatarContainerClass }) => (
  <div className="applicants-container">
    {applicants &&
      applicants.map((applicant, index) => {
        return (
          <div className="applicant-container" key={index}>
            <div className="applicant-upper-container">
              <div
                className={`household-applicant-table avatar-container mt0 ${avatarContainerClass}`}
              >
                <div
                  className="avatar application-avatar"
                  style={{ backgroundImage: `url(${applicant.avatar})` }}
                >
                  {!applicant.avatar && (
                    <span className="app-avatar-initial-position default-font-family">
                      {helpers.capitalizeFirstLetter(
                        applicant.firstName.substring(0, 1),
                      )}
                      {helpers.capitalizeFirstLetter(
                        applicant.lastName.substring(0, 1),
                      )}
                    </span>
                  )}
                </div>
              </div>
              <div className="applicant-info-container">
                <h4 className="font-size-18 fw500">
                  {helpers.capitalizeFirstLetter(applicant.firstName)}{' '}
                  {helpers.capitalizeFirstLetter(applicant.lastName)}{' '}
                  <span className="fw500 color-light-grey">(Occupant)</span>
                </h4>
                <div className="applicant-info-sub-container">
                  <div>{applicant.mobileNumber}</div>
                  {applicant.mobileNumber && applicant.email && (
                    <div className="spot-seperator" />
                  )}
                  <div>{applicant.email}</div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
  </div>
)

export default OccupantListComponent
