import React, { useContext } from 'react'

import { Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import { mediaQueries } from 'app/components/design-system-components/styles/variables'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { SnugNotifierWrapper } from 'app/services/snugNotifier'
import {
  STEP_ENTER_DETAILS_CONFIRMATION,
  STEP_ENTER_EMAIL,
  STEP_ENTER_MOBILE,
} from 'app/session/join/join-snug.constants'
import { JoinSnugContext } from 'app/session/join/JoinContext'
import { Captcha, Error, SubmitButton } from 'app/session/join/partials'

const StyledFormContainer = styled(Box)`
  min-width: 490px;
  ${mediaQueries.sm} {
    min-width: 100%;
  }
`

const BaseForm = (props) => {
  const { currentStep, gCaptchaRef } = useContext(JoinSnugContext)

  const {
    initialValues,
    submitHandler,
    validator,
    bodyRenderer,
    submitButtonText = 'Continue',
    disableSubmit = false,
  } = props

  const renderCaptcha = () => {
    if (
      ![
        STEP_ENTER_DETAILS_CONFIRMATION,
        STEP_ENTER_MOBILE,
        STEP_ENTER_EMAIL,
      ].includes(currentStep)
    ) {
      return
    }

    return (
      <Captcha
        size={
          [STEP_ENTER_MOBILE, STEP_ENTER_EMAIL].includes(currentStep) &&
          'invisible'
        }
        captchaRef={gCaptchaRef}
      />
    )
  }

  return (
    <StyledFormContainer>
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={initialValues}
        onSubmit={submitHandler}
        validate={validator}
      >
        {({ isSubmitting, ...props }) => (
          <Form>
            {bodyRenderer(props)}

            <Box mt={theme.space[1]} ml={'8px'}>
              <Link className="fs14" to="/reset">
                Forgot account details?
              </Link>
            </Box>

            {renderCaptcha()}

            <Error />

            <SubmitButton
              disabled={isSubmitting || disableSubmit}
              text={submitButtonText}
            />
          </Form>
        )}
      </Formik>
      <SnugNotifierWrapper />
    </StyledFormContainer>
  )
}

export default BaseForm
