import React from 'react'

import moment from 'moment'
import DateTime from 'react-datetime'
import styled from 'styled-components'

import circleCross from 'app/assets/icons/circle-cross.png'
import AvatarAndNameDropdown from 'app/shared_components/AvatarAndNameDropdown'

const PreferenceOptionContainer = ({ children }) => {
  return <div className="display-flex mt20">{children}</div>
}

const PropertyManagerContainer = styled.div`
  max-width: 300px;
  .preference-label {
    width: 200px;
  }
`

const FilterOptions = ({
  onDateChanged,
  date,
  managerList = [],
  showAvatarAndNameComponent = false,
  managerInputValue = '',
  allManagersSelected = [],
  onClickManagerDropdown,
  onChangeManagerInputValue,
  removeEmailFromList,
  viewingRunModeForTeam,
}) => {
  const yesterday = moment().subtract(1, 'day')
  const disablePastDate = (current) => {
    return current.isAfter(yesterday)
  }
  const filteredManagerList = managerList.filter((manager) => {
    return !allManagersSelected.find(
      (allManager) => allManager.managerGUID === manager.managerGUID,
    )
  })
  const isManagerInputDisabled =
    viewingRunModeForTeam === 'single'
      ? allManagersSelected && allManagersSelected.length > 0
      : allManagersSelected && allManagersSelected.length > 6
  return (
    <div className="mt20">
      <div className="sub-title">1. Select date</div>
      <PreferenceOptionContainer>
        <div className="mr15 font-size-18 preference-label">Date</div>
        <DateTime
          dateFormat="ddd, D MMM YYYY"
          timeFormat={false}
          inputProps={{ placeholder: '', className: `datePickerStyle` }}
          onChange={onDateChanged}
          closeOnSelect={true}
          value={date.format('ddd, D MMM YYYY')}
          isValidDate={disablePastDate}
        />
      </PreferenceOptionContainer>
      <PreferenceOptionContainer>
        <div className="mr15 font-size-18 preference-label">Inspector</div>
        <PropertyManagerContainer className="input-box mb0 pt0">
          {allManagersSelected.length > 0 &&
            allManagersSelected.map((emailItem, index) => {
              return (
                <div
                  className={`font-size-16 fw500 mr10 p10 background-grey-mobile-excluded display-flex email-icon ${
                    emailItem.status === 'invalid' ? 'invalid-email' : ''
                  }`}
                >
                  <div>{emailItem.email}</div>
                  <img
                    className="email-remove"
                    onClick={() => removeEmailFromList(index)}
                    src={circleCross}
                    alt="remove"
                    role="button"
                    tabIndex="0"
                  />
                </div>
              )
            })}
          <input
            className="email-input"
            value={managerInputValue}
            onChange={(e) => onChangeManagerInputValue(e.target.value)}
            disabled={isManagerInputDisabled}
            placeholder={
              isManagerInputDisabled ? '' : 'Search for team members'
            }
          />
          <AvatarAndNameDropdown
            showDropdown={showAvatarAndNameComponent}
            dropdownItems={filteredManagerList}
            showStatusPills={true}
            dropdownItemClickEventEnabled={true}
            dropdownItemClickEventHandler={onClickManagerDropdown}
          />
        </PropertyManagerContainer>
      </PreferenceOptionContainer>
    </div>
  )
}
export default FilterOptions
