const _defaultState = {
  TBD: {},
}

const SignatureSign = (state = _defaultState, action) => {
  let { type } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    default:
      return newState
  }
}

export default SignatureSign
