import React from 'react'

import parsePhoneNumber from 'libphonenumber-js'
import qs from 'qs'
import styled from 'styled-components'

import {
  LoadMore,
  PropertyCardList,
} from 'app/agency/public_properties/container'
import iconAlert from 'app/assets/icons/alert.svg'
import AgencyInfoHeader from 'app/components/agency_info_header/component'
import RegisterConfirmation from 'app/pages/apply/teams/checkin/components/registerConfirmation'
import RegisterModal from 'app/pages/apply/teams/checkin/components/registerModal'
import ViewingCard from 'app/pages/apply/teams/checkin/components/viewingCard'
import * as snugNotifier from 'app/services/snugNotifier'
import Spinner from 'app/sm/common/spinner'
import * as phoneNumberHelpers from 'app/utils/phonenumber/helpers'
import * as vaccinationHelpers from 'app/utils/vaccination/helpers'
import * as viewingsHelpers from 'app/utils/viewings/helpers'

const CheckInContainer = styled.div`
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 16px;
`

const NoViewingsFoundContainer = styled.div`
  margin: 15px;
  font-weight: bold;
`

const AU_COUNTRY_CODE = 'AU'
const SUCCESSFUL_CHECK_IN = 'Check-in was successful'
const FROM_LOAD_MORE_VIEWINGS = true
const VIEWING_PAGINATION_LIMIT = 12

class AgencyPublicViewings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      propertySelected: {},
      viewingSelected: {},
      cursor: '',
      vaccinationQuestionsContainerOpened: false,
      qrCheckInEnabled: true,
      modalFormValue: {
        firstName: '',
        lastName: '',
        email: '',
        weeklyIncome: '',
        mobilePhone: '',
        currentResidenceStartYear: '',
        currentResidenceStartMonth: '',
        weeklyHousingPayment: '',
        hasRecentlyVisitedOverseasOrAffectedArea: '',
        hasColdSymptoms: '',
        whereDoYouLive: '',
        covid19VaccinationStatus: '',
      },
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: '',
        weeklyIncome: '',
        currentResidenceStartYear: '',
        currentResidenceStartMonth: '',
        weeklyHousingPayment: '',
        hasRecentlyVisitedOverseasOrAffectedArea: '',
        hasColdSymptoms: '',
        whereDoYouLive: '',
        covid19VaccinationStatus: '',
      },
      registeredInfo: {
        firstName: '',
        lastName: '',
        mobilePhone: '',
        hasRecentlyVisitedOverseasOrAffectedArea: '',
        hasColdSymptoms: '',
        covid19VaccinationStatus: '',
      },
      registrationDone: false,
      allViewingsForAgency: [],
      initialFetchDone: false,
      disableCheckinButton: false,
      isFromAutoCheckInProcess: false,
    }
  }

  componentDidMount() {
    document.querySelector('#index').classList.add('no-navbar-menu')
    const {
      match: {
        params: { agencySlug },
      },
    } = this.props
    const { cursor = '' } = this.state

    if (agencySlug) {
      this.callFetchAgencyPublicViewings(agencySlug, cursor)
    }
  }

  componentWillUnmount() {
    document.querySelector('#index').classList.remove('no-navbar-menu')
  }

  onChangeRadioGroup = (radioGroupID) => {
    return (event) => {
      const { target } = event
      const { id } = target
      this.setState({
        modalFormValue: {
          ...this.state.modalFormValue,
          [radioGroupID]: id,
        },
        errors: { ...this.state.errors, [radioGroupID]: '' },
      })
    }
  }

  onChangeRadioGroupCovid19VaccinationStatus = (radioGroupID) => {
    return (event) => {
      const { target } = event
      const { id } = target
      this.setState({
        modalFormValue: {
          ...this.state.modalFormValue,
          [radioGroupID]: vaccinationHelpers.vaccinationStatusMap[id],
        },
        errors: { ...this.state.errors, [radioGroupID]: '' },
      })
    }
  }

  populateUserFields = () => {
    const { currentUser } = this.props
    if (currentUser && currentUser.mobile) {
      const parsedNumber = parsePhoneNumber(
        currentUser.mobile.replace(/\s/g, ''),
        AU_COUNTRY_CODE,
      )
      this.setState({
        modalFormValue: {
          ...this.state.modalFormValue,
          firstName: currentUser.firstName || '',
          lastName: currentUser.lastName || '',
          mobilePhone: phoneNumberHelpers.formatPhoneNumber(
            parsedNumber.number,
          ),
          email: currentUser.email || '',
        },
      })
    }
  }

  onCheckInClick = (viewing, property) => {
    this.resetModalForm()
    this.setState(
      {
        showModal: true,
        propertySelected: property,
        viewingSelected: viewing,
      },
      () => {
        this.populateUserFields()
      },
    )
  }

  onDropDownValueChange = (field) => {
    return (data) => {
      this.setState({
        modalFormValue: {
          ...this.state.modalFormValue,
          [field]: data.value,
        },
        errors: {
          ...this.state.errors,
          [field]: data.error,
        },
      })
    }
  }

  onModalValueChange = (field) => {
    return (data) => {
      this.setState({
        modalFormValue: {
          ...this.state.modalFormValue,
          [field]: data.value,
        },
        errors: {
          ...this.state.errors,
          [field]: data.error,
        },
      })
    }
  }

  onRegisterClick = () => {
    const { registerForEmailViewing } = this.props
    const { modalFormValue, viewingSelected, isFromAutoCheckInProcess } =
      this.state
    const { guidID } = viewingSelected

    const errors = this.checkForErrorsInForm()

    this.setState(
      {
        errors: {
          ...this.state.errors,
          ...errors,
        },
      },
      () => {
        if (
          !Object.keys(this.state.errors).find(
            (field) => this.state.errors[field] !== '',
          )
        ) {
          const payload = {
            ...modalFormValue,
            hasRecentlyVisitedOverseasOrAffectedArea:
              modalFormValue.hasRecentlyVisitedOverseasOrAffectedArea.toString(),
            hasColdSymptoms: modalFormValue.hasColdSymptoms.toString(),
            covid19VaccinationStatus:
              modalFormValue.covid19VaccinationStatus.toString(),
            selfCheckin: true,
            autoCheckIn: isFromAutoCheckInProcess,
          }
          if (guidID) {
            this.setState({ disableCheckinButton: true })
            registerForEmailViewing(guidID, payload)
              .then(({ data }) => {
                snugNotifier.success(SUCCESSFUL_CHECK_IN)
                this.setState({
                  showModal: false,
                  registrationDone: true,
                  registeredInfo: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    mobilePhone: data.mobilePhone,
                    viewingDate: data.viewingDate,
                    propertyFriendlyName: data.propertyFriendlyName,
                    hasRecentlyVisitedOverseasOrAffectedArea:
                      data.hasRecentlyVisitedOverseasOrAffectedArea === 'true',
                    hasColdSymptoms: data.hasColdSymptoms === 'true',
                  },
                })
              })
              .catch((apiError) => {
                snugNotifier.error(apiError)
              })
              .finally(() => {
                this.setState({ disableCheckinButton: false })
              })
          }
        }
      },
    )
  }

  callFetchAgencyPublicViewings = (
    agencySlug,
    cursor = '',
    fromLoadMoreViewings = false,
  ) => {
    const { fetchAllViewingsForCheckin } = this.props
    fetchAllViewingsForCheckin(agencySlug, cursor, VIEWING_PAGINATION_LIMIT)
      .then((result) => {
        if (result.errors) {
          result.errors.map((error) => snugNotifier.error(error.message))
        }
        if (fromLoadMoreViewings) {
          const previousViewings = this.state.allViewingsForAgency
          const updatedProperties = [
            ...previousViewings,
            ...result.data.viewingsList,
          ]
          this.setState({
            allViewingsForAgency: updatedProperties,
            responseMetadata: result.meta,
            cursor: result.meta.pagination.next_cursor,
          })
        } else {
          this.setState({
            allViewingsForAgency: result.data.viewingsList,
            responseMetadata: result.meta,
            cursor: result.meta.pagination.next_cursor,
            agency: {
              agencyAddress: result.data.agencyProfile.contactAddress,
              brandingBannerURL: result.data.agencyProfile.brandingBannerURL,
              brandingLogoURL: result.data.agencyProfile.brandingLogoURL,
              agencyContactNumber: result.data.agencyProfile.contactNumber,
              agencyEmail: result.data.agencyProfile.contactEmail,
              agencyName: result.data.agencyProfile.contactName,
            },
          })
        }
      })
      .then(() => {
        const curQueries = qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        })
        const { viewingGUID = '' } = curQueries
        if (viewingGUID) {
          const { allViewingsForAgency } = this.state
          if (allViewingsForAgency?.length) {
            const selectedViewings = allViewingsForAgency.filter(
              ({ viewing }) => viewing.guidID === viewingGUID,
            )
            if (selectedViewings.length) {
              this.setState(
                {
                  isFromAutoCheckInProcess: true,
                  viewingSelected: selectedViewings[0].viewing,
                },
                () => {
                  this.setState(
                    {
                      showModal: true,
                    },
                    () => {
                      this.populateUserFields()
                    },
                  )
                },
              )
            } else {
              snugNotifier.error('Sorry, the requested viewing was not found.')
            }
          }
        }
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
      .finally(() => {
        this.setState({
          initialFetchDone: true,
        })
        if (fromLoadMoreViewings) {
          this.setState({
            loadMoreSpinner: false,
          })
        }
      })
  }

  checkForErrorsInForm = () => {
    const { modalFormValue } = this.state
    let errors = {}
    Object.keys(modalFormValue)
      .filter((value) => value !== 'viewing')
      .filter((value) => value !== 'weeklyIncome')
      .filter((value) => value !== 'currentResidenceStartMonth')
      .filter((value) => value !== 'currentResidenceStartYear')
      .filter((value) => value !== 'weeklyHousingPayment')
      .filter((value) => value !== 'mobilePhone')
      .filter((value) => value !== 'preferredDate')
      .filter((value) => value !== 'preferredTime')
      .filter((value) => value !== 'covid19VaccinationStatus')
      .filter((value) => value !== 'hasRecentlyVisitedOverseasOrAffectedArea')
      .filter((value) => value !== 'hasColdSymptoms')
      .filter((value) => value !== 'recentOverseasVisit')
      .forEach((value) => {
        if (this.state.modalFormValue[value] === '') {
          errors = Object.assign(errors, {
            [value]: 'This field cannot be empty',
          })
        }
      })
    if (
      this.state.errors.preferredDate ===
      viewingsHelpers.oneDayLaterErrorMessage
    ) {
      return errors
    } else if (
      (this.state.modalFormValue['preferredTime'] === '' &&
        this.state.modalFormValue['preferredDate'] !== '' &&
        this.state.errors.preferredDate !==
          viewingsHelpers.oneDayLaterErrorMessage) ||
      (this.state.modalFormValue['preferredDate'] === '' &&
        this.state.modalFormValue['preferredTime'] !== '')
    ) {
      errors = Object.assign(errors, {
        preferredTime: 'Both time and date required',
        preferredDate: 'Both time and date required',
      })
    } else {
      errors = Object.assign(errors, {
        preferredDate: '',
        preferredTime: '',
      })
    }
    return errors
  }

  loadMore = (cursor) => {
    const {
      match: {
        params: { agencySlug },
      },
    } = this.props
    this.setState({ loadMoreSpinner: true })
    this.callFetchAgencyPublicViewings(
      agencySlug,
      cursor,
      FROM_LOAD_MORE_VIEWINGS,
    )
  }

  resetModalForm = () => {
    this.setState({
      modalFormValue: {
        firstName: '',
        lastName: '',
        email: '',
        weeklyIncome: '',
        mobilePhone: '',
        currentResidenceStartYear: '',
        currentResidenceStartMonth: '',
        weeklyHousingPayment: '',
        hasRecentlyVisitedOverseasOrAffectedArea: '',
        hasColdSymptoms: '',
        whereDoYouLive: '',
        covid19VaccinationStatus: '',
      },
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: '',
        weeklyIncome: '',
        currentResidenceStartYear: '',
        currentResidenceStartMonth: '',
        weeklyHousingPayment: '',
        hasRecentlyVisitedOverseasOrAffectedArea: '',
        hasColdSymptoms: '',
        whereDoYouLive: '',
        covid19VaccinationStatus: '',
      },
    })
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  toggleVaccinationQuestionsContainerOpened = () => {
    const { vaccinationQuestionsContainerOpened } = this.state
    this.setState({
      vaccinationQuestionsContainerOpened: !vaccinationQuestionsContainerOpened,
    })
  }

  render() {
    const {
      showModal = false,
      modalFormValue,
      errors,
      viewingSelected,
      registeredInfo,
      registrationDone,
      agency,
      allViewingsForAgency,
      responseMetadata,
      loadMoreSpinner,
      initialFetchDone,
      disableCheckinButton,
      vaccinationQuestionsContainerOpened,
    } = this.state
    const cursor =
      responseMetadata &&
      responseMetadata.pagination &&
      responseMetadata.pagination.next_cursor

    return (
      <div>
        {!registrationDone ? (
          <div>
            <AgencyInfoHeader agency={agency} />
            <CheckInContainer>Check-in</CheckInContainer>
            <div className="mb40">
              Please select the property and time you’re checking in for.
            </div>
            {!initialFetchDone ? (
              <Spinner />
            ) : (
              <PropertyCardList>
                {allViewingsForAgency && allViewingsForAgency.length > 0 ? (
                  allViewingsForAgency
                    .filter((property) => property.agencyID !== '')
                    .map((viewingAndProperty) => {
                      const { property, viewing } = viewingAndProperty
                      return (
                        <ViewingCard
                          key={viewing.guidID}
                          property={property}
                          viewing={viewing}
                          primaryButtonFunc={this.onCheckInClick}
                        />
                      )
                    })
                ) : (
                  <NoViewingsFoundContainer>
                    <div>Check-ins open 15 minutes prior</div>
                  </NoViewingsFoundContainer>
                )}
              </PropertyCardList>
            )}
            {cursor && (
              <div className="text-center bc-hub-dropdown-text mra width-100">
                {loadMoreSpinner ? (
                  <i className="fa fa-spinner fa-pulse fs24 mt20" />
                ) : (
                  <LoadMore cursor={cursor} loadMore={this.loadMore} />
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            <AgencyInfoHeader agency={agency} showAgencyContactInfo={false} />
            <RegisterConfirmation registeredInfo={registeredInfo} />
          </div>
        )}
        {showModal && (
          <RegisterModal
            modalFormValue={modalFormValue}
            errors={errors}
            onModalValueChange={this.onModalValueChange}
            onDropDownValueChange={this.onDropDownValueChange}
            onChangeRadioGroup={this.onChangeRadioGroup}
            toggleModal={this.toggleModal}
            primaryButtonAction={this.onRegisterClick}
            viewingSelected={viewingSelected}
            title="Check-in"
            headingInfo={<></>}
            includeLiving
            showCurrentRenterInfo={false}
            primaryDisabled={disableCheckinButton}
            onChangeRadioGroupCovid19VaccinationStatus={
              this.onChangeRadioGroupCovid19VaccinationStatus
            }
            toggleVaccinationQuestionsContainerOpened={
              this.toggleVaccinationQuestionsContainerOpened
            }
            vaccinationQuestionsContainerOpened={
              vaccinationQuestionsContainerOpened
            }
          />
        )}
      </div>
    )
  }
}

export default AgencyPublicViewings
