import React from 'react'

import { Link } from 'react-router-dom'
import SignaturePad from 'snugco_signature_pad/dist/signature_pad.js'

import headerImg06 from 'app/assets/icons/header-img-06.png'
import getParameter from 'app/shared_components/parameter_parser'
import { urlTo } from 'app/sm/helpers'
class RenterSignature extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      requestID: null,
      agreedToTerms: false,
      hasUnpaidBondClaim: false,
      disabled: false,
      clicked: false,
      error: false,
    }
  }

  UNSAFE_componentWillMount() {
    let { getSecondaryTenantSummary } = this.props
    let requestID = getParameter('requestid')
    this.setState({ requestID })
    getSecondaryTenantSummary(requestID)
  }

  componentDidMount() {
    let canvas = document.querySelector('canvas')
    this.signaturePad = new SignaturePad(canvas)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ clicked: false, disabled: false })
    let { request, signature } = nextProps
    if (request) {
      let { guidID } = request
      this.setState({ requestID: guidID })
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    nextState.disabled = !(nextState.agreedToTerms && !nextState.clicked)
  }

  clear() {
    this.signaturePad.clear()
  }

  signByRenter = () => {
    return () => {
      let { signByRenter } = this.props
      let { agreedToTerms, hasUnpaidBondClaim, requestID } = this.state
      let isPrimary = false
      if (this.signaturePad.isEmpty()) {
        this.setState({ error: true })
      } else {
        this.setState({ error: false, clicked: true })
        let signature = {
          signatureSvg: this.signaturePad.toDataURL('image/svg+xml'),
          agreedToTerms: agreedToTerms,
          hasUnpaidBondClaim: !hasUnpaidBondClaim,
        }
        signByRenter(requestID, signature, isPrimary)
      }
    }
  }

  toggleCheckbox(field) {
    return (event) => {
      let { checked } = event.target
      this.setState({ [field]: checked })
    }
  }

  render() {
    let { quote, signature, apiError } = this.props
    let {
      agreedToTerms,
      hasUnpaidBondClaim,
      disabled,
      clicked,
      error,
      requestID,
    } = this.state
    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <div className="mobile-only">
            <Link
              to={urlTo('bondCoverLeaseeSign', { requestID })}
              className="btn btn-transparent btn-left xs-text-center wa"
            >
              <i className="icon-arrow-left left"></i>
              <span>Back</span>
            </Link>
          </div>
          <div className="ilustration-box">
            <img src={headerImg06} />
          </div>

          <h3 className="mb15">Your signature please</h3>

          <p className="pb5">
            Please sign to verify that the information is correct in your
            application and confirm that you request Snug to:
          </p>

          <ul className="pl20 lh13">
            <li>
              Arrange a refund of my bond of{' '}
              <b>
                $
                {quote && quote.bondAmount && quote.premium
                  ? (quote.bondAmount - quote.premium).toFixed(2)
                  : ''}
              </b>{' '}
            </li>
            <li>Amend my lease to accept BondCover.</li>
          </ul>

          <p>
            You understand that if a valid bond claim is made, you must pay the
            full amount of the claim to the owner or property manager.
          </p>

          <p>
            Please ensure your Current Rental and Property Manager information
            are correct as signing will lock in those details of your
            application.
          </p>

          <div className="text-right pb20">
            <button
              className="btn btn-small btn-gray wa pl20 pr20"
              onClick={() => this.clear()}
            >
              Clear
            </button>
          </div>

          <div className="signature-pad-wrapper">
            <canvas className="canvas"></canvas>
          </div>

          <div className="cb-wrapper mt15">
            <p> By signing you agree: </p>
            <div className="cb-wrapper-row">
              <input
                type="checkbox"
                checked={agreedToTerms}
                onChange={this.toggleCheckbox('agreedToTerms')}
              />
              <div>
                To the{' '}
                <a
                  target="_blank"
                  href="http://snug.com/bondcover/terms"
                  rel="noreferrer"
                >
                  BondCover Terms &amp; Condition
                </a>
              </div>
            </div>
            <div className="cb-wrapper-row">
              <input
                type="checkbox"
                checked={hasUnpaidBondClaim}
                onChange={this.toggleCheckbox('hasUnpaidBondClaim')}
              />
              <div>
                I have no unpaid Bond Claim more than my bond within the last 3
                years
              </div>
            </div>
          </div>

          <div className={error ? 'alert alert-danger' : 'hidden'}>
            {error ? 'Please Sign Above' : ''}
          </div>

          <div className={apiError ? 'alert alert-danger' : 'hidden'}>
            {apiError}
          </div>
          <div className="row pt25 mobile-form-button">
            <div className="col-sm-5 pb5 tablet-only">
              <Link
                to={urlTo('bondCoverLeaseeSign', { requestID })}
                className="btn btn-transparent btn-left xs-text-center wa"
              >
                <i className="icon-arrow-left left"></i>
                <span>Back</span>
              </Link>
            </div>
            <div className="col-sm-7 pb5">
              <button
                className="btn"
                disabled={disabled}
                onClick={this.signByRenter()}
              >
                Save
                <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RenterSignature
