import React from 'react'

const BlueLinkComponent = ({ label, titleClass, onClick }) => {
  return (
    <div
      className={`blue-link-style font-size-16 ${titleClass}`}
      onClick={onClick}
    >
      {label}
    </div>
  )
}

export default BlueLinkComponent
