import React from 'react'

import { uniqBy } from 'lodash'
import moment from 'moment'
import qs from 'qs'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import questionIcon from 'app/assets/icons/question-icon.svg'
import {
  fetchAgencyStatus,
  fetchAllPropertyFutureViewings,
  fetchTeamPropertiesByRecentApplication,
  fetchTeamProperty,
  fetchUtilityReferralStatus,
  getPropertyBallotsDetail,
  postRunPropertyBallots,
} from 'app/bond_cover/agency/agency_actions'
import AgencyFilterSection from 'app/components/agency_search_section/connection'
import Button from 'app/components/buttons/default/component'
import {
  Box,
  Flex,
  LoadingSection,
  Button as NewButton,
  TextSpan,
} from 'app/components/design-system-components'
import { Button as SystemButton } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import { FlipToFrontRounded } from 'app/components/design-system-components/icons/actions'
import { MailRoundedOutline } from 'app/components/design-system-components/icons/content'
import { ChildCareRounded } from 'app/components/design-system-components/icons/places'
import { Pet } from 'app/components/design-system-components/icons/snug-specific'
import {
  StarRoundedOutline,
  StarRoundedSolid,
} from 'app/components/design-system-components/icons/social'
import InviteToApplyModal from 'app/components/design-system-components/InviteToApply/InviteToApplyModal'
import { MaskedWrappingLink } from 'app/components/design-system-components/links/MaskedWrappingLink'
import ApplicationNoteFlatStyle from 'app/components/display/application_note/component_flatstyle'
import * as Display from 'app/components/display/display'
import {
  defaultEditViewingModalValues,
  durationOptionsNumbers,
} from 'app/components/display/edit_viewing_modal/viewing_modal_data'
import { searchAddressEmailNamePhonePlaceholder } from 'app/components/text/placeholderText'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages, {
  translateErrorCodeToMessage,
} from 'app/constants/error_messages'
import {
  ApplicationWorkflowContainer,
  devicesMediaMaxWidths,
  DroppedContainer,
  ResponsiveFlex,
  ShowDropdownContainer,
  StyledApplicationSortPos,
  StyledAppRowInfo,
  StyledIcon,
} from 'app/dashboard/app-row-styles'
import ApplicantScore from 'app/dashboard/components/applicantScore'
import DisplayText from 'app/dashboard/components/DisplayText'
import DragandReset from 'app/dashboard/components/DragAndReset'
import ExcludeViewingScheduleModal from 'app/dashboard/components/exclude_viewing_schedule_modal'
import ArchiveConfirmationModal from 'app/dashboard/components/modal_before_archive'
import { NoApplicationRow } from 'app/dashboard/components/no_application_row'
import * as prospectSummaryHelpers from 'app/dashboard/prospect-summary-helpers'
import { IntegrationsTeamSettingsContext } from 'app/dashboard/settings_context'
import ShareWithOwnerModal from 'app/dashboard/shareWithOwnerModal'
import { setPropertyFilters } from 'app/dashboard/team_overview_actions'
import { InactiveTeamModal } from 'app/features/teams/applications/application-row/inactive-team-modal'
import {
  BallotsRunModal,
  BallotsRunRecorder,
} from 'app/features/teams/applications/ballots-run'
import { RegisterApplicantModal } from 'app/features/teams/applications/registerApplicant'
import { BallotMessageModal } from 'app/features/teams/applications/share-applications/BallotSendMessageModal'
import { ShareApplicationsModal } from 'app/features/teams/applications/share-applications/ShareApplicationsModal'
import {
  CancelUtilityReferralModal,
  isProviderAllowedToCancel,
} from 'app/features/teams/applications/utility-referral/CancelUtilityReferralModal'
import { SendUtilityReferralModal } from 'app/features/teams/applications/utility-referral/SendUtilityReferralModal'
import { SendUtilityReferralToolbar } from 'app/features/teams/applications/utility-referral/SendUtilityReferralToolbar'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { fetchLeasingOfferInfo } from 'app/match/leaseAttachment/action'
import { BasicMultiButtonModal } from 'app/modals/basic_modal'
import {
  changeApplicationOrder,
  sendViewingInviteToApplicant,
  shortListApplication,
} from 'app/services/http/applications'
import { getTeamSettings } from 'app/services/http/teams/settings'
import * as snugNotifier from 'app/services/snugNotifier'
import { setBackUrl } from 'app/shared_components/actions'
import AvatarAndNameDropdown from 'app/shared_components/AvatarAndNameDropdown'
import { ErrorMessage, isMobile } from 'app/shared_components/helpers'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'
import { StatusLabel } from 'app/shared_components/sending_email'
import Spinner from 'app/sm/common/spinner'
import * as helpers from 'app/sm/helpers'
import {
  addOnScrollLoadListeners,
  ApplicationCategory,
  ApplicationStatusCategory,
  capitalizeFirstLetter,
  filtersHaveChanged,
  isBreakpointReached,
  isPropertyDraft,
  isPropertyLeased,
  isPropertyTakingApplications,
  isScrollAtEnd,
  isStatusAccepted,
  isStatusDeclinableByManager,
  isStatusDeclined,
  isStatusOfferable,
  isStatusOffered,
  isStatusPMWithdrawn,
  isStatusShortlisted,
  parseDataUtil,
  removeOnScrollLoadListeners,
  setStringLength,
  trimFileLength,
  urlTo,
  utcFormatTimeStamp,
} from 'app/sm/helpers'
import AddOwnerModal from 'app/sm/inspections/components/add_owner_modal'
import ShortlistAttendee from 'app/sm/inspections/components/shortlist_attendee'
import {
  addCurrentOwners,
  fetchAgencyApplicantsElasticSearchResult,
  fetchCurrentOwners,
  removeCurrentOwners,
  updateCurrentOwners,
} from 'app/sm/inspections/inspections_actions'
import { getConsoleCloudIntegration } from 'app/sm/plugins/plugins_access'
import { addViewing } from 'app/sm/ppp/viewing_actions'
import {
  fetchApplicationNotesWithLimit,
  fetchNotesWithLimit,
  markProgressApplicationAsApproved,
  markProgressApplicationAsLeased,
  sendScheduleViewingFromApplication,
  sendUtilityReferral,
} from 'app/sm/progress_application/action'
import {
  ApplicationWorkflowStatusLabel,
  ApplicationWorkflowTextStatus,
  ApplicationWorkflowToolbar,
} from 'app/sm/properties/components/property_search_filter_util'
import {
  archiveProperty,
  persistManager,
} from 'app/sm/properties/properties_actions'
import {
  declineApplication,
  inviteToApplyApplicant,
  moveTo,
  offerApplication,
  shortlistApplication,
  unshortlistApplication,
  withdrawOfferForApplication,
} from 'app/sm/renter_applications/renter_applications_actions'
import { applicationsAttributesIDs } from 'app/sm/team_viewing_preference/applications-attributes.constants'
import {
  EMPLOYMENT_VERIFICATION_INCOMPLETE,
  EMPLOYMENT_VERIFIED,
  IDENTITY_DOCS_NOT_RECEIVED,
  IDENTITY_DOCS_RECEIVED,
  INCOME_INCOMPLETE,
  INCOME_RECEIVED,
  REFERENCE_INCOMPLETE,
  REFERENCE_RECEIVED,
} from 'app/utils/applications/completeness-statuses'
import * as applicationHelpers from 'app/utils/applications/helpers'
import {
  autoWithdrawnExplanation,
  isApplicationDetailsAccessible,
  isAutoWithdrawn,
  isManuallyWithdrawn,
  isStatusEligibleForUtilityReferral,
  isWithdrawn,
  mapApplicationWithRecentlyDeclinedFlag,
} from 'app/utils/applications/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import {
  DATE_DAY_SHORT_STRING_MONTH_YEAR,
  getCurrentDate,
  STANDARD_DATETIME,
} from 'app/utils/datetime/helpers'
import * as flagsUtil from 'app/utils/flags'
import { withMultiSelect } from 'app/utils/hooks/useMultiSelect'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  promiseWrapperFactory,
} from 'app/utils/loading-states'
import * as stringHelpers from 'app/utils/strings/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import * as viewingsHelpers from 'app/utils/viewings/helpers'
import { isNewWorkflowEnabled, isPMSortEnabled } from 'config/features'

const NoPropertyContainer = () => (
  <div className="display-flex property-item-container pb10 pt10 border-bottom-1px height80 justify-content-center align-items-center">
    There are no properties.
  </div>
)

class PropertyButtonsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeModal: '',
      addViewingModal: {
        ...defaultEditViewingModalValues,
      },
      markAsArchivedOptions: {
        cancelFutureViewings: true,
        declineUnsuccessfulApplications: false,
      },
      showExcludeViewingScheduleModal: false,
      cancelLastUtilityLeadModalConfig: {
        isOpen: false,
        config: {},
      },
      inviteToApplyModalOpen: false,
      runBallotsModalConfig: {
        isOpen: false,
        config: {},
      },
    }
    this.archiveProperty = this.archiveProperty.bind(this)
  }

  onChangeMarkAsArchivedOptions = (checkboxID) => {
    return (event) => {
      const { checked } = event.target
      const { markAsArchivedOptions } = this.state
      this.setState({
        ...this.state,
        markAsArchivedOptions: {
          ...markAsArchivedOptions,
          [checkboxID]: checked,
        },
      })
    }
  }

  onClickAddApplication = () => {
    const { property } = this.props
    const offerId = prospectSummaryHelpers.selectPropertyOfferId(property)
    history.push(
      `${urlTo('addApplicationByManager', {
        propertyId: offerId,
      })}`,
    )
  }

  onClickAddOwner = (OwnerModalCategory) => {
    const { toggleModal, text } = this.props
    toggleModal(helpers.modalNameCollection.addOwnerModal, {
      text,
      OwnerModalCategory,
    })
  }

  onClickAddTenants = (tenantModalCategory) => {
    const { toggleModal, text } = this.props
    toggleModal(helpers.modalNameCollection.addTenantsModal, {
      text,
      tenantModalCategory,
    })
  }

  onClickAddViewing = () => {
    const { addViewingModal = {} } = this.state
    const { addViewing, property = {}, refetchTheViewingList } = this.props
    const offerId = prospectSummaryHelpers.selectPropertyOfferId(property)
    const { startDate, startTime, duration, ...viewingOptions } =
      addViewingModal || {}
    const mergedStartDate = `${moment(startDate).format(
      helpers.dateWithDash,
    )} ${startTime}`
    const formattedStartDate = helpers.getStandardFormattedDate(
      moment(mergedStartDate, helpers.dateTimeWithDash).utc(),
      helpers.utcFormatTimeStamp,
    )
    if (property && property.guidID) {
      addViewing(
        offerId,
        formattedStartDate,
        durationOptionsNumbers[duration],
        false,
        false,
        viewingOptions,
      )
        .then((data) => {
          this.setState({ activeModal: '' })
          snugNotifier.success('Viewing Successfully added!')
          if (refetchTheViewingList) {
            refetchTheViewingList()
          }
        })
        .catch((error) => {
          if (
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForProperty] ||
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForManager]
          ) {
            if (window.confirm(viewingsHelpers.conflictingTimeMessage)) {
              addViewing(
                offerId,
                formattedStartDate,
                durationOptionsNumbers[duration],
                false,
                true,
                viewingOptions,
              )
                .then((data) => {
                  this.setState({ activeModal: '' })
                  snugNotifier.success('Viewing Successfully added!')
                  refetchTheViewingList()
                })
                .catch((error) => {
                  snugNotifier.error(error)
                })
            }
          } else {
            snugNotifier.error(error)
          }
        })
    }
  }

  onClickArchive = () => {
    this.setState({
      activeModal: 'ArchiveConfirmationModal',
    })
  }

  onClickEditOnlistDetails = () => {
    const { property, teamSlug } = this.props
    const propertyId = prospectSummaryHelpers.selectPropertyGUID(property)
    const offerGUID = prospectSummaryHelpers.selectPropertyOfferId(property)
    history.push(
      `${urlTo('onlistDetails', {
        teamSlug,
      })}?property=${propertyId}&offerGUID=${offerGUID}`,
    )
  }

  archiveProperty = (checkboxID) => {
    const propertyGUID = prospectSummaryHelpers.selectPropertyGUID(
      this.props.property,
    )
    const { archiveTimeAdder } = this.props
    const { cancelFutureViewings, declineUnsuccessfulApplications } =
      this.state.markAsArchivedOptions
    this.props
      .archiveProperty(
        propertyGUID,
        cancelFutureViewings,
        declineUnsuccessfulApplications,
      )
      .then(() => {
        this.setState(
          {
            activeModal: '',
          },
          () => {
            archiveTimeAdder()
          },
        )
      })
      .catch(this.props.failCallback)
  }

  runPropertyBallot = () => {
    const propertyGUID = prospectSummaryHelpers.selectPropertyGUID(
      this.props.property,
    )

    this.setState({
      runBallotsModalConfig: {
        ...this.state.runBallotsModalConfig,
        config: {
          ...this.state.runBallotsModalConfig.config,
          loading: true,
        },
      },
    })

    this.props
      .postRunPropertyBallots(propertyGUID)
      .then(() => {
        const getBallotJobDetail = (propertyGUID) => {
          this.props
            .getPropertyBallotsDetail(propertyGUID)
            .then(({ run }) => {
              const { status } = run || {}
              if (status !== 'Succeeded' && status !== 'Failed') {
                helpers.wait(1000).then(() => getBallotJobDetail(propertyGUID))
              } else {
                this.setState({
                  runBallotsModalConfig: {
                    ...this.state.runBallotsModalConfig,
                    isOpen: false,
                    config: {
                      ...this.state.runBallotsModalConfig.config,
                      loading: false,
                    },
                  },
                })
                this.props.refreshApplicationsList()
              }
            })
            .catch((error) => {
              this.setState({
                runBallotsModalConfig: {
                  ...this.state.runBallotsModalConfig,
                  config: {
                    ...this.state.runBallotsModalConfig.config,
                    error: error?.message,
                    loading: false,
                  },
                },
              })
            })
        }
        getBallotJobDetail(propertyGUID)
      })
      .catch((error) => {
        this.setState({
          runBallotsModalConfig: {
            ...this.state.runBallotsModalConfig,
            config: {
              ...this.state.runBallotsModalConfig.config,
              error: error?.message,
              loading: false,
            },
          },
        })
      })
  }

  updateAddViewingModal = (modalValues) => {
    this.setState(
      {
        ...this.state,
        addViewingModal: {
          ...this.state.addViewingModal,
          ...modalValues,
        },
      },
      () => this.onClickAddViewing(),
    )
  }

  onClickExcludedViewings = () => {
    this.setState({
      showExcludeViewingScheduleModal: true,
    })
  }

  hideExcludedViewingsModal = () => {
    this.setState({
      showExcludeViewingScheduleModal: false,
    })
  }

  renderCancelUtilityReferralModal = () => {
    const {
      cancelLastUtilityLeadModalConfig: {
        isOpen,
        config: { lastSentUtilityLead },
      },
    } = this.state
    if (!isOpen) return

    const {
      property = {},
      currentTeam,
      applications = [],
      onPropertyDataNeedUpdate,
    } = this.props
    const application = applications.find(
      (app) => app.guidID === lastSentUtilityLead.application_guid,
    )

    const onClose = (succeeded) => {
      this.setState({
        cancelLastUtilityLeadModalConfig: {
          isOpen: false,
          config: {},
        },
      })
      if (succeeded) {
        setTimeout(() => {
          onPropertyDataNeedUpdate()
        }, 5000)
        snugNotifier.success(
          'Sending cancel referral request is being processed',
        )
      }
    }

    return (
      isOpen && (
        <CancelUtilityReferralModal
          utilityLead={lastSentUtilityLead}
          currentTeam={currentTeam}
          application={application}
          propertyGUID={prospectSummaryHelpers.selectPropertyGUID(property)}
          onCloseModal={onClose}
        />
      )
    )
  }

  toggleInviteToApplyModalOpen = () => {
    const { inviteToApplyModalOpen } = this.state
    this.setState({
      inviteToApplyModalOpen: !inviteToApplyModalOpen,
    })
  }

  renderBallotsRunModal = () => {
    const {
      runBallotsModalConfig: { isOpen, config },
    } = this.state

    const { ballotPropertyDetail, applicationTeamSettings } = this.props

    const { count = 0, completedAppsNum = 0 } = ballotPropertyDetail || {}

    const applicationSuccessfulBallotsSize =
      applicationTeamSettings &&
      applicationTeamSettings[
        applicationsAttributesIDs.applicationSuccessfulBallotsSize
      ]
    const applicationReserveBallotsSize =
      applicationTeamSettings &&
      applicationTeamSettings[
        applicationsAttributesIDs.applicationReserveBallotsSize
      ]

    if (!isOpen) return

    const onClose = () => {
      this.setState({
        runBallotsModalConfig: {
          isOpen: false,
          config: {},
        },
      })
    }

    const { loading = false, error = '' } = config || {}

    return (
      isOpen && (
        <BallotsRunModal
          isEligible={true}
          householdApplications={completedAppsNum}
          toggleModal={onClose}
          applicationSuccessfulBallotsSize={applicationSuccessfulBallotsSize}
          applicationReserveBallotsSize={applicationReserveBallotsSize}
          successAction={() => {
            this.runPropertyBallot()
          }}
          loading={loading}
          error={error}
        />
      )
    )
  }

  render() {
    const {
      activeModal,
      markAsArchivedOptions,
      showExcludeViewingScheduleModal,
      inviteToApplyModalOpen,
    } = this.state
    const slug = prospectSummaryHelpers.selectPropertySlug(this.props.property)
    const propertyGUID = prospectSummaryHelpers.selectPropertyGUID(
      this.props.property,
    )
    const propertyStatus = prospectSummaryHelpers.selectPropertyStatus(
      this.props.property,
    )
    const { showQuickLink = false, teamSlug } = this.props
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { stage: currentStage, property: specificPropertyID } = curQueries
    const isBrowsingSpecificProperty = !!specificPropertyID
    const {
      text,
      property = {},
      toggleModal,
      showSentUtilitiy = false,
      sentUtilityLeads = [],
      activeOffer = {},
      viewingEntryNoticeEnabled,
      keyCode,
      isOwnerModalActive,
      currentTeam,
      fetchAgencyApplicantsElasticSearchResult,
      fetchCurrentOwners,
      addCurrentOwners,
      updateCurrentOwners,
      ownerModalCategory,
      removeCurrentOwners,
      isEntryDetailsDisplay = false,
      fetchAllPropertyFutureViewings,
      mostProgressedApplicationStatus,
      defaultSelectedViewingDurationEnabled,
      defaultSelectedViewingDuration,
      viewingScheduleEnabled,
      applications = [],
      keySets,
      ballotPropertyDetail,
    } = this.props

    const { runRecord: ballotRanRecord } = ballotPropertyDetail || {}

    const {
      createdAt: ballotRanAt = '',
      user: ballotRanBy = {},
      id = '',
      status: ballotRanStatus,
    } = ballotRanRecord || {}

    const lastSentUtilityLead =
      sentUtilityLeads &&
      sentUtilityLeads.sort(
        (refA, refB) =>
          moment(refB.created_at).valueOf() - moment(refA.created_at).valueOf(),
      )[0]
    const {
      bedrooms = 0,
      bathrooms = 0,
      offers = [],
      address,
      chosenPropertyID = property.guidID,
      agencyID,
    } = property
    const { friendlyName = '', suburb = '' } = address || ''
    const propertyAddress = `${friendlyName}, ${suburb}`
    const offerInfo = prospectSummaryHelpers.selectActiveOffer(offers)
    const {
      availableFrom = '',
      weeklyRentDisplay = '',
      ballotsEnabled,
      weeklyRent = 0,
    } = offerInfo
    const formattedRent = helpers.pickRentDisplayAndFormat(
      weeklyRent,
      weeklyRentDisplay,
    )
    const availableDate = moment(availableFrom).format('DD MMM YYYY')
    const shouldShowViewingsLink =
      currentStage ===
        prospectSummaryHelpers.prospectSummaryPageStage.application ||
      (isBrowsingSpecificProperty &&
        currentStage !==
          prospectSummaryHelpers.prospectSummaryPageStage.viewing)
    const isCurrentStageViewing =
      currentStage === prospectSummaryHelpers.prospectSummaryPageStage.viewing
    const propertyTools = [
      {
        text: 'Edit',
        enableTool: isPropertyDraft(propertyStatus),
        onClick: () => {
          window.open(urlTo('updatePropertyBasics', { id: propertyGUID }))
        },
      },
      {
        text: 'View property',
        enableTool: isPropertyTakingApplications(propertyStatus),
        onClick: () => {
          window.open(urlTo('applyRedirect', { slug }))
        },
      },
      {
        text: 'Edit access details',
        enableTool: isPropertyTakingApplications(propertyStatus),
        onClick: this.onClickEditOnlistDetails,
      },
      {
        text: 'Excluded from schedule',
        enableTool: viewingScheduleEnabled,
        onClick: this.onClickExcludedViewings,
      },
      {
        text: 'Add owner',
        enableTool: true,
        onClick: () => {
          toggleModal(helpers.modalNameCollection.addOwnerModal, {
            text,
            ownerModalCategory: helpers.OwnerModalCategory.addOwners,
          })
        },
      },
      {
        text: 'Add current tenants',
        enableTool:
          isPropertyTakingApplications(propertyStatus) &&
          viewingEntryNoticeEnabled,
        onClick: () =>
          toggleModal(helpers.modalNameCollection.addTenantsModal, {
            text,
            tenantModalCategory: helpers.TenantModalCategory.addTenants,
          }),
      },
      {
        text: 'Send entry notice',
        enableTool:
          isPropertyTakingApplications(propertyStatus) &&
          viewingEntryNoticeEnabled,
        onClick: () =>
          toggleModal(helpers.modalNameCollection.addTenantsModal, {
            text,
            tenantModalCategory: helpers.TenantModalCategory.sendEntryNotice,
          }),
      },
      {
        text: 'Add application',
        enableTool:
          isPropertyTakingApplications(propertyStatus) ||
          helpers.isPropertyArchived(propertyStatus) ||
          isPropertyLeased(propertyStatus),
        onClick: () => this.onClickAddApplication(),
      },
      {
        text: 'Edit onlist',
        enableTool: true,
        onClick: () =>
          history.push(
            `${urlTo('onlistDetailsForProperty', {
              teamSlug,
              offerGUID: prospectSummaryHelpers.selectPropertyOfferId(property),
              propertyGUID: prospectSummaryHelpers.selectPropertyGUID(property),
            })}`,
          ),
      },
      {
        text: 'Cancel last utility referral',
        enableTool:
          !!lastSentUtilityLead &&
          applications.length &&
          isProviderAllowedToCancel(lastSentUtilityLead.provider_name),
        onClick: () =>
          this.setState({
            cancelLastUtilityLeadModalConfig: {
              isOpen: true,
              config: {
                lastSentUtilityLead,
              },
            },
          }),
      },
      {
        text: 'Archive property',
        enableTool: true,
        onClick: () =>
          isPropertyLeased(propertyStatus)
            ? this.archiveProperty()
            : this.onClickArchive(),
      },
      {
        text: 'Invite To Apply',
        enableTool: true,
        onClick: () => this.toggleInviteToApplyModalOpen(),
      },
    ]

    return (
      <div className="width100">
        <Flex width="100%" justifyContent="space-between">
          <Box>
            <div className="display-flex flex-direction-column-mobile width100 justify-content-space-between">
              <div className="display-flex flex-direction-column-mobile">
                <div className="display-flex prospect-address-wrappper">
                  <span className="font-size-22 display-flex mr10">
                    {propertyAddress}
                  </span>
                  <Display.ToolsButton
                    tools={propertyTools}
                    componentClass="mr40"
                  />
                </div>
                <div className="display-flex team-over-button">
                  {showQuickLink && (
                    <div className="display-flex">
                      <div
                        className="blue-link-style mr5"
                        onClick={() =>
                          history.push(
                            `${urlTo('teamMessages', {
                              teamSlug,
                            })}?property=${propertyGUID}`,
                          )
                        }
                      >
                        Message
                      </div>
                      {shouldShowViewingsLink && (
                        <div
                          className="blue-link-style"
                          onClick={() =>
                            history.push(
                              `${urlTo('prospectSummary', {
                                teamSlug,
                              })}?stage=Viewing&property=${propertyGUID}`,
                            )
                          }
                        >
                          Viewings
                        </div>
                      )}
                      {isCurrentStageViewing && (
                        <div
                          className="blue-link-style"
                          onClick={() =>
                            history.push(
                              `${urlTo('prospectSummary', {
                                teamSlug,
                              })}?stage=Application&property=${propertyGUID}`,
                            )
                          }
                        >
                          Applications
                        </div>
                      )}
                      <div
                        className="blue-link-style ml5"
                        onClick={() =>
                          this.setState({
                            activeModal:
                              helpers.modalNameCollection.addViewingModal,
                          })
                        }
                      >
                        Add Viewing
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <ApplicationWorkflowContainer>
                <ApplicationWorkflowStatusLabel
                  applicationStatus={mostProgressedApplicationStatus}
                />
              </ApplicationWorkflowContainer>
            </div>
            <Flex>
              <Display.PropertyInfoDetails
                bedrooms={bedrooms}
                bathrooms={bathrooms}
                weeklyRentDisplay={formattedRent}
                availableFrom={availableDate}
                propertyInfo={property}
                keyCode={keyCode}
                isEntryDetailsDisplay={isEntryDetailsDisplay}
                keySets={keySets}
              />
              <Box ml={3}>
                <Display.PropertyLabels activeOffer={activeOffer} />
              </Box>
            </Flex>
          </Box>
          <Flex flexDirection="column" alignItems="flex-end">
            {ballotsEnabled && (
              <>
                {currentStage ===
                  prospectSummaryHelpers.prospectSummaryPageStage
                    .application && (
                  <SystemButton
                    mb={2}
                    buttonType="primary"
                    onClick={() =>
                      this.setState({
                        runBallotsModalConfig: {
                          isOpen: true,
                          config: {
                            propertyGUID:
                              prospectSummaryHelpers.selectPropertyGUID(
                                property,
                              ),
                          },
                        },
                      })
                    }
                  >
                    Run Ballot
                  </SystemButton>
                )}
                {!!ballotRanRecord && (
                  <BallotsRunRecorder
                    id={id}
                    ballotRanRecord={ballotRanRecord}
                    ranBy={ballotRanBy}
                    ranAt={ballotRanAt}
                  />
                )}
              </>
            )}
          </Flex>
        </Flex>
        {activeModal === 'ArchiveConfirmationModal' && (
          <ArchiveConfirmationModal
            closeModal={() => {
              this.setState({ activeModal: '' })
            }}
            clickArchive={() => this.archiveProperty()}
            markAsArchivedOptions={markAsArchivedOptions}
            onChangeMarkAsArchivedOptions={this.onChangeMarkAsArchivedOptions}
            toggleModal={this.toggleModal}
            currentTeam={currentTeam}
          />
        )}
        {activeModal === helpers.modalNameCollection.addViewingModal && (
          <Display.EditViewingModal
            title="Add Viewing"
            primaryActionLabel="Add"
            secondaryActionLabel="Cancel"
            primaryButtonActionInParent={this.updateAddViewingModal}
            secondaryButtonAction={() => this.setState({ activeModal: '' })}
            toggleModal={() => this.setState({ activeModal: '' })}
            fetchAllPropertyFutureViewings={fetchAllPropertyFutureViewings}
            teamGUID={agencyID}
            defaultSelectedViewingDurationEnabled={
              defaultSelectedViewingDurationEnabled
            }
            defaultSelectedViewingDuration={defaultSelectedViewingDuration}
          />
        )}
        {isOwnerModalActive && (
          <AddOwnerModal
            fetchAgencyApplicantsElasticSearchResult={
              fetchAgencyApplicantsElasticSearchResult
            }
            teamId={agencyID}
            address={address}
            toggleModal={toggleModal}
            ownerModalCategory={ownerModalCategory}
            chosenPropertyText={propertyAddress}
            chosenPropertyID={chosenPropertyID}
            fetchCurrentOwners={fetchCurrentOwners}
            currentTeam={currentTeam}
            addCurrentOwners={addCurrentOwners}
            updateCurrentOwners={updateCurrentOwners}
            removeCurrentOwners={removeCurrentOwners}
          />
        )}

        {showExcludeViewingScheduleModal && (
          <ExcludeViewingScheduleModal
            toggleModal={() => this.hideExcludedViewingsModal()}
            teamGUID={agencyID}
            propertyGUID={propertyGUID}
            property={this.props.property}
          />
        )}

        {this.renderCancelUtilityReferralModal()}
        {inviteToApplyModalOpen && (
          <InviteToApplyModal
            toggleModal={() => this.toggleInviteToApplyModalOpen()}
            teamGUID={agencyID}
            propertyDisplayText={friendlyName}
            propertyGUID={chosenPropertyID}
          />
        )}
        {this.renderBallotsRunModal()}
      </div>
    )
  }
}

const mapDispatchToPropsForPropertyButtons = (dispatch) => ({
  archiveProperty: (
    propertyGUID,
    cancelFutureViewings,
    declineUnsuccessfulApplications,
  ) =>
    dispatch(
      archiveProperty(
        propertyGUID,
        cancelFutureViewings,
        declineUnsuccessfulApplications,
      ),
    ),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
  fetchAgencyApplicantsElasticSearchResult: (teamId, queryString) =>
    dispatch(fetchAgencyApplicantsElasticSearchResult(teamId, queryString)),
  fetchCurrentOwners: (teamId, queryString) =>
    dispatch(fetchCurrentOwners(teamId, queryString)),
  addCurrentOwners: (data, teamId) => dispatch(addCurrentOwners(data, teamId)),

  updateCurrentOwners: (teamId, ownerId, data) =>
    dispatch(updateCurrentOwners(teamId, ownerId, data)),
  removeCurrentOwners: (teamId, contactId, propertyId) =>
    dispatch(removeCurrentOwners(teamId, contactId, propertyId)),
  addViewing: (
    id,
    startdate,
    duration,
    fromScheduler,
    skipDoubleBookingCheck,
    options,
  ) =>
    dispatch(
      addViewing(
        id,
        startdate,
        duration,
        fromScheduler,
        skipDoubleBookingCheck,
        options,
      ),
    ),
  fetchAllPropertyFutureViewings: (teamGUID) =>
    dispatch(fetchAllPropertyFutureViewings(teamGUID)),
  postRunPropertyBallots: (propertyGUID) =>
    dispatch(postRunPropertyBallots(propertyGUID)),
  getPropertyBallotsDetail: (propertyGUID) =>
    dispatch(getPropertyBallotsDetail(propertyGUID)),
})

const PropertyButtonsWrapper = connect(
  null,
  mapDispatchToPropsForPropertyButtons,
)(PropertyButtonsContainer)

export const PropertyHeader = ({
  activeOffer,
  appCueTag,
  text,
  property,
  failCallback,
  teamSlug,
  showQuickLink = false,
  toggleModal,
  showSentUtilitiy,
  sentUtilityLeads,
  archiveTimeAdder,
  refetchTheViewingList,
  viewingEntryNoticeEnabled = false,
  keyCode,
  isOwnerModalActive = false,
  isEntryDetailsDisplay = false,
  mostProgressedApplicationStatus,
  defaultSelectedViewingDuration = 15,
  defaultSelectedViewingDurationEnabled = false,
  viewingScheduleEnabled = false,
  applications = [],
  onPropertyDataNeedUpdate,
  currentTeam,
  keySets,
  ballotPropertyDetail,
  applicationTeamSettings,
  refreshApplicationsList,
}) => {
  return (
    <div className="display-flex pt50">
      <PropertyButtonsWrapper
        activeOffer={activeOffer}
        appCueTag={appCueTag}
        failCallback={failCallback}
        property={property}
        teamSlug={teamSlug}
        showQuickLink={showQuickLink}
        text={text}
        toggleModal={toggleModal}
        showSentUtilitiy={showSentUtilitiy}
        sentUtilityLeads={sentUtilityLeads}
        archiveTimeAdder={archiveTimeAdder}
        refetchTheViewingList={refetchTheViewingList}
        viewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
        keyCode={keyCode}
        isOwnerModalActive={isOwnerModalActive}
        isEntryDetailsDisplay={isEntryDetailsDisplay}
        mostProgressedApplicationStatus={mostProgressedApplicationStatus}
        defaultSelectedViewingDuration={defaultSelectedViewingDuration}
        defaultSelectedViewingDurationEnabled={
          defaultSelectedViewingDurationEnabled
        }
        viewingScheduleEnabled={viewingScheduleEnabled}
        applications={applications}
        onPropertyDataNeedUpdate={onPropertyDataNeedUpdate}
        currentTeam={currentTeam}
        keySets={keySets}
        ballotPropertyDetail={ballotPropertyDetail}
        applicationTeamSettings={applicationTeamSettings}
        refreshApplicationsList={refreshApplicationsList}
      />
    </div>
  )
}

export const AscendingArrow = () => (
  <span className="display-flex">
    <img
      className="sort-arrow-rotate"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAIBAMAAAA/ygPCAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAGFBMVEUAAACWmZyWnp6VmZyVmJ2ZmZ+hoaEAAABBL5wZAAAAB3RSTlMAVSLb5ygTNj0YsQAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAtSURBVAjXYxBiAAJFBpMABgZWZ4YQVwYQZnUJACIQEyQA5II4YBkQYEtgYAAAtcMGcm4776MAAAAASUVORK5CYII="
      alt=""
    />
  </span>
)

export const DescendingArrow = () => (
  <span className="display-flex">
    <img
      className="sort-arrow"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAIBAMAAAA/ygPCAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAGFBMVEUAAACWmZyWnp6VmZyVmJ2ZmZ+hoaEAAABBL5wZAAAAB3RSTlMAVSLb5ygTNj0YsQAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAtSURBVAjXYxBiAAJFBpMABgZWZ4YQVwYQZnUJACIQEyQA5II4YBkQYEtgYAAAtcMGcm4776MAAAAASUVORK5CYII="
      alt=""
    />
  </span>
)

const SortField = ({ label, onClick, order, className }) => (
  <div
    className={`${className} display-flex justify-content-center align-items-center`}
  >
    <div
      className="display-flex justify-content-center align-items-center"
      onClick={onClick}
    >
      {label}
      {order === 'ascending' && <AscendingArrow />}
      {order === 'descending' && <DescendingArrow />}
    </div>
  </div>
)

const NoSortField = ({ label, className }) => (
  <div
    className={`${className} display-flex justify-content-center align-items-center`}
  >
    <div className="display-flex">{label}</div>
  </div>
)

class ApplicationSortByRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sortBy: 'lastEventAt',
      order: {
        score: 'descending',
        submittedDate: 'descending',
        rent: 'descending',
        ratio: 'descending',
        household: 'descending',
        status: 'descending',
      },
      archivePropertyCancelViewings: {
        declineUnsuccessfulApplications: false,
        cancelFutureViewings: true,
      },
      archivePropertyDeclineHouseholdApplicants: {
        declineUnsuccessfulApplications: true,
        cancelFutureViewings: false,
      },
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.propertyGUID !== this.props.propertyGUID) {
      this.props.propertyGUID &&
        this.props.onClickSortBy(
          this.state.sortBy,
          this.state.order,
          this.props.propertyGUID,
        )
    }
  }

  handleClick = (sortBy) => {
    this.setState(
      {
        sortBy,
        order: {
          ...this.state.order,
          [sortBy]:
            this.state.order[sortBy] === prospectSummaryHelpers.SORT_ASCENDING
              ? prospectSummaryHelpers.SORT_DESCENDING
              : prospectSummaryHelpers.SORT_ASCENDING,
        },
      },
      () => {
        this.props.onClickSortBy(sortBy, this.state.order[sortBy])
      },
    )
  }

  render() {
    const { order } = this.state
    const { score, submittedDate, rent, ratio, status } = order
    const {
      toggleSelection,
      isSelected,
      actionsHeaderCell,
      ballotsEnabled = false,
    } = this.props
    return (
      <div className="flex-direction-column border-bottom mb20 tablet-only">
        <div className="display-flex property-item-container">
          <Flex flexBasis="100%" className="property-item-info height80">
            <label className="ibm filter-today-checkbox">
              <input
                type="checkbox"
                className="input-box wa pt0"
                onChange={toggleSelection}
                checked={isSelected}
              />
            </label>

            {!ballotsEnabled && (
              <SortField
                label="Match Score"
                onClick={() => this.handleClick('score')}
                order={score}
                className="team-width-10p"
              />
            )}
            <SortField
              label="Date"
              onClick={() => this.handleClick('submittedDate')}
              order={submittedDate}
              className={`${
                ballotsEnabled ? 'team-width-30p' : 'team-width-20p'
              }`}
            />
            <SortField
              label="Rent"
              onClick={() => this.handleClick('rent')}
              order={rent}
              className="team-width-10p"
            />
            <SortField
              label="Ratio"
              onClick={() => this.handleClick('ratio')}
              order={ratio}
              className="team-width-10p"
            />
            <NoSortField label="" className="width34p" />
            <SortField
              label="Status"
              onClick={() => this.handleClick('status')}
              order={status}
              className="team-width-20p pl40"
            />
          </Flex>
          <Flex
            pl="120px"
            className="display-flex property-item-button"
            width="35%"
          >
            {actionsHeaderCell}
          </Flex>
        </div>
      </div>
    )
  }
}

export const Breadcrumbs = ({ crumbs }) => {
  const shouldShowCrumb = !getParameter('address')
  return shouldShowCrumb ? (
    <div className="mb10 pb10">
      <div className="viewing-crumb-wrapper display-flex">
        {crumbs.map((crumb, index) => {
          if (index === crumbs.length - 1) {
            return (
              <button
                key={crumb.text}
                className="btn btn-transparent btn-left rental-header-mobile-layout height15"
              >
                <span className="font-size-14">{crumb.text}</span>
              </button>
            )
          } else {
            return (
              <button
                key={crumb.text}
                className="btn btn-transparent btn-left rental-header-mobile-layout width-auto height15"
              >
                <span
                  className="font-size-14"
                  onClick={() => history.push(crumb.link)}
                >
                  {crumb.text}&nbsp;/&nbsp;
                </span>
              </button>
            )
          }
        })}
      </div>
    </div>
  ) : (
    <div />
  )
}

export const LoadMoreButton = ({
  onClick,
  buttonText = 'Load more',
  positionClassName = '',
  textAlignClass = '',
}) => (
  <div
    className={`text-center ${
      positionClassName ? positionClassName : 'mt20 ml20'
    }`}
  >
    <button
      className={`textButton wa ha btnmr mt10 mb10 ${
        textAlignClass ? textAlignClass : ``
      }`}
      onClick={onClick}
    >
      {buttonText}
    </button>
  </div>
)

export const ApplicationButton = ({
  label,
  onClick,
  disabled,
  className,
  id,
}) => (
  <div className="flex-2 display-flex justify-content-center align-items-center team-over-button desktop-std-btn mr5">
    <button
      className={`btn-decline p0 font-size-14 height40 ${className}`}
      id={id}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{label}</span>
    </button>
  </div>
)

class ButtonsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldShowFilledStar: false,
    }
  }

  componentDidMount() {
    this.computeShouldShowFilledStar()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.application.status !== prevProps.application.status ||
      this.props.application.shortlistingEmailSent !==
        prevProps.application.shortlistingEmailSent
    ) {
      this.computeShouldShowFilledStar()
    }
  }

  computeShouldShowFilledStar = () => {
    const { application } = this.props
    const applicationStatus =
      prospectSummaryHelpers.selectApplicationStatus(application)

    if (isStatusShortlisted(applicationStatus)) {
      this.setState({
        shouldShowFilledStar: true,
      })
    }
  }

  onAddShortListApplication = (guidID) => {
    const { onAddShortListApplication, applicationTeamSettings } = this.props
    const { enable_internal_shortlisting: enableInternalShortlisting = false } =
      applicationTeamSettings || {}
    onAddShortListApplication(guidID)
      .then(() => {
        snugNotifier.success(
          `Application has been shortlisted${
            !enableInternalShortlisting ? ' and email sent.' : '.'
          }`,
        )
        this.setState({ shouldShowFilledStar: true })
      })
      .catch((error) => {
        snugNotifier.error(translateErrorCodeToMessage(error))
      })
  }

  onRemoveShortListApplication = (guidID) => {
    const { onRemoveShortListApplication } = this.props
    onRemoveShortListApplication(guidID)
      .then(() => {
        snugNotifier.success(`Application has been removed from shortlisted`)
        this.setState({ shouldShowFilledStar: false })
      })
      .catch((error) => {
        snugNotifier.error(translateErrorCodeToMessage(error))
      })
  }

  executeIfClickable = (executeFn) => {
    const { onDisabledClicked, currentTeam } = this.props
    const { isActive = true } = currentTeam || {}
    isActive ? executeFn() : onDisabledClicked()
  }

  render() {
    const applicationStatus = prospectSummaryHelpers.selectApplicationStatus(
      this.props.application,
    )
    const {
      teamSlug,
      application = {},
      applicationWorkflowStatus,
      currentTeam,
      onMarkAsLeasedBtnClick,
      onMarkAsApplicationApproved,
      isClickable,
      sendUtilityReferralToLead,
      onClickScheduleViewing,
      fetchApplicationsBasedOnQuery,
      pmsIntegrationStatus,
      refreshApplicationsList,
      onClickShare,
      onClickDecline,
      onClickOffer,
      onClickWithDraw,
      compactWorkflow,
      shortlistWithEmail,
      applicationTeamSettings,
      isConsoleCloudEnabled = false,
    } = this.props
    const { shouldShowFilledStar } = this.state
    const selectedFilledStar = !application?.shortlistingEmailSent
      ? prospectSummaryHelpers.shortlistedStarsVariants.blue
      : prospectSummaryHelpers.shortlistedStarsVariants.yellow
    const { guidID = '' } = application
    return (
      <>
        <Box className="icon-button-placeholder mr5">
          {isClickable &&
            (shouldShowFilledStar ? (
              <StyledIcon className="cursor-pointer" color={selectedFilledStar}>
                <StarRoundedSolid
                  onClick={() =>
                    this.executeIfClickable(() =>
                      this.onRemoveShortListApplication(guidID),
                    )
                  }
                />
              </StyledIcon>
            ) : (
              <StyledIcon className="cursor-pointer">
                <StarRoundedOutline
                  onClick={() =>
                    this.executeIfClickable(() =>
                      this.onAddShortListApplication(guidID),
                    )
                  }
                />
              </StyledIcon>
            ))}
        </Box>

        <Box className="cursor-pointer icon-button-placeholder mr5">
          <StyledIcon>
            <MailRoundedOutline
              onClick={() => this.executeIfClickable(onClickShare)}
            />
          </StyledIcon>
        </Box>

        {/* TODO: clean up feature flag */}
        {!isNewWorkflowEnabled(teamSlug) &&
          (this.props.status === 'OfferWithdrawn' ||
            (this.props.status === '' &&
              isStatusDeclinableByManager(applicationStatus))) && (
            <div className="flex-2 display-flex justify-content-center">
              <Button
                text="Decline"
                buttonType="secondary"
                onClick={() => this.executeIfClickable(onClickDecline)}
                size="large"
                disabled={this.props.disabled}
              />
            </div>
          )}
        <div className="flex-2 display-flex justify-content-center">
          {/* TODO: clean up feature flag */}
          {!isNewWorkflowEnabled(teamSlug) &&
            (this.props.status === 'OfferWithdrawn' ||
              (this.props.status === '' &&
                isStatusOfferable(applicationStatus))) && (
              <ApplicationButton
                label="Progress"
                className=""
                id={teamSlug}
                onClick={() => this.executeIfClickable(onClickOffer)}
                disabled={
                  isStatusOffered(this.props.status)
                    ? this.props.disabled
                    : false
                }
              />
            )}
          {!isNewWorkflowEnabled(teamSlug) &&
            this.props.status === '' &&
            isStatusAccepted(applicationStatus) && (
              <ApplicationButton
                label="View"
                className=""
                id={teamSlug}
                onClick={() => this.executeIfClickable(onClickOffer)}
                disabled={
                  this.props.status === 'Offered' ? this.props.disabled : false
                }
              />
            )}
          {!isNewWorkflowEnabled(teamSlug) &&
            this.props.status !== 'OfferWithdrawn' &&
            (isStatusOffered(this.props.status) ||
              isStatusOffered(applicationStatus)) && (
              <Button
                text="Withdraw"
                buttonType="secondary"
                onClick={() => this.executeIfClickable(onClickWithDraw)}
                size="large"
                disabled={this.props.disabled}
              />
            )}
        </div>
        {isNewWorkflowEnabled(teamSlug) && (
          <IntegrationsTeamSettingsContext.Consumer>
            {(settings) => (
              <ApplicationWorkflowToolbar
                application={this.props.application}
                property={this.props.property}
                moveTo={this.props.moveTo}
                updateApplicationWorkflowStatusTo={
                  this.props.updateApplicationWorkflowStatusTo
                }
                onClickShare={this.props.onClickShare}
                onClickDecline={this.props.onClickDecline}
                onClickWithdrawOffer={this.props.onClickWithdrawOffer}
                applicationStatus={
                  ApplicationCategory[applicationWorkflowStatus]
                }
                currentTeam={currentTeam}
                onMarkAsLeasedBtnClick={onMarkAsLeasedBtnClick}
                onMarkAsApplicationApproved={onMarkAsApplicationApproved}
                sendUtilityReferralToLead={sendUtilityReferralToLead}
                onClickScheduleViewing={onClickScheduleViewing}
                fetchApplicationsBasedOnQuery={fetchApplicationsBasedOnQuery}
                pmsIntegrationStatus={pmsIntegrationStatus}
                refreshApplicationsList={refreshApplicationsList}
                integrationsTeamSettings={settings}
                executeIfClickable={this.executeIfClickable}
                compactWorkflow={compactWorkflow}
                shortlistWithEmail={shortlistWithEmail}
                applicationTeamSettings={applicationTeamSettings}
                isConsoleCloudEnabled={isConsoleCloudEnabled}
                showNewButtonStyle={true}
                alignItems="reset"
                showIconMoveTo={false}
              />
            )}
          </IntegrationsTeamSettingsContext.Consumer>
        )}
      </>
    )
  }
}

const getShortListApplication = (applications = []) =>
  applications.filter((application) =>
    isStatusShortlisted(
      prospectSummaryHelpers.selectApplicationStatus(application),
    ),
  )
const getNonShortListApplication = (applications = []) =>
  applications.filter(
    (application) =>
      !isStatusShortlisted(
        prospectSummaryHelpers.selectApplicationStatus(application),
      ),
  )

const StyledApplicationRolloverContainer = styled.div`
  position: absolute;
  width: ${({ isMaxWidth }) => (isMaxWidth ? '100vw' : 'auto')};
  max-width: 300px;
  background-color: #34353a;
  color: white;
  border-radius: 4px;
  top: -32px;
  padding: 8px 16px;
  z-index: 10;
`

const ApplicationRollover = ({
  commentsForApplicationFetchDone,
  commentsForApplication,
}) => {
  const _renderCommentsTooltip = () => {
    const EMPTY_NOTE_TEXT = 'No notes'
    if (commentsForApplicationFetchDone) {
      if (commentsForApplication && commentsForApplication.length > 0) {
        return (
          <>
            {commentsForApplication.map((comment, index) => (
              <ApplicationNoteFlatStyle
                note={comment}
                key={index}
                componentClass="text-white"
                infoTextClass="text-white"
              />
            ))}
          </>
        )
      } else {
        return <span>{EMPTY_NOTE_TEXT}</span>
      }
    } else {
      return <i className="fa fa-spinner fa-pulse" />
    }
  }

  const isMaxWidth =
    commentsForApplicationFetchDone && commentsForApplication?.length > 0

  return (
    <StyledApplicationRolloverContainer isMaxWidth={isMaxWidth}>
      {_renderCommentsTooltip()}
    </StyledApplicationRolloverContainer>
  )
}

export const ApplicationsListHeader = () => (
  <>
    <Display.MainHeader text="Applications" componentClass="mb10" />
    <Display.DefaultBodyText componentClass="mb24">
      <span>
        Use your Apply Link everywhere and anywhere to receive high quality
        applications.{' '}
      </span>
      <a
        href="https://help.snug.com/hc/en-us/articles/360001468195"
        target="_blank"
        rel="noreferrer"
      >
        Learn more
      </a>
    </Display.DefaultBodyText>
  </>
)

const CUSTOM_SORT_ID = 'customSort'

class PureApplicationTableContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shortListApplications: [],
      nonShortListApplications: [],
      finalApplicationList: [],
      showFilteredOutApps: false,
      hasFilteredOutApps: false,
      utilityReferral: [],
      bulkShareModal: {
        isOpened: false,
        config: {},
      },
      registerApplicantModal: {
        isOpened: false,
        config: {},
      },
      bulkBallotModal: {
        isOpened: false,
        config: {},
      },
      sendUtilityReferralsDisabled: false,
      showInactiveTeamModal: false,
      applicationsInCustomOrder: [],
      beingDragged: false,
    }
    this.toggleModal = this.toggleModal.bind(this)
  }

  componentDidMount() {
    const { applications = [] } = this.props
    if (applications.length > 0) {
      this.sortApplicationTypes(applications)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.applications === undefined &&
        this.props.applications !== undefined) ||
      prevProps.applications !== this.props.applications
    ) {
      this.sortApplicationTypes(this.props.applications)
      if (!!this.props.searchText && this.props.applications) {
        this.setState((state) => {
          const { toBeFilteredOutApps = {} } = state
          const filteredOutApps = (
            Object.values(toBeFilteredOutApps) || []
          ).flat()
          if (filteredOutApps.length === this.props.applications.length) {
            return { ...state, showFilteredOutApps: true }
          }
          return state
        })
      }
    }
  }

  onAddShortListApplication = (applicationID) => {
    const { applicationTeamSettings } = this.props
    const { applications } = this.state
    const { enable_internal_shortlisting: enableInternalShortlisting = false } =
      applicationTeamSettings
    const indexForUpdatedApplication = applications.findIndex(
      (application) => application.guidID === applicationID,
    )

    const isAlreadySorted =
      applications[indexForUpdatedApplication]?.sortApplied

    return shortListApplication(applicationID, {
      sendEmail: !enableInternalShortlisting,
    }).then(({ status, shortlistingEmailSent }) => {
      applications[indexForUpdatedApplication] = {
        ...applications[indexForUpdatedApplication],
        status,
        shortlistingEmailSent,
      }
      this.sortApplicationsAfterShortlistAction(
        applications,
        applicationID,
        indexForUpdatedApplication,
        isAlreadySorted,
      )
    })
  }

  sortApplicationsAfterShortlistAction = (
    applications,
    shortlistedAppID,
    shortlistedAppIndex,
    isAlreadySorted,
  ) => {
    const { currentTeam, property } = this.props
    if (isPMSortEnabled(currentTeam?.slug) && !isAlreadySorted) {
      this.moveApplication(
        property?.guidID,
        shortlistedAppID,
        shortlistedAppIndex,
        1, // move shortlisted to the top
      )
    } else {
      this.sortApplicationTypes(applications)
    }
  }

  onClickSortBy = (field, order) => {
    this.setState({
      finalApplicationList: this.state.finalApplicationList
        .slice()
        .sort(prospectSummaryHelpers.selectSortFunc(field, order)),
    })
  }

  onFetchUtilityReferral = (applicationID, propertyID) => {
    const { property, fetchUtilityReferralStatus } = this.props
    const { guidID: propertyGUID } = property
    return fetchUtilityReferralStatus(applicationID).then((utilityResponse) => {
      if (propertyID === propertyGUID) {
        if (utilityResponse.length === 0) {
          this.setState({
            utilityReferral: [
              { utility_status: prospectSummaryHelpers.UTILITY_STATUS_PENDING },
            ],
          })
        } else {
          this.setState({
            utilityReferral: utilityResponse,
          })
        }
      }
    })
  }

  onRemoveShortListApplication = (applicationID) => {
    const { removeShortListApplication, currentTeam } = this.props
    const { applications } = this.state
    const indexForUpdatedApplication = applications.findIndex(
      (application) => application.guidID === applicationID,
    )
    return removeShortListApplication(applicationID).then((status) => {
      applications[indexForUpdatedApplication].status = status
      if (isPMSortEnabled(currentTeam?.slug)) {
        this.setState({ applications })
      } else {
        this.sortApplicationTypes(applications)
      }
    })
  }

  changeOrder = (result, propertyGUID) => {
    const { destination, source, draggableId } = result

    const { applicationsInCustomOrder } = this.state

    this.setState({
      beingDragged: false,
    })

    const customSortApplicationEnd = applicationsInCustomOrder.length
    if (!destination || destination?.index > customSortApplicationEnd) {
      return
    }

    let destinationIndex = destination.index
    if (!applicationsInCustomOrder.length) {
      destinationIndex = 0
    }

    this.moveApplication(
      propertyGUID,
      draggableId,
      source.index,
      destinationIndex + 1,
    )
  }

  moveApplication = (
    propertyGUID,
    applicationGUID,
    oldPosition,
    newPosition,
  ) => {
    const { currentTeam, onPropertyDataNeedUpdate } = this.props
    const payload = {
      agencyGUID: currentTeam?.guid,
      propertyGUID: propertyGUID,
      applicationGUID: applicationGUID,
      previous_position: oldPosition,
      new_position: newPosition,
    }

    changeApplicationOrder(applicationGUID, payload)
      .then(() => {
        onPropertyDataNeedUpdate()
      })
      .catch((error) => {
        snugNotifier.error(error?.message)
      })
  }

  onDragStart = () => {
    this.setState({
      beingDragged: true,
    })
  }

  onShortlistWithEmail = (applicationID, params) => {
    const { applications } = this.state
    const indexForUpdatedApplication = applications.findIndex(
      (application) => application.guidID === applicationID,
    )
    const application = applications[indexForUpdatedApplication]
    const { applicationTeamSettings } = this.props
    const { enable_internal_shortlisting: enableInternalShortlisting = false } =
      applicationTeamSettings
    const isAlreadySorted = application?.sortApplied
    if (
      enableInternalShortlisting &&
      application.shortlistingEmailSent &&
      isStatusShortlisted(ApplicationCategory[application.status])
    ) {
      snugNotifier.success('Already shortlisted and email sent')
      return
    }
    shortListApplication(applicationID, params).then(
      ({ status, shortlistingEmailSent }) => {
        applications[indexForUpdatedApplication] = {
          ...applications[indexForUpdatedApplication],
          status,
          shortlistingEmailSent,
        }
        this.sortApplicationsAfterShortlistAction(
          applications,
          applicationID,
          indexForUpdatedApplication,
          isAlreadySorted,
        )
        snugNotifier.success('Application has been shortlisted and email sent')
      },
    )
  }

  filterOutApplications = (applications, filterOutIncompleteHousehold) => {
    const filteredOutAppsMap = {}

    const applicationsFilterWrapper = (filterCbFn) => {
      return applications.filter((application) => {
        const applicationId = application.guidID
        const match =
          !filteredOutAppsMap[applicationId] && filterCbFn(application)
        if (match) {
          filteredOutAppsMap[applicationId] = application
          return true
        }
        return false
      })
    }

    return {
      declined: applicationsFilterWrapper((application) =>
        isStatusDeclined(
          prospectSummaryHelpers.selectApplicationStatus(application),
        ),
      ),
      withdrawn: applicationsFilterWrapper((application) => {
        return isWithdrawn(application)
      }),
      ...(!filterOutIncompleteHousehold
        ? {}
        : {
            incompleteHousehold: applicationsFilterWrapper(({ applicants }) => {
              return applicants.some((applicant) => !applicant.applied)
            }),
          }),
    }
  }

  sortApplicationTypes = (applications = []) => {
    const { applicationTeamSettings, ballotsEnabled } = this.props
    const shortListApplications = getShortListApplication(applications)
    const nonShortListApplications = getNonShortListApplication(applications)
    let finalApplicationList = [
      ...shortListApplications,
      ...nonShortListApplications,
    ]

    if (ballotsEnabled) {
      //no sort needed, already sorted by rank
      finalApplicationList = applications
    }
    this.setState({
      applications: applications,
      finalApplicationList,
    })

    const filterOutIncompleteHousehold =
      !!applicationTeamSettings?.filter_incomplete_household_applications

    const toBeFilteredOutApps = this.filterOutApplications(
      applications,
      filterOutIncompleteHousehold,
    )

    const filteredOutApps = uniqBy(
      Object.values(toBeFilteredOutApps).flat(),
      (application) => application.guidID,
    )

    const filteredApplicationIds = filteredOutApps.map(
      (application) => application.guidID,
    )

    const filteredApps = applications.filter(
      (application) => !filteredApplicationIds.includes(application.guidID),
    )

    const customSorted = applications.filter(
      (application) => application.sortApplied,
    )

    this.setState({
      applicationsInCustomOrder: customSorted,
      finalApplicationList: applications.filter(
        (application) => !application.sortApplied,
      ),
    })

    if (filteredOutApps.length) {
      this.setState({
        hasFilteredOutApps: true,
        toBeFilteredOutApps,
        filteredOutApps,
        finalApplicationList: filteredApps.filter(
          (application) => !application.sortApplied,
        ),
      })
    }
  }

  toggleModal = (field, option = {}) => {
    const { text, ownerModalCategory } = option
    this.setState({
      [field]: !this.state[field],
      chosenPropertyText: text,
      ownerModalCategory,
    })
  }

  getShownApplications = () => {
    const {
      finalApplicationList = [],
      showFilteredOutApps,
      filteredOutApps,
    } = this.state

    return [
      ...finalApplicationList,
      ...(showFilteredOutApps ? filteredOutApps || [] : []),
    ]
  }

  getEligibleApplicationsForUtilityReferral = () =>
    this.getShownApplications().filter((app) =>
      isStatusEligibleForUtilityReferral(app),
    )

  toggleSelectAllApplications = () => {
    const { selectedItemsIds, setSelectedItemsSetIds } = this.props
    const { finalApplicationList } = this.state

    const shownApplications = finalApplicationList

    const hasSelectedApplications = !!selectedItemsIds.length

    hasSelectedApplications
      ? setSelectedItemsSetIds([])
      : setSelectedItemsSetIds(shownApplications.map((app) => app.guidID))
  }

  closeBulkShareModal = () =>
    this.setState({
      bulkShareModal: {
        isOpened: false,
        config: {},
      },
    })

  closeBallotModal = () =>
    this.setState({
      bulkBallotModal: {
        isOpened: false,
        config: {},
      },
    })

  closeRegisterApplicantModal = () =>
    this.setState({
      registerApplicantModal: {
        isOpened: false,
        config: {},
      },
    })

  onSuccessfullySharedApplications = () => {
    snugNotifier.success('Applications have been shared successfully!')
    this.closeBulkShareModal()
  }

  onSuccessfullySendApplications = () => {
    snugNotifier.success('Bulk message have been sent successfully!')
    this.closeBallotModal()
  }

  onSuccessfullySentApplications = () => {
    snugNotifier.success(
      'Ballots applications message have been sent successfully!',
    )
    this.closeBallotModal()
  }

  openBulkShareApplicationsModal = () => {
    const { selectedItemsIds } = this.props
    const selectedApplications = this.getShownApplications().filter((app) =>
      selectedItemsIds.includes(app.guidID),
    )
    this.setState({
      bulkShareModal: {
        isOpened: true,
        config: {
          onDismiss: this.closeBulkShareModal,
          onSharedSuccessfully: this.onSuccessfullySharedApplications,
          applications: selectedApplications,
        },
      },
    })
  }

  openBulkBallotApplicationsModal = () => {
    const { selectedItemsIds } = this.props
    const selectedApplications = this.getShownApplications().filter((app) =>
      selectedItemsIds.includes(app.guidID),
    )
    this.setState({
      bulkBallotModal: {
        isOpened: true,
        config: {
          onDismiss: this.closeBallotModal,
          onSentSuccessfully: () => this.onSuccessfullySendApplications(),
          applications: selectedApplications,
        },
      },
    })
  }

  openRegisterApplicantModal = () => {
    const { selectedItemsIds, property } = this.props
    const activeOffer = prospectSummaryHelpers.selectActiveOffer(
      property.offers,
    )
    const primaryApplicants = this.getShownApplications()
      .filter((app) => selectedItemsIds.includes(app.guidID))
      .map((app) => {
        const applicants = prospectSummaryHelpers.selectApplicants(app)
        const primaryApplicant =
          prospectSummaryHelpers.selectPrimaryApplicant(applicants)
        return primaryApplicant?.guidID
      })
    this.setState({
      registerApplicantModal: {
        isOpened: true,
        config: {
          offerID: activeOffer.guidID,
          primaryApplicants,
        },
      },
    })
  }

  renderBulkActionsControls = () => {
    const { selectedItemsIds } = this.props
    const showBulkyControls = !!selectedItemsIds.length

    if (!showBulkyControls) return null

    return (
      <Flex
        // alignItems="center"
        justifyContent="end"
      >
        <Box mr={theme.space[4] + 'px'}>
          <TextSpan color="deepBlue" fontWeight="bold">
            {selectedItemsIds.length}
          </TextSpan>{' '}
          Selected
        </Box>

        <NewButton
          mr={3}
          variant="brightGreenOutline"
          onClick={this.openRegisterApplicantModal}
        >
          Register to view
        </NewButton>

        <NewButton
          variant="brightGreenOutline"
          onClick={this.openBulkShareApplicationsModal}
        >
          Share
        </NewButton>

        <Box ml={3}>
          <NewButton
            variant="brightGreenOutline"
            onClick={this.openBulkBallotApplicationsModal}
          >
            Send Message
          </NewButton>
        </Box>
      </Flex>
    )
  }

  renderShareApplicationsModal = () => {
    const { isOpened, config } = this.state.bulkShareModal
    const { applicationTeamSettings } = this.props

    if (!isOpened) return null
    return (
      <ShareApplicationsModal
        // TODO: we should use the currentTeam instead
        teamId={this.props.property.agencyID}
        property={this.props.property}
        applications={config.applications}
        onSendSuccessfully={config.onSharedSuccessfully}
        onDismissClick={config.onDismiss}
        currentTeam={this.props.currentTeam}
        applicationSettings={applicationTeamSettings}
      />
    )
  }

  renderBallotSendApplicationsModal = () => {
    const { isOpened, config } = this.state.bulkBallotModal
    if (!isOpened) return null
    return (
      <BallotMessageModal
        // TODO: we should use the currentTeam instead
        teamId={this.props.property.agencyID}
        property={this.props.property}
        applications={config.applications}
        onSendSuccessfully={config.onSentSuccessfully}
        onDismissClick={config.onDismiss}
      />
    )
  }

  renderSendUtilityReferralModal = () => {
    const { sendUtilityReferralModalConfig } = this.state
    const { isOpen, config } = sendUtilityReferralModalConfig || {}
    if (!isOpen) return null
    const { property, applications, currentTeam, onPropertyDataNeedUpdate } =
      this.props
    if (!currentTeam?.utilityProviderConfigured && !currentTeam?.providerName) {
      return null
    }

    const eligibleApplications =
      this.getEligibleApplicationsForUtilityReferral()

    if (!eligibleApplications || !eligibleApplications.length) {
      return null
    }

    const onCloseModal = (response) => {
      if (response) {
        snugNotifier.success('Sending referrals request is being processed')
        // waiting for 5 seconds before updating the overview and checking new referrals
        setTimeout(() => {
          onPropertyDataNeedUpdate().then(() =>
            this.setState({ sendUtilityReferralsDisabled: false }),
          )
        }, 5000)

        this.setState({ sendUtilityReferralsDisabled: true })
      }
      this.setState({
        sendUtilityReferralModalConfig: { isOpen: false },
      })
    }

    return (
      <SendUtilityReferralModal
        property={property}
        applications={eligibleApplications}
        onCloseModal={onCloseModal}
        config={{ providerId: currentTeam.providerName }}
      />
    )
  }

  renderRegisterApplicantModal = () => {
    const { registerApplicantModal } = this.state
    const { isOpened, config } = registerApplicantModal || {}
    if (!isOpened) return null

    const { property, fetchAllPropertyFutureViewings } = this.props
    const { offerID, primaryApplicants } = config
    return (
      <RegisterApplicantModal
        applicantsIDs={primaryApplicants}
        onDismiss={this.closeRegisterApplicantModal}
        property={property}
        offerID={offerID}
        fetchAllFutureViewings={fetchAllPropertyFutureViewings}
      />
    )
  }

  renderUtilityReferralToolbar = () => {
    const {
      finalApplicationList: activeApplicationsList,
      sendUtilityReferralsDisabled,
    } = this.state
    const { currentTeam, sentUtilityLeads, property } = this.props
    if (!currentTeam?.utilityProviderConfigured && !currentTeam?.providerName) {
      return null
    }

    const eligibleApplications =
      this.getEligibleApplicationsForUtilityReferral()

    const activeApplicationsListIds = (activeApplicationsList || []).map(
      ({ guidID }) => guidID,
    )
    const filteredUtilityLeads = (sentUtilityLeads || []).filter(
      (utilityLead) =>
        activeApplicationsListIds.includes(utilityLead.application_guid),
    )

    const config = {
      allApplications: this.getShownApplications(),
      eligibleApplications,
      sentReferrals: filteredUtilityLeads,
      providerId: currentTeam.providerName,
      platformDefaultProvider: currentTeam?.platformDefaultProvider,
      property,
      sendDisabled: sendUtilityReferralsDisabled,
      onSendClicked: () => {
        currentTeam.isActive
          ? this.setState({ sendUtilityReferralModalConfig: { isOpen: true } })
          : this.toggleInactiveTeamModal()
      },
    }

    return <SendUtilityReferralToolbar config={config} />
  }

  renderFilteredOutAppsToggle = () => {
    const { hasFilteredOutApps, showFilteredOutApps, toBeFilteredOutApps } =
      this.state

    if (!hasFilteredOutApps) return null

    const filteredDefs = {
      withdrawn: { title: 'Withdrawn' },
      declined: { title: 'Declined' },
      incompleteHousehold: { title: 'Partial Household' },
    }

    const filteredOutAppsStatsText = Object.entries(toBeFilteredOutApps || {})
      .map(([filterType, filteredApps]) => {
        const count = (filteredApps || []).length
        if (!count) return null
        const def = filteredDefs[filterType]
        const title = def?.title
        return `${title} (${count})`
      })
      .filter(Boolean)
      .join(' / ')

    return (
      <div
        className="textButton wa ha btnmr mt10 mb10 gray-text text-align-center"
        onClick={() =>
          this.setState({
            showFilteredOutApps: !showFilteredOutApps,
          })
        }
      >
        {showFilteredOutApps ? 'Hide' : 'Show'} {filteredOutAppsStatsText}{' '}
        Applications
      </div>
    )
  }

  renderBallotEntriesEntered = () => {
    const { ballotPropertyDetail = {} } = this.props
    const { count: ballotEntryReceived } = ballotPropertyDetail || {}

    return (
      <Flex
        fontSize={theme.fontSizes.pExtraLarge18}
        fontWeight={theme.fontWeights.h3}
        justifyContent="center"
        p={8}
      >
        {ballotEntryReceived} ballot entries received
      </Flex>
    )
  }

  toggleInactiveTeamModal = () => {
    const { showInactiveTeamModal } = this.state
    this.setState({
      showInactiveTeamModal: !showInactiveTeamModal,
    })
  }

  render() {
    let {
      property,
      setAPIError,
      teamSlug,
      leased_application_guid,
      currentTeam,
      fetchProgressApplication,
      markProgressApplicationAsLeased,
      applications,
      propertyOverviewError = '',
      sentUtilityLeads = [],
      appCueTag = '',
      archiveTimeAdder,
      inviteToApplyApplicant,
      markProgressApplicationAsApproved,
      sendUtilityReferral,
      fetchApplicationNotesWithLimit,
      fetchAllPropertyFutureViewings,
      addViewing,
      sendScheduleViewingFromApplication,
      fetchApplicationsBasedOnQuery,
      pmsIntegrationStatus,
      refreshApplicationsList,
      isApplicantReport,
      currentAgency,
      applicationTeamSettings,
      onPropertyDataNeedUpdate,
      ballotsEnabled,
      ballotPropertyDetail,
      isConsoleCloudEnabled = false,
      allApplicationsForPropertyPresent = true,
    } = this.props
    const {
      finalApplicationList = [],
      showFilteredOutApps,
      hasFilteredOutApps,
      isOwnerModalActive = false,
      utilityReferral,
      showInactiveTeamModal,
      applicationsInCustomOrder,
      beingDragged,
    } = this.state
    const { utilityProviderConfigured } = currentTeam || {}
    const shouldShowApllicationSortRow =
      (finalApplicationList && finalApplicationList.length !== 0) ||
      (hasFilteredOutApps && showFilteredOutApps) ||
      ballotsEnabled ||
      (applicationsInCustomOrder && applicationsInCustomOrder.length > 0)
    if (utilityReferral.length > 0) {
      sentUtilityLeads = utilityReferral
    }

    const { selectedItemsIds, toggleItemSelection } = this.props

    const hasSelectedApplications = !!selectedItemsIds.length
    const shownApplications = this.getShownApplications()
    const activeOffer = prospectSummaryHelpers.selectActiveOffer(
      property.offers,
    )

    const isDragDisabled =
      isMobile() ||
      ballotsEnabled ||
      !allApplicationsForPropertyPresent ||
      !isPMSortEnabled(teamSlug)

    const allApplications = [...applicationsInCustomOrder, ...shownApplications]
    const customSortApplicationEnd = applicationsInCustomOrder.length

    return (
      <div key={prospectSummaryHelpers.selectPropertyGUID(property)}>
        {this.renderShareApplicationsModal()}
        {this.renderSendUtilityReferralModal()}
        {this.renderBallotSendApplicationsModal()}
        {this.renderRegisterApplicantModal()}
        <PropertyHeader
          activeOffer={activeOffer}
          appCueTag={appCueTag}
          text={prospectSummaryHelpers.selectAddressFriendlyName(property)}
          property={property}
          failCallback={setAPIError}
          teamSlug={teamSlug}
          showQuickLink={true}
          showSentUtilitiy={utilityProviderConfigured}
          sentUtilityLeads={sentUtilityLeads}
          archiveTimeAdder={archiveTimeAdder}
          toggleModal={this.toggleModal}
          isOwnerModalActive={isOwnerModalActive}
          applications={applications}
          onPropertyDataNeedUpdate={onPropertyDataNeedUpdate}
          currentTeam={currentTeam}
          ballotPropertyDetail={ballotPropertyDetail}
          applicationTeamSettings={applicationTeamSettings}
          refreshApplicationsList={refreshApplicationsList}
        />
        {!applications && !propertyOverviewError && (
          <div className="display-flex justify-content-center">
            <i className="fa fa-spinner fa-pulse" />
          </div>
        )}
        <ErrorMessage error={propertyOverviewError} />

        {this.renderBulkActionsControls()}

        {shouldShowApllicationSortRow && (
          <ApplicationSortByRow
            onClickSortBy={this.onClickSortBy}
            propertyGUID={prospectSummaryHelpers.selectPropertyGUID(property)}
            isSelected={hasSelectedApplications}
            toggleSelection={this.toggleSelectAllApplications}
            actionsHeaderCell={this.renderUtilityReferralToolbar()}
            ballotsEnabled={ballotsEnabled}
          />
        )}

        {applications && applications.length === 0 && (
          <>
            {!ballotsEnabled && (
              <NoApplicationRow
                property={property || {}}
                leased_application_guid={leased_application_guid || ''}
              />
            )}
            {ballotsEnabled && this.renderBallotEntriesEntered()}
          </>
        )}
        <DragDropContext
          onDragEnd={(result) => this.changeOrder(result, property?.guidID)}
          onDragStart={this.onDragStart}
        >
          <Droppable
            droppableId={`${property?.guidID}-${CUSTOM_SORT_ID}`}
            type="droppableItem"
            isDropDisabled={!isPMSortEnabled(teamSlug)}
          >
            {(provided, snapshot) => (
              <>
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    marginBottom: beingDragged ? '100px' : 0,
                  }}
                >
                  {applications && applications.length !== 0 && (
                    <>
                      {allApplications &&
                        allApplications.map((application, index) => {
                          const isSelected = !!selectedItemsIds.includes(
                            application.guidID,
                          )
                          const dragDisabled =
                            isDragDisabled ||
                            isStatusDeclined(application?.status) ||
                            isWithdrawn(application)
                          return (
                            <Box>
                              {index === customSortApplicationEnd &&
                                (snapshot.isDraggingOver || beingDragged) && (
                                  <DroppedContainer>
                                    <FlipToFrontRounded /> Move here
                                  </DroppedContainer>
                                )}
                              <Draggable
                                key={application.guidID}
                                draggableId={application.guidID}
                                index={index}
                                isDragDisabled={dragDisabled}
                              >
                                {(draggableProvided, snapshot) => (
                                  <div
                                    style={
                                      beingDragged &&
                                      index < customSortApplicationEnd
                                        ? {
                                            backgroundColor: `${theme.colors.gray100}`,
                                            marginLeft: '-35px',
                                            paddingLeft: '35px',
                                          }
                                        : {}
                                    }
                                  >
                                    <div>
                                      <div
                                        ref={draggableProvided.innerRef}
                                        {...draggableProvided.draggableProps}
                                        {...draggableProvided.dragHandleProps}
                                      >
                                        <ApplicationRowWithRouter
                                          key={
                                            application.guidID +
                                            '_' +
                                            isSelected
                                          }
                                          offer={activeOffer}
                                          property={property}
                                          application={application}
                                          failCallback={setAPIError}
                                          teamSlug={teamSlug}
                                          onAddShortListApplication={
                                            this.onAddShortListApplication
                                          }
                                          onRemoveShortListApplication={
                                            this.onRemoveShortListApplication
                                          }
                                          declineApplication={
                                            this.props.declineApplication
                                          }
                                          offerApplication={
                                            this.props.offerApplication
                                          }
                                          withdrawOfferForApplication={
                                            this.props
                                              .withdrawOfferForApplication
                                          }
                                          moveTo={this.props.moveTo}
                                          status={application.status}
                                          currentTeam={currentTeam}
                                          currentAgency={currentAgency}
                                          fetchProgressApplication={
                                            fetchProgressApplication
                                          }
                                          markProgressApplicationAsLeased={
                                            markProgressApplicationAsLeased
                                          }
                                          markProgressApplicationAsApproved={
                                            markProgressApplicationAsApproved
                                          }
                                          inviteToApplyApplicant={
                                            inviteToApplyApplicant
                                          }
                                          sendUtilityReferral={
                                            sendUtilityReferral
                                          }
                                          onFetchUtilityReferral={
                                            this.onFetchUtilityReferral
                                          }
                                          fetchApplicationNotesWithLimit={
                                            fetchApplicationNotesWithLimit
                                          }
                                          fetchAllPropertyFutureViewings={
                                            fetchAllPropertyFutureViewings
                                          }
                                          addViewing={addViewing}
                                          sendScheduleViewingFromApplication={
                                            sendScheduleViewingFromApplication
                                          }
                                          fetchApplicationsBasedOnQuery={
                                            fetchApplicationsBasedOnQuery
                                          }
                                          pmsIntegrationStatus={
                                            pmsIntegrationStatus
                                          }
                                          refreshApplicationsList={
                                            refreshApplicationsList
                                          }
                                          isApplicantReport={isApplicantReport}
                                          showSelectCheckbox={true}
                                          isSelected={isSelected}
                                          toggleSelection={() =>
                                            toggleItemSelection(
                                              application.guidID,
                                            )
                                          }
                                          from={textHelpers.PROSPECT_SUMMARY}
                                          applicationTeamSettings={
                                            applicationTeamSettings
                                          }
                                          shortlistWithEmail={
                                            this.onShortlistWithEmail
                                          }
                                          ballotsEnabled={ballotsEnabled}
                                          ballotApplicationIds={
                                            ballotPropertyDetail?.applicationsIds
                                          }
                                          isConsoleCloudEnabled={
                                            isConsoleCloudEnabled
                                          }
                                          onPropertyDataNeedUpdate={
                                            onPropertyDataNeedUpdate
                                          }
                                          dragDisabled={dragDisabled}
                                        />
                                      </div>
                                    </div>
                                    <Box>{draggableProvided.placeholder}</Box>
                                  </div>
                                )}
                              </Draggable>
                            </Box>
                          )
                        })}
                    </>
                  )}
                </Box>
              </>
            )}
          </Droppable>
        </DragDropContext>

        {this.renderFilteredOutAppsToggle()}
        {showInactiveTeamModal && (
          <InactiveTeamModal closeModal={this.toggleInactiveTeamModal} />
        )}
      </div>
    )
  }
}

const ApplicationTableContainer = withMultiSelect(PureApplicationTableContainer)

const ApplicationStatusLabelWithCircle = ({
  label = '',
  circleClasses = [],
  tooltip,
  tooltipPrefixId,
  style,
}) => {
  if (!label) {
    return ''
  }

  const allCircleClasses = ['status-circle-indicator'].concat(circleClasses)
  const tooltipId = `${tooltipPrefixId}_${label}`
  return (
    <div
      className="display-flex font-size-12"
      data-tooltip-content=""
      data-tooltip-id={tooltipId}
      style={style}
    >
      {label}
      <span
        className={allCircleClasses.join(' ')}
        style={{ cursor: 'pointer', marginLeft: '2px' }}
      />

      <ReactTooltip id={tooltipId} place="bottom" className="tool-tip-style">
        <div>{tooltip}</div>
      </ReactTooltip>
    </div>
  )
}

class ApplicationRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: '',
      applicationWorkflowStatus: 0,
      mappedApplication: {},
      emailModal: false,
      sharedApplication: '',
      sharePropertyUrl: '',
      showAvatarAndNameComponent: false,
      isPropertyLoading: false,
      commentsForApplication: [],
      commentsForApplicationFetchDone: false,
      showCommentsContent: false,
      showScheduleViewingModal: false,
      statusChanged: false,
      scheduleViewingModal: {
        ...defaultEditViewingModalValues,
      },
      defaultDisplay: {
        registeredViewings: [],
        viewings: [],
        notes: [],
      },
      showInactiveTeamModal: false,
      isShareWithOwnerModalOpen: false,
      isHovered: false,
    }
  }

  componentDidMount() {
    this.setState({
      applicationWorkflowStatus: this.props.application.status,
      mappedApplication: mapApplicationWithRecentlyDeclinedFlag(
        this.props.application,
      ),
    })
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.application !== this.props.application ||
        prevProps.status !== this.props.status) &&
      this.props.application
    ) {
      this.setState({
        applicationWorkflowStatus: this.props.application.status,
        mappedApplication: mapApplicationWithRecentlyDeclinedFlag(
          this.props.application,
        ),
      })
    }
  }

  onApplicationLinkClick = () => {
    const { currentTeam } = this.props
    if (!currentTeam.isActive) {
      this.toggleInactiveTeamModal()
    }
  }

  onRowMouseOver = () => {
    this.setState({
      isHovered: true,
    })
  }

  onRowMouseOut = () => {
    this.setState({
      isHovered: false,
    })
  }

  onClickDecline = (declineReasons, notifyApplicant, comment) => {
    return new Promise((resolve, reject) => {
      const applicationGUID = prospectSummaryHelpers.selectApplicationGUID(
        this.props.application,
      )
      const agencyGUID = prospectSummaryHelpers.selectAgencyGUID(
        this.props.property,
      )
      this.setState({ loading: true })
      this.props
        .declineApplication(
          agencyGUID,
          applicationGUID,
          declineReasons,
          notifyApplicant,
          comment,
        )
        .then(() => {
          this.updateApplicationWorkflowStatusTo(
            ApplicationStatusCategory.Declined,
          )
          this.setState({ status: 'Declined', statusChanged: true })
          this.setState({ loading: false })
          let message = 'Moved to "Declined"'
          if (!notifyApplicant) {
            message = message.split('.')[0]
          }
          snugNotifier.success(message)
          resolve()
        })
        .catch((error) => {
          this.props.failCallback(error)
          this.setState({ loading: false })
          reject(error)
        })
    })
  }

  onClickInviteToApply = () => {
    const { inviteToApplyApplicant, application } = this.props
    inviteToApplyApplicant(application.guidID)
      .then(() => {
        snugNotifier.success('Notification to applicant was successfully sent')
        this.setState({
          ...this.state,
          emailModal: false,
        })
      })
      .catch((error) => {
        snugNotifier.error(error)
        return Promise.resolve()
      })
  }

  onClickOffer = () => {
    const applicationId = prospectSummaryHelpers.selectApplicationGUID(
      this.props.application,
    )
    history.push(urlTo('progressApplication', { applicationId }))
  }

  onClickScheduleViewingPrimaryButton() {
    const { scheduleViewingModal = {} } = this.state

    const { startDate, startTime, duration, ...viewingOptions } =
      scheduleViewingModal || {}
    const { addViewing, property = {}, offer = {} } = this.props
    const mergedStartDate = `${moment(startDate).format(
      helpers.dateWithDash,
    )} ${startTime}`
    const formattedStartDate = helpers.getStandardFormattedDate(
      moment(mergedStartDate, helpers.dateTimeWithDash).utc(),
      helpers.utcFormatTimeStamp,
    )
    if (property) {
      let offerGUID = ''
      if (offer && offer.guidID) {
        offerGUID = offer.guidID
      } else {
        offerGUID = prospectSummaryHelpers.selectPropertyOfferId(property)
      }
      addViewing(
        offerGUID,
        formattedStartDate,
        durationOptionsNumbers[duration],
        false,
        false,
        viewingOptions,
      )
        .then((data) => {
          this.callRegisterForViewing(data.viewing)
          this.toggleScheduleViewingModal()
        })
        .catch((error) => {
          if (
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForProperty] ||
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForManager]
          ) {
            if (window.confirm(viewingsHelpers.conflictingTimeMessage)) {
              addViewing(
                offerGUID,
                formattedStartDate,
                durationOptionsNumbers[duration],
                false,
                true,
                viewingOptions,
              )
                .then((data) => {
                  this.callRegisterForViewing(data.viewing)
                  this.toggleScheduleViewingModal()
                })
                .catch((error) => {
                  snugNotifier.error(error)
                })
            }
          } else {
            snugNotifier.error(error)
          }
        })
    }
  }

  onClickShareApplication = () => {
    const propertyGUID = prospectSummaryHelpers.selectPropertyGUID(
      this.props.property,
    )
    const agencyGUID = prospectSummaryHelpers.selectAgencyGUID(
      this.props.property,
    )
    const applicants = prospectSummaryHelpers.selectApplicants(
      this.props.application,
    )
    const primaryApplicant =
      prospectSummaryHelpers.selectPrimaryApplicant(applicants)

    const primaryApplicantEmailAddress =
      primaryApplicant && primaryApplicant.email
    const sharePropertyUrl = `/teams/${agencyGUID}/property/${propertyGUID}?email=${primaryApplicantEmailAddress}`
    const applicationGUID = prospectSummaryHelpers.selectApplicationGUID(
      this.props.application,
    )
    this.setState({
      sharedApplication: applicationGUID,
      sharePropertyUrl,
      emailModal: true,
    })
  }

  onClickWithdrawOffer = (onConfirm) => {
    const applicationGUID = prospectSummaryHelpers.selectApplicationGUID(
      this.props.application,
    )
    this.setState({ loading: true })
    if (window.confirm('Are you sure you want to withdraw this offer?')) {
      this.props
        .withdrawOfferForApplication(applicationGUID)
        .then(() => {
          this.setState({ status: 'OfferWithdrawn', loading: false })
          onConfirm && onConfirm()
        })
        .catch((error) => {
          this.props.failCallback(error)
          this.setState({ loading: false })
        })
    } else {
      this.setState({ loading: false })
    }
  }

  onMarkAsApplicationApproved = (markAsApplicationApprovedOptions) => {
    const {
      fetchProgressApplication,
      markProgressApplicationAsApproved,
      application,
      property,
      teamSlug,
    } = this.props
    const { guidID: applicationId } = application
    const { agencyID: agencyId, guidID: propertyId } = property
    return fetchProgressApplication(applicationId, agencyId)
      .then((applicationInfo) => {
        const shouldSendUtilityReferral =
          markAsApplicationApprovedOptions.sendUtilityReferrals
        if (applicationInfo && applicationInfo.referrals) {
          const modifiedReferrals = applicationInfo.referrals.map(
            (referral) => {
              return { ...referral, selected: shouldSendUtilityReferral }
            },
          )
          applicationInfo = { ...applicationInfo, referrals: modifiedReferrals }
        }
        applicationInfo.markApproved = true
        markProgressApplicationAsApproved(
          applicationId,
          applicationInfo,
          agencyId,
          markAsApplicationApprovedOptions,
        )
          .then(() => {
            this.updateApplicationWorkflowStatusTo(
              ApplicationStatusCategory['Approved'],
            )
            snugNotifier.success(`Move to "Application Approved"`)
            history.push(
              `${urlTo('prospectSummary', {
                teamSlug,
              })}?property=${propertyId}`,
            )
            return Promise.resolve()
          })
          .catch((error) => {
            snugNotifier.error(translateErrorCodeToMessage(error))
          })
      })
      .catch((apiError) => {
        snugNotifier.error(apiError.message)
        return Promise.resolve()
      })
  }

  onMarkAsLeasedBtnClick = (markAsLeasedOptions) => {
    const {
      fetchProgressApplication,
      markProgressApplicationAsLeased,
      application,
      property,
      teamSlug,
    } = this.props
    const { guidID: applicationId } = application
    const { agencyID: agencyId, guidID: propertyId } = property
    return fetchProgressApplication(applicationId, agencyId)
      .then((applicationInfo) => {
        const { referrals = [] } = applicationInfo
        const confirmText =
          referrals.length > 0
            ? 'Save the successful lease details and issue the utility connection referral?'
            : 'Save the successful lease details?'
        applicationInfo.markLeased = true
        if (window.confirm(confirmText)) {
          markProgressApplicationAsLeased(
            applicationId,
            applicationInfo,
            markAsLeasedOptions,
          )
            .then(() => {
              this.updateApplicationWorkflowStatusTo(
                ApplicationStatusCategory['Leased'],
              )
              snugNotifier.success(`Move to "Mark as leased"`)
              history.push(
                `${urlTo('prospectSummary', {
                  teamSlug,
                })}?property=${propertyId}`,
              )
              return Promise.resolve()
            })
            .catch((error) => {
              snugNotifier.error(translateErrorCodeToMessage(error))
            })
        }
      })
      .catch((apiError) => {
        snugNotifier.error(apiError.message)
        return Promise.resolve()
      })
  }

  callRegisterForViewing = (viewing) => {
    const { application, sendScheduleViewingFromApplication } = this.props
    const primaryApplicant = prospectSummaryHelpers.selectPrimaryApplicant(
      application.applicants,
    )

    const primaryApplicantGUID = primaryApplicant && primaryApplicant.guidID
    if (
      application &&
      application.guidID &&
      viewing.guidID &&
      primaryApplicantGUID
    ) {
      sendScheduleViewingFromApplication(
        application.guidID,
        primaryApplicantGUID,
        viewing.guidID,
      )
        .then(({ data }) => {
          snugNotifier.success(
            applicationHelpers.successfullyRegisteredForScheduledViewing(
              data.firstName,
              data.lastName,
            ),
          )
        })
        .catch((error) => {
          snugNotifier.error(error)
        })
    }
  }

  closeAvatarAndNameDropdown = () => {
    this.setState({
      showAvatarAndNameComponent: false,
    })
  }

  evalClassNameAndTipTextForApplicationStatus = (applicants) => {
    const applicantsLength = applicants.length
    const numAppliedApplicants = applicants.filter((applicant) => {
      return applicant.applied
    }).length
    const numApplicantsFlaggedUnsuitable = applicants.filter(
      (applicant) =>
        applicant.flags &&
        applicant.flags.length > 0 &&
        flagsUtil.isFlaggedUnsuitable(applicant.flags),
    ).length
    const anyFlaggedApplicants = numApplicantsFlaggedUnsuitable > 0
    const applicationIconStatusHelperText = `${numAppliedApplicants}/${applicantsLength} `

    if (numAppliedApplicants === applicantsLength) {
      return {
        applicationStatusIconClassName: anyFlaggedApplicants
          ? 'application-status-icons-orange'
          : 'application-status-icons-green',
        applicationIconStatusHelperText: 'All ',
        anyFlaggedApplicants,
        numFlaggedApplicants: numApplicantsFlaggedUnsuitable,
      }
    }

    if (numAppliedApplicants === 1) {
      return {
        applicationStatusIconClassName: anyFlaggedApplicants
          ? 'application-status-icons-empty-orange'
          : 'application-status-icons-empty',
        applicationIconStatusHelperText,
        anyFlaggedApplicants,
        numFlaggedApplicants: numApplicantsFlaggedUnsuitable,
      }
    }

    return {
      applicationStatusIconClassName: anyFlaggedApplicants
        ? 'application-status-icons-grey-orange'
        : 'application-status-icons-grey',
      applicationIconStatusHelperText,
      anyFlaggedApplicants,
      numFlaggedApplicants: numApplicantsFlaggedUnsuitable,
    }
  }

  fetchNotesForApplication = debounce((applicationGUID) => {
    const { fetchApplicationNotesWithLimit } = this.props
    const { commentsForApplicationFetchDone } = this.state
    if (!commentsForApplicationFetchDone) {
      fetchApplicationNotesWithLimit(
        applicationGUID,
        prospectSummaryHelpers.NOTES_LIMIT_FOR_APPLICATION,
      )
        .then((data) => {
          this.setState(
            {
              commentsForApplicationFetchDone: true,
            },
            () => {
              this.setState({
                commentsForApplication: data,
              })
            },
          )
        })
        .catch((error) => snugNotifier.error(error))
    }
  }, prospectSummaryHelpers.TIME_FOR_NOTES_FETCH_MS)

  sendUtilityReferralToLead = (force) => {
    const {
      sendUtilityReferral,
      application,
      onFetchUtilityReferral,
      property,
    } = this.props
    const { guidID: applicationId } = application
    const { guidID: propertyID } = property
    return sendUtilityReferral(applicationId, force)
      .then(() => {
        this.setState({
          isPropertyLoading: true,
        })
        snugNotifier.success(prospectSummaryHelpers.UTILITY_LEAD_SENT)
      })
      .then(() => {
        if (onFetchUtilityReferral) {
          onFetchUtilityReferral(applicationId, propertyID)
        }
      })
      .catch((apiError) => {
        snugNotifier.error(apiError)
        return Promise.resolve()
      })
      .finally(() => {
        this.setState({
          isPropertyLoading: false,
        })
      })
  }

  toggleEmailModal = () => {
    this.setState({ emailModal: !this.state.emailModal })
  }

  toggleScheduleViewingModal = () => {
    this.setState({
      showScheduleViewingModal: !this.state.showScheduleViewingModal,
    })
  }

  updateApplicationWorkflowStatusTo = (newStatus) => {
    if (newStatus === ApplicationStatusCategory.PMWithdrawn) {
      this.setState({
        statusChanged: true,
      })
    }
    this.setState({
      applicationWorkflowStatus: newStatus,
      mappedApplication: mapApplicationWithRecentlyDeclinedFlag({
        ...this.props.application,
        status: newStatus,
        lastEventAt: moment(),
      }),
    })
  }

  updateScheduleViewingModal = (modalValues) => {
    this.setState(
      {
        ...this.state,
        scheduleViewingModal: {
          ...this.state.scheduleViewingModal,
          ...modalValues,
        },
      },
      () => this.onClickScheduleViewingPrimaryButton(),
    )
  }

  selectCheckboxHandler = (event) => {
    const { toggleSelection } = this.props
    toggleSelection()
    event.stopPropagation()
    event.preventDefault()
  }

  toggleInactiveTeamModal = () => {
    const { showInactiveTeamModal } = this.state
    this.setState({
      showInactiveTeamModal: !showInactiveTeamModal,
    })
  }

  renderAutoWithdrawnBadge = (application) => {
    const { currentAgency } = this.props
    return (
      isAutoWithdrawn(application) && (
        <Flex mt={`${theme.space[2]}px`}>
          <Badge
            text={'Auto-Withdrawn'}
            variant="blue"
            style={{ whiteSpace: 'nowrap' }}
          />
          <img
            data-tooltip-content=""
            data-tooltip-id={`auto-withdrawn-explanation-${application.guidID}`}
            src={questionIcon}
            alt={'icon'}
          />
          <ReactTooltip
            id={`auto-withdrawn-explanation-${application.guidID}`}
            data-type="info"
            className="tool-tip-style"
          >
            <span>{autoWithdrawnExplanation(application)}</span>
          </ReactTooltip>
        </Flex>
      )
    )
  }

  renderAutowithdrawTimeRemainingBadge = (application) => {
    const {
      autoWihdrawTimeRemaining = 0,
      showAutoWihdrawTimeRemaining = false,
      showExtraTextInAutoWithdrawTimeRemainingTooltip = false,
    } = application
    const { days, hours } = dateTimeHelpers.getDaysHoursNumFromHours(
      autoWihdrawTimeRemaining,
    )
    const isDaysLessThan1 = Number(days) < 1
    const textInBadge = isDaysLessThan1 ? `${hours} hours` : `${days} days`
    return (
      !isAutoWithdrawn(application) &&
      showAutoWihdrawTimeRemaining && (
        <Flex
          mt={`${theme.space[2]}px`}
          //  alignItems="center"
        >
          <Badge
            text={textInBadge}
            variant={isDaysLessThan1 ? 'error' : 'warning'}
            style={{ whiteSpace: 'nowrap' }}
            data-tooltip-content=""
            data-tooltip-id={`auto-withdrawn-time-remaining-${application.guidID}`}
          />
          <ReactTooltip
            id={`auto-withdrawn-time-remaining-${application.guidID}`}
            data-type="info"
            className="tool-tip-style"
          >
            <span>{`Received ${dateTimeHelpers.humanizedDateDuration(
              moment.now(),
              application?.submittedDate,
            )} ago, will Auto-Withdraw if not Shortlisted or moved to an Approve workflow stage. Tip: Mark as Leased for ongoing access to successful application.`}</span>
          </ReactTooltip>
        </Flex>
      )
    )
  }

  renderApplicationRank() {
    const { application } = this.props
    // applications applied before ballot enabled, doesn't have a ballet object, while they're included in ballot process
    // if (application?.ballot) {
    //   return this.renderBallotRank()
    // }

    const ballotRankCmp = this.renderBallotRank()
    if (ballotRankCmp) {
      return ballotRankCmp
    }
    const applicants = prospectSummaryHelpers.selectApplicants(application)
    const primaryApplicant =
      prospectSummaryHelpers.selectPrimaryApplicant(applicants) || applicants[0]
    if (primaryApplicant?.score) {
      return this.renderApplicantScore(primaryApplicant.score)
    } else {
      // placeholder
      return <Box width="40px" />
    }
  }

  renderBallotRank() {
    const { application, ballotApplicationIds } = this.props
    const appId = prospectSummaryHelpers.selectApplicationGUID(application)
    if (!appId || !ballotApplicationIds?.length) return
    const { applicationTeamSettings } = this.props
    const successfulBallotSize =
      applicationTeamSettings?.[
        applicationsAttributesIDs.applicationSuccessfulBallotsSize
      ]
    const reserveBallotSize =
      applicationTeamSettings?.[
        applicationsAttributesIDs.applicationReserveBallotsSize
      ]
    const successfulApps = ballotApplicationIds.slice(0, successfulBallotSize)
    const reserveApps = ballotApplicationIds.slice(
      successfulBallotSize,
      successfulBallotSize + reserveBallotSize,
    )
    const successRank = successfulApps.indexOf(appId)
    if (successRank > -1) {
      return (
        <Box minWidth={`${theme.dividedSpace[8]}px`} textAlign="center">
          <TextSpan
            fontSize={theme.fontSizes.h3}
            fontWeight={theme.fontWeights.h2}
            ml={-1}
          >
            {successRank + 1}
          </TextSpan>
        </Box>
      )
    }
    const reserveRank = reserveApps.indexOf(appId)
    if (reserveRank > -1) {
      return (
        <TextSpan
          fontSize={theme.fontSizes.h3}
          fontWeight={theme.fontWeights.h2}
          color={theme.colors.gray500}
          ml={-1}
        >
          {reserveRank + 1}R
        </TextSpan>
      )
    }
  }

  renderApplicantScore(score) {
    return (
      <Flex>
        <div
          className="rank-info chart relative"
          data-tooltip-content=""
          data-tooltip-id="snug-match-label"
        >
          <ReactTooltip
            id="snug-match-label"
            data-type="info"
            className="tool-tip-style"
          >
            <span>{textHelpers.SNUG_MATCH_LABEL}</span>
          </ReactTooltip>
          <ApplicantScore score={Math.round(score)} />
        </div>
      </Flex>
    )
  }

  sendViewingInvite = () => {
    const { application } = this.props
    return sendViewingInviteToApplicant(application.guidID)
      .then(() => {
        snugNotifier.success('Sending invitation to primary applicant')
        this.toggleEmailModal()
      })
      .catch(({ message }) => message && snugNotifier.error(message))
  }

  renderEmailModal = () => {
    const { emailModal, sharedApplication } = this.state
    if (!emailModal) return
    const { application, teamSlug, currentTeam, currentAgency } = this.props
    const isClickable = isApplicationDetailsAccessible(
      application,
      currentAgency,
      currentTeam,
    )
    const buttons = [
      {
        label: 'Invite to Apply',
        action: this.onClickInviteToApply,
      },
      {
        label: 'Invite to Viewing',
        action: this.sendViewingInvite,
      },
      {
        label: 'Invite to Property',
        action: () => history.push(this.state.sharePropertyUrl),
      },
    ]
    if (isClickable)
      buttons.push({
        label: 'Share Application',
        action: () => this.toggleShareWithOwnerModal(),
      })

    return (
      <BasicMultiButtonModal
        toggleModal={this.toggleEmailModal}
        title="Send Apply Link or Summary"
        body={
          <div>
            Share an application summary, invite a prospect to apply for an
            alternative property or reapply for the original property (if
            declined or withdrawn).
          </div>
        }
        buttons={buttons}
      />
    )
  }

  toggleShareWithOwnerModal = () => {
    const { isShareWithOwnerModalOpen } = this.state
    this.setState({
      isShareWithOwnerModalOpen: !isShareWithOwnerModalOpen,
      emailModal: false,
    })
  }

  render() {
    const {
      application,
      teamSlug,
      onAddShortListApplication,
      onRemoveShortListApplication,
      currentTeam,
      currentAgency,
      offer,
      property,
      fetchAllPropertyFutureViewings,
      fetchApplicationsBasedOnQuery,
      pmsIntegrationStatus,
      refreshApplicationsList,
      isApplicantReport,
      from = '',
      applicationTeamSettings,
      shortlistWithEmail,
      ballotsEnabled,
      isConsoleCloudEnabled = false,
      onPropertyDataNeedUpdate,
      dragDisabled = false,
    } = this.props
    const { weeklyRentDisplay, weeklyRent } = offer

    const { sortPosition = 0 } = application || {}
    const {
      bedrooms = 0,
      bathrooms = 0,
      carports,
      garages,
      parking,
      address,
    } = property
    const { friendlyName = '', suburb = '' } = address
    const {
      status,
      sharedApplication,
      applicationWorkflowStatus,
      mappedApplication,
      showAvatarAndNameComponent,
      isPropertyLoading,
      commentsForApplication,
      commentsForApplicationFetchDone,
      showCommentsContent,
      showScheduleViewingModal,
      showInactiveTeamModal,
      isShareWithOwnerModalOpen,
      isHovered,
    } = this.state
    const applicants = prospectSummaryHelpers.selectApplicants(
      this.props.application,
    )
    const primaryApplicant =
      prospectSummaryHelpers.selectPrimaryApplicant(applicants) || {}
    const applicationStatus = ApplicationCategory[applicationWorkflowStatus]
    const applicationsActivity =
      prospectSummaryHelpers.selectApplicationActivity(this.props.application)
    const isApplicationWithdrawn = isManuallyWithdrawn(application)
    const isClickable = isApplicationDetailsAccessible(
      application,
      currentAgency,
      currentTeam,
    )
    const { leaseStartDate = '' } = application || {}
    const { firstName = '', lastName = '' } =
      (Object.keys(primaryApplicant).length
        ? primaryApplicant
        : applicants[0]) || {}
    const fullName =
      capitalizeFirstLetter(firstName) + ' ' + capitalizeFirstLetter(lastName)
    const wordLength = 15
    const primaryApplicantName = setStringLength(fullName, wordLength)
    const rentToIncomeRatio = prospectSummaryHelpers.selectRentToIncomeRatio(
      this.props.application,
    )

    const propertyGUID =
      property && prospectSummaryHelpers.selectPropertyGUID(property)
    const applicationGUID = prospectSummaryHelpers.selectApplicationGUID(
      this.props.application,
    )
    const primaryApplicantGUID =
      prospectSummaryHelpers.selectApplicantGUID(primaryApplicant)

    const { availableFrom = '' } = offer || {}
    const leaseStartDateDisplay =
      dateTimeHelpers.formatTime(
        leaseStartDate,
        dateTimeHelpers.DATE_NO_YEAR,
      ) || ''

    const isApplicationOffered =
      !isNewWorkflowEnabled[this.props.teamSlug] &&
      (isStatusOffered(applicationStatus) ||
        isStatusAccepted(applicationStatus))
    let linkForApplication =
      isApplicationOffered && application.hasLeaseOffer
        ? urlTo('leasingOffer', {
            applicationId: applicationGUID,
            agencyId: this.props.currentTeam.guid,
          })
        : urlTo('renterApplication', {
            propertyId: propertyGUID,
            applicationId: applicationGUID,
            applicantId: primaryApplicantGUID,
          })

    if (
      from &&
      !application.hasLeaseOffer &&
      (from === textHelpers.APPLICATION_BY_STAGE ||
        from === textHelpers.PROSPECT_SUMMARY)
    ) {
      linkForApplication = `${urlTo('renterApplication', {
        propertyId: propertyGUID,
        applicationId: applicationGUID,
        applicantId: primaryApplicantGUID,
      })}?from=${from}`
    }

    const statusOnNetworkFlowEnabled =
      !isNewWorkflowEnabled(teamSlug) &&
      !isStatusDeclined(applicationStatus) &&
      !isStatusDeclined(status) &&
      !isStatusOffered(applicationStatus) &&
      !isStatusOffered(status) &&
      !isApplicationWithdrawn

    const allApplicantsWithCalculatedProperties = (allApplicants) => {
      let unSortedArray = allApplicants.map((applicant) => {
        let applicantObj = { ...applicant }
        if (applicant.isPrimary) {
          applicantObj['pillText'] = 'Primary'
        }
        return applicantObj
      })
      return unSortedArray.sort(
        (applicantA, applicantB) => applicantB.isPrimary - applicantA.isPrimary,
      )
    }

    let applicationStatusIconClassName = ''
    let applicationIconStatusHelperText = '0 '
    let anyFlaggedApplicants = false
    let numFlaggedApplicants = 0
    if (applicants) {
      const evaluatedInfoFromApplicants =
        this.evalClassNameAndTipTextForApplicationStatus(applicants)
      applicationStatusIconClassName =
        evaluatedInfoFromApplicants.applicationStatusIconClassName
      applicationIconStatusHelperText =
        evaluatedInfoFromApplicants.applicationIconStatusHelperText
      anyFlaggedApplicants = evaluatedInfoFromApplicants.anyFlaggedApplicants
      numFlaggedApplicants = evaluatedInfoFromApplicants.numFlaggedApplicants
    }
    const flaggedApplicantsTooltipText =
      anyFlaggedApplicants &&
      ` ${numFlaggedApplicants} applicant(s) flagged unsuitable.`

    const identityDocsCompletenessText = application.identityDocsCompleteness
      ? IDENTITY_DOCS_RECEIVED
      : IDENTITY_DOCS_NOT_RECEIVED
    const incomeCompletenessText = application.incomeCompleteness
      ? INCOME_RECEIVED
      : INCOME_INCOMPLETE
    const referenceCompletenessText = application.rentalReferenceCompleteness
      ? REFERENCE_RECEIVED
      : REFERENCE_INCOMPLETE
    const employmentCompletenessText = application.employmentCompleteness
      ? EMPLOYMENT_VERIFIED
      : EMPLOYMENT_VERIFICATION_INCOMPLETE

    const successStatusCircleClasses = [
      'status-circle-indicator--success status-circle-indicator--solid',
    ]
    const errorStatusCircleClasses = [
      'status-circle-indicator--error status-circle-indicator--solid',
    ]

    const statusValToCircleClasses = (value) =>
      value === 1
        ? successStatusCircleClasses
        : value === 2
        ? errorStatusCircleClasses
        : []

    const applicantsViewingSummary =
      application &&
      application.applicants &&
      application.applicants
        .map((applicant) => {
          const { applicantViewingSummary } = applicant
          if (!applicantViewingSummary) {
            return null
          }
          return {
            ...applicantViewingSummary,
            applicant,
          }
        })
        .filter((summary) => Boolean(summary))
        .sort(
          (summaryA, summaryB) =>
            moment(summaryA.checkInAt).valueOf() -
            moment(summaryB.checkInAt).valueOf(),
        )

    const applicantsViewingSummaryTooltip = (applicantsViewing) => {
      if (!applicantsViewing.length)
        return 'Unknown if applicants viewed the property'

      const separatedSummaryText = applicantsViewing.map((summary) => {
        const { applicant, checkInAt } = summary
        return (
          <span>{`${applicant.firstName} ${applicant.lastName} (${
            applicant.isPrimary ? 'Primary' : 'Secondary'
          }) viewed ${moment(checkInAt).format(STANDARD_DATETIME)}`}</span>
        )
      })

      return separatedSummaryText.map((summary, i) => {
        const isLastItem = i === separatedSummaryText.length - 1
        return (
          <div>
            {summary}
            {isLastItem && <br />}
          </div>
        )
      })
    }

    const applicationStatusIndicatorsConfigs = [
      {
        label: 'ID',
        circleClasses: statusValToCircleClasses(
          application.identityDocsCompleteness,
        ),
        tooltip: identityDocsCompletenessText,
      },
      {
        label: 'IN',
        circleClasses: statusValToCircleClasses(application.incomeCompleteness),
        tooltip: incomeCompletenessText,
      },
      {
        label: 'EM',
        circleClasses: statusValToCircleClasses(
          application.employmentCompleteness,
        ),
        tooltip: employmentCompletenessText,
      },
      {
        label: 'RR',
        circleClasses: statusValToCircleClasses(
          application.rentalReferenceCompleteness,
        ),
        tooltip: referenceCompletenessText,
      },
    ]

    const viewingStr =
      applicantsViewingSummary &&
      `Viewed: ${
        !applicantsViewingSummary.length
          ? 'N/A'
          : moment(applicantsViewingSummary[0].checkInAt).format(
              DATE_DAY_SHORT_STRING_MONTH_YEAR,
            )
      }`
    const viewingTooltip =
      applicantsViewingSummary &&
      applicantsViewingSummaryTooltip(applicantsViewingSummary)

    const shortlistedApplicant =
      applicantsViewingSummary?.length &&
      applicantsViewingSummary[0]?.shortlisted ? (
        <Flex mb="2px">
          <ShortlistAttendee
            shortlisted={applicantsViewingSummary[0]?.shortlisted}
            showLabel={false}
            clickable={false}
          />
        </Flex>
      ) : null

    const composedFullNameWithEmailOrMobileNumber = `${helpers.capitalizeFirstLetter(
      primaryApplicant.firstName,
    )} ${helpers.capitalizeFirstLetter(primaryApplicant.lastName)} (${
      primaryApplicant.email || primaryApplicant.phone
    })`

    const scheduleViewingModalText = `Register ${composedFullNameWithEmailOrMobileNumber} for
    ${prospectSummaryHelpers.selectAddressFriendlyName(property)}`

    const isApplicationLoading =
      Object.keys(this.state.mappedApplication).length === 0

    const propertyAddressTooltipId = `${applicationGUID}_address`

    const applicationsSum = allApplicantsWithCalculatedProperties(applicants)
      .map((a) => a.applicationsCount - 1)
      .reduce((a, b) => a + b, 0)

    const showLinkText =
      `+ Show` + (applicationsSum >= 1 ? ` (+${applicationsSum} Apps)` : ``)

    const { showSelectCheckbox, isSelected } = this.props

    const showCheckbox =
      showSelectCheckbox &&
      !isStatusDeclined(applicationStatus) &&
      !isWithdrawn(application)
    const applicationStatusId = this.state.applicationWorkflowStatus

    const autoWithdrawnBadge = this.renderAutoWithdrawnBadge(application)

    const autowithdrawTimeRemainingBadge =
      this.renderAutowithdrawTimeRemainingBadge(application)

    const formattedRent = helpers.pickRentDisplayAndFormat(
      weeklyRent,
      weeklyRentDisplay,
    )

    //TODO: refactor this legacy code!
    const renderApplicationStatusCmp = () => (
      <Flex
        flexBasis="10%"
        className="prospect-mobile-section position-relative"
        onMouseEnter={() => {
          this.setState({ showCommentsContent: true })
          this.fetchNotesForApplication(applicationGUID)
        }}
        onMouseLeave={() => this.setState({ showCommentsContent: false })}
      >
        {showCommentsContent && (
          <ApplicationRollover
            commentsForApplicationFetchDone={commentsForApplicationFetchDone}
            commentsForApplication={commentsForApplication}
          />
        )}
        <Flex justifyContent="center" flexBasis="100%" mr={1}>
          <div className="display-flex flex-direction-column text-align-center relative">
            <div className="rank-info">
              <span className="mobile-only">
                Application status <br />
              </span>
              {/* TODO: clean up feature flag */}
              {statusOnNetworkFlowEnabled && (
                <span className="font-size-16 pr5">
                  {prospectSummaryHelpers.formatApplicationStatus(
                    this.props.application,
                  )}
                  {applicationsActivity > 0 && <span className="green-dot" />}
                </span>
              )}
              {isNewWorkflowEnabled(teamSlug) &&
                applicationStatusId !== ApplicationStatusCategory.Applied &&
                !isStatusDeclined(applicationStatus) &&
                !isStatusOffered(applicationStatus) &&
                !isStatusPMWithdrawn(applicationStatus) && (
                  <ApplicationWorkflowTextStatus
                    applicationStatus={this.state.applicationWorkflowStatus}
                  />
                )}
              {applicationStatusId === ApplicationStatusCategory.Applied && (
                <Flex justifyContent="center">
                  <Badge text="New" variant="success" />
                </Flex>
              )}
              {(isStatusDeclined(
                ApplicationCategory[this.state.applicationWorkflowStatus],
              ) ||
                isStatusDeclined(ApplicationCategory[this.state.status])) && (
                <StatusLabel
                  color="red"
                  label="Declined"
                  lastEventAt={
                    this.state.statusChanged
                      ? getCurrentDate(utcFormatTimeStamp)
                      : prospectSummaryHelpers.selectLastEventAt(
                          this.props.application,
                        )
                  }
                >
                  {mappedApplication.isRecentlyDeclined && (
                    <Box mt={theme.space[1] + 'px'}>
                      Expires {mappedApplication.remainingDays}d
                    </Box>
                  )}
                </StatusLabel>
              )}
              {(isStatusOffered(
                ApplicationCategory[this.state.applicationWorkflowStatus],
              ) ||
                isStatusOffered(ApplicationCategory[this.state.status])) && (
                <StatusLabel
                  color="green"
                  label="Offered"
                  lastEventAt={
                    prospectSummaryHelpers.selectLastEventAt(
                      this.props.application,
                    ) || ''
                  }
                />
              )}
              {isApplicationWithdrawn && (
                <StatusLabel
                  color="grey"
                  label="Withdrawn"
                  lastEventAt={
                    this.state.statusChanged
                      ? getCurrentDate(utcFormatTimeStamp)
                      : prospectSummaryHelpers.selectLastEventAt(
                          this.props.application,
                        )
                  }
                />
              )}
            </div>
          </div>
        </Flex>
      </Flex>
    )

    const fullApplicationRowBreakpoint = devicesMediaMaxWidths.desktopXL
    const leftAlignDragIcon = isBreakpointReached(fullApplicationRowBreakpoint)

    const showMorelinkLeftMargin = !showCheckbox ? '74px' : '90px'

    const checkboxWidthProp = { width: showSelectCheckbox && '20px' }

    return (
      <div>
        {applicants !== null ? (
          isPropertyLoading || isApplicationLoading ? (
            <Spinner />
          ) : (
            <Box
              className="border-bottom"
              onMouseOver={this.onRowMouseOver}
              onMouseOut={this.onRowMouseOut}
            >
              <ResponsiveFlex
                alignItems="start"
                deviceMaxWidth={fullApplicationRowBreakpoint}
                flexChildrenJustify="stretch"
                minHeight={prospectSummaryHelpers.APPLICATION_ROW_MIN_HEIGHT}
                py={6}
              >
                <StyledAppRowInfo
                  deviceMaxWidth={devicesMediaMaxWidths.mobile}
                  className={`property-item-info`}
                >
                  <Flex
                    flexDirection="column"
                    flexBasis="32%"
                    className="prospect-mobile-section"
                  >
                    <Flex>
                      {isPMSortEnabled(currentTeam?.slug) && (
                        <StyledApplicationSortPos mt={3} minWidth="30px">
                          <TextSpan
                            fontSize={theme.fontSizes.pSuperLarge22}
                            fontWeight={theme.fontWeights.h2}
                          >
                            {sortPosition > 0 && sortPosition}
                          </TextSpan>
                        </StyledApplicationSortPos>
                      )}
                      <Box mr={1} mt={3} {...checkboxWidthProp}>
                        {showCheckbox && (
                          <label className="ibm">
                            <input
                              type="checkbox"
                              className="input-box wa pt0"
                              onClick={this.selectCheckboxHandler}
                              checked={isSelected}
                            />
                          </label>
                        )}
                      </Box>

                      <MaskedWrappingLink
                        flexBasis="100%"
                        className={`property-item-info`}
                        link={linkForApplication}
                        disable={!isClickable}
                        LayoutWrapperCmp={Flex}
                        onClick={() => this.onApplicationLinkClick()}
                      >
                        <Flex justifyContent="space-between">
                          <Flex
                            justifyContent="space-around"
                            className="prospect-user-row-mobile"
                          >
                            <Flex
                              justifyContent="center"
                              className="prospect-user-row-mobile"
                              ml={6}
                            >
                              <div className="meta">
                                <div className="rank">
                                  {this.renderApplicationRank()}
                                </div>
                              </div>
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex
                          justifyContent="center"
                          flexDirection="column"
                          ml={3}
                          mr={2}
                          className="prospect-user-row-mobile"
                        >
                          <DisplayText
                            dataDisplayedAndStyle={[
                              {
                                text: (
                                  <>
                                    {trimFileLength(24)(primaryApplicantName)}
                                    {applicants &&
                                      applicants.length > 1 &&
                                      ` +${
                                        applicants && applicants.length - 1
                                      }`}
                                  </>
                                ),
                                textColor: `${theme.colors.gray800}`,
                                textSize: `${theme.fontSizes[6]}`,
                                textFontWeight: `${theme.fontWeights[4]}`,
                                textLineHeight: '120%',
                              },
                              {
                                text: (
                                  <Box mb={3}>
                                    {dateTimeHelpers.hourDayMonthOrYearDifference(
                                      prospectSummaryHelpers.selectSubmittedDate(
                                        application,
                                      ),
                                    )}
                                  </Box>
                                ),
                                textColor: `${theme.colors.gray600}`,
                                textSize: `${theme.fontSizes[4]}`,
                              },
                              {
                                text: (
                                  <Flex>
                                    {applicationStatusIndicatorsConfigs &&
                                      applicationStatusIndicatorsConfigs.map(
                                        (statusConfig, index) => {
                                          return (
                                            <ApplicationStatusLabelWithCircle
                                              style={
                                                index !== 0
                                                  ? {
                                                      marginLeft: '3px',
                                                    }
                                                  : {}
                                              }
                                              label={statusConfig.label}
                                              tooltip={statusConfig.tooltip}
                                              circleClasses={
                                                statusConfig.circleClasses
                                              }
                                              tooltipPrefixId={
                                                application.guidID
                                              }
                                            />
                                          )
                                        },
                                      )}
                                  </Flex>
                                ),
                              },
                            ]}
                          />
                        </Flex>
                      </MaskedWrappingLink>
                    </Flex>
                    <Box
                      style={{ whiteSpace: 'nowrap' }}
                      className="position-relative"
                      ml={showMorelinkLeftMargin}
                      mt={3}
                    >
                      <NewButton
                        variant="linkBlue"
                        onClick={() =>
                          this.setState({
                            showAvatarAndNameComponent:
                              !showAvatarAndNameComponent,
                          })
                        }
                      >
                        {showLinkText}
                      </NewButton>
                      <ShowDropdownContainer>
                        <AvatarAndNameDropdown
                          teamSlug={teamSlug}
                          closeAvatarAndNameDropdown={() =>
                            this.closeAvatarAndNameDropdown()
                          }
                          showDropdown={showAvatarAndNameComponent}
                          dropdownItems={
                            applicants
                              ? allApplicantsWithCalculatedProperties(
                                  applicants,
                                )
                              : []
                          }
                          dropdownItemClickEventEnabled={false}
                          showStatusPills={true}
                          isApplicantReport={isApplicantReport}
                          showBallot={ballotsEnabled && application?.ballot}
                          ballotDetails={application?.ballot}
                          applicationSlug={application?.slug}
                        />
                      </ShowDropdownContainer>
                    </Box>
                  </Flex>

                  <Flex flexDirection="column" px={4} minWidth="30px">
                    <MaskedWrappingLink
                      className={`prospect-mobile-section`}
                      link={linkForApplication}
                      disable={!isClickable}
                      LayoutWrapperCmp={Flex}
                      onClick={() => this.onApplicationLinkClick()}
                    >
                      <Flex justifyContent="center">
                        <Box minWidth="55px">
                          <DisplayText
                            dataDisplayedAndStyle={[
                              {
                                text: `$ ${prospectSummaryHelpers.selectWeeklyRentWithEditedSummary(
                                  this.props.application,
                                )}`,
                                textColor: `${theme.colors.black}`,
                                textSize: `${theme.fontSizes[6]}`,
                                textFontWeight: `${theme.fontWeights[4]}`,
                                textLineHeight: '120%',
                              },
                              {
                                text: `${prospectSummaryHelpers.selectTermWithEditedSummary(
                                  this.props.application,
                                )} mths`,
                                textColor: `${theme.colors.gray600}`,
                                textSize: `${theme.fontSizes[3]}`,
                                textFontWeight: `${theme.fontWeights[3]}`,
                                textLineHeight: '100%',
                              },
                              {
                                text: leaseStartDateDisplay,
                                textColor: `${theme.colors.gray600}`,
                                textSize: `${theme.fontSizes[3]}`,
                                textFontWeight: `${theme.fontWeights[3]}`,
                                textLineHeight: '100%',
                              },
                            ]}
                          />
                        </Box>
                      </Flex>
                      <Flex justifyContent="center" mr={6} ml={6}>
                        <Box maxWidth="55px">
                          <DisplayText
                            dataDisplayedAndStyle={[
                              {
                                text: `${
                                  rentToIncomeRatio
                                    ? `${rentToIncomeRatio}%`
                                    : 'N/A'
                                }`,
                                textColor: `${theme.colors.black}`,
                                textSize: `${theme.fontSizes[6]}`,
                                textFontWeight: `${theme.fontWeights[4]}`,
                                textLineHeight: '120%',
                              },
                              {
                                text: `Rent to income`,
                                textColor: `${theme.colors.gray600}`,
                                textSize: `${theme.fontSizes[3]}`,
                                textFontWeight: `${theme.fontWeights[3]}`,
                                textLineHeight: '100%',
                              },
                            ]}
                          />
                        </Box>
                      </Flex>
                      <Flex justifyContent="center">
                        <Flex flexDirection="column">
                          <Box className="rank-info" mt={0}>
                            <Box
                              data-tooltip-content=""
                              data-tooltip-id={applicationGUID}
                              className={`${applicationStatusIconClassName} application-status-icons`}
                              mr={1}
                            >
                              <div className="message-mobile-hide">
                                <ReactTooltip
                                  id={applicationGUID}
                                  data-type="info"
                                  className="tool-tip-style"
                                >
                                  <span>
                                    <b>
                                      {applicationIconStatusHelperText}
                                      household members
                                    </b>{' '}
                                    have submitted their applications.
                                    {flaggedApplicantsTooltipText}
                                  </span>
                                </ReactTooltip>
                              </div>
                            </Box>
                            <span>
                              {prospectSummaryHelpers.selectPeople(
                                this.props.application,
                              )}
                            </span>
                          </Box>
                          <Box my={2}>
                            <Flex>
                              <ChildCareRounded
                                data-tooltip-id={`${applicationGUID}-child-info`}
                              />
                              <Box ml={1}>
                                {prospectSummaryHelpers.selectNumChildren(
                                  this.props.application,
                                )}
                              </Box>
                              <ReactTooltip
                                id={`${applicationGUID}-child-info`}
                                data-type="info"
                                className="tool-tip-style"
                              >
                                <span>Children</span>
                              </ReactTooltip>
                            </Flex>
                          </Box>
                          <Flex className="rank-info" title="Pets count">
                            <Box
                              mr={1}
                              data-tooltip-id={`${applicationGUID}-pets-info`}
                            >
                              <Pet />
                            </Box>
                            <span>
                              {prospectSummaryHelpers.selectPets(
                                this.props.application,
                              )}
                            </span>
                            <ReactTooltip
                              id={`${applicationGUID}-pets-info`}
                              data-type="info"
                              className="tool-tip-style"
                            >
                              <span>Pets</span>
                            </ReactTooltip>
                          </Flex>
                        </Flex>
                      </Flex>
                    </MaskedWrappingLink>
                  </Flex>
                  <MaskedWrappingLink
                    link={linkForApplication}
                    disable={!isClickable}
                    LayoutWrapperCmp={Flex}
                    flexDirection="column"
                    className={`prospect-mobile-section text-align-center-mobile`}
                    onClick={() => this.onApplicationLinkClick()}
                    mx={4}
                    minWidth="160px"
                  >
                    <DisplayText
                      dataDisplayedAndStyle={[
                        {
                          text: stringHelpers.trimStringLengthBasedOnWindowWidth(
                            `${friendlyName}, ${suburb}`,
                            17,
                          ),
                          textColor: `${theme.colors.black}`,
                          textSize: `${theme.fontSizes[5]}`,
                          textLineHeight: '140%',
                        },
                        {
                          text: (
                            <>
                              <Box
                                dataTip=""
                                dataFor={propertyAddressTooltipId}
                              >
                                {`${bedrooms}Br,${bathrooms}Bath,${
                                  parking || garages || carports || 0
                                }Car,${formattedRent}`}
                              </Box>
                              <ReactTooltip
                                id={propertyAddressTooltipId}
                                data-type="info"
                                className="tool-tip-style"
                              >
                                <span>{`${friendlyName}, ${suburb}`}</span>
                              </ReactTooltip>
                            </>
                          ),
                          textColor: `${theme.colors.gray600}`,
                          textSize: `${theme.fontSizes[4]}`,
                          textLineHeight: '140%',
                        },
                        {
                          text: (
                            <Flex mt={3}>
                              <Box className="mr3">{viewingStr} </Box>
                              <Box
                                data-tooltip-content={''}
                                data-tooltip-id={applicationGUID + '_viewing'}
                                className={
                                  'status-circle-indicator status-circle-indicator--outline status-circle-indicator--' +
                                  `${
                                    applicantsViewingSummary.length
                                      ? 'success'
                                      : 'warning'
                                  }`
                                }
                              >
                                {''}
                              </Box>
                              <ReactTooltip
                                id={applicationGUID + '_viewing'}
                                data-type="info"
                                className="tool-tip-style"
                              >
                                <div>{viewingTooltip}</div>
                              </ReactTooltip>
                              <span>{shortlistedApplicant}</span>
                            </Flex>
                          ),
                          textColor: `${theme.colors.gray600}`,
                          textSize: `${theme.fontSizes[3]}`,
                        },
                      ]}
                    />
                    <ReactTooltip
                      id={propertyAddressTooltipId}
                      data-type="info"
                      className="tool-tip-style"
                    >
                      <span>{`${friendlyName}, ${suburb}`}</span>
                    </ReactTooltip>

                    <ReactTooltip
                      id={applicationGUID + '_viewing'}
                      data-type="info"
                      className="tool-tip-style"
                    >
                      <div>{viewingTooltip}</div>
                    </ReactTooltip>
                  </MaskedWrappingLink>
                  <MaskedWrappingLink
                    link={linkForApplication}
                    disable={!isClickable}
                    LayoutWrapperCmp={Flex}
                    flexDirection="column"
                    flexBasis="15%"
                    className={`prospect-mobile-section`}
                    onClick={() => this.onApplicationLinkClick()}
                    alignItems="center"
                    mr={5}
                  >
                    {renderApplicationStatusCmp()}
                    {autoWithdrawnBadge}
                    {autowithdrawTimeRemainingBadge}
                  </MaskedWrappingLink>
                </StyledAppRowInfo>
                <ResponsiveFlex
                  flex="0 0 27%"
                  alignItems="center"
                  deviceMaxWidth={devicesMediaMaxWidths.smallMobile}
                >
                  {!dragDisabled && leftAlignDragIcon && (
                    <Box mr={5}>
                      <DragandReset
                        isHovered={isHovered}
                        applicationGUID={applicationGUID}
                        propertyGUID={propertyGUID}
                        agencyGUID={currentTeam.guid}
                        onPropertyDataNeedUpdate={onPropertyDataNeedUpdate}
                        applicationNumber={sortPosition}
                        isDragDisabled={dragDisabled}
                      />
                    </Box>
                  )}
                  <ButtonsContainer
                    application={this.state.mappedApplication}
                    isClickable={isClickable}
                    onDisabledClicked={() => this.toggleInactiveTeamModal()}
                    status={this.state.status}
                    disabled={this.state.loading}
                    onClickDecline={this.onClickDecline}
                    onClickOffer={this.onClickOffer}
                    onClickWithdrawOffer={this.onClickWithdrawOffer}
                    onClickShare={this.onClickShareApplication}
                    teamSlug={teamSlug}
                    onAddShortListApplication={onAddShortListApplication}
                    onRemoveShortListApplication={onRemoveShortListApplication}
                    property={this.props.property}
                    moveTo={this.props.moveTo}
                    updateApplicationWorkflowStatusTo={
                      this.updateApplicationWorkflowStatusTo
                    }
                    applicationWorkflowStatus={
                      this.state.applicationWorkflowStatus
                    }
                    currentTeam={currentTeam}
                    onMarkAsLeasedBtnClick={this.onMarkAsLeasedBtnClick}
                    onMarkAsApplicationApproved={
                      this.onMarkAsApplicationApproved
                    }
                    sendUtilityReferralToLead={this.sendUtilityReferralToLead}
                    onClickScheduleViewing={this.toggleScheduleViewingModal}
                    fetchApplicationsBasedOnQuery={
                      fetchApplicationsBasedOnQuery
                    }
                    pmsIntegrationStatus={pmsIntegrationStatus}
                    refreshApplicationsList={refreshApplicationsList}
                    compactWorkflow={
                      applicationTeamSettings &&
                      applicationTeamSettings[
                        applicationsAttributesIDs.compactWorkflowEnabled
                      ]
                    }
                    shortlistWithEmail={shortlistWithEmail}
                    applicationTeamSettings={applicationTeamSettings}
                    isConsoleCloudEnabled={isConsoleCloudEnabled}
                  />
                  {!dragDisabled && !leftAlignDragIcon && (
                    <Box ml={5}>
                      <DragandReset
                        isHovered={isHovered}
                        applicationGUID={applicationGUID}
                        propertyGUID={propertyGUID}
                        agencyGUID={currentTeam.guid}
                        onPropertyDataNeedUpdate={onPropertyDataNeedUpdate}
                        applicationNumber={sortPosition}
                        isDragDisabled={dragDisabled}
                      />
                    </Box>
                  )}
                </ResponsiveFlex>
                {this.renderEmailModal()}
              </ResponsiveFlex>
              {showScheduleViewingModal && (
                <Display.EditViewingModal
                  title="Schedule Viewing"
                  primaryActionLabel="Schedule"
                  secondaryActionLabel="Cancel"
                  textToBeDisplayed={scheduleViewingModalText}
                  primaryButtonActionInParent={this.updateScheduleViewingModal}
                  secondaryButtonAction={() =>
                    this.toggleScheduleViewingModal()
                  }
                  toggleModal={() => this.toggleScheduleViewingModal()}
                  isScheduleViewing={true}
                  fetchAllPropertyFutureViewings={
                    fetchAllPropertyFutureViewings
                  }
                  teamGUID={currentTeam.guid}
                />
              )}
              {showInactiveTeamModal && (
                <InactiveTeamModal closeModal={this.toggleInactiveTeamModal} />
              )}
              {isShareWithOwnerModalOpen && (
                <ShareWithOwnerModal
                  teamSlug={teamSlug}
                  applicationGUID={sharedApplication}
                  toggleShareWithOwnerModal={this.toggleShareWithOwnerModal}
                />
              )}
            </Box>
          )
        ) : (
          <ErrorMessage
            error={prospectSummaryHelpers.ERROR_FOR_NULL_APPLICANTS}
          />
        )}
      </div>
    )
  }
}

const ApplicationRowWithRouter = withRouter(ApplicationRow)

export default ApplicationRowWithRouter

class ProspectsSummaryContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      propertyOverviews: [],
      data: [],
      response_metadata: {},
      loading: false,
      loadingError: '',
      showAllTab: false,
      loadingMore: false,
      archiveTime: 0,
      pmsIntegrationStatus: [],
      basicDataLoadingStates: createInitialLoadingState(),
      basicData: {},
      isConsoleCloudEnabled: false,
      // ballotPropertiesMap: {},
    }

    this.basicDataLoadingStatesUtils = createLoadingStateUtils(
      (loadingState) => {
        this.setState((state) => ({
          ...state,
          basicDataLoadingStates: loadingState,
        }))
      },
    )

    this.filterApplicationsId = null
  }

  UNSAFE_componentWillMount() {
    const teamSlug = this.props.match.params.teamSlug
    const curTeam = this.props.teams.find((team) => team.slug === teamSlug)
    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    // assert that applicant report navigates with applicantEmail param only
    // if other queries exists for some reason
    if (this.props.isApplicantReport && Object.keys(curQueries).length > 1) {
      history.push(
        urlTo('teamApplicantApplicationsReport', {
          teamSlug: teamSlug,
          applicantEmail: curQueries.applicantEmail,
        }),
      )
    }
    if (curQueries.applicantEmail) {
      this.props.setPropertyFilters({
        ...this.props.filters,
        applicantEmail: curQueries.applicantEmail,
      })
    }
    const { persistedManager } = this.props
    persistedManager === ''
      ? this.setState({ showAllTab: false })
      : this.setState({ showAllTab: true })
    if (curQueries.property && curTeam) {
      this.loadSinglePropertyApplications(curQueries.property, curTeam.guid, {
        applicantEmail: curQueries.applicantEmail,
      })
      return
    }

    curQueries.searchText &&
      this.setState({ searchText: curQueries.searchText })
    if (curTeam) {
      this.setState({ loading: true })
      const { filters } = this.props
      this.onFetchTeamPropertiesByRecentApplication(curTeam.guid, filters)
    }
  }

  componentDidMount() {
    const { guid } = this.props.currentTeam
    this.props.fetchAgencyStatus(guid).then(({ integrationStatus }) => {
      this.setState({
        pmsIntegrationStatus: integrationStatus,
      })
    })
    if (guid) {
      this.getAndSetConsoleCloudIntegration(guid)
    }

    this.loadBasicData()

    const element = document.querySelector('.app-content')
    element && addOnScrollLoadListeners(this.loadOnScroll, element)
  }

  componentDidUpdate(prevProps, prevState) {
    const prevQueries = qs.parse(prevProps.location.search, {
      ignoreQueryPrefix: true,
    })
    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })

    // clean state from applicantEmail in case of moving away from applicant report
    if (!curQueries.applicantEmail && this.props.filters.applicantEmail) {
      this.props.setPropertyFilters({
        ...this.props.filters,
        applicantEmail: '',
      })
    }

    if (curQueries.searchText !== prevQueries.searchText) {
      curQueries.searchText &&
        this.setState({ searchText: curQueries.searchText })
    }

    const prevTeamSlug = prevProps.match.params.teamSlug
    const prevTeam =
      prevProps.teams.find((team) => team.slug === prevTeamSlug) || {}
    const curTeamSlug = this.props.match.params.teamSlug
    const curTeam =
      this.props.teams.find((team) => team.slug === curTeamSlug) || {}

    if (curTeam.guid && curTeam.guid !== prevTeam.guid) {
      this.props
        .fetchAgencyStatus(curTeam.guid)
        .then(({ integrationStatus }) => {
          this.setState({
            pmsIntegrationStatus: integrationStatus,
          })
        })

      if (curTeam.guid) {
        this.getAndSetConsoleCloudIntegration(curTeam.guid)
      }

      this.loadBasicData()
    }

    if (curQueries.property !== prevQueries.property && !curQueries.property) {
      this.onFetchTeamPropertiesByRecentApplication(
        curTeam.guid,
        this.props.filters,
      )
    }

    if (
      curQueries.property &&
      curTeam.guid &&
      (curQueries.property !== prevQueries.property ||
        curTeam.guid !== prevTeam.guid)
    ) {
      this.setState({ loading: true })
      this.props
        .fetchTeamProperty(curTeam.guid, curQueries.property, {
          applicantEmail: curQueries.applicantEmail,
        })
        .then((propertyOverview) =>
          this.setState({
            // for the purpose of always mapping through data during render
            data: [
              {
                property:
                  prospectSummaryHelpers.selectProperty(propertyOverview),
              },
            ],
            propertyOverviews: [propertyOverview],
            loading: false,
          }),
        )
        .catch((error) => {
          this.setState({ loading: false, loadingError: error })
        })
      return
    }
    if (
      filtersHaveChanged(prevProps, this.props) ||
      prevProps.currentTeam.guid !== this.props.currentTeam.guid ||
      prevState.archiveTime !== this.state.archiveTime
    ) {
      this.setState({ propertyOverviews: [] }, () => {
        this.setState({ loading: true })
        this.onFetchTeamPropertiesByRecentApplication(
          curTeam.guid,
          this.props.filters,
        )
      })
    }
  }

  componentWillUnmount() {
    const element = document.querySelector('.app-content')
    element && removeOnScrollLoadListeners(this.loadOnScroll, element)
  }

  getAndSetConsoleCloudIntegration = (agencyGUID) => {
    getConsoleCloudIntegration(agencyGUID)
      .then(({ result }) => {
        this.setState({
          isConsoleCloudEnabled:
            result && result.status === textHelpers.CONSOLE_CLOUD_ACTIVE,
        })
      })
      .catch((err) => snugNotifier.error(err))
  }

  loadBasicData = () => {
    const { currentTeam } = this.props
    const basicDataPromise = getTeamSettings(currentTeam.guid, {
      keys: ['application', 'integrations'],
    }).then(({ settingInfo }) => {
      const { application = {}, integrations = {} } = settingInfo
      const applicationTeamSettings =
        parseDataUtil.convertObjValueFromStringToBoolean(application)
      this.setState({
        integrationsTeamSettings: integrations,
        applicationTeamSettings,
      })
    })
    return promiseWrapperFactory(this.basicDataLoadingStatesUtils)(
      basicDataPromise,
    )
  }

  onClickSortBy = (field, order, propertyGUID) => {
    this.setState({
      propertyOverviews: this.state.propertyOverviews.map(
        (propertyOverview) => {
          const property =
            prospectSummaryHelpers.selectProperty(propertyOverview)
          if (
            prospectSummaryHelpers.selectPropertyGUID(property) === propertyGUID
          ) {
            return {
              ...propertyOverview,
              applications: prospectSummaryHelpers
                .selectApplications(propertyOverview)
                .slice()
                .sort(prospectSummaryHelpers.selectSortFunc(field, order)),
            }
          } else {
            return propertyOverview
          }
        },
      ),
    })
  }

  onPropertyDataNeedUpdate = (propertyGUID) => {
    const { currentTeam, filters } = this.props
    return this.props
      .fetchTeamProperty(currentTeam.guid, propertyGUID, filters)
      .then((propertyOverview) => {
        this.setState((prevState) => {
          return {
            propertyOverviews: prevState.propertyOverviews.map(
              (prevOverview) => {
                if (prevOverview.property.guidID !== propertyGUID)
                  return prevOverview
                return propertyOverview
              },
            ),
          }
        })
      })
  }

  onFetchTeamPropertiesByRecentApplication = debounce(
    (teamGuid, queryString) => {
      this.filterApplicationsId = Date.now()
      const curQueries = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      })

      const { property } = curQueries
      const { manager, searchText, applicantEmail } = queryString
      if (!!property) {
        this.loadSinglePropertyApplications(property, teamGuid, {
          manager,
          searchText,
          applicantEmail,
        })
        return
      }
      const currentFilterId = this.filterApplicationsId
      this.props
        .fetchTeamPropertiesByRecentApplication(teamGuid, queryString)
        .then(({ properties }) => {
          const { data, response_metadata } = properties
          this.setState(
            {
              data,
              response_metadata,
              loading: false,
              propertyOverviews: Array(data.length).fill({}),
            },
            () => {
              properties.data.forEach((item, index) => {
                const options = {
                  manager,
                  searchText,
                  applicantEmail: queryString.applicantEmail,
                }
                if (
                  !property ||
                  (property && property === item.property.guidID)
                ) {
                  const offer =
                    (item?.property?.offers || []).find((o) => o?.isActive) ||
                    {}

                  const { ballotsEnabled = false } = offer || {}
                  if (!ballotsEnabled) {
                    this.props
                      .fetchTeamProperty(
                        teamGuid,
                        item.property.guidID,
                        options,
                      )
                      .then((propertyOverview) => {
                        if (currentFilterId !== this.filterApplicationsId) {
                          //not set PropertyOverview State if the currentFilterId in this function scope is different from the global page variable
                          return
                        }

                        this.setState((prevState) => ({
                          propertyOverviews: prevState.propertyOverviews.map(
                            (prevPropertyOverview, prevIndex) => {
                              if (index === prevIndex) {
                                return propertyOverview
                              } else {
                                return prevPropertyOverview
                              }
                            },
                          ),
                        }))
                      })
                      .catch((error) => {
                        this.onPropertyOverviewErrorReceived(error, index)
                      })
                  } else {
                    this.fetchBallotsDetail(
                      item.property.guidID,
                      teamGuid,
                      index,
                      options,
                    )
                  }
                }
              })
            },
          )
        })
        .catch((error) => {
          this.setState({ loading: false, loadingError: error })
        })
    },
    STANDARD_TIMEOUT,
  )

  prepareSinglePropertyBallotState = (
    pOverview,
    ballotAppsIds,
    run,
    count,
    completedAppsNum,
  ) => {
    const overview = {
      ...pOverview,
      applications: pOverview?.applications
        ?.filter(({ guidID }) => (ballotAppsIds || []).includes(guidID))
        .sort(
          (a, b) =>
            ballotAppsIds.indexOf(a.guidID) - ballotAppsIds.indexOf(b.guidID),
        ),
    }
    const propertyId = prospectSummaryHelpers.selectPropertyGUID(
      prospectSummaryHelpers.selectProperty(pOverview),
    )
    const ballotPropertyMap = {
      [propertyId]: {
        applicationsIds: ballotAppsIds,
        runRecord: run,
        count,
        completedAppsNum,
      },
    }
    return { overview, ballotPropertyMap }
  }

  loadSinglePropertyApplications = (propertyId, teamId, filters) => {
    this.setState({ loading: true })
    this.props
      .fetchTeamProperty(teamId, propertyId, filters)
      .then((propertyOverview) => {
        const offer = propertyOverview.property.offers?.find((o) => o.isActive)

        if (offer?.ballotsEnabled) {
          this.props
            .getPropertyBallotsDetail(propertyId)
            .then(({ count, applicationIDs = [], run, completedAppsNum }) => {
              const { overview, ballotPropertyMap } =
                this.prepareSinglePropertyBallotState(
                  propertyOverview,
                  applicationIDs,
                  run,
                  count,
                  completedAppsNum,
                )
              this.setState({
                // for the purpose of always mapping through data during render
                data: [
                  {
                    property:
                      prospectSummaryHelpers.selectProperty(propertyOverview),
                  },
                ],
                ballotPropertiesMap: {
                  ...this.state.ballotPropertiesMap,
                  ...ballotPropertyMap,
                },
                propertyOverviews: [overview],
                loading: false,
              })
            })
            .catch((error) => {
              this.setState({ loading: false, loadingError: error.message })
            })
        } else {
          this.setState({
            // for the purpose of always mapping through data during render
            data: [
              {
                property:
                  prospectSummaryHelpers.selectProperty(propertyOverview),
              },
            ],
            propertyOverviews: [propertyOverview],
            loading: false,
          })
        }
      })
      .catch((error) => {
        this.setState({ loading: false, loadingError: error.message })
      })
  }

  fetchBallotsDetail = (
    propertyGUID,
    teamGUID,
    propertyIndex,
    propertyOptions,
  ) => {
    this.props
      .getPropertyBallotsDetail(propertyGUID)
      .then(({ count, applicationIDs = [], run, completedAppsNum }) => {
        this.props
          .fetchTeamProperty(teamGUID, propertyGUID, propertyOptions)
          .then((propertyOverview) => {
            const { overview, ballotPropertyMap } =
              this.prepareSinglePropertyBallotState(
                propertyOverview,
                applicationIDs,
                run,
                count,
                completedAppsNum,
              )
            this.setState((prevState) => ({
              ballotPropertiesMap: {
                ...this.state.ballotPropertiesMap,
                ...ballotPropertyMap,
              },
              propertyOverviews: prevState.propertyOverviews.map(
                (prevPropertyOverview, prevIndex) => {
                  return propertyIndex === prevIndex
                    ? overview
                    : prevPropertyOverview
                },
              ),
            }))
          })
          .catch((error) => {
            this.onPropertyOverviewErrorReceived(error, propertyIndex)
          })
      })
      .catch((error) => {
        this.onPropertyOverviewErrorReceived(error.message, propertyIndex)
      })
  }

  onPropertyOverviewErrorReceived = (error, index) => {
    this.setState((prevState) => ({
      propertyOverviews: prevState.propertyOverviews.map(
        (prevPropertyOverview, prevIndex) => {
          if (index === prevIndex) {
            return { error }
          } else {
            return prevPropertyOverview
          }
        },
      ),
    }))
  }

  setAPIError = (apiError) => {
    snugNotifier.error(apiError)
  }

  archiveTimeAdder = () => {
    this.setState({ archiveTime: this.state.archiveTime + 1 })
  }

  fetchApplicationsBasedOnQuery = debounce(() => {
    const teamSlug = this.props.match.params.teamSlug
    const curTeam = this.props.teams.find((team) => team.slug === teamSlug)
    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const { persistedManager } = this.props
    persistedManager === ''
      ? this.setState({ showAllTab: false })
      : this.setState({ showAllTab: true })
    if (curQueries.property && curTeam) {
      this.setState({ loading: true })
      this.props
        .fetchTeamProperty(curTeam.guid, curQueries.property, {
          applicantEmail: curQueries.applicantEmail,
        })
        .then((propertyOverview) =>
          this.setState({
            // for the purpose of always mapping through data during render
            data: [
              {
                property:
                  prospectSummaryHelpers.selectProperty(propertyOverview),
              },
            ],
            propertyOverviews: [propertyOverview],
            loading: false,
          }),
        )
        .catch((error) => {
          this.setState({ loading: false, loadingError: error })
        })
    }
  }, STANDARD_TIMEOUT)

  loadData = (filters) => {
    const url = prospectSummaryHelpers.generateURL(
      this.props.match.params.teamSlug,
      filters,
    )
    history.push(url)
  }

  loadOnScroll = () => {
    const {
      response_metadata = {},
      propertyOverviews = [],
      loadingMore,
    } = this.state
    if (response_metadata.total <= propertyOverviews.length) {
      return
    }
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { property: specificPropertyID } = curQueries
    if (!!specificPropertyID) {
      return
    }
    const cursor = this.state.response_metadata.next_cursor || ''

    const docHeight = document.body.clientHeight
    const winHeight = window.innerHeight
    const bottomElement = document.getElementById('content-end')
    const elementRect = bottomElement && bottomElement.getBoundingClientRect()
    const rectBottom = elementRect && elementRect.bottom

    if (isScrollAtEnd(rectBottom, docHeight, winHeight) && !loadingMore) {
      const curTeamSlug = this.props.match.params.teamSlug
      const curTeam =
        this.props.teams.find((team) => team.slug === curTeamSlug) || {}
      const options = {
        ...this.props.filters,
        cursor,
      }
      const { manager } = options
      this.setState({ loadingMore: true })
      this.props
        .fetchTeamPropertiesByRecentApplication(curTeam.guid, options)
        .then(({ properties }) => {
          const { data, response_metadata } = properties
          const existingPropertyOverviewLength =
            this.state.propertyOverviews.length
          this.setState(
            {
              data: this.state.data.concat(data),
              response_metadata,
              loadingMore: false,
              propertyOverviews: this.state.propertyOverviews.concat(
                Array(data.length).fill({}),
              ),
            },
            () => {
              properties.data.forEach((item, index) => {
                const offer =
                  (item?.property?.offers || []).find((o) => o?.isActive) || {}
                const { ballotsEnabled = false } = offer || {}
                if (!ballotsEnabled) {
                  this.props
                    .fetchTeamProperty(curTeam.guid, item.property.guidID, {
                      manager,
                      applicantEmail: curQueries.applicantEmail,
                    })
                    .then((propertyOverview) => {
                      this.setState((prevState) => ({
                        propertyOverviews: prevState.propertyOverviews.map(
                          (prevPropertyOverview, prevIndex) => {
                            if (
                              index + existingPropertyOverviewLength ===
                              prevIndex
                            ) {
                              return propertyOverview
                            } else {
                              return prevPropertyOverview
                            }
                          },
                        ),
                      }))
                    })
                    .catch((error) => {
                      this.setState((prevState) => ({
                        propertyOverviews: prevState.propertyOverviews.map(
                          (prevPropertyOverview, prevIndex) => {
                            if (
                              index + existingPropertyOverviewLength ===
                              prevIndex
                            ) {
                              return { error }
                            } else {
                              return prevPropertyOverview
                            }
                          },
                        ),
                      }))
                    })
                } else {
                  this.fetchBallotsDetail(
                    item.property.guidID,
                    curTeam.guid,
                    index + existingPropertyOverviewLength,
                    options,
                  )
                }
              })
            },
          )
        })
        .catch((loadingError) => {
          this.setState({ loadingMore: false, loadingError })
        })
    }
  }

  async refreshApplicationsList() {
    const teamSlug = this.props.match.params.teamSlug
    const curTeam = this.props.teams.find((team) => team.slug === teamSlug)
    if (curTeam) {
      this.setState({ loading: true })
      const { filters } = this.props
      await this.onFetchTeamPropertiesByRecentApplication(curTeam.guid, filters)
    }
    this.setState({ loading: false })
  }

  renderPropertySection = (propertyData, propertyIndex) => {
    const teamSlug = this.props.match.params.teamSlug
    const {
      isPropertyLoading,
      applicationTeamSettings,
      integrationsTeamSettings,
      isConsoleCloudEnabled = false,
    } = this.state
    const {
      shortListApplication,
      removeShortListApplication,
      currentTeam = {},
      fetchProgressApplication,
      markProgressApplicationAsLeased,
      markProgressApplicationAsApproved,
      inviteToApplyApplicant,
      sendUtilityReferral,
      fetchUtilityReferralStatus,
      fetchApplicationNotesWithLimit,
      fetchAllPropertyFutureViewings,
      addViewing,
      sendScheduleViewingFromApplication,
      isApplicantReport = false,
      filters,
      currentAgency,
    } = this.props

    const { property = {} } = propertyData || {}

    const offer = (property?.offers || []).find((o) => o.isActive) || {}

    const { ballotsEnabled } = offer || {}
    const {
      leased_application_guid = '',
      sentUtilityLeads,
      allApplicationsForPropertyPresent = true,
    } = this.state.propertyOverviews.length > 0 &&
    this.state.propertyOverviews[propertyIndex]

    const isFirstItem = propertyIndex === 0
    const appCueTag = isFirstItem ? 'fist-utility-status-application' : ''
    const propertyOverview = this.state.propertyOverviews[propertyIndex]
    const ballotPropertyDetail =
      this.state.ballotPropertiesMap?.[
        prospectSummaryHelpers.selectPropertyGUID(property)
      ]

    const applications =
      prospectSummaryHelpers.selectApplications(propertyOverview)
    const propertyOverviewError = propertyOverview && propertyOverview.error
    const searchText = (filters && filters.searchText) || ''
    return (
      <IntegrationsTeamSettingsContext.Provider
        value={integrationsTeamSettings}
      >
        <ApplicationTableContainer
          key={propertyIndex}
          appCueTag={appCueTag}
          property={property}
          applications={applications}
          applicationTeamSettings={applicationTeamSettings}
          leased_application_guid={leased_application_guid}
          setAPIError={this.setAPIError}
          onClickSortBy={this.onClickSortBy}
          teamSlug={teamSlug}
          shortListApplication={shortListApplication}
          removeShortListApplication={removeShortListApplication}
          declineApplication={this.props.declineApplication}
          offerApplication={this.props.offerApplication}
          withdrawOfferForApplication={this.props.withdrawOfferForApplication}
          moveTo={this.props.moveTo}
          currentTeam={currentTeam}
          fetchProgressApplication={fetchProgressApplication}
          markProgressApplicationAsLeased={markProgressApplicationAsLeased}
          markProgressApplicationAsApproved={markProgressApplicationAsApproved}
          propertyOverviewError={propertyOverviewError}
          sentUtilityLeads={sentUtilityLeads}
          archiveTimeAdder={this.archiveTimeAdder}
          inviteToApplyApplicant={inviteToApplyApplicant}
          sendUtilityReferral={sendUtilityReferral}
          fetchUtilityReferralStatus={fetchUtilityReferralStatus}
          fetchAllPropertyFutureViewings={fetchAllPropertyFutureViewings}
          addViewing={addViewing}
          isPropertyLoading={isPropertyLoading}
          fetchApplicationNotesWithLimit={fetchApplicationNotesWithLimit}
          sendScheduleViewingFromApplication={
            sendScheduleViewingFromApplication
          }
          fetchApplicationsBasedOnQuery={this.fetchApplicationsBasedOnQuery}
          searchText={searchText}
          pmsIntegrationStatus={this.state.pmsIntegrationStatus}
          refreshApplicationsList={() => this.refreshApplicationsList()}
          filterByApplicantEmail={this.filterByApplicantEmail}
          isApplicantReport={isApplicantReport}
          onPropertyDataNeedUpdate={() =>
            this.onPropertyDataNeedUpdate(property.guidID)
          }
          currentAgency={currentAgency}
          ballotsEnabled={ballotsEnabled}
          ballotPropertyDetail={ballotPropertyDetail}
          isConsoleCloudEnabled={isConsoleCloudEnabled}
          allApplicationsForPropertyPresent={allApplicationsForPropertyPresent}
        />
      </IntegrationsTeamSettingsContext.Provider>
    )
  }

  renderPropertiesSections = () => {
    const { data: propertiesOverviewData } = this.state

    if (!propertiesOverviewData || !propertiesOverviewData.length) {
      return <NoPropertyContainer />
    }

    return propertiesOverviewData.map((propertyData, index) =>
      this.renderPropertySection(propertyData, index),
    )
  }

  render() {
    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const teamSlug = this.props.match.params.teamSlug
    const { loading } = this.state
    const curTeam =
      this.props.teams.find((team) => team.slug === teamSlug) || {}
    const { backUrl } = this.props
    const isFromRenterApplication =
      backUrl.includes('sm/property') && backUrl.includes('applications')
    const { guid = '' } = curTeam
    const crumbs = [
      {
        text: curTeam.name || 'Team',
        link: urlTo('teamOverview', {
          teamSlug: this.props.match.params.teamSlug,
        }),
      },
    ]

    let secondCrumb = null
    if (!curQueries.stage && !isFromRenterApplication) {
      secondCrumb = {
        text: 'Overview',
        link: urlTo('teamOverview', {
          teamSlug: this.props.match.params.teamSlug,
        }),
      }
    } else if (
      (curQueries.stage && curQueries.stage.toLowerCase() === 'application') ||
      isFromRenterApplication
    ) {
      secondCrumb = {
        text: 'Applications',
        link: prospectSummaryHelpers.generateURL(
          this.props.match.params.teamSlug,
          {
            stage: 'Application',
          },
        ),
      }
    } else if (
      curQueries.stage &&
      curQueries.stage.toLowerCase() === 'viewing'
    ) {
      secondCrumb = {
        text: 'Viewings',
        link: prospectSummaryHelpers.generateURL(
          this.props.match.params.teamSlug,
          {
            stage: 'Viewing',
          },
        ),
      }
    }

    let lastCrumb = null
    if (curQueries.property && this.state.propertyOverviews.length === 1) {
      lastCrumb = {
        text: prospectSummaryHelpers.selectAddressFriendlyName(
          prospectSummaryHelpers.selectProperty(
            this.state.propertyOverviews[0],
          ),
        ),
        link: '#',
      }
    } else if (curQueries.searchText) {
      lastCrumb = {
        text: `search: "${this.state.searchText}"`,
        link: '#',
      }
    } else if (
      this.props.filters?.applicantEmail &&
      this.state.propertyOverviews?.length
    ) {
      lastCrumb = {
        text: this.state.propertyOverviews?.[0].applications?.[0]?.applicants[0]
          .firstName,
        link: '#',
      }
    }
    const isThereMore = this.state.response_metadata.next_cursor
    const isApplicationsPage =
      curQueries.stage && curQueries.stage.toLowerCase() === 'application'
    const placeholderTextForSearch = isApplicationsPage
      ? searchAddressEmailNamePhonePlaceholder
      : 'property address'
    const { isApplicantReport = false } = this.props

    const { basicDataLoadingStates } = this.state

    return (
      <div>
        <Breadcrumbs
          crumbs={crumbs.concat(secondCrumb || {}).concat(lastCrumb || [])}
        />
        <ApplicationsListHeader />
        {!isApplicantReport && (
          <AgencyFilterSection
            disableSearchStatus
            disableSearchTodayOnly
            disableDatePicker
            disableSearchManager
            enableViewingSearchManager={true}
            enableUnAssigned={true}
            placeholderText={placeholderTextForSearch}
            disableSortby={teamSlug === textHelpers.VARC_TEAM_NAME}
          />
        )}

        <LoadingSection
          loadingState={basicDataLoadingStates}
          actionHandler={() => this.loadBasicData()}
          loaderProps={{ fontSize: '50px' }}
        >
          {/* todo: get rid from the following spinner and just add the loaded/basic data to loadBasicData method */}
          {loading ? <Spinner /> : <div>{this.renderPropertiesSections()}</div>}
        </LoadingSection>

        {isThereMore && (
          <div id="content-end">{this.state.loadingMore && <Spinner />}</div>
        )}
        <ErrorMessage error={this.state.loadingError} />
      </div>
    )
  }
}

// connect to redux
const mapStateToProps = ({ session, MyProperties, Shared, TeamOverview }) => ({
  teams: session.teams,
  persistedManager: MyProperties.persistedManager,
  backUrl: Shared.backUrl,
  currentAgency: session.currentAgency,
  currentTeam: session.currentTeam,
  filters: TeamOverview.filters,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTeamProperty: (teamGUID, propertyGUID, options) =>
    dispatch(fetchTeamProperty(teamGUID, propertyGUID, options)),
  getPropertyBallotsDetail: (propertyGUID) =>
    dispatch(getPropertyBallotsDetail(propertyGUID)),
  fetchUtilityReferralStatus: (applicationGUID) =>
    dispatch(fetchUtilityReferralStatus(applicationGUID)),
  fetchAllPropertyFutureViewings: (teamGUID) =>
    dispatch(fetchAllPropertyFutureViewings(teamGUID)),
  fetchTeamPropertiesByRecentApplication: (teamGUID, options) =>
    dispatch(fetchTeamPropertiesByRecentApplication(teamGUID, options)),
  persistManager: (manager) => dispatch(persistManager(manager)),
  shortListApplication: (applicationID) =>
    dispatch(shortlistApplication(applicationID)),
  removeShortListApplication: (applicationID) =>
    dispatch(unshortlistApplication(applicationID)),
  declineApplication: (
    agencyGUID,
    applicationID,
    declineReasons,
    notifyApplicant,
    otherReasonComment,
  ) =>
    dispatch(
      declineApplication(
        agencyGUID,
        applicationID,
        declineReasons,
        notifyApplicant,
        otherReasonComment,
      ),
    ),
  offerApplication: (applicationID) =>
    dispatch(offerApplication(applicationID)),
  withdrawOfferForApplication: (applicationID) =>
    dispatch(withdrawOfferForApplication(applicationID)),
  moveTo: (applicationId, newStatus) =>
    dispatch(moveTo(applicationId, newStatus)),
  fetchProgressApplication: (applicationId, agencyId) =>
    dispatch(fetchLeasingOfferInfo(applicationId, agencyId)),
  inviteToApplyApplicant: (applicationGUID) =>
    dispatch(inviteToApplyApplicant(applicationGUID)),
  markProgressApplicationAsLeased: (
    applicationId,
    applicationInfo,
    markAsLeasedOptions,
  ) =>
    dispatch(
      markProgressApplicationAsLeased(
        applicationId,
        applicationInfo,
        markAsLeasedOptions,
      ),
    ),
  markProgressApplicationAsApproved: (
    applicationId,
    applicationInfo,
    agencyGUID,
    markAsApplicationApprovedOptions,
  ) =>
    dispatch(
      markProgressApplicationAsApproved(
        applicationId,
        applicationInfo,
        agencyGUID,
        markAsApplicationApprovedOptions,
      ),
    ),
  sendUtilityReferral: (applicationId, force) =>
    dispatch(sendUtilityReferral(applicationId, force)),
  fetchNotesWithLimit: (ownerGUID, ownerType, notesLimit) =>
    dispatch(fetchNotesWithLimit(ownerGUID, ownerType, notesLimit)),
  fetchApplicationNotesWithLimit: (applicationGUID, notesLimit) =>
    dispatch(fetchApplicationNotesWithLimit(applicationGUID, notesLimit)),
  sendScheduleViewingFromApplication: (
    applicationGUID,
    applicantGUID,
    viewingGUID,
  ) =>
    dispatch(
      sendScheduleViewingFromApplication(
        applicationGUID,
        applicantGUID,
        viewingGUID,
      ),
    ),
  addViewing: (
    id,
    startdate,
    duration,
    fromScheduler,
    skipDoubleBookingCheck,
    options,
  ) =>
    dispatch(
      addViewing(
        id,
        startdate,
        duration,
        fromScheduler,
        skipDoubleBookingCheck,
        options,
      ),
    ),
  fetchAgencyStatus: (agencyGUID) => dispatch(fetchAgencyStatus(agencyGUID)),
  setPropertyFilters: (filters) => dispatch(setPropertyFilters(filters)),
})

export const ApplicationsSummary = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProspectsSummaryContainer)
