import React, { useState } from 'react'

import { Field, Form } from 'formik'
import styled from 'styled-components'

import { Alert, Button, Flex } from 'app/components/design-system-components'
import {
  InputField,
  TextArea,
} from 'app/components/design-system-components/inputs'
import * as getters from 'app/components/design-system-components/theme/getters'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { formFields } from 'app/sm/ppp/property_components/share/InviteToApplyModal/form.utils'
import { InvitePreview } from 'app/sm/ppp/property_components/share/InviteToApplyModal/InvitePreview'

const StyledFieldWrapper = styled(FieldWrapper)`
  margin-bottom: ${getters.spacing(5)};
`

export const FormBody = ({
  formBag: { isValid, status, values },
  property,
}) => {
  const [showPreview, setShowPreview] = useState(false)
  return (
    <Form>
      {!!status && !!status.apiGeneralError && (
        <Alert variant="warningWithBg" mt={theme.space[3] + 'px'}>
          {status.apiGeneralError}
        </Alert>
      )}

      <StyledFieldWrapper
        id={formFields.recipient}
        name={formFields.recipient}
        label="Recipient"
        required
        mb={theme.space[5] + 'px'}
        mt={theme.space[3] + 'px'}
      >
        <InputField
          name={formFields.recipient}
          id={formFields.recipient}
          inputProps={{ placeholder: 'Enter recipient email address' }}
        />
      </StyledFieldWrapper>

      {showPreview ? (
        <InvitePreview {...values} property={property} />
      ) : (
        <StyledFieldWrapper
          id={formFields.message}
          name={formFields.message}
          label="Message"
        >
          <Field name={formFields.message} as={TextArea} rows={10} />
        </StyledFieldWrapper>
      )}

      <Flex alignItems="center">
        <Button
          type="button"
          variant="solidSecondary"
          flex="1 1 30%"
          mr={theme.space[3] + 'px'}
          onClick={() => {
            setShowPreview(!showPreview)
          }}
        >
          {!showPreview ? 'Preview' : 'Edit'}
        </Button>
        <Button disabled={!isValid} type="submit" flex="1 1 70%">
          Send invitation
        </Button>
      </Flex>
    </Form>
  )
}
