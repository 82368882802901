import { connect } from 'react-redux'

import {
  fetchCards,
  fetchRequestSummary,
  registerNewCard,
} from 'app/bond_cover/bond_cover_actions'
import NewCreditCardContainer from 'app/bond_cover/renters/identity/new_credit_card_container'

const mapStateToProps = ({ bondCover, session }) => ({
  currentUser: session.currentUser,
  currentLease: bondCover.currentLease,
  bankAccount: bondCover.bankAccount,
  quote: bondCover.quote,
  apiError: bondCover.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchRequestSummary: (requestID) => fetchRequestSummary(requestID),
  fetchCards: () => fetchCards(),
  registerNewCard: (card) => registerNewCard(card),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCreditCardContainer)
