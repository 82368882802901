import { productionEnv, version } from 'config/env'
import moment from 'moment'
import { call, delay, put, select, takeEvery } from 'redux-saga/effects'

import {
  actions as coreActions,
  actionTypes as coreActionTypes,
} from 'app/core/coreSlice'
import { getLatestAppVersion } from 'app/services/http/core/appVersion'
import * as SnugLogger from 'app/services/snugLogger'
import { SessionStorageUtils } from 'app/storage_utils'

const showMessagePerDaysIntervalCondition = (daysInterval) => (state) => {
  const lastUpdateTime = state.core.lastUpdateVersionMessageTime
  if (!lastUpdateTime) return true
  return moment().diff(moment(lastUpdateTime), 'days') >= daysInterval
}

const validatingMaxAllowedNewVersionMessagesOptions = {
  oncePerDay: showMessagePerDaysIntervalCondition(1),
  oncePerThreeDays: showMessagePerDaysIntervalCondition(3),
  oncePerWeek: showMessagePerDaysIntervalCondition(7),
}

const recheckVersionTimeInSeconds = 15 * 60 // 15 minutes

const isShowingNewVersionMessagesAllowed =
  validatingMaxAllowedNewVersionMessagesOptions.oncePerWeek

const appVisibilitySelector = (state) => state.core.appIsVisible

const isRehydrated = (state) => state.core._persist.rehydrated

export const refreshAndUpdateApplication = () => {
  const currentUrl = `${window.location.pathname}?${window.location.search}`
  SessionStorageUtils.setItem('forwardUrl', currentUrl)
  navigator.serviceWorker.getRegistration().then(function (reg) {
    if (reg) {
      reg.unregister().then((unregistered) => {
        if (unregistered) {
          window.location.replace('/')
        } else {
          SnugLogger.warn(
            'could not update application because of a failure in unregistering current service-worker',
          )
        }
      })
    } else {
      SnugLogger.warn(
        'could not update application because of a failure in getting current service-worker registration',
      )
    }
  })
}

export function* checkNewVersionWorker() {
  const appIsVisible = yield select(appVisibilitySelector)

  if (productionEnv() && appIsVisible) {
    try {
      const versionResponse = yield call(getLatestAppVersion)
      const { version: latestAvailableVersion, force = false } = versionResponse
      const currentVersion = version

      const isAllowedToShowMessage = yield select(
        isShowingNewVersionMessagesAllowed,
      )

      const updateExist =
        latestAvailableVersion && currentVersion !== latestAvailableVersion

      if (updateExist && force) {
        refreshAndUpdateApplication()
      } else if (updateExist && isAllowedToShowMessage) {
        yield put(coreActions.updateNewVersionToDownloadStatus(true))
        yield put(
          coreActions.updateLastNewVersionMessageTime(moment().format()),
        )
      }
    } catch {}
  }

  yield delay(recheckVersionTimeInSeconds * 1000)
  yield put(coreActions.checkNewVersion())
}

export function* watchCheckNewVersion() {
  yield takeEvery(coreActionTypes.checkNewVersion, checkNewVersionWorker)
}

export function* watchNewServiceWorkerRegistered() {
  yield takeEvery(
    coreActionTypes.notifyNewServiceWorkerRegistered,
    function* ({ waitingRegistration }) {
      const isStateRehydrated = yield select(isRehydrated)
      if (!isStateRehydrated) {
        yield delay(1000)
        yield put(
          coreActions.notifyNewServiceWorkerRegistered(waitingRegistration),
        )
        return
      }

      const isAllowedToShowMessage = yield select(
        isShowingNewVersionMessagesAllowed,
      )
      if (isAllowedToShowMessage) {
        yield put(
          coreActions.updateNewVersionToActivateStatus(
            true,
            waitingRegistration,
          ),
        )
        yield put(
          coreActions.updateLastNewVersionMessageTime(moment().format()),
        )
      }
    },
  )
}
