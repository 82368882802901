import React from 'react'

const Amenity = ({
  id,
  name,
  subs,
  openSubs,
  toggleOpenSubs,
  updateCheckbox,
  isChecked,
  isCommonAmenitie,
}) => {
  let subCheckboxes, cog
  let isOpen = openSubs.has(id.toString())
  let sortDirection = isOpen ? 'icon-arrow-up' : 'icon-arrow-down'
  let parentId = id.toString()

  if (subs) {
    // subIds = subs.map(sub => sub.id.toString())
    cog = (
      <div
        id={id}
        className="sm-checkbox-list__toggle"
        onClick={toggleOpenSubs()}
      >
        <i
          className={`${sortDirection}`}
          aria-hidden="true"
          title="More options"
        />
      </div>
    )
    if (isOpen) {
      subCheckboxes = subs.map(({ id, name }) => (
        <label key={id}>
          <input
            id={id}
            type="checkbox"
            name="commonAmenities"
            onChange={updateCheckbox(id, isCommonAmenitie, parentId, true)}
            checked={isChecked(id, isCommonAmenitie, parentId, true)}
          />
          <span>{name}</span>
        </label>
      ))
    }
  }
  return (
    <div className="sm-checkbox-list__item">
      <label key={id}>
        <input
          id={id}
          type="checkbox"
          name="commonAmenities"
          onChange={updateCheckbox(id, isCommonAmenitie)}
          checked={isChecked(id, isCommonAmenitie)}
        />
        <span>{name}</span>
        <div className="sm-checkbox-list__subitems">{subCheckboxes}</div>
      </label>
      {cog}
    </div>
  )
}

export default Amenity
