import React from 'react'

import moment from 'moment'
import DateTime from 'react-datetime'

const TimePicker = ({ onChangeHandler, time, placeholder }) => {
  return (
    <DateTime
      dateFormat={false}
      timeFormat="hh:mm A"
      inputProps={{
        placeholder: placeholder,
        className: `timePickerStyle`,
      }}
      onChange={(date) => {
        onChangeHandler(moment(date, 'HH:mm').format('HH:mm'))
      }}
      value={time && moment(time, 'HH:mm').format('hh:mm A')}
      timeConstraints={{
        minutes: {
          step: 5,
        },
      }}
    />
  )
}

export default TimePicker
