const selectAllActionTypes = {
  updateActivation: 'UPDATE_ACTIVATION',
  updateSelection: 'UPDATE_SELECTION',
  resetState: 'RESET_STATE',
}

export const updateActivation = (active) => ({
  type: selectAllActionTypes.updateActivation,
  active,
})

export const updateSelection = (allSelected) => ({
  type: selectAllActionTypes.updateSelection,
  allSelected,
})

export const resetState = () => ({ type: selectAllActionTypes.resetState })

export const selectAllDefaultState = {
  isModeActive: false,
  allSelected: false,
}

export const selectAllReducer = (state, action) => {
  const { type } = action
  switch (type) {
    case selectAllActionTypes.updateActivation: {
      const { active } = action
      const { isModeActive } = state
      if (isModeActive !== active) {
        return {
          ...state,
          isModeActive: active,
        }
      }

      return state
    }

    case selectAllActionTypes.activate: {
      return {
        ...state,
        isModeActive: true,
      }
    }

    case selectAllActionTypes.updateSelection: {
      const { allSelected } = action
      return {
        ...state,
        allSelected,
      }
    }

    case selectAllActionTypes.resetState: {
      return {
        ...selectAllDefaultState,
      }
    }
    default: {
      return state
    }
  }
}
