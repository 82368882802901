import React, { useEffect, useState } from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledSpan = styled.span`
  cursor: pointer;
  color: ${({ color }) => color};
`

const INITIAL_ID_RAND_MULTIPLIER = 100000000

const CopyableText = ({
  text,
  color = theme.colors.gray500,
  stopClickPropagation = false,
}) => {
  const [_id] = useState(`${Math.random() * INITIAL_ID_RAND_MULTIPLIER}`)
  const [copied, setCopied] = useState(false)

  const handleOnClick = (e) => {
    if (stopClickPropagation) {
      e.preventDefault()
      e.stopPropagation()
    }

    const inp = document.createElement('input')
    document.body.appendChild(inp)
    inp.value = text
    inp.select()
    document.execCommand('copy', false)
    inp.remove()

    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  useEffect(() => {
    // ReactTooltip.rebuild()
  }, [copied])

  return (
    <>
      <StyledSpan
        key={copied ? `copied-content-id` : `to-copy-text-id`}
        onClick={handleOnClick}
        data-tooltip-content={copied ? 'Copied!' : 'Click to copy'}
        data-tooltip-id={_id}
        color={color}
      >
        {text}
      </StyledSpan>
      <ReactTooltip id={_id} />
    </>
  )
}

export default CopyableText
