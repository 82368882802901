import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

import {
  firstAndLastNameInitials,
  isPropertyDraft,
  isPropertyLeased,
  isPropertyTakingApplications,
  urlTo,
} from 'app/sm/helpers'
import { FeatureFlag } from 'config/features'

const ApplicationsCard = ({ applicationsCount, property }) => {
  const propertyId = property.guidID
  return (
    <li>
      <header>
        <i className="icon-applications"></i>
        <h4>Applications</h4>
      </header>
      <main>
        {isPropertyDraft(property.status) && (
          <p>
            This is still a draft application.{' '}
            <Link to={urlTo('updatePropertyBasics', { id: propertyId })}></Link>
          </p>
        )}
        {isPropertyLeased(property.status) && (
          <p>Property rented, so no active applications.</p>
        )}
        {isPropertyTakingApplications(property.status) && (
          <p>
            You currently have{' '}
            <Link to={urlTo('renterApplications', { propertyId })}>
              {applicationsCount} in progress applications
            </Link>
            .
          </p>
        )}
      </main>
      {/* <footer>
      <Link to={"/"}>Applications History</Link>
    </footer> */}
    </li>
  )
}

const ViewingsCard = ({ property, viewings }) => {
  const status = property.status
  const propertyId = property.guidID
  const viewingsCount = (viewings || []).length
  return (
    <li>
      <header>
        <i className="icon-applications"></i>
        <h4>Viewings</h4>
      </header>
      <main>
        {isPropertyDraft(status) && (
          <p>
            This is still a draft application.{' '}
            <Link to={urlTo('updatePropertyBasics', { id: propertyId })}></Link>
          </p>
        )}
        {isPropertyLeased(status) && (
          <p>Property rented, so no active viewings.</p>
        )}
        {isPropertyTakingApplications(status) && (
          <p>
            You currently have{' '}
            <Link to={urlTo('registerBtnLinkTo', { propertyId })}>
              {viewingsCount} viewings
            </Link>
            .
          </p>
        )}
      </main>
    </li>
  )
}
// extractRenter(gv.renters).avatar
// ? <div className="avatar avatar-xsmall" style={{ backgroundImage: `url(${extractRenter(gv.renters).avatar})` }}></div>
// : <div className="avatar  blue-bg-light-rgba blue-color" >
//   {extractRenter(gv.renters).firstName ? extractRenter(gv.renters).firstName.charAt(0).toUpperCase() : ''}{extractRenter(gv.renters).lastName ? extractRenter(gv.renters).lastName.charAt(0).toUpperCase() : ''}
// </div>
const RenterInfoCard = ({
  acceptedApplication = {},
  agent = {},
  property = {},
}) => {
  const { weeklyRent, term, leaseStartDate } = acceptedApplication
  const leaseEndDate = moment(leaseStartDate).add(term, 'M')
  const todayDate = moment(new Date())
  const leftLeaseDurationByMonth = leaseEndDate.diff(todayDate, 'months')
  const leftLeaseDurationByDay = leaseEndDate.diff(todayDate, 'days')
  const leftLeaseDuration =
    leftLeaseDurationByMonth > 0
      ? `${leftLeaseDurationByMonth} months`
      : `${leftLeaseDurationByDay} days`
  const applicationId = acceptedApplication.guidID
  const propertyId = property.guidID
  return (
    <li>
      <header>
        <i className="icon-lease"></i>
        <h4>Rented next {leftLeaseDuration}</h4>
      </header>
      <main>
        <h3 className="pt15 pr3">$</h3>
        <h1>{weeklyRent}</h1>
        <h3 className="pt15">p/w</h3>
        <div className="spacer"></div>
        {agent.avatar ? (
          <div
            className="avatar avatar-xsmall"
            style={{ backgroundImage: `url(${agent.avatar})` }}
          ></div>
        ) : (
          <div className="avatar  blue-bg-light-rgba blue-color">
            {firstAndLastNameInitials(agent, 'both')}
          </div>
        )}
      </main>
      <footer>
        <Link to={urlTo('partialApplication', { propertyId, applicationId })}>
          View Application Details
        </Link>
      </footer>
    </li>
  )
}

class PropertyDashboardContent extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const {
      property,
      viewings,
      snugFit,
      applicationsCount,
      acceptedApplication,
      agent,
    } = this.props
    const status = property.status
    return (
      <div className="sm-property-dashboard-content">
        <ul>
          {isPropertyLeased(status) && (
            <RenterInfoCard
              property={property}
              acceptedApplication={acceptedApplication}
              agent={agent}
            />
          )}
          <ApplicationsCard
            applicationsCount={applicationsCount}
            property={property}
          />
          {FeatureFlag.Viewings.isOn && (
            <ViewingsCard property={property} viewings={viewings} />
          )}
        </ul>
      </div>
    )
  }
}

export default PropertyDashboardContent
