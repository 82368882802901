import { useState } from 'react'

import {
  createInitialLoadingState,
  createLoadingStateUtils,
  promiseWrapperFactory,
} from 'app/utils/loading-states'

export const useStateWithLoading = (initialState) => {
  const [state, setState] = useState(initialState)
  const [loadingStates, setLoadingStates] = useState(
    createInitialLoadingState(),
  )

  const loadingStatesHelpers = createLoadingStateUtils(setLoadingStates)

  const promiseWrapper = promiseWrapperFactory(loadingStatesHelpers)

  return {
    state,
    setState,
    loadingStates,
    loadingStatesHelpers,
    promiseWrapper,
  }
}
