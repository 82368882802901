import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import {
  Box,
  BoxInput,
  Flex,
  Select,
  Table,
} from 'app/components/design-system-components'
import { ModalButtonGroup } from 'app/components/display/display'
import * as Forms from 'app/components/forms/forms'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import Theme from 'app/pages/teams/viewings-mobile/theme-styled-components'
import { ModalHeader } from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingDetailsTabsModal'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as stringHelpers from 'app/utils/strings/helpers'

const SpanWidth = styled.span`
  display: inline-block;
  width: ${(props) => props.width}px;
`

const TotalRow = styled.div`
  padding-left: 20px;
  padding-top: 20px;
  font-size: 18px;
  border-top: 1px solid #e8e8e9;
`

const SummaryContainer = styled(Flex)`
  @media screen and (max-width: 576px) {
    flex-direction: column;
    .date-container {
      padding: 0 20px;
    }
  }
`

const TableContainer = styled.div`
  @media screen and (max-width: 576px) {
    overflow-x: auto;
    white-space: nowrap;
    table {
      display: unset;
      overflow-x: unset;
      white-space: unset;
    }
    tbody td,
    th {
      width: 150px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
    }
  }
`

const columnWidths = {
  name: '150',
  weeklyTotal: '140',
  employment: '140',
  govtBenefits: '140',
  otherBenefits: '140',
}

const defaultAvailableLease = [
  { name: '6 months', id: 6 },
  { name: '12 months', id: 12 },
  { name: '24 months', id: 24 },
  { name: '36 months', id: 36 },
]

const ApplicantEmploymentContainer = ({
  applicantsEmployment = [],
  onEditEmploymentSummary,
}) => {
  const totalIncome = applicantsEmployment.reduce(
    (res, applicant) =>
      res +
      Number(applicant.employment) +
      Number(applicant.govtWeeklyBenefits) +
      Number(applicant.otherWeeklyBenefits),
    0,
  )
  const totalEmploymentAmount = applicantsEmployment.reduce(
    (res, applicant) => res + Number(applicant.employment),
    0,
  )
  const totalGovtWeeklyAmount = applicantsEmployment.reduce(
    (res, applicant) => res + Number(applicant.govtWeeklyBenefits),
    0,
  )
  const totalOtherWeeklyAmount = applicantsEmployment.reduce(
    (res, applicant) => res + Number(applicant.otherWeeklyBenefits),
    0,
  )
  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Applicant',
        accessor: 'applicant',
        style: { width: `${columnWidths.name}px` },
        Cell: ({
          row: {
            original: { applicantName, isPrimary },
          },
        }) => {
          return (
            <span>
              {stringHelpers.firstNameAndFirstLetter(applicantName)}
              {isPrimary ? ' (Primary)' : ''}
            </span>
          )
        },
      },
      {
        Header: 'Weekly Total ($)',
        accessor: 'weeklyTotal',
        style: { fontWeight: 'bold', width: `${columnWidths.weeklyTotal}px` },
        Cell: ({
          row: {
            original: { employment, govtWeeklyBenefits, otherWeeklyBenefits },
          },
        }) => {
          const totalWeeklyIncome =
            employment + govtWeeklyBenefits + otherWeeklyBenefits
          return <span>{totalWeeklyIncome}</span>
        },
      },
      {
        Header: 'Employment ($)',
        accessor: 'employment',
        style: { width: `${columnWidths.employment}px` },
        Cell: ({
          row: {
            original: { employment },
            index,
          },
        }) => {
          return (
            <BoxInput
              initialValue={employment}
              error={``}
              onChange={(data) =>
                onEditEmploymentSummary('employment', index, data)
              }
              handleAsValueAndError={true}
            />
          )
        },
      },
      {
        Header: 'Govt. Benefits ($)',
        accessor: 'govtWeeklyBenefits',
        style: { width: `${columnWidths.govtBenefits}px` },
        Cell: ({
          row: {
            original: { govtWeeklyBenefits },
            index,
          },
        }) => {
          return (
            <BoxInput
              initialValue={govtWeeklyBenefits}
              error={``}
              onChange={(data) =>
                onEditEmploymentSummary('govtWeeklyBenefits', index, data)
              }
              handleAsValueAndError={true}
            />
          )
        },
      },
      {
        Header: 'Other ($)',
        accessor: 'otherWeeklyBenefits',
        style: { width: columnWidths.otherBenefits },
        Cell: ({
          row: {
            original: { otherWeeklyBenefits },
            index,
          },
        }) => {
          return (
            <BoxInput
              initialValue={otherWeeklyBenefits}
              error={``}
              onChange={(data) =>
                onEditEmploymentSummary('otherWeeklyBenefits', index, data)
              }
              handleAsValueAndError={true}
            />
          )
        },
      },
    ],
    [],
  )
  const dataToBeEntered = applicantsEmployment.map((applicant) => {
    return {
      applicantName: applicant.applicantName,
      isPrimary: applicant.isPrimary,
      employment: applicant.employment,
      govtWeeklyBenefits: applicant.govtWeeklyBenefits,
      otherWeeklyBenefits: applicant.otherWeeklyBenefits,
    }
  })
  return (
    <TableContainer>
      <Table
        columns={tableColumns}
        dataToBeEntered={dataToBeEntered}
        cellPadding="10px 8px 15px 20px"
        thPadding="8px 0 8px 24px"
      />
      <TotalRow>
        <b>
          <SpanWidth width={columnWidths.name}>Total ($)</SpanWidth>
          <SpanWidth width={columnWidths.weeklyTotal}>{totalIncome}</SpanWidth>
          <SpanWidth width={columnWidths.employment}>
            {totalEmploymentAmount}
          </SpanWidth>
          <SpanWidth width={columnWidths.govtBenefits}>
            {totalGovtWeeklyAmount}
          </SpanWidth>
          <SpanWidth>{totalOtherWeeklyAmount}</SpanWidth>
        </b>
      </TotalRow>
    </TableContainer>
  )
}

const EditSummaryModal = ({
  onInputChange,
  managerEditedSummary,
  toggleEditRenterApplication,
  onEditEmploymentSummary,
  applicantsEmployment,
  primaryButtonAction,
  secondaryButtonAction,
  editSummaryInProgress,
}) => {
  const { weeklyRent, leaseStart, leaseTerm, pets = 0 } = managerEditedSummary
  return (
    <Theme>
      <div className="modal-fullscreen modal-layout-flex-container">
        <ModalContentWrapper
          justifyOrAlignCenter={false}
          style={{ overflowY: 'auto' }}
        >
          <ModalHeader>
            <div className="arrow-icon" onClick={toggleEditRenterApplication}>
              <i className="icon-arrow-left color-watermelon" />
            </div>
            <h4 className="display-flex align-items-center justify-content-center fw700 flex-1">
              <span>Edit Summary</span>
            </h4>
          </ModalHeader>
          <SummaryContainer width="100%" mt="20px">
            <Flex width="100%" pl="15px" pr="20px" flex="1">
              <Box mt="36px" mr="8px">
                $
              </Box>
              <BoxInput
                labelText="Rent weekly"
                placeholder="Rent"
                onChange={(data) => onInputChange('weeklyRent', data)}
                p="2"
                handleAsValueAndError={true}
                initialValue={weeklyRent}
              />
            </Flex>
            <Flex width="100%" flex="2" mt="12px" className="date-container">
              <Forms.InputDate
                value={moment(leaseStart).format(
                  dateTimeHelpers.DATE_WITH_SLASH,
                )}
                onChange={(data) => onInputChange('leaseStart', data)}
                label="Lease Start"
                viewMode="days"
                containerClassName="width100"
                customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                readOnly={false}
                componentClass="width100"
              />
            </Flex>
            <Flex width="100%" flex="2" px="20px" height="40px">
              <Select
                placeholder="Rent"
                icon=""
                onSelectChange={(data) => onInputChange('leaseTerm', data)}
                options={defaultAvailableLease}
                label="Term"
                value={leaseTerm}
                labelText="Term"
              />
            </Flex>
            <Flex width="100%" pl="10px" pr="20px" flex="1">
              <BoxInput
                labelText="Pets"
                placeholder="Pets"
                onChange={(data) => onInputChange('pets', data)}
                p="2"
                handleAsValueAndError={true}
                initialValue={pets}
              />
            </Flex>
          </SummaryContainer>
          <Box>
            {applicantsEmployment && applicantsEmployment.length > 0 && (
              <Box pt="30px">
                <h5 className="ml15 mb20">
                  Estimated Average Weekly (After Tax)
                </h5>
                <ApplicantEmploymentContainer
                  applicantsEmployment={applicantsEmployment}
                  onEditEmploymentSummary={onEditEmploymentSummary}
                />
              </Box>
            )}
          </Box>
          <ModalButtonGroup
            primaryLabel="Save"
            secondaryLabel="Cancel"
            secondaryButtonType="tertiary"
            primaryAction={primaryButtonAction}
            secondaryAction={secondaryButtonAction}
            disable={editSummaryInProgress}
            isLoading={editSummaryInProgress}
            primaryLoaderClassName="mr5 fs14"
            secondaryDisabled={editSummaryInProgress}
          />
        </ModalContentWrapper>
      </div>
    </Theme>
  )
}

export default EditSummaryModal
