import React from 'react'

import { connect } from 'react-redux'

import { fetchUpdateAgencyUserUtilityProviderAgentID } from 'app/bond_cover/agency/agency_actions'
import * as snugNotifier from 'app/services/snugNotifier'
import { ErrorMessage } from 'app/shared_components/helpers'

class AgentUtilityProviderUserIDUpdater extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      agentUtilityProviderUserID: props.agencyUser.agentUtilityProviderUserID,
      error: '',
    }
  }

  onChange = (event) => {
    const { value } = event.target
    this.setState({ agentUtilityProviderUserID: value })
  }

  onClick = () => {
    const { isAdmin, isFinance, isLeasingConsultant } = this.props.agencyUser
    const body = {
      agentUtilityProviderUserID: this.state.agentUtilityProviderUserID,
      isAdmin,
      isFinance,
      isLeasingConsultant,
    }
    this.props
      .fetchUpdateAgencyUserUtilityProviderAgentID(
        this.props.agencyUser.guidID,
        body,
      )
      .then(() => {
        snugNotifier.success('Agency User Updated!')
        this.setState({ error: '' })
      })
      .catch((error) => {
        this.setState({ error })
      })
  }

  render() {
    return (
      <div>
        <div className="col-xl-6 row">
          <div className="col-sm-6">
            <div className="input-box">
              <input
                type="text"
                required
                className={this.state.error}
                value={this.state.agentUtilityProviderUserID}
                onChange={this.onChange}
              />
              <label>AgentUtilityProviderUserID</label>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="input-box">
              <button onClick={this.onClick}>Update</button>
            </div>
          </div>
        </div>
        <div className="col-xl-6 row">
          <ErrorMessage error={this.state.error} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = null
const mapDispatchToProps = (dispatch) => ({
  fetchUpdateAgencyUserUtilityProviderAgentID: (
    agencyUserGUID,
    agentUtilityProviderUserID,
  ) =>
    dispatch(
      fetchUpdateAgencyUserUtilityProviderAgentID(
        agencyUserGUID,
        agentUtilityProviderUserID,
      ),
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgentUtilityProviderUserIDUpdater)
