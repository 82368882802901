import React from 'react'

import moment from 'moment'

import BondSplitsForm from 'app/bond_cover/renters/tenants/bond_splits_form.jsx'
import MultiTenantErrors from 'app/shared_components/multi_tenant_errors'
import getParameter from 'app/shared_components/parameter_parser.js'
import { history } from 'app/shared_components/router'
import * as validations from 'app/shared_components/validations'

class BondSplitsFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      requestID: '',
      tenants: [],
      disabled: true,
      clicked: false,
      errors: {},
      apiError: '',
      unsavedChanges: false,
    }
  }

  UNSAFE_componentWillMount() {
    let requestID = getParameter('requestid')
    this.setState({ requestID: requestID })

    let { fetchTenants } = this.props
    fetchTenants(requestID)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { currentLease, tenants, apiError } = nextProps
    if (tenants) {
      let update = !tenants.some((tenant) => tenant.bondAmount !== 0)
      if (update) {
        this.default(currentLease, tenants)
      } else {
        this.setState({ tenants, apiError, disabled: false, clicked: false })
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    let { tenants, currentLease } = nextProps
    let multiTenantErrors = MultiTenantErrors(currentLease, tenants)
    nextState.disabled = multiTenantErrors || nextState.clicked
  }

  calculateTotal() {
    let { tenants } = this.state
    let total = 0
    tenants.map((tenant) => {
      if (tenant.bondAmount) {
        total = total + parseFloat(tenant.bondAmount)
      }
    })
    return total
  }

  calculateVariance() {
    let { currentLease } = this.props
    let { bond } = currentLease ? currentLease : ''
    let total = this.calculateTotal()

    return bond - total
  }

  default(currentLease, tenants) {
    let averageBond = currentLease.bond / tenants.length
    tenants.map((tenant) => {
      tenant.bondAmount = averageBond
    })
    this.setState({ tenants, unsavedChanges: true })
  }

  isNumeric(n) {
    if (!n) {
      return true
    }
    return !isNaN(parseFloat(n)) && isFinite(n)
  }

  reset = () => {
    let { currentLease } = this.props
    let { tenants } = this.state
    this.default(currentLease, tenants)
  }

  submit() {
    let { splitBond } = this.props
    this.setState({ clicked: true, unsavedChanges: false })
    splitBond(this.state.requestID, this.state.tenants)
  }

  trimField(guidID) {
    return (event) => {
      let { value } = event.target
      let bondAmount = parseFloat(value)
      let { tenants } = this.state
      let index = tenants.findIndex((x) => x.guidID === guidID)
      tenants[index].bondAmount = bondAmount
      this.setState({ tenants })
    }
  }

  update(guidID) {
    return (event) => {
      let { value } = event.target
      if (this.isNumeric(value)) {
        let bondAmount = parseFloat(value)
        if (value.endsWith('.')) {
          bondAmount = bondAmount + '.'
        } else if (value.endsWith('.0')) {
          bondAmount = bondAmount + '.0'
        }

        let { tenants } = this.state
        let index = tenants.findIndex((x) => x.guidID === guidID)
        tenants[index].bondAmount = bondAmount
        this.setState({ tenants, unsavedChanges: true })
      }
    }
  }

  render() {
    let { currentLease } = this.props
    return (
      <BondSplitsForm
        currentLease={currentLease}
        tenants={this.state.tenants}
        update={(guidID) => this.update(guidID)}
        trimField={(guidID) => this.trimField(guidID)}
        calculateTotal={this.calculateTotal()}
        calculateVariance={this.calculateVariance()}
        submit={() => this.submit()}
        errors={this.state.errors}
        apiError={this.state.apiError}
        disabled={this.state.disabled}
        clicked={this.state.clicked}
        reset={this.reset}
        requestID={this.state.requestID}
        backUrl={this.props.backUrl}
        unsavedChanges={this.state.unsavedChanges}
      />
    )
  }
}

export default BondSplitsFormContainer
