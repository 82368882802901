import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import * as getters from 'app/components/design-system-components/theme/getters'
import { Heading } from 'app/components/design-system-components/typography'
import { followsCategoriesDefs } from 'app/features/follows/constants'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  FollowsGridCategoriesContainer,
  FollowsGridContainer,
  FollowsGridTeamMemberContainer,
} from 'app/pages/teams/settings/personal/follows/FollowsGrid/FollowsGridContainer'

const StyledFollowsGridContainer = styled(FollowsGridContainer)`
  padding: ${getters.spacing(4)} ${getters.spacing(6)};
`

const CircleSeparator = styled(Box)`
  width: 6px;
  height: 6px;
  min-width: 6px;
  border-radius: 50%;
  background-color: ${getters.color('gray600')};

  margin: 0 ${getters.spacing(3)};
`

export const FollowsGridRow = ({ teamMemberFollows, actionsColRenderer }) => {
  const { team_member, follows } = teamMemberFollows

  const categories = follows.map(
    ({ category }) => followsCategoriesDefs[category] || {},
  )

  const { first_name, last_name, phone, email } = team_member

  const actionColElem =
    actionsColRenderer && actionsColRenderer({ teamMemberFollows })

  return (
    <StyledFollowsGridContainer>
      <FollowsGridTeamMemberContainer>
        <Heading displayingLevel={6}>
          {first_name} {last_name}
        </Heading>
        <Flex alignItems="center" flexWrap="wrap" mt={theme.space[3] + 'px'}>
          {phone}
          <CircleSeparator />
          {email}
        </Flex>
      </FollowsGridTeamMemberContainer>
      <FollowsGridCategoriesContainer>
        {categories.map((category) => (
          <Badge key={category} text={category?.title} variant="notice" />
        ))}
      </FollowsGridCategoriesContainer>
      {actionColElem}
    </StyledFollowsGridContainer>
  )
}
