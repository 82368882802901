import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { history } from 'app/shared_components/router'
import { getProperty } from 'app/sm/property_details/property_details_access'
import {
  deleteRentalReference,
  postNote,
  pushReview,
} from 'app/sm/review/review_access'
import store from 'app/store'

export const PING = 'RE_PING'
export const RATE = 'RE_RATE'
export const INPUT_CHANGED = 'RE_INPUT_CHANGED'
export const CHECKBOX_CHANGED = 'RE_CHECKBOX_CHANGED'
export const SET_PROPERTY_ID = 'RE_SET_PROPERTY_ID'
export const RECEIVE_PROPERTY = 'RE_RECEIVE_PROPERTY'
export const RECEIVE_RESPONSE_TEXT = 'RE_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'RE_RECEIVE_ERROR'
export const CLEAR_ERROR = 'RE_CLEAR_ERROR'

// Intercepted by Middleware
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})

// Emitted by Middleware for Reducer
export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })

// Action Creators
export const ping = () => ({ type: PING })
export const clearError = () => ({ type: CLEAR_ERROR })
export const rate = (role, score) => ({
  type: RATE,
  role,
  score,
})

export const inputChanged = (role, key, value) => ({
  type: INPUT_CHANGED,
  role,
  key,
  value,
})

export const checkboxChanged = (role, key) => ({
  type: CHECKBOX_CHANGED,
  role,
  key,
})

const receiveProperty = (property) => ({
  type: RECEIVE_PROPERTY,
  property,
})

const setPropertyId = (propertyId) => ({
  type: SET_PROPERTY_ID,
  propertyId,
})

// Methods

export function submitReview() {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    const { propertyId, review } = getState().review

    pushReview(propertyId, review).then(({ ok, responseText, statusCode }) => {
      if (ok) {
        history.goBack()
      } else {
        responseText.then((t) => {
          store.dispatch(receiveResponseText(t))
        })
      }
    })
  }
}

export function fetchPropertyMetadata(propertyId) {
  return (dispatch) => {
    dispatch(setPropertyId(propertyId))
    getProperty(propertyId).then(
      ({ property, ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(receiveProperty(property))
        } else {
          responseText.then((t) => {
            store.dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

export const removeRentalReference = (rentalHistoryId) => {
  return (dispatch) => {
    return deleteRentalReference(rentalHistoryId).then(
      ({ ok, responseText, result }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const addNote = (applicationId, applicantId, payload) => {
  return (dispatch) => {
    return postNote(applicationId, applicantId, payload).then(
      ({ ok, responseText, notes }) => {
        if (ok) {
          return Promise.resolve(notes)
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}
