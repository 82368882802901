import {
  APPLICATION_LIST_RETURNED,
  RECEIVE_AMOUNT_RESPONSE_TEXT,
  RECEIVE_RESPONSE_TEXT,
  REQUEST_SUMMARY_RETURNED,
  SEARCH_RESULTS_RETURNED,
  TOGGLE_SPINNER,
  TOGGLE_SPINNER_OFF,
  TOGGLE_SPINNER_ON,
  // RECEIVE_ERROR
} from 'app/bond_cover/admin/admin_actions'

const _defaultState = {
  searchResults: {},
  requestSummary: {},
  applicationList: {},
  responseText: '',
  spinner: false,
  errorAmount: '',
  resetField: false,
}

const AdminReducer = (state = _defaultState, action) => {
  let {
    type,
    searchResults,
    requestSummary,
    applicationList,
    responseText,
    error,
  } = action
  Object.freeze(state)

  // if (type !== RECEIVE_ERROR) {
  //   error = ''
  // }

  let newState = Object.assign({}, state)
  switch (type) {
    case SEARCH_RESULTS_RETURNED:
      newState.searchResults.requests = []
      newState.searchResults.leases = []
      newState.searchResults = {}
      return Object.assign(newState, { searchResults, error })
    case REQUEST_SUMMARY_RETURNED:
      newState.requestSummary.signatures = []
      return Object.assign(newState, {
        requestSummary,
        error,
        errorAmount: '',
        resetField: true,
      })
    case APPLICATION_LIST_RETURNED:
      newState.applicationList = {}
      return Object.assign(newState, { applicationList, error })
    case TOGGLE_SPINNER:
      return Object.assign(newState, { spinner: !newState.spinner })
    case TOGGLE_SPINNER_ON:
      return Object.assign(newState, { spinner: true })
    case TOGGLE_SPINNER_OFF:
      return Object.assign(newState, { spinner: false })
    case RECEIVE_RESPONSE_TEXT:
      return Object.assign(newState, { responseText })
    // case RECEIVE_ERROR:
    //   return Object.assign(newState, { error })
    case RECEIVE_AMOUNT_RESPONSE_TEXT:
      return Object.assign(newState, {
        errorAmount: responseText,
        resetField: false,
      })
    default:
      return state
  }
}

export default AdminReducer
