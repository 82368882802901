import React from 'react'

import { Link } from 'react-router-dom'

import { getApplicationStatus } from 'app/shared_components/helpers'

const ResultItem = ({ request, lease, manager, tenant, payment }) => {
  let { appCode, referralCode } = request
  let {
    submissionStatus,
    SecondaryTenantsApproved,
    pauseState,
    idCheckStatus,
    exitStatus,
    managerAction,
  } = request
  let { isMultiTenantLease } = lease
  let tenantFirstName = '',
    tenantLastName = '',
    tenantEmail = '',
    tenantMobile = '',
    managerFirstName = '',
    managerLastName = '',
    managerEmail = '',
    managerMobile = ''
  if (tenant) {
    let primaryTenant = tenant[0] || []
    tenantFirstName = primaryTenant.firstName || 'Not Submitted'
    tenantLastName = primaryTenant.lastName
    tenantEmail = primaryTenant.email
    tenantMobile = primaryTenant.mobile
  }
  if (manager) {
    managerFirstName = manager.firstName
    managerLastName = manager.lastName
    managerEmail = manager.email
    managerMobile = manager.mobile
  }

  const applicationStatus = getApplicationStatus(request, payment, lease)

  const { friendlyName, suburb, state, postcode } = lease.address
  const link = `/admin/request?id=${request.guidID}`
  return (
    <li className="admin-dashboard-item width100">
      {!!tenant && !!manager && (
        <Link to={link}>
          <div className="content-wrapper width100">
            <div className="meta">
              <div className="meta-wrapper">
                <div className="admin-dashboard-container">
                  <div className="text text-prototype">{appCode}</div>
                  <div className="text text-prototype">
                    <div>
                      {tenantFirstName} {tenantLastName}
                    </div>
                    <div>{tenantEmail}</div>
                    <div>{tenantMobile}</div>
                  </div>
                  <div className="text text-prototype">
                    {managerEmail ? (
                      <div>
                        <div>
                          {managerFirstName} {managerLastName}
                        </div>
                        <div>{managerEmail}</div>
                        <div>{managerMobile}</div>
                      </div>
                    ) : (
                      <div>Not Submitted</div>
                    )}
                  </div>
                  <div className="text text-prototype">
                    {friendlyName ? (
                      <div>
                        {friendlyName}, {suburb} {state} {postcode}
                      </div>
                    ) : (
                      <div>Not Submitted</div>
                    )}
                  </div>
                  <div className="text text-prototype">{applicationStatus}</div>
                  <div className="text text-prototype">{referralCode}</div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
    </li>
  )
}

export default ResultItem
