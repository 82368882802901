import React from 'react'

import { Alert, Box, Flex } from 'app/components/design-system-components'
import FileAttachment from 'app/components/design-system-components/FileAttachment'
import { InfoRounded } from 'app/components/design-system-components/icons/actions'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as attachmentsHelpers from 'app/utils/attachments'

function SelfTenancyDocList({
  selfTenancyCheckDoc,
  onRemoveButtonClicked,
  showAlert = false,
  showRemoveButtons = true,
}) {
  return (
    <div>
      {selfTenancyCheckDoc &&
        selfTenancyCheckDoc.map((doc) => {
          const { guidID, name, URL } = doc

          const { filename, extension } =
            attachmentsHelpers.getFilenameAndExtension(name)
          return (
            <>
              {showAlert && (
                <Alert
                  leftIconVariant="info"
                  showLeftIcon={true}
                  variant="blueWithBg"
                  mb={5}
                >
                  <Flex alignItems="center">
                    <Box>Alternative tenancy check uploaded</Box>
                  </Flex>
                </Alert>
              )}
              <FileAttachment
                attachmentExtension={extension}
                attachmentName={filename}
                onRemoveButtonClicked={onRemoveButtonClicked}
                fileGUID={guidID}
                URL={URL}
                showRemoveButtons={showRemoveButtons}
                showAlert
              />
            </>
          )
        })}
    </div>
  )
}

export default SelfTenancyDocList
