import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

import headerImg01 from 'app/assets/icons/header-img-01.png'
import usersAvatars from 'app/assets/icons/users-avatars.png'
import { history } from 'app/shared_components/router'

class ManagerComplete extends React.Component {
  UNSAFE_componentWillMount() {
    let { getBondCoverCompleteSummary } = this.props
    let requestID = this.getParameter('requestid')
    getBondCoverCompleteSummary(requestID)
  }

  getParameter(field) {
    let url = window.location.href
    field = field.replace(/[\[\]]/g, '\\$&')
    let regex = new RegExp('[?&]' + field + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  render() {
    let { bondCoverCompleteSummary, currentUser } = this.props
    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <div className="ilustration-box">
            <img src={headerImg01} />
          </div>

          <h3 className="mb50">
            Congratulations! Your renter now has BondCover.
          </h3>

          <div className="bc-app-id">
            <h4>
              Application ID:{' '}
              {bondCoverCompleteSummary && bondCoverCompleteSummary.appCode}
            </h4>
          </div>

          {/* Commented out for future use  */}
          {/* <a href={ bondCoverCompleteSummary && bondCoverCompleteSummary.bcCertURL }>
            <div className="table">
              <div className="cell-m pr20">
                <i className="fa fa-file-pdf-o pink-color fs48" ></i>
              </div>
              <div className="cell-m-100">
                <h4>BondCover Certificate</h4>
                <small className="gray-light-color"> { bondCoverCompleteSummary && moment(bondCoverCompleteSummary.bcCertStartDate).format('DD MMM YYYY') } - { bondCoverCompleteSummary && moment(bondCoverCompleteSummary.bcCertEndDate).format('DD MMM YYYY') } </small>
              </div>
              <div className="cell-m pl10">
                <i className="fa fa-angle-right gray-dark-color fs32"></i>
              </div>
            </div>
          </a>

          <hr />

          <a href={ bondCoverCompleteSummary && bondCoverCompleteSummary.productURL }>
            <div className="table">
              <div className="cell-m pr20">
                <i className="fa fa-file-pdf-o pink-color fs48" ></i>
              </div>
              <div className="cell-m-100">
                <h4>PDS</h4>
              </div>
              <div className="cell-m pl10">
                <i className="fa fa-angle-right gray-dark-color fs32"></i>
              </div>
            </div>
          </a> */}

          <p className="mt50 mb50">
            You will soon receive a copy of the Snug BondCover Certificate and
            supporting documents in your email:{' '}
            <b>{currentUser && currentUser.email}</b>
          </p>

          <div className="bc-social-share">
            <img src={usersAvatars} />
          </div>

          {/* Commented out for future use */}
          {/* <h3 className="mb15">What's Next?</h3>

          <p>Share your plans on what you want to do with your bond!</p>

          <div className="bc-social-share">
            <img src={usersAvatars} />
            <h1>#bondcover</h1>
            <p>Spread the word, tell your friends about BondCover and help make renting more affordable.</p>
            <a className="btn btn-facebook" href="https://www.facebook.com/sharer/sharer.php?u=https%3A//snug.com">
              <i className="icon-facebook"></i>
              <span>Share on Facebook</span>
            </a>
            <a className="btn btn-twitter" href="https://twitter.com">
              <i className="icon-twitter"></i>
              <span>Share on Twitter</span>
            </a>
          </div>   */}
        </div>
      </div>
    )
  }
}

export default ManagerComplete
