import React from 'react'

import { connect } from 'react-redux'
import styled from 'styled-components'

import workflowStatusBarNoApplictions from 'app/assets/icons/workflow-status-0-start.svg'
import workflowStatusBarNew from 'app/assets/icons/workflow-status-1.svg'
import workflowStatusBarReview from 'app/assets/icons/workflow-status-2.svg'
import workflowStatusBarApprove from 'app/assets/icons/workflow-status-3.svg'
import workflowStatusBarOffer from 'app/assets/icons/workflow-status-4.svg'
import workflowStatusBarAccept from 'app/assets/icons/workflow-status-5.svg'
import workflowStatusBarLeased from 'app/assets/icons/workflow-status-6-final.svg'
import { fetchAllPropertyFutureViewings } from 'app/bond_cover/agency/agency_actions'
import { FilterUnAssigned } from 'app/bond_cover/agency/agency_utils'
import Button from 'app/components/buttons/default/component'
import { Flex } from 'app/components/design-system-components'
import { Box } from 'app/components/design-system-components'
import { Button as NewButton } from 'app/components/design-system-components'
import { SubdirectoryArrowRightRounded } from 'app/components/design-system-components/icons/navigation'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import * as Display from 'app/components/display/display'
import { getActiveIntegrationsSendingTenancyOnboard } from 'app/constants/pms.constants'
import { MergeApplicationModal } from 'app/features/teams/applications/merge-applications/MergeApplicationModal'
import { RegisterApplicantModal } from 'app/features/teams/applications/registerApplicant'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { updateStatusWithSideEffectActions } from 'app/services/http/applications/workflow'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import WorkFlowPinkSpots from 'app/shared_components/work-flow-pink-spots-component/component'
import MoveApplicationPropertyModal from 'app/sm/applications/components/move_application/move_application_property_modal'
import {
  ApplicationStatusCategory,
  firstAndLastNameInitials,
  isStatusPMWithdrawn,
  isStatusWithdrawn,
  PropertyStatus,
  trimFileLength,
  urlTo,
} from 'app/sm/helpers'
import * as helpers from 'app/sm/helpers'
import ApplicationApprovedModal from 'app/sm/renter_applications/components/application_approved'
import DeclineApplicationModal from 'app/sm/renter_applications/components/decline_application'
import DepositReceivedOptionsModal from 'app/sm/renter_applications/components/depositReceivedOptionsModal'
import { MarkAsLeasedModal } from 'app/sm/renter_applications/components/markAsLeasedModal'
import {
  isApplicationMergeable,
  isStatusEligibleForUtilityReferral,
  shortWorkflowStatuses,
  shouldShowApplicationMoveTo,
} from 'app/utils/applications/helpers'
import * as textHelpers from 'app/utils/text/helpers'

export const PropertySortCriterias = {
  Any: undefined,
  'Lowest Rent': (rentA, rentB) => rentA - rentB,
  'Highest Rent': (rentA, rentB) => rentB - rentA,
  'Suburb A-Z': (suburbA, suburbB) => suburbA.localeCompare(suburbB),
  'Suburb Z-A': (suburbA, suburbB) => suburbB.localeCompare(suburbA),
}

export const PropertySearchFilterRow = ({ children }) => {
  return <div className="properties-search-filter mb0">{children}</div>
}

export const PropertySearchBox = ({ placeholder, value, onChange }) => {
  return (
    <div className="property-search-filter-widget">
      <div className="input-box">
        <div className="prefix">
          <i className="icon-search" />
        </div>
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export const DropDown = ({
  label,
  options,
  value,
  onChange,
  className = '',
}) => {
  return (
    <div className={`${className} property-search-filter-widget`}>
      <div className="ibm pr10">{label}</div>
      <div className="input-box wa pt0">
        <select value={value} onChange={onChange}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export const DropDownForViewingManager = ({
  label,
  options,
  value,
  onChange,
  changeToMeFilter,
  showAllTab,
  enableUnAssigned,
}) => {
  return (
    <div className="property-search-filter-widget border-0">
      <div className="ibm pr10">{label}</div>
      <span
        className="textButton linkStyle-blue wa ha btnmr mt10 mb10 mr10"
        onClick={changeToMeFilter}
      >
        {showAllTab ? 'All' : 'Me'}
      </span>
      <div className="input-box wa pt0">
        <select value={value} onChange={onChange}>
          <option key={'0'} value={''}>
            All
          </option>
          {options &&
            options.map((opt) => (
              <option key={opt.contact.guidID} value={opt.contact.guidID}>
                {`${opt.contact && opt.contact.firstName} ${
                  opt.contact &&
                  firstAndLastNameInitials(opt.contact, 'lastName')
                }`}
              </option>
            ))}
          {enableUnAssigned && (
            <option key={'1'} value={FilterUnAssigned} className="fw600">
              Unassigned
            </option>
          )}
        </select>
      </div>
    </div>
  )
}

export const DropDownForManager = ({
  label,
  options,
  value,
  onChange,
  changeToMeFilter,
  showAllTab,
}) => {
  return (
    <div className="property-search-filter-widget border-0">
      <div className="ibm pr10">{label}</div>
      <span
        className="textButton linkStyle-blue wa ha btnmr mt10 mb10 mr10"
        onClick={changeToMeFilter}
      >
        {showAllTab ? 'All' : 'Me'}
      </span>
      <div className="input-box wa pt0">
        <select value={value} onChange={onChange}>
          <option key={'0'} value={''}>
            All
          </option>
          {options &&
            options.map((option) => (
              <option key={option.guidID} value={option.guidID}>
                {`${option.profile && option.profile.firstName} ${
                  option.profile &&
                  firstAndLastNameInitials(option.profile, 'lastName')
                }`}
              </option>
            ))}
        </select>
      </div>
    </div>
  )
}

export const PropertyManagerFilter = ({
  options,
  value,
  onChange,
  changeToMeFilter,
  showAllTab,
}) => {
  return (
    <DropDownForManager
      label="Manager: "
      options={options}
      value={value}
      onChange={onChange}
      changeToMeFilter={changeToMeFilter}
      showAllTab={showAllTab}
    />
  )
}

export const DropDownForShowDeclinedAndWithdrawnApplications = ({
  label,
  showDeclinedAndWithdrawnApplicationsFilter,
  showDeclinedAndWithdrawnApplicationsTab,
}) => {
  return (
    <div className="ml10 border-0">
      <div className="ibm pr10">{label}</div>
      <span
        className="textButton linkStyle-blue wa ha btnmr mt10 mb10 mr10"
        onClick={showDeclinedAndWithdrawnApplicationsFilter}
      >
        {showDeclinedAndWithdrawnApplicationsTab ? 'Active' : 'All'}
      </span>
    </div>
  )
}

export const ApplicationYesNoFilterCriteria = [
  { text: 'Any' },
  { text: 'Yes' },
  { text: 'No' },
]

export const ApplicationYesNoFilter = ({ value, onChange }) => {
  return (
    <DropDown
      label="Applicants: "
      options={ApplicationYesNoFilterCriteria.map((criteria) => criteria.text)}
      value={value}
      onChange={onChange}
    />
  )
}

export const PropertyStatusFilterOptions = ['Any (Non-archived)'].concat(
  PropertyStatus.filter((s) => s !== undefined).map((s) =>
    s === 'TakingApplications' ? 'Taking Applications' : s,
  ),
)

export const PropertyStatusFilter = ({ value, onChange }) => {
  return (
    <DropDown
      label="Status: "
      options={PropertyStatusFilterOptions}
      value={value}
      onChange={onChange}
    />
  )
}

export const PropertyStatusesFilterOptions = [
  'All properties',
  'Excl Off Market',
]

export const PropertyStatusesFilter = ({ value, onChange }) => {
  return (
    <DropDown
      label="Status: "
      options={PropertyStatusesFilterOptions}
      value={value}
      onChange={onChange}
    />
  )
}

export const ApplicationStageFilterOptions = [
  'All',
  'EOI',
  'Viewing',
  'Application',
  'Offer',
]

export const ApplicationStageFilter = ({ value, onChange, className = '' }) => {
  return (
    <DropDown
      label="Stage: "
      options={ApplicationStageFilterOptions}
      value={value}
      onChange={onChange}
      className={className}
    />
  )
}

export const ApplicationWorkflowFilter = ({ label, value, onChange }) => {
  const allStages = [{ stage: 'All', substages: [{ status: -1 }] }].concat(
    ApplicationWorkflowStages,
  )
  return (
    <div className="property-search-filter-widget border-0">
      <div className="ibm pr10">{label}</div>
      <div className="input-box wa pt0">
        <select value={value} onChange={onChange}>
          {allStages.map((stage) => {
            if (stage.substages.length === 1) {
              return (
                <option key={stage.stage} value={stage.substages[0].status}>
                  {stage.stage}
                </option>
              )
            } else {
              return (
                <optgroup key={stage.stage} label={stage.stage}>
                  {stage.substages
                    .filter((substage) => substage.name !== 'Declined')
                    .filter((substage) => substage.name !== 'Declined (Flag)')
                    .map((substage) => (
                      <option key={substage.name} value={substage.status}>
                        {`${substage.name}`}
                      </option>
                    ))}
                </optgroup>
              )
            }
          })}
        </select>
      </div>
    </div>
  )
}

export const ApplicationWorkflowTextStatus = ({ applicationStatus }) => {
  const substage = getWorkflowSubstage(applicationStatus)
  const stage = substage ? substage.stage : ''
  const status = substage && stage !== 'New' ? substage.name : ''
  return (
    <div className="">
      <strong className="font-size-18 fw500">{stage}</strong>
      <div>{status ? ` (${status})` : ''}</div>
    </div>
  )
}

export const ApplicationWorkflowStatusBar = ({ applicationStatus }) => {
  const workflowStatusBar = {
    0: workflowStatusBarNoApplictions,
    1: workflowStatusBarNew,
    2: workflowStatusBarReview,
    3: workflowStatusBarApprove,
    4: workflowStatusBarOffer,
    5: workflowStatusBarAccept,
    6: workflowStatusBarLeased,
  }
  return (
    <div className="pb5">
      <img alt="" src={workflowStatusBar[applicationStatus]} height="12px" />
    </div>
  )
}

export const ApplicationWorkflowStatusLabel = ({ applicationStatus }) => {
  const statusIndex = allSubstages.findIndex(
    (substage) => substage.status === applicationStatus,
  )
  const stageIndex =
    statusIndex !== -1
      ? ApplicationWorkflowStages.findIndex(
          (stage) => stage.stage === allSubstages[statusIndex].stage,
        )
      : -1
  return (
    <Flex flexDirection="column" justifyContent="center" pl={3}>
      <div className="font-size-14">
        <div className="display-flex flex-direction-column">
          <ApplicationWorkflowStatusBar applicationStatus={stageIndex} />
          <ApplicationWorkflowTextStatus
            applicationStatus={applicationStatus}
          />
        </div>
      </div>
    </Flex>
  )
}

export const getApplicationWorkflowName = (targetStatus) => {
  const status = ApplicationStatusCollection.find(
    (applicationStatus) => applicationStatus.status === targetStatus,
  )
  return status ? status.text : 'Unknown'
}
const getApplicationWorkflowEnum = (statusText) => {
  const status = ApplicationStatusCollection.find(
    (applicationStatus) => applicationStatus.text === statusText,
  )
  return status ? status.status : -1
}

const ApplicationStatusCollection = [
  {
    status: 0,
    text: 'Drafted',
  },
  {
    status: 1,
    text: 'New',
  },
  {
    status: 2,
    text: 'Shortlisted',
  },
  {
    status: 3,
    text: 'Offered',
  },
  {
    status: 4,
    text: 'Declined',
  },
  {
    status: 5,
    text: 'Renter withdrawn',
  },
  {
    status: 6,
    text: 'Accepted',
  },
  {
    status: 7,
    text: 'Mark as leased',
  },
  {
    status: 8,
    text: 'Quick applied',
  },
  {
    status: 9,
    text: 'Processing',
  },
  {
    status: 10,
    text: 'Requested info',
  },
  {
    status: 11,
    text: 'Pre-approval / With PM',
  },
  {
    status: 19,
    text: 'With Owner',
  },
  {
    status: 12,
    text: 'Owner approved',
  },
  {
    status: 13,
    text: 'Payment pending',
  },
  {
    status: 14,
    text: 'Lease sent',
  },
  // PM withdrawn
  {
    status: 15,
    text: 'Withdrawn',
  },
  {
    status: 16,
    text: 'Declined (Flag)',
  },
  {
    status: 17,
    text: 'Application approved',
  },
  {
    status: 18,
    text: 'Deposit received',
  },
]

export const isWorkflowOffered = (applicationStatus) =>
  applicationStatus === getApplicationWorkflowEnum('Offered')
export const isWorkflowLeaseSigned = (applicationStatus) =>
  applicationStatus === getApplicationWorkflowEnum('Mark as leased')
export const isWorkflowDeclined = (applicationStatus) =>
  applicationStatus === getApplicationWorkflowEnum('Declined')
export const isWorkflowDeclinedFlagged = (applicationStatus) =>
  applicationStatus === getApplicationWorkflowEnum('Declined (Flag)')
export const isWorkflowApplicationApproved = (applicationStatus) =>
  applicationStatus === getApplicationWorkflowEnum('Application approved')
export const isWorkflowDepositReceived = (applicationStatus) =>
  applicationStatus === getApplicationWorkflowEnum('Deposit received')
export const isWorkflowReviewStage = (applicationStatus) =>
  allSubstages.filter((substage) => substage.status === applicationStatus)[0]
    .stage === 'Review'
export const isWorkflowOfferWithdrawable = (applicationStatus) =>
  compareWorkflow(applicationStatus, getApplicationWorkflowEnum('Offered')) >= 0
const formatApplicantsNames = (application) => {
  return application.applicants.reduce((acc, cur) => {
    return acc ? `${acc}, ${cur.firstName}` : cur.firstName
  }, '')
}
export const getRequestedInfoSubstage = () =>
  allSubstages.filter((substage) => substage.name === 'Requested info')[0]
export const getProcessingSubstage = () =>
  allSubstages.filter((substage) => substage.name === 'Processing')[0]

export const getSelectedSubStage = (currentWorkflowStatus, application) => {
  const index = allSubstages.findIndex(
    (substage) =>
      substage.name === getApplicationWorkflowName(currentWorkflowStatus),
  )
  return allSubstages[index].getMessage(application)
}

const ApplicationWorkflowStages = [
  {
    stage: textHelpers.ONLIST_INCOMPLETE_TEXT,
    readOnly: true,
    substages: [
      {
        status: textHelpers.ONLIST_INCOMPLETE,
      },
    ],
  },
  {
    stage: 'Archived',
    readOnly: true,
    substages: [
      {
        status: textHelpers.ARCHIVED_STATUS,
      },
    ],
  },
  {
    stage: 'New',
    readOnly: true,
    substages: [
      {
        name: 'New',
        action: 'NA',
        status: 1,
      },
    ],
  },
  {
    stage: 'Review',
    substages: [
      {
        name: 'Processing',
        action: 'Mark processing',
        getMessage: () => 'Moved to "Processing"',
        status: 9,
      },
      {
        name: 'Requested info',
        action: 'Request info',
        getMessage: () => `Moved to "Requested info"`,
        status: 10,
      },
      {
        name: 'Shortlisted',
        action: 'Mark shortlisted',
        getMessage: (application) =>
          `Moved to "Shortlisted". Email will be sent to ${formatApplicantsNames(
            application,
          )}`,
        status: 2,
        showEmailApplicantTag: true,
      },
      {
        name: 'Declined',
        action: 'Mark declined',
        getMessage: (application) =>
          `Moved to "Declined". Email will be sent to ${formatApplicantsNames(
            application,
          )}`,
        status: 4,
        showEmailApplicantTag: true,
      },
      {
        name: 'Declined (Flag)',
        action: 'Mark declined and flag',
        getMessage: (application) =>
          `Moved to "Declined" and flagged. Decline email will be sent to ${formatApplicantsNames(
            application,
          )}`,
        status: 16,
        showEmailApplicantTag: true,
      },
      {
        name: 'Withdrawn',
        action: 'Withdraw',
        getMessage: (application) =>
          `Moved to "Withdrawn". Email will be sent to ${formatApplicantsNames(
            application,
          )}`,
        status: 15,
        showEmailApplicantTag: true,
      },
    ],
  },
  {
    stage: 'Approve',
    substages: [
      {
        name: 'Pre-approval / With PM',
        action: 'Mark pre-approval',
        getMessage: () => 'Moved to "Pre-approval / With PM"',
        status: 11,
      },
      {
        name: 'With Owner',
        action: 'Mark with Owner',
        getMessage: () => 'Moved to "With Owner"',
        status: 19,
        showEmailApplicantTag: true,
      },
      {
        name: 'Owner approved',
        action: 'Mark owner approved',
        getMessage: () => 'Moved to "Owner approved"',
        status: 12,
      },
      {
        name: 'Application approved',
        action: 'Mark application approved',
        getMessage: () => 'Moved to "Application approved"',
        status: 17,
        showEmailApplicantTag: true,
        showSendUtilityReferralTag: true,
      },
    ],
  },
  {
    stage: 'Offer',
    substages: [
      {
        name: 'Offered',
        action: 'Offer',
        status: 3,
        getMessage: () => `Moved to "Offered"`,
        showEmailApplicantTag: true,
        showSendUtilityReferralTag: true,
      },
      {
        name: 'Payment pending',
        action: 'Mark payment pending',
        getMessage: () => `Moved to "Payment pending"`,
        status: 13,
      },
      {
        name: 'Deposit received',
        action: 'Mark Deposit received',
        getMessage: () => `Moved to "Deposit received"`,
        status: 18,
        showSendUtilityReferralTag: true,
      },
    ],
  },
  {
    stage: 'Accept',
    substages: [
      {
        name: 'Accepted',
        action: 'Mark accepted',
        getMessage: () => `Moved to "Accepted"`,
        status: 6,
      },
      {
        name: 'Lease sent',
        action: 'Send lease',
        getMessage: () => `Moved to "Lease sent"`,
        status: 14,
      },
      {
        name: 'Mark as leased',
        action: 'Mark lease signed',
        getMessage: () => `Marked as "Leased"`,
        status: 7,
        showLabelsPropertyTag: true,
        showSendUtilityReferralTag: true,
      },
    ],
  },
]

const compactWorkflowStages = (shouldInclude) =>
  ApplicationWorkflowStages.map((stg) => ({
    ...stg,
    substages: stg.substages.filter(
      (stage) =>
        shortWorkflowStatuses().includes(stage.status) ||
        stage.status === shouldInclude,
    ),
  })).filter((stage) => !!stage.substages.length)

const allSubstages = ApplicationWorkflowStages.reduce((acc, cur) => {
  // eslint-disable-next-line array-callback-return
  cur.substages.map((substage) => {
    acc.push({
      ...substage,
      stage: cur.stage,
    })
  })
  return acc
}, [])

// -1, 1, 0 if status1 is earlier, later or equal to status2
export const compareWorkflow = (applicationStatus1, applicationStatus2) => {
  const index1 = allSubstages.findIndex(
    (substage) => substage.status === applicationStatus1,
  )
  const index2 = allSubstages.findIndex(
    (substage) => substage.status === applicationStatus2,
  )
  return index1 < index2 ? -1 : index1 > index2 ? 1 : 0
}

const StageOption = styled.li`
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.4;
  color: #686d71;
  white-space: nowrap;
`

const StageOptionBold = styled(StageOption)`
  font-weight: 600;
`

const StyledButtonContainer = styled(Box)`
  width: 60px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const StyledNewButton = styled(NewButton)`
  padding: 10px 8px !important;
`

export const ClickableStageOption = ({ value, onClick }) => {
  return (
    <li className="link-styles fw600" onClick={onClick}>
      {value}
    </li>
  )
}

const SubStageOption = ({
  value,
  onClick,
  selected = false,
  showEmailApplicantTag = false,
  showLabelPropertyTag = false,
  showSendUtilityReferralTag = false,
}) => {
  const tick = '✓ '
  const emailApplicantTag = '*'
  const labelPropertyTag = '#'
  const sendUtilityReferalTag = '$'

  let label = `- ${value}`

  if (selected) label = tick + label

  if (
    showEmailApplicantTag ||
    showLabelPropertyTag ||
    showSendUtilityReferralTag
  ) {
    label = label + ' '
    if (showEmailApplicantTag) label = label + emailApplicantTag
    if (showLabelPropertyTag) label = label + labelPropertyTag
    if (showSendUtilityReferralTag) label = label + sendUtilityReferalTag
  }

  return (
    <li className="link-styles" onClick={onClick}>
      {label}
    </li>
  )
}

export const ApplicationMoveToButton = ({
  showMoveToText = true,
  onClickItem,
  currentWorkflowStatus,
  dropup,
  compactWorkflow,
  showNewButtonStyle = false,
  onClickSave,
  showIconMoveTo = true,
}) => {
  const workflowStages = compactWorkflow
    ? compactWorkflowStages()
    : ApplicationWorkflowStages
  const buttonText = showMoveToText
    ? 'Move to'
    : trimFileLength(11)(getApplicationWorkflowName(currentWorkflowStatus))
  const onClickListItem = (substage) => {
    onClickItem(getApplicationWorkflowEnum(substage.name), substage.getMessage)
    if (showNewButtonStyle && onClickSave) {
      onClickSave(getApplicationWorkflowEnum(substage.name))
    }
  }
  return (
    <div className="button-container mr10">
      <div className="flex-1 display-flex justify-content-center align-items-center team-over-button">
        <div className={`dropdown actions ${dropup ? 'dropup' : ''}`}>
          <div className="dropdown-toggle" data-toggle="dropdown">
            {!showNewButtonStyle ? (
              <Button
                buttonType="secondary"
                size="large"
                text={buttonText}
                componentClass="progress-button-container"
                buttonStyleClass="p0"
              />
            ) : (
              <StyledNewButton
                sizeVariant="regular"
                variant="greenHover"
                maxWidth={showIconMoveTo ? '140px' : '80px'}
              >
                <Flex alignItems="center" justifyContent="center">
                  {showIconMoveTo && (
                    <Box mr={2}>
                      <SubdirectoryArrowRightRounded />
                    </Box>
                  )}
                  <StyledButtonContainer>{buttonText}</StyledButtonContainer>
                </Flex>
              </StyledNewButton>
            )}
          </div>
          <ul className="dropdown-menu team-overview-dropdown">
            {workflowStages
              .filter((stage) => !stage.readOnly)
              .map((option) => {
                const items = []
                items.push(
                  <StageOptionBold key={option.stage}>
                    {option.stage}
                  </StageOptionBold>,
                )

                option.substages.map((substage) =>
                  items.push(
                    <SubStageOption
                      key={`${option.stage}-${substage.name}`}
                      value={substage.name}
                      onClick={() => onClickListItem(substage)}
                      selected={
                        currentWorkflowStatus ===
                        getApplicationWorkflowEnum(substage.name)
                      }
                      showEmailApplicantTag={substage.showEmailApplicantTag}
                      showLabelPropertyTag={substage.showLabelsPropertyTag}
                      showSendUtilityReferralTag={
                        substage.showSendUtilityReferralTag
                      }
                    />,
                  ),
                )
                return items
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}

const getStatusCollection = (currentWorkflowStatus) => {
  const currentWorkflowName = getApplicationWorkflowName(currentWorkflowStatus)
  const currentStageIndex = ApplicationWorkflowStages.reduce(
    (acc, cur, curIndex) => {
      if (
        cur.substages.find(
          (substage) => substage.name === currentWorkflowName,
        ) !== undefined
      ) {
        return curIndex
      }
      return acc
    },
    0,
  )
  const lastSubstages =
    ApplicationWorkflowStages[ApplicationWorkflowStages.length - 1].substages
  const lastWorkflowName = lastSubstages[lastSubstages.length - 1].name
  return ApplicationWorkflowStages.map((option, index) => ({
    stage: option.stage,
    substage: currentWorkflowName,
    // if currentStageIndex is the last stage it means all done
    status:
      index < currentStageIndex
        ? 'done'
        : currentStageIndex < index
        ? 'not start'
        : currentWorkflowName !== lastWorkflowName
        ? 'in progress'
        : 'done',
  }))
}

export const ApplicationWorkflowProgressChart = ({
  currentWorkflowStatus,
  containerClass,
}) => {
  return (
    <WorkFlowPinkSpots
      statusCollection={getStatusCollection(currentWorkflowStatus)}
      containerClass={containerClass}
    />
  )
}

const getWorkflowSubstage = (currentWorkflowStatus) => {
  const index = allSubstages.findIndex(
    (substage) =>
      substage.name === getApplicationWorkflowName(currentWorkflowStatus),
  )
  // find it and not at the last substage
  if (index !== -1) {
    return allSubstages[index]
  }
  return undefined
}

const getWorkflowNextSubstage = (currentWorkflowStatus) => {
  const index = allSubstages.findIndex(
    (substage) =>
      substage.name === getApplicationWorkflowName(currentWorkflowStatus),
  )
  // find it and not at the last substage
  if (index !== -1 && index < allSubstages.length - 1) {
    return allSubstages[index + 1]
  }
  if (index === allSubstages.length - 1) {
    return { name: 'NA', action: 'Leased' }
  }
  return undefined
}

export const ApplicationWorkflowNextStepButton = ({
  onClickItem,
  currentWorkflowStatus,
}) => {
  const nextSubstage = getWorkflowNextSubstage(currentWorkflowStatus) || {}
  const newStatus = getApplicationWorkflowEnum(nextSubstage.name)
  const action = nextSubstage.action
  const onClickHandler =
    newStatus !== -1
      ? () => onClickItem(newStatus, nextSubstage.getMessage)
      : () => {}
  return (
    <div className="button-container application-button">
      <button
        className="btn offer-application application-button p0"
        onClick={onClickHandler}
      >
        {action}
      </button>
    </div>
  )
}

export const ApplicationWorkflowSaveButton = ({
  onClick,
  prevWorkflowStatus,
  currentWorkflowStatus,
  isLoading,
  isSaveDisabled,
  showNewButtonStyle = false,
}) => {
  const index = allSubstages.findIndex(
    (substage) =>
      substage.name === getApplicationWorkflowName(currentWorkflowStatus),
  )
  const newWorkflowStepSelected =
    0 <= index &&
    index < allSubstages.length &&
    prevWorkflowStatus !== currentWorkflowStatus
  const isSaveEnabled = newWorkflowStepSelected && !isLoading
  const onClickHandler = isSaveEnabled
    ? () => onClick(currentWorkflowStatus, allSubstages[index].getMessage)
    : undefined

  let label =
    prevWorkflowStatus !== currentWorkflowStatus ||
    index !== allSubstages.length - 1
      ? 'Save'
      : 'Leased'
  if (currentWorkflowStatus === ApplicationStatusCategory.Offered)
    label = 'Preview'

  return (
    <>
      {!showNewButtonStyle ? (
        <div className="button-container application-button">
          <button
            className="btn offer-application application-button p0"
            onClick={onClickHandler}
            disabled={isSaveDisabled ? isSaveDisabled : !isSaveEnabled}
          >
            {label}
            {isLoading ? <i className="fa fa-spinner fa-pulse" /> : ''}
          </button>
        </div>
      ) : (
        <NewButton
          sizeVariant="regular"
          variant="greenHover"
          disabled={isSaveDisabled ? isSaveDisabled : !isSaveEnabled}
          onClick={onClickHandler}
          height={'40px'}
          width={'80px'}
        >
          <Box>
            {label}
            {isLoading ? <i className="fa fa-spinner fa-pulse" /> : ''}
          </Box>
        </NewButton>
      )}
    </>
  )
}

export const ApplicationWorkflowContextMenu = ({
  application,
  currentWorkflowStatus,
  onClickDecline,
  onClickWithdrawOffer,
  containerClass = '',
  onClickScheduleViewing,
  onClickMoveApplication,
  onClickMergeApplication,
  applicationSettings,
  onClickShortlistWithEmail,
  isDropUp = true,
}) => {
  const buttons = []

  if (applicationSettings?.enable_internal_shortlisting) {
    buttons.push({
      label: 'Shortlist with email',
      onClick: onClickShortlistWithEmail,
    })
  }

  if (!isWorkflowLeaseSigned(currentWorkflowStatus)) {
    buttons.push({
      label: 'Decline',
      onClick: onClickDecline,
    })
  }

  if (
    compareWorkflow(
      currentWorkflowStatus,
      getApplicationWorkflowEnum('Offered'),
    ) >= 0
  ) {
    buttons.push({
      label: 'Withdraw offer',
      onClick: onClickWithdrawOffer,
    })
  }
  buttons.push({
    label: 'Schedule Viewing',
    onClick: onClickScheduleViewing,
  })
  buttons.push({
    label: 'Move Application',
    onClick: onClickMoveApplication,
  })

  return (
    <div
      className={`dropdown actions ${
        isDropUp ? 'dropup' : ''
      } ml10 ${containerClass}`}
    >
      <div className="dropdown-toggle drop-down-toggler" data-toggle="dropdown">
        <i className="icon-more drop-down-spots" />
      </div>
      <ul className="dropdown-menu team-overview-dropdown">
        {buttons.map((button) => (
          <li
            key={button.label}
            className="link-styles"
            onClick={button.onClick}
          >
            {button.label}
          </li>
        ))}
        {buttons.length === 0 && <li className="link-styles">Empty</li>}
      </ul>
    </div>
  )
}

export const ReopenDeclinedApplicationConfirmationModal = ({
  onConfirm,
  onCancel,
}) => {
  return (
    <Display.ModalWithScroll
      modalHeader={
        <div className="vertical-horizontal-center width-100 text-align-center">
          <h4 className="standard-subheading">Re-open application</h4>
        </div>
      }
      modalBody={
        <div className="pl10-desktop pr10-desktop mt20">
          Re-opening this application will send a notice to the renter that
          their profile is visible
        </div>
      }
      modalBottom={
        <Display.ModalButtonGroup
          primaryLabel="Proceed"
          secondaryLabel="Cancel"
          secondaryButtonType="tertiary"
          primaryAction={() => onConfirm()}
          secondaryAction={() => onCancel()}
        />
      }
      toggleModal={() => onCancel()}
    />
  )
}

class ApplicationWorkflowToolbarContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      applicationWorkflowStatus: 0,
      prevApplicationWorkflowStatus: 0,
      workflowSaveButtonBusy: false,
      showMoveToText: true,
      activeModal: '',
      markAsLeasedOptions: {
        declineUnsuccessfulApplications: true,
        cancelFutureViewings: true,
        archiveProperty: true,
        archiveEnquirers: false,
      },
      markAsApplicationApprovedOptions: {
        declineUnsuccessfulApplications: false,
        cancelFutureViewings: false,
        sendUtilityReferrals: true,
        sendApplicationToPT: false,
        archiveProperty: false,
      },
      declineApplicationOptions: {
        ownerSelectedAnAlternative: false,
        householdAffordabilityIssue: false,
        petNotSuitable: false,
        rentalReferenceIssue: false,
        insufficientDocumentation: false,
        notSuitableProperty: false,
        ownerWithdrawnProperty: false,
        noSpecificReason: false,
      },
      declineApplicationNotifyApplicant: true,
      declineComment: '',
      declineApplicationInProgress: false,
      isReopenApplicationGuardModalOpened: false,
      showMoveApplicationPropertyModal: false,
      isConsoleCloudIntegrationCallMade: false,
      registerApplicantModal: { opened: false, config: {} },
      forceUtilityReferralModal: {
        isOpen: false,
      },
      mergeApplicationModal: {
        isOpened: false,
        config: {},
      },
    }
  }

  componentDidMount() {
    const { integrationsTeamSettings } = this.props
    this.setState({
      applicationWorkflowStatus: this.props.application.status,
      prevApplicationWorkflowStatus: this.props.application.status,
    })
    const isConsoleCloudFeatureEnabled =
      integrationsTeamSettings?.console_cloud_enabled === 'true'
    if (!isConsoleCloudFeatureEnabled) {
      this.setState({
        isConsoleCloudEnabled: false,
      })
      return
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.application !== this.props.application &&
      this.props.application
    ) {
      this.setState({
        applicationWorkflowStatus: this.props.application.status,
        prevApplicationWorkflowStatus: this.props.application.status,
      })
    }
  }

  onApplicationWorkflowChange = (newStatus) => {
    return this.props
      .moveTo(this.props.application.guidID, newStatus)
      .then(() => {
        this.setState({ applicationWorkflowStatus: newStatus })
      })
  }

  onApplicationStatusUpdateWithActions = (status, actions) => {
    const { guidID } = this.props.application
    updateStatusWithSideEffectActions(guidID, status, actions)
      .then(() => {
        this.toggleModal('')
        snugNotifier.success(`Move to "Deposit Received"`)
      })
      .catch((error) => {
        snugNotifier.error(error.message)
      })
      //  refresh even when error: error could be in handling actions, while status update succeeded
      .then(() => {
        if (this.props.fetchApplicationsBasedOnQuery) {
          this.props.fetchApplicationsBasedOnQuery()
        }
      })
  }

  onChangeDeclineApplicationOptions = (checkboxID) => {
    return (event) => {
      const { checked } = event.target
      const { declineApplicationOptions } = this.state
      this.setState({
        ...this.state,
        declineApplicationOptions: {
          ...declineApplicationOptions,
          [checkboxID]: checked,
        },
      })
    }
  }

  onChangeDeclineComment = () => {
    return (event) => {
      this.setState({
        declineComment: event.target.value,
      })
    }
  }

  onChangeMarkAsApplicationApprovedOptions = (checkboxID) => {
    return (event) => {
      const { checked } = event.target
      const { markAsApplicationApprovedOptions } = this.state

      const { currentTeam = {} } = this.props
      const { platformDefaultProvider = false } = currentTeam || {}
      if (checkboxID === 'sendUtilityReferrals' && platformDefaultProvider) {
        this.setState({
          ...this.state,
          markAsApplicationApprovedOptions: {
            ...markAsApplicationApprovedOptions,
            [checkboxID]: true,
          },
        })
      } else {
        this.setState({
          ...this.state,
          markAsApplicationApprovedOptions: {
            ...markAsApplicationApprovedOptions,
            [checkboxID]: checked,
          },
        })
      }
    }
  }

  onChangeNotifyApplicant = () => {
    return (event) => {
      const { checked } = event.target
      this.setState({
        declineApplicationNotifyApplicant: checked,
      })
    }
  }

  onChangeWorkflowClicked = (...params) => {
    const { application } = this.props
    if (application.isRecentlyDeclined) {
      return this.setState({
        isReopenApplicationGuardModalOpened: true,
        changeWorkflowParamsTemp: params,
      })
    }
    this.onChangeWorkflow(...params)
  }

  confirmReopen = () => {
    const { changeWorkflowParamsTemp } = this.state
    this.setState(
      {
        isReopenApplicationGuardModalOpened: false,
        changeWorkflowParamsTemp: null,
      },
      () => this.onChangeWorkflow(...changeWorkflowParamsTemp),
    )
  }

  cancelReopen = () => {
    this.setState({
      isReopenApplicationGuardModalOpened: false,
      changeWorkflowParamsTemp: null,
    })
  }

  onChangeWorkflow = (newStatus) => {
    if (isWorkflowLeaseSigned(newStatus)) {
      this.setState({
        activeModal: 'markAsLeased',
        workflowSaveButtonBusy: false,
        applicationWorkflowStatus: newStatus,
        prevApplicationWorkflowStatus: newStatus,
      })
      return
    }
    if (isWorkflowApplicationApproved(newStatus)) {
      this.setState({
        activeModal: 'markAsApplicationApproved',
        workflowSaveButtonBusy: false,
        applicationWorkflowStatus: newStatus,
        prevApplicationWorkflowStatus: newStatus,
      })
      return
    }
    if (isWorkflowDepositReceived(newStatus)) {
      this.setState({
        activeModal: 'markAsDepositReceived',
        workflowSaveButtonBusy: false,
        applicationWorkflowStatus: newStatus,
        prevApplicationWorkflowStatus: newStatus,
      })
      return
    }
    if (isWorkflowDeclined(newStatus) || isWorkflowDeclinedFlagged(newStatus)) {
      this.setState({
        activeModal: 'markAsApplicationDeclined',
        workflowSaveButtonBusy: false,
        applicationWorkflowStatus: newStatus,
        prevApplicationWorkflowStatus: newStatus,
      })
      return
    }
    this.setState({
      applicationWorkflowStatus: newStatus,
      showMoveToText: false,
    })
  }

  onChangemarkAsLeasedOptions = (checkboxID) => {
    return (event) => {
      const { checked } = event.target
      const { markAsLeasedOptions } = this.state
      this.setState({
        ...this.state,
        markAsLeasedOptions: {
          ...markAsLeasedOptions,
          [checkboxID]: checked,
        },
      })
    }
  }

  onClickSave = (newStatus, getMessage) => {
    const { fetchApplicationsBasedOnQuery } = this.props
    this.setState({ showMoveToText: true })
    const { currentTeam = {} } = this.props
    if (
      isWorkflowDeclinedFlagged(newStatus) &&
      !window.confirm(
        'Are you sure you would like to decline and flag submitted applicants unsuitable for 90 days?',
      )
    ) {
      return
    }
    if (this.state.prevApplicationWorkflowStatus !== newStatus) {
      if (isWorkflowOffered(newStatus)) {
        history.push(
          `${urlTo('leasingOffer', {
            applicationId: this.props.application.guidID,
            agencyId: currentTeam.guid,
          })}`,
        )
        return
      }
      this.setState({ workflowSaveButtonBusy: true })
      this.onApplicationWorkflowChange(newStatus)
        .then(() => {
          // a bit hack to make sure the text status is updated
          this.props.updateApplicationWorkflowStatusTo(newStatus)

          this.setState({
            workflowSaveButtonBusy: false,
            applicationWorkflowStatus: newStatus,
            prevApplicationWorkflowStatus: newStatus,
          })
          getMessage &&
            this.reactToastify('success', getMessage(this.props.application))
          if (fetchApplicationsBasedOnQuery) {
            fetchApplicationsBasedOnQuery()
          }
        })
        .catch((error) => {
          this.setState({ workflowSaveButtonBusy: false })
          this.reactToastify(
            'fail',
            `There was a problem updating the status (${error})`,
          )
        })
    }
  }

  onConfirmButtonClicked = () => {
    const { onMarkAsLeasedBtnClick } = this.props
    this.setState({
      workflowSaveButtonBusy: false,
    })
    onMarkAsLeasedBtnClick(this.state.markAsLeasedOptions)
      .then(() => {
        this.toggleModal('')
      })
      .then(() => {
        this.setState({
          ...this.state,
          workflowSaveButtonBusy: false,
          markAsLeasedOptions: {
            declineUnsuccessfulApplications: true,
            cancelFutureViewings: true,
            archiveProperty: true,
            archiveEnquirers: false,
          },
        })
      })
      .then(() => {
        if (this.props.fetchApplicationsBasedOnQuery) {
          this.props.fetchApplicationsBasedOnQuery()
        }
      })
  }

  onConfirmDeclineApplicationClicked = () => {
    const {
      declineApplicationOptions,
      declineApplicationNotifyApplicant,
      declineComment,
    } = this.state

    if (
      isWorkflowDeclined(this.state.applicationWorkflowStatus) &&
      !window.confirm('Are you sure you want to decline this application?')
    ) {
      return
    }
    if (
      isWorkflowDeclinedFlagged(this.state.applicationWorkflowStatus) &&
      !window.confirm(
        'Are you sure you would like to decline and flag submitted applicants unsuitable for 90 days?',
      )
    ) {
      return
    }
    const optionsSelected = Object.keys(declineApplicationOptions).filter(
      (item) => {
        return declineApplicationOptions[item] === true
      },
    )
    this.setState({
      declineApplicationInProgress: true,
      workflowSaveButtonBusy: false,
    })

    this.onApplicationWorkflowChange(this.state.applicationWorkflowStatus).then(
      () => {
        this.props
          .onClickDecline(
            optionsSelected,
            declineApplicationNotifyApplicant,
            declineComment,
          )
          .then(() => {
            this.props.updateApplicationWorkflowStatusTo(
              helpers.STATUS_DECLINED,
            )
            this.toggleModal('')
            this.clearDeclineApplicationModalOptions()
          })
          .then(() => {
            if (this.props.fetchApplicationsBasedOnQuery) {
              this.props.fetchApplicationsBasedOnQuery()
            }
          })
          .finally(() => {
            this.setState({ declineApplicationInProgress: false })
          })
      },
    )
  }

  onConfirmMarkAsApplicationApprovedClicked = () => {
    const { onMarkAsApplicationApproved } = this.props
    this.setState({
      workflowSaveButtonBusy: false,
    })

    const { currentTeam = {} } = this.props
    const { platformDefaultProvider = false } = currentTeam || {}
    const markAsApprovedOptions = {
      ...this.state.markAsApplicationApprovedOptions,
      sendUtilityReferrals: platformDefaultProvider
        ? true
        : this.state.markAsApplicationApprovedOptions.sendUtilityReferrals,
    }
    onMarkAsApplicationApproved(markAsApprovedOptions)
      .then(() => {
        this.toggleModal('')
      })
      .then(() => {
        this.setState({
          ...this.state,
          workflowSaveButtonBusy: false,
          markAsApplicationApprovedOptions: {
            declineUnsuccessfulApplications: false,
            cancelFutureViewings: false,
            sendUtilityReferrals: true,
            sendApplicationToPT: false,
            archiveProperty: false,
          },
        })
      })
      .then(() => {
        if (this.props.fetchApplicationsBasedOnQuery) {
          this.props.fetchApplicationsBasedOnQuery()
        }
      })
  }

  clearDeclineApplicationModalOptions = () => {
    this.setState({
      declineApplicationOptions: {
        ownerSelectedAnAlternative: false,
        householdAffordabilityIssue: false,
        petNotSuitable: false,
        rentalReferenceIssue: false,
        insufficientDocumentation: false,
        notSuitableProperty: false,
        ownerWithdrawnProperty: false,
        noSpecificReason: false,
      },
      declineApplicationNotifyApplicant: true,
      declineComment: '',
    })
  }

  closeDeclineApplicationModal = () => {
    this.toggleModal()
    this.clearDeclineApplicationModalOptions()
  }

  reactToastify = (category, message) => {
    if (category === 'success') {
      snugNotifier.success(message || 'success')
    } else if (category === 'fail') {
      snugNotifier.error(message || 'failed')
    }
  }

  toggleModal = (modal = '') => {
    this.setState({
      activeModal: modal,
    })
  }

  updateLocalStatusForWithDraw = () => {
    const processingSubstage = getProcessingSubstage()

    // a bit hack to make sure the text status is updated
    this.props.updateApplicationWorkflowStatusTo(processingSubstage.status)

    this.setState({
      applicationWorkflowStatus: processingSubstage.status,
      prevApplicationWorkflowStatus: processingSubstage.status,
    })
    // this.reactToastify('success', processingSubstage.getMessage(this.props.application));
  }

  toggleMoveApplicationPropertyModal = () => {
    this.setState({
      showMoveApplicationPropertyModal:
        !this.state.showMoveApplicationPropertyModal,
    })
  }

  onClickShortlistWithEmail = () => {
    const { application, shortlistWithEmail } = this.props
    const { guidID = null } = application

    shortlistWithEmail(guidID, { sendEmail: true })
  }

  onClickSendUtilityReferral = () => {
    const { application, sendUtilityReferralToLead } = this.props
    const isEligibleForUtilityLead =
      application?.isActive && isStatusEligibleForUtilityReferral(application)
    if (isEligibleForUtilityLead) {
      return sendUtilityReferralToLead()
    }
    this.toggleForceUtilityReferralModal()
  }

  toggleForceUtilityReferralModal = () => {
    const {
      forceUtilityReferralModal: { isOpen },
    } = this.state
    this.setState({
      forceUtilityReferralModal: { isOpen: !isOpen },
    })
  }

  renderForceUtilityReferralModal = () => {
    const { sendUtilityReferralToLead } = this.props
    const {
      forceUtilityReferralModal: { isOpen },
    } = this.state
    if (!isOpen) return
    return (
      <GenericModal
        onDismiss={this.toggleForceUtilityReferralModal}
        title="Are you sure?"
        modalFooter={
          <GenericModalFooter
            primaryBtnConfig={{
              title: 'Send Referral',
              onClick: () => sendUtilityReferralToLead(true),
            }}
            secondaryBtnConfig={{
              title: 'Cancel',
              onClick: this.toggleForceUtilityReferralModal,
            }}
          />
        }
      >
        <Box mb={6} lineHeight={theme.lineHeights.encrption}>
          This application is not at the "Approve" stage or later. Would you
          still like to send the utility referral?
        </Box>
      </GenericModal>
    )
  }
  openRegisterApplicantModal = () => {
    const { property, application } = this.props
    const activeOffer = property.offers.find((o) => o.isActive) || {}
    const primaryApplicant =
      application.applicants.find((applicant) => applicant.isPrimary) || {}
    this.setState({
      registerApplicantModal: {
        isOpened: true,
        config: {
          offerID: activeOffer.guidID,
          primaryApplicants: [primaryApplicant.guidID],
        },
      },
    })
  }

  closeRegisterApplicantModal = () =>
    this.setState({
      registerApplicantModal: {
        isOpened: false,
        config: {},
      },
    })

  renderRegisterApplicantModal = () => {
    const { registerApplicantModal } = this.state
    const { isOpened, config } = registerApplicantModal || {}
    if (!isOpened) return null

    const { property, fetchAllPropertyFutureViewings } = this.props
    const { offerID, primaryApplicants } = config
    return (
      <RegisterApplicantModal
        applicantsIDs={primaryApplicants}
        onDismiss={this.closeRegisterApplicantModal}
        property={property}
        offerID={offerID}
        fetchAllFutureViewings={fetchAllPropertyFutureViewings}
      />
    )
  }

  toggleMergeApplicationModal = () => {
    const {
      mergeApplicationModal: { isOpen },
    } = this.state
    this.setState({
      mergeApplicationModal: { isOpen: !isOpen },
    })
  }

  renderMergeApplicationModal = (applicationId) => {
    const { mergeApplicationModal } = this.state
    if (!mergeApplicationModal?.isOpen) return null
    const { property } = this.props

    const onCloseModal = () => {
      this.setState({
        mergeApplicationModal: { isOpen: false },
      })
    }

    const onSuccessfulSubmit = () => {
      snugNotifier.success('Application merge request is being sent')
      onCloseModal()
    }

    return (
      <MergeApplicationModal
        applicationId={applicationId}
        propertyId={property.guidID}
        onClose={onCloseModal}
        onMergeSubmitted={onSuccessfulSubmit}
      />
    )
  }

  render() {
    const {
      applicationStatus,
      currentTeam,
      application,
      property,
      pmsIntegrationStatus,
      refreshApplicationsList,
      executeIfClickable,
      onClickShare,
      onClickWithdrawOffer,
      compactWorkflow,
      applicationTeamSettings,
      isConsoleCloudEnabled = false,
      showNewButtonStyle = false,
      alignItems = 'center',
      showIconMoveTo,
      integrationsTeamSettings,
    } = this.props
    const { enable_internal_shortlisting: enableInternalShortlisting = false } =
      applicationTeamSettings || {}
    const {
      markAsLeasedOptions,
      markAsApplicationApprovedOptions,
      declineApplicationOptions,
      declineComment,
      declineApplicationNotifyApplicant,
      declineApplicationInProgress,
      isReopenApplicationGuardModalOpened,
    } = this.state

    if (isReopenApplicationGuardModalOpened) {
      return (
        <ReopenDeclinedApplicationConfirmationModal
          onConfirm={this.confirmReopen}
          onCancel={this.cancelReopen}
        />
      )
    }

    const { activeModal, showMoveApplicationPropertyModal } = this.state
    const isApplicationWithdrawn =
      isStatusPMWithdrawn(applicationStatus) ||
      isStatusWithdrawn(applicationStatus)
    const shouldShowWithdrawOfferButton =
      isWorkflowOfferWithdrawable(this.state.applicationWorkflowStatus) &&
      !isApplicationWithdrawn

    const providerName = currentTeam && currentTeam.providerName
    const platformDefaultProvider =
      currentTeam && currentTeam?.platformDefaultProvider

    const isUtilityProviderAvailable = !!(
      currentTeam &&
      currentTeam.utilityProviderConfigured &&
      currentTeam.providerName !== ''
    )

    const isConciergeServiceEnabled = !!(
      currentTeam && currentTeam.conciergeServiceEnabled
    )

    const activeSendingOnboardIntegrations =
      getActiveIntegrationsSendingTenancyOnboard(pmsIntegrationStatus)

    const applicants = application.applicants || []
    const primaryApplicant =
      !!applicants &&
      applicants.find((applicant) => applicant.isPrimary === true)
    const easyBondpayOptIn =
      (primaryApplicant && primaryApplicant.optedToReceiveEasyBondpay) || false

    return (
      <Flex alignItems={alignItems}>
        {shouldShowApplicationMoveTo(application) && (
          <Flex>
            <ApplicationMoveToButton
              showMoveToText={this.state.showMoveToText}
              onClickItem={this.onChangeWorkflowClicked}
              currentWorkflowStatus={this.state.applicationWorkflowStatus}
              dropup={false}
              compactWorkflow={compactWorkflow}
              showNewButtonStyle={showNewButtonStyle}
              showIconMoveTo={showIconMoveTo}
            />
            <ApplicationWorkflowSaveButton
              isLoading={this.state.workflowSaveButtonBusy}
              onClick={(newStatus, getMessage) =>
                executeIfClickable(() =>
                  this.onClickSave(newStatus, getMessage),
                )
              }
              prevWorkflowStatus={this.state.prevApplicationWorkflowStatus}
              currentWorkflowStatus={this.state.applicationWorkflowStatus}
              showNewButtonStyle={showNewButtonStyle}
            />
          </Flex>
        )}
        {activeModal === 'markAsLeased' && (
          <MarkAsLeasedModal
            toggleModal={() => this.toggleModal('')}
            easyBondpayOptIn={easyBondpayOptIn}
            isConciergeServiceEnabled={isConciergeServiceEnabled}
            isConsoleCloudEnabled={isConsoleCloudEnabled}
            activeSendingOnboardIntegrations={activeSendingOnboardIntegrations}
            onChangemarkAsLeasedOptions={(field) =>
              this.onChangemarkAsLeasedOptions(field)
            }
            markAsLeasedOptions={markAsLeasedOptions}
            onConfirmButtonClicked={() => this.onConfirmButtonClicked()}
          />
        )}
        {activeModal === 'markAsApplicationApproved' && (
          <ApplicationApprovedModal
            markAsApplicationApprovedOptions={markAsApplicationApprovedOptions}
            pmsIntegrationStatus={pmsIntegrationStatus}
            onChangeMarkAsApplicationApprovedOptions={
              this.onChangeMarkAsApplicationApprovedOptions
            }
            toggleModal={this.toggleModal}
            onConfirmMarkAsApplicationApprovedClicked={
              this.onConfirmMarkAsApplicationApprovedClicked
            }
            currentTeam={currentTeam}
            easyBondpayOptIn={easyBondpayOptIn}
            integrationSettings={integrationsTeamSettings}
          />
        )}
        {activeModal === 'markAsDepositReceived' && (
          <DepositReceivedOptionsModal
            isConsoleCloudEnabled={isConsoleCloudEnabled}
            activeSendingOnboardIntegrations={activeSendingOnboardIntegrations}
            isUtilityProviderAvailable={isUtilityProviderAvailable}
            isConciergeServiceEnabled={isConciergeServiceEnabled}
            isEasyBondpayOptIn={easyBondpayOptIn}
            providerName={providerName}
            toggleModal={this.toggleModal}
            onSubmit={(actions) =>
              this.onApplicationStatusUpdateWithActions(
                getApplicationWorkflowEnum('Deposit received'),
                actions,
              )
            }
            platformDefaultProvider={platformDefaultProvider}
            teamSlug={currentTeam.slug}
            integrationSettings={integrationsTeamSettings}
          />
        )}
        {activeModal === 'markAsApplicationDeclined' && (
          <DeclineApplicationModal
            declineApplicationOptions={declineApplicationOptions}
            declineApplicationNotifyApplicant={
              declineApplicationNotifyApplicant
            }
            onChangeDeclineApplicationOptions={
              this.onChangeDeclineApplicationOptions
            }
            onChangeNotifyApplicant={this.onChangeNotifyApplicant}
            toggleModal={this.closeDeclineApplicationModal}
            onConfirmDeclineApplicationClicked={
              this.onConfirmDeclineApplicationClicked
            }
            currentTeam={currentTeam}
            declineComment={declineComment}
            onChangeDeclineComment={this.onChangeDeclineComment}
            declineApplicationInProgress={declineApplicationInProgress}
          />
        )}
        <div className="display-flex team-over-button pl10 justify-content-flex-end">
          <div className="dropdown actions">
            <div
              className="dropdown-toggle drop-down-toggler"
              data-toggle="dropdown"
            >
              <i className="icon-more drop-down-spots" />
            </div>
            <ul className="dropdown-menu team-overview-dropdown">
              {enableInternalShortlisting && application?.isActive && (
                <li
                  className="link-styles"
                  onClick={() => this.onClickShortlistWithEmail()}
                >
                  Shortlist with email
                </li>
              )}

              <li
                className="link-styles"
                onClick={this.openRegisterApplicantModal}
              >
                Register to View
              </li>

              <li
                className="link-styles"
                onClick={this.props.onClickScheduleViewing}
              >
                Schedule Viewing
              </li>
              {application.isActive && (
                <li
                  className="link-styles"
                  onClick={() => executeIfClickable(onClickShare)}
                >
                  Share
                </li>
              )}
              {shouldShowWithdrawOfferButton && (
                <li
                  className="link-styles"
                  onClick={() =>
                    executeIfClickable(() =>
                      onClickWithdrawOffer(this.updateLocalStatusForWithDraw),
                    )
                  }
                >
                  Withdraw offer
                </li>
              )}
              {isUtilityProviderAvailable && application?.isActive && (
                <li
                  className="link-styles"
                  onClick={() =>
                    executeIfClickable(this.onClickSendUtilityReferral)
                  }
                >
                  Send utility referral
                </li>
              )}
              {application.isActive && (
                <li
                  className="link-styles"
                  onClick={() =>
                    executeIfClickable(() =>
                      this.toggleMoveApplicationPropertyModal(),
                    )
                  }
                >
                  Move application
                </li>
              )}
              {isApplicationMergeable(application) && (
                <li
                  className="link-styles"
                  onClick={() =>
                    executeIfClickable(() => this.toggleMergeApplicationModal())
                  }
                >
                  Merge application
                </li>
              )}
            </ul>
          </div>
        </div>

        {showMoveApplicationPropertyModal && (
          <MoveApplicationPropertyModal
            toggleModal={this.toggleMoveApplicationPropertyModal}
            teamGUID={currentTeam.guid}
            application={application}
            property={property}
            refresh={refreshApplicationsList}
          />
        )}
        {this.renderRegisterApplicantModal()}
        {this.renderForceUtilityReferralModal()}
        {this.renderMergeApplicationModal(application.guidID)}
      </Flex>
    )
  }
}

const mapDispatchToPropsForApplicationWorkflow = (dispatch) => ({
  fetchAllPropertyFutureViewings: (teamGUID) =>
    dispatch(fetchAllPropertyFutureViewings(teamGUID)),
})

export const ApplicationWorkflowToolbar = connect(
  null,
  mapDispatchToPropsForApplicationWorkflow,
)(ApplicationWorkflowToolbarContainer)
