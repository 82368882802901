import moment from 'moment'

import { filterManagerViewings, parseManagerViewing } from 'app/sm/helpers'
import { getViewings } from 'app/sm/manager_viewings/manager_viewings_access'
import store from 'app/store'

export const PING = 'V_MANAGER_PING'
export const TOGGLE_SPINNER = 'V_MANAGER_TOGGLE_SPINNER'
export const RECEIVE_RESPONSE_TEXT = 'V_MANAGER_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_COMING_UP_VIEWINGS = 'V_MANAGER_RECEIVE_COMING_UP_VIEWINGS'
export const RECEIVE_COMPLETED_VIEWINGS = 'V_MANAGER_RECEIVE_COMPLETE_VIEWINGS'
export const RECEIVE_ERROR = 'V_MANAGER_RECEIVE_ERROR'
export const ON_SORTER_DROPDOWN_CHANGE = 'V_MANAGER_ON_SORTER_DROPDOWN_CHANGE'
export const ON_TAB_CHANGE = 'V_MANAGER_ON_TAB_CHANGE'

export const ping = () => ({ type: PING })

const toggleSpinner = () => ({ type: TOGGLE_SPINNER })
const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})

const receiveComingUpViewings = (comingUpViewings) => ({
  type: RECEIVE_COMING_UP_VIEWINGS,
  comingUpViewings,
})
const receiveCompletedViewings = (completedViewings) => ({
  type: RECEIVE_COMPLETED_VIEWINGS,
  completedViewings,
})

function parseServerViewings(viewings) {
  return viewings.map((record) =>
    Object.assign({}, record, {
      viewing: parseManagerViewing(record.viewing, record.offer.property),
    }),
  )
}

export function fetchViewings() {
  return (dispatch) => {
    dispatch(toggleSpinner())
    getViewings().then(({ ok, responseText, viewings }) => {
      if (ok) {
        const parsedServerViewings = parseServerViewings(viewings).sort(
          (a, b) =>
            moment(a.viewing.startDate, 'YYYY-MM-DD HH:mm:ss').isAfter(
              b.viewing.startDate,
              'YYYY-MM-DD HH:mm:ss',
            )
              ? 1
              : -1,
        )
        const comingUpViewings = filterManagerViewings(parsedServerViewings)
        const completedViewings = filterManagerViewings(
          parsedServerViewings,
          true,
        )

        dispatch(receiveComingUpViewings(comingUpViewings))
        dispatch(receiveCompletedViewings(completedViewings))
        dispatch(ping())
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
      dispatch(toggleSpinner())
    })
  }
}

function sortViewings(sorter) {
  return (dispatch, getState) => {
    const { viewings, comingUpViewings, completedViewings, activeTab } =
      getState().ManagerViewings

    switch (sorter) {
      case 'time':
        if (activeTab === 'completed') {
          dispatch(
            receiveCompletedViewings(
              completedViewings.sort((a, b) =>
                moment(a.viewing.startDate, 'YYYY-MM-DD HH:mm:ss').isAfter(
                  b.viewing.startDate,
                  'YYYY-MM-DD HH:mm:ss',
                )
                  ? 1
                  : -1,
              ),
            ),
          )
        }
        if (activeTab === 'coming_up') {
          dispatch(
            receiveComingUpViewings(
              comingUpViewings.sort((a, b) =>
                moment(a.viewing.startDate, 'YYYY-MM-DD HH:mm:ss').isAfter(
                  b.viewing.startDate,
                  'YYYY-MM-DD HH:mm:ss',
                )
                  ? 1
                  : -1,
              ),
            ),
          )
        }

        break
      case 'timeDesc':
        if (activeTab === 'completed') {
          dispatch(
            receiveCompletedViewings(
              completedViewings.sort((a, b) =>
                moment(a.viewing.startDate, 'YYYY-MM-DD HH:mm:ss').isBefore(
                  b.viewing.startDate,
                  'YYYY-MM-DD HH:mm:ss',
                )
                  ? 1
                  : -1,
              ),
            ),
          )
        }
        if (activeTab === 'coming_up') {
          dispatch(
            receiveComingUpViewings(
              comingUpViewings.sort((a, b) =>
                moment(a.viewing.startDate, 'YYYY-MM-DD HH:mm:ss').isBefore(
                  b.viewing.startDate,
                  'YYYY-MM-DD HH:mm:ss',
                )
                  ? 1
                  : -1,
              ),
            ),
          )
        }
        break
      case 'address':
        if (activeTab === 'completed') {
          dispatch(
            receiveCompletedViewings(
              completedViewings.sort((a, b) =>
                a.viewing.address < b.viewing.address ? -1 : 1,
              ),
            ),
          )
        }
        if (activeTab === 'coming_up') {
          dispatch(
            receiveComingUpViewings(
              comingUpViewings.sort((a, b) =>
                a.viewing.address < b.viewing.address ? -1 : 1,
              ),
            ),
          )
        }

        break
      case 'addressDesc':
        if (activeTab === 'completed') {
          dispatch(
            receiveCompletedViewings(
              completedViewings.sort((a, b) =>
                a.viewing.address > b.viewing.address ? -1 : 1,
              ),
            ),
          )
        }
        if (activeTab === 'coming_up') {
          dispatch(
            receiveComingUpViewings(
              comingUpViewings.sort((a, b) =>
                a.viewing.address > b.viewing.address ? -1 : 1,
              ),
            ),
          )
        }
        break
      case 'registered':
        if (activeTab === 'completed') {
          dispatch(
            receiveCompletedViewings(
              completedViewings.sort((a, b) =>
                a.numberRegistered < b.numberRegistered ? -1 : 1,
              ),
            ),
          )
        }
        if (activeTab === 'coming_up') {
          dispatch(
            receiveComingUpViewings(
              comingUpViewings.sort((a, b) =>
                a.numberRegistered < b.numberRegistered ? -1 : 1,
              ),
            ),
          )
        }
        break
      case 'registeredDesc':
        if (activeTab === 'completed') {
          dispatch(
            receiveCompletedViewings(
              completedViewings.sort((a, b) =>
                a.numberRegistered > b.numberRegistered ? -1 : 1,
              ),
            ),
          )
        }
        if (activeTab === 'coming_up') {
          dispatch(
            receiveComingUpViewings(
              comingUpViewings.sort((a, b) =>
                a.numberRegistered > b.numberRegistered ? -1 : 1,
              ),
            ),
          )
        }
        break
      default:
        dispatch(receiveCompletedViewings(completedViewings))
        dispatch(receiveComingUpViewings(comingUpViewings))
        return viewings
    }
  }
}

export function onSorterDropdownChange(sorter) {
  return (dispatch) => {
    dispatch({ type: ON_SORTER_DROPDOWN_CHANGE, sorter })
    dispatch(sortViewings(sorter))
  }
}

export function onTabChange(activeTab) {
  return (dispatch) => {
    dispatch({ type: ON_TAB_CHANGE, activeTab })
  }
}
