import { api } from 'app/utils/api/helpers'

export const updateStatusWithSideEffectActions = (
  applicationGUID,
  status,
  actions,
) => {
  return api.put(
    `/sm/applications/${applicationGUID}/updateStatusWithActions`,
    { status, ...actions },
  )
}

export const moveApplicationToProperty = (
  teamGUID,
  applicationGUID,
  payload,
) => {
  return api.post(
    `/sm/team/${teamGUID}/application/${applicationGUID}/move`,
    payload,
  )
}
