import React from 'react'

import styled from 'styled-components'
import { isArray } from 'util'

import DefaultButton from 'app/components/buttons/default/component'
import * as Display from 'app/components/display/display'
import SnugTip from 'app/components/display/snug_tip/component'
import {
  GreySubheading,
  SectionHeader,
} from 'app/components/display/text/standard_text/standard-headings/component'
import * as Form from 'app/components/forms/forms'
import {
  validateEmail,
  validatePhoneOrMobileNumber,
} from 'app/shared_components/validations'
import * as Helpers from 'app/sm/helpers'
import { NUMBER_TYPE_MOBILE } from 'app/utils/phonenumber/helpers'

const errorClass = (hasError) => (hasError ? 'error' : '')
const isThereValidationErrors = (validations) =>
  Object.keys(validations).some((key) => validations[key] !== '')
const formatValidationErrors = (validations) =>
  Object.keys(validations).map((key) => <div key={key}>{validations[key]}</div>)
const areSomeFieldsEmpty = (newTenant) =>
  Object.keys(newTenant).some((key) => newTenant[key] === '')
const emptyValidations = () => ({
  firstName: '',
  lastName: '',
  mobileNumber: '',
  email: '',
})
const emptyTenant = () => ({
  firstName: '',
  lastName: '',
  mobileNumber: '',
  email: '',
})

const trimFields = (tenant) =>
  Object.keys(tenant).reduce(
    (tenantVal, key) =>
      Object.assign({}, tenantVal, {
        [key]: tenant[key].trim(),
      }),
    {},
  )

const allInfoFilled = (obj) => {
  for (var key in obj) {
    if (obj[key] === '') return false
  }
  return true
}

export const isSecondaryOrNewApplicant = (applicant) =>
  !applicant.guidID || applicant.isPrimary === false

export function IconNameValue(props) {
  const { icon, name, value, link } = props
  const wrappedValue = link ? <a href={`${link}:${value}`}>{value}</a> : value
  return (
    <div className="pb10">
      {icon && <i className={`${icon} ibm`} />}
      <span className="ibm fw600 pr10">{name}:</span>
      <span className="ibm">{wrappedValue}</span>
    </div>
  )
}

const StyledSnugTip = styled.div`
  top: -150px;
  @media screen and (max-width: 1159px) {
    top: 30px;
  }
`

class Household extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newTenant: emptyTenant(),
      validations: emptyValidations(),
      applicationsList: [],
    }
  }

  onApplicantValueUpdated = (field, nth, options = {}) => {
    return ({ value, error }) => {
      const { reverseValue = false } = options
      const {
        onApplicantFieldChange,
        currentApplicant,
        isApplicationCreatedByManager,
      } = this.props
      let useCaseError = ''
      if (
        !isApplicationCreatedByManager &&
        field === 'email' &&
        value === currentApplicant.email
      ) {
        useCaseError = 'You are already an applicant'
      }
      const finalErrorMessage = error || useCaseError
      onApplicantFieldChange(
        field,
        reverseValue ? !value : value,
        nth,
        finalErrorMessage,
      )
    }
  }

  onRemoveButtonClick = (nth) => {
    const { onRemoveButtonClick } = this.props
    onRemoveButtonClick(nth)
  }

  addNewRecord = () => {
    this.props.onAddButtonClick()
  }

  updateTenantField(field, value) {
    const { setUnsavedChanges } = this.props
    setUnsavedChanges()
    this.setState(
      {
        newTenant: Object.assign({}, this.state.newTenant, {
          [field]: value,
        }),
      },
      () => {},
    )
  }

  validateTenantField(field) {
    const {
      onOtherApplicantFormOnBlur,
      addCurrentNewTenant,
      setUnsavedChanges,
    } = this.props
    const { newTenant, validations } = this.state
    const value = newTenant[field]
    if (value === '') {
      this.setState({
        validations: Object.assign({}, this.state.validations, {
          [field]: `The field ${field} is required`,
        }),
      })
      onOtherApplicantFormOnBlur(
        !areSomeFieldsEmpty(this.state.newTenant),
        !isThereValidationErrors(this.state.validations),
        this.state.newTenant,
      )
      return
    }

    this.setState({
      validations: Object.assign({}, this.state.validations, {
        [field]: '',
      }),
    })

    let validationResult

    switch (field) {
      case 'mobileNumber':
        validationResult = validatePhoneOrMobileNumber(value, '')

        if (isArray(validationResult) && validationResult.length === 0) {
          validationResult = ''
        } else {
          validationResult =
            'Phone number invalid, e.g. 02 9123 9123 or 0431 111 111'
        }

        this.setState(
          {
            validations: Object.assign({}, this.state.validations, {
              [field]: validationResult,
            }),
          },
          () => {
            addCurrentNewTenant(newTenant)
            onOtherApplicantFormOnBlur(
              !areSomeFieldsEmpty(this.state.newTenant),
              !isThereValidationErrors(this.state.validations),
            )
          },
        )
        return
      case 'email':
        validationResult = validateEmail(value, '')

        if (isArray(validationResult) && validationResult.length === 0) {
          validationResult = ''
        } else {
          validationResult = 'The email is not valid'
        }

        this.setState(
          {
            validations: Object.assign({}, this.state.validations, {
              [field]: validationResult,
            }),
          },
          () => {
            addCurrentNewTenant(newTenant)
            onOtherApplicantFormOnBlur(
              !areSomeFieldsEmpty(this.state.newTenant),
              !isThereValidationErrors(this.state.validations),
            )
          },
        )
        return
    }
    addCurrentNewTenant(newTenant)
    onOtherApplicantFormOnBlur(
      !areSomeFieldsEmpty(this.state.newTenant),
      !isThereValidationErrors(this.state.validations),
    )
  }

  render() {
    const { validations } = this.state
    const {
      applicants = [],
      reviewMode = false,
      isApplicationEditable = true,
      applicantsErrors,
      isApplicationCreatedByManager,
      applicationSettingInfo,
    } = this.props

    const { disable_occupants_enabled = false } = applicationSettingInfo || {}

    const introductoryText = `Please provide contact details for other Applicants (on the lease / living in the property)${
      disable_occupants_enabled ? '' : ' or Occupants (not on the lease)'
    }. We will email an invite to join your application as a household.`

    const isAddButtonDisabled = applicants.length >= 6 || !isApplicationEditable

    return (
      <div className="row p24 mr0 ml0 mobile-apply-padding">
        <div className="col-sm-12 pr0 pl0 mb10">
          <StyledSnugTip className="snug-tip-position multi-applicant-tip-position">
            <Display.SnugTip
              text="Adjust the number of adults to add in your co-applicants. We'll invite them to apply once you've submitted your application."
              componentClass="apply-snug-tip"
            />
          </StyledSnugTip>
        </div>
        {(!reviewMode || reviewMode) && (
          <div className="col-sm-12 pl0 pr0">
            {!!applicants.filter(isSecondaryOrNewApplicant).length && (
              <div>
                <Display.GreySubheading text="Adults (18+ years)" />
                <Display.GreyBodyText text={introductoryText} />
              </div>
            )}
            {applicants
              .filter(
                isApplicationCreatedByManager
                  ? () => true
                  : isSecondaryOrNewApplicant,
              )
              .map((tenant, nth) => {
                const validationReminderText = tenant.isJointApplicant
                  ? `Please provide phone and email contact details. We'll issue them an email invitation to apply`
                  : `Please provide phone or email contact details. They'll be noted as a resident`
                const isFirstAdultForm = nth === 0

                const firstAdultInManagerApplication =
                  isFirstAdultForm && isApplicationCreatedByManager
                const isRemoveEnabled =
                  isApplicationEditable && !firstAdultInManagerApplication
                const isOccupantOptionEnabled =
                  !firstAdultInManagerApplication && !disable_occupants_enabled
                const adultFormTitleNumber = isApplicationCreatedByManager
                  ? Helpers.IDDocumentDocType.DriverLicense
                  : Helpers.IDDocumentDocType.Passport
                const adultText = `Adult ${nth + adultFormTitleNumber} ${
                  firstAdultInManagerApplication ? ' (Primary)' : ''
                } ${tenant.isJointApplicant ? '(on the lease)' : ''}`
                return (
                  <Display.GreyContainer
                    key={`${nth}`}
                    containerClass="mb20 col-sm-12"
                  >
                    <div className="secondary-applicant-form-position">
                      <div className="field-container row">
                        <div className="col-sm-8">
                          <Display.GreySubheading
                            text={adultText}
                            componentClass="applicant-heading-spacing"
                          />
                        </div>
                        <div className="col-sm-4">
                          {isRemoveEnabled && (
                            <DefaultButton
                              text="Remove"
                              componentClass="remove-tenant-record destructive-hollow"
                              size="small"
                              onClick={() => this.onRemoveButtonClick(nth)}
                              buttonType="destructive"
                            />
                          )}
                        </div>
                      </div>

                      <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                        <div className="col-md-6 p0">
                          <Form.InputName
                            label="First Name"
                            value={tenant.firstName}
                            id="firstName"
                            disabled={!isApplicationEditable}
                            onChange={this.onApplicantValueUpdated(
                              'firstName',
                              nth,
                            )}
                            error={applicantsErrors[nth]['firstName']}
                            inputClass="width100"
                            componentClass="margin-profile-item-left"
                          />
                        </div>
                        <div className="col-md-6 p0">
                          <Form.InputName
                            label="Last Name"
                            value={tenant.lastName}
                            id="lastName"
                            disabled={!isApplicationEditable}
                            onChange={this.onApplicantValueUpdated(
                              'lastName',
                              nth,
                            )}
                            error={applicantsErrors[nth]['lastName']}
                            inputClass="width100"
                            componentClass="margin-profile-item-right"
                          />
                        </div>
                      </Form.ProfilePagesRowLayout>
                      <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                        <div className="col-md-6 p0">
                          <Form.InputPhoneNumber
                            label={`Phone Number${
                              isApplicationCreatedByManager ? '(optional)' : ''
                            }`}
                            value={tenant.mobileNumber}
                            error={applicantsErrors[nth]['mobileNumber']}
                            onChange={this.onApplicantValueUpdated(
                              'mobileNumber',
                              nth,
                            )}
                            id="phone"
                            containerClassName="width100"
                            componentClass="margin-profile-item-left"
                            numberType={NUMBER_TYPE_MOBILE}
                            required={
                              tenant.isJointApplicant &&
                              !isApplicationCreatedByManager
                            }
                          />
                        </div>
                        <div className="col-md-6 p0">
                          <Form.InputEmail
                            label="Email"
                            value={tenant.email}
                            error={applicantsErrors[nth]['email']}
                            onChange={this.onApplicantValueUpdated(
                              'email',
                              nth,
                            )}
                            id="email"
                            inputClass="width100"
                            componentClass="margin-profile-item-right"
                            isOptional={!tenant.isJointApplicant}
                          />
                        </div>
                      </Form.ProfilePagesRowLayout>
                      <div className="display-flex">
                        <Form.CheckBoxGeneral
                          componentClassName="mr10"
                          label="Applicant (on the lease)"
                          checked={tenant.isJointApplicant}
                          onChange={this.onApplicantValueUpdated(
                            'isJointApplicant',
                            nth,
                          )}
                        />
                        {isOccupantOptionEnabled && (
                          <Form.CheckBoxGeneral
                            label="Occupant"
                            checked={!tenant.isJointApplicant}
                            onChange={this.onApplicantValueUpdated(
                              'isJointApplicant',
                              nth,
                              { reverseValue: true },
                            )}
                          />
                        )}
                      </div>
                      <div className="mt30-mobile">
                        <Display.GreyBodyText text={validationReminderText} />
                      </div>
                    </div>
                  </Display.GreyContainer>
                )
              })}
            {!reviewMode && (
              <div>
                <div
                  className={
                    isThereValidationErrors(validations)
                      ? 'alert alert-danger'
                      : 'hidden'
                  }
                >
                  {formatValidationErrors(validations)}
                </div>
                {applicants.filter(isSecondaryOrNewApplicant).length > 0 && (
                  <div>
                    <div className="text-center col-sm-5 p0">
                      <DefaultButton
                        text="Add Another Adult"
                        onClick={() => this.addNewRecord()}
                        disabled={isAddButtonDisabled}
                        size="large"
                        buttonType="default"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default Household
