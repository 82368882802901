import { useRef, useState } from 'react'

import { setNestedObjectValues } from 'formik'

import { Alert, Box, Flex } from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { VIEWING_FORM_TYPE } from 'app/components/ViewingRegisterRequestFormModal/form-utils'
import RegisterViewingModalBody from 'app/components/ViewingRegisterRequestFormModal/register-form-body'
import RequestViewingModalBody from 'app/components/ViewingRegisterRequestFormModal/request-form-body'
import { StyledSubText } from 'app/components/ViewingRegisterRequestFormModal/styled-form-component'
import * as snugNotifier from 'app/services/snugNotifier'
import { isMobile } from 'app/shared_components/helpers'
import * as helpers from 'app/sm/helpers'
import {
  IS_FROM_SCHEDULE,
  SKIP_DOUBLE_BOOKING_CHECK,
} from 'app/sm/property_details/property_details_container'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import { isEmpty } from 'app/utils/objects/helpers'
import { PRIVACY_SECURITY_LEARN_MORE_LINK } from 'app/utils/text/helpers'

const NewRegisterRequestViewingModal = ({
  toggleModal,
  addressFriendlyName,
  agencyName,
  currentUser,
  requestForViewing,
  agencyID,
  managingAgentId,
  propertyId,
  managerEmail,
  type,
  selectedViewing,
  registerForEmailViewing,
  addViewing,
  offerId,
  registerModalSuccessCb,
  finalizeRequestViewing,
  modalSend,
}) => {
  const formRef = useRef()
  const [loading, setLoading] = useState(false)

  const _renderModalTitle = () => {
    if (
      type === VIEWING_FORM_TYPE.register ||
      type === VIEWING_FORM_TYPE.potential_register
    ) {
      return 'Register for viewing'
    }

    if (type === VIEWING_FORM_TYPE.request) {
      return 'Request a viewing'
    }
  }

  const _renderModalButton = () => {
    if (
      type === VIEWING_FORM_TYPE.register ||
      type === VIEWING_FORM_TYPE.potential_register
    ) {
      return 'Register'
    }

    if (type === VIEWING_FORM_TYPE.request) {
      return 'Request'
    }
  }

  const _renderSubTitleText = () => {
    if (type === VIEWING_FORM_TYPE.request) {
      return (
        <Box mb={5}>
          Enter your details and preferred time to request a viewing for{' '}
          <b>{addressFriendlyName}</b> with <>{agencyName}</>.
        </Box>
      )
    }
    if (
      type === VIEWING_FORM_TYPE.register ||
      type === VIEWING_FORM_TYPE.potential_register
    ) {
      return (
        <Box mb={5}>
          Enter your details to register to view <b>{addressFriendlyName}</b>{' '}
          with <>{agencyName}</>.
        </Box>
      )
    }
  }

  const _renderFormBody = () => {
    if (type === VIEWING_FORM_TYPE.request) {
      return (
        <Box mb={5}>
          <RequestViewingModalBody
            formRef={formRef}
            currentUser={currentUser}
          />
        </Box>
      )
    }
    if (
      type === VIEWING_FORM_TYPE.register ||
      type === VIEWING_FORM_TYPE.potential_register
    ) {
      return (
        <Box mb={5}>
          <RegisterViewingModalBody
            formRef={formRef}
            currentUser={currentUser}
            selectedViewing={selectedViewing}
          />
        </Box>
      )
    }
  }

  const submitRequestForViewing = () => {
    const {
      email,
      firstName,
      lastName,
      contactNumber,
      preferredTime,
      preferredDate,
    } = formRef.current.values

    const payload = {
      propertyGUID: propertyId,
      managingAgentID: managingAgentId,
      agencyID: agencyID,
      managerEmail: managerEmail,
      firstName,
      lastName,
      email,
      contactNumber,
      preferredDate: preferredDate
        ? dateTimeHelpers.formatPayloadDate(preferredDate)
        : '',
      preferredTime,
    }

    setLoading(true)
    requestForViewing(payload)
      .then(() => {
        snugNotifier.success(
          'Request sent. The Property Manager will contact you to confirm.',
        )
        toggleModal()
        setLoading(false)
        finalizeRequestViewing()
      })
      .catch((error) => {
        snugNotifier.error(error)
        setLoading(false)
        finalizeRequestViewing()
      })
  }

  const submitRegisterForViewing = () => {
    const { email, firstName, lastName, contactNumber } = formRef.current.values
    const { referrer_id, referrer_group, referrer_url } =
      helpers.parseURLQuery()
    const payload = {
      firstName,
      lastName,
      email,
      mobilePhone: contactNumber,
      referrerID: referrer_id || '',
      referrerGroup: referrer_group || '',
      referrerURL: referrer_url || '',
    }
    setLoading(true)
    registerForEmailViewing(selectedViewing.viewingID, payload)
      .then(({ data }) => {
        snugNotifier.success('You have successfully registered for the viewing')
        toggleModal()
        setLoading(false)
        registerModalSuccessCb(data)
      })
      .catch((error) => {
        setLoading(false)
        snugNotifier.error(error)
      })
  }

  const submitRegisterForPotentialViewing = () => {
    const { email, firstName, lastName, contactNumber } = formRef.current.values
    const { referrer_id, referrer_group, referrer_url } =
      helpers.parseURLQuery()
    const payload = {
      firstName,
      lastName,
      email,
      mobilePhone: contactNumber,
      referrerID: referrer_id || '',
      referrerGroup: referrer_group || '',
      referrerURL: referrer_url || '',
    }
    setLoading(true)
    addViewing(
      offerId,
      dateTimeHelpers.getDateValue(selectedViewing.preferredDate),
      selectedViewing.duration,
      IS_FROM_SCHEDULE,
      SKIP_DOUBLE_BOOKING_CHECK,
      {
        published: true,
        notify: true,
        isNoneUser: true,
      },
    )
      .then(({ viewing }) => {
        const { guidID: viewingId } = viewing
        registerForEmailViewing(viewingId, payload)
          .then(({ data }) => {
            snugNotifier.success(
              'You have successfully registered for the viewing',
            )
            toggleModal()
            registerModalSuccessCb(data)
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            snugNotifier.error(error)
          })
      })
      .catch((error) => {
        setLoading(false)
        snugNotifier.error(error)
      })
  }

  return (
    <GenericModal
      onDismiss={() => toggleModal()}
      title={_renderModalTitle()}
      takeMaxHeight={true}
      modalFooter={
        <GenericModalFooter
          secondaryBtnConfig={{
            title: 'Cancel',
            onClick: () => toggleModal(),
          }}
          primaryBtnConfig={{
            title: _renderModalButton(),
            disabled: type === VIEWING_FORM_TYPE.request && modalSend,
            props: {
              loading,
            },
            onClick: () => {
              formRef.current.validateForm().then((errors) => {
                if (isEmpty(errors)) {
                  if (type === VIEWING_FORM_TYPE.request) {
                    submitRequestForViewing()
                  }
                  if (type === VIEWING_FORM_TYPE.register) {
                    submitRegisterForViewing()
                  }
                  if (type === VIEWING_FORM_TYPE.potential_register) {
                    submitRegisterForPotentialViewing()
                  }
                } else {
                  formRef.current.setTouched(
                    setNestedObjectValues(errors, true),
                  )
                  const errorMessage = 'Please add valid contact details'
                  snugNotifier.error(errorMessage)
                }
              })
            },
          }}
        />
      }
    >
      {_renderSubTitleText()}
      <Flex flexDirection={isMobile() ? 'column-reverse' : 'column'}>
        {(type === VIEWING_FORM_TYPE.register ||
          type === VIEWING_FORM_TYPE.potential_register) && (
          <Alert variant="warningWithBg" mb={3} fontSize={4}>
            Registration is mandatory. If you do not register, you may be denied
            entry and the viewing may be cancelled.
          </Alert>
        )}
        {_renderFormBody()}
      </Flex>
      <Box mb={5}>
        <StyledSubText>
          This real estate agency uses Snug for managing the leasing process and
          to provide related services to you. By providing information you agree
          to the{' '}
          <a
            href={PRIVACY_SECURITY_LEARN_MORE_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            Snug Privacy Policy
          </a>{' '}
          regarding the collection, storage and disclosure of information via
          Snug. Note agency may require or apply an additional privacy policy or
          consent and manage your data outside of this agreement.
        </StyledSubText>
      </Box>
    </GenericModal>
  )
}

export default NewRegisterRequestViewingModal
