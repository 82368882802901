import { useEffect } from 'react'

import {
  Box,
  Button,
  LoadingSection,
} from 'app/components/design-system-components'
import { keySetTypesOptions } from 'app/constants/key-logger.constants'
import {
  SuccessModal,
  SuccessModalSimpleDescriptionList,
} from 'app/features/teams/key-logger/components/SuccessModal'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  generateKeySetPopulateParams,
  getKeySet,
} from 'app/services/http/teams/key-logger'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'

export const AddKeySetSuccessModal = ({
  teamId,
  keySet: initialKeySet,
  closeModal,
  addAnotherKeySet,
}) => {
  const {
    state: keySetInfo,
    setState: setKeySetInfo,
    loadingStates: keySetInfoLoadingStates,
    loadingStatesHelpers: keySetInfoLoadingStatesHelpers,
  } = useStateWithLoading({ keySet: null, listData: null })

  const generateListData = (keySet) => {
    return [
      {
        title: 'Property address',
        description: keySet.property.address.friendlyName,
      },
      { title: 'Key set', description: keySet.label },
      {
        title: 'Key type',
        description: keySetTypesOptions[keySet.set_type],
      },
    ]
  }

  const loadKeySet = () => {
    return getKeySet(
      teamId,
      initialKeySet.guid,
      generateKeySetPopulateParams({
        property: true,
      }),
    )
      .then(({ key_set: keySet }) => {
        setKeySetInfo({
          keySet,
          listData: generateListData(keySet),
        })
        keySetInfoLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => keySetInfoLoadingStatesHelpers.setError(err))
  }

  useEffect(() => {
    loadKeySet()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const actions = (
    <Box>
      <Button
        type="button"
        onClick={closeModal}
        variant="solid"
        width="100%"
        // fixme: we should be able to use {theme.space[4]} directly
        mb={theme.space[4] + 'px'}
      >
        Close
      </Button>
      <Button type="button" onClick={addAnotherKeySet} width="100%">
        Add another key set
      </Button>
    </Box>
  )

  return (
    <SuccessModal
      title="Key Set Added"
      description="Your key set has been successfully added into the key tracker log"
      actions={actions}
    >
      <LoadingSection
        height="100%"
        loadingState={keySetInfoLoadingStates}
        actionHandler={loadKeySet}
        loaderProps={{ fontSize: theme.fontSizes.pExtraLarge18 }}
      >
        {!!keySetInfo.keySet && (
          <SuccessModalSimpleDescriptionList
            listConfig={{ listData: keySetInfo.listData }}
          />
        )}
      </LoadingSection>
    </SuccessModal>
  )
}
