import React from 'react'

import houseIcon1 from 'app/assets/icons/house01.jpg'
import humanIcon2 from 'app/assets/icons/human02.jpg'
import humanIcon3 from 'app/assets/icons/human03.jpg'

function Profile() {
  return (
    <div className="sm-chat-profile">
      <div className="user">
        <div className="avatar-box">
          <div></div>
          <div
            className="avatar avatar-big"
            style={{ backgroundImage: `url(${humanIcon2})` }}
          >
            <div className="bond-covered"></div>
          </div>
          <div className="status">Primary</div>
        </div>
        <h4>Robbie T.</h4>
        <a href="#">robertatimms@gmail.com</a>
        <a href="#">+1 31 217 9271</a>
        <div className="buttons">
          <button className="btn btn-round btn-medium btn-gray">
            <i className="icon-phone"></i>
          </button>
          <button className="btn btn-round btn-medium btn-gray">
            <i className="icon-mail"></i>
          </button>
        </div>
      </div>

      <div className="meta">
        <ul>
          <li>
            <div className="icon-wrapper">
              <div
                className="avatar avatar-xsmall"
                style={{ backgroundImage: `url(${houseIcon1})` }}
              ></div>
            </div>
            <div className="text-wrapper">
              <div className="fs16 fw600 gray-darker-color">50 Bond St.</div>
              <div className="fs14 fw600 gray-color pt5">Renter</div>
            </div>
          </li>
          <li>
            <div className="icon-wrapper">
              <i className="icon-rent"></i>
            </div>
            <div className="text-wrapper">
              <div className="fs16 fw600 gray-darker-color">$600 per week</div>
              <div className="fs14 fw600 gray-color pt5">
                Due 14 May 2017 (in 14 days)
              </div>
            </div>
          </li>
          <li>
            <div className="icon-wrapper">
              <i className="icon-date"></i>
            </div>
            <div className="text-wrapper">
              <div className="fs16 fw600 gray-darker-color">4 months left</div>
              <div className="fs14 fw600 gray-color pt5">
                of 24 months agreement
              </div>
            </div>
          </li>
        </ul>
        <a href="#" className="gray-dark-color">
          View Details
        </a>
      </div>

      <div className="renters">
        <div className="title">Household (2)</div>
        <div className="avatars">
          <a href="#">
            <div
              className="avatar avatar-msmall"
              style={{ backgroundImage: `url(${humanIcon3})` }}
            ></div>
          </a>
          <a href="#">
            <div className="avatar avatar-msmall blue-bg-light-rgba blue-color">
              JM
            </div>
          </a>
        </div>
      </div>

      <div className="help">
        <div className="title">SnugHelps</div>
        <ul className="snug-tips">
          <li>
            Approval process usually takes few work days, so in the meantime
            please share some love on social networks.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Profile
