import React from 'react'

import { Link } from 'react-router-dom'

import { Button } from 'app/components/design-system-components'
import { history } from 'app/shared_components/router'
import { findTeamByAgencyId, urlTo, wait, windowUrl } from 'app/sm/helpers'
import Breadcrumbs from 'app/sm/ppp/breadcrumbs'
import { InviteToApplyModal } from 'app/sm/ppp/property_components/share/InviteToApplyModal'
import { FeatureFlag } from 'config/features'

class Share extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      linkCopied: false,
      inviteModalOpen: false,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { encodedParams },
      },
    } = this.props
    const { propertySlug } = this.decodeParams(encodedParams)
    this.props.fetchProperty(propertySlug)

    if (this.props.property && this.props.property.guidID) {
      const propertyAgencyID =
        this.props.property && this.props.property.agencyID
      const currentTeam = findTeamByAgencyId(this.props.teams, propertyAgencyID)
      currentTeam && this.props.changeTeam(currentTeam)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.property && nextProps.property.guidID) {
      const currentPropertyAgencyID =
        this.props.property && this.props.property.agencyID
      const nextPropertyAgencyID =
        nextProps.property && nextProps.property.agencyID
      if (
        nextProps.teams !== this.props.teams ||
        nextPropertyAgencyID !== currentPropertyAgencyID
      ) {
        const currentTeam = nextProps.teams.find(
          (team) => team.guid === nextPropertyAgencyID,
        )
        currentTeam && this.props.changeTeam(currentTeam)
      }
    }
  }

  copyLinkToClipboard(event) {
    const target = event.target
    try {
      target.select()
      document.execCommand('copy')
      this.setState({ linkCopied: true })
    } catch (err) {
      // TODO: Deal with crash ( but this is a little bit overly defensive, we probably would never enter here)
    } finally {
      wait(3000).then(() => this.setState({ linkCopied: false })) // Just to be in sync with the animation
    }
  }

  decodeParams(encodedParams) {
    let o
    try {
      o = JSON.parse(atob(encodedParams))
    } catch (err) {
      o = {
        offerId: '',
        propertySlug: '',
      }
    }

    return o
  }

  closeInviteToApplyModal = () => {
    this.setState({
      inviteModalOpen: false,
    })
  }

  render() {
    const {
      profile,
      steps,
      update,
      match: {
        params: { encodedParams },
      },
      property,
    } = this.props
    const { linkCopied, inviteModalOpen } = this.state

    const link = (
      <span>
        , you can also{' '}
        <Link
          to={urlTo('adFlowDescription', {
            propertyId: property.guidID,
          })}
        >
          Advertise your property
        </Link>
      </span>
    )

    const { offerId, propertySlug } = this.decodeParams(encodedParams)
    const shareLink = `${windowUrl()}${urlTo('applyRedirect', {
      slug: propertySlug,
    })}`
    const previousStepUrl = FeatureFlag.Viewings.isOn
      ? urlTo('profileViewings', { offerId: offerId })
      : urlTo('profilePreferences', { offerId: offerId })
    const createPropertyTagClass = 'manager-create-property'

    return (
      <div className="profile-screen">
        <Breadcrumbs activeBreadCrumb="share" />
        <div className="mobile-only">
          <button
            onClick={() => history.push(previousStepUrl)}
            className="btn btn-transparent btn-left xs-text-center wa"
          >
            <i className="icon-arrow-left left" />
            <span>Back</span>
          </button>
        </div>
        <div className="section-title">
          <div className="left column">
            <h3>Share your property link</h3>
            <p className="gray-color mt10 mb0">
              Share and start taking applications
            </p>
          </div>
        </div>
        <div className="ps-content p0">
          <div className="two-col-box sm64">
            <div className="col-first xs-second">
              <p className="mb40">
                We've created a unique link to allow you to start taking
                applications for your property. Click to copy the link.{' '}
                {FeatureFlag.Advertisement.isOn && link}
              </p>

              <div className="mb15">
                <h4 className="ibm">Click to copy the link and share:</h4>
                {linkCopied && (
                  <span className={`ibm pink-color pl15 pt2`}>
                    (Link copied, now you can share it)
                  </span>
                )}
              </div>
              <input
                title="Click to copy"
                className="shareLink mb50 p0"
                type="text"
                value={shareLink}
                onClick={(e) => this.copyLinkToClipboard(e)}
                readOnly
              />

              <Button
                variant="outlineGreen"
                onClick={() => this.setState({ inviteModalOpen: true })}
                disabled={inviteModalOpen}
              >
                Invite to apply
              </Button>

              <div className="row pt25 mobile-form-button">
                <div className="col-sm-5 pb5 tablet-only">
                  <button
                    onClick={() => history.push(previousStepUrl)}
                    className="btn btn-transparent btn-left xs-text-center wa"
                  >
                    <i className="icon-arrow-left left" />
                    <span>Back</span>
                  </button>
                </div>
                <div className="col-sm-7 pb5">
                  <button
                    className={`btn ${createPropertyTagClass}`}
                    onClick={() => update(steps.Share, profile)}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {inviteModalOpen && (
          <InviteToApplyModal
            property={property}
            onDismissClick={this.closeInviteToApplyModal}
            onSuccess={this.closeInviteToApplyModal}
          />
        )}
      </div>
    )
  }
}

export default Share
