import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { history } from 'app/shared_components/router'
import { urlTo, validateAddress, validateProperty } from 'app/sm/helpers'
import * as API from 'app/sm/ppp/property_access'
import store from 'app/store'
import { api } from 'app/utils/api/helpers'

export const RECEIVE_ATTRIBUTES = 'PP_RECEIVE_ATTRIBUTES'
export const RECEIVE_PROPERTIES = 'PP_RECEIVE_PROPERTIES'
export const RECEIVE_PROPERTY = 'PP_RECEIVE_PROPERTY'
export const RESET_PROPERTY = 'PP_RESET_PROPERTY'
export const RECEIVE_RESPONSE_TEXT = 'PP_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'PP_RECEIVE_ERROR'
export const CLEAR_ERROR = 'PP_CLEAR_ERROR'
export const PING = 'PP_PING'
export const TOGGLE_LOADING = 'PP_TOGGLE_LOADING'
export const UPDATE_PROPERTY_CLICKED = 'UPDATE_PROPERTY_CLICKED'
export const UPDATE_PROPERTY_COMPLETE = 'UPDATE_PROPERTY_COMPLETE'

export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })
export const ping = () => ({ type: PING })
export const updatePropertyClicked = () => ({
  type: UPDATE_PROPERTY_CLICKED,
  updatePropertyClicked: true,
})
export const updatePropertyComplete = () => ({
  type: UPDATE_PROPERTY_COMPLETE,
  updatePropertyClicked: false,
})
export const clearError = () => ({ type: CLEAR_ERROR })

const toggleLoading = () => ({ type: TOGGLE_LOADING })

export const receiveAttributes = (attributes) => ({
  type: RECEIVE_ATTRIBUTES,
  attributes,
})

export const receiveProperties = (data) => ({
  type: RECEIVE_PROPERTIES,
  properties: data.data,
})

export const receiveProperty = (property) => {
  let { commonAmenities, otherAmenities: moreAmenities } = property

  try {
    commonAmenities = JSON.parse(commonAmenities)
  } catch (err) {
    commonAmenities = {}
  }

  try {
    moreAmenities = JSON.parse(moreAmenities)
  } catch (err) {
    moreAmenities = {}
  }

  return {
    type: RECEIVE_PROPERTY,
    property: Object.assign({}, property, {
      otherAmenities: undefined,
      commonAmenities,
      moreAmenities,
      typeId: property.type,
    }),
  }
}

export const resetProperty = () => ({ type: RESET_PROPERTY })

let attributesFetched = (attributes) => {
  store.dispatch(receiveAttributes(attributes))
}

const isCreateArchive = (path) => /create-archived/.test(path)

let propertyCreated = ({ guidID }) => {
  let url = urlTo('updatePropertyPhotos', { id: guidID })
  const {
    location: { pathname },
  } = history

  if (/property-basics/.test(pathname)) {
    url = urlTo('profilePhotos', { propertyId: guidID })
  }

  if (isCreateArchive(pathname)) {
    history.goBack()
  }

  history.push(url)
}

let propertyUpdated = (path) => (property) => {
  store.dispatch(updatePropertyComplete())
  store.dispatch(receiveResponseText(error.responseText))
  history.push(path)
  store.dispatch(receiveProperty(property))
}

let error = (error) => {
  store.dispatch(receiveResponseText(error.responseText))
  store.dispatch(updatePropertyComplete())
}

export function fetchPropertyAttributes() {
  API.getPropertyAttributes(attributesFetched, error)
}

export function fetchUserProperties(id) {
  // API.getUserProperties(id, propertiesFetched, error)
}

export const fetchProperty = (id) => (dispatch, getState) => {
  return API.getProperty(id).then(({ ok, property, responseText }) => {
    if (ok) {
      dispatch(receiveProperty(property))
      return Promise.resolve(property)
    } else {
      return responseText.then((error) => Promise.reject(error))
    }
  })
}

export function createProperty(property) {
  return (dispatch, getState) => {
    dispatch(clearError())
    const addressValidation = validateAddress(property.address)

    if (!addressValidation.ok) {
      store.dispatch(receiveResponseText(addressValidation.errorCode))

      return Promise.resolve()
    }

    const {
      location: { pathname },
    } = history
    const propertyValidation = validateProperty(property, pathname)

    if (!propertyValidation.ok) {
      store.dispatch(receiveResponseText(propertyValidation.errorCode))
      return Promise.resolve()
    }

    // inject agencyID
    const { currentTeam } = getState().session
    if (currentTeam && currentTeam.guid) {
      property = { ...property, agencyID: currentTeam.guid }
    }

    const payload = {
      property,
      address: property.address,
      ...(isCreateArchive(pathname) ? { archived: true } : {}),
      ...(property.managingAgentId && {
        managerID: property.managingAgentId,
      }),
    }

    API.createProperty(payload, propertyCreated, error)
  }
}

export function updateProperty(id, property, path) {
  return (dispatch) => {
    dispatch(clearError())
    const addressValidation = validateAddress(property.address)
    store.dispatch(updatePropertyClicked())

    if (!addressValidation.ok) {
      store.dispatch(receiveResponseText(addressValidation.errorCode))
      store.dispatch(updatePropertyComplete())
      return Promise.resolve()
    }

    const {
      location: { pathname },
    } = history
    const propertyValidation = validateProperty(property, pathname)

    if (!propertyValidation.ok) {
      store.dispatch(receiveResponseText(propertyValidation.errorCode))
      store.dispatch(updatePropertyComplete())

      return Promise.resolve()
    }

    API.updateProperty(
      id,
      {
        property,
        address: property.address,
        ...(property.managingAgentId && {
          managerID: property.managingAgentId,
        }),
      },
      propertyUpdated(path),
      error,
    )
  }
}

export function uploadPhoto(propertyId, files) {
  store.dispatch(clearError())

  return (dispatch) => {
    let photos = document.getElementById('photos')
    let f = new FormData()
    const iteratee = files || photos.files

    for (let i = 0; i < iteratee.length; i++) {
      f.append('file', iteratee[i])
    }

    dispatch(toggleLoading())

    API.uploadPhoto(propertyId, f).then(({ ok, statusCode, responseText }) => {
      if (ok) {
        dispatch(fetchProperty(propertyId))
      } else {
        responseText.then((t) => {
          dispatch(receiveResponseText(translateErrorCodeToMessage(t)))
        })
      }
      dispatch(toggleLoading())
    })
  }
}

export function setMainImage(propertyId, photoId) {
  store.dispatch(clearError())

  return (dispatch) => {
    API.setMainImage(propertyId, photoId).then(
      ({ ok, statusCode, responseText }) => {
        if (ok) {
          dispatch(fetchProperty(propertyId))
          dispatch(receiveResponseText())
        } else {
          responseText.then((t) => {
            dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

export function deletePhoto(propertyId, photoId) {
  store.dispatch(clearError())

  return (dispatch, getState) => {
    API.deletePhoto(propertyId, photoId).then(
      ({ ok, statusCode, responseText }) => {
        if (ok) {
          const propertyId = getState().Ppp.property.guidID
          dispatch(fetchProperty(propertyId))
          dispatch(receiveResponseText())
        } else {
          responseText.then((t) => {
            dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

export const fetchPropertyByGUID = (guidID) => {
  return api.get(`/sm/properties/${guidID}/summary`)
}
