import React from 'react'

import { QuestionType, times } from 'app/sm/helpers'

const isStarsRatingNotRated = (rating) => rating === 0
export class StarComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      onHoverActiveStars: 0,
    }
  }

  onHoverStar(nth) {
    this.setState({
      onHoverActiveStars: nth,
    })
  }

  onLeaveStar() {
    this.setState({
      onHoverActiveStars: 0,
    })
  }

  render() {
    const {
      questionCategory,
      updateQuestionAnswer,
      referenceId,
      existingAnswer,
      disabledRating,
    } = this.props
    const { onHoverActiveStars } = this.state
    return (
      <div
        className="rating-categories-box"
        key={questionCategory.Code + questionCategory.Description}
      >
        <div className="rating-desc"> {questionCategory.Description} </div>
        <div
          className={`${disabledRating} rating-stars`}
          key={questionCategory.Code}
        >
          {times(5).map((i) => {
            let rating = isStarsRatingNotRated(onHoverActiveStars)
              ? existingAnswer
              : onHoverActiveStars
            let key = i + 1
            return (
              <i
                key={questionCategory.Code + '.' + key}
                className={
                  key <= rating
                    ? 'icon-star rated rating-star-clicked'
                    : 'icon-star rating-star-clicked'
                }
                onMouseEnter={() => this.onHoverStar(key)}
                onMouseLeave={() => this.onLeaveStar()}
                onClick={
                  disabledRating
                    ? () => {}
                    : () =>
                        updateQuestionAnswer(
                          questionCategory.Code,
                          String(key),
                          referenceId,
                          QuestionType.Int,
                        )
                }
              ></i>
            )
          })}
        </div>
      </div>
    )
  }
}

export default StarComponent
