import * as React from 'react'

function SvgWhereToVoteRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9.265c0-3.86 3.14-7 7-7s7 3.14 7 7c0 4.17-4.42 9.92-6.24 12.11-.4.48-1.13.48-1.53 0C9.42 19.185 5 13.435 5 9.265zm6.18 4.29l5.13-5.13c.39-.39.39-1.02.01-1.42-.39-.39-1.03-.39-1.42 0l-4.42 4.42-1.36-1.36c-.39-.39-1.03-.39-1.42 0-.39.39-.39 1.03 0 1.42l2.07 2.07c.39.39 1.02.39 1.41 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgWhereToVoteRounded
