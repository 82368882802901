import { connect } from 'react-redux'

import AddPropertyToBuilding from 'app/sm/onlist_details/AddPropertyToBuilding/add_property_building_container'

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPropertyToBuilding)
