import React from 'react'

import SignaturePad from 'snugco_signature_pad'

class SignatureSign extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const canvasElements = document.querySelectorAll('canvas')

    this.signaturePad1 = new SignaturePad(canvasElements[0])
    this.signaturePad2 = new SignaturePad(canvasElements[1])
  }
  onFinish() {
    const data = this.signaturePad1.toData()
  }

  clear(padNumber) {
    if (padNumber === 1) {
      this.signaturePad1.clear()
    } else {
      this.signaturePad2.clear()
    }
  }

  render() {
    return (
      <div>
        <div className="section-title">
          <div className="left column">
            <h3>Sign the Lease</h3>
          </div>
        </div>
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <p>After you sign you're application will be finalized.</p>
            <div className="row">
              <div className="col-xs-8">
                <h4>James O'Neil</h4>
              </div>
              <div className="col-xs-4 text-right">
                <button
                  className="btn btn-small btn-gray wa pl20 pr20"
                  onClick={() => this.clear(1)}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="row mt15">
              <div className="col-xs-12 signature-pad-wrapper">
                <canvas className="ss-canvas" />
              </div>
            </div>
            <div className="row mt40">
              <div className="col-xs-8">
                <h4>Catherine O'Neil</h4>
              </div>
              <div className="col-xs-4 text-right">
                <button
                  className="btn btn-small btn-gray wa pl20 pr20"
                  onClick={() => this.clear(2)}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="row mt15">
              <div className="col-xs-12 signature-pad-wrapper">
                <canvas className="ss-canvas" />
              </div>
            </div>
            <button onClick={() => this.onFinish()} className="btn mt40">
              Finish
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default SignatureSign
