import { connect } from 'react-redux'

import {
  fetchApplicationList,
  fetchPaginationInfo,
  searchRequests,
} from 'app/bond_cover/admin/admin_actions'
import SearchFormContainer from 'app/bond_cover/admin/search/search_form_container'

const mapStateToProbs = ({ admin, session }) => ({
  admin: admin,
  currentUser: session.currentUser,
})

const mapDispatchToProbs = (dispatch) => ({
  searchRequests: (input, searchResultNextPageFirstItemId) =>
    searchRequests(input, searchResultNextPageFirstItemId),
  fetchApplicationList: (page) => fetchApplicationList(page),
})

export default connect(mapStateToProbs, mapDispatchToProbs)(SearchFormContainer)
