import React from 'react'

import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import { AgencyInviteAccept } from 'app/bond_cover/agency/agency_invite_accept'
import { getErrorText } from 'app/bond_cover/agency/agency_utils'
import { ErrorCurrentUserIsNotInvitee } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { ErrorMessage } from 'app/shared_components/helpers'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'
import { findTeamByAgencyId, urlTo } from 'app/sm/helpers'
import { accountTypes } from 'app/utils/accounts/helpers'
import * as textHelpers from 'app/utils/text/helpers'

const LINK_TO_SNUG_MANAGER = 'https://snug.com/manager'

const checkIfRRFromUTM = () => {
  const utmMediumParameter = getParameter('utm_medium')
  // TODO: move `leads/rental-references` to some const value
  return utmMediumParameter === 'leads/rental-references'
}

class AgencyInviteAcceptContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: false,
      error: '',
      inviteId: '',
      agencyId: '',
      teamSlug: '',
      accepted: false,
      validationError: '',
      errorCode: 0,
      agencyName: '',
    }
  }

  componentDidMount() {
    const isFromRR = checkIfRRFromUTM()
    const hasRRInURL = textHelpers.hasRRUrlStrInPathname()
    if (isFromRR & !hasRRInURL) {
      const queryParams = window.location.search
      history.push(
        `/agency/accept/${textHelpers.RENTAL_REFERENCE_URL_STR}${queryParams}`,
      )
    }
    const inviteId = getParameter('code')
    const agencyId = getParameter('secret')
    this.setState({ inviteId, agencyId })
    const { fetchAgencyInfo } = this.props
    this.props
      .validateInvite(inviteId)
      .then(({ ok, statusCode, responseText }) => {
        if (!ok) {
          responseText.then((errorCode) => {
            const errorMsg = translateErrorCodeToMessage(errorCode)
              ? translateErrorCodeToMessage(errorCode)
              : `There is an error: ${errorCode}`
            this.setState({
              validationError: errorMsg,
              errorCode: parseInt(errorCode),
            })
          })
        } else if (agencyId !== '') {
          fetchAgencyInfo(agencyId).then(({ agency }) => {
            const { name } = agency || {}
            this.setState({
              agencyName: name,
            })
          })
        }
      })
  }

  handleDecline = () => {
    if (window.confirm('Are you sure?')) {
      window.location.href = LINK_TO_SNUG_MANAGER
    }
  }

  handleOnClick = () => {
    const { acceptInvite } = this.props
    this.setState({ disabled: true })
    acceptInvite(this.state.inviteId).then(
      ({ ok, statusCode, responseText }) => {
        if (ok) {
          this.props.fetchTeams().then(() => {
            const currentTeam = findTeamByAgencyId(
              this.props.teams,
              this.state.agencyId,
            )
            const teamSlug = currentTeam ? currentTeam.slug : ''
            this.setState({ accepted: true, teamSlug })
          })
        } else {
          responseText.then((text) => {
            const errorCode = parseInt(text)
            const error = getErrorText(errorCode)
            this.setState({ error })
          })
        }
      },
    )
  }

  render() {
    const { accepted, validationError, errorCode, agencyName } = this.state
    const { currentUser, logout } = this.props
    const { firstName = '', email = '', roles = [] } = currentUser || {}
    const isAdmin = roles.length > 0 && roles.includes('admin')
    const inviteeEmail = getParameter('inviteemail')
    // TODO: move `leads/rental-references` to some const value
    const redirectToRR = checkIfRRFromUTM()
    let errorMsg = validationError
    if (errorCode === ErrorCurrentUserIsNotInvitee) {
      errorMsg = (
        <p>
          {`${errorMsg}. The invite is for ${inviteeEmail} and you are logged in as ${email}. `}
          <span className="linkStyle-blue linkStyle" onClick={logout}>
            Log out.
          </span>
        </p>
      )
    }

    return validationError ? (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <ErrorMessage error={errorMsg} />
        </div>
      </div>
    ) : accepted ? (
      redirectToRR ? (
        <Redirect
          push
          to={urlTo('rentalReferencesForTeamFirstTime', {
            teamSlug: this.state.teamSlug,
          })}
        />
      ) : (
        <Redirect
          push
          to={urlTo('teamOverview', { teamSlug: this.state.teamSlug })}
        />
      )
    ) : (
      <AgencyInviteAccept
        disabled={this.state.disabled}
        error={this.state.error}
        handleOnClick={this.handleOnClick}
        isAdmin={isAdmin}
        userName={firstName}
        agencyName={agencyName}
        handleDecline={this.handleDecline}
      />
    )
  }
}

AgencyInviteAcceptContainer.propTypes = {
  /* eslint react/forbid-prop-types: "warn" */
  currentUser: PropTypes.object.isRequired,
  acceptInvite: PropTypes.func.isRequired,
}

export default AgencyInviteAcceptContainer
