import React from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import * as Display from 'app/components/display/display'

const DocPointsLabel = styled.span`
  position: absolute;
  margin: 0px;
  right: 0px;
  bottom: 30px;
  width: max-content;
`

const DetailsBoxCard = ({
  title = '',
  titleClass = '',
  details = [],
  componentClass = '',
  icon = '',
  disabled = false,
  removable = true,
  onRemove,
  editable = true,
  editUrl = '',
  children,
  docPoints,
  isEmploymentDetail = false,
  pillType = '',
  statusText = '',
  isEditLink = false,
  isEditText = '',
  isEditLinkText = '',
  openEditModal,
  footer,
}) => {
  return (
    <div className={`bc-hub-item-link card-block pt0 ${componentClass}`}>
      <div className="card-wrapper">
        <div className="card-details pull-right card-info">
          <content className="fw400">
            <p>
              <i className={`pet-abs mt5 ${icon}`} />
              <span className={`fw600 ${titleClass}`}>{title}</span>
            </p>
            {details.length > 0 &&
              details.map((detail, index) => {
                return (
                  <div key={index}>
                    {detail && (
                      <p className="fw400">
                        {detail}
                        <br />
                      </p>
                    )}
                  </div>
                )
              })}
            {details.length > 0 && isEmploymentDetail && (
              <div className="display-flex">
                <p className="mr5 fw400">Status: </p>
                <Display.StatusPill
                  pillType={pillType}
                  text={statusText}
                  pillTextClass="grey-text"
                />
              </div>
            )}
          </content>
          {isEditLink && (
            <div className="edit-link-container ml0 mt15">
              {isEditText}?
              <span
                className="textButton linkStyle-blue wa ha btnmr mt10 mb10 mr10 ml10"
                onClick={openEditModal}
              >
                {isEditLinkText}
              </span>
            </div>
          )}
        </div>
        {!disabled && (
          <div className="table-cards w-120">
            <div className="cell-t relative">
              {removable && (
                <button className="textButton pet-btn" onClick={onRemove}>
                  Remove
                </button>
              )}
              <div>
                {docPoints && (
                  <DocPointsLabel>{docPoints} Points</DocPointsLabel>
                )}
                {editable && (
                  <Link className="textButton profile-edit pb5" to={editUrl}>
                    Edit details
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
        {children}
      </div>

      {footer && footer}
    </div>
  )
}

export default DetailsBoxCard
