import React from 'react'

const StandardScreen = ({ children }) => {
  return (
    <div className="two-col-box sm64 mobile-p4">
      <div className="col-first xs-second">{children}</div>
      <div className="col-second xs-first"></div>
    </div>
  )
}

export default StandardScreen
