import React, { useEffect, useState } from 'react'

import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import VaultRELogo from 'app/assets/pmsLogos/vaultre.png'
import { Alert, Box } from 'app/components/design-system-components'
import { InputField } from 'app/components/design-system-components/inputs'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { PageSection } from 'app/components/design-system-components/page/Section'
import { Text } from 'app/components/design-system-components/typography'
import { pmsBrands } from 'app/constants/pms.constants'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  activationModalText,
  minimumTokenLength,
  minimumTokenLengthErrMsg,
} from 'app/pages/VaultREActivity/constants'
import PMSActivity, {
  useActivatePMS,
  useDeactivatePMS,
  usePMSStatus,
} from 'app/PMSActivity'
import * as snugNotifier from 'app/services/snugNotifier'
import { loadingStatesIds } from 'app/utils/loading-states'

const vaultREId = pmsBrands.vaultRE.id

const formFields = {
  token: 'third_party_token',
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.token: {
      return {
        required: !val && 'This field is required',
        min:
          !!val && val.length < minimumTokenLength && minimumTokenLengthErrMsg,
      }
    }
  }
}

const formValidation = formValidationBuilder(fieldValidation)

const noop = () => {}

const VaultREActivity = ({
  currentTeam,
  isAdmin,
  integrationsBundleIsActive,
}) => {
  const { loadPMSStatus, pmsStatusInfo, pmsStatusLoadingStates } = usePMSStatus(
    currentTeam,
    vaultREId,
    integrationsBundleIsActive,
  )

  const {
    activatePMS,
    activatePMSLoadingStates,
    activatePMSLoadingStatesHelpers,
  } = useActivatePMS(currentTeam, vaultREId)

  const { deactivatePMS, deactivatePMSLoadingStates } = useDeactivatePMS(
    currentTeam,
    vaultREId,
  )

  const [activationModal, setActivationModal] = useState({ isShown: false })

  useEffect(() => {
    loadPMSStatus()
  }, [])

  const openActivationModal = () => {
    activatePMSLoadingStatesHelpers.reset()
    setActivationModal({ isShown: true })
  }

  const closeActivateModal = () => {
    activatePMSLoadingStatesHelpers.reset()
    setActivationModal({ isShown: false })
  }

  const onActivate = (values) => {
    return activatePMS(values)
      .then(() => {
        loadPMSStatus()
        closeActivateModal()
      })
      .catch(noop)
  }

  const renderActivationModalFooter = (
    onClickActivate,
    onCancel,
    isSubmitting,
  ) => {
    return (
      <GenericModalFooter
        primaryBtnConfig={{
          title: 'Activate',
          onClick: onClickActivate,
          props: {
            loading: !!isSubmitting,
          },
        }}
        secondaryBtnConfig={{
          title: 'Cancel',
          onClick: onCancel,
        }}
      />
    )
  }

  const renderActivationModal = () => {
    if (!activationModal.isShown) return null

    const modalBody = (
      <Box>
        <Form>
          <Text>{activationModalText}</Text>
          <PageSection>
            {activatePMSLoadingStates.state === loadingStatesIds.ERROR && (
              <Alert variant="warningWithBg" mb={theme.space[4] + 'px'}>
                {activatePMSLoadingStates.error.message}
              </Alert>
            )}

            <FieldWrapper
              id={formFields.token}
              name={formFields.token}
              label="VaultRE token"
              required
            >
              <InputField id={formFields.token} name={formFields.token} />
            </FieldWrapper>
          </PageSection>
        </Form>
      </Box>
    )

    return (
      <Formik
        initialValues={{ [formFields.token]: '' }}
        onSubmit={onActivate}
        validate={formValidation}
        validateOnBlur
      >
        {({ isSubmitting, isValid, submitForm }) => {
          return (
            <GenericModal
              onDismiss={() => closeActivateModal()}
              title="Activate VaultRE"
              modalFooter={renderActivationModalFooter(
                submitForm,
                () => closeActivateModal(),
                isSubmitting,
                !isValid,
              )}
            >
              {modalBody}
            </GenericModal>
          )
        }}
      </Formik>
    )
  }

  const activationModalElem = renderActivationModal()

  const statusProps = {
    statusInfo: pmsStatusInfo,
    statusLoadingStates: pmsStatusLoadingStates,
    reloadStatusInfo: loadPMSStatus,
  }

  const activationProps = {
    activationFn: openActivationModal,
    activateLoadingStates: activatePMSLoadingStates,
  }

  const deactivateProps = {
    deactivationFn: () =>
      deactivatePMS()
        .then(() => loadPMSStatus())
        .catch(({ message }) => snugNotifier.error(message)),
    deactivateLoadingStates: deactivatePMSLoadingStates,
  }

  return (
    <>
      <PMSActivity
        pmsBrand={pmsBrands.vaultRE.id}
        logo={VaultRELogo}
        statusProps={statusProps}
        activateProps={activationProps}
        deactivateProps={deactivateProps}
        isAdmin={isAdmin}
        integrationsBundleIsActive={integrationsBundleIsActive}
      />

      {activationModalElem}
    </>
  )
}

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
})

const VaultREActivityPage = withRouter(
  connect(mapStateToProps)(VaultREActivity),
)

export default VaultREActivityPage
