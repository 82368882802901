import { connect } from 'react-redux'

import { createNewViewingRun } from 'app/sm/viewings_new_run/action'
import { notifyLateViewing } from 'app/sm/viewings_new_run/action'
import Preview from 'app/sm/viewings_new_run/preview/container'

const mapStateToProps = ({ ViewingRun, session }) => ({
  viewingRun: ViewingRun.viewingRun,
  teams: session.teams || [],
})

const mapDispatchToProps = (dispatch) => ({
  createNewViewingRun: (viewingRunReqeust) =>
    dispatch(createNewViewingRun(viewingRunReqeust)),
  notifyLateViewing: (data, viewingGUID) =>
    dispatch(notifyLateViewing(data, viewingGUID)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Preview)
