import styled from 'styled-components'
import { border, space } from 'styled-system'

import Box from 'app/components/design-system-components/Box'
import Flex from 'app/components/design-system-components/Flex'
import * as getters from 'app/components/design-system-components/theme/getters'

const HorizontalLine = styled(Box)`
  width: 100%;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.gray100};
  ${border}
  ${space}
`

export default HorizontalLine

const DividerWithCenterElemContainer = styled(Flex)`
  align-items: center;

  .divider-portion {
    display: flex;
    flex: 1 1 100%;
    border: 1px solid ${getters.color('gray100')};
  }

  .centeredElemText {
    text-transform: uppercase;
    color: ${getters.color('gray500')};
    font-weight: ${getters.fontWeight(6)};
    margin: 0 ${getters.spacing(6)};
  }
`

export const DividerWithCenterElem = ({
  elem,
  elemClass = 'centeredElemText',
  ...props
}) => {
  return (
    <DividerWithCenterElemContainer {...props}>
      <div className="divider-portion" />
      <div className={elemClass}>{elem}</div>
      <div className="divider-portion" />
    </DividerWithCenterElemContainer>
  )
}
