import React from 'react'

import { Link } from 'react-router-dom'

import headerImg01 from 'app/assets/icons/header-img-01.png'
import sofaConfetti from 'app/assets/icons/sofa-confetti.png'
import WithdrawButton from 'app/bond_cover/renters/sharedComponent/withdraw_button'
import GreenStateTimings from 'app/shared_components/green_state_timings'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'
import TenantStatus from 'app/shared_components/tenant_status'
import UnsavedMessage from 'app/shared_components/unsaved_message'

class RenterSubmission extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      unsavedChanges: false,
    }
  }

  UNSAFE_componentWillMount() {
    let { fetchRequestSummary, getSecondaryTenantSummary } = this.props
    let role = getParameter('role')
    let requestID = getParameter('requestid')
    if (role === 'secondary') {
      getSecondaryTenantSummary(requestID)
    } else {
      fetchRequestSummary(requestID)
    }
  }

  onWithdrawClicked = () => {
    this.setState({ unsavedChanges: true }, () => {
      history.push('/home/overview')
    })
  }

  onWithdrawClicked = () => {
    let requestID = getParameter('requestid')
    this.setState({ unsavedChanges: true }, () => {
      history.push(`/bc/renterwithdraw?requestid=${requestID}`)
    })
  }

  getPrimaryEmail() {
    let { tenants } = this.props
    let primaryEmail = ''
    tenants &&
      tenants.map((tenant) => {
        if (tenant.isPrimary) {
          primaryEmail = tenant.email
        }
      })
    return primaryEmail
  }

  render() {
    let { unsavedChanges } = this.state
    let { currentUser, currentLease, bondCoverRequest, tenants } = this.props
    let managerApproved = false
    if (
      bondCoverRequest &&
      bondCoverRequest.managerAction === 3 &&
      bondCoverRequest.pauseState === 0
    ) {
      managerApproved = true
    }
    let issueBondCover = false
    if (bondCoverRequest && bondCoverRequest.isCompleted) {
      issueBondCover = true
    }
    let submitted = false
    if (bondCoverRequest && bondCoverRequest.submissionStatus !== 0) {
      submitted = true
    }
    if (false) {
      return (
        <div className="two-col-box-flex-row sm64">
          <div className="col-first">
            <Link to="/home/overview">
              <button type="button"> Return to Dashboard </button>
            </Link>
          </div>
        </div>
      )
    } else {
      return (
        <div className="two-col-box-flex-row sm64">
          <div className="col-first">
            <div className="ilustration-box">
              <img src={headerImg01} />
            </div>

            <h3>
              Thanks, {currentUser.firstName} we've received your application.
            </h3>

            <div className="bc-app-id">
              <h4>
                Application ID:{' '}
                {bondCoverRequest &&
                  bondCoverRequest.appCode &&
                  bondCoverRequest.appCode}
              </h4>
            </div>

            <GreenStateTimings />

            <p>
              Snug will review everything. Once we've reached out to your
              property manager, they have signed, and your bond refund is
              processed, we'll issue your BondCover Certificate and refund your
              bond.
            </p>

            <ul className="bc-waiting-status mt30 mb30">
              <li className="completed">
                <div className="icon-box">
                  <div className="icon"></div>
                </div>
                <div className="text-box">
                  <h4>Apply for BondCover</h4>
                  {currentLease &&
                    currentLease.isMultiTenantLease &&
                    tenants.map((i) => {
                      return (
                        <TenantStatus
                          key={i.guidID}
                          firstName={i.firstName}
                          lastName={i.lastName}
                          approved={i.hasApproved}
                          isPrimary={i.isPrimary}
                          submitted={submitted}
                        />
                      )
                    })}
                </div>
              </li>
              <li className={managerApproved ? 'completed' : 'current'}>
                <div className="icon-box">
                  <div className="icon"></div>
                </div>
                <div className="text-box">
                  <h4>Get property manager approval</h4>
                  <small></small>
                </div>
              </li>
              <li
                className={
                  issueBondCover
                    ? 'completed'
                    : managerApproved
                    ? 'current'
                    : ''
                }
              >
                <div className="icon-box">
                  <div className="icon"></div>
                </div>
                <div className="text-box">
                  <h4>Issue BondCover &amp; refund</h4>
                  <small></small>
                </div>
              </li>
            </ul>

            <p className="mb30">
              We'll send updates about the status of your application to:{' '}
              <b>{this.getPrimaryEmail()} </b>
            </p>

            <ul className="snug-tips">
              <li>
                Once approval is received and we've submitted the refund, it may
                take 1-3 weeks for the relevant bond authority to process the
                refund.
              </li>
            </ul>

            <div>
              {!!bondCoverRequest && bondCoverRequest.idCheckStatus === 0 ? (
                <div className="float-left">
                  <WithdrawButton onWithdrawClicked={this.onWithdrawClicked} />
                </div>
              ) : (
                ''
              )}
              <div className="col-sm-5 pb5 tablet-only float-right p0 mt20">
                <Link className="btn btn-success" to="/home/overview">
                  Continue
                </Link>
              </div>
            </div>

            {/* <h3 className="mb15">Live rent free for a month!</h3>

          <p>Snug is helping to make housing more affordable by giving away 12 prizes to live rent free for a month.</p>

            <div className="img-full mt50 mb30">
              <img src={sofaConfetti} />
            </div>

            {/* <h3 className="mb15">Live rent free for a month!</h3>

            <p>Snug is helping to make housing more affordable by giving away 12 prizes to live rent free for a month.</p>

            <Link className="btn mt15 mb15" to={"/bc/liverentfree"}>Enter Now</Link>

            <small className="gray-light-color">Prizes to be drawn on 12/12/2017 09:00am AEST. 12 prizes worth $4000 each to be won. Entries close 12/12/2017.</small> */}
          </div>
          <UnsavedMessage
            unsavedChanges={unsavedChanges}
            message={
              'Are you sure? You will need to submit a new application if you wish to continue with this application'
            }
          />
        </div>
      )
    }
  }
}

export default RenterSubmission
