import React from 'react'

import 'app/sm/progress_application/send_details/style.scss'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import * as Form from 'app/shared_components/form_component'
import CheckboxOptionContainer from 'app/sm/progress_application/components/checkbox_option_container/component'
import CheckboxOrEmailComponent from 'app/sm/progress_application/components/checkboxOrEmailComponent/component'
import FlexContainer from 'app/sm/progress_application/components/flex-container/component'
import HeaderLg from 'app/sm/progress_application/components/header_lg/component'
import SectionContainer from 'app/sm/progress_application/components/section_container/component'

const SendDetails = ({
  holdingDepositPaymentLink = false,
  sendBondInstruction = false,
  sendHoldingDepositInstruction = false,
  onCheckBoxClicked,
  referrals = [],
  onUtilityConnectionClicked,
  showBondInstructionLink = false,
  showHoldingDepositInstructionLink = false,
  bondInstruction = '',
  holdingDepositInstruction = '',
  enableEdit = true,
}) => {
  return (
    <SectionContainer>
      <FlexContainer flexClassName="width100">
        <HeaderLg title={enableEdit ? 'Send details' : 'Details sent'} />
      </FlexContainer>
      <p>Issue an offer email with the following details</p>
      <div className="checkbox-container">
        <div className="checkbox-container-header">
          {enableEdit ? 'Email next steps:' : 'Next steps:'}
        </div>
        <CheckboxOrEmailComponent
          label="Bond instructions"
          checked={sendBondInstruction}
          onChange={onCheckBoxClicked('sendBondInstruction')}
          showLink={showBondInstructionLink && enableEdit}
          mailTo="help@snug.com"
          subject="Add holding deposit/bond instructions to my team"
          linkText="Add bond instructions"
          normalText="to your account"
          instructionText={bondInstruction}
          enableEdit={enableEdit}
        />
        <CheckboxOrEmailComponent
          label="Pre-payment / holding deposit instructions"
          checked={sendHoldingDepositInstruction}
          onChange={onCheckBoxClicked('sendHoldingDepositInstruction')}
          showLink={showHoldingDepositInstructionLink && enableEdit}
          mailTo="help@snug.com"
          subject="Add holding deposit/bond instructions to my team"
          linkText="Add pre-payment / holding deposit instructions"
          normalText="to your account"
          instructionText={holdingDepositInstruction}
          enableEdit={enableEdit}
        />
        <CheckboxOptionContainer>
          <Form.Checkbox
            label="Pre-payment / holding deposit payment link"
            checked={holdingDepositPaymentLink}
            onChange={onCheckBoxClicked('holdingDepositPaymentLink')}
            enableEdit={enableEdit}
          />
        </CheckboxOptionContainer>
      </div>
      <div className="checkbox-container">
        <div className="checkbox-container-header">
          {enableEdit ? 'Send referrals:' : 'Referrals:'}
        </div>
        {referrals.length > 0 ? (
          referrals.map((referral, index) => {
            const { snugProvided = false, name, selected } = referral
            const labelText = (
              <span>
                <span className="fw600">{name}</span> utility connection
              </span>
            )
            return (
              <CheckboxOptionContainer key={index}>
                <Form.Checkbox
                  label={labelText}
                  checked={selected}
                  onChange={onUtilityConnectionClicked(name)}
                  enableEdit={enableEdit && !snugProvided}
                />
                {snugProvided && (
                  <i
                    className="icon-help-outline mt3"
                    data-tooltip-content="Please enter number of bathrooms in property"
                  />
                )}
                <ReactTooltip
                  placement="top"
                  overlay="You are currently on the Match Free plan. Visit snug.com/help to change your plan"
                >
                  <span>
                    You are currently on the Match Free plan. Visit
                    snug.com/help to change your plan
                  </span>
                </ReactTooltip>
              </CheckboxOptionContainer>
            )
          })
        ) : (
          <div>Opted out of receiving utility connection referral</div>
        )}
      </div>
    </SectionContainer>
  )
}

export default SendDetails
