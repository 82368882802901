import { connect } from 'react-redux'

import {
  declineBondCoverRequest,
  fetchAgencyManagerSignatureSummary,
  fetchManagerSignatureSummary,
  signByAgencyManager,
  signByManager,
} from 'app/bond_cover/bond_cover_actions.js'
import ManagerSign from 'app/bond_cover/managers/signature/bc_manager_sign'

const mapStateToProps = ({ bondCover, Shared }) => ({
  currentLease: bondCover.currentLease,
  quote: bondCover.quote,
  renter: bondCover.renter,
  signature: bondCover.signature,
  error: bondCover.error,
  backUrl: Shared.backUrl,
})

const mapDispatchToProps = (dispatch) => ({
  signByManager: (requestID, signature, forwardUrl) =>
    signByManager(requestID, signature, forwardUrl),
  signByAgencyManager: (requestID, signature, forwardUrl) =>
    signByAgencyManager(requestID, signature, forwardUrl),
  fetchManagerSignatureSummary: (requestID) =>
    fetchManagerSignatureSummary(requestID),
  decline: (requestID, secret) =>
    declineBondCoverRequest(null, requestID, secret),
  fetchAgencyManagerSignatureSummary: (requestID) =>
    fetchAgencyManagerSignatureSummary(requestID),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManagerSign)
