import { LOCATION_CHANGE } from 'connected-react-router'

import {
  CLEAR_ERROR,
  PING,
  RECEIVE_ATTRIBUTES,
  RECEIVE_ERROR,
  RECEIVE_PROPERTIES,
  RECEIVE_PROPERTY,
  RECEIVE_RESPONSE_TEXT,
  RESET_PROPERTY,
  TOGGLE_LOADING,
  UPDATE_PROPERTY_CLICKED,
  UPDATE_PROPERTY_COMPLETE,
} from 'app/sm/ppp/property_actions'

const _defaultState = {
  property: {},
  properties: [],
  attributes: {},
  error: '',
  spinner: false,
  receiveResponseText: '',
  updatePropertyClicked: false,
}

const PropertiesReducer = (
  state = _defaultState,
  {
    type,
    attributes,
    properties,
    property,
    error,
    responseText,
    updatePropertyClicked,
  },
) => {
  Object.freeze(state)
  let newState = Object.assign({}, state)
  switch (type) {
    case LOCATION_CHANGE:
      return Object.assign(newState, { error: '' })
    case PING:
      return Object.assign(newState, { error: '' })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case RESET_PROPERTY:
      return _defaultState
    case RECEIVE_PROPERTY:
      return Object.assign(newState, { property })
    case RECEIVE_PROPERTIES:
      return Object.assign(newState, { properties })
    case RECEIVE_ATTRIBUTES:
      return Object.assign(newState, { attributes })
    case TOGGLE_LOADING:
      return Object.assign(newState, { spinner: !newState.spinner })
    case RECEIVE_RESPONSE_TEXT:
      return Object.assign(newState, { responseText })
    case UPDATE_PROPERTY_CLICKED:
      return Object.assign(newState, { updatePropertyClicked })
    case UPDATE_PROPERTY_COMPLETE:
      return Object.assign(newState, { updatePropertyClicked })
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    default:
      return state
  }
}

export default PropertiesReducer
