import { productFruitWorkspaceCode } from 'config/env'

import {
  RentalHistoryStatusNew,
  RentalReferencesSidebarRetentionMaxCount,
} from 'app/pages/teams/rental-references/helpers'
import { getRentalReferencesCount } from 'app/services/http/rentalReferences'
import * as snugNotifier from 'app/services/snugNotifier'
import * as API from 'app/session/session_access'
import { getAgencyUser } from 'app/session/session_access'
import { isMobileOrTablet } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import { db, dbKeys, urlIds, urlTo } from 'app/sm/helpers'
import { fetchApplicationsCount } from 'app/sm/properties/properties_actions'
import { getCurrentStepForQuickStart } from 'app/sm/team_viewing_preference/setup/api'
import { SessionStorageUtils } from 'app/storage_utils'
import store from 'app/store'
import { AGENCY_USER_ADMIN } from 'app/utils/roles'
import * as textHelpers from 'app/utils/text/helpers'

// Action Types

export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER'
export const RECEIVE_CURRENT_AGENCY = 'RECEIVE_CURRENT_AGENCY'
export const RECEIVE_CURRENT_USER_PROFILE = 'RECEIVE_CURRENT_USER_PROFILE'
export const RECEIVE_SESSION_ERROR = 'RECEIVE_ERROR'
export const RECEIVE_AUTH_ERROR = 'RECEIVE_AUTH_ERROR'
export const RECEIVE_USER_ERROR = 'RECEIVE_USER_ERROR'
export const RECEIVE_SUCCESS_MESSAGE = 'RECEIVE_SUCCESS_MESSAGE'
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT'
export const TOGGLE_ROLE_PROMPT = 'TOGGLE_ROLE_PROMPT'
export const RECEIVE_TEAMS = 'RECEIVE_TEAMS'
export const CHANGE_CURRENT_TEAM = 'CHANGE_CURRENT_TEAM'
export const UPDATE_RENTAL_REFERENCES_COUNT = 'UPDATE_RENTAL_REFERENCES_COUNT'
export const UPDATE_SETUP_WIZARD_INVALID_STATUS =
  'UPDATE_SETUP_WIZARD_INVALID_STATUS'

const toggleRolePrompt = () => ({
  type: TOGGLE_ROLE_PROMPT,
})

function continueToApply() {
  if (db.has(dbKeys.applyLinkUnauthenticated)) {
    const payload = db.get(dbKeys.applyLinkUnauthenticated)
    history.push(payload)
    SessionStorageUtils.removeItem(dbKeys.applyLinkUnauthenticated)
    return true
  }
  return false
}

function continueToPropertyDetails() {
  if (db.has(dbKeys.property_details)) {
    const payload = db.get(dbKeys.property_details)
    const propertyId = (payload || {}).propertyId

    if (propertyId !== undefined) {
      history.push(urlTo('propertyDetails', { propertyId }))
      SessionStorageUtils.removeItem(dbKeys.property_details)
      return true
    }
  }

  return false
}

// Action Creators

let receiveCurrentUser = (currentUser) => ({
  type: RECEIVE_CURRENT_USER,
  currentUser: {
    ...currentUser,
    isVerified: isVerifiedUser(currentUser) && isTwoFAConfirmed(currentUser),
  },
})

let receiveCurrentUserProfile = (currentUser) => ({
  type: RECEIVE_CURRENT_USER_PROFILE,
  currentUser,
})

let receiveCurrentAgency = (currentAgency = null) => ({
  type: RECEIVE_CURRENT_AGENCY,
  currentAgency,
})

let receiveLogout = () => ({
  type: RECEIVE_LOGOUT,
})

let receiveAuthError = (error) => ({
  type: RECEIVE_AUTH_ERROR,
  error,
  currentUser: {
    isUserFetching: false,
    isUserProfileFetching: false,
  },
})

let receiveSessionError = (error) => ({
  type: RECEIVE_SESSION_ERROR,
  error,
})

let receiveSuccess = (success) => ({
  type: RECEIVE_SUCCESS_MESSAGE,
  success,
})

let receiveUserError = () => ({
  type: RECEIVE_USER_ERROR,
  currentUser: {
    isUserFetching: false,
    isUserProfileFetching: false,
  },
})

const receiveTeams = (teams) => ({
  type: RECEIVE_TEAMS,
  teams,
})

const changeCurrentTeam = (currentTeam) => ({
  type: CHANGE_CURRENT_TEAM,
  currentTeam,
})

const updateRentalReferencesCount = (count) => ({
  type: UPDATE_RENTAL_REFERENCES_COUNT,
  count,
})

const updateSetupWizardStep = (isInvalid) => ({
  type: UPDATE_SETUP_WIZARD_INVALID_STATUS,
  isInvalid,
})

// Methods

export const register = (user, isFromRR = false) => {
  store.dispatch(receiveSessionError(''))
  if (isFromRR) {
    API.register(
      user,
      registerSuccess(`/verify/${textHelpers.RENTAL_REFERENCE_URL_STR}`),
      authError,
    )
  } else {
    API.register(user, registerSuccess('/verify'), authError)
  }
}

export const confirm = (user) => {
  store.dispatch(receiveSessionError(''))
  API.confirm(user, registerSuccess('/verify'), authError)
}

export const verify = (mobile, forwardUrl) => {
  store.dispatch(receiveSessionError(''))
  API.verify(mobile, verifySuccess(forwardUrl), authError)
}

export const resendOTP = (data) => {
  API.resendOTP(data, resendSuccess, authError)
}

export const logout = () => {
  store.dispatch(receiveSessionError(''))
  API.logout(logoutSuccess('/join', authError))
}

export const clearError = () => {
  store.dispatch(receiveSessionError(''))
}

export const fetchCurrentUser = () => {
  API.getUser(
    ({ user }) => {
      store.dispatch(receiveCurrentUser(user))
      fetchCurrentUserProfile()
    },
    (error) => store.dispatch(receiveUserError()),
  )
}

export const fetchCurrentUserProfile = () => (dispatch) => {
  return API.fetchUserProfile().then(({ ok, profile, responseText }) => {
    if (ok) {
      return dispatch(receiveCurrentUserProfile(profile))
    } else {
      return responseText.then((error) => {
        dispatch(receiveAuthError(error))
        // TODO: No user notice presented for this issue. Hiding until 900100 root cause is identified
        // https://app.shortcut.com/snugco/story/92354/sentry-still-showing-900100-error
      })
    }
  })
}

const formatTeamOptions = (currentUser, teams = []) => {
  return [
    {
      name: currentUser
        ? `${currentUser.firstName} ${currentUser.lastName}`
        : '',
      slug: undefined,
      guid: undefined,
      type: '',
    },
  ].concat(teams)
}

export const fetchTeams = () => {
  return (dispatch, getState) => {
    return API.getTeams().then(({ ok, teams, responseText }) => {
      if (ok) {
        const { currentUser } = getState().session
        const myselfAndTeams = formatTeamOptions(currentUser, teams)
        dispatch(receiveTeams(myselfAndTeams))
        return Promise.resolve(teams)
      } else {
        return responseText.then((error) => Promise.reject(error))
      }
    })
  }
}

export const changeTeam = (team) => (dispatch) => {
  const agencyID = team && team.guid
  return (
    agencyID &&
    getAgencyUser(agencyID).then((agencyUser) => {
      fetchNewRentalReferencesCount(agencyID)
        .then(({ count }) => {
          dispatch(checkSetupWizard(team, agencyUser))
          dispatch(updateRentalReferencesCount(count))
        })
        .catch((err) => snugNotifier.error(err.message))
      dispatch(receiveCurrentAgency(agencyUser))
      dispatch(changeCurrentTeam(team))
    })
  )
}

const fetchNewRentalReferencesCount = (teamId) => {
  return getRentalReferencesCount(teamId, {
    statusValue: RentalHistoryStatusNew,
    retention: RentalReferencesSidebarRetentionMaxCount,
  })
}

const checkSetupWizard = (team, agencyUser) => (dispatch) => {
  let userIsAdmin = false
  if (agencyUser.agencyID && agencyUser.agencyID === team.guid) {
    agencyUser.roles.forEach((agency) => {
      if (agency.roleID === AGENCY_USER_ADMIN) {
        userIsAdmin = true
      }
    })
  }
  if (userIsAdmin) {
    fetchSetupWizardStatus(team.guid)
      .then(({ Invalid }) => dispatch(updateSetupWizardStep(Invalid)))
      .catch((err) => snugNotifier.error(err.message))
  }
}

const fetchSetupWizardStatus = (teamGUID) => {
  return getCurrentStepForQuickStart(teamGUID)
}

export const addUserProfilePicture = (formData, success, fail) => {
  return (dispatch, getState) => {
    const { currentUser: { avatar } = {} } = getState().session
    const apiCall = avatar
      ? API.updateUserProfilePicture
      : API.addUserProfilePicture
    apiCall(formData).then(({ ok, user, statusCode, responseText }) => {
      success && success()

      if (ok) {
        dispatch(receiveCurrentUser(user))
      } else {
        responseText.then((t) => {
          fail(t)
        })
      }
    })
  }
}

export const deleteUserProfilePicture = () => (dispatch) => {
  return API.deleteUserProfilePicture().then(({ ok, responseText }) => {
    if (ok) {
      return Promise.resolve()
    } else {
      return responseText.then((error) => Promise.reject(error))
    }
  })
}

//Waiting for endpoint
export const forgotPassword = (data) => {
  store.dispatch(receiveSessionError(''))
  API.forgotPassword(data, resetSuccess(), authError)
}

export const newPassword = (data) => {
  store.dispatch(receiveSessionError(''))
  API.newPassword(data, newPasswordSuccess('/home/overview'), authError)
}

export const changePassword = (data) => {
  store.dispatch(receiveSessionError(''))
  API.changePassword(
    data,
    newPasswordSuccess('/bc/profile'),
    changePasswordError,
  )
}

const isVerifiedUser = (currentUser) => {
  const { isOffshore, emailVerified, mobileVerified } = currentUser || {}
  return (isOffshore && emailVerified) || mobileVerified
}

const isTwoFAConfirmed = (currentUser) => {
  const { twoFARequired = false, twoFACompleted = false } = currentUser || {}

  if (twoFARequired && !twoFACompleted) {
    return false
  }

  return true
  // const { twoFARequired } = currentUser || {}
  // return twoFARequired
}

// Success Callback

const checkForTeamsAndRedirectOrToggle = () => {
  store.dispatch(
    fetchApplicationsCount(() => {
      store.dispatch(fetchTeams()).then((teams) => {
        if (teams && teams.length > 0) {
          store.dispatch(changeTeam(teams[0]))
        }

        if (teams && teams.length > 0 && isMobileOrTablet) {
          history.push(urlTo('teamMobileViewings', { teamSlug: teams[0].slug }))
        } else {
          if (!continueToPropertyDetails()) {
            const forwardUrl = SessionStorageUtils.getItem('forwardUrl')
            if (teams?.length === 0) {
              forwardUrl && history.push(forwardUrl)
            } else {
              forwardUrl && forwardUrl !== '/home/overview'
                ? history.push(forwardUrl)
                : store.dispatch(toggleRolePrompt())
            }
            SessionStorageUtils.removeItem('forwardUrl')
          }
        }
      })
    }),
  )
  store.dispatch(fetchCurrentUserProfile())
}

export let authSuccess =
  (path) =>
  ({ user }) => {
    store.dispatch(
      receiveCurrentUser(Object.assign({}, user, { bindedRolePrompt: true })),
    )

    const isVerified = isVerifiedUser(user)
    const channelEmail = 'email'
    const channelSMS = 'sms'

    if (!isVerified) {
      history.push(urlTo(urlIds.join.verify.mobile))
      return
    } else {
      if (user.twoFARequired && user.twoFAChannel === channelEmail) {
        history.push(urlTo(urlIds.join.verify.twoFA.email))
        return
      }
      if (user.twoFARequired && user.twoFAChannel === channelSMS) {
        history.push(urlTo(urlIds.join.verify.twoFA.sms))
        return
      }
    }
    checkForTeamsAndRedirectOrToggle()
  }

let logoutSuccess = (path) => () => {
  store.dispatch(receiveLogout())
  history.push(path)
}

export let registerSuccess =
  (path) =>
  ({ user }) => {
    store.dispatch(receiveCurrentUser(user))
    history.push(path)
  }

export let verifySuccess =
  (path) =>
  ({ user }) => {
    store.dispatch(
      receiveCurrentUser(Object.assign({}, user, { bindedRolePrompt: true })),
    )
    checkForTeamsAndRedirectOrToggle()
  }

let resetSuccess =
  () =>
  (
    {
      /* responseText */
    },
  ) => {
    let responseText = 'Success! We’ve sent a link to the email provided.'
    store.dispatch(receiveSuccess(responseText))
  }

let newPasswordSuccess =
  (path) =>
  ({ user }) => {
    store.dispatch(receiveCurrentUser(user))
    history.push(path)
  }

//TODO: Should success do anything?
let resendSuccess = (
  {
    /* responseText */
  },
) => {
  let responseText = 'Sent a new verification code'
  store.dispatch(receiveSuccess(responseText))
}

// Error Callback

let authError = ({ responseText }) => {
  responseText = responseText
    ? responseText.replace(/\n/, '')
    : 'Request failed'
  store.dispatch(receiveAuthError(responseText))
}

let changePasswordError = ({ responseText }) => {
  responseText = responseText ? responseText.replace(/\n/, '') : ''
  store.dispatch(receiveSessionError(responseText))
}
