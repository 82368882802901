import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { parseViewing } from 'app/sm/helpers'
import { alterViewing } from 'app/sm/inspections/inspections_access'
import {
  _createViewing,
  _getViewings,
  _updateViewing,
} from 'app/sm/ppp/viewing_access'
import store from 'app/store'

export const PING = 'V_PING'
export const TOGGLE_SPINNER = 'V_TOGGLE_SPINNER'
export const UPDATE_FIELD = 'V_UPDATE_FIELD'
export const RECEIVE_RESPONSE_TEXT = 'V_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_VIEWINGS = 'V_RECEIVE_VIEWINGS'
export const ADD_VIEWINGS = 'V_ADD_VIEWINGS'
export const EDIT_VIEWINGS = 'V_EDIT_VIEWINGS'
export const UPDATED_VIEWINGS = 'V_UPDATED_VIEWINGS'
export const DELETE_VIEWINGS = 'V_DELETE_VIEWINGS'
export const RECEIVE_ERROR = 'V_RECEIVE_ERROR'
export const CLEAR_ERROR = 'V_CLEAR_ERROR'

export const ping = () => ({ type: PING })
export const updateField = (key, val) => ({ type: UPDATE_FIELD, key, val })
export const clearError = () => ({ type: CLEAR_ERROR })

const toggleSpinner = () => ({ type: TOGGLE_SPINNER })
const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})

const receiveViewings = (viewings) => ({ type: RECEIVE_VIEWINGS, viewings })
const createViewing = (viewing) => ({ type: ADD_VIEWINGS, viewing })
const updatedViewing = (viewing) => ({ type: UPDATED_VIEWINGS, viewing })
const patchViewing = (viewing) => ({ type: EDIT_VIEWINGS, viewing })

export function addViewing(
  offerId,
  startdate,
  duration,
  fromScheduler,
  skipDoubleBookingCheck,
  options,
) {
  store.dispatch(clearError())
  return (dispatch) => {
    return _createViewing(
      offerId,
      startdate,
      duration,
      fromScheduler,
      skipDoubleBookingCheck,
      options,
    ).then(({ ok, viewing, responseText }) => {
      if (ok) {
        dispatch(createViewing(parseViewing(viewing)))
        dispatch(ping())
        return Promise.resolve({ viewing })
      } else {
        return responseText.then((error) => {
          dispatch(receiveResponseText(error))
          return Promise.reject(translateErrorCodeToMessage(error))
        })
      }
    })
  }
}

export function cancelViewing(offerId, viewingId) {
  store.dispatch(clearError())
  return (dispatch) => {
    alterViewing(viewingId).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(fetchViewings(offerId))
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function updateViewing(
  offerId,
  viewingId,
  startdate,
  duration,
  skipDoubleBookingCheck,
  options,
) {
  store.dispatch(clearError())
  return (dispatch) => {
    _updateViewing(
      offerId,
      viewingId,
      startdate,
      duration,
      skipDoubleBookingCheck,
      options,
    ).then(({ ok, viewing, responseText }) => {
      if (ok) {
        dispatch(updatedViewing(parseViewing(viewing)))
        dispatch(ping())
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function editViewing(offerId, viewing) {
  return (dispatch) => {
    dispatch(patchViewing(viewing))
  }
}

function parseServerViewings(viewings) {
  return viewings.map((viewing) => parseViewing(viewing))
}

export function fetchViewings(offerId) {
  return (dispatch) => {
    dispatch(toggleSpinner())
    _getViewings(offerId).then(({ ok, responseText, viewings }) => {
      if (ok) {
        dispatch(receiveViewings(parseServerViewings(viewings)))
        dispatch(ping())
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
      dispatch(toggleSpinner())
    })
  }
}
