interface ToolTipQuestionMarkProps {
  toolTipID: string
}

export function ToolTipQuestionMark({
  toolTipID = '',
}: ToolTipQuestionMarkProps) {
  return (
    <div>
      <i
        className="icon-help-outline fs16 gray-light-color ibm"
        data-tooltip-content=""
        data-tooltip-id={toolTipID}
      />
    </div>
  )
}
