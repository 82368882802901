import React from 'react'

import DatePicker from 'app/shared_components/date_picker'

const AgencyModal = ({ applicationKey, updateDate }) => (
  <div className="agency-modal">
    <div className="modal-wrapper">
      <DatePicker
        label="New Expiry Date"
        inputProps={{ placeholder: 'dd/mm/yyyy' }}
        viewMode="years"
        disableOnClickOutside="false"
        value={null}
        onChange={updateDate(applicationKey)}
      />
    </div>
  </div>
)

export default AgencyModal
