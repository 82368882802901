import { defaultPropertyFilters } from 'app/dashboard/team_overview_reducer'

export const SET_PROPERTY_FILTERS = 'SET_PROPERTY_FILTERS'
export const RESET_PROPERTY_FILTERS = 'RESET_PROPERTY_FILTERS'

export const setPropertyFilters = (filters) => {
  return { type: SET_PROPERTY_FILTERS, filters }
}

export const resetPropertyFilters = () => {
  const filters = defaultPropertyFilters
  return { type: RESET_PROPERTY_FILTERS, filters }
}
