import React from 'react'

import PropTypes from 'prop-types'

import Card from 'app/match/applicationReportPDF/components/Card'
import InfoList from 'app/match/applicationReportPDF/components/InfoList'

const propTypes = {
  data: PropTypes.object,
}

const Other = ({ data }) => {
  return (
    <div className="no-break-section">
      <Card
        type={'icon'}
        icon={'icon-geometry'}
        title={'Other'}
        addClass={'bottom-border'}
      />
      <InfoList data={data} addClass={'other-info-list'} />
    </div>
  )
}

Other.propTypes = propTypes

export default Other
