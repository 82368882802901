import { mapRegistrant } from 'app/services/http/viewings/utils'
import { api } from 'app/utils/api/helpers'

export const getViewing = (teamID, viewingID) => {
  return api.get(`/sm/team/${teamID}/viewing/${viewingID}`)
}

export const listViewings = (offerID) => {
  return api.get(`/sm/offers/${offerID}/viewings`)
}

export const registerApplicantsToViewings = (
  teamGUID,
  viewingsIds,
  applicantsIds,
) => {
  const payload = {
    applicants_guids: applicantsIds,
    viewings_guids: viewingsIds,
  }
  return api.post(
    `/sm/team/${teamGUID}/viewingregistration/register-applicants`,
    payload,
  )
}

export const getViewingRegistrants = (teamID, viewingID, searchQ) => {
  return api
    .get(`/sm/team/${teamID}/viewing/${viewingID}/registrants`, {
      params: {
        q: searchQ,
      },
    })
    .then(({ data }) => data.viewingOnsiteRegistrants)
    .then((registrants) => registrants.map(mapRegistrant))
}

export const createRegistrant = (data, viewingId) => {
  return api
    .post(`sm/viewing/${viewingId}/onsiteregistrant`, data)
    .then(mapRegistrant)
}

export const sendApplyInvitationForRegistrant = (viewingId, registrantId) => {
  return api.post(
    `sm/viewing/${viewingId}/viewingregistrant/${registrantId}/invite`,
  )
}

export const registerEnquirerForViewing = (
  teamId,
  viewingId,
  messageId,
  payload = {},
) => {
  return api.post(
    `/sm/agency/${teamId}/viewings/${viewingId}/message/${messageId}/register`,
    payload,
  )
}

export const mergeViewing = (cancelViewingId, mergingViewingId, force) => {
  return api.post(`/sm/viewings/merge`, {
    mergingViewingId,
    cancelViewingId,
    force,
  })
}

export const sendMessage = (teamGUID, viewingGUID, payload) => {
  return api.post(
    `/sm/agency/${teamGUID}/viewings/${viewingGUID}/send-message`,
    payload,
  )
}
