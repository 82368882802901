import React from 'react'

import styled from 'styled-components'

import { Box, Flex } from '.'

const StyledStatus = styled(Box)`
  background-color: #f8f8f9;
  padding: 20px;
  border-radius: 5px;
`

const StyledProgress = styled(Box)`
  width: 100%;
  background-color: #eff6fd;
  font-size: 18px;
  height: 10px;
  border-radius: 100px;
  .progress-bar {
    background-color: ${(props) => props.progressBgColor};
    width: ${(props) => props.progressBarWidth}%;
    border-radius: 100px;
  }
`

function Status({
  totalSteps,
  currentStep,
  leftContentToShow,
  rightContentToShow,
  progressBgColor = '#3774C3',
}) {
  const progressBarWidth = (currentStep / totalSteps) * 100
  let progressBarColor = progressBgColor
  if (totalSteps === currentStep) {
    progressBarColor = '#37C977'
  }
  return (
    <>
      <StyledStatus>
        <Flex justifyContent="space-between">
          <Box>{leftContentToShow}</Box>
          <Box>{rightContentToShow}</Box>
        </Flex>
      </StyledStatus>
      <StyledProgress
        progressBgColor={progressBarColor}
        progressBarWidth={progressBarWidth}
      >
        <div className="progress-bar"></div>
      </StyledProgress>
    </>
  )
}

export default Status
