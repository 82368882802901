import { connect } from 'react-redux'

import PluginsContainer from 'app/sm/plugins/plugins_container'

const mapStateToProps = ({ session }) => ({
  currentUser: session.currentUser,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PluginsContainer)
