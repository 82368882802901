import React, { Component } from 'react'

import qs from 'qs'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { Button, Flex } from 'app/components/design-system-components'
import { mediaQueries } from 'app/components/design-system-components/styles/variables'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { addRentalReputationBackUrl } from 'app/shared_components/actions'
import BlueSafetyMessage from 'app/shared_components/blue_safety_message'
import CreditCards from 'app/shared_components/payment_form/credit_cards'
import NewCreditCard from 'app/shared_components/payment_form/new_credit_card'
import { history } from 'app/shared_components/router'
import { payWithExistingCard } from 'app/sm/background_check/background_check_actions'
import { SNUG_TERMS_URL, urlTo } from 'app/sm/helpers'

const StyledCreditCardSelect = styled(Flex)`
  align-items: center;
  ${mediaQueries.sm} {
    flex-direction: column;
    margin-bottom: ${theme.space[4]}px;
  }
`

class PayContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      payDepositButtonClicked: false,
    }
  }
  UNSAFE_componentWillReceiveProps() {
    this.setState({
      payDepositButtonClicked: false,
    })
  }

  onPayDepositButtonClicked = () => {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    this.setState({ payDepositButtonClicked: true })

    const { applicationId = '' } = queryParams || {}

    const applicationGUID = applicationId?.toString()?.split(',')[0]

    payWithExistingCard(applicationGUID).then((success) => {
      if (success) {
        const { backUrl } = this.props
        backUrl ? history.push(backUrl) : history.goBack()
      }
    })
  }

  isPaymentDisabled() {
    const { acceptTermsAndConditions } = this.props
    return !acceptTermsAndConditions
  }

  pushToEditCards = () => {
    const { addRentalReputationBackUrl } = this.props
    addRentalReputationBackUrl(window.location.pathname)
    const url = `${urlTo('RentalReputationCreditCards')}${
      window.location.search
    }`

    history.push(url)
  }

  renderBackBtn = () => {
    const { backUrl } = this.props

    return (
      <button
        className="btn btn-transparent btn-left xs-w100p wa"
        onClick={() => (backUrl ? history.push(backUrl) : history.goBack())}
      >
        <i className="icon-arrow-left left"></i>Back
      </button>
    )
  }

  render() {
    const {
      match,
      cards,
      selectedCreditCard,
      cardSelect,
      registerPayment,
      acceptTermsAndConditions,
      backUrl,
      payForWhat,
      amount,
      buttonParole,
      showCards,
      autoSave,
      payText,
      hideAmount,
      renderAllCards,
      shouldShowReferralCode,
      entryPoint,
      GSTParole,
      verifyReferralCode,
      apiError,
      error,
      teamSlug,
    } = this.props
    const { payDepositButtonClicked } = this.state
    const payDepositTagClass = payDepositButtonClicked
      ? 'pay-and-run-bgCheck'
      : ''
    return (
      <div className="sm-property advertise pay">
        <div className="row">
          <div className="col-sm-12 mobile-only">{this.renderBackBtn()}</div>
        </div>
        <div className="section-title">
          <div className="left column">
            <h3>{payForWhat}</h3>
          </div>
        </div>
        <div className="two-col-box-flex-row sm64">
          <div className="two-row-box col-first">
            <div className="xs-second">
              {!hideAmount && (
                <div className="row">
                  <div className="col-sm-12 pb10">
                    <div className="table">
                      <div className="cell-m-100">
                        <div>{GSTParole ? GSTParole : 'Payment Amount'}</div>
                        <h2>${Number(amount).toFixed(2)}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showCards && (
                <StyledCreditCardSelect>
                  <div className="col-xs-12 col-sm-9">
                    <CreditCards
                      renderAllCards={renderAllCards}
                      cards={cards}
                      selectedCreditCard={selectedCreditCard}
                      cardSelect={cardSelect}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-3 mb20 text-align-center">
                    <Button
                      variant="outlineSuccess"
                      onClick={this.pushToEditCards}
                    >
                      Update Card
                    </Button>
                  </div>
                </StyledCreditCardSelect>
              )}
              {selectedCreditCard === 'New' ? (
                <NewCreditCard
                  shouldShowReferralCode={shouldShowReferralCode}
                  formData={{}}
                  match={match}
                  registerPayment={registerPayment}
                  autoSave={autoSave}
                  payText={payText}
                  entryPoint={entryPoint}
                  backUrl={backUrl}
                  verifyReferralCode={verifyReferralCode}
                  apiError={apiError}
                  teamSlug={teamSlug}
                  errorMessage={error}
                />
              ) : (
                <div>
                  <div className="row">
                    <div className="col-sm-12 pb30 xs-pb60">
                      <BlueSafetyMessage message="Snug uses bank-level 256-bit encryption to protect your data." />
                      <label className="new-card-condition">
                        <input
                          type="checkbox"
                          id="phd-terms-agreement-sawsd"
                          checked={acceptTermsAndConditions}
                          onChange={() =>
                            this.props.acceptTermsConditionsChange()
                          }
                        />

                        <span>
                          I have read and accept the{' '}
                          <a
                            className="gray-color tdu xs-block"
                            href={SNUG_TERMS_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Terms and Conditions
                          </a>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="row pt25 mobile-form-button">
                    <div className="col-sm-5 pb5 tablet-only">
                      {this.renderBackBtn()}
                    </div>
                    <div className="col-sm-7 pb5">
                      <button
                        onClick={this.onPayDepositButtonClicked}
                        disabled={
                          this.isPaymentDisabled() || payDepositButtonClicked
                        }
                        className={`btn ${payDepositTagClass}`}
                      >
                        <i
                          className="fa fa-lock btn-icon left"
                          aria-hidden="true"
                        />
                        <span>{buttonParole}</span>
                        {payDepositButtonClicked ? (
                          <i className="fa fa-spinner fa-pulse" />
                        ) : (
                          ''
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-sm-12">
                  <div className={error ? 'alert alert-danger' : ''}>
                    <div>{error}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  addRentalReputationBackUrl: (rentalBackUrl) =>
    dispatch(addRentalReputationBackUrl(rentalBackUrl)),
})

export default connect(null, mapDispatchToProps)(PayContainer)
