import { connect } from 'react-redux'

import {
  declineBondCoverRequest,
  fetchBondCoverReview,
} from 'app/bond_cover/bond_cover_actions'
import DeclineForm from 'app/bond_cover/managers/review/decline_form_container'

const mapStateToProps = ({ bondCover, Shared }) => ({
  bondCoverRequest: bondCover.request,
  currentLease: bondCover.currentLease,
  backUrl: Shared.backUrl,
})

const mapDispatchToProps = (dispatch) => ({
  fetchBondCoverReview: (requestID, secret) =>
    fetchBondCoverReview(requestID, secret),
  decline: (data, requestID, secret, managerType, navigateUrl) =>
    declineBondCoverRequest(data, requestID, secret, managerType, navigateUrl),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeclineForm)
