import React from 'react'

import moment from 'moment'
import qs from 'qs'
import { Link } from 'react-router-dom'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import { Alert, Box } from 'app/components/design-system-components'
import {
  Heading,
  Text,
} from 'app/components/design-system-components/typography'
import * as Display from 'app/components/display/display'
import ProgressBarComponent from 'app/components/progress_bar/connection'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import Checkbox from 'app/shared_components/checkbox'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'
import AddBGCheckModal from 'app/sm/background_check/addBGCheckModal'
import AttachBGCheckModal from 'app/sm/background_check/attachBGCheckModal'
import BGCheck from 'app/sm/background_check/bg_check'
import ExampleReportModal from 'app/sm/background_check/exampleReportModal'
import { BackgroundCheckExpiryModal } from 'app/sm/background_check/ExpiryModal'
import BackgroundCheckConfirmation from 'app/sm/background_check/identity_confirmation/connection'
import {
  deleteSelfTenancyCheck,
  getSelfTenancyCheck,
} from 'app/sm/background_check/SelfTenancyCheck/upload_tenancy_check_http'
import Spinner from 'app/sm/common/spinner'
import {
  IDDocumentDocType,
  isFromApplication,
  urlIds,
  urlTo,
} from 'app/sm/helpers'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import { BackgroundCheckProcessedDate } from 'app/sm/renter_applications/renter_application_detailed/components/applicant-details'
import {
  BG_CHECK_SPECIAL_PRICE,
  BG_CHECK_USUAL_PRICE,
} from 'app/utils/text/helpers'

const PendingReminder = ({ status }) => {
  return (
    <div>
      {status === 'pending' && (
        <div className="alert alert-success">
          The background check is processing, please wait a few minutes.
        </div>
      )}
    </div>
  )
}

const ErrorMessageBox = ({ error }) => (
  <div className="row">
    <div className="col-sm-12">
      <Box className="alert alert-danger">{error}</Box>
      {error.includes('Invalid driver licence card number') && (
        <Link
          style={{
            display: 'block',
            margin: `-${theme.space[4]}px 0 ${theme.space[4]}px 0`,
          }}
          to={urlTo('identityDocument')}
        >
          Update Driver Licence card number
        </Link>
      )}
    </div>
  </div>
)

class IdentityOverview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      apiError: '',
      selfTenancyCheck: {},
      isBallotApplication: false,
    }
  }

  componentDidMount() {
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { id: agencyGUID, ballot = false, applicationId } = curQueries || {}
    if (
      agencyGUID &&
      agencyGUID !== '' &&
      applicationId &&
      applicationId !== ''
    ) {
      const applicationGUID = applicationId?.toString()?.split(',')[0]
      this.props.fetchApplication(applicationGUID)
    }
    if (ballot && ballot === 'true') {
      this.setState({
        isBallotApplication: true,
      })
    }
    this.getBGCheckData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.backgroundCheck !== this.props.backgroundCheck) {
      moment(this.props.backgroundCheck.expiryDate).isBefore() &&
        this.openExpiryModal()
    }
  }

  getBGCheckData = () => {
    this.props.fetchIdentityDocs().catch((error) => {
      this.setState({
        apiError: error,
        isAttachBGCheckModalOpen: false,
        isAddBGCheckModalOpen: false,
      })
    })
    this.props.fetchCompleteness()
    this.props.fetchPaymentAmount()
    this.props.retrieveReputationPreference()
    getSelfTenancyCheck().then((data) => {
      this.setState({
        selfTenancyCheck: data,
      })
    })
  }

  onChangeOptOutBackgroundCheck = () => {
    const { reputationPreference, changeReputationPreference } = this.props
    changeReputationPreference({
      ...reputationPreference,
      optOutBackgroundCheck: !reputationPreference.optOutBackgroundCheck,
    }).catch((error) => this.setState({ apiError: error }))
  }

  onCompulsoryLinkClicked = () => {
    const { addRentalReputationBackUrl } = this.props
    addRentalReputationBackUrl(urlTo('BackgroundCheck'))
  }

  openExpiryModal = () => this.setState({ isExpiryModalOpened: true })
  closeExpiryModal = () => this.setState({ isExpiryModalOpened: false })

  goToNextURL = () => {
    const { backUrl } = this.props
    const { isBallotApplication } = this.state
    const propertyId = getParameter('propertyId')
    const applicationId = getParameter('applicationId')
    const isFromApplicationForm =
      isFromApplication(backUrl) || propertyId || applicationId
    let nextURL = isFromApplicationForm
      ? `${urlTo('profileAvatar')}${window.location.search}`
      : urlTo(urlIds.profileAboutUs)
    if (isBallotApplication) {
      nextURL = isFromApplicationForm
        ? `${urlTo('profileAvatar')}${window.location.search}`
        : urlTo(urlIds.profileAboutUs)
    }
    history.push(nextURL)
  }

  attachBGCheckToProfileAndGoToNextPage = () => {
    const { attachToProfile } = this.props
    attachToProfile()
    this.toggleAttachBGCheckModal()
    this.goToNextURL()
  }

  toggleAttachBGCheckModal = () => {
    const { isAttachBGCheckModalOpen } = this.state
    this.setState({ isAttachBGCheckModalOpen: !isAttachBGCheckModalOpen })
  }

  toggleAddBGCheckModal = () => {
    const { isAddBGCheckModalOpen } = this.state
    this.setState({
      isAddBGCheckModalOpen: !isAddBGCheckModalOpen,
    })
  }

  primaryActionAddBGCheckModal = () => {
    this.toggleAddBGCheckModal()
    history.push(urlTo('backgroundCheckPayment'))
  }

  deleteCheck = (id) => {
    deleteSelfTenancyCheck(id)
      .then(() => {
        snugNotifier.success('Tenancy Document successfully deleted')
        this.getBGCheckData()
      })
      .catch((error) => snugNotifier.error(error.message))
  }

  onClickSaveForSelfTenancyCheck = () => {
    this.setState({
      isAddBGCheckModalOpen: false,
    })
    this.getBGCheckData()
  }

  onClickNextButton = () => {
    const { spinner, reputationPreference, backgroundCheck } = this.props

    const { selfTenancyCheck } = this.state

    const { SelfTenancyCheckDoc = [] } = selfTenancyCheck || {}

    const hasSelfTenancyCheck =
      SelfTenancyCheckDoc && SelfTenancyCheckDoc.length > 0
    const backgroundCheckOptOut =
      reputationPreference && !reputationPreference.optOutBackgroundCheck
    const status = backgroundCheck.status
    const { attachedToProfile = false } = backgroundCheck

    const checkStatus = backgroundCheck.checkStatus

    const isStatusCompleted =
      checkStatus === 'verified' || checkStatus === 'unverified'

    const isCheckStatusError =
      checkStatus === 'request-invalid' ||
      checkStatus === 'error' ||
      checkStatus === 'response-invalid' ||
      checkStatus === 'payment-failed'

    if (!isStatusCompleted && !isCheckStatusError && !hasSelfTenancyCheck) {
      this.toggleAddBGCheckModal()
      return
    }

    const shouldShowBackgroundCheck = status === 'complete'
    if (
      shouldShowBackgroundCheck &&
      !spinner &&
      backgroundCheckOptOut &&
      !attachedToProfile &&
      !hasSelfTenancyCheck
    ) {
      this.toggleAttachBGCheckModal()
    } else {
      this.goToNextURL()
    }
  }

  render() {
    const {
      backgroundCheck,
      identityDocList,
      spinner,
      error,
      backUrl,
      completeness,
      property,
      reputationPreference,
      currentUser,
      discardResult,
      applicationDetails,
    } = this.props

    const {
      apiError,
      isExpiryModalOpened,
      isAttachBGCheckModalOpen,
      isAddBGCheckModalOpen,
      selfTenancyCheck,
      showExampleReportModal = false,
    } = this.state
    const { config: applicationConfig = {}, offer = {} } =
      applicationDetails || {}
    const { property: propertyInApp = {} } = offer || {}
    const { teamName = '' } = propertyInApp || {}
    const { applicationsSettingsConfig = {} } = applicationConfig
    const { exclude_bankruptcy_report: excludeBGCBankruptcyReport = false } =
      applicationsSettingsConfig || {}

    const propertyId = getParameter('propertyId')
    const applicationId = getParameter('applicationId')
    const isFromApplicationForm =
      isFromApplication(backUrl) || propertyId || applicationId
    const status = backgroundCheck.status
    const checkStatus = backgroundCheck.checkStatus
    const message = backgroundCheck.message
    const { firstName = '', lastName = '' } = currentUser
    const backgroundCheckOptOut =
      reputationPreference && !reputationPreference.optOutBackgroundCheck
    const backgroundCheckDate =
      backgroundCheck.createdAt &&
      backgroundCheck.createdAt !== '0001-01-01T00:00:00Z'
        ? moment(backgroundCheck.createdAt)
            .format('hh:mm A DD MMM YYYY')
            .toUpperCase()
        : ''
    const isStatusPending = status === 'pending'
    const isStatusCompleted =
      checkStatus === 'verified' || checkStatus === 'unverified'
    const isCheckStatusError =
      checkStatus === 'request-invalid' ||
      checkStatus === 'error' ||
      checkStatus === 'response-invalid' ||
      checkStatus === 'payment-failed'
    const shouldShowBackgroundCheck = status === 'complete'
    const nextURL = isFromApplicationForm
      ? `${urlTo('profileAvatar')}${window.location.search}`
      : `${urlTo('profileAvatar')}`

    const renterName = `${firstName} ${lastName}`

    const hasDriversLicense = (identityDocList?.identityDocs || []).some(
      (doc) => doc.docType === IDDocumentDocType.DriverLicense,
    )

    const driversLicense =
      identityDocList?.identityDocs?.find(
        (doc) => doc.docType === IDDocumentDocType.DriverLicense,
      ) || {}

    const { SelfTenancyCheckDoc = [] } = selfTenancyCheck || {}

    const hasSelfTenancyCheck =
      SelfTenancyCheckDoc && SelfTenancyCheckDoc.length > 0

    const hasIdentityDocs = (identityDocList?.identityDocs || []).length > 0

    const toggleExampleReportModal = () => {
      const { showExampleReportModal } = this.state
      this.setState({
        showExampleReportModal: !showExampleReportModal,
      })
    }

    return (
      <Display.CenterContentContainer componentClass="width100">
        {isExpiryModalOpened && (
          <BackgroundCheckExpiryModal
            discardResult={discardResult}
            closeModal={() => this.closeExpiryModal()}
            validityPeriodInMonths={backgroundCheck.validityPeriodInMonths}
          />
        )}
        <ProgressBarComponent completeness={completeness} />
        <Box mb={theme.space[3] + 'px'}>
          <RentalReputationHeaderReminder
            property={property}
            backUrl={backUrl}
            remindText={'Current application'}
            isFromApplicationForm={isFromApplicationForm}
            title="Background Check"
          />
        </Box>
        <Box mb={theme.space[4] + 'px'}>
          <Heading level={1}>Background Check</Heading>
        </Box>
        {!hasSelfTenancyCheck && (
          <Text maxWidth={theme.maxWidths[5] + 'px'} mb={theme.space[5] + 'px'}>
            Strengthen your profile with a Background Check so agents and owners
            can assess your application faster. Only {BG_CHECK_SPECIAL_PRICE}{' '}
            (usually {BG_CHECK_USUAL_PRICE}, save 32%), view an{' '}
            <Text
              color={theme.colors.skyBlue}
              onClick={toggleExampleReportModal}
              className="cursor-pointer"
              as="span"
            >
              example detailed report
            </Text>
            .
          </Text>
        )}

        {!isStatusCompleted && !isCheckStatusError && (
          <div>
            <BGCheck
              rentalHistoryCompleted={completeness.rentalHistory}
              identityDocumentCompleted={completeness.identityDocument}
              hasValidIdentityDocs={completeness.hasValidIdentityDocs}
              hasDriversLicense={hasDriversLicense}
              hasDriverLicenseCardNumber={Boolean(driversLicense.cardNumber)}
              notShowAddBGCheckSection={spinner || isStatusPending}
              nextURL={nextURL}
              selfTenancyCheck={selfTenancyCheck}
              deleteCheck={this.deleteCheck}
              onClickSaveForSelfTenancyCheck={
                this.onClickSaveForSelfTenancyCheck
              }
              hasIdentityDocs={hasIdentityDocs}
              excludeBGCBankruptcyReport={excludeBGCBankruptcyReport}
              queryParams={window.location.search}
            />
          </div>
        )}

        {isStatusPending && <PendingReminder status={status} />}

        {excludeBGCBankruptcyReport && (
          <Alert
            showLeftIcon={true}
            leftIconVariant="info"
            variant="blueWithBg"
            mt={3}
            mb={5}
          >
            {teamName} does not include Bankruptcy in the Background Check
            Report
          </Alert>
        )}

        {isStatusCompleted && (
          <div>
            {shouldShowBackgroundCheck &&
              backgroundCheckDate &&
              backgroundCheckOptOut && (
                <BackgroundCheckProcessedDate
                  backgroundCheckDate={backgroundCheckDate}
                  validityPeriodInMonths={
                    backgroundCheck.validityPeriodInMonths
                  }
                />
              )}
            {shouldShowBackgroundCheck && backgroundCheckOptOut && (
              <BackgroundCheckConfirmation
                backgroundCheckDate={
                  backgroundCheck &&
                  backgroundCheck.createdAt &&
                  backgroundCheck.createdAt
                }
                renterName={renterName}
                excludeBGCBankruptcyReport={excludeBGCBankruptcyReport}
                applicationGUID={applicationId}
              />
            )}
          </div>
        )}

        {spinner && (
          <Spinner text="We've requested a Background Check Report which can take up to 30 seconds to complete. You can continue and you will receive an email once it has finished processing." />
        )}

        {backgroundCheck && !hasSelfTenancyCheck && (
          <div className="mt40">
            <Checkbox
              label="Include the Background Check in my profile and applications"
              firstName={firstName}
              value={
                reputationPreference &&
                !reputationPreference.optOutBackgroundCheck
              }
              onChange={this.onChangeOptOutBackgroundCheck}
              shouldShowBackgroundCheck={shouldShowBackgroundCheck}
              componentClassName={'mb10'}
            />
          </div>
        )}

        {!isStatusPending && isCheckStatusError && (
          <div>
            <ErrorMessageBox error={message} />
            <button className="btn" onClick={discardResult}>
              Try again
            </button>
          </div>
        )}

        {error && <ErrorMessageBox error={error} />}
        {apiError && <ErrorMessageBox error={apiError} />}

        <Display.BottomContentContainer>
          <Display.CenterContentContainer componentClass="width100">
            <div className="submit-button-container">
              <BottomButton
                btnText={
                  isFromApplicationForm ? 'Save & Continue' : 'Next: About us'
                }
                clickNextFunction={this.onClickNextButton}
                bottomButtonsClass="pt0 mt0 summary-bottom-button"
                nextBtnClass="summary-green-button-class"
                backButtonClass="summary-grey-button-class"
                nextButtonPositionClass="pr20 pl0"
                showArrow={false}
              />
            </div>
          </Display.CenterContentContainer>
        </Display.BottomContentContainer>

        {isAttachBGCheckModalOpen && (
          <AttachBGCheckModal
            primaryAction={this.attachBGCheckToProfileAndGoToNextPage}
            toggleModal={this.toggleAttachBGCheckModal}
            secondaryAction={this.goToNextURL}
          />
        )}
        {isAddBGCheckModalOpen && (
          <AddBGCheckModal
            toggleModal={this.toggleAddBGCheckModal}
            secondaryAction={this.goToNextURL}
            primaryAction={this.primaryActionAddBGCheckModal}
            onClickSaveForSelfTenancyCheck={this.onClickSaveForSelfTenancyCheck}
          />
        )}
        {showExampleReportModal && (
          <ExampleReportModal
            toggleExampleReportModal={toggleExampleReportModal}
          />
        )}
      </Display.CenterContentContainer>
    )
  }
}

export default IdentityOverview
