import styled from 'styled-components'
import {
  border,
  boxShadow,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  typography,
} from 'styled-system'

export const Svg = styled.svg(
  position,
  space,
  color,
  typography,
  border,
  boxShadow,
  layout,
  flexbox,
  grid,
)
