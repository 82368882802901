import moment from 'moment'

import BaseInputDate from 'app/components/forms/base_inputDate/component'
import {
  firstElementOrEmptyString,
  withValidation,
} from 'app/components/forms/util'
import * as validations from 'app/shared_components/validations'
import * as helpers from 'app/sm/helpers'

const futureDateValidator = (date) => {
  return validations.compareDates(
    moment().format(helpers.dateWithDash),
    date,
    'This field is required',
    'The date must be in the future',
  )
}

const validateFutureDateFlattened = (date) =>
  firstElementOrEmptyString(futureDateValidator(date))

const InputFutureDate = withValidation(
  BaseInputDate,
  validateFutureDateFlattened,
)

export default InputFutureDate
