import * as React from 'react'

function SvgWbSunnyRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.005 1.05h.01c.55 0 .99.44.99.99v.97c0 .55-.44.99-1 .98h-.01c-.55 0-.99-.44-.99-.99v-.96c0-.55.44-.99 1-.99zm-5.95 3.59l-.39-.39a.993.993 0 00-1.4 0l-.01.01a.984.984 0 000 1.4l.39.39c.39.39 1.01.39 1.4 0l.01-.01a.984.984 0 000-1.4zM3.015 11h-1.02c-.55 0-.99.44-.99.99V12c0 .55.44.99.99.99h1.01c.56.01 1-.43 1-.98V12c0-.56-.44-1-.99-1zm16.74-6.74c-.39-.39-1.02-.39-1.41-.01l-.39.39a.984.984 0 000 1.4l.01.01c.39.39 1.02.39 1.4 0l.39-.39a.984.984 0 000-1.4zm-1.42 15.49l-.39-.39a.987.987 0 01.01-1.41.993.993 0 011.4 0l.39.39a.996.996 0 11-1.41 1.41zm1.67-7.76V12c0 .55.44.99.99.99h1.01c.55 0 .99-.44.99-.99v-.01c0-.55-.44-.99-.99-.99h-1.01c-.55 0-.99.44-.99.99zm-14 .01c0-3.31 2.69-6 6-6s6 2.69 6 6-2.69 6-6 6-6-2.69-6-6zm5.99 10.95h.01c.55 0 .99-.44.99-.99V21c0-.55-.44-.99-.99-.99h-.01c-.55 0-.99.44-.99.99v.96c0 .55.44.99.99.99zm-6.33-3.21a.996.996 0 01-1.41 0c-.39-.39-.39-1.01-.01-1.41l.39-.39a.996.996 0 011.41 0l.01.01c.38.38.39 1.01 0 1.4l-.39.39z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgWbSunnyRounded
