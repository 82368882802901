import React from 'react'

import styled from 'styled-components'
import { color, layout, typography } from 'styled-system'

import { Box } from 'app/components/design-system-components'
import { Heading } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const DescriptionContainer = styled(Box)`
  line-height: ${({ theme }) => theme.lineHeights.title};
  color: ${({ theme }) => theme.colors.gray};

  ${color}
  ${layout}
  ${typography}
`

const PropertyDescription = ({
  property: { description, title },
  ...props
}) => {
  const renderDescription = () => ({
    __html:
      description ||
      '<Box mt={`${theme.space[4]}px`}>Description not available</Box>',
  })
  return (
    <DescriptionContainer {...props}>
      <Heading
        level="4"
        fontSize={theme.fontSizes.pLarge16}
        color={theme.colors.gray800}
      >
        {title}
      </Heading>
      <Box
        mt={`${theme.space[4]}px`}
        dangerouslySetInnerHTML={renderDescription()}
      />
    </DescriptionContainer>
  )
}

export default PropertyDescription
