import {
  covidAlertIds,
  covidAlertsConstants,
} from 'app/constants/covid-alerts.constants'

export const mapCovidAlerts = ({ hasColdSymptoms, recentOverseasVisit }) => {
  const covidAlertId =
    hasColdSymptoms && recentOverseasVisit
      ? covidAlertIds.overseasVisitorAndHasColdSymptoms
      : hasColdSymptoms
      ? covidAlertIds.hasColdSymptoms
      : recentOverseasVisit
      ? covidAlertIds.recentOverseasVisit
      : null

  if (!covidAlertId) return {}

  return {
    message: covidAlertsConstants[covidAlertId],
  }
}
