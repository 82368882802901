import React from 'react'

import styled from 'styled-components'

import stoolflipped from 'app/assets/icons/stool-flipped.png'
import { history } from 'app/shared_components/router'

const LinksContainer = styled.ul`
  li {
    margin-bottom: ${({ theme }) => theme.space[3] + 'px'};
  }
`

export const NotFound404 = () => (
  <div className="hero-error">
    <div className="error-wrapper">
      <div className="hero_text mt10">
        <h2 className="error_h2 text-center-sm mt200">
          Sorry, we can't find that page.
        </h2>
        <div data-aos="fade-right">
          <div className="mt20">
            <p>
              <a className="backlink_text" onClick={() => history.goBack()}>
                &lt; click to go back
              </a>
            </p>
            <div className="mt20">
              <p className="error_text">Here are some useful links:</p>
              <LinksContainer className="list-unstyled">
                <li>
                  <a href="/home/overview">My Home</a>
                </li>
                <li>
                  <a href="/portfolio/overview">My Portfolio</a>
                </li>
                <li>
                  <a href="https://help.snug.com/hc/en-us">Help Center</a>
                </li>
              </LinksContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img className="error-tool" src={stoolflipped} />
  </div>
)
export default NotFound404
