import React from 'react'

import { SearchSampleItem } from 'app/bond_cover/agency/agency_search_item'
import SnugTip from 'app/components/display/snug_tip/component'
import * as Headers from 'app/components/display/text/standard_text/standard-headings/component'
import * as NewForm from 'app/components/forms/forms'
import * as Form from 'app/shared_components/form_component'

const StandardScreen = ({ children }) => {
  return (
    <div className="two-col-box-flex-row sm73">
      <div className="col-first">{children}</div>
    </div>
  )
}

class FormDemo extends React.Component {
  static onAddValue(value) {
    return (valueLimit) => {
      if (valueLimit.hasMaxValue) {
        return value >= valueLimit.maxValue ? valueLimit.maxValue : value + 1
      }
      return value + 1
    }
  }

  static onReduceValue(value) {
    return (valueLimit) => {
      if (valueLimit.hasMinValue) {
        return value <= valueLimit.minValue ? valueLimit.minValue : value - 1
      }
      return value <= 0 ? 0 : value - 1
    }
  }
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      middleName: '',
      middleNameChecked: false,
      lastName: '',
      email: '',
      ABN: '',
      gender: '',
      rentingLength: '',
      startDate: '',
      endDate: '',
      expiryDate: '',
      phone: '',
      comment: '',
      text: '',
      fuzzySearchValue: '',
      adults: 0,
      rent: 200,
      multiSelectSampleOptions: [
        {
          value: '1',
          picked: false,
        },
        {
          value: '2',
          picked: false,
        },
        {
          value: '3',
          picked: false,
        },
      ],
      errors: {
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        ABN: '',
        gender: '',
        rentingLength: '',
        startDate: '',
        endDate: '',
        expiryDate: '',
        phone: '',
        comment: '',
        text: '',
        fuzzySearchValue: '',
        adults: '',
      },
      submitting: false,
      isCommentOptional: true,
    }
  }

  onChangeMultiSelect = (val) => {
    const { multiSelectSampleOptions } = this.state
    const targetOption = multiSelectSampleOptions.find(
      (option) => option.value === val,
    )
    targetOption.picked = !targetOption.picked
    this.setState({
      multiSelectSampleOptions,
    })
  }

  onChangeRadioGroup = (radioGroupID) => {
    return (event) => {
      const { target } = event
      const { id } = target
      this.setState({
        [radioGroupID]: id,
        errors: { ...this.state.errors, [radioGroupID]: '' },
      })
    }
  }

  onCommentOptionalChange = () => {
    this.setState({
      isCommentOptional: !this.state.isCommentOptional,
      errors: { ...this.state.errors, text: '' },
    })
  }

  onSearchBoxBlur = (event) => {
    const { value } = event.target
    if (value) {
      this.setState({
        errors: {
          ...this.state.errors,
          fuzzySearchValue: '',
        },
      })
    }
  }

  onSearchBoxChange = (result, callback) => {
    this.setState(
      {
        fuzzySearchValue: result.value,
      },
      () => {
        return callback && callback()
      },
    )
  }

  focusAndScrollToFirstError(errors) {
    const field = Object.keys(errors)
      .filter((fieldName) => this.state.errors[fieldName])
      .shift()
    if (field) {
      const element = document.getElementById(field)
      if (element) {
        element.focus()
        element.scrollIntoView(true)
      }
    }
  }

  selectGender = (event) => {
    this.setState({ gender: event.target.value })
  }

  submit = () => {
    if (!this.validateRequiredFields() || !this.validateForm()) {
      this.focusAndScrollToFirstError(this.state.errors)
      return
    }
    this.setState({ submitting: true })
    setTimeout(() => {
      this.setState({ submitting: false })
    }, 2000)
  }

  toggleMiddleName = () => {
    this.setState({ middleNameChecked: !this.state.middleNameChecked })
  }

  update = (field) => {
    return (data) => {
      this.setState({
        [field]: data.value,
        errors: {
          ...this.state.errors,
          [field]: data.error,
        },
      })
    }
  }

  validateForm = () => {
    const errors = []
    Object.keys(this.state.errors).map((field) => {
      const error = this.state.errors[field]
      return error && errors.push(error)
    })
    return errors.length === 0
  }

  validateRequiredFields = () => {
    const errors = {}
    // skip middle name if not checked
    Object.keys(this.state.errors)
      .filter((field) => field !== 'middleName' || this.state.middleNameChecked)
      .filter((field) => field !== 'text' || !this.state.isCommentOptional)
      .map((field) => {
        if (this.state[field] === '') {
          errors[field] = 'This field is required'
        }
        return ''
      })
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    })
    return Object.keys(errors).length === 0
  }

  render() {
    const disabled = this.state.submitting
    return (
      <StandardScreen>
        <div className="container p0 m0 width100 mb20">
          <NewForm.InputName
            label="First Name"
            value={this.state.firstName}
            error={this.state.errors.firstName}
            onChange={this.update('firstName')}
            id="firstName"
            componentClass="col-sm-6 col-12 p0"
          />
          <NewForm.InputName
            label="Last Name"
            value={this.state.lastName}
            error={this.state.errors.lastName}
            onChange={this.update('lastName')}
            id="lastName"
            componentClass="col-sm-6 col-12 p0"
          />
          <NewForm.InputEmail
            label="Email"
            value={this.state.email}
            error={this.state.errors.email}
            onChange={this.update('email')}
            id="email"
            componentClass="col-sm-6 col-12 p0"
          />
        </div>
        <Form.Checkbox
          label="Middle Name"
          checked={this.state.middleNameChecked}
          onChange={this.toggleMiddleName}
        />
        {this.state.middleNameChecked && (
          <NewForm.InputName
            label="Middle Name"
            value={this.state.middleName}
            error={this.state.errors.middleName}
            onChange={this.update('middleName')}
            id="middleName"
          />
        )}
        <div className="container p0 m0 width100 mb20">
          <NewForm.InputABN
            label="ABN"
            value={this.state.ABN}
            error={this.state.errors.ABN}
            onChange={this.update('ABN')}
            id="ABN"
            componentClass="col-sm-6 col-12 p0"
          />
          <NewForm.FuzzySearchDropdown
            placeholder="relax, just sample"
            value={this.state.fuzzySearchValue}
            handleInputChange={this.onSearchBoxChange}
            inputChangeCallback={() => {}}
            handleInputBlur={this.onSearchBoxBlur}
            className=""
            searchFunction={() => {}}
            SearchResultItem={SearchSampleItem}
            error={this.state.errors.fuzzySearchValue}
            // query results should not be passed from here in real usecase
            queryResults={['sample 1', 'sample 2', 'sample 3']}
            componentClass="col-sm-6 col-12 p0"
          />
        </div>
        <NewForm.DropdownGender
          label="Gender"
          value={this.state.gender}
          error={this.state.errors.gender}
          options={['Male', 'Female']}
          onChange={this.update('gender')}
          id="gender"
        />
        <NewForm.DropdownRelationship
          placeholder="relationship"
          label="relationship"
          value={this.state.relationship}
          error={this.state.errors.relationship}
          options={[
            'Parent',
            'Sibling',
            'Grandparent',
            'Child',
            'Extended Family',
            'Spouse',
            'Partner',
            'Friend',
            'Colleague',
            'Other',
          ]}
          onChange={this.update('relationship')}
          id="relationship"
        />
        <NewForm.InputDate
          label="New Start Date"
          value={this.state.startDate}
          error={this.state.errors.startDate}
          before={this.state.endDate}
          onChange={this.update('startDate')}
          id="startDate"
        />
        <NewForm.InputDate
          label="End Date"
          value={this.state.endDate}
          error={this.state.errors.endDate}
          after={this.state.startDate}
          onChange={this.update('endDate')}
          id="endDate"
        />
        <NewForm.InputFutureDate
          label="New Expiry Date"
          value={this.state.expiryDate}
          error={this.state.errors.expiryDate}
          onChange={this.update('expiryDate')}
          id="expiryDate"
        />
        <NewForm.InputPhoneNumber
          label="New Contact Number"
          value={this.state.phone}
          error={this.state.errors.phone}
          onChange={this.update('phone')}
          id="phone"
        />
        <Form.RadioGroup
          label="How long have you been renting"
          id="rentingLength"
          error={this.state.errors.rentingLength}
        >
          <Form.Checkbox
            id="6m"
            label="6m"
            checked={this.state.rentingLength === '6m'}
            onChange={this.onChangeRadioGroup('rentingLength')}
          />
          <Form.Checkbox
            id="12m"
            label="12m"
            checked={this.state.rentingLength === '12m'}
            onChange={this.onChangeRadioGroup('rentingLength')}
          />
          <Form.Checkbox
            id="24m"
            label="24m"
            checked={this.state.rentingLength === '24m'}
            onChange={this.onChangeRadioGroup('rentingLength')}
          />
          <Form.Checkbox
            id="36m"
            label="36m"
            checked={this.state.rentingLength === '36m'}
            onChange={this.onChangeRadioGroup('rentingLength')}
          />
        </Form.RadioGroup>
        <Form.CommentArea
          label="Comment"
          value={this.state.comment}
          error={this.state.errors.comment}
          placeholder="Your comment"
          rows={2}
          onChange={this.update('comment')}
          id="comment"
        />
        <Form.Checkbox
          label="Optional"
          checked={this.state.isCommentOptional}
          onChange={this.onCommentOptionalChange}
        />
        <NewForm.CommentArea
          label="Comment"
          value={this.state.text}
          error={this.state.errors.text}
          placeholder="Insert text"
          onChange={this.update('text')}
          id="comment"
          cols={50}
          rows={1}
          isOptional={this.state.isCommentOptional}
        />
        <NewForm.DigitAdderWidget
          text="Small adder widget"
          value={this.state.adults}
          onChange={this.update('adults')}
          onAdd={FormDemo.onAddValue(this.state.adults)}
          onMinus={FormDemo.onReduceValue(this.state.adults)}
          leftIcon="-"
          rightIcon="+"
          hasMaxValue
          maxValue={6}
        />
        <NewForm.DigitAdderWidget
          text="Big adder widget"
          value={this.state.rent}
          onChange={this.update('rent')}
          onAdd={FormDemo.onAddValue(this.state.rent)}
          onMinus={FormDemo.onReduceValue(this.state.rent)}
          leftIcon="-"
          rightIcon="+"
          size="big"
          componentClass="mb30"
        />
        <NewForm.MultiSelect
          title="demo"
          options={this.state.multiSelectSampleOptions}
          className="p0"
          onChange={this.onChangeMultiSelect}
        />
        <SnugTip
          componentClass="col-sm-8"
          text="Lorem ipsum dolor sit amet, consectetur elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation"
        />
        <Form.Button
          label="Submit"
          loading={this.state.submitting}
          disabled={disabled}
          onClick={this.submit}
        />
        <Headers.MainHeader text="Main Header" />
        <Headers.SectionHeader text="Section Header" />
        <Headers.ContainerHeader text="Container Header" />
        <Headers.GreySubheading text="Grey Subheading" />
      </StandardScreen>
    )
  }
}

export default FormDemo
