import moment from 'moment'

import * as helpers from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

export const SMS_REPORT_HELP_TEXT =
  'SMS report scheduled to send within 30m of finish'

export const startToEndTimeIntervalsFromHours = (
  startTime,
  endTime,
  duration,
) => {
  let timeIntervalsArray = []
  const beginning = moment().hour(startTime).minute(0).seconds(0)
  const end = moment().hour(endTime).minute(0).seconds(0)

  while (beginning.diff(end) < 0) {
    // TODO: Remove this condition!
    if (beginning > moment(beginning).hour(startTime)) {
      timeIntervalsArray.push(beginning.format('h:mm A'))
    }
    beginning.add(duration, 'minutes')
  }
  return timeIntervalsArray
}

export const requestViewingDateFormat = 'D MMM YYYY (ddd)'

export const oneDayLaterErrorMessage =
  'Choose a date within the past 24 hrs or later'
export const conflictingTimeMessage =
  'A time already exists for this property or team member, schedule anyway?'

export const VIEWING_REPORT_CONFIRMATION_MESSAGE =
  'The viewing is not finished. Are you sure you want to send the Report?'
export const SMS_VIEWING_REPORT_CONFIRMATION_MESSAGE =
  'The viewing is not finished. Are you sure you want to send the SMS Report?'

export const VIEWING_TIME_DATE_ERROR =
  'Please check if the viewing time and date are valid'

export const CANCEL_VIEWING_CONFIRM_TEXT =
  'Are you sure you want to cancel the viewing? Registrants and enquirers will be notified.'
export const CANCEL_VIEWING_CONFIRMATION_MESSAGE =
  'Viewing cancelled, notification for registrants and enquirers requested.'

export const TIME_PAST_ERROR = 'Time Past'

export const isfutureViewing = (startDate) => {
  return moment(startDate).isAfter(moment())
}

export const calcEndDateFromStartDateAndDuration = (startDate, duration) => {
  return moment(startDate).add(duration, 'minutes')
}

export const isViewingInProgress = (startDate, endDate) => {
  moment().isBetween(startDate, endDate)
}

export const checkIfViewingEnded = (viewing) => {
  const { startDate, duration } = viewing || {}
  if (startDate && duration) {
    return moment().isAfter(moment(startDate).add(duration, 'minutes'))
  }
  return false
}
export const StatusAttendeeUndefined = 0
export const StatusAttendeeConfirmed = 1
export const StatusAttendeeCancelled = 2
export const SelfCheckInTypeUndefined = ''
export const SelfCheckInTypeQR = 'QR'

export function ValidateDateWithOnedayPastDate(
  date1,
  date2,
  emptyMessage,
  message,
) {
  const datePastOneDay = moment().add(-1, 'days').format(helpers.dateWithDash)
  const errors = []
  if (!date1 || date1 === '') {
    errors.push(emptyMessage)
  } else if (!date2 || date2 === '') {
    errors.push(emptyMessage)
  } else if (datePastOneDay > date2) {
    errors.push(message)
  }

  return errors
}

export function validateViewingStartTime(selectedDate, startTime, message) {
  let errors = []

  if (!startTime) {
    errors.push(message)

    return errors
  }

  let currentDate = moment().format('DD MMM YYYY')
  if (selectedDate) {
    currentDate = selectedDate
  }

  const selectedStartDate = `${currentDate} ${startTime}`
  if (
    moment(selectedStartDate, 'DD MMM YYYY hh:mm A').isBefore(
      moment(
        moment().add(-24, 'hours').format('DD MMM YYYY hh:mm A'),
        'DD MMM YYYY hh:mm A',
      ),
      'second',
    )
  ) {
    errors.push(message)
  }

  return errors
}

export const VIEWINGS_BY_PROPERTY = 'viewingsByProperty'
export const VIEWINGS_BY_DATE = 'viewingsByDate'

export const SORT_BY_DATE = 'date'
export const SORT_BY_ADDRESS = 'address'

export const viewingCutoffOptions = [
  { guid: 0, name: 'Next day' },
  { guid: 1, name: '2 Hours' },
  { guid: 2, name: '4 Hours' },
]

export const showSMSReportStatusAndType = (
  viewingTimeStart,
  viewingTimeEnd,
  isSMSViewingReportSent,
) => {
  let showReportAndStatus = {
    showStatusPill: false,
    statusPillText: 'Not Sent',
    statusPillVariant: 'warning',
  }
  if (isSMSViewingReportSent) {
    showReportAndStatus = {
      showStatusPill: true,
      statusPillText: 'Sent',
      statusPillVariant: 'success',
    }
    return showReportAndStatus
  }
  const currentTime = moment().format(dateTimeHelpers.DEFAULT_UNIX_TIME_Z)
  const startTime = moment(viewingTimeStart).format(
    dateTimeHelpers.DEFAULT_UNIX_TIME_Z,
  )
  const endTime = moment(viewingTimeEnd).format(
    dateTimeHelpers.DEFAULT_UNIX_TIME_Z,
  )
  console.log(
    currentTime,
    moment(currentTime).isBetween(startTime, endTime),
    viewingTimeStart,
    viewingTimeEnd,
  )
  if (moment(currentTime).isBetween(startTime, endTime)) {
    showReportAndStatus = {
      showStatusPill: true,
      statusPillText: 'Pending',
      statusPillVariant: 'warning',
    }
    return showReportAndStatus
  }
  if (moment(currentTime).isAfter(viewingTimeEnd)) {
    showReportAndStatus = {
      showStatusPill: true,
      statusPillText: 'Not Sent',
      statusPillVariant: 'warning',
    }
  }
  return showReportAndStatus
}

export const checkIfViewingSlotIsEnabled = (registrants) => {
  return registrants.some((registrant) => registrant.slot > 0)
}

export const orderRegistrantsBasedOnSlots = (registrants) => {
  const filteredRegistrantsWithNoSlots = registrants.filter(
    (registrant) => registrant.slot === 0,
  )
  const filteredRegistrantsWithSlots = registrants.filter(
    (registrant) => registrant.slot !== 0,
  )

  let slotOrderedRegistrants = filteredRegistrantsWithSlots.sort(
    (regA, regB) => {
      return regA.slot - regB.slot
    },
  )
  slotOrderedRegistrants = slotOrderedRegistrants.concat(
    filteredRegistrantsWithNoSlots,
  )
  return slotOrderedRegistrants
}

export const VIEWING_RUN_DRAFT = 'draft'
export const VIEWING_RUN_PUBLISHED = 'published'

export const isViewingRunDraft = (status) => {
  return status === VIEWING_RUN_DRAFT
}

export const isActiveRegistrant = (registrant = {}) => {
  const confirmationStatus =
    registrant?.viewingOnsiteRegistrant?.confirmationStatus || 0
  return confirmationStatus !== StatusAttendeeCancelled
}
