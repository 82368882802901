import { connect } from 'react-redux'

import EmploymentContainer from 'app/profile/renter/employment/employment_form_container'
import {
  addEmployer,
  fetchEmployer,
  removeEmployer,
  updateEmployer,
} from 'app/sm/employment/actions'

const mapStateToProps = ({ Employment, Shared }) => ({
  employer: Employment.employer,
  backUrl: Shared.backUrl,
})

const mapDispatchToProps = (dispatch) => ({
  addEmployer: (payload, success, fail) =>
    dispatch(addEmployer(payload, success, fail)),
  fetchEmployer: (id) => dispatch(fetchEmployer(id)),
  updateEmployer: (payload, id, success, fail) =>
    dispatch(updateEmployer(payload, id, success, fail)),
  removeEmployer: (id, success, fail) =>
    dispatch(removeEmployer(id, true, success, fail)),
  ping: () => console.log('ping'),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentContainer)
