import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'

import { connect } from 'react-redux'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  Box,
  Button,
  Flex,
  LoadingSection,
} from 'app/components/design-system-components'
import {
  InfoRounded,
  SearchRounded,
} from 'app/components/design-system-components/icons/actions'
import { WarningRounded } from 'app/components/design-system-components/icons/alert'
import { Input } from 'app/components/design-system-components/inputs'
import Pagination from 'app/components/design-system-components/Pagination'
import * as actFilters from 'app/dashboard/team_activity/ActivityFilters'
import { activityPeriods } from 'app/dashboard/team_activity/ActivityFilters/DateRangeFilter/constants'
import { SMSTable } from 'app/dashboard/team_activity/SMSReporting/SMSTable'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { getSmsUsageStats, listSms } from 'app/services/http/sms'
import {
  getStartOfNextMonth,
  HALF_DAY_FULL_MONTH,
} from 'app/utils/datetime/helpers'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import {
  filterReducerFactory,
  filtersActions,
} from 'app/utils/reducers/filtersReducer'

const PAGINATION_LIMIT = 8

const FiltersAndSearch = ({
  filtersState,
  filtersDispatch,
  currentTeam,
  onFiltersInitialized,
}) => {
  const [searchTextState, setSearchTextState] = useState('')

  const debouncedOnSearchTextChange = useCallback(
    debounce(
      (value) => filtersDispatch(filtersActions.updateSearch(value)),
      STANDARD_TIMEOUT,
    ),
    [],
  )

  const onSearchTextChange = ({ target: { value } }) => {
    setSearchTextState(value)
    debouncedOnSearchTextChange(value)
  }

  const hasFiltersApplied = actFilters.hasAppliedFilters(filtersState)

  const filtersSortActionsElem = (
    <Button
      ml={2}
      height="40px"
      variant="outline"
      onClick={() => {
        setSearchTextState('')
        filtersDispatch(filtersActions.resetAllFilters())
      }}
    >
      Clear
    </Button>
  )

  const filtersInputs = (
    <Box ml={2}>
      <actFilters.ActivitiesFilters
        teamId={currentTeam.guid}
        initialPeriod={activityPeriods.currentMonth}
        filters={filtersState.filters}
        updateFilter={(filterId, filterValues) =>
          filtersDispatch(filtersActions.updateFilter(filterId, filterValues))
        }
        onFiltersInitialized={onFiltersInitialized}
      />
    </Box>
  )

  return (
    <Box>
      <Flex>
        <Input
          icon={
            <SearchRounded
              style={{
                color: theme.colors.gray400,
              }}
            />
          }
          inputProps={{
            placeholder: 'Search phone or message content',
            value: searchTextState,
            onChange: onSearchTextChange,
            height: '20px',
            fontSize: theme.fontSizes.pSmall12,
          }}
          maxWidth="240px"
          height="40px"
        />
        {filtersInputs}
        {/*{(hasFiltersApplied || !!searchTextState) && filtersSortActionsElem}*/}
      </Flex>
    </Box>
  )
}

const SMSList = ({ currentTeam, isEnabled }) => {
  const {
    state: smsList,
    setState: setSmsList,
    loadingStates: listLoadingStates,
    loadingStatesHelpers: setListLoadingStatesHelpers,
  } = useStateWithLoading([])

  const {
    state: usageStats,
    setState: setUsageStats,
    loadingStates: usageStatsLoadingStates,
    loadingStatesHelpers: setUsageStatsLoadingStatesHelpers,
  } = useStateWithLoading([])

  const [filtersState, filtersDispatch] = useReducer(
    filterReducerFactory(actFilters.filtersInitialState),
    actFilters.filtersInitialState,
  )

  const [filtersInitializationReady, setFiltersInitializationReady] =
    useState(false)

  const [paginationOffset, setPaginationOffset] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    loadUsageStats()
  }, [])

  useEffect(() => {
    setPaginationOffset(0)
  }, [filtersState])

  useEffect(() => {
    if (filtersInitializationReady) {
      loadSMS()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOffset, filtersState])

  const loadSMS = () => {
    setListLoadingStatesHelpers.startLoading()
    const { range = {} } = actFilters.getFilters(filtersState)
    return listSms(currentTeam.guid, {
      offset: paginationOffset,
      limit: PAGINATION_LIMIT,
      ...range,
      q: filtersState.search,
    })
      .then(({ items, count }) => {
        setSmsList(items)
        setTotalCount(count)
        setListLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => setListLoadingStatesHelpers.setError(err))
  }

  const loadUsageStats = () => {
    setUsageStatsLoadingStatesHelpers.startLoading()
    return getSmsUsageStats(currentTeam.guid)
      .then((stats) => {
        setUsageStats(stats)
        setUsageStatsLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => setUsageStatsLoadingStatesHelpers.setError(err))
  }

  const onChangePage = (selectedPage) => {
    setPaginationOffset(selectedPage * PAGINATION_LIMIT)
  }

  const searchAndFiltersElem = (
    <FiltersAndSearch
      currentTeam={currentTeam}
      filtersState={filtersState}
      filtersDispatch={filtersDispatch}
      onFiltersInitialized={() => setFiltersInitializationReady(true)}
    />
  )

  const renderUsageAlert = () => {
    const { used, monthlyAllocation } = usageStats
    if (!used || !monthlyAllocation) return <></>
    const usagePercentage = used / monthlyAllocation
    if (usagePercentage < 0.75) return <></>

    const tooltip = (
      <ReactTooltip id="usageTooltip" data-type="info">
        <Box
          maxWidth="250px"
          textAlign="center"
          fontSize={theme.fontSizes.pSmall12}
        >
          Used {used}/{monthlyAllocation} of monthly SMS allocation. Usage
          resets on {getStartOfNextMonth().format(HALF_DAY_FULL_MONTH)}.
        </Box>
      </ReactTooltip>
    )

    if (used > monthlyAllocation) {
      return (
        <>
          <InfoRounded
            color={theme.colors.error500}
            fontSize={theme.fontSizes.pLarge16}
            data-tooltip-id="usageTooltip"
            data-tooltip-content=""
          />
          {tooltip}
        </>
      )
    }

    return (
      <>
        <WarningRounded
          color={theme.colors.warning400}
          verticalAlign={'middle'}
          data-tooltip-id="usageTooltip"
          data-tooltip-content=""
        />
        {tooltip}
      </>
    )
  }

  const totalCountElem = (count) => (
    <Box
      py={3}
      px={5}
      borderRadius={2}
      color={theme.colors.gray600}
      fontSize={5}
      fontWeight={theme.fontWeights[6]}
      lineHeight={1.4}
      backgroundColor={theme.colors.paleBlue}
      variant="blackWithBlueBg"
    >
      Total: {count}
    </Box>
  )

  const emptyStateMessage = <Box>No results</Box>

  const currentPage = Math.floor(paginationOffset / PAGINATION_LIMIT) + 1
  const pagesCount = Math.ceil(totalCount / PAGINATION_LIMIT)
  const paginationElem =
    pagesCount > 1 ? (
      <Pagination
        canNextPage={pagesCount > currentPage}
        canPreviousPage={currentPage > 1}
        currentPageNum={currentPage}
        maxPageNumbers={pagesCount}
        onPaginationButtonClicked={onChangePage}
      />
    ) : null

  const memoizedTable = useMemo(
    () => <SMSTable smsListData={smsList} />,
    [smsList],
  )

  return (
    <div>
      <Flex justifyContent="space-between" alignItems="center" mb={5}>
        <Flex flexDirection="column" flex="1">
          {searchAndFiltersElem}
        </Flex>
        <Flex alignItems="center" mb={4}>
          {!!usageStats && <Box mr={2}>{renderUsageAlert()}</Box>}{' '}
          {totalCountElem(totalCount)}
        </Flex>
      </Flex>

      <LoadingSection
        loadingState={listLoadingStates}
        actionHandler={loadSMS}
        loaderProps={{ fontSize: '48px' }}
      >
        {memoizedTable}
        {!smsList?.length && (
          <Box textAlign="center" lineHeight={1.4} m="auto" mt={8} width="30%">
            {emptyStateMessage}
          </Box>
        )}
      </LoadingSection>

      {paginationElem}
    </div>
  )
}

const mapStateToProps = ({ session }) => {
  return {
    currentTeam: session.currentTeam,
    currentAgency: session.currentAgency,
  }
}

export const SMSListPage = connect(mapStateToProps)(SMSList)
