import {
  composeFilesWithFieldNames,
  LogFetchError,
} from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'
import { api } from 'app/utils/api/helpers'

export const postOffPlatformApplication = (application) => {
  const url = `${apiBaseUrl}sm/applications?manageradded=true`
  let data = new FormData()

  const { otherPlatformApplicationFiles, otherPlatformSupportingAttachments } =
    application

  if (
    otherPlatformApplicationFiles &&
    otherPlatformApplicationFiles.length > 0
  ) {
    composeFilesWithFieldNames(otherPlatformApplicationFiles, data, 'file')
  }

  if (
    otherPlatformSupportingAttachments &&
    otherPlatformSupportingAttachments.length > 0
  ) {
    composeFilesWithFieldNames(
      otherPlatformSupportingAttachments,
      data,
      'supportingFiles',
    )
  }

  data.append('data', JSON.stringify(application))

  const options = {
    method: 'POST',
    body: data,
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (returnedApplication) => ({ ok: true, returnedApplication }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const putOffPlatformApplication = (application, applicaitonId) => {
  const url = `${apiBaseUrl}sm/applications/${applicaitonId}?manageradded=true`
  let data = new FormData()

  const { otherPlatformApplicationFiles, otherPlatformSupportingAttachments } =
    application

  data.append('data', JSON.stringify(application))

  if (
    otherPlatformApplicationFiles &&
    otherPlatformApplicationFiles.length > 0
  ) {
    composeFilesWithFieldNames(otherPlatformApplicationFiles, data, 'file')
  }

  if (
    otherPlatformSupportingAttachments &&
    otherPlatformSupportingAttachments.length > 0
  ) {
    composeFilesWithFieldNames(
      otherPlatformSupportingAttachments,
      data,
      'supportingFiles',
    )
  }

  const options = {
    method: 'PUT',
    body: data,
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (returnedApplication) => ({ ok: true, returnedApplication }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const pushApplication = (
  propertyId,
  application,
  isDraft,
  isApplyAnywhere,
  applyAnywhereGUID,
) => {
  const strip = (o) =>
    Object.assign({}, o, {
      offerID: propertyId,
    })

  var url = ''

  var http_verb = application.guidID ? 'PUT' : 'POST'

  if (isApplyAnywhere && !application.guidID) {
    url = `${apiBaseUrl}sm/applyanywhere/${applyAnywhereGUID}/apply`
    http_verb = 'POST'
  } else {
    url = application.guidID
      ? `${apiBaseUrl}sm/applications/${application.guidID}?apply=${!isDraft}`
      : `${apiBaseUrl}sm/applications?apply=${!isDraft}`
  }

  const options = {
    method: http_verb,
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(strip(application)),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json() // We get nothing for now, when a application is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (returnedApplication) => ({ ok: true, returnedApplication }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const pushQuickApplication = (propertyId, application, isDraft) => {
  const url = application.guidID
    ? `${apiBaseUrl}sm/applications/${
        application.guidID
      }?apply=${!isDraft}&quickapply=true`
    : `${apiBaseUrl}sm/applications?apply=${!isDraft}&quickapply=true`

  const strip = (o) =>
    Object.assign({}, o, {
      offerID: propertyId,
    })

  const options = {
    method: application.guidID ? 'PUT' : 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(strip(application)),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json() // We get nothing for now, when a application is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (application) => ({ ok: true, application }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getRenterSummary = (applicationGUID) => {
  const url = `${apiBaseUrl}sm/applications/${applicationGUID}/rentersummary`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (renterSummary) => ({ ok: true, renterSummary }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getApplication = (id) => {
  const url = `${apiBaseUrl}sm/applications/${id}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (application) => ({ ok: true, application }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getApplicationByUserId = (id) => {
  const url = `${apiBaseUrl}sm/applications/${id}/applicant`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (application) => ({ ok: true, application }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deleteDraft = (applicationId) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}`
  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true, responseText: '' }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getApplicationSummary = (id) => {
  const url = `${apiBaseUrl}sm/applications/${id}/summary`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (summary) => ({ ok: true, summary }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const _subscribeRenterScore = (offerId, payload) => {
  const url = `${apiBaseUrl}sm/offers/${offerId}/renterscore`

  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve(res.json()) // We get nothing for now, when a application is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (snugRank) => ({ ok: true, snugRank }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const syncAcceptApplication = (offerId, applicationId) => {
  const url = `${apiBaseUrl}sm/offers/${offerId}/applications/${applicationId}/accept`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({}),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
export const deleteApplicant = (applicationId, applicantId) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/applicants/${applicantId}`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true, responseText: '' }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postAcceptJointApplicationAsSecondary = (
  applicationId,
  applicantId,
  payload,
  apply,
) => {
  const url = `${apiBaseUrl}sm/applicants/${applicantId}/apply?secret=${applicationId}&apply=${apply}`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (payload) => ({ ok: true, payload }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getApplicantSummary = () => {
  const url = `${apiBaseUrl}sm/applicants/summary`
  const options = {
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (applicantSummary) => ({ ok: true, applicantSummary }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const pushFinalApplication = (applicationId, data) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/apply`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const putApplicantDisclosures = (applicantID, data) => {
  const url = `${apiBaseUrl}sm/applicants/${applicantID}/disclosures`
  return fetch(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getApplicantConfirmationSummary = (applicationId) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/confirmationSummary`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (confirmationSummary) => ({ ok: true, confirmationSummary }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getApplyAnywhereListing = (applyAnywhereListingGUID) => {
  const url = `${apiBaseUrl}sm/applyanywhere/${applyAnywhereListingGUID}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (applyAnywhereListing) => ({ ok: true, applyAnywhereListing }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deleteOccupant = (teamId, occupantId) => {
  const url = `${apiBaseUrl}sm/teams/${teamId}/contacts/${occupantId}`

  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postBallotSurvey = (payload) => {
  return api.post('/sm/ballotsurveys', payload)
}
