import React from 'react'

import { DragSource } from 'react-dnd'
const photoSource = {
  beginDrag: function (props, monitor) {
    return { photoId: props.photo.guidID }
  },
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    item: monitor.getItem(),
  }
}

class PhotosListItemImage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { connectDragSource, isDragging } = this.props
    const { photo, property, deletePhoto, setMainImage } = this.props

    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )

    return connectDragSource(
      <div>
        <div className="image" style={{ backgroundImage: `url(${photo.URL})` }}>
          <button
            onClick={() => deletePhoto(property.guidID, photo.guidID)}
            className="remove-btn"
          >
            <i className="icon-remove" aria-hidden="true" />
          </button>
          {isMobile && (
            <div
              onClick={() => setMainImage(property.guidID, photo.guidID)}
              className="main-sign"
            >
              <i className="icon-arrow-up"></i>
              <span>Set As Main Image</span>
            </div>
          )}
        </div>
      </div>,
    )
  }
}

export default DragSource('photo', photoSource, collect)(PhotosListItemImage)
