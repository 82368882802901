import { connect } from 'react-redux'

import { fetchApplication } from 'app/sm/apply/apply_actions'
import {
  deleteIdentityDocAttachment,
  fetchIdentityDocs,
  removeIdentityDoc,
} from 'app/sm/background_check/background_check_actions'
import IdentityDocuments from 'app/sm/background_check/identity_documents/identity_documents_container'
import {
  fetchCompleteness,
  fetchTeamSettingInformation,
} from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({
  BackgroundCheck,
  Shared,
  apply,
  RentalReputation,
}) => ({
  identityDocList: BackgroundCheck.identityDocList,
  error: BackgroundCheck.error,
  backUrl: Shared.backUrl,
  property: apply.property,
  completeness: RentalReputation.completeness,
  applicationDetails: apply.application,
})

const mapDispatchToProps = (dispatch) => ({
  fetchIdentityDocs: () => dispatch(fetchIdentityDocs()),
  removeIdentityDoc: (id) => dispatch(removeIdentityDoc(id)),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
  deleteIdentityDocAttachment: (id, attachmentrID, isIdentityDoc) =>
    dispatch(deleteIdentityDocAttachment(id, attachmentrID, isIdentityDoc)),
  fetchTeamSettingInformation: (teamId, queryString) =>
    dispatch(fetchTeamSettingInformation(teamId, queryString)),
  fetchApplication: (applicationId) =>
    dispatch(fetchApplication(applicationId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IdentityDocuments)
