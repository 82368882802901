import React from 'react'

import disclosureIcon from 'app/assets/icons/summary_icons/attachment.svg'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import Checkbox from 'app/shared_components/checkbox'
import { DISCLOSURE_AUTHORITY_TEMPLATE } from 'app/sm/helpers'
import { getUtilityProviderDisclosureConsent } from 'app/utils/utilityprovider/helpers'

class DisclosureContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      disclosureOptions: {
        optedToAcceptDisclosureConsent: false,
        optedToAcceptCondition: false,
        optedToReceiveUtilityConnection: false,
        optedToRequirePostingClause: false,
        optedToReceiveBondLoanInformation: false,
        optedInAsSmoker: false,
      },
    }
    this.toggleDisclosureOptions = this.toggleDisclosureOptions.bind(this)
  }

  componentDidUpdate = (prevProps) => {
    const { savedDisclosureOptions: prevSavedDisclosureOptions } = prevProps
    const { savedDisclosureOptions } = this.props
    if (!prevSavedDisclosureOptions && savedDisclosureOptions) {
      this.setState({
        disclosureOptions: savedDisclosureOptions,
      })
    }
  }

  toggleDisclosureOptions = (field) => {
    const { updateDisclosureOptions } = this.props
    return () => {
      this.setState(
        {
          disclosureOptions: {
            ...this.state.disclosureOptions,
            [field]: !this.state.disclosureOptions[field],
          },
        },
        () => updateDisclosureOptions(this.state.disclosureOptions),
      )
    }
  }

  render() {
    const {
      componentClassName,
      utilityProviderName,
      parsedApplicationSettingInfo,
    } = this.props
    const {
      disclosureOptions: {
        optedToAcceptDisclosureConsent,
        optedToAcceptCondition,
        optedToReceiveUtilityConnection,
        optedToRequirePostingClause,
        optedToReceiveBondLoanInformation,
        optedInAsSmoker,
      },
    } = this.state
    const {
      display_bond_loan_enabled: displayBondLoadEnabled,
      posting_clause_enabled: postingClauseEnabled,
      smoker_declaration_enabled: smokerDeclarationEnabled,
    } = parsedApplicationSettingInfo

    const optionsArray = [
      {
        condition: true,
        label: (
          <div>
            I accept the Personal Information Collection and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={DISCLOSURE_AUTHORITY_TEMPLATE}
            >
              Disclosure Authority
            </a>
          </div>
        ),
        value: optedToAcceptDisclosureConsent,
        onChangeFunction: this.toggleDisclosureOptions(
          'optedToAcceptDisclosureConsent',
        ),
        componentClassName: '',
      },
      {
        condition: true,
        label: 'I have inspected and accept the property condition',
        value: optedToAcceptCondition,
        onChangeFunction: this.toggleDisclosureOptions(
          'optedToAcceptCondition',
        ),
        componentClassName: '',
      },
      {
        condition: true,
        label: getUtilityProviderDisclosureConsent(utilityProviderName),
        value: optedToReceiveUtilityConnection,
        onChangeFunction: this.toggleDisclosureOptions(
          'optedToReceiveUtilityConnection',
        ),
        componentClassName: '',
      },
      {
        condition: postingClauseEnabled,
        label: 'I will require a posting clause',
        value: optedToRequirePostingClause,
        onChangeFunction: this.toggleDisclosureOptions(
          'optedToRequirePostingClause',
        ),
        componentClassName: 'mobile-utility-checkbox-min-height',
      },
      {
        condition: displayBondLoadEnabled,
        label: 'I will need a Bond Loan',
        value: optedToReceiveBondLoanInformation,
        onChangeFunction: this.toggleDisclosureOptions(
          'optedToReceiveBondLoanInformation',
        ),
        componentClassName: '',
      },
      {
        condition: smokerDeclarationEnabled,
        label: 'I am a smoker',
        value: optedInAsSmoker,
        onChangeFunction: this.toggleDisclosureOptions('optedInAsSmoker'),
        componentClassName: '',
      },
    ]
    return (
      <div className={`container-component ${componentClassName}`}>
        <ContainerHeader
          icon={disclosureIcon}
          text="Disclosures"
          isEditable={false}
        />
        {optionsArray.map((optionObject, index) => {
          const {
            condition,
            label,
            value,
            onChangeFunction,
            componentClassName,
          } = optionObject
          if (condition) {
            return (
              <Checkbox
                key={index}
                label={label}
                value={value}
                onChange={onChangeFunction}
                componentClassName={`mt30 ${componentClassName}`}
                spanClassName="gray-color fs16 height-auto width100"
              />
            )
          }
          return null
        })}
      </div>
    )
  }
}

export default DisclosureContainer
