export const convertTransApiErrorToStatus = (transformedApiErrors) => {
  return Object.entries(transformedApiErrors).reduce(
    (accErrors, [fieldName, error]) => {
      if (!error) return accErrors
      return {
        ...accErrors,
        [fieldName]: [error],
      }
    },
    {},
  )
}
