import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import { Input } from 'app/components/design-system-components/inputs'
import { PageSection } from 'app/components/design-system-components/page/Section'
import { PageSubHeading } from 'app/components/design-system-components/page/SubHeading'
import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import GeneralBottomBtns from 'app/shared_components/general_bottom_btn/component'
import { StyledInput } from 'app/sm/team_viewing_preference/container'
import FeedStatusActivities from 'app/sm/team_viewing_preference/feed/activities'
import UserNotAuthorizedShowTeamMembersList from 'app/sm/team_viewing_preference/userNotAuthorized/showTeamMembersList'
import { isFeedEmailFilterEnabled } from 'config/features'

const StyledIntro = styled(Box)`
  line-height: ${({ theme }) => theme.lineHeights.title};
  margin-top: ${({ theme }) => theme.space[5]}px;
  margin-bottom: ${({ theme }) => theme.space[7]}px;
`

const FeedPreferences = ({
  autoArchiveProperty,
  autoArchiveLeasedProperties,
  filterPropertiesOnListingAgentEmailEnabled,
  filterPropertiesOnListingAgentEmail,
  onFeedFilterEmailValueChange,
  onBackButtonClicked,
  onConfirmButtonClicked,
  onUpdateCheckbox,
  disableConfirmBtn,
  feedFilterEmailValidationError,
  isActionDisabled,
  isUserAdmin,
  teamSlug,
}) => {
  return (
    <div>
      <Flex alignItems="center">
        <Display.MainHeader
          hasSeperator={false}
          text="Property Feed"
          textClass="p0"
          componentClass="mb0"
        />
      </Flex>
      <Display.DefaultBodyText>
        <StyledIntro>
          Manage your property feed settings{' '}
          <a href="mailto:help@snug.com">learn more</a>
        </StyledIntro>
      </Display.DefaultBodyText>

      {!isUserAdmin && (
        <UserNotAuthorizedShowTeamMembersList teamSlug={teamSlug} />
      )}

      <Form.CheckBoxGeneral
        label="Archive property from feed"
        checked={autoArchiveProperty}
        eventProcessedByComponent
        onChange={onUpdateCheckbox('autoArchiveProperty')}
        disabled={isActionDisabled}
        showDisabledGrey={isActionDisabled}
        toolTip={
          <div>
            <i
              className="icon-help-outline fs16 gray-light-color ibm"
              data-tooltip-content=""
              data-tooltip-id="archiveFromFeed"
            />
          </div>
        }
      />
      <div>
        <ReactTooltip id="archiveFromFeed">
          <span>
            Automatically archive the property if the property is updated with a
            Leased or OffMarket status
          </span>
        </ReactTooltip>
      </div>
      <Form.CheckBoxGeneral
        label="Auto archive properties 'Marked as Leased' after 30 days"
        onChange={onUpdateCheckbox('autoArchiveLeasedProperties')}
        checked={autoArchiveLeasedProperties}
        eventProcessedByComponent
        disabled={isActionDisabled}
        showDisabledGrey={isActionDisabled}
      />
      {isFeedEmailFilterEnabled(teamSlug) && (
        <>
          <Form.CheckBoxGeneral
            label="Filter properties by listing agent email address"
            checked={filterPropertiesOnListingAgentEmailEnabled}
            disabled={isActionDisabled}
            showDisabledGrey={isActionDisabled}
            eventProcessedByComponent
            onChange={onUpdateCheckbox(
              'filterPropertiesOnListingAgentEmailEnabled',
            )}
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="filterPropertiesOnListingAgent"
                />
              </div>
            }
          />
          <div>
            <ReactTooltip id="filterPropertiesOnListingAgent">
              <span>
                Import only properties matching the specified listing agent
                email address. NOTE: properties will not upload if there are no
                matches.
              </span>
            </ReactTooltip>
          </div>
          {!!filterPropertiesOnListingAgentEmailEnabled && (
            <Flex mb={5}>
              <StyledInput>
                <Input
                  error={!!feedFilterEmailValidationError.length}
                  inputProps={{
                    placeholder: 'e.g. agent@exampleagency.com.au',
                    onChange: onFeedFilterEmailValueChange,
                    value: filterPropertiesOnListingAgentEmail,
                  }}
                />
              </StyledInput>
            </Flex>
          )}
        </>
      )}
      {!isActionDisabled && (
        <GeneralBottomBtns
          onBackButtonClicked={onBackButtonClicked}
          onConfirmButtonClicked={onConfirmButtonClicked}
          confirmBtnText={'Update'}
          disableConfirmBtn={disableConfirmBtn}
        />
      )}
      <PageSection>
        <PageSubHeading title="Activity" />
        <FeedStatusActivities />
      </PageSection>
    </div>
  )
}

export default FeedPreferences
