import React from 'react'

import { Box, Button } from 'app/components/design-system-components'
import Status from 'app/components/design-system-components/status'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'
import {
  findStepNumberBasedOnStep,
  findTextForNextStep,
} from 'app/sm/team_viewing_preference/setup/helpers'

function InProgress({
  stepToRouteTo,
  teamSlug = '',
  agencyGUID = '',
  currentStep,
}) {
  const stepNum = findStepNumberBasedOnStep(currentStep)
  const nextStepText = findTextForNextStep(currentStep)
  return (
    <Box>
      <Status
        leftContentToShow="In Progress"
        rightContentToShow={
          <Box>
            Add your <b>{nextStepText}</b> next
          </Box>
        }
        totalSteps={4}
        currentStep={stepNum}
      />
      <Box mt={theme.space[5] + 'px'}>
        Start receiving better quality rental applications in 2 minutes.
      </Box>
      <Box mt={theme.space[5] + 'px'}>
        <Button
          variant="outlineGreen"
          sizeVariant="large"
          onClick={() =>
            history.push(urlTo(stepToRouteTo, { teamSlug, agencyGUID }))
          }
        >
          Continue -{'>'}
        </Button>
      </Box>
    </Box>
  )
}

export default InProgress
