import { connect } from 'react-redux'

import {
  ping,
  sendQuestion,
  setPropertyId,
  textChanged,
} from 'app/sm/ask_question/ask_question_actions'
import AskQuestionContainer from 'app/sm/ask_question/ask_question_container'

const mapStateToProps = ({ askQuestion }) => ({
  question: askQuestion.question,
  propertyId: askQuestion.propertyId,
  error: askQuestion.error,
})

const mapDispatchToProps = (dispatch) => ({
  textChanged: (text) => dispatch(textChanged(text)),
  sendQuestion: () => dispatch(sendQuestion()),
  setPropertyId: (propertyId) => dispatch(setPropertyId(propertyId)),
  ping: () => dispatch(ping()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AskQuestionContainer)
