import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import QRCodeImage from 'app/assets/icons/qr-code-scanner.svg'
import {
  Box,
  Flex,
  FullWidthLinksTabs,
  HorizontalLine,
  IconButton,
} from 'app/components/design-system-components'
import { NavigateBeforeRounded } from 'app/components/design-system-components/icons/image'
import * as Display from 'app/components/display/display'
import { connectionStatuses } from 'app/core/coreSlice'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import QRCodeModal from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/qrCodeModal'
import * as helpers from 'app/sm/helpers'

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #d8dce2;
  height: 55px;
  align-items: center;
  .color-watermelon {
    color: #ff5b77;
  }
  .arrow-icon {
    padding-left: 25px;
  }
  .close-icon-container {
    padding: 20px 20px 20px 0;
  }
`

const attendeesTabId = 'attendees'
const listingTabId = 'listing'
const reportTabId = 'report'

const actionClickWrapperFactory =
  (onClickHandler) =>
  ({ children }) =>
    <div onClick={() => onClickHandler()}>{children}</div>

class ViewingDetailsTabPureModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTabId: attendeesTabId,
      qrCodeViewingScannerModalOpen: false,
    }
  }

  selectTab(tabId) {
    this.setState({
      selectedTabId: tabId,
    })
  }

  toggleQrCodeViewingScannerModal = () => {
    const { qrCodeViewingScannerModalOpen } = this.state
    this.setState({
      qrCodeViewingScannerModalOpen: !qrCodeViewingScannerModalOpen,
    })
  }

  render() {
    let {
      AttendeesTabCmp,
      ListingTabCmp,
      ReportContentCmp,
      closeHandler,
      propertyData,
      modalHeading,
      propertyTools,
      isOnline,
      displayViewingAttendeesModalHeading = true,
      viewingGUID = '',
      currentTeamSlug = '',
      viewingQRAutoCheckinEnabled = false,
    } = this.props
    const { selectedTabId, qrCodeViewingScannerModalOpen } = this.state
    const tabs = [
      {
        id: attendeesTabId,
        displayName: 'Attendees',
        ActionWrapper: actionClickWrapperFactory(() =>
          this.selectTab(attendeesTabId),
        ),
      },
      {
        id: listingTabId,
        displayName: 'Listing',
        ActionWrapper: actionClickWrapperFactory(() =>
          this.selectTab(listingTabId),
        ),
      },
      {
        id: reportTabId,
        displayName: 'Report',
        ActionWrapper: actionClickWrapperFactory(() =>
          this.selectTab(reportTabId),
        ),
      },
    ]

    return (
      <div
        className="modal-fullscreen"
        style={{ backgroundColor: 'transparent' }}
      >
        <ModalContentWrapper>
          {/* showing or hiding the heading. This is to resolve an issue on iPhone that did not show the */}
          {/* 'Add/Edit Attendee' heading if the edit/add attendee modal was clicked. Introduced here: */}
          {/* https://app.shortcut.com/snugco/story/85593/viewing-mobile-display-header-section-display-bug */}
          {displayViewingAttendeesModalHeading && (
            <ModalHeader>
              <Flex alignItems="center">
                <IconButton
                  m={5}
                  IconCmp={NavigateBeforeRounded}
                  variant="flat"
                  onClick={() => closeHandler()}
                  iconProps={{
                    fontSize: theme.fontSizes[12] + 'px',
                    color: theme.colors.watermelon,
                  }}
                />
                <h4 className="fw700 ">{modalHeading}</h4>
              </Flex>
              {viewingQRAutoCheckinEnabled && (
                <Box mr={4} onClick={this.toggleQrCodeViewingScannerModal}>
                  <img src={QRCodeImage} />
                </Box>
              )}
            </ModalHeader>
          )}
          <Flex width="100%" flex="1 1" overflowY="auto">
            <Box width="100%">
              <Box>
                <Flex flex="1 1 100%">
                  <Box width="100%">
                    <FullWidthLinksTabs
                      items={tabs}
                      selectedItemId={selectedTabId}
                    />
                  </Box>
                  <Display.ToolsButton
                    tools={propertyTools}
                    disabled={!isOnline}
                    componentClass="mr20"
                  />
                </Flex>
                <HorizontalLine />
              </Box>
              {selectedTabId === attendeesTabId && AttendeesTabCmp}

              {selectedTabId === listingTabId && ListingTabCmp}

              {selectedTabId === reportTabId && (
                <Box p="20px">{ReportContentCmp}</Box>
              )}
            </Box>
          </Flex>
        </ModalContentWrapper>
        {qrCodeViewingScannerModalOpen && (
          <QRCodeModal
            toggleModal={this.toggleQrCodeViewingScannerModal}
            viewingGUID={viewingGUID}
            currentTeamSlug={currentTeamSlug}
          />
        )}
      </div>
    )
  }
}

ViewingDetailsTabPureModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  propertyData: PropTypes.object.isRequired,
  AttendeesTabCmp: PropTypes.node,
  ListingTabCmp: PropTypes.node,
  ReportContentCmp: PropTypes.node,
  isOnline: PropTypes.bool,
}

const ViewingDetailsTabModal = connect(({ core }) => ({
  isOnline:
    core.connectionStatus === connectionStatuses.online ||
    core.connectionStatus === connectionStatuses.becameOnline,
}))(ViewingDetailsTabPureModal)

export default ViewingDetailsTabModal
