import { connect } from 'react-redux'

import AddStudentDetailsContainer from 'app/profile/renter/student_details/student_details_add_container'
import {
  getSingleStudent,
  postStudentDetails,
  updateStudentDetails,
} from 'app/sm/student_details/student_details_add_actions'

const mapStateToProps = ({ Shared, apply }) => ({
  backUrl: Shared.backUrl,
  property: apply.property,
})

const mapDispatchToProps = (dispatch) => ({
  postStudentDetails: (formData) => dispatch(postStudentDetails(formData)),
  getSingleStudent: (studentId) => dispatch(getSingleStudent(studentId)),
  updateStudentDetails: (formData, studentDetailId) =>
    dispatch(updateStudentDetails(formData, studentDetailId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddStudentDetailsContainer)
