import moment from 'moment'
import qs from 'qs'

import theme from 'app/match/applicationReportPDF/assets/theme'
import * as smHelpers from 'app/sm/helpers'

export const NOTES_LIMIT_FOR_APPLICATION = 3
export const TIME_FOR_NOTES_FETCH_MS = 1000
export const APPLICATION_ROW_MIN_HEIGHT = '125px'

export const ERROR_FOR_NULL_APPLICANTS =
  'This application could not be displayed'

export const generateURL = (teamSlug, filters) => {
  return (
    smHelpers.urlTo('prospectSummary', { teamSlug }) +
    '?' +
    qs.stringify(filters)
  )
}

export const UTILITY_LEAD_SENT = 'Utility lead referral has been sent.'

// helpers
export const selectProperty = (propertyOverview) =>
  propertyOverview && propertyOverview.property
export const selectApplications = (propertyOverview) =>
  propertyOverview && propertyOverview.applications
export const selectAddressFriendlyName = (property) =>
  property && property.address && property.address.friendlyName
export const selectPropertyGUID = (property) => property && property.guidID
export const selectApplicationGUID = (application) =>
  application && application.guidID
export const selectApplicants = (application) =>
  application && application.applicants
export const selectWeeklyRent = (application) =>
  application && application.weeklyRent
export const selectWeeklyRentWithEditedSummary = (application) => {
  return (application && application.managerAddedRent) > 0
    ? application.managerAddedRent
    : application.weeklyRent
}
export const selectRentToIncomeRatio = (application) =>
  application && application.rentToIncomeRatio
export const selectTerm = (application) => application && application.term
export const selectTermWithEditedSummary = (application) => {
  return application && application.managerAddedLeaseTerm > 0
    ? application.managerAddedLeaseTerm
    : application.term
}
export const selectPeople = (application) =>
  application && (application.people || application.adults)
export const selectPets = (application) =>
  application && application.managerAddedPets > 0
    ? application.managerAddedPets
    : application.pets

export const selectNumChildren = (application) => application?.numChildren

export const selectApplicationStatus = (application) =>
  application && smHelpers.ApplicationCategory[application.status]
export const selectApplicationActivity = (application) =>
  application && application.ApplicationActivity
export const selectSubmittedDate = (application) =>
  application && application.submittedDate
export const selectAgencyGUID = (property) => property && property.agencyID
export const selectPropertyOfferId = (property) =>
  property && property.offers[0].guidID
export const selectActiveOffer = (offers = []) =>
  offers.find((o) => o.isActive) || {}

// only difference than selectApplicationStatus is special treatment to household application
export const formatApplicationStatus = (application) => {
  if (!application) return ''
  const status = selectApplicationStatus(application)
  if (
    smHelpers.isJointApplication(application) &&
    smHelpers.isStatusApplied(status)
  ) {
    const allApplied = selectApplicants(application).every((applicant) =>
      smHelpers.isApplicantApplied(applicant),
    )
    if (allApplied) return 'Applied'
    else return 'In Progress'
  }
  return status
}
export const selectLastEventAt = (application) =>
  application && application.statusUpdatedAt
export const selectTotalIncome = (application) =>
  application &&
  selectApplicants(application).reduce(
    (acc, cur) => acc + selectWeeklyNetIncome(selectUserSummary(cur)),
    0,
  )
export const selectPrimaryApplicant = (applicants) =>
  applicants && applicants.find((applicant) => applicant.isPrimary)
export const selectApplicantGUID = (applicant) => applicant && applicant.guidID
export const selectFirstName = (applicant) =>
  (applicant && applicant.firstName) || 'Unknown'
export const selectLastName = (applicant) =>
  (applicant && applicant.lastName) || 'Unknown'
export const formatUpdatedAt = (updatedAt) =>
  moment(updatedAt).format('ddd DD MMM HH:mm')
export const selectUserSummary = (applicant) =>
  applicant && applicant.userSummary
export const selectScore = (applicant) => applicant && applicant.score
export const selectWeeklyNetIncome = (userSummary) =>
  userSummary ? userSummary.weeklyNetIncome : 0
export const selectPropertySlug = (property) => property && property.slug
export const selectPropertyStatus = (property) => property && property.status
export const sortByLastEventAtAscending = (app1, app2) =>
  moment(selectLastEventAt(app1)).diff(moment(selectLastEventAt(app2)))
export const sortByLastEventAtDescending = (app1, app2) =>
  moment(selectLastEventAt(app2)).diff(moment(selectLastEventAt(app1)))
export const sortBySubmittedDateAtAscending = (app1, app2) =>
  moment(selectSubmittedDate(app1)).diff(moment(selectSubmittedDate(app2)))
export const sortBySubmittedDateAtDescending = (app1, app2) =>
  moment(selectSubmittedDate(app2)).diff(moment(selectSubmittedDate(app1)))
export const sortByScoreAscending = (app1, app2) =>
  selectScore(selectPrimaryApplicant(selectApplicants(app1))) -
  selectScore(selectPrimaryApplicant(selectApplicants(app2)))
export const sortByScoreDescending = (app1, app2) =>
  selectScore(selectPrimaryApplicant(selectApplicants(app2))) -
  selectScore(selectPrimaryApplicant(selectApplicants(app1)))
export const sortByRentAscending = (app1, app2) =>
  selectWeeklyRent(app1) - selectWeeklyRent(app2)
export const sortByRentDescending = (app1, app2) =>
  selectWeeklyRent(app2) - selectWeeklyRent(app1)
export const sortByRatioAscending = (app1, app2) =>
  selectTotalIncome(app1) / selectWeeklyRent(app1) -
  selectTotalIncome(app2) / selectWeeklyRent(app2)
export const sortByRatioDescending = (app1, app2) =>
  selectTotalIncome(app2) / selectWeeklyRent(app2) -
  selectTotalIncome(app1) / selectWeeklyRent(app1)
export const sortByHouseholdAscending = (app1, app2) =>
  selectPeople(app1) - selectPeople(app2)
export const sortByHouseholdDescending = (app1, app2) =>
  selectPeople(app2) - selectPeople(app1)
export const sortByStatusAscending = (app1, app2) =>
  formatApplicationStatus(app1).localeCompare(formatApplicationStatus(app2))
export const sortByStatusDescending = (app1, app2) =>
  formatApplicationStatus(app2).localeCompare(formatApplicationStatus(app1))
export const selectSortFunc = (field, order) => {
  switch (field) {
    case 'submittedDate':
      return order === 'ascending'
        ? sortBySubmittedDateAtAscending
        : sortBySubmittedDateAtDescending
    case 'lastEventAt':
      return order === 'ascending'
        ? sortByLastEventAtAscending
        : sortByLastEventAtDescending
    case 'score':
      return order === 'ascending'
        ? sortByScoreAscending
        : sortByScoreDescending
    case 'rent':
      return order === 'ascending' ? sortByRentAscending : sortByRentDescending
    case 'ratio':
      return order === 'ascending'
        ? sortByRatioAscending
        : sortByRatioDescending
    case 'household':
      return order === 'ascending'
        ? sortByHouseholdAscending
        : sortByHouseholdDescending
    case 'status':
      return order === 'ascending'
        ? sortByStatusAscending
        : sortByStatusDescending
    default:
      return () => {}
  }
}

export const prospectSummaryPageStage = {
  viewing: 'Viewing',
  application: 'Application',
}

export const SORT_ASCENDING = 'ascending'
export const SORT_DESCENDING = 'descending'

export const UTILITY_STATUS_PENDING = 'pending'

export const shortlistedStarsVariants = {
  blue: theme.colors.blue,
  yellow: theme.colors.warning200,
}
