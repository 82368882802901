import React from 'react'

import styled from 'styled-components'

import * as Display from 'app/components/display/display'

const VideoDisplayContainer = styled.div`
  border: 1px solid black;
  background: black;
  color: white;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`

function VideoDisplay(props) {
  const {
    youtubeId = '',
    videoLength = '',
    videoTitle = '',
    height = '',
    isVimeo = false,
    vimeoId = '',
    width = '100%',
  } = props

  let styleProps = {
    width,
  }
  if (height) {
    styleProps = {
      width,
      height,
    }
  }
  return (
    <div>
      {youtubeId.length > 0 || vimeoId.length > 0 ? (
        <div>
          <iframe
            title="youtube video"
            style={styleProps}
            src={
              isVimeo
                ? `https://player.vimeo.com/video/${vimeoId}`
                : `https://www.youtube.com/embed/${youtubeId}`
            }
            frameBorder="0"
            allowFullScreen="allowFullScreen"
          />
          <Display.DefaultBodyText text={videoTitle} textClass="m0" />
          <Display.GreyBodyText text={videoLength} textClass="m0" />
        </div>
      ) : (
        <VideoDisplayContainer>Video Coming Soon</VideoDisplayContainer>
      )}
    </div>
  )
}

export default VideoDisplay
