import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  Box,
  ButtonWithLoading,
  Flex,
} from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import Amenities from 'app/sm/common/amenities'
import {
  formatLeaseTerm,
  formatRent,
  NO_IMAGE_PLACEHOLDER_URL,
  urlTo,
} from 'app/sm/helpers'
import { RankPieChart } from 'app/sm/renter_applications/components/applications_card'
import * as textHelpers from 'app/utils/text/helpers'

const StyledApplyAndBook = styled(Flex)`
  width: 100%;
  margin-top: 16px;
  button {
    width: 100%;
  }
  a {
    color: inherit;
    text-decoration: none;
    width: 100%;
    display: inline-block;
  }
`

// used public agency property list page
export class AgencyApplyCard extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props)
  }

  setFavorite(e) {
    e.preventDefault()
  }

  getOfferID = (property) => {
    const activeOffer = (property.offers || []).find((o) => o.isActive) || {}
    return activeOffer.guidID
  }

  _renderApplyButtonLabel = () => {
    const { property } = this.props
    const { applyButtonLabel = '' } = property || {}
    const DEFAULT_APPLY_LABEL = 'Apply'
    if (applyButtonLabel) {
      return applyButtonLabel
    }

    return DEFAULT_APPLY_LABEL
  }

  _renderBookButtonLabel = () => {
    const DEFAULT_BOOK_LABEL = 'Book'
    const { ballotsEnabled } = this.props
    if (ballotsEnabled) {
      return textHelpers.BALLOT_PROPERTY_APPLY_BUTTON_LABEL
    }
    return DEFAULT_BOOK_LABEL
  }

  render() {
    const {
      property,
      status,
      applicationId,
      score,
      weeklyRent,
      term,
      availableFromDate,
      loggedIn,
      teamSlug,
      ballotsEnabled,
    } = this.props
    const { isFavourite, applyLink = '' } = property || {}

    let imageUrl = NO_IMAGE_PLACEHOLDER_URL
    if (property.images && property.images.length > 0) {
      imageUrl = (
        property.images.find((image) => image.isMain === true) ||
        property.images[0]
      ).URL
    }

    let address = ''
    let suburb = ''
    if (property.address) {
      if (property.address.unitNumber) {
        address = `${property.address.unitNumber}/${property.address.streetNumber} ${property.address.streetName}`
      } else {
        address = `${property.address.streetNumber} ${property.address.streetName}`
      }
      suburb = property.address.suburb
    }

    const heartStyle = isFavourite
      ? 'icon-heart-filled red-color'
      : 'icon-heart-outline'
    const heartButton = loggedIn ? (
      <button onClick={(e) => this.setFavorite(e)}>
        <i className={heartStyle} />
      </button>
    ) : (
      ''
    )
    const viewPropertyDetailsTagClass =
      'rental-application-view-property-details'

    let applicationCorrespondingLink
    if (status === 'Offered') {
      applicationCorrespondingLink = urlTo('offeredApplication', {
        propertyId: property.guidID,
        applicationId,
      })
    } else if (status === 'Details') {
      applicationCorrespondingLink = urlTo('applyRedirect', {
        slug: property.slug,
      })
    } else {
      applicationCorrespondingLink = urlTo('application', { applicationId })
    }
    const availableFromFormatted = moment().isAfter(moment(availableFromDate))
      ? 'Available now'
      : moment(availableFromDate).format('DD MMM YYYY')
    const queryString = window.location.search
    applicationCorrespondingLink = applicationCorrespondingLink + queryString
    const finalApplyLink = applyLink
      ? applyLink + queryString
      : applicationCorrespondingLink
    const formattedRent = formatRent(weeklyRent)

    return (
      <div className="sm-properties-card-list-item">
        <div className="sm-properties-card-list-item-wrapper pb20">
          <Link
            to={applicationCorrespondingLink}
            className={`image ${viewPropertyDetailsTagClass}`}
            id={teamSlug}
            style={{ backgroundImage: `url(${imageUrl})` }}
          >
            <div className="controls">
              <div className="left">{false && heartButton}</div>
            </div>
          </Link>
          <div className="meta bbs">
            <div className="address">
              <Link
                to={applicationCorrespondingLink}
                className={`street ${viewPropertyDetailsTagClass}`}
                id={teamSlug}
                title={address}
              >
                {address}
              </Link>
              <div className="suburb mb10">{suburb}</div>
              <Amenities payload={property} wrapClassName="amenities-widget" />
            </div>

            {status !== 'Details' && (
              <div className="rank-chart">
                <div className="rank-chart-wrapper">
                  <RankPieChart rank={Math.round(score)} />
                  <div className="rank-chart-text fontSize-13">
                    {Math.round(score)}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="info">
            <Flex alignItems="center">
              <span>{formattedRent}</span>{' '}
              <span>
                <i className="icon-dot" /> {formatLeaseTerm(term)} months
              </span>
              {ballotsEnabled && (
                <Badge ml={2} text="Ballots" variant="notice" />
              )}
            </Flex>
            <div>
              <span>Date Available : </span>{' '}
              <span>{availableFromFormatted}</span>
            </div>
            <StyledApplyAndBook>
              <Box mr="8px" width="50%">
                {!ballotsEnabled && (
                  <ButtonWithLoading width="100%" variant="solid">
                    <Link to={finalApplyLink}>
                      {this._renderApplyButtonLabel()}
                    </Link>
                  </ButtonWithLoading>
                )}
              </Box>
              <Box ml="8px" width="50%">
                <ButtonWithLoading width="100%">
                  <Link
                    to={
                      ballotsEnabled
                        ? finalApplyLink
                        : applicationCorrespondingLink
                    }
                  >
                    {this._renderBookButtonLabel()}
                  </Link>
                </ButtonWithLoading>
              </Box>
            </StyledApplyAndBook>
          </div>
        </div>
      </div>
    )
  }
}
