import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getApplications = (id) => {
  const url = `${apiBaseUrl}sm/applications`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (applications) => ({ ok: true, applications }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const assignApplication = (teamId, applicationID, teamMemberID) => {
  const url = `${apiBaseUrl}sm/team/${teamId}/application/${applicationID}/assign/${teamMemberID}`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const fetchAssignedTeamMember = (teamGUID, applicationGUID) => {
  const url = `${apiBaseUrl}sm/team/${teamGUID}/application/${applicationGUID}/assignee`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (teamMemberInfo) => ({ ok: true, teamMemberInfo }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const acceptDeclineApplication = (shortcode, req) => {
  const url = `${apiBaseUrl}sm/shareapplication/${shortcode}/confirm`
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(req),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const moveApplication = (teamGUID, applicationGUID, payload) => {
  const { propertyGUID, message } = payload

  const url = `${apiBaseUrl}sm/team/${teamGUID}/application/${applicationGUID}/move`

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      message,
      propertyGUID,
    }),
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          })
    })
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
