import React from 'react'

import { RadialBar, RadialBarChart } from 'recharts'

import Card from 'app/match/applicationReportPDF/components/Card'
import CustomCheckbox from 'app/match/applicationReportPDF/components/CustomCheckbox'
import InfoList from 'app/match/applicationReportPDF/components/InfoList'

const listItems = [
  {
    title: 'Identity',
    field: 'identityDocumentCompleted',
  },
  {
    title: 'Income',
    field: 'incomeCompleted',
  },
]

const propTypes = {}

const data = [
  {
    uv: 50,
    pv: 2400,
    fill: '#37C878',
  },
]

const CustomizedLabel = ({ x, y, fill, value }) => {
  return (
    <text
      x={x}
      y={y}
      dy={'50%'}
      dx={'50%'}
      fill={fill || '#37C878'}
      fontSize={'9px'}
      fontFamily={'cera_pro, sans-serif'}
      fontWeight={700}
      textAnchor="middle"
      dominantBaseline="central"
    >
      {value}%
    </text>
  )
}

const Summary = ({
  summary,
  hasLeaseDetailsBeenModified,
  modifiedApplicationSummary,
}) => {
  if (summary.snugMatchScore > 0) {
    data[0].uv = summary.snugMatchScore
  }

  const endAngleCustom = () => {
    const res = data.map((item) => {
      return (-360 * item.uv) / 100 + 90
    })
    return res[0]
  }
  const summaryProperties = Object.keys(summary)
  const { affordability = '' } = summary
  return (
    <div className="Summary Application">
      {hasLeaseDetailsBeenModified && (
        <>
          <Card
            type={'icon'}
            icon={'icon-flag-points'}
            title={'Lease Summary'}
            addClass={'card-bottom-bordered'}
          />
          <InfoList
            data={modifiedApplicationSummary}
            addClass="bottom-border"
          />
        </>
      )}
      <Card
        type={'icon'}
        icon={'icon-flag-points'}
        title={'Lease Requested'}
        addClass={'card-bottom-bordered'}
      />
      <InfoList data={summary.rentInfo} addClass="bottom-border" />
      <div className="custom-row">
        <div className="custom-column">
          <div className="snug-mutch">
            <span className="pdf-flex-row">Snug Match Score</span>
            <small className="text-gray small--alt">
              Based on occupancy and rental yield
            </small>
          </div>
        </div>
        <div className="custom-column">
          <RadialBarChart
            startAngle={90}
            endAngle={endAngleCustom()}
            width={32}
            height={32}
            cx={16}
            cy={16}
            innerRadius={15}
            outerRadius={30}
            barSize={2}
            data={data}
          >
            <RadialBar
              minAngle={15}
              label={<CustomizedLabel />}
              background
              clockWise={true}
              dataKey="uv"
            />
          </RadialBarChart>
        </div>
      </div>
      <ul className="info-list bottom-border text-gray-dark">
        {listItems.map((listItem, index) => {
          const targetProperty = summaryProperties.find(
            (summaryProperty) => summaryProperty === listItem.field,
          )
          return (
            <li className="info-list__item" key={`${listItem} ${index}`}>
              <div className="info-list__item--title">{listItem.title}</div>
              <div className="info-list__item--value">
                <CustomCheckbox
                  type={summary[targetProperty] ? 'check' : 'cross'}
                  label={summary[targetProperty] ? 'Yes' : 'No'}
                />
              </div>
            </li>
          )
        })}
        <li className="info-list__item">
          <div className="info-list__item--title">
            Affordabilty (Rent/Income)
          </div>
          <div className="info-list__item--value">{affordability}</div>
        </li>
      </ul>
    </div>
  )
}

Summary.propTypes = propTypes

export default Summary
