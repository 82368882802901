import { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import {
  Button,
  FilesDropzone,
  LoadingSection,
} from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import { Image } from 'app/features/teams/key-logger/AddKeySetLoggerModal/KeySetImages/Image'
import { StepLayoutContainer } from 'app/features/teams/key-logger/components/StepLayoutContainer'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as keySetHttp from 'app/services/http/teams/key-logger'
import { useAttachments } from 'app/utils/hooks/useAttachments'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'

const PureKeySetImages = ({ teamId, keySet: initialKeySet, afterSave }) => {
  const {
    state: keySet,
    setState: setKeySet,
    loadingStatesHelpers: keySetLoadingStatesHelpers,
    loadingStates: keySetLoadingStates,
  } = useStateWithLoading(null)

  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    loadKeySetImages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const uploadImageFn = (file) =>
    keySetHttp
      .uploadKeySetImages(teamId, keySet.guid, file)
      .then(({ images: imagesRes }) => imagesRes[0])
  const deleteImageFn = (imageId) =>
    keySetHttp.deleteKeySetImages(teamId, keySet.guid, [imageId])

  const onImageAdded = (fileId) => {
    const attachedImage = images[fileId]
    console.log(attachedImage)
    if (attachedImage.file.guid) return
    uploadImage(fileId)
  }

  const {
    attachments: images,
    setExistingAttachments: setInitialImages,
    addAttachment: addImage,
    uploadAttachment: uploadImage,
    deleteAttachment: deleteImage,
  } = useAttachments(uploadImageFn, deleteImageFn, onImageAdded)

  const loadKeySetImages = () => {
    keySetLoadingStatesHelpers.startLoading()
    return keySetHttp
      .getKeySet(teamId, initialKeySet.guid, { populate_images: true })
      .then(({ key_set }) => {
        setKeySet(key_set)
        setInitialImages(key_set.images || [])
        keySetLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => keySetLoadingStatesHelpers.setError(err))
  }

  const onDrop = (selectedFiles) => {
    selectedFiles.forEach((file) => addImage(file))
  }

  const withDirtyCheck = (fn) => {
    return (fileId) => {
      if (!isDirty) setIsDirty(true)
      fn(fileId)
    }
  }

  const uploadImageWithDirtyCheck = withDirtyCheck(uploadImage)
  const deleteImageWithDirtyCheck = withDirtyCheck(deleteImage)

  const onContinue = () => {
    afterSave(isDirty ? initialKeySet : null)
  }

  const actionsElem = (
    <Button type="button" width="100%" onClick={onContinue}>
      Continue
    </Button>
  )

  return (
    <StepLayoutContainer actions={actionsElem}>
      <LoadingSection
        loadingState={keySetLoadingStates}
        actionHandler={loadKeySetImages}
        loaderProps={{ fontSize: '48px' }}
      >
        <Text>
          Add an image of your key set for reference.{' '}
          <Text as="span" color={theme.colors.gray400}>
            (optional)
          </Text>
        </Text>
        {Object.entries(images).map(([fileId, image]) => (
          <Image
            key={`${fileId}__${image.guid}`}
            imageAttachment={image}
            fileId={fileId}
            deleteImage={deleteImageWithDirtyCheck}
            retryUpload={uploadImageWithDirtyCheck}
            mb={theme.space[3] + 'px'}
          />
        ))}

        <FilesDropzone onDrop={onDrop} />
      </LoadingSection>
    </StepLayoutContainer>
  )
}

const mapStateToProps = ({ session }) => ({
  teamId: session.currentTeam.guid,
})

export const KeySetImages = connect(mapStateToProps)(PureKeySetImages)
