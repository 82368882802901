import React from 'react'

import 'app/sm/renter_background_check_report/components/sub-header/style.scss'
import Status from 'app/sm/renter_background_check_report/components/status-sector/component'

const SubHeader = ({
  title = '',
  image = '',
  date = '',
  status = '',
  text = '',
}) => {
  return (
    <div>
      {title && (
        <div className="sub-header">
          <span className="sub-header-title">{title}</span>
        </div>
      )}
      <p>{text}</p>
    </div>
  )
}

export default SubHeader
