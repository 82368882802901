import React from 'react'

import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'

const RentalReputationHeadReminder = ({
  property = {},
  backUrl = '',
  title,
  rentalBackUrlList = [],
  applyAnywhereListing = {},
  thirdTierText = '',
}) => {
  let address = ''
  if (property && property.address) {
    address = `${property.address.friendlyName}`
  }
  if (applyAnywhereListing && applyAnywhereListing.address) {
    address = `${applyAnywhereListing.address.friendlyName}`
  }

  const forwardUrl =
    rentalBackUrlList && rentalBackUrlList[0] === '/bc/profile'
      ? rentalBackUrlList[0]
      : backUrl
  const isFromProfile =
    rentalBackUrlList && rentalBackUrlList[0] === '/bc/profile'
  let previousPage = 'Profile'
  previousPage = isFromProfile ? 'Profile' : address || 'Profile'
  const isApplyingApplication = getParameter('address')
  return (
    <div>
      {!!isApplyingApplication ? (
        <div></div>
      ) : (
        <div className="rental-notification display-flex">
          <button className="btn btn-transparent btn-left height-auto-desktop rental-header-mobile-layout width-auto font-size-16">
            <span
              onClick={() =>
                previousPage === 'Profile'
                  ? history.push(urlTo('RentalReputation'))
                  : history.push(forwardUrl)
              }
            >
              {previousPage}
              <i className="icon-arrow-right font-size-14" />
            </span>
          </button>
          <button
            className="btn btn-transparent btn-left height-auto-desktop rental-header-mobile-layout width-auto font-size-16"
            onClick={thirdTierText ? () => history.goBack() : ''}
          >
            <span>
              {title}
              {thirdTierText && <i className="icon-arrow-right font-size-14" />}
            </span>
          </button>
          {thirdTierText && (
            <button className="btn btn-transparent btn-left rental-header-mobile-layout font-size-14">
              <span>{thirdTierText}</span>
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default RentalReputationHeadReminder
