import { useEffect, useState } from 'react'

import UpdateProfileForm from 'app/session/bc_profile/profile_update/Form'
import { formFields } from 'app/session/bc_profile/profile_update/form.utils'
import Header from 'app/session/bc_profile/profile_update/header'
import { isEmpty } from 'app/utils/objects/helpers'

const ProfileUpdate = ({ currentUser, fetchCurrentUserProfile, logout }) => {
  const mapInitialValues = () => {
    const profileExist = !!currentUser && !isEmpty(currentUser)
    return {
      [formFields.firstName]: !profileExist ? '' : currentUser.firstName,
      [formFields.lastName]: !profileExist ? '' : currentUser.lastName,
      [formFields.email]: !profileExist ? '' : currentUser.email,
      [formFields.mobile]: !profileExist ? '' : currentUser.mobilePhone,
    }
  }

  const [initialValues, setInitialValues] = useState(mapInitialValues())

  useEffect(() => {
    fetchCurrentUserProfile()
  }, [])

  useEffect(() => {
    setInitialValues(mapInitialValues())
  }, [currentUser])

  return (
    <>
      <Header />

      <UpdateProfileForm
        initialValues={initialValues}
        fetchCurrentUserProfile={fetchCurrentUserProfile}
        logout={logout}
        currentUser={currentUser}
      />
    </>
  )
}

export default ProfileUpdate
