import { useEffect } from 'react'

import { Form } from 'formik'
import { Link } from 'react-router-dom'

import { Button, TextSpan } from 'app/components/design-system-components'
import {
  AutocompleteField,
  InputField,
} from 'app/components/design-system-components/inputs'
import { SelectField } from 'app/components/design-system-components/inputs/Select/Select'
import {
  formFields,
  keySetMappedOptions,
} from 'app/features/teams/key-logger/AddKeySetLoggerModal/DefineKeySet/form.utils'
import {
  LabelSubText,
  StepLayoutContainer,
  StyledFieldWrapper,
} from 'app/features/teams/key-logger/components/StepLayoutContainer'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { urlIds, urlTo } from 'app/sm/helpers'
import { useSearchPropertyField } from 'app/utils/hooks/useSearchPropertyField'

const PropertyAutoCompleteHintMessage = () => {
  return (
    <TextSpan
      as="p"
      fontSize={theme.fontSizes.pSmall12}
      m={theme.space[3] + 'px'}
      color={theme.colors.gray600}
    >
      Property not available?{' '}
      <Link to={urlTo(urlIds.teams.properties.createArchived)}>add here</Link>
    </TextSpan>
  )
}

export const FormBody = ({
  teamId,
  formBag: {
    isValid,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    status,
    values,
    dirty,
  },
  markDirty,
}) => {
  useEffect(() => {
    if (dirty) markDirty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty])

  const { onSearchProperties, propertiesOptions, loadingProperties } =
    useSearchPropertyField(teamId, { allProperties: true })

  const actionsElem = (
    <Button type="submit" disabled={!isValid || isSubmitting} width="100%">
      Save and continue
    </Button>
  )

  return (
    <Form style={{ height: '100%' }}>
      <StepLayoutContainer actions={actionsElem}>
        <StyledFieldWrapper
          id={formFields.property}
          name={formFields.property}
          label="Property Address"
          labelProps={{
            subText: (
              <LabelSubText>
                Please select the property address that the keys are for
              </LabelSubText>
            ),
          }}
          required
        >
          <AutocompleteField
            name={formFields.property}
            placeholder="Search for a property address"
            options={propertiesOptions}
            onChangeText={onSearchProperties}
            isLoading={loadingProperties}
            HintMessageCmp={PropertyAutoCompleteHintMessage}
          />
        </StyledFieldWrapper>

        <StyledFieldWrapper
          id={formFields.keyIdLabel}
          name={formFields.keyIdLabel}
          label="Key Identity Label"
          labelProps={{
            subText: (
              <LabelSubText>
                Please enter a unique identifier for this particular key set
                (e.g. 412, 10Smith)
              </LabelSubText>
            ),
          }}
          required
        >
          <InputField
            id={formFields.keyIdLabel}
            name={formFields.keyIdLabel}
            inputProps={{ placeholder: 'Enter a key identity label' }}
          />
        </StyledFieldWrapper>

        <StyledFieldWrapper
          id={formFields.keySetType}
          name={formFields.keySetType}
          label="Key set type"
          required
        >
          <SelectField
            id={formFields.keySetType}
            name={formFields.keySetType}
            label="Select"
            options={keySetMappedOptions}
          />
        </StyledFieldWrapper>
      </StepLayoutContainer>
    </Form>
  )
}
