import React from 'react'

import * as Display from 'app/components/display/display'
class BackgroundCheckStatusComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDetails: false,
    }
  }

  onDetailsLinkClicked = () => {
    this.setState({
      showDetails: !this.state.showDetails,
    })
  }

  render() {
    const {
      title = '',
      componentClassName = '',
      titleClassName = '',
      resultInfo = {},
      result = false,
      contentClassName = '',
      bgImage = '',
      from = '',
    } = this.props
    const { showDetails } = this.state
    const { message = '' } = resultInfo

    const isIdentityVerification = title === 'Identity Verification:'
    const successText = isIdentityVerification ? 'Verified' : 'Info'
    const failText = isIdentityVerification ? 'Not Verified' : 'Not Found'
    const pillType = isIdentityVerification
      ? result
        ? 'green'
        : 'yellow'
      : !result
      ? 'green'
      : 'yellow'

    const fromBackgroundCheckReport = from === 'reportPage'

    return (
      <div>
        <div
          className={`application-info-common-component no-page-break ${componentClassName}`}
        >
          <div className={`background-check-title ${titleClassName}`}>
            {bgImage && (
              <span className="width20">
                <img className="mr3" src={bgImage} alt="background check" />
              </span>
            )}
            {title}
          </div>
          {
            <div
              className={`background-check-content display-flex ${contentClassName}`}
            >
              {!fromBackgroundCheckReport && (
                <div
                  className="pr15 details-text pdf-hide"
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    this.onDetailsLinkClicked()
                  }}
                >
                  {showDetails ? 'hide details' : 'show details'}
                </div>
              )}
              <Display.StatusPill
                pillType={pillType}
                text={result ? successText : failText}
              />
            </div>
          }
        </div>
        {showDetails && (
          <div className="background-check-message">
            {message || 'No information provided'}
          </div>
        )}
        {!fromBackgroundCheckReport && (
          <div className="background-check-message pdf-show">
            {message || 'No information provided'}
          </div>
        )}
      </div>
    )
  }
}

export default BackgroundCheckStatusComponent
