import React from 'react'

import { Link } from 'react-router-dom'

//TODO: Remove <Link> to Quote when possible
const Agent = () => (
  <div className="two-col-box-flex-row sm64">
    <div className="col-first">
      <table className="width100">
        <tbody>
          <tr>
            <td className="bond-back-cell">
              <div className="bond-back-header">
                Join the Snug movement
                <div className="note">(and earn some $ while you're at it)</div>
              </div>
              <div>
                Snug marketplace offers agents an additional revenue stream by
                servicing their local neighbourhood.
              </div>
              <button className="snug-button">Join Snug</button>
            </td>
          </tr>
        </tbody>
      </table>
      <Link to="/bc/quote">Quote</Link>
    </div>
  </div>
)

export default Agent
