import * as React from 'react'

function SvgOndemandVideoRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v1c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-1h5c1.1 0 1.99-.9 1.99-2L23 5a2 2 0 00-2-2zm-1 14H4c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h16c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1zm-9.5-2.85l3.98-2.28c.67-.39.67-1.35 0-1.74L10.5 7.85c-.67-.38-1.5.1-1.5.87v4.56c0 .76.83 1.25 1.5.87z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgOndemandVideoRounded
