import React from 'react'

import moment from 'moment'
import { Route } from 'react-router-dom'

import Breadcrumbs from 'app/sm/advertise_flow/components/breadcrumbs'
import Pay from 'app/sm/advertisements/advertisement_components/advertisement_form/pay'
import Portals from 'app/sm/advertisements/advertisement_components/advertisement_form/property_portals'
import { urlTo } from 'app/sm/helpers'
import { isCurrentRoute } from 'app/sm/router_helpers'

class AdvertisementForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: new Date().toISOString(),
      weeklyRent: null,
      acceptedLeaseLength: '6',
      listingPortals: '1;2;3;4;5;6;7;8;9;10',
      listingFee: 129,
    }
  }

  componentDidMount() {
    let { fetchPortals, match, fetchAdvertisement, fetchActiveAd } = this.props
    let { params, url } = match
    fetchPortals()

    const possibleRoutesForFetch = ['adFlowPortals', 'adFlowPayment']
    if (possibleRoutesForFetch.some((route) => isCurrentRoute(route))) {
      fetchAdvertisement(params.offerId)
      fetchActiveAd(params.offerId)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { advertisement, ad, listingFee } = nextProps
    this.setState(advertisement)
    this.setState({
      listingPortals:
        ad.listingPortals !== undefined
          ? ad.listingPortals
          : this.state.listingPortals,
      listingFee,
    })
  }

  componentWillUnmount() {
    // this.props.resetAdvertisement()
  }

  update = (field) => {
    return (event) =>
      this.setState({
        [field]: event.currentTarget.value,
      })
  }

  updateCheckbox = () => {
    return (event) => {
      let { name, id } = event.currentTarget
      let checks = this.state[name].split(';')

      let index = checks.indexOf(id)
      if (index == -1) {
        checks.push(id)
      } else {
        checks.splice(index, 1)
      }

      checks = checks.filter((a) => a != '').join(';')
      this.setState({ [name]: checks })
    }
  }

  updateDate = (date) => {
    this.setState({ startDate: date.toDate().toISOString() })
  }

  updateNumber = (field) => {
    return (event) =>
      this.setState({
        [field]: parseInt(event.currentTarget.value),
      })
  }

  render() {
    let {
      match,
      portals,
      createAdvertisement,
      updateAdvertisement,
      registerPayment,
      cardSelect,
      acceptTermsConditionsChange,
      fetchCards,
      acceptTermsAndConditions,
      cards,
      selectedCreditCard,
      payWithExistingCard,
      fetchActiveAd,
      ad,
      error,
      step,
    } = this.props

    let {
      weeklyRent,
      startDate,
      acceptedLeaseLength,
      listingPortals,
      listingFee,
    } = this.state
    let numPortals = listingPortals.split(';').filter((p) => p != '').length
    let { url, params } = match
    let { propertyId: id, offerId } = params
    let submit =
      url.includes('create') || url.includes('offer-details')
        ? createAdvertisement()
        : () =>
            updateAdvertisement(
              urlTo('updateOfferPortals', { propertyId: id, offerId }),
            )(offerId, this.state)

    return (
      <div className="profile-screen">
        <Breadcrumbs activeBreadCrumb={step} />
        <div className="ps-content">
          {step === 'portals' && (
            <Portals
              listingPortals={listingPortals}
              listingFee={listingFee}
              error={error}
              match={match}
              portals={portals}
              ad={ad}
              updateAdvertisement={updateAdvertisement}
            />
          )}
          {step === 'payment' && (
            <Pay
              error={error}
              registerPayment={registerPayment}
              listingFee={listingFee}
              match={match}
              selectedCreditCard={selectedCreditCard}
              cardSelect={cardSelect}
              cards={cards}
              fetchCards={fetchCards}
              acceptTermsAndConditions={acceptTermsAndConditions}
              acceptTermsConditionsChange={acceptTermsConditionsChange}
              payWithExistingCard={payWithExistingCard}
              fetchActiveAd={fetchActiveAd}
              ad={ad}
            />
          )}
        </div>
      </div>
    )
  }
}

export default AdvertisementForm
