import React, { createRef } from 'react'

import $ from 'jquery'
import moment from 'moment'
import qs from 'qs'
import styled from 'styled-components'

import WelcomeImage from 'app/assets/icons/Welcome.svg'
import Button from 'app/components/buttons/default/component'
import {
  Alert,
  Box,
  ButtonWithIcon,
  Flex,
  LoadingSection,
} from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import { InfoRounded } from 'app/components/design-system-components/icons/actions'
import { WarningAmberRounded } from 'app/components/design-system-components/icons/alert'
import { ArrowBackRounded } from 'app/components/design-system-components/icons/navigation'
import { Text as TextComponent } from 'app/components/design-system-components/typography'
import * as Display from 'app/components/display/display'
import * as Text from 'app/components/display/text/text_component'
import * as Form from 'app/components/forms/forms'
import { LabelWrapper } from 'app/components/forms/util'
import { Sources } from 'app/constants/agency-user-invites.constants'
import { RentalReferenceDeclineModal } from 'app/features/teams/rental-references/RentalReferenceDeclineModal'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  march2021PrivacyForcedQuestions,
  march2021Questions,
  march2021QuickReferencePrivacyControlledQuestions,
  march2021TenancyPrivacyControlledQuestions,
  NO_PETS,
  ratingVersions,
  UN_SURE,
  YES_CAT,
  YES_DOG,
  YES_OTHER,
} from 'app/match/rental_reference/rating_categories.constants'
import {
  DisclosureAuthority,
  GreenTickText,
  GreyBox,
  StyledFeaturesBox,
} from 'app/match/rental_reference/rating_form/commonComponents'
import * as QuestionsDisplay from 'app/match/rental_reference/rating_form/questions_display'
import RentalReferenceBanner from 'app/match/rental_reference/rating_form/RentalReferenceBanner'
import { withRatingFormBasicControls } from 'app/match/rental_reference/rating_form_base/withRatingFormBasicControls'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import SliderButton from 'app/shared_components/slider-button'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import {
  isAddressHistoryWithFamilyOrFriends,
  ratingCodeCollection,
} from 'app/sm/helpers'
import * as helpers from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
} from 'app/utils/loading-states'
import { formatPhoneNumber } from 'app/utils/phonenumber/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import { isRRToLiteGuest, isRRToLiteURL } from 'app/utils/url/helpers'

import 'app/match/rental_reference/rating_form/style.scss'

const TenancyQuestionsVisibilityContainer = styled.div`
  background: #f8f8f9;
  border: 1px solid #d8dce2;
  box-sizing: border-box;
  border-radius: 4px;
  border-left: 2px solid #ffaa4d;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    text-align: center;
    margin: 0;
  }
`

const StyledPetsDamageQuestion = styled.div`
  .picked {
    background-color: ${(props) =>
      PetsDamageSelectionColors[props.selection]} !important;
    color: white;
  }
`

const rentDurationTypeToText = ['/ week', '/ month']

export const CollapseQuestionsContainer = styled(Box)`
  box-shadow: 0px 3px 5px rgba(34, 33, 42, 0.08);
  width: 100%;
  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
  }
`

export const getTerm = (startDate, endDate) => {
  if (endDate) {
    return moment(endDate).diff(moment(startDate), 'months')
  }
  return moment().diff(moment(startDate), 'months')
}

export const Yes = 'Yes'
export const No = 'No'
export const trueStr = 'true'
export const falseStr = 'false'
export const MANAGER_TYPE = {
  agent: 1,
  landlord: 2,
}

const LITE_ACCOUNT_NOT_ELIGIBLE = 0
const LITE_ACCOUNT_ELIGIBLE = 1
export const LITE_ACCOUNT_JULY_2022_FLOW = 9
export const LITE_ACCOUNT_JUNE_2023_FLOW = 10

const constructRating = (ratingCode, answerValue, questionType) => {
  return {
    ratingCode,
    answerValue,
    questionType,
    isPrivate: true,
  }
}

const HasAnswerValueEmptyStringInArray = (array) => {
  const filetered = array.filter(
    (tenancyQuestion) => tenancyQuestion.answerValue === '',
  )
  return filetered.length > 0
}

export const AddressContainer = styled(Flex)`
  font-size: ${theme.fontSizes.pSurplusLarge24};
  font-weight: ${theme.fontWeights.h1};
  align-items: center;

  line-height: 25px;
  color: #252e3f;
  padding: 24px;
  padding-bottom: 0;
  padding-top: 0;
  img {
    margin-right: 8px;
  }
  @media screen and (max-width: 576px) {
    padding-left: 0;
  }
`

export const DeclineButtonContainer = styled.button`
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #7c8188;
  width: 50%;
  height: 50px;
  font-size: 14px;
  &:hover {
    background: transparent;
    color: #7c8188;
  }
`

export const RentalReferenceHeader = styled.div`
  font-family: Arial;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #252e3f;
  padding: 10px 0 0 24px;
  font-weight: bolder;
  @media screen and (max-width: 576px) {
    padding-left: 0;
  }
`

const LegacyRedColor = '#CA5442'
const LegacyGreenColor = '#008039'

const PetsDamageSelectionColors = {
  [Yes]: LegacyRedColor,
  [No]: LegacyGreenColor,
  [UN_SURE]: theme.colors.blue,
}

const MANDATORY_ERROR_TEXT = 'Question is required'

const PrivacySelector = ({
  isPrivateEnabled,
  onCheckBoxChange,
  disabled = false,
}) => {
  return (
    <TenancyQuestionsVisibilityContainer>
      <div className="mr15 fs16 fw500">
        {`The answers below are ${
          isPrivateEnabled ? 'not visible' : 'visible'
        } to the tenant`}
      </div>
      {!disabled && (
        <div className="display-flex align-items-center fs16">
          <span className="mr10">{`Currently: ${
            isPrivateEnabled ? 'not visible' : 'visible'
          }`}</span>
          <SliderButton
            enabled={isPrivateEnabled}
            onCheckboxChange={onCheckBoxChange}
          />
        </div>
      )}
    </TenancyQuestionsVisibilityContainer>
  )
}

const hasPetsOptions = [YES_CAT, YES_DOG, YES_OTHER]
const updatedTenantPetsArrOnChange = (currentlySelectedOptions, value) => {
  const hasPetSelected = hasPetsOptions.includes(value)
  const optionIsAlreadySelected = currentlySelectedOptions.includes(value)
  if (!hasPetSelected) {
    return optionIsAlreadySelected ? [] : [value]
  }
  const petsSelectedOptions = currentlySelectedOptions.filter((option) =>
    hasPetsOptions.includes(option),
  )
  return optionIsAlreadySelected
    ? petsSelectedOptions.filter((option) => option !== value)
    : [...petsSelectedOptions, value]
}

const CreateLiteAccountComp = ({
  createAnAccount,
  onChangeCreateAnAccount,
}) => (
  <Alert variant="blueWithBg">
    <Flex alignItems="center">
      <img src={WelcomeImage} alt="welcome" />
      <Box ml="16px">
        <Form.CheckBoxGeneral
          label={
            <>
              Access my reference history with a FREE Snug Lite Account.{' '}
              <a
                href="https://snug.com/manager/"
                target="_blank"
                rel="noreferrer"
              >
                learn more
              </a>
            </>
          }
          eventProcessedByComponent={true}
          checked={createAnAccount}
          onChange={onChangeCreateAnAccount}
        />
      </Box>
    </Flex>
  </Alert>
)

class RatingFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: false,
      isRentCorrect: '',
      isTermCorrect: '',
      rentAgainSelections: Object.values(
        march2021Questions.rentagain.options,
      ).map((option) => ({ ...option, picked: false })),
      tenantPetsOptions: [
        {
          value: YES_CAT,
          picked: false,
        },
        {
          value: YES_DOG,
          picked: false,
        },
        {
          value: YES_OTHER,
          picked: false,
        },
        {
          value: NO_PETS,
          picked: false,
        },
        {
          value: UN_SURE,
          picked: false,
        },
      ],
      petsDamageOptions: [
        {
          value: No,
          picked: false,
        },
        {
          value: Yes,
          picked: false,
        },
        {
          value: UN_SURE,
          picked: false,
        },
      ],
      referenceContactPreferenceSelections: [
        {
          value: 'Please call',
          picked: false,
        },
        {
          value: 'Call if required',
          picked: false,
        },
        {
          value: 'Email only',
          picked: false,
        },
      ],
      rentOnTimeSelections: [
        {
          value: 'Yes, always',
          picked: false,
        },
        {
          value: 'Often (late less than 3)',
          picked: false,
        },
        {
          value: 'Sometimes (late 3-6)',
          picked: false,
        },
        {
          value: 'Rarely (late 6 or more)',
          picked: false,
        },
      ],
      overall: [
        {
          value: '5',
          picked: false,
          text: 'Excellent',
        },
        {
          value: '4',
          picked: false,
          text: 'Great',
        },
        {
          value: '3',
          picked: false,
          text: 'Good',
        },
        {
          value: '2',
          picked: false,
          text: 'Average',
        },
        {
          value: '1',
          picked: false,
          text: 'Not Ok',
        },
      ],
      pleasant: [
        {
          value: '5',
          picked: false,
          text: 'Excellent',
        },
        {
          value: '4',
          picked: false,
          text: 'Great',
        },
        {
          value: '3',
          picked: false,
          text: 'Good',
        },
        {
          value: '2',
          picked: false,
          text: 'Average',
        },
        {
          value: '1',
          picked: false,
          text: 'Not Ok',
        },
      ],
      takesCare: [
        {
          value: '5',
          picked: false,
          text: 'Excellent',
        },
        {
          value: '4',
          picked: false,
          text: 'Great',
        },
        {
          value: '3',
          picked: false,
          text: 'Good',
        },
        {
          value: '2',
          picked: false,
          text: 'Average',
        },
        {
          value: '1',
          picked: false,
          text: 'Not Ok',
        },
      ],
      textTypeRatingPayload: {
        positiveComment: {
          ratingCode: helpers.ratingType.positiveComment,
          answerValue: '',
          questionType: helpers.QuestionType.Text,
          isPrivate: true,
        },
        feedback: {
          ratingCode: helpers.ratingType.feedback,
          answerValue: '',
          questionType: helpers.QuestionType.Text,
          isPrivate: true,
        },
      },
      tenancyQuestions: [
        {
          ratingCode: helpers.ratingType.payOnTime,
          description: 'Did the tenant pay rent on time?',
          questionType: helpers.QuestionType.Boolean,
          answerValue: '',
          isPrivate: true,
        },
        {
          ratingCode: helpers.ratingType.maintenance,
          description: 'Were repair and maintenance requests reasonable?',
          questionType: helpers.QuestionType.Boolean,
          answerValue: '',
          isPrivate: true,
        },
        {
          ratingCode: helpers.ratingType.petsOnProperty,
          description: 'Were pets kept on the property without permission?',
          questionType: helpers.QuestionType.Boolean,
          answerValue: '',
          isPrivate: true,
        },
        {
          ratingCode: helpers.ratingType.complaints,
          description: 'Did you receive any complaints during the tenancy?',
          questionType: helpers.QuestionType.Boolean,
          answerValue: '',
          isPrivate: true,
        },
        // NOTE: Previous breach notice question removed [ch53708]
        {
          ratingCode: helpers.ratingType.condition,
          description:
            'Did the tenant leave the property in reasonable condition?',
          questionType: helpers.QuestionType.Boolean,
          answerValue: '',
          isPrivate: true,
        },
        {
          ratingCode: helpers.ratingType.termination,
          description:
            'Did or do you expect the tenant to terminate the tenancy?',
          questionType: helpers.QuestionType.Boolean,
          answerValue: '',
          isPrivate: true,
        },
        {
          ratingCode: helpers.ratingType.unpaidAmount,
          description:
            'Did or do you expect the bond claim to be fully refunded or paid in full?',
          questionType: helpers.QuestionType.Boolean,
          answerValue: '',
          isPrivate: true,
        },
      ],
      recommendation: {
        ratingCode: helpers.ratingType.recommendation,
        description: 'Would you rent to this tenant again?',
        questionType: helpers.QuestionType.Boolean,
        answerValue: '',
        isPrivate: true,
      },
      bondDeduction: {
        ratingCode: helpers.ratingType.bondDeduction,
        description: 'Were there deductions to the bond?',
        questionType: helpers.QuestionType.Boolean,
        answerValue: '',
        isPrivate: true,
        comment: '',
      },
      rentalAmount: {
        rent: '',
        frequencyType: 0,
        isPrivate: true,
      },
      errors: {
        positiveComment: '',
        feedback: '',
        rentalAmount: '',
        bondDeduction: '',

        namedOnLease: '',
        isRentalAmount: '',
        tenancyTerm: '',
        rentOnTime: '',
      },
      isTenancyPrivateEnabled: true,
      isQuickReferencePrivateEnabled: true,
      startDate: '',
      endDate: '',
      term: '',
      alreadyKnowEndDate: false,
      errorForOverall: '',
      newStartDate: '',
      newEndDate: '',
      newTerm: '',
      disclosureDocument: {},
      unsavedChanges: false,
      nominatedRent: '',
      submitSpinner: false,
      isConfirmBeforeSubmitModalOpen: false,
      answers: {},
      isFormForTenancyLedger: false,
      createAnAccount: true,
      referenceId: '',
      secretGUID: '',
      createLiteAccount: true,
      reviewFetchLoadingStates: createInitialLoadingState(),
      showSubmitSection: true,
      refereeFirstName: '',
      refereeLastName: '',
      showCompletedAlert: false,
      rentalReferenceSubmittedAt: '',
      isFromRRInbox: false,
      isRRToLiteProcess: false,
      showConnectTeamModal: false,
      isFromRentalApplication: false,

      declineReasonModalConfig: {
        isOpened: false,
        onDecline: null,
      },
    }

    this.quickReferenceRef = createRef()
    this.rentOnTimeRef = createRef()

    this.reviewFetchLoadingStateUtils = createLoadingStateUtils((state) =>
      this.setState({
        reviewFetchLoadingStates: state,
      }),
    )

    const setAnswersState = (fn) => {
      this.setState(({ answers }) => {
        return {
          answers: fn(answers),
        }
      })
    }

    const getAnswersState = () => this.state.answers

    this.props.initializeQuestionsControls(getAnswersState, setAnswersState)
  }

  componentDidMount() {
    $('#index').addClass('no-navbar-menu')
    const { RentalHistory } = this.props

    const isRRToLite = isRRToLiteURL()
    if (isRRToLite) {
      this.setState({
        createAnAccount: false,
        createLiteAccount: false,
        isRRToLiteProcess: isRRToLiteURL(),
      })
    }

    let { match = {} } = this.props
    let { params = {} } = match
    let { referenceId = '', secretGUID = '', shortCode = '' } = params

    if (!referenceId) {
      const { ratingGUID, secret } = this.props
      referenceId = ratingGUID
      secretGUID = secret
      $('#index').removeClass('no-navbar-menu')
    }

    const { managerType } = RentalHistory
    if (
      !shortCode &&
      (!(this.props.RentalHistory && this.props.RentalHistory.guidID) ||
        !isRRToLiteURL())
    ) {
      const curQueries = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      })
      this.setState({
        applicationId: curQueries.applicationId,
        applicantId: curQueries.applicantId,
        isFromRentalApplication:
          !!curQueries.applicantId && !!curQueries.applicationId,
        isFromRRInbox: !!curQueries.fromRRInbox,
      })
      if (curQueries && curQueries.submitTenancyLedger) {
        this.setState({
          isFormForTenancyLedger: curQueries.submitTenancyLedger === 'true',
        })
      }

      this.setState({
        secretGUID,
        referenceId,
      })
      this.props.setRentalHistorySecret(secretGUID)
      this.reviewFetchLoadingStateUtils.startLoading()
      this.props
        .fetchRentalHistoryReviewWithCatchableError(referenceId, secretGUID)
        .then(() => {
          this.reviewFetchLoadingStateUtils.markDoneSuccessfully()
        })
        .catch((err) => this.reviewFetchLoadingStateUtils.setError(err))
      if (managerType && managerType !== MANAGER_TYPE.agent) {
        this.setState({
          createAnAccount: false,
          createLiteAccount: false,
        })
      }
    }

    if (shortCode) {
      this.props.fetchRentalHistoryReviewByShortCode(shortCode)
    }

    if (this.props.RentalHistory && this.props.RentalHistory.guidID) {
      const {
        rate,
        startDate = '',
        endDate = '',
        guidID,
        secret,
      } = this.props.RentalHistory
      if (guidID && secret) {
        this.setState({
          referenceId: guidID,
          secretGUID: secret,
        })
      }
      const { disclosureURL } = this.props.renter
      const disclosureDocument = {
        url: disclosureURL,
        name: 'Disclosure_authority.pdf',
      }
      const term = getTerm(startDate, endDate)
      this.setState({
        rentalAmount: {
          ...this.state.rentalAmount,
          rent: rate,
        },
        startDate: moment(startDate).format('DD MMM YYYY'),
        endDate: endDate ? moment(endDate).format('DD MMM YYYY') : 'Present',
        term: term.toString(),
        disclosureDocument,
        nominatedRent: rate,
      })
    }
    this.populateAnswersIfPresent()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { RentalHistory = {} } = this.props
    if (
      !RentalHistory.guidID &&
      nextProps.RentalHistory &&
      nextProps.RentalHistory.guidID
    ) {
      const { rate, startDate = '', endDate = '' } = nextProps.RentalHistory
      const { disclosureURL } = nextProps.renter
      const disclosureDocument = {
        url: disclosureURL,
        name: 'Disclosure_authority.pdf',
      }
      const term = getTerm(startDate, endDate)
      const { tenancyQuestions } = this.state
      this.setState({
        rentalAmount: {
          ...this.state.rentalAmount,
          rent: rate,
        },
        startDate: moment(startDate).format('DD MMM YYYY'),
        endDate: endDate ? moment(endDate).format('DD MMM YYYY') : 'Present',
        term: term.toString(),
        disclosureDocument,
        nominatedRent: rate,
        tenancyQuestions,
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { answers } = this.state
    const { RentalHistory, currentUser, rentalHistoryReview } = this.props

    if (
      !Object.keys(answers).length &&
      this.props.RentalHistory &&
      this.props.RentalHistory.referenceSetVersion ===
        ratingVersions.march2021.ref
    ) {
      this.initializeMarch2021Answers()
    }
    if (RentalHistory !== prevProps.RentalHistory) {
      const {
        liteAccountEligibility = LITE_ACCOUNT_NOT_ELIGIBLE,
        metaPublic = {},
      } = RentalHistory || {}
      let eligibilityToTake = liteAccountEligibility
      if (!!metaPublic && metaPublic?.liteAccountEligibility) {
        eligibilityToTake = metaPublic?.liteAccountEligibility
      }
      const loggedIn = !!(currentUser && currentUser.isVerified)
      if (
        this.props.RentalHistory &&
        this.props.RentalHistory !== prevProps.RentalHistory
      ) {
        const { guidID, secret, managerRated } = this.props.RentalHistory
        this.setState({
          referenceId: guidID,
          secretGUID: secret,
        })
        if (eligibilityToTake !== LITE_ACCOUNT_ELIGIBLE || loggedIn) {
          this.setState({
            createLiteAccount: false,
            createAnAccount: false,
          })
        }

        // To check metrics for RR page and lite accounts,
        // we are adding the extra string in the URL
        const hasRRInURL = textHelpers.hasRRUrlStrInPathname()
        if (
          (eligibilityToTake === LITE_ACCOUNT_JULY_2022_FLOW ||
            eligibilityToTake === LITE_ACCOUNT_JUNE_2023_FLOW) &&
          !hasRRInURL &&
          !managerRated
        ) {
          history.push(
            helpers.urlTo('liteAccountRRPage', {
              ratingGUID: guidID,
              secret,
              createLiteAccount: true,
              submitTenancyLedger: false,
              guest: isRRToLiteGuest(),
              liteAccountEligibility: eligibilityToTake,
            }),
          )
        }
      }
    }
    if (
      String(this.props.rentalHistoryReview?.Rating) !==
      String(prevProps.rentalHistoryReview?.Rating)
    ) {
      this.populateAnswersIfPresent()
    }
  }

  componentWillUnmount() {
    $('#index').removeClass('no-navbar-menu')
  }

  populateAnswersIfPresent = () => {
    const { rentalHistoryReview = {} } = this.props
    let { Rating = [], RentalHistory = {} } = rentalHistoryReview
    const { rate, startDate, endDate } = RentalHistory
    const checkIfRentalAmountPresent = Rating.find(
      (rating) => rating.code === march2021Questions.rentalamount.id,
    )
    const checkIfRentalTermIsPresent = Rating.find(
      (rating) => rating.code === march2021Questions.tenancyterm.id,
    )
    const term = getTerm(startDate, endDate)

    if (
      (checkIfRentalAmountPresent &&
        Object.keys(checkIfRentalAmountPresent).length) ||
      (checkIfRentalTermIsPresent &&
        Object.keys(checkIfRentalTermIsPresent).length)
    ) {
      const { answerValue: answerValueAmount } = checkIfRentalAmountPresent
      const { answerValue: answerValueTerm } = checkIfRentalTermIsPresent
      this.setState({
        ...this.state,
        isRentCorrect: Number(answerValueAmount) === rate ? Yes : No,
        isTermCorrect: Number(answerValueTerm) === term ? Yes : No,
      })
    }

    const namedOnLeaseRating =
      Rating &&
      Rating.find(
        (question) => question.code === march2021Questions.namedonlease.id,
      )

    const {
      refereeFirstName = '',
      refereeLastName = '',
      createdAt = '',
    } = namedOnLeaseRating || {}
    let answersCopy = {}
    if (Rating && Rating.length) {
      Rating.forEach((rating) => {
        answersCopy = {
          ...answersCopy,
          [rating.code]: {
            answerValue: rating.answerValue,
            questionType: rating.questionType,
            ratingCode: rating.code,
          },
        }
      })
      this.setState({
        answers: answersCopy,
        showSubmitSection: false,
        refereeFirstName,
        refereeLastName,
        showCompletedAlert: true,
        rentalReferenceSubmittedAt: dateTimeHelpers.formatTime(
          createdAt,
          dateTimeHelpers.STANDARD_DATETIME,
        ),
      })
    }
  }

  onAddLegerFile() {
    let {
      match: {
        params: { referenceId = '', secretGUID = '', shortCode = '' },
      },
      addRentalLedger,
    } = this.props
    if (shortCode) {
      referenceId = this.state.referenceId
      secretGUID = this.state.secretGUID
    }
    const documentType = helpers.DocumentTypes.ArbitraryAttachment
    addRentalLedger(referenceId, secretGUID, documentType)
  }

  onChangeMultiSelect = (field) => {
    return (val) => {
      const updatedField = this.state[field]
      const targetOption = updatedField.find((option) => option.value === val)
      updatedField.forEach((option) => {
        if (option.value !== val) {
          option.picked = false
        }
      })
      targetOption.picked = !targetOption.picked
      this.setState({
        [field]: updatedField,
        unsavedChanges: true,
      })
      if (field === helpers.ratingType.overall) {
        this.setState({ errorForOverall: '' })
      }
    }
  }

  onChangeRadioGroup = (radioGroupID) => {
    return (event) => {
      const { target } = event
      const { id } = target
      if (this.state[radioGroupID] === id) {
        this.setState({
          [radioGroupID]: '',
        })
      } else {
        this.setState({
          [radioGroupID]: id,
          unsavedChanges: true,
        })
      }
    }
  }

  onClickSubmit = (type) => {
    const {
      isFromRentalApplication,
      applicantId = '',
      applicationId = '',
      createAnAccount,
    } = this.state
    let {
      manualSubmitRentalReference,
      submitRentalReference,
      addNote,
      RentalHistory: { referenceSetVersion, managerType },
      selfRegistration,
    } = this.props

    let { match = {} } = this.props
    let { params = {} } = match
    let { referenceId = '', secretGUID = '', shortCode = '' } = params

    if (!referenceId || !secretGUID) {
      const { RentalHistory, secretGUID: secretGUIDFromProps } = this.props
      const { guidID } = RentalHistory
      referenceId = guidID
      secretGUID = secretGUIDFromProps
    }
    if (shortCode) {
      referenceId = this.state.referenceId
      secretGUID = this.state.secretGUID
    }
    const addNotePayload = {
      type: helpers.QuestionType.Int,
      target: 13,
      content: '',
    }

    let payload
    if (referenceSetVersion < ratingVersions.march2021.ref) {
      payload = this.prepareOldVersionPayload(type === 'forceToSubmit')
    } else if (referenceSetVersion === ratingVersions.march2021.ref) {
      payload = this.prepareMarch2021Payload()
    }

    return (
      payload &&
      this.handleSubmit(
        isFromRentalApplication,
        manualSubmitRentalReference,
        referenceId,
        { ...payload, referenceSetVersion },
        addNote,
        applicationId,
        applicantId,
        addNotePayload,
        submitRentalReference,
        secretGUID,
        managerType,
        createAnAccount,
        selfRegistration,
      )
    )
  }

  onDeclineClick = () => {
    const { rentalHistoryReferenceDecline } = this.props

    let { match = {} } = this.props
    let { params = {} } = match
    let { referenceId = '', secretGUID = '', shortCode = '' } = params

    if (!referenceId || !secretGUID) {
      const { RentalHistory, secretGUID: secretGUIDFromProps } = this.props
      const { guidID } = RentalHistory
      referenceId = guidID
      secretGUID = secretGUIDFromProps
    }

    if (shortCode) {
      referenceId = this.state.referenceId
      secretGUID = this.state.secretGUID
    }
    const { applicantId = '', applicationId = '' } = this.state
    const isFromRentalApplication = applicantId && applicationId
    const isHistoryRating = !!secretGUID
    if (isFromRentalApplication) {
      history.goBack()
    } else if (isHistoryRating) {
      return this.setState({
        declineReasonModalConfig: {
          isOpened: true,
          onDecline: (declinePayload) =>
            rentalHistoryReferenceDecline(referenceId, declinePayload).then(
              () => this.closeDeclineReasonModal(true),
            ),
        },
      })
    }
    history.push('/register')
  }

  onLedgerAdded = (referenceId, secretGUID, documentType) => {
    const { addRentalLedger, manualAddRentalLedger } = this.props
    const { isFromRentalApplication } = this.state
    if (isFromRentalApplication) {
      manualAddRentalLedger(referenceId, secretGUID, documentType).catch(
        (error) => snugNotifier.error(error),
      )
    } else {
      addRentalLedger(referenceId, secretGUID, documentType).catch((error) =>
        snugNotifier.error(error),
      )
    }
  }

  onLedgerDeleted = (referenceId, secretGUID, guidID) => {
    const { removeRentalLedger, manualRemoveRentalLedger } = this.props
    const { isFromRentalApplication } = this.state
    if (isFromRentalApplication) {
      manualRemoveRentalLedger(referenceId, secretGUID, guidID)
    } else {
      removeRentalLedger(referenceId, secretGUID, guidID)
    }
  }

  onOverallYesorNoChange = (field) => {
    return (event) => {
      const { target } = event
      const { id } = target
      const updateStatus = id === Yes ? 'true' : 'false'
      this.setState({
        [field]: {
          ...this.state[field],
          answerValue: updateStatus,
        },
        unsavedChanges: true,
      })
    }
  }

  onTenancyQuestionChange = (field) => {
    return (event) => {
      const { target } = event
      const { id } = target
      const updateStatus = id === Yes ? 'true' : 'false'
      const { tenancyQuestions } = this.state
      tenancyQuestions.forEach((question) => {
        if (question.ratingCode === field) {
          if (question.answerValue === updateStatus) {
            question.answerValue = ''
          } else {
            question.answerValue = updateStatus
          }
        }
      })
      this.setState({
        tenancyQuestions,
        unsavedChanges: true,
      })
    }
  }

  addCorrectedRentToPayload(
    isRentCorrect,
    rentalAmount,
    nominatedRent,
    payload,
  ) {
    if (isRentCorrect === Yes) {
      rentalAmount.rent = nominatedRent
      rentalAmount.frequencyType = 0
      payload.rentalAmount = rentalAmount
    } else if (isRentCorrect === No) {
      payload.rentalAmount = rentalAmount
    }
  }

  buildMarch2021Questions() {
    const { answers, isTenancyPrivateEnabled, showSubmitSection } = this.state
    const {
      updateAnswer,
      removeQuestion,
      addQuestion,
      buildMultiSelectQuestion,
      buildSimpleBooleanQuestion,
      buildCommentsQuestion,
      renter,
    } = this.props
    const {
      lastinspecteddate,
      tenanthaspets,
      petsauthorised,
      petsauthorisedcomments,
      petsdamage,
      petsdamagecomments,

      rentontime,
      reasonablecondition,
      reasonableconditioncomments,
      respectneighbours,
      respectneighbourscomments,

      referencecontactpreferences,
    } = march2021Questions
    const { firstName: renterFirstName = '' } = renter || {}

    const lastInspectedDateQ = answers[lastinspecteddate.id] && (
      <div className="mb30">
        <Text.Strong text={lastinspecteddate.description()} />
        <Form.InputPastDate
          label="Last Inspected Date"
          readOnly={false}
          componentClass="date-picker-130"
          viewMode="days"
          onChange={({ value }) => updateAnswer(lastinspecteddate, value)}
        />
      </div>
    )

    const tenantPetsAnswerArr = (
      answers[tenanthaspets.id]?.answerValue || ''
    ).split(',')
    const tenantPetsOptions = this.state.tenantPetsOptions.map((o) => ({
      ...o,
      picked: tenantPetsAnswerArr.includes(o.value),
    }))

    const tenantPetsQ =
      answers[tenanthaspets.id] &&
      buildMultiSelectQuestion({
        options: tenantPetsOptions,
        questionDef: tenanthaspets,
        enableEdit: showSubmitSection,
        onChange: (value) => {
          const selectedOptions = updatedTenantPetsArrOnChange(
            tenantPetsAnswerArr,
            value,
          )
          updateAnswer(tenanthaspets, selectedOptions.join(','))
          const hasPets = selectedOptions.some((selectedOpt) =>
            hasPetsOptions.includes(selectedOpt),
          )
          if (hasPets) {
            ;[petsauthorised, petsdamage].forEach((q) => addQuestion(q))
          } else {
            ;[
              petsauthorised,
              petsauthorisedcomments,
              petsdamage,
              petsdamagecomments,
            ].forEach((question) => removeQuestion(question))
          }
        },
        renterName: renterFirstName,
      })

    const petsAuthorizedQ =
      answers[petsauthorised.id] &&
      buildSimpleBooleanQuestion({
        questionDef: petsauthorised,
        onTrueChecked: () => {
          updateAnswer(petsauthorised, trueStr)
          addQuestion(petsauthorisedcomments)
        },
        onFalseChecked: () => {
          updateAnswer(petsauthorised, falseStr)
          removeQuestion(petsauthorisedcomments)
        },
        enableEdit: showSubmitSection,
        renterName: renterFirstName,
      })

    const petsAuthorizedComments =
      answers[petsauthorisedcomments.id] &&
      buildCommentsQuestion({
        questionDef: petsauthorisedcomments,
        readOnly: !showSubmitSection,
      })

    const petsDamageOptions = this.state.petsDamageOptions.map((o) => ({
      ...o,
      picked:
        answers[petsdamage.id] &&
        answers[petsdamage.id].answerValue === o.value,
    }))

    const petsDamageQ = answers[petsdamage.id] && (
      <StyledPetsDamageQuestion selection={answers.petsdamage.answerValue}>
        {buildMultiSelectQuestion({
          questionDef: petsdamage,
          options: petsDamageOptions,
          enableEdit: showSubmitSection,
          onChange: (value) => {
            updateAnswer(petsdamage, value)
            if (value === Yes) {
              addQuestion(petsdamagecomments)
            } else {
              removeQuestion(petsdamagecomments)
            }
          },
        })}
      </StyledPetsDamageQuestion>
    )

    const petsDamageComments =
      answers[petsdamagecomments.id] &&
      buildCommentsQuestion({
        questionDef: petsdamagecomments,
        readOnly: !showSubmitSection,
      })

    const reasonableConditionQ =
      answers[reasonablecondition.id] &&
      buildSimpleBooleanQuestion({
        questionDef: reasonablecondition,
        onTrueChecked: () => {
          updateAnswer(reasonablecondition, trueStr)
          removeQuestion(reasonableconditioncomments)
        },
        onFalseChecked: () => {
          updateAnswer(reasonablecondition, falseStr)
          addQuestion(reasonableconditioncomments)
        },
        enableEdit: showSubmitSection,
        renterName: renterFirstName,
      })

    const reasonableConditionCommentsQ =
      answers[reasonableconditioncomments.id] &&
      buildCommentsQuestion({
        questionDef: reasonableconditioncomments,
        readOnly: !showSubmitSection,
        renterName: renterFirstName,
      })

    const respectNeighboursQ =
      answers[respectneighbours.id] &&
      buildSimpleBooleanQuestion({
        questionDef: respectneighbours,
        onTrueChecked: () => {
          updateAnswer(respectneighbours, trueStr)
          removeQuestion(respectneighbourscomments)
        },
        onFalseChecked: () => {
          updateAnswer(respectneighbours, falseStr)
          addQuestion(respectneighbourscomments)
        },
        enableEdit: showSubmitSection,
        renterName: renterFirstName,
      })

    const respectNeighboursCommentsQ =
      answers[respectneighbourscomments.id] &&
      buildCommentsQuestion({
        questionDef: respectneighbourscomments,
        readOnly: !showSubmitSection,
      })

    const referenceContactPreferencesOptions =
      this.state.referenceContactPreferenceSelections.map((o) => ({
        ...o,
        picked:
          answers[referencecontactpreferences.id] &&
          answers[referencecontactpreferences.id].answerValue === o.value,
      }))
    const referenceContactPreferencesQ = answers[
      referencecontactpreferences.id
    ] && (
      <div className="mb30">
        <Text.Strong text={referencecontactpreferences.description()} />
        <Form.MultiSelect
          options={referenceContactPreferencesOptions}
          className="p0"
          onChange={(value) => updateAnswer(referencecontactpreferences, value)}
          bottomSpacingClass="pb0"
          isEditable={showSubmitSection}
        />
      </div>
    )

    return (
      <div>
        <Display.SectionContentContainer showBottomBorder={false}>
          <Display.ContainerHeader
            text="Supporting questions"
            isEditable={false}
            componentClass="mb10-mobile mb24"
          />
          Make renting better with transparent, factual and fair responses
          visible to the renter.
        </Display.SectionContentContainer>

        <Display.SectionContentContainer showBottomBorder={false}>
          <Box mb={`${theme.space[6]}px`}>
            <PrivacySelector
              isPrivateEnabled={isTenancyPrivateEnabled}
              onCheckBoxChange={this.toggleTenancyPrivacyEnabled}
            />
          </Box>
          {lastInspectedDateQ}
          {tenantPetsQ}
          {petsAuthorizedQ}
          {petsAuthorizedComments}
          {petsDamageQ}
          {petsDamageComments}
          {reasonableConditionQ}
          {reasonableConditionCommentsQ}
          {respectNeighboursQ}
          {respectNeighboursCommentsQ}
        </Display.SectionContentContainer>
        {showSubmitSection && (
          <>
            <Display.SectionContentContainer showBottomBorder={false}>
              <Display.ContainerHeader
                text="Your contact preferences"
                isEditable={false}
                componentClass="mb10-mobile mb10"
              />
              <p>
                Please select your preferences in relation to being contacted
                about this reference.
              </p>
              {referenceContactPreferencesQ}
            </Display.SectionContentContainer>
            <Display.SectionContentContainer
              showBottomBorder={false}
              componentClass="pb0 fs18 lh16"
            >
              I warrant the information provided is accurate and reasonably
              represents the tenancy. I acknowledge that the tenant(s) may
              receive a copy of some or all information I have provided, as
              required by law, and has the right to dispute that information.
            </Display.SectionContentContainer>
          </>
        )}
      </div>
    )
  }

  renderQuickReferenceQuestionsSection = () => {
    const {
      isFormForTenancyLedger,
      answers,
      isRentCorrect,
      isTermCorrect,
      errors,
      isQuickReferencePrivateEnabled,
      rentalAmount,
      startDate,
      alreadyKnowEndDate,
      endDate,
      term,
      newStartDate,
      newEndDate,
      nominatedRent,
      showSubmitSection,
      isRRToLiteProcess,
    } = this.state

    const {
      buildSimpleBooleanQuestion,
      buildCommentsQuestion,
      RentalHistory = {},
      showRentalLedgerSpinner,
      updateAnswer,
      addQuestion,
      removeQuestion,
      renter = {},
    } = this.props
    let { match = {} } = this.props
    let { params = {} } = match
    let { referenceId = '', secretGUID = '', shortCode = '' } = params
    if (!referenceId || !secretGUID) {
      const { RentalHistory, secretGUID: secretGUIDFromProps } = this.props
      const { guidID } = RentalHistory
      referenceId = guidID
      secretGUID = secretGUIDFromProps
    }
    if (shortCode) {
      referenceId = this.state.referenceId
      secretGUID = this.state.secretGUID
    }
    const { firstName: renterFirstName = '' } = renter || {}
    const {
      ledger = [],
      referenceSetVersion,
      propertyArrangement,
    } = RentalHistory
    const march2021Version =
      referenceSetVersion === ratingVersions.march2021.ref
    const {
      namedonlease,
      areasforconcerncomments,
      positivecomments,
      overall,
      rentontime,
      rentagain,
      rentagaincomments,
    } = march2021Questions
    const namedOnLeaseQ =
      answers[namedonlease.id] &&
      buildSimpleBooleanQuestion({
        questionDef: namedonlease,
        enableEdit: showSubmitSection,
        required: true,
        error: errors.namedOnLease,
        renterName: renterFirstName,
      })

    const areasForConcernsCommentsQ =
      answers[areasforconcerncomments.id] &&
      buildCommentsQuestion({
        questionDef: areasforconcerncomments,
        readOnly: !showSubmitSection,
      })

    const positiveCommentsQ =
      answers[positivecomments.id] &&
      buildCommentsQuestion({
        questionDef: positivecomments,
        readOnly: !showSubmitSection,
      })

    const overallOptions = this.state.overall.map((o) => ({
      ...o,
      picked:
        answers[overall.id] && answers[overall.id].answerValue === o.value,
    }))
    const overallQ = answers[overall.id] && (
      <>
        <Text.Strong text={overall.description(renterFirstName)} />
        <Form.MultiSelect
          options={overallOptions}
          className="p0"
          onChange={(value) => updateAnswer(overall, value)}
          bottomSpacingClass="pb0"
          isEditable={showSubmitSection}
        />
      </>
    )
    const rentOnTimeOptions = this.state.rentOnTimeSelections.map((o) => ({
      ...o,
      picked:
        answers[rentontime.id] &&
        answers[rentontime.id].answerValue === o.value,
    }))
    const rentOnTimeQ = answers[rentontime.id] && (
      <div className="mb30" ref={this.rentOnTimeRef}>
        <LabelWrapper required={true}>
          <Text.Strong text={rentontime.description(renterFirstName)} />
        </LabelWrapper>
        <Form.MultiSelect
          error={errors.rentOnTime}
          options={rentOnTimeOptions}
          className="p0"
          onChange={(value) => updateAnswer(rentontime, value)}
          bottomSpacingClass="pb0"
          isEditable={showSubmitSection}
        />
      </div>
    )
    const rentAgainOptions = this.state.rentAgainSelections.map((o) => ({
      ...o,
      picked:
        answers[rentagain.id] && answers[rentagain.id].answerValue === o.value,
    }))
    const rentAgainQ = answers[rentagain.id] && (
      <div className="mb30">
        <Text.Strong text={rentagain.description(renterFirstName)} />
        <Form.MultiSelect
          options={rentAgainOptions}
          className="p0"
          onChange={(value) => {
            updateAnswer(rentagain, value)
            value !== march2021Questions.rentagain.options.value
              ? addQuestion(rentagaincomments)
              : removeQuestion(rentagaincomments)
          }}
          bottomSpacingClass="pb0"
          isEditable={showSubmitSection}
        />
      </div>
    )

    const rentAgainCommentsQ =
      answers[rentagaincomments.id] &&
      buildCommentsQuestion({
        questionDef: rentagaincomments,
        readOnly: !showSubmitSection,
        renterName: renterFirstName,
      })
    return (
      <Box
        my={`${theme.space[6]}px`}
        p={`${theme.space[6]}px`}
        bg={theme.colors.paleBlue}
      >
        {!isFormForTenancyLedger && (
          <Box ref={this.quickReferenceRef}>
            {march2021Version && (
              <Display.ContainerHeader
                text="Quick Reference"
                isEditable={false}
                componentClass="mb10-mobile mb24"
              />
            )}
            {namedOnLeaseQ}
            <div className="mb30 mt30-mobile">
              <Form.YesOrNoRadioGroup
                id="Rent"
                required={true}
                label={`The rent was $${nominatedRent} p/w`}
                error={errors.isRentalAmount}
              >
                <Form.YesOrNoCheckbox
                  id={Yes}
                  label={Yes}
                  checked={isRentCorrect === Yes}
                  onChange={this.onChangeRadioGroup('isRentCorrect')}
                  componentClassName="yes left"
                  enableEdit={showSubmitSection}
                />
                <Form.YesOrNoCheckbox
                  id={No}
                  label={No}
                  checked={isRentCorrect === No}
                  onChange={this.onChangeRadioGroup('isRentCorrect')}
                  componentClassName="no right"
                  enableEdit={showSubmitSection}
                />
              </Form.YesOrNoRadioGroup>
              {isRentCorrect === No && (
                <div className="display-flex mt15">
                  <span className="dollor-sign">$</span>
                  <Form.InputNumber
                    value={rentalAmount.rent}
                    error={errors.rentalAmount}
                    onChange={this.updateRentalInput('rent')}
                    inputClass="width100"
                    id="rent"
                    label="Rent"
                  />
                  <Form.Dropdown
                    componentClass="ml15"
                    label="Period"
                    value={rentalAmount.frequencyType}
                    options={rentDurationTypeToText}
                    onChange={this.updateRentalInput('frequencyType')}
                    id="period"
                    valueType="number"
                    inputClass="width100"
                  />
                </div>
              )}
            </div>{' '}
          </Box>
        )}
        {!isFormForTenancyLedger && (
          <div className="mb30">
            <Form.YesOrNoRadioGroup
              id="Rent"
              required={true}
              label={`The term was ${term} months: ${startDate} - ${endDate}`}
              error={errors.tenancyTerm}
            >
              <Form.YesOrNoCheckbox
                id={Yes}
                label={Yes}
                checked={isTermCorrect === Yes}
                onChange={this.onChangeRadioGroup('isTermCorrect')}
                componentClassName="yes left"
                enableEdit={showSubmitSection}
              />
              <Form.YesOrNoCheckbox
                id={No}
                label={No}
                checked={isTermCorrect === No}
                onChange={this.onChangeRadioGroup('isTermCorrect')}
                componentClassName="no right"
                enableEdit={showSubmitSection}
              />
            </Form.YesOrNoRadioGroup>
            {isTermCorrect === No && (
              <div className="mt15">
                <Form.InputDate
                  label="Start Date"
                  value={newStartDate}
                  onChange={this.updateDateInput('newStartDate')}
                  id="startDate"
                  containerClassName="width100"
                  componentClass="rental-date-wrapper"
                  isValidDate={helpers.isDateBeforeTargetDate(
                    alreadyKnowEndDate ? newEndDate : '',
                  )}
                  readOnly={false}
                  error={this.state.errors.newStartDate}
                />
                <Form.CheckBoxGeneral
                  label="Lease end date is known"
                  checked={alreadyKnowEndDate}
                  onChange={this.toggle('alreadyKnowEndDate')}
                />
                {alreadyKnowEndDate && (
                  <Form.InputDate
                    label="End Date"
                    value={newEndDate}
                    onChange={this.updateDateInput('newEndDate')}
                    id="endDate"
                    containerClassName="width100"
                    componentClass="rental-date-wrapper"
                    isValidDate={helpers.validateEndDate(newStartDate)}
                    readOnly={false}
                    error={this.state.errors.newEndDate}
                  />
                )}
              </div>
            )}
          </div>
        )}
        <div className="mb30">
          <Flex mb={3}>
            <Text.Strong
              text="Upload rental ledger"
              textClass="fs18 fw700"
              showBottomMargin={false}
              componentClass="mb10"
            />
          </Flex>
          <Box mb={5}>
            Please take a moment to upload a rental ledger (if available) which
            is important and appreciated.
          </Box>

          <Display.DocumentListItemSecondVersion
            documents={ledger}
            addDocument={(documentType) =>
              this.onLedgerAdded(referenceId, secretGUID, documentType)
            }
            deleteDocument={(guidID) =>
              this.onLedgerDeleted(referenceId, secretGUID, guidID)
            }
            documentType={helpers.DocumentTypes.ArbitraryAttachment}
            label="(max 10mb - pdf, doc, xlsx, png, jpg, jpeg)"
            labelFontWeight={theme.fontWeights[2]}
            hideInstructions
            fileBodyClass="max-width-150px-mobile"
            showRentalLedgerSpinner={showRentalLedgerSpinner}
            textButton={true}
            textButtonLabel="Upload Ledger"
            docBadgeRenderer={(ledger) =>
              ledger.addedByRenter && (
                <Badge
                  text="Renter upload"
                  variant="darkGray"
                  tooltipId={ledger.guidID}
                  tooltipText="Uploaded by Renter in their Rental Profile"
                />
              )
            }
          />
        </div>

        <Box my={`${theme.space[4]}px`}>
          <PrivacySelector
            isPrivateEnabled={isQuickReferencePrivateEnabled}
            onCheckBoxChange={this.toggleQuickReferencePrivateEnabled}
          />
        </Box>
        <Box my={`${theme.space[6]}px`}>{rentOnTimeQ}</Box>
        <Box my={`${theme.space[6]}px`}>{rentAgainQ}</Box>
        <Box my={`${theme.space[6]}px`}>{rentAgainCommentsQ}</Box>
        <Box my={`${theme.space[6]}px`}>{positiveCommentsQ}</Box>
        <Box my={`${theme.space[6]}px`}>{overallQ}</Box>

        <Box>
          {/* A forced private question. PrivacySelector is disabled, it's just to show it's private */}
          <PrivacySelector isPrivateEnabled={true} disabled={true} />
        </Box>
        <Box my={`${theme.space[6]}px`}>{areasForConcernsCommentsQ}</Box>
      </Box>
    )
  }

  checkAndAddTermToPayload(
    isTermCorrect,
    term,
    newStartDate,
    alreadyKnowEndDate,
    newEndDate,
    payload,
    tenancyTermCategoryCode,
  ) {
    if (isTermCorrect === Yes) {
      const termRating = constructRating(
        tenancyTermCategoryCode,
        term,
        helpers.QuestionType.Text,
      )
      payload.rentalReferenceRatings.push(termRating)
      return true
    } else if (isTermCorrect === No) {
      if (!newStartDate) {
        snugNotifier.error('Start date cannot be empty')
        return false
      }
      if (!helpers.validateDateFormat(newStartDate)) {
        snugNotifier.error('Start date is invalid')
        return false
      }
      const newTerm = alreadyKnowEndDate
        ? getTerm(newStartDate, newEndDate)
        : getTerm(newStartDate, '')
      if (newTerm < 0) {
        snugNotifier.error('End date must be after than start date')
        return false
      }
      if (
        alreadyKnowEndDate &&
        newEndDate &&
        !helpers.validateDateFormat(newEndDate)
      ) {
        snugNotifier.error('End date is invalid')
        return false
      }
      const termRating = constructRating(
        tenancyTermCategoryCode,
        newTerm.toString(),
        helpers.QuestionType.Text,
      )
      payload.rentalReferenceRatings.push(termRating)
      return true
    }
    return true
  }

  handleSubmit = (
    isFromRentalApplication,
    manualSubmitRentalReference,
    referenceId,
    payload,
    addNote,
    applicationId,
    applicantId,
    addNotePayload,
    submitRentalReference,
    secretGUID,
    managerType,
    createAnAccount,
    selfRegistration,
  ) => {
    const continueAsGuest = isRRToLiteGuest()
    const { redirectToRRTable } = this.props
    this.setState(
      {
        unsavedChanges: false,
        submitSpinner: true,
      },
      () => {
        const curQueries = qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        })
        const fromRentalApplication =
          !!curQueries.applicantId && !!curQueries.applicationId
        if (fromRentalApplication) {
          manualSubmitRentalReference(referenceId, payload)
            .then(() => {
              snugNotifier.success(
                'Rental reference has been successfully submitted',
              )
              addNote(applicationId, applicantId, addNotePayload)
            })
            .then(() => {
              history.goBack()
              this.setState({
                submitSpinner: false,
                isConfirmBeforeSubmitModalOpen: false,
              })
            })
            .catch((error) => {
              snugNotifier.error(error)
              this.setState({ submitSpinner: false })
            })
        } else {
          submitRentalReference(referenceId, payload, secretGUID)
            .then(() => {
              const {
                toggleCompletedReferenceModal,
                toggleConnectTeamMemberModal,
              } = this.props
              if (isRRToLiteURL()) {
                // show modal
                if (continueAsGuest) {
                  toggleCompletedReferenceModal()
                  toggleConnectTeamMemberModal()
                  return
                } else {
                  redirectToRRTable()
                  return
                }
              }
              if (createAnAccount && managerType === MANAGER_TYPE.agent) {
                const referee = this.props.RentalHistory
                const payload = {
                  name: referee.agencyName,
                  addressHistoryGUID: referenceId,
                  agencyUserInvites: [
                    {
                      firstName: referee.firstName,
                      email: referee.email,
                      lastName: referee.lastName,
                      mobile: referee.phoneNumber,
                      isAdmin: true,
                      isFinance: false,
                      isLeasing: false,
                    },
                  ],
                  source: Sources.rentalReference,
                  meta: {
                    generateDummyData: true, // not working in current PR
                    tracking: {
                      source: 'Snug.com',
                      medium: 'leads/rental-references',
                      campaign: 'rental_references_Oct2021',
                      content: referenceId,
                    },
                  },
                }

                selfRegistration(payload)
                  .then((data) => {
                    if (!!data.userInviteLink) {
                      window.location = data.userInviteLink
                    } else {
                      history.replace(
                        helpers.urlTo('rentalReferenceCreateLiteAccount', {
                          agencyName: data.agencyName,
                          ...data.joinRequest.sentTo,
                        }),
                      )
                    }
                  })
                  .catch((error) => snugNotifier.error(error))
                  .finally(() => {
                    this.setState({ submitSpinner: false })
                  })
                return
              }
              if (createAnAccount && managerType === MANAGER_TYPE.agent) {
                history.replace(
                  helpers.urlTo('rentalReferenceCreateLiteAccount'),
                )
                return
              }

              if (managerType === MANAGER_TYPE.landlord) {
                history.replace(
                  helpers.urlTo('rentalReferenceSignupAfterSubmissionOwner'),
                )
              } else {
                history.replace(
                  helpers.urlTo('rentalReferenceSignupAfterSubmission'),
                )
              }
            })
            .catch((error) => {
              this.setState({ submitSpinner: false })
              snugNotifier.error(error)
            })
            .finally(() => {
              this.setState({ submitSpinner: false })
            })
        }
      },
    )
  }

  handleTenantLedgerSaveClick = () => {
    const { RentalHistory = {} } = this.props
    const { ledger = [] } = RentalHistory
    if (ledger.length === 0) {
      snugNotifier.error('Please attach a tenant ledger')
      return
    }
    history.replace(helpers.urlTo('rentalReferenceSignupAfterSubmission'))
  }

  initializeMarch2021Answers() {
    const questions = march2021Questions
    const { initializeRatingQuestion, RentalHistory } = this.props

    const defaultQuestions = [
      questions.namedonlease,
      questions.lastinspecteddate,
      questions.tenanthaspets,
      questions.petsdamage,

      questions.rentontime,
      questions.reasonablecondition,
      questions.respectneighbours,

      questions.areasforconcerncomments,
      questions.positivecomments,
      questions.overall,
      questions.rentagain,

      questions.referencecontactpreferences,
      questions.warranty,
    ]

    const initializedQuestions = defaultQuestions.reduce((acc, questionDef) => {
      return {
        ...acc,
        [questionDef.id]: initializeRatingQuestion(questionDef),
      }
    }, {})

    if (
      isAddressHistoryWithFamilyOrFriends(RentalHistory.propertyArrangement)
    ) {
      initializedQuestions[questions.rentontime.id].answerValue = 'Yes, always'
    }

    this.setState(() => {
      return {
        answers: initializedQuestions,
      }
    })
  }

  checkMandatoryQuestions(
    rentOnTimeAnswer,
    tenancyTermAnswer,
    isRentalAmountAnswer,
    namedOnLeaseAnswer,
  ) {
    this.setState({
      errors: {
        ...this.state.errors,
        rentOnTime: !rentOnTimeAnswer ? MANDATORY_ERROR_TEXT : '',
        tenancyTerm: !tenancyTermAnswer ? MANDATORY_ERROR_TEXT : '',
        isRentalAmount: !isRentalAmountAnswer ? MANDATORY_ERROR_TEXT : '',
        namedOnLease: !namedOnLeaseAnswer ? MANDATORY_ERROR_TEXT : '',
      },
    })
    if (!namedOnLeaseAnswer || !isRentalAmountAnswer || !tenancyTermAnswer) {
      this.quickReferenceRef.current.scrollIntoView()
    } else {
      if (!rentOnTimeAnswer) {
        this.rentOnTimeRef.current.scrollIntoView()
      }
    }
  }

  prepareMarch2021Payload() {
    const {
      isRentCorrect,
      rentalAmount,
      nominatedRent,
      answers,
      isTermCorrect,
      term,
      newStartDate,
      alreadyKnowEndDate,
      newEndDate,
      isTenancyPrivateEnabled,
      isQuickReferencePrivateEnabled,
    } = this.state

    const { namedonlease, rentontime } = march2021Questions

    let payload = {
      rentalReferenceRatings: [],
    }

    if (
      !this.checkAndAddTermToPayload(
        isTermCorrect,
        term,
        newStartDate,
        alreadyKnowEndDate,
        newEndDate,
        payload,
        march2021Questions.tenancyterm.id,
      )
    ) {
      return
    }

    this.addCorrectedRentToPayload(
      isRentCorrect,
      rentalAmount,
      nominatedRent,
      payload,
    )

    this.checkMandatoryQuestions(
      answers[rentontime.id]?.answerValue,
      isTermCorrect,
      isRentCorrect,
      answers[namedonlease.id]?.answerValue,
    )

    Object.values(answers)
      .filter((a) => a.answerValue !== null && a.answerValue !== undefined)
      .forEach((a) => {
        let aCopy = { ...a }
        if (aCopy.ratingCode in march2021PrivacyForcedQuestions)
          aCopy.isPrivate = march2021PrivacyForcedQuestions[aCopy.ratingCode]
        else {
          if (
            march2021TenancyPrivacyControlledQuestions.includes(
              aCopy.ratingCode,
            )
          )
            aCopy.isPrivate = isTenancyPrivateEnabled
          else if (
            march2021QuickReferencePrivacyControlledQuestions.includes(
              aCopy.ratingCode,
            )
          )
            aCopy.isPrivate = isQuickReferencePrivateEnabled
        }

        payload.rentalReferenceRatings.push(aCopy)
      })

    return payload
  }

  prepareOldVersionPayload(validate = false) {
    const {
      textTypeRatingPayload,
      tenancyQuestions,
      overall,
      pleasant,
      takesCare,
      recommendation,
      bondDeduction,
      isRentCorrect,
      isTermCorrect,
      rentalAmount,
      term,
      newStartDate,
      newEndDate,
      alreadyKnowEndDate,
      isTenancyPrivateEnabled,
      nominatedRent,
    } = this.state

    const rentalReferenceRatings = []
    const textTypeRatingPayloadCollection = Object.keys(textTypeRatingPayload)
    textTypeRatingPayloadCollection.forEach((option) => {
      if (textTypeRatingPayload[option].answerValue) {
        rentalReferenceRatings.push(textTypeRatingPayload[option])
      }
    })
    const overallPickOption = overall.find((option) => option.picked === true)
    if (overallPickOption) {
      const overallRating = constructRating(
        helpers.ratingType.overall,
        overallPickOption.value,
        helpers.QuestionType.Int,
      )
      rentalReferenceRatings.push(overallRating)
    } else {
      const validationMsg = 'Please provide feedback overall'
      snugNotifier.error(validationMsg)
      this.setState({
        errorForOverall: validationMsg,
      })
      return
    }
    const pleasantOption = pleasant.find((option) => option.picked === true)
    if (pleasantOption) {
      const pleasantRating = constructRating(
        helpers.ratingType.pleasant,
        pleasantOption.value,
        helpers.QuestionType.Int,
      )
      rentalReferenceRatings.push(pleasantRating)
    }
    const takesCareOption = takesCare.find((option) => option.picked === true)
    if (takesCareOption) {
      const takesCareRating = constructRating(
        helpers.ratingType.takesCare,
        takesCareOption.value,
        helpers.QuestionType.Int,
      )
      rentalReferenceRatings.push(takesCareRating)
    }
    tenancyQuestions.forEach((question) => {
      if (question.answerValue) {
        const questionCopy = { ...question }
        rentalReferenceRatings.push(questionCopy)
      }
    })

    if (recommendation.answerValue) {
      const recommendationRating = constructRating(
        helpers.ratingType.recommendation,
        recommendation.answerValue,
        helpers.QuestionType.Boolean,
      )
      rentalReferenceRatings.push(recommendationRating)
    }

    if (bondDeduction.answerValue) {
      const bondDeductionRating = constructRating(
        helpers.ratingType.bondDeduction,
        bondDeduction.answerValue,
        helpers.QuestionType.Boolean,
      )
      if (bondDeduction.answerValue === 'true' && !bondDeduction.comment) {
        const validationMsg = 'Please provide comment for bond deduction'
        snugNotifier.error(validationMsg)
        return
      }
      bondDeductionRating.comment = bondDeduction.comment
      rentalReferenceRatings.push(bondDeductionRating)
    }

    if (!isTenancyPrivateEnabled) {
      rentalReferenceRatings.forEach((rating) => {
        if (ratingCodeCollection.tenancyQuestions.includes(rating.ratingCode)) {
          rating.isPrivate = false
        }
      })
    }

    const payload = {
      rentalReferenceRatings,
    }

    if (
      !this.checkAndAddTermToPayload(
        isTermCorrect,
        term,
        newStartDate,
        alreadyKnowEndDate,
        newEndDate,
        payload,
        helpers.ratingType.tenancyTerm,
      )
    ) {
      return
    }

    this.addCorrectedRentToPayload(
      isRentCorrect,
      rentalAmount,
      nominatedRent,
      payload,
    )

    if (
      validate &&
      (pleasantOption === undefined ||
        takesCareOption === undefined ||
        isRentCorrect === '' ||
        isTermCorrect === '' ||
        bondDeduction.answerValue === '' ||
        recommendation.answerValue === '' ||
        HasAnswerValueEmptyStringInArray(tenancyQuestions))
    ) {
      this.toggleModal()
      return
    }

    return payload
  }

  toggle = (field) => {
    return (date) => {
      if (field === 'alreadyKnowEndDate') {
        this.setState({
          newEndDate: '',
        })
      }
      this.setState({
        [field]: date.value,
      })
    }
  }

  toggleModal() {
    this.setState({
      isConfirmBeforeSubmitModalOpen:
        !this.state.isConfirmBeforeSubmitModalOpen,
    })
  }

  toggleTenancyPrivacyEnabled = () => {
    const { isTenancyPrivateEnabled } = this.state
    this.setState({ isTenancyPrivateEnabled: !isTenancyPrivateEnabled })
  }

  toggleQuickReferencePrivateEnabled = () => {
    const { isQuickReferencePrivateEnabled } = this.state
    this.setState({
      isQuickReferencePrivateEnabled: !isQuickReferencePrivateEnabled,
    })
  }

  updateDateInput(field) {
    return (data) => {
      const dateCheck = helpers.getStandardFormattedDate(
        data.value,
        helpers.dateWithDash,
      )
      if (dateCheck === helpers.invalidDate) {
        this.setState({
          errors: {
            ...this.state.errors,
            [field]: dateCheck,
          },
        })
        return
      }
      this.setState({
        [field]: data.value,
        unsavedChanges: true,
        errors: {
          ...this.state.errors,
          [field]: '',
        },
      })
    }
  }

  updateOverallCommentText = (field) => {
    return (data) => {
      const { value, error } = data
      this.setState({
        [field]: {
          ...this.state[field],
          comment: value,
        },
        errors: {
          ...this.state.errors,
          [field]: error,
        },
        unsavedChanges: true,
      })
    }
  }

  updateRentalInput(field) {
    return (data) => {
      const { rentalAmount } = this.state
      const { value } = data
      if (
        Number.isFinite(value) ||
        (Number.isFinite(Number(value)) && typeof value === 'string')
      ) {
        rentalAmount[field] = Number(value)
        this.setState({
          rentalAmount,
          errors: {
            ...this.state.errors,
            rentalAmount: data.error,
          },
          unsavedChanges: true,
        })
      }
    }
  }

  updateUploadData = (field) => {
    return (data) => {
      const { value, error } = data
      const updateState = this.state.textTypeRatingPayload[field]
      updateState.answerValue = value
      this.setState({
        textTypeRatingPayload: {
          ...this.state.textTypeRatingPayload,
          [field]: updateState,
        },
        errors: {
          ...this.state.errors,
          [field]: error,
        },
        unsavedChanges: true,
      })
    }
  }

  onChangeCreateAnAccount = (event) => {
    const { checked } = event.target || {}
    this.setState({
      createAnAccount: checked,
    })
  }

  ReviewFetchError = ({ error }) => (
    <Alert variant="warningWithBg" mb={`${theme.space[4]}px`}>
      <Flex alignItems="center">
        <WarningAmberRounded
          verticalAlign={'middle'}
          width={`${theme.width[5]}px`}
          height={`${theme.width[5]}px`}
        />
        <TextComponent as={'span'} ml={`${theme.space[3]}px`}>
          {error.message}
        </TextComponent>
      </Flex>
    </Alert>
  )

  closeDeclineReasonModal = (successData) => {
    return this.setState({
      declineReasonModalConfig: {
        isOpened: false,
      },
    })
  }

  renderDeclineReasonModal = () => {
    if (!this.state.declineReasonModalConfig.isOpened) return null

    const { onDecline } = this.state.declineReasonModalConfig

    return (
      <RentalReferenceDeclineModal
        onCloseModal={() => this.closeDeclineReasonModal()}
        onSubmitDecline={onDecline}
      />
    )
  }

  render() {
    const { RentalHistory = {}, message, renter = {}, currentUser } = this.props
    const { managerType, managerRated } = RentalHistory || {}
    let hasManagerType = false
    if (managerType) {
      hasManagerType = true
    }
    const loggedIn = !!(currentUser && currentUser.isVerified)

    const {
      overall,
      pleasant,
      takesCare,
      recommendation,
      bondDeduction,
      textTypeRatingPayload,
      errors,
      isTenancyPrivateEnabled,
      tenancyQuestions,
      errorForOverall,
      disclosureDocument,
      unsavedChanges,
      submitSpinner,
      isFormForTenancyLedger,
      createAnAccount,
      createLiteAccount,
      reviewFetchLoadingStates,
      showSubmitSection,
      showCompletedAlert,
      refereeFirstName = '',
      refereeLastName = '',
      rentalReferenceSubmittedAt = '',
      isRRToLiteProcess,
      showConnectTeamModal = false,
    } = this.state
    const { address = {}, referenceSetVersion } = RentalHistory

    const {
      lastName = '',
      firstName = '',
      email = '',
      mobilePhone = '',
    } = renter
    const { positiveComment, feedback } = textTypeRatingPayload
    const {
      friendlyName = '',
      suburb = '',
      state = '',
      postcode = '',
    } = address
    const addressText = `${friendlyName}, ${suburb}`
    const { disclosureSignedDate } = renter
    const formattedSignDate = moment(disclosureSignedDate)
      .local()
      .format('hh:mm a DD MMM YYYY')
    if (message) {
      return (
        <div
          className={
            message && message.startsWith('Error')
              ? 'alert alert-danger'
              : 'alert alert-success'
          }
        >
          {' '}
          {message}{' '}
        </div>
      )
    }

    const referee = RentalHistory.latestRedirect
      ? RentalHistory.latestRedirect
      : RentalHistory

    let questions

    const march2021Version =
      referenceSetVersion === ratingVersions.march2021.ref

    if (march2021Version) {
      questions = this.buildMarch2021Questions()
    } else {
      questions = (
        <div>
          <Display.SectionContentContainer showBottomBorder={false}>
            <QuestionsDisplay.OverallSection
              overall={overall}
              pleasant={pleasant}
              takesCare={takesCare}
              recommendation={recommendation}
              bondDeduction={bondDeduction}
              errorForOverall={errorForOverall}
              onChangeMultiSelect={this.onChangeMultiSelect}
              onChangeYesNo={this.onOverallYesorNoChange}
              updateUploadData={this.updateOverallCommentText}
              renterFirstName={firstName}
              bondDeductionCommentError={errors.bondDeductionComment}
            />
            <QuestionsDisplay.CommentsSection
              positiveComment={positiveComment}
              errors={errors}
              feedback={feedback}
              updateUploadData={this.updateUploadData}
              renterFirstName={firstName}
            />
            <div>
              <Display.ContainerHeader
                text="Tenancy Questions (8)"
                isEditable={false}
                componentClass="mb10-mobile"
              />
            </div>
          </Display.SectionContentContainer>
          <PrivacySelector
            isPrivateEnabled={isTenancyPrivateEnabled}
            onCheckBoxChange={this.toggleTenancyPrivacyEnabled}
          />

          <Display.SectionContentContainer
            componentClass="mt10-mobile"
            showBottomBorder={false}
          >
            {tenancyQuestions
              .filter(
                (question) =>
                  question.questionType === helpers.QuestionType.Boolean,
              )
              .map((question) => {
                const { ratingCode, description, answerValue } = question
                return (
                  <Form.YesOrNoRadioGroup
                    id={ratingCode}
                    label={description}
                    childrenWrapperClassName="pt0 mb30"
                  >
                    <Form.YesOrNoCheckbox
                      id={
                        helpers.isReversedRatingQuestions(ratingCode) ? No : Yes
                      }
                      label={
                        helpers.isReversedRatingQuestions(ratingCode) ? No : Yes
                      }
                      checked={
                        helpers.isReversedRatingQuestions(ratingCode)
                          ? answerValue === 'false'
                          : answerValue === 'true'
                      }
                      onChange={this.onTenancyQuestionChange(ratingCode)}
                      componentClassName="yes left"
                    />
                    <Form.YesOrNoCheckbox
                      id={
                        helpers.isReversedRatingQuestions(ratingCode) ? Yes : No
                      }
                      label={
                        helpers.isReversedRatingQuestions(ratingCode) ? Yes : No
                      }
                      checked={
                        helpers.isReversedRatingQuestions(ratingCode)
                          ? answerValue === 'true'
                          : answerValue === 'false'
                      }
                      onChange={this.onTenancyQuestionChange(ratingCode)}
                      componentClassName="no right"
                    />
                  </Form.YesOrNoRadioGroup>
                )
              })}
          </Display.SectionContentContainer>
        </div>
      )
    }
    // const loggedIn = !!(currentUser && currentUser.mobileVerified)

    const quickReferenceQuestions = this.renderQuickReferenceQuestionsSection()
    const showCreateLiteAccountCheckbox =
      !isFormForTenancyLedger &&
      RentalHistory &&
      hasManagerType &&
      RentalHistory.managerType &&
      RentalHistory.managerType === MANAGER_TYPE.agent &&
      createLiteAccount &&
      !loggedIn &&
      !managerRated

    const renterFullName = `${helpers.capitalizeFirstLetter(
      firstName,
    )} ${helpers.capitalizeFirstLetter(lastName)}`

    return (
      <LoadingSection
        loadingState={reviewFetchLoadingStates}
        ErrorComponent={this.ReviewFetchError}
      >
        <div className="match-rental-reference-container fs18">
          <Display.CenterContentContainer componentClass=" col-xs-12 summary-box-on-mobile">
            {showCompletedAlert && !isFormForTenancyLedger && (
              <Alert variant="blueWithBg">
                <Flex alignItems="center">
                  <InfoRounded
                    verticalAlign={'middle'}
                    width={`${theme.width[5]}px`}
                    height={`${theme.width[5]}px`}
                  />
                  <TextComponent
                    as={'span'}
                    ml={`${theme.space[5]}px`}
                    fontSize={theme.fontSizes.pRegular14}
                  >
                    Completed {rentalReferenceSubmittedAt} by {refereeFirstName}{' '}
                    {refereeLastName}{' '}
                  </TextComponent>
                </Flex>
              </Alert>
            )}
            <RentalReferenceBanner />
            <StyledFeaturesBox>
              <GreenTickText
                text={`Trusted by ${textHelpers.MarketingPMCount} referees`}
              />
              <GreenTickText text="You control reference visibility" />
            </StyledFeaturesBox>
            <AddressContainer>
              <span>{addressText}</span>
            </AddressContainer>
          </Display.CenterContentContainer>
          <Display.CenterContentContainer
            componentClass="col-xs-12 summary-box-on-mobile"
            containerClass="pb0"
          >
            <div className="section-content-container no-border-bottom pt20 pl0 pr0 pb0">
              <GreyBox mb={6}>
                <Box
                  fontSize={theme.fontSizes[9] + 'px'}
                  fontWeight={theme.fontWeights[6]}
                  mb={4}
                >
                  Renter Details
                </Box>
                <Box mb={1}>
                  {renterFullName} {`(${formatPhoneNumber(mobilePhone)})`}
                </Box>
                <Box>{email}</Box>
                <Box mt={5} mb={4}>
                  {`${helpers.capitalizeFirstLetter(firstName)}
                ${helpers.capitalizeFirstLetter(lastName)} has authorised the
                rental reference.`}
                </Box>

                {disclosureDocument.name && (
                  <DisclosureAuthority
                    docName={disclosureDocument.name}
                    fileType="PDF"
                    text={`Signed ${formattedSignDate}`}
                    showTick={true}
                    docURL={disclosureDocument?.URL || disclosureDocument?.url}
                  />
                )}
              </GreyBox>

              <GreyBox>
                <Box
                  fontSize={theme.fontSizes[9] + 'px'}
                  fontWeight={theme.fontWeights[6]}
                  mb={4}
                >
                  Referee Details
                </Box>
                <Box>{referee?.agencyName}</Box>
                <Box>
                  {`${helpers.capitalizeFirstLetter(
                    referee.firstName,
                  )} ${helpers.capitalizeFirstLetter(referee.lastName)}`}{' '}
                </Box>
                <Box>{referee?.email}</Box>
                <Box>
                  {referee?.officeNumber &&
                    `t ${formatPhoneNumber(referee?.officeNumber)} `}
                  {referee?.phoneNumber &&
                    `m ${formatPhoneNumber(referee?.phoneNumber)}`}
                </Box>
              </GreyBox>
            </div>
          </Display.CenterContentContainer>
          <Display.CenterContentContainer>
            <Box p={8}>
              <Box fontSize={theme.fontSizes.pSmall12} pt={3}>
                {textHelpers.MarketingPMCountCaveatText}
              </Box>
            </Box>
          </Display.CenterContentContainer>
          <Display.CenterContentContainer>
            {showCreateLiteAccountCheckbox && (
              <Display.SectionContentContainer showBottomBorder={false}>
                <>
                  <CreateLiteAccountComp
                    createAnAccount={createAnAccount}
                    onChangeCreateAnAccount={this.onChangeCreateAnAccount}
                  />
                </>
              </Display.SectionContentContainer>
            )}
          </Display.CenterContentContainer>

          <Display.CenterContentContainer componentClass=" col-xs-12 summary-box-on-mobile">
            {!isFormForTenancyLedger && (
              <div>
                <RentalReferenceHeader>
                  Please complete the Rental Reference
                </RentalReferenceHeader>
                <Display.SectionContentContainer
                  showBottomBorder={false}
                  componentClass="pb0 fs18 lh16"
                >{`By answering the following questions you’ll help ${firstName} improve their chances at a successful application.`}</Display.SectionContentContainer>
              </div>
            )}

            {quickReferenceQuestions}

            {!isFormForTenancyLedger && <Box>{questions}</Box>}
          </Display.CenterContentContainer>
          <Display.CenterContentContainer>
            <Display.SectionContentContainer showBottomBorder={false}>
              {showCreateLiteAccountCheckbox && (
                <>
                  <CreateLiteAccountComp
                    createAnAccount={createAnAccount}
                    onChangeCreateAnAccount={this.onChangeCreateAnAccount}
                  />
                </>
              )}
            </Display.SectionContentContainer>
          </Display.CenterContentContainer>

          <div
            style={
              isRRToLiteProcess ? { position: 'absolute', bottom: '10px' } : {}
            }
          >
            {!isFormForTenancyLedger && showSubmitSection && (
              <Display.CenterContentContainer componentClass="width100 mt20">
                <div className="display-flex justify-content-space-between">
                  <div className=" text-align-center mobile-mt10">
                    <ButtonWithIcon
                      type="button"
                      sizeVariant="large"
                      variant="outlineError"
                      leading
                      IconCmp={ArrowBackRounded}
                      mr={theme.space[3] + 'px'}
                      onClick={() => this.onDeclineClick()}
                    >
                      I'm unable to provide a reference
                    </ButtonWithIcon>
                  </div>
                  <Button
                    size="large"
                    text="Submit Reference"
                    onClick={() => this.onClickSubmit()}
                    disabled={submitSpinner}
                    isLoading={submitSpinner}
                    buttonType="darker-green"
                    componentClass="width-50 fs18"
                    buttonStyleClass="height50px fw700"
                  />
                </div>
              </Display.CenterContentContainer>
            )}
          </div>
          {isFormForTenancyLedger && (
            <Display.CenterContentContainer componentClass="width100 mt20">
              <Button
                size="large"
                text="Save"
                onClick={() => this.handleTenantLedgerSaveClick()}
                buttonType="darker-green"
                componentClass="width-50 fs18"
                buttonStyleClass="height50px fw700"
              />
            </Display.CenterContentContainer>
          )}
          <UnsavedMessage unsavedChanges={unsavedChanges} />
          {this.state.isConfirmBeforeSubmitModalOpen && (
            <Display.Modal
              toggleModal={() => this.toggleModal()}
              title="Reference Incomplete"
              hideButtonSection
              modalBodyClass="mt10"
              modalSubheadingClass="ml-auto mr-auto"
              modalSubheaderClass="position-relative height-auto"
            >
              <div className="fs16 ">
                <Display.DefaultBodyText
                  text={
                    <span>
                      Are you sure you want to submit an incomplete reference?
                      <br />
                      Click Continue to answer the remaining questions.
                    </span>
                  }
                  textClass="text-align-center"
                />
              </div>
              <Display.ModalButtonGroup
                primaryLabel="Continue"
                secondaryText="Submit"
                secondaryButtonType="tertiary"
                primaryAction={() => this.toggleModal()}
                secondaryAction={() => this.onClickSubmit('forceToSubmit')}
                secondaryDisabled={submitSpinner}
                secondaryIsLoading={submitSpinner}
                secondaryLoaderClassName="color-inherit"
              />
            </Display.Modal>
          )}

          {this.renderDeclineReasonModal()}
        </div>
      </LoadingSection>
    )
  }
}

export default withRatingFormBasicControls(RatingFormContainer)
