import { connect } from 'react-redux'

import {
  fetchPets,
  removePet,
} from 'app/sm/pets_details/pets_details_add_actions'
import PetsDetails from 'app/sm/pets_details/pets_details_list'
import {
  changeReputationPreference,
  retrieveReputationPreference,
} from 'app/sm/rental_reputation/actions'
import { fetchCompleteness } from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({
  AddPetDetails,
  Shared,
  apply,
  RentalReputation,
}) => ({
  petList: AddPetDetails.petList,
  spinner: AddPetDetails.spinner,
  responseText: AddPetDetails.responseText,
  backUrl: Shared.backUrl,
  property: apply.property,
  reputationPreference: RentalReputation.preference,
  completeness: RentalReputation.completeness,
})

const mapDispatchToProps = (dispatch) => ({
  fetchPets: () => dispatch(fetchPets()),
  removePet: (id) => dispatch(removePet(id)),
  retrieveReputationPreference: () => dispatch(retrieveReputationPreference()),
  changeReputationPreference: (preference) =>
    dispatch(changeReputationPreference(preference)),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PetsDetails)
