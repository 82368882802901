import { ButtonWithLoading } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

type Props = {
  disabled: boolean
  onActivate: () => void
}

export const ActivationButton = ({ disabled, onActivate }: Props) => (
  <ButtonWithLoading
    height={`${theme.dividedSpace[8]}px`}
    disabled={disabled}
    onClick={() => onActivate()}
    ml={3}
  >
    Activate
  </ButtonWithLoading>
)
