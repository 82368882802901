import React from 'react'

import $ from 'jquery'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import BPAY from 'app/assets/icons/BPay-image.png'
import calendar from 'app/assets/icons/calendar.png'
import DEFT from 'app/assets/icons/DEFT.png'
import EFT from 'app/assets/icons/EFT.png'
import exclamationLg from 'app/assets/icons/exclamation-lg.png'
import * as leaseAgencyUtils from 'app/bond_cover/agency/utils'
import {
  Box,
  Button,
  Flex,
  Modal,
} from 'app/components/design-system-components'
import { GenericModal } from 'app/components/design-system-components/modals/GenericModal'
import * as Display from 'app/components/display/display'
import * as Headers from 'app/components/display/text/standard_text/standard-headings/component'
import * as Text from 'app/components/display/text/text_component'
import * as Form from 'app/components/forms/forms'
import { StyledEllipse } from 'app/components/property/PropertySummary'
import {
  getActiveIntegrationsSendingTenancyOnboard,
  getPMSBrand,
  pmsBrands,
  pmsStatuses,
} from 'app/constants/pms.constants'
import theme from 'app/match/applicationReportPDF/assets/theme'
import InputFieldWithSearchDropdown from 'app/match/leaseAttachment/components/InputFieldWithSearchDropdown'
import NewLeaseOfferVersionContent from 'app/match/leaseAttachment/components/new-lease-offer-version-content'
import { TEAM_MEMBER_PILL_TEXT } from 'app/match/propertyReport/container'
import * as snugNotifier from 'app/services/snugNotifier'
import DisclosureQuestionsDisplay from 'app/shared_components/DisclosureQuestionsDisplay'
import * as NewForm from 'app/shared_components/form_component'
import { ErrorMessage, isLoggedIn } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import * as validations from 'app/shared_components/validations'
import * as helpers from 'app/sm/helpers'
import {
  routes,
  SNUG_SUBMIT_REQUEST_URL,
  timeUnitsText,
  urlIds,
} from 'app/sm/helpers'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'
import { SessionStorageUtils } from 'app/storage_utils'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as leaseOfferHelpers from 'app/utils/leaseOffer'
import { leaseLengthOptions } from 'app/utils/leaseOffer'
import { formatRent, getMonthlyFromWeeklyRent } from 'app/utils/numbers/helpers'
import * as objectHelpers from 'app/utils/objects/helpers'
import { formatPhoneNumber } from 'app/utils/phonenumber/helpers'
import { SendApplicationToPMS } from 'app/utils/pms/sendApplicationToPMS'
import { RAY_WHITE_CONCIERGE_SEND_LABEL } from 'app/utils/raywhiteconcierge/helpers'
import { AGENCY_USER_FINANCE } from 'app/utils/roles'
import { consoleCloudFeatureText } from 'app/utils/strings/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import 'app/match/leaseAttachment/style.scss'
import { DefaultUtilityProviderComponent } from 'app/utils/utilityprovider/helpers'
import { isSendApplicantEnabled } from 'config/features'

const NEW_LEASE_OFFER_VERSION = 1

const EMAIL_LIST_VALUE_INPUT = 'emailList'
const CC_EMAIL_LIST_VALUE_INPUT = 'ccEmailList'

const initialDepositDurationArray = [
  'Select',
  '1 week',
  '2 weeks',
  '1 month',
  'Custom',
]
const initialDepositDurationObject = {
  select: {
    text: 'select',
    index: 0,
  },
  '1 week': {
    text: '1 week',
    index: 1,
  },
  '2 weeks': {
    text: '2 weeks',
    index: 2,
  },
  '1 month': {
    text: '1 month',
    index: 3,
  },
  custom: {
    text: 'custom',
    index: 4,
  },
}
const initialRentPeriodArray = [
  'Select',
  '1 month',
  '2 months',
  '3 months',
  '4 months',
  '5 months',
  '6 months',
  'Custom',
]
const initialBondDurationdArray = [
  'Select',
  '1 week',
  '2 weeks',
  '3 weeks',
  '4 weeks',
  '1 month',
  '6 weeks',
  'Custom',
]

const leaseDetailSetByTypeGUIDKey = {
  byDays: 'by-days',
  byWeeks: 'by-weeks',
  byMonths: 'by-months',
  byEndDate: 'by-end-date',
}

const leaseDetailDurationByType = {
  'by-days': 'days',
  'by-weeks': 'weeks',
  'by-months': 'months',
  'by-end-date': 'months',
}

const leaseDetailSetByTypeNameKey = {
  days: 'Days',
  weeks: 'Weeks',
  months: 'Months',
  endDate: 'End date',
}

const initialLeaseDetailSetByType = [
  {
    guid: leaseDetailSetByTypeGUIDKey.byDays,
    name: leaseDetailSetByTypeNameKey.days,
  },
  {
    guid: leaseDetailSetByTypeGUIDKey.byWeeks,
    name: leaseDetailSetByTypeNameKey.weeks,
  },
  {
    guid: leaseDetailSetByTypeGUIDKey.byMonths,
    name: leaseDetailSetByTypeNameKey.months,
  },
  {
    guid: leaseDetailSetByTypeGUIDKey.byEndDate,
    name: leaseDetailSetByTypeNameKey.endDate,
  },
]

const statusAccepted = 'Accepted'
const statusOffered = 'Offered'
const VICTORIA_STATE = 'Victoria'
const replaceText = (message = '', text, placeholder = '%v') =>
  message.replace(placeholder, text)

const OFFER_SENT_SUCCESS_MESSAGE = 'Offer Sent'
const DEFAULT_UCS_MAKE_MOVING_EASIER_MESSAGE =
  'will be in touch shortly to finalise your move. Keep your eyes open for an email from them.'

const attachmentScopeTeam = 'team'
const attachmentScopeProperty = 'property'

const CCLeaseOffers = styled(Flex)`
  @media (max-width: 576px) {
    display: block;
  }
`
const ImportantInfoTxtCmp = styled(Box)`
  white-space: pre-line;
`

class LeaseAttachmentContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emailList: [],
      ccEmailList: [],
      assignedTeamMemberEmail: '',
      secondaryEmailList: [],
      emailValue: '',
      ccEmailValue: '',
      message: '',
      errors: {},
      activeModal: '',
      propertyInfo: {},
      applicationInfo: {},
      managerNameList: [],
      chosenManager: '',
      unsavedChanges: false,
      enableToast: false,
      agencyProfile: {},
      sendConnectionReferral: true,
      sendApplicationToConsole: false,
      teamSettingInfo: {},
      activeSendingOnboardIntegrations: [],
      showPropertyAttachmentSpinner: false,
      hasPropertyDisclosure: false,
      propertyDisclosures: {},
      combinedManagersList: [],
      showResults: {
        [EMAIL_LIST_VALUE_INPUT]: false,
        [CC_EMAIL_LIST_VALUE_INPUT]: false,
      },
    }
  }

  componentDidMount() {
    $('#index').addClass('no-navbar-menu')
    SessionStorageUtils.setItem('forwardUrl', window.location.pathname)

    let {
      match: {
        params: { slug },
      },
      fetchLeasingOfferInfo,
      fetchLeasingOfferInfoBySlug,
      fetchTeamManagerList,
    } = this.props

    if (slug) {
      fetchLeasingOfferInfoBySlug(slug).then((result) => {
        this.setState({
          agencyId: result.application.offer.property.agencyID,
          applicationId: result.application.guidID,
        })

        hydrateResult(result)
      })
    } else {
      let {
        match: {
          params: { applicationId, agencyId },
        },
      } = this.props

      this.setState({
        agencyId,
        applicationId,
      })

      fetchLeasingOfferInfo(applicationId, agencyId)
        .then((result) => {
          hydrateResult(result)
        })
        .catch(({ code, message }) => {
          if (code === 404) {
            history.push('/404')
          } else {
            this.setState({ error: message })
          }
        })
    }

    const hydrateResult = (result) => {
      const { agencyId, applicationId } = this.state

      const calendarMonthlyRent = getMonthlyFromWeeklyRent(
        result.nextStep.lease_details.weeklyRent,
      )
      const initalModalRentValue =
        calendarMonthlyRent - result.nextStep.deposit.deposit_amount > 0
          ? (
              calendarMonthlyRent - result.nextStep.deposit.deposit_amount
            ).toFixed(2)
          : 0

      const {
        nextStep: {
          email_recipients: existingRecipients,
          email_carbon_copy: existingCarbonCopy,
          primaryRecipient,
          secondaryRecipients,
          assignedTeamMemberEmail,
          send_to_all_household: sendToAll,
        },
      } = result
      const emailList = [
        {
          text: `${primaryRecipient} (primary)`,
          status: 'valid',
          primary: true,
        },
      ]
      const ccEmailList = []
      const secondaryEmailList =
        secondaryRecipients &&
        secondaryRecipients.map((e) => ({
          text: e,
          status: 'valid',
          primary: true,
        }))
      if (sendToAll && secondaryEmailList) {
        emailList.push(...secondaryEmailList)
      }
      if (existingRecipients) {
        const existingRecipientsList = existingRecipients
          .split(',')
          .filter(
            (existingEmail) =>
              !existingEmail.includes(primaryRecipient) &&
              !(
                sendToAll &&
                secondaryRecipients &&
                secondaryRecipients.includes(existingEmail)
              ),
          )
        emailList.push(
          ...existingRecipientsList.map((email) => ({
            text: email,
            status: 'valid',
            primary: false,
          })),
        )
      }
      if (existingCarbonCopy) {
        ccEmailList.push(
          ...existingCarbonCopy.split(',').map((email) => ({
            text: email,
            status: 'valid',
            primary: false,
          })),
        )
      }
      this.setState(
        {
          calendarMonthlyRent,
          leaseOfferInfo: result,
          leaseOfferId: result.nextStep.leaseOfferGUID,
          originalOfferInfo: result,
          modalBondInfo: result.nextStep.bond,
          modalDepositInfo: result.nextStep.deposit,
          modalRentInfo: Object.assign({}, result.nextStep.rent, {
            rent_amount: initalModalRentValue,
          }),
          modalImportantInformation: {
            important_message:
              result.nextStep.important_information.important_message,
            attachments: JSON.parse(
              JSON.stringify(result.nextStep.important_information.attachments),
            ),
          },
          modalLeaseDetails: result.nextStep.lease_details,
          modalLeasingContact: result.nextStep.leasing_contact,
          initialLeasingContact: result.nextStep.leasing_contact,
          modalLeasingSigning: result.nextStep.lease_signing,
          propertyDisclosures:
            result.propertyDisclosure &&
            result.propertyDisclosure.propertyDisclosure,
          hasPropertyDisclosure:
            result.propertyDisclosure &&
            result.propertyDisclosure.hasPropertyDisclosure,
          emailList,
          secondaryEmailList,
          assignedTeamMemberEmail,
          ccEmailList,
        },
        () => {
          this.props
            .fetchTeamSettingInfo(agencyId, 'leaseattachments')
            .then((attributes) => {
              const { leaseOfferId } = this.state
              if (attributes) {
                const { leaseattachments } = attributes
                this.setState({
                  ...this.state,
                  modalDepositInfo: {
                    ...this.state.modalDepositInfo,
                    EFTPaymentsV2:
                      leaseAgencyUtils.convertToEFTValues(leaseattachments),
                  },
                })
              }
              if (attributes && !leaseOfferId.length) {
                const { leaseattachments } = attributes
                const { standard_bond_duration_weeks } = leaseattachments
                if (standard_bond_duration_weeks) {
                  const bondDurationWeeks =
                    leaseOfferHelpers.convertBondDurationToCorrespondingArrayIndex(
                      standard_bond_duration_weeks,
                    )
                  const bondAmount = leaseOfferHelpers.calcBondAmount(
                    this.state.leaseOfferInfo.leaseOffer.weeklyRent,
                    standard_bond_duration_weeks,
                  )
                  this.setState({
                    ...this.state,
                    modalBondInfo: {
                      ...this.state.modalBondInfo,
                      bond_duration: bondDurationWeeks,
                      bond_amount: bondAmount,
                    },
                    leaseOfferInfo: {
                      ...this.state.leaseOfferInfo,
                      nextStep: {
                        ...this.state.leaseOfferInfo.nextStep,
                        bond: {
                          ...this.state.leaseOfferInfo.nextStep.bond,
                          bond_duration: bondDurationWeeks,
                          bond_amount: bondAmount,
                        },
                      },
                    },
                  })
                }
              }
            })
            .catch((error) => {
              if (window.location.pathname.includes('/manager')) {
                this.onEnableToast(error)
              }
            })
        },
      )

      fetchTeamManagerList(agencyId)
        .then(({ managerList }) => {
          this.setState({
            managerList,
            managerNameList: managerList.map(
              (manager) =>
                `${helpers.capitalizeFirstLetter(
                  manager.profile.firstName,
                )} ${helpers.capitalizeFirstLetter(manager.profile.lastName)}`,
            ),
            combinedManagersList: [
              ...this.state.combinedManagersList,
              ...this.addManagersProfileToCombinedList(managerList),
            ],
          })
        })
        .catch((error) => {
          if (window.location.pathname.includes('/manager')) {
            this.onEnableToast(error)
          }
        })
      if (agencyId) {
        this.props
          .fetchTeamSettingInfo(
            agencyId,
            'notifications,integrations,application',
          )
          .then((teamSettingInfo) => {
            this.setState({
              teamSettingInfo,
            })
            const { application: applicationAttributes = {} } =
              teamSettingInfo || {}

            this.receiveTeamSettingEmailList(applicationAttributes)
            this.receiveTeamSettingRoundDownPayment(applicationAttributes)
            const { leaseOfferInfo } = this.state
            const { nextStep = {} } = leaseOfferInfo
            const { lease_details = {} } = nextStep
            let { leaseStatus: offeredLeaseStatus } = lease_details
            const isUserManager = window.location.pathname.includes('/manager')

            const isOfferOffered =
              helpers.ApplicationCategory[offeredLeaseStatus] === statusOffered

            const isOfferAccepted =
              helpers.ApplicationCategory[offeredLeaseStatus] === statusAccepted

            const isOverridingLeaseLengthDefault =
              isUserManager && !isOfferOffered && !isOfferAccepted

            isOverridingLeaseLengthDefault &&
              this.receiveTeamSettingLeaseLengthDefault(applicationAttributes)
            this.props
              .fetchAgencyStatus(agencyId)
              .then(({ integrationStatus }) => {
                const activeSendingOnboardIntegrations =
                  getActiveIntegrationsSendingTenancyOnboard(integrationStatus)

                // don't set inactive pms options
                integrationStatus.map((brandStatus) => {
                  if (brandStatus.status === pmsStatuses.inactive.id) {
                    const brand = getPMSBrand(brandStatus.pms)
                    if (brand && brand.offerConfig) {
                      nextStep[brand.offerConfig] = false
                    }
                  }
                })
                leaseOfferInfo.nextStep = nextStep
                this.setState({
                  activeSendingOnboardIntegrations,
                  leaseOfferInfo,
                })
              })
              .catch((error) => {
                if (window.location.pathname.includes('/manager')) {
                  this.onEnableToast(error)
                }
              })
          })
          .catch((error) => {
            if (window.location.pathname.includes('/manager')) {
              this.onEnableToast(error)
            }
          })
      }

      this.setState({
        applicationId,
        agencyId,
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.isManagerView() &&
      this.state.teamSettingInfo !== prevState.teamSettingInfo
    ) {
      this.onTeamIntegrationsUpdated()
    }
  }

  componentWillUnmount() {
    $('#index').removeClass('no-navbar-menu')
    $('.app-content-container').removeClass('background-grey-mobile-excluded')
    window.onbeforeunload = null
  }

  isManagerView() {
    return this.props.match.path === routes.leaseAttachmentManager
  }

  onTeamIntegrationsUpdated() {
    const { integrations } = this.state.teamSettingInfo
    const isConsoleCloudEnabled = integrations.console_cloud_enabled === 'true'
    if (!isConsoleCloudEnabled) {
      this.setState({ sendApplicationToConsole: false })
      return
    }

    const { getIntegration } = this.props
    const { agencyId } = this.state

    getIntegration(agencyId)
      .then((result) => {
        this.setState({
          sendApplicationToConsole: result && result.status === 'ACTIVE',
        })
      })
      .catch((err) => {
        snugNotifier.error(err)
      })
  }

  onEditClicked = (fieldName) => {
    if (fieldName) {
      $('body').css('overflow', 'hidden')
    } else {
      $('body').css('overflow', 'auto')
    }
    this.setState({
      activeModal: fieldName,
    })
  }

  onEnableToast = (message) => {
    this.setState(
      {
        enableToast: true,
      },
      () => snugNotifier.error(message),
    )
  }

  onManagerDropDownChanged() {
    return (data) => {
      const { managerList } = this.state
      const chosenManager = managerList[data.value]
      const { profile = {} } = chosenManager
      const { firstName, lastName, email, mobilePhone } = profile
      this.turnOnLeavingPrompt()
      this.setState({
        chosenManager: data.value,
        modalLeasingContact: {
          ...this.state.modalLeasingContact,
          leasingContactName: `${helpers.capitalizeFirstLetter(
            firstName,
          )} ${helpers.capitalizeFirstLetter(lastName)}`,
          leasingContactEmail: email,
          leasingContactMobile: mobilePhone,
        },
      })
    }
  }

  onManagerWithdraw = () => {
    const { withdrawOfferForApplication } = this.props
    const { applicationId } = this.state

    if (window.confirm('Are you sure you want to withdraw this offer?')) {
      this.setState({
        withdrawOfferSpinner: true,
      })
      withdrawOfferForApplication(applicationId)
        .then(() => {
          this.setState({ withdrawOfferSpinner: false })
          history.goBack()
        })
        .catch((error) => {
          this.setState({ withdrawOfferSpinner: false })
          this.onEnableToast(error)
        })
    }
  }

  addManagersProfileToCombinedList = (managersList) => {
    return managersList.map((manager) => {
      return { ...manager.profile, pillText: TEAM_MEMBER_PILL_TEXT }
    })
  }

  onModalCheckboxToggled = (key) => {
    const {
      modalImportantInformation: { attachments = [] },
    } = this.state
    attachments.forEach((attachment) => {
      if (attachment.Key === key) {
        attachment.included = !attachment.included
      }
    })
    const updateAttachments = attachments
    this.setState({
      modalImportantInformation: {
        ...this.state.modalImportantInformation,
        attachments: updateAttachments,
      },
    })
    this.turnOnLeavingPrompt()
  }

  onModalRemoveAttachment = (scope, key) => {
    const { removeAttachmentForAgency } = this.props
    const { applicationId, agencyId } = this.state
    this.turnOnLeavingPrompt()
    if (scope === attachmentScopeTeam) {
      this.setState({
        showRentalLedgerSpinner: true,
      })
    }
    if (scope === attachmentScopeProperty) {
      this.setState({
        showPropertyAttachmentSpinner: true,
      })
    }
    removeAttachmentForAgency(applicationId, agencyId, key, scope)
      .then((result) => {
        const { attachments = [] } = this.state.modalImportantInformation
        this.setState({
          showRentalLedgerSpinner: false,
        })
        if (result) {
          result.forEach((dataFile) => {
            ;(attachments || []).forEach((attachment) => {
              if (attachment.Key === dataFile.Key) {
                dataFile.included = attachment.included
              }
            })
          })
        } else {
          result = []
        }

        const data = { value: result }
        this.onModalUpdated('modalImportantInformation')('attachments')(data)
      })
      .catch((error) => {
        this.onEnableToast(error)
        this.setState({
          showRentalLedgerSpinner: false,
        })
      })
      .finally(() => {
        this.setState({
          showRentalLedgerSpinner: false,
          showPropertyAttachmentSpinner: false,
        })
      })
  }

  onModalSaved = (targetSection, modalSection) => {
    const {
      deposit_amount,
      deposit_bpay_reference,
      deposit_duration,
      deposit_eft_reference,
      deposit_deft_reference,
      deposit_include_bpay_details,
      deposit_include_eft_details,
      deposit_include_deft_details,
      deposit_due_date,
    } = this.state.modalDepositInfo

    const {
      bond_amount,
      bond_bpay_reference,
      bond_deft_reference,
      bond_duration,
      bond_eft_reference,
      bond_include_bpay_details,
      bond_include_deft_details,
      bond_include_eft_details,
      bond_pay_online,
      bond_due_date,
    } = this.state.modalBondInfo

    const {
      rent_amount,
      rent_bpay_reference,
      rent_deft_reference,
      rent_eft_reference,
      rent_include_bpay_details,
      rent_include_eft_reference,
      rent_include_deft_reference,
      rent_period,
      pay_rent_in_advance,
      rent_due_date,
    } = this.state.modalRentInfo

    const {
      leaseStartDate,
      leaseEndDate,
      leaseSetByType,
      weeklyRent,
      numPets,
      is_pets_allowed,
    } = this.state.modalLeaseDetails

    const {
      leaseOfferInfo: {
        nextStep: { rent = {}, include_holding_deposit },
      },
    } = this.state

    const {
      modalDepositInfo: modalDepositInfoErrors = {},
      modalRentInfo: modalRentInfoErrors = {},
      modalLeaseDetails: modalLeaseDetailsErrors = {},
      modalBondInfo: modalBondInfoErrors = {},
    } = this.state.errors
    if (modalSection === 'modalBondInfo') {
      if (!bond_amount || modalBondInfoErrors.bond_amount) {
        this.onEnableToast('The Bond must be a number greater than zero')
        return
      }

      if (!bond_duration) {
        this.onEnableToast('Please select a Bond period')
        return
      }
      if (!bond_due_date || modalBondInfoErrors.bond_due_date) {
        this.onEnableToast('Please select valid bond due date')
        return
      }

      if (
        (bond_include_eft_details &&
          !bond_pay_online &&
          (!bond_eft_reference || modalBondInfoErrors.bond_eft_reference)) ||
        (bond_include_bpay_details &&
          !bond_pay_online &&
          (!bond_bpay_reference || modalBondInfoErrors.bond_bpay_reference)) ||
        (bond_include_deft_details &&
          !bond_pay_online &&
          (!bond_deft_reference || modalBondInfoErrors.bond_deft_reference))
      ) {
        this.onEnableToast('Please enter a valid payment Reference number')
        return
      }
    }

    if (modalSection === 'modalLeaseDetails') {
      if (!weeklyRent || modalLeaseDetailsErrors.weeklyRent) {
        this.onEnableToast('The number of rent is not valid')
        return
      }
      //workaround to fix https://app.shortcut.com/snugco/story/79968/pm-issue-error-900102
      //This whole component needs to be refactored!
      if (!is_pets_allowed) {
        this.state.modalLeaseDetails.numPets = 0
      }
      if (is_pets_allowed && !Number.isInteger(Number(numPets))) {
        this.onEnableToast('Number of pets should be a valid number')
        return
      }
      if (modalLeaseDetailsErrors.leaseStartDate) {
        this.onEnableToast('Please select valid lease start date')
        return
      }
      if (modalLeaseDetailsErrors.leaseEndDate) {
        this.onEnableToast('Please select valid lease end date')
        return
      }
      if (leaseStartDate > leaseEndDate) {
        this.onEnableToast(
          'Please select a lease end date after the start date',
        )
        return
      }
      const updatedLeaseLength = Math.round(
        moment(new Date(leaseEndDate)).diff(
          new Date(leaseStartDate),
          leaseDetailDurationByType[leaseSetByType] || timeUnitsText.months,
          true,
        ),
      ).toString()
      this.state.modalLeaseDetails.leaseLength = updatedLeaseLength
    }

    if (modalSection === 'modalDepositInfo') {
      if (!this.validateFirstPaymentBeforeSendOffer()) {
        return
      }

      if (!deposit_amount || modalDepositInfoErrors.deposit_amount) {
        this.onEnableToast(
          'The First Payment / Holding Deposit must be a number greater than zero',
        )
        return
      }
      if (!deposit_duration || deposit_duration === '0') {
        this.onEnableToast(
          'Please select a First Payment / Holding Deposit duration',
        )
        return
      }
      if (!deposit_due_date || modalDepositInfoErrors.deposit_due_date) {
        this.onEnableToast('Please select valid deposit due date')
        return
      }
      if (
        (deposit_include_bpay_details &&
          (!deposit_bpay_reference ||
            modalDepositInfoErrors.deposit_bpay_reference)) ||
        (deposit_include_deft_details &&
          (!deposit_deft_reference ||
            modalDepositInfoErrors.deposit_deft_reference))
      ) {
        this.onEnableToast(
          'Please enter a valid payment Reference for First Payment',
        )
        return
      }
    }
    if (modalSection === 'modalRentInfo') {
      if (!rent_amount || modalRentInfoErrors.rent_amount) {
        this.onEnableToast('The Rent must be a number greater than zero')
        return
      }
      if (pay_rent_in_advance && !rent_period) {
        this.onEnableToast('Please select advance rent period')
        return
      }
      if (!rent_due_date || modalRentInfoErrors.rent_due_date) {
        this.onEnableToast('Please select valid rent due date')
        return
      }
      if (
        (rent_include_eft_reference &&
          (!rent_eft_reference || modalRentInfoErrors.rent_eft_reference)) ||
        (rent_include_bpay_details &&
          (!rent_bpay_reference || modalRentInfoErrors.rent_bpay_reference)) ||
        (rent_include_deft_reference &&
          (!rent_deft_reference || modalRentInfoErrors.rent_deft_reference))
      ) {
        this.onEnableToast(
          'Please enter a valid payment Reference for Outstanding Balance',
        )
        return
      }
    }
    if (modalSection === 'modalDepositInfo') {
      this.state.leaseOfferInfo.nextStep.rent.rent_amount =
        Number(this.state.leaseOfferInfo.nextStep.rent.rent_amount) +
        Number(this.state.leaseOfferInfo.nextStep.deposit.deposit_amount) -
        Number(this.state.modalDepositInfo.deposit_amount)
      this.state.modalRentInfo.rent_amount =
        this.state.leaseOfferInfo.nextStep.rent.rent_amount
      if (!rent.rent_bpay_reference) {
        this.state.leaseOfferInfo.nextStep.rent.rent_bpay_reference =
          this.state.modalDepositInfo.deposit_bpay_reference
      }
      if (!rent.rent_deft_reference) {
        this.state.leaseOfferInfo.nextStep.rent.rent_deft_reference =
          this.state.modalDepositInfo.deposit_deft_reference
      }
      if (!rent.rent_eft_reference) {
        this.state.leaseOfferInfo.nextStep.rent.rent_eft_reference =
          this.state.modalDepositInfo.deposit_eft_reference
      }
    }
    if (modalSection === 'modalLeaseDetails') {
      this.state.leaseOfferInfo.nextStep.deposit.deposit_amount =
        this.state.modalLeaseDetails.weeklyRent
      this.state.leaseOfferInfo.nextStep.bond.bond_amount =
        this.state.modalLeaseDetails.weeklyRent * 4
      this.state.calendarMonthlyRent = getMonthlyFromWeeklyRent(
        this.state.modalLeaseDetails.weeklyRent,
      )
      const updatedRent =
        this.state.calendarMonthlyRent *
          (this.state.leaseOfferInfo.nextStep.rent.rent_period + 1) -
        (include_holding_deposit ? this.state.modalLeaseDetails.weeklyRent : 0)
      this.state.leaseOfferInfo.nextStep.rent.rent_amount = updatedRent
      this.state.modalRentInfo.rent_amount =
        this.state.leaseOfferInfo.nextStep.rent.rent_amount
      this.state.modalBondInfo.bond_amount =
        this.state.leaseOfferInfo.nextStep.bond.bond_amount
    }
    this.setState(
      {
        leaseOfferInfo: {
          ...this.state.leaseOfferInfo,
          nextStep: {
            ...this.state.leaseOfferInfo.nextStep,
            [targetSection]: {
              ...this.state.leaseOfferInfo.nextStep[targetSection],
              ...JSON.parse(JSON.stringify(this.state[modalSection])),
            },
          },
        },
      },
      () => {
        if (modalSection === 'modalLeaseDetails') {
          this.calculateAndSetDepositAmountInLeaseDetails()
        }
        if (modalSection === 'modalDepositInfo') {
          this.setPaymentInfoInLeaseDetails()
        }
      },
    )
    this.onEditClicked('')
  }

  receiveTeamSettingEmailList = (applicationAttributes) => {
    const {
      application_lease_offer_always_copy_lease_ops: alwaysCopyOperationEmail,
      application_lease_offer_operations_email: operationsEmail,
      application_lease_offer_lease_ops_as_contact:
        operationEmailAsLeasingContact,
    } = applicationAttributes || {}

    if (!!operationsEmail && alwaysCopyOperationEmail === 'true') {
      const isOperationsEmailAlreadyCopy = this.state.ccEmailList
        .map(({ text }) => text)
        .includes(operationsEmail)
      this.setState({
        ccEmailList: !isOperationsEmailAlreadyCopy
          ? [...this.state.ccEmailList, { text: operationsEmail }]
          : this.state.ccEmailList,
        leaseOfferInfo: {
          ...this.state.leaseOfferInfo,
          nextStep: {
            ...this.state.leaseOfferInfo.nextStep,
            carbon_copy_operations: true,
          },
        },
      })
    }

    if (!!operationsEmail && operationEmailAsLeasingContact === 'true') {
      this.setState({
        useOpsContactEmailAddress: true,
        leaseOfferInfo: {
          ...this.state.leaseOfferInfo,
          nextStep: {
            ...this.state.leaseOfferInfo.nextStep,
            leasing_contact: {
              ...this.state.leaseOfferInfo.nextStep.leasing_contact,
              leasingContactEmail: operationsEmail,
            },
          },
        },
        modalLeasingContact: {
          ...this.state.modalLeasingContact,
          leasingContactEmail: operationsEmail,
        },
      })
    }
  }

  receiveTeamSettingRoundDownPayment = (applicationAttributes) => {
    const {
      round_payments_down_nearest_dollar: roundPaymentsDownNearestDollar,
    } = applicationAttributes || {}

    if (roundPaymentsDownNearestDollar === 'true') {
      this.setState({
        modalRentInfo: {
          ...this.state.modalRentInfo,
          round_down: true,
          rent_amount: Math.floor(this.state.modalRentInfo.rent_amount).toFixed(
            2,
          ),
        },
      })
    }
  }

  receiveTeamSettingLeaseLengthDefault = (applicationAttributes) => {
    const { lease_length_default: leaseLengthDefault = '1' } =
      applicationAttributes || {}
    const { leaseStartDate } = this.state.modalLeaseDetails
    const selectedLeaseLength = leaseLengthOptions.find(
      ({ guid }) => guid.toString() === leaseLengthDefault,
    )
    const leaseDuration = String(selectedLeaseLength.value)
    this.onModalUpdated('modalLeaseDetails')('leaseDuration')({
      value: selectedLeaseLength.value.toString(),
      error: '',
    })
    let leaseDetails = {
      ...this.state['modalLeaseDetails'],
      leaseEndDate: this.updateEndDate(leaseStartDate, leaseDuration, 'week'),
      leaseSetByType: leaseDetailSetByTypeGUIDKey.byWeeks,
      leaseLength: leaseDuration,
      isLeaseSetByTypeSelected: true,
    }
    if (selectedLeaseLength.name.includes('weeks')) {
      leaseDetails.leaseEndDate = this.updateEndDate(
        leaseStartDate,
        leaseDuration,
        'week',
      )
      leaseDetails.leaseSetByType = leaseDetailSetByTypeGUIDKey.byWeeks
    }
    if (selectedLeaseLength.name.includes('months')) {
      leaseDetails.leaseEndDate = this.updateEndDate(
        leaseStartDate,
        leaseDuration,
        'month',
      )
      leaseDetails.leaseSetByType = leaseDetailSetByTypeGUIDKey.byMonths
    }
    this.setState({
      modalLeaseDetails: leaseDetails,
      leaseOfferInfo: {
        ...this.state.leaseOfferInfo,
        nextStep: {
          ...this.state.leaseOfferInfo.nextStep,
          lease_details: {
            ...this.state.leaseOfferInfo.nextStep.lease_details,
            ...leaseDetails,
          },
        },
      },
    })
  }

  calculateAndSetDepositAmountInLeaseDetails = () => {
    this.setState({
      leaseOfferInfo: {
        ...this.state.leaseOfferInfo,
        nextStep: {
          ...this.state.leaseOfferInfo.nextStep,
          is_pets_allowed: this.state.modalLeaseDetails.is_pets_allowed,
          posting_clause: this.state.modalLeaseDetails.posting_clause,
        },
      },
    })
    const { deposit_duration } = this.state.modalDepositInfo
    const { weeklyRent } = this.state.modalLeaseDetails
    let finalizedRent = deposit_duration * weeklyRent
    if (
      initialDepositDurationArray[deposit_duration] ===
      initialDepositDurationObject['1 month'].text
    ) {
      const monthlyRent = getMonthlyFromWeeklyRent(weeklyRent)
      finalizedRent = monthlyRent.toFixed(2)
    }
    this.setState({
      ...this.state,
      modalDepositInfo: {
        ...this.state.modalDepositInfo,
        deposit_amount: finalizedRent,
      },
      leaseOfferInfo: {
        ...this.state.leaseOfferInfo,
        nextStep: {
          ...this.state.leaseOfferInfo.nextStep,
          deposit: {
            ...this.state.leaseOfferInfo.nextStep.deposit,
            deposit_amount: finalizedRent,
          },
        },
      },
    })
  }

  setPaymentInfoInLeaseDetails = () => {
    const { modalDepositInfo, leaseOfferInfo } = this.state
    const { nextStep } = leaseOfferInfo
    const { deposit, rent } = nextStep || {}
    const {
      deposit_include_eft_details,
      EFTPaymentsV2,
      selected_eft_payment_option,
    } = modalDepositInfo

    if (
      deposit_include_eft_details &&
      selected_eft_payment_option !== undefined
    ) {
      const { EFTPaymentBSB, EFTPaymentAccountNum, EFTPaymentAccountName } =
        EFTPaymentsV2[selected_eft_payment_option]
      this.setState({
        ...this.state,
        leaseOfferInfo: {
          ...this.state.leaseOfferInfo,
          nextStep: {
            ...nextStep,
            deposit: {
              ...deposit,
              deposit_bsb: EFTPaymentBSB,
              deposit_account: EFTPaymentAccountNum,
              deposit_eft_account_name: EFTPaymentAccountName,
              selected_eft_payment_option: selected_eft_payment_option,
            },
            rent: {
              ...rent,
              rent_eft_account_name: EFTPaymentAccountName,
            },
          },
        },
      })
    }
  }

  onModalUpdated = (section) => {
    return (field) => {
      return (data) => {
        const {
          originalOfferInfo: { nextStep },
          leaseOfferInfo,
          calendarMonthlyRent,
          modalLeaseDetails,
          modalRentInfo,
        } = this.state
        const { round_down = false } = modalRentInfo
        const { bond } = leaseOfferInfo.nextStep || {}
        let {
          rent: { rent_amount: originalRentAmount },
          deposit: { deposit_amount: originalDepositAmount },
          bond: { bond_amount: originalBondAmount },
        } = nextStep
        const { include_holding_deposit = false } = leaseOfferInfo.nextStep
        this.turnOnLeavingPrompt()
        if (
          [
            'bond_amount',
            'deposit_amount',
            'rent_amount',
            'weeklyRent',
          ].includes(field)
        ) {
          if (helpers.isOverTwoDecials(data.value)) {
            return
          }
        }
        if (
          [
            'bond_duration',
            'deposit_duration',
            'rent_period',
            'numPets',
            'rentFrequency',
            'waterInclusion',
            'leaseDuration',
          ].includes(field)
        ) {
          if (!isNaN(data.value)) {
            data.value = Number(data.value)
          }
        }
        if (section === 'modalLeaseDetails') {
          if (
            data.error === '' &&
            (field === 'leaseStartDate' || field === 'leaseEndDate')
          ) {
            data.value =
              data.value !== ''
                ? moment(data.value).format(helpers.dateWithDash)
                : ''
          }
        }
        this.setState(
          {
            [section]: {
              ...this.state[section],
              [field]: data.value,
            },
            errors: {
              ...this.state.errors,
              [section]: {
                ...this.state.errors[section],
                [field]: data.error,
              },
            },
          },
          () => {
            const { weeklyRent } = modalLeaseDetails

            if (section === 'modalDepositInfo') {
              if (
                field === 'deposit_amount' &&
                data.value !== originalDepositAmount
              ) {
                this.setState({
                  [section]: {
                    ...this.state[section],
                    deposit_duration: initialDepositDurationObject.custom.index,
                  },
                })
              }
              if (field === 'deposit_due_date') {
                field =
                  field !== ''
                    ? moment(field).format(dateTimeHelpers.DATE_WITH_DASH)
                    : ''
              }
              if (
                field === 'deposit_duration' &&
                data.value < initialDepositDurationArray.length - 1 &&
                data.value > 0
              ) {
                if (
                  initialDepositDurationArray[data.value] ===
                  initialDepositDurationObject['1 month'].text
                ) {
                  const formattedMonthlyRent = formatRent(
                    getMonthlyFromWeeklyRent(weeklyRent),
                    round_down,
                    2,
                  )
                  this.setState({
                    [section]: {
                      ...this.state[section],
                      deposit_amount: formattedMonthlyRent,
                    },
                  })
                  return
                }
                this.setState({
                  [section]: {
                    ...this.state[section],
                    deposit_amount: weeklyRent * data.value,
                  },
                })
              }
              if (field?.includes('deposit_eft_guid')) {
                const updatedStateSection = { ...this.state[section] }
                for (let key in updatedStateSection) {
                  if (key.includes('deposit_eft_guid') && key !== field) {
                    updatedStateSection[key] = false
                  }
                }
                const index =
                  this.state[section][field] === true
                    ? field.split('_')[3]
                    : undefined
                this.setState({
                  [section]: {
                    ...updatedStateSection,
                    selected_eft_payment_option: index,
                  },
                })
              }
            }
            if (section === 'modalRentInfo') {
              if (
                field === 'rent_period' &&
                data.value < initialRentPeriodArray.length - 1 &&
                data.value > 0
              ) {
                const rentAmountResult =
                  calendarMonthlyRent * data.value -
                  (include_holding_deposit
                    ? this.state.leaseOfferInfo.nextStep.deposit.deposit_amount
                    : 0)
                this.setState({
                  [section]: {
                    ...this.state[section],
                    rent_amount:
                      rentAmountResult > 0
                        ? formatRent(rentAmountResult, round_down, 2)
                        : 0,
                  },
                })
              }
              if (field === 'rent_due_date') {
                field =
                  field !== ''
                    ? moment(field).format(dateTimeHelpers.DATE_WITH_DASH)
                    : ''
              }
              if (
                field === 'rent_amount' &&
                data.value !== originalRentAmount
              ) {
                this.setState({
                  [section]: {
                    ...this.state[section],
                    rent_period: 7,
                    round_down: Number.isInteger(Number(data.value))
                      ? this.state.modalRentInfo.round_down
                      : false,
                  },
                })
              }
              if (field === 'round_down') {
                const rentAmountResult =
                  calendarMonthlyRent *
                    (this.state.modalRentInfo.rent_period
                      ? this.state.modalRentInfo.rent_period
                      : originalRentAmount) -
                  (include_holding_deposit
                    ? this.state.leaseOfferInfo.nextStep.deposit.deposit_amount
                    : 0)
                this.state.modalRentInfo.rent_period &&
                  this.setState({
                    [section]: {
                      ...this.state[section],
                      rent_amount:
                        rentAmountResult > 0
                          ? data.value
                            ? Math.floor(rentAmountResult).toFixed(2)
                            : rentAmountResult.toFixed(2)
                          : 0,
                    },
                  })
              }
            }
            if (section === 'modalBondInfo') {
              if (
                field === 'bond_duration' &&
                data.value < initialBondDurationdArray.length - 1 &&
                data.value > 0
              ) {
                const bondDurationMultiplier = Number(
                  initialBondDurationdArray[data.value].split(' ')[0],
                )
                if (bond && bond.bond_amount) {
                  originalBondAmount = bond.bond_amount
                }
                this.setState({
                  [section]: {
                    ...this.state[section],
                    bond_amount:
                      initialBondDurationdArray[data.value] !== '1 month'
                        ? weeklyRent * bondDurationMultiplier
                        : formatRent(
                            getMonthlyFromWeeklyRent(weeklyRent),
                            round_down,
                            2,
                          ),
                  },
                })
              }
              if (field === 'bond_due_date') {
                field =
                  field !== ''
                    ? moment(field).format(dateTimeHelpers.DATE_WITH_DASH)
                    : ''
              }
              if (
                field === 'bond_amount' &&
                data.value !== originalBondAmount
              ) {
                this.setState({
                  [section]: {
                    ...this.state[section],
                    bond_duration: initialBondDurationdArray.length - 1,
                  },
                })
              }
            }
            if (section === 'modalLeaseDetails') {
              if (field === 'leaseSetByType') {
                const { leaseStartDate, leaseDuration } =
                  this.state.modalLeaseDetails
                this.setState({
                  errors: {
                    modalLeaseDetails: {
                      leaseEndDateError: '',
                    },
                  },
                })
                if (data.value === leaseDetailSetByTypeGUIDKey.byDays) {
                  this.setState({
                    [section]: {
                      ...this.state[section],
                      leaseEndDate: this.updateEndDate(
                        leaseStartDate,
                        leaseDuration,
                        'day',
                      ),
                      leaseLength: leaseDuration,
                      isLeaseSetByTypeSelected: true,
                    },
                  })
                } else if (data.value === leaseDetailSetByTypeGUIDKey.byWeeks) {
                  this.setState({
                    [section]: {
                      ...this.state[section],
                      leaseEndDate: this.updateEndDate(
                        leaseStartDate,
                        leaseDuration,
                        'week',
                      ),
                      leaseLength: leaseDuration,
                      isLeaseSetByTypeSelected: true,
                    },
                  })
                } else if (
                  data.value === leaseDetailSetByTypeGUIDKey.byMonths
                ) {
                  this.setState({
                    [section]: {
                      ...this.state[section],
                      leaseEndDate: this.updateEndDate(
                        leaseStartDate,
                        leaseDuration,
                        'month',
                      ),
                      leaseLength: leaseDuration,
                      isLeaseSetByTypeSelected: true,
                    },
                  })
                } else {
                  this.setState({
                    [section]: {
                      ...this.state[section],
                      isLeaseSetByTypeSelected: false,
                      leaseSetByType: leaseDetailSetByTypeGUIDKey.byEndDate,
                      leaseLength: 0,
                    },
                  })
                }
              }
              if (field === 'leaseEndDate') {
                this.setState({
                  [section]: {
                    ...this.state[section],
                    isLeaseSetByTypeSelected: false,
                    leaseSetByType: leaseDetailSetByTypeGUIDKey.byEndDate,
                    leaseLength: 0,
                  },
                })
              }
              if (field === 'leaseDuration') {
                const { leaseStartDate, leaseSetByType } =
                  this.state.modalLeaseDetails
                if (leaseSetByType === leaseDetailSetByTypeGUIDKey.byDays) {
                  this.setState({
                    [section]: {
                      ...this.state[section],
                      leaseEndDate: this.updateEndDate(
                        leaseStartDate,
                        data.value,
                        'day',
                      ),
                      leaseLength: data.value,
                    },
                  })
                } else if (
                  leaseSetByType === leaseDetailSetByTypeGUIDKey.byWeeks
                ) {
                  this.setState({
                    [section]: {
                      ...this.state[section],
                      leaseEndDate: this.updateEndDate(
                        leaseStartDate,
                        data.value,
                        'week',
                      ),
                      leaseLength: data.value,
                    },
                  })
                } else {
                  this.setState({
                    [section]: {
                      ...this.state[section],
                      leaseEndDate: this.updateEndDate(
                        leaseStartDate,
                        data.value,
                        'month',
                      ),
                      leaseLength: data.value,
                    },
                  })
                }
              }
            }
          },
        )
      }
    }
  }

  onModalUploadAttachment = (scope) => {
    const { addAttachmentForAgency } = this.props
    const { applicationId, agencyId } = this.state
    if (scope === attachmentScopeTeam) {
      this.setState({
        showRentalLedgerSpinner: true,
      })
    }
    if (scope === attachmentScopeProperty) {
      this.setState({
        showPropertyAttachmentSpinner: true,
      })
    }
    this.turnOnLeavingPrompt()
    addAttachmentForAgency(applicationId, agencyId, scope)
      .then((result) => {
        const { attachments = [] } = this.state.modalImportantInformation
        this.setState({
          showRentalLedgerSpinner: false,
        })
        result.forEach((dataFile) => {
          ;(attachments || []).forEach((attachment) => {
            if (attachment.Key === dataFile.Key) {
              dataFile.included = attachment.included
            }
          })
        })
        const data = { value: result }
        this.onModalUpdated('modalImportantInformation')('attachments')(data)
      })
      .catch((error) => {
        this.onEnableToast(error)
        this.setState({
          showRentalLedgerSpinner: false,
        })
      })
      .finally(() => {
        this.setState({
          showRentalLedgerSpinner: false,
          showPropertyAttachmentSpinner: false,
        })
      })
  }

  onRenterAcceptOffer() {
    const { acceptApplication } = this.props
    const { applicationId, agencyId } = this.state
    const { leaseOfferInfo } = this.state
    const { nextStep } = leaseOfferInfo || {}
    const { propertyOfferGUID = '', propertyGUID = '' } = nextStep || {}

    if (window.confirm('Are you sure you want to accept this offer?')) {
      this.setState(
        {
          acceptOfferSpinner: true,
          unsavedChanges: false,
        },
        () => {
          acceptApplication(propertyOfferGUID, applicationId)
            .then(() => {
              this.setState({
                acceptOfferSpinner: false,
              })
              history.push(
                `${helpers.urlTo('offeredApplication', {
                  propertyId: propertyGUID,
                  applicationId,
                })}?agencyId=${agencyId}`,
              )
            })
            .catch((error) => {
              this.setState({
                acceptOfferSpinner: false,
              })
              this.onEnableToast(error)
            })
        },
      )
    }
  }

  validateFirstPaymentBeforeSendOffer() {
    const listErrors = []
    const { modalDepositInfo, errors } = this.state
    const {
      deposit_eft_reference,
      deposit_include_eft_details,
      selected_eft_payment_option,
    } = modalDepositInfo
    const { modalDepositInfo: modalDepositInfoErrors = {} } = errors

    if (
      deposit_include_eft_details &&
      (!deposit_eft_reference || modalDepositInfoErrors.deposit_eft_reference)
    ) {
      listErrors.push('EFT Payment Reference')
    }

    if (
      deposit_include_eft_details &&
      selected_eft_payment_option === undefined
    ) {
      listErrors.push('Select the required trust account')
    }
    if (listErrors.length > 0) {
      this.setState(
        {
          ...this.state,
          firstPaymentListErrors: listErrors,
        },
        () => {
          this.onEditClicked('updateOutstandingItems')
        },
      )
      return false
    }

    return true
  }

  onSendOfferClicked(balancePaymentAmount) {
    if (!this.validateFirstPaymentBeforeSendOffer()) {
      return
    }
    const { sendLeaseOffer } = this.props
    const { applicationId, agencyId, modalRentInfo } = this.state
    const { leaseOfferInfo, emailList = [], ccEmailList = [] } = this.state
    const { address = {} } = leaseOfferInfo
    const { state } = address
    const {
      include_bond = false,
      include_holding_deposit = false,
      include_lease_signing_message = false,
      include_rent = false,
      deposit = {},
      rent = {},
      important_information = {},
      lease_signing = {},
      email_message = '',
      include_message_email = false,
      bond = {},
      lease_details = {},
      utility_provider = false,
    } = leaseOfferInfo.nextStep
    const { rent_amount } = modalRentInfo
    const {
      bond_include_bpay_details,
      bond_include_deft_details,
      bond_include_eft_details,
      bond_bpay_reference,
      bond_deft_reference,
      bond_eft_reference,
      bond_pay_online,
    } = bond
    const {
      deposit_include_bpay_details,
      deposit_include_deft_details,
      deposit_include_eft_details,
      deposit_bpay_reference,
      deposit_deft_reference,
      deposit_eft_reference,
    } = deposit
    const { important_message = '' } = important_information
    const { lease_signing_message = '' } = lease_signing
    const {
      rent_include_bpay_details,
      rent_include_deft_reference,
      rent_include_eft_reference,
      rent_bpay_reference,
      rent_eft_reference,
      rent_deft_reference,
    } = rent
    bond.bond_amount = helpers.parseStringToNumber(bond.bond_amount)
    deposit.deposit_amount =
      state === VICTORIA_STATE
        ? helpers.parseStringToRoundedDownNumber(deposit.deposit_amount)
        : helpers.parseStringToNumber(deposit.deposit_amount)
    rent.rent_amount = helpers.parseStringToNumber(
      balancePaymentAmount.toString(),
    )
    lease_details.weeklyRent = helpers.parseStringToNumber(
      lease_details.weeklyRent,
    )
    if (
      include_holding_deposit &&
      !this.noPaymentMethodAvailable() &&
      !deposit_include_bpay_details &&
      !deposit_include_deft_details &&
      !deposit_include_eft_details
    ) {
      this.onEnableToast(
        'Please select the First Payment / Holding Deposit payment method',
      )
      return
    }
    if (
      include_holding_deposit &&
      ((deposit_include_bpay_details && !deposit_bpay_reference) ||
        (deposit_include_deft_details && !deposit_deft_reference) ||
        (deposit_include_eft_details && !deposit_eft_reference))
    ) {
      this.onEnableToast('Please add the First Payment reference number')
      return
    }
    if (
      include_rent &&
      !this.noPaymentMethodAvailable() &&
      !rent_include_bpay_details &&
      !rent_include_deft_reference &&
      !rent_include_eft_reference
    ) {
      this.onEnableToast(
        'Please select the Remainging Balance Payment payment method',
      )
      return
    }
    if (
      !bond_pay_online &&
      include_bond &&
      ((bond_include_bpay_details && !bond_bpay_reference) ||
        (bond_include_deft_details && !bond_deft_reference) ||
        (bond_include_eft_details && !bond_eft_reference))
    ) {
      this.onEnableToast('Please add the Bond Payment reference number')
      return
    }
    if (
      include_rent &&
      ((rent_include_bpay_details && !rent_bpay_reference) ||
        (rent_include_deft_reference && !rent_deft_reference) ||
        (rent_include_eft_reference && !rent_eft_reference))
    ) {
      this.onEnableToast(
        'Please add the Remaining Balance Payment reference number',
      )
      return
    }

    if (
      emailList.find((email) => email.status === 'invalid') ||
      ccEmailList.find((email) => email.status === 'invalid')
    ) {
      this.onEnableToast('Invalid email address')
      return
    }

    if (!lease_signing_message) {
      this.onEnableToast('Please add a Lease Signing message')
      return
    }
    if (!important_message) {
      this.onEnableToast('Please add the Important Information message')
      return
    }
    const noNextStepSectionSelected =
      !include_bond &&
      !include_holding_deposit &&
      !include_lease_signing_message &&
      !include_rent
    if (noNextStepSectionSelected) {
      this.onEnableToast('Please select at least one Next Step section')
      return
    }

    if (include_message_email && !email_message) {
      this.onEnableToast('Please add an email message')
      return
    }
    const emailTextList = emailList.map((email) => email.text)

    const ccEmailTextList = ccEmailList.map((email) => email.text)

    leaseOfferInfo.nextStep.email_recipients = emailTextList.join()
    leaseOfferInfo.nextStep.email_carbon_copy = ccEmailTextList.join()

    leaseOfferInfo.leaseOffer = lease_details
    const leaseOfferAttachments =
      leaseOfferInfo.nextStep.important_information.attachments
    const lengthOfImportantAttachment =
      leaseOfferAttachments && leaseOfferAttachments.length > 0
        ? leaseOfferAttachments.length
        : 0

    const { currentTeam = {} } = this.props
    const { platformDefaultProvider = false } = currentTeam || {}
    const sendReferral = platformDefaultProvider
      ? true
      : this.state.sendConnectionReferral

    const referrals =
      leaseOfferInfo &&
      leaseOfferInfo.referrals &&
      // eslint-disable-next-line array-callback-return
      leaseOfferInfo.referrals.map((referral) => {
        if (referral && referral.name === utility_provider) {
          return {
            ...referral,
            selected: sendReferral,
          }
        }
      })
    leaseOfferInfo.referrals = referrals
    leaseOfferInfo.leaseOffer.leaseOfferVersion = NEW_LEASE_OFFER_VERSION
    if (
      window.confirm(
        `${
          lengthOfImportantAttachment > 0
            ? 'Are you sure you want to send this offer?'
            : 'You have not attached any documents, would you like to continue?'
        }`,
      )
    ) {
      this.setState(
        {
          sendingOfferSpinner: true,
          unsavedChanges: false,
        },
        () => {
          sendLeaseOffer(applicationId, agencyId, leaseOfferInfo)
            .then(() => {
              if (leaseOfferInfo.nextStep.decline_applicants) {
                setTimeout(() => {
                  this.onOfferSentSuccessfully()
                }, 3000)
              } else {
                this.onOfferSentSuccessfully()
              }
            })
            .catch((error) => {
              this.onEnableToast(error)
              this.setState({
                sendingOfferSpinner: false,
              })
            })
        },
      )
    }
  }

  onOfferSentSuccessfully = () => {
    const { emailList = [], ccEmailList = [] } = this.state
    const offerSentToRecepients = emailList
      .map((email) => email.text.split(' ')[0])
      .join(', ')

    const offerCarbonCopy = ccEmailList
      .map((email) => email.text.split(' ')[0])
      .join(', ')

    this.setState({
      sendingOfferSpinner: false,
    })

    snugNotifier.success(
      `${OFFER_SENT_SUCCESS_MESSAGE} 
      to: ${offerSentToRecepients}
      cc: ${offerCarbonCopy}`,
    )

    history.goBack()
  }

  emailKeyDown = (event, arrayKey, valueKey) => {
    const { value } = event.target
    const { keyCode } = event
    const enterButtonKeyCode = 13
    if (keyCode === enterButtonKeyCode) {
      this.emailOnBlur(value, arrayKey, valueKey)
    }
  }

  showResultsForComp = (arrayKey, value) => {
    this.setState({
      showResults: {
        [arrayKey]: value,
      },
    })
  }

  emailOnBlur = (value, arrayKey, valueKey) => {
    if (!value || !value.trim()) {
      this.showResultsForComp(arrayKey, false)
      return
    }
    const emailValueWithoutSpaces = leaseOfferHelpers.removeSpacesInText(value)
    const isEmailValid = !validations.validateEmail(emailValueWithoutSpaces)[0]
    const targetEmail = {
      text: emailValueWithoutSpaces,
      status: isEmailValid ? 'valid' : 'invalid',
    }
    this.setState({
      [arrayKey]: [...this.state[arrayKey], targetEmail],
      [valueKey]: '',
      showResults: {
        [EMAIL_LIST_VALUE_INPUT]: false,
        [CC_EMAIL_LIST_VALUE_INPUT]: false,
      },
    })
  }

  emailOnFocus = (list) => {
    const deDuplicatedRows = this.deDuplicatedRows(
      this.state.combinedManagersList,
      list,
    )
    this.setState({
      showResults: { [list]: true },
      filteredSearchResults: deDuplicatedRows,
    })
  }

  deDuplicatedRows = (rows = [], list) => {
    return rows.filter((searchResultRow) => {
      return !this.state[list].find((email) => {
        return email.text.split(' ')[0] === searchResultRow.email
      })
    })
  }

  filteredItemSelected = (item, inputList, inputValue) => {
    this.emailOnBlur(item.email, inputList, inputValue)
  }

  emailOnchange = (value, arrayKey, valueKey) => {
    const { combinedManagersList, emailList, ccEmailList } = this.state
    this.turnOnLeavingPrompt()
    this.setState({
      [valueKey]: value,
    })
    this.showResultsForComp(arrayKey, true)
    let searchResultRows = combinedManagersList.filter(
      ({
        email,
        firstName,
        lastName,
        mobilePhone,
        phone,
        homePhone,
        workPhone,
      }) =>
        [
          email,
          firstName,
          lastName,
          mobilePhone,
          phone,
          homePhone,
          workPhone,
        ].some((field) =>
          `${field || ''}`.toLowerCase().includes(value.toLowerCase()),
        ),
    )
    if (searchResultRows && searchResultRows.length > 0) {
      searchResultRows = objectHelpers.alphabeticallySortObjectsBasedOnKey(
        searchResultRows,
        'firstName',
      )
    }
    const deDuplicatedRows = this.deDuplicatedRows(
      searchResultRows,
      EMAIL_LIST_VALUE_INPUT,
    )

    this.setState({
      filteredSearchResults: deDuplicatedRows,
    })
  }

  noPaymentMethodAvailable = () => {
    const {
      leaseOfferInfo: { nextStep: { payments_enabled = {} } = {} } = {},
    } = this.state
    const {
      BPAY: bpay = 'false',
      DEFT: deft = 'false',
      EFT: eft = 'false',
    } = payments_enabled
    const disablePaymentStatus = 'false'
    const noPaymentMethodAvailable =
      (bpay === disablePaymentStatus || bpay === '') &&
      (deft === disablePaymentStatus || deft === '') &&
      (eft === disablePaymentStatus || eft === '')
    return noPaymentMethodAvailable
  }

  removeEmailFromList = (emailIndex) => {
    const { emailList } = this.state
    const filteredEmailList = emailList.filter(
      (_, index) => Number(index) !== Number(emailIndex),
    )
    this.turnOnLeavingPrompt()
    this.setState({
      emailList: filteredEmailList,
    })
  }

  removeCCEmailFromList = (emailIndex) => {
    const {
      ccEmailList,
      assignedTeamMemberEmail,
      managerList,
      leaseOfferInfo: {
        nextStep: {
          carbon_copy_listing_agents,
          carbon_copy_finance,
          carbon_copy_operations,
        },
      },
      teamSettingInfo,
    } = this.state

    let isUnCheckCCListingAgents = false
    let isUnCheckCCFinance = false
    let isUnCheckCCOperations = false

    const { application: applicationAttributes = {} } = teamSettingInfo || {}
    const { application_lease_offer_operations_email: operationsEmail } =
      applicationAttributes || {}

    const financeUsers = managerList
      .filter(
        ({ roles, profile: { email } }) =>
          roles.some(({ roleID }) => roleID === AGENCY_USER_FINANCE) &&
          email !== assignedTeamMemberEmail,
      )
      .map(({ profile: { email } }) => email)

    const { text } = ccEmailList.find(
      (_, index) => Number(index) === Number(emailIndex),
    )

    const filteredEmailList = ccEmailList.filter(
      (_, index) => Number(index) !== Number(emailIndex),
    )

    if (carbon_copy_listing_agents && text === assignedTeamMemberEmail) {
      isUnCheckCCListingAgents = true
    }

    if (carbon_copy_operations && text === operationsEmail) {
      isUnCheckCCOperations = true
    }

    if (
      carbon_copy_finance &&
      !financeUsers.some((user) =>
        filteredEmailList
          .map(({ text: emailText }) => emailText)
          .includes(user),
      )
    ) {
      isUnCheckCCFinance = true
    }

    this.setState({
      ccEmailList: filteredEmailList,
      leaseOfferInfo: {
        ...this.state.leaseOfferInfo,
        nextStep: {
          ...this.state.leaseOfferInfo.nextStep,
          carbon_copy_finance: isUnCheckCCFinance ? false : carbon_copy_finance,
          carbon_copy_operations: isUnCheckCCOperations
            ? false
            : carbon_copy_operations,
          carbon_copy_listing_agents: isUnCheckCCListingAgents
            ? false
            : carbon_copy_listing_agents,
        },
      },
    })
  }

  toggleCheckbox = (field) => {
    return (data) => {
      const { nextStep: { deposit: { deposit_amount = 0 } = {} } = {} } =
        this.state.leaseOfferInfo
      this.turnOnLeavingPrompt()
      this.setState({
        leaseOfferInfo: {
          ...this.state.leaseOfferInfo,
          nextStep: {
            ...this.state.leaseOfferInfo.nextStep,
            [field]: data.value,
          },
        },
      })

      if (field === 'include_holding_deposit') {
        if (data.value) {
          this.setState({
            modalRentInfo: {
              ...this.state.modalRentInfo,
              rent_amount:
                this.state.modalRentInfo.rent_amount - deposit_amount,
            },
          })
          this.state.leaseOfferInfo.nextStep.rent.rent_amount =
            this.state.leaseOfferInfo.nextStep.rent.rent_amount - deposit_amount
        }
        if (!data.value) {
          this.setState({
            modalRentInfo: {
              ...this.state.modalRentInfo,
              rent_amount:
                this.state.modalRentInfo.rent_amount + deposit_amount,
            },
          })
          this.state.leaseOfferInfo.nextStep.rent.rent_amount =
            this.state.leaseOfferInfo.nextStep.rent.rent_amount + deposit_amount
        }
      }

      if (field === 'carbon_copy_finance') {
        const {
          managerList,
          ccEmailList,
          assignedTeamMemberEmail,
          leaseOfferInfo: {
            nextStep: { carbon_copy_listing_agents, carbon_copy_operations },
          },
        } = this.state
        const financeUsers = managerList.filter(({ roles }) =>
          roles.some(({ roleID }) => roleID === AGENCY_USER_FINANCE),
        )
        let ccEmailArray = [
          ...ccEmailList,
          ...financeUsers.map(({ profile: { email } }) => ({
            text: email,
          })),
        ]

        let removeCcEmailList = ccEmailList.filter(({ text }) => {
          if (
            assignedTeamMemberEmail &&
            (carbon_copy_listing_agents || carbon_copy_operations) &&
            text === assignedTeamMemberEmail
          ) {
            return true
          }
          return !financeUsers
            .map(({ profile: { email } }) => email)
            .includes(text)
        })

        ccEmailArray = [...new Set(ccEmailArray.map((item) => item.text))].map(
          (email) => ({ text: email }),
        )

        if (data.value) {
          this.setState({
            ccEmailList: ccEmailArray,
          })
        }
        if (!data.value) {
          this.setState({
            ccEmailList: removeCcEmailList,
          })
        }
      }

      if (
        field === 'carbon_copy_listing_agents' ||
        field === 'carbon_copy_operations'
      ) {
        const { assignedTeamMemberEmail, ccEmailList, teamSettingInfo } =
          this.state

        const { application: applicationAttributes = {} } =
          teamSettingInfo || {}

        const {
          application_lease_offer_always_copy_lease_ops:
            alwaysCopyOperationEmail,
          application_lease_offer_operations_email: operationsEmail,
          application_lease_offer_lease_ops_as_contact:
            operationEmailAsLeasingContact,
        } = applicationAttributes || {}

        let toCCEmail
        if (field === 'carbon_copy_listing_agents')
          toCCEmail = assignedTeamMemberEmail
        if (field === 'carbon_copy_operations') toCCEmail = operationsEmail

        if (toCCEmail) {
          const ccEmailArray = [
            ...ccEmailList,
            {
              text: toCCEmail,
            },
          ]

          const uniqueEmailList = [
            ...new Set(ccEmailArray.map((item) => item.text)),
          ].map((email) => ({ text: email }))
          if (data.value) {
            this.setState({
              ccEmailList: uniqueEmailList,
            })
          }
          if (!data.value) {
            this.setState({
              ccEmailList: ccEmailList.filter(({ text }) => text !== toCCEmail),
            })
          }
        }
      }
    }
  }

  toggleSendToAllHouseHold = () => {
    const { leaseOfferInfo, emailList, secondaryEmailList = [] } = this.state
    const { nextStep } = leaseOfferInfo || {}
    const { send_to_all_household: sendToAll } = nextStep || {}
    if (!secondaryEmailList || !secondaryEmailList?.length) {
      this.setState({
        leaseOfferInfo: {
          ...leaseOfferInfo,
          nextStep: {
            ...nextStep,
            send_to_all_household: !sendToAll,
          },
        },
      })
      return
    }
    if (!sendToAll) {
      emailList.push(...secondaryEmailList)
    } else {
      const secondaryEmailsIndexes = secondaryEmailList.map((emailElement) =>
        emailList.map((e) => e.text).indexOf(emailElement.text),
      )
      secondaryEmailsIndexes.forEach((i) => i >= 0 && emailList.splice(i, 1))
    }
    this.setState({
      leaseOfferInfo: {
        ...leaseOfferInfo,
        nextStep: {
          ...nextStep,
          send_to_all_household: !sendToAll,
        },
      },
      emailList,
    })
  }

  turnOnLeavingPrompt = () => {
    this.setState({
      unsavedChanges: true,
    })
    window.onbeforeunload = () => true
  }

  updateCommentSections = (field) => {
    return (data) => {
      this.turnOnLeavingPrompt()
      this.setState({
        leaseOfferInfo: {
          ...this.state.leaseOfferInfo,
          nextStep: {
            ...this.state.leaseOfferInfo.nextStep,
            [field]: data.value,
          },
        },
      })
    }
  }

  updateEndDate(startDate, duration, dateType) {
    const endDate = moment(startDate)
      .add(duration, dateType)
      .subtract(1, 'day') // calculated endDate is inclusive so need to subtract one day
      .format('YYYY-MM-DD')
    return endDate
  }

  updateSendUtilityConnecttion = () => {
    return (data) => {
      this.setState({
        sendConnectionReferral: data.value,
      })
    }
  }

  onChangeLeasingModal = (key) => {
    return (data) => {
      if (key === 'useOpsContactEmailAddress') {
        const { teamSettingInfo, initialLeasingContact } = this.state
        const { leasingContactEmail: initialLeasingContactEmail } =
          initialLeasingContact
        const { application: applicationAttributes = {} } =
          teamSettingInfo || {}
        const { application_lease_offer_operations_email: operationsEmail } =
          applicationAttributes || {}
        this.setState({
          modalLeasingContact: {
            ...this.state.modalLeasingContact,
            leasingContactEmail: data.value
              ? operationsEmail
              : initialLeasingContactEmail,
          },
        })
      }

      this.setState({
        [key]: data.value,
      })
    }
  }

  renderPMSConfig = ({ pms }) => {
    const { currentTeam = {} } = this.props
    const { teamSettingInfo, leaseOfferInfo: { nextStep } = {} } = this.state
    const brand = Object.values(pmsBrands).find(({ id }) => pms === id)
    if (!brand || !isSendApplicantEnabled(pms, currentTeam?.slug)) return

    const option = brand.offerConfig

    return (
      <div className="offer-lease-checkbox-wrapper">
        <SendApplicationToPMS
          pms={pms}
          teamSlug={currentTeam?.slug}
          integrationSettings={teamSettingInfo.integrations}
          pmsConfig={nextStep}
          onChange={this.toggleCheckbox(option)}
          sendOption="offerConfig"
        />
      </div>
    )
  }

  render() {
    const { currentUser } = this.props
    const isUserManager = window.location.pathname.includes('/manager')
    const isUserRenter = window.location.pathname.includes('/renter')
    const {
      ccEmailListemailList = [],
      ccEmailList = [],
      ccEmailValue = '',
      emailValue = '',
      errors = {},
      activeModal = '',
      leaseOfferInfo = {},
      modalBondInfo = {},
      modalDepositInfo = {},
      modalRentInfo = {},
      modalImportantInformation = {},
      modalLeaseDetails = {},
      modalLeasingSigning = {},
      managerNameList,
      chosenManager,
      showRentalLedgerSpinner = false,
      sendingOfferSpinner = false,
      acceptOfferSpinner = false,
      unsavedChanges = false,
      withdrawOfferSpinner = false,
      calendarMonthlyRent = 0,
      leaseOfferId = '',
      sendConnectionReferral,
      sendApplicationToConsole,
      teamSettingInfo,
      activeSendingOnboardIntegrations,
      showPropertyAttachmentSpinner = false,
      hasPropertyDisclosure,
      propertyDisclosures,
      error,
      secondaryEmailList = [],
      assignedTeamMemberEmail = '',
      managerList = [],
      showResults = {
        [CC_EMAIL_LIST_VALUE_INPUT]: false,
        [EMAIL_LIST_VALUE_INPUT]: false,
      },
      filteredSearchResults = [],
      emailList,
      useOpsContactEmailAddress = false,
    } = this.state

    const isFinanceTeamMember = managerList.some(({ roles }) =>
      roles.some(({ roleID }) => roleID === AGENCY_USER_FINANCE),
    )

    const {
      bedrooms = 0,
      bathrooms = 0,
      carports = 0,
      parking = 0,
      garages = 0,
      address = {},
    } = leaseOfferInfo
    const propertyInfo = {
      parking: Number.parseInt(parking),
      carports: Number.parseInt(carports),
      garages: Number.parseInt(garages),
    }
    const { friendlyName, state } = address
    const { nextStep = {}, application = {} } = leaseOfferInfo
    const { isManagerAdded, applicants } = application
    const primaryApplicant =
      (applicants && applicants.find((applicant) => applicant.isPrimary)) || {}
    const { guidID: primaryApplicantGUID = '' } = primaryApplicant || {}
    const {
      lease_details = {},
      leasing_contact = {},
      deposit = {},
      rent = {},
      bond = {},
      important_information = {},
      message: congratulationMessage = '',
      lease_signing = {},
      include_bond,
      include_holding_deposit,
      include_lease_signing_message,
      include_rent,
      include_message_email,
      include_send_to_console,
      include_ray_white_concierge,
      include_send_to_property_tree,
      email_message,
      is_pets_allowed: offeredIsPetsAllowed,
      posting_clause: offeredPostingClause,
      payments_enabled = {},
      opted_to_receive_utility_connection,
      utility_provider,
      cancel_future_viewings,
      decline_applicants,
      send_to_all_household,
      send_property_under_offer,
      carbon_copy_listing_agents,
      carbon_copy_finance,
      carbon_copy_operations,
    } = nextStep
    const {
      BPAY: enableBPay,
      DEFT: enableDEFT,
      EFT: enableEFT,
    } = payments_enabled
    const { EFTPaymentsV2 } = modalDepositInfo
    const isBPayEnabled = enableBPay === 'true'
    const isDEFTEnabled = enableDEFT === 'true'
    const isEFTEnabled = enableEFT === 'true'
    let {
      leaseLength: offeredLeaseLength,
      leaseStartDate: offeredLeaseStartDate,
      leaseEndDate: offeredLeaseEndDate,
      weeklyRent: offeredWeeklyRent,
      leaseStatus: offeredLeaseStatus,
      leaseIssuedDate: offeredLeaseIssuedDate,
      numPets: offeredNumPets,
      rentFrequency: offeredRentFrequency,
      leaseOfferVersion: offeredLeaseOfferVersion,
      waterInclusion: offeredWaterInclusion,
      waterInclusionComment: offeredWaterInclusionComment,
      leaseSetByType: offeredLeaseSetByType,
    } = lease_details

    const {
      brandingLogoURL,
      contactName: agencyName,
      contactNumber: agencyContactNumber,
      leasingContactEmail: offeredLeasingContactEmail,
      leasingContactName: offeredLeasingContactName,
    } = leasing_contact
    const showMakeMovingEasier = utility_provider && utility_provider.length > 0
    const makeMovingEasierMessage = `${utility_provider} ${DEFAULT_UCS_MAKE_MOVING_EASIER_MESSAGE}`
    const formattedContactNumber = formatPhoneNumber(agencyContactNumber)
    const { lease_signing_message: offeredLeaseSigningMessage } = lease_signing
    const {
      deposit_account: offeredDepositAccount,
      deposit_amount: offeredDepositAmount,
      deposit_biller: offeredDepositBiller,
      deposit_bpay_reference: offeredDepositBPayReference,
      deposit_bsb: offeredDepositBSB,
      deposit_deft_reference: offeredDeftReference,
      deposit_duration: offeredDepositDuration,
      deposit_eft_reference: offeredDepositEftReference,
      deposit_include_bpay_details: offeredDepositIncludeBPayDetails,
      deposit_include_deft_details: offeredDepositIncludeDeftDetails,
      deposit_include_eft_details: offeredDepositIncludeEftDetails,
      deposit_message: offeredDepositMessage,
      deposit_due_date: offeredDepositDueDate,
      deposit_set_as_deposit: offeredSetAsDeposit,
      deposit_eft_account_name: offeredDepositEftAccountName,
    } = deposit
    const {
      bond_account: offeredBondAccount,
      bond_amount: offeredBondAmount,
      bond_biller: offeredBondBiller,
      bond_bpay_reference: offeredBondBPayReference,
      bond_bsb: offeredBondBSB,
      bond_deft_reference: offeredBondDeftReference,
      bond_due_date: offeredBondDueDate,
      bond_duration: offeredBondDuration,
      bond_eft_reference: offeredBondEftReference,
      bond_include_bpay_details: offeredBondIncludeBPayDetails,
      bond_include_deft_details: offeredBondIncludeDeftDetails,
      bond_include_eft_details: offeredBondIncludeEftDetails,
      bond_message: offeredBondMessage,
      bond_pay_online: offeredBondPayOnline,
      bond_eft_account_name: offeredBondEftAccountName,
    } = bond
    const {
      rent_account: offeredRentAccount,
      rent_amount: offeredRentAmount,
      rent_biller: offeredRentBiller,
      rent_bpay_reference: offeredRentBPayReference,
      rent_bsb: offeredRentBsb,
      rent_eft_reference: offeredRentEftReference,
      rent_deft_reference: offeredRentDeftReference,
      rent_include_bpay_details: offeredRentIncludeBPayDetails,
      rent_include_deft_reference: offeredRentIncludeDeftReference,
      rent_include_eft_reference: offeredIncludeEftReference,
      rent_message: offeredRentMessage,
      rent_due_date: offeredRentDueDate,
      pay_rent_in_advance: offeredPayRentInAdvance,
      rent_eft_account_name: offeredRentEftAccountName,
    } = rent
    const {
      attachments: offeredAttachment = [],
      important_message: offeredImportantMessage = '',
    } = important_information
    const {
      modalDepositInfo: {
        deposit_amount: deposit_amount_error = '',
        deposit_bpay_reference: deposit_bpay_reference_error = '',
        deposit_eft_reference: deposit_eft_reference_error = '',
        deposit_deft_reference: deposit_deft_reference_error = '',
        deposit_due_date: deposit_due_date_error = '',
      } = {},
      modalRentInfo: {
        rent_amount: rent_amount_error,
        rent_bpay_reference: rent_bpay_reference_error,
        rent_eft_reference: rent_eft_reference_error,
        rent_deft_reference: rent_deft_reference_error,
        rent_due_date: rent_due_date_error = '',
      } = {},
      modalBondInfo: {
        bond_amount: bond_amount_error,
        bond_deft_reference: bond_deft_reference_error,
        bond_bpay_reference: bond_bpay_reference_error,
        bond_eft_reference: bond_eft_reference_error,
        bond_due_date: bond_due_date_error = '',
      } = {},
      modalLeaseDetails: {
        weeklyRent: weeklyRent_error,
        leaseStartDate: leaseStartDateError,
        leaseEndDate: leaseEndDateError,
        leaseDuration: leaseDurationError,
      } = {},
    } = errors
    const { lease_signing_message } = modalLeasingSigning
    const { important_message = '', attachments = [] } =
      modalImportantInformation
    const agencyAttachments = (attachments || []).filter(
      (attachment) =>
        attachment.scope === '' || attachment.scope === attachmentScopeTeam,
    )
    const propertyAttachments = (attachments || []).filter(
      (attachment) => attachment.scope === attachmentScopeProperty,
    )
    const {
      leaseStartDate = '',
      leaseEndDate = '',
      weeklyRent = 0,
      posting_clause = false,
      is_pets_allowed = false,
      leaseSetByType = '',
      leaseDuration = 0,
      isLeaseSetByTypeSelected = false,
      numPets,
      rentFrequency,
      waterInclusion,
      waterInclusionComment = '',
    } = modalLeaseDetails
    const formattedLeaseStartDate =
      leaseStartDateError !== dateTimeHelpers.INVALID_DATE_STRING
        ? leaseStartDate &&
          moment(leaseStartDate).format(dateTimeHelpers.DATE_WITH_SLASH)
        : leaseStartDate
    const formattedLeaseEndDate =
      leaseEndDateError !== dateTimeHelpers.INVALID_DATE_STRING
        ? leaseEndDate &&
          moment(leaseEndDate).format(dateTimeHelpers.DATE_WITH_SLASH)
        : leaseEndDate

    const formattedLeaseStartDateForDuration = moment(
      leaseStartDate,
      'YYYY-MM-DD',
    )
    const formattedLeaseEndDateForDuration = moment(leaseEndDate, 'YYYY-MM-DD')

    const leaseLengthByWeeks = Math.round(
      formattedLeaseEndDateForDuration.diff(
        formattedLeaseStartDateForDuration,
        'weeks',
        true,
      ),
    ).toString()
    const leaseLengthByMonths = Math.round(
      formattedLeaseEndDateForDuration.diff(
        formattedLeaseStartDateForDuration,
        'months',
        true,
      ),
    ).toString()

    const {
      rent_amount,
      rent_bpay_reference,
      rent_deft_reference,
      rent_eft_reference,
      rent_include_bpay_details,
      rent_include_eft_reference,
      rent_include_deft_reference,
      rent_period,
      pay_rent_in_advance,
      round_down,
      rent_due_date,
    } = modalRentInfo
    const {
      bond_amount,
      bond_bpay_reference,
      bond_deft_reference,
      bond_duration,
      bond_eft_reference,
      bond_include_bpay_details,
      bond_include_deft_details,
      bond_include_eft_details,
      bond_pay_online,
      bond_due_date,
    } = modalBondInfo
    const {
      deposit_amount,
      deposit_bpay_reference,
      deposit_duration,
      deposit_eft_reference,
      deposit_deft_reference,
      deposit_include_bpay_details,
      deposit_include_eft_details,
      deposit_include_deft_details,
      deposit_set_as_deposit,
      deposit_due_date,
    } = modalDepositInfo
    const formattedDueDate =
      deposit_due_date_error !== dateTimeHelpers.INVALID_DATE_STRING
        ? deposit_due_date &&
          moment(deposit_due_date).format(dateTimeHelpers.DATE_WITH_SLASH)
        : deposit_due_date
    const formattedBondDueDate =
      bond_due_date_error !== dateTimeHelpers.INVALID_DATE_STRING
        ? bond_due_date &&
          moment(bond_due_date).format(dateTimeHelpers.DATE_WITH_SLASH)
        : bond_due_date
    const formattedRentDueDate =
      rent_due_date_error !== dateTimeHelpers.INVALID_DATE_STRING
        ? rent_due_date &&
          moment(rent_due_date).format(dateTimeHelpers.DATE_WITH_SLASH)
        : rent_due_date
    const isOfferOffered =
      helpers.ApplicationCategory[offeredLeaseStatus] === statusOffered
    const isOfferAccepted =
      helpers.ApplicationCategory[offeredLeaseStatus] === statusAccepted
    const enableEdit = isUserManager && !isOfferOffered && !isOfferAccepted

    const isUserLoggedIn = isLoggedIn(currentUser)
    const isLeaseOfferConfirmed = !!leaseOfferId

    let balancePaymentAmount = 0
    if (isLeaseOfferConfirmed) {
      balancePaymentAmount = offeredRentAmount
    } else if (offeredPayRentInAdvance) {
      balancePaymentAmount = offeredRentAmount > 0 ? offeredRentAmount : 0
    } else {
      balancePaymentAmount =
        calendarMonthlyRent -
          (include_holding_deposit ? offeredDepositAmount : 0) >
        0
          ? calendarMonthlyRent -
            (include_holding_deposit ? offeredDepositAmount : 0)
          : 0
    }

    if (round_down && enableEdit) {
      balancePaymentAmount = Math.floor(balancePaymentAmount)
    }

    let depositAmount =
      state === VICTORIA_STATE
        ? Math.floor(Number(offeredDepositAmount))
        : Number(offeredDepositAmount)

    if (round_down) {
      depositAmount = Math.floor(depositAmount)
    }

    const { currentTeam = {} } = this.props
    const { platformDefaultProvider = false } = currentTeam || {}

    const textForUtilityCheckBox = platformDefaultProvider ? (
      <DefaultUtilityProviderComponent />
    ) : (
      `Send ${utility_provider} referral`
    )

    const sendReferral = platformDefaultProvider
      ? true
      : this.state.sendConnectionReferral

    const textForNoPaymentAvailable = (
      <p className="fw600 mb0">
        Add payment options by contacting
        <a className="pink-color pl5" href="mailto:help@snug.com">
          help@snug.com
        </a>
      </p>
    )

    const textForConfigurePayment =
      'Select available payment options and add the payment reference'

    const { applicationId } = this.state
    const propertyGUID = nextStep && nextStep.propertyGUID
    const applicationDetailsURL = helpers.urlTo('renterApplication', {
      propertyId: propertyGUID,
      applicationId,
      applicantId: primaryApplicantGUID,
    })
    const { integrations = {}, application: applicationAttributes = {} } =
      teamSettingInfo || {}
    const {
      round_payments_down_nearest_dollar:
        roundPaymentsDownNearestDollar = false,
    } = applicationAttributes || {}
    const { application_lease_offer_operations_email: operationsEmail = '' } =
      applicationAttributes || {}
    const { ray_white_concierge_enabled = 'false' } = integrations
    const displayApplicationDetailsURL =
      isUserManager && (isOfferOffered || isOfferAccepted)
    if (!!error) {
      return <ErrorMessage error={error} />
    }

    const selectedLeaseDetailType = initialLeaseDetailSetByType.find(
      (type) => type.guid.toString() === offeredLeaseSetByType,
    )

    const leaseTermText =
      selectedLeaseDetailType &&
      selectedLeaseDetailType.guid !== leaseDetailSetByTypeGUIDKey.byEndDate &&
      selectedLeaseDetailType.guid !== leaseDetailSetByTypeGUIDKey.byMonths
        ? `${offeredLeaseLength} ${selectedLeaseDetailType.name.toLocaleLowerCase()}`
        : `${offeredLeaseLength} months`

    return (
      <div>
        {enableEdit && (
          <Display.CenterContentContainer componentClass="col-xs-12 p0">
            <Display.FlexContainer containerClass="flex-direction-column">
              <Headers.MainHeader text="Send offer" />
              <div className="font-size-20 fw500">
                Preview and update offer details below. Email the successful
                primary applicant. Only the primary applicant can accept.
              </div>
            </Display.FlexContainer>
            <div className="email-input-border-bottom">
              <InputFieldWithSearchDropdown
                label="To"
                inputList={emailList}
                changeInputList={'emailList'}
                inputValue={emailValue}
                changeInputValue={'emailValue'}
                removeFromListFunc={this.removeEmailFromList}
                showResults={showResults[EMAIL_LIST_VALUE_INPUT]}
                filteredSearchResults={filteredSearchResults}
                emailOnChange={this.emailOnchange}
                emailOnBlur={this.emailOnBlur}
                emailKeyDown={this.emailKeyDown}
                filteredItemSelected={this.filteredItemSelected}
                emailOnFocus={() => this.emailOnFocus(EMAIL_LIST_VALUE_INPUT)}
              />

              {(ccEmailList.length > 0 ||
                carbon_copy_finance ||
                carbon_copy_listing_agents ||
                carbon_copy_operations) && (
                <>
                  <InputFieldWithSearchDropdown
                    label="cc"
                    inputList={ccEmailList}
                    changeInputList={'ccEmailList'}
                    inputValue={ccEmailValue}
                    changeInputValue={'ccEmailValue'}
                    removeFromListFunc={this.removeCCEmailFromList}
                    showResults={showResults[CC_EMAIL_LIST_VALUE_INPUT]}
                    filteredSearchResults={filteredSearchResults}
                    emailOnChange={this.emailOnchange}
                    emailOnBlur={this.emailOnBlur}
                    emailKeyDown={this.emailKeyDown}
                    filteredItemSelected={this.filteredItemSelected}
                    emailOnFocus={() =>
                      this.emailOnFocus(CC_EMAIL_LIST_VALUE_INPUT)
                    }
                  />
                </>
              )}
            </div>
            <Box mt={`${theme.space[5]}px`} mb={`${theme.space[2]}px`}>
              <CCLeaseOffers alignItems="center">
                <Box mr={`${theme.space[7]}px`}>
                  <Form.CheckBoxGeneral
                    label="Send to all applicants"
                    checked={send_to_all_household}
                    onChange={this.toggleSendToAllHouseHold}
                    componentClassName="align-self-normal-mobile mt10-mobile"
                  />
                </Box>
                <div className="font-size-16 fw500 mr10 grey-text mt10 pb20">
                  cc:
                </div>
                <Box>
                  <Form.CheckBoxGeneral
                    label="Listing agents"
                    checked={carbon_copy_listing_agents}
                    onChange={this.toggleCheckbox('carbon_copy_listing_agents')}
                    componentClassName="align-self-normal-mobile mt10-mobile"
                  />
                </Box>
                <Box>
                  <Form.CheckBoxGeneral
                    label="Finance"
                    checked={carbon_copy_finance}
                    onChange={this.toggleCheckbox('carbon_copy_finance')}
                    componentClassName="align-self-normal-mobile mt10-mobile"
                  />
                </Box>
                {!!operationsEmail.length && (
                  <Box>
                    <Form.CheckBoxGeneral
                      label="Lease Ops"
                      checked={carbon_copy_operations}
                      onChange={this.toggleCheckbox('carbon_copy_operations')}
                      componentClassName="align-self-normal-mobile mt10-mobile"
                    />
                  </Box>
                )}
              </CCLeaseOffers>
            </Box>
            <Text.Strong text="Select to include and send the following sections:" />
            <Display.FlexContainer containerClass="flex-wrap-wrap flex-direction-column-mobile">
              <div className="offer-lease-checkbox-wrapper">
                <Form.CheckBoxGeneral
                  label="First Payment"
                  checked={include_holding_deposit}
                  onChange={this.toggleCheckbox('include_holding_deposit')}
                  componentClassName="align-self-normal-mobile mt10-mobile"
                />
              </div>
              <div className="offer-lease-checkbox-wrapper">
                <Form.CheckBoxGeneral
                  label="Bond Payment"
                  checked={include_bond}
                  onChange={this.toggleCheckbox('include_bond')}
                  componentClassName="align-self-normal-mobile mt10-mobile"
                />
              </div>
              <div className="offer-lease-checkbox-wrapper">
                <Form.CheckBoxGeneral
                  label="Remaining Balance Payment"
                  checked={include_rent}
                  onChange={this.toggleCheckbox('include_rent')}
                  componentClassName="align-self-normal-mobile mt10-mobile"
                />
              </div>
              <div className="offer-lease-checkbox-wrapper">
                <Form.CheckBoxGeneral
                  label="Lease Signing"
                  checked={include_lease_signing_message}
                  onChange={this.toggleCheckbox(
                    'include_lease_signing_message',
                  )}
                  componentClassName="align-self-normal-mobile mt10-mobile"
                />
              </div>
              <div className="offer-lease-checkbox-wrapper">
                <Form.CheckBoxGeneral
                  label="Message in email"
                  checked={include_message_email}
                  onChange={this.toggleCheckbox('include_message_email')}
                  componentClassName="align-self-normal-mobile mt10-mobile"
                />
              </div>

              {utility_provider && (
                <div className="offer-lease-checkbox-wrapper">
                  <Form.CheckBoxGeneral
                    label={
                      opted_to_receive_utility_connection
                        ? textForUtilityCheckBox
                        : 'Opted out of utility connection'
                    }
                    checked={sendReferral}
                    onChange={this.updateSendUtilityConnecttion()}
                    componentClassName="align-self-normal-mobile mt10-mobile"
                    disabled={!opted_to_receive_utility_connection}
                    checkboxInputClassName={
                      platformDefaultProvider && 'disabled-grey'
                    }
                  />
                </div>
              )}
              {sendApplicationToConsole && (
                <div className="offer-lease-checkbox-wrapper">
                  <Form.CheckBoxGeneral
                    label={`Send to ${consoleCloudFeatureText}`}
                    checked={include_send_to_console}
                    onChange={this.toggleCheckbox('include_send_to_console')}
                    componentClassName="align-self-normal-mobile mt10-mobile"
                  />
                </div>
              )}
              {ray_white_concierge_enabled === 'true' && (
                <div className="offer-lease-checkbox-wrapper">
                  <Form.CheckBoxGeneral
                    label={RAY_WHITE_CONCIERGE_SEND_LABEL}
                    checked={include_ray_white_concierge}
                    onChange={this.toggleCheckbox(
                      'include_ray_white_concierge',
                    )}
                    componentClassName="align-self-normal-mobile mt10-mobile"
                  />
                </div>
              )}

              {activeSendingOnboardIntegrations &&
                activeSendingOnboardIntegrations.map(this.renderPMSConfig)}
            </Display.FlexContainer>
            {include_message_email && (
              <Display.FlexContainer>
                <div className="mt20 width100">
                  <Text.Strong text="Message" />
                  <Form.CommentArea
                    value={email_message}
                    placeholder="Enter your message to include in the email"
                    onChange={this.updateCommentSections('email_message')}
                    id="message"
                    cols={50}
                    rows={3}
                    isOptional
                    inputBoxClass="pt0"
                  />
                </div>
              </Display.FlexContainer>
            )}
            <Box>
              <Form.CheckBoxGeneral
                label="Notify other Approved Applicants that property is offered"
                checked={send_property_under_offer}
                onChange={this.toggleCheckbox('send_property_under_offer')}
                componentClassName="align-self-normal-mobile mt10-mobile"
              />
            </Box>
            <div>
              <Form.CheckBoxGeneral
                label="Cancel future viewings, notify enquirers/registered and tenants"
                checked={cancel_future_viewings}
                onChange={this.toggleCheckbox('cancel_future_viewings')}
                componentClassName="align-self-normal-mobile mt10-mobile"
              />
            </div>
            <div>
              <ReactTooltip id="declineTimeOutDesciption">
                {textHelpers.DECLINE_OTHER_HOUSEHOLD_TOOLTIP_CONTENT}
              </ReactTooltip>
            </div>
            <div>
              <Form.CheckBoxGeneral
                label="Decline household groups, except Approved Applicants"
                checked={decline_applicants}
                onChange={this.toggleCheckbox('decline_applicants')}
                componentClassName="align-self-normal-mobile mt10-mobile"
                toolTip={
                  <div>
                    <i
                      className="icon-help-outline fs16 gray-light-color ibm"
                      data-tooltip-content=""
                      data-tooltip-id="declineTimeOutDesciption"
                    />
                  </div>
                }
              />
            </div>

            <Display.FlexContainer containerClass="justify-content-space-between mt20 mb40">
              <div
                className="btn btn-transparent btn-left wa"
                onClick={() => history.goBack()}
                role="button"
                tabIndex="0"
              >
                <span>Cancel</span>
              </div>
              <Display.GenericButton
                text="Send"
                size="large"
                onClick={() => this.onSendOfferClicked(balancePaymentAmount)}
                disabled={sendingOfferSpinner}
                isLoading={sendingOfferSpinner}
              />
            </Display.FlexContainer>
          </Display.CenterContentContainer>
        )}
        {displayApplicationDetailsURL && (
          <Display.CenterContentContainer componentClass="col-xs-12 p0 lease-offer-application-url-position text-align-center">
            <div>
              <span>
                <Link to={applicationDetailsURL}>View application</Link>
              </span>
            </div>
          </Display.CenterContentContainer>
        )}
        <Display.CenterContentContainer
          componentClass="col-xs-12 p0 background-grey-mobile-excluded"
          containerClass="background-grey-mobile-excluded"
        >
          <Display.CenterContentContainer
            componentClass="col-xs-12 p0 background-grey-mobile-excluded"
            containerClass="background-grey-mobile-excluded mt40-desktop"
          >
            <Display.FlexContainer containerClass="justify-content-space-between mb20 flex-direction-column-reverse-mobile ">
              <div>
                <Headers.MainHeader text="Offer" componentClass="mb10" />
                {isUserRenter && isOfferAccepted && (
                  <Text.Normal
                    text={`Issued: ${moment(offeredLeaseIssuedDate).format(
                      'ddd DD MMM YYYY',
                    )}`}
                    componentClass="font-size-12"
                  />
                )}
              </div>
              {brandingLogoURL && (
                <div className="branding-logo-container mb20">
                  <img src={brandingLogoURL} alt="Agency Logo" />
                </div>
              )}
            </Display.FlexContainer>
            <Display.PropertyInfoDetails
              address={friendlyName}
              bathrooms={bathrooms}
              bedrooms={bedrooms}
              propertyInfo={propertyInfo}
            />
            <Display.CenterContentContainer
              componentClass="application-standard-shadow-box col-xs-12 summary-box-on-mobile border-radius-10px"
              sizeClass="mb30"
            >
              <Headers.SectionHeader
                text="Congratulations!"
                textClass="fw600 ml24-mobile"
              />
              <Display.SectionContentContainer componentClass="p24">
                <Display.FlexContainer>
                  <Text.Normal text={congratulationMessage} textClass="lh14" />
                </Display.FlexContainer>
              </Display.SectionContentContainer>
            </Display.CenterContentContainer>
            <Display.FlexContainer containerClass="flex-direction-column-mobile">
              <Display.WhiteBoxContainer componentClass="margin-report-block-left">
                <Display.ContainerHeader
                  text="Lease details"
                  componentClass="mb20"
                  editBtnClass="btn hollow-button-green attachment-edit-button"
                  onEditClick={() => this.onEditClicked('leaseDetails')}
                  isEditable={enableEdit}
                />
                <div>
                  <Display.FlexContainer>
                    <Text.Strong text="Term" componentClass="width50p" />
                    <Text.Normal text={leaseTermText} />
                  </Display.FlexContainer>
                  <Display.FlexContainer>
                    <Text.Strong text="Start" componentClass="width50p" />
                    <Text.Normal
                      text={moment(offeredLeaseStartDate).format(
                        'ddd, DD MMM YYYY',
                      )}
                    />
                  </Display.FlexContainer>
                  <Display.FlexContainer>
                    <Text.Strong text="End" componentClass="width50p" />
                    <Text.Normal
                      text={moment(offeredLeaseEndDate).format(
                        'ddd, DD MMM YYYY',
                      )}
                    />
                  </Display.FlexContainer>
                  <Display.FlexContainer>
                    <Text.Strong text="Rent" componentClass="width50p" />
                    <Text.Normal text={`$${offeredWeeklyRent} / w`} />
                  </Display.FlexContainer>
                  {offeredIsPetsAllowed && (
                    <Display.FlexContainer>
                      <Text.Strong text="Pets" componentClass="width50p" />
                      <Text.Normal text="Yes" />
                    </Display.FlexContainer>
                  )}
                  {offeredPostingClause && (
                    <Display.FlexContainer>
                      <Text.Strong
                        text="Posting clause"
                        componentClass="width50p"
                        imgSrc={calendar}
                        imgAlt="Calender Icon"
                      />
                      <Text.Strong text="Yes" />
                    </Display.FlexContainer>
                  )}
                  {offeredLeaseOfferVersion === NEW_LEASE_OFFER_VERSION && (
                    <NewLeaseOfferVersionContent
                      offeredNumPets={offeredNumPets}
                      offeredRentFrequency={offeredRentFrequency}
                      offeredWaterInclusion={offeredWaterInclusion}
                      offeredWaterInclusionComment={
                        offeredWaterInclusionComment
                      }
                    />
                  )}
                </div>
              </Display.WhiteBoxContainer>
              <Display.WhiteBoxContainer componentClass="margin-report-block-right">
                <Display.ContainerHeader
                  text="Leasing contact"
                  componentClass="mb20"
                  editBtnClass="btn hollow-button-green attachment-edit-button"
                  onEditClick={() => this.onEditClicked('leasingContact')}
                  isEditable={enableEdit}
                />
                <div>
                  <Text.Strong text={offeredLeasingContactName} />
                  {offeredLeasingContactEmail && (
                    <a
                      className="color-black display-flex"
                      href={`mailto:${offeredLeasingContactEmail}`}
                    >
                      <Text.Strong text="E&nbsp;" />
                      <Text.Strong
                        text={`${helpers.trimFileLengthTo30(
                          offeredLeasingContactEmail,
                        )}`}
                      />
                    </a>
                  )}
                  <Text.Strong text={agencyName} />
                  {formattedContactNumber && (
                    <a
                      className="color-black"
                      href={`tel:${formattedContactNumber}`}
                    >
                      <Text.Strong text={`T ${formattedContactNumber}`} />
                    </a>
                  )}
                </div>
              </Display.WhiteBoxContainer>
            </Display.FlexContainer>
          </Display.CenterContentContainer>
          <Display.CenterContentContainer
            componentClass="application-standard-shadow-box col-xs-12 summary-box-on-mobile border-radius-10px"
            containerClass="background-grey-mobile-excluded"
            sizeClass="mb30"
          >
            <Headers.SectionHeader
              text="Next steps"
              textClass="fw600 ml24-mobile"
            />
            {this.noPaymentMethodAvailable() && isUserManager && (
              <div className="background-light-orange no-border-bottom p24 colored-background-text-board">
                <div className="title-text-container">
                  <Display.FlexContainer containerClass="align-items-center">
                    <img
                      src={exclamationLg}
                      alt="shield"
                      className="big-lock mr5"
                    />
                    {textForNoPaymentAvailable}
                  </Display.FlexContainer>
                </div>
              </div>
            )}
            <Display.SectionContentContainer componentClass="p24">
              {include_holding_deposit && (
                <div className="mb20 bbs pb20">
                  <Display.ContainerHeader
                    text={
                      offeredSetAsDeposit ? 'Holding Deposit' : 'First Payment'
                    }
                    componentClass="mb20"
                    editBtnClass="btn hollow-button-green attachment-edit-button"
                    onEditClick={() => this.onEditClicked('initialRent')}
                    isEditable={enableEdit}
                  />
                  <Display.FlexContainer containerClass="flex-direction-column-mobile">
                    <div className="flex-1 attachment-text-padding mb20">
                      <Text.Normal
                        text={replaceText(
                          offeredDepositMessage,
                          offeredLeasingContactEmail,
                        )}
                        textClass="lh14"
                      />
                    </div>
                    <div className="flex-1">
                      <Display.FlexContainer containerClass="align-items-center">
                        <span className="fw700 font-size-24 mr10">
                          ${depositAmount}
                        </span>
                        <span className="font-size-14">
                          {initialDepositDurationArray[
                            offeredDepositDuration
                          ] === '1 month'
                            ? '1 months rent'
                            : initialDepositDurationArray[
                                offeredDepositDuration
                              ] + ' rent'}
                        </span>
                      </Display.FlexContainer>
                      <Text.Strong
                        text={`Due ${moment(offeredDepositDueDate).format(
                          'DD MMM YYYY',
                        )}`}
                        componentClass="mt5 mb5"
                      />
                      {offeredDepositIncludeBPayDetails && (
                        <Display.FlexContainer containerClass="align-items-center mt20">
                          <div className="mr5">
                            <img src={BPAY} alt="BPay" />
                          </div>
                          <div className="attachment-grey-border billing-block-container">
                            <div>Biller code: {offeredDepositBiller}</div>
                            <div>Reference: {offeredDepositBPayReference}</div>
                          </div>
                        </Display.FlexContainer>
                      )}
                      {offeredDepositIncludeEftDetails && (
                        <Display.FlexContainer containerClass="align-items-center mt20">
                          <div className="mr5">
                            <img src={EFT} alt="EFT" />
                          </div>
                          <div className="billing-block-container">
                            <div>
                              BSB: {offeredDepositBSB} Acct Num:{' '}
                              {offeredDepositAccount}
                            </div>
                            <div>
                              Acct Name: {offeredDepositEftAccountName || 'N/A'}
                            </div>
                            <div>Reference: {offeredDepositEftReference}</div>
                          </div>
                        </Display.FlexContainer>
                      )}
                      {offeredDepositIncludeDeftDetails && (
                        <Display.FlexContainer containerClass="align-items-center mt20">
                          <div className="mr5">
                            <img src={DEFT} alt="DEFT" />
                          </div>
                          <div className="billing-block-container">
                            <div className="mt15">
                              Reference: {offeredDeftReference}
                            </div>
                          </div>
                        </Display.FlexContainer>
                      )}
                    </div>
                  </Display.FlexContainer>
                </div>
              )}
              {include_bond && (
                <div className="mb20 bbs pb20">
                  <Display.ContainerHeader
                    text="Bond Payment"
                    componentClass="mb20"
                    editBtnClass="btn hollow-button-green attachment-edit-button"
                    onEditClick={() => this.onEditClicked('bond')}
                    isEditable={enableEdit}
                  />
                  <Display.FlexContainer containerClass="flex-direction-column-mobile">
                    <div className="flex-1 attachment-text-padding mb20">
                      <Text.Normal
                        text={replaceText(
                          offeredBondMessage,
                          offeredLeasingContactEmail,
                        )}
                        textClass="lh14"
                      />
                    </div>
                    <div className="flex-1">
                      <Display.FlexContainer containerClass="align-items-center">
                        <span className="fw700 font-size-24 mr10">{`$${Number(
                          offeredBondAmount,
                        ).toFixed(2)}`}</span>
                        <span className="font-size-14">{`${
                          initialBondDurationdArray[offeredBondDuration] ===
                          '1 month'
                            ? '1 months'
                            : initialBondDurationdArray[
                                offeredBondDuration || 4
                              ]
                        } rent`}</span>
                      </Display.FlexContainer>
                      <Text.Strong
                        text={`Due ${moment(offeredBondDueDate).format(
                          'DD MMM YYYY',
                        )}`}
                        componentClass="mt5 mb5"
                      />
                      {offeredBondPayOnline && (
                        <Text.Strong
                          text="The Property manager will lodge a bond request online."
                          componentClass="mt5 mb5"
                        />
                      )}
                      {offeredBondIncludeBPayDetails && !offeredBondPayOnline && (
                        <Display.FlexContainer containerClass="align-items-center mt20">
                          <div className="mr5">
                            <img src={BPAY} alt="BPay" />
                          </div>
                          <div className="attachment-grey-border billing-block-container">
                            <div>Biller code: {offeredBondBiller}</div>
                            <div>Reference: {offeredBondBPayReference}</div>
                          </div>
                        </Display.FlexContainer>
                      )}
                      {offeredBondIncludeEftDetails && !offeredBondPayOnline && (
                        <Display.FlexContainer containerClass="align-items-center mt20">
                          <div className="mr5">
                            <img src={EFT} alt="EFT" />
                          </div>
                          <div className="billing-block-container">
                            <div>
                              BSB: {offeredBondBSB} Acct Num:{' '}
                              {offeredBondAccount}
                            </div>
                            <div>
                              Acct Name: {offeredBondEftAccountName || 'N/A'}
                            </div>
                            <div>Reference: {offeredBondEftReference}</div>
                          </div>
                        </Display.FlexContainer>
                      )}
                      {offeredBondIncludeDeftDetails && !offeredBondPayOnline && (
                        <Display.FlexContainer containerClass="align-items-center mt20">
                          <div className="mr5">
                            <img src={DEFT} alt="DEFT" />
                          </div>
                          <div className="billing-block-container">
                            <div className="mt15">
                              Reference: {offeredBondDeftReference}
                            </div>
                          </div>
                        </Display.FlexContainer>
                      )}
                    </div>
                  </Display.FlexContainer>
                </div>
              )}
              {include_rent && (
                <div className="bbs pb20">
                  <Display.ContainerHeader
                    text="Remaining Balance Payment"
                    componentClass="mb20"
                    editBtnClass="btn hollow-button-green attachment-edit-button"
                    onEditClick={() => this.onEditClicked('remainingRent')}
                    isEditable={enableEdit}
                  />
                  <Display.FlexContainer containerClass="flex-direction-column-mobile">
                    <div className="flex-1 attachment-text-padding mb20">
                      <Text.Normal
                        text={replaceText(
                          offeredRentMessage,
                          offeredLeasingContactEmail,
                        )}
                        textClass="lh14"
                      />
                    </div>
                    <div className="flex-1">
                      <Display.FlexContainer containerClass="align-items-center">
                        <span className="fw700 font-size-24 mr10">
                          ${Number(balancePaymentAmount).toFixed(2)}
                        </span>
                      </Display.FlexContainer>
                      <Text.Strong
                        text={`Due ${moment(offeredRentDueDate).format(
                          'DD MMM YYYY',
                        )}`}
                        componentClass="mt5 mb5"
                      />
                      {offeredRentIncludeBPayDetails && (
                        <Display.FlexContainer containerClass="align-items-center mt20">
                          <div className="mr5">
                            <img src={BPAY} alt="BPay" />
                          </div>
                          <div className="attachment-grey-border billing-block-container">
                            <div>Biller code: {offeredRentBiller}</div>
                            <div>Reference: {offeredRentBPayReference}</div>
                          </div>
                        </Display.FlexContainer>
                      )}
                      {offeredIncludeEftReference && (
                        <Display.FlexContainer containerClass="align-items-center mt20">
                          <div className="mr5">
                            <img src={EFT} alt="EFT" />
                          </div>
                          <div className="billing-block-container">
                            <div>
                              BSB: {offeredRentBsb} Acct Num:{' '}
                              {offeredRentAccount}
                            </div>
                            <div>
                              Acct Name: {offeredRentEftAccountName || 'N/A'}
                            </div>
                            <div>Reference: {offeredRentEftReference}</div>
                          </div>
                        </Display.FlexContainer>
                      )}

                      {offeredRentIncludeDeftReference && (
                        <Display.FlexContainer containerClass="align-items-center mt20">
                          <div className="mr5">
                            <img src={DEFT} alt="DEFT" />
                          </div>
                          <div className="billing-block-container">
                            <div className="mt15">
                              Reference: {offeredRentDeftReference}
                            </div>
                          </div>
                        </Display.FlexContainer>
                      )}
                    </div>
                  </Display.FlexContainer>
                </div>
              )}
              {include_lease_signing_message && (
                <div className="mt20">
                  <Display.ContainerHeader
                    text="Lease Signing"
                    componentClass="mb20"
                    editBtnClass="btn hollow-button-green attachment-edit-button"
                    onEditClick={() => this.onEditClicked('leasingSigning')}
                    isEditable={enableEdit}
                  />
                  <Display.FlexContainer containerClass="flex-direction-column-mobile">
                    <div className="flex-1 attachment-text-padding mb20">
                      <Text.Normal
                        text={replaceText(
                          offeredLeaseSigningMessage,
                          offeredLeasingContactEmail,
                        )}
                        textClass="lh14"
                      />
                    </div>
                  </Display.FlexContainer>
                </div>
              )}
            </Display.SectionContentContainer>
          </Display.CenterContentContainer>
          {showMakeMovingEasier && (
            <Display.CenterContentContainer
              componentClass="application-standard-shadow-box col-xs-12 summary-box-on-mobile border-radius-10px"
              sizeClass="mb30"
            >
              <Display.ContainerHeader
                text="Make moving easier"
                componentClass="bbs heading ml24-mobile"
                editBtnClass="btn hollow-button-green attachment-edit-button"
                textClass="font-size-32 line-height-40"
                isEditable={false}
              />
              <Display.SectionContentContainer componentClass="p24">
                <Display.FlexContainer containerClass="flex-direction-column-mobile">
                  <div className="flex-1 attachment-text-padding mb20">
                    <Text.Normal
                      text={makeMovingEasierMessage}
                      textClass="lh14"
                    />
                  </div>
                </Display.FlexContainer>
              </Display.SectionContentContainer>
            </Display.CenterContentContainer>
          )}
          <Display.CenterContentContainer
            componentClass="application-standard-shadow-box col-xs-12 summary-box-on-mobile border-radius-10px"
            sizeClass="mb30"
          >
            <Display.ContainerHeader
              text="Important information"
              componentClass="bbs heading ml24-mobile"
              editBtnClass="btn hollow-button-green attachment-edit-button"
              textClass="font-size-32 line-height-40"
              onEditClick={() => this.onEditClicked('importantInformation')}
              isEditable={enableEdit}
            />
            <Display.SectionContentContainer componentClass="p24">
              <Display.FlexContainer>
                <ImportantInfoTxtCmp className="lh14">
                  {offeredImportantMessage}
                </ImportantInfoTxtCmp>
              </Display.FlexContainer>
              <Display.FlexContainer containerClass="flex-wrap-wrap">
                {offeredAttachment &&
                  offeredAttachment
                    .filter((attachment) => attachment.included)
                    .map((attachment, index) => {
                      return (
                        <div
                          key={attachment.Key}
                          className="offer-lease-checkbox-wrapper"
                        >
                          <div className="width80p">
                            <Text.Strong
                              text={`Document ${index + 1}`}
                              componentClass="grey-text"
                            />
                            <AppDocumentListItemSecondVersion
                              document={{
                                name: attachment.name,
                                url: attachment.url,
                              }}
                            />
                          </div>
                        </div>
                      )
                    })}
              </Display.FlexContainer>
            </Display.SectionContentContainer>

            <Display.SectionContentContainer componentClass="p24">
              <Display.FlexContainer>
                <DisclosureQuestionsDisplay
                  showDisclosureLink={hasPropertyDisclosure}
                  hasPropertyDisclosure={hasPropertyDisclosure}
                  propertyDisclosure={propertyDisclosures}
                />
              </Display.FlexContainer>
            </Display.SectionContentContainer>
          </Display.CenterContentContainer>
          {isUserRenter && (
            <Display.BottomContentContainer>
              <Display.CenterContentContainer componentClass="width100">
                <div className="submit-button-container display-flex justify-content-space-between rental-reference-bottom-button-layout">
                  <div>
                    {isOfferAccepted && (
                      <button
                        className="btn btn-transparent rental-review-declined height40 width-auto"
                        onClick={() => history.goBack()}
                      >
                        Back
                      </button>
                    )}
                    {!isUserLoggedIn && (
                      <button
                        className="btn btn-transparent rental-review-declined height40 width-auto"
                        onClick={() =>
                          history.push(helpers.urlTo(urlIds.join.index))
                        }
                      >
                        Log in
                      </button>
                    )}
                    <span />
                  </div>

                  <div>
                    {!isUserLoggedIn && (
                      <Button
                        onClick={() => {
                          isManagerAdded
                            ? history.push(helpers.urlTo('register'))
                            : history.push(helpers.urlTo(urlIds.join.index))
                        }}
                      >
                        {`${isManagerAdded ? 'Register' : 'Log in'}`} to accept
                      </Button>
                    )}
                    {isUserLoggedIn && !isOfferAccepted && (
                      <Display.GenericButton
                        size="large"
                        text="Accept"
                        onClick={() => this.onRenterAcceptOffer()}
                        disabled={acceptOfferSpinner}
                        isLoading={acceptOfferSpinner}
                      />
                    )}
                    {isUserLoggedIn && isOfferAccepted && (
                      <div className="status-green-label fw600 font-size-16 accept-text-wrapper">
                        This offer has been accepted
                      </div>
                    )}
                  </div>
                </div>
              </Display.CenterContentContainer>
            </Display.BottomContentContainer>
          )}
          {isUserManager && (
            <Display.BottomContentContainer>
              <Display.CenterContentContainer componentClass="width100">
                <div className="submit-button-container display-flex justify-content-space-between rental-reference-bottom-button-layout">
                  <div>
                    <button
                      className="btn btn-transparent rental-review-declined height40 width-auto"
                      onClick={() => history.goBack()}
                    >
                      Back
                    </button>
                  </div>

                  <div>
                    {isOfferOffered && (
                      <Display.GenericButton
                        size="large"
                        text="Withdraw"
                        onClick={() => this.onManagerWithdraw()}
                        disabled={withdrawOfferSpinner}
                        isLoading={withdrawOfferSpinner}
                      />
                    )}
                    {isOfferAccepted && (
                      <div className="status-green-label fw600 font-size-16 accept-text-wrapper">
                        This offer has been accepted
                      </div>
                    )}
                  </div>
                </div>
              </Display.CenterContentContainer>
            </Display.BottomContentContainer>
          )}
        </Display.CenterContentContainer>
        {activeModal === 'leasingSigning' && (
          <Display.Modal
            toggleModal={() => this.onEditClicked('')}
            title="Lease Signing"
            hideButtonSection
          >
            <div className="mt20 width100">
              <Text.Strong text="Message" />
              <Form.CommentArea
                value={replaceText(
                  lease_signing_message,
                  offeredLeasingContactEmail,
                )}
                placeholder="Enter your Lease Signing message"
                onChange={this.onModalUpdated('modalLeasingSigning')(
                  'lease_signing_message',
                )}
                id="message"
                cols={50}
                rows={2}
                isOptional
                inputBoxClass="pt0"
              />
            </div>
            <Display.FlexContainer containerClass="justify-content-space-between mt20">
              <div
                className="btn btn-transparent btn-left wa"
                onClick={() => this.onEditClicked('')}
                role="button"
                tabIndex="0"
              >
                <span>Cancel</span>
              </div>
              <Display.GenericButton
                text="Save"
                size="large"
                onClick={() =>
                  this.onModalSaved('lease_signing', 'modalLeasingSigning')
                }
              />
            </Display.FlexContainer>
          </Display.Modal>
        )}
        {activeModal === 'leaseDetails' && (
          <Display.Modal
            toggleModal={() => this.onEditClicked('')}
            title="Lease Details"
            hideButtonSection
          >
            <div>
              <Display.FlexContainer>
                <Display.FlexContainer>
                  <span className="mr10 fw700 mt15">$</span>
                  <Form.InputNumber
                    value={weeklyRent || ''}
                    error={weeklyRent_error}
                    onChange={this.onModalUpdated('modalLeaseDetails')(
                      'weeklyRent',
                    )}
                    id="weeklyRent"
                    label="Weekly Rent"
                    labelClass="top18"
                    inputClass="max-width-100px"
                    componentClass="mr20"
                  />
                </Display.FlexContainer>
                <Form.Dropdown
                  label="Rent Frequency"
                  frameLabel="Rent Frequency"
                  frameLabelClass="lease-attachment-setby-dropdown-label"
                  componentClass="lease-attachment-setby-dropdown"
                  value={rentFrequency}
                  options={leaseOfferHelpers.leaseDetailsRentFrequency}
                  onChange={this.onModalUpdated('modalLeaseDetails')(
                    'rentFrequency',
                  )}
                  id="rentFrequency"
                  idAsValue={true}
                  inputBoxClass="pt8 max-width-150px"
                  selectClassName="pb5"
                />
              </Display.FlexContainer>
              <Display.FlexContainer containerClass="mt10 flex-direction-column-mobile">
                <Form.InputDate
                  label="Start"
                  value={formattedLeaseStartDate}
                  error={leaseStartDateError}
                  onChange={this.onModalUpdated('modalLeaseDetails')(
                    'leaseStartDate',
                  )}
                  before={leaseEndDate || moment()}
                  id="startDate"
                  containerClassName="max-width-150px"
                  componentClass="mr20 date-picker-130"
                  viewMode="days"
                  customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                  readOnly={false}
                />
                {isLeaseSetByTypeSelected && (
                  <Form.InputNumber
                    value={leaseDuration}
                    error={leaseDurationError}
                    onChange={this.onModalUpdated('modalLeaseDetails')(
                      'leaseDuration',
                    )}
                    id="leaseDuration"
                    label="Duration"
                    inputClass="max-width-100px"
                    componentClass="mr20"
                  />
                )}
                <Form.InputDate
                  label="End"
                  value={formattedLeaseEndDate}
                  error={leaseEndDateError}
                  onChange={this.onModalUpdated('modalLeaseDetails')(
                    'leaseEndDate',
                  )}
                  id="endDate"
                  after={leaseStartDate}
                  containerClassName="max-width-150px"
                  componentClass="mr20 date-picker-130"
                  //isValidDate={helpers.validateEndDate(leaseStartDate)}
                  viewMode="months"
                  customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                  readOnly={false}
                />
                <Form.Dropdown
                  label="End date"
                  frameLabel="Set By"
                  frameLabelClass="lease-attachment-setby-dropdown-label"
                  componentClass="lease-attachment-setby-dropdown"
                  value={leaseSetByType}
                  options={initialLeaseDetailSetByType}
                  onChange={this.onModalUpdated('modalLeaseDetails')(
                    'leaseSetByType',
                  )}
                  id="leaseSetByType"
                  idAsValue={true}
                  inputBoxClass="pt8 max-width-150px"
                />
              </Display.FlexContainer>
              <Text.Normal
                text={`Duration:
                          ${leaseLengthByWeeks}
                          weeks / ${leaseLengthByMonths}
                          months`}
                componentClass="mb20"
                textClass="font-size-18"
              />
              <Form.CheckBoxGeneral
                label="Pets approved"
                checked={is_pets_allowed}
                onChange={this.onModalUpdated('modalLeaseDetails')(
                  'is_pets_allowed',
                )}
                componentClassName="mb20 mt20"
              />
              {is_pets_allowed && (
                <Form.InputNumber
                  value={numPets}
                  error={leaseDurationError}
                  onChange={this.onModalUpdated('modalLeaseDetails')('numPets')}
                  id="numPets"
                  label="Number of Pets"
                  componentClass="mr20"
                />
              )}
              <Form.Dropdown
                label="Water is:"
                frameLabel="Water is:"
                frameLabelClass="lease-attachment-setby-dropdown-label"
                componentClass="lease-attachment-setby-dropdown mt20"
                value={waterInclusion}
                options={leaseOfferHelpers.waterInclusionOptions}
                onChange={this.onModalUpdated('modalLeaseDetails')(
                  'waterInclusion',
                )}
                id="waterInclusion"
                idAsValue={true}
                inputBoxClass="pt8"
                selectClassName="w300 pb5"
              />
              {waterInclusion === leaseOfferHelpers.WaterInclusionSplit && (
                <NewForm.CommentArea
                  value={waterInclusionComment || ''}
                  placeholder="Add Comment"
                  rows={1}
                  onChange={this.onModalUpdated('modalLeaseDetails')(
                    'waterInclusionComment',
                  )}
                  id="content"
                />
              )}
              <Form.CheckBoxGeneral
                label="Posting clause included"
                checked={posting_clause}
                onChange={this.onModalUpdated('modalLeaseDetails')(
                  'posting_clause',
                )}
                componentClassName="mb20 mt20"
              />
              <Display.FlexContainer containerClass="justify-content-space-between mt20">
                <div
                  className="btn btn-transparent btn-left wa"
                  onClick={() => this.onEditClicked('')}
                  role="button"
                  tabIndex="0"
                >
                  <span>Cancel</span>
                </div>
                <Display.GenericButton
                  text="Save"
                  size="large"
                  onClick={() =>
                    this.onModalSaved('lease_details', 'modalLeaseDetails')
                  }
                />
              </Display.FlexContainer>
            </div>
          </Display.Modal>
        )}
        {activeModal === 'leasingContact' && (
          <GenericModal
            onDismiss={() => this.onEditClicked('')}
            title="Leasing Contact"
          >
            <Flex alignItems="center">
              <Text.Strong
                text="Select manager:"
                componentClass="mr30-table mb0 mt10"
              />
              <Form.Dropdown
                placeholder="manager"
                componentClass="mr30-table"
                value={chosenManager}
                inputBoxClass="pt0"
                options={managerNameList}
                onChange={this.onManagerDropDownChanged()}
                id="relationship"
              />
            </Flex>
            <Flex alignItems="center">
              <Form.CheckBoxGeneral
                id="useOpsContactEmailAddress"
                label={`Use lease ops email ${
                  !!operationsEmail.length ? `(${operationsEmail})` : ''
                }`}
                checked={useOpsContactEmailAddress}
                onChange={this.onChangeLeasingModal(
                  'useOpsContactEmailAddress',
                )}
                componentClassName="mt10"
                disabled={!!!operationsEmail.length}
                showDisabledGrey={!!!operationsEmail.length}
              />
            </Flex>
            <Display.FlexContainer containerClass="justify-content-space-between mt20">
              <div
                className="btn btn-transparent btn-left wa"
                onClick={() => this.onEditClicked('')}
                role="button"
                tabIndex="0"
              >
                <span>Cancel</span>
              </div>
              <Display.GenericButton
                text="Save"
                size="large"
                onClick={() =>
                  this.onModalSaved('leasing_contact', 'modalLeasingContact')
                }
              />
            </Display.FlexContainer>
          </GenericModal>
        )}
        {activeModal === 'initialRent' && (
          <Display.Modal
            toggleModal={() => this.onEditClicked('')}
            title="First Payment"
            hideButtonSection
          >
            <div>
              <div>
                <Form.CheckBoxGeneral
                  label="Rename to Holding Deposit"
                  checked={deposit_set_as_deposit}
                  onChange={this.onModalUpdated('modalDepositInfo')(
                    'deposit_set_as_deposit',
                  )}
                />
                <Display.FlexContainer>
                  <Display.FlexContainer>
                    <span className="fw700 mt15 mr5">$</span>
                    <Form.InputNumberWithTwoDecimals
                      value={deposit_amount || ''}
                      error={deposit_amount_error}
                      onChange={this.onModalUpdated('modalDepositInfo')(
                        'deposit_amount',
                      )}
                      id="amount"
                      label="Amount"
                      labelClass="top18"
                      inputClass="lease-offer-modal-combo-input-wrapper"
                      componentClass="mr20"
                    />
                  </Display.FlexContainer>
                  <Form.Dropdown
                    placeholder="Duration"
                    value={deposit_duration}
                    inputBoxClass="pt0"
                    options={initialDepositDurationArray}
                    onChange={this.onModalUpdated('modalDepositInfo')(
                      'deposit_duration',
                    )}
                    id="relationship"
                    componentClass="mt8 ml10"
                    inputClass="lease-offer-modal-combo-input-wrapper"
                  />
                </Display.FlexContainer>
                <div className="mt5 mb125">
                  <Form.InputDate
                    label="Due date"
                    value={formattedDueDate}
                    onChange={this.onModalUpdated('modalDepositInfo')(
                      'deposit_due_date',
                    )}
                    after={moment()}
                    id="dueDate"
                    containerClassName="max-width-150px"
                    componentClass="mr20 date-picker-130"
                    isValidDate={helpers.validateFutureDate()}
                    viewMode="days"
                    readOnly={false}
                    customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                    error={deposit_due_date_error}
                  />
                </div>
                <Text.Strong
                  componentClass="mt15"
                  text={
                    this.noPaymentMethodAvailable()
                      ? textForNoPaymentAvailable
                      : textForConfigurePayment
                  }
                />
                {isEFTEnabled && (
                  <>
                    <Display.FlexContainer containerClass="lease-offer-modal-payment-wrapper">
                      <Form.CheckBoxGeneral
                        label="EFT"
                        checked={deposit_include_eft_details}
                        onChange={this.onModalUpdated('modalDepositInfo')(
                          'deposit_include_eft_details',
                        )}
                        componentClassName="mr20 mt10 w90"
                      />
                      {deposit_include_eft_details && (
                        <Form.InputTextRequired
                          value={deposit_eft_reference || ''}
                          error={deposit_eft_reference_error}
                          onChange={this.onModalUpdated('modalDepositInfo')(
                            'deposit_eft_reference',
                          )}
                          id="eft_reference"
                          label="Reference"
                          labelClass="top18"
                          inputClass="max-width-150px"
                        />
                      )}
                    </Display.FlexContainer>
                    {deposit_include_eft_details && (
                      <Display.FlexContainer containerClass="lease-offer-modal-payment-wrapper mt0 mb0">
                        <div className="mr20 mt10 w90"></div>
                        <div>
                          {EFTPaymentsV2 &&
                            EFTPaymentsV2.length > 0 &&
                            EFTPaymentsV2.map(
                              (
                                {
                                  EFTPaymentBSB,
                                  EFTPaymentAccountNum,
                                  EFTPaymentAccountName,
                                },
                                idx,
                              ) => (
                                <div key={idx}>
                                  <Form.CheckBoxGeneral
                                    label={`${EFTPaymentAccountName} (BSB: ${EFTPaymentBSB} Num: ${EFTPaymentAccountNum})`}
                                    checked={
                                      modalDepositInfo[
                                        `deposit_eft_guid_${idx}`
                                      ]
                                    }
                                    onChange={this.onModalUpdated(
                                      'modalDepositInfo',
                                    )(`deposit_eft_guid_${idx}`)}
                                    componentClassName="mr20 mt10"
                                  />
                                </div>
                              ),
                            )}
                          <div>
                            <div className={'note text-left m0 p0'}>
                              {'Team admin can add or change account details: '}
                              <a
                                href={SNUG_SUBMIT_REQUEST_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {'submit a request'}
                              </a>
                            </div>
                          </div>
                        </div>
                      </Display.FlexContainer>
                    )}
                  </>
                )}
                {isBPayEnabled && (
                  <Display.FlexContainer containerClass="lease-offer-modal-payment-wrapper">
                    <Form.CheckBoxGeneral
                      label="BPay"
                      checked={deposit_include_bpay_details}
                      onChange={this.onModalUpdated('modalDepositInfo')(
                        'deposit_include_bpay_details',
                      )}
                      componentClassName="mr20 mt10 w90"
                    />
                    {deposit_include_bpay_details && (
                      <Form.InputTextRequired
                        value={deposit_bpay_reference || ''}
                        error={deposit_bpay_reference_error}
                        onChange={this.onModalUpdated('modalDepositInfo')(
                          'deposit_bpay_reference',
                        )}
                        id="bpay_reference"
                        label="Reference"
                        labelClass="top18"
                        inputClass="max-width-150px"
                      />
                    )}
                  </Display.FlexContainer>
                )}
                {isDEFTEnabled && (
                  <Display.FlexContainer containerClass="lease-offer-modal-payment-wrapper">
                    <Form.CheckBoxGeneral
                      label="DEFT"
                      checked={deposit_include_deft_details}
                      onChange={this.onModalUpdated('modalDepositInfo')(
                        'deposit_include_deft_details',
                      )}
                      componentClassName="mr20 mt10 w90"
                    />
                    {deposit_include_deft_details && (
                      <Form.InputTextRequired
                        value={deposit_deft_reference || ''}
                        error={deposit_deft_reference_error}
                        onChange={this.onModalUpdated('modalDepositInfo')(
                          'deposit_deft_reference',
                        )}
                        id="deft_reference"
                        label="Reference"
                        labelClass="top18"
                        inputClass="max-width-150px"
                      />
                    )}
                  </Display.FlexContainer>
                )}
                <Display.FlexContainer containerClass="justify-content-space-between mt20">
                  <div
                    className="btn btn-transparent btn-left wa"
                    onClick={() => this.onEditClicked('')}
                    role="button"
                    tabIndex="0"
                  >
                    <span>Cancel</span>
                  </div>
                  <Display.GenericButton
                    text="Save"
                    size="large"
                    onClick={() =>
                      this.onModalSaved('deposit', 'modalDepositInfo')
                    }
                  />
                </Display.FlexContainer>
              </div>
            </div>
          </Display.Modal>
        )}
        {activeModal === 'bond' && (
          <Display.Modal
            toggleModal={() => this.onEditClicked('')}
            title="Bond Payment"
            hideButtonSection
          >
            <div>
              <Display.FlexContainer>
                <Display.FlexContainer>
                  <span className="mr10 fw700 mt15">$</span>
                  <Form.InputNumberWithTwoDecimals
                    value={bond_amount || ''}
                    error={bond_amount_error}
                    onChange={this.onModalUpdated('modalBondInfo')(
                      'bond_amount',
                    )}
                    id="amount"
                    label="Amount"
                    labelClass="top18"
                    inputClass="lease-offer-modal-combo-input-wrapper"
                    componentClass="mr10"
                  />
                </Display.FlexContainer>
                <Form.Dropdown
                  placeholder="Duration"
                  value={bond_duration}
                  inputBoxClass="pt0"
                  options={initialBondDurationdArray}
                  onChange={this.onModalUpdated('modalBondInfo')(
                    'bond_duration',
                  )}
                  id="relationship"
                  componentClass="mt8"
                  inputClass="lease-offer-modal-combo-input-wrapper"
                />
              </Display.FlexContainer>
              <div className="mt5 mb80">
                <Form.InputDate
                  label="Due date"
                  value={formattedBondDueDate}
                  error={bond_due_date_error}
                  after={moment()}
                  customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                  onChange={this.onModalUpdated('modalBondInfo')(
                    'bond_due_date',
                  )}
                  id="dueDate"
                  containerClassName="max-width-150px"
                  componentClass="mr20 date-picker-130"
                  isValidDate={helpers.validateFutureDate()}
                  viewMode="days"
                  readOnly={false}
                />
              </div>
              <Form.CheckBoxGeneral
                label="Lodge via government rental bonds online"
                checked={bond_pay_online}
                onChange={this.onModalUpdated('modalBondInfo')(
                  'bond_pay_online',
                )}
                componentClassName="mt15"
              />
              {!bond_pay_online && (
                <div>
                  <Text.Strong
                    componentClass="mt15 mt30-mobile"
                    text={
                      this.noPaymentMethodAvailable()
                        ? textForNoPaymentAvailable
                        : textForConfigurePayment
                    }
                  />
                  {isEFTEnabled && (
                    <Display.FlexContainer containerClass="mb10 align-items-center">
                      <Form.CheckBoxGeneral
                        label="EFT"
                        checked={bond_include_eft_details}
                        onChange={this.onModalUpdated('modalBondInfo')(
                          'bond_include_eft_details',
                        )}
                        componentClassName="mr20 mt10 width100px"
                      />
                      {bond_include_eft_details && (
                        <Form.InputTextRequired
                          value={bond_eft_reference || ''}
                          error={bond_eft_reference_error}
                          onChange={this.onModalUpdated('modalBondInfo')(
                            'bond_eft_reference',
                          )}
                          id="eft_reference"
                          label="Reference"
                          labelClass="top18"
                          inputClass="max-width-150px"
                        />
                      )}
                    </Display.FlexContainer>
                  )}
                  {isBPayEnabled && (
                    <Display.FlexContainer containerClass="mt10 mb10 align-items-center">
                      <Form.CheckBoxGeneral
                        label="BPay"
                        checked={bond_include_bpay_details}
                        onChange={this.onModalUpdated('modalBondInfo')(
                          'bond_include_bpay_details',
                        )}
                        componentClassName="mr20 mt10 width100px"
                      />
                      {bond_include_bpay_details && (
                        <Form.InputTextRequired
                          value={bond_bpay_reference || ''}
                          error={bond_bpay_reference_error}
                          onChange={this.onModalUpdated('modalBondInfo')(
                            'bond_bpay_reference',
                          )}
                          id="bpay_reference"
                          label="Reference"
                          labelClass="top18"
                          inputClass="max-width-150px"
                        />
                      )}
                    </Display.FlexContainer>
                  )}
                  {isDEFTEnabled && (
                    <Display.FlexContainer containerClass="mt10 mb10 align-items-center">
                      <Form.CheckBoxGeneral
                        label="DEFT"
                        checked={bond_include_deft_details}
                        onChange={this.onModalUpdated('modalBondInfo')(
                          'bond_include_deft_details',
                        )}
                        componentClassName="mr20 mt10 width100px"
                      />
                      {bond_include_deft_details && (
                        <Form.InputTextRequired
                          value={bond_deft_reference || ''}
                          error={bond_deft_reference_error}
                          onChange={this.onModalUpdated('modalBondInfo')(
                            'bond_deft_reference',
                          )}
                          id="deft_reference"
                          label="Reference"
                          labelClass="top18"
                          inputClass="max-width-150px"
                        />
                      )}
                    </Display.FlexContainer>
                  )}
                </div>
              )}
              <Display.FlexContainer containerClass="justify-content-space-between mt20">
                <div
                  className="btn btn-transparent btn-left wa"
                  onClick={() => this.onEditClicked('')}
                  role="button"
                  tabIndex="0"
                >
                  <span>Cancel</span>
                </div>
                <Display.GenericButton
                  text="Save"
                  size="large"
                  onClick={() => this.onModalSaved('bond', 'modalBondInfo')}
                />
              </Display.FlexContainer>
            </div>
          </Display.Modal>
        )}
        {activeModal === 'remainingRent' && (
          <Display.Modal
            toggleModal={() => this.onEditClicked('')}
            title="Remaining Balance Payment"
            hideButtonSection
          >
            <div>
              <div>
                <Flex>
                  <Form.CheckBoxGeneral
                    label="Pay rent in advance"
                    checked={pay_rent_in_advance}
                    onChange={this.onModalUpdated('modalRentInfo')(
                      'pay_rent_in_advance',
                    )}
                  />
                  {roundPaymentsDownNearestDollar === 'true' && (
                    <Form.CheckBoxGeneral
                      label="Round down"
                      checked={round_down}
                      onChange={this.onModalUpdated('modalRentInfo')(
                        'round_down',
                      )}
                    />
                  )}
                </Flex>
                {pay_rent_in_advance && (
                  <Display.FlexContainer>
                    <Display.FlexContainer>
                      <span className="mr10 fw700 mt15">$</span>
                      <Form.InputNumberWithTwoDecimals
                        value={rent_amount > 0 ? rent_amount : 0}
                        error={rent_amount_error}
                        onChange={this.onModalUpdated('modalRentInfo')(
                          'rent_amount',
                        )}
                        id="amount"
                        label="Amount"
                        labelClass="top18"
                        inputClass="lease-offer-modal-combo-input-wrapper"
                        componentClass="mr10"
                      />
                    </Display.FlexContainer>
                    <Form.Dropdown
                      placeholder="Duration"
                      value={rent_period}
                      inputBoxClass="pt0"
                      options={initialRentPeriodArray}
                      onChange={this.onModalUpdated('modalRentInfo')(
                        'rent_period',
                      )}
                      id="relationship"
                      componentClass="mt8"
                      inputClass="lease-offer-modal-combo-input-wrapper"
                    />
                  </Display.FlexContainer>
                )}
                <div className="mt5 mb125">
                  <Form.InputDate
                    label="Due date"
                    value={formattedRentDueDate}
                    error={rent_due_date_error}
                    after={moment()}
                    customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                    onChange={this.onModalUpdated('modalRentInfo')(
                      'rent_due_date',
                    )}
                    id="dueDate"
                    containerClassName="max-width-150px"
                    componentClass="mr20 date-picker-130"
                    isValidDate={helpers.validateFutureDate()}
                    viewMode="days"
                    readOnly={false}
                  />
                </div>
                <Text.Strong
                  componentClass="mt15"
                  text={
                    this.noPaymentMethodAvailable()
                      ? textForNoPaymentAvailable
                      : textForConfigurePayment
                  }
                />
                {isEFTEnabled && (
                  <Display.FlexContainer containerClass="mb10 align-items-center">
                    <Form.CheckBoxGeneral
                      label="EFT"
                      checked={rent_include_eft_reference}
                      onChange={this.onModalUpdated('modalRentInfo')(
                        'rent_include_eft_reference',
                      )}
                      componentClassName="mr20 mt10 width100px"
                    />
                    {rent_include_eft_reference && (
                      <Form.InputTextRequired
                        value={rent_eft_reference || ''}
                        error={rent_eft_reference_error}
                        onChange={this.onModalUpdated('modalRentInfo')(
                          'rent_eft_reference',
                        )}
                        id="eft_reference"
                        label="Reference"
                        labelClass="top18"
                        inputClass="max-width-150px"
                      />
                    )}
                  </Display.FlexContainer>
                )}
                {isBPayEnabled && (
                  <Display.FlexContainer containerClass="mt10 mb10 align-items-center">
                    <Form.CheckBoxGeneral
                      label="BPay"
                      checked={rent_include_bpay_details}
                      onChange={this.onModalUpdated('modalRentInfo')(
                        'rent_include_bpay_details',
                      )}
                      componentClassName="mr20 mt10 width100px"
                    />
                    {rent_include_bpay_details && (
                      <Form.InputTextRequired
                        value={rent_bpay_reference || ''}
                        error={rent_bpay_reference_error}
                        onChange={this.onModalUpdated('modalRentInfo')(
                          'rent_bpay_reference',
                        )}
                        id="bpay_reference"
                        label="Reference"
                        labelClass="top18"
                        inputClass="max-width-150px"
                      />
                    )}
                  </Display.FlexContainer>
                )}
                {isDEFTEnabled && (
                  <Display.FlexContainer containerClass="mt10 mb10 align-items-center">
                    <Form.CheckBoxGeneral
                      label="DEFT"
                      checked={rent_include_deft_reference}
                      onChange={this.onModalUpdated('modalRentInfo')(
                        'rent_include_deft_reference',
                      )}
                      componentClassName="mr20 mt10 width100px"
                    />
                    {rent_include_deft_reference && (
                      <Form.InputTextRequired
                        value={rent_deft_reference || ''}
                        error={rent_deft_reference_error}
                        onChange={this.onModalUpdated('modalRentInfo')(
                          'rent_deft_reference',
                        )}
                        id="deft_reference"
                        label="Reference"
                        labelClass="top18"
                        inputClass="max-width-150px"
                      />
                    )}
                  </Display.FlexContainer>
                )}
                <Display.FlexContainer containerClass="justify-content-space-between mt20">
                  <div
                    className="btn btn-transparent btn-left wa"
                    onClick={() => this.onEditClicked('')}
                    role="button"
                    tabIndex="0"
                  >
                    <span>Cancel</span>
                  </div>
                  <Display.GenericButton
                    text="Save"
                    size="large"
                    onClick={() => this.onModalSaved('rent', 'modalRentInfo')}
                  />
                </Display.FlexContainer>
              </div>
            </div>
          </Display.Modal>
        )}
        {activeModal === 'importantInformation' && (
          <Display.Modal
            toggleModal={() => this.onEditClicked('')}
            title="Important Information"
            hideButtonSection
          >
            <div className="lease-offer-important-info-wrapper">
              <div>
                <Text.Strong text="Message" />
                <Form.CommentArea
                  value={important_message}
                  placeholder="Enter your message to include in the email"
                  onChange={this.onModalUpdated('modalImportantInformation')(
                    'important_message',
                  )}
                  id="message"
                  cols={50}
                  rows={2}
                  isOptional
                  inputBoxClass="pt0"
                />
              </div>
              <Display.DocumentListItemSecondVersion
                documents={agencyAttachments}
                addDocument={() =>
                  this.onModalUploadAttachment(attachmentScopeTeam)
                }
                deleteDocument={(key) =>
                  this.onModalRemoveAttachment(attachmentScopeTeam, key)
                }
                documentType={attachmentScopeTeam}
                label={
                  <div>
                    Add team attachments
                    <i
                      className="icon-help-outline fs16 gray-light-color ibm ml5 mb5"
                      data-tooltip-content
                      data-tooltip-id="allLeaseOfferAttachments"
                    />
                    <ReactTooltip id="allLeaseOfferAttachments">
                      <span>Attachments are available on all lease offers</span>
                    </ReactTooltip>
                  </div>
                }
                fileBodyClass="max-width-150px-mobile"
                titleClass="team-width-60p team-width-60p-mobile"
                enableCheckbox
                onCheckboxToggled={this.onModalCheckboxToggled}
                documentWrapperClass="align-items-center"
                showRentalLedgerSpinner={showRentalLedgerSpinner}
              />
              <Display.DocumentListItemSecondVersion
                documents={propertyAttachments}
                addDocument={() =>
                  this.onModalUploadAttachment(attachmentScopeProperty)
                }
                deleteDocument={(key) =>
                  this.onModalRemoveAttachment(attachmentScopeProperty, key)
                }
                documentType={attachmentScopeProperty}
                label={
                  <div className="display-flex align-items-center">
                    <span>Add property attachments</span>
                    <i
                      className="icon-help-outline fs16 gray-light-color ibm ml5 mb5"
                      data-tooltip-content
                      data-tooltip-id="propertyOfferAttachments"
                    />
                    <ReactTooltip id="propertyOfferAttachments">
                      <span>Attachments are specific to this property</span>
                    </ReactTooltip>
                  </div>
                }
                fileBodyClass="max-width-150px-mobile"
                titleClass="team-width-60p team-width-60p-mobile"
                enableCheckbox
                onCheckboxToggled={this.onModalCheckboxToggled}
                documentWrapperClass="align-items-center"
                showRentalLedgerSpinner={showPropertyAttachmentSpinner}
              />
              <Display.FlexContainer containerClass="justify-content-space-between important-info-buttons-wrapper">
                <div
                  className="btn btn-transparent btn-left wa"
                  onClick={() => this.onEditClicked('')}
                  role="button"
                  tabIndex="0"
                >
                  <span>Cancel</span>
                </div>
                <Display.GenericButton
                  text="Save"
                  size="large"
                  onClick={() =>
                    this.onModalSaved(
                      'important_information',
                      'modalImportantInformation',
                    )
                  }
                />
              </Display.FlexContainer>
            </div>
          </Display.Modal>
        )}
        {activeModal === 'updateOutstandingItems' && (
          <Modal
            modalHeading="Update First Payment details"
            modalBody={
              <Box mt={6}>
                <Box>
                  Please update the following before sending the Lease Offer:
                  <div className="mt6">
                    {this.state.firstPaymentListErrors &&
                      this.state.firstPaymentListErrors.length > 0 &&
                      this.state.firstPaymentListErrors.map((error) => (
                        <div className="row flex pl20">
                          <StyledEllipse mx={2} />
                          <span>{error}</span>
                        </div>
                      ))}
                  </div>
                </Box>
              </Box>
            }
            showSecondaryButton={true}
            primaryLabel="Open details"
            secondaryLabel={'Cancel'}
            primaryAction={() => this.onEditClicked('initialRent')}
            secondaryAction={() => {
              this.setState({
                ...this.state,
                firstPaymentListErrors: undefined,
              })
              return this.onEditClicked('')
            }}
            toggleModal={() => {
              this.setState({
                ...this.state,
                firstPaymentListErrors: undefined,
              })
              return this.onEditClicked('')
            }}
          />
        )}
        <UnsavedMessage unsavedChanges={unsavedChanges} />
      </div>
    )
  }
}

export default LeaseAttachmentContainer
