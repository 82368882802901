import React from 'react'

import { Formik } from 'formik'

import { Box, Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import { moveApplicationToProperty } from 'app/services/http/applications/workflow'
import * as snugNotifier from 'app/services/snugNotifier'
import {
  moveApplicationFormFields,
  moveApplicationFormValidation,
} from 'app/sm/applications/components/move_application/form.utils'
import { ModalHeader } from 'app/sm/applications/components/move_application/ModalHeader'
import MoveApplicationFormBody from 'app/sm/applications/components/move_application/MoveApplicationFormBody'

const MoveApplicationPropertyModal = (props) => {
  const { toggleModal, teamGUID, application, property, refresh } = props

  const initialValues = {
    [moveApplicationFormFields.property]: null,
    [moveApplicationFormFields.message]: '',
  }

  const moveApplicationRequest = (values) => {
    const { message, property } = values

    return moveApplicationToProperty(teamGUID, application.guidID, {
      message,
      propertyGUID: property.option.guidID,
    })
      .then(() => {
        snugNotifier.success('Application moved')
        toggleModal()
        refresh()
      })
      .catch((err) => {
        snugNotifier.error(
          err?.message || 'Failed to move application to this property',
        )
      })
  }

  return (
    <Box className="modal-fullscreen" bg={theme.colors.overlay}>
      <ModalContentWrapper>
        <Flex width="100%" flex="1 1" overflowY="auto">
          <Box p={theme.space[7] + 'px'} width="100%">
            <ModalHeader onDismiss={toggleModal} />
            <Box pb={theme.space[5] + 'px'}>
              <Formik
                onSubmit={moveApplicationRequest}
                initialValues={initialValues}
                validate={moveApplicationFormValidation}
                validateOnBlur={true}
              >
                {(props) => (
                  <MoveApplicationFormBody
                    formBag={props}
                    teamGUID={teamGUID}
                    property={property}
                    toggleModal={toggleModal}
                  />
                )}
              </Formik>
            </Box>
          </Box>
        </Flex>
      </ModalContentWrapper>
    </Box>
  )
}

export default MoveApplicationPropertyModal
