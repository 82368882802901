import React from 'react'

import styled from 'styled-components'

import InviteTeamMembersForm from 'app/sm/team_viewing_preference/setup/inviteTeamMemberForm'

const InviteTeamMemberContainer = styled.div`
  padding: 30px;
  margin: 0 20px 20px 0;
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 100%;
`

const InviteTeamMember = ({
  teamGUID,
  toggleSuggestTeamMembers,
  initialInvite,
  setInvitedMembers,
}) => {
  return (
    <InviteTeamMemberContainer>
      <InviteTeamMembersForm
        teamGUID={teamGUID}
        successAction={(email) => {
          setInvitedMembers((oldState) => [...oldState, { email }])
          toggleSuggestTeamMembers()
        }}
        cancelAction={toggleSuggestTeamMembers}
        initialInvite={initialInvite}
      />
    </InviteTeamMemberContainer>
  )
}

export default InviteTeamMember
