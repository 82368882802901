import React from 'react'

import { Link } from 'react-router-dom'

import headerImg07 from 'app/assets/icons/header-img-07.png'
import usersAvatars from 'app/assets/icons/users-avatars.png'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'

class ManagerWait extends React.Component {
  UNSAFE_componentWillMount() {
    let { getManagerWait } = this.props
    let requestID = getParameter('requestid')
    getManagerWait(requestID)
  }

  render() {
    let { currentUser, managerRequestSummary } = this.props
    let { email } = currentUser
    let { request } = managerRequestSummary ? managerRequestSummary : ''
    let { submissionStatus, exitStatus, pauseState, appCode } = request
      ? request
      : ''
    let finalizeApplication = false
    let issueBondCover = false

    if (submissionStatus === 2 && pauseState === 0) {
      finalizeApplication = true
    }
    if (exitStatus === 1 && pauseState === 0) {
      issueBondCover = true
    }

    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <div className="ilustration-box">
            <img src={headerImg07} />
          </div>

          <h3>
            Thanks! We're finalising BondCover for{' '}
            {managerRequestSummary && managerRequestSummary.renterName}
          </h3>

          <div className="bc-app-id">
            <h4>Application ID: {appCode}</h4>
          </div>

          <p>
            {finalizeApplication
              ? ''
              : 'We need to finalise a few details with your renter.'}{' '}
            We'll email you when the BondCover Certificate is ready.
          </p>

          <p>Feel free to contact us</p>

          <a className="pink-color" href="mailto:help@snug.com">
            help@snug.com
          </a>

          <ul className="bc-waiting-status mt40 mb30">
            <li className="completed">
              <div className="icon-box">
                <div className="icon"></div>
              </div>
              <div className="text-box">
                <h4>Approve BondCover</h4>
                {/* <small>2 days ago</small> */}
                {/* Commented out until endpoint supports it */}
              </div>
            </li>
            <li className={finalizeApplication ? 'completed' : 'current'}>
              <div className="icon-box">
                <div className="icon"></div>
              </div>
              <div className="text-box">
                <h4>Finalise application</h4>
                <small></small>
              </div>
            </li>
            <li
              className={
                issueBondCover
                  ? 'completed'
                  : finalizeApplication
                  ? 'current'
                  : ''
              }
            >
              <div className="icon-box">
                <div className="icon"></div>
              </div>
              <div className="text-box">
                <h4>Issue BondCover</h4>
                <small></small>
              </div>
            </li>
          </ul>

          <p>
            You'll receive a copy of this information in your email:{' '}
            <b>{email}</b>
          </p>

          <ul className="snug-tips">
            <li>The approval process usually takes few business days. </li>
            {/* so in the meantime please share some love on social networks */}
          </ul>

          <div className="bc-social-share">
            <img src={usersAvatars} />
          </div>

          {/* Commented out for future use  */}
          {/* <div className="bc-social-share">
            <img src={userAvatars} />
            <h1>#bondcover</h1>
            <p>Spread the word, tell your friends about BondCover and help make renting more affordable.</p>
            <a className="btn btn-facebook" href="https://www.facebook.com/sharer/sharer.php?u=https%3A//snug.com">
              <i className="icon-facebook"></i>
              <span>Share on Facebook</span>
            </a>
            <a className="btn btn-twitter" href="https://twitter.com">
              <i className="icon-twitter"></i>
              <span>Share on Twitter</span>
            </a>
          </div> */}
        </div>
      </div>
    )
  }
}
export default ManagerWait
