import { api } from 'app/utils/api/helpers'

export const getFollowedTeamMembers = (teamID) => {
  return api.get(`/sm/teams/${teamID}/settings/follows/followed`)
}

export const getFollowersTeamMembers = (teamID) => {
  return api.get(`/sm/teams/${teamID}/settings/follows/followers`)
}

export const updateFollowedTeamMembers = (teamID, payload) => {
  return api.put(`/sm/teams/${teamID}/settings/follows/followed`, payload)
}

export const unFollowTeamMember = (teamID, teamMemberID) => {
  return api.delete(
    `/sm/teams/${teamID}/settings/follows/followed/${teamMemberID}`,
  )
}
