import styled from 'styled-components'

export const StyledPill = styled.div`
  min-width: 60px;
  max-width: 80px;
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.bgColor};
  text-align: center;
  padding: ${(props) => `${props.theme.space[1]}px ${props.theme.space[4]}px`};
  border-radius: ${(props) => props.theme.radii[6]}px;
`

export const ContactItemContainer = styled.div`
  .phone-link {
    color: ${(props) => props.theme.colors.deepBlue};
  }
  .email-link {
    img {
      width: 16px;
      height: 14px;
      margin-left: ${(props) => props.theme.space[1]}px;
    }
  }
`

export const ActionContainer = styled.a`
  max-width: 120px;
  color: ${(props) => props.theme.colors.success600};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radii[2]}px;
  border: ${(props) =>
    `${props.theme.borders[1]} ${props.theme.colors.success600}`};
  padding: ${(props) => props.theme.space[3]}px;
  font-size: ${(props) => props.theme.fontSizes[4]}px;
  display: block;
  text-decoration: none;
  text-align: center;
  width: 100%;
  margin: auto;
  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.colors.success600};
    text-decoration: none;
    color: ${(props) => props.theme.colors.white};
  }
`
