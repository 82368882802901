import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import * as snugLogger from 'app/services/snugLogger'
import { addRentalReputationBackUrl } from 'app/shared_components/actions'
import {
  apiFailCallback,
  composeAttachment,
} from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import {
  _attachToProfile,
  _discardResult,
  _saveCard,
  addIdentityDoc,
  alterIdentityDoc,
  deleteIdentityDoc,
  getBackgroundCheck,
  getCards,
  getIdentityDoc,
  getIdentityDocs,
  getIdentityDocuments,
  getPaymentAmount,
  payWithExistingAccount,
  payWithNewAccount,
  removeIdentityDocuments,
  uploadIdentityDocuments,
} from 'app/sm/background_check/background_check_access'
import { urlTo, wait } from 'app/sm/helpers'
import store from 'app/store'

export const CARDS_RECEIVED = 'IDC_CARDS_RECEIVED'
export const CARD_SELECT = 'IDC-CARD-SELECT'
export const ACCEPT_TERMS_CONDITIONS_CHANGE =
  'IDC-ACCEPT-TERMS-CONDITIONS-CHANGE'
export const RECEIVE_DOC = 'IDC_RECEIVE_DOC'
export const RECEIVE_DOCS = 'IDC_RECEIVE_DOCS'
export const RECEIVE_BACKGROUND_CHECK = 'IDC_RECEIVE_BACKGROUND_CHECK'
export const TRIGGER_SPINNER = 'IDC_TRIGGER_SPINNER'
export const RESET_SPINNER = 'IDC_RESET_SPINNER'
export const RECEIVE_PAYMENT_AMOUNT = 'IDC_RECEIVE_PAYMENT_AMOUNT'
export const RECEIVE_RESPONSE_TEXT = 'IDC_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'IDC_RECEIVE_ERROR'
export const CLEAR_ERROR = 'IDC_CLEAR_ERROR'
export const RENDER_ALL_CARDS_CHANGE = 'IDC_RENDER_ALL_CARDS_CHANGE'
export const POLLING_STOPPED = 'IDC_POLLING_STOPPED'
export const DISCARD_RESULT = 'IDC_DISCARD_RESULT'
export const ATTACH_TO_PROFILE = 'IDC_ATTACH_TO_PROFILE'
export const RECEIVE_IDENTITY_DOCUMENTS = 'IDC_RECEIVE_IDENTITY_DOCUMENTS'

const receiveIdentityDocuments = (identityDocAttachments) => ({
  type: RECEIVE_IDENTITY_DOCUMENTS,
  identityDocAttachments,
})

const baseRoute = '/sm/background-check'

export const steps = {
  OVERVIEW: `${baseRoute}/overview`,
  DETAILS: `${baseRoute}/details`,
  EDIT_DETAILS: (id) => `${baseRoute}/${id}/details`,
  PAYMENT: `${baseRoute}/pay`,
  CONFIRMATION: `${baseRoute}/confirmation`,
}

// Intercepted by Middleware
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})

// Emitted by Middleware for Reducer
export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })
export const clearError = () => ({ type: CLEAR_ERROR })
export const cardsReceived = (cards) => ({ type: CARDS_RECEIVED, cards })
export const cardSelect = (selected) => ({ type: CARD_SELECT, selected })
export const acceptTermsConditionsChange = () => ({
  type: ACCEPT_TERMS_CONDITIONS_CHANGE,
})

export const receiveIdentityDocs = (identityDocList) => ({
  type: RECEIVE_DOCS,
  identityDocList,
})
export const receiveBackgroundCheck = (backgroundCheck) => ({
  type: RECEIVE_BACKGROUND_CHECK,
  backgroundCheck,
})
export const triggerSpinner = () => ({ type: TRIGGER_SPINNER })
export const resetSpinner = () => ({ type: RESET_SPINNER })
export const receivePaymentAmount = (paymentAmount) => ({
  type: RECEIVE_PAYMENT_AMOUNT,
  paymentAmount,
})
export const renderAllCardsChange = (renderAllCards) => ({
  type: RENDER_ALL_CARDS_CHANGE,
  renderAllCards,
})
export const receivePollingStopped = (isPollingStopped) => ({
  type: POLLING_STOPPED,
  isPollingStopped,
})
export const emitDiscardResult = () => ({ type: DISCARD_RESULT })
export const emitAttashToProfile = () => ({ type: ATTACH_TO_PROFILE })

export function continueAction(currentStep) {
  const backUrl = window.location.pathname
  store.dispatch(addRentalReputationBackUrl(backUrl))
  return (dispatch, getState) => {
    switch (currentStep) {
      case steps.OVERVIEW: {
        const { identityDoc } = getState().BackgroundCheck
        if (identityDoc === null || identityDoc.guidID === undefined) {
          history.push(steps.DETAILS)
        } else {
          history.push(steps.EDIT_DETAILS(identityDoc.guidID))
        }
        break
      }
      case steps.PAYMENT:
        history.push(steps.CONFIRMATION)
        break
      default:
    }
  }
}

function pay(data, applicationGUID = '') {
  store.dispatch(clearError())
  return (dispatch) => {
    payWithNewAccount(data, applicationGUID).then(({ ok, responseText }) => {
      if (ok) {
        history.push(`${urlTo('BackgroundCheck')}${window.location.search}`)
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function saveCard(card, applicationGUID = '') {
  store.dispatch(clearError())
  return (dispatch) => {
    _saveCard(card).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(pay(card, applicationGUID))
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function fetchBackgroundCheckWithoutPulling() {
  return (dispatch) => {
    dispatch(triggerSpinner())
    getBackgroundCheck().then(({ ok, backgroundCheck }) => {
      if (ok) {
        if (backgroundCheck.status === 'pending') {
          wait(5000).then(() => dispatch(fetchBackgroundCheckWithoutPulling()))
        } else {
          dispatch(receiveBackgroundCheck(backgroundCheck))
          dispatch(resetSpinner())
        }
      } else {
        dispatch(resetSpinner())
      }
    })
  }
}

export function fetchBackgroundCheck() {
  return (dispatch) => {
    dispatch(triggerSpinner())

    getBackgroundCheck().then(({ ok, backgroundCheck, responseText }) => {
      if (ok) {
        dispatch(receiveBackgroundCheck(backgroundCheck))
        dispatch(resetSpinner())
      } else {
        responseText.then((t) => {
          store.dispatch(receiveResponseText(t))
          dispatch(resetSpinner())
        })
      }
    })
  }
}

export function fetchIdentityDocs() {
  return (dispatch) => {
    return getIdentityDocs().then(({ ok, identityDocList, responseText }) => {
      if (ok) {
        dispatch(fetchBackgroundCheckWithoutPulling())
        dispatch(receiveIdentityDocs(identityDocList))
        return Promise.resolve(identityDocList)
      } else {
        apiFailCallback(responseText, () => {
          snugLogger.warn('missing fail function')
        })
        return responseText
          .then((error) => Promise.resolve(translateErrorCodeToMessage(error)))
          .then((error) => Promise.reject(error))
      }
    })
  }
}

export function createIdentityDoc(data, isDraft) {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    return addIdentityDoc(data, isDraft).then(
      ({ ok, responseText, identityDoc }) => {
        if (ok) {
          return Promise.resolve(identityDoc)
        } else {
          return responseText.then((t) => {
            return Promise.reject(t)
          })
        }
      },
    )
  }
}

export function removeIdentityDoc(id) {
  return (dispatch) => {
    return deleteIdentityDoc(id).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(fetchIdentityDocs())
        return Promise.resolve()
      } else {
        responseText.then().then((t) => dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function updateIdentityDoc(data, id) {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    // const { checkStatus } = getState().BackgroundCheck.identityDoc

    return alterIdentityDoc(data, id).then(({ ok, responseText }) => {
      if (ok) {
        history.goBack()
        return Promise.resolve()
      } else {
        return responseText.then((t) => {
          return Promise.reject(t)
        })
      }
    })
  }
}

export function attachToProfile() {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    const { backUrl } = getState().Shared
    _attachToProfile().then(({ ok, responseText }) => {
      if (ok) {
        dispatch(emitAttashToProfile())
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function discardResult() {
  store.dispatch(clearError())
  return (dispatch) => {
    return _discardResult().then(({ ok, responseText }) => {
      if (ok) {
        dispatch(emitDiscardResult())
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function fetchPaymentAmount() {
  return (dispatch) => {
    getPaymentAmount().then(({ ok, paymentAmount, responseText }) => {
      if (ok) {
        const { amount = 0 } = paymentAmount
        dispatch(receivePaymentAmount(amount))
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function fetchCards() {
  return (dispatch) => {
    getCards().then(({ cards: cardsPayload, ok, responseText }) => {
      if (ok) {
        const { cards, defaultCard } = cardsPayload

        const Cards = cards
          .map((c) => ({
            id: c.guidID,
            number: `${c.cardBrand} XXXX-${c.cardLast4} (exp ${
              c.cardExpMonth
            }/${c.cardExpYear % 100})`,
          }))
          .concat({
            id: 'New',
            number: 'New Credit Card',
          })

        dispatch(cardsReceived(Cards))

        if (defaultCard) {
          dispatch(cardSelect(defaultCard))
          dispatch(renderAllCardsChange(false))
          return
        }

        dispatch(cardSelect(Cards[0].id))
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function registerPayment(data, applicationGUID = '') {
  return (dispatch) => {
    dispatch(saveCard(data, applicationGUID))
  }
}

export function payWithExistingCard(applicationID = '') {
  store.dispatch(clearError())
  return payWithExistingAccount(applicationID).then(({ ok, responseText }) => {
    if (ok) {
      return Promise.resolve(true)
    } else {
      responseText.then((t) => {
        store.dispatch(receiveResponseText(t))
        return Promise.resolve(false)
      })
    }
  })
}

export const fetchIdentityDocAttachments = (id, success, fail) => () => {
  return getIdentityDocuments(id).then(
    ({ ok, attachments, responseText, statusText }) => {
      if (ok) {
        return Promise.resolve(attachments)
      } else {
        return responseText.then((t) => {
          return Promise.reject(t)
        })
      }
    },
  )
}

export const addIdentityDocAttachment =
  (documentType, id, isIdentityDoc) => () => {
    const attachments = document.getElementById(`attachments-${documentType}`)
    let f = new FormData()

    composeAttachment(attachments, f)

    return uploadIdentityDocuments(f, id, isIdentityDoc, documentType).then(
      ({ ok, statusCode, responseText, statusText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((t) => {
            return Promise.reject(translateErrorCodeToMessage(t))
          })
        }
      },
    )
  }

export const deleteIdentityDocAttachment = (
  id,
  attachmentid,
  isIdentityDoc,
) => {
  return (dispatch) => {
    return removeIdentityDocuments(attachmentid).then(
      ({ ok, statusCode, responseText, statusText }) => {
        if (ok) {
          isIdentityDoc && dispatch(fetchIdentityDocAttachments(id))
          return Promise.resolve()
        } else {
          return responseText.then((t) => {
            return Promise.reject(t)
          })
        }
      },
    )
  }
}

export const fetchIdentityDoc = (id) => () => {
  return getIdentityDoc(id).then(({ ok, identityDoc, responseText }) => {
    if (ok) {
      return Promise.resolve(identityDoc)
    } else {
      return responseText.then((t) => {
        return Promise.reject(t)
      })
    }
  })
}
