import { connect } from 'react-redux'

import {
  fetchAgencyProfile,
  fetchAgencyProfileForBackGroundCheck,
  fetchAgencyStatus,
  fetchAllPropertyFutureViewings,
  fetchTeamManagerList,
} from 'app/bond_cover/agency/agency_actions'
import { fetchRentalHistoryRatingCategories } from 'app/bond_cover/bond_cover_actions'
import { fetchLeasingOfferInfo } from 'app/match/leaseAttachment/action'
import { changeTeam } from 'app/session/session_actions'
import { setBackUrl } from 'app/shared_components/actions'
import {
  assignApplicationToTeamMember,
  fetchApplicationTeamMember,
} from 'app/sm/applications/applications_actions'
import { addViewing } from 'app/sm/ppp/viewing_actions'
import {
  markProgressApplicationAsApproved,
  markProgressApplicationAsLeased,
  sendScheduleViewingFromApplication,
  sendUtilityReferral,
} from 'app/sm/progress_application/action'
import {
  fetchCompleteness,
  fetchTeamSettingInfo,
  retrieveReputationPreference,
} from 'app/sm/rental_reputation/actions'
import RenterApplication from 'app/sm/renter_applications/renter_application_detailed/new_renter_application_container'
import {
  moveTo,
  shortlistApplication,
  unshortlistApplication,
} from 'app/sm/renter_applications/renter_applications_actions'
import {
  addPMSupportDoc,
  declineApplication,
  deletePMSupportAttachments,
  fetchBackgroundForManager,
  fetchPMSupportAttachments,
  fetchRentAndEmploymentSummary,
  fetchRenterApplication,
  getApplicationPDF,
  managerEditRentAndEmploymentSummary,
  managerProcessBackgroundCheck,
  onStatusDropdownChange,
  requestRenterInformation,
  requestSubmitApplication,
  toggleSendRequestInfo,
  updatePreferences,
} from 'app/sm/renter_applications/renter_applications_actions'
import { fetchApplicationApplicantBackgroundCheckReportDetails } from 'app/sm/renter_background_check_report/action'
import { addNote, removeRentalReference } from 'app/sm/review/review_actions'
import { getManagersListForViewingFilters } from 'app/sm/viewings/viewings_actions'

const mapStateToProps = ({
  RenterApplications,
  RentalReputation,
  Profile,
  session,
  bondCover,
}) => ({
  application: RenterApplications.application,
  isRequestInfoDone: RenterApplications.isRequestInfoDone,
  error: RenterApplications.error,
  completeness: RentalReputation.completeness,
  spinner: RenterApplications.spinner,
  reputationPreference: RentalReputation.preference,
  profile: Profile.profile,
  currentTeam: session.currentTeam,
  teams: session.teams,
  versionsRatingCategories: bondCover.versionsRatingCategories,
})

const mapDispatchToProps = (dispatch) => ({
  fetchRenterApplication: (applicationId, applicantId, setting) =>
    dispatch(fetchRenterApplication(applicationId, applicantId, setting)),
  onStatusDropdownChange: (value, applicationId, dispatchedFromTab) =>
    dispatch(onStatusDropdownChange(value, applicationId, dispatchedFromTab)),
  requestRenterInformation: (applicationId, applicantId, content) =>
    dispatch(requestRenterInformation(applicationId, applicantId, content)),
  toggleSendRequestInfo: () => dispatch(toggleSendRequestInfo()),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
  getApplicationPDF: (applicationId, applicantId, downloadOption) =>
    dispatch(getApplicationPDF(applicationId, applicantId, downloadOption)),
  retrieveReputationPreference: () => dispatch(retrieveReputationPreference()),
  updatePreferences: (id, payload, applicationId, applicantId) =>
    dispatch(updatePreferences(id, payload, applicationId, applicantId)),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
  changeTeam: (currentTeam) => dispatch(changeTeam(currentTeam)),
  shortListApplication: (applicationID) =>
    dispatch(shortlistApplication(applicationID)),
  removeShortListApplication: (applicationID) =>
    dispatch(unshortlistApplication(applicationID)),
  fetchRentalHistoryRatingCategories: (versions) =>
    dispatch(fetchRentalHistoryRatingCategories(versions)),
  addNote: (applicationId, applicantId, payload) =>
    dispatch(addNote(applicationId, applicantId, payload)),
  moveTo: (applicationId, newStatus) =>
    dispatch(moveTo(applicationId, newStatus)),
  addPMSupportDoc: (documentType, applicantID) =>
    dispatch(addPMSupportDoc(documentType, applicantID)),
  fetchPMSupportAttachments: (applicantID) =>
    dispatch(fetchPMSupportAttachments(applicantID)),
  deletePMSupportAttachments: (id) => dispatch(deletePMSupportAttachments(id)),
  removeRentalReference: (rentalHistoryId) =>
    dispatch(removeRentalReference(rentalHistoryId)),
  fetchProgressApplication: (applicationId, agencyId) =>
    dispatch(fetchLeasingOfferInfo(applicationId, agencyId)),
  markProgressApplicationAsLeased: (
    applicationId,
    applicationInfo,
    markAsLeasedOptions,
  ) =>
    dispatch(
      markProgressApplicationAsLeased(
        applicationId,
        applicationInfo,
        markAsLeasedOptions,
      ),
    ),
  markProgressApplicationAsApproved: (
    applicationId,
    applicationInfo,
    agencyGUID,
    markAsApplicationApprovedOptions,
  ) =>
    dispatch(
      markProgressApplicationAsApproved(
        applicationId,
        applicationInfo,
        agencyGUID,
        markAsApplicationApprovedOptions,
      ),
    ),
  managerProcessBackgroundCheck: (applicantId, applicationId) =>
    dispatch(managerProcessBackgroundCheck(applicantId, applicationId)),
  fetchBackgroundForManager: (applicantId, applicationId) =>
    dispatch(fetchBackgroundForManager(applicantId, applicationId)),
  requestSubmitApplication: (applicationGUID) =>
    dispatch(requestSubmitApplication(applicationGUID)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
  fetchTeamManagerList: (teamGUID, success, fail) =>
    dispatch(fetchTeamManagerList(teamGUID, success, fail)),
  assignApplicationToTeamMember: (teamId, applicationGUID, teamMemberGUID) =>
    dispatch(
      assignApplicationToTeamMember(teamId, applicationGUID, teamMemberGUID),
    ),
  fetchApplicationTeamMember: (teamGUID, applicationGUID) =>
    dispatch(fetchApplicationTeamMember(teamGUID, applicationGUID)),
  getManagersListForViewingFilters: (teamGUID) =>
    dispatch(getManagersListForViewingFilters(teamGUID)),
  sendScheduleViewingFromApplication: (
    applicationGUID,
    applicantGUID,
    viewingGUID,
  ) =>
    dispatch(
      sendScheduleViewingFromApplication(
        applicationGUID,
        applicantGUID,
        viewingGUID,
      ),
    ),
  addViewing: (
    id,
    startdate,
    duration,
    fromScheduler,
    skipDoubleBookingCheck,
    options,
  ) =>
    dispatch(
      addViewing(
        id,
        startdate,
        duration,
        fromScheduler,
        skipDoubleBookingCheck,
        options,
      ),
    ),
  fetchAllPropertyFutureViewings: (teamGUID) =>
    dispatch(fetchAllPropertyFutureViewings(teamGUID)),
  declineApplication: (
    agencyGUID,
    applicationID,
    declineReasons,
    notifyApplicant,
    otherReasonComment,
  ) =>
    dispatch(
      declineApplication(
        agencyGUID,
        applicationID,
        declineReasons,
        notifyApplicant,
        otherReasonComment,
      ),
    ),
  fetchAgencyProfile: (agencyGUID, fetchSuccess) =>
    dispatch(fetchAgencyProfile(agencyGUID, fetchSuccess)),
  fetchAgencyProfileForBackGroundCheck: (agencyGUID) =>
    dispatch(fetchAgencyProfileForBackGroundCheck(agencyGUID)),
  fetchRentAndEmploymentSummary: (applicationId) =>
    dispatch(fetchRentAndEmploymentSummary(applicationId)),
  managerEditRentAndEmploymentSummary: (applicationId, request) =>
    dispatch(managerEditRentAndEmploymentSummary(applicationId, request)),
  fetchAgencyStatus: (agencyGUID) => dispatch(fetchAgencyStatus(agencyGUID)),
  sendUtilityReferral: (applicationId) =>
    dispatch(sendUtilityReferral(applicationId)),
  fetchApplicationApplicantBackgroundCheckReportDetails: (
    applicationGUID,
    applicantGUID,
  ) =>
    dispatch(
      fetchApplicationApplicantBackgroundCheckReportDetails(
        applicationGUID,
        applicantGUID,
      ),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(RenterApplication)
