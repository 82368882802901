import React from 'react'

import PropTypes from 'prop-types'

import isEmptyObject from 'app/shared_components/check_empty_object'
import * as validations from 'app/shared_components/validations'

const ErrorMessage = ({ error }) => (
  <div className="apply-form-error row">
    <div className="col-sm-12">
      <div className="alert alert-danger">
        <div>{error}</div>
      </div>
    </div>
  </div>
)
class SupplierSourceForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      staticSupplierSources: [],
      errors: {},
      editMode: false,
      supplierSource: {},
      showSpinner: false,
      apiError: '',
    }
  }

  componentDidMount() {
    let { clearError } = this.props
    clearError()

    this.onFetchAllSupplierSources()
  }

  onCreateSupplierSource = (supplierSource) => {
    const { createSupplierSource } = this.props
    createSupplierSource(
      supplierSource,
      (supplierSource) => {
        this.setState({ supplierSource, showSpinner: false })
        this.onFetchAllSupplierSources()
      },
      (error) => {
        this.setState({ apiError: error, showSpinner: false })
      },
    )
  }

  onFetchAllSupplierSources = () => {
    const { fetchAllSupplierSources } = this.props
    fetchAllSupplierSources((supplierSources) => {
      this.setState({ supplierSources })

      //make another static copy for the dropdown
      const staticSources = JSON.parse(JSON.stringify(supplierSources))
      this.setState({ staticSupplierSources: staticSources })
    })
  }

  onUpdateSupplierSource = (supplierSource) => {
    const { updateSupplierSource } = this.props
    updateSupplierSource(
      supplierSource.GUID,
      supplierSource,
      (supplierSource) => {
        this.setState({ showSpinner: false, supplierSource })
        this.onFetchAllSupplierSources()
      },
      (error) => {
        this.setState({ apiError: error, showSpinner: false })
      },
    )
  }

  setValidationErrors(field, error) {
    let errors = this.state.errors
    if (error.length === 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  changeMode = (event) => {
    const { value } = event.target
    const { staticSupplierSources } = this.state
    const supplierSrc =
      staticSupplierSources &&
      staticSupplierSources.find((m) => {
        return m.guid_id === value
      })

    if (value !== undefined) {
      if (value.trim().length > 0) {
        //new clicked and edit mode, switch to create mode
        if (value === 0) {
          this.setState({ editMode: false, supplierSource: {} })
        }

        //new not clicked and edit mode, switch to edit mode
        if (value !== 0) {
          this.setState({
            editMode: true,
            supplierSource: JSON.parse(JSON.stringify(supplierSrc)),
          })
        }
      }
    }
    this.setState({
      supplierSource:
        supplierSrc === undefined
          ? {}
          : JSON.parse(JSON.stringify(supplierSrc)),
    })
  }

  isDisabled = (supplierSource) => {
    if (supplierSource === undefined) {
      return true
    }

    if (
      supplierSource.source === undefined ||
      supplierSource.token === undefined
    ) {
      return true
    }

    if (
      supplierSource.source.trim().length === 0 ||
      supplierSource.token.trim().length === 0
    ) {
      return true
    }

    if (
      supplierSource.sendReport &&
      supplierSource.recipientAddress &&
      supplierSource.recipientAddress.trim().length === 0
    ) {
      return true
    }

    if (this.state.showSpinner) {
      return true
    }

    return !!(
      this.state.errors &&
      this.state.errors.recipientAddress &&
      this.state.errors.recipientAddress.length > 0
    )
  }

  submit = () => {
    const { supplierSource, editMode } = this.state

    this.setState({ showSpinner: true }, () => {})

    let supplierSrc
    if (!supplierSource.sendReport) {
      supplierSource.recipientAddress = ''
    }

    supplierSrc = {
      GUID: supplierSource.guid_id,
      supplierSource: supplierSource.source,
      token: supplierSource.token,
      active:
        supplierSource.active === undefined ? false : supplierSource.active,
      sendReport:
        supplierSource.sendReport === undefined
          ? false
          : supplierSource.sendReport,
      recipientAddress: supplierSource.recipientAddress,
      showExternalImageLink:
        supplierSource.showExternalImageLink === undefined
          ? false
          : supplierSource.showExternalImageLink,
    }

    if (editMode) {
      supplierSrc.GUID = supplierSource.guid_id
      this.onUpdateSupplierSource(supplierSrc)
    } else {
      this.onCreateSupplierSource(supplierSrc)
    }
  }

  update = (event, field) => {
    let { value } = event.target
    const { supplierSource } = this.state

    switch (field) {
      case 'supplierSourceName':
        supplierSource.source = value
        break
      case 'token':
        supplierSource.token = value
        break
      case 'active':
        supplierSource.active = !supplierSource.active
        break
      case 'showExternalImages':
        supplierSource.showExternalImageLink =
          !supplierSource.showExternalImageLink
        break
      case 'sendReport':
        supplierSource.sendReport = !supplierSource.sendReport
        if (!supplierSource.sendReport) {
          supplierSource.recipientAddress = ''
        }
        break
      case 'recipientAddress':
        supplierSource.recipientAddress = value
        break
      default:
        break
    }

    this.setState({
      supplierSource: supplierSource,
      unsavedChanges: true,
    })
  }

  validate(field) {
    return () => {
      const { supplierSource } = this.state

      switch (field) {
        case 'supplierSourceName':
          return this.setValidationErrors(
            field,
            validations.validateSupplierSourceName(
              supplierSource.supplierSourceName,
              'Invalid Supplier Source Name',
            ),
          )
        case 'token':
          return this.setValidationErrors(
            field,
            validations.validateSupplierSourceToken(
              supplierSource.token,
              'Invalid token',
            ),
          )
        case 'recipientAddress':
          return (
            supplierSource.sendReport &&
            this.setValidationErrors(
              field,
              validations.validateEmail(
                supplierSource.recipientAddress,
                'Invalid email address',
              ),
            )
          )
        default:
          return undefined
      }
    }
  }

  render() {
    const {
      errors,
      showSpinner,
      supplierSource,
      staticSupplierSources,
      apiError,
    } = this.state

    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <div>
            <div className="input-box">
              <label> Supplier Source</label>
            </div>
            <div className="input-box">
              <select
                name="select"
                onChange={(event) => this.changeMode(event)}
              >
                <option value="0" key="0">
                  New
                </option>
                {staticSupplierSources &&
                  staticSupplierSources.map((m) => {
                    return (
                      <option value={m.guid_id} key={m.guid_id}>
                        {' '}
                        {m.source}
                      </option>
                    )
                  })}
              </select>
            </div>
          </div>
          <div>
            <div className="input-box">
              <input
                type="text"
                required
                className={errors.supplierSourceName ? 'error' : ''}
                value={
                  !isEmptyObject(supplierSource) ? supplierSource.source : ''
                }
                onChange={(event) => this.update(event, 'supplierSourceName')}
                onBlur={this.validate('supplierSourceName')}
              />

              <label>Supplier Source Name</label>
            </div>

            <div
              className={
                errors.supplierSourceName && this.isDisabled(supplierSource)
                  ? 'alert alert-danger'
                  : 'hide-alert'
              }
            >
              <div>{errors.SupplierSourceName}</div>
            </div>
          </div>

          <div>
            <div className="input-box">
              <input
                type="text"
                required
                className={errors.token ? 'error' : ''}
                value={
                  !isEmptyObject(supplierSource) ? supplierSource.token : ''
                }
                onChange={(event) => this.update(event, 'token')}
                onBlur={this.validate('token')}
              />

              <label>Token</label>
            </div>
            <div
              className={
                errors.token && this.isDisabled(supplierSource)
                  ? 'alert alert-danger'
                  : 'hide-alert'
              }
            >
              <div>{errors.token}</div>
            </div>
          </div>

          <div>
            <div className="checkbox pl20">
              <input
                type="checkbox"
                required
                checked={supplierSource && supplierSource.active}
                onChange={(event) => this.update(event, 'active')}
                onBlur={this.validate('active')}
              />

              <label> Active </label>
            </div>
          </div>
          <div>
            <div className="checkbox pl20">
              <input
                type="checkbox"
                required
                checked={supplierSource && supplierSource.showExternalImageLink}
                onChange={(event) => this.update(event, 'showExternalImages')}
                onBlur={this.validate('showExternalImages')}
              />
              <label> Show External Images </label>
            </div>
          </div>
          <div>
            <div className="checkbox pl20">
              <input
                type="checkbox"
                required
                checked={supplierSource && supplierSource.sendReport}
                onChange={(event) => this.update(event, 'sendReport')}
                onBlur={this.validate('sendReport')}
              />
              <label> Send Report </label>
            </div>
          </div>
          {supplierSource && supplierSource.sendReport && (
            <div>
              <div className="input-box">
                <input
                  type="text"
                  required
                  className={errors.recipientAddress ? 'error' : ''}
                  onChange={(event) => this.update(event, 'recipientAddress')}
                  value={
                    !isEmptyObject(supplierSource)
                      ? supplierSource.recipientAddress
                      : ''
                  }
                  onBlur={this.validate('recipientAddress')}
                />

                <label> Recipient Address </label>
              </div>
              <div
                className={
                  errors.recipientAddress && this.isDisabled(supplierSource)
                    ? 'alert alert-danger'
                    : 'hide-alert'
                }
              >
                <div>{errors.recipientAddress}</div>
              </div>
            </div>
          )}
          {apiError && <ErrorMessage error={apiError} />}
          <button
            className="mt15 mb30"
            onClick={this.submit}
            disabled={this.isDisabled(supplierSource)}
          >
            {this.state['editMode']
              ? 'Update Supplier Source'
              : 'Add Supplier Source'}{' '}
            <i className={showSpinner ? 'fa fa-spinner fa-pulse' : ''} />
          </button>
        </div>
      </div>
    )
  }
}

SupplierSourceForm.propTypes = {
  error: PropTypes.string,
  clearError: PropTypes.func.isRequired,
}

SupplierSourceForm.defaultProps = {
  error: null,
}

export default SupplierSourceForm
