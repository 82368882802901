import React from 'react'

import {
  ExitStatus,
  IDCheckStatus,
  managerActionStatus,
  pauseStateStatus,
  paymentStatus,
} from 'app/shared_components/helpers'

export class PaymentProcessText extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { paymentProcessText, payment } = this.props

    return (
      <div
        className={
          paymentStatus[payment.status] === 'Error'
            ? 'alert alert-danger'
            : 'alert alert-success'
        }
      >
        {paymentProcessText}
      </div>
    )
  }
}

const ApplicationBtns = ({
  lease,
  request,
  payment,
  onReopen,
  onRelease,
  onSend,
  reopenState,
  releaseState,
  pauseButtonContext,
  onUnpause,
  onProcessPayment,
  onDecline,
  spinner,
  responseText,
}) => {
  let ApplicationBtnsHtml = null
  if (lease && request) {
    let {
      primaryTenantSigned,
      managerAction,
      exitStatus,
      pauseState,
      submissionStatus,
      idCheckStatus,
    } = request

    let isReopenAllowed =
      !managerAction && primaryTenantSigned && !!submissionStatus && !exitStatus

    let reopenText = 'Reopen for Renter'
    let releaseText = 'Resend to Manager'
    if (pauseState === 1) {
      releaseText = 'Release to Manager'
    }

    const showDeclineButton =
      exitStatus === 0 && managerAction !== 2 && !payment

    let paymentProcessText = ''
    if (payment) {
      switch (paymentStatus[payment.status]) {
        case 'Pending':
          paymentProcessText = 'The payment is pending'
          break
        case 'Success':
          paymentProcessText = 'The payment is successful'
          break
        case 'Error':
          paymentProcessText = 'The payment is failed'
          break
      }
    }

    let showPayButton =
      pauseStateStatus[pauseState] === 'Not Paused' &&
      managerActionStatus[managerAction] === 'Manager Approved' &&
      IDCheckStatus[idCheckStatus] === 'ID Check Succeeded' &&
      (!payment || paymentStatus[payment.status] === 'Error') &&
      ExitStatus[exitStatus] === 'Exit Status In Progress'

    ApplicationBtnsHtml = (
      <div>
        <div className="requestBtnBox">
          {isReopenAllowed && (
            <li>
              <button
                className="requestBtn"
                onClick={onReopen}
                disabled={spinner}
              >
                {reopenText}
                <i className={reopenState ? 'fa fa-spinner fa-pulse' : ''} />
              </button>
            </li>
          )}

          {isReopenAllowed &&
            (releaseText == 'Release to Manager' ? (
              <li>
                <button
                  className="requestBtn"
                  onClick={onRelease}
                  disabled={releaseState}
                >
                  {releaseText}
                  <i className={releaseState ? 'fa fa-spinner fa-pulse' : ''} />
                </button>
              </li>
            ) : (
              <li>
                <button
                  className="requestBtn"
                  onClick={onSend}
                  disabled={releaseState}
                >
                  {releaseText}
                  <i className={releaseState ? 'fa fa-spinner fa-pulse' : ''} />
                </button>
              </li>
            ))}

          {!!pauseButtonContext && (
            <li>
              <button
                disabled={spinner}
                className="requestBtn"
                onClick={() => {
                  onUnpause(pauseButtonContext)
                }}
              >
                {pauseButtonContext}
              </button>
            </li>
          )}

          {!!showDeclineButton && (
            <li>
              <button
                disabled={spinner}
                className="requestBtn declineButton"
                onClick={() => {
                  onDecline()
                }}
              >
                Decline
              </button>
            </li>
          )}
          {showPayButton && (
            <li>
              <button
                disabled={spinner}
                className="requestBtn payButton"
                name="payButton"
                onClick={() => {
                  onProcessPayment()
                }}
              >
                Pay
              </button>
            </li>
          )}
        </div>
        {!!paymentProcessText && (
          <PaymentProcessText
            paymentProcessText={paymentProcessText}
            payment={payment}
          />
        )}
      </div>
    )
  }

  return (
    <div>
      {ApplicationBtnsHtml}
      {!!responseText && (
        <div className="alert alert-danger"> {responseText} </div>
      )}
    </div>
  )
}

export default ApplicationBtns
