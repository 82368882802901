import React from 'react'

import { formatNumber, parseNumber } from 'libphonenumber-js'

import { SectionHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import * as Form from 'app/components/forms/forms'
import { ErrorMessage } from 'app/shared_components/helpers'
import StandardScreen from 'app/shared_components/layout_component/standard_screen_layout_component'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import {
  isRelationshipOther,
  relationshipTypesIds,
  relationshipTypesTitles,
  validatePersonalReferenceRelationship,
} from 'app/sm/helpers'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import { isCurrentRoute } from 'app/sm/router_helpers'
import 'app/profile/renter/personal_reference/style.scss'
import { NUMBER_TYPE_MOBILE } from 'app/utils/phonenumber/helpers'

const allowedRelationships = [
  relationshipTypesIds.Colleague,
  relationshipTypesIds.CommunityLeader,
  relationshipTypesIds.FamilyFriend,
  relationshipTypesIds.Other,
]

const mappedAllowedRelationships = allowedRelationships.map((id) => ({
  id,
  title: relationshipTypesTitles[id],
}))

const mappedOptions = [{ id: 0, title: 'Select Relationship' }].concat(
  mappedAllowedRelationships,
)

const getRelationshipOptionByOptionIndex = (optionIdx) =>
  mappedOptions[optionIdx]

class PersonalReferenceForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      countryCode: '',
      relationship: 0,
      comment: '',
      clicked: false,
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        relationship: '',
        phoneNumber: '',
        comment: '',
      },
      formError: '',
      unsavedChanges: false,
      showValidationStatus: true,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { personalRefId },
      },
      getPersonalReference,
    } = this.props
    if (personalRefId) {
      getPersonalReference(personalRefId)
      this.setState({
        showValidationStatus: false,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { personalReference } = nextProps
    this.setState({ clicked: false })
    if (personalReference && personalReference.guidID) {
      const parsedNumber = parseNumber(nextProps.personalReference.phoneNumber)
      const formattedPhoneNumber = formatNumber(
        personalReference.phoneNumber,
        'AU',
        'International',
      )

      const selectedRelationshipIdx = mappedOptions.findIndex(
        ({ id }) => id === personalReference.relationship,
      )

      this.setState({
        firstName: personalReference.firstName,
        lastName: personalReference.lastName,
        email: personalReference.email,
        phoneNumber: formattedPhoneNumber,
        relationship: selectedRelationshipIdx,
        comment: personalReference.comment || '',
        countryCode: parsedNumber && parsedNumber.country,
      })
    }
  }

  submit = () => {
    const {
      createPersonalReference,
      editPersonalReference,
      match: {
        params: { personalRefId },
      },
    } = this.props
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      comment,
      countryCode = 'AU',
    } = this.state

    const selectedRelationshipOption =
      getRelationshipOptionByOptionIndex(this.state.relationship) || {}

    const data = {
      firstName,
      lastName,
      email,
      phoneNumber,
      countryCode: countryCode,
      relationship: selectedRelationshipOption.id,
      comment: isRelationshipOther(selectedRelationshipOption.id)
        ? comment
        : '',
    }
    const isValid = this.validateRequiredFields()
    if (isValid) {
      this.setState({ clicked: true, unsavedChanges: false })
      if (personalRefId) {
        editPersonalReference(data, personalRefId)
      } else {
        createPersonalReference(data)
      }
    }
  }

  updateField = (field) => {
    return (data) => {
      if (field === 'relationship') {
        this.setState({
          [field]: parseInt(data.value, 10),
          showValidationStatus: true,
          errors: {
            ...this.state.errors,
            comment: '',
          },
        })
      } else {
        this.setState({
          [field]: data.value,
          showValidationStatus: true,
          errors: {
            ...this.state.errors,
            [field]: data.error,
          },
        })
      }
    }
  }

  validateRequiredFields() {
    const errors = {}
    Object.keys(this.state.errors)
      .filter((field) => field !== 'phoneNumber')
      .filter((field) => field !== 'email')
      .forEach((field) => {
        if (field === 'relationship' || field === 'comment') {
          const errorMessage = validatePersonalReferenceRelationship(
            this.state.relationship,
            this.state.comment,
          )[field]
          if (errorMessage) {
            errors[field] = errorMessage
          }
        } else if (this.state[field] === '') {
          errors[field] = 'This field is required'
        } else if (this.state.errors[field] !== '') {
          errors[field] = this.state.errors[field]
        }
      })
    if (this.state['email'] === '' && this.state['phoneNumber'] === '') {
      const requiredErr = 'Email or phone required'
      errors['email'] = requiredErr
      errors['phoneNumber'] = requiredErr
    }
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    })
    return Object.keys(errors).length === 0
  }

  render() {
    const { clicked, unsavedChanges, formError } = this.state
    const addPersonalReferenceTagClass = isCurrentRoute('addPersonalReference')
      ? 'personal-reference-added'
      : ''
    const {
      match: {
        params: { personalRefId },
      },
      apiError,
    } = this.props
    const isEditable = `${personalRefId ? 'Edit' : 'Add'}`
    const HeaderText = `${isEditable} Personal Reference`

    const relationshipOptionsTitles = mappedOptions.map(({ title }) => title)
    const isOtherRelationship = isRelationshipOther(
      getRelationshipOptionByOptionIndex(this.state.relationship)?.id,
    )

    return (
      <StandardScreen>
        <RentalReputationHeaderReminder
          property=""
          backUrl="/"
          isFromApplicationForm=""
          title="Personal Preference"
          thirdTierText={isEditable}
        />
        <SectionHeader text={HeaderText} hasSeperator={false} textClass="pl0" />
        <div className="">
          <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
            <div className="col-md-6 p0 width100">
              <Form.InputName
                label="First Name"
                value={this.state.firstName}
                error={this.state.errors.firstName}
                onChange={this.updateField('firstName')}
                id="firstName"
                showValidationStatus={this.state.showValidationStatus}
                inputClass="width100"
                componentClass="margin-profile-item-left"
              />
            </div>
            <div className="col-md-6 p0 width100">
              <Form.InputName
                label="Last Name"
                value={this.state.lastName}
                error={this.state.errors.lastName}
                onChange={this.updateField('lastName')}
                id="lastName"
                showValidationStatus={this.state.showValidationStatus}
                inputClass="width100"
                componentClass="margin-profile-item-right"
              />
            </div>
          </Form.ProfilePagesRowLayout>
          <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
            <div className="col-md-6 p0 width100">
              <Form.InputEmail
                label="Email"
                value={this.state.email}
                error={this.state.errors.email}
                onChange={this.updateField('email')}
                id="email"
                showValidationStatus={this.state.showValidationStatus}
                inputClass="width100"
                componentClass="margin-profile-item-left"
              />
            </div>
            <div className="col-md-6 p0 width100">
              <Form.InputPhoneNumber
                label="Mobile Number"
                value={this.state.phoneNumber}
                error={this.state.errors.phoneNumber}
                onChange={this.updateField('phoneNumber')}
                id="phoneNumber"
                showValidationStatus={this.state.showValidationStatus}
                containerClassName="width100"
                componentClass="margin-profile-item-right"
                numberType={NUMBER_TYPE_MOBILE}
                required
              />
            </div>
          </Form.ProfilePagesRowLayout>
          <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items justify-content-flex-start">
            <div className="col-md-6 p0">
              <Form.DropdownRelationship
                label="Relationship"
                value={this.state.relationship}
                error={this.state.errors.relationship}
                options={relationshipOptionsTitles}
                onChange={this.updateField('relationship')}
                id="relationship"
                inputClass="width100"
                componentClass="margin-profile-item-left"
              />
            </div>
          </Form.ProfilePagesRowLayout>
          {isOtherRelationship && (
            <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items justify-content-flex-start">
              <div className="col-md-6 p0">
                <Form.CommentArea
                  value={this.state.comment}
                  error={this.state.errors.comment}
                  placeholder="Insert text"
                  onChange={this.updateField('comment')}
                  id="comment"
                  rows={1}
                  isOptional={false}
                  inputClass="width100"
                  componentClass="margin-profile-item-left"
                />
              </div>
            </Form.ProfilePagesRowLayout>
          )}

          <ErrorMessage error={apiError || formError} />
          <Form.ProfilePagesBottomButtons
            clickNextFunction={this.submit}
            clickNextText="Save"
            clicked={clicked}
            buttonClass={addPersonalReferenceTagClass}
          />
        </div>
        <UnsavedMessage unsavedChanges={unsavedChanges} />
      </StandardScreen>
    )
  }
}

export default PersonalReferenceForm
