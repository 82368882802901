import { mapLegacyAccessPromiseToServiceFnPromise } from 'app/services/http/utils'
import { getPaymentAmount } from 'app/sm/background_check/background_check_access'
import {
  getTeamSettingInfo,
  putTeamViewingSettingInfo,
} from 'app/sm/rental_reputation/access'
import { api } from 'app/utils/api/helpers'

export const getTeamSettings = (teamId, queryParams) => {
  const { keys = [] } = queryParams
  const keysStr = keys.join(',')
  return mapLegacyAccessPromiseToServiceFnPromise(
    getTeamSettingInfo(teamId, keysStr),
  )
}

export const getPaymentForBGC = () => {
  return mapLegacyAccessPromiseToServiceFnPromise(getPaymentAmount())
}

export const updateTeamSettings = (
  teamId,
  viewingSettngs,
  applicationSettings,
  notificationSettings,
  feedSettings,
  integrationSettings,
) => {
  return mapLegacyAccessPromiseToServiceFnPromise(
    putTeamViewingSettingInfo(
      teamId,
      viewingSettngs,
      applicationSettings,
      notificationSettings,
      feedSettings,
      integrationSettings,
    ),
  )
}

export const updateViewingPreferences = (teamGUID, payload) => {
  return api.put(`/sm/teams/${teamGUID}/preferences/viewings`, payload)
}

export const getTeamBGCheckPrice = (teamGUID) => {
  return api.get(`/sm/team/${teamGUID}/backgroundcheck/price`)
}

export const getApplicationConfigForProperty = (teamGUID, applicationGUID) => {
  return api.get(
    `/sm/team/${teamGUID}/application/config?applicationGUID=${applicationGUID}`,
  )
}
