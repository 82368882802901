import React from 'react'

import qs from 'qs'
import { Link } from 'react-router-dom'

import { validateResetToken } from 'app/services/http/session'
import isEmptyObject from 'app/shared_components/check_empty_object'
import SnugLogo from 'app/shared_components/snug_logo'
import * as validations from 'app/shared_components/validations'
import { urlTo } from 'app/sm/helpers'

class NewPasswordForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      token: '',
      newPassword: '',
      errors: {},
      shownew: false,
      disabled: true,
      clicked: false,
      invalidResetToken: false,
    }
  }

  UNSAFE_componentWillMount() {
    let { clearError } = this.props
    clearError()
    this.validateToken()
  }

  componentDidMount() {
    this.getDetails()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ clicked: false })
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    nextState.disabled = !(
      nextState.email &&
      nextState.token &&
      nextState.newPassword &&
      isEmptyObject(nextState.errors) &&
      !nextState.clicked
    )
  }

  validateToken() {
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    if (curQueries.token) {
      validateResetToken(curQueries.token).catch(() => {
        this.setState({
          invalidResetToken: true,
        })
      })
    }
  }

  getDetails() {
    let queryToken = this.getParameter('token')
    let queryEmail = this.getParameter('email', true)

    this.state.token = queryToken
    this.state.email = queryEmail

    this.setState(this.state)
  }

  getParameter(field, ignorePlus = false) {
    let url = window.location.href
    field = field.replace(/[\[\]]/g, '\\$&')
    let regex = new RegExp('[?&]' + field + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    if (ignorePlus) {
      return decodeURIComponent(results[2])
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  setValidationErrors(field, error) {
    let errors = this.state.errors
    if (error.length === 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      document.getElementById(field).className = ''
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      document.getElementById(field).className = 'error'
      return false
    }
  }

  isEmptyObject(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false
      }
    }
    return true
  }

  submit = () => {
    this.setState({ clicked: true })
    let { newPassword } = this.props
    newPassword(this.state)
  }

  toggleShow = (field) => {
    return () => {
      field = 'show' + field
      let value = this.state[field]
      this.setState({ [field]: !value })
    }
  }

  update(field) {
    return (event) => this.setState({ [field]: event.target.value })
  }

  updatePassword(field) {
    return (event) => {
      this.setState({ [field]: event.target.value })
    }
  }

  validate(field) {
    return () => {
      let value = this.state[field]
      switch (field) {
        case 'newPassword':
          return this.setValidationErrors(
            field,
            validations.validateNewPassword(value),
          )
      }
    }
  }

  renderFormBody() {
    let { newPassword, errors, disabled, clicked, invalidResetToken } =
      this.state
    let { error } = this.props
    if (invalidResetToken) {
      return (
        <div className="alert alert-danger">
          This request is no longer valid
        </div>
      )
    }

    return (
      <>
        <h3 className="mb30">Choose a new Password</h3>
        <div className="panel-body">
          <div className={error ? 'alert alert-danger' : 'hidden'}>
            {error && error}
          </div>

          <div className="input-box">
            <input
              type={this.state.shownew ? 'text' : 'password'}
              required
              id="newPassword"
              value={newPassword}
              onChange={this.update('newPassword')}
              onBlur={this.validate('newPassword')}
            />
            <label>New Password</label>
            <span className="show-password" onClick={this.toggleShow('new')}>
              Show
            </span>
          </div>

          <div
            className={
              !isEmptyObject(errors) || error ? 'alert alert-danger' : ''
            }
          >
            {error} {errors.newPassword}
          </div>

          <button className="mt15" disabled={disabled} onClick={this.submit}>
            Set New Password{' '}
            <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
          </button>
        </div>
      </>
    )
  }

  render() {
    return (
      <div className="auth-wrapper">
        <div className="auth-screen panel panel-default">
          <div className="panel-heading">
            <div className="app-header">
              <div className="app-header_left"></div>
              <div className="app-header_center">
                <SnugLogo />
              </div>
              <div className="app-header_right">
                <Link to={urlTo('join')}>Log in</Link>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="panel panel-default">{this.renderFormBody()}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewPasswordForm
