import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import BlueSafetyMessage from 'app/shared_components/blue_safety_message'
import HeaderMessage from 'app/shared_components/rental_reputation_header_connection'
import { history } from 'app/shared_components/router'
import SnugTip from 'app/shared_components/snug_tips'
import { urlTo } from 'app/sm/helpers'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'

class ProfileCreditCardsForm extends React.Component {
  constructor(props) {
    super(props)
  }

  UNSAFE_componentWillMount() {
    let { fetchCards } = this.props
    fetchCards()
  }

  removeCard(cardId) {
    if (window.confirm('Are you sure you want to remove this card ?')) {
      this.props.removeCard(cardId)
    }
  }

  render() {
    let { paymentOptions, addCards, setDefaultCard, backUrl, property, error } =
      this.props
    const cards = paymentOptions ? paymentOptions.cards : []
    const { defaultCard } = paymentOptions ? paymentOptions : ''
    const isFromApplicationForm = backUrl.includes('sm/applications')
    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <RentalReputationHeaderReminder
            property={property}
            backUrl={backUrl}
            remindText={'Current application'}
            isFromApplicationForm={isFromApplicationForm}
            title="Credit Cards"
          />

          <HeaderMessage
            title="Credit Cards"
            message="You may add a credit card to pay for services such as a Background Check when building your Snug Profile. You will always be asked to authorise payment. Payment will be processed using the Primary card."
            showTopBack={true}
          />

          <div className="credit-cards-header mb20">
            <h4>Add credit cards</h4>

            <button className="btn btn-round" onClick={() => addCards()}>
              <i className="icon-add"></i>
            </button>
          </div>

          <BlueSafetyMessage message="Snug uses bank-level 256-bit encryption to protect your data." />

          <div className="mb50 mt50">
            {cards &&
              cards.map((card) => {
                return (
                  <div
                    className="bc-hub-item-link card-block"
                    key={card.guidID}
                  >
                    <div className="card-wrapper">
                      <div className="card-details">
                        <header>
                          <i className="fa fa-credit-card" />
                          <span>
                            {card.cardBrand ? card.cardBrand : 'Credit Card'}
                          </span>
                        </header>
                        <content>
                          Ending in {card.cardLast4}
                          <br />
                          Expiry: {card.cardExpMonth}/{card.cardExpYear}
                        </content>
                      </div>
                      <div className="card-actions position-relative">
                        <div className="table-cards float-right wa">
                          <div className="cell-t">
                            <button
                              className="btn btn-transparent p0 credit-cards-delete-button"
                              onClick={() => this.removeCard(card.guidID)}
                            >
                              <i className="icon-remove"></i>
                            </button>
                          </div>
                        </div>
                        {defaultCard !== card.guidID && (
                          <button
                            className="textButton primary-card"
                            onClick={() => setDefaultCard(card.guidID)}
                          >
                            Make primary
                          </button>
                        )}
                        {defaultCard === card.guidID && (
                          <div className="card-badge badge red primary-label">
                            Primary
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>

          <ReactTooltip className="tooltip" />

          <SnugTip tipContent="You can add or delete a credit card at any time." />

          <BottomButton
            backButtonClass="tablet-only"
            clickNextFunction={() =>
              history.push(
                `${urlTo('backgroundCheckPayment')}${window.location.search}`,
              )
            }
            btnText="Save"
          />

          <div className="row">
            <div className="col-sm-12">
              <div className={error ? 'alert alert-danger' : ''}>
                <div>{error}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ProfileCreditCardsForm
