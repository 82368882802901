import React from 'react'

import PropertyImageAndHeader from 'app/application_revamp/components/Toolbar/components/PropertyImageAndHeader'
import {
  StyledApplicantListItem,
  StyledApplicationToolbar,
  StyledButton,
} from 'app/application_revamp/components/Toolbar/styles'
import { Box, Flex, Spinner } from 'app/components/design-system-components'
import {
  ListAltRounded,
  MailOutlineRounded,
} from 'app/components/design-system-components/icons/communications'
import {
  ArrowBackRounded,
  ArrowForwardRounded,
} from 'app/components/design-system-components/icons/navigation'
import {
  StarOutlineRounded,
  StarRounded,
} from 'app/components/design-system-components/icons/toggle'
import { Content } from 'app/components/display/three_column_layout'
import { StyledEllipse } from 'app/components/property/PropertySummary'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { history } from 'app/shared_components/router'
import { NO_IMAGE_PLACEHOLDER_URL, urlTo } from 'app/sm/helpers'
import {
  ApplicationMoveToButton,
  ApplicationWorkflowContextMenu,
  ApplicationWorkflowStatusLabel,
} from 'app/sm/properties/components/property_search_filter_util'
import { getDateWithoutYear } from 'app/utils/datetime/helpers'

function ApplicationToolbar({
  property = {},
  activeOffer = {},
  onClickMoveApplication,
  onClickWithdrawOffer,
  onClickDecline,
  currentWorkflowStatus,
  onClickScheduleViewing,
  applicationSettings,
  onClickShortlistWithEmail,
  appsDetails = {},
  onClickShare,
  compactWorkflow,
  onClickItem,
  showMoveToText,
  isShortListed = false,
  onAddShortListedApplication,
  onRemoveShortListedApplication,
  renderAssignTeamMember,
  teamSlug,
  onClickSave,
  appsDetailsLoading,
  disableShortlistButton,
}) {
  const getApplicantDetails = (
    weeklyRent,
    leaseStartDate,
    adults,
    children,
    pets,
    rentFontWeight = 'inherit',
  ) => {
    return (
      <Flex alignItems="center">
        <Box fontWeight={rentFontWeight} mr={1}>
          {`$${weeklyRent} p/w`}
        </Box>{' '}
        <StyledEllipse mx={2} /> {getDateWithoutYear(leaseStartDate)}
        <StyledEllipse mx={2} />
        {`${adults} adults ${children} children ${pets} pets`}
      </Flex>
    )
  }

  const {
    images = [],
    address = {},
    guidID: propertyGUID = '',
    slug = '',
  } = property || {}
  const { friendlyName = '', suburb = '' } = address || {}
  const mainImage = images?.find((image) => image.isMain === true)
  const propertyImage = mainImage ? mainImage.URL : NO_IMAGE_PLACEHOLDER_URL
  const propertyText = `${friendlyName}, ${suburb}`

  const {
    otherAppDetails = [],
    position = 0,
    totalApps = 0,
    currentAppDetails = {},
  } = appsDetails || {}
  const { application = {}, childNum = 0 } = currentAppDetails || {}
  const {
    adults = 0,
    pets = 0,
    weeklyRent = 0,
    leaseStartDate = '',
  } = application
  let indexOfNextApplication = 0
  if (otherAppDetails && otherAppDetails.length > 0) {
    if (position < totalApps) {
      indexOfNextApplication = position - 1
    }
  }
  const nextApplication = otherAppDetails[indexOfNextApplication]
  const primaryApplicantNextApplication = nextApplication?.applicants?.find(
    (applicant) => applicant.isPrimary,
  )
  const propertyHasMoreApplications = totalApps > 1

  const onClickShortlistButton = () => {
    !isShortListed
      ? onAddShortListedApplication()
      : onRemoveShortListedApplication()
  }

  const onClickNextButton = () => {
    history.push(
      urlTo('renterApplication', {
        propertyId: propertyGUID,
        applicationId: nextApplication?.applicationGUID,
        applicantId: primaryApplicantNextApplication?.guidID,
      }),
    )
  }

  const onClickAllApplicationsButton = () => {
    history.push(
      `${urlTo('prospectSummary', {
        teamSlug,
      })}?property=${propertyGUID}&stage=Application`,
    )
  }

  const shortListedColor = !application?.shortlistingEmailSent
    ? theme.colors.skyBlue
    : theme.colors.yellow

  let allAppDetails = [...otherAppDetails]
  allAppDetails.splice(position - 1, 0, currentAppDetails)

  return (
    <>
      <StyledApplicationToolbar>
        <Flex style={{ width: '1440px', margin: '0 auto' }}>
          <Flex width="368px" alignItems="center">
            <PropertyImageAndHeader
              propertyImage={propertyImage}
              propertyText={propertyText}
              slug={slug}
              property={property}
              activeOffer={activeOffer}
            />
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="center"
            style={{ width: '700px' }}
          >
            <Content width="100%">
              {!appsDetailsLoading ? (
                <>
                  <Flex alignItems="center">
                    <StyledButton variant="flatWithBorder">
                      <div className={`dropdown actions`}>
                        <div className="dropdown-toggle" data-toggle="dropdown">
                          <Flex alignItems="center" justifyContent="center">
                            <ListAltRounded />{' '}
                            <Box ml={2}>
                              {position} of {totalApps}
                            </Box>
                          </Flex>
                        </div>
                        <ul className="dropdown-menu mt10 ml-15">
                          <StyledApplicantListItem>
                            <StyledButton
                              variant="flatWithBorder"
                              onClick={() => onClickAllApplicationsButton()}
                            >
                              <Flex alignItems="center">
                                <ArrowBackRounded />{' '}
                                <Box ml={2}>All Applications</Box>
                              </Flex>
                            </StyledButton>
                          </StyledApplicantListItem>
                          {allAppDetails &&
                            allAppDetails.map((appDetails) => {
                              const {
                                applicants = [],
                                application = {},
                                childNum = 0,
                              } = appDetails
                              const {
                                adults = 0,
                                pets = 0,
                                weeklyRent = 0,
                                leaseStartDate = '',
                              } = application || {}

                              const primaryApplicant = applicants?.find(
                                (applicant) => applicant.isPrimary,
                              )
                              const firstLine = `${
                                primaryApplicant?.firstName
                              } ${primaryApplicant?.lastName} ${
                                adults > 1 ? ` + ${adults - 1}` : ''
                              }`
                              const secondLine = getApplicantDetails(
                                weeklyRent,
                                leaseStartDate,
                                adults,
                                childNum,
                                pets,
                                theme.fontWeights[5],
                              )
                              const onClickListItem = () => {
                                history.push(
                                  urlTo('renterApplication', {
                                    propertyId: propertyGUID,
                                    applicationId: appDetails.applicationGUID,
                                    applicantId: primaryApplicant.guidID,
                                  }),
                                )
                              }
                              return (
                                <StyledApplicantListItem
                                  key={appDetails.applicationGUID}
                                  onClick={() => onClickListItem()}
                                >
                                  <Box color={theme.colors.black}>
                                    {firstLine}
                                  </Box>
                                  <Box className="second-line">
                                    {secondLine}
                                  </Box>
                                </StyledApplicantListItem>
                              )
                            })}
                        </ul>
                      </div>
                    </StyledButton>

                    <StyledButton
                      variant="flatWithBorder"
                      onClick={() => onClickNextButton()}
                      disabled={!propertyHasMoreApplications}
                    >
                      <Flex alignItems="center">
                        <ArrowForwardRounded /> <Box ml={2}>Next</Box>
                      </Flex>
                    </StyledButton>
                    <StyledButton
                      variant="flatWithBorder"
                      onClick={() => onClickShortlistButton()}
                      disabled={disableShortlistButton}
                      width={`${theme.baseSpace * 15}px`}
                    >
                      {disableShortlistButton ? (
                        <Flex alignItems="center" justifyContent="center">
                          <Spinner />
                        </Flex>
                      ) : (
                        <Flex alignItems="center">
                          {isShortListed && (
                            <StarRounded color={shortListedColor} />
                          )}
                          {!isShortListed && <StarOutlineRounded />}
                          <Box ml={2}>Shortlist</Box>
                        </Flex>
                      )}
                    </StyledButton>
                    <StyledButton
                      variant="flatWithBorder"
                      onClick={() => onClickShare()}
                    >
                      <Flex alignItems="center">
                        <MailOutlineRounded />
                        <Box ml={2}>Share</Box>
                      </Flex>
                    </StyledButton>
                    <ApplicationMoveToButton
                      currentWorkflowStatus={currentWorkflowStatus}
                      showNewButtonStyle={true}
                      compactWorkflow={compactWorkflow}
                      onClickItem={onClickItem}
                      showMoveToText={showMoveToText}
                      onClickSave={onClickSave}
                    />
                    <ApplicationWorkflowContextMenu
                      onClickMoveApplication={onClickMoveApplication}
                      onClickWithdrawOffer={onClickWithdrawOffer}
                      onClickDecline={onClickDecline}
                      currentWorkflowStatus={currentWorkflowStatus}
                      containerClass="mr10"
                      onClickScheduleViewing={onClickScheduleViewing}
                      applicationSettings={applicationSettings}
                      onClickShortlistWithEmail={onClickShortlistWithEmail}
                      isDropUp={false}
                    />
                  </Flex>
                  <Flex
                    alignItems="center"
                    mt={3}
                    fontWeight={theme.fontWeights.h2}
                    color={theme.colors.gray700}
                  >
                    {getApplicantDetails(
                      weeklyRent,
                      leaseStartDate,
                      adults,
                      childNum,
                      pets,
                    )}
                  </Flex>
                </>
              ) : (
                <Flex justifyContent="center">
                  <Spinner
                    height={theme.height[7] + 'px'}
                    width={theme.width[7] + 'px'}
                  />
                </Flex>
              )}
            </Content>
          </Flex>

          <Flex style={{ width: '368px' }} alignItems="center">
            <Box width={`${theme.width[9]}px`}>
              <ApplicationWorkflowStatusLabel
                applicationStatus={currentWorkflowStatus}
              />
            </Box>
            {renderAssignTeamMember()}
          </Flex>
        </Flex>
      </StyledApplicationToolbar>
    </>
  )
}

export default ApplicationToolbar
