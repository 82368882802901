import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'
import { pageLimit } from 'app/sm/helpers'

export const getProperties = (
  agencyID,
  archived = false,
  cursor = '',
  status = '',
  address = '',
  limit = pageLimit,
  managerID = '',
  hasApplicant = 'any',
) => {
  const propertyStatus = archived ? '' : status
  const propertiesUrl = `properties?archived=${archived}&cursor=${cursor}&limit=${limit}&status=${propertyStatus}&address=${address}&manager=${managerID}&applicants=${hasApplicant}`

  const url = agencyID
    ? `${apiBaseUrl}sm/agencies/${agencyID}/${propertiesUrl}`
    : `${apiBaseUrl}sm/${propertiesUrl}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (properties) => ({ ok: true, properties }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getPublicProperties = (agencySlug) => {
  const url = `${apiBaseUrl}apply/agencies/${agencySlug}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (properties) => ({ ok: true, properties }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getAgencyPublicProperties = (
  agencySlug,
  address = '',
  sort = '',
  sort_dir = '',
  bedroom = '',
  cursor = '',
  limit = 24,
  buildingSlug = '',
) => {
  if (bedroom === 'Any') {
    bedroom = 0
  }
  const url = `${apiBaseUrl}teams/${agencySlug}/apply?limit=${limit}&sort=${sort}&sort_dir=${sort_dir}&bedroom=${bedroom}&address=${address}&cursor=${cursor}&buildingSlug=${buildingSlug}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({ statusCode: res.status, responseText: res.text() })
    })
    .then((properties) => {
      return { ok: true, properties }
    })
    .catch((error) => {
      return {
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }
    })
}

export const withdrawAd = (adId) => {
  const url = `${apiBaseUrl}sm/ads/${adId}/withdraw`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const addArchiveProperty = (
  id,
  cancelFutureViewings,
  declineUnsuccessfulApplications,
) => {
  const payload = {
    cancelFutureViewings,
    declineUnsuccessfulApplications,
  }
  const url = `${apiBaseUrl}sm/properties/${id}/archive`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const restoreArchiveProperty = (id) => {
  const url = `${apiBaseUrl}sm/properties/${id}/archive`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
