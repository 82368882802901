import {
  NetworkConnectionError,
  RequestedPropertyNotExist,
} from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { history } from 'app/shared_components/router'
import { removeGuidFromArray, urlTo } from 'app/sm/helpers'
import {
  getAgencyRules,
  getPropertySummary,
  postForViewing,
  registerForViewing,
  removeFavorite,
  saveFavorite,
  unregisterForViewing,
} from 'app/sm/property_details/property_details_access'
import store from 'app/store'

export const RECEIVE_PROPERTY = 'RP_RECEIVE_PROPERTY'
export const RECEIVE_RESPONSE_TEXT = 'RP_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'RP_RECEIVE_ERROR'
export const TRIGGER_MODAL = 'RP_TRIGGER_MODAL'
export const CANCEL_MODAL = 'RP_CANCEL_MODAL'
export const TOGGLE_FAVORITE_MARK = 'RP_TOGGLE_FAVORITE_MARK'
export const RECEIVE_PROPERTY_SUMMARY = 'RP_RECEIVE_PROPERTY_SUMMARY'
export const RECEIVE_VIEWINGS = 'RP_RECEIVE_VIEWINGS'
export const CLEAR_ERROR = 'RP_CLEAR_ERROR'
export const SHOW_RSVP_SPINNER = 'RP_SHOW_RSVP_SPINNER'
export const REVEIVE_POTENTIAL_VIEWINGS = 'REVEIVE_POTENTIAL_VIEWINGS'

export const showRsvpSpinner = (rsvpSpinnerGuidArray) => ({
  type: SHOW_RSVP_SPINNER,
  rsvpSpinnerGuidArray,
})
// Intercepted by Middleware
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})

export const receiveViewings = (viewings) => ({
  type: RECEIVE_VIEWINGS,
  viewings,
})

export const receivePotentialViewings = (potentialViewings) => ({
  type: REVEIVE_POTENTIAL_VIEWINGS,
  potentialViewings: potentialViewings,
})

export const toggleFavoriteMark = () => ({ type: TOGGLE_FAVORITE_MARK })

// Emitted by Middleware for Reducer
export const receiveError = (error) => ({
  type: RECEIVE_ERROR,
  error,
})

export const clearError = () => ({ type: CLEAR_ERROR })
export const triggerModal = () => ({ type: TRIGGER_MODAL })
export const cancelModal = () => ({ type: CANCEL_MODAL })

// Action Creators

const receiveProperty = (property) => ({ type: RECEIVE_PROPERTY, property })
const receivePropertySummary = (summary) => ({
  type: RECEIVE_PROPERTY_SUMMARY,
  summary,
})

// Methods
export function fetchProperty(id, { viewingID, ...queryParams } = {}) {
  return (dispatch, getState) => {
    return getPropertySummary(id, queryParams).then(
      ({ ok, summary, responseText, statusCode }) => {
        const { rsvpSpinnerGuidArray } = getState().propertyDetails
        const newArray = viewingID
          ? removeGuidFromArray(rsvpSpinnerGuidArray, viewingID)
          : []
        if (ok) {
          if (
            summary.cannotBeViewed &&
            summary.property &&
            summary.property.teamSlug
          ) {
            history.replace({
              pathname: urlTo('propertyNotAvailableWithTeamsSlug', {
                teamSlug: summary.property.teamSlug,
              }),
            })
            return
          } else {
            dispatch(showRsvpSpinner(newArray))
            const { property, potentialViewings = [] } = summary
            dispatch(receiveProperty(property))
            dispatch(receivePropertySummary(summary))
            dispatch(receiveViewings(summary.viewings))
            dispatch(receivePotentialViewings(potentialViewings))
            return Promise.resolve()
          }
        } else {
          dispatch(showRsvpSpinner([]))
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((t) => {
            if (parseInt(t) === RequestedPropertyNotExist) {
              return Promise.reject(t)
            }
            if (statusCode === 404) {
              history.replace({
                pathname: urlTo('propertyNotAvailable'),
                state: { statusCode: statusCode },
              })
              return
            }
            store.dispatch(receiveResponseText(t))
            return Promise.reject(t)
          })
        }
      },
    )
  }
}

export const requestForViewing = (payload) => {
  return () => {
    return postForViewing(payload).then(({ ok, responseText, statusCode }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText.then((error) => {
          return Promise.reject(translateErrorCodeToMessage(error))
        })
      }
    })
  }
}

export function addToSavedProperty(propertyId) {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    const isFavourite = getState().propertyDetails.property.isFavourite

    if (!isFavourite) {
      saveFavorite(propertyId).then(({ ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(toggleFavoriteMark())
        }
      })
    } else {
      removeFavorite(propertyId).then(({ ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(toggleFavoriteMark())
        }
      })
    }
  }
}

export function registerViewing(id) {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    const {
      property: { guidID },
      rsvpSpinnerGuidArray,
    } = getState().propertyDetails
    let newArray = Array.from(rsvpSpinnerGuidArray)
    id && newArray.push(id)

    dispatch(showRsvpSpinner(newArray))
    return registerForViewing(id).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(fetchProperty(guidID, { viewingID: id }))
        return Promise.resolve()
      } else {
        dispatch(showRsvpSpinner([]))
        return responseText.then((error) => {
          store.dispatch(receiveResponseText(error))
          return Promise.reject(error)
        })
      }
    })
  }
}

export function unregisterViewing(id) {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    const {
      property: { guidID },
      rsvpSpinnerGuidArray,
    } = getState().propertyDetails
    let newArray = Array.from(rsvpSpinnerGuidArray)
    id && newArray.push(id)

    dispatch(showRsvpSpinner(newArray))

    return unregisterForViewing(id).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(fetchProperty(guidID, { viewingID: id }))
        return Promise.resolve()
      } else {
        dispatch(showRsvpSpinner([]))
        responseText.then((t) => {
          store.dispatch(receiveResponseText(t))
          return Promise.reject(t)
        })
      }
    })
  }
}

export const checkForAgencyRules = (agencyGUID) => {
  return (dispatch) => {
    dispatch(clearError())
    return getAgencyRules(agencyGUID).then(({ ok, result, responseText }) => {
      if (ok) {
        return Promise.resolve(result)
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        responseText.then((t) =>
          dispatch(receiveResponseText(translateErrorCodeToMessage(t))),
        )
      }
    })
  }
}
