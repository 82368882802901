import React from 'react'

import { Link } from 'react-router-dom'

import AgencyDashboardHeader from 'app/bond_cover/agency/dashboard/agency_dashboard_header'
import ApplicationItem from 'app/bond_cover/agency/dashboard/application_item'
import Spinner from 'app/sm/common/spinner'

const AgencyDashboard = ({
  data,
  action,
  openModal,
  spinner,
  responseText,
}) => (
  <div className="sm-ra-card">
    <AgencyDashboardHeader />
    {spinner && <Spinner />}
    <ul className="sm-ra-card__list">
      {data.agencyRequests && !responseText ? (
        data.agencyRequests.map((application, i) => {
          return (
            <ApplicationItem
              application={application}
              key={i}
              action={action}
              openModal={(key) => openModal(key)}
            />
          )
        })
      ) : (
        <li> No results found.</li>
      )}
    </ul>
  </div>
)

export default AgencyDashboard
