import {
  createContact,
  getContact,
  getContacts,
  putContact,
  searchContacts,
} from 'app/sm/team_contact/access'

export const addContact = (teamId, formData) => {
  return (dispatch) => {
    return createContact(teamId, formData).then(({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText.then((error) => Promise.reject(error))
      }
    })
  }
}

export const updateContact = (teamId, contactGUID, formData) => {
  return (dispatch) => {
    return putContact(teamId, contactGUID, formData).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export const fetchContacts = (teamId) => (dispatch) => {
  return getContacts(teamId).then(({ ok, contactInfo, responseText }) => {
    if (ok) {
      return Promise.resolve({ contactInfo })
    } else {
      return responseText.then((t) => {
        return Promise.reject(t)
      })
    }
  })
}

export const fetchSearchContacts =
  (teamId, searchStr, from = 0, size = 20) =>
  (dispatch) => {
    return searchContacts(teamId, searchStr, from, size).then(
      ({ ok, contactInfo, responseText }) => {
        if (ok) {
          return Promise.resolve({ contactInfo })
        } else {
          return responseText.then((t) => {
            return Promise.reject(t)
          })
        }
      },
    )
  }

export const fetchContact = (teamId, contactGUID) => (dispatch) => {
  return getContact(teamId, contactGUID).then(
    ({ ok, contactInfo, responseText }) => {
      if (ok) {
        return Promise.resolve({ contactInfo })
      } else {
        return responseText.then((t) => {
          return Promise.reject(t)
        })
      }
    },
  )
}
