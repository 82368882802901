import React from 'react'

import styled from 'styled-components'

import { Box, Modal } from 'app/components/design-system-components'

const StyledLi = styled.li`
  display: list-item !important;
  margin-left: 1em;
`

function RequestDocsModal({
  toggleRequestDocsModalOpen,
  requestDocsModalPrimaryAction,
}) {
  const modalBody = (
    <Box mt={6} mb={3}>
      <Box>
        The following details need updating before a TICA report can be
        requested:
      </Box>
      <ul my={5}>
        <StyledLi>Passport number</StyledLi>
        <StyledLi>Driver Licence number</StyledLi>
        <StyledLi>Date of Birth</StyledLi>
      </ul>
      <Box>
        Please click "Request Info" to request current ID documentation and
        re-run the TICA check once complete.
      </Box>
    </Box>
  )
  return (
    <Modal
      modalBody={modalBody}
      modalHeading="Update required TICA details"
      primaryLabel="Request Info"
      secondaryLabel="Cancel"
      toggleModal={toggleRequestDocsModalOpen}
      secondaryAction={toggleRequestDocsModalOpen}
      primaryAction={() => requestDocsModalPrimaryAction('Identity Documents')}
    />
  )
}

export default RequestDocsModal
