import React from 'react'

import { Alert } from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { PageSection } from 'app/components/design-system-components/page/Section'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

export const UnfollowMemberModal = ({
  teamMember,
  onUnfollow,
  onCloseModal,
}) => {
  const { loadingStates, promiseWrapper } = useLoadingStates()

  const onUnfollowClicked = () => {
    return promiseWrapper(onUnfollow(), true).then(() =>
      onCloseModal(teamMember),
    )
  }

  const error = loadingStates?.error
  const isLoading = loadingStates.state === loadingStatesIds.LOADING

  const { first_name, last_name } = teamMember

  return (
    <GenericModal
      title="Stop following?"
      onDismiss={() => onCloseModal()}
      modalFooter={
        <GenericModalFooter
          primaryBtnConfig={{
            title: 'Cancel Follow',
            onClick: () => onUnfollowClicked(),
            props: {
              loading: isLoading,
              disabled: isLoading,
            },
          }}
          secondaryBtnConfig={{
            title: 'Cancel',
            onClick: () => onCloseModal(),
          }}
        />
      }
    >
      {!!error && (
        <Alert variant="warningWithBg" mb={theme.space[3] + 'px'}>
          {error}
        </Alert>
      )}
      <PageSection>
        Are you sure you would like to stop following {first_name} {last_name}?
      </PageSection>
    </GenericModal>
  )
}
