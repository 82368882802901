import React from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'

import Card from 'app/match/applicationReportPDF/components/Card'
import CustomCheckbox from 'app/match/applicationReportPDF/components/CustomCheckbox'
import PDFBullet from 'app/match/applicationReportPDF/components/PDFBullet'
import * as helpers from 'app/sm/helpers'
import { checkIfBGCheckNotSupportedInState } from 'app/utils/address/helpers'
import * as applicationHelpers from 'app/utils/applications/helpers'

const propTypes = {
  data: PropTypes.object,
}

function BackgroundCheck({
  backgroundCheck,
  optOutBackgroundCheck,
  applicationPropertyState,
}) {
  const {
    applicationGUID = '',
    asicCompanyCheck: { result: asicCompanyCheckResult } = {},
    banckruptcyCheck: { result: banckruptcyCheckResult } = {},
    identityVerification: { result: identityVerificationResult } = {},
    tenanacyBlacklistCheck: { result: tenanacyBlacklistCheckResult } = {},
    status = 'Pending',
    updatedAt = '',
    attachedToProfile,
  } = backgroundCheck || {}
  let showBackgroundCheckReport =
    applicationHelpers.shouldShowBackgroundCheckReport(
      status,
      attachedToProfile,
      optOutBackgroundCheck,
      applicationGUID,
    )

  const bgCheckCompletedByManager =
    applicationHelpers.isBGCheckCompletedByManager(
      status,
      attachedToProfile,
      optOutBackgroundCheck,
      applicationGUID,
    )

  if (
    checkIfBGCheckNotSupportedInState(applicationPropertyState) &&
    !bgCheckCompletedByManager
  ) {
    showBackgroundCheckReport = false
  }

  return (
    <div className="background-check">
      <Card
        type={'icon'}
        icon={'icon-security-guard'}
        title={'Background Check'}
        addClass={'card-bottom-bordered'}
        encryption={true}
      />
      {!showBackgroundCheckReport && <p>No information is provided</p>}
      {showBackgroundCheckReport && (
        <div>
          <ul className="info-list bottom-border">
            <li className="info-list__item">
              <span className="info-list__item--title">
                Identity Verification
              </span>
              <span className="info-list__item--value">
                <CustomCheckbox
                  type={identityVerificationResult ? 'check' : 'info'}
                  isFixedWidth={true}
                  label={identityVerificationResult ? 'Verified' : 'Not found'}
                />
              </span>
            </li>
            <li className="info-list__item">
              <span className="info-list__item--title">
                National Tenancy Database
              </span>
              <span className="info-list__item--value">
                <CustomCheckbox
                  type={tenanacyBlacklistCheckResult ? 'check' : 'info'}
                  isFixedWidth={true}
                  label={
                    tenanacyBlacklistCheckResult ? 'Verified' : 'Not found'
                  }
                />
              </span>
            </li>
            <li className="info-list__item">
              <span className="info-list__item--title">
                Bankruptcy / Court Records
              </span>
              <span className="info-list__item--value">
                <CustomCheckbox
                  type={banckruptcyCheckResult ? 'check' : 'info'}
                  isFixedWidth={true}
                  label={banckruptcyCheckResult ? 'Verified' : 'Not found'}
                />
              </span>
            </li>
            <li className="info-list__item">
              <span className="info-list__item--title">ASIC Directorships</span>
              <span className="info-list__item--value">
                <CustomCheckbox
                  type={asicCompanyCheckResult ? 'check' : 'info'}
                  isFixedWidth={true}
                  label={asicCompanyCheckResult ? 'Verified' : 'Not found'}
                />
              </span>
            </li>
          </ul>
          <div className="pdf-flex-row space-between">
            <small>
              Powered by Equifax on these terms: &nbsp;
              <a href="/" className="text-underline">
                tenancydatabase.com.au/terms
              </a>
              .
            </small>
          </div>
          <PDFBullet
            label={helpers.capitalizeFirstLetter(status)}
            value={moment(updatedAt).format('HH:MM DD MMM YYYY')}
          />
        </div>
      )}
    </div>
  )
}

BackgroundCheck.propTypes = propTypes

export default BackgroundCheck
