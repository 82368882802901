import React from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'

import RentalForm from 'app/bond_cover/renters/request/rental_form'
import isEmptyObject from 'app/shared_components/check_empty_object'
import DatePicker from 'app/shared_components/date_picker'
import getParameter from 'app/shared_components/parameter_parser'
import * as validations from 'app/shared_components/validations'

const MINIMUM_BOND_AMOUNT = 100
const MAXIMUM_BOND_AMOUNT = 10000

const guid = () => {
  return getParameter('id')
}

const leaseLength = (startDate, endDate) => {
  if (startDate && endDate) {
    const start = moment(startDate).startOf('day')
    const end = moment(endDate).startOf('day')
    const duration = moment.duration(end.diff(start))
    const years = duration.years()
    const months = duration.months()
    const days = duration.days()
    return (
      <div className="lease-length">
        <span>Lease length : </span>
        {years} years {months} months {days} days
      </div>
    )
  }
  return null
}

class RentalFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      requestID: null,
      type: false,
      isNewLease: false,
      rate: null,
      bond: null,
      isRollingLease: false,
      startDate: null,
      endDate: null,
      disabled: true,
      clicked: false,
      bedrooms: '',
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        address2: '',
        suburb: '',
        state: '',
        postcode: '',
        country: 'Australia',
        lat: null,
        lng: null,
        friendlyName: '',
        googleId: '',
      },
      suggest: true,
      propertyType: '',
      isMultiTenantLease: false,
      hasUnpaidBondClaim: false,
      unsavedChanges: false,
      errors: {},
    }
  }

  UNSAFE_componentWillMount() {
    const { fetchCurrentLease } = this.props
    const requestID = getParameter('requestid')
    if (guid()) {
      fetchCurrentLease(requestID, guid())
    }
    const type = getParameter('type')
    const isNewLease = type === 'new'
    this.setState({ isNewLease })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentLease, apiError } = nextProps
    const errors = {}

    this.setState({ apiError, errors, disabled: false })

    if (currentLease && currentLease.guidID) {
      this.setState({
        requestID: currentLease.requestID,
        isNewLease: currentLease.isNewLease,
        bedrooms: currentLease.bedrooms,
        address: currentLease.address,
        rate: currentLease.rate,
        bond: currentLease.bond,
        isRollingLease: currentLease.isNewLease
          ? false
          : currentLease.isRollingLease,
        startDate: currentLease.startDate,
        endDate: currentLease.endDate,
        propertyType: currentLease.propertyType,
        isGovernmentSponsored: currentLease.isGovernmentSponsored,
        isMultiTenantLease: currentLease.isMultiTenantLease,
        isFirstTimeRenter: currentLease.isFirstTimeRenter,
        isForeignResident: currentLease.isForeignResident,
        hasUnpaidBondClaim: currentLease.hasUnpaidBondClaim,
      })
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.isNewLease) {
      nextState.disabled = !(
        nextState.bedrooms &&
        nextState.propertyType &&
        nextState.rate &&
        nextState.bond &&
        nextState.startDate &&
        nextState.endDate &&
        isEmptyObject(nextState.errors) &&
        !nextState.clicked
      )
    } else {
      nextState.disabled = !(
        nextState.bedrooms &&
        nextState.propertyType &&
        nextState.rate &&
        nextState.bond &&
        nextState.startDate &&
        (nextState.endDate || nextState.isRollingLease) &&
        isEmptyObject(nextState.errors) &&
        !nextState.clicked
      )
    }
  }

  setValidationErrors(field, error) {
    const { errors } = this.state
    if (error.length === 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    }
    // Validation errors
    errors[field] = error
    this.setState({ errors })
    return false
  }

  endDateInput = (endDate) => {
    return (
      <DatePicker
        label="End Date"
        viewMode="months"
        value={endDate ? moment(endDate).format('DD/MM/YYYY') : null}
        onChange={this.updateDate('endDate')}
        isOutsideRange={(day) => day.isAfter(moment())}
      />
    )
  }

  resetAddress = () => {
    return () => {
      this.setState({
        address: {
          unitNumber: '',
          streetNumber: '',
          streetName: '',
          address2: '',
          suburb: '',
          state: '',
          postcode: '',
          country: 'Australia',
          lat: null,
          lng: null,
          friendlyName: '',
          googleId: '',
        },
      })
    }
  }

  submit = () => {
    const { currentLease, addCurrentLease, updateCurrentLease } = this.props
    const { requestID } = this.state
    this.setState({ unsavedChanges: false })
    if (this.validateAll()) {
      if (currentLease && currentLease.address) {
        updateCurrentLease(requestID, this.state, currentLease.guidID)
      } else {
        addCurrentLease(this.state)
      }
    }
  }

  toggleCheckbox = (field) => {
    return (event) => {
      const { checked } = event.target
      this.setState({ [field]: checked, unsavedChanges: true })
    }
  }

  toggleRollingLease = () => {
    return (event) => {
      const { checked } = event.target
      if (checked) {
        this.setValidationErrors('endDate', [])
      }
      const endDate = null
      this.setState(
        {
          isRollingLease: checked,
          endDate,
        },
        this.validateEndDate,
      )
    }
  }

  toggleSuggest = () => {
    this.setState({ suggest: !this.state.suggest })
  }

  trimAddressField = (field) => {
    return () => {
      const { address } = this.state
      const value = address[field]
      address[field] = value.trim()
      this.validate(field, value)()
      this.setState({ address })
    }
  }

  trimField = (field) => {
    return () => {
      const value = this.state[field].trim()
      this.validate(field, value)()
      this.setState({ [field]: value })
    }
  }

  update = (field) => {
    return (event) => {
      let { value } = event.target
      this.setState({ [field]: value, unsavedChanges: true })
    }
  }

  updateAddress = (field) => {
    return (event) => {
      const { address } = this.state
      address[field] = event.target.value
      this.setState({ address, unsavedChanges: true })
    }
  }

  updateAddressNumber = (field) => {
    return (event) => {
      const { address } = this.state
      const { value } = event.target
      address[field] = parseInt(value, 10)
      this.setState({ address, unsavedChanges: true })
    }
  }

  updateCurrency = (field) => {
    return (event) => {
      let { value } = event.target
      let { isFloat } = false
      if (value.endsWith('.')) {
        value = value.slice(0, -1)
        isFloat = true
      }
      this.setState({ [field]: parseFloat(parseFloat(value).toFixed(2)) })
      if (isFloat) {
        this.setState({ [field]: value + '.', unsavedChanges: true })
      }
    }
  }

  updateDate = (field) => {
    return (date) => {
      const value = moment(date).format('YYYY-MM-DD')
      this.setState(
        { [field]: value, unsavedChanges: true },
        this.validate(field, value),
      )
    }
  }

  updateGoogleAddress = (address, errors) => {
    if (errors) {
      this.setState({ errors })
    } else {
      this.setState(
        { address, unsavedChanges: true },
        this.validateGoogleAddress,
      )
    }
  }

  updateNumber = (field) => {
    return (event) => {
      let { value } = event.target
      value = parseInt(value, 10)
      this.setState({ [field]: value, unsavedChanges: true })
    }
  }

  validate = (field, value) => {
    return () => {
      const { isNewLease, address, bedrooms } = this.state
      const { streetNumber, streetName, suburb, postcode, state } = address
      switch (field) {
        case 'streetNumber':
          return this.setValidationErrors(
            field,
            validations.validateStreetNumber(streetNumber, 'Street Number'),
          )
        case 'streetName':
          return this.setValidationErrors(
            field,
            validations.validateAddressName(streetName, 'Street Name'),
          )
        case 'suburb':
          return this.setValidationErrors(
            field,
            validations.validateSuburb(suburb, 'Suburb'),
          )
        case 'postcode':
          return this.setValidationErrors(
            field,
            validations.validatePostcode(postcode, 'Postcode'),
          )
        case 'state':
          return this.setValidationErrors(
            field,
            validations.validateStateName(state, 'State'),
          )
        case 'bedrooms':
          return this.setValidationErrors(
            field,
            validations.validateNumber(
              bedrooms,
              'Number of bedrooms is required',
            ),
          )
        case 'propertyType':
          return this.setValidationErrors(
            field,
            validations.validateNumber(
              this.state[field],
              'Property type is required',
            ),
          )
        case 'rate':
          return this.setValidationErrors(
            field,
            validations.validateCurrency(this.state[field], 'Rent is required'),
          )
        case 'bond':
          return this.setValidationErrors(
            field,
            validations.validateBond(
              MINIMUM_BOND_AMOUNT,
              this.state[field],
              MAXIMUM_BOND_AMOUNT,
              'Bond is required',
              'Bond is a minimum of $100',
              'Bond is a maximum of $10,000',
            ),
          )
        case 'startDate':
          this.validateEndDate()
          if (!isNewLease) {
            return this.setValidationErrors(
              field,
              validations.compareDates(
                value,
                moment().format('YYYY-MM-DD'),
                'Start Date is required',
                'Lease Start must be in the past',
              ),
            )
          }
          return this.setValidationErrors(
            field,
            validations.compareDates(
              moment().format('YYYY-MM-DD'),
              value,
              'Start Date is required',
              'Lease Start must be in the future for new leases. If you need a refund, you can access it from the following link: ',
            ),
          )
        case 'endDate':
          this.validateEndDate()
          return this.setValidationErrors(
            field,
            validations.compareDates(
              moment().format('YYYY-MM-DD'),
              value,
              'End Date is required for non-rolling lease',
              'Lease End must be in the future',
            ),
          )
        default:
          return true
      }
    }
  }

  validateAll() {
    if (!this.validateGoogleAddress() || !this.validateEndDate()) {
      return false
    }

    let valid = true
    const fields = ['bedrooms', 'rate', 'bond', 'startDate']

    fields.forEach((field) => {
      if (!this.validate(field, this.state[field])) {
        valid = false
      }
    })

    return valid
  }

  validateEndDate() {
    const { startDate, endDate, errors, isRollingLease } = this.state
    if (endDate && startDate && !(endDate > startDate) && !isRollingLease) {
      errors.lease = 'End date must be after start date'
      this.setState({ errors })
      return false
    }

    delete errors.lease
    this.setState({ errors })
    return true
  }

  validateGoogleAddress = () => {
    const fields = [
      'country',
      'postcode',
      'state',
      'streetName',
      'streetNumber',
      'suburb',
    ]
    fields.forEach((field) => {
      this.validate(field)()
    })

    return isEmptyObject(this.state.errors)
  }

  render() {
    const {
      address,
      propertyType,
      rate,
      bond,
      startDate,
      endDate,
      errors,
      bedrooms,
      isRollingLease,
    } = this.state
    const { clicked } = this.props
    const disabled = !(
      bedrooms &&
      propertyType &&
      rate &&
      bond &&
      startDate &&
      (isRollingLease ? true : endDate) &&
      isEmptyObject(errors) &&
      !clicked
    )

    return (
      <div
        ref={(node) => {
          this.node = node
        }}
      >
        <RentalForm
          isNewLease={this.state.isNewLease}
          address={address}
          suggest={this.state.suggest}
          rate={rate}
          bond={bond}
          bedrooms={bedrooms}
          isRollingLease={this.state.isRollingLease}
          propertyType={this.state.propertyType}
          isMultiTenantLease={this.state.isMultiTenantLease}
          isGovernmentSponsored={this.state.isGovernmentSponsored}
          isFirstTimeRenter={this.state.isFirstTimeRenter}
          isForeignResident={this.state.isForeignResident}
          hasUnpaidBondClaim={this.state.hasUnpaidBondClaim}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          errors={this.state.errors}
          apiError={this.state.apiError}
          disabled={disabled}
          clicked={this.props.clicked}
          requestID={this.state.requestID}
          isEmptyObject={isEmptyObject}
          updateAddressNumber={this.updateAddressNumber}
          updateAddress={this.updateAddress}
          updateGoogleAddress={this.updateGoogleAddress}
          resetAddress={this.resetAddress}
          validateGoogleAddress={this.validateGoogleAddress}
          updateNumber={this.updateNumber}
          updateCurrency={this.updateCurrency}
          validate={this.validate}
          toggleSuggest={this.toggleSuggest}
          trimField={this.trimField}
          trimAddressField={this.trimAddressField}
          toggleCheckbox={this.toggleCheckbox}
          toggleRollingLease={this.toggleRollingLease}
          updateDate={this.updateDate}
          endDateInput={this.endDateInput}
          leaseLength={leaseLength}
          update={this.update}
          submit={this.submit}
          unsavedChanges={this.state.unsavedChanges}
        />
      </div>
    )
  }
}

RentalFormContainer.propTypes = {
  fetchCurrentLease: PropTypes.func.isRequired,
  currentLease: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      ),
    ]),
  ),
  currentUser: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.objectOf([
            PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.bool,
              PropTypes.number,
            ]),
          ]),
        ]),
      ),
    ]),
  ).isRequired,
  addCurrentLease: PropTypes.func.isRequired,
  updateCurrentLease: PropTypes.func.isRequired,
  clicked: PropTypes.bool.isRequired,
  apiError: PropTypes.string,
}

RentalFormContainer.defaultProps = {
  currentLease: {},
  apiError: '',
}

export default RentalFormContainer
