import React from 'react'

import { Link } from 'react-router-dom'

const Manager = ({ manager, request }) => {
  let requestID = ''
  if (request) {
    requestID = request.guidID
  }

  let managerHtml = null,
    id = ''
  if (manager && manager.email) {
    let { guidID, agencyName, firstName, lastName, email, mobile } = manager
    id = guidID
    managerHtml = (
      <p>
        {agencyName}
        {agencyName && <br />}
        {firstName} {lastName}
        <br />
        {email}
        <br />
        {mobile}
      </p>
    )
  }

  return (
    <div>
      {request && request.submissionStatus === 0 ? (
        <Link
          className={
            managerHtml ? 'bc-hub-item-link completed' : 'bc-hub-item-link'
          }
          to={`/bc/manager?requestid=${requestID}&id=${id}`}
        >
          <header>
            <i className="icon-user" />
            <span>2. Property Manager</span>
          </header>
          <content>{managerHtml}</content>
        </Link>
      ) : (
        <div
          className={
            request ? 'bc-hub-item-link completed' : 'bc-hub-item-link disabled'
          }
        >
          <header>
            <i className="icon-user" />
            <span>2. Property Manager</span>
          </header>
          <content>{managerHtml}</content>
        </div>
      )}
    </div>
  )
}

export default Manager
