import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

const propTypes = {
  dropdown: PropTypes.array,
}

const Holder = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 4px;
`

const MainItem = styled.span`
  color: var(--pdf-blue);
  display: inline-block;
  cursor: pointer;
  padding: 0 20px 0 0;
  text-decoration: underline;
  position: relative;

  &:after {
    width: 8px;
    border: solid #a1a4a8;
    transform: rotate(45deg);
    border-width: 0 1px 1px 0;
    height: 8px;
    position: absolute;
    top: 3px;
    right: 0;
    content: '';
  }

  ${Holder}:hover & {
    text-decoration: none;
  }
`

const Drop = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 100%;
  border-radius: 3px;
  left: 0px;
  width: 140px;
  background: var(--pdf-white);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  li {
    padding: 10px;
  }

  li + li {
    border-top: 1px solid var(--pdf-gray-middle);
  }
`

class Dropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showOptions: false,
    }
  }

  toggleDropdown = () => {
    this.setState({
      showOptions: !this.state.showOptions,
    })
  }

  render() {
    const {
      dropdownContent,
      updateDropdown,
      dropdownSelectedItem,
      waitingForData,
    } = this.props
    const selectedItem = Object.keys(dropdownContent).filter((item) => {
      return dropdownContent[item]['id'] === dropdownSelectedItem
    })
    const { showOptions } = this.state
    return (
      <Holder>
        <MainItem onClick={this.toggleDropdown}>
          {selectedItem && selectedItem.length === 1
            ? dropdownContent[selectedItem].label
            : 'Not selected'}
        </MainItem>
        {waitingForData && <i className="ml10 fa fa-spinner fa-pulse" />}
        {showOptions && (
          <Drop>
            {dropdownContent.map((item) => (
              <li
                key={item.id}
                onClick={() => {
                  this.toggleDropdown()
                  updateDropdown(item.id)
                }}
              >
                {item.label}
              </li>
            ))}
          </Drop>
        )}
      </Holder>
    )
  }
}

Dropdown.propTypes = propTypes

export default Dropdown
