import React from 'react'

import headerImg01 from 'app/assets/icons/header-img-01.png'
import * as snugNotifier from 'app/services/snugNotifier'
import { firstAndLastNameInitials } from 'app/sm/helpers'

class ApplyJointConfirmationPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      application: {},
    }
  }
  componentDidMount() {
    const { slug } = this.props.match.params
    const { submitJoinedApplication } = this.props
    const data = {
      optedToAcceptCondition: true,
      optedToAcceptDisclosureConsent: true,
      isPrimary: false,
    }
    if (slug) {
      submitJoinedApplication(slug, data)
        .then((application) => {
          this.setState({
            application: application,
          })
        })
        .catch((apiError) => {
          snugNotifier.error(apiError)
        })
    }
  }

  getApplicants = () => {
    const { application } = this.state
    const { applicants } = application
    let applicantsNamesString = ''
    if (applicants) {
      applicants.forEach((applicant, index) => {
        if (index === 0) {
          applicantsNamesString = `${
            applicant.firstName
          } ${firstAndLastNameInitials(applicant, 'lastName')}`
        }
        if (index !== 0 && index !== applicants.length - 1) {
          applicantsNamesString = applicantsNamesString.concat(
            ', ',
            `${applicant.firstName} ${firstAndLastNameInitials(
              applicant,
              'lastName',
            )}`,
          )
        }
        if (index !== 0 && index === applicants.length - 1) {
          applicantsNamesString = applicantsNamesString.concat(
            ' and ',
            `${applicant.firstName} ${firstAndLastNameInitials(
              applicant,
              'lastName',
            )}`,
          )
        }
      })
    }
    return applicantsNamesString
  }

  render() {
    const { application } = this.state
    const { address } = application || {}
    const { friendlyName, suburb } = address || {}
    const propertyAddress = `${friendlyName}, ${suburb}`
    const applicantsNames = this.getApplicants()
    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-second">
          <div className="ilustration-box">
            <img src={headerImg01} alt="" />
          </div>
          {propertyAddress && applicantsNames && (
            <div className="ml15">
              <h3 className="mb15">Application Submitted</h3>
              <p>
                You have now joined the application for <b>{propertyAddress}</b>{' '}
                with <b>{applicantsNames}</b> Your other applications for this
                property have been withdrawn.
              </p>
              <span>
                If you'd like to undo this change, please contact
                <a className="pl5" href="mailto:help@snug.com">
                  help@snug.com
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ApplyJointConfirmationPage
