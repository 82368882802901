import styled from 'styled-components'
import {
  color,
  flex,
  fontSize,
  layout,
  space,
  typography,
  variant,
} from 'styled-system'

import Flex from 'app/components/design-system-components/Flex'
import * as getters from 'app/components/design-system-components/theme/getters'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

// TODO: remove !important padding once you get rid of bootstrap normalize.scss which is using !important

const buttonStates = '&, &:hover, &:active, &:focus'

const disabledStylesForOutlineButtons = {
  '&:disabled': {
    borderColor: 'gray300',
  },
}

const disabledStylesForLinkButtons = {
  '&:disabled, &:disabled:hover': {
    border: 'none',
    backgroundColor: 'transparent',
    color: 'gray300',
  },
}

const flatButtonDefaultStyles = {
  [buttonStates]: {
    backgroundColor: 'transparent',
    color: 'gray400',
    '.fa': {
      color: 'gray400',
    },
  },
}

const linkButtonDefaultStyles = {
  [buttonStates]: {
    padding: `0 !important`,
    backgroundColor: 'transparent',
  },
}

export const buttonSizeVariants = {
  prop: 'sizeVariant',
  variants: {
    extraSmall: {
      padding: `${theme.space[2]}px 10px !important`,
      fontSize: theme.fontSizes.pSmall12,
    },
    small: {
      padding: `${theme.space[2]}px ${theme.space[4]}px !important`,
      fontSize: theme.fontSizes.pSmall12,
    },
    regular: {
      padding: `10px ${theme.space[5]}px !important`,
      fontSize: theme.fontSizes.pRegular14,
    },
    large: {
      padding: `14px 21px !important`,
      fontSize: `${theme.fontSizes[5]}px`,
    },
  },
}

export const buttonVariants = {
  variants: {
    solid: {
      '&, &:hover, &:active, &:focus': {
        backgroundColor: 'gray100',
        color: 'gray900',
      },
    },
    solidSuccess: {
      '&, &:hover, &:active, &:focus': {
        backgroundColor: 'success100',
        color: 'success700',
      },
    },
    solidSuccessStrong: {
      '&, &:hover, &:active, &:focus': {
        backgroundColor: 'success600',
        color: 'white',
      },
    },
    solidSecondary: {
      '&, &:hover, &:active, &:focus': {
        backgroundColor: theme.colors.gray200,
        color: theme.colors.gray900,
      },
    },
    solidWarning: {
      '&, &:hover, &:active, &:focus': {
        backgroundColor: 'warning500',
        color: 'white',
      },
    },
    outline: {
      '&, &:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        color: 'gray500',
        border: '1px solid #CCCCCC',
      },
      ...disabledStylesForOutlineButtons,
    },
    outlineNoFontColor: {
      '&, &:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        border: '1px solid #CCCCCC',
      },
      ...disabledStylesForOutlineButtons,
    },
    outlineWarning: {
      '&, &:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        color: 'warning500',
        border: '1px solid',
        borderColor: 'warning500',
      },
      ...disabledStylesForOutlineButtons,
    },
    outlinePrimary: {
      '&, &:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        color: 'primary300',
        border: '1px solid',
        borderColor: 'primary300',
      },
      ...disabledStylesForOutlineButtons,
    },
    outlineSuccess: {
      '&, &:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        color: 'success600',
        borderColor: 'success600',
      },
      border: '1px solid',
      ...disabledStylesForOutlineButtons,
    },
    outlineError: {
      '&, &:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        color: 'error500',
        border: '1px solid',
        borderColor: 'error500',
      },
      ...disabledStylesForOutlineButtons,
    },
    outlineDeepBlue: {
      '&, &:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        color: 'deepBlue',
        border: '1px solid',
        borderColor: 'deepBlue',
      },
      ...disabledStylesForOutlineButtons,
    },
    linkSuccess: {
      ...linkButtonDefaultStyles,
      '&, &:hover, &:active, &:focus': {
        ...linkButtonDefaultStyles[buttonStates],
        color: `${theme.colors.success600} !important`,
      },
      ...disabledStylesForLinkButtons,
    },
    linkWarning: {
      ...linkButtonDefaultStyles,
      '&, &:hover, &:active, &:focus': {
        ...linkButtonDefaultStyles[buttonStates],
        color: 'warning500',
      },
      ...disabledStylesForLinkButtons,
    },
    linkBlue: {
      ...linkButtonDefaultStyles,
      '&, &:hover, &:active, &:focus': {
        ...linkButtonDefaultStyles[buttonStates],
        color: 'blue',
      },
      ...disabledStylesForLinkButtons,
    },
    linkGray: {
      ...linkButtonDefaultStyles,
      '&, &:hover, &:active, &:focus': {
        ...linkButtonDefaultStyles[buttonStates],
        color: 'gray',
      },
      ...disabledStylesForLinkButtons,
    },
    linkBlueUnderlined: {
      ...linkButtonDefaultStyles,
      '&, &:hover, &:active, &:focus': {
        ...linkButtonDefaultStyles[buttonStates],
        color: 'blue',
        textDecoration: 'underline !important',
      },
      ...disabledStylesForLinkButtons,
    },
    linkBlueWithoutWeight: {
      ...linkButtonDefaultStyles,
      fontWeight: 'unset',
      '&, &:hover, &:active, &:focus': {
        ...linkButtonDefaultStyles[buttonStates],
        color: 'blue',
      },
      ...disabledStylesForLinkButtons,
    },
    linkWhiteUnderlined: {
      ...linkButtonDefaultStyles,
      fontWeight: 'unset',
      '&, &:hover, &:active, &:focus': {
        ...linkButtonDefaultStyles[buttonStates],
        color: 'white',
        textDecoration: 'underline !important',
      },
      ...disabledStylesForLinkButtons,
    },
    linkBlackUnderlinedWithoutWeight: {
      ...linkButtonDefaultStyles,
      fontWeight: 'unset',
      '&, &:hover, &:active, &:focus': {
        ...linkButtonDefaultStyles[buttonStates],
        color: 'black',
        textDecoration: 'underline !important',
      },
      ...disabledStylesForLinkButtons,
    },
    linkDeepBlue: {
      ...linkButtonDefaultStyles,
      '&, &:hover, &:active, &:focus': {
        ...linkButtonDefaultStyles[buttonStates],
        color: 'deepBlue',
      },
      ...disabledStylesForLinkButtons,
    },
    flat: {
      ...flatButtonDefaultStyles,
    },
    flatBlue: {
      ...flatButtonDefaultStyles,
      [buttonStates]: {
        ...flatButtonDefaultStyles[buttonStates],
        color: 'deepBlue',
      },
    },
    flatBlack: {
      ...flatButtonDefaultStyles,
      [buttonStates]: {
        ...flatButtonDefaultStyles[buttonStates],
        color: 'black',
      },
    },
    pagination: {
      color: 'gray500',
      background: 'white',
      '&, &:hover, &:active, &:focus': {
        background: 'white',
        color: 'gray500',
      },
    },
    flatWithBorder: {
      ...flatButtonDefaultStyles,
      borderColor: 'gray400',
      border: '1px solid',
      color: 'gray600',
      [buttonStates]: {
        ...flatButtonDefaultStyles[buttonStates],
        color: 'gray600',
      },
    },
    greenHover: {
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: 'green',
      color: 'green',
      '&:hover, &:active, &:focus': {
        backgroundColor: 'green',
        color: 'white',
      },
      ...disabledStylesForOutlineButtons,
    },
    paginationActive: {
      backgroundColor: 'primary100',
      color: 'primary500',
      '&, &:hover, &:active, &:focus': {
        backgroundColor: 'primary100',
        color: 'primary500',
      },
    },
    blueTabHeaderActive: {
      backgroundColor: 'paleBlue',
      color: 'blue',
      '&, &:hover, &:active, &:focus': {
        backgroundColor: 'paleBlue',
        color: 'blue',
      },
    },
    outlineBlue: {
      color: 'blue',
      border: '1px solid',
      borderColor: 'blue',
      backgroundColor: 'transparent',
      '&:hover, &:active, &:focus': {
        backgroundColor: 'blue',
        color: 'white',
      },
    },
    outlineGreen: {
      color: 'success600',
      border: '1px solid',
      borderColor: 'success600',
      backgroundColor: 'transparent',
      '&:hover, &:active, &:focus': {
        backgroundColor: 'white',
        color: 'success600',
        borderColor: 'success600',
      },
    },
    outlineGreenWhiteBg: {
      color: 'success600',
      border: '1px solid',
      borderColor: 'success600',
      backgroundColor: 'white',
      '&:hover, &:active, &:focus': {
        backgroundColor: 'white',
        color: 'success600',
        borderColor: 'success600',
      },
    },
    outlineGray: {
      color: 'gray500',
      border: '1px solid',
      borderColor: 'gray500',
      backgroundColor: 'transparent',
      '&:hover, &:active, &:focus': {
        backgroundColor: 'white',
        color: 'success600',
        borderColor: 'success600',
      },
    },
    paginationDisabled: {},
    whiteBGGrayBorder: {
      color: 'gray500',
      background: 'white',
      border: '1px solid',
      borderColor: 'gray300',
    },
    brightGreenOutline: {
      color: 'success600',
      border: '1px solid',
      borderColor: 'success600',
      backgroundColor: 'transparent',
    },
    grayWithGreenHoverGrayClicked: {
      color: 'gray600',
      background: 'white',
      border: '1px solid',
      borderColor: 'gray300',
      '&:hover': {
        borderColor: 'success600',
      },
      '&:active': {
        backgroundColor: 'gray300',
        borderColor: 'gray300',
        color: 'gray500',
      },
    },
  },
}

export const LegacyButton = styled.button`
  size: 14px;
  padding: 0 ${getters.spacing(6)};
  line-height: 18px;
  font-weight: ${({ theme }) => theme.fontWeights[6]};
  border-radius: 5px;
  i {
    margin: initial;
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.gray300};
    background-color: ${({ theme }) => theme.colors.gray200};
    border: none;
  }
  ${layout}
  ${flex}
  ${space}
  ${fontSize}
  ${variant(buttonVariants)}
  ${variant(buttonSizeVariants)}
`

const StyledButton = styled(LegacyButton)`
  width: initial;
  height: unset;
  line-height: 1.3 !important;

  ${layout}
  ${color}
  ${space}
  ${typography}
`

export const Button = ({ ...props }) => {
  return <StyledButton {...props} />
}

export const IconButtonContainer = styled(Button)`
  // resetting properties added by legacy stylesheets
  padding: 0;
  height: unset;
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: unset;
  }

  border-radius: 50%;
  text-align: center;
  .icon-cmp {
    display: block;
  }

  ${variant(buttonVariants)}
  ${variant(buttonSizeVariants)}
`

Button.defaultProps = {
  sizeVariant: 'regular',
}

export const IconButton = ({ IconCmp, iconProps, ...props }) => {
  return (
    <IconButtonContainer {...props}>
      <IconCmp className="icon-cmp" {...iconProps} />
    </IconButtonContainer>
  )
}

export const WhiteBGButton = styled(Flex)`
  background: ${theme.colors.white};
  border: ${theme.borders[1]} ${theme.colors.grey200};
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: ${theme.radii[6]}px;
  padding: ${(props) =>
    props.padding ? props.padding : `${theme.space[3]}px`};
  color: ${theme.colors.gray600};
  font-size: ${(props) => props.fontSize || theme.fontSizes.pSmall12};
  line-height: ${theme.lineHeights.title};
  cursor: pointer;
`

export const ButtonWithIcon = ({
  leading = true,
  IconCmp,
  children,
  iconProps = {},
  iconContainerProps = {},
  containerProps = {},
  ...props
}) => {
  return (
    <Button {...props}>
      <Flex alignItems="center" justifyContent="center" {...containerProps}>
        {leading && (
          <Flex
            mr="0.8em"
            fontSize="1.3em"
            alignItems="center"
            {...iconContainerProps}
          >
            <IconCmp {...iconProps} />
          </Flex>
        )}
        {children}
        {!leading && (
          <Flex
            ml="0.8em"
            fontSize="1.3em"
            alignItems="center"
            {...iconContainerProps}
          >
            <IconCmp {...iconProps} />
          </Flex>
        )}
      </Flex>
    </Button>
  )
}
