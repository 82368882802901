// https://github.com/marcuswestin/store.js/issues/232#issuecomment-310127814
const memoryLocalStorage = {}
let hasLocalStorage = false
if (localStorage) {
  try {
    const x = 'testLocalStorage'
    localStorage.setItem(x, x)
    localStorage.removeItem(x)
    hasLocalStorage = true
  } catch (e) {
    hasLocalStorage = false
  }
}

class LocalStorageWrapper {
  // set
  static setItem(key, value) {
    if (hasLocalStorage) {
      localStorage.setItem(key, value)
    } else {
      memoryLocalStorage[key] = value
    }
  }

  // get
  static getItem(key) {
    if (hasLocalStorage) {
      return localStorage.getItem(key)
    }
    return memoryLocalStorage[key]
  }

  // remove
  static removeItem(key) {
    if (hasLocalStorage) {
      localStorage.removeItem(key)
    } else {
      memoryLocalStorage[key] = null
    }
  }
}

const memorySessionStorage = {}
let hasSessionStorage = false
if (sessionStorage) {
  try {
    const x = 'testSessionStorage'
    sessionStorage.setItem(x, x)
    sessionStorage.removeItem(x)
    hasSessionStorage = true
  } catch (e) {
    hasSessionStorage = false
  }
}

class SessionStorageWrapper {
  // set
  static setItem(key, value) {
    if (hasSessionStorage) {
      sessionStorage.setItem(key, value)
    } else {
      memorySessionStorage[key] = value
    }
  }

  // get
  static getItem(key) {
    if (hasSessionStorage) {
      return sessionStorage.getItem(key)
    }
    return memorySessionStorage[key]
  }

  // remove
  static removeItem(key) {
    if (hasSessionStorage) {
      sessionStorage.removeItem(key)
    } else {
      memorySessionStorage[key] = null
    }
  }
}

export const LocalStorageUtils = LocalStorageWrapper
export const SessionStorageUtils = SessionStorageWrapper
export const RegisterAgencyIDKey = 'targetAgencyID'
