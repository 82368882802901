import React from 'react'

import moment from 'moment'

import * as Display from 'app/components/display/display'
import * as Text from 'app/components/display/text/text_component'
import * as Form from 'app/components/forms/forms'
import { DATE_WITH_DASH } from 'app/utils/datetime/helpers'

const YES = 'Yes'
const NO = 'No'

//versions are only used in displaying disclosures, in forms (adding disclosures) we're always using latest version

const MISSING_VERSION = 0
const BASE_VERSION = 1
const SECOND_VERSION = 2
const ALL_VERSIONS = [MISSING_VERSION, BASE_VERSION, SECOND_VERSION]

export const getDisclosureLatestVersion = () => SECOND_VERSION
export const getDisclosureMissingVersion = () => MISSING_VERSION

export const disclosureVersionedQuestions = {
  hasHomicide: {
    versions: ALL_VERSIONS,
    text: 'Was there a homicide at the property?',
    render: (value, err, isEditable, onChange, version) => (
      <DisclosureQuestion id={'hasHomicide'} version={version}>
        {renderDisclosureBooleanQuestion(
          value,
          err,
          isEditable,
          'hasHomicide',
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  dateOfHomicide: {
    versions: ALL_VERSIONS,
    text: 'Date of Homicide',
    render: (value, err, isEditable, onChange, version) => (
      <DisclosureQuestion id={'dateOfHomicide'} version={version}>
        {renderDisclosurePastDateQuestion(
          value,
          err,
          isEditable,
          'dateOfHomicide',
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  compliesWithMinimumStandards: {
    versions: ALL_VERSIONS,
    text: 'Does the rented premises comply with the Minimum Standards?',
    render: (value, err, isEditable, onChange, version) => (
      <DisclosureQuestion id={'compliesWithMinimumStandards'} version={version}>
        {renderDisclosureBooleanQuestion(
          value,
          err,
          isEditable,
          'compliesWithMinimumStandards',
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  dateOfRepairNotice: {
    versions: ALL_VERSIONS,
    text: 'Date of most recent Repair Notice relating to Mould or Damp?',
    render: (value, err, isEditable, onChange, version) => (
      <DisclosureQuestion id={'dateOfRepairNotice'} version={version}>
        {renderDisclosurePastDateQuestion(
          value,
          err,
          isEditable,
          'dateOfRepairNotice',
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  dateOfGasCheck: {
    versions: ALL_VERSIONS,
    text: 'Date of most recent Gas Safety Check?',
    render: (value, err, isEditable, onChange, version) => (
      <DisclosureQuestion id={'dateOfGasCheck'} version={version}>
        {renderDisclosurePastDateQuestion(
          value,
          err,
          isEditable,
          'dateOfGasCheck',
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  dateOfElectricityCheck: {
    versions: ALL_VERSIONS,
    text: 'Date of most recent Electrical Safety Check?',
    render: (value, err, isEditable, onChange, version) => (
      <DisclosureQuestion id={'dateOfElectricityCheck'} version={version}>
        {renderDisclosurePastDateQuestion(
          value,
          err,
          isEditable,
          'dateOfElectricityCheck',
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  dateOfPoolCheck: {
    versions: ALL_VERSIONS,
    text: 'Date of most recent Pool Barrier Compliance Check?',
    render: (value, err, isEditable, onChange, version) => (
      <DisclosureQuestion id={'dateOfPoolCheck'} version={version}>
        {renderDisclosurePastDateQuestion(
          value,
          err,
          isEditable,
          'dateOfPoolCheck',
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  isRegisteredPlace: {
    versions: ALL_VERSIONS,
    text: 'Is the rented premises a Registered Place?',
    render: (value, err, isEditable, onChange, version) => (
      <DisclosureQuestion id={'isRegisteredPlace'} version={version}>
        {renderDisclosureBooleanQuestion(
          value,
          err,
          isEditable,
          'isRegisteredPlace',
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  dateOfDrugUsage: {
    versions: ALL_VERSIONS,
    text: 'Date premise last used as a if contaminated because of drug of dependence trafficking or cultivation?',
    render: (value, err, isEditable, onChange, version) => (
      <DisclosureQuestion id={'dateOfDrugUsage'} version={version}>
        {renderDisclosurePastDateQuestion(
          value,
          err,
          isEditable,
          'dateOfDrugUsage',
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  hasAsbestos: {
    versions: ALL_VERSIONS,
    text: 'Does the rental premise contain friable or non-friable asbestos based on an inspection of a suitably qualified person?',
    render: (value, err, isEditable, onChange, version) => (
      <DisclosureQuestion id={'hasAsbestos'} version={version}>
        {renderDisclosureBooleanQuestion(
          value,
          err,
          isEditable,
          'hasAsbestos',
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  isAffectedWithRelevantAuthorities: {
    versions: ALL_VERSIONS,
    text: 'Is the rental premise affected by a building or planning application lodged with the relevant authorities?',
    render: (value, err, isEditable, onChange, version) => (
      <DisclosureQuestion
        id={'isAffectedWithRelevantAuthorities'}
        version={version}
      >
        {renderDisclosureBooleanQuestion(
          value,
          err,
          isEditable,
          'isAffectedWithRelevantAuthorities',
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  hasEmbeddedNetwork: {
    versions: ALL_VERSIONS,
    text: 'Is there an Embedded Network?',
    render: (value, err, isEditable, onChange, version) => (
      <DisclosureQuestion id={'hasEmbeddedNetwork'} version={version}>
        {renderDisclosureBooleanQuestion(
          value,
          err,
          isEditable,
          'hasEmbeddedNetwork',
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  embeddedNetworkName: {
    versions: ALL_VERSIONS,
    text: 'Embedded Network:',
    render: (value, err, isEditable, label, onChange, version) => (
      <DisclosureQuestion id={'embeddedNetworkName'} version={version}>
        {renderDisclosureOptionalTextQuestion(
          value,
          err,
          isEditable,
          'embeddedNetworkName',
          label,
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  embeddedNetworkAbn: {
    versions: ALL_VERSIONS,
    text: 'ABN:',
    render: (value, err, isEditable, label, onChange, version) => (
      <DisclosureQuestion id="embeddedNetworkAbn" version={version}>
        {renderDisclosureABNQuestion(
          version,
          value,
          err,
          isEditable,
          'embeddedNetworkAbn',
          label,
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  embeddedNetworkPhone: {
    versions: ALL_VERSIONS,
    text: 'Telephone:',
    render: (value, err, isEditable, label, onChange, version) => (
      <DisclosureQuestion id="embeddedNetworkPhone" version={version}>
        {renderDisclosurePhoneQuestion(
          value,
          err,
          isEditable,
          'embeddedNetworkPhone',
          label,
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  embeddedNetworkWebsite: {
    versions: ALL_VERSIONS,
    text: 'Website:',
    render: (value, err, isEditable, label, onChange, version) => (
      <DisclosureQuestion id={'embeddedNetworkWebsite'} version={version}>
        {renderDisclosureOptionalTextQuestion(
          value,
          err,
          isEditable,
          'embeddedNetworkWebsite',
          label,
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  embeddedNetworkPricing: {
    versions: ALL_VERSIONS,
    text: 'Pricing:',
    render: (value, err, isEditable, label, onChange, version) => (
      <DisclosureQuestion id={'embeddedNetworkPricing'} version={version}>
        {renderDisclosureOptionalTextQuestion(
          value,
          err,
          isEditable,
          'embeddedNetworkPricing',
          label,
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
  isBeingSold: {
    versions: [SECOND_VERSION],
    text: 'Has an agent/person been engaged to sell the property or, if a contract of sale has been prepared, is there an ongoing proposal to sell the property?',
    render: (value, err, isEditable, onChange, version) => (
      <DisclosureQuestion id={'isBeingSold'} version={version}>
        {renderDisclosureBooleanQuestion(
          value,
          err,
          isEditable,
          'isBeingSold',
          onChange,
        )}
      </DisclosureQuestion>
    ),
  },
}

const renderDisclosureBooleanQuestion = (
  value,
  err,
  isEditable,
  id,
  onChange,
) => {
  return (
    <>
      <Form.YesOrNoRadioGroup id={id} error={err}>
        <Form.YesOrNoCheckbox
          id={YES}
          label={YES}
          checked={value === true}
          onChange={onChange(id)}
          componentClassName="yes left"
          enableEdit={isEditable}
        />
        <Form.YesOrNoCheckbox
          id={NO}
          label={NO}
          checked={value === false}
          onChange={onChange(id)}
          componentClassName="no right"
          enableEdit={isEditable}
        />
      </Form.YesOrNoRadioGroup>
    </>
  )
}

const renderDisclosurePastDateQuestion = (
  value,
  err,
  isEditable,
  id,
  onChange,
) => (
  <>
    <Form.InputPastDate
      value={moment(value)}
      onChange={onChange(id)}
      id={id}
      readOnly={!isEditable}
      error={err}
      customisedDateFormat={DATE_WITH_DASH}
    />
  </>
)
const renderDisclosureOptionalTextQuestion = (
  value,
  err,
  isEditable,
  id,
  label,
  onChange,
) => (
  <>
    <Form.InputTextOptional
      value={value}
      label={isEditable ? label : ''}
      onChange={onChange(id)}
      labelClass="top18"
      inputClass="width100"
      isOptional
      readOnly={!isEditable}
      showError={true}
      error={err}
    />
  </>
)

const renderDisclosureABNQuestion = (
  version,
  value,
  err,
  isEditable,
  id,
  label,
  onChange,
) => (
  <>
    <Form.InputABN
      value={value}
      label={isEditable ? label : ''}
      onChange={onChange(id)}
      labelClass="top18"
      inputClass="width100"
      isOptional
      readOnly={!isEditable}
      error={err}
    />
  </>
)

const renderDisclosurePhoneQuestion = (
  value,
  err,
  isEditable,
  id,
  label,
  onChange,
) => (
  <>
    <Form.InputPhoneNumber
      value={value}
      label={isEditable ? label : ''}
      onChange={onChange(id)}
      labelClass="top18"
      inputClass="width100"
      isOptional
      readOnly={!isEditable}
      error={err}
    />
  </>
)

const DisclosureQuestion = ({ id, version, children }) =>
  disclosureVersionedQuestions[id]?.versions?.includes(version) && (
    <>
      <Display.FlexContainer>
        <Text.Strong text={disclosureVersionedQuestions[id].text} />
      </Display.FlexContainer>
      {children}
    </>
  )
