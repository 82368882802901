import React from 'react'

import moment from 'moment'

import { Flex } from 'app/components/design-system-components'
import { MailToLink } from 'app/components/design-system-components/links/MailTo'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { toTimeAmPmDateFormat } from 'app/utils/datetime/helpers'

export const TeamIDAliasesModal = ({ aliases, onCloseModal }) => {
  return (
    <GenericModal
      onDismiss={() => onCloseModal()}
      title={<Flex alignItems="center">Snug Team ID aliases</Flex>}
      modalFooter={
        <GenericModalFooter
          secondaryBtnConfig={{
            title: 'Close',
            onClick: () => onCloseModal(),
          }}
        />
      }
    >
      <p>
        Team ID aliases allow different IDs to be used with different
        integrations. Contact{' '}
        <MailToLink email="help@snug.com" subject="Set up Team ID alias">
          help@snug.com
        </MailToLink>{' '}
        to set up aliases.
      </p>
      {aliases &&
        aliases.map((alias) => (
          <p>
            {alias.teamID} {alias.label && <span>({alias.label})</span>}{' '}
            {alias.created_at && (
              <span>added {toTimeAmPmDateFormat(alias.created_at)}</span>
            )}
          </p>
        ))}
      {(!aliases || !aliases.length) && <p>No aliases are configured.</p>}
    </GenericModal>
  )
}
