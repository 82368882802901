const _defaultState = {
  property: {
    address: {},
    reviews: [],
    questions: [],
    images: [],
    agent: {},
  },
  error: '',
}

const PropertyTransactionsReducer = (state = _defaultState, action) => {
  let { property } = action

  Object.freeze(state)
  let newState = Object.assign({}, state)
  return Object.assign(newState, { property })
}

export default PropertyTransactionsReducer
