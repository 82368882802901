import React from 'react'

import {
  Box,
  Flex,
  HorizontalLine,
} from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const MessagePreview = ({ subject, message, property }) => {
  return (
    <Box
      backgroundColor={theme.colors.paleBlue}
      overflowY="scroll"
      height="360px"
      p={theme.space[6] + 'px'}
      my={theme.space[6] + 'px'}
    >
      <Box mb={theme.space[6] + 'px'}>
        <Box textAlign="center">
          <h3>{subject || '[Subject]'}</h3>
        </Box>
        <Flex
          flexDirection="column"
          alignItems="start"
          mt={theme.space[6] + 'px'}
        >
          <br />
          Hi [name],
          <br />
          <Box mb={theme.space[6] + 'px'} style={{ whiteSpace: 'pre-wrap' }}>
            <br />
            {message || '[message]'}
          </Box>
          Regards,
          <br />
          [agent name]
          <br />
          [agency phone]
          <br />
          {property && (
            <>
              To book an inspection or start an application for this property,
              please visit: [property apply link]
              <br />
            </>
          )}
          View our available listings: [team apply link]
        </Flex>
      </Box>
      <HorizontalLine />
    </Box>
  )
}
