export const incomeLimitFromGroup = (group, incomeTableData = []) => {
  if ((group, incomeTableData.length > 0)) {
    const incomeLimitData = incomeTableData.find(({ type }) => {
      if (group === 'Group') {
        return type === 'Family'
      }
      return type === group
    })
    const { income = 0 } = incomeLimitData || {}
    return income
  } else {
    return 0
  }
}
