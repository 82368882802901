import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { actions as coreActions } from 'app/core/coreSlice'
import {
  deleteWalkInRenter,
  getPreloadedPropertiesAndViewingsData,
  getPropertyReportData,
  getRegistrantsForViewing,
  postAddViewing,
  postArchiveProperty,
  postWalkInRenter,
  putCancelViewing,
  putEditViewing,
  putWalkInRenter,
} from 'app/pages/teams/viewings-mobile/access'
import { showCheckboxSpinner } from 'app/sm/inspections/inspections_actions'

export const RECEIVE_PRELOAD = 'RECEIVE_PRELOAD'
export const RESET_PRELOAD_DATA = 'RESET_PRELOAD_DATA'
export const PRELOAD_PROPERTIES_AND_VIEWINGS_ERROR =
  'PRELOAD_PROPERTIES_AND_VIEWINGS_ERROR'

export const UPDATE_VIEWING = 'UPDATE_VIEWING'
export const CANCEL_VIEWING = 'CANCEL_VIEWING'

export const ADD_REGISTRANT = 'ADD_REGISTRANT'
export const UPDATE_REGISTRANT = 'UPDATE_REGISTRANT'
export const REMOVE_REGISTRANT = 'REMOVE_REGISTRANT'

export const ARCHIVE_PROPERTY = 'ARCHIVE_PROPERTY'

export const SHOW_FETCH_SPINNER = 'SHOW_FETCH_SPINNER'

export const UPDATE_PRELOAD_SPINNER_SHOW = 'UPDATE_PRELOAD_SPINNER_SHOW'
export const UPDATE_PRELOAD_SPINNER_HIDE = 'UPDATE_PRELOAD_SPINNER_HIDE'

export const RECEIVE_ERROR_PRELOAD = 'RECEIVE_ERROR_PRELOAD'

export const ADD_VIEWING = 'ADD_VIEWING'
export const ADD_VIEWING_TO_PROPERTY_ERROR = 'ADD_VIEWING_TO_PROPERTY_ERROR'
export const ADD_VIEWING_TO_PROPERTY_SUCCESS = 'ADD_VIEWING_TO_PROPERTY_SUCCESS'

export const RECEIVE_REGISTRANTS_FOR_VIEWING = 'RECEIVE_REGISTRANTS_FOR_VIEWING'

const receivePreload = (preloadedPropertiesAndViewings) => ({
  type: RECEIVE_PRELOAD,
  preloadedPropertiesAndViewings,
})

const receiveRegistrantsForViewing = (registrants) => ({
  type: RECEIVE_REGISTRANTS_FOR_VIEWING,
  registrants,
})

const updatePreloadSpinnerShow = () => ({
  type: UPDATE_PRELOAD_SPINNER_SHOW,
})

const updatePreloadSpinnerHide = () => ({
  type: UPDATE_PRELOAD_SPINNER_HIDE,
})

const updateViewingForProperty = (viewingData) => ({
  type: UPDATE_VIEWING,
  viewingData,
})

const addViewingToProperty = (viewingData) => ({
  type: ADD_VIEWING,
  viewingData,
})

const cancelExistingViewing = (viewingData) => ({
  type: CANCEL_VIEWING,
  viewingData,
})

const archiveCurrentProperty = (propertyData) => ({
  type: ARCHIVE_PROPERTY,
  propertyData,
})

const receiveErrorPreload = (preloadFetchErrors) => ({
  type: RECEIVE_ERROR_PRELOAD,
  preloadFetchErrors,
})

const addRegistrant = (registrantData) => ({
  type: ADD_REGISTRANT,
  registrantData,
})

const updateRegistrant = (registrantData) => ({
  type: UPDATE_REGISTRANT,
  registrantData,
})

const removeRegistrant = (registrantData) => ({
  type: REMOVE_REGISTRANT,
  registrantData,
})

export const resetData = () => ({ type: RESET_PRELOAD_DATA })

export const fetchPropertyViewingsReportData = (viewingGUID) => () => {
  return getPropertyReportData(viewingGUID)
    .then(({ data, ok, errorCode }) => {
      if (ok) {
        return Promise.resolve(data)
      } else {
        return Promise.reject(translateErrorCodeToMessage(errorCode))
      }
    })
    .catch((error) => {
      return Promise.reject(translateErrorCodeToMessage(error))
    })
}

export const addViewing =
  (
    offerGUID,
    startDate,
    duration,
    fromScheduler,
    skipDoubleBookingCheck,
    viewingOptions,
  ) =>
  (dispatch) => {
    return postAddViewing(
      offerGUID,
      startDate,
      duration,
      fromScheduler,
      skipDoubleBookingCheck,
      viewingOptions,
    ).then(({ data, ok, errorCode }) => {
      if (ok) {
        return Promise.resolve(
          dispatch(addViewingToProperty({ ...data, offerGUID })),
        )
      } else {
        return Promise.reject(translateErrorCodeToMessage(errorCode))
      }
    })
  }

export const editViewing =
  (
    offerGUID,
    viewingGUID,
    startDate,
    duration,
    skipDoubleBookingCheck,
    viewingOptions,
  ) =>
  (dispatch) => {
    return putEditViewing(
      offerGUID,
      viewingGUID,
      startDate,
      duration,
      skipDoubleBookingCheck,
      viewingOptions,
    ).then(({ data, ok, errorCode }) => {
      if (ok) {
        return Promise.resolve(dispatch(updateViewingForProperty(data)))
      }
      return Promise.reject(translateErrorCodeToMessage(errorCode))
    })
  }

//Done using dispatch
export const fetchPreloadedPropertiesAndViewingsData =
  (teamGUID, options) => (dispatch) => {
    dispatch(coreActions.startNewGlobalLoading())
    return getPreloadedPropertiesAndViewingsData(teamGUID, options)
      .then(({ data, ok, errorCode }) => {
        dispatch(updatePreloadSpinnerShow())
        if (ok) {
          dispatch(receivePreload(data))
        } else {
          dispatch(
            receiveErrorPreload(errorCode.errors || [{ message: errorCode }]),
          )
        }
      })
      .finally(() => {
        dispatch(updatePreloadSpinnerHide())
        dispatch(coreActions.endOneGlobalLoading())
      })
  }

export const cancelViewing = (viewingGUID) => (dispatch) => {
  return putCancelViewing(viewingGUID)
    .then(({ data, ok, errorCode }) => {
      if (ok) {
        return Promise.resolve(dispatch(cancelExistingViewing(data)))
      } else {
        return Promise.reject(translateErrorCodeToMessage(errorCode))
      }
    })
    .catch((error) => {
      return Promise.reject(translateErrorCodeToMessage(error))
    })
}

export const archiveProperty =
  (
    propertyGUID,
    cancelFutureViewings = false,
    declineUnsuccessfulApplications = false,
  ) =>
  (dispatch) => {
    return postArchiveProperty(
      propertyGUID,
      cancelFutureViewings,
      declineUnsuccessfulApplications,
    )
      .then(({ data, ok, errorCode }) => {
        if (ok) {
          return Promise.resolve(dispatch(archiveCurrentProperty(data)))
        } else {
          return Promise.reject(translateErrorCodeToMessage(errorCode))
        }
      })
      .catch((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
  }

export const fetchRegistrantsForViewing =
  (teamGUID, viewingGUID) => (dispatch) => {
    return getRegistrantsForViewing(teamGUID, viewingGUID)
      .then(({ data, ok, errorCode }) => {
        if (ok) {
          return Promise.resolve(dispatch(receiveRegistrantsForViewing(data)))
        } else {
          return Promise.reject(translateErrorCodeToMessage(errorCode))
        }
      })
      .catch((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
  }

export const addWalkInRenter =
  (data, propertyId, viewingGUID) => (dispatch) => {
    data.propertyId = propertyId
    return postWalkInRenter(data, viewingGUID)
      .then(({ data, ok, errorCode }) => {
        if (ok) {
          dispatch(addRegistrant(data))
          return Promise.resolve(data)
        } else {
          return Promise.reject(translateErrorCodeToMessage(errorCode))
        }
      })
      .catch((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
  }

export const updateRenterStatus =
  (data, renterType, viewingGUID, registrationGUID) => (dispatch, getState) => {
    const { checkboxSpinnerGuidArray } = getState().ViewingsReducer
    let newArray = Array.from(checkboxSpinnerGuidArray)
    newArray.push(registrationGUID)
    dispatch(showCheckboxSpinner(newArray))
    return putWalkInRenter(data, renterType, viewingGUID, registrationGUID)
      .then(({ data, ok, errorCode }) => {
        if (ok) {
          dispatch(showCheckboxSpinner([]))
          return Promise.resolve(dispatch(updateRegistrant(data)))
        } else {
          dispatch(showCheckboxSpinner([]))
          return Promise.reject(translateErrorCodeToMessage(errorCode))
        }
      })
      .catch((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
  }

export const removeOnsiteViewingRenter =
  (viewingGUID, registrationGUID) => (dispatch) => {
    return deleteWalkInRenter(viewingGUID, registrationGUID)
      .then(({ data, ok, errorCode }) => {
        if (ok) {
          return Promise.resolve(
            dispatch(removeRegistrant({ viewingGUID, registrationGUID })),
          )
        } else {
          dispatch(showCheckboxSpinner([]))
          return Promise.reject(translateErrorCodeToMessage(errorCode))
        }
      })
      .catch((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
  }
