import React from 'react'

import {
  Box,
  Flex,
  HorizontalLine,
  IconButton,
} from 'app/components/design-system-components'
import {
  AddRounded,
  RemoveRounded,
} from 'app/components/design-system-components/icons/content'
import { Input } from 'app/components/design-system-components/inputs'
import { DropdownBodySectionHeader } from 'app/components/design-system-components/list-filters/DropdownBodySectionHeader'
import { StyledCheckbox } from 'app/components/design-system-components/list-filters/StyledCheckbox'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const propertyTypeFilterFields = {
  bedrooms: 'bedrooms',
  bathrooms: 'bathrooms',
  weeklyRentMin: 'weeklyRentMin',
  weeklyRentMax: 'weeklyRentMax',
}

export const propertyTypeFilterDataExtractor = (filterValues) => {
  return Object.entries(filterValues)
    .filter(([id, { enabled }]) => Boolean(enabled))
    .reduce((accFilter, [id, { value }]) => ({ ...accFilter, [id]: value }), {})
}

export const PropertyTypeAppliedFilterRenderer = ({ filterValues }) => {
  const formattersMap = {
    [propertyTypeFilterFields.bedrooms]: (val) => `${val} BR`,
    [propertyTypeFilterFields.bathrooms]: (val) => `${val} BTH`,
    [propertyTypeFilterFields.weeklyRentMin]: (val) => `min rent p/w $${val}`,
    [propertyTypeFilterFields.weeklyRentMax]: (val) => `max rent p/w $${val}`,
  }

  const valuesStrings = Object.entries(formattersMap).map(
    ([fieldId, formatter]) => {
      const { enabled, value } = filterValues[fieldId] || {}
      return enabled ? formatter(value) : null
    },
  )

  return valuesStrings.filter(Boolean).join(', ')
}

export const isPropertyTypeApplied = (filterValues) => {
  return Object.values(filterValues).some((filterVal) => !!filterVal?.enabled)
}

const NumberInput = ({
  value,
  changeHandler,
  blurHandler,
  error,
  disableChange,
  disableInc,
  disableDec,
}) => {
  const number = value || 0
  const increment = () => updateNumber(number + 1)
  const decrement = () => updateNumber(number - 1)
  const onInputChange = ({ target: { value } }) => {
    const valueToNumber = parseInt(value)
    if (isNaN(valueToNumber)) return
    updateNumber(valueToNumber)
  }

  const updateNumber = (number) => {
    changeHandler(number)
    return number
  }

  return (
    <Flex alignItems="center">
      <IconButton
        IconCmp={RemoveRounded}
        variant="flat"
        onClick={decrement}
        disabled={disableChange || disableDec}
        type="button"
        mr={theme.space[2] + 'px'}
      />
      <Input
        error={error}
        maxWidth="40px"
        inputProps={{
          value: number,
          onChange: onInputChange,
          pattern: '^[0-9]\\d*$',
          width: '100%',
          textAlign: 'center',
          disabled: disableChange,
        }}
      />
      <IconButton
        IconCmp={AddRounded}
        variant="flat"
        onClick={increment}
        disabled={disableChange || disableInc}
        type="button"
        ml={theme.space[2] + 'px'}
      />
    </Flex>
  )
}

const ChildFilter = ({ isEnabled, updateEnabled, title, input }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      p={`${theme.space[3]}px ${theme.space[4]}px`}
    >
      <StyledCheckbox
        label={title}
        checked={isEnabled}
        onChange={({ target: { checked } }) => updateEnabled(checked)}
        mb="unset"
        mr={theme.space[3] + 'px'}
      />

      {input}
    </Flex>
  )
}

export const PropertyTypeFilterBody = ({
  formBag: { values, setFieldValue },
}) => {
  const bedroomsFilterVal = values[propertyTypeFilterFields.bedrooms] || {
    enabled: false,
    value: 1,
  }
  const bedroomsElem = (
    <ChildFilter
      title="Bedrooms"
      input={
        <NumberInput
          value={parseInt(bedroomsFilterVal.value)}
          changeHandler={(value) =>
            setFieldValue(propertyTypeFilterFields.bedrooms, {
              ...bedroomsFilterVal,
              value: value + '',
            })
          }
          disableChange={!bedroomsFilterVal.enabled}
        />
      }
      isEnabled={bedroomsFilterVal.enabled}
      updateEnabled={(enabled) =>
        setFieldValue(propertyTypeFilterFields.bedrooms, {
          ...bedroomsFilterVal,
          enabled,
        })
      }
    />
  )

  const bathroomsFilterVal = values[propertyTypeFilterFields.bathrooms] || {
    enabled: false,
    value: '1',
  }

  const bathroomsElem = (
    <ChildFilter
      title="Bathrooms"
      input={
        <NumberInput
          value={parseInt(bathroomsFilterVal.value)}
          changeHandler={(value) =>
            setFieldValue(propertyTypeFilterFields.bathrooms, {
              ...bathroomsFilterVal,
              value: value + '',
            })
          }
          disableChange={!bathroomsFilterVal.enabled}
        />
      }
      isEnabled={bathroomsFilterVal.enabled}
      updateEnabled={(enabled) =>
        setFieldValue(propertyTypeFilterFields.bathrooms, {
          ...bathroomsFilterVal,
          enabled,
        })
      }
    />
  )

  const weeklyRentMinFilterVal = values[
    propertyTypeFilterFields.weeklyRentMin
  ] || {
    enabled: false,
    value: 100,
  }

  const weeklyRentMinElem = (
    <ChildFilter
      title="Rent pw min"
      input={
        <Input
          prefix="$"
          maxWidth="90px"
          inputProps={{
            value: weeklyRentMinFilterVal.value,
            onChange: ({ target: { value } }) =>
              setFieldValue(propertyTypeFilterFields.weeklyRentMin, {
                ...weeklyRentMinFilterVal,
                value: parseInt(value),
              }),
            type: 'number',
            width: '100%',
            disabled: !weeklyRentMinFilterVal.enabled,
          }}
        />
      }
      isEnabled={weeklyRentMinFilterVal.enabled}
      updateEnabled={(enabled) =>
        setFieldValue(propertyTypeFilterFields.weeklyRentMin, {
          ...weeklyRentMinFilterVal,
          enabled,
        })
      }
    />
  )

  const weeklyRentMaxFilterVal = values[
    propertyTypeFilterFields.weeklyRentMax
  ] || {
    enabled: false,
    value: 500,
  }

  const weeklyRentMaxElem = (
    <ChildFilter
      title="Rent pw max"
      input={
        <Input
          prefix="$"
          maxWidth="90px"
          inputProps={{
            value: weeklyRentMaxFilterVal.value,
            onChange: ({ target: { value } }) =>
              setFieldValue(propertyTypeFilterFields.weeklyRentMax, {
                ...weeklyRentMaxFilterVal,
                value: parseInt(value),
              }),
            type: 'number',
            width: '100%',
            disabled: !weeklyRentMaxFilterVal.enabled,
          }}
        />
      }
      isEnabled={weeklyRentMaxFilterVal.enabled}
      updateEnabled={(enabled) =>
        setFieldValue(propertyTypeFilterFields.weeklyRentMax, {
          ...weeklyRentMaxFilterVal,
          enabled,
        })
      }
    />
  )

  return (
    <Box minWidth="250px" maxHeight="300px" overflowY="auto">
      <DropdownBodySectionHeader header="Property Type" />
      <Box>
        {bedroomsElem}
        <HorizontalLine mb={theme.space[3] + 'px'} />

        {bathroomsElem}
        <HorizontalLine mb={theme.space[3] + 'px'} />

        {weeklyRentMinElem}
        <HorizontalLine mb={theme.space[3] + 'px'} />

        {weeklyRentMaxElem}
      </Box>
    </Box>
  )
}
