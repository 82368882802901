import React from 'react'

import { Link } from 'react-router-dom'

import { urlTo } from 'app/sm/helpers'

const ReviewedForm = ({ error, message, isVerified, dashboardUrl }) => {
  return (
    <div className="two-col-box-flex-row sm64">
      <div className="col-first">
        <div className={error ? 'pt20' : 'hidden'}>
          <h3> Oops! Something went wrong! </h3>

          <div className={error ? 'alert alert-danger' : ''}>{error}</div>

          <Link to={isVerified ? dashboardUrl : urlTo('join')}>
            <button type="button">
              {' '}
              Return to {isVerified ? 'Dashboard' : urlTo('join')}
            </button>
          </Link>
        </div>

        <div className={error ? 'hidden' : ''}>
          <div className={message ? 'alert alert-info pt20' : ''}>
            {message}
          </div>

          <div className={isVerified ? 'pt20' : 'hidden'}>
            <Link to={dashboardUrl}>
              <button type="button"> Return to Dashboard </button>
            </Link>
          </div>

          <div className={!isVerified ? 'pt20' : 'hidden'}>
            <Link to={urlTo('join')}>Register</Link> or{' '}
            <Link to={urlTo('join')}>Login</Link> to complete the approval
            process.
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewedForm
