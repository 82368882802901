import React from 'react'

import lockImg from 'app/assets/icons/apply_anywhere/lock.svg'
import arrowRightWhiteImg from 'app/assets/icons/arrow-right-white.svg'
import phoneImg from 'app/assets/icons/snug-phone.png'
import tickWhiteImg from 'app/assets/icons/tick-white.svg'
import { Box } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  Img,
  LinkList,
  LinkToSnugWrapper,
  MainHeading,
  MaxWidth,
  PhoneImgWrapper,
  SecurityTextWrapper,
  SemiHeading,
  Wrapper,
} from 'app/match/leadToSnug/styles'
import VideoDisplay from 'app/pages/teams/onboarding/components/video'
import SnugLogo from 'app/shared_components/snug_logo'
import * as textHelpers from 'app/utils/text/helpers'

const SiteInformation = ({ isAA, onClickFreeTrial }) => {
  return (
    <Wrapper
      darkTheme
      displayType={isAA && 'flex'}
      alignItems={isAA && 'center'}
      justifyContent={isAA && 'center'}
      flexDirection={isAA ? 'row' : 'column'}
    >
      <MaxWidth maxWidth={isAA && '530px'}>
        {isAA && (
          <SnugLogo height={24} className="mb20" wrapperClassName="snug-logo" />
        )}
        <MainHeading>Find &#38; select top tenants, fast.</MainHeading>
        <SemiHeading
          lineHeight={'31px'}
          lineHeightMobile={'22px'}
          fontSizeMobile={'16px'}
          fontSize={'22px'}
          margin={'16px 0 32px'}
          marginMobile={'12px 0 30px'}
        >
          Save time and costs with a streamlined, automated, verified leasing
          workflow.
        </SemiHeading>
        {isAA ? (
          <ul>
            {LinkToSnugList.map((ele, index) => {
              return (
                <LinkToSnug
                  key={index}
                  isAA={isAA}
                  link={ele.link}
                  text={ele.label}
                />
              )
            })}
          </ul>
        ) : (
          <ul>
            <LinkToSnug text="Automated Rental References" isLink={false} />
            <LinkToSnug text="Verified Employment" isLink={false} />
            <LinkToSnug text="100 points of ID" isLink={false} />
            <LinkToSnug text="Portal enquiry auto responder" isLink={false} />
            <LinkToSnug text="Inspection Bookings" isLink={false} />
            <LinkToSnug text="Application processing" isLink={false} />
            <LinkToSnug text="plus many more features" isLink={false} />
          </ul>
        )}
        {isAA && (
          <div>
            <LinkToSnugWrapper>
              <button type="button" onClick={onClickFreeTrial}>
                Start your free trial
                <ArrowRightSVG fill="white" />
              </button>
              <a href="https://snug.com/">
                Visit Snug.com
                <ArrowRightSVG fill="#A6F1C7" />
              </a>
            </LinkToSnugWrapper>
            <SecurityTextWrapper className="security">
              <img src={lockImg} alt="lock" />
              <p>
                {textHelpers.MarketingPMCountPlus} property managers trust Snug.
                <br />
                250,000+ Australians in Snug’s online rental community
              </p>
            </SecurityTextWrapper>
            <Box fontSize={4} pt={theme.baseSpace * 3 + 'px'}>
              {textHelpers.MarketingPMCountCaveatText}
            </Box>
          </div>
        )}
      </MaxWidth>
      <PhoneImgWrapper
        marginTop={!isAA && '80px'}
        marginLeft={isAA && '70px'}
        marginLeftMobile={isAA && 0}
        className="text-align-center"
      >
        {isAA ? (
          <Img
            src={phoneImg}
            alt="phone"
            maxWidth={'420px'}
            maxWidthMobile={'247px'}
          />
        ) : (
          <VideoDisplay height="250px" isVimeo vimeoId="745643484" />
        )}
      </PhoneImgWrapper>
    </Wrapper>
  )
}

export default SiteInformation

const ArrowRightSVG = ({ fill }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00065 0.833415L5.82565 2.00841L10.4757 6.66675L0.333984 6.66675L0.333984 8.33342L10.4757 8.33341L5.81732 12.9834L7.00065 14.1667L13.6673 7.50008L7.00065 0.833415Z"
        fill={fill}
      />
    </svg>
  )
}

const LinkToSnug = ({ link, text, isAA, isLink = true, children }) => {
  return (
    <LinkList width={isAA ? 'auto' : '130px'}>
      <img className="tick" src={tickWhiteImg} alt="tick" />
      {isLink ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span>{text}</span>
          <img
            className="arrow-right"
            src={arrowRightWhiteImg}
            alt="arrow-right"
          />
        </a>
      ) : (
        <span>{text}</span>
      )}
    </LinkList>
  )
}

const LinkToSnugList = [
  {
    link: '/',
    label: 'Referral management',
  },
  {
    link: 'https://snug.com/manager/features/#inspection-bookings',
    label: 'Viewing management',
  },
  {
    link: 'https://snug.com/manager/features/#enquiry-auto-responder',
    label: 'Enquiry management',
  },
  {
    link: 'https://snug.com/manager/features/#tenant-screening',
    label: 'Tenant screening',
  },
  {
    link: 'https://snug.com/manager/features/#applications',
    label: 'Applications',
  },
  {
    link: 'https://snug.com/manager/features/#integration-and-reports',
    label: 'Utility intergration',
  },
  {
    link: 'https://snug.com/manager/features/#application-processing',
    label: 'Lease offer',
  },
]
