export const STREAM_LINE_TEAM_URL = '/team/register'
export const GET_STARTED = 'GET_STARTED'
export const REGISTERING_TEAM = 'REGISTERING_TEAM'

export const Started = 'Started'
export const TeamCreated = 'TeamCreated'
export const TeamPropertiesImported = 'TeamPropertiesImported'
export const TeamInvitesEmailed = 'TeamInvitesEmailed'

export const VIEWING_STATUS = {
  TeamCreated: 'Creating team',
  TeamPropertiesImported: 'Properties imported',
  TeamInvitesEmailed: 'Team invites emailed',
}

export const HEADER_HELPER = {
  Started: 'We’ll do the initial setup for you.',
  TeamCreated: 'Importing current rental listings',
  TeamPropertiesImported: 'Emailing listing agent invites',
  TeamInvitesEmailed: 'We’ll do the initial setup for you.',
}

export const RegistrationTimeout = 2000
