import React from 'react'

import { Link } from 'react-router-dom'

function Status() {
  return (
    <div className="sm-shadow-card status">
      <div className="header xs-mb20">
        <h4>Status</h4>
      </div>
      <div className="content">
        <div className="table pb20">
          <div className="cell-m pr20">
            <div className="big-icon">
              <i className="icon-date"></i>
            </div>
          </div>
          <div className="cell-m-100 pb10">
            <h4 className="mb5">24 Jun 2017 (09:30pm)</h4>
            <span>Date opened</span>
            <i className="icon-dot border-color fs13 pl5 pr5"></i>
            <span>3 days ago</span>
          </div>
        </div>

        <div className="table pb20">
          <div className="cell-m pr20">
            <div className="big-icon blue-bg-light-rgba">
              <i className="icon-dot blue-color fs40"></i>
            </div>
          </div>
          <div className="cell-m-100 pb10">
            <h4 className="mb5">
              Pending Inspection{' '}
              <i className="icon-arrow-down gray-color fs18 ml10"></i>
            </h4>
            <span>Status</span>
          </div>
        </div>

        <div className="table">
          <div className="cell-m pr20">
            <div className="big-icon yellow-bg-light-rgba">
              <i className="fa fa-circle-o yellow-color fs24"></i>
            </div>
          </div>
          <div className="cell-m-100 pb10">
            <h4 className="mb5">
              Medium Priority{' '}
              <i className="icon-arrow-down gray-color fs18 ml10"></i>
            </h4>
            <span>Severity Level</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Status
