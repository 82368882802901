import React from 'react'

import { connect } from 'react-redux'

import {
  Alert,
  Box,
  LoadingSection,
} from 'app/components/design-system-components'
import { portalBrands } from 'app/constants/portals.constants'
import { PortalStatusBlock } from 'app/IntegrationPortals/components/StatusBlock'
import {
  activatePortal,
  deactivatePortal,
  getPortalStatus,
} from 'app/services/http/portals'
import * as snugNotifier from 'app/services/snugNotifier'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { loadingStatesIds } from 'app/utils/loading-states'

export const usePortalStatus = (currentTeam, portalBrand) => {
  const {
    state: status,
    setState: setStatus,
    loadingStates: statusLoadingStates,
    loadingStatesHelpers: statusLoadingStatesHelpers,
  } = useStateWithLoading(null)

  const loadPortalStatus = () => {
    statusLoadingStatesHelpers.startLoading()
    return getPortalStatus(currentTeam.guid, portalBrand)
      .then((statusInfo) => {
        setStatus(statusInfo)
        statusLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => {
        statusLoadingStatesHelpers.setError(err)
        return Promise.reject(err)
      })
  }

  return {
    portalStatusInfo: status,
    loadPortalStatus,
    portalStatusLoadingStates: statusLoadingStates,
  }
}

export const useActivatePortal = (currentTeam, portalBrand) => {
  const {
    loadingStates: activationLoadingStates,
    loadingStatesHelpers: activationLoadingStatesHelpers,
  } = useLoadingStates()

  const activate = (payload) => {
    activationLoadingStatesHelpers.startLoading()
    return activatePortal(currentTeam.guid, portalBrand, payload)
      .then((data) => {
        activationLoadingStatesHelpers.markDoneSuccessfully()
        if (data) {
          return Promise.resolve(data)
        }
        snugNotifier.success(`${portalBrand} activated successfully!`)
      })
      .catch((err) => {
        activationLoadingStatesHelpers.setError(err)
        return Promise.reject(err)
      })
  }

  return {
    activatePortal: activate,
    activatePortalLoadingStates: activationLoadingStates,
    activatePortalLoadingStatesHelpers: activationLoadingStatesHelpers,
  }
}

export const useDeactivatePortal = (currentTeam, portalBrand) => {
  const {
    loadingStates: deactivateLoadingStates,
    loadingStatesHelpers: deactivateLoadingStatesHelpers,
  } = useLoadingStates()

  const deactivate = () => {
    deactivateLoadingStatesHelpers.startLoading()
    return deactivatePortal(currentTeam.guid, portalBrand)
      .then(() => {
        deactivateLoadingStatesHelpers.markDoneSuccessfully()
        snugNotifier.success(`${portalBrand} deactivated successfully!`)
      })
      .catch((err) => {
        deactivateLoadingStatesHelpers.setError(err)
        return Promise.reject(err)
      })
  }

  return {
    deactivatePortal: deactivate,
    deactivatePortalLoadingStates: deactivateLoadingStates,
  }
}

const PortalActivity = ({
  portalBrand,
  logo,
  statusProps,
  activateProps,
  deactivateProps,
  activationInstructions,
}) => {
  const brand =
    Object.values(portalBrands).find(({ id }) => id === portalBrand) || {}

  const { manualActivationAllowed, manualDeactivationAllowed } = brand || {}

  const { statusInfo, statusLoadingStates, reloadStatusInfo } = statusProps
  const { status, activatedAt, activatedBy, activatedAgencyIDs } =
    statusInfo || {}

  const { activationFn, activateLoadingStates } = activateProps || {}
  const { deactivationFn, deactivateLoadingStates } = deactivateProps || {}

  const renderErrorMsg = (loadingStates) => (
    <Box>
      {loadingStates?.state === loadingStatesIds.ERROR && (
        <Alert variant="warningWithBg" mb={4}>
          {loadingStates.error.message}
        </Alert>
      )}
    </Box>
  )

  const activationErr = renderErrorMsg(activateLoadingStates)
  const deactivationErr = renderErrorMsg(deactivateLoadingStates)

  return (
    <>
      <LoadingSection
        actionHandler={() => reloadStatusInfo()}
        loadingState={statusLoadingStates}
      >
        <PortalStatusBlock
          onActivation={manualActivationAllowed && activationFn}
          activateLoadingStates={activateLoadingStates}
          onDeactivation={manualDeactivationAllowed && deactivationFn}
          deactivateLoadingStates={deactivateLoadingStates}
          status={status}
          activatedAt={activatedAt}
          activatedBy={activatedBy}
          activationInstructions={activationInstructions}
          activatedAgencyIDs={activatedAgencyIDs}
          logo={logo}
        />
        {activationErr}
        {deactivationErr}
      </LoadingSection>
    </>
  )
}

const mapStateToProps = ({ session }) => ({ currentTeam: session.currentTeam })

export default connect(mapStateToProps)(PortalActivity)
