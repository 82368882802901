import React from 'react'

import styled from 'styled-components'

import Flex from 'app/components/design-system-components/Flex'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledHR = styled(Flex)`
  align-items: center;
  p {
    color: ${theme.colors.gray400};
    font-weight: ${theme.fontWeights[6]};
    font-size: ${theme.fontSizes[4]}px;
  }

  .line {
    height: 1px;
    flex: 1;
    background-color: ${theme.colors.gray400};
  }
`

function Separator({ text }) {
  return (
    <StyledHR>
      <div className="line"></div>
      {text && (
        <Text mb={0} px={8}>
          {text}
        </Text>
      )}
      <div className="line"></div>
    </StyledHR>
  )
}

export default Separator
