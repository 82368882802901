import React from 'react'

import * as Text from 'app/components/display/text/text_component'
import * as Form from 'app/components/forms/forms'

export const Yes = 'Yes'
export const No = 'No'
export const trueStr = 'true'
export const falseStr = 'false'

export const withRatingFormBasicControls = (WrappedComponent) => {
  return class WithRatingFormBasicControls extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        controls: {},
      }
    }

    initializeQuestionsControls = (getAnswersState, setAnswersStateFn) => {
      const updateAnswer = (questionDef, value) => {
        setAnswersStateFn((answers) => ({
          ...answers,
          [questionDef.id]: {
            ...answers[questionDef.id],
            answerValue: value,
          },
        }))
      }

      const removeQuestion = (questionDef) => {
        setAnswersStateFn((answers) => {
          const copiedAnswers = { ...answers }
          delete copiedAnswers[questionDef.id]
          return {
            ...copiedAnswers,
          }
        })
      }

      const addQuestion = (questionDef) => {
        setAnswersStateFn((answers) => {
          return {
            ...answers,
            [questionDef.id]: this.initializeRatingQuestion(questionDef),
          }
        })
      }

      const buildReverseSimpleBooleanQuestion = ({
        questionDef,
        onTrueChecked,
        onFalseChecked,
        enableEdit = true,
        renterName,
      }) => {
        const answers = getAnswersState()
        return (
          <Form.YesOrNoRadioGroup
            id={questionDef.id}
            label={questionDef.description(renterName)}
            childrenWrapperClassName="pt0 mb30"
          >
            <Form.YesOrNoCheckbox
              id={No}
              label={No}
              checked={answers[questionDef.id].answerValue === falseStr}
              onChange={() =>
                onTrueChecked
                  ? onTrueChecked()
                  : updateAnswer(questionDef, falseStr)
              }
              componentClassName="yes left"
              enableEdit={enableEdit}
            />
            <Form.YesOrNoCheckbox
              id={Yes}
              label={Yes}
              checked={answers[questionDef.id].answerValue === trueStr}
              onChange={() =>
                onFalseChecked
                  ? onFalseChecked()
                  : updateAnswer(questionDef, trueStr)
              }
              componentClassName="no right"
              enableEdit={enableEdit}
            />
          </Form.YesOrNoRadioGroup>
        )
      }

      const buildMultiSelectQuestion = ({
        questionDef,
        onChange,
        options,
        enableEdit = true,
        renterName,
      }) => {
        return (
          <div className="mb30">
            <Text.Strong text={questionDef.description(renterName)} />
            <Form.MultiSelect
              options={options}
              className="p0"
              onChange={onChange}
              bottomSpacingClass="pb0"
              isEditable={enableEdit}
            />
          </div>
        )
      }

      const buildSimpleBooleanQuestion = ({
        questionDef,
        onTrueChecked,
        onFalseChecked,
        required = false,
        error = '',
        enableEdit = true,
        renterName,
      }) => {
        const answers = getAnswersState()
        return (
          <div className="mb30">
            <Form.YesOrNoRadioGroup
              id={questionDef.id}
              label={questionDef.description(renterName)}
              childrenWrapperClassName="pt0"
              error={error}
              required={required}
            >
              <Form.YesOrNoCheckbox
                id={Yes}
                label={Yes}
                checked={answers[questionDef.id].answerValue === trueStr}
                onChange={() =>
                  onTrueChecked
                    ? onTrueChecked()
                    : updateAnswer(questionDef, trueStr)
                }
                componentClassName="yes left"
                enableEdit={enableEdit}
              />
              <Form.YesOrNoCheckbox
                id={No}
                label={No}
                checked={answers[questionDef.id].answerValue === falseStr}
                onChange={() =>
                  onFalseChecked
                    ? onFalseChecked()
                    : updateAnswer(questionDef, falseStr)
                }
                componentClassName="no right"
                enableEdit={enableEdit}
              />
            </Form.YesOrNoRadioGroup>
          </div>
        )
      }

      const buildCommentsQuestion = ({
        questionDef,
        label,
        placeholder = 'Please provide more details',
        readOnly = false,
        renterName,
      }) => {
        const answers = getAnswersState()
        return (
          <div className="mb30">
            <Text.Strong text={label || questionDef.description(renterName)} />
            <Form.CommentArea
              value={answers[questionDef.id].answerValue}
              placeholder={placeholder}
              onChange={({ value }) => updateAnswer(questionDef, value)}
              id={questionDef.id}
              cols={50}
              rows={5}
              inputBoxClass="pt0"
              readOnly={readOnly}
            />
          </div>
        )
      }

      const controlFns = {
        updateAnswer,
        removeQuestion,
        addQuestion,
        buildMultiSelectQuestion,
        buildSimpleBooleanQuestion,
        buildReverseSimpleBooleanQuestion,
        buildCommentsQuestion,
      }

      this.setState(({ controls }) => ({
        controls: {
          ...controls,
          ...controlFns,
        },
      }))
    }

    initializeRatingQuestion = ({ id, type, defaultValue }) => {
      return {
        ratingCode: id,
        questionType: type,
        answerValue: defaultValue,
        isPrivate: true,
      }
    }

    render() {
      return (
        <WrappedComponent
          initializeQuestionsControls={this.initializeQuestionsControls.bind(
            this,
          )}
          initializeRatingQuestion={this.initializeRatingQuestion}
          {...this.state.controls}
          {...this.props}
        />
      )
    }
  }
}
