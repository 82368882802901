import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware } from 'redux'

import BondCoverMiddleware from 'app/bond_cover/bond_cover_middleware'
import DashboardMiddleware from 'app/dashboard/dashboard_middleware'
import { history } from 'app/shared_components/router'

const rm = routerMiddleware(history)
const bondCoverMiddleware = applyMiddleware(BondCoverMiddleware)
const dashboardMiddleware = applyMiddleware(DashboardMiddleware)
const routeMiddleware = applyMiddleware(rm)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  bondCoverMiddleware,
  dashboardMiddleware,
  routeMiddleware,
}
