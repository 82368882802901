import React from 'react'

const GreenStateTimings = () => (
  <div className="mt15">
    <p>
      We're accepting renter applications for BondCover in the ACT, NSW, SA, TAS
      and WA. Apply now to be first in the queue.
    </p>
  </div>
)

export default GreenStateTimings
