import { connect } from 'react-redux'

import {
  fetchRequestSummary,
  newBondCoverRequest,
  redirectToRenterWithdrawnPage,
  submit,
} from 'app/bond_cover/bond_cover_actions'
import HubFormContainer from 'app/bond_cover/renters/hub/hub_form_container'
import { setBackUrl } from 'app/shared_components/actions'

const mapStateToProbs = ({ bondCover, session }) => ({
  request: bondCover.request,
  currentLease: bondCover.currentLease,
  quote: bondCover.quote,
  manager: bondCover.manager,
  tenants: bondCover.tenants,
  signature: bondCover.signature,
  identityDoc: bondCover.identityDoc,
  bankAccount: bondCover.bankAccount,
  card: bondCover.card,
  currentUser: session.currentUser,
  apiError: bondCover.error,
})

const mapDispatchToProbs = (dispatch) => ({
  newBondCoverRequest: () => dispatch(newBondCoverRequest()),
  fetchRequestSummary: (requestID) => fetchRequestSummary(requestID),
  submit: (requestID) => submit(requestID),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
  redirectToRenterWithdrawnPage: (requestID) =>
    redirectToRenterWithdrawnPage(requestID),
})

export default connect(mapStateToProbs, mapDispatchToProbs)(HubFormContainer)
