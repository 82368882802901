import React from 'react'

import { Link } from 'react-router-dom'

import Completions from 'app/sm/common/completions'

function Timeline() {
  const MockData = [
    {
      ID: 41,
      CreatedAt: '2017-10-16T18:49:53.249612+02:00',
      UpdatedAt: '2017-10-16T18:49:53.249612+02:00',
      DeletedAt: null,
      guidID: '537bca87-3f6e-48dd-95c6-0b908bd4689c',
      event: 10,
    },
    {
      ID: 49,
      CreatedAt: '2017-10-18T18:21:28.48713+02:00',
      UpdatedAt: '2017-10-18T18:21:28.48713+02:00',
      DeletedAt: null,
      guidID: '33a51189-0331-44ed-beba-d9dc7d999d59',
      event: 40,
    },
    {
      ID: 59,
      CreatedAt: '2017-10-30T17:49:51.757015+01:00',
      UpdatedAt: '2017-10-30T17:49:51.757015+01:00',
      DeletedAt: null,
      guidID: '44e9dbd8-7bb9-4d5b-93dc-db731c45cc24',
      event: 70,
    },
    {
      ID: 59,
      CreatedAt: '2017-11-08T17:49:51.757015+01:00',
      UpdatedAt: '2017-10-30T17:49:51.757015+01:00',
      DeletedAt: null,
      guidID: '44e9dbd8-7bb9-4d5b-93dc-db731c45cc24',
      event: 80,
    },
    {
      ID: 59,
      CreatedAt: '2017-11-09T17:49:51.757015+01:00',
      UpdatedAt: '2017-10-30T17:49:51.757015+01:00',
      DeletedAt: null,
      guidID: '44e9dbd8-7bb9-4d5b-93dc-db731c45cc24',
      event: 90,
    },
    {
      ID: 59,
      CreatedAt: '2017-11-10T17:49:51.757015+01:00',
      UpdatedAt: '2017-10-30T17:49:51.757015+01:00',
      DeletedAt: null,
      guidID: '44e9dbd8-7bb9-4d5b-93dc-db731c45cc24',
      event: 100,
    },
  ]

  return (
    <div className="sm-property-lease-details-timeline">
      <div className="mb40">
        <h3 className="ibm pr30">Lease Timeline</h3>
        <button className="btn btn-gray btn-round btn-medium">
          <i className="icon-add"></i>
        </button>
      </div>
      <Completions Events={MockData} />
    </div>
  )
}

export default Timeline
