import React from 'react'

import { Box, Modal } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'

function LastAdminRemoveModal({ toggleConfirmRemovingLastAdminModal }) {
  return (
    <Modal
      modalHeading="Admin user required"
      modalBody={
        <Box style={{ lineHeight: theme.lineHeights.encrption }} mt={3}>
          At least one admin is required in a team. Please make another team
          member an admin before removing the admin role from this user.
        </Box>
      }
      showSecondaryButton={false}
      primaryLabel="OK"
      primaryAction={() => toggleConfirmRemovingLastAdminModal()}
      toggleModal={toggleConfirmRemovingLastAdminModal}
    />
  )
}

export default LastAdminRemoveModal
