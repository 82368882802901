import type { FunctionComponent } from 'react'
import type React from 'react'

import { Formik, FormikProps } from 'formik'

import {
  formFields,
  formValidation,
} from 'app/components/Ballot/Disclosures/form.utils'
import {
  BallotsHouseholdIncomeLimit,
  FormBody,
} from 'app/components/Ballot/Disclosures/Formbody'
import { Box } from 'app/components/design-system-components'

type BallotDisclosuresProps = {
  formRef: React.Ref<FormikProps<object>>
  ballotsHouseholdIncomeLimit: BallotsHouseholdIncomeLimit
  ballotsHouseholdIncomeRegion: string
  ballotDisclosuresAnswers: object
}

export const BallotDisclosures: FunctionComponent<BallotDisclosuresProps> = ({
  formRef,
  ballotsHouseholdIncomeLimit,
  ballotsHouseholdIncomeRegion,
  ballotDisclosuresAnswers,
}) => {
  const baseInitialValues =
    Object.keys(ballotDisclosuresAnswers).length !== 0
      ? ballotDisclosuresAnswers
      : {
          [formFields.acceptBallotTermsAndConditions]: false,
          [formFields.victorianResidentsOrAustralianCitizens]: false,
          [formFields.householdMeetsIncomeLimits]: false,
          [formFields.noHouseholdMembersOwnProperty]: false,
          [formFields.documentsWithinDays]: false,
          [formFields.householdCanMoveIn]: false,
        }

  return (
    <Box mt={6}>
      <Formik
        initialValues={baseInitialValues}
        onSubmit={() => {}}
        validate={formValidation}
        innerRef={formRef}
      >
        {() => (
          <FormBody
            ballotsHouseholdIncomeLimit={ballotsHouseholdIncomeLimit}
            ballotsHouseholdIncomeRegion={ballotsHouseholdIncomeRegion}
          />
        )}
      </Formik>
    </Box>
  )
}

export default BallotDisclosures
