import { Formik } from 'formik'

import {
  Box,
  Flex,
  HorizontalLine,
  IconButton,
} from 'app/components/design-system-components'
import { ClearRounded } from 'app/components/design-system-components/icons/content'
import { API_ERRORS_PROPERTY } from 'app/forms/FieldWrapper'
import { convertTransApiErrorToStatus } from 'app/forms/utils/convertTransApiErrorToStatus'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import { sendInviteToApply } from 'app/services/http/teams/properties'
import * as snugNotifier from 'app/services/snugNotifier'
import {
  formFields,
  formValidation,
  transformApiErrorToFields,
} from 'app/sm/ppp/property_components/share/InviteToApplyModal/form.utils'
import { FormBody } from 'app/sm/ppp/property_components/share/InviteToApplyModal/FormBody'

export const InviteToApplyModal = ({ property, onDismissClick, onSuccess }) => {
  const initialValues = {
    [formFields.recipient]: '',
    [formFields.message]: '',
  }
  const sendInvitation = (values, { setStatus }) => {
    setStatus({ apiErrors: {}, apiGeneralError: '' })
    const payload = {
      email: values[formFields.recipient],
      [formFields.message]: values[formFields.message],
    }
    const offerID = property.offers[0].guidID
    sendInviteToApply(property.agencyID, offerID, payload)
      .then((res) => {
        snugNotifier.success('Invite sent')
        onSuccess()
      })
      .catch(({ message, detailedError }) => {
        if (detailedError) {
          const transformedApiErrors = transformApiErrorToFields(detailedError)
          setStatus({
            [API_ERRORS_PROPERTY]:
              convertTransApiErrorToStatus(transformedApiErrors),
          })
        } else {
          setStatus({ apiGeneralError: message })
        }
      })
  }

  return (
    <Box className="modal-fullscreen" bg={theme.colors.overlay}>
      <ModalContentWrapper>
        <Flex width="100%" flex="1 1" overflowY="auto">
          <Box p={theme.space[7] + 'px'} width="100%">
            <>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb={theme.space[6] + 'px'}
              >
                <h3>Invite to apply</h3>
                <IconButton
                  IconCmp={ClearRounded}
                  iconProps={{ fontSize: theme.fontSizes[10] + 'px' }}
                  variant="flat"
                  onClick={onDismissClick}
                />
              </Flex>
              <HorizontalLine />
            </>
            <Box pb={theme.space[5] + 'px'}>
              <Formik
                initialValues={initialValues}
                onSubmit={sendInvitation}
                validate={formValidation}
                validateOnChange={true}
                validateOnBlur={true}
              >
                {(props) => <FormBody formBag={props} property={property} />}
              </Formik>
            </Box>
          </Box>
        </Flex>
      </ModalContentWrapper>
    </Box>
  )
}
