import React from 'react'

const Checkbox = ({
  className,
  disabled,
  label,
  value,
  onChange,
  id,
  spanClassName,
  componentClassName,
  readOnly = false,
}) => (
  <label id={id} className={`display-flex ${componentClassName}`}>
    <input
      type="checkbox"
      className={className}
      checked={value}
      onChange={onChange}
      disabled={disabled}
      readOnly={readOnly}
    />
    <span className={spanClassName ? spanClassName : 'gray-color fs16'}>
      {label}
    </span>
  </label>
)

export default Checkbox
