import React from 'react'

import idIcon from 'app/assets/icons/summary_icons/id.svg'
import {
  Alert,
  AlertSensitiveInfo,
  Box,
} from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import SummaryIdentityDocumentBox from 'app/match/apply/application_summary_before_submit/shared_components/identity_box/component'
import { SupportingIDDocuments, urlTo } from 'app/sm/helpers'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'
import { ApplicationDetailsNumbersComponent } from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'

const IdentityDocumentsContainer = ({
  componentClassName = '',
  identityDocs = [],
  supportingIdentityDocs = [],
  identityCheckPoints = 0,
  isIdentityDocumentsEditable = true,
  onEditClick,
  enableNote = false,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  displayedApplicant,
  section,
  id = '',
  toggleRequestInfoModal = undefined,
  agencyID = '',
  idDocsNotRequested,
  acknowledgeSupplyOfIDDocuments = false,
  showManagerAlertTextForIDDocs = false,
  ballotsEnabled = false,
  toggleDocumentsViewer = undefined,
}) => {
  const acknowledgementText = acknowledgeSupplyOfIDDocuments ? 'Yes' : 'No'
  const alertTextForRenter = (
    <Box>
      You will be required to provide 100 points of ID or equivalent if your
      application is successful. Existing ID information in your profile will
      not be shared.
    </Box>
  )
  const alertTextForPM = (
    <Box>
      100 points of ID or equivalent is required if the application is
      successful
    </Box>
  )
  let showIdDocs = true
  if (!isIdentityDocumentsEditable && idDocsNotRequested) {
    showIdDocs = false
  }
  if (ballotsEnabled) {
    showIdDocs = false
  }
  return (
    <div id={id} className={`container-component ${componentClassName}`}>
      <Display.ContainerHeader
        icon={idIcon}
        text="Identity Documents"
        isEditable={isIdentityDocumentsEditable}
        onEditClick={() =>
          onEditClick(
            `${urlTo('identityDocument')}?from=rentersummary${
              ballotsEnabled && `&ballot=true`
            }`,
          )
        }
        enableNote={enableNote}
        onNewNoteUpdated={onNewNoteUpdated}
        newNotesContent={newNotesContent}
        onNoteIconClicked={onNoteIconClicked}
        onNewNoteAdded={onNewNoteAdded}
        onNoteCrossClicked={onNoteCrossClicked}
        interpreteNoteResult={interpreteNoteResult}
        enableNotesButton={enableNotesButton}
        displayedApplicant={displayedApplicant}
        section={section}
        toggleRequestInfoModal={toggleRequestInfoModal}
      />
      {showIdDocs && (
        <>
          <AlertSensitiveInfo />
          <div>
            <Display.ProgressLine
              totalPoints={identityCheckPoints}
              recommendedPoints={100}
            >
              <div className="score-container">
                {identityCheckPoints}
                <sub> / 100 points of ID</sub>
              </div>
            </Display.ProgressLine>
            {identityDocs &&
              identityDocs.length > 0 &&
              identityDocs.map((idDoc) => {
                return (
                  <SummaryIdentityDocumentBox
                    identityDoc={idDoc}
                    key={idDoc.guidID}
                    readOnly
                    from="renterSummary"
                    toggleDocumentsViewer={toggleDocumentsViewer}
                  />
                )
              })}
            {identityDocs.length <= 0 && (
              <div className="application-container-padding pdf-font-size-10">
                Not provided
              </div>
            )}
          </div>
          <div className="application-identification-container application-income-proof-container width100 flex-direction-row">
            {SupportingIDDocuments.map((supportingDoc) => {
              const fileteredSupportingDocs =
                supportingIdentityDocs &&
                supportingIdentityDocs.filter(
                  (supportingIdentityDoc) =>
                    supportingIdentityDoc.documentType ===
                    supportingDoc.docType,
                )
              return (
                <div
                  className={`application-identification-sub-container width-50 ${
                    fileteredSupportingDocs.length === 0 && 'd-none'
                  }`}
                  key={supportingDoc.name}
                >
                  <div className="application-info-common-component">
                    <div className="title width100">
                      <Display.SectionMinorTitle text={supportingDoc.name} />
                      {fileteredSupportingDocs.map((doc) => {
                        return (
                          <AppDocumentListItemSecondVersion
                            titleClass="mt0"
                            document={doc}
                            key={doc.guidID}
                            isSensitive
                            toggleDocumentsViewer={
                              toggleDocumentsViewer
                                ? () => toggleDocumentsViewer(doc.guidID)
                                : undefined
                            }
                          />
                        )
                      })}
                      {fileteredSupportingDocs.length === 0 && (
                        <div className="no-info-provided">
                          No information provided
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
      {idDocsNotRequested && (
        <>
          <Alert variant="blueWithBg" mt={5}>
            {showManagerAlertTextForIDDocs
              ? alertTextForPM
              : alertTextForRenter}
          </Alert>
          <Box>
            <ApplicationDetailsNumbersComponent
              title="Acknowledgement"
              text={<Box ml={3}>{acknowledgementText}</Box>}
              componentClassName="mb20 mt10"
              titleClassName="mb0 mt5"
              titleAndTextClass="display-flex align-items-center"
              textClassName="mb0"
            />
          </Box>
        </>
      )}
    </div>
  )
}
export default IdentityDocumentsContainer
