import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import {
  Alert,
  Box,
  BoxInput,
  Button,
  Flex,
} from 'app/components/design-system-components'
import HorizontalTabs from 'app/components/design-system-components/HorizontalTabs'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { postStartedSuggestTeamMembers } from 'app/pages/teams/rental-references/helpers/http'
import * as snugNotifier from 'app/services/snugNotifier'
import Spinner from 'app/sm/common/spinner'
import { postInviteTeamMembers } from 'app/sm/team_viewing_preference/setup/api'
import { getSuggestedTeamMembersList } from 'app/sm/team_viewing_preference/setup/api'
import TeamList from 'app/sm/team_viewing_preference/setup/letsGetStarted/TeamList'
import AllTeamMembers from 'app/sm/team_viewing_preference/setup/TeamMembers/allTeamMembers'

const TeamMemberContainer = styled(Box)`
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  min-height: 400px;
  border-radius: 2px;
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  align-items: center;
`

const PAGINATION_LIMIT = 6

const TeamMemberListPages = ({
  list,
  current,
  setCurrent,
  teamGUID,
  invitedMembersEmail,
  setInvitedMembers,
}) => {
  const startIndex = current * PAGINATION_LIMIT - PAGINATION_LIMIT
  const endIndex =
    list.length < current * PAGINATION_LIMIT
      ? list.length - 1
      : current * PAGINATION_LIMIT - 1

  const viewList = list.filter((_, i) => startIndex <= i && i <= endIndex)

  const onClickInvite = (member) => {
    const { email, firstName = '', lastName = '' } = member
    const payload = {
      agencyUserInvites: [
        {
          firstName,
          email,
          lastName,
        },
      ],
    }

    return postInviteTeamMembers(teamGUID, payload)
      .then(() => {
        setInvitedMembers((oldState) => [...oldState, { email }])
        snugNotifier.success('Member successfully invited')
      })
      .catch((err) => {
        snugNotifier.error(err?.message || 'Failed to send Invite')
      })
  }

  const onClickPrevious = () => {
    if (current > 1) {
      setCurrent(current - 1)
    }
  }

  const onClickNext = () => {
    if ((current + 1) * PAGINATION_LIMIT - list.length < PAGINATION_LIMIT) {
      setCurrent(current + 1)
    }
  }

  return (
    <>
      <TeamList
        membersList={viewList}
        showInviteButtons={true}
        onClickInvite={onClickInvite}
        invitedMembersEmail={invitedMembersEmail}
      />
      {list.length > PAGINATION_LIMIT && (
        <PaginationContainer>
          <p>
            {startIndex + 1} to {endIndex + 1} of {list.length} results
          </p>
          <Flex>
            <Button onClick={onClickPrevious} variant="outline" mr="5">
              {'< Previous'}
            </Button>
            <Button onClick={onClickNext} variant="outline">
              {' '}
              {'Next >'}
            </Button>
          </Flex>
        </PaginationContainer>
      )}
    </>
  )
}

const SuggestedTeamMember = ({
  toggleInviteTeamMembersModal,
  teamGUID,
  setInitialInvite,
  fetchMemberList,
  invitedMembers = [],
  setInvitedMembers,
  suggestTeamMemberButtonClassName = '',
  setModalHeading,
  showSuggestTeamMembersAsLink = false,
}) => {
  const [isSuggestingMember, setIsSuggestingMember] = useState(false)
  const [isSuggestingMemberError, setIsSuggestingMemberError] = useState(false)
  const [activeTabGUID, setActiveTabGUID] = useState('2')
  const [suggestedTeamMembersList, setSuggestedTeamMembersList] = useState([])
  const [current, setCurrent] = useState(1)

  const invitedMembersEmail = suggestedTeamMembersList
    .filter((l) => invitedMembers.map((m) => m.email).includes(l.email))
    .map((l) => l.email)

  const [formDetails, setFormDetails] = useState({
    reaAgencyID: '',
    domainAgencyID: '',
  })

  const onClickTabInHorizontalTab = (tabGUID) => {
    setActiveTabGUID(tabGUID)
  }

  const payload = {
    rea_agency_id: formDetails.reaAgencyID,
    domain_agency_id: formDetails.domainAgencyID,
  }

  const changeInputValue = (field) => {
    return (data) => {
      setFormDetails({
        ...formDetails,
        [field]: data,
      })
    }
  }

  const fetchSuggestedMember = () => {
    setIsSuggestingMember(true)
    getSuggestedTeamMembersList(teamGUID)
      .then((data) => {
        fetchMemberList()
        if (data) {
          setSuggestedTeamMembersList(data)
        } else {
          setIsSuggestingMemberError(true)
        }

        setIsSuggestingMember(false)
      })
      .catch((error) => {
        setIsSuggestingMember(false)
        setIsSuggestingMemberError(true)
        snugNotifier.error(error.message)
      })
  }

  const submitClick = () => {
    setIsSuggestingMember(true)
    postStartedSuggestTeamMembers(teamGUID, payload)
      .then(() => {
        fetchSuggestedMember()
      })
      .catch((error) => {
        setIsSuggestingMember(false)
        snugNotifier.error(error.message)
      })
  }

  const inviteTeamMemberClick = () => {
    setInitialInvite('')
    toggleInviteTeamMembersModal()
  }

  useEffect(() => {
    if (isSuggestingMember) {
      setModalHeading('Add team members')
      return
    }

    if (!isSuggestingMemberError && suggestedTeamMembersList.length === 0) {
      setModalHeading('Add team members')
      return
    }

    if (isSuggestingMemberError || suggestedTeamMembersList.length > 0) {
      setModalHeading('Connect team members')
      return
    }
  }, [
    isSuggestingMember,
    isSuggestingMemberError,
    setModalHeading,
    suggestedTeamMembersList.length,
  ])

  return (
    <>
      <Button
        sizeVariant="large"
        onClick={inviteTeamMemberClick}
        className={suggestTeamMemberButtonClassName}
      >
        Invite New Team Member
      </Button>
      <Box mt={theme.space[5] + 'px'} mb={theme.space[5] + 'px'}>
        <HorizontalTabs
          tabHeaders={[
            { tabGUID: '1', label: 'Members' },
            { tabGUID: '2', label: 'Suggested' },
          ]}
          activeTabGUID={activeTabGUID}
          onClickTabInHorizontalTab={onClickTabInHorizontalTab}
        />
        <Box minHeight="200px">
          {activeTabGUID === '1' && (
            <div>
              <AllTeamMembers teamGUID={teamGUID} />
            </div>
          )}
          {activeTabGUID === '2' &&
            (suggestedTeamMembersList && suggestedTeamMembersList.length > 0 ? (
              <TeamMemberListPages
                list={suggestedTeamMembersList}
                current={current}
                setCurrent={setCurrent}
                teamGUID={teamGUID}
                fetchSuggestedMember={fetchSuggestedMember}
                invitedMembersEmail={invitedMembersEmail}
                setInvitedMembers={setInvitedMembers}
              />
            ) : (
              <TeamMemberContainer>
                {isSuggestingMember ? (
                  <Flex>
                    <Spinner />
                    <Alert>
                      One moment, we’re looking for suggested team members.
                    </Alert>
                  </Flex>
                ) : (
                  <>
                    <Alert>
                      {isSuggestingMemberError ? (
                        <>
                          {
                            'We were unable to find any suggested team members. Please re-check the details and try again or'
                          }{' '}
                          <Button
                            variant="linkBlue"
                            onClick={inviteTeamMemberClick}
                            sizeVariant="large"
                          >
                            Invite New Team Member
                          </Button>{' '}
                          {'‘s directly.'}
                        </>
                      ) : (
                        <>
                          {'Please'}{' '}
                          <Button
                            variant="linkBlue"
                            onClick={inviteTeamMemberClick}
                            sizeVariant="large"
                          >
                            Invite New Team Member
                          </Button>{' '}
                          {
                            'or add your REA and Domain Agency ID below and we will suggest team members to invite.'
                          }
                        </>
                      )}
                    </Alert>
                    <Box
                      ml={theme.space[5] + 'px'}
                      pt={theme.space[5] + 'px'}
                      mb={theme.space[5] + 'px'}
                    >
                      <Box>
                        <Box>
                          <BoxInput
                            labelText="REA Agency ID"
                            onChange={changeInputValue('reaAgencyID')}
                            placeholder="e.g. ABC123"
                            takeMaxWidth
                            required
                            initialValue={formDetails.reaAgencyID}
                          />
                        </Box>
                        <Box
                          mt={theme.space[5] + 'px'}
                          mb={theme.space[5] + 'px'}
                        >
                          <BoxInput
                            labelText="Domain Agency ID"
                            onChange={changeInputValue('domainAgencyID')}
                            placeholder="e.g. 123abc"
                            takeMaxWidth
                            initialValue={formDetails.domainAgencyID}
                          />
                        </Box>
                      </Box>
                      <Button
                        sizeVariant="large"
                        onClick={submitClick}
                        variant={
                          showSuggestTeamMembersAsLink
                            ? 'linkBlue'
                            : 'outlineSuccess'
                        }
                      >
                        Suggest team members
                      </Button>
                    </Box>
                  </>
                )}{' '}
              </TeamMemberContainer>
            ))}
        </Box>
      </Box>
    </>
  )
}

export default SuggestedTeamMember
