import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'

export const formFields = {
  message: 'message',
  owners: 'owners',
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    default:
      return {}
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
