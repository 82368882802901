import * as React from 'react'

function SvgFlightTakeoffRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.116 8.235c.8-.21 1.62.26 1.84 1.06.21.8-.27 1.62-1.07 1.84l-15.82 4.24c-.43.11-.89-.08-1.12-.47l-1.88-3.25a.492.492 0 01.3-.73l.33-.09c.31-.08.63-.01.88.18l1.57 1.24 4.97-1.33-3.44-5.96c-.35-.61-.03-1.39.65-1.58.36-.1.75-.01 1.02.25l6.46 6.02 5.31-1.42zm-16.73 10.42h17c.55 0 1 .45 1 1s-.45 1-1 1h-17c-.55 0-1-.45-1-1s.45-1 1-1z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFlightTakeoffRounded
