import React from 'react'

import classnames from 'classnames'
import PropTypes from 'prop-types'

import { Box } from 'app/components/design-system-components'

import 'app/match/applicationReportPDF/components/CustomCheckbox/style.scss'

const propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  isFixedWidth: PropTypes.bool,
  subLabel: PropTypes.string,
  width: PropTypes.number,
}

const typeView = {
  check: 'icon-check',
  cross: 'icon-cross-x',
  info: 'icon-question',
  null: 'icon-question',
}

const CustomCheckbox = ({ label, type, isFixedWidth, subLabel, width }) => {
  return (
    <div
      className={classnames('verified-checkbox-container', {
        'fixed-width': isFixedWidth,
      })}
      style={{ width: `${width}px` }}
    >
      {type !== null && (
        <div className="verified-checkbox">
          <i className={`icon ${typeView[type]}`}></i>
        </div>
      )}
      <Box ml={2}>
        <div className="verified-checkbox__label">
          <span>{label}</span>
          {subLabel && subLabel.length > 0 && (
            <div className="sublabel-container">
              <small className="text-gray small--alt">{subLabel}</small>
            </div>
          )}
        </div>
      </Box>
    </div>
  )
}

CustomCheckbox.propTypes = propTypes

export default CustomCheckbox
