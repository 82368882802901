import { api } from 'app/utils/api/helpers'

export const getIncomeTableForAgency = (agencyGUID, state = '') => {
  const queryString = state
    ? `jurisdiction=${state}&scheme=VARC`
    : `scheme=VARC`
  return api.get(`/sm/teams/${agencyGUID}/incometables?${queryString}`)
}

export const getIncomeTableForProperty = (agencyGUID, incomeTableGUID) => {
  return api.get(`/sm/teams/${agencyGUID}/incometables/${incomeTableGUID}`)
}
