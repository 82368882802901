import React, { useContext, useEffect, useState } from 'react'

import { recaptchaSiteKey } from 'config/env'
import ReCAPTCHA from 'react-google-recaptcha'

import { Box } from 'app/components/design-system-components/index'
import { JoinSnugContext } from 'app/session/join/JoinContext'

const Captcha = ({ captchaRef, size = 'normal' }) => {
  const { setGCaptcha } = useContext(JoinSnugContext)
  const DELAY = 1000
  const [load, setLoad] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoad(true)
    }, DELAY)
  }, [])

  return (
    <Box mt="12px">
      {size !== 'invisible' && (
        <label>Click below to confirm you're human.</label>
      )}
      <Box mt="10px">
        {load && (
          <ReCAPTCHA
            size={size}
            sitekey={recaptchaSiteKey}
            onChange={setGCaptcha}
            onExpired={() => setGCaptcha('')}
            ref={captchaRef}
          />
        )}
      </Box>
    </Box>
  )
}

export default Captcha
