import React, { useState } from 'react'

import { Formik } from 'formik'
import { Form } from 'formik'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import {
  Box,
  Button,
  ButtonWithLoading,
  Flex,
} from 'app/components/design-system-components'
import { HelpOutlineRounded } from 'app/components/design-system-components/icons/actions/index'
import { InputField } from 'app/components/design-system-components/inputs'
import { CheckboxInputField } from 'app/components/design-system-components/inputs/Checkboxes'
import { SelectField } from 'app/components/design-system-components/inputs/Select/Select'
import { StyledFieldWrapper } from 'app/features/teams/key-logger/components/StepLayoutContainer'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import { validateEmail } from 'app/shared_components/validations'
import { postInviteTeamMembers } from 'app/sm/team_viewing_preference/setup/api'
import { TEAM_ROLE_LEARN_MORE_LINK } from 'app/sm/team_viewing_preference/setup/helpers'

const StyledFormBody = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.baseSpace}px 0;
`

const StyledLink = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizes[5]}px;
`

export const keySetTypes = {
  admin: 'admin',
  casualInspector: 'casualInspector',
  leasingConsultant: 'leasingConsultant',
  propertyManager: 'propertyManager',
  bdm: 'bdm',
  teamLeader: 'teamLeader',
}

export const teamMemberTypes = {
  [keySetTypes.admin]: 'Team Admin (account owner/full access)',
  [keySetTypes.casualInspector]:
    'Casual Inspector (only enquiries and viewings)',
  [keySetTypes.leasingConsultant]: 'Leasing Consultant',
  [keySetTypes.propertyManager]: 'Property Manager',
  [keySetTypes.bdm]: 'BDM',
  [keySetTypes.teamLeader]: 'Team Leader',
}

const mappedTeamMemberOptions = Object.entries(teamMemberTypes).map(
  ([id, displayText]) => ({
    option: id,
    optionMeta: {
      id,
      displayText,
    },
  }),
)

const formFields = {
  email: 'email',
  teamMemberType: 'teamMemberType',
  isOffshore: 'isOffshore',
}

const initialFormValues = {
  email: '',
  teamMemberType: '',
  firstName: '',
  lastName: '',
  [formFields.isOffshore]: false,
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.email: {
      return {
        required: !val && 'Please enter an email',
        pattern: validateEmail(val)[0],
      }
    }
    case formFields.teamMemberType: {
      return {
        required: !val && 'Please select a type',
      }
    }
    default: {
      return {}
    }
  }
}

const FormFooter = ({ cancelAction, isLoading }) => {
  return (
    <Flex alignItems="center" mt={theme.space[7] + 'px'}>
      <Button
        type="button"
        variant="solidSecondary"
        flex="1 1 30%"
        onClick={cancelAction}
        mr={theme.space[3] + 'px'}
      >
        Cancel
      </Button>
      <ButtonWithLoading type="submit" flex="1 1 70%" loading={isLoading}>
        Invite
      </ButtonWithLoading>
    </Flex>
  )
}

const formValidation = formValidationBuilder(fieldValidation)

const offshoreUserTooltipMsg =
  'Allow offshore user to register with an offshore Mobile or Landline number'
const offshoreUserTooltipId = `offshore-user-tooltip`

const FormBody = ({
  teamId,
  formBag: { isValid, isSubmitting, dirty },
  markDirty,
  footer,
  cancelAction,
  isMemberInviteInProgress,
}) => {
  const onLearnMoreClick = () => {
    window.open(TEAM_ROLE_LEARN_MORE_LINK, '_blank')
  }

  const LearnMoreRender = (
    <StyledLink
      ml={theme.space[1] + 'px'}
      variant="linkBlue"
      onClick={onLearnMoreClick}
    >
      learn more
    </StyledLink>
  )

  return (
    <Form style={{ height: '100%', width: '100%' }}>
      <StyledFormBody>
        <StyledFieldWrapper
          id={formFields.email}
          name={formFields.email}
          label="TO"
          required
        >
          <InputField
            id={formFields.email}
            name={formFields.email}
            inputProps={{ placeholder: 'Add email/s' }}
          />
        </StyledFieldWrapper>

        <StyledFieldWrapper
          id={formFields.teamMemberType}
          name={formFields.teamMemberType}
          label="ROLE"
          required
        >
          <Flex mb={theme.space[5] + 'px'} alignItems="center">
            Invite users with their primary role and set access permissions.{' '}
            {LearnMoreRender}
          </Flex>
          <SelectField
            id={formFields.teamMemberType}
            name={formFields.teamMemberType}
            label="Select"
            options={mappedTeamMemberOptions}
            HintMessageCmp={() => (
              <Button
                ml={theme.space[3] + 'px'}
                my={theme.space[1] + 'px'}
                variant="linkBlue"
                onClick={onLearnMoreClick}
              >
                learn more
              </Button>
            )}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper
          id={formFields.isOffshore}
          name={formFields.isOffshore}
        >
          <Flex alignItems="center">
            <CheckboxInputField
              id={formFields.isOffshore}
              name={formFields.isOffshore}
            >
              Offshore user
            </CheckboxInputField>

            <Flex
              alignItems="center"
              fontSize={theme.fontSizes.pLarge}
              color={theme.colors.gray600}
            >
              <HelpOutlineRounded
                data-tooltip-content=""
                data-tooltip-id={offshoreUserTooltipId}
              />
            </Flex>
            <ReactTooltip
              id={offshoreUserTooltipId}
              data-type="info"
              place="top"
              className="tool-tip-style"
            >
              <Box maxWidth="222px" textAlign="center">
                {offshoreUserTooltipMsg}
              </Box>
            </ReactTooltip>
          </Flex>
        </StyledFieldWrapper>
      </StyledFormBody>
      <FormFooter
        cancelAction={cancelAction}
        isLoading={isMemberInviteInProgress}
      />
    </Form>
  )
}
const InviteTeamMemberForm = ({
  onSuccessfulInvite,
  teamGUID,
  successAction,
  cancelAction,
  initialInvite,
}) => {
  const [isMemberInviteInProgress, setIsMemberInviteInProgress] =
    useState(false)

  let initialValuesForForm = initialInvite
    ? { ...initialFormValues, ...initialInvite }
    : initialFormValues

  const onSubmitForm = (values) => {
    const { [formFields.isOffshore]: isOffshore } = values
    const { email, teamMemberType, firstName = '', lastName = '' } = values
    const { option } = teamMemberType
    if (!option) {
      snugNotifier.error('Please select a role')
    }
    if (!email) {
      snugNotifier.error('Email is required')
    }
    setIsMemberInviteInProgress(true)
    let isAdmin = false,
      isFinance = false,
      isLeasingConsultant = false

    switch (option) {
      case keySetTypes.admin:
        isAdmin = true
        break
      case keySetTypes.casualInspector:
        isLeasingConsultant = true
        break
      default:
        isAdmin = false
        isFinance = false
        isLeasingConsultant = false
    }

    const payload = {
      agencyUserInvites: [
        {
          firstName,
          email,
          lastName,
          isAdmin,
          isFinance,
          isLeasingConsultant,
          isOffshore,
        },
      ],
    }
    return postInviteTeamMembers(teamGUID, payload)
      .then(() => {
        snugNotifier.success('Member successfully invited')
        if (successAction) {
          successAction(email)
        }
      })
      .catch((err) => {
        snugNotifier.error(err?.message || 'Failed to send Invite')
      })
      .finally(() => setIsMemberInviteInProgress(false))
  }

  return (
    <Formik
      initialValues={initialValuesForForm}
      onSubmit={onSubmitForm}
      validate={formValidation}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {(props) => (
        <FormBody
          formBag={props}
          cancelAction={cancelAction}
          isMemberInviteInProgress={isMemberInviteInProgress}
        />
      )}
    </Formik>
  )
}

export default InviteTeamMemberForm
