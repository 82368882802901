import React, { Component } from 'react'

class WithdrawAdModal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { ok, withdrawModalOff } = this.props

    return (
      <div className="modal-fullscreen modal-ui">
        <div className="modal-wrapper">
          <div className="modal-header">
            <h1>Withdrawing an Advertisement</h1>
          </div>
          <div className="modal-body">
            <p>
              Withdrawing an advertisement cannot be undone and is not
              refundable if the ad has already been published.
            </p>
            <div className="modal-buttons">
              <button
                className="snug-button modal-cancel"
                onClick={() => withdrawModalOff()}
              >
                No
              </button>
              <button className="snug-button modal-accept" onClick={() => ok()}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WithdrawAdModal
