import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'

export const PdfPrintSection = styled(Box)`
  @media print {
    break-after: avoid !important;
    page-break-after: avoid !important;
    break-inside: avoid;
  }
`
