import React from 'react'

import moment from 'moment'

const Ratings = ({ ratings }) => {
  let ratingsHtml = (
    <div className="contentBlock">
      <header>
        <span>No Renter Rating Record Found</span>
      </header>
    </div>
  )

  function getRating(code, tenantRating, prop) {
    for (var i = 0; i < tenantRating.length; i++) {
      if (tenantRating[i][prop] === code) {
        return tenantRating[i].rating
      }
    }
    return -1
  }

  let stars = [1, 2, 3, 4, 5]

  if (ratings && ratings.length > 0) {
    ratingsHtml = (
      <div className="contentBlock">
        <header>
          <span>Ratings</span>
        </header>
        <content>
          {ratings.map((j) => {
            return (
              <div
                className="rating-categories-box"
                key={j.code + j.Description}
              >
                <div className="rating-desc"> {j.code} </div>
                {j.comment ? (
                  <div className="rating-comment">
                    {j.comment}
                    <br />
                    <br />
                  </div>
                ) : (
                  ''
                )}
                <div className="rating-stars" key={j.code}>
                  {stars.map((i) => {
                    let rating = getRating(j.code, ratings, 'code')
                    let key = i + 1
                    return (
                      <i
                        key={j.code + '.' + key}
                        className={
                          key <= rating ? 'icon-star rated' : 'icon-star'
                        }
                      ></i>
                    )
                  })}
                </div>
                <div className="rating-date">
                  {' '}
                  Date: {moment(j.date).format('Do MMM YYYY, h:mm a')}
                </div>
              </div>
            )
          })}
        </content>
      </div>
    )
  }
  return <div>{ratingsHtml}</div>
}

export default Ratings
