export const smsRegistrationConfirmation = 'smsRegistrationConfirmation'
export const smsViewingCreated = 'smsViewingCreated'
export const smsViewingChanges = 'smsViewingChanges'
export const smsEnNewCancelledViewing = 'smsEnNewCancelledViewing'
export const smsViewingAttendeeDayBeforeEnabled =
  'smsViewingAttendeeDayBeforeEnabled'
export const smsLeaseOffer = 'smsLeaseOffer'
export const smsRequestInformation = 'smsRequestInformation'
export const enableBackgroundCheckBundle = 'enableBackgroundCheckBundle'
export const enableKeyManagement = 'enableKeyManagement'
export const enableTravelTimeOptimisationOption =
  'enableTravelTimeOptimisationOption'
export const enableViewingExcludeCalendarEventsTime =
  'enableViewingExcludeCalendarEventsTime'

export const viewing_book_nearby_properties_enabled =
  'viewing_book_nearby_properties_enabled'

export const enable_assign_property_to_building =
  'enable_assign_property_to_building'

export const enquiry_sms_response = 'enquiry_sms_response'

export const userMFAConfigSms = 'user_mfa_config_sms'
export const userMFAConfigEmail = 'user_mfa_config_email'

export const paidPlans = [
  smsRegistrationConfirmation,
  smsViewingCreated,
  smsViewingChanges,
  smsEnNewCancelledViewing,
  smsViewingAttendeeDayBeforeEnabled,
  smsLeaseOffer,
  smsRequestInformation,
  enableBackgroundCheckBundle,
  enableKeyManagement,
  enableTravelTimeOptimisationOption,
  enableViewingExcludeCalendarEventsTime,
  enquiry_sms_response,
  userMFAConfigSms,
  userMFAConfigEmail,
]
