import React from 'react'

import styled from 'styled-components'

import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledSwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: ${theme.width[26]}px;
  height: ${theme.height[13]}px;
`

const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + span {
    background-color: ${theme.colors.success200};
  }
  &:checked + span:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: ${theme.colors.success600};
    left: -4px;
    bottom: 2px;
  }
`

const StyledSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.grey200};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: ${theme.radii[10]}px;
  height: unset !important;
  &::before {
    position: absolute;
    content: '';
    height: ${theme.height[16]}px;
    width: ${theme.width[27]}px;
    left: 2px;
    bottom: 2px;
    background-color: ${theme.colors.white};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    background-color: ${theme.colors.gray400};
  }
`

function SwitchButton({
  onChange,
  value,
  disabled,
  buttonID = '',
  labelClassName = '',
}) {
  return (
    <StyledSwitchLabel className={labelClassName}>
      <StyledInput
        type="checkbox"
        checked={value}
        onChange={onChange}
        id={buttonID}
        className={buttonID}
        disabled={disabled}
      />
      <StyledSlider class="slider" />
    </StyledSwitchLabel>
  )
}

export default SwitchButton
