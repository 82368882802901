import * as React from 'react'

function SvgPet(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.32 15.383c.262.743.661 1.335 1.212 1.754.53.403 1.189.64 1.98.707a3.58 3.58 0 006.202.883 3.58 3.58 0 006.203-.882c.79-.068 1.45-.305 1.98-.708.55-.42.95-1.01 1.212-1.754.398-1.129.47-2.205.47-4.812 0-1.95-1.116-3.635-2.898-4.825-1.782-1.19-4.241-1.896-6.967-1.896-2.725 0-5.184.705-6.966 1.896C2.965 6.936 1.85 8.62 1.85 10.57c0 2.607.071 3.683.47 4.812zm17.428-.48c-.158.45-.37.786-.652 1.027-.246.21-.554.354-.946.43v-2.074a.721.721 0 00-1.443 0v2.285a2.136 2.136 0 11-4.271 0v-1.564h.421a.721.721 0 100-1.443h-2.286a.721.721 0 000 1.443h.422v1.564a2.136 2.136 0 01-4.272 0v-2.285a.721.721 0 00-1.442 0v2.073c-.392-.075-.7-.219-.947-.43-.281-.24-.493-.577-.652-1.026-.317-.9-.387-1.865-.387-4.332 0-1.439.889-2.754 2.398-3.717C7.2 5.89 9.313 5.293 11.714 5.293s4.515.598 6.024 1.56c1.509.964 2.398 2.28 2.398 3.718 0 2.467-.07 3.431-.388 4.332zM7.564 10a1.007 1.007 0 102.015 0 1.007 1.007 0 00-2.015 0zm6.286 0a1.007 1.007 0 102.014 0 1.007 1.007 0 00-2.014 0z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.3}
      />
    </svg>
  )
}

export default SvgPet
