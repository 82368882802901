import React from 'react'

import iconErrorRed from 'app/assets/icons/error-red.svg'
import { GreySubheading } from 'app/components/display/text/standard_text/standard-headings/component'
import { LabelWrapper } from 'app/components/forms/util'

import 'app/components/forms/multi_select/style.scss'

const MultiSelect = (props) => {
  const {
    className = undefined,
    title = '',
    titleTextClass = '',
    isEditable = true,
    options,
    onChange,
    extraText = '',
    bottomSpacingClass = '',
    required = false,
    error = '',
  } = props
  return (
    <div
      className={`stand-form-multi-select-container p24 input-adder ${className}`}
    >
      {title && (
        <LabelWrapper required={required}>
          <GreySubheading
            text={title}
            componentClass="text-area-label"
            textClass={titleTextClass}
          />
        </LabelWrapper>
      )}

      <div
        className={`multi-select-container-bottom-spacing ${bottomSpacingClass}`}
      >
        <div className="multi-select-container-wrapper display-flex space-between">
          {options?.length > 0 &&
            options.map((option, index) => {
              const val = option.length || option.value
              const { picked, text } = option
              return (
                <div className="display-flex flex-direction-column width100">
                  <div
                    role="button"
                    tabIndex={0}
                    className={`flex width100 justify-content-center align-items-center multi-select-option-container ${
                      picked ? 'picked' : ''
                    } ${index === 0 && 'first'} ${
                      index === options.length - 1 && 'last'
                    }`}
                    key={val}
                    onClick={isEditable ? () => onChange(val) : undefined}
                  >
                    <div
                      name="lease"
                      value={val}
                      className={'text-align-center p10'}
                    >
                      <span className="option-text">
                        {val}
                        {extraText}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`text-grey font-size-12 text-align-center line-height-20 ${
                      picked ? 'fw700' : ''
                    }`}
                  >
                    {text}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      {error && (
        <div className="mt10">
          <img className="ibm pr10" src={iconErrorRed} height="25" alt="" />
          <error>{error}</error>
        </div>
      )}
    </div>
  )
}

export default MultiSelect
