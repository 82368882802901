import React from 'react'

import { Box, Modal } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'
import JoinSnugBanner from 'app/shared_components/join_snug_banner'
import * as gtmHelpers from 'app/utils/google-tag-manager/helpers'

function VerifyOrContinueAsGuestModal({
  toggleVerifyOrContinueAsGuestModal,
  toggleCompletedReferenceModal,
  toggleVerifyAccountModal,
  primaryActionConnectTeam,
  continueAsGuestAction,
  managerEmail = '',
}) {
  const primaryActionForModal = () => {
    toggleVerifyOrContinueAsGuestModal()
    primaryActionConnectTeam()
    toggleVerifyAccountModal()
  }
  const modalBody = (
    <Box fontSize={theme.fontSizes.pLarge16} mt={5}>
      <Box>
        Take 1 minute to verify for faster access to new and past references.
      </Box>
      <Box mt={6} mb={5}>
        Click Verify and Start Reference to send a verification code to:{' '}
        {managerEmail}
      </Box>
      <JoinSnugBanner />
    </Box>
  )
  return (
    <Modal
      modalBody={modalBody}
      secondaryLabel="Skip Verification"
      primaryLabel="Verify and Start Reference"
      toggleModal={toggleVerifyOrContinueAsGuestModal}
      secondaryAction={() => {
        continueAsGuestAction()
      }}
      primaryAction={primaryActionForModal}
      modalHeading="Start Rental Reference"
      secondaryButtonClassName={
        gtmHelpers.RR_LITE_JUNE_2023_GTM_CONTINUE_AS_GUEST
      }
      primaryButtonClassName={gtmHelpers.RR_LITE_JUNE_2023_GTM_VERIFY_BUTTON}
      secondaryButtonVariant="outlineSuccess"
    />
  )
}

export default VerifyOrContinueAsGuestModal
