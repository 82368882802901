import styled from 'styled-components'

import logo from 'app/assets/icons/snug-logo-big.png'
import gsBackground from 'app/assets/icons/streamline-background-gs.png'
import { Box, Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import GettingStarted from 'app/pages/SteamLineSeftServiceTeam/getting-started/component'
import RegisteringTeam from 'app/pages/SteamLineSeftServiceTeam/registering-team/component'
import type { AsyncFunction } from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_connection'
import {
  GET_STARTED,
  REGISTERING_TEAM,
} from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_const'
import StreamLineSSTeamContextProvider, {
  StreamLineSSTeamContext,
} from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_context'
import type { StreamLineRegisterPayload } from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_type'

const StreamLineContainer = styled(Flex)`
  justify-content: space-between;
  @media (max-width: 991px) {
    justify-content: center;
    height: 100vh !important;
  }
`

const StreamLineLeftContainer = styled(Flex)`
  min-width: 500px;
  margin: 0 auto;
  width: 40%;
  position: relative;
`

const StreamLineLeftBlock = styled(Box)`
  margin: auto;
`
const StreamLineImage = styled.div`
  width: 60%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${gsBackground});
  @media (max-width: 991px) {
    display: none;
  }
`

export const HeadingText = styled(Text)`
  font-size: ${({ theme }) => `${theme.fontSizes[16]}px`};
  font-weight: ${({ theme }) => `${theme.fontWeights[6]}`};
  color: ${({ theme }) => `${theme.colors.gray600}`};
  margin-bottom: 0px;
`

export const PageText = styled(Text)`
  max-width: 300px;
  color: ${theme.colors.gray600};
`

const FormContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
`

const Logo = styled.img`
  position: absolute;
  top: 5%;
`

interface IProps {
  searchAgencyDomain: AsyncFunction<string>
  registerTeamViaDomain: AsyncFunction<StreamLineRegisterPayload>
  getRegisterTeamViaDomainStatus: AsyncFunction<string>
}

const StreamLineSSTeamContainer = ({
  searchAgencyDomain,
  registerTeamViaDomain,
  getRegisterTeamViaDomainStatus,
}: IProps) => {
  return (
    <StreamLineSSTeamContextProvider
      getRegisterTeamViaDomainStatus={getRegisterTeamViaDomainStatus}
      registerTeamViaDomain={registerTeamViaDomain}
    >
      <StreamLineSSTeamContext.Consumer>
        {({
          currentStep,
          handleSubmitProperties,
          submitLoading,
          registeringStatus,
          registeringError,
        }) => (
          <StreamLineContainer>
            <StreamLineLeftContainer>
              <StreamLineLeftBlock>
                <Logo src={logo} alt="snug" />
                <FormContainer>
                  {currentStep === GET_STARTED && (
                    <GettingStarted
                      searchAgencyDomain={searchAgencyDomain}
                      handleSubmitProperties={handleSubmitProperties}
                      submitLoading={submitLoading}
                      submitError={registeringError}
                    />
                  )}
                  {currentStep === REGISTERING_TEAM && (
                    <RegisteringTeam
                      registeringStatus={registeringStatus}
                      registeringError={registeringError}
                    />
                  )}
                </FormContainer>
              </StreamLineLeftBlock>
            </StreamLineLeftContainer>

            <StreamLineImage />
          </StreamLineContainer>
        )}
      </StreamLineSSTeamContext.Consumer>
    </StreamLineSSTeamContextProvider>
  )
}

export default StreamLineSSTeamContainer
