import React from 'react'

class SorterDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sorters: [
        { id: 'rank', label: 'Rank' },
        { id: 'lease-start-date', label: 'Lease Start Date' },
        { id: 'weekly-rent', label: 'Weekly Rent' },
      ],
    }
  }

  render() {
    return (
      <div>
        <div className="ibm pr10">Sort by : </div>
        <div className="input-box single wa ibm pt0">
          <select
            required
            value={this.props.sortBy}
            onChange={(e) => this.props.onChange(e.target.value)}
          >
            {this.state.sorters.map((s) => (
              <option key={s.id} value={s.id}>
                {s.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }
}

export default SorterDropdown
