import { connect } from 'react-redux'

import {
  activatePMSIntegration,
  addAgencyBranding,
  adminSearchAgencies,
  clearError,
  deactivatePMSIntegration,
  deleteAgencyUser,
  fetchAgencyInfo,
  fetchAgencyPaymentGateways,
  fetchAgencyProfile,
  fetchAllPropertytreeTokens,
  fetchPropertytreeTokenForAgency,
  refreshtreeTokenForAgency,
  register,
  removeAgencyBranding,
  updateAgency,
  updateAgencyProfile,
} from 'app/bond_cover/agency/agency_actions'
import EditAgencyFormContainer from 'app/bond_cover/agency/edit_agency_form_container'

const mapStateToProps = ({ agency }) => ({
  error: agency.error,
  createError: agency.createError,
  registerError: agency.registerError,
  agency: agency.agency,
})

const mapDispatchToProps = (dispatch) => ({
  register: (user) => dispatch(register(user)),
  fetchAgencyProfile: (agencyGUID, fetchSuccess) =>
    dispatch(fetchAgencyProfile(agencyGUID, fetchSuccess)),
  updateAgency: (agency) => dispatch(updateAgency(agency)),
  updateAgencyProfile: (agencyProfile, updateSuccess) =>
    dispatch(updateAgencyProfile(agencyProfile, updateSuccess)),
  deleteAgencyUser: (agencyUser, agencyGUID, deleteSuccess) =>
    dispatch(deleteAgencyUser(agencyUser, agencyGUID, deleteSuccess)),
  clearError: () => dispatch(clearError()),
  fetchAgencyInfo: (agencyGUID) => dispatch(fetchAgencyInfo(agencyGUID)),
  searchAgencies: (q, pageSize, searchSuccess) =>
    dispatch(adminSearchAgencies(q, pageSize, searchSuccess)),
  addAgencyBranding: (logoType, agencyGUID, documentType) =>
    dispatch(addAgencyBranding(logoType, agencyGUID, documentType)),
  removeAgencyBranding: (logoType, agencyGUID) =>
    dispatch(removeAgencyBranding(logoType, agencyGUID)),
  fetchAgencyPaymentGateways: (agencyGUID) =>
    dispatch(fetchAgencyPaymentGateways(agencyGUID)),
  fetchPropertytreeTokenForAgency: (agencyGUID) =>
    dispatch(fetchPropertytreeTokenForAgency(agencyGUID)),
  fetchAllPropertytreeTokens: () => dispatch(fetchAllPropertytreeTokens()),
  refreshtreeTokenForAgency: (agencyGUID) =>
    dispatch(refreshtreeTokenForAgency(agencyGUID)),
  activatePMSIntegration: (agencyGUID, tokenAndAgency, pms) =>
    dispatch(activatePMSIntegration(agencyGUID, tokenAndAgency, pms)),
  deactivatePMSIntegration: (agencyGUID, pms) =>
    dispatch(deactivatePMSIntegration(agencyGUID, pms)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAgencyFormContainer)
