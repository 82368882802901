import React from 'react'

import { kolmeoPartnerGUID } from 'config/env'
import styled from 'styled-components'

import * as Form from 'app/components/forms/forms'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import * as helpers from 'app/sm/helpers'

const partnerDropdown = [
  { guid: '6ba78f12-75c3-11e8-a802-0242ac120002', name: 'AgentPoint' }, // From prod supplier source
  { guid: '69261a59-93fb-424d-8f09-461e0de2393e', name: 'Agentbox' }, // From prod supplier source
  { guid: 'ccbc40a7-9828-49f3-9091-29f022f59a61', name: 'Box+Dice' }, // From prod supplier source
  { guid: '8bce95f8-94a2-4f25-aff1-7485e1677535', name: 'All Homes' },
  { guid: 'a58f72dd-5895-42d7-8aaf-6dd5f5546473', name: 'Domain.com.au' },
  { guid: '6da4ea11-e5b1-4759-bba1-9f264df56032', name: 'Homely.com.au' },
  { guid: '8eecb736-36b8-4d4b-9262-fd26c6633d74', name: 'ListOnce' }, // From prod supplier source
  { guid: 'bc3be6cd-556e-41b8-9fd0-ab6502c210d1', name: 'MyDesktop' }, // From prod supplier source
  { guid: 'a5f204b6-6801-449a-9f71-5a636b436032', name: 'Realestate.com.au' },
  { guid: '1d0981dc-10e6-406f-914c-acf150e9b744', name: 'REIWA' },
  { guid: 'd9870626-8693-40dc-8c98-21eba2cfc37d', name: 'Rex' }, // From prod supplier source
  { guid: 'ead28419-f2b0-4017-b016-32c4289e608b', name: 'VaultRE' }, // From prod supplier source
  { guid: 'a70401c5-ba12-48f6-a406-64cc4ad7fc25', name: 'Zango' },
  {
    guid: '640d9693-a7bb-4d4c-ae65-84c866d58f42',
    name: 'Raine & Horne',
  },
  {
    guid: 'e486b4c2-f3fb-11ea-adc1-0242ac120002',
    name: 'Ray White',
  },
  { guid: '7de143a7-c038-48d5-826b-4a84215ff936', name: 'PropertyMe.com' },
  { guid: kolmeoPartnerGUID, name: 'Kolmeo.com' },
  { guid: 'b595a788-183f-4bb3-a35d-e6d3ecb3976e', name: 'ManagedApp.com.au' },
]

const PropertyLookupRowContainer = styled.div`
  display: flex;
  align-items: center;
  .blue-link-style {
    margin-left: 16px;
  }
  .blue-link-style.save-link {
    margin-left: 40px;
  }
  select {
    width: 256px;
  }
  .standard-form-components .standard-inputbox {
    width: 192px;
    margin-right: 8px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    .blue-link-style {
      margin-left: 0;
    }
    .blue-link-style.save-link {
      margin-left: 0;
    }
    .margin-profile-item-left {
      width: 100%;
    }
  }
`

const PropertyLookupContainer = styled.div`
  clear: both;
`

function PropertyLookupRow(props) {
  const {
    updateAddedPropertyLookup,
    onAddButtonClicked,
    currentPropertyLookupValues,
    isEditable = false,
    onRemoveButtonClicked,
    modifiedPartnerGUIDDropdown,
    actionOccuring,
    actionOccuringForGUID,
    propertyLookupAddition,
  } = props
  return (
    <div>
      <PropertyLookupRowContainer>
        <Form.Dropdown
          label="Source"
          value={
            currentPropertyLookupValues.partnerGUID ||
            currentPropertyLookupValues.guid
          }
          options={modifiedPartnerGUIDDropdown}
          onChange={updateAddedPropertyLookup('guid')}
          inputClass="width100"
          componentClass="margin-profile-item-left"
          idAsValue={true}
          selectIsDisabled={!isEditable}
          colorClass={!isEditable ? 'border-color-grey ' : ''}
        />
        <Form.InputTextOptional
          value={
            currentPropertyLookupValues.agencyID ||
            currentPropertyLookupValues.externalAgencyID ||
            ''
          }
          label={'Agency ID'}
          onChange={updateAddedPropertyLookup('agencyID')}
          labelClass="top18"
          inputClass="width100 mt0"
          componentClass="width100"
          disabled={!isEditable}
          colorClass={!isEditable ? 'border-color-grey ' : ''}
        />
        <Form.InputTextOptional
          value={
            currentPropertyLookupValues.value ||
            currentPropertyLookupValues.externalListingID ||
            ''
          }
          label={'Listing ID'}
          onChange={updateAddedPropertyLookup('value')}
          labelClass="top18"
          inputClass="width100 mt0"
          componentClass="width100"
          disabled={!isEditable}
          colorClass={!isEditable ? 'border-color-grey ' : ''}
        />
        {isEditable ? (
          <div className="display-flex">
            <span
              onClick={() => onAddButtonClicked()}
              className="blue-link-style save-link"
            >
              Save
            </span>
            {propertyLookupAddition && (
              <i className="ml5 fa fa-spinner fa-pulse" />
            )}
          </div>
        ) : (
          <div className="display-flex">
            <span
              onClick={() =>
                onRemoveButtonClicked(currentPropertyLookupValues.guid)
              }
              className="blue-link-style"
            >
              Remove
            </span>
            {actionOccuring &&
              currentPropertyLookupValues.guid === actionOccuringForGUID && (
                <i className="ml5 fa fa-spinner fa-pulse" />
              )}
          </div>
        )}
      </PropertyLookupRowContainer>
    </div>
  )
}

class PropertyLookup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasPreviousPropertyLookup: false,
      addedPropertyLookup: [],
      currentPropertyLookupValues: {
        guid: '',
        value: '',
        agencyID: '',
      },
      partnerGUIDDropdown: partnerDropdown,
      addAnotherRow: false,
      actionOccuring: false,
      actionOccuringForGUID: '',
      propertyLookupAddition: false,
    }
  }

  componentDidMount() {
    const { fetchPropertyLookup, guidDetails } = this.props
    const { offerGUID, agencyGUID } = guidDetails
    if (fetchPropertyLookup && offerGUID && agencyGUID) {
      fetchPropertyLookup(agencyGUID, offerGUID).then((data) => {
        if (data) {
          this.setState(
            {
              addedPropertyLookup: data,
              hasPreviousPropertyLookup: true,
            },
            () => {
              this.removeExistingPartners()
            },
          )
        }
      })
    }
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.addedPropertyLookup.length !==
      this.state.addedPropertyLookup.length
    ) {
      this.removeExistingPartners()
    }
  }

  onAddButtonClicked = () => {
    const { currentPropertyLookupValues } = this.state
    const { addPropertyLookup, guidDetails = {} } = this.props || {}
    const { offerGUID = '', agencyGUID = '' } = guidDetails
    if (
      !currentPropertyLookupValues.guid ||
      !currentPropertyLookupValues.value ||
      !currentPropertyLookupValues.agencyID
    ) {
      snugNotifier.error('Please add the value first')
      return
    }
    const payload = {
      partnerGUID: currentPropertyLookupValues.guid,
      listingID: currentPropertyLookupValues.value,
      agencyID: currentPropertyLookupValues.agencyID,
    }
    this.setState({ propertyLookupAddition: true })

    addPropertyLookup(agencyGUID, offerGUID, payload)
      .then((data) =>
        this.setState({
          addedPropertyLookup: [...this.state.addedPropertyLookup, data],
          currentPropertyLookupValues: {
            guid: '',
            value: '',
            agencyID: '',
          },
          addAnotherRow: false,
        }),
      )
      .catch((error) => snugNotifier.error(error))
      .finally(this.setState({ propertyLookupAddition: false }))
  }

  onRemoveButtonClicked = (propertyLookupGUID) => {
    const { addedPropertyLookup } = this.state
    this.setState({
      actionOccuring: true,
      actionOccuringForGUID: propertyLookupGUID,
    })
    if (!addedPropertyLookup.length) {
      return
    }
    const { guidDetails, removePropertyLookup } = this.props
    const { offerGUID, agencyGUID } = guidDetails
    removePropertyLookup(agencyGUID, offerGUID, propertyLookupGUID)
      .then(() => {
        const modifiedAddedPropertyLookup = addedPropertyLookup.filter(
          (lookup) => {
            return lookup.guid !== propertyLookupGUID
          },
        )
        this.setState({
          addedPropertyLookup: modifiedAddedPropertyLookup,
        })
      })
      .catch((error) => snugNotifier.error(error))
      .finally(() => this.setState({ actionOccuring: false }))
  }

  addAnotherRowClicked = (addAnotherRow) => {
    this.setState({
      addAnotherRow: !addAnotherRow,
    })
  }

  goBack = () => {
    const { slug = '' } = this.props
    history.push(helpers.urlTo('teamsViewing', { teamSlug: slug }))
  }

  isFormEmpty = () => {
    const { currentPropertyLookupValues, addAnotherRow } = this.state
    const {
      guid = '',
      value = '',
      agencyID = '',
    } = currentPropertyLookupValues || {}
    return (
      guid.length > 0 ||
      value.length > 0 ||
      agencyID.length > 0 ||
      addAnotherRow
    )
  }

  removeExistingPartners = () => {
    const { addedPropertyLookup } = this.state
    const arrayOfPartnerGUIDs = addedPropertyLookup.map(
      (partner) => partner.partnerGUID,
    )
    const removedPartnerGUIDs = partnerDropdown.filter(
      (partner) => !arrayOfPartnerGUIDs.includes(partner.guid),
    )
    this.setState({
      partnerGUIDDropdown: removedPartnerGUIDs,
    })
  }

  updateAddedPropertyLookup = (field) => {
    return (data) => {
      this.setState({
        currentPropertyLookupValues: {
          ...this.state.currentPropertyLookupValues,
          [field]: data.value,
        },
      })
    }
  }

  render() {
    const {
      hasPreviousPropertyLookup,
      currentPropertyLookupValues,
      addedPropertyLookup,
      addAnotherRow,
      partnerGUIDDropdown,
      actionOccuring,
      actionOccuringForGUID,
      propertyLookupAddition,
    } = this.state
    const unsavedChanges = this.isFormEmpty()
    return (
      <PropertyLookupContainer>
        <h5 className="mt20">External Listing Identifiers</h5>
        <div className="mt10 mb20">
          Route enquiries by adding the appropriate external Agency and
          Listing/Unique ID.
        </div>
        {addedPropertyLookup &&
          hasPreviousPropertyLookup &&
          addedPropertyLookup.map((propertyLookup) => {
            return (
              <PropertyLookupRow
                onAddButtonClicked={this.onAddButtonClicked}
                updateAddedPropertyLookup={this.updateAddedPropertyLookup}
                currentPropertyLookupValues={propertyLookup}
                onRemoveButtonClicked={this.onRemoveButtonClicked}
                modifiedPartnerGUIDDropdown={partnerDropdown}
                actionOccuring={actionOccuring}
                actionOccuringForGUID={actionOccuringForGUID}
              />
            )
          })}
        {addAnotherRow && (
          <PropertyLookupRow
            onAddButtonClicked={this.onAddButtonClicked}
            updateAddedPropertyLookup={this.updateAddedPropertyLookup}
            currentPropertyLookupValues={currentPropertyLookupValues}
            isEditable={true}
            onRemoveButtonClicked={this.onRemoveButtonClicked}
            modifiedPartnerGUIDDropdown={partnerGUIDDropdown}
            propertyLookupAddition={propertyLookupAddition}
          />
        )}
        <div
          onClick={() => this.addAnotherRowClicked(addAnotherRow)}
          className="blue-link-style mt25"
        >
          {addAnotherRow ? 'Remove' : 'Add another'}
        </div>
        <UnsavedMessage unsavedChanges={unsavedChanges} />
      </PropertyLookupContainer>
    )
  }
}

export default PropertyLookup
