import React from 'react'

import moment from 'moment'
import qs from 'qs'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'

import {
  google_map_markers,
  MARKER_LETTER_NUMBER,
} from 'app/assets/icons/google-maps-markers'
import {
  Alert,
  Box,
  Button,
  ButtonWithLoading,
  Flex,
} from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { OppositeSidesContainer } from 'app/pages/teams/viewings-mobile/styles'
import {
  MODE_DRIVING_NEW_VERSION,
  MODE_WALKING_NEW_VERSION,
  modeDriving,
} from 'app/pages/teams/viewings-run/components/schedule-list'
import StyledBackButton from 'app/pages/teams/viewings-run/components/styled/back-button'
import HorizontalTabs from 'app/pages/teams/viewings-run/components/tabs'
import {
  createViewingRun,
  updateViewingRun,
} from 'app/services/http/viewing-runs'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import { findChosenTeamBySlug, routes, urlIds, urlTo } from 'app/sm/helpers'
import PublicHeader from 'app/sm/viewings_new_run/header/component'
import PropertyItem, {
  PropertyItemListContainer,
} from 'app/sm/viewings_new_run/property_item/component'
import * as timeHelpers from 'app/utils/datetime/helpers'
import {
  HUMAN_READABLE_DATE,
  nanoSecToMinute,
} from 'app/utils/datetime/helpers'
import { isEmpty } from 'app/utils/objects/helpers'
import * as stringHelpers from 'app/utils/strings/helpers'
import { isViewingRunDraft } from 'app/utils/viewings/helpers'

const center = { lat: -33.87, lng: 151.2 }
const markerLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123'
const strokeColors = ['blue', 'red', 'green', 'yellow', 'darkcyan', 'orange']
// maxInspectorProperties The maximum allowed waypoints for this request is 25, plus the origin, and destination.
const MAX_INSPECTOR_PROPERTIES = 27

const MINUTE_ALIGNER = 5

const defaultPropertyInfo = {
  viewingsPublished: true,
}

const CTAButtonWithFixedWidth = styled(Button)`
  width: ${theme.width[12]}px;
`

class PreviewContainer extends React.Component {
  constructor(props) {
    super(props)

    const {
      match: { path },
    } = props

    const isEditMode = path === routes.viewingRuns.editPreview
    this.state = {
      isEditMode,
      isRouteUpdated: false,
      activeManagerTabGUID: '',
      allManagersSelected: [],
      managerViewingPreferences: {},
      managerViewingPreferencesInitial: {},
      viewingRunDate: '',
      isPreviewFromExistingRun: false,
      disableAndShowSpinner: false,
      errorPropertyGUID: '',
      isRunDraft: true,
      showViewingSettingsNotice: false,
      viewingSettingsNoticeContent: '',
    }
  }

  componentDidMount() {
    const { isEditMode } = this.state
    if (isEditMode) this.editModeGuard()

    const { fetchTeamSettingInfo } = this.props
    const { guid } = this.getChosenTeam()
    if (guid) {
      fetchTeamSettingInfo(guid, 'viewings')
        .then((teamSettingInfo) => {
          const { viewings } = teamSettingInfo
          const {
            viewing_cap_enabled,
            viewing_slot_enabled,
            viewing_cap,
            viewing_slot,
          } = viewings
          if (
            viewing_cap_enabled === 'true' ||
            viewing_slot_enabled === 'true'
          ) {
            let viewingSettingsNoticeContent = ''
            if (viewing_cap_enabled) {
              viewingSettingsNoticeContent = `Viewings Cap of ${viewing_cap} is enabled`
            }
            if (viewing_slot_enabled) {
              viewingSettingsNoticeContent = `${viewing_slot} minute Viewing Slots are enabled`
            }
            this.setState({
              showViewingSettingsNotice: true,
              viewingSettingsNoticeContent,
            })
          }
          // const parsedApplicationSettingInfo =
          //   helpers.parseDataUtil.convertObjValueFromStringToBoolean(
          //     applicationSettingInfo,
          //   )
          // this.setState({ parsedApplicationSettingInfo })
        })
        .catch((error) => {
          this.setState({ error })
        })
    }
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { changesToEditMade = 'false' } = curQueries
    const changesMade = changesToEditMade === 'true'
    if (guid && this.props.match.params.viewingRunGUID && !changesMade) {
      this.fetchViewingRunInfoFromGUID(this.props.match.params.viewingRunGUID)
      return
    }
    if (
      !this.props.match.params.viewingRunGUID ||
      (isEditMode && changesMade)
    ) {
      this.fetchPreflightResponse()
    }
  }

  componentDidUpdate(prevProps) {
    const { guid } = this.getChosenTeam()
    const { fetchTeamSettingInfo } = this.props

    if (
      guid &&
      this.props.match.params.viewingRunGUID &&
      prevProps.teams !== this.props.teams
    ) {
      if (guid) {
        fetchTeamSettingInfo(guid, 'viewings')
          .then((teamSettingInfo) => {
            // const parsedApplicationSettingInfo =
            //   helpers.parseDataUtil.convertObjValueFromStringToBoolean(
            //     applicationSettingInfo,
            //   )
            // this.setState({ parsedApplicationSettingInfo })
          })
          .catch((error) => {
            this.setState({ error })
          })
      }
      this.fetchViewingRunInfoFromGUID(this.props.match.params.viewingRunGUID)
      return
    }
    if (
      prevProps.managerPreferences !== this.props.managerPreferences &&
      !this.props.match.params.viewingRunGUID
    ) {
      this.fetchPreflightResponse()
    }
  }

  editModeGuard() {
    const { managerPreferences, history, match } = this.props
    if (isEmpty(managerPreferences)) {
      history.push(
        urlTo(urlIds.viewingRuns.edit, {
          teamSlug: match.params.teamSlug,
          id: match.params.viewingRunGUID,
        }),
      )
    }
  }

  onClickTabInHorizontalTab = (managerGUID) => {
    this.setState({
      activeManagerTabGUID: managerGUID,
    })
  }

  onDeleteButtonClicked = (managerGUID, propertyGUID) => {
    if (!window.confirm('Do you want to delete this viewing?')) {
      return
    }
    const { routedProperties, managerViewingPreferences } = this.state
    const routedPropertiesForManager = routedProperties[managerGUID]
    if (routedPropertiesForManager.length === 2) {
      window.alert('Cannot delete. You need to have more than 2 properties.')
      return
    }
    let managerViewingPreferencesWithDeletedproperty = managerViewingPreferences
    delete managerViewingPreferencesWithDeletedproperty[managerGUID][
      propertyGUID
    ]
    this.setState(
      {
        routedProperties: {
          ...routedProperties,
          [managerGUID]: routedPropertiesForManager.filter(
            (routedProperty) => routedProperty !== propertyGUID,
          ),
        },
        managerViewingPreferences: managerViewingPreferencesWithDeletedproperty,
      },
      () => this.fetchPreflightResponseAfterInitial(),
    )
  }

  onDragEndHandler = (result, managerGUID) => {
    const { routedProperties } = this.state
    const { destination, source, draggableId } = result
    if (!destination) {
      return
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const newOrderedPropertyGUIDs = [...routedProperties[managerGUID]]
    newOrderedPropertyGUIDs.splice(source.index, 1)
    newOrderedPropertyGUIDs.splice(destination.index, 0, draggableId)

    this.setState(
      {
        routedProperties: {
          ...this.state.routedProperties,
          [managerGUID]: newOrderedPropertyGUIDs,
        },
      },
      () => this.fetchPreflightResponseAfterInitial(false, true),
    )
  }

  getChosenTeam = () => {
    const { teams } = this.props
    const { teamSlug = '' } = this.props.match.params
    return findChosenTeamBySlug(teams, teamSlug) || {}
  }

  changeManagerViewingPreference = (
    managerGUID,
    propertyGUID,
    field,
    value,
  ) => {
    const {
      managerViewingPreferences,
      managerViewingPreferencesInitial,
      errorPropertyGUID,
    } = this.state
    if (field === 'editable' && value === true) {
      this.setState({
        editClicked: true,
      })
    }
    if (field === 'viewingDuration') {
      if (isNaN(value)) return
    }

    if (field === 'breakDuration') {
      if (isNaN(value)) return
    }

    this.setState(
      {
        managerViewingPreferences: {
          ...managerViewingPreferences,
          [managerGUID]: {
            ...managerViewingPreferences[managerGUID],
            [propertyGUID]: {
              ...managerViewingPreferences[managerGUID][propertyGUID],
              [field]: value,
            },
          },
        },
      },
      () => {
        if (field === 'editable' && value === false) {
          this.setState({
            editClicked: false,
          })
          const { managerViewingPreferences: managerViewingPreferencesFinal } =
            this.state

          const viewingDurationInitial = parseInt(
            managerViewingPreferencesInitial[managerGUID][propertyGUID][
              'viewingDuration'
            ],
          )

          const viewingTimeInitial = moment(
            managerViewingPreferencesInitial[managerGUID][propertyGUID][
              'startTime'
            ],
          )

          const breakDurationInitial =
            managerViewingPreferencesInitial[managerGUID][propertyGUID][
              'breakDuration'
            ]
          const differenceBetweenInitalAndNewDuration =
            parseInt(
              managerViewingPreferencesFinal[managerGUID][propertyGUID][
                'viewingDuration'
              ],
            ) - viewingDurationInitial

          const differenceBetweenInitialAndNewViewingStartTime = moment
            .duration(
              moment(
                managerViewingPreferencesFinal[managerGUID][propertyGUID][
                  'startTime'
                ],
              ).diff(viewingTimeInitial),
            )
            .asMinutes()

          const differenceBetweenInitialAndNewBreakDuration =
            managerViewingPreferencesFinal[managerGUID][propertyGUID][
              'breakDuration'
            ] - breakDurationInitial

          let changedManagerViewingPreferences = managerViewingPreferencesFinal

          const allPropertiesFollowingTheModifiedProperty = Object.keys(
            managerViewingPreferencesInitial[managerGUID],
          ).slice(
            Object.keys(managerViewingPreferencesInitial[managerGUID]).indexOf(
              propertyGUID,
            ) + 1,
          )

          allPropertiesFollowingTheModifiedProperty.forEach((propertyGUID) => {
            if (
              managerViewingPreferencesInitial[managerGUID][propertyGUID][
                'startTime'
              ]
            ) {
              let additionalMinutes = moment(
                managerViewingPreferencesInitial[managerGUID][propertyGUID][
                  'startTime'
                ],
              ).add(differenceBetweenInitalAndNewDuration, 'minutes')
              additionalMinutes = moment(additionalMinutes).add(
                differenceBetweenInitialAndNewViewingStartTime,
                'minutes',
              )
              additionalMinutes = moment(additionalMinutes).add(
                differenceBetweenInitialAndNewBreakDuration,
                'minutes',
              )
              const remainder =
                (MINUTE_ALIGNER - additionalMinutes.minute()) % MINUTE_ALIGNER

              changedManagerViewingPreferences[managerGUID][propertyGUID][
                'startTime'
              ] = moment(additionalMinutes)
                .add(remainder, 'minutes')
                .add(additionalMinutes, 'minutes')
            }
          })
          if (errorPropertyGUID === propertyGUID) {
            this.setState({
              errorPropertyGUID: '',
            })
          }
          this.setState({
            managerViewingPreferences: {
              ...changedManagerViewingPreferences,
            },
            managerViewingPreferencesInitial: changedManagerViewingPreferences,
          })
        }
      },
    )
  }

  changeToOtherManager = (
    managerGUID,
    propertyGUID,
    destinationManagerGUID,
  ) => {
    if (!window.confirm('Are you sure you want to change the property')) {
      return
    }
    const { routedProperties, managerViewingPreferences } = this.state
    const routedPropertiesForManager = routedProperties[managerGUID]
    const routedPropertiesForDestinationManager =
      routedProperties[destinationManagerGUID]
    if (
      routedPropertiesForDestinationManager.length >= MAX_INSPECTOR_PROPERTIES
    ) {
      window.alert(
        `Cannot change. Manager cannot have more than ${MAX_INSPECTOR_PROPERTIES} properties`,
      )
      return
    }
    if (routedPropertiesForManager.length === 2) {
      window.alert('Cannot change. You need to have more than 2 properties.')
      return
    }

    const selectedProperties = Object.values(routedProperties).flat()

    // todo: this is anti-pattern, we shouldn't update the state like that!!!
    let managerViewingPreferencesCopy = managerViewingPreferences
    const managerPreferencesForProperty =
      managerViewingPreferencesCopy[managerGUID][propertyGUID]
    delete managerViewingPreferencesCopy[managerGUID][propertyGUID]
    managerViewingPreferencesCopy[destinationManagerGUID][propertyGUID] =
      managerPreferencesForProperty
    Object.keys(managerViewingPreferencesCopy).forEach((managerGUID) => {
      Object.keys(managerViewingPreferencesCopy[managerGUID]).forEach(
        (propertyGUID) => {
          // the following line, is to exclude other preferences that we put on the same level with properties!!
          // todo: of course this should be fixed and separate the properties from the other preferences!!
          if (!selectedProperties.includes(propertyGUID)) return
          return (managerViewingPreferencesCopy[managerGUID][propertyGUID][
            'startTime'
          ] = null)
        },
      )
    })
    this.setState(
      {
        routedProperties: {
          ...routedProperties,
          [managerGUID]: routedPropertiesForManager.filter(
            (routedProperty) => routedProperty !== propertyGUID,
          ),
          [destinationManagerGUID]:
            routedProperties[destinationManagerGUID].concat(propertyGUID),
        },
        managerViewingPreferences: managerViewingPreferencesCopy,
      },
      () => this.fetchPreflightResponseAfterInitial(true),
    )
  }

  fetchPreflightResponse = () => {
    const { formNewViewingRun, selectedPropertyInfos } = this.props
    const { guid } = this.getChosenTeam()

    if (selectedPropertyInfos.length < 2) {
      history.goBack()
      return
    }

    const dataForBE = this.buildViewingRunRequestFromManagerPreferences()

    this.setState({
      disableAndShowSpinner: true,
    })
    formNewViewingRun(guid, dataForBE)
      .then(({ viewingRun }) => {
        let allProperties = {}
        let routedPropertiesForManagers = {}
        let managerViewingPreferencesInResponse = {}

        viewingRun.groups.forEach((group) => {
          group.properties.forEach((property) => {
            return (allProperties[property.guidID] = property)
          })
          routedPropertiesForManagers[group.inspector_agency_user_id] =
            group.route.stopovers.map((stopover) => stopover.waypoint.key)
          group.route.stopovers.forEach((stopover) => {
            let waypointInfo = {}
            waypointInfo[stopover.waypoint.key] = {
              ...defaultPropertyInfo,
              viewingDuration: nanoSecToMinute(stopover.stay_duration.duration),
              startTime: stopover.arrival_time,
              editable: false,
              breakDuration: stopover.buffer_duration
                ? nanoSecToMinute(stopover.buffer_duration.duration)
                : 0,
              travelDuration: stopover.to_duration
                ? stopover.to_duration.formatted.split(' ')[0]
                : -1,
            }
            managerViewingPreferencesInResponse = {
              ...managerViewingPreferencesInResponse,
              [group.inspector_agency_user_id]: {
                ...managerViewingPreferencesInResponse[
                  group.inspector_agency_user_id
                ],
                ...waypointInfo,
              },
            }
          })
        })

        const allManagersSelected = viewingRun.groups.map((group) => {
          return {
            ...group.inspector_agency_user,
            ...group.inspector_agency_user.profile,
          }
        })
        this.setState({
          initialPreviewCallDone: true,
          rawRoutes: viewingRun.groups
            .map((group) => group.route.raw_route)
            .filter((el) => el !== null),
          stopovers: viewingRun.groups.map((group) => group.route.stopovers),
          gMapRoute: viewingRun.groups.map((group) => group.route),
          allProperties,
          routedProperties: routedPropertiesForManagers,
          allManagersSelected,
          activeManagerTabGUID: allManagersSelected[0].guidID,
          managerViewingPreferences: managerViewingPreferencesInResponse,
          managerViewingPreferencesInitial: managerViewingPreferencesInResponse,
          viewingRunDate: viewingRun?.original_request.date,
        })
      })
      .then(() => this.renderGoogleMapWithRoutes())
      .catch((error) => snugNotifier.error(error))
      .finally(() =>
        this.setState({
          disableAndShowSpinner: false,
        }),
      )
  }

  mapManagerPreferencesForRequest = (managerGUID, preferences) => {
    return {
      user_id: managerGUID, // the same uuid from the inspectors.ids field
      start_time: moment(preferences.startTime).format(
        timeHelpers.FULL_TIME_24H,
      ), // default value is 09:00
      start_property_id: preferences.startPropertyGUID, // default to a random property from his group
      end_property_id: preferences.endPropertyGUID, // default to a random property from his group
      travel_mode:
        preferences.travelMode === modeDriving ||
        preferences.travelMode === MODE_DRIVING_NEW_VERSION
          ? MODE_DRIVING_NEW_VERSION
          : MODE_WALKING_NEW_VERSION, // default to car
      stopover_duration_minutes: parseInt(preferences.viewingDuration), // the viewing time - default to 15 minutes
      stopover_buffer_minutes: parseInt(preferences.breakDuration), // viewing buffer - default to 5 minutes
      optimize_stopovers: true, // default to true - set this to false in subsequent calls to enable the user arranging the properties manaully
    }
  }

  buildViewingRunRequestFromManagerPreferences = () => {
    const {
      startTime,
      allManagersSelected,
      managerPreferences,
      selectedPropertyInfos,
    } = this.props
    const propertiesIds = selectedPropertyInfos.map(
      ({ property }) => property.guidID,
    )
    const managersIds = allManagersSelected.map(
      ({ managerGUID }) => managerGUID,
    )

    return {
      date: moment(startTime).format(timeHelpers.DATE_TIME_Z),
      properties: {
        all: false,
        ids: propertiesIds,
      },
      inspectors: {
        all: false,
        ids: managersIds,
      },
      inspectors_preferences: Object.entries(managerPreferences).map(
        ([managerGUID, preferences]) =>
          this.mapManagerPreferencesForRequest(managerGUID, preferences),
      ),
    }
  }

  buildViewingRunRequestIncludingPropertiesPreferences(
    optimizeStopovers,
    unsetStopOverStartTime = false,
  ) {
    const { startTime, managerPreferences } = this.props
    const { routedProperties, managerViewingPreferences } = this.state
    const propertiesSelected = Object.values(routedProperties).flat()
    return {
      date: moment(startTime).format(timeHelpers.DATE_TIME_Z),
      properties: {
        all: false,
        ids: propertiesSelected,
      },
      inspectors: {
        all: false,
        ids: Object.keys(routedProperties),
      },
      inspectors_preferences: Object.keys(managerPreferences).map(
        (managerGUID) => {
          const managerPreferencePerProperty = Object.keys(
            managerViewingPreferences[managerGUID],
          )
            .map((propertyGUID) => {
              // the following line, is to exclude other preferences that we put on the same level with properties!!
              // todo: of course this should be fixed and separate the properties from the other preferences!!
              if (!propertiesSelected.includes(propertyGUID)) return undefined

              const currentStopOverStartTime =
                managerViewingPreferences[managerGUID][propertyGUID].startTime

              const stopOverStartTime =
                unsetStopOverStartTime || !currentStopOverStartTime
                  ? null
                  : moment(currentStopOverStartTime).format('HH:mm')
              return {
                id: propertyGUID,
                stopover_start_time: stopOverStartTime,
                stopover_duration_minutes: parseInt(
                  managerViewingPreferences[managerGUID][propertyGUID]
                    .viewingDuration,
                ),
                stopover_buffer_minutes: parseInt(
                  managerViewingPreferences[managerGUID][propertyGUID]
                    .breakDuration,
                ),
                stopover_order:
                  routedProperties[managerGUID].indexOf(propertyGUID),
                viewings_published:
                  managerViewingPreferences[managerGUID][propertyGUID]
                    .viewingsPublished,
              }
            })
            .filter((property) => Boolean(property))
            .sort(
              (propertyA, propertyB) =>
                propertyA.stopover_order - propertyB.stopover_order,
            )
            .map((property, i) => ({ ...property, stopover_order: i }))

          return {
            user_id: managerGUID, // the same uuid from the inspectors.ids field
            start_time: moment(
              managerPreferences[managerGUID].startTime,
            ).format(timeHelpers.FULL_TIME_24H), // default value is 09:00
            start_property_id: routedProperties[managerGUID][0], // default to a random property from his group
            end_property_id:
              routedProperties[managerGUID][
                routedProperties[managerGUID].length - 1
              ], // default to a random property from his group
            properties: managerPreferencePerProperty,
            travel_mode:
              managerPreferences[managerGUID].travelMode === modeDriving ||
              managerPreferences[managerGUID].travelMode ===
                MODE_DRIVING_NEW_VERSION
                ? MODE_DRIVING_NEW_VERSION
                : MODE_WALKING_NEW_VERSION, // default to car
            stopover_duration_minutes: parseInt(
              managerPreferences[managerGUID].viewingDuration,
            ), // the viewing time - default to 15 minutes
            stopover_buffer_minutes: parseInt(
              managerPreferences[managerGUID].breakDuration,
            ), // viewing buffer - default to 5 minutes
            optimize_stopovers: optimizeStopovers, // default to true - set this to false in subsequent calls to enable the user arranging the properties manaully
          }
        },
      ),
    }
  }

  fetchPreflightResponseAfterInitial = (
    optimizeStopovers = false,
    unsetStopOverStartTime = false,
  ) => {
    const { guid } = this.getChosenTeam()
    const { formNewViewingRun } = this.props
    const { managerViewingPreferences } = this.state
    this.setState({ disableAndShowSpinner: true })

    const dataForBE = this.buildViewingRunRequestIncludingPropertiesPreferences(
      optimizeStopovers,
      unsetStopOverStartTime,
    )

    formNewViewingRun(guid, dataForBE)
      .then(({ viewingRun }) => {
        let allProperties = {}
        let routedPropertiesForManagers = {}
        let managerViewingPreferencesInResponse = {}

        viewingRun.groups.forEach((group) => {
          const managerId = group.inspector_agency_user_id
          const currentManagerPreferences =
            managerViewingPreferences[managerId] || {}

          group.properties.forEach((property) => {
            return (allProperties[property.guidID] = property)
          })
          routedPropertiesForManagers[group.inspector_agency_user_id] =
            group.route.stopovers.map((stopover) => stopover.waypoint.key)
          group.route.stopovers.forEach((stopover) => {
            // return (
            const currentPropertyPreferences =
              currentManagerPreferences[stopover.waypoint.key] || {}

            let waypointInfo = {}
            waypointInfo[stopover.waypoint.key] = {
              ...defaultPropertyInfo,
              ...currentPropertyPreferences,
              viewingDuration: nanoSecToMinute(stopover.stay_duration.duration),
              startTime: stopover.arrival_time,
              editable: false,
              breakDuration: stopover.buffer_duration
                ? nanoSecToMinute(stopover.buffer_duration.duration)[0]
                : 0,
            }
            managerViewingPreferencesInResponse = {
              ...managerViewingPreferencesInResponse,
              [group.inspector_agency_user_id]: {
                ...managerViewingPreferencesInResponse[
                  group.inspector_agency_user_id
                ],
                ...waypointInfo,
              },
            }
          })
        })
        const allManagersSelected = viewingRun.groups.map((group) => {
          return {
            ...group.inspector_agency_user,
            ...group.inspector_agency_user.profile,
          }
        })

        this.setState({
          initialPreviewCallDone: true,
          rawRoutes: viewingRun.groups
            .map((group) => group.route.raw_route)
            .filter((el) => el !== null),
          stopovers: viewingRun.groups.map((group) => group.route.stopovers),
          gMapRoute: viewingRun.groups.map((group) => group.route),
          allProperties,
          routedProperties: routedPropertiesForManagers,
          allManagersSelected,
          managerViewingPreferences: managerViewingPreferencesInResponse,
          managerViewingPreferencesInitial: managerViewingPreferencesInResponse,
        })
      })
      .then(() => this.renderGoogleMapWithRoutes())
      .catch((error) => snugNotifier.error(error))
      .finally(() =>
        this.setState({
          disableAndShowSpinner: false,
        }),
      )
  }

  fetchViewingRunInfoFromGUID = (viewingRunGUID) => {
    const { fetchNewViewingRunVersion } = this.props
    const { guid } = this.getChosenTeam()
    this.setState({ disableAndShowSpinner: true })

    fetchNewViewingRunVersion(guid, viewingRunGUID)
      .then(({ viewingRun }) => {
        let allProperties = {}
        let routedPropertiesForManagers = {}
        let managerViewingPreferencesInResponse = {}
        const { status } = viewingRun
        const isRunDraft = isViewingRunDraft(status)
        this.setState({
          isRunDraft,
        })

        viewingRun.groups.forEach((group) => {
          group.properties.forEach((property) => {
            return (allProperties[property.guidID] = property)
          })
          routedPropertiesForManagers[group.inspector_agency_user_id] =
            group.route.stopovers.map((stopover) => stopover.waypoint.key)
          group.route.stopovers.forEach((stopover) => {
            let waypointInfo = {}
            waypointInfo[stopover.waypoint.key] = {
              viewingDuration: nanoSecToMinute(stopover.stay_duration.duration),
              startTime: stopover.arrival_time,
              editable: false,
              breakDuration: stopover.buffer_duration
                ? nanoSecToMinute(stopover.buffer_duration.duration)
                : 0,
            }
            managerViewingPreferencesInResponse = {
              ...managerViewingPreferencesInResponse,
              [group.inspector_agency_user_id]: {
                ...managerViewingPreferencesInResponse[
                  group.inspector_agency_user_id
                ],
                ...waypointInfo,
              },
            }
          })

          group.group_viewings.forEach((viewingInRun) => {
            managerViewingPreferencesInResponse = {
              ...managerViewingPreferencesInResponse,
              [group.inspector_agency_user_id]: {
                ...managerViewingPreferencesInResponse[
                  group.inspector_agency_user_id
                ],
                [viewingInRun.property_id]: {
                  ...managerViewingPreferencesInResponse[
                    group.inspector_agency_user_id
                  ][viewingInRun.property_id],

                  viewingGUID: viewingInRun.viewing_id,
                  viewing: viewingInRun.viewing,
                  ...viewingInRun.original_viewing.preferences,
                  viewingsPublished:
                    viewingInRun.original_viewing.preferences.published,
                },
              },
            }
          })
        })

        const viewingRunDate = viewingRun.original_request.date
        viewingRun.original_request.inspectors_preferences.forEach(
          (manager) => {
            managerViewingPreferencesInResponse = {
              ...managerViewingPreferencesInResponse,
              [manager.user_id]: {
                ...managerViewingPreferencesInResponse[manager.user_id],
                travelMode: manager.travel_mode,
              },
            }
          },
        )

        const allManagersSelected = viewingRun.groups.map((group) => {
          return {
            ...group.inspector_agency_user,
            ...group.inspector_agency_user.profile,
          }
        })

        this.setState({
          initialPreviewCallDone: true,
          rawRoutes: viewingRun.groups
            .map((group) => group.route.raw_route)
            .filter((el) => el !== null),
          stopovers: viewingRun.groups.map((group) => group.route.stopovers),
          gMapRoute: viewingRun.groups.map((group) => group.route),
          allProperties,
          routedProperties: routedPropertiesForManagers,
          allManagersSelected: allManagersSelected,
          activeManagerTabGUID: allManagersSelected[0].guidID,
          managerViewingPreferences: managerViewingPreferencesInResponse,
          managerViewingPreferencesInitial: managerViewingPreferencesInResponse,
          viewingRunDate,
          isPreviewFromExistingRun: true,
        })
      })
      .then(() => this.renderGoogleMapWithRoutes())
      .catch((error) => snugNotifier.error(error))
      .finally(() =>
        this.setState({
          disableAndShowSpinner: false,
        }),
      )
  }

  saveNewViewingRun = (isDraft = false) => {
    const { match } = this.props
    const { isEditMode, editClicked } = this.state
    const { guid } = this.getChosenTeam()

    if (editClicked) {
      snugNotifier.error('There are unsaved changes to an item in the run')
      return
    }
    this.setState({ disableAndShowSpinner: true })

    const dataForBE = {
      ...this.buildViewingRunRequestIncludingPropertiesPreferences(false),
      draft: isDraft,
    }

    const apiToCall = isEditMode
      ? updateViewingRun.bind(null, guid, match.params.viewingRunGUID)
      : createViewingRun.bind(null, guid)

    return apiToCall(dataForBE)
      .then(() => {
        snugNotifier.success('Viewing Run Successfully saved!')
        history.push(
          urlTo('newViewingsRunAll', {
            teamSlug: this.props.match.params.teamSlug,
          }),
        )
      })
      .catch(({ plainError, message }) => {
        const errorBody = plainError?.response?.data
        if (!errorBody || !errorBody.detail) {
          return snugNotifier.error(message)
        }
        snugNotifier.error(errorBody.detail.message)
        const errorPropertyGUID = errorBody.detail.property_guid
        if (errorPropertyGUID) {
          this.setState({
            errorPropertyGUID,
          })
        } else {
          this.setState({
            errorPropertyGUID: '',
          })
        }
      })
      .finally(() => this.setState({ disableAndShowSpinner: false }))
  }

  renderGoogleMapWithRoutes = () => {
    const { gMapRoute } = this.state
    this.map = new window.google.maps.Map(document.getElementById('map'), {
      // set sydney cbd as center by default. The map center will changed automatically when direction routes are rendered to cover them all
      center,
      zoom: 14,
      scrollwheel: false,
      disableDoubleClickZoom: true,
    })
    let maxBounds = new window.google.maps.LatLngBounds()
    gMapRoute.forEach((gRoute) => {
      if (gRoute.raw_route) {
        const currBounds = new window.google.maps.LatLngBounds(
          gRoute.raw_route.bounds.southwest,
          gRoute.raw_route.bounds.northeast,
        )
        maxBounds.union(currBounds)
      }
    })

    // eslint-disable-next-line array-callback-return
    let gRoutes = gMapRoute.map((gRoute) => {
      if (gRoute.raw_route) {
        const googleMapRouteRenderObject = {
          bounds: maxBounds,
          overview_path: window.google.maps.geometry.encoding.decodePath(
            gRoute.raw_route.overview_polyline.points,
          ),
          legs: gRoute.raw_route.legs.map(function (leg) {
            leg.start_location = new window.google.maps.LatLng(
              leg.start_location.lat,
              leg.start_location.lng,
            )
            leg.end_location = new window.google.maps.LatLng(
              leg.end_location.lat,
              leg.end_location.lng,
            )
            leg.steps = leg.steps.map(function (step) {
              step.path = window.google.maps.geometry.encoding.decodePath(
                step.polyline.points,
              )
              step.start_location = new window.google.maps.LatLng(
                step.start_location.lat,
                step.start_location.lng,
              )
              step.end_location = new window.google.maps.LatLng(
                step.end_location.lat,
                step.end_location.lng,
              )
              return step
            })
            return leg
          }),
        }
        return googleMapRouteRenderObject
      }
    })
    gRoutes.forEach((groute, index) => {
      this.directionsDisplay = new window.google.maps.DirectionsRenderer({
        draggable: false,
        map: this.map,
        suppressMarkers: true,
      })

      const numberRouterIndex =
        index - strokeColors.length * Math.floor(index / strokeColors.length)

      this.directionsDisplay.setOptions({
        polylineOptions: {
          strokeColor: strokeColors[numberRouterIndex],
        },
      })

      this.directionsDisplay.setDirections({
        routes: [groute],
        request: { travelMode: 'DRIVING' },
      })

      const { legs = [] } = groute

      legs.forEach((leg, legIndex) => {
        const alphabetLegChar =
          legIndex -
          MARKER_LETTER_NUMBER * Math.floor(legIndex / MARKER_LETTER_NUMBER)

        const startMakerImageName = `${
          strokeColors[numberRouterIndex]
        }_Marker${(alphabetLegChar + 10).toString(36).toUpperCase()}`
        const endMakerImageName = `${strokeColors[numberRouterIndex]}_Marker${(
          alphabetLegChar + 11
        )
          .toString(36)
          .toUpperCase()}`

        new window.google.maps.Marker({
          position: {
            lat: leg.start_location.lat(),
            lng: leg.start_location.lng(),
          },
          map: this.map,
          icon: google_map_markers[startMakerImageName],
        })

        new window.google.maps.Marker({
          position: {
            lat: leg.end_location.lat(),
            lng: leg.end_location.lng(),
          },
          map: this.map,
          icon: google_map_markers[endMakerImageName],
        })
      })
    })

    this.map.fitBounds(maxBounds)
    this.directionsDisplay.setMap(this.map)
  }

  render() {
    const {
      isEditMode,
      routedProperties = {},
      allProperties,
      allManagersSelected,
      managerViewingPreferences,
      viewingRunDate,
      disableAndShowSpinner,
      errorPropertyGUID,
      isRunDraft,
      showViewingSettingsNotice,
      viewingSettingsNoticeContent,
      stopovers,
    } = this.state
    const { activeManagerTabGUID } = this.state
    const managerGUIDS = Object.keys(routedProperties)
    const { startTime, managerPreferences, match } = this.props
    const { params = {} } = match || {}
    const { teamSlug = '' } = params || {}
    const isReadOnly = !isRunDraft

    const tabHeaders = allManagersSelected.map((manager, index) => {
      const viewingRunProperties =
        routedProperties?.[manager.guidID]?.length > 0
          ? ` (${routedProperties[manager.guidID].length})`
          : ''
      return {
        label:
          `${manager.firstName} ${manager.lastName}${viewingRunProperties}` ||
          '',
        tabGUID: manager.guidID,
        labelColor: strokeColors[index],
      }
    })

    const commaSeparatedManagerNames = stringHelpers.commaSeparatedString(
      tabHeaders,
      'label',
    )
    const text = this.props.match.params.viewingRunGUID
      ? `Viewing run ${isRunDraft ? 'Draft' : ''} scheduled for ${moment(
          viewingRunDate,
        ).format('ddd DD MMM')} for ${commaSeparatedManagerNames}.`
      : `Viewing run ${isRunDraft ? 'Draft' : ''} scheduled for ${moment(
          startTime,
        ).format('ddd DD MMM')} for ${commaSeparatedManagerNames}.` +
        ' Click save to schedule viewings and notify enquirers.'

    const changeToOtherManagerContent = allManagersSelected.map((manager) => {
      return manager.guidID !== activeManagerTabGUID
        ? {
            label: `Change to ${manager.firstName} ${manager.lastName}`,
            action: this.changeToOtherManager,
            destinationManagerGUID: manager.guidID,
          }
        : null
    })

    let contentForChangeButton = [
      {
        label: 'Remove',
        action: this.onDeleteButtonClicked,
      },
      ...changeToOtherManagerContent,
    ]
    contentForChangeButton = contentForChangeButton.filter(Boolean)

    const formatViewingDate = (date) => {
      if (!date) return ''
      return moment(date).format(HUMAN_READABLE_DATE)
    }

    let viewingDate = this.props.match.params.viewingRunGUID
      ? formatViewingDate(viewingRunDate)
      : formatViewingDate(startTime)
    let viewingsRunTitle = `Viewings run - ${viewingDate}`
    if (isRunDraft) {
      viewingsRunTitle += ' (Draft)'
    }
    return (
      <div>
        <PublicHeader title={viewingsRunTitle} text={text} />
        <div id="map-container">
          <div id="map" />
        </div>
        <div className="mb20" />
        <PublicHeader text="Drag and drop to change the viewing order." />
        {showViewingSettingsNotice && (
          <Alert variant="blueWithBg">{viewingSettingsNoticeContent}</Alert>
        )}
        <div className="mb20" />
        <HorizontalTabs
          tabHeaders={tabHeaders}
          activeTabGUID={activeManagerTabGUID}
          onClickTabInHorizontalTab={this.onClickTabInHorizontalTab}
          showLabelColors={true}
        />
        {managerGUIDS.map((managerGUID, managerGUIDIndex) => {
          return managerGUID === activeManagerTabGUID ? (
            <PropertyItemListContainer key={managerGUIDIndex}>
              <DragDropContext
                onDragEnd={(result) =>
                  this.onDragEndHandler(result, managerGUID)
                }
              >
                <Droppable droppableId={'preview' + managerGUIDIndex}>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {routedProperties[managerGUID].map(
                        (propertyGUID, index) => {
                          const needTravelToNext =
                            index === routedProperties[managerGUID].length - 1
                          const propertyInfo = {
                            startTime:
                              managerViewingPreferences[managerGUID][
                                propertyGUID
                              ].startTime,
                            viewingDuration:
                              managerViewingPreferences[managerGUID][
                                propertyGUID
                              ].viewingDuration,
                            editable:
                              managerViewingPreferences[managerGUID][
                                propertyGUID
                              ].editable,
                            breakDuration:
                              managerViewingPreferences[managerGUID][
                                propertyGUID
                              ].breakDuration,
                            travelMode: managerViewingPreferences[managerGUID]
                              .travelMode
                              ? managerViewingPreferences[managerGUID]
                                  .travelMode
                              : managerPreferences[managerGUID].travelMode,
                            needTravelToNext: !needTravelToNext,
                            viewingGUID:
                              managerViewingPreferences[managerGUID][
                                propertyGUID
                              ].viewingGUID,
                            viewingsPublished:
                              managerViewingPreferences[managerGUID][
                                propertyGUID
                              ].viewingsPublished,
                          }
                          return (
                            <Draggable
                              key={index}
                              draggableId={propertyGUID}
                              index={index}
                              isDragDisabled={isReadOnly}
                            >
                              {(provided, snapshot) => (
                                <div>
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Box ml="30px">
                                      {stopovers[managerGUIDIndex][index] &&
                                        stopovers[managerGUIDIndex][index]
                                          .to_duration &&
                                        `${stopovers[managerGUIDIndex][index].to_distance.formatted} (${stopovers[managerGUIDIndex][index].to_duration.formatted})`}
                                    </Box>
                                    <PropertyItem
                                      errorPropertyGUID={errorPropertyGUID}
                                      propertyGUID={propertyGUID}
                                      propertyInfo={{
                                        property: allProperties[propertyGUID],
                                        ...propertyInfo,
                                      }}
                                      status="scheduled"
                                      markerLabel={markerLabels.charAt(index)}
                                      teamSlug={teamSlug}
                                      onEditClicked={() => {
                                        this.changeManagerViewingPreference(
                                          managerGUID,
                                          propertyGUID,
                                          'editable',
                                          !managerViewingPreferences[
                                            managerGUID
                                          ][propertyGUID].editable,
                                        )
                                      }}
                                      viewingRunNewVersion={true}
                                      onDeleteButtonClicked={() =>
                                        this.onDeleteButtonClicked(
                                          managerGUID,
                                          propertyGUID,
                                        )
                                      }
                                      onViewingStartTimeChanged={(value) =>
                                        this.changeManagerViewingPreference(
                                          managerGUID,
                                          propertyGUID,
                                          'startTime',
                                          value,
                                        )
                                      }
                                      onSaveClicked={() => {
                                        this.saveeManagerViewingPreference(
                                          managerGUID,
                                          propertyGUID,
                                        )
                                      }}
                                      onViewingDurationChanged={(event) =>
                                        this.changeManagerViewingPreference(
                                          managerGUID,
                                          propertyGUID,
                                          'viewingDuration',
                                          event.target.value,
                                        )
                                      }
                                      onBreakDurationChanged={(event) =>
                                        this.changeManagerViewingPreference(
                                          managerGUID,
                                          propertyGUID,
                                          'breakDuration',
                                          event.target.value,
                                        )
                                      }
                                      onChangeViewingType={({ value }) =>
                                        this.changeManagerViewingPreference(
                                          managerGUID,
                                          propertyGUID,
                                          'viewingsPublished',
                                          value === 'true',
                                        )
                                      }
                                      isReadOnly={isReadOnly}
                                      notifyLateViewing={
                                        this.props.notifyLateViewing
                                      }
                                      contentForChangeButton={
                                        contentForChangeButton
                                      }
                                      managerGUID={managerGUID}
                                      propertyGUIDNewVersion={propertyGUID}
                                      showStats={true}
                                    />
                                  </div>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                          )
                        },
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </PropertyItemListContainer>
          ) : null
        })}
        <OppositeSidesContainer>
          <StyledBackButton onClick={() => history.goBack()}>
            <i className="icon-arrow-left left" />
            <span>Back</span>
          </StyledBackButton>
          {!isReadOnly && (
            <Flex
              justifyContent="center"
              mb={theme.space[5] + 'px'}
              mt={theme.space[13] + 'px'}
            >
              <ButtonWithLoading
                onClick={() => this.saveNewViewingRun(true)}
                disabled={disableAndShowSpinner}
                loading={disableAndShowSpinner}
                variant="solidSecondary"
                showSpinnerBesideText={true}
                ButtonCmp={CTAButtonWithFixedWidth}
                mr="8px"
              >
                {isEditMode ? 'Update Draft' : 'Save as Draft'}
              </ButtonWithLoading>
              <ButtonWithLoading
                onClick={() => this.saveNewViewingRun()}
                disabled={disableAndShowSpinner}
                loading={disableAndShowSpinner}
                showSpinnerBesideText={true}
                ButtonCmp={CTAButtonWithFixedWidth}
              >
                Save and Publish
              </ButtonWithLoading>
            </Flex>
          )}
        </OppositeSidesContainer>
      </div>
    )
  }
}

export default PreviewContainer
