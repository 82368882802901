import React from 'react'

import { ThemeProvider } from 'styled-components'
import { createGlobalStyle } from 'styled-components'

import { theme } from 'app/match/applicationReportPDF/assets/theme'

const GlobalStyles = createGlobalStyle`
  input:-webkit-autofill ~ label {
    top: 0px;
    transition: all 0.2s ease;
    transition-delay: 0s;
  }
`

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
)

export default Theme
