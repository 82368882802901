import React, { useEffect, useState } from 'react'

import moment from 'moment'
import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import * as Form from 'app/components/forms/forms'
import { updateOfferLeaseEndDate } from 'app/services/http/offers'
import * as snugNotifier from 'app/services/snugNotifier'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

const DEFAULT_MONTHS_TO_ADD = 6

const StyledLeaseEndDate = styled(Box)`
  button {
    height: ${({ theme }) => theme.height[12] + 'px'};
    width: ${({ theme }) => theme.width[12] + 'px'};
    font-size: ${({ theme }) => theme.space[5] + 'px'};
  }

  margin-bottom: 12px;
`

function LeaseEndDate({ guidDetails = {}, offer = {} }) {
  const [hasLeaseEndDate, setHasLeaseEndDate] = useState(false)
  const [leaseEndDateValue, setLeaseEndDateValue] = useState({
    value: '',
    error: '',
  })

  useEffect(() => {
    const { setLeaseEndDate, leaseEndDateValue } = offer || {}
    setHasLeaseEndDate(setLeaseEndDate)
    setLeaseEndDateValue({ value: leaseEndDateValue, error: '' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateDate = (data) => {
    const { value, error } = data
    setLeaseEndDateValue({
      value,
      error,
    })
    const requestBody = {
      setLeaseEndDate: hasLeaseEndDate,
      leaseEndDateValue: moment.utc(value),
    }
    if (!error) {
      updateLeaseEndDate(requestBody)
    }
  }

  const updateCheckBox = () => {
    const { setLeaseEndDate } = offer || {}
    setHasLeaseEndDate(!hasLeaseEndDate)
    if (!setLeaseEndDate) {
      setLeaseEndDateValue({
        value: moment(moment.now()).add(DEFAULT_MONTHS_TO_ADD, 'months'),
      })
    }
    const requestBody = {
      setLeaseEndDate: !hasLeaseEndDate,
      leaseEndDateValue: moment(moment.now()).add(
        DEFAULT_MONTHS_TO_ADD,
        'months',
      ),
    }
    updateLeaseEndDate(requestBody)
  }

  const updateLeaseEndDate = (requestBody) => {
    const { offerGUID, agencyGUID } = guidDetails
    updateOfferLeaseEndDate(agencyGUID, offerGUID, requestBody)
      .then(() =>
        snugNotifier.success(
          'Successfully updated lease end date for property',
        ),
      )
      .catch((error) => snugNotifier.error(error.message))
  }

  return (
    <StyledLeaseEndDate>
      <Form.CheckBoxGeneral
        id="setLeaseEndDate"
        label="Set a default lease end date in lease offer"
        checked={hasLeaseEndDate}
        onChange={() => updateCheckBox('setLeaseEndDate')}
        enableEdit
      />
      {hasLeaseEndDate && (
        <>
          <Form.InputFutureDate
            label="Lease End Date"
            value={moment(leaseEndDateValue.value).format(
              dateTimeHelpers.DATE_WITH_SLASH,
            )}
            error={leaseEndDateValue.error}
            onChange={updateDate}
            id="leaseEndDateValue"
            readOnly={false}
          />
        </>
      )}
    </StyledLeaseEndDate>
  )
}

export default LeaseEndDate
