import React, { useEffect, useState } from 'react'

import qs from 'qs'
import styled from 'styled-components'

import {
  Alert,
  Box,
  BoxInput,
  Button,
  Flex,
  LegacySpinner,
  ProgressBar,
} from 'app/components/design-system-components'
import { ModalButtonGroup } from 'app/components/display/display'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'
import {
  getCurrentStepForQuickStart,
  getQuickWizardData,
  postBrandingInfo,
  postNewStepForAgencyForQuickstart,
} from 'app/sm/team_viewing_preference/setup/api'
import HasBannerAndLogo from 'app/sm/team_viewing_preference/setup/Branding/hasBannerAndLogo'
import NoBannerAndLogo from 'app/sm/team_viewing_preference/setup/Branding/noBannerOrLogo'
import * as setupHelpers from 'app/sm/team_viewing_preference/setup/helpers'
import { StyledSetupContainerSteps } from 'app/sm/team_viewing_preference/setup/index'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

export const StyledBranding = styled(Box)`
  line-height: ${({ theme }) => theme.lineHeights.title};
`

const THIS_STEP = setupHelpers.SETUP_BRANDING

function Branding({ match }) {
  const {
    loadingStates: brandingLoadingStates,
    loadingStatesHelpers: brandingLoadingStateHelpers,
  } = useLoadingStates()
  const { params } = match
  const { teamSlug = '' } = params
  const curQueries = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  const { teamGUID = '' } = curQueries
  const [wizardGUID, setWizardGUID] = useState('')
  const [currentStep, setCurrentStep] = useState(THIS_STEP)

  const [formDetails, setFormDetails] = useState({
    smsBrandingName: '',
    brandingBannerUrl: '',
    brandingLogoURL: '',
  })

  const [agencyName, setAgencyName] = useState('')
  useEffect(() => {
    brandingLoadingStateHelpers.startLoading()

    getQuickWizardData(teamGUID)
      .then((data) => {
        const {
          branding_banner_url,
          branding_logo_url,
          name,
          branding_sms_sender,
        } = data
        setFormDetails({
          ...formDetails,
          brandingBannerUrl: branding_banner_url,
          brandingLogoURL: branding_logo_url,
          smsBrandingName: branding_sms_sender,
        })
        setAgencyName(name)
      })
      .catch((error) => snugNotifier.error(error.message))
    getCurrentStepForQuickStart(teamGUID)
      .then((data) => {
        const { id, current_step_id } = data
        setWizardGUID(id)
        setCurrentStep(current_step_id)
        brandingLoadingStateHelpers.markDoneSuccessfully()
      })
      .catch((err) => {
        brandingLoadingStateHelpers.setError(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeInputValue = (field) => {
    return (data) => {
      setFormDetails({
        ...formDetails,
        [field]: data,
      })
    }
  }

  const goToNextStep = () => {
    history.push(urlTo('stepFinished', { teamSlug, agencyGUID: teamGUID }))
  }

  const onClickNext = () => {
    const payload = {
      sms_branding_name: formDetails.smsBrandingName,
    }
    postBrandingInfo(teamGUID, payload)
      .then(() => {
        snugNotifier.success('Branding information successfully added')
      })
      .catch((error) => snugNotifier.error(error.message))
    if (currentStep === THIS_STEP) {
      postNewStepForAgencyForQuickstart(teamGUID, wizardGUID)
        .then(({ current_step }) => {
          const { ID } = current_step
          const step = setupHelpers.findWhichStepToRouteTo(ID)
          history.push(urlTo(step, { teamSlug, agencyGUID: teamGUID }))
          setupHelpers.dontShowSetupWizardPrompt()
        })
        .catch((error) => snugNotifier.error(error.message))
    } else {
      goToNextStep()
    }
  }

  const hasBannerURLAndLogo =
    formDetails.brandingBannerUrl && formDetails.brandingLogoURL

  return (
    <StyledSetupContainerSteps>
      <Box mb={theme.space[8] + 'px'}>
        <ProgressBar totalStepsCount={3} lastCheckedStep={3} />
      </Box>
      <h2>Branding</h2>
      {brandingLoadingStates.state === loadingStatesIds.LOADING ? (
        <Flex justifyContent="center">
          <LegacySpinner />
        </Flex>
      ) : (
        <>
          {brandingLoadingStates.state !== loadingStatesIds.ERROR ? (
            <>
              {hasBannerURLAndLogo && (
                <HasBannerAndLogo
                  agencyName={agencyName}
                  bannerURL={formDetails.brandingBannerUrl}
                  logoURL={formDetails.brandingLogoURL}
                />
              )}
              {!hasBannerURLAndLogo && (
                <NoBannerAndLogo agencyName={agencyName} />
              )}
              <Box mb={theme.space[9] + 'px'}>
                <BoxInput
                  onChange={changeInputValue('smsBrandingName')}
                  takeMaxWidth
                  labelText="SMS Sender (11 letters e.g. JJRE)"
                  labelClassName="fs16 mb16"
                  placeholder="JJRealEstate"
                  initialValue={formDetails.smsBrandingName}
                />
              </Box>
              <Flex alignItems="center">
                <ModalButtonGroup
                  primaryLabel="Next"
                  secondaryLabel="Back"
                  secondaryButtonType="tertiary"
                  primaryAction={onClickNext}
                  secondaryAction={() =>
                    history.push(
                      urlTo('stepTeamMembers', {
                        teamSlug,
                        agencyGUID: teamGUID,
                      }),
                    )
                  }
                  justifyContent="left"
                  containerClassName="m0"
                />
                <Button
                  variant="linkBlue"
                  onClick={() =>
                    history.push(
                      urlTo('stepFinished', { teamSlug, agencyGUID: teamGUID }),
                    )
                  }
                >
                  skip
                </Button>
              </Flex>
            </>
          ) : (
            <Alert variant="warningWithBg" mt={theme.space[3] + 'px'}>
              <div>{brandingLoadingStates.error.message}</div>
            </Alert>
          )}
        </>
      )}
    </StyledSetupContainerSteps>
  )
}

export default Branding
