import { useEffect, useState } from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Box } from 'app/components/design-system-components'
import { ErrorBox } from 'app/components/forms/util'
import { ManagedAppId } from 'app/constants/pms.constants'
import { activatePMS, deactivatePMS, getPMSConfig } from 'app/services/http/pms'
import * as snugNotifier from 'app/services/snugNotifier'
import * as Form from 'app/shared_components/form_component'

export const ManagedAppIntegration = ({
  agencyId,
  integrationsBundleEnabled,
}: any) => {
  const [isEnabled, setIsEnabled] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [apiErr, setApiErr] = useState(null)
  const [managedAgencyId, setManagedAgencyId] = useState(null)

  const changeIntegrationAvailability = () => {
    setIsEnabled(!isEnabled)
    if (isEnabled) {
      setApiErr(null)
      setManagedAgencyId(null)
    }
  }

  useEffect(() => {
    setApiErr(null)
    getPMSConfig(agencyId, ManagedAppId)
      .then((config: any) => {
        const { agency_id } = config || {}
        setManagedAgencyId(agency_id)
        setIsActive(!!agency_id)
        setIsEnabled(!!agency_id)
      })
      .catch(({ message }: any) => {
        snugNotifier.error(message)
        setApiErr(message)
      })
  }, [agencyId])

  const activate = () => {
    if (isActive) {
      snugNotifier.error('ManagedApp PMS already activated')
      return
    }

    if (!managedAgencyId) {
      snugNotifier.error('Please set agency id')
      return
    }

    setApiErr(null)
    setIsLoading(true)

    activatePMS(agencyId, ManagedAppId, {
      agency_id: managedAgencyId,
    })
      .then(() => {
        setIsActive(true)
        snugNotifier.success('ManagedApp has been activated for agency')
      })
      .catch(({ message }: any) => {
        snugNotifier.error(message)
        setApiErr(message)
      })
      .finally(() => setIsLoading(false))
  }

  const deactivate = () => {
    if (!isActive) {
      snugNotifier.error('ManagedApp PMS not activated')
      return
    }

    setApiErr(null)
    setIsLoading(true)

    deactivatePMS(agencyId, ManagedAppId)
      .then(() => {
        setManagedAgencyId(null)
        setIsActive(false)
        setIsEnabled(false)
      })
      .catch(({ message }: any) => {
        snugNotifier.error(message)
        setApiErr(message)
      })
      .finally(() => setIsLoading(false))
  }

  const updateManagedAppAgencyId = ({ target: { value } }: any) => {
    setManagedAgencyId(value)
  }

  return (
    <Box mb={4} className={'col-sm-12'} pl={0}>
      <Form.Checkbox
        id="managedAppEnabled"
        label={`Enable ${ManagedAppId}`}
        checked={isEnabled}
        onChange={changeIntegrationAvailability}
        readOnly={isActive || !integrationsBundleEnabled}
        toolTip={
          <div>
            <i
              className="icon-help-outline fs16 gray-light-color ibm"
              data-tooltip-content=""
              data-tooltip-id="managedAppIntegration"
            />
          </div>
        }
      />

      <Box>
        <ReactTooltip id="managedAppIntegration">
          <span>Contact ManagedApp support to get agency id</span>
        </ReactTooltip>
      </Box>

      {isEnabled && (
        <>
          <div className="alert alert-danger">
            Agency ID Key from {ManagedAppId}
          </div>
          <div className="input-box">
            <input
              disabled={isActive}
              type="text"
              required
              value={managedAgencyId || ''}
              onChange={updateManagedAppAgencyId}
            />
          </div>
          <Box mb={4}>
            <ErrorBox error={apiErr} showError={!!apiErr} />
          </Box>

          <Form.Button
            className="mb10 mr10"
            onClick={isActive ? deactivate : activate}
            disabled={!isActive && !managedAgencyId}
            label={isActive ? 'Deactivate' : 'Activate'}
            loading={isLoading}
          />
        </>
      )}
    </Box>
  )
}
