import React, { Component } from 'react'

import ManagerForm from 'app/bond_cover/renters/request/manager_form.jsx'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import isEmptyObject from 'app/shared_components/check_empty_object'
import getParameter from 'app/shared_components/parameter_parser'
import * as validations from 'app/shared_components/validations'

class ManagerFormContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requestID: null,
      managerType: 1,
      agencyName: '',
      agencyGUID: '',
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      disabled: true,
      clicked: false,
      errors: {},
      apiError: '',
      unsavedChanges: false,
    }
  }

  UNSAFE_componentWillMount() {
    let { fetchManager } = this.props
    let requestID = getParameter('requestid')
    this.setState({ requestID: requestID })
    if (this.guid()) {
      fetchManager(requestID, this.guid())
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { manager, apiError } = nextProps

    this.setState({ apiError: apiError, clicked: false })

    if (manager && manager.guid && this.state.mobile === '') {
      this.setState({
        managerType: manager.managerType,
        agencyName: manager.agencyName,
        agencyGUID: manager.agencyID,
        firstName: manager.firstName,
        lastName: manager.lastName,
        email: manager.email,
        mobile: manager.mobile,
      })
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.managerType === 1) {
      nextState.disabled = !(
        nextState.agencyName &&
        nextState.firstName &&
        nextState.lastName &&
        nextState.email &&
        nextState.mobile &&
        isEmptyObject(nextState.errors) &&
        !nextState.clicked
      )
    } else {
      let errorStateForOwner = nextState.errors
      !!errorStateForOwner.agencyName && delete errorStateForOwner.agencyName
      nextState.disabled = !(
        nextState.firstName &&
        nextState.lastName &&
        nextState.email &&
        nextState.mobile &&
        isEmptyObject(errorStateForOwner) &&
        !nextState.clicked
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors.mobile !== this.state.errors.mobile) {
      this.setValidationErrors('mobile', [], false)
    }
  }

  onCountrySelected = () => {
    this.setState({
      errors: {
        mobile: [
          ErrorMessages[Errors.ErrorWhenConvertingMobileToInternationalFormat],
        ],
      },
    })
  }

  onMobileChange = () => {
    return (status, value, countryData, number, id) => {
      this.setState({ mobile: number.trim() })
      this.validatePhoneNumber()
    }
  }

  setValidationErrors = (field, error) => {
    let { errors } = this.state
    if (error.length === 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  guid() {
    return getParameter('id')
  }

  submit = () => {
    const { manager, addManager, updateManager } = this.props
    const { requestID } = this.state

    if (this.validateAll()) {
      this.setState({ clicked: true, unsavedChanges: false })

      if (manager && manager.email) {
        updateManager(requestID, this.state, manager.guid)
      } else {
        addManager(requestID, this.state)
      }
    }
  }

  trimField = (field) => {
    let userInfo = ''
    let value = ''
    return (event) => {
      if (!!event.target.value) {
        value = this.state[field].trim()
      }
      if (field === 'email') {
        userInfo = this.props.currentUser.email
      } else if (field === 'mobile') {
        userInfo = this.props.currentUser.mobile
      }
      this.validate(field, value, userInfo)()
      this.setState({ [field]: value })
    }
  }

  update = (field) => {
    return (event) => {
      let { value } = event.target
      this.setState(
        { [field]: value, unsavedChanges: true },
        this.validate(field, value),
      )
    }
  }

  updateAgency = (agency) => {
    let { value, guidID } = agency
    this.setState({
      agencyName: value,
      agencyGUID: guidID,
      unsavedChanges: true,
    })
  }

  updateEmail = (field) => {
    return (event) => {
      let { value } = event.target
      value = value.toLowerCase()
      this.setState(
        { [field]: value, unsavedChanges: true },
        this.validate(field, value),
      )
    }
  }

  updateNumber = (field) => {
    return (event) => {
      let { value } = event.target
      this.setState({ [field]: parseInt(value), unsavedChanges: true })
    }
  }

  validate = (field, value, userInfo) => {
    return () => {
      switch (field) {
        case 'agencyName':
          return this.setValidationErrors(
            field,
            validations.validateAgencyName(value),
          )
        case 'firstName':
          return this.setValidationErrors(
            field,
            validations.validateFirstName(value),
          )
        case 'lastName':
          return this.setValidationErrors(
            field,
            validations.validateLastName(value),
          )
        case 'email':
          return this.setValidationErrors(
            field,
            validations.validateEmail(value, userInfo),
          )
        default:
          return null
      }
    }
  }

  validateAll = () => {
    let valid = true
    let fields = ['agencyName', 'firstName', 'lastName', 'email']

    if (this.state.managerType === 2) {
      fields = ['firstName', 'lastName', 'email']
    }

    fields.forEach((field) => {
      if (!this.validate(field, this.state[field])()) {
        valid = false
      }
    })
    return valid
  }

  validatePhoneNumber = () => {
    return (status, value, countryData, number, id) => {
      this.setValidationErrors(
        'mobile',
        status
          ? []
          : [
              ErrorMessages[
                Errors.ErrorWhenConvertingMobileToInternationalFormat
              ],
            ],
      )
    }
  }

  render() {
    return (
      <ManagerForm
        managerType={this.state.managerType}
        agencyName={this.state.agencyName}
        updateAgency={this.updateAgency}
        firstName={this.state.firstName}
        lastName={this.state.lastName}
        email={this.state.email}
        mobile={this.state.mobile}
        errors={this.state.errors}
        apiError={this.state.apiError}
        disabled={this.state.disabled}
        clicked={this.state.clicked}
        updateNumber={this.updateNumber}
        updateEmail={this.updateEmail}
        trimField={this.trimField}
        update={this.update}
        submit={this.submit}
        requestID={this.state.requestID}
        backUrl={this.props.backUrl}
        searchFunction={this.props.searchAgencies}
        unsavedChanges={this.state.unsavedChanges}
        onCountrySelected={this.onCountrySelected}
        validatePhoneNumber={this.validatePhoneNumber}
        onMobileChange={this.onMobileChange}
      />
    )
  }
}

export default ManagerFormContainer
