import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getViewings = () => {
  const url = `${apiBaseUrl}sm/viewing/summary`

  return fetch(url, {
    credentials: 'include',
    headers: new Headers().append('Content-Type', 'application/json'),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (viewings) => ({ ok: true, responseText: '', viewings }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
