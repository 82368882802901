import React from 'react'

import PropTypes from 'prop-types'

import 'app/match/applicationReportPDF/components/TitleHorizontalLine/style.scss'

const propTypes = {
  title: PropTypes.string.isRequired,
}

const TitleHorizontalLine = ({ title, ...props }) => {
  return (
    <div className="title-horizontal-line">
      <span className="title-horizontal-line__title">{title}</span>
      <div className="width100 title-horizontal-line__line" />
    </div>
  )
}

TitleHorizontalLine.propTypes = propTypes

export default TitleHorizontalLine
