import React from 'react'

import styled from 'styled-components'

import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import * as snugNotifier from 'app/services/snugNotifier'
import Spinner from 'app/sm/common/spinner'
import * as helpers from 'app/sm/helpers'

const customisableOption = 'Other - describe'
const defaultOptionIndex = 0

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #5a9bee;
  text-decoration: underline;

  &:hover,
  :focus {
    cursor: pointer;
    outline: none;
    color: #1771e4;
    text-decoration: underline;
  }
  @media (max-width: 767px) {
    font-size: 16px !important;
  }
`

export const options = [
  '',
  'Bedroom',
  'Bathroom',
  'Kitchen',
  'Lounge',
  'Dining',
  'Laundry',
  'Entry / Hall',
  'Carport / Garage',
  'Yard / Courtyard',
  'Roof',
  'N/A',
  'Other - describe',
]

const spinnerType = {
  noteHeaderSpinner: 'noteHeaderSpinner',
  noteBodySpinner: 'noteBodySpinner',
}

const checkboxOptionCollection = [
  {
    label: 'Property',
    field: 'property',
  },
  {
    label: 'Tenancy',
    field: 'tenancy',
  },
  {
    label: 'Viewing',
    field: 'viewing',
  },
]
class AddNoteModalContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      comment: '',
      location: options[defaultOptionIndex],
      noteType: helpers.noteActionType.property,
      images: [],
      noteHistory: [],
      noteHeaderSpinner: false,
      noteBodySpinner: false,
      errors: {},
      teamMembers: [],
      assignedTeamMemberGUID: '',
    }
    this.onNoteUpdate = this.onNoteUpdate.bind(this)
    this.onClickSave = this.onClickSave.bind(this)
    this.onImgRemoveIconClicked = this.onImgRemoveIconClicked.bind(this)
    this.resetFormState = this.resetFormState.bind(this)
  }

  componentDidMount = () => {
    const {
      activeViewingID = '',
      propertyId = '',
      fetchAllNotesForPropertyAndViewing,
      currentTeam,
    } = this.props
    this.toggleSpiner(spinnerType.noteBodySpinner)
    this.fetchAndSetManagerList(currentTeam)
    fetchAllNotesForPropertyAndViewing(propertyId, activeViewingID)
      .then((data) => {
        this.toggleSpiner(spinnerType.noteBodySpinner)
        this.setState({
          noteHistory: data,
        })
      })
      .catch((error) => {
        this.toggleSpiner(spinnerType.noteBodySpinner)
        snugNotifier.error(error)
      })
  }

  onClickSave = () => {
    const {
      comment = '',
      location = defaultOptionIndex,
      noteType = helpers.noteActionType.unspecified,
      images,
      locationDescription = '',
      assignedTeamMemberGUID = '',
    } = this.state
    const {
      createNote,
      activeViewingID = '',
      propertyId = '',
      fetchAllNotesForPropertyAndViewing,
    } = this.props
    const noteCategory =
      noteType === helpers.noteActionType.tenancy ||
      noteType === helpers.noteActionType.property
        ? helpers.noteOwnerType.Property
        : helpers.noteOwnerType.Viewing

    const sourceGuid =
      noteCategory === helpers.noteOwnerType.Property
        ? propertyId
        : activeViewingID

    if (comment === '') {
      snugNotifier.error('Comment cannot be empty')
      return
    }

    if (noteType === helpers.noteActionType.property && !location) {
      snugNotifier.error('Please select the location')
      return
    }

    if (
      options[location] === customisableOption &&
      locationDescription === ''
    ) {
      snugNotifier.error('Please describe the location')
      return
    }
    const payload = {
      type: noteType,
      content: comment,
      metadata: {
        location: '',
      },
      assignedTeamMemberGUID,
    }

    if (noteType === helpers.noteActionType.property) {
      payload.metadata.location =
        options[location] === customisableOption
          ? locationDescription
          : options[location]
    }

    this.toggleSpiner(spinnerType.noteHeaderSpinner)

    createNote(payload, sourceGuid, noteCategory, images)
      .then(() => {
        snugNotifier.success('The note has been added successfully.')
        fetchAllNotesForPropertyAndViewing(propertyId, activeViewingID).then(
          (data) => {
            this.toggleSpiner(spinnerType.noteHeaderSpinner)
            this.setState({
              noteHistory: data,
            })
          },
        )
        this.resetFormState()
      })
      .catch((error) => {
        this.toggleSpiner(spinnerType.noteHeaderSpinner)
        snugNotifier.error(error)
      })
  }
  onImgRemoveIconClicked = (chosenIndex) => {
    const filteredImages = this.state.images.filter(
      (_, index) => index !== chosenIndex,
    )
    this.setState({
      images: filteredImages,
    })
  }

  onNoteUpdate = (field, option = {}) => {
    const { type = 0 } = option
    return (data) => {
      if (field === 'noteType') {
        this.setState({
          [field]: type,
        })
        return
      }
      if (field === 'images') {
        const notAllFileImage = data.find((file) => !helpers.isFileImage(file))
        if (notAllFileImage) {
          snugNotifier.error('Please make sure all files are pictures.')
          return
        }
        const updateFiles = data.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        )
        this.setState({ images: this.state.images.concat(updateFiles) })
        return
      }
      this.setState({
        [field]: data.value,
        errors: {
          ...this.state.errors,
          [field]: data.error,
        },
      })
    }
  }

  fetchAndSetManagerList = (currentTeam) => {
    const currentTeamGUID = currentTeam ? currentTeam.guid : ''
    const teamMembers = []
    if (currentTeamGUID) {
      this.props
        .fetchTeamManagerList(currentTeamGUID)
        .then(({ managerList }) => {
          if (managerList) {
            managerList.forEach((manager) => {
              if (manager.profile) {
                teamMembers.push({
                  name: `${manager.profile.firstName} ${manager.profile.lastName}`,
                  guid: manager.guidID,
                })
              }
            })
          }
          this.setState({ teamMembers })
        })
        .catch((error) => {
          this.setState({ error })
        })
    }
  }

  resetFormState = () => {
    this.setState({
      images: [],
      comment: '',
      location: options[defaultOptionIndex],
      noteType: helpers.noteActionType.property,
      assignedTeamMemberGUID: '',
    })
  }

  toggleSpiner = (spinnerType) => {
    this.setState({
      [spinnerType]: !this.state[spinnerType],
    })
  }

  render() {
    const {
      comment = '',
      noteType = helpers.noteActionType.property,
      location,
      assignedTeamMemberGUID,
      images = [],
      noteHistory = [],
      noteHeaderSpinner = false,
      noteBodySpinner = false,
      locationDescription = '',
      teamMembers,
      errors: { locationDescription: locationDescriptionError },
    } = this.state

    const {
      turnOffAllModals,
      onModalTitleClicked,
      showTopHeader = true,
    } = this.props
    return (
      <div className="modal-fullscreen background-dark-grey modal-layout-flex-container viewing-view">
        <ModalContentWrapper takeMinHeight>
          <div className="modal-close top5">
            <i
              className="fa fa-times font-size-24"
              onClick={() => turnOffAllModals()}
            />
          </div>
          {showTopHeader ? (
            <div>
              <div className="font-size-18 fw500 position-absolute left15 top20 mobile-font-size-16">
                Add:
              </div>
              <div className="modal-header display-flex justify-content-space-around align-items-center ml5">
                <Title
                  onClick={() =>
                    onModalTitleClicked(
                      helpers.modalNameCollection.attendeeModal,
                    )
                  }
                >
                  Attendee
                </Title>
                <Title className="fw700">Note</Title>
                <Title
                  onClick={() =>
                    onModalTitleClicked(
                      helpers.modalNameCollection.addAccessModal,
                    )
                  }
                >
                  Access Details
                </Title>
              </div>
            </div>
          ) : (
            <div className="modal-header">
              <h4 className="standard-subheading">Notes</h4>
            </div>
          )}
          <div className="modal-body p15 viewing-card pb90">
            <div className="search-section-container mb10">
              <Form.CommentArea
                value={comment}
                placeholder="Your comment"
                rows={5}
                onChange={this.onNoteUpdate('comment')}
                id="content"
              />
            </div>
            <div className="display-flex mt30 justify-content-space-around mb20">
              {checkboxOptionCollection.map((option, index) => (
                <Form.CheckBoxGeneral
                  key={index}
                  label={option.label}
                  checked={noteType === helpers.noteActionType[option.field]}
                  onChange={this.onNoteUpdate('noteType', {
                    type: helpers.noteActionType[option.field],
                  })}
                />
              ))}
            </div>
            {noteType === helpers.noteActionType.property && (
              <div className="mb30">
                <Form.Dropdown
                  placeholder="Location"
                  label="Please select a location"
                  value={location}
                  options={options}
                  onChange={this.onNoteUpdate('location')}
                  inputClass="width100"
                />
                {options[location] === customisableOption && (
                  <Form.InputTextRequired
                    value={locationDescription}
                    error={locationDescriptionError}
                    onChange={this.onNoteUpdate('locationDescription')}
                    id="location_description"
                    label="Describe the location"
                    labelClass="top18"
                    inputClass="width100"
                  />
                )}
              </div>
            )}
            <div className="mb30">
              <Form.Dropdown
                placeholder="Team Member"
                label="Assign to team member (optional)"
                value={assignedTeamMemberGUID}
                options={teamMembers}
                onChange={this.onNoteUpdate('assignedTeamMemberGUID')}
                inputClass="width100"
                idAsValue={true}
              />
            </div>
            <div>
              <Display.DropZoneBasic
                onDrop={this.onNoteUpdate('images')}
                files={images}
                onRemoveIconClciked={this.onImgRemoveIconClicked}
              />
            </div>
            {noteHistory && noteHistory.length > 0 && (
              <div className="mt30">
                <div className="font-size-18 fw500">Previous notes</div>
                {(noteHeaderSpinner || noteBodySpinner) && <Spinner />}
                {!noteBodySpinner && noteHistory.length === 0 && (
                  <p className="mt10 text-align-center">There is no note yet</p>
                )}
                {!noteBodySpinner &&
                  noteHistory
                    .sort(helpers.sortArrayByDate)
                    .map((note, index) => {
                      return (
                        <Display.Note
                          key={index}
                          note={note}
                          from="propertyViewing"
                          componentClass={
                            'width100 max-width-none m0 mb10 mt10'
                          }
                        />
                      )
                    })}
              </div>
            )}
          </div>

          <div className="modal-footer border-none width100 display-flex justify-content-center">
            <div className="min-width120p">
              <Display.GenericButton
                text="Save"
                onClick={this.onClickSave}
              ></Display.GenericButton>
            </div>
          </div>
        </ModalContentWrapper>
      </div>
    )
  }
}

export default AddNoteModalContainer
