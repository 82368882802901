import { FilterAny } from 'app/bond_cover/agency/agency_utils'
import {
  RESET_PROPERTY_FILTERS,
  SET_PROPERTY_FILTERS,
} from 'app/dashboard/team_overview_actions'

export const propertyFilters = {
  MANAGER_DEFAULT: FilterAny,
  SEARCH_TEXT_DEFAULT: '',
  WORKFLOW_DEFAULT: -1,
  SHOW_ALL_DEFAULT: false,
  TODAY_ONLY_DEFAULT: false,
  DATE_PICKER_VALUE: '',
  DATE_OPTION: '0',
  ARCHIVED_MESSAGES: false,
  UNREGISTERED_MESSAGES: false,
  NO_NOTES_MESSAGES: false,
  VIEWING_AVAILABLE: false,
  HAS_MOBILE_NUMBER: false,
  DECLINED_AND_WITHDRAWN_APPLICATIONS: false,
  APPLICANT_EMAIL: '',
}

export const defaultPropertyFilters = {
  manager: propertyFilters.MANAGER_DEFAULT,
  searchText: propertyFilters.SEARCH_TEXT_DEFAULT,
  workflow: propertyFilters.WORKFLOW_DEFAULT,
  showAllTab: propertyFilters.SHOW_ALL_DEFAULT,
  todayOnly: propertyFilters.TODAY_ONLY_DEFAULT,
  datePickerValue: propertyFilters.DATE_PICKER_VALUE,
  dateOption: propertyFilters.DATE_OPTION,
  archived: propertyFilters.ARCHIVED_MESSAGES,
  unRegistered: propertyFilters.UNREGISTERED_MESSAGES,
  noNotes: propertyFilters.NO_NOTES_MESSAGES,
  viewingAvailable: propertyFilters.VIEWING_AVAILABLE,
  hasMobileNumber: propertyFilters.HAS_MOBILE_NUMBER,
  showDeclinedAndWithdrawnApplications:
    propertyFilters.DECLINED_AND_WITHDRAWN_APPLICATIONS,
  applicantEmail: propertyFilters.APPLICANT_EMAIL,
}

const _defaultState = {
  filters: defaultPropertyFilters,
}

const TeamOverviewReducer = (state = _defaultState, action) => {
  Object.freeze(state)
  switch (action.type) {
    case SET_PROPERTY_FILTERS:
    case RESET_PROPERTY_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.filters),
      })
    default:
      return state
  }
}

export default TeamOverviewReducer
