import { LogFetchError } from 'app/shared_components/helpers'
import * as sharedHelpers from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getAllViewingsForCheckin = (agencySlug, cursor, limit = 3) => {
  const url = `${apiBaseUrl}sm/teams/${agencySlug}/checkinviewings?limit=${limit}&cursor=${cursor}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}
