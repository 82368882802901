import React from 'react'

import * as Display from 'app/components/display/display'

const ApplicationAttachments = ({
  files = [],
  onDrop = undefined,
  onRemoveIconClciked = undefined,
  title = '',
  componentClass = '',
}) => (
  <div className={componentClass}>
    <Display.GreySubheading text={title} textClass="position-relative p0" />
    <Display.DropZoneSnugStandard
      files={files}
      onDrop={onDrop}
      onRemoveIconClciked={onRemoveIconClciked}
    />
  </div>
)

export default ApplicationAttachments
