import React from 'react'

import PropTypes from 'prop-types'

import Card from 'app/match/applicationReportPDF/components/Card'
import InfoList from 'app/match/applicationReportPDF/components/InfoList'
import Persona from 'app/match/applicationReportPDF/components/Persona'
import TitleHorizontalLine from 'app/match/applicationReportPDF/components/TitleHorizontalLine'

const propTypes = {
  data: PropTypes.array,
}

const StudentDetails = ({ data = [] }) => {
  const studentSectionContent = data.map((studentInfo, index) => {
    const { university, type, enrolmentNumber, duration } = studentInfo.details
    return (
      <div className="student-details mb20" key={index}>
        <span className={'list-title'}>{university}</span>
        <InfoList
          data={{
            type,
            enrolmentNumber,
            duration,
          }}
        />
        <div className="contact-archive">
          {studentInfo.campusContact && (
            <div>
              <TitleHorizontalLine title="Campus Contact" />
              <Persona info={studentInfo.campusContact} />
            </div>
          )}
          {studentInfo.courseCoordinator && (
            <div>
              <TitleHorizontalLine title="Cource Coordinator" />
              <Persona info={studentInfo.courseCoordinator} />
            </div>
          )}
        </div>
      </div>
    )
  })

  return (
    <div>
      <Card
        type={'icon'}
        icon={'icon-books'}
        title="Student Details"
        addClass="bottom-border"
      />
      {studentSectionContent}
    </div>
  )
}

StudentDetails.propTypes = propTypes

export default StudentDetails
