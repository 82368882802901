import React from 'react'

import styled from 'styled-components'

import {
  Button,
  ButtonWithLoading,
  Flex,
} from 'app/components/design-system-components/index'

export const StyledButtonPair = styled(Flex)`
  button {
    width: ${({ theme }) => theme.width[15] + 'px'};
  }

  button:first-child {
    margin-right: ${({ theme }) => theme.space[3]}px;
  }

  @media screen and (max-width: ${({ theme }) => theme.maxWidths[6] + 'px'}) {
    flex-direction: column-reverse;
    button {
      width: 100%;
    }
    button:first-child {
      margin-top: ${({ theme }) => theme.space[3]}px;
    }
  }
`

export const ButtonPair = ({
  primaryFn,
  primaryLabel,
  isSubmitting,
  secondaryFn,
  secondaryLabel,
  ...props
}) => {
  return (
    <StyledButtonPair mt="40px" {...props}>
      <Button
        variant="solid"
        disabled={isSubmitting}
        type="button"
        onClick={secondaryFn}
      >
        {secondaryLabel}
      </Button>
      <ButtonWithLoading
        type="button"
        variant="solidSuccessStrong"
        loading={isSubmitting}
        disabled={isSubmitting}
        disableWhenLoading
        onClick={primaryFn}
      >
        {primaryLabel}
      </ButtonWithLoading>
    </StyledButtonPair>
  )
}
