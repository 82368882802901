import React from 'react'

import { Link } from 'react-router-dom'

import Spinner from 'app/sm/common/spinner'
import {
  ApplicationCategory,
  clusterApplications,
  findTeamByAgencyId,
  isApplicationDeclined,
} from 'app/sm/helpers'
import ApplicationsCard from 'app/sm/renter_applications/components/applications_card'
import NoApplicationsHelper from 'app/sm/renter_applications/components/no-applications-helper'
import RATabs from 'app/sm/renter_applications/components/ra_tabs'
import Properties from 'app/sm/renter_applications/renter_application_detailed/components/properties'

function renderApplicationsTab(
  clusterKeys,
  clusters,
  onClickRequestInfo,
  props,
) {
  const activeTab = props.tabsCtrl.find((t) => t.isActive) || {}
  const propertyId = props.match.params.propertyId
  return clusterKeys.map((cluster, i) => (
    <ApplicationsCard
      key={i}
      propertyId={propertyId}
      applications={clusters[cluster] || []}
      cluster={cluster}
      onStatusDropdownChange={props.onStatusDropdownChange}
      tab={activeTab.id}
      onClickRequestInfo={onClickRequestInfo}
    />
  ))
}

function renderRegularTab(applications, tabsCtrl, onClickRequestInfo, props) {
  const activeTab = tabsCtrl.find((t) => t.isActive) || {}
  const shown = applications.filter(
    (a) => ApplicationCategory[a.status] === activeTab.id,
  )
  const clusters = clusterApplications(shown)
  let clusterKeys = []
  if (Object.keys(clusters).length !== 0) {
    clusterKeys = ['great', 'good', 'ok', 'not suitable']
  }
  return clusterKeys.map((cluster, i) => (
    <ApplicationsCard
      key={i}
      propertyId={props.match.params.propertyId}
      applications={clusters[cluster] || []}
      cluster={cluster}
      onStatusDropdownChange={props.onStatusDropdownChange}
      isOfferTab={activeTab.id === 'Offered'}
      tab={activeTab.id}
      onClickRequestInfo={onClickRequestInfo}
    />
  ))
}

const isApplicationTabFocused = (tabsCtrl) => !!tabsCtrl.find((t) => t.isActive)

class RenterApplications extends React.Component {
  componentDidMount() {
    const { propertyId } = this.props.match.params
    this.props.fetchPropertyApplications(propertyId, 'Applications')
    this.props.setPropertyId(propertyId)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { propertyId } = nextProps.match.params
    if (propertyId !== this.props.match.params.propertyId) {
      this.props.fetchPropertyApplications(propertyId, 'Applications')
      this.props.setPropertyId(propertyId)
    }

    if (this.props.property && this.props.property.guidID) {
      // update team according the property's agencyID
      if (
        nextProps.teams !== this.props.teams ||
        nextProps.property.agencyID !== this.props.property.agencyID
      ) {
        // update team according to property
        const currentTeam = findTeamByAgencyId(
          nextProps.teams,
          nextProps.property.agencyID,
        )
        this.props.changeTeam(currentTeam)
      }
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  onClickRequestInfo = (applicationGUID) => {
    const { applications } = this.props
    const requestedApplication = applications.find(
      (application) => application.guidID === applicationGUID,
    )
    const primaryApplicant = requestedApplication.applicants
      .filter((applicant) => applicant.isPrimary)
      .shift()
    if (
      window.confirm(
        `Do you want to request application from ${primaryApplicant.firstName} ${primaryApplicant.lastName}?`,
      )
    ) {
      this.props.requestRenterInformation(
        applicationGUID,
        primaryApplicant.guidID,
        {
          fullApplication: true,
          employment: [
            { name: 'Payslips', value: false },
            { name: 'Employment Contract', value: false },
            { name: 'Letter from Employer', value: false },
          ],
          identification: [
            { name: 'Background Check', value: false },
            { name: 'Visa Details', value: false },
            { name: 'Identity documents', value: false },
          ],
          rentalHistory: [{ name: 'Rental payment ledger', value: false }],
          other: false,
          message: '',
        },
      )
    }
  }

  render() {
    const {
      tabsCtrl,
      shown,
      applications,
      error,
      property,
      property: { slug: propertySlug },
      spinner = false,
      fetchProfile,
      profile,
      updatePreferences,
      applicationsSpinner,
    } = this.props

    const noApplications =
      (applications || []).filter((a) => !isApplicationDeclined(a)).length === 0
    const activeTab = tabsCtrl.find((t) => t.isActive) || {}
    const isOfferTab = activeTab.id === 'Offered'

    const clusters = clusterApplications(
      applications.filter(
        (application) =>
          ApplicationCategory[application.status] === activeTab.id,
      ),
    )
    let clusterKeys = []
    if (Object.keys(clusters).length !== 0) {
      clusterKeys = ['great', 'good', 'ok', 'not suitable']
    }

    const renderTab = isApplicationTabFocused(tabsCtrl)
      ? renderApplicationsTab(
          clusterKeys,
          clusters,
          this.onClickRequestInfo,
          this.props,
        )
      : renderRegularTab(shown, tabsCtrl, this.onClickRequestInfo, this.props)

    return spinner ? (
      <Spinner />
    ) : (
      <div className="sm-renter-applications">
        <div className="sm-ra-header">
          <Link
            to="/portfolio/overview"
            className="btn btn-transparent btn-left xs-text-center wa"
          >
            <i className="icon-arrow-left left"></i>
            <span>Back</span>
          </Link>
        </div>
        {!noApplications && property && (
          <div className="sm-applications-detailed">
            <Properties
              property={property}
              fetchProfile={fetchProfile}
              profile={profile}
              updatePreferences={updatePreferences}
            />
          </div>
        )}
        {!noApplications && (
          <RATabs
            labels={tabsCtrl}
            onChange={(id) => this.props.onTabChange(id)}
          />
        )}

        {applicationsSpinner ? <Spinner /> : renderTab}

        {isOfferTab ? (
          <div className="sm-ra-card__info">
            *First applicant to accept and pay deposit will get the property
          </div>
        ) : (
          ''
        )}

        {noApplications && <NoApplicationsHelper propertySlug={propertySlug} />}
        <div className="row">
          <div className="col-sm-12">
            <div className={error ? 'alert alert-danger' : ''}>
              <div>{error}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RenterApplications
