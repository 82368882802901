import React from 'react'

import { Modal } from 'app/components/design-system-components'
import InviteTeamMembersForm from 'app/sm/team_viewing_preference/setup/inviteTeamMemberForm'

const WAIT_TO_CALL_SUCCESSFUL_INVITE_SECS = 1500

function InviteTeamMembersModal({
  toggleModal,
  teamGUID = '',
  initialInvite = {},
  onSuccessfulInvite,
  primaryAction,
  cancelAction,
  primarButtonClass = '',
}) {
  const onSubmitForm = () => {
    if (onSuccessfulInvite) {
      setTimeout(() => {
        onSuccessfulInvite()
        toggleModal()
      }, WAIT_TO_CALL_SUCCESSFUL_INVITE_SECS)
    } else {
      toggleModal()
    }
  }

  const modalBody = (
    <InviteTeamMembersForm
      teamGUID={teamGUID}
      successAction={(email) => {
        primaryAction && primaryAction(email)
        onSubmitForm()
      }}
      cancelAction={() => {
        cancelAction && cancelAction()
        toggleModal()
      }}
      initialInvite={initialInvite}
    />
  )

  return (
    <Modal
      modalBody={modalBody}
      toggleModal={() => {
        cancelAction && cancelAction()
        toggleModal()
      }}
      modalHeading="Invite team members"
      showPrimaryButton={false}
      showSecondaryButton={false}
      customModalFooter={true}
      primaryButtonClassName={primarButtonClass}
    />
  )
}
export default InviteTeamMembersModal
