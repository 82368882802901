import React from 'react'

import BackButton from 'app/bond_cover/renters/sharedComponent/back_button'
import { history } from 'app/shared_components/router'
import {
  isApplicationSubmitable,
  isApplicationUpdateable,
  isPrimaryApplicant,
  isSecondaryApplicant,
  isStatusApplied,
  isStatusDeletable,
  isStatusOffered,
  isStatusWithdrawableByRenter,
  urlTo,
} from 'app/sm/helpers'

const ApplyButton = ({
  disabled,
  clicked,
  className,
  id,
  onClick,
  label,
  description,
  icon,
}) => (
  <div>
    <button
      disabled={disabled || clicked}
      className={`p0 ${className}`}
      id={id}
      onClick={onClick}
    >
      <span className="apply-form-button-label">
        {label}
        {clicked && <i className={clicked ? 'fa fa-spinner fa-pulse' : icon} />}
      </span>
      {description && (
        <small className="apply-form-button-description">{description}</small>
      )}
    </button>
  </div>
)

export const DashboardModal = ({
  toggleModal,
  onButtonClick,
  applicationId,
  disabled,
  label,
  applicantId,
  isSecondaryApplicant,
}) => {
  return (
    <div className="modal-fullscreen-withdraw">
      <div className="modal-wrapper-withdraw">
        <div className="modal-close">
          <i className="fa fa-times" onClick={toggleModal} />
        </div>
        <div className="modal-header width100">
          <h4>Are you sure?</h4>
        </div>
        <div className="modal-body">
          <button
            disabled={disabled}
            className="btn mt10"
            onClick={() =>
              onButtonClick(applicationId, applicantId, isSecondaryApplicant)
            }
          >
            {label}
            <i className={disabled ? 'fa fa-spinner fa-pulse' : ''} />
          </button>
        </div>
      </div>
    </div>
  )
}

class ApplyForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      disabled,
      applicationStatus,
      clicked,
      onSecondaryButtonClick,
      applicationId,
      secondaryButtonClicked,
      teamSlug,
      from,
      isApplyAnywhere,
      urlQueryString,
      applicant,
      isApplicationCreatedByManager,
      ballotsEnabled,
      validateBallotAppAndGoToNextPage,
    } = this.props

    const fromRenterSummary = from === 'rentersummary'
    const applyAnywhereSaveContinueTagClass = isApplyAnywhere
      ? 'apply-anywhere-rental-application-save-btn'
      : 'rental-application-save-btn'
    const buttonClass = isStatusApplied(applicationStatus)
      ? 'summary-green-button-class'
      : 'summary-green-button-class ' + applyAnywhereSaveContinueTagClass
    const showSave =
      (isApplicationSubmitable(applicationStatus) ||
        isApplicationUpdateable(applicationStatus)) &&
      !fromRenterSummary &&
      !isApplicationCreatedByManager
    const showUpdate =
      isPrimaryApplicant(applicant) &&
      isApplicationUpdateable(applicationStatus) &&
      fromRenterSummary &&
      !isApplicationCreatedByManager
    const showOffPlatformApplicationUpdate =
      isApplicationCreatedByManager &&
      isApplicationUpdateable(applicationStatus)
    const showReview =
      isPrimaryApplicant(applicant) &&
      isStatusOffered(applicationStatus) &&
      !isApplicationCreatedByManager
    const showSubmit = isApplicationCreatedByManager
    const showButton = showSave || showUpdate || showReview || showSubmit

    const labelHandler = () => {
      switch (true) {
        case showSave:
          return 'Save & continue'
        case showUpdate:
          return 'Update'
        case showReview:
          return 'Review offer'
        case showOffPlatformApplicationUpdate:
          return 'Update'
        case showSubmit:
          return 'Submit application'
        default:
          return 'Unknown'
      }
    }

    const clickHandler = () => {
      switch (true) {
        // Starting a new application
        case isApplicationSubmitable(applicationStatus) &&
          !isApplyAnywhere &&
          !fromRenterSummary:
          return this.props.onApplyNextButtonClicked
        // Starting a new apply anywhere application
        case isApplicationSubmitable(applicationStatus) &&
          isApplyAnywhere &&
          !fromRenterSummary:
          return this.props.onApplyAnywhereNextClicked
        case isPrimaryApplicant(applicant) &&
          isApplicationSubmitable(applicationStatus) &&
          !isApplyAnywhere:
          return this.props.onApplyNextButtonClicked
        case isPrimaryApplicant(applicant) &&
          isApplicationSubmitable(applicationStatus) &&
          isApplyAnywhere:
          return this.props.onUpdateApplication
        case isPrimaryApplicant(applicant) &&
          isApplicationUpdateable(applicationStatus):
          return this.props.onUpdateApplication
        case isSecondaryApplicant(applicant) &&
          isApplicationSubmitable(applicationStatus):
          // should never really end up in this state as secondary applicant
          // shouldn't see the application until the primary has applied
          return this.props.onApplyNextButtonClickedAsSecondaryApplicant
        case !isApplicationCreatedByManager &&
          isSecondaryApplicant(applicant) &&
          isApplicationUpdateable(applicationStatus) &&
          !fromRenterSummary &&
          !ballotsEnabled:
          return () =>
            history.push(
              `${urlTo(
                'rentalHistorySummary',
              )}${urlQueryString}&applicationId=${applicationId}&stage=essentials`,
            )
        case !isApplicationCreatedByManager &&
          isSecondaryApplicant(applicant) &&
          isApplicationUpdateable(applicationStatus) &&
          !fromRenterSummary &&
          ballotsEnabled:
          return () => {
            validateBallotAppAndGoToNextPage(applicationId, true)
          }
        case isSecondaryApplicant(applicant) &&
          isApplicationUpdateable(applicationStatus) &&
          fromRenterSummary &&
          !ballotsEnabled:
          return () =>
            history.push(urlTo('applicationSummary', { applicationId }))
        case isApplicationCreatedByManager &&
          isApplicationUpdateable(applicationStatus):
          return this.props.editOffPlatformApplication
        default:
          return () => {
            console.log('Empty function')
          }
      }
    }

    return (
      <div>
        {
          <div className="apply-form-buttons float-right mt5 flex-direction-row">
            <BackButton
              clickBackFunction={() => {
                history.goBack()
              }}
              showArrow={false}
              backButtonClass="summary-grey-button-class tablet-only"
            />
            {showButton && (
              <ApplyButton
                className={buttonClass}
                id={teamSlug}
                disabled={disabled || clicked}
                clicked={clicked}
                onClick={clickHandler()}
                label={labelHandler()}
                icon=""
              />
            )}
          </div>
        }

        {this.props.modalOpened === 'withdrawOrDelete' && (
          <DashboardModal
            toggleModal={() => this.props.toggleModal('withdrawOrDelete')}
            onButtonClick={onSecondaryButtonClick}
            applicationId={applicationId}
            label={
              isStatusWithdrawableByRenter(applicationStatus)
                ? 'Withdraw'
                : isStatusDeletable(applicationStatus)
                ? 'Delete Draft'
                : 'Ok'
            }
            disabled={secondaryButtonClicked}
            applicantId={this.props.applicant.guidID}
            isSecondaryApplicant={isSecondaryApplicant(this.props.applicant)}
          />
        )}
      </div>
    )
  }
}

export default ApplyForm
