import React, { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

import { Box } from 'app/components/design-system-components/index'
import { Scrollbar } from 'app/components/design-system-components/styles/mixins/Scrollbar'

const ShadowScrollBoxContainer = styled(Box)`
  .shadow-overlay {
    position: absolute;
    height: 10px;
    width: 100%;
    z-index: 1;

    &.shadow-top {
      box-shadow: inset 0 8px 5px -5px rgb(0 0 0 / 0.4);
      top: 0;
    }

    &.shadow-bottom {
      box-shadow: inset 0 -8px 5px -5px rgb(0 0 0 / 0.4);
      bottom: 0;
    }
  }

  transition: box-shadow 0.3s;
  position: relative;
  overflow-y: hidden;
`

const ShadowScrollBoxContent = styled(Box)`
  max-height: 100%;
  overflow-y: auto;
  ${Scrollbar()}
`

export const ShadowScrollBox = ({ children, ...props }) => {
  const ref = useRef()
  const [scroll, setScroll] = useState({
    scrolledTop: false,
    scrolledBottom: false,
    scrollYShown: false,
  })

  const checkScrollAndUpdate = () => {
    const containerElem = ref.current

    if (!containerElem) return null

    const { prevScrolledTop, prevScrolledBottom, prevScrollYShown } = scroll
    const { scrollHeight, clientHeight, scrollTop } = containerElem

    const scrollYShown = scrollHeight > clientHeight
    const scrolledTop = !!scrollTop
    const scrolledBottom = scrollTop < scrollHeight - clientHeight

    if (
      prevScrolledTop !== scrolledTop ||
      prevScrolledBottom !== scrolledBottom ||
      prevScrollYShown !== scrollYShown
    )
      setScroll({
        scrolledTop,
        scrolledBottom,
        scrollYShown,
      })
  }

  useEffect(() => {
    const containerElem = ref.current

    if (!containerElem) return null

    checkScrollAndUpdate()

    containerElem.addEventListener('scroll', () => checkScrollAndUpdate())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  const shadowOverlayElem = scroll.scrollYShown ? (
    <>
      {scroll.scrolledTop && (
        <Box
          className="shadow-overlay shadow-top"
          style={{ position: 'absolute', top: 0 }}
        />
      )}
      {scroll.scrolledBottom && (
        <Box
          className="shadow-overlay shadow-bottom"
          style={{ position: 'absolute', bottom: 0 }}
        />
      )}
    </>
  ) : null

  return (
    <ShadowScrollBoxContainer {...props}>
      {shadowOverlayElem}
      <ShadowScrollBoxContent ref={ref}>{children}</ShadowScrollBoxContent>
    </ShadowScrollBoxContainer>
  )
}
