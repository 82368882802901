import * as React from 'react'

function SvgPersonOutlineRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm2.1 4a2.1 2.1 0 10-4.2 0 2.1 2.1 0 004.2 0zm4 9c0-.64-3.13-2.1-6.1-2.1-2.97 0-6.1 1.46-6.1 2.1v1.1h12.2V17zM4 17c0-2.66 5.33-4 8-4s8 1.34 8 4v2c0 .55-.45 1-1 1H5c-.55 0-1-.45-1-1v-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPersonOutlineRounded
