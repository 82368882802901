import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import * as API from 'app/sm/apply_anywhere/apply_anywhere_access'

//TODO: improve this stuff here
export function addApplyAnywhere(
  applyAnywhereListing,
  addApplyAnywhereSuccess,
  addApplyAnywhereFailure,
) {
  return (dispatch) => {
    API.addApplyAnywhere(applyAnywhereListing).then(
      ({ ok, guid, applicationRoute, responseText }) => {
        if (ok) {
          addApplyAnywhereSuccess(guid, applicationRoute)
          return Promise.resolve()
        } else {
          addApplyAnywhereFailure()
          return (responseText) => Promise.reject(responseText)
        }
      },
    )
  }
}

//TODO: improve this stuff here
export function updateApplyAnywhere(
  applyAnywhereListing,
  addApplyAnywhereSuccess,
  addApplyAnywhereFailure,
) {
  return (dispatch) => {
    API.updateApplyAnywhere(applyAnywhereListing).then(
      ({ ok, guid, applicationRoute, responseText }) => {
        if (ok) {
          addApplyAnywhereSuccess(guid, applicationRoute)
          return Promise.resolve()
        } else {
          addApplyAnywhereFailure()
          return (responseText) => Promise.reject(responseText)
        }
      },
    )
  }
}

export function apply(applyAnywhereGUID, applySuccessful, applyFailed) {
  return (dispatch) => {
    API.applyAnywhereApply(
      applyAnywhereGUID,
      applySuccessful,
      applyFailed,
    ).then(({ ok, guid }) => {
      if (ok) {
        applySuccessful(guid)
        return Promise.resolve()
      } else {
        applyFailed()
        return (responseText) => Promise.reject(responseText)
      }
    })
  }
}

export function fetchApplyAnywhereListing(guid, success, failure) {
  return (dispatch) => {
    API.fetchApplyAnywhereListing(guid).then(
      ({ ok, applyAnywhereListing, responseText }) => {
        if (ok) {
          success(applyAnywhereListing)
        } else {
          failure(responseText)
        }
      },
    )
  }
}
