import React from 'react'

class CreditCards extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { selectedCreditCard, cards, renderAllCards = true } = this.props
    let card = null
    if (!renderAllCards) {
      card = cards.find((c) => c.id === selectedCreditCard) || {}
    }

    return (
      <div className="input-box">
        {!renderAllCards && card && (
          <div key={card.id} value={card.id}>
            {card.number}
          </div>
        )}
        {renderAllCards && (
          <select
            value={selectedCreditCard}
            onChange={(e) => this.props.cardSelect(e.target.value)}
          >
            {cards.map((c) => (
              <option key={c.id} value={c.id}>
                {c.number}
              </option>
            ))}
          </select>
        )}
      </div>
    )
  }
}

export default CreditCards
