import React from 'react'

import * as Display from 'app/components/display/display'
import { formatGroupName } from 'app/sm/inspections/components/owner-list'

const ViewingItem = ({
  viewing,
  isBtnRegisteredText,
  showSpinner,
  spinner,
  btnStyles,
  teamSlug,
  btnGuid,
  btnText,
  onActionBtnClicked,
  changeText,
  isUserLoggedIn,
}) => {
  const { cancelled, isFullyBooked, registered } = viewing
  const allowUnregister = !cancelled && !isFullyBooked
  return (
    <div className="table bbs pb20 mb20" id={`viewing-${viewing.guidID}`}>
      <div className="cell-m-100 t-view pr30 lh13">
        {formatGroupName(viewing)}
      </div>
      <div className="cell-m t-view">
        {cancelled && <div className="cancelled">Cancelled</div>}
        {!cancelled && isFullyBooked && (
          <Display.GenericButton
            text="Fully Booked"
            buttonType="ghost"
            size="large"
            componentClass="fully-booked-button"
          />
        )}
        {allowUnregister && (
          <button
            className={`btn btn-medium rental-application-rsvp-view-btn ${btnStyles} btn-light btn-view`}
            id={teamSlug}
            onClick={() => {
              onActionBtnClicked(viewing, isBtnRegisteredText)
            }}
            /* temporarily removed for COVID-19  Precautions (ch33975) */
            // onMouseEnter={() => (!showSpinner && isUserLoggedIn) && changeText && changeText(viewing.guidID, 'Unregister')}
            // onMouseLeave={() => !showSpinner && changeText && changeText(viewing.guidID, isBtnRegisteredText)}
            disabled={showSpinner || (registered && !isUserLoggedIn)}
          >
            {btnGuid === viewing.guidID && viewing.registered ? (
              <span>{btnText}</span>
            ) : (
              <span>{isBtnRegisteredText}</span>
            )}
            {spinner}{' '}
            {viewing.registered && (
              <div className="fs12">{viewing.listedDate}</div>
            )}
          </button>
        )}
      </div>
    </div>
  )
}

export default ViewingItem
