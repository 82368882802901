import { connect } from 'react-redux'

import {
  declineBondCoverRequest,
  fetchBondCoverReview,
} from 'app/bond_cover/bond_cover_actions'
import ReviewForm from 'app/bond_cover/managers/review/bc_review_form_container'

const mapStateToProps = ({ session, bondCover }) => ({
  currentUser: session.currentUser,
  bondCoverRequest: bondCover.request,
  currentLease: bondCover.currentLease,
  renter: bondCover.renter,
  message: bondCover.message,
  error: bondCover.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchBondCoverReview: (requestID, secret) =>
    fetchBondCoverReview(requestID, secret),
  decline: (requestID, secret) =>
    declineBondCoverRequest(null, requestID, secret),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewForm)
