import React, { Component } from 'react'

import qs from 'qs'
import styled from 'styled-components'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import {
  Alert,
  AlertSensitiveInfo,
  Box,
  Button,
  Modal,
} from 'app/components/design-system-components'
import { BallotAlertDocumentUpload } from 'app/components/design-system-components/BallotAlertDocumentUpload'
import { Input } from 'app/components/design-system-components/inputs'
import { TagsInput } from 'app/components/design-system-components/inputs/TagsInput/TagsInput'
import { Text as SystemText } from 'app/components/design-system-components/typography'
import * as Display from 'app/components/display/display'
import * as NewForm from 'app/components/forms/forms'
import { ErrorBox } from 'app/components/forms/util'
import ProgressBarComponent from 'app/components/progress_bar/connection'
import Text from 'app/components/text/text'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import DocumentList from 'app/shared_components/document_list/document_list'
import * as Form from 'app/shared_components/form_component'
import getParameter from 'app/shared_components/parameter_parser'
import Header from 'app/shared_components/rental_reputation_header_connection'
import { history } from 'app/shared_components/router'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import {
  DocumentTypes,
  isFromApplication,
  parseStringToNumber,
  shouldCurrencyUpdate,
  urlTo,
} from 'app/sm/helpers'
import SelectInvestmentProfile, {
  InvProfileBothExistingAndPlanned,
  InvProfileExistingInvestor,
  InvProfileSelect,
} from 'app/sm/proof_of_income/components/SelectInvestmentProfile'
import {
  isAllSufficient,
  WarningModal,
} from 'app/sm/proof_of_income/components/WarningModal'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import { applicationsAttributesIDs } from 'app/sm/team_viewing_preference/applications-attributes.constants'
import {
  payslipReqOptions,
  requireBankStatementOptionsMap,
} from 'app/sm/team_viewing_preference/team_viewing_preference_const'
import { MAX_ANNUAL_INCOME, MAX_WEEKLY_INCOME } from 'app/utils/numbers/helpers'
import { covertBoolStringToBool } from 'app/utils/strings/helpers'
import * as textHelpers from 'app/utils/text/helpers'

const No = 1
const Yes = 2
const TRUE = 'true'
const FALSE = 'false'

const StyledTagsInput = styled.div`
  & label {
    border-color: ${({ error }) =>
      error ? theme.colors.error400 : theme.colors.grey200} !important;
  }
`

class ProofOfIncome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      newPaySlipsUpdated: false,
      newBankStatementsUpdated: false,
      newOtherStatementsAdded: false,
      newGovernmentBenefitDocumentAdded: false,
      otherIncomeTicked: false,
      governmentBenefitTicked: false,
      incomeValues: {
        otherWeeklyIncome: 0,
        otherAnnualIncome: 0,
        governmentWeeklyBenefits: 0,
        governmentAnnualBenefits: 0,
      },
      errors: {
        otherWeeklyIncome: '',
        otherAnnualIncome: '',
        governmentWeeklyBenefits: '',
        governmentAnnualBenefits: '',
        propertyInvestmentsProfile: '',
        savingsBalance: '',
      },
      extraIncomeReferenceId: '',
      unsavedChanges: false,
      teamSettingInfo: {},
      disableNextButtonToFetchSettings: true,
      bankStatementRequire: false,
      payslipsCountRequired: false,
      payslipsDurationRequired: false,
      payslipsMinDuration: 0,
      agencyId: '',
      isRenterEmployed: true,
      propertyInvestmentsContact: null,
      propertyInvestmentsPostcodes: [],
      propertyInvestmentsProfile: 0,
      isBallotApplication: false,
      isBallotSubmitModalOpen: false,
      isBallotSubmitting: false,
      isGovtInfoLearnMoreModalOpened: false,
    }
  }

  componentDidMount() {
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const {
      id: agencyGUID,
      ballot = false,
      applicationId = '',
    } = curQueries || {}
    if (ballot && ballot === 'true') {
      this.setState({
        isBallotApplication: true,
      })
    }

    this.props.fetchProofOfIncome()
    this.props.fetchCompleteness()
    this.props
      .fetchExtraIncome()
      .then((result) => {
        if (result.guidID) {
          const {
            governmentAnnualBenefits,
            governmentWeeklyBenefits,
            guidID,
            optedToHaveGovernmentBenefits,
            optedToHaveOtherIncome,
            otherAnnualIncome,
            otherWeeklyIncome,
            optedToHavePropertyInvestor,
            propertyInvestmentsContact,
            propertyInvestmentsPostcodes,
            propertyInvestmentsProfile,
            optedToHavePropertyOwnership,
            savingsBalance,
          } = result
          const savingsBalanceVal =
            Number(savingsBalance) === 0 ? '' : savingsBalance
          this.setState({
            extraIncomeReferenceId: guidID,
            otherIncomeTicked: optedToHaveOtherIncome,
            governmentBenefitTicked: optedToHaveGovernmentBenefits,
            optedToHavePropertyInvestor: optedToHavePropertyInvestor,
            optedToHavePropertyOwnership: optedToHavePropertyOwnership,
            propertyInvestmentsContact: String(propertyInvestmentsContact),
            propertyInvestmentsProfile,
            incomeValues: {
              governmentAnnualBenefits,
              governmentWeeklyBenefits,
              otherWeeklyIncome,
              otherAnnualIncome,
              savingsBalance: savingsBalanceVal,
            },
          })
          if (propertyInvestmentsPostcodes) {
            this.setState({
              propertyInvestmentsPostcodes: propertyInvestmentsPostcodes.map(
                (value) => ({
                  option: value,
                  optionMeta: { id: value, displayText: value },
                }),
              ),
            })
          }
        }
      })
      .catch((error) => {
        this.setState({
          apiError: error,
        })
      })

    this.props.retrieveReputationPreference().then(({ preference }) => {
      this.setState({
        isRenterEmployed: preference && preference.optInEmployed,
      })
    })

    if (agencyGUID && agencyGUID !== '') {
      this.setState({
        agencyId: agencyGUID,
      })
      const applicationGUID =
        applicationId && applicationId?.toString()?.split(',')[0]

      this.props
        .fetchTeamSettingInformation(agencyGUID, 'application')
        .then((teamSettingInfo) => {
          if (teamSettingInfo) {
            this.setState({
              teamSettingInfo,
              disableNextButtonToFetchSettings: false,
              bankStatementRequire: covertBoolStringToBool(
                teamSettingInfo?.application?.bank_statement_require,
              ),
              payslipsCountRequired: covertBoolStringToBool(
                teamSettingInfo?.application?.payslips_require,
              ),
              payslipsDurationRequired: covertBoolStringToBool(
                teamSettingInfo?.application?.[
                  applicationsAttributesIDs.payslipsRequiredDurationEnabled
                ],
              ),
              payslipsMinDuration: parseStringToNumber(
                teamSettingInfo?.application?.[
                  applicationsAttributesIDs.payslipsRequiredDuration
                ],
              ),
            })
          }
        })
        .catch((error) => {
          this.setState({ error })
        })
        .finally(() =>
          this.setState({ disableNextButtonToFetchSettings: false }),
        )

      this.props.fetchApplication(applicationGUID)
    }
  }

  onChangePropertyInvestor = () => {
    return (event) => {
      const { target } = event
      const { id } = target

      this.setState({
        ...this.state.optedToHavePropertyInvestor,
        optedToHavePropertyInvestor: Number(id),
        errors: {
          ...this.state.errors,
          optedToHavePropertyInvestor: ![Yes, No].includes(Number(id))
            ? 'Please select property investment option'
            : '',
        },
      })
    }
  }

  onChangePropertyOwnership = () => {
    return (event) => {
      const { target } = event
      const { id } = target

      this.setState({
        ...this.state.optedToHavePropertyOwnership,
        optedToHavePropertyOwnership: Number(id),
        errors: {
          ...this.state.errors,
          optedToHavePropertyOwnership: ![Yes, No].includes(Number(id))
            ? 'Please select property ownership option'
            : '',
        },
      })
    }
  }

  onChangeInvestmentProfile = (data) => {
    const { value } = data
    this.setState(
      {
        propertyInvestmentsProfile: value,
      },
      () => {
        if (
          value > InvProfileSelect &&
          value <= InvProfileBothExistingAndPlanned
        ) {
          this.setState({
            errors: {
              ...this.state.errors,
              propertyInvestmentsProfile: '',
            },
          })
        }
      },
    )
  }

  onChangePropertyInvestmentContact = () => {
    return (event) => {
      const { target } = event
      const { id } = target

      this.setState({
        propertyInvestmentsContact: id,
        errors: {
          ...this.state.errors,
          propertyInvestmentsContact: ![TRUE, FALSE].includes(id)
            ? 'Please select property investment contact option'
            : '',
        },
      })
    }
  }

  onChangePostcodesInput = (payload) => {
    const filteredCodes = payload.filter(
      (code) => /^[0-9]+$/.test(code.option) && code.option?.length <= 4,
    )

    this.setState({
      propertyInvestmentsPostcodes: filteredCodes,
      errors: {
        ...this.state.errors,
        propertyInvestmentsPostcodes: !filteredCodes.length
          ? 'Please enter at least one postcode'
          : '',
      },
    })
  }

  onCheckBoxTicked = (field) => {
    this.setState({
      [field]: !this.state[field],
      unsavedChanges: true,
    })
  }

  onModalSecondaryButtonClick = () => {
    const { addRentalReputationBackUrl } = this.props
    const { pathname, search } = window.location
    addRentalReputationBackUrl(pathname + search)
    this.processExtraIncome()
  }

  setBankStatementsChanged = (newBankStatementsAdded) => {
    this.setState({
      newBankStatementsUpdated: newBankStatementsAdded > 0,
    })
  }

  setGovernmentBenefitDocumentChanged = (newGovernmentBenefitDocumentAdded) => {
    this.setState({
      newGovernmentBenefitDocumentAdded: newGovernmentBenefitDocumentAdded > 0,
    })
  }

  setOtherIncomeStatementsChanged = (newOtherStatementsAdded) => {
    this.setState({
      newOtherStatementsAdded: newOtherStatementsAdded > 0,
    })
  }

  setPaySlipsChanged = (newPaySlipsAdded) => {
    this.setState({ newPaySlipsUpdated: newPaySlipsAdded > 0 })
  }

  checkProofOfIncomeBeforeNext = (
    payslipsNumber,
    bankStatementPeriodNumber,
    enforcePayslipRequirements,
  ) => {
    const {
      isRenterEmployed,
      isBallotApplication,
      otherIncomeTicked,
      governmentBenefitTicked,
      incomeValues: { savingsBalance },
    } = this.state
    const { addRentalReputationBackUrl, documents } = this.props
    const validHistory = isAllSufficient(
      documents,
      savingsBalance,
      otherIncomeTicked,
      governmentBenefitTicked,
      payslipsNumber,
      bankStatementPeriodNumber,
      enforcePayslipRequirements,
    )
    if (!validHistory && isRenterEmployed && !isBallotApplication) {
      this.toggleModal()
    } else {
      addRentalReputationBackUrl(
        `${window.location.pathname}${window.location.search}`,
      )
      this.processExtraIncome()
    }
  }

  processExtraIncome = () => {
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { applicationId } = curQueries

    const { addExtraIncome, editExtraIncome } = this.props
    const {
      otherIncomeTicked: optedToHaveOtherIncome,
      governmentBenefitTicked: optedToHaveGovernmentBenefits,
      incomeValues,
      extraIncomeReferenceId,
      optedToHavePropertyInvestor,
      propertyInvestmentsContact,
      propertyInvestmentsPostcodes,
      propertyInvestmentsProfile,
      optedToHavePropertyOwnership,
      isBallotApplication,
    } = this.state
    const {
      otherWeeklyIncome = 0,
      otherAnnualIncome = 0,
      governmentWeeklyBenefits = 0,
      governmentAnnualBenefits = 0,
      savingsBalance = 0,
    } = incomeValues
    const extraIncomeInfo = {
      optedToHaveOtherIncome: false,
      optedToHaveGovernmentBenefits: false,
      optedToHavePropertyInvestor: optedToHavePropertyInvestor,
      optedToHavePropertyOwnership: optedToHavePropertyOwnership,
      propertyInvestmentsContact: propertyInvestmentsContact === TRUE,
      propertyInvestmentsPostcodes: propertyInvestmentsPostcodes.map(
        (tag) => tag.option,
      ),
      propertyInvestmentsProfile,
      isBallotApplication,
    }

    const validationErrors = this.state.errors

    if (!!savingsBalance && !Number(savingsBalance)) {
      validationErrors['savingsBalance'] =
        'Savings balance must be empty or greater than 0'
    } else if (!!savingsBalance) {
      Object.assign(extraIncomeInfo, {
        savingsBalance: Number(savingsBalance),
      })
    }

    if (!isBallotApplication) {
      if (+optedToHavePropertyInvestor === Yes) {
        if (![TRUE, FALSE].includes(propertyInvestmentsContact)) {
          validationErrors['propertyInvestmentsContact'] =
            'Please select property investment contact option'
        }

        if (!propertyInvestmentsPostcodes.length) {
          validationErrors['propertyInvestmentsPostcodes'] =
            'Postcodes are required'
        }
        if (
          propertyInvestmentsProfile < InvProfileExistingInvestor ||
          propertyInvestmentsProfile > InvProfileBothExistingAndPlanned
        ) {
          validationErrors['propertyInvestmentsProfile'] =
            'Investment Profile is required'
        }
      }
    }

    if (isBallotApplication) {
      if (![Yes, No].includes(optedToHavePropertyOwnership)) {
        validationErrors['optedToHavePropertyOwnership'] =
          'Please select property ownership option'
      }
      if (optedToHavePropertyOwnership === Yes) {
        validationErrors['optedToHavePropertyOwnership'] =
          'Property owners are not eligible for this affordable housing program'
      }
    }

    if (optedToHaveOtherIncome === true) {
      if (!Number(otherWeeklyIncome)) {
        validationErrors['otherWeeklyIncome'] =
          'Other weekly income cannot be empty or 0'
      }
      if (Number(otherWeeklyIncome) > MAX_WEEKLY_INCOME) {
        validationErrors['otherWeeklyIncome'] =
          'Other weekly income is exceeding maximum limit'
      }
      if (!Number(otherAnnualIncome)) {
        validationErrors['otherAnnualIncome'] =
          'Other annual income cannot be empty or 0'
      }
      if (Number(otherAnnualIncome) > MAX_ANNUAL_INCOME) {
        validationErrors['otherAnnualIncome'] =
          'Other annual income is exceeding maximum limit'
      }
      Object.assign(extraIncomeInfo, {
        optedToHaveOtherIncome,
        otherWeeklyIncome: Number(otherWeeklyIncome),
        otherAnnualIncome: Number(otherAnnualIncome),
      })
    }
    if (optedToHaveGovernmentBenefits === true) {
      if (!Number(governmentWeeklyBenefits)) {
        validationErrors['governmentWeeklyBenefits'] =
          'Government weekly benefits cannot be empty or 0'
      }
      if (Number(governmentWeeklyBenefits) > MAX_WEEKLY_INCOME) {
        validationErrors['governmentWeeklyBenefits'] =
          'Government weekly benefits is exceeding maximum limit'
      }
      if (!Number(governmentAnnualBenefits)) {
        validationErrors['governmentAnnualBenefits'] =
          'Other weekly income cannot be empty or 0'
      }
      if (Number(governmentAnnualBenefits) > MAX_ANNUAL_INCOME) {
        validationErrors['governmentAnnualBenefits'] =
          'Government annual benefits is exceeding maximum limit'
      }
      Object.assign(extraIncomeInfo, {
        optedToHaveGovernmentBenefits,
        governmentWeeklyBenefits: Number(governmentWeeklyBenefits),
        governmentAnnualBenefits: Number(governmentAnnualBenefits),
      })
    }

    const errMessages = Object.values(validationErrors)
      .map((errorValue, errorIndex) => {
        const errorKey = Object.keys(validationErrors)[errorIndex]
        if (
          !optedToHaveOtherIncome &&
          (errorKey === 'otherWeeklyIncome' || errorKey === 'otherAnnualIncome')
        ) {
          return ''
        }

        if (
          !optedToHaveGovernmentBenefits &&
          (errorKey === 'governmentAnnualBenefits' ||
            errorKey === 'governmentWeeklyBenefits')
        ) {
          return ''
        }
        return errorValue
      })
      .filter((message) => {
        return !!message.length
      })

    if (!!errMessages.length) {
      snugNotifier.error(errMessages[0])
      this.switchOffModal()
      return
    }
    this.setState({ errors: validationErrors })

    if (applicationId?.length > 0) {
      extraIncomeInfo['applicationGUID'] = Array.isArray(applicationId)
        ? applicationId[0]
        : applicationId
    }
    // should go to edit if extraIncomeReferenceId present
    if (
      (optedToHaveOtherIncome ||
        !!savingsBalance ||
        optedToHaveGovernmentBenefits ||
        (!isBallotApplication && optedToHavePropertyInvestor === Yes) ||
        (isBallotApplication && optedToHavePropertyOwnership === Yes)) &&
      !extraIncomeReferenceId
    ) {
      addExtraIncome(extraIncomeInfo)
        .then((result) => {
          this.setState(
            {
              unsavedChanges: false,
            },
            () => {
              history.push(
                `${urlTo('identityDocument')}${window.location.search}`,
              )
            },
          )
        })
        .catch((error) => {
          snugNotifier.error(error)
          this.switchOffModal()
        })
    } else if (extraIncomeReferenceId) {
      editExtraIncome(extraIncomeReferenceId, extraIncomeInfo)
        .then((result) => {
          this.setState(
            {
              unsavedChanges: false,
            },
            () => {
              history.push(
                `${urlTo('identityDocument')}${window.location.search}`,
              )
            },
          )
        })
        .catch((error) => {
          snugNotifier.error(error)
          this.switchOffModal()
        })
    } else {
      this.setState(
        {
          unsavedChanges: false,
        },
        () => {
          history.push(`${urlTo('identityDocument')}${window.location.search}`)
        },
      )
    }
  }

  switchOffModal = () => {
    this.setState({ modalOpen: false })
  }

  toggleGovtInfoLearnMoreModal = () => {
    const { isGovtInfoLearnMoreModalOpened } = this.state
    this.setState({
      isGovtInfoLearnMoreModalOpened: !isGovtInfoLearnMoreModalOpened,
    })
  }

  toggleModal = () => {
    const { modalOpen } = this.state
    this.setState({ modalOpen: !modalOpen })
  }

  update(field) {
    return (data) => {
      const currencyFields = [
        'governmentWeeklyBenefits',
        'governmentAnnualBenefits',
        'otherWeeklyIncome',
        'otherAnnualIncome',
        'optedToHavePropertyInvestor',
        'savingsBalance',
      ]
      if (!shouldCurrencyUpdate(currencyFields, field, data.value)) {
        return
      }
      this.setState({
        unsavedChanges: true,
        incomeValues: {
          ...this.state.incomeValues,
          [field]: data.value,
        },
        apiError: '',
        frontEndError: '',
        errors: {
          ...this.state.errors,
          [field]: data.error || '',
        },
      })
    }
  }

  render() {
    const {
      spinners,
      documents,
      addProofOfIncome,
      deleteProofOfIncome,
      responseTexts,
      backUrl,
      property,
      completeness,
      applicationDetails,
    } = this.props
    const propertyId = getParameter('propertyId')
    const applicationId = getParameter('applicationId')
    const isFromApplicationForm =
      isFromApplication(backUrl) || propertyId || applicationId
    const {
      modalOpen,
      newPaySlipsUpdated,
      newBankStatementsUpdated,
      newOtherStatementsAdded,
      newGovernmentBenefitDocumentAdded,
      otherIncomeTicked,
      governmentBenefitTicked,
      optedToHavePropertyInvestor,
      propertyInvestmentsContact,
      propertyInvestmentsPostcodes = [],
      optedToHavePropertyOwnership,
      incomeValues: {
        otherWeeklyIncome,
        otherAnnualIncome,
        governmentWeeklyBenefits,
        governmentAnnualBenefits,
        savingsBalance,
      },
      errors: {
        otherWeeklyIncome: otherWeeklyIncomeError,
        otherAnnualIncome: otherAnnualIncomeError,
        governmentWeeklyBenefits: governmentWeeklyBenefitsError,
        governmentAnnualBenefits: governmentAnnualBenefitsError,
        optedToHavePropertyInvestor: optedToHavePropertyInvestorError,
        propertyInvestmentsContact: propertyInvestmentsContactError,
        propertyInvestmentsPostcodes: propertyInvestmentsPostcodesError,
        propertyInvestmentsProfile: propertyInvestmentsProfileError,
        optedToHavePropertyOwnership: optedToHavePropertyOwnershipError,
        savingsBalance: savingsBalanceError,
      },
      unsavedChanges,
      disableNextButtonToFetchSettings,
      payslipsCountRequired,
      payslipsDurationRequired,
      payslipsMinDuration,
      agencyId,
      propertyInvestmentsProfile,
      isBallotApplication,
      isGovtInfoLearnMoreModalOpened,
    } = this.state
    const { config: applicationConfig = {} } = applicationDetails || {}
    const { applicationsSettingsConfig = {} } = applicationConfig || {}
    const {
      payslips_require_number: payslipsRequiredNumber = 0,
      payslips_require: payslipsRequired = false,
      bank_statement_require_type: bankStatementRequireType = 0,
      bank_statement_require: bankStatementRequire = false,
      bank_statement_require_recent_two: bankStatementRequireRecentTwo = false,
    } = applicationsSettingsConfig || {}
    const { teamName = '' } = property || {}
    let payslipsNumber = 0
    let bankStatementsOptions = ''

    if (payslipsRequired) {
      payslipsNumber = payslipReqOptions[payslipsRequiredNumber || 0]
    }

    if (bankStatementRequire) {
      bankStatementsOptions =
        requireBankStatementOptionsMap[bankStatementRequireType || 0]
    }

    const newIncomeUpdate =
      newPaySlipsUpdated ||
      newBankStatementsUpdated ||
      newOtherStatementsAdded ||
      newGovernmentBenefitDocumentAdded
    const incomeUpdateTagClass = newIncomeUpdate ? 'renter-income-added' : ''
    const bankStatementPeriodNumber = bankStatementRequireRecentTwo ? 2 : 1

    const bankStatementRequirementMessage = `${teamName} requires ${
      bankStatementRequireRecentTwo
        ? ` two (2) recent Bank Statements`
        : ' a Bank Statement'
    }${bankStatementsOptions}.`

    const {
      section: {
        income: { checkPayslips: applyRuleCheckPayslips = true } = {},
      } = {},
    } = this.props.applyRules || {}

    const enforcePayslipRequirements =
      payslipsRequired && applyRuleCheckPayslips

    let payslipsGuidance = textHelpers.INCOME_PAYSLIPS_COUNT_RECOMMEND
    if (
      payslipsDurationRequired &&
      !!payslipsMinDuration &&
      enforcePayslipRequirements
    ) {
      payslipsGuidance =
        textHelpers.incomePayslipsDurationRecommendTxt(payslipsMinDuration)
    }

    return (
      <Display.CenterContentContainer componentClass="width100">
        <div className="proof-of-income">
          <ProgressBarComponent
            completeness={completeness}
            clickNextFunctionFromParentComponent={() =>
              this.checkProofOfIncomeBeforeNext(
                payslipsNumber,
                bankStatementPeriodNumber,
                enforcePayslipRequirements,
              )
            }
          />
          <RentalReputationHeaderReminder
            property={property}
            backUrl={backUrl}
            remindText={'Current application'}
            isFromApplicationForm={isFromApplicationForm}
            title="Income"
          />

          <Header
            title="Income"
            message={
              <>
                {isBallotApplication && <BallotAlertDocumentUpload />}
                <p className="gray-color mb0">
                  {textHelpers.INCOME_HEADER_GUIDANCE}
                </p>
              </>
            }
          />

          <AlertSensitiveInfo />
        </div>
        <div className="content">
          <div className="row">
            <div className="col-xs-12">
              <h3 className="mt15">Payslips</h3>
              <p className="mt15 mb25">{payslipsGuidance}</p>
              {enforcePayslipRequirements && (
                <Alert
                  showLeftIcon={true}
                  leftIconVariant="info"
                  variant="blueWithBg"
                  fontSize={theme.fontSizes.pRegular14}
                >
                  {teamName} requires {payslipsNumber} payslips
                </Alert>
              )}
              <DocumentList
                documents={documents.paySlips}
                addDocument={addProofOfIncome}
                deleteDocument={deleteProofOfIncome}
                documentType={DocumentTypes.Payslips}
                spinner={spinners.Payslips}
                responseText={responseTexts.Payslips}
                fileType="Payslips"
                setDocumentsChanged={this.setPaySlipsChanged}
                allowDeletion={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 mt25">
              <h3 className="mb15">Bank Statements</h3>
              <p>{textHelpers.BANK_STATEMENT_TEXT_PROFILE_PAGE}</p>
              {bankStatementRequire && (
                <Alert
                  showLeftIcon={true}
                  leftIconVariant="info"
                  variant="blueWithBg"
                  fontSize={theme.fontSizes.pRegular14}
                  mb={4}
                >
                  {bankStatementRequirementMessage}
                </Alert>
              )}

              <Box lineHeight="1.6">
                <SystemText as="strong">Savings balance</SystemText>
                <br />
                <SystemText as="span">
                  Add a Savings Balance to show affordability (useful if retired
                  or not currently working).
                </SystemText>
              </Box>
              <Input
                prefix="$"
                error={savingsBalanceError}
                inputProps={{
                  placeholder: 'e.g. 5000',
                  value: savingsBalance,
                  onChange: (e) => this.update('savingsBalance')(e.target),
                  maxWidth: '90px',
                  maxHeight: '20px',
                }}
                containerProps={{
                  width: '130px',
                  height: '40px',
                  marginBottom: '24px',
                  marginTop: '16px',
                }}
              />
              <ErrorBox
                showError={!!savingsBalanceError}
                error={savingsBalanceError}
              />
              <DocumentList
                documents={documents.bankStatements}
                addDocument={addProofOfIncome}
                deleteDocument={deleteProofOfIncome}
                documentType={DocumentTypes.BankStatements}
                spinner={spinners.BankStatements}
                responseText={responseTexts.BankStatements}
                fileType="BankStatements"
                setDocumentsChanged={this.setBankStatementsChanged}
                allowDeletion={true}
              />
            </div>
          </div>
          <div className="mt25">
            <Form.Checkbox
              label="I have other sources of income"
              checked={otherIncomeTicked}
              onChange={() => this.onCheckBoxTicked('otherIncomeTicked')}
            />
            {otherIncomeTicked && (
              <div>
                <h3 className="mt25 mb15">Other Income Statements</h3>
                <div className="mb10">
                  <DocumentList
                    documents={documents.otherIncomeDocuments}
                    addDocument={addProofOfIncome}
                    deleteDocument={deleteProofOfIncome}
                    documentType={DocumentTypes.OtherIncomeDocuments}
                    spinner={spinners.OtherIncomeDocuments}
                    responseText={responseTexts.OtherIncomeDocuments}
                    fileType="OtherIncomeDocuments"
                    setDocumentsChanged={this.setOtherIncomeStatementsChanged}
                    allowDeletion={true}
                  />
                </div>
                <NewForm.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                  <NewForm.InputTextOptional
                    label={Text.placeholder.otherWeeklyIncomePlaceholder}
                    value={otherWeeklyIncome}
                    error={otherWeeklyIncomeError}
                    onChange={this.update('otherWeeklyIncome')}
                    id="otherWeeklyIncome"
                    inputClass="width100"
                    componentClass="margin-profile-item-left width100"
                    labelClass="top20 small-label"
                    isOptional={!otherIncomeTicked}
                  />
                  <NewForm.InputTextOptional
                    label={Text.placeholder.otherAnnualIncomePlaceholder}
                    value={otherAnnualIncome}
                    error={otherAnnualIncomeError}
                    onChange={this.update('otherAnnualIncome')}
                    id="otherAnnualIncome"
                    inputClass="width100"
                    componentClass="margin-profile-item-right width100"
                    labelClass="top20 small-label"
                    isOptional={!otherIncomeTicked}
                  />
                </NewForm.ProfilePagesRowLayout>
              </div>
            )}
          </div>
          <div className="mt25">
            <Form.Checkbox
              label="I receive Government benefits"
              checked={governmentBenefitTicked}
              onChange={() => this.onCheckBoxTicked('governmentBenefitTicked')}
            />
            {governmentBenefitTicked && (
              <div>
                <h3 className="mt25 mb15">
                  Services Australia/Government Benefits
                </h3>
                <p>
                  Please include your Centrelink Income and Assessment or
                  similar future benefits statement.
                </p>
                <div className="mb10">
                  <DocumentList
                    documents={documents.governmentBenefitDocuments}
                    addDocument={addProofOfIncome}
                    deleteDocument={deleteProofOfIncome}
                    documentType={DocumentTypes.GovernmentBenefitDocuments}
                    spinner={spinners.GovernmentBenefitDocuments}
                    responseText={responseTexts.GovernmentBenefitDocuments}
                    fileType="GovernmentBenefitDocuments"
                    setDocumentsChanged={
                      this.setGovernmentBenefitDocumentChanged
                    }
                    allowDeletion={true}
                  />
                </div>
                <p className="gray-color mb10">
                  {Text.placeholder.governmentBenefitsLabel}
                  <Button
                    variant="linkBlueWithoutWeight"
                    onClick={() => this.toggleGovtInfoLearnMoreModal()}
                    ml={1}
                  >
                    learn more
                  </Button>
                </p>
                <NewForm.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                  <NewForm.InputTextOptional
                    label={Text.placeholder.governmentWeeklyBenefitsPlacehold}
                    value={governmentWeeklyBenefits}
                    error={governmentWeeklyBenefitsError}
                    onChange={this.update('governmentWeeklyBenefits')}
                    id="governmentWeeklyBenefits"
                    inputClass="width100"
                    componentClass="margin-profile-item-left width100"
                    labelClass="top20 small-label"
                    isOptional={!governmentBenefitTicked}
                  />
                  <NewForm.InputTextOptional
                    label={Text.placeholder.governmentAnnualBenefitsPlacehold}
                    value={governmentAnnualBenefits}
                    error={governmentAnnualBenefitsError}
                    onChange={this.update('governmentAnnualBenefits')}
                    id="governmentAnnualBenefits"
                    inputClass="width100"
                    componentClass="margin-profile-item-right width100"
                    labelClass="top20 small-label"
                    isOptional={!governmentBenefitTicked}
                  />
                </NewForm.ProfilePagesRowLayout>
              </div>
            )}
          </div>
          {!isBallotApplication && (
            <div className="mt25 width100">
              <h3 className="mb15">Property Investment</h3>
              <p>
                Add optional Profile info, unrelated to application, to receive
                more information
              </p>

              <Form.RadioGroup
                label="Do you have existing or planned property investments?"
                id="optedToHavePropertyInvestor"
                field="optedToHavePropertyInvestor"
                componentClassName="property-details-radio-group"
                error={optedToHavePropertyInvestorError}
              >
                <Form.Checkbox
                  id={Yes}
                  label="Yes"
                  checked={optedToHavePropertyInvestor === Yes}
                  onChange={(e) => this.onChangePropertyInvestor()(e)}
                />
                <Form.Checkbox
                  id={No}
                  label="No"
                  checked={optedToHavePropertyInvestor === No}
                  onChange={(e) => this.onChangePropertyInvestor()(e)}
                />
              </Form.RadioGroup>

              {optedToHavePropertyInvestor === Yes && (
                <>
                  <SystemText mb="0">
                    Select your investment profile.
                  </SystemText>
                  <SelectInvestmentProfile
                    propertyInvestmentsProfile={propertyInvestmentsProfile}
                    onChangeInvestmentProfile={this.onChangeInvestmentProfile}
                    propertyInvestmentsProfileError={
                      propertyInvestmentsProfileError
                    }
                  />

                  <SystemText mb="0">
                    Which postcode(s) do you have existing and/or planned
                    investments?
                  </SystemText>
                  <Box width="283px" mb="16px">
                    <StyledTagsInput
                      error={propertyInvestmentsPostcodesError?.length}
                    >
                      <TagsInput
                        value={propertyInvestmentsPostcodes}
                        changeHandler={(tags) =>
                          this.onChangePostcodesInput(tags)
                        }
                        inputPlaceholder={'Add postcode and press Enter'}
                      />
                    </StyledTagsInput>
                  </Box>

                  <Form.RadioGroup
                    label="Would you like to be contacted about property investment or management services?"
                    id="propertyInvestmentsContact"
                    field="propertyInvestmentsContact"
                    componentClassName="property-details-radio-group"
                    error={propertyInvestmentsContactError}
                  >
                    <Form.Checkbox
                      id={TRUE}
                      label="Yes"
                      checked={propertyInvestmentsContact === TRUE}
                      onChange={(e) =>
                        this.onChangePropertyInvestmentContact()(e)
                      }
                    />

                    <Form.Checkbox
                      id={FALSE}
                      label="No"
                      checked={propertyInvestmentsContact === FALSE}
                      onChange={(e) =>
                        this.onChangePropertyInvestmentContact()(e)
                      }
                    />
                  </Form.RadioGroup>
                </>
              )}
            </div>
          )}
          {isBallotApplication && (
            <div className="mt25 width100">
              <h3 className="mb15">Property Ownership</h3>
              <Form.RadioGroup
                label="Do you own a whole or part of a property in Australia?"
                id="optedToHavePropertyOwnership"
                field="optedToHavePropertyOwnership"
                componentClassName="property-details-radio-group"
                error={optedToHavePropertyOwnershipError}
              >
                <Form.Checkbox
                  id={Yes}
                  label="Yes"
                  checked={optedToHavePropertyOwnership === Yes}
                  onChange={(e) => this.onChangePropertyOwnership()(e)}
                />
                <Form.Checkbox
                  id={No}
                  label="No"
                  checked={optedToHavePropertyOwnership === No}
                  onChange={(e) => this.onChangePropertyOwnership()(e)}
                />
              </Form.RadioGroup>
            </div>
          )}
          <Display.BottomContentContainer>
            <Display.CenterContentContainer componentClass="width100">
              <div className="submit-button-container">
                <BottomButton
                  clickNextFunction={() =>
                    this.checkProofOfIncomeBeforeNext(
                      payslipsNumber,
                      bankStatementPeriodNumber,
                      enforcePayslipRequirements,
                    )
                  }
                  btnText={
                    isFromApplicationForm ? (
                      <span>
                        Save & Continue
                        <i
                          className={`${
                            isFromApplicationForm && agencyId !== ''
                              ? disableNextButtonToFetchSettings
                                ? 'fa fa-spinner'
                                : ''
                              : ''
                          }`}
                        />
                      </span>
                    ) : (
                      'Next: Identity Document'
                    )
                  }
                  bottomButtonsClass="pt0 mt0 summary-bottom-button"
                  nextBtnClass={`summary-green-button-class p0 ${incomeUpdateTagClass}`}
                  backButtonClass="summary-grey-button-class"
                  nextButtonPositionClass="pr20 pl0"
                  showArrow={false}
                  nextBtnDisabled={
                    isFromApplicationForm && agencyId !== ''
                      ? disableNextButtonToFetchSettings
                      : false
                  }
                />
              </div>
            </Display.CenterContentContainer>
          </Display.BottomContentContainer>
          {modalOpen && (
            <WarningModal
              docs={documents}
              isPayslipsCountRequired={payslipsCountRequired}
              isBankStatementRequired={bankStatementRequire}
              isPayslipsDurationRequired={payslipsDurationRequired}
              isOtherDocsTicked={otherIncomeTicked}
              isGovDocsTicked={governmentBenefitTicked}
              payslipsDuration={payslipsMinDuration}
              savingsBalance={savingsBalance}
              onPrimaryClick={() => this.toggleModal()}
              onSecondaryClick={(allowContinue) =>
                allowContinue
                  ? this.onModalSecondaryButtonClick()
                  : this.toggleModal()
              }
              payslipsNumber={payslipsNumber}
              bankStatementPeriodNumber={bankStatementPeriodNumber}
            />
          )}
          {isGovtInfoLearnMoreModalOpened && (
            <Modal
              modalBody={Text.placeholder.governmentBenefitsInfoModalText}
              toggleModal={() => this.toggleGovtInfoLearnMoreModal()}
              showPrimaryButton={false}
              secondaryLabel="Close"
              secondaryAction={() => this.toggleGovtInfoLearnMoreModal()}
            />
          )}
        </div>
        <UnsavedMessage unsavedChanges={unsavedChanges} />
      </Display.CenterContentContainer>
    )
  }
}

export default ProofOfIncome
