import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { validateEmail } from 'app/shared_components/validations'

export const formFields = {
  recipients: 'recipients',
  message: 'message',
  withOwnerStatus: 'withOwnerStatus',
  isOwnerAcceptEnabled: 'isOwnerAcceptEnabled',
  requestBGCApproval: 'requestBGCApproval',
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.recipients: {
      const invalidEmails = (val || [])
        .filter(({ option }) => validateEmail(option)[0])
        .map(({ option }) => option)

      return {
        required:
          (!val || !val.length) && 'at least on recipient should be added',
        pattern:
          invalidEmails.length &&
          `Following email(s) are invalid "${invalidEmails.join(', ')}"`,
      }
    }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
