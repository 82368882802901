import React from 'react'

import classNames from 'classnames'
import $ from 'jquery'
import { Link } from 'react-router-dom'

import iconCouples from 'app/assets/icons/icon-couples.svg'
import iconFamilies from 'app/assets/icons/icon-families.svg'
import iconSingles from 'app/assets/icons/icon-singles.svg'
import { urlTo } from 'app/sm/helpers'
import { isCurrentRoute } from 'app/sm/router_helpers'

function bindCorrsepondingContinueUrl(activeOffer, propertyId, propertySlug) {
  if (isCurrentRoute('adFlowDescription')) {
    return urlTo('adFlowAmenities', { propertyId })
  }

  if (activeOffer.guidID === undefined) {
    return urlTo('createOffer', { propertyId })
  }

  return urlTo('updateOfferBase', { propertyId, offerId: activeOffer.guidID })
}

function bindCorrsepondingBackUrl(activeOffer, propertySlug, propertyId) {
  if (isCurrentRoute('adFlowDescription')) {
    const encodedUrlParam = btoa(
      JSON.stringify({
        offerId: activeOffer.guidID,
        propertySlug,
      }),
    )

    return urlTo('profileShare', { encodedParams: encodedUrlParam })
  }

  return urlTo('updatePropertyPhotos', { id: propertyId })
}

class Description extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clicked: false,
      titleValidation: {
        ok: true,
        parole: 'Property title is required',
      },
    }
  }

  componentDidMount() {
    $('.app-content').scrollTop(0)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.error) {
      this.setState({
        clicked: false,
      })
    }
  }

  onContinueClick() {
    this.setState({ clicked: true })
  }

  validateTitle(value) {
    this.setState({
      titleValidation: Object.assign({}, this.state.titleValidation, {
        ok: value === '' ? false : true,
      }),
    })
  }

  render() {
    const { clicked, titleValidation } = this.state
    const {
      ad,
      state,
      property,
      params,
      update,
      updateList,
      updateCheckbox,
      updateProperty,
      error,
    } = this.props
    let activeOffer = (state.offers || []).find((o) => o.isActive) || {}
    let topQualities = state.topQualities.split(';')
    let idealFor = state.idealFor.split(';')
    let summary = state.summary.split(';')
    let idealForButtons = [
      { id: '1', name: 'Singles', icon: iconSingles },
      { id: '2', name: 'Couples', icon: iconCouples },
      { id: '3', name: 'Families', icon: iconFamilies },
    ]

    let ranges = [
      { left: 'Understated', right: 'Bright' },
      { left: 'Private', right: 'Open' },
      { left: 'Cosy', right: 'Spacious' },
      { left: 'Leafy', right: 'Urban' },
    ]

    let { id, propertyId } = params

    const disabled = clicked || !titleValidation.ok

    return (
      <div className="sm-property-create basic">
        <div className="mobile-only">
          <Link
            className="btn btn-transparent btn-left xs-text-center wa"
            to={bindCorrsepondingBackUrl(
              activeOffer,
              state.slug,
              id || propertyId,
            )}
          >
            <i className="icon-arrow-left left"></i>
            <span>Back</span>
          </Link>
        </div>
        <div className="section-title">
          <div className="left column">
            <h3>Describe your property</h3>
          </div>
        </div>
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <div className="row">
              <div className="col-sm-12">
                <div className="input-box">
                  <input
                    value={state.title}
                    onChange={update('title')}
                    onBlur={(e) => this.validateTitle(e.target.value)}
                  />
                  <label>Property title</label>
                </div>
                <div
                  className={
                    !titleValidation.ok ? 'alert alert-danger' : 'hide-alert'
                  }
                >
                  <div>{titleValidation.parole}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-box">
                  {topQualities.map((quality, id) => (
                    <input
                      type="text"
                      id={id}
                      key={id}
                      value={quality}
                      onChange={updateList('topQualities')}
                    />
                  ))}
                  <label>3 things to love about your property</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <h4 className="pb20">This property is ideal for</h4>
                <div className="button-group">
                  {idealForButtons.map(({ id, name, icon }) => (
                    <button
                      key={id}
                      id={id}
                      name="idealFor"
                      className={classNames('btn btn-switch', {
                        active: idealFor.includes(id),
                      })}
                      onClick={updateCheckbox()}
                    >
                      <img src={icon} height="36" />
                      <span>{name}</span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 pt50">
                <h4 className="pb5">Property description</h4>
                <p>
                  Add more details to help potential renters fall in love with
                  your property
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-box">
                  <textarea
                    rows="4"
                    placeholder="Describe the indoor and outdoor living areas"
                    value={state.livingAreaDesc}
                    onChange={update('livingAreaDesc')}
                  />
                  <label>Living Area</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-box">
                  <textarea
                    rows="4"
                    placeholder="Describe the bedrooms and bathrooms"
                    value={state.bedroomsDesc}
                    onChange={update('bedroomsDesc')}
                  />
                  <label>Bedrooms and Bathrooms</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-box">
                  <textarea
                    rows="4"
                    placeholder="Describe the building and it’s facilities"
                    value={state.buildingDesc}
                    onChange={update('buildingDesc')}
                  />
                  <label>Building & Facilities</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 pb20">
                <div className="input-box">
                  <textarea
                    rows="4"
                    placeholder="Describe the local area, transport, schools and what it’s like to live there"
                    value={state.streetDesc}
                    onChange={update('streetDesc')}
                  />
                  <label>Street & Neighbourhood</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <div className="row">
              <div className="col-sm-12 pt20 pb30">
                <h4>How does this property feel ?</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 pb25">
                {ranges.map((range, id) => (
                  <div key={id} className="pb25">
                    <input
                      type="range"
                      id={id}
                      min="0"
                      max="100"
                      step="1"
                      value={summary[id]}
                      onChange={updateList('summary')}
                    />
                    <div className="range-labels">
                      <span>{range.left}</span>
                      <span>{range.right}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className={error ? 'alert alert-danger' : ''}>
                  <div>{error}</div>
                </div>
              </div>
            </div>
            <div className="row pt25 mobile-form-button">
              <div className="col-sm-5 pb5 tablet-only">
                <Link
                  className="btn btn-transparent btn-left xs-text-center wa"
                  to={bindCorrsepondingBackUrl(
                    activeOffer,
                    state.slug,
                    id || propertyId,
                  )}
                >
                  <i className="icon-arrow-left left"></i>
                  <span>Back</span>
                </Link>
              </div>
              <div className="col-sm-7 pb5">
                <button
                  className="btn"
                  disabled={disabled}
                  onClick={() => {
                    this.onContinueClick()
                    updateProperty(
                      id || propertyId,
                      bindCorrsepondingContinueUrl(
                        activeOffer,
                        id || propertyId,
                        state.slug,
                      ),
                    )(state)
                  }}
                >
                  Continue{' '}
                  <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
                </button>
              </div>
            </div>
          </div>
          <div className="col-second xs-first">
            <ul className="snug-tips">
              <li>
                Detailed descriptions improve your property exposure and ensure
                the best match with your renter.
              </li>
              {ad !== null && (
                <li>
                  We will update the ad automatically after the property is
                  changed
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Description
