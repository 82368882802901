import {
  ON_SORTER_DROPDOWN_CHANGE,
  RECEIVE_APPLICATIONS,
  TOGGLE_FAVORITE_MARK,
  TOGGLE_SPINNER,
} from 'app/sm/applications/applications_actions'

const _defaultState = {
  applications: [],
  sortBy: 'rank',
  error: '',
  spinner: false,
}

const ApplicationsReducer = (state = _defaultState, action) => {
  let { type, applications, sorter, propertyId, spinner } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case RECEIVE_APPLICATIONS:
      return Object.assign(newState, { applications })
    case ON_SORTER_DROPDOWN_CHANGE:
      return Object.assign(newState, { sortBy: sorter })
    case TOGGLE_FAVORITE_MARK:
      const index = newState.applications.findIndex(
        (a) => a.offer.property.guidID === propertyId,
      )
      const {
        offer: {
          property: { isFavourite },
        },
      } = newState.applications[index]
      newState.applications[index].offer.property.isFavourite = !isFavourite
      return Object.assign({}, newState)
    case TOGGLE_SPINNER:
      return Object.assign(newState, { spinner })
    default:
      return state
  }
}

export default ApplicationsReducer
