import React from 'react'

function AdminDashboard() {
  return (
    <div>
      <h4>Admin Dashboard</h4>
    </div>
  )
}

export default AdminDashboard
