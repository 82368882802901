export const ACCOUNT_TYPE_NOT_SET = 0
export const ACCOUNT_TYPE_BASE_APR19 = 1
export const ACCOUNT_TYPE_PLUS_JUL19 = 2
export const ACCOUNT_TYPE_MATCH_PLUS_BASE70 = 3
export const ACCOUNT_TYPE_STANDARD_MAR20 = 4
export const ACCOUNT_TYPE_PLUS_MAR20 = 5
export const ACCOUNT_TYPE_GROWTH_DEC20 = 6
export const ACCOUNT_TYPE_LITE = 7
export const ACCOUNT_TYPE_GROWTH_MAR21 = 8
export const ACCOUNT_TYPE_PLUS_MAR21 = 9
export const ACCOUNT_TYPE_ENTERPRISE_RAYWHITE_OCT20 = 10
export const ACCOUNT_TYPE_ENTERPRISE_RESICONNECT_OCT20 = 11
export const ACCOUNT_TYPE_PREMIER_FEB23 = 12
export const ACCOUNT_TYPE_GROWTH_FEB23 = 13
export const ACCOUNT_TYPE_GROWTH_5PER_FEB24 = 14
export const ACCOUNT_TYPE_GROWTH_10PER_FEB24 = 15
export const ACCOUNT_TYPE_PREMIER_5PER_FEB24 = 16
export const ACCOUNT_TYPE_PREMIER_10PER_FEB24 = 17
export const ACCOUNT_TYPE_ESSENTIAL_APR24 = 18
export const ACCOUNT_TYPE_ESSENTIAL_PLUS_APR24 = 19
export const ACCOUNT_TYPE_PREMIER_APR24 = 20
export const ACCOUNT_TYPE_ENTERPRISE_SPONSORED_RAY_WHITE_JUN24 = 21
export const ACCOUNT_TYPE_ENTERPRISE_SPONSORED_YOUR_PORTER_JUN24 = 22
export const ACCOUNT_TYPE_ENTERPRISE_SPONSORED_COMPARE_AND_CONNECT_JUN24 = 23
export const ACCOUNT_TYPE_ENTERPRISE_SPONSORED_DIRECT_CONNECT_JUN24 = 24
export const ACCOUNT_TYPE_ENTERPRISE_SPONSORED_ELDERS_JUL24 = 25

export const accountTypes = [
  { guid: ACCOUNT_TYPE_NOT_SET, name: 'Not Set' },
  { guid: ACCOUNT_TYPE_PREMIER_APR24, name: 'Premier (Apr24)' },
  { guid: ACCOUNT_TYPE_ESSENTIAL_PLUS_APR24, name: 'Essential Plus (Apr24)' },
  { guid: ACCOUNT_TYPE_ESSENTIAL_APR24, name: 'Essential (Apr24)' },
  { guid: ACCOUNT_TYPE_LITE, name: 'Lite (March 2021) - FEATURE RESTRICTED' },
  {
    guid: ACCOUNT_TYPE_ENTERPRISE_SPONSORED_COMPARE_AND_CONNECT_JUN24,
    name: 'ESP - Compare & Connect (Jun 24)',
  },
  {
    guid: ACCOUNT_TYPE_ENTERPRISE_SPONSORED_DIRECT_CONNECT_JUN24,
    name: 'ESP - Direct Connect (Jun 24)',
  },
  {
    guid: ACCOUNT_TYPE_ENTERPRISE_SPONSORED_ELDERS_JUL24,
    name: 'ESP - Elders (Jul 24)',
  },
  {
    guid: ACCOUNT_TYPE_ENTERPRISE_SPONSORED_RAY_WHITE_JUN24,
    name: 'ESP - Ray White (Jun 24)',
  },
  {
    guid: ACCOUNT_TYPE_ENTERPRISE_SPONSORED_YOUR_PORTER_JUN24,
    name: 'ESP - Your Porter (Jun 24)',
  },
  {
    guid: ACCOUNT_TYPE_ENTERPRISE_RAYWHITE_OCT20,
    name: 'OLD - Enterprise - Ray White (Oct 20)',
  },
  {
    guid: ACCOUNT_TYPE_ENTERPRISE_RESICONNECT_OCT20,
    name: 'OLD - Enterprise - ResiConnect (Oct 20)',
  },
  { guid: ACCOUNT_TYPE_GROWTH_5PER_FEB24, name: 'OLD - Growth (Feb24 5%)' },
  { guid: ACCOUNT_TYPE_GROWTH_10PER_FEB24, name: 'OLD - Growth (Feb24 10%)' },
  { guid: ACCOUNT_TYPE_PREMIER_5PER_FEB24, name: 'OLD - Premier (Feb24 5%)' },
  { guid: ACCOUNT_TYPE_PREMIER_10PER_FEB24, name: 'OLD - Premier (Feb24 10%)' },
  { guid: ACCOUNT_TYPE_PREMIER_FEB23, name: 'OLD - Premier (Feb 23)' },
  { guid: ACCOUNT_TYPE_GROWTH_FEB23, name: 'OLD - Growth (Feb 23)' },
  { guid: ACCOUNT_TYPE_GROWTH_MAR21, name: 'OLD - Growth (Mar 2021)' },
  { guid: ACCOUNT_TYPE_PLUS_MAR21, name: 'OLD - Plus (Mar 2021)' },
  { guid: ACCOUNT_TYPE_BASE_APR19, name: 'OLD - Base (2019-04-12)' },
  { guid: ACCOUNT_TYPE_PLUS_JUL19, name: 'OLD - Plus (July 2019)' },
  { guid: ACCOUNT_TYPE_MATCH_PLUS_BASE70, name: 'OLD - Match Plus (Base70)' },
  { guid: ACCOUNT_TYPE_STANDARD_MAR20, name: 'OLD - Standard (March 2020)' },
  { guid: ACCOUNT_TYPE_PLUS_MAR20, name: 'OLD - Plus (March 2020)' },
  { guid: ACCOUNT_TYPE_GROWTH_DEC20, name: 'OLD - Growth (Dec 2020)' },
]

export const UPGRADE_TYPE_MESSAGES = 'messages'
export const UPGRADE_TYPE_VIEWINGS = 'viewings'
export const UPGRADE_TYPE_SETTINGS = 'settings'
export const UPGRADE_TYPE_APPLICATIONS = 'applications'
export const UPGRADE_TYPE_ACTIVITY = 'liteActivity'

export const isAccountLite = (accountType) => {
  if (accountType) {
    return accountType === ACCOUNT_TYPE_LITE
  }
  return false
}

export const isAccountPremier = (accountType) =>
  [
    ACCOUNT_TYPE_PREMIER_FEB23,
    ACCOUNT_TYPE_PREMIER_5PER_FEB24,
    ACCOUNT_TYPE_PREMIER_10PER_FEB24,
  ].some((acc) => acc === accountType)

export const selectAccountType = (plan) => {
  const NoPlan = 'N/A'

  if (plan < ACCOUNT_TYPE_NOT_SET) {
    return NoPlan
  }

  const foundAccounts = accountTypes.filter((account) => account.guid === plan)

  if (foundAccounts.length < 1 || foundAccounts.length > 1) {
    return NoPlan
  }

  return foundAccounts[0].name
}
