import React from 'react'

import { Link } from 'react-router-dom'

import RegisterEmail from 'app/session/register_components/register_email_connection'
import RegisterFacebook from 'app/session/register_components/register_facebook'

function JoinManagerForm() {
  return (
    <div>
      <h3 className="mb20">Join Snug</h3>

      <p>Join to approve BondCover Application</p>

      <RegisterFacebook form="Sign up" />

      <h4 className="mb25 mt40">Sign up with your email</h4>

      <ul className="snug-tips">
        <li>W've pre-filled a few details for you.</li>
      </ul>

      <RegisterEmail />
    </div>
  )
}

export default JoinManagerForm
