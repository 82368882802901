import React, { useEffect, useMemo, useReducer, useState } from 'react'

import { connect } from 'react-redux'
import {
  matchPath,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import {
  Box,
  ButtonWithIcon,
  LoadingSection,
} from 'app/components/design-system-components'
import { ArrowBackIosRounded } from 'app/components/design-system-components/icons/navigation'
import * as actFilters from 'app/dashboard/team_activity/ActivityFilters'
import { ManagerDetailedVacancyTable } from 'app/dashboard/team_activity/VacancyReporting/ManagerDetailedVacancyTable'
import { VacancyTable } from 'app/dashboard/team_activity/VacancyReporting/VacancyTable'
import { getVacancyReport } from 'app/services/http/teams/vacancy-report'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { filterReducerFactory } from 'app/utils/reducers/filtersReducer'

const VacancyList = ({ currentTeam, isEnabled, disabledAlertCmp }) => {
  const {
    state: vacancyList,
    setState: setVacancyList,
    loadingStates: listLoadingStates,
    loadingStatesHelpers: setListLoadingStatesHelpers,
  } = useStateWithLoading([])

  const [filtersState, filtersDispatch] = useReducer(
    filterReducerFactory(actFilters.filtersInitialState),
    actFilters.filtersInitialState,
  )

  const [detailedUser, setDetailedUser] = useState(null)

  useEffect(() => {
    loadVacancyReport()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const match = useRouteMatch()
  const history = useHistory()

  const location = useLocation()

  useEffect(() => {
    const detailedManagerId = matchPath(location.pathname, {
      path: `${match.path}/:id`,
    })?.params?.id

    if (!detailedManagerId) {
      setDetailedUser(null)
    }

    setDetailedUser(vacancyList?.find(({ id }) => id === detailedManagerId))
  }, [location, vacancyList])

  const loadVacancyReport = () => {
    setListLoadingStatesHelpers.startLoading()
    const { range = {} } = actFilters.getFilters(filtersState)
    return getVacancyReport(currentTeam.guid, {
      ...range,
      q: filtersState.search,
    })
      .then((items) => {
        setVacancyList(items)
        setListLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => setListLoadingStatesHelpers.setError(err))
  }

  const emptyStateMessage = <Box>No results</Box>

  const goToManagerStats = (id) => {
    history.push(`${match.url}/${id}`)
  }

  const onBackToList = () => {
    history.push(match.url)
  }

  const memoizedTable = useMemo(
    () => (
      <VacancyTable
        vacancyListData={vacancyList}
        onManagerDetails={goToManagerStats}
      />
    ),
    [vacancyList],
  )

  if (!isEnabled) {
    return disabledAlertCmp
  }

  return (
    <>
      {!detailedUser && (
        <LoadingSection
          loadingState={listLoadingStates}
          actionHandler={loadVacancyReport}
          loaderProps={{ fontSize: '48px' }}
        >
          {memoizedTable}
          {!vacancyList?.length && (
            <Box
              textAlign="center"
              lineHeight={1.4}
              m="auto"
              mt={8}
              width="30%"
            >
              {emptyStateMessage}
            </Box>
          )}
        </LoadingSection>
      )}

      <Route path={`${match.path}/:id`} exact>
        {!!detailedUser && (
          <ManagerVacancyReport onBack={onBackToList} user={detailedUser} />
        )}
      </Route>
    </>
  )
}

const mapStateToProps = ({ session }) => {
  return {
    currentTeam: session.currentTeam,
    currentAgency: session.currentAgency,
  }
}

export const VacancyListPage = connect(mapStateToProps)(VacancyList)

const ManagerVacancyReport = ({ user, onBack }) => {
  const offersTotals = (({
    avgDaysOnMarket,
    vacantProperties,
    lessWeekVacantProperties,
    moreWeekVacantProperties,
    vacantDays,
    lostRent,
    newManagement,
    receivedApps,
    totalEnquiries,
  }) => ({
    avgDaysOnMarket,
    vacantProperties,
    lessWeekVacantProperties,
    moreWeekVacantProperties,
    vacantDays,
    lostRent,
    newManagement,
    receivedApps,
    totalEnquiries,
  }))(user)

  const memoizedTable = useMemo(
    () => (
      <ManagerDetailedVacancyTable
        managerName={user?.managerName}
        offersList={user?.offers}
        offersTotals={offersTotals}
      />
    ),
    [user?.offers],
  )

  return (
    <>
      <ButtonWithIcon
        variant="flat"
        IconCmp={ArrowBackIosRounded}
        onClick={onBack}
        mb={3}
      >
        Back
      </ButtonWithIcon>
      {memoizedTable}
    </>
  )
}
