import React from 'react'

import moment from 'moment'

import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import { PropertySearchContainer } from 'app/dashboard/team_overview_container'
import TwoColumnContainer, {
  LeftComponent,
} from 'app/shared_components/layout_component/two_column_layout_components'
import NotFound404 from 'app/shared_components/not_found_404/not_found_404'
import { history } from 'app/shared_components/router'
import Spinner from 'app/sm/common/spinner'
import { findChosenTeamBySlug, urlTo } from 'app/sm/helpers'
import { DropDownForManager } from 'app/sm/properties/components/property_search_filter_util'
import ViewingRunItem from 'app/sm/viewings_new_run/viewingrun_item/component'
import { isViewingRunEnabled } from 'config/features'

import 'app/sm/viewings_new_run/dashboard/style.scss'

const Loadmore = ({ onClick }) => {
  return (
    <button
      className="btn hollow-button-green p0 mb5 width120px"
      onClick={onClick}
    >
      Load more
    </button>
  )
}

class RunDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: moment().format('ddd, D MMM YYYY'),
      managerNameList: [],
      managerList: [],
      showAllTab: false,
      todayFilterOn: false,
      fetchedRunSummaries: [],
      loadMoreSpinner: false,
      tableSpinner: false,
    }
  }

  componentDidMount() {
    const { guid } = this.getChosenTeam()
    guid && this.loadManagerList(guid)
    guid && this.callFetchViewingRuns(guid)
  }

  componentDidUpdate(prevProps) {
    const { guid } = this.getChosenTeam()

    if (
      prevProps.match.params.teamSlug !== this.props.match.params.teamSlug ||
      prevProps.teams !== this.props.teams
    ) {
      guid && this.loadManagerList(guid)
      guid && this.callFetchViewingRuns(guid)
    }
  }

  onClickViewingRun = (viewingRunGUID) => {
    this.props
      .fetchViewingRunDetail(viewingRunGUID)
      .then(({ viewingRun }) => {
        const manager =
          this.state.managerList.find(
            (manager) => manager.guidID === viewingRun.managerGUID,
          ) || {}
        // this is the request to be passed to preview page thru redux, which will be used to query
        // google map direction api. the difference with create viewing run when navigating to preview page
        // is here it will strictly use the order provided (because viewings are already scheduled) and
        // it's read only
        const viewingRunRequest = {
          agencyGUID: viewingRun.agencyGUID,
          managerGUID: viewingRun.managerGUID,
          managerProfile: manager.profile,
          // snick property and viewing guid into propertyInfo
          selectedPropertyInfos: viewingRun.waypointDetails.map(
            (waypointDetail) => ({
              property: waypointDetail.property,
              viewingGUID: waypointDetail.viewingGUID,
            }),
          ),
          travelMode: viewingRun.travelMode,
          breakDuration: viewingRun.breakDuration,
          viewingStartTimes: viewingRun.waypointDetails.map(
            (waypointDetail) => waypointDetail.viewingStartTime,
          ),
          viewingDurations: viewingRun.waypointDetails.map(
            (waypointDetail) => waypointDetail.viewingDuration,
          ),
        }
        return this.props.generateViewingRun(viewingRunRequest)
      })
      .then(() => {
        const { teamSlug = '' } = this.props.match.params
        history.push(urlTo('viewingRunReadOnly', { teamSlug, viewingRunGUID }))
      })
  }

  onLoadMoreClicked = () => {
    const { chosenFilterManger: managerGUID = '' } = this.state
    const { guid } = this.getChosenTeam()
    guid &&
      this.callFetchViewingRuns(
        guid,
        managerGUID,
        this.state.todayFilterOn,
        true,
      )
  }

  onManagerFilterChange = (event) => {
    this.setState(
      {
        chosenFilterManger: event.target.value,
      },
      () => {
        const { chosenFilterManger: managerGUID = '' } = this.state
        const { guid } = this.getChosenTeam()
        if (!guid) {
          return
        }

        this.setState({ showAllTab: managerGUID === '' })
        guid &&
          this.callFetchViewingRuns(guid, managerGUID, this.state.todayFilterOn)
      },
    )
  }

  onTodayFilterChange = () => {
    this.setState({ todayFilterOn: !this.state.todayFilterOn }, () => {
      const { chosenFilterManger: managerGUID = '' } = this.state
      const { guid } = this.getChosenTeam()
      guid &&
        this.callFetchViewingRuns(
          guid,
          managerGUID,
          this.state.todayFilterOn,
          false,
        )
    })
  }

  // return a team object (with guid) or undefined if not found
  getChosenTeam = () => {
    const { teams } = this.props
    const { teamSlug = '' } = this.props.match.params
    return findChosenTeamBySlug(teams, teamSlug) || {}
  }

  callFetchViewingRuns = (
    agencyGUID,
    managerGUID = '',
    todayFilterOn,
    loadMore = false,
  ) => {
    const previousRunSummaries = loadMore ? this.state.fetchedRunSummaries : []
    loadMore
      ? this.setState({ loadMoreSpinner: true })
      : this.setState({ tableSpinner: true })

    let startTime, endTime
    if (todayFilterOn) {
      startTime = moment().startOf('day').format()
      endTime = moment().endOf('day').format()
    }
    this.props
      .fetchViewingRuns(
        agencyGUID,
        managerGUID,
        startTime,
        endTime,
        previousRunSummaries.length,
      )
      .then(({ runSummaries, hasMore }) => {
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
        this.setState({
          fetchedRunSummaries: previousRunSummaries.concat(runSummaries),
          hasMore,
        })
      })
      .catch((error) => {
        console.log('callFetchViewingRuns error', error)
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
      })
  }

  changeToMeFilter = () => {
    const { showAllTab, managerList } = this.state
    const { guid } = this.getChosenTeam()
    if (!guid) {
      return
    }
    const currentManagerAgency = (managerList || []).filter((m) => m.isMe)
    if (currentManagerAgency.length === 0) {
      return
    }
    const currentManagerAgencyGUID = currentManagerAgency[0].guidID
    const currentChoice =
      currentManagerAgencyGUID && !showAllTab ? currentManagerAgencyGUID : ''

    this.setState({ chosenFilterManger: currentChoice }, () => {
      guid &&
        this.callFetchViewingRuns(
          guid,
          this.state.chosenFilterManger,
          this.state.todayFilterOn,
        )
    })
    currentManagerAgencyGUID && showAllTab === false
      ? this.setState({ showAllTab: true })
      : this.setState({ showAllTab: false })
  }

  generateCrumbs = () => {
    const { teamSlug = '' } = this.props.match.params
    const curTeam =
      this.props.teams.find((team) => team.slug === teamSlug) || {}
    let crumbs = [
      {
        text: curTeam.name,
        link: urlTo('teamOverview', { teamSlug }),
      },
      {
        text: 'Viewings',
        link: `${urlTo('prospectSummary', { teamSlug })}?stage=Viewing`,
      },
      {
        text: 'Run',
        link: '#',
      },
    ]
    return crumbs
  }

  loadManagerList = (agencyGUID) => {
    agencyGUID &&
      this.props.fetchTeamManagerList(agencyGUID).then(({ managerList }) => {
        const managerNameList = managerList.map(
          (manager) =>
            manager.profile.firstName + ' ' + manager.profile.lastName,
        )
        this.setState({
          managerNameList: managerNameList,
          managerList: managerList,
          chosenManager: managerNameList[0],
        })
      })
  }

  render() {
    const { teamSlug = '' } = this.props.match.params

    return isViewingRunEnabled(teamSlug, this.props.teams) ? (
      <TwoColumnContainer>
        <LeftComponent>
          <Breadcrumbs crumbs={this.generateCrumbs()} />
          <div className="preference-header-container">
            <div className="viewing-run-dashboard-header">
              <h4 className="header">{'Viewing runs'}</h4>
              {this.state.fetchedRunSummaries.length !== 0 && (
                <button
                  className="btn wa"
                  onClick={() => {
                    this.props.generateResetViewingRun()
                    history.push(urlTo('newViewingRun', { teamSlug }))
                  }}
                >
                  Create Run
                </button>
              )}
            </div>
            <p className="title">
              {
                'Create a run to optimise your viewing schedule and manage your day.'
              }
            </p>
          </div>
          <PropertySearchContainer>
            {this.state.fetchedRunSummaries.length !== 0 &&
              this.state.managerList && (
                <DropDownForManager
                  label="Manager:"
                  options={this.state.managerList}
                  value={this.state.chosenFilterManger}
                  onChange={this.onManagerFilterChange}
                  changeToMeFilter={this.changeToMeFilter}
                  showAllTab={this.state.showAllTab}
                />
              )}

            {this.state.fetchedRunSummaries.length !== 0 && (
              <div className="pl15 smlpl0">
                <label className="ibm filter-today-checkbox">
                  <input
                    type="checkbox"
                    className="input-box wa pt0"
                    onChange={this.onTodayFilterChange}
                  ></input>
                  <span>Today only</span>
                </label>
              </div>
            )}
          </PropertySearchContainer>

          <div>
            {this.state.tableSpinner ? (
              <Spinner />
            ) : (
              this.state.fetchedRunSummaries.map((runSummary) => {
                return (
                  <ViewingRunItem
                    key={runSummary.guidID}
                    manager={
                      this.state.managerList.find(
                        (manager) => manager.guidID === runSummary.managerGUID,
                      ) || {}
                    }
                    runSummary={runSummary}
                    onClickRow={() => this.onClickViewingRun(runSummary.guidID)}
                  />
                )
              })
            )}
          </div>

          {this.state.fetchedRunSummaries.length === 0 && (
            <div className="empty-viewing-run">
              <p className="title">
                {"It looks like you haven't added a viewing run yet"}
              </p>
              <button
                className="btn wa"
                onClick={() =>
                  history.push(urlTo('newViewingRun', { teamSlug }))
                }
              >
                Create Run
              </button>
            </div>
          )}

          {this.state.hasMore && (
            <div className={`text-center mt20 ml20`}>
              {this.state.loadMoreSpinner ? (
                <Spinner />
              ) : (
                <Loadmore onClick={this.onLoadMoreClicked} />
              )}
            </div>
          )}
        </LeftComponent>
      </TwoColumnContainer>
    ) : (
      <NotFound404 />
    )
  }
}

export default RunDashboard
