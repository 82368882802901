import React, { Component } from 'react'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import {
  AlertSensitiveInfo,
  TextSpan,
} from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { Text } from 'app/components/design-system-components/typography'
import * as Display from 'app/components/display/display'
import ProgressBarComponent from 'app/components/progress_bar/connection'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { listOfferAttachments } from 'app/services/http/offers'
import * as snugLogger from 'app/services/snugLogger'
import * as snugNotifier from 'app/services/snugNotifier'
import DocumentList from 'app/shared_components/document_list/document_list'
import getParameter from 'app/shared_components/parameter_parser'
import Header from 'app/shared_components/rental_reputation_header_connection'
import { history } from 'app/shared_components/router'
import SnugTip from 'app/shared_components/snug_tips'
import { isDocAutoDeleted } from 'app/sm/docs_helpers'
import {
  DocumentTypes,
  isApplicationApplyAnywhere,
  isFromApplication,
  urlTo,
} from 'app/sm/helpers'
import { hasAutoDeletedDocsWarningModalConf } from 'app/sm/proof_of_income/components/WarningModal'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import * as textHelpers from 'app/utils/text/helpers'

class Documents extends Component {
  constructor(props) {
    super(props)
    const { backUrl } = props
    const propertyId = getParameter('propertyId')
    const applicationId = getParameter('applicationId')
    const isFromApplicationForm =
      isFromApplication(backUrl) || propertyId || applicationId
    this.state = {
      fileTypeError: '',
      newOtherDocsUpdated: false,
      isFromApplicationForm,
      attachments: [],
      autoDeletedDocsModalIsOpen: false,
    }
  }

  componentDidMount() {
    this.props.fetchDocuments()
    this.props.fetchCompleteness()
    const { offer } = this.props
    const { isFromApplicationForm } = this.state
    if (isFromApplicationForm) {
      if (offer) {
        this.fetchOfferAttachments()
      } else {
        this.fetchApplication().then(() => this.fetchOfferAttachments())
      }
    }
  }

  fetchOfferAttachments = () => {
    const {
      offer: { guidID },
      applicationDetails = {},
    } = this.props
    const { applicationType = 0 } = applicationDetails || {}
    const isApplyAnywhereApplication =
      isApplicationApplyAnywhere(applicationType)
    const propertyId = getParameter('propertyId')
    const applicationId = getParameter('applicationId')
    if (isApplyAnywhereApplication) {
      return
    }
    if (!guidID) {
      snugLogger.error(
        'offerGUID for fetching offer attachments is not present',
        {
          tags: {
            propertyGUID: propertyId,
            applicationGUID: applicationId,
          },
        },
      )
      return
    }
    listOfferAttachments(guidID)
      .then((offerAttachments) => {
        this.setState({ attachments: offerAttachments })
      })
      .catch((error) => {
        snugNotifier.error(error?.message)
      })
  }

  fetchApplication = () => {
    const { fetchApplication } = this.props
    const applicationId = getParameter('applicationId')
    if (!applicationId) return
    return fetchApplication(applicationId)
  }

  setOtherDocumentsChanged = (newOtherDocumentsAdded) => {
    this.setState({
      newOtherDocsUpdated: newOtherDocumentsAdded > 0,
    })
  }

  renderAttachments = () => {
    const { isFromApplicationForm, attachments } = this.state
    return (
      isFromApplicationForm &&
      attachments?.length > 0 && (
        <>
          <Text color={theme.colors.pdfGray}>
            Please also review the following information related to this
            property:
            <ul>
              {attachments.map((attachment) => (
                <li>
                  <a href={attachment.url}>{attachment.displayName}</a>
                </li>
              ))}
            </ul>
          </Text>
        </>
      )
    )
  }

  personalRefURL = `${urlTo('personalReferenceDetails')}${
    window.location.search
  }`

  toggleAutoDeletedDocsModal = () => {
    const { autoDeletedDocsModalIsOpen } = this.state
    this.setState({ autoDeletedDocsModalIsOpen: !autoDeletedDocsModalIsOpen })
  }

  renderAutoDeletedDocsModal = () => (
    <GenericModal
      onDismiss={this.toggleAutoDeletedDocsModal}
      title={hasAutoDeletedDocsWarningModalConf.title}
      modalFooter={
        <GenericModalFooter
          primaryBtnConfig={{
            title: hasAutoDeletedDocsWarningModalConf.primaryBtnTitle,
            onClick: this.toggleAutoDeletedDocsModal,
          }}
          secondaryBtnConfig={{
            title: hasAutoDeletedDocsWarningModalConf.secondaryBtnTitle,
            onClick: () => history.push(this.personalRefURL),
          }}
        />
      }
    >
      <AlertSensitiveInfo />
      <TextSpan mb={theme.space[6]} lineHeight={theme.lineHeights.encrption}>
        {hasAutoDeletedDocsWarningModalConf.body}
      </TextSpan>
    </GenericModal>
  )

  render() {
    const {
      spinner,
      documents,
      addDocument,
      deleteDocument,
      responseText,
      backUrl,
      property,
      completeness,
    } = this.props
    const { isFromApplicationForm, autoDeletedDocsModalIsOpen } = this.state

    const hasAutoDeletedDocs = documents.some(isDocAutoDeleted)

    return (
      <Display.CenterContentContainer componentClass="width100">
        <div className="documents proof-of-income pt0">
          <div className="row">
            <div className="col-xs-12">
              <RentalReputationHeaderReminder
                property={property}
                backUrl={backUrl}
                remindText={'Current application'}
                isFromApplicationForm={isFromApplicationForm}
                title="Other Documents"
              />
            </div>
          </div>
          <ProgressBarComponent completeness={completeness} />
          <Header
            title="Other Documents"
            message={textHelpers.OTHER_DOCUMENT_PROFILE_TEXT}
          />
          <AlertSensitiveInfo />
          {this.renderAttachments()}
          <div className="content row">
            <div className="col-xs-12">
              <DocumentList
                documents={documents}
                addDocument={addDocument}
                deleteDocument={deleteDocument}
                documentType={DocumentTypes.ArbitraryAttachment}
                spinner={spinner}
                responseText={responseText}
                fileTypeError={this.state.fileTypeError}
                setDocumentsChanged={this.setOtherDocumentsChanged}
                allowDeletion={true}
              />
            </div>
          </div>
          <SnugTip tipContent="You might choose to upload a cover letter or rental ledger from a prior property." />
          <Display.BottomContentContainer>
            <Display.CenterContentContainer componentClass="width100">
              <div className="submit-button-container">
                <BottomButton
                  clickNextFunction={
                    hasAutoDeletedDocs && this.toggleAutoDeletedDocsModal
                  }
                  nextUrl={hasAutoDeletedDocs ? undefined : this.personalRefURL}
                  btnText={
                    isFromApplicationForm
                      ? 'Save & Continue'
                      : 'Next: Personal Reference'
                  }
                  bottomButtonsClass="pt0 mt0 summary-bottom-button"
                  nextBtnClass="summary-green-button-class"
                  backButtonClass="summary-grey-button-class"
                  nextButtonPositionClass="pr20 pl0"
                  showArrow={false}
                />
              </div>
            </Display.CenterContentContainer>
          </Display.BottomContentContainer>
        </div>
        {autoDeletedDocsModalIsOpen && this.renderAutoDeletedDocsModal()}
      </Display.CenterContentContainer>
    )
  }
}

export default Documents
