import {
  ADD_RENTAL_REPUTATION_BACK_URL,
  CLEAR_RENTAL_BACK_URL,
  CLOSE_SIDE_BAR,
  DELETE_LAST_RENTAL_BACK_URL,
  RESET_SIDE_BAR,
  SET_BACK_URL,
} from 'app/shared_components/actions'

const _defaultState = {
  backUrl: '/',
  close: false,
  rentalBackUrlList: [],
}

const SharedReducer = (state = _defaultState, action) => {
  let { type, backUrl, close, rentalBackUrl } = action
  Object.freeze(state)
  let newState = Object.assign({}, state)
  switch (type) {
    case SET_BACK_URL:
      return Object.assign({}, state, { backUrl })
    case CLOSE_SIDE_BAR:
      return Object.assign({}, state, { close })
    case RESET_SIDE_BAR:
      return Object.assign({}, state, { close })
    case ADD_RENTAL_REPUTATION_BACK_URL:
      newState.rentalBackUrlList.push(rentalBackUrl)
      return Object.assign({}, state, newState)
    case DELETE_LAST_RENTAL_BACK_URL:
      newState.rentalBackUrlList.pop()
      return Object.assign({}, state, newState)
    case CLEAR_RENTAL_BACK_URL:
      newState.rentalBackUrlList = []
      return Object.assign({}, state, newState)
    default:
      return state
  }
}

export default SharedReducer
