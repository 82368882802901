import React from 'react'

import { Box, Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import * as Display from 'app/components/display/display'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as Form from 'app/shared_components/form_component'

import 'app/sm/team_viewing_preference/notifications/style.scss'
const CommunicationPreference = ({
  enquiries_auto_response_enabled,
  viewings_new,
  viewings_updates,
  applications_new,
  applications_updates,
  applications_approved,
  daily_viewings_report,
  send_daily_digest_notification,
  weekly_digest_report,
  daily_keys_report,
  onNotificationsBoxChanged,
  enquiryNotificationsSettingsEnabled,
  viewingNotificationsSettingsEnabled,
  applicationNotificationsSettingsEnabled,
  reportNotificationsSettingsEnabled,
  viewingRegistrantNotificationEnabled,
  onUpdateViewingRegistrantNotificationEnabled,
  newlyReceivedApplicationNotificationEnabled,
  applicationUpdatedNotificationEnabled,
  onUpdateApplicationNotificationEnabled,
  isUserAdmin = false,
  onlist_send_notifications_enabled,
}) => {
  return (
    <div className="preference-option-container">
      <p className="mb0">Select the notifications you would like to receive.</p>
      {enquiryNotificationsSettingsEnabled && (
        <div>
          <Display.SectionHeader
            text="Enquiries"
            hasSeperator={false}
            textClass="pl0"
          />
          <div className="display-flex flex-direction-column">
            <div className="mb24 pl15">
              <Form.Checkbox
                id="enquiries_auto_response_enabled"
                label="Auto Responses"
                checked={enquiries_auto_response_enabled}
                componentClassName="mt10"
                onChange={onNotificationsBoxChanged(
                  'enquiries_auto_response_enabled',
                )}
              />
              <span className="pl31 display-inline-block line-height-20">
                Receive a copy of enquiry auto responses.
              </span>
            </div>
          </div>

          <Flex flexDirection="column">
            <Box mb={7} pl={5}>
              <Form.Checkbox
                id="onlist_send_notifications_enabled"
                label="Onlist"
                checked={onlist_send_notifications_enabled}
                componentClassName="mt10"
                onChange={onNotificationsBoxChanged(
                  'onlist_send_notifications_enabled',
                )}
              />
              <Text as="span" pl={8} lineHeight="20px">
                New listing for configuration on Snug
              </Text>
            </Box>
          </Flex>
        </div>
      )}
      <Display.SectionHeader
        text="Viewings"
        hasSeperator={false}
        textClass="pl0"
      />
      <div className="mb30 pl15">
        <Form.Checkbox
          label="Viewing Registrations"
          checked={viewingRegistrantNotificationEnabled}
          onChange={onUpdateViewingRegistrantNotificationEnabled}
        />
        <div className="pl31 display-inline-block line-height-20">
          Receive emails for each new viewing registration. This won’t affect
          cancellation alerts, we’ll still send those to you.
        </div>
      </div>
      <Display.SectionHeader
        text="Applications"
        hasSeperator={false}
        textClass="pl0"
      />
      <div className="mb30 pl15">
        <Form.Checkbox
          label="New Received"
          checked={newlyReceivedApplicationNotificationEnabled}
          onChange={(event) =>
            onUpdateApplicationNotificationEnabled(
              event,
              'newlyReceivedApplicationNotificationEnabled',
            )
          }
        />
        <div className="pl31 display-inline-block line-height-20">
          Receive emails for each new application received for properties you
          manage.
        </div>
      </div>
      <div className="mb30 pl15">
        <Form.Checkbox
          label="Updated"
          checked={applicationUpdatedNotificationEnabled}
          onChange={(event) =>
            onUpdateApplicationNotificationEnabled(
              event,
              'applicationUpdatedNotificationEnabled',
            )
          }
        />
        <div className="pl31 display-inline-block line-height-20">
          Receive emails when applications that are assigned to you are updated.
        </div>
      </div>
      {viewingNotificationsSettingsEnabled && (
        <div>
          <Display.SectionHeader
            text="Viewings"
            hasSeperator={false}
            textClass="pl0"
          />
          <div className="display-flex flex-direction-column">
            <div className="mb24 pl15">
              <Form.Checkbox
                id="viewingsNew"
                label="New"
                checked={viewings_new}
                componentClassName="mt10"
                onChange={onNotificationsBoxChanged('viewings_new')}
              />
              <span className="pl31 display-inline-block line-height-20">
                New registration and times scheduled.
              </span>
            </div>
            <div className="mb24 pl15">
              <Form.Checkbox
                id="viewingsUpdates"
                label="Updates"
                checked={viewings_updates}
                componentClassName="mt10"
                onChange={onNotificationsBoxChanged('viewings_updates')}
              />
              <span className="pl31 display-inline-block line-height-20">
                Cancellations, feedback received and viewing reports.
              </span>
            </div>
          </div>
        </div>
      )}
      {applicationNotificationsSettingsEnabled && (
        <div>
          <Display.SectionHeader
            text="Applications"
            hasSeperator={false}
            textClass="pl0"
          />
          <div className="display-flex flex-direction-column">
            <div className="mb24 pl15">
              <Form.Checkbox
                id="applicationsNew"
                label="New"
                checked={applications_new}
                componentClassName="mt10"
                onChange={onNotificationsBoxChanged('applications_new')}
              />
              <span className="pl31 display-inline-block line-height-20">
                New applications received.
              </span>
            </div>
            <div className="mb24 pl15">
              <Form.Checkbox
                id="applicationsUpdates"
                label="Updates"
                checked={applications_updates}
                componentClassName="mt10"
                onChange={onNotificationsBoxChanged('applications_updates')}
              />
              <span className="pl31 display-inline-block line-height-20">
                Updated applications, lease offer and share application.
              </span>
            </div>
            <div className="mb24 pl15">
              <Form.Checkbox
                id="applicationsApproved"
                label="Approved / confirmed"
                checked={applications_approved}
                componentClassName="mt10"
                onChange={onNotificationsBoxChanged('applications_approved')}
              />
              <span className="pl31 display-inline-block line-height-20">
                Offer accepted
              </span>
            </div>
          </div>
        </div>
      )}
      {reportNotificationsSettingsEnabled && (
        <div>
          <Display.SectionHeader
            text="Reports"
            hasSeperator={false}
            textClass="p0"
          />
          <Box pl="15px" my={theme.space[8]}>
            <Form.Checkbox
              id="dailyViewingsReport"
              label="Daily Viewings Report"
              checked={daily_viewings_report}
              onChange={onNotificationsBoxChanged('daily_viewings_report')}
            />
            <Text as="div" pl={theme.space[8]}>
              Daily summary of viewings across the team.
            </Text>
          </Box>
          <Box pl="15px" my={theme.space[8]}>
            <Form.Checkbox
              id="reportsNew"
              label="Daily Summary"
              checked={send_daily_digest_notification}
              onChange={onNotificationsBoxChanged(
                'send_daily_digest_notification',
              )}
            />
            <Text as="div" pl={theme.space[8]}>
              Daily summary of key activities across the team.
            </Text>
          </Box>
          {isUserAdmin && (
            <Box pl="15px" my={theme.space[8]}>
              <Form.Checkbox
                id="weeklyDigestReport"
                label="Weekly Report (Admin)"
                checked={weekly_digest_report}
                onChange={onNotificationsBoxChanged('weekly_digest_report')}
              />
              <Text as="div" pl={theme.space[8]}>
                Team leasing performance report sent weekly on a Friday morning.
              </Text>
            </Box>
          )}
          {isUserAdmin && (
            <Box pl="15px" my={theme.space[8]}>
              <Form.Checkbox
                id="dailyKeysReport"
                label="Daily Keys Report (Admin)"
                checked={daily_keys_report}
                onChange={onNotificationsBoxChanged('daily_keys_report')}
              />
              <Text as="div" pl={theme.space[8]}>
                Due and overdue keys report sent daily.
              </Text>
            </Box>
          )}
        </div>
      )}
    </div>
  )
}

export default CommunicationPreference
