import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import iconOrangeTick from 'app/assets/icons/orange-tick.svg'
import iconGreenTick from 'app/assets/icons/round-green-tick.svg'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

const RegisterConfirmationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .checked-in-text {
    font-size: 40px;
  }
`

const ViewingInfoContainer = styled.div`
  margin-top: 40px;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  .address {
    margin-top: 32px;
  }
  .applicant-name {
    margin-top: 32px;
    font-size: 20px;
  }
  .applicant-num {
    margin-top: 8px;
    font-size: 18px;
  }
`

const GreenTickContainer = styled.div`
  margin-top: 24px;
  text-align: center;
  img {
    width: 125px;
    height: 125px;
  }
`

function RegisterConfirmation(props) {
  const { registeredInfo } = props
  const {
    firstName = '',
    lastName = '',
    mobilePhone = '',
    propertyFriendlyName = '',
    viewingDate = '',
    hasColdSymptoms = false,
    hasRecentlyVisitedOverseasOrAffectedArea = false,
  } = registeredInfo
  return (
    <RegisterConfirmationContainer>
      <div className="checked-in-text">Checked In</div>
      <GreenTickContainer>
        <img
          src={
            hasColdSymptoms || hasRecentlyVisitedOverseasOrAffectedArea
              ? iconOrangeTick
              : iconGreenTick
          }
          alt="checked in status"
        />
        {hasColdSymptoms && <div className="mt10">Cold or flu symptoms</div>}
        {hasRecentlyVisitedOverseasOrAffectedArea && (
          <div className="mt10">Travelled overseas or to an affected area</div>
        )}
      </GreenTickContainer>
      <ViewingInfoContainer>
        <div className="">
          <b>
            {moment(viewingDate).format(
              dateTimeHelpers.TIME_AM_PM_HALF_DAY_HALF_MONTH,
            )}
          </b>
        </div>
        <div className="address">
          <b>{propertyFriendlyName}</b>
        </div>
        <div className="applicant-name">
          <div>Name: {`${firstName} ${lastName}`}</div>
          <div>Number: {`${mobilePhone}`}</div>
        </div>
      </ViewingInfoContainer>
      <p className="mt30 text-align-center">
        Important: to help comply with COVID-19 health and safety requirements,
        each individual attendee must register and check-in to the viewing (even
        if a group). Thank you for cooperating.
      </p>
    </RegisterConfirmationContainer>
  )
}

export default RegisterConfirmation
