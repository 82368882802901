import React, { useState } from 'react'

import { Field, FieldInputProps, FieldProps, Form } from 'formik'
import styled from 'styled-components'

import {
  ageRangeOptions,
  formAnswers,
  formFields,
  formQuestion,
} from 'app/components/Ballot/Survey/form.utils'
import { Box, Flex } from 'app/components/design-system-components'
import { TextArea } from 'app/components/design-system-components/inputs'
import { SelectField } from 'app/components/design-system-components/inputs/Select/Select'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledFormLabel = styled.label`
  margin-left: ${theme.space[3]}px;
  width: 100%;
`

export const FormBody = () => {
  const [otherComments, setOtherComments] = useState({
    [formFields.identifyAsAboriginalOrTorresStraitIslander]: '',
    [formFields.identifyAsCulturallyAndLinguisticallyDiverse]: '',
    [formFields.identifyAsPersonWithDisability]: '',
  })

  const handleOtherCommentChange = (formKey: string, value: string) => {
    setOtherComments({
      ...otherComments,
      [formKey]: value,
    })
  }

  const _renderFormQuestionAnswer = (
    formKey: string,
    fieldProps: FieldInputProps<any>,
  ) => {
    return (
      <>
        {formAnswers[formKey]?.map((answer) => {
          if (answer === 'otherComments') {
            return (
              <Flex mt={2} alignItems="center">
                <input
                  {...fieldProps}
                  id={`${formKey}-${answer}`}
                  value={`${answer}`}
                  checked={Boolean(
                    fieldProps.value === answer || otherComments[formKey],
                  )}
                  name={formKey}
                  type="radio"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    fieldProps.onChange(event)
                  }}
                />
                <Box width="95%">
                  <StyledFormLabel htmlFor={`${formKey}-${answer}`}>
                    {!fieldProps.value.includes(answer) &&
                    !otherComments[formKey] ? (
                      'Other comments'
                    ) : (
                      <TextArea
                        containerProps={{}}
                        {...fieldProps}
                        onChange={(
                          event: React.ChangeEvent<HTMLTextAreaElement>,
                        ) => {
                          handleOtherCommentChange(formKey, event.target.value)
                          if (!event.target.value) {
                            event.target.value = answer
                          }
                          fieldProps.onChange(event)
                        }}
                        value={otherComments[formKey]}
                        placeholder="Other comments"
                        rows={1}
                      />
                    )}
                  </StyledFormLabel>
                </Box>
              </Flex>
            )
          }

          return (
            <Flex mt={2} alignItems="center">
              <input
                {...fieldProps}
                id={`${formKey}-${answer}`}
                value={answer}
                name={formKey}
                type="radio"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (otherComments[formKey]) {
                    setOtherComments({
                      ...otherComments,
                      [formKey]: '',
                    })
                  }
                  fieldProps.onChange(event)
                }}
              />
              <StyledFormLabel htmlFor={`${formKey}-${answer}`}>
                {answer}
              </StyledFormLabel>
            </Flex>
          )
        })}
      </>
    )
  }

  return (
    <Form>
      <Box mb={7} mt={5}>
        <FieldWrapper
          id={formFields.identifyAsAboriginalOrTorresStraitIslander}
          name={formFields.identifyAsAboriginalOrTorresStraitIslander}
          required
          label={
            formQuestion[formFields.identifyAsAboriginalOrTorresStraitIslander]
          }
        >
          <Field
            name={formFields.identifyAsAboriginalOrTorresStraitIslander}
            render={({ field }: FieldProps) =>
              _renderFormQuestionAnswer(
                formFields.identifyAsAboriginalOrTorresStraitIslander,
                field,
              )
            }
          />
        </FieldWrapper>
      </Box>
      <Box mb={7} mt={5}>
        <FieldWrapper
          id={formFields.isOnVictorianHousingRegisterWaitingList}
          name={formFields.isOnVictorianHousingRegisterWaitingList}
          required
          label={
            formQuestion[formFields.isOnVictorianHousingRegisterWaitingList]
          }
        >
          <Field
            name={formFields.isOnVictorianHousingRegisterWaitingList}
            render={({ field }: FieldProps) =>
              _renderFormQuestionAnswer(
                formFields.isOnVictorianHousingRegisterWaitingList,
                field,
              )
            }
          />
        </FieldWrapper>
      </Box>
      <Box mb={7} mt={5}>
        <FieldWrapper
          id={formFields.resideSocialOrPublicHousing}
          name={formFields.resideSocialOrPublicHousing}
          required
          label={formQuestion[formFields.resideSocialOrPublicHousing]}
        >
          <Field
            name={formFields.resideSocialOrPublicHousing}
            render={({ field }: FieldProps) =>
              _renderFormQuestionAnswer(
                formFields.resideSocialOrPublicHousing,
                field,
              )
            }
          />
        </FieldWrapper>
      </Box>
      <Box mb={7} mt={5}>
        <FieldWrapper
          id={formFields.ageRange}
          name={formFields.ageRange}
          required
          label={formQuestion[formFields.ageRange]}
          pb={3}
        >
          <SelectField
            id={formFields.ageRange}
            name={formFields.ageRange}
            label="Age Range"
            options={ageRangeOptions}
          />
        </FieldWrapper>
      </Box>
      <Box mb={7} mt={5}>
        <FieldWrapper
          id={formFields.applicantGender}
          name={formFields.applicantGender}
          required
          label={formQuestion[formFields.applicantGender]}
        >
          <Field
            name={formFields.applicantGender}
            render={({ field }: FieldProps) =>
              _renderFormQuestionAnswer(formFields.applicantGender, field)
            }
          />
        </FieldWrapper>
      </Box>
      <Box mb={7} mt={5}>
        <FieldWrapper
          id={formFields.identifyAsCulturallyAndLinguisticallyDiverse}
          name={formFields.identifyAsCulturallyAndLinguisticallyDiverse}
          required
          label={
            formQuestion[
              formFields.identifyAsCulturallyAndLinguisticallyDiverse
            ]
          }
          mb={5}
        >
          <Field
            name={formFields.identifyAsCulturallyAndLinguisticallyDiverse}
            render={({ field }: FieldProps) =>
              _renderFormQuestionAnswer(
                formFields.identifyAsCulturallyAndLinguisticallyDiverse,
                field,
              )
            }
          />
        </FieldWrapper>
      </Box>
      <Box mb={7} mt={5}>
        <FieldWrapper
          id={formFields.identifyAsPersonWithDisability}
          name={formFields.identifyAsPersonWithDisability}
          required
          label={formQuestion[formFields.identifyAsPersonWithDisability]}
          pb={3}
        >
          <Field
            name={formFields.identifyAsPersonWithDisability}
            render={({ field }: FieldProps) =>
              _renderFormQuestionAnswer(
                formFields.identifyAsPersonWithDisability,
                field,
              )
            }
          />
        </FieldWrapper>
      </Box>
    </Form>
  )
}
