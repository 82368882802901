import React, { useState } from 'react'

import { Field, Form, useFormikContext } from 'formik'
import styled from 'styled-components'

import { Modal } from 'app/components/design-system-components'
import {
  InputField,
  TelField,
} from 'app/components/design-system-components/inputs'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  EMAIL_NETWORK_TYPE,
  formFields,
} from 'app/session/bc_profile/profile_update/form.utils'
import FormActions from 'app/session/bc_profile/profile_update/FormActions'

const StyledFieldWrapper = styled(FieldWrapper)`
  max-width: ${theme.space[11]}px;
  margin-bottom: ${theme.space[7]}px;
`

const FormBody = ({ formBag, currentUser }) => {
  const [updateSocialEmailConfirmation, setUpdateSocialEmailConfirmation] =
    useState(false)

  const { submitForm } = useFormikContext()

  const { isSubmitting, values } = formBag

  const submitHandler = () => {
    if (
      values.email !== currentUser.email &&
      currentUser.networkType !== EMAIL_NETWORK_TYPE
    ) {
      setUpdateSocialEmailConfirmation(true)
    } else {
      submitForm()
    }
  }

  const renderConfirmationModal = () => {
    if (!updateSocialEmailConfirmation || !currentUser.networkType) return null

    const network =
      currentUser.networkType.charAt(0).toUpperCase() +
      currentUser.networkType.substr(1).toLowerCase()
    const bodyText = `Updating your email address will mean it will be different to the ${network} profile. Do you want to continue?`

    return (
      <Modal
        toggleModal={() => setUpdateSocialEmailConfirmation(false)}
        modalHeading="Update account confirmation"
        modalBody={<div>{bodyText}</div>}
        primaryLabel="Confirm"
        primaryAction={() => submitForm()}
        secondaryLabel="Cancel"
        secondaryAction={() => setUpdateSocialEmailConfirmation(false)}
      />
    )
  }

  return (
    <Form>
      <StyledFieldWrapper
        id={formFields.firstName}
        label="First Name"
        required
        name={formFields.firstName}
      >
        <Field
          name={formFields.firstName}
          id={formFields.firstName}
          as={InputField}
        />
      </StyledFieldWrapper>

      <StyledFieldWrapper
        id={formFields.lastName}
        label="Last Name"
        required
        name={formFields.lastName}
      >
        <Field
          name={formFields.lastName}
          id={formFields.lastName}
          as={InputField}
        />
      </StyledFieldWrapper>

      <StyledFieldWrapper
        id={formFields.email}
        label="Email"
        required
        name={formFields.email}
      >
        <Field name={formFields.email} id={formFields.email} as={InputField} />
      </StyledFieldWrapper>

      <StyledFieldWrapper
        id={formFields.mobile}
        label="Mobile"
        required
        name={formFields.mobile}
      >
        <TelField name={formFields.mobile} id={formFields.mobile} />
      </StyledFieldWrapper>

      <FormActions isSubmitting={isSubmitting} submitHandler={submitHandler} />

      {renderConfirmationModal()}
    </Form>
  )
}

export default FormBody
