import React, { useEffect, useState } from 'react'

import { Box, Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { EXCLUDE_SCHEDULE_TYPE } from 'app/sm/properties/components/excluded_times/constants'
import { StyledSelectorsContainer } from 'app/sm/properties/components/excluded_times/partials/styled_components'
import TimePicker from 'app/sm/properties/components/excluded_times/partials/time_picker'
import { WeekDaySelectorArray } from 'app/sm/team_viewing_preference/container'

const PropertySchedule = ({ onEntryChange, initialState }) => {
  const [days, setDays] = useState([])
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')

  useEffect(() => {
    if (initialState) {
      setDays(initialState.days || [])
      setStartTime(initialState.startTime || '')
      setEndTime(initialState.endTime || '')
    }
  }, [])

  useEffect(() => {
    onEntryChange({
      type: EXCLUDE_SCHEDULE_TYPE,
      days,
      startTime,
      endTime,
    })
  }, [days, startTime, endTime])

  const isDaySelected = (dayName) => days.indexOf(dayName) !== -1

  const onDayClicked = (dayName) =>
    isDaySelected(dayName)
      ? setDays(days.filter((d) => d !== dayName))
      : setDays([...days, dayName])

  return (
    <StyledSelectorsContainer className="scheduleContainer">
      <TimePicker
        placeholder="start"
        time={startTime}
        onChangeHandler={setStartTime}
      />
      <span className="mt3 ml5 mr5">to</span>
      <TimePicker
        placeholder="end"
        time={endTime}
        onChangeHandler={setEndTime}
      />
      <Flex ml={`${theme.space[4]}px`} className="weekdaySelector">
        {WeekDaySelectorArray.map(({ name, text }) => {
          return (
            <span
              onClick={() => onDayClicked(name)}
              className={`weekday pl5 pr5 pt2 ${
                isDaySelected(name) && 'chosen'
              }`}
              key={name}
            >
              {text}
            </span>
          )
        })}
      </Flex>
    </StyledSelectorsContainer>
  )
}

export default PropertySchedule
