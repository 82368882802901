import React from 'react'

import $ from 'jquery'

import { times } from 'app/sm/helpers'

const starType = {
  full: 'icon-star full',
  halfEmpty: 'icon-star half',
  empty: 'icon-star empty',
}
const starWidth = 30

class ReviewStars extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rate: 0,
      rest: false,
      lastSetRating: {
        rate: 0,
        rest: false,
      },
    }
  }

  onStarCover(e, nth) {
    const { left } = $(e.target).offset()
    const clientX = e.clientX

    // This is invalidating of the current star
    if (left === Math.floor(clientX)) {
      this.setState({
        rate: nth === 0 ? 0 : nth - 1,
        rest: false,
      })
    }
    // This is half star
    else if (left + starWidth / 2 >= clientX) {
      this.setState({
        rate: nth === 0 ? 0 : nth - 1,
        rest: true,
      })
      // This is full star
    } else {
      this.setState({
        rate: nth,
        rest: false,
      })
    }
  }

  backToPreviousRating() {
    this.setState({
      rate: this.state.lastSetRating.rate,
      rest: this.state.lastSetRating.rest,
    })
  }

  classifyStar(nth, rate, rest) {
    if (nth <= rate) {
      return starType.full
    } else if (rest && nth === rate + 1) {
      return starType.halfEmpty
    }

    return starType.empty
  }

  dispatchRating() {
    const { onRateChange } = this.props
    const { rate, rest } = this.state

    // We dispatch a number
    // If the rest (the half star is present) we just add 0.5 to the rate

    onRateChange(rate + (rest ? 0.5 : 0))

    this.setState({
      lastSetRating: {
        rate: this.state.rate,
        rest: this.state.rest,
      },
    })
  }

  render() {
    const { rate, rest } = this.state

    return (
      <div className="sm-property-details">
        <div
          className="sm-property-details__section reviews"
          onMouseLeave={() => this.backToPreviousRating()}
        >
          <span className="pl0">
            {times(5).map((i) => (
              <i
                key={i}
                onMouseMove={(e) => this.onStarCover(e, i + 1)}
                onClick={() => this.dispatchRating()}
                className={`${this.classifyStar(i + 1, rate, rest)} big`}
                aria-hidden="true"
              />
            ))}
          </span>
        </div>
      </div>
    )
  }
}

export default ReviewStars
