import type { FC } from 'react'

import { Formik, FormikProps } from 'formik'

import {
  formFields,
  formValidation,
} from 'app/components/Ballot/Survey/form.utils'
import { FormBody } from 'app/components/Ballot/Survey/FormBody'
import { Box } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as textHelpers from 'app/utils/text/helpers'

interface IBallotSurveyProps {
  formRef: React.Ref<
    FormikProps<{
      [x: number]: string
    }>
  >
}

export const BallotSurvey: FC<IBallotSurveyProps> = ({ formRef }) => {
  const baseInitialValues = {
    [formFields.identifyAsAboriginalOrTorresStraitIslander]: '',
    [formFields.isOnVictorianHousingRegisterWaitingList]: '',
    [formFields.resideSocialOrPublicHousing]: '',
    [formFields.applicantGender]: '',
    [formFields.identifyAsCulturallyAndLinguisticallyDiverse]: '',
    [formFields.identifyAsPersonWithDisability]: '',
    [formFields.ageRange]: '',
  }

  return (
    <Box color="black">
      <Box mt={4} mb={7} lineHeight={theme.lineHeights['middle']}>
        {textHelpers.BALLOT_SURVEY_INTRODUCTION_TEXT}
      </Box>
      <Formik
        initialValues={baseInitialValues}
        onSubmit={() => {}}
        innerRef={formRef}
        validate={formValidation}
      >
        {() => <FormBody />}
      </Formik>
    </Box>
  )
}

export default BallotSurvey
