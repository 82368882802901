import humanIcon01 from 'app/assets/icons/human01.jpg'

export const Maintenance = [
  {
    priority: 'low',
    subject: 'Broken tiles on the bathroom floor',
    reportedByImage: humanIcon01,
    reportedByFirstname: 'Anne',
    reportedByLastname: 'Quincy',
    reportedByStatus: 'Co-Tenant',
    date: '24 July 2017',
    time: '09:30pm',
    assigneeImage: '',
    assigneeFirstname: '',
    assigneeLastname: '',
    assigneeStatus: '',
    ststusColor: 'yellow',
    statusTitle: 'Pending Assignee',
  },
  {
    priority: 'high',
    subject: 'Window hinges fell off',
    reportedByImage: humanIcon01,
    reportedByFirstname: 'Jenny',
    reportedByLastname: 'Quincy',
    reportedByStatus: 'Co-Tenant',
    date: '24 July 2017',
    time: '09:30pm',
    assigneeImage: humanIcon01,
    assigneeFirstname: 'James',
    assigneeLastname: 'Toney',
    assigneeStatus: 'Provider',
    ststusColor: 'blue',
    statusTitle: 'Pending Inspection',
  },
  {
    priority: 'medium',
    subject: 'Window hinges fell off',
    reportedByImage: humanIcon01,
    reportedByFirstname: 'Jenny',
    reportedByLastname: 'Quincy',
    reportedByStatus: 'Co-Tenant',
    date: '24 July 2017',
    time: '09:30pm',
    assigneeImage: humanIcon01,
    assigneeFirstname: 'James',
    assigneeLastname: 'Toney',
    assigneeStatus: 'Provider',
    ststusColor: 'green',
    statusTitle: 'Confirm Completion',
  },
  {
    priority: 'low',
    subject: 'Broken tiles on the bathroom floor',
    reportedByImage: humanIcon01,
    reportedByFirstname: 'Anne',
    reportedByLastname: 'Quincy',
    reportedByStatus: 'Co-Tenant',
    date: '24 July 2017',
    time: '09:30pm',
    assigneeImage: '',
    assigneeFirstname: '',
    assigneeLastname: '',
    assigneeStatus: '',
    ststusColor: 'yellow',
    statusTitle: 'Pending Assignee',
  },
]
