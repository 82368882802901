import React from 'react'

import { Link } from 'react-router-dom'

import Details from 'app/sm/property_lease_details/components/details'
import Documents from 'app/sm/property_lease_details/components/documents'
import Rent from 'app/sm/property_lease_details/components/rent'
import Tenants from 'app/sm/property_lease_details/components/tenants'
import Timeline from 'app/sm/property_lease_details/components/timeline'

function PropertyLeaseDetailsContainer() {
  return (
    <div className="sm-property-lease-details">
      <div className="section-title xs-row">
        <div className="left">
          <Link to={'/'} className="lh1">
            <h3 className="lh1">
              <i className="icon-arrow-left fs16 pt2 pr10 xs-ibm"></i>
              <span className="ibm lh1">Lease Details</span>
            </h3>
          </Link>
        </div>
        <div className="right">
          <div className="dropdown actions">
            <div className="dropdown-toggle" data-toggle="dropdown">
              <button className="btn btn-small-xs">
                <i className="icon-more left ibm"></i>
                <span className="ibm">Actions</span>
              </button>
            </div>
            <ul className="dropdown-menu dropdown-menu-right mt5">
              <li>
                <Link to={'/'}>Preview</Link>
              </li>
              <li>
                <Link to={'/'}>Edit property</Link>
              </li>
              <li className="divider"></li>
              <li>
                <Link to={'/'}>ToDo's</Link>
              </li>
              <li>
                <Link to={'/'}>Profit</Link>
              </li>
              <li>
                <Link to={'/'}>Maintenece</Link>
              </li>
              <li>
                <Link to={'/'}>Reports</Link>
              </li>
              <li>
                <Link to={'/'}>Applications</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="sm-property-lease-details-wrapper">
        <Tenants />
        <Details />
        <Rent />
        <Documents />
      </div>
      <Timeline />
    </div>
  )
}

export default PropertyLeaseDetailsContainer
