import * as React from 'react'

function SvgUserPlaceholder(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 12c0 5.523-4.477 10-10 10-5.522 0-10-4.477-10-10C2 6.478 6.478 2 12 2c5.523 0 10 4.478 10 10z"
        fill="#E6E6E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.597 18.503A9.978 9.978 0 0112 22a9.978 9.978 0 01-7.597-3.497c1.291-1.886 5.37-2.835 7.597-2.835 2.228 0 6.306.949 7.597 2.835zM11.295 6.077a.85.85 0 01.304-.076.886.886 0 01.79.405l.5.765c.726 0 2.667.774 2.667 3.164 0 2.129-1.805 3.827-3.943 3.598-2.133-.23-3.506-2.445-3.073-4.583.388-1.914 1.861-2.863 2.755-3.273z"
        fill="#CCC"
      />
    </svg>
  )
}

export default SvgUserPlaceholder
