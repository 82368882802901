import React from 'react'

import qs from 'qs'
import styled from 'styled-components'

import * as Display from 'app/components/display/display'
import * as onlistConstants from 'app/pages/teams/onlist/onlist.constants'

const EachStepContainer = styled.div`
  width: ${(props) =>
    props.isLastStep ? '0px' : `${100 / (props.totalSteps - 1)}%`};
  .count {
    display: inline-block;
    margin-top: 4px;
  }
  .stepper-border {
    border: 2px solid #f2f2f2;
    display: inline-block;
    width: calc(100% - 24px);
    margin-bottom: 4px;
  }
  .stepper-border.current-border {
    border: 2px solid #5a9bee;
  }
  .step-counter {
    display: inline-block;
    height: 24px;
    width: 24px;
    min-width: 24px;
    background: #f2f2f2;
    border-radius: 50%;
    color: #999999;
    text-align: center;
  }
  .step-counter.current {
    background: #5a9bee;
    color: #f2f2f2;
  }
`

const EachStep = ({ step, currentStep, totalSteps }) => {
  let isLastStep = false
  if (step === totalSteps - 1) {
    isLastStep = true
  }
  return (
    <EachStepContainer isLastStep={isLastStep} totalSteps={totalSteps}>
      <span className={`step-counter ${step <= currentStep ? 'current' : ''}`}>
        <span className="count">{step + 1}</span>
      </span>
      {!isLastStep && (
        <span
          className={`stepper-border ${
            step < currentStep ? 'current-border' : ''
          }`}
        ></span>
      )}
    </EachStepContainer>
  )
}

const StepperContainer = styled.div`
  width: calc(100% - 24px);
  display: flex;
  padding: 24px 0 60px 0;
  .width-0 {
    width: 0;
  }
`

class StepperComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 0,
    }
  }

  componentDidMount() {
    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const { step = '' } = curQueries
    if (step.length > 0) {
      this.setState({
        currentStep: Number(step),
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { callFunctionOnSpecificStepNum, callFunctionOnSpecificStep } =
      this.props
    if (
      prevState.currentStep !== this.state.currentStep &&
      this.state.currentStep === callFunctionOnSpecificStepNum
    ) {
      if (callFunctionOnSpecificStep) {
        callFunctionOnSpecificStep()
      }
    }
  }

  getPrimaryLabel = () => {
    const { useProvidedSettings, stepperSettings } = this.props
    const { currentStep } = this.state
    if (useProvidedSettings) {
      return stepperSettings[currentStep].primaryButtonLabel
    } else {
      return 'Next'
    }
  }

  primaryAction = () => {
    const { useProvidedSettings, stepperSettings } = this.props
    const { currentStep } = this.state
    if (
      useProvidedSettings ||
      stepperSettings[currentStep].forceUsePrimaryButtonAction
    ) {
      stepperSettings[currentStep].primaryButtonAction()
    } else {
      this.updateCurrentComponentPlusOne()
    }
  }

  updateCurrentComponentMinusOne = () => {
    const { currentStep } = this.state
    this.setState({
      currentStep: currentStep - 1,
    })
  }

  updateCurrentComponentPlusOne = () => {
    const { currentStep } = this.state
    this.setState({
      currentStep: currentStep + 1,
    })
  }

  jumpToComponent = (step) => {
    this.setState({
      currentStep: step,
    })
  }

  render() {
    const { currentStep } = this.state
    const { allComponentsToRender, redirectToPageAfterFinished } = this.props
    const totalComponentsToRender = allComponentsToRender.length
    const allSteps = []
    for (let stepNum = 0; stepNum < totalComponentsToRender; stepNum++) {
      allSteps.push(
        <EachStep
          step={stepNum}
          currentStep={currentStep}
          totalSteps={totalComponentsToRender}
        />,
      )
    }

    return (
      <Display.CenterContentContainer componentClass="width100">
        <StepperContainer>{allSteps}</StepperContainer>
        {allComponentsToRender[currentStep]}
        <Display.ModalButtonGroup
          containerClassName="application-apply-panel-container m0 p15 text-align-right"
          primaryLabel={
            currentStep === totalComponentsToRender - 1
              ? 'Finished'
              : this.getPrimaryLabel()
          }
          secondaryLabel="Previous"
          secondaryButtonType="tertiary"
          primaryAction={
            currentStep === totalComponentsToRender
              ? redirectToPageAfterFinished()
              : this.primaryAction
          }
          secondaryAction={this.updateCurrentComponentMinusOne}
          showPrimaryButton={currentStep !== totalComponentsToRender}
          showSecondaryButton={currentStep !== 0}
        />
      </Display.CenterContentContainer>
    )
  }
}

export default StepperComponent
