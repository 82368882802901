import React from 'react'

import $ from 'jquery'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import * as Display from 'app/components/display/display'
import * as Layout from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import DocumentList from 'app/shared_components/document_list/document_list'
import { ErrorMessage } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import {
  IDDocumentDocType,
  idDocumentTypeTextConverter,
  SupportingIDDocuments,
  SupportingIDDocumentsOptions,
  urlTo,
} from 'app/sm/helpers'

class OtherIdentityDoc extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      attachments: [],
      chosenDocType: '',
      error: '',
      uploadSpinner: false,
      payload: {
        chosenDocType: '',
      },
      attachmentError: '',
      displayInfoText: '',
      showUploader: false,
    }
  }

  componentDidMount() {
    this.callFetchAllSupportingIDDocs()
  }

  onAddDocument = (documentType) => {
    const { addIdentityDocAttachment } = this.props
    const { chosenDocType } = this.state
    if (chosenDocType) {
      this.setState({ uploadSpinner: true })
      addIdentityDocAttachment(documentType, '', false)
        .then(() => {
          this.callFetchAllSupportingIDDocs()
          this.setState({ uploadSpinner: false })
        })
        .catch((error) =>
          this.setState({ attachmentError: error, uploadSpinner: false }),
        )
    } else {
      this.setState({ attachmentError: 'Please choose a document type' })
    }
  }

  onChangeDocType = () => {
    const firstSelection = '0'
    return (data) => {
      const document = SupportingIDDocuments.find(
        (doc) => doc.name === SupportingIDDocumentsOptions[data.value],
      )
      if (data.value !== firstSelection) {
        this.setState({
          chosenDocType: data.value,
          payload: { chosenDocType: document.docType, attachmentError: '' },
          displayInfoText: document.infoText,
          showUploader: true,
        })
      }
    }
  }

  onDeleteDocument = (docID) => {
    const { deleteIdentityDocAttachment } = this.props
    deleteIdentityDocAttachment(0, docID, false)
      .then(() => {
        this.callFetchAllSupportingIDDocs()
      })
      .catch((error) => this.setState({ attachmentError: error }))
  }

  callFetchAllSupportingIDDocs = () => {
    const { getAllSupportingIDAttachments } = this.props
    getAllSupportingIDAttachments()
      .then((attachments) => {
        this.setState({ attachments: attachments.data })
      })
      .catch((error) => this.setState({ error }))
  }

  render() {
    const {
      chosenDocType,
      attachments,
      error,
      uploadSpinner,
      attachmentError,
      payload,
      showUploader,
    } = this.state
    return (
      <Display.CenterContentContainer componentClass="width100">
        <div>
          <Display.SectionHeader
            componentClass="pl0 ml0"
            textClass="pl0 ml0"
            text="Supporting identity documents"
            hasSeperator={false}
          />
          <Display.GreyBodyText>
            <div className="row">
              {SupportingIDDocuments.filter(
                (supportingDoc) =>
                  supportingDoc.docType !== IDDocumentDocType.SecondaryMedium,
              ).map((supportingDoc) => {
                return (
                  <div className="col-sm-4" key={supportingDoc.docType}>
                    <Display.GreySubheading
                      componentClass="mb0"
                      text={supportingDoc.exampleTitle}
                    />
                    <Display.GreyBodyText>
                      <ul className="supporting-doc-list">
                        {supportingDoc.examples.map((example, index) => {
                          return (
                            <li className="pt5" key={index}>
                              {example}
                            </li>
                          )
                        })}
                      </ul>
                    </Display.GreyBodyText>
                  </div>
                )
              })}
            </div>
          </Display.GreyBodyText>
          <Form.Dropdown
            placeholder="Document type"
            label="Click here"
            value={chosenDocType}
            options={SupportingIDDocumentsOptions}
            onChange={this.onChangeDocType()}
            componentClass="mb30"
          />
          <Display.GreyBodyText className="mb10">
            For more suitable documents visit our&nbsp;
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://help.snug.com/hc/en-us/articles/360000868955-100-Point-Check-for-Proof-of-Identity"
            >
              Help Centre
            </a>
          </Display.GreyBodyText>
          {showUploader && (
            <Layout.SnugTip
              text="Where possible, please include the front and back of your ID document (e.g. Driver Licence or ID card"
              componentClass="mt25"
            />
          )}
          <DocumentList
            documents={attachments}
            addDocument={this.onAddDocument}
            deleteDocument={this.onDeleteDocument}
            documentType={payload.chosenDocType}
            showDocumentType
            documentTypeTextConverter={idDocumentTypeTextConverter}
            spinner={uploadSpinner}
            responseText={attachmentError}
            showUploader={showUploader}
          />
        </div>
        <ErrorMessage error={error} />
        <Display.BottomContentContainer>
          <Display.CenterContentContainer componentClass="width100">
            <div className="submit-button-container">
              <BottomButton
                nextUrl={`${urlTo('identityDocument')}${
                  window.location.search
                }`}
                btnText="Continue"
                bottomButtonsClass="pt0 mt0 summary-bottom-button"
                nextBtnClass="summary-green-button-class"
                backButtonClass="summary-grey-button-class"
                nextButtonPositionClass="pr20 pl0"
                showArrow={false}
                customisedBackClick={() => history.goBack()}
              />
            </div>
          </Display.CenterContentContainer>
        </Display.BottomContentContainer>
      </Display.CenterContentContainer>
    )
  }
}
export default OtherIdentityDoc
