import React from 'react'

import { connect } from 'react-redux'

const NextText = ({ text, backUrl }) => {
  const isFromApplicationForm =
    backUrl.includes('sm/applications') && !backUrl.includes('/rentersummary')

  return (
    <div className="width100">
      {isFromApplicationForm && (
        <div className="gray-text text-center pt10 mobile-fontSize-13 w55p float-right apply-flow-button-description-mobile">
          {text}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ Shared }) => ({
  backUrl: Shared.backUrl,
})

export default connect(mapStateToProps, null)(NextText)
