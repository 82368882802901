import { connect } from 'react-redux'

import {
  fetchProgressApplication,
  markProgressApplicationAsLeased,
  updateProgressApplication,
} from 'app/sm/progress_application/action'
import ProgressApplicationContainer from 'app/sm/progress_application/container'
import { withdrawOfferForApplication } from 'app/sm/renter_applications/renter_applications_actions'

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  fetchProgressApplication: (applicationId) =>
    dispatch(fetchProgressApplication(applicationId)),
  updateProgressApplication: (applicationId, applicationInfo) =>
    dispatch(updateProgressApplication(applicationId, applicationInfo)),
  withdrawOfferForApplication: (applicationId) =>
    dispatch(withdrawOfferForApplication(applicationId)),
  markProgressApplicationAsLeased: (
    applicationId,
    applicationInfo,
    markAsLeasedOptions,
  ) =>
    dispatch(
      markProgressApplicationAsLeased(
        applicationId,
        applicationInfo,
        markAsLeasedOptions,
      ),
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgressApplicationContainer)
