import { connect } from 'react-redux'

import { fetchRentalHistoryRatingCategories } from 'app/bond_cover/bond_cover_actions'
import RenterApplicationPDF from 'app/match/applicationReportPDF/container'
import {
  fetchCompleteness,
  fetchTeamSettingInfo,
  retrieveReputationPreference,
} from 'app/sm/rental_reputation/actions'
import {
  fetchApplyAnywhereManagerSummaryWithSecret,
  fetchPMSupportAttachments,
  fetchRentAndEmploymentSummary,
  fetchRenterApplication,
} from 'app/sm/renter_applications/renter_applications_actions'

const mapStateToProps = ({
  RenterApplications,
  RentalReputation,
  Profile,
  session,
  bondCover,
}) => ({
  application: RenterApplications.application,
  isRequestInfoDone: RenterApplications.isRequestInfoDone,
  error: RenterApplications.error,
  completeness: RentalReputation.completeness,
  spinner: RenterApplications.spinner,
  reputationPreference: RentalReputation.preference,
  profile: Profile.profile,
  currentTeam: session.currentTeam,
  teams: session.teams,
  currentUser: session.currentUser,
  ratingCategories: RenterApplications.ratingCategories,
  versionsRatingCategories: bondCover.versionsRatingCategories,
})

const mapDispatchToProps = (dispatch) => ({
  fetchRenterApplication: (applicationId, applicantId, setting) =>
    dispatch(fetchRenterApplication(applicationId, applicantId, setting)),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
  fetchRentalHistoryRatingCategories: () =>
    dispatch(fetchRentalHistoryRatingCategories()),
  fetchPMSupportAttachments: (applicantID) =>
    dispatch(fetchPMSupportAttachments(applicantID)),

  retrieveReputationPreference: () => dispatch(retrieveReputationPreference()),
  fetchApplyAnywhereManagerSummaryWithSecret: (
    applicationId,
    secret,
    applicantId,
  ) =>
    dispatch(
      fetchApplyAnywhereManagerSummaryWithSecret(
        applicationId,
        secret,
        applicantId,
      ),
    ),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
  fetchRentAndEmploymentSummary: (applicationId) =>
    dispatch(fetchRentAndEmploymentSummary(applicationId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RenterApplicationPDF)
