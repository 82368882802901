import React, { useState } from 'react'

import moment from 'moment'
import DateTime from 'react-datetime'

import * as snugNotifier from 'app/services/snugNotifier'
import ViewingScheduleZoneDropdown, {
  viewingScheduleZoneDropdownValues,
} from 'app/shared_components/viewing_schedule_zone_dropdown'
import { FULL_HOUR_AM_PM } from 'app/utils/datetime/helpers'

import 'app/sm/team_viewing_preference/components/viewingItem/style.scss'

const isModeView = (mode) => mode === 'view'
const isModeCreate = (mode) => mode === 'create'
const isModeEdit = (mode) => mode === 'edit'

const ViewingItemContainer = ({
  onStartDatePickerChange,
  onEndDatePickerChange,
  weekDaySelectorArray = [],
  onWeekdayAddedClick,
  timeWindowAdder,
  onButtonClicked,
  mode = 'view',
  index: viewingIndex,
  extraStyle = '',
  onStartDateFocus,
  onEndDateFocus,
  updateViewingScheduleDropdown,
  disabled,
}) => {
  const startTimeValue = timeWindowAdder.StartTime
  const endTimeValue = timeWindowAdder.EndTime

  const [startTime, setStartTime] = useState(timeWindowAdder.StartTime)
  const [endTime, setEndTime] = useState(timeWindowAdder.EndTime)

  const onChangeTime = (type, time) => {
    if (typeof time === 'string') {
      return
    }
    validateAndChangeTimeInput(time, type)
  }

  const onBlurTime = (type, time) => {
    if (type === 'start') {
      setStartTime(time)
    } else {
      setEndTime(time)
    }
    validateAndChangeTimeInput(time, type)
    if (
      type === 'end' &&
      moment(time).isBefore(moment(startTimeValue, FULL_HOUR_AM_PM))
    ) {
      snugNotifier.error('End day needs to be after start day')
    }
  }

  const validateAndChangeTimeInput = (time, type) => {
    const valueChanged = moment(time, FULL_HOUR_AM_PM)
    if (type === 'start') {
      onStartDatePickerChange(valueChanged)
    }
    if (type === 'end') {
      onEndDatePickerChange(valueChanged)
    }
  }

  return (
    <div
      className={`display-flex mt30 align-items-center flex-direction-column-mobile  ${extraStyle}`}
    >
      <div
        className={`display-flex align-items-center ${
          isModeView(mode) && 'disabled-link'
        }`}
      >
        <DateTime
          key={startTime}
          dateFormat={false}
          timeFormat={FULL_HOUR_AM_PM}
          inputProps={{
            placeholder: 'start',
            className: `timePickerStyle ${extraStyle}`,
            disabled: disabled,
          }}
          onBlur={onBlurTime.bind(null, 'start')}
          onChange={onChangeTime.bind(null, 'start')}
          onFocus={onStartDateFocus}
          value={
            startTimeValue &&
            moment(startTimeValue, FULL_HOUR_AM_PM).format(FULL_HOUR_AM_PM)
          }
        />
        <span className="mt3 ml5 mr5">to</span>
        <DateTime
          key={endTime}
          dateFormat={false}
          timeFormat={FULL_HOUR_AM_PM}
          inputProps={{
            placeholder: 'end',
            className: `timePickerStyle ${extraStyle}`,
            disabled: disabled,
          }}
          onBlur={onBlurTime.bind(null, 'end')}
          onChange={onChangeTime.bind(null, 'end')}
          onFocus={onEndDateFocus}
          value={
            endTimeValue &&
            moment(endTimeValue, FULL_HOUR_AM_PM).format(FULL_HOUR_AM_PM)
          }
        />
        <div className="display-flex ml10 weekdaySelector">
          {weekDaySelectorArray.map((day, index) => {
            return (
              <span
                onClick={() => onWeekdayAddedClick(day.name, viewingIndex)}
                className={`weekday pl5 pr5 ${
                  timeWindowAdder[day.name] ? 'chosen' : 'not-chosen'
                } ${extraStyle}`}
                key={index}
              >
                {day.text}
              </span>
            )
          })}
        </div>
      </div>
      <div className="display-flex align-items-center ml10">
        {(isModeCreate(mode) || isModeEdit(mode)) && (
          <ViewingScheduleZoneDropdown
            selectedDropdownValue={timeWindowAdder.ViewingScheduleZone}
            updateViewingScheduleDropdown={(data) => {
              updateViewingScheduleDropdown(viewingIndex, data.value)
            }}
          />
        )}
        {isModeView(mode) && (
          <span className="min-width100p">
            {timeWindowAdder.ViewingScheduleZone >= 0
              ? viewingScheduleZoneDropdownValues[
                  timeWindowAdder.ViewingScheduleZone
                ].name
              : viewingScheduleZoneDropdownValues[0].name}
          </span>
        )}
        {isModeCreate(mode) && (
          <div
            className={`link-button blue-link-style ml40 ${extraStyle}`}
            onClick={() => onButtonClicked('save')}
          >
            Save
          </div>
        )}
        {isModeCreate(mode) && (
          <div
            className={`link-button blue-link-style ml40 ${extraStyle}`}
            onClick={() => onButtonClicked('cancel')}
          >
            Cancel
          </div>
        )}
        {isModeView(mode) && !disabled && (
          <div
            className={`link-button blue-link-style ml40 ${extraStyle}`}
            onClick={() => onButtonClicked('edit', viewingIndex)}
          >
            Edit
          </div>
        )}
        {isModeView(mode) && !disabled && (
          <div
            className={`link-button blue-link-style ml40 ${extraStyle}`}
            onClick={() => onButtonClicked('delete', viewingIndex)}
          >
            Delete
          </div>
        )}
        {isModeEdit(mode) && !disabled && (
          <div
            className={`link-button blue-link-style ml40 ${extraStyle}`}
            onClick={() => onButtonClicked('save', viewingIndex)}
          >
            Save
          </div>
        )}
        {isModeEdit(mode) && (
          <div
            className={`link-button blue-link-style ml40 ${extraStyle}`}
            onClick={() => onButtonClicked('cancel', viewingIndex)}
          >
            Cancel
          </div>
        )}
      </div>
    </div>
  )
}

export default ViewingItemContainer
