import { api } from 'app/utils/api/helpers'

const mapMessagesFilters = ({ managers, properties, ...otherProps }) => {
  return {
    ...(!!managers && { manager: managers }),
    ...(!!properties && { propertyGUID: properties }),
    ...otherProps,
  }
}

export const sendBulkMessage = (teamId, bulkMessagePayload) => {
  const { messages_filters, ...otherPayload } = bulkMessagePayload
  const mappedPayload = {
    ...otherPayload,
    ...(!!messages_filters && {
      messages_filters: mapMessagesFilters(messages_filters),
    }),
  }

  return api.post(`sm/teams/${teamId}/messages/replies`, mappedPayload)
}

export const uploadAttachments = (teamId, file) => {
  const formData = new FormData()
  formData.append('file', file)
  return api.post(`sm/teams/${teamId}/messages/replies/attachments`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const deleteAttachments = (teamId, attachmentIds = []) => {
  const idsParams = attachmentIds.map((id) => `guid=${id}`).join('&')
  return api.delete(
    `sm/teams/${teamId}/messages/replies/attachments?${idsParams}`,
  )
}
