import React from 'react'

import moment from 'moment'

import iconDocumentDoc from 'app/assets/icons/icon-document-doc.svg'
import iconDocumentImg from 'app/assets/icons/icon-document-img.svg'
import iconDocumentPdf from 'app/assets/icons/icon-document-pdf.svg'
import { Box } from 'app/components/design-system-components'
import * as snugNotifier from 'app/services/snugNotifier'
import DocumentListText from 'app/shared_components/document_list/component/document_list_text'
import {
  invalidDocExtensionExists,
  invalidDocSizeExists,
} from 'app/shared_components/helpers'
import Spinner from 'app/sm/common/spinner'
import { setFileIcon } from 'app/sm/helpers'
import * as stringHelpers from 'app/utils/strings/helpers'
import { errorMessageForInvalidFileTypeDoc } from 'app/utils/text/helpers'

class PetReference extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      validationError: '',
    }
  }

  onDocumentsUpdate = (event) => {
    const { uploadPetReference, petGuidID, editGuidID, editable, pet } =
      this.props
    const guid = editable ? editGuidID : petGuidID

    const attachments = document.getElementById('pet-attachments')
    const attachmentsArr = Object.keys(attachments.files).map(
      (k) => attachments.files[k],
    )
    const InvalidDocExtension = invalidDocExtensionExists(attachmentsArr, 'doc')
    const InvalidDocSize = invalidDocSizeExists(attachmentsArr)
    let isAttachmentExist = false
    if (pet && pet.petReferences) {
      isAttachmentExist =
        pet &&
        pet.petReferences &&
        pet.petReferences.some(
          (attach) => attachmentsArr[0].name === attach.name,
        )
    }
    if (isAttachmentExist) {
      if (!window.confirm(stringHelpers.isAttachmentExistMessage)) {
        event.target.value = null
        return
      }
    }

    if (InvalidDocExtension) {
      this.setState({
        validationError: errorMessageForInvalidFileTypeDoc(
          InvalidDocExtension?.name,
        ),
      })
    } else if (InvalidDocSize) {
      this.setState({
        validationError: `The file size of ${InvalidDocSize.name} can not exceed 10mb`,
      })
    } else {
      this.setState({ validationError: '' })
      uploadPetReference(guid)
    }
    event.target.value = null
  }

  render() {
    const { removePetReference, spinner, pet, responseText } = this.props

    const { validationError } = this.state
    const documentIcons = {
      doc: iconDocumentDoc,
      img: iconDocumentImg,
      pdf: iconDocumentPdf,
    }

    return (
      <div>
        <div className="sm-file-upload single">
          <form id="property-photos-upload">
            <input
              multiple
              id="pet-attachments"
              type="file"
              onChange={(event) => this.onDocumentsUpdate(event)}
              onClick={(event) => (event.target.value = null)}
            />
            <label htmlFor="photos">
              <div>
                <i className="icon-image" />
                <b>Upload manually,</b>
                <span>or just drag and drop here</span>
              </div>
              <Box>
                <DocumentListText />
              </Box>
            </label>
          </form>
        </div>
        <table className="style3">
          {(pet.petReferences || []).length !== 0 ? (
            <thead>
              <tr>
                <td colSpan="2">Name</td>
                <td>Date</td>
                <td />
                <td />
              </tr>
            </thead>
          ) : (
            <thead />
          )}
          <tbody>
            {(pet.petReferences || []).length !== 0 &&
              pet.petReferences.map((reference) => {
                const fileExtension = reference.name.substring(
                  reference.name.lastIndexOf('.') + 1,
                  reference.name.length,
                )
                const fileIconName = setFileIcon(fileExtension)
                const date = moment(reference.CreatedAt).format('DD MMM YYYY')

                return (
                  <tr key={reference.guidID}>
                    <td className="pr0 w1p">
                      <img
                        alt=""
                        src={documentIcons[fileIconName]}
                        height="25"
                      />
                    </td>
                    <td>
                      <a
                        className="gray-dark-color fw400"
                        href={reference.URL}
                        download={reference.name}
                      >
                        {reference.name}
                      </a>
                    </td>
                    <td className="fw400">on {date}</td>
                    <td>{/* placeholder for other element */}</td>
                    <td className="actionButtonWrapper fw400">
                      <button
                        onClick={() =>
                          removePetReference(pet.guidID, reference.guidID)
                        }
                        className="remove-btn"
                      >
                        <i className="icon-remove" aria-hidden="true" />
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        <div className={responseText ? 'alert alert-danger' : 'hide-alert'}>
          <div>{responseText}</div>
        </div>
        <div className={validationError ? 'alert alert-danger' : 'hide-alert'}>
          <div>{validationError}</div>
        </div>
        <div>{spinner && <Spinner />}</div>
      </div>
    )
  }
}

export default PetReference
