import React from 'react'

import moment from 'moment'

import { Box } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import { Text } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import { viewingStandardDisplayTimeFormat } from 'app/sm/helpers'
import {
  formatTime,
  HALF_DAY_HALF_MONTH_TIME_SMALL_AM_PM,
} from 'app/utils/datetime/helpers'

class ViewingRegistrantCommentSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      externalComment: '',
    }
  }

  componentDidMount() {
    const { registrant } = this.props
    const { externalNotes } = registrant
    this.setState({
      externalNotes,
    })
  }

  render() {
    const { hasComment, registrant, onClickViewEdit, renter } = this.props
    const { comment, externalNote } = registrant || {}
    const isDisplayPrivateAndExternalNoteTitle =
      (comment && comment.length > 0) ||
      (externalNote && externalNote.length > 0)
    const isDisplayAddPrivateNoteLink = comment && comment.length > 0
    const isDisplayAddExternalNoteLink = externalNote && externalNote.length > 0
    const truncate = (input, character) => {
      return input.length > character
        ? `${input.substring(0, character)}... `
        : input
    }
    return (
      <div className="comment-wrap width100 display-flex ml30-desktop display-flex flex-direction-column">
        {!isDisplayAddPrivateNoteLink && !isDisplayAddExternalNoteLink && (
          <span
            className="blue-link-style font-size-14 ml5 mt15 width100 text-align-left"
            onClick={() => onClickViewEdit(renter)}
          >
            Add Notes
          </span>
        )}
        <div className="width100 mobile-mt10">
          {isDisplayPrivateAndExternalNoteTitle && (
            <div className="font-weight600 font-size-16 mb5">
              Note (private)
            </div>
          )}
          {!isDisplayAddPrivateNoteLink && isDisplayAddExternalNoteLink && (
            <span
              className="blue-link-style font-size-14 mt15 width100 text-align-left"
              onClick={() => onClickViewEdit(renter)}
            >
              Add
            </span>
          )}
          {comment && (
            <div>
              <span className="comment lh18px">{truncate(comment, 150)}</span>
              <span
                className="blue-link-style font-size-14 ml5 mt15 width100 text-align-left"
                onClick={() => onClickViewEdit(renter)}
              >
                {hasComment ? 'edit' : ''}
              </span>
            </div>
          )}
        </div>
        <div className="width100 mobile-mt10 pt8">
          {isDisplayPrivateAndExternalNoteTitle && (
            <div className="font-weight600 font-size-16 mb5 pt8">
              Note (viewing report)
            </div>
          )}

          {isDisplayAddPrivateNoteLink && !isDisplayAddExternalNoteLink && (
            <span
              className="blue-link-style font-size-14 mt15 width100 text-align-left"
              onClick={() => onClickViewEdit(renter)}
            >
              Add
            </span>
          )}
          {externalNote && (
            <div>
              <span className="comment lh18px">
                {truncate(externalNote, 150)}
              </span>
              <span
                className="blue-link-style font-size-14 ml5 mt15 width100 text-align-left"
                onClick={() => onClickViewEdit(renter)}
              >
                {externalNote ? 'edit' : ''}
              </span>
            </div>
          )}
        </div>
      </div>
    )
  }
}

class IndividualInviteToApplySection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isInviteSent: false,
      inviteSentDate: '',
    }
  }
  componentDidMount() {
    const { registrant } = this.props
    const { sentApplyNotification, sentApplyNotificationDate } = registrant
    this.setState({
      isInviteSent: sentApplyNotification,
      inviteSentDate: sentApplyNotificationDate,
    })
  }

  componentDidUpdate(prevProps) {
    const { registrant } = this.props
    const { sentApplyNotification, sentApplyNotificationDate } = registrant
    if (sentApplyNotification !== prevProps.registrant.sentApplyNotification) {
      this.setState({
        isInviteSent: sentApplyNotification,
        inviteSentDate: sentApplyNotificationDate,
      })
    }
  }

  sendInviteToApply = () => {
    const {
      sendInviteToApplyToRegistrant,
      registrantFirstName = '',
      registrant,
    } = this.props
    const { checkIn } = registrant
    const { isInviteSent } = this.state
    if (!checkIn) {
      snugNotifier.error(
        `Please Check-in attendee then Save and Invite to Apply`,
      )
      return
    }
    if (
      window.confirm(
        `Email and SMS invite ${registrantFirstName} ${
          isInviteSent ? 'again' : ''
        }?`,
      )
    ) {
      sendInviteToApplyToRegistrant(registrant, registrantFirstName).then(
        () => {
          this.setState({ isInviteSent: true, inviteSentDate: moment() })
          snugNotifier.success('Email and SMS invite sent to attendee.')
        },
      )
    }
  }

  render() {
    const { isInviteSent, inviteSentDate } = this.state
    const inviteSentDateFormatted = moment(inviteSentDate)
      .local()
      .format(viewingStandardDisplayTimeFormat)
    return (
      <div>
        <div
          className="blue-link-style font-size-14 mt15 width100 text-align-left"
          onClick={() => {
            this.sendInviteToApply()
          }}
        >
          Invite to apply
        </div>
        <div className="font-size-14 mt5">
          {isInviteSent
            ? `Invited ${inviteSentDate ? `: ${inviteSentDateFormatted}` : ''}`
            : 'Not invited'}
        </div>
      </div>
    )
  }
}
export default IndividualInviteToApplySection
export { ViewingRegistrantCommentSection }

export const RegistrantApplied = ({ appliedAt }) => {
  const formattedDate = formatTime(
    appliedAt,
    HALF_DAY_HALF_MONTH_TIME_SMALL_AM_PM,
  )
  return (
    <Box mt={3}>
      <Badge
        variant="blue"
        pb={0}
        text={
          <Box
            style={{ textAlign: 'center' }}
            fontSize={theme.fontSizes[4] + 'px'}
            pb={theme.space[1] + 'px'}
          >
            Applied
            <br />
            <Text
              fontWeight={6}
              color={theme.colors.blue}
              mb={0}
              mt={1}
              fontSize={2}
            >
              {formattedDate}
            </Text>
          </Box>
        }
      />
    </Box>
  )
}
