import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

const propTypes = {
  snug_match_score: PropTypes.number,
}

const Holder = styled.div`
  float: right;
  margin: 4px 0 0 -10px;
  width: 50px;
  position: relative;

  svg {
    width: 100%;
    height: auto;
  }
`

const Score = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  line-height: 50px;
  text-align: center;
`

const Bar = styled.circle`
  fill: transparent;
  stroke-width: 8;
  stroke-dasharray: 251;
  transform-origin: center center;
  transform: rotate(90deg);
`

function Chart({ snug_match_score }) {
  const color = snug_match_score > 79 ? 'red' : 'green'
  const r = 40
  const l = Math.PI * 2 * r
  const offset = l * (1 - snug_match_score / 100)

  return (
    <Holder>
      <svg
        viewBox="0 0 100 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Bar
          cx="50"
          cy="50"
          r={r}
          fill="none"
          strokeDashoffset={offset}
          stroke={color}
        />
      </svg>
      <Score>{snug_match_score}</Score>
    </Holder>
  )
}

Chart.propTypes = propTypes

export default Chart
