import { connect } from 'react-redux'

import NewPasswordForm from 'app/session/login_components/new_password_form'
import { clearError, newPassword } from 'app/session/session_actions'

const mapStateToProps = ({ session }) => ({
  error: session.error,
  attempts: session.attempts,
})

const mapDispatchToProps = (dispatch) => ({
  newPassword: (data) => newPassword(data),
  clearError: () => clearError(),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordForm)
