import styled from 'styled-components'

import shieldTickGreen from 'app/assets/icons/green-line-fill-tick.svg'
import shieldTickGrey from 'app/assets/icons/grey-line-fill-tick.svg'
import { Box, Flex, Spinner } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  HEADER_HELPER,
  Started,
  TeamCreated,
  TeamInvitesEmailed,
  TeamPropertiesImported,
  VIEWING_STATUS,
} from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_const'
import { PageText } from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_container'
import type { RegisteringTeamStatus } from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_type'

const CreationStepContainer = styled(Flex)`
  align-items: center;
  margin-bottom: ${theme.dividedSpace[6]}px;
`

const StepText = styled(Text)`
  font-size: ${({ theme }) => `${theme.fontSizes[6]}px`};
  font-weight: ${({ theme }) => `${theme.fontWeights[6]}`};
  color: ${theme.colors.gray500};
  align-self: center;
  margin-bottom: ${theme.dividedSpace[0]}px;
  margin-left: ${theme.dividedSpace[2]}px;
`

const Icon = styled.img`
  height: ${theme.bannerHeightInPx}px;
`

const LoadingBox = styled(Box)`
  margin-top: ${({ theme }) => `${theme.space[3]}px`};
  margin-bottom: ${({ theme }) => `${theme.space[3]}px`};
  padding: ${({ theme }) => `0 ${theme.space[3]}px 0 ${theme.space[3]}px`};
`

const CreationStep = ({
  registeringStatus,
}: {
  registeringStatus: RegisteringTeamStatus
}) => {
  const renderStatus = (label: string) => {
    switch (label) {
      case 'Team invites emailed':
        if (
          registeringStatus === Started ||
          registeringStatus === TeamCreated
        ) {
          return <Icon src={shieldTickGrey} alt="submitted" />
        }
        if (registeringStatus === TeamPropertiesImported) {
          return (
            <LoadingBox>
              <Spinner
                height={`${theme.streamlineSpinnerWidthHeightPx}px`}
                width={`${theme.streamlineSpinnerWidthHeightPx}px`}
              />
            </LoadingBox>
          )
        }
        if (registeringStatus === TeamInvitesEmailed) {
          return <Icon src={shieldTickGreen} alt="submitted" />
        }
        return <Icon src={shieldTickGreen} alt="submitted" />
      case 'Properties imported':
        if (registeringStatus === Started) {
          return <Icon src={shieldTickGrey} alt="submitted" />
        }
        if (registeringStatus === TeamCreated) {
          return (
            <LoadingBox>
              <Spinner
                height={`${theme.streamlineSpinnerWidthHeightPx}px`}
                width={`${theme.streamlineSpinnerWidthHeightPx}px`}
              />
            </LoadingBox>
          )
        }
        if (
          registeringStatus === TeamPropertiesImported ||
          registeringStatus === TeamInvitesEmailed
        ) {
          return <Icon src={shieldTickGreen} alt="submitted" />
        }
        return <Icon src={shieldTickGreen} alt="submitted" />
      case 'Creating team':
        if (registeringStatus === Started) {
          return (
            <LoadingBox>
              <Spinner
                height={`${theme.streamlineSpinnerWidthHeightPx}px`}
                width={`${theme.streamlineSpinnerWidthHeightPx}px`}
              />
            </LoadingBox>
          )
        }
        if (
          registeringStatus === TeamCreated ||
          registeringStatus === TeamPropertiesImported ||
          registeringStatus === TeamInvitesEmailed
        ) {
          return <Icon src={shieldTickGreen} alt="submitted" />
        }
        return <Icon src={shieldTickGreen} alt="submitted" />
      default:
        return <Icon src={shieldTickGrey} alt="submitted" />
    }
  }

  const renderHelperText = () => {
    return HEADER_HELPER[registeringStatus]
  }

  return (
    <>
      <PageText>{renderHelperText()}</PageText>
      <CreationStepContainer>
        {renderStatus('Creating team')}
        <StepText>{VIEWING_STATUS[TeamCreated]}</StepText>
      </CreationStepContainer>
      <CreationStepContainer>
        {renderStatus('Properties imported')}
        <StepText>{VIEWING_STATUS[TeamPropertiesImported]}</StepText>
      </CreationStepContainer>
      <CreationStepContainer>
        {renderStatus('Team invites emailed')}
        <StepText>{VIEWING_STATUS[TeamInvitesEmailed]}</StepText>
      </CreationStepContainer>
      <PageText>
        We’re all done! Check your inbox to complete your user registration.
      </PageText>
    </>
  )
}

export default CreationStep
