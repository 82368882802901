import React from 'react'

import { history } from 'app/shared_components/router'

const ProfilePagesBottomButtons = ({
  clickNextFunction,
  clickNextText,
  clicked,
  clickedBackFunction,
  buttonClass,
}) => {
  return (
    <div className="row pt25 mobile-form-button">
      <div className="col-sm-5 pb5 tablet-only">
        <button
          onClick={() =>
            clickedBackFunction ? clickedBackFunction() : history.goBack()
          }
          className="btn btn-transparent btn-left xs-text-center wa"
        >
          <i className="icon-arrow-left left" />
          <span>Back</span>
        </button>
      </div>
      <div className="col-sm-7 pb5">
        <button
          className={`btn ${buttonClass || ''}`}
          onClick={clickNextFunction}
        >
          {clickNextText}
          <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
        </button>
      </div>
    </div>
  )
}

export default ProfilePagesBottomButtons
