import * as API from 'app/bond_cover/agency/dashboard/agency_dashboard_access'

export const RECEIVE_AGENCY_REQUESTS = 'RECEIVE_AGENCY_REQUESTS'
export const RECEIVE_RESPONSE_TEXT = 'ADB_RECEIVE_RESPONSE_TEXT'
export const TOGGLE_SPINNER = 'ADB_TOGGLE_SPINNER'

export const toggleSpinner = () => ({ type: TOGGLE_SPINNER })

export const fetchAgencyRequestsSuccess = (success) => ({
  type: RECEIVE_AGENCY_REQUESTS,
  bondCovers: success,
})

export const searchAgencyRequestsSuccess = (success) => ({
  type: RECEIVE_AGENCY_REQUESTS,
  bondCovers: success,
})

export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})

export function fetchAgencyRequests(searchType, manager, agencyID, cursor) {
  return (dispatch) => {
    dispatch(toggleSpinner())
    return API.fetchAgencyRequests(searchType, manager, agencyID, cursor).then(
      (res) => {
        if (res.ok) {
          dispatch(fetchAgencyRequestsSuccess(res))
          dispatch(receiveResponseText(''))
          return dispatch(toggleSpinner())
        } else {
          const { responseText } = res
          return responseText.then((error) => {
            dispatch(receiveResponseText(error))
            return dispatch(toggleSpinner())
          })
        }
      },
    )
  }
}

export function searchAgencyRequests(
  filterType,
  manager,
  addressSearchText,
  cursor,
) {
  return (dispatch, getState) => {
    dispatch(toggleSpinner())
    const { currentTeam } = getState().session
    const agencyID = currentTeam && currentTeam.guid
    return API.searchAgencyRequests(
      filterType,
      manager,
      addressSearchText,
      agencyID,
      cursor,
    ).then((res) => {
      if (res.ok) {
        dispatch(fetchAgencyRequestsSuccess(res))
        dispatch(receiveResponseText(''))
        return dispatch(toggleSpinner())
      } else {
        const { responseText } = res
        return responseText.then((error) => {
          dispatch(receiveResponseText(error))
          return dispatch(toggleSpinner())
        })
      }
    })
  }
}
