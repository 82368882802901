import React from 'react'

import { connect } from 'react-redux'

import {
  Alert,
  Box,
  LoadingSection,
} from 'app/components/design-system-components'
import { integrationBrands } from 'app/constants/integrations.constants'
import { IntegrationStatusBlock } from 'app/Integrations/components/StatusBlock'
import * as snugNotifier from 'app/services/snugNotifier'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { loadingStatesIds } from 'app/utils/loading-states'

export const useIntegrationStatus = (currentTeam, brand, statusGetterAPI) => {
  const {
    state: status,
    setState: setStatus,
    loadingStates: statusLoadingStates,
    loadingStatesHelpers: statusLoadingStatesHelpers,
  } = useStateWithLoading(null)

  const loadIntegrationStatus = () => {
    statusLoadingStatesHelpers.startLoading()
    return statusGetterAPI(currentTeam.guid, brand)
      .then((statusInfo) => {
        setStatus(statusInfo)
        statusLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => {
        statusLoadingStatesHelpers.setError(err)
        return Promise.reject(err)
      })
  }

  return {
    integrationStatusInfo: status,
    loadIntegrationStatus,
    integrationStatusLoadingStates: statusLoadingStates,
  }
}

export const useActivateIntegration = (
  currentTeam,
  brand,
  activationAPI,
  successMsg,
) => {
  const {
    loadingStates: activationLoadingStates,
    loadingStatesHelpers: activationLoadingStatesHelpers,
  } = useLoadingStates()

  const activate = (payload) => {
    activationLoadingStatesHelpers.startLoading()
    return activationAPI(currentTeam.guid, brand, payload)
      .then(() => {
        activationLoadingStatesHelpers.markDoneSuccessfully()
        !!successMsg && snugNotifier.success(successMsg)
      })
      .catch((err) => {
        activationLoadingStatesHelpers.setError(err)
        return Promise.reject(err)
      })
  }

  return {
    activateIntegration: activate,
    activateIntegrationLoadingStates: activationLoadingStates,
    activateIntegrationLoadingStatesHelpers: activationLoadingStatesHelpers,
  }
}

export const useDeactivateIntegration = (
  currentTeam,
  brand,
  deactivationAPI,
  successMsg,
) => {
  const {
    loadingStates: deactivateLoadingStates,
    loadingStatesHelpers: deactivateLoadingStatesHelpers,
  } = useLoadingStates()

  const deactivate = () => {
    deactivateLoadingStatesHelpers.startLoading()
    return deactivationAPI(currentTeam.guid, brand)
      .then(() => {
        deactivateLoadingStatesHelpers.markDoneSuccessfully()
        successMsg && snugNotifier.success(successMsg)
      })
      .catch((err) => {
        deactivateLoadingStatesHelpers.setError(err)
        return Promise.reject(err)
      })
  }

  return {
    deactivateIntegration: deactivate,
    deactivateIntegrationLoadingStates: deactivateLoadingStates,
  }
}

const IntegrationActivity = ({
  integrationBrand,
  logo,
  statusProps,
  activateProps,
  deactivateProps,
  activationInstructions,
  brandsList = integrationBrands,
}) => {
  const brand =
    Object.values(brandsList).find(({ id }) => id === integrationBrand) || {}

  const { manualActivationAllowed, manualDeactivationAllowed } = brand || {}

  const { statusInfo, statusLoadingStates, reloadStatusInfo } = statusProps
  const { status, lastActedAt, lastActedBy } = statusInfo || {}

  const { activationFn, activateLoadingStates } = activateProps || {}
  const { deactivationFn, deactivateLoadingStates } = deactivateProps || {}

  const renderErrorMsg = (loadingStates) => (
    <Box>
      {loadingStates?.state === loadingStatesIds.ERROR && (
        <Alert variant="warningWithBg" mb={4}>
          {loadingStates.error.message}
        </Alert>
      )}
    </Box>
  )

  const activationErr = renderErrorMsg(activateLoadingStates)
  const deactivationErr = renderErrorMsg(deactivateLoadingStates)

  return (
    <>
      <LoadingSection
        actionHandler={() => reloadStatusInfo()}
        loadingState={statusLoadingStates}
      >
        <IntegrationStatusBlock
          onActivation={manualActivationAllowed && activationFn}
          activateLoadingStates={activateLoadingStates}
          onDeactivation={manualDeactivationAllowed && deactivationFn}
          deactivateLoadingStates={deactivateLoadingStates}
          status={status}
          actedAt={lastActedAt}
          actedBy={lastActedBy}
          activationInstructions={activationInstructions}
          logo={logo}
          brandId={integrationBrand}
        />
        {activationErr}
        {deactivationErr}
      </LoadingSection>
    </>
  )
}

const mapStateToProps = ({ session }) => ({ currentTeam: session.currentTeam })

export default connect(mapStateToProps)(IntegrationActivity)
