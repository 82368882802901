import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AgencyPublicPropertiesContainer from 'app/agency/public_properties/container'
import { setBackUrl } from 'app/shared_components/actions'
import { fetchAgencyPublicProperties } from 'app/sm/properties/properties_actions'
import { requestForViewing } from 'app/sm/property_details/property_details_actions'

const mapStateToProps = ({ agency, MyProperties, session }) => ({
  agencyProfile: agency.agencyResponse,
  properties: MyProperties.publicProperties.data,
  propertyData: MyProperties.publicProperties,
  currentUser: session.currentUser,
  spinner: MyProperties.spinner,
  currentTeam: session.currentTeam,
  loadMoreSpinner: agency.loadMoreSpinner,
})

const mapDispatchToProps = (dispatch) => ({
  fetchAgencyPublicProperties: (
    agencySlug,
    address,
    sort,
    sortDir,
    bedroom,
    cursor,
    limit,
    buildingSlug,
  ) =>
    dispatch(
      fetchAgencyPublicProperties(
        agencySlug,
        address,
        sort,
        sortDir,
        bedroom,
        cursor,
        limit,
        buildingSlug,
      ),
    ),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
  requestForViewing: (payload) => dispatch(requestForViewing(payload)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgencyPublicPropertiesContainer),
)
