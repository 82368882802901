import React from 'react'

import moment from 'moment'

import { Flex } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import MembersDropdown from 'app/components/display/edit_viewing_modal/members_dropdown'
import {
  defaultEditViewingModalValues,
  durationOptionsText,
} from 'app/components/display/edit_viewing_modal/viewing_modal_data'
import * as Form from 'app/components/forms/forms'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { getAgencyMembers } from 'app/services/http/agency'
import * as snugNotifier from 'app/services/snugNotifier'
import * as FormComponent from 'app/shared_components/form_component'
import { validateStartTime } from 'app/shared_components/validations'
import * as helpers from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as viewingHelpers from 'app/utils/viewings/helpers'

const START_TIME = 'startTime'
const START_DATE = 'startDate'
const FALSE = 'false'
const DURATION = 'duration'
const PUBLISHED = 'published'
const NOTIFY = 'notify'
const ADD_VIEWING_TITLE = 'Add Viewing'
const EDIT_VIEWING_TITLE = 'Edit Viewing'

class AddEditViewing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addViewing: false,
      startDate: moment().format(dateTimeHelpers.DATE_WITH_DASH),
      startTime: defaultEditViewingModalValues.startTime,
      duration: 2,
      published: true,
      notify: true,
      notifyShortlistedApplicants: false,
      teamMembers: [],
      inspectorsGUIDs: [],
      errors: {
        startDate: '',
        startTime: '',
        duration: '',
        notify: '',
        published: '',
      },
    }
  }

  componentDidMount() {
    const { addViewing, teamGUID } = this.props || {}
    if (!addViewing) {
      const {
        startDate = '',
        duration = '',
        published = false,
        notify = false,
        notifyShortlistedApplicants,
      } = this.props || {}
      this.setState({
        startDate: moment(startDate).format(dateTimeHelpers.DATE_WITH_DASH),
        startTime: moment(startDate).format(helpers.timeFormat),
        duration,
        published,
        notify,
        notifyShortlistedApplicants,
        addViewing,
      })
    } else {
      getAgencyMembers(teamGUID).then((res) => {
        this.setState({
          teamMembers: res,
        })
      })
    }
  }

  onChangeRadioGroup = (radioGroupID) => {
    return (event) => {
      const { id } = event.target || {}
      this.setState({
        [radioGroupID]: id !== FALSE,
        errors: { ...this.state.errors, [radioGroupID]: '' },
      })
      if (id === FALSE) {
        this.setState({
          notify: false,
        })
      } else {
        this.setState({
          notify: true,
        })
      }
    }
  }

  handlePrimaryAction = () => {
    const {
      startDate,
      startTime,
      duration,
      published,
      notify,
      notifyShortlistedApplicants,
      inspectorsGUIDs,
    } = this.state
    const { confirmButtonFunc } = this.props
    if (
      this.state.errors.startTime.length ||
      this.state.errors.startDate.length
    ) {
      snugNotifier.error(viewingHelpers.VIEWING_TIME_DATE_ERROR)
      return
    }
    confirmButtonFunc({
      startDate,
      startTime,
      duration,
      published,
      notify,
      notifyShortlistedApplicants,
      inspectorsGUIDs,
    })
  }

  updateFormFields(field) {
    const { errors } = this.state
    return (data) => {
      const value = data.value
      this.setState(
        {
          [field]: value,
          errors: {
            ...errors,
            [field]: data.error,
          },
        },
        () => {
          const { startTime, startDate, errors } = this.state
          if (field === START_TIME || field === START_DATE) {
            const checkStartTimeError = validateStartTime(
              moment(startDate).format(dateTimeHelpers.DATE_WITH_SLASH),
              startTime,
              viewingHelpers.TIME_PAST_ERROR,
            )
            if (checkStartTimeError.length) {
              this.setState({
                ...this.state,
                errors: {
                  ...errors,
                  startTime: checkStartTimeError[0],
                },
              })
            } else {
              this.setState({
                ...this.state,
                errors: {
                  ...errors,
                  startTime: '',
                },
              })
            }
          }
        },
      )
    }
  }

  render() {
    const {
      addViewing = false,
      textToBeDisplayed = '',
      startTime,
      cancelButtonFunc,
      disableAddEditSaveButton,
    } = this.props || {}
    let { published, teamMembers } = this.state || {}
    const formattedStartDate =
      this.state.errors.startDate !== dateTimeHelpers.INVALID_DATE_STRING
        ? this.state.startDate &&
          moment(this.state.startDate).format(dateTimeHelpers.DATE_WITH_SLASH)
        : this.state.startDate
    const modalTitle = addViewing ? ADD_VIEWING_TITLE : EDIT_VIEWING_TITLE
    return (
      <Display.Modal
        toggleModal={cancelButtonFunc}
        title={modalTitle}
        primaryButtonLabel="Save"
        primaryButtonAction={this.handlePrimaryAction}
        secondaryButtonAction={cancelButtonFunc}
        secondaryButtonLabel="Cancel"
        buttonsContainerClass="row ml0 mr0"
        primaryButtonClassName="col-sm-6"
        secondaryButtonClassName="col-sm-6"
        modalBodyClass="display-flex flex-direction-column"
        primaryDisabled={disableAddEditSaveButton}
      >
        <div className="display-flex flex-direction-column  p0 col-sm-12">
          <Display.DefaultBodyText
            componentClass="col-sm-12"
            text={textToBeDisplayed}
          />
          <div className="display-flex col-sm-12 p0 mobile-flex-direction-column schedule-viewing-forms mb15-mobile">
            <Form.InputFutureDate
              componentClass="col-sm-12 col-md-6"
              value={formattedStartDate}
              onChange={this.updateFormFields(START_DATE)}
              error={this.state.errors.startDate}
              label="Date"
              containerClassName="width-auto"
              viewMode="days"
              customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
              readOnly={false}
            />
            <Form.TimePicker
              value={this.state.startTime}
              viewMode="time"
              onChange={this.updateFormFields(START_TIME)}
              componentClass="col-sm-12 col-md-3 time-picker"
              label="Time"
              containerClassName="width-auto"
              error={this.state.errors.startTime}
              placeholder={startTime}
              defaultValue={startTime}
              fiveMinuteIntervals={true}
            />
            <Form.Dropdown
              label={durationOptionsText[0]}
              value={this.state.duration}
              options={durationOptionsText}
              onChange={this.updateFormFields(DURATION)}
              componentClass="col-sm-12 col-md-3"
              inputClass="width-auto"
              frameLabel="Duration"
              inputBoxClass="standard-input-box"
            />
          </div>
          <div className="display-flex mobile-flex-direction-column col-sm-12 p0">
            <FormComponent.RadioGroup
              label=""
              id="published"
              field="published"
              componentClassName="property-details-radio-group col-sm-7 pr0"
            >
              <FormComponent.Checkbox
                id="true"
                label="Open home"
                checked={published === true}
                onChange={this.onChangeRadioGroup(PUBLISHED)}
                componentClassName={'mr8'}
              />
              <FormComponent.Checkbox
                id="false"
                label="Private appointment"
                checked={published === false}
                onChange={this.onChangeRadioGroup(PUBLISHED)}
                componentClassName={'mr0'}
              />
            </FormComponent.RadioGroup>
          </div>

          <div className="display-flex mobile-flex-direction-column col-sm-7 p0">
            <FormComponent.RadioGroup
              label=""
              id="notify-enquirers"
              field="notifyEnquirers"
              componentClassName="property-details-radio-group col-sm-7 pr0"
            >
              <Form.CheckBoxGeneral
                label="Notify other enquirers"
                labelClass="font-size-17 pr0"
                checked={this.state.notify}
                onChange={this.updateFormFields('notify')}
                componentClassName="mr8"
              />
            </FormComponent.RadioGroup>
          </div>
          <div>
            {addViewing && (
              <Flex
                alignItems="center"
                my={theme.space[1] + 'px'}
                pl={theme.space[5] + 'px'}
              >
                <Flex
                  mr={theme.space[4] + 'px'}
                  fontWeight={theme.fontWeights[4]}
                >
                  <span>Inspector:</span>
                </Flex>
                <MembersDropdown
                  teamMembers={teamMembers}
                  onMembersChanged={(guids) => {
                    this.setState({
                      inspectorsGUIDs: guids,
                    })
                  }}
                />
              </Flex>
            )}
          </div>
          <div className="display-flex mobile-flex-direction-column col-sm-7 p0">
            <FormComponent.RadioGroup
              label=""
              id="notify-shortlisted-applicants"
              field="notifyShortlistedApplicants"
              componentClassName="property-details-radio-group col-sm-7 pr0"
            >
              <Form.CheckBoxGeneral
                label="Notify shortlisted applicants"
                labelClass="font-size-17 pr0"
                checked={this.state.notifyShortlistedApplicants}
                onChange={this.updateFormFields('notifyShortlistedApplicants')}
              />
            </FormComponent.RadioGroup>
          </div>
        </div>
      </Display.Modal>
    )
  }
}

export default AddEditViewing
