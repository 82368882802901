import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import { Dropdown } from 'app/components/design-system-components/Dropdown'
import Flex from 'app/components/design-system-components/Flex'
import HorizontalLine from 'app/components/design-system-components/HorizontalLine'

const DropdownListContainer = styled(Box)`
  min-width: 116px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray200}`};
  border-radius: 5px;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.1));

  .option {
    padding: 7px 16px;
    background-color: white;

    font-size: ${({ theme }) => theme.fontSizes[4] + 'px'};
    font-weight: ${({ theme }) => theme.fontWeights[3]};
    line-height: 140%;

    &:hover {
      background-color: #eff6fd;
      cursor: pointer;
    }
  }
`

const CheckboxAndDropdownBtnContainer = styled(Flex)`
  cursor: pointer;
`

const CheckboxAndDropdownBtn = ({
  dropdownControls: { toggle },
  toggleSelection,
  isChecked,
  styledProps,
}) => {
  return (
    <CheckboxAndDropdownBtnContainer
      alignItems="center"
      styles={{ cursor: 'pointer' }}
      onClick={() => toggle()}
      {...styledProps}
    >
      <Box>
        <label className="ibm filter-today-checkbox">
          <input
            type="checkbox"
            className="input-box wa pt0"
            onChange={() => toggleSelection()}
            onClick={(e) => e.stopPropagation()}
            checked={isChecked}
          />
        </label>
      </Box>
      <i
        className="fa fa-sort-down"
        style={{ top: '-3px', position: 'relative' }}
      />
    </CheckboxAndDropdownBtnContainer>
  )
}

const OptionsDropdownList = ({ dropdownControls: { toggle }, options }) => {
  const onOptionClicked = (onClick) => {
    onClick()
    toggle()
  }

  return (
    <DropdownListContainer>
      {(options || []).map(({ id, label, onClick }) => (
        <>
          <div
            className="option"
            key={id}
            onClick={() => onOptionClicked(onClick)}
          >
            {label}
          </div>
          <HorizontalLine />
        </>
      ))}
    </DropdownListContainer>
  )
}

// maybe later if needed we allow passing CheckboxAndDropdownBtn and OptionsDropdownList render props
export const CheckboxWithDropdown = ({
  isChecked,
  toggleSelection,
  options,
}) => {
  return (
    <Dropdown
      triggerWrapperComponent={(props) => (
        <CheckboxAndDropdownBtn
          {...props}
          isChecked={isChecked}
          toggleSelection={toggleSelection}
        />
      )}
      dropdownComponent={({ dropdownControls }) => (
        <OptionsDropdownList
          dropdownControls={dropdownControls}
          options={options}
        />
      )}
    />
  )
}

CheckboxWithDropdown.propTypes = {
  isChecked: PropTypes.bool,
  toggleSelection: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.any,
      onClick: PropTypes.func,
    }),
  ),
}
