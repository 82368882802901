import styled from 'styled-components'

import { Alert, Box, Button } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as textHelpers from 'app/utils/text/helpers'

const StyledConfirmationAlert = styled(Box)`
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.12);
  h2 {
    font-weight: 500;
  }
`

function ConfirmationAlert({
  onPressFinalizeApplication,
}: {
  onPressFinalizeApplication: () => void
}) {
  return (
    <StyledConfirmationAlert>
      <Alert variant="blueWithBg" actionIconBtnConfig={undefined}>
        <Box color={theme.colors.black}>
          <h2>Application</h2>
          <Box mt={theme.space[2]}>
            {textHelpers.VARC_POST_APPLICATION_SUBMISSION_TEXT}
          </Box>
          <Button onClick={onPressFinalizeApplication} mt={7}>
            Finalise Application
          </Button>
        </Box>
      </Alert>
    </StyledConfirmationAlert>
  )
}

export default ConfirmationAlert
