import React from 'react'

const FASpinner = () => {
  return (
    <div className="text-align-center text-black mt20 mb20">
      <i className="fa fa-spinner fa-pulse fs24"></i>
    </div>
  )
}

export default FASpinner
