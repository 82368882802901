import React from 'react'

import qs from 'qs'
import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import { Alert } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import ProgressBarComponent from 'app/components/progress_bar/connection'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import Checkbox from 'app/shared_components/checkbox'
import getParameter from 'app/shared_components/parameter_parser'
import Header from 'app/shared_components/rental_reputation_header_connection'
import SnugTip from 'app/shared_components/snug_tips'
import { isFromApplication, urlTo } from 'app/sm/helpers'
import PetsDetailsBox from 'app/sm/pets_details/components/pets_details_box'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import * as textHelpers from 'app/utils/text/helpers'

const ErrorMessage = ({ error }) => (
  <div className="row">
    <div className={error ? 'alert alert-danger' : ''}>
      <div>{error}</div>
    </div>
  </div>
)

const StyledHeaderContainer = styled.div`
  margin-bottom: ${theme.space[8]}px;

  .section-title {
    padding-bottom: ${theme.space[3]}px !important;
  }
`

class PetsDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      isBallotApplication: false,
    }
  }

  componentDidMount() {
    this.props.fetchPets()
    this.props.retrieveReputationPreference()
    this.props.fetchCompleteness()

    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { ballot = false } = curQueries || {}
    if (ballot && ballot === 'true') {
      this.setState({
        isBallotApplication: true,
      })
    }
  }

  onChangeOptOutPets = () => {
    const needDelete =
      this.props.reputationPreference.optOutPets === false &&
      this.props.petList.length
    if (needDelete) {
      if (
        window.confirm(
          'This will delete your existing pet details from your profile. Would you like to continue?',
        )
      ) {
        this.props
          .changeReputationPreference({
            ...this.props.reputationPreference,
            optOutPets: !this.props.reputationPreference.optOutPets,
          })
          .then(() => this.props.fetchPets())
          .catch((error) => this.setState({ error }))
      }
    } else {
      this.props
        .changeReputationPreference({
          ...this.props.reputationPreference,
          optOutPets: !this.props.reputationPreference.optOutPets,
        })
        .catch((error) => this.setState({ error }))
    }
  }

  confirmRemovePet = (guid) => {
    if (window.confirm('Are you sure?')) {
      this.props.removePet(guid)
    }
  }

  render() {
    const { petList, removePet, backUrl, property, completeness } = this.props
    const { isBallotApplication } = this.state

    const propertyId = getParameter('propertyId')
    const applicationId = getParameter('applicationId')
    const isFromApplicationForm =
      isFromApplication(backUrl) || propertyId || applicationId
    const addPetDetailsTagClass = !this.props.reputationPreference.optOutPets
      ? 'pet-details-added'
      : ''

    return (
      <Display.CenterContentContainer componentClass="width100">
        <ProgressBarComponent completeness={completeness} />
        <RentalReputationHeaderReminder
          property={property}
          backUrl={backUrl}
          remindText={'Current application'}
          isFromApplicationForm={isFromApplicationForm}
          title="Pets"
        />

        <StyledHeaderContainer>
          <Header
            title="Pets"
            message={textHelpers.PETS_DETAIL_HEADER_GUIDANCE}
          />

          <Alert
            variant="blueWithBg"
            fontSize={theme.fontSizes.pRegular14}
            showLeftIcon
            leftIconVariant="info"
          >
            {textHelpers.SHARED_PETS_ALERT}
          </Alert>
        </StyledHeaderContainer>

        <Checkbox
          label="I don't have any pets"
          value={this.props.reputationPreference.optOutPets}
          className={addPetDetailsTagClass}
          onChange={this.onChangeOptOutPets}
          id="optOutPets"
        />

        {this.props.reputationPreference.optOutPets === false && (
          <div className="table mb20">
            <div className="cell-m-100">
              <h4>Add pet</h4>
            </div>
            <div className="cell-m">
              <Link className="btn btn-round" to={urlTo('PetDetailsAdd')}>
                <i className="icon-add" />
              </Link>
            </div>
          </div>
        )}

        <div className="mb50">
          {(petList || []).length !== 0 &&
            petList.map((pet) => (
              <PetsDetailsBox
                removePet={this.confirmRemovePet}
                pet={pet}
                key={pet.guidID}
              />
            ))}
        </div>

        <ErrorMessage error={this.state.error} />

        <ReactTooltip className="tooltip" />

        <SnugTip
          tipContent="Providing details about any pets makes it easier for your application to be reviewed.
         Please do not add pets common to any of your household"
        />
        <Display.BottomContentContainer>
          <Display.CenterContentContainer componentClass="width100">
            <div className="submit-button-container">
              <BottomButton
                nextUrl={`${urlTo('RentalReputationDocuments')}${
                  window.location.search
                }`}
                btnText={
                  isFromApplicationForm
                    ? 'Save & Continue'
                    : 'Next: Other Documents'
                }
                bottomButtonsClass="pt0 mt0 summary-bottom-button"
                nextBtnClass="summary-green-button-class"
                backButtonClass="summary-grey-button-class"
                nextButtonPositionClass="pr20 pl0"
                showArrow={false}
              />
            </div>
          </Display.CenterContentContainer>
        </Display.BottomContentContainer>
      </Display.CenterContentContainer>
    )
  }
}

export default PetsDetails
