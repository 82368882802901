import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import * as getters from 'app/components/design-system-components/theme/getters'

const gridAreas = {
  teamMember: 'teamMember',
  categories: 'categories',
}

export const FollowsGridContainer = styled(Box)`
  display: grid;

  align-items: center;
  grid-column-gap: ${getters.spacing(4)};

  grid-template-columns: 60% 35% 1fr;
  grid-template-areas: '${gridAreas.teamMember} ${gridAreas.categories} .';
`

export const FollowsGridTeamMemberContainer = styled(Box)`
  grid-area: ${gridAreas.teamMember};
`

export const FollowsGridCategoriesContainer = styled(Box)`
  grid-area: ${gridAreas.categories};
`
