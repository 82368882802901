import React, { useEffect, useState } from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import {
  Box,
  Flex,
  LoadingSection,
} from 'app/components/design-system-components'
import { LockOutlineRounded } from 'app/components/design-system-components/icons/actions'
import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import { getPMSKeys } from 'app/services/http/pms/keys'
import * as snugNotifier from 'app/services/snugNotifier'
import { onlistModalCodes } from 'app/sm/helpers'
import { AccessDetailsFields } from 'app/sm/onlist_details/AccessDetails/constants'
import { formatTime, SHORT_STANDARD_DATETIME } from 'app/utils/datetime/helpers'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'

const EntryDetailsHeader = styled.div`
  font-size: 22px;
  color: black;
  font-weight: bold;
  margin-bottom: 18px;
  position: relative;
`

const EditLink = styled.span`
  font-size: 16px;
  width: 30px;
  float: right;
  padding: 0px;
  position: absolute;
  left: 200px;
  top: 5px;
  color: #5a9bee;
  text-decoration: underline !important;
  cursor: pointer;
`

const StyledAccessDetailsBlock = styled(Box)`
  white-space: pre-line;
`

const EntryDetails = ({
  entryDetails,
  onEntryDetailsModalOpen,
  lastUpdateBy,
  isDisplayTitle = true,
  offerGUID,
  agencyGUID,
}) => {
  const [pmsKey, setPMSKey] = useState()
  const {
    loadingStates: pmsKeysLoadingStates,
    loadingStatesHelpers: pmsKeysLoadingHelpers,
  } = useLoadingStates()

  const entryDetailsObj = {
    keyCode: '',
    alarmCode: '',
    accessNote: '',
  }
  entryDetails.forEach((codeDetails, index) => {
    if (codeDetails.entryType === onlistModalCodes.keyCode) {
      entryDetailsObj.keyCode = codeDetails.entryNote
    }
    if (codeDetails.entryType === onlistModalCodes.alarmCode) {
      entryDetailsObj.alarmCode = codeDetails.entryNote
    }
    if (codeDetails.entryType === onlistModalCodes.accessNote) {
      entryDetailsObj.accessNote = codeDetails.entryNote
    }
  })

  useEffect(() => {
    if (!offerGUID || !agencyGUID) return
    pmsKeysLoadingHelpers.startLoading()
    getPMSKeys(agencyGUID, offerGUID)
      .then((key) => {
        pmsKeysLoadingHelpers.markDoneSuccessfully()
        key &&
          setPMSKey({
            ...key,
            lastUpdatedDate: formatTime(
              key?.updatedAt,
              SHORT_STANDARD_DATETIME,
            ),
          })
      })
      .catch((err) => {
        pmsKeysLoadingHelpers.setError(err)
      })
  }, [])

  const renderPMSKeyCode = () => {
    const { keyCode, id, pms } = pmsKey
    return (
      <Flex alignItems="center">
        {keyCode}{' '}
        <LockOutlineRounded
          ml={3}
          data-tooltip-id={id}
          data-tooltip-content=""
        />
        <ReactTooltip id={id} data-type="info" className="tool-tip-style">
          Change in {pms} to update upon next synchronisation
        </ReactTooltip>
      </Flex>
    )
  }

  const displayEntryNotice = entryDetails.length > 0 || !!pmsKey

  const renderLastUpdated = () => {
    if (!pmsKey && lastUpdateBy === ' ') return
    const { lastUpdatedDate, pms } = pmsKey || {}
    return (
      <Box mt={6} mb={6}>
        Last updated:{' '}
        {!!pmsKey ? `${lastUpdatedDate} ${pms} Sync` : lastUpdateBy}
      </Box>
    )
  }

  return (
    <div className="position-relative">
      {isDisplayTitle ||
        (displayEntryNotice && (
          <EntryDetailsHeader>Access Details</EntryDetailsHeader>
        ))}
      {displayEntryNotice && (
        <div>
          {!pmsKey && (
            <EditLink onClick={() => onEntryDetailsModalOpen('Edit')}>
              Edit
            </EditLink>
          )}
          <div className="mt20">
            <h5>Key Code</h5>
            <LoadingSection
              showChildrenWhenError
              loadingState={pmsKeysLoadingStates}
            >
              {!!pmsKey ? (
                renderPMSKeyCode()
              ) : (
                <span>
                  {entryDetailsObj.keyCode ? entryDetailsObj.keyCode : 'N/A'}
                </span>
              )}
            </LoadingSection>
          </div>
          <div className="mt20">
            <h5>Alarm Code</h5>
            <span>
              {entryDetailsObj.alarmCode ? entryDetailsObj.alarmCode : 'N/A'}
            </span>
          </div>
          <div className="mt20">
            <h5>Access Notes</h5>
            <StyledAccessDetailsBlock>
              {entryDetailsObj.accessNote || pmsKey?.keyDescription
                ? pmsKey?.keyDescription || entryDetailsObj.accessNote
                : 'N/A'}
            </StyledAccessDetailsBlock>
          </div>
          {renderLastUpdated()}
        </div>
      )}
      {!displayEntryNotice && (
        <div className="ml15">
          <Display.GenericButton
            componentClass="col-sm-3 ml-30 mb20 min-200px"
            text="Add Access Details"
            onClick={() => onEntryDetailsModalOpen('Add')}
          />
        </div>
      )}
    </div>
  )
}

class AccessDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disablePrimaryButton: false,
      isEntryDetailsModalOpen: false,
      modalHeader: '',
      modalFormData: {
        keyCode: '',
        alarmCode: '',
        accessNote: '',
      },
      entryDetails: [],
      lastUpdateBy: '',
    }
  }

  componentDidMount() {
    const { entryDetails, lastUpdateBy, isDisplayTitle } = this.props
    this.setState({
      entryDetails,
      lastUpdateBy,
      isDisplayTitle,
    })
  }

  componentDidUpdate(prevProps) {
    const { entryDetails, lastUpdateBy } = this.props
    if (prevProps.entryDetails !== this.props.entryDetails) {
      this.setState({
        entryDetails,
        lastUpdateBy,
      })
    }
  }

  onEntryDetailsModalOpen = (field) => {
    this.setState({
      modalHeader: field,
      isEntryDetailsModalOpen: true,
    })
    if (field === 'Edit') {
      this.clearUnsavedChanges()
    }
  }

  onModalValueChange(field, data) {
    this.setState({
      modalFormData: {
        ...this.state.modalFormData,
        [field]: data.value,
      },
    })
  }

  addEntryDetails = () => {
    this.setState({
      disablePrimaryButton: true,
    })
    const { modalFormData } = this.state
    const { createEntryDetails, guidDetails } = this.props
    setTimeout(() => {
      const requestData = this.validateAndCreateRequest(modalFormData)
      if (requestData.length === 0) {
        window.confirm('Are you sure you want to continue')
          ? this.setState({
              disablePrimaryButton: false,
              isEntryDetailsModalOpen: false,
            })
          : this.setState({
              disablePrimaryButton: false,
            })
      }
      const { property, agencyGUID } = guidDetails
      if (requestData.length > 0 && property && agencyGUID) {
        const requestBody = {
          entryDetails: requestData,
        }
        createEntryDetails(agencyGUID, property, requestBody)
          .then((response) => {
            const lastUpdatedDateWithManager = `${response.lastUpdatedDate} ${response.managerName}`
            this.setState({
              lastUpdateBy: lastUpdatedDateWithManager,
              entryDetails: response.entryDetails,
              disablePrimaryButton: false,
              isEntryDetailsModalOpen: false,
            })
          })
          .catch((error) => {
            this.setState({
              disablePrimaryButton: false,
            })
            snugNotifier.error(error)
          })
      }
    }, 300)
  }

  clearUnsavedChanges() {
    const { modalFormData } = this.state
    const { entryDetails } = this.state
    if (entryDetails.length > 0) {
      entryDetails.forEach((codeDetails, index) => {
        if (codeDetails.entryType === onlistModalCodes.keyCode) {
          modalFormData.keyCode = codeDetails.entryNote
        }
        if (codeDetails.entryType === onlistModalCodes.alarmCode) {
          modalFormData.alarmCode = codeDetails.entryNote
        }
        if (codeDetails.entryType === onlistModalCodes.accessNote) {
          modalFormData.accessNote = codeDetails.entryNote
        }
      })
    }
  }

  closeModal = () => {
    const { modalHeader, modalFormData } = this.state
    if (modalHeader === 'Add') {
      const requestData = this.validateAndCreateRequest(modalFormData)
      if (requestData.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        !window.confirm(
          'There are unsaved changes! are you sure you want to continue ?',
        )
          ? this.setState({
              isEntryDetailsModalOpen: false,
            })
          : ''
      }
      if (requestData.length === 0) {
        this.setState({
          isEntryDetailsModalOpen: false,
        })
      }
    }
    if (modalHeader === 'Edit') {
      // eslint-disable-next-line no-unused-expressions
      window.confirm('There are unsaved changes')
        ? this.setState({
            isEntryDetailsModalOpen: false,
          })
        : ''
      this.clearUnsavedChanges()
    }
  }

  editEntryDetails = () => {
    const { modalFormData } = this.state
    const { updateEntryDetails, guidDetails } = this.props
    this.setState({
      disablePrimaryButton: true,
    })
    setTimeout(() => {
      const requestData = this.validateAndCreateRequest(modalFormData)
      const { property, agencyGUID } = guidDetails

      if (requestData.length > 0 && property && agencyGUID) {
        const requestBody = {
          entryDetails: requestData,
        }
        updateEntryDetails(agencyGUID, property, requestBody)
          .then((response) => {
            const lastUpdatedDateWithManager = `${response.lastUpdatedDate} ${response.managerName}`
            this.setState({
              lastUpdateBy: lastUpdatedDateWithManager,
              entryDetails: response.entryDetails,
              disablePrimaryButton: false,
              isEntryDetailsModalOpen: false,
            })
          })
          .catch((error) => {
            this.setState({
              disablePrimaryButton: false,
            })
            snugNotifier.error(error)
          })
      }
    }, 300)
  }

  validateAndCreateRequest(modalValues) {
    const entryDetailsList = []
    const { modalHeader } = this.state
    Object.values(AccessDetailsFields).forEach((field) => {
      if (modalValues[field.typeKey] !== '' || modalHeader === 'Edit') {
        const entry = {
          entryType: field.typeIndex,
          entryNote: modalValues[field.typeKey],
        }
        entryDetailsList.push(entry)
      }
    })
    return entryDetailsList
  }

  render() {
    const { guidDetails } = this.props
    const {
      disablePrimaryButton,
      isEntryDetailsModalOpen,
      modalFormData,
      modalHeader,
      entryDetails,
      lastUpdateBy,
      isDisplayTitle,
    } = this.state

    const { offerGUID, agencyGUID } = guidDetails

    return (
      <div>
        <EntryDetails
          entryDetails={entryDetails}
          onEntryDetailsModalOpen={this.onEntryDetailsModalOpen}
          lastUpdateBy={lastUpdateBy}
          modalFormData={modalFormData}
          isDisplayTitle={isDisplayTitle}
          offerGUID={offerGUID}
          agencyGUID={agencyGUID}
        />
        {isEntryDetailsModalOpen && (
          <Display.Modal
            title={`${modalHeader} Access details`}
            secondaryButtonLabel="Cancel"
            primaryButtonLabel="Save"
            primaryButtonAction={() =>
              modalHeader === 'Edit'
                ? this.editEntryDetails()
                : this.addEntryDetails()
            }
            primaryDisabled={disablePrimaryButton}
            secondaryButtonAction={() => this.closeModal()}
            toggleModal={() => this.closeModal()}
          >
            <Form.InputTextOptional
              value={modalFormData.keyCode}
              label="Key code"
              onChange={(value) =>
                this.onModalValueChange(
                  AccessDetailsFields.KeyCode.typeKey,
                  value,
                )
              }
              labelClass="top18"
              inputClass="width100"
              isOptional
            />
            <Form.InputTextOptional
              value={modalFormData.alarmCode}
              label="Alarm code"
              onChange={(value) =>
                this.onModalValueChange(
                  AccessDetailsFields.AlarmCode.typeKey,
                  value,
                )
              }
              labelClass="top18"
              inputClass="width100 mb10"
              isOptional
            />
            <Form.CommentArea
              value={modalFormData.accessNote}
              placeholder="Access Note"
              onChange={(value) =>
                this.onModalValueChange(
                  AccessDetailsFields.AccessNotes.typeKey,
                  value,
                )
              }
              cols={50}
              rows={2}
              isOptional={true}
              inputBoxClass="pt0"
            />
          </Display.Modal>
        )}
      </div>
    )
  }
}

export default AccessDetails
