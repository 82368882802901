import React from 'react'

import moment from 'moment'

import { Box } from 'app/components/design-system-components'
import {
  GreySubheading,
  SectionHeader,
} from 'app/components/display/text/standard_text/standard-headings/component'
import * as NewForm from 'app/components/forms/forms'
import { studentDetailsAttachmentTypes } from 'app/constants/student.constants'
import {
  addStudentDetailsAttachments,
  deleteStudentDetailsAttachment,
} from 'app/services/http/student'
import * as snugNotifier from 'app/services/snugNotifier'
import DocumentList from 'app/shared_components/document_list/document_list'
import * as Form from 'app/shared_components/form_component'
import { composeAttachment, ErrorMessage } from 'app/shared_components/helpers'
import StandardScreen from 'app/shared_components/layout_component/standard_screen_layout_component'
import { history } from 'app/shared_components/router'
import * as helpers from 'app/sm/helpers'
import {
  DocumentTypes,
  durationTypeToText,
  studentTypeToText,
  validateMinimumContactDetailProvided,
} from 'app/sm/helpers'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  loadingStatesIds,
} from 'app/utils/loading-states'
import { SUBTEXT_FILE_UPLOAD_ALL_FILES } from 'app/utils/text/helpers'

class AddStudentDetailsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      studentForm: {
        placeOfStudy: '',
        courseName: '',
        studentType: 0,
        duration: '',
        durationType: 0,
        enrolmentNumber: '',
        startDate: '',
        campusContact: false,
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        courseCoordinator: false,
        coordinatorName: '',
        coordinatorEmail: '',
        coordinatorMobile: '',
      },
      errors: {
        placeOfStudy: '',
        courseName: '',
        studentType: '',
        duration: '',
        durationType: '',
        enrolmentNumber: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        contactOfficeNumber: '',
        coordinatorName: '',
        coordinatorEmail: '',
        coordinatorMobile: '',
        startDate: '',
      },
      submitting: false,
      confirmBtnText: 'Submit',
      apiError: '',
      frontEndError: '',
      //Edit case
      studentId: null,

      studentIDAttachments: [],
      offerLetterAttachments: [],

      studentIDAttachmentsLoadingStates: createInitialLoadingState(),
      offerLetterAttachmentsLoadingStates: createInitialLoadingState(),
    }

    this.studentIDAttachmentsLoadingUtils = createLoadingStateUtils((state) => {
      this.setState({
        studentIDAttachmentsLoadingStates: state,
      })
    })
    this.offerLetterAttachmentsLoadingUtils = createLoadingStateUtils(
      (state) => {
        this.setState({
          offerLetterAttachmentsLoadingStates: state,
        })
      },
    )
  }

  componentDidMount() {
    const { getSingleStudent } = this.props
    const { studentId } = this.props.match.params
    this.setState({ studentId })

    if (studentId) {
      getSingleStudent(studentId)
        .then(({ studentInfo }) => {
          this.setState({
            studentForm: {
              ...studentInfo,
              startDate: moment(studentInfo.startDate).format(
                dateTimeHelpers.DATE_WITH_DASH,
              ),
            },
            confirmBtnText: 'Save',
            studentIDAttachments: studentInfo.studentIDAttachments
              ? this.transformAttachments(studentInfo.studentIDAttachments)
              : [],
            offerLetterAttachments: studentInfo.offerLetterAttachments
              ? this.transformAttachments(studentInfo.offerLetterAttachments)
              : [],
          })
        })
        .catch((error) =>
          this.setState({
            apiError: error,
          }),
        )
    }
  }

  focusAndScrollToFirstError(errors) {
    const field = Object.keys(errors)
      .filter((f) => this.state.errors[f])
      .shift()
    if (field) {
      const element = document.getElementById(field)
      if (element) {
        element.focus()
        element.scrollIntoView(true)
      }
    }
  }

  submit = () => {
    const {
      studentId,
      studentForm,
      studentIDAttachments,
      offerLetterAttachments,
    } = this.state
    this.setState({
      frontEndError: '',
    })

    if (!studentForm.campusContact && !studentForm.courseCoordinator) {
      this.setState({
        frontEndError: 'Please provide campus or course coordinator contact.',
      })
      return
    }

    if (!this.validateRequiredFields() || !this.validateForm()) {
      this.focusAndScrollToFirstError(this.state.errors)
      return
    }

    studentForm.studentType = Number(studentForm.studentType)
    studentForm.duration = Number(Number(studentForm.duration).toFixed(2))
    studentForm.durationType = Number(studentForm.durationType)

    studentForm.studentIDAttachments = studentIDAttachments.map(
      (attachment) => attachment.guidID,
    )

    studentForm.offerLetterAttachments = offerLetterAttachments.map(
      (attachment) => attachment.guidID,
    )

    delete this.state.errors
    const { postStudentDetails, updateStudentDetails } = this.props
    const requestFunction = studentId
      ? updateStudentDetails
      : postStudentDetails
    requestFunction(studentForm, studentId)
      .then(() => {
        snugNotifier.success('Student details saved successfully')
        history.goBack()
      })
      .catch((apiError) => {
        snugNotifier.error(apiError)
        this.setState({
          apiError,
        })
      })
  }

  toggleField = (field) => {
    return () => {
      this.setState({
        studentForm: {
          ...this.state.studentForm,
          [field]: !this.state.studentForm[field],
        },
      })
      const { studentForm, errors } = this.state
      if (field === 'campusContact') {
        const updatedErrors = Object.assign({}, errors, {
          contactName: studentForm.contactName ? errors.contactName : '',
          contactEmail: studentForm.contactEmail ? errors.contactEmail : '',
          contactPhone: studentForm.contactPhone ? errors.contactPhone : '',
        })
        this.setState({
          errors: updatedErrors,
        })
      }
      if (field === 'courseCoordinator') {
        const updatedErrors = Object.assign({}, errors, {
          coordinatorName: studentForm.coordinatorName
            ? errors.coordinatorName
            : '',
          coordinatorEmail: studentForm.coordinatorEmail
            ? errors.coordinatorEmail
            : '',
          coordinatorMobile: studentForm.coordinatorMobile
            ? errors.coordinatorEmail
            : '',
        })
        this.setState({
          errors: updatedErrors,
        })
      }
    }
  }

  update = (field) => {
    return (data) => {
      let value = data.value
      const error = data.error
      if (error === '' && field === 'startDate') {
        value =
          value !== ''
            ? moment(value).format(dateTimeHelpers.DATE_WITH_DASH)
            : ''
      }
      this.setState({
        studentForm: { ...this.state.studentForm, [field]: value },
        apiError: '',
        frontEndError: '',
        errors: {
          ...this.state.errors,
          [field]: error,
        },
      })
    }
  }

  validateForm = () => {
    const errors = []
    Object.keys(this.state.errors).forEach((field) => {
      const error = this.state.errors[field]
      if (error) {
        errors.push(error)
      }
    })
    return errors.length === 0
  }

  validateRequiredFields = () => {
    const {
      studentForm: {
        campusContact,
        courseCoordinator,
        contactEmail,
        contactPhone,
        coordinatorEmail,
        coordinatorMobile,
      },
    } = this.state

    if (campusContact) {
      const errorMessage = validateMinimumContactDetailProvided([
        contactEmail,
        contactPhone,
      ])
      if (errorMessage !== '') {
        this.setState({
          frontEndError: errorMessage,
        })
        return false
      }
    }

    if (courseCoordinator) {
      const errorMessage = validateMinimumContactDetailProvided([
        coordinatorEmail,
        coordinatorMobile,
      ])
      if (errorMessage !== '') {
        this.setState({
          frontEndError: errorMessage,
        })
        return false
      }
    }

    const errors = {}
    Object.keys(this.state.errors)
      .filter(
        (field) =>
          field !== 'contactName' || this.state.studentForm.campusContact,
      )
      .filter((field) => field !== 'contactPhone')
      .filter((field) => field !== 'contactEmail')
      .filter(
        (field) =>
          field !== 'coordinatorName' ||
          this.state.studentForm.courseCoordinator,
      )
      .filter((field) => field !== 'coordinatorEmail')
      .filter((field) => field !== 'coordinatorMobile')
      .forEach((field) => {
        if (this.state.studentForm[field] === '') {
          errors[field] = 'This field is required'
        }
      })
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    })
    if (Object.keys(errors).length !== 0) {
      this.setState({
        frontEndError: 'Please complete all details before submitting.',
      })
    }
    return Object.keys(errors).length === 0
  }

  uploadStudentID = () => {
    this.studentIDAttachmentsLoadingUtils.startLoading()
    this.uploadAttachments(
      DocumentTypes.studentID,
      studentDetailsAttachmentTypes.studentId.id,
    )
      .then((addedAttachments) => {
        snugNotifier.success('Successfully uploaded attachments')
        this.setState({
          studentIDAttachments: [
            ...this.state.studentIDAttachments,
            ...this.transformAttachments(addedAttachments),
          ],
        })
        this.studentIDAttachmentsLoadingUtils.markDoneSuccessfully()
      })
      .catch((error) => {
        this.studentIDAttachmentsLoadingUtils.setError(error.message)
        snugNotifier.error(error.message)
      })
  }

  uploadOfferLetter = () => {
    this.offerLetterAttachmentsLoadingUtils.startLoading()
    this.uploadAttachments(
      DocumentTypes.studentOfferLetter,
      studentDetailsAttachmentTypes.offerLetter.id,
    )
      .then((addedAttachments) => {
        snugNotifier.success('Successfully uploaded offer letters')
        this.setState({
          offerLetterAttachments: [
            ...this.state.offerLetterAttachments,
            ...this.transformAttachments(addedAttachments),
          ],
        })
        this.offerLetterAttachmentsLoadingUtils.markDoneSuccessfully()
      })
      .catch((error) => {
        this.offerLetterAttachmentsLoadingUtils.setError(error.message)
        snugNotifier.error(error.message)
      })
  }

  uploadAttachments = (docType, attachmentsType) => {
    const attachments = document.getElementById(`attachments-${docType}`)
    let formData = new FormData()

    const { studentId } = this.state

    if (studentId) {
      formData.append('studentId', studentId)
    }

    composeAttachment(attachments, formData)

    return addStudentDetailsAttachments(attachmentsType, formData)
  }

  deleteAttachment = (attachmentGUID) => {
    return deleteStudentDetailsAttachment(attachmentGUID)
      .then(() => snugNotifier.success('Attachment deleted successfully'))
      .catch((err) => snugNotifier.error(err.message))
  }

  deleteStudentIDAttachment = (attachmentGUID) => {
    this.deleteAttachment(attachmentGUID).then(() => {
      const { studentIDAttachments } = this.state
      this.setState({
        studentIDAttachments: studentIDAttachments.filter(
          (attachment) => attachment.guidID !== attachmentGUID,
        ),
      })
    })
  }

  deleteOfferLetterAttachment = (attachmentGUID) => {
    this.deleteAttachment(attachmentGUID).then(() => {
      const { offerLetterAttachments } = this.state
      this.setState({
        offerLetterAttachments: offerLetterAttachments.filter(
          (attachment) => attachment.guidID !== attachmentGUID,
        ),
      })
    })
  }

  renderAttachmentsInputs() {
    const {
      studentIDAttachments,
      offerLetterAttachments,
      studentIDAttachmentsLoadingStates,
      offerLetterAttachmentsLoadingStates,
    } = this.state
    return (
      <>
        <Box my="20px">
          <Box mb="10px">
            <h4>Student ID</h4>
          </Box>
          <DocumentList
            documents={studentIDAttachments}
            addDocument={this.uploadStudentID}
            documentType={helpers.DocumentTypes.studentID}
            spinner={
              studentIDAttachmentsLoadingStates.state ===
              loadingStatesIds.LOADING
            }
            availableFileTypesText={SUBTEXT_FILE_UPLOAD_ALL_FILES}
            deleteDocument={this.deleteStudentIDAttachment}
            allowDeletion={true}
          />
        </Box>
        <Box my="20px">
          <Box mb="20px">
            <h4>Letter of Offer</h4>
          </Box>
          <DocumentList
            documents={offerLetterAttachments}
            addDocument={this.uploadOfferLetter}
            documentType={helpers.DocumentTypes.studentOfferLetter}
            spinner={
              offerLetterAttachmentsLoadingStates.state ===
              loadingStatesIds.LOADING
            }
            availableFileTypesText={SUBTEXT_FILE_UPLOAD_ALL_FILES}
            deleteDocument={this.deleteOfferLetterAttachment}
            allowDeletion={true}
          />
        </Box>
      </>
    )
  }

  transformAttachments(attachments = []) {
    // name, guidID and URL fields are used in DocumentList component
    return attachments.map(
      ({ displayName: DName, name, url, guid, ...rest }) => ({
        name: DName,
        URL: url,
        guidID: guid,
        ...rest,
      }),
    )
  }

  render() {
    const disabled = this.state.submitting
    const {
      studentForm: {
        placeOfStudy,
        courseName,
        duration,
        studentType,
        durationType,
        enrolmentNumber,
        campusContact,
        contactName,
        contactEmail,
        contactPhone,
        courseCoordinator,
        coordinatorName,
        coordinatorEmail,
        coordinatorMobile,
        startDate,
      },
      confirmBtnText,
      apiError,
      frontEndError,
    } = this.state
    const {
      errors: {
        placeOfStudy: placeOfStudyError,
        courseName: courseError,
        duration: durationError,
        studentType: studentTypeError,
        durationType: durationTypeError,
        enrolmentNumber: enrolmentNumberError,
        contactName: contactNameError,
        contactEmail: contactEmailError,
        contactPhone: contactPhoneError,
        coordinatorName: coordinatorNameError,
        coordinatorEmail: coordinatorEmailError,
        coordinatorMobile: coordinatorMobileError,
        startDate: startDateError,
      },
    } = this.state

    const { property, backUrl } = this.props
    const { studentId } = this.props.match.params

    const isFromApplicationForm = backUrl.includes('sm/applications')
    const isEdit = `${studentId ? 'Edit' : 'Add'}`
    const HeaderText = `${isEdit} Student Details`
    const formattedStartDate =
      startDateError !== dateTimeHelpers.INVALID_DATE_STRING
        ? startDate && moment(startDate).format(dateTimeHelpers.DATE_WITH_SLASH)
        : startDate
    const attachmentInputs = this.renderAttachmentsInputs()
    return (
      <StandardScreen>
        <RentalReputationHeaderReminder
          property={property}
          backUrl={backUrl}
          remindText="Current application"
          isFromApplicationForm={isFromApplicationForm}
          title="Student Details"
          thirdTierText={isEdit}
        />
        <SectionHeader textClass="pl0" text={HeaderText} hasSeperator={false} />
        <NewForm.ProfilePagesRowLayout containerClass="profile-layout-two-items">
          <div className="width100">
            <NewForm.InputAlphabetic
              label="Place of Study"
              value={placeOfStudy}
              error={placeOfStudyError}
              onChange={this.update('placeOfStudy')}
              id="placeOfStudy"
              inputClass="width100"
              componentClass="margin-profile-item-left"
            />
          </div>
          <div className="width100">
            <NewForm.InputTextRequired
              label="Course"
              value={courseName}
              error={courseError}
              onChange={this.update('courseName')}
              id="courseName"
              inputClass="width100"
              componentClass="margin-profile-item-right"
            />
          </div>
        </NewForm.ProfilePagesRowLayout>
        <NewForm.ProfilePagesRowLayout containerClass="profile-layout-two-items">
          <div className="width100">
            <NewForm.Dropdown
              label="Student Type"
              value={studentType}
              error={studentTypeError}
              options={studentTypeToText}
              onChange={this.update('studentType')}
              id="studentType"
              inputClass="width100"
              componentClass="margin-profile-item-left"
            />
          </div>
          <div className="width100">
            <NewForm.InputDate
              label="Start Date"
              value={formattedStartDate}
              error={startDateError}
              onChange={this.update('startDate')}
              id="startDate"
              containerClassName="width100"
              componentClass="margin-profile-item-right"
              viewMode="months"
              customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
              readOnly={false}
            />
          </div>
        </NewForm.ProfilePagesRowLayout>
        <NewForm.ProfilePagesRowLayout containerClass="profile-layout-two-items">
          <div className="width100">
            <NewForm.InputNumber
              label="Duration"
              value={duration}
              error={durationError}
              onChange={this.update('duration')}
              id="duration"
              inputClass="width100"
              componentClass="margin-profile-item-left"
            />
          </div>
          <div className="width100">
            <NewForm.Dropdown
              label="Duration Type"
              value={durationType}
              error={durationTypeError}
              options={durationTypeToText}
              onChange={this.update('durationType')}
              id="durationType"
              valueType="number"
              inputClass="width100"
              componentClass="margin-profile-item-right"
            />
          </div>
        </NewForm.ProfilePagesRowLayout>
        <NewForm.ProfilePagesRowLayout containerClass="profile-layout-two-items justify-content-flex-start">
          <div className="col-md-6 p0">
            <NewForm.InputTextRequired
              label="Enrolment No."
              value={enrolmentNumber}
              error={enrolmentNumberError}
              onChange={this.update('enrolmentNumber')}
              id="enrolmentNumber"
              inputClass="width100"
              componentClass="margin-profile-item-left"
            />
          </div>
        </NewForm.ProfilePagesRowLayout>
        <GreySubheading
          text="Contact Details"
          componentClass="text-area-label"
        />
        <div className="mb20">
          <Form.Checkbox
            label="Campus Contact"
            checked={campusContact}
            onChange={this.toggleField('campusContact')}
          />
        </div>
        {campusContact && (
          <div>
            <NewForm.ProfilePagesRowLayout containerClass="profile-layout-two-items">
              <div className="width100">
                <NewForm.InputName
                  label="Name"
                  value={contactName}
                  error={campusContact ? contactNameError : ''}
                  onChange={this.update('contactName')}
                  id="contactName"
                  inputClass="width100"
                  componentClass="margin-profile-item-left"
                />
              </div>
              <div className="width100">
                <NewForm.InputEmail
                  label="Email"
                  value={contactEmail}
                  error={campusContact ? contactEmailError : ''}
                  onChange={this.update('contactEmail')}
                  id="contactEmail"
                  inputClass="width100"
                  componentClass="margin-profile-item-right"
                  isOptional
                />
              </div>
            </NewForm.ProfilePagesRowLayout>
            <NewForm.ProfilePagesRowLayout containerClass="profile-layout-two-items justify-content-flex-start">
              <div className="col-md-6 p0">
                <NewForm.InputPhoneNumber
                  label="Phone Number"
                  value={contactPhone}
                  error={campusContact ? contactPhoneError : ''}
                  onChange={this.update('contactPhone')}
                  id="contactOfficeNumber"
                  placeholder="+61 2 1234 5678 or +61 412 345 678"
                  containerClassName="width100"
                  componentClass="margin-profile-item-left"
                />
              </div>
            </NewForm.ProfilePagesRowLayout>
          </div>
        )}
        <div className="mb20">
          <Form.Checkbox
            label="Course Coordinator"
            checked={courseCoordinator}
            onChange={this.toggleField('courseCoordinator')}
          />
        </div>
        {courseCoordinator && (
          <div>
            <NewForm.ProfilePagesRowLayout containerClass="profile-layout-two-items">
              <div className="width100">
                <NewForm.InputName
                  label="Name"
                  value={coordinatorName}
                  error={coordinatorNameError}
                  onChange={this.update('coordinatorName')}
                  id="coordinatorName"
                  inputClass="width100"
                  componentClass="margin-profile-item-left"
                />
              </div>
              <div className="width100">
                <NewForm.InputEmail
                  label="Email"
                  value={coordinatorEmail}
                  error={coordinatorEmailError}
                  onChange={this.update('coordinatorEmail')}
                  id="coordinatorEmail"
                  inputClass="width100"
                  componentClass="margin-profile-item-right"
                  isOptional
                />
              </div>
            </NewForm.ProfilePagesRowLayout>
            <NewForm.ProfilePagesRowLayout containerClass="profile-layout-two-items justify-content-flex-start">
              <div className="col-md-6 p0">
                <NewForm.InputPhoneNumber
                  label="Phone Number"
                  value={coordinatorMobile}
                  error={coordinatorMobileError}
                  onChange={this.update('coordinatorMobile')}
                  id="coordinatorMobile"
                  containerClassName="width100"
                  componentClass="margin-profile-item-left"
                />
              </div>
            </NewForm.ProfilePagesRowLayout>
          </div>
        )}
        {attachmentInputs}
        <ErrorMessage error={apiError} />
        <ErrorMessage error={frontEndError} />
        <div className="row pt25 mobile-form-button mt15">
          <div className="col-sm-5 pb5 tablet-only">
            <div
              role="button"
              tabIndex="0"
              className="btn btn-transparent btn-left xs-w100p wa"
              onClick={() => {
                history.goBack()
              }}
            >
              <i className="icon-arrow-left left" />
              <span>Back</span>
            </div>
          </div>

          <div className="col-sm-7 pb5">
            <Form.Button
              label={confirmBtnText}
              loading={this.state.submitting}
              disabled={disabled}
              onClick={this.submit}
            />
          </div>
        </div>
      </StandardScreen>
    )
  }
}

export default AddStudentDetailsContainer
