export const rentFrequencyOptions = [
  { label: 'Allow applicant to choose', value: 'open' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Fortnightly', value: 'fortnightly' },
  { label: 'Monthly', value: 'monthly' },
]

export const rentFrequencyOpenOption = rentFrequencyOptions[0].value
export const rentFrequencyDefaultValue = rentFrequencyOpenOption
export const rentFrequencyDBKey = 'rent_frequency'
