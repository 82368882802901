import { fetchAgencyManagerList } from 'app/bond_cover/agency/agency_access'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { mapLegacyAccessPromiseToServiceFnPromise } from 'app/services/http/utils'
import { getManagersListForViewing } from 'app/sm/viewings/viewings_access'
import { api } from 'app/utils/api/helpers'

// just a wrapper around the legacy function to use same API to replace it later with axios base one
export const getTeamManagers = (teamId) => {
  return fetchAgencyManagerList(teamId)
    .then(({ ok, ...rest }) => {
      return ok ? Promise.resolve(rest) : Promise.reject(rest)
    })
    .catch(({ statusCode }) => {
      return Promise.reject({
        message: translateErrorCodeToMessage(statusCode),
      })
    })
}

export const getTeamMembers = (teamId) => {
  return mapLegacyAccessPromiseToServiceFnPromise(
    getManagersListForViewing(teamId),
  )
}

export const inviteTeamMembers = (teamId, payload) => {
  return api.post(`/sm/team/${teamId}/invite`, payload)
}
