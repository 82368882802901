import React from 'react'

import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import { MailToLink } from 'app/components/design-system-components/links/MailTo'
import {
  Heading,
  Text,
} from 'app/components/design-system-components/typography'
import { TelLink } from 'app/components/display/contact_links'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as phoneHelpers from 'app/utils/phonenumber/helpers'
import * as stringHelpers from 'app/utils/strings/helpers'

const AgencyInfoHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .default-body-text {
    p {
      font-size: 18px;
    }
  }
  .image-container {
    text-align: right;
  }
  img {
    display: inline-block;
    width: 400px;
    height: auto;
  }
  .place-dot-before {
    display: inherit;
  }
  .place-dot-before::before {
    content: '•';
    margin: 0 8px;
    font-size: 20px;
    align-items: center;
    margin-top: -2px;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    img {
      width: 100%;
      display: block;
    }

    .image-container {
      margin-right: -20px;
      margin-left: -20px;
      margin-top: -4px;
    }
  }
  @media print {
    flex-direction: row;
    margin-bottom: 20px;
    .default-body-text {
      p {
        line-height: 24px;
      }
    }
    img {
      width: 400px;
      height: auto;
    }
  }
`

const AgencyInfoHeader = ({
  agency,
  showAgencyContactInfo = true,
  isBuildingApplyPage = false,
  buildingName = '',
}) => {
  const {
    agencyAddress = '',
    agencyContactNumber = '',
    agencyEmail = '',
    brandingBannerURL = '',
    agencyName = '',
  } = agency || {}
  const nameInHeader = isBuildingApplyPage ? buildingName : agencyName
  const showDotDivider = !!agencyContactNumber && !!agencyEmail
  const dotDividerClass = showDotDivider ? 'place-dot-before' : ''
  const agencyHasAddress =
    agencyAddress && agencyAddress.replace(/[, ]+/g, ' ').trim().length > 0

  const shouldDisplayAddress =
    agencyAddress && showAgencyContactInfo && agencyHasAddress
  const shouldDisplayContactNumber =
    !!agencyContactNumber && showAgencyContactInfo
  const shouldDisplayContactEmail = !!agencyEmail && showAgencyContactInfo
  const formattedAddress = stringHelpers.trimAndAddEllipsesPastSpecifiedLength(
    agencyAddress,
    50,
  )
  const formattedContactNumber =
    phoneHelpers.formatPhoneNumberWithoutCountryCode(agencyContactNumber)
  const formattedEmail = stringHelpers.trimAndAddEllipsesPastSpecifiedLength(
    agencyEmail,
    30,
  )

  return (
    <AgencyInfoHeaderContainer className="agency-info-card">
      <div className="contact-details-card">
        <Box>
          <Heading level={3}>{nameInHeader}</Heading>
          <Box>
            {shouldDisplayAddress && (
              <Text mb={1} color={theme.colors.gray600}>
                {formattedAddress}
              </Text>
            )}
            <Flex>
              {shouldDisplayContactNumber && (
                <TelLink phone={formattedContactNumber}>
                  {formattedContactNumber}
                </TelLink>
              )}
              {shouldDisplayContactEmail && (
                <Flex className={dotDividerClass} color={theme.colors.gray300}>
                  <MailToLink email={agencyEmail}>{formattedEmail}</MailToLink>
                </Flex>
              )}
            </Flex>
          </Box>
        </Box>
      </div>
      <Flex>
        <div className="image-container">
          {brandingBannerURL && (
            <img src={brandingBannerURL} alt="Agency Banner" />
          )}
        </div>
      </Flex>
    </AgencyInfoHeaderContainer>
  )
}

export default AgencyInfoHeader
