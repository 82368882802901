import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

import usersAvatars from 'app/assets/icons/users-avatars.png'
import * as snugLogger from 'app/services/snugLogger'
import isEmptyObject from 'app/shared_components/check_empty_object'
import * as validations from 'app/shared_components/validations'
import * as utils from 'app/utils'

class SocialShare extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      referrerEmail: '',
      emails: {},
      errors: {},
      disabled: true,
      sentEmails: false,
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    nextState.disabled = !(
      nextState.referrerEmail.length &&
      nextState.emails['firstEmail'] &&
      nextState.emails['secondEmail'] &&
      nextState.emails['thirdEmail'] &&
      !isEmptyObject(nextState.emails) &&
      isEmptyObject(nextState.errors)
    )
  }

  setValidationErrors(field, error) {
    let errors = this.state.errors
    if (error.length == 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  submitEmails = () => {
    snugLogger.error('No contact exporter')
    // this.setState({ disabled: true, sentEmails: true })
  }

  trimField = (field) => {
    return (event) => {
      let { emails } = this.state
      let value = emails[field]
      if (value) {
        value = value.trim()
        emails[field] = value
        this.validate(field, value)()
        this.setState({ emails })
      }
    }
  }

  updateEmail(field) {
    return (event) => {
      let { emails } = this.state
      let { value } = event.target
      value = value.toLowerCase()
      emails[field] = value
      this.setState({ emails })
    }
  }

  updatereferrerEmail() {
    return (event) => {
      let { value } = event.target
      this.setState({ referrerEmail: value.toLowerCase() })
    }
  }

  validate(field, value) {
    return (event) => {
      return this.setValidationErrors(
        field,
        validations.validateEmailOptional(value),
      )
    }
  }

  render() {
    let { emails, errors, disabled, sentEmails, referrerEmail } = this.state
    let { firstEmail, secondEmail, thirdEmail } = emails
    return (
      <div className="pb50">
        <h3>Help your friends get BondCover</h3>
        <p>
          Spread the word, tell your friends about BondCover and help make
          renting more affordable.
        </p>

        <h5 className="center mt40">Enter emails</h5>

        <div className="input-box mt25">
          <input
            type="text"
            required
            value={referrerEmail ? referrerEmail : ''}
            className={errors.referrerEmail ? 'error' : ''}
            onChange={this.updatereferrerEmail()}
            onBlur={this.trimField('referrerEmail')}
          />
          <label>Your Email Address</label>
        </div>

        <div className="input-box">
          <input
            type="text"
            required
            value={firstEmail ? firstEmail : ''}
            className={errors.firstEmail ? 'error' : ''}
            onChange={this.updateEmail('firstEmail')}
            onBlur={this.trimField('firstEmail')}
          />
          <label>Friend 1</label>
        </div>

        <div className="input-box">
          <input
            type="text"
            required
            value={secondEmail ? secondEmail : ''}
            className={errors.secondEmail ? 'error' : ''}
            onChange={this.updateEmail('secondEmail')}
            onBlur={this.trimField('secondEmail')}
          />
          <label>Friend 2</label>
        </div>

        <div className="input-box">
          <input
            type="text"
            required
            value={thirdEmail ? thirdEmail : ''}
            className={errors.thirdEmail ? 'error' : ''}
            onChange={this.updateEmail('thirdEmail')}
            onBlur={this.trimField('thirdEmail')}
          />
          <label>Friend 3</label>
        </div>

        {!sentEmails ? (
          <button
            className="mt15"
            type="button"
            disabled={disabled || sentEmails}
            onClick={this.submitEmails}
          >
            Invite your friends
          </button>
        ) : (
          <a
            target="_blank"
            className={sentEmails ? 'btn btn-facebook' : 'hidden'}
            href="http://www.facebook.com/dialog/send?app_id=1833293223590676&amp;link=https://snug.com/&amp;redirect_uri=https://snug.com/"
            rel="noreferrer"
          >
            <i className="icon-facebook"></i>
            <span>Share on Facebook</span>
          </a>
        )}

        <div className={sentEmails ? 'alert alert-success' : 'hidden'}>
          Emails successfully sent, thanks for your support.
        </div>
      </div>

      /* <div className="bc-social-share">
        <img src={usersAvatars} />
        <h1>#bondcover</h1>
        <p>Spread the word, tell your friends about BondCover and help make renting more affordable.</p>
        <a className="btn btn-facebook" href="https://www.facebook.com/sharer/sharer.php?u=https%3A//snug.com">
          <i className="icon-facebook"></i>
          <span>Share on Facebook</span>
        </a>
        <a className="btn btn-twitter" href="https://twitter.com">
          <i className="icon-twitter"></i>
          <span>Share on Twitter</span>
        </a>
      </div> */
    )
  }
}

export default SocialShare
