import { history } from 'app/shared_components/router'
import { _saveCard } from 'app/sm/background_check/background_check_access'
import { urlTo } from 'app/sm/helpers'
import {
  getCards,
  getOfferedApplication,
  payWithExistingAccount,
  payWithNewAccount,
} from 'app/sm/ph_deposit/ph_deposit_access'
import store from 'app/store'

export const CARDS_RECEIVED = 'PHD_CARDS_RECEIVED'
export const CARD_SELECT = 'PHD_CARD_SELECT'
export const ACCEPT_TERMS_CONDITIONS_CHANGE =
  'PHD_ACCEPT_TERMS_CONDITIONS_CHANGE'
export const RECEIVE_RESPONSE_TEXT = 'PHD_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'PHD_RECEIVE_ERROR'
export const CLEAR_ERROR = 'PHD_CLEAR_ERROR'
export const PING = 'PHD_PING'
export const RECEIVE_OFFERED_APPLICATION = 'PHD_RECEIVE_OFFERED_APPLICATION'

export const ping = () => ({ type: PING })
export const cardsReceived = (cards) => ({ type: CARDS_RECEIVED, cards })
export const cardSelect = (selected) => ({ type: CARD_SELECT, selected })
export const acceptTermsConditionsChange = () => ({
  type: ACCEPT_TERMS_CONDITIONS_CHANGE,
})
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })
export const clearError = () => ({ type: RECEIVE_ERROR })
export const receiveOfferedApplication = (offeredApplication) => ({
  type: RECEIVE_OFFERED_APPLICATION,
  offeredApplication,
})

function stepForward(propertyId, applicationId) {
  history.push(urlTo('offeredApplication', { propertyId, applicationId }))
}

export function fetchCards() {
  return (dispatch) => {
    getCards().then(({ cards, ok, responseText, statusCode }) => {
      if (ok) {
        const Cards = cards
          .map((c) => ({
            id: c.id,
            number: `${c.brand} XXXX-${c.last4} (exp ${c.exp_month}/${
              c.exp_year % 100
            })`,
          }))
          .concat({
            id: 'New',
            number: 'New Credit Card',
          })

        dispatch(cardsReceived(Cards))
        dispatch(cardSelect(Cards[0].id))
      } else {
        responseText.then((t) => {
          store.dispatch(receiveResponseText(t))
        })
      }
    })
  }
}

export function fetchOfferedApplication(applicationId) {
  return (dispatch) => {
    getOfferedApplication(applicationId).then(
      ({ application, ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(receiveOfferedApplication(application))
        } else {
          responseText.then((t) => {
            store.dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

export function pay(data) {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    const { offeredApplication } = getState().PhDeposit
    const { Application } = offeredApplication
    const {
      guidID: applicationId,
      weeklyRent: amount,
      offer: {
        property: { guidID: propertyId },
      },
    } = Application
    const offerId = Application.offer.guidID

    payWithNewAccount(data, offerId, applicationId, amount).then(
      ({ ok, statusCode, responseText }) => {
        if (ok) {
          stepForward(propertyId, applicationId)
        } else {
          responseText.then((t) => {
            store.dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

function saveCard(card) {
  store.dispatch(clearError())
  return (dispatch) => {
    _saveCard(card).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(pay(card))
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function registerPayment(data) {
  return (dispatch, getState) => {
    dispatch(saveCard(data))
  }
}

export function payWithExistingCard() {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    const { cards, selectedCreditCard, offeredApplication } =
      getState().PhDeposit
    const { Application } = offeredApplication
    const {
      guidID: applicationId,
      weeklyRent: amount,
      offer: {
        property: { guidID: propertyId },
      },
    } = Application
    const offerId = Application.offer.guidID
    const card = cards.find((c) => c.id === selectedCreditCard)

    payWithExistingAccount(card.id, offerId, applicationId, amount).then(
      ({ ok, statusCode, responseText }) => {
        if (ok) {
          stepForward(propertyId, applicationId)
        } else {
          responseText.then((t) => {
            store.dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}
