import { connect } from 'react-redux'

import {
  addPricingVariation,
  approveApplication,
  deletePricingVariation,
  getRequestSummary,
  processPayment,
  redirectToDeclineForm,
  releaseApplication,
  releaseBackToRenter,
  releaseToCertificate,
  releaseToIDCheck,
  removeAllDiscounts,
  reopenApplication,
  resendApplication,
} from 'app/bond_cover/admin/admin_actions.js'
import RequestFormContainer from 'app/bond_cover/admin/requests/request_form'

const mapStateToProbs = ({ admin }) => ({
  admin: admin,
  spinner: admin.spinner,
  responseText: admin.responseText,
})

const mapDispatchToProbs = (dispatch) => ({
  getRequestSummary: (guid) => getRequestSummary(guid),
  reopenApplication: (appCode, guid) => reopenApplication(appCode, guid),
  releaseApplication: (guid) => releaseApplication(guid),
  resendApplication: (appCode, guid) => resendApplication(appCode, guid),
  approveApplication: (appCode, data, guid) =>
    approveApplication(appCode, data, guid),
  releaseBackToRenter: (guid) => releaseBackToRenter(guid),
  releaseToIDCheck: (guid) => releaseToIDCheck(guid),
  releaseToCertificate: (guid) => releaseToCertificate(guid),
  processPayment: (guid) => processPayment(guid),
  addPricingVariation: (requestId, payload) =>
    dispatch(addPricingVariation(requestId, payload)),
  deletePricingVariation: (requestId, pricingVariationId) =>
    dispatch(deletePricingVariation(requestId, pricingVariationId)),
  removeAllDiscounts: (requestId, guids) =>
    dispatch(removeAllDiscounts(requestId, guids)),
  redirectToDeclineForm: () => redirectToDeclineForm(),
})

export default connect(
  mapStateToProbs,
  mapDispatchToProbs,
)(RequestFormContainer)
