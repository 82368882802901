import React from 'react'

import * as Display from 'app/components/display/display'
import * as Text from 'app/components/display/text/text_component'
import * as Form from 'app/components/forms/forms'
import * as helpers from 'app/sm/helpers'

export const CommentsSection = ({
  positiveComment,
  errors,
  feedback,
  updateUploadData,
  renterFirstName,
}) => {
  const ratingQuestions = [
    {
      id: 'positive',
      title: '',
      value: positiveComment.answerValue,
      error: errors.positiveComment,
      placeholder: 'Write positive comments here',
      field: 'positiveComment',
    },
    {
      id: 'feedback',
      title: 'Feedback',
      value: feedback.answerValue,
      error: errors.feedback,
      placeholder: 'Write general feedback here',
      field: 'feedback',
    },
  ]
  return (
    <div className="mb30">
      <div className="mb20">
        <Display.FlexContainer containerClass="mb10">
          <div className="font-size-24 fw500 mr10">Positive Comments</div>
          <Text.Normal text="(optional)" componentClass="grey-text" />
        </Display.FlexContainer>
        <Text.Normal
          text={`Please provide some positive comments about ${renterFirstName}`}
        />
      </div>
      <div>
        {ratingQuestions.map((ratingQuestion) => {
          return (
            <div className="mb20" key={ratingQuestion.id}>
              <Text.Strong text={ratingQuestion.title} />
              <Form.CommentArea
                value={ratingQuestion.value}
                error={ratingQuestion.error}
                placeholder={ratingQuestion.placeholder}
                onChange={updateUploadData(ratingQuestion.field)}
                id={ratingQuestion.id}
                cols={50}
                rows={5}
                isOptional
                inputBoxClass="pt0"
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const OverallSection = ({
  overall,
  pleasant,
  takesCare,
  recommendation,
  bondDeduction,
  errorForOverall,
  bondDeductionCommentError,
  onChangeMultiSelect,
  onChangeYesNo,
  renterFirstName,
  updateUploadData,
}) => {
  const multiSelectQuestions = [
    {
      multiSelectOptions: overall,
      question: 'How was the tenant overall?',
      error: errorForOverall,
      field: 'overall',
    },
    {
      multiSelectOptions: pleasant,
      question: 'How pleasant were they to deal with?',
      error: '',
      field: 'pleasant',
    },
    {
      multiSelectOptions: takesCare,
      question: 'How well did they look after the property?',
      error: '',
      field: 'takesCare',
    },
  ]
  const yesOrNoQuestions = [
    {
      ratingCode: helpers.ratingType.recommendation,
      field: 'recommendation',
      question: `Would you rent to ${renterFirstName} again?`,
      positiveChoice: {
        id: 'Yes',
        label: 'Yes',
        checked: recommendation.answerValue === 'true',
      },
      negativeChoice: {
        id: 'No',
        label: 'No',
        checked: recommendation.answerValue === 'false',
      },
    },
    {
      ratingCode: helpers.ratingType.bondDeduction,
      field: 'bondDeduction',
      question: 'Were there deductions to the bond?',
      positiveChoice: {
        id: 'No',
        label: 'No',
        checked: bondDeduction.answerValue === 'false',
      },
      negativeChoice: {
        id: 'Yes',
        label: 'Yes',
        checked: bondDeduction.answerValue === 'true',
      },
    },
  ]
  return (
    <div className="mb30 fs18">
      {multiSelectQuestions.map((question) => {
        return (
          <div className="mb30" key={question.field}>
            <Text.Strong text={question.question} />
            {question.multiSelectOptions && (
              <Form.MultiSelect
                options={question.multiSelectOptions}
                className="p0"
                onChange={onChangeMultiSelect(question.field)}
                bottomSpacingClass="pb0"
              />
            )}
            {question.error && (
              <Display.ErrorMessageContainer error={question.error} />
            )}
          </div>
        )
      })}
      {yesOrNoQuestions.map((yesOrNoQuestion) => {
        return (
          <Form.YesOrNoRadioGroup
            id={yesOrNoQuestion.ratingCode}
            label={yesOrNoQuestion.question}
            childrenWrapperClassName="pt0 mb30"
            key={yesOrNoQuestion.ratingCode}
          >
            <Form.YesOrNoCheckbox
              id={yesOrNoQuestion.positiveChoice.id}
              label={yesOrNoQuestion.positiveChoice.label}
              checked={yesOrNoQuestion.positiveChoice.checked}
              onChange={onChangeYesNo(yesOrNoQuestion.field)}
              componentClassName="yes left"
            />
            <Form.YesOrNoCheckbox
              id={yesOrNoQuestion.negativeChoice.id}
              label={yesOrNoQuestion.negativeChoice.label}
              checked={yesOrNoQuestion.negativeChoice.checked}
              onChange={onChangeYesNo(yesOrNoQuestion.field)}
              componentClassName="no right"
            />
          </Form.YesOrNoRadioGroup>
        )
      })}
      {bondDeduction.answerValue === 'true' && (
        <div className="mb20">
          <Text.Strong text="Bond deductions" />
          <Form.CommentArea
            value={bondDeduction.comment}
            error={bondDeductionCommentError}
            placeholder="Please describe any bond deductions"
            onChange={updateUploadData('bondDeduction')}
            id="bondDeduction"
            cols={50}
            rows={5}
            isOptional
            inputBoxClass="pt0"
          />
        </div>
      )}
      <div></div>
    </div>
  )
}
