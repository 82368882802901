import React from 'react'

import styled from 'styled-components'

import { Box, Modal } from 'app/components/design-system-components'
import { BG_CHECK_EXAMPLE_REPORT_PDF_URL } from 'app/utils/backgroundcheck'

const StyledModalContainer = styled(Box)`
  width: 100%;
  height: 60vh;
`

const docViewerUrl = `https://docs.google.com/gview?url=${BG_CHECK_EXAMPLE_REPORT_PDF_URL}&embedded=true`

function ExampleReportModal({ toggleExampleReportModal }) {
  return (
    <Modal
      modalBody={
        <StyledModalContainer>
          <iframe
            id="exampleBackgroundCheckReport"
            title="Example Background Check Report"
            src={docViewerUrl}
            frameborder="1"
            scrolling="no"
            height="100%"
            width="100%"
          />
        </StyledModalContainer>
      }
      modalHeading="Example Report"
      showPrimaryButton={false}
      secondaryLabel="Close"
      toggleModal={toggleExampleReportModal}
      secondaryAction={toggleExampleReportModal}
      secondaryButtonVariant="solidSuccessStrong"
    />
  )
}

export default ExampleReportModal
