import React from 'react'

import moment from 'moment'

import householdIcon from 'app/assets/icons/summary_icons/household.svg'
import DefaultButton from 'app/components/buttons/default/component'
import * as Display from 'app/components/display/display'
import { capitalizeFirstLetter, getRoundedNumber, urlTo } from 'app/sm/helpers'
import { RankPieChart } from 'app/sm/renter_applications/components/applications_card'
import {
  ApplicantListComponent,
  sortApplicants,
} from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'
import OccupantListComponent from 'app/sm/renter_applications/renter_application_detailed/components/occupants/component'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import { formatPhoneNumber } from 'app/utils/phonenumber/helpers'

export const HouseholdContainer = ({
  componentClassName = '',
  application = {},
  isHouseholdEditable = true,
  onEditClick,
}) => {
  const { applicants = [], children = [], occupants = [] } = application
  return (
    <div className={`container-component ${componentClassName}`}>
      <div className="application-details-info-container">
        <Display.ContainerHeader
          icon={householdIcon}
          text="Household"
          isEditable={isHouseholdEditable}
          onEditClick={() =>
            onEditClick(
              `${urlTo('application', {
                applicationId: application.guidID,
              })}?from=rentersummary`,
            )
          }
        />
        {applicants.length > 0 && (
          <div>
            <ApplicantListComponent
              application={application}
              applicants={applicants}
            />
          </div>
        )}
        {occupants.length > 0 && (
          <div>
            <OccupantListComponent
              application={application}
              applicants={occupants}
            />
          </div>
        )}
        {children.length > 0 && (
          <div>
            <h4 className="mt20 mb15 fw600 font-size-18">Children</h4>
            {children.map((child, index) => {
              return (
                <ChildrenItem
                  child={child}
                  key={child.guidID}
                  extraNameStyle="fw400"
                  extraTextStyle="font-size-12"
                  index={index}
                />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export const ManagerHouseholdContainer = ({
  componentClassName = '',
  application = {},
  isHouseholdEditable = true,
  onEditClick,
  sendReminderIcon = '',
  isSubmitApplicationRequestLoading = false,
  showRequestSubmitApplicationReminder = false,
  lastSubmitApplicationRequestTime,
  onRequestSubmitApplication = () => {},
  isReminderSent = false,
  applicationAddress = '',
}) => {
  const lastReminderSentTimeStamp = isReminderSent
    ? moment()
    : moment(lastSubmitApplicationRequestTime)
  const lastReminderSentTimeFormatted = lastReminderSentTimeStamp
    .local()
    .format('HH:mm DD MMM YYYY')

  const { applicants = [], children = [], occupants = [] } = application
  return (
    <div className={`container-component ${componentClassName}`}>
      <div className="application-details-info-container pdf-display-flex">
        <div
          className={`flex-3 mr10 ${
            children.length > 0 && 'pdf-border-right pdf-pr-10'
          }`}
        >
          <Display.ContainerHeader
            icon={householdIcon}
            text="Household"
            isEditable={isHouseholdEditable}
            onEditClick={() =>
              onEditClick(
                `${urlTo('application', {
                  applicationId: application.guidID,
                })}?from=rentersummary`,
              )
            }
          />
          {applicants.length > 0 && (
            <div className="applicants-container">
              {applicants &&
                applicants.sort(sortApplicants).map((applicant) => {
                  return (
                    <ApplicantItem
                      applicant={applicant}
                      application={application}
                      address={applicationAddress}
                    />
                  )
                })}
            </div>
          )}
          {occupants && occupants.length > 0 && (
            <div className="mt20 mb20">
              <OccupantListComponent applicants={occupants} />
            </div>
          )}
          {showRequestSubmitApplicationReminder && (
            <div className="display-flex mobile-flex-direction-column pdf-hide">
              <DefaultButton
                componentClass="width3 mb5"
                text={isReminderSent ? 'Reminder sent' : 'Send reminder'}
                size="large"
                buttonType={isReminderSent ? 'tertiary' : 'secondary'}
                onClick={onRequestSubmitApplication}
                isLoading={isSubmitApplicationRequestLoading}
                disabled={isReminderSent}
              >
                {sendReminderIcon && (
                  <img
                    className="ml5 mr5 pb2"
                    src={sendReminderIcon}
                    alt="reminder icon"
                  />
                )}
              </DefaultButton>
              {(lastSubmitApplicationRequestTime || isReminderSent) && (
                <p className="resend-reference-request-info mt-auto mb-auto pl5">
                  Reminder requested: {lastReminderSentTimeFormatted}
                </p>
              )}
            </div>
          )}
        </div>
        {children.length > 0 && (
          <div className="flex-2">
            <h4 className="mt10 mb15 fw600 font-size-18">Children</h4>
            {children.map((child) => {
              return (
                <ChildrenItem
                  child={child}
                  key={child.guidID}
                  extraNameStyle="fw700"
                  extraTextStyle="font-size-12"
                />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export const ApplicantItem = ({ applicant, address }) => {
  const applicantStatus = applicant.applied ? 'Applied' : 'Invited'
  const formattedPhoneNumber = formatPhoneNumber(applicant.mobileNumber)
  const { applicantViewingSummary } = applicant
  const checkinAvailable =
    !!applicantViewingSummary && applicantViewingSummary.checkInAt !== null
  return (
    <div
      className="applicant-container household-applicant-container mb20 no-page-break"
      key={applicant.guidID}
    >
      <div className="applicant-upper-container">
        <div className="household-applicant-table avatar-container mt5 align-items-center">
          <div className="avatar application-avatar">
            {applicant.avatar && (
              <img
                src={applicant.avatar}
                className="application-avatar-img"
                alt="Applicant's avatar"
              />
            )}
            {!applicant.avatar && (
              <span className="app-avatar-initial-position default-font-family">
                {capitalizeFirstLetter(applicant.firstName.substring(0, 1))}
                {capitalizeFirstLetter(applicant.lastName.substring(0, 1))}
              </span>
            )}
          </div>
        </div>
        <Display.StandardContactInfoContainer
          containerClass="width-50"
          mainTitle={`${capitalizeFirstLetter(
            applicant.firstName,
          )} ${capitalizeFirstLetter(applicant.lastName)}`}
          mainTitleSupportiveText={
            applicant.isPrimary ? ' (Primary)' : ' (Applicant)'
          }
          firstLineText={
            <Display.TelLink
              phone={formattedPhoneNumber}
              className="linkStyle"
            />
          }
          secondLineText={
            <Display.EmailLink
              email={applicant.email}
              subject={`Your Application for: ${address}`}
              className="linkStyle"
            />
          }
          linesText={[
            `Viewed : ${
              checkinAvailable
                ? `${moment(applicantViewingSummary.checkInAt).format(
                    'DD MMM YYYY',
                  )}`
                : 'N/A'
            }`,
            `${
              applicant.age > 0 ? `${applicant.age} yo` : 'Age not available'
            }`,
          ]}
        />
        <div className="applicant-match-score-container pdf-hide">
          <div className="justify-content-center align-items-center prospect-user-row-mobile applicant-match-score-display">
            <div className="meta" to="">
              <div className="rank">
                <div className="rank-info chart relative" title="Snug rank">
                  <RankPieChart rank={getRoundedNumber(applicant.score)} />
                  <div className="rank-value font-size-13">
                    {getRoundedNumber(applicant.score) > 0
                      ? getRoundedNumber(applicant.score)
                      : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="applicant-status-container">
        <div className="updated-date pdf-font-size-10">
          {moment(applicant.updatedAt).format('HH:mm DD MMM YYYY')}
        </div>
        <div
          className={`household-applicant-status ${applicantStatus} pdf-font-size-10`}
        >
          {applicantStatus}
        </div>
      </div>
    </div>
  )
}

const ChildrenItem = ({ child, extraNameStyle, extraTextStyle, index }) => {
  const firstName = !!child.firstName ? child.firstName : 'Child'
  const lastName =
    !!!child.firstName && !!!child.lastName
      ? (index + 1).toString()
      : child.lastName
  return (
    <div className="applicant-container no-page-break mb10">
      <div className="applicant-upper-container">
        <div className="household-applicant-table avatar-container mt0">
          <div className="avatar application-avatar">
            {child.avatar && (
              <img
                src={child.avatar}
                className="application-avatar-img"
                alt="Child avatar"
              />
            )}
            {!child.avatar && (
              <span className="app-avatar-initial-position default-font-family">
                {capitalizeFirstLetter(firstName.substring(0, 1))}
                {capitalizeFirstLetter(lastName.substring(0, 1))}
              </span>
            )}
          </div>
        </div>
        <div className="applicant-info-container display-flex flex-direction-column justify-content-center">
          <h4 className={`applicant-name ${extraNameStyle}`}>
            {capitalizeFirstLetter(firstName)} {capitalizeFirstLetter(lastName)}
          </h4>
          {child.dob && (
            <div className="applicant-info-sub-container">
              <div className={`applicant-info-text ${extraTextStyle}`}>
                {moment().diff(child.dob, 'years')} yo
              </div>
              <div className="spot-seperator" />
              <div className={`applicant-info-text ${extraTextStyle}`}>
                {dateTimeHelpers.formatYearOfBirth(child.dob)}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HouseholdContainer
