import { connect } from 'react-redux'

import IdentityDoc from 'app/profile/renter/identity_doc/identity_doc'
import {
  addIdentityDocAttachment,
  createIdentityDoc,
  deleteIdentityDocAttachment,
  fetchIdentityDoc,
  fetchIdentityDocAttachments,
  removeIdentityDoc,
  steps,
  updateIdentityDoc,
} from 'app/sm/background_check/background_check_actions'

const mapStateToProps = ({ BackgroundCheck, bondCover, session }) => ({
  identityDoc: BackgroundCheck.identityDoc,
  identityDocList: BackgroundCheck.identityDocList,
  steps,
  identityDocAttachments: BackgroundCheck.identityDocAttachments,
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  createIdentityDoc: (data, isDraft) =>
    dispatch(createIdentityDoc(data, isDraft)),
  removeIdentityDoc: (id) => dispatch(removeIdentityDoc(id)),
  fetchIdentityDoc: (guid) => dispatch(fetchIdentityDoc(guid)),
  updateIdentityDoc: (data, guid) => dispatch(updateIdentityDoc(data, guid)),
  fetchIdentityDocAttachments: (guid) =>
    dispatch(fetchIdentityDocAttachments(guid)),
  deleteIdentityDocAttachment: (id, attachmentId) =>
    dispatch(deleteIdentityDocAttachment(id, attachmentId)),
  addIdentityDocAttachment: (documentType, id) =>
    dispatch(addIdentityDocAttachment(documentType, id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IdentityDoc)
