import React from 'react'

import { productFruitWorkspaceCode } from 'config/env'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import warningImg from 'app/assets/icons/warning.svg'
import {
  Alert,
  Box,
  Flex,
  IndeterminateProgressBar,
} from 'app/components/design-system-components'
import { ClearRounded } from 'app/components/design-system-components/icons/content'
import { refreshAndUpdateApplication } from 'app/core/appNewVersion'
import {
  actions,
  connectionStatusChange,
  connectionStatuses,
} from 'app/core/coreSlice'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import { SnugNotifierWrapper } from 'app/services/snugNotifier'
import { closeSideBar } from 'app/shared_components/actions'
import Footer from 'app/shared_components/footer/footer'
import Navbar from 'app/shared_components/navbar/navbar_connection'
import * as roleHelpers from 'app/utils/roles'
import { isMobile } from 'app/utils/system/helpers'
import { TeamActivationMessage } from 'app/utils/teamActivation'
import { isMaintenanceWindowEnabled } from 'config/features'

import 'react-toastify/dist/ReactToastify.css'

const mapDispatchToProps = (dispatch) => ({
  started: () => dispatch(actions.started()),
  newActivityFeedCheck: () => dispatch(actions.newActivityFeedCheck()),
  closeSideBar: () => dispatch(closeSideBar()),
  updateConnectionStatus: (status) =>
    dispatch(actions.updateConnectionStatus(status)),
  dismissConnectionAlert: () => dispatch(actions.dismissAlert()),
  appVisibilityUpdate: (isVisible) =>
    dispatch(actions.appVisibilityUpdate(isVisible)),
  dismissTeamInactiveModal: (status) =>
    dispatch(actions.dismissTeamInactiveModal()),
})

const mapStateToProps = ({ session, core }) => ({
  currentUser: session.currentUser,
  teams: session.teams,
  currentTeam: session.currentTeam,
  currentAgency: session.currentAgency,
  connectionStatus: core.connectionStatus,
  connectionAlertDismissed: core.connectionAlertDismissed,
  globalLoadingActive: !!core.globalLoadingConnections,
  newVersionToDownload: core.showNewVersionToDownload,
  newVersionToActivate: core.showNewVersionToActivate,
  waitingRegistration: core.waitingRegistration,
  maintenanceMessageConfig: core.maintenanceMessageConfig,
  hasNewActivityFeedConfig: core.hasNewActivityFeedConfig,
  hasDismissedInactiveTeamAlert: core.hasDismissedInactiveTeamAlert,
})

const TIME_IN_SECONDS_TO_DISMISS_ALERT = 3

const globalLoadingStyleProps = {
  zIndex: '510',
  position: 'absolute',
  width: '100vw',
}

const alertsStyleProps = {
  position: 'fixed',
  top: '0',
  zIndex: '510',
  boxShadow: '1px 1px 2px 0px #cccccc',
  textAlign: 'center',
}

const networkStatusAlertCTAs = {
  dismiss: {
    text: 'dismiss',
    clickHandlerFactory: (props) => {
      return (event) => {
        event.preventDefault()
        props.dismiss()
      }
    },
  },
  reload: {
    text: 'reload',
    clickHandlerFactory: () => {
      return (event) => {
        event.preventDefault()
        window.location.reload()
      }
    },
  },
}

const NetworkStatusAlert = (props) => {
  const { ctaConfig } = props
  const ctaClickHandler = ctaConfig.clickHandlerFactory(props)
  return (
    <Alert {...alertsStyleProps} {...props}>
      <Box>
        {props.children} <a onClick={ctaClickHandler}> {ctaConfig.text}</a>
      </Box>
    </Alert>
  )
}

const ToastContainerWrapper = styled.div`
  .full-width {
    &--body {
      padding: 0;
    }
  }
`

const NewVersionAlert = ({ children, onClick, onDismiss }) => {
  const messageContent = children || (
    <>
      A new version of Snug is available. Save your changes and click{' '}
      <a onClick={onClick}>update</a>.
    </>
  )

  const dismissActionBtnConfig = {
    onClick: onDismiss,
    iconCmp: ClearRounded,
  }

  return (
    <Alert
      actionIconBtnConfig={dismissActionBtnConfig}
      variant="warningWithBg"
      width="100%"
    >
      <Flex justifyContent="space-between">
        <Box textAlign="left">{messageContent}</Box>
      </Flex>
    </Alert>
  )
}

const downloadAndActivateNewVersion = (e) => {
  e.preventDefault()
  e.stopPropagation()
  if (!navigator.serviceWorker) {
    return
  }

  refreshAndUpdateApplication()
}

const toastNewVersionAlert = (alertElem) =>
  toast(alertElem, {
    autoClose: false,
    closeButton: false,
    closeOnClick: false,
    bodyClassName: 'full-width--body',
    className: 'full-width--toast',
    style: {
      padding: 0,
    },
  })

const StyledNavbar = styled(Navbar)`
  &.navbar-position-mobile {
    @media (max-width: 576px) {
      top: ${({ banners = 0 }) => theme.bannerHeightInPx * banners + 'px'};
    }
  }
`

const BannerIcon = styled.img`
  height: ${theme.space[5]}px;
  width: ${theme.space[5]}px;
`

const BannersContainer = styled(Box)`
  @media (max-width: 576px) {
    position: fixed;
    z-index: 501;
    width: 100vw;
  }
`

const Banner = styled(Alert)`
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: ${theme.bannerHeightInPx + 'px'};
  max-height: ${theme.bannerHeightInPx + 'px'};

  display: flex;
  align-items: center;
`

const StyledAppContent = styled(Box)`
  @media (max-width: 576px) {
    margin-top: ${({ banners = 0 }) =>
      70 + theme.bannerHeightInPx * banners + 'px'};
  }
`

class App extends React.Component {
  constructor(props) {
    super(props)

    this.props.history.listen((location, action) => {
      this.props.closeSideBar()
    })

    this.newVersionToastRef = React.createRef()
  }

  componentDidMount() {
    const {
      updateConnectionStatus,
      started,
      newActivityFeedCheck,
      appVisibilityUpdate,
      currentTeam,
      currentUser,
      currentAgency,
    } = this.props
    started()
    newActivityFeedCheck()
    // in the page being controlled
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', ({ data }) => {
        const { type, ...payload } = data
        if (type === 'connection') {
          const { changed, connectionStatus } = connectionStatusChange(
            payload.isOnline,
            this.props.connectionStatus,
          )
          if (!changed) {
            return
          }
          updateConnectionStatus(connectionStatus)
        }
      })
    }

    document.addEventListener('visibilitychange', () =>
      appVisibilityUpdate(document.visibilityState === 'visible'),
    )

    const { newVersionToDownload, newVersionToActivate } = this.props
    if (newVersionToDownload) {
      this.showNewVersionToDownloadToast()
    }
    if (newVersionToActivate) {
      this.showNewVersionToActivateToast()
    }

    if (!!currentUser?.guidID) {
      const checkIfUserIsAdmin = currentAgency
        ? roleHelpers.isUserAnAdmin(currentAgency)
        : false

      this.triggerProductFruits({
        username: currentUser.guidID,
        createdAt: currentUser.createdAt,
        isTeamAdmin: checkIfUserIsAdmin,
        ...(!!currentTeam?.guid && { teamGUID: currentTeam.guid }),
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { newActivityFeedCheck, currentUser, currentTeam, currentAgency } =
      this.props

    this.checkNewVersionDidUpdate(prevProps)

    if (prevProps.currentTeam?.guid !== currentTeam?.guid) {
      newActivityFeedCheck()

      const checkIfUserIsAdmin = currentAgency
        ? roleHelpers.isUserAnAdmin(currentAgency)
        : false

      // should be true
      if (currentUser?.guidID) {
        this.triggerProductFruits({
          username: currentUser.guidID,
          createdAt: currentUser.createdAt,
          isTeamAdmin: checkIfUserIsAdmin,
          ...(!!currentTeam?.guid && { teamGUID: currentTeam.guid }),
        })
      }
    }
  }

  checkNewVersionDidUpdate(prevProps) {
    const {
      newVersionToDownload: prevNewVersionToDownload,
      newVersionToActivate: prevNewVersionToActivate,
    } = prevProps

    const { newVersionToDownload, newVersionToActivate } = this.props

    if (
      prevNewVersionToDownload !== newVersionToDownload &&
      newVersionToDownload
    ) {
      this.showNewVersionToDownloadToast()
    }

    if (
      prevNewVersionToActivate !== newVersionToActivate &&
      newVersionToActivate
    ) {
      this.showNewVersionToActivateToast()
    }
  }

  showNewVersionToDownloadToast = () => {
    const newVersionToDownloadAlert = (
      <NewVersionAlert
        onClick={downloadAndActivateNewVersion}
        onDismiss={() => this.closeNewVersionToast()}
      />
    )

    this.newVersionToastRef.current = toastNewVersionAlert(
      newVersionToDownloadAlert,
    )
  }

  showNewVersionToActivateToast = () => {
    const activateTheNewVersion = (e) => {
      e.preventDefault()
      e.stopPropagation()
      const { waitingRegistration } = this.props
      waitingRegistration?.postMessage({ type: 'SKIP_WAITING' })
      window.location.reload()
    }

    const newVersionToActivateAlert = (
      <NewVersionAlert
        onClick={activateTheNewVersion}
        onDismiss={() => this.closeNewVersionToast()}
      />
    )

    this.newVersionToastRef.current = toastNewVersionAlert(
      newVersionToActivateAlert,
    )
  }

  closeNewVersionToast = () => {
    snugNotifier.dismiss(this.newVersionToastRef.current)
  }

  renderConnectionStatus() {
    const { dismissConnectionAlert, connectionAlertDismissed, teams } =
      this.props

    // for some reason, we are adding a virtual team for users. so all users all ready have at least 1 team even they are renters
    const isPm = teams.length > 1

    if (connectionAlertDismissed || !isPm || isMobile()) return null

    const offlineAlert = (
      <NetworkStatusAlert
        variant="warningWithBg"
        ctaConfig={networkStatusAlertCTAs.dismiss}
        dismiss={() => dismissConnectionAlert()}
      >
        Offline, some functions disabled
      </NetworkStatusAlert>
    )

    const becameOnlineAlert = (
      <NetworkStatusAlert
        variant="successWithBg"
        ctaConfig={networkStatusAlertCTAs.reload}
      >
        Connection restored
      </NetworkStatusAlert>
    )
    const { connectionStatus } = this.props

    if (
      connectionStatus === connectionStatuses.becameOnline ||
      connectionStatus === connectionStatuses.offline
    ) {
      setTimeout(() => {
        dismissConnectionAlert()
      }, TIME_IN_SECONDS_TO_DISMISS_ALERT * 1000)
    }

    switch (connectionStatus) {
      case connectionStatuses.becameOnline:
        return becameOnlineAlert
      case connectionStatuses.offline:
        return offlineAlert
      default:
        return null
    }
  }

  triggerProductFruits = (data) => {
    const existingUserData = window.productFruitsUser
    const newDataObj = {
      ...(existingUserData?.username && {
        pf_username: existingUserData?.username,
      }),
      ...(existingUserData?.signUpAt && {
        pf_createdAt: existingUserData?.signUpAt,
      }),
      ...(existingUserData?.username && {
        pf_teamGUID: existingUserData?.props?.teamGUID,
      }),
      ...(existingUserData?.isTeamAdmin && {
        pf_isTeamAdmin: existingUserData?.props?.isTeamAdmin,
      }),
      ...(data.username && { pf_username: data.username }),
      ...(data.username && { pf_createdAt: data.createdAt }),
      ...(data.username && { pf_teamGUID: data.teamGUID }),
      ...(data.username && { pf_isTeamAdmin: data.isTeamAdmin }),
    }
    window.dataLayer.push({
      pf_workspaceCode: productFruitWorkspaceCode,
      ...newDataObj,
    })
    window.dataLayer.push({ event: 'pf-user-data-set' })
  }

  render() {
    const {
      globalLoadingActive,
      hasDismissedInactiveTeamAlert,
      dismissTeamInactiveModal,
    } = this.props
    const connectionStatusAlert = this.renderConnectionStatus()
    const { maintenanceMessageConfig, currentTeam } = this.props
    const bannerMessage =
      isMaintenanceWindowEnabled(currentTeam?.slug) &&
      maintenanceMessageConfig?.message
    const showInactiveBanner = !!currentTeam ? !currentTeam.isActive : false
    const bannersCount = [!!bannerMessage, showInactiveBanner].filter(
      Boolean,
    ).length

    const hasBanners = bannersCount > 0

    return (
      <>
        {bannersCount > 0 && (
          <BannersContainer id="app-banners">
            {!!bannerMessage && (
              <Banner
                variant="warningWithBg"
                dangerouslySetInnerHTML={{
                  __html: bannerMessage,
                }}
              />
            )}
            {showInactiveBanner && (
              <Banner variant="warningWithBg">
                <Box>
                  <BannerIcon src={warningImg} />
                </Box>
                <Box ml={3} mt={1}>
                  <TeamActivationMessage
                    hasDismissedInactiveTeamAlert={
                      hasDismissedInactiveTeamAlert
                    }
                    dismissTeamInactiveModal={dismissTeamInactiveModal}
                  />
                </Box>
              </Banner>
            )}
          </BannersContainer>
        )}
        <Box
          style={
            hasBanners
              ? {
                  position: 'relative',
                  height: 'calc(100vh - 56px)',
                }
              : null
          }
        >
          <div>
            {globalLoadingActive && (
              <Box {...globalLoadingStyleProps}>
                <IndeterminateProgressBar height="3px" />
              </Box>
            )}
            <StyledNavbar banners={bannersCount} />
            <StyledAppContent banners={bannersCount} className="app-content">
              <div className="app-content-container">
                <Flex justifyContent="center">{connectionStatusAlert}</Flex>
                {this.props.children}
                <Footer />
              </div>
            </StyledAppContent>
            <div className="pdf-hide">
              <ToastContainerWrapper>
                {/* these default configuration used in the legacy version */}
                <SnugNotifierWrapper />
              </ToastContainerWrapper>
            </div>
          </div>
        </Box>
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
