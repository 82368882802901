import { useState } from 'react'

import {
  createInitialLoadingState,
  createLoadingStateUtils,
  promiseWrapperFactory,
} from 'app/utils/loading-states'

export const useLoadingStates = () => {
  const [loadingStates, setLoadingStates] = useState(
    createInitialLoadingState(),
  )

  const loadingStatesHelpers = createLoadingStateUtils(setLoadingStates)

  const promiseWrapper = promiseWrapperFactory(loadingStatesHelpers)

  return { loadingStates, loadingStatesHelpers, promiseWrapper }
}
