import React from 'react'

const CheckboxOptionContainer = ({ children, enableEdit = true }) => {
  return (
    <div className={`display-flex mb20 ${!enableEdit && 'disabled-link'}`}>
      {children}
    </div>
  )
}

export default CheckboxOptionContainer
