import React from 'react'

import { Form } from 'formik'
import styled from 'styled-components'

import { Flex } from 'app/components/design-system-components'
import ButtonWithLoading from 'app/components/design-system-components/ButtonWithLoading'
import { TelField } from 'app/components/design-system-components/inputs'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { formFields } from 'app/session/login_components/forgotten_email/form.utils'

const StyledFieldWrapper = styled(FieldWrapper)`
  margin-bottom: 25px;
  .iti-arrow {
    display: none !important;
  }
  .flag-container {
    pointer-events: none;
  }
`

const FormBody = (props) => {
  const {
    formBag: { isSubmitting },
  } = props

  return (
    <Form>
      <div className="panel panel-default">
        <div className="panel-body">
          <StyledFieldWrapper
            id={formFields.mobile}
            label="Your registered mobile"
            name={formFields.mobile}
            required
          >
            <TelField
              name={formFields.mobile}
              placeholder="e.g +61 412 345 678"
              onlyCountries={['au']}
              defaultCountry={'au'}
            />
          </StyledFieldWrapper>

          <Flex>
            <ButtonWithLoading
              height="50px"
              width="100%"
              loading={isSubmitting}
              type="submit"
            >
              Send SMS
            </ButtonWithLoading>
          </Flex>
        </div>
      </div>
    </Form>
  )
}

export default FormBody
