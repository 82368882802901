import { connect } from 'react-redux'

import {
  fetchCards,
  fetchRentalHistory,
} from 'app/bond_cover/bond_cover_actions'
import Profile from 'app/session/bc_profile/profile_container'
import {
  addRentalReputationBackUrl,
  clearRentalBackUrl,
  setBackUrl,
} from 'app/shared_components/actions'

const mapStateToProps = ({ session, bondCover }) => ({
  currentUser: session.currentUser,
  rentalHistory: bondCover.rentalHistory,
  paymentOptions: bondCover.paymentOptions,
})

const mapDispatchToProps = (dispatch) => ({
  fetchRentalHistory: () => dispatch(fetchRentalHistory()),
  fetchCards: () => fetchCards(),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
  addRentalReputationBackUrl: (url) =>
    dispatch(addRentalReputationBackUrl(url)),
  clearRentalBackUrl: () => dispatch(clearRentalBackUrl()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
