import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { Box, Dropdown, Flex } from 'app/components/design-system-components'
import { SearchRounded } from 'app/components/design-system-components/icons/actions'
import { ArrowDropDownRounded } from 'app/components/design-system-components/icons/navigation'
import { Input } from 'app/components/design-system-components/inputs'
import { CheckboxesList } from 'app/components/design-system-components/list-filters/CheckboxesList'
import { DropdownBodySectionHeader } from 'app/components/design-system-components/list-filters/DropdownBodySectionHeader'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  DropdownBodyWrapper,
  StyledDropdownButton,
} from 'app/sm/team_viewing_preference/states_dropdown'

const StyledBodyWrapper = styled(DropdownBodyWrapper)`
  max-height: ${theme.space[15]}px;
  overflow-y: auto;
`

const StyledNoResultsFound = styled(Box)`
  font-size: ${theme.fontSizes[5]}px;
  font-weight: ${theme.fontWeights[3]};
  color: ${theme.colors.pdfGray};
  padding-left: ${theme.space[8]}px;
  margin-bottom: ${theme.space[5]}px;
`

const memberToOption = (member) => ({
  option: member,
  optionMeta: {
    id: member.contact.guidID,
    displayText: `${member.contact.firstName} ${member.contact.lastName}`,
  },
})

const MembersDropdown = ({ onMembersChanged, teamMembers = [] }) => {
  const [selectedInspectors, setSelectedInspectors] = useState({})
  const [membersSearch, setMembersSearch] = useState('')

  useEffect(() => {
    const me = teamMembers.find((t) => t.isMe)
    if (me) {
      setSelectedInspectors({
        [me.contact.guidID]: memberToOption(me),
      })
    }
  }, [teamMembers])

  useEffect(() => {
    onMembersChanged(Object.keys(selectedInspectors))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInspectors])

  const onOptionClicked = (checked, selected) => {
    const tmp = { ...selectedInspectors }

    if (!checked) {
      delete tmp[selected.option.contact.guidID]
    } else {
      tmp[selected.option.contact.guidID] = selected
    }

    setSelectedInspectors(tmp)

    onMembersChanged(Object.keys(tmp))
  }

  const membersOptions = teamMembers.map((member) => memberToOption(member))
  const filteredTeamMembers = !membersSearch
    ? membersOptions
    : membersOptions.filter((option) =>
        option.optionMeta.displayText
          .toLowerCase()
          .includes(membersSearch.toLowerCase()),
      )

  const dropdownLabel = () => {
    const values = Object.values(selectedInspectors)

    if (values.length === 0) return 'Select Team Member'

    return (
      values[0].optionMeta.displayText +
      `${values.length > 1 ? ` (+${values.length - 1})` : ''}`
    )
  }

  return (
    <Dropdown
      triggerWrapperComponent={({ dropdownControls }) => (
        <StyledDropdownButton
          variant="outline"
          onClick={() => dropdownControls.toggle()}
          minWidth={`${theme.space[14]}px`}
          height={`${theme.space[13]}px`}
        >
          <Flex justifyContent="space-between">
            <Box>{dropdownLabel()}</Box>
            <Box
              as={ArrowDropDownRounded}
              ml={theme.space[3] + 'px'}
              fontSize={theme.fontSizes.pExtraLarge18}
            />
          </Flex>
        </StyledDropdownButton>
      )}
      dropdownComponent={() => (
        <StyledBodyWrapper>
          <Box p={theme.space[4] + 'px'}>
            <Input
              icon={<SearchRounded style={{ color: theme.colors.gray400 }} />}
              inputProps={{
                onChange: (event) => setMembersSearch(event.target.value),
                value: membersSearch,
                placeholder: 'Search for team member',
              }}
            />
          </Box>
          <DropdownBodySectionHeader
            header="Team Members"
            containerProps={{
              mb: theme.space[4] + 'px',
              className: 'dropdown-header',
            }}
          />
          {filteredTeamMembers && filteredTeamMembers.length > 0 ? (
            <CheckboxesList
              options={filteredTeamMembers}
              checkedOptions={selectedInspectors}
              onCheckBoxClicked={onOptionClicked}
              containerProps={{
                className: 'dropdown-list',
              }}
            />
          ) : (
            <StyledNoResultsFound>No results</StyledNoResultsFound>
          )}
        </StyledBodyWrapper>
      )}
      topSpaceInPx={9}
    />
  )
}

export default MembersDropdown
