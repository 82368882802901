import React from 'react'

import { Route } from 'react-router-dom'

import Payment from 'app/sm/advertise_flow/components/payment_connection'
import Portals from 'app/sm/advertise_flow/components/portals_connection'
import PropertyForm from 'app/sm/advertise_flow/components/property_form_connection'

class AdWorkflow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    // if (error) {
    //   return (
    //     <div className='alert alert-danger disposable-alert'>
    //       <i onClick={() => this.props.ping()} className="fa fa-chevron-left mr15" aria-hidden="true" />{error}
    //     </div>
    //   )
    // }

    return (
      <div className="profile">
        {['/sm/advertise'].map((path) => (
          <div key={path}>
            <Route
              exact
              path={`${path}/description/:propertyId`}
              render={({ match }) => {
                return <PropertyForm component="description" match={match} />
              }}
            />
            <Route
              exact
              path={`${path}/amenities/:propertyId`}
              render={({ match }) => {
                return <PropertyForm component="ammenities" match={match} />
              }}
            />
            <Route
              exact
              path={`${path}/portals/:propertyId/:offerId`}
              render={({ match }) => {
                return <Portals component="portals" match={match} />
              }}
            />
            <Route
              exact
              path={`${path}/pay/:propertyId/:offerId`}
              render={({ match }) => {
                return <Payment component="payment" match={match} />
              }}
            />
          </div>
        ))}
      </div>
    )
  }
}

export default AdWorkflow
