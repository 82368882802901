import qs from 'qs'

import { LogFetchError } from 'app/shared_components/helpers'
import * as sharedHelpers from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getCurrentTenants = (teamId, propertyId) => {
  const url = `${apiBaseUrl}sm/properties/${propertyId}/tenants`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => sharedHelpers.LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const getCurrentOwners = (teamId, propertyId) => {
  const url = `${apiBaseUrl}sm/teams/${teamId}/contacts/sm_property/${propertyId}/owner`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => sharedHelpers.LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const deleteCurrentTenant = (propertyId, tenantId) => {
  const url = `${apiBaseUrl}sm/properties/${propertyId}/tenants/${tenantId}`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then(() => ({ ok: true, responseText: '' }), sharedHelpers.returnAPIError)
}

// The other instance of this function shouldn't be deleted in the contact, just the owner relationship
export const deleteCurrentOwner = (teamId, contactId, propertyId) => {
  const url = `${apiBaseUrl}sm/teams/${teamId}/contacts/${contactId}/${propertyId}/owner`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then(() => ({ ok: true, responseText: '' }), sharedHelpers.returnAPIError)
}

export const getAgencyApplicantsElasticSearchResult = (teamId, queryString) => {
  const url = `${apiBaseUrl}sm/teams/${teamId}/search?q=${queryString}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const getEnquirers = (teamId, propertyId) => {
  const url = `${apiBaseUrl}sm/teams/${teamId}/property/${propertyId}/recentenquiries`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getPropertyViewings = ({
  propertyId,
  startDate,
  endDate,
  searchText,
}) => {
  const query = qs.stringify(
    { startTime: startDate, endTime: endDate, q: searchText },
    { addQueryPrefix: true },
  )
  const url = `${apiBaseUrl}sm/properties/${propertyId}/viewings${query}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((viewings) => ({ ok: true, viewings }), sharedHelpers.returnAPIError)
}

export const sendEntryNotice = (offerId, data) => {
  const url = `${apiBaseUrl}sm/offers/${offerId}/entrynotice`
  const options = {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(data),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then(() => ({ ok: true }), sharedHelpers.returnAPIError)
}

export const editCurrentTenants = (propertyId, tenantId, data) => {
  const url = `${apiBaseUrl}sm/properties/${propertyId}/tenants/${tenantId}`
  const options = {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(data),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const editCurrentOwners = (teamId, ownerId, data) => {
  const url = `${apiBaseUrl}sm/teams/${teamId}/contacts/${ownerId}`
  const options = {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(data),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const createCurrentTenants = (data, propertyId) => {
  const url = `${apiBaseUrl}sm/properties/${propertyId}/tenants`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(data),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const createCurrentOwners = (data, teamId) => {
  const url = `${apiBaseUrl}sm/teams/${teamId}/contacts`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(data),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const createWalkInRenter = (data, viewingGUID) => {
  const url = `${apiBaseUrl}sm/viewing/${viewingGUID}/onsiteregistrant`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(data),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            statusText: res.statusText,
            responseText: res.text(),
          }),
    )
    .then(
      (registrant) => ({ ok: true, registrant }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const alterRegisteredRenter = (
  payload,
  renterType,
  id,
  registrationGUID,
) => {
  const url =
    renterType === 'renter'
      ? `${apiBaseUrl}sm/viewing/${id}/viewingregistration/${registrationGUID}`
      : `${apiBaseUrl}sm/viewing/${id}/onsiteregistrant/${registrationGUID}`

  return fetch(url, {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deleteOnsiteViewingRenter = (viewingGUID, registrationGUID) => {
  const url = `${apiBaseUrl}sm/viewing/${viewingGUID}/onsiteregistrant/${registrationGUID}`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true, responseText: '' }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const sendLateViewingNotification = (data, viewingGUID) => {
  const url = `${apiBaseUrl}sm/viewing/${viewingGUID}/latenotification`
  const payload = {
    lateTime: Number(data),
  }
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            statusText: res.statusText,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const sendApplyViewingNotification = (viewingGUID) => {
  const url = `${apiBaseUrl}sm/viewing/${viewingGUID}/applynotification`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            statusText: res.statusText,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const sendIndividualApplyViewingNotification = (
  viewingGUID,
  registrationGUID,
) => {
  const url = `${apiBaseUrl}sm/viewing/${viewingGUID}/viewingregistrant/${registrationGUID}/invite`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            statusText: res.statusText,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const alterViewing = (viewingGUID) => {
  const url = `${apiBaseUrl}sm/viewing/${viewingGUID}/cancel`

  return fetch(url, {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({}),
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getQuestionFeedback = () => {
  const url = `${apiBaseUrl}sm/viewing/feedbackquestions`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (feedbackQuestions) => ({ ok: true, feedbackQuestions }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const sendFeedbackRequest = (viewingGUID) => {
  const url = `${apiBaseUrl}sm/viewing/${viewingGUID}/requestfeedback`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            statusText: res.statusText,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const sendViewingFeedback = (data, viewingGUID, feedbackGUID) => {
  const url = `${apiBaseUrl}sm/viewing/${viewingGUID}/feedback?secret=${feedbackGUID}`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(data),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            statusText: res.statusText,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const getViewingRequestStatus = (viewingGUID, feedbackGUID) => {
  const url = `${apiBaseUrl}sm/viewing/${viewingGUID}/feedbackrequest/${feedbackGUID}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (feedbackStatus) => ({ ok: true, feedbackStatus }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const renterUpdateOnsiteViewingRegistrant = (registrationGUID) => {
  const url = `${apiBaseUrl}sm/onsiteregistrant/${registrationGUID}/renter`

  return fetch(url, {
    method: 'PUT',
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (feedbackStatus) => ({ ok: true, feedbackStatus }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getPropertyAndFeedbackRequestFromShortCode = (shortCode) => {
  const url = `${apiBaseUrl}sm/feedbackrequest/${shortCode}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const sendSmsReportToOwner = (viewingID, mobileNumber) => {
  const url = `${apiBaseUrl}sm/viewing/${viewingID}/sendsmsreport`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({ mobileNumbers: mobileNumber }),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            statusText: res.statusText,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const getViewingsByDate = (teamId, filters) => {
  const { manager = '', searchText = '' } = filters || {}
  const url = `${apiBaseUrl}sm/teams/${teamId}/viewings?address=&manager=${manager}&q=${searchText}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}
