import { LogFetchError } from 'app/shared_components/helpers'
import * as sharedHelpers from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const postViewingPreference = (teamId, preferenceInfo) => {
  const url = `${apiBaseUrl}sm/teams/${teamId}/preferences/viewings`
  const options = {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(preferenceInfo),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getViewingPreference = (teamId, propertyGUID) => {
  let url = `${apiBaseUrl}sm/teams/${teamId}/preferences/viewings`
  if (propertyGUID !== '') {
    url = `${url}?property=${propertyGUID}`
  }
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (preferenceInfo) => ({ ok: true, preferenceInfo }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getAgencyStatusReport = (agencyGUID) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/preferences/status`

  const options = {
    method: 'GET',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const getAgencyMembersList = (agencyGUID) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/preferences/members`

  const options = {
    method: 'GET',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const getListCalendar = (agencyGUID) => {
  const url = `${apiBaseUrl}userprofile/calendars/${agencyGUID}`

  const options = {
    method: 'GET',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (calendarList) => ({ ok: true, calendarList }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deleteListCalendar = (platform, calendarGUID) => {
  const url = `${apiBaseUrl}userprofile/calendars/${platform}/${calendarGUID}`

  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
