import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const fetchAgencyRequests = (searchType, manager, agencyID, cursor) => {
  let url = ''
  const cursorResult = cursor || ''
  if (searchType === 4) {
    url = `${apiBaseUrl}agencies/${agencyID}/bc/requests`
  } else {
    url = `${apiBaseUrl}agencies/${agencyID}/bc/requests?filterType=${searchType}&manager=${manager}&cursor=${cursorResult}`
  }

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (res) => ({ ok: true, ...res }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const searchAgencyRequests = (
  filterType,
  manager,
  addressSearchText,
  agencyID,
  cursor,
) => {
  const cursorResult = cursor || ''
  const url = `${apiBaseUrl}agencies/${agencyID}/bc/requests/search?addresssearchtext=${addressSearchText}&filtertype=${filterType}&cursor=${cursorResult}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (res) => ({ ok: true, ...res }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
