import React from 'react'

import $ from 'jquery'
import moment from 'moment'
import styled from 'styled-components'

import BallotDetails from 'app/components/Ballot/Details'
import DefaultButton from 'app/components/buttons/default/component'
import { Box, Flex } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import { SectionHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import * as Form from 'app/components/forms/forms'
import {
  rentFrequencyDBKey,
  rentFrequencyOpenOption,
} from 'app/constants/application-settings.constants'
import theme from 'app/match/applicationReportPDF/assets/theme'
import DatePicker from 'app/shared_components/date_picker'
import RentOfferDeclarationModal from 'app/sm/apply/components/RentOfferDeclarationModal'
import {
  StyledVehicleContainer,
  StyledVehicleInfo,
} from 'app/sm/apply/components/styles/Vehicles'
import {
  constructAdderEntityFromDate,
  constructAvailableLeaseLengthFromAdderEntity,
  constructDateFromAdderEntity,
  convertMoveInDate,
  formatRent,
  isStatusDeclined,
  isStatusNew,
  isStatusOffered,
} from 'app/sm/helpers'
import * as vehicleHelpers from 'app/utils/applications/vehicles'
import * as textHelpers from 'app/utils/text/helpers'

const OFFER_ACTOIN_TYPE = {
  MOVE_IN_DATE: 'moveInDate',
  INCOME: 'income',
  RENT: 'rent',
  TERM: 'term',
  VEHICLES: 'vehicles',
}

const StyledSolicitedToOfferIncreasedRent = styled(Box)`
  margin-top: -${theme.space[9]}px;
  @media screen and (max-width: 576px) {
    margin-top: -${theme.space[7]}px;
  }
`

class YourOffer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMoveInDatePickerVisible: false,
      plusRentActionPerformed: false,
      initialRent: 0,
      showRentOfferDeclarationModal: false,
      initialRentOfferDeclarationShown: false,
      // allVehicles: [],
    }
  }

  componentDidMount() {
    const { property = {} } = this.props
    const { offers = [] } = property
    const activeOffer = offers?.find((offer) => offer.isActive === true)
    const { weeklyRent } = activeOffer || {}
  }

  componentDidUpdate(prevProps) {
    const {
      property = {},
      onLeaseTermBoxChanged,
      applicationStatus,
      application,
      solicitedAbovePrice,
    } = this.props
    const {
      adders: { rent, term, moveInDate, vehicles },
      updateSolicitedAbovePrice,
    } = this.props
    if (
      prevProps.property !== property ||
      prevProps.application !== application
    ) {
      const { availableLeaseLength } = this.state
      const isNew = isStatusNew(applicationStatus)
      let offer
      if (isNew) {
        offer = (property.offers || []).find((o) => o.isActive) || {}
      } else {
        offer = (application || {}).offer || {}
      }
      const { ballotsEnabled = false, weeklyRent } = offer || {}
      if (ballotsEnabled) {
        onLeaseTermBoxChanged(textHelpers.rentalTermForBallotApplications)
      }
      this.setState({ initialRent: weeklyRent })
    }
    if (prevProps?.adders?.rent !== rent) {
      const { initialRent } = this.state
      if (rent.quantity > initialRent && initialRent !== 0) {
        updateSolicitedAbovePrice({
          ...solicitedAbovePrice,
          isAboveDefaultPrice: true,
          isSolicitedAbovePriceChecked: true,
        })
        this.setState({
          showRentOfferDeclarationModal: true,
        })
      } else {
        updateSolicitedAbovePrice({
          isAboveDefaultPrice: false,
          isSolicitedAbovePriceChecked: false,
        })
        this.setState({
          initialRentOfferDeclarationShown: false,
          showRentOfferDeclarationModal: false,
        })
      }
    }
  }

  toggleRentOfferDeclarationModal = () => {
    const { showRentOfferDeclarationModal } = this.state
    this.setState({
      showRentOfferDeclarationModal: !showRentOfferDeclarationModal,
    })
  }

  resetRentToInitialRent = () => {
    const { initialRent } = this.state
    const { updateSolicitedAbovePrice } = this.props
    this.onAdderChange(OFFER_ACTOIN_TYPE.RENT, 'update', {
      measure: '$',
      quantity: initialRent,
    })
    updateSolicitedAbovePrice({
      isAboveDefaultPrice: false,
      isSolicitedAbovePriceChecked: false,
    })
    this.toggleRentOfferDeclarationModal()
  }

  onAdderChange = (
    adderType,
    operation,
    payload = {},
    availableDate,
    moveInDateConverted = '',
  ) => {
    const { applicationStatus, isApplyAnywhere, income } = this.props
    const { allVehicles, updateVehicleNumber } = this.props

    const isDateAfterToday = moment(moveInDateConverted).isAfter(
      moment(availableDate),
      'day',
    )
    const isMinusDateAction =
      adderType === OFFER_ACTOIN_TYPE.MOVE_IN_DATE && operation === '-'
    const isIncomeAction = adderType === OFFER_ACTOIN_TYPE.INCOME

    const isMinusVehiclesAction =
      adderType === OFFER_ACTOIN_TYPE.VEHICLES && operation === '-'
    const isPlusVehiclesAction =
      adderType === OFFER_ACTOIN_TYPE.VEHICLES && operation === '+'

    const isPlusRentAction =
      adderType === OFFER_ACTOIN_TYPE.RENT && operation === '+'
    this.props.setUnsavedChanges()
    if (isMinusDateAction) {
      isDateAfterToday &&
        !isStatusOffered(applicationStatus) &&
        !isStatusDeclined(applicationStatus) &&
        this.props.onAdderChange(adderType, operation, payload, isApplyAnywhere)
      return
    }
    if (isIncomeAction) {
      if (operation === '+') {
        this.updateIncome({ value: income + 5 })
        return
      }
      if (operation === '-') {
        const updateValue = income - 5 >= 0 ? income - 5 : 0
        this.updateIncome({ value: updateValue })
      }
      return
    }
    !isStatusOffered(applicationStatus) &&
      !isStatusDeclined(applicationStatus) &&
      this.props.onAdderChange(adderType, operation, payload, isApplyAnywhere)

    if (isPlusVehiclesAction) {
      const vehicles = [...allVehicles, { ...vehicleHelpers.vehicleObject }]
      updateVehicleNumber(vehicles)
    }
    if (isMinusVehiclesAction) {
      if (allVehicles.length > 0) {
        const vehicles = [...allVehicles]
        vehicles.splice(-1)
        updateVehicleNumber(vehicles)
      }
    }
    if (isPlusRentAction) {
      this.setState({ plusRentActionPerformed: true })
    }
  }

  formatTip(tip, payload) {
    const otherOffersLabel = (val) => `Other Offers ${val}`
    const formatDate = (date) => moment(date).format('MMM D')
    if (!payload) {
      return ''
    }
    switch (tip) {
      case OFFER_ACTOIN_TYPE.RENT:
        return otherOffersLabel(`$${payload.min} - $${payload.max}`)
      case OFFER_ACTOIN_TYPE.TERM:
        return otherOffersLabel(`${payload.min}m - ${payload.max}m`)
      case OFFER_ACTOIN_TYPE.MOVE_IN_DATE:
        return otherOffersLabel(
          `${formatDate(payload.min)} - ${formatDate(payload.max)}`,
        )
      default:
        return ''
    }
  }

  moveInDateClicked() {
    if (this.props.isApplicationEditable || this.props.isNewApplication) {
      this.setState({ isMoveInDatePickerVisible: true }, () => {
        this.props.setUnsavedChanges()
        $('.apply-date-picker-wrapper').click((e) => {
          if (
            !$(e.target).closest('.rdtYears').length &&
            !$(e.target).closest('.rdtMonths').length &&
            !$(e.target).closest('.rdtDays').length
          ) {
            this.setState({ isMoveInDatePickerVisible: false })
          }
        })
      })
    }
  }

  updateIncome = (data) => {
    const { onIncomeSetByManagerUpdated } = this.props
    if (Number(data.value) < 100000000000) {
      onIncomeSetByManagerUpdated(Number(data.value))
    }
  }

  updateMoveInDate(date) {
    const addersFormat = constructAdderEntityFromDate(date.format('YYYY-MM-DD'))
    this.onAdderChange(OFFER_ACTOIN_TYPE.MOVE_IN_DATE, 'update', {
      year: addersFormat.year,
      measure: addersFormat.measure,
      quantity: addersFormat.quantity,
    })
    this.setState({ isMoveInDatePickerVisible: false })
    this.props.setUnsavedChanges()
  }

  updateRent = (data) => {
    if (Number(data.value) < 100000000000) {
      this.onAdderChange(OFFER_ACTOIN_TYPE.RENT, 'update', {
        measure: '$',
        quantity: Number(data.value),
      })
    }
  }

  updateVehicles = (data) => {
    if (Number(data.value) < 100000000000) {
      this.onAdderChange(OFFER_ACTOIN_TYPE.VEHICLES, 'update', {
        measure: '$',
        quantity: Number(data.value),
      })
    }
  }

  // onChangeVehicleRegistrationType = (e) => {
  //   this.props.changeVehicleRegistrationType(e.value)
  // }

  // onChangeVehicleRegistrationText = (e) => {
  //   this.props.changeVehicleRegistrationText(e.value)
  // }

  updateNotSolicitedToOfferIncreasedRent = (value) => {
    this.setState({
      notSolicitedToOfferIncreasedRent: value,
    })
  }

  render() {
    const {
      adders: { rent, term, moveInDate, vehicles },
      property,
      application,
      applicationStatus,
      otherOffers,
      isApplicationEditable,
      onLeaseTermBoxChanged,
      isApplyAnywhere,
      isNewApplication,
      applicant,
      preferredRentScheduleOptions,
      updatePreferredRentSchedule,
      requestCarSpaceOptions,
      updateRequestCarSpace,
      parsedApplicationSettingInfo = {},
      income,
      isApplicationCreatedByManager,
      onRemoveVehicleButtonClick,
      onChangeVehicleDetails,
      allVehicles,
      ballotsHouseholdIncomeLimit,
      ballotsHouseholdIncomeRegion,
      ballotDisclosuresAnswers,
      solicitedAbovePrice,
      updateSolicitedAbovePrice,
      excludeRequestVehicleRegistration,
    } = this.props

    const { showRentOfferDeclarationModal, initialRentOfferDeclarationShown } =
      this.state

    const {
      isAboveDefaultPrice = false,
      isSolicitedAbovePriceChecked = false,
    } = solicitedAbovePrice || {}
    const allowRentCycleSelection =
      parsedApplicationSettingInfo[rentFrequencyDBKey] ===
      rentFrequencyOpenOption
    const { request_car_space: requestCarSpace } = parsedApplicationSettingInfo
    const { isMoveInDatePickerVisible } = this.state
    const moveInDateParsed = constructDateFromAdderEntity(moveInDate)
    const moveInDateConverted = convertMoveInDate(moveInDate)
    const isNewApplyAnywhere = isNewApplication && isApplyAnywhere

    const tips = {
      rent: isNewApplyAnywhere
        ? 0
        : otherOffers &&
          this.formatTip(
            OFFER_ACTOIN_TYPE.RENT,
            otherOffers[OFFER_ACTOIN_TYPE.RENT],
          ),
      term:
        otherOffers &&
        this.formatTip(
          OFFER_ACTOIN_TYPE.TERM,
          otherOffers[OFFER_ACTOIN_TYPE.TERM],
        ),
      moveInDate:
        otherOffers &&
        this.formatTip(
          OFFER_ACTOIN_TYPE.MOVE_IN_DATE,
          otherOffers[OFFER_ACTOIN_TYPE.MOVE_IN_DATE],
        ),
    }

    const isNew = isStatusNew(applicationStatus)
    let offer
    if (isNew) {
      offer = (property.offers || []).find((o) => o.isActive) || {}
    } else {
      offer = (application || {}).offer || {}
    }
    const availableDate = moment(offer.availableFrom).format('DD MMM YYYY')
    const { weeklyRentDisplay = '', ballotsEnabled = false } = offer || {}
    const formattedRent = formatRent(weeklyRentDisplay)
    const titleForRent = isApplyAnywhere
      ? `Rent p/week`
      : `Advert Rent: ${formattedRent}`
    const titleForDate = `Lease Start`

    const showTooltipInAdvertRent = !isApplyAnywhere

    const startDate = moment().isSameOrAfter(moment(offer.availableFrom))
      ? moment()
      : moment(offer.availableFrom)

    const oneDayBeforeStartDate = startDate.subtract(1, 'day').format('L')
    const oneYearAfterStartDate = startDate.add(1, 'years').format('L')
    const validDate = (current) => {
      return (
        current.isAfter(oneDayBeforeStartDate) &&
        current.isBefore(oneYearAfterStartDate)
      )
    }

    const { vehicleType, vehicleRegistrationType, vehicleRegistrationText } =
      application

    const availableLeaseLength =
      constructAvailableLeaseLengthFromAdderEntity(term)

    const pickedLeaseLengths =
      availableLeaseLength &&
      availableLeaseLength.filter((leaseLength) => leaseLength.picked)
    const pickedLeaseLengthIsOnly12 =
      pickedLeaseLengths &&
      pickedLeaseLengths.every((leaseLength) => leaseLength.length === 12)
    const isWeeklyDisplayRentDefaultDisplayPrice =
      weeklyRentDisplay === textHelpers.DefaultDisplayPrice

    let allowRentEdit =
      (isApplicationEditable || isNewApplication) && !ballotsEnabled
    if (isNewApplyAnywhere) {
      allowRentEdit = true
    }

    const carSpaceQuestionText = ballotsEnabled
      ? 'Do you have a vehicle?'
      : 'Would you like a car space? (additional charges may apply)'

    return (
      <div className="sm-apply__your-offer">
        <div className="col-first p24 bbs">
          {ballotsEnabled && (
            <BallotDetails
              formRef={this.props.formRef}
              ballotsHouseholdIncomeLimit={ballotsHouseholdIncomeLimit}
              ballotsHouseholdIncomeRegion={ballotsHouseholdIncomeRegion}
              ballotDisclosuresAnswers={ballotDisclosuresAnswers}
            />
          )}
          <SectionHeader
            text="My Rental Preferences"
            hasSeperator={false}
            textClass="mobile-apply-padding"
          />
          {!ballotsEnabled && (
            <Form.MultiSelect
              className="big pb0 mobile-apply-padding "
              title={textHelpers.APPLICATION_LEASE_TERM_TITLE}
              options={availableLeaseLength}
              onChange={onLeaseTermBoxChanged}
              isEditable={isApplicationEditable || isNewApplication}
              extraText="m"
            />
          )}
          <Form.DigitAdderWidget
            value={moveInDate.quantity}
            extraText={moveInDate.measure}
            onAdd={() =>
              this.onAdderChange(
                OFFER_ACTOIN_TYPE.MOVE_IN_DATE,
                '+',
                null,
                availableDate,
                moveInDateConverted,
              )
            }
            onMinus={() =>
              this.onAdderChange(
                OFFER_ACTOIN_TYPE.MOVE_IN_DATE,
                '-',
                null,
                availableDate,
                moveInDateConverted,
              )
            }
            text={titleForDate}
            leftIcon="-"
            rightIcon="+"
            size="big"
            isEditable={isApplicationEditable || isNewApplication}
            isInputAllowed={true}
            click={() => this.moveInDateClicked()}
            componentClass="mt0 pt0 household-input-bottom-spacing mobile-apply-padding"
          />

          <Form.DigitAdderWidget
            text={titleForRent}
            value={rent.quantity}
            onChange={this.updateRent}
            onAdd={() => this.onAdderChange(OFFER_ACTOIN_TYPE.RENT, '+')}
            onMinus={() => this.onAdderChange(OFFER_ACTOIN_TYPE.RENT, '-')}
            leftIcon="-"
            rightIcon="+"
            showControls={
              !ballotsEnabled
                ? { minus: true, plus: false }
                : { minus: false, plus: false }
            }
            size="big"
            isInputAllowed={!ballotsEnabled}
            isEditable={allowRentEdit}
            componentClass={`${
              isApplicationCreatedByManager
                ? 'mt0 pt0 household-input-bottom-spacing mobile-apply-padding'
                : 'household-input-bottom-spacing mobile-apply-padding'
            }`}
            showTooltip={true}
            tooltipId="advertisedRent"
            tooltipMessage={textHelpers.ADVERTISED_RENT_TOOLTIP_TEXT}
            showEditIcon={allowRentEdit}
          />
          {isAboveDefaultPrice && (
            <StyledSolicitedToOfferIncreasedRent>
              <Form.CheckBoxGeneral
                label="I have not been solicited to offer rent above the advertised price"
                id="notSolicitedToOfferIncreasedRent"
                onChange={(e) =>
                  updateSolicitedAbovePrice({
                    ...solicitedAbovePrice,
                    isSolicitedAbovePriceChecked: e.value,
                  })
                }
                checked={isSolicitedAbovePriceChecked}
                enableEdit={allowRentEdit}
                checkboxLabelSpanClassName="fs16 height-auto"
                componentClassName="pt0 household-input-bottom-spacing mobile-apply-padding"
              />
            </StyledSolicitedToOfferIncreasedRent>
          )}
          {isApplicationCreatedByManager && (
            <Form.DigitAdderWidget
              text={'Income Weekly (After Tax)'}
              value={income}
              onChange={this.updateIncome}
              onAdd={() => this.onAdderChange(OFFER_ACTOIN_TYPE.INCOME, '+')}
              onMinus={() => this.onAdderChange(OFFER_ACTOIN_TYPE.INCOME, '-')}
              leftIcon="-"
              rightIcon="+"
              size="big"
              componentClass="mt0 pt0 p24 household-input-bottom-spacing mobile-apply-padding"
              isInputAllowed={true}
              isEditable={isApplicationEditable || isNewApplication}
            />
          )}
          {allowRentCycleSelection && (
            <Form.MultiSelect
              className="big pb0 mobile-apply-padding "
              title={textHelpers.RENT_SCHEDULE_SECTION_TITLE}
              options={preferredRentScheduleOptions}
              onChange={updatePreferredRentSchedule}
              isEditable={isApplicationEditable || isNewApplication}
            />
          )}
          {requestCarSpace && (
            <Form.MultiSelect
              className="big pb0 mobile-apply-padding "
              title={carSpaceQuestionText}
              options={requestCarSpaceOptions}
              onChange={updateRequestCarSpace}
              isEditable={isApplicationEditable || isNewApplication}
            />
          )}
          {requestCarSpace && (
            <>
              <Form.DigitAdderWidget
                text="Vehicles"
                value={allVehicles.length}
                onAdd={() =>
                  this.onAdderChange(OFFER_ACTOIN_TYPE.VEHICLES, '+')
                }
                onMinus={() =>
                  this.onAdderChange(OFFER_ACTOIN_TYPE.VEHICLES, '-')
                }
                leftIcon="-"
                rightIcon="+"
                isInputAllowed={true}
                isEditable={isApplicationEditable || isNewApplication}
                componentClass={`${
                  isApplicationCreatedByManager
                    ? 'mt0 pt0 household-input-bottom-spacing mobile-apply-padding pb20'
                    : 'household-input-bottom-spacing pb20'
                }`}
                showEditIcon={false}
              />
              {allVehicles && allVehicles.length > 0 && (
                <>
                  {allVehicles &&
                    allVehicles.map((vehicleInfo, nth) => {
                      return (
                        <StyledVehicleContainer>
                          <StyledVehicleInfo>
                            {allVehicles[nth] && (
                              <div key={nth}>
                                <Flex
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <p>Vehicle {nth + 1}</p>
                                  <DefaultButton
                                    text="Remove"
                                    componentClass="remove-tenant-record destructive-hollow"
                                    size="small"
                                    onClick={() =>
                                      onRemoveVehicleButtonClick(nth)
                                    }
                                    buttonType="destructive"
                                  />
                                </Flex>
                                <Flex>
                                  <Display.GreyBodyText
                                    text="Type"
                                    textClass="font-size-18"
                                    componentClass="mb10 mt10 width-50"
                                  />
                                  {!excludeRequestVehicleRegistration && (
                                    <Display.GreyBodyText
                                      text="Registration Number"
                                      textClass="font-size-18"
                                      componentClass="mb10 mt10 width-50"
                                    />
                                  )}
                                </Flex>
                                <Flex alignItems="center">
                                  <Form.Dropdown
                                    placeholder="Team contact"
                                    componentClass=" width-50 pt8"
                                    value={
                                      vehicleInfo[
                                        vehicleHelpers.VEHICLES_KEY_TYPE
                                      ]
                                    }
                                    inputBoxClass="pt0"
                                    options={vehicleHelpers.vehicleOptions}
                                    onChange={(event) =>
                                      onChangeVehicleDetails(
                                        event,
                                        vehicleHelpers.VEHICLES_KEY_TYPE,
                                        nth,
                                      )
                                    }
                                    id="relationship"
                                    idAsValue
                                  />
                                  <>
                                    {!excludeRequestVehicleRegistration && (
                                      <Form.InputTextRequired
                                        value={
                                          vehicleInfo[
                                            vehicleHelpers
                                              .VEHICLES_KEY_REG_NUMBER
                                          ] || ''
                                        }
                                        onChange={(event) =>
                                          onChangeVehicleDetails(
                                            event,
                                            vehicleHelpers.VEHICLES_KEY_REG_NUMBER,
                                            nth,
                                          )
                                        }
                                        id={
                                          vehicleHelpers.VEHICLES_KEY_REG_NUMBER
                                        }
                                        label="Registration Number"
                                        disabled={
                                          vehicleInfo[
                                            vehicleHelpers
                                              .VEHICLES_KEY_REG_NUMBER
                                          ] === 'Unregistered'
                                        }
                                      />
                                    )}
                                  </>
                                </Flex>
                              </div>
                            )}
                          </StyledVehicleInfo>
                        </StyledVehicleContainer>
                      )
                    })}
                </>
              )}
            </>
          )}
        </div>

        {isMoveInDatePickerVisible && (
          <div className="apply-date-picker-wrapper">
            <DatePicker
              label="Lease start date"
              inputProps={{ placeholder: 'dd/mm/yyyy' }}
              viewMode="days"
              isActive={isMoveInDatePickerVisible}
              value={moment(moveInDateParsed).format('DD/MM/YYYY')}
              onChange={(date) => this.updateMoveInDate(date)}
              isValidDate={validDate}
            />
          </div>
        )}
        {showRentOfferDeclarationModal && !initialRentOfferDeclarationShown && (
          <RentOfferDeclarationModal
            toggleRentOfferDeclarationModal={() => {
              this.setState({
                initialRentOfferDeclarationShown: true,
              })
            }}
            resetRentToInitialRent={this.resetRentToInitialRent}
          />
        )}
      </div>
    )
  }
}

export default YourOffer
