import { connect, MapDispatchToProps } from 'react-redux'
import type { Dispatch } from 'redux'

import * as agencyAction from 'app/agency/agency_action'
import StreamLineSSTeamContainer from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_container'
import type { StreamLineRegisterPayload } from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_type'

const mapStateToProps = ({}) => ({})

interface DispatchProps {
  searchAgencyDomain: AsyncFunction<string>
  registerTeamViaDomain: AsyncFunction<StreamLineRegisterPayload>
  getRegisterTeamViaDomainStatus: AsyncFunction<string>
}

interface OwnProps {}

export type AsyncFunction<S> = (agency: S) => Promise<any>

const mapDispatchToProps = (
  dispatch: Dispatch<AsyncFunction<any>>,
): MapDispatchToProps<DispatchProps, OwnProps> => ({
  searchAgencyDomain: (agency: string) =>
    dispatch(agencyAction.searchAgencyDomain(agency)),
  registerTeamViaDomain: (payload: StreamLineRegisterPayload) =>
    dispatch(agencyAction.registerTeamViaDomain(payload)),
  getRegisterTeamViaDomainStatus: (jobId: string) =>
    dispatch(agencyAction.getRegisterTeamViaDomainStatus(jobId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StreamLineSSTeamContainer)
