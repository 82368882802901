import React, { useEffect, useRef } from 'react'

import calendar from 'app/assets/icons/calendar-information.2-white.svg'
import bathIcon from 'app/assets/icons/summary_icons/bath-white.svg'
import bedIcon from 'app/assets/icons/summary_icons/bed-white.svg'
import carIcon from 'app/assets/icons/summary_icons/car-white.svg'
import keyIcon from 'app/assets/icons/summary_icons/key-white.svg'
import rentIcon from 'app/assets/icons/summary_icons/rent-white.svg'
import { Flex } from 'app/components/design-system-components'
import { PropertyKeysInfoModal } from 'app/features/teams/key-logger/PropertyKeysInfoModal'
import { detectWrap } from 'app/helpers/dom'
import { PropertyInfoDescContainer } from 'app/pages/teams/viewings-mobile/styles'
import * as helpers from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

function PropertyInfoDesc(props) {
  const { propertyData = {}, setKeyCodeTextHeight } = props
  const {
    property = {},
    keyCode,
    keySets,
    weeklyRentDisplay = '',
    availableFrom = '',
    weeklyRent = 0,
  } = propertyData || {}
  const { guidID = '' } = property
  const { bedrooms, bathrooms, carports, garages, parking } = property
  const carCountSpaces = { garages, parking, carports }
  const keyCodeTextRef = useRef()
  const formattedRent = helpers.pickRentDisplayAndFormat(
    weeklyRent,
    weeklyRentDisplay,
  )

  useEffect(() => {
    setKeyCodeTextHeight(keyCodeTextRef?.current?.clientHeight)
  }, [setKeyCodeTextHeight])

  return (
    <PropertyInfoDescContainer>
      <ul>
        {bedrooms !== undefined && (
          <li>
            <img className="summary-icon" src={bedIcon} alt="Bed Icon" />
            <span className="application-summary-number mb0">{bedrooms}</span>
          </li>
        )}
        {bathrooms !== undefined && (
          <li>
            <img className="summary-icon" src={bathIcon} alt="Bath Icon" />
            <span className="application-summary-number mb0">{bathrooms}</span>
          </li>
        )}
        {carports !== undefined && (
          <li>
            <img className="summary-icon" src={carIcon} alt="Car Icon" />
            <span className="application-summary-number mb0">
              {helpers.getCarSpaceCountForProperty(carCountSpaces)}
            </span>
          </li>
        )}
        {weeklyRentDisplay !== undefined && (
          <li>
            <img className="summary-icon" src={rentIcon} alt="Rent Icon" />
            <span className="application-summary-number mb0">
              {formattedRent}
            </span>
          </li>
        )}
        {availableFrom !== undefined && (
          <li>
            <img className="summary-icon" src={calendar} alt="Rent Icon" />
            <span className="application-summary-number mb0">
              {dateTimeHelpers.convertBETimeFormatToGivenFormat(
                availableFrom,
                dateTimeHelpers.HALF_DATE_WITHOUT_YEAR,
              )}
            </span>
          </li>
        )}
      </ul>

      <ul id={`keyCodeLabel-${guidID}`}>
        <li>
          <Flex mr={3}>
            <img
              className="summary-icon"
              src={keyIcon}
              height="18"
              width="18"
              alt={'key code'}
            />
            <PropertyKeysInfoModal
              tipId={guidID}
              keySets={keySets}
              onlistKeyCode={keyCode}
              triggerVariant="linkWhiteUnderlined"
              openRight={detectWrap(`keyCodeLabel-${guidID}`)}
              modalProps={{ maxWidth: '250px' }}
            />
          </Flex>
        </li>
      </ul>
    </PropertyInfoDescContainer>
  )
}

export default PropertyInfoDesc
