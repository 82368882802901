import React, { useEffect, useState } from 'react'

import { LoadingSection } from 'app/components/design-system-components'
import OwnerPropertyDisclosuresForm from 'app/match/ownerpropertydisclosures/form'
import {
  getPropertyDisclosureRequestDetailsForOwner,
  submitPropertyDisclosureRequestForOwner,
} from 'app/services/http/propertydisclosures'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import * as helpers from 'app/sm/helpers'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'

function OwnerPropertyDisclosuresPage({ match }) {
  const [submitDisclosureInProgress, setSubmitDisclosureInProgress] =
    useState(false)
  const { token, guid, contact } = match.params

  const {
    state: disclosureRequest,
    setState: setDisclosureRequest,
    loadingStates: disclosureRequestLoadingStates,
    loadingStatesHelpers: disclosureRequestLoadingStatesHelpers,
  } = useStateWithLoading([])

  const loadPropertyDisclosuresForOwner = () => {
    disclosureRequestLoadingStatesHelpers.startLoading()
    getPropertyDisclosureRequestDetailsForOwner(token, guid, contact)
      .then((response) => {
        setDisclosureRequest(response)
        disclosureRequestLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => {
        disclosureRequestLoadingStatesHelpers.setError(err)
      })
  }

  useEffect(() => {
    loadPropertyDisclosuresForOwner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addPropertyDisclosureRequestForOwner = (payload) => {
    setSubmitDisclosureInProgress(true)
    submitPropertyDisclosureRequestForOwner(guid, token, contact, payload)
      .then(() => {
        snugNotifier.success('Disclosures successfully submitted')
        history.replace(helpers.urlTo(helpers.urlIds.ownerDisclosuresSubmitted))
      })
      .catch((error) => {
        snugNotifier.error(error.message)
      })
      .finally(() => setSubmitDisclosureInProgress(false))
  }

  const disclosuresAlreadySubmitted =
    disclosureRequest && disclosureRequest.submittedBy

  return (
    <LoadingSection loadingState={disclosureRequestLoadingStates}>
      {disclosuresAlreadySubmitted ? (
        <div>
          The disclosures for this property have already been submitted.
        </div>
      ) : (
        <OwnerPropertyDisclosuresForm
          disclosureRequest={disclosureRequest}
          addPropertyDisclosureRequestForOwner={
            addPropertyDisclosureRequestForOwner
          }
          submitDisclosureInProgress={submitDisclosureInProgress}
        />
      )}
    </LoadingSection>
  )
}

export default OwnerPropertyDisclosuresPage
