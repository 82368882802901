export const BcRequestNotExistForCurrentUser = 200107
export const RentRateNotValid = 200109
export const RentBondNotValid = 200111
export const StartDateRequired = 200112
export const StartDateMustBeInThePast = 200113
export const EndDateRequired = 200114
export const EndDateMustBeAfterStartDate = 200115
export const NamesNotComplete = 200117
export const EmailNotValid = 200118
export const ErrorAgencyManagerDoesNotExist = 200148
export const TooCloseToTheEndOfLeaseOrAlreadyEnded = 200121
export const BcRequestAlreadyExistsForCurrentUser = 200125
export const TenantEmailAddressAlreadyExists = 200192
export const SecondaryTenantNotExist = 200197
export const RentalHistoryNotExist = 200238
export const CannotUpdateAlreadyApproved = 200200
export const ErrorManagerCannotBeTenant = 200202
export const BcRequestNotInProperStateToBeenSigned = 200150
export const CannotUpdateRequestDetailAfterSigning = 200154
export const BcRequestCannotBeSubmitted = 200170
export const ErrorTenantsBondTotalIncorrect = 200191
export const ErrorWhenRetrievingCustomer = 300122
export const ErrorWhenCreatingViewing = 200272
export const ErrorWhenRetrievingViewing = 200273
export const RequestedViewingNotExist = 200274
export const ErrorWhenUpdatingViewing = 200275
export const ErrorWhenDeletingViewing = 200276
export const DurationRequired = 200277
export const PropertyArrangementInvalid = 200343
export const ErrorWhenParsingParameters = 200517
export const ErrorViewingTimeExistsForProperty = 200519
export const ErrorViewingTimeExistsForManager = 200520
export const ErrorInvalidUnitNumber = 200601
export const ErrorInvalidStreetNumber = 200602
export const ErrorOccupantsNotAllowedForApplication = 200603
export const EmploymentVerificationRequestRequiresReason = 200608

export const ErrorWhenRetrievingBackgroundCheck = 200239
export const ErrorWhenParsingBackgroundCheck = 200241
export const NoIdentityDocExistsForUser = 600139
export const ErrorWhenUpdatingIdentityDoc = 600113

export const PlaceOfStudyRequired = 200405
export const CourseNameRequired = 200406

export const ErrorWhenParsingIncomingRequest = 900102
export const ErrorWhenReadingUserID = 900100
export const MobileNotValid = 700111
export const ErrorParsingFileType = 999996
export const ErrorParsingFile = 999997
export const InsufficientPrivileges = 999998
export const InvalidCredentials = 'auth error'
export const ParameterIDNotValid = 900103
export const InvalidFileType = 900105
export const ErrorWhenRetrievingProperty = 300113
export const DeclineReasonNotValid = 500108
export const ReviewNotValid = 300201
export const ReviewTitleRequired = 300202
export const ReviewTextRequired = 300203
export const ReviewRatingNotValid = 300204
export const ErrorWhenCreatingReview = 300205
export const ErrorWhenCreatingQuestion = 300301
export const QuestionTextRequired = 300302
export const ErrorWhenCreatingCustomer = 300101
export const ErrorWhenCallingStripeAPIToCreateCustomer = 300102
export const ErrorWhenUpdatingCustomer = 300103
export const NoCustomerRecordExistsForCurrentUser = 300104
export const StripeTokenIsRequired = 300105
export const ErrorWhenCreatingPayment = 300109
export const ErrorWhenCallingStripeAPIToMakePayment = 300110
export const ErrorWhenUpdatingPayment = 300111
export const ErrorWhenRetrievingPayment = 300123
export const StreetNumberRequired = 400101
export const StreetNameRequired = 400102
export const SuburbRequired = 400103
export const StateRequired = 400104
export const PostcodeRequired = 400105
export const CountryRequired = 400106
export const StreetTypeImproper = 400107
export const BedroomCountRequired = 400108
export const CountryAustraliaRequired = 400109
export const AusPostcodeMinimumLength = 400110
export const PostcodeWithInvalidState = 400111
export const OfferAlreadyPaid = 300408
export const ErrorEmptyLeaseNextStep = 300419
export const OfferIsNotActive = 300405
export const RequestedAdNotExist = 300141
export const NoAdExistsForCurrentProperty = 300108
export const ErrorWhenRetrievingAd = 300112
export const AdAlreadyExistsForCurrentProperty = 300116
export const ErrorWhenUpdatingAd = 300120
export const ErrorWhenCreatingAd = 300117
export const ErrorWhenCallingStripeAPIToCreateSub = 300124
export const OfferAvailableFromDateIsRequired = 300412
export const OfferWeeklyRentIsRequired = 300413
export const AcceptedLeaseLengthIsRequired = 300414
export const PropertyTitleIsRequired = 300500
export const PropertyTypeIsRequired = 300501
export const PropertyAvailableFromDateIsRequired = 300502
export const CalendarPlatformNotSupported = 300700
export const ErrorSavingCalendarRecord = 300701
export const ErrorDeletingCalendarRecord = 300702
export const ErrorRetrievingCalendarRecord = 300703
export const ErrorCreatingGoogleCalendarEvent = 300704
export const ErrorInvalidCalendarPlatform = 300705
export const ErrorInvalidCalendarGUID = 300706
export const ErrorCreatingCalendarActivity = 300707
export const ErrorDeletingMicrosoftCalendarEvent = 300708
export const ErrorLinkedCalendarNotFound = 300709
export const ErrorSendingFailedCalendarEventByEmail = 300710
export const ErrorRetrievingCalendarEvent = 300715
export const ErrorDecliningUnsuccessfulApplicantsInMarkedAsLeased = 300601
export const ErrorGettingFutureViewingsWithOfferID = 300602
export const ErrorCancellingFutureViewingsInMarkedAsLeased = 300603
export const ErrorWhenCreatingCard = 300131
export const ErrorInvalidCard = 300134
export const ErrorCannotDeleteDefaultCard = 300135
export const ErrorWhenUpdatingApplication = 300136
export const BackgroundCheckNotExist = 200240
export const ListingFeeAlreadyPaid = 300107
export const BackgroundCheckAlreadyPaid = 200237
export const IdentityDocExistsAlready = 600111
export const DateOfBirthNotValid = 600116
export const RequestedApplicantNotExist = 200261
export const PeopleIsRequired = 200266
export const OfferRentIsRequired = 300402
export const OfferTermIsRequired = 300403
export const OfferMoveInDateIsRequired = 300404
export const PetsIsRequired = 333999
export const RequestedOfferPreferencesNotExist = 800504
export const LettingPropertyOptionIsInvalid = 200254
export const RequestedPropertyNotExist = 300106
export const CreditCardDoesNotExist = 300133
export const CannotUpdateRentalHistory = 200233
export const ErrorWhenRetrievingApplication = 300155
export const RequestedApplicationNotExist = 300157
export const ErrorGettingApplicationDetails = 300218

export const ErrorApplicationMustBeActive = 300219

export const ErrorCannotDeleteRatedRentalHistory = 200358
export const ErrorWhenRetrievingRentalHistory = 200217
export const RequestedRentalHistoryNotExist = 200219
export const ErrorGettingNonExistingRentalHistoryByReferee = 200609
export const ErrorWhenCreatingApplicationDetailsPdf = 300187
export const RequestedViewingOnsiteRegistrantNotExist = 200380
export const ViewingStartDateIsInTheFuture = 200383

export const ErrorWhenGettingReputationPreference = 500150
export const ErrorWhenUpdatingReputationPreference = 500151
export const ErrorWhenParsingPreferenceValue = 500151
export const ErrorWhenConvertingPreferenceToResponse = 500155

export const ErrorWhenRetrievingViewingFeedbackRequest = 500157
export const ErrorWhenCreateViewingFeedbackResponse = 500158
export const RequestedViewingFeedbackRequestNotExist = 500159
export const ErrorWhenUpdatingViewingFeedbackRequest = 500160
export const ViewingFeedbackAlreadyAdded = 500161
export const ErrorViewingFeedbackRequestExpired = 500162

export const ErrorWhenCreatingAgency = 800200
export const ErrorFailedABNValidation = 800201
export const ErrorFailedACNValidation = 800202
export const ErrorEitherABNOrACNRequired = 800203
export const ErrorFailedRealEstateLicenseValidation = 800204
export const ErrorWhenCreatingAgencyManager = 800205
export const ErrorWhenRetrievingAgencyManager = 800206
export const ErrorInviteeFirstNameRequired = 800207
export const ErrorInviteeLastNameRequired = 800208
export const ErrorInviteeEmailRequired = 800209
export const ErrorInviteeFirstNameNotValid = 800210
export const ErrorInviteeLastNameNotValid = 800211
export const ErrorInviteeEmailNotValid = 800212
export const ErrorWhenCreatingAgencyManagerInvite = 800213
export const ErrorWhenCreatingAgencyManagerInviteEmailTemplate = 800214
export const ErrorABNRequired = 800215
export const ErrorInviteNotValid = 800216
export const ErrorInviteDoesNotExist = 800217
export const ErrorInviteAlreadyAccepted = 800218
export const ErrorWhenRetrievingInvite = 800219
export const ErrorInviteHasBeenCanceled = 800220
export const ErrorWhenUpdatingInvite = 800221
export const ErrorTradingNameRequired = 800222
export const ErrorCompanyNameRequired = 800223
export const ErrorWhenRegisteringAgency = 800224
export const ErrorWhenRetrievingAgencyUserProfile = 800230
export const ErrorWhenGettingAgency = 800232
export const ErrorCurrentUserIsNotInvitee = 800238
export const ErrorAgencyNotFound = 800240
export const ErrorParsingShareApplicationRequest = 800246
export const ErrorConsoleCloudIsNotEnabled = 800261
export const ErrorRetrievingUsers = 700176

export const ErrorWhenUpdatingApplicationStatus = 300153
export const DuplicateInvitesExist = 200309
export const InvalidOrEmptyDiscountcode = 200303
export const ErrorEmployerNotValid = 200341
export const ErrorEmployerUpdated = 200363
export const RequestedApplicantApplied = 200367
export const ErrorEmployerEventNotValid = 200500
export const ErrorConfirmationRequestExpired = 200501
export const ErrorRequestHasBeenChanged = 200510
export const RequestedViewingAllreadyExist = 200285
export const RequestedViewingRegistrationNotExist = 200283

export const ErrorEmployerEventNotExist = 200348
export const ErrorWhenRetrievingEmployer = 200243
export const ErrorEmployerDoesNotExist = 200245
export const ApplicationExistsAlready = 200270
export const ErrorValidatingMobile = 5
export const ErrorWhenConvertingMobileToInternationalFormat = 200333
export const ErrorWhenConvertingOfficeNumberToInternationalFormat = 200344
export const ErrorEmployerAlreadyVerified = 200506
export const ErrorEmployeeNotAllowedToVerify = 200508
export const InvalidEmploymentEndDate = 200354
export const RenterFirstNameIsRequired = 200371
export const ErrorWhenRetrievingViewingOnsiteRegistrant = 200379
export const ErrorWhenUpdatingViewingOnsiteRegistrant = 200381
export const RentalLedgerDoesNotExist = 200402
export const AgencyPaymentGatewayNotFound = 200404

export const RenterProfileIsNotComplete = 800512

export const MiddleNameIsRequired = 900111
export const ErrorEmploymentTypeNotInRange = 900112
export const ErrorApplicationHasToAcceptConditionOfProperty = 900113
export const ErrorApplicationHasDuplicateApplicant = 900162
export const InvalidSupplierSource = 900139
export const ErrorWhenCreatingApplyNotificationEmailForRenter = 200350
export const ErrorApplicantAndLoggedInUserMismatch = 200351
export const RecipientAddressCannotBeEmptyWhenSendReport = 900174
export const ApplicationDoesNotExist = 900182
export const EmptyMessageBody = 900202
export const EmptyRecipientList = 900203
export const EmptyAttachedProperties = 900204
export const CannotRequestRentalHistoryReviewByManager = 900252
export const ErrorWhenCreatingRentalHistoryReminderEmailToManager = 900253
export const ErrorInvalidTokenOrUnAuthorised = 900256
export const ErrorLeaseOfferAlreadyExists = 900260

// Identitiy document errors
export const IdentityDocsErrors = {
  DocumentTypeNotValid: 600114,
  ExpiryDateNotValid: 600115,
  ErrorIdentityDocNumberInvalid: 600145,
  ErrorIdentityDocNumberNotPresent: 600146,
  ErrorIdentityDocAusDLStateNotPresent: 600147,
  ErrorIdentityDocAusDLStateInvalid: 600148,
  ErrorIdentityDocAusDLNumberInvalid: 600149,
  ErrorIdentityDocPassportCountryNotPresent: 600150,
  ErrorIdentityDocAusPassportNumberInvalid: 600151,
  ErrorIdentityDocPassportCountryInvalid: 600152,
  ErrorExpiryDateNotPresent: 600153,
  ErrorIdentityDocDateOfBirthNotValid: 600154,
  ErrorIdentityDocDateOfBirthNotPresent: 600155,
  ErrorIdentityDocInvalidForBGC: 600165,
  ErrorIdentityDocAusDLACTCardNumberInvalid: 600156,
  ErrorIdentityDocAusDLNTCardNumberInvalid: 600157,
  ErrorIdentityDocAusDLQLDCardNumberInvalid: 600158,
  ErrorIdentityDocAusDLNSWCardNumberInvalid: 600159,
  ErrorIdentityDocAusDLSACardNumberInvalid: 600160,
  ErrorIdentityDocAusDLTASCardNumberInvalid: 600161,
  ErrorIdentityDocAusDLVICCardNumberInvalid: 600162,
  ErrorIdentityDocAusDLWACardNumberInvalid: 600163,
  ErrorIdentityDocAusDLMatchCardNumber: 600164,
}

export const ErrorInvalidRequest = 900115

export const DuplicatePhoneNumber = 900278
export const ErrorContactGUIDNotValid = 900279
export const ErrorContactNotExist = 900280
export const ErrorUpdatingContact = 900218
export const ErrorDeletingContact = 900282
export const ErrorInvalidContactRequest = 900283
export const ErrorCreatingContact = 900284
export const ErrorListingContacts = 900285
export const ErrorRetrievingContact = 900286

export const ErrorInvalidTeamViewingSettingsRequest = 900295

export const ErrorPhoneNotMatchForRenterUpdateOnsiteViewingRegistrant = 900296
export const ErrorTimeExpiredForRenterUpdateOnsiteViewingRegistrant = 900297
export const ErrorEmailAlreadyExistsForOnsiteViewingRegistrant = 900298

export const ErrorCannotDeleteOnsiteViewingRegistrant = 900302
export const ErrorRetrievingAutoReminderForRentalReviewEvent = 900303
export const ErrorRetrievingResendReminderForRentalReviewEvent = 900304
export const ErrorRetrievingEditedResendReminderForRentalReviewEvent = 900305
export const ErrorRetrievingLastRentalReferenceReminderSent = 900306

export const ErrorApplicationExpired = 900322
export const ErrorWhenSavingDocumentAttachment = 900328
export const ErrorWhenRetrievingDocumentAttachment = 900329
export const DocumentAttachmentNotExist = 900330
export const ErrorWhenDeletingDocumentAttachment = 900331
export const ErrorWhenReadingSaveParam = 900332
export const NoDraftIdentityDocExists = 900333
export const IdentityDocBirthDateMismatch = 900334

export const InvalidRentalHistoryResendRefereeEmail = 900314

export const ErrorCreatingRentalReferenceRenterReview = 900318
export const ErrorRetrievingRentalReferenceRenterReview = 900319
export const ErrorRentalReferenceRenterReviewNotExists = 900320
export const ErrorCreatingNotRatedRentalReferenceRenterReview = 900321

export const ErrorDifferentDateOfBirth = 900334
export const ErrorPlaceOfStudyRequired = 900336
export const ErrorCourseNamRequired = 900337
export const ErrorStudentTypeUnrecognised = 900338
export const ErrorDurationTypeRequired = 900339
export const ErrorDurationTypeUnrecognised = 900340
export const ErrorEnrolmentNumberRequired = 900341
export const ErrorContactNameRequired = 900342
export const ErrorContactEmailRequired = 900343
export const ErrorPhoneOrOfficeNumberRequired = 900344
export const ErrorPhoneNotValid = 900345
export const ErrorCoordinatorNameRequired = 900346
export const ErrorCoordinatorEmailRequired = 900347
export const ErrorCreateStudentDetails = 900348
export const ErrorGetStudentDetails = 900349
export const ErrorUpdateStudentDetails = 900350
export const StudentDetailsNotFound = 900351
export const ErrorDurationYearsInvalid = 900352
export const FailedToDeleteStudentDetailsByUser = 900353
export const FailedToDeleteStudentDetails = 900354
export const PhoneOfficeNumberNotValid = 900355
export const ErrorPhoneOrMobileRequired = 900356
export const ErrorDurationMonthInValid = 900357
export const DateOfBirthIsUnderAge = 900358
export const ErrorWhenConvertDocumentToPDF = 900359
export const ErrorMustAcceptConditionToApply = 900360
export const ErrorMustAcceptDisclosureToApply = 900361
export const ErrorWhenRetrievingApplicationNotes = 900362
export const ErrorWhenCreatingApplicationNote = 900363
export const ErrorInvalidApplicationNoteRequest = 900364

export const ErrorWorkflowChangeNotAllowed = 900365
export const ErrorDeletingLeaseEvent = 900366
export const ErrorDeletingApplicantsForOffering = 900367
export const ErrorDeletingNextStepForOffering = 900368
export const ErrorDeletingReferralsForOffering = 900369
export const ErrorWhenCheckApplicationIDManagedByTeamSlugs = 900370
export const ErrorRetrievingEnquiryNotes = 900588
export const ErrorRetrievingViewingAttendeeNotes = 900589

export const ErrorSendingSMS = 900371
export const InvalidApplicationSlug = 900372
export const InvalidCallBackTime = 900373
export const ErrorWhenDraftingCallbakEmailToDirectConnect = 900374

export const ErrorWhenRetrievingIdentityDocs = 900375
export const ErrorWhenDeletingIdentityDocs = 900376
export const ErrorWhenDeletingIdentityDocsAttachments = 900377

export const ErrorWhenReadingMessadeID = 900392
export const ErrorWhenReadingViewingID = 900393
export const ErrorWhenReadingRegisterID = 900394
export const ErrorUserAlreadyRegistered = 900395
export const ErrorRedirectingAlreadyRatedRentalHistory = 900396

export const ErrorWeeklyNetIncomeRequired = 200267
export const ErrorNetIncomeLessThanMinimum = 200404

export const InvalidOtherWeeklyIncome = 900378
export const InvalidOtherAnnualIncome = 900379
export const InvalidGovernmentWeeklyBenefits = 900380
export const InvalidGovernmentAnnualBenefits = 900381
export const ErrorWhenCreatingOtherProofOfIncome = 900382
export const ErrorWhenRetrievingOtherProofOfIncome = 900383
export const OtherProofOfIncomeDoesNotExist = 900384
export const ErrorWhenUpdatingOtherProofOfIncome = 900385
export const ErrorOtherProofOfIncomeAlreadyExists = 900386
export const ErrorDeletingRatingsForRentalHistory = 900398
export const ErrorWhenMarkingRentalHistoryAsDeclined = 200222
export const ErrorWhenUpdatingRentalHistory = 200223
export const ErrorWhenCreatingRentalHistory = 200216
export const ErrorWhenCreatingDeclineReason = 500110
export const ErrorWhenMarkingRentalHistoryAsRated = 200232
export const ErrorWhenUpdatingRentalHistoryRating = 200231
export const ErrorWhenCreatingRentalHistoryRating = 200230
export const ErrorWhenRetrievingRentalHistoryRating = 200228
export const RentalHistoryRatingExistsAlready = 200229
export const ErrorCannotCreateRatingForRatedRentalHistory = 900397
export const RatingCategoryNotValid = 500115
export const RatingValueNotValid = 500107
export const ErrorOverallRatingIsRequired = 900399
export const ErrorUsingNotMatchedQuestionTypeForRatingCategory = 901017
export const ErrorWhenParsingCookie = 700101
export const ErrorWhenParsingAuthToken = 700102
export const IDTokenNotValid = 700103
export const ErrorRevokingAuthToken = 700104
export const ErrorRetrievingAuthToken = 700105
export const AuthTokenRequired = 700106
export const ErrorWhenCreatingDexClient = 700107
export const ErrorWhenRevokingRefreshToken = 700108
export const ErrorWhenParsingRegisterPasswordRequest = 700109
export const PasswordNotValid = 700112
export const ErrorAccountAlreadyExists = 700114
export const ErrorCreatingPasswordAccount = 700115
export const ErrorWhenParsingVerifyRequest = 700116
export const ErrorWhenRetreivingUser = 700117
export const ErrorIncorrectOTPFormat = 700118
export const ErrorWhenParsingLoginPasswordRequest = 700119
export const ErrorWhenCreatingPassword = 700120
export const ErrorWhenTryingToPostLoginToDex = 700121
export const ErrorWhenParsingLoginResponseFromDex = 700122
export const ErrorWhenParsingCallbackRequest = 700123
export const ErrorWhenValidatingCallbackRequest = 700124
export const ErrorWrongCodeInCallbackRequest = 700125
export const ErrorWrongStateInCallbackRequest = 700126
export const ErrorWhenTryingToGetTokenInCallback = 700127
export const ErrorWhenTryingToGetIDTokenInCallback = 700128
export const ErrorWhenTryingToParseEmailClaimInCallback = 700129
export const ErrorNoCookie = 700130
export const ErrorWhenParsingRegisterSocialRequest = 700131
export const OTPNotValid = 700132
export const SocialNotValid = 700133
export const ErrorWhenMarkingUserMobileVerified = 700134
export const MobileVerified = 700136
export const ErrorWhenTryingToGetRenterName = 700137
export const ErrorWhenTryingToGetManagerName = 700138
export const ErrorWhenTryingToGetUser = 700139
export const ErrorWhenParsingUserWithRequestIDRequest = 700140
export const ErrorWhenParsingDeleteAccountRequest = 700141
export const ErrorWhenDeletingAccount = 700142
export const RoleNotValid = 700143
export const ErrorRenterIDCheckFailed = 700144
export const ErrorIdentityTypeNotImplemented = 700145
export const ErrorIdentityTypeNotAvailable = 700146
export const ErrorBondCoverRequestNotReadyForRenterIDCheck = 700147
export const ErrorBondCoverRequestNotReadyForManagerSignature = 700148
export const ErrorBondCoverRequestNotReadyForManagerApproval = 700149
export const ErrorAnalyticsForBondCoverRequestSaveManagerFailed = 700150
export const ErrorRenterIDCheckProductIdWrong = 700151
export const ErrorRenterIDCheckClientRefTooLong = 700152
export const FirstNameNotValid = 700153
export const MiddleNameNotValid = 700154
export const LastNameNotValid = 700155
export const ErrorWhenTryingToGetRenterProfile = 700156
export const AgencyGUIDWasNotProvided = 700157
export const ErrorWhenReadingInviterName = 700160
export const ErrorWhenReadingInviteeEmail = 700161
export const ErrorWhenReadingInviterEmail = 700162
export const ErrorWhenTryingToGetRentersForBCRequest = 700158
export const ErrorWhenTryingToGenerateRenterRatingsForAllRenters = 700159
export const ErrorWhenGeneratingAdminSearchResponse = 700160
export const RollingIsNotCurrentAddress = 700162
export const ReasonForLeavingNotProvided = 700163
export const ErrorEndDateMustBeInTheFutureForCurrentAddress = 700164
export const ErrorWhenRemovingIsCurrentAddressForRentalHistory = 700165
export const OtherReasonForLeavingNotSpecified = 700166
export const OfficeNumberNotValid = 700167
export const ReasonForUnsubscribeNotProvided = 700168
export const ErrorWhenCreatingUnsubscribeSuccessEmail = 700169
export const ErrorUserIDUnauthorised = 700170
export const PetDateOfBirthInvalid = 200317

export const ErrorWhenRetrievingViewingFeedback = 900410
export const ErrorWhenCountingActivityOverview = 900411
export const ErrorWhenCountingPropertyViews = 900412
export const ErrorWhenCountingApplications = 900413
export const ErrorWhenGeneratingViewingSummary = 900414
export const ErrorInvalidPropertyReportRequest = 900415
export const ErrorCreatingPropertyReportRecord = 900416
export const ErrorNotAuthorisedToViewPropertyReport = 900417
export const ErrorWhenRetrievingPropertyReport = 900418
export const ErrorPropertyReportDoesNotExist = 900419
export const ErrorWhenCreatingPropertyReportForPM = 900420
export const ErrorWhenCreatingCreateEmailRequestForOwner = 900421
export const ErrorInvalidManagerGUID = 900422
export const ErrorPMmessageIsRequired = 900423
export const ErrorFetchingAllPropertyFutureViewings = 900482

export const ErrorSearchingContactByAgency = 900443

export const ErrorViewingIsFullyBooked = 900453
export const ErrorViewingslotsAlreadyBooked = 900811

export const UnsubscribeFetchSmsDetailsError = 400107
export const UnsubscribeDeleteSmsPreferenceDetailsError = 400108
export const ErrorWhenParsingInvalidLogoDimensions = 500187
export const ErrorExternalTeamIDCannotBeEmpty = 500188

export const ErrorGetAgencyRedisCache = 100002
export const ErrorUnmarshallingRedisCache = 100003
export const ErrorGettingAttributes = 100004
export const ErrorMaximumAttachments = 100005
export const ErrorWhenRetreivingAdvancedActivityReport = 900425
export const UnauthorisedUserForAgency = 900426
export const ErrorGeneratingActivityFilter = 900427
export const ErrorRetrievingContactInfoFromViewingOnsiteRegistrant = 900311

export const ErrorWhenRetrievingBlockedDate = 900436
export const ErrorWhenSavingBlockedDate = 900437
export const ErrorWhenDeletingBlockedDate = 900438

export const ErrorCreatingLeaseOfferIncompleteNoCoreMessage = 900272
export const ErrorCreatingLeaseOfferIncompleteMustInclude = 900273
export const ErrorOnlyApplicantCanAcceptLeaseOffer = 999994
export const ErrorMissingPaymentReference = 999995

export const ErrorWhileRetrievingApplication = 900192
export const ErrorWhenCreatingApplicationSubmitReminder = 900432
export const ErrorWhenRetrievingApplicationSubmitReminder = 900434
export const ErrorUtilityProviderMissingProviderAgentID = 900444
export const ErrorUtilityProviderMissingProviderAgentReferenceID = 900445

export const ApplicationHasBeenPMWithdrawn = 300207
export const ErrorUserNotValid = 800228
export const ErrorWhenRetrievingUser = 800501
export const ErrorInvalidChildrenRequest = 900299
export const ErrorInvalidChildrenCount = 201011
export const ErrorWhenRegisteringToOldViewing = 900448
export const ErrorAlreadyRegisteredForViewing = 900470
export const ErrorGettingAgencyCriteria = 900475

export const ErrorWhenRetrievingMessage = 300198
export const RequestedMessageNotExist = 300199
export const ErrorWhenRetrievingAgencyProfile = 800237
export const EmptyPropertyGUID = 900474
export const ErrorWhenRetrievingEntryDetails = 900485
export const ErrorEntryDetailsDoesNotExist = 900486

export const ErrorAgencyProfileNotExist = 800244
export const ErrorInvalidAgencyProfileID = 800254
export const ErrorWhenCreatingViewingSlot = 200522
export const ErrorWhenAssigningViewingSlot = 200525
export const ErrorWhenRetrievingViewingSlot = 200523
export const ErrorWhenRetrievingViewingSlotCount = 200524
export const ErrorFlaggingApplicantUnsuitable = 200526
export const ErrorRetrievingApplicantFlags = 200527
export const ErrorInvalidApplicationCap = 900477
export const ErrorWhenReadingTeamGUID = 900487
export const ErrorWhenReadingApplicationGUID = 900488
export const ErrorWhenReadingTeamMemberGUID = 900479
export const EmptyTeamMemberGUID = 900480
export const ErrorSavingContactRelationship = 900481
export const ErrorWhenRetrievingApplicationTeamMember = 300208
export const ErrorNoFilesToDownloadPDF = 300209
export const ErrorInvalidRequestForTeamMemberAssign = 300210
export const ErrorCleanupPreviousTeamMemberAssignment = 300211
export const ErrorWhenReadingSearchQueryParameter = 300215
export const ErrorEmptySearchQuery = 300216
export const ErrorInvalidBondDuration = 900490
export const ErrorWhenDeletingViewingOnsiteRegistrantNote = 900489
export const ErrorWhenUpdatingViewingOnsiteRegistrantNote = 900484

export const EmptyViewingGUID = 900491
export const ErrorWhenRetrievingViewingTeamMembers = 900492
export const ErrorWhenDeletingViewingTeamMember = 900493
export const ErrorWhenReadingContactID = 900494

export const AuthRecordAlreadyVerified = 900237
export const AuthRecordExpired = 900495

export const ErrorWhenRetrievingTeamContacts = 900790
export const EmptyContactGUID = 900795
export const ErrorViewingTeamMemberAlreadyAssigned = 900796
export const EmptyTeamGUID = 900797
export const ErrorCheckingActiveTeamMember = 900798
export const ContactIsNotAnActiveTeamMember = 900799

export const ErrorInvalidPreferredDateOrTime = 900500
export const ErrorCreatingEntryDetails = 900501
export const ErrorUpdatingEntryDetails = 900502
export const ErrorDeletingEntryDetails = 900503

export const ErrorApplicationClosed = 210005

export const ErrorInvalidEnquiriesNotification = 900600
export const ErrorInvalidViewingsNotification = 900601
export const ErrorInvalidApplicationNotification = 900602
export const ErrorInvalidReportsNotification = 900603
export const ErrorAgencyUserNotExist = 800241
export const ErrorPropertyNotExist = 900220

export const ErrorLongDepositBSBLength = 900610
export const ErrorLongDepositAccountLength = 900611
export const ErrorLongDepositEFTReferenceLength = 900612
export const ErrorLongDepositDEFTReferenceLength = 900613
export const ErrorLongDepositBillerLength = 900614
export const ErrorLongDepositBPAYReferenceLength = 900615
export const ErrorLongBondBSBLength = 900616
export const ErrorLongBondAccountLength = 900617
export const ErrorLongBondEFTReferenceLength = 900618
export const ErrorLongBondDEFTReferenceLength = 900619
export const ErrorLongBondBillerLength = 900620
export const ErrorLongBondBPAYReferenceLength = 900621
export const ErrorLongRentBSBLength = 900622
export const ErrorLongRentAccountLength = 900623
export const ErrorLongRentEFTAccountNameLength = 900624
export const ErrorLongRentEFTReferenceLength = 900625
export const ErrorLongRentDEFTReferenceLength = 900626
export const ErrorLongRentBillerLength = 900627
export const ErrorLongRentBPAYReferenceLength = 900628

export const ErrorWhenReadingEmployerGUID = 200528
export const ErrorEmployerNotEligibleForConfirmation = 200529
export const ErrorWhenCreatingSuccessfulApplicantDetailsEmailToRayWhiteConcierge = 200532
export const InvalidConfirmationNotificationRequest = 900452
export const ErrorCreatingNotification = 900604
export const ErrorInvalidNotificationRecordType = 900605
export const EmptyRequest = 900142
export const ErrorAgencySourceCreateFailed = 900149
export const ErrorAgencySourceUpdateFailed = 900158
export const ErrorInvalidAgencySourceGUID = 900159
export const ErrorOccurredWhileUpdatingAgencySource = 900160
export const ErrorCreatingPropertyVideos = 900504
export const ErrorDeletingPropertyVideos = 900505
export const EmptyVideoGUID = 900506
export const ErrorWhenReadingAgencyID = 900106
export const ErrorAgencySlugIsNotValid = 800236
export const ErrorAgencySlugAlreadyExists = 710129

export const ErrorArchivingPropertyAfterMarkedAsLeased = 900700
export const ErrorUpdatingLeaseOffer = 900701
export const ErrorCancelFutureViewingArchiveProperty = 900702
export const ErrorDeclineHouseholdArchiveProperty = 900703
export const ErrorWhenRetriggeringWebFeed = 100001
export const ErrorGettingEmailDataForRefreshFeedEmailToTeamAdmin = 100002

export const ErrorMarkApprovedShouldBeTrueForMarkApproved = 900800
export const ErrorInvalidApplicationApprovedRequest = 900801
export const ErrorWhenReadingRentalHistoryID = 900802
export const ErrorWhenReadingRentalReferenceID = 900803
export const ErrorWhenReadingRentalHistoryTenant = 900804
export const RentalHistoryTenantNotExist = 900805
export const ErrorDeletingRentalHistoryTenant = 900806
export const ErrorFetchingRentalHistoryOccupants = 900807
export const ErrorStoringRentalHistoryOccupants = 900808
export const ErrorInvalidIdentityCap = 900809
export const ErrorFromConsoleCloudAPI = 900813
export const ErrorIntegrationDoesNotExist = 900814
export const ErrorSavingAgencyAttributes = 900815

export const ErrorFetchingPropertyPartnerKeys = 900510
export const ErrorDeletingPropertyLookupGUID = 900511
export const EmptyCreatePropertyLookupRequest = 900512
export const InvalidCreatePropertyLookupRequest = 900513
export const ErrorCreatingPropertyLookupFromRequest = 900514

export const ErrorWhenCreatingOrUpdatingExcludedProperties = 900515
export const ErrorWhenDeletingExcludedProperties = 900516
export const ErrorWhenRetrievingExcludedProperties = 900517
export const ErrorNoChangeToAgencySource = 900518
export const ErrorWhenRetrievingViewings = 900439
export const ErrorInvalidViewingCutoffEnabled = 900720
export const ErrorInvalidViewingCutoffTime = 900721
export const ErrorInvalidViewingRequestDisabled = 900722
export const ErrorInvalidNewViewingRegistrationNotification = 900606
export const ViewingRequestDisabled = 300213

export const ErrorGettingRegistrantDetailsFromShortcode = 900560
export const ErrorRegistrantFromShortcodeNotFound = 900561

export const ErrorInvalidViewingScheduleZoneType = 300420
export const ErrorPropertyOfferNotProvided = 200532
export const ErrorInvalidSelfCheckinType = 900723
export const ErrorWhenCountingTeamViewings = 900724
export const ErrorWhengettingTeamViewings = 900725

export const ErrorViewingIsCancelled = 200385
export const ErrorWhenIssuingRayWhiteConcierge = 300419

export const ErrorSelectingEnablePreApply = 900820
export const ErrorInvalidInspectionType = 900821
export const ErrorWhenCreatingWithOwnerApplicationToTenant = 200533
export const ErrorAttributeConsoleNotEnabled = 900822

export const ErrorRegistrantNotPreApplied = 200641

export const ErrorGettingManagersForRentalReference = 900550

export const ErrorWhenDeletingManyViewings = 200592
export const ViewingOrchestratedByIDRequired = 200593
export const ViewingOrchestratorNotRegistered = 200594
export const ErrGettingViewingOrchestrators = 200595

export const ErrorImportingKeySetsInvalidFile = 200709

export const ErrorViewingRunsInvalidRequest = 901000
export const ErrorViewingRunsPreflight = 901001
export const ErrorViewingRunsCreateRunGroups = 901002
export const ErrorViewingRunsCreate = 901003
export const ErrorViewingRunsInsertViewings = 901004
export const ErrorViewingRunsList = 901005
export const ErrorViewingRunsGet = 901006
export const ErrorViewingRunsPopulateProperties = 901007
export const ErrorViewingRunsPopulateViewings = 901008
export const ErrorViewingRunsDelete = 901009
export const ErrorViewingRunsNotExist = 901010
export const ErrorViewingRunsUserContactNotExist = 901011

export const ErrorFailedAccountTypeValidation = 200460

export const ErrorNotLogin = 'please login to perform this action'
export const NetworkConnectionError = 'Network connection error'

export const InvalidPropertyDisclosureRequest = 300350
export const ErrorRetrievingPropertyDisclosure = 300351
export const ErrorUpsertingPropertyDisclosure = 300352
export const ErrorInvalidCalendarAuthCode = 300711
export const ErrorSendingPropertyOnListEmailToManager = 900704
export const ErrorGettingEnableViewingCheckin = 900551
export const ErrorInvalidEnableViewingCheckin = 900552
export const ErrorGetViewingCheckinCountForProperty = 900553
export const ErrorRequiredApplicantPropertyCheckIn = 900554
export const ErrorAuthorizingUserToAcceptLeaseOffer = 900705

export const ErrorListingTeamRentalReferences = 500300
export const ErrorGettingTeamRentalReference = 500301
export const ErrorCountingTeamRentalReference = 500302
export const ErrorGettingMeAssigneeValue = 500303
export const ErrorUpdatingTeamRentalReference = 500304

export const ErrorInvalidAgency = 900146
export const OnlyUserWhoRequestedCanVerify = 900240
export const ApplicantLastNameIsRequired = 200288
export const ApplicantFirstNameIsRequired = 200287
export const ApplicantEmailIsRequired = 200289
export const ApplicantMobileNumberIsRequired = 200290
export const ErrorGetApplicantDisclosureAuthority = 900555
export const ErrorCreateApplicantDisclosureAuthority = 900556
export const ErrorUpdateApplicantDisclosureAuthority = 900557
export const ErrorInvalidViewingRegistrantLimit = 900519

export const ErrorPMSUnknownPMS = 900834
export const ErrorPMSDataPullerIsNotImplemented = 900835
export const ErrorPMSApplicationNotifierIsNotImplemented = 900836
export const ErrorPMSAttributeNotEnabled = 900837
export const ErrorPMSAttributeParse = 900838
export const ErrorPMSWebHookUnauthorized = 900839
export const ErrorPMSWebHookInternalError = 900840
export const ErrorPMSActivateError = 900841
export const ErrorPMSCreateIntegration = 900842
export const ErrorPMSUpdateIntegrationConfig = 900843
export const ErrorPMSGetIntegrationConfig = 900844
export const ErrorPMSJobSubmitErr = 900845
export const ErrorPMSReadPMSParam = 900846
export const ErrorPMSReadRequestBody = 900847
export const ErrorPMSWhileGettingIntegration = 900848
export const ErrorPMSReadLimitParam = 900849
export const ErrorPMSInvalidActivateCredentials = 900850
export const ErrorValidateApplicantIncome = 902001
export const ErrorGetApplicantIncome = 902002
export const ErrorCreateApplicantIncome = 902003
export const ErrorUpdateApplicantIncome = 902004
export const ErrorUpdateApplicationPreference = 902005
export const ErrorTryingToCreateAnotherViewingInSameDay = 900900
export const ErrorPMSKolmeoSupplierSourceNotConfigured = 900853
export const ErrorPMSIntegrationBundleRequired = 900854

export const ErrorGettingEmailRecords = 900910
export const ErrorGettingSmsRecords = 900911

export const ErrorSubmittingFormDisclosureForAttachmentTyped = 300353
export const InvalidPropertyDisclosureAttachmentsRequest = 300354
export const ErrorSavingPropertyDisclosureAttachments = 300355

export const ErrorPetMicrochipNumberRequired = 900450
export const ErrorPetRegistrationNumberRequired = 900451
export const ErrorPetMicrochipMaxLength = 901200
export const ErrorPetRegistrationNumberMaxLength = 901201

export const ErrorInvalidBuildingGUID = 901202
export const ErrorGettingBuilding = 901203
export const ErrorBuildingNotFound = 902104
export const ErrorBuildingLimitReached = 902105
export const ErrorGettingBuildingCount = 902106
export const ErrorDeletingBuildingAssignments = 902107
export const ErrorBuildingSlugRequired = 902108

export const ErrorInvalidShortCode = 900574
export const ErrorWhenDeletingViewingSlot = 900575
export const ErrorAuthorizingAgencyForApplication = 900576
export const UnauthorizedAgencyForApplication = 900577

export const ErrorUpdatingShareApplication = 900578
export const ErrorWhenSendingShareApplicationToTeamMember = 900579
export const ErrorWhenCreatingShareApplicationStatus = 900580
export const ErrorShareApplicationExpired = 200463
export const ErrorAssigningManagerForApplication = 900585
export const FailedToDecryptDocAttachment = 900586
export const InvalidDocAttachment = 900587

export const ErrorCreatingReassignmentApplicationPropertyRecord = 960000
export const ApplicationAlreadyAssignedToTargetProperty = 960001
export const ErrorInvalidDefaultSelectedViewingDuration = 960002
export const ErrorInvalidDefaultSelectedViewingDurationEnabled = 960003
export const ErrorInvalidDefaultSelectedViewingDurationValue = 960004
export const ErrorInvalidNewManagementBDMLeadValue = 960005
export const ErrorInvalidRentvestorBDMLeadValue = 960006
export const ErrorInvalidBDMLeadEmailValue = 960007
export const ErrorInvalidBDMLeadStatesValue = 960008
export const ErrorBDMStatesRequired = 960009
export const ErrorInvalidOptedToHavePropertyInvestorValue = 960010
export const ErrorInvalidPropertyInvestmentPostcode = 960011
export const ErrorMissingPostcodes = 960012
export const ErrorInvalidInvestmentProfile = 960013

export const ErrorFailedToRetrievingViewingsMode = 960014
export const ErrorViewingInspectorsRequired = 960015
export const ErrorInvalidViewingInspectorGUID = 960016
export const ErrorInvalidKeysDailyDigestNotificationValue = 960017
export const ErrorFailedToUpdatePropertyListingAgent = 960020
export const ErrorUpdatingExcludedProperties = 960018
export const ErrorCreatingExcludedProperties = 960019
export const ErrorCreatingExcludedPropertyDates = 960020
export const ErrorDeletingExcludedPropertyDates = 960021
export const ErrorReadingPropertyGUID = 960022

export const ErrorInvalidApplicationLeaseOfferOperationsEmail = 960027
export const ErrorInvalidApplicationLeaseOfferAlwaysCopyLeaseOps = 960028
export const ErrorInvalidApplicationLeaseOfferLeaseOpsAsContact = 960029

export const ErrorInvalidArchiveFromFeed = 9004701
export const ErrorInvalidArchiveLeased = 9004702

export const ErrorFetchingTenantSettings = 960031
export const ErrorCreatingTenantSettings = 960032
export const ErrorUpdatingTenantSettings = 960033

export const ErrorPreviousTenanciesTerminationRequired = 960034
export const ErrorPreviousTenanciesTerminationDetailsRequired = 960035
export const ErrorAnotherLessorOrAgentDebtRequired = 960036
export const ErrorAnotherLessorOrAgentDebtDetailsRequired = 960037
export const ErrorReasonAffectRentPaymentRequired = 960038
export const ErrorReasonAffectRentPaymentDetailsRequired = 960039
export const ErrorOwnPropertiesOrLandRequired = 960040
export const ErrorOwnPropertiesOrLandDetailsRequired = 960041
export const ErrorTeamMemberAssociationRequired = 960042
export const ErrorGettingDisclosuresData = 960044

export const ErrorCheckingForNewActivityFeed = 960045
export const ErrorFetchingActivitiesFeedList = 960046
export const ErrorInvalidListingFiltersRequest = 960047
export const ErrorCantUpdateSanitizedDocument = 960048

export const ErrorSendingPropertyDisclosureEmailRequestData = 970000
export const ErrorReadingGUIDParameter = 970001
export const ErrorResolvingPropertyDisclosureRequest = 970002
export const ErrorCreatingPropertyDisclosureRequest = 970003
export const ErrorUpdatingPropertyDisclosureRequest = 970004
export const ErrorResolvingOwner = 970100
export const ErrorPropertyDisclosureAlreadySubmitted = 970005

export const ErrorInvalidUnsubPageToken = 500170
export const ErrorUnsubTokenExpired = 500180

export const ErrorCreatingInviteToApply = 970300

export const ErrorCreatingVehicles = 970010
export const ErrorUpdatingVehicles = 970011
export const ErrorRetrievingVehicles = 970012

export const ErrorRetrievingAgencySetupWizard = 800262
export const ErrorWizardNotFound = 800263
export const ErrorWhileUpdatingWizard = 800264
export const ErrorWhenReadingWizardID = 800265
export const ErrorInvalidWizardStep = 800267
export const ErrorLookingUpEmployer = 970020
export const ErrorEndDateBeforeStartDate = 970021
export const ErrorUpdatingEmployerEndDate = 970022
export const ErrorLookingUpAddressHistory = 970023
export const ErrorUpdatingRentalHistoryEndDate = 970024

export const ErrorEmailAndPhoneAreRequired = 970031

export const ErrorInvalidRegistrantData = 970200

export const ErrorSearchingBorrowers = 200706

export const ErrorMobileAlreadyRegistered = 700174
export const ErrorEmailAlreadyRegistered = 700175
export const ErrorAboutMeIsRequired = 200450
export const ErrorHouseholdYearlyIncomeNotValid = 200451

export const ErrorDeletingTeamMember = 970030
export const ErrorRentalHistoryGUIDRequired = 200658
export const ErrorRentalHistoryNotFound = 200659
export const ErrorTeamMemberExistInAnotherTeams = 970032

export const ErrorInvalidAddressHistoryRent = 900631

export const ErrorParsingShareApplicationBGCApprovalAttrs = 900639

export const ErrorWhenRetrievingUtilityLead = 900641
export const ErrorUtilityLeadNotFound = 900642
export const ErrorInvalidApplicationStatusForUtilityLead = 900643

export const ErrorRetrievingFollows = 970400
export const ErrorUpdatingFollows = 970401
export const ErrorDeletingFollows = 970401
export const RentalRequestCanNotBeDeclined = 200221
export const ErrorInactiveAgencyProfile = 800256
export const ErrorCrmIDDuplicate = 800257
export const ErrorExternalAgencySourceDuplicate = 800258
export const ErrorCRMIDRequired = 800259
export const ErrorCRMIDShouldBeNumber = 800260

export const ErrorUserNotAllowedToActiveDomain = 970045

export const ErrorPersonalReferenceAlreadyResponded = 900590
export const ErrorRetrievingPersonalReferenceQuestions = 900591
export const ErrorCreatingPersonalReferenceSurveyResponse = 900592
export const ErrorInvalidPersonalReferenceQuestionsVersion = 900593
export const ErrorRetrievingPersonalReferenceResponse = 900594
export const ErrorInvalidPersonalReferenceAnswerCode = 900595
export const ErrorInvalidPersonalReferenceAnswerValue = 900596
export const ErrorPersonalReferenceQuestionAnswerTypesMismatch = 900597
export const ErrorGettingPersonalReferenceSendEnableAttribute = 900598
export const ErrorSendingPersonalReferenceWhileAgencyDisablingFeature = 900599
export const ErrorCreatingSendEventForPersonalReference = 900609
export const ErrorNoPhoneOrEmailSendPersonalReference = 900608

export const ErrorRetrievingReportManagerInfo = 970500

export const ErrorInvalidPauseViewingsWhenApprovedApplication = 200460
export const ErrorViewingsForThisPropertyArePaused = 200461
export const ErrorUnableToCheckIsViewingPaused = 200462
export const ErrorActiveOfferNotFound = 200463
export const ErrorRetrievingApplicationsCount = 200464
export const ErrorViewingMergeNotAllowed = 600620

export const ErrorInvalidOfferVacateDate = 300431

export const ErrorViewingRegistrantAlreadyApplied = 200610

export const ErrorApplicantReachedAgencyApplicationsLimit = 970036

export const ErrorNamedOnLeaseIsRequired = 900522
export const ErrorTenancyTermIsRequired = 900523
export const ErrorRentOnTimeIsRequired = 900524
export const ErrorRentalAmountIsRequired = 900525

export const InvalidAgencyUserRoleForSnugTeamMember = 900526

export const CommentIsRequired = 500137

export const ErrorCreatingAutoresponderAttachment = 200611
export const ErrorInvalidHousingCategory = 200612
export const InvalidAutoresponderAttachmentFilesNumber = 200613
export const ErrorMaxAutoresponderAttachmentsReached = 200614
export const ErrorListingAutoresponderAttachments = 200615
export const ErrorAttachmentNotFound = 200616
export const ErrorDeletingAutoresponderAttachment = 200617
export const ErrorUpdatingAutoresponderAttachment = 200618
export const ErrorOnlyMobileNumberIsAccepted = 200480
export const ErrorOneAgencyAdminIsRequired = 900520
export const ErrorInvalidSource = 900521

export const ErrorCreatingKeySet = 200700
export const ErrorListingKeySets = 200701
export const ErrorTransitingKeySetStatus = 200702
export const ErrorRetrievingKeySet = 200703
export const ErrorKeySetNotFound = 200704
export const ErrorBorrowerNotFound = 200705
export const ErrorInvalidKeySetStatus = 200707
export const ErrorImportingKeySets = 200708

export const ErrorExternalTeamIDDisabled = 201015
export const ErrorCreatingAliasTeamID = 201016
export const ErrorDeletingAliasTeamID = 201017
export const ErrorExternalTeamIDInvalid = 201018

export const ErrorInvalidInspectedDate = 900473

export const ErrorUnableToGetAgencyDetails = 200490

export const ErrorUnableToSendInviteToApplyApplicationRequestValidation = 200750
export const ErrorUnableToSendInviteToApplyApplicationAuthorisationError = 200751
export const ErrorUnableToSendInviteToApplySaveError = 200752
export const ErrorUnableToSendInviteToApplyEmail = 200753
export const ErrorCreatingCompletedBackgroundCheckConfirmationEmail = 970037
export const ErrorSendingCompletedBackgroundCheckConfirmationEmail = 970038

export const ErrorInvalidApplicationBallotsEnabled = 970050
export const ErrorInvalidApplicationSuccessfulBallotsSize = 970051
export const ErrorInvalidApplicationReserveBallotsSize = 970052
export const ErrorInvalidIDDocsNotRequested = 200491
export const ErrorAgencyUserIsNotAdmin = 900141

export const ErrorApplicationNotEligibleForBgCRun = 900646
export const ErrorInvalidAttribute = 900644
export const ErrorCheckingApplicationBgCEligibility = 900645

export const ErrorInvalidPropertyOwnershipOption = 200630

export const ErrorIncomeExceedingMaxValid = 200627

export const ErrorWhenDeletingAlertPreference = 200800

export const ErrorGeneratingTICARequestHashedDocuments = 200628

export const ErrorGettingSmsListCount = 200670
export const ErrorGettingSmsList = 200671

export const ErrorBackgroundCheckAlreadyBeingProcessed = 200760

export const ErrorEmptyRefereeType = 200761

export const ApplicantNotFound = 210004

export const ErrorSourceApplicationIsNotActive = 200780
export const ErrorApplicationsNotForSamePropertyMergeNotAllowed = 200781
export const ErrorApplicantsExceedingLimitForRequestedMerge = 200785
export const ErrorMergeRequestExpired = 200787
export const ErrorDestinationApplicationIsNotActive = 200789
export const ErrorApplicationAlreadyMerged = 200790
export const ErrorApplicationMergeRequestAlreadyExists = 200792

export const ErrorSortingApplications = 300224
export const ErrorManagerRunningBgCForSanitizedDoc = 200763
export const ErrorRenterRunningBgCForSanitizedDoc = 200764

export const ErrorImportingEnquirersInvalidFile = 500189
export const ErrorImportingEnquirers = 500192
export const ErrorInvalidImportEnquirersPayload = 500193
export const ErrorRenterRunningBgcForInvalidDoc = 200765
export const ErrorUpdatingViewingRegistrantShortlist = 200820

export const InvalidImageFileType = 900001
export const InvalidDocFileType = 900002
export const InvalidDocAndImageFileType = 900003

export const ErrorBackgroundCheckBillingNotEnabled = 200766
export const ErrorCreatingBillingEvent = 200767
export const ErrorGettingTeamBillingPlanSubscriptionIDAttribute = 200768
export const ErrorGettingTeamBillingBGCSubscriptionItemIDAttribute = 200769
export const ErrorRetrievingAuditLog = 200770
export const ErrorCreatingAuditLog = 200771
export const ErrorInvalidAuditLog = 200772
export const ErrorGettingStripeInvoice = 200773
export const ErrorSubscriptionTeamIDNotEnabled = 200774

export const ErrorIncrementingBillingProviderUsage = 200830
export const ErrorUpdatingBillingEvent = 200831
export const ErrorParsingBillingProviderResponse = 200832
export const ErrorParsingBillingProviderRequest = 200833

export const ErrorDeactivatingTeamBGC = 200834
export const ErrorDeactivatingTeamBGCEvent = 200385
export const ErrorActivatingTeamBGC = 200836
export const ErrorActivatingTeamBGCEvent = 200837
export const ErrorCreatingSubscriptionItemID = 200838

export const ErrorSubscriptionItemIDAlreadyPresent = 200730
export const ErrorSubscriptionIDAlreadyPresent = 200731
export const ErrorGettingSubscriptionItemID = 200732
export const ErrorGettingSubscriptionID = 200733

export const ErrorMinNumberOfPayslipRequiredNotMet = 200901
export const ErrorMinPointsRequiredNotMet = 200902
export const ErrorPrimaryIDRequired = 200903
export const ErrorMinDocRequiredNotMet = 200904
export const ErrorReasonForLeavingRequired = 200905
