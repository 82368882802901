import { connect } from 'react-redux'

import { registerForEmailViewing } from 'app/match/viewings/enquiry_viewing_register/enquiry_viewing_register_actions'
import { fetchAllViewingsForCheckin } from 'app/pages/apply/teams/checkin/action'
import AgencyPublicViewingsContainer from 'app/pages/apply/teams/checkin/container'
import { setBackUrl } from 'app/shared_components/actions'

const mapStateToProps = ({ agency, session }) => ({
  agencyProfile: agency.agencyResponse,
  currentUser: session.currentUser,
  currentTeam: session.currentTeam,
})

const mapDispatchToProps = (dispatch) => ({
  setBackUrl: (url) => dispatch(setBackUrl(url)),
  registerForEmailViewing: (viewingGUID, payload) =>
    dispatch(registerForEmailViewing(viewingGUID, payload)),
  fetchAllViewingsForCheckin: (agencySlug, cursor, limit) =>
    dispatch(fetchAllViewingsForCheckin(agencySlug, cursor, limit)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgencyPublicViewingsContainer)
