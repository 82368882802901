import { NetworkConnectionError } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  getCountForRentalReferencesForAgency,
  getRentalReferencesForAgency,
} from 'app/pages/teams/rental-references/access'

export const fetchRentalReferencesForAgency =
  (agencyGUID, limit, offset, filterValues) => () => {
    return getRentalReferencesForAgency(
      agencyGUID,
      limit,
      offset,
      filterValues,
    ).then(({ result, ok, responseText }) => {
      if (ok) {
        return Promise.resolve(result)
      } else {
        return responseText.then((error) => {
          if (responseText === undefined) {
            return Promise.reject(new Error('Something went wrong!'))
          }
          return Promise.reject(translateErrorCodeToMessage(error))
        })
      }
    })
  }

export const fetchCountForRentalReferencesForAgency =
  (agencyGUID, filterValues) => () => {
    return getCountForRentalReferencesForAgency(agencyGUID, filterValues).then(
      ({ result, ok, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          return responseText.then((error) => {
            if (responseText === undefined) {
              return Promise.reject(NetworkConnectionError)
            }
            return Promise.reject(translateErrorCodeToMessage(error))
          })
        }
      },
    )
  }
