import React from 'react'
import { useState } from 'react'

import { Box } from 'app/components/design-system-components'
import { SearchRounded } from 'app/components/design-system-components/icons/actions'
import { Input } from 'app/components/design-system-components/inputs'
import { CheckboxesList } from 'app/components/design-system-components/list-filters/CheckboxesList'
import { DropdownBodySectionHeader } from 'app/components/design-system-components/list-filters/DropdownBodySectionHeader'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const managerFilterFields = {
  manager: 'manager',
}

const allManagersOption = {
  option: 'all',
  optionMeta: { id: 'all', displayText: 'All team members' },
}

export const managerFilterDataExtractor = (filterValues) => {
  const { [managerFilterFields.manager]: managersObj } = filterValues
  return {
    managers: Object.values(managersObj || {})
      .filter(
        ({ optionMeta }) => optionMeta.id !== allManagersOption.optionMeta.id,
      )
      .map(({ option }) => option),
  }
}

export const ManagerAppliedFilterRenderer = ({ filterValues }) => {
  const { [managerFilterFields.manager]: managersObj } = filterValues
  if (managersObj[allManagersOption.optionMeta.id])
    return allManagersOption.optionMeta.displayText
  return Object.values(managersObj)
    .map((managerOption) => managerOption.optionMeta.displayText)
    .join(', ')
}

export const isManagerFilterApplied = (managerFilterValues) => {
  return (
    managerFilterValues &&
    managerFilterValues[managerFilterFields.manager] &&
    Object.values(managerFilterValues[managerFilterFields.manager]).length
  )
}

export const ManagersOptionsList = ({
  listTitle,
  options,
  selectedOptions,
  onCheckBoxClicked,
}) => {
  if (!options || !options.length) return null
  return (
    <>
      <DropdownBodySectionHeader
        header={listTitle}
        containerProps={{ mb: theme.space[4] + 'px' }}
      />
      <CheckboxesList
        options={options}
        onCheckBoxClicked={onCheckBoxClicked}
        checkedOptions={selectedOptions}
      />
    </>
  )
}

export const ManagerFilterBody = ({
  formBag: { values, setFieldValue },
  managers,
}) => {
  const [searchText, setSearchText] = useState('')

  const onSearchTextChange = ({ target: { value } }) => {
    setSearchText(value)
  }

  const options = managers
    .map((manager) => {
      const { firstName, lastName } = manager.profile
      return {
        option: manager,
        optionMeta: {
          id: manager.guidID,
          displayText: `${firstName} ${lastName}`,
        },
      }
    })
    .filter(({ optionMeta: { displayText } }) =>
      displayText.toLowerCase().includes(searchText.toLowerCase()),
    )

  const onCheckBoxClicked = (checked, option) => {
    if (option.optionMeta.id === allManagersOption.optionMeta.id) {
      toggleAll(checked)
      return
    }
    const managerValues = values[managerFilterFields.manager]

    if (checked) {
      setFieldValue(managerFilterFields.manager, {
        ...managerValues,
        [option.optionMeta.id]: option,
      })
    } else {
      const {
        [option.optionMeta.id]: uncheckedOption,
        // if any manager unchecked, should remove (All) filter if exists
        [allManagersOption.optionMeta.id]: allOption,
        ...otherOptions
      } = managerValues
      setFieldValue(managerFilterFields.manager, {
        ...otherOptions,
      })
    }
  }

  const toggleAll = (checked) => {
    if (checked) {
      options.unshift(allManagersOption)
      setFieldValue(
        managerFilterFields.manager,
        Object.assign(
          {},
          ...options.map((option) => ({
            [option.optionMeta.id]: option,
          })),
        ),
      )
    } else {
      setFieldValue(managerFilterFields.manager, {})
    }
  }

  const renderOptions = () => {
    const meOptionIndex = options.findIndex(({ option: { isMe } }) => !!isMe)

    const selectedManagers = values[managerFilterFields.manager] || {}

    if (meOptionIndex < 0) {
      return (
        <ManagersOptionsList
          options={options}
          listTitle="All Managers"
          onCheckBoxClicked={onCheckBoxClicked}
          selectedOptions={selectedManagers}
        />
      )
    }

    const clonedOptions = [...options]
    const [meOption] = clonedOptions.splice(meOptionIndex, 1)
    return (
      <Box maxHeight="200px" overflowY="auto">
        <ManagersOptionsList
          options={[allManagersOption]}
          listTitle="ALL"
          onCheckBoxClicked={onCheckBoxClicked}
          selectedOptions={selectedManagers}
        />
        <ManagersOptionsList
          options={[meOption]}
          listTitle="You"
          onCheckBoxClicked={onCheckBoxClicked}
          selectedOptions={selectedManagers}
        />
        <ManagersOptionsList
          options={clonedOptions}
          listTitle="All Managers"
          onCheckBoxClicked={onCheckBoxClicked}
          selectedOptions={selectedManagers}
        />
      </Box>
    )
  }

  return (
    <Box maxHeight="100%">
      <Box p={theme.space[4] + 'px'}>
        <Input
          icon={<SearchRounded style={{ color: theme.colors.gray400 }} />}
          inputProps={{
            onChange: onSearchTextChange,
            placeholder: 'Search for a manager',
          }}
        />
      </Box>

      {renderOptions()}
    </Box>
  )
}
