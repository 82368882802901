import React, { useState } from 'react'

import moment from 'moment'
import qs from 'qs'
import { useHistory } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import GenericButtonComponent from 'app/components/buttons/default/component'
import {
  Alert,
  AlertSensitiveInfo,
  Box,
  Button,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'
import SvgHelpOutlineRounded from 'app/components/design-system-components/icons/actions/HelpOutlineRounded'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import * as Display from 'app/components/display/display'
import { CheckBoxGeneral } from 'app/components/forms/forms'
import ProgressBarComponent from 'app/components/progress_bar/connection'
import theme from 'app/match/applicationReportPDF/assets/theme'
import BasicModal from 'app/modals/basic_modal'
import {
  getApplicantAcknowledgementofPoints,
  updateApplicantAcknowledgementofPoints,
} from 'app/services/http/applications'
import { getApplicationConfigForProperty } from 'app/services/http/teams/settings'
import * as snugNotifier from 'app/services/snugNotifier'
import DocumentList from 'app/shared_components/document_list/document_list'
import { ErrorMessage } from 'app/shared_components/helpers'
import getParameter from 'app/shared_components/parameter_parser'
import Header from 'app/shared_components/rental_reputation_header_connection'
import { history } from 'app/shared_components/router'
import SnugTip from 'app/shared_components/snug_tips'
import { countryList } from 'app/sm/background_check/identity_documents/country_list'
import {
  IDDocumentDocType,
  idDocumentTypeTextConverter,
  isFromApplication,
  urlTo,
} from 'app/sm/helpers'
import { SupportingIDDocuments } from 'app/sm/helpers'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import {
  HUNDRED_POINTS_OPTION_VALUE_STRING,
  identityCapEnabledNumberOptionsMap,
  NUMBER_OF_IDS_REQUIRED,
  ONE_PRIMARY_ID_OPTION_VALUE_STRING,
} from 'app/sm/team_viewing_preference/team_viewing_preference_const'
import { checkIfBGCheckNotSupportedInState } from 'app/utils/address/helpers'
import * as applicationHelpers from 'app/utils/applications/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as stringHelpers from 'app/utils/strings/helpers'
import { AUTO_REMOVE_OUTDATED_DOCUMENTS_DAYS } from 'app/utils/text/helpers'

export const IdentityDocumentBox = ({
  identityDoc,
  removeIdentityDoc,
  readOnly = false,
  from = '',
}) => {
  const history = useHistory()
  const [showEditModal, setShowEditModal] = useState(false)

  let {
    middleName,
    firstName,
    lastName,
    dateOfBirth,
    expiryDate,
    docNumber,
    state,
    guidID,
    docType,
    country,
    sanitizedAt,
    hashedDateOfBirth,
  } = identityDoc
  const name = middleName
    ? `${firstName} ${middleName} ${lastName}`
    : `${firstName} ${lastName}`
  dateOfBirth = moment(dateOfBirth).format('Do MMM YYYY')
  const showHashedDOB = !!sanitizedAt && hashedDateOfBirth !== '' // backward compatible
  expiryDate = moment(expiryDate).format('Do MMM YYYY')
  const isDriverLicense = docType === IDDocumentDocType.DriverLicense
  const regionScope = docType === isDriverLicense ? 'State' : 'Country'
  const documentCategory = isDriverLicense ? 'Driver Licence' : 'Passport'
  const chosenCountry =
    !!countryList &&
    countryList.find((element) => {
      return element['alpha-3'] === country
    })
  const chosenCountryName = chosenCountry && chosenCountry.name
  const regionName = isDriverLicense ? state : chosenCountryName
  let docPoints
  if (docType === IDDocumentDocType.DriverLicense) {
    docPoints = SupportingIDDocuments[4].points
  } else if (
    SupportingIDDocuments[docType].points &&
    IDDocumentDocType.Passport
  ) {
    docPoints = SupportingIDDocuments[4].points
  } else {
    docPoints = SupportingIDDocuments[docType].points
  }
  const fromRenterSummary = from === 'renterSummary'
  const details = [
    `Document type: ${documentCategory}`,
    `Document Number: ${docNumber}`,
    `Date of expiry: ${expiryDate}`,
    `${regionScope}: ${regionName}`,
    ` Date of birth: ${
      showHashedDOB
        ? hashedDateOfBirth
        : dateTimeHelpers.getDateOfBirthText(dateOfBirth)
    }`,
  ]
  return (
    <Display.DetailsBoxCard
      title={name}
      titleClass="ml30"
      details={details}
      docPoints={docPoints}
      icon="icon-person"
      componentClass={`${fromRenterSummary && 'no-box-shadow p0'}`}
      disabled={readOnly}
      onRemove={() => removeIdentityDoc(guidID)}
      editUrl={urlTo('BackgroundCheckDetails', { idDocGuid: guidID })}
      footer={
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent={!!sanitizedAt ? 'space-between' : 'end'}
        >
          {!!sanitizedAt && (
            <Flex flexDirection="row" alignItems="center">
              <TextSpan
                fontWeight={400}
                fontSize={`${theme.space[4]}px`}
                color={theme.colors.gray600}
                mr={`${theme.space[3]}px`}
              >
                Sensitive data removed automatically on{' '}
                {moment(sanitizedAt).format(
                  dateTimeHelpers.SHORT_STANDARD_DATETIME,
                )}{' '}
              </TextSpan>
              <SvgHelpOutlineRounded
                color={theme.colors.gray600}
                data-tooltip-id={`doc-message-${guidID}`}
                data-tooltip-content=""
              />
              <ReactTooltip id={`doc-message-${guidID}`} data-type="info">
                <span>
                  Sensitive data is hashed ####345 and documents deleted after{' '}
                  {AUTO_REMOVE_OUTDATED_DOCUMENTS_DAYS} days.
                </span>
              </ReactTooltip>
            </Flex>
          )}
          <Flex>
            <button
              style={{ marginRight: '8px' }}
              className="textButton "
              onClick={() => removeIdentityDoc(guidID)}
            >
              Remove
            </button>
            <button
              className="textButton"
              onClick={() => {
                !!sanitizedAt
                  ? setShowEditModal(true)
                  : history.push(
                      urlTo('BackgroundCheckDetails', { idDocGuid: guidID }),
                    )
              }}
            >
              Edit
            </button>
          </Flex>
        </Flex>
      }
      editable={false}
      removable={false}
    >
      {showEditModal && (
        <Display.Modal
          toggleModal={() => setShowEditModal(false)}
          title={'Edit Document'}
          hideButtonSection
          modalSubheadingClass="ml-auto mr-auto"
        >
          <div className="fs16">
            <AlertSensitiveInfo hideLearnMore={false} />
            <Display.DefaultBodyText
              text={`Please remove and add a new entry if required.`}
              textClass="text-align-center"
            />
          </div>
          <GenericButtonComponent
            onClick={() => setShowEditModal(false)}
            buttonType="tertiary"
            buttonStyleClass="height-48 desktop-std-btn mt0 mb0 ml-auto mr-auto display-table"
          >
            Close
          </GenericButtonComponent>
        </Display.Modal>
      )}
    </Display.DetailsBoxCard>
  )
}

class IdentityDocuments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      apiError: '',
      showModal: false,
      showConfirmationModal: false,
      teamSettingInfo: {},
      disableNextButtonToFetchSettings: false,
      presentDocsLaterAcknowledged: false,
    }
  }

  componentDidMount() {
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const applicationId = getParameter('applicationId')
    const {
      fetchIdentityDocs,
      fetchCompleteness,
      fetchTeamSettingInformation,
    } = this.props
    const { id, ballot = false, address = '' } = curQueries || {}
    fetchIdentityDocs().catch((error) => this.setState({ apiError: error }))
    fetchCompleteness()
    if (id) {
      this.setState({
        disableNextButtonToFetchSettings: true,
      })
      fetchTeamSettingInformation(id, 'application')
        .then((teamSettingInfo) => {
          this.setState({ teamSettingInfo })
        })
        .catch((error) => {
          this.setState({ error })
        })
        .finally(() =>
          this.setState({ disableNextButtonToFetchSettings: false }),
        )
      const applicationGUID = applicationId?.toString()?.split(',')[0]
      getApplicationConfigForProperty(id, applicationGUID).then(
        ({ agencyName, config = {} }) => {
          const { identity_cap_enabled, identity_cap_enabled_options } =
            config || {}
          this.setState({
            agencyName,
            identityCapEnabled: identity_cap_enabled === 'true',
            identityCapEnabledOptions: identity_cap_enabled_options,
          })
        },
      )
    }
    if (applicationId) {
      this.props.fetchApplication(applicationId)
    }
    if (ballot && ballot === 'true') {
      this.setState({
        isBallotApplication: true,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicationDetails !== this.props.applicationDetails) {
      this.setAgencyOptions()
    }
  }

  setAgencyOptions = () => {
    const { applicationDetails = {} } = this.props
    const {
      publicConfig = {},
      applicant = {},
      guidID: applicationGUID = '',
    } = applicationDetails || {}
    const { agencyOptions = {} } = publicConfig || {}
    const { idDocsNotRequested = false } = agencyOptions || {}
    const { guidID: applicantGUID = '' } = applicant || {}
    this.setState({
      idDocsNotRequested,
    })
    if (!applicationGUID || !applicantGUID) return
    getApplicantAcknowledgementofPoints(applicationGUID, applicantGUID)
      .then((data) => {
        if (data) {
          const { acknowledgeSupplyOfIDDocuments = false } = data || {}
          this.setState({
            presentDocsLaterAcknowledged: acknowledgeSupplyOfIDDocuments,
          })
        }
      })
      /*
      FIXME: api errors should not override each other,
      and should deal with each api error differently depending on its source
     */
      .catch((error) => this.setState({ apiError: error?.message }))
  }

  onDeleteSupportingDocument = (docID) => {
    const { deleteIdentityDocAttachment, fetchIdentityDocs } = this.props
    deleteIdentityDocAttachment(0, docID, false)
      .then(() => {
        fetchIdentityDocs().catch((error) => this.setState({ apiError: error }))
      })
      .catch((error) => this.setState({ attachmentError: error }))
  }

  onModalSecondaryButtonClicked() {
    history.push(`${urlTo('profileAvatar')}${window.location.search}`)
  }

  onToggleConfirmationModal = () => {
    this.setState({
      showConfirmationModal: !this.state.showConfirmationModal,
    })
  }

  onTogleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  toggleSanitizedRenewalModal = () => {
    this.setState({ renewalModalOpened: !this.state.renewalModalOpened })
  }

  renderSanitizedRenewalModal = () => (
    <GenericModal
      onDismiss={() => this.toggleSanitizedRenewalModal()}
      title="Add Identity Documents"
      modalFooter={
        <Button
          width="100%"
          onClick={() =>
            history.push(
              `${urlTo('identityDocumentAdd')}${window.location.search}`,
            )
          }
        >
          Add
        </Button>
      }
    >
      <AlertSensitiveInfo />
      <TextSpan as="div" mt={3} fontSize={theme.fontSizes.pLarge16}>
        Please remove old ID records and add current ID details.
      </TextSpan>
    </GenericModal>
  )

  validateAndNext = (isFromApplication) => {
    const { identityDocList } = this.props
    const { isBallotApplication, idDocsNotRequested } = this.state

    if (
      isFromApplication &&
      identityDocList.identityDocs?.length > 0 &&
      identityDocList.identityDocs.every((doc) => !!doc.sanitizedAt) &&
      !isBallotApplication
    ) {
      this.toggleSanitizedRenewalModal()
      return
    }
    this.checkProofOfIdentityBeforeNext()
  }

  checkProofOfIdentityBeforeNext = () => {
    const { identityDocList, applicationDetails } = this.props
    const { application } = this.state.teamSettingInfo
    const {
      disableNextButtonToFetchSettings,
      idDocsNotRequested,
      presentDocsLaterAcknowledged,
      isBallotApplication,
      identityCapEnabledOptions,
    } = this.state

    const {
      applicant = {},
      guidID: applicationGUID = '',
      offer = {},
    } = applicationDetails || {}
    const { guidID: applicantGUID = '' } = applicant || {}

    const { property = {} } = offer || {}
    const { address = {} } = property || {}
    const { state = '' } = address || {}

    if (idDocsNotRequested) {
      if (!presentDocsLaterAcknowledged) {
        snugNotifier.error(
          'Please acknowledge the requirements for ID documents to progress',
        )
        return
      }
      updateApplicantAcknowledgementofPoints(
        applicationGUID,
        applicantGUID,
        presentDocsLaterAcknowledged,
      )
        .then(() => {
          snugNotifier.success('Acknowledgement successfully saved')
        })
        .catch((error) => {
          snugNotifier.error(error?.message)
          return
        })
      if (isBallotApplication) {
        history.push(`${urlTo('PetDetails')}${window.location.search}`)
        return
      }
    }
    if (checkIfBGCheckNotSupportedInState(state)) {
      history.push(`${urlTo('profileAvatar')}${window.location.search}`)
      return
    }
    if (
      (application && application.identity_cap_enabled === 'true') ||
      (applicationDetails &&
        applicationDetails.applicationType ===
          applicationHelpers.APPLY_ANYWHERE_APPLICATION)
    ) {
      if (!applicationHelpers.APPLY_ANYWHERE_APPLICATION) {
        if (
          (identityCapEnabledOptions === HUNDRED_POINTS_OPTION_VALUE_STRING &&
            identityDocList.identityCheckPoints >= 100) ||
          (identityCapEnabledOptions === ONE_PRIMARY_ID_OPTION_VALUE_STRING &&
            identityDocList?.identityDocs?.length >= NUMBER_OF_IDS_REQUIRED)
        ) {
          history.push(`${urlTo('BackgroundCheck')}${window.location.search}`)
        } else {
          this.onToggleConfirmationModal()
          return
        }
      } else {
        if (identityDocList.identityCheckPoints >= 100) {
          history.push(`${urlTo('BackgroundCheck')}${window.location.search}`)
        } else {
          this.onToggleConfirmationModal()
          return
        }
      }
    } else {
      if (!disableNextButtonToFetchSettings) {
        history.push(`${urlTo('BackgroundCheck')}${window.location.search}`)
      }
    }
  }

  confirmRemoveIdentityDoc = (id) => {
    if (
      window.confirm('Are you sure you want to delete your identity document')
    ) {
      this.props.removeIdentityDoc(id)
    }
  }

  updatePresentDocsLater = (value) => {
    this.setState({
      presentDocsLaterAcknowledged: value,
    })
  }

  render() {
    const {
      backUrl,
      identityDocList = {},
      property,
      error,
      completeness,
    } = this.props
    const {
      identityDocs = [],
      supportingIdentityDocs = [],
      identityCheckPoints = 0,
    } = identityDocList || {}
    const {
      apiError,
      showModal,
      showConfirmationModal,
      disableNextButtonToFetchSettings,
      idDocsNotRequested = false,
      presentDocsLaterAcknowledged = false,
      renewalModalOpened,
      isBallotApplication,
      identityCapEnabled,
      identityCapEnabledOptions,
      agencyName,
    } = this.state
    const propertyId = getParameter('propertyId')
    const applicationId = getParameter('applicationId')
    const isFromApplicationForm =
      isFromApplication(backUrl) || propertyId || applicationId
    const identityPointCheckHelpURL =
      'https://help.snug.com/hc/en-us/articles/360000868955-100-Point-Check-for-Proof-of-Identity'
    const supportingIdentityDocsAvailable =
      supportingIdentityDocs && supportingIdentityDocs.length !== 0

    const idDocsMessage = idDocsNotRequested ? (
      <></>
    ) : (
      <div>
        Add a current Primary ID with a photo to verify your identity & Snug
        profile. Note a Passport and Drivers Licence is required if you choose
        to add a Background Check.
        <a
          target="_blank"
          href={identityPointCheckHelpURL}
          rel="noopener noreferrer"
        >
          {' '}
          Learn more about ID
        </a>
        .
      </div>
    )

    let show100IDPointsCounter = true
    if (
      identityCapEnabled &&
      identityCapEnabledOptions === ONE_PRIMARY_ID_OPTION_VALUE_STRING
    ) {
      show100IDPointsCounter = false
    }

    return (
      <Display.CenterContentContainer componentClass="width100">
        <ProgressBarComponent
          completeness={completeness}
          clickNextFunctionFromParentComponent={() =>
            this.validateAndNext(isFromApplicationForm)
          }
        />
        <RentalReputationHeaderReminder
          property={property}
          backUrl={backUrl}
          isFromApplicationForm={isFromApplicationForm}
          title="Identity Documents"
        />
        <Header title="Identity Documents" message={idDocsMessage} />
        {!isBallotApplication && (
          <>
            {show100IDPointsCounter && (
              <Display.ProgressLine
                totalPoints={identityCheckPoints}
                recommendedPoints={100}
              >
                <div>
                  {identityCheckPoints}
                  <sub> / 100 points of ID</sub>
                </div>
              </Display.ProgressLine>
            )}

            <AlertSensitiveInfo hideLearnMore={false} />

            <div className="table mb20">
              <div className="cell-m-100">
                <h4>Add identity documents</h4>
              </div>
              <div className="cell-m-100">
                <div className="cell-m">
                  <button
                    className="btn btn-round"
                    onClick={() => this.onTogleModal()}
                  >
                    <i className="icon-add" />
                  </button>
                </div>
              </div>
            </div>
            {identityCapEnabled && (
              <Alert
                variant="blueWithBg"
                showLeftIcon={true}
                leftIconVariant="info"
                mb={5}
              >
                {agencyName} requires{' '}
                {identityCapEnabledNumberOptionsMap[identityCapEnabledOptions]}
              </Alert>
            )}
            <div className="mb50">
              {identityDocs.length > 0 &&
                identityDocs.map((idDoc) => {
                  return (
                    <IdentityDocumentBox
                      key={idDoc.GuidID}
                      identityDoc={idDoc}
                      removeIdentityDoc={this.confirmRemoveIdentityDoc}
                    />
                  )
                })}
            </div>
            {supportingIdentityDocsAvailable && (
              <div className="mb50">
                <h4 className="mb20">Supporting identity documents</h4>
                {supportingIdentityDocs.length !== 0 ? (
                  <DocumentList
                    documents={supportingIdentityDocs}
                    showDocumentType
                    documentTypeTextConverter={idDocumentTypeTextConverter}
                    deleteDocument={this.onDeleteSupportingDocument}
                    showUploader={false}
                  />
                ) : (
                  <div>No information provided</div>
                )}
              </div>
            )}
            {showModal && (
              <Display.Modal
                title="Add New Document"
                toggleModal={this.onTogleModal}
                primaryButtonLabel="Driver Licence or Passport"
                primaryButtonAction={() =>
                  history.push(
                    `${urlTo('identityDocumentAdd')}${window.location.search}`,
                  )
                }
                secondaryButtonLabel="Supporting"
                secondaryButtonAction={() =>
                  history.push(
                    `${urlTo('supportingIdentityDocumentAdd')}${
                      window.location.search
                    }`,
                  )
                }
                secondaryButtonType="green"
                primaryButtonClassName="p5"
                secondaryButtonClassName="p5"
              >
                Please add your drivers licence and passport to confirm
                identity. If you’re missing one/both of these, please add a
                supporting document.
                <AlertSensitiveInfo hideLearnMore={false} />
              </Display.Modal>
            )}
            {showConfirmationModal && (
              <BasicModal
                title="Add More Identity Documents"
                toggleModal={this.onToggleConfirmationModal}
                body={stringHelpers.IDENTITY_POINT_ERROR_TEXT}
                primaryButtonLabel="Add Identity"
                primaryButtonAction={() => {
                  this.onToggleConfirmationModal()
                  this.onTogleModal()
                }}
                secondaryButtonLabel="Close"
                secondaryButtonAction={this.onToggleConfirmationModal}
              />
            )}
            {error && <ErrorMessage error={error} />}
            {apiError && <ErrorMessage error={apiError} />}
            <ReactTooltip className="tooltip" />
          </>
        )}
        {idDocsNotRequested && (
          <>
            <Alert variant="blueWithBg" mb={5}>
              <Box>
                You will be required to provide 100 points of ID or equivalent
                if your application is successful. Existing ID information in
                your profile will not be shared.
              </Box>
            </Alert>

            <CheckBoxGeneral
              label="I agree to present 100 points of ID in person prior to signing the lease"
              id="presentDocsLaterAcknowledged"
              onChange={(e) => this.updatePresentDocsLater(e.value)}
              checked={presentDocsLaterAcknowledged}
              checkboxLabelSpanClassName="fs16 height-auto"
            />
          </>
        )}
        {renewalModalOpened && this.renderSanitizedRenewalModal()}
        {error && <ErrorMessage error={error} />}
        {apiError && <ErrorMessage error={apiError} />}
        <ReactTooltip className="tooltip" />

        <Display.BottomContentContainer>
          <Display.CenterContentContainer componentClass="width100">
            <div className="submit-button-container">
              <BottomButton
                clickNextFunction={() =>
                  this.validateAndNext(isFromApplicationForm)
                }
                nextUrl={
                  isFromApplicationForm
                    ? `${urlTo('ProfileBuildSuccess')}${window.location.search}`
                    : `${urlTo('profileAvatar')}`
                }
                btnText={
                  isFromApplicationForm
                    ? 'Save & Continue'
                    : 'Next: Background Check'
                }
                bottomButtonsClass="pt0 mt0 summary-bottom-button"
                nextBtnClass={`summary-green-button-class`}
                backButtonClass="summary-grey-button-class"
                nextButtonPositionClass="pr20 pl0"
                showArrow={false}
                nextBtnDisabled={disableNextButtonToFetchSettings}
              />
            </div>
          </Display.CenterContentContainer>
        </Display.BottomContentContainer>
      </Display.CenterContentContainer>
    )
  }
}

export default IdentityDocuments
