import React from 'react'

import { Field, Form } from 'formik'

import { Alert, Button } from 'app/components/design-system-components'
import {
  DateTimeField,
  InputField,
  TextArea,
} from 'app/components/design-system-components/inputs'
import { SelectField } from 'app/components/design-system-components/inputs/Select/Select'
import {
  borrowingReasons,
  borrowingReasonsOptions,
} from 'app/constants/key-logger.constants'
import {
  formFields,
  isValidDueDate,
} from 'app/features/teams/key-logger/CheckoutKeySetModal/BorrowingDetails/form.utils'
import {
  LabelSubText,
  StepLayoutContainer,
  StyledFieldWrapper,
} from 'app/features/teams/key-logger/components/StepLayoutContainer'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const borrowingReasonsSelectOptions = Object.values(borrowingReasons).map(
  (reason) => ({
    option: reason,
    optionMeta: { id: reason, displayText: borrowingReasonsOptions[reason] },
  }),
)

export const FormBody = ({
  teamId,
  formBag: {
    isValid,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    status,
    values,
    touched,
    dirty,
  },
}) => {
  const actionsElem = (
    <Button type="submit" disabled={!isValid || isSubmitting} width="100%">
      Check out
    </Button>
  )
  return (
    <Form style={{ height: '100%' }}>
      <StepLayoutContainer actions={actionsElem}>
        {!!status?.apiGeneralError && (
          <Alert variant="warningWithBg" m={`${theme.space[3]}px 0`}>
            {status.apiGeneralError}
          </Alert>
        )}

        <StyledFieldWrapper
          id={formFields.reason}
          name={formFields.reason}
          label="Reason for checking out"
          labelProps={{
            subText: (
              <LabelSubText>Please select a reason for borrowing</LabelSubText>
            ),
          }}
          required
        >
          <SelectField
            id={formFields.reason}
            name={formFields.reason}
            label="Select reason"
            options={borrowingReasonsSelectOptions}
          />
        </StyledFieldWrapper>

        <StyledFieldWrapper
          id={formFields.dueDate}
          name={formFields.dueDate}
          label="What is the return Due Date?"
          required
          style={{ position: 'relative' }}
        >
          <DateTimeField
            id={formFields.dueDate}
            name={formFields.dueDate}
            placeholder="Select date"
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            isValidDate={isValidDueDate}
          />
        </StyledFieldWrapper>

        <StyledFieldWrapper
          id={formFields.notes}
          name={formFields.notes}
          label="Check out notes (private to team)"
        >
          <Field name={formFields.notes} as={TextArea} />
        </StyledFieldWrapper>

        <StyledFieldWrapper
          name={formFields.alarmCode}
          id={formFields.alarmCode}
          label="Property alarm code"
        >
          <InputField name={formFields.alarmCode} id={formFields.alarmCode} />
        </StyledFieldWrapper>

        <StyledFieldWrapper
          id={formFields.accessNotes}
          name={formFields.accessNotes}
          label="Property access notes"
        >
          <Field name={formFields.accessNotes} as={TextArea} />
        </StyledFieldWrapper>
      </StepLayoutContainer>
    </Form>
  )
}
