import * as React from 'react'

function SvgWarningAmberRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.47 20.504c-1.54 0-2.5-1.67-1.73-3l7.53-13.01c.77-1.33 2.69-1.33 3.46 0l7.53 13.01c.77 1.33-.19 3-1.73 3H4.47zm15.06-2L12 5.494l-7.53 13.01h15.06zm-8.53-8v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zm2 7v-2h-2v2h2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgWarningAmberRounded
