import React from 'react'

import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'
import AddressForm from 'app/shared_components/address_form/address_form'

const StyledAddressForm = styled(AddressForm)``

const AddressComponent = ({
  resetAddress,
  addressValidation,
  handleAddressChanged,
  handleManualAddressChanged,
  address,
  addressErr,
}) => {
  return (
    <div className="col-md-12 p0 pt20">
      <h4 className="agency-title">Office Address</h4>
      <StyledAddressForm
        address={address}
        updateAddress={(e) => handleManualAddressChanged(e)}
        updateGoogleAddress={(e) => handleAddressChanged(e)}
        resetAddress={resetAddress()}
        placeholder=""
        error={!addressValidation.ok}
        country={['au']}
      />

      {addressErr && (
        <Box
          fontSize={theme.fontSizes[4] + 'px'}
          p={theme.space[3] + 'px'}
          id={'address-api-error'}
          style={{ color: 'red' }}
        >
          {addressErr}
        </Box>
      )}
    </div>
  )
}

export default AddressComponent
