import { useState } from 'react'

import EFTPaymentsSettingDelModal from 'app/bond_cover/agency/EFT_payments_setting_delete_modal'
import * as validations from 'app/shared_components/validations'

const dfValue = {
  EFTPaymentBSB: '',
  EFTPaymentAccountNum: '',
  EFTPaymentAccountName: '',
}

function EFTPaymentsSetting(props) {
  const { EFTPaymentEnabled, disabled, errors, paymentsSetting, control } =
    props

  const [delModalInfo, setDelModalInfo] = useState()

  const onUpdate = (field, idx) => (e) => {
    const value = e.target.value

    paymentsSetting[idx] = {
      ...paymentsSetting[idx],
      [field]: value,
    }

    const event = {
      target: {
        value: [...paymentsSetting],
      },
    }

    control.update('EFTPaymentsV2')(event)
  }

  const getFieldWithIdx = (field, idx) =>
    idx === 0 ? field : field + '_' + idx

  const onValidate = (field, idx) => (e) => {
    let value = paymentsSetting[idx][field]

    const fieldWithIdx = getFieldWithIdx(field, idx)

    if (value) {
      switch (field) {
        case 'EFTPaymentBSB':
          return control.setValidationErrors(
            fieldWithIdx,
            validations.validateBSB(
              value.replace(/\D/g, ''),
              'Invalid EFT Payment BSB',
            ),
          )
        case 'EFTPaymentAccountNum':
          return control.setValidationErrors(
            fieldWithIdx,
            validations.validateBankAccount(
              value.replace(/\D/g, ''),
              'Invalid EFT Payment Account',
            ),
          )
        case 'EFTPaymentAccountName':
          return control.setValidationErrors(
            fieldWithIdx,
            validations.validateAccountName(
              value.trim(),
              'Invalid EFT Account Name',
            ),
          )
        default:
          return false
      }
    }
  }

  const onAddNewOne = () => {
    const event = {
      target: {
        value: [...paymentsSetting, { ...dfValue }],
      },
    }

    control.update('EFTPaymentsV2')(event)
  }

  const onRemoveOne = (idx) => {
    setDelModalInfo({
      idx,
      item: paymentsSetting[idx],
    })
  }

  const handleRemoveOne = () => {
    const newPaymentsSetting = paymentsSetting.filter(
      (_, i) => i !== delModalInfo.idx,
    )

    const event = {
      target: {
        value: newPaymentsSetting,
      },
    }

    setDelModalInfo(null)

    control.update('EFTPaymentsV2')(event)
  }

  console.log('paymentsSetting', paymentsSetting)

  return (
    <div className="col-sm-8 mb40">
      <div className="">
        {EFTPaymentEnabled
          ? paymentsSetting.map(
              (
                { EFTPaymentBSB, EFTPaymentAccountNum, EFTPaymentAccountName },
                idx,
              ) => (
                <div className="row position-relative" key={idx}>
                  <div className="col-sm-3">
                    <div className="input-box">
                      <input
                        type="text"
                        required
                        className={
                          errors[getFieldWithIdx('EFTPaymentBSB', idx)] &&
                          disabled
                            ? 'error'
                            : ''
                        }
                        value={EFTPaymentBSB}
                        onChange={onUpdate('EFTPaymentBSB', idx)}
                        onBlur={onValidate('EFTPaymentBSB', idx)}
                      />
                      <label>BSB</label>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-box">
                      <input
                        type="text"
                        required
                        className={
                          errors[
                            getFieldWithIdx('EFTPaymentAccountNum', idx)
                          ] && disabled
                            ? 'error'
                            : ''
                        }
                        value={EFTPaymentAccountNum}
                        onChange={onUpdate('EFTPaymentAccountNum', idx)}
                        onBlur={onValidate('EFTPaymentAccountNum', idx)}
                      />
                      <label>Acc. Num</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-box">
                      <input
                        type="text"
                        required
                        className={
                          errors[
                            getFieldWithIdx('EFTPaymentAccountName', idx)
                          ] && disabled
                            ? 'error'
                            : ''
                        }
                        value={EFTPaymentAccountName}
                        onChange={onUpdate('EFTPaymentAccountName', idx)}
                        onBlur={onValidate('EFTPaymentAccountName', idx)}
                      />
                      <label>Acc. Name</label>
                    </div>
                  </div>

                  <div
                    className="textButton linkStyle-blue position-absolute top-center-right-0"
                    onClick={onRemoveOne.bind(null, idx)}
                  >
                    remove
                  </div>
                </div>
              ),
            )
          : null}
      </div>
      <div
        className="textButton linkStyle-blue text-align-left"
        onClick={onAddNewOne}
      >
        add another
      </div>

      {delModalInfo && (
        <EFTPaymentsSettingDelModal
          ok={handleRemoveOne}
          cancel={() => {
            setDelModalInfo(null)
          }}
          payment={delModalInfo.item}
        />
      )}
    </div>
  )
}

export default EFTPaymentsSetting
