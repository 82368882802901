import { connect } from 'react-redux'

import { fetchRenterApplication } from 'app/sm/renter_applications/renter_applications_actions'
import {
  fetchApplicationApplicantBackgroundCheckReportDetails,
  fetchBackgroundCheckReportDetails,
} from 'app/sm/renter_background_check_report/action'
import NewBackgroundCheckReportContainer from 'app/sm/renter_background_check_report/new_backgroundcheck_report_container'

const mapStateToProps = ({ session, RenterApplications }) => ({
  currentUser: session.currentUser,
  application: RenterApplications.application,
})

const mapDispatchToProps = (dispatch) => ({
  fetchBackgroundCheckReportDetails: (params) =>
    dispatch(fetchBackgroundCheckReportDetails(params)),
  fetchApplicationApplicantBackgroundCheckReportDetails: (
    applicationGUID,
    applicantGUID,
  ) =>
    dispatch(
      fetchApplicationApplicantBackgroundCheckReportDetails(
        applicationGUID,
        applicantGUID,
      ),
    ),
  fetchRenterApplication: (applicationId, applicantId, setting) =>
    dispatch(fetchRenterApplication(applicationId, applicantId, setting)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewBackgroundCheckReportContainer)
