import React from 'react'

import { Link } from 'react-router-dom'

import PayContainer from 'app/shared_components/payment_form/pay_container'
import { urlTo } from 'app/sm/helpers'

class PhDeposit extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { applicationId } = this.props.match.params
    this.props.fetchCards()
    this.props.fetchOfferedApplication(applicationId)
  }

  isPaymentDisabled() {
    const { acceptTermsAndConditions } = this.props
    return !acceptTermsAndConditions
  }

  render() {
    const {
      error,
      cards,
      acceptTermsAndConditions,
      selectedCreditCard,
      cardSelect,
      offeredApplication,
      attempts,
    } = this.props
    const { Application } = offeredApplication

    const { applicationId, propertyId } = this.props.match.params
    const teamSlug =
      offeredApplication.offer && offeredApplication.offer.property.teamSlug
    return (
      <PayContainer
        {...this.props}
        showCards={true}
        autoSave={true}
        backUrl={urlTo('offeredApplication', { propertyId, applicationId })}
        payForWhat={'Pay Holding Deposit'}
        amount={Application.weeklyRent}
        buttonParole={`Pay ${Application.weeklyRent} for holding deposit`}
        error={error}
        attempts={attempts}
        teamSlug={teamSlug}
      />
    )
  }
}

export default PhDeposit
