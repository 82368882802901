import React from 'react'

import moment from 'moment'

import ReviewForm from 'app/bond_cover/managers/review/bc_review_form'
import ReviewedForm from 'app/bond_cover/managers/review/bc_reviewed_form'
import { history } from 'app/shared_components/router'
import StatusBar from 'app/shared_components/status_bar'
import { SessionStorageUtils } from 'app/storage_utils'

class ReviewFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: false,
      requestID: '',
      secret: '',
      permissionClicked: false,
      declineClicked: false,
    }
  }

  UNSAFE_componentWillMount() {
    let { fetchBondCoverReview, currentUser } = this.props
    this.setState({
      disabled: false,
      permissionClicked: false,
      declineClicked: false,
    })
    let requestID = this.getParameter('requestid')
    let secret = this.getParameter('secret')
    this.setState({ requestID: requestID, secret: secret })
    fetchBondCoverReview(requestID, secret)
  }

  UNSAFE_componentWillUpdate() {
    const { currentUser } = this.props
    const requestID = this.getParameter('requestid')
    const secret = this.getParameter('secret')
    if (
      currentUser.isUserFetching ||
      currentUser.isUserProfileFetching ||
      currentUser.isVerified
    ) {
      SessionStorageUtils.removeItem('forwardUrl')
    } else {
      if (!!requestID && !!secret) {
        let forwardUrl = `/bc/review?requestid=${requestID}&secret=${secret}`
        SessionStorageUtils.setItem('forwardUrl', forwardUrl)
      }
    }
  }

  getParameter = (field) => {
    let url = window.location.href
    field = field.replace(/[\[\]]/g, '\\$&')
    let regex = new RegExp('[?&]' + field + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  decline = () => {
    let { bondCoverRequest, decline } = this.props
    this.setState({ disabled: true, declineClicked: true })
    let { secret } = this.state
    let navigateUrl =
      window.location.href.indexOf('agency') > 0
        ? `/agency/bc/decline?requestid=${bondCoverRequest.guidID}&secret=${secret}`
        : `/bc/decline?requestid=${bondCoverRequest.guidID}&secret=${secret}`
    history.push(navigateUrl)
  }

  render() {
    let {
      currentUser,
      currentLease,
      renter,
      error,
      message,
      bondCoverRequest,
    } = this.props
    let { disabled, requestID, secret, permissionClicked, declineClicked } =
      this.state
    let { isVerified } = currentUser
    let { address } = currentLease ? currentLease : ''
    let navigateUrl =
      window.location.href.indexOf('agency') > 0
        ? '/agency/bc/rating'
        : '/bc/rating'
    let dashboardUrl =
      window.location.href.indexOf('agency') > 0
        ? '/agency/4824a732-2475-4ac7-8bfb-d27b5e35a45e/bc/dashboard'
        : '/portfolio/overview'
    const { status = '' } = bondCoverRequest ? bondCoverRequest : ''
    if (error || message) {
      return (
        <ReviewedForm
          error={error}
          isVerified={isVerified}
          dashboardUrl={dashboardUrl}
          message={message}
        />
      )
    } else {
      return (
        <div className="two-col-box-flex-row sm64">
          <div className="col-first">
            <StatusBar dashboardUrl={dashboardUrl} progress="1" />
            <ReviewForm
              address={address}
              currentLease={currentLease}
              disabled={disabled}
              permissionClicked={permissionClicked}
              declineClicked={declineClicked}
              decline={this.decline}
              renter={renter}
              requestID={requestID}
              navigateUrl={navigateUrl}
              secret={secret}
              status={status}
            />
          </div>
        </div>
      )
    }
  }
}

export default ReviewFormContainer
