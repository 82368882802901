import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import checkMarkIconUrl from 'app/assets/icons/checkmark-with-bg.svg'
import { Flex } from 'app/components/design-system-components'
import { HelpOutlineRounded } from 'app/components/design-system-components/icons/actions'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { Button } from 'app/shared_components/form_component'

const Email_REPORT_HELP_TEXT =
  'Email report scheduled to send as per the weekday selection'

const SendEmailReportButton = ({
  isViewingReportEmailEnabled: isAutoSendEnabled,
  viewingReportEmailSent: viewingReportEmailAutoSent,
  buttonClassName,
  buttonOnClick,
  viewingGUID,
  disabled = false,
  showSpinner = false,
  isReportSent = false,
}) => {
  const isReportAutoSent = isAutoSendEnabled && viewingReportEmailAutoSent
  let emailReportButtonLabel
  if (isReportSent) emailReportButtonLabel = 'View Email Report'
  else
    emailReportButtonLabel = isAutoSendEnabled
      ? 'Auto Email Report'
      : 'Send Email Report'

  const tooltipId = `email-${viewingGUID}`

  const buttonLabel = (
    <div className="display-inline-block">
      {isReportAutoSent && (
        <img src={checkMarkIconUrl} alt="report sent" className="mr10" />
      )}
      <span>{emailReportButtonLabel}</span>
    </div>
  )
  return (
    <Flex alignItems="center">
      <Button
        className={
          isReportAutoSent
            ? 'background-color-gray-with-opacity-green-hover green btn-medium'
            : buttonClassName
        }
        onClick={buttonOnClick}
        disabled={disabled}
        label={buttonLabel}
        loading={showSpinner}
      />
      {isReportAutoSent && (
        <Flex
          fontSize={theme.fontSizes[9] + 'px'}
          color={theme.colors.gray500}
          ml={1}
        >
          <HelpOutlineRounded
            data-tooltip-id={tooltipId}
            data-tooltip-content=""
          />
          <ReactTooltip data-type="info" place="top" id={tooltipId}>
            <span>{Email_REPORT_HELP_TEXT}</span>
          </ReactTooltip>
        </Flex>
      )}
    </Flex>
  )
}

export default SendEmailReportButton
