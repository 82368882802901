import React from 'react'

import $ from 'jquery'
import qs from 'qs'

import RatingForm from 'app/bond_cover/renters/history/bc_rating_form'
import * as Display from 'app/components/display/display'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import * as snugLogger from 'app/services/snugLogger'
import * as snugNotifier from 'app/services/snugNotifier'
import { ErrorMessage } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import StatusBar from 'app/shared_components/status_bar'
import { parseAnswer, urlTo } from 'app/sm/helpers'

const getParameter = (field) => {
  const url = window.location.href
  // eslint-disable-next-line no-useless-escape
  const updateField = field.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${updateField}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const getAnswerValue = (code, tenantRating, prop) => {
  const targetRating = tenantRating.find(
    (ratingAnswer) => ratingAnswer[prop] === code,
  )
  if (targetRating) return parseAnswer(targetRating)
  return ''
}

const getRating = (code, tenantRating, prop) => {
  for (let i = 0; i < tenantRating.length; i++) {
    if (tenantRating[i][prop] === code) {
      return tenantRating[i].rating
    }
  }
  return -1
}

class RatingFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: false,
      permissionClicked: false,
      declineClicked: false,
      comment: '',
      disabledRating: false,
      learnMoreFromSnug: false,
    }
  }

  componentDidMount() {
    const {
      isHistoryRating,
      fetchRentalHistoryRatingCategories,
      fetchBondCoverReview,
    } = this.props
    this.setState({
      disabled: false,
      permissionClicked: false,
      declineClicked: false,
    })

    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    this.setState({
      applicationId: curQueries.applicationId,
      applicantId: curQueries.applicantId,
    })
    $('#index').addClass('no-navbar-menu')

    fetchRentalHistoryRatingCategories() // We are using this both at bondCover and rentalHistory ratings
    if (isHistoryRating) {
      const {
        match: {
          params: { referenceId },
        },
      } = this.props
      this.props.fetchRentalHistoryReview(referenceId)
    } else {
      const requestCode = getParameter('requestcode')
      fetchBondCoverReview(requestCode)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { tenantRating } = nextProps

    const { comment } = tenantRating.find((T) => T.Code === 'overall') || {
      comment: undefined,
    }
    if (comment !== undefined) {
      this.setState({ comment })
    }
  }

  componentWillUnmount() {
    $('#index').removeClass('no-navbar-menu')
  }

  onLearnMoreClicked = () => {
    this.setState({
      learnMoreFromSnug: !this.state.learnMoreFromSnug,
    })
  }

  decline = () => {
    const { isHistoryRating, RentalHistory } = this.props
    const { applicantId = '', applicationId = '' } = this.state
    this.setState({ disabled: true, declineClicked: true })
    const isFromRentalApplication = applicantId && applicationId
    if (isFromRentalApplication) {
      history.goBack()
    } else if (isHistoryRating) {
      const { guidID: referenceId } = RentalHistory
      this.props.decline(referenceId, {
        DeclineReason: 5 /* Other Reason */,
        Description: this.state.comment,
      })
      return
    }
    history.push(urlTo('join'))
  }

  proceed = () => {
    const {
      isHistoryRating,
      proceed,
      bondCoverRequest,
      RentalHistory,
      addNote,
      tenantRating,
    } = this.props
    const {
      learnMoreFromSnug,
      applicationId = '',
      applicantId = '',
    } = this.state
    if (learnMoreFromSnug) {
      snugLogger.warn('No contact exporter')
    }

    const { comment } = this.state
    const commentPost = { comment, applicationId, applicantId }

    const addNotePayload = {
      type: 1,
      target: 13,
      content: '',
    }
    if (
      tenantRating &&
      tenantRating.length > 0 &&
      tenantRating.find((rating) => rating.code === 'overall')
    ) {
      this.setState({ disabled: true, permissionClicked: true })

      if (isHistoryRating) {
        proceed(RentalHistory.guidID, commentPost)
          .then(() => addNote(applicationId, applicantId, addNotePayload))
          .then(() => {
            history.goBack()
          })
        return
      }

      proceed(bondCoverRequest.guidID, commentPost)
        .then(() => addNote(applicationId, applicantId, addNotePayload))
        .then(() => {
          history.goBack()
        })
    } else {
      snugNotifier.error('Overall rating is required')
    }
  }

  update = (field) => {
    return (event) => this.setState({ [field]: event.target.value })
  }

  updateTenantRating = (ratingCode, answerValue, requestCode, questionType) => {
    const {
      isHistoryRating,
      createTenantRating,
      updateTenantRating,
      tenantRating,
    } = this.props
    if (this.state.disabledRating) return

    this.setState({ disabledRating: true })
    if (isHistoryRating) {
      const {
        match: {
          params: { referenceId },
        },
      } = this.props
      const shouldUpdate = !!tenantRating.find((T) => T.code === ratingCode)
      if (shouldUpdate) {
        updateTenantRating(referenceId, {
          ratingCode,
          comment: '',
          answerValue,
          questionType,
        }).then(() => {
          this.setState({ disabledRating: false })
        })
      } else {
        createTenantRating(referenceId, {
          ratingCode,
          comment: '',
          answerValue,
          questionType,
        }).then(() => {
          this.setState({ disabledRating: false })
        })
      }
    }
  }

  render() {
    const {
      isHistoryRating,
      RentalHistory = {},
      ratingCategories,
      tenantRating,
      renter,
      error,
      currentLease,
      bondCoverRequest,
      message,
      addRentalLedger,
      removeRentalLedger,
      showRentalLedgerSpinner,
      match: {
        params: { secretGUID },
      },
      rentalHistoryReview,
    } = this.props

    const { ledger } = RentalHistory
    if (isHistoryRating && message) {
      return <div className="alert alert-success"> {message} </div>
    }

    const { address } = isHistoryRating ? RentalHistory : currentLease

    const { guidID } = isHistoryRating ? RentalHistory : bondCoverRequest

    const {
      disabled,
      permissionClicked,
      declineClicked,
      comment,
      disabledRating,
      learnMoreFromSnug,
    } = this.state
    return (
      <div>
        {!error && (
          <Display.CenterContentContainer componentClass="application-standard-shadow-box col-xs-12 summary-box-on-mobile">
            {!isHistoryRating && <StatusBar progress="2" />}

            {guidID && (
              <RatingForm
                isHistoryRating={isHistoryRating}
                address={address}
                tenantRating={tenantRating}
                getRating={getRating}
                update={this.update}
                comment={comment}
                updateTenantRating={this.updateTenantRating}
                disabled={disabled}
                renter={renter}
                ratingCategories={ratingCategories}
                requestCode={guidID}
                referenceId={guidID}
                permissionClicked={permissionClicked}
                declineClicked={declineClicked}
                proceed={this.proceed}
                decline={this.decline}
                addRentalLedger={addRentalLedger}
                removeRentalLedger={removeRentalLedger}
                showRentalLedgerSpinner={showRentalLedgerSpinner}
                ledger={ledger}
                secretGUID={secretGUID}
                rentalHistoryReview={rentalHistoryReview}
                disabledRating={disabledRating}
                getAnswerValue={getAnswerValue}
                learnMoreFromSnug={learnMoreFromSnug}
                onLearnMoreClicked={this.onLearnMoreClicked}
              />
            )}
          </Display.CenterContentContainer>
        )}
        {error && (
          <Display.CenterContentContainer componentClass="col-xs-12">
            {error && (
              <ErrorMessage error={translateErrorCodeToMessage(error)} />
            )}
          </Display.CenterContentContainer>
        )}
      </div>
    )
  }
}

export default RatingFormContainer
