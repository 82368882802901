import React from 'react'

import headerImg01 from 'app/assets/icons/header-img-01.png'

const OwnerWithdraw = ({
  withdrawReason,
  update,
  updateNumber,
  withdraw,
  disabled,
  error,
}) => {
  let details = (
    <div>
      <p className="mb10">
        Can you please give us a bit more detail in the comment section below?
      </p>
      <div className="input-box mb30">
        <textarea required rows="4" onChange={update('description')}></textarea>
        <label>Please provide more details</label>
      </div>
    </div>
  )
  return (
    <div className="two-col-box-flex-row sm64">
      <div className="col-first">
        <div className="ilustration-box">
          <img src={headerImg01} />
        </div>

        <h3 className="mb25">
          Why are you withdrawing this BondCover application?
        </h3>

        <label className="display-flex mb15">
          <input
            type="radio"
            name="manager"
            value="1"
            onClick={updateNumber('')}
          />
          <span className="height-auto">I no longer need BondCover</span>
        </label>

        <label className="display-flex mb15">
          <input
            type="radio"
            name="manager"
            value="2"
            onClick={updateNumber('')}
          />
          <span className="height-auto">I've decided not to use BondCover</span>
        </label>

        <label className="display-flex mb15">
          <input
            type="radio"
            name="manager"
            value="3"
            onClick={updateNumber('')}
          />
          <span className="height-auto">
            My application details have changed
          </span>
        </label>

        <label className="display-flex mb15">
          <input
            type="radio"
            name="manager"
            value="4"
            onClick={updateNumber('')}
          />
          <span className="height-auto">
            My Property Manager / Owner will not accept BondCover
          </span>
        </label>

        <label className="display-flex mb15">
          <input
            type="radio"
            name="manager"
            value="5"
            onClick={updateNumber(`Other`)}
          />
          <span className="height-auto">Other</span>
        </label>

        {withdrawReason === 5 ? details : ''}

        <button disabled={disabled} onClick={withdraw}>
          Submit{' '}
        </button>

        {error && <div className="alert alert-danger"> {error} </div>}

        <p className="fs12 mt25 mb0 gray-color">
          Your answer will only be visible to Snug
        </p>
      </div>
    </div>
  )
}

export default OwnerWithdraw
