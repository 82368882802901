import React from 'react'

const BankAccount = ({ bankAccount }) => {
  let bankAccountHtml = (
    <div className="contentBlock">
      <header>
        <span>No Bank Account Found</span>
      </header>
    </div>
  )
  if (bankAccount && bankAccount.guidID) {
    let { guidID, accountName, bankName, bsb, accountNumber } = bankAccount
    bankAccountHtml = (
      <div className="contentBlock">
        <header>
          <span>Bank Account</span>
        </header>
        <content>
          <p>
            Account Name: {accountName}
            <br />
            BSB: {bsb} <br />
            Account Number: {accountNumber}
            <br />
          </p>
        </content>
      </div>
    )
  }
  return <div>{bankAccountHtml}</div>
}

export default BankAccount
