import {
  CLEAR_ERROR,
  PING,
  RECEIVE_ERROR,
  SET_PROPERTY_ID,
  TEXT_CHANGED,
} from 'app/sm/ask_question/ask_question_actions'

const _defaultState = {
  propertyId: null,
  question: {
    text: '',
  },
  error: '',
}

const AskQuestionReducer = (state = _defaultState, action) => {
  let { type, text, propertyId, error } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case PING:
      return Object.assign(newState, { error: '' })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case SET_PROPERTY_ID:
      return Object.assign(newState, {
        propertyId,
      })
    case TEXT_CHANGED:
      return Object.assign(newState, {
        question: Object.assign(newState.question, { text }),
      })
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    default:
      return state
  }
}

export default AskQuestionReducer
