import React, { useEffect, useState } from 'react'

import DateTime from 'react-datetime'

import {
  Box,
  Button,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'
import { Select } from 'app/components/design-system-components/inputs/Select/Select'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as Form from 'app/shared_components/form_component'

import 'app/sm/viewings_new_run/schedule_list/style.scss'

export const modeDriving = 'DRIVING'
export const modeWalking = 'WALKING'

export const MODE_DRIVING_NEW_VERSION = 'car'
export const MODE_WALKING_NEW_VERSION = 'foot'

const startPropertyField = 'startPropertyGUID'
const endPropertyField = 'endPropertyGUID'

const PreferenceOptionContainer = ({ children }) => {
  return <div className="display-flex mt20">{children}</div>
}

const ScheduleListContainer = ({
  startTime,
  travelMode,
  scheduleBreaks,
  breakDuration,
  viewingDuration,
  startPropertyGUID = '',
  endPropertyGUID = '',
  onPreferenceOptionChanged,
  viewingPropertyInfos,
  managerGUID,
  defaultSelectedViewingDuration,
  defaultSelectedViewingDurationEnabled,
}) => {
  const [localViewingDuration, setLocalViewingDuration] =
    useState(viewingDuration)

  const getPropertyAddressDisplay = (address) =>
    `${address.friendlyName}${!!address.suburb && `, ${address.suburb}`} `

  const [propertyOptions, setPropertyOptions] = useState()
  const [startProperty, setStartProperty] = useState()
  const [endProperty, setEndProperty] = useState()

  useEffect(() => {
    const newPropertyOptions = Object.values(viewingPropertyInfos).map(
      (viewing) => ({
        option: viewing.property.guidID,
        optionMeta: {
          id: viewing.property.guidID,
          displayText: getPropertyAddressDisplay(viewing.property.address),
        },
      }),
    )
    setPropertyOptions(newPropertyOptions)
    const startPropertyID =
      newPropertyOptions.find(
        (opt) => opt.optionMeta.id === startPropertyGUID,
      ) || ''
    if (startPropertyID) {
      setStartProperty(startPropertyID)
    }

    const endPropertyID =
      newPropertyOptions.find((opt) => opt.optionMeta.id === endPropertyGUID) ||
      ''
    if (endPropertyID) {
      setEndProperty(endPropertyID)
    }
  }, [viewingPropertyInfos])

  useEffect(() => {
    if (startProperty?.optionMeta?.id) {
      onPreferenceOptionChanged(
        managerGUID,
        startPropertyField,
      )(startProperty?.optionMeta?.id)
    } else {
      onPreferenceOptionChanged(managerGUID, startPropertyField)('')
    }
    if (endProperty?.optionMeta?.id) {
      onPreferenceOptionChanged(
        managerGUID,
        endPropertyField,
      )(endProperty?.optionMeta?.id)
    } else {
      onPreferenceOptionChanged(managerGUID, endPropertyField)('')
    }
  }, [startProperty, endProperty])

  useEffect(() => {
    if (defaultSelectedViewingDurationEnabled) {
      handleViewingDurationChanged(defaultSelectedViewingDuration)
    }
  }, [defaultSelectedViewingDurationEnabled])

  const handleViewingDurationChanged = (value) => {
    setLocalViewingDuration(value)

    onPreferenceOptionChanged(managerGUID, 'viewingDuration')(value)
  }

  const timeConstraints = {
    minutes: {
      step: 5,
    },
  }
  return (
    <div>
      <div className="new-run-schedule-container">
        <div className="new-run-schedule-preference">
          <PreferenceOptionContainer>
            <div className="mr15 font-size-18 preference-label">Start time</div>
            <DateTime
              dateFormat={false}
              timeFormat={'hh:mm A'}
              inputProps={{
                placeholder: '',
                className: `datePickerStyle `,
              }}
              onChange={(value) =>
                onPreferenceOptionChanged(managerGUID, 'startTime')(value)
              }
              value={startTime.format('hh:mm A')}
              timeConstraints={timeConstraints}
            />
          </PreferenceOptionContainer>
          <PreferenceOptionContainer>
            <Flex alignItems="center">
              <Box width="200px">
                <TextSpan
                  as="span"
                  color={theme.colors.gray400}
                  fontSize={theme.fontSizes.pExtraLarge18}
                  fontWeight={theme.fontWeights[4]}
                >
                  Start property
                </TextSpan>
              </Box>
              <Flex>
                <Select
                  options={propertyOptions}
                  value={startProperty}
                  changeHandler={setStartProperty}
                  label="Select a property"
                  minWidth="250px"
                  mr={4}
                />
                {!!startProperty && (
                  <Button
                    variant="outline"
                    onClick={() => setStartProperty(null)}
                  >
                    Clear
                  </Button>
                )}
              </Flex>
            </Flex>
          </PreferenceOptionContainer>
          <PreferenceOptionContainer>
            <Flex alignItems="center">
              <Box width="200px">
                <TextSpan
                  as="span"
                  color={theme.colors.gray400}
                  fontSize={theme.fontSizes.pExtraLarge18}
                  fontWeight={theme.fontWeights[4]}
                >
                  End property
                </TextSpan>
              </Box>
              <Flex>
                <Select
                  options={propertyOptions}
                  value={endProperty}
                  changeHandler={setEndProperty}
                  label="Select a property"
                  minWidth="250px"
                  mr={4}
                />
                {!!endProperty && (
                  <Button
                    variant="outline"
                    onClick={() => setEndProperty(null)}
                  >
                    Clear
                  </Button>
                )}
              </Flex>
            </Flex>
          </PreferenceOptionContainer>
          <PreferenceOptionContainer>
            <div className="mr15 font-size-18 preference-label">
              Travel mode
            </div>
            <Form.Checkbox
              label="Car"
              checked={travelMode === modeDriving}
              onChange={() => {
                onPreferenceOptionChanged(
                  managerGUID,
                  'travelMode',
                  modeDriving,
                )
              }}
              componentClassName={'mr15'}
            />
            <Form.Checkbox
              label="Foot"
              checked={travelMode === modeWalking}
              onChange={() => {
                onPreferenceOptionChanged(
                  managerGUID,
                  'travelMode',
                  modeWalking,
                )
              }}
            />
          </PreferenceOptionContainer>
          <PreferenceOptionContainer>
            <div className="mr15 font-size-18 preference-label">
              Viewing duration
            </div>
            <div className="input-box mb0 width120px pt0">
              <select
                className="height20px p0 preference-select"
                value={localViewingDuration}
                onChange={(e) => handleViewingDurationChanged(e.target.value)}
              >
                <option value={5}>5 mins</option>
                <option value={10}>10 mins</option>
                <option value={15}>15 mins</option>
                <option value={20}>20 mins</option>
                <option value={30}>30 mins</option>
                <option value={45}>45 mins</option>
                <option value={60}>60 mins</option>
                <option value={90}>90 mins</option>
                <option value={120}>120 mins</option>
              </select>
            </div>
          </PreferenceOptionContainer>
          <PreferenceOptionContainer>
            <Form.Checkbox
              label="Buffer after viewing"
              checked={scheduleBreaks}
              onChange={() => {
                onPreferenceOptionChanged(managerGUID, 'scheduleBreaks')
              }}
            />
            {scheduleBreaks && (
              <div className="ml15 input-box mb0 width120px pt0">
                <select
                  className="height20px p0 preference-select"
                  value={breakDuration}
                  onChange={(value) =>
                    onPreferenceOptionChanged(
                      managerGUID,
                      'breakDuration',
                    )(value)
                  }
                >
                  <option value={5}>5 minutes</option>
                  <option value={10}>10 minutes</option>
                  <option value={15}>15 minutes</option>
                </select>
              </div>
            )}
          </PreferenceOptionContainer>
        </div>
      </div>
    </div>
  )
}

export default ScheduleListContainer
