import { buildBaseUrl } from 'app/services/http/teams/key-logger/utils'
import { api } from 'app/utils/api/helpers'

export const createKeySet = (teamId, keySetPayload) => {
  return api.post(buildBaseUrl(teamId), keySetPayload)
}

export const updateKeySet = (teamId, keySetId, keySetPayload) => {
  return api.put(`${buildBaseUrl(teamId)}/${keySetId}`, keySetPayload)
}

export const listKeySets = (teamId, params) => {
  return api.get(buildBaseUrl(teamId), {
    params,
  })
}

export const getKeySet = (teamId, keySetId, params) => {
  return api.get(`${buildBaseUrl(teamId)}/${keySetId}`, {
    params,
  })
}

export const uploadKeySetImages = (teamId, keySetId, file) => {
  const formData = new FormData()
  formData.append('file', file)

  return api.post(`${buildBaseUrl(teamId)}/${keySetId}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const deleteKeySetImages = (teamId, keySetId, imageIds) => {
  const idsParams = imageIds.map((id) => `images=${id}`).join('&')
  return api.delete(`${buildBaseUrl(teamId)}/${keySetId}/images?${idsParams}`)
}

export const checkInKeySet = (teamId, keySetId, checkinPayload) => {
  return api.post(`${buildBaseUrl(teamId)}/${keySetId}/checkin`, checkinPayload)
}

export const checkOutKeySet = (teamId, keySetId, checkoutPayload) => {
  return api.post(
    `${buildBaseUrl(teamId)}/${keySetId}/checkout`,
    checkoutPayload,
  )
}

export const bulkCheckOutKeySets = (teamId, keySetsIds, checkoutPayload) => {
  return api.post(`${buildBaseUrl(teamId)}/bulk-checkout`, {
    key_sets: keySetsIds,
    ...checkoutPayload,
  })
}

export const removeKeySet = (teamId, keySetId, removePayload) => {
  return api.post(`${buildBaseUrl(teamId)}/${keySetId}/remove`, removePayload)
}

export const extendLoanKeySet = (teamId, keySetId, extendLoanPayload) => {
  return api.post(
    `${buildBaseUrl(teamId)}/${keySetId}/extend-loan`,
    extendLoanPayload,
  )
}

export const reserveKeySet = (teamId, keySetId) => {
  return api.post(`${buildBaseUrl(teamId)}/${keySetId}/reserve`)
}

export const cancelKeySetReservation = (teamId, reservationId) => {
  return api.delete(`${buildBaseUrl(teamId)}/reservations/${reservationId}`)
}

export const searchBorrowers = (teamId, searchText) => {
  return api.get(`${buildBaseUrl(teamId)}/borrowers`, {
    params: {
      q: searchText,
    },
  })
}

export const deleteBorrower = (teamId, borrowerGUID) => {
  return api.delete(`${buildBaseUrl(teamId)}/borrowers/${borrowerGUID}`)
}

export const generateKeySetFilteringParams = ({ property, statuses }) => {
  return {
    property,
    status: statuses,
  }
}

export const generateKeySetPopulateParams = ({
  property,
  images,
  borrower,
  issuer,
  manager,
}) => {
  return {
    ...(property ? { populate_property: true } : {}),
    ...(images ? { populate_images: true } : {}),
    ...(borrower ? { populate_borrower: true } : {}),
    ...(issuer ? { populate_issuer: true } : {}),
    ...(manager ? { populate_manager: true } : {}),
    ...(manager ? { populate_last_actor: true } : {}),
    ...(manager ? { populate_last_extended_by: true } : {}),
    ...(manager ? { populate_reservations: true } : {}),
  }
}
