import React from 'react'

import styled from 'styled-components'

import { Box, Button } from 'app/components/design-system-components'
import { TrimWithEllipsis } from 'app/components/design-system-components/TrimWithEllipsis'
import RightAngledArrowIcon from 'app/pages/teams/messages/components/RightAngledArrowIcon'
import * as stringHelpers from 'app/utils/strings/helpers'

const ContentStyled = styled(TrimWithEllipsis)`
  white-space: pre-wrap;
`

function MessageCell({ toggleInfoModal, message = {}, isRowHovered = false }) {
  return (
    <Box
      onClick={() =>
        toggleInfoModal(
          'Message',
          <Box
            dangerouslySetInnerHTML={{
              __html: message.desc,
            }}
          />,
        )
      }
      cursor="pointer"
      fontSize="14px"
    >
      <ContentStyled
        dangerouslySetInnerHTML={{
          __html: stringHelpers.trimAndAddEllipsesPastSpecifiedLength(
            message.desc,
            40,
          ),
        }}
      />
      {isRowHovered && (
        <Button variant="linkBlueWithoutWeight">view message</Button>
      )}
    </Box>
  )
}

export default MessageCell
