import React from 'react'

import getParameter from 'app/shared_components/parameter_parser'
import PayContainer from 'app/shared_components/payment_form/pay_container'

class NewCreditCardContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cards: [{ id: 'New', number: 'New Credit Card' }],
      selectedCreditCard: 'New',
      acceptTermsAndConditions: false,
      error: '',
    }
  }

  acceptTermsConditionsChange = () => {
    let { acceptTermsAndConditions } = this.state
    this.setState({ acceptTermsAndConditions: !acceptTermsAndConditions })
  }

  registerNewCard = (data) => {
    let { registerNewCard } = this.props
    registerNewCard(data, this.requestID)
  }

  render() {
    let { apiError } = this.props
    let { cards, selectedCreditCard, acceptTermsAndConditions, error } =
      this.state
    return (
      <PayContainer
        cards={cards}
        selectedCreditCard={selectedCreditCard}
        acceptTermsAndConditions={acceptTermsAndConditions}
        backUrl={`/bc/profile/credit-cards${window.location.search}`}
        payForWhat={'Add new credit card'}
        payText={'Save Payment Details'}
        acceptTermsConditionsChange={this.acceptTermsConditionsChange}
        registerPayment={this.registerNewCard}
        showCards={false}
        autoSave={true}
        hideAmount={true}
        error={apiError}
      />
    )
  }
}

export default NewCreditCardContainer
