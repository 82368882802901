import React from 'react'

import SearchForm from 'app/bond_cover/admin/search/search_form'

class SearchFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      searchType: 1,
      resetButtonClicked: true,
      error: '',
    }
  }

  UNSAFE_componentWillMount() {
    let { fetchApplicationList } = this.props
    fetchApplicationList()
  }

  findUserRole(role) {
    return role == 'admin' || role == 'super'
  }

  handleApplicationChangePage = (direction) => {
    let { fetchApplicationList, admin } = this.props
    let applicationListNextPageFirstItemId = admin.applicationList.cursor
    if (direction == 'firstPage') {
      fetchApplicationList()
    } else if (direction == 'nextPage') {
      fetchApplicationList(applicationListNextPageFirstItemId)
    }
  }

  handleSearchResultChangePage = (direction) => {
    let { searchRequests, admin } = this.props
    let searchResultNextPageFirstItemId = admin.searchResults.cursor
    if (direction == 'firstPage') {
      searchRequests(this.state)
    } else if (direction == 'nextPage') {
      searchRequests(this.state, searchResultNextPageFirstItemId)
    }
  }

  reset = () => {
    this.setState({
      searchText: '',
      resetButtonClicked: true,
    })
    let { fetchApplicationList } = this.props
    fetchApplicationList()
  }

  submit = () => {
    let { searchRequests } = this.props
    const { searchText } = this.state

    if (searchText.trim().length > 1) {
      this.setState({
        resetButtonClicked: false,
        error: '',
      })
      searchRequests(this.state, 0)
    } else {
      this.setState({
        error: 'Please enter 2 or more characters to search the result',
      })
    }
  }

  update = (field) => {
    return (event) => this.setState({ [field]: event.target.value })
  }

  validate() {
    return true
  }

  render() {
    let { searchText, searchType } = this.state
    let { currentUser, admin } = this.props

    let isAdminRole = false
    let searchResults = null
    let applicationList = null
    let applicationListCursorNumber = 0
    let searchResultsCursorNumber = 0

    if (currentUser && currentUser.roles) {
      isAdminRole = currentUser.roles.find(this.findUserRole)
    }

    if (currentUser && currentUser.email) {
      if (!isAdminRole) {
        this.props.history.push('/home/overview')
      }
    }

    if (admin && admin.searchResults.searchResults) {
      searchResults = admin.searchResults.searchResults
      searchResultsCursorNumber = admin.searchResults.cursor
    }

    if (admin && admin.applicationList.searchResults) {
      applicationList = admin.applicationList.searchResults
      applicationListCursorNumber = admin.applicationList.cursor
    }

    return (
      <SearchForm
        searchResults={searchResults}
        applicationList={applicationList}
        searchText={searchText}
        searchType={searchType}
        update={this.update}
        reset={this.reset}
        submit={this.submit}
        resetButtonClicked={this.state.resetButtonClicked}
        handleApplicationChangePage={this.handleApplicationChangePage}
        handleSearchResultChangePage={this.handleSearchResultChangePage}
        applicationListCursorNumber={applicationListCursorNumber}
        searchResultsCursorNumber={searchResultsCursorNumber}
        error={this.state.error}
      />
    )
  }
}

export default SearchFormContainer
