import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { fetchAllSupportingIDAttachments } from 'app/profile/renter/identity_doc/other_identity_doc/access'

export const getAllSupportingIDAttachments = () => () => {
  return fetchAllSupportingIDAttachments().then(
    ({ data, ok, responseText }) => {
      if (ok) {
        return Promise.resolve({ data })
      }
      return responseText.then((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
    },
  )
}
