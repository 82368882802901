import React from 'react'

import 'app/components/display/text/standard_text/standard-body/style.scss'

export const GreyBodyText = ({
  text = '',
  componentClass = '',
  textClass = '',
  children,
}) => {
  return (
    <div className={`grey-body-text ${componentClass}`}>
      {text && <p className={textClass}>{text}</p>}
      {children}
    </div>
  )
}

export const DefaultBodyText = ({
  text = '',
  componentClass = '',
  children,
  textClass = '',
}) => {
  return (
    <div className={`default-body-text ${componentClass}`}>
      {text && <p className={textClass}>{text}</p>}
      {children}
    </div>
  )
}

export const SmallCaption = ({
  text = '',
  componentClass = '',
  textClass = '',
  children,
  colour = '',
}) => {
  const colours = {
    grey: 'grey-text',
  }
  return (
    <div className={`caption-text ${componentClass} `}>
      {text && (
        <p className={`${colour ? colours[colour] : ''} ${textClass}`}>
          {text}
        </p>
      )}
      {children}
    </div>
  )
}

export const SectionMinorTitle = ({
  text = '',
  componentClass = '',
  textClass = '',
  imgSrc,
  imgAlt = 'icon',
}) => {
  return (
    <div className={`income-category income-label ${componentClass}`}>
      {imgSrc && <img className="mr5" src={imgSrc} alt={imgAlt} />}
      <span className={`${textClass}`}>{text}</span>
    </div>
  )
}
