import React from 'react'

import { createBrowserHistory } from 'history'
import { Link } from 'react-router-dom'

import { Box } from 'app/components/design-system-components'
import { Heading } from 'app/components/design-system-components/typography'
import * as Display from 'app/components/display/display'
import ThreeSectionLayout from 'app/pages/teams/onboarding/components/three-section-layout'
import {
  applicationsVideos,
  setupVideos,
  trainingVideos,
  viewingsVideos,
} from 'app/pages/teams/onboarding/constants'
import * as helpers from 'app/sm/helpers'

export const history = createBrowserHistory()

function OnboardingPage(props) {
  const { currentTeam, currentUser } = props
  let teamSlug = ''
  if (currentTeam && currentTeam.slug) {
    teamSlug = currentTeam.slug
  }
  let userFirstName = ''
  if (currentUser && currentUser.firstName) {
    userFirstName = currentUser.firstName
  }

  const renderSetupVideos = () => renderVideosSection('Set Up', setupVideos)
  const renderApplicationsVideos = () =>
    renderVideosSection('Applications', applicationsVideos)
  const renderViewingsVideos = () =>
    renderVideosSection('Viewings', viewingsVideos)
  const renderTrainingVideos = () =>
    renderVideosSection('Training', trainingVideos)

  const renderVideosSection = (header, links) => (
    <Box>
      <Box my={3}>
        <Heading level={4} displayingLevel={4}>
          {header}
        </Heading>
      </Box>
      {links.map((link) => (
        <Box my={4}>
          <a href={link.url} target="_blank" rel="noreferrer">
            {link.label}
          </a>
        </Box>
      ))}
    </Box>
  )

  return (
    <div>
      <Display.MainHeader text={`Welcome ${userFirstName}!`} />
      <Display.ContainerHeader isEditable={false} text="Tour Snug" />
      <Display.DefaultBodyText text="Follow an in app tour of Snug" />

      <ThreeSectionLayout
        noMarginTop={true}
        sectionOne={
          <Link to={helpers.urlTo('teamsApplication', { teamSlug })}>
            <Display.GenericButton buttonType="secondary" text="Applications" />
          </Link>
        }
        sectionTwo={
          <Link to={helpers.urlTo('teamsViewing', { teamSlug })}>
            <Display.GenericButton buttonType="secondary" text="Viewings" />
          </Link>
        }
      />

      <Display.ContainerHeader
        isEditable={false}
        text="Videos"
        componentClass="mt40"
      />
      <Display.DefaultBodyText text="Watch snippets or full training videos" />

      <ThreeSectionLayout
        noWrap
        sectionOne={renderSetupVideos()}
        sectionTwo={
          <>
            <Box mb={6}>{renderApplicationsVideos()}</Box>
            <Box mb={6}>{renderViewingsVideos()}</Box>
            <Box mb={6}>{renderTrainingVideos()}</Box>
          </>
        }
      />

      <ThreeSectionLayout
        sectionOne={
          <div>
            <Display.ContainerHeader
              isEditable={false}
              text="Webinars, live!"
            />
            <Display.DefaultBodyText text="Join our free webinars: New User Training’s bi-weekly or Product Update Monthly" />
            <a
              href="https://us02web.zoom.us/webinar/register/WN_OamICqipRqS942uVichaSA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Display.GenericButton
                buttonType="secondary"
                text="Product Update"
                componentClass="mb20"
              />
            </a>
            <a
              href="https://us02web.zoom.us/webinar/register/WN_9MCsqeXHQ-aNf2ONfs2HvA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Display.GenericButton
                buttonType="secondary"
                text="New User Intro/Q&A - Tuesday 10am"
                componentClass="mb20"
              />
            </a>
            <a
              href="https://us02web.zoom.us/webinar/register/WN_OdimWjmLT-yKHe4k5qm2OQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Display.GenericButton
                buttonType="secondary"
                text="New User Intro/Q&A - Thursday 2pm"
                componentClass="mb20"
              />
            </a>
          </div>
        }
        sectionTwo={
          <div>
            <Display.ContainerHeader
              isEditable={false}
              text="Quiz & Scenarios"
            />
            <Display.DefaultBodyText text="Test your knowledge" />
            <a
              href="https://docs.google.com/forms/d/1WlGxb6seTCAB5-NQF9JgjBMTQx5BElMFIfjIDpCyrms/edit"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Display.GenericButton
                buttonType="secondary"
                text="Viewings Quiz"
                componentClass="mb20 mt48"
              />
            </a>
            <a
              href="https://docs.google.com/forms/u/1/d/13v473lg1xBcuDBsluLpxpY2U3dQk7HQpRmedAQivvLo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Display.GenericButton
                buttonType="secondary"
                text="Applications Quiz"
                componentClass="mb20"
              />
            </a>
            <a
              href="https://help.snug.com/hc/en-us/articles/360001620655"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Display.GenericButton
                buttonType="secondary"
                text="Top 10 Scenarios"
                componentClass="mb20"
              />
            </a>
          </div>
        }
        sectionThree={
          <div>
            <Display.ContainerHeader isEditable={false} text="Need help?" />
            <Display.DefaultBodyText text="Our friendly team is here to help you via chat, email, call back and Zooms." />
            <a
              href="mailto:help@snug.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Display.GenericButton
                buttonType="secondary"
                text="Email (<1hr reply)"
                componentClass="mb20"
              />
            </a>
            <a
              href="https://snugcom.pipedrive.com/scheduler/49oqjuq/lets-get-you-set-up"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Display.GenericButton
                buttonType="secondary"
                text="30 min Set Up"
                componentClass="mb20"
              />
            </a>
            <a
              href="https://snugcom.pipedrive.com/scheduler/weL3OuKQ/health-check-in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Display.GenericButton
                buttonType="secondary"
                text="Health Check-in"
                componentClass="mb20"
              />
            </a>
          </div>
        }
      />
    </div>
  )
}

export default OnboardingPage
