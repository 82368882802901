import humanIcon01 from 'app/assets/icons/human01.jpg'
import logoIconPink from 'app/assets/icons/logo-icon-pink.svg'

export const Transactions = [
  {
    name: 'Rent Paid',
    avatarImage: humanIcon01,
    avatarIcon: '',
    avatarIconColor: '',
    userFirstname: 'Anne',
    userLastname: 'Quincy',
    userSufix: '',
    date: '24 July 2017',
    amountIncome: '600.00',
    amountExpense: '',
  },
  {
    name: 'Gas Bill',
    avatarImage: '',
    avatarIcon: 'gas',
    avatarIconColor: 'green',
    userFirstname: 'Justin',
    userLastname: 'Bieber',
    userSufix: 'you',
    date: '24 July 2017',
    amountIncome: '',
    amountExpense: '981.22',
  },
  {
    name: 'Phone Bill',
    avatarImage: '',
    avatarIcon: 'phone',
    avatarIconColor: 'purple',
    userFirstname: 'Justin',
    userLastname: 'Bieber',
    userSufix: 'you',
    date: '24 July 2017',
    amountIncome: '',
    amountExpense: '472.11',
  },
  {
    name: 'Advertising & Listing',
    avatarImage: logoIconPink,
    avatarIcon: '',
    avatarIconColor: '',
    userFirstname: 'Snug Co',
    userLastname: '',
    userSufix: '',
    date: '24 July 2017',
    amountIncome: '',
    amountExpense: '100.00',
  },
  {
    name: 'Rent Paid',
    avatarImage: humanIcon01,
    avatarIcon: '',
    avatarIconColor: '',
    userFirstname: 'Anne',
    userLastname: 'Quincy',
    userSufix: '',
    date: '24 July 2017',
    amountIncome: '600.00',
    amountExpense: '',
  },
  {
    name: 'Advertising & Listing',
    avatarImage: logoIconPink,
    avatarIcon: '',
    avatarIconColor: '',
    userFirstname: 'Snug Co',
    userLastname: '',
    userSufix: '',
    date: '24 July 2017',
    amountIncome: '',
    amountExpense: '100.00',
  },
  {
    name: 'Rent Paid',
    avatarImage: humanIcon01,
    avatarIcon: '',
    avatarIconColor: '',
    userFirstname: 'Anne',
    userLastname: 'Quincy',
    userSufix: '',
    date: '24 July 2017',
    amountIncome: '600.00',
    amountExpense: '',
  },
  {
    name: 'Gas Bill',
    avatarImage: '',
    avatarIcon: 'gas',
    avatarIconColor: 'green',
    userFirstname: 'Justin',
    userLastname: 'Bieber',
    userSufix: 'you',
    date: '24 July 2017',
    amountIncome: '',
    amountExpense: '981.22',
  },
  {
    name: 'Rent Paid',
    avatarImage: humanIcon01,
    avatarIcon: '',
    avatarIconColor: '',
    userFirstname: 'Anne',
    userLastname: 'Quincy',
    userSufix: '',
    date: '24 July 2017',
    amountIncome: '600.00',
    amountExpense: '',
  },
]
