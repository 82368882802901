import React from 'react'

import { history } from 'app/shared_components/router'

const Breadcrumbs = ({ crumbs }) => {
  return (
    <div className="mb10 pb10">
      <div className="viewing-crumb-wrapper display-flex">
        {crumbs.map((crumb, index) => {
          const { text = '', link = '' } = crumb
          if (index === crumbs.length - 1) {
            return (
              <button
                key={text}
                className="btn btn-transparent btn-left rental-header-mobile-layout height15"
              >
                <span className="font-size-14">{text}</span>
              </button>
            )
          } else {
            return (
              <button
                key={text}
                className="btn btn-transparent btn-left rental-header-mobile-layout width-auto height15"
              >
                <span
                  className="font-size-14"
                  onClick={() => history.push(link)}
                >
                  {text}&nbsp;/&nbsp;
                </span>
              </button>
            )
          }
        })}
      </div>
    </div>
  )
}
export default Breadcrumbs
