import React from 'react'

import styled from 'styled-components'

import {
  Box,
  Flex,
  ShadowScrollBox,
} from 'app/components/design-system-components'
import * as getters from 'app/components/design-system-components/theme/getters'
import { Text } from 'app/components/design-system-components/typography'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const StyledFieldWrapper = styled(FieldWrapper)`
  :not(&:first-child) {
    margin-top: ${getters.spacing(6)};
  }
`

export const LabelSubText = styled(Text)`
  max-width: 350px;
  margin-top: ${getters.spacing(2)};
  margin-bottom: 0;
`

const StepLayoutContainerWrapper = styled(Flex)`
  padding: ${getters.spacing(6)} ${getters.spacing(8)};

  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`

const StyledLayoutContainerBody = styled(Box)`
  padding-bottom: ${getters.spacing(8)};
`

export const StepLayoutContainer = ({
  actions,
  children,
  containerBodyProps,
}) => {
  return (
    <StepLayoutContainerWrapper>
      <ShadowScrollBox mb={theme.space[5] + 'px'}>
        <StyledLayoutContainerBody {...containerBodyProps}>
          {children}
        </StyledLayoutContainerBody>
      </ShadowScrollBox>
      <Box>{actions}</Box>
    </StepLayoutContainerWrapper>
  )
}
