import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import Flex from 'app/components/design-system-components/Flex'
import * as getters from 'app/components/design-system-components/theme/getters'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const TabHeader = styled(Box)`
  cursor: pointer;
  font-size: ${({ fontSize }) => fontSize || getters.fontSize(7)};
  padding: 8px;
  width: 100%;
  text-align: center;
  ${(props) => {
    switch (props.selected) {
      case true:
        return `
          font-weight: bold;
          color: ${theme.colors.white};
          background-color: ${theme.colors.skyBlue};
          border-radius: 5px;
        `
      default:
        return `
          color: ${theme.colors.black};
        `
    }
  }};
`

export const TabsHeader = ({
  selectedTab,
  tabsOptions,
  onTabChanged,
  tabOptions,
  ...props
}) => {
  return (
    <Box
      backgroundColor={theme.colors.paleBlue}
      borderRadius={10}
      p={2}
      mb={4}
      {...props}
    >
      <Flex justifyContent="space-evenly" alignItems="center">
        {tabsOptions.map((tab, idx) => {
          const isSelected = tab.key === selectedTab.key
          return (
            <TabHeader
              onClick={() => onTabChanged(tab)}
              selected={isSelected}
              key={idx}
              {...tabOptions}
            >
              {tab.header}
            </TabHeader>
          )
        })}
      </Flex>
    </Box>
  )
}
