import React from 'react'

import { Link } from 'react-router-dom'

function Details() {
  return (
    <div className="sm-property-lease-details-card details">
      <div className="header xs-mb20">
        <h4>Lease Details</h4>
        <div className="controls">
          <button className="btn btn-gray btn-round btn-medium">
            <i className="icon-edit"></i>
          </button>
        </div>
      </div>
      <div className="content">
        <div className="table pb30">
          <div className="cell-m pr20">
            <div className="big-icon" title="Rent">
              <i className="icon-rent"></i>
            </div>
          </div>
          <div className="cell-m-100 pb10">
            <h3 className="mb5">$600 per week</h3>
            <span>Due 14 May 2017</span>
            <i className="icon-dot border-color fs13 pl5 pr5"></i>
            <span>in 14 days</span>
          </div>
        </div>
        <div className="table pb30 bbs mb30">
          <div className="cell-m pr20">
            <div className="big-icon" title="Length">
              <i className="icon-date"></i>
            </div>
          </div>
          <div className="cell-m-100 pb10">
            <h3 className="mb5">4 months left</h3>
            <span>12 Dec 2017 - 12 Dec 2018</span>
            <i className="icon-dot border-color fs13 pl5 pr5"></i>
            <span>12 months</span>
          </div>
        </div>
        <h4 className="mb15">Special Conditions</h4>
        <p className="m0">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud.
        </p>
      </div>
    </div>
  )
}

export default Details
