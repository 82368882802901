export const TEMPLATE_LATE_ARRIVER = 'LATE_ARRIVER'
export const TEMPLATE_PLEASE_BUZZ = 'PLEASE_BUZZ'

export const IsValidTemplate = (t) => {
  return [TEMPLATE_LATE_ARRIVER, TEMPLATE_PLEASE_BUZZ].includes(t)
}

export const DefaultMessageTemplates = {
  [TEMPLATE_PLEASE_BUZZ]: {
    header: 'Onsite, Please Buzz',
    body: 'I am now in the property. Please buzz the apartment number or call and I will let you up.',
  },
  [TEMPLATE_LATE_ARRIVER]: {
    header: 'Onsite, Late Arriver',
    body: 'If you have arrived after the viewing start time please call me for access details.',
  },
}
