import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

const propTypes = {
  pillType: PropTypes.oneOf([
    'green',
    'grey',
    'yellow',
    'hollow',
    'green-hollow',
    'orange-hollow',
    'red',
    'red-follow',
  ]),
  text: PropTypes.string,
  componentClass: PropTypes.string,
  pillTextClass: PropTypes.string,
  componentID: PropTypes.string,
  borderColor: PropTypes.string,
}

const StatusContainer = styled.div`
  min-height: 24px;
  .green {
    background-color: #37c878;
    color: white;
  }
  .grey {
    background-color: #f4f4f4;
    color: #68696b;
  }
  .yellow {
    background-color: #ffe756;
    color: #68696b;
  }
  .yellow-hollow {
    border: 2px solid #ffe756;
  }
  .hollow {
    border: 2px solid #37c878;
    color: #37c878;
  }
  .green-hollow {
    border: 2px solid #37c878;
  }
  .orange-hollow {
    border: 2px solid #ffaa4d;
  }
  .red {
    background-color: #ca5442;
    color: white;
  }
  .red-hollow {
    border: 2px solid #ca5442;
  }
  .brown {
    background-color: #fef8ec;
    color: #916208;
  }
  .white-grey {
    color: white;
    border: 2px solid #68696b;
  }
`
const Pill = styled.div`
  padding: 0 10px;
  font-size: 14px;
  border-radius: 12px;
  text-align: center;
  height: 24px;
  line-height: 20px;
  font-weight: 400;
  width: min-content;
  vertical-align: middle;
  border: 2px solid
    ${({ borderColor }) => (borderColor ? borderColor : 'transparent')};
`
const PillText = styled.div`
  margin-bottom: 0;
  width: max-content;
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : 'unset'};
`
const StatusPill = ({
  componentID,
  pillType,
  text,
  componentClass,
  pillTextClass,
  borderColor,
  textTransform,
}) => {
  return (
    <StatusContainer id={componentID} className={`${componentClass}`}>
      <Pill
        className={`${pillType} ${pillTextClass}`}
        {...(borderColor && { borderColor: borderColor })}
      >
        <PillText textTransform={textTransform}>{text}</PillText>
      </Pill>
    </StatusContainer>
  )
}

StatusPill.propTypes = propTypes
export default StatusPill
