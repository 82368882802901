import React from 'react'

import { DetailsBoxCard } from 'app/components/display/display'
import { ErrorMessage } from 'app/shared_components/helpers'
import EmploymentEditModal from 'app/sm/employment/employment_edit_modal'

class EmployerContactBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      removeApiError: '',
    }
  }

  render() {
    const {
      employer,
      disabled,
      update,
      validate,
      validateInternationalNumber,
      onSaveContactDetails,
      errors,
      employee,
      isContactDetailsUpdated,
      openEditModal,
      isEditModal,
      modalType,
    } = this.props

    let { contactName, phoneNumber, email } = employee || {}
    if (isContactDetailsUpdated) {
      contactName = employer.contactName
      phoneNumber = employer.phoneNumber
      email = employer.email
    }
    const { removeApiError } = this.state
    const employerContactDetail = [
      `Full Name: ${contactName}`,
      `Phone: ${phoneNumber}`,
      `Email: ${email}`,
    ]

    let sendStatusInfo = false

    return (
      <div>
        <DetailsBoxCard
          title={`Employment contact`}
          icon="fa fa-id-badge"
          titleClass="ml20"
          details={employerContactDetail}
          disabled={disabled}
          editable={false}
          isEmploymentDetail={sendStatusInfo}
          isEditLink={true}
          isEditText={'Not you'}
          isEditLinkText={'Change details'}
          openEditModal={openEditModal}
        />

        <ErrorMessage error={removeApiError} />
        {isEditModal && (
          <EmploymentEditModal
            closeReasonsModal={openEditModal}
            details={employer}
            modalType={modalType}
            update={update}
            validate={validate}
            validateInternationalNumber={validateInternationalNumber}
            onSaveContactDetails={onSaveContactDetails}
            errors={errors}
            isEditModal={isEditModal}
          />
        )}
      </div>
    )
  }
}

export default EmployerContactBox
