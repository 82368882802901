import React from 'react'

import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import {
  MailOutlineRounded,
  PhoneRounded,
} from 'app/components/design-system-components/icons/communications'
import { MailToLink } from 'app/components/design-system-components/links/MailTo'
import { Text } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { formatPhoneNumber } from 'app/utils/phonenumber/helpers'
import { trimAndAddEllipsesPastSpecifiedLength } from 'app/utils/strings/helpers'

const StyledIconContainer = styled(Flex)`
  background: ${theme.colors.grey200};
  width: ${theme.width[13]}px;
  height: ${theme.height[12]}px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  svg {
    color: ${theme.colors.skyBlue};
    width: ${theme.width[6]}px;
    height: ${theme.height[6]}px;
  }
`

function BaseContactDetails({
  fullName = '',
  contactNumber = '',
  email = '',
  subject = '',
}) {
  return (
    <Box mt={7}>
      <Text mb={1}>{fullName}</Text>
      {contactNumber && (
        <a href={`tel:${contactNumber}`}>
          <Flex alignItems="center">
            <StyledIconContainer mr={4} mt={1}>
              <PhoneRounded />
            </StyledIconContainer>
            <Box mt={1}>{formatPhoneNumber(contactNumber)}</Box>
          </Flex>
        </a>
      )}
      {email && (
        <MailToLink email={email} subject={subject}>
          <Flex alignItems="center">
            <StyledIconContainer mr={4} mt={3}>
              <MailOutlineRounded />
            </StyledIconContainer>
            <Box mt={2}>{trimAndAddEllipsesPastSpecifiedLength(email, 30)}</Box>
          </Flex>
        </MailToLink>
      )}
    </Box>
  )
}

export default BaseContactDetails
