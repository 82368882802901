import { NetworkConnectionError } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  generateRenterProfilePDF,
  getCompleteness,
  getReputationPreference,
  getTeamSettingInfo,
  getTeamSettingInformation,
  putTeamViewingSettingInfo,
  updateReputationPreference,
} from 'app/sm/rental_reputation/access'

const prefix = 'rR_'
export const RECEIVE_RENTAL_REPUTATION = prefix + 'RECEIVE_RENTAL_REPUTATION'
export const RECEIVE_COMPLETENESS = prefix + 'RECEIVE_COMPLETENESS'
export const RECEIVE_RESPONSE_TEXT = prefix + 'RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = prefix + 'RECEIVE_ERROR'
export const RECEIVE_PDF = prefix + 'RECEIVE_PDF'
export const RECEIVE_PREFERENCE = prefix + 'RECEIVE_PREFERENCE'

const receiveCompleteness = (completeness) => ({
  type: RECEIVE_COMPLETENESS,
  completeness,
})
const receivePDF = (pdf) => ({ type: RECEIVE_PDF, pdf })
const receivePreference = (preference) => ({
  type: RECEIVE_PREFERENCE,
  preference,
})

export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})

export function fetchCompleteness() {
  return (dispatch) => {
    return getCompleteness().then(({ ok, completeness, responseText }) => {
      if (ok) {
        return dispatch(receiveCompleteness(completeness))
      } else {
        return responseText.then((t) => {
          dispatch(receiveResponseText(t))
          return Promise.reject(t)
        })
      }
    })
  }
}

export const fetchTeamSettingInfo = (teamId, queryString) => {
  return (dispatch) => {
    return getTeamSettingInfo(teamId, queryString).then(
      ({ ok, settingInfo, responseText }) => {
        if (ok) {
          return Promise.resolve(settingInfo)
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((t) => {
            return Promise.reject(translateErrorCodeToMessage(t))
          })
        }
      },
    )
  }
}

export const fetchTeamSettingInformation = (teamId, queryString) => {
  return (dispatch) => {
    return getTeamSettingInformation(teamId, queryString).then(
      ({ ok, settingInfo, responseText }) => {
        if (ok) {
          return Promise.resolve(settingInfo)
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((t) => {
            return Promise.reject(translateErrorCodeToMessage(t))
          })
        }
      },
    )
  }
}

export const updateTeamViewingSettingInfo = (
  teamId,
  viewingSetting,
  applicationSetting,
  personalNotificationSettings,
  feedSettings,
) => {
  return (dispatch) => {
    return putTeamViewingSettingInfo(
      teamId,
      viewingSetting,
      applicationSetting,
      personalNotificationSettings,
      feedSettings,
    ).then(({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      }
    })
  }
}

export function createRenterProfilePDF(success, fail) {
  return (dispatch) => {
    generateRenterProfilePDF()
      .then(({ ok, url, responseText }) => {
        success && success()
        if (ok) {
          dispatch(receivePDF(url.url))
        } else {
          fail && fail()
          responseText.then((t) => {
            fail(t)
          })
        }
      })
      .catch((error) => {
        fail && fail('Network connection error')
      })
  }
}

export const retrieveReputationPreference = () => (dispatch) => {
  return getReputationPreference().then(({ ok, preference, responseText }) => {
    if (ok) {
      dispatch(receivePreference(preference))
      return { preference }
    } else {
      return responseText
        .then((error) => translateErrorCodeToMessage(error))
        .then((error) => Promise.reject(error))
    }
  })
}

export const changeReputationPreference = (payload) => (dispatch) => {
  return updateReputationPreference(payload).then(
    ({ ok, preference, responseText }) => {
      if (ok) {
        dispatch(receivePreference(preference))
        return { preference }
      } else {
        return responseText
          .then((error) => translateErrorCodeToMessage(error))
          .then((error) => Promise.reject(error))
      }
    },
  )
}
