export const Yes = 'Yes'
export const No = 'No'

export const PREVIOUS_TENANCIES_TERMINATION = 'previousTenanciesTermination'
export const PREVIOUS_TENANCIES_TERMINATION_DETAILS =
  'previousTenanciesTerminationDetails'
export const ANOTHER_LESSOR_OR_AGENT_DEBT = 'anotherLessorOrAgentDebt'
export const ANOTHER_LESSOR_OR_AGENT_DEBT_DETAILS =
  'anotherLessorOrAgentDebtDetails'
export const REASON_AFFECT_RENT_PAYMENT = 'reasonAffectRentPayment'
export const REASON_AFFECT_RENT_PAYMENT_DETAILS =
  'reasonAffectRentPaymentDetails'
export const OWN_PROPERTIES_OR_LAND = 'ownPropertiesOrLand'
export const OWN_PROPERTIES_OR_LAND_DETAILS = 'ownPropertiesOrLandDetails'
export const TEAM_MEMBER_ASSOCIATION = 'teamMemberAssociation'
export const ABORIGINAL_OR_TORRES_STRAIGHT_ISLANDER =
  'aboriginalOrTorresStraitIslander'

export const STATE_OPTED_IN_UTILITY_CONNECTION = 'Victoria'

export const YesOrNoOptions = [
  {
    index: 1,
    value: No,
    picked: true,
  },
  {
    index: 2,
    value: Yes,
    picked: false,
  },
]

export const yesOrNoDisclosureQuestions = [
  {
    id: PREVIOUS_TENANCIES_TERMINATION,
    label: 'Have any of your previous tenancies been terminated?',
    required: true,
    hasMoreDetails: true,
    moreDetailsId: PREVIOUS_TENANCIES_TERMINATION_DETAILS,
  },
  {
    id: ANOTHER_LESSOR_OR_AGENT_DEBT,
    label: 'Are you in debt to another lessor or agent?',
    required: true,
    hasMoreDetails: true,
    moreDetailsId: ANOTHER_LESSOR_OR_AGENT_DEBT_DETAILS,
  },
  {
    id: REASON_AFFECT_RENT_PAYMENT,
    label: 'Is there an existing reason that may affect your rent payment?',
    required: true,
    hasMoreDetails: true,
    moreDetailsId: REASON_AFFECT_RENT_PAYMENT_DETAILS,
  },
  {
    id: OWN_PROPERTIES_OR_LAND,
    label: 'Do you own any properties or land?',
    required: true,
    hasMoreDetails: true,
    moreDetailsId: OWN_PROPERTIES_OR_LAND_DETAILS,
  },
  {
    id: TEAM_MEMBER_ASSOCIATION,
    label: `Does anyone included in your application have an association with a Board or Staff member of [Team Name]?`,
    required: true,
    hasMoreDetails: false,
  },
]
