import React from 'react'

import { Link } from 'react-router-dom'

import { urlTo } from 'app/sm/helpers'
import PropertyDashboardContent from 'app/sm/property_dashboard/components/property_dashboard_content'
import PropertyDashboardHeader from 'app/sm/property_dashboard/components/property_dashboard_header'

class PropertyDashboard extends React.Component {
  componentDidMount() {
    const { propertyId } = this.props.match.params
    this.props.fetchProperty(propertyId)
    this.props.fetchPropertyApplications(propertyId, 'Applications')
    this.props.fetchApplications()
  }

  render() {
    const {
      property = {},
      viewings,
      applicationsCount,
      acceptedApplication,
      error,
    } = this.props
    const { propertyId } = this.props.match.params

    return (
      <div className="sm-property-dashboard">
        <div className="section-title xs-row">
          <div className="left">
            <Link to={urlTo('portfolioOverview')} className="lh1">
              <h3 className="lh1">
                <i className="icon-arrow-left fs16 pt2 pr10 xs-ibm"></i>
                <span className="ibm lh1">Dashboard</span>
              </h3>
            </Link>
          </div>
          {false && (
            <div className="right">
              <div className="dropdown actions">
                <div className="dropdown-toggle" data-toggle="dropdown">
                  <button className="btn btn-small-xs">
                    <i className="icon-more left ibm"></i>
                    <span className="ibm">Actions</span>
                  </button>
                </div>
                <ul className="dropdown-menu dropdown-menu-right mt5">
                  <li>
                    <Link to={'/'}>Preview</Link>
                  </li>
                  <li>
                    <Link to={'/'}>Edit property</Link>
                  </li>
                  <li className="divider"></li>
                  <li>
                    <Link to={'/'}>ToDo's</Link>
                  </li>
                  <li>
                    <Link to={'/'}>Profit</Link>
                  </li>
                  <li>
                    <Link to={'/'}>Lease details</Link>
                  </li>
                  <li>
                    <Link to={'/'}>Maintenece</Link>
                  </li>
                  <li>
                    <Link to={'/'}>Reports</Link>
                  </li>
                  <li>
                    <Link to={'/'}>Applications</Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <PropertyDashboardHeader error={error} property={property} />
        <PropertyDashboardContent
          property={property}
          viewings={viewings}
          applicationsCount={applicationsCount}
          acceptedApplication={acceptedApplication}
          agent={property.agent}
        />
      </div>
    )
  }
}

export default PropertyDashboard
