import {
  AlertSensitiveInfo,
  Box,
  TextSpan,
} from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { isDocAutoDeleted } from 'app/sm/docs_helpers'
import { BANK_STATEMENT_BALANCE_ONLY_GUIDANCE } from 'app/utils/text/helpers'

// const requiredPayslips = 3
// const requiredBankStatements = 1
const requiredOtherDocuments = 1
const requiredGovernmentBenefitIncomeDocuments = 1
const REMOVE_AUTO_DELETED_FILES_MSG =
  'You also have documents that have been auto-deleted that can be removed.'
const REMOVE_AUTO_DELETED_FILES_BTN_TITLE = 'Remove Auto-Deleted'

export const isAllSufficient = (
  docs: any,
  savingsBalance: number,
  isOtherDocsTicked: boolean,
  isGovDocsTicked: boolean,
  payslipsNumber: number,
  bankStatementPeriodNumber: number,
  enforcePayslipsRequired: boolean = true,
): boolean => {
  const {
    paySlips = [],
    bankStatements = [],
    otherIncomeDocuments = [],
    governmentBenefitDocuments = [],
  } = docs

  const sufficientPayslips = enforcePayslipsRequired
    ? isPayslipsCountSufficient(paySlips, payslipsNumber)
    : true

  return (
    (!savingsBalance ||
      isBankStatementsSufficient(bankStatements, bankStatementPeriodNumber)) &&
    sufficientPayslips &&
    isBankStatementsSufficient(bankStatements, bankStatementPeriodNumber) &&
    (!isOtherDocsTicked || isOtherDocsSufficient(otherIncomeDocuments)) &&
    (!isGovDocsTicked || isGovDocsSufficient(governmentBenefitDocuments))
  )
}

const filterOutAutoDeletedDocs = (docs: any[]): any[] =>
  docs.filter((doc) => !isDocAutoDeleted(doc))

const isPayslipsCountSufficient = (
  payslips: any[],
  payslipsNumber: number,
): boolean => filterOutAutoDeletedDocs(payslips).length >= payslipsNumber

const isBankStatementsSufficient = (
  bankStatements: any[],
  bankStatementPeriodNumber: number,
): boolean =>
  filterOutAutoDeletedDocs(bankStatements).length >= bankStatementPeriodNumber

const isPayslipsDurationSufficient = (payslips: any[]): boolean =>
  filterOutAutoDeletedDocs(payslips).length > 0

const isOtherDocsSufficient = (otherDocs: any[]): boolean =>
  filterOutAutoDeletedDocs(otherDocs).length >= requiredOtherDocuments

const isGovDocsSufficient = (govDocs: any[]): boolean =>
  filterOutAutoDeletedDocs(govDocs).length >=
  requiredGovernmentBenefitIncomeDocuments

interface AgencyRequirements {
  payslipsCount: boolean
  bankStatement: boolean
  payslipsDuration: boolean
  otherDocs: boolean
  govDocs: boolean
}

interface ModalConfig {
  title?: string
  body?: string
  primaryBtnTitle?: string
  secondaryBtnTitle?: string
  allowContinue: boolean
  replaceBody: boolean
  includeSensitiveInfo?: boolean
}

const generalWarningModal: ModalConfig = {
  title: 'Add Income',
  body: 'This will allow you to submit a complete rental application.',
  primaryBtnTitle: 'Add Income',
  secondaryBtnTitle: 'Continue',
  allowContinue: true,
  replaceBody: false,
  includeSensitiveInfo: true,
}

export const hasAutoDeletedDocsWarningModalConf: ModalConfig = {
  title: 'Remove auto-deleted documents',
  body: 'You have documents that have been auto-deleted. Would you like to manually remove these documents or skip and leave them?',
  allowContinue: true,
  replaceBody: true,
  primaryBtnTitle: 'Manually remove',
  secondaryBtnTitle: 'Skip',
  includeSensitiveInfo: true,
}

type Props = {
  docs: any
  isPayslipsCountRequired: boolean
  isBankStatementRequired: boolean
  isPayslipsDurationRequired: boolean
  isOtherDocsTicked: boolean
  isGovDocsTicked: boolean

  payslipsDuration: number
  savingsBalance: number
  payslipsNumber: number
  bankStatementPeriodNumber: number

  onPrimaryClick: () => void
  onSecondaryClick: (allowContinue: boolean) => void
}

export const WarningModal = ({
  docs,
  isPayslipsCountRequired,
  isBankStatementRequired,
  isPayslipsDurationRequired,
  isOtherDocsTicked,
  isGovDocsTicked,
  payslipsDuration,
  onPrimaryClick,
  onSecondaryClick,
  savingsBalance,
  payslipsNumber,
  bankStatementPeriodNumber,
}: Props) => {
  const getModalConfig = (required: AgencyRequirements): ModalConfig => {
    const {
      paySlips = [],
      bankStatements = [],
      otherIncomeDocuments = [],
      governmentBenefitDocuments = [],
    } = docs

    const hasAutoDeletedFile = paySlips
      .concat(bankStatements)
      .concat(otherIncomeDocuments)
      .concat(governmentBenefitDocuments)
      .some(isDocAutoDeleted)

    const getSuitableMessage = (noAutoDeletedDocsMsg: string) => {
      return hasAutoDeletedFile
        ? `${noAutoDeletedDocsMsg} ${REMOVE_AUTO_DELETED_FILES_MSG}`
        : noAutoDeletedDocsMsg
    }

    const getSuitableBtnTitle = (noAutoDeletedDocsBtn: string) => {
      return hasAutoDeletedFile
        ? `${noAutoDeletedDocsBtn} / ${REMOVE_AUTO_DELETED_FILES_BTN_TITLE}`
        : noAutoDeletedDocsBtn
    }

    if (
      !!savingsBalance &&
      !isBankStatementsSufficient(bankStatements, bankStatementPeriodNumber)
    ) {
      return {
        title: 'Add a savings balance bank statement',
        body: getSuitableMessage(
          'You’ve added a savings balance but have not attached any bank statements. Please add a savings balance bank statement. ' +
            BANK_STATEMENT_BALANCE_ONLY_GUIDANCE,
        ),
        primaryBtnTitle: getSuitableBtnTitle('Add'),
        secondaryBtnTitle: 'Continue',
        allowContinue: true,
        replaceBody: true,
        includeSensitiveInfo: true,
      }
    }

    // all not required
    if (Object.values(required).every((req) => !req)) {
      if (
        !isPayslipsCountSufficient(paySlips, payslipsNumber) ||
        !isBankStatementsSufficient(
          bankStatements,
          bankStatementPeriodNumber,
        ) ||
        !isPayslipsDurationSufficient(paySlips)
      ) {
        const mc = { ...generalWarningModal }
        mc.body = getSuitableMessage(mc.body as string)
        mc.primaryBtnTitle = getSuitableBtnTitle(mc.primaryBtnTitle as string)
        return mc
      } else if (hasAutoDeletedFile) {
        return hasAutoDeletedDocsWarningModalConf
      }
    }

    if (
      required.payslipsCount &&
      !isPayslipsCountSufficient(paySlips, payslipsNumber) &&
      required.bankStatement &&
      !isBankStatementsSufficient(bankStatements, bankStatementPeriodNumber)
    ) {
      return {
        title: 'Add Payslips and Bank Statement',
        body: getSuitableMessage(
          `This property manager requires ${payslipsNumber} recent payslips and ${bankStatementPeriodNumber} Bank Statement to apply.`,
        ),
        primaryBtnTitle: getSuitableBtnTitle('Add Payslips/Bank Statement'),
        secondaryBtnTitle: 'Close',
        allowContinue: false,
        replaceBody: false,
        includeSensitiveInfo: true,
      }
    }
    if (
      required.payslipsDuration &&
      required.bankStatement &&
      !isBankStatementsSufficient(bankStatements, bankStatementPeriodNumber)
    ) {
      return {
        title: 'Add Payslips and Bank Statement',
        body: getSuitableMessage(
          `This property manager requires ${payslipsDuration} weeks of payslips and ${bankStatementPeriodNumber} Bank Statement to apply.`,
        ),
        primaryBtnTitle: getSuitableBtnTitle('Add Payslips/Bank Statement'),
        secondaryBtnTitle: 'Close',
        allowContinue: false,
        replaceBody: false,
        includeSensitiveInfo: true,
      }
    }
    if (
      required.payslipsCount &&
      !isPayslipsCountSufficient(paySlips, payslipsNumber)
    ) {
      return {
        title: 'Add Payslips',
        body: getSuitableMessage(
          `This property manager requires ${payslipsNumber} payslips to apply.`,
        ),
        primaryBtnTitle: getSuitableBtnTitle('Add Payslips'),
        secondaryBtnTitle: 'Close',
        allowContinue: false,
        replaceBody: false,
        includeSensitiveInfo: true,
      }
    }

    if (
      required.bankStatement &&
      !isBankStatementsSufficient(bankStatements, bankStatementPeriodNumber)
    ) {
      return {
        title: 'Add Bank Statement',
        body: getSuitableMessage(
          `This property manager requires ${bankStatementPeriodNumber} Bank Statement to apply.`,
        ),
        primaryBtnTitle: getSuitableBtnTitle('Add Bank Statement'),
        secondaryBtnTitle: 'Close',
        allowContinue: false,
        replaceBody: false,
        includeSensitiveInfo: true,
      }
    }

    if (required.payslipsDuration) {
      return {
        title: 'Add Payslips',
        body: getSuitableMessage(
          `This property manager requires ${payslipsDuration} weeks of payslips to apply.`,
        ),
        primaryBtnTitle: getSuitableBtnTitle('Add Payslips'),
        secondaryBtnTitle: isPayslipsDurationSufficient(paySlips)
          ? 'Continue'
          : 'Close',
        allowContinue: isPayslipsDurationSufficient(paySlips),
        replaceBody: false,
        includeSensitiveInfo: true,
      }
    }

    if (required.otherDocs && !isOtherDocsSufficient(otherIncomeDocuments)) {
      return {
        title: 'Add More Proof Of Income',
        body: getSuitableMessage('Please upload an income document.'),
        primaryBtnTitle: getSuitableBtnTitle('Add Income'),
        secondaryBtnTitle: 'Continue',
        allowContinue: true,
        replaceBody: false,
        includeSensitiveInfo: true,
      }
    }

    if (required.govDocs && !isGovDocsSufficient(governmentBenefitDocuments)) {
      return {
        title: 'Add More Proof Of Income',
        body: getSuitableMessage(
          'Please add documents supporting Government benefits.',
        ),
        primaryBtnTitle: getSuitableBtnTitle('Add Income'),
        secondaryBtnTitle: 'Continue',
        allowContinue: true,
        replaceBody: false,
        includeSensitiveInfo: true,
      }
    }

    if (hasAutoDeletedFile) {
      return hasAutoDeletedDocsWarningModalConf
    }

    return generalWarningModal
  }

  const modalConfig = getModalConfig({
    payslipsCount: isPayslipsCountRequired,
    bankStatement: isBankStatementRequired,
    payslipsDuration: isPayslipsDurationRequired,
    otherDocs: isOtherDocsTicked,
    govDocs: isGovDocsTicked,
  })

  return (
    <GenericModal
      onDismiss={() => onPrimaryClick()}
      title={modalConfig.title}
      modalFooter={
        <GenericModalFooter
          primaryBtnConfig={{
            title: modalConfig.primaryBtnTitle,
            onClick: () => onPrimaryClick(),
          }}
          secondaryBtnConfig={{
            title: modalConfig.secondaryBtnTitle,
            onClick: () => onSecondaryClick(modalConfig.allowContinue),
          }}
        />
      }
    >
      {modalConfig.includeSensitiveInfo && <AlertSensitiveInfo />}
      <Box my={6}>
        <TextSpan lineHeight={theme.lineHeights.encrption}>
          {!modalConfig.replaceBody &&
            'To help demonstrate affordability, consider adding a recent Payslip, Proof of Income Sources and/or a Bank Statement or Government benefits.'}
          {modalConfig.body}
        </TextSpan>
      </Box>
    </GenericModal>
  )
}
