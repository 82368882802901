import React from 'react'

import { Link } from 'react-router-dom'

import { websiteUrl } from 'app/shared_components/shared_base_url'
import { openZendeskWidget } from 'app/utils/widgets/helpers'

const toggleChat = () => {
  openZendeskWidget()
}

export const ResourcePanel = ({ bondCovers, role }) => (
  <div className="col-second dashboard-resource-panel">
    <div className="renter-dashboard-resource">
      <h4>More information for {role}</h4>
      {bondCovers.length > 0 && (
        <div className="renter-dashboard-video-second-col mt20 mb20">
          {/* <YouTube
            videoId="VVi91REpsEk"
            opts={{ height: '195', width: '100%' }}
          /> */}
          {
            <iframe
              title="video"
              src="https://www.youtube.com/embed/Brj3ySeY53c"
              height="195"
              width="100%"
              frameBorder="0"
            ></iframe>
          }
        </div>
      )}
      <p>Find out more about BondCover and Snug.</p>
      {/* <p>
        <b>Update:</b> The ACT government is leading the way on bond reform.
        &nbsp;Change is coming. Renters who <a target="_blank" href={`${websiteUrl}/bondcover/renter/`}>pre-register</a> for BondCover in the ACT save $50.
      </p> */}
      <p>
        <b>Update:</b> check out the{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${websiteUrl}/betterbonds`}
        >
          #BetterBonds
        </a>{' '}
        campaign calling on State Govts to unlock $4 billion in rental bond
        cash.
      </p>
      <p>
        <b>Promotion: ACT </b> Renters save $50,{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${websiteUrl}/bondcover/renter/`}
        >
          pre-register
        </a>{' '}
        for BondCover now!
      </p>
      <p className="mb10">BondCover resources:</p>
      <ul>
        <li>
          <Link target="_blank" to={`${websiteUrl}/bondcover/terms`}>
            Terms and Conditions
          </Link>
        </li>
        <li>
          <Link target="_blank" to={`${websiteUrl}/betterbonds/`}>
            #betterbonds Campaign
          </Link>
        </li>
        {role === 'Renters' && (
          <li>
            <Link target="_blank" to={`${websiteUrl}/bondcover/renter/`}>
              For Renters
            </Link>
          </li>
        )}
        {role === 'Renters' && (
          <li>
            <Link target="_blank" to={`${websiteUrl}/bondcover/manager/`}>
              For Managers
            </Link>
          </li>
        )}
        <li>
          <Link target="_blank" to="https://snug.com/help">
            Help Centre
          </Link>
        </li>
        <li>
          <Link target="_blank" to={`${websiteUrl}/blog`}>
            Blog
          </Link>
        </li>
      </ul>
      <p>
        And you can always reach us at{' '}
        <a href="mailto:hello@snug.com">hello@snug.com</a> or{' '}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={() => toggleChat()}>chat with our team</a>.
      </p>
    </div>
  </div>
)
