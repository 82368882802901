import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import {
  actionCreators as localEntitiesActions,
  actionTypes as localEntitiesActionTypes,
} from 'app/core/localEntitiesSlice'
import { generateOfflineActionSagaWatcherAndWorker } from 'app/core/offlineActionsSagas.utils'
import { actionTypes as pendingTasksActionTypes } from 'app/core/pendingTasksSlice'
import {
  actionCreators as viewingAttendeesActions,
  actionTypes as viewingAttendeesActionTypes,
} from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingDetailsAttendeesTab/viewingAttendeesTabSlice'
import {
  createRegistrant,
  sendApplyInvitationForRegistrant,
} from 'app/services/http/viewings'
import * as snugNotifier from 'app/services/snugNotifier'
import { createLoadingStateUtils } from 'app/utils/loading-states'

export const { watcher: addRegistrantWatcher, worker } =
  generateOfflineActionSagaWatcherAndWorker({
    loadingStatesUtils: null,
    registrant: null,

    actionType: viewingAttendeesActionTypes.addRegistrant,
    syncTaskNameCreator: ({ registrantPayload }) =>
      `Creating ${registrantPayload.firstName} ${registrantPayload.lastName} Registrant`,
    actionStarter: function* () {
      this.loadingStatesUtils = createLoadingStateUtils()
    },
    liveActionStarter: function* () {
      yield put(
        viewingAttendeesActions.updateAddRegistrantLoadingStates(
          this.loadingStatesUtils.startLoading(),
        ),
      )
    },
    asyncCallParams: function (action) {
      const { viewingId, registrantPayload } = action
      return [createRegistrant, registrantPayload, viewingId]
    },

    onLiveSuccess: function* (action, registrant) {
      const { viewingId } = action
      const state = yield select()
      yield put(
        viewingAttendeesActions.updateAddRegistrantLoadingStates(
          this.loadingStatesUtils.markDoneSuccessfully(),
        ),
      )
      yield put(viewingAttendeesActions.updateIsAddRegistrantModalOpened(false))
      yield put(
        viewingAttendeesActions.registrantCreated(registrant, viewingId, state),
      )
      snugNotifier.success(`${registrant.fullName} has been added`)
    },
    onSyncSuccess: function* (action, registrant) {
      const { registrantPayload, viewingId } = action
      const state = yield select()
      yield put(localEntitiesActions.removeRegistrant(registrantPayload))
      yield put(
        viewingAttendeesActions.registrantCreated(registrant, viewingId, state),
      )
    },

    onLiveFailed: function* (action) {
      const { registrantPayload, viewingId } = action
      yield put(
        localEntitiesActions.addRegistrant(registrantPayload, viewingId),
      )
      yield put(
        viewingAttendeesActions.combineViewingRegistrantsWithLocal(viewingId),
      )
      yield put(
        viewingAttendeesActions.updateAddRegistrantLoadingStates(
          this.loadingStatesUtils.addedToPending(),
        ),
      )
      yield put(viewingAttendeesActions.updateIsAddRegistrantModalOpened(false))
    },

    onError: function* (action, err) {
      snugNotifier.error(err.message)
      yield put(
        viewingAttendeesActions.updateAddRegistrantLoadingStates(
          this.loadingStatesUtils.setError(err),
        ),
      )
    },
  })

export function* combineSyncedRegistrantsWithLocal() {
  const state = yield select()
  const registrants = state.localEntities.registrants.all
  const { syncedRegistrants, viewingId } = state.uiState.viewingAttendeesTab
  const localViewingRegistrants = Object.entries(registrants)
    .filter(([id, registrant]) => registrant.__viewingId === viewingId)
    .reduce(
      (accRegistrants, [id, registrant]) => ({
        ...accRegistrants,
        [id]: registrant,
      }),
      {},
    )
  const combinedRegistrants = {
    ...syncedRegistrants,
    ...localViewingRegistrants,
  }
  yield put(
    viewingAttendeesActions.pushCombinedRegistrants(combinedRegistrants),
  )
}

export function* watchCombineRegistrants() {
  yield takeEvery(
    viewingAttendeesActionTypes.combineSyncedRegistrantsWithLocal,
    combineSyncedRegistrantsWithLocal,
  )
}

export function* watchActionsNeedsUpdateWithLocal() {
  const callCombineWithLocal = function* () {
    yield put(viewingAttendeesActions.combineViewingRegistrantsWithLocal())
  }

  yield all([
    yield takeEvery(
      viewingAttendeesActionTypes.registrantCreated,
      callCombineWithLocal,
    ),
    yield takeEvery(
      viewingAttendeesActionTypes.syncedRegistrantsLoaded,
      callCombineWithLocal,
    ),
    yield takeEvery(
      viewingAttendeesActionTypes.updateSyncedRegistrant,
      callCombineWithLocal,
    ),
    yield takeEvery(
      viewingAttendeesActionTypes.addSyncedRegistrant,
      callCombineWithLocal,
    ),
    yield takeEvery(
      localEntitiesActionTypes.removeRegistrant,
      callCombineWithLocal,
    ),
  ])
}

export function* watchSendApplyInvitations() {
  yield takeEvery(
    viewingAttendeesActionTypes.registrantCreated,
    function* ({ registrant, viewingId }) {
      try {
        yield call(
          sendApplyInvitationForRegistrant,
          viewingId,
          registrant.guidID,
        )
      } catch (e) {
        snugNotifier.error(
          `Something went wrong! Couldn't send invitation for ${registrant.fullName}`,
        )
      }
    },
  )
}

export function* watchSkippedTasks() {
  yield takeEvery(pendingTasksActionTypes.taskSkipped, function* ({ task }) {
    const { type, ...payload } = task
    switch (type) {
      case viewingAttendeesActionTypes.addRegistrant: {
        const { registrantPayload } = payload
        yield put(localEntitiesActions.removeRegistrant(registrantPayload))
        return
      }
    }
  })
}

export function* viewingDetailsEffects() {
  yield all([
    watchSendApplyInvitations(),
    addRegistrantWatcher(),
    watchCombineRegistrants(),
    watchActionsNeedsUpdateWithLocal(),
    watchSkippedTasks(),
  ])
}
