import { Box } from 'app/components/design-system-components'
import TableComponent from 'app/components/design-system-components/Table'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { currencyFormat } from 'app/utils/numbers/helpers'

interface ManagerVacancyTableProps {
  managerName: string
  offersList: any[]
  offersTotals: any
}

export const ManagerDetailedVacancyTable = ({
  managerName,
  offersList,
  offersTotals,
}: ManagerVacancyTableProps) => {
  const mapBoolToBinary = (isTrue: boolean) => (isTrue ? 1 : 0)

  const colDefs = [
    {
      accessor: 'address',
      Header: managerName,
    },
    {
      accessor: 'daysOnMarket',
      Header: 'DoM',
      Footer: Math.round(offersTotals.avgDaysOnMarket),
    },
    {
      accessor: 'isCurrentlyVacant',
      Header: 'Vacant Listings',
      Cell: ({ value }: any) => mapBoolToBinary(value as boolean),
      Footer: offersTotals.vacantProperties,
    },
    {
      accessor: 'isVacantLessThanWeek',
      Header: 'Vacant 0-7 days',
      Cell: ({ value }: any) => mapBoolToBinary(value as boolean),
      Footer: offersTotals.lessWeekVacantProperties,
    },
    {
      accessor: 'isVacantMoreThanWeek',
      Header: 'Vacant >7 days',
      Cell: ({ value }: any) => mapBoolToBinary(value as boolean),
      Footer: offersTotals.moreWeekVacantProperties,
    },
    {
      accessor: 'vacantDays',
      Header: 'Vacancy',
      Footer: offersTotals.vacantDays,
    },
    {
      accessor: 'lostRent',
      Header: 'Lost Rent',
      Cell: ({ value }: any) => currencyFormat(value, 0),
      Footer: currencyFormat(offersTotals.lostRent, 0),
    },
    {
      accessor: 'isNewManagement',
      Header: 'New Biz',
      Cell: ({ value }: any) => mapBoolToBinary(value as boolean),
      Footer: offersTotals.newManagement,
    },
    {
      accessor: 'receivedApps',
      Header: 'App Received',
      Footer: offersTotals.receivedApps,
    },
    {
      accessor: 'enquiries',
      Header: 'Enquiries',
      Footer: offersTotals.totalEnquiries,
    },
  ]

  return (
    <>
      <Box width="100%" overflowX="auto">
        <TableComponent
          columns={colDefs}
          headerBgColor={theme.colors.white}
          dataToBeEntered={offersList}
          textColor={theme.colors.gray600}
          showHeaderBottomBorder
          showRowBottomBorder
          thPadding={`${theme.space[5]}px`}
          fontSize={theme.fontSizes.pRegular14}
          verticalAlignTop
          sortable
        />
      </Box>
    </>
  )
}
