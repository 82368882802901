// Deprecated
import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

const propTypes = {
  image_url: PropTypes.string,
  name: PropTypes.string,
  user_src: PropTypes.string,
}

const Holder = styled.div`
  float: left;
  margin: 1px 22px -2px 0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  img {
    height: auto;
    width: 100%;
    display: block;
  }
`

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--pdf-blue);
  border-radius: 50%;
`

function Avatar({ image_url, name, user_src }) {
  const img = !image_url ? <Placeholder /> : <img src={image_url} alt={name} />

  return <Holder>{!user_src ? img : <a href={user_src}>{img}</a>}</Holder>
}

Avatar.propTypes = propTypes

export default Avatar
