import React from 'react'

const ActivityRowField = ({ widthClass = '', fieldClass = '', field = '' }) => {
  return (
    <div className={`${widthClass} activity-content-field`}>
      <p className={fieldClass}>{field}</p>
    </div>
  )
}

export default ActivityRowField
