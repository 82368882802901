import { connect } from 'react-redux'

import {
  fetchRenterSignature,
  signByRenter,
} from 'app/bond_cover/bond_cover_actions.js'
import RenterSignature from 'app/bond_cover/renters/signature/signature'

const mapStateToProps = ({ bondCover }) => ({
  currentLease: bondCover.currentLease,
  quote: bondCover.quote,
  signature: bondCover.signature,
  apiError: bondCover.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchRenterSignature: (requestID) => fetchRenterSignature(requestID),
  signByRenter: (requestID, signature, isPrimary) =>
    signByRenter(requestID, signature, isPrimary),
})

export default connect(mapStateToProps, mapDispatchToProps)(RenterSignature)
