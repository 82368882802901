import React from 'react'

import { Alert, Box, TextSpan } from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { reserveKeySet } from 'app/services/http/teams/key-logger'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

const _renderReserveKeysetModalFooter = (reserve, onCancel, isSubmitting) => {
  return (
    <GenericModalFooter
      primaryBtnConfig={{
        title: 'Reserve',
        onClick: reserve,
        props: {
          loading: !!isSubmitting,
        },
      }}
      secondaryBtnConfig={{
        title: 'Cancel',
        onClick: onCancel,
      }}
    />
  )
}

export const ReserveKeySetModal = ({ teamId, keySet, closeModal }) => {
  console.log(keySet)
  const {
    loadingStates: createReservationLoadingStates,
    loadingStatesHelpers: createReservationLoadingStateHelpers,
  } = useLoadingStates()

  const onReserve = () => {
    createReservationLoadingStateHelpers.startLoading()
    return reserveKeySet(teamId, keySet.guid)
      .then((response) => {
        createReservationLoadingStateHelpers.markDoneSuccessfully()
        closeModal(response, true)
      })
      .catch((err) => {
        createReservationLoadingStateHelpers.setError(err)
      })
  }

  return (
    <GenericModal
      onDismiss={() => closeModal()}
      title="Reserve"
      modalFooter={_renderReserveKeysetModalFooter(
        onReserve,
        () => closeModal(),
        createReservationLoadingStates.state === loadingStatesIds.LOADING,
      )}
    >
      {!!createReservationLoadingStates.error && (
        <Alert variant="primaryWithBg" mb={4}>
          {createReservationLoadingStates.error}
        </Alert>
      )}
      <Box mb={8}>
        <Box mb={5}>Reserve key:</Box>
        <Box mb={3}>
          <TextSpan as="span" fontWeight={theme.fontWeights.h5}>
            Key:
          </TextSpan>{' '}
          {keySet?.label}
        </Box>
        <Box mb={3}>
          <TextSpan as="span" fontWeight={theme.fontWeights.h5}>
            Address:
          </TextSpan>{' '}
          {keySet?.property?.address?.friendlyName}
        </Box>
      </Box>
    </GenericModal>
  )
}
