import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  Flex,
  TextSpan,
  WhiteBGButton,
} from 'app/components/design-system-components'
import { Lightning } from 'app/components/design-system-components/icons/snug-specific'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { history } from 'app/shared_components/router'
import { urlIds, urlTo } from 'app/sm/helpers'

const RequestBGCOptionLabel = ({
  disableRequestBGCOption,
  bgcPrice,
  teamSlug,
  alreadyHasBackgroundCheck,
}) => {
  const messageWhenDisabled = 'Request owner Background Check approval '
  const messageWhenEnabled =
    'Request owner approval to process Background Check'
  const redirectToSettings = () => {
    history.push(urlTo(urlIds.teams.settings.screening, { teamSlug }))
  }
  return (
    <>
      <Flex alignItems="baseline">
        <Text
          fontSize={theme.fontSizes.pExtraLarge18}
          fontWeight={theme.fontWeights[4]}
          as="span"
        >
          {disableRequestBGCOption ? messageWhenDisabled : messageWhenEnabled}
          {!disableRequestBGCOption && ` for ${bgcPrice} (inc GST)`}
        </Text>
        {disableRequestBGCOption && !alreadyHasBackgroundCheck && (
          <>
            <WhiteBGButton
              data-tooltip-id="activateTooltip"
              data-tooltip-content=""
              ml={1}
              alignItems="center"
              onClick={redirectToSettings}
            >
              <Lightning
                width={theme.width[4] + 'px'}
                height={theme.height[4] + 'px'}
                color={theme.colors.warning400}
              />
              <TextSpan as="span" ml={2}>
                Activate
              </TextSpan>
            </WhiteBGButton>
            <ReactTooltip id="activateTooltip">
              Your team admin can turn on in Team Settings {'->'} Screening
            </ReactTooltip>
          </>
        )}
      </Flex>
    </>
  )
}

export default RequestBGCOptionLabel
