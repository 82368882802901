import { connect } from 'react-redux'

import * as agencyAction from 'app/agency/agency_action'
import {
  addRentalLedger,
  fetchRentalHistoryReview,
  fetchRentalHistoryReviewByShortCode,
  fetchRentalHistoryReviewWithCatchableError,
  manualAddRentalLedger,
  manualRemoveRentalLedger,
  removeRentalLedger,
  rentalHistoryDecline,
  setRentalHistorySecret,
} from 'app/bond_cover/bond_cover_actions'
import {
  manualSubmitRentalReference,
  submitRentalReference,
} from 'app/match/rental_reference/rating_form/action'
import MatchRatingForm from 'app/match/rental_reference/rating_form/component'
import { addNote } from 'app/sm/review/review_actions'

const mapStateToProps = ({ bondCover, session }) => ({
  RentalHistory: bondCover.rentalHistoryReview.RentalHistory,
  ratingCategories: bondCover.ratingCategories,
  tenantRating: bondCover.rentalHistoryReview.Rating,
  renter: bondCover.rentalHistoryReview.Renter,
  message: bondCover.rentalHistoryReview.Message,
  isHistoryRating: true,
  currentUser: session.currentUser,
  secretGUID: bondCover.secretGUID,
  error: bondCover.error,
  showRentalLedgerSpinner: bondCover.showRentalLedgerSpinner,
  rentalHistoryReview: bondCover.rentalHistoryReview,
})

const mapDispatchToProps = (dispatch) => ({
  fetchRentalHistoryReviewWithCatchableError: (
    historyGUID,
    secretGUID,
    documentType,
  ) =>
    dispatch(
      fetchRentalHistoryReviewWithCatchableError(
        historyGUID,
        secretGUID,
        documentType,
      ),
    ),
  addRentalLedger: (historyGUID, secretGUID, documentType) =>
    dispatch(addRentalLedger(historyGUID, secretGUID, documentType)),
  removeRentalLedger: (historyGUID, secretGUID, ledgerGUID) =>
    dispatch(removeRentalLedger(historyGUID, secretGUID, ledgerGUID)),
  fetchRentalHistoryReview: (referenceId) =>
    dispatch(fetchRentalHistoryReview(referenceId)),
  fetchRentalHistoryReviewByShortCode: (shortCode) =>
    dispatch(fetchRentalHistoryReviewByShortCode(shortCode)),
  setRentalHistorySecret: (secretGUID) =>
    dispatch(setRentalHistorySecret(secretGUID)),
  manualSubmitRentalReference: (referenceId, payload) =>
    dispatch(manualSubmitRentalReference(referenceId, payload)),
  submitRentalReference: (referenceId, payload, secretId) =>
    dispatch(submitRentalReference(referenceId, payload, secretId)),
  manualAddRentalLedger: (historyGUID, secretGUID, documentType) =>
    dispatch(manualAddRentalLedger(historyGUID, secretGUID, documentType)),
  manualRemoveRentalLedger: (historyGUID, secretGUID, ledgerGUID) =>
    dispatch(manualRemoveRentalLedger(historyGUID, secretGUID, ledgerGUID)),
  rentalHistoryReferenceDecline: (referenceId, payload) =>
    dispatch(rentalHistoryDecline(referenceId, payload)),
  addNote: (applicationId, applicantId, payload) =>
    dispatch(addNote(applicationId, applicantId, payload)),
  selfRegistration: (payload, logo, banner) =>
    dispatch(agencyAction.selfServiceRegistration(payload, logo, banner)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MatchRatingForm)
