import React from 'react'

import {
  DefaultNoOptionsCmp,
  ResultsLabelHeaderRender,
  SelectField,
  SelectOptionsContainer,
  SimpleOptionContainer,
} from 'app/components/design-system-components/inputs/Select/Select'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import {
  getGooglePlaceDetails,
  getGoogleSuggestions,
} from 'app/services/http/googlemaps'
import * as snugNotifier from 'app/services/snugNotifier'
import { emptyAddress } from 'app/shared_components/address_form/address_form'

const GOOGLE_ADDRESS_GET_PLACES_ERROR =
  'There was an error. Please enter address manually.'

class GoogleAddress extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      googleInputValue: '',
      predictions: [],
      sessionToken: '',
      placeDetailsResult: {},
      addressInfo: {},
      controlPredict: {
        predictionsMade: false,
        predictionsFound: false,
        showDropdown: true,
      },
    }
  }
  componentDidMount() {
    const { address, update, country } = this.props
    this.setInput(address)
  }

  componentDidUpdate(prevProps) {
    const { address, queryStringAddress } = this.props
    if (address !== prevProps.address) {
      this.setInput(address)
    }
  }

  setInput(address) {
    const {
      unitNumber,
      streetNumber,
      streetName,
      suburb,
      state,
      country,
      googleId,
    } = address || {}
    if (unitNumber || streetNumber || streetName || suburb || state) {
      let googleAddress = [
        streetNumber,
        streetName,
        suburb,
        state,
        country,
      ].join(', ')

      if (unitNumber) {
        googleAddress = `${unitNumber}/${googleAddress}`
      }
      this.setState({
        googleInputValue: googleAddress,
      })
      return
    }
    this.setState({
      googleInputValue: '',
    })
  }

  autofillUnitAndStreetNumber = (
    txtAutocomplete,
    txtUnitNumber,
    txtStreetNumber,
    txtStreetName,
  ) => {
    // get all the user entered values before a match with the street name; group #1 = unit number, group #2 = street number
    const regex = RegExp('^(.*?)/(.*?) ' + txtStreetName)
    return regex.exec(txtAutocomplete)
  }

  formatAddress = () => {
    const { update } = this.props
    const { addressInfo, googleInputValue } = this.state

    const {
      address_components = '',
      geometry = '',
      place_id = '',
      name = '',
    } = addressInfo

    if (!(address_components && geometry)) {
      return update(emptyAddress())
    }

    const { location } = geometry
    const newAddress = {
      friendlyName: name,
      googleId: place_id,
    }

    address_components.forEach((component) => {
      const { long_name } = component
      switch (component.types[0]) {
        case 'subpremise':
          newAddress.unitNumber = long_name
          break
        case 'street_number':
          newAddress.streetNumber = long_name
          break
        case 'route':
          newAddress.streetName = long_name
          break
        case 'locality':
          newAddress.suburb = long_name
          break
        case 'administrative_area_level_1':
          newAddress.state = long_name
          break
        case 'country':
          newAddress.country = long_name
          break
        case 'postal_code':
          newAddress.postcode = long_name
          break
        default:
          break
      }
    })

    const rawAddress = googleInputValue.toUpperCase()
    const getUnitAndStreetNumber = this.autofillUnitAndStreetNumber(
      googleInputValue,
      newAddress.unitNumber,
      newAddress.streetNumber,
      newAddress.streetName,
    )
    if (Array.isArray(getUnitAndStreetNumber)) {
      newAddress.unitNumber = getUnitAndStreetNumber[1]
        ? getUnitAndStreetNumber[1]
        : newAddress.unitNumber
      newAddress.streetNumber = getUnitAndStreetNumber[2]
        ? getUnitAndStreetNumber[2]
        : newAddress.streetNumber
    }

    if (rawAddress && newAddress.streetNumber) {
      if (newAddress.unitNumber) {
        if (!rawAddress.startsWith(newAddress.unitNumber)) {
          newAddress.unitNumber = undefined
        }
      } else if (
        newAddress.streetNumber &&
        !rawAddress.startsWith(newAddress.streetNumber)
      ) {
        const unitNumberEndIndex = rawAddress.indexOf(
          newAddress.streetNumber.toUpperCase(),
        )
        newAddress.unitNumber = rawAddress.substring(0, unitNumberEndIndex - 1)
      }
    }
    if (newAddress.streetNumber) {
      if (newAddress.unitNumber) {
        if (!newAddress.friendlyName.startsWith(newAddress.unitNumber)) {
          newAddress.friendlyName = `${newAddress.unitNumber}/${newAddress.friendlyName}`
        }
      } else if (!newAddress.friendlyName.startsWith(newAddress.streetNumber)) {
        const streetNumberStartIndex = newAddress.friendlyName
          .toUpperCase()
          .indexOf(newAddress.streetNumber.toUpperCase())
        newAddress.friendlyName = newAddress.friendlyName.substring(
          streetNumberStartIndex,
          newAddress.friendlyName.length,
        )
      }
    }
    return newAddress
  }

  onSearchSuggestions = debounce((value) => {
    const { sessionToken = '', controlPredict } = this.state
    return getGoogleSuggestions(value, sessionToken)
      .then((data) => {
        const { response = {} } = data || {}
        const { autocompleteResponse = {}, sessionToken = '' } = response || {}
        const { predictions = [] } = autocompleteResponse || {}
        this.setState({
          predictions,
          sessionToken,
          controlPredict: {
            predictionsMade: true,
            predictionsFound: predictions && predictions.length > 0,
            showDropdown: true,
          },
        })
      })
      .catch((err) => snugNotifier.error(err?.message))
  }, STANDARD_TIMEOUT)

  updateSearchText = (event) => {
    const { googleInputValue } = this.state
    let { value } = event?.target
    this.setState({
      googleInputValue: value,
    })
    if (value) {
      this.onSearchSuggestions(value)
    }
  }

  getPlaceDetailsResult = (place_id) => {
    const { update } = this.props
    const { sessionToken } = this.state
    getGooglePlaceDetails(place_id, sessionToken)
      .then((data) => {
        const { response = {} } = data || {}
        const { placeDetailsResult = {} } = response || {}
        this.setState(
          {
            addressInfo: placeDetailsResult,
            controlPredict: {},
            sessionToken: '',
          },
          () => {
            const newAddress = this.formatAddress()
            update(newAddress)
          },
        )
      })
      .catch((err) => snugNotifier.error(err?.message))
  }

  render() {
    let {
      placeholder,
      onBlur,
      error,
      disabled,
      inputDisabled = false,
      toggleSuggest,
      suggest,
    } = this.props

    const {
      googleInputValue,
      predictions = [],
      controlPredict = {},
    } = this.state
    const { predictionsFound, predictionsMade } = controlPredict

    return (
      <div className="input-address-google">
        <div className={`input-box ${error && disabled ? 'error' : ''}`}>
          <div className="mb10">
            <input
              disabled={inputDisabled}
              type="text"
              required
              id="googleAddress"
              placeholder=""
              onBlur={onBlur}
              autocomplete="off"
              onChange={(event) => this.updateSearchText(event)}
              value={googleInputValue}
            />
            <label className={inputDisabled && 'fixedLabelStyle'}>
              {placeholder}
            </label>
          </div>
          {googleInputValue.length > 0 && predictionsMade && (
            <SelectOptionsContainer>
              <ResultsLabelHeaderRender />
              {predictionsFound &&
                predictions.map((prediction) => {
                  return (
                    <SimpleOptionContainer
                      onClick={() =>
                        this.getPlaceDetailsResult(prediction.place_id)
                      }
                    >
                      {prediction?.description}
                    </SimpleOptionContainer>
                  )
                })}
              {!predictionsFound && <DefaultNoOptionsCmp />}
            </SelectOptionsContainer>
          )}
          {!inputDisabled && (
            <div
              className={`${
                suggest ? 'note note-google' : 'note note-manual'
              } mt10`}
            >
              {suggest
                ? error
                  ? 'Please try '
                  : "Can't find your address? "
                : ''}
              <a onClick={() => toggleSuggest()}>
                {suggest
                  ? error
                    ? 'entering your address manually'
                    : 'Add manually'
                  : 'Suggest address'}
              </a>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default GoogleAddress
