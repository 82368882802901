import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import { Button } from 'app/components/design-system-components/Button'
import Flex from 'app/components/design-system-components/Flex'
import theme from 'app/match/applicationReportPDF/assets/theme'

const ActivityItemDot = styled(Box)`
  border: 3px solid ${theme.colors.skyBlue};
  border-radius: 100%;
  position: relative;
  width: 10px;
  height: 10px;
  background: ${theme.colors.paleBlue};
`

const StyledActivityItem = styled(Box)`
  padding-left: 12px;
  border-left: 1px solid ${theme.colors.grey200};
  margin-left: 5px;
  padding-bottom: 16px;
`

const StyledActivityItemWithDotContainer = styled(Box)`
  padding: 0 ${theme.space[3]}px ${theme.space[3]}px;
  ${(props) =>
    props.hasActionOnActivityClick &&
    `
  cursor: pointer;
  &:hover {
    background: rgba(230, 230, 230, 0.25);
  }
  `};
`

const StyledDateInfo = styled(Box)`
  font-size: ${theme.fontSizes.pSmall12};
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: ${theme.colors.gray500};
`

const StyledCommentInfo = styled(Box)`
  font-size: ${theme.fontSizes.pRegular14};
  font-style: normal;
  line-height: 140%;
  color: ${theme.colors.gray700};
`

const StyledPropertyInfo = styled(Box)`
  font-size: ${theme.fontSizes.pRegular14};
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${theme.colors.gray400};
`

const StyledComment = styled(Box)`
  font-size: ${theme.fontSizes.pRegular14};
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${theme.colors.gray400};
  padding: ${theme.space[3]}px 0;
  &::before {
    content: '"';
  }
  &::after {
    content: '"';
  }
`

const StyledAction = styled(Button)`
  font-size: ${theme.fontSizes.pSmall12};
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`

const ActivityItemWithDot = ({
  dateInfo = '',
  commentInfo = <></>,
  propertyAddress = '',
  commentContent = '',
  hasLink = false,
  actionButtonClick = () => {},
  actionButtonLabel = '',
  onActivityItemClick = null,
  icon = <ActivityItemDot />,
}) => {
  return (
    <StyledActivityItemWithDotContainer
      onClick={onActivityItemClick}
      hasActionOnActivityClick={onActivityItemClick !== null}
    >
      <Flex alignItems="center">
        {icon}
        <StyledDateInfo pl={3}>{dateInfo}</StyledDateInfo>
      </Flex>
      <StyledActivityItem>
        {commentInfo && <StyledCommentInfo>{commentInfo}</StyledCommentInfo>}
        <StyledPropertyInfo>{propertyAddress}</StyledPropertyInfo>
        {commentContent && <StyledComment>{commentContent}</StyledComment>}
        {hasLink && (
          <StyledAction
            onClick={actionButtonClick}
            variant="linkBlueUnderlined"
          >
            {actionButtonLabel}
          </StyledAction>
        )}
      </StyledActivityItem>
    </StyledActivityItemWithDotContainer>
  )
}

export default ActivityItemWithDot
