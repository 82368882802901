import { connect } from 'react-redux'

import {
  fetchRequestSummary,
  getBondCoverCompleteSummary,
  getSecondaryTenantSummary,
} from 'app/bond_cover/bond_cover_actions'
import RenterComplete from 'app/bond_cover/renters/status/request_complete'

const mapStateToProps = ({ bondCover, session }) => ({
  apiError: bondCover.error,
  currentUser: session.currentUser,
  quote: bondCover.quote,
  bondCoverRequest: bondCover.request,
})

const mapDispatchToProps = (dispatch) => ({
  fetchRequestSummary: (requestID) => fetchRequestSummary(requestID),
  getSecondaryTenantSummary: (requestID) =>
    getSecondaryTenantSummary(requestID),
  getBondCoverCompleteSummary: (requestID) =>
    getBondCoverCompleteSummary(requestID),
})

export default connect(mapStateToProps, mapDispatchToProps)(RenterComplete)
