import React from 'react'

import { Link } from 'react-router-dom'

import humanIcon3 from 'app/assets/icons/human03.jpg'

function AssignedTo() {
  return (
    <div className="sm-shadow-card assigned-to">
      <div className="header xs-mb20">
        <h4 className="ibm lh1">Assigned to</h4>
        <i className="ibm icon-dot border-color fs13 pl5 pr5"></i>
        <a className="ibm gray-dark-color" href="#">
          Change
        </a>
      </div>
      <div className="content">
        <div className="table">
          <div className="cell-t-100">
            <h4 className="mb5">James Toney</h4>
            <div className="gray-color fs16 fw600 mb20">Service Provider</div>
            <a className="block mb10" href="#">
              help@toneyandsons.au
            </a>
            <a className="block" href="#">
              +1 31 217 9271
            </a>
          </div>
          <div className="cell-t">
            <div
              className="avatar avatar-big xs-avatar-small"
              style={{ backgroundImage: `url(${humanIcon3})` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssignedTo
