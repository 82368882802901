import * as errorCodes from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import * as API from 'app/sm/plugins/plugins_access'

export function activateIntegration(teamGUID, data) {
  return () => {
    return API.activateIntegration(teamGUID, data).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function getConsoleCloudIntegration(teamGUID, data) {
  return () => {
    return API.getConsoleCloudIntegration(teamGUID).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          return responseText.then((error) => {
            if (error !== errorCodes.ErrorIntegrationDoesNotExist) {
              Promise.reject(translateErrorCodeToMessage(error))
            }
          })
        }
      },
    )
  }
}

export function getIntegrationActivity(teamGUID, data) {
  return () => {
    return API.getIntegrationActivity(teamGUID, data).then(
      ({ ok, activity, responseText }) => {
        if (ok) {
          return Promise.resolve(activity)
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}
