import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { composeAttachment } from 'app/shared_components/helpers'
import { InverseDocumentTypes } from 'app/sm/helpers'
import {
  deleteDocument,
  getExtraIncome,
  getProofOfIncome,
  postExtraIncome,
  putExtraIncome,
  uploadDocument,
} from 'app/sm/proof_of_income/proof_of_income_access'

export const RECEIVE_PROOF_OF_INCOME = 'RECEIVE_PROOF_OF_INCOME'
export const TOGGLE_SPINNER = 'POI_TOGGLE_SPINNER'
export const RECEIVE_RESPONSE_TEXT = 'POI_RECEIVE_RESPONSE_TEXT'

const receiveProofOfIncome = (documents) => ({
  type: RECEIVE_PROOF_OF_INCOME,
  documents,
})
const toggleSpinner = (documentType, spinnerOnOff) => ({
  type: TOGGLE_SPINNER,
  documentType,
  spinnerOnOff,
})
const receiveResponseText = (responseText, fileType) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
  fileType,
})

// Cause we use a diffrent naming
const formatPoI = (o) => ({
  paySlips: o.Payslips || [],
  bankStatements: o.BankStatements || [],
  otherIncomeDocuments: o.OtherIncomeDocuments || [],
  governmentBenefitDocuments: o.GovernmentBenefitDocuments || [],
})

export const fetchProofOfIncome = () => {
  return (dispatch) => {
    return getProofOfIncome().then(({ ok, Record, responseText }) => {
      if (ok) {
        dispatch(receiveProofOfIncome(formatPoI(Record)))
        return Promise.resolve()
      } else {
        responseText.then((t) => {
          dispatch(receiveResponseText(t, 'Payslips'))
          dispatch(receiveResponseText(t, 'BankStatements'))
          dispatch(receiveResponseText(t, 'OtherIncomeDocuments'))
          dispatch(receiveResponseText(t, 'GovernmentBenefitDocuments'))
        })
      }
    })
  }
}

export const fetchExtraIncome = () => {
  return () => {
    return getExtraIncome().then(({ ok, responseText, extraIncome }) => {
      if (ok) {
        return Promise.resolve(extraIncome)
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    })
  }
}

export const editExtraIncome = (referenceId, extraIncome) => {
  return () => {
    return putExtraIncome(referenceId, extraIncome).then(
      ({ ok, responseText, result }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const addExtraIncome = (extraIncome) => {
  return () => {
    return postExtraIncome(extraIncome).then(({ ok, responseText, result }) => {
      if (ok) {
        return Promise.resolve(result)
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    })
  }
}

// This is an upload functionality
export const addProofOfIncome = (documentType, fileType) => {
  return (dispatch) => {
    let attachments = document.getElementById(`attachments-${documentType}`)
    let f = new FormData()
    composeAttachment(attachments, f)
    f.append('type', documentType)

    dispatch(toggleSpinner(InverseDocumentTypes.get(documentType), true))

    uploadDocument(f).then(({ ok, statusCode, responseText, statusText }) => {
      if (ok) {
        dispatch(fetchProofOfIncome())
      } else {
        responseText.then((t) => {
          if (!t) {
            dispatch(
              receiveResponseText(`${statusCode} ${statusText}`, fileType),
            )
          } else {
            dispatch(receiveResponseText(t, fileType))
          }
        })
      }
      dispatch(toggleSpinner(InverseDocumentTypes.get(documentType), false))
    })
  }
}

export const deleteProofOfIncome = (id, fileType) => {
  return (dispatch) => {
    deleteDocument(id).then(({ ok, statusCode, responseText, statusText }) => {
      if (ok) {
        dispatch(fetchProofOfIncome())
      } else {
        responseText.then((t) => {
          if (!t) {
            dispatch(
              receiveResponseText(`${statusCode} ${statusText}`, fileType),
            )
          } else {
            dispatch(receiveResponseText(t, fileType))
          }
        })
      }
    })
  }
}
