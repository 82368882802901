import React from 'react'

import ConsoleCloudContainer from 'app/sm/plugins/components/console_cloud/connection'
import { consoleCloudFeatureText } from 'app/utils/strings/helpers'

const Plugins = [
  {
    key: 'consolecloud',
    value: consoleCloudFeatureText,
  },
]

class PluginsContainer extends React.Component {
  getPluginName = (field) => {
    let name = Plugins.find((data) => {
      return field === data.key
    })
    return name.value
  }

  render() {
    const teamSlug = this.props.teamSlug
    return (
      <div className="">
        <ConsoleCloudContainer teamSlug={teamSlug} />
      </div>
    )
  }
}

export default PluginsContainer
