import { connect } from 'react-redux'

import RolePromptWrapper from 'app/session/join/role_prompt/role_prompt_wrapper'
import { fetchTeams } from 'app/session/session_actions'
import { changeRole } from 'app/sm/properties/properties_actions'

const mapStateToProps = ({ dashboard, session, MyProperties, Shared }) => ({
  bondCovers: dashboard.bondCovers,
  currentAgency: session.currentAgency,
  applicationsCount: MyProperties.applicationsCount,
  teams: session.teams,
  backUrl: Shared.backUrl,
  rolePromptOn: session.rolePromptOn,
})

const mapDispatchToProps = (dispatch) => ({
  changeRole: (role) => dispatch(changeRole(role)),
  fetchTeams: () => dispatch(fetchTeams()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RolePromptWrapper)
