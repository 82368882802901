import React, { Component } from 'react'

import * as Form from 'app/components/forms/forms'
import { QuestionType } from 'app/sm/helpers'

const isBooleanAnswerTrue = (answer) => answer === 'true'
const isBooleanAnswerFalse = (answer) => answer === 'false'

const onBooleanAnswerChanged = (questionSetting) => {
  const { disabledRating, updateQuestionAnswer, code, result, referenceId } =
    questionSetting
  !!!disabledRating &&
    updateQuestionAnswer(code, result, referenceId, QuestionType.Boolean)
}

export class BooleanAnswerComponent extends Component {
  render() {
    const {
      questionCategory,
      existingAnswer,
      updateQuestionAnswer,
      referenceId,
      disabledRating,
    } = this.props
    const isYesChosen = existingAnswer
      ? isBooleanAnswerTrue(existingAnswer)
      : false
    const isNoChosen = existingAnswer
      ? isBooleanAnswerFalse(existingAnswer)
      : false
    return (
      <div
        className="rating-categories-box"
        key={questionCategory.Code + questionCategory.Description}
      >
        <div className="rating-desc"> {questionCategory.Description} </div>
        <div key={questionCategory.Code}>
          <label className="block mb15">
            <Form.CheckBoxGeneral
              label={'Yes'}
              eventProcessedByComponent={true}
              checked={isYesChosen}
              onChange={() =>
                onBooleanAnswerChanged({
                  disabledRating,
                  code: questionCategory.Code,
                  result: 'true',
                  referenceId,
                  questionTypeBoolean: QuestionType.Boolean,
                  updateQuestionAnswer,
                })
              }
            />
          </label>
          <label className="block mb15">
            <Form.CheckBoxGeneral
              label={'No'}
              eventProcessedByComponent={true}
              checked={isNoChosen}
              onChange={() =>
                onBooleanAnswerChanged({
                  disabledRating,
                  code: questionCategory.Code,
                  result: 'false',
                  referenceId,
                  questionTypeBoolean: QuestionType.Boolean,
                  updateQuestionAnswer,
                })
              }
            />
          </label>
        </div>
      </div>
    )
  }
}

export default BooleanAnswerComponent
