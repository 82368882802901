import React from 'react'

import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ModalContentWrapper } from 'app/modals/modal-styled'

const StyledModalContentWrapper = styled(ModalContentWrapper)`
  max-width: 516px;

  @media (min-width: 768px) {
    width: 516px;
    height: 641px;
  }
`

export const StepsModalContainer = ({ children }) => {
  return (
    <Box className="modal-fullscreen" bg={theme.colors.overlay}>
      <StyledModalContentWrapper maxWidth="516px">
        {children}
      </StyledModalContentWrapper>
    </Box>
  )
}
