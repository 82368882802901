import { noteActionType, targetSectionObject } from 'app/sm/helpers'

export const getNotePayloadForShortlistViewingRegistrant = (
  shortlist: boolean,
) => {
  return {
    type: shortlist
      ? noteActionType.viewingAttendeeRemoveShortlist
      : noteActionType.viewingAttendeeAddShortlist,
    target: shortlist
      ? targetSectionObject.viewingAttendeeRemoveShortlist['index']
      : targetSectionObject.viewingAttendeeAddShortlist['index'],
  }
}
