import { connect } from 'react-redux'

import {
  acceptInvite,
  validateInvite,
} from 'app/bond_cover/agency/agency_actions'
import { AgencyInviteContainer } from 'app/bond_cover/agency/agency_invite_container'
import { logout } from 'app/session/session_actions'

const mapStateToProps = ({ session }) => ({
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => logout(),
  validateInvite,
  acceptInvite,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgencyInviteContainer)
