import React, { Component } from 'react'

import Banner from 'app/sm/alternate_owner_profile/components/banner'

const parole =
  'Select a profile and we`ll automatically set up your preferences.'
const selections = [
  {
    type: 'Speculator',
    description:
      'Lorem ipsum dolor sit amet,conscetaur adispicing elit, sed do eiusmod tempor intisicut ut ulur.',
  },
  {
    type: 'Long Term Investor',
    description:
      'Lorem ipsum dolor sit amet,conscetaur adispicing elit, sed do eiusmod tempor intisicut ut ulur.',
  },
  {
    type: 'Rentvestor',
    description:
      'Lorem ipsum dolor sit amet,conscetaur adispicing elit, sed do eiusmod tempor intisicut ut ulur.',
  },
]

class Choice extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <Banner parole={parole} />
        <div className="two-col-box sm64">
          <div className="col-first">
            <div className="">
              {selections.map((s) => (
                <div key={s.type} className="shadow-wrapper mb10">
                  <h4 className="mb10 mt3">{s.type}</h4>
                  <p className="mb10">{s.description}</p>
                  <button className="mb10">Yup, that`s me!</button>
                </div>
              ))}
            </div>
            <div className="mt40">
              <h4 className="mb10">None of the above?</h4>
              <p className="mb0">
                Snug will learn more about you as you use it, so if none of the
                above sounds like you just{' '}
                <a className="pink-color" href="#">
                  akip for now
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Choice
