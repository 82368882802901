import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

import headerImg04 from 'app/assets/icons/header-img-04.png'
import { stateOptions } from 'app/constants/identity-docs.constants'
import isEmptyObject from 'app/shared_components/check_empty_object'
import DatePicker from 'app/shared_components/date_picker'
import getParameter from 'app/shared_components/parameter_parser'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import * as validations from 'app/shared_components/validations'
import { urlTo } from 'app/sm/helpers'

class IdentityDoc extends React.Component {
  constructor(props) {
    super(props)
    let { identityDoc } = props
    this.state = {
      requestID: '',
      firstName: '',
      middleName: '',
      lastName: '',
      docType: null,
      docNumber: '',
      state: '',
      country: 'AUS',
      expiryDate: null,
      dateOfBirth: null,
      disabled: false,
      clicked: false,
      errors: {},
      unsavedChanges: false,
    }
  }

  UNSAFE_componentWillMount() {
    let { fetchRequestSummary, fetchIdentityDoc } = this.props
    let requestID = getParameter('requestid')
    fetchRequestSummary(requestID)
    let guidID = this.guid()
    if (guidID) {
      fetchIdentityDoc(requestID, guidID)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { request, identityDoc, apiError } = nextProps

    if (apiError) {
      this.setState({
        apiError: apiError,
      })
    }

    if (request) {
      let { guidID } = request
      let errors = {}
      this.setState({
        requestID: guidID,
        errors,
        disabled: false,
        clicked: false,
      })
    }

    if (identityDoc && identityDoc.guidID) {
      this.setState({
        firstName: identityDoc.firstName,
        middleName: identityDoc.middleName,
        lastName: identityDoc.lastName,
        docType: identityDoc.docType,
        docNumber: identityDoc.docNumber,
        state: identityDoc.state,
        expiryDate: identityDoc.expiryDate,
        dateOfBirth: identityDoc.dateOfBirth,
      })
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    let { docType } = this.state
    if (docType == 1) {
      nextState.disabled = !(
        nextState.firstName &&
        nextState.lastName &&
        nextState.docNumber &&
        nextState.state &&
        nextState.expiryDate &&
        nextState.dateOfBirth &&
        isEmptyObject(nextState.errors) &&
        !nextState.clicked
      )
    } else {
      nextState.disabled = !(
        nextState.firstName &&
        nextState.lastName &&
        nextState.docNumber &&
        nextState.country &&
        nextState.expiryDate &&
        nextState.dateOfBirth &&
        isEmptyObject(nextState.errors) &&
        !nextState.clicked
      )
    }
  }

  setValidationErrors(field, error) {
    let { errors } = this.state
    if (error.length == 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  form() {
    let {
      firstName,
      middleName,
      lastName,
      docType,
      docNumber,
      state,
      country,
      expiryDate,
      dateOfBirth,
      disabled,
      clicked,
      errors,
      apiError,
      requestID,
    } = this.state
    return (
      <div>
        <div className="row">
          <div className="col-xs-7">
            <div className={errors.firstName ? 'input-box error' : 'input-box'}>
              <input
                type="text"
                required
                id="firstName"
                value={firstName}
                onChange={this.update('firstName')}
                onBlur={this.trimField('firstName')}
              />
              <label>First Name</label>
              {errors.firstName ? (
                <error>Please enter valid First Name.</error>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="col-xs-5">
            <div
              className={errors.middleName ? 'input-box error' : 'input-box'}
            >
              <input
                type="text"
                required
                id="middleName"
                className={errors.middleName ? 'error' : ''}
                value={middleName}
                onChange={this.update('middleName')}
                onBlur={this.trimField('middleName')}
              />
              <label>Middle Name</label>
              {errors.middleName ? (
                <error>Please enter valid Middle Name.</error>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div className={errors.lastName ? 'input-box error' : 'input-box'}>
              <input
                type="text"
                required
                id="lastName"
                className={errors.lastName ? 'error' : ''}
                value={lastName}
                onChange={this.update('lastName')}
                onBlur={this.trimField('lastName')}
              />
              <label>Last Name</label>
              {errors.lastName ? (
                <error>Please enter valid Last Name.</error>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>

        <div className={errors.docNumber ? 'input-box error' : 'input-box'}>
          <input
            type="text"
            required
            id="docNumber"
            value={docNumber}
            onChange={this.update('docNumber')}
            onBlur={this.trimField('docNumber')}
          />
          <label>
            {docType === 1 ? 'Australian Driver Licence No.' : 'Passport No.'}
          </label>
          {errors.docNumber ? (
            <error>
              Please enter valid{' '}
              {docType === 1 ? 'Australian Driver Licence No.' : 'Passport No.'}
            </error>
          ) : (
            ''
          )}
        </div>

        <div>
          {docType === 1 ? (
            <div className="input-box">
              <select
                required
                id="state"
                onChange={this.update('state')}
                value={this.state.state}
              >
                <option className="blank" disabled></option>
                {stateOptions.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <label>Select State</label>
            </div>
          ) : (
            <div className="input-box">
              <select
                required
                id="country"
                onChange={this.update('country')}
                value={this.state.country}
              >
                <option className="blank" disabled></option>
                <option value="AUS">Australia</option>
                <option value="AFG">Afghanistan</option>
                <option value="ALA">Åland Islands</option>
                <option value="ALB">Albania</option>
                <option value="DZA">Algeria</option>
                <option value="ASM">American Samoa</option>
                <option value="AND">Andorra</option>
                <option value="AGO">Angola</option>
                <option value="AIA">Anguilla</option>
                <option value="ATA">Antarctica</option>
                <option value="ATG">Antigua and Barbuda</option>
                <option value="ARG">Argentina</option>
                <option value="ARM">Armenia</option>
                <option value="ABW">Aruba</option>
                <option value="AUT">Austria</option>
                <option value="AZE">Azerbaijan</option>
                <option value="BHS">Bahamas</option>
                <option value="BHR">Bahrain</option>
                <option value="BGD">Bangladesh</option>
                <option value="BRB">Barbados</option>
                <option value="BLR">Belarus</option>
                <option value="BEL">Belgium</option>
                <option value="BLZ">Belize</option>
                <option value="BEN">Benin</option>
                <option value="BMU">Bermuda</option>
                <option value="BTN">Bhutan</option>
                <option value="BOL">Bolivia, Plurinational State of</option>
                <option value="BES">Bonaire, Sint Eustatius and Saba</option>
                <option value="BIH">Bosnia and Herzegovina</option>
                <option value="BWA">Botswana</option>
                <option value="BVT">Bouvet Island</option>
                <option value="BRA">Brazil</option>
                <option value="IOT">British Indian Ocean Territory</option>
                <option value="BRN">Brunei Darussalam</option>
                <option value="BGR">Bulgaria</option>
                <option value="BFA">Burkina Faso</option>
                <option value="BDI">Burundi</option>
                <option value="KHM">Cambodia</option>
                <option value="CMR">Cameroon</option>
                <option value="CAN">Canada</option>
                <option value="CPV">Cape Verde</option>
                <option value="CYM">Cayman Islands</option>
                <option value="CAF">Central African Republic</option>
                <option value="TCD">Chad</option>
                <option value="CHL">Chile</option>
                <option value="CHN">China</option>
                <option value="CXR">Christmas Island</option>
                <option value="CCK">Cocos (Keeling) Islands</option>
                <option value="COL">Colombia</option>
                <option value="COM">Comoros</option>
                <option value="COG">Congo</option>
                <option value="COD">
                  Congo, the Democratic Republic of the
                </option>
                <option value="COK">Cook Islands</option>
                <option value="CRI">Costa Rica</option>
                <option value="CIV">Côte d'Ivoire</option>
                <option value="HRV">Croatia</option>
                <option value="CUB">Cuba</option>
                <option value="CUW">Curaçao</option>
                <option value="CYP">Cyprus</option>
                <option value="CZE">Czech Republic</option>
                <option value="DNK">Denmark</option>
                <option value="DJI">Djibouti</option>
                <option value="DMA">Dominica</option>
                <option value="DOM">Dominican Republic</option>
                <option value="ECU">Ecuador</option>
                <option value="EGY">Egypt</option>
                <option value="SLV">El Salvador</option>
                <option value="GNQ">Equatorial Guinea</option>
                <option value="ERI">Eritrea</option>
                <option value="EST">Estonia</option>
                <option value="ETH">Ethiopia</option>
                <option value="FLK">Falkland Islands (Malvinas)</option>
                <option value="FRO">Faroe Islands</option>
                <option value="FJI">Fiji</option>
                <option value="FIN">Finland</option>
                <option value="FRA">France</option>
                <option value="GUF">French Guiana</option>
                <option value="PYF">French Polynesia</option>
                <option value="ATF">French Southern Territories</option>
                <option value="GAB">Gabon</option>
                <option value="GMB">Gambia</option>
                <option value="GEO">Georgia</option>
                <option value="DEU">Germany</option>
                <option value="GHA">Ghana</option>
                <option value="GIB">Gibraltar</option>
                <option value="GRC">Greece</option>
                <option value="GRL">Greenland</option>
                <option value="GRD">Grenada</option>
                <option value="GLP">Guadeloupe</option>
                <option value="GUM">Guam</option>
                <option value="GTM">Guatemala</option>
                <option value="GGY">Guernsey</option>
                <option value="GIN">Guinea</option>
                <option value="GNB">Guinea-Bissau</option>
                <option value="GUY">Guyana</option>
                <option value="HTI">Haiti</option>
                <option value="HMD">Heard Island and McDonald Islands</option>
                <option value="VAT">Holy See (Vatican City State)</option>
                <option value="HND">Honduras</option>
                <option value="HKG">Hong Kong</option>
                <option value="HUN">Hungary</option>
                <option value="ISL">Iceland</option>
                <option value="IND">India</option>
                <option value="IDN">Indonesia</option>
                <option value="IRN">Iran, Islamic Republic of</option>
                <option value="IRQ">Iraq</option>
                <option value="IRL">Ireland</option>
                <option value="IMN">Isle of Man</option>
                <option value="ISR">Israel</option>
                <option value="ITA">Italy</option>
                <option value="JAM">Jamaica</option>
                <option value="JPN">Japan</option>
                <option value="JEY">Jersey</option>
                <option value="JOR">Jordan</option>
                <option value="KAZ">Kazakhstan</option>
                <option value="KEN">Kenya</option>
                <option value="KIR">Kiribati</option>
                <option value="PRK">
                  Korea, Democratic People's Republic of
                </option>
                <option value="KOR">Korea, Republic of</option>
                <option value="KWT">Kuwait</option>
                <option value="KGZ">Kyrgyzstan</option>
                <option value="LAO">Lao People's Democratic Republic</option>
                <option value="LVA">Latvia</option>
                <option value="LBN">Lebanon</option>
                <option value="LSO">Lesotho</option>
                <option value="LBR">Liberia</option>
                <option value="LBY">Libya</option>
                <option value="LIE">Liechtenstein</option>
                <option value="LTU">Lithuania</option>
                <option value="LUX">Luxembourg</option>
                <option value="MAC">Macao</option>
                <option value="MKD">
                  Macedonia, the former Yugoslav Republic of
                </option>
                <option value="MDG">Madagascar</option>
                <option value="MWI">Malawi</option>
                <option value="MYS">Malaysia</option>
                <option value="MDV">Maldives</option>
                <option value="MLI">Mali</option>
                <option value="MLT">Malta</option>
                <option value="MHL">Marshall Islands</option>
                <option value="MTQ">Martinique</option>
                <option value="MRT">Mauritania</option>
                <option value="MUS">Mauritius</option>
                <option value="MYT">Mayotte</option>
                <option value="MEX">Mexico</option>
                <option value="FSM">Micronesia, Federated States of</option>
                <option value="MDA">Moldova, Republic of</option>
                <option value="MCO">Monaco</option>
                <option value="MNG">Mongolia</option>
                <option value="MNE">Montenegro</option>
                <option value="MSR">Montserrat</option>
                <option value="MAR">Morocco</option>
                <option value="MOZ">Mozambique</option>
                <option value="MMR">Myanmar</option>
                <option value="NAM">Namibia</option>
                <option value="NRU">Nauru</option>
                <option value="NPL">Nepal</option>
                <option value="NLD">Netherlands</option>
                <option value="NCL">New Caledonia</option>
                <option value="NZL">New Zealand</option>
                <option value="NIC">Nicaragua</option>
                <option value="NER">Niger</option>
                <option value="NGA">Nigeria</option>
                <option value="NIU">Niue</option>
                <option value="NFK">Norfolk Island</option>
                <option value="MNP">Northern Mariana Islands</option>
                <option value="NOR">Norway</option>
                <option value="OMN">Oman</option>
                <option value="PAK">Pakistan</option>
                <option value="PLW">Palau</option>
                <option value="PSE">Palestinian Territory, Occupied</option>
                <option value="PAN">Panama</option>
                <option value="PNG">Papua New Guinea</option>
                <option value="PRY">Paraguay</option>
                <option value="PER">Peru</option>
                <option value="PHL">Philippines</option>
                <option value="PCN">Pitcairn</option>
                <option value="POL">Poland</option>
                <option value="PRT">Portugal</option>
                <option value="PRI">Puerto Rico</option>
                <option value="QAT">Qatar</option>
                <option value="REU">Réunion</option>
                <option value="ROU">Romania</option>
                <option value="RUS">Russian Federation</option>
                <option value="RWA">Rwanda</option>
                <option value="BLM">Saint Barthélemy</option>
                <option value="SHN">
                  Saint Helena, Ascension and Tristan da Cunha
                </option>
                <option value="KNA">Saint Kitts and Nevis</option>
                <option value="LCA">Saint Lucia</option>
                <option value="MAF">Saint Martin (French part)</option>
                <option value="SPM">Saint Pierre and Miquelon</option>
                <option value="VCT">Saint Vincent and the Grenadines</option>
                <option value="WSM">Samoa</option>
                <option value="SMR">San Marino</option>
                <option value="STP">Sao Tome and Principe</option>
                <option value="SAU">Saudi Arabia</option>
                <option value="SEN">Senegal</option>
                <option value="SRB">Serbia</option>
                <option value="SYC">Seychelles</option>
                <option value="SLE">Sierra Leone</option>
                <option value="SGP">Singapore</option>
                <option value="SXM">Sint Maarten (Dutch part)</option>
                <option value="SVK">Slovakia</option>
                <option value="SVN">Slovenia</option>
                <option value="SLB">Solomon Islands</option>
                <option value="SOM">Somalia</option>
                <option value="ZAF">South Africa</option>
                <option value="SGS">
                  South Georgia and the South Sandwich Islands
                </option>
                <option value="SSD">South Sudan</option>
                <option value="ESP">Spain</option>
                <option value="LKA">Sri Lanka</option>
                <option value="SDN">Sudan</option>
                <option value="SUR">Suriname</option>
                <option value="SJM">Svalbard and Jan Mayen</option>
                <option value="SWZ">Swaziland</option>
                <option value="SWE">Sweden</option>
                <option value="CHE">Switzerland</option>
                <option value="SYR">Syrian Arab Republic</option>
                <option value="TWN">Taiwan, Province of China</option>
                <option value="TJK">Tajikistan</option>
                <option value="TZA">Tanzania, United Republic of</option>
                <option value="THA">Thailand</option>
                <option value="TLS">Timor-Leste</option>
                <option value="TGO">Togo</option>
                <option value="TKL">Tokelau</option>
                <option value="TON">Tonga</option>
                <option value="TTO">Trinidad and Tobago</option>
                <option value="TUN">Tunisia</option>
                <option value="TUR">Turkey</option>
                <option value="TKM">Turkmenistan</option>
                <option value="TCA">Turks and Caicos Islands</option>
                <option value="TUV">Tuvalu</option>
                <option value="UGA">Uganda</option>
                <option value="UKR">Ukraine</option>
                <option value="ARE">United Arab Emirates</option>
                <option value="GBR">United Kingdom</option>
                <option value="USA">United States</option>
                <option value="UMI">
                  United States Minor Outlying Islands
                </option>
                <option value="URY">Uruguay</option>
                <option value="UZB">Uzbekistan</option>
                <option value="VUT">Vanuatu</option>
                <option value="VEN">Venezuela, Bolivarian Republic of</option>
                <option value="VNM">Viet Nam</option>
                <option value="VGB">Virgin Islands, British</option>
                <option value="VIR">Virgin Islands, U.S.</option>
                <option value="WLF">Wallis and Futuna</option>
                <option value="ESH">Western Sahara</option>
                <option value="YEM">Yemen</option>
                <option value="ZMB">Zambia</option>
                <option value="ZWE">Zimbabwe</option>
              </select>
              <label>Country of Issue</label>
            </div>
          )}
        </div>

        <div className="input-box">
          <DatePicker
            label="Expiry Date"
            viewMode="years"
            value={expiryDate ? moment(expiryDate).format('DD/MM/YYYY') : ''}
            onChange={this.updateDate('expiryDate')}
          />
        </div>

        <div className="input-box">
          <DatePicker
            label="Date of Birth"
            viewMode="years"
            value={dateOfBirth ? moment(dateOfBirth).format('DD/MM/YYYY') : ''}
            onChange={this.updateDate('dateOfBirth')}
          />
        </div>

        <div
          className={
            errors.docNumber ||
            errors.state ||
            errors.country ||
            errors.expiryDate ||
            errors.dateOfBirth ||
            apiError
              ? 'alert alert-danger'
              : ''
          }
        >
          {errors.docNumber ? <div>{errors.docNumber}</div> : ''}
          {errors.state ? <div>{errors.state}</div> : ''}
          {errors.country ? <div>{errors.country}</div> : ''}
          {errors.expiryDate ? <div>{errors.expiryDate}</div> : ''}
          {errors.dateOfBirth ? <div>{errors.dateOfBirth}</div> : ''}
          {apiError ? <div>{apiError}</div> : ''}
        </div>

        <div className="row pt25 mobile-form-button">
          <div className="col-sm-5 pb5 tablet-only">
            <Link
              to={urlTo('bondCoverApplyProgress', { requestID })}
              className="btn btn-transparent btn-left xs-text-center wa"
            >
              <i className="icon-arrow-left left"></i>
              <span>Back</span>
            </Link>
          </div>
          <div className="col-sm-7 pb5">
            <button className="btn" onClick={this.submit}>
              Save
              <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
            </button>
          </div>
        </div>
      </div>
    )
  }

  guid() {
    let search = getParameter('id')
    return search
  }

  label(docType) {
    switch (docType) {
      case 1:
        return 'Australian Driver Licence'
      case 2:
        return 'Australian Passport No.'
    }
  }

  submit = () => {
    let { identityDoc, createIdentityDoc, updateIdentityDoc } = this.props
    let {
      requestID,
      firstName,
      middleName,
      lastName,
      docNumber,
      docType,
      state,
      country,
      expiryDate,
      dateOfBirth,
    } = this.state
    let isPrimary = true
    if (this.validateAll()) {
      this.setState({ clicked: true, unsavedChanges: false })
      let formDetails = {
        requestID: requestID,
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        docNumber: docNumber,
        docType: docType,
        state: state,
        country: country,
        expiryDate: expiryDate,
        dateOfBirth: dateOfBirth,
      }
      if (identityDoc && identityDoc.expiryDate) {
        updateIdentityDoc(requestID, formDetails, identityDoc.guidID, isPrimary)
      } else {
        createIdentityDoc(requestID, formDetails, isPrimary)
      }
    }
  }

  trimField(field) {
    return (event) => {
      let value = this.state[field].trim()
      this.validate(field, value)()
      this.setState({ [field]: value })
    }
  }

  update(field) {
    return (event) => {
      let { value } = event.target
      this.setState({ [field]: value, unsavedChanges: true })
    }
  }

  updateDate(field) {
    return (date) => {
      let value = moment(date).format('YYYY-MM-DD')
      this.setState(
        { [field]: value, unsavedChanges: true },
        this.validate(field, value),
      )
    }
  }

  updateNumber(field) {
    return (event) =>
      this.setState({
        [field]: parseInt(event.target.value),
        unsavedChanges: true,
      })
  }

  validate = (field, value) => {
    return (event) => {
      switch (field) {
        case 'firstName':
          return this.setValidationErrors(
            field,
            validations.validateName(value),
          )
        case 'middleName':
          return this.setValidationErrors(
            field,
            validations.validateNameOptional(value),
          )
        case 'lastName':
          return this.setValidationErrors(
            field,
            validations.validateName(value),
          )
        case 'docNumber':
          if (this.state.docType == 1) {
            return this.setValidationErrors(
              field,
              validations.validateLicence(value),
            )
          } else {
            return this.setValidationErrors(
              field,
              validations.validatePassport(value, this.state.country),
            )
          }
        case 'state':
          if (value == '') {
            return this.setValidationErrors(field, 'Invalid State')
          } else {
            return true
          }
        case 'country':
          return this.setValidationErrors(
            field,
            validations.validateCountry(value),
          )
        // Refactor this at the nearest opportunity
        case 'expiryDate':
          return this.setValidationErrors(
            field,
            validations.validateExpiryDate(
              moment().format('YYYY-MM-DD'),
              value,
              'Expiry Date is required',
              'Expiry Date must be in the future',
            ),
          )
        // Refactor this at the nearest opportunity
        case 'dateOfBirth':
          return this.setValidationErrors(
            field,
            validations.compareDates(
              value,
              moment().subtract(18, 'years').format('YYYY-MM-DD'),
              'Date of Birth is required',
              'Please contact us directly if you are under 18 years of age',
            ),
          )
      }
    }
  }

  validateAll() {
    let { docType } = this.state
    let valid = true
    let fields = ['docNumber', 'state', 'expiryDate', 'dateOfBirth']

    if (docType == 2) {
      fields = ['docNumber', 'country', 'expiryDate', 'dateOfBirth']
    }

    fields.forEach((field) => {
      if (!this.validate(field, this.state[field])) {
        valid = false
      }
    })

    return valid
  }

  render() {
    let { docType, unsavedChanges, requestID } = this.state
    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <div className="mobile-only">
            <Link
              to={urlTo('bondCoverApplyProgress', { requestID })}
              className="btn btn-transparent btn-left xs-text-center wa"
            >
              <i className="icon-arrow-left left"></i>
              <span>Back</span>
            </Link>
          </div>
          <div className="ilustration-box">
            <img src={headerImg04} />
          </div>

          <h3 className="mb15">Identity Check</h3>
          <p>
            Snug needs a current <b>Australian Driver Licence or passport</b> to
            verify your identity.
          </p>

          <ul className="snug-infos">
            <li>Snug uses bank-level 256-bit security to protect your data.</li>
          </ul>

          <p>
            {docType
              ? ''
              : 'Please select valid identity document to continue:'}
          </p>

          <div className="snug-tabs">
            <label>
              <input
                type="radio"
                name="docType"
                value={1}
                checked={docType === 1}
                onChange={this.updateNumber('docType')}
              />
              <span>Driver Licence</span>
            </label>
            <label>
              <input
                type="radio"
                name="docType"
                value={2}
                checked={docType === 2}
                onChange={this.updateNumber('docType')}
              />
              <span>Passport</span>
            </label>
          </div>

          {docType ? this.form() : ''}

          <UnsavedMessage unsavedChanges={unsavedChanges} />
        </div>
      </div>
    )
  }
}

export default IdentityDoc
