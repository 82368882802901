import { MailToLink } from 'app/components/design-system-components/links/MailTo'
import { Text } from 'app/components/design-system-components/typography'

export const pmsStatuses = {
  // color is used as StatusPill component variant
  active: {
    id: 'active',
    label: 'Active',
    color: 'green',
  },
  inactive: {
    id: 'inactive',
    label: 'Inactive',
    color: 'grey',
  },
}

const propertyTreeActivationInstructions = (
  <Text as="span">
    Please contact{' '}
    <MailToLink email="help@snug.com" subject="Activate Property Tree">
      help@snug.com
    </MailToLink>{' '}
    to activate.
  </Text>
)

const kolmeoActivationInstructions = (
  <Text as="span">
    Please contact{' '}
    <MailToLink
      email="help@kolmeo.com"
      subject="Kolmeo activation key for Snug"
    >
      help@kolmeo.com
    </MailToLink>{' '}
    for an activation key.
  </Text>
)

const managedAppActivationInstructions = (
  <Text as="span">
    Please contact{' '}
    <MailToLink
      email="help@managedapp.com.au"
      subject="ManagedApp activation key for Snug"
    >
      help@managedapp.com.au
    </MailToLink>{' '}
    for an activation key.
  </Text>
)

export const SendApplicantIsRequiredTxt =
  'Enable applicant onboarding in My Team ➝ Settings ➝ PropertyMe'
export const sendApplicantPMSSetting = 'pms_applicant_onboarding'
export const syncTenantPMSSetting = 'pms_tenant_sync'
export const syncOwnersPMSSetting = 'pms_owner_sync'
export const syncManagersPMSSetting = 'pms_property_manager_sync'
export const syncKeysPMSSetting = 'pms_key_sync'

export const kolmeoPMSId = 'Kolmeo.com'
export const ManagedAppId = 'ManagedApp.com.au'

export const pmsBrands = {
  propertyTree: {
    id: 'PropertyTree.com',
    label: 'Property Tree',
    activationInstructions: propertyTreeActivationInstructions,
    manualActivationAllowed: true,
    manualDeactivationAllowed: false,
    applicationConfig: 'sendApplicationToPT',
    offerConfig: 'include_send_to_property_tree',
    settings: [
      `${syncTenantPMSSetting}.PropertyTree.com`,
      `${syncOwnersPMSSetting}.PropertyTree.com`,
      `${sendApplicantPMSSetting}.PropertyTree.com`,
      `${syncManagersPMSSetting}.PropertyTree.com`,
      `${syncKeysPMSSetting}.PropertyTree.com`,
    ],
  },
  vaultRE: {
    id: 'VaultRE.com.au',
    label: 'VaultRE',
    activationInstructions: null,
    manualActivationAllowed: true,
    manualDeactivationAllowed: true,
    settings: [],
  },
  rwConciergeVaultRE: {
    id: 'pms-rwconcierge-vaultre',
    label: '',
    activationInstructions: null,
    manualActivationAllowed: false,
    manualDeactivationAllowed: false,
    settings: [],
  },
  propertyMe: {
    id: 'PropertyMe.com',
    label: 'PropertyMe',
    activationInstructions: 'Activate via your PropertyMe credentials',
    manualActivationAllowed: true,
    manualDeactivationAllowed: true,
    applicationConfig: 'sendApplicationToPme',
    offerConfig: 'include_send_to_pme',
    settings: [
      `${syncTenantPMSSetting}.PropertyMe.com`,
      `${syncOwnersPMSSetting}.PropertyMe.com`,
      `${sendApplicantPMSSetting}.PropertyMe.com`,
      `${syncManagersPMSSetting}.PropertyMe.com`,
      `${syncKeysPMSSetting}.PropertyMe.com`,
    ],
  },
  kolmeo: {
    id: kolmeoPMSId,
    label: 'Kolmeo',
    activationInstructions: kolmeoActivationInstructions,
    manualActivationAllowed: true,
    manualDeactivationAllowed: true,
    applicationConfig: 'sendApplicationToKolmeo',
    offerConfig: 'include_send_to_kolmeo',
    settings: [],
  },
  managedApp: {
    id: ManagedAppId,
    label: 'Managed App',
    activationInstructions: managedAppActivationInstructions,
    manualActivationAllowed: true,
    manualDeactivationAllowed: true,
    applicationConfig: 'sendApplicationToManagedApp',
    offerConfig: 'include_send_to_managed_app',
    settings: [],
  },
}

export const consoleCloudId = 'ConsoleCloud'

export const consoleCloudSettings = []

export const pmsActivityTypes = {
  activate: {
    id: 'activate',
    label: 'Activate',
  },
  deactivate: {
    id: 'deactivate',
    label: 'Deactivate',
  },
  pullData: {
    id: 'pull-data',
    label: 'Pull Data',
  },
  notifyApplication: {
    id: 'notify-application',
    label: 'Notify Application',
  },
  sendUtilityReferral: {
    id: 'send-utility-referral',
    label: 'Send Utility Referral',
  },
  notifyViewing: {
    id: 'notify-viewing',
    label: 'Notify Viewing',
  },
  processProperty: {
    id: 'process-property',
    label: 'Process Property',
  },
}

export const pmsActivityStatuses = {
  success: {
    id: 'success',
    label: 'Success',
    color: 'green-hollow',
  },
  warning: {
    id: 'warning',
    label: 'Warning',
    color: 'yellow-hollow',
  },
  failed: {
    id: 'failed',
    label: 'Failed',
    color: 'red-hollow',
  },
}

export const ignoreActivePMS = (pms) => {
  switch (pms) {
    case pmsBrands.vaultRE.id:
      return true
    case pmsBrands.rwConciergeVaultRE.id:
      return true
    default:
      return false
  }
}

export const getActiveIntegrationsSendingTenancyOnboard = (pmsStatusList) =>
  pmsStatusList &&
  pmsStatusList.filter(
    ({ status, sending_tenancy_onboarding = false }) =>
      status === pmsStatuses.active.id && sending_tenancy_onboarding,
  )

export const getPMSBrand = (pmsId) =>
  Object.values(pmsBrands).find(({ id }) => pmsId === id)
