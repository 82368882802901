import { createContext, useEffect, useMemo, useRef, useState } from 'react'

import qs from 'qs'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  FIELD_MOBILE,
  STEP_ENTER_DETAILS,
  STEP_ENTER_EMAIL,
  STEP_ENTER_MOBILE,
  STEP_UPDATE_REGISTRATION_INFO,
} from 'app/session/join/join-snug.constants'
import { checkForAgencyRules } from 'app/sm/property_details/property_details_actions'
import { RegisterAgencyIDKey, SessionStorageUtils } from 'app/storage_utils'

const getDocumentPath = () => {
  return document.location.pathname
}

const initialContext = {
  currentStep: '',
  setCurrentStep: (s) => {},

  accountCheckedKey: {
    name: '',
    value: '',
  },
  setAccountCheckKey: (k) => {},

  gCaptcha: '',
  setGCaptcha: (c) => {},

  error: null,
  setError: (e) => {},

  prefillDetails: {},
  setPrefillDetails: (d) => {},

  registerToken: '',
  setRegisterToken: () => {},

  internationalMobilesAllowed: false,
  setInternationalMobilesAllowed: () => {},

  stepBack: () => {},
  showStepBack: false,

  gCaptchaRef: null,
}

const JoinSnugContext = createContext(initialContext)

const ContextProvider = ({ children, user, systemError, getAgencyRules }) => {
  const history = useHistory()

  const [currentStep, setCurrentStep] = useState(STEP_ENTER_EMAIL)
  const [gCaptcha, setGCaptcha] = useState('')
  const [error, setError] = useState(null)
  const [prefillDetails, setPrefillDetails] = useState({})
  const [registerToken, setRegisterToken] = useState('')
  const [internationalMobilesAllowed, setInternationalMobilesAllowed] =
    useState(false)

  useEffect(() => {
    setError(systemError)
  }, [systemError])

  // accountCheckedKey determines whether continued with mobile or email
  const [accountCheckedKey, setAccountCheckedKey] = useState({
    name: '',
    value: '',
  })

  const gCaptchaRef = useRef()

  useEffect(() => {
    const _registerToken = parseToken()
    if (!_registerToken) return

    setRegisterToken(_registerToken)

    const registerForAgency = SessionStorageUtils.getItem(RegisterAgencyIDKey)
    if (registerForAgency) {
      getAgencyRules(registerForAgency).then(
        ({ internationalMobilesAllowed: _internationalMobilesAllowed }) =>
          setInternationalMobilesAllowed(_internationalMobilesAllowed),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const forwardURL = SessionStorageUtils.getItem('forwardUrl')
    if (forwardURL) {
      const parsedForwardURL = qs.parse(forwardURL, { ignoreQueryPrefix: true })

      if (
        Object.keys(parsedForwardURL).length > 0 &&
        'invitefirstname' in parsedForwardURL
      ) {
        setPrefillDetails({
          firstName: parsedForwardURL.invitefirstname,
          lastName: parsedForwardURL.invitelastname,
          email: !!parsedForwardURL.inviteemail
            ? parsedForwardURL.inviteemail.trim().replace(' ', '+')
            : '',
          mobile: parsedForwardURL.invitemobile,
        })

        setCurrentStep(STEP_ENTER_EMAIL)
      }
    }

    if (user && getDocumentPath().includes('update-registration')) {
      setPrefillDetails({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobile: user.mobile,
      })

      setCurrentStep(STEP_UPDATE_REGISTRATION_INFO)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const parseToken = () => {
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    return curQueries && curQueries.token
  }

  const isCurrentStepInitial = () => {
    return [STEP_ENTER_MOBILE, STEP_ENTER_EMAIL].includes(currentStep)
  }

  const stepBack = () => {
    if (getDocumentPath() === '/join' && !isCurrentStepInitial()) {
      if (!accountCheckedKey.name || accountCheckedKey.name === FIELD_MOBILE) {
        setCurrentStep(STEP_ENTER_MOBILE)
      } else {
        setCurrentStep(STEP_ENTER_EMAIL)
      }
    } else {
      history.goBack()
    }
  }

  const isNotInitialStep = !isCurrentStepInitial()
  const contextRef = useMemo(
    () => ({
      currentStep,
      setCurrentStep,

      gCaptcha,
      setGCaptcha,

      accountCheckedKey,
      setAccountCheckedKey,

      error,
      setError,

      prefillDetails,
      setPrefillDetails,

      registerToken,
      setRegisterToken,

      internationalMobilesAllowed,
      setInternationalMobilesAllowed,

      stepBack,
      showStepBack: isNotInitialStep,

      gCaptchaRef,
    }),
    [
      currentStep,

      gCaptcha,

      accountCheckedKey,

      error,

      prefillDetails,

      registerToken,

      internationalMobilesAllowed,

      stepBack,
      isNotInitialStep,

      gCaptchaRef,
    ],
  )

  return (
    <JoinSnugContext.Provider value={contextRef}>
      {children}
    </JoinSnugContext.Provider>
  )
}

const mapStateToProps = ({ session }) => ({
  user: session.currentUser,
  systemError: session.error,
})

const mapDispatchToProps = (dispatch) => ({
  getAgencyRules: (agencyGUID) => dispatch(checkForAgencyRules(agencyGUID)),
})

const JoinSnugContextProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContextProvider)

export { JoinSnugContext, JoinSnugContextProvider }
