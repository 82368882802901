import { connect } from 'react-redux'

import {
  addCurrentLease,
  fetchCurrentLease,
  updateCurrentLease,
} from 'app/bond_cover/bond_cover_actions'
import RentalFormContainer from 'app/bond_cover/renters/request/rental_form_container'

const mapStateToProps = ({ bondCover, session }) => ({
  apiError: bondCover.error,
  currentUser: session.currentUser,
  bondCoverRequest: bondCover.request,
  currentLease: bondCover.currentLease,
  clicked: bondCover.clicked,
})

const mapDispatchToProps = () => ({
  addCurrentLease: (currentLease) => addCurrentLease(currentLease),
  fetchCurrentLease: (requestID, id) => fetchCurrentLease(requestID, id),
  updateCurrentLease: (requestID, currentLease, id) => {
    updateCurrentLease(requestID, currentLease, id)
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(RentalFormContainer)
