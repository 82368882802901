import { LOCATION_CHANGE } from 'connected-react-router'

import { InvalidCredentials } from 'app/constants/error_codes'
import {
  CHANGE_CURRENT_TEAM,
  RECEIVE_AUTH_ERROR,
  RECEIVE_CURRENT_AGENCY,
  RECEIVE_CURRENT_USER,
  RECEIVE_CURRENT_USER_PROFILE,
  RECEIVE_SESSION_ERROR,
  RECEIVE_SUCCESS_MESSAGE,
  RECEIVE_TEAMS,
  RECEIVE_USER_ERROR,
  TOGGLE_ROLE_PROMPT,
  UPDATE_RENTAL_REFERENCES_COUNT,
  UPDATE_SETUP_WIZARD_INVALID_STATUS,
} from 'app/session/session_actions'

const _nullUser = Object.freeze({
  currentUser: { isUserFetching: true, isUserProfileFetching: true },
  error: null,
  success: null,
  attempts: 0,
  currentAgency: null,
  currentAgencyLoaded: false,
  rolePromptOn: false,
  teams: [],
  teamsLoaded: false,
  currentTeam: null,
  rentalReferencesCount: 0,
  isQuickStartWizardForTeamInvalid: true,
})

const SessionReducer = (state = _nullUser, action) => {
  let { type, currentUser, error, success, currentAgency, teams, currentTeam } =
    action
  Object.freeze(state)
  switch (type) {
    case RECEIVE_CURRENT_USER:
      if (currentUser) {
        currentUser.isUserFetching = false
      }
      return Object.assign(
        {},
        state,
        { currentUser: Object.assign({}, state.currentUser, currentUser) },
        { error },
      )
    case RECEIVE_CURRENT_USER_PROFILE:
      if (currentUser) {
        currentUser.isUserProfileFetching = false
      }
      return Object.assign(
        {},
        state,
        {
          currentUser: Object.assign({}, state.currentUser, currentUser),
        },
        { error },
      )
    case RECEIVE_AUTH_ERROR:
      error = error === InvalidCredentials ? 'Wrong email or password.' : error
      return {
        ...state,
        error,
        attempts: state.attempts + 1,
        currentUser: {
          ...state.currentUser,
          ...currentUser,
        },
      }
    case RECEIVE_USER_ERROR:
      return Object.assign({}, _nullUser, { currentUser, error })
    case RECEIVE_SUCCESS_MESSAGE:
      return Object.assign({}, _nullUser, { success, error })
    case RECEIVE_SESSION_ERROR:
      return Object.assign({}, state, { error })
    case RECEIVE_CURRENT_AGENCY: {
      return {
        ...state,
        currentAgency,
        currentAgencyLoaded: true,
      }
    }
    case TOGGLE_ROLE_PROMPT:
      return Object.assign({}, state, { rolePromptOn: !state.rolePromptOn })
    case RECEIVE_TEAMS:
      return {
        ...state,
        teams,
        teamsLoaded: true,
      }
    case CHANGE_CURRENT_TEAM:
      return Object.assign({}, state, { currentTeam })
    case LOCATION_CHANGE:
      error = ''
      return Object.assign({}, state, { error })
    case UPDATE_RENTAL_REFERENCES_COUNT: {
      const { count } = action
      return {
        ...state,
        rentalReferencesCount: count,
      }
    }
    case UPDATE_SETUP_WIZARD_INVALID_STATUS: {
      const { isInvalid } = action
      return {
        ...state,
        isQuickStartWizardForTeamInvalid: isInvalid,
      }
    }
    default:
      return state
  }
}

export default SessionReducer
