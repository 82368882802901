import humanIcon01 from 'app/assets/icons/human01.jpg'
import humanIcon02 from 'app/assets/icons/human02.jpg'
import humanIcon03 from 'app/assets/icons/human03.jpg'

export const Inbox = [
  {
    recordStatus: 'new',
    users: [
      { avatar: humanIcon02, firstName: 'Robbie', lastName: 'Thomas' },
      { avatar: humanIcon03, firstName: 'Andrew', lastName: 'Smith' },
    ],
    userStatus: 'Renters',
    address: '50 Bond St.',
    title: 'We need the lease documents',
    lastMessage:
      'Hi there Justin, we would like to get our lease documents ...',
    time: '20 min ago',
    unreadMessages: '2',
  },
  {
    recordStatus: 'new marked',
    users: [{ avatar: humanIcon01, firstName: 'Rupert', lastName: 'Murdoch' }],
    userStatus: 'Renter',
    address: '124 Harrington Av.',
    title: 'Maintenece Request',
    lastMessage: 'Hi there Justin, where can i submit a maintenece request?',
    time: '2 days ago',
    unreadMessages: '4',
  },
  {
    recordStatus: '',
    users: [{ avatar: humanIcon03, firstName: 'James', lastName: 'Toney' }],
    userStatus: 'Service Provider',
    address: '',
    title: 'We need the lease documents',
    lastMessage:
      'Hi there Justin, we would like to get our lease documents ...',
    time: '6 days ago',
    unreadMessages: '',
  },
  {
    recordStatus: 'marked',
    users: [
      { avatar: humanIcon01, firstName: 'James', lastName: 'Toney' },
      { avatar: humanIcon02, firstName: 'Robbie', lastName: 'Thomas' },
      { avatar: humanIcon03, firstName: 'Andrew', lastName: 'Smith' },
      { avatar: '', firstName: 'Justin', lastName: 'Butterworth' },
    ],
    userStatus: 'Service Provider',
    address: '',
    title: 'We need the lease documents',
    lastMessage:
      'Hi there Justin, we would like to get our lease documents ...',
    time: '12 days ago',
    unreadMessages: '',
  },
  {
    recordStatus: '',
    users: [{ avatar: humanIcon02, firstName: 'Samantha', lastName: 'Perry' }],
    userStatus: 'Renter',
    address: '4 Cobblestone Rd.',
    title: 'Late with my rent',
    lastMessage: 'Hi there Justin, where can i submit a maintenece request?',
    time: '24 days ago',
    unreadMessages: '',
  },
]

export const PropertyInbox = [
  {
    recordStatus: 'new',
    users: [
      { avatar: humanIcon02, firstName: 'Robbie', lastName: 'Thomas' },
      { avatar: humanIcon03, firstName: 'Andrew', lastName: 'Smith' },
    ],
    userStatus: 'Renters',
    address: '50 Bond St.',
    title: 'We need the lease documents',
    lastMessage:
      'Hi there Justin, we would like to get our lease documents ...',
    time: '20 min ago',
    unreadMessages: '2',
  },
  {
    recordStatus: 'new marked',
    users: [{ avatar: humanIcon02, firstName: 'Robbie', lastName: 'Thomas' }],
    userStatus: 'Renter',
    address: '50 Bond St.',
    title: 'Maintenece Request',
    lastMessage: 'Hi there Justin, where can i submit a maintenece request?',
    time: '2 days ago',
    unreadMessages: '4',
  },
  {
    recordStatus: '',
    users: [{ avatar: humanIcon01, firstName: 'James', lastName: 'Toney' }],
    userStatus: 'Service Provider',
    address: '',
    title: 'We need the lease documents',
    lastMessage:
      'Hi there Justin, we would like to get our lease documents ...',
    time: '6 days ago',
    unreadMessages: '',
  },
]
