import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  alterEquiryViewingRegistrant,
  getEnquirerPreference,
  getPropertyInfoByViewingId,
  getRegistrantInfoFromShortcode,
  postEnquirerPreference,
  postViewingRegistrationFromEmail,
  postViewingRegistrationFromMessage,
  postViewingRegistrationFromSMSMessage,
  unregisterEnquiryViewing,
  updateViewingAttendeeConfirmation,
} from 'app/match/viewings/enquiry_viewing_register/enquiry_viewing_register_access'

const fetchEnquirerPreference = (teamSlug, payload) => () => {
  return getEnquirerPreference(teamSlug, payload).then(
    ({ data, ok, responseText }) => {
      if (ok) {
        return Promise.resolve({ data })
      } else {
        return responseText.then((error) => {
          return Promise.reject(translateErrorCodeToMessage(error))
        })
      }
    },
  )
}

const registerForEnquiryViewing =
  (viewingID, messageID, registrationData) => () => {
    return postViewingRegistrationFromMessage(
      viewingID,
      messageID,
      registrationData,
    ).then(({ data, ok, responseText }) => {
      if (ok) {
        return Promise.resolve({ data })
      }
      return responseText.then((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
    })
  }

const registerForEnquirySMSViewing =
  (viewingID, messageID, registrationData) => () => {
    return postViewingRegistrationFromSMSMessage(
      viewingID,
      messageID,
      registrationData,
    ).then(({ data, ok, responseText }) => {
      if (ok) {
        return Promise.resolve({ data })
      }
      return responseText.then((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
    })
  }

const updateEnquirerPreference = (messageId, teamSlug, payload) => () => {
  return postEnquirerPreference(messageId, teamSlug, payload).then(
    ({ data, ok, responseText }) => {
      if (ok) {
        return Promise.resolve({ data })
      }
      return responseText.then((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
    },
  )
}

const registerForEmailViewing = (viewingID, payload) => () => {
  return postViewingRegistrationFromEmail(viewingID, payload).then(
    ({ data, ok, responseText }) => {
      if (ok) {
        return Promise.resolve({ data })
      }
      return responseText.then((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
    },
  )
}

const updateEquiryViewingRegistrant = (data, registrantID) => () => {
  return alterEquiryViewingRegistrant(data, registrantID).then(
    ({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      }
      return responseText.then((error) => {
        Promise.reject(translateErrorCodeToMessage(error))
      })
    },
  )
}

export const fetchRegistrantInfoFromShortcode = (shortcode) => () => {
  return getRegistrantInfoFromShortcode(shortcode).then(
    ({ data, ok, responseText }) => {
      if (ok) {
        return Promise.resolve({ data })
      }
      return responseText.then((error) => {
        Promise.reject(translateErrorCodeToMessage(error))
      })
    },
  )
}

const unregisterForEnquiryViewing = (registrantID) => () => {
  return unregisterEnquiryViewing(registrantID).then(({ ok, responseText }) => {
    if (ok) {
      return Promise.resolve()
    }
    return responseText.then((error) => {
      Promise.reject(translateErrorCodeToMessage(error))
    })
  })
}

const fetchPropertyInfoByViewingId = (viewingId) => () => {
  return getPropertyInfoByViewingId(viewingId).then(
    ({ data, ok, responseText }) => {
      if (ok) {
        return Promise.resolve({ data })
      }
      return responseText.then((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
    },
  )
}

const viewingAttendeeConfirmation = (payload, viewingID, attendeeID) => () => {
  return updateViewingAttendeeConfirmation(payload, viewingID, attendeeID).then(
    ({ data, ok, responseText }) => {
      if (ok) {
        return Promise.resolve({ data })
      }
      return responseText.then((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
    },
  )
}
export {
  fetchEnquirerPreference,
  fetchPropertyInfoByViewingId,
  registerForEmailViewing,
  registerForEnquirySMSViewing,
  registerForEnquiryViewing,
  unregisterForEnquiryViewing,
  updateEnquirerPreference,
  updateEquiryViewingRegistrant,
  viewingAttendeeConfirmation,
}
