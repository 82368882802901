import React from 'react'

import { Link } from 'react-router-dom'

import MultiTenantErrors from 'app/shared_components/multi_tenant_errors'
import TenantWidget from 'app/shared_components/tenant_widget'

const Tenants = ({ request, currentLease, tenants }) => {
  let tenantsHtml = null

  let { guidID } = request ? request : ''
  let { isNewLease } = currentLease ? currentLease : ''

  if (tenants && tenants.length) {
    tenantsHtml = tenants.map((i) => {
      return (
        <TenantWidget
          isNewLease={isNewLease}
          currentLease={currentLease}
          tenant={i}
          key={i.guidID}
        />
      )
    })
  }
  let multiTenantErrors = MultiTenantErrors(currentLease, tenants, isNewLease)
  return (
    <div>
      {request && request.submissionStatus === 0 ? (
        <div
          className={
            tenantsHtml && !multiTenantErrors
              ? 'bc-hub-item-link completed'
              : 'bc-hub-item-link more-info'
          }
        >
          <Link
            className="tenant-widget"
            to={'/bc/tenants?requestid=' + guidID}
          >
            <header>
              <i className="icon-tenants" />
              <span>3. Tenants</span>
            </header>
            <content>{tenantsHtml}</content>
          </Link>
          <div>
            {multiTenantErrors && (
              <status>
                {multiTenantErrors === 'Tenants' ? (
                  <span>
                    Add Secondary Tenant {!isNewLease && 'and Bond Split'}{' '}
                    Information
                  </span>
                ) : (
                  <span>
                    You have not let us know how the current bond is split.{' '}
                    <Link to={'bc/bondsplits?requestid=' + request.guidID}>
                      Please Update
                    </Link>
                  </span>
                )}
              </status>
            )}
          </div>
        </div>
      ) : (
        <div className="bc-hub-item-link completed">
          <header>
            <i className="icon-tenants" />
            <span>3. Tenants</span>
          </header>
          <content>{tenantsHtml}</content>
        </div>
      )}
    </div>
  )
}

export default Tenants
