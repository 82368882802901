import React from 'react'

import { Alert, Box, TextSpan } from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { cancelKeySetReservation } from 'app/services/http/teams/key-logger'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

const renderCancelReservationKeysetModalFooter = (
  cancelReservation,
  onClose,
  isSubmitting,
) => {
  return (
    <GenericModalFooter
      primaryBtnConfig={{
        title: 'Cancel Reservation',
        onClick: cancelReservation,
        props: {
          loading: !!isSubmitting,
          type: 'submit',
        },
      }}
      secondaryBtnConfig={{
        title: 'Cancel',
        onClick: onClose,
      }}
    />
  )
}

export const CancelReservationKeySetModal = ({
  teamId,
  keySet,
  reservation,
  closeModal,
}) => {
  const {
    loadingStates: cancelReservationLoadingStates,
    loadingStatesHelpers: cancelReservationLoadingHelpers,
  } = useLoadingStates()

  const onCancelReservation = () => {
    cancelReservationLoadingHelpers.startLoading()
    return cancelKeySetReservation(teamId, reservation.id)
      .then((response) => {
        cancelReservationLoadingHelpers.markDoneSuccessfully()
        closeModal(response)
      })
      .catch(({ message }) => {
        cancelReservationLoadingHelpers.setError(message)
      })
  }

  return (
    <GenericModal
      onDismiss={() => closeModal()}
      title={`Cancel reservation`}
      modalFooter={renderCancelReservationKeysetModalFooter(
        () => onCancelReservation(),
        () => closeModal(),
        cancelReservationLoadingStates.state === loadingStatesIds.LOADING,
      )}
    >
      {!!cancelReservationLoadingStates.error && (
        <Alert variant="primaryWithBg" mb={4}>
          {cancelReservationLoadingStates.error}
        </Alert>
      )}
      <Box mb={8}>
        <Box mb={5}>Cancel key reservation for:</Box>
        <Box mb={3}>
          <TextSpan as="span" fontWeight={theme.fontWeights.h5}>
            Key:
          </TextSpan>{' '}
          {keySet?.label}
        </Box>
        <Box mb={3}>
          <TextSpan as="span" fontWeight={theme.fontWeights.h5}>
            Address:
          </TextSpan>{' '}
          {keySet?.property?.address?.friendlyName}
        </Box>
      </Box>
    </GenericModal>
  )
}
