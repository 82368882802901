import * as React from 'react'

function SvgKeyboardArrowDownRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.125 9l3.88 3.88L15.885 9a.996.996 0 111.41 1.41L12.705 15a.996.996 0 01-1.41 0l-4.59-4.59a.996.996 0 010-1.41c.39-.38 1.03-.39 1.42 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgKeyboardArrowDownRounded
