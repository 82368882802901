import * as React from 'react'

function SvgDoneRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.8 15.905l-3.5-3.5a.984.984 0 00-1.4 0 .984.984 0 000 1.4l4.19 4.19c.39.39 1.02.39 1.41 0l10.6-10.59a.984.984 0 000-1.4.984.984 0 00-1.4 0l-9.9 9.9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDoneRounded
