import {
  ACCEPT_TERMS_CONDITIONS_CHANGE,
  CARD_SELECT,
  CLEAR_ERROR,
  PING,
  RECEIVE_AD,
  RECEIVE_ADVERTISEMENT,
  RECEIVE_CARDS,
  RECEIVE_ERROR,
  RECEIVE_LISTING_FEE,
  RECEIVE_PORTALS,
  RESET_ADVERTISEMENT,
} from 'app/sm/advertisements/advertisement_actions'

const _defaultState = {
  portals: [],
  advertisement: {}, // This should be an offer the name is misleading
  ad: {},
  listingFee: 100,
  cards: [],
  acceptTermsAndConditions: false,
  selectedCreditCard: '', // The Id from the option just goes there
  error: '',
}

const PropertiesReducer = (
  state = _defaultState,
  { type, portals, advertisement, listingFee, ad, selected, cards, error },
) => {
  Object.freeze(state)
  let newState = Object.assign({}, state)
  switch (type) {
    case PING:
      return Object.assign(newState, { error: '' })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    case RECEIVE_PORTALS:
      return Object.assign(newState, { portals })
    case RECEIVE_ADVERTISEMENT:
      return Object.assign(newState, { advertisement })
    case RECEIVE_AD:
      return Object.assign(newState, { ad })
    case CARD_SELECT:
      return Object.assign(newState, { selectedCreditCard: selected })
    case RECEIVE_CARDS:
      return Object.assign(newState, { cards })
    case ACCEPT_TERMS_CONDITIONS_CHANGE:
      return Object.assign(newState, {
        acceptTermsAndConditions: !newState.acceptTermsAndConditions,
      })
    case RECEIVE_LISTING_FEE:
      return Object.assign(newState, { listingFee })
    case RESET_ADVERTISEMENT:
      return _defaultState
    // case LOCATION_CHANGE:
    //   return Object.assign({}, _defaultState)
    default:
      return state
  }
}

export default PropertiesReducer
