import {
  CLEAR_ERROR,
  ON_TAB_CHANGE,
  ON_TAB_COUNTS_ALTER,
  PING,
  RECEIVE_ACTIVE_OFFER,
  RECEIVE_APPLICATION,
  RECEIVE_APPLICATIONS,
  RECEIVE_ERROR,
  RECEIVE_PROPERTY,
  RECEIVE_RATING_CATEGORIES,
  RESET,
  SET_PROPERTY_ID,
  TOGGLE_APPLICATIONS_SPINNER,
  TOGGLE_SEND_REQUEST_INFO,
  TOGGLE_SPINNER,
} from 'app/sm/renter_applications/renter_applications_actions'

const _defaultState = {
  application: {},
  applications: [],
  shown: [],
  tabsCtrl: [
    { id: 'Interested', title: 'Interested', count: 0 },
    { id: 'Applied', title: 'Applied', count: 0, isActive: true },
    { id: 'Shortlisted', title: 'Shortlisted', count: 0 },
    { id: 'Offered', title: 'Offered', count: 0 },
  ],
  propertyId: null,
  activeOffer: {},
  property: {},
  error: '',
  isRequestInfoDone: false,
  spinner: false,
  applicationsSpinner: false,
  ratingCategories: [],
}

const RenterApplications = (state = _defaultState, action) => {
  let {
    type,
    application,
    applications,
    tabsCtrl,
    propertyId,
    id,
    error,
    activeOffer,
    property,
    spinner,
    applicationsSpinner,
    ratingCategories,
  } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case PING:
      return Object.assign(newState, { error: '' })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case SET_PROPERTY_ID:
      return Object.assign(newState, { propertyId })
    case ON_TAB_CHANGE:
      return Object.assign(newState, {
        tabsCtrl: newState.tabsCtrl.map((t) =>
          t.id !== id
            ? Object.assign({}, t, { isActive: false })
            : Object.assign({}, t, { isActive: true }),
        ),
      })
    case RECEIVE_APPLICATION:
      return Object.assign(newState, { application })
    case RECEIVE_APPLICATIONS:
      return Object.assign(newState, { applications })
    case RECEIVE_ACTIVE_OFFER:
      return Object.assign(newState, { activeOffer })
    case ON_TAB_COUNTS_ALTER:
      return Object.assign(newState, { tabsCtrl })
    case RECEIVE_PROPERTY:
      return Object.assign(newState, { property })
    case RESET:
      return _defaultState
    case TOGGLE_SEND_REQUEST_INFO:
      return Object.assign(newState, {
        isRequestInfoDone: !newState.isRequestInfoDone,
      })
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    case TOGGLE_SPINNER:
      return Object.assign(newState, { spinner })
    case TOGGLE_APPLICATIONS_SPINNER:
      return Object.assign(newState, { applicationsSpinner })
    case RECEIVE_RATING_CATEGORIES:
      return Object.assign(newState, { ratingCategories, error })
    default:
      return state
  }
}

export default RenterApplications
