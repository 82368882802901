import { api } from 'app/utils/api/helpers'

export const checkInEnquirer = (enquirer, viewingId) => {
  const { firstName, lastName, email, phone: mobilePhone } = enquirer
  const payload = {
    firstName,
    lastName,
    email,
    mobilePhone,
    checkIn: true,
    interested: true,
  }
  return api
    .post(`/sm/viewing/${viewingId}/onsiteregistrant`, payload)
    .then((registrant) => ({
      ...registrant,
      fullName: `${registrant.firstName} ${registrant.lastName}`,
    }))
}
