import React from 'react'

import styled from 'styled-components'

const ContactDetails = styled.address`
  font-family: Cera PRO;
  line-height: 16px;
  font-size: 16px;
`

function ContactDetailsWrapper(props) {
  const emailExists = props.email !== undefined
  const mobileExists = props.mobile !== undefined
  return (
    (emailExists || mobileExists) && (
      <ContactDetails>
        {mobileExists && (
          <span>
            <a href={'tel:' + props.mobile}>{props.formattedMobile}</a>
            <br />
          </span>
        )}
        {emailExists && <a href={'mailto:' + props.email}>{props.email}</a>}
      </ContactDetails>
    )
  )
}

export default ContactDetailsWrapper
