import React, { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

import {
  Box,
  Button,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  IdGenerator,
  transformExcludedDates,
} from 'app/sm/properties/components/excluded_times/helpers'
import PropertyOptionRow from 'app/sm/properties/components/excluded_times/option_row'

const StyledSwitchButton = styled(Button)`
  font-weight: 400 !important;
  font-size: 16px !important;
  text-decoration: underline;
`

const PropertyExcludedTimes = ({
  onEntriesChanged,
  currentExcludedDates = [],
  showAllDatesLabelWhenEmpty = true, // Used to determine if label should be shown
  disabled = false,
}) => {
  const [entries, setEntries] = useState([])

  const prevEntriesRef = useRef([])

  useEffect(() => {
    if (currentExcludedDates) {
      const mappedExcludedPropertyDates =
        transformExcludedDates(currentExcludedDates)
      // break the recursive change emiting on entries dates empty reference change.
      if (
        mappedExcludedPropertyDates?.length === 0 &&
        prevEntriesRef.current?.length === 0
      ) {
        return
      }
      setEntries(mappedExcludedPropertyDates)
      prevEntriesRef.current = mappedExcludedPropertyDates
    }
  }, [currentExcludedDates])

  useEffect(() => {
    onEntriesChanged(entries)
  }, [entries])

  const addEntry = () => {
    setEntries([
      ...entries,
      {
        _id: IdGenerator(),
      },
    ])
  }

  const deleteEntry = ({ _id }) => {
    setEntries(entries.filter(({ _id: id }) => id !== _id))
  }

  const onEntryChangedHandler = (newEntry, payload) => {
    setEntries(
      entries.map((entry) => {
        let target = { ...entry }
        if (entry._id === newEntry._id) {
          target = { ...target, ...payload }
        }
        return target
      }),
    )
  }

  const showAllTimesLabel = entries?.length === 0 && showAllDatesLabelWhenEmpty

  return (
    <Box mt={`${theme.space[5]}px`}>
      {entries.map((entry) => (
        <Box mb={`${theme.space[5]}px`} key={entry._id}>
          <PropertyOptionRow
            entryInitState={entry}
            deleteEntry={() => deleteEntry(entry)}
            onEntryChanged={(payload) => onEntryChangedHandler(entry, payload)}
            disabled={disabled}
          />
        </Box>
      ))}

      <Flex mt={`${theme.space[5]}px`} alignItems="center">
        {showAllTimesLabel && (
          <TextSpan mr={`${theme.space[7]}px`}>All schedule times</TextSpan>
        )}

        {!disabled && (
          <StyledSwitchButton variant="linkBlue" onClick={addEntry}>
            {!!entries.length ? 'Add another' : 'Add times'}
          </StyledSwitchButton>
        )}
      </Flex>
    </Box>
  )
}

export default PropertyExcludedTimes
