import React from 'react'

import { Field, Form } from 'formik'
import styled from 'styled-components'

import { Alert, Button, Flex } from 'app/components/design-system-components'
import { TextArea } from 'app/components/design-system-components/inputs'
import { TagsInputField } from 'app/components/design-system-components/inputs/TagsInput/TagsInput'
import * as getters from 'app/components/design-system-components/theme/getters'
import { formFields } from 'app/features/teams/applications/share-applications/ShareApplicationsModal/form.utils'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import RequestBGCOptionLabel from 'app/sm/remarketing/request_bgc_optional_label'

const StyledFieldWrapper = styled(FieldWrapper)`
  margin: ${getters.spacing(7)} 0;
`

export const CheckboxInput = ({ value, onChange, label, ...props }) => {
  return (
    <Flex as="label" alignItems="center">
      <input
        type="checkbox"
        className="input-box wa pt0 mr8"
        onChange={(v) => {
          onChange(v)
        }}
        onClick={(e) => e.stopPropagation()}
        checked={value}
        {...props}
      />

      {label}
    </Flex>
  )
}

export const FormBody = ({
  formBag: { isValid, status, values },
  disableRequestBGCOption,
  teamSlug,
  bgcPrice,
}) => {
  return (
    <Form>
      {!!status && !!status.apiGeneralError && (
        <Alert variant="warningWithBg" mt={theme.space[3] + 'px'}>
          {status.apiGeneralError}
        </Alert>
      )}

      <StyledFieldWrapper
        id={formFields.recipients}
        name={formFields.recipients}
        label="Recipients"
        required
      >
        <TagsInputField
          id={formFields.recipients}
          name={formFields.recipients}
          inputPlaceholder="Add an email and press Enter"
        />
      </StyledFieldWrapper>

      <StyledFieldWrapper
        id={formFields.message}
        name={formFields.message}
        label="Message"
      >
        <Field name={formFields.message} as={TextArea} rows={10} />
      </StyledFieldWrapper>

      <StyledFieldWrapper
        id={formFields.withOwnerStatus}
        name={formFields.withOwnerStatus}
      >
        <Field
          name={formFields.withOwnerStatus}
          as={CheckboxInput}
          label='Update application to "With Owner" status*'
        />
      </StyledFieldWrapper>

      <StyledFieldWrapper
        id={formFields.isOwnerAcceptEnabled}
        name={formFields.isOwnerAcceptEnabled}
      >
        <Field
          name={formFields.isOwnerAcceptEnabled}
          as={CheckboxInput}
          label="Share with approve link"
        />
      </StyledFieldWrapper>

      {values.isOwnerAcceptEnabled && (
        <StyledFieldWrapper
          id={formFields.requestBGCApproval}
          name={formFields.requestBGCApproval}
        >
          <Field
            name={formFields.requestBGCApproval}
            as={CheckboxInput}
            label={
              <RequestBGCOptionLabel
                disableRequestBGCOption={disableRequestBGCOption}
                bgcPrice={bgcPrice}
                teamSlug={teamSlug}
              />
            }
          />
        </StyledFieldWrapper>
      )}

      <Flex alignItems="center">
        <Button disabled={!isValid} type="submit" flex="1 1 70%">
          Share
        </Button>
      </Flex>
    </Form>
  )
}
