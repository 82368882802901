import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

import humanIcon01 from 'app/assets/icons/human01.jpg'
import FAQs from 'app/shared_components/faqs'
import WithdrawnForm from 'app/shared_components/templates/withdraw_form'

const ReviewForm = ({
  address,
  currentLease,
  disabled,
  permissionClicked,
  declineClicked,
  approve,
  decline,
  renter,
  requestID,
  navigateUrl,
  secret,
  status,
}) => {
  let { friendlyName, streetNumber, streetName, suburb, state, postcode } =
    address ? address : ''
  let { firstName, lastName } = renter ? renter : ''
  return (
    <div>
      {status === 'WITHDRAWN' ? (
        <WithdrawnForm
          title="This request has been withdrawn by the applicant."
          context="If you believe this BondCover request has been withdrawn in error or would like some more information, please contact us "
          backLink="/home/overview"
        />
      ) : (
        <div>
          <h3 className="manager-review-header mb20">
            Your tenant{' '}
            <b>
              {firstName} {lastName}
            </b>{' '}
            has applied for <b>BondCover</b> and is requesting your approval to
            cover <b>{friendlyName}</b>, {suburb}, {state} {postcode}
          </h3>

          <ul className="manager-review-list">
            <li>
              BondCover swaps out the traditional cash bond with a guarantee and
              has the same protection.
            </li>
            <li>
              Much easier and faster <b>bond claims</b> which saves you time and
              effort.
            </li>
            <li>
              A <b>3% referral fee</b> (inc GST) on the face value of the Bond
              for approving this application (property managers only, terms
              apply).
            </li>
            <li>
              {firstName} is a <b>good renter</b> based on the National Tenancy
              Database, a Bankruptcy check and their Application documents.
            </li>
          </ul>

          <div className="row mt20 mb10 mobile-form-button">
            <div className="col-sm-2 pb5 tablet-only"></div>
            <div className="col-sm-7 margin-desktop-only">
              <Link
                className="btn"
                disabled={disabled}
                to={{
                  pathname: navigateUrl,
                  search: '?requestid=' + requestID + '&secret=' + secret,
                }}
              >
                Review &amp; Approve
                <i
                  className={permissionClicked ? 'fa fa-spinner fa-pulse' : ''}
                />
              </Link>
            </div>
          </div>
          <div className="row mb30 mobile-form-button">
            <div className="col-sm-2 pb5 tablet-only"></div>
            <div className="col-sm-7 margin-desktop-only">
              <button
                className="btn btn-transparent"
                disabled={disabled}
                onClick={decline}
              >
                No Thanks
                <i className={declineClicked ? 'fa fa-spinner fa-pulse' : ''} />
              </button>
            </div>
          </div>

          <FAQs type="manager" />

          <div className="row mt50 mb10 mobile-form-button">
            <div className="col-sm-2 pb5 tablet-only"></div>
            <div className="col-sm-7 margin-desktop-only">
              <Link
                className="btn"
                disabled={disabled}
                to={{
                  pathname: navigateUrl,
                  search: '?requestid=' + requestID + '&secret=' + secret,
                }}
              >
                Get Started{' '}
                <i
                  className={
                    permissionClicked ? 'fa fa-spinner fa-pulse fa-2x' : ''
                  }
                />
              </Link>
            </div>
          </div>
          <div className="row mobile-form-button">
            <div className="col-sm-2 pb5 tablet-only"></div>
            <div className="col-sm-7 margin-desktop-only">
              <button
                className="btn btn-transparent"
                disabled={disabled}
                onClick={decline}
              >
                {' '}
                No Thanks{' '}
                <i
                  className={
                    declineClicked ? 'fa fa-spinner fa-pulse fa-2x' : ''
                  }
                />{' '}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReviewForm
