import React from 'react'

import styled from 'styled-components'

import {
  Box,
  ButtonWithLoading,
  Flex,
  IconButton,
} from 'app/components/design-system-components'
import { OpenInNewRounded } from 'app/components/design-system-components/icons/actions'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const StyledPropertyRow = styled(Flex)`
  margin: 16px 0;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .button-container {
    width: 45%;
    text-align: right;
    button {
      margin-left: 16px;
    }
  }
`

function BuildingRow({
  building,
  onClickAssignBuilding,
  onClickRemoveBuildingForTeam,
  teamSlug,
}) {
  const { name, id, slug } = building

  return (
    <StyledPropertyRow>
      <Flex className="property-info" alignItems="center">
        {name}
        <a
          href={`/apply/${teamSlug}/b/${slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton
            IconCmp={OpenInNewRounded}
            iconProps={{
              fontSize: theme.fontSizes[6] + 'px',
            }}
            variant="flatBlack"
            col
            mb="2px"
            ml={theme.space[1] + 'px'}
          />
        </a>
      </Flex>
      <Box className="button-container">
        <ButtonWithLoading
          variant="outline"
          onClick={() => onClickRemoveBuildingForTeam(id)}
        >
          Remove
        </ButtonWithLoading>
        <ButtonWithLoading
          variant="brightGreenOutline"
          onClick={() => onClickAssignBuilding(id)}
        >
          Assign
        </ButtonWithLoading>
      </Box>
    </StyledPropertyRow>
  )
}

export default BuildingRow
