import { connect } from 'react-redux'

import ProgressBar from 'app/components/progress_bar/container'
import { addRentalReputationBackUrl } from 'app/shared_components/actions'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  addRentalReputationBackUrl: (rentalBackUrl) =>
    dispatch(addRentalReputationBackUrl(rentalBackUrl)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar)
