import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import crossButton from 'app/assets/icons/cross-button.svg'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import Theme from 'app/pages/teams/viewings-mobile/theme-styled-components'

import { Box, Button, Flex } from '.'

const ModalBackgroundContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  appearance: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 200;
  overflow-y: auto;

  color: black;
  white-space: normal;
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`

const ModalContentContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  .m-heading {
    display: flex;
    justify-content: space-between;
    font-size: 26px;
    flex-grow: 0;
    padding-bottom: ${({ theme }) => `${theme.space[4]}px`};
    font-weight: bold;
    .cross-button {
      display: flex;
      cursor: pointer;
      width: ${({ theme }) => `${theme.width[4]}px`};
      height: ${({ theme }) => `${theme.height[4]}px`};
    }
    border-bottom: 1px solid #e6e6e6;
    border-bottom: ${({ theme }) =>
      `${theme.borders[1]} ${theme.colors.grey200}`};
  }
  .m-content {
    &.content-overflow {
      box-shadow: inset 0px -11px 10px -10px
        ${({ theme }) => `${theme.colors.gray300}`};
    }
    flex-grow: 1;
    padding: ${({ theme }) => `${theme.space[4]}px ${theme.space[0]}px`};
    overflow-y: auto;
  }
  .m-footer {
    flex-grow: 0;
    font-weight: bold;
    font-size: ${({ theme }) => `${theme.fontSizes[4]}px`};
    padding-top: ${({ theme }) => `${theme.space[7]}px`};
    .secondary-button {
      width: 30%;
    }
    .primary-button {
      width: ${(props) =>
        props.standardWidth
          ? '310px'
          : !props.showSecondaryButton
          ? '100%'
          : '70%'};
    }
    @media screen and (max-width: 576px) {
      flex-direction: column;
      .secondary-button {
        width: 100%;
        margin-right: 0;
        margin-bottom: ${({ theme }) => `${theme.space[3]}px`};
      }

      .primary-button {
        width: 100%;
        margin-right: 0;
        margin-bottom: ${({ theme }) => `${theme.space[3]}px`};
      }
    }
  }
`

// https://codesandbox.io/s/outside-alerter-hooks-lmr2y?module=/src/OutsideAlerter.js&file=/src/OutsideAlerter.js:776-841
export const useOutsideAlerter = (ref, toggleModal) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleModal()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])
}

export const ModalBasicFooter = ({
  secondaryAction,
  showSecondaryButton,
  secondaryLabel,
  primaryAction,
  primaryLabel,
  primaryButtonLoading = false,
  showPrimaryButton = true,
  primaryButtonClassName = '',
  secondaryButtonClassName = '',
  secondaryButtonVariant = '',
  primaryButtonDisabled = false,
  secondaryButtonDisabled = false,
}) => {
  return (
    <Flex className="m-footer">
      {showSecondaryButton && (
        <Button
          variant={secondaryButtonVariant ? secondaryButtonVariant : 'solid'}
          className={`secondary-button ${secondaryButtonClassName}`}
          onClick={secondaryAction}
          disabled={primaryButtonLoading || secondaryButtonDisabled}
          mr="8px"
        >
          {secondaryLabel}
        </Button>
      )}
      {showPrimaryButton && (
        <Button
          onClick={primaryAction}
          className={`primary-button ${primaryButtonClassName}`}
          disabled={primaryButtonLoading || primaryButtonDisabled}
        >
          {primaryLabel}{' '}
          {primaryButtonLoading && (
            <i className="ml10 fa fa-spinner fa-pulse" />
          )}
        </Button>
      )}
    </Flex>
  )
}

const Modal = ({
  modalHeading = '',
  modalBody,
  modalFooter,
  customModalFooter = false,
  primaryLabel,
  primaryAction,
  secondaryLabel,
  secondaryAction,
  toggleModal,
  showSecondaryButton = true,
  primaryButtonLoading = false,
  showPrimaryButton = true,
  standardWidth = false,
  takeIncreasedWidth = false,
  primaryButtonClassName = '',
  secondaryButtonClassName = '',
  secondaryButtonVariant = '',
  showModalHeading = true,
  contentClass = '',
  takeMaxWidth = false,
  primaryButtonDisabled = false,
  secondaryButtonDisabled = false,
}) => {
  const [isContentOverflow, setIsContentOverflow] = useState(false)
  useEffect(() => {
    const modalContent = document.querySelector('#m-content')
    const isHeightOverflow =
      modalContent.offsetHeight < modalContent.scrollHeight
    const isWidthOverflow = modalContent.offsetWidth < modalContent.scrollWidth
    const isOverflow = isHeightOverflow || isWidthOverflow
    setIsContentOverflow(isOverflow)
  }, [])
  // temporarily commenting out to fix close issue when clicked inside the modal
  // and another modal is open
  // const wrapperRef = useRef(null)
  // useOutsideAlerter(wrapperRef, toggleModal)
  return (
    <Theme>
      <ModalBackgroundContainer>
        <div
          // ref={wrapperRef}
          className="content"
        >
          <ModalContentWrapper
            takeFullHeight
            takeIncreasedWidth={takeIncreasedWidth}
            takeMaxWidth={takeMaxWidth}
          >
            <ModalContentContainer
              standardWidth={standardWidth}
              showSecondaryButton={showSecondaryButton}
            >
              {showModalHeading && (
                <Flex className="m-heading">
                  <Box>{modalHeading}</Box>
                  <Box className="cross-button" onClick={toggleModal}>
                    <img src={crossButton} alt="close modal" />
                  </Box>
                </Flex>
              )}
              <Flex
                className={`m-content ${
                  isContentOverflow ? 'content-overflow' : ''
                } ${contentClass}`}
                id="m-content"
              >
                {modalBody}
              </Flex>
              {!customModalFooter && (
                <ModalBasicFooter
                  primaryLabel={primaryLabel}
                  primaryAction={primaryAction}
                  secondaryLabel={secondaryLabel}
                  secondaryAction={secondaryAction}
                  showSecondaryButton={showSecondaryButton}
                  primaryButtonLoading={primaryButtonLoading}
                  showPrimaryButton={showPrimaryButton}
                  primaryButtonClassName={primaryButtonClassName}
                  secondaryButtonClassName={secondaryButtonClassName}
                  secondaryButtonVariant={secondaryButtonVariant}
                  primaryButtonDisabled={primaryButtonDisabled}
                  secondaryButtonDisabled={secondaryButtonDisabled}
                />
              )}
              {customModalFooter && modalFooter}
            </ModalContentContainer>
          </ModalContentWrapper>
        </div>
      </ModalBackgroundContainer>
    </Theme>
  )
}

export default Modal
