import React from 'react'

import * as Form from 'app/components/forms/forms'

export const viewingScheduleZoneDropdownValues = [
  { guid: 0, name: 'Default Zone' },
  { guid: 1, name: 'North Zone' },
  { guid: 2, name: 'South Zone' },
  { guid: 3, name: 'East Zone' },
  { guid: 4, name: 'West Zone' },
  { guid: 5, name: 'Nearby Zone' },
  { guid: 6, name: 'Distant Zone' },
  { guid: 7, name: 'Zone 1' },
  { guid: 8, name: 'Zone 2' },
  { guid: 9, name: 'Zone 3' },
  { guid: 10, name: 'Zone 4' },
  { guid: 11, name: 'Zone 5' },
  { guid: 12, name: 'Zone 6' },
  { guid: 13, name: 'Zone 7' },
  { guid: 14, name: 'Zone 8' },
  { guid: 15, name: 'Zone 9' },
  { guid: 16, name: 'Zone 10' },
  { guid: 17, name: 'Zone 11' },
  { guid: 18, name: 'Zone 12' },
  { guid: 19, name: 'Zone 13' },
  { guid: 20, name: 'Zone 14' },
  { guid: 21, name: 'Zone 15' },
  { guid: 22, name: 'Zone 16' },
  { guid: 23, name: 'Zone 17' },
  { guid: 24, name: 'Zone 18' },
  { guid: 25, name: 'Zone 19' },
  { guid: 26, name: 'Zone 20' },
  { guid: 27, name: 'Zone 21' },
  { guid: 28, name: 'Zone 22' },
  { guid: 29, name: 'Zone 23' },
  { guid: 30, name: 'Zone 24' },
  { guid: 31, name: 'Zone 25' },
  { guid: 32, name: 'Zone 26' },
  { guid: 33, name: 'Zone 27' },
  { guid: 34, name: 'Zone 28' },
  { guid: 35, name: 'Zone 29' },
  { guid: 36, name: 'Zone 30' },
  { guid: 37, name: 'Zone 31' },
  { guid: 38, name: 'Zone 32' },
  { guid: 39, name: 'Zone 33' },
  { guid: 40, name: 'Zone 34' },
  { guid: 41, name: 'Zone 35' },
]

class ViewingScheduleZoneDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedZone: 0,
    }
  }

  render() {
    const { selectedDropdownValue, updateViewingScheduleDropdown } = this.props
    return (
      <Form.Dropdown
        value={selectedDropdownValue}
        options={viewingScheduleZoneDropdownValues}
        onChange={updateViewingScheduleDropdown}
        idAsValue
      />
    )
  }
}

export default ViewingScheduleZoneDropdown
