import React from 'react'

import styled from 'styled-components'

const SearchInputBoxWrapper = styled.div`
  .styled-search-filter-widget {
    display: flex;
    align-items: baseline;
    flex: 1;
    padding-left: 10px;

    .input-box {
      flex-grow: 1;
      margin-bottom: 0;
    }
    @media (max-width: 576px) {
      padding-left: 0;
    }
  }
`

const SearchInputBox = ({
  iconType = 'search',
  onChange,
  value,
  placeholder = '',
  inputType,
}) => {
  return (
    <SearchInputBoxWrapper>
      <div className="styled-search-filter-widget mr20">
        <div className="input-box mb0">
          <div className="prefix">
            <i className={`icon-${iconType}`}></i>
          </div>
          <input
            id="search-bar"
            type={inputType ? inputType : 'text'}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
        </div>
      </div>
    </SearchInputBoxWrapper>
  )
}

export default SearchInputBox
