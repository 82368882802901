import React from 'react'

import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'

const StyledWithPaidPlanOnlyBadge = styled(Flex)`
  align-items: self-end;
  .custom-badge {
    margin-bottom: 10px;
    margin-left: ${(props) => props.theme.space[3]}px;
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: self-start;
    .custom-badge {
      margin-left: 0;
    }
  }
`

function WithPaidPlanOnlyBadge({ comp }) {
  return (
    <StyledWithPaidPlanOnlyBadge>
      {comp}
      <Box className="custom-badge">
        <Badge variant="blue" text="Paid Plans Only" />
      </Box>
    </StyledWithPaidPlanOnlyBadge>
  )
}

export default WithPaidPlanOnlyBadge
