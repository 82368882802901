import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { validateEmail } from 'app/shared_components/validations'
import {
  getPhoneTypeErrorMessage,
  NUMBER_TYPE_MOBILE,
  validateNumberType,
} from 'app/utils/phonenumber/helpers'

const GENERIC_PHONE_NUMBER_ERROR = 'e.g. 0431 111 222 or 02 5550 2309'

export const formFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  contactNumber: 'contactNumber',
  preferredDate: 'preferredDate',
  preferredTime: 'preferredTime',
}

export const VIEWING_FORM_TYPE = {
  request: 'request',
  register: 'register',
  potential_register: 'potential_register',
}

export const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.firstName: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    case formFields.lastName: {
      return {
        required: !val && 'This field is required to continue',
      }
    }
    case formFields.email: {
      return {
        required: !val && 'This field is required to continue',
        invalid: validateEmail(val)[0],
      }
    }
    case formFields.contactNumber: {
      return {
        required: !val && 'This field is required to continue',
        invalid:
          !validateNumberType(val, NUMBER_TYPE_MOBILE) &&
          (getPhoneTypeErrorMessage(val) || GENERIC_PHONE_NUMBER_ERROR),
      }
    }
    default: {
      return {}
    }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
