import React from 'react'

import referenceIcon from 'app/assets/icons/summary_icons/personal-reference.svg'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import SummaryPersonalReferenceBox from 'app/match/apply/application_summary_before_submit/shared_components/personal_reference_box/component'
import { sendPersonalReferenceRequestToReference } from 'app/services/http/personalReference'
import * as snugNotifier from 'app/services/snugNotifier'
import { parseDataUtil, urlTo } from 'app/sm/helpers'

const PersonalReferenceContainer = ({
  teamGUID,
  componentClassName = '',
  personalReferences = [],
  isPersonalReferenceEditable = true,
  onEditClick,
  enableNote = false,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  displayedApplicant,
  section,
  id = '',
  toggleRequestInfoModal = undefined,
  teamSettingInfo = {},
}) => {
  const onResendReference = (referenceGUID, email, message, teamGUID) => {
    return sendPersonalReferenceRequestToReference(
      referenceGUID,
      email,
      message,
      teamGUID,
    )
      .then(({ email, phone, ...event }) => {
        const msg = {
          1: 'Email requested',
          2: 'SMS requested',
          3: 'Email/SMS requested',
        }[
          email !== '' && phone === ''
            ? 1
            : email === '' && phone !== ''
            ? 2
            : 3
        ]

        snugNotifier.success(msg)
        return { email, phone, ...event }
      })
      .catch((error) => snugNotifier.error(error.message))
  }
  const { application: applicationSettings = {} } = teamSettingInfo
  const displayResponseSection =
    parseDataUtil.convertObjValueFromStringToBoolean(
      applicationSettings,
    ).sending_personal_reference_request_enabled
  const hasPersonalReferences =
    personalReferences && personalReferences.length > 0
  return (
    <div id={id} className={`container-component ${componentClassName}`}>
      <ContainerHeader
        icon={referenceIcon}
        text="Personal Reference"
        isEditable={isPersonalReferenceEditable}
        onEditClick={() =>
          onEditClick(`${urlTo('personalReferenceDetails')}?from=rentersummary`)
        }
        enableNote={enableNote}
        onNewNoteUpdated={onNewNoteUpdated}
        newNotesContent={newNotesContent}
        onNoteIconClicked={onNoteIconClicked}
        onNewNoteAdded={onNewNoteAdded}
        onNoteCrossClicked={onNoteCrossClicked}
        interpreteNoteResult={interpreteNoteResult}
        enableNotesButton={enableNotesButton}
        displayedApplicant={displayedApplicant}
        section={section}
        toggleRequestInfoModal={toggleRequestInfoModal}
      />
      <div className="application-container-padding pdf-display-flex flex-wrap-wrap">
        {hasPersonalReferences &&
          personalReferences.map((personalRef) => (
            <SummaryPersonalReferenceBox
              personalRef={personalRef}
              key={personalRef.guidID}
              readOnly
              from="renterSummary"
              onResend={(email, message) =>
                onResendReference(personalRef.guidID, email, message, teamGUID)
              }
              displayResponseSection={displayResponseSection}
            />
          ))}
        {!hasPersonalReferences && <div>Not provided</div>}
      </div>
    </div>
  )
}

export default PersonalReferenceContainer
