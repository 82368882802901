import React, { Component } from 'react'

import moment from 'moment'

import { Box } from 'app/components/design-system-components/index'
import * as Display from 'app/components/display/display'
import { ButtonContainer } from 'app/components/display/modal_bottom_buttons'
import Banner from 'app/match/applicationReportPDF/components/Banner'
import Container from 'app/match/applicationReportPDF/components/Layout/Container'
import LeadCaptureForm from 'app/match/applicationReportPDF/components/LeadCaptureForm'
import SnugPdf1 from 'app/match/applicationReportPDF/pages/Snug-pdf-1'
import SnugPdf2 from 'app/match/applicationReportPDF/pages/Snug-pdf-2'
import SnugPdf4 from 'app/match/applicationReportPDF/pages/Snug-pdf-4'
import { ApplicationTimelinePrint } from 'app/match/applicationReportPDF/pages/Snug-pdf-4/StatusActivities'
import SiteInformation from 'app/match/leadToSnug/components/site_information'
import { ratingVersions } from 'app/match/rental_reference/rating_categories.constants'
import { getAgencyProfileAndDetails } from 'app/services/http/agency'
import * as snugNotifier from 'app/services/snugNotifier'
import * as sharedHelpers from 'app/shared_components/helpers'
import { ErrorMessage } from 'app/shared_components/helpers'
import * as helpers from 'app/sm/helpers'
import { defaultRentScheduleOptions } from 'app/sm/helpers'
import * as vehicleHelpers from 'app/utils/applications/vehicles'
import { DATE_WITH_DASH, DEFAULT_UNIX_TIME } from 'app/utils/datetime/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import { formatAndPrefix, LANDLINE_PREFIX } from 'app/utils/phonenumber/helpers'
import * as sensitiveInfoHelpers from 'app/utils/sensitiveInfo/helper'
import * as stringHelpers from 'app/utils/strings/helpers'

import 'app/match/applicationReportPDF/style.scss'

const getRenterApplication = (managerSummary) => {
  return managerSummary.application ? managerSummary.application : {}
}

export const PERSONAL_REFERENCE_NEXT_OF_KIN = 'Personal Reference / Next of Kin'
export const EMERGENCY_CONTACT = 'Emergency Contact'

export const pdfAnswerType = {
  unknown: 0,
  positive: 1,
  negative: 2,
}

const managerTypes = {
  agent: 'Agent',
  owner: 'Owner',
  referee: 'Referee',
}

const getApplicants = (renterApplication) =>
  renterApplication && renterApplication.applicants
const getPrimaryApplicant = (application) => {
  const renterApplication = getRenterApplication(application)
  const applicants = getApplicants(renterApplication)
  const primaryApplicant =
    applicants && applicants.find((applicant) => applicant.isPrimary)
  return primaryApplicant || {}
}
const getApplicantByApplicantId = (application, applicantId) => {
  const renterApplication = getRenterApplication(application)
  const applicants = getApplicants(renterApplication)
  const targetApplicant =
    applicants &&
    applicants.find((applicant) => applicant.guidID === applicantId)
  return targetApplicant || {}
}
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      apiError: '',
      allApplicants: [],
      selectedApplicantGUID: '',
      application: '',
      display: {
        isLoading: true,
      },
      isBannerModalOpen: false,
      isFormModalOpen: false,
      agencyInfo: {},
    }
  }

  componentDidMount() {
    document.getElementById('index').classList.add('no-navbar-menu')
    const {
      match: {
        params: { applicationId, applicantId },
      },
      fetchRentalHistoryRatingCategories,
      fetchCompleteness,
      retrieveReputationPreference,
    } = this.props
    fetchRentalHistoryRatingCategories()
    const isApplyAnywhere = helpers.isApplyAnywhere()
    if (isApplyAnywhere) {
      this.getApplyAnywhereApplicationDetails()
    }

    if (!isApplyAnywhere) {
      const { property: { agencyID = '' } = {} } = this.props.application

      this.fetchAgencyInfoAndSettings(agencyID)

      this.fetchManagerSummary(applicationId, applicantId).then(() => {
        const primaryApplicant = getPrimaryApplicant(this.props.application)
        const applicantIdForRequest =
          applicantId || (primaryApplicant && primaryApplicant.guidID)
        applicantIdForRequest &&
          this.callFetchPMSupportAttachments(applicantIdForRequest)
      })
      this.onfetchRentAndEmploymentSummary(applicationId)
    }
    fetchCompleteness().catch((error) => this.setState({ apiError: error }))
    retrieveReputationPreference()
      .then(({ preference }) => {
        this.setState({ preference })
      })
      .catch((error) => {
        this.setState({ error })
      })

    window.zE('messenger', 'hide')
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !helpers.isApplyAnywhere() &&
      this.props.application?.application?.guidID !==
        prevProps.application?.application?.guidID
    ) {
      const { property: { agencyID = '' } = {} } = this.props.application

      this.fetchAgencyInfoAndSettings(agencyID)
    }
  }

  componentWillUnmount() {
    window.zE('messenger', 'show')
  }

  fetchAgencyInfoAndSettings(agencyID) {
    const { fetchTeamSettingInfo } = this.props

    agencyID &&
      fetchTeamSettingInfo(agencyID, 'application')
        .then((teamSettingInfo) => {
          const { application: applicationSettingInfo = {} } = teamSettingInfo
          const parsedApplicationSettingInfo =
            helpers.parseDataUtil.convertObjValueFromStringToBoolean(
              applicationSettingInfo,
            )
          this.setState({ parsedApplicationSettingInfo })
        })
        .catch((error) => {
          this.setState({ error })
        })

    agencyID &&
      getAgencyProfileAndDetails(agencyID)
        .then((response) => {
          this.setState({
            agencyInfo: response,
          })
        })
        .catch((err) => {
          snugNotifier.error(err?.message)
        })
  }

  onfetchRentAndEmploymentSummary = (applicationGUID) => {
    const { fetchRentAndEmploymentSummary } = this.props
    fetchRentAndEmploymentSummary(applicationGUID).then((summary) => {
      if (summary) {
        let { applicantsEmployment } = summary
        if (applicantsEmployment && applicantsEmployment.length > 0) {
          applicantsEmployment = applicantsEmployment.sort(
            (applicantA, applicantB) => {
              return applicantB.isPrimary - applicantA.isPrimary
            },
          )
          summary.applicantsEmployment = applicantsEmployment
        }
        this.setState({
          rentAndEmploymentDetails: summary,
        })
      }
    })
  }

  callFetchPMSupportAttachments(applicantID) {
    const { fetchPMSupportAttachments, application } = this.props
    const primaryApplicant = getPrimaryApplicant(application)
    const applicantIdForRequest = applicantID || primaryApplicant.guidID
    fetchPMSupportAttachments(applicantIdForRequest)
      .then((pmSupportDocs) => {
        this.setState({
          pmSupportAttachments: pmSupportDocs,
        })
      })
      .catch((error) => this.setState({ apiError: error }))
  }

  fetchManagerSummary(applicationId, applicantId, setting = {}) {
    const { fetchRenterApplication } = this.props
    return fetchRenterApplication(applicationId, applicantId, setting)
      .then(() => {
        const { application } = this.props
        const renterApplication = getRenterApplication(application)
        const applicants = getApplicants(renterApplication)
        const primaryApplicant =
          applicants && applicants.find((applicant) => applicant.isPrimary)
        const applicationSubmitReminder =
          application.lastApplicationSubmitReminder
        this.setState({
          selectedApplicantGUID: applicantId || primaryApplicant.guidID,
          lastSubmitApplicationRequestTime:
            applicationSubmitReminder && applicationSubmitReminder.createdAt,
        })
        return Promise.resolve()
      })
      .catch((error) =>
        this.setState({
          apiError: error,
        }),
      )
  }

  handleApplicantChange = (applicationGUID) => {
    const { selectedApplicantGUID } = this.state
    if (selectedApplicantGUID && selectedApplicantGUID !== applicationGUID) {
      this.getApplyAnywhereApplicationDetails(applicationGUID)
    }
  }

  getApplyAnywhereApplicationDetails = (applicantGUID) => {
    // applicantGUID is optional
    const {
      token,
      match: {
        params: { applicationId: applicationGUID },
      },
    } = this.props
    if (!!!applicationGUID) {
      snugNotifier.error('Application ID required')
      return
    }
    if (!!!token) {
      snugNotifier.error('Token required')
      return
    }

    const displayPrimaryApplicant = !!!applicantGUID

    this.setState({
      display: {
        isLoading: true,
      },
    })
    this.props
      .fetchApplyAnywhereManagerSummaryWithSecret(
        applicationGUID,
        token,
        applicantGUID,
      )
      .then((application) => {
        const { application: renterApplication } = application || {}
        const { applicants } = renterApplication || {}

        let selectedApplicantGUID = applicantGUID
        if (displayPrimaryApplicant) {
          selectedApplicantGUID = (applicants || []).reduce(
            (acc, applicant) => {
              if (applicant.isPrimary) acc = applicant.guidID
              return acc
            },
            '',
          )
        }

        this.setState({
          ...this.state,
          allApplicants: applicants,
          selectedApplicantGUID: selectedApplicantGUID,
          application: application,
          apiError: '',
          display: {
            isLoading: false,
          },
        })
      })
      .catch((error) =>
        this.setState({
          display: {
            isLoading: false,
          },
          apiError: error,
        }),
      )
      .finally(() => {
        this.setState({ display: { isLoading: false } })
      })
  }

  toggleModal = (type) => {
    this.setState({
      [type]: !this.state[type],
    })
  }

  render() {
    const {
      apiError = '',
      display,
      allApplicants,
      selectedApplicantGUID,
      preference = {},
      isBannerModalOpen,
      isFormModalOpen,
      rentAndEmploymentDetails,
      agencyInfo,
    } = this.state
    const { parsedApplicationSettingInfo = {} } = this.state
    const { isLoading } = display
    const showSpinner = isLoading
    const {
      posting_clause_enabled: postingClauseEnabled,
      enable_enhanced_disclosure: enableEnhancedDisclosure,
    } = parsedApplicationSettingInfo

    let {
      application = {},
      completeness,
      versionsRatingCategories = {},
    } = this.props

    if (!versionsRatingCategories) return null

    const isApplyAnywhere = helpers.isApplyAnywhere()
    if (isApplyAnywhere) {
      application = this.state.application
    }
    const {
      match: {
        params: { applicantId, applicationId },
      },
      currentUser = {},
    } = this.props
    let chosenApplicant = getApplicantByApplicantId(application, applicantId)
    if (isApplyAnywhere) {
      chosenApplicant = getApplicantByApplicantId(
        application,
        selectedApplicantGUID,
      )
    }
    const { income = false, identityDocument = false } = completeness
    let {
      property = {},
      application: renterApplication = {},
      propertyOffer = {},
      backgroundCheck = {},
      emergencyContacts = [],
      personalReferences = [],
      employers = [],
      rentalHistory = [],
      proofOfIncome = {},
      studentDetails = [],
      idDocumentsSummary = {},
      attachments: otherAttachments = [],
      pets = [],
      viewingCheckInSummary = {},
      applicationNotes = [],
    } = application

    const {
      submittedDate = '',
      requests = '',
      aboutMe = '',
      term: renterOfferedTerm = '',
      weeklyRent: renterOfferedRent = 0,
      leaseStartDate = '',
      score = 0,
      applicants = [],
      children = [],
      slug: applicationSlug = '',
      rentToIncomeRatio,
      applyAnywhereListing = {},
      occupants = [],
      rentScheduleType = 0,
      requestCarSpace = false,
      allVehicles,
      config = {},
    } = renterApplication

    const { solicitedAbovePrice = {}, applicationsSettingsConfig = {} } =
      config || {}
    const {
      exclude_request_reason_for_moving: excludeRequestReasonForMoving = true,
    } = applicationsSettingsConfig || {}

    const {
      isAboveDefaultPrice = false,
      isSolicitedAbovePriceChecked = false,
    } = solicitedAbovePrice

    const {
      address: {
        friendlyName = '',
        suburb = '',
        postcode = '',
        state = '',
      } = {},
      bedrooms = 0,
      bathrooms = 0,
      garages = 0,
      carports = 0,
      parking = 0,
    } = isApplyAnywhere ? applyAnywhereListing : property

    const {
      weeklyRent: advertWeeklyRent = 0,
      acceptedLeaseLength = 'N/A',
      availableFrom: advertLeaseStartDate,
    } = propertyOffer || {}
    const {
      firstName = '',
      lastName = '',
      email = '',
      mobileNumber = '',
      smokingInfo = helpers.smokingType.NotCompleted,
      optedToReceiveUtilityConnection = false,
      optedToAcceptCondition = false,
      optedToAcceptDisclosureConsent = false,
      optedToRequirePostingClause = false,
      optedToReceiveBondLoanInformation = false,
      isPrimary = false,
      optedToSkipViewingDisclosures = false,
    } = chosenApplicant
    const { viewingID = '', checkInAt = '' } = viewingCheckInSummary
    const complianceInfo = {
      property_condition: {
        date: submittedDate,
        disable: optedToSkipViewingDisclosures,
        type: optedToAcceptCondition
          ? pdfAnswerType.positive
          : pdfAnswerType.negative,
      },
      disclosure_consent: {
        date: submittedDate,
        disable: optedToSkipViewingDisclosures,
        type: optedToAcceptDisclosureConsent
          ? pdfAnswerType.positive
          : pdfAnswerType.negative,
      },
      connecting_utilities: {
        date: submittedDate,
        disable: optedToSkipViewingDisclosures,
        type: optedToReceiveUtilityConnection
          ? pdfAnswerType.positive
          : pdfAnswerType.negative,
      },
      posting_clause: {
        disable: !postingClauseEnabled || optedToSkipViewingDisclosures,
        date: submittedDate,
        type: optedToRequirePostingClause
          ? pdfAnswerType.positive
          : pdfAnswerType.negative,
      },
      viewed_property: {
        date: checkInAt || 'N/A',
        disable: optedToSkipViewingDisclosures,
        type: viewingID ? pdfAnswerType.positive : pdfAnswerType.unknown,
      },
      bond_loan: {
        date: submittedDate,
        disable: optedToSkipViewingDisclosures,
        type: optedToReceiveBondLoanInformation
          ? pdfAnswerType.unknown
          : pdfAnswerType.positive,
      },
      skip_viewing_disclosures: {
        date: submittedDate,
        type: pdfAnswerType.positive,
        disable: !optedToSkipViewingDisclosures,
      },
      solicited_above_adv_price: {
        date: submittedDate,
        type: isSolicitedAbovePriceChecked
          ? pdfAnswerType.positive
          : pdfAnswerType.unknown,
        disable: !isAboveDefaultPrice,
      },
    }
    const otherInfo = {
      smoker: helpers.smokingText[smokingInfo],
      'utility_connection_opt-in': optedToReceiveUtilityConnection
        ? 'Yes'
        : 'No',
    }
    !parsedApplicationSettingInfo.smoker_declaration_enabled &&
      delete otherInfo.smoker
    const applicantInfo = Object.assign(
      {},
      { firstName: firstName },
      { lastName: lastName },
      {
        name: `${helpers.capitalizeFirstLetter(
          firstName,
        )} ${helpers.capitalizeFirstLetter(lastName)}`,
      },
      { email },
      { mobile: mobileNumber },
      { address: `${friendlyName}, ${suburb} ${postcode} ${state}` },
      {
        submitTime: helpers.getStandardFormattedDate(
          submittedDate,
          helpers.pdfStandardDisplayTimeFormat,
        ),
      },
      { requests },
      { aboutMe },
      {
        advertised: Object.assign(
          {},
          {
            bedCount: bedrooms,
            showerCount: bathrooms,
            carCount: garages || carports || parking,
            timeTo: acceptedLeaseLength,
            maxRentPrice: advertWeeklyRent,
          },
        ),
      },
      { app_number: applicationSlug },
      { isPrimary },
    )
    const contacts = {
      [PERSONAL_REFERENCE_NEXT_OF_KIN]: personalReferences,
      [EMERGENCY_CONTACT]: emergencyContacts,
    }

    const rentScheduleDef =
      defaultRentScheduleOptions.find((o) => o.index === rentScheduleType) || {}
    const rentScheduleTitle = rentScheduleDef.value || 'N/A'
    let hasLeaseDetailsBeenModified = false
    let modifiedApplicationSummary = {}
    let totalHouseholdIncome = 0
    if (rentAndEmploymentDetails && rentAndEmploymentDetails.isLeaseUpdated) {
      hasLeaseDetailsBeenModified = true
      totalHouseholdIncome =
        rentAndEmploymentDetails.applicantsEmployment &&
        rentAndEmploymentDetails.applicantsEmployment.reduce(
          (res, applicant) =>
            res +
            Number(applicant.employment) +
            Number(applicant.govtWeeklyBenefits) +
            Number(applicant.otherWeeklyBenefits),
          0,
        )
      modifiedApplicationSummary = {
        rent: `$${rentAndEmploymentDetails.weeklyRent}/week`,
        'lease start': helpers.getStandardFormattedDate(
          rentAndEmploymentDetails.leaseStart,
          helpers.standardDateWithoutTime,
        ),
        occupancy: `${rentAndEmploymentDetails.leaseTerm} months`,
        income: `$${totalHouseholdIncome}/week`,
      }
    }

    const rentVariance = renterOfferedRent - advertWeeklyRent
    const renterVarianceText =
      rentVariance === 0
        ? ''
        : `(${rentVariance > 0 ? '+' : '-'}$${Math.abs(rentVariance)})`

    const leaseStartDateVariance = moment(leaseStartDate).diff(
      moment(advertLeaseStartDate),
      'days',
    )
    const leaseStartDateVarianceText =
      leaseStartDateVariance === 0
        ? ''
        : `(${Math.abs(leaseStartDateVariance)} days ${
            leaseStartDateVariance > 0 ? 'after' : 'before'
          })`

    const applicationSummary = {
      rentInfo: {
        rent: `$${renterOfferedRent}/week ${renterVarianceText}`,
        occupancy: `${renterOfferedTerm} months`,
        'lease start': `${helpers.getStandardFormattedDate(
          leaseStartDate,
          helpers.standardDateWithoutTime,
        )} ${leaseStartDateVarianceText}`,
        'Rent schedule': rentScheduleTitle,
      },
      snugMatchScore: Math.round(score),
      incomeCompleted: income,
      identityDocumentCompleted: identityDocument,
      affordability: rentToIncomeRatio > 0 ? `${rentToIncomeRatio}%` : 'N/A',
    }
    const totalVehicles = allVehicles && allVehicles.length
    const totalVehiclesText = vehicleHelpers.formTotalVehiclesText(allVehicles)
    if (parsedApplicationSettingInfo.request_car_space) {
      applicationSummary.rentInfo['Request a Car Space'] = requestCarSpace
        ? 'Yes'
        : 'No'
      if (totalVehicles) {
        applicationSummary.rentInfo[`Vehicles (${totalVehicles})`] =
          totalVehiclesText
      }
    }
    const applicantsInfo = applicants
      .sort((a, b) => -(a.isPrimary - b.isPrimary))
      .map((applicant) => {
        const {
          avatar = '',
          firstName = '',
          lastName = '',
          email = '',
          mobileNumber: mobile = 'N/A',
          updatedAt = '',
          age = '',
          isPrimary,
        } = applicant
        return {
          image: avatar,
          type: 'household',
          name: `${helpers.capitalizeFirstLetter(
            firstName,
          )} ${helpers.capitalizeFirstLetter(lastName)}`,
          email,
          mobile,
          age,
          viewedOn: helpers.getStandardFormattedDate(
            updatedAt,
            helpers.pdfStandardDisplayTimeFormat,
          ),
          status: '',
          isPrimary,
        }
      })

    const occupantsInfo = occupants.map((occupant) => {
      const {
        firstName = '',
        lastName = '',
        email = '',
        mobileNumber: mobile = '',
        updatedAt = '',
      } = occupant
      return {
        type: 'household',
        name: `${helpers.capitalizeFirstLetter(
          firstName,
        )} ${helpers.capitalizeFirstLetter(lastName)}`,
        email,
        mobile,
        viewedOn: helpers.getStandardFormattedDate(
          updatedAt,
          helpers.pdfStandardDisplayTimeFormat,
        ),
        status: '',
      }
    })

    const childrenInfo = children.map((child) => {
      const { firstName = '', lastName = '' } = child
      return {
        image: '',
        type: 'household',
        name: `${helpers.capitalizeFirstLetter(
          firstName,
        )} ${helpers.capitalizeFirstLetter(lastName)}`,
        status: '',
        child: true,
      }
    })
    const householdInfo = applicantsInfo
      .concat(occupantsInfo)
      .concat(childrenInfo)
    const employmentInfo =
      employers &&
      employers.map((employer) => {
        const {
          startDate,
          endDate,
          jobTitle = '',
          employerName = '',
          contactName = '',
          email = '',
          phoneNumber = '',
          stillEmployed = false,
          verifyStatus = 0,
          employmentType = 0,
          grossAnnualIncome = 'N/A',
          verifyDate = null,
          address = {},
          reasonAffectingEmployment = '',
          confirmedWeeklyNetIncome,
          employerABN = '',
          employerTradingName = '',
        } = employer
        const showReasonAffectingEmploymentLine = !!reasonAffectingEmployment
        const formattedPrefixedPhone = formatAndPrefix(
          phoneNumber,
          LANDLINE_PREFIX,
          true,
        )
        const contactVerifyDate =
          verifyDate != null
            ? moment(verifyDate).format(helpers.standardMilitaryTime)
            : ''
        return {
          generalInfo: {
            position: `${stringHelpers.capitalizeFirstLetterOfString(
              jobTitle,
            )} at ${helpers.capitalizeFirstLetter(employerName)}`,
            type: helpers.EmploymentTypes[employmentType],
            tenure: helpers.calculateDuration(
              startDate,
              endDate,
              stillEmployed,
            ),
            address: `${address.friendlyName}, ${address.suburb}`,
            addressObj: address,
            gross_income: `$${helpers.numberWithCommas(
              grossAnnualIncome,
            )} p/annum`,
            net_income: `$${helpers.numberWithCommas(
              confirmedWeeklyNetIncome,
            )}/week`,
            employer_abn: employerABN,
            employer_name: employerName,
            employer_trading_name: employerTradingName,
            show_reason_affecting_employment_line:
              showReasonAffectingEmploymentLine,
            reason_affecting_employment: reasonAffectingEmployment,
          },
          contact: {
            type: 'contact',
            image: '',
            name: `${helpers.capitalizeFirstLetter(contactName)}`,
            status: '',
            email: `E: ${email}`,
            mobile: formattedPrefixedPhone,
            verifyStatus: helpers.EmploymentVerifyStatus[verifyStatus],
            verifyDate: contactVerifyDate,
          },
        }
      })
    const addressHistoryInfo =
      rentalHistory &&
      rentalHistory.map((addressDetails) => {
        const { referenceSetVersion } = addressDetails
        const ratingCategories =
          versionsRatingCategories[referenceSetVersion] || []
        const addressWithRating = !!addressDetails.ratings
        const {
          rate = 0,
          startDate = '',
          endDate = '',
          isCurrentAddress = false,
          propertyArrangement = 0,
          reasonForLeaving = '',
          ratings,
          firstName: rentalReferenceFirstName = '',
          lastName: rentalReferenceLastName = '',
          email: rentalReferenceEmail = '',
          officeNumber = '',
          phoneNumber = '',
          managerType = 0,
        } = addressDetails
        const formattedPrefixedOfficeNumber = formatAndPrefix(
          officeNumber,
          LANDLINE_PREFIX,
          true,
        )
        const formattedPrefixedPhone = formatAndPrefix(
          phoneNumber,
          LANDLINE_PREFIX,
          true,
        )
        const { friendlyName, suburb } = addressDetails.address
        const addressInfo = {
          addressWithRating,
          generalInfo: {
            address: `${friendlyName}, ${suburb}`,
            rent: helpers.getDisplayedRent(rate, propertyArrangement),
            occupancy: helpers.calculateDuration(
              startDate,
              endDate,
              isCurrentAddress,
            ),
            arrangement: helpers.propertyArrangementLabels[propertyArrangement],
            reasonForLeaving,
          },
          contact: {
            name: '',
          },
          exceptionRatingQuestions: [],
        }

        if (addressWithRating) {
          const overAllresult = helpers.findRatingResultByCode(
            ratings,
            'overall',
          )

          const pleasantResult = helpers.findRatingResultByCode(
            ratings,
            'pleasant',
          )
          const takeCareResult = helpers.findRatingResultByCode(
            ratings,
            'takes-care',
          )

          const commentResult = helpers.findRatingResultByCode(
            ratings,
            'positive-comment',
          )
          const initialRatingQuestions = ratings.filter(
            (rating) =>
              rating.code !== 'overall' &&
              rating.code !== 'pleasant' &&
              rating.code !== 'takes-care' &&
              rating.code !== 'positive-comment',
          )

          const uniqueIds = new Set()

          const removeDuplicateRatings = ratings.filter((element) => {
            const isDuplicate = uniqueIds.has(element.code)
            uniqueIds.add(element.code)

            if (!isDuplicate) {
              return true
            }

            return false
          })

          let allDetailQuestionCodes = []

          Object.keys(helpers.questionsAccordingRentalHistoryReference).forEach(
            (rentalHistoryKey) => {
              const { questions } =
                helpers.questionsAccordingRentalHistoryReference[
                  rentalHistoryKey
                ]

              const generalCodes = questions.map((question) => question.key)

              const answeredGeneralCodes = questions
                .filter((question) =>
                  removeDuplicateRatings
                    .map((rating) => rating.code)
                    .includes(question.key),
                )
                .map((question) => question.key)

              let detailQuestionsCode = []

              generalCodes.forEach((code) => {
                if (
                  code === 'overall' ||
                  code === 'pleasant' ||
                  code === 'takes-care' ||
                  code === 'positive-comment'
                ) {
                  return
                }
                const detailCode = answeredGeneralCodes.includes(code)
                  ? questions.find((question) => question.key === code).details
                  : []

                detailQuestionsCode = [
                  ...detailQuestionsCode,
                  code,
                  ...detailCode,
                ]
              })

              allDetailQuestionCodes = [
                ...allDetailQuestionCodes,
                ...detailQuestionsCode,
              ]
            },
          )

          let detailQuestionCodeByVersion =
            referenceSetVersion === ratingVersions.march2021.ref
              ? allDetailQuestionCodes
              : ratingCategories.map((category) => category.Code)

          const exceptionRatingQuestions = detailQuestionCodeByVersion.map(
            (code) => {
              const matchedQuestion = ratingCategories.find(
                (ratingCategory) => ratingCategory.Code === code,
              )

              const question = initialRatingQuestions.find(
                (ratingCategory) => ratingCategory.code === code,
              )

              if (question) {
                question.description = matchedQuestion.Description
                question.questionType = matchedQuestion.QuestionType

                return question
              }
              return {
                description: matchedQuestion.Description,
                questionType: matchedQuestion.QuestionType,
              }
            },
          )

          const ratingQuestion = ratings[0]
          const {
            refereeFirstName = '',
            refereeLastName = '',
            createdAt = '',
          } = ratingQuestion || {}
          if (referenceSetVersion < 3) {
            addressInfo.rentalReference = {
              overall: overAllresult.answerValue,
              looksAfterTheProperty: takeCareResult.answerValue,
              pleasantToDealWith: pleasantResult.answerValue,
            }
          } else {
            addressInfo.rentalReference = {
              overall: overAllresult.answerValue,
            }
          }
          addressInfo.exceptionRatingQuestions = exceptionRatingQuestions
          addressInfo.contact = {
            name: `${helpers.capitalizeFirstLetter(
              rentalReferenceFirstName,
            )} ${helpers.capitalizeFirstLetter(rentalReferenceLastName)}`,
            verifier: `${helpers.capitalizeFirstLetter(
              refereeFirstName,
            )} ${helpers.capitalizeFirstLetter(refereeLastName)}`,
            email: `E: ${rentalReferenceEmail}`,
            mobile: formattedPrefixedPhone || formattedPrefixedOfficeNumber,
            date: moment(createdAt).format(helpers.standardMilitaryTime),
            status:
              managerType === 1
                ? managerTypes.agent
                : managerType === 2
                ? managerTypes.owner
                : managerTypes.referee,
          }
          addressInfo.comment = commentResult.answerValue
        }
        return addressInfo
      })

    const incomeTypeCollection = Object.keys(proofOfIncome)
    let incomeInfo = {}
    incomeTypeCollection.forEach((incomeType) => {
      const title =
        incomeType !== 'SavingsBalance'
          ? incomeType.replace(/([a-z])([A-Z])/g, '$1 $2')
          : incomeType
      Object.assign(incomeInfo, { [title]: proofOfIncome[incomeType] })
    })
    const studentsInfo =
      studentDetails &&
      studentDetails.map((studentDetail) => {
        const {
          campusContact = false,
          contactEmail = 'N/A',
          contactName = '',
          contactPhone = 'N/A',
          coordinatorEmail = 'N/A',
          coordinatorMobile = 'N/A',
          coordinatorName = '',
          courseCoordinator = false,
          duration = 0,
          durationType = 0,
          enrolmentNumber = '',
          studentType = 0,
          startDate = '',
          placeOfStudy = '',
        } = studentDetail
        const studentInfo = {
          details: {
            university: placeOfStudy,
            type: helpers.studentTypeToText[studentType],
            enrolmentNumber: enrolmentNumber,
            duration: `${duration} ${
              helpers.durationTypeToText[durationType]
            } starting from ${moment(startDate).format('DD MMM YYYY')}`,
          },
        }
        if (campusContact) {
          studentInfo.campusContact = {
            name: contactName,
            email: contactEmail,
            mobile: contactPhone,
          }
        }
        if (courseCoordinator) {
          studentInfo.courseCoordinator = {
            name: coordinatorName,
            email: coordinatorEmail,
            mobile: coordinatorMobile,
          }
        }
        return studentInfo
      })
    const {
      identityCheckPoints = 0,
      identityDocs = [],
      supportingIdentityDocs = [],
    } = idDocumentsSummary || {}
    let identityInfo = {
      primaryDoc: {},
      identityCheckPoints,
      supportingIdentityDocs: {},
    }
    identityInfo.identityCheckPoints = identityCheckPoints

    if (identityDocs && identityDocs.length > 0) {
      const driverLicenseDoc = helpers.findPrimaryDocumentByType(
        identityDocs,
        helpers.IDDocumentDocType.DriverLicense,
      )
      if (driverLicenseDoc) {
        const {
          docNumber = 0,
          dateOfBirth = '',
          expiryDate = '',
          identityDocAttachments = [],
        } = driverLicenseDoc
        const dobText = dateTimeHelpers.getDateOfBirthText(
          dateTimeHelpers.formatTime(
            dateOfBirth,
            dateTimeHelpers.STANDARD_DATE,
          ),
        )
        identityInfo.primaryDoc.driver_license = {
          number: sensitiveInfoHelpers.hashSensitiveNumber(docNumber),
          date_of_birth: dobText,
          expiry: sensitiveInfoHelpers.hashSensitiveDate(expiryDate),
          attachment: identityDocAttachments,
        }
      }
      const passportDoc = helpers.findPrimaryDocumentByType(
        identityDocs,
        helpers.IDDocumentDocType.Passport,
      )
      if (passportDoc) {
        const {
          country,
          dateOfBirth = '',
          docNumber = 0,
          expiryDate = '',
          identityDocAttachments = [],
        } = passportDoc
        const dobText = dateTimeHelpers.getDateOfBirthText(
          dateTimeHelpers.formatTime(
            dateOfBirth,
            dateTimeHelpers.STANDARD_DATE,
          ),
        )
        identityInfo.primaryDoc.passport = {
          country,
          date_of_birth: dobText,
          expiry: sensitiveInfoHelpers.hashSensitiveDate(expiryDate),
          number: sensitiveInfoHelpers.hashSensitiveNumber(docNumber),
          attachment: identityDocAttachments,
        }
      }
      const hasHighSupportingDoc = helpers.hasSupportingDocumentByType(
        supportingIdentityDocs,
        helpers.IDDocumentDocType.SecondaryHigh,
      )
      if (hasHighSupportingDoc) {
        const highSupportingDoc = helpers.findSupportingDocumentByType(
          supportingIdentityDocs,
          helpers.IDDocumentDocType.SecondaryHigh,
        )
        identityInfo.supportingIdentityDocs['Secondary_(40_points)'] =
          highSupportingDoc.map((doc) => {
            return {
              URL: doc.url,
              name: doc.name,
            }
          })
      }
      const hasMidSupportingDoc = helpers.hasSupportingDocumentByType(
        supportingIdentityDocs,
        helpers.IDDocumentDocType.SecondaryMedium,
      )
      if (hasMidSupportingDoc) {
        const midSupportingDoc = helpers.findSupportingDocumentByType(
          supportingIdentityDocs,
          helpers.IDDocumentDocType.SecondaryMedium,
        )
        identityInfo.supportingIdentityDocs['Secondary_(35_points)'] =
          midSupportingDoc.map((doc) => {
            return {
              URL: doc.url,
              name: doc.name,
            }
          })
      }
      const hasLowSupportingDoc = helpers.hasSupportingDocumentByType(
        supportingIdentityDocs,
        helpers.IDDocumentDocType.SecondaryLow,
      )
      if (hasLowSupportingDoc) {
        const lowSupportingDoc = helpers.findSupportingDocumentByType(
          supportingIdentityDocs,
          helpers.IDDocumentDocType.SecondaryLow,
        )
        identityInfo.supportingIdentityDocs['Secondary_(25_points)'] =
          lowSupportingDoc.map((doc) => {
            return {
              URL: doc.url,
              name: doc.name,
            }
          })
      }
    }
    const otherAttachmentsInfo = { otherAttachments }
    const petInfo =
      pets &&
      pets.map((pet) => {
        const {
          type,
          breed,
          name,
          dateOfBirth,
          outdoor,
          indoor,
          description,
          isRegistered,
          registrationNumber,
          petReferences = [],
        } = pet
        //TODO: fix hack
        const invalidDate = moment(DEFAULT_UNIX_TIME, 'YYYY-MM-DD')
        const isBirthDateValid = moment(
          pet.dateOfBirth,
          DATE_WITH_DASH,
        ).isAfter(invalidDate)
        const petAge = moment().diff(dateOfBirth, 'years')
        return {
          label: `${name} (${helpers.petTypes[type]} ${breed})`,
          text: `${isBirthDateValid && `${petAge} y/o,`}${
            indoor ? ' indoor' : ''
          } ${indoor && outdoor ? 'and' : ''} ${outdoor ? 'outdoor' : ''} ${
            description ? `, ${description}` : ''
          }`,
          registration_number: isRegistered
            ? registrationNumber
            : 'Unregistered',
          references: petReferences.length > 0 ? 'Yes' : 'No',
        }
      })
    let petsNote
    if (!pets || pets.length === 0) {
      petsNote = preference.optOutPets ? (
        <>
          <b>{applicantInfo.name}</b> has noted they <b>do not have any pets</b>
        </>
      ) : (
        'Not provided'
      )
    }
    const { optOutBackgroundCheck = false } = preference
    return (
      <div>
        <div className="display-flex justify-content-center">
          <div>
            {isApplyAnywhere && (
              <div className="applyanywhere-container pdf-hide flex-direction-column align-items-start">
                <div>
                  <p className="font-size-22 mb8">
                    Application for {applicantInfo.address}
                  </p>
                  <Box mb="16px">
                    <span className="mr10 font-size-18">Applicants: </span>
                    {allApplicants &&
                      allApplicants.map((applicant) => {
                        const isPrimary = applicant.isPrimary
                        const hasApplied = applicant.applied
                        const hasNotApplied = !hasApplied
                        const showAsLink =
                          applicant.guidID !== selectedApplicantGUID &&
                          hasApplied

                        return (
                          <span key={applicant.guidID}>
                            <span
                              className={`mr10 font-weight600 font-size-18 ${
                                showAsLink ? 'blue-link-style' : ''
                              }`}
                              onClick={() => {
                                showAsLink &&
                                  this.handleApplicantChange(applicant.guidID)
                              }}
                            >
                              {applicant.firstName}
                              {isPrimary ? ' (Primary) ' : ' '}
                              {hasNotApplied ? ' (Invited) ' : ' '}
                            </span>
                          </span>
                        )
                      })}
                    <span>
                      {showSpinner && <i className="fa fa-spinner fa-pulse" />}
                    </span>
                  </Box>
                </div>
                <ButtonContainer className="width-auto">
                  <Display.GenericButton
                    onClick={() => {
                      window.print()
                    }}
                  >
                    Download Application
                  </Display.GenericButton>
                </ButtonContainer>
              </div>
            )}
            <div className="App pdf-report-container">
              <ErrorMessage error={apiError} />
              {!apiError && (
                <Container>
                  <SnugPdf1
                    applicantInfo={applicantInfo}
                    backgroundCheck={backgroundCheck}
                    applicationSummary={applicationSummary}
                    householdInfo={householdInfo}
                    identityInfo={identityInfo}
                    optOutBackgroundCheck={optOutBackgroundCheck}
                    hasLeaseDetailsBeenModified={hasLeaseDetailsBeenModified}
                    modifiedApplicationSummary={modifiedApplicationSummary}
                    agencyInfo={agencyInfo}
                    currentUser={currentUser}
                    applicationPropertyState={state}
                  />
                  <div className="mt20">
                    <SnugPdf2
                      employmentInfo={employmentInfo}
                      addressHistoryInfo={addressHistoryInfo}
                      excludeRequestReasonForMoving={
                        excludeRequestReasonForMoving
                      }
                    />
                  </div>
                  <div className="mt20">
                    <SnugPdf4
                      contacts={contacts}
                      incomeInfo={incomeInfo}
                      studentsInfo={studentsInfo}
                      otherAttachmentsInfo={otherAttachmentsInfo}
                      otherInfo={otherInfo}
                      petInfo={petInfo}
                      petsNote={petsNote}
                      complianceInfo={complianceInfo}
                      applicationNotes={applicationNotes}
                      chosenApplicant={chosenApplicant}
                      enableEnhancedDisclosure={enableEnhancedDisclosure}
                      agencyInfo={agencyInfo}
                    />
                  </div>
                  {applicationId && property?.agencyID && (
                    <ApplicationTimelinePrint
                      applicationId={applicationId}
                      applicantId={applicantId}
                      teamId={property.agencyID}
                    />
                  )}
                </Container>
              )}
            </div>
          </div>
          {isApplyAnywhere &&
            (sharedHelpers.isMobile() ? (
              <div className="pdf-hide">
                <Banner onClick={() => this.toggleModal('isBannerModalOpen')} />
                {isBannerModalOpen && (
                  <Display.Modal
                    toggleModal={() => this.toggleModal('isBannerModalOpen')}
                    hideButtonSection
                    modalSubheaderClass="display-none"
                    modalBodyClass="mt0 p0 banner-modal-body"
                  >
                    <LeadCaptureForm
                      isInModal
                      isFormModalOpen={isFormModalOpen}
                      toggleFormModal={() =>
                        this.toggleModal('isFormModalOpen')
                      }
                    />
                  </Display.Modal>
                )}
              </div>
            ) : (
              <div className="pdf-hide">
                <LeadCaptureForm
                  isFormModalOpen={isFormModalOpen}
                  toggleFormModal={() => this.toggleModal('isFormModalOpen')}
                />
              </div>
            ))}
        </div>
        {isApplyAnywhere && (
          <div className="pdf-hide">
            <SiteInformation
              isAA
              onClickFreeTrial={() =>
                this.toggleModal(
                  sharedHelpers.isMobile()
                    ? 'isBannerModalOpen'
                    : 'isFormModalOpen',
                )
              }
            />
          </div>
        )}
      </div>
    )
  }
}

export default App
