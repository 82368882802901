import React, { useContext, useEffect, useState } from 'react'

import { isValidNumber } from 'libphonenumber-js'
import qs from 'qs'

import { Box } from 'app/components/design-system-components'
import {
  InputField,
  TelField,
} from 'app/components/design-system-components/inputs'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { register, updateRegistrationInfo } from 'app/services/http/session'
import * as snugNotifier from 'app/services/snugNotifier'
import {
  AUTH_VERSION_2,
  FIELD_EMAIL,
  FIELD_EMAIL_CONFIRMATION,
  FIELD_FIRST_NAME,
  FIELD_LAST_NAME,
  FIELD_MOBILE,
  FIELD_MOBILE_CONFIRMATION,
  FIELD_PASSWORD,
  STEP_ENTER_DETAILS,
  STEP_ENTER_DETAILS_CONFIRMATION,
  STEP_UPDATE_REGISTRATION_INFO,
} from 'app/session/join/join-snug.constants'
import { JoinSnugContext } from 'app/session/join/JoinContext'
import { BaseForm } from 'app/session/join/partials'
import StyledFieldWrapper from 'app/session/join/partials/styled_field'
import { registerSuccess } from 'app/session/session_actions'
import { validateEmail } from 'app/shared_components/validations'
import * as validations from 'app/shared_components/validations'
import { urlIds, urlTo } from 'app/sm/helpers'
import * as textHelpers from 'app/utils/text/helpers'

// eslint-disable-next-line no-empty-pattern
const EnterDetailsForm = ({}) => {
  const [invitationDetails, setInvitationDetails] = useState({
    isOffshore: false,
    invitationID: '',
  })

  const {
    currentStep,
    setCurrentStep,
    accountCheckedKey,
    gCaptcha,
    setError,
    prefillDetails,
    registerToken,
    internationalMobilesAllowed,
  } = useContext(JoinSnugContext)

  useEffect(() => {
    const { offshore, invitation } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    if (offshore && invitation) {
      setInvitationDetails({
        isOffshore: !!offshore,
        invitationID: invitation,
      })
    }
  }, [])

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    mobileConfirmation: '',
    emailConfirmation: '',
    password: '',

    ...prefillDetails,
    ...(accountCheckedKey && accountCheckedKey.name
      ? { [accountCheckedKey.name]: accountCheckedKey.value }
      : {}),
  }

  const showConfirmationFields = () => {
    return [
      STEP_ENTER_DETAILS_CONFIRMATION,
      STEP_UPDATE_REGISTRATION_INFO,
    ].includes(currentStep)
  }

  const validConfirmation = ({ values }, field, confirmedField) => {
    return values[field] === values[confirmedField]
  }

  const handleSubmit = (values, { setSubmitting }) => {
    if (currentStep === STEP_ENTER_DETAILS_CONFIRMATION && !gCaptcha) {
      snugNotifier.error('Please check captcha')
      setSubmitting(false)
      return
    }

    setError('')

    if (currentStep === STEP_UPDATE_REGISTRATION_INFO) {
      return updateRegistrationInfo(values)
        .then((res) => {
          registerSuccess(urlTo(urlIds.join.verify.mobile))(res)
        })
        .catch((err) => [setError(err.message || 'Failed to update details')])
        .finally(() => setSubmitting(false))
    }

    if (currentStep === STEP_ENTER_DETAILS) {
      return new Promise((resolve) => {
        setCurrentStep(STEP_ENTER_DETAILS_CONFIRMATION)
        resolve()
      })
    }

    if (
      !validConfirmation({ values }, FIELD_MOBILE_CONFIRMATION, FIELD_MOBILE) ||
      !validConfirmation({ values }, FIELD_EMAIL_CONFIRMATION, FIELD_EMAIL)
    ) {
      setSubmitting(false)
      return
    }

    const payload = {
      ...values,
      gRecaptchaResponse: gCaptcha,
      authVersion: AUTH_VERSION_2,
      registrationToken: registerToken,
    }

    const isFromRR = window.location.pathname.endsWith(
      textHelpers.SLASH_RENTAL_REFERENCE_URL_STR,
    )

    return new Promise((resolve, reject) => {
      register({ ...payload, ...invitationDetails })
        .then((res) => {
          const redirectPath = isFromRR
            ? urlTo(urlIds.join.verify.rr)
            : urlTo(urlIds.join.verify.mobile)

          resolve()
          registerSuccess(redirectPath)(res)
        })
        .catch((err) => {
          setSubmitting(false)
          reject()
          setError(err.message || 'Failed to register.')
        })
    })
  }

  const validateMobile = (value) => {
    if (!value && invitationDetails.isOffshore) return []
    if (invitationDetails.isOffshore || internationalMobilesAllowed) {
      const isValid = isValidNumber(
        value.replaceAll('-', '').replaceAll(' ', ''),
      )
      return isValid ? [] : ['Please enter a valid number']
    }
    return validations.validateMobile(value)
  }

  const mobileRequired = !invitationDetails.isOffshore

  const validatePassword = (value) => {
    if (!value) return []

    const errors =
      validations.validateNewPassword(value)?.length > 0
        ? [validations.PASSWORD_INVALID_MSG]
        : []
    return errors
  }

  const validate = (values) => {
    return formValidationBuilder((field, val) => {
      switch (field) {
        case FIELD_MOBILE:
          return {
            required:
              !val &&
              mobileRequired &&
              'Please enter your mobile number, e.g +61 412 345 678',
            invalid: val && validateMobile(val).join(', '),
          }
        case FIELD_EMAIL:
          return {
            required: !val && 'Please enter your email',
            invalid: val && validateEmail(val)[0] && 'Please enter valid email',
          }
        case FIELD_FIRST_NAME:
          return {
            required: !val && 'Please enter first name',
          }
        case FIELD_LAST_NAME:
          return {
            required: !val && 'Please enter last name',
          }
        case FIELD_PASSWORD:
          return {
            required: !val && 'Please enter your account password',
            invalid: val && validatePassword(val)[0],
          }
        case FIELD_EMAIL_CONFIRMATION:
          return {
            invalid:
              currentStep === STEP_ENTER_DETAILS_CONFIRMATION &&
              values[FIELD_EMAIL] !== val &&
              'Please make sure email fields are the same',
          }
        case FIELD_MOBILE_CONFIRMATION:
          return {
            invalid:
              currentStep === STEP_ENTER_DETAILS_CONFIRMATION &&
              values[FIELD_MOBILE] !== val &&
              'Please make sure mobile fields are the same',
          }
        default:
          return {}
      }
    })(values)
  }

  const bodyRenderer = ({ values }) => {
    return (
      <>
        <Box mb={3}>
          <StyledFieldWrapper id={FIELD_FIRST_NAME} name={FIELD_FIRST_NAME}>
            <InputField
              id={FIELD_FIRST_NAME}
              name={FIELD_FIRST_NAME}
              inputProps={{ placeholder: 'First name' }}
              height="50px"
            />
          </StyledFieldWrapper>
        </Box>

        <Box mb={3}>
          <StyledFieldWrapper id={FIELD_LAST_NAME} name={FIELD_LAST_NAME}>
            <InputField
              id={FIELD_LAST_NAME}
              name={FIELD_LAST_NAME}
              inputProps={{ placeholder: 'Last name' }}
              height="50px"
            />
          </StyledFieldWrapper>
        </Box>

        <Box mb={3}>
          <StyledFieldWrapper id={FIELD_MOBILE} name={FIELD_MOBILE}>
            <TelField
              name={FIELD_MOBILE}
              placeholder="Mobile"
              onlyCountries={['au']}
              defaultCountry={'au'}
              international={internationalMobilesAllowed}
              height="50px"
            />
          </StyledFieldWrapper>
        </Box>

        {showConfirmationFields() && (
          <Box mb={3}>
            <StyledFieldWrapper
              id={FIELD_MOBILE_CONFIRMATION}
              name={FIELD_MOBILE_CONFIRMATION}
              validConfirmation={validConfirmation(
                { values },
                FIELD_MOBILE_CONFIRMATION,
                FIELD_MOBILE,
              )}
            >
              <TelField
                height="50px"
                name={FIELD_MOBILE_CONFIRMATION}
                placeholder="Mobile confirmation"
                onlyCountries={['au']}
                defaultCountry={'au'}
              />
            </StyledFieldWrapper>
          </Box>
        )}
        <Box mb={3}>
          <StyledFieldWrapper id={FIELD_EMAIL} name={FIELD_EMAIL}>
            <InputField
              id={FIELD_EMAIL}
              name={FIELD_EMAIL}
              inputProps={{ placeholder: 'Email' }}
              height="50px"
            />
          </StyledFieldWrapper>
        </Box>

        {showConfirmationFields() && (
          <Box mb={3}>
            <StyledFieldWrapper
              id={FIELD_EMAIL_CONFIRMATION}
              name={FIELD_EMAIL_CONFIRMATION}
              validConfirmation={validConfirmation(
                { values },
                FIELD_EMAIL_CONFIRMATION,
                FIELD_EMAIL,
              )}
            >
              <InputField
                id={FIELD_EMAIL_CONFIRMATION}
                name={FIELD_EMAIL_CONFIRMATION}
                inputProps={{ placeholder: 'Email Confirmation' }}
                height="50px"
              />
            </StyledFieldWrapper>
          </Box>
        )}

        <Box mb={3}>
          <StyledFieldWrapper id={FIELD_PASSWORD} name={FIELD_PASSWORD}>
            <InputField
              id={FIELD_PASSWORD}
              name={FIELD_PASSWORD}
              inputProps={{ placeholder: 'Password', type: 'password' }}
              height="50px"
            />
          </StyledFieldWrapper>
        </Box>
      </>
    )
  }

  const submitButtonText = () => {
    return currentStep === STEP_UPDATE_REGISTRATION_INFO
      ? 'Update Registration Info'
      : 'Continue'
  }

  const shouldDisableSubmit =
    currentStep === STEP_ENTER_DETAILS_CONFIRMATION && !gCaptcha

  return (
    <BaseForm
      initialValues={initialValues}
      submitHandler={handleSubmit}
      validator={validate}
      bodyRenderer={bodyRenderer}
      submitButtonText={submitButtonText()}
      disableSubmit={shouldDisableSubmit}
    />
  )
}

export default EnterDetailsForm
