import { accountsApi, api } from 'app/utils/api/helpers'

export const updateRegistrationInfo = (info) => {
  return accountsApi.put('/update-registration', info)
}

export const resendOTPSMS = () => {
  return accountsApi.get(`send/sms`)
}

export const resendOTPEmail = () => {
  return accountsApi.get(`send/email`)
}

export const forgottenEmail = (payload) => {
  return accountsApi.post('/reset/email/forgotten', payload)
}

export const validateResetToken = (token) => {
  return accountsApi.post(`/reset/token/${token}/validate`)
}

export const checkAccountExistence = (payload) => {
  return accountsApi.post('/join/check', payload)
}

export const login = (payload) => {
  return accountsApi.post('/join', payload)
}

export const register = (payload) => {
  payload.email = payload.email.toLowerCase()
  return accountsApi.post('/register/email', payload)
}

export const verify = (payload) => {
  return accountsApi.post('/verify/mobile', payload)
}
