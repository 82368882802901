import { defaults } from 'lodash'
import moment from 'moment'

import * as ACTIONS from 'app/pages/teams/viewings-run/action'
import { modeDriving } from 'app/pages/teams/viewings-run/components/schedule-list'

const afterOneHour = moment().add(1, 'hour')
const momentMinuteAfterOneHour = afterOneHour.minute()
const minute15After1Hour = 15 * Math.ceil(momentMinuteAfterOneHour / 15)
const setTime = moment(afterOneHour).set('minutes', minute15After1Hour)

const DEFAULT_MANAGER_PREFERENCES = {
  startTime: setTime,
  travelMode: modeDriving,
  scheduleBreaks: false,
  breakDuration: 0,
  viewingDuration: 15,
  startPropertyGUID: '',
  endPropertyGUID: '',
  properties: {},
  isReadOnly: false,
  viewingsPublished: true,
}

const DEFAULT_NEW_VIEWINGS_RUN_STATE = {
  hasUpdates: false,
  allManagersSelected: [],
  managerPreferences: {},
  selectedPropertyInfos: [],
  startTime: moment().hour(9).minute(0).second(0),
}

const NewViewingsRunReducer = (
  state = DEFAULT_NEW_VIEWINGS_RUN_STATE,
  action,
) => {
  const {
    managerDetails,
    managerGUIDAndPreference,
    property,
    time,
    isReadOnly,
    managerGUID,
    type,
  } = action

  switch (type) {
    case ACTIONS.ADD_MANAGER_DETAILS: {
      const { managerDetails, preferences } = action
      return {
        ...state,
        hasUpdates: true,
        allManagersSelected: [...state.allManagersSelected, managerDetails],
        managerPreferences: {
          ...state.managerPreferences,
          [managerDetails.managerGUID]: defaults(
            preferences || {},
            DEFAULT_MANAGER_PREFERENCES,
          ),
        },
      }
    }
    case ACTIONS.UPDATE_MANAGER_PREFERENCES:
      return {
        ...state,
        hasUpdates: true,
        managerPreferences: {
          ...state.managerPreferences,
          [managerGUIDAndPreference.managerGUID]: {
            ...state.managerPreferences[managerGUIDAndPreference.managerGUID],
            [managerGUIDAndPreference.managerPreferenceField]:
              managerGUIDAndPreference.managerPreferenceValue,
          },
        },
      }
    case ACTIONS.ADD_SELECTED_PROPERTY_INFOS:
      return {
        ...state,
        hasUpdates: true,
        selectedPropertyInfos: property,
      }
    case ACTIONS.REMOVE_SELECTED_PROPERTY_INFOS:
      return {
        ...state,
        hasUpdates: true,
        selectedPropertyInfos: property,
      }
    case ACTIONS.UPDATE_VIEWING_RUN_START_TIME:
      return {
        ...state,
        hasUpdates: true,
        startTime: time,
      }
    case ACTIONS.UPDATE_VIEWING_RUN_READ_ONLY:
      return {
        ...state,
        isReadOnly,
      }
    case ACTIONS.DELETE_MANAGER_DETAILS:
      let managerPreferenceWithDeletedManager = state.managerPreferences
      delete managerPreferenceWithDeletedManager[managerGUID]
      return {
        ...state,
        hasUpdates: true,
        allManagersSelected: state.allManagersSelected.filter(
          (manager) => manager.managerGUID !== managerGUID,
        ),
        managerPreferences: managerPreferenceWithDeletedManager,
      }
    case ACTIONS.DELETE_MANAGER_STATE:
      return {
        hasUpdates: true,
        ...DEFAULT_NEW_VIEWINGS_RUN_STATE,
      }
    case ACTIONS.POPULATE_VIEWING_RUN_DATA: {
      const {
        runData: { startTime, selectedProperties, managers },
      } = action

      const managersData = managers.reduce(
        (
          { allManagersSelected, managerPreferences },
          { managerDetails, preferences },
        ) => {
          return {
            allManagersSelected: [...allManagersSelected, managerDetails],
            managerPreferences: {
              ...managerPreferences,
              [managerDetails.managerGUID]: defaults(
                preferences || {},
                DEFAULT_MANAGER_PREFERENCES,
              ),
            },
          }
        },
        {
          allManagersSelected: [],
          managerPreferences: {},
        },
      )

      return {
        ...state,
        startTime,
        selectedPropertyInfos: selectedProperties,
        ...managersData,
      }
    }
    case ACTIONS.RESET_VIEWING_RUN_STATE: {
      return {
        ...DEFAULT_NEW_VIEWINGS_RUN_STATE,
      }
    }
    default:
      return state
  }
}

export default NewViewingsRunReducer
