import React from 'react'

import moment from 'moment'

import { DropdownItem } from 'app/shared_components/AvatarAndNameDropdown'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

const STATUS_ACCEPTED = 'accepted'

const MembersList = ({
  teamMembersList,
  showToolsButton = false,
  memberTools,
  onClickHandler,
  showAvatar,
}) => {
  return (
    <div>
      {teamMembersList &&
        teamMembersList.map((teamMember) => {
          const memberName = teamMember.name
          const [firstName, ...restName] = memberName.split(' ')
          const { guid = '' } = teamMember

          const statusAccepted = teamMember.status === STATUS_ACCEPTED
          const translatedMemberData = {
            firstName,
            lastName: restName.join(' '),
            email: teamMember.email,
            mobileNumber: teamMember.mobile,
            status: teamMember.status,
            statusDate: moment(teamMember.statusDate).format(
              dateTimeHelpers.INVITED_DATE_FORMAT,
            ),
            isAdmin: teamMember.isAdmin,
            statusAccepted,
            guid,
          }
          return (
            <DropdownItem
              item={translatedMemberData}
              showStatusPills={false}
              showDates={true}
              fullNameClassName="fs18"
              showToolsButton={showToolsButton}
              memberTools={memberTools}
              onClickHandler={onClickHandler}
              showAvatar={showAvatar}
            />
          )
        })}
    </div>
  )
}

export default MembersList
