import React, { Component } from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { fetchNotes } from 'app/agency/agency_action'
import viewingsClosed from 'app/assets/icons/container-closed.svg'
import viewingsOpen from 'app/assets/icons/container-open.svg'
import privateLock from 'app/assets/icons/private-lock.svg'
import warningIcon from 'app/assets/icons/warning-lighter.svg'
import { Flex, TextSpan } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import RegisteredViewingNumBadge from 'app/components/display/registered-viewing-num'
import { ViewingEntryNoticesStatusAndDetailsCTA } from 'app/components/viewings/ViewingEntryNoticesStatusAndDetailsCTA'
import { PropertyKeysInfoModal } from 'app/features/teams/key-logger/PropertyKeysInfoModal'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  AlertIcon,
  BoldText,
  DateTimeInRow,
  DesktopOnly,
  MobileTabletOnly,
  Row,
  ViewingFurtherActionsContainer,
  WidthPercentage,
} from 'app/pages/teams/viewings/styles'
import { ViewingDetailsContainer } from 'app/pages/teams/viewings/ViewingDetails/connection'
import Theme from 'app/pages/teams/viewings-mobile/theme-styled-components'
import * as snugNotifier from 'app/services/snugNotifier'
import DownloadIcs from 'app/shared_components/download_ics'
import {
  convertDateTimeToTime,
  convertDateToUTCTimestampFormat,
  getCarSpaceCountForProperty,
  lowerCaseTimeFormat,
  noteActionType,
  onlistModalCodes,
  targetSectionObject,
  utcFormatTimeStamp,
} from 'app/sm/helpers'
import AddAccessDetailModal from 'app/sm/inspections/components/add_access_details_modal_connection'
import AddNoteModal from 'app/sm/inspections/components/add_notes_modal_connection'
import AssignViewingTeamMemberModal from 'app/sm/inspections/components/assign_viewing_team_member_modal'
import AttendeeHubModal from 'app/sm/inspections/components/attendee_hub_modal'
import LateForViewingModal from 'app/sm/inspections/components/late_for_viewing_modal'
import TenantReadModal, {
  DATE_ENTRY_NOTICES_RELEASED,
  getAllFailedOrUnopenedStatuses,
} from 'app/sm/inspections/components/tenant_read_modal'
import WalkinRenterModal from 'app/sm/inspections/components/walkin_renter_modal'
import { formatPhoneNumber } from 'app/utils/phonenumber/helpers'
import * as viewingsHelpers from 'app/utils/viewings/helpers'

const StyledPrintWidthFlex = styled(Flex)`
  @media print {
    width: ${({ widthPrint }) => (widthPrint ? widthPrint : 'auto')} !important;
    .sm {
      line-height: 1.35;
      font-size: 12px;
    }
    align-items: baseline !important;
    flex-direction: column;
    align-self: baseline !important;
  }
`

class ViewingRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rowOpen: false,
      dropDown: '',
      requestFeedbackError: '',
      isAddAttendeeHubModalOn: false,
      isWalkInModalOn: false,
      isTenantModalActive: false,
      isAddNoteModalOn: false,
      isAccessDetailModalOn: false,
      isLateModalOn: false,
      filteredEnquirersForAttendeeHub: [],
      walkinModalStatus: 'edit',
      targetAttendee: {},
      addAttendeePreloadContent: {},
      propertyInfo: {},
      apiError: '',
      viewingAttendeeCount: 0,
      totalCheckedInAttendeeCount: 0,
      disableSaveButton: false,
      openModalForTeamMember: false,
      viewingSelected: {},
      propertyDetails: {
        contactNumber: '',
        weeklyRent: 0,
        availableFrom: '',
        bedroom: 0,
        bathroom: 0,
        car: 0,
      },
      tenantsWhoReadTheEntryNotice: [],
      tenantReadModal: false,
    }
  }

  componentDidMount() {
    const { fetchPropertyReport, data } = this.props
    if (data.viewing.guidID) {
      fetchPropertyReport(data.viewing.guidID).then((result) => {
        const {
          bedrooms,
          bathrooms,
          carports,
          garages,
          parking,
          offers,
          guidID,
        } = result.property
        this.setState({
          propertyDetails: {
            contactNumber:
              data.assignedTeamMembers.length > 0
                ? data.assignedTeamMembers[0].phone
                : '',
            weeklyRent: offers.length > 0 ? offers[0].weeklyRent : 0,
            availableFrom: offers.length > 0 ? offers[0].availableFrom : 0,
            bedroom: bedrooms,
            bathroom: bathrooms,
            car: getCarSpaceCountForProperty({
              carports,
              garages,
              parking,
            }),
            guidID,
            offers,
          },
        })
      })
    }
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevProp.data !== this.props.data) {
      const { data } = this.props
      this.setState((prevState) => ({
        propertyDetails: {
          ...prevState.propertyDetails,
          contactNumber:
            data.assignedTeamMembers.length > 0
              ? data.assignedTeamMembers[0].phone
              : '',
        },
      }))
    }
  }

  onAddWalkInRenter = (
    payload,
    propertyId,
    viewingId = this.props.data.summary.viewingGUID,
    shouldSendInvite = false,
  ) => {
    const { addWalkInRenter, updateAllViewingsData } = this.props
    if (shouldSendInvite) {
      const { checkIn } = payload
      if (!checkIn) {
        snugNotifier.error(`Please Check-in attendee then Invite to Apply`)
        return
      }
    }
    this.setState({
      disableSaveButton: true,
    })
    setTimeout(() => {
      addWalkInRenter(payload, propertyId, viewingId)
        .then((data) => {
          const { registrant } = data
          this.setState({
            viewingAttendeeCount: this.state.viewingAttendeeCount + 1,
            apiError: '',
            registrant,
            disableSaveButton: false,
            isWalkInModalOn: false,
            isAddAttendeeHubModalOn: true,
          })
          snugNotifier.success('Added successfully')
          if (shouldSendInvite && data) {
            const { firstName } = registrant
            this.sendInviteToApplyToRegistrant(registrant, firstName)
          }
          updateAllViewingsData()
        })
        .then(() => {
          if (payload && payload.externalComment) {
            const { registrant } = this.state
            const notePayload = {
              content: payload.externalComment,
              type: noteActionType.viewingAttendee,
              target: targetSectionObject.viewingReport['index'],
            }
            this.callCreateNote(notePayload, registrant.guidID)
          }
        })
        .catch((error) => {
          snugNotifier.error(error)
          this.setState({
            disableSaveButton: false,
          })
        })
    }, 200)
    return Promise.resolve()
  }

  onAttendeeHubModalAddIconClicked = (
    nameSearchText = '',
    mobileSearchText = '',
  ) => {
    const addAttendeePreloadContent = { nameSearchText, mobileSearchText }
    this.setState({
      isWalkInModalOn: true,
      walkinModalStatus: 'create',
      isAddAttendeeHubModalOn: false,
      addAttendeePreloadContent,
    })
  }

  onClickEntryNotices = (entryNoticeRecordsSummary = undefined) => {
    const { tenantReadModal } = this.state
    this.setState({
      tenantReadModal: !tenantReadModal,
    })
    if (entryNoticeRecordsSummary) {
      this.setState({
        tenantsWhoReadTheEntryNotice: entryNoticeRecordsSummary.recipients,
      })
    }
  }

  onModalTitleClicked = (field) => {
    this.turnOffAllModals(() =>
      this.setState({
        [field]: true,
      }),
    )
  }

  onNotifyLateViewing = (data) => {
    const { notifyLateViewing } = this.props
    notifyLateViewing(data)
      .then(() => {
        this.setState({ isLateModalOn: false })
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  onSearchAttendeeLinkClicked = () => {
    this.setState({
      isWalkInModalOn: false,
      isAddAttendeeHubModalOn: true,
    })
  }

  onShowLateViewingModal = (status, viewing) => {
    const { showLateViewingModal, data } = this.props
    const { registrations } = data
    const checkedInRegistrations = registrations.filter((attendee) => {
      return attendee.viewingOnsiteRegistrant.checkIn
    })

    const totalCheckedInAttendeeCount = checkedInRegistrations.length

    showLateViewingModal(status, viewing)
    this.setState({
      isLateModalOn: status,
      totalCheckedInAttendeeCount,
    })
  }

  onToggleAttendeeHubModal = (isOpen, viewing, onsiteRegistrants) => {
    const { toggleWalkInModal } = this.props
    const { propertyViewings, enquirers } = this.state
    toggleWalkInModal(false, viewing)
    let filteredEnquirers = []
    if (viewing && propertyViewings) {
      filteredEnquirers =
        enquirers && enquirers.length > 0
          ? enquirers
              .filter(
                (enquirer) =>
                  !onsiteRegistrants.find(
                    (onsiteRegistrant) =>
                      onsiteRegistrant.viewingOnsiteRegistrant.mobilePhone ===
                      enquirer.phone,
                  ),
              )
              .filter(
                (enquirer) =>
                  !onsiteRegistrants.find(
                    (onsiteRegistrant) =>
                      onsiteRegistrant.viewingOnsiteRegistrant.email ===
                      enquirer.email,
                  ),
              )
          : []
    }
    this.setState({
      isAddAttendeeHubModalOn: isOpen,
      filteredEnquirersForAttendeeHub: filteredEnquirers,
    })
  }

  onToggleWalkInModal = (status, viewing) => {
    const { toggleWalkInModal } = this.props
    toggleWalkInModal(status, viewing)
    this.setState({
      isWalkInModalOn: status,
      walkinModalStatus: 'create',
    })
  }

  confirmCancelViewing(guidID, propertyId) {
    const { updateAllViewingsData } = this.props
    if (window.confirm(viewingsHelpers.CANCEL_VIEWING_CONFIRM_TEXT)) {
      this.props
        .cancelViewing(guidID, propertyId)
        .then(() => {
          updateAllViewingsData()
        })
        .catch((error) => {
          this.setState({ apiError: error })
        })
    }
  }

  toggleRow = (viewingGUID) => {
    this.setState((prevState) => ({
      rowOpen: !prevState.rowOpen,
    }))
    if (this.state.dropDown === viewingGUID) {
      this.setState({ dropDown: '', requestFeedbackError: '' })
    } else {
      this.setState({ dropDown: viewingGUID, requestFeedbackError: '' })
    }
    this.setState({
      activeViewingID: viewingGUID,
    })
  }

  toggleTeamMemberModal = (viewing) => {
    this.setState(
      {
        openModalForTeamMember: !this.state.openModalForTeamMember,
        viewingSelected: viewing,
      },
      () => {
        if (!this.state.openModalForTeamMember) {
          this.props.fetchViewingsByDateFromTeamId()
        }
      },
    )
  }

  turnOffAllModals = (callback = () => {}) => {
    this.setState(
      {
        isEditViewModalOn: false,
        isAddAttendeeHubModalOn: false,
        isWalkInModalOn: false,
        isLateModalOn: false,
        isAddNoteModalOn: false,
        isAccessDetailModalOn: false,
      },
      () => callback(),
    )
  }

  render() {
    const { isAddAttendeeHubModalOn } = this.state
    const {
      data,
      updateAllViewingsData,
      clearError,
      fetchAgencyApplicantsElasticSearchResult,
      renterInfo = {},
      teamGUID,
      assignViewingToTeamMember,
      fetchViewingTeamMembers,
      deleteViewingTeamMember,
      updateViewingTeamMemberRelation,
      managersListForViewingFilters,
      viewingEntryNoticeEnabled,
      isViewingReportSmsEnabled,
      isViewingReportEmailEnabled,
      currentTeam = {},
    } = this.props
    const {
      summary,
      viewing,
      renters,
      registrations,
      assignedTeamMembers,
      propertyKeySets,
      activeOffer,
      onListKeycode,
    } = data
    let assignedTeamMembersCountMinusOne = 0
    if (assignedTeamMembers.length > 0) {
      assignedTeamMembersCountMinusOne = assignedTeamMembers.length - 1
    }
    const { propertyGUID, viewingGUID } = summary
    const enabledOptionsForWalkinModal = {
      enableContactInfomation: true,
      enableCheckedInAndInterested: true,
      enablePreference: true,
      enableNote: true,
      showSearchButton: true,
      enableViewingBottomButtons: true,
    }
    const {
      rowOpen,
      activeViewingID,
      enquirers,
      filteredEnquirersForAttendeeHub,
      isWalkInModalOn,
      walkinModalStatus,
      targetAttendee,
      addAttendeePreloadContent,
      isAddNoteModalOn,
      isAccessDetailModalOn,
      apiError,
      viewingAttendeeCount,
      isLateModalOn,
      totalCheckedInAttendeeCount,
      disableSaveButton,
      openModalForTeamMember,
      propertyDetails,
      tenantReadModal,
      tenantsWhoReadTheEntryNotice,
    } = this.state
    const {
      entryNoticeRecordsSummary,
      isViewingReportSmsSent,
      isViewingReportEmailSent,
      fullyBooked,
      totalRegistered = 0,
      viewingCap = 0,
    } = viewing
    const startDateInBETimeFormat = moment(summary.dateTime, utcFormatTimeStamp)
    const startDate = summary.dateTime
    const endDate = startDateInBETimeFormat
      .add(summary.duration, 'minutes')
      .format(utcFormatTimeStamp)
    const confirmedAttendeeAll = registrations.filter((attendee) => {
      return (
        attendee.viewingOnsiteRegistrant.confirmationStatus ===
        viewingsHelpers.StatusAttendeeConfirmed
      )
    })
    const registeredAttendees = registrations.filter((attendee) =>
      viewingsHelpers.isActiveRegistrant(attendee),
    )
    const registeredAttendeesCount = registeredAttendees
      ? registeredAttendees.length
      : 0
    const confirmedAttendee = confirmedAttendeeAll.length
    const event = {
      title: `Viewing at ${summary.address}`,
      location: `${summary.address}`,
      description: '',
      startTime: convertDateToUTCTimestampFormat(startDate),
      endTime: convertDateToUTCTimestampFormat(endDate),
    }

    const { contactNumber, weeklyRent, availableFrom, bedroom, bathroom, car } =
      propertyDetails

    const isEntryNoticeEmailNotOpened =
      entryNoticeRecordsSummary &&
      entryNoticeRecordsSummary.receipts &&
      entryNoticeRecordsSummary.receipts.filter((recepient) =>
        getAllFailedOrUnopenedStatuses(recepient.status),
      ).length > 0 &&
      moment(startDate).isAfter(DATE_ENTRY_NOTICES_RELEASED)

    const entryNoticesBlock = (
      <ViewingEntryNoticesStatusAndDetailsCTA
        entryNoticeRecordsSummary={entryNoticeRecordsSummary}
        openDetailsHandler={this.onClickEntryNotices}
        uniqueId={viewing.guid}
        isViewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
      />
    )
    const showRegisteredViewingNumBadge = fullyBooked
    const teamSlug = currentTeam?.slug
    return (
      <Theme>
        <WidthPercentage>
          <Row style={{ position: 'relative' }}>
            <MobileTabletOnly displayType="flex">
              <Flex className="mt50 ml-40 pr10-mobile">
                {summary.private ? (
                  <img
                    src={privateLock}
                    alt="private viewing"
                    height="24"
                    width="24"
                  />
                ) : null}
              </Flex>
              <Flex
                className="width-max-content-mobile"
                flexDirection="column"
                flexGrow={4}
                maxWidth="230px"
              >
                <StyledPrintWidthFlex alignItems="center">
                  <BoldText className="mr10">
                    {convertDateTimeToTime(
                      summary.dateTime,
                      lowerCaseTimeFormat,
                    )}
                  </BoldText>
                  {summary.cancelled ? (
                    <div style={{ color: '#ca5442' }}>Cancelled</div>
                  ) : (
                    <div>{summary.duration} minutes</div>
                  )}
                </StyledPrintWidthFlex>
                <BoldText>{summary.address}</BoldText>
                <Flex alignItems="center">
                  <div>
                    Reg:
                    <BoldText
                      className="ml5"
                      fontSize="14"
                      lineHeight="16"
                      displayType="inline-block"
                    >
                      {registeredAttendeesCount}
                    </BoldText>
                  </div>
                  <div>
                    Att:
                    <BoldText
                      className="ml5"
                      fontSize="14"
                      lineHeight="16"
                      displayType="inline-block"
                    >
                      {summary.attendees}
                    </BoldText>
                  </div>
                  <Flex alignItems="center">
                    <TextSpan as="span" mr={2}>
                      Keycode:
                    </TextSpan>
                    <PropertyKeysInfoModal
                      tipId={propertyDetails.guidID}
                      keySets={propertyKeySets}
                      onlistKeyCode={onListKeycode}
                      triggerProps={{ width: '50px' }}
                      modalProps={{ minWidth: '200px', maxWidth: '220px' }}
                      openRight
                    />
                  </Flex>
                </Flex>
                <StyledPrintWidthFlex alignItems="center">
                  <Display.PropertyLabels activeOffer={activeOffer} />
                </StyledPrintWidthFlex>
                <WidthPercentage>
                  <div>
                    <MobileTabletOnly displayType="inline">
                      Assignee:
                    </MobileTabletOnly>
                    {summary.assigned}{' '}
                    <span>
                      {assignedTeamMembersCountMinusOne > 0
                        ? `+ ${assignedTeamMembersCountMinusOne}`
                        : ''}
                      <span
                        className="blue-link-style ml5"
                        onClick={() => this.toggleTeamMemberModal(viewing)}
                      >
                        edit
                      </span>
                    </span>
                  </div>
                </WidthPercentage>
                <WidthPercentage className="mt15">
                  {/*Removed temporarily to release external notes*/}
                  {/*Doesn't have appropriate methods in place*/}
                  {/*https://app.clubhouse.io/snugco/story/36769/viewing-report-notes-to-owner*/}
                  {/*<WidthPercentage*/}
                  {/*  mobileWidth="50%"*/}
                  {/*  mobileDisplayType="inline-block"*/}
                  {/*>*/}
                  {/*  <span*/}
                  {/*    className="blue-link-style"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.onToggleAttendeeHubModal(*/}
                  {/*        true,*/}
                  {/*        summary,*/}
                  {/*        registrations,*/}
                  {/*      )*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    Add attendee*/}
                  {/*  </span>*/}
                  {/*</WidthPercentage>*/}
                  <WidthPercentage
                    mobileWidth="49%"
                    mobileDisplayType="inline-block"
                  >
                    <span
                      className="blue-link-style"
                      onClick={() => this.onShowLateViewingModal(true, summary)}
                    >
                      Running Late
                    </span>
                  </WidthPercentage>
                </WidthPercentage>
              </Flex>
              <StyledPrintWidthFlex
                className="width-max-content-mobile mt50"
                flexDirection="column"
                flexGrow={5}
              >
                <img
                  className="cursor-pointer"
                  src={rowOpen ? viewingsOpen : viewingsClosed}
                  alt="row status"
                  onClick={() => this.toggleRow(viewingGUID)}
                />
              </StyledPrintWidthFlex>
            </MobileTabletOnly>
            <DesktopOnly displayType="flex" style={{ width: '100%' }}>
              <Flex flexGrow={9}>
                <StyledPrintWidthFlex
                  justifyContent="center"
                  flexDirection="column"
                  flexGrow={2}
                  maxWidth="100px"
                >
                  <DateTimeInRow>
                    <BoldText>
                      {convertDateTimeToTime(
                        summary.dateTime,
                        lowerCaseTimeFormat,
                      )}
                    </BoldText>
                    {summary.cancelled ? (
                      <div style={{ color: '#CA5442' }}>Cancelled</div>
                    ) : (
                      <div>{summary.duration} minutes</div>
                    )}
                  </DateTimeInRow>
                </StyledPrintWidthFlex>
                <div className="pdf-show-important">
                  <StyledPrintWidthFlex
                    justifyContent="center"
                    flexDirection="column"
                    flexGrow={2}
                    maxWidth="100px"
                  >
                    {summary.private ? (
                      <img
                        src={privateLock}
                        alt="private viewing"
                        height="24"
                        width="24"
                      />
                    ) : (
                      <span style={{ width: '24px' }} />
                    )}
                  </StyledPrintWidthFlex>
                </div>
                <StyledPrintWidthFlex
                  ml={3}
                  flexDirection="column"
                  flexGrow={5}
                  flexGrowPrint={6}
                  widthPrint="260px"
                >
                  <BoldText maxWidth="250px">{summary.address}</BoldText>
                  <Flex
                    alignItems="center"
                    style={{ lineHeight: theme.lineHeights.link }}
                  >
                    <div>
                      Reg:
                      <BoldText
                        className="ml5"
                        fontSize="14"
                        lineHeight={theme.lineHeights.link}
                      >
                        {registeredAttendeesCount}
                      </BoldText>
                    </div>
                    <div>
                      Conf:
                      <BoldText
                        className="ml5"
                        fontSize="14"
                        lineHeight={theme.lineHeights.link}
                        displayType="inline-block"
                      >
                        {confirmedAttendee}
                      </BoldText>
                    </div>
                    <div>
                      Att:
                      <BoldText
                        className="ml5"
                        fontSize="14"
                        lineHeight={theme.lineHeights.link}
                        displayType="inline-block"
                      >
                        {summary.attendees}
                      </BoldText>
                    </div>
                    <Flex alignItems="center">
                      <TextSpan as="span" mr={2}>
                        Keycode:
                      </TextSpan>
                      <PropertyKeysInfoModal
                        tipId={propertyDetails.guidID}
                        keySets={propertyKeySets}
                        onlistKeyCode={onListKeycode}
                        triggerProps={{ width: '120px' }}
                      />
                    </Flex>
                  </Flex>
                  <Flex alignItems="center">
                    {showRegisteredViewingNumBadge && (
                      <RegisteredViewingNumBadge
                        uniqueIdentifier={`${viewing.guidID}-full-badge`}
                        numRegistered={totalRegistered}
                        viewingCap={viewingCap}
                      />
                    )}
                    <Display.PropertyLabels activeOffer={activeOffer} />
                  </Flex>
                </StyledPrintWidthFlex>
                <StyledPrintWidthFlex
                  flexGrow={3}
                  flexGrowPrint={4}
                  widthPrint="90px"
                  alignSelf="center"
                  alignSelfPrint="inherit"
                  mr="4px"
                >
                  <MobileTabletOnly displayType="inline">
                    Assignee:
                  </MobileTabletOnly>
                  <div className="sm">{summary.assigned}</div>
                  <div className="sm pdf-show-important">
                    {formatPhoneNumber(contactNumber)}
                  </div>
                  <span className="pdf-hide">
                    {assignedTeamMembersCountMinusOne > 0
                      ? `+ ${assignedTeamMembersCountMinusOne}`
                      : ''}
                    <span
                      className="blue-link-style ml5"
                      onClick={() => this.toggleTeamMemberModal(viewing)}
                    >
                      edit
                    </span>
                  </span>
                </StyledPrintWidthFlex>
                <StyledPrintWidthFlex
                  flexGrow={2}
                  alignItems="center"
                  flexDirection="column"
                >
                  <Flex alignItems="flex-start" flexDirection="column">
                    {viewingEntryNoticeEnabled && (
                      <Flex flexDirection="row" alignItems="center">
                        <div
                          className={
                            isViewingReportSmsEnabled ||
                            isViewingReportEmailEnabled
                              ? 'mt5'
                              : ''
                          }
                        >
                          {isEntryNoticeEmailNotOpened && (
                            <AlertIcon>
                              <img
                                data-tooltip-content=""
                                className="icon"
                                src={warningIcon}
                                height="24"
                                width="24"
                                alt={'icon'}
                                data-tooltip-id={summary.viewingGUID}
                              />
                              <ReactTooltip
                                data-type="info"
                                place="top"
                                className="tool-tip-style"
                                id={summary.viewingGUID}
                              >
                                <span>Issue with Entry Notices</span>
                              </ReactTooltip>
                            </AlertIcon>
                          )}
                        </div>
                        <div
                          onClick={() =>
                            this.onClickEntryNotices(entryNoticeRecordsSummary)
                          }
                          className="blue-link-style pdf-hide"
                        >
                          Notices
                        </div>
                      </Flex>
                    )}
                  </Flex>
                </StyledPrintWidthFlex>

                {entryNoticesBlock}
                <DesktopOnly
                  displayType="flex"
                  alignItems="center"
                  className="pdf-hide"
                >
                  <Flex flexGrow={1} justifyContent="center">
                    {summary.private ? (
                      <img
                        src={privateLock}
                        alt="private viewing"
                        className="mr5 mt5"
                      />
                    ) : (
                      <span style={{ width: '24px' }} />
                    )}
                  </Flex>
                </DesktopOnly>
                <StyledPrintWidthFlex
                  flexGrow={4}
                  justifyContent="space-around"
                  alignItems="center"
                  widthPrint="110px"
                  positionType="relative"
                  mr="10px"
                >
                  <div className="pdf-hide">
                    <ViewingFurtherActionsContainer>
                      <span
                        className="blue-link-style"
                        onClick={() =>
                          this.confirmCancelViewing(viewingGUID, propertyGUID)
                        }
                      >
                        Cancel
                      </span>
                      {/*Removed temporarily to release external notes*/}
                      {/*Doesn't have appropriate methods in place*/}
                      {/*https://app.clubhouse.io/snugco/story/36769/viewing-report-notes-to-owner*/}
                      {/*<span*/}
                      {/*  className="blue-link-style"*/}
                      {/*  onClick={() =>*/}
                      {/*    this.onToggleAttendeeHubModal(*/}
                      {/*      true,*/}
                      {/*      summary,*/}
                      {/*      registrations,*/}
                      {/*    )*/}
                      {/*  }*/}
                      {/*>*/}
                      {/*  Add attendee*/}
                      {/*</span>*/}
                      <DownloadIcs
                        rootClass={'display-inline-block vertical-align-top'}
                        event={event}
                        linkText="Add to calendar"
                      />
                    </ViewingFurtherActionsContainer>
                  </div>
                  <div className="pdf-hide">
                    <img
                      className="cursor-pointer"
                      src={rowOpen ? viewingsOpen : viewingsClosed}
                      alt="row status"
                      onClick={() => this.toggleRow(viewingGUID)}
                    />
                  </div>
                  <div className="pdf-show-important">
                    <div className="sm">${weeklyRent} p/w</div>
                    <div className="sm">
                      Avail: {moment(availableFrom).format('DD MMM YYYY')}
                    </div>
                  </div>
                </StyledPrintWidthFlex>
                <StyledPrintWidthFlex
                  justifyContent="space-around"
                  alignItems="center"
                  positionType="relative"
                  widthPrint="110px"
                  pl="4px"
                >
                  <div className="pdf-show-important">
                    <div className="sm">
                      Bed {bedroom} Bath {bathroom} Car {car}
                    </div>
                  </div>
                </StyledPrintWidthFlex>
              </Flex>
            </DesktopOnly>
            {rowOpen && (
              <WidthPercentage>
                <ViewingDetailsContainer
                  startDate={summary.dateTime}
                  viewing={viewing}
                  summary={summary}
                  renters={renters}
                  dropDown={this.state.dropDown}
                  onsiteRegistrants={registrations}
                  activeViewingID={activeViewingID}
                  updateAllViewingsData={updateAllViewingsData}
                  isViewingReportSmsEnabled={isViewingReportSmsEnabled}
                  isViewingReportEmailEnabled={isViewingReportEmailEnabled}
                  viewingReportSMSSent={isViewingReportSmsSent}
                  viewingReportEmailSent={isViewingReportEmailSent}
                  property={propertyDetails}
                />
              </WidthPercentage>
            )}
          </Row>
          {isAddAttendeeHubModalOn && (
            <AttendeeHubModal
              clearError={clearError}
              toggleAttendeeHubModal={this.onToggleAttendeeHubModal}
              enquirers={enquirers}
              onAttendeeHubModalAddIconClicked={
                this.onAttendeeHubModalAddIconClicked
              }
              onOnSiteAttendeeNameClicked={this.onOnSiteAttendeeNameClicked}
              fetchAgencyApplicantsElasticSearchResult={
                fetchAgencyApplicantsElasticSearchResult
              }
              teamId={teamGUID}
              filteredEnquirersForAttendeeHub={filteredEnquirersForAttendeeHub}
              onModalTitleClicked={this.onModalTitleClicked}
            />
          )}
          {isWalkInModalOn && (
            <WalkinRenterModal
              viewingAttendeeCount={viewingAttendeeCount}
              error={apiError}
              toggleWalkInModal={this.onToggleWalkInModal}
              addWalkInRenter={this.onAddWalkInRenter}
              propertyId={propertyGUID}
              clearError={this.clearError}
              walkinModalStatus={walkinModalStatus}
              targetAttendee={targetAttendee}
              onUpdateRenterStatus={this.onUpdateRenterStatus}
              renterInfo={renterInfo}
              onSearchAttendeeLinkClicked={this.onSearchAttendeeLinkClicked}
              addAttendeePreloadContent={addAttendeePreloadContent}
              enableOptions={enabledOptionsForWalkinModal}
              fetchNotes={fetchNotes}
              disableSaveButton={disableSaveButton}
              agencyGUID={teamGUID}
              teamSlug={teamSlug}
            />
          )}
          {isAddNoteModalOn && (
            <AddNoteModal
              turnOffAllModals={this.turnOffAllModals}
              onModalTitleClicked={this.onModalTitleClicked}
              propertyId={propertyGUID}
              activeViewingID={viewingGUID}
            />
          )}
          {isAccessDetailModalOn && (
            <AddAccessDetailModal
              turnOffAllModals={this.turnOffAllModals}
              onModalTitleClicked={this.onModalTitleClicked}
              property={propertyGUID}
              agencyGUID={teamGUID}
            />
          )}
          {isLateModalOn && (
            <LateForViewingModal
              showLateViewingModal={this.onShowLateViewingModal}
              error={apiError}
              notifyLateViewing={this.onNotifyLateViewing}
              clearError={clearError}
              totalCheckedInAttendeeCount={totalCheckedInAttendeeCount}
              isLateModalOn={isLateModalOn}
            />
          )}
          {tenantReadModal && (
            <TenantReadModal
              toggleModal={() => this.onClickEntryNotices()}
              tenantsWhoReadTheEntryNotice={tenantsWhoReadTheEntryNotice}
            />
          )}
          <div>
            {openModalForTeamMember && (
              <AssignViewingTeamMemberModal
                toggleModal={this.toggleTeamMemberModal}
                viewing={viewing}
                teamId={teamGUID}
                assignViewingToTeamMember={assignViewingToTeamMember}
                fetchViewingTeamMembers={fetchViewingTeamMembers}
                deleteViewingTeamMember={deleteViewingTeamMember}
                updateViewingTeamMemberRelation={
                  updateViewingTeamMemberRelation
                }
                propertyId={propertyGUID}
                viewingStartDate={convertDateTimeToTime(
                  summary.dateTime,
                  lowerCaseTimeFormat,
                )}
                managersListForViewingFilters={managersListForViewingFilters}
              />
            )}
          </div>
        </WidthPercentage>
      </Theme>
    )
  }
}

export default ViewingRow
