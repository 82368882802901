import { apiBaseUrl } from 'config/env'

import { api } from 'app/utils/api/helpers'

export const getPMSStatus = (teamGUID) => {
  return api.get(`/pms/${teamGUID}/status`)
}

export const getPMSActivityLogs = (teamGUID, pms, cursor) => {
  return api.get(`/pms/${teamGUID}/activities/${pms}`, {
    params: {
      ...(cursor && { cursor: cursor }),
    },
  })
}

export const oauthActivate = (pms, teamGUID, returnPath) => {
  window.location = `${apiBaseUrl}pms/oauth2/authorize/${pms}/${teamGUID}?return=${returnPath}`
}
export const activatePMS = (teamGUID, pms, payload) => {
  return api.post(`/pms/${teamGUID}/activate/${pms}`, payload)
}

export const deactivatePMS = (teamGUID, pms) => {
  return api.post(`/pms/${teamGUID}/deactivate/${pms}`)
}

export const getPUM = (teamGUID) => {
  return api.get(`/pms/${teamGUID}/pum`)
}

export const getPMSConfig = (teamGUID, pms) => {
  return api.get(`/pms/${teamGUID}/config/${pms}`)
}
