import type { FunctionComponent } from 'react'

import styled from 'styled-components'
import type { SpaceProps } from 'styled-system'

import { AlertInfoBase } from 'app/components/design-system-components/AlertInfoBase'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledLearnMore = styled.a`
  text-decoration: underline !important;
`

type AlertInfoProps = {
  message: string
  learnMoreLink?: string
}

export const AlertInfo: FunctionComponent<AlertInfoProps & SpaceProps> = ({
  message,
  learnMoreLink = '',
  ...props
}) => {
  return (
    <AlertInfoBase {...props}>
      <Text my={0} color={theme.colors.deepBlue}>
        {message}{' '}
        {!!learnMoreLink && (
          <StyledLearnMore
            target="_blank"
            rel="noopener noreferrer"
            href={learnMoreLink}
          >
            learn more
          </StyledLearnMore>
        )}{' '}
      </Text>
    </AlertInfoBase>
  )
}
