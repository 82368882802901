import { NetworkConnectionError } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { getAllViewingsForCheckin } from 'app/pages/apply/teams/checkin/access'

export const fetchAllViewingsForCheckin = (agencySlug, cursor, limit) => () => {
  return getAllViewingsForCheckin(agencySlug, cursor, limit).then(
    ({ ok, result, responseText }) => {
      if (ok) {
        return Promise.resolve(result)
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      }
    },
  )
}
