import React from 'react'

import styled from 'styled-components'

import backgroundCheckHeaderBackground from 'app/assets/icons/bc-report-header.svg'
import logo from 'app/assets/icons/icon-solid.svg'
import whiteShield from 'app/assets/icons/shield-white.svg'
import { Box, Flex } from 'app/components/design-system-components'
import {
  Heading,
  Text,
} from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const HeaderContainer = styled(Box)`
  overflow: hidden;
  background-image: url(${backgroundCheckHeaderBackground}) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: relative;

  display: ${(props) => (props.page ? 'none' : 'block')};
  @media print {
    display: ${(props) => (props.page ? 'block' : 'block')};
  }
`

const HeaderContent = styled(Flex)`
  padding: ${({ theme }) =>
    `${theme.dividedSpace[10]}px ${theme.dividedSpace[6]}px`};
  @media print {
    padding: ${({ theme }) =>
      `${theme.dividedSpace[4]}px ${theme.dividedSpace[6]}px`};
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;
    padding: ${({ theme }) =>
      `${theme.dividedSpace[5]}px ${theme.dividedSpace[2]}px ${theme.dividedSpace[3]}px ${theme.dividedSpace[2]}px`};
  }
`

const HeaderText = styled(Heading)`
  color: white !important;
  font-size: ${({ theme }) => `${theme.fontSizes[17]}px`};
  font-weight: 700;
  margin-left: ${({ theme }) => `${theme.dividedSpace[6]}px`};

  @media screen and (max-width: 576px) {
    font-size: ${({ theme }) => `${theme.fontSizes[19]}px`};
    margin-left: ${({ theme }) => `${theme.dividedSpace[3]}px`};
  }
`

const DateMark = styled.div`
  background-color: #ff5777 !important;
  width: ${({ theme }) => `${theme.dividedSpace[1]}px`} !important;
  height: 17px !important;
  border-radius: 3px !important;
  @media screen and (max-width: 576px) {
    width: 0 !important;
  }
  @media print {
    display: none;
  }
`

const DateText = styled(Text)`
  margin-bottom: 0;
  @media screen and (max-width: 576px) {
    margin-left: 0;
    color: black !important;
    font-size: ${({ theme }) => `${theme.fontSizes[20]}px`} !important;
  }
`

const DateLabel = styled(Text)`
  font-weight: ${({ theme }) => `${theme.fontWeights[6]}`};
  margin-bottom: 0;
  padding: ${({ theme }) => `0 0 0 ${theme.dividedSpace[3]}px`};
  width: 120px;
  @media screen and (max-width: 576px) {
    padding: 0;
    color: black !important;
    font-size: ${({ theme }) => `${theme.fontSizes[20]}px`} !important;
  }
`

const SnugLogo = styled.img`
  width: 50px;
  align-self: flex-end;
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[2]}px`};
  @media screen and (max-width: 576px) {
    position: absolute;
    right: ${({ theme }) => `${theme.dividedSpace[2]}px`};
    top: ${({ theme }) => `${theme.dividedSpace[5]}px`};
  }
`

const BackgroundCheckIcon = styled.img`
  @media screen and (max-width: 576px) {
    width: 80px;
  }
`

const IssueContainer = styled(Flex)`
  p {
    color: #696682;
    font-size: ${({ theme }) => `${theme.fontSizes[21]}px`};
  }
  @media print {
    p {
      color: #696682 !important;
      font-size: 15px !important;
    }
  }
`

const LeftFlex = styled(Flex)`
  @media screen and (max-width: 576px) {
    width: 90%;
  }
`

const RightFlex = styled(Flex)`
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`

const BackgroundCheckHeader = ({
  expireDate,
  formattedUpdateDate = '',
  page,
  slug = '',
}) => {
  return (
    <HeaderContainer page={page}>
      <HeaderContent justifyContent="space-between">
        <LeftFlex alignItems="center" width="50%">
          <BackgroundCheckIcon src={whiteShield} alt="dismiss" />
          <HeaderText>Background Check Report</HeaderText>
        </LeftFlex>
        <RightFlex justifyContent="flex-end" flexDirection="column" width="40%">
          <SnugLogo src={logo} alt="dismiss" />
          <Flex justifyContent="flex-end" align-items="center">
            <Box width="60%">
              <Flex alignItems="center" my={`${theme.space[3]}px`}>
                <DateMark />
                <IssueContainer justifyContent="space-between" width="100%">
                  <DateLabel>Issue Date:</DateLabel>
                  <DateText ml="32px">{formattedUpdateDate}</DateText>
                </IssueContainer>
              </Flex>
              <Flex alignItems="center" my={`${theme.space[3]}px`}>
                <DateMark />
                <IssueContainer justifyContent="space-between" width="100%">
                  <DateLabel>Report ID:</DateLabel>
                  <DateText ml="32px">{slug}</DateText>
                </IssueContainer>
              </Flex>
              <Flex alignItems="center" my={`${theme.space[3]}px`}>
                <DateMark />
                <Flex justifyContent="space-between" width="100%">
                  <DateLabel>Expiry Date:</DateLabel>
                  <DateText>{expireDate}</DateText>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </RightFlex>
      </HeaderContent>
    </HeaderContainer>
  )
}

export default BackgroundCheckHeader
