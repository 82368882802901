import React from 'react'

const PricingVariationModal = ({
  categories,
  pricingElements,
  form,
  validations,
  updateField,
  validate,
  isSubmitDisabled,
  submit,
  errorAmount,
  triggerModal,
}) => (
  <div className="modal-fullscreen">
    <div className="modal-wrapper modal-top">
      <div className="modal-close">
        <i className="fa fa-times" onClick={() => triggerModal(false)} />
      </div>
      <div className="modal-header">
        <h3>Pricing Variations</h3>
      </div>

      <div className="modal-body">
        <div className="row">
          <div className="col-sm-6">
            <div className="input-box">
              <select
                required
                value={form.pricingElement}
                onChange={(e) =>
                  updateField('number', 'pricingElement', e.target.value)
                }
                onBlur={(e) => validate('pricingElement', e.target.value)}
              >
                <option className="blank"></option>
                {pricingElements.map((element, i) => (
                  <option key={element.id} value={element.id}>
                    {element.label}
                  </option>
                ))}
              </select>
              <label>Pricing Element</label>
            </div>
            {!validations.pricingElement.ok && (
              <div className="alert alert-danger">
                {validations.pricingElement.error}
              </div>
            )}
          </div>
          <div className="col-sm-6">
            <div className="input-box">
              <select
                required
                value={form.category}
                onChange={(e) =>
                  updateField('number', 'category', e.target.value)
                }
                onBlur={(e) => validate('category', e.target.value)}
              >
                <option className="blank"></option>
                {categories.map((category, i) => (
                  <option key={category.id} value={category.id}>
                    {category.label}
                  </option>
                ))}
              </select>
              <label>Category</label>
            </div>
            {!validations.category.ok && (
              <div className="alert alert-danger">
                {validations.category.error}
              </div>
            )}
          </div>
          <div className="col-sm-12">
            <div className="input-box">
              <input
                required
                value={form.amount}
                onChange={(e) =>
                  updateField('number', 'amount', e.target.value)
                }
                onBlur={(e) => validate('amount', e.target.value)}
              />
              <label>Amount</label>
            </div>
            {errorAmount && (
              <div className="alert alert-danger">{errorAmount}</div>
            )}
            {!validations.amount.ok && (
              <div className="alert alert-danger">
                {validations.amount.error}
              </div>
            )}
          </div>
          <div className="col-sm-12">
            <div className="input-box">
              <textarea
                required
                value={form.comment}
                onChange={(e) =>
                  updateField('string', 'comment', e.target.value)
                }
                onBlur={(e) => validate('comment', e.target.value)}
                rows="5"
              />
              <label>Comment</label>
            </div>
            {!validations.comment.ok && (
              <div className="alert alert-danger">
                {validations.comment.error}
              </div>
            )}
          </div>
          <div className="btn-w">
            <button
              disabled={isSubmitDisabled}
              onClick={submit}
              className={`btn ${isSubmitDisabled ? 'btn-gray-light' : ''}`}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default PricingVariationModal
