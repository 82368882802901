import type { KeyboardEvent } from 'react'

import circleCross from 'app/assets/icons/circle-cross.png'
import * as Display from 'app/components/display/display'
import {
  AllSearchResultsContainer,
  FilteredSearchResultsRow,
  NO_RESULTS_FOUND_TEXT,
  SearchResultContainer,
} from 'app/match/propertyReport/container'

interface IEmailItem {
  status: string
  text: string
  primary: string
}

interface IInputFieldWithSearchDropdown {
  label: string
  inputList: IEmailItem[]
  changeInputList: string
  inputValue: string
  changeInputValue: string
  removeFromListFunc: (index: number) => void
  showResults: boolean
  filteredSearchResults: IEmailItem[]
  emailOnChange: (
    event: string,
    changeInputList: string,
    changeInputValue: string,
  ) => void
  emailOnBlur: (
    event: string,
    changeInputList: string,
    changeInputValue: string,
  ) => void
  emailKeyDown: (
    event: KeyboardEvent<HTMLInputElement>,
    changeInputList: string,
    changeInputValue: string,
  ) => void
  emailOnFocus: () => void
  filteredItemSelected: (
    emailItem: IEmailItem,
    changeInputList: string,
    changeInputValue: string,
  ) => void
}

const InputFieldWithSearchDropdown = ({
  label = '',
  inputList = [],
  changeInputList,
  inputValue = '',
  changeInputValue,
  removeFromListFunc,
  showResults = false,
  filteredSearchResults = [],
  emailOnChange,
  emailOnBlur,
  emailKeyDown,
  filteredItemSelected,
  emailOnFocus,
}: IInputFieldWithSearchDropdown) => {
  return (
    <>
      <Display.FlexContainer containerClass="flex-wrap-wrap width100">
        <div className="font-size-16 fw500 mr10 grey-text mt10 pb20">
          {label}
        </div>
        {inputList.length > 0 &&
          inputList.map((emailItem: IEmailItem, index: number) => {
            return (
              <div
                className={`font-size-16 fw500 mr10 p10 background-grey-mobile-excluded display-flex email-icon ${
                  emailItem.status === 'invalid' ? 'invalid-email' : ''
                }`}
                key={emailItem.text}
              >
                <div>{emailItem.text}</div>
                {!emailItem.primary && (
                  <img
                    className="email-remove"
                    onClick={() => removeFromListFunc(index)}
                    src={circleCross}
                    alt="remove"
                    role="button"
                    tabIndex={0}
                  />
                )}
              </div>
            )
          })}

        <div className="email-input-wrapper mb10">
          <input
            className="email-input"
            value={inputValue}
            onChange={(e) =>
              emailOnChange(e.target.value, changeInputList, changeInputValue)
            }
            onBlur={(e) =>
              emailOnBlur(e.target.value, changeInputList, changeInputValue)
            }
            onKeyDown={(e) =>
              emailKeyDown(e, changeInputList, changeInputValue)
            }
            onFocus={emailOnFocus}
          />
        </div>
      </Display.FlexContainer>
      {showResults && (
        <AllSearchResultsContainer style={{ marginLeft: '0' }}>
          {filteredSearchResults.length > 0 ? (
            filteredSearchResults.map((item, index) => {
              return (
                <div
                  key={index}
                  onMouseDown={() =>
                    filteredItemSelected(
                      item,
                      changeInputList,
                      changeInputValue,
                    )
                  }
                >
                  <FilteredSearchResultsRow item={item} />
                </div>
              )
            })
          ) : (
            <SearchResultContainer>
              {NO_RESULTS_FOUND_TEXT}
            </SearchResultContainer>
          )}
        </AllSearchResultsContainer>
      )}
    </>
  )
}

export default InputFieldWithSearchDropdown
