import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { matchPath, NavLink, Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import activityBlurred from 'app/assets/icons/activity-blurred.png'
import {
  Box,
  Flex,
  FullWidthLinksTabs,
  LoadingSection,
} from 'app/components/design-system-components'
import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import AdvancedActivityReporting from 'app/dashboard/team_activity/AdvancedActivityReporting'
import AutomationsReporting from 'app/dashboard/team_activity/AutomationsReporting'
import { BackgroundChecksListPage } from 'app/dashboard/team_activity/BackgroundChecksReporting/BackgroundChecksListPage'
import { SMSListPage } from 'app/dashboard/team_activity/SMSReporting/SMSListPage'
import StandardActivityReporting from 'app/dashboard/team_activity/StandardActivityReporting'
import { VacancyListPage } from 'app/dashboard/team_activity/VacancyReporting/VacancyListPage'
import { getTeamSettings } from 'app/services/http/teams/settings'
import { TitleHeader } from 'app/shared_components/helpers'
import SnugUpgradeAccount from 'app/shared_components/snug_account_upgrade'
import { parseDataUtil, urlTo } from 'app/sm/helpers'
import * as accountHelpers from 'app/utils/accounts/helpers'
import 'app/dashboard/team_activity/style.scss'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'

const StyledFullWidthLinksTabs = styled(FullWidthLinksTabs)`
  width: fit-content;
  ${Flex} {
    margin-right: 1px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    white-space: nowrap;
  }
`

const ProductivityBundleAlert = (
  <>
    <img src={activityBlurred} alt="blurred report" />
    <SnugUpgradeAccount typeOfMessage={accountHelpers.UPGRADE_TYPE_ACTIVITY} />
  </>
)

const TeamActivityContainer = ({
  currentTeam,
  location,
  match,
  advancedEnabled,
  bgCheckEnabled,
  smsEnabled,
  vacancyEnabled,
  automationsEnabled,
}) => {
  const [productivityBundleEnabled, setProductivityBundleEnabled] =
    useState(false)

  const isPremierAccount = accountHelpers.isAccountPremier(
    currentTeam.accountType,
  )

  const {
    loadingStates: teamSettingsLoadingStates,
    loadingStatesHelpers: teamSettingsLoadingHelpers,
  } = useLoadingStates()

  useEffect(() => {
    teamSettingsLoadingHelpers.startLoading()
    getTeamSettings(currentTeam.guid, {
      keys: ['bundle'],
    })
      .then(({ settingInfo }) => {
        teamSettingsLoadingHelpers.markDoneSuccessfully()
        const bundles = parseDataUtil.convertObjValueFromStringToBoolean(
          settingInfo?.bundle,
        )
        setProductivityBundleEnabled(!!bundles?.productivity_bundle)
      })
      .catch(teamSettingsLoadingHelpers.setError)
  }, [])

  const renderTabs = () => {
    const createTabUrl = (match, tabSubUrl) => `${match.url}${tabSubUrl}`
    const createTabPath = (match, tabSubUrl) => `${match.path}${tabSubUrl}`
    const standardSubUrl = '/standard'
    const advancedSubUrl = '/advanced'
    const bgSubUrl = '/backgroundcheck'
    const smsSubUrl = '/sms'
    const vacancySubUrl = '/vacancy'
    const automationsSubUrl = '/automations'

    const standardPath = createTabPath(match, standardSubUrl)
    const advancedPath = createTabPath(match, advancedSubUrl)
    const bgPath = createTabPath(match, bgSubUrl)
    const smsPath = createTabPath(match, smsSubUrl)
    const vacancyPath = createTabPath(match, vacancySubUrl)
    const automationsPath = createTabPath(match, automationsSubUrl)

    const tabs = [
      {
        id: standardPath,
        displayName: 'Activity',
        ActionWrapper: NavLink,
        actionProps: { to: createTabUrl(match, standardSubUrl) },
      },
      ...(advancedEnabled
        ? [
            {
              id: advancedPath,
              displayName: 'Advanced Reporting',
              ActionWrapper: NavLink,
              actionProps: { to: createTabUrl(match, advancedSubUrl) },
            },
          ]
        : []),
      {
        id: bgPath,
        displayName: 'Background Checks',
        ActionWrapper: NavLink,
        actionProps: { to: createTabUrl(match, bgSubUrl) },
      },
      ...(smsEnabled
        ? [
            {
              id: smsPath,
              displayName: 'SMS',
              ActionWrapper: NavLink,
              actionProps: { to: createTabUrl(match, smsSubUrl) },
            },
          ]
        : []),
      ...(vacancyEnabled
        ? [
            {
              id: vacancyPath,
              displayName: 'Vacancy',
              ActionWrapper: NavLink,
              actionProps: { to: createTabUrl(match, vacancySubUrl) },
            },
          ]
        : []),
      ...(automationsEnabled
        ? [
            {
              id: automationsPath,
              displayName: 'Automations',
              ActionWrapper: NavLink,
              actionProps: { to: createTabUrl(match, automationsSubUrl) },
            },
          ]
        : []),
    ]

    const currentTabPath = [
      standardPath,
      advancedPath,
      bgPath,
      smsPath,
      vacancyPath,
      automationsPath,
    ].find((path) => matchPath(location.pathname, { path }))

    return (
      <Box>
        <StyledFullWidthLinksTabs
          items={tabs}
          selectedItemId={currentTabPath}
          variant="graySelectedBold"
          selectionBgColor="white"
          blurBgColor="gray200"
        />
        <hr style={{ marginTop: '0' }} />
        {subRoutes(
          match,
          advancedEnabled,
          bgCheckEnabled,
          smsEnabled,
          vacancyEnabled,
          automationsEnabled,
          {
            bgSubUrl,
            standardSubUrl,
            advancedSubUrl,
            smsSubUrl,
            vacancySubUrl,
            automationsSubUrl,
          },
        )}
      </Box>
    )
  }

  const subRoutes = (
    match,
    advancedEnabled,
    bgEnabled,
    smsEnabled,
    vacancyEnabled,
    automationsEnabled,
    urls,
  ) => (
    <Switch>
      <Route exact path={match.path + urls.bgSubUrl}>
        <BackgroundChecksListPage isEnabled={bgEnabled} />
      </Route>
      <Route
        exact
        path={match.path + urls.standardSubUrl}
        component={StandardActivityReporting}
      />
      {advancedEnabled && (
        <Route
          exact
          path={match.path + urls.advancedSubUrl}
          component={AdvancedActivityReporting}
        />
      )}
      {smsEnabled && (
        <Route exact path={match.path + urls.smsSubUrl}>
          <SMSListPage isEnabled={smsEnabled} />
        </Route>
      )}
      <Route path={match.path + urls.vacancySubUrl}>
        <VacancyListPage
          isEnabled={productivityBundleEnabled || isPremierAccount}
          disabledAlertCmp={ProductivityBundleAlert}
        />
      </Route>
      <Route exact path={match.path + urls.automationsSubUrl}>
        <AutomationsReporting />
      </Route>
      <Redirect from={match.path} exact to={match.url + urls.standardSubUrl} />
    </Switch>
  )

  let crumbs = []
  let isAccountLite = true
  if (currentTeam && currentTeam.name) {
    crumbs = [
      {
        text: currentTeam.name || 'Team',
        link: urlTo('teamOverview', { teamSlug: currentTeam.slug }),
      },
      {
        text: 'Activity',
        link: '#',
      },
    ]
    isAccountLite = currentTeam.accountType === accountHelpers.ACCOUNT_TYPE_LITE
  }

  return (
    <div>
      {isAccountLite ? (
        <div>
          <img src={activityBlurred} alt="blurred activities" />
          <SnugUpgradeAccount
            typeOfMessage={accountHelpers.UPGRADE_TYPE_ACTIVITY}
          />
        </div>
      ) : (
        <div>
          <div className="col-first">
            <Breadcrumbs crumbs={crumbs} />
            <div className="display-flex">
              <TitleHeader
                header="Activity"
                headerClass="header"
                headerBoxClass="activity-header-container"
              />
            </div>
          </div>
        </div>
      )}
      <LoadingSection loadingState={teamSettingsLoadingStates}>
        {renderTabs()}
      </LoadingSection>
    </div>
  )
}

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
})

export default connect(mapStateToProps)(TeamActivityContainer)
