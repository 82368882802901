import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'

export const moveApplicationFormFields = {
  property: 'property',
  message: 'message',
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case moveApplicationFormFields.property: {
      return {
        required: !val && 'property is required',
      }
    }
    case moveApplicationFormFields.message: {
      return {
        max: val.length > 5000 && 'this message is too long',
      }
    }
    default:
      return {}
  }
}

export const moveApplicationFormValidation =
  formValidationBuilder(fieldValidation)
