import React from 'react'

import { Box } from 'app/components/design-system-components/index'
import { CustomInputWrapper } from 'app/forms/CustomInputWrapper'

export const CheckboxesInput = ({
  value,
  changeHandler,
  blurHandler,
  options = [],
  optionRenderer,
  isRadioGroup,
  customLabelClass = 'filter-today-checkbox',
  rendererContainerProps = {},
}) => {
  const toggleCheckboxItemSelection = (optionObj) => {
    const { optionMeta } = optionObj
    if (value[optionMeta.id]) {
      const { [optionMeta.id]: toggledOption, ...otherOptions } = value
      return changeHandler({ ...otherOptions })
    } else {
      return changeHandler({
        ...value,
        [optionMeta.id]: optionObj,
      })
    }
  }

  const toggleRadioGroupItemSelection = (optionObj) => {
    const { optionMeta } = optionObj
    if (value[optionMeta.id]) {
      return
    } else {
      return changeHandler({
        [optionMeta.id]: optionObj,
      })
    }
  }

  const toggleItemSelection = isRadioGroup
    ? toggleRadioGroupItemSelection
    : toggleCheckboxItemSelection

  const selectedOptionsIds = Object.values(value).map(
    ({ optionMeta }) => optionMeta.id,
  )

  return (
    <>
      {options.map((optionObj) => (
        <Box>
          <label className={`ibm ${customLabelClass}`}>
            <input
              type="checkbox"
              className="input-box wa pt0"
              onClick={() => toggleItemSelection(optionObj)}
              checked={selectedOptionsIds.includes(optionObj.optionMeta.id)}
            />
            <Box display="inline-block" ml={3} {...rendererContainerProps}>
              {optionRenderer(optionObj)}
            </Box>
          </label>
        </Box>
      ))}
    </>
  )
}

export const CheckboxesInputField = (props) => {
  const { name } = props

  return (
    <CustomInputWrapper
      name={name}
      customInputCmp={(customInputProps) => (
        <CheckboxesInput {...customInputProps} {...props} />
      )}
    />
  )
}

export { CheckboxInput, CheckboxInputField } from './Checkbox'
