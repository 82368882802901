import { all, delay, put, select, takeEvery } from 'redux-saga/effects'

import { actions as coreActionCreators } from 'app/core/coreSlice'
import { connectionStatuses } from 'app/core/coreSlice'
import { actionTypes as coreActionTypes } from 'app/core/coreSlice'
import {
  actionCreators as pendingTasksActionCreators,
  actionTypes as pendingTasksActionTypes,
} from 'app/core/pendingTasksSlice'
import * as snugNotifier from 'app/services/snugNotifier'
import { CHANGE_CURRENT_TEAM } from 'app/session/session_actions'
import { isOfflineActionsEnabled } from 'config/features'

const pendingTasksSliceSelector = (state) => state.pendingTasks
const connectionStatusSelector = (state) => state.core.connectionStatus

const maxTrials = 3
const startApplicationTimeoutBeforeSyncing = 3000

export const offlineActionIsEnabledSelector = ({ session }) => {
  const currentTeamSlug = session?.currentTeam?.slug
  return isOfflineActionsEnabled(currentTeamSlug || '')
}

function* startSyncing() {
  const isOfflineActionsEnabled = yield select(offlineActionIsEnabledSelector)
  if (!isOfflineActionsEnabled) {
    return
  }

  const { queue } = yield select(pendingTasksSliceSelector)
  const status = yield select(connectionStatusSelector)
  const isOnline =
    status === connectionStatuses.online ||
    status === connectionStatuses.becameOnline
  if (!queue.length || !isOnline) {
    return
  }
  const pickedTask = queue[0]
  const mappedTask = {
    ...pickedTask,
    __pendingTaskTrials: 0,
  }
  yield put(pendingTasksActionCreators.updateSyncingTask(mappedTask))
  yield put(coreActionCreators.startNewGlobalLoading())
  yield put(mappedTask)
}

export function* watchStartSyncing() {
  yield takeEvery(pendingTasksActionTypes.startSyncing, startSyncing)
}

function* taskFailed({ task }) {
  const { __pendingTaskTrials, __pendingTaskName } = task
  const { queue } = yield select(pendingTasksSliceSelector)
  const connectionStatus = yield select(connectionStatusSelector)

  if (__pendingTaskTrials >= maxTrials) {
    snugNotifier.error(`Syncing "${__pendingTaskName}" task has been failed`)
    yield put(pendingTasksActionCreators.taskSkipped(queue[0]))

    // skip the failed task and remove it from the pending tasks
    yield put(pendingTasksActionCreators.updateSyncingTask(null))
    yield put(pendingTasksActionCreators.popTask())
    yield put(coreActionCreators.endOneGlobalLoading())

    if (
      connectionStatus === connectionStatuses.online ||
      connectionStatus === connectionStatuses.becameOnline
    ) {
      yield put(pendingTasksActionCreators.startSyncing())
    }
  } else {
    yield put(
      pendingTasksActionCreators.updateSyncingTask({
        ...task,
        __pendingTaskTrials: __pendingTaskTrials + 1,
      }),
    )
    yield delay(__pendingTaskTrials * 10000)
    yield put(pendingTasksActionCreators.retrySync())
  }
}

export function* watchTaskFailed() {
  yield takeEvery(pendingTasksActionTypes.taskFailed, taskFailed)
}

function* taskSynced() {
  const connectionStatus = yield select(connectionStatusSelector)
  yield put(pendingTasksActionCreators.updateSyncingTask(null))
  yield put(pendingTasksActionCreators.popTask())
  yield put(coreActionCreators.endOneGlobalLoading())

  if (
    connectionStatus === connectionStatuses.online ||
    connectionStatus === connectionStatuses.becameOnline
  ) {
    yield put(pendingTasksActionCreators.startSyncing())
  }
}

export function* watchTaskSucceed() {
  yield takeEvery(pendingTasksActionTypes.taskSynced, taskSynced)
}

function* retryTask() {
  const { syncingTask } = yield select(pendingTasksSliceSelector)
  yield put(syncingTask)
}

export function* watchRetryTask() {
  yield takeEvery(pendingTasksActionTypes.retrySync, retryTask)
}

function* checkAbilityToDispatchStartSyncing({ type }) {
  const isOfflineActionsEnabled = yield select(offlineActionIsEnabledSelector)
  if (!isOfflineActionsEnabled) {
    return
  }

  const status = yield select(connectionStatusSelector)
  if (
    status === connectionStatuses.online ||
    status === connectionStatuses.becameOnline
  ) {
    yield delay(
      type === coreActionTypes.started
        ? startApplicationTimeoutBeforeSyncing
        : 0,
    )
    yield put(pendingTasksActionCreators.startSyncing())
  }
}

export function* watchCasesToFireStartSyncing() {
  yield all([
    yield takeEvery(
      coreActionTypes.started,
      checkAbilityToDispatchStartSyncing,
    ),
    yield takeEvery(
      coreActionTypes.updateConnectionStatus,
      checkAbilityToDispatchStartSyncing,
    ),
    // ready to check feature flags
    yield takeEvery(CHANGE_CURRENT_TEAM, checkAbilityToDispatchStartSyncing),
  ])
}

export function* pendingTasksEffects() {
  yield all([
    watchRetryTask(),
    watchTaskFailed(),
    watchStartSyncing(),
    watchTaskSucceed(),
    watchCasesToFireStartSyncing(),
  ])
}
