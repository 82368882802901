import { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Box, LoadingSection } from 'app/components/design-system-components'
import { propertyDisclosureInputTypes } from 'app/constants/disclosure.constants'
import DisclosureQuestions from 'app/pages/teams/onlist/components/Disclosures'
import { PropertyDisclosureHeader } from 'app/pages/teams/onlist/components/Disclosures/PropertyDisclosureHeader'
import { getPropertyDisclosuresStatusForAgency } from 'app/services/http/propertydisclosures'
import DocumentList from 'app/shared_components/document_list/document_list'
import * as helpers from 'app/sm/helpers'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

type Props = {
  currentTeam: any
  match: any
}

export const PropertyDisclosure = ({ currentTeam, match }: Props) => {
  const {
    loadingStates: disclosureLoadingStates,
    loadingStatesHelpers: disclosureLoadingStatesHelpers,
  } = useLoadingStates()

  const [inputType, setInputType] = useState(null)
  const [answers, setAnswers] = useState({})
  const [version, setVersion] = useState(0)
  const [attachments, setAttachments] = useState([] as any[])
  const [propertyAddress, setPropertyAddress] = useState('')

  useEffect(() => {
    loadDisclosure()
  }, [])

  const loadDisclosure = () => {
    disclosureLoadingStatesHelpers.startLoading()
    const { offerId } = (match || {}).params || {}
    if (!currentTeam?.guid || !offerId) return
    return getPropertyDisclosuresStatusForAgency(
      currentTeam.guid,
      offerId,
    ).then(({ propertyDisclosure, address }: any) => {
      if (!propertyDisclosure) return
      disclosureLoadingStatesHelpers.markDoneSuccessfully()
      setInputType(propertyDisclosure.disclosureType)
      setPropertyAddress(address)
      if (
        propertyDisclosure.disclosureType ===
        propertyDisclosureInputTypes.attachments.id
      ) {
        setAttachments(
          transformAttachments(propertyDisclosure.disclosureAttachments),
        )
      } else {
        setAnswers({ ...propertyDisclosure })
        setVersion(propertyDisclosure.version)
      }
    })
  }

  const transformAttachments = (attachments: any[]): any[] => {
    // name and URL fields are used in DocumentList component
    return attachments.map(
      ({ displayName: DName, name, url, ...rest }: any) => ({
        name: DName,
        URL: url,
        ...rest,
      }),
    )
  }

  return (
    <Box width="70%">
      <LoadingSection
        loadingState={disclosureLoadingStates}
        actionHandler={() => loadDisclosure()}
      >
        <PropertyDisclosureHeader propertyAddress={propertyAddress} />
        <Box>
          {inputType === propertyDisclosureInputTypes.attachments.id && (
            <>
              <DocumentList
                documents={attachments}
                showUploader={false}
                documentType={helpers.DocumentTypes.propertyDisclosure}
                spinner={
                  disclosureLoadingStates.state === loadingStatesIds.LOADING
                }
                availableFileTypesText="(PDF)"
                allowDeletion={false}
                toggleDocumentsViewer={undefined}
              />
            </>
          )}
          {inputType === propertyDisclosureInputTypes.form.id && (
            <DisclosureQuestions
              areDisclosuresEditable={false}
              answers={answers}
              version={version}
            />
          )}
        </Box>
      </LoadingSection>
    </Box>
  )
}

const mapStateToProps = ({ session }: any) => ({
  currentTeam: session.currentTeam,
})

export const PropertyDisclosurePage = withRouter(
  connect(mapStateToProps)(PropertyDisclosure),
)
