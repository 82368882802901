import React, { useEffect, useState } from 'react'

import { Field, Form } from 'formik'
import styled from 'styled-components'

import { Alert, HorizontalLine } from 'app/components/design-system-components'
import { ChipsField } from 'app/components/design-system-components/inputs'
import { AutocompleteField } from 'app/components/design-system-components/inputs'
import { TextArea } from 'app/components/design-system-components/inputs'
import { InputField } from 'app/components/design-system-components/inputs'
import * as getters from 'app/components/design-system-components/theme/getters'
import { MAX_ALLOWED_RECEIVERS } from 'app/constants/messages.constants'
import { Attachments } from 'app/features/pm-messages/new-message/components/Attachments'
import { MessagePreview } from 'app/features/pm-messages/new-message/components/MessagePreview'
import { ModalFooter } from 'app/features/pm-messages/new-message/components/ModalFooter'
import { newMessagesModes } from 'app/features/pm-messages/new-message/constants'
import { formFields } from 'app/features/pm-messages/new-message/form.utils'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { debounce } from 'app/helpers/debounce'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  deleteAttachments,
  uploadAttachments,
} from 'app/services/http/teams/pm-messages'
import { searchProperty } from 'app/services/http/teams/properties'
import * as snugNotifier from 'app/services/snugNotifier'
import { useAttachments } from 'app/utils/hooks/useAttachments'
import { useComponentWillUnmount } from 'app/utils/hooks/useComponentWillUnmount'

const StyledFieldWrapper = styled(FieldWrapper)`
  margin-bottom: ${getters.spacing(5)};
`

export const newBulkyFormBodyConfig = (messagesCount) => {
  return {
    mode: newMessagesModes.bulky,
    messagesCount,
  }
}

export const newIdentifiedMessagesFormBodyConfig = (invalidMessages) => {
  return {
    mode: newMessagesModes.identifiedMessages,
    invalidMessages,
  }
}

export const FormBody = ({
  teamId,
  saved,
  modeConfig,
  formBag: {
    isValid,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    status,
    values,
    touched,
  },
}) => {
  const [loadingProperties, setLoadingProperties] = useState(false)
  const [propertiesOptions, setPropertiesOptions] = useState([])
  const [showPreview, setShowPreview] = useState(false)

  const { setAndMergeTrackedState } = useComponentWillUnmount(
    ({ attachments, saved }) => {
      if (saved) return
      const attachmentsIds = Object.values(attachments)
        .map(({ guid }) => guid)
        .filter(Boolean)

      if (!attachmentsIds.length) return
      deleteAttachments(teamId, attachmentsIds)
    },
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setAndMergeTrackedState({ saved }), [saved])

  useEffect(() => {
    const { option: property } = values[formFields.property] || {}
    if (property) {
      setFieldValue(
        formFields.subject,
        `re: Enquiry about ${property.address.friendlyName}`,
        true,
      )
    } else if (!touched[formFields.subject] && touched[formFields.property]) {
      setFieldValue(formFields.subject, `Reply to your enquiry`, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[formFields.property]])

  const uploadAttachmentFn = (file) =>
    uploadAttachments(teamId, file).then(
      ({ attachments: attachmentsRes }) => attachmentsRes[0],
    )
  const deleteAttachmentFn = (attachmentId) =>
    deleteAttachments(teamId, [attachmentId])

  const onFileReadyToUpload = (fileId) => {
    uploadAttachment(fileId)
  }

  const { attachments, addAttachment, uploadAttachment, deleteAttachment } =
    useAttachments(uploadAttachmentFn, deleteAttachmentFn, onFileReadyToUpload)

  useEffect(() => {
    setFieldValue(formFields.attachments, attachments)
    setTimeout(() => setFieldTouched(formFields.attachments, true))
    setAndMergeTrackedState({ attachments: attachments })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments])

  const onSearchProperties = debounce((searchText) => {
    setLoadingProperties(true)

    return searchProperty(teamId, searchText)
      .then((response) => {
        const filteredProperties = response.properties?.data || []
        return filteredProperties.map(({ property }) => ({
          optionMeta: {
            id: property.guidID,
            displayText: property.address.friendlyName,
          },
          option: property,
        }))
      })
      .then((filteredPropertyOptions) =>
        setPropertiesOptions(filteredPropertyOptions),
      )
      .finally(() => setLoadingProperties(false))
  })

  const onSelectFilesToUpload = (files) => {
    Array.from(files).forEach((file) => {
      const error = addAttachment(file)
      if (error) {
        snugNotifier.error(error)
      }
    })
  }

  const getExpandedLabel = (itemsCount, maxCount) => 'hide'
  const getCollapsedLabel = (itemsCount, maxCount) => `${itemsCount} prospects`

  const renderReceiversElem = () => {
    const { mode, ...relatedConfig } = modeConfig
    if (mode === newMessagesModes.bulky) {
      const { messagesCount } = relatedConfig
      const reachedMaxAllowedReceivers = messagesCount >= MAX_ALLOWED_RECEIVERS
      const alertMessage = reachedMaxAllowedReceivers
        ? `Sending to most recent ${MAX_ALLOWED_RECEIVERS} of ${messagesCount} selected messages`
        : `Sending to all ${messagesCount} selected messages`
      return (
        <Alert
          variant="warningWithBg"
          fontSize={theme.fontSizes.pRegular14}
          mb={theme.space[3] + 'px'}
          mt={theme.space[3] + 'px'}
          textAlign="center"
        >
          {alertMessage}
        </Alert>
      )
    }

    const { invalidMessages } = relatedConfig

    return (
      <>
        <StyledFieldWrapper
          id={formFields.enquirerMessages}
          label="To"
          required={true}
          name={formFields.enquirerMessages}
          mb={theme.space[5] + 'px'}
          mt={theme.space[3] + 'px'}
        >
          <ChipsField
            name={formFields.enquirerMessages}
            maxShownSelections={
              values[formFields.enquirerMessages].length > 1 ? 0 : 1
            }
            getExpandedLabel={getExpandedLabel}
            getCollapsedLabel={getCollapsedLabel}
            dismissEnabled={values[formFields.enquirerMessages].length > 1}
          />
        </StyledFieldWrapper>

        {invalidMessages && !!invalidMessages.length && (
          <Alert
            variant="warningWithBg"
            fontSize={theme.fontSizes.pRegular14}
            mb={theme.space[3] + 'px'}
          >
            {invalidMessages.length} excluded, no email address
          </Alert>
        )}

        <HorizontalLine mb={theme.space[4] + 'px'} />
      </>
    )
  }

  const receiversElem = renderReceiversElem()

  return (
    <Form>
      {!!status && !!status.apiGeneralError && (
        <Alert variant="warningWithBg" mt={theme.space[3] + 'px'}>
          {status.apiGeneralError}
        </Alert>
      )}

      {receiversElem}

      <StyledFieldWrapper
        id={formFields.property}
        label="Property"
        required={false}
        name={formFields.property}
      >
        <AutocompleteField
          name={formFields.property}
          placeholder="Add property (optional)"
          options={propertiesOptions}
          onChangeText={onSearchProperties}
          isLoading={loadingProperties}
        />
      </StyledFieldWrapper>

      <HorizontalLine mb={theme.space[4] + 'px'} />

      {showPreview ? (
        <MessagePreview {...values} />
      ) : (
        <>
          <StyledFieldWrapper
            id={formFields.subject}
            label="Subject"
            required
            name={formFields.subject}
          >
            <Field name={formFields.subject} as={InputField} />
          </StyledFieldWrapper>

          <StyledFieldWrapper
            id={formFields.message}
            label="Message"
            required
            name={formFields.message}
          >
            <Field name={formFields.message} as={TextArea} rows={10} />
          </StyledFieldWrapper>

          <StyledFieldWrapper
            id={formFields.attachments}
            name={formFields.attachments}
            required={false}
          >
            {/* Attachments is not a field component, it is just a view for selected attachments */}
            <Attachments
              attachments={attachments}
              onDismiss={(fileId) => deleteAttachment(fileId)}
              onRetry={(fileId) => uploadAttachment(fileId)}
            />
          </StyledFieldWrapper>
        </>
      )}

      {/*<Flex alignItmes="center" justifyContent="end" my={theme.space[5] + 'px'}>*/}
      {/*  <Button*/}
      {/*    type="button"*/}
      {/*    variant="outlineSuccess"*/}
      {/*    onClick={() => setShowPreview(!showPreview)}*/}
      {/*  >*/}
      {/*    {!showPreview ? 'Preview' : 'Edit'}*/}
      {/*  </Button>*/}
      {/*</Flex>*/}

      <ModalFooter
        showPreview={showPreview}
        onPreviewClicked={() => setShowPreview(!showPreview)}
        sendDisabled={isSubmitting}
        selectAttachments={onSelectFilesToUpload}
      />
    </Form>
  )
}
