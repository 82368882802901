import { Box, Ellipse, Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { getDateWithoutYear } from 'app/utils/datetime/helpers'

type Props = {
  application: any
  borderTop?: boolean
  borderBottom?: boolean

  [x: string]: any
}

export const ApplicationShortCard = ({
  application,
  borderBottom,
  ...containerProps
}: Props) => {
  const {
    applicants,
    adults: adultsCount = 0,
    children = [],
    pets: petsCount = 0,
    weeklyRent = 0,
    leaseStartDate = '',
  } = application || {}

  const primaryApplicant = applicants?.find(
    (applicant: any) => applicant.isPrimary,
  )

  const childrenCount = children?.length || 0

  const secondaryAdultsCount = adultsCount - 1
  const adultsCountStr = secondaryAdultsCount
    ? ` + ${secondaryAdultsCount}`
    : ''

  return (
    <Box
      borderBottom={
        borderBottom ? `${theme.borders[1]} ${theme.colors.grey200}` : ''
      }
      {...containerProps}
    >
      <Box mb={3} lineHeight={theme.lineHeights.encrption}>
        {primaryApplicant?.firstName} {primaryApplicant?.lastName}
        {adultsCountStr}
      </Box>
      <Flex
        alignItems="center"
        color={theme.colors.gray700}
        mb={3}
        fontSize={theme.fontSizes.pRegular14}
      >
        {`${weeklyRent} p/w`}
        <Ellipse mx={2} /> {getDateWithoutYear(leaseStartDate)}
        <Ellipse mx={2} />
        {`${adultsCount} adults ${childrenCount} children ${petsCount} pets`}
      </Flex>
    </Box>
  )
}
