import React from 'react'

import HeaderLg from 'app/sm/progress_application/components/header_lg/component'
import EditableInputComponent, {
  EditableDatePickerComponent,
} from 'app/sm/progress_application/components/input_box_collection/component'
import SectionContainer from 'app/sm/progress_application/components/section_container/component'
const LeaseDetails = ({
  weeklyRent = 0,
  startDate = '',
  terms = '',
  onRentInputFieldChange,
  onTermInputFieldChange,
  onDateChange,
  pickerStatus = false,
  enableEdit = true,
}) => {
  return (
    <SectionContainer>
      <HeaderLg title="Lease details" />
      <div className="display-flex">
        <EditableInputComponent
          prefixText="$"
          title="Rent"
          text={`${weeklyRent}`}
          extraText="/week"
          enableEdit={enableEdit}
          onChange={onRentInputFieldChange}
        />
        <EditableDatePickerComponent
          title="Lease Start"
          text={startDate}
          enableEdit={enableEdit}
          onChange={onDateChange}
        />
        <EditableInputComponent
          title="Term"
          text={terms}
          extraText="&nbsp;months"
          enableEdit={enableEdit}
          onChange={onTermInputFieldChange}
          pickerStatus={pickerStatus}
        />
      </div>
    </SectionContainer>
  )
}

export default LeaseDetails
