import React from 'react'

import {
  Box,
  Button,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'
import TableComponent from 'app/components/design-system-components/Table'
import theme from 'app/match/applicationReportPDF/assets/theme'
import ActionsCell from 'app/pages/teams/messages/components/ActionsCell'
import EnquirerCell from 'app/pages/teams/messages/components/EnquirerCell'
import MessageCell from 'app/pages/teams/messages/components/MessageCell'
import NotesCell from 'app/pages/teams/messages/components/NotesCell'
import PropertyCell from 'app/pages/teams/messages/components/PropertyCell'
import { SelectMessageCell } from 'app/pages/teams/messages/components/SelectMessageCell'
import { SelectMessagesHeader } from 'app/pages/teams/messages/components/SelectMessageHeader'
import * as constants from 'app/pages/teams/messages/helpers/constants'
import {
  leadSourcesConfig,
  rentvestorLeadSource,
} from 'app/pages/teams/messages/helpers/constants'
import { isLeadSource } from 'app/pages/teams/messages/helpers/helpers'
import Spinner from 'app/sm/common/spinner'

function renderEnquirerCell({
  row: {
    original: { enquirer = {}, messages, status = {}, applicationDetails },
  },
}) {
  const {
    source,
    updatedAt,
    guidid: messageGUID,
    createdAt,
    covid19VaccinationStatus,
  } = messages || {}
  return (
    <EnquirerCell
      messageGUID={messageGUID}
      messageSource={source}
      updatedAt={updatedAt}
      createdAt={createdAt}
      {...status}
      {...enquirer}
      covid19VaccinationStatus={covid19VaccinationStatus}
      applicationDetails={applicationDetails}
    />
  )
}

function renderPropertyCell(
  onClickRegisterForViewing,
  scheduleViewingAction,
  onInviteToApply,
  teamGUID,
) {
  return ({
    row: {
      original: {
        property,
        preferences,
        viewings,
        messages = {},
        applicationDetails,
      },
    },
    isRowHovered = false,
  }) => {
    const { guidid: messageGUID, source, address } = messages || {}
    if (source === rentvestorLeadSource) {
      return (
        <Box>
          <TextSpan as="span" fontWeight={theme.fontWeights.bold}>
            Postcodes:{' '}
          </TextSpan>
          {address}
        </Box>
      )
    }
    return (
      <PropertyCell
        property={property}
        preferences={preferences}
        viewings={viewings}
        onClickRegisterForViewing={onClickRegisterForViewing}
        onInviteToApply={onInviteToApply}
        scheduleViewingAction={scheduleViewingAction}
        isRowHovered={isRowHovered}
        messageGUID={messageGUID}
        applicationDetails={applicationDetails}
        teamGUID={teamGUID}
      />
    )
  }
}

function renderMessageCell(toggleInfoModal) {
  return ({
    row: {
      original: { messages, bdmApplicationURL },
    },
    isRowHovered = false,
  }) => {
    if (isLeadSource(messages.source)) {
      return (
        <Box fontSize={theme.fontSizes.pRegular14}>
          <TextSpan as="div">
            {leadSourcesConfig[messages.source]?.messageBody}
          </TextSpan>
          {!!bdmApplicationURL && (
            <Box mt={4}>
              Link to{' '}
              <a href={bdmApplicationURL} target="_blank" rel="noreferrer">
                application
              </a>
            </Box>
          )}
        </Box>
      )
    }
    return (
      <MessageCell
        message={messages}
        toggleInfoModal={toggleInfoModal}
        isRowHovered={isRowHovered}
      />
    )
  }
}

function renderNotesCell(toggleInfoModal, addPrivateNote, teamSlug) {
  return ({
    row: {
      original: { notes = {}, messages = {} },
    },
    isRowHovered = false,
  }) => {
    const { guidid: messageGUID } = messages || {}
    return (
      <NotesCell
        notes={notes}
        toggleInfoModal={toggleInfoModal}
        messageGUID={messageGUID}
        isRowHovered={isRowHovered}
        addPrivateNote={addPrivateNote}
        teamSlug={teamSlug}
      />
    )
  }
}

function renderActionsCell(
  toggleInfoRow,
  markAsUnread,
  markAsRead,
  onArchiveMessage,
  shortlistMessage,
  unshortlistMessage,
  onClickInviteToProperty,
  sendPropertyListAction,
  teamGUID,
  propertyInfo,
  scheduleViewingAction,
) {
  return ({
    row: {
      original: { messages, enquirer = {} },
    },
  }) => {
    const {
      readAt = '',
      guidid = '',
      archived = true,
      shortlisted,
    } = messages || {}
    const offerGUID =
      toggleInfoRow &&
      toggleInfoRow.original &&
      toggleInfoRow.original.property &&
      toggleInfoRow.original.property.offerGUID
    const enquirerHasEmail =
      toggleInfoRow &&
      toggleInfoRow.original &&
      toggleInfoRow.original.enquirer &&
      toggleInfoRow.original.enquirer.email

    return (
      <ActionsCell
        offerGUID={offerGUID}
        messageGUID={guidid}
        archived={archived}
        readAt={readAt}
        shortlisted={shortlisted}
        markAsUnread={markAsUnread}
        markAsRead={markAsRead}
        onArchiveMessage={onArchiveMessage}
        shortlistMessage={shortlistMessage}
        unshortlistMessage={unshortlistMessage}
        onClickInviteToProperty={onClickInviteToProperty}
        sendPropertyListAction={sendPropertyListAction}
        enquirerHasEmail={enquirerHasEmail}
        teamGUID={teamGUID}
        propertyInfo={propertyInfo}
        enquirer={enquirer}
        scheduleViewingAction={scheduleViewingAction}
        disableContext={isLeadSource(messages.source)}
      />
    )
  }
}

const Table = ({
  toggleInfoModal,
  dataToBeEntered,
  toggleAdditionalMenuForRow,
  toggleInfoRow,
  onInviteToApply,
  viewAllNotes,
  markAsRead,
  markAsUnread,
  onArchiveMessage,
  shortlistMessage,
  unshortlistMessage,
  onClickRegisterForViewing,
  propertyInfo,
  sendPropertyList,
  onClickInviteToProperty,
  addPrivateNote,
  scheduleViewingAction,
  onClickLoadMore,
  sendPropertyListAction,
  loadMoreSpinner,
  messagesMetaData,
  fetchingData,
  enquirerInfo,
  toggleMessageSelection,
  selectionConfigs,
  teamGUID = '',
  teamSlug = '',
}) => {
  const tableColumns = [
    {
      Header: () => (
        <SelectMessagesHeader
          hasSelection={selectionConfigs.hasSelection}
          toggleSelection={selectionConfigs.toggleAllMessagesSelection}
          messagesSelectionOptions={selectionConfigs.options}
        />
      ),
      Cell: ({
        row: {
          original: { messages, isSelected },
        },
        isRowHovered,
      }) => {
        return (
          <SelectMessageCell
            isSelected={isSelected}
            toggleMessageSelection={() => toggleMessageSelection(messages)}
          />
        )
      },
      accessor: 'isSelected',
    },
    {
      Header: 'Contact',
      accessor: 'enquirer',
      style: {
        width: `${constants.columnWidths.enquirer}px`,
        padding: '24px 16px',
      },
      Cell: renderEnquirerCell,
    },
    {
      Header: 'Property',
      accessor: 'property',
      style: {
        width: `${constants.columnWidths.property}px`,
        padding: '24px 16px',
      },
      Cell: renderPropertyCell(
        onClickRegisterForViewing,
        scheduleViewingAction,
        onInviteToApply,
        teamGUID,
      ),
    },
    {
      Header: 'Messages',
      accessor: 'messages',
      style: {
        width: `${constants.columnWidths.messages}px`,
        maxWidth: `${constants.columnWidths.messages}px`,
        lineHeight: '140%',
        padding: '24px 16px',
      },
      Cell: renderMessageCell(toggleInfoModal),
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      style: {
        width: `${constants.columnWidths.notes}px`,
        lineHeight: '140%',
        padding: '24px 16px',
      },
      Cell: renderNotesCell(toggleInfoModal, addPrivateNote, teamSlug),
    },
    {
      Header: '',
      accessor: 'actions',
      style: {
        width: `${constants.columnWidths.actions}px`,
        padding: '24px 16px',
      },
      Cell: renderActionsCell(
        toggleInfoRow,
        markAsUnread,
        markAsRead,
        onArchiveMessage,
        shortlistMessage,
        unshortlistMessage,
        onClickInviteToProperty,
        sendPropertyListAction,
        teamGUID,
        propertyInfo,
        scheduleViewingAction,
      ),
    },
  ]

  return (
    !!dataToBeEntered && (
      <>
        <TableComponent
          columns={tableColumns}
          dataToBeEntered={dataToBeEntered}
          onMouseEnter={(event, row) =>
            toggleAdditionalMenuForRow(event, row, true)
          }
          onMouseLeave={(event, row) =>
            toggleAdditionalMenuForRow(event, row, false)
          }
          extraInfoRow={toggleInfoRow.index}
          showShadowOnHover={true}
          showExtraInfo={true}
          showRowBottomBorder={true}
          thPadding="12px 24px"
          textColor="#4d4d4d"
          verticalAlignTop={true}
          boxShadowForRow="box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25)"
          maxRowHeight="118px"
        />
        {messagesMetaData.nextCursor && !fetchingData && (
          <>
            {loadMoreSpinner ? (
              <Spinner />
            ) : (
              <Flex justifyContent="center" alignItems="center" mt="100px">
                <Button
                  variant="outlineSuccess"
                  onClick={onClickLoadMore}
                  width="150px"
                >
                  Load More
                </Button>
              </Flex>
            )}
          </>
        )}
      </>
    )
  )
}

export default Table
