import React from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'

import AgentUtilityProviderUserIDUpdater from 'app/bond_cover/agency/agent_utility_provider_user_id_updater'
import { ResendInvitationModal } from 'app/bond_cover/agency/resend_invitation_modal'
import { Button } from 'app/components/design-system-components'
import { formatPhoneNumber } from 'app/utils/phonenumber/helpers'

class InviteForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      resendModal: { isOpened: false, config: {} },
    }
  }

  openResendModal = (invite) =>
    this.setState({
      resendModal: {
        isOpened: true,
        config: {
          invite,
        },
      },
    })

  closeResendModal = () =>
    this.setState({
      resendModal: {
        isOpened: false,
        config: {},
      },
    })

  resendBtnRenderer(invitation) {
    return (
      <Button
        variant="linkBlue"
        ml={4}
        onClick={() => this.openResendModal(invitation)}
      >
        resend
      </Button>
    )
  }

  render() {
    const {
      title,
      invitesNumber,
      invites,
      errors,
      validateInvite,
      updateInvite,
      removeInvite,
      editable,
      roleEditable = false,
    } = this.props

    const { resendModal } = this.state
    const resendModalElem = resendModal.isOpened && (
      <ResendInvitationModal
        invitation={resendModal.config.invite}
        closeModal={() => this.closeResendModal()}
      />
    )

    return (
      <div>
        {Array(invitesNumber)
          .fill()
          .map((_, i) => i)
          .map((_, i) => (
            <div key={i}>
              <h5>
                {title} {i + 1}
                {title === 'Pending' && (
                  <>
                    <i>, expires {moment(invites[i].expiry).fromNow()}</i>{' '}
                    {this.resendBtnRenderer(invites[i])}
                  </>
                )}
              </h5>
              <div>
                <div className="col-xl-6 row">
                  <div className="col-sm-6">
                    <div className="input-box">
                      <input
                        disabled={!editable}
                        type="text"
                        required
                        className={errors[i].firstName ? 'error' : ''}
                        value={invites[i].firstName}
                        onChange={(e) =>
                          updateInvite('firstName', e.target.value, i)
                        }
                        onBlur={(e) =>
                          validateInvite('firstName', e.target.value, i)
                        }
                      />
                      {editable && <label>First Name</label>}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="input-box">
                      <input
                        disabled={!editable}
                        type="text"
                        required
                        className={errors[i].lastName ? 'error' : ''}
                        value={invites[i].lastName}
                        onChange={(e) =>
                          updateInvite('lastName', e.target.value, i)
                        }
                        onBlur={(e) =>
                          validateInvite('lastName', e.target.value, i)
                        }
                      />
                      {editable && <label>Last Name</label>}
                    </div>
                  </div>

                  {title !== 'Accepted' && (
                    <div className="col-sm-6">
                      <div className="input-box">
                        <input
                          type="text"
                          required
                          className={
                            errors[i].agentUtilityProviderUserID ? 'error' : ''
                          }
                          value={invites[i].agentUtilityProviderUserID}
                          onChange={(e) =>
                            updateInvite(
                              'agentUtilityProviderUserID',
                              e.target.value,
                              i,
                            )
                          }
                          onBlur={(e) =>
                            validateInvite(
                              'agentUtilityProviderUserID',
                              e.target.value,
                              i,
                            )
                          }
                        />
                        <label>AgentUtilityProviderUserID</label>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-xl-6 row mb20">
                  <div className="col-xs-6">
                    <div className="input-box">
                      <input
                        disabled={!editable}
                        type="text"
                        required
                        className={errors[i].email ? 'error' : ''}
                        value={invites[i].email}
                        onChange={(e) =>
                          updateInvite('email', e.target.value, i)
                        }
                        onBlur={(e) =>
                          validateInvite('email', e.target.value, i)
                        }
                      />
                      {editable && <label>Email</label>}
                    </div>
                  </div>

                  {title === 'Accepted' && ( // Display only not form entry, reusing existing pattern
                    <div className="col-xs-6">
                      <div className="input-box">
                        <input
                          disabled={!editable}
                          type="text"
                          required
                          value={formatPhoneNumber(invites[i].mobile)}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-xs-10 mt20">
                    <div className="table">
                      <div className="cell-m display-block-mobile mb10-mobile">
                        <label className="wsnw">
                          <input
                            disabled={!roleEditable ? !editable : !roleEditable}
                            type="checkbox"
                            defaultChecked={false}
                            checked={invites[i].isAdmin}
                            onChange={(e) =>
                              updateInvite('isAdmin', !invites[i].isAdmin, i)
                            }
                          />
                          <span>Admin</span>
                        </label>
                      </div>
                      <div className="cell-m display-block-mobile mb10-mobile">
                        <label className="wsnw">
                          <input
                            disabled={!roleEditable ? !editable : !roleEditable}
                            type="checkbox"
                            defaultChecked={false}
                            checked={invites[i].isFinance}
                            onChange={(e) =>
                              updateInvite(
                                'isFinance',
                                !invites[i].isFinance,
                                i,
                              )
                            }
                          />
                          <span>Finance</span>
                        </label>
                      </div>
                      <div className="cell-m display-block-mobile mb10-mobile">
                        <label className="wsnw">
                          <input
                            disabled={!roleEditable ? !editable : !roleEditable}
                            type="checkbox"
                            defaultChecked={false}
                            checked={invites[i].isLeasingConsultant}
                            onChange={(e) =>
                              updateInvite(
                                'isLeasingConsultant',
                                !invites[i].isLeasingConsultant,
                                i,
                              )
                            }
                          />
                          <span>
                            Casual Inspector (Enquiries/Viewings only)
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  {removeInvite && (
                    <div className="col-xs-2 mt10">
                      <button
                        className="btn btn-round btn-remove"
                        onClick={() => removeInvite(i, invites[i])}
                      >
                        <i className="icon-remove"></i>
                      </button>
                    </div>
                  )}
                </div>

                {title === 'Accepted' && (
                  <AgentUtilityProviderUserIDUpdater agencyUser={invites[i]} />
                )}
              </div>
              <div
                className={
                  errors[i].firstName ? 'alert alert-danger' : 'hide-alert'
                }
              >
                <div>{errors[i].firstName}</div>
              </div>
              <div
                className={
                  errors[i].lastName ? 'alert alert-danger' : 'hide-alert'
                }
              >
                <div>{errors[i].lastName}</div>
              </div>
              <div
                className={
                  errors[i].email ? 'alert alert-danger' : 'hide-alert'
                }
              >
                <div>{errors[i].email}</div>
              </div>
            </div>
          ))}

        {resendModalElem}
      </div>
    )
  }
}

InviteForm.propTypes = {
  editable: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  ).isRequired,
  invitesNumber: PropTypes.number.isRequired,
  removeInvite: PropTypes.func.isRequired,
  updateInvite: PropTypes.func.isRequired,
  validateInvite: PropTypes.func.isRequired,
}

export default InviteForm
