import React from 'react'

import qs from 'qs'
import { connect } from 'react-redux'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { formFields } from 'app/components/Ballot/Disclosures/form.utils'
import {
  formFields as ballotSurveyFormFields,
  formQuestion,
} from 'app/components/Ballot/Survey/form.utils'
import BallotTermAndConditions from 'app/components/Ballot/TermAndConditions'
import { Alert, Box } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import ProgressBarComponent from 'app/components/progress_bar/connection'
import { rentFrequencyOpenOption } from 'app/constants/application-settings.constants'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { getReferrer } from 'app/constants/referrer.constants'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import PropertyInfoBox from 'app/match/apply/apply_form/components/property_info/component'
import { falseStr } from 'app/match/rental_reference/rating_form_base/withRatingFormBasicControls'
import { getRenterAgencySummary } from 'app/services/http/applicant-agency-summary'
import {
  getRenterAboutUs,
  saveRenterAboutUs,
} from 'app/services/http/renter/profile'
import { getIncomeTableForProperty } from 'app/services/http/teams/income-table'
import { getApplicationConfigForProperty } from 'app/services/http/teams/settings'
import * as snugNotifier from 'app/services/snugNotifier'
import {
  addRentalReputationBackUrl,
  clearRentalBackUrl,
  setBackUrl,
} from 'app/shared_components/actions'
import { history } from 'app/shared_components/router'
import { SmallStraightAnglePieChart } from 'app/shared_components/straight_angle_pie_chart'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import * as validations from 'app/shared_components/validations'
import { postBallotSurvey } from 'app/sm/apply/apply_access'
import {
  aboutMeInput,
  acceptApplication,
  acceptJointApplicationAsSecondary,
  addCurrentNewTenant,
  adultInput,
  childrenInput,
  clearCurrentNewTenant,
  createOffPlatformApplication,
  deleteDraftApplication,
  editOffPlatformApplication,
  fetchApplicantSummary,
  fetchApplication,
  fetchApplicationForApplicant,
  fetchApplyAnywhereListing,
  fetchCompleteness,
  fetchProperty,
  groupInput,
  heartClicked,
  householdYearlyInput,
  newTenant,
  onAdderChange,
  peopleInput,
  petsInput,
  ping,
  removeOccupant,
  removeTenant,
  requestsInput,
  saveDraftApplication,
  startNewApplication,
  submitApplication,
  submitQuickApplication,
} from 'app/sm/apply/apply_actions'
import { incomeLimitFromGroup } from 'app/sm/apply/apply_helper'
import { AgencyApplicationsLimitAlert } from 'app/sm/apply/components/AgencyApplicationsLimitAlert'
import ApplyForm from 'app/sm/apply/components/apply_form'
import ApplyOffer from 'app/sm/apply/components/apply_offer'
import ApplySectionWrapper from 'app/sm/apply/components/apply_page_section_wrapper_container'
import ApplySegmentWrapper from 'app/sm/apply/components/apply_page_segment_wrapper_container'
import ApplicationAttachments from 'app/sm/apply/components/attachments'
import BallotSurveyModal from 'app/sm/apply/components/ballot_survey_modal'
import DisclosureContainer from 'app/sm/apply/components/disclosure'
import OfferDetails from 'app/sm/apply/components/offer-details'
import YourOffer from 'app/sm/apply/components/your_offer'
import Spinner from 'app/sm/common/spinner'
import { deleteManagerAttachment } from 'app/sm/documents/actions'
import {
  ApplicationCategory,
  applicationTypeCollection,
  constructAvailableLeaseLengthFromAdderEntity,
  constructDateFromAdderEntity,
  contactRelationshipCollection,
  defaultRentScheduleOptions,
  defaultRequestCarSpaceOptions,
  getQueryString,
  isApplicantApplied,
  isApplicationApplyAnywhere,
  isApplicationEditable,
  isDraftApplication,
  isSecondaryApplicant,
  isStatusAccepted,
  isStatusApplied,
  isStatusDeclined,
  isStatusDeletable,
  isStatusDraft,
  isStatusNew,
  isStatusOffered,
  isStatusQuickApplied,
  isStatusWithdrawableByRenter,
  isStatusWithdrawn,
  parseDataUtil,
  parseURLQuery,
  routes,
  selectApplicants,
  selectPrimaryApplicant,
  smokingType,
  urlTo,
} from 'app/sm/helpers'
import { fetchTeamSettingInformation } from 'app/sm/rental_reputation/actions'
import {
  acceptSecondaryWithdraw,
  acceptWithdraw,
  fetchOfferedApplication,
} from 'app/sm/withdraw_confirmation/withdraw_confirmation_actions'
import { isAutoWithdrawn } from 'app/utils/applications/helpers'
import * as vehicleHelpers from 'app/utils/applications/vehicles'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import { isEmpty } from 'app/utils/objects/helpers'
import * as textHelpers from 'app/utils/text/helpers'

const IS_CAR_SPACE_REQUESTED = true

const emptyAdultForm = {
  email: '',
  firstName: '',
  lastName: '',
  isJointApplicant: true,
  mobileNumber: '',
}

const primaryApplicantNumber = 1

const APPLICATION_DOCUMENT_REMOVED = 'Application document removed'

// how long have renters been renting in the current place
const currentLeaseOptions = [
  { id: '1year', label: '<1', value: 12 },
  { id: '2year', label: '1-2', value: 24 },
  { id: '3year', label: '3+', value: 36 },
]

const generateApplicantSummaryForSubmit = (
  weeklyNetIncome,
  currentRent,
  currentLease,
  isJoint,
  iAmCurrentlyRenting = false,
) => {
  const convertCurrentLeaseOptionToInt = (data) => {
    const selectedOption = currentLeaseOptions.find(
      (option) => option.id === data,
    )
    return selectedOption ? selectedOption.value : 0
  }

  const parsedWeeklyNetIncome = parseFloat(weeklyNetIncome)
  const parsedCurrentRent = parseFloat(currentRent)
  const parsedCurrentLease = convertCurrentLeaseOptionToInt(currentLease)
  const result = {
    weeklyNetIncome: parsedWeeklyNetIncome ? parsedWeeklyNetIncome : 0,
    currentRent:
      iAmCurrentlyRenting && parsedCurrentRent ? parsedCurrentRent : 0,
    currentLease:
      iAmCurrentlyRenting && parsedCurrentLease ? parsedCurrentLease : 0,
    jointLease: isJoint,
  }
  return result
}

const generateApplicantSummaryForUI = (applicantSummary) => {
  const convertNonZeroNumberToString = (number) =>
    number ? number.toString() : ''
  const convertCurrentLeaseOptionToString = (data) => {
    const selectedOption = currentLeaseOptions.find(
      (option) => option.value === data,
    )
    return selectedOption ? selectedOption.id : ''
  }

  return {
    weeklyNetIncome: applicantSummary
      ? convertNonZeroNumberToString(applicantSummary.weeklyNetIncome)
      : '',
    iAmCurrentlyRenting: applicantSummary
      ? applicantSummary.currentRent !== 0 ||
        applicantSummary.currentLease !== 0
      : false,
    currentRent: applicantSummary
      ? convertNonZeroNumberToString(applicantSummary.currentRent)
      : '',
    currentLease:
      applicantSummary &&
      convertCurrentLeaseOptionToString(applicantSummary.currentLease),
  }
}

const ApplyPanel = ({
  snugRank,
  applicationStatus,
  onApplyNextButtonClickedAsSecondaryApplicant,
  clicked,
  onApplyNextButtonClicked,
  onUpdateApplication,
  applicant,
  applicants,
  toggleModal,
  modalOpened,
  onSecondaryButtonClick,
  applicationId,
  secondaryButtonClicked,
  error,
  teamSlug,
  isApplyAnywhere,
  from,
  onApplyAnywhereNextClicked,
  takeHoldingDeposit,
  propertyId,
  urlQueryString,
  isApplicationCreatedByManager,
  editOffPlatformApplication,
  showApplicantScore,
  ballotsEnabled,
  validateBallotAppAndGoToNextPage,
}) => {
  const showSnugRank =
    !isApplyAnywhere &&
    showApplicantScore !== 0 &&
    !isApplicationCreatedByManager &&
    !ballotsEnabled
  return (
    <Display.BottomContentContainer>
      <Display.CenterContentContainer
        componentClass="width100 background-transparent"
        sizeClass="height100"
      >
        <div className="col-xs-3 col-md-5">
          {showSnugRank && (
            <div className="col">
              <div className="rank-top-wrap">
                <div className="rank-fit-chart apply-container pt10">
                  {snugRank && (
                    <SmallStraightAnglePieChart
                      score={Math.round(snugRank.score)}
                    />
                  )}
                  <div className="rank-fit-chart-text">
                    {snugRank && (
                      <span className="snug-score text-align-center">
                        {Math.round(snugRank.score)}
                      </span>
                    )}
                  </div>
                  <ReactTooltip
                    id="snug-match-label"
                    data-type="info"
                    className="tool-tip-style"
                  >
                    <span>{textHelpers.SNUG_MATCH_LABEL}</span>
                  </ReactTooltip>
                  <div className="snug-label-apply-container">
                    <div className="mb5">
                      <span className="desktop-tablet-only">
                        Snug Match Score{' '}
                      </span>
                      <i
                        className="icon-help-outline fs16 gray-light-color ml5"
                        data-tooltip-content=""
                        data-tooltip-id="snug-match-label"
                      />
                    </div>
                    <div className="fs10 desktop-tablet-only">
                      Enhance your profile to boost your score
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-xs-7 float-right">
          <div className="mt15">
            <ApplyForm
              applicationStatus={applicationStatus}
              clicked={clicked}
              applicant={applicant}
              onApplyNextButtonClickedAsSecondaryApplicant={
                onApplyNextButtonClickedAsSecondaryApplicant
              }
              onApplyNextButtonClicked={onApplyNextButtonClicked}
              onUpdateApplication={onUpdateApplication}
              applicants={applicants}
              toggleModal={toggleModal}
              modalOpened={modalOpened}
              onSecondaryButtonClick={onSecondaryButtonClick}
              applicationId={applicationId}
              secondaryButtonClicked={secondaryButtonClicked}
              error={error}
              teamSlug={teamSlug}
              from={from}
              isApplyAnywhere={isApplyAnywhere}
              onApplyAnywhereNextClicked={onApplyAnywhereNextClicked}
              editOffPlatformApplication={editOffPlatformApplication}
              takeHoldingDeposit={takeHoldingDeposit}
              propertyId={propertyId}
              urlQueryString={urlQueryString}
              isApplicationCreatedByManager={isApplicationCreatedByManager}
              ballotsEnabled={ballotsEnabled}
              validateBallotAppAndGoToNextPage={
                validateBallotAppAndGoToNextPage
              }
            />
          </div>
        </div>
      </Display.CenterContentContainer>
    </Display.BottomContentContainer>
  )
}

class ApplyContainer extends React.Component {
  constructor(props) {
    super(props)
    const queryParams = parseURLQuery(this.props.location.search)
    const isApplicationCreatedByManager = queryParams.manageradded === 'true'
    const isCreateApplicationMode = props.match.path.includes('apply')

    this.state = {
      isCreateApplicationMode,
      acceptButtonClicked: false,
      Validations: {
        group: true,
        pets: true,
        people: true,
      },
      unsavedChanges: false,
      spinner: true,
      applyButtonClicked: false,
      applyFormError: '',
      otherApplicantValidated: true,
      applicants: isApplicationCreatedByManager ? [emptyAdultForm] : [],
      applicantsErrors: [{}],
      showValidationError: [{}],

      quickApplyModalOpen: false,
      applyModalOpen: false,
      withdrawModalOpen: false,
      modalOpened: '',

      secondaryButtonClicked: false,

      // for quick apply
      weeklyNetIncome: '',
      iAmCurrentlyRenting: false,
      currentRent: '',
      currentLease: '',
      incomeRentErrors: {
        weeklyNetIncome: '',
        currentRent: '',
        currentLease: '',
      },

      // toast
      showingToast: false,
      preferredRentScheduleOptions: defaultRentScheduleOptions,
      pickedRentSchedule: 0,
      requestCarSpaceOptions: defaultRequestCarSpaceOptions,
      requestCarSpace: 0,
      // segmentIdentify stuff
      lastMatchApplicationField: 'lastMatchApplicationFormViewed',
      isApplyAnywhere: queryParams.applyanywhere === 'true',
      applyAnywhereGUID: '',
      children: [],
      termsError: '',
      isApplicationCreatedByManager,
      otherPlatformApplicationFiles: [],
      otherPlatformSupportingAttachments: [],
      incomeSetByManager: 0,
      disclosureOptions: {},
      isImageRemoving: false,
      allVehicles: [],
      ballotSurveyModalConfig: {
        isOpened: false,
        isFromSecondary: false,
      },
      ballotsHouseholdIncomeLimit: 0,
      ballotsHouseholdIncomeRegion: '',
      solicitedAbovePrice: {
        isAboveDefaultPrice: false,
        isSolicitedAbovePriceChecked: false,
      },

      profileAboutUs: '',
      enableSaveAboutToProfile: true,
      requestVehicleRegistration: true,
    }

    this.ballotSurveyFormRef = React.createRef()
    this.callFetchTeamSettingInfo = this.callFetchTeamSettingInfo.bind(this)
    this.onAttachmentsUpdate = this.onAttachmentsUpdate.bind(this)
    this.onImgRemoveIconClicked = this.onImgRemoveIconClicked.bind(this)
    this.onNumberFieldUpdate = this.onNumberFieldUpdate.bind(this)
    this.updateDisclosureOptions = this.updateDisclosureOptions.bind(this)
    this.submitOffPlatformApplication =
      this.submitOffPlatformApplication.bind(this)
    this.editOffPlatformApplication = this.editOffPlatformApplication.bind(this)
    this.filterListByIndex = this.filterListByIndex.bind(this)
    this.toggleStatus = this.toggleStatus.bind(this)
    this.handleEnableSaveAboutToProfileChange =
      this.handleEnableSaveAboutToProfileChange.bind(this)
    this.ballotDisclosuresFormRef = React.createRef()
  }

  componentDidMount() {
    const { isCreateApplicationMode } = this.state
    const { aboutMeInput } = this.props
    if (isCreateApplicationMode) {
      this.props.startNewApplication()
      getRenterAboutUs().then((resp) => {
        aboutMeInput(resp?.about)
        this.setState({ profileAboutUs: resp?.about })
      })
    }
    // eslint-disable-next-line no-undef
    $('#index').addClass('no-navbar-menu')
    const { propertyId, applicationId } = this.props.match.params
    this.setState({ applicationId: applicationId })
    this.props.fetchCompleteness()
    const { isApplyAnywhere } = this.state

    const {
      referrer_group: urlReferrerGroup,
      referrer_id: urlReferrerId,
      referrer_url: urlReferrerBackUrl,
      image_url: urlPropertyImageUrl,
      withdraw,
    } = parseURLQuery(this.props.location.search)
    // keep dealing with referrer data even if couldn't be identified to know if incorrect details are being passed
    let referrer = getReferrer(urlReferrerGroup, urlReferrerId) || {
      referrer_group: urlReferrerGroup,
      referrer_id: urlReferrerId,
    }
    this.setState({
      referrer,
      referrerBackUrl: urlReferrerBackUrl,
      imageUrlFromUrl: urlPropertyImageUrl,
    })

    this.props.fetchApplicantSummary().then((res) => {
      const applicantSummary = generateApplicantSummaryForUI(res)
      this.setState({ ...applicantSummary, spinner: false })
    })

    if (isApplyAnywhere) {
      this.state.applyAnywhereGUID = propertyId
      this.props
        .fetchApplyAnywhereListing(propertyId)
        .catch((error) => this.setState({ spinner: false }))
      return
    }

    if (propertyId) {
      this.props
        .fetchProperty(propertyId)
        .then((propertyInfo) => {
          const { agencyID, offers } = propertyInfo
          const activeOffer = (offers || []).find((o) => o.isActive) || {}
          const { ballotsEnabled = false, ballotsIncomeTableID = '' } =
            activeOffer || {}
          getRenterAgencySummary(agencyID).then((summary) =>
            this.setState({ applicantAgencySummary: summary }),
          )
          agencyID && this.callFetchTeamSettingInfo(agencyID, 'application')
          agencyID &&
            ballotsEnabled &&
            ballotsIncomeTableID &&
            this.fetchPropertyIncomeTable(agencyID, ballotsIncomeTableID)
        })
        .catch((error) => this.setState({ spinner: false }))
      return
    }

    if (applicationId) {
      // FIXME: this api is not suitable for a manager call, change to another
      this.props.fetchApplicationForApplicant(
        applicationId,
        (applicationDetail) => {
          const { offer = {}, allVehicles = [], config } = applicationDetail
          const { solicitedAbovePrice } = config || {}
          const {
            property = {},
            ballotsIncomeTableID = '',
            ballotsEnabled = false,
          } = offer
          const { agencyID = '' } = property
          const { requestCarSpace } = applicationDetail
          if (applicationDetail.requestCarSpace !== null) {
            this.setState({
              requestCarSpace,
            })
          }
          this.setState({
            applicantAgencySummary: applicationDetail.applicant?.agencySummary,
          })
          this.setState({ allVehicles })
          // TODO: calling fetch team settings needs to be sync
          agencyID && this.callFetchTeamSettingInfo(agencyID, 'application')
          agencyID &&
            ballotsEnabled &&
            ballotsIncomeTableID &&
            this.fetchPropertyIncomeTable(agencyID, ballotsIncomeTableID)
          const applicantSummary = generateApplicantSummaryForUI(
            applicationDetail.applicantSummary,
          )
          let { occupants } = applicationDetail
          occupants = !!occupants ? occupants : []
          const isApplicationCreatedByManager =
            getQueryString('manageradded') === 'true'
          let applicants = applicationDetail.applicants
            .map((applicant) => ({ ...applicant, isJointApplicant: true }))
            .concat(
              occupants.map((applicant) => ({
                ...applicant,
                isJointApplicant: false,
              })),
            )
          if (!isApplicationCreatedByManager) {
            applicants = applicants.filter((applicant) => !applicant.isPrimary)
          }
          const primaryApplicant = applicationDetail.applicants.find(
            (applicant) => applicant.isPrimary,
          )
          this.setState(
            {
              applicants,
              applicantsErrors: applicationDetail.applicants
                .map(() => ({}))
                .concat(occupants.map(() => ({}))),
              showValidationError: applicationDetail.applicants.map(() => ({})),
              ...applicantSummary,
              spinner: false,
              children: applicationDetail.children,
              isApplyAnywhere: isApplicationApplyAnywhere(
                applicationDetail.applicationType,
              ),
              applyAnywhereListingFromApplication:
                applicationDetail.applyAnywhereListing,
              pickedRentSchedule:
                applicationDetail.rentScheduleType ||
                this.state.pickedRentSchedule,
              incomeSetByManager: applicationDetail.income,
              otherPlatformApplicationFiles:
                applicationDetail.managerApplicationFiles || [],
              otherPlatformSupportingAttachments:
                applicationDetail.managerApplicationSupportingFiles || [],
              savedDisclosureOptions: {
                optedToAcceptCondition: primaryApplicant.optedToAcceptCondition,
                optedToAcceptDisclosureConsent:
                  primaryApplicant.optedToAcceptDisclosureConsent,
                optedToReceiveBondLoanInformation:
                  primaryApplicant.optedToReceiveBondLoanInformation,
                optedToReceiveUtilityConnection:
                  primaryApplicant.optedToReceiveUtilityConnection,
                optedToRequirePostingClause:
                  primaryApplicant.optedToRequirePostingClause,
                optedInAsSmoker:
                  primaryApplicant.smokingInfo === smokingType.Smoker,
              },
              solicitedAbovePrice,
            },
            () =>
              this.setState({
                disclosureOptions: this.state.savedDisclosureOptions,
              }),
          )
          const { preferredRentScheduleOptions } = this.state
          const updatedRentScheduleOptions = preferredRentScheduleOptions.map(
            (option) => {
              if (applicationDetail.rentScheduleType === option.index) {
                option.picked = true
              } else {
                option.picked = false
              }
              return option
            },
          )
          this.setState({
            preferredRentScheduleOptions: updatedRentScheduleOptions,
          })

          //update the redux if people is not 0 which potentially means this is an old application.
          applicationDetail.people &&
            this.props.adultInput(applicationDetail.people)
          applicationDetail.children &&
            this.props.childrenInput(applicationDetail.children.length)
          applicationDetail.people && this.props.peopleInput(0)

          let {
            application: { term },
          } = this.props
          if (term) {
            this.updateLeaseLength(term.toString())
          }

          if (withdraw === 'true') this.toggleModal('withdrawOrDelete')

          if (!applicationDetail.aboutMe) {
            getRenterAboutUs().then((resp) => {
              aboutMeInput(resp?.about)
              this.setState({ profileAboutUs: resp?.about })
            })
          }
        },
      )
    }
    if (!applicationId) {
      this.setState({
        preferredRentScheduleOptions: defaultRentScheduleOptions,
      })
    }

    if (this.props.property.guidID) {
      const { propertyId, applicationId } = this.props.match.params
      const {
        address: { friendlyName, state: addrState },
        agencyID = '',
      } = this.props.property
      let isApplied = 'Draft'
      isApplied =
        this.props.applicant && this.props.applicant.applied
          ? 'Submitted'
          : 'Draft'
      let urlQueryString = ''
      if (propertyId) {
        urlQueryString = `?address=${friendlyName}&addr_state=${addrState}&id=${agencyID}&propertyId=${propertyId}&isApplied=${isApplied}`
      }
      if (applicationId) {
        urlQueryString = `?address=${friendlyName}&addr_state=${addrState}&id=${agencyID}&applicationId=${applicationId}&isApplied=${isApplied}`
      }
      this.setState({ urlQueryString })
      const applicationGUID =
        applicationId && applicationId?.toString().split(',')[0]
      this.getApplicationConfig(agencyID, applicationGUID)
    }

    //workaround collecting a logic used in multiple places. This is because we deal with multiple different cases in same place
    //should be refactored. One step is that we're collecting in one place
    const { applyAnywhereListing, applyAnywhereListingFromApplication } =
      this.props
    if (
      applyAnywhereListing?.referrer_group &&
      applyAnywhereListing?.referrer_id
    ) {
      referrer = getReferrer(
        applyAnywhereListing.referrer_group,
        applyAnywhereListing.referrer_group,
      )
    } else if (
      applyAnywhereListingFromApplication?.referrer_group &&
      applyAnywhereListingFromApplication?.referrer_id
    ) {
      referrer = getReferrer(
        applyAnywhereListingFromApplication.referrer_group,
        applyAnywhereListingFromApplication.referrer_group,
      )
    }

    const applyAnywhereImg =
      isApplyAnywhere &&
      (applyAnywhereListing?.referrer_img_url ||
        applyAnywhereListingFromApplication?.referrer_img_url)
    const applyAnywhereBackUrl =
      isApplyAnywhere &&
      (applyAnywhereListing?.referrer_back_url ||
        applyAnywhereListingFromApplication?.referrer_back_url)

    this.setState({
      referrer,
      referrerBackUrl: applyAnywhereBackUrl || urlReferrerBackUrl,
      propertyImageUrl: applyAnywhereImg || urlPropertyImageUrl,
    })
  }

  UNSAFE_componentWillReceiveProps({
    summary,
    property,
    application,
    error,
    match,
    applyAnywhereListing,
  }) {
    const appliedRedirect =
      summary.application !== null &&
      !this.pushOnce &&
      isStatusWithdrawn(application.status) &&
      isStatusDeclined(application.status) &&
      match.path.includes('apply')
    const isApplicationCreatedByManager =
      getQueryString('manageradded') === 'true'
    const { children: existedChildren } = this.state
    const { children: childrenNumber } = application
    let isApplied = 'Draft'
    isApplied =
      this.props.applicant && this.props.applicant.applied
        ? 'Submitted'
        : 'Draft'

    if (
      (!this.props.applyAnywhereListing && applyAnywhereListing) ||
      (applyAnywhereListing && !property.guidID)
    ) {
      this.setUrlQueryStringForApplyAnywhere(applyAnywhereListing)
    }
    if (!this.props.property.guidID && property.guidID) {
      const { propertyId, applicationId } = match.params
      const {
        address: { friendlyName, state: addrState },
        agencyID,
      } = property
      let urlQueryString = ''
      if (propertyId) {
        urlQueryString = `?address=${friendlyName}&addr_state=${addrState}&id=${agencyID}&propertyId=${propertyId}&isApplied=${isApplied}`
      }
      if (applicationId) {
        urlQueryString = `?address=${friendlyName}&addr_state=${addrState}&id=${agencyID}&applicationId=${applicationId}&isApplied=${isApplied}`
      }
      this.setState({ urlQueryString })
      const applicationGUID =
        applicationId && applicationId?.toString()?.split(',')[0]
      this.getApplicationConfig(agencyID, applicationGUID)
    }
    if (this.props.application.children !== application.children) {
      let newChildrenArray = existedChildren
      let sizeDifference = 0
      if (childrenNumber > existedChildren.length) {
        sizeDifference = childrenNumber - existedChildren.length
        for (let i = 0; i < sizeDifference; i++) {
          newChildrenArray.push({
            firstName: '',
            lastName: '',
            dob: '',
            error: '',
          })
        }
      }
      if (childrenNumber < existedChildren.length) {
        newChildrenArray = newChildrenArray.slice(0, childrenNumber)
      }
      this.setState({ children: newChildrenArray })
    }

    if (error) {
      this.setState({ acceptButtonClicked: false, spinner: false })
    }

    if (summary.application === undefined) {
      return
    }

    if (appliedRedirect) {
      if (isApplicationCreatedByManager) {
        return
      }
      this.pushOnce = true
      // This will redirect to property details,
      // if the renter already applied
      // for the same property he is not allowed to apply twice
      history.push(
        urlTo('application', { applicationId: summary.application.guidID }),
      )
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { application } = this.props.summary
    if (!!application) {
      this.onExistingApplicationDetected()
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-undef
    $('#index').removeClass('no-navbar-menu')
    const { application } = this.props.summary
    if (this.state.isApplicationCreatedByManager || !!application) {
      return
    }
    this.saveDraft().catch(() => {})
  }

  getApplicationConfig = (agencyID, applicationID) => {
    getApplicationConfigForProperty(agencyID, applicationID).then(
      ({ config = {} }) => {
        const { exclude_request_vehicle_registration } = config || {}
        this.setState({
          excludeRequestVehicleRegistration:
            exclude_request_vehicle_registration === falseStr ? false : true,
          applicationsSettingsConfig:
            parseDataUtil.convertObjValueFromStringToBoolean(config),
        })
      },
    )
  }

  onChangeVehicleDetails = (event, field, nth) => {
    const { allVehicles } = this.state
    let allVehiclesCopy = [...allVehicles]
    allVehiclesCopy[nth][field] = event.value
    if (field === vehicleHelpers.VEHICLES_KEY_TYPE) {
      allVehiclesCopy[nth][vehicleHelpers.VEHICLES_KEY_TYPE] = Number(
        event.value,
      )
    }
    this.setState({
      allVehicles: allVehiclesCopy,
    })
  }

  onRemoveVehicleButtonClick = (nth) => {
    const { allVehicles } = this.state
    let allVehiclesCopy = [...allVehicles]
    allVehiclesCopy.splice(nth, 1)
    this.setState({
      allVehicles: allVehiclesCopy,
    })
  }

  updateSolicitedAbovePrice = (solicitedAbovePrice) => {
    this.setState({
      solicitedAbovePrice,
    })
  }

  updateVehicleNumber = (vehicles) => {
    this.setState({
      allVehicles: vehicles,
    })
  }

  onExistingApplicationDetected() {
    const { isApplicationCreatedByManager } = this.state
    const { summary, match, history } = this.props
    if (match.path === routes.applyApplication) {
      return
    }

    const { application } = summary
    // moved from apply_action.js to keep same behavior
    if (
      isDraftApplication(application) &&
      !match.path.includes('emergency-contact-details') &&
      !match.path.includes('/rentersummary') &&
      !isApplicationCreatedByManager
    ) {
      history.push(urlTo('application', { applicationId: application.guidID }))
    }
  }

  onAddAnotherClicked = () => {
    const {
      application: { children: childrenNumber },
      childrenInput,
    } = this.props
    childrenInput(childrenNumber + 1)
  }

  onAddButtonClick = () => {
    let {
      applicants,
      applicantsErrors,
      showValidationError,
      isApplicationCreatedByManager,
    } = this.state
    this.setState(
      {
        applicants: applicants.concat([
          {
            firstName: '',
            lastName: '',
            mobileNumber: '',
            email: '',
            isJointApplicant: true,
          },
        ]),
        applicantsErrors: applicantsErrors.concat([{}]),
        showValidationError: showValidationError.concat([{}]),
      },
      () => {
        const totalApplicantNumber = isApplicationCreatedByManager
          ? this.state.applicants.length
          : this.state.applicants.length + primaryApplicantNumber
        this.props.adultInput(totalApplicantNumber)
      },
    )
    this.setUnsavedChanges()
  }

  onApplicantFieldChange = (field, value, i, error, actionType) => {
    let { applicants } = this.state
    const isNotNameField = field !== 'firstName' && field !== 'lastName'

    if (field === 'email') {
      value = value.toLowerCase()
    }

    if (actionType === 'onBlur' && isNotNameField) {
      value = value.replace(/\s+/g, '')
    } else if (actionType === 'onBlur') {
      value = value.trim()
    }

    if (
      applicants[i].guidID &&
      field === 'isJointApplicant' &&
      !applicants[i].sourceStatus
    ) {
      if (applicants[i][field] === true) {
        applicants[i].sourceStatus = contactRelationshipCollection.applicant
      } else {
        applicants[i].sourceStatus = contactRelationshipCollection.occupant
      }
    }

    applicants[i][field] = value
    this.setState({
      applicants,
    })
    this.setApplicantValidationErrors(field, error, i)
    this.setUnsavedChanges()
  }

  onApplyAnywhereNextClicked = () => {
    const { isApplicationCreatedByManager } = this.state
    if (!this.validateApply(isApplicationCreatedByManager)) {
      return
    }

    this.setState({ applyButtonClicked: true })
    const {
      currentUser,
      application,
      isJoint,
      saveDraftApplication,
      completeness = {},
    } = this.props
    const {
      lastMatchApplicationField,
      applyButtonClicked,
      weeklyNetIncome,
      currentRent,
      currentLease,
      applicants,
      secondaryButtonClicked,
      iAmCurrentlyRenting,
      isApplyAnywhere,
      applyAnywhereGUID,
      children = [],
      urlQueryString,
      pickedRentSchedule,
      requestCarSpace,
      parsedApplicationSettingInfo = {},
    } = this.state
    const applicationOption = { pickedRentSchedule }
    if (parsedApplicationSettingInfo.request_car_space) {
      applicationOption['requestCarSpace'] = requestCarSpace
    }
    const applicationStatus = ApplicationCategory[application.status]
    const isStatusNewOrDraft =
      isStatusNew(applicationStatus) || isStatusDraft(applicationStatus)

    //TODO: refactor htis or reuse componentwillunmount
    const allEssentialFilled =
      completeness.backgroundCheck &&
      completeness.rentalHistory &&
      completeness.employmentDetails &&
      completeness.income &&
      completeness.identityDocument
    if (isStatusNewOrDraft && !applyButtonClicked && !secondaryButtonClicked) {
      const applicantSummary = generateApplicantSummaryForSubmit(
        weeklyNetIncome,
        currentRent,
        currentLease,
        isJoint,
        iAmCurrentlyRenting,
      )
      saveDraftApplication(
        applicants,
        applicantSummary,
        isApplyAnywhere,
        applyAnywhereGUID,
        children,
        applicationOption,
      )
        .then((returnedApplication) => {
          this.props.fetchApplication(returnedApplication.guidID)
          this.setState({ applicationId: returnedApplication.guidID })
          this.props.addRentalReputationBackUrl(
            `${urlTo('application', {
              applicationId: returnedApplication.guidID,
            })}${urlQueryString}`,
          )
          allEssentialFilled &&
            history.push(
              urlTo('applicationSummary', {
                applicationId: returnedApplication.guidID,
              }),
            )
          !allEssentialFilled &&
            history.push(
              `${urlTo(
                'bondCoverRentalHistory',
              )}${urlQueryString}&applicationId=${
                returnedApplication.guidID
              }&stage=essentials`,
            )
        })
        .catch((error) => {
          snugNotifier.error(translateErrorCodeToMessage(error))
          this.setState({ applyButtonClicked: false })
        })
    }
  }

  onSaveApplicationAndRedirectProfile() {
    const { isApplyAnywhere, urlQueryString } = this.state
    const from = this.getFromQueryString()

    const applicationId =
      this.props.applicant && this.props.applicant.applicationID

    const offer = this.getPropertyOffer()
    const { ballotsEnabled = false } = offer || {}

    if (ballotsEnabled) {
      this.setState({ applyButtonClicked: true })
      this.saveDraft()
        .then((returnedApplication) => {
          this.validateBallotAppAndGoToNextPage(
            returnedApplication.guidID,
            false,
          )
        })
        .catch((error) => {
          this.setState({
            applyButtonClicked: false,
          })
        })
    }

    if (!ballotsEnabled) {
      if (!this.validateSolicitedAbovePrice()) {
        return
      }
      this.setState({ applyButtonClicked: true })
      this.saveDraft()
        .then((returnedApplication) => {
          const navigateToRenterSummary =
            from === 'rentersummary' || isApplyAnywhere
          navigateToRenterSummary
            ? history.push(urlTo('applicationSummary', { applicationId }))
            : history.push(
                `${urlTo(
                  'rentalHistorySummary',
                )}${urlQueryString}&applicationId=${
                  returnedApplication.guidID
                }&stage=essentials`,
              )
        })
        .catch((error) => {
          this.setState({
            applyButtonClicked: false,
          })
        })
    }
  }

  onApplyNextButtonClicked = () => {
    const {
      isApplicationCreatedByManager,
      profileAboutUs,
      enableSaveAboutToProfile,
    } = this.state
    const { application } = this.props
    const { isManagerAdded, status } = application

    if (!this.validateApply(isApplicationCreatedByManager)) {
      return
    }

    const offer = this.getPropertyOffer()
    const { ballotsEnabled = false } = offer || {}

    if (isApplicationCreatedByManager && !isManagerAdded) {
      this.submitOffPlatformApplication()
      return
    }

    if (application.aboutMe !== profileAboutUs && enableSaveAboutToProfile) {
      saveRenterAboutUs(application.aboutMe).catch((err) =>
        snugNotifier.error(translateErrorCodeToMessage(err)),
      )
    }

    const applicationStatus = application
      ? ApplicationCategory[application.status]
      : undefined

    if (ballotsEnabled && isStatusNew(applicationStatus)) {
      this.setBallotDisclosureFieldTouch()
      this.ballotDisclosuresFormRef.current.validateForm().then((errors) => {
        if (isEmpty(errors)) {
          this.toggleBallotSurveyModal(false)
        } else {
          snugNotifier.error('Please accept the eligibility disclosures')
        }
      })
    } else {
      this.onSaveApplicationAndRedirectProfile()
    }
  }

  setBallotDisclosureFieldTouch = () => {
    this.ballotDisclosuresFormRef.current.setFieldTouched(
      formFields.acceptBallotTermsAndConditions,
      true,
      true,
    )
    this.ballotDisclosuresFormRef.current.setFieldTouched(
      formFields.victorianResidentsOrAustralianCitizens,
      true,
      true,
    )
    this.ballotDisclosuresFormRef.current.setFieldTouched(
      formFields.householdMeetsIncomeLimits,
      true,
      true,
    )
    this.ballotDisclosuresFormRef.current.setFieldTouched(
      formFields.noHouseholdMembersOwnProperty,
      true,
      true,
    )
    this.ballotDisclosuresFormRef.current.setFieldTouched(
      formFields.documentsWithinDays,
      true,
      true,
    )
    this.ballotDisclosuresFormRef.current.setFieldTouched(
      formFields.householdCanMoveIn,
      true,
      true,
    )
  }

  validateBallotAppAndGoToNextPage = (
    applicationGUID,
    fromSecondary = false,
  ) => {
    const { urlQueryString } = this.state
    this.setBallotDisclosureFieldTouch()
    this.ballotDisclosuresFormRef.current.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        const applicationQuery = !urlQueryString.includes('applicationId')
          ? `${urlQueryString}&applicationId=${applicationGUID}&stage=essentials&ballot=true`
          : `${urlQueryString}&stage=essentials&ballot=true`
        if (!fromSecondary) {
          history.push(`${urlTo('rentalHistorySummary')}${applicationQuery}`)
        }

        if (fromSecondary) {
          this.toggleBallotSurveyModal(true)
        }
      } else {
        snugNotifier.error('Please accept the eligibility disclosures')
        this.setState({
          applyButtonClicked: false,
        })
      }
    })
  }

  onApplyNextButtonClickedAsSecondaryApplicant = () => {
    const {
      currentLease,
      currentRent,
      weeklyNetIncome,
      applicants,
      iAmCurrentlyRenting,
      urlQueryString,
    } = this.state
    const applicationGUID = this.props.applicant.applicationID
    const applicantGUID = this.props.applicant.guidID

    const applicantSummary = generateApplicantSummaryForSubmit(
      weeklyNetIncome,
      currentRent,
      currentLease,
      this.props.isJoint,
      iAmCurrentlyRenting,
    )
    const offer = this.getPropertyOffer()
    const { ballotsEnabled = false } = offer || {}
    if (ballotsEnabled) {
      this.setBallotDisclosureFieldTouch()
      this.ballotDisclosuresFormRef.current.validateForm().then((errors) => {
        if (isEmpty(errors)) {
          return this.toggleBallotSurveyModal(true)
        } else {
          const errorMessage = 'Please accept the eligibility disclosures'
          snugNotifier.error(errorMessage)
          return Promise.reject(errorMessage)
        }
      })
    }

    if (!ballotsEnabled) {
      return this.props
        .acceptJointApplicationAsSecondary(
          applicationGUID,
          applicantGUID,
          applicantSummary,
          false,
        )
        .then(() => {
          this.setState(
            {
              unsavedChanges: false,
              applyButtonClicked: true,
            },
            () => {
              const from = this.getFromQueryString()
              from === 'rentersummary'
                ? history.push(
                    urlTo('applicationSummary', {
                      applicationId: applicantGUID,
                    }),
                  )
                : history.push(
                    `${urlTo(
                      'rentalHistorySummary',
                    )}${urlQueryString}&applicationId=${applicationGUID}&stage=essentials`,
                  )
            },
          )
          return Promise.resolve()
        })
        .catch((error) => {
          this.setState({ applyButtonClicked: false })
          snugNotifier.error(translateErrorCodeToMessage(error))
          return Promise.reject(error)
        })
    }
  }

  onAttachmentsUpdate = (field, option = {}) => {
    const { type = 0 } = option
    return (data) => {
      const updateFiles = data.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )
      this.setState({ [field]: this.state[field].concat(updateFiles) })
      return
    }
  }

  onBlurShowValidationError = (field, nth) => {
    let { showValidationError } = this.state
    showValidationError[nth][field] = true
    this.setState({
      showValidationError,
    })
  }

  onChangeCurrentLease = (event) => {
    const { target } = event
    const { id } = target
    this.setState({
      currentLease: id,
      incomeRentErrors: { ...this.state.incomeRentErrors, currentLease: '' },
    })
    this.setUnsavedChanges()
  }

  onChangeIamCurrentlyRenting = () => {
    this.setState({ iAmCurrentlyRenting: !this.state.iAmCurrentlyRenting })
    this.setUnsavedChanges()
  }

  onChangeIncomeRent = (field) => {
    return (data) => {
      this.setState({
        [field]: data.value,
        incomeRentErrors: {
          ...this.state.incomeRentErrors,
          [field]: data.error,
        },
      })
      this.setUnsavedChanges()
    }
  }

  onChildDelete = (index) => {
    return () => {
      const { children } = this.state
      const {
        application: { children: childrenNumber },
        childrenInput,
      } = this.props
      children.splice(index, 1)
      this.setState({ children })
      childrenInput(childrenNumber - 1)
    }
  }

  onChildrenDateOfBirthChanged = (index) => {
    return (data) => {
      const formattedDate = dateTimeHelpers.formatYearOfBirth(data.value)
      const { children } = this.state
      children[index]['dob'] = formattedDate
      this.setState({
        children,
      })
    }
  }

  onChildrenFocus = (index) => {
    return () => {
      const { children } = this.state
      children[index]['error'] = ''
      this.setState({
        children,
      })
    }
  }

  onChildrenInfoBlured = (index) => {
    return (event, field) => {
      const { value } = event.target
      const { children } = this.state
      if (field === 'firstName') {
        children[index]['error'] = validations.validateFirstName(value)[0]
      }
      if (field === 'lastName') {
        children[index]['error'] = validations.validateLastName(value)[0]
      }
      this.setState({ children })
    }
  }

  onChildrenInfoChanged = (index) => {
    return (event, field) => {
      const { children } = this.state
      const { value } = event.target
      children[index][field] = value

      this.setState({ children })
    }
  }

  onFocusHideValidationError = (field, nth) => {
    let { showValidationError } = this.state
    showValidationError[nth][field] = false
    this.setState({
      showValidationError,
    })
  }

  onImgRemoveIconClicked = (field) => {
    return (chosenIndex) => {
      const { deleteManagerAttachment } = this.props
      const deletingFile = this.state[field].find(
        (_, index) => index === chosenIndex,
      )
      if (deletingFile.guidID) {
        this.setState({
          isImageRemoving: true,
        })
        deleteManagerAttachment(deletingFile.guidID)
          .then(() => {
            snugNotifier.success(APPLICATION_DOCUMENT_REMOVED)
            this.filterListByIndex(field, chosenIndex)
          })
          .catch((error) => {
            snugNotifier.error(error)
          })
          .finally(() => {
            this.setState({
              isImageRemoving: false,
            })
          })
        return
      }
      this.filterListByIndex(field, chosenIndex)
    }
  }

  onLeaseTermBoxChanged = (value) => {
    const offer = this.getPropertyOffer()
    const { ballotsEnabled = false } = offer || {}
    let availableLeaseLength = constructAvailableLeaseLengthFromAdderEntity(
      this.props.adders.term,
    )
    if (ballotsEnabled) {
      this.updateLeaseLength(
        textHelpers.rentalTermForBallotApplications.toString(),
      )
      return
    }
    const changedTermOption = availableLeaseLength.filter(
      (option) => option.length == value,
    )[0]
    availableLeaseLength.map((option) => {
      if (option.length === changedTermOption.length) {
        option.picked = !option.picked
      }
    })
    let termString = ''
    availableLeaseLength.map((option) => {
      if (option.picked === true) {
        termString = `${termString},${option.length}`.replace(/^,/, '').trim()
      }
    })
    this.updateLeaseLength(termString)

    this.setUnsavedChanges()
  }

  onNumberFieldUpdate = (field) => (value) => {
    this.setState({ [field]: value })
  }

  onOtherApplicantFormOnBlur = (isComplete, isValidated, fromRemoveButton) => {
    if (isComplete && isValidated) {
      this.setState({
        otherApplicantValidated: true,
      })
    } else {
      this.setState({
        otherApplicantValidated: false,
      })
    }
  }

  onRemoveButtonClick = (nth) => {
    let { applicants, applicantsErrors, isApplicationCreatedByManager } =
      this.state
    const { applicationId } = this.props.match.params
    const {
      removeTenant,
      property: { agencyID: teamId = '' } = {},
      removeOccupant,
    } = this.props
    const applicant = applicants[nth]
    const applicantId = applicants[nth].guidID
    const newApplicants = applicants.filter((value, index) => {
      return index !== nth
    })
    const newApplicantsErrors = applicantsErrors.filter((value, index) => {
      return index !== nth
    })
    if (applicantId && applicationId) {
      if (applicants[nth].isPrimary) {
        this.setState({
          applicants: newApplicants,
          applicantsErrors: newApplicantsErrors,
        })
      } else {
        const { isJointApplicant } = applicant
        isJointApplicant
          ? removeTenant(applicantId, () => {
              this.setState({
                applicants: newApplicants,
                applicantsErrors: newApplicantsErrors,
              })
            })
          : removeOccupant(teamId, applicantId)
              .then(() => {
                this.setState({
                  applicants: newApplicants,
                  applicantsErrors: newApplicantsErrors,
                })
              })
              .catch((error) => snugNotifier.error(error))
      }
    } else {
      this.setState({
        applicants: newApplicants,
        applicantsErrors: newApplicantsErrors,
      })
    }
    const totalApplicantNumber = isApplicationCreatedByManager
      ? newApplicants.length
      : newApplicants.length + primaryApplicantNumber
    this.props.adultInput(totalApplicantNumber)
    this.setUnsavedChanges()
  }

  onRemoveLastApplicant() {
    const {
      applicants = [],
      applicantsErrors = [],
      isApplicationCreatedByManager,
    } = this.state
    const indexForLastApplicant = applicants.length - 1
    this.onRemoveButtonClick(indexForLastApplicant)
    if (applicants.length > 0) {
      applicants.pop()
      applicantsErrors.pop()
      this.setState({
        applicants,
        applicantsErrors,
      })
    }
  }

  onSecondaryButtonClick = (
    applicationId,
    applicantId,
    isSecondaryApplicant,
  ) => {
    const { application } = this.props
    const applicationStatus = application
      ? ApplicationCategory[application.status]
      : undefined
    if (isStatusDeletable(applicationStatus)) {
      this.deleteDraftApplication(applicationId)
    } else if (
      isStatusWithdrawableByRenter(applicationStatus) &&
      !isSecondaryApplicant
    ) {
      this.withdrawApplication(applicationId)
    } else if (
      isStatusWithdrawableByRenter(applicationStatus) &&
      isSecondaryApplicant
    ) {
      this.withdrawSecondaryApplicant(applicationId, applicantId)
    }
  }
  onUpdateAdultInput = (value) => {
    const { adultInput } = this.props
    const { isApplicationCreatedByManager, applicants = [] } = this.state
    adultInput(value)
    const secondaryApplicantNumber = value - primaryApplicantNumber
    const updatedAdultsNumber = isApplicationCreatedByManager
      ? value
      : secondaryApplicantNumber
    if (updatedAdultsNumber > applicants.length) {
      this.onAddButtonClick()
    } else {
      this.onRemoveLastApplicant()
    }
  }
  onUpdateApplication = () => {
    const { isApplicationCreatedByManager } = this.state
    if (!this.validateApply(isApplicationCreatedByManager)) {
      return
    }
    this.setState({
      applyButtonClicked: true,
    })
    const { currentUser, application } = this.props
    const { isManagerAdded } = application
    if (isApplicationCreatedByManager && isManagerAdded) {
      this.submitOffPlatformApplication()
      return
    }
    const applicationId = this.props.applicant.applicationID
    const { urlQueryString } = this.state
    const {
      currentLease,
      currentRent,
      weeklyNetIncome,
      applicants,
      iAmCurrentlyRenting,
      children,
      lastMatchApplicationField,
      pickedRentSchedule,
      requestCarSpace,
      parsedApplicationSettingInfo = {},
      allVehicles,
    } = this.state
    const applicationOption = {
      pickedRentSchedule,
    }
    if (parsedApplicationSettingInfo.request_car_space) {
      applicationOption['requestCarSpace'] = requestCarSpace
    }
    if (parsedApplicationSettingInfo?.id_docs_not_requested) {
      applicationOption['idDocsNotRequested'] =
        parsedApplicationSettingInfo.id_docs_not_requested
    }
    const excludeRequestVehicleRegistration =
      parsedApplicationSettingInfo?.exclude_request_vehicle_registration

    let allVehiclesCopy = vehicleHelpers.fillNotProvidedIfEmptyRegNum(
      allVehicles,
      excludeRequestVehicleRegistration,
    )
    const applicantSummary = generateApplicantSummaryForSubmit(
      weeklyNetIncome,
      currentRent,
      currentLease,
      this.props.isJoint,
      iAmCurrentlyRenting,
    )
    return this.props
      .submitApplication(
        false,
        applicants,
        applicantSummary,
        null,
        this.apiFail,
        children,
        applicationOption,
        allVehiclesCopy,
      )
      .then(() => {
        const { agencyID = '' } = this.props.property
        this.setState({ unsavedChanges: false }, () => {
          const from = this.getFromQueryString()
          !isApplicationCreatedByManager &&
            this.props.addRentalReputationBackUrl(
              `${urlTo('application', { applicationId })}${
                window.location.search
              }`,
            )
          from === 'rentersummary'
            ? history.push(urlTo('applicationSummary', { applicationId }))
            : history.push(
                `${urlTo(
                  'rentalHistorySummary',
                )}${urlQueryString}&id=${agencyID}&applicationId=${applicationId}&stage=essentials`,
              )
        })
      })
      .catch((error) => {
        this.setState({ applyButtonClicked: false })
        snugNotifier.error(translateErrorCodeToMessage(error))
      })
  }

  getFromQueryString = () => {
    const { from = '' } = parseURLQuery(this.props.location.search)
    return from
  }

  setApplicantValidationErrors(field, error, i) {
    let applicantsErrors = this.state.applicantsErrors
    if (error.length == 0) {
      // No validation errors
      delete applicantsErrors[i][field]
      this.setState({ applicantsErrors })
      return true
    } else {
      // Validation errors
      applicantsErrors[i][field] = error
      this.setState({ applicantsErrors })
      return false
    }
  }

  setUnsavedChanges = () => {
    this.setState({ unsavedChanges: true })
  }
  setUrlQueryStringForApplyAnywhere = (applyAnywhereListing) => {
    const { friendlyName, suburb } = applyAnywhereListing.address
    const { guid } = applyAnywhereListing
    const applicationAddress = `${friendlyName}, ${suburb}`
    const urlQueryString = `?address=${applicationAddress}&propertyId=${guid}`
    this.setState({ urlQueryString })
  }

  setValidation(field, ok) {
    this.setState({
      Validations: Object.assign({}, this.state.Validations, {
        [field]: ok,
      }),
    })
  }

  activateSpinner() {
    this.setState({ acceptButtonClicked: true })
  }

  apiFail = (responseText) => {
    this.setState({
      withdrawModalOpen: false,
      applyFormError: responseText,
      applyButtonClicked: false,
    })
  }
  callFetchTeamSettingInfo = (teamId, queryString) => {
    const { fetchTeamSettingInformation } = this.props
    fetchTeamSettingInformation(teamId, queryString)
      .then((teamSettingInfo) => {
        const { application: applicationSettingInfo = {} } = teamSettingInfo
        const parsedApplicationSettingInfo =
          parseDataUtil.convertObjValueFromStringToBoolean(
            applicationSettingInfo,
          )
        this.setState({ parsedApplicationSettingInfo })
        this.setRequestCarOptionSelection(parsedApplicationSettingInfo)
      })
      .catch((error) => {
        this.setState({ error })
      })
  }

  fetchPropertyIncomeTable = (teamID, incomeTableID) => {
    getIncomeTableForProperty(teamID, incomeTableID).then(
      ({ data: incomeTableData, region }) => {
        this.setState({
          ballotsHouseholdIncomeLimit: incomeTableData,
          ballotsHouseholdIncomeRegion: region,
        })
      },
    )
  }

  setRequestCarOptionSelection(parsedApplicationSettingInfo) {
    if (parsedApplicationSettingInfo?.request_car_space) {
      const { requestCarSpaceOptions } = this.state
      const { requestCarSpace: currentRequestCarSpaceOption } = this.state
      const updatedRequestCarSpaceOptions = requestCarSpaceOptions.map(
        (option) => {
          return {
            ...option,
            picked: currentRequestCarSpaceOption === option.model,
          }
        },
      )
      const carOption = updatedRequestCarSpaceOptions.find(
        (o) => o.model === currentRequestCarSpaceOption,
      )
      this.setState({
        requestCarSpaceOptions: updatedRequestCarSpaceOptions,
        requestCarSpace: carOption ? carOption.model : 0,
      })
    }
  }

  deleteDraftApplication = (applicationId) => {
    const { deleteDraftApplication } = this.props
    this.setState({
      secondaryButtonClicked: true,
      applyFormError: '',
    })
    deleteDraftApplication(
      applicationId,
      () => {
        history.push(urlTo('applications'))
      },
      (responseText) => {
        this.setState({
          secondaryButtonClicked: false,
          applyFormError: responseText,
          withdrawModalOpen: false,
        })
      },
    )
  }
  editOffPlatformApplication = () => {
    const {
      currentUser,
      application,
      isJoint,
      adders,
      propertyId,
      updateOffPlatformApplication,
    } = this.props
    const { guidID: applicationId } = application
    const {
      lastMatchApplicationField,
      applyButtonClicked,
      weeklyNetIncome,
      currentRent,
      currentLease,
      applicants,
      secondaryButtonClicked,
      iAmCurrentlyRenting,
      applyAnywhereGUID,
      children,
      pickedRentSchedule,
      requestCarSpace,
      incomeSetByManager,
      disclosureOptions,
      otherPlatformApplicationFiles = [],
      otherPlatformSupportingAttachments = [],
      isApplicationCreatedByManager,
      parsedApplicationSettingInfo = {},
    } = this.state
    const applicationOption = { pickedRentSchedule }
    if (parsedApplicationSettingInfo.request_car_space) {
      applicationOption['requestCarSpace'] = requestCarSpace
    }
    const applicationStatus = ApplicationCategory[application.status]
    const isStatusNewOrDraft =
      isStatusNew(applicationStatus) || isStatusDraft(applicationStatus)
    if (!this.validateApply(isApplicationCreatedByManager)) {
      return
    }
    if (!this.extraValidationForOffPlatformApplication()) {
      return
    }
    // save draft only when new. However, after applying application status will not be
    // TODO: this should be refactored as applyButtonClicked is used as a UI state mostly
    if (!applyButtonClicked && !secondaryButtonClicked) {
      const applicantSummary = generateApplicantSummaryForSubmit(
        weeklyNetIncome,
        currentRent,
        currentLease,
        isJoint,
        iAmCurrentlyRenting,
      )
      this.toggleStatus('applyButtonClicked')
      // Todo: set the primary applicant
      const formattedApplication = Object.assign({}, application, {
        rent: adders['rent'].quantity,
        term: adders['term'].quantity.toString(),
        moveInDate: constructDateFromAdderEntity(adders['moveInDate']),
        applicationType: applicationTypeCollection.standard,
        rentScheduleType: pickedRentSchedule,
        requestCarSpace: requestCarSpace === IS_CAR_SPACE_REQUESTED,
        applicants: applicants
          .filter((applicant) => applicant.isJointApplicant)
          .map((applicant, index) => ({
            ...applicant,
            mobileNumber:
              applicant.mobileNumber &&
              applicant.mobileNumber.replace(/\s+/g, ''),
            email: applicant.email && applicant.email.replace(/\s+/g, ''),
            guidID:
              applicant.sourceStatus === contactRelationshipCollection.occupant
                ? ''
                : applicant.guidID,
            isPrimary: index === 0 ? true : false,
            ...disclosureOptions,
            smokingInfo: disclosureOptions.optedInAsSmoker
              ? smokingType.Smoker
              : smokingType.NonSmoker,
          })),
        occupants: applicants
          .filter((applicant) => !applicant.isJointApplicant)
          .map((applicant) => ({
            ...applicant,
            mobileNumber:
              applicant.mobileNumber &&
              applicant.mobileNumber.replace(/\s+/g, ''),
            email: applicant.email && applicant.email.replace(/\s+/g, ''),
            guidID:
              applicant.sourceStatus === contactRelationshipCollection.applicant
                ? ''
                : applicant.guidID,
          })),
        children,
        applicantSummary,
        offerID: propertyId,
        income: incomeSetByManager,
        otherPlatformApplicationFiles: otherPlatformApplicationFiles.filter(
          (file) => !file.guidID,
        ),
        otherPlatformSupportingAttachments:
          otherPlatformSupportingAttachments.filter((file) => !file.guidID),
      })
      updateOffPlatformApplication(formattedApplication, applicationId)
        .then(() => {
          this.setState({ applyButtonClicked: true })
          history.goBack()
        })
        .catch((error) => {
          this.setState({ applyButtonClicked: false })
          snugNotifier.error(error)
        })
    }
    return Promise.resolve()
  }
  extraValidationForOffPlatformApplication = () => {
    const { incomeSetByManager, disclosureOptions } = this.state
    const { optedToAcceptCondition, optedToAcceptDisclosureConsent } =
      disclosureOptions
    if (!incomeSetByManager || incomeSetByManager <= 0) {
      snugNotifier.error('Income must be higher than 0')
      return false
    }
    if (!optedToAcceptDisclosureConsent) {
      snugNotifier.error('You must accept the disclosure consent to proceed')
      return false
    }
    if (!optedToAcceptCondition) {
      snugNotifier.error('You must accept the condition to proceed')
      return false
    }
    return true
  }
  filterListByIndex = (field, chosenIndex) => {
    const filteredFiles = this.state[field].filter(
      (_, index) => index !== chosenIndex,
    )
    this.setState({
      [field]: filteredFiles,
    })
  }

  isEmptyObject(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false
      }
    }
    return true
  }

  newOnChildrenInfoChanged = (index, field) => {
    return (data) => {
      const { children } = this.state
      children[index][field] = data.value
      this.setState({ children })
    }
  }

  noApplicantsErrors(isApplicationCreatedByManager = false) {
    const { applicants, applicantsErrors } = this.state
    if (applicants) {
      for (const i in applicants) {
        if (applicants[i].isJointApplicant) {
          if (
            applicants[i] &&
            !(
              applicants[i].firstName &&
              applicants[i].lastName &&
              applicants[i].email
            )
          ) {
            return false
          }
          if (!isApplicationCreatedByManager && !applicants[i].mobileNumber) {
            return false
          }
        } else {
          const isEmailOrMobileNumberFilled =
            applicants[i].email || applicants[i].mobileNumber
          if (
            applicants[i] &&
            (!applicants[i].firstName ||
              !applicants[i].lastName ||
              !isEmailOrMobileNumberFilled)
          ) {
            return false
          }
        }
      }
    }
    if (applicantsErrors) {
      for (const i in applicantsErrors) {
        if (!this.isEmptyObject(applicantsErrors[i])) {
          return false
        }
      }
    }
    return true
  }

  reactToastify = (errorMessage = '') => {
    this.state.showingToast === false &&
      snugNotifier.error(errorMessage || this.state.applyFormError, {
        onOpen: () => this.setState({ showingToast: true }),
        onClose: () => this.setState({ showingToast: false }),
      })
  }
  saveDraft = () => {
    const {
      currentUser,
      application,
      isJoint,
      saveDraftApplication,
      property,
    } = this.props
    const {
      lastMatchApplicationField,
      applyButtonClicked,
      weeklyNetIncome,
      currentRent,
      currentLease,
      applicants,
      secondaryButtonClicked,
      iAmCurrentlyRenting,
      isApplyAnywhere,
      applyAnywhereGUID,
      children,
      preferredRentScheduleOptions,
      pickedRentSchedule,
      requestCarSpace,
      parsedApplicationSettingInfo = {},
      allVehicles,
      referrer,
      referrerBackUrl,
      solicitedAbovePrice,
      applicationsSettingsConfig,
    } = this.state

    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })

    const { allowSkippingDisclosures = '' } = curQueries || {}
    const config = {
      applyOptions: {
        allowApplicantToSkipViewingDisclosures: allowSkippingDisclosures,
      },
      solicitedAbovePrice,
      applicationsSettingsConfig,
    }
    const applicationOption = { pickedRentSchedule }
    if (parsedApplicationSettingInfo.request_car_space) {
      applicationOption['requestCarSpace'] = requestCarSpace
    }
    const applicationStatus = ApplicationCategory[application.status]
    const isStatusNewOrDraft =
      isStatusNew(applicationStatus) || isStatusDraft(applicationStatus)

    let allVehiclesCopy =
      vehicleHelpers.fillNotProvidedIfEmptyRegNum(allVehicles)
    //for standard applications, check for possible handoff referrer, if found pass to BE to create corresponding records
    const referrerPayload = !isApplyAnywhere &&
      referrer && {
        referrerGroup: referrer.referrer_group,
        referrerID: referrer.referrer_id,
        referrerURL: referrerBackUrl,
      }
    if (parsedApplicationSettingInfo?.id_docs_not_requested) {
      applicationOption['idDocsNotRequested'] =
        parsedApplicationSettingInfo.id_docs_not_requested
    }

    const offer = this.getPropertyOffer()
    const { ballotsEnabled = false } = offer || {}
    if (ballotsEnabled) {
      applicationOption['isBallotEntry'] = ballotsEnabled
      const { current = {} } = this.ballotDisclosuresFormRef || {}
      const { values = {} } = current || {}
      applicationOption['ballotDisclosuresAnswers'] = values
    }
    // save draft only when new. However, after applying application status will not be
    // TODO: this should be refactored as applyButtonClicked is used as a UI state mostly
    if (isStatusNewOrDraft && !applyButtonClicked && !secondaryButtonClicked) {
      const applicantSummary = generateApplicantSummaryForSubmit(
        weeklyNetIncome,
        currentRent,
        currentLease,
        isJoint,
        iAmCurrentlyRenting,
      )

      return saveDraftApplication(
        applicants,
        applicantSummary,
        isApplyAnywhere,
        applyAnywhereGUID,
        children,
        applicationOption,
        allVehiclesCopy,
        config,
        referrerPayload,
      )
        .then((returnedApplication) => {
          this.props.fetchApplication(returnedApplication.guidID)
          if (parseURLQuery().manageradded) {
            return
          }
          this.props.addRentalReputationBackUrl(
            `${urlTo('application', {
              applicationId: returnedApplication.guidID,
            })}${window.location.search}`,
          )
          return Promise.resolve(returnedApplication)
        })
        .catch((error) => {
          snugNotifier.error(translateErrorCodeToMessage(error))
          return Promise.reject(error)
        })
    }
    return Promise.resolve()
  }

  getPropertyOffer = () => {
    const applicationStatus = this.props.application
      ? ApplicationCategory[this.props.application.status]
      : undefined

    let offer = {}
    const isNewApplication = isStatusNew(applicationStatus)
    if (isNewApplication) {
      offer = (this.props.property.offers || []).find((o) => o.isActive) || {}
    }
    if (!isNewApplication) {
      offer = (this.props.application || {}).offer || {}
    }
    return offer
  }

  submitOffPlatformApplication = () => {
    const {
      currentUser,
      application,
      isJoint,
      adders,
      propertyId,
      createOffPlatformApplication,
    } = this.props
    const {
      lastMatchApplicationField,
      applyButtonClicked,
      weeklyNetIncome,
      currentRent,
      currentLease,
      applicants,
      secondaryButtonClicked,
      iAmCurrentlyRenting,
      applyAnywhereGUID,
      children,
      pickedRentSchedule,
      requestCarSpace,
      incomeSetByManager,
      disclosureOptions,
      otherPlatformApplicationFiles = [],
      otherPlatformSupportingAttachments = [],
      parsedApplicationSettingInfo = {},
    } = this.state
    const applicationOption = { pickedRentSchedule }
    if (parsedApplicationSettingInfo.request_car_space) {
      applicationOption['requestCarSpace'] = requestCarSpace
    }
    const applicationStatus = ApplicationCategory[application.status]
    const isStatusNewOrDraft =
      isStatusNew(applicationStatus) || isStatusDraft(applicationStatus)
    if (!this.extraValidationForOffPlatformApplication()) {
      return
    }
    // save draft only when new. However, after applying application status will not be
    // TODO: this should be refactored as applyButtonClicked is used as a UI state mostly
    if (!applyButtonClicked && !secondaryButtonClicked) {
      this.toggleStatus('applyButtonClicked')
      const applicantSummary = generateApplicantSummaryForSubmit(
        weeklyNetIncome,
        currentRent,
        currentLease,
        isJoint,
        iAmCurrentlyRenting,
      )
      // Todo: set the primary applicant
      const formattedApplication = Object.assign({}, application, {
        rent: adders['rent'].quantity,
        term: adders['term'].quantity.toString(),
        moveInDate: constructDateFromAdderEntity(adders['moveInDate']),
        applicationType: applicationTypeCollection.standard,
        rentScheduleType: pickedRentSchedule,
        requestCarSpace: requestCarSpace === IS_CAR_SPACE_REQUESTED,
        applicants: applicants
          .filter((applicant) => applicant.isJointApplicant)
          .map((applicant, index) => ({
            ...applicant,
            mobileNumber:
              applicant.mobileNumber &&
              applicant.mobileNumber.replace(/\s+/g, ''),
            email: applicant.email && applicant.email.replace(/\s+/g, ''),
            guidID:
              applicant.sourceStatus === contactRelationshipCollection.occupant
                ? ''
                : applicant.guidID,
            isPrimary: index === 0 ? true : false,
            ...disclosureOptions,
            smokingInfo: disclosureOptions.optedInAsSmoker
              ? smokingType.Smoker
              : smokingType.NonSmoker,
          })),
        occupants: applicants
          .filter((applicant) => !applicant.isJointApplicant)
          .map((applicant) => ({
            ...applicant,
            mobileNumber:
              applicant.mobileNumber &&
              applicant.mobileNumber.replace(/\s+/g, ''),
            email: applicant.email && applicant.email.replace(/\s+/g, ''),
            guidID:
              applicant.sourceStatus === contactRelationshipCollection.applicant
                ? ''
                : applicant.guidID,
          })),
        children,
        applicantSummary,
        offerID: propertyId,
        income: incomeSetByManager,
        otherPlatformApplicationFiles,
        otherPlatformSupportingAttachments,
      })
      createOffPlatformApplication(formattedApplication)
        .then(() => {
          this.setState({ applyButtonClicked: true })
          history.goBack()
        })
        .catch((error) => {
          snugNotifier.error(error)
          this.setState({ applyButtonClicked: false })
        })
    }
    return Promise.resolve()
  }
  toggleModal = (modalName) => {
    if (this.state.modalOpened === modalName) {
      this.setState({ modalOpened: '' })
    } else if (this.state.modalOpened === '') {
      this.setState({ modalOpened: modalName })
    }
  }
  toggleStatus = (field) => {
    this.setState({
      [field]: !this.state[field],
    })
  }
  updateDisclosureOptions = (newDisclosureOptions) => {
    this.setState({
      disclosureOptions: {
        ...this.state.disclosureOptions,
        ...newDisclosureOptions,
      },
    })
  }
  updateLeaseLength = (val) => {
    this.props.onAdderChange(
      'term',
      'update',
      {
        measure: 'm',
        quantity: val,
      },
      this.state.isApplyAnywhere,
    )
  }
  updatePreferredRentSchedule = (val) => {
    const { preferredRentScheduleOptions } = this.state
    const updatedRentScheduleOptions = preferredRentScheduleOptions.map(
      (option) => {
        if (val === option.value) {
          option.picked = true
        } else {
          option.picked = false
        }
        return option
      },
    )
    this.setState({
      preferredRentScheduleOptions: updatedRentScheduleOptions,
      pickedRentSchedule: updatedRentScheduleOptions.find(
        (option) => option.picked,
      ).index,
    })
  }
  updateRequestCarSpace = (val) => {
    const { requestCarSpaceOptions } = this.state
    const updatedRequestCarSpaceOptions = requestCarSpaceOptions.map(
      (option) => {
        return {
          ...option,
          picked: val === option.value,
        }
      },
    )
    const option = updatedRequestCarSpaceOptions.find((o) => o.picked)
    this.setState({
      requestCarSpaceOptions: updatedRequestCarSpaceOptions,
      requestCarSpace: option ? option.model : 0,
    })
  }

  validateSolicitedAbovePrice = () => {
    const { children, allVehicles, isApplyAnywhere } = this.state
    const { solicitedAbovePrice } = this.state
    const offer = this.getPropertyOffer()
    const { ballotsEnabled = false } = offer || {}
    if (!isApplyAnywhere && !ballotsEnabled) {
      const {
        isAboveDefaultPrice = false,
        isSolicitedAbovePriceChecked = false,
      } = solicitedAbovePrice || {}

      if (isAboveDefaultPrice && !isSolicitedAbovePriceChecked) {
        snugNotifier.error(textHelpers.NOT_SOLICITED_TO_RENT_BID_MSG)
        return false
      }
    }
    return true
  }

  validateApply = (isApplicationCreatedByManager) => {
    const { children, allVehicles, isApplyAnywhere } = this.state
    const { solicitedAbovePrice } = this.props
    const applicantsArray = this.state.applicants.map((a) => a.email)
    const isDuplicate = applicantsArray.some(
      (item, i) => applicantsArray.indexOf(item) !== i,
    )
    const isUserAddedAsJoint = applicantsArray.some(
      (a) => a === this.props.currentUser.email,
    )
    let childrenValidationResults = []
    if (isUserAddedAsJoint) {
      this.setState(
        { applyFormError: 'You are already an applicant, please add others' },
        this.reactToastify,
      )
      return false
    }
    if (this.props.adders.rent.quantity <= 0) {
      this.setState(
        { applyFormError: 'Please enter a rent amount' },
        this.reactToastify,
      )
      return false
    }
    const { rent_frequency } = this.state.parsedApplicationSettingInfo || {}
    const rentCycleEnabled = rent_frequency === rentFrequencyOpenOption
    const { pickedRentSchedule } = this.state
    const weeklyRentSchedule = 1
    if (!!rentCycleEnabled && pickedRentSchedule < weeklyRentSchedule) {
      this.setState(
        { applyFormError: 'Please select your preferred rent schedule' },
        this.reactToastify,
      )
      return false
    }
    const { request_car_space: requestCarSpaceEnabled } =
      this.state.parsedApplicationSettingInfo || {}
    if (
      requestCarSpaceEnabled &&
      ![true, false].includes(this.state.requestCarSpace)
    ) {
      this.setState(
        {
          applyFormError:
            'Please select whether you would like to request a car space',
        },
        this.reactToastify,
      )
      return false
    }

    if (!this.validateTerm()) {
      this.setState(
        { applyFormError: 'Please select term' },
        this.reactToastify,
      )
      return false
    }
    // group type
    if (!this.props.application.group) {
      const offer = this.getPropertyOffer()
      const { ballotsEnabled = false } = offer || {}
      if (ballotsEnabled) {
        this.setState(
          { applyFormError: 'Please select group type' },
          this.reactToastify,
        )
        return false
      }
    }
    // About Us
    if (!this.props.application.aboutMe && !isApplicationCreatedByManager) {
      const offer = this.getPropertyOffer()
      const { ballotsEnabled = false } = offer || {}
      if (!ballotsEnabled) {
        this.setState(
          { applyFormError: 'About Us is required' },
          this.reactToastify,
        )
        return false
      }
    }
    // people
    if (!this.props.application.people && !this.props.application.adults) {
      this.setState(
        { applyFormError: 'Please select how many adults' },
        this.reactToastify,
      )
      return false
    }
    // joint applicants
    if (
      this.state.applicants.length > 0 &&
      this.noApplicantsErrors(isApplicationCreatedByManager) === false
    ) {
      this.setState(
        {
          applyFormError:
            'Something wrong with adults information. Please fix it and try again',
        },
        this.reactToastify,
      )
      return false
    }
    if (isDuplicate) {
      this.setState(
        { applyFormError: 'You can only invite someone once' },
        this.reactToastify,
      )
      return false
    }
    if (this.props.application.children > 0) {
      childrenValidationResults = children.map((child) => {
        if (!!child.error) {
          snugNotifier.error('The information of children is not valid')
          return false
        }
        return true
      })
      if (
        childrenValidationResults.length > 0 &&
        childrenValidationResults.findIndex((result) => result === false) >= 0
      ) {
        return false
      }
    }
    this.setState({
      applyFormError: '',
    })

    if (allVehicles && allVehicles.length > 0) {
      let isAnError = false
      allVehicles.forEach((vehicleInfo) => {
        if (vehicleInfo.vehicleType === vehicleHelpers.VEHICLE_TYPE_UNSET) {
          snugNotifier.error(vehicleHelpers.ERROR_TYPE_NOT_SELECTED)
          isAnError = true
        }
      })
      if (isAnError) {
        return
      }
    }

    const offer = this.getPropertyOffer()
    const { ballotsEnabled = false } = offer || {}

    const { ballotsHouseholdIncomeLimit } = this.state
    const { application = {} } = this.props

    const { group: applicationGroup, householdYearlyIncome } = application

    if (ballotsEnabled) {
      if (!this.props.application.householdYearlyIncome) {
        snugNotifier.error('Please enter Household yearly income')
        return false
      }

      const limit = incomeLimitFromGroup(
        applicationGroup,
        ballotsHouseholdIncomeLimit,
      )

      if (limit && householdYearlyIncome > limit) {
        snugNotifier.error(
          'Household yearly income is more than the eligible amount for your group type',
        )
        return false
      }
    }
    if (!this.validateSolicitedAbovePrice()) {
      return false
    }

    return true
  }
  validateNotEmptyApplicant = (applicant, i) => {
    if (applicant && applicant.isJointApplicant) {
      this.setApplicantValidationErrors(
        'firstName',
        validations.validateNotEmpty(applicant.firstName),
        i,
      )
      this.setApplicantValidationErrors(
        'lastName',
        validations.validateNotEmpty(applicant.lastName),
        i,
      )
      this.setApplicantValidationErrors(
        'mobileNumber',
        validations.validateNotEmpty(applicant.mobileNumber),
        i,
      )
      this.setApplicantValidationErrors(
        'email',
        validations.validateNotEmpty(applicant.email),
        i,
      )
    } else {
      this.setApplicantValidationErrors(
        'firstName',
        validations.validateNotEmpty(applicant.firstName),
        i,
      )
      this.setApplicantValidationErrors(
        'lastName',
        validations.validateNotEmpty(applicant.lastName),
        i,
      )
      !applicant.mobileNumber &&
        !applicant.email &&
        this.setApplicantValidationErrors(
          'mobileNumber',
          validations.validateNotEmpty(applicant.mobileNumber),
          i,
        )
    }
  }
  validateTerm = () => {
    const availableLeaseLength = constructAvailableLeaseLengthFromAdderEntity(
      this.props.adders.term,
    )
    return !!availableLeaseLength.find((option) => option.picked === true)
  }

  withdrawApplication(applicationId) {
    const { acceptWithdraw } = this.props
    this.setState({
      secondaryButtonClicked: true,
      applyFormError: '',
    })
    acceptWithdraw(
      applicationId,
      () =>
        this.setState({
          secondaryButtonClicked: false,
        }),
      (responseText) =>
        this.setState({
          secondaryButtonClicked: false,
          applyFormError: responseText,
          withdrawModalOpen: false,
        }),
    )
  }

  withdrawSecondaryApplicant(applicationId, applicantId) {
    const { acceptSecondaryWithdraw } = this.props
    this.setState({
      secondaryButtonClicked: true,
      applyFormError: '',
    })
    acceptSecondaryWithdraw(
      applicationId,
      applicantId,
      () =>
        this.setState({
          secondaryButtonClicked: false,
        }),
      (responseText) =>
        this.setState({
          secondaryButtonClicked: false,
          applyFormError: responseText,
          withdrawModalOpen: false,
        }),
    )
  }

  renderAgencyApplicationsLimitAlert() {
    const {
      applicantAgencySummary: summary,
      parsedApplicationSettingInfo: applicationSettings,
    } = this.state
    const {
      property: { teamName },
    } = this.props
    return (
      applicationSettings && (
        <Box px={theme.space[7] + 'px'}>
          <AgencyApplicationsLimitAlert
            applicantApplicationsCount={summary?.applicationsCount}
            teamName={teamName}
            applicationTeamSettings={applicationSettings}
          />
        </Box>
      )
    )
  }

  toggleBallotSurveyModal(isFromSecondary) {
    const { ballotSurveyModalConfig } = this.state
    if (ballotSurveyModalConfig.isOpened) {
      this.setState({
        ballotSurveyModalConfig: {
          isOpened: false,
          isFromSecondary: false,
        },
      })
    }

    if (!ballotSurveyModalConfig.isOpened) {
      this.setState({
        ballotSurveyModalConfig: {
          isOpened: true,
          isFromSecondary: Boolean(isFromSecondary),
        },
      })
    }
  }

  submitBallotSurveyModal() {
    const ballotSurveyValues = this.ballotSurveyFormRef?.current?.values
    const { ballotSurveyModalConfig, urlQueryString } = this.state
    const { property = {}, application = {} } = this.props
    const { agencyID = '' } = property || {}
    const applicationStatus = application
      ? ApplicationCategory[application?.status]
      : undefined
    let offer
    const isNewApplication = isStatusNew(applicationStatus)
    if (isNewApplication) {
      offer = (property.offers || []).find((o) => o.isActive) || {}
    }
    if (!isNewApplication) {
      offer = (application || {}).offer || {}
    }
    const { guidID = '' } = offer || {}

    const {
      identifyAsAboriginalOrTorresStraitIslander,
      isOnVictorianHousingRegisterWaitingList,
      resideSocialOrPublicHousing,
      applicantGender,
      identifyAsCulturallyAndLinguisticallyDiverse,
      identifyAsPersonWithDisability,
      ageRange,
    } = ballotSurveyValues || {}
    if (
      identifyAsAboriginalOrTorresStraitIslander &&
      isOnVictorianHousingRegisterWaitingList &&
      resideSocialOrPublicHousing &&
      applicantGender &&
      identifyAsCulturallyAndLinguisticallyDiverse &&
      identifyAsPersonWithDisability &&
      ageRange &&
      ageRange.value
    ) {
      const surveyResponse = {
        [formQuestion.identifyAsAboriginalOrTorresStraitIslander]:
          identifyAsAboriginalOrTorresStraitIslander,
        [formQuestion.isOnVictorianHousingRegisterWaitingList]:
          isOnVictorianHousingRegisterWaitingList,
        [formQuestion.resideSocialOrPublicHousing]: resideSocialOrPublicHousing,
        [formQuestion.applicantGender]: applicantGender,
        [formQuestion.identifyAsCulturallyAndLinguisticallyDiverse]:
          identifyAsCulturallyAndLinguisticallyDiverse,
        [formQuestion.identifyAsPersonWithDisability]:
          identifyAsPersonWithDisability,
        [formQuestion.ageRange]: ageRange?.value,
      }
      if (
        Object.values(surveyResponse).some((value) => value === 'otherComments')
      ) {
        Object.keys(surveyResponse).forEach((key) => {
          if (surveyResponse[key] === 'otherComments') {
            this.ballotSurveyFormRef.current.setFieldError(
              Object.keys(formQuestion).find(
                (questionKey) => formQuestion[questionKey] === key,
              ),
              { required: 'This field is required to continue' },
            )
          }
        })
        snugNotifier.error('Please fill all other comment inputs')
      } else {
        let payload = {
          data: {},
          agencyGUID: agencyID,
          offerGUID: guidID,
        }

        Object.keys(surveyResponse).forEach((key) => {
          payload.data[key] = surveyResponse[key].includes('otherComments:')
            ? surveyResponse[key].split('otherComments')[0]
            : surveyResponse[key]
        })

        postBallotSurvey(payload).catch(() => {
          snugNotifier.error('Error while posting ballot survey')
        })

        if (!ballotSurveyModalConfig.isFromSecondary) {
          this.onSaveApplicationAndRedirectProfile()
        }
        if (ballotSurveyModalConfig.isFromSecondary) {
          const applicationGUID = this.props.applicant.applicationID
          const applicantGUID = this.props.applicant.guidID

          const applicationQuery = !urlQueryString.includes('applicationId')
            ? `${urlQueryString}&applicationId=${applicationGUID}&stage=essentials&ballot=true`
            : `${urlQueryString}&stage=essentials&ballot=true`
          history.push(
            `${urlTo(
              'rentalHistorySummary',
            )}${applicationQuery}&fromSecondary=true&applicantGUID=${applicantGUID}`,
          )
        }
      }
    } else {
      snugNotifier.error('Please fill all mandatory inputs')
      this.ballotSurveyFormRef.current.setFieldTouched(
        ballotSurveyFormFields.identifyAsAboriginalOrTorresStraitIslander,
        true,
        true,
      )
      this.ballotSurveyFormRef.current.setFieldTouched(
        ballotSurveyFormFields.isOnVictorianHousingRegisterWaitingList,
        true,
        true,
      )
      this.ballotSurveyFormRef.current.setFieldTouched(
        ballotSurveyFormFields.resideSocialOrPublicHousing,
        true,
        true,
      )
      this.ballotSurveyFormRef.current.setFieldTouched(
        ballotSurveyFormFields.applicantGender,
        true,
        true,
      )
      this.ballotSurveyFormRef.current.setFieldTouched(
        ballotSurveyFormFields.identifyAsCulturallyAndLinguisticallyDiverse,
        true,
        true,
      )
      this.ballotSurveyFormRef.current.setFieldTouched(
        ballotSurveyFormFields.identifyAsPersonWithDisability,
        true,
        true,
      )
      this.ballotSurveyFormRef.current.setFieldTouched(
        ballotSurveyFormFields.ageRange,
        true,
        true,
      )
    }
  }

  handleEnableSaveAboutToProfileChange(isEnabled) {
    this.setState({ enableSaveAboutToProfile: isEnabled })
  }

  render() {
    let {
      Validations,
      acceptButtonClicked,
      unsavedChanges,
      otherApplicantValidated,
      formRemoved,
      formCompleted,
      spinner,
      applicants,
      applicantsErrors,
      showValidationError,
      withdrawModalOpen,
      applyButtonClicked,
      secondaryButtonClicked,
      availableLeaseLength,
      isApplyAnywhere,
      children,
      urlQueryString,
      imageUrlFromUrl,
      applyAnywhereListingFromApplication,
      preferredRentScheduleOptions,
      requestCarSpaceOptions,
      parsedApplicationSettingInfo = {},
      isApplicationCreatedByManager = false,
      otherPlatformApplicationFiles = [],
      otherPlatformSupportingAttachments = [],
      incomeSetByManager = 0,
      disclosureOptions,
      savedDisclosureOptions,
      isImageRemoving,
      referrer,
      referrerBackUrl,
      propertyImageUrl,
      allVehicles,
      ballotSurveyModalConfig: { isOpened: ballotSurveyModalOpened },
      ballotsHouseholdIncomeLimit,
      ballotsHouseholdIncomeRegion,
      solicitedAbovePrice,
      enableSaveAboutToProfile,
      excludeRequestVehicleRegistration,
    } = this.state
    const {
      property,
      application,
      adders,
      heartClicked,
      completeness,
      snugRank,
      otherOffers,
      error,
      currentUser,
      summary,
      takeHoldingDeposit,
      isJoint,
      addCurrentNewTenant,
      clearCurrentNewTenant,
      applyAnywhereListing,
      setBackUrl,
    } = this.props
    setBackUrl(window.location.pathname)
    const { agencyID = '' } = property

    const isSecondaryApplicantViewingApplication =
      this.props.applicant &&
      this.props.applicant.guidID &&
      this.props.applicant.isPrimary === false
    const applicant = this.props.applicant
    const { isManagerAdded, ballotDisclosuresAnswers = {} } = application
    let isEditable = isApplicationCreatedByManager
      ? isApplicationEditable(application) && isManagerAdded
      : isApplicationEditable(application) &&
        (!isSecondaryApplicantViewingApplication || !isManagerAdded)
    if (isSecondaryApplicantViewingApplication) {
      isEditable = false
    }
    if (isEditable) {
      isApplicationCreatedByManager = isManagerAdded
    } else if (isApplicationCreatedByManager && isManagerAdded === false) {
      isApplicationCreatedByManager = false
    }
    const applicationApplicants = selectApplicants(this.props.application)
    const primaryApplicant = selectPrimaryApplicant(applicationApplicants) || {}
    const { score: primaryApplicantScore = '' } = primaryApplicant
    const showApplicantScore =
      primaryApplicantScore && primaryApplicantScore > 0
    const applicationStatus = application
      ? ApplicationCategory[application.status]
      : undefined
    const propertyId = property.guidID
    const applicationId = application.guidID
    const offerId = (application.offer || {}).guidID
    const teamSlug = property && property.teamSlug
    const labelClassname =
      isStatusOffered(applicationStatus) ||
      isStatusDeclined(applicationStatus) ||
      isStatusWithdrawn(applicationStatus)
        ? 'label-floating-fixture'
        : ''
    const otherApplicantValidationApproved =
      !isJoint || (isJoint && otherApplicantValidated)
    // manager preference
    let offer
    const isNewApplication = isStatusNew(applicationStatus)
    if (isNewApplication) {
      offer = (property.offers || []).find((o) => o.isActive) || {}
    }
    if (!isNewApplication) {
      offer = (application || {}).offer || {}
    }
    const {
      acceptedLeaseLength = 'N/A',
      weeklyRentDisplay = '',
      ballotsEnabled = false,
    } = offer
    const { offerPreferences } = summary.offerPreferences ? summary : {}
    // property info
    let address = ''
    let suburb = ''
    if (!isApplyAnywhere && property.address && property.address.unitNumber) {
      address = `${property.address.unitNumber}/${property.address.streetNumber} ${property.address.streetName}`
      suburb = `${property.address.suburb} ${property.address.state}`
    } else if (
      !isApplyAnywhere &&
      property.address &&
      !property.address.unitNumber
    ) {
      address = `${property.address.streetNumber} ${property.address.streetName}`
      suburb = `${property.address.suburb} ${property.address.state}`
    } else if (
      isApplyAnywhere &&
      applyAnywhereListing &&
      applyAnywhereListing.address.unitNumber
    ) {
      address = `${applyAnywhereListing.address.unitNumber}/${applyAnywhereListing.address.streetNumber} ${applyAnywhereListing.address.streetName}`
      suburb = `${applyAnywhereListing.address.suburb} ${applyAnywhereListing.address.state}`
    } else if (
      isApplyAnywhere &&
      applyAnywhereListing &&
      !applyAnywhereListing.address.unitNumber
    ) {
      address = `${applyAnywhereListing.address.streetNumber} ${applyAnywhereListing.address.streetName}`
      suburb = `${applyAnywhereListing.address.suburb} ${applyAnywhereListing.address.state}`
    }
    const { from = '' } = parseURLQuery(this.props.location.search)
    const showSaveContinue =
      !isStatusWithdrawn(applicationStatus) &&
      !isStatusAccepted(applicationStatus) &&
      !isStatusOffered(applicationStatus) &&
      !isStatusDeclined(applicationStatus)
    const isAppliedBySecondaryApplicant =
      isSecondaryApplicant(this.props.applicant) &&
      isApplicantApplied(this.props.applicant)
    const agencyApplicationsLimitAlert =
      this.renderAgencyApplicationsLimitAlert()

    const { ballotsIncomeTableID } = offer || {}

    return spinner ? (
      <Spinner />
    ) : error === "The application doesn't exist" ? (
      <div className="alert alert-danger">{error}</div>
    ) : (
      <div className="sm-apply">
        <ApplyOffer
          property={property}
          application={application}
          heartClicked={heartClicked}
          isApplyAnywhere={isApplyAnywhere}
          applyAnywhereListing={applyAnywhereListing}
          applyAnywhereListingFromApplication={
            applyAnywhereListingFromApplication
          }
          referrer={referrer}
          referrerBackUrl={referrerBackUrl}
          propertyImage={propertyImageUrl || imageUrlFromUrl}
        />
        <Display.CenterContentContainer
          containerClass="apply-form-mobile-display"
          componentClass="application-standard-shadow-box application-apply-container"
        >
          {!isApplicationCreatedByManager && (
            <div className="application-start-progress-bar">
              <ProgressBarComponent completeness={completeness} />
            </div>
          )}
          {agencyApplicationsLimitAlert}
          <PropertyInfoBox
            property={property}
            address={address}
            suburb={suburb}
            acceptedLeaseLength={acceptedLeaseLength}
            weeklyRent={weeklyRentDisplay}
            isApplyAnywhere={isApplyAnywhere}
            isPetsAllowed={offerPreferences && offerPreferences.allowPets}
            ballotsEnabled={ballotsEnabled}
          />
          <YourOffer
            property={property}
            application={application}
            applicationStatus={applicationStatus}
            adders={adders}
            onAdderChange={this.props.onAdderChange}
            otherOffers={otherOffers}
            onLeaseTermBoxChanged={this.onLeaseTermBoxChanged}
            isApplicationEditable={isEditable}
            setUnsavedChanges={this.setUnsavedChanges}
            isApplyAnywhere={isApplyAnywhere}
            isNewApplication={isStatusNew(application.status)}
            applicant={applicant}
            preferredRentScheduleOptions={preferredRentScheduleOptions}
            updatePreferredRentSchedule={this.updatePreferredRentSchedule}
            requestCarSpaceOptions={requestCarSpaceOptions}
            updateRequestCarSpace={this.updateRequestCarSpace}
            isApplicationCreatedByManager={isApplicationCreatedByManager}
            onIncomeSetByManagerUpdated={this.onNumberFieldUpdate(
              'incomeSetByManager',
            )}
            income={incomeSetByManager}
            parsedApplicationSettingInfo={parsedApplicationSettingInfo}
            allVehicles={allVehicles}
            updateVehicleNumber={this.updateVehicleNumber}
            onChangeVehicleDetails={this.onChangeVehicleDetails}
            onRemoveVehicleButtonClick={this.onRemoveVehicleButtonClick}
            formRef={this.ballotDisclosuresFormRef}
            ballotsHouseholdIncomeLimit={ballotsHouseholdIncomeLimit}
            ballotsHouseholdIncomeRegion={ballotsHouseholdIncomeRegion}
            ballotDisclosuresAnswers={ballotDisclosuresAnswers}
            updateSolicitedAbovePrice={this.updateSolicitedAbovePrice}
            solicitedAbovePrice={solicitedAbovePrice}
            excludeRequestVehicleRegistration={
              excludeRequestVehicleRegistration
            }
          />
          <OfferDetails
            setValidation={(field, ok) => this.setValidation(field, ok)}
            property={property}
            application={application}
            isSaveAboutToProfileEnabled={enableSaveAboutToProfile}
            onEnableSaveAboutToProfileChanged={
              this.handleEnableSaveAboutToProfileChange
            }
            isCurrentApplicantSecondary={isSecondaryApplicantViewingApplication}
            groupInput={this.props.groupInput}
            petsInput={this.props.petsInput}
            peopleInput={this.props.peopleInput}
            adultInput={this.onUpdateAdultInput}
            childrenInput={this.props.childrenInput}
            requestsInput={this.props.requestsInput}
            aboutMeInput={this.props.aboutMeInput}
            labelClassname={labelClassname}
            newTenant={(tenant) => this.props.newTenant(tenant)}
            removeTenant={(nth) => this.props.removeTenant(nth)}
            isApplicationEditable={isEditable}
            onOtherApplicantFormOnBlur={this.onOtherApplicantFormOnBlur}
            addCurrentNewTenant={addCurrentNewTenant}
            clearCurrentNewTenant={clearCurrentNewTenant}
            setUnsavedChanges={this.setUnsavedChanges}
            applicants={applicants}
            currentApplicant={this.props.currentUser}
            onApplicantFieldChange={this.onApplicantFieldChange}
            onAddButtonClick={this.onAddButtonClick}
            onRemoveButtonClick={this.onRemoveButtonClick}
            validateNotEmptyApplicant={this.validateNotEmptyApplicant}
            applicantsErrors={applicantsErrors}
            showValidationError={showValidationError}
            onBlurShowValidationError={this.onBlurShowValidationError}
            onFocusHideValidationError={this.onFocusHideValidationError}
            teamSlug={teamSlug}
            children={children}
            onChildrenInfoChanged={this.newOnChildrenInfoChanged}
            onChildrenDateOfBirthChanged={this.onChildrenDateOfBirthChanged}
            onChildrenInfoBlured={this.onChildrenInfoBlured}
            onChildrenFocus={this.onChildrenFocus}
            onChildDelete={this.onChildDelete}
            onAddAnotherClicked={this.onAddAnotherClicked}
            isApplicationCreatedByManager={isApplicationCreatedByManager}
            applicationSettingInfo={parsedApplicationSettingInfo}
            householdYearlyInput={this.props.householdYearlyInput}
            ballotsEnabled={ballotsEnabled}
            ballotsHouseholdIncomeLimit={ballotsHouseholdIncomeLimit}
            ballotsIncomeTableID={ballotsIncomeTableID}
            ballotsHouseholdIncomeRegion={ballotsHouseholdIncomeRegion}
            agencyID={agencyID}
          />
          {isApplicationCreatedByManager && (
            <ApplySectionWrapper>
              <ApplySegmentWrapper>
                <Display.SectionHeader
                  text="Attachments"
                  textClass="position-relative p0"
                  componentClass="apply-snug-tip-display"
                  hasSeperator={false}
                />
                <Display.GreyBodyText text="Upload the application and supporting attachments." />
                {isImageRemoving && <Spinner />}
                <ApplicationAttachments
                  files={otherPlatformApplicationFiles}
                  onDrop={this.onAttachmentsUpdate(
                    'otherPlatformApplicationFiles',
                  )}
                  onRemoveIconClciked={this.onImgRemoveIconClicked(
                    'otherPlatformApplicationFiles',
                  )}
                  title="Applications"
                />
                <ApplicationAttachments
                  files={otherPlatformSupportingAttachments}
                  onDrop={this.onAttachmentsUpdate(
                    'otherPlatformSupportingAttachments',
                  )}
                  onRemoveIconClciked={this.onImgRemoveIconClicked(
                    'otherPlatformSupportingAttachments',
                  )}
                  title="Supporting attachments"
                />
              </ApplySegmentWrapper>
            </ApplySectionWrapper>
          )}
          {isApplicationCreatedByManager && (
            <ApplySectionWrapper>
              <ApplySegmentWrapper>
                <DisclosureContainer
                  isPrimaryApplicant={true}
                  utilityProviderName={''}
                  updateDisclosureOptions={this.updateDisclosureOptions}
                  parsedApplicationSettingInfo={parsedApplicationSettingInfo}
                  savedDisclosureOptions={savedDisclosureOptions}
                />
              </ApplySegmentWrapper>
            </ApplySectionWrapper>
          )}
          {ballotsEnabled && (
            <ApplySectionWrapper>
              <Alert variant="blueWithBg">
                <Box color={theme.colors.black}>
                  <BallotTermAndConditions />
                </Box>
              </Alert>
            </ApplySectionWrapper>
          )}
        </Display.CenterContentContainer>
        {
          <div className="apply-form-buttons">
            {showSaveContinue && (
              <div className="apply-form-button mt10 mb30">
                <div
                  className="gray-text linkStyle text-center mobile-fontSize-13"
                  onClick={() => this.toggleModal('withdrawOrDelete')}
                >
                  {isStatusDeletable(applicationStatus) && 'Delete draft'}
                  {isStatusWithdrawableByRenter(applicationStatus) &&
                    !isSecondaryApplicant(applicant) &&
                    'Withdraw my application'}
                  {isStatusWithdrawableByRenter(applicationStatus) &&
                    isAppliedBySecondaryApplicant &&
                    isSecondaryApplicant(applicant) &&
                    'Withdraw my application'}
                </div>
              </div>
            )}
            {isStatusWithdrawn(applicationStatus) && (
              <Alert
                variant="primaryWithBg"
                textAlign="center"
                my={theme.space[7] + 'px'}
              >
                Withdrawn
              </Alert>
            )}
            {isAutoWithdrawn(application) && (
              <Alert
                variant="primaryWithBg"
                textAlign="center"
                my={theme.space[7] + 'px'}
              >
                Auto-Withdrawn
              </Alert>
            )}
          </div>
        }
        <ApplyPanel
          snugRank={snugRank}
          completeness={completeness}
          applicationStatus={applicationStatus}
          clicked={applyButtonClicked}
          onApplyNextButtonClickedAsSecondaryApplicant={
            this.onApplyNextButtonClickedAsSecondaryApplicant
          }
          onUpdateApplication={this.onUpdateApplication}
          applicant={applicant}
          onApplyNextButtonClicked={this.onApplyNextButtonClicked}
          applicants={applicants}
          toggleModal={this.toggleModal}
          modalOpened={this.state.modalOpened}
          onSecondaryButtonClick={this.onSecondaryButtonClick}
          applicationId={applicationId}
          secondaryButtonClicked={secondaryButtonClicked}
          error={this.state.applyFormError}
          teamSlug={teamSlug}
          isApplyAnywhere={isApplyAnywhere}
          from={from}
          onApplyAnywhereNextClicked={this.onApplyAnywhereNextClicked}
          takeHoldingDeposit={takeHoldingDeposit}
          propertyId={propertyId}
          urlQueryString={urlQueryString}
          isApplicationCreatedByManager={isApplicationCreatedByManager}
          editOffPlatformApplication={this.editOffPlatformApplication}
          showApplicantScore={showApplicantScore}
          ballotsEnabled={ballotsEnabled}
          validateBallotAppAndGoToNextPage={
            this.validateBallotAppAndGoToNextPage
          }
        />
        <div className="display-flex justify-content-center">
          <div className="pt30 width-720px">
            {isStatusOffered(applicationStatus) && takeHoldingDeposit && (
              <button
                className="btn btn-big"
                onClick={() =>
                  history.push(
                    urlTo('offeredApplication', {
                      propertyId,
                      applicationId,
                    }),
                  )
                }
              >
                <span>Review offer</span>
              </button>
            )}
            {isStatusDeclined(applicationStatus) && (
              <div className="btn btn-gray btn-light mt20">Unsuccessful</div>
            )}
            {isStatusAccepted(applicationStatus) && (
              <div className="btn green-bg-light green-color disabled-link">
                Accepted
              </div>
            )}
            {isStatusOffered(applicationStatus) && !takeHoldingDeposit && (
              <button
                className="btn btn-big renter-accept-offer"
                id={teamSlug}
                onClick={() => {
                  this.activateSpinner()
                  this.props.acceptApplication(
                    offerId,
                    applicationId,
                    propertyId,
                  )
                }}
              >
                <span>
                  Accept offer{' '}
                  <i
                    className={
                      acceptButtonClicked ? 'fa fa-spinner fa-pulse' : ''
                    }
                  />
                </span>
              </button>
            )}
          </div>
        </div>
        <UnsavedMessage
          unsavedChanges={
            applyButtonClicked &&
            this.state.unsavedChanges &&
            (isStatusQuickApplied(applicationStatus) ||
              isStatusApplied(applicationStatus))
          }
        />
        {ballotSurveyModalOpened && (
          <BallotSurveyModal
            toggleBallotSurveyModal={() => this.toggleBallotSurveyModal()}
            submitBallotSurveyModal={() => this.submitBallotSurveyModal()}
            ballotSurveyFormRef={this.ballotSurveyFormRef}
            applyButtonClicked={applyButtonClicked}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ apply, session, WithdrawConfirmation }) => ({
  property: apply.property,
  propertyId: apply.propertyId,
  adders: apply.adders,
  applicant: apply.applicant,
  application: apply.application,
  summary: apply.summary,
  snugRank: apply.snugRank,
  otherOffers: apply.otherOffers,
  completeness: apply.completeness,
  error: apply.error,
  currentUser: session.currentUser,
  takeHoldingDeposit: apply.takeHoldingDeposit,
  isJoint: apply.application.isJoint,
  applyAnywhereListing: apply.applyAnywhereListing,
  solicitedAbovePrice: apply.solicitedAbovePrice,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCompleteness: () => dispatch(fetchCompleteness()),
  ping: () => dispatch(ping()),
  fetchProperty: (propertyId) => dispatch(fetchProperty(propertyId)),
  fetchApplication: (applicationId) =>
    dispatch(fetchApplication(applicationId)),
  fetchApplicationForApplicant: (applicationId, success) =>
    dispatch(fetchApplicationForApplicant(applicationId, success)),
  fetchApplicantSummary: () => dispatch(fetchApplicantSummary()),
  onAdderChange: (adderType, operation, payload, isApplyAnywhere) =>
    dispatch(onAdderChange(adderType, operation, payload, isApplyAnywhere)),
  groupInput: (input) => dispatch(groupInput(input)),
  petsInput: (input) => dispatch(petsInput(input)),
  peopleInput: (input) => dispatch(peopleInput(input)),
  adultInput: (input) => dispatch(adultInput(input)),
  childrenInput: (input) => dispatch(childrenInput(input)),
  requestsInput: (input) => dispatch(requestsInput(input)),
  aboutMeInput: (input) => dispatch(aboutMeInput(input)),
  householdYearlyInput: (input) => dispatch(householdYearlyInput(input)),

  submitApplication: (
    isOffer,
    applicants,
    applicantSummary,
    success,
    fail,
    children,
    applicationOption,
    allVehicles,
  ) =>
    dispatch(
      submitApplication(
        isOffer,
        applicants,
        applicantSummary,
        success,
        fail,
        children,
        applicationOption,
        allVehicles,
      ),
    ),
  submitQuickApplication: (applicants, applicantSummary) =>
    dispatch(submitQuickApplication(applicants, applicantSummary)),
  heartClicked: (propertyId, isFavorite) =>
    dispatch(heartClicked(propertyId, isFavorite)),
  fetchOfferedApplication: (id) => dispatch(fetchOfferedApplication(id)),
  acceptWithdraw: (id, success, fail) =>
    dispatch(acceptWithdraw(id, success, fail)),
  acceptSecondaryWithdraw: (applicationId, applicantId, success, fail) =>
    dispatch(
      acceptSecondaryWithdraw(applicationId, applicantId, success, fail),
    ),
  addRentalReputationBackUrl: (url) =>
    dispatch(addRentalReputationBackUrl(url)),
  saveDraftApplication: (
    applicants,
    applicantSummary,
    isApplyAnywhere,
    applyAnywhereGUID,
    children,
    applicationOption,
    allVehicles,
    config,
    referrerPayload,
  ) =>
    dispatch(
      saveDraftApplication(
        applicants,
        applicantSummary,
        isApplyAnywhere,
        applyAnywhereGUID,
        children,
        applicationOption,
        allVehicles,
        config,
        referrerPayload,
      ),
    ),
  acceptApplication: (offerId, applicationId, propertyId) =>
    dispatch(acceptApplication(offerId, applicationId, propertyId)),
  newTenant: (tenant) => dispatch(newTenant(tenant)),
  removeTenant: (applicantId, success) =>
    dispatch(removeTenant(applicantId, success)),
  acceptJointApplicationAsSecondary: (
    applicationId,
    applicantId,
    applicantSummary,
    apply,
  ) =>
    dispatch(
      acceptJointApplicationAsSecondary(
        applicationId,
        applicantId,
        applicantSummary,
        apply,
      ),
    ),
  deleteDraftApplication: (applicationId, success, fail) =>
    dispatch(deleteDraftApplication(applicationId, success, fail)),
  addCurrentNewTenant: (currentTenant) =>
    dispatch(addCurrentNewTenant(currentTenant)),
  clearCurrentNewTenant: () => dispatch(clearCurrentNewTenant()),
  clearRentalBackUrl: () => dispatch(clearRentalBackUrl()),
  fetchApplyAnywhereListing: (applyAnywhereListingGUID) =>
    dispatch(fetchApplyAnywhereListing(applyAnywhereListingGUID)),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
  removeOccupant: (teamId, occupantId) =>
    dispatch(removeOccupant(teamId, occupantId)),
  createOffPlatformApplication: (application) =>
    dispatch(createOffPlatformApplication(application)),
  updateOffPlatformApplication: (application, applicationId) =>
    dispatch(editOffPlatformApplication(application, applicationId)),
  deleteManagerAttachment: (documentId) =>
    dispatch(deleteManagerAttachment(documentId)),
  fetchTeamSettingInformation: (teamId, queryString) =>
    dispatch(fetchTeamSettingInformation(teamId, queryString)),
  startNewApplication: () => dispatch(startNewApplication()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplyContainer)
