import React, { useState } from 'react'

import { Formik } from 'formik'
import styled from 'styled-components'

import { Alert, Box, Flex } from 'app/components/design-system-components'
import {
  InputField,
  TelField,
} from 'app/components/design-system-components/inputs'
import { CheckboxesInputField } from 'app/components/design-system-components/inputs/Checkboxes'
import {
  landlinePlaceholder,
  mobileTelPlaceholder,
} from 'app/components/design-system-components/inputs/Tel'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { PageSection } from 'app/components/design-system-components/page/Section'
import { Scrollbar } from 'app/components/design-system-components/styles/mixins/Scrollbar'
import { Heading } from 'app/components/design-system-components/typography'
import {
  declineReasonsConfig,
  declineReasonsIDs,
} from 'app/constants/rental-references.constants'
import { StyledFieldWrapper } from 'app/features/teams/key-logger/components/StepLayoutContainer'
import {
  agentContactFormFieldName,
  agentContactFormFields,
  formFields,
  formValidation,
} from 'app/features/teams/rental-references/RentalReferenceDeclineModal/form.utils'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const declineReasonsOptions = Object.entries(declineReasonsConfig)
  .filter(([id]) => id !== declineReasonsIDs.OTHER_REASON + '')
  .map(([id, config]) => {
    const intId = parseInt(id)
    return {
      option: intId,
      optionMeta: {
        id: intId,
        displayText: config.title,
      },
    }
  })

const ModalBodyContainer = styled(Box)`
  max-height: 100%;
  overflow-y: auto;
  ${Scrollbar()}
`

const RentalReferenceDeclineModalFormBody = ({
  onDismiss,
  error,
  formBag: { values, setFieldValue, isSubmitting, isValid, submitForm },
}) => {
  const selectedDeclineReason = Object.values(
    values[formFields.declineReason],
  )[0]

  const redirectContactSubForm = selectedDeclineReason?.option ===
    declineReasonsIDs.NOT_THE_PROPERTY_MANAGER &&
    values[formFields.redirectReference] && (
      <Box mt={theme.space[4] + 'px'}>
        <StyledFieldWrapper
          id={formFields.agentContact.firstName}
          name={formFields.agentContact.firstName}
          label="First name"
          required
        >
          <InputField
            id={formFields.agentContact.firstName}
            name={formFields.agentContact.firstName}
            inputProps={{ placeholder: 'Enter PM first name' }}
          />
        </StyledFieldWrapper>

        <StyledFieldWrapper
          id={formFields.agentContact.lastName}
          name={formFields.agentContact.lastName}
          label="Last name"
          required
        >
          <InputField
            id={formFields.agentContact.lastName}
            name={formFields.agentContact.lastName}
            inputProps={{ placeholder: 'Enter PM last name' }}
          />
        </StyledFieldWrapper>

        <StyledFieldWrapper
          id={formFields.agentContact.email}
          name={formFields.agentContact.email}
          label="Email"
        >
          <InputField
            id={formFields.agentContact.email}
            name={formFields.agentContact.email}
            inputProps={{ placeholder: 'Enter Manager Email' }}
          />
        </StyledFieldWrapper>

        <StyledFieldWrapper
          id={formFields.agentContact.mobile}
          name={formFields.agentContact.mobile}
          label="Mobile"
        >
          <TelField
            name={formFields.agentContact.mobile}
            placeholder={mobileTelPlaceholder}
          />
        </StyledFieldWrapper>

        <StyledFieldWrapper
          id={formFields.agentContact.officeNumber}
          name={formFields.agentContact.officeNumber}
          label="Office Number"
        >
          <TelField
            name={formFields.agentContact.officeNumber}
            placeholder={landlinePlaceholder}
          />
        </StyledFieldWrapper>
      </Box>
    )

  const redirectContactSection = selectedDeclineReason?.option ===
    declineReasonsIDs.NOT_THE_PROPERTY_MANAGER && (
    <PageSection>
      <Box mb={theme.space[5] + 'px'}>
        <Heading level={4} displayingLevel={5}>
          Redirect rental reference
        </Heading>
      </Box>

      <Box>
        <label className="ibm filter-today-checkbox">
          <input
            type="checkbox"
            className="input-box wa pt0"
            onClick={() =>
              setFieldValue(
                formFields.redirectReference,
                !values[formFields.redirectReference],
              )
            }
            checked={values[formFields.redirectReference]}
          />
          <Box display="inline-block" ml={theme.space[3] + 'px'}>
            Redirect rental reference to another contact
          </Box>
        </label>
      </Box>

      {redirectContactSubForm}
    </PageSection>
  )

  return (
    <GenericModal
      onDismiss={() => onDismiss()}
      title={<Flex alignItems="center">Reason</Flex>}
      modalFooter={
        <GenericModalFooter
          primaryBtnConfig={{
            title: 'Submit',
            onClick: () => submitForm(),
            props: {
              loading: !!isSubmitting,
              disabled: !isValid,
              type: 'submit',
            },
          }}
          secondaryBtnConfig={{
            title: 'Cancel',
            onClick: () => onDismiss(),
          }}
        />
      }
    >
      <ModalBodyContainer mb={theme.space[5] + 'px'}>
        {!!error && (
          <Alert variant="warningWithBg" mb={theme.space[3] + 'px'}>
            {error}
          </Alert>
        )}
        <PageSection>
          <CheckboxesInputField
            id={formFields.declineReason}
            name={formFields.declineReason}
            isRadioGroup
            options={declineReasonsOptions}
            optionRenderer={({ optionMeta }) => optionMeta.displayText}
          />
        </PageSection>

        {redirectContactSection}
      </ModalBodyContainer>
    </GenericModal>
  )
}

export const RentalReferenceDeclineModal = ({
  onSubmitDecline,
  onCloseModal,
}) => {
  const [errorMessage, setErrorMessage] = useState('')

  const initialValues = {
    [formFields.declineReason]: {},
    [agentContactFormFieldName]: {
      [agentContactFormFields.firstName]: null,
      [agentContactFormFields.lastName]: null,
      [agentContactFormFields.email]: null,
      [agentContactFormFields.mobile]: null,
      [agentContactFormFields.officeNumber]: null,
    },
  }
  const onSubmit = (values) => {
    setErrorMessage('')
    const selectedOption = Object.values(values[formFields.declineReason])[0]
      .option

    const basePayload = {
      declineReason: selectedOption,
    }

    const redirectRR = values[formFields.redirectReference]
    if (
      selectedOption === declineReasonsIDs.NOT_THE_PROPERTY_MANAGER &&
      !!redirectRR
    ) {
      const {
        [agentContactFormFields.firstName]: firstName,
        [agentContactFormFields.lastName]: lastName,
        [agentContactFormFields.email]: email,
        [agentContactFormFields.mobile]: phone,
        [agentContactFormFields.officeNumber]: officeNumber,
      } = values[agentContactFormFieldName]
      return onSubmitDecline({
        ...basePayload,
        redirectData: {
          firstName,
          lastName,
          email,
          phone,
          officeNumber,
        },
      })
    }

    return onSubmitDecline(basePayload)
  }

  const onDismiss = () => {
    onCloseModal()
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={formValidation}
    >
      {(formBag) => (
        <RentalReferenceDeclineModalFormBody
          formBag={formBag}
          onDismiss={onDismiss}
          error={errorMessage}
        />
      )}
    </Formik>
  )
}
