import React from 'react'

const TwoColumnContainer = ({ children }) => (
  <div className="two-col-box-flex-row sm73">{children}</div>
)

export const LeftComponent = ({ children }) => (
  <div className="col-first">{children}</div>
)

export default TwoColumnContainer
