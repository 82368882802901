import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  Box,
  Button,
  ButtonWithIcon,
  ButtonWithLoading,
  Flex,
  LoadingSection,
} from 'app/components/design-system-components'
import { EventRounded } from 'app/components/design-system-components/icons/actions'
import {
  MailOutlineRounded,
  MessageRounded,
} from 'app/components/design-system-components/icons/communications'
import { fontSizes } from 'app/components/design-system-components/styles/variables'
import { FeedItem } from 'app/features/teams/applications/application-activities/ApplicationSideTabs/FeedItem'
import { getApplicationStatusActivity } from 'app/services/http/applications/activity'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { loadingStatesIds } from 'app/utils/loading-states'

const PAGE_SIZE = 20

export const activityTabCategories = {
  app_event: { id: 'app_event', label: 'Events', icon: EventRounded },
  email: { id: 'email', label: 'Email', icon: MailOutlineRounded },
  sms: { id: 'sms', label: 'SMS', icon: MessageRounded },
}

const ActivityTab = ({
  applicationId,
  applicantId,
  teamID,
  disableCommunicationLogs,
}) => {
  const {
    state: activities,
    setState: setActivities,
    loadingStates: activitiesLoadingStates,
    loadingStatesHelpers: activitiesLoadingStatesHelpers,
  } = useStateWithLoading([])

  const [filters, setFilters] = useState({
    [activityTabCategories.app_event.id]: true,
    [activityTabCategories.email.id]: !disableCommunicationLogs,
    [activityTabCategories.sms.id]: !disableCommunicationLogs,
  })

  const [nextCursor, setNextCursor] = useState('')

  useEffect(() => {
    loadAppActivities()
  }, [filters])

  const updateFilters = (newFilters) => {
    setActivities([])
    setNextCursor('')
    setFilters(newFilters)
  }

  const toggleFilterCategory = (categoryId) => {
    updateFilters({ ...filters, [categoryId]: !filters[categoryId] })
  }
  const setAllFilterCategories = () => {
    updateFilters({
      [activityTabCategories.app_event.id]: true,
      [activityTabCategories.email.id]: true,
      [activityTabCategories.sms.id]: true,
    })
  }

  const isAllCategoryFiltersSet = () =>
    Object.values(filters).every((value) => value)

  const loadAppActivities = () => {
    activitiesLoadingStatesHelpers.startLoading()
    return getApplicationStatusActivity(
      applicationId,
      applicantId,
      teamID,
      filters,
      nextCursor,
      PAGE_SIZE,
    )
      .then(({ logs, next_cursor }) => {
        setActivities([...activities, ...(logs || [])])
        setNextCursor(next_cursor)
        activitiesLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => activitiesLoadingStatesHelpers.setError(err))
  }

  const renderCategoryHeader = (category) => (
    <>
      <ButtonWithIcon
        data-tooltip-id={category.id}
        data-tooltip-content=""
        variant={filters[category.id] ? 'blueTabHeaderActive' : 'pagination'}
        IconCmp={category.icon}
        iconContainerProps={{ margin: 0 }}
        p="6px !important"
        mx={3}
        onClick={() => toggleFilterCategory(category.id)}
      />
      <ReactTooltip id={category.id}>
        <span>{category.label}</span>
      </ReactTooltip>
    </>
  )

  const renderFeedItem = (act, idx) => {
    const category = activityTabCategories[act.type]
    const showIcon = [
      activityTabCategories.email.id,
      activityTabCategories.sms.id,
    ].includes(category?.id)

    return (
      <FeedItem
        activityLog={act}
        category={category?.id}
        lastItem={idx === activities.length - 1}
        key={idx}
        showIcon={showIcon}
      />
    )
  }

  const renderCategoryFilters = () => {
    if (disableCommunicationLogs) return
    return (
      <Flex width="100%" alignItems="center" my={5}>
        <Box borderRight={1} pr={4} mr={1} fontSize={fontSizes.pLarge16}>
          <Button
            variant={isAllCategoryFiltersSet() ? 'linkBlue' : 'linkGray'}
            onClick={() => setAllFilterCategories()}
          >
            All
          </Button>
        </Box>
        {Object.values(activityTabCategories).map(renderCategoryHeader)}
      </Flex>
    )
  }

  return (
    <>
      {renderCategoryFilters()}
      <LoadingSection
        loadingState={activitiesLoadingStates}
        loaderProps={{ fontSize: '50px' }}
        actionHandler={loadAppActivities}
      >
        <Box mt={3}>
          {activities.map((activityLog, idx) =>
            renderFeedItem(activityLog, idx),
          )}
          {!activities.length && (
            <Box m={4} textAlign="center">
              No Activity for this application
            </Box>
          )}
        </Box>
        {nextCursor && (
          <Flex mt="10px" justifyContent="center">
            <ButtonWithLoading
              height="40px"
              variant="outlineBlue"
              onClick={() => loadAppActivities()}
              loading={
                activitiesLoadingStates.state === loadingStatesIds.LOADING
              }
              disableWhenLoading
            >
              Load more
            </ButtonWithLoading>
          </Flex>
        )}
      </LoadingSection>
    </>
  )
}

const mapStateToProps = ({ session }) => ({
  teamID: session.currentTeam.guid,
})

export const ApplicationActivityTab = connect(mapStateToProps)(ActivityTab)
