import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { LoadingScreen } from 'app/shared_components/loading_screen'

const ShortCodeRedirect = ({
  match: { params },
  fetchPersonalReferenceReviewByShortCode,
}) => {
  const { shortCode } = params

  const history = useHistory()

  useEffect(() => {
    fetchPersonalReferenceReviewByShortCode(shortCode)
      .then(({ guidID, secret }) => {
        history.push(`/sm/personal-reference/survey/${guidID}/secret/${secret}`)
      })
      .catch(() => {
        history.push('/404')
      })
  }, [])

  return <LoadingScreen />
}

export default ShortCodeRedirect
