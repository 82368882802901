import BaseInput from 'app/components/forms/base_input/component'
import {
  firstElementOrEmptyString,
  withValidation,
} from 'app/components/forms/util'
import * as validations from 'app/shared_components/validations'

const validateNumber = (value) =>
  firstElementOrEmptyString(
    validations.validateNumberWithTwoDecimals(value, 'Invalid number'),
  )

const InputNumberWithTwoDecimals = withValidation(BaseInput, validateNumber)

export default InputNumberWithTwoDecimals
