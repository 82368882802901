import ErrorMessages from 'app/constants/error_messages'
import {
  ADD_VIEWINGS,
  CLEAR_ERROR,
  DELETE_VIEWINGS,
  EDIT_VIEWINGS,
  PING,
  RECEIVE_ERROR,
  RECEIVE_RESPONSE_TEXT,
  RECEIVE_VIEWINGS,
  TOGGLE_SPINNER,
  UPDATE_FIELD,
  UPDATED_VIEWINGS,
} from 'app/sm/ppp/viewing_actions'

const _defaultState = {
  viewings: [],
  updatedViewing: {},
  spinner: false,
  error: '',
  responseText: '',
}

function parseResponseText(responseText) {
  return responseText ? responseText.replace(/\n/, '') : ''
}

const Viewing = (state = _defaultState, action) => {
  let { type, viewings, viewing, error, responseText, offerId } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case PING:
      return Object.assign(newState, { error: '', responseText: '' })
    case RECEIVE_VIEWINGS:
      return Object.assign(newState, { viewings })
    case ADD_VIEWINGS:
      return Object.assign({}, state, {
        viewings: [...state.viewings, viewing],
        updatedViewing: {},
      })
    case DELETE_VIEWINGS:
      const viewingId = viewing.viewingId
      const filteredViewings = state.viewings.filter(
        (v) => v.guidID !== viewingId,
      )
      return Object.assign(newState, { viewings: filteredViewings })
    case EDIT_VIEWINGS:
      return Object.assign({}, state, {
        updatedViewing: viewing,
      })
    case UPDATED_VIEWINGS:
      const updatedViewing = viewing
      const updatedViewingIndex = state.viewings.findIndex(
        (v) => v.guidID === viewing.guidID,
      )

      return Object.assign({}, state, {
        viewings: [
          ...state.viewings.slice(0, updatedViewingIndex),
          viewing,
          ...state.viewings.slice(updatedViewingIndex + 1),
        ],
        updatedViewing: _defaultState.updatedViewing,
      })
    case TOGGLE_SPINNER:
      return Object.assign(newState, { spinner: !newState.spinner })
    case UPDATE_FIELD:
      // eslint-disable-next-line no-undef
      const p = Object.assign({}, newState.updatedViewing, { [key]: val })
      return Object.assign({}, newState, { updatedViewing: p })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case RECEIVE_RESPONSE_TEXT:
      const errorCode = parseInt(parseResponseText(responseText))
      const errorMessage = ErrorMessages[errorCode]
      return Object.assign(newState, {
        responseText: errorCode,
        error: errorMessage,
      })
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    default:
      return newState
  }
}

export default Viewing
