import type React from 'react'

import styled from 'styled-components'
import { space, variant } from 'styled-system'

import Box from 'app/components/design-system-components/Box'
import { IconButton } from 'app/components/design-system-components/Button'
import Flex from 'app/components/design-system-components/Flex'
import SvgInfoRounded from 'app/components/design-system-components/icons/actions/InfoRounded'
import SvgWarningLighter from 'app/components/design-system-components/icons/actions/WarningLighter'
import * as getters from 'app/components/design-system-components/theme/getters'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const alertVariants = {
  variants: {
    successWithBg: {
      color: 'success800',
      backgroundColor: 'success100',
      '.action-icon-btn': {
        color: 'success800',
      },
    },
    errorWithBg: {
      color: theme.colors.white,
      backgroundColor: theme.colors.error400,
    },
    warningWithBg: {
      color: 'warning600',
      backgroundColor: 'warning100',
      '.action-icon-btn': {
        color: 'warning600',
      },
    },
    lightWarningWithBg: {
      color: 'warning500',
      backgroundColor: 'warning100',
      '.action-icon-btn': {
        color: 'warning500',
      },
    },
    blueWithBg: {
      color: 'deepBlue',
      backgroundColor: 'paleBlue',
      '.action-icon-btn': {
        color: 'deepBlue',
      },
    },
    blackWithBlueBg: {
      color: 'black',
      backgroundColor: 'paleBlue',
    },
    greyWithBg: {
      color: 'black',
      backgroundColor: 'grey1',
    },
    primaryWithBg: {
      color: theme.colors.primary600,
      backgroundColor: theme.colors.primary100,
    },
    grayWithBg: {
      color: 'gray500',
      backgroundColor: 'paleBlue',
      '.action-icon-btn': {
        color: 'gray500',
      },
    },
  },
}

const AlertContainer = styled(Box)`
  border-radius: ${getters.radius(6)}px;
  line-height: ${({ theme }) => `${theme.lineHeights.copy}em`};
  padding: ${({ theme }) => `${theme.space[5]}px ${theme.space[5]}px`};
  ${variant(alertVariants)}
  ${space}
`

export const Alert = ({
  showLeftIcon,
  leftIconVariant = 'none',
  actionIconBtnConfig = undefined,
  children,
  ...props
}: AlertProps) => {
  if (!actionIconBtnConfig) {
    return (
      <AlertContainer {...props}>
        {' '}
        <Flex alignItems="center">
          {showLeftIcon && leftIconVariant === 'info' && (
            <Flex mr={5} alignItems="center">
              <SvgInfoRounded
                color={theme.colors.deepBlue}
                width={theme.width[4] + 'px'}
                height={theme.height[4] + 'px'}
              />
            </Flex>
          )}
          {showLeftIcon && leftIconVariant === 'warning' && (
            <Flex mr={5} alignItems="center">
              <SvgWarningLighter
                width={theme.width[4] + 'px'}
                height={theme.height[4] + 'px'}
              />
            </Flex>
          )}
          {children}
        </Flex>
      </AlertContainer>
    )
  }

  const { onClick, iconCmp } = actionIconBtnConfig
  const container = (
    <Flex justifyContent="space-between">
      <Box>{children}</Box>
      <Box>
        <IconButton
          IconCmp={iconCmp}
          iconProps={{ fontSize: theme.fontSizes.pSurplusLarge24 }}
          onClick={onClick}
          className="action-icon-btn"
        />
      </Box>
    </Flex>
  )

  return <AlertContainer {...props}>{container}</AlertContainer>
}

interface AlertProps {
  showLeftIcon?: boolean
  leftIconVariant?: string
  actionIconBtnConfig?: any
  children: React.ReactNode

  [x: string]: any
}
