import { LogFetchError } from 'app/shared_components/helpers'
import * as sharedHelpers from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const addOnlistDetails = (agencyGUID, propertyGUID, requestBody) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/property/${propertyGUID}/entrydetails`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(requestBody),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getEntryDetailsByTeamGUIDAndPropertyGUID = (
  agencyGUID,
  propertyGUID,
) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/property/${propertyGUID}/entrydetails`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const updateOnlistDetails = (agencyGUID, propertyGUID, requestBody) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/property/${propertyGUID}/entrydetails`
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(requestBody),
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getPropertyVideoLinks = (agencyGUID, propertyGUID) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/property/${propertyGUID}/virtualtour`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const addVirtualTourVideoLinks = (
  agencyGUID,
  propertyGUID,
  requestBody,
) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/property/${propertyGUID}/virtualtour`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(requestBody),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getAllPropertyLookupsForProperty = (agencyGUID, offerGUID) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/property/${offerGUID}/propertyLookup`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const postPropertyLookupForProperty = (
  agencyGUID,
  offerGUID,
  requestBody,
) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/property/${offerGUID}/propertyLookup`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(requestBody),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const deletePropertyLookupForProperty = (
  agencyGUID,
  offerGUID,
  propertyLookupGUID,
) => {
  const url = `${apiBaseUrl}sm/teams/${agencyGUID}/property/${offerGUID}/propertyLookup/${propertyLookupGUID}`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const putOfferWithViewingScheduleZone = (
  agencyGUID,
  offerGUID,
  viewingScheduleZone,
) => {
  const url = `${apiBaseUrl}sm/agency/${agencyGUID}/offers/${offerGUID}/viewingschedulezone`
  const requestBody = {
    viewingScheduleZone,
  }
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(requestBody),
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const putOfferWithViewingRegistrantsLimit = (
  agencyGUID,
  offerGUID,
  requestBody,
) => {
  const url = `${apiBaseUrl}sm/agency/${agencyGUID}/offers/${offerGUID}/viewingregistrant`
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(requestBody),
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const putOfferWithPropertyViewingSchedule = (
  agencyGUID,
  offerGUID,
  showViewingScheduleBeforeVacant,
) => {
  const url = `${apiBaseUrl}sm/agency/${agencyGUID}/offers/${offerGUID}/propertyviewingschedule`
  const requestBody = {
    showViewingScheduleBeforeVacant,
  }
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(requestBody),
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}
