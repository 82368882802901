export const setupVideos = [
  {
    label: 'Invite Team (2 min)',
    url: 'https://vimeo.com/744850460/7cf4b29f00',
  },
  { label: 'Add Logo (1 min)', url: 'https://vimeo.com/744851108/91d8eaca12' },
  {
    label: 'Apply Link (1 min)',
    url: 'https://vimeo.com/744851491/debb81917d',
  },
  {
    label: 'Connect Utility Provider (1 min)',
    url: 'https://vimeo.com/744852006/8b34327ef8',
  },
  {
    label: 'Connect Property Uploader (1 min)',
    url: 'https://vimeo.com/744852446/137e3a3145',
  },
  {
    label: 'Turn on your enquiry autoresponder (2 min)',
    url: 'https://vimeo.com/744852933/afa98e3ccc',
  },
  {
    label: 'Disable Other Application Forms (1 min)',
    url: 'https://vimeo.com/744854044/816701dd28',
  },
  {
    label: 'Connect Property Management Software (2 min)',
    url: 'https://vimeo.com/744854451/543694f9e9',
  },
  {
    label: 'Update website with Apply Link (1 min)',
    url: 'https://vimeo.com/744854928/21f218ad58',
  },
  {
    label: 'Lease Offer (3 min)',
    url: 'https://vimeo.com/744855365/0e21b10240',
  },
]

export const applicationsVideos = [
  {
    label: 'Processing Applications (10 min)',
    url: 'https://vimeo.com/745623204/039c3941c0',
  },
  {
    label: 'Dashboard (2 min)',
    url: 'https://vimeo.com/745623263/af81d86952',
  },
  {
    label: 'How renters apply using Snug (4 min)',
    url: 'https://vimeo.com/745623284/8259d65156',
  },
]

export const viewingsVideos = [
  {
    label: 'Viewings (6 min)',
    url: 'https://vimeo.com/745620757/fb41507c04',
  },
  {
    label: 'Viewings on mobile (2 min)',
    url: 'https://vimeo.com/745620711/ebce1dbdcf',
  },
  {
    label: 'Messages (4 min)',
    url: 'https://vimeo.com/745620561/06b2b5207a',
  },
  {
    label: 'Key Management (3 min)',
    url: 'https://vimeo.com/745620508/a238adc137',
  },
]

export const trainingVideos = [
  {
    label: 'New User Training (30 min)',
    url: 'https://vimeo.com/745618542/7d8e12ff52',
  },
]
