import { fetchRentalHistoryReview } from 'app/bond_cover/bond_cover_access'
import { mapLegacyAccessPromiseToServiceFnPromise } from 'app/services/http/utils'
import { api } from 'app/utils/api/helpers'

export const getRentalReferencesCount = (agencyGUID, filters) => {
  const { managerValue, statusValue, dateValue, searchValue, ...otherFilters } =
    filters

  return api.get(`sm/teams/${agencyGUID}/rentalreferences/count`, {
    params: {
      ...(searchValue && { q: searchValue }),
      ...(dateValue && { sort_dir: dateValue }),
      ...(managerValue && { assignee: managerValue }),
      ...(statusValue && { status: statusValue }),
      ...otherFilters,
    },
  })
}

export const getRentalHistoryReview = (referenceId, secretGUID) => {
  return mapLegacyAccessPromiseToServiceFnPromise(
    fetchRentalHistoryReview(referenceId, secretGUID),
  )
}

export const uploadLedgerByRenter = (rentalHistoryID, formData) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  return api.post(
    `sm/reputation/rentalhistory/${rentalHistoryID}/manual/ledger/renter`,
    formData,
    headers,
  )
}

export const uploadUnboundedLedgerByRenter = (formData) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  return api.post(
    'sm/reputation/rentalhistory/manual/ledger/renter',
    formData,
    headers,
  )
}

export const deleteRentalLedger = (rentalID, ledgerID) => {
  return api.delete(
    `sm/reputation/rentalhistory/${rentalID}/manual/ledger/${ledgerID}`,
  )
}

export const deleteUnboundedLedger = (id) => {
  return api.delete(`sm/reputation/rentalhistory/manual/ledger/${id}`)
}

export const redirectRentalReferenceByPM = (
  agencyGUID,
  rentalHistoryGUID,
  applicationGUID,
  applicantGUID,
  contact,
) => {
  return api.post(`sm/teams/${agencyGUID}/rentalreferences/redirect`, {
    rentalHistoryGUID,
    applicationGUID,
    applicantGUID,
    ...contact,
  })
}

export const resendReferenceRequest = (historyId, resendData) => {
  return api.post(
    `sm/reputation/rentalhistory/${historyId}/manager/reviewreminderresend`,
    {
      ...resendData,
    },
  )
}
