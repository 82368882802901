import React from 'react'

import styled from 'styled-components'

import background from 'app/assets/icons/background-report/explainer/bc-explainer-background.svg'
import backgroundCheckFooterBackground from 'app/assets/icons/background-report/explainer/bc-explainer-footer.svg'
import backgroundCheckHeaderBackground from 'app/assets/icons/background-report/explainer/bc-explainer-header.svg'
import icon from 'app/assets/icons/background-report/explainer/bc-explainer-icon.svg'
import logo from 'app/assets/icons/icon-solid.svg'
import footerIcon from 'app/assets/icons/logo-no-color.svg'
import { Box, Button, Flex } from 'app/components/design-system-components'
import {
  Heading,
  Text,
} from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import Theme from 'app/pages/teams/viewings-mobile/theme-styled-components'
import Status from 'app/sm/renter_background_check_report/components/status-sector/component'

const HELP_LINK = 'https://snug.com/help'

const HeaderContainer = styled(Box)`
  background-image: url(${backgroundCheckHeaderBackground}) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: relative;
  @media print {
    background-size: fixed !important;
  }
`

const HeaderContent = styled(Box)`
  padding: ${({ theme }) => {
    return `${theme.dividedSpace[10]}px ${theme.dividedSpace[6]}px`
  }};
  @media print {
    padding: ${({ theme }) =>
      `${theme.dividedSpace[4]}px ${theme.dividedSpace[6]}px`};
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;
    padding: ${({ theme }) =>
      `${theme.dividedSpace[5]}px ${theme.dividedSpace[2]}px ${theme.dividedSpace[3]}px ${theme.dividedSpace[2]}px`};
  }
`

const HeaderText = styled(Heading)`
  color: white !important;
  font-size: ${({ theme }) => {
    return `${theme.fontSizes[17]}px`
  }};
  font-weight: ${({ theme }) => `${theme.fontWeights[6]}`};
  margin-left: ${({ theme }) => {
    return `${theme.dividedSpace[6]}px`
  }};
  @media print {
    font-size: ${({ theme }) => `${theme.dividedSpace[7]}`};
  }

  @media screen and (max-width: 576px) {
    font-size: 25px;
    margin-left: ${({ theme }) => `${theme.dividedSpace[3]}px`};
    padding-right: ${({ theme }) => `${theme.dividedSpace[6]}px`};
  }
`

const SnugLogo = styled.img`
  @media screen and (max-width: 576px) {
    position: absolute;
    right: ${({ theme }) => `${theme.dividedSpace[2]}px`};
    top: ${({ theme }) => `${theme.dividedSpace[5]}px`};
  }
`
const FooterContainer = styled(Box)`
  position: relative;
`

const HelpLink = styled(Button)`
  font-size: ${({ theme }) => `${theme.fontSizes[5]}px`};
  line-height: ${({ theme }) => `${theme.lineHeights.encrption} !important`};
  vertical-align: baseline;
`

const PageText = styled.div`
  font-size: ${({ theme }) => `${theme.fontSizes[3]}px`};
  font-weight: ${({ theme }) => `${theme.fontWeights[4]}`};
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[2]}px`};
  line-height: ${({ theme }) => `${theme.lineHeights.encrption}`};
  @media print {
    line-height: 1.2;
    margin-bottom: ${({ theme }) => `${theme.dividedSpace[1]}px`};
  }
  text-align: justify;
`

const ExplainerText = styled(Text)`
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[2]}px`};
  line-height: 1.4;
  @media print {
    line-height: 1.2;
    margin-bottom: ${({ theme }) => `${theme.dividedSpace[1]}px`};
  }

  text-align: justify;
`

const ExplainerHeading = styled.p`
  font-size: ${({ theme }) => `${theme.dividedSpace[7]}px`};
  font-weight: ${({ theme }) => `${theme.fontWeights[6]}`};
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[2]}px`};
  @media print {
    font-size: 25px;
    margin-bottom: ${({ theme }) => `${theme.dividedSpace[1]}px`};
  }
`

const ExplainerContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.dividedSpace[7]}px ${theme.dividedSpace[10]}px ${theme.dividedSpace[7]}px ${theme.dividedSpace[10]}px`};
  @media print {
    transform: scale(0.9);
    padding: ${({ theme }) =>
      `5px ${theme.dividedSpace[2]}px 4px ${theme.dividedSpace[2]}px`};
    margin-top: -43px;
    margin-bottom: -34px;
  }

  @media screen and (max-width: 576px) {
    padding: ${({ theme }) =>
      `0 ${theme.dividedSpace[2]}px 0 ${theme.dividedSpace[2]}px`};
  }
  background-image: url(${background}) !important;
  background-size: 700px !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
`

const HeaderImg = styled.img`
  @media screen and (max-width: 576px) {
    width: 80px;
  }
`

const ExplainerHeader = (
  <HeaderContainer>
    <HeaderContent>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" width="550px">
          <HeaderImg src={icon} alt="dismiss" />
          <HeaderText>Background Check Report Explainer</HeaderText>
        </Flex>
        <SnugLogo src={logo} alt="dismiss" />
      </Flex>
    </HeaderContent>
  </HeaderContainer>
)

const ExplainerFooter = (
  <FooterContainer>
    <Box>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" width="300px">
          <img src={footerIcon} alt="dismiss" />
        </Flex>
        <Flex width="800px" justifyContent="space-between" alignItems="center">
          <Box>
            <PageText>
              © Snug Technologies Pty Ltd. ‘Snug’ is a registered Trademark. All
              rights reserved. ABN 82 616 484 292
            </PageText>
          </Box>
        </Flex>
      </Flex>
    </Box>
    <img src={backgroundCheckFooterBackground} alt="dismiss" />
  </FooterContainer>
)

const ExplainerCategoryContainer = styled.div`
  border-bottom: 0.5px solid rgba(189, 187, 202, 1);
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[4]}px`};
  padding-bottom: ${({ theme }) => `${theme.dividedSpace[4]}px`};
  @media print {
    margin-bottom: ${({ theme }) => `${theme.dividedSpace[1]}px`};
    padding-bottom: ${({ theme }) => `${theme.dividedSpace[1] + 2.5}px`};
  }
`

const ExplainerCategoryHeading = styled.p`
  font-size: 25px;
  font-weight: 700;
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[2]}px`};
  @media print {
    font-size: 21px;
    margin-bottom: ${({ theme }) => `${theme.dividedSpace[1]}px`};
  }
`

const ExplainerCategorySubHeading = styled.p`
  font-size: 20px;
  font-weight: ${({ theme }) => `${theme.fontWeights[5]}`};
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[2]}px`};
  @media print {
    font-size: 16.5px;
    margin-bottom: ${({ theme }) => `${theme.dividedSpace[1]}px`};
  }
`

const ExplainerCategoryWrapper = styled(Flex)`
  justify-content: space-between;

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`

const ExplainerCategoryColumn = styled(Box)`
  width: 47%;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
`

const ExplainerCategory = ({
  label = '',
  leftContent = <></>,
  rightContent = <></>,
}) => {
  return (
    <ExplainerCategoryContainer>
      <ExplainerCategoryHeading>{label}</ExplainerCategoryHeading>
      <ExplainerCategoryWrapper>
        <ExplainerCategoryColumn>
          <ExplainerCategorySubHeading>
            Records checked
          </ExplainerCategorySubHeading>
          {leftContent}
        </ExplainerCategoryColumn>
        <ExplainerCategoryColumn>
          <ExplainerCategorySubHeading>
            Result interpretation
          </ExplainerCategorySubHeading>
          {rightContent}
        </ExplainerCategoryColumn>
      </ExplainerCategoryWrapper>
    </ExplainerCategoryContainer>
  )
}

const ResultInterpretationItem = ({ statusText, statusClass, paragraph }) => {
  return (
    <Box mt={theme.space[4] + 'px'}>
      <Status statusText={statusText} statusClass={statusClass} />
      <Box mt={theme.space[4] + 'px'}>
        <CategoryText>{paragraph}</CategoryText>
      </Box>
    </Box>
  )
}

const CategoryText = styled(Text)`
  line-height: 1.4;
  @media print {
    line-height: 1.2;
  }
  text-align: justify;
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[2]}px`};
  @media print {
    margin-bottom: ${({ theme }) => `${theme.dividedSpace[1]}px`};
  }
`

const IntroduceContainer = styled(Box)`
  margin: ${({ theme }) => `${theme.dividedSpace[4]}px 0 62px 0`};
  @media print {
    margin: ${({ theme }) => {
      return `${theme.dividedSpace[2]}px 0 ${theme.dividedSpace[4]}px 0`
    }};
  }
`

const Explainer = () => {
  const IdentityVerification = {
    left: (
      <CategoryText>
        This individual's identity documents are checked for validity through
        the Australian Government Document Verification Service (DVS).
      </CategoryText>
    ),
    right: (
      <>
        <ResultInterpretationItem
          statusText={'Verified'}
          statusClass={'Verified'}
          paragraph={
            'We were able to successfully verify all details of the provided document via the Australian Government Document Verification Service (DVS).'
          }
        />
        <ResultInterpretationItem
          statusText={'Not Verified'}
          statusClass={'not-verified'}
          paragraph={
            'We were able to successfully verify all details of the provided document via the Australian Government Document Verification Service (DVS).'
          }
        />
      </>
    ),
  }

  const LegalRecords = {
    left: (
      <>
        <Box mt={theme.space[4] + 'px'}>
          <CategoryText fontWeight={theme.fontWeights[5]}>
            Bankruptcy Records
          </CategoryText>
          <CategoryText>
            Includes Bankruptcy Act Information, such as bankruptcies, debt
            agreements and personal insolvencies, is publicly available
            information that is obtained from the Australian Financial Security
            Authority (AFSA). Generally speaking this information is held on an
            individual's credit report for 5 years, however, the retention
            period can vary depending upon a number of circumstances including
            the length of the bankruptcy or agreement.
          </CategoryText>
        </Box>
        <Box mt={theme.space[4] + 'px'}>
          <CategoryText fontWeight={theme.fontWeights[5]}>
            Court Records
          </CategoryText>
          <CategoryText>
            Includes publicly available court records related to the individual
            (as a named party to the court action only) that are checked and
            displayed here.
          </CategoryText>
        </Box>
        <Box mt={theme.space[4] + 'px'}>
          <CategoryText fontWeight={theme.fontWeights[6]}>
            Directorships and Propriertorships
          </CategoryText>
          <CategoryText>
            Includes Directorships and Propriertorships which are or have been
            registered with Australian Securities and Investments Commission
            (ASIC) that are checked and displayed here. ASIC company data
            records show if the individual has been disqualified from
            involvement in the management of a corporation.
          </CategoryText>
        </Box>
      </>
    ),
    right: (
      <>
        <ResultInterpretationItem
          statusText={'Not found'}
          statusClass={'Verified'}
          paragraph={
            'No records were found on the Australian Financial Security Authority (AFSA), the Australian Securities and Investments Commission (ASIC), or Equifax’s public record database using the personal details provided.'
          }
        />
        <ResultInterpretationItem
          statusText={'Info'}
          statusClass={'identity-verification-unverified'}
          paragraph={
            'Records were found on the Australian Financial Security Authority (AFSA), the Australian Securities and Investments Commission (ASIC), or Equifax’s public record database using the personal details provided. Records found are displayed.'
          }
        />
      </>
    ),
  }

  const Tenancy = {
    left: (
      <CategoryText>
        The individual's tenant blacklist and rental history (which may include
        breach notices, unpaid tribunal or court order monies, terminations,
        damage or cleaning) are checked through Equifax’s National Tenancy
        Database (this report check excludes TRA, TICA or real estate agency
        records). Note that activity within the last 30 days may not be captured
        and rental references are recommended. ENQUIRY means a search is
        recorded. NO RECORD FOUND may mean the tenant has not rented before or
        has no recorded rental history.
      </CategoryText>
    ),
    right: (
      <>
        <ResultInterpretationItem
          statusText={'Not found'}
          statusClass={'Verified'}
          paragraph={
            'No records were found which may mean the tenant has not rented before or has no recorded rental history. This reports records of current or previous directorships or proprietorships recorded on ASIC’s database. Enquiries are recorded and does not necessarily flag a problem.'
          }
        />
        <ResultInterpretationItem
          statusText={'Info'}
          statusClass={'identity-verification-unverified'}
          paragraph={
            'We found records in the National Tenancy Database using the applicant’s personal details information. Records found are displayed.'
          }
        />
      </>
    ),
  }
  return (
    <Theme>
      {ExplainerHeader}
      <ExplainerContainer>
        <IntroduceContainer>
          <ExplainerHeading>Background Check Report</ExplainerHeading>
          <ExplainerText>
            This Background Check helps rental applicants stand out from the
            crowd and put their best foot forward with an instant report that
            verifies Identity and confirms if they are listed in Equifax's
            Tenant database*^ as well presents other important screening
            information.
          </ExplainerText>
        </IntroduceContainer>
        <ExplainerHeading>Information by Category</ExplainerHeading>
        <ExplainerCategory
          label="Identity verification"
          leftContent={IdentityVerification.left}
          rightContent={IdentityVerification.right}
        />
        <ExplainerCategory
          label="National Tenancy Database check"
          leftContent={Tenancy.left}
          rightContent={Tenancy.right}
        />
        <ExplainerCategory
          label="Legal Records"
          leftContent={LegalRecords.left}
          rightContent={LegalRecords.right}
        />
        <ExplainerCategoryWrapper>
          <ExplainerCategoryColumn>
            <ExplainerHeading>Common questions</ExplainerHeading>
            <ExplainerText>
              For more Frequently Asked Questions refer to <br />
              <HelpLink
                variant="linkBlue"
                onClick={() => window.open(HELP_LINK, '_blank').focus()}
                className="deleteButton"
                width="50px"
              >
                {HELP_LINK}
              </HelpLink>
            </ExplainerText>
          </ExplainerCategoryColumn>
          <ExplainerCategoryColumn>
            <ExplainerHeading>Validity period</ExplainerHeading>
            <ExplainerText>
              Each Background Check Report is valid for 6 months from the date
              of purchase.
            </ExplainerText>
          </ExplainerCategoryColumn>
        </ExplainerCategoryWrapper>
      </ExplainerContainer>
      {/* {ExplainerFooter} */}
    </Theme>
  )
}

export default Explainer
