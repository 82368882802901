import { connect } from 'react-redux'

import {
  fetchBondCoverReview,
  withdrawBondCoverRequest,
} from 'app/bond_cover/bond_cover_actions'
import WithdrawForm from 'app/bond_cover/renters/status/request_withdraw_container'

const mapStateToProps = ({ bondCover }) => ({
  bondCoverRequest: bondCover.request,
  currentLease: bondCover.currentLease,
  error: bondCover.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchBondCoverReview: (requestID, secret) =>
    fetchBondCoverReview(requestID, secret),
  withdraw: (data, requestID, success, fail) =>
    withdrawBondCoverRequest(data, requestID, success, fail),
})

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawForm)
