import React, { useEffect } from 'react'

import { Form, Formik } from 'formik'
import styled from 'styled-components'

import { Box, Button, Flex } from 'app/components/design-system-components'
import { InputField } from 'app/components/design-system-components/inputs'
import { Text } from 'app/components/design-system-components/typography'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { Iframe } from 'app/match/leadToSnug/styles'
import { validateEmail } from 'app/shared_components/validations'
import {
  MarketingAgencyCount,
  MarketingAgencyText,
  MarketingRRLiteVideoLink,
  PrimaryAccessReference,
} from 'app/utils/text/helpers'

const TEAM_MEMBERS_EMAIL_RENDER = 3

function validateSameEmail(key, val, values) {
  const keyArray = Object.keys(values).filter((objectKey) => key !== objectKey)
  const emailArray = keyArray.map((otherKey) => values[otherKey])

  if ((emailArray || []).find((email) => val === email)) {
    return 'This email is the same on other emails'
  }
  return false
}

const fieldValidation = (key, val, values) => {
  return {
    pattern:
      !!val && (validateEmail(val)[0] || validateSameEmail(key, val, values)),
  }
}

const formValidation = formValidationBuilder(fieldValidation)

const LeftContainer = styled(Box)`
  width: 50%;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    width: 100%;
  }
`
const RightContainer = styled(Box)`
  width: 50%;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    width: 100%;
    margin: ${({ theme }) =>
      `${theme.dividedSpace[4]}px 0 ${theme.dividedSpace[4]}px 0`};
  }
  margin: ${({ theme }) =>
    `0 ${theme.dividedSpace[4]}px 0 ${theme.dividedSpace[4]}px`};
`

const StyledFieldWrapper = styled(FieldWrapper)`
  max-width: 283px;
  border-color: ${({ theme }) => theme.colors.gray500};
  label {
    input {
      padding: ${theme.space[3]}px 0;
    }
  }
`

const StyledInputField = styled(InputField)`
  & input {
    height: ${({ theme }) => `${theme.height[12]}px`} !important;
  }
`

const ModalContainer = styled(Flex)`
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`

const Video = styled(Flex)`
  iframe {
    transform: scale(1.33);
  }
  overflow: hidden;
  width: 100%;
  margin-bottom: ${theme.space[5]}px;
`

const FormBody = ({
  formBag: { isValid, values },
  setAccessReferenceUser,
  markDirty,
}) => {
  useEffect(() => {
    const payloadInviteUsers = Object.keys(values).map((key) => {
      return {
        email: values[key],
        isAdmin: false,
        isFinance: false,
        isLeasing: false,
      }
    })
    setAccessReferenceUser(payloadInviteUsers)
  }, [values])

  useEffect(() => {
    if (isValid) {
      markDirty(true)
    } else {
      markDirty(false)
    }
  }, [isValid, markDirty])

  return (
    <Form>
      {Array(TEAM_MEMBERS_EMAIL_RENDER)
        .fill()
        .map((_, index) => {
          const emailOrder = index + 1
          return (
            <StyledFieldWrapper
              id={`email-address-${emailOrder}`}
              name={`email-address-${emailOrder}`}
              mb={5}
            >
              <StyledInputField
                id={`email-address-${emailOrder}`}
                name={`email-address-${emailOrder}`}
                inputProps={{
                  placeholder: `Team member email ${emailOrder} (optional)`,
                }}
              />
            </StyledFieldWrapper>
          )
        })}
    </Form>
  )
}

const LinkStyed = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizes[5]}px;
`

const AccessReferences = ({ setAccessReferenceUsers, markDirty }) => {
  return (
    <ModalContainer
      mt={`${theme.space[4]}px`}
      fontSize={theme.fontSizes.pLarge16}
      width="100%"
    >
      <LeftContainer>
        <Text mb={`${theme.space[4]}px`}>
          Save time and access past rental references for yourself and your
          team. <LinkStyed variant="linkBlue">Find out more</LinkStyed>
        </Text>
        <Text mb={`${theme.space[4]}px`}>
          Click “{PrimaryAccessReference}” to verify your email. Optionally
          invite team members to access references.
        </Text>
        <Formik
          initialValues={{}}
          validate={formValidation}
          validateOnBlur
          validateOnChange
        >
          {(props) => (
            <FormBody
              formBag={props}
              setAccessReferenceUser={setAccessReferenceUsers}
              markDirty={markDirty}
            />
          )}
        </Formik>
      </LeftContainer>
      <RightContainer>
        <Video justifyContent="center">
          <Iframe width="auto" height="100%" src={MarketingRRLiteVideoLink} />
        </Video>
        <Box textAlign="center">
          <Text
            fontWeight={theme.fontWeights[6]}
            fontSize={`${theme.fontSizes[6]}px`}
            mb={`${theme.space[4]}px`}
          >
            Join {MarketingAgencyCount} agents using Snug
          </Text>
        </Box>

        <Text
          fontWeight={theme.fontWeights[3]}
          fontSize={`${theme.fontSizes[4]}px`}
          color={theme.colors.gray500}
          mb={`${theme.space[4]}px`}
        >
          {MarketingAgencyText}
        </Text>
      </RightContainer>
    </ModalContainer>
  )
}

export default AccessReferences
