import React, { useState } from 'react'

import styled from 'styled-components'

import searchIconUrl from 'app/assets/icons/search.svg'
import {
  Box,
  Flex,
  LegacyButton,
} from 'app/components/design-system-components'
import * as systemHelpers from 'app/utils/system/helpers'

const SearchAndFilterContainer = styled(Flex)`
  position: relative;
  margin-top: ${(props) => props.theme.space[7]}px;
  .search-icon {
    position: absolute;
    margin-left: ${(props) => props.theme.space[5]}px;
    margin-top: ${(props) => props.theme.space[3]}px;
    width: 18px;
    height: 18px;
  }
  select {
    width: 140px;
    height: 34px;
    padding: 0 ${(props) => props.theme.space[3]}px;
    color: #4d4d4d;
    font-size: ${(props) => props.theme.fontSizes[4]}px;
    font-weight: bold;
  }
  input[type='text'] {
    min-width: 275px;
    height: 35px;
    padding-left: 40px;
    font-size: ${(props) => props.theme.fontSizes[4]}px;
  }
  .clear-button {
    width: 70px;
    height: 34px;
    padding: ${(props) => props.theme.space[4]}px;
    font-size: ${(props) => props.theme.fontSizes[3]}px;
  }
  input,
  select {
    margin-right: ${(props) => props.theme.space[3]}px;
    border: ${(props) =>
      `${props.theme.borders[1]} ${props.theme.colors.grey200}`};
    border-radius: ${(props) => props.theme.radii[6]}px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    input,
    select {
      margin-bottom: ${(props) => props.theme.space[3]}px;
      width: 100%;
    }
  }
`

const SearchAndFilter = ({
  filterValues,
  inputTextPlaceholder,
  onChangeFilterValues,
  managers,
  onClearFilterValues,
  statusOptions,
  sortOptions,
}) => {
  const [showFilters, setShowFilters] = useState(false)
  const label = showFilters ? 'Hide Filters' : 'Show Filters'
  const displayShowHideLink = systemHelpers.isMobile()
  const shouldShowFilters = showFilters || !displayShowHideLink
  return (
    <SearchAndFilterContainer>
      <div>
        <img src={searchIconUrl} alt="search icon" className="search-icon" />
        <input
          type="text"
          value={filterValues.searchValue}
          placeholder={inputTextPlaceholder}
          onChange={onChangeFilterValues}
          id="searchValue"
        />
      </div>
      {shouldShowFilters && (
        <>
          {managers && managers.length > 0 && (
            <div>
              <select
                value={filterValues.managerValue}
                onChange={onChangeFilterValues}
                id="managerValue"
              >
                <option value="">Manager</option>
                {managers &&
                  managers.map((manager) => (
                    <option value={manager.guid}>{manager.name}</option>
                  ))}
                <option value="unassigned">Unassigned</option>
              </select>
            </div>
          )}
          <div>
            <select
              value={filterValues.statusValue}
              onChange={onChangeFilterValues}
              id="statusValue"
            >
              {statusOptions &&
                statusOptions.map((status) => (
                  <option value={status.value}>{status.name}</option>
                ))}
            </select>
          </div>
          <div>
            <select
              value={filterValues.dateValue}
              onChange={onChangeFilterValues}
              id="dateValue"
            >
              {sortOptions &&
                sortOptions.map((sort) => (
                  <option value={sort.value}>{sort.name}</option>
                ))}
            </select>
          </div>
        </>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        <LegacyButton
          variant="outlineBlue"
          className="clear-button"
          onClick={onClearFilterValues}
        >
          Clear
        </LegacyButton>
        {displayShowHideLink && (
          <Box
            ml="8px"
            onClick={() => setShowFilters(!showFilters)}
            className="blue-link-style"
          >
            {label}
          </Box>
        )}
      </Flex>
    </SearchAndFilterContainer>
  )
}

export default SearchAndFilter
