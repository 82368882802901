import React from 'react'

import { Link } from 'react-router-dom'

import humanIcon2 from 'app/assets/icons/human02.jpg'

function ReportedBy() {
  return (
    <div className="sm-shadow-card reported-by">
      <div className="header xs-mb20">
        <h4>Reported by</h4>
      </div>
      <div className="content">
        <div className="table">
          <div className="cell-t-100">
            <h4 className="mb10">Amanda Richards</h4>
            <a className="block mb10" href="#">
              arichards@gmail.com
            </a>
            <a className="block mb20" href="#">
              +1 31 217 9271
            </a>
            <div>
              <span className="ibm br4 p5 pl10 pr10 fs14 fw600 pink-bg-light-rgba pink-color">
                Primary
              </span>
            </div>
          </div>
          <div className="cell-t">
            <div
              className="avatar avatar-big xs-avatar-small"
              style={{ backgroundImage: `url(${humanIcon2})` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportedBy
