import React from 'react'

import { Link } from 'react-router-dom'

import ResetForm from 'app/session/login_components/reset_form_connection'
import SnugLogo from 'app/shared_components/snug_logo'
import { urlTo } from 'app/sm/helpers'

const Reset = () => (
  <div className="auth-wrapper">
    <div className="auth-screen panel panel-default">
      <div className="panel-heading">
        <div className="app-header">
          <div className="app-header_left"></div>
          <div className="app-header_center">
            <SnugLogo />
          </div>
          <div className="app-header_right">
            <Link to={urlTo('join')}>Join</Link>
          </div>
        </div>
      </div>
      <div className="panel-body">
        <h3 className="mb30">Forgot Account Details</h3>
        <ResetForm />
      </div>
    </div>
  </div>
)

export default Reset
