import React from 'react'

import { Box, Modal } from 'app/components/design-system-components'

function UploadContinueModal({
  toggleUploadContinueModal,
  uploadContinueClicked,
}) {
  const modalBody = (
    <Box>
      You've already requested an disclosure completion from the owner. Are you
      sure you want to continue to upload the disclosures?
    </Box>
  )
  return (
    <Modal
      primaryLabel="Continue"
      secondaryLabel="Cancel"
      modalHeading="Upload Disclosures"
      modalBody={modalBody}
      toggleModal={toggleUploadContinueModal}
      secondaryAction={toggleUploadContinueModal}
      primaryAction={uploadContinueClicked}
    />
  )
}

export default UploadContinueModal
