import React from 'react'

import $ from 'jquery'
import { Route } from 'react-router-dom'

import * as MockData from 'app/sm/chat/chat_mock_data'
import Inbox from 'app/sm/chat/components/inbox'
import NewMessage from 'app/sm/chat/components/new_message'
import Timeline from 'app/sm/chat/components/timeline'

class ChatContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    $('#index').addClass('no-navbar-menu')
  }

  componentWillUnmount() {
    $('#index').removeClass('no-navbar-menu')
  }

  render() {
    let path = this.props.match.path

    return (
      <div>
        <Route
          exact
          path={`${path}`}
          render={({ match }) => {
            return <Inbox data={MockData.Inbox} />
          }}
        />
        <Route
          exact
          path={`${path}/new-message`}
          render={({ match }) => {
            return <NewMessage />
          }}
        />
        <Route
          exact
          path={`${path}/timeline`}
          render={({ match }) => {
            return <Timeline />
          }}
        />
      </div>
    )
  }
}

export default ChatContainer
