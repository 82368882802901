import React from 'react'

import styled from 'styled-components'

import { Button, Flex, Modal } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const StyledModal = styled(Modal)`
  color: red !important;

  .m-footer {
    & button.primary-button {
      background-color: red !important;

      display: none !important;
    }
  }
`

const StyledModalBody = styled.div`
  ol {
    & li {
      margin-bottom: ${theme.space[5]}px;
      list-style-type: decimal;
      display: list-item;
      line-height: 1.3;
    }
  }
`

const ModalBody = () => {
  return (
    <StyledModalBody>
      <ol type="1">
        <li>
          My real estate agent/Snug providing my personal information to Direct
          Connect including name, address, email and phone number.
        </li>
        <li>
          Direct Connect contacting me by any means during my move in relation
          to electricity, gas and other services, which include; phone,
          internet, pay TV, cleaning, removalists, truck hire and water (VIC
          only)
        </li>
        <li>
          Direct Connect obtaining metering information for the premises I am
          moving to.
        </li>
        <li>
          Privacy Collection Statement: Direct Connect Australia Pty Limited
          (DCA) is collecting your personal information for the purposes of
          contacting you in relation to your utilities and services connections.
          DCA will otherwise collect, use and disclose your personal information
          for purposes set out in its Privacy Policy at{' '}
          <a href="https://www.directconnect.com.au/privacypolicy">
            https://www.directconnect.com.au/privacypolicy
          </a>
          . This information may be disclosed to third parties that help DCA
          deliver its services. The Privacy Policy explains how DCA will
          collect, use, store and disclose your personal information, the
          consequences for you if DCA does not collect this information, and the
          way in which you can access and seek correction of your personal
          information or complain about a breach of the Privacy Act. To obtain
          further information, you can contact DCA on 1300 664 715.
        </li>
      </ol>
    </StyledModalBody>
  )
}

const ModalFooter = ({ toggleModal }) => {
  return (
    <Flex>
      <Button variant="solid" onClick={toggleModal} mr="8px" width="180px">
        Close
      </Button>
    </Flex>
  )
}

const DirectConnectTermsModal = ({
  toggleModal,
  showDirectConnectTermsModal,
}) => {
  if (!showDirectConnectTermsModal) return null

  return (
    <StyledModal
      secondaryLabel="Close"
      modalHeading="Direct Connect Data Sharing and Terms"
      modalBody={<ModalBody />}
      customModalFooter={true}
      toggleModal={toggleModal}
      modalFooter={<ModalFooter toggleModal={toggleModal} />}
    />
  )
}

export default DirectConnectTermsModal
