import React from 'react'

import PropTypes from 'prop-types'

import Card from 'app/match/applicationReportPDF/components/Card'
import InfoList from 'app/match/applicationReportPDF/components/InfoList'
import TitleHorizontalLine from 'app/match/applicationReportPDF/components/TitleHorizontalLine'

const propTypes = {
  applicant: PropTypes.object,
  applicant_applicant: PropTypes.object,
}

const ApplicantContainer = ({ targetApplicant }) => {
  const { isPrimary } = targetApplicant
  const titleText = isPrimary ? 'Applicant (Primary)' : 'Applicant (Secondary)'
  const { imageUrl, applicantInfo } = targetApplicant
  return (
    <div className="primary-applicant">
      <Card
        type={'image'}
        avatarUrl={imageUrl}
        size={'small'}
        tag={'div'}
        title={titleText}
        addClass={'card-bottom-bordered'}
      />
      <InfoList data={applicantInfo} />
      <TitleHorizontalLine title={'About'} />
      <p>{targetApplicant.about || 'No information is provided'}</p>
    </div>
  )
}

ApplicantContainer.propTypes = propTypes

export default ApplicantContainer
