import { connect } from 'react-redux'

import { createNote } from 'app/agency/agency_action'
import {
  addViewing,
  addWalkInRenter,
  archiveProperty,
  cancelViewing,
  editViewing,
  fetchPreloadedPropertiesAndViewingsData,
  fetchPropertyViewingsReportData,
  fetchRegistrantsForViewing,
  removeOnsiteViewingRenter,
  resetData,
  updateRenterStatus,
} from 'app/pages/teams/viewings-mobile/action'
import MobileViewings from 'app/pages/teams/viewings-mobile/index'
import { setBackUrl } from 'app/shared_components/actions'
import {
  addCurrentOwners,
  addCurrentTenants,
  clearError,
  fetchAgencyApplicantsElasticSearchResult,
  fetchCurrentOwners,
  fetchCurrentTenants,
  fetchEnquirers,
  fetchPropertyViewings,
  fetchViewingsByDateFromTeamId,
  removeCurrentOwners,
  removeCurrentTenants,
  requestFeedback,
  sendEntryNotice,
  sendIndividualInviteToApply,
  sendInviteToApplyToAllRegistrants,
  showCommentModal,
  showEditViewingModal,
  showLateViewingModal,
  toggleWalkInModal,
  updateCurrentOwners,
  updateCurrentTenants,
  updateRenterStatusComment,
} from 'app/sm/inspections/inspections_actions'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'
import {
  assignViewingToTeamMember,
  deleteViewingTeamMember,
  fetchViewingTeamMembers,
  getManagersListForViewingFilters,
  updateViewingTeamMemberRelation,
} from 'app/sm/viewings/viewings_actions'
import { notifyLateViewing } from 'app/sm/viewings_new_run/action'

const mapStateToProps = ({
  session,
  TeamOverview,
  ViewingsReducer,
  DataPropertiesAndViewings,
  PageViewings,
}) => {
  const preloadedPropertiesAndViewings = {
    allViewings: DataPropertiesAndViewings.allViewings,
    dataPropertiesCurrent: DataPropertiesAndViewings.dataPropertiesCurrent,
    dataPropertiesCurrentListAll:
      DataPropertiesAndViewings.dataPropertiesCurrentListAll,
    allViewingsGUIDs: DataPropertiesAndViewings.allViewingsGUIDs,
    totalRecords: DataPropertiesAndViewings.totalRecords,
    dataOffset: DataPropertiesAndViewings.recordsOffset,
  }
  const viewingsErrors = {
    addViewingsError: PageViewings.addViewingError,
  }
  return {
    teams: session.teams,
    currentTeam: session.currentTeam,
    filters: TeamOverview.filters,
    renterInfo: ViewingsReducer.renterInfo,
    currentUser: session.currentUser,
    preloadedPropertiesAndViewings,
    viewingsByDate: DataPropertiesAndViewings.allViewingsGUIDs,
    showSpinner: PageViewings.showSpinner,
    preloadFetchErrors: PageViewings.preloadFetchErrors,
    viewingsErrors,
    addViewingSuccess: PageViewings.addViewingSuccess,
    dataRegistrantsCurrent: DataPropertiesAndViewings.dataRegistrantsCurrent,
    displayedPropertyOffers:
      DataPropertiesAndViewings.dataPropertiesCurrentListAll,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchPropertyViewings: (params) => dispatch(fetchPropertyViewings(params)),
  toggleWalkInModal: (isWalkInModalOn, viewing) =>
    dispatch(toggleWalkInModal(isWalkInModalOn, viewing)),
  addWalkInRenter: (data, propertyId, viewingId) =>
    dispatch(addWalkInRenter(data, propertyId, viewingId)),
  sendIndividualInviteToApply: (viewingId, registrantGUID) =>
    dispatch(sendIndividualInviteToApply(viewingId, registrantGUID)),
  updateRenterStatus: (payload, renterType, id, registrationGUID, propertyId) =>
    dispatch(
      updateRenterStatus(payload, renterType, id, registrationGUID, propertyId),
    ),
  removeOnsiteViewingRenter: (viewingGUID, registrationGUID) =>
    dispatch(removeOnsiteViewingRenter(viewingGUID, registrationGUID)),
  notifyLateViewing: (data, viewingGUID) =>
    dispatch(notifyLateViewing(data, viewingGUID)),
  clearError: () => dispatch(clearError()),
  sendInviteToApplyToAllRegistrants: (viewingGUID) =>
    dispatch(sendInviteToApplyToAllRegistrants(viewingGUID)),
  cancelViewing: (viewingGUID, propertyId) =>
    dispatch(cancelViewing(viewingGUID, propertyId)),
  editViewing: (
    offerId,
    viewingId,
    startdate,
    duration,
    skipDoubleBookingCheck,
    viewingOptions,
  ) =>
    dispatch(
      editViewing(
        offerId,
        viewingId,
        startdate,
        duration,
        skipDoubleBookingCheck,
        viewingOptions,
      ),
    ),
  showEditViewingModal: (isEditViewModalOn, viewingTime) =>
    dispatch(showEditViewingModal(isEditViewModalOn, viewingTime)),
  showLateViewingModal: (isLateModalOn, viewing) =>
    dispatch(showLateViewingModal(isLateModalOn, viewing)),
  showCommentModal: (isCommentModalOn, renterInfo) =>
    dispatch(showCommentModal(isCommentModalOn, renterInfo)),
  updateRenterStatusComment: (payload) =>
    dispatch(updateRenterStatusComment(payload)),
  requestFeedback: (viewingGUID, success) =>
    dispatch(requestFeedback(viewingGUID, success)),
  fetchEnquirers: (teamId, propertyId) =>
    dispatch(fetchEnquirers(teamId, propertyId)),
  fetchAgencyApplicantsElasticSearchResult: (teamId, queryString) =>
    dispatch(fetchAgencyApplicantsElasticSearchResult(teamId, queryString)),
  fetchCurrentTenants: (teamId, queryString) =>
    dispatch(fetchCurrentTenants(teamId, queryString)),
  addCurrentTenants: (data, propertyId) =>
    dispatch(addCurrentTenants(data, propertyId)),
  updateCurrentTenants: (propertyId, tenantId, data) =>
    dispatch(updateCurrentTenants(propertyId, tenantId, data)),
  removeCurrentTenants: (propertyId, tenantId) =>
    dispatch(removeCurrentTenants(propertyId, tenantId)),
  sendEntryNotice: (offerId, data) => dispatch(sendEntryNotice(offerId, data)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
  createNote: (payload, ownerGUID, ownerType) =>
    dispatch(createNote(payload, ownerGUID, ownerType)),
  assignViewingToTeamMember: (teamGUID, viewingGUID, teamMemberGUID) =>
    dispatch(assignViewingToTeamMember(teamGUID, viewingGUID, teamMemberGUID)),
  fetchViewingTeamMembers: (teamGUID, viewingGUID) =>
    dispatch(fetchViewingTeamMembers(teamGUID, viewingGUID)),
  deleteViewingTeamMember: (teamGUID, viewingGUID, teamMemberGUID) =>
    dispatch(deleteViewingTeamMember(teamGUID, viewingGUID, teamMemberGUID)),
  updateViewingTeamMemberRelation: (
    teamGUID,
    viewingGUID,
    teamMemberGUID,
    requestBody,
  ) =>
    dispatch(
      updateViewingTeamMemberRelation(
        teamGUID,
        viewingGUID,
        teamMemberGUID,
        requestBody,
      ),
    ),
  getManagersListForViewingFilters: (teamGUID) =>
    dispatch(getManagersListForViewingFilters(teamGUID)),
  addViewing: (
    id,
    startdate,
    duration,
    fromScheduler,
    skipDoubleBookingCheck,
    options,
  ) =>
    dispatch(
      addViewing(
        id,
        startdate,
        duration,
        fromScheduler,
        skipDoubleBookingCheck,
        options,
      ),
    ),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
  fetchPropertyViewingsReportData: (viewingGUID) =>
    dispatch(fetchPropertyViewingsReportData(viewingGUID)),
  fetchCurrentOwners: (teamId, queryString) =>
    dispatch(fetchCurrentOwners(teamId, queryString)),
  addCurrentOwners: (data, teamId) => dispatch(addCurrentOwners(data, teamId)),
  updateCurrentOwners: (teamId, ownerId, data) =>
    dispatch(updateCurrentOwners(teamId, ownerId, data)),
  removeCurrentOwners: (teamId, contactId, propertyId) =>
    dispatch(removeCurrentOwners(teamId, contactId, propertyId)),
  archiveProperty: (
    propertyGUID,
    cancelFutureViewings,
    declineUnsuccessfulApplications,
  ) =>
    dispatch(
      archiveProperty(
        propertyGUID,
        cancelFutureViewings,
        declineUnsuccessfulApplications,
      ),
    ),
  fetchViewingsByDateFromTeamId: (teamGUID, filters) =>
    dispatch(fetchViewingsByDateFromTeamId(teamGUID, filters)),
  fetchPreloadedPropertiesAndViewingsData: (teamGUID, options) =>
    dispatch(fetchPreloadedPropertiesAndViewingsData(teamGUID, options)),
  fetchRegistrantsForViewing: (teamGUID, viewingGUID) =>
    dispatch(fetchRegistrantsForViewing(teamGUID, viewingGUID)),
  resetData: () => dispatch(resetData()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileViewings)
