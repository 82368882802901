import React from 'react'

import moment from 'moment'
import qs from 'qs'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import BallotSubmitModal from 'app/components/Ballot/Modals/BallotSubmitModal'
import { Alert, Box } from 'app/components/design-system-components'
import { BallotAlertDocumentUpload } from 'app/components/design-system-components/BallotAlertDocumentUpload'
import * as Display from 'app/components/display/display'
import * as NewForm from 'app/components/forms/forms'
import ProgressBarComponent from 'app/components/progress_bar/connection'
import BasicModal from 'app/modals/basic_modal'
import UpdateCurrentModal from 'app/profile/renter/updateCurrentModal'
import { UPDATE_MODAL_TYPE_EMPLOYMENT } from 'app/profile/renter/updateCurrentModal/helpers'
import { checkIfEmployedForLessThanSpecifiedMonths } from 'app/profile/renter/updateCurrentModal/helpers'
import { updateEmploymentEndDate } from 'app/services/http/updateCurrentData'
import * as snugNotifier from 'app/services/snugNotifier'
import Checkbox from 'app/shared_components/checkbox'
import getParameter from 'app/shared_components/parameter_parser'
import Header from 'app/shared_components/rental_reputation_header_connection'
import { history } from 'app/shared_components/router'
import EmploymentBox from 'app/sm/employment/employment_box'
import {
  aggregateTotalEmploymentDuration,
  isFromApplication,
  urlTo,
} from 'app/sm/helpers'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as textHelpers from 'app/utils/text/helpers'

const requiredEmploymentHistory = 2

const CenterLinkButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`

function validateEmploymentHistory(employers) {
  const { years: employmentYears } = aggregateTotalEmploymentDuration(employers)
  if (employmentYears < requiredEmploymentHistory) {
    return false
  }
  return true
}

function validateCurrentEmploymentHistory(employers = []) {
  return (
    employers?.length >= 1 &&
    employers?.filter((emp) => emp.stillEmployed)?.length > 0
  )
}

class EmploymentHistoryList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      noFurtherEmploymentHistory: false,
      optInEmployed: false,
      optInRetired: false,
      optInOther: false,
      selectEmploymentStatus: [
        {
          value: 'Employed',
          picked: false,
        },
        {
          value: 'Retired',
          picked: false,
        },
        {
          value: 'Other',
          picked: false,
        },
      ],
      showUpdateCurrentHistoryModal: false,
      currentEmployer: {},
      numMonthsMinusSpecifiedMonths: 0,
      hasErrorUpdatingEndDate: false,
      isBallotApplication: false,
      isBallotSubmitting: false,
      isBallotSubmitModalOpen: false,
    }
  }

  componentDidMount() {
    const { fetchEmployers, retrieveReputationPreference, fetchApplication } =
      this.props
    const { selectEmploymentStatus } = this.state
    fetchEmployers()

    retrieveReputationPreference().then(({ preference }) => {
      selectEmploymentStatus[0].picked = preference.optInEmployed
      selectEmploymentStatus[1].picked = preference.optInRetired
      selectEmploymentStatus[2].picked = preference.optInOther
      this.setState({
        noFurtherEmploymentHistory: preference.optOutEmploymentHistory,
        optInEmployed: preference.optInEmployed,
        optInRetired: preference.optInRetired,
        optInOther: preference.optInOther,
        selectEmploymentStatus: selectEmploymentStatus,
      })
    })
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { id: agencyGUID, ballot = false, applicationId } = curQueries || {}
    if (ballot && ballot === 'true') {
      this.setState({
        isBallotApplication: true,
      })
    }
    if (
      agencyGUID &&
      agencyGUID !== '' &&
      applicationId &&
      applicationId !== ''
    ) {
      const applicationGUID = applicationId?.toString()?.split(',')[0]
      fetchApplication(applicationGUID)
    }
  }

  onChangeMultiSelect = (val) => {
    const { selectEmploymentStatus } = this.state
    const targetOption = selectEmploymentStatus.find(
      (option) => option.value === val,
    )
    selectEmploymentStatus.forEach((option) => {
      if (option.value !== val) {
        option.picked = false
      }
    })
    targetOption.picked = !targetOption.picked
    this.setState({
      selectEmploymentStatus,
    })
  }

  onFurtherEmploymentCheckboxChange = () => {
    const { reputationPreference } = this.props
    this.callChangeReputationPreference(
      !reputationPreference.optOutEmploymentHistory,
    )
  }

  onModalSecondaryButtonClicked = () => {
    const { addRentalReputationBackUrl } = this.props
    addRentalReputationBackUrl(
      window.location.pathname + window.location.search,
    )
    this.callChangeReputationPreference(true)
    this.submitBallotModalOrGoToNextPage()
  }

  callChangeEmploymentStatusPreference() {
    const { reputationPreference, changeReputationPreference } = this.props
    const { selectEmploymentStatus } = this.state

    let payload = {
      ...reputationPreference,
    }

    payload.optInEmployed = selectEmploymentStatus[0].picked
    payload.optInRetired = selectEmploymentStatus[1].picked
    payload.optInOther = selectEmploymentStatus[2].picked

    changeReputationPreference(payload).then(({ preference }) => {
      selectEmploymentStatus[0].picked = preference.optInEmployed
      selectEmploymentStatus[1].picked = preference.optInRetired
      selectEmploymentStatus[2].picked = preference.optInOther
      this.setState({
        optInEmployed: preference.optInEmployed,
        optInRetired: preference.optInRetired,
        optInOther: preference.optInOther,
        selectEmploymentStatus: selectEmploymentStatus,
      })
    })
  }

  callChangeReputationPreference = (status) => {
    const { reputationPreference, changeReputationPreference } = this.props
    changeReputationPreference({
      ...reputationPreference,
      optOutEmploymentHistory: status,
    }).then(({ preference }) => {
      this.setState({
        noFurtherEmploymentHistory: preference.optOutEmploymentHistory,
      })
    })
  }

  checkEmploymentHistoryBeforeNext = () => {
    const {
      employers,
      addRentalReputationBackUrl,
      applicationDetails = {},
    } = this.props
    const { noFurtherEmploymentHistory, selectEmploymentStatus } = this.state
    const validHistory = validateEmploymentHistory(employers)

    const { config: applicationConfig = {} } = applicationDetails || {}
    const { applicationsSettingsConfig = {} } = applicationConfig || {}
    const {
      require_current_employment_only: requireCurrentEmploymentOnly = false,
    } = applicationsSettingsConfig || {}

    const isESChecked = selectEmploymentStatus
      .filter((emp) => emp.picked)
      .map(() => {
        return true
      })
    if (isESChecked.length === 0) {
      return snugNotifier.error('Please select your employment status')
    }

    const isESCheckedEmployed = selectEmploymentStatus[0].picked
    if (
      requireCurrentEmploymentOnly &&
      !noFurtherEmploymentHistory &&
      isESCheckedEmployed
    ) {
      const validCurrentHistory = validateCurrentEmploymentHistory(employers)
      if (!validCurrentHistory) {
        return snugNotifier.error('Please add your current employment history')
      }
    }

    if (!validHistory && !noFurtherEmploymentHistory) {
      this.toggleModal()
    } else {
      this.callChangeEmploymentStatusPreference()
      addRentalReputationBackUrl(
        window.location.pathname + window.location.search,
      )

      this.submitBallotModalOrGoToNextPage()
    }
  }

  toggleModal = () => {
    const { modalOpen } = this.state
    this.setState({ modalOpen: !modalOpen })
  }

  checkShowUpdateCurrentHistory = () => {
    const { employers } = this.props
    const { hasErrorUpdatingEndDate } = this.state
    const {
      isLessThanSpecifiedMonths,
      numMonthsMinusSpecifiedMonths,
      currentEmployer,
    } = checkIfEmployedForLessThanSpecifiedMonths(employers)
    this.setState({
      showUpdateCurrentHistoryModal: isLessThanSpecifiedMonths,
      currentEmployer,
      numMonthsMinusSpecifiedMonths: numMonthsMinusSpecifiedMonths,
    })
    if (!isLessThanSpecifiedMonths && !hasErrorUpdatingEndDate) {
      this.checkEmploymentHistoryBeforeNext()
    }
  }

  toggleUpdateCurrentModal = () => {
    const { showUpdateCurrentHistoryModal } = this.state
    this.setState({
      showUpdateCurrentHistoryModal: !showUpdateCurrentHistoryModal,
    })
  }

  continueActionInUpdateCurrentModal = (data) => {
    const { fetchEmployers } = this.props

    const { currentEmployer } = this.state
    const { guidID = '' } = currentEmployer
    const { needsToUpdateAndCallEndpoint, newEndDate, needsToGoToAddPage } =
      data
    const { value: newEndDateValue = '' } = newEndDate
    const payload = {
      employmentGUID: guidID,
      endDate: moment(newEndDateValue, dateTimeHelpers.DATE_WITH_DASH).format(
        dateTimeHelpers.DEFAULT_UNIX_TIME_Z,
      ),
    }
    let hasError = false
    if (needsToUpdateAndCallEndpoint) {
      updateEmploymentEndDate(payload)
        .then(() => {
          snugNotifier.success('End date for employment successfully updated')
          if (needsToGoToAddPage) {
            history.push(urlTo('EmploymentDetails'))
            return
          }
          fetchEmployers()
          this.setState({
            hasErrorUpdatingEndDate: false,
          })
        })
        .catch((error) => {
          snugNotifier.error(error.message)
          hasError = true
          this.setState({
            hasErrorUpdatingEndDate: true,
          })
        })
        .finally(() => {
          if (!hasError) {
            this.toggleUpdateCurrentModal()
            if (!needsToGoToAddPage) {
              this.checkEmploymentHistoryBeforeNext()
            }
          }
        })
    } else {
      this.checkEmploymentHistoryBeforeNext()
    }
  }

  toggleBallotSubmitModal = () => {
    const { isBallotSubmitModalOpen } = this.state
    this.setState({
      isBallotSubmitModalOpen: !isBallotSubmitModalOpen,
    })
  }

  submitBallotApplication = () => {
    const { submitFinalApplication, acceptJointApplicationAsSecondary } =
      this.props
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const {
      applicationId = '',
      fromSecondary = false,
      applicantGUID = '',
    } = curQueries
    let finalisedApplicationID = ''
    if (applicationId && Array.isArray(applicationId)) {
      finalisedApplicationID = applicationId[0]
    } else {
      finalisedApplicationID = applicationId
    }
    this.setState({
      isBallotSubmitting: true,
    })
    if (!fromSecondary) {
      submitFinalApplication(finalisedApplicationID, {
        isPrimary: true,
        optedToAcceptCondition: true,
        propertyInspectionType: 2,
        optedToAcceptDisclosureConsent: true,
        isBallotEntry: true,
      })
        .then(() => {
          history.push(
            `${urlTo('applyConfirmationSummaryBallot', {
              applicationId: finalisedApplicationID,
            })}?${new URLSearchParams(curQueries).toString()}`,
          )
        })
        .catch((error) => {
          snugNotifier.error('Error submitting ballot')
        })
        .finally(() => {
          this.setState({
            isBallotSubmitting: false,
          })
        })
    }
    if (fromSecondary) {
      acceptJointApplicationAsSecondary(
        finalisedApplicationID,
        applicantGUID,
        {
          applicantSummary: {},
          applicantDisclosure: {
            isPrimary: false,
            optedToAcceptCondition: true,
            propertyInspectionType: 2,
            optedToAcceptDisclosureConsent: true,
            isBallotEntry: true,
          },
        },
        true,
        {},
      )
        .then(() => {
          history.push(
            `${urlTo('applyConfirmationSummaryBallot', {
              applicationId: finalisedApplicationID,
            })}?${new URLSearchParams(curQueries).toString()}`,
          )
        })
        .catch((error) => {
          snugNotifier.error('Error submitting ballot')
        })
        .finally(() => {
          this.setState({
            isBallotSubmitting: false,
          })
        })
    }
  }

  submitBallotModalOrGoToNextPage = () => {
    const { isBallotApplication } = this.state

    if (isBallotApplication) {
      this.setState({
        isBallotSubmitModalOpen: true,
      })
    } else {
      history.push(`${urlTo('ProofOfIncome')}${window.location.search}`)
    }
  }

  render() {
    const {
      employers = [],
      backUrl,
      property,
      removeEmployer,
      completeness,
      applicationDetails,
    } = this.props
    const {
      showUpdateCurrentHistoryModal,
      currentEmployer,
      numMonthsMinusSpecifiedMonths,
      isBallotApplication,
      isBallotSubmitModalOpen,
      isBallotSubmitting,
    } = this.state
    const propertyId = getParameter('propertyId')
    const applicationId = getParameter('applicationId')
    const isFromApplicationForm =
      isFromApplication(backUrl) || propertyId || applicationId
    const { modalOpen, noFurtherEmploymentHistory } = this.state

    const addMoreHistoryModalBody =
      'Typically, property managers require 2 years of employment history.'

    const { config: applicationConfig = {}, offer = {} } =
      applicationDetails || {}
    const { property: propertyInApp = {} } = offer || {}
    const { teamName = '' } = propertyInApp || {}
    const { applicationsSettingsConfig = {} } = applicationConfig || {}
    const {
      require_current_employment_only: requireCurrentEmploymentOnly = false,
    } = applicationsSettingsConfig || {}

    return (
      <Display.CenterContentContainer componentClass="width100">
        <ProgressBarComponent
          completeness={completeness}
          clickNextFunctionFromParentComponent={
            this.checkEmploymentHistoryBeforeNext
          }
        />
        <RentalReputationHeaderReminder
          property={property}
          backUrl={backUrl}
          remindText={'Current application'}
          isFromApplicationForm={isFromApplicationForm}
          title="Employment"
        />

        {requireCurrentEmploymentOnly && (
          <Alert
            showLeftIcon={true}
            leftIconVariant="info"
            variant="blueWithBg"
            mt={3}
            mb={5}
          >
            {teamName} requires current employment only.
          </Alert>
        )}

        <Header
          title="Employment History"
          message={
            <>
              {isBallotApplication && <BallotAlertDocumentUpload />}
              <Box mt={5}>{textHelpers.EMPLOYMENT_HISTORY_HEADER_GUIDANCE}</Box>
            </>
          }
        />

        <div className="mt25 mr10 width100">
          <div className="mb20">
            <h4>Employment status</h4>
          </div>
          <NewForm.MultiSelect
            options={this.state.selectEmploymentStatus}
            className="p0"
            onChange={this.onChangeMultiSelect}
          />
        </div>

        <div className="table mb20 height60">
          <div className="cell-m-100">
            <h4>Employment History</h4>
          </div>
          {!noFurtherEmploymentHistory && (
            <div className="cell-m">
              <CenterLinkButton
                className="btn btn-round"
                to={`${urlTo('EmploymentDetails')}${window.location.search}`}
                title="Add employment history"
              >
                <i className="icon-add"></i>
              </CenterLinkButton>
            </div>
          )}
        </div>
        <Box mb={8}>
          <Checkbox
            label="No further employment history"
            value={noFurtherEmploymentHistory}
            onChange={this.onFurtherEmploymentCheckboxChange}
          />
        </Box>
        <div className="mb50">
          {employers.length > 0 &&
            employers.map((E, i) => (
              <EmploymentBox
                employer={E}
                key={E.guidID}
                removeEmployer={removeEmployer}
                isContactDetailsUpdated={true}
              />
            ))}
        </div>
        <Display.BottomContentContainer>
          <Display.CenterContentContainer componentClass="width100">
            <div className="submit-button-container">
              <BottomButton
                clickNextFunction={() => {
                  this.checkShowUpdateCurrentHistory()
                }}
                btnText={
                  isFromApplicationForm ? 'Save & Continue' : 'Next: Income'
                }
                bottomButtonsClass="pt0 mt0 summary-bottom-button"
                nextBtnClass="summary-green-button-class"
                backButtonClass="summary-grey-button-class"
                nextButtonPositionClass="pr20 pl0"
                showArrow={false}
              />
            </div>
          </Display.CenterContentContainer>
        </Display.BottomContentContainer>
        {modalOpen && (
          <BasicModal
            toggleModal={this.toggleModal}
            title={'Add More Employment History'}
            body={addMoreHistoryModalBody}
            primaryButtonLabel={'Add History'}
            primaryButtonAction={() => history.push(urlTo('EmploymentDetails'))}
            secondaryButtonLabel={'No further history'}
            secondaryButtonAction={this.onModalSecondaryButtonClicked}
          />
        )}
        {showUpdateCurrentHistoryModal && (
          <UpdateCurrentModal
            modalType={UPDATE_MODAL_TYPE_EMPLOYMENT}
            currentTextToAppend={
              currentEmployer && currentEmployer.employerName
            }
            timeFromNow={numMonthsMinusSpecifiedMonths}
            toggleModal={this.toggleUpdateCurrentModal}
            continueAction={this.continueActionInUpdateCurrentModal}
          />
        )}
        {isBallotSubmitModalOpen && (
          <BallotSubmitModal
            toggleModal={this.toggleBallotSubmitModal}
            primaryAction={this.submitBallotApplication}
            submitLoading={isBallotSubmitting}
          />
        )}
      </Display.CenterContentContainer>
    )
  }
}

export default EmploymentHistoryList
