import { connect } from 'react-redux'

import {
  activatePMSIntegration,
  adminSearchAgencies,
  clearError,
  deactivatePMSIntegration,
  fetchAgencyPaymentGateways,
  fetchAllPropertytreeTokens,
  fetchPropertytreeTokenForAgency,
  refreshtreeTokenForAgency,
  register,
} from 'app/bond_cover/agency/agency_actions'
import RegisterAgencyFormContainer from 'app/bond_cover/agency/register_agency_form_container'

const mapStateToProps = ({ agency }) => ({
  error: agency.error,
  agency,
})

const mapDispatchToProps = (dispatch) => ({
  register: (user) => dispatch(register(user)),
  clearError: () => dispatch(clearError()),
  searchAgencies: (agencyName, pageSize, searchSuccess) =>
    dispatch(adminSearchAgencies(agencyName, pageSize, searchSuccess)),
  fetchAgencyPaymentGateways: (agencyGUID) =>
    dispatch(fetchAgencyPaymentGateways(agencyGUID)),
  fetchPropertytreeTokenForAgency: (agencyGUID) =>
    dispatch(fetchPropertytreeTokenForAgency(agencyGUID)),
  fetchAllPropertytreeTokens: () => dispatch(fetchAllPropertytreeTokens()),
  refreshtreeTokenForAgency: (agencyGUID) =>
    dispatch(refreshtreeTokenForAgency(agencyGUID)),
  activatePMSIntegration: (agencyGUID, tokenAndAgency, pms) =>
    dispatch(activatePMSIntegration(agencyGUID, tokenAndAgency, pms)),
  deactivatePMSIntegration: (agencyGUID, pms) =>
    dispatch(deactivatePMSIntegration(agencyGUID, pms)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterAgencyFormContainer)
