import * as React from 'react'

function SvgWebRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2l.01-12c0-1.1.89-2 1.99-2zm10.5 5H4v3.5h10.5V9zM4 14.5h10.5V18H5c-.55 0-1-.45-1-1v-2.5zM16.5 18H19c.55 0 1-.45 1-1V9h-3.5v9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgWebRounded
