export const newMessagesModes = {
  bulky: 'bulky',
  identifiedMessages: 'identifiedMessages',
}

export const newBulkyConfig = (filters, messagesCount) => {
  return {
    mode: newMessagesModes.bulky,
    filters,
    messagesCount,
  }
}

export const newIdentifiedMessagesConfig = (messages) => {
  return {
    mode: newMessagesModes.identifiedMessages,
    messages,
  }
}
