import React from 'react'

import { ButtonPair } from 'app/components/design-system-components/ButtonPair'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'

const FormActions = ({ isSubmitting, submitHandler }) => {
  const previousUrl = urlTo('BCProfile')
  const cancelFn = () => history.push(previousUrl)

  return (
    <ButtonPair
      primaryFn={submitHandler}
      isSubmitting={isSubmitting}
      primaryLabel="Update"
      secondaryLabel="Cancel"
      secondaryFn={cancelFn}
    />
  )
}

export default FormActions
