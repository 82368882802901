import React, { useState } from 'react'

import { appURL } from 'config/env'
import QRCode from 'qrcode.react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import * as Form from 'app/components/forms/forms'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { DesktopOnly } from 'app/shared_components/shared_styled_component'

const IMAGE_WIDTH_HEIGHT = 400
const DEST_X_Y_0 = 0

const Wrapper = styled.div`
  color: #212025;
  line-height: 24px;
  p {
    margin: 0;
    word-break: break-word;
  }

  @media print {
    max-width: 700px;
    margin: 0 auto;
  }
`

const SemiWrapper = styled.div`
  @media print {
    max-width: 680px;
    margin: 0 auto;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;

  h1 {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    margin: 0 0 8px 0;
  }

  p {
    font-size: 14px;
  }

  @media print {
    justify-content: center;
    margin-bottom: 16px;

    h1 {
      font-weight: 700;
      margin-top: 90px;
      font-size: 48px;
      line-height: 40px;
    }

    p {
      font-size: 16px;
    }
  }
`

const QRCodeWrapper = styled.div`
  margin: 46px 26px;
  text-align: center;

  > svg {
    display: block;
    height: auto;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`
const triggerDownload = (imgURI) => {
  const mouseClickEvent = new MouseEvent('click', {
    view: window,
    bubbles: false,
    cancelable: true,
  })

  let QRHyperlink = document.createElement('a')
  QRHyperlink.setAttribute('download', 'qrcode.png')
  QRHyperlink.setAttribute('href', imgURI)
  QRHyperlink.setAttribute('target', '_blank')

  QRHyperlink.dispatchEvent(mouseClickEvent)
}

const downloadQRApplyID = 'qr-svg-apply'
const downloadQRCheckinID = 'qr-svg-checkin'

const downloadQR = (downloadQRID) => {
  // LOGIC for downloading svg as png
  // 1. svg to canvas
  // 2. canvas to dataUrl
  // 3. trigger download from dataUrl

  const renderedQR = document.getElementById(downloadQRID)
  let canvas = document.getElementById('qr-canvas')
  let context2D = canvas.getContext('2d')
  const data = new XMLSerializer().serializeToString(renderedQR)
  let DOMURL = window.URL || window.webkitURL || window

  let QRImg = new Image()
  const svgBlob = new Blob([data], { type: 'image/svg+xml' })
  const url = DOMURL.createObjectURL(svgBlob)

  QRImg.onload = () => {
    canvas.width = IMAGE_WIDTH_HEIGHT
    canvas.height = IMAGE_WIDTH_HEIGHT
    context2D.drawImage(
      QRImg,
      DEST_X_Y_0,
      DEST_X_Y_0,
      IMAGE_WIDTH_HEIGHT,
      IMAGE_WIDTH_HEIGHT,
    )
    DOMURL.revokeObjectURL(url)

    let imgURI = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')

    triggerDownload(imgURI)
  }

  QRImg.src = url
}

function QRCheckin({
  teamslug,
  agencyProfile,
  updateQRCheckinEnabled,
  viewingQRCheckinEnabled = true,
  isActionDisabled,
  viewingQRAutoCheckinEnabled,
  updateViewingQRAutoCheckinEnabled,
}) {
  const { brandingBannerURL = '', agencyName = '' } = agencyProfile || {}

  const QR_CHECKIN_URL = `${appURL}/apply/${teamslug}/checkin`
  const QR_APPLY_URL = `${appURL}/apply/${teamslug}/`

  const [downloadQRCodeType, setDownloadQRCodeType] =
    useState(downloadQRApplyID)

  return (
    <Wrapper className="">
      <Box className="pdf-show-block" mt={9}>
        {brandingBannerURL && (
          <Flex justifyContent="center">
            <img src={brandingBannerURL} alt="Agency Banner" />
          </Flex>
        )}
        {!brandingBannerURL && (
          <Flex justifyContent="center">
            <h1>{agencyName}</h1>
          </Flex>
        )}
      </Box>
      <SemiWrapper>
        <TitleWrapper>
          <Box>
            <h1 className="pdf-hide">QR Codes for Team</h1>
            <Box className="pdf-show" mt={theme.baseSpace * 5 + 'px'}>
              <h1>Scan to inspect and apply</h1>
            </Box>
            <p className="pdf-hide">
              Snug has two types of QR Codes for teams detailed below. Also see
              the Onlist for a property viewing time specific QR for
              auto-check-ins.
            </p>
          </Box>
        </TitleWrapper>
        <Flex justifyContent="space-between" mt={7}>
          <h2 className="pdf-hide">QR Apply Link</h2>

          <DesktopOnly>
            <Flex justifyContent="center" alignItems="center">
              <button
                id="qr-svg-download-link"
                className="hollow-button-green pdf-hide mr10"
                onClick={() => downloadQR(downloadQRApplyID)}
              >
                Download
              </button>
              <button
                className="pdf-hide "
                onClick={() => {
                  setDownloadQRCodeType(downloadQRApplyID)
                  setTimeout(() => {
                    window.print()
                  }, 500)
                }}
              >
                Print
              </button>
              <canvas className="display-none" id="qr-canvas"></canvas>
            </Flex>
          </DesktopOnly>
        </Flex>
        <Box mt={4}>
          <Box className="pdf-hide">
            This QR code links to your Apply Link and can be used in portal
            image carousels to encourage inspections and applications.
          </Box>
          <QRCodeWrapper
            className={downloadQRCodeType !== downloadQRApplyID && 'pdf-hide'}
          >
            <QRCode
              id={downloadQRApplyID}
              renderAs="svg"
              value={QR_APPLY_URL}
            />
          </QRCodeWrapper>
        </Box>

        <Flex justifyContent="space-between" mt={7}>
          <h2 className="pdf-hide">QR Check-in</h2>

          <DesktopOnly>
            <Flex justifyContent="center" alignItems="center">
              <button
                id="qr-svg-download-link"
                className="hollow-button-green pdf-hide mr10"
                onClick={() => downloadQR(downloadQRCheckinID)}
              >
                Download
              </button>
              <button
                className="pdf-hide "
                onClick={() => {
                  setDownloadQRCodeType(downloadQRCheckinID)
                  setTimeout(() => {
                    window.print()
                  }, 500)
                }}
              >
                Print
              </button>
              <canvas className="display-none" id="qr-canvas"></canvas>
            </Flex>
          </DesktopOnly>
        </Flex>
        <Box mt={4}>
          <Box className="pdf-hide">
            This QR code can be used at the property, it shows viewings for
            today and enables Renters to register for the viewing. The inspector
            can quickly Check-In attendees at busy viewings.
          </Box>

          <QRCodeWrapper
            className={downloadQRCodeType !== downloadQRCheckinID && 'pdf-hide'}
          >
            <QRCode
              id={downloadQRCheckinID}
              renderAs="svg"
              value={QR_CHECKIN_URL}
            />
          </QRCodeWrapper>
          <Box className="pdf-hide">
            <Text as="h4" fontSize={theme.fontSizes[8] + 'px'} mb={5} mt={8}>
              Free Forever Plan
            </Text>
            <Form.CheckBoxGeneral
              label="Show viewings starting in the next 60 minutes"
              eventProcessedByComponent={true}
              checked={viewingQRCheckinEnabled}
              onChange={updateQRCheckinEnabled}
            />
          </Box>
        </Box>
        <Box mt={4} className="pdf-hide">
          <Text as="h4" fontSize={theme.fontSizes[8] + 'px'} mb={5} mt={8}>
            Growth Plan
          </Text>

          <div>
            <ReactTooltip id="enablePauseViewingsTooltip">
              <span>
                Show QR code on mobile app to show to attendees for self
                registration
              </span>
            </ReactTooltip>
          </div>
          <div className="mt20 wa pt0">
            <Form.CheckBoxGeneral
              id="viewingQRAutoCheckinEnabled"
              label="Display QR check-in on mobile attendee screen"
              checked={viewingQRAutoCheckinEnabled}
              onChange={updateViewingQRAutoCheckinEnabled}
              toolTip={
                <div>
                  <i
                    className="icon-help-outline fs16 gray-light-color ibm"
                    data-tooltip-content=""
                    data-tooltip-id="enablePauseViewingsTooltip"
                  />
                </div>
              }
              componentClassName="mt10"
              eventProcessedByComponent
              disabled={isActionDisabled}
              showDisabledGrey={isActionDisabled}
            />
          </div>
        </Box>
      </SemiWrapper>
    </Wrapper>
  )
}

export default QRCheckin
