import React from 'react'

import styled from 'styled-components'

import eyeIcon from 'app/assets/icons/eye-icon.svg'

const LabelContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider {
    background-color: rgb(225, 232, 242);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  input[type='checkbox']:checked + span {
    color: #22212a;
    border: 1px solid #3774c3;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  input[type='checkbox'] + span {
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    color: #686d71;
    height: unset;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
    padding-left: 14px;
    padding-right: 7px;
  }
`

const SliderContent = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-image: url('${eyeIcon}');
    background-color: #3774c3;
    background-repeat: no-repeat;
    background-position: center center;
  }
`

const SliderButton = ({ enabled = false, onCheckboxChange }) => {
  return (
    <LabelContainer className="switch">
      <input
        type="checkbox"
        value={enabled}
        onChange={() => onCheckboxChange()}
      />
      <SliderContent className="slider round" />
    </LabelContainer>
  )
}

export default SliderButton
