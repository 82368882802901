import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Box, TextSpan } from 'app/components/design-system-components'
import { statusesToCheckOut } from 'app/constants/key-logger.constants'
import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import { CheckoutKeySetModal } from 'app/features/teams/key-logger/CheckoutKeySetModal'
import theme from 'app/match/applicationReportPDF/assets/theme'
import ViewingRunItem from 'app/pages/teams/viewings-run/components/viewing-run-item'
import * as snugNotifier from 'app/services/snugNotifier'
import isEmptyObject from 'app/shared_components/check_empty_object'
import NotFound404 from 'app/shared_components/not_found_404/not_found_404'
import { history } from 'app/shared_components/router'
import Spinner from 'app/sm/common/spinner'
import { findChosenTeamBySlug, urlIds, urlTo } from 'app/sm/helpers'

const AllViewingRuns = ({
  deleteManagerState,
  teams,
  updateViewingRunReadOnly,
  match,
  fetchTeamSettingInfo,
  fetchAllNewViewingRunVersion,
  fetchNewViewingRunVersion,
  fetchTeamManagerList,
  fetchTeams,
  changeTeam,
  updatelNewViewingRunManagerKeyCheckOut,
}) => {
  const [allViewingRuns, setAllViewingRuns] = useState([])
  const [managerNameList, setManagerNameList] = useState([])
  const [managerList, setManagerList] = useState([])

  const [viewingRunEnabledForTeam, setViewingRunEnabledForTeam] =
    useState(false)
  const [teamSettingFetchDone, setTeamSettingFetchDone] = useState(false)

  const [checkOutModal, setCheckOutModal] = useState({
    isOpened: false,
    config: {},
  })

  const anyViewingRunsPresent = useMemo(
    () => allViewingRuns && allViewingRuns.length > 0,
    [allViewingRuns],
  )

  const teamSlug = useMemo(() => match.params.teamSlug, [match])

  const onClickViewingRun = (runGUID, isDraft = false) => {
    if (!isDraft) {
      updateViewingRunReadOnly(true)
    } else {
      updateViewingRunReadOnly(false)
    }
    const url = isDraft
      ? urlTo(urlIds.viewingRuns.edit, { teamSlug, id: runGUID })
      : urlTo('newViewingsRunPreviewWithGuid', {
          teamSlug,
          viewingRunGUID: runGUID,
        })
    history.push(url)
  }

  const getChosenTeam = useCallback(() => {
    return findChosenTeamBySlug(teams, teamSlug) || {}
  }, [teamSlug, teams])

  const callFetchViewingRuns = useCallback(
    (guid) => {
      fetchAllNewViewingRunVersion(guid).then(({ viewingRun }) =>
        setAllViewingRuns(viewingRun),
      )
    },
    [fetchAllNewViewingRunVersion],
  )

  const postManagerCheckOutKeyViewingRuns = useCallback(
    (guid, viewingGUID, managerGUID) => {
      updatelNewViewingRunManagerKeyCheckOut(
        guid,
        viewingGUID,
        managerGUID,
      ).then(() => {
        callFetchViewingRuns(guid)
      })
    },
    [callFetchViewingRuns, updatelNewViewingRunManagerKeyCheckOut],
  )

  const fetchIfViewingRunIsEnabledForTeam = useCallback(() => {
    const { guid } = getChosenTeam()
    if (guid) {
      fetchTeamSettingInfo(guid, 'viewings')
        .then(({ viewings }) => {
          if (!isEmptyObject(viewings)) {
            const { viewing_run_enabled: viewingRunEnabled = false } = viewings
            setViewingRunEnabledForTeam(viewingRunEnabled === 'true')
          }
        })
        .finally(() => setTeamSettingFetchDone(true))
    }
  }, [fetchTeamSettingInfo, getChosenTeam])

  const generateCrumbs = () => {
    const curTeam = teams.find((team) => team.slug === teamSlug) || {}
    let crumbs = [
      {
        text: curTeam.name,
        link: urlTo('teamOverview', { teamSlug }),
      },
      {
        text: 'Viewings',
        link: `${urlTo('prospectSummary', { teamSlug })}?stage=Viewing`,
      },
      {
        text: 'Run',
        link: '#',
      },
    ]
    return crumbs
  }

  const loadManagerList = useCallback(
    (agencyGUID) => {
      agencyGUID &&
        fetchTeamManagerList(agencyGUID).then(({ managerList }) => {
          const managerNameList = managerList.map(
            (manager) =>
              manager.profile.firstName + ' ' + manager.profile.lastName,
          )
          setManagerList(managerList)
          setManagerNameList(managerNameList)
        })
    },
    [fetchTeamManagerList],
  )

  const checkOutModalElem = checkOutModal.isOpened && (
    <CheckoutKeySetModal
      keySet={checkOutModal.config.keySet}
      bulkKeySets={checkOutModal.config.bulkKeySets}
      bulkCheckoutCommentCmp={checkOutModal.config.bulkCheckoutCommentCmp}
      bulkCheckoutSelectedCmp={checkOutModal.config.bulkCheckoutSelectedCmp}
      closeModal={checkOutModal.config.closeModal}
      borrower={checkOutModal.config.borrower}
      isBorrowerDisabled={checkOutModal.config.isBorrowerDisabled}
      afterCheckOutAction={() => {
        const { guid } = getChosenTeam()
        postManagerCheckOutKeyViewingRuns(
          guid,
          checkOutModal.config.viewingRunGUID,
          checkOutModal.config.managerGUID,
        )
      }}
    />
  )

  const onCloseCheckOutModal = (resultant) => {
    setCheckOutModal({ isOpened: false, config: {} })

    if (resultant) {
      const isCheckedOutManyKeys =
        Array.isArray(resultant) && resultant.length > 1
      const keyLabel = isCheckedOutManyKeys
        ? 'Selected keys'
        : resultant[0]?.label || resultant.label || 'Key set'
      snugNotifier.success(`${keyLabel} checked out successfully`)
    }
  }

  const onClickKeyCheckOut = (viewingRunGUID, managerGUID, manager) => {
    const { guid } = getChosenTeam()
    fetchNewViewingRunVersion(guid, viewingRunGUID)
      .then(({ viewingRun }) => {
        const { runKeySets = [] } = viewingRun || {}
        const propertyKeySets =
          runKeySets.find(
            ({ managerGUID: propertyManagerGUID }) =>
              propertyManagerGUID === managerGUID,
          )?.propertyKeySets || []

        setCheckOutModal({
          isOpened: true,
          config: {
            keySet: null,
            bulkKeySets: getEligibleSelectedKeysIds(propertyKeySets),
            bulkCheckoutCommentCmp:
              renderBulkCheckoutCommentCmp(propertyKeySets),
            bulkCheckoutSelectedCmp:
              renderBulkCheckoutSelectedCmp(propertyKeySets),
            closeModal: (resultant) => onCloseCheckOutModal(resultant),
            viewingRunGUID,
            managerGUID,
            borrower: manager,
            isBorrowerDisabled: true,
          },
        })
      })
      .catch((err) => {
        snugNotifier.error(err)
      })
  }

  const renderBulkCheckoutSelectedCmp = (propertyKeySets) => {
    return (
      <>
        {propertyKeySets
          .filter(({ status }) => statusesToCheckOut.includes(status))
          .map(
            ({
              guid,
              property: selectedProperty,
              label: selectedKeyLabel = '',
            }) => {
              const { address: selectedAddress } = selectedProperty || {}
              const {
                friendlyName: selectedFriendlyName = '',
                suburb: selectedSuburb = '',
              } = selectedAddress || {}

              return (
                <TextSpan
                  as="div"
                  mt={1}
                  fontSize={theme.fontSizes.pSmall12}
                  lineHeight={theme.lineHeights.encrption}
                >
                  {selectedKeyLabel} - {selectedFriendlyName}, {selectedSuburb}
                </TextSpan>
              )
            },
          )}
      </>
    )
  }

  const getInEligibleSelectedKeys = (propertyKeySets) => {
    return propertyKeySets.filter(
      ({ status }) => !statusesToCheckOut.includes(status),
    )
  }

  const getEligibleSelectedKeysIds = (propertyKeySets) => {
    return propertyKeySets
      .filter(({ status }) => statusesToCheckOut.includes(status))
      .map(({ guid }) => guid)
  }

  const renderBulkCheckoutCommentCmp = (propertyKeySets) => {
    const inEligibleKeys = getInEligibleSelectedKeys(propertyKeySets)
    if (!inEligibleKeys.length) return
    const addressList = inEligibleKeys.map((keySet) => {
      const address = keySet.property.address
      if (!address.friendlyName.includes(',')) {
        return `${address.friendlyName}, ${address.suburb}`
      }
      return address.friendlyName
    })
    return (
      <Box>
        <TextSpan>{addressList.length} key sets are unavailable:</TextSpan>
        {addressList.map((add) => (
          <TextSpan
            as="div"
            mt={1}
            fontSize={theme.fontSizes.pSmall12}
            lineHeight={theme.lineHeights.encrption}
          >
            <li>{add}</li>
          </TextSpan>
        ))}
      </Box>
    )
  }

  useEffect(() => {
    deleteManagerState()
  }, [deleteManagerState])

  useEffect(() => {
    const { guid } = getChosenTeam()
    guid && loadManagerList(guid)
    guid && callFetchViewingRuns(guid)
    guid && fetchIfViewingRunIsEnabledForTeam()
  }, [
    callFetchViewingRuns,
    fetchIfViewingRunIsEnabledForTeam,
    getChosenTeam,
    loadManagerList,
    teamSlug,
    teams,
  ])

  useEffect(() => {
    loadTeams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadTeams = () => {
    fetchTeams().then((teams) => {
      const { teamSlug } = match.params
      const currentTeam = teams.find(({ slug }) => slug === teamSlug)
      if (!currentTeam) history.push(urlTo(urlIds.notFound))
      changeTeam(currentTeam)
    })
  }

  return teamSettingFetchDone ? (
    viewingRunEnabledForTeam ? (
      <div>
        <Breadcrumbs crumbs={generateCrumbs()} />
        <div className="preference-header-container">
          <div className="viewing-run-dashboard-header">
            <h4 className="header">{'Viewing runs'}</h4>
            {anyViewingRunsPresent && (
              <button
                className="btn wa"
                onClick={() => {
                  history.push(urlTo('newViewingsRunCreate', { teamSlug }))
                }}
              >
                Create Run
              </button>
            )}
          </div>
          <p className="title">
            {
              'Create a run to optimise your viewing schedule and manage your day.'
            }
          </p>
        </div>
        {checkOutModalElem}
        {anyViewingRunsPresent ? (
          allViewingRuns.map((viewingRun) => {
            return (
              <ViewingRunItem
                key={viewingRun.id}
                managerGUIDS={viewingRun.original_request.inspectors.ids}
                managerList={managerList}
                checkedOutManagers={viewingRun.checkedOutMangerIds || []}
                originalRequest={viewingRun.original_request}
                managerNameList={managerNameList}
                onClickRow={(draft) => onClickViewingRun(viewingRun.id, draft)}
                onClickKeyCheckOut={(managerId, manager) => {
                  onClickKeyCheckOut(viewingRun.id, managerId, manager)
                }}
                registrantsCount={viewingRun.meta.registrants_count}
                runCreatedAt={viewingRun.created_at}
                runCumulativeDuration={viewingRun.cumulative_duration.formatted}
                currentTeam={getChosenTeam()}
              >
                {viewingRun.original_request.date}
              </ViewingRunItem>
            )
          })
        ) : (
          <div className="empty-viewing-run">
            <p className="title">
              {"It looks like you haven't added a viewing run yet"}
            </p>
            <button
              className="btn wa"
              onClick={() =>
                history.push(urlTo('newViewingsRunCreate', { teamSlug }))
              }
            >
              Create Run
            </button>
          </div>
        )}
      </div>
    ) : (
      <NotFound404 />
    )
  ) : (
    <Spinner />
  )
}

export default AllViewingRuns
