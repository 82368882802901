import React from 'react'

import { Link } from 'react-router-dom'

import headerImg07 from 'app/assets/icons/header-img-07.png'
import { urlTo } from 'app/sm/helpers'

class PostViewingResults extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { currentUser } = this.props
    const isLoggedIn = !!currentUser.email

    return (
      <div className="sm-property advertise confirmation">
        <div className="row">
          <div className="col-sm-12 text-center pb30">
            <img src={headerImg07} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 text-center">
            <h3>Thanks for your feedback</h3>
            <Link to={isLoggedIn ? urlTo('homeoverview') : urlTo('register')}>
              <button className="btn mt30 wa">
                {isLoggedIn ? 'Apply' : 'Register for Snug'}
              </button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default PostViewingResults
