import React, { useEffect, useState } from 'react'

import moment from 'moment'

import { Box, Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import { YesOrNoRadioGroup } from 'app/components/forms/forms'
import { YesOrNoCheckboxComponent } from 'app/components/forms/radio_group/component'
import { getShareApplicationDetails } from 'app/services/http/shareApplication'
import * as snugNotifier from 'app/services/snugNotifier'
import { ErrorMessage } from 'app/shared_components/helpers'
import NotFound404 from 'app/shared_components/not_found_404/not_found_404'
import { history } from 'app/shared_components/router'
import * as validations from 'app/shared_components/validations'
import { validateEmail, validateName } from 'app/shared_components/validations'
import AcceptStage from 'app/sm/applications/shareApplication/components/acceptStage'
import ConfirmationPage from 'app/sm/applications/shareApplication/components/confirmationPage'
import DeclineStage from 'app/sm/applications/shareApplication/components/declineStage'
import * as helpers from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

const DeclineStatus = 'decline'
const ApproveStatus = 'accept'

const ApplicationStatus = {
  accept: 'Accepted',
  decline: 'Declined',
}

const OWNER_CONFIRMATION_MESSAGE = 'Owner confirmed application successfully.'

function ShareApplicationConfirmation({ match, AcceptDeclineApplication }) {
  const [invalidPage, setInvalidPage] = useState(false)
  const [shareApplicationStatus, setShareApplicationStatus] = useState('')
  const [comment, setComment] = useState('')
  const [declineApplicationOptions, setDeclineApplicationOptions] = useState({
    ownerSelectedAnAlternative: true,
    householdAffordabilityIssue: false,
    petNotSuitable: false,
    rentalReferenceIssue: false,
    insufficientDocumentation: false,
    ownerWithdrawnProperty: false,
    notSuitableProperty: false,
  })
  const [declineReason, setDeclineReason] = useState('')
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    isBGCApproved: undefined,
  })
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
  })
  const [shareApplicationDetails, setShareApplicationDetails] = useState({})
  const [showConfirmationPage, setShowConfirmationPage] = useState(false)
  const [acceptOrDeclineInProgress, setAcceptOrDeclineInProgress] =
    useState(false)
  const [errorWhileLoading, setErrorWhileLoading] = useState('')
  const [bgcPrice, setBGCPrice] = useState(0)

  useEffect(() => {
    const {
      params: { shortcode },
    } = match

    getShareApplicationDetails(shortcode)
      .then((data) => {
        setShareApplicationDetails({ ...data.Application })
        const { backgroundCheck = {} } = data || {}
        const { displayPriceIncGST = 0 } = backgroundCheck || {}
        setBGCPrice(displayPriceIncGST)
        const initialFields = {}
        if (data.Application.request_bgc_approval) {
          // default to be true
          initialFields['isBGCApproved'] = true
        }

        if (data.Application.owners) {
          if (data.Application.owners.length > 0) {
            const defaultOwner = data.Application.owners[0]
            if (defaultOwner) {
              initialFields['firstName'] = defaultOwner.firstName
              initialFields['lastName'] = defaultOwner.lastName
              initialFields['email'] = defaultOwner.email
              initialFields['mobilePhone'] = defaultOwner.phone
            }
          }
        }

        setFields(initialFields)
      })
      .catch((error) => {
        setErrorWhileLoading(error.message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const {
      params: { status },
    } = match
    if (status !== DeclineStatus && status !== ApproveStatus) {
      setInvalidPage(true)
      return false
    }

    setShareApplicationStatus(status)
  }, [match])

  useEffect(() => {
    const { request_bgc_approval: requestBGCApproval } =
      shareApplicationDetails || {}
    if (requestBGCApproval) {
      setFields({
        ...fields,
        isBGCApproved: shareApplicationStatus === ApproveStatus,
      })
    }
  }, [shareApplicationStatus])

  const validateFormFields = () => {
    if (errors.length > 0) {
      return false
    }
    if (fields.firstName === '' || fields.lastName === '') {
      return false
    }
    return !(fields.email === '' || fields.mobilePhone === '')
  }

  const onSubmit = () => {
    const {
      params: { shortcode },
    } = match

    const validated = validateFormFields()
    if (!validated) {
      snugNotifier.error('Please enter all contact details')
      return
    }
    if (!ApplicationStatus[shareApplicationStatus]) {
      snugNotifier.error('Invalid application status')
      return
    }

    const { firstName, lastName, email, mobilePhone, isBGCApproved } = fields

    let req = {
      comment,
      isBGCApproved,
      contactDetails: {
        firstName,
        lastName,
        email,
        mobilePhone,
      },
      confirmationStatus: ApplicationStatus[shareApplicationStatus],
    }
    if (shareApplicationStatus === DeclineStatus) {
      req = {
        ...req,
        declineReason,
      }
    }
    if (req && shortcode) {
      setAcceptOrDeclineInProgress(true)
      AcceptDeclineApplication(shortcode, req)
        .then(() => {
          setShowConfirmationPage(true)
          snugNotifier.success(OWNER_CONFIRMATION_MESSAGE)
        })
        .catch((err) => {
          snugNotifier.error(err)
        })
        .finally(() => {
          setAcceptOrDeclineInProgress(false)
        })
    }
  }

  const onAddComment = (event) => {
    setComment(event.value)
  }

  const onChangeDeclineApplicationOptions = (checkboxID, reason) => {
    return (event) => {
      const { checked } = event.target
      const declineOptions = {
        ownerSelectedAnAlternative: false,
        householdAffordabilityIssue: false,
        petNotSuitable: false,
        rentalReferenceIssue: false,
        insufficientDocumentation: false,
        ownerWithdrawnProperty: false,
        notSuitableProperty: false,
      }

      setDeclineApplicationOptions({
        ...declineOptions,
        [checkboxID]: checked,
      })
      setDeclineReason(reason)
    }
  }

  const update = (field) => {
    return (data) => {
      setFields({
        ...fields,
        [field]: data.value,
      })
      setErrors({
        ...errors,
        [field]: data.error,
      })
    }
  }

  const validate = (field, value, onBlur) => {
    switch (field) {
      case 'firstName':
        setValidationErrors(field, validateName(value), onBlur)
        break
      case 'lastName':
        setValidationErrors(field, validateName(value), onBlur)
        break
      case 'email':
        setValidationErrors(field, validateEmail(value), onBlur)
        break
      case 'mobilePhone':
        return setValidationErrors(field, validations.validateMobile(value))
      default:
        break
    }
  }

  const setValidationErrors = (field, error, onBlur) => {
    if (error.length === 0) {
      setErrors({ ...errors, [field]: undefined })
      return true
    }

    setErrors({ ...errors, [field]: error })
    return false
  }

  const agencyName =
    shareApplicationDetails &&
    shareApplicationDetails.agency_details &&
    shareApplicationDetails.agency_details.companyName

  const brandingURL =
    shareApplicationDetails &&
    shareApplicationDetails.agency_details &&
    shareApplicationDetails.agency_details.brandingBannerURL
  let propertyAddress = ''
  if (
    shareApplicationDetails &&
    shareApplicationDetails.property_address &&
    shareApplicationDetails &&
    shareApplicationDetails.property_address.friendlyName
  ) {
    propertyAddress = `${shareApplicationDetails.property_address.friendlyName}, ${shareApplicationDetails.property_address.suburb}`
  }
  const {
    params: { shortcode },
  } = match
  const { applicants, lease_offer } = shareApplicationDetails

  const renderBGCApproval = () => {
    const { request_bgc_approval: requestBGCApproval } = shareApplicationDetails

    if (!requestBGCApproval || shareApplicationStatus !== ApproveStatus) return

    const { isBGCApproved } = fields

    return (
      <Box mb={5} mt={6}>
        <Text color="gray" fontSize={7} fontWeight={6} mb={5}>
          Background Check approval
        </Text>
        <Form.CheckBoxGeneral
          label={`Approve running a Background Check (all applicants) at a cost of ${bgcPrice} (inc GST)`}
          checked={isBGCApproved}
          labelClass="fs16 fw400"
          onChange={() =>
            setFields({ ...fields, isBGCApproved: !isBGCApproved })
          }
        />
      </Box>
    )
  }

  let householdNames = ''
  applicants &&
    applicants.forEach((applicant, index) => {
      if (index !== applicants.length - 1) {
        householdNames += `${applicant.firstName} ${applicant.lastName}, `
      } else {
        householdNames += `${applicant.firstName} ${applicant.lastName}`
      }
    })
  const leaseAmount = lease_offer && lease_offer.WeeklyRent
  const leaseStartDate = lease_offer && lease_offer.LeaseStartDate
  const headerText = 'Request to Approve Application'

  const {
    firstName: firstNameField,
    lastName: lastNameField,
    email: emailField,
    mobilePhone: mobilePhoneField,
  } = fields

  const {
    firstName: firstNameError,
    lastName: lastNameError,
    email: emailError,
    mobilePhone: mobilePhoneError,
  } = errors

  return (
    <div>
      {!invalidPage ? (
        <>
          {!errorWhileLoading && (
            <Display.CenterContentContainer componentClass="width100 display-flex flex-direction-column">
              {!showConfirmationPage && (
                <>
                  {brandingURL ? (
                    <div className="ilustration-box">
                      <img src={brandingURL} alt="Agency Logo" />
                    </div>
                  ) : (
                    <h2>{agencyName}</h2>
                  )}
                  {propertyAddress && (
                    <Box textAlign="center" mb="32px">
                      <h3>Property: {propertyAddress}</h3>
                    </Box>
                  )}

                  <Box>
                    <p className="text-align-left">
                      <h3>{headerText}</h3>
                      Household: {householdNames}
                      <br />
                      Rent: ${leaseAmount} p/w
                      <br />
                      Start:{' '}
                      {moment(leaseStartDate).format(
                        dateTimeHelpers.DATE_DAY_SHORT_STRING_MONTH_YEAR,
                      )}
                      <br />
                    </p>
                    <p className="text-align-left">
                      Review the details and click Submit to complete.
                    </p>
                    <p className="text-align-left">
                      Please note confirmation of the tenancy is subject to
                      final checks, receipt of funds and tenancy agreement being
                      completed by the applicant.
                    </p>
                  </Box>
                  <YesOrNoRadioGroup
                    id="application_approval"
                    label="Application approval"
                    labelClass="gray-color fs20 fw700"
                    childrenWrapperClassName="pt0"
                  >
                    <YesOrNoCheckboxComponent
                      id={'yes'}
                      label={'Approve'}
                      checked={shareApplicationStatus === ApproveStatus}
                      componentClassName="yes left"
                      onChange={() =>
                        history.push(
                          helpers.urlTo('shareApplicationAccept', {
                            shortcode,
                          }),
                        )
                      }
                    />
                    <YesOrNoCheckboxComponent
                      id={'no'}
                      label={'Decline'}
                      checked={shareApplicationStatus === DeclineStatus}
                      componentClassName="no right"
                      onChange={() =>
                        history.push(
                          helpers.urlTo('shareApplicationDecline', {
                            shortcode,
                          }),
                        )
                      }
                      to={helpers.urlTo('shareApplicationDecline', {
                        shortcode,
                      })}
                    />
                  </YesOrNoRadioGroup>

                  {renderBGCApproval()}

                  <>
                    <Text
                      color="gray"
                      fontSize={7}
                      fontWeight={6}
                      mb={4}
                      mt={8}
                    >
                      Confirm details
                    </Text>
                    <Box>
                      <p className="text-align-left">
                        Enter your details to confirm your response.
                      </p>
                    </Box>

                    <Flex>
                      <Form.InputName
                        componentClass="width50p mr5"
                        label="First name"
                        value={firstNameField}
                        error={firstNameError}
                        onChange={update('firstName')}
                        id="firstName"
                        onBlur={(e) =>
                          validate('firstName', e.target.value, true)
                        }
                        inputClass="width100"
                      />
                      <Form.InputName
                        label="Last name"
                        value={lastNameField}
                        error={lastNameError}
                        onChange={update('lastName')}
                        id="lastName"
                        componentClass="width50p"
                        inputClass="width100"
                      />
                    </Flex>
                    <Flex>
                      <Form.InputEmail
                        label="Email"
                        value={emailField}
                        error={emailError}
                        onChange={update('email')}
                        id="email"
                        componentClass="width50p mr5"
                        inputClass="width100"
                      />
                      <Form.InputPhoneNumber
                        label="Mobile"
                        value={mobilePhoneField}
                        error={mobilePhoneError}
                        onChange={update('mobilePhone')}
                        id="mobilePhone"
                        componentClass="width50p"
                        containerClassName="width100"
                      />
                    </Flex>
                  </>
                  {shareApplicationStatus === DeclineStatus && (
                    <DeclineStage
                      declineApplicationOptions={declineApplicationOptions}
                      onAddComment={onAddComment}
                      comment={comment}
                      onChangeDeclineApplicationOptions={
                        onChangeDeclineApplicationOptions
                      }
                      onSubmit={onSubmit}
                      acceptOrDeclineInProgress={acceptOrDeclineInProgress}
                    />
                  )}
                  {shareApplicationStatus === ApproveStatus && (
                    <AcceptStage
                      onAddComment={onAddComment}
                      comment={comment}
                      formFields={fields}
                      formFieldErrors={errors}
                      onUpdate={update}
                      onValidate={validate}
                      onSubmit={onSubmit}
                      shareApplicationDetails={shareApplicationDetails}
                      acceptOrDeclineInProgress={acceptOrDeclineInProgress}
                    />
                  )}
                </>
              )}
            </Display.CenterContentContainer>
          )}
          {errorWhileLoading && <ErrorMessage error={errorWhileLoading} />}
          {showConfirmationPage && <ConfirmationPage />}
        </>
      ) : (
        <NotFound404 />
      )}
    </div>
  )
}

export default ShareApplicationConfirmation
