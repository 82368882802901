import { connect } from 'react-redux'

import RentalReputationHeader from 'app/shared_components/rental_reputation_header'
import { fetchCompleteness } from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({ RentalReputation }) => ({
  completeness: RentalReputation.completeness,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCompleteness: () => dispatch(fetchCompleteness()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RentalReputationHeader)
