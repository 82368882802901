import React from 'react'

import { Link } from 'react-router-dom'

import mobilePreview from 'app/assets/icons/mobile-preview.png'
import FAQs from 'app/shared_components/faqs'
import WithdrawnForm from 'app/shared_components/templates/withdraw_form'
import { urlTo } from 'app/sm/helpers'

const LeaseeStart = ({
  request,
  currentLease,
  address,
  renter,
  tenant,
  currentUser,
}) => (
  <div>
    {request.exitStatus === 4 ? (
      <WithdrawnForm
        title="This request has been withdrawn by the applicant."
        context="If you believe this BondCover request has been withdrawn in error or would like some more information, please contact us "
        backLink="/home/overview"
      />
    ) : (
      <div>
        <h3 className="mb25">BondCover Application</h3>

        <h4>
          {' '}
          {address &&
            address.friendlyName +
              ', ' +
              address.suburb +
              ', ' +
              address.state +
              ' ' +
              address.postcode}
        </h4>
        <h4 className="bbs pb15 mb15">Bond: ${currentLease.bond}</h4>

        <p className="mb20">
          Your fellow leasee{' '}
          <b>
            {renter.firstName} {renter.lastName}{' '}
          </b>{' '}
          has applied for BondCover
          {!currentLease.isNewLease && ' which will result in refund for you'}.
        </p>

        {!currentLease.isNewLease && (
          <div>
            <h6>You will receive</h6>
            <h2 className="pt5 pb5">$ {tenant.bondAmount}</h2>
            <h5 className="fs14 mb30">Includes Snug Fee </h5>
          </div>
        )}

        <Link
          to={
            currentUser.isVerified
              ? '/bc/leaseesign?requestid=' + request.guidID
              : {
                  pathname: urlTo('join'),
                  state: {
                    message:
                      'Please sign up to securely approve this BondCover request.',
                    firstName: tenant.firstName,
                    lastName: tenant.lastName,
                    email: tenant.email,
                    mobile: tenant.mobile,
                  },
                }
          }
        >
          <button className="btn mb15">Get Started</button>
        </Link>

        <h3 className="mb15">What is BondCover? </h3>
        <p>
          BondCover replaces the need for a cash bond. Renters with a good
          address history can now choose to buy a BondCover for a small annual
          fee and use their cash bond for other important things in life.
        </p>

        <div className="img-full">
          <img src={mobilePreview} alt={'mobile preview'} />
        </div>

        <ul className="snug-tips mb50">
          <li>
            There is no change to the rights and obligations of owners and
            tenants, with a BondCover certificate.
          </li>
        </ul>

        <h3>Benefits for you</h3>
        <div className="card">
          <div className="card-block">
            <ul className="card-text-ul">
              <li>Unlocks your rental bond cash.</li>
              <li>Generous loyalty discounts on renewals.</li>
              <li>No claim bonuses.</li>
              <li>
                Comprehensive checks that you can use to prove you’re a good
                renter.
              </li>
            </ul>
          </div>
        </div>

        <a
          className="pink-color text-center block fw700 mb50"
          href="https://snug.com/bondcover/renter-quick-quote"
          target="_blank"
          rel="noreferrer"
        >
          <span>Learn More</span>
          <i className="icon-arrow-right pl20 fs14" />
        </a>

        <FAQs type="renter" />

        <div className="mt50 mb10">
          <Link
            to={
              currentUser.isVerified
                ? '/bc/leaseesign?requestid=' + request.guidID
                : {
                    pathname: urlTo('join'),
                    state: {
                      message:
                        'Please sign up to securely approve this BondCover request.',
                      firstName: tenant.firstName,
                      lastName: tenant.lastName,
                      email: tenant.email,
                      mobile: tenant.mobile,
                    },
                  }
            }
          >
            <button className="btn mb15">
              {' '}
              {!currentLease.isNewLease
                ? 'Get $' + tenant.bondAmount + ' back with BondCover'
                : 'Get Started'}{' '}
            </button>
          </Link>
        </div>
      </div>
    )}
  </div>
)

export default LeaseeStart
