import React from 'react'

import { Link } from 'react-router-dom'

const BondSplit = ({ total, tenants, errors, update, trimField }) => (
  <div>
    <ul className="list list-unstyled">
      {tenants.map((tenant) => (
        <li className="pb15 mb15 bbs" key={tenant.guidID}>
          <div className="table pb15">
            <div className="cell-m-40 gray-dark-color fw700 lh13">
              {tenant.firstName}
              <br />
              {tenant.lastName}
            </div>
            <div className="cell-m-20 text-center pink-color fs20 fw700">
              {tenant.bondAmount
                ? ((tenant.bondAmount / total) * 100).toFixed(2)
                : '0'}
              %
            </div>
            <div className="cell-m-40 text-right gray-dark-color">
              <div className="input-box w90 ibm mb10">
                <div className="prefix">$</div>
                <input
                  type="text"
                  required
                  id="bondAmount"
                  value={tenant.bondAmount || ''}
                  onChange={update(tenant.guidID)}
                  onBlur={trimField(tenant.guidID)}
                  maxLength="7"
                />
              </div>
            </div>
          </div>
          {/* <input type="range" min="0" max={total} step="0.01" value={tenant.bondAmount || ''} onChange={update(tenant.guidID)} onBlur={validate(tenant.guidID)} /> */}
        </li>
      ))}
    </ul>
  </div>
)

export default BondSplit
