import {
  removeFavorite,
  saveFavorite,
} from 'app/sm/property_details/property_details_access'
import {
  getRecentlyViewed,
  getSavedProperties,
} from 'app/sm/recently_viewed/recently_viewed_access'

export const RECEIVE_PROPERTIES = 'RW_RECEIVE_PROPERTIES'
export const TOGGLE_FAVORITE_MARK = 'RW_TOGGLE_FAVORITE_MARK'
export const TOGGLE_SPINNER = 'RW_TOGGLE_SPINNER'

// Action Creators
const receiveProperties = (properties) => ({
  type: RECEIVE_PROPERTIES,
  properties,
})
const toggleFavoriteMark = (propertyId) => ({
  type: TOGGLE_FAVORITE_MARK,
  propertyId,
})
const toggleSpinner = (spinner) => ({ type: TOGGLE_SPINNER, spinner })

// Methods
export function fetchRecentlyViewedProperties() {
  return (dispatch) => {
    dispatch(toggleSpinner(true))
    getRecentlyViewed().then(({ properties, ok, responseText, statusCode }) => {
      if (ok) {
        dispatch(toggleSpinner(false))
        // This is most robustly done here, because we need the snugFit,and the payload is changed to return { application , property, snugFit }.
        // This is how we make it : { snugFit , ...property}

        const merged = properties.map((p) =>
          Object.assign({}, p.property, { snugFit: p.snugFit }),
        )
        dispatch(receiveProperties(merged))
      } else {
        dispatch(toggleSpinner(false))
        // responseText.then(t => {
        //     store.dispatch(receiveResponseText(t))
        // })
      }
    })
  }
}

export function fetchSavedProperties() {
  return (dispatch) => {
    dispatch(toggleSpinner(true))
    getSavedProperties().then(
      ({ properties, ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(toggleSpinner(false))
          const merged = properties.map((p) =>
            Object.assign({}, p.property, { snugFit: p.snugFit }),
          )
          dispatch(receiveProperties(merged))
        } else {
          dispatch(toggleSpinner(false))
          // responseText.then(t => {
          //     store.dispatch(receiveResponseText(t))
          // })
        }
      },
    )
  }
}

export function addToSavedProperty(propertyId) {
  return (dispatch, getState) => {
    const isFavourite = getState().PropertiesLists.properties.find(
      (p) => p.guidID === propertyId,
    ).isFavourite

    if (!isFavourite) {
      saveFavorite(propertyId).then(({ ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(toggleFavoriteMark(propertyId))
        }
      })
    } else {
      removeFavorite(propertyId).then(({ ok, responseText, statusCode }) => {
        if (ok) {
          dispatch(toggleFavoriteMark(propertyId))
        }
      })
    }
  }
}
