import React from 'react'

import * as helpers from 'app/sm/helpers'

const Amenities = ({ payload, wrapClassName, availableDate }) => {
  const { carports = 0, parking = 0, garages = 0 } = payload || {}
  const carCountSpaces = { garages, parking, carports }

  return (
    <ul className={wrapClassName || 'amenities'}>
      <li>
        <i className="icon-bedroom"></i>
        <span>{payload.bedrooms}</span>
      </li>
      <li>
        <i className="icon-bathroom"></i>
        <span>{payload.bathrooms}</span>
      </li>
      <li>
        <i className="icon-cars"></i>
        <span>{helpers.getCarSpaceCountForProperty(carCountSpaces)}</span>
      </li>
      {availableDate && (
        <li>
          <i className="icon-calendar"></i>
          <span>{availableDate}</span>
        </li>
      )}
    </ul>
  )
}

export default Amenities
