import React from 'react'

import 'app/sm/viewings_new_run/schedule_list/style.scss'
import DateTime from 'react-datetime'

import * as Form from 'app/shared_components/form_component'

export const modeDriving = 'DRIVING'
export const modeWalking = 'WALKING'

const PreferenceOptionContainer = ({ children }) => {
  return <div className="display-flex mt20">{children}</div>
}

const ScheduleListContainer = ({
  startTime,
  travelMode,
  scheduleBreaks,
  breakDuration,
  viewingDuration,
  startPropertyGUID,
  endPropertyGUID,
  onPreferenceOptionChanged,
  viewingPropertyInfos,
}) => {
  return (
    <div>
      <div className="new-run-schedule-container">
        <div className="new-run-schedule-header">
          <div className="sub-title mb0">3. Set preferences</div>
        </div>
        <div className="new-run-schedule-preference">
          <PreferenceOptionContainer>
            <div className="mr15 font-size-18 preference-label">Start time</div>
            <DateTime
              dateFormat={false}
              timeFormat={'hh:mm A'}
              inputProps={{ placeholder: '', className: `datePickerStyle ` }}
              onChange={onPreferenceOptionChanged('startTime')}
              value={startTime.format('hh:mm A')}
            />
          </PreferenceOptionContainer>
          <PreferenceOptionContainer>
            <div className="mr15 font-size-18 preference-label">
              Start property
            </div>
            <div className="input-box mb0 width120px pt0">
              <select
                className="height20px p0 preference-select"
                value={startPropertyGUID}
                onChange={onPreferenceOptionChanged('startPropertyGUID')}
              >
                {viewingPropertyInfos.map((propertyInfo, index) => (
                  <option value={propertyInfo.property.guidID} key={index}>
                    {propertyInfo.property.address.friendlyName}
                  </option>
                ))}
              </select>
            </div>
          </PreferenceOptionContainer>
          <PreferenceOptionContainer>
            <div className="mr15 font-size-18 preference-label">
              End property
            </div>
            <div className="input-box mb0 width120px pt0">
              <select
                className="height20px p0 preference-select"
                value={endPropertyGUID}
                onChange={onPreferenceOptionChanged('endPropertyGUID')}
              >
                {viewingPropertyInfos.map((propertyInfo, index) => (
                  <option value={propertyInfo.property.guidID} key={index}>
                    {propertyInfo.property.address.friendlyName}
                  </option>
                ))}
              </select>
            </div>
          </PreferenceOptionContainer>
          <PreferenceOptionContainer>
            <div className="mr15 font-size-18 preference-label">
              Travel mode
            </div>
            <Form.Checkbox
              label="Car"
              checked={travelMode === modeDriving}
              onChange={() => {
                onPreferenceOptionChanged('travelMode', modeDriving)
              }}
              componentClassName={'mr15'}
            />
            <Form.Checkbox
              label="Foot"
              checked={travelMode === modeWalking}
              onChange={() => {
                onPreferenceOptionChanged('travelMode', modeWalking)
              }}
            />
          </PreferenceOptionContainer>
          <PreferenceOptionContainer>
            <div className="mr15 font-size-18 preference-label">
              Viewing duration
            </div>
            <div className="input-box mb0 width120px pt0">
              <select
                className="height20px p0 preference-select"
                value={viewingDuration}
                onChange={onPreferenceOptionChanged('viewingDuration')}
              >
                <option value={5}>5 mins</option>
                <option value={10}>10 mins</option>
                <option value={15}>15 mins</option>
                <option value={20}>20 mins</option>
                <option value={30}>30 mins</option>
                <option value={45}>45 mins</option>
                <option value={60}>60 mins</option>
              </select>
            </div>
          </PreferenceOptionContainer>
          <PreferenceOptionContainer>
            <Form.Checkbox
              label="Buffer after viewing"
              checked={scheduleBreaks}
              onChange={() => {
                onPreferenceOptionChanged('scheduleBreaks')
              }}
            />
            {scheduleBreaks && (
              <div className="ml15 input-box mb0 width120px pt0">
                <select
                  className="height20px p0 preference-select"
                  value={breakDuration}
                  onChange={onPreferenceOptionChanged('breakDuration')}
                >
                  <option value={5}>5 minutes</option>
                  <option value={10}>10 minutes</option>
                  <option value={15}>15 minutes</option>
                </select>
              </div>
            )}
          </PreferenceOptionContainer>
        </div>
      </div>
    </div>
  )
}

export default ScheduleListContainer
