import React from 'react'

import styled from 'styled-components'

import {
  BoldText,
  DesktopOnly,
  WidthPercentage,
} from 'app/pages/teams/viewings/styles'
import ViewingRow from 'app/pages/teams/viewings/ViewingRow'
import Spinner from 'app/sm/common/spinner'
import { convertDateToDayAndDate } from 'app/sm/helpers'
import { trimAndAddEllipsesPastSpecifiedLength } from 'app/utils/strings/helpers'

const StyledDayText = styled.div`
  font-size: 16px;
  @media print {
    font-size: 12px;
  }
`

const ViewingBlock = (props) => {
  const {
    viewingInfo = {},
    toggleWalkInModal,
    updateAllViewingsData,
    fetchViewingsByDateFromTeamId,
    teamGUID,
    clearError,
    fetchAgencyApplicantsElasticSearchResult,
    renterInfo,
    addWalkInRenter,
    currentUser,
    showLateViewingModal,
    notifyLateViewing,
    assignViewingToTeamMember,
    fetchViewingTeamMembers,
    deleteViewingTeamMember,
    updateViewingTeamMemberRelation,
    managersListForViewingFilters,
    viewingEntryNoticeEnabled,
    fetchPropertyReport,
    openPrintDialog,
    currentTeam,
    isViewingReportSmsEnabled,
    isViewingReportEmailEnabled,
  } = props
  const { fetchPropertyViewings, cancelViewing } = props || {}

  const { viewings = [] } = viewingInfo || {}
  const { day, dateWithoutYear } =
    viewingInfo.date && convertDateToDayAndDate(viewingInfo.date)

  const { dayAbbr } =
    viewingInfo.date && convertDateToDayAndDate(viewingInfo.date, true)

  return (
    <WidthPercentage>
      <WidthPercentage
        amount="80px"
        amountPrint="40px"
        mobileWidth="100%"
        displayType="inline-block"
        mobileDisplayType="block"
        style={{ verticalAlign: 'top' }}
      >
        <div className="mt15">
          <BoldText className="pdf-hide">
            {trimAndAddEllipsesPastSpecifiedLength(day, 8)}
          </BoldText>
          <BoldText className="pdf-show-important">{dayAbbr}</BoldText>
          <StyledDayText className="14px">{dateWithoutYear}</StyledDayText>
        </div>
        <DesktopOnly>
          <button
            className="hollow-button-green wa p10 height30 mt10 pdf-hide"
            onClick={openPrintDialog}
          >
            Print
          </button>
        </DesktopOnly>
      </WidthPercentage>
      {viewings.length === 0 && <Spinner />}
      <WidthPercentage
        amount="calc(100% - 80px)"
        amountPrint="calc(100% - 54px)"
        mobileWidth="100%"
        displayType="inline-block"
        mobileDisplayType="block"
      >
        {viewings.map((data) => {
          return (
            <ViewingRow
              data={data}
              key={data.propertyGUID}
              fetchPropertyReport={fetchPropertyReport}
              fetchPropertyViewings={fetchPropertyViewings}
              cancelViewing={cancelViewing}
              propertyGUID={data.propertyGUID}
              toggleWalkInModal={toggleWalkInModal}
              updateAllViewingsData={updateAllViewingsData}
              fetchViewingsByDateFromTeamId={fetchViewingsByDateFromTeamId}
              teamGUID={teamGUID}
              clearError={clearError}
              fetchAgencyApplicantsElasticSearchResult={
                fetchAgencyApplicantsElasticSearchResult
              }
              renterInfo={renterInfo}
              addWalkInRenter={addWalkInRenter}
              currentUser={currentUser}
              showLateViewingModal={showLateViewingModal}
              notifyLateViewing={notifyLateViewing}
              assignViewingToTeamMember={assignViewingToTeamMember}
              fetchViewingTeamMembers={fetchViewingTeamMembers}
              deleteViewingTeamMember={deleteViewingTeamMember}
              updateViewingTeamMemberRelation={updateViewingTeamMemberRelation}
              managersListForViewingFilters={managersListForViewingFilters}
              viewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
              currentTeam={currentTeam}
              isViewingReportSmsEnabled={isViewingReportSmsEnabled}
              isViewingReportEmailEnabled={isViewingReportEmailEnabled}
            />
          )
        })}
      </WidthPercentage>
    </WidthPercentage>
  )
}

export default ViewingBlock
