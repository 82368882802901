import React from 'react'

import { Link } from 'react-router-dom'
import { Pie, PieChart } from 'recharts'

import humanIcon1 from 'app/assets/icons/human01.jpg'

function RankPieChart(props) {
  const { rank } = props
  let fillColor = '#37c977'
  if (rank < 75) {
    fillColor = '#24C9D0'
  }
  if (rank < 50) {
    fillColor = '#579FFB'
  }
  if (rank < 25) {
    fillColor = '#ffe757'
  }
  const data = [
    { name: 'rank', value: rank, fill: fillColor },
    { name: 'blank', value: 100 - rank, fill: '#ffffff' },
  ]
  return (
    <PieChart width={40} height={40}>
      <Pie
        dataKey="value"
        data={data}
        innerRadius={16}
        outerRadius={20}
        labelLine={false}
      />
    </PieChart>
  )
}

function Tenants() {
  return (
    <div className="sm-property-lease-details-card tenants">
      <div className="header">
        <h4>Tenants (3)</h4>
      </div>
      <div className="content">
        <div className="table mb20 pb30 bbs">
          <div className="cell-t-100">
            <h3 className="mb10">Amanda Richards</h3>
            <a className="block mb10" href="#">
              arichards@gmail.com
            </a>
            <a className="block mb20" href="#">
              +1 31 217 9271
            </a>
            <div>
              <span className="ibm br4 p5 pl10 pr10 fs14 fw600 pink-bg-light-rgba pink-color">
                Primary
              </span>
              <a className="ibm fs16 tdu fw600 ml20" href="#">
                Send Message
              </a>
            </div>
          </div>
          <div className="cell-t">
            <div
              className="avatar avatar-big xs-avatar-small"
              style={{ backgroundImage: `url(${humanIcon1})` }}
            ></div>
          </div>
        </div>
        <div className="mb30 pb20 bbs">
          <div className="flex mb10">
            <div
              className="avatar avatar-msmall"
              style={{ backgroundImage: `url(${humanIcon1})` }}
            ></div>
            <span className="fw600 pl15">Robert Murdoch</span>
            <a href="#" className="pl30">
              +1 31 217 9271
            </a>
          </div>
          <div className="flex">
            <div className="avatar avatar-msmall blue-bg-light-rgba blue-color">
              JM
            </div>
            <span className="fw600 pl15">Jozy Murdoch</span>
          </div>
        </div>
        <div className="table">
          <div className="cell-t-100 pr30 sm-block sm-p0 xs-block xs-p0">
            <p className="m0">
              "We are a young couple with 2 pets looking for a place to live
              in…"
            </p>
          </div>
          <div className="cell-t sm-block sm-mt15 xs-block xs-mt15">
            <div className="rank">
              <div className="rank-info chart" title="Snug rank">
                <RankPieChart rank={94} />
                <div className="rank-value">94</div>
              </div>
              <div className="rank-info" title="Persons count">
                <i className="icon-person"></i>
                <span>3</span>
              </div>
              <div className="rank-info" title="Pets count">
                <i className="icon-pets"></i>
                <span>2</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tenants
