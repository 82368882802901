import React, { useEffect } from 'react'

import { Field, Form } from 'formik'

import {
  Alert,
  Button,
  LoadingSection,
} from 'app/components/design-system-components'
import {
  AutocompleteField,
  TextArea,
} from 'app/components/design-system-components/inputs'
import { SelectField } from 'app/components/design-system-components/inputs/Select/Select'
import { statusesToCheckIn } from 'app/constants/key-logger.constants'
import { formFields } from 'app/features/teams/key-logger/CheckInKeySetModal/KeySetSelection/form.utils'
import {
  LabelSubText,
  StepLayoutContainer,
  StyledFieldWrapper,
} from 'app/features/teams/key-logger/components/StepLayoutContainer'
import { useKeySetOptionsForProperty } from 'app/features/teams/key-logger/hooks/useKeySetOptionsForProperty'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { useSearchPropertyField } from 'app/utils/hooks/useSearchPropertyField'

export const FormBody = ({
  teamId,
  formBag: {
    isValid,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    status,
    values,
    touched,
    dirty,
  },
}) => {
  const { onSearchProperties, propertiesOptions, loadingProperties } =
    useSearchPropertyField(teamId, { allProperties: true })

  const { loadKeySetsOptions, keySetsOptions, keySetsOptionsLoadingStates } =
    useKeySetOptionsForProperty(teamId, statusesToCheckIn)

  useEffect(() => {
    const { property } = values

    // reset selected keySet value, if not property initial value
    if (!property || !values[formFields.property].optionMeta.isInitialValue) {
      setFieldValue(formFields.keySet, null, true)
    }
    // we are hiding keysets field if no property selected
    // so no need to handle options in this case
    if (!property) return
    loadKeySetsOptions(property)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.property])

  const retryLoadKeySetOptions = () => loadKeySetsOptions(values.property)

  const actionsElem = (
    <Button type="submit" disabled={!isValid || isSubmitting} width="100%">
      Next
    </Button>
  )

  return (
    <Form style={{ height: '100%' }}>
      <StepLayoutContainer actions={actionsElem}>
        <StyledFieldWrapper
          id={formFields.property}
          name={formFields.property}
          label="Property Address"
          labelProps={{
            subText: (
              <LabelSubText>
                Please select the property address that the keys are for
              </LabelSubText>
            ),
          }}
          required
        >
          <AutocompleteField
            name={formFields.property}
            placeholder="Search for a property address"
            options={propertiesOptions}
            onChangeText={onSearchProperties}
            isLoading={loadingProperties}
          />
        </StyledFieldWrapper>

        {values.property && (
          <LoadingSection
            loadingState={keySetsOptionsLoadingStates}
            actionHandler={retryLoadKeySetOptions}
            loaderProps={{ fontSize: theme.fontSizes.pExtraLarge18 }}
          >
            {!!keySetsOptions.length && (
              <StyledFieldWrapper
                id={formFields.keySet}
                name={formFields.keySet}
                label="Available to be checked in"
              >
                <SelectField
                  id={formFields.keySet}
                  name={formFields.keySet}
                  label="Select"
                  options={keySetsOptions}
                />
              </StyledFieldWrapper>
            )}

            {!keySetsOptions.length && (
              <Alert variant="warningWithBg" mt={theme.space[4] + 'px'}>
                No available key sets for this property
              </Alert>
            )}
          </LoadingSection>
        )}

        <StyledFieldWrapper
          id={formFields.notes}
          name={formFields.notes}
          label="Check in notes (private to team)"
        >
          <Field name={formFields.notes} as={TextArea} />
        </StyledFieldWrapper>
      </StepLayoutContainer>
    </Form>
  )
}
