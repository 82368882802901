import { connect } from 'react-redux'

import {
  getPersonalReferences,
  removePersonalReference,
} from 'app/sm/personal_reference/personal_reference_actions'
import PersonalReferenceAdd from 'app/sm/personal_reference/personal_reference_add'
import { fetchCompleteness } from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({
  PersonalReference,
  Shared,
  apply,
  RentalReputation,
}) => ({
  personalReferences: PersonalReference.personalReferences,
  backUrl: Shared.backUrl,
  property: apply.property,
  completeness: RentalReputation.completeness,
})

const mapDispatchToProps = (dispatch) => ({
  getPersonalReferences: () => dispatch(getPersonalReferences()),
  removePersonalReference: (id) => dispatch(removePersonalReference(id)),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersonalReferenceAdd)
