import React from 'react'

import { IntlTelInputWrapper } from 'app/components/design-system-components'
import { ErrorBox, getCurrentValidationStatus } from 'app/components/forms/util'
import { intTelTop10List } from 'app/shared_components/helpers'
import 'app/components/forms/base_phoneNumber/style.scss'
import {
  getPhoneTypeErrorMessage,
  relaxedValidateNumberNoType,
  validateNumberType,
} from 'app/utils/phonenumber/helpers'

const GENERIC_PHONE_NUMBER_ERROR = 'e.g. 0431 111 222 or 02 5550 2309'

class PhoneInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      updatingField: false,
      localError: false,
    }
  }

  handleBlur = () => {
    this.setState({
      updatingField: false,
    })
  }

  handleChange = (status, value, countryData, number) => {
    let error = this.validatePhoneType(number)

    const { validate } = this.props

    if (!error && !!validate) {
      error = validate(number.trim())
    }

    this.props.onChange({ value: number.trim(), error })
    this.setState({
      localError: !!error,
      updatingField: true,
    })
  }

  // returns error message. Empty string is returned if no errors
  validatePhoneType(phoneNumber) {
    const { numberType, required } = this.props
    if (!required && !phoneNumber) return ''
    if (!numberType) {
      return relaxedValidateNumberNoType(phoneNumber)
        ? ''
        : GENERIC_PHONE_NUMBER_ERROR
    }
    if (numberType && !validateNumberType(phoneNumber, numberType)) {
      return getPhoneTypeErrorMessage(numberType) || GENERIC_PHONE_NUMBER_ERROR
    }
    return ''
  }

  render() {
    const { updatingField, localError } = this.state
    const {
      label,
      value,
      error,
      id,
      placeholder = '',
      componentClass,
      showValidationStatus = true,
      containerClassName = '',
      disabled,
    } = this.props
    const validateClass = showValidationStatus
      ? getCurrentValidationStatus(value, error)
      : ''
    const showLocalValidationError = !!error && localError && !updatingField
    const showProvidedError = !!error && !localError
    const showError = showProvidedError || showLocalValidationError

    return (
      <div className={`${componentClass}`}>
        <div className={`standard-form-components mb10 ${containerClassName}`}>
          <div className="mb5 input-box standard-input-box">
            <IntlTelInputWrapper
              css={[
                'intl-tel-input',
                `standard-inputbox ${validateClass}`,
                validateClass,
              ]}
              utilsScript="libphonenumber.js"
              defaultCountry="au"
              preferredCountries={intTelTop10List}
              onPhoneNumberChange={this.handleChange}
              onPhoneNumberBlur={this.handleBlur}
              onSelectFlag={null}
              value={value}
              placeholder={placeholder || '+61 412 345 678'}
              fieldId={id}
              disabled={disabled}
            />
            <label className="input-label">{label}</label>
          </div>

          <ErrorBox error={error} showError={showError} />
        </div>
      </div>
    )
  }
}

export default PhoneInput
