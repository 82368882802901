import { useState } from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Box, Button, Flex } from 'app/components/design-system-components'
import { CopyRounded } from 'app/components/design-system-components/icons/content'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import EnquiryResponderToggle from 'app/pages/teams/messages/components/EnquiryResponderToggle'
// import VideoDisplay from 'app/pages/teams/onboarding/components/video'
import { toggleEnquiryResponder } from 'app/services/http/messages'
import * as snugNotifier from 'app/services/snugNotifier'
import { MESSAGES_AUTO_RESPONDER_TOGGLE_BUTTON } from 'app/utils/google-tag-manager/helpers'
import { copyToClipboard } from 'app/utils/strings/helpers'

const COPY_TEXT = 'Click to copy Snug Messages Inbox address'
const COPY_TEXT_COPIED = 'Copied!'

const StyledText = styled(Box)`
  margin-bottom: ${theme.space[7]}px;
  li {
    display: list-item;
    margin: ${theme.space[3]}px 0;
  }
`

const StyledContainer = styled(Box)`
  line-height: 140%;
  color: ${theme.colors.gray800};
`

export interface StatusItemProps {
  name: string
  description: string
  currentStatus: string
}

interface EnquiryAutoResponderContentProps {
  teamStatusData: Array<StatusItemProps>
  getTeamStausData: Function
  teamID: string
  isUserAdmin: boolean
}

function EnquiryAutoResponderContent({
  teamStatusData,
  getTeamStausData,
  teamID = '',
  isUserAdmin = false,
}: EnquiryAutoResponderContentProps) {
  const autoResponderStatusItem = teamStatusData?.filter(
    (statusItem) => statusItem?.name === 'Autoresponder',
  )[0]

  const autoResponderSnugEmail =
    autoResponderStatusItem?.description
      ?.match(/\((.*?)\)/g)
      ?.map((b) => b.replace(/\(|(.*?)\)/g, '$1')) ||
    autoResponderStatusItem?.description ||
    ''

  const autoResponderActive =
    autoResponderStatusItem?.currentStatus === 'active' || false

  const [copyButtonHelperText, setCopyButtonHelperText] = useState(COPY_TEXT)

  const toggleEnquiryResponderStatus = () => {
    toggleEnquiryResponder(teamID, !autoResponderActive)
      .then(() => {
        getTeamStausData()
      })
      .then(() =>
        snugNotifier.success(
          autoResponderActive
            ? 'Autoresponder deactivated'
            : 'Autoresponder activated',
        ),
      )
      .catch((error: { message: any }) => snugNotifier.error(error?.message))
  }

  const clickToCopySuccessFunc = () => {
    setCopyButtonHelperText(COPY_TEXT_COPIED)
    setTimeout(() => {
      setCopyButtonHelperText(COPY_TEXT)
    }, 2000)
  }

  const clickToCopyFailureFunc = () => {
    snugNotifier.error(
      'Unable to copy to clipboard. Please select and copy instead',
    )
  }

  const onClickCopyTextIcon = () => {
    copyToClipboard(
      `${autoResponderSnugEmail}`,
      clickToCopySuccessFunc,
      clickToCopyFailureFunc,
    )
  }

  const onClickBookCall = () => {
    window.open(
      'https://snugcom.pipedrive.com/scheduler/37MoEtj/assistance-call',
    )
  }

  return (
    <StyledContainer>
      {/* <Flex mt={6} justifyContent="center">
        <VideoDisplay
          vimeoId="886753144"
          height="180px"
          width="320px"
          isVimeo={true}
        />
      </Flex> */}
      <Box my={6}>
        Automatically nurture prospects through the leasing process, saving time
        and maximising attendance at your viewings. Get started in 60 seconds.{' '}
        <a
          href="https://help.snug.com/hc/en-us/articles/360001957375"
          target="_blank"
          rel="noreferrer"
        >
          learn more
        </a>
      </Box>
      <StyledText>
        <Text fontWeight={theme.fontWeights.h1} mb={0}>
          {' '}
          1. Update the listing agent contact email
        </Text>
        Add {autoResponderSnugEmail}{' '}
        <CopyRounded
          style={{
            cursor: 'pointer',
            color: `${theme.colors.gray400}`,
            marginBottom: '-3px',
          }}
          data-tooltip-content=""
          data-tooltip-id="copyButtonToClipboard"
          onClick={() => onClickCopyTextIcon()}
        ></CopyRounded>{' '}
        <ReactTooltip id="copyButtonToClipboard">
          <span>{copyButtonHelperText}</span>
        </ReactTooltip>
        separated by a comma to the primary listing agent on realestate.com.au
        <Flex alignItems="center" mt={5}>
          <Box mr={3}>
            <Text fontWeight={theme.fontWeights.h1} mb={0}>
              2. Turn on the Snug enquiry autoresponder
            </Text>
          </Box>
          <EnquiryResponderToggle
            onChange={toggleEnquiryResponderStatus}
            value={autoResponderActive}
            disabled={!isUserAdmin}
            buttonID={MESSAGES_AUTO_RESPONDER_TOGGLE_BUTTON}
            isUserAdmin={isUserAdmin}
          />
        </Flex>
        <Text fontWeight={theme.fontWeights.h1} mb={0} mt={5}>
          3. Send a test enquiry
        </Text>
        Enquire on a property for the updated listing agent
      </StyledText>
      <Box mb={4}>Need help? Book a free setup video call (15 min)</Box>
      <Button sizeVariant="large" onClick={() => onClickBookCall()}>
        Book a Call
      </Button>
    </StyledContainer>
  )
}

export default EnquiryAutoResponderContent
