import React, { useEffect, useRef, useState } from 'react'

import { isEmpty } from 'lodash'
import moment from 'moment'
import qs from 'qs'
import styled, { createGlobalStyle } from 'styled-components'

import { Box } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import Theme from 'app/pages/teams/viewings-mobile/theme-styled-components'
import BackgroundCheckFooter from 'app/sm/renter_background_check_report/background_report_footer/component'
import BackgroundCheckHeader from 'app/sm/renter_background_check_report/background_report_header/header_new'
import Explainer from 'app/sm/renter_background_check_report/explainer/component'
import IdentityVerificationComponent from 'app/sm/renter_background_check_report/identity_verification/id_new_component'
import LegalRecords, {
  PartOne,
  PartTwo,
} from 'app/sm/renter_background_check_report/legal_records/component'
import GeneralInformation from 'app/sm/renter_background_check_report/new_background_report/GeneralInformation'
import Reminder from 'app/sm/renter_background_check_report/reminder/component'
import TenancyComponent from 'app/sm/renter_background_check_report/tenancy/tenancy_new_component'

export const BACKGROUND_CHECK_EXPIRED_DURATION_MONTHS = 6

const PDF_CONTENT_HEIGHT = '1248px'
const PDF_PRINT_WIDTH = '1090px'
const OVER_LAP_PAGE_HEIGHT = 800

const GlobalStyles = createGlobalStyle`
  @media print {
  html,
  body {
     width: ${PDF_PRINT_WIDTH};
     height: auto;
  }
    .footer {
     display: none !important;
  }
  }
`

const BackgroundCheckReportContentWrapper = styled.div`
  padding: ${({ theme }) =>
    `${theme.dividedSpace[7]}px ${theme.dividedSpace[10]}px ${theme.dividedSpace[7]}px ${theme.dividedSpace[10]}px`};
  @media print {
    padding: ${({ theme }) =>
      `${theme.dividedSpace[6]}px ${theme.dividedSpace[10]}px ${theme.dividedSpace[4]}px ${theme.dividedSpace[10]}px`};
    overflow: hidden !important;
    max-height: ${PDF_CONTENT_HEIGHT} !important;
    min-height: ${PDF_CONTENT_HEIGHT} !important;
  }

  @media screen and (max-width: 576px) {
    padding: ${({ theme }) => `${theme.space[6]}px`};
  }
`

const PrintOnly = styled(Box)`
  display: none;
  @media print {
    display: block;
  }
`

const BrowserView = styled(Box)`
  @media print {
    display: none;
  }
`

export const BackgroundCheckReportContentTitle = styled.div`
  font-size: ${({ theme }) => `${theme.fontSizes[11]}px`};
  font-weight: ${({ theme }) => `${theme.fontWeights[6]}`};
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[10]}px`};
  white-space: nowrap;
  overflow: visible;
  text-overflow: clip;
  @media print {
    margin-bottom: ${({ theme }) => `${theme.space[0]}`};
  }

  @media screen and (max-width: 576px) {
    white-space: normal;
  }
`

const BackgroundCheckContainer = styled.div`
  transform: scale(0.8) translateY(-11%);
  margin-bottom: -65%;
  box-shadow: ${({ theme }) =>
    `${theme.space[0]} ${theme.space[1]}px ${theme.space[1]}px ${theme.space[0]} rgba(0, 0, 0, 0.25) !important`};
  @page {
    size: A4;
    margin: 0;
  }

  @media screen and (max-width: 576px) {
    transform: none;
    margin: ${({ theme }) => `${theme.space[6]}px`};
  }
  @media print {
    html,
    body {
      width: ${PDF_PRINT_WIDTH};
      height: auto;
    }
    transform: none;
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
`

export const PageText = styled(Text)`
  margin-bottom: ${(props) => (props.isNotMb ? '7px' : '10px')};
  line-height: ${({ theme }) => `${theme.lineHeights.encrption}`};
  text-align: ${(props) => (props.isNotAlign ? 'left' : 'justify')};
`

export const EquiFaxResponse = styled(PageText)`
  color: ${({ theme }) => `${theme.colors.gray600} !important`};
  font-weight: ${({ theme }) => `${theme.fontWeights[6]} !important`};
`

const NewBackgroundCheckReport = ({
  match,
  fetchBackgroundCheckReportDetails,
  fetchApplicationApplicantBackgroundCheckReportDetails,
  currentUser,
  fetchRenterApplication,
  application,
}) => {
  const [backgroundCheckDetailError, setBackgroundCheckDetailError] =
    useState('')

  const [renterInformation, setRenterInformation] = useState({})
  const [backgroundCheckDetail, setBackgroundCheckDetail] = useState({})
  const [idTenancyHeight, setIdTenancyHeight] = useState(null)
  const {
    backgroundCheckResponse = {},
    updatedAt = '',
    identityVerification = {},
    tenanacyBlacklistCheck = {},
    banckruptcyCheck = {},
    courtRecordsCheck = {},
    asicCompanyCheck = {},
    slug = '',
  } = backgroundCheckDetail || {}
  const { email = '', mobile = '' } = renterInformation
  const { ConsumerTenantCheck = {} } = backgroundCheckResponse || {}
  const {
    TenantIdentityInformation = {},
    CurrentAddress = {},
    IdentityVerification = {},
    Enquiries = {},
    Blacklists = {},
    Bankruptcies = {},
    CourtRecords = {},
    DirectorshipsProprietorships = {},
  } = ConsumerTenantCheck || {}
  const formattedUpdateDate =
    updatedAt && moment(updatedAt).format('DD MMM YYYY')
  const expireDate =
    updatedAt &&
    moment(updatedAt)
      .add(BACKGROUND_CHECK_EXPIRED_DURATION_MONTHS, 'months')
      .format('DD MMM YYYY')

  const idTenancyRef = useRef(null)

  useEffect(() => {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    const { applicationId = '', applicantId = '' } = match.params
    if (applicationId && applicantId) {
      fetchApplicationApplicantBackgroundCheckReportDetails(
        applicationId,
        applicantId,
      ).then(({ details }) => {
        setBackgroundCheckDetail(details)
      })
      fetchRenterApplication(applicationId)
      return
    }

    fetchBackgroundCheckReportDetails(queryParams)
      .then(({ details }) => {
        setBackgroundCheckDetail(details)
      })
      .catch((apiError) => {
        if (queryParams.token) {
          setBackgroundCheckDetailError('Not authorised')
          return
        }

        setBackgroundCheckDetailError(apiError)
      })

    if (currentUser?.email && currentUser?.mobile) {
      setRenterInformation((oldState) => ({
        ...oldState,
        mobile: currentUser.mobile,
        email: currentUser.email,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUser.email,
    currentUser.mobile,
    fetchRenterApplication,
    fetchApplicationApplicantBackgroundCheckReportDetails,
    fetchBackgroundCheckReportDetails,
    match.params.applicantId,
    match.params.applicationId,
  ])

  useEffect(() => {
    if (!isEmpty(application)) {
      const { application: renterApplication = {} } = application
      const { applicant = {} } = renterApplication || {}
      const { email: renterEmail = '', mobileNumber: renterMobile = '' } =
        applicant || {}

      setRenterInformation((oldState) => ({
        ...oldState,
        mobile: renterMobile,
        email: renterEmail,
      }))
    }
  }, [application])

  useEffect(() => {
    document.getElementById('index').classList.add('no-navbar-menu')
    document
      .querySelector('.app-content-container')
      .classList.add('no-padding-app-content-container')
  }, [])

  useEffect(() => {
    if (idTenancyRef?.current?.clientHeight) {
      setIdTenancyHeight(idTenancyRef.current.clientHeight)
    }
  }, [TenantIdentityInformation])

  return (
    <Theme>
      <Box maxWidth={'1200px'} m={'auto'}>
        <GlobalStyles />
        {backgroundCheckDetailError ? (
          <div className="alert alert-danger">
            {' '}
            {backgroundCheckDetailError}{' '}
          </div>
        ) : (
          <>
            <BackgroundCheckContainer className="new-bc-report-container">
              {' '}
              <BackgroundCheckHeader
                formattedUpdateDate={formattedUpdateDate}
                expireDate={expireDate}
                slug={slug}
              />
              <BackgroundCheckReportContentWrapper>
                <GeneralInformation
                  IdInfo={TenantIdentityInformation}
                  identityVerification={identityVerification}
                  currentAddress={CurrentAddress}
                  email={email}
                  mobile={mobile}
                />
                <BackgroundCheckReportContentTitle>
                  Summary of status of databases check
                </BackgroundCheckReportContentTitle>
                <div ref={idTenancyRef}>
                  <IdentityVerificationComponent
                    IdInfo={TenantIdentityInformation}
                    resultInfo={identityVerification}
                    IdentityVerification={IdentityVerification}
                    updateDate={formattedUpdateDate}
                  />
                  <TenancyComponent
                    resultInfo={tenanacyBlacklistCheck}
                    Enquiries={Enquiries}
                    Blacklists={Blacklists}
                    updateDate={formattedUpdateDate}
                  />
                </div>

                <BrowserView>
                  <LegalRecords
                    CourtRecords={CourtRecords}
                    updateDate={formattedUpdateDate}
                    Bankruptcies={Bankruptcies}
                    DirectorshipsProprietorships={DirectorshipsProprietorships}
                    BankruptciesResultInfo={banckruptcyCheck}
                    CourtRecordsResultInfo={courtRecordsCheck}
                    DirectorshipsProprietorshipsResultInfo={asicCompanyCheck}
                    backgroundCheckResponse={backgroundCheckResponse}
                    backgroundCheck={backgroundCheckDetail}
                  />
                </BrowserView>
                {idTenancyHeight < OVER_LAP_PAGE_HEIGHT && (
                  <PrintOnly>
                    <PartOne
                      CourtRecords={CourtRecords}
                      updateDate={formattedUpdateDate}
                      Bankruptcies={Bankruptcies}
                      DirectorshipsProprietorships={
                        DirectorshipsProprietorships
                      }
                      BankruptciesResultInfo={banckruptcyCheck}
                      CourtRecordsResultInfo={courtRecordsCheck}
                      DirectorshipsProprietorshipsResultInfo={asicCompanyCheck}
                      backgroundCheckResponse={backgroundCheckResponse}
                      backgroundCheck={backgroundCheckDetail}
                    />
                  </PrintOnly>
                )}
              </BackgroundCheckReportContentWrapper>
              <BackgroundCheckFooter page={1} />
              <BackgroundCheckHeader
                formattedUpdateDate={formattedUpdateDate}
                expireDate={expireDate}
                slug={slug}
                page={2}
              />
              <BackgroundCheckReportContentWrapper>
                {idTenancyHeight < OVER_LAP_PAGE_HEIGHT ? (
                  <PrintOnly>
                    <PartTwo
                      CourtRecords={CourtRecords}
                      updateDate={formattedUpdateDate}
                      Bankruptcies={Bankruptcies}
                      DirectorshipsProprietorships={
                        DirectorshipsProprietorships
                      }
                      BankruptciesResultInfo={banckruptcyCheck}
                      CourtRecordsResultInfo={courtRecordsCheck}
                      DirectorshipsProprietorshipsResultInfo={asicCompanyCheck}
                      backgroundCheckResponse={backgroundCheckResponse}
                      backgroundCheck={backgroundCheckDetail}
                    />
                  </PrintOnly>
                ) : (
                  <PrintOnly>
                    <LegalRecords
                      CourtRecords={CourtRecords}
                      updateDate={formattedUpdateDate}
                      Bankruptcies={Bankruptcies}
                      DirectorshipsProprietorships={
                        DirectorshipsProprietorships
                      }
                      backgroundCheckResponse={backgroundCheckResponse}
                      backgroundCheck={backgroundCheckDetail}
                    />
                  </PrintOnly>
                )}
                <Reminder />
              </BackgroundCheckReportContentWrapper>
              <BackgroundCheckFooter page={2} />
              <Explainer />
              <BackgroundCheckFooter page={3} isView />
            </BackgroundCheckContainer>
          </>
        )}
      </Box>
    </Theme>
  )
}

export default NewBackgroundCheckReport
