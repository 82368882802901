import React from 'react'

import iconDocumentPdf from 'app/assets/icons/icon-document-pdf.svg'

function Files() {
  return (
    <div className="sm-chat-files">
      <a href="#">
        <img className="icon" src={iconDocumentPdf} height="25" />
        <div className="text">
          <div className="name">Signed Lease.pdf</div>
          <div className="info">uploaded 24 Jun 2017</div>
        </div>
      </a>
      <a href="#">
        <img className="icon" src={iconDocumentPdf} height="25" />
        <div className="text">
          <div className="name">Rent Breakdown.doc</div>
          <div className="info">uploaded 24 Jun 2017</div>
        </div>
      </a>
      <a href="#">
        <img className="icon" src={iconDocumentPdf} height="25" />
        <div className="text">
          <div className="name">House Rules.doc</div>
          <div className="info">uploaded 24 Jun 2017</div>
        </div>
      </a>
    </div>
  )
}

export default Files
