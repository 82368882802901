import { connect } from 'react-redux'

import { createNote } from 'app/agency/agency_action'
import { fetchPropertyReport } from 'app/match/propertyReport/action'
import PropertyViewings from 'app/pages/teams/viewings/index'
import {
  addWalkInRenter,
  cancelViewing,
  clearError,
  fetchAgencyApplicantsElasticSearchResult,
  fetchViewingsByDateFromTeamId,
  notifyLateViewing,
  showLateViewingModal,
  toggleWalkInModal,
} from 'app/sm/inspections/inspections_actions'
import { fetchAgencyPublicProperties } from 'app/sm/properties/properties_actions'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'
import {
  assignViewingToTeamMember,
  deleteViewingTeamMember,
  fetchViewingTeamMembers,
  getManagersListForViewingFilters,
  updateViewingTeamMemberRelation,
} from 'app/sm/viewings/viewings_actions'

const mapStateToProps = ({ session, TeamOverview, ViewingsReducer }) => ({
  teams: session.teams,
  currentTeam: session.currentTeam,
  filters: TeamOverview.filters,
  renterInfo: ViewingsReducer.renterInfo,
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  toggleWalkInModal: (isWalkInModalOn, viewing) =>
    dispatch(toggleWalkInModal(isWalkInModalOn, viewing)),
  cancelViewing: (viewingGUID, propertyId) =>
    dispatch(cancelViewing(viewingGUID, propertyId)),
  addWalkInRenter: (data, propertyId, viewingId) =>
    dispatch(addWalkInRenter(data, propertyId, viewingId)),
  fetchAgencyApplicantsElasticSearchResult: (teamId, queryString) =>
    dispatch(fetchAgencyApplicantsElasticSearchResult(teamId, queryString)),
  fetchViewingsByDateFromTeamId: (teamGUID, filters) =>
    dispatch(fetchViewingsByDateFromTeamId(teamGUID, filters)),
  clearError: () => dispatch(clearError()),
  showLateViewingModal: (isLateModalOn, viewing) =>
    dispatch(showLateViewingModal(isLateModalOn, viewing)),
  notifyLateViewing: (data) => dispatch(notifyLateViewing(data)),
  createNote: (payload, ownerGUID, ownerType) =>
    dispatch(createNote(payload, ownerGUID, ownerType)),
  assignViewingToTeamMember: (teamGUID, viewingGUID, teamMemberGUID) =>
    dispatch(assignViewingToTeamMember(teamGUID, viewingGUID, teamMemberGUID)),
  fetchViewingTeamMembers: (teamGUID, viewingGUID) =>
    dispatch(fetchViewingTeamMembers(teamGUID, viewingGUID)),
  deleteViewingTeamMember: (teamGUID, viewingGUID, teamMemberGUID) =>
    dispatch(deleteViewingTeamMember(teamGUID, viewingGUID, teamMemberGUID)),
  getManagersListForViewingFilters: (teamGUID) =>
    dispatch(getManagersListForViewingFilters(teamGUID)),
  updateViewingTeamMemberRelation: (
    teamGUID,
    viewingGUID,
    teamMemberGUID,
    requestBody,
  ) =>
    dispatch(
      updateViewingTeamMemberRelation(
        teamGUID,
        viewingGUID,
        teamMemberGUID,
        requestBody,
      ),
    ),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
  fetchAgencyPublicProperties: (
    agencySlug,
    address,
    sort,
    sortDir,
    bedroom,
    cursor,
  ) =>
    dispatch(
      fetchAgencyPublicProperties(
        agencySlug,
        address,
        sort,
        sortDir,
        bedroom,
        cursor,
      ),
    ),
  fetchPropertyReport: (viewingId) => dispatch(fetchPropertyReport(viewingId)),
})

export const PropertyViewingsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyViewings)
