import * as API from 'app/bond_cover/admin/admin_access'
import { history } from 'app/shared_components/router'
import store from 'app/store'

export const RECEIVE_RESPONSE_TEXT = 'RECEIVE_RESPONSE_TEXT'
export const RECEIVE_AMOUNT_RESPONSE_TEXT = 'RECEIVE_AMOUNT_RESPONSE_TEXT'
export const SEARCH_RESULTS_RETURNED = 'SEARCH_RESULTS_RETURNED'
export const REQUEST_SUMMARY_RETURNED = 'REQUEST_SUMMARY_RETURNED'
export const APPLICATION_LIST_RETURNED = 'APPLICATION_LIST_RETURNED'
export const TOGGLE_SPINNER = 'TOGGLE_SPINNER'
export const TOGGLE_SPINNER_ON = 'TOGGLE_SPINNER_ON'
export const TOGGLE_SPINNER_OFF = 'TOGGLE_SPINNER_OFF'
export const toggleSpinner = () => ({ type: TOGGLE_SPINNER })
export const toggleSpinnerOn = () => ({ type: TOGGLE_SPINNER_ON })
export const toggleSpinnerOff = () => ({ type: TOGGLE_SPINNER_OFF })

export function searchRequests(searchInput, searchResultNextPageFirstItemId) {
  let nextPageFirstItemId = searchResultNextPageFirstItemId || 0
  API.searchRequests(
    searchInput,
    nextPageFirstItemId,
    (searchResults) => store.dispatch(searchResultsReturned(searchResults)),
    error,
  )
}
const searchResultsReturned = (searchResults) => ({
  type: SEARCH_RESULTS_RETURNED,
  searchResults,
})

export function getRequestSummary(guid) {
  API.getRequestSummary(
    guid,
    (requestSummary) => store.dispatch(requestSummaryReturned(requestSummary)),
    error,
  )
}

const requestSummaryReturned = (requestSummary) => ({
  type: REQUEST_SUMMARY_RETURNED,
  requestSummary,
})

export function reopenApplication(appCode, guid) {
  store.dispatch(toggleSpinnerOn())
  API.reopenApplication(
    appCode,
    () => {
      getRequestSummary(guid)
      store.dispatch(toggleSpinnerOff())
      store.dispatch(receiveResponseText())
    },
    error,
  )
}

export function releaseApplication(guid) {
  store.dispatch(toggleSpinnerOn())
  API.releaseApplication(
    guid,
    () => {
      getRequestSummary(guid)
      store.dispatch(toggleSpinnerOff())
      store.dispatch(receiveResponseText())
    },
    error,
  )
}

export function redirectToDeclineForm() {
  history.push(`/admin/decline`)
}

export function resendApplication(appCode, guid) {
  store.dispatch(toggleSpinnerOn())
  API.resendApplication(
    appCode,
    () => {
      getRequestSummary(guid)
      store.dispatch(toggleSpinnerOff())
      store.dispatch(receiveResponseText())
    },
    error,
  )
}

export function approveApplication(appCode, data, guid) {
  API.approveApplication(
    appCode,
    data,
    () => {
      getRequestSummary(guid)
    },
    error,
  )
}

export function fetchApplicationList(applicationListNextPageFirstItemId) {
  let nextPageFirstItemId = applicationListNextPageFirstItemId || 0
  API.fetchApplicationList(
    nextPageFirstItemId,
    (applicationList) =>
      store.dispatch(applicationListReturned(applicationList)),
    error,
  )
}

export function releaseBackToRenter(guid) {
  store.dispatch(toggleSpinnerOn())
  API.releaseBackToRenter(
    guid,
    () => {
      getRequestSummary(guid)
      store.dispatch(toggleSpinnerOff())
      store.dispatch(receiveResponseText())
    },
    error,
  )
}

export function adminDecline(data, appCode) {
  store.dispatch(toggleSpinnerOn())
  API.adminDecline(
    data,
    appCode,
    (response) => {
      store.dispatch(toggleSpinnerOff())
      store.dispatch(receiveResponseText())
      history.push('/admin/search')
    },
    error,
  )
}

export function releaseToIDCheck(guid) {
  store.dispatch(toggleSpinnerOn())
  API.releaseToIDCheck(
    guid,
    () => {
      getRequestSummary(guid)
      store.dispatch(toggleSpinnerOff())
      store.dispatch(receiveResponseText())
    },
    error,
  )
}

export function processPayment(guid) {
  store.dispatch(toggleSpinnerOn())
  API.processPayment(
    guid,
    () => {
      store.dispatch(toggleSpinnerOff())
      store.dispatch(receiveResponseText())
      setTimeout(function () {
        getRequestSummary(guid)
      }, 1000)
    },
    error,
  )
}

export function releaseToCertificate(guid) {
  store.dispatch(toggleSpinnerOn())
  API.releaseToCertificate(
    guid,
    () => {
      getRequestSummary(guid)
      store.dispatch(toggleSpinnerOff())
      store.dispatch(receiveResponseText())
    },
    error,
  )
}

const applicationListReturned = (applicationList) => ({
  type: APPLICATION_LIST_RETURNED,
  applicationList,
})

export function clearError() {
  store.dispatch(receiveResponseText(''))
}

export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})

export const receiveAmountResponseText = (responseText) => ({
  type: RECEIVE_AMOUNT_RESPONSE_TEXT,
  responseText,
})

let error = ({ responseText }) => {
  responseText = responseText ? responseText.replace(/\n/, '') : ''
  store.dispatch(receiveResponseText(responseText))
  if (store.getState().admin.spinner) {
    store.dispatch(toggleSpinnerOff())
  }
}

let errorAmount = ({ responseText }) => {
  responseText = responseText ? responseText.replace(/\n/, '') : ''
  store.dispatch(receiveAmountResponseText(responseText))
  if (store.getState().admin.spinner) {
    store.dispatch(toggleSpinnerOff())
  }
}

export function addPricingVariation(requestId, payload) {
  return () => {
    API.addPricingVariation(requestId, payload).then(({ ok, responseText }) => {
      if (ok) {
        getRequestSummary(requestId)
      } else {
        responseText.then((t) => errorAmount({ responseText: t }))
      }
    })
  }
}

export function deletePricingVariation(requestId, pricingVariationId) {
  return () => {
    API.deletePricingVariation(requestId, pricingVariationId).then(
      ({ ok, responseText }) => {
        if (ok) {
          getRequestSummary(requestId)
        } else {
          responseText.then((t) => errorAmount({ responseText: t }))
        }
      },
    )
  }
}

export function removeAllDiscounts(requestId, guids) {
  return () => {
    Promise.all(
      guids.map((id) => API.deletePricingVariation(requestId, id)),
    ).then((responses) => {
      getRequestSummary(requestId)
    })
  }
}
