import React from 'react'

import moment from 'moment'

import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import { SearchInputBox } from 'app/dashboard/team_overview_container'
import * as snugNotifier from 'app/services/snugNotifier'
import GeneralBottomBtns from 'app/shared_components/general_bottom_btn/component'
import TwoColumnContainer, {
  LeftComponent,
} from 'app/shared_components/layout_component/two_column_layout_components'
import NotFound404 from 'app/shared_components/not_found_404/not_found_404'
import { history } from 'app/shared_components/router'
import Spinner from 'app/sm/common/spinner'
import { findChosenTeamBySlug, urlTo } from 'app/sm/helpers'
import { DropDownForManager } from 'app/sm/properties/components/property_search_filter_util'
import FilterOptions from 'app/sm/viewings_new_run/filter_options/component'
import PublicHeader from 'app/sm/viewings_new_run/header/component'
import Loadmore from 'app/sm/viewings_new_run/load_more/component'
import PropertyItem, {
  PropertyItemListContainer,
} from 'app/sm/viewings_new_run/property_item/component'
import ScheduleListContainer from 'app/sm/viewings_new_run/schedule_list/component'
import { isViewingRunEnabled } from 'config/features'

import 'app/sm/viewings_new_run/style.scss'

export const PropertySearchContainer = ({ children }) => (
  <div className="properties-search-filter mb0">{children}</div>
)

// sort selectedPropertyInfos given start, end guid
const sortSelectedPropertyInfos = (
  startPropertyGUID,
  endPropertyGUID,
  selectedPropertyInfos,
) => {
  const newSelectedPropertyInfos = selectedPropertyInfos
    .filter((propertyInfo) => {
      return propertyInfo.property.guidID === startPropertyGUID
    })
    .concat(
      selectedPropertyInfos.filter((propertyInfo) => {
        return (
          propertyInfo.property.guidID !== startPropertyGUID &&
          propertyInfo.property.guidID !== endPropertyGUID
        )
      }),
    )
    .concat(
      selectedPropertyInfos.filter((propertyInfo) => {
        return propertyInfo.property.guidID === endPropertyGUID
      }),
    )
  return newSelectedPropertyInfos
}

// choose the nearest one in future
export const selectSameDayOrFutureViewingInfos = (
  existingViewingInfos,
  startTime,
) => {
  if (!existingViewingInfos || existingViewingInfos.length === 0) {
    return []
  }

  const sortedExistingViewingInfos = existingViewingInfos.map((v) => v)
  sortedExistingViewingInfos.sort((v1, v2) =>
    v1.viewing.startDate.localeCompare(v2.viewing.startDate),
  )
  const latestFutureViewingInfos = sortedExistingViewingInfos.filter(
    (viewingInfo) =>
      moment(viewingInfo.viewing.startDate).isAfter(startTime) ||
      moment(viewingInfo.viewing.startDate).isSame(startTime, 'day'),
  )
  return latestFutureViewingInfos
}

// TODO: there's a bug in not detecting conflicts when scheduling exactly same viewing run
// find the conflicted viewing if any
export const findConflictedViewing = (
  existingViewingInfos,
  newViewingStartTime,
  newViewingDuration,
  newBreakDuration,
) => {
  if (
    !existingViewingInfos ||
    existingViewingInfos.length === 0 ||
    !newViewingStartTime
  ) {
    return []
  }

  const newViewingEndTime = newViewingStartTime
    .clone()
    .add(newViewingDuration + newBreakDuration, 'm')
  const conflicted = existingViewingInfos.filter((existingViewingInfo) => {
    const existingViewingStartTime = moment(
      existingViewingInfo.viewing.startDate,
    )
    const existingViewingEndTime = existingViewingStartTime
      .clone()
      .add(existingViewingInfo.viewing.duration, 'm')
    if (
      existingViewingStartTime.isBetween(
        newViewingStartTime,
        newViewingEndTime,
        null,
        '[]',
      ) ||
      newViewingStartTime.isBetween(
        existingViewingStartTime,
        existingViewingEndTime,
        null,
        '[]',
      )
    ) {
      return true
    }
    return false
  })
  return conflicted
}

class ViewingNewRun extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      managerList: [],
      showAllTab: false,
      manager: '',
      searchText: '',
      propertyData: {
        data: [],
      },
      propertyViewingInfos: [],
      loadMoreSpinner: false,
      tableSpinner: false,
    }
  }

  componentDidMount() {
    const { guid } = this.getChosenTeam()
    guid && this.loadManagerList(guid)
    guid && this.callFetchTeamProperties(guid, {})
  }

  componentDidUpdate(prevProps, prevState) {
    const { guid } = this.getChosenTeam()

    if (
      prevProps.match.params.teamSlug !== this.props.match.params.teamSlug ||
      prevProps.teams !== this.props.teams
    ) {
      guid && this.loadManagerList(guid)
      guid && this.callFetchTeamProperties(guid, {})
    }
  }

  onAddButtonClicked = (propertyInfo) => {
    const newSelectedPropertyInfos =
      this.props.viewingRun.selectedPropertyInfos.concat(propertyInfo)
    this.props.generateViewingRun(
      Object.assign({}, this.props.viewingRun, {
        selectedPropertyInfos: newSelectedPropertyInfos,
      }),
    )
  }

  onDateChanged = (value) => {
    const newStartTime = this.props.viewingRun.startTime.clone()
    newStartTime.year(value.year()).month(value.month()).date(value.date())
    this.props.generateViewingRun(
      Object.assign({}, this.props.viewingRun, { startTime: newStartTime }),
    )
  }

  onDeleteButtonClicked = (index) => {
    const newSelectedPropertyInfos = [
      ...this.props.viewingRun.selectedPropertyInfos,
    ]
    newSelectedPropertyInfos.splice(index, 1)
    this.props.generateViewingRun(
      Object.assign({}, this.props.viewingRun, {
        selectedPropertyInfos: newSelectedPropertyInfos,
      }),
    )
  }

  onLoadMoreClicked = (offset) => {
    const { manager = '', searchText = '' } = this.state
    const { guid } = this.getChosenTeam()
    const loadMore = true
    const status = ''
    guid &&
      this.callFetchTeamProperties(guid, {
        searchText,
        manager,
        status,
        offset,
        loadMore,
      })
  }

  onManagerFilterChange = (event) => {
    this.setState(
      {
        manager: event.target.value,
      },
      () => {
        const { searchText = '', manager = '' } = this.state
        const { guid } = this.getChosenTeam()
        if (!guid) {
          return
        }

        manager === ''
          ? this.setState({ showAllTab: false })
          : this.setState({ showAllTab: true })
        this.callFetchTeamProperties(guid, { searchText, manager })
      },
    )
  }

  // choose manager to execute the viewing run
  onManagerListChange = (event) => {
    const newChosenManager = event.target.value
    const { guidID: managerGUID, profile: managerProfile } =
      this.getChosenManager(newChosenManager)
    this.props.generateViewingRun(
      Object.assign({}, this.props.viewingRun, {
        chosenManager: newChosenManager,
        managerGUID,
        managerProfile,
      }),
    )
  }

  onPreferenceOptionChanged = (field, text) => {
    if (field === 'startTime') {
      return (value) => {
        const newValue = moment(value, 'hh:mm A')
        if (newValue.isValid()) {
          const newStartTime = this.props.viewingRun.startTime.clone()
          newStartTime.hour(newValue.hour()).minute(newValue.minute())
          this.props.generateViewingRun(
            Object.assign({}, this.props.viewingRun, {
              startTime: newStartTime,
            }),
          )
        }
      }
    } else if (field === 'travelMode') {
      this.props.generateViewingRun(
        Object.assign({}, this.props.viewingRun, { travelMode: text }),
      )
    } else if (field === 'scheduleBreaks') {
      this.props.generateViewingRun(
        Object.assign({}, this.props.viewingRun, {
          scheduleBreaks: !this.props.viewingRun.scheduleBreaks,
        }),
      )
    } else if (field === 'breakDuration') {
      return (event) => {
        this.props.generateViewingRun(
          Object.assign({}, this.props.viewingRun, {
            breakDuration: Number(event.target.value),
          }),
        )
      }
    } else if (field === 'viewingDuration') {
      return (event) => {
        this.props.generateViewingRun(
          Object.assign({}, this.props.viewingRun, {
            viewingDuration: Number(event.target.value),
          }),
        )
      }
    } else if (field === 'startPropertyGUID') {
      return (event) => {
        const newSelectedPropertyInfos = sortSelectedPropertyInfos(
          event.target.value,
          '',
          this.props.viewingRun.selectedPropertyInfos,
        )
        this.props.generateViewingRun(
          Object.assign({}, this.props.viewingRun, {
            startPropertyGUID: event.target.value,
            selectedPropertyInfos: newSelectedPropertyInfos,
          }),
        )
      }
    } else if (field === 'endPropertyGUID') {
      return (event) => {
        const newSelectedPropertyInfos = sortSelectedPropertyInfos(
          '',
          event.target.value,
          this.props.viewingRun.selectedPropertyInfos,
        )
        this.props.generateViewingRun(
          Object.assign({}, this.props.viewingRun, {
            endPropertyGUID: event.target.value,
            selectedPropertyInfos: newSelectedPropertyInfos,
          }),
        )
      }
    }
  }

  onPreviewButtonClicked = () => {
    if (this.props.viewingRun.selectedPropertyInfos.length < 2) {
      snugNotifier.error('At least 2 properties should be selected')
      return
    }
    const { teamSlug = '' } = this.props.match.params
    history.push(urlTo('viewingRunPreview', { teamSlug }))
  }

  onSearchChange = (event) => {
    const { guid } = this.getChosenTeam()
    if (!guid) {
      return
    }

    const { value } = event.target
    clearInterval(this.propertySearch)

    this.setState({ searchText: value }, () => {
      const { searchText = '', manager = '' } = this.state
      this.propertySearch = setTimeout(
        () => this.callFetchTeamProperties(guid, { searchText, manager }),
        500,
      )
    })
  }

  getChosenManager = (chosenManager) => {
    return (
      this.state.managerList.find(
        (manager) =>
          manager.profile.firstName + ' ' + manager.profile.lastName ===
          chosenManager,
      ) || {}
    )
  }

  // return team object (with property guid) or undefined if not found
  getChosenTeam = () => {
    const { teams } = this.props
    const { teamSlug = '' } = this.props.match.params
    return findChosenTeamBySlug(teams, teamSlug) || {}
  }

  addAllPropertiesToViewingRun = () => {
    const propertyList = this.state.propertyData.data
    this.props.generateViewingRun(
      Object.assign({}, this.props.viewingRun, {
        selectedPropertyInfos: propertyList,
      }),
    )
  }

  callFetchTeamProperties = (
    teamID,
    { searchText, manager, status, offset, loadMore = false },
  ) => {
    const { fetchTeamProperties } = this.props
    loadMore
      ? this.setState({ loadMoreSpinner: true })
      : this.setState({ tableSpinner: true })
    return fetchTeamProperties(teamID, {
      searchText,
      manager,
      status,
      offset,
      loadMore,
      limit: 4,
    })
      .then(({ properties }) => {
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })

        const previousProperties = loadMore ? this.state.propertyData.data : []
        const updatedProperties = previousProperties.concat(properties.data)
        this.setState({
          propertyData: {
            data: updatedProperties,
            response_metadata: properties.response_metadata,
          },
        })
        return Promise.resolve({ properties })
      })
      .then(({ properties }) => {
        const fetchViewingPromises = properties.data.map(({ property }) => {
          return this.props
            .fetchPropertyViewings({ propertyId: property.guidID })
            .then((propertyViewings) => ({
              [property.guidID]: propertyViewings,
            }))
        })

        Promise.all(fetchViewingPromises).then((values) => {
          // create map for newly fetched viewing info
          // cur is of shape {propertyGUID: [viewing1, viewing2]}
          const newPropertyViewingInfos = values.reduce((acc, cur) => {
            Object.keys(cur).forEach((key) => {
              acc[key] = cur[key]
            })
            return acc
          }, {})
          // populate old one into it
          Object.keys(this.state.propertyViewingInfos).forEach((key) => {
            newPropertyViewingInfos[key] = this.state.propertyViewingInfos[key]
          })
          this.setState({ propertyViewingInfos: newPropertyViewingInfos })
          this.props.generateViewingRun(
            Object.assign({}, this.props.viewingRun, {
              propertyViewingInfos: newPropertyViewingInfos,
            }),
          )
        })
      })
      .catch((error) => {
        console.log('callFetchTeamProperties error', error)
        this.setState({ error })
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
        return Promise.reject(error)
      })
  }

  changeToMeFilter = () => {
    const { showAllTab, managerList } = this.state
    const { guid } = this.getChosenTeam()
    if (!guid) {
      return
    }
    const currentManagerAgency =
      managerList && managerList.filter((m) => m.isMe)
    const currentManagerAgencyGUID =
      currentManagerAgency && currentManagerAgency[0].guidID
    const currentChoice =
      currentManagerAgencyGUID && !showAllTab ? currentManagerAgencyGUID : ''

    this.setState({ manager: currentChoice }, () => {
      const { manager = '', searchText = '' } = this.state
      const status = ''
      this.callFetchTeamProperties(guid, { searchText, manager, status })
    })
    currentManagerAgencyGUID && showAllTab === false
      ? this.setState({ showAllTab: true })
      : this.setState({ showAllTab: false })
  }

  generateCrumbs = () => {
    const { teamSlug = '' } = this.props.match.params
    const curTeam =
      this.props.teams.find((team) => team.slug === teamSlug) || {}
    let crumbs = [
      {
        text: curTeam.name,
        link: urlTo('teamOverview', { teamSlug }),
      },
      {
        text: 'Viewings',
        link: `${urlTo('prospectSummary', { teamSlug })}?stage=Viewing`,
      },
      {
        text: 'Run',
        link: urlTo('viewingRuns', { teamSlug }),
      },
      {
        text: 'Create',
        link: '#',
      },
    ]
    return crumbs
  }

  loadManagerList = (agencyGUID) => {
    agencyGUID &&
      this.props.fetchTeamManagerList(agencyGUID).then(({ managerList }) => {
        const managerNameList = managerList.map(
          (manager) =>
            manager.profile.firstName + ' ' + manager.profile.lastName,
        )
        this.setState(
          {
            managerNameList: managerNameList,
            managerList: managerList,
          },
          () => {
            // do nothing if manager list is empty or chosenManager is set already (back from Preview)
            if (
              managerList.length === 0 ||
              this.props.viewingRun.chosenManager
            ) {
              return
            }
            this.props.generateViewingRun(
              Object.assign({}, this.props.viewingRun, {
                chosenManager: managerNameList[0],
                agencyGUID,
                managerGUID: managerList[0].guidID,
                managerProfile: managerList[0].profile,
              }),
            )
          },
        )
      })
  }

  render() {
    const {
      managerNameList,
      showAllTab,
      manager,
      searchText,
      managerList,
      propertyData = {},
      loadMoreSpinner,
      tableSpinner,
      schedulePreference,
    } = this.state

    const { data: propertyList = [], response_metadata = {} } = propertyData
    const { total } = response_metadata
    const offset = propertyList.length

    return isViewingRunEnabled(
      this.props.match.params.teamSlug,
      this.props.teams,
    ) ? (
      <TwoColumnContainer>
        <LeftComponent>
          <Breadcrumbs crumbs={this.generateCrumbs()} />
          <PublicHeader
            title="Prepare run"
            text="Select the properties, configure your options and preview."
          />
          <FilterOptions
            onDateChanged={this.onDateChanged}
            date={this.props.viewingRun.startTime}
            managerList={managerNameList}
            onChange={this.onManagerListChange}
            chosenManager={this.props.viewingRun.chosenManager}
          />
          <div className="mt50">
            <div className="sub-title">2. Search and select properties</div>
            <PropertySearchContainer>
              <SearchInputBox
                searchText={searchText}
                onSearchChange={this.onSearchChange}
              />
              {managerList && (
                <DropDownForManager
                  label="Manager:"
                  options={managerList}
                  value={manager}
                  onChange={this.onManagerFilterChange}
                  changeToMeFilter={this.changeToMeFilter}
                  showAllTab={showAllTab}
                />
              )}
              <div
                className="blue-link-style ml10"
                onClick={this.addAllPropertiesToViewingRun}
              >
                Add all
              </div>
            </PropertySearchContainer>
          </div>
          <PropertyItemListContainer>
            {tableSpinner ? (
              <Spinner />
            ) : (
              <div>
                {propertyList.map((propertyInfo, index) => {
                  return (
                    <PropertyItem
                      key={index}
                      propertyInfo={propertyInfo}
                      onAddButtonClicked={this.onAddButtonClicked}
                      status="select" // properties to be selected
                      addAgain={this.props.viewingRun.selectedPropertyInfos.find(
                        (selectedPropertyInfo) =>
                          selectedPropertyInfo.property.guidID ===
                          propertyInfo.property.guidID,
                      )}
                      viewingInfos={
                        this.state.propertyViewingInfos[
                          propertyInfo.property.guidID
                        ]
                      }
                    />
                  )
                })}
                {offset < total && (
                  <div className={`text-center mt20 ml20`}>
                    {loadMoreSpinner ? (
                      <Spinner />
                    ) : (
                      <Loadmore
                        offset={offset}
                        onLoadMoreClicked={this.onLoadMoreClicked}
                      />
                    )}
                  </div>
                )}
              </div>
            )}

            {this.props.viewingRun.selectedPropertyInfos.length !== 0 && (
              <div className="selected-section mt20">
                <p>Selected</p>
                <PropertyItemListContainer>
                  {this.props.viewingRun.selectedPropertyInfos.map(
                    (propertyInfo, index) => {
                      return (
                        <PropertyItem
                          propertyInfo={propertyInfo}
                          key={index}
                          onDeleteButtonClicked={() =>
                            this.onDeleteButtonClicked(index)
                          }
                          status="selected" // properties already selected
                          sameDayOrfutureViewingInfos={selectSameDayOrFutureViewingInfos(
                            this.state.propertyViewingInfos[
                              propertyInfo.property.guidID
                            ],
                            this.props.viewingRun.startTime,
                          )}
                          conflicted={findConflictedViewing(
                            this.state.propertyViewingInfos[
                              propertyInfo.property.guidID
                            ],
                            this.props.viewingRun.startTime,
                            this.props.viewingRun.viewingDuration,
                            this.props.viewingRun.scheduleBreaks
                              ? this.props.viewingRun.breakDuration
                              : 0,
                          )}
                        />
                      )
                    },
                  )}
                </PropertyItemListContainer>
              </div>
            )}
          </PropertyItemListContainer>
          <ScheduleListContainer
            schedulePreference={schedulePreference}
            startTime={this.props.viewingRun.startTime}
            travelMode={this.props.viewingRun.travelMode}
            scheduleBreaks={this.props.viewingRun.scheduleBreaks}
            breakDuration={this.props.viewingRun.breakDuration}
            viewingDuration={this.props.viewingRun.viewingDuration}
            startPropertyGUID={this.props.viewingRun.startPropertyGUID}
            endPropertyGUID={this.props.viewingRun.endPropertyGUID}
            viewingPropertyInfos={[
              { property: { address: { friendlyName: 'Select' } } },
            ].concat(this.props.viewingRun.selectedPropertyInfos)}
            onPreferenceOptionChanged={this.onPreferenceOptionChanged}
          />

          <GeneralBottomBtns
            onBackButtonClicked={() => history.goBack()}
            onConfirmButtonClicked={this.onPreviewButtonClicked}
            confirmBtnText="Preview"
          />
        </LeftComponent>
      </TwoColumnContainer>
    ) : (
      <NotFound404 />
    )
  }
}

export default ViewingNewRun
