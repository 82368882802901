import React from 'react'

import classnames from 'classnames'
import PropTypes from 'prop-types'

import DownloadAttach from 'app/match/applicationReportPDF/components/DownloadAttach'
import { isDocAutoDeleted } from 'app/sm/docs_helpers'
import * as helpers from 'app/sm/helpers'
import 'app/match/applicationReportPDF/components/InfoList/style.scss'
import { getBusinessNumberLabel } from 'app/sm/helpers'

const propTypes = {
  data: PropTypes.object,
  marked: PropTypes.string,
}

const InfoList = (props) => {
  const { data = {}, addClass, marked, component, companyDetail } = props
  const {
    employer_abn = '',
    employer_trading_name = '',
    type = '',
    employer_name = '',
    address,
  } = companyDetail || {}

  const renderValueDetailApplicationObjectData = (key, value) => {
    if (key === 'attachment') {
      return (
        <div>
          {(value || []).map((attachment, index) => (
            <DownloadAttach
              isAutoDeleted={isDocAutoDeleted(attachment)}
              attachFileName={helpers.trimFileLength(30)(attachment.name)}
              linkLabel={attachment.url}
              key={index}
            />
          ))}
        </div>
      )
    }
    return `${value}`
  }

  return (
    <ul className={`info-list ${addClass ? addClass : ''}`}>
      {Object.keys(data).map((item, index) => {
        return (
          <li className="info-list__item" key={index}>
            <div className="info-list__item--title">
              {item.replace(/_/g, ' ')}
            </div>
            {component}
            {!component && (
              <div
                className={classnames('info-list__item--value', {
                  'text-marked': marked === item,
                })}
              >
                {renderValueDetailApplicationObjectData(item, data[item])}
              </div>
            )}
          </li>
        )
      })}
      {type === 'Self Employed' && (
        <>
          <li className="info-list__item">
            <div className="info-list__item--title">Company Name</div>
            <div className="info-list__item--value">{employer_name}</div>
          </li>
          <li className="info-list__item">
            <div className="info-list__item--title">
              {getBusinessNumberLabel(address, 'Company ')}
            </div>
            <div className="info-list__item--value">{employer_abn}</div>
          </li>
          <li className="info-list__item">
            <div className="info-list__item--title">Company Trading Name</div>
            <div className="info-list__item--value">
              {employer_trading_name}
            </div>
          </li>
        </>
      )}
    </ul>
  )
}

InfoList.propTypes = propTypes

export default InfoList
