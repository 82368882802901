import moment from 'moment'

import { DATE_DAY_SHORT_STRING_MONTH_YEAR } from 'app/utils/datetime/helpers'

export const extractBorrowingInfo = (borrowingData) => {
  if (!borrowingData) return null
  const { borrower } = borrowingData
  return {
    borrower: `${borrower.first_name} ${borrower.last_name}`,
    checkOutDate: moment(borrowingData.issued_date).format(
      DATE_DAY_SHORT_STRING_MONTH_YEAR,
    ),
  }
}
