import { connect } from 'react-redux'

import * as agencyAction from 'app/agency/agency_action'
import {
  addEnquirerWithPropertyAsManager,
  archiveMessage,
  fetchAllPropertyFutureViewings,
  fetchTeamManagerList,
  inviteToApplyEnquirer,
  markMessageAsRead,
  markMessageAsUnread,
} from 'app/bond_cover/agency/agency_actions'
import { resetPropertyFilters } from 'app/dashboard/team_overview_actions'
import {
  fetchEnquirerPreference,
  registerForEnquiryViewing,
  updateEnquirerPreference,
} from 'app/match/viewings/enquiry_viewing_register/enquiry_viewing_register_actions'
import NewMessages from 'app/pages/teams/messages/index'
import { fetchTeams } from 'app/session/session_actions'
import { setBackUrl } from 'app/shared_components/actions'
import { addViewing } from 'app/sm/ppp/viewing_actions'
import { persistManager } from 'app/sm/properties/properties_actions'
import { fetchProperty } from 'app/sm/property_dashboard/property_dashboard_actions'

const mapStateToProps = ({ session, MyProperties }) => ({
  teams: session.teams,
  currentTeam: session.currentTeam,
  currentAgency: session.currentAgency,
  persistedManager: MyProperties.persistedManager,
})

const mapDispatchToProps = (dispatch) => ({
  setBackUrl: (backUrl) => dispatch(setBackUrl(backUrl)),
  fetchTeamManagerList: (teamGUID, success, fail) =>
    dispatch(fetchTeamManagerList(teamGUID, success, fail)),
  archiveMessage: (teamGUID, messageGUID, statusForUpdate) =>
    dispatch(archiveMessage(teamGUID, messageGUID, statusForUpdate)),
  markMessageAsRead: (teamGUID, messageGUID) =>
    dispatch(markMessageAsRead(teamGUID, messageGUID)),
  markMessageAsUnread: (teamGUID, messageGUID) =>
    dispatch(markMessageAsUnread(teamGUID, messageGUID)),
  persistManager: (manager) => dispatch(persistManager(manager)),
  fetchTeams: () => dispatch(fetchTeams()),
  fetchProperty: (id) => dispatch(fetchProperty(id)),
  addEnquirerWithPropertyAsManager: (payload) =>
    dispatch(addEnquirerWithPropertyAsManager(payload)),
  resetPropertyFilters: () => dispatch(resetPropertyFilters()),
  fetchNotes: (ownerGUID, ownerType) =>
    dispatch(agencyAction.fetchNotes(ownerGUID, ownerType)),
  createNote: (payload, ownerGUID, ownerType) =>
    dispatch(agencyAction.createNote(payload, ownerGUID, ownerType)),
  registerForEnquiryViewing: (viewingID, messageID) =>
    dispatch(registerForEnquiryViewing(viewingID, messageID)),
  addViewing: (
    id,
    startdate,
    duration,
    fromScheduler,
    skipDoubleBookingCheck,
    options,
  ) =>
    dispatch(
      addViewing(
        id,
        startdate,
        duration,
        fromScheduler,
        skipDoubleBookingCheck,
        options,
      ),
    ),
  sendPropertyList: (messageGUID) =>
    dispatch(agencyAction.sendPropertyList(messageGUID)),
  fetchEnquirerPreference: (teamSlug, payload) =>
    dispatch(fetchEnquirerPreference(teamSlug, payload)),
  updateEnquirerPreference: (messageId, teamSlug, payload) =>
    dispatch(updateEnquirerPreference(messageId, teamSlug, payload)),
  inviteToApplyEnquirer: (applicationGUID) =>
    dispatch(inviteToApplyEnquirer(applicationGUID)),
  fetchAllPropertyFutureViewings: (teamGUID) =>
    dispatch(fetchAllPropertyFutureViewings(teamGUID)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewMessages)
