import React from 'react'

import { Flex } from 'app/components/design-system-components'
import { FilterDropdown } from 'app/components/design-system-components/list-filters/FilterDropdown'
import {
  buildRenderedFilterConfig,
  filtersIds,
} from 'app/features/teams/messages/table-filters/filters.config'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { isAccountPremier } from 'app/utils/accounts/helpers'

const defaultTriggerProps = {
  mr: theme.space[3] + 'px',
  mb: theme.space[3] + 'px',
}

export const MessagesFilters = ({
  filters,
  updateFilter,
  currentTeam,
  managers,
  plan,
  ...containerProps
}) => {
  const {
    [filtersIds.manager]: managerFilterConfig,
    [filtersIds.property]: propertyFilterConfig,
    [filtersIds.propertyType]: propertyTypeFilterConfig,
    [filtersIds.enquirerActions]: enquirerActionsFilterConfig,
    [filtersIds.readStatus]: readStatusFilterConfig,
    [filtersIds.leadSource]: leadSourceFilterConfig,
  } = buildRenderedFilterConfig(filters, updateFilter)

  const isLeadDisabled = !isAccountPremier(currentTeam.accountType)

  return (
    <Flex flexWrap="wrap" mt={theme.space[3] + 'px'} {...containerProps}>
      <FilterDropdown
        filterConfig={managerFilterConfig}
        filterBodyProps={{ managers }}
        triggerCmpProps={defaultTriggerProps}
      />

      {/* hiding property filter until the confusion about functionality difference between search and filters discussed */}
      {/*<FilterDropdown*/}
      {/*  filterConfig={propertyFilterConfig}*/}
      {/*  filterBodyProps={{ teamId }}*/}
      {/*  triggerCmpProps={defaultTriggerProps}*/}
      {/*/>*/}

      <FilterDropdown
        filterConfig={propertyTypeFilterConfig}
        triggerCmpProps={defaultTriggerProps}
      />
      <FilterDropdown
        filterConfig={enquirerActionsFilterConfig}
        triggerCmpProps={defaultTriggerProps}
      />
      <FilterDropdown
        filterConfig={readStatusFilterConfig}
        triggerCmpProps={defaultTriggerProps}
      />
      <FilterDropdown
        filterConfig={leadSourceFilterConfig}
        triggerCmpProps={defaultTriggerProps}
        filterBodyProps={{ disabled: isLeadDisabled }}
        disabled={isLeadDisabled}
      />
    </Flex>
  )
}
