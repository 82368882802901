import React, { useState } from 'react'

import { connect } from 'react-redux'

import { HorizontalLine } from 'app/components/design-system-components'
import { TabsHeader } from 'app/components/design-system-components/TabsHeader'
import { ApplicationActivityTab } from 'app/features/teams/applications/application-activities/ApplicationSideTabs/ApplicationActivityTab'
import { ApplicationNotesTab } from 'app/features/teams/applications/application-activities/ApplicationSideTabs/ApplicationNotesTab'
import { isApplicantCommunicationLogsEnabled } from 'config/features'

const ApplicationSideTabs = ({
  currentTeam,
  notesLoading,
  notes,
  applicationId,
  applicantId,
  changeApplication,
  showNotes,
  teamSlug = '',
}) => {
  const notesTab = {
    key: 'notes',
    header: 'Notes',
  }
  const activitiesTab = {
    key: 'activity',
    header: 'Activity',
  }
  const tabsList = [notesTab, activitiesTab]
  const [notesSelected, setNotesSelected] = useState(true)
  const [activitySelected, setActivitySelected] = useState(false)
  const [selectedTab, setSelectedTab] = useState(notesTab)

  const changeTab = (newSelectedTab) => {
    setSelectedTab(newSelectedTab)
    switch (newSelectedTab.key) {
      case notesTab.key:
        setNotesSelected(true)
        setActivitySelected(false)
        break
      case activitiesTab.key:
        setNotesSelected(false)
        setActivitySelected(true)
        break
    }
  }

  const isCommunicationCategoriesEnabled = isApplicantCommunicationLogsEnabled(
    currentTeam?.slug,
  )

  return (
    <>
      <TabsHeader
        tabsOptions={tabsList}
        selectedTab={selectedTab}
        onTabChanged={changeTab}
      />
      <HorizontalLine />
      {notesSelected && (
        <ApplicationNotesTab
          notes={notes}
          notesLoading={notesLoading}
          onApplicationChange={changeApplication}
          showNotes={showNotes}
          teamSlug={teamSlug}
        />
      )}
      {activitySelected && (
        <ApplicationActivityTab
          applicationId={applicationId}
          applicantId={applicantId}
          disableCommunicationLogs={!isCommunicationCategoriesEnabled}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
})

export default connect(mapStateToProps)(ApplicationSideTabs)
