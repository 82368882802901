import React from 'react'

import { Box, Select } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const InvProfileSelect = 0
export const InvProfileExistingInvestor = 1
export const InvProfilePlanning = 2
export const InvProfileBothExistingAndPlanned = 3

const investmentProfileOptions = [
  { id: InvProfileSelect, name: 'Select investment profile' },
  { id: InvProfileExistingInvestor, name: 'Existing investments' },
  { id: InvProfilePlanning, name: 'Planned investments' },
  {
    id: InvProfileBothExistingAndPlanned,
    name: 'Existing and planned investments',
  },
]

function SelectInvestmentProfile({
  propertyInvestmentsProfile,
  onChangeInvestmentProfile,
  propertyInvestmentsProfileError,
}) {
  return (
    <Box
      height={theme.baseSpace * 5 + 'px'}
      mt={theme.baseSpace + 'px'}
      mb={theme.baseSpace * 3 + 'px'}
      width="fit-content"
    >
      <Select
        options={investmentProfileOptions}
        onSelectChange={onChangeInvestmentProfile}
        value={propertyInvestmentsProfile}
        showSelectLabel={false}
      />
      <error>{propertyInvestmentsProfileError}</error>
    </Box>
  )
}

export default SelectInvestmentProfile
