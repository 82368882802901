import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import { LoadingSection } from 'app/components/design-system-components'
import { TextArea } from 'app/components/design-system-components/inputs'
import * as Display from 'app/components/display/display'
import {
  getRenterAboutUs,
  saveRenterAboutUs,
} from 'app/services/http/renter/profile'
import Header from 'app/shared_components/rental_reputation_header_connection'
import { urlTo } from 'app/sm/helpers'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

const AboutUs = ({ history }: any) => {
  const [savedContent, setSavedContent] = useState<string>('')
  const [content, setContent] = useState<string>('')
  const { loadingStates, loadingStatesHelpers } = useLoadingStates()

  useEffect(() => {
    loadingStatesHelpers.startLoading()
    loadRenterAboutUs()
  }, [])

  const loadRenterAboutUs = () => {
    return getRenterAboutUs()
      .then((resp: any) => {
        if (!!resp?.about) {
          setSavedContent(resp?.about)
          setContent(resp?.about)
        }
        loadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err: any) => {
        loadingStatesHelpers.setError(err)
      })
  }

  const submitAboutUs = () => {
    if (content === savedContent) {
      proceed()
      return
    }
    loadingStatesHelpers.startLoading()
    return saveRenterAboutUs(content)
      .then(() => {
        loadingStatesHelpers.markDoneSuccessfully()
        proceed()
      })
      .catch((err: any) => {
        loadingStatesHelpers.setError(err)
      })
  }

  const proceed = () => {
    history.push(urlTo('profileAvatar'))
  }

  return (
    <Display.CenterContentContainer componentClass="width100">
      <RentalReputationHeaderReminder title="About us" />
      <Header
        title="About us"
        message="Save a reuseable introduction about yourself or household. Update it at any time when starting an application."
      />
      <LoadingSection showChildrenWhenError loadingState={loadingStates}>
        <TextArea
          containerProps={{
            padding: '14px 18px',
          }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setContent(e.target.value)
          }}
          value={content}
          placeholder="Describe your household in a few sentences"
          disabled={loadingStates.state === loadingStatesIds.LOADING}
          rows={4}
        />
      </LoadingSection>
      <Display.BottomContentContainer>
        <Display.CenterContentContainer componentClass="width100">
          <div className="submit-button-container">
            <BottomButton
              clickNextFunction={() => submitAboutUs()}
              btnText="Next: Profile Picture"
              customisedBackClick={() => history.push(urlTo('BackgroundCheck'))}
              bottomButtonsClass="pt0 mt0 summary-bottom-button"
              nextBtnClass={`summary-green-button-class`}
              backButtonClass="summary-grey-button-class"
              nextButtonPositionClass="pr20 pl0"
              showArrow={false}
            />
          </div>
        </Display.CenterContentContainer>
      </Display.BottomContentContainer>
    </Display.CenterContentContainer>
  )
}

export default connect()(AboutUs)
