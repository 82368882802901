import { Box, Button } from 'app/components/design-system-components'
import TableComponent from 'app/components/design-system-components/Table'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { currencyFormat } from 'app/utils/numbers/helpers'

export const VacancyTable = ({ vacancyListData, onManagerDetails }) => {
  const colDefs = [
    {
      accessor: 'managerName',
      Header: 'Team Member',
      Cell: ({
        row: {
          original: { id, managerName },
        },
      }) => (
        <Button
          onClick={() => onManagerDetails(id)}
          variant="linkBlue"
          textAlign="left"
        >
          {managerName}
        </Button>
      ),
    },
    {
      accessor: 'totalProperties',
      Header: 'Total Listings',
    },
    {
      accessor: 'avgDaysOnMarket',
      Header: 'DoM',
      Cell: ({ value }) => Math.round(value),
    },
    {
      accessor: 'vacantProperties',
      Header: 'Vacant Listings',
    },
    {
      accessor: 'lessWeekVacantProperties',
      Header: 'Vacant Properties 0-7 days',
    },
    {
      accessor: 'moreWeekVacantProperties',
      Header: 'Vacant Properties >7 days',
    },
    {
      accessor: 'vacantDays',
      Header: 'Vacancy',
    },
    {
      accessor: 'lostRent',
      Header: 'Lost Rent',
      Cell: ({ value }) => currencyFormat(value, 0),
    },
    {
      accessor: 'newManagement',
      Header: 'New Biz',
    },
    {
      accessor: 'receivedApps',
      Header: 'App Received',
    },
    {
      accessor: 'totalEnquiries',
      Header: 'Enquiries',
    },
  ]

  return (
    <>
      <Box width="100%" overflowX="auto">
        <TableComponent
          columns={colDefs}
          headerBgColor={theme.colors.white}
          dataToBeEntered={vacancyListData}
          textColor={theme.colors.gray600}
          showHeaderBottomBorder
          showRowBottomBorder
          thPadding={`${theme.space[5]}px`}
          fontSize={theme.fontSizes.pRegular14}
          verticalAlignTop
          sortable
        />
      </Box>
    </>
  )
}
