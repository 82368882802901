import styled from 'styled-components'

const StyledBackButton = styled.div`
  color: ${(props) => props.theme.colors.gray};
  i {
    margin-right: 8px;
  }
  display: flex;
  align-items: center;
  padding-top: ${(props) => props.theme.space[5]}px;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes[6]}px;
`
export default StyledBackButton
