import styled from 'styled-components'

const StyledActivitySidebarContainer = styled.div`
  position: fixed;
  right: 0;
  z-index: 1000000 !important;
  overflow: hidden;
  height: 100vh;
  background: #ffffff;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.25);
  width: 327px;

  padding: 16px 0 0px 22.5px;

  .sidebar {
    overflow-x: hidden;
    position: fixed;
    height: 100%;
    padding-right: 22.5px;

    .activity-feed-header-container {
      position: sticky;
      top: 0;
      background-color: white;

      .activity-header {
        h3 {
          font-size: 26px;
          font-weight: 700;
        }

        svg {
          cursor: pointer;
          width: 18px !important;
          height: 18px !important;
        }
      }
    }

    .multi-select-container-wrapper,
    .multi-select-option-container {
      height: 30px !important;

      .text-align-center {
        padding: 0 !important;
      }
    }

    .tab-option {
      height: 24px !important;
      display: flex !important;
      padding: 0 !important;
      align-items: center !important;
      justify-content: center !important;
      font-size: 14px !important;
      text-align: center;
    }
  }
`

export default StyledActivitySidebarContainer
