import styled from 'styled-components'
import { layout } from 'styled-system'

import Flex from 'app/components/design-system-components/Flex'
import { getters } from 'app/components/design-system-components/theme'

const ProgressBarContainer = styled(Flex)`
  width: 100%;
  height: 21px;
  overflow: hidden;

  .step {
    display: flex;
    flex: 1 1 100%;
    background-color: ${getters.color('paleBlue')};

    &--checked {
      background-color: ${getters.color('deepBlue')};
    }
  }

  .step + .step {
    border-left: solid 1px ${getters.color('skyBlue')};
  }

  ${layout}
`

export const ProgressBar = ({
  totalStepsCount,
  lastCheckedStep = 0,
  ...containerProps
}) => {
  const stepsArr = Array(totalStepsCount)
    .fill(null)
    .map((v, index) => {
      const isChecked = index < lastCheckedStep
      return {
        key: `step-${index}`,
        isChecked,
        className: isChecked ? 'step step--checked' : 'step',
      }
    })

  if (!stepsArr || !stepsArr.length) return ''
  return (
    <ProgressBarContainer {...containerProps}>
      {stepsArr.map((stepConfig) => (
        <div key={stepConfig.key} className={stepConfig.className} />
      ))}
    </ProgressBarContainer>
  )
}
