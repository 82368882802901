import { generateUniqueId } from 'app/utils/strings/helpers'

const appendActorToAction = (actionStr) => `PENDING_TASKS/${actionStr}`

export const actionTypes = {
  pushTask: appendActorToAction('PUSH_TASK'),
  popTask: appendActorToAction('POP_TASK'),
  startSyncing: appendActorToAction('START_SYNCING'),
  taskSynced: appendActorToAction('TASK_SYNCED'),
  taskFailed: appendActorToAction('TASK_FAILED'),
  retrySync: appendActorToAction('RETRY_TASK'),
  updateSyncingTask: appendActorToAction('UPDATE_SYNCING_TASK'),
  taskSkipped: appendActorToAction('TASK_SKIPPED'),
}

export const actionCreators = {
  pushPendingTask: (action, actionName) => ({
    type: actionTypes.pushTask,
    task: {
      ...action,
      __pendingTaskId: generateUniqueId(),
      __pendingTaskTrials: 0,
      __pendingTaskName: actionName,
    },
  }),
  popTask: (task) => ({
    type: actionTypes.popTask,
    task,
  }),
  startSyncing: () => ({
    type: actionTypes.startSyncing,
  }),
  taskSynced: (task) => ({
    type: actionTypes.taskSynced,
    task,
  }),
  taskFailed: (task) => ({
    type: actionTypes.taskFailed,
    task,
  }),
  retrySync: () => ({
    type: actionTypes.retrySync,
  }),
  updateSyncingTask: (task) => ({
    type: actionTypes.updateSyncingTask,
    task,
  }),
  taskSkipped: (task) => ({
    type: actionTypes.taskSkipped,
    task,
  }),
}

const defaultState = {
  queue: [],
  syncingTask: null,
}

export const pendingTasks = (state = defaultState, action) => {
  const { type, ...payload } = action
  switch (type) {
    case actionTypes.pushTask: {
      const { task } = payload
      console.log({ task })
      return {
        ...state,
        queue: [...state.queue, task],
      }
    }
    case actionTypes.popTask: {
      const updatedQueue = state.queue.slice(1)
      return {
        ...state,
        queue: updatedQueue,
      }
    }

    case actionTypes.updateSyncingTask: {
      const { task } = payload
      return {
        ...state,
        syncingTask: task,
      }
    }

    default:
      return state
  }
}
