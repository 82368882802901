import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getViewings = () => {
  const url = `${apiBaseUrl}sm/viewingregistration/summary`

  return fetch(url, {
    credentials: 'include',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (viewings) => ({ ok: true, responseText: '', viewings }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const addFavouriteViewingFlag = (id) => {
  const url = `${apiBaseUrl}sm/viewingregistration/${id}/favourite`

  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (registerViewing) => ({ ok: true, registerViewing }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const remoteFavouriteViewingFlag = (id) => {
  const url = `${apiBaseUrl}sm/viewingregistration/${id}/favourite`

  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (registerViewing) => ({ ok: true, registerViewing }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const assignViewing = (teamId, viewingID, teamMemberID) => {
  const url = `${apiBaseUrl}sm/team/${teamId}/viewing/${viewingID}/assign/${teamMemberID}`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const fetchAssignedTeamMembers = (teamGUID, viewingGUID) => {
  const url = `${apiBaseUrl}sm/team/${teamGUID}/viewing/${viewingGUID}/assignees`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (teamMemberInfo) => ({ ok: true, teamMemberInfo }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
export const deleteAssignedTeamMember = (teamId, viewingId, teamMemberId) => {
  const url = `${apiBaseUrl}sm/team/${teamId}/viewing/${viewingId}/remove/${teamMemberId}`

  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const updateTeamMemberRelation = (teamId, viewingID, contactID, req) => {
  const url = `${apiBaseUrl}sm/team/${teamId}/viewing/${viewingID}/inspector/${contactID}`
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(req),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getManagersListForViewing = (teamGUID) => {
  const url = `${apiBaseUrl}sm/team/${teamGUID}/members`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (managerList) => ({ ok: true, managerList }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
