import React from 'react'

import qs from 'qs'
import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import * as Display from 'app/components/display/display'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { getRegisterUrl } from 'app/services/http/users'
import EligibilityCriteria from 'app/shared_components/eligibility_criteria'
import { isMobile } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import {
  capitalizeFirstLetter,
  isApplicantApplied,
  isApplicationDeclined,
  isApplicationPMWithdrawn,
  isApplicationWithdrawn,
  isDraftApplication,
  urlTo,
} from 'app/sm/helpers'
import PropertyBasic from 'app/sm/property_details/property_basic'
import { RegisterAgencyIDKey, SessionStorageUtils } from 'app/storage_utils'
import { formatStdDateStamp } from 'app/utils/datetime/helpers'
import { EASY_BONDPAY_LEARN_MORE_LINK } from 'app/utils/easybondpay/helpers'

// Here the key :BOOLEAN represents, does the renter already applied for the property
const applyButtonParoles = new Map()
  .set('New', 'Start Application')
  .set('SecondaryNew', 'Start Application (Joint application)')
  .set('Applied', `You've already applied`)
  .set('SecondaryApplied', `You've already applied (Joint application)`)
  .set('Draft', 'Continue your application')
  .set('isAcceptingApplications', 'Applications closed')
  .set('Ballots', 'Enter Ballot')

const CRITERIA_NOT_SET = 0
const DONT_SHOW_RULES_AT_START = 0

class PropertyInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      enabledModals: {
        eligibilityCriteria: false,
      },
      agencyRules: {},
    }
  }

  componentDidMount() {
    document
      .querySelector('.app-content')
      .addEventListener('scroll', this.handleScroll)
    const { checkForAgencyRules, agencyID } = this.props
    checkForAgencyRules(agencyID).then(({ applicationEligibility } = {}) => {
      this.setState({
        ...this.state,
        agencyRules: applicationEligibility,
      })
    })
    SessionStorageUtils.removeItem('forwardUrl')
    SessionStorageUtils.removeItem(RegisterAgencyIDKey)
  }

  componentWillUnmount() {
    document
      .querySelector('.app-content')
      .removeEventListener('scroll', this.handleScroll)
  }

  handlePrimaryButtonClick = (applyLink) => {
    window.location.href = applyLink
  }

  handleScroll = (event) => {
    const app_content = document.querySelector('.app-content')
    const header = document.querySelector(
      '.sm-property-details__property-header',
    )
    const element = document.querySelector('.rank-wrapper')
    const element_top = 30

    if (app_content.scrollTop > header.clientHeight) {
      element.style.top = `${
        app_content.scrollTop - header.clientHeight + element_top
      }px`
    } else {
      element.style.top = `${element_top}px`
    }
  }

  toggleModal = (toggleField) => {
    this.setState({
      enabledModals: {
        [toggleField]: !this.state.enabledModals[toggleField],
      },
    })
  }

  redirectApplyButton = () => {
    const { currentUser, summary, property } = this.props
    const { guidID: userGuidId, isVerified } = currentUser
    const applicant = summary && summary.applicant
    const applied = isApplicantApplied(applicant)
    const isUserLoggedInAndVerified = userGuidId && isVerified
    const applyConfirmLink =
      summary.application &&
      urlTo('applyConfirmationSummary', {
        applicationId: summary.application.guidID,
      })

    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { referrer_id, referrer_group, referrer_url } = curQueries
    if (isUserLoggedInAndVerified) {
      history.push(
        applied
          ? applyConfirmLink
          : this.getApplyLink({
              referrer_id,
              referrer_group,
              referrer_url,
            }),
      )
    } else {
      const { jointApplicationID, ...referrer } = curQueries

      const forwardUrl = jointApplicationID
        ? urlTo('application', {
            applicationId: jointApplicationID,
            referrer,
          })
        : urlTo('apply', { propertyId: this.getOfferID(property), ...referrer })

      SessionStorageUtils.setItem('forwardUrl', forwardUrl)
      SessionStorageUtils.setItem(RegisterAgencyIDKey, property.agencyID)

      getRegisterUrl(property.agencyID)
        .then(({ url }) => {
          window.location.replace(url)
        })
        .catch((err) => {
          history.push(urlTo('join'))
        })
    }
  }

  getApplyLink = (payload) => {
    const { summary, property } = this.props
    const hasUserAlreadyStartedApplication =
      summary.application !== undefined && summary.application !== null
    const application = summary && summary.application
    const isWithdrawnOrDeclined =
      application &&
      (isApplicationWithdrawn(application) ||
        isApplicationPMWithdrawn(application) ||
        isApplicationDeclined(application))

    return (hasUserAlreadyStartedApplication ||
      isDraftApplication(application)) &&
      !isWithdrawnOrDeclined
      ? urlTo('application', {
          applicationId: application.guidID,
          ...payload,
        })
      : urlTo('apply', { propertyId: this.getOfferID(property), ...payload })
  }

  getOfferID = (property) => {
    const activeOffer = (property.offers || []).find((o) => o.isActive) || {}
    return activeOffer.guidID
  }

  render() {
    const {
      property,
      summary,
      currentUser,
      logout,
      isEasyBondPayEnabled,
      propertyDisclosure,
      renderKeepSafeAndBGCheck,
    } = this.props
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    let isSecondaryApplication = false
    if (curQueries && curQueries.isSecondaryApplicantInvite === 'true') {
      isSecondaryApplication = true
    }
    const hasUserAlreadyStartedApplication =
      summary.application !== undefined && summary.application !== null
    const applicant = summary && summary.applicant
    const application = summary && summary.application
    const applied = isApplicantApplied(applicant)
    const isWithdrawnOrDeclined =
      application &&
      (isApplicationWithdrawn(application) ||
        isApplicationPMWithdrawn(application) ||
        isApplicationDeclined(application))
    const { brandingLogoURL = '' } = summary || {}
    const { guidID: userGuidId, isVerified } = currentUser
    let applyButtonText
    let teamSlug
    const { enabledModals, agencyRules = {} } = this.state
    const { enabled = 0, showRulesAtStart, criteria } = agencyRules

    const isUserLoggedInAndVerified = userGuidId && isVerified

    const isAcceptingApplications = summary.isAcceptingApplications

    const activeOffer = (property.offers || []).find((o) => o.isActive) || {}
    const { weeklyRentDisplay, weeklyRent, ballotsEnabled } = activeOffer

    if (hasUserAlreadyStartedApplication || applied) {
      if (isDraftApplication(summary.application)) {
        applyButtonText = applyButtonParoles.get('Draft')
      } else {
        if (applicant && applicant.guidID && applicant.isPrimary === false) {
          applyButtonText = applied
            ? `${capitalizeFirstLetter(
                currentUser.firstName,
              )}, ${applyButtonParoles.get('SecondaryApplied')}`
            : applyButtonParoles.get('SecondaryNew')
        } else {
          applyButtonText = `${capitalizeFirstLetter(
            currentUser.firstName,
          )}, ${applyButtonParoles.get('Applied')}`
        }
      }
    } else {
      if (!isAcceptingApplications) {
        applyButtonText = applyButtonParoles.get('isAcceptingApplications')
      } else if (isSecondaryApplication) {
        applyButtonText = applyButtonParoles.get('SecondaryNew')
      } else {
        if (ballotsEnabled) {
          applyButtonText = applyButtonParoles.get('Ballots')
        } else {
          applyButtonText = applyButtonParoles.get('New')
        }
        teamSlug = property.teamSlug
      }
    }
    if (isWithdrawnOrDeclined) {
      applyButtonText = applyButtonParoles.get('New')
    }

    let address = ''
    let suburb = ''

    if (property.address) {
      if (property.address.unitNumber) {
        address = `${property.address.unitNumber}/${property.address.streetNumber} ${property.address.streetName}`
      } else {
        address = `${property.address.streetNumber} ${property.address.streetName}`
      }
      suburb = `${property.address.suburb} ${property.address.state} ${property.address.postcode}`
    }

    const displayRent =
      activeOffer &&
      (weeklyRentDisplay !== ''
        ? weeklyRentDisplay
        : weeklyRent > 0
        ? `$${weeklyRent} p/w`
        : '0')
    let usernameFormatted = ''

    if (summary.manager.firstName) {
      usernameFormatted += summary.manager.firstName
    }

    if (summary.manager.lastName) {
      usernameFormatted += ` ${summary.manager.lastName[0]}.`
    }

    let availableFrom = ''
    let availableDateFromFormatted = ''
    if (activeOffer) {
      availableFrom = activeOffer.availableFrom
      availableDateFromFormatted = formatStdDateStamp(availableFrom)
    }

    const applyConfirmLink =
      summary.application &&
      urlTo('applyConfirmationSummary', {
        applicationId: summary.application.guidID,
      })

    const { carports = 0, parking = 0, garages = 0 } = property
    const carCountSpaces = { garages, parking, carports }
    let showInfoForSecondaryApplicant = false
    if (isUserLoggedInAndVerified && applicant && currentUser) {
      if (applicant.email && applicant.email === currentUser.email) {
        showInfoForSecondaryApplicant = true
      }
    }
    const {
      hasPropertyDisclosure = false,
      propertyDisclosure: disclosures = {},
    } = propertyDisclosure
    return (
      <div className="sm-property-details__property_info">
        <div className="two-col-box sm55 address-rank">
          <PropertyBasic
            property={property}
            address={address}
            brandingLogoURL={brandingLogoURL}
            suburb={suburb}
            rent={displayRent}
            carCountSpaces={carCountSpaces}
            manager={summary.manager}
            usernameFormatted={usernameFormatted}
            offerDataStyles={{ fontSize: theme.fontSizes.pExtraLarge18 }}
            availabilityDate={availableDateFromFormatted}
          />
          <div className="col-second rank">
            <div className="rank-wrapper">
              {applyButtonText === applyButtonParoles.get('New') &&
              enabled > CRITERIA_NOT_SET &&
              showRulesAtStart > DONT_SHOW_RULES_AT_START ? (
                isAcceptingApplications ? (
                  <button
                    className={`${
                      !applied &&
                      !isDraftApplication(summary.application) &&
                      `rental-application-start-application`
                    } btn p0`}
                    id={teamSlug}
                    onClick={() => this.toggleModal('eligibilityCriteria')}
                  >
                    {applyButtonText}
                  </button>
                ) : (
                  <button disabled={true}>{applyButtonText}</button>
                )
              ) : applied ? (
                <Link
                  className={`${
                    !applied &&
                    !isDraftApplication(summary.application) &&
                    `rental-application-start-application`
                  } btn p0`}
                  id={teamSlug}
                  to={
                    applied && !isWithdrawnOrDeclined
                      ? applyConfirmLink
                      : this.getApplyLink()
                  }
                >
                  {applyButtonText}
                </Link>
              ) : !isAcceptingApplications && !showInfoForSecondaryApplicant ? (
                <button disabled={true}>{applyButtonText}</button>
              ) : (
                <button
                  className={`${
                    !applied &&
                    !isDraftApplication(summary.application) &&
                    `rental-application-start-application`
                  } btn p0`}
                  id={teamSlug}
                  onClick={this.redirectApplyButton}
                >
                  {applyButtonText}
                </button>
              )}
              {applied && (
                <div className="logout-link-container">
                  <span>Not you?&nbsp;</span>
                  <span className="linkStyle-blue linkStyle" onClick={logout}>
                    Log out
                  </span>
                </div>
              )}
              {!isUserLoggedInAndVerified && isSecondaryApplication && (
                <div className="logout-link-container">
                  <span>Invited to apply?&nbsp;</span>
                  <span
                    className="linkStyle-blue linkStyle"
                    onClick={() => {
                      SessionStorageUtils.setItem(
                        'forwardUrl',
                        window.location.pathname + window.location.search,
                      )
                      history.push(urlTo('join'))
                    }}
                  >
                    Log In
                  </span>
                </div>
              )}
              {isEasyBondPayEnabled && (
                <div className="mt30 bts pt30">
                  <p className="mb10">
                    Make moving easier with an easyBondpay loan.{' '}
                    <a
                      href={EASY_BONDPAY_LEARN_MORE_LINK}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Learn more
                    </a>
                    .
                  </p>
                  <p className="mb0">
                    <ul>
                      <li>Fast approval, often same day</li>
                      <li>Competitive rates</li>
                      <li>Easy repayment terms</li>
                    </ul>
                  </p>
                </div>
              )}
              {!isMobile() && renderKeepSafeAndBGCheck()}
            </div>
          </div>
        </div>
        <ReactTooltip className="tooltip" />
        {enabledModals.eligibilityCriteria && (
          <Display.Modal
            toggleModal={() => this.toggleModal('eligibilityCriteria')}
            title="This property manager uses Eligibility Criteria"
            primaryButtonAction={() =>
              this.handlePrimaryButtonClick(
                applied ? applyConfirmLink : this.getApplyLink(),
              )
            }
            primaryButtonLabel="Continue"
            secondaryButtonAction={() =>
              this.toggleModal('eligibilityCriteria')
            }
            secondaryButtonLabel="Go back"
            buttonsContainerClass="row ml0 mr0"
            primaryButtonClassName="col-sm-6"
            secondaryButtonClassName="col-sm-6"
            body={<EligibilityCriteria criterion={criteria} />}
          />
        )}
      </div>
    )
  }
}

export default PropertyInfo
