export const renterApplicationStatusLabels = {
  primary: {
    0: {
      buttonLabel: 'Resume Application',
      statusPillText: 'Draft',
      statusPillBorderColor: '#686D71',
    },
    1: {
      buttonLabel: 'View Application',
      statusPillText: 'Submitted',
      statusPillBorderColor: '#37C977',
    },
    2: {
      buttonLabel: 'Shortlisted',
      statusPillText: 'Shortlisted',
      statusPillBorderColor: '#37C977',
    },
    3: {
      buttonLabel: 'View Offer',
      statusPillText: 'Offered',
      statusPillBorderColor: '#37C977',
    },
    4: {
      buttonLabel: 'Unsuccessful',
      statusPillText: 'Unsuccessful',
      statusPillBorderColor: '#CA5442',
    },
    5: {
      buttonLabel: 'Withdrawn',
      statusPillText: '',
      statusPillBorderColor: '#37C977',
    },
    6: {
      buttonLabel: 'Accepted',
      statusPillText: 'Accepted',
      statusPillBorderColor: '#37C977',
    },
    7: {
      buttonLabel: 'Leased',
      statusPillText: 'Leased',
      statusPillBorderColor: '#37C977',
    },
    8: {
      buttonLabel: 'Interested',
      statusPillText: 'Interested',
      statusPillBorderColor: '#37C977',
    },
    9: {
      buttonLabel: 'View Application',
      statusPillText: 'Submitted',
      statusPillBorderColor: '#37C977',
    },
    10: {
      buttonLabel: 'View Application',
      statusPillText: 'Submitted',
      statusPillBorderColor: '#37C977',
    },
    11: {
      buttonLabel: 'View Application',
      statusPillText: 'Submitted',
      statusPillBorderColor: '#37C977',
    },
    12: {
      buttonLabel: 'View Application',
      statusPillText: 'Submitted',
      statusPillBorderColor: '#37C977',
    },
    13: {
      buttonLabel: 'Payment Pending',
      statusPillText: 'Payment Pending',
      statusPillBorderColor: '#ffaa4d',
    },
    14: {
      buttonLabel: 'View Application',
      statusPillText: 'Submitted',
      statusPillBorderColor: '#37C977',
    },
    15: {
      buttonLabel: 'Withdrawn by Manager',
      statusPillText: 'Withdrawn by Manager',
      statusPillBorderColor: '#ffaa4d',
    },
    16: {
      buttonLabel: 'Application Unsuitable',
      statusPillText: 'Declined',
      statusPillBorderColor: '#CA5442',
    },
    17: {
      buttonLabel: 'View Application',
      statusPillText: 'Application Approved',
      statusPillBorderColor: '#37C977',
    },
    18: {
      buttonLabel: 'Deposit Received',
      statusPillText: 'Deposit Received',
      statusPillBorderColor: '#37C977',
    },
    19: {
      buttonLabel: 'With Owner',
      statusPillText: 'With Owner',
      statusPillBorderColor: '#37C977',
    },
    20: {
      buttonLabel: 'Collecting References',
      statusPillText: 'Collecting References',
      statusPillBorderColor: '#37C977',
    },
  },
  secondary: {
    0: {
      buttonLabel: 'Resume Application',
      statusPillText: 'Draft (Secondary)',
      statusPillBorderColor: '#686D71',
    },
    1: {
      buttonLabel: 'Submit Application',
      statusPillText: 'Invited',
      statusPillBorderColor: '#686D71',
    },
    2: {
      buttonLabel: 'Submit Application',
      statusPillText: 'Invited',
      statusPillBorderColor: '#686D71',
    },
    3: {
      buttonLabel: 'Submit Application',
      statusPillText: 'Invited',
      statusPillBorderColor: '#686D71',
    },
    4: {
      buttonLabel: 'Unsuccessful',
      statusPillText: 'Unsuccessful',
      statusPillBorderColor: '#CA5442',
    },
    5: {
      buttonLabel: 'Withdrawn',
      statusPillText: '',
      statusPillBorderColor: '#37C977',
    },
    6: {
      buttonLabel: 'View Application',
      statusPillText: 'Accepted (Secondary)',
      statusPillBorderColor: '#37C977',
    },
    7: {
      buttonLabel: 'View Application',
      statusPillText: 'Leased (Secondary)',
      statusPillBorderColor: '#37C977',
    },
    8: {
      buttonLabel: 'Interested',
      statusPillText: 'Interested (Secondary)',
      statusPillBorderColor: '#37C977',
    },
    9: {
      buttonLabel: 'View Application',
      statusPillText: 'Invited',
      statusPillBorderColor: '#686D71',
    },
    10: {
      buttonLabel: 'View Application',
      statusPillText: 'Invited',
      statusPillBorderColor: '#686D71',
    },
    11: {
      buttonLabel: 'View Application',
      statusPillText: 'Invited',
      statusPillBorderColor: '#686D71',
    },
    12: {
      buttonLabel: 'View Application',
      statusPillText: 'Invited',
      statusPillBorderColor: '#686D71',
    },
    13: {
      buttonLabel: 'View Application',
      statusPillText: 'Invited',
      statusPillBorderColor: '#686D71',
    },
    14: {
      buttonLabel: 'View Application',
      statusPillText: 'Invited',
      statusPillBorderColor: '#686D71',
    },
    15: {
      buttonLabel: 'Withdrawn by Manager',
      statusPillText: 'Withdrawn by Manager',
      statusPillBorderColor: '#ffaa4d',
    },
    16: {
      buttonLabel: 'Application Unsuitable',
      statusPillText: 'Declined',
      statusPillBorderColor: '#CA5442',
    },
    17: {
      buttonLabel: 'View Application',
      statusPillText: 'Application Approved',
      statusPillBorderColor: '#37C977',
    },
    18: {
      buttonLabel: 'Deposit Received',
      statusPillText: 'Deposit Received',
      statusPillBorderColor: '#686D71',
    },
    19: {
      buttonLabel: 'With Owner',
      statusPillText: 'With Owner',
      statusPillBorderColor: '#37C977',
    },
    20: {
      buttonLabel: 'Collecting References',
      statusPillText: 'Collecting References',
      statusPillBorderColor: '#37C977',
    },
  },
}

export const Invited = 'Invited'
