import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import AvatarPlaceholder from 'app/match/applicationReportPDF/assets/images/avatar-placeholder.jpg'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  iconsCustom,
  tagPropType,
} from 'app/match/applicationReportPDF/helpers/utils'

import 'app/match/applicationReportPDF/components/Card/style.scss'

const { space, display, height, width, fontSizes, lineHeights } = theme

const CardWrapper = styled.div`
  margin-top: ${space[7]}px;
  .pdf-card {
    margin-bottom: ${space[2]}px;
    display: ${display.flex};
    align-items: center;
    justify-content: flex-start;

    .card-media {
      display: ${display.flex};
      overflow: hidden;
      .image-small {
        width: ${width[4]}px;
        height: ${height[4]}px;
      }

      .image-large {
        width: ${width[6]}px;
        height: ${height[6]}px;
      }

      .image-rounded {
        border-radius: 50%;
      }

      .icon {
        font-size: ${fontSizes[5]}px;
        color: var(--pdf-gray);
      }
    }

    .pdf-card-title {
      margin-left: 8px;

      h2 {
        margin: 0;
      }
    }

    &.card-bottom-bordered {
      padding-bottom: ${space[2]};
      border-bottom: 1px solid var(--border-color);
    }

    .encryption {
      font-size: ${fontSizes[2]}px;
      max-width: 70px;
      margin-left: auto;
      line-height: ${lineHeights.encrption};
      color: var(--pdf-gray);
      flex-wrap: nowrap;

      .icon {
        font-size: ${fontSizes[4]}px;
        margin-right: ${space[3]}px;
      }
    }
  }
  @media print {
    break-after: avoid !important;
    page-break-after: avoid !important;
    break-inside: avoid;
  }
`

const propTypes = {
  tag: tagPropType,
  title: PropTypes.string,
  type: PropTypes.oneOf(['image', 'icon']),
  avatarUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  size: PropTypes.oneOf(['small', 'large']),
  icon: PropTypes.oneOf(iconsCustom),
  encryption: PropTypes.bool,
}

const defaultProps = {
  tag: 'div',
  icon: null,
  encryption: false,
}

const Card = (props) => {
  const { type, avatarUrl, title, size, icon, addClass, encryption } = props
  return (
    <CardWrapper>
      <div className={`pdf-card bottom-border pdf-card-${type} ${addClass}`}>
        <div className="card-media">
          {type === 'icon' ? (
            <div className={`pdf-icon-container icon ${icon}`}></div>
          ) : (
            <img
              className={`image-rounded ${
                size === 'small' ? 'image-small' : 'image-large'
              }`}
              src={avatarUrl || AvatarPlaceholder}
              alt={'avatar'}
            />
          )}
        </div>
        <div className="pdf-card-title">
          <h2>{title}</h2>
        </div>
        {encryption && (
          <div className="encryption pdf-flex-row">
            <i className="icon icon-lock"></i>
            <small>Industry standard 256-bit encryption</small>
          </div>
        )}
      </div>
    </CardWrapper>
  )
}

Card.propTypes = propTypes
Card.defaultProps = defaultProps

export default Card
