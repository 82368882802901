import React, { Component } from 'react'

import Banner from 'app/sm/alternate_owner_profile/components/banner'

const parole =
  'Select a profile from the dropdown or fill out the preferences manually.'

class Pilot extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.fetchSliders()
  }
  render() {
    const { sliders } = this.props

    return (
      <div>
        <Banner parole={parole} />
        <div className="two-col-box sm64">
          <div className="col-first">
            <div className="shadow-wrapper pt15 mb30">
              <div className="input-box single-no-border">
                <div className="prefix">
                  <i className="icon-briefcase"></i>
                </div>
                <select required>
                  <option>Customized Profile</option>
                </select>
                <label>Select a profile</label>
              </div>
            </div>
            <hr className="or big" />
            <div>
              {sliders.map((s) => (
                <div key={s.id}>
                  <div className="mt40 mb40 lh14">{s.question}</div>
                  <input
                    value={s.value}
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                    onChange={(e) =>
                      this.props.updateSlider(s.id, e.target.value)
                    }
                  />
                  <div className="range-labels gray-color op08">
                    <span>{s.left}</span>
                    <span>{s.right}</span>
                  </div>
                </div>
              ))}
            </div>
            <button className="mt50">Save</button>
            <div className="mt40">
              <h4 className="mb10">You in a rush?</h4>
              <p className="mb0">
                Don't stress too much about this stuff. You can tweak these
                anityime in your setting.{' '}
                <a className="pink-color" href="#">
                  Skip for now
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Pilot
