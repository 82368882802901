import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #ff5b77;
  padding: 4px 15px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 55px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Button = styled.div`
  border: 1px solid #ffffff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  padding: 8px 9px;
`

export const ImgBox = styled.div`
  position: relative;
  width: 75px;
  height: 55px;
  img {
    position: absolute;
  }
  .human01 {
    width: 45px;
    left: 0;
    top: 2px;
    z-index: 5;
  }
  .human02 {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 36px;
    z-index: 4;
  }
  .human03 {
    right: 0;
    top: 0;
    width: 50px;
    z-index: 3;
  }
`

export const Text = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin-bottom: 0;
  max-width: 172px;
`
