import React from 'react'

import styled from 'styled-components'

import { Box, Flex, TextSpan } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  CategoryTypes,
  formatRent,
  getCarSpaceCountForProperty,
} from 'app/sm/helpers'

const StyledPropertyBasic = styled(Box)`
  @media screen and (max-width: 576px) {
    .property-info-logo {
      align-items: flex-start;
      margin-top: ${theme.space[5]}px;
      img {
        margin-bottom: ${theme.space[4]}px;
      }
    }
  }
`

const PropertyBasic = ({
  property,
  rent,
  address,
  suburb,
  brandingLogoURL,
  carCountSpaces,
  usernameFormatted,
  manager,
  availabilityDate,
  addressStyles,
  suburbStyles,
  offerDataStyles,
}) => (
  <StyledPropertyBasic className="col-first address">
    <Flex
      fontSize={`${theme.fontSizes[9]}px`}
      fontWeight={`${theme.fontWeights[6]}`}
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>{formatRent(rent)}</Box>
      <Box>
        {' '}
        {brandingLogoURL && <img alt={'logo'} src={brandingLogoURL} />}
      </Box>
    </Flex>
    <Flex
      className="property-info-logo"
      justifyContent="space-between"
      alignItems="center"
      mb={5}
    >
      <Box
        fontSize={`${theme.fontSizes[8]}px`}
        fontWeight={`${theme.fontWeights[4]}`}
        {...addressStyles}
        pb={0}
        mt={3}
      >
        {address}
      </Box>
    </Flex>
    <Box
      fontSize={`${theme.fontSizes[5]}px`}
      fontWeight={`${theme.fontWeights[4]}`}
      level={4}
      {...suburbStyles}
      mb={4}
    >
      {suburb}
    </Box>
    <Box className="meta" fontWeight={`${theme.fontWeights[6]}`}>
      <Flex my={`${theme.space[3]}px`}>
        <Flex mr={`${theme.space[6]}px`}>
          <i className="icon-bedroom" />
          <TextSpan ml={`${theme.space[2]}px`}>{property.bedrooms}</TextSpan>
        </Flex>
        <Flex mr={`${theme.space[6]}px`}>
          <i className="icon-bathroom" />
          <TextSpan ml={`${theme.space[2]}px`}>{property.bathrooms}</TextSpan>
        </Flex>
        <Flex mr={`${theme.space[6]}px`}>
          <i className="icon-cars" />
          {carCountSpaces && (
            <TextSpan ml={`${theme.space[2]}px`}>
              {getCarSpaceCountForProperty(carCountSpaces)}
            </TextSpan>
          )}
        </Flex>
        <Flex mr={`${theme.space[6]}px`}>
          <TextSpan ml={`${theme.space[2]}px`}>
            {CategoryTypes[property.type]}
          </TextSpan>
        </Flex>
      </Flex>
    </Box>
    <Flex {...offerDataStyles} mt={3} alignItems="center">
      {availabilityDate && (
        <>
          <Box fontSize={`${theme.fontSizes[5]}px`}>
            Available: {availabilityDate}
          </Box>
        </>
      )}
    </Flex>
  </StyledPropertyBasic>
)

export default PropertyBasic
