import React from 'react'

import { Formik } from 'formik'

import { updateUserProfile } from 'app/services/http/users'
import * as snugNotifier from 'app/services/snugNotifier'
import {
  formFields,
  formValidation,
} from 'app/session/bc_profile/profile_update/form.utils'
import FormBody from 'app/session/bc_profile/profile_update/FormBody'

const stringCheckValExtractor = (value) => value.trim()
const mobileCheckValExtractor = (value) => value.trim().replace(/\s/g, '')

const UpdateProfileForm = ({
  initialValues,
  fetchCurrentUserProfile,
  logout,
  currentUser,
}) => {
  const fieldChangeCheck = (
    values,
    fieldName,
    valExtractorFn,
    changeFn = (a, b) => a !== b,
  ) => {
    const a = valExtractorFn(initialValues[fieldName])
    const b = valExtractorFn(values[fieldName])
    return changeFn(a, b)
  }

  const mustUpdate = (values) => {
    return new Promise((resolve) => {
      const isEmailChanged = fieldChangeCheck(
        values,
        formFields.email,
        stringCheckValExtractor,
      )
      const isMobileChanged = fieldChangeCheck(
        values,
        formFields.mobile,
        mobileCheckValExtractor,
        (a, b) => !b.includes(a),
      )
      const hasChanges =
        fieldChangeCheck(
          values,
          formFields.firstName,
          stringCheckValExtractor,
        ) ||
        fieldChangeCheck(
          values,
          formFields.lastName,
          stringCheckValExtractor,
        ) ||
        isEmailChanged ||
        isMobileChanged

      const shouldLogout = isEmailChanged || isMobileChanged

      resolve({
        hasChanges,
        shouldLogout,
        isEmailChanged,
      })
    })
  }

  const updateProfile = (values) => {
    return mustUpdate(values).then((result) => {
      if (result.hasChanges) {
        return updateUserProfile(values)
          .then(() => {
            snugNotifier.success('Profile updated successfully')
            if (result.shouldLogout) {
              logout()
            } else {
              fetchCurrentUserProfile()
            }
          })
          .catch((err) => {
            const message = err.message || 'Unable to update profile'
            snugNotifier.error(message)
          })
      } else {
        snugNotifier.info('No changes!')
      }
    })
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={updateProfile}
        validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {(props) => (
          <FormBody
            formBag={props}
            onFormSubmit={updateProfile}
            currentUser={currentUser}
          />
        )}
      </Formik>
    </>
  )
}

export default UpdateProfileForm
