import React from 'react'

import FuzzySearch from 'fuzzy'
import { Link } from 'react-router-dom'

import Spinner from 'app/sm/common/spinner'
import { pageLimit, propertyStatusCode, urlTo } from 'app/sm/helpers'
import PropertyCard from 'app/sm/properties/components/property_card'
import PropertyPageFooter from 'app/sm/properties/components/property_page_footer'
import {
  ApplicationYesNoFilter,
  ApplicationYesNoFilterCriteria,
  PropertyManagerFilter,
  PropertySearchBox,
  PropertySearchFilterRow,
  PropertyStatusFilter,
  PropertyStatusFilterOptions,
} from 'app/sm/properties/components/property_search_filter_util'

const PropertyCardScreen = ({ children }) => {
  return <div>{children}</div>
}

const AddPropertyButton = ({ teamSlug }) => {
  return (
    <div className="sm-properties-card-list-item add">
      <div className="sm-properties-card-list-item-wrapper">
        <Link to={urlTo('propertyGetStarted', { teamSlug })}>
          <i className="icon-add"></i>
          <h5>Add a Property</h5>
        </Link>
      </div>
    </div>
  )
}

const PropertyCardTitle = ({
  fetchProperties,
  responseMetadata = {},
  searchText,
  propertyStatus,
  firstPageCursor = {},
  teamGuid,
  propertyManagerFilter,
  applicationYesNoFilter = 'any',
}) => (
  <div className="section-title mt10">
    <div className="left flex-sp portfolio-properties-header">
      <h3>
        Your properties{' '}
        {responseMetadata.total > 0 && `(${responseMetadata.total})`}
      </h3>
      <PropertyPageFooter
        fetchProperties={fetchProperties}
        responseMetadata={responseMetadata}
        searchText={searchText}
        propertyStatus={propertyStatus}
        firstPageCursor={firstPageCursor}
        teamGuid={teamGuid}
        propertyManagerFilter={propertyManagerFilter}
        applicationYesNoFilter={applicationYesNoFilter}
      />
    </div>
  </div>
)

const PropertyCardList = ({ children }) => (
  <div className="sm-properties-card-list">{children}</div>
)

const selectAgentName = (property) => {
  const { agent } = property.property
  return `${agent.firstName} ${agent.lastName}`
}

const selectApplicationCount = (property) => property.applicationsCount
const selectSuburb = (property) => property.property.address.suburb
const selectStreetName = (property) => property.property.address.streetName
const selectStreetNumber = (property) => property.property.address.streetNumber
const selectPropertyStatus = (property) =>
  property.property.archived ? 5 : property.property.status
const extractAddress = (property) =>
  (
    selectStreetNumber(property) +
    selectStreetName(property) +
    selectSuburb(property)
  ).replace(/\s+/g, '')
const uniqueValue = (value, index, self) => self.indexOf(value) === index

class Properties extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      propertyManagerFilter: 'Any',
      applicationYesNoFilter: 'any',
      propertyStatus: 'Any (Non-archived)',
    }
  }

  componentDidMount() {
    const teamSlug = this.props.currentTeam && this.props.currentTeam.slug
    const teamGuid = this.props.currentTeam && this.props.currentTeam.guid
    this.props.setBackUrl(urlTo('portfolioOverview', { teamSlug }))
    this.props.fetchProperties(teamGuid)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextTeam = nextProps.currentTeam ? nextProps.currentTeam : {}
    const currentTeam = this.props.currentTeam ? this.props.currentTeam : {}

    if (nextTeam.slug !== currentTeam.slug) {
      this.setState({
        searchText: '',
        propertyManagerFilter: 'Any',
        applicationYesNoFilter: 'Any',
        propertyStatus: 'Any (Non-archived)',
      })
    }
  }

  onApplicationYesNoFilterChange = (event) => {
    const { value } = event.target
    const { fetchProperties, responseMetadata = {}, currentTeam } = this.props
    this.setState({ applicationYesNoFilter: value }, () => {
      const teamGuid = currentTeam && currentTeam.guid
      const {
        applicationYesNoFilter = 'any',
        propertyManagerFilter,
        propertyStatus,
      } = this.state
      fetchProperties(
        teamGuid,
        '',
        propertyStatusCode[propertyStatus],
        this.state.searchText,
        pageLimit,
        propertyManagerFilter,
        applicationYesNoFilter,
      )
    })
  }

  onArchiveProperty = (id) => {
    const {
      archiveProperty,
      properties = [],
      currentTeam,
      receiveResponseText,
      fetchProperties,
    } = this.props
    const next_cursor = properties[0].property.guidID
    const teamGuid = currentTeam && currentTeam.guid
    const {
      applicationYesNoFilter = 'any',
      propertyManagerFilter,
      propertyStatus,
    } = this.state
    archiveProperty(id)
      .then(() =>
        fetchProperties(
          teamGuid,
          next_cursor,
          propertyStatusCode[propertyStatus],
          this.state.searchText,
          pageLimit,
          propertyManagerFilter,
          applicationYesNoFilter,
        ),
      )
      .catch(() => console.log('fetchProperties failed'))
  }

  onPropertyManagerFilterChange = (event) => {
    const { propertyStatus, searchText } = this.state
    const { fetchProperties, responseMetadata = {}, currentTeam } = this.props
    this.setState({ propertyManagerFilter: event.target.value }, () => {
      const teamGuid = currentTeam && currentTeam.guid
      const { propertyManagerFilter, applicationYesNoFilter = 'any' } =
        this.state
      fetchProperties(
        teamGuid,
        '',
        propertyStatusCode[propertyStatus],
        searchText,
        pageLimit,
        propertyManagerFilter,
        applicationYesNoFilter,
      )
    })
  }

  onPropertyStatusFilterChange = (event) => {
    const { value } = event.target
    const { fetchProperties, responseMetadata = {}, currentTeam } = this.props
    const { propertyManagerFilter, applicationYesNoFilter = 'any' } = this.state
    const teamGuid = currentTeam && currentTeam.guid
    this.setState({ propertyStatus: value })
    fetchProperties(
      teamGuid,
      '',
      propertyStatusCode[value],
      this.state.searchText,
      pageLimit,
      propertyManagerFilter,
      applicationYesNoFilter,
    )
  }

  onSearchChange = (event) => {
    const { fetchProperties, responseMetadata = {}, currentTeam } = this.props
    const teamGuid = currentTeam && currentTeam.guid
    const status = propertyStatusCode[this.state.propertyStatus]
    const { value } = event.target
    const { propertyManagerFilter = '', applicationYesNoFilter = 'any' } =
      this.state

    this.setState({ searchText: value })
    clearInterval(this.propertySearch)
    this.propertySearch = setTimeout(
      () =>
        fetchProperties(
          teamGuid,
          '',
          status,
          value,
          pageLimit,
          propertyManagerFilter,
          applicationYesNoFilter,
        ),
      500,
    )
  }

  render() {
    const {
      currentUser,
      properties = [],
      archivedProperties = [],
      responseMetadata,
      withdrawModalId,
      withdrawAdvertisement,
      withdrawModalOn,
      withdrawModalOff,
      archiveProperty,
      unarchiveProperty,
      fetchProperties,
      spinner = false,
      currentTeam,
      firstPageCursor,
      managerList,
    } = this.props
    const {
      searchText,
      propertyStatus,
      applicationYesNoFilter = 'any',
      propertyManagerFilter,
    } = this.state
    const teamGuid = currentTeam && currentTeam.guid
    const displayedProperties =
      (propertyStatus === 'Archived' ? archivedProperties : properties) || []
    const filteredProperties = displayedProperties

    return (
      <PropertyCardScreen>
        <PropertyCardTitle
          count={filteredProperties.length}
          fetchProperties={fetchProperties}
          responseMetadata={responseMetadata}
          searchText={searchText}
          propertyStatus={propertyStatus}
          firstPageCursor={firstPageCursor}
          teamGuid={teamGuid}
          applicationYesNoFilter={applicationYesNoFilter}
          propertyManagerFilter={propertyManagerFilter}
        />

        <PropertySearchFilterRow>
          <PropertySearchBox
            placeholder="Search properties"
            value={searchText}
            onChange={this.onSearchChange}
          />
          {currentTeam &&
            currentTeam.slug &&
            managerList &&
            managerList.length > 0 && (
              <PropertyManagerFilter
                options={managerList}
                value={propertyManagerFilter}
                onChange={this.onPropertyManagerFilterChange}
              />
            )}
          <ApplicationYesNoFilter
            value={applicationYesNoFilter}
            onChange={this.onApplicationYesNoFilterChange}
          />
          <PropertyStatusFilter
            value={propertyStatus}
            onChange={this.onPropertyStatusFilterChange}
          />
        </PropertySearchFilterRow>
        {spinner ? (
          <Spinner />
        ) : (
          <PropertyCardList>
            {filteredProperties.map((propertyInfo, i) => (
              <PropertyCard
                key={i}
                propertyInfo={propertyInfo}
                withdrawModalOn={withdrawModalOn}
                withdrawModalOff={withdrawModalOff}
                withdrawModalId={withdrawModalId}
                withdrawAdvertisement={withdrawAdvertisement}
                currentUser={currentUser}
                archiveProperty={this.onArchiveProperty}
                unarchiveProperty={unarchiveProperty}
              />
            ))}
            <AddPropertyButton
              teamSlug={currentTeam ? currentTeam.slug : undefined}
            />
          </PropertyCardList>
        )}
        <PropertyPageFooter
          fetchProperties={fetchProperties}
          responseMetadata={responseMetadata}
          searchText={searchText}
          propertyStatus={propertyStatus}
          firstPageCursor={firstPageCursor}
          className="card-badge"
          teamGuid={teamGuid}
          applicationYesNoFilter={applicationYesNoFilter}
          propertyManagerFilter={propertyManagerFilter}
        />
      </PropertyCardScreen>
    )
  }
}

export default Properties
