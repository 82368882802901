import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'

const Directorship = styled.div`
  padding-top: 20px;
`
const Label = styled.div`
  justify-content: space-between;
  font-size: 14px;
  line-height: 1.6;
`
const Title = styled.p`
  color: #22212a;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
`
const Companyinfo = styled.div`
  justify-content: space-between;
  font-size: 14px;
  padding: 13px 0;
  border-bottom: 1px solid #f4f4f4;
`

const PageTextCategory = styled(Text)`
  margin-bottom: 0;
  line-height: 1.4;
  text-align: ${(props) => (props.isNotAlign ? 'left' : 'justify')};
`

const DirectorshipInfo = ({ title = '', directorshipData = [] }) => {
  return (
    <Directorship>
      <Title>{title}</Title>
      {directorshipData.length > 0 &&
        directorshipData.map((info, index) => {
          const {
            ABN = '',
            ACN = '',
            CompanyName = '',
            Companystatus = '',
            DateAppointed = '',
            FileNumber = '',
            DateCeased = '',
            DateLastDirector = '',
          } = info
          return (
            <Companyinfo key={index}>
              {CompanyName && <Label>Company Name: {CompanyName}</Label>}
              {Companystatus && <Label>Company Status: {Companystatus}</Label>}
              {ACN && <Label>ACN: {ACN}</Label>}
              {ABN && <Label>ABN: {ABN}</Label>}
              {DateAppointed && <Label>Date Appointed: {DateAppointed}</Label>}
              {FileNumber && <Label>File Number: {FileNumber}</Label>}
              {DateCeased && <div>Date Ceased: {DateCeased}</div>}
              {DateLastDirector && (
                <div>Date Last Director: {DateLastDirector}</div>
              )}
            </Companyinfo>
          )
        })}
    </Directorship>
  )
}

export default DirectorshipInfo

export const NewDirectorshipInfo = ({
  directorshipData = [],
  isProprietorship = false,
}) => {
  return (
    <>
      {directorshipData.length > 0 &&
        directorshipData.map((info) => {
          const {
            ACN = '',
            CompanyName = '',
            FileNumber = '',
            BusinessName = '',
            RegistrationNumber = '',
          } = info
          return (
            <Box my={`${theme.space[6]}px`}>
              {isProprietorship ? (
                <PageTextCategory>
                  Business Name: {BusinessName}
                </PageTextCategory>
              ) : (
                <PageTextCategory>Company Name: {CompanyName}</PageTextCategory>
              )}
              {isProprietorship ? (
                <PageTextCategory>
                  Registration Number: {RegistrationNumber}
                </PageTextCategory>
              ) : (
                <PageTextCategory>ACN: {ACN}</PageTextCategory>
              )}
              <PageTextCategory>File Number: {FileNumber}</PageTextCategory>
            </Box>
          )
        })}
    </>
  )
}

export const NewDisqualifiedDirectorship = ({ directorshipData = {} }) => {
  const { DatedIsQualified = '', DatedIsQualifiedUntil = '' } = directorshipData

  const formattedDatedIsQualified =
    DatedIsQualified && moment(DatedIsQualified).format('DD MMM YYYY')

  const formattedDatedIsQualifiedUntil =
    DatedIsQualifiedUntil && moment(DatedIsQualifiedUntil).format('DD MMM YYYY')

  return (
    <Box my={`${theme.space[6]}px`}>
      <PageTextCategory>Disqualified directorships</PageTextCategory>
      <PageTextCategory>
        From {formattedDatedIsQualified} until {formattedDatedIsQualifiedUntil}
      </PageTextCategory>
    </Box>
  )
}
