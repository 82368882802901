import { useField } from 'formik'

export const CustomInputWrapper = ({ name, customInputCmp }) => {
  const [field, meta, helpers] = useField(name)
  const error = meta.error && meta.touched
  return customInputCmp({
    changeHandler: (value) => helpers.setValue(value, true),
    blurHandler: () => helpers.setTouched(true, true),
    value: field.value,
    error,
    touched: meta.touched,
  })
}
