import React from 'react'

import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const PageSection = styled(Box)`
  margin-bottom: ${theme.baseSpace * 4}px;
`
