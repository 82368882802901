import { connect } from 'react-redux'

import ViewingsListing from 'app/sm/viewings/components/viewings_listing'
import {
  addFavouriteViewingFlagById,
  fetchViewings,
  onSorterDropdownChange,
  ping,
  removeFavouriteViewingFlagById,
} from 'app/sm/viewings/viewings_actions'

const mapStateToProps = ({ Viewings }) => ({
  error: Viewings.error,
  viewings: Viewings.viewings,
  spinner: Viewings.spinner,
  sortBy: Viewings.sortBy,
})

const mapDispatchToProps = (dispatch) => ({
  ping: () => dispatch(ping()),
  fetchViewings: () => dispatch(fetchViewings()),
  addFavourite: (id) => dispatch(addFavouriteViewingFlagById(id)),
  removeFavourite: (id) => dispatch(removeFavouriteViewingFlagById(id)),
  onSorterDropdownChange: (sorter) => dispatch(onSorterDropdownChange(sorter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewingsListing)
