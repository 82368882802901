import React from 'react'

import {
  Box,
  HorizontalLine,
} from 'app/components/design-system-components/index'
import { Text } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const DropdownBodySectionHeader = ({ header, containerProps }) => {
  return (
    <Box {...containerProps}>
      <Box p={`${theme.space[3]}px ${theme.space[4]}px`}>
        <Text
          as="span"
          fontWeight={theme.fontWeights[6]}
          fontSize={theme.fontSizes.pSmall12}
          color={theme.colors.gray600}
        >
          {header}
        </Text>
      </Box>
      <HorizontalLine />
    </Box>
  )
}
