import React from 'react'

import $ from 'jquery'

import Amenities from 'app/sm/ppp/property_components/property_form/amenities/amenities'

class AmenitiesContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openSubs: new Set(),
      clicked: false,
    }
  }

  componentDidMount() {
    $('.app-content').scrollTop(0)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.error) {
      this.setState({ clicked: false })
    }
  }

  onContinueClicked() {
    this.setState({ clicked: true })
  }

  toggleOpenSubs = () => {
    return (event) => {
      let { openSubs } = this.state
      let { id } = event.currentTarget
      if (openSubs.has(id)) {
        openSubs.delete(id)
      } else {
        openSubs.add(id)
      }
      this.setState({ openSubs })
    }
  }

  render() {
    let {
      ad,
      state,
      updateCheckbox,
      params,
      updateProperty,
      attributes,
      error,
    } = this.props
    let { id, propertyId } = params
    let { openSubs, clicked } = this.state
    let commonAmenities = attributes.commonAmenities || []
    let moreAmenities = attributes.moreAmenities || []

    let common = state.commonAmenities
    let more = state.moreAmenities

    let isChecked = (id, isCommonAmenitie, parentId, isSub = false) => {
      // TODO not repeat the code

      if (isCommonAmenitie) {
        if (isSub) {
          return (
            Array.isArray(common[parentId.toString()]) &&
            common[parentId.toString()].find((k) => id.toString() === k) !==
              undefined
          )
        }

        return common[id.toString()] !== undefined
      } else {
        if (isSub) {
          return (
            Array.isArray(more[parentId.toString()]) &&
            more[parentId.toString()].find((k) => id.toString() === k) !==
              undefined
          )
        }

        return more[id.toString()] !== undefined
      }
    }

    return (
      <Amenities
        id={id || propertyId}
        onContinueClicked={() => this.onContinueClicked()}
        ad={ad}
        error={error}
        updateCheckbox={updateCheckbox}
        updateProperty={updateProperty}
        openSubs={openSubs}
        commonAmenities={commonAmenities}
        moreAmenities={moreAmenities}
        isChecked={isChecked}
        toggleOpenSubs={this.toggleOpenSubs}
        state={state}
        clicked={clicked}
      />
    )
  }
}

export default AmenitiesContainer
