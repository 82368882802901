import React from 'react'

import styled from 'styled-components'

import { accountsBaseUrl } from 'app/shared_components/shared_base_url'

// Source: https://www.codegrepper.com/code-examples/html/sing+up+with+google+button+css
const RegisterGoogleButton = styled.div`
  display: inline-block;
  width: 100%;
  height: 50px;
  background-color: #4285f4;
  color: #fff;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;

  &:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
  }

  &:active {
    background-color: #3367d6;
    transition: background-color 0.2s;
  }

  .content-wrapper {
    height: 100%;
    width: 100%;
    border: 1px solid transparent;
  }

  img {
    width: 18px;
    height: 18px;
  }

  .logo-wrapper {
    padding: 15px;
    background: #fff;
    width: 48px;
    height: 100%;
    border-radius: 1px;
    display: inline-block;
  }

  .text-container {
    font-family: Roboto, arial, sans-serif;
    font-weight: 500;
    letter-spacing: 0.21px;
    font-size: 16px;
    line-height: 48px;
    vertical-align: top;
    border: none;
    display: inline-block;
    text-align: center;
    width: calc(100% - 48px);
  }

  .roboto-font {
    font-family: 'Roboto' !important;
  }
`

const RegisterGoogle = ({ form }) => {
  return (
    <div className="col-sm-6 panel panel-default pb30 width-100">
      <a href={accountsBaseUrl + 'google'}>
        <RegisterGoogleButton>
          <div className="content-wrapper">
            <div className="logo-wrapper">
              <img
                alt="Google"
                src="https://developers.google.com/identity/images/g-logo.png"
              />
            </div>
            <span className="text-container">
              <span className="roboto-font">{form} with Google</span>
            </span>
          </div>
        </RegisterGoogleButton>
      </a>
    </div>
  )
}

export default RegisterGoogle
