import React from 'react'

import ActivityLogsTable from 'app/components/design-system-components/ActivityLogsTable'
import { Text } from 'app/components/design-system-components/typography'
import {
  pmsActivityStatuses,
  pmsActivityTypes,
} from 'app/constants/pms.constants'

export class PMSActivityLogsTable extends React.Component {
  render() {
    const { activityList } = this.props
    return activityList && activityList.length ? (
      <ActivityLogsTable
        activityList={activityList}
        activityStatuses={pmsActivityStatuses}
        activityTypes={pmsActivityTypes}
      />
    ) : (
      <Text>No activity</Text>
    )
  }
}
