import React from 'react'

import * as Form from 'app/components/forms/forms'
import { isAustralianAddress } from 'app/sm/helpers'

const ManualAddress = ({
  address = {},
  placeholder,
  update,
  clear,
  onBlur,
  inputDisabled = false,
  error,
  suggest,
  toggleSuggest,
  country = address.country || 'AUS',
  showSuggestAddress = true,
}) => (
  <div className="input-address-manual" onBlur={onBlur}>
    {placeholder && <h4 className="pb10 pt10">{placeholder} :</h4>}

    <div className="row">
      <div className="col-xs-6">
        <div className="input-box">
          <input
            type="text"
            required
            id="unitNumber"
            disabled={inputDisabled}
            value={address.unitNumber}
            onChange={update('unitNumber')}
            autoComplete="nope"
          />
          <label className={inputDisabled && 'fixedLabelStyle'}>
            Unit Number
          </label>
        </div>
      </div>
      <div className="col-xs-6">
        <div className="input-box">
          <input
            type="text"
            required
            id="streetNumber"
            disabled={inputDisabled}
            value={address.streetNumber}
            onChange={update('streetNumber')}
            autoComplete="nope"
          />
          <label className={inputDisabled && 'fixedLabelStyle'}>
            Street Number
          </label>
        </div>
      </div>
    </div>

    <div className="input-box">
      <input
        type="text"
        required
        id="streetName"
        disabled={inputDisabled}
        value={address.streetName}
        onChange={update('streetName')}
        autoComplete="nope"
      />
      <label className={inputDisabled && 'fixedLabelStyle'}>Street Name</label>
    </div>

    <div className="input-box">
      <input
        type="text"
        required
        id="address2"
        disabled={inputDisabled}
        value={address.address2}
        onChange={update('address2')}
        autoComplete="nope"
      />
      <label className={inputDisabled && 'fixedLabelStyle'}>
        Street address 2
      </label>
    </div>

    <div className="input-box">
      <input
        type="text"
        required
        id="suburb"
        disabled={inputDisabled}
        value={address.suburb}
        onChange={update('suburb')}
        autoComplete="nope"
      />
      <label className={inputDisabled && 'fixedLabelStyle'}>Suburb</label>
    </div>

    <div className="input-box">
      <input
        type="text"
        required
        id="postcode"
        disabled={inputDisabled}
        value={address.postcode}
        onChange={update('postcode')}
        autoComplete="nope"
      />
      <label className={inputDisabled && 'fixedLabelStyle'}>Postcode</label>
    </div>

    {address.country && isAustralianAddress(address) ? (
      <div>
        <label className="position-relative top5">State</label>
        <Form.DropdownState
          componentClass={'mb30'}
          name="State"
          value={address.state}
          onChange={update('state')}
          standardShareComponentStyleEnabled={false}
        />
      </div>
    ) : (
      <div className="input-box">
        <input
          type="text"
          required
          id="state"
          value={address.state}
          onChange={update('state')}
          autoComplete="nope"
        />
        <label>State/Province/Region</label>
      </div>
    )}

    <label className="position-relative top5">Country</label>
    <Form.DropdownCountry
      name="Country"
      value={country}
      onChange={update('country')}
      placeholder="Select country"
      standardShareComponentStyleEnabled={false}
    />
    <div
      className={`${suggest ? 'note note-google' : 'note note-manual'} mt10`}
    >
      {suggest ? (error ? 'Please try ' : "Can't find your address? ") : ''}
      {showSuggestAddress && (
        <a onClick={() => toggleSuggest()}>
          {suggest
            ? error
              ? 'entering your address manually'
              : 'Add manually'
            : 'Suggest address'}
        </a>
      )}
    </div>
  </div>
)

export default ManualAddress
