import React from 'react'

import moment from 'moment'
import DateTime from 'react-datetime'

import { ErrorBox, getCurrentValidationStatus } from 'app/components/forms/util'
import * as helpers from 'app/sm/helpers'
import 'app/components/forms/base_inputDate/style.scss'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

class InputDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      manualDate: '',
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props
    const { manualDate } = this.state
    const dateValue = prevProps.value
    if (value !== dateValue && value !== manualDate) {
      this.setState({
        manualDate: dateValue,
      })
    }
  }

  checkMoment = (date) => {
    const dateReg =
      /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/
    if (date.toString().match(dateReg)) {
      const dateString = date.toString()
      const dateMomentObject = moment(
        dateString,
        dateTimeHelpers.DATE_WITH_SLASH,
        true,
      )
      if (dateMomentObject.isValid()) {
        const dateObject = dateMomentObject.toDate() // convert moment.js object to Date object
        this.validateDate(moment(dateObject))
      } else {
        const error = 'Invalid date'
        const value = date
        this.props.onChange({ value, error })
      }
    } else {
      const error = 'Invalid date'
      const value = date
      this.props.onChange({ value, error })
    }
  }

  handleChange = (date) => {
    if (!moment.isMoment(date)) {
      this.checkMoment(date)
    } else {
      if (moment(date).isValid()) {
        this.validateDate(date)
      } else {
        const { manualDate } = this.state
        this.checkMoment(manualDate)
      }
    }
  }

  validateDate(date) {
    const { customisedDateFormat = helpers.dateWithDash } = this.props
    const value = moment(date).format(helpers.dateWithDash)
    let error = value ? this.props.validate(value) : ''
    if (!error && this.props.before) {
      error = date.isBefore(moment(this.props.before))
        ? ''
        : `The date must be before ${moment(this.props.before).format(
            customisedDateFormat,
          )}`
    }
    if (!error && this.props.after) {
      error = date.isAfter(moment(this.props.after))
        ? ''
        : `The date must be after ${moment(this.props.after).format(
            customisedDateFormat,
          )}`
    }
    this.props.onChange({ value, error })
  }

  render() {
    const {
      label,
      value,
      error,
      id,
      containerClassName,
      componentClass,
      isValidDate,
      viewMode,
      defaultValue,
      renderDay,
      inputClassName,
      customisedDateFormat,
      placeholder = 'dd/mm/yyyy',
      readOnly = true,
    } = this.props
    let validationClass = ''
    if (value !== '') {
      validationClass = getCurrentValidationStatus(value, error)
    }
    return (
      <div className={`${componentClass}`}>
        <div className={`standard-form-components mb10 ${containerClassName}`}>
          <div className="input-box standard-input-box mb5">
            <div className="input-date pos-initial pt0 mb0">
              <DateTime
                className="date-time"
                inputProps={{
                  className: `standard-inputbox ${validationClass} ${inputClassName}`,
                  placeholder: placeholder,
                  readOnly: readOnly,
                  disabled: readOnly,
                  id,
                  autoComplete: 'off',
                }}
                onBlur={undefined}
                onChange={this.handleChange}
                onFocus={undefined}
                value={value}
                defaultValue={defaultValue}
                viewMode={`${viewMode ? viewMode : 'years'}`}
                dateFormat={customisedDateFormat || helpers.dateWithDash}
                timeFormat={false}
                open={null}
                closeOnSelect
                disableOnClickOutside
                isValidDate={isValidDate}
                renderDay={renderDay}
              />
              <label className="input-label">{label}</label>
            </div>
          </div>
          <ErrorBox error={error} showError={!!error} />
        </div>
      </div>
    )
  }
}

export default InputDate
