import React from 'react'

import { Box, Modal } from 'app/components/design-system-components'
import * as textHelpers from 'app/utils/text/helpers'

function RentOfferDeclarationModal({
  toggleRentOfferDeclarationModal,
  resetRentToInitialRent,
}) {
  const modalBody = <Box>{textHelpers.NOT_SOLICITED_TO_RENT_BID_MODAL_MSG}</Box>
  return (
    <Modal
      modalHeading="Rent offer declaration"
      modalBody={modalBody}
      primaryLabel="Submit rent offer"
      secondaryLabel="Cancel"
      primaryAction={toggleRentOfferDeclarationModal}
      toggleModal={resetRentToInitialRent}
      secondaryAction={resetRentToInitialRent}
    />
  )
}

export default RentOfferDeclarationModal
