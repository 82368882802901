import React from 'react'

import styled from 'styled-components'

import legalRecords from 'app/assets/icons/background-report/legal-records.svg'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import BankruptcyRecordsInfo from 'app/sm/renter_background_check_report/components/bankruptcy-records-info/component'
import CourtRecordsInfo from 'app/sm/renter_background_check_report/components/court-records-info/component'
import {
  NewDirectorshipInfo,
  NewDisqualifiedDirectorship,
} from 'app/sm/renter_background_check_report/components/directorship-info/component'
import DatabasesCheckComponent, {
  CheckMark,
} from 'app/sm/renter_background_check_report/databases_check/component'
import {
  EquiFaxResponse,
  PageText,
} from 'app/sm/renter_background_check_report/new_backgroundcheck_report_container'
import { checkBankruptcyCourtRecordsASISFromBGCheckResponse } from 'app/utils/backgroundcheck'

const PageTextCategory = styled(Text)`
  margin-bottom: 0;
  line-height: 1.4;
  text-align: ${(props) => (props.isNotAlign ? 'left' : 'justify')};
`

const PageTextContent = styled(Text)`
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[4]}px`};
  line-height: 1.4;
  text-align: ${(props) => (props.isNotAlign ? 'left' : 'justify')};
`

const bankruptcyNotIncludedMsg = 'Not included'

const LegalRecords = ({
  Bankruptcies = {},
  CourtRecords = {},
  DirectorshipsProprietorships = {},
  updateDate = '',
  BankruptciesResultInfo = {},
  CourtRecordsResultInfo = {},
  DirectorshipsProprietorshipsResultInfo = {},
  backgroundCheckResponse = {},
  backgroundCheck = {},
}) => {
  const { result: noBankruptcyResults = true } = BankruptciesResultInfo || {}

  const { result: noCourtRecordsResult = true } = CourtRecordsResultInfo

  const { result: noDirectorshipsProprietorshipsResult = true } =
    DirectorshipsProprietorshipsResultInfo

  const { BankruptcyMessage = '', Bankruptcy: bankruptcyRecordsList = [] } =
    Bankruptcies

  const { CourtRecordMessage = '', CourtRecords: courtRecordsList = [] } =
    CourtRecords
  const {
    DirectorshipsProprietorshipsMessage = '',
    FileMessage = [],
    CurrentDirectorships = [],
    PreviousDirectorships = [],
    CurrentProprietorship = null,
    DisqualifiedDirectorship = null,
  } = DirectorshipsProprietorships

  const {
    isBankruptciesResults,
    isCourtRecordsResults,
    isDirectorshipsProprietorshipsResult,
  } = checkBankruptcyCourtRecordsASISFromBGCheckResponse(
    backgroundCheckResponse,
    backgroundCheck,
  )

  const isValid = !(
    isBankruptciesResults ||
    isCourtRecordsResults ||
    isDirectorshipsProprietorshipsResult
  )

  const isDirectorshipsAndProprietorshipsRecord =
    (!CurrentDirectorships || CurrentDirectorships.length === 0) &&
    (!PreviousDirectorships || PreviousDirectorships.length === 0) &&
    !CurrentProprietorship &&
    !DisqualifiedDirectorship

  const statusClass = isValid ? 'Verified' : 'identity-verification-unverified'
  const statusText = isValid ? 'Not Found' : 'Info'

  const bankruptcyNotIncluded =
    BankruptciesResultInfo?.message === bankruptcyNotIncludedMsg

  let bankruptcyText = noBankruptcyResults ? (
    <CheckMark text="No Bankruptcy records found" iconType="verified" />
  ) : (
    <CheckMark text="Bankruptcy records found" iconType="info" />
  )

  if (bankruptcyNotIncluded) {
    bankruptcyText = (
      <CheckMark
        text="Bankruptcy records not included"
        iconType="not-verified"
      />
    )
  }

  const leftContent = (
    <>
      {bankruptcyText}
      {noCourtRecordsResult ? (
        <CheckMark text="No Court records found" iconType="verified" />
      ) : (
        <CheckMark text="Court records found" iconType="info" />
      )}
      {isDirectorshipsAndProprietorshipsRecord ? (
        <CheckMark
          text="No Directorships and Proprietorships records found"
          iconType="verified"
        />
      ) : (
        <CheckMark
          text="Directorships and Proprietorships records found"
          iconType="info"
        />
      )}
      <EquiFaxResponse isNotMb={true}>Equifax response:</EquiFaxResponse>
      <PageTextCategory isNotMb={true} fontWeight={theme.fontWeights[6]}>
        Court Records
      </PageTextCategory>
      <PageTextCategory isNotMb={true} isNotAlign={true}>
        {CourtRecordMessage}
      </PageTextCategory>

      {courtRecordsList && (
        <>
          <CourtRecordsInfo courtRecordsData={courtRecordsList} />
        </>
      )}
      {(courtRecordsList || []).length === 0 && (
        <PageText>No current court records found</PageText>
      )}
    </>
  )
  const rightContent = (
    <>
      <EquiFaxResponse isNotMb={true}>Equifax response:</EquiFaxResponse>
      <PageTextCategory isNotMb={true} fontWeight={theme.fontWeights[6]}>
        Bankruptcy History
      </PageTextCategory>
      <PageText isNotAlign={true}>{BankruptcyMessage}</PageText>
      {bankruptcyRecordsList && (
        <>
          <BankruptcyRecordsInfo
            bankruptcyRecordsData={bankruptcyRecordsList}
          />
        </>
      )}

      <EquiFaxResponse isNotMb={true}>Equifax response:</EquiFaxResponse>
      <PageTextCategory isNotMb={true} fontWeight={theme.fontWeights[6]}>
        Directorships and Proprietorships
      </PageTextCategory>
      <PageText pb={`${theme.dividedSpace[1]}px`} isNotAlign={true}>
        {DirectorshipsProprietorshipsMessage}
      </PageText>

      {FileMessage &&
        FileMessage.map((message, index) => (
          <PageText isNotAlign={true}>{message}</PageText>
        ))}
      {(FileMessage || []).length === 0 && (
        <PageTextContent>{'No information found'}</PageTextContent>
      )}

      {CurrentDirectorships && (
        <NewDirectorshipInfo directorshipData={CurrentDirectorships} />
      )}

      {(CurrentDirectorships || []).length === 0 && (
        <PageTextContent>{'No current directorship found'}</PageTextContent>
      )}

      {DisqualifiedDirectorship && (
        <>
          <NewDisqualifiedDirectorship
            directorshipData={DisqualifiedDirectorship}
          />
        </>
      )}
      {!DisqualifiedDirectorship && (
        <PageTextContent>
          {'No disqualified proprietorship found'}
        </PageTextContent>
      )}

      {PreviousDirectorships && (
        <>
          <NewDirectorshipInfo directorshipData={PreviousDirectorships} />
        </>
      )}
      {(PreviousDirectorships || []).length === 0 && (
        <PageTextContent>{'No previous directorship found'}</PageTextContent>
      )}

      {CurrentProprietorship && (
        <>
          <NewDirectorshipInfo
            isProprietorship={true}
            directorshipData={[CurrentProprietorship]}
          />
        </>
      )}
      {!CurrentProprietorship && (
        <PageTextContent>{'No current proprietorship found'}</PageTextContent>
      )}
    </>
  )

  return (
    <>
      <DatabasesCheckComponent
        label="Legal Records"
        icon={legalRecords}
        statusClass={statusClass}
        statusText={statusText}
        updateDate={updateDate}
        leftBody={leftContent}
        rightBody={rightContent}
      />
    </>
  )
}

export const PartOne = ({
  Bankruptcies = {},
  CourtRecords = {},
  DirectorshipsProprietorships = {},
  updateDate = '',
  backgroundCheckResponse = {},
  backgroundCheck = {},
}) => {
  const { BankruptcyMessage = '', bankruptcyRecordsList } = Bankruptcies

  const { CourtRecords: courtRecordsList = [] } = CourtRecords
  const {
    isBankruptciesResults,
    isCourtRecordsResults,
    isDirectorshipsProprietorshipsResult,
  } = checkBankruptcyCourtRecordsASISFromBGCheckResponse(
    backgroundCheckResponse,
    backgroundCheck,
  )

  const isValid = !(
    isBankruptciesResults ||
    isCourtRecordsResults ||
    isDirectorshipsProprietorshipsResult
  )

  const statusClass = isValid ? 'Verified' : 'identity-verification-unverified'
  const statusText = isValid ? 'Not Found' : 'Info'

  const {
    CurrentDirectorships = [],
    PreviousDirectorships = [],
    CurrentProprietorship = null,
    DisqualifiedDirectorship = null,
  } = DirectorshipsProprietorships

  const isDirectorshipsAndProprietorshipsRecord =
    (!CurrentDirectorships || CurrentDirectorships.length === 0) &&
    (!PreviousDirectorships || PreviousDirectorships.length === 0) &&
    !CurrentProprietorship &&
    !DisqualifiedDirectorship

  const leftContent = (
    <>
      {(bankruptcyRecordsList || []).length > 0 ? (
        <CheckMark text="Bankruptcy records found" iconType="info" />
      ) : (
        <CheckMark text="No Bankruptcy records found" iconType="verified" />
      )}
      {(courtRecordsList || []).length > 0 ? (
        <CheckMark text="Court records found" iconType="info" />
      ) : (
        <CheckMark text="No Court records found" iconType="verified" />
      )}
      {isDirectorshipsAndProprietorshipsRecord ? (
        <CheckMark
          text="No Directorships and Proprietorships records found"
          iconType="verified"
        />
      ) : (
        <CheckMark
          text="Directorships and Proprietorships records found"
          iconType="info"
        />
      )}
    </>
  )
  const rightContent = (
    <>
      <EquiFaxResponse isNotMb={true}>Equifax response:</EquiFaxResponse>
      <PageTextCategory isNotMb={true} fontWeight={theme.fontWeights[6]}>
        Bankruptcy History
      </PageTextCategory>
      <PageText isNotAlign={true}>{BankruptcyMessage}</PageText>
    </>
  )

  return (
    <>
      <DatabasesCheckComponent
        label="Legal Records"
        icon={legalRecords}
        statusClass={statusClass}
        statusText={statusText}
        updateDate={updateDate}
        leftBody={leftContent}
        rightBody={rightContent}
      />
    </>
  )
}

export const PartTwo = ({
  CourtRecords = {},
  DirectorshipsProprietorships = {},
  updateDate = '',
  backgroundCheckResponse = {},
  backgroundCheck = {},
}) => {
  const { CourtRecordMessage = '', CourtRecords: courtRecordsList = [] } =
    CourtRecords
  const {
    DirectorshipsProprietorshipsMessage = '',
    FileMessage = [],
    CurrentDirectorships = [],
    PreviousDirectorships = [],
    CurrentProprietorship = null,
    DisqualifiedDirectorship = null,
  } = DirectorshipsProprietorships

  const {
    isBankruptciesResults,
    isCourtRecordsResults,
    isDirectorshipsProprietorshipsResult,
  } = checkBankruptcyCourtRecordsASISFromBGCheckResponse(
    backgroundCheckResponse,
    backgroundCheck,
  )

  const isValid = !(
    isBankruptciesResults ||
    isCourtRecordsResults ||
    isDirectorshipsProprietorshipsResult
  )

  const statusClass = isValid ? 'Verified' : 'identity-verification-unverified'
  const statusText = isValid ? 'Not Found' : 'Info'

  const rightContent = (
    <>
      <EquiFaxResponse isNotMb={true}>Equifax response:</EquiFaxResponse>
      <PageTextCategory isNotMb={true} fontWeight={theme.fontWeights[6]}>
        Directorships and Proprietorships
      </PageTextCategory>
      <PageText pb={`${theme.dividedSpace[1]}px`} isNotAlign={true}>
        {DirectorshipsProprietorshipsMessage}
      </PageText>

      {FileMessage &&
        FileMessage.map((message, index) => (
          <PageText isNotAlign={true}>{message}</PageText>
        ))}
      {(FileMessage || []).length === 0 && (
        <PageTextContent>{'No information found'}</PageTextContent>
      )}

      {CurrentDirectorships && (
        <>
          <NewDirectorshipInfo directorshipData={CurrentDirectorships} />
        </>
      )}

      {(CurrentDirectorships || []).length === 0 && (
        <PageTextContent>{'No current directorship found'}</PageTextContent>
      )}

      {DisqualifiedDirectorship && (
        <>
          <NewDisqualifiedDirectorship
            directorshipData={DisqualifiedDirectorship}
          />
        </>
      )}
      {!DisqualifiedDirectorship && (
        <PageTextContent>
          {'No disqualified proprietorship found'}
        </PageTextContent>
      )}

      {PreviousDirectorships && (
        <>
          <NewDirectorshipInfo directorshipData={PreviousDirectorships} />
        </>
      )}
      {(PreviousDirectorships || []).length === 0 && (
        <PageTextContent>{'No previous directorship found'}</PageTextContent>
      )}

      {CurrentProprietorship && (
        <>
          <NewDirectorshipInfo
            isProprietorship={true}
            directorshipData={[CurrentProprietorship]}
          />
        </>
      )}
      {!CurrentProprietorship && (
        <PageTextContent>{'No current proprietorship found'}</PageTextContent>
      )}
    </>
  )
  const leftContent = (
    <>
      <EquiFaxResponse isNotMb={true}>Equifax response:</EquiFaxResponse>
      <PageTextCategory isNotMb={true} fontWeight={theme.fontWeights[6]}>
        Court Records
      </PageTextCategory>
      <PageTextCategory isNotMb={true} isNotAlign={true}>
        {CourtRecordMessage}
      </PageTextCategory>

      {courtRecordsList && (
        <>
          <CourtRecordsInfo courtRecordsData={courtRecordsList} />
        </>
      )}
      {(courtRecordsList || []).length === 0 && (
        <PageText>No current court records found</PageText>
      )}
    </>
  )

  return (
    <>
      <DatabasesCheckComponent
        label="Legal Records"
        subLabel="continuation"
        icon={legalRecords}
        statusClass={statusClass}
        statusText={statusText}
        updateDate={updateDate}
        leftBody={leftContent}
        rightBody={rightContent}
      />
    </>
  )
}

export default LegalRecords
