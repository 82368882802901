import React from 'react'

import { getNumberType } from 'libphonenumber-js'
import metadata from 'libphonenumber-js/metadata.full.json'
import moment from 'moment'

import { DetailsBoxCard } from 'app/components/display/display'
import * as helpers from 'app/sm/helpers'
import { trimAndAddEllipsesPastSpecifiedLength } from 'app/utils/strings/helpers'

const StudentBox = ({
  studentDetail,
  disabled = false,
  removeStudentDetails,
  from = '',
}) => {
  const {
    placeOfStudy,
    courseName,
    duration,
    durationType,
    startDate,
    studentType,
    enrolmentNumber,
    guidID: studentId,
    contactName,
    contactEmail,
    contactPhone,
    coordinatorName,
    coordinatorEmail,
    coordinatorMobile,
  } = studentDetail
  const durationText = `${duration} ${
    helpers.durationTypeToText[durationType]
  } start from ${moment(startDate).format(helpers.standardDateWithoutTime)}`
  const fromRenterSummary = from === 'renterSummary'
  const actualNumberType =
    !!contactPhone && getNumberType(contactPhone, metadata)
  const details = [
    `Course name: ${courseName}`,
    `Duration: ${durationText}`,
    `Student type: ${helpers.studentTypeToText[studentType]}`,
    `Enrolment number: ${enrolmentNumber}`,
    `Campus contact name: ${contactName ? contactName : 'N/A'}`,
    `Campus contact email: ${
      contactEmail
        ? trimAndAddEllipsesPastSpecifiedLength(contactEmail, 30)
        : 'N/A'
    }`,
    `Campus contact phone: ${
      contactPhone && actualNumberType === 'MOBILE' ? contactPhone : 'N/A'
    }`,
    `Campus contact office number: ${
      contactPhone && actualNumberType !== 'MOBILE' ? contactPhone : 'N/A'
    }`,
    `Coordinator name: ${coordinatorName ? coordinatorName : 'N/A'}`,
    `Coordinator email: ${
      coordinatorEmail
        ? trimAndAddEllipsesPastSpecifiedLength(coordinatorEmail, 30)
        : 'N/A'
    }`,
    `Coordinator mobile: ${coordinatorMobile ? coordinatorMobile : 'N/A'}`,
  ]
  return (
    <DetailsBoxCard
      title={placeOfStudy}
      titleClass={fromRenterSummary ? 'ml0' : 'ml20'}
      details={details}
      icon={'fa fa-id-badge'}
      componentClass={`${fromRenterSummary && 'no-box-shadow p0'}`}
      disabled={disabled}
      onRemove={() => removeStudentDetails(studentId)}
      editUrl={helpers.urlTo('studentDetailsEdit', { studentId })}
    />
  )
}

export default StudentBox
