import React from 'react'

import { Link } from 'react-router-dom'

import { history } from 'app/shared_components/router'
import { PropertyAdStatus, urlTo } from 'app/sm/helpers'
import { isCurrentRoute } from 'app/sm/router_helpers'

function isDraftAd(ad) {
  return (
    ad !== null &&
    (PropertyAdStatus.find((s) => s.id === ad.status) || {}).label === 'Draft'
  )
}

function bindCorrespondingContinueUrl(id, offerId) {
  if (isCurrentRoute('adFlowPortals')) {
    return urlTo('adFlowPayment', { propertyId: id, offerId })
  }
  return urlTo('updateOfferPayment', { propertyId: id, offerId })
}

class Portals extends React.Component {
  constructor(props) {
    super(props)
    let { listingPortals } = props
    this.state = { listingPortals, clicked: false }
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.ad.listingPortals !== undefined) {
      this.setState({
        listingPortals: newProps.ad.listingPortals,
      })
    }

    this.setState({
      clicked: newProps.error ? false : this.state.clicked,
    })

    this.setState({
      listingFee: newProps.listingFee,
    })
  }
  onContinueClicked() {
    this.setState({ clicked: true })
  }

  clear() {
    this.setState({ listingPortals: '' })
  }

  update() {
    return (event) => {
      let { name, id } = event.currentTarget
      let checks = this.state[name].split(';')

      let index = checks.indexOf(id)
      if (index == -1) {
        checks.push(id)
      } else {
        checks.splice(index, 1)
      }

      checks = checks.filter((a) => a != '').join(';')
      this.setState({ [name]: checks })
    }
  }

  render() {
    let { listingPortals, listingFee, clicked } = this.state
    let { portals, updateAdvertisement, match, ad } = this.props

    //TODO bind corresponding back route when in the advertise flow
    let { id, propertyId, offerId } = match.params
    let back = isCurrentRoute('adFlowPortals')
      ? urlTo('adFlowAmenities', { propertyId })
      : urlTo('updateOfferBase', { offerId, propertyId: id || propertyId })
    listingPortals = listingPortals.split(';')
    let numPortals = listingPortals.filter((p) => p != '').length

    return (
      <div className="sm-property portals">
        <div className="mobile-only">
          <Link
            to={back}
            className="btn btn-transparent btn-left xs-text-center wa"
          >
            <i className="icon-arrow-left left"></i>
            <span>Back</span>
          </Link>
        </div>
        <div className="section-title">
          <div className="left column">
            <h3>Advertise your property</h3>
            <p>
              Advertise your property on the leading free and paid property
              portals with just a few clicks
            </p>
          </div>
        </div>
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <div className="row">
              <div className="col-sm-12 pb10">
                <a className="gray-color" onClick={this.clear.bind(this)}>
                  Clear Selection
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="sm-checkbox-list">
                  <div className="sm-checkbox-list__section">
                    {portals.map((portal) => {
                      let { id, name } = portal
                      let checked = listingPortals.includes(id.toString())
                      return (
                        <div className="sm-checkbox-list__item" key={id}>
                          <label>
                            <input
                              type="checkbox"
                              name="listingPortals"
                              id={id}
                              checked={checked}
                              onChange={this.update()}
                            />
                            <span>{name}</span>
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt25 mobile-form-button">
              <div className="col-sm-5 pb5 tablet-only">
                <Link
                  to={back}
                  className="btn btn-transparent btn-left xs-text-center wa"
                >
                  <i className="icon-arrow-left left"></i>
                  <span>Back</span>
                </Link>
              </div>
              <div className="col-sm-7 pb5">
                <button
                  className="btn"
                  disabled={clicked}
                  onClick={() => {
                    this.onContinueClicked()
                    updateAdvertisement(
                      bindCorrespondingContinueUrl(id || propertyId, offerId),
                    )(offerId, this.state)
                  }}
                >
                  {isDraftAd(ad)
                    ? 'Update draft ad'
                    : `Advertise on ${numPortals} portals`}{' '}
                  <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
                </button>
              </div>
            </div>
          </div>
          <div className="col-second xs-first">
            <ul className="snug-tips">
              <li>Maximise your exposure on leading portals</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Portals
