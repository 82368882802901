import React from 'react'

import { Link } from 'react-router-dom'

import * as validations from 'app/shared_components/validations'

function ProfileBankForm() {
  return (
    <div className="two-col-box-flex-row sm64">
      <div className="col-first">
        <h3>Bank Account</h3>

        <ul className="snug-infos mb30">
          <li>Snug uses bank-level 256-bit security to protect your data.</li>
        </ul>

        <div className="input-box">
          <input type="text" required />
          <label>Account Name</label>
        </div>

        <div className="input-box">
          <input type="text" required />
          <label>BSB</label>
        </div>

        <div className="input-box">
          <input type="text" required />
          <label>Account Number</label>
        </div>

        <button className="mt25">Save</button>
      </div>
    </div>
  )
}

export default ProfileBankForm
