import { connect } from 'react-redux'

import Preview from 'app/pages/teams/viewings-run/components/preview-container'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'
import {
  createNewViewingRunVersion,
  fetchNewViewingRunVersion,
  formNewViewingRun,
  notifyLateViewing,
} from 'app/sm/viewings_new_run/action'

const mapStateToProps = ({ ViewingRun, session, NewViewingsRunReducer }) => ({
  viewingRun: ViewingRun.viewingRun,
  teams: session.teams || [],
  preferencesHasUpdates: NewViewingsRunReducer.hasUpdates,
  allManagersSelected: NewViewingsRunReducer.allManagersSelected,
  managerPreferences: NewViewingsRunReducer.managerPreferences,
  startTime: NewViewingsRunReducer.startTime,
  selectedPropertyInfos: NewViewingsRunReducer.selectedPropertyInfos,
  isRunReadOnly: NewViewingsRunReducer.isReadOnly,
})

const mapDispatchToProps = (dispatch) => ({
  formNewViewingRun: (teamGUID, managerPreferences) =>
    dispatch(formNewViewingRun(teamGUID, managerPreferences)),
  createNewViewingRunVersion: (teamGUID, managerPreferences) =>
    dispatch(createNewViewingRunVersion(teamGUID, managerPreferences)),
  fetchNewViewingRunVersion: (teamGUID, viewingRunGUID) =>
    dispatch(fetchNewViewingRunVersion(teamGUID, viewingRunGUID)),
  notifyLateViewing: (data, viewingGUID) =>
    dispatch(notifyLateViewing(data, viewingGUID)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Preview)
