import { connect } from 'react-redux'

import ApplyConfirmationContainer from 'app/match/apply/application_confirmation/component'
import { setBackUrl } from 'app/shared_components/actions'
import {
  fetchApplicantConfirmationSummary,
  fetchRenterSummary,
  resetState,
} from 'app/sm/apply/apply_actions'
import {
  acceptSecondaryWithdraw,
  acceptWithdraw,
} from 'app/sm/withdraw_confirmation/withdraw_confirmation_actions'

const mapStateToProps = ({
  session,
  apply,
  BackgroundCheck: { backgroundCheck },
}) => ({
  currentUser: session.currentUser,
  confirmationSummary: apply.confirmationSummary,
  backgroundCheck,
})

const mapDispatchToProps = (dispatch) => ({
  fetchApplicantConfirmationSummary: (applicationId) =>
    dispatch(fetchApplicantConfirmationSummary(applicationId)),
  acceptWithdraw: (id, success, fail) =>
    dispatch(acceptWithdraw(id, success, fail)),
  acceptSecondaryWithdraw: (applicationId, applicantId, success, fail) =>
    dispatch(
      acceptSecondaryWithdraw(applicationId, applicantId, success, fail),
    ),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
  resetState: () => dispatch(resetState()),
  fetchRenterSummary: (applicationId) =>
    dispatch(fetchRenterSummary(applicationId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplyConfirmationContainer)
