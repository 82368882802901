import React from 'react'
import { useState } from 'react'

import { Box } from 'app/components/design-system-components'
import { SearchRounded } from 'app/components/design-system-components/icons/actions'
import { Input } from 'app/components/design-system-components/inputs'
import { CheckboxesList } from 'app/components/design-system-components/list-filters/CheckboxesList'
import { DropdownBodySectionHeader } from 'app/components/design-system-components/list-filters/DropdownBodySectionHeader'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const propertyManagerFilterFields = {
  manager: 'manager',
}

export const propertyManagerFilterDataExtractor = (filterValues) => {
  const { [propertyManagerFilterFields.manager]: managersObj } = filterValues
  return {
    managers: Object.values(managersObj || {}).map(({ option }) => option),
  }
}

export const PropertyManagerAppliedFilterRenderer = ({ filterValues }) => {
  const { [propertyManagerFilterFields.manager]: managersObj } = filterValues
  return Object.values(managersObj)
    .map((managerOption) => managerOption.optionMeta.displayText)
    .join(', ')
}

export const isPropertyManagerApplied = (managerFilterValues) => {
  return (
    managerFilterValues &&
    managerFilterValues[propertyManagerFilterFields.manager] &&
    Object.values(managerFilterValues[propertyManagerFilterFields.manager])
      .length
  )
}

const PropertyManagersOptionsList = ({
  listTitle,
  options,
  selectedOptions,
  onCheckBoxClicked,
}) => {
  if (!options || !options.length) return null
  return (
    <>
      <DropdownBodySectionHeader
        header={listTitle}
        containerProps={{ mb: theme.space[4] + 'px' }}
      />
      <CheckboxesList
        options={options}
        onCheckBoxClicked={onCheckBoxClicked}
        checkedOptions={selectedOptions}
      />
    </>
  )
}

export const PropertyManagerFilterBody = ({
  formBag: { values, setFieldValue },
  managers,
}) => {
  const [searchText, setSearchText] = useState('')

  const onSearchTextChange = ({ target: { value } }) => {
    setSearchText(value)
  }

  const options = managers
    .map((manager) => {
      const { firstName, lastName, guidID } = manager.contact
      return {
        option: manager,
        optionMeta: {
          id: guidID,
          displayText: `${firstName} ${lastName}`,
        },
      }
    })
    .filter(({ optionMeta: { displayText } }) =>
      displayText.toLowerCase().includes(searchText.toLowerCase()),
    )

  const onCheckBoxClicked = (checked, option) => {
    const propertyValues = values[propertyManagerFilterFields.manager]

    if (checked) {
      setFieldValue(propertyManagerFilterFields.manager, {
        ...propertyValues,
        [option.optionMeta.id]: option,
      })
    } else {
      const { [option.optionMeta.id]: uncheckedOption, ...otherOptions } =
        propertyValues
      setFieldValue(propertyManagerFilterFields.manager, {
        ...otherOptions,
      })
    }
  }

  const renderOptions = () => {
    const meOptionIndex = options.findIndex(({ option: { isMe } }) => !!isMe)

    const selectedManagers = values[propertyManagerFilterFields.manager] || {}

    if (meOptionIndex < 0) {
      return (
        <PropertyManagersOptionsList
          options={options}
          listTitle="All Property Managers"
          onCheckBoxClicked={onCheckBoxClicked}
          selectedOptions={selectedManagers}
        />
      )
    }

    const clonedOptions = [...options]
    const [meOption] = clonedOptions.splice(meOptionIndex, 1)
    return (
      <Box maxHeight="300px" overflowY="auto">
        <PropertyManagersOptionsList
          options={[meOption]}
          listTitle="You"
          onCheckBoxClicked={onCheckBoxClicked}
          selectedOptions={selectedManagers}
        />
        <PropertyManagersOptionsList
          options={clonedOptions}
          listTitle="All Property Managers"
          onCheckBoxClicked={onCheckBoxClicked}
          selectedOptions={selectedManagers}
        />
      </Box>
    )
  }

  return (
    <Box maxHeight="100%">
      <Box p={theme.space[4] + 'px'}>
        <Input
          icon={<SearchRounded style={{ color: theme.colors.gray400 }} />}
          inputProps={{
            onChange: onSearchTextChange,
            placeholder: 'Search for a PM',
          }}
        />
      </Box>

      {renderOptions()}
    </Box>
  )
}
