import React from 'react'

import { Link } from 'react-router-dom'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

function OverdueLineChart() {
  const data = [
    { name: 'JAN', days: 1, fill: '#37c977', radius: 6 },
    { name: 'FEB', days: 8, fill: '#ffe757', radius: 6 },
    { name: 'MAR', days: 17, fill: '#ffe757', radius: 6 },
    { name: 'APR', days: 1, fill: '#37c977', radius: 6 },
    { name: 'MAY', days: 8, fill: '#ffe757', radius: 6 },
    { name: 'JUN', days: 3, fill: '#37c977', radius: 6 },
  ]
  return (
    <ResponsiveContainer>
      <BarChart data={data} height={220}>
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          height={30}
          tickSize={20}
        />
        <YAxis axisLine={false} tickLine={false} width={30} tickSize={20} />
        <CartesianGrid stroke="#e8e8e9" vertical={false} />
        <Tooltip />
        <Bar dataKey="days" barSize={12} />
      </BarChart>
    </ResponsiveContainer>
  )
}

function Rent() {
  return (
    <div className="sm-property-lease-details-card rent">
      <div className="header">
        <h4>Rent Overdue Index Last 6 months</h4>
      </div>
      <div className="content">
        <div className="overdue-chart">
          <OverdueLineChart />
        </div>
      </div>
    </div>
  )
}

export default Rent
