import { api } from 'app/utils/api/helpers'

export const addPropertyOfferDisclosureAttachments = (
  agencyGUID,
  offerGUID,
  formData,
) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  return api.post(
    `sm/agency/${agencyGUID}/offer/${offerGUID}/propertydisclosureattachments`,
    formData,
    headers,
  )
}
