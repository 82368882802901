import React from 'react'

import PropTypes from 'prop-types'

import Card from 'app/match/applicationReportPDF/components/Card'
import { attachList } from 'app/match/applicationReportPDF/helpers/utils'

const propTypes = {
  data: PropTypes.object,
}

const OtherAttachments = ({ data }) => {
  return (
    <div className="other-attachments no-break-section">
      <Card
        type={'icon'}
        icon={'icon-note-code'}
        title={'Other attachments'}
        addClass="bottom-border"
      />
      {Object.keys(data) && <div>No information is provided</div>}
      {attachList(data, false)}
    </div>
  )
}

OtherAttachments.propTypes = propTypes

export default OtherAttachments
