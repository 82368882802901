import {
  CHECKBOX_CHANGED,
  CLEAR_ERROR,
  INPUT_CHANGED,
  PING,
  RATE,
  RECEIVE_ERROR,
  RECEIVE_PROPERTY,
  SET_PROPERTY_ID,
} from 'app/sm/review/review_actions'

const _defaultState = {
  property: {
    agent: {},
  },
  propertyId: null,
  review: {
    property: {
      title: '',
      description: '',
      isPrivate: false,
      score: 0,
    },
    agent: {
      title: '',
      description: '',
      isPrivate: false,
      score: 0,
    },
  },
  error: '',
}

const ReviewReducer = (state = _defaultState, action) => {
  let { type, role, key, value, score, propertyId, property, error } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)
  let it = null

  switch (type) {
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    case PING:
      // This is a hacky way to invalidate the error when user clicks on the alert danger chevron
      return Object.assign(newState, { error: '' })
    case SET_PROPERTY_ID:
      return Object.assign(newState, {
        propertyId,
      })

    case RATE:
      it = Object.assign(newState.review[role], {
        score,
      })

      return Object.assign(newState, {
        review: Object.assign(newState.review, {
          [role]: it,
        }),
      })

    case INPUT_CHANGED:
      it = Object.assign(newState.review[role], {
        [key]: value,
      })

      return Object.assign(newState, {
        review: Object.assign(newState.review, {
          [role]: it,
        }),
      })

    case CHECKBOX_CHANGED:
      it = Object.assign(newState.review[role], {
        [key]: !state.review[role][key],
      })

      return Object.assign(newState, {
        review: Object.assign(newState.review, {
          [role]: it,
        }),
      })
    case RECEIVE_PROPERTY:
      return Object.assign(newState, {
        property,
      })
    default:
      return state
  }
}

export default ReviewReducer
