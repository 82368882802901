import React from 'react'

import styled from 'styled-components'

import * as FormComponent from 'app/shared_components/form_component'
import * as vaccinationHelpers from 'app/utils/vaccination/helpers'

const StyledRadioComponent = styled.div`
  .form-radio-group {
    &-controls {
      @media (max-width: 576px) {
        display: block;
      }
    }
  }
  input[type='checkbox'] + span {
    font-weight: unset;
    color: unset;
  }
  .form-checkbox {
    margin-bottom: 12px;
  }
`

function VaccinationRadioComponent({
  covid19VaccinationStatusError,
  covid19VaccinationStatus,
  onChangeRadioGroupCovid19VaccinationStatus,
}) {
  return (
    <div>
      <h5 className="mt10 mb10">Vaccination status</h5>
      <StyledRadioComponent>
        <FormComponent.RadioGroup
          label="Until COVID-19 restrictions are removed, to manage health and safety risks for teams and tenants, please advise if you are fully vaccinated against COVID-19?"
          id="fullyVaccinated"
          field="covid19VaccinationStatus"
          error={covid19VaccinationStatusError}
          componentClassName="property-details-radio-group lh16"
        >
          <FormComponent.Checkbox
            id="fullyVaccinated"
            label="Fully vaccinated"
            checked={
              covid19VaccinationStatus ===
              vaccinationHelpers.FULLY_VACCINATED_STATUS
            }
            onChange={(event) =>
              onChangeRadioGroupCovid19VaccinationStatus(event)
            }
          />
          <FormComponent.Checkbox
            id="notVaccinated"
            label="Not vaccinated"
            checked={
              covid19VaccinationStatus ===
              vaccinationHelpers.NOT_VACCINATED_STATUS
            }
            onChange={(event) =>
              onChangeRadioGroupCovid19VaccinationStatus(event)
            }
          />
          <FormComponent.Checkbox
            id="preferNotToSay"
            label="Prefer not to answer"
            checked={
              covid19VaccinationStatus ===
              vaccinationHelpers.PREFER_NOT_TO_ANSWER_VACCINATED_STATUS
            }
            onChange={(event) =>
              onChangeRadioGroupCovid19VaccinationStatus(event)
            }
          />
        </FormComponent.RadioGroup>
      </StyledRadioComponent>
    </div>
  )
}

export default VaccinationRadioComponent
