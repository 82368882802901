import { connect } from 'react-redux'

import { updateReaListings } from 'app/bond_cover/agency/agency_actions'
import {
  fetchReaAgencyId,
  fetchTeamManagerList,
} from 'app/bond_cover/agency/agency_actions'
import { fetchManagerDashboard } from 'app/dashboard/dashboard_actions'
import ManagerDashboardContainer from 'app/dashboard/manager_dashboard_container'
import { setBackUrl } from 'app/shared_components/actions'
import {
  changeRole,
  fetchProperties,
} from 'app/sm/properties/properties_actions'

const mapStateToProps = ({ session, dashboard, agency, MyProperties }) => ({
  currentUser: session.currentUser,
  bondCovers: dashboard.bondCovers,
  currentAgency: session.currentAgency,
  agencyDashboardSpinner: agency.agencyDashboardSpinner,
  // apiError: dashboard.error,
  agencyApiError: agency.error,
  teams: session.teams,
  currentTeam: session.currentTeam,
})

const mapDispatchToProps = (dispatch) => ({
  fetchManagerDashboard: () => dispatch(fetchManagerDashboard()),
  updateReaListings: (query, success, fail) =>
    dispatch(updateReaListings(query, success, fail)),
  changeRole: (role) => dispatch(changeRole(role)),
  fetchReaAgencyId: (agencyGUID, success, fail) =>
    dispatch(fetchReaAgencyId(agencyGUID, success, fail)),
  fetchProperties: (teamGUID) => dispatch(fetchProperties(teamGUID)),
  setBackUrl: (backUrl) => dispatch(setBackUrl(backUrl)),
  fetchTeamManagerList: (teamGUID, success, fail) =>
    dispatch(fetchTeamManagerList(teamGUID, success, fail)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManagerDashboardContainer)
