import React from 'react'

import $ from 'jquery'
import { CSSTransitionGroup } from 'react-transition-group'
import styled from 'styled-components'

import Application from 'app/application_revamp/components/Applicant/Application'
import Child from 'app/application_revamp/components/Applicant/Child'
import data from 'app/application_revamp/components/Applicant/data.json'
import Occupant from 'app/application_revamp/components/Applicant/Occupant'
import PrimaryApplicant from 'app/application_revamp/components/Applicant/PrimaryApplicant'
import SecondaryApplicant from 'app/application_revamp/components/Applicant/SecondaryApplicant'

const BtnHolder = styled.div`
  text-align: center;
  padding: 21px 24px;
`

const ViewBtn = styled.button`
  cursor: pointer;
  text-decoration-line: underline;
  text-align: center;
  color: var(--pdf-blue);
  font-size: 16px;
  line-height: 1.2;
  background: transparent;
  padding: 0;
  border: none;
  outline: none;

  &:hover {
    text-decoration: none;
  }
`

const Holder = styled.div`
  position: relative;
  background: var(--pdf-white);
  border: 1px solid var(--pdf-gray-middle);
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    overflow-y: auto;
    max-height: ${(props) => props.leftSwitcherMaxHeight}px;
  }
`

const IsOpenChild = 'isOpenedChild'
const IsOpenOccupant = 'isOpenedOccupant'
const isApplicationListDisplayForApplied = true
const isApplicationListDisplayForInvited = false

class Applicant extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      selectedGUID: '',
      collapse: false,
    }
  }

  componentDidMount() {
    const isMobile = window.innerWidth < 768
    this.setState({
      primary: data.primary,
      applicants: data.applicants,
      view: true,
      isMobile: false,
      isOpened: false,
      isOpenedChild: false,
      isOpenedOccupant: false,
      isSmall: isMobile,
      selectedGUID: '',
      isApplicationListDisplay: isApplicationListDisplayForApplied,
    })
    $('#index').addClass('no-navbar-menu')
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    $('#index').removeClass('no-navbar-menu')
  }

  changeHandler = (id, applied) => {
    let isApplicationListDisplay = this.state.isApplicationListDisplay
    let selectedID = ''
    if (applied) {
      this.props.handleApplicantChange(id)
      isApplicationListDisplay = isApplicationListDisplayForApplied
    } else {
      selectedID = id
      isApplicationListDisplay = isApplicationListDisplayForInvited
    }
    this.setState({
      selectedGUID: selectedID,
      isOpenedOccupant: false,
      isOpenedChild: false,
      collapse: false,
      isApplicationListDisplay,
    })
  }

  handleResize = () => {
    const isMobile = window.innerWidth < 768
    this.setState({
      isSmall: isMobile,
    })
  }

  openHandler = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    })
  }

  toggleField = (id, field) => {
    let { isOpenedOccupant, isOpenedChild } = this.state || {}

    if (field === IsOpenChild) {
      isOpenedOccupant = false
    } else if (field === IsOpenOccupant) {
      isOpenedChild = false
    }
    this.setState({
      [field]: !this.state[field],
      selectedGUID: id,
      collapse: true,
      isOpenedOccupant,
      isOpenedChild,
    })
  }

  render() {
    const {
      primary = [],
      applicants = [],
      view,
      isSmall,
      isOpened,
      isOpenedOccupant,
      selectedGUID,
      collapse,
      isApplicationListDisplay,
    } = this.state

    const {
      applicants: applicantList = [],
      selectedApplicantGUID = '',
      occupants = [],
      children = [],
      downloadPDFButton,
      leftSwitcherMaxHeight,
      interpreteNoteResult,
      onRequestSubmitApplication,
      lastSubmitApplicationRequestTime,
      isReminderSent,
      isManagerAdded,
      idDocsNotRequested = false,
      disabledSections,
      ballotsEnabled = false,
      toggleRequestInfoModal,
      numberOfReqInfoItemsSelected,
      sendRequestInfo,
    } = this.props

    let primaryGUID = selectedApplicantGUID
    if (selectedGUID !== '') {
      primaryGUID = selectedGUID
    }

    const transitionOptions = {
      transitionName: 'fade',
      transitionEnterTimeout: 300,
      transitionLeaveTimeout: 300,
    }

    let members = applicantList
    members = members.concat(occupants)
    const primary_applicant =
      members.filter((item) => item.guidID === primaryGUID) || []

    let secondary = members.filter((item) => item.guidID !== primaryGUID) || []

    let box
    let btnText

    if (!view && !isSmall && primary.length > 0) {
      box = (
        <div key="comp2">
          {applicants.length > 0 &&
            applicants.map((applicant) => (
              <Application key={applicant.id} application={applicant} />
            ))}
        </div>
      )
      btnText = `Back to ${primary[0].first_name || 'John'} ${
        primary[0].last_name || 'Doe'
      } +${secondary.length}`
    } else {
      box = (
        <div key="comp1">
          <CSSTransitionGroup {...transitionOptions}>
            {primary_applicant.map((item) => {
              return (
                <PrimaryApplicant
                  applicant={item}
                  key={item.guidID}
                  open={this.openHandler}
                  downloadPDFButton={downloadPDFButton}
                  interpreteNoteResult={interpreteNoteResult}
                  collapse={collapse}
                  isApplicationListDisplay={isApplicationListDisplay}
                  isManagerAdded={isManagerAdded}
                  idDocsNotRequested={idDocsNotRequested}
                  disabledSections={disabledSections}
                  ballotsEnabled={ballotsEnabled}
                  toggleRequestInfoModal={toggleRequestInfoModal}
                  numberOfReqInfoItemsSelected={numberOfReqInfoItemsSelected}
                  sendRequestInfo={sendRequestInfo}
                />
              )
            })}
          </CSSTransitionGroup>
          {isOpened || !isSmall
            ? secondary.map((item) => {
                return item.applicationID ? (
                  <SecondaryApplicant
                    key={item.guidID}
                    change={this.changeHandler}
                    applicant={item}
                    applicationID={item.applicationID}
                    onRequestSubmitApplication={onRequestSubmitApplication}
                    lastSubmitApplicationRequestTime={
                      lastSubmitApplicationRequestTime
                    }
                    isReminderSent={isReminderSent}
                    isManagerAdded={isManagerAdded}
                    ballotsEnabled={ballotsEnabled}
                  />
                ) : (
                  <Occupant
                    key={item.id}
                    occupant={item}
                    isOpened={isOpenedOccupant}
                    open={this.toggleField}
                  />
                )
              })
            : ''}
          {isOpened || !isSmall
            ? children.map((item, index) => (
                <Child
                  index={index}
                  child={item}
                  key={item.guidID}
                  open={this.toggleField}
                />
              ))
            : null}
        </div>
      )
      btnText = `View ${applicants.length} other applications `
    }

    return (
      <Holder
        leftSwitcherMaxHeight={leftSwitcherMaxHeight}
        className={`applicant-widget left-nav-switch ${
          isOpened ? 'opened' : ''
        } ${isSmall ? 'is-mobile' : ''}`}
      >
        <CSSTransitionGroup {...transitionOptions}>{box}</CSSTransitionGroup>
        {/* TODO: Enable once household switcher is merged */}
        {false && applicants.length && !isSmall ? (
          <BtnHolder>
            <ViewBtn
              onClick={() => {
                this.toggleField()
              }}
            >
              {btnText}
            </ViewBtn>
          </BtnHolder>
        ) : (
          ''
        )}
      </Holder>
    )
  }
}

export default Applicant
