import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Flex, TextSpan } from 'app/components/design-system-components'
import { Select } from 'app/components/design-system-components/inputs/Select/Select'
import { FilterDateRange } from 'app/components/design-system-components/list-filters/FilterDateRange'
import { FilterDropdown } from 'app/components/design-system-components/list-filters/FilterDropdown'
import {
  dateRangeFiltersFields,
  getPeriodOptionValue,
  periodsOptions,
} from 'app/dashboard/team_activity/ActivityFilters/DateRangeFilter'
import { activityPeriods } from 'app/dashboard/team_activity/ActivityFilters/DateRangeFilter/constants'
import {
  buildRenderedFilterConfig,
  filtersIds,
} from 'app/dashboard/team_activity/ActivityFilters/filters.config'

export const ActivitiesFilters = ({
  filters,
  initialPeriod,
  updateFilter,
  teamId,
  managers,
  includeManagersFilter,
  onPeriodChange,
  onFiltersInitialized,
  ...containerProps
}) => {
  // filters initialization (period initialization only required)
  const [periodInitiator, setPeriodInitiator] = useState(
    periodsOptions.find((opt) => opt.optionMeta.id === initialPeriod),
  )

  onFiltersInitialized?.()

  const {
    [filtersIds.dateRange]: dateRangeFilterConfig,
    [filtersIds.manager]: managerFilterConfig,
  } = buildRenderedFilterConfig(filters, updateFilter, includeManagersFilter)

  useEffect(() => {
    onPeriodChange && onPeriodChange(periodInitiator)
    if (
      !periodInitiator ||
      periodInitiator.optionMeta.id === activityPeriods.custom
    ) {
      return
    }
    const range = getPeriodOptionValue(periodInitiator?.optionMeta.id)
    dateRangeFilterConfig.setFilterValues({
      [filtersIds.dateRange]: {
        ...{
          [dateRangeFiltersFields.start]: range.startDate,
          [dateRangeFiltersFields.end]: range.endDate,
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodInitiator])

  const onDateRangeManualUpdate = (date) => {
    setPeriodInitiator(
      periodsOptions.find(
        (opt) => opt.optionMeta.id === activityPeriods.custom,
      ),
    )
  }

  return (
    <Flex flexWrap="wrap" alignItems="center" {...containerProps}>
      <Select
        options={periodsOptions}
        value={periodInitiator}
        changeHandler={(opt) => setPeriodInitiator(opt)}
        label="Select period"
        resultsLabel="PERIODS"
        width="205px"
        triggerWrapperComponentProps={{ height: '40px' }}
        mr={4}
        mb={4}
      />
      <Box mb={4}>
        <FilterDateRange
          onChange={onDateRangeManualUpdate}
          filterConfig={dateRangeFilterConfig}
          dropDownWidth="175px"
        />
      </Box>
      {managerFilterConfig && (
        <Flex alignItems="center" mb={4}>
          {' '}
          <TextSpan mx={2} as="span">
            for
          </TextSpan>
          <FilterDropdown
            filterConfig={managerFilterConfig}
            filterBodyProps={{ managers }}
            triggerCmpProps={{ height: '40px', m: 3, sizeVariant: 'regular' }}
          />
        </Flex>
      )}
    </Flex>
  )
}

ActivitiesFilters.propTypes = {
  filters: PropTypes.any,
  initialPeriod: PropTypes.string,
  updateFilter: PropTypes.func,
  teamId: PropTypes.string,
  managers: PropTypes.any,
  includeManagersFilter: PropTypes.bool,
  onPeriodChange: PropTypes.func,
  onFiltersInitialized: PropTypes.any,
  children: PropTypes.element,
}
