import React from 'react'

import styled from 'styled-components'

import reminderBackground from 'app/assets/icons/bc-reminder.png'
import { Box, Button, Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'

const HELP_LINK = 'tenancydatabase.com.au/terms'

const ReminderContent = styled(Text)`
  margin: ${({ theme }) =>
    `${theme.dividedSpace[2]}px 0 ${theme.dividedSpace[2]}px 0`};
  padding-right: ${({ theme }) => `${theme.dividedSpace[8]}px`};
  text-align: justify;
  line-height: 1.4;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    justify-content: center;
    padding-right: 0px;
  }
`

const ReminderContainer = styled(Box)`
  margin: 70px 0 70px 0;
  @media print {
    margin: 20px 0 20px 0;
  }
`

const ReminderWrapper = styled(Flex)`
  justify-content: space-between;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    justify-content: center;
  }
`
const ReminderColumn = styled(Box)`
  width: 50%;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`

const Image = styled.img`
  width: 50%;
  @media screen and (max-width: 576px) {
    width: 100%;
    margin-top: ${({ theme }) => `${theme.dividedSpace[6]}px`};
  }
`

const HelpLink = styled(Button)`
  font-size: 16px;
  line-height: 1.4 !important;
  vertical-align: baseline;
`

const Header = styled.div`
  font-size: ${({ theme }) => `${theme.fontSizes[10]}px`};
  font-weight: ${({ theme }) => `${theme.fontWeights[6]}`};
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[10]}px`};
  white-space: nowrap;
  overflow: visible;
  text-overflow: clip;
  @media print {
    margin-bottom: ${({ theme }) => `${theme.dividedSpace[10]}px`};
  }

  @media screen and (max-width: 576px) {
    white-space: normal;
  }
`

const Reminder = () => {
  return (
    <ReminderContainer>
      <ReminderWrapper>
        <ReminderColumn>
          <Header>Important information about this Background Check</Header>
          <ReminderContent>
            Snug.com provides information of a general nature and does not
            provide legal, financial or compliance advice. You should conduct
            your own due diligence and seek independent expert advice. Tenancy
            legislation and regulations are subject to frequent change so we
            recommend you check the latest requirements in your State or
            Territory. To the extent permitted by law, Snug accepts no liability
            for loss or damages on any basis (including negligence) of
            whatsoever nature (including consequential loss) for errors,
            omission or reliance on this report. Please view the Terms of Use
            and Privacy Policy at Snug.com for full terms and conditions. This
            background checked is powered by Equifax on these terms:{' '}
            <HelpLink
              variant="linkBlue"
              onClick={() => {
                window.open(HELP_LINK, '_blank').focus()
              }}
              fontSize="16px"
            >
              {HELP_LINK}
            </HelpLink>{' '}
          </ReminderContent>
        </ReminderColumn>
        <Image src={reminderBackground} alt="dismiss" />
      </ReminderWrapper>
    </ReminderContainer>
  )
}

export default Reminder
