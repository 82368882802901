import React from 'react'

import moment from 'moment'
import DateTime from 'react-datetime'

import 'app/sm/progress_application/components/input_box_collection/style.scss'
import 'react-datetime/css/react-datetime.css'

class EditableInputComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 'read',
    }
  }

  onEditClicked = () => {
    const { mode } = this.state
    if (mode === 'read') this.setState({ mode: 'edit' })
    if (mode === 'edit') this.setState({ mode: 'read' })
  }

  onInputBoxBlur = () => {
    this.setState({ mode: 'read' })
  }

  render() {
    const {
      image = '',
      title = '',
      titleClassName = '',
      text = '',
      textClassName = '',
      extraText = '',
      extraTextClassName = '',
      componentClassName = '',
      hookedText = '',
      enableEdit = false,
      prefixText = '',
      onChange,
    } = this.props
    const { mode = 'read' } = this.state
    return (
      <div
        className={`application-detials-numbers-component ${componentClassName} min-width100p`}
      >
        <div className="info-frame relative width100">
          <div className={`title mb5 ${titleClassName}`}>
            {image && <img className="mr5 pb2" src={image} />}
            {title}
          </div>
          <div className="display-flex">
            <p className={`content fw500`}>{prefixText}</p>
            {mode === 'edit' && (
              <input
                type="number"
                value={text}
                onChange={onChange}
                onBlur={this.onInputBoxBlur}
                className="lease-details-inputbox"
              />
            )}
            {mode === 'read' && (
              <p className={`content fw500 ${textClassName}`}>{text}</p>
            )}
            <p className={`content fw500 ${extraTextClassName}`}>{extraText}</p>
            {enableEdit && (
              <p
                onClick={this.onEditClicked}
                className="content fw500 blue-link-style ml10"
              >
                {mode === 'read' ? 'edit' : 'save'}
              </p>
            )}
          </div>
          {hookedText && <div className="hook-text">{hookedText}</div>}
        </div>
      </div>
    )
  }
}

class EditableDatePickerComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 'read',
    }
  }

  onEditClicked = () => {
    const { mode } = this.state
    if (mode === 'read') this.setState({ mode: 'edit' })
    if (mode === 'edit') this.setState({ mode: 'read' })
  }

  onInputBoxBlur = () => {
    //change value here
    this.setState({ mode: 'read' })
  }

  render() {
    const {
      image = '',
      title = '',
      titleClassName = '',
      text = '',
      textClassName = '',
      extraText = '',
      extraTextClassName = '',
      componentClassName = '',
      hookedText = '',
      enableEdit = false,
      onChange,
      pickerStatus = false,
    } = this.props
    const { mode = 'read' } = this.state
    const formatDate = moment(text).format('DD MMM YYYY')
    return (
      <div
        className={`application-detials-numbers-component ${componentClassName} min-width100p`}
      >
        <div className="info-frame relative width100">
          <div className={`title mb5 ${titleClassName}`}>
            {image && <img className="mr5 pb2" src={image} />}
            {title}
          </div>
          <div className="display-flex">
            {mode === 'edit' && (
              <DateTime
                value={formatDate}
                viewMode={'days'}
                className={'lease-details-datepicker-box m0'}
                onChange={onChange}
                open={pickerStatus}
                inputProps={{ readOnly: true }}
              />
            )}
            {mode === 'read' && (
              <p className={`content fw500 ${textClassName}`}>{formatDate}</p>
            )}
            <p className={`content fw500 ${extraTextClassName}`}>{extraText}</p>
            {enableEdit && (
              <p
                onClick={this.onEditClicked}
                className="content fw500 blue-link-style ml10"
              >
                {mode === 'read' ? 'edit' : 'save'}
              </p>
            )}
          </div>
          {hookedText && <div className="hook-text">{hookedText}</div>}
        </div>
      </div>
    )
  }
}

export default EditableInputComponent
export { EditableDatePickerComponent }
