import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getProofOfIncome = () => {
  const url = `${apiBaseUrl}sm/reputation/proofofincome`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
            statusText: res.statusText,
          }),
    )
    .then(
      (Record) => ({ ok: true, Record }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const getExtraIncome = () => {
  const url = `${apiBaseUrl}sm/reputation/proofofincome/other`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
            statusText: res.statusText,
          }),
    )
    .then(
      (extraIncome) => ({ ok: true, extraIncome }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const putExtraIncome = (referenceId, extraIncome) => {
  const url = `${apiBaseUrl}sm/reputation/proofofincome/${referenceId}/other`
  const settings = {
    credentials: 'include',
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(extraIncome),
  }

  const response = fetch(url, settings)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            statusText: res.statusText,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )

  return response
}

export const postExtraIncome = (extraIncome) => {
  const url = `${apiBaseUrl}sm/reputation/proofofincome/other`
  const settings = {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(extraIncome),
  }

  const response = fetch(url, settings)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            statusText: res.statusText,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )

  return response
}

export const uploadDocument = (formData) => {
  const url = `${apiBaseUrl}sm/reputation/proofofincome`
  const options = {
    method: 'POST',
    body: formData,
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            statusText: res.statusText,
            responseText: res.text(),
          }),
    )
    .then(
      (_) => ({ ok: true, _ }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const deleteDocument = (id) => {
  const url = `${apiBaseUrl}sm/reputation/proofofincome/${id}`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
            statusText: res.statusText,
          }),
    )
    .then(
      (_) => ({ ok: true, _ }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}
