import React from 'react'

const SnugLogo = ({ height = '24', className, wrapperClassName }) => (
  <div className={wrapperClassName}>
    <img
      className={className}
      height={height}
      src="https://s3-ap-southeast-2.amazonaws.com/snugco/assets/snug-com-logo.png"
    ></img>
  </div>
)

export default SnugLogo
