import React from 'react'

import { Link } from 'react-router-dom'

import { Box } from 'app/components/design-system-components'
import { AlertInfoBase } from 'app/components/design-system-components/AlertInfoBase'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { urlTo } from 'app/sm/helpers'
import { applicationsAttributesIDs } from 'app/sm/team_viewing_preference/applications-attributes.constants'

export const AgencyApplicationsLimitAlert = ({
  applicantApplicationsCount,
  teamName,
  applicationTeamSettings,
  showOnlyText = false,
  renderWithMargin = false,
}) => {
  const renderLimitAlert = (teamName, applicationsCount, agencyLimit) => {
    if (!applicationsCount)
      return showOnlyText
        ? renderNoCountText(teamName, agencyLimit, renderWithMargin)
        : renderNoCountAlert(teamName, agencyLimit, renderWithMargin)
    const limitReached = applicationsCount >= agencyLimit
    if (showOnlyText) {
      return limitReached
        ? renderLimitReachedText(
            teamName,
            agencyLimit,
            applicationsCount,
            renderWithMargin,
          )
        : renderLimitNotReachedText(
            teamName,
            agencyLimit,
            applicationsCount,
            renderWithMargin,
          )
    }
    return limitReached
      ? renderLimitReachedAlert(
          teamName,
          agencyLimit,
          applicationsCount,
          renderWithMargin,
        )
      : renderLimitNotReachedAlert(
          teamName,
          agencyLimit,
          applicationsCount,
          renderWithMargin,
        )
  }

  const renderNoCountText = (
    teamName,
    agencyLimit,
    renderWithMargin = false,
  ) => {
    return (
      <Box mt={renderWithMargin ? 7 : 0} ml={renderWithMargin ? 3 : 0}>
        {teamName} allows {agencyLimit} active applications.
      </Box>
    )
  }

  const renderNoCountAlert = (
    teamName,
    agencyLimit,
    renderWithMargin = false,
  ) => (
    <AlertInfoBase>
      {renderNoCountText(teamName, agencyLimit, renderWithMargin)}
    </AlertInfoBase>
  )

  const renderLimitNotReachedAlert = (
    teamName,
    agencyLimit,
    count,
    renderWithMargin = false,
  ) => (
    <AlertInfoBase>
      {renderLimitNotReachedText(
        teamName,
        agencyLimit,
        count,
        renderWithMargin,
      )}
    </AlertInfoBase>
  )

  const renderLimitNotReachedText = (
    teamName,
    agencyLimit,
    count,
    renderWithMargin = false,
  ) => {
    return (
      <Box mt={renderWithMargin ? 7 : 0} ml={renderWithMargin ? 3 : 0}>
        {teamName} allows {agencyLimit} active applications. You have {count}.
        Manage active applications:{' '}
        <Link
          to={urlTo('applications')}
          style={{ color: theme.colors.skyBlue, textDecoration: 'underline' }}
        >
          My Applications
        </Link>
      </Box>
    )
  }

  const renderLimitReachedText = (
    teamName,
    agencyLimit,
    count,
    renderWithMargin = false,
  ) => {
    return (
      <Box mt={renderWithMargin ? 7 : 0} ml={renderWithMargin ? 3 : 0}>
        {teamName} allows {agencyLimit} active applications. You have {count}.
        Manage active applications:{' '}
        <Link
          to={urlTo('applications')}
          style={{ color: theme.colors.skyBlue, textDecoration: 'underline' }}
        >
          My Applications
        </Link>
      </Box>
    )
  }

  const renderLimitReachedAlert = (teamName, agencyLimit, count) => (
    <AlertInfoBase>
      {renderLimitReachedText(teamName, agencyLimit, count)}
    </AlertInfoBase>
  )

  const limitFeatureEnabled =
    applicationTeamSettings &&
    (applicationTeamSettings[
      applicationsAttributesIDs.applicationLimitPerApplicantEnabled
    ] === 'true' ||
      applicationTeamSettings[
        applicationsAttributesIDs.applicationLimitPerApplicantEnabled
      ] === true)
  const limitFeatureValue =
    applicationTeamSettings &&
    applicationTeamSettings[
      applicationsAttributesIDs.applicationLimitPerApplicant
    ]
  const featureValid = limitFeatureEnabled && limitFeatureValue
  return (
    <>
      {featureValid &&
        renderLimitAlert(
          teamName,
          applicantApplicationsCount,
          limitFeatureValue,
          renderWithMargin,
        )}
    </>
  )
}
