import React from 'react'

import { Spinner as NewSpinner } from 'app/components/design-system-components/Spinner'

const Spinner = ({ text }) => (
  <div className="Spinner pdf-hide">
    <NewSpinner fontSize={'50px'} />
    {text && <p className="gray-color mt10 mb0">{text}</p>}
  </div>
)

export default Spinner
