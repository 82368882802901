import React from 'react'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { ModalContentWrapper } from 'app/modals/modal-styled'
import { urlTo } from 'app/sm/helpers'

// TODO: Close modal when clicked outside the wrapper on the overlay
class RolePrompt extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchTeams()
  }

  render() {
    const {
      bondCovers,
      currentAgency,
      applicationsCount = {},
      teams = [],
      backUrl,
    } = this.props
    const isAgency = currentAgency && currentAgency.agencyID
    const isFromVerifyForm = backUrl.includes('verify')
    const profileRegitrationTagClass = isFromVerifyForm
      ? 'match-registration'
      : ''
    let firstTeam = {}
    if (teams) {
      firstTeam = teams[1]
    }
    return (
      <div className="modal-fullscreen modal-layout-flex-container">
        <ModalContentWrapper>
          <div className="modal-close">
            {/* <i className="fa fa-times" onClick={() => ()} /> */}
          </div>
          <div className="modal-header">
            <h4>I'm here as</h4>
          </div>
          <div className="modal-body mb20">
            <Link
              className={`btn mt10 ${profileRegitrationTagClass}`}
              to="/home/overview"
            >
              A renter
            </Link>
            {(applicationsCount.myPortofolioProperties > 0 ||
              applicationsCount.managerBondCoverCount > 0) && (
              <div onClick={() => this.props.changeRole('individual')}>
                <Link className="btn mt10" to="/portfolio/overview">
                  A manager
                </Link>
              </div>
            )}
            {isAgency && (
              <div onClick={() => this.props.changeRole('agency')}>
                <Link
                  className="btn mt10"
                  to={urlTo('teamOverview', {
                    teamSlug: firstTeam ? firstTeam.slug : '',
                  })}
                >
                  An agency manager
                </Link>
              </div>
            )}
          </div>
        </ModalContentWrapper>
      </div>
    )
  }
}

RolePrompt.propTypes = {
  bondCovers: PropTypes.array.isRequired,
}

export default RolePrompt
