import React from 'react'

import moment from 'moment'

import addIcon from 'app/assets/icons/add-sign.png'
import removeIcon from 'app/assets/icons/cross-sign.png'
import DefaultButton from 'app/components/buttons/default/component'
import { Flex } from 'app/components/design-system-components'
import { getWeeklyRentDisplay, NO_IMAGE_PLACEHOLDER_URL } from 'app/sm/helpers'
import PropertyExcludedTimes from 'app/sm/properties/components/excluded_times/index'

export const SearchedPropertyResults = ({
  propertySearchResults,
  onAddButtonClick,
  hideResults,
  isPropertyAttached,
  category = 'existedInterestedProperties',
  isFlexibleTimePicked = false,
}) => {
  const showResults =
    !hideResults && propertySearchResults && propertySearchResults.data
  return (
    <div className={`${!isFlexibleTimePicked && 'disable-content'}`}>
      {showResults &&
        propertySearchResults.data
          .filter((propertyData) => propertyData.property.offers.length > 0)
          .map((propertyData) => {
            return (
              <PropertyItem
                key={propertyData.property.guidID}
                propertyItem={propertyData.property}
                weeklyRent={
                  propertyData.property &&
                  propertyData.property.offers &&
                  propertyData.property.offers.length > 0 &&
                  propertyData.property.offers[0].weeklyRent
                }
                availableFrom={
                  propertyData.property &&
                  propertyData.property.offers &&
                  propertyData.property.offers[0].availableFrom
                }
                onAddButtonClick={onAddButtonClick}
                showRemoveButton={false}
                showAddButton={true}
                isBtnDisplayToggled={true}
                isPropertyAttached={isPropertyAttached}
                category={category}
              />
            )
          })}
    </div>
  )
}

const existedInterestedProperties = 'existedInterestedProperties'
export const PropertyItem = ({
  propertyItem,
  weeklyRent,
  availableFrom,
  onAddButtonClick,
  onRemoveButtonClick,
  showAddButton,
  showRemoveButton,
  isPropertyAttached,
  category = { existedInterestedProperties },
  showExcludeScheduleSection = false,
  onExcludedPropertyDateEntriesChanged,
  disabled = false,
}) => {
  const {
    guidID,
    images,
    address: { friendlyName },
    bathrooms,
    bedrooms,
    parking,
  } = propertyItem
  return (
    <div className="mb40">
      <div className="display-flex overview-property-info space-between">
        <div className="display-flex">
          <div
            className="team-image relative"
            style={{
              backgroundImage: `url("${
                images[0] ? images[0].URL : NO_IMAGE_PLACEHOLDER_URL
              }")`,
            }}
          />
          <Flex flexDirection="column" justifyContent="center" pl={3}>
            <div className="font-size-18 mb0">
              <strong>{friendlyName}</strong>
            </div>
            <ul className="amenities-widget mb0">
              <li className="font-size-14 pr10">
                <i className="icon-bedroom" />
                <span>{bedrooms}</span>
              </li>
              <li className="font-size-14 pr10">
                <i className="icon-bathroom" />
                <span>{bathrooms}</span>
              </li>
              <li className="font-size-14 pr10">
                <i className="icon-cars" />
                <span>{parking}</span>
              </li>
            </ul>
            <div className="mb5 font-size-14 mb0">
              {getWeeklyRentDisplay(weeklyRent)}
            </div>
            <div className="font-size-14 mb0">
              Avail:{' '}
              {availableFrom
                ? moment(availableFrom).format('ddd DD MMM')
                : 'N/A'}
            </div>
          </Flex>
        </div>
        <div className="display-flex justify-content-flex-end align-items-center">
          {showAddButton && !isPropertyAttached(guidID) && !disabled && (
            <div className="display-flex flex-direction-column">
              <AddPropertyButton
                onAddButtonClick={onAddButtonClick(category)}
                guidID={guidID}
                src={addIcon}
                altText="Add"
              />
            </div>
          )}
          {showRemoveButton && !disabled && (
            <RemovePropertyButton
              onRemoveButtonClick={onRemoveButtonClick}
              guidID={guidID}
              src={removeIcon}
              altText="Remove"
            />
          )}
        </div>
      </div>
      {showExcludeScheduleSection && (
        <PropertyExcludedTimes
          currentExcludedDates={
            propertyItem?.excludedPropertyDetails?.excludedPropertyDates || []
          }
          onEntriesChanged={(entry) => {
            onExcludedPropertyDateEntriesChanged(propertyItem, entry)
          }}
          disabled={disabled}
        />
      )}
    </div>
  )
}

const RemovePropertyButton = ({
  onRemoveButtonClick,
  guidID,
  src,
  altText,
}) => {
  return (
    <div className="removeIcon">
      <img
        src={src}
        onClick={() => onRemoveButtonClick(guidID)}
        alt={altText}
      />
    </div>
  )
}

const AddPropertyButton = ({ onAddButtonClick, guidID, altText }) => {
  return (
    <DefaultButton
      componentClass="mt20"
      text="Add"
      onClick={() => onAddButtonClick(guidID)}
      alt={altText}
      buttonType="secondary"
      size="small"
    />
  )
}
