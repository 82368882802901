import {
  RECEIVE_PROPERTIES,
  TOGGLE_FAVORITE_MARK,
  TOGGLE_SPINNER,
} from 'app/sm/recently_viewed/recently_viewed_actions'

const _defaultState = {
  properties: [],
  error: '',
  spinner: false,
}

const RecentlyViewedReducer = (state = _defaultState, action) => {
  let { type, properties, propertyId, spinner } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case RECEIVE_PROPERTIES:
      return Object.assign(newState, { properties })
    case TOGGLE_FAVORITE_MARK:
      return Object.assign(newState, {
        properties: newState.properties.map((p) =>
          p.guidID !== propertyId
            ? p
            : Object.assign(p, { isFavourite: !p.isFavourite }),
        ),
      })
    case TOGGLE_SPINNER:
      return Object.assign(newState, { spinner })
    default:
      return state
  }
}

export default RecentlyViewedReducer
