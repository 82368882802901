import React from 'react'

import { Box } from 'app/components/design-system-components'
import { CheckboxesList } from 'app/components/design-system-components/list-filters/CheckboxesList'
import { DropdownBodySectionHeader } from 'app/components/design-system-components/list-filters/DropdownBodySectionHeader'
import {
  keySetStatusConfig,
  keySetStatuses,
} from 'app/constants/key-logger.constants'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const statusFiltersFields = {
  status: 'status',
}

const allStatusOption = {
  option: 'all',
  optionMeta: { id: 'all', displayText: 'All' },
}

export const isStatusFilterApplied = (statusFilterValues) =>
  statusFilterValues &&
  statusFilterValues[statusFiltersFields.status] &&
  Object.values(statusFilterValues[statusFiltersFields.status]).length

export const statusDataExtractor = (filterValues) => {
  const { [statusFiltersFields.status]: statusesObj } = filterValues
  return {
    statuses: Object.values(statusesObj || {})
      .filter(
        ({ optionMeta }) => optionMeta.id !== allStatusOption.optionMeta.id,
      )
      .map(({ optionMeta }) => optionMeta.id),
  }
}

export const StatusAppliedFilterRenderer = ({ filterValues }) => {
  const { [statusFiltersFields.status]: status } = filterValues
  if (status[allStatusOption.optionMeta.id])
    return allStatusOption.optionMeta.displayText
  return Object.values(status)
    .map((statusObj) => statusObj.optionMeta.displayText)
    .join(', ')
}

export const StatusFilterBody = ({ formBag: { values, setFieldValue } }) => {
  const onCheckBoxClicked = (checked, option) => {
    if (option.optionMeta.id === allStatusOption.optionMeta.id) {
      toggleAll(checked)
      return
    }
    const statusValues = values[statusFiltersFields.status]

    if (checked) {
      setFieldValue(statusFiltersFields.status, {
        ...statusValues,
        [option.optionMeta.id]: option,
      })
    } else {
      const {
        [option.optionMeta.id]: uncheckedOption,
        // if any status unchecked, should remove (All) filter if exists
        [allStatusOption.optionMeta.id]: allOption,
        ...otherOptions
      } = statusValues
      setFieldValue(statusFiltersFields.status, {
        ...otherOptions,
      })
    }
  }

  const toggleAll = (checked) => {
    if (checked) {
      setFieldValue(
        statusFiltersFields.status,
        Object.assign(
          {},
          ...statusesOptions.map((option) => ({
            [option.optionMeta.id]: option,
          })),
        ),
      )
    } else {
      setFieldValue(statusFiltersFields.status, {})
    }
  }

  const statusValues = values[statusFiltersFields.status] || {}

  const statusesOptions = Object.values(keySetStatuses).map((status) => ({
    option: status,
    optionMeta: {
      id: status,
      displayText: keySetStatusConfig[status]?.title,
      noSeparator: true,
    },
  }))

  statusesOptions.unshift(allStatusOption)

  return (
    <>
      <Box style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <DropdownBodySectionHeader
          header="STATUSES"
          containerProps={{ mb: theme.space[4] + 'px' }}
        />
        <CheckboxesList
          options={statusesOptions}
          checkedOptions={statusValues}
          onCheckBoxClicked={onCheckBoxClicked}
        />
      </Box>
    </>
  )
}
