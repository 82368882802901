import { LOCATION_CHANGE } from 'connected-react-router'

import {
  RECEIVE_EMPLOYEE,
  RECEIVE_EMPLOYER,
  RECEIVE_EMPLOYERS,
} from 'app/sm/employment/actions'

const _defaultState = {
  employer: {},
  employers: [],
  employee: {},
}

const Employment = (state = _defaultState, action) => {
  let { type, employers, employer, employee } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case RECEIVE_EMPLOYERS:
      return Object.assign({}, newState, { employers })
    case RECEIVE_EMPLOYER:
      return Object.assign({}, newState, { employer })
    case RECEIVE_EMPLOYEE:
      return Object.assign({}, newState, { employee })
    case LOCATION_CHANGE:
      return _defaultState
    default:
      return newState
  }
}

export default Employment
