import React from 'react'

import styled from 'styled-components'

import GenericButtonComponent from 'app/components/buttons/default/component'

const ModalBottomButtonGroupContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  margin-top: 40px;
  margin-bottom: 16px;
`

export const ButtonContainer = styled.div`
  width: 140px;
  height: 48px;
  text-align: center;
  margin-right: 8px;
  @media (max-width: 991px) {
    margin-bottom: 16px;
  }
`

const ModalBottomButtonGroup = ({
  primaryLabel = '',
  primaryText = '',
  secondaryLabel = '',
  secondaryText = '',
  primaryAction,
  secondaryAction,
  disable = false,
  secondaryButtonType = 'secondary',
  isLoading = false,
  secondaryDisabled = false,
  secondaryIsLoading = false,
  secondaryLoaderClassName = '',
  primaryLoaderClassName = '',
  showPrimaryButton = true,
  showSecondaryButton = true,
  containerClassName = '',
  justifyContent = 'center',
}) => {
  return (
    <ModalBottomButtonGroupContainer
      className={containerClassName}
      justifyContent={justifyContent}
    >
      <ButtonContainer>
        {showSecondaryButton && (
          <GenericButtonComponent
            onClick={() => secondaryAction()}
            buttonType={secondaryButtonType}
            buttonStyleClass="height-48"
            disabled={secondaryDisabled}
            isLoading={secondaryIsLoading}
            text={secondaryLabel ? '' : secondaryText}
            loaderClassName={secondaryLoaderClassName}
          >
            {secondaryLabel}
          </GenericButtonComponent>
        )}
      </ButtonContainer>
      <ButtonContainer>
        {showPrimaryButton && (
          <GenericButtonComponent
            onClick={() => primaryAction()}
            buttonStyleClass="height-48"
            disabled={disable}
            isLoading={isLoading}
            text={primaryLabel ? '' : primaryText}
            loaderClassName={primaryLoaderClassName}
          >
            {primaryLabel}
          </GenericButtonComponent>
        )}
      </ButtonContainer>
    </ModalBottomButtonGroupContainer>
  )
}

export default ModalBottomButtonGroup
