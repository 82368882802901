import React from 'react'

import { Alert, Box, Flex } from 'app/components/design-system-components'
import { formatStdTimeStamp } from 'app/utils/datetime/helpers'

function ReminderNotice({ timeStamp }) {
  const sentAt = formatStdTimeStamp(timeStamp)
  return (
    <Box my="16px">
      <Alert variant="greyWithBg">
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Box>Email Reminder</Box>
          </Flex>
          <Box style={{ textAlign: 'right' }}>
            <Box>{sentAt}</Box>
          </Box>
        </Flex>
      </Alert>
    </Box>
  )
}

export default ReminderNotice
