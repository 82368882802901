import { NetworkConnectionError } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  deleteListCalendar,
  getAgencyMembersList,
  getAgencyStatusReport,
  getListCalendar,
  getViewingPreference,
  postViewingPreference,
} from 'app/sm/team_viewing_preference/access'

export const updateViewingPreference = (teamId = '', preferenceInfo = {}) => {
  return (dispatch) => {
    return postViewingPreference(teamId, preferenceInfo).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const fetchViewingPreference = (teamId = '', propertyGUID = '') => {
  return (dispatch) => {
    return getViewingPreference(teamId, propertyGUID).then(
      ({ ok, responseText, preferenceInfo }) => {
        return Promise.resolve({ preferenceInfo })
      },
    )
  }
}

export const fetchAgencyStatusReport = (agencyGUID) => {
  return () => {
    return getAgencyStatusReport(agencyGUID).then(
      ({ ok, responseText, result }) => {
        if (ok) {
          return Promise.resolve({ result })
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const fetchAgencyMembersList = (agencyGUID) => {
  return () => {
    return getAgencyMembersList(agencyGUID).then(
      ({ ok, responseText, result }) => {
        if (ok) {
          return Promise.resolve({ result })
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}
export const syncCalendar = (agencyGUID) => {
  return getListCalendar(agencyGUID).then(
    ({ ok, responseText, calendarList }) => {
      if (ok) {
        return Promise.resolve({ calendarList })
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const unsyncCalendar = (platform, calendarGUID) => {
  return deleteListCalendar(platform, calendarGUID).then(
    ({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}
