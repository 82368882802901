import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const manualPostMatchRentalReference = (referenceId, payload) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${referenceId}/rentalreference/manual`

  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => {
        return {
          ok: false,
          statusCode: error.statusCode,
          responseText: error.responseText,
        }
      },
    )
}

export const postMatchRentalReference = (referenceId, payload, secretId) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${referenceId}/rentalreference?secret=${secretId}`

  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => {
        return {
          ok: false,
          statusCode: error.statusCode,
          responseText: error.responseText,
        }
      },
    )
}
