import styled from 'styled-components'

import shieldUser from 'app/assets/icons/shield-user.svg'
import { Box, Flex } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledContainer = styled(Flex)`
  background-color: ${theme.colors.watermelon};
  align-items: center;
  color: ${theme.colors.white};
  padding: ${theme.space[13]}px;
  .shield-image {
  }
  .rr-text {
    font-size: ${theme.fontSizes.h1}px;
    font-weight: ${theme.fontWeights.h1};
  }
  @media screen and (max-width: 576px) {
    margin-left: -${theme.space[6]}px;
    margin-right: -${theme.space[6]}px;
    margin-top: -${theme.space[6]}px;
  }
`

function RentalReferenceBanner({}) {
  return (
    <StyledContainer>
      <Box className="shield-image">
        <img src={shieldUser} />
      </Box>
      <Box className="rr-text" ml={8}>
        Rental Reference
      </Box>
    </StyledContainer>
  )
}

export default RentalReferenceBanner
