import React from 'react'

import IntlTelInput from 'react-intl-tel-input'
import { Link } from 'react-router-dom'

import { IntlTelInputWrapper } from 'app/components/design-system-components'
import { intTelTop10List } from 'app/shared_components/helpers'
import { urlTo } from 'app/sm/helpers'

const TenantForm = ({
  firstName,
  lastName,
  email,
  mobile,
  clicked,
  errors,
  apiError,
  updateEmail,
  trimField,
  update,
  submit,
  disabled,
  requestID,
  onCountrySelected,
  validatePhoneNumber,
  onMobileChange,
}) => (
  <div className="two-col-box-flex-row sm64">
    <div className="col-first">
      <div className="mobile-only">
        <Link
          to={urlTo('bondCoverTenants', { requestID })}
          className="btn btn-transparent btn-left xs-text-center wa"
        >
          <i className="icon-arrow-left left"></i>
          <span>Back</span>
        </Link>
      </div>
      <h3 className="mb25">Tenant Details</h3>
      <div className="row">
        <div className="col-xs-6">
          <div className="input-box">
            <input
              type="text"
              required
              id="firstName"
              className={errors.firstName ? 'error' : ''}
              value={firstName}
              onChange={update('firstName')}
              onBlur={trimField('firstName')}
            />
            <label>First Name</label>
          </div>
        </div>
        <div className="col-xs-6">
          <div className="input-box">
            <input
              type="text"
              required
              id="lastName"
              className={errors.lastName ? 'error' : ''}
              value={lastName}
              onChange={update('lastName')}
              onBlur={trimField('lastName')}
            />
            <label>Last Name</label>
          </div>
        </div>
      </div>

      <div className="input-box">
        <input
          type="email"
          required
          id="email"
          className={errors.email ? 'error' : ''}
          value={email}
          onChange={updateEmail('email')}
          onBlur={trimField('email')}
        />
        <label>Email address</label>
      </div>

      <div className={`input-box ${errors.mobile ? 'error' : ''}`}>
        <IntlTelInputWrapper
          css={['intl-tel-input', '']}
          utilsScript={'libphonenumber.js'}
          defaultCountry={'au'}
          preferredCountries={intTelTop10List}
          onPhoneNumberChange={onMobileChange()}
          onPhoneNumberBlur={validatePhoneNumber()}
          onSelectFlag={onCountrySelected}
          value={mobile}
          placeholder={'e.g. +61 412 345 678'}
        />
        <p>Phone Number</p>
      </div>

      <ul className="snug-tips">
        <li>Please signup with details that match your identity documents.</li>
      </ul>

      <div
        className={
          errors.firstName ||
          errors.lastName ||
          errors.email ||
          errors.mobile ||
          apiError
            ? 'alert alert-danger'
            : ''
        }
      >
        <div className={!errors.firstName && 'hidden'}>{errors.firstName}</div>
        <div className={!errors.lastName && 'hidden'}>{errors.lastName}</div>
        <div className={!errors.email && 'hidden'}>{errors.email}</div>
        <div className={!errors.mobile && 'hidden'}>{errors.mobile}</div>
        <div className={!apiError && 'hidden'}>{apiError}</div>
      </div>

      <div className="row pt25 mobile-form-button">
        <div className="col-sm-5 pb5 tablet-only">
          <Link
            to={urlTo('bondCoverTenants', { requestID })}
            className="btn btn-transparent btn-left xs-text-center wa"
          >
            <i className="icon-arrow-left left"></i>
            <span>Back</span>
          </Link>
        </div>
        <div className="col-sm-7 pb5">
          <button className="btn" disabled={disabled} onClick={submit}>
            Save
            <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default TenantForm
