import React from 'react'

import { Box, Flex } from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { UCSProviderConfig } from 'app/constants/ucs.constants'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { DefaultUtilityProviderComponent } from 'app/utils/utilityprovider/helpers'

export const DownloadApplicationModal = ({
  sendReferralSelected,
  config,
  onToggleSendUtilityOption,
  onSaveClicked,
  onCloseModal,
}) => {
  const { providerId, platformDefaultProvider = false } = config
  const ProviderIcon = UCSProviderConfig[providerId]?.icon
  const ProviderIconElem = !!ProviderIcon && (
    <Flex alignItems="center" maxWidth="100px" ml={theme.space[6] + 'px'}>
      <ProviderIcon height="38px" width="auto" />
    </Flex>
  )

  const messageToBeShown = platformDefaultProvider ? (
    <DefaultUtilityProviderComponent />
  ) : (
    <Box>Send {providerId} referral (if opted in)</Box>
  )

  return (
    <GenericModal
      onDismiss={() => onCloseModal()}
      title={
        <Flex alignItems="center">
          Download and send referral {ProviderIconElem}
        </Flex>
      }
      modalFooter={
        <GenericModalFooter
          secondaryBtnConfig={{
            title: 'Cancel',
            onClick: () => onCloseModal(),
          }}
          primaryBtnConfig={{
            title: `Download${sendReferralSelected ? ' and Send' : ''}`,
            onClick: () => onSaveClicked(),
          }}
        />
      }
    >
      <p>
        Download the application to PDF and optionally send utility connection
        referral (if opted in). learn more
      </p>

      <Box>
        <label className="ibm filter-today-checkbox">
          <input
            type="checkbox"
            className={`input-box wa pt0 ${
              platformDefaultProvider && 'disabled-grey-bg'
            }`}
            onClick={() => onToggleSendUtilityOption()}
            checked={sendReferralSelected}
          />
          <Box display="inline-block" ml={theme.space[3] + 'px'}>
            {messageToBeShown}
          </Box>
        </label>
      </Box>
    </GenericModal>
  )
}
