import React, { useContext } from 'react'

import { Alert } from 'app/components/design-system-components'
import { JoinSnugContext } from 'app/session/join/JoinContext'

const Error = ({ errorFromComp = '' }) => {
  const { error } = useContext(JoinSnugContext)
  const errorDisplayed = error || errorFromComp

  if (!errorDisplayed) {
    return null
  }

  return (
    <Alert variant="primaryWithBg" margin="12px 0">
      {errorDisplayed}
    </Alert>
  )
}

export default Error
