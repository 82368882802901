/* eslint-disable import/order */
import React from 'react'

import jquery from 'jquery'
import 'react-app-polyfill/ie9'
import ReactDOM from 'react-dom'

import 'react-intl-tel-input/dist/libphonenumber'
import '../node_modules/react-intl-tel-input/dist/main.css'
import 'app/assets/icons/font-awesome/css/font-awesome.min.css'
import 'app/assets/icons/icomoon/style.css'
import 'app/assets/stylesheets/_fonts.scss'
import 'app/assets/stylesheets/1_styles_old.scss'
import 'app/assets/stylesheets/2_styles_boro.scss'
import 'app/assets/stylesheets/3_styles_george.scss'
import 'app/assets/stylesheets/4_styles_marino.scss'
import 'app/assets/stylesheets/5_styles_sm.scss'
import 'app/assets/stylesheets/6_styles_pay.scss'
import 'app/assets/stylesheets/7_styles_components.scss'
import 'app/assets/stylesheets/8_printable-hacks.scss'
import * as snugLogger from 'app/services/snugLogger' // For IE 9-11 support
import { historySubscription, HistorySubscriptions } from 'app/sm/helpers'
import store from 'app/store'
import 'polyfills'
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'

// NOTE: due to an import sequence dependency, these two need to the last imports in this order
import Index, * as routerExport from 'app/shared_components/router'
import {
  fetchCurrentUser,
  fetchCurrentUserProfile,
} from 'app/session/session_actions'
import { actions as coreActions } from 'app/core/coreSlice'

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) =>
    store.dispatch(
      coreActions.notifyNewServiceWorkerRegistered(registration.waiting),
    ),
})

global.$ = jquery
global.jQuery = jquery
require('bootstrap')

const versionFromEnv = process.env.REACT_APP_BUILD_VERSION
const versionNumber = versionFromEnv ? versionFromEnv : 'unknown'
const showErrorMessage = () => {
  const errorElement = document.getElementById('error_before_react_app')
  const loaderElement = document.getElementById('loader_before_react')
  if (errorElement && loaderElement) {
    loaderElement.style.display = 'none'
    errorElement.style.display = 'block'
  }
}

document.addEventListener('DOMContentLoaded', () => {
  fetchCurrentUser()

  const currentPath = window.location.pathname
  if (
    !(
      currentPath === '/register' ||
      currentPath === '/confirm' ||
      currentPath === '/login' ||
      currentPath === '/join' ||
      currentPath === '/verify' ||
      currentPath === '/reset' ||
      currentPath === '/forgot'
    )
  ) {
    store.dispatch(fetchCurrentUserProfile())
  }

  routerExport.history.listen((location, action) => {
    historySubscription(location, action, routerExport).subscribe(
      HistorySubscriptions.MANAGE_BACK_BUTTON,
    )
  })

  // show error message after 10 seconds
  setTimeout(showErrorMessage, 10000)
  // render react app

  ReactDOM.render(<Index store={store} />, document.getElementById('index'))
})

snugLogger.boot(versionNumber)
