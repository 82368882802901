import React from 'react'

import styled from 'styled-components'

import { Box, Button, Flex } from 'app/components/design-system-components'
import Card from 'app/components/design-system-components/Card'
import { ArrowUpwardRounded } from 'app/components/design-system-components/icons/navigation'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledCard = styled(Box)`
  position: fixed;
  bottom: ${theme.space[18]}px;
  right: ${theme.space[13]}px;
  z-index: 1000;
  background-color: ${theme.colors.white};
`
const StyledButton = styled(Button)`
  width: ${theme.baseSpace * 10}px;
  font-size: ${theme.fontSizes.pRegular14};
  svg {
    width: 100%;
    height: 100%;
  }
  &:active {
    svg {
      color: ${theme.colors.gray500};
    }
    color: ${theme.colors.gray900};
  }
`

function FloatingSet({ scroller }) {
  const internalComponent = (
    <Box>
      <StyledButton
        onClick={() => {
          scroller.current.scrollIntoView({ behavior: 'smooth' })
        }}
        onMouseDown={(event) => event.preventDefault()}
        variant="grayWithGreenHoverGrayClicked"
      >
        <Flex alignItems="center">
          <ArrowUpwardRounded />
          <Box ml={1}>Top</Box>
        </Flex>
      </StyledButton>
      {/* Functionality for Next to be added later */}
      {/* <StyledButton variant="grayWithGreenHoverGrayClicked">Next</StyledButton> */}
    </Box>
  )
  return (
    <StyledCard>
      <Card
        internalComponent={internalComponent}
        forcedStyles={{ marginBottom: '0', paddingTop: '0' }}
      />
    </StyledCard>
  )
}

export default FloatingSet
