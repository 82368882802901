import styled from 'styled-components'
import { layout, space } from 'styled-system'

import { Box } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { getAllBannersHeight } from 'app/utils/layout'

const HEADER_HEIGHT = '55px'
const MAX_MODAL_WIDTH = '700px'
const MIN_MODAL_HEIGHT = '200px'
const TAKE_MIN_HEIGHT = '600px'
const INCREASED_MODAL_WIDTH = '800px'

export const ModalContentWrapper = styled.div`
  position: absolute;
  display: flex;
  ${({ justifyOrAlignCenter }) =>
    !justifyOrAlignCenter
      ? ``
      : `justify-content: center;
  align-items: center;
  `}

  flex-direction: column;
  background-color: white;
  box-shadow: ${(props) =>
    props.boxShadow && '0px 8px 20px rgba(0, 0, 0, 0.15)'};
  width: 80%;
  min-height: ${MIN_MODAL_HEIGHT};
  border-radius: 4px;
  ${({ takeIncreasedWidth }) =>
    !takeIncreasedWidth
      ? `max-width: ${MAX_MODAL_WIDTH};`
      : `max-width: ${INCREASED_MODAL_WIDTH};`}
  ${({ takeMaxWidth }) => takeMaxWidth && `max-width: 1100px;`}
  
  max-height: calc(100vh - 50px);
  .modal-body {
    width: 100%;
  }
  @media (max-height: 220px) {
    top: 20px;
  }
  @media (max-width: 576px) {
    height: ${({ isFullScreenMobile }) => {
      return (
        isFullScreenMobile &&
        `calc(100% - ${HEADER_HEIGHT} - ${getAllBannersHeight()}px)`
      )
    }};
    width: ${(props) => props.isFullScreenMobile && '100vw'};
    top: ${({ isFullScreenMobile }) => {
      return (
        isFullScreenMobile &&
        `calc(${HEADER_HEIGHT} + ${getAllBannersHeight()}px)`
      )
    }};
  }
  @media (min-width: 577px) {
    ${({ takeMinHeight }) =>
      takeMinHeight &&
      `
      height: ${TAKE_MIN_HEIGHT};
    `}
    ${({ takeFullHeight }) =>
      takeFullHeight &&
      `
      height: unset;
    `}
  }
  @media (max-height: 600px) {
    ${({ takeMinHeight }) =>
      takeMinHeight &&
      `
      height: calc(100% - ${HEADER_HEIGHT});
    `}
  }

  ${layout}
  ${space}
`

export const ModalContentContainer = styled(Box)`
  ${({ takeMaxHeight }) =>
    takeMaxHeight &&
    `
      max-height: calc(100vh - ${HEADER_HEIGHT});
      overflow-y: scroll;
    `}
  margin-bottom: ${theme.space[5]}px;
`

ModalContentWrapper.defaultProps = {
  isFullScreenMobile: true,
}
