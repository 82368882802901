import React from 'react'

const InputBox = ({
  validate,
  onInputChange,
  field,
  placeholder,
  error,
  value,
  label,
}) => {
  return (
    <div>
      <div className="input-box">
        <input
          type="text"
          placeholder={placeholder}
          onBlur={validate(field)}
          onChange={(event) => onInputChange(event, field)}
          value={value}
        />
        <label className="fixedLabelStyle">{label}</label>
      </div>
      {error && <div className="alert alert-danger"> {error} </div>}
    </div>
  )
}

export default InputBox
