import { Alert, Box } from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { adminResendAgencyUserInvitation } from 'app/services/http/teams/user-invite'
import * as snugNotifier from 'app/services/snugNotifier'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

type Props = {
  invitation: any
  closeModal: (resp?: any) => void
}

export const ResendInvitationModal = ({ invitation, closeModal }: Props) => {
  const {
    loadingStates: resendLoadingStates,
    loadingStatesHelpers: resendLoadingStateHelpers,
  } = useLoadingStates()

  const onResend = () => {
    resendLoadingStateHelpers.startLoading()
    return adminResendAgencyUserInvitation(invitation.guidID)
      .then((response: any) => {
        resendLoadingStateHelpers.markDoneSuccessfully()
        snugNotifier.success('Invite resend requested successfully')
        closeModal(response)
      })
      .catch((err: any) => {
        resendLoadingStateHelpers.setError(err)
      })
  }

  const isSubmitting = resendLoadingStates.state === loadingStatesIds.LOADING

  return (
    <GenericModal
      onDismiss={() => closeModal()}
      title="Resend invitation"
      modalFooter={
        <GenericModalFooter
          primaryBtnConfig={{
            title: 'Resend invitation',
            onClick: onResend,
            props: {
              loading: isSubmitting,
            },
          }}
          secondaryBtnConfig={{
            title: 'Cancel',
            onClick: () => closeModal(),
          }}
        />
      }
    >
      {!!resendLoadingStates.error && (
        <Alert variant="primaryWithBg" mb={4}>
          {(resendLoadingStates.error as any).message}
        </Alert>
      )}
      <Box mb={8}>
        Resend the invite to {invitation.email} and cancel the current invite?
      </Box>
    </GenericModal>
  )
}
