import { connect } from 'react-redux'

import { ping } from 'app/sm/sign_lease/sign_lease_actions'
import SignLeaseContainer from 'app/sm/sign_lease/sign_lease_container'

const mapStateToProps = ({ SignLease }) => ({
  TBD: SignLease.TBD,
})

const mapDispatchToProps = (dispatch) => ({
  ping: () => dispatch(ping()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignLeaseContainer)
