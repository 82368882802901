import React from 'react'

import { Link } from 'react-router-dom'

const BackLink = ({ link }) => (
  <Link to={link} className="btn btn-success">
    <span>Continue</span>
  </Link>
)

export default BackLink
