import { getTeamApplications } from 'app/agency/applications_list/access'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'

export const fetchTeamApplications = (teamGUID, filters) => () => {
  return getTeamApplications(teamGUID, filters).then(
    ({ responseData, ok, responseText }) => {
      if (ok) {
        return Promise.resolve(responseData)
      }
      return responseText.then((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
    },
  )
}
