import { useEffect, useState } from 'react'

import { Formik } from 'formik'
import moment from 'moment'
import { connect } from 'react-redux'

import { Box } from 'app/components/design-system-components'
import { borrowingReasonsOptions } from 'app/constants/key-logger.constants'
import {
  formFields,
  formValidation,
} from 'app/features/teams/key-logger/CheckoutKeySetModal/BorrowingDetails/form.utils'
import { FormBody } from 'app/features/teams/key-logger/CheckoutKeySetModal/BorrowingDetails/FormBody'
import { API_ERRORS_PROPERTY } from 'app/forms/FieldWrapper'
import { convertTransApiErrorToStatus } from 'app/forms/utils/convertTransApiErrorToStatus'
import {
  bulkCheckOutKeySets,
  checkOutKeySet,
} from 'app/services/http/teams/key-logger'
import { getEntryDetailsByTeamGUIDAndPropertyGUID } from 'app/sm/onlist_details/access'
import { AccessDetailsFields } from 'app/sm/onlist_details/AccessDetails/constants'
import { isEmpty } from 'app/utils/objects/helpers'

const defaultInitialValues = {
  [formFields.reason]: null,
  [formFields.dueDate]: null,
}

export const PureBorrowingDetails = ({ teamId, checkOutData, afterSave }) => {
  const [initialValues, setInitialValues] = useState(defaultInitialValues)

  useEffect(() => {
    if (!checkOutData) return
    generateInitialValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkOutData])

  const generateInitialValues = () => {
    const { reason, dueDate, property } = checkOutData
    const { agencyID, guidID: propertyID } = property || {}
    const accessDetails = {}
    if (!propertyID) return
    return getEntryDetailsByTeamGUIDAndPropertyGUID(agencyID, propertyID)
      .then(({ result }) => {
        const { entryDetails } = result || {}
        if (!entryDetails) return
        entryDetails.map((entry) => {
          switch (entry.entryType) {
            case AccessDetailsFields.AccessNotes.typeIndex:
              accessDetails[formFields.accessNotes] = entry.entryNote
              break
            case AccessDetailsFields.AlarmCode.typeIndex:
              accessDetails[formFields.alarmCode] = entry.entryNote
          }
        })
      })
      .finally(() => {
        const values = {
          [formFields.reason]: reason
            ? {
                option: reason,
                optionMeta: {
                  id: reason,
                  displayText: borrowingReasonsOptions[reason],
                },
              }
            : null,
          [formFields.dueDate]: null,
          ...accessDetails,
        }
        setInitialValues(values)
      })
  }

  const onSubmit = (values, { setStatus }) => {
    setStatus({ apiErrors: {}, apiGeneralError: '' })

    const { borrower } = checkOutData

    const borrowerPayload = borrower.guid
      ? { borrower_guid: borrower.guid }
      : { borrower: borrower }

    const fullPayload = {
      ...borrowerPayload,
      borrowing_reason: values[formFields.reason].option,
      // should be handled in BE
      due_date: moment.utc(values[formFields.dueDate].endOf('d')).format(),
      notes: values[formFields.notes],
      alarm_code: values[formFields.alarmCode],
      access_notes: values[formFields.accessNotes],
    }

    const isBulkMode = !!checkOutData.bulkKeySets?.length
    const checkOutPromise = isBulkMode
      ? bulkCheckOutKeySets(teamId, checkOutData.bulkKeySets, fullPayload)
      : checkOutKeySet(teamId, checkOutData.keySet.guid, fullPayload)

    return checkOutPromise
      .then(() => afterSave(!isBulkMode && checkOutData.keySet.guid))
      .catch(({ message, detailedError }) => {
        if (!detailedError) {
          return setStatus({ apiGeneralError: message })
        }

        // todo: handle BE validation in a better way for 1st step form instead
        // currently we are showing BE validation errors in place for this step fields
        // but errors for the 1st step fields, we are showing it in a general message alert
        // we should better save error state in a higher level, then show each field error in place
        // using setStatus once getting to the step component
        // also we may should navigate the user to the first step in case of validation error
        const {
          due_date,
          borrowing_reason,
          notes,
          alarm_code,
          access_notes,
          ...firstStepErrors
        } = detailedError?.validation || {}
        const transformedApiErrors = {
          [formFields.dueDate]: due_date,
          [formFields.reason]: borrowing_reason,
          [formFields.notes]: notes,
          [formFields.alarmCode]: alarm_code,
          [formFields.accessNotes]: access_notes,
        }
        const generalErrorMessage =
          !isEmpty(firstStepErrors) &&
          Object.entries(firstStepErrors).reduce(
            (accMessage, [field, error]) => {
              return `${accMessage}\n${field}: ${error}`
            },
            '',
          )

        setStatus({
          [API_ERRORS_PROPERTY]:
            convertTransApiErrorToStatus(transformedApiErrors),
          apiGeneralError: generalErrorMessage,
        })
      })
  }

  return (
    <Box style={{ overflow: 'auto' }}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validateOnMount
        onSubmit={onSubmit}
        validate={formValidation}
        validateOnChange
        validateOnBlur
      >
        {(props) => <FormBody formBag={props} teamId={teamId} />}
      </Formik>
    </Box>
  )
}

const mapStateToProps = ({ session }) => ({ teamId: session.currentTeam.guid })
export const BorrowingDetails = connect(mapStateToProps)(PureBorrowingDetails)
