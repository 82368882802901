import { noteActionType, targetSectionObject } from 'app/sm/helpers'

const generateUniqueKey = (mobile, email) => `${mobile}_${email}`

export const mapEnquirer = (enquirer) => ({
  ...enquirer,
  uniqueKey: generateUniqueKey(enquirer.phone, enquirer.email),
})

export const mapRegistrant = (registrant) => ({
  ...registrant,
  uniqueKey: generateUniqueKey(registrant.mobilePhone, registrant.email),
})

export const mapToUniqueKey = ({ uniqueKey }) => uniqueKey

// expected Sorted Lists
export const isSameUsersList = (prevList, nextList) => {
  if (prevList.length !== nextList.length) {
    return false
  }

  const prevListKeys = prevList.map(mapToUniqueKey)
  const nextListKeys = nextList.map(mapToUniqueKey)
  let i = 0
  do {
    if (prevListKeys[i] !== nextListKeys[i]) {
      return false
    }
    i++
  } while (i < prevList.length)
  return true
}

export const mapRegistrantPayload = (
  registrantPayload,
  forceCheckIn = false,
) => {
  const { externalComment } = registrantPayload
  const externalNotePayload = {
    content: externalComment,
    type: noteActionType.viewingAttendee,
    target: targetSectionObject.viewingReport['index'],
  }

  const forcedCheckInPayload = forceCheckIn ? { checkIn: true } : {}
  return {
    ...registrantPayload,
    ...externalNotePayload,
    ...forcedCheckInPayload,
  }
}
