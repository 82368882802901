import React from 'react'

import { ErrorMessage } from 'app/shared_components/helpers'
import NewBGCheckReport from 'app/sm/background_check/new_report'
import { urlTo } from 'app/sm/helpers'
import { checkBankruptcyCourtRecordsASISFromBGCheckResponse } from 'app/utils/backgroundcheck'

const safe = (o) =>
  o
    ? Object.assign({}, o, { message: o.message || 'No Information Provided' })
    : { message: 'No Information Provided' }

class IdentityConfirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      apiError: '',
      backgroundCheckDetail: '',
    }
  }
  componentDidMount() {
    this.props.receivePollingStopped && this.props.receivePollingStopped(false)
    this.props.fetchBackgroundCheck && this.props.fetchBackgroundCheck()
    this.props.fetchPaymentAmount && this.props.fetchPaymentAmount()
    this.props.fetchIdentityDocs &&
      this.props.fetchIdentityDocs().catch((error) =>
        this.setState({
          apiError: error,
        }),
      )

    this.props.fetchBackgroundCheckReportDetails &&
      this.props.fetchBackgroundCheckReportDetails().then(({ details }) => {
        this.setState({
          backgroundCheckDetail: details,
        })
      })
  }

  componentWillUnmount() {
    this.props.receivePollingStopped(true)
    this.props.resetSpinner()
  }

  render() {
    const {
      steps,
      backgroundCheck,
      attachToProfile,
      discardResult,
      spinner,
      error,
      backUrl,
      readOnly = false,
      whiteBackground = true,
      backgroundCheckDate = '',
      renterName = '',
      excludeBGCBankruptcyReport = false,
      applicationGUID: appGUIDFromComp = '',
    } = this.props
    const { apiError, backgroundCheckDetail } = this.state
    const { backgroundCheckResponse = {} } = backgroundCheckDetail
    const { ConsumerTenantCheck = {} } = backgroundCheckResponse
    const {
      Bankruptcies = {},
      CourtRecords = {},
      DirectorshipsProprietorships = {},
    } = ConsumerTenantCheck
    const { applicationGUID } = backgroundCheck
    const {
      isIdentityVerificationResults,
      isTenanacyBlacklistCheckResults,
      isBankruptciesResults,
      isCourtRecordsResults,
      isDirectorshipsProprietorshipsResult,
    } = checkBankruptcyCourtRecordsASISFromBGCheckResponse(
      backgroundCheckResponse,
      backgroundCheck,
    )

    const isCreatedByRenter = !applicationGUID
    const checks = [
      {
        parole: 'Identify verification',
        message: safe(backgroundCheck.identityVerification).message,
        check: safe(backgroundCheck.identityVerification).result,
      },
      {
        parole: 'Tenancy blacklist check',
        message: safe(backgroundCheck.tenanacyBlacklistCheck).message,
        check: !safe(backgroundCheck.tenanacyBlacklistCheck).result,
      },
      {
        parole: 'Bankruptcy & court record check',
        message: safe(backgroundCheck.banckruptcyCheck).message,
        check: safe(backgroundCheck.banckruptcyCheck).result,
      },
      {
        parole: 'ASIC company directorship check',
        message: safe(backgroundCheck.asicCompanyCheck).message,
        check: safe(backgroundCheck.asicCompanyCheck).result,
      },
    ]
    const { attachedToProfile } = backgroundCheck
    const bgCheckAttachToProfileTagClass = 'attach-BGCheck-to-profile'
    const bgCheckViewReportTagClass = 'view-BGCheck-report'
    const bgCheckDiscardResult = 'discard-BGCheck-result'
    return (
      <div className="sm-property-create basic">
        <NewBGCheckReport
          idVerified={isIdentityVerificationResults}
          presentOnNTD={isTenanacyBlacklistCheckResults}
          bankruptcyOrCourtFound={
            isBankruptciesResults && isCourtRecordsResults
          }
          asicFound={isDirectorshipsProprietorshipsResult}
          backgroundCheckDate={backgroundCheckDate}
          renterName={renterName}
          validityPeriod={backgroundCheck.validityPeriodInMonths}
          excludeBGCBankruptcyReport={excludeBGCBankruptcyReport}
        />
        <div className="">
          <div className="xs-second">
            {!readOnly && !spinner && (
              <div className="row pt70 ml0 mr0">
                <div className="display-flex flex-direction-column-mobile">
                  {!attachedToProfile && (
                    <button
                      className={`btn hollow-button-green btn-medium btn-medium wa ${bgCheckAttachToProfileTagClass}`}
                      onClick={() => attachToProfile()}
                    >
                      Attach to profile
                    </button>
                  )}
                  <div className="display-flex justify-content-space-around-mobile">
                    <div
                      className={`ml10 mr5 pt15 gray-text linkStyle ${bgCheckViewReportTagClass}`}
                      onClick={() => {
                        window.open(
                          urlTo('backgroundCheckReport', {
                            applicationGUID: appGUIDFromComp,
                          }),
                          '_blank',
                        )
                      }}
                    >
                      View report
                    </div>
                    {isCreatedByRenter && (
                      <div
                        className={`ml5 mr10 pt15 gray-text linkStyle ${bgCheckDiscardResult}`}
                        onClick={discardResult}
                      >
                        Discard result
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <ErrorMessage error={apiError} />
        </div>
      </div>
    )
  }
}

export default IdentityConfirmation
