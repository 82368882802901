import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Flex, TextSpan } from 'app/components/design-system-components'
import Card from 'app/match/applicationReportPDF/components/Card'
import CustomCheckbox from 'app/match/applicationReportPDF/components/CustomCheckbox'
import { formatDateTime } from 'app/match/applicationReportPDF/helpers/utils'
import {
  Yes,
  yesOrNoDisclosureQuestions,
} from 'app/match/apply/application_summary_before_submit/components/disclosure_consent_container/disclosure_questions.constants'

const propTypes = {
  data: PropTypes.object,
}

const checkType = {
  0: 'info',
  1: 'check',
  2: 'cross',
}

const complianceSentence = {
  property_condition: 'I have inspected and accepted the property condition',
  disclosure_consent:
    'I accept the Personal Information Collection and Disclosure Authority',
  connecting_utilities:
    'I wish to be contacted about connecting my utilities(gas, internet, etc)',
  viewed_property: 'Viewed property(checked-in)',
  bond_loan: 'Bond loan not required',
  posting_clause: 'Posting clause not required',
  easy_bondpay: 'easyBondpay requested',
  skip_viewing_disclosures:
    'I have not inspected and indemnify the agent/Snug from related claims',
  solicited_above_adv_price:
    'I have not been solicited by this agent/agency to rent bid',
}

const StyledDetails = styled.p`
  margin-bottom: 5px !important;

  ::before {
    content: 'Details: ';
  }
`

const Compliance = ({
  data,
  enableEnhancedDisclosure,
  chosenApplicant,
  agencyInfo,
}) => {
  return (
    <div className="compliance no-break-section">
      <Card
        type={'icon'}
        icon={'icon-s-check'}
        title="Compliance"
        addClass="bottom-border"
      />
      <ul className="list-reset">
        {Object.keys(data)
          .filter((item) => !data[item].disable)
          .map((item, index) => {
            return (
              <li className="compliance-list-item" key={index}>
                <CustomCheckbox
                  type={checkType[data[item].type]}
                  label={complianceSentence[item]}
                />
                <time dateTime={data[item].date}>
                  {data[item].date !== null && data[item].date !== 'N/A'
                    ? formatDateTime(data[item].date)
                    : data[item].date === 'N/A'
                    ? 'N/A'
                    : ''}
                </time>
              </li>
            )
          })}

        {enableEnhancedDisclosure &&
          chosenApplicant.disclosures &&
          yesOrNoDisclosureQuestions.map(
            (question) =>
              chosenApplicant.disclosures[question.id] && (
                <>
                  <li key={question.id}>
                    <Flex justifyContent="space-between" mb="5px">
                      <CustomCheckbox
                        type={null}
                        label={`${question.label.replace(
                          '[Team Name]',
                          agencyInfo?.name || '',
                        )}`}
                      />
                      <TextSpan color="var(--pdf-gray)">
                        {chosenApplicant.disclosures[question.id]}
                      </TextSpan>
                    </Flex>
                  </li>
                  {question.hasMoreDetails &&
                    chosenApplicant.disclosures[question.moreDetailsId] &&
                    chosenApplicant.disclosures[question.id] === Yes && (
                      <StyledDetails>
                        {chosenApplicant.disclosures[question.moreDetailsId]}
                      </StyledDetails>
                    )}
                </>
              ),
          )}
      </ul>
    </div>
  )
}

Compliance.propTypes = propTypes

export default Compliance
