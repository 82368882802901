import { receivePublishOption } from 'app/services/http/offers'
import { history } from 'app/shared_components/router'
import * as API from 'app/sm/advertisements/advertisement_access'
import {
  Portals,
  prepareOfferRequest,
  urlIds,
  urlTo,
  validateAdvertisement,
} from 'app/sm/helpers'
import { getCards } from 'app/sm/ph_deposit/ph_deposit_access'
import { isCurrentRoute } from 'app/sm/router_helpers'
import 'block-ui'
import store from 'app/store'

export const RECEIVE_PORTALS = 'AD_RECEIVE_PORTALS'
export const RECEIVE_ADVERTISEMENT = 'AD_RECEIVE_ADVERTISEMENT'
export const RECEIVE_AD = 'AD_RECEIVE_AD'
export const RECEIVE_CARDS = 'AD_RECEIVE_CARDS'
export const CARD_SELECT = 'AD_CARD_SELECT'
export const ACCEPT_TERMS_CONDITIONS_CHANGE =
  'AD_ACCEPT_TERMS_CONDITIONS_CHANGE'
export const RECEIVE_RESPONSE_TEXT = 'AD_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'AD_RECEIVE_ERROR'
export const CLEAR_ERROR = 'AD_CLEAR_ERROR'
export const PING = 'AD_PING'
export const RESET_ADVERTISEMENT = 'AD_RESET_ADVERTISEMENT'
export const RECEIVE_LISTING_FEE = 'AD_RECEIVE_LISTING_FEE'

export const ping = () => ({ type: PING })
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })
export const clearError = () => ({ type: CLEAR_ERROR })
export const resetAdvertisement = () => ({ type: RESET_ADVERTISEMENT })

export const successfulPayment = () => {
  return (dispatch) => {
    // TODO: maybe should store the payment
    const {
      advertisement: {
        guidID: offerId,
        property: { guidID: propertyId },
      },
    } = store.getState().Advertisements
    history.push(urlTo('updateOfferConfirmation', { propertyId, offerId }))
  }
}

export const receiveCards = (cards) => ({
  type: RECEIVE_CARDS,
  cards,
})

// Portals from the new API are not an array of portals, but an object with listingFee included
// So we must format it to fit our representation

const formatPortals = (p) => ({
  portals: p.listingPortals || [],
  listingFee: p.listingFee || 100,
})

export const receivePortals = (portals) => ({
  type: RECEIVE_PORTALS,
  portals,
})

export const receiveListingFee = (listingFee) => ({
  type: RECEIVE_LISTING_FEE,
  listingFee,
})

export const receiveAdvertisement = (advertisement) => ({
  type: RECEIVE_ADVERTISEMENT,
  advertisement,
})

export const receiveAd = (ad) => ({
  type: RECEIVE_AD,
  ad: Object.assign({}, ad, {
    listingPortals: Portals.deserialize(ad.listingPortals),
    // listingPortals: (store.getState().Advertisements.portals || []).map(p => String(p.id)).join(';')
  }),
})

export const cardSelect = (selected) => ({ type: CARD_SELECT, selected })
export const acceptTermsConditionsChange = () => ({
  type: ACCEPT_TERMS_CONDITIONS_CHANGE,
})

let portalsFetched = (portals) => {
  const { portals: listingPortals, listingFee } = formatPortals(portals)
  store.dispatch(receivePortals(listingPortals))
  store.dispatch(receiveListingFee(listingFee))
}

let advertisementUpdated = (path) => (ad) => {
  history.push(path)
}

let advertisementCreated =
  (propertyId) =>
  ({ guidID: offerId, property: { guidID, slug: propertySlug } }) => {
    // TODO: maybe we should send the property slug not the offer slug
    history.push(
      urlTo(urlIds.propertyPublishingOptions, { propertyId, offerId }),
    )
  }

let advertisementFetched = (ad) => {
  const offer = {
    ...ad,
    publishingOption: receivePublishOption(ad),
    startDate: ad.availableFrom,
  }
  store.dispatch(receiveAdvertisement(offer))
}

let adFetched = (ad) => {
  const {
    advertisement: {
      guidID: offerId,
      property: { guidID: propertyId },
    },
  } = store.getState().Advertisements
  const url = isCurrentRoute('adFlowPortals')
    ? urlTo('adFlowPayment', { propertyId, offerId })
    : urlTo('updateOfferPayment', { propertyId, offerId })

  history.push(url)
  store.dispatch(receiveAd(ad))
}

let error = ({ responseText }) => {
  responseText = responseText ? responseText.replace(/\n/, '') : ''
  store.dispatch(receiveResponseText(responseText))
}

export function fetchPortals() {
  API.getPortals(portalsFetched, error)
}

export function createAdvertisement(propertyId, ad) {
  store.dispatch(clearError())
  const advertisementValidation = validateAdvertisement(ad)

  if (!advertisementValidation.ok) {
    store.dispatch(receiveResponseText(advertisementValidation.errorCode))

    return Promise.resolve()
  }
  const offerRequest = prepareOfferRequest(ad)

  API.createAdvertisement(
    propertyId,
    offerRequest,
    advertisementCreated(propertyId),
    error,
  )
}

export function updateAdvertisement(offerId, ad, path) {
  store.dispatch(clearError())
  const {
    location: { pathname },
  } = history

  if (/portals/.test(pathname)) {
    const { ad: activeAd } = store.getState().Advertisements

    const formattedAd = Object.assign({}, ad, {
      listingPortals: Portals.serialize(ad.listingPortals),
    })

    if (activeAd.guidID !== undefined) {
      updateAd(activeAd.guidID, formattedAd)

      return
    }
    createAd(offerId, formattedAd)

    return
  }

  if (/confirmation/.test(pathname)) {
    history.push(urlTo('applications'))
    return
  }
  const offer = prepareOfferRequest(ad)
  const advertisementValidation = validateAdvertisement(offer)

  if (!advertisementValidation.ok) {
    store.dispatch(receiveResponseText(advertisementValidation.errorCode))

    return Promise.resolve()
  }

  store.dispatch(receiveAdvertisement(offer))
  API.updateAdvertisement(offerId, offer, advertisementUpdated(path), error)
}

export function fetchAdvertisement(id) {
  API.getAdvertisement(id, advertisementFetched, error)
}

export function fetchActiveAd(offerId) {
  return (dispatch) => {
    API.getActiveAd(offerId).then(({ ok, ad, statusCode, responseText }) => {
      if (ok) {
        dispatch(receiveAd(ad))
      } else {
        responseText.then((error) => console.log(error))
      }
    })
  }
}

export function createAd(offerId, data) {
  store.dispatch(clearError())

  API.createAd(
    offerId,
    {
      listingPortals: data.listingPortals,
      listingFee: 127,
    },
    (ad) => {
      adFetched(ad)
    },
    error,
  )
}

export function updateAd(adId, data) {
  store.dispatch(clearError())

  API.updateAd(
    adId,
    {
      listingPortals: data.listingPortals,
      listingFee: 127,
    },
    (ad) => {
      adFetched(ad)
    },
    error,
  )
}

export function fetchAd(id) {
  API.getAds(id, adFetched, error)
}

export function fetchCards() {
  return (dispatch) => {
    getCards().then(({ cards, ok, responseText, statusCode }) => {
      if (ok) {
        const Cards = cards
          .map((c) => ({
            id: c.id,
            number: `${c.brand} XXXX-${c.last4} (exp ${c.exp_month}/${
              c.exp_year % 100
            })`,
          }))
          .concat({
            id: 'New',
            number: 'New Credit Card',
          })

        dispatch(receiveCards(Cards))
        dispatch(cardSelect(Cards[0].id))
      } else {
        // responseText.then(t => {
        //     store.dispatch(receiveResponseText(t))
        // })
      }
    })
  }
}

export function registerPayment(data) {
  // API.registerPayment(id, data, paymentRegistered, error)

  const {
    ad: { guidID: adId },
  } = store.getState().Advertisements
  API.payWithNewAccount(adId, data).then(({ ok, statusCode, responseText }) => {
    if (ok) {
      store.dispatch(successfulPayment())
    } else {
      responseText.then((t) => {
        store.dispatch(receiveResponseText(t))
      })
    }
  })
}

export function payWithExistingCard() {
  store.dispatch(clearError())

  return (dispatch, getState) => {
    const {
      cards,
      selectedCreditCard,
      ad: { guidID: adId },
    } = getState().Advertisements
    const card = cards.find((c) => c.id === selectedCreditCard)

    API.payWithExistingAccount(card.id, adId).then(
      ({ ok, statusCode, responseText }) => {
        if (ok) {
          store.dispatch(successfulPayment())
        } else {
          responseText.then((t) => {
            store.dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}
