import { Modal } from 'app/components/design-system-components'
import ShareWithOwner from 'app/dashboard/share_with_owner'

const ShareWithOwnerModal = ({
  applicationGUID = '',
  teamSlug = '',
  toggleShareWithOwnerModal = () => {},
}) => {
  const modalBody = (
    <ShareWithOwner
      applicationGUID={applicationGUID}
      teamSlug={teamSlug}
      toggleShareWithOwnerModal={toggleShareWithOwnerModal}
      showUpdatedEmailNotice={false}
    />
  )
  return (
    <Modal
      modalHeading="Share application"
      modalBody={modalBody}
      modalFooter={undefined}
      primaryLabel={undefined}
      primaryAction={undefined}
      secondaryLabel={undefined}
      secondaryAction={undefined}
      toggleModal={toggleShareWithOwnerModal}
      customModalFooter={true}
    />
  )
}

export default ShareWithOwnerModal
