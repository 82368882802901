import styled from 'styled-components'
import { space } from 'styled-system'

import * as getters from 'app/components/design-system-components/theme/getters'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { Checkbox } from 'app/shared_components/form_component'

export const StyledCheckbox = styled(Checkbox)`
  input[type='checkbox'] + span {
    //color: red;
    font-weight: ${getters.fontWeight(3)};
    font-size: ${theme.fontSizes.pRegular14};
  }

  input[type='checkbox']:checked:after {
    background-color: ${getters.color('deepBlue')};
    border-color: ${getters.color('deepBlue')};
  }

  ${space}
`
