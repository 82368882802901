import { api } from 'app/utils/api/helpers'

export const getGoogleSuggestions = (queryString, sessionToken) => {
  return api.get(
    `/address/search?query=${queryString}&sessionToken=${sessionToken}`,
  )
}

export const getGooglePlaceDetails = (placeID, sessionToken) => {
  return api.get(`/address/search/${placeID}?sessionToken=${sessionToken}`)
}
