import React from 'react'

import * as SummaryHeaders from 'app/match/apply/application_summary_before_submit/shared_components/headers/component'
import { isMobile } from 'app/shared_components/helpers'
import CenterContentContainer from 'app/shared_components/layout_component/center_content_component/component'
import SnugLogo from 'app/shared_components/snug_logo'

class Properties extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      spinnerName: '',
      isShortListed: undefined,
    }
  }

  render() {
    const {
      property,
      propertyOffer,
      applicants = [],
      selectedApplicantGUID = '',
      application = {},
      teamSlug = '',
      componentClassName = '',
    } = this.props
    const chosenApplicant = applicants.find(
      (applicant) => applicant.guidID === selectedApplicantGUID,
    )

    return (
      <CenterContentContainer
        componentClass={`col-xs-12 ${
          isMobile() ? 'pr15 pl15' : ''
        } ${componentClassName}`}
      >
        <div>
          {chosenApplicant && (
            <SummaryHeaders.ApplicationSummaryHeader
              property={property}
              propertyOffer={!!propertyOffer ? propertyOffer : {}}
              applicationType={application.applicationType}
              applicant={chosenApplicant}
              teamSlug={teamSlug}
            />
          )}
        </div>
        <SnugLogo className="pdf-show logo-position" />
      </CenterContentContainer>
    )
  }
}

export default Properties
