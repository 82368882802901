import { useEffect } from 'react'

import { Button, LoadingSection } from 'app/components/design-system-components'
import { keySetTypesOptions } from 'app/constants/key-logger.constants'
import { extractBorrowingInfo } from 'app/features/teams/key-logger/CheckInKeySetModal/utils'
import {
  SuccessModal,
  SuccessModalSimpleDescriptionList,
} from 'app/features/teams/key-logger/components/SuccessModal'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  generateKeySetPopulateParams,
  getKeySet,
} from 'app/services/http/teams/key-logger'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'

export const CheckInSuccessModal = ({
  teamId,
  keySet: initialKeySet,
  closeModal,
}) => {
  const generateListData = (keySet) => {
    const { property } = keySet
    const borrowingInfo = extractBorrowingInfo(keySet.borrowing_data)
    return [
      { title: 'Property Address', description: property.address.friendlyName },
      { title: 'Key set', description: keySet.label },
      {
        title: 'Key set type',
        description: keySetTypesOptions[keySet.set_type],
      },
      { title: 'Borrower', description: borrowingInfo?.borrower || 'N/A' },
      {
        title: 'Checked out',
        description: borrowingInfo?.checkOutDate || 'N/A',
      },
    ]
  }

  const {
    state: keySetInfo,
    setState: setKeySetInfo,
    loadingStates: keySetInfoLoadingStates,
    loadingStatesHelpers: keySetInfoLoadingStatesHelpers,
  } = useStateWithLoading({ keySet: null, listData: null })

  const loadKeySet = () => {
    return getKeySet(
      teamId,
      initialKeySet.guid,
      generateKeySetPopulateParams({
        property: true,
        borrower: true,
        issuer: true,
      }),
    )
      .then(({ key_set: keySet }) => {
        setKeySetInfo({
          keySet,
          listData: generateListData(keySet),
        })
        keySetInfoLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => keySetInfoLoadingStatesHelpers.setError(err))
  }

  useEffect(() => {
    loadKeySet()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const actions = (
    <Button
      type="button"
      onClick={closeModal}
      variant="solid"
      width="100%"
      mb={theme.space[4] + 'px'}
    >
      Close
    </Button>
  )

  return (
    <SuccessModal
      title="Keys Checked In"
      description="Checked in info:"
      actions={actions}
    >
      <LoadingSection
        height="100%"
        loadingState={keySetInfoLoadingStates}
        actionHandler={loadKeySet}
        loaderProps={{ fontSize: theme.fontSizes.pExtraLarge18 }}
      >
        {!!keySetInfo.keySet && (
          <SuccessModalSimpleDescriptionList
            listConfig={{ listData: keySetInfo.listData }}
          />
        )}
      </LoadingSection>
    </SuccessModal>
  )
}
