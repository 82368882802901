import { keySetTypesOptions } from 'app/constants/key-logger.constants'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'

export const formFields = {
  property: 'property',
  keyIdLabel: 'keyIdLabel',
  keySetType: 'keySetType',
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.property:
      return {
        required: !val && 'Please select a property address',
      }
    case formFields.keyIdLabel:
      return {
        required: !val && 'Please enter a key label',
        min: val && val.length < 2 && 'This label is too short',
      }
    case formFields.keySetType:
      return {
        required: !val && 'Please select a key set type',
      }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)

export const buildRequestPayload = (formValues) => {
  const selectedPropertyOption = formValues[formFields.property]
  const label = formValues[formFields.keyIdLabel]
  const selectedTypeOption = formValues[formFields.keySetType]
  return {
    property_guid: selectedPropertyOption.option.guidID,
    label,
    set_type: selectedTypeOption.option,
  }
}

export const transformApiErrorToFields = ({
  property_guid,
  label,
  set_type,
}) => ({
  [formFields.property]: property_guid,
  [formFields.keyIdLabel]: label,
  [formFields.keySetType]: set_type,
})

///////////////////////////////////////////////////////////////////
// following is some constants and options used commonly

export const keySetMappedOptions = Object.entries(keySetTypesOptions).map(
  ([id, displayText]) => ({
    option: id,
    optionMeta: {
      id,
      displayText,
    },
  }),
)
