import React from 'react'

import Flex from 'app/components/design-system-components/Flex'
import { Heading } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const PageHeader = ({ title = 'Undefined', ...props }) => {
  return (
    <Flex
      alignItems="start"
      justifyContent="space-between"
      mb={props.mb || theme.baseSpace * 4 + 'px'}
    >
      <Heading level={1} displayingLevel={1} {...props}>
        {title}
      </Heading>
    </Flex>
  )
}
