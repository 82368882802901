import qs from 'qs'

import * as sharedHelpers from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getTeamApplications = (agencyID, filters) => {
  const defaultedFilters = {
    archived: false,
    cursor: '',
    manager: '',
    limit: 10,
    searchText: '',
    applicantEmail: '',
    ...filters,
  }
  const { searchText, ...otherFilters } = defaultedFilters
  const mappedFilters = {
    ...otherFilters,
    q: searchText,
  }
  const filtersQuery = qs.stringify(mappedFilters, { arrayFormat: 'repeat' })
  const url = `${apiBaseUrl}sm/teams/${agencyID}/applications?${filtersQuery}`
  const options = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(url, options)
    .then(sharedHelpers.checkStatus)
    .then(
      (responseData) => ({ ok: true, responseData }),
      sharedHelpers.returnAPIError,
    )
    .catch(sharedHelpers.logAPIError)
}
