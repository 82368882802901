import ErrorMessages from 'app/constants/error_messages'
import {
  ON_SORTER_DROPDOWN_CHANGE,
  ON_TAB_CHANGE,
  PING,
  RECEIVE_COMING_UP_VIEWINGS,
  RECEIVE_COMPLETED_VIEWINGS,
  RECEIVE_ERROR,
  RECEIVE_RESPONSE_TEXT,
  TOGGLE_SPINNER,
} from 'app/sm/manager_viewings/manager_viewings_actions'

const _defaultState = {
  viewings: [],
  comingUpViewings: [],
  completedViewings: [],
  spinner: false,
  sortBy: 'time',
  error: '',
  responseText: '',
  activeTab: 'coming_up',
  tabs: [
    {
      id: 'coming_up',
      title: 'Coming up',
      count: 0,
      isActive: true,
    },
    {
      id: 'completed',
      title: 'Completed',
      count: 0,
      isActive: false,
    },
  ],
}

function parseResponseText(responseText) {
  return responseText ? responseText.replace(/\n/, '') : ''
}

const ManagerViewings = (state = _defaultState, action) => {
  const {
    type,
    comingUpViewings,
    completedViewings,
    error,
    responseText,
    sorter,
    activeTab,
  } = action

  Object.freeze(state)

  const newState = Object.assign({}, state)

  let tabs

  switch (type) {
    case PING:
      return Object.assign(newState, { error: '', responseText: '' })
    case RECEIVE_COMING_UP_VIEWINGS:
      tabs = state.tabs.map((tab) =>
        tab.id === 'coming_up'
          ? Object.assign({}, tab, { count: comingUpViewings.length })
          : Object.assign({}, tab),
      )
      return Object.assign(newState, { comingUpViewings }, { tabs })
    case RECEIVE_COMPLETED_VIEWINGS:
      tabs = state.tabs.map((tab) =>
        tab.id === 'completed'
          ? Object.assign({}, tab, { count: completedViewings.length })
          : Object.assign({}, tab),
      )
      return Object.assign(newState, { completedViewings }, { tabs })
    case ON_SORTER_DROPDOWN_CHANGE:
      return Object.assign(newState, { sortBy: sorter })
    case TOGGLE_SPINNER:
      return Object.assign(newState, { spinner: !newState.spinner })
    case ON_TAB_CHANGE:
      tabs = state.tabs.map((tab) =>
        tab.id === activeTab
          ? Object.assign({}, tab, { isActive: true })
          : Object.assign({}, tab, { isActive: false }),
      )
      return Object.assign(newState, { tabs }, { activeTab })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case RECEIVE_RESPONSE_TEXT:
      const errorCode = parseInt(parseResponseText(responseText))
      const errorMessage = ErrorMessages[errorCode]
      return Object.assign(newState, {
        responseText: errorCode,
        error: errorMessage,
      })
    default:
      return newState
  }
}

export default ManagerViewings
