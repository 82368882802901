import React from 'react'

import { Form } from 'formik'
import styled from 'styled-components'

import {
  Alert,
  Button,
  Flex,
  ShadowScrollBox,
} from 'app/components/design-system-components'
import {
  InputField,
  TelField,
} from 'app/components/design-system-components/inputs'
import { formFields } from 'app/components/display/redirect/RedirectRentalReferenceModal/form.utils'
import { FieldWrapper } from 'app/forms/FieldWrapper'

const StyledFormWrapper = styled(Flex)`
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`
const ResponsiveFlex = styled(Flex)`
  @media (max-width: 576px) {
    flex-direction: column;
    div:first-child {
      margin-right: 0;
    }
  }
`

export const FormBody = ({
  formBag: {
    isValid,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    status,
    values,
    dirty,
  },
  close,
}) => {
  return (
    <Form style={{ height: '100%' }}>
      <StyledFormWrapper>
        <ShadowScrollBox>
          {!!status && !!status.apiGeneralError && (
            <Alert variant="warningWithBg" mt={3}>
              {status.apiGeneralError}
            </Alert>
          )}
          <ResponsiveFlex justifyContent="space-between">
            <FieldWrapper
              id={formFields.firstName}
              name={formFields.firstName}
              flex="50%"
              mr={3}
            >
              <InputField
                id={formFields.firstName}
                name={formFields.firstName}
                inputProps={{ placeholder: 'First name' }}
              />
            </FieldWrapper>

            <FieldWrapper
              id={formFields.lastName}
              name={formFields.lastName}
              flex="50%"
            >
              <InputField
                id={formFields.lastName}
                name={formFields.lastName}
                inputProps={{ placeholder: 'Last name' }}
              />
            </FieldWrapper>
          </ResponsiveFlex>

          <FieldWrapper id={formFields.email} name={formFields.email}>
            <InputField
              id={formFields.email}
              name={formFields.email}
              inputProps={{ placeholder: 'Email' }}
            />
          </FieldWrapper>

          <ResponsiveFlex justifyContent="space-between">
            <FieldWrapper
              id={formFields.phone}
              name={formFields.phone}
              flex="50%"
              mr={3}
            >
              <TelField name={formFields.phone} placeholder="Mobile" />
            </FieldWrapper>
            <FieldWrapper
              id={formFields.officePhone}
              name={formFields.officePhone}
              flex="50%"
            >
              <TelField name={formFields.officePhone} placeholder="Office" />
            </FieldWrapper>
          </ResponsiveFlex>
        </ShadowScrollBox>

        <Flex alignItems="center" mt={5}>
          <Button
            type="button"
            variant="solidSecondary"
            flex="1 1 30%"
            mr={3}
            onClick={close}
          >
            Cancel
          </Button>
          <Button disabled={!isValid} type="submit" flex="1 1 70%">
            Send
          </Button>
        </Flex>
      </StyledFormWrapper>
    </Form>
  )
}
