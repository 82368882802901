import React from 'react'

import styled from 'styled-components'

import * as snugNotifier from 'app/services/snugNotifier'
import ViewingScheduleZoneDropdown from 'app/shared_components/viewing_schedule_zone_dropdown'

const VIEWING_SCHEDULE_ZONE_UPDATED_SUCCESS =
  'Your Viewing Schedule Zone has been updated.'

const ViewingScheduleZoneContainer = styled.div`
  clear: both;
`

const AddAndDropdownContainer = styled.div`
  display: flex;
  select {
    width: 256px;
  }
  .add-link {
    margin-left: 75px;
  }
`

class ViewingScheduleZone extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedZone: 0,
    }
  }

  componentDidMount() {
    const { offer } = this.props
    const { viewingScheduleZone } = offer || {}
    this.setState({
      selectedZone: viewingScheduleZone,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.offer && this.props.offer !== prevProps.offer) {
      const { offer } = this.props
      const { viewingScheduleZone } = offer || {}
      this.setState({
        selectedZone: viewingScheduleZone,
      })
    }
  }

  updateViewingScheduleDropdown = (data) => {
    const { guidDetails, updateOfferWithViewingScheduleZone } = this.props
    const { offerGUID, agencyGUID } = guidDetails
    const { selectedZone } = this.state
    if (data.value !== selectedZone) {
      this.setState(
        {
          selectedZone: Number(data.value),
        },
        () => {
          if (offerGUID && agencyGUID) {
            updateOfferWithViewingScheduleZone(
              agencyGUID,
              offerGUID,
              this.state.selectedZone,
            )
              .then(() =>
                snugNotifier.success(VIEWING_SCHEDULE_ZONE_UPDATED_SUCCESS),
              )
              .catch((error) => snugNotifier.error(error))
          }
        },
      )
    }
  }

  render() {
    const { selectedZone } = this.state
    return (
      <ViewingScheduleZoneContainer>
        <h5 className="mt20">Viewing Zone</h5>
        <div className="mt10 mb20">
          Select the viewing schedule zone for this property.{' '}
          <a
            href="https://help.snug.com/hc/en-us/articles/360000914956"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to set up a viewing schedule
          </a>
          .
          <AddAndDropdownContainer>
            <ViewingScheduleZoneDropdown
              selectedDropdownValue={selectedZone}
              updateViewingScheduleDropdown={(event) => {
                this.updateViewingScheduleDropdown(event)
              }}
            />
          </AddAndDropdownContainer>
        </div>
      </ViewingScheduleZoneContainer>
    )
  }
}

export default ViewingScheduleZone
