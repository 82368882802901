import React from 'react'

export const SelectMessageCell = ({ isSelected, toggleMessageSelection }) => {
  return (
    //  todo: make a new checkbox component and stop using the legacy one
    <label className="ibm filter-today-checkbox">
      <input
        type="checkbox"
        className="input-box wa pt0"
        onChange={() => toggleMessageSelection()}
        checked={isSelected}
      />
    </label>
  )
}
