import React from 'react'
import 'app/sm/team_viewing_preference/components/optionBox/style.scss'

const OptionBox = ({
  chosenViewingDuration = 0,
  onOptionBoxChange = undefined,
  availableDuration = [],
  label = '',
  selectorStyle = '',
  extraStyle = '',
  optionUnit = '',
  toolTip = '',
  disabled,
}) => {
  return (
    <div className={`mb10 display-flex option-box ${extraStyle}`}>
      <div className="pr20">{label}</div>
      <div className="input-box wa pt0">
        <select
          className={selectorStyle}
          value={chosenViewingDuration}
          onChange={onOptionBoxChange}
          disabled={disabled}
        >
          {availableDuration.map((option) => (
            <option key={option} value={option}>
              {option}
              {optionUnit}
            </option>
          ))}
        </select>
      </div>
      {toolTip}
    </div>
  )
}

export default OptionBox
