import React, { useState } from 'react'

import moment from 'moment'

import { ButtonWithLoading } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import DisclosureQuestions from 'app/pages/teams/onlist/components/Disclosures'
import { PropertyDisclosureHeader } from 'app/pages/teams/onlist/components/Disclosures/PropertyDisclosureHeader'
import { getDisclosureLatestVersion } from 'app/pages/teams/onlist/components/Disclosures/questions'
import * as snugNotifier from 'app/services/snugNotifier'
import { convertYesOrNoToBool } from 'app/utils/onlist/helpers'

const failedValidationMessage = 'Please answer this question'

function OwnerPropertyDisclosuresForm({
  disclosureRequest,
  addPropertyDisclosureRequestForOwner,
  submitDisclosureInProgress,
}) {
  const [disclosureErrors, setDisclosureErrors] = useState({
    compliesWithMinimumStandards: '',
    isRegisteredPlace: '',
    hasAsbestos: '',
    isAffectedWithRelevantAuthorities: '',
    dateOfHomicide: '',
    dateOfRepairNotice: '',
    dateOfGasCheck: '',
    dateOfElectricityCheck: '',
    dateOfPoolCheck: '',
    dateOfDrugUsage: '',
    embeddedNetworkName: '',
    embeddedNetworkAbn: '',
    embeddedNetworkPhone: '',
    embeddedNetworkPricing: '',
    isBeingSold: '',
    hasHomicide: '',
    hasEmbeddedNetwork: '',
    embeddedNetworkWebsite: '',
  })

  const [disclosureAnswers, setDisclosureAnswers] = useState({
    isBeingSold: null,
    hasHomicide: null,
    hasEmbeddedNetwork: null,
    compliesWithMinimumStandards: null,
    isRegisteredPlace: null,
    hasAsbestos: null,
    isAffectedWithRelevantAuthorities: null,
    dateOfHomicide: null,
    dateOfRepairNotice: null,
    dateOfGasCheck: null,
    dateOfElectricityCheck: null,
    dateOfPoolCheck: null,
    dateOfDrugUsage: null,
    embeddedNetworkName: '',
    embeddedNetworkAbn: '',
    embeddedNetworkPhone: '',
    embeddedNetworkPricing: '',
    embeddedNetworkWebsite: '',
  })

  const validateResponsesAndSetErrors = () => {
    const {
      compliesWithMinimumStandards,
      isRegisteredPlace,
      hasAsbestos,
      isAffectedWithRelevantAuthorities,
      dateOfHomicide,
      hasHomicide,
      hasEmbeddedNetwork,
      embeddedNetworkName,
      embeddedNetworkAbn,
      embeddedNetworkPhone,
      embeddedNetworkPricing,
      embeddedNetworkWebsite,
      isBeingSold,
    } = disclosureAnswers
    let errorsPresented = false
    let errors = { ...disclosureErrors }
    if (hasHomicide === null) {
      errors = { ...errors, hasHomicide: failedValidationMessage }
      errorsPresented = true
    }
    if (hasHomicide && !dateOfHomicide) {
      errors = { ...errors, dateOfHomicide: failedValidationMessage }
      errorsPresented = true
    }
    if (isRegisteredPlace === null) {
      errors = { ...errors, isRegisteredPlace: failedValidationMessage }
      errorsPresented = true
    }
    if (isAffectedWithRelevantAuthorities === null) {
      errors = {
        ...errors,
        isAffectedWithRelevantAuthorities: failedValidationMessage,
      }
      errorsPresented = true
    }
    if (hasAsbestos === null) {
      errors = { ...errors, hasAsbestos: failedValidationMessage }
      errorsPresented = true
    }

    if (compliesWithMinimumStandards === null) {
      errors = {
        ...errors,
        compliesWithMinimumStandards: failedValidationMessage,
      }
      errorsPresented = true
    }
    if (isBeingSold === null) {
      errors = { ...errors, isBeingSold: failedValidationMessage }
      errorsPresented = true
    }
    if (hasEmbeddedNetwork === null) {
      errors = { ...errors, hasEmbeddedNetwork: failedValidationMessage }
      errorsPresented = true
    }
    if (hasEmbeddedNetwork) {
      if (!embeddedNetworkAbn) {
        errors = { ...errors, embeddedNetworkAbn: failedValidationMessage }
        errorsPresented = true
      }
      if (!embeddedNetworkPricing) {
        errors = { ...errors, embeddedNetworkPricing: failedValidationMessage }
        errorsPresented = true
      }
      if (!embeddedNetworkName) {
        errors = { ...errors, embeddedNetworkName: failedValidationMessage }
        errorsPresented = true
      }
      if (!embeddedNetworkPhone) {
        errors = { ...errors, embeddedNetworkPhone: failedValidationMessage }
        errorsPresented = true
      }
      if (!embeddedNetworkWebsite) {
        errors = { ...errors, embeddedNetworkWebsite: failedValidationMessage }
        errorsPresented = true
      }
    }

    setDisclosureErrors(errors)

    return errorsPresented
  }

  const onChangeRadioGroup = (field) => {
    return (event) => {
      const { target } = event
      const { id: val } = target

      let errors = { ...disclosureErrors }
      let fieldVal = null

      // if not toggling, then we have a valid bool
      if (disclosureAnswers[field] !== convertYesOrNoToBool(val)) {
        fieldVal = convertYesOrNoToBool(val)
        errors = {
          ...errors,
          [field]: '',
        }
      }

      if (field === 'hasHomicide' && !convertYesOrNoToBool(val)) {
        errors = {
          ...errors,
          dateOfHomicide: '',
        }
      }

      if (field === 'hasEmbeddedNetwork' && !convertYesOrNoToBool(val)) {
        errors = {
          ...errors,
          embeddedNetworkName: '',
          embeddedNetworkAbn: '',
          embeddedNetworkPhone: '',
          embeddedNetworkPricing: '',
          embeddedNetworkWebsite: '',
        }
      }

      if (disclosureAnswers[field] === convertYesOrNoToBool(val)) {
        fieldVal = null
      }

      setDisclosureAnswers({ ...disclosureAnswers, [field]: fieldVal })
      setDisclosureErrors(errors)
    }
  }

  const updateDateInput = (field) => {
    return (data) => {
      let error = data.error
      setDisclosureAnswers({
        ...disclosureAnswers,
        [field]: data.value ? moment(data.value) : data.value,
      })
      setDisclosureErrors({
        ...disclosureErrors,
        [field]: error,
      })
    }
  }

  const inputValueChange = (field) => {
    return (event) => {
      const { value } = event
      if (disclosureAnswers.hasEmbeddedNetwork) {
        if (field === 'embeddedNetworkAbn' && value !== '') {
          setDisclosureErrors((prevState) => ({
            ...prevState,
            embeddedNetworkAbn: '',
          }))
        }
        if (field === 'embeddedNetworkPricing' && value !== '') {
          setDisclosureErrors((prevState) => ({
            ...prevState,
            embeddedNetworkPricing: '',
          }))
        }
        if (field === 'embeddedNetworkName' && value !== '') {
          setDisclosureErrors((prevState) => ({
            ...prevState,
            embeddedNetworkName: '',
          }))
        }
        if (field === 'embeddedNetworkPhone' && value !== '') {
          setDisclosureErrors((prevState) => ({
            ...prevState,
            embeddedNetworkPhone: '',
          }))
        }
        if (field === 'embeddedNetworkWebsite' && value !== '') {
          setDisclosureErrors((prevState) => ({
            ...prevState,
            embeddedNetworkWebsite: 'Please answer this question',
          }))
        }
      }
      if (
        field === 'embeddedNetworkName' ||
        field === 'embeddedNetworkPricing'
      ) {
        setDisclosureAnswers({
          ...disclosureAnswers,
          [field]: value,
        })
        return
      }
      setDisclosureAnswers({
        ...disclosureAnswers,
        [field]: value,
      })

      setDisclosureErrors({
        ...disclosureErrors,
        [field]: event.error,
      })
    }
  }

  const onSubmitDisclosuresClicked = () => {
    const errorsPresent = validateResponsesAndSetErrors()
    if (errorsPresent) {
      snugNotifier.error(
        'There was an error in submission. Please check your responses again',
      )
      return
    }
    if (
      window.confirm(
        'Please ensure that the details entered are correct. These cannot be changed later.',
      )
    ) {
      addPropertyDisclosureRequestForOwner(disclosureAnswers)
    }
  }

  const { property = {} } = disclosureRequest || {}
  const { address = {} } = property || {}
  const propertyAddress = `${address.friendlyName}, ${address.suburb}`

  return (
    <div>
      <Display.CenterContentContainer componentClass=" col-xs-12 summary-box-on-mobile">
        <PropertyDisclosureHeader propertyAddress={propertyAddress} />
        <DisclosureQuestions
          answers={disclosureAnswers}
          disclosureErrors={disclosureErrors}
          onChangeRadioGroup={onChangeRadioGroup}
          updateDateInput={updateDateInput}
          inputValueChange={inputValueChange}
          areDisclosuresEditable={true}
          version={getDisclosureLatestVersion()} //if creating a disclosure, use the latest version
        />
        <ButtonWithLoading
          onClick={() => onSubmitDisclosuresClicked()}
          loading={submitDisclosureInProgress}
          disableWhenLoading={true}
        >
          Submit
        </ButtonWithLoading>
      </Display.CenterContentContainer>
    </div>
  )
}

export default OwnerPropertyDisclosuresForm
