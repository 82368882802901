import React from 'react'

import { InputContainer } from 'app/components/design-system-components/inputs/Input/Input'
import { IntlTelInputWrapper } from 'app/components/design-system-components/IntlTelInputWrapper'
import { CustomInputWrapper } from 'app/forms/CustomInputWrapper'

export const mobileTelPlaceholder = '+61 412 345 678'
export const landlinePlaceholder = '02 1234 5678'

export const Tel = ({
  changeHandler,
  blurHandler,
  value,
  placeholder,
  error,
  onlyCountries = ['au'],
  international = false,
  ...props
}) => {
  return (
    <InputContainer {...props} className={error && 'error'}>
      <IntlTelInputWrapper
        css={['intl-tel-input country-list', 'register-form-mobile-input']}
        utilsScript={'libphonenumber.js'}
        defaultCountry={'au'}
        onPhoneNumberChange={(status, value, countryData, number, id) =>
          changeHandler(number)
        }
        onPhoneNumberBlur={() => {
          blurHandler()
        }}
        value={value}
        onlyCountries={international ? [] : onlyCountries}
        placeholder={placeholder}
        error={error}
      />
    </InputContainer>
  )
}

export const TelField = (props) => {
  const { name } = props
  return (
    <CustomInputWrapper
      name={name}
      customInputCmp={(customInputProps) => (
        <Tel {...customInputProps} {...props} />
      )}
    />
  )
}
