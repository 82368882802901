import { isEmpty } from 'app/utils/objects/helpers'

// this accepts fieldValidation fn
// that accepts 3 params fieldName (string), val (any), values ({[key: string]: any})
// and it returns object that contains only error fields as keys and object of errors (key is error id and value is error message string)
export const formValidationBuilder = (fieldValidation) => {
  return (values) => {
    return Object.entries(values).reduce((accErrors, [fieldName, val]) => {
      const fieldValidationResult = fieldValidation(fieldName, val, values)

      // there is no validation for this field
      if (!fieldValidationResult) return accErrors

      // filterOut non error message (empty messages)
      const fieldErrors = Object.entries(fieldValidationResult).reduce(
        (accErrors, [key, errorString]) => {
          if (!errorString) return accErrors
          return {
            ...accErrors,
            [key]: errorString,
          }
        },
        {},
      )

      if (isEmpty(fieldErrors)) return accErrors

      return {
        ...accErrors,
        [fieldName]: fieldErrors,
      }
    }, {})
  }
}
