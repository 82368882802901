import * as React from 'react'

function SvgImageRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 5v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2zM11 16.51l-2.1-2.53a.493.493 0 00-.78.02l-2.49 3.2c-.26.33-.03.81.39.81h11.99a.5.5 0 00.4-.8l-3.51-4.68c-.2-.27-.6-.27-.8-.01L11 16.51z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgImageRounded
