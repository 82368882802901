import React from 'react'

import PropTypes from 'prop-types'

import { Box } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import Card from 'app/match/applicationReportPDF/components/Card'
import { attachList } from 'app/match/applicationReportPDF/helpers/utils'
import { formatBalance } from 'app/sm/proof_of_income/helper'

const propTypes = {
  data: PropTypes.object,
}

const Income = ({ data = {} }) => {
  const { SavingsBalance = 0, ...attachments } = data
  const savingsBalanceDisplay = formatBalance(SavingsBalance)

  return (
    <div>
      <Card
        type={'icon'}
        icon={'icon-income'}
        addClass={'bottom-border mt-0'}
        title="Income"
      />
      {(!data || Object.keys(data).length === 0) && (
        <p> No information is provided</p>
      )}
      <Box mb={3}>
        <Text as="span" fontWeight="700" lineHeight="2">
          Savings Balance
        </Text>
        <Text mb="0">{savingsBalanceDisplay}</Text>
      </Box>
      {attachments && attachList(attachments)}
    </div>
  )
}

Income.propTypes = propTypes

export default Income
