import {
  ABOUT_ME_INPUT,
  ADD_CURRENT_NEW_TENANT,
  ADULT_INPUT,
  CHILDREN_INPUT,
  CLEAR_CURRENT_NEW_TENANT,
  CLEAR_ERROR,
  GROUP_INPUT,
  HAS_ACCEPTED_CONDITION_INTPU,
  HOUSEHOLD_YEARLY_INCOME,
  NEW_TENANT,
  ON_ADDER_CHANGE,
  PEOPLE_INPUT,
  PETS_INPUT,
  PING,
  RECEIVE_APPLICANT,
  RECEIVE_APPLICATION,
  RECEIVE_APPLICATION_APPLY_RULES,
  RECEIVE_APPLYANYWHERE,
  RECEIVE_COMPLETENESS,
  RECEIVE_CONFIRMATION_SUMMARY,
  RECEIVE_ERROR,
  RECEIVE_OTHER_OFFERS,
  RECEIVE_PROPERTY,
  RECEIVE_PROPERTY_DISCLOSURES,
  RECEIVE_PROPERTY_OFFER,
  RECEIVE_PROPERTY_SUMMARY,
  RECEIVE_SNUG_RANK,
  REMOVE_TENANT,
  REQUESTS_INPUT,
  RESET_APPLICATION,
  RESET_STATE,
  SET_PROPERTY_ID,
  START_NEW_APPLICATION,
  TAKE_HOLDING_DEPOSIT,
  TOGGLE_FAVORITE_MARK,
} from 'app/sm/apply/apply_actions'
import { monthsEnum } from 'app/sm/helpers'

const defaultProperty = {
  agent: {},
  offer: {},
}

const _defaultState = {
  property: { ...defaultProperty },
  application: {
    group: '',
    pets: 0,
    requests: '',
    applicants: [],
    isJoint: false,
    hasAcceptedCondition: false,
    adults: 1,
  },
  applicant: {},
  currentTenant: {},
  adders: {
    rent: {
      minValue: 0,
      maxValue: Infinity,
      quantity: 0,
      measure: '$',
    },
    term: {
      minValue: 0,
      maxValue: Infinity,
      quantity: 0,
      measure: 'm',
    },
    moveInDate: {
      minValue: 1,
      maxValue: 31,
      year: new Date().getFullYear(),
      quantity: new Date().getDate(),
      measure: monthsEnum[new Date().getMonth()].measure,
    },
    vehicles: {
      minValue: 0,
      maxValue: Infinity,
      quantity: 0,
      measure: '',
    },
  },
  vehicleType: 0,
  vehicleRegistrationType: false,
  vehicleRegistrationText: '',
  summary: {},
  completeness: {},
  snugRank: {
    score: 0,
    rank: 0,
    total_applications: 0,
  },
  applyRules: {},
  propertyDisclosure: {
    hasPropertyDisclosure: false,
  },
  otherOffers: {
    rent: null,
    term: null,
    moveInDate: null,
  },
  propertyId: null,
  takeHoldingDeposit: null,
  error: '',
  confirmationSummary: {
    application_guid: '',
    application_status: 0,
    is_joint: false,
    application_slug: '',
    applicants: [],
    address: {},
    profile_completeness: 0,
  },
}

// The 'update' operation is basically a preemptive change. Not controlled by the handlers
// We need that to bind the actual data for the adders when we fetch an existing
// application

const adderHandlers = {
  rent: (it, operation, payload) => {
    if (operation === 'update') {
      return Object.assign({}, it, {
        quantity: payload.quantity,
        measure: payload.measure,
      })
    }

    if (operation === '+' && it.maxValue >= it.quantity + 5) {
      return Object.assign({}, it, { quantity: it.quantity + 5 })
    }

    if (operation === '-' && it.minValue <= it.quantity - 5) {
      return Object.assign({}, it, { quantity: it.quantity - 5 })
    }

    return it
  },
  vehicles: (it, operation, payload) => {
    if (operation === 'update') {
      return Object.assign({}, it, {
        quantity: payload.quantity,
        measure: payload.measure,
      })
    }

    if (operation === '+' && it.maxValue >= it.quantity + 1) {
      return Object.assign({}, it, { quantity: it.quantity + 1 })
    }

    if (operation === '-' && it.minValue <= it.quantity - 1) {
      return Object.assign({}, it, { quantity: it.quantity - 1 })
    }

    return it
  },
  term: (it, operation, payload) => {
    if (operation === 'update') {
      return Object.assign({}, it, {
        quantity: payload.quantity,
        measure: payload.measure,
      })
    }

    if (operation === '+' && it.maxValue >= it.quantity + 1) {
      return Object.assign({}, it, { quantity: it.quantity + 1 })
    }

    if (operation === '-' && it.minValue <= it.quantity - 1) {
      return Object.assign({}, it, { quantity: it.quantity - 1 })
    }

    return it
  },
  moveInDate: (it, operation, payload) => {
    const month = monthsEnum.find((m) => m.measure === it.measure) || {}

    if (operation === 'update') {
      return Object.assign({}, it, {
        year: payload.year,
        quantity: payload.quantity,
        measure: payload.measure,
      })
    }

    if (operation === '+') {
      if (month.days >= it.quantity + 1) {
        return Object.assign({}, it, { quantity: it.quantity + 1 })
      } else {
        let nextMonthIndex =
          monthsEnum.findIndex((m) => m.measure === it.measure) + 1
        const year = nextMonthIndex === 12 ? it.year + 1 : it.year
        nextMonthIndex = nextMonthIndex === 12 ? 0 : nextMonthIndex

        return Object.assign({}, it, {
          year,
          measure: monthsEnum[nextMonthIndex].measure,
          quantity: 1,
        })
      }
    }

    if (operation === '-') {
      if (it.quantity - 1 >= 1) {
        return Object.assign({}, it, { quantity: it.quantity - 1 })
      } else {
        let prevMonthIndex =
          monthsEnum.findIndex((m) => m.measure === it.measure) - 1
        const year = prevMonthIndex === -1 ? it.year - 1 : it.year
        prevMonthIndex = prevMonthIndex === -1 ? 11 : prevMonthIndex

        return Object.assign({}, it, {
          year,
          measure: monthsEnum[prevMonthIndex].measure,
          quantity: monthsEnum[prevMonthIndex].days,
        })
      }
    }
  },
}

const ApplyReducer = (state = _defaultState, action) => {
  let {
    type,
    property,
    application,
    applicant,
    propertyId,
    adderType,
    operation,
    payload,
    input,
    summary,
    completeness,
    snugRank,
    applyRules,
    otherOffers,
    takeHoldingDeposit,
    tenant,
    nth,
    currentTenant,
    error,
    offer,
    confirmationSummary,
    applyAnywhereListing,
    propertyDisclosure,
  } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case PING:
      return newState
    case SET_PROPERTY_ID:
      return Object.assign({}, newState, { propertyId })
    case RESET_APPLICATION:
      return Object.assign({}, newState, {
        application: _defaultState.application,
      })
    case RECEIVE_PROPERTY:
      return {
        ...state,
        property,
        applyAnywhereListing: null,
      }
    case RECEIVE_APPLYANYWHERE:
      return {
        ...state,
        applyAnywhereListing,
        property: { ...defaultProperty },
      }
    case RECEIVE_APPLICATION:
      return Object.assign({}, newState, { application })
    case RECEIVE_APPLICANT:
      return Object.assign({}, newState, { applicant })
    case ON_ADDER_CHANGE:
      let adders = Object.assign({}, newState.adders, {
        [adderType]: adderHandlers[adderType](
          newState.adders[adderType],
          operation,
          payload,
        ),
      })
      return Object.assign({}, newState, { adders })
    case GROUP_INPUT:
      return Object.assign({}, newState, {
        application: Object.assign({}, newState.application, {
          group: input,
        }),
      })
    case PETS_INPUT:
      return Object.assign({}, newState, {
        application: Object.assign({}, newState.application, {
          pets: Number(input),
        }),
      })
    case HAS_ACCEPTED_CONDITION_INTPU:
      return Object.assign({}, newState, {
        application: Object.assign({}, newState.application, {
          hasAcceptedCondition: input,
        }),
      })
    case REQUESTS_INPUT:
      return Object.assign({}, newState, {
        application: Object.assign({}, newState.application, {
          requests: input,
        }),
      })
    case ABOUT_ME_INPUT:
      return Object.assign({}, newState, {
        application: Object.assign({}, newState.application, {
          aboutMe: input,
        }),
      })

    case HOUSEHOLD_YEARLY_INCOME:
      return Object.assign({}, newState, {
        application: Object.assign({}, newState.application, {
          householdYearlyIncome: input,
        }),
      })

    case PEOPLE_INPUT:
      return Object.assign({}, newState, {
        application: Object.assign({}, newState.application, {
          people: Number(input),
        }),
      })
    case ADULT_INPUT:
      return Object.assign({}, newState, {
        application: Object.assign({}, newState.application, {
          adults: Number(input),
        }),
      })
    case CHILDREN_INPUT:
      return Object.assign({}, newState, {
        application: Object.assign({}, newState.application, {
          children: Number(input),
        }),
      })
    case TOGGLE_FAVORITE_MARK:
      return Object.assign({}, newState, {
        property: Object.assign({}, newState.property, {
          isFavourite: !newState.property.isFavourite,
        }),
      })
    case RECEIVE_PROPERTY_SUMMARY:
      return Object.assign(
        newState,
        { summary },
        { applicant: summary.applicant },
      )
    case RECEIVE_COMPLETENESS:
      return Object.assign(newState, { completeness })
    case RECEIVE_SNUG_RANK:
      return Object.assign(newState, { snugRank })
    case RECEIVE_APPLICATION_APPLY_RULES:
      return Object.assign(newState, { applyRules })
    case RECEIVE_PROPERTY_DISCLOSURES:
      return Object.assign(newState, { propertyDisclosure })
    case RECEIVE_OTHER_OFFERS:
      return Object.assign(newState, { otherOffers })
    case TAKE_HOLDING_DEPOSIT:
      return Object.assign(newState, { takeHoldingDeposit })
    case NEW_TENANT:
      return Object.assign({}, newState, {
        application: Object.assign({}, newState.application, {
          applicants: (newState.application.applicants || []).concat(tenant),
        }),
      })
    case REMOVE_TENANT:
      return Object.assign({}, newState, {
        application: Object.assign({}, newState.application, {
          applicants: newState.application.applicants.filter(
            (_, n) => n !== nth,
          ),
        }),
      })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case RESET_STATE:
      return _defaultState
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    case ADD_CURRENT_NEW_TENANT:
      return Object.assign(newState, { currentTenant })
    case CLEAR_CURRENT_NEW_TENANT:
      return Object.assign(newState, { currentTenant: {} })
    case RECEIVE_PROPERTY_OFFER:
      const {
        adders: { rent, term, moveInDate, vehicles },
      } = newState
      return Object.assign(newState, {
        adders: {
          rent: {
            ...rent,
            quantity: offer.rent,
          },
          term: {
            ...term,
            quantity: offer.term,
          },
          moveInDate: {
            ...moveInDate,
            quantity: offer.moveInDate.quantity,
            measure: offer.moveInDate.measure,
            year: offer.moveInDate.year,
          },
          vehicles: {
            ...vehicles,
            quantity: offer.vehicles,
          },
        },
      })
    case RECEIVE_CONFIRMATION_SUMMARY:
      return Object.assign(newState, { confirmationSummary })
    case START_NEW_APPLICATION: {
      return {
        ..._defaultState,
      }
    }
    default:
      return newState
  }
}

export default ApplyReducer
