import React from 'react'

import moment from 'moment'
import { Bar, Line } from 'recharts'

import * as ActivityCharts from 'app/agency/advance_activity/activity_charts'
import ActivityRowField from 'app/agency/advance_activity/activity_row_field'
import ActivityRowLabels from 'app/agency/advance_activity/activity_row_label'
import PropertiesLeasedRows from 'app/agency/advance_activity/properties_leased_rows'
import PropertiesVacancyRows from 'app/agency/advance_activity/properties_vacancy_rows'
import RentAchievedRows from 'app/agency/advance_activity/rent_achieved_rows'
import {
  Box,
  Flex,
  LoadingSection,
} from 'app/components/design-system-components'
import { managementTypeOptions } from 'app/constants/offer.constants'
import {
  filtersIds,
  LEASED_DATE_FILTER_KEY,
  LISTED_DATE_FILTER_KEY,
} from 'app/dashboard/team_activity/ActivityFilters/filters.config'
import Checkbox from 'app/shared_components/checkbox'
import { ErrorMessage } from 'app/shared_components/helpers'
import { FlexBox } from 'app/shared_components/shared_styled_component'
import {
  DATE_DAY_SHORT_STRING_MONTH_YEAR,
  YEAR_DASH_MONTH,
} from 'app/utils/datetime/helpers'
import { currencyFormat } from 'app/utils/numbers/helpers'
import { capitalizeFirstLetterOfString } from 'app/utils/strings/helpers'

const labelMonthFormat = 'MMM-YYYY'
const fieldLabelClass = 'col-md-6 col-sm-6 col-xs-4'
const percentFieldLabelClass = 'col-md-6 col-sm-6 col-xs-4'
const dataFieldClass = 'col-md-8 col-sm-5 col-xs-5'
const dataPercentFieldClass = 'col-md-6 col-sm-6 col-xs-8'
const dataFieldContainer = 'col-md-6 col-sm-6 col-xs-8'
const dataPercentFieldContainer = 'col-md-6 col-sm-6 col-xs-8'

const zip = (a1, a2, a3, key) =>
  a1[key].map((x, i) => [
    { ...x, monthStarting: a1.monthStarting },
    { ...a2[key][i], monthStarting: a2.monthStarting },
    { ...a3[key][i], monthStarting: a3.monthStarting },
  ])

export const zipObjects = (a1, a2, a3) => [a1, a2, a3]

const TableHeaders = ({ text = '', headerClass = '', children }) => {
  return (
    <div className="advance-activity-section-headers">
      <div className={`col-md-6 col-sm-5 col-xs-6 ${headerClass} header-font`}>
        {text}
      </div>
      {children || (
        <div>
          <div className="space-placeholder" />
          <div className="space-placeholder" />
        </div>
      )}
    </div>
  )
}

export const advancedReporttimePeriods = {
  yearToYearMonth: 0,
  prevMonth: 1,
  customDate: 2,
}

const totalLabel = 2

const propertiesLeasingLabel = [
  'Vacant Managements',
  'Tenanted Managements',
  'Total',
]

const emptyActivity = (date) => {
  return {
    monthStarting: date,
    rentAchieved: {
      asListedCount: 0,
      asListedPercentage: 0,
      belowListedCount: 0,
      belowListedPercentage: 0,
      aboveListedCount: 0,
      aboveListedPercentage: 0,
      averageRent: 0,
    },
    propertiesLeased: [
      {
        label: 1,
        leasedCount: 0,
        leasedPercent: 0,
        avgVacantDays: 0,
        avgDaysOnMarket: 0,
      },
      {
        label: 2,
        leasedCount: 0,
        leasedPercent: 0,
        avgVacantDays: 0,
        avgDaysOnMarket: 0,
      },
      {
        label: 3,
        leasedCount: 0,
        leasedPercent: 0,
        avgVacantDays: 0,
        avgDaysOnMarket: 0,
      },
    ],
  }
}

const emptyInterestAcitivity = (date) => {
  return {
    monthStarting: date,
    applications: 0,
    enquiries: 0,
    viewings: 0,
    viewingsAttendees: 0,
  }
}

const AdvancedActivityTable = ({
  className = '',
  advanceActivities,
  propertiesActivitiesReport = [],
  propertiesReportLoadingStates,
  customColumnSelector,
  customColumnLabel,
  error = '',
  filters,
  onFiltersUpdated,
}) => {
  const {
    propertiesLeasedActivityReport = [],
    interestActivitiesReport = [],
    newListingsCounts = [],
    lostRentReport = [],
    enquirySources = [],
  } = advanceActivities

  const prevMonth = moment().subtract(1, 'months')
  const prevYearPrevMonth = moment().subtract(1, 'months').subtract(1, 'years')

  const prevMonthLabel = prevMonth.format(labelMonthFormat)
  const yearToYearMonthLabel = prevYearPrevMonth.format(labelMonthFormat)
  const columnLabels = [yearToYearMonthLabel, prevMonthLabel, customColumnLabel]

  const prevMonthSelector = prevMonth.format(YEAR_DASH_MONTH)
  const prevYearPrevMonthSelector = prevYearPrevMonth.format(YEAR_DASH_MONTH)

  const propertiesReportColumnsLabels = [
    'Mgmt',
    'Listed',
    'Adv. Rent',
    'DoM',
    'Enq',
    'Viewings',
    'Registrations',
    'Apps',
    'Leased',
    'Leased Rent',
    'Vac (days)',
  ]
  const propertiesLeasedActivities = propertiesLeasedActivityReport || [
    emptyActivity(prevYearPrevMonthSelector),
    emptyActivity(prevMonthSelector),
    emptyActivity(customColumnSelector),
  ]

  const prevYearPrevMonthNewListings = newListingsCounts.find(
    (act) => act.monthStarting === prevYearPrevMonthSelector,
  )
  const prevMonthNewListings = newListingsCounts.find(
    (act) => act.monthStarting === prevMonthSelector,
  )
  const customRangeNewListings = newListingsCounts.find(
    (act) => act.monthStarting === customColumnSelector,
  )

  const readyNewListings = [
    ...(prevYearPrevMonthNewListings ? [prevYearPrevMonthNewListings] : []),
    ...(prevMonthNewListings ? [prevMonthNewListings] : []),
    ...(customRangeNewListings ? [customRangeNewListings] : []),
  ]

  const transformedNewListingsCounts = readyNewListings.map((period) => {
    period.totalCount = period.counts.reduce(
      (accum, item) => accum + item.count,
      0,
    )
    return period
  })

  const prevYearPrevMonthLostRent = lostRentReport.find(
    (act) => act.monthStarting === prevYearPrevMonthSelector,
  )
  const prevMonthLostRent = lostRentReport.find(
    (act) => act.monthStarting === prevMonthSelector,
  )
  const customRangeLostRent = lostRentReport.find(
    (act) => act.monthStarting === customColumnSelector,
  )

  const readyLostRentReport = [
    prevYearPrevMonthLostRent || {},
    prevMonthLostRent || {},
    customRangeLostRent || {},
  ]

  const prevYearPrevMonthEnquirySrc = enquirySources.find(
    (act) => act.monthStarting === prevYearPrevMonthSelector,
  )
  const prevMonthEnquirySrc = enquirySources.find(
    (act) => act.monthStarting === prevMonthSelector,
  )
  const customRangeEnquirySrc = enquirySources.find(
    (act) => act.monthStarting === customColumnSelector,
  )

  const readyEnquirySources = [
    ...(prevYearPrevMonthEnquirySrc ? [prevYearPrevMonthEnquirySrc] : []),
    ...(prevMonthEnquirySrc ? [prevMonthEnquirySrc] : []),
    ...(customRangeEnquirySrc ? [customRangeEnquirySrc] : []),
  ]

  let existingEnquirySources = []
  readyEnquirySources.forEach(({ sourcesCounts }) => {
    existingEnquirySources = [
      ...new Set([
        ...existingEnquirySources,
        ...sourcesCounts.map(({ source }) => source),
      ]),
    ]
  })

  const enquirySourcesReadyRows = existingEnquirySources.map((sourceLabel) => {
    let countColumns = []

    readyEnquirySources.map((monthSourcesPair) => {
      const countObj = monthSourcesPair.sourcesCounts.find(
        ({ source }) => source === sourceLabel,
      )
      const countWithPercentageText = countObj
        ? `${countObj.count} (${countObj.percentage}%)`
        : 0
      countColumns.push({
        month: monthSourcesPair.monthStarting,
        countWithPercentageText,
      })
    })
    return { source: capitalizeFirstLetterOfString(sourceLabel), countColumns }
  })

  const customRangeActivities =
    propertiesLeasedActivities.find(
      (act) => act.monthStarting === customColumnSelector,
    ) || emptyActivity(customColumnSelector)
  const prevMonthActivities =
    propertiesLeasedActivities.find(
      (act) => act.monthStarting === prevMonthSelector,
    ) || emptyActivity(prevMonthSelector)
  const yearToYearActivities =
    propertiesLeasedActivities.find(
      (act) => act.monthStarting === prevYearPrevMonthSelector,
    ) || emptyActivity(prevYearPrevMonthSelector)

  const mergedPropertiesLeased = zip(
    yearToYearActivities || {},
    prevMonthActivities || {},
    customRangeActivities || {},
    'propertiesLeased',
  )
  const mergedRentAchieved = [
    yearToYearActivities.rentAchieved || {},
    prevMonthActivities.rentAchieved || {},
    customRangeActivities.rentAchieved || {},
  ]
  const totalPropertiesLeasedData = mergedPropertiesLeased[totalLabel]
  const propertiesLeasedData = [
    {
      name: columnLabels[advancedReporttimePeriods.yearToYearMonth],
      yearToYearPriorMonth:
        totalPropertiesLeasedData &&
        totalPropertiesLeasedData[advancedReporttimePeriods.yearToYearMonth]
          ? totalPropertiesLeasedData[advancedReporttimePeriods.yearToYearMonth]
              .leasedCount
          : 0,
    },
    {
      name: columnLabels[advancedReporttimePeriods.prevMonth],
      priorMonth:
        totalPropertiesLeasedData &&
        totalPropertiesLeasedData[advancedReporttimePeriods.prevMonth]
          ? totalPropertiesLeasedData[advancedReporttimePeriods.prevMonth]
              .leasedCount
          : 0,
    },
    {
      name: columnLabels[advancedReporttimePeriods.customDate],
      customDate:
        totalPropertiesLeasedData &&
        totalPropertiesLeasedData[advancedReporttimePeriods.customDate]
          ? totalPropertiesLeasedData[advancedReporttimePeriods.customDate]
              .leasedCount
          : 0,
    },
  ]
  const lineChartData = [
    {
      name: columnLabels[advancedReporttimePeriods.yearToYearMonth],
      averageRent:
        mergedRentAchieved[advancedReporttimePeriods.yearToYearMonth]
          .averageRent,
      daysOnMarket:
        totalPropertiesLeasedData &&
        totalPropertiesLeasedData[advancedReporttimePeriods.yearToYearMonth]
          ? totalPropertiesLeasedData[advancedReporttimePeriods.yearToYearMonth]
              .avgDaysOnMarket
          : 0,
    },
    {
      name: columnLabels[advancedReporttimePeriods.prevMonth],
      averageRent:
        mergedRentAchieved[advancedReporttimePeriods.prevMonth].averageRent,
      daysOnMarket:
        totalPropertiesLeasedData &&
        totalPropertiesLeasedData[advancedReporttimePeriods.prevMonth]
          ? totalPropertiesLeasedData[advancedReporttimePeriods.prevMonth]
              .avgDaysOnMarket
          : 0,
    },
    {
      name: columnLabels[advancedReporttimePeriods.customDate],
      averageRent:
        mergedRentAchieved[advancedReporttimePeriods.customDate].averageRent,
      daysOnMarket:
        totalPropertiesLeasedData &&
        totalPropertiesLeasedData[advancedReporttimePeriods.customDate]
          ? totalPropertiesLeasedData[advancedReporttimePeriods.customDate]
              .avgDaysOnMarket
          : 0,
    },
  ]

  const customRangeInterestActivities =
    interestActivitiesReport.find(
      (act) => act.monthStarting === customColumnSelector,
    ) || emptyInterestAcitivity(customColumnSelector)

  const PriorMonthInterestActivities =
    interestActivitiesReport.find(
      (act) => act.monthStarting === prevMonthSelector,
    ) || emptyInterestAcitivity(prevMonthSelector)

  const yearToYearMonthInterestActivities =
    interestActivitiesReport.find(
      (act) => act.monthStarting === prevYearPrevMonthSelector,
    ) || emptyInterestAcitivity(prevYearPrevMonthSelector)

  const mergedInterestActivities = [
    yearToYearMonthInterestActivities,
    PriorMonthInterestActivities,
    customRangeInterestActivities,
  ]

  const propertiesReportComponent = (
    <div>
      {(propertiesActivitiesReport || []).map((property) => {
        const foundManagementType = managementTypeOptions.find(
          ({ value }) => value === property.managementType,
        )
        const managementTypeLabel = foundManagementType
          ? foundManagementType.label
          : 'N/A'

        return (
          <div className="advance-activity-content-list-row">
            <FlexBox
              justifyContent="start"
              className="col-md-2 col-sm-2 font-size-14"
              style={{ margin: 'auto', whiteSpace: 'nowrap' }}
            >
              <Box mr="1rem">{property.address}</Box>
            </FlexBox>

            <div className="col-md-10 col-sm-10 col-xs-12 display-flex pl0">
              <ActivityRowField
                key={`${property.id}managementType`}
                field={managementTypeLabel}
                widthClass={'col-md-1 col-sm-1'}
                fieldClass="mb0 mr-auto ml-auto font-size-14"
                style={{ whiteSpace: 'nowrap' }}
              />
              <ActivityRowField
                key={`${property.id}listedDate`}
                field={moment(property.listedDate).format(
                  DATE_DAY_SHORT_STRING_MONTH_YEAR,
                )}
                widthClass={'col-md-1 col-sm-1'}
                fieldClass="mb0 mr-auto ml-auto font-size-12"
              />
              <ActivityRowField
                key={`${property.id}listedRent`}
                field={currencyFormat(property.listedRent, 0)}
                widthClass={'col-md-1 col-sm-1'}
                fieldClass="mb0 mr-auto ml-auto font-size-14"
              />
              <ActivityRowField
                key={`${property.id}daysOnMarket`}
                field={property.daysOnMarket}
                widthClass={'col-md-1 col-sm-1'}
                fieldClass="mb0 mr-auto ml-auto font-size-14"
              />
              <ActivityRowField
                key={`${property.id}enquiries`}
                field={property.enquiries}
                widthClass={'col-md-1 col-sm-1'}
                fieldClass="mb0 mr-auto ml-auto font-size-14"
              />
              <ActivityRowField
                key={`${property.id}openHome`}
                field={property.openHome}
                widthClass={'col-md-1 col-sm-1'}
                fieldClass="mb0 mr-auto ml-auto font-size-14"
              />
              <ActivityRowField
                key={`${property.id}attendees`}
                field={property.attendees}
                widthClass={'col-md-1 col-sm-1'}
                fieldClass="mb0 mr-auto ml-auto font-size-14"
              />
              <ActivityRowField
                key={`${property.id}applications`}
                field={property.applications}
                widthClass={'col-md-1 col-sm-1'}
                fieldClass="mb0 mr-auto ml-auto font-size-14"
              />
              <ActivityRowField
                key={`${property.id}leasedDate`}
                field={
                  property.leaseDate
                    ? moment(property.leaseDate).format(
                        DATE_DAY_SHORT_STRING_MONTH_YEAR,
                      )
                    : 'N/A'
                }
                widthClass={'col-md-1 col-sm-1'}
                fieldClass="mb0 mr-auto ml-autov font-size-12"
              />
              <ActivityRowField
                key={`${property.id}leasedRent`}
                field={
                  property.leasedRent
                    ? currencyFormat(property.leasedRent, 0)
                    : 'N/A'
                }
                widthClass={'col-md-1 col-sm-1'}
                fieldClass="mb0 mr-auto ml-auto font-size-14"
              />

              <ActivityRowField
                key={`${property.id}vacancy`}
                field={Math.abs(property.vacancy)}
                widthClass={'col-md-1 col-sm-1'}
                fieldClass="mb0 mr-auto ml-auto font-size-14"
              />
            </div>
          </div>
        )
      })}
    </div>
  )

  const onDateFilterByChanged = (val) => {
    onFiltersUpdated(filtersIds.dateFilterBy, val)
  }

  return (
    <div className={className}>
      <div className="row mobile-hide mb40">
        <div className="col-sm-6">
          <ActivityCharts.TeamActivityBarChart
            data={propertiesLeasedData}
            heading="Properties Leased"
            className="activity-bar-chart-position"
          >
            <Bar
              dataKey="yearToYearPriorMonth"
              fill="#84aad8"
              legendType="none"
            />
            <Bar dataKey="priorMonth" fill="#82ca9d" legendType="none" />
            <Bar dataKey="customDate" fill="#8884d8" legendType="none" />
          </ActivityCharts.TeamActivityBarChart>
        </div>
        <div className="col-sm-6">
          <ActivityCharts.TeamActivityLineChart
            data={lineChartData}
            heading="Rent and Days on Market (Avg)"
            headingClass="ml40"
          >
            {/* if animation interrupted, dots don't show https://github.com/recharts/recharts/issues/1426
            https://github.com/recharts/recharts/issues/1135 */}

            <Line
              yAxisId="left"
              type="monotone"
              dataKey="averageRent"
              name="Average rent"
              stroke="#8884d8"
              dot
              isAnimationActive={false}
              label={<ActivityCharts.LineChartDataLabel />}
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="daysOnMarket"
              name="Days on Market"
              stroke="#82ca9d"
              dot
              isAnimationActive={false}
              label={<ActivityCharts.LineChartDataLabel />}
            />
          </ActivityCharts.TeamActivityLineChart>
        </div>
      </div>

      <TableHeaders text="Leasing">
        {columnLabels && (
          <ActivityRowLabels
            componentClassName="col-md-6 col-sm-5 col-xs-6"
            columnsLabels={columnLabels}
          />
        )}
      </TableHeaders>
      {mergedPropertiesLeased && (
        <div className="mb40">
          {mergedPropertiesLeased.map((act, index) => {
            return (
              <PropertiesLeasedRows
                key={index}
                propertiesActivities={act}
                fieldLabelClass={fieldLabelClass}
                percentFieldLabelClass={percentFieldLabelClass}
                dataFieldClass={dataFieldClass}
                dataPercentFieldClass={dataPercentFieldClass}
                dataFieldContainer={dataFieldContainer}
                dataPercentFieldContainer={dataPercentFieldContainer}
                labels={propertiesLeasingLabel}
                prevYearSelector={prevYearPrevMonthSelector}
                prevMonthSelector={prevMonthSelector}
                customColumnSelector={customColumnSelector}
              />
            )
          })}
          <div className="advance-activity-content-list-row">
            <ActivityRowField
              widthClass={fieldLabelClass}
              fieldClass="mb0"
              field="New Listings"
            />
            <div className={`${dataFieldContainer} display-flex`}>
              {transformedNewListingsCounts.map((pair) => {
                return (
                  <ActivityRowField
                    key={pair.monthStarting}
                    field={pair.totalCount}
                    widthClass={dataFieldClass}
                    fieldClass="mb0 mr-auto ml-auto"
                  />
                )
              })}
            </div>
          </div>

          {managementTypeOptions.map((manageType) => {
            return (
              <div className="advance-activity-content-list-row">
                <ActivityRowField
                  widthClass={fieldLabelClass}
                  fieldClass="mb0 pl20"
                  field={manageType.label}
                />
                <div className={`${dataFieldContainer} display-flex`}>
                  {transformedNewListingsCounts.map((pair) => {
                    const countObj = pair.counts.find(
                      ({ type }) => type === manageType.value,
                    )
                    return (
                      <ActivityRowField
                        key={pair.monthStarting + String(manageType.value)}
                        field={countObj ? countObj.count : 0}
                        widthClass={dataFieldClass}
                        fieldClass="mb0 mr-auto ml-auto"
                      />
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      )}
      <TableHeaders text="Rent" />
      {
        <div className="mb40">
          <RentAchievedRows
            mergedRentAchievedActivities={mergedRentAchieved}
            fieldLabelClass={fieldLabelClass}
            percentFieldLabelClass={percentFieldLabelClass}
            dataFieldClass={dataFieldClass}
            dataPercentFieldClass={dataPercentFieldClass}
            dataFieldContainer={dataFieldContainer}
            dataPercentFieldContainer={dataPercentFieldContainer}
          />
          <div className="advance-activity-content-list-row grey-background">
            <ActivityRowField
              widthClass={fieldLabelClass}
              fieldClass="mb0"
              field="Lost Rent"
            />
            <div className={`${dataFieldContainer} display-flex`}>
              {readyLostRentReport.map((pair) => {
                return (
                  <ActivityRowField
                    key={pair.monthStarting}
                    field={currencyFormat(pair.amount)}
                    widthClass={dataFieldClass}
                    fieldClass="mb0 mr-auto ml-auto"
                  />
                )
              })}
            </div>
          </div>
        </div>
      }
      <TableHeaders text="Vacancy" />
      {mergedPropertiesLeased && (
        <div className="mb40">
          {mergedPropertiesLeased.map((act, index) => {
            return (
              <PropertiesVacancyRows
                key={index}
                propertiesActivities={act}
                percentFieldLabelClass={percentFieldLabelClass}
                dataPercentFieldClass={dataPercentFieldClass}
                dataPercentFieldContainer={dataPercentFieldContainer}
                prevYearSelector={prevYearPrevMonthSelector}
                prevMonthSelector={prevMonthSelector}
                customColumnSelector={customColumnSelector}
              />
            )
          })}
        </div>
      )}
      <TableHeaders text="Interest" />
      {mergedInterestActivities && (
        <div>
          <div className="advance-activity-content-list-row">
            <ActivityRowField
              widthClass={fieldLabelClass}
              fieldClass="mb0"
              field="Enquiries"
            />
            <div className={`${dataFieldContainer} display-flex`}>
              {mergedInterestActivities.map((act, index) => {
                return (
                  <ActivityRowField
                    key={index}
                    field={act ? act.enquiries : 0}
                    widthClass={dataFieldClass}
                    fieldClass="mb0 mr-auto ml-auto"
                  />
                )
              })}
            </div>
          </div>
          <div className="advance-activity-content-list-row grey-background">
            <ActivityRowField
              widthClass={fieldLabelClass}
              fieldClass="mb0"
              field="Viewings"
            />
            <div className={`${dataFieldContainer} display-flex`}>
              {mergedInterestActivities.map((act, index) => {
                return (
                  <ActivityRowField
                    key={index}
                    field={act ? act.viewings : 0}
                    widthClass={dataFieldClass}
                    fieldClass="mb0 mr-auto ml-auto"
                  />
                )
              })}
            </div>
          </div>
          <div className="advance-activity-content-list-row">
            <ActivityRowField
              widthClass={fieldLabelClass}
              fieldClass="mb0"
              field="Viewing Attendees"
            />
            <div className={`${dataFieldContainer} display-flex`}>
              {mergedInterestActivities.map((act, index) => {
                return (
                  <ActivityRowField
                    key={index}
                    field={act ? act.viewingsAttendees : 0}
                    widthClass={dataFieldClass}
                    fieldClass="mb0 mr-auto ml-auto"
                  />
                )
              })}
            </div>
          </div>
          <div className="advance-activity-content-list-row grey-background">
            <ActivityRowField
              widthClass={fieldLabelClass}
              fieldClass="mb0"
              field="Applications"
            />
            <div className={`${dataFieldContainer} display-flex`}>
              {mergedInterestActivities.map((act, index) => {
                return (
                  <ActivityRowField
                    key={index}
                    field={act ? act.applications : 0}
                    widthClass={dataFieldClass}
                    fieldClass="mb0 mr-auto ml-auto"
                  />
                )
              })}
            </div>
          </div>
        </div>
      )}
      <div className="mt40">
        <TableHeaders text="Enquiries" />
        {enquirySourcesReadyRows && (
          <div>
            {enquirySourcesReadyRows.map((row) => {
              return (
                <div className="advance-activity-content-list-row">
                  <ActivityRowField
                    widthClass={fieldLabelClass}
                    fieldClass="mb0"
                    field={row.source}
                  />
                  <div className={`${dataFieldContainer} display-flex`}>
                    {row.countColumns.map((column) => {
                      return (
                        <ActivityRowField
                          key={`${row.source}${column.month}`}
                          field={column.countWithPercentageText}
                          widthClass={dataFieldClass}
                          fieldClass="mb0 mr-auto ml-auto"
                        />
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>

      <Flex mt={9}>
        <Box>
          <Checkbox
            label="Leased"
            value={filters[filtersIds.dateFilterBy] === LEASED_DATE_FILTER_KEY}
            onChange={() => onDateFilterByChanged(LEASED_DATE_FILTER_KEY)}
          />
        </Box>
        <Box ml={5}>
          <Checkbox
            label="Listed"
            value={filters[filtersIds.dateFilterBy] === LISTED_DATE_FILTER_KEY}
            onChange={() => onDateFilterByChanged(LISTED_DATE_FILTER_KEY)}
          />
        </Box>
      </Flex>

      <div className="mt40" style={{ overflowX: 'auto' }}>
        <TableHeaders text="Properties" headerClass="col-md-2 col-sm-1">
          {propertiesReportColumnsLabels && (
            <ActivityRowLabels
              componentClassName="col-md-10 col-sm-11 pl0"
              columnsLabels={propertiesReportColumnsLabels}
              colClassName="col-md-2 pr2"
            />
          )}
        </TableHeaders>
        <LoadingSection loadingState={propertiesReportLoadingStates}>
          {propertiesActivitiesReport && propertiesReportComponent}
        </LoadingSection>
      </div>

      <ErrorMessage error={error} />
    </div>
  )
}

export default AdvancedActivityTable
