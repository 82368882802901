import React from 'react'

import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { NO_IMAGE_PLACEHOLDER_URL, urlTo } from 'app/sm/helpers'
import { FeatureFlag } from 'config/features'

function compare(a, b) {
  if (a.streetName > b.streetName) return 1
  if (a.streetName < b.streetName) return -1
  if (a.streetNumber > b.streetNumber) return 1
  if (a.streetNumber < b.streetNumber) return -1
  return 0
}

export function getImageFromProperty(p) {
  let images = (p.property || {}).images
  const hasImages = images && images.length > 0

  if (hasImages) {
    return (images.find((image) => image.isMain === true) || images[0]).URL
  }

  return NO_IMAGE_PLACEHOLDER_URL
}

const mapStateToProps = ({ MyProperties, session }) => ({
  properties: MyProperties.properties,
  currentAgency: session.currentAgency,
  teams: session.teams,
  currentUser: session.currentUser,
  currentTeam: session.currentTeam,
})

class PortfolioNavbar extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.setBackUrl(window.location.pathname)
  }

  formatAddresses(properties) {
    return properties
      .map((p) =>
        Object.assign({}, p.address, {
          imageUrl: getImageFromProperty(p),
          propertyId: p.property.guidID,
          friendlyName: p.property.address.friendlyName,
          acceptedApplicationId: p.acceptedApplication
            ? p.acceptedApplication.guidID
            : null,
          streetName: p.property.address.streetName,
          streetNumber: p.property.address.streetNumber,
        }),
      )
      .sort((a, b) => compare(a, b))
  }

  render() {
    const {
      properties = [],
      currentAgency,
      isTeamsTabActive,
      bondCoverTab,
    } = this.props
    const addresses = this.formatAddresses(properties)

    const teamSlug = this.props.currentTeam && this.props.currentTeam.slug

    return (
      <div className="navLinks">
        <ul>
          <li>
            <NavLink to={urlTo('portfolioOverview', { teamSlug })}>
              <i className="icon-dasshboard" />
              <span>Overview</span>
            </NavLink>
          </li>
          {FeatureFlag.BondCover.isOn && (
            <li>
              <NavLink to={urlTo('managerDashboard', { teamSlug })}>
                <i className="icon-bondcover" />
                <span>BondCover</span>
              </NavLink>
            </li>
          )}

          {!bondCoverTab &&
            !isTeamsTabActive &&
            addresses.map((a, i) => {
              return (
                <li key={`${a.friendlyName + i}`}>
                  <NavLink
                    to={
                      a.acceptedApplicationId
                        ? urlTo('renterApplication', {
                            propertyId: a.propertyId,
                            applicationId: a.acceptedApplicationId,
                          })
                        : urlTo('propertyDashboard', {
                            propertyId: a.propertyId,
                          })
                    }
                  >
                    <div
                      className="avatar avatar-xxsmall"
                      style={{ backgroundImage: `url(${a.imageUrl}) ` }}
                    />
                    <span>{a.friendlyName}</span>
                  </NavLink>
                </li>
              )
            })}

          <li>
            <NavLink to={urlTo('propertyGetStarted', { teamSlug })}>
              <span>+ Add Property</span>
            </NavLink>
          </li>
        </ul>
      </div>
    )
  }
}

export default connect(mapStateToProps, null)(PortfolioNavbar)
