import { connect } from 'react-redux'

import { redirectBondCoverReferral } from 'app/bond_cover/bond_cover_actions'
import RefferalCodeRedirect from 'app/bond_cover/renters/history/referral_code_redirect'
import { fetchDashboard } from 'app/dashboard/dashboard_actions'

const mapStateToProps = ({ session, dashboard }) => ({
  currentUser: session.currentUser,
  bondCovers: dashboard.bondCovers,
})

const mapDispatchToProps = (dispatch) => ({
  fetchDashboard,
  redirectBondCoverReferral,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RefferalCodeRedirect)
