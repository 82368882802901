import * as React from 'react'

function SvgGoogleRounded(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2 8.17081C15.2 7.63899 15.1523 7.12763 15.0636 6.63672H8V9.53786H12.0364C11.8625 10.4754 11.3341 11.2697 10.5398 11.8015V13.6833H12.9636C14.3818 12.3776 15.2 10.4549 15.2 8.17081Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15.4998C10.025 15.4998 11.7227 14.8282 12.9636 13.6828L10.5398 11.8009C9.86818 12.2509 9.00909 12.5168 8 12.5168C6.04659 12.5168 4.39318 11.1975 3.80341 9.4248H1.29773V11.368C2.53182 13.8191 5.06818 15.4998 8 15.4998Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.80341 9.42502C3.65341 8.97502 3.56818 8.49434 3.56818 8.00002C3.56818 7.5057 3.65341 7.02502 3.80341 6.57502V4.63184H1.29773C0.789773 5.64434 0.5 6.78979 0.5 8.00002C0.5 9.21025 0.789773 10.3557 1.29773 11.3682L3.80341 9.42502Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.48295C9.10114 3.48295 10.0898 3.86136 10.867 4.60455L13.0182 2.45341C11.7193 1.24318 10.0216 0.5 8 0.5C5.06818 0.5 2.53182 2.18068 1.29773 4.63182L3.80341 6.575C4.39318 4.80227 6.04659 3.48295 8 3.48295Z"
        fill="#EA4335"
      />
    </svg>
  )
}

export default SvgGoogleRounded
