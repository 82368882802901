export const columnWidths = {
  favouriteSelect: 60,
  enquirer: 220,
  property: 175,
  messages: 220,
  notes: 270,
  actions: 150,
}

export const allMessageFilters = (filters) => {
  return [
    {
      label: 'Archived',
      field: 'archived',
      value: filters.archived,
      id: 'message-archived-checkbox',
    },
    {
      label: 'Unregistered',
      field: 'unRegistered',
      value: filters.unRegistered,
      id: 'message-unRegistered-checkbox',
    },
    {
      label: 'No notes',
      field: 'noNotes',
      value: filters.noNotes,
      id: 'message-noNotes-checkbox',
    },
    {
      label: 'Viewing available',
      field: 'viewingAvailable',
      value: filters.viewingAvailable,
      id: 'message-viewing-available-checkbox',
    },
    {
      label: 'Has mobile number',
      field: 'hasMobileNumber',
      value: filters.hasMobileNumber,
      id: 'message-has-mobile-checkbox',
    },
  ]
}

export const months = [
  { guid: 1, name: 'Jan' },
  { guid: 2, name: 'Feb' },
  { guid: 3, name: 'Mar' },
  { guid: 4, name: 'Apr' },
  { guid: 5, name: 'May' },
  { guid: 6, name: 'Jun' },
  { guid: 7, name: 'Jul' },
  { guid: 8, name: 'Aug' },
  { guid: 9, name: 'Sep' },
  { guid: 10, name: 'Oct' },
  { guid: 11, name: 'Nov' },
  { guid: 12, name: 'Dec' },
]
export const True = 'true'
export const False = 'false'

export const bedroomsDropdown = ['1', '2', '3', '4', '5', '5+']

export const selfManagingLeadSource = 'self-managing-owner'
export const rentvestorLeadSource = 'rentvestor'

export const leadSourcesConfig = {
  [selfManagingLeadSource]: {
    messageBody: 'New management opportunity received.',
  },
  [rentvestorLeadSource]: {
    messageBody: 'New Rentvestor opportunity received.',
  },
}
