import equifaxRed from 'app/assets/icons/equifax-red.png'
import ticaLogo from 'app/assets/integrationLogos/tica.svg'

export const integrationsStatuses = {
  // color is used as StatusPill component variant
  active: {
    id: 'active',
    label: 'Active',
    color: 'green',
  },
  inactive: {
    id: 'inactive',
    label: 'Inactive',
    color: 'grey',
  },
}

export const integrationBrands = {
  tica: {
    id: 'tica',
    label: 'Screening',
    manualActivationAllowed: true,
    manualDeactivationAllowed: true,
    logo: ticaLogo,
    attributeId: 'tica_enabled',
  },
  equifax: {
    id: 'equifax',
    label: 'Equifax',
    manualActivationAllowed: true,
    manualDeactivationAllowed: true,
    logo: equifaxRed,
  },
}
