import React from 'react'

import { Formik } from 'formik'
import { Link } from 'react-router-dom'

import { Box, Modal } from 'app/components/design-system-components'
import {
  formFields,
  formValidation,
} from 'app/shared_components/request_app_access/form.utils'
import FormBody from 'app/shared_components/request_app_access/request_access_form'
import { urlIds, urlTo } from 'app/sm/helpers'

function RequestAccessModal({
  toggleIsRequestAccessModalOpen,
  currentUser = {},
  submitApplicationRequestAccess,
}) {
  const initialValues = {
    [formFields.firstName]: currentUser?.firstName || '',
    [formFields.lastName]: currentUser?.lastName || '',
    [formFields.email]: currentUser?.email || '',
    [formFields.mobile]: currentUser?.mobile || '',
  }
  return (
    <Modal
      modalBody={
        <Box>
          <Box mt={5}>
            Enter your details and we'll email when your request is approved.
            Already a team member?{' '}
            <Link className="fw600 ml5" to={urlTo(urlIds.join.index)}>
              Log in
            </Link>
          </Box>
          <Box mt={7}>
            <Formik
              initialValues={initialValues}
              onSubmit={submitApplicationRequestAccess}
              validate={formValidation}
              validateOnBlur={true}
              enableReinitialize={true}
            >
              {(props) => (
                <FormBody
                  formBag={props}
                  onFormSubmit={submitApplicationRequestAccess}
                  toggleModal={toggleIsRequestAccessModalOpen}
                />
              )}
            </Formik>
          </Box>
        </Box>
      }
      modalHeading="Request access"
      toggleModal={toggleIsRequestAccessModalOpen}
      customModalFooter={true}
    />
  )
}

export default RequestAccessModal
