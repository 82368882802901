import React from 'react'

import { Link } from 'react-router-dom'

const Tenant = ({ isNewLease, currentLease, tenant, deleteTenant }) => {
  let { requestID, firstName, lastName, email, isPrimary, guidID } = tenant
  return (
    <li className="bbs pb25 mb25">
      {isPrimary ? (
        <div className="table">
          <div className="cell-t">
            <div className="avatar pink-bg fs24">
              {firstName ? firstName.charAt(0) : ''}
              {lastName ? lastName.charAt(0) : ''}
            </div>
          </div>
          <div className="cell-t-100 pl15 pr15">
            <h4>
              {firstName} {lastName}
            </h4>
            <small className="gray-light-color">{email}</small>
            {!isNewLease && (
              <div className="pt10 fs16 fw600">
                ${tenant.bondAmount.toFixed(2)} bond ({' '}
                {((tenant.bondAmount / currentLease.bond) * 100).toFixed(2)}% )
              </div>
            )}
          </div>
          <div className="cell-t">
            <div className="badge red">Primary</div>
          </div>
        </div>
      ) : (
        <div className="table">
          <Link
            className="cell-t gray-dark-color"
            to={`/bc/tenant?id=${guidID}&requestid=${requestID}`}
          >
            <div className="avatar blue-bg fs24">
              {firstName ? firstName.charAt(0) : ''}
              {lastName ? lastName.charAt(0) : ''}
            </div>
          </Link>
          <Link
            className="cell-t-100 gray-dark-color pl15 pr15"
            to={`/bc/tenant?id=${guidID}&requestid=${requestID}`}
          >
            <h4>
              {firstName} {lastName}
            </h4>
            <small className="gray-light-color">{email}</small>
            {!isNewLease && (
              <div className="pt10 fs16 fw600">
                ${tenant.bondAmount.toFixed(2)} bond ({' '}
                {((tenant.bondAmount / currentLease.bond) * 100).toFixed(2)}% )
              </div>
            )}
          </Link>
          <div className="cell-t">
            <button
              className="btn btn-transparent p0"
              onClick={() => deleteTenant(requestID, guidID)}
            >
              <i className="icon-remove"></i>
            </button>
          </div>
        </div>
      )}
    </li>
  )
}

export default Tenant
