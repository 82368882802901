import React from 'react'

import { Tooltip } from 'react-tooltip'

export const ToolsButton = ({
  tools = [],
  componentClass = '',
  className = '',
  disabled = false,
}) => {
  return (
    <div className={`dropdown actions ${componentClass} ${className}`}>
      <div
        className={`dropdown-toggle drop-down-toggler ${
          disabled && 'disabled'
        }`}
        data-toggle="dropdown"
      >
        <i className="icon-more drop-down-spots"></i>
      </div>
      <ul className="dropdown-menu team-overview-dropdown">
        {tools &&
          tools.map((tool, index) => {
            const {
              enableTool = false,
              onClick,
              text = '',
              className = '',
              tooltipId,
            } = tool || {}
            return (
              <li
                key={index}
                className={`link-styles ${
                  enableTool ? '' : 'd-none'
                } ${className}`}
                onClick={onClick}
                {...(tooltipId && {
                  'data-tooltip-content': '',
                  'data-tooltip-id': tooltipId,
                })}
              >
                {text}
              </li>
            )
          })}
      </ul>
      {tools &&
        tools.map(
          ({ tooltipText, tooltipId }) =>
            tooltipId && <Tooltip id={tooltipId}>{tooltipText}</Tooltip>,
        )}
    </div>
  )
}

export const ToolsButtonWithTextComponent = ({
  tools = [],
  buttonText = '',
  componentClass = '',
  optionsClass = '',
}) => {
  return (
    <div className={`dropdown actions ${componentClass}`}>
      <div className="dropdown-toggle dropdown-button" data-toggle="dropdown">
        {buttonText}
      </div>
      <ul className={`dropdown-menu team-overview-dropdown ${optionsClass}`}>
        {tools &&
          tools.map((tool, index) => {
            const { enableTool = false, onClick, text = '' } = tool || {}
            return (
              <li
                key={index}
                className={`link-styles ${enableTool ? '' : 'd-none'}`}
                onClick={onClick}
              >
                {text}
              </li>
            )
          })}
      </ul>
    </div>
  )
}
export default ToolsButton
