import React from 'react'

import styled from 'styled-components'

const AsideWrapper = styled.div`
  width: 100%;
  @media (min-width: 992px) {
    max-width: 368px;
    flex-shrink: 0;
  }
`

export function Aside(props) {
  return <AsideWrapper>{props.children}</AsideWrapper>
}

const ContentWrapper = styled.div`
  @media (min-width: 992px) {
    flex-grow: 1;
    padding: 0 40px;
  }
`

export function Content(props) {
  return <ContentWrapper>{props.children}</ContentWrapper>
}
