import React, { useState } from 'react'

import 'app/components/display/application_note_add/style.scss'
import applicationDialogue from 'app/assets/icons/application_icon_dialogue.svg'
import applicationApprove from 'app/assets/icons/application_note_approve.svg'
import applicationDecline from 'app/assets/icons/application_note_decline.svg'
import * as Form from 'app/components/forms/forms'
import {
  capitalizeFirstLetter,
  noteActionType,
  targetSectionObject,
} from 'app/sm/helpers'

const AddNoteComponent = ({
  onNewNoteUpdated,
  newNotesContent = {},
  section = '',
  onNewNoteAdded,
  onNoteCrossClicked,
  name = 'this applicant',
  targetGUID = null,
}) => {
  const [noteContent, setNoteContent] = useState({ value: '', error: '' })
  const [shareWithOwner, setShareWithOwner] = useState(false)

  const {
    value,
    type,
    isNoteSaving = false,
    frontEndError = '',
  } = newNotesContent
  const { approve, decline, comment } = noteActionType
  let showNote = section === newNotesContent.target
  // to avoid opening all rental and employment histories header notes at once
  if (newNotesContent.targetGUID && newNotesContent.targetGUID !== targetGUID) {
    showNote = false
  }
  const sectionText = targetSectionObject[section].text
  let actionText = 'Action on'
  let actionImgSrc = applicationDialogue
  if (type === approve) {
    actionText = 'Approve'
    actionImgSrc = applicationApprove
  } else if (type === decline) {
    actionText = 'Decline'
    actionImgSrc = applicationDecline
  } else if (type === comment) {
    actionText = 'Comment on'
  }
  const updateComment = (e) => {
    onNewNoteUpdated({
      section,
      shareWithOwner,
      targetGUID,
    })(e)

    setNoteContent(e)
  }
  const updateShareWithOwnerFlag = (value) => {
    onNewNoteUpdated({
      section,
      shareWithOwner: value,
      targetGUID,
    })(noteContent)

    setShareWithOwner(value)
  }

  const sharableSectionsNotes = [
    'addressHistory',
    'employmentDetails',
    'income',
  ]

  const closeAddNoteComponent = () => {
    updateComment({ value: '', error: '' })
    updateShareWithOwnerFlag(false)
    onNoteCrossClicked()
  }

  const renderSharableNoteCheckBox = () => {
    if (!sharableSectionsNotes.includes(section)) return

    return (
      <Form.CheckBoxGeneral
        label="Share With Owner?"
        id="shareWithOwner"
        onChange={(e) => updateShareWithOwnerFlag(e.value)}
        checked={shareWithOwner}
        checkboxLabelSpanClassName="fs16 height-auto"
      />
    )
  }

  const sharableNoteCheckbox = renderSharableNoteCheckBox()

  return (
    <div
      className={`application-note-add-container ${
        section ? 'specified-note-adder' : ''
      } ${showNote ? '' : 'd-none'} ${isNoteSaving ? 'disabled-link' : ''}`}
    >
      {isNoteSaving && (
        <div className="note-shade">
          <i className="center-position fa fa-spinner fa-pulse" />
        </div>
      )}
      <div className="display-flex justify-content-space-between">
        <img src={actionImgSrc} alt="application approval" className="mr10" />
        <div className="display-flex">
          {actionText}&nbsp;
          <strong>
            {capitalizeFirstLetter(name)}&apos;s {sectionText}
          </strong>
          <div
            onClick={closeAddNoteComponent}
            className="ml10"
            role="button"
            tabIndex={0}
          >
            <i className="fa fa-times" />
          </div>
        </div>
      </div>
      <Form.CommentArea
        value={value}
        error={frontEndError}
        placeholder="Your comment"
        rows={2}
        onChange={updateComment}
        id="comment"
        inputBoxClass="mb0"
      />
      <div className="bottom-section display-flex justify-content-space-between mt20">
        {sharableNoteCheckbox}
        <div />
        <div className="button-section">
          <button className="btn btn-success" onClick={() => onNewNoteAdded()}>
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
export default AddNoteComponent
