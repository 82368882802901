import React from 'react'

import qs from 'qs'

import bathIcon from 'app/assets/icons/summary_icons/bath.svg'
import bedIcon from 'app/assets/icons/summary_icons/bed.svg'
import calendarIcon from 'app/assets/icons/summary_icons/calendar-information.png'
import carIcon from 'app/assets/icons/summary_icons/car.svg'
import rentIcon from 'app/assets/icons/summary_icons/rent.svg'
import { PageSubHeading } from 'app/components/design-system-components/page/SubHeading'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { history } from 'app/shared_components/router'
import { formatRent, urlTo } from 'app/sm/helpers'
import * as helpers from 'app/sm/helpers'
import { SessionStorageUtils } from 'app/storage_utils'
import { getPropertyAvailabilityDate } from 'app/utils/datetime/helpers'

import 'app/match/apply/application_summary_before_submit/shared_components/headers/style.scss'

const SAVED_APPLY_ANYWHERE_LISTING = 'savedApplyAnywhereListing'

export const ApplicationSummaryHeader = ({
  property = {},
  propertyOffer = {},
  applicationType = 0,
  teamSlug = '',
}) => {
  const {
    address = {},
    bedrooms = 0,
    bathrooms = 0,
    carports = 0,
    parking = 0,
    garages = 0,
  } = property
  const carCountSpaces = { garages, parking, carports }

  const { friendlyName = '', suburb = '', postcode = '' } = address
  const { weeklyRentDisplay = '', availableFrom = null } = propertyOffer
  const formattedRent = formatRent(weeklyRentDisplay)
  const isNotApplyAnywhereApplication = applicationType !== 2
  const availableFromText = getPropertyAvailabilityDate(
    availableFrom,
    helpers.standardDateWithoutTimeAndComma,
  )

  return (
    <div className="summary-details-list">
      <PageSubHeading
        fontWeight={theme.fontWeights[6]}
        title={`${friendlyName}, ${suburb}, ${postcode}`}
      />

      <div className="display-flex">
        {isNotApplyAnywhereApplication && (
          <div className="width-100">
            <ul>
              <li>
                <img
                  className="summary-icon icon-space"
                  src={bedIcon}
                  alt="Bed Icon"
                />
                <span className="application-summary-number">{bedrooms}</span>
              </li>
              <li>
                <img
                  className="summary-icon icon-space"
                  src={bathIcon}
                  alt="Bath Icon"
                />
                <span className="application-summary-number">{bathrooms}</span>
              </li>
              <li>
                <img
                  className="summary-icon icon-space"
                  src={carIcon}
                  alt="Car Icon"
                />
                <span className="application-summary-number">
                  {helpers.getCarSpaceCountForProperty(carCountSpaces)}
                </span>
              </li>
              <li>
                <img
                  className="summary-icon icon-space"
                  src={rentIcon}
                  alt="Rent Icon"
                />
                <span className="application-summary-number">
                  {formattedRent}
                </span>
              </li>
              <li>
                <img
                  className="summary-icon icon-space"
                  src={calendarIcon}
                  alt="Rent Icon"
                />
                <span className="application-summary-number">
                  {availableFromText}
                </span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export const ApplyAnywhereApplicationHeader = ({
  application,
  isApplyAnywhereHeadEditable = true,
  property = {},
}) => {
  const listing = application && application.applyAnywhereListing
  const { emailAddress = '', firstName = '', guid = '' } = listing
  const managerDetails = `${firstName} (${emailAddress})`
  const value = SessionStorageUtils.getItem(SAVED_APPLY_ANYWHERE_LISTING)
  let url = urlTo('applyAnywhere', { applyAnywhereGUID: guid })
  const curQueries = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  const { address = {} } = property
  const { friendlyName = '', suburb = '' } = address
  const addressFromRequest = `${friendlyName} ${suburb}`
  let queryParams = {
    ...(application &&
      application.guidID && { applicationId: application.guidID }),
  }
  try {
    const savedApplyAnywhereListing = JSON.parse(value)
    if (savedApplyAnywhereListing && savedApplyAnywhereListing.id) {
      queryParams = { ...queryParams, id: savedApplyAnywhereListing.id }
    }
  } catch (err) {
    console.log('caught err', err)
  }
  const queryParamsParts = Object.entries(queryParams).reduce(
    (parts, [queryKey, queryVal]) => {
      return [...parts, `${queryKey}=${queryVal}`]
    },
    [],
  )

  url = url + '?' + queryParamsParts.join('&')

  return (
    <div>
      <p className="application-summary-address m0">
        {curQueries.address || addressFromRequest}
      </p>
      <div className="pt20 mb30 application-submitted-email">
        <span className="text-grey">
          Your application will be submitted to{' '}
          <span className="text-blue">{managerDetails}</span>
        </span>
        {isApplyAnywhereHeadEditable && (
          <span
            className="mr20 linkStyle"
            onClick={() => {
              history.push(url)
            }}
            role="button"
            tabIndex="0"
          >
            Edit
          </span>
        )}
      </div>
    </div>
  )
}
