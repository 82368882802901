import type { FC } from 'react'

import BallotSurvey from 'app/components/Ballot/Survey'
import { Modal } from 'app/components/design-system-components'

interface IBallotSurveyModalProps {
  ballotSurveyFormRef: any
  toggleBallotSurveyModal: () => void
  submitBallotSurveyModal: () => void
  applyButtonClicked: () => void
}

const BallotSurveyModal: FC<IBallotSurveyModalProps> = ({
  ballotSurveyFormRef,
  toggleBallotSurveyModal,
  submitBallotSurveyModal,
  applyButtonClicked,
}) => {
  return (
    <Modal
      toggleModal={() => toggleBallotSurveyModal()}
      modalHeading={'Survey'}
      modalBody={<BallotSurvey formRef={ballotSurveyFormRef} />}
      primaryLabel={'Submit & Continue'}
      primaryAction={() => submitBallotSurveyModal()}
      secondaryLabel={'Cancel'}
      secondaryAction={() => toggleBallotSurveyModal()}
      primaryButtonLoading={!!applyButtonClicked}
      modalFooter={undefined}
    />
  )
}

export default BallotSurveyModal
