import React from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

const SwitchToNewPageContainer = styled.div`
  ${(props) => props.backgroundColor && `background-color: #d6e6fb;`};
  ${(props) => props.showPadding && `padding: 16px 24px;`};
  ${(props) => !props.showPadding && `padding: 8px 0;`};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  flex-wrap: wrap;
  a:not(:first-child) {
    padding-left: 24px;
  }
  @media (max-width: 576px) {
    a {
      display: block;
      &:not(:first-child) {
        padding-left: 0;
      }
    }
  }
`

const SwitchToNewPage = ({
  message,
  primarySwitchLinkPath,
  primarySwitchLinkText,
  secondarySwitchLinkPath,
  secondarySwitchLinkText,
  secondarySwitchLinkIsMailTo,
  backgroundColor = true,
  showPadding = true,
}) => {
  const showSwitchLink = primarySwitchLinkPath && primarySwitchLinkText
  const showOtherSwitchLink = secondarySwitchLinkPath && secondarySwitchLinkText
  return (
    <SwitchToNewPageContainer
      backgroundColor={backgroundColor}
      showPadding={showPadding}
    >
      <div>{message}</div>
      <div>
        {showOtherSwitchLink &&
          (secondarySwitchLinkIsMailTo ? (
            <Link to={secondarySwitchLinkPath} target="_blank">
              {secondarySwitchLinkText}
            </Link>
          ) : (
            <Link to={secondarySwitchLinkPath}>{secondarySwitchLinkText}</Link>
          ))}
        {showSwitchLink && (
          <Link to={primarySwitchLinkPath}>{primarySwitchLinkText}</Link>
        )}
      </div>
    </SwitchToNewPageContainer>
  )
}

export default SwitchToNewPage
