import { connect } from 'react-redux'

import { fetchTeamManagerList } from 'app/bond_cover/agency/agency_actions'
import {
  fetchViewingRunDetail,
  fetchViewingRuns,
  generateResetViewingRun,
  generateViewingRun,
} from 'app/sm/viewings_new_run/action'
import RunDashboard from 'app/sm/viewings_new_run/dashboard/container'

const mapStateToProps = ({ session }) => ({
  teams: session.teams || [],
})

const mapDispatchToProps = (dispatch) => ({
  fetchTeamManagerList: (agencyGUID) =>
    dispatch(fetchTeamManagerList(agencyGUID)),
  fetchViewingRuns: (agencyGUID, managerGUID, startTime, endTime, offset) =>
    dispatch(
      fetchViewingRuns(agencyGUID, managerGUID, startTime, endTime, offset),
    ),
  fetchViewingRunDetail: (viewingRunGUID) =>
    dispatch(fetchViewingRunDetail(viewingRunGUID)),
  generateViewingRun: (viewingRunRequest) =>
    dispatch(generateViewingRun(viewingRunRequest)),
  generateResetViewingRun: () => dispatch(generateResetViewingRun()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RunDashboard)
