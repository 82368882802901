import { connect } from 'react-redux'

import CallBackContainer from 'app/landing/direct_connect/direct_connect_callback'
import {
  getCallBackInformation,
  sendCallBackInformation,
} from 'app/landing/direct_connect/direct_connect_callback_actions'

const mapStateToProps = null

const mapDispatchToProps = (dispatch) => ({
  getCallBackInformation: (applicationSlug) =>
    dispatch(getCallBackInformation(applicationSlug)),
  sendCallBackInformation: (applicationId, callBackDateTime) =>
    dispatch(sendCallBackInformation(applicationId, callBackDateTime)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CallBackContainer)
