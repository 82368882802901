import React from 'react'

import partnersLogosImg from 'app/assets/icons/apply_anywhere/partners-logos-colour.png'
import semicircleImg from 'app/assets/icons/apply_anywhere/semicircle.png'
import humanImg01 from 'app/assets/icons/apply_anywhere/verified-human01.png'
import humanImg02 from 'app/assets/icons/apply_anywhere/verified-human02.png'
import humanImg03 from 'app/assets/icons/apply_anywhere/verified-human03.png'
import humanImg04 from 'app/assets/icons/apply_anywhere/verified-human04.png'
import humanImg05 from 'app/assets/icons/apply_anywhere/verified-human05.png'
import checkmarkImg from 'app/assets/icons/checkmark.svg'
import copyImg from 'app/assets/icons/copy.svg'
import { Box } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import {
  CopyWrapper,
  CustomLink,
  ExtraInfo,
  Heading,
  HumanDecoBox,
  LeadCaptureFormWrapper,
  LinkList,
  Padding,
  SemiHeading,
  Text,
  TextWithLine,
} from 'app/match/applicationReportPDF/components/LeadCaptureForm/styles'
import { Iframe } from 'app/match/leadToSnug/styles'
import SnugLogo from 'app/shared_components/snug_logo'
import { wait } from 'app/sm/helpers'
import * as textHelpers from 'app/utils/text/helpers'

class LeadCaptureForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCopied: false,
      isInModal: false,
    }
  }

  copyLinkToClipboard = (event) => {
    const target = event.target
    try {
      target.select()
      document.execCommand('copy')
      this.setState({ isCopied: true })
    } catch (err) {
      // TODO: Deal with crash ( but this is a little bit overly defensive, we probably would never enter here)
    } finally {
      wait(3000).then(() => this.setState({ isCopied: false })) // Just to be in sync with the animation
    }
  }

  render() {
    const { isInModal, isFormModalOpen, toggleFormModal } = this.props
    const { isCopied } = this.state
    const snugLink = `https://snug.com/manager/`

    return (
      <LeadCaptureFormWrapper isInModal={isInModal} bgImage={semicircleImg}>
        <Padding padding="0 17px">
          <HumanDecoBox>
            <img className="human-center" src={humanImg02} alt="human" />
            <div className="human-img-box">
              <img className="human-img img01" src={humanImg01} alt="human" />
              <img className="human-img img03" src={humanImg03} alt="human" />
              <img className="human-img img04" src={humanImg04} alt="human" />
              <img className="human-img img05" src={humanImg05} alt="human" />
            </div>
          </HumanDecoBox>
          <Heading>Want more verified tenant applications?</Heading>
          <Text margin="14px 0 22px" fontSize="18" lineHeight="25">
            Get more verified applications &#38; improve your company’s ROI
          </Text>
          <ul>
            {LinkToSnugList.map((ele, index) => {
              return <LinkToSnug key={index} link={ele.link} text={ele.label} />
            })}
          </ul>
        </Padding>
        <TextWithLine margin="30px 0 0">
          <span>
            Join over {textHelpers.MarketingPMCount} property managers
          </span>
        </TextWithLine>
        <Padding padding="0 55px 14px 55px">
          <img
            className="width-100"
            src={partnersLogosImg}
            alt="Snug's partners logo"
          />
        </Padding>
        <TextWithLine />
        <Padding padding="19px 28px 14px 28px">
          <SemiHeading>Share the value of Snug</SemiHeading>
          <Text margin="10px 0 8px">
            Impress your boss by sharing them a helpful link
          </Text>
          <CustomLink margin="0 0 13px" href={snugLink} target="_blank">
            View below link
          </CustomLink>
          <CopyWrapper>
            <div className="copy-img-wrapper">
              <img src={copyImg} alt="copy" />
              {isCopied ? 'Copied' : 'Copy'}
            </div>
            <input
              type="text"
              value={snugLink}
              onClick={(event) => this.copyLinkToClipboard(event)}
              readOnly
            />
          </CopyWrapper>
        </Padding>
        <TextWithLine>
          <span>Or simply</span>
        </TextWithLine>
        <Padding padding="16px 28px 34px 28px">
          <button onClick={toggleFormModal} type="button">
            Start your free trial
          </button>
        </Padding>
        <Box p="0 28px 16px 28px">{textHelpers.MarketingPMCountCaveatText}</Box>
        {isFormModalOpen && (
          <Display.Modal
            toggleModal={toggleFormModal}
            hideButtonSection
            modalSubheaderClass="display-none"
            modalBodyClass="mt0 pl15 pr15 leadform-modal-body"
          >
            <SnugLogo height={20} />
            <Heading margin="18px 0 15px">
              Learn how Snug helps you lease faster
            </Heading>
            <Text>
              <img className="black-tick" src={checkmarkImg} alt="black-tick" />
              {textHelpers.MarketingPMCountPlus} property managers trust Snug.
            </Text>
            <Text>
              <img className="black-tick" src={checkmarkImg} alt="black-tick" />
              Find &#38; select top tenant, fast.
            </Text>
            <Iframe
              src="https://webforms.pipedrive.com/f/1CaUyyS9I6t1l4Lr57B0rlMf1ezciRZUlLrFrAc7C5JWy6qWQJGZIO8M5UNLdWSOv"
              width="100%"
              minHeight="656px"
            />
            <div
              className="pipedriveWebForms"
              data-pd-webforms="https://webforms.pipedrive.com/f/1CaUyyS9I6t1l4Lr57B0rlMf1ezciRZUlLrFrAc7C5JWy6qWQJGZIO8M5UNLdWSOv"
            >
              <script src="https://webforms.pipedrive.com/f/loader" />
            </div>
            {/* </Form> */}
            <ExtraInfo>
              Don’t want a free Snug account?
              <a
                href="https://snug.com/manager/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get to know about Snug
              </a>
            </ExtraInfo>
            <Box fontSize={4}>{textHelpers.MarketingPMCountCaveatText}</Box>
          </Display.Modal>
        )}
      </LeadCaptureFormWrapper>
    )
  }
}

export default LeadCaptureForm

const LinkToSnug = ({ link, text }) => {
  return (
    <LinkList>
      <img className="tick" src={checkmarkImg} alt="tick" />
      <a href={link} target="_blank" rel="noopener noreferrer">
        <span>{text}</span>
      </a>
    </LinkList>
  )
}

const LinkToSnugList = [
  {
    link: 'https://snug.com/manager/features/#enquiry-auto-responder',
    label: 'Manage Enquiries',
  },
  {
    link: 'https://snug.com/manager/features/#application-processing',
    label: 'Lease offer',
  },
  {
    link: 'https://snug.com/manager/features/#applications',
    label: 'Applications',
  },
  {
    link: 'https://snug.com/manager/features/#tenant-screening',
    label: 'Screen tenants',
  },
  {
    link: 'https://snug.com/manager/features/#inspection-bookings',
    label: 'Manage viewings',
  },
  {
    link: 'https://snug.com/manager/features/#integration-and-reports',
    label: 'Utility intergration',
  },
]
