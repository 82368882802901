import React from 'react'

import qs from 'qs'
import styled from 'styled-components'

import smartAnswers from 'app/assets/icons/smart-answers.svg'
import { Box } from 'app/components/design-system-components'
import { MailToLink } from 'app/components/design-system-components/links/MailTo'

const StyledGreyContainer = styled(Box)`
  background-color: ${({ theme }) => theme.colors.grey1};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: ${(props) => props.theme.radii[6]}px;
  padding: 40px;
  img {
    width: 60px;
    height: 60px;
  }
`

const SelfServiceRegistrationSuccess = () => {
  const curQueries = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })

  return (
    <Box textAlign="center" my="40px">
      <Box fontSize="36px" fontWeight="700" mb="20px">
        Thank you!
      </Box>
      <Box fontSize="18px" mb="30px">
        The rental reference has been submitted.
      </Box>
      <StyledGreyContainer>
        <Box mb="32px">
          <img src={smartAnswers} alt="pink house" />
        </Box>
        <Box fontSize="18px" fontWeight="500" mb="16px">
          <p>
            Your Rental Reference Inbox request for the {curQueries.agencyName}{' '}
            team has been sent.
          </p>
          <p>
            Please contact the team admin (
            <MailToLink email={curQueries.email}>{curQueries.email}</MailToLink>
            ) if you do not receive an invite.
          </p>
        </Box>
      </StyledGreyContainer>
    </Box>
  )
}

export default SelfServiceRegistrationSuccess
