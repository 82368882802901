import React from 'react'

import { Alert } from 'app/components/design-system-components'

function UserNotAuthorized() {
  return (
    <Alert variant="blueWithBg">
      You do not have permission, please contact your team administrator.
    </Alert>
  )
}

export default UserNotAuthorized
