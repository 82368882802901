import React from 'react'

import moment from 'moment'

import 'app/landing/direct_connect/style.scss'
import dcLogo from 'app/assets/icons/logo-directconnect.png'
import DefaultButton from 'app/components/buttons/default/component'
import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import { ErrorMessage } from 'app/shared_components/helpers'
import CenterContentContainer from 'app/shared_components/layout_component/center_content_component/component'

const openingHoursInUTC = {
  weekday: {
    start: {
      hour: '22',
      negativeDayOffset: 1,
    },
    end: {
      hour: '09',
      negativeDayOffset: 0,
    },
  },
  saturday: {
    start: {
      hour: '23',
      negativeDayOffset: 1,
    },
    end: {
      hour: '06',
      negativeDayOffset: 0,
    },
  },
}

const currentTime = () => moment()

const dateIsSaturday = (date) => {
  // expects moment object
  return date.isoWeekday() === 6
}

const convertUTCOpeningHoursToLocal = (openingHoursUTC) => {
  const datePlaceHolderLocalTime = currentTime()
  const { hour, negativeDayOffset } = openingHoursUTC
  const dateOffsetToUTC = moment(datePlaceHolderLocalTime)
    .subtract(negativeDayOffset, 'day')
    .format('YYYY-MM-DD')

  const timeStringInUTC = `${dateOffsetToUTC}T${hour}:00:00Z`
  return moment(timeStringInUTC).local()
}

const availableTimeSlots = (startTime, endTime) => {
  const totalMinutesDifference = Math.abs(
    moment(startTime).diff(endTime, 'minutes'),
  )
  return Math.floor(totalMinutesDifference / 30)
}

const plusMinutes = (time, delayMinutes) => {
  const timeCopy = moment(time)
  return timeCopy.add(delayMinutes, 'minutes')
}

const clearTimeToLastHour = (time) => {
  const timeCopy = moment(time)
  return timeCopy.set({
    minute: 0,
    seconds: 0,
    milliseconds: 0,
  })
}

const prepTimeSlots = (openingHoursUTC = {}, isToday) => {
  const timeSlotsValues = []
  const timeSlotsDisplays = []
  const { start, end } = openingHoursUTC
  const now = currentTime()
  let startTime = convertUTCOpeningHoursToLocal(start)
  const endTime = convertUTCOpeningHoursToLocal(end)

  if (isToday) {
    startTime = plusMinutes(clearTimeToLastHour(now), 60)
  }
  const timeSlotsAvailable = availableTimeSlots(startTime, endTime)
  let lastTimeSlot = startTime

  for (let i = 0; i < timeSlotsAvailable; i += 1) {
    const currentTimeSlot = moment(lastTimeSlot).add(30, 'minutes')
    timeSlotsValues.push({
      startTime: currentTimeSlot.format('YYYY-MM-DD HH:mm'),
      endTime: moment(currentTimeSlot)
        .add(30, 'minutes')
        .format('YYYY-MM-DD HH:mm'),
    })
    timeSlotsDisplays.push(
      `${currentTimeSlot.format('h:mm')} - ${moment(currentTimeSlot)
        .add(30, 'minutes')
        .format('h:mm A')}`,
    )
    lastTimeSlot = currentTimeSlot
  }
  return { timeSlotsValues, timeSlotsDisplays }
}

const timeSlotsGenerator = (date, isToday) => {
  if (dateIsSaturday(date)) {
    return prepTimeSlots(openingHoursInUTC.saturday, isToday)
  }

  return prepTimeSlots(openingHoursInUTC.weekday, isToday)
}

const isDateSunday = (date) => {
  return moment(date).isoWeekday() === 7
}

const datesSlotsGenerator = (firstDisplayDate) => {
  const datesAvailableDisplays = []
  const datesAvailableValues = []
  let currentDay = firstDisplayDate

  for (let i = 0; i < 14; i += 1) {
    if (isDateSunday(currentDay)) {
      i -= 1
    } else {
      datesAvailableValues.push(currentDay)
      datesAvailableDisplays.push(moment(currentDay).format('dddd, MMMM Do'))
    }
    currentDay = moment(currentDay).add(1, 'days')
  }
  return { datesAvailableValues, datesAvailableDisplays }
}

const firstDisplayDateFrom = (localTimeNow) => {
  const localTimeNowCopy = moment(localTimeNow)
  const saturdayEndTimeInLocal = convertUTCOpeningHoursToLocal(
    openingHoursInUTC.saturday.end,
  )
  if (
    dateIsSaturday(localTimeNowCopy) &&
    moment(localTimeNowCopy).isAfter(moment.utc(saturdayEndTimeInLocal))
  ) {
    return localTimeNowCopy.add(1, 'days').startOf('day')
  }

  const weekdayUTCEndTime = convertUTCOpeningHoursToLocal(
    openingHoursInUTC.weekday.end,
  )
  if (moment(localTimeNowCopy).isAfter(moment.utc(weekdayUTCEndTime))) {
    return localTimeNowCopy.add(1, 'days').startOf('day')
  }

  return localTimeNowCopy.startOf('day')
}

const getDate = (time) => moment(time).format('YYYY-MM-DD')

const composePayLoadTime = (
  chosenDateDisplayIndex,
  datesValues,
  chosenTimeDisplayIndex,
  timeSlotsValues,
  type,
) => {
  const chosenStartTimeHour = moment(
    timeSlotsValues[chosenTimeDisplayIndex][type],
  ).get('hour')
  const chosenStartTimeMinute = moment(
    timeSlotsValues[chosenTimeDisplayIndex][type],
  ).get('minute')

  const composedDateTime = datesValues[chosenDateDisplayIndex].set({
    hour: chosenStartTimeHour,
    minute: chosenStartTimeMinute,
    second: 0,
    millisecond: 0,
  })
  return composedDateTime
}

class CallBackContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeSlotsValues: [],
      timeSlotsDisplays: [],
      datesValues: [],
      datesDisplays: [],
      callBackInfoDetails: {},
      chosenDateDisplayIndex: 0,
      chosenTimeDisplayIndex: 0,
      startTime: '',
      endTime: '',
      apiError: '',
      errors: {
        chosenTimeDisplayIndex: '',
        chosenDateDisplayIndex: '',
      },
      callBackRequestClicked: false,
    }
  }

  componentDidMount() {
    const { getCallBackInformation } = this.props
    const { applicationSlug = '' } = this.props.match.params
    const localTimeNow = currentTime()
    const firstDisplayDate = firstDisplayDateFrom(localTimeNow)
    const isToday = getDate(firstDisplayDate) === getDate(localTimeNow)
    const { datesAvailableValues, datesAvailableDisplays } =
      datesSlotsGenerator(firstDisplayDate)

    // Prep the time list based on the first day
    const { timeSlotsValues, timeSlotsDisplays } = timeSlotsGenerator(
      firstDisplayDate,
      isToday,
    )

    getCallBackInformation(applicationSlug)
      .then((dcCallBackInfo) => {
        this.setState({
          callBackInfoDetails: dcCallBackInfo,
          apiError: '',
        })
      })
      .catch((error) =>
        this.setState({
          apiError: error,
        }),
      )
    this.setState({
      timeSlotsValues,
      timeSlotsDisplays,
      datesValues: datesAvailableValues,
      datesDisplays: datesAvailableDisplays,
    })
  }

  onRequestCallBackClicked = () => {
    const { sendCallBackInformation } = this.props
    const {
      chosenDateDisplayIndex,
      chosenTimeDisplayIndex,
      callBackInfoDetails,
      timeSlotsValues,
      datesValues,
    } = this.state

    const payLoadStartTime = composePayLoadTime(
      chosenDateDisplayIndex,
      datesValues,
      chosenTimeDisplayIndex,
      timeSlotsValues,
      'startTime',
    )
    const formattedStartTime = moment(payLoadStartTime).format(
      'YYYY-MM-DDTHH:mm:ssZ',
    )

    const payLoadEndTime = composePayLoadTime(
      chosenDateDisplayIndex,
      datesValues,
      chosenTimeDisplayIndex,
      timeSlotsValues,
      'endTime',
    )
    const formattedEndTime = moment(payLoadEndTime).format(
      'YYYY-MM-DDTHH:mm:ssZ',
    )

    const data = {
      StartTime: formattedStartTime,
      EndTime: formattedEndTime,
    }
    sendCallBackInformation(callBackInfoDetails.applicationGUID, data)
      .then(this.setState({ callBackRequestClicked: true }))
      .catch((error) => {
        this.setState({ apiError: error })
      })
  }

  updateDate = (optionsValue) => {
    return (data) => {
      const localTimeNow = currentTime()
      const selectedLocalDate = optionsValue[data.value]
      const isToday = getDate(selectedLocalDate) === getDate(localTimeNow)
      const { timeSlotsValues, timeSlotsDisplays } = timeSlotsGenerator(
        selectedLocalDate,
        isToday,
      )
      this.setState({
        ...this.state,
        chosenDateDisplayIndex: data.value,
        timeSlotsValues,
        timeSlotsDisplays,
        chosenTimeDisplayIndex: 0, // reset the time list to starting one
        errors: {
          ...this.state.errors,
          chosenDateDisplayIndex: data.error,
        },
      })
    }
  }

  updateTime = () => {
    return (data) => {
      this.setState({
        chosenTimeDisplayIndex: data.value,
        errors: {
          ...this.state.errors,
          chosenTimeDisplayIndex: data.error,
        },
      })
    }
  }

  render() {
    const {
      datesValues,
      datesDisplays,
      chosenDateDisplayIndex,
      timeSlotsDisplays,
      chosenTimeDisplayIndex,
      callBackRequestClicked,
      callBackInfoDetails,
      apiError,
    } = this.state
    const { friendlyAddress = '' } = callBackInfoDetails

    return (
      <div>
        <CenterContentContainer>
          <div
            className={`width100 pt50 pb50 ${
              callBackRequestClicked ? 'confetti-bg' : ''
            }`}
          >
            <img
              src={dcLogo}
              alt="Direct connect logo"
              className="direct-connect-logo"
            />
          </div>
          {!apiError && (
            <Display.GreyContainer containerClass="display-flex flex-direction-column login-container p0">
              <Display.SectionHeader
                text={
                  callBackRequestClicked ? `We'll be in touch!` : `Book a call`
                }
                textClass="text-align-center"
                hasSeperator={false}
              />
              <Display.DefaultBodyText
                componentClass="p24 pt0"
                text={
                  callBackRequestClicked
                    ? `Our friendly moving experts at Direct Connect will be in touch between ${timeSlotsDisplays[chosenTimeDisplayIndex]} on ${datesDisplays[chosenDateDisplayIndex]} to help make your move a breeze. Our customer service team is based in Melbourne so keep an eye out for our (03) number.
                  Your Direct Connect reference number will also be sent to you via email.
                  `
                    : `Let Snug and Direct Connect make your move to ${friendlyAddress} as easy as possible. Book your time for a moving expert to discuss connecting your electricity, gas and more.`
                }
              />
              {!callBackRequestClicked && (
                <div className="ml-auto mra mb30">
                  <Form.Dropdown
                    label={datesDisplays[0]}
                    value={chosenDateDisplayIndex}
                    error={this.state.errors.chosenDate}
                    options={datesDisplays}
                    onChange={this.updateDate(datesValues)}
                    id="chosenDate"
                    inputClass="mra mla dropdown-width"
                  />
                  <Form.Dropdown
                    label={timeSlotsDisplays[0]}
                    value={chosenTimeDisplayIndex}
                    error={this.state.errors.chosenTimeDisplayIndex}
                    options={timeSlotsDisplays}
                    onChange={this.updateTime()}
                    id="chosenTimeDisplayIndex"
                    inputClass="mra mla dropdown-width"
                    componentClass="mt20"
                  />
                  <DefaultButton
                    text="Book Callback"
                    size="large"
                    componentClass="mt30"
                    onClick={() => this.onRequestCallBackClicked()}
                    disabled={!!apiError}
                  />
                </div>
              )}
            </Display.GreyContainer>
          )}

          {!callBackRequestClicked && (
            <div>
              <Display.GreyBodyText>
                <p>
                  Want to get started now? Call
                  <a href="tel: 1300 664 715"> 1300 664 715</a>
                </p>
              </Display.GreyBodyText>
            </div>
          )}
          <ErrorMessage error={apiError} />
        </CenterContentContainer>
      </div>
    )
  }
}
export default CallBackContainer
