import React from 'react'

import { Link } from 'react-router-dom'

import humanIcon01 from 'app/assets/icons/human01.jpg'
import * as snugNotifier from 'app/services/snugNotifier'
import {
  hasActiveOfferGuid,
  NO_IMAGE_PLACEHOLDER_URL,
  Profile,
  urlTo,
} from 'app/sm/helpers'

const ownerPreferencesToastifyText = (offerId) => (
  <div>
    We have updated the{' '}
    <Link to={urlTo('profilePreferences', { offerId })}>owner preferences</Link>
  </div>
)

class Properties extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      conditionPreferenceWeighting: 0,
      occupancyPreferenceWeighting: 0,
      yieldPreferenceWeighting: 0,
      offerId: '',
      allowPets: false,
      takeHoldingDeposit: false,
      showingToast: false,
      spinnerName: '',
    }
  }

  componentDidMount() {
    const { fetchProfile, property = {} } = this.props
    const activeOfferGuid = hasActiveOfferGuid(property)
    activeOfferGuid && fetchProfile(activeOfferGuid)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { profile } = nextProps
    const updateState =
      profile.conditionPreferenceWeighting !==
        this.state.conditionPreferenceWeighting ||
      profile.occupancyPreferenceWeighting !==
        this.state.occupancyPreferenceWeighting ||
      profile.yieldPreferenceWeighting !== this.state.yieldPreferenceWeighting

    if (updateState && !this.initialSet) {
      this.initialSet = true
      this.setState({
        conditionPreferenceWeighting: profile.conditionPreferenceWeighting,
        occupancyPreferenceWeighting: profile.occupancyPreferenceWeighting,
        yieldPreferenceWeighting: profile.yieldPreferenceWeighting,
        allowPets: profile.allowPets,
        takeHoldingDeposit: profile.takeHoldingDeposit,
      })
    }
  }

  reactToastify = () => {
    this.setState({ spinnerName: '' })
    const { property, propertyOffer } = this.props
    const activeOfferGuid = hasActiveOfferGuid(property)
    const offerId = (propertyOffer || {}).guidID || activeOfferGuid

    this.state.showingToast === false &&
      snugNotifier.success(ownerPreferencesToastifyText(offerId), {
        onOpen: () => this.setState({ showingToast: true }),
        onClose: () => this.setState({ showingToast: false }),
      })
  }

  update = (field, value) => {
    this.setState({ spinnerName: field })
    const {
      updatePreferences,
      property = {},
      propertyOffer,
      applicationId,
      applicantId,
    } = this.props
    const activeOfferGuid = hasActiveOfferGuid(property)
    const offerId = (propertyOffer || {}).guidID || activeOfferGuid

    const mostImportantKeys = Profile.mostImportant.map((o) => o.id)
    mostImportantKeys.forEach((f) =>
      f === field ? this.setState({ [f]: value }) : this.setState({ [f]: 0 }),
    )

    this.setState(
      { offerId },
      () =>
        property.guidID &&
        updatePreferences(
          property.guidID,
          this.state,
          applicationId,
          applicantId,
        ).then(this.reactToastify),
    )
  }

  render() {
    let propertyImage = NO_IMAGE_PLACEHOLDER_URL

    const { property, propertyOffer } = this.props
    const { spinnerName } = this.state
    let { address, bedrooms, bathrooms, parking, images } = property
      ? property
      : ''
    let { friendlyName, suburb, state, postcode } = address ? address : ''
    let { weeklyRent, acceptedLeaseLength } = propertyOffer ? propertyOffer : ''

    if (images && images.length > 0) {
      propertyImage = (
        images.find((image) => image.isMain === true) || images[0]
      ).URL
    }

    const spinner = spinnerName ? (
      <i className="fa fa-spinner fa-pulse spin" />
    ) : (
      ''
    )

    return (
      <div className="sm-applications-detailed-properties bigScreen-pl0">
        <div
          className="image"
          style={{ backgroundImage: `url(${propertyImage})` }}
        ></div>
        <div className="address">
          <h2>{friendlyName}</h2>
          <div className="fs16 pt5">
            {suburb}, {state} {postcode}
          </div>
          <div className="fs16 pt20 xs-pt20 wsnw asking-rent amenities justify-content-flex-start">
            <ul className="owner-preference-mobile">
              <li>
                <li>
                  <i className="icon-bedroom"></i>
                  <span>{bedrooms}</span>
                </li>
                <li>
                  <i className="icon-bathroom"></i>
                  <span>{bathrooms}</span>
                </li>
                <li>
                  <i className="icon-cars"></i>
                  <span>{parking}</span>
                </li>
              </li>
              <li className="ml10 preference-tag">Owner Preference:</li>
              <li className="first-option-tag">
                {Profile.mostImportant.map((option) => (
                  <span
                    key={option.id}
                    name="mostImportant"
                    className={`linkStyle-blue ml20 ${
                      this.state[option.id] === 100 ? 'fw600' : 'fw400'
                    }`}
                    onClick={() => this.update(option.id, 100)}
                    disabled={spinnerName}
                  >
                    {option.link} {spinnerName === option.id && spinner}
                  </span>
                ))}
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Properties
