import { Box } from 'app/components/design-system-components'
import { CheckboxesList } from 'app/components/design-system-components/list-filters/CheckboxesList'
import { DropdownBodySectionHeader } from 'app/components/design-system-components/list-filters/DropdownBodySectionHeader'
import theme from 'app/match/applicationReportPDF/assets/theme'

export const readStatusFiltersFields = {
  status: 'status',
}
export const isReadStatusFilterApplied = (statusFilterValues) =>
  statusFilterValues &&
  statusFilterValues[readStatusFiltersFields.status] &&
  Object.values(statusFilterValues[readStatusFiltersFields.status]).length

export const readStatusDataExtractor = (filterValues) => {
  const { [readStatusFiltersFields.status]: statusesObj = {} } = filterValues

  return {
    statuses: {
      ...(Object.keys(statusesObj).length === 1 && {
        isRead: Boolean(
          Object.keys(statusesObj).find((key) => key === statuses.isRead),
        ),
      }),
    },
  }
}

export const readStatusAppliedFilterRenderer = ({ filterValues }) => {
  const { [readStatusFiltersFields.status]: status } = filterValues
  if (status[allStatusOption.optionMeta.id])
    return allStatusOption.optionMeta.displayText
  return Object.values(status)
    .map((statusObj) => statusObj.optionMeta.displayText)
    .join(', ')
}
const allStatusOption = {
  option: 'all',
  optionMeta: { id: 'all', displayText: 'All' },
}
const statuses = {
  isRead: 'read',
  isUnread: 'unread',
}
const statusKeys = {
  [statuses.isRead]: 'Read',
  [statuses.isUnread]: 'Unread',
}
export const ReadStatusFilterBody = ({
  formBag: { values, setFieldValue },
}) => {
  const onCheckBoxClicked = (checked, option) => {
    if (option.optionMeta.id === allStatusOption.optionMeta.id) {
      toggleAll(checked)
      return
    }
    const statusValues = values[readStatusFiltersFields.status] || {}

    if (checked) {
      setFieldValue(readStatusFiltersFields.status, {
        ...statusValues,
        [option.optionMeta.id]: option,
      })
    } else {
      const {
        [option.optionMeta.id]: uncheckedOption,
        [allStatusOption.optionMeta.id]: allOption,
        ...otherOptions
      } = statusValues
      setFieldValue(readStatusFiltersFields.status, {
        ...otherOptions,
      })
    }
  }
  const toggleAll = (checked) => {
    if (checked) {
      setFieldValue(
        readStatusFiltersFields.status,
        Object.assign(
          {},
          ...statusesOptions.map((option) => ({
            [option.optionMeta.id]: option,
          })),
        ),
      )
    } else {
      setFieldValue(readStatusFiltersFields.status, {})
    }
  }
  const statusValues = values[readStatusFiltersFields.status] || {}
  const statusesOptions = Object.values(statuses).map((status) => ({
    option: status,
    optionMeta: {
      id: status,
      displayText: statusKeys[status],
    },
  }))
  statusesOptions.unshift(allStatusOption)

  return (
    <>
      <Box style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <DropdownBodySectionHeader
          header="Read Statuses"
          containerProps={{ mb: theme.space[4] + 'px' }}
        />
        <CheckboxesList
          options={statusesOptions}
          checkedOptions={statusValues}
          onCheckBoxClicked={onCheckBoxClicked}
        />
      </Box>
    </>
  )
}
