import { useEffect, useState } from 'react'

import { Field, Formik, FormikValues } from 'formik'

import { ApplicationShortCard } from 'app/components/application/ApplicationShortCard'
import {
  Alert,
  Box,
  Flex,
  LoadingSection,
  TextSpan,
} from 'app/components/design-system-components'
import { WarningRounded } from 'app/components/design-system-components/icons/alert'
import { TextArea } from 'app/components/design-system-components/inputs'
import { CheckboxesInputField } from 'app/components/design-system-components/inputs/Checkboxes'
import { getEntityFromOptionProp } from 'app/components/design-system-components/inputs/utils'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import {
  defaultInitialValues,
  formFields,
  formValidation,
} from 'app/features/teams/applications/merge-applications/MergeApplicationModal/form.utils'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { getPropertyApplicationsForManager } from 'app/services/http/applications'
import { createApplicationMergeRequest } from 'app/services/http/applications'
import { isApplicationMergeable } from 'app/utils/applications/helpers'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'

const renderActivationModalFooter = (
  onClickActivate: () => {},
  onCancel: () => {},
  isSubmitting: boolean,
  isDisabled: boolean,
) => {
  return (
    <GenericModalFooter
      primaryBtnConfig={{
        title: 'Request merge',
        onClick: onClickActivate,
        props: {
          loading: isSubmitting,
          disabled: isDisabled,
        },
      }}
      secondaryBtnConfig={{
        title: 'Cancel',
        onClick: onCancel,
      }}
    />
  )
}

interface MergeModalProps {
  applicationId: string
  propertyId: string
  onMergeSubmitted: () => {}
  onClose: () => {}
}

export const MergeApplicationModal = ({
  applicationId,
  propertyId,
  onMergeSubmitted,
  onClose,
}: MergeModalProps) => {
  const {
    state: applicationsOptions,
    setState: setApplicationsOptions,
    loadingStates: appsLoadingStates,
    loadingStatesHelpers: appsLoadingStatesHelper,
  } = useStateWithLoading([])

  useEffect(() => {
    loadPropertyApplications(propertyId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [error, setError] = useState('')

  const emptyStateMessage = (
    <Alert variant="blueWithBg" fontSize={theme.fontSizes.pRegular14} m={4}>
      No other applications available to be merged with selected application.
    </Alert>
  )

  const loadPropertyApplications = (propertyId: string) => {
    appsLoadingStatesHelper.startLoading()
    getPropertyApplicationsForManager(propertyId)
      .then((apps: any[]) => {
        setApplicationsOptions(
          apps
            .filter(({ guidID }: any) => guidID !== applicationId)
            .filter((app: any) => isApplicationMergeable(app))
            .map((application: any) => ({
              option: application,
              optionMeta: { id: application.guidID },
            })),
        )
        appsLoadingStatesHelper.markDoneSuccessfully()
      })
      .catch((err: any) => appsLoadingStatesHelper.setError(err))
  }

  const onMergeApplications = (values: FormikValues) => {
    const selectedApplication = getEntityFromOptionProp(
      values[formFields.destinationApplication],
    )
    const payload = {
      destination_application: selectedApplication.guidID,
      message: values[formFields.message],
    }
    setError('')
    return createApplicationMergeRequest(applicationId, payload)
      .then(() => {
        onMergeSubmitted?.()
      })
      .catch(({ message }: any) => {
        setError(message)
      })
  }

  return (
    <Formik
      initialValues={defaultInitialValues}
      onSubmit={onMergeApplications}
      validate={formValidation}
    >
      {({ isSubmitting, isValid, submitForm }) => {
        return (
          <GenericModal
            onDismiss={() => onClose()}
            title={<Flex alignItems="center">Merge application</Flex>}
            modalFooter={renderActivationModalFooter(
              submitForm,
              () => onClose(),
              isSubmitting,
              !isValid,
            )}
          >
            <TextSpan
              fontSize={theme.fontSizes.pRegular14}
              lineHeight={theme.lineHeights.encrption}
            >
              Merge this application into the selected application. The primary
              applicant will be notified via SMS and email to approve the merge.
            </TextSpan>

            <Alert
              fontSize={theme.fontSizes.pRegular14}
              variant="warningWithBg"
              my={6}
              alignItems="center"
            >
              <WarningRounded
                width={`${theme.width[5]}px`}
                height={`${theme.height[5]}px`}
              />
              <TextSpan ml={5}>
                Please review carefully. Once approved, this cannot be undone.
              </TextSpan>
            </Alert>

            {!!error && (
              <Alert variant="warningWithBg" mb={3}>
                {error}
              </Alert>
            )}

            <Box>
              <FieldWrapper
                id={formFields.destinationApplication}
                name={formFields.destinationApplication}
                label="Merge into application"
                required
              >
                <Box
                  maxHeight="186px"
                  overflowY="auto"
                  mb={4}
                  border={`${theme.borders[1]} ${theme.colors.grey200}`}
                  px={3}
                >
                  <LoadingSection
                    loadingState={appsLoadingStates}
                    actionHandler={loadPropertyApplications}
                    loaderProps={{ fontSize: theme.fontSizes.pExtraLarge18 }}
                  >
                    {applicationsOptions?.length > 0 ? (
                      <CheckboxesInputField
                        id={formFields.destinationApplication}
                        name={formFields.destinationApplication}
                        options={applicationsOptions}
                        customLabelClass="filter-today-checkbox width100 pb0"
                        rendererContainerProps={{
                          width: '90%',
                          verticalAlign: 'middle',
                        }}
                        optionRenderer={({ option }: any) => (
                          <ApplicationShortCard
                            application={option}
                            borderBottom
                            pl={3}
                          />
                        )}
                        isRadioGroup
                      />
                    ) : (
                      emptyStateMessage
                    )}
                  </LoadingSection>
                </Box>
              </FieldWrapper>

              <FieldWrapper
                id={formFields.message}
                label="Message"
                name={formFields.message}
                my={4}
              >
                <Field
                  name={formFields.message}
                  as={TextArea}
                  rows={3}
                  placeholder="Type a message (optional)"
                />
              </FieldWrapper>
            </Box>
          </GenericModal>
        )
      }}
    </Formik>
  )
}
