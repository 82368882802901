import { css } from 'styled-components'

import { fontSizes } from 'app/components/design-system-components/styles/variables'

// just a replace for @mixin scrollbar in src/app/assets/stylesheets/_variables.scss
export const Scrollbar = (
  background = `white`,
  width = `${fontSizes[4]}px`,
  thumb = '4px',
  color = '#e8e8e9',
) => css`
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    background-color: ${background};
    width: ${width};
    height: ${width};
  }

  &::-webkit-scrollbar-corner {
    background-color: ${background};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${width};
    background-color: ${color};
    border: ${thumb} solid ${background};
  }
`
