import moment from 'moment'
import { call, delay, put, select, takeEvery } from 'redux-saga/effects'

import {
  watchCheckNewVersion,
  watchNewServiceWorkerRegistered,
} from 'app/core/appNewVersion'
import {
  connectionStatuses,
  actions as coreActions,
  actionTypes as coreActionTypes,
} from 'app/core/coreSlice'
import { watchCheckMaintenanceMessage } from 'app/core/maintenanceMessageSaga'
import { watchNewActivityFeedCheck } from 'app/core/newActivityFeedSaga'
import { healthCheck } from 'app/services/http/core'
import * as snugLogger from 'app/services/snugLogger'

const recheckTimeInSeconds = 15

const appVisibilitySelector = (state) => state.core.appIsVisible

function* checkConnectionWorker() {
  const appIsVisible = yield select(appVisibilitySelector)
  if (appIsVisible) {
    try {
      yield call(healthCheck)
    } catch {}
  }
  yield delay(recheckTimeInSeconds * 1000)
  yield put(coreActions.checkConnection())
}

function* watchCheckConnection() {
  yield takeEvery(coreActionTypes.checkConnection, checkConnectionWorker)
}

function* watchAppStarted() {
  yield takeEvery(coreActionTypes.started, function* () {
    yield put(coreActions.checkConnection())
    yield put(coreActions.checkNewVersion())
    yield put(coreActions.maintenanceMessageCheck())
  })
}

function* logWhenConnectionRetrieved({ status }) {
  if (status !== connectionStatuses.becameOnline) {
    return
  }

  const { teams, currentUser, currentTeam, lastOfflineTime } = yield select(
    (state) => ({ ...state.session, ...state.core }),
  )

  // for some reason, we are adding a virtual team for users. so all users all ready have at least 1 team even they are renters
  const isPm = teams.length > 1

  if (!isPm) {
    return
  }

  snugLogger.log('user became online', {
    tags: {
      event: 'offlineMode/existed',
      teamSlug: currentTeam.slug,
      wentOfflineTime:
        lastOfflineTime && moment(lastOfflineTime).utc().format(),
    },
    user: {
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      email: currentUser.email,
      guidID: currentUser.guidID,
    },
  })
}

function* watchConnectionStatusChange() {
  yield takeEvery(
    coreActionTypes.updateConnectionStatus,
    logWhenConnectionRetrieved,
  )
}

export const coreSagas = [
  watchAppStarted(),
  watchCheckConnection(),
  watchConnectionStatusChange(),
  watchCheckNewVersion(),
  watchNewServiceWorkerRegistered(),
  watchCheckMaintenanceMessage(),
  watchNewActivityFeedCheck(),
]
