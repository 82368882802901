import React, { useEffect, useState } from 'react'

import IntlTelInput from 'react-intl-tel-input'

import {
  formatPhoneNumber,
  formatPhoneNumberE164,
} from 'app/utils/phonenumber/helpers'

export const IntlTelInputWrapper = ({
  css,
  utilsScript,
  defaultCountry,
  preferredCountries,
  onPhoneNumberChange,
  onPhoneNumberBlur,
  value,
  placeholder,
  onlyCountries,
  onSelectFlag,
  fieldId,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(value)

  useEffect(() => {
    if (formatPhoneNumberE164(value) !== formatPhoneNumberE164(phoneNumber)) {
      setPhoneNumber(formatPhoneNumber(value))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const formatIntlTelNumber = (blur) => {
    return (status, value, countryData, number, id) => {
      setPhoneNumber(number)
      const formattedPhoneNumber = formatPhoneNumberE164(number)
      if (blur) {
        onPhoneNumberBlur(status, value, countryData, formattedPhoneNumber, id)
      } else {
        onPhoneNumberChange(
          status,
          value,
          countryData,
          formattedPhoneNumber,
          id,
        )
      }
    }
  }

  return (
    <IntlTelInput
      css={css}
      utilsScript={utilsScript}
      defaultCountry={defaultCountry}
      preferredCountries={preferredCountries}
      onPhoneNumberChange={formatIntlTelNumber(false)}
      onPhoneNumberBlur={formatIntlTelNumber(true)}
      value={phoneNumber}
      placeholder={placeholder}
      onlyCountries={onlyCountries}
      onSelectFlag={onSelectFlag}
      fieldId={fieldId}
    />
  )
}
