import React from 'react'

import $ from 'jquery'
import { Link } from 'react-router-dom'

import iconDocumentImg from 'app/assets/icons/icon-document-img.svg'
import Spinner from 'app/sm/common/spinner'
import { urlTo } from 'app/sm/helpers'
import Breadcrumbs from 'app/sm/ppp/breadcrumbs'
import PhotosList from 'app/sm/ppp/property_components/property_form/photos_list'
import { isCurrentRouteFrom } from 'app/sm/router_helpers'

function bindCorrespondingContinueUrl(activeOffer, propertyId) {
  let [updateKey, createKey] = ['updateOfferBase', 'createOffer']

  if (isCurrentRouteFrom('Profile')) {
    updateKey = 'profileUpdateOfferDetails'
    createKey = 'profileOfferDetails'
  }

  return activeOffer !== undefined
    ? urlTo(updateKey, { propertyId, offerId: activeOffer.guidID })
    : urlTo(createKey, { propertyId })
}

function bindCorrespondingBackUrl(propertyId) {
  const key = isCurrentRouteFrom('Profile')
    ? 'profileUpdatePropertyBasics'
    : 'updatePropertyBasics'

  return urlTo(key, { propertyId, id: propertyId })
}

class Photos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clicked: {},
    }
  }

  componentDidMount() {
    $('.app-content').scrollTop(0)
  }

  render() {
    const {
      ad,
      params,
      updatePhotos,
      state,
      setMainImage,
      deletePhoto,
      spinner,
      property,
      responseText,
      photosValidationError,
    } = this.props
    const activeOffer = (state.offers || []).find((o) => o.isActive)
    const urls = {
      continue: bindCorrespondingContinueUrl(activeOffer, params.id),
    }

    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )

    const currentTeam = this.props.currentTeam

    return (
      <div className="sm-property-create photos">
        {!isCurrentRouteFrom('Profile') && (
          <Breadcrumbs activeBreadCrumb={'property-photos'} />
        )}
        <div className="mobile-only">
          <Link
            to={urlTo('updatePropertyBasics', { id: params.id })}
            className="btn btn-transparent btn-left xs-text-center wa"
          >
            <i className="icon-arrow-left left"></i>
            <span>Back</span>
          </Link>
        </div>
        <div className="section-title">
          <div className="left column">
            <h3>Add Photos</h3>
            <p className="gray-color mt10 mb0">
              Upload a selection of property photos and help your property
              shine.
            </p>
          </div>
        </div>
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <div className="sm-property-photos">
              <PhotosList
                images={state.images}
                deletePhoto={deletePhoto}
                updatePhotos={updatePhotos}
                setMainImage={setMainImage}
                property={property}
              />
              {spinner && <Spinner />}
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={photosValidationError ? 'alert alert-danger' : ''}
                >
                  <div>{photosValidationError}</div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className={responseText ? 'alert alert-danger' : ''}>
                  <div>{responseText}</div>
                </div>
              </div>
            </div>
            <div className="row pt25 mobile-form-button">
              <div className="col-sm-5 pb5 tablet-only">
                <Link
                  to={urlTo('updatePropertyBasics', { id: params.id })}
                  className="btn btn-transparent btn-left xs-text-center wa"
                >
                  <i className="icon-arrow-left left"></i>
                  <span>Back</span>
                </Link>
              </div>
              <div className="col-sm-7 pb5">
                <Link className="btn" to={urls.continue}>
                  Continue
                </Link>
              </div>
            </div>
          </div>
          <div className="col-second xs-first">
            {state.images.length > 0 && !isMobile && (
              <div className="sm-main-photo-info-box">
                <div className="image-wrapper">
                  <img src={iconDocumentImg} />
                  <i className="icon-move"></i>
                </div>
                <div className="text-wrapper">
                  Drag photos to a "Main image" position to make them a cover
                  image.
                </div>
              </div>
            )}
            <ul className="snug-tips">
              <li>Great photos increase views and enquiries.</li>
              {ad !== null && (
                <li>
                  We will update the ad automatically after the property is
                  changed.
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Photos
