import { connect } from 'react-redux'

import {
  acceptTermsConditionsChange,
  cardSelect,
  fetchCards,
  fetchOfferedApplication,
  payWithExistingCard,
  ping,
  registerPayment,
} from 'app/sm/ph_deposit/ph_deposit_actions'
import PhDepositContainer from 'app/sm/ph_deposit/ph_deposit_container'

const mapStateToProps = ({ PhDeposit }) => ({
  cards: PhDeposit.cards,
  acceptTermsAndConditions: PhDeposit.acceptTermsAndConditions,
  selectedCreditCard: PhDeposit.selectedCreditCard,
  offeredApplication: PhDeposit.offeredApplication,
  error: PhDeposit.error,
  attempts: PhDeposit.attempts,
})

const mapDispatchToProps = (dispatch) => ({
  ping: () => dispatch(ping()),
  fetchCards: () => dispatch(fetchCards()),
  acceptTermsConditionsChange: () => dispatch(acceptTermsConditionsChange()),
  cardSelect: (selected) => dispatch(cardSelect(selected)),
  fetchOfferedApplication: (id) => dispatch(fetchOfferedApplication(id)),
  registerPayment: (data) => dispatch(registerPayment(data)),
  payWithExistingCard: () => dispatch(payWithExistingCard()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PhDepositContainer)
