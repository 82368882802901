import React from 'react'

import styled from 'styled-components'

const Row = styled.div`
  border-bottom: 1px solid var(--pdf-gray-light);
  padding: 15px 14px 20px 19px;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  line-height: 1.14;

  p {
    margin: 0 0 2px;
    font-size: inherit;
    line-height: inherit;
  }
`

function ApplicantRow(props) {
  return <Row className="applicant-row cursor-pointer">{props.children}</Row>
}

export default ApplicantRow
