import React from 'react'

import ApplicationRow from 'app/dashboard/prospects_summary'
import { shortListApplication } from 'app/services/http/applications'
import { getTeamSettings } from 'app/services/http/teams/settings'
import * as snugNotifier from 'app/services/snugNotifier'
import {
  ApplicationCategory,
  isStatusShortlisted,
  parseDataUtil,
} from 'app/sm/helpers'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  loadingStatesIds,
} from 'app/utils/loading-states'
import * as textHelpers from 'app/utils/text/helpers'

const searchDelay = 500
class ApplicationsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      applications: [],
      totalApplications: 0,
      nextCursor: '',
      applicationTeamSettings: {},
      appsLoadingStates: createInitialLoadingState(),
    }

    this.appsLoadingUtils = createLoadingStateUtils((loadingState) => {
      this.setState((state) => ({
        ...state,
        appsLoadingStates: loadingState,
      }))
    })
  }

  componentDidMount() {
    const { currentTeam } = this.props
    const { guid: teamGUID } = currentTeam || {}
    teamGUID && this.loadStageApplications()
    teamGUID && this.fetchTeamSettings(teamGUID)
  }

  componentDidUpdate(prevProps) {
    let { currentTeam, filters } = this.props
    currentTeam = currentTeam || {}
    const prevTeam = prevProps.currentTeam || {}
    const { guid: teamGUID } = currentTeam
    if (currentTeam.guid !== prevTeam.guid) {
      this.loadStageApplications()
      this.fetchTeamSettings(teamGUID)
    }
    if (filters !== prevProps.filters) {
      clearInterval(this.addressSearch)
      this.addressSearch = setTimeout(
        () => this.loadStageApplications(),
        searchDelay,
      )
    }
  }

  fetchTeamSettings = (teamGUID) => {
    getTeamSettings(teamGUID, {
      keys: ['application', 'integrations'],
    }).then(({ settingInfo }) => {
      const { application = {}, integrations = {} } = settingInfo
      const applicationTeamSettings =
        parseDataUtil.convertObjValueFromStringToBoolean(application)
      this.setState({
        integrationsTeamSettings: integrations,
        applicationTeamSettings,
      })
    })
  }

  onShortlistWithEmail = (applicationID, params) => {
    const { applications = [], totalApplications } = this.state
    const indexForUpdatedApplication = applications.findIndex(
      (application) => application.guidID === applicationID,
    )
    if (indexForUpdatedApplication === -1) {
      return
    }
    const application = applications[indexForUpdatedApplication]
    const { applicationTeamSettings } = this.state
    const { enable_internal_shortlisting: enableInternalShortlisting = false } =
      applicationTeamSettings || {}
    if (
      enableInternalShortlisting &&
      application.shortlistingEmailSent &&
      isStatusShortlisted(ApplicationCategory[application.status])
    ) {
      snugNotifier.success('Already shortlisted and email sent')
      return
    }
    shortListApplication(applicationID, params).then(
      ({ status, shortlistingEmailSent }) => {
        applications[indexForUpdatedApplication] = {
          ...applications[indexForUpdatedApplication],
          status,
          shortlistingEmailSent,
        }
        this.setApplicationsStates(applications, totalApplications)
        snugNotifier.success('Application has been shortlisted and email sent')
      },
    )
  }

  refreshApplicationsList() {
    const { currentTeam } = this.props
    const { guid: teamGUID } = currentTeam || {}
    teamGUID && this.loadStageApplications()
  }

  filterByApplicantEmail = (applicantEmail) => {
    this.props.setPropertyFilters({
      ...this.props.filters,
      applicantEmail,
    })
  }

  loadStageApplications = (fromLoadMore = false) => {
    this.appsLoadingUtils.startLoading()
    const { filters, currentTeam, stage, teamSettingsFilters } = this.props
    const { guid: teamGUID } = currentTeam
    const { nextCursor: cursor } = this.state
    teamGUID &&
      this.props
        .fetchTeamApplications(teamGUID, {
          ...filters,
          workflowStage: stage,
          cursor,
          ...teamSettingsFilters,
        })
        .then((responseData) => {
          let updateApplications = []

          if (fromLoadMore) {
            updateApplications = [
              ...this.state.applications,
              ...responseData.data,
            ]
          } else {
            updateApplications = [...responseData.data]
          }
          this.setApplicationsStates(
            updateApplications,
            responseData?.response_metadata?.total,
          )
          this.setState({
            nextCursor:
              responseData && responseData.response_metadata.next_cursor,
          })
          this.appsLoadingUtils.markDoneSuccessfully()
        })
        .catch((error) => {
          this.appsLoadingUtils.setError(error)
        })
  }

  onAddShortListApplication = (applicationID) => {
    const { applications = [], totalApplications } = this.state
    const indexForUpdatedApplication = applications.findIndex(
      (application) => application.guidID === applicationID,
    )
    if (indexForUpdatedApplication === -1) {
      return
    }
    const { applicationTeamSettings } = this.state
    const { enable_internal_shortlisting: enableInternalShortlisting = false } =
      applicationTeamSettings || {}

    return shortListApplication(applicationID, {
      sendEmail: !enableInternalShortlisting,
    }).then(({ status, shortlistingEmailSent }) => {
      applications[indexForUpdatedApplication] = {
        ...applications[indexForUpdatedApplication],
        status,
        shortlistingEmailSent,
      }
      this.setApplicationsStates(applications, totalApplications)
    })
  }

  onRemoveShortListApplication = (applicationID) => {
    const { removeShortListApplication } = this.props

    return removeShortListApplication(applicationID).then((status) => {
      this.updateApplicationShortlistStatus(applicationID, status)
    })
  }

  updateApplicationShortlistStatus(applicationID, status) {
    const { applications, totalApplications } = this.state
    const updatedApplications = applications.map((application) =>
      application.guidID === applicationID
        ? { ...application, status }
        : application,
    )
    this.setApplicationsStates(updatedApplications, totalApplications)
  }

  setApplicationsStates = (applications, totalCount) => {
    this.setState({
      applications,
      totalApplications: totalCount,
    })
  }

  render() {
    const {
      applications,
      totalApplications,
      applicationTeamSettings,
      appsLoadingStates,
      nextCursor = '',
    } = this.state
    const {
      headingText = '',
      currentTeam,
      markProgressApplicationAsApproved,
      sendUtilityReferral,
      fetchNotesWithLimit,
      fetchApplicationNotesWithLimit,
      addViewing,
      fetchAllPropertyFutureViewings,
      sendScheduleViewingFromApplication,
      pmsIntegrationStatus,
      isConsoleCloudEnabled = false,
    } = this.props
    const isLoadingApps = appsLoadingStates.state === loadingStatesIds.LOADING
    const error = appsLoadingStates.error
    const enableShowMoreLink = !isLoadingApps && nextCursor !== ''
    const isLoadingInitially = isLoadingApps && applications?.length === 0
    const showApplications =
      !isLoadingInitially && applications && applications.length > 0
    return (
      <div className="pt50">
        <div className="display-flex flex-direction-column-mobile width100 mb20">
          <div className="display-flex prospect-address-wrappper">
            <span className="font-size-22 display-flex align-items-center mr10">
              {headingText} ({totalApplications})
            </span>
          </div>
        </div>

        {showApplications ? (
          <div>
            {applications.map((application) => {
              return (
                <ApplicationRow
                  key={application.guidID}
                  offer={application.propertyOffer}
                  property={application.propertyOffer.property}
                  application={application}
                  failCallback={error}
                  teamSlug={currentTeam.slug}
                  onAddShortListApplication={this.onAddShortListApplication}
                  onRemoveShortListApplication={
                    this.onRemoveShortListApplication
                  }
                  declineApplication={this.props.declineApplication}
                  offerApplication={this.props.offerApplication}
                  withdrawOfferForApplication={
                    this.props.withdrawOfferForApplication
                  }
                  moveTo={this.props.moveTo}
                  status={application.status}
                  currentTeam={this.props.currentTeam}
                  currentAgency={this.props.currentAgency}
                  fetchProgressApplication={this.props.fetchProgressApplication}
                  markProgressApplicationAsLeased={
                    this.props.markProgressApplicationAsLeased
                  }
                  markProgressApplicationAsApproved={
                    markProgressApplicationAsApproved
                  }
                  sendUtilityReferral={sendUtilityReferral}
                  fetchNotesWithLimit={fetchNotesWithLimit}
                  fetchApplicationNotesWithLimit={
                    fetchApplicationNotesWithLimit
                  }
                  addViewing={addViewing}
                  fetchAllPropertyFutureViewings={
                    fetchAllPropertyFutureViewings
                  }
                  sendScheduleViewingFromApplication={
                    sendScheduleViewingFromApplication
                  }
                  pmsIntegrationStatus={pmsIntegrationStatus}
                  refreshApplicationsList={() => this.refreshApplicationsList()}
                  filterByApplicantEmail={this.filterByApplicantEmail}
                  from={textHelpers.APPLICATION_BY_STAGE}
                  applicationTeamSettings={applicationTeamSettings}
                  shortlistWithEmail={this.onShortlistWithEmail}
                  isConsoleCloudEnabled={isConsoleCloudEnabled}
                  dragDisabled={true}
                />
              )
            })}
          </div>
        ) : (
          <div className={`text-center ${isLoadingApps && 'd-none'}`}>
            No Applications available
          </div>
        )}
        <div className="text-center">
          {isLoadingApps && <i className="fa fa-spinner fa-pulse" />}
        </div>
        {enableShowMoreLink && (
          <div className="text-center">
            <button
              className="textButton wa ha btnmr mt10 mb10 gray-text"
              onClick={() => this.loadStageApplications(true)}
            >
              Show more
            </button>
          </div>
        )}
      </div>
    )
  }
}

export default ApplicationsTable
