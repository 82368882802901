export const generateApplicantsText = (applicants) => {
  const mappedApplicants = applicants.map((applicant) => ({
    ...applicant,
    fullName: `${applicant.firstName} ${applicant.lastName}`,
  }))
  if (mappedApplicants.length === 1) {
    return mappedApplicants[0].fullName
  } else {
    return `${mappedApplicants[0].fullName} +${
      mappedApplicants.length - 1
    } more`
  }
}
