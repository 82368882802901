import React, { useState } from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'
import { color, zIndex } from 'styled-system'

import {
  Box,
  Button,
  Flex,
  IconButton,
} from 'app/components/design-system-components'
import {
  StarRoundedOutline,
  StarRoundedSolid,
} from 'app/components/design-system-components/icons/social'
import InviteToApplyModal from 'app/components/design-system-components/InviteToApply/InviteToApplyModal'
import ContextMenu from 'app/pages/teams/messages/components/ContextMenu'

const IconContainer = styled(Flex)`
  svg {
    width: 1.5em;
    height: 1.5em;
    color: ${({ theme }) => theme.colors.pdfGray};
    stroke-width: 1.5;
    ${color}
  }
`

const ShortlistTooltip = styled(ReactTooltip)`
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.pdfGray};
`

function ActionsCell({
  offerGUID,
  messageGUID,
  archived,
  shortlisted,
  readAt,
  markAsUnread,
  markAsRead,
  onArchiveMessage,
  shortlistMessage,
  unshortlistMessage,
  sendPropertyListAction,
  onClickInviteToProperty,
  enquirerHasEmail = false,
  teamGUID,
  propertyInfo,
  enquirer = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
  },
  scheduleViewingAction,
  disableContext,
}) {
  const markReadOrUnread = !!readAt
    ? () => markAsUnread(messageGUID)
    : () => markAsRead(messageGUID)
  const markReadOrUnreadLabel = !!readAt ? 'Mark as unread' : 'Mark as read'
  const markReadOrUnreadVariant = !!readAt ? 'outline' : 'brightGreenOutline'

  const [showInviteToApplyModal, setShowInviteToApplyModal] = useState(false)
  const toggleShowInviteToApplyModal = () => {
    setShowInviteToApplyModal(!showInviteToApplyModal)
  }

  const { guidID = '', address = '' } = propertyInfo || {}

  const shortlistOnClick = shortlisted ? unshortlistMessage : shortlistMessage

  const shortlistBtn = () => (
    <IconContainer>
      {shortlisted ? (
        <StarRoundedSolid
          data-tooltip-id={`shortlist-icon-${messageGUID}`}
          data-tooltip-content=""
          data-tooltip-delay-show={1000}
        />
      ) : (
        <StarRoundedOutline
          data-tooltip-id={`shortlist-icon-${messageGUID}`}
          data-tooltip-content=""
          data-tooltip-delay-show={1000}
        />
      )}
      <ShortlistTooltip id={`shortlist-icon-${messageGUID}`}>
        {shortlisted ? 'Shortlisted' : 'Click to shortlist'}
      </ShortlistTooltip>
    </IconContainer>
  )

  return (
    <Flex flexDirection="row">
      <IconButton
        mr={4}
        mt={1}
        height="1.5em !important"
        sizeVariant=""
        IconCmp={shortlistBtn}
        onClick={() => shortlistOnClick(messageGUID)}
        variant="flat"
      />
      <Flex flexDirection="column">
        <Button
          variant={markReadOrUnreadVariant}
          sizeVariant="extraSmall"
          onClick={markReadOrUnread}
        >
          {markReadOrUnreadLabel}
        </Button>
        <Flex alignItems="center" mt="10px">
          {!archived && (
            <Button
              variant="outline"
              sizeVariant="extraSmall"
              fontSize="12px"
              onClick={() => onArchiveMessage(messageGUID)}
            >
              Archive
            </Button>
          )}
          <Box ml="8px">
            <ContextMenu
              offerGUID={offerGUID}
              sendPropertyList={sendPropertyListAction}
              onClickInviteToProperty={onClickInviteToProperty}
              enquirerHasEmail={enquirerHasEmail}
              toggleShowInviteToApplyModal={toggleShowInviteToApplyModal}
              scheduleViewingAction={scheduleViewingAction}
              disabled={disableContext}
            />
          </Box>
        </Flex>
      </Flex>
      {showInviteToApplyModal && (
        <InviteToApplyModal
          toggleModal={() => toggleShowInviteToApplyModal()}
          teamGUID={teamGUID}
          propertyDisplayText={address}
          propertyGUID={guidID}
          contactDetails={enquirer}
          enquirerHasEmail={enquirerHasEmail}
        />
      )}
    </Flex>
  )
}

export default ActionsCell
