import React, { useEffect } from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  Box,
  Flex,
  LoadingSection,
  TextSpan,
} from 'app/components/design-system-components'
import { HelpOutlineRounded } from 'app/components/design-system-components/icons/actions'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { getApplicationFollowers } from 'app/services/http/team-applications'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { loadingStatesIds } from 'app/utils/loading-states'

export const ApplicationFollowers = ({
  teamID,
  applicationID,
  assignedTeamMember,
}) => {
  const {
    state: followers,
    setState: setFollowers,
    loadingStates: followersLoadingStates,
    promiseWrapper,
  } = useStateWithLoading({})

  const loadFollowers = () => {
    const promise = getApplicationFollowers(teamID, applicationID).then(
      setFollowers,
    )
    return promiseWrapper(promise)
  }

  useEffect(() => {
    loadFollowers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamID, applicationID, assignedTeamMember])

  const tooltipId = applicationID + '-followers-tooltip'
  const tooltipMessage =
    'Team Members also receiving new, updated and withdrawn application updates'

  const followersElem = (() => {
    if (followersLoadingStates.state !== loadingStatesIds.LOADED) return null
    if (!followers || !followers.length)
      return <TextSpan color={theme.colors.gray700}>No followers</TextSpan>

    return followers
      .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
      .join(', ')
  })()

  return (
    <Box as="section">
      <Flex alignItems="center" mb={theme.space[3] + 'px'}>
        <Box mr={theme.space[3] + 'px'}>
          <strong>Followed by</strong>
        </Box>
        <Flex
          alignItems="center"
          fontSize={theme.space[5] + 'px'}
          color={theme.colors.gray600}
        >
          <HelpOutlineRounded
            data-tooltip-content=""
            data-tooltip-id={tooltipId}
          />
        </Flex>
      </Flex>
      <Box>
        <LoadingSection
          loadingState={followersLoadingStates}
          actionHandler={() => loadFollowers()}
        >
          {followersElem}
        </LoadingSection>
      </Box>

      <ReactTooltip
        id={tooltipId}
        data-type="info"
        place="top"
        className="tool-tip-style"
      >
        <Box maxWidth="200px" textAlign="center">
          {tooltipMessage}
        </Box>
      </ReactTooltip>
    </Box>
  )
}
