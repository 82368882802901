import React, { useEffect, useState } from 'react'

import { Field, Form } from 'formik'
import styled from 'styled-components'

import { Flex } from 'app/components/design-system-components'
import {
  InputField,
  TextArea,
} from 'app/components/design-system-components/inputs'
import * as getters from 'app/components/design-system-components/theme/getters'
import { FieldWrapper, SimpleFieldLabel } from 'app/forms/FieldWrapper'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { MessagePreview } from 'app/sm/inspections/viewing_registrants_message_modal/components/MessagePreview'
import { ModalFooter } from 'app/sm/inspections/viewing_registrants_message_modal/components/ModalFooter'
import TemplatesDropdownField from 'app/sm/inspections/viewing_registrants_message_modal/components/TemplatesDropdown'
import {
  DefaultMessageTemplates,
  IsValidTemplate,
} from 'app/sm/inspections/viewing_registrants_message_modal/constants'
import { formFields } from 'app/sm/inspections/viewing_registrants_message_modal/form.utils'

const StyledFieldWrapper = styled(FieldWrapper)`
  margin-bottom: ${getters.spacing(5)};
`

const CustomSpanStyle = styled.span`
  font-weight: ${theme.fontWeights[6]} !important;
  font-size: ${theme.space[5]}px !important;
  color: ${theme.colors.gray700} !important;
`

export const FormBody = ({
  formBag: { isSubmitting, setFieldValue, values, setFieldError },
}) => {
  const [showPreview, setShowPreview] = useState(false)

  const handleTemplateChanged = (t) => {
    if (IsValidTemplate(t)) {
      setFieldValue(formFields.template, t)
      setFieldError(formFields.template, '')
      setFieldError(formFields.message, '')
      setFieldValue(formFields.message, DefaultMessageTemplates[t].body)
    }
  }

  return (
    <Form>
      {showPreview ? (
        <MessagePreview {...values} subject={values.subject} />
      ) : (
        <>
          <StyledFieldWrapper
            id={formFields.template}
            label="Template"
            name={formFields.template}
          >
            <TemplatesDropdownField
              name={formFields.template}
              templateChanged={handleTemplateChanged}
            />
          </StyledFieldWrapper>

          <StyledFieldWrapper
            id={formFields.subject}
            label="Subject"
            name={formFields.subject}
            required
          >
            <Field name={formFields.subject} as={InputField} />
          </StyledFieldWrapper>

          <StyledFieldWrapper
            id={formFields.message}
            label="Message"
            name={formFields.message}
            required
          >
            <Field name={formFields.message} as={TextArea} rows={4} />
          </StyledFieldWrapper>

          <Flex mb={`${theme.space[5]}px`}>
            <label className="ibm">
              <input
                type="checkbox"
                className="input-box wa pt0"
                onChange={(e) =>
                  setFieldValue(formFields.email, e.target.checked)
                }
                checked={values[formFields.email]}
              />
              <CustomSpanStyle>Email</CustomSpanStyle>
            </label>

            <label className="ibm">
              <input
                type="checkbox"
                className="input-box wa pt0"
                onChange={(e) =>
                  setFieldValue(formFields.sms, e.target.checked)
                }
                checked={values[formFields.sms]}
              />
              <CustomSpanStyle>SMS</CustomSpanStyle>
            </label>
          </Flex>
        </>
      )}

      <ModalFooter
        showPreview={showPreview}
        onPreviewClicked={() => setShowPreview(!showPreview)}
        sendDisabled={isSubmitting}
      />
    </Form>
  )
}
