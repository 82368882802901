import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import * as validations from 'app/shared_components/validations'

export const AdminRole = 'Admin'
export const CasualInspectorRole = 'Casual Inspector'
export const LeasingConsultantRole = 'Leasing Consultant'
export const PropertyManagerRole = 'Property Manager'
export const BDMRole = 'BDM'
export const TeamLeaderRole = 'Team Leader'

export const roles = [
  AdminRole,
  CasualInspectorRole,
  LeasingConsultantRole,
  PropertyManagerRole,
  BDMRole,
  TeamLeaderRole,
]
export const rolesOptions = roles.map((id) => ({
  option: id,
  optionMeta: {
    id: id,
    displayText: id,
  },
}))

export const teamMembersFormFields = {
  members: 'members',
}

const fieldValidation = (filedName, val = '') => {
  switch (filedName) {
    case teamMembersFormFields.members: {
      let errors = {}

      val.map((member, index) => {
        if (!member.firstName) {
          errors[`${teamMembersFormFields.members}.${index}.firstName`] =
            'First name required'
        }
        if (!member.email) {
          errors[`${teamMembersFormFields.members}.${index}.email`] =
            'Email required'
        } else {
          const emailErrs = validations.validateEmail(member.email?.trim(), '')
          if (emailErrs.length > 0) {
            errors[`${teamMembersFormFields.members}.${index}.email`] =
              'Email invalid'
          }
        }
        if (!member.role) {
          errors[`${teamMembersFormFields.members}.${index}.role`] =
            'Role required'
        }
      })

      return errors
    }
  }
}

export const teamMembersFormValidation = formValidationBuilder(fieldValidation)
