import { connect } from 'react-redux'

import {
  fetchPropertiesForViewingsByProperty,
  fetchTeamManagerList,
} from 'app/bond_cover/agency/agency_actions'
import ManagerViewingsListing from 'app/sm/manager_viewings/components/manager_viewings_listing'
import {
  fetchViewings,
  onSorterDropdownChange,
  onTabChange,
  ping,
} from 'app/sm/manager_viewings/manager_viewings_actions'
import { persistManager } from 'app/sm/properties/properties_actions'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'
import { fetchViewingPreference } from 'app/sm/team_viewing_preference/action'

const mapStateToProps = ({
  ManagerViewings,
  session,
  MyProperties,
  TeamOverview,
}) => ({
  error: ManagerViewings.error,
  viewings: ManagerViewings.viewings,
  comingUpViewings: ManagerViewings.comingUpViewings,
  completedViewings: ManagerViewings.completedViewings,
  spinner: ManagerViewings.spinner,
  sortBy: ManagerViewings.sortBy,
  activeTab: ManagerViewings.activeTab,
  tabs: ManagerViewings.tabs,
  teams: session.teams,
  currentTeam: session.currentTeam,
  persistedManager: MyProperties.persistedManager,
  currentAgency: session.currentAgency,
  filters: TeamOverview.filters,
})

const mapDispatchToProps = (dispatch) => ({
  ping: () => dispatch(ping()),
  fetchViewings: () => dispatch(fetchViewings()),
  onSorterDropdownChange: (sorter) => dispatch(onSorterDropdownChange(sorter)),
  onTabChange: (activeTab) => dispatch(onTabChange(activeTab)),
  fetchTeamManagerList: (teamGUID) => dispatch(fetchTeamManagerList(teamGUID)),
  persistManager: (manager) => dispatch(persistManager(manager)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
  fetchPropertiesForViewingsByProperty: (teamGUID, option) =>
    dispatch(fetchPropertiesForViewingsByProperty(teamGUID, option)),
  fetchViewingPreference: (teamId, propertyGUID) =>
    dispatch(fetchViewingPreference(teamId, propertyGUID)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManagerViewingsListing)
