import qs from 'qs'

import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'
import { DownloadOptions, PDFTypes } from 'app/sm/helpers'
import { fetchRentAndEmploymentSummary } from 'app/sm/renter_applications/renter_applications_actions'

const now = Date.now().toString()

export const getActiveOffer = (propertyId) => {
  const url = `${apiBaseUrl}sm/properties/${propertyId}/offer`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (offer) => ({ ok: true, offer }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const managerPostBackgroundCheck = (applicantId, applicationId) => {
  const url = `${apiBaseUrl}sm/backgroundcheck?applicantId=${applicantId}&applicationId=${applicationId}`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getBackgroundCheckForManager = (applicantId, applicationId) => {
  const url = `${apiBaseUrl}sm/backgroundcheck?applicantId=${applicantId}&applicationId=${applicationId}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (backgroundCheck) => ({ ok: true, backgroundCheck }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getRenterApplication = (
  applicationId,
  applicantId,
  calculateScore = false,
) => {
  let url
  if (applicantId) {
    url = `${apiBaseUrl}sm/applications/${applicationId}/applicant/${applicantId}/managersummary?cachebuster=${now}&calculateScore=${calculateScore}`
  } else {
    url = `${apiBaseUrl}sm/applications/${applicationId}/managersummary?cachebuster=${now}&calculateScore=${calculateScore}`
  }

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (application) => ({ ok: true, application }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getPropertyApplications = (propertyId, calculateScore = false) => {
  const url = `${apiBaseUrl}sm/properties/${propertyId}/applications?calculateScore=${calculateScore}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (applications) => ({ ok: true, applications }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const alterApplicationStatus = (action, applicationId, params = {}) => {
  const actions = {
    shortlist: 'shortlist',
    unshortlist: 'shortlist',
    decline: 'decline',
    offer: 'offer',
    withdrawoffer: 'withdrawoffer',
  }

  let method = action === 'unshortlist' ? 'DELETE' : 'POST'

  let url = `${apiBaseUrl}sm/applications/${applicationId}/${actions[action]}`
  if (Object.keys(params).length > 0) {
    url += `?${Object.keys(params)
      .map((k) => `${k}=${params[k]}`)
      .join('&')}`
  }
  const options = {
    method: method,
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({}),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (application) => ({ ok: true, application }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const sendRenterInformation = (applicationId, applicantId, content) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/applicant/${applicantId}/requestinformation`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      ...content,
    }),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve() // We get nothing for now, when a review is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getGeneratePDF = (
  applicationID,
  applicantId,
  downloadOption = DownloadOptions[PDFTypes.AllPDFs].value,
) => {
  const amendApplicantUrl = `/applicant/${applicantId}/print`
  const updatedUrl =
    window.location.href.indexOf('/applicant/') >= 0
      ? window.location.href.split('/applicant/')[0] + amendApplicantUrl
      : window.location.href + amendApplicantUrl
  const url = `${apiBaseUrl}sm/applications/${applicationID}/applicant/${applicantId}/generatepdf?downloadOption=${downloadOption}&url=${updatedUrl}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (applicationPDF) => ({ ok: true, applicationPDF }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getRequestApplyAnywhereApplicationAccess = (
  applicationId,
  query,
) => {
  const queryStr = qs.stringify(query)
  const url = `${apiBaseUrl}sm/applications/${applicationId}/requestapplyanywhereaccess?${queryStr}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getApplyAnywhereManagerSummaryWithSecret = (
  applicationId,
  token,
  applicantId = '',
) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/applyanywheremanagersummary?token=${token}${
    applicantId ? `&applicantID=${applicantId}` : ''
  }`
  return fetch(url, {
    credentials: 'include',
  })
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (application) => ({ ok: true, application }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getVerifyApplicationAccess = (applicationId, token) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/verifyaccess?token=${token}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const putMoveTo = (applicationId, newStatus) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/status?to=${newStatus}`
  return fetch(url, {
    credentials: 'include',
    method: 'PUT',
  })
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getRentalHistoryRatings = () => {
  const url = `${apiBaseUrl}sm/ratingcategories?cachebuster=${now}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (ratingCategories) => ({ ok: true, ratingCategories }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const uploadPMSupportAttachment = (formData, applicantID) => {
  const url = `${apiBaseUrl}sm/reputation/attachments/applicant/${applicantID}`
  const options = {
    method: 'POST',
    body: formData,
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
            statusText: res.statusText,
          }),
    )
    .then(
      (_) => ({ ok: true, _ }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}
export const getPMSupportAttachments = (applicantID) => {
  const url = `${apiBaseUrl}sm/reputation/attachments/applicant/${applicantID}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (pmSupportDocs) => ({ ok: true, pmSupportDocs }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const removePMSupportAttachment = (attachmentID) => {
  const url = `${apiBaseUrl}sm/reputation/attachments/${attachmentID}/applicant`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
            statusText: res.statusText,
          }),
    )
    .then(
      (_) => ({ ok: true, _ }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
        statusText: error.statusText,
      }),
    )
}

export const sendSubmitApplicationReminder = (applicationGUID) => {
  const url = `${apiBaseUrl}sm/applications/${applicationGUID}/reminder`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const inviteApplicant = (applicationGUID) => {
  const url = `${apiBaseUrl}sm/applications/${applicationGUID}/applynotification`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const callDeclineApplication = (
  agencyGUID,
  applicationID,
  declineReasons,
  notifyApplicant,
  otherReasonComment,
) => {
  const url = `${apiBaseUrl}sm/team/${agencyGUID}/application/${applicationID}/decline`
  const requestBody = {
    declineReasons,
    notifyApplicant,
    otherReasonComment,
  }
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(requestBody),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve() // We get nothing for now, when a review is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getRentAndEmploymentSummary = (applicationGUID) => {
  const url = `${apiBaseUrl}sm/applications/${applicationGUID}/applicationsummary`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (summary) => ({ ok: true, summary }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const editRentAndEmploymentSummary = (applicationGUID, request) => {
  const url = `${apiBaseUrl}sm/applications/${applicationGUID}/editsummary`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(request),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve() // We get nothing for now, when a review is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
