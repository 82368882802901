import React from 'react'

import { Link } from 'react-router-dom'

import AttachedFiles from 'app/sm/common/attached_files'
import AutocompletePeople from 'app/sm/common/autocomplete_people'

class NewMessage extends React.Component {
  closeModal() {
    window.location.href = `/sm/chat`
  }

  render() {
    return (
      <div className="sm-modal">
        <div className="sm-modal-wrapper sm-modal-wide">
          <div onClick={() => this.closeModal()} className="sm-modal-close" />
          <div className="sm-modal-content overflow-visible">
            <h3 className="mb30">
              <i className="icon-mail ibm mr15"></i>
              <span className="ibm pb3">New Message</span>
            </h3>

            <div className="full-width pb7 bbs">
              <AutocompletePeople placeholder="To" />
            </div>

            <div className="full-width bbs">
              <div className="input-box single-no-border pt0">
                <input
                  type="text"
                  required
                  placeholder="Subject"
                  className="ha pt20 pb20"
                />
              </div>
            </div>

            <div className="full-width bbs">
              <div className="input-box single-no-border">
                <textarea
                  required
                  placeholder="Write Message"
                  className="no-resize lh14 mh25vh xs-mh35vh"
                ></textarea>
              </div>
              <AttachedFiles />
            </div>

            <div className="row mt30">
              <div className="col-xs-4 col-sm-6">
                <button className="btn btn-white btn-narrow wa pl0">
                  <i className="icon-attach fs20"></i>
                </button>
                <button className="btn btn-white btn-narrow wa pl0 pr0">
                  <i className="icon-emojis fs20"></i>
                </button>
              </div>
              <div className="col-xs-8 col-sm-6 text-right">
                <button className="btn btn-wide wa">
                  <span>Send</span>
                  <i className="icon-send ml20"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewMessage
