import React from 'react'

import { Link } from 'react-router-dom'

import iconDocumentDoc from 'app/assets/icons/icon-document-doc.svg'
import iconDocumentImg from 'app/assets/icons/icon-document-img.svg'
import iconDocumentPdf from 'app/assets/icons/icon-document-pdf.svg'
import SearchInline from 'app/sm/common/search-inline'
import * as MockData from 'app/sm/property_documents/property_documents_mock_data'

function PropertyDocumentsContainer() {
  const documentIcons = {
    doc: iconDocumentDoc,
    img: iconDocumentImg,
    pdf: iconDocumentPdf,
  }
  return (
    <div className="sm-property-documents">
      <div className="section-title xs-row">
        <div className="left">
          <Link to={'/'} className="lh1">
            <h3 className="lh1">
              <i className="icon-arrow-left fs16 pt2 pr10 xs-ibm"></i>
              <span className="ibm lh1">Documents (7)</span>
            </h3>
          </Link>
        </div>
        <div className="right">
          <SearchInline placeholder="Search..." className="xs-hide" />
        </div>
      </div>
      <div className="sm-file-upload single">
        <form>
          <input id="file" type="file" />
          <label htmlFor="file">
            <i className="icon-documents"></i>
            <b>Upload manually</b>
            <span> or drag and drop documents here.</span>
          </label>
        </form>
      </div>
      <table className="style3">
        <thead>
          <tr>
            <td colSpan="2">Name</td>
            <td>Uploaded by</td>
            <td>Date</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {MockData.Documents.map((doc, index) => (
            <tr key={index}>
              <td className="pr0 w1p">
                <img src={documentIcons[doc.type]} height="25" />
              </td>
              <td>
                <a className="gray-dark-color" href="#">
                  {doc.name}
                </a>
              </td>
              <td>
                {doc.avatarImage != '' ? (
                  <div
                    className="avatar avatar-xsmall mr10"
                    style={{ backgroundImage: `url('${doc.avatarImage}')` }}
                  ></div>
                ) : (
                  <div className="avatar avatar-xsmall mr10 blue-bg-light-rgba blue-color">
                    {doc.userFirstname.charAt(0)}
                    {doc.userLastname.charAt(0)}
                  </div>
                )}
                {doc.userFirstname} {doc.userLastname.charAt(0)}.
                {doc.userSufix != '' && <span> ({doc.userSufix})</span>}
              </td>
              <td>on {doc.date}</td>
              <td>
                <div className="dropdown actions">
                  <div className="dropdown-toggle" data-toggle="dropdown">
                    <button className="btn btn-round btn-medium btn-gray-light">
                      <i className="icon-more"></i>
                    </button>
                  </div>
                  <ul className="dropdown-menu dropdown-menu-right mt5">
                    <li>
                      <Link to={'/'}>View</Link>
                    </li>
                    <li>
                      <Link to={'/'}>Edit</Link>
                    </li>
                    <li>
                      <Link to={'/'}>Delete</Link>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PropertyDocumentsContainer
