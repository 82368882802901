import React from 'react'

import Signature from 'app/bond_cover/admin/requests/signature_widget'

const Manager = ({ manager, managerSignature }) => {
  let managerHtml = (
    <div className="contentBlock">
      <header>
        <span>No Manager Record Found</span>
      </header>
    </div>
  )
  if (manager && manager.guidID) {
    let { guidID, agencyName, firstName, lastName, email, mobile } = manager
    managerHtml = (
      <div className="contentBlock">
        <header>
          <span>Manager</span>
        </header>
        <content>
          <p className="mb0">
            Agency Name: {agencyName}
            <br />
            Full Name: {firstName} {lastName}
            <br />
            Email: {email}
            <br />
            Mobile:{mobile}
            <br />
          </p>
          <Signature signature={managerSignature} />
        </content>
      </div>
    )
  }
  return <div>{managerHtml}</div>
}

export default Manager
