import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  deleteSingleStudentDetail,
  fetchStudentDetails,
} from 'app/sm/student_details/student_details_access'

export const getStudentDetails = () => (dispatch) => {
  return fetchStudentDetails().then(({ ok, responseText, studentInfo }) => {
    if (ok) {
      return Promise.resolve({ studentInfo })
    } else {
      return responseText.then((t) => {
        return Promise.reject(translateErrorCodeToMessage(t))
      })
    }
  })
}

export const removeSingleStudentDetail = (studentDetailId) => (dispatch) => {
  return deleteSingleStudentDetail(studentDetailId).then(
    ({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      }
    },
  )
}
