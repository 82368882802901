import { PERSONAL_REFERENCE_SURVEY_ORIGINAL_VERSION } from 'app/constants/personal_reference/survey/survey-questions.constants'
import { api } from 'app/utils/api/helpers'

const personalRefApiBaseUrl = '/sm/personalreferences'

export const submitPersonalReferenceSurveyResponse = (
  personalReferenceID,
  version = PERSONAL_REFERENCE_SURVEY_ORIGINAL_VERSION,
  surveyAnswers,
  secret,
) => {
  return api.post(
    `${personalRefApiBaseUrl}/${personalReferenceID}/survey-response?secret=${secret}`,
    {
      version,
      surveyAnswers,
    },
  )
}

export const getPersonalReferenceFullInfoForSurvey = (
  personalReferenceID,
  secret,
) => {
  return api.get(
    `${personalRefApiBaseUrl}/${personalReferenceID}/review?secret=${secret}`,
  )
}

export const getPersonalReferenceReviewForManager = (personalReferenceID) => {
  return api.get(
    `${personalRefApiBaseUrl}/${personalReferenceID}/review/manager`,
  )
}

export const submitPersonalReferenceSurveyByManager = (
  personalReferenceID,
  version = PERSONAL_REFERENCE_SURVEY_ORIGINAL_VERSION,
  surveyAnswers,
) => {
  return api.post(
    `${personalRefApiBaseUrl}/${personalReferenceID}/survey-response/manager`,
    {
      version,
      surveyAnswers,
    },
  )
}

export const sendPersonalReferenceRequestToReference = (
  personalReferenceID,
  email,
  message,
  agencyGUID,
) => {
  return api.post(
    `${personalRefApiBaseUrl}/${personalReferenceID}/send-survey/${agencyGUID}`,
    { email, message, agencyGUID },
  )
}

export const fetchPersonalReferenceReviewByShortCode = (shortCode) => {
  return api.get(
    `${personalRefApiBaseUrl}/${shortCode}/review/reviewbyshortcode`,
  )
}
