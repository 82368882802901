import React from 'react'

const WithdrawButton = ({ onWithdrawClicked }) => {
  return (
    <div className="mt40 mb10 mw890 clearfix">
      <div className="pull-left">
        <div className="gray-text linkStyle" onClick={onWithdrawClicked}>
          Withdraw Your Application
        </div>
      </div>
    </div>
  )
}

export default WithdrawButton
