import React, { useEffect, useState } from 'react'

import {
  Box,
  FilesDropzone,
  Modal,
} from 'app/components/design-system-components'
import { Flex, Spinner } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import SelfTenancyDocList from 'app/sm/background_check/SelfTenancyCheck/DocList'
import {
  deleteSelfTenancyCheck,
  getSelfTenancyCheck,
  uploadSelfTenancyCheck,
} from 'app/sm/background_check/SelfTenancyCheck/upload_tenancy_check_http'
import { urlIds, urlTo } from 'app/sm/helpers'
import { useAttachments } from 'app/utils/hooks/useAttachments'

function UploadOwnTenancyCheckModal({
  toggleShowUploadOwnTenancyCheckModal,
  onClickSaveForSelfTenancyCheck,
}) {
  const [selfTenancyCheckDoc, setSelfTenacyCheckDoc] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)

  const getAndSetTenancyCheck = () => {
    getSelfTenancyCheck()
      .then((data) => {
        const { SelfTenancyCheckDoc = [] } = data
        setSelfTenacyCheckDoc(SelfTenancyCheckDoc)
      })
      .catch(({ plainError, message }) => {
        if (plainError?.response?.status === 401) {
          window.location.replace(urlTo(urlIds.join.index))
        }
        message && snugNotifier.error(message)
      })
  }

  useEffect(() => {
    getAndSetTenancyCheck()
  }, [])

  const uploadImageFn = (file) => {
    setShowSpinner(true)
    return uploadSelfTenancyCheck(file)
      .then(() => {
        snugNotifier.success('Tenancy Document successfully uploaded')
        getAndSetTenancyCheck()
      })
      .catch((error) => snugNotifier.error(error.message))
      .finally(() => setShowSpinner(false))
  }

  const deleteImageFn = (imageId) => {
    return
  }

  const deleteCheck = (id) => {
    setShowSpinner(true)
    deleteSelfTenancyCheck(id)
      .then(() => {
        snugNotifier.success('Tenancy Document successfully deleted')
        getAndSetTenancyCheck()
      })
      .catch((error) => snugNotifier.error(error.message))
      .finally(() => setShowSpinner(false))
  }

  const onImageAdded = (fileId) => {
    const attachedImage = images[fileId]
    if (attachedImage.file.guid) return
    uploadImage(fileId)
  }

  const closeButtonAction = () => {
    if (selfTenancyCheckDoc && selfTenancyCheckDoc.length > 0) {
      setShowSpinner(true)
      selfTenancyCheckDoc.forEach((doc) => {
        const { guidID } = doc
        deleteSelfTenancyCheck(guidID)
          .then(() => {
            snugNotifier.success('Tenancy Document successfully deleted')
          })
          .catch((error) => snugNotifier.error(error.message))
          .finally(() => setShowSpinner(false))
      })
    }
    toggleShowUploadOwnTenancyCheckModal()
  }

  const {
    attachments: images,
    addAttachment: addImage,
    uploadAttachment: uploadImage,
  } = useAttachments(uploadImageFn, deleteImageFn, onImageAdded)
  const modalBody = (
    <Box width="100%">
      <Box mb={6} mt={3}>
        Upload a current tenancy check you have purchased.
      </Box>

      {showSpinner ? (
        <Flex justifyContent="center">
          <Spinner
            width={theme.width[8] + 'px'}
            height={theme.height[8] + 'px'}
          />
        </Flex>
      ) : (
        <>
          {selfTenancyCheckDoc && selfTenancyCheckDoc.length === 0 && (
            <FilesDropzone onDrop={(file) => addImage(file)} />
          )}
          {selfTenancyCheckDoc && (
            <SelfTenancyDocList
              selfTenancyCheckDoc={selfTenancyCheckDoc}
              onRemoveButtonClicked={deleteCheck}
            />
          )}
        </>
      )}
    </Box>
  )
  return (
    <>
      <Modal
        modalBody={modalBody}
        toggleModal={toggleShowUploadOwnTenancyCheckModal}
        modalHeading="Upload tenancy check"
        secondaryLabel="Close"
        primaryLabel="Save"
        secondaryAction={closeButtonAction}
        primaryAction={() => {
          toggleShowUploadOwnTenancyCheckModal()
          onClickSaveForSelfTenancyCheck()
        }}
      />
    </>
  )
}

export default UploadOwnTenancyCheckModal
