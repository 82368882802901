import styled from 'styled-components'
import { color, space, typography } from 'styled-system'

import { textTransform } from 'app/components/design-system-components/styled-system/custom-props/text'

const TextSpan = styled.span`
  ${textTransform} // Missing in TextSpanProps
  ${typography}
  ${color}
  ${space}
`
export default TextSpan
