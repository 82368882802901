import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import { capitalizeFirstLetter } from 'app/sm/helpers'
import { formatPhoneNumber } from 'app/utils/phonenumber/helpers'
import * as sensitiveInfoHelpers from 'app/utils/sensitiveInfo/helper'

const GeneralInformationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: ${({ theme }) =>
    `${theme.dividedSpace[2]}px 0 ${theme.dividedSpace[6]}px 0`};
  @media print {
    padding: ${({ theme }) =>
      `${theme.dividedSpace[2]}px 0 ${theme.dividedSpace[5]}px 0`};
  }
  @media screen and (max-width: 576px) {
    display: block;
  }
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`

const TextWrapperBig = styled.div`
  display: flex;
  min-height: 65px;
  align-items: end;
`

const TextLabel = styled(Text)`
  min-width: 150px;
  font-weight: ${({ theme }) => `${theme.fontWeights[6]}`};
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[3]}px`};
`
const TextContent = styled(Text)`
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[3]}px`};
  border-bottom: 0.5px solid rgba(157, 154, 176, 1);
  width: 80%;
`

const TextContentName = styled(Text)`
  margin-bottom: ${({ theme }) => `${theme.dividedSpace[3]}px`};
  border-bottom: 0.5px solid rgba(157, 154, 176, 1);
  font-weight: ${({ theme }) => `${theme.fontWeights[6]}`};
  font-size: ${({ theme }) => `${theme.fontSizes[18]}px`};
  line-height: 33.6px;
  width: 80%;
`

const GeneralWrapper = styled(Box)`
  @media screen and (max-width: 576px) {
    width: 100% !important;
  }
`

const GeneralInformation = ({
  IdInfo = {},
  identityVerification = {},
  currentAddress = {},
  email = '',
  mobile = '',
}) => {
  const {
    DateOfBirth = '',
    DriversNicenceNumber = 0,
    IndividualName = '',
    PassportNumber = '',
  } = IdInfo || {}

  const { result } = identityVerification

  const { FirstGivenName = '', FamilyName = '' } = IndividualName

  const {
    StreetNumber = '',
    StreetName = '',
    State = '',
    PostCode = '',
    Suburb = '',
  } = currentAddress

  const friendlyFullName = `${capitalizeFirstLetter(
    FirstGivenName,
  )} ${capitalizeFirstLetter(FamilyName)}`

  const friendlyCurrentAddress = `${StreetName ? StreetNumber : ''}${
    StreetName ? ` ${StreetName},` : ''
  } ${Suburb}, ${State} ${PostCode}`

  const friendlyDateOfBirth = moment(DateOfBirth).format('DD MMM YYYY')

  const formattedMobileNumber = formatPhoneNumber(mobile)

  const idVerifiedLabel = result === true ? ' (Verified)' : ' (Unverified)'
  const idDocumentNumberLabel = `${sensitiveInfoHelpers.hashSensitiveNumber(
    DriversNicenceNumber ? DriversNicenceNumber : PassportNumber,
  )}${idVerifiedLabel}`

  return (
    <GeneralInformationContainer>
      <GeneralWrapper width="60%">
        <TextWrapperBig>
          <TextLabel>Name:</TextLabel>
          <TextContentName>{friendlyFullName}</TextContentName>
        </TextWrapperBig>
        <TextWrapper>
          <TextLabel>Address:</TextLabel>
          <TextContent>{friendlyCurrentAddress}</TextContent>
        </TextWrapper>
        <TextWrapper>
          <TextLabel>Email:</TextLabel>
          <TextContent>{email}</TextContent>
        </TextWrapper>
      </GeneralWrapper>
      <GeneralWrapper width="33%">
        <TextWrapperBig>
          <TextLabel>DOB:</TextLabel>
          <TextContent>{friendlyDateOfBirth}</TextContent>
        </TextWrapperBig>
        <TextWrapper>
          <TextLabel>Mobile:</TextLabel>
          <TextContent>{formattedMobileNumber}</TextContent>
        </TextWrapper>
        {(DriversNicenceNumber || PassportNumber) && (
          <TextWrapper>
            <TextLabel>
              {DriversNicenceNumber ? 'Driver Licence' : 'Passport'}:
            </TextLabel>
            <TextContent>{idDocumentNumberLabel}</TextContent>
          </TextWrapper>
        )}
      </GeneralWrapper>
    </GeneralInformationContainer>
  )
}

export default GeneralInformation
