import React from 'react'

import moment from 'moment'

import { Alert, Box, TextSpan } from 'app/components/design-system-components'
import { fontSizes } from 'app/components/design-system-components/styles/variables'
import * as Display from 'app/components/display/display'
import * as Headers from 'app/components/display/text/standard_text/standard-headings/component'
import * as Form from 'app/components/forms/forms'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import StandardScreen from 'app/shared_components/layout_component/standard_screen_layout_component'
import { history } from 'app/shared_components/router'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import * as helpers from 'app/sm/helpers'
import PetReference from 'app/sm/pets_details/components/pets_details_add_file_reference'
import { initialValidations } from 'app/sm/pets_details/pet_helper'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import { isCurrentRoute } from 'app/sm/router_helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as textHelpers from 'app/utils/text/helpers'

import 'app/profile/renter/pet/styles.scss'

const defaultPetPayload = (userGuidID) => ({
  guidID: userGuidID,
  name: '*',
  petDateOfBirth: '2000-01-01',
  type: 3,
  breed: '*',
  indoor: true,
})

class AddPetDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      type: 0,
      petDateOfBirth: '',
      breed: '',
      indoor: false,
      outdoor: false,
      isDesexed: false,
      isRegistered: false,
      registrationNumber: '',
      saveClicked: false,
      editable: false,
      petGuidID: null,
      unsavedChanges: false,
      validations: initialValidations,
      errors: {
        name: '',
        type: '',
        petDateOfBirth: '',
        breed: '',
        registrationNumber: '',
      },
      description: '',
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup)
    const {
      fetchPet,
      match: {
        params: { petId },
      },
    } = this.props
    if (petId) {
      fetchPet(petId)
        .then((pet) => {
          if (pet && pet.guidID) {
            this.setState({
              name: pet.name,
              type: pet.type,
              breed: pet.breed,
              petDateOfBirth: moment(pet.dateOfBirth, helpers.dateWithDash),
              editable: true,
              indoor: pet.indoor,
              outdoor: pet.outdoor,
              description: pet.description,
              isDesexed: pet.isDesexed,
              isRegistered: pet.isRegistered,
              registrationNumber: pet.registrationNumber,
            })
          }
        })
        .catch((error) => snugNotifier.error(error))
    }
    if (isCurrentRoute('addPetDetails')) {
      const {
        addPet,
        user: { guidID: userGuidID },
      } = this.props
      addPet(defaultPetPayload(userGuidID))
    }
  }

  componentDidUpdate(prevProps) {
    const {
      pet,
      match: {
        params: { petId },
      },
    } = this.props
    const { name, breed } = this.state
    if (!prevProps.pet.guidID && pet.guidID) {
      if (isCurrentRoute('addPetDetails') && pet.guidID) {
        this.setState({ petGuidID: pet.guidID })
        return
      }
      if (pet.guidID && petId) {
        if (name === '' && breed === '') {
          this.setState({
            name: pet.name,
            type: pet.type,
            breed: pet.breed,
            petDateOfBirth: moment(pet.dateOfBirth, helpers.dateWithDash),
            editable: true,
            indoor: pet.indoor,
            outdoor: pet.outdoor,
            description: pet.description,
            isDesexed: pet.isDesexed,
            isRegistered: pet.isRegistered,
            registrationNumber: pet.registrationNumber,
          })
        }
      }
    }
    if (
      prevProps.responseText !== this.props.responseText &&
      this.props.responseText !== ''
    ) {
      snugNotifier.error(this.props.responseText)
    }
  }

  componentWillUnmount() {
    this.componentCleanup()
  }

  componentCleanup = () => {
    const { petGuidID, saveClicked } = this.state
    if (petGuidID && !saveClicked) {
      this.props.removePet(petGuidID)
    }
  }

  submit = () => {
    const { user, editPet, pet } = this.props
    const isValid = this.validateRequiredFields()
    const payload = Object.assign({}, this.state, {
      guidID: user.guidID,
    })
    if (!!payload.petDateOfBirth) {
      const isBirthDateValid =
        this.state.petDateOfBirth &&
        dateTimeHelpers.checkIfPetYOBIsValid(this.state.petDateOfBirth)
      if (isBirthDateValid) {
        payload.petDateOfBirth = moment(this.state.petDateOfBirth).format(
          helpers.dateWithDash,
        )
      } else payload.petDateOfBirth = ''
    }
    const data = {
      UserID: payload.guidID,
      Name: payload.name,
      DateOfBirth: payload.petDateOfBirth,
      Type: Number(payload.type),
      Breed: payload.breed,
      Indoor: payload.indoor,
      Outdoor: payload.outdoor,
      Description: payload.description,
      IsDesexed: payload.isDesexed,
      IsRegistered: payload.isRegistered,
      RegistrationNumber: payload.isRegistered
        ? payload.registrationNumber
        : '',
    }
    if (isValid) {
      this.setState({ saveClicked: true, unsavedChanges: false })
      editPet(data, pet.guidID)
    }
  }

  updateField = (field) => {
    return (data) => {
      if (field === 'type') {
        this.setState({
          [field]: parseInt(data.value, 10),
          errors: {
            ...this.state.errors,
            [field]: data.error,
          },
        })
      } else {
        let value = data.value
        if (data.error === '' && field === 'petDateOfBirth') {
          value =
            value !== ''
              ? moment(value).format(dateTimeHelpers.DATE_WITH_DASH)
              : ''
        }
        this.setState(
          {
            [field]: value,
            errors: {
              ...this.state.errors,
              [field]: data.error,
            },
          },
          () => {
            this.validate(field, this.state[field])
          },
        )
      }
    }
  }

  validate = (field, value) => {
    let errorMessage
    switch (field) {
      case 'name':
        if (value.length === 0) {
          errorMessage = `${field.charAt(0).toUpperCase()}${field.slice(
            1,
          )} is a required field`
        }
        break
      case 'type':
        if (value === 0) {
          errorMessage = `${field.charAt(0).toUpperCase()}${field.slice(
            1,
          )} is a required field`
        }
        break
      case 'petDateOfBirth':
        if (
          moment(
            value,
            dateTimeHelpers.DATE_DAY_SHORT_STRING_MONTH_YEAR,
          ).isAfter(moment())
        ) {
          errorMessage = `Date must be in the past`
        }
        break
      case 'breed':
        if (value.length === 0) {
          errorMessage = `${field.charAt(0).toUpperCase()}${field.slice(
            1,
          )} is a required field`
        }
        break
      case 'registrationNumber':
        if (this.state.isRegistered && !this.state.registrationNumber) {
          errorMessage = 'This field cannot be empty'
        }
        break
      default:
        break
    }
    return errorMessage
  }

  validateRequiredFields() {
    const errors = {}
    Object.keys(this.state.errors).forEach((field) => {
      const errorMessage = this.validate(field, this.state[field])
      if (errorMessage) {
        errors[field] = errorMessage
        snugNotifier.error(errorMessage)
      }
      if (this.state.errors[field] !== '' && this.state.errors[field] !== 0) {
        errors[field] = this.state.errors[field]
      }
    })
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    })
    return Object.keys(errors).length === 0
  }

  render() {
    const {
      validations,
      editable,
      petGuidID,
      indoor = false,
      outdoor = false,
      isDesexed = false,
      isRegistered = false,
      registrationNumber = '',
      saveClicked,
      unsavedChanges,
      description,
    } = this.state
    const {
      spinner,
      pet,
      uploadPetReference,
      removePetReference,
      responseText,
      match: {
        params: { petId },
      },
    } = this.props
    const addPetsTagClass = isCurrentRoute('addPetDetails')
      ? 'pet-details-added'
      : ''
    const isEdit = `${petId ? 'Edit' : 'Add'}`
    const { property } = this.props
    const inputBoxesForCheckboxes = {
      registration: {
        label: 'Registration number',
        field: 'registrationNumber',
        value: registrationNumber,
      },
    }
    const checkboxes = [
      {
        text: 'Indoor',
        field: 'indoor',
        value: indoor,
        hasInputBox: false,
        inputBox: '',
      },
      {
        text: 'Outdoor',
        field: 'outdoor',
        value: outdoor,
        hasInputBox: false,
        inputBox: '',
      },
      {
        text: 'Desexed',
        field: 'isDesexed',
        value: isDesexed,
        hasInputBox: false,
        inputBox: '',
      },
    ]
    const isBirthDateValid =
      this.state.petDateOfBirth &&
      dateTimeHelpers.checkIfPetYOBIsValid(this.state.petDateOfBirth)

    let formattedPetDateOfBirth = ''
    if (isBirthDateValid) {
      formattedPetDateOfBirth =
        this.state.errors.petDateOfBirth !== dateTimeHelpers.INVALID_DATE_STRING
          ? this.state.petDateOfBirth &&
            moment(this.state.petDateOfBirth).year()?.toString()
          : this.state.petDateOfBirth
    }
    return (
      <StandardScreen>
        <div className="row m0">
          <RentalReputationHeaderReminder
            property={property}
            backUrl="/"
            remindText="Current application"
            isFromApplicationForm=""
            title="Pet Details"
            thirdTierText={isEdit}
          />
          <Headers.SectionHeader
            text="Tell us about your pet"
            hasSeperator={false}
            textClass="pl0"
          />

          <Box>{textHelpers.PETS_DETAIL_HEADER_GUIDANCE}</Box>

          <Alert
            my={8}
            variant="blueWithBg"
            fontSize={theme.fontSizes.pRegular14}
            showLeftIcon
            leftIconVariant="info"
          >
            {textHelpers.SHARED_PETS_ALERT}
          </Alert>

          <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
            <div className="col-md-6 p0 width100">
              <Box
                fontSize={fontSizes.pLarge16}
                fontWeight={theme.fontWeights.h3}
              >
                Name{' '}
                <TextSpan as="span" color="red">
                  *
                </TextSpan>
              </Box>
              <Form.InputName
                value={this.state.name}
                error={this.state.errors.name}
                onChange={this.updateField('name')}
                id="name"
                inputClass="width100"
                componentClass="margin-profile-item-left"
              />
            </div>
            <div className="col-md-6 p0 width100">
              <Box
                ml={4}
                fontSize={fontSizes.pLarge16}
                fontWeight={theme.fontWeights.h3}
              >
                Breed{' '}
                <TextSpan as="span" color="red">
                  *
                </TextSpan>
              </Box>
              <Form.InputName
                value={this.state.breed}
                error={this.state.errors.breed}
                onChange={this.updateField('breed')}
                id="Breed"
                inputClass="width100"
                componentClass="margin-profile-item-right"
              />
            </div>
          </Form.ProfilePagesRowLayout>
          <Box width="49%" mb={8}>
            <Box
              fontSize={fontSizes.pLarge16}
              fontWeight={theme.fontWeights.h3}
            >
              Type{' '}
              <TextSpan as="span" color="red">
                *
              </TextSpan>
            </Box>
            <Form.DropdownRelationship
              label="Type"
              value={this.state.type}
              error={this.state.errors.type}
              options={['Select Type', 'Dog', 'Cat', 'Others']}
              onChange={this.updateField('type')}
              id="type"
              inputClass="width100"
            />
          </Box>
          <Form.ProfilePagesRowLayout>
            <div className="col-md-12 p0 width100">
              <Box
                fontSize={fontSizes.pLarge16}
                fontWeight={theme.fontWeights.h3}
              >
                Identifying features (eg. sex, fur/colour, other features):{' '}
              </Box>
              <Form.CommentArea
                value={description}
                error={this.state.errors.description}
                onChange={this.updateField('description')}
                id="description"
                containerClassName="width100"
                rows={3}
              />
            </div>
          </Form.ProfilePagesRowLayout>
        </div>

        {!validations.type.ok && (
          <div className="alert alert-danger">{validations.type.error}</div>
        )}
        <Display.ContainerHeader
          text="Other information (optional)"
          isEditable={false}
          componentClass="mb24"
        />
        <Box>
          <Box mb={6}>
            <Form.CheckBoxGeneral
              label="Registered"
              checked={isRegistered || false}
              onChange={this.updateField('isRegistered')}
            />
            {isRegistered && (
              <Box width="50%">
                <Form.InputTextRequired
                  label={inputBoxesForCheckboxes.registration.label}
                  value={inputBoxesForCheckboxes.registration.value}
                  error={
                    this.state.errors[
                      inputBoxesForCheckboxes.registration.field
                    ]
                  }
                  onChange={this.updateField(
                    inputBoxesForCheckboxes.registration.field,
                  )}
                  id={inputBoxesForCheckboxes.registration.field}
                  inputClass="width100"
                />
              </Box>
            )}
          </Box>
        </Box>
        <div className="row m0 m mb8">
          <div className="col-sm-12 p0 flex-wrap">
            {checkboxes.map((checkbox, index) => {
              const inputBox = inputBoxesForCheckboxes[checkbox.inputBox]
              const showInputBox =
                checkbox.value && checkbox.hasInputBox && inputBox
              return (
                <div key={index} className={`col-sm-12 pl0`}>
                  <div>
                    <Form.CheckBoxGeneral
                      label={checkbox.text}
                      checked={checkbox.value || false}
                      onChange={this.updateField(checkbox.field)}
                      componentClassName={`pl0 ${
                        checkbox.hasInputBox && checkbox.value ? '' : 'mb24'
                      }`}
                    />
                  </div>
                  {showInputBox && (
                    <Form.InputTextRequired
                      label={inputBox.label}
                      value={inputBox.value}
                      error={this.state.errors[inputBox.field]}
                      onChange={this.updateField(inputBox.field)}
                      id={inputBox.field}
                      inputClass="width100"
                      componentClass="margin-profile-item-left mb24 col-sm-6 pl0"
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
        <Box width="49%" mb={8}>
          <Box fontSize={fontSizes.pLarge16} fontWeight={theme.fontWeights.h3}>
            Birth Year{' '}
          </Box>
          <Form.InputPastDate
            value={formattedPetDateOfBirth}
            error={this.state.errors.petDateOfBirth}
            onChange={this.updateField('petDateOfBirth')}
            id="petDateOfBirth"
            containerClassName="width100"
            componentClass="margin-profile-item-left"
            customisedDateFormat="YYYY"
            placeholder="yyyy"
            readOnly={false}
          />
        </Box>
        {(petGuidID || pet.guidID) && (
          <Display.ContainerHeader
            text="Attach a photo and pet reference"
            isEditable={false}
            componentClass="mb24"
          />
        )}

        <div className="row m0">
          {(petGuidID || pet.guidID) && (
            <PetReference
              uploadPetReference={uploadPetReference}
              removePetReference={removePetReference}
              spinner={spinner}
              editable={editable}
              petGuidID={petGuidID}
              editGuidID={pet.guidID}
              pet={pet}
              responseText={responseText}
            />
          )}
        </div>

        <div className="row pt32">
          <Display.SnugTip
            text={
              <div>
                Accepting a pet is at the property owner's discretion in
                accordance with&nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={helpers.PETS_ARTICLE_URL}
                >
                  residential tenancy regulations
                </a>
              </div>
            }
          />
        </div>

        <div className="row pt25">
          <div className="col-sm-5 pb5 pl0 tablet-only">
            <button
              onClick={() => history.goBack()}
              className="btn btn-transparent btn-left xs-text-center wa"
            >
              <i className="icon-arrow-left left" />
              <span>Back</span>
            </button>
          </div>

          <div className="col-sm-7 pet-save pb5">
            <button className={`btn ${addPetsTagClass}`} onClick={this.submit}>
              Save
              <i className={saveClicked ? 'fa fa-spinner fa-pulse' : ''} />
            </button>
          </div>
        </div>

        <UnsavedMessage unsavedChanges={unsavedChanges} />
      </StandardScreen>
    )
  }
}

export default AddPetDetails
