import { LOCATION_CHANGE } from 'connected-react-router'

import {
  RECEIVE_PET,
  RECEIVE_PET_DOCUMENTS,
  RECEIVE_PETS,
  RECEIVE_RESPONSE_TEXT,
  TOGGLE_SPINNER,
} from 'app/sm/pets_details/pets_details_add_actions'

const defaultState = {
  petList: [],
  pet: {},
  spinner: false,
  responseText: '',
}

const AddPetDetails = (state = defaultState, action) => {
  let { type, petList, pet, petDocs, responseText } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case RECEIVE_PETS:
      return Object.assign(newState, { petList, responseText: '' })
    case TOGGLE_SPINNER:
      return Object.assign(newState, { spinner: !newState.spinner })
    case RECEIVE_RESPONSE_TEXT:
      return Object.assign(newState, { responseText })
    case RECEIVE_PET:
      return Object.assign(newState, { pet })
    case RECEIVE_PET_DOCUMENTS:
      return Object.assign(newState, {
        pet: Object.assign({}, newState.pet, { petReferences: petDocs }),
      })
    case LOCATION_CHANGE:
      return Object.assign(newState, { spinner: false })
    default:
      return state
  }
}

export default AddPetDetails
