import { useState } from 'react'

import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  Box,
  Flex,
  HorizontalLine,
  IconButton,
  TextSpan,
} from 'app/components/design-system-components'
import { DeleteOutlineRounded } from 'app/components/design-system-components/icons/actions'
import { SimpleOptionContainer } from 'app/components/design-system-components/inputs/Select/Select'
import type { OptionProp } from 'app/components/design-system-components/inputs/types'
import OverlayPortalModal from 'app/components/design-system-components/modals/OverlayPortalModal'
import { DeleteBorrowerConfirmation } from 'app/features/teams/key-logger/CheckoutKeySetModal/AddressBorrowerSelection/DeleteBorrowerModals/DeleteBorrowerConfirmation'
import { DisallowedBorrowerDeletion } from 'app/features/teams/key-logger/CheckoutKeySetModal/AddressBorrowerSelection/DeleteBorrowerModals/DisallowedBorrowerDeletion'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { formatPhoneNumberWithoutCountryCode } from 'app/utils/phonenumber/helpers'
import { trimAndAddEllipsesPastSpecifiedLength } from 'app/utils/strings/helpers'

const VerticalLineCmp = styled(Box)`
  border-left: 1px solid ${(props) => props.theme.colors.gray400};
  height: 15px;
`

type Props = {
  option: OptionProp
  onClick: () => void
  selected: boolean
  isLast: boolean
  currentTeam: any
  onDeletion: (guid: string) => void
}

const BorrowerSelectionOption = ({
  option,
  onClick,
  selected,
  isLast,
  currentTeam,
  onDeletion,
  ...props
}: Props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [deleteConfirmationModalOpened, setDeleteConfirmationModalOpened] =
    useState<boolean>(false)
  const [deletionDisallowedModalOpened, setDeletionDisallowedModalOpened] =
    useState<boolean>(false)

  const borrower = option.option
  const borrowedKeysCount = borrower.borrowed_keys?.length || 0

  const showEmailPhoneSeparator = !!borrower.email && !!borrower.phone
  const trimmedEmailChars = !!borrower.phone ? 30 : 45
  const trimmedDisplayedKeyChars =
    28 - (borrower.first_name.length + borrower.last_name.length)

  const renderBorrowerDetails = () => {
    const keyToDisplay =
      borrowedKeysCount > 0 ? borrower.borrowed_keys[0] : null

    return (
      <Box>
        <Flex>
          <TextSpan as="div" fontWeight={theme.fontWeights.bold}>
            {borrower.first_name} {borrower.last_name}
          </TextSpan>
          {!!keyToDisplay && (
            <Flex alignItems="center">
              <VerticalLineCmp mx={2} />
              <Box>
                {trimAndAddEllipsesPastSpecifiedLength(
                  keyToDisplay.label,
                  trimmedDisplayedKeyChars,
                )}{' '}
                ({borrowedKeysCount} borrowed)
              </Box>
            </Flex>
          )}
        </Flex>
        <Flex mt={2} color={theme.colors.skyBlue} alignItems="center">
          <Box>{formatPhoneNumberWithoutCountryCode(borrower.phone)}</Box>
          {showEmailPhoneSeparator && <VerticalLineCmp mx={2} />}
          <Box>
            {trimAndAddEllipsesPastSpecifiedLength(
              borrower.email,
              trimmedEmailChars,
            )}
          </Box>
        </Flex>
      </Box>
    )
  }

  const onBorrowerDeleteClicked = () => {
    if (borrowedKeysCount > 0) setDeletionDisallowedModalOpened(true)
    else setDeleteConfirmationModalOpened(true)
  }

  return (
    <>
      <Box>
        <HorizontalLine my={3} />
        <SimpleOptionContainer
          onClick={onClick}
          className={selected && 'selected'}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          {...props}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Box>{renderBorrowerDetails()}</Box>
            {isHovered && (
              <IconButton
                IconCmp={DeleteOutlineRounded}
                iconProps={{
                  fontSize: theme.fontSizes.pExtraLarge18,
                }}
                variant="flat"
                onClick={(e: Event) => {
                  e.stopPropagation()
                  onBorrowerDeleteClicked()
                }}
                px="0 !important"
              />
            )}
          </Flex>
        </SimpleOptionContainer>
        {isLast && <HorizontalLine my={3} />}
      </Box>
      {deleteConfirmationModalOpened && (
        <OverlayPortalModal>
          <DeleteBorrowerConfirmation
            borrower={borrower}
            teamID={currentTeam?.guid}
            onSubmission={() => onDeletion(borrower.guid)}
            closeModal={() => setDeleteConfirmationModalOpened(false)}
          />
        </OverlayPortalModal>
      )}
      {deletionDisallowedModalOpened && (
        <OverlayPortalModal>
          <DisallowedBorrowerDeletion
            closeModal={() => setDeletionDisallowedModalOpened(false)}
          />
        </OverlayPortalModal>
      )}
    </>
  )
}

const mapStateToProps = ({ session }: any) => ({
  currentTeam: session.currentTeam,
})

export default connect(mapStateToProps)(BorrowerSelectionOption)
