import React, { useState } from 'react'

import moment from 'moment'

import { Box, Modal } from 'app/components/design-system-components'
import * as Form from 'app/components/forms/forms'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as updateCurrentModalHelpers from 'app/profile/renter/updateCurrentModal/helpers'
import * as snugNotifier from 'app/services/snugNotifier'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

function UpdateCurrentModal({
  modalType,
  currentTextToAppend,
  timeFromNow,
  toggleModal,
  continueAction,
}) {
  const [stillAtX, setStillAtX] = useState(
    updateCurrentModalHelpers.stillAtXOptions.map((a) => Object.assign({}, a)),
  )
  const [atNewX, setAtNewX] = useState(
    updateCurrentModalHelpers.atNewXOptions.map((a) => Object.assign({}, a)),
  )

  const [stillAtXValue, setStillAtXValue] = useState('')
  const [atNewXValue, setAtNewXValue] = useState('')

  const [newEndDate, setNewEndDate] = useState({ value: '', error: '' })

  const validateAndContinue = () => {
    if (
      newEndDate.value === '' &&
      stillAtXValue === updateCurrentModalHelpers.STILL_AT_X_NO
    ) {
      snugNotifier.error('End Date cannot be empty')
      return
    }
    if (!newEndDate.error) {
      continueAction({
        needsToUpdateAndCallEndpoint:
          stillAtXValue === updateCurrentModalHelpers.STILL_AT_X_NO,
        needsToGoToAddPage:
          atNewXValue === updateCurrentModalHelpers.STILL_AT_X_YES,
        newEndDate,
      })
    } else {
      snugNotifier.error(newEndDate.error)
    }
    return
  }

  const updateDateInput = () => {
    return (data) => {
      const { value, error } = data
      setNewEndDate({ value, error })
    }
  }

  const updateMultiSelectOptions = (
    selectedMultiselect,
    value,
    updateMultiselectFunc,
  ) => {
    const updatedLikeAPersonOptions = Object.values(selectedMultiselect).map(
      (option) => {
        option.picked = value === option.value

        return option
      },
    )
    updateMultiselectFunc(updatedLikeAPersonOptions)
  }

  const formattedNewEndDate =
    newEndDate.value !== dateTimeHelpers.INVALID_DATE_STRING
      ? newEndDate.value &&
        moment(newEndDate.value).format(dateTimeHelpers.DATE_WITH_SLASH)
      : newEndDate.value

  const modalBody = (
    <div>
      {`It’s been ${timeFromNow} since you last updated your current ${
        modalType === updateCurrentModalHelpers.UPDATE_MODAL_TYPE_ADDRESS
          ? 'residential'
          : ''
      } ${
        updateCurrentModalHelpers.updateModalObj[modalType][
          updateCurrentModalHelpers.MODAL_OBJECT_ACTION
        ]
      }${
        updateCurrentModalHelpers.updateModalObj[modalType][
          updateCurrentModalHelpers.MODAL_OBJECT_APPEND_TO_FIRST_Q
        ]
      } ${currentTextToAppend}?`}
      <Form.MultiSelect
        options={stillAtX}
        onChange={(value) => {
          setStillAtXValue(value)
          updateMultiSelectOptions(stillAtX, value, setStillAtX)
        }}
        bottomSpacingClass="pb0"
        className="pr0 pl0"
      />
      {stillAtXValue === updateCurrentModalHelpers.STILL_AT_X_YES && (
        <div>
          {
            updateCurrentModalHelpers.updateModalObj[modalType][
              updateCurrentModalHelpers.MODAL_OBJECT_NO_NEED_TO_CHNG_TXT
            ]
          }
        </div>
      )}
      {stillAtXValue === updateCurrentModalHelpers.STILL_AT_X_NO && (
        <div>
          <Box mb={theme.space[5] + 'px'}>OK, When did you leave?</Box>
          <Box mb={theme.space[5] + 'px'}>
            <Form.InputPastDate
              label="Select Date"
              value={formattedNewEndDate}
              onChange={updateDateInput('newEndDate')}
              id="newEndDate"
              readOnly={false}
              error={newEndDate.error}
            />
          </Box>
          <div>
            {
              updateCurrentModalHelpers.updateModalObj[modalType][
                updateCurrentModalHelpers.MODAL_OBJECT_ELSE_Q
              ]
            }
          </div>
          <Box mb={theme.baseSpace * 6 + 'px'}>
            <Form.MultiSelect
              options={atNewX}
              onChange={(value) => {
                setAtNewXValue(value)
                updateMultiSelectOptions(atNewX, value, setAtNewX)
              }}
              bottomSpacingClass="pb0"
              className="pr0 pl0"
            />
            {atNewXValue === updateCurrentModalHelpers.STILL_AT_X_YES && (
              <div>
                {
                  updateCurrentModalHelpers.updateModalObj[modalType][
                    updateCurrentModalHelpers.MODAL_OBJECT_CURR_TYPE_MSG
                  ]
                }
              </div>
            )}
            {atNewXValue === updateCurrentModalHelpers.STILL_AT_X_NO && (
              <div>
                Thanks for confirming. Click continue to resume updating your
                profile / application.
              </div>
            )}
          </Box>
        </div>
      )}
    </div>
  )
  return (
    <Modal
      modalHeading={`${
        updateCurrentModalHelpers.updateModalObj[modalType][
          updateCurrentModalHelpers.MODAL_OBJECT_HEADER
        ]
      } ${currentTextToAppend}?`}
      primaryLabel="Continue"
      primaryAction={validateAndContinue}
      secondaryLabel="Cancel"
      secondaryAction={toggleModal}
      toggleModal={toggleModal}
      modalBody={modalBody}
    />
  )
}

export default UpdateCurrentModal
