import React, { useEffect } from 'react'

import qs from 'qs'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import propertyMeLogo from 'app/assets/pmsLogos/propertyme.png'
import { pmsBrands } from 'app/constants/pms.constants'
import PMSActivity, {
  useActivatePMS,
  useDeactivatePMS,
  usePMSStatus,
} from 'app/PMSActivity'
import { oauthActivate } from 'app/services/http/pms'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'

const PropertyMeActivity = ({
  currentTeam,
  isAdmin,
  integrationsBundleIsActive,
}) => {
  const { loadPMSStatus, pmsStatusInfo, pmsStatusLoadingStates } = usePMSStatus(
    currentTeam,
    pmsBrands.propertyMe.id,
    integrationsBundleIsActive,
  )

  const { activatePMSLoadingStates, activatePMSLoadingStatesHelpers } =
    useActivatePMS(currentTeam, pmsBrands.propertyMe.id)

  const { deactivatePMS, deactivatePMSLoadingStates } = useDeactivatePMS(
    currentTeam,
    pmsBrands.propertyMe.id,
  )

  useEffect(() => {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    if (queryParams.error) {
      snugNotifier.error(queryParams.error)
      activatePMSLoadingStatesHelpers.setError({
        message: queryParams.error,
      })
      clearActivationResponseQuery()
    }
    if (queryParams.success === 'true') {
      snugNotifier.success('Activated successfully')
      clearActivationResponseQuery()
    }
    loadPMSStatus()
  }, [])

  const clearActivationResponseQuery = () => {
    const purePath = window.location
      .toString()
      .replace(window.location.search, '')
    history.replace(urlTo(purePath))
  }

  const activate = () => {
    activatePMSLoadingStatesHelpers.startLoading()
    oauthActivate(
      pmsBrands.propertyMe.id,
      currentTeam.guid,
      `teams/${currentTeam.slug}/settings/propertyme`,
    )
  }

  const statusProps = {
    statusInfo: pmsStatusInfo,
    statusLoadingStates: pmsStatusLoadingStates,
    reloadStatusInfo: loadPMSStatus,
  }

  const activationProps = {
    activationFn: activate,
    activateLoadingStates: activatePMSLoadingStates,
  }

  const deactivateProps = {
    deactivationFn: () =>
      deactivatePMS()
        .then(() => loadPMSStatus())
        .catch(({ message }) => snugNotifier.error(message)),
    deactivateLoadingStates: deactivatePMSLoadingStates,
  }

  return (
    <PMSActivity
      pmsBrand={pmsBrands.propertyMe.id}
      logo={propertyMeLogo}
      statusProps={statusProps}
      activateProps={activationProps}
      deactivateProps={deactivateProps}
      isAdmin={isAdmin}
      integrationsBundleIsActive={integrationsBundleIsActive}
    />
  )
}

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
})

const PropertyMeActivityPage = withRouter(
  connect(mapStateToProps)(PropertyMeActivity),
)

export default PropertyMeActivityPage
