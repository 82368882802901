import React from 'react'

import HubForm from 'app/bond_cover/renters/hub/hub_form'
import isEmptyObject from 'app/shared_components/check_empty_object'
import MultiTenantErrors from 'app/shared_components/multi_tenant_errors'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import { urlTo } from 'app/sm/helpers'

class HubFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      requestID: null,
      show: false,
      type: '',
      isNewLease: false,
      disabled: true,
      submitClicked: false,
      unsavedChanges: false,
    }
  }

  UNSAFE_componentWillMount() {
    let { newBondCoverRequest, fetchRequestSummary } = this.props
    let requestID = getParameter('requestid')
    if (requestID) {
      fetchRequestSummary(requestID)
    } else {
      newBondCoverRequest()
    }
    let type = getParameter('type')
    this.setState({ requestID: requestID, type: type })
  }

  componentDidMount() {
    let requestID = getParameter('requestid')
    if (requestID) {
      this.props.setBackUrl(urlTo('bondCoverApplyProgress', { requestID }))
    } else {
      this.props.setBackUrl(urlTo('bondCoverApply'))
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ submitClicked: false })
    if (nextProps.request) {
      let { currentLease, tenants, request, bankAccount, identityDoc, card } =
        nextProps
      let { isNewLease } = currentLease ? currentLease : ''
      let managerApproved = request.managerAction == 3
      let multiTenantErrors = MultiTenantErrors(
        currentLease,
        tenants,
        isNewLease,
      )
      if (
        (request.submissionStatus === 0 &&
          currentLease &&
          !currentLease.isMultiTenantLease &&
          request.primaryTenantSigned) ||
        (request.submissionStatus === 0 &&
          currentLease &&
          currentLease.isMultiTenantLease &&
          request.primaryTenantSigned &&
          !multiTenantErrors) ||
        (!currentLease.isNewLease &&
          request.submissionStatus === 1 &&
          bankAccount &&
          identityDoc &&
          managerApproved) ||
        (currentLease.isNewLease &&
          request.submissionStatus === 1 &&
          card &&
          identityDoc &&
          managerApproved)
      ) {
        this.setState({ disabled: false })
      }
      if (currentLease) {
        this.setState({ isNewLease: currentLease.isNewLease })
      }
      if (request.submissionStatus !== 0 || identityDoc || bankAccount) {
        this.setState({ show: true })
      }
    }
  }

  onWithdrawClicked = () => {
    const { redirectToRenterWithdrawnPage } = this.props
    const requestID = getParameter('requestid')
    this.setState({ unsavedChanges: true }, () => {
      this.setState({ unsavedChanges: false })
      redirectToRenterWithdrawnPage(requestID)
    })
  }

  submit = () => {
    let { requestID } = this.state
    this.setState({ disabled: true, submitClicked: true })
    let { submit } = this.props
    submit(requestID)
  }

  toggleShow = () => {
    return (event) => {
      let { show } = this.state
      this.setState({ show: !show })
    }
  }

  render() {
    let {
      request,
      currentLease,
      currentUser,
      quote,
      manager,
      tenants,
      signature,
      identityDoc,
      bankAccount,
      card,
      apiError,
    } = this.props
    let { type, isNewLease, show, disabled, submitClicked } = this.state
    let showWithdraw = false

    return (
      <HubForm
        type={type}
        isNewLease={isNewLease}
        request={request}
        currentLease={currentLease}
        currentUser={currentUser}
        quote={quote}
        show={show}
        toggleShow={this.toggleShow}
        submitClicked={submitClicked}
        manager={manager}
        tenants={tenants}
        disabled={disabled}
        signature={signature}
        identityDoc={identityDoc}
        bankAccount={bankAccount}
        card={card}
        submit={this.submit}
        onWithdrawClicked={this.onWithdrawClicked}
        unsavedChanges={this.state.unsavedChanges}
        apiError={apiError}
      />
    )
  }
}

export default HubFormContainer
