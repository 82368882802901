import React from 'react'

import DefaultButton from 'app/components/buttons/default/component'
import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import { NUMBER_TYPE_MOBILE } from 'app/utils/phonenumber/helpers'

const isThereValidationErrors = (validations) =>
  Object.keys(validations).some((key) => validations[key] !== '')
const formatValidationErrors = (validations) =>
  Object.keys(validations).map((key) => <div key={key}>{validations[key]}</div>)
const emptyValidations = () => ({
  firstName: '',
  lastName: '',
  mobileNumber: '',
  email: '',
})
const emptyTenant = () => ({
  firstName: '',
  lastName: '',
  mobileNumber: '',
  email: '',
})

const isSecondaryOrNewReference = (reference) =>
  !reference.guid || reference.isPrimary === false

class JointTenants extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newTenant: emptyTenant(),
      validations: emptyValidations(),
      applicationsList: [],
    }
  }

  onRemoveButtonClick(nth) {
    const { onRemoveButtonClick } = this.props
    onRemoveButtonClick(nth)
  }

  onTenantValueUpdated = (field, nth, options = {}) => {
    return ({ value, error }) => {
      const { reverseValue = false } = options

      const { onTenantFieldChange, currentUser } = this.props
      let useCaseError = ''
      if (field === 'email' && value === currentUser.email) {
        useCaseError = 'You are already a tenant'
      }
      const finalErrorMessage = error || useCaseError
      onTenantFieldChange(
        field,
        reverseValue ? !value : value,
        nth,
        finalErrorMessage,
      )
    }
  }

  addNewRecord = () => {
    this.props.onAddJointTenant()
  }

  render() {
    const { validations } = this.state
    const { tenants = [], tenantsErrors } = this.props
    return (
      <div className="row p24 mr0 ml0 mobile-apply-padding">
        <div className="col-sm-12 pl0 pr0">
          {tenants &&
            tenants.filter(isSecondaryOrNewReference).map((tenant, nth) => {
              return (
                <Display.GreyContainer
                  key={`${nth}`}
                  containerClass="mb20 col-sm-12"
                >
                  <div className="secondary-applicant-form-position">
                    <div className="field-container row">
                      <div className="col-sm-8">
                        <Display.GreySubheading
                          text={`Tenant ${nth + 2} ${
                            tenant.isJointTenant ? '(on the lease)' : ''
                          }`}
                          componentClass="applicant-heading-spacing"
                        />
                      </div>
                      <div className="col-sm-4">
                        <DefaultButton
                          text="Remove"
                          componentClass="remove-tenant-record destructive-hollow"
                          size="small"
                          onClick={() => this.onRemoveButtonClick(nth)}
                          buttonType="destructive"
                        />
                      </div>
                    </div>
                    <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                      <div className="col-md-6 p0">
                        <Form.InputName
                          label="First Name"
                          value={tenant.firstName}
                          id="firstName"
                          onChange={this.onTenantValueUpdated('firstName', nth)}
                          error={tenantsErrors[nth]['firstName']}
                          inputClass="width100"
                          componentClass="margin-profile-item-left"
                        />
                      </div>
                      <div className="col-md-6 p0">
                        <Form.InputName
                          label="Last Name"
                          value={tenant.lastName}
                          id="lastName"
                          onChange={this.onTenantValueUpdated('lastName', nth)}
                          error={tenantsErrors[nth]['lastName']}
                          inputClass="width100"
                          componentClass="margin-profile-item-right"
                        />
                      </div>
                    </Form.ProfilePagesRowLayout>
                    <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                      <div className="col-md-6 p0">
                        <Form.InputPhoneNumber
                          label="Phone Number"
                          value={tenant.mobileNumber}
                          onChange={this.onTenantValueUpdated(
                            'mobileNumber',
                            nth,
                          )}
                          id="phone"
                          error={tenantsErrors[nth]['mobileNumber']}
                          containerClassName="width100"
                          componentClass="margin-profile-item-left"
                          numberType={NUMBER_TYPE_MOBILE}
                          required
                        />
                      </div>
                      <div className="col-md-6 p0">
                        <Form.InputEmail
                          label="Email"
                          value={tenant.email}
                          onChange={this.onTenantValueUpdated('email', nth)}
                          error={tenantsErrors[nth]['email']}
                          id="email"
                          inputClass="width100"
                          componentClass="margin-profile-item-right"
                        />
                      </div>
                    </Form.ProfilePagesRowLayout>
                    <div className="display-flex">
                      <Form.CheckBoxGeneral
                        componentClassName="mr10"
                        label="Tenant (on the lease)"
                        checked={tenant.isJointTenant}
                        onChange={this.onTenantValueUpdated(
                          'isJointTenant',
                          nth,
                        )}
                      />
                      <Form.CheckBoxGeneral
                        label="Occupant"
                        checked={!tenant.isJointTenant}
                        onChange={this.onTenantValueUpdated(
                          'isJointTenant',
                          nth,
                          { reverseValue: true },
                        )}
                      />
                    </div>
                  </div>
                </Display.GreyContainer>
              )
            })}
          {tenants && tenants.length > 0 && (
            <div>
              <div
                className={
                  isThereValidationErrors(validations)
                    ? 'alert alert-danger'
                    : 'hidden'
                }
              >
                {formatValidationErrors(validations)}
              </div>
              <div className="text-center col-sm-5 p0">
                <DefaultButton
                  text="Add Another Tenant"
                  onClick={this.addNewRecord}
                  size="large"
                  buttonType="default"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default JointTenants
