import React from 'react'

import accessControlAnswer from 'app/assets/icons/access_control_answer.png'
import encryptionLock from 'app/assets/icons/encryption_lock.png'
import { Box, Flex } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import { PERSONAL_REFERENCE_SURVEY_ORIGINAL_VERSION } from 'app/constants/personal_reference/survey/survey-questions.constants'
import {
  getPersonalReferenceFullInfoForSurvey,
  getPersonalReferenceReviewForManager,
  submitPersonalReferenceSurveyByManager,
  submitPersonalReferenceSurveyResponse,
} from 'app/services/http/personalReference'
import { history } from 'app/shared_components/router'
import { SNUG_PRIVACY_URL, urlIds, urlTo } from 'app/sm/helpers'
import ReferenceSurveyForm from 'app/sm/personal_reference/survey/ReferenceSurveyForm'

class PersonalReferenceSurveyPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      personalReference: null,
      renter: null,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { referenceGUID, secretGUID },
      },
    } = this.props
    let referenceReviewPromise
    if (secretGUID) {
      referenceReviewPromise = getPersonalReferenceFullInfoForSurvey(
        referenceGUID,
        secretGUID,
      )
    } else {
      referenceReviewPromise =
        getPersonalReferenceReviewForManager(referenceGUID)
    }
    referenceReviewPromise.then(({ personalReference, renter }) =>
      this.setState({ personalReference, renter }),
    )
  }

  submitSurveyResponse = (formValue) => {
    const {
      match: {
        params: { referenceGUID, secretGUID },
      },
    } = this.props
    let submissionPromise
    if (secretGUID)
      submissionPromise = submitPersonalReferenceSurveyResponse(
        referenceGUID,
        PERSONAL_REFERENCE_SURVEY_ORIGINAL_VERSION,
        formValue,
        secretGUID,
      )
    else {
      submissionPromise = submitPersonalReferenceSurveyByManager(
        referenceGUID,
        PERSONAL_REFERENCE_SURVEY_ORIGINAL_VERSION,
        formValue,
      )
    }

    return submissionPromise.then(() =>
      history.replace(urlTo(urlIds.personalReferenceSurveySubmitted)),
    )
  }

  renderBasicInfoCard = (firstname, lastname, phone, email) => (
    <div className="applicant-upper-container display-flex mb24">
      <div className="household-applicant-table avatar-container mt5">
        <div className="avatar application-avatar">
          <span className="app-avatar-initial-position default-font-family">
            {firstname?.toUpperCase().charAt(0)}
            {lastname?.toUpperCase().charAt(0)}
          </span>
        </div>
      </div>
      <Display.StandardContactInfoContainer
        mainTitle={`${firstname} ${lastname}`}
        linesText={[phone, email]}
      />
    </div>
  )

  render() {
    const { renter, personalReference } = this.state
    const renterBasicInfoCard =
      renter &&
      this.renderBasicInfoCard(
        renter.firstName,
        renter.lastName,
        renter.mobilePhone,
        renter.email,
      )
    const referenceBasicInfoCard =
      personalReference &&
      this.renderBasicInfoCard(
        personalReference.firstName,
        personalReference.lastName,
        personalReference.phoneNumber,
        personalReference.email,
      )
    return (
      //  TODO: Replace inline maxWidth
      <Box mx="auto" maxWidth={'720px'}>
        <h1>Personal Reference</h1>

        <Flex my="20px">
          <img src={accessControlAnswer} alt="shield" />
          <img src={encryptionLock} alt="lock" />
        </Flex>
        <Box my="20px">
          <p className="mb0">
            Trusted by over 60,000 Property Managers in Australia, Private and
            Secure.{' '}
            <a
              href={SNUG_PRIVACY_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn More {'>>'}
            </a>
          </p>
        </Box>
        {renter && (
          <>
            <Box my="10px">
              <h3>Renter Details</h3>
            </Box>
            {renterBasicInfoCard}
          </>
        )}
        {personalReference && (
          <>
            <Box my="10px">
              <h3>Referee Details</h3>
            </Box>
            {referenceBasicInfoCard}
          </>
        )}
        {renter && (
          <Box>
            <h3>Please complete the Personal Reference</h3>
            <p>
              By answering the following questions you’ll help{' '}
              {renter.firstName} improve their chances at a successful
              application.
            </p>
            <Box>
              <ReferenceSurveyForm onSubmit={this.submitSurveyResponse} />
            </Box>
          </Box>
        )}
      </Box>
    )
  }
}

export default PersonalReferenceSurveyPage
