import styled from 'styled-components'

export const DesktopOnly = styled.div`
  display: ${(props) => (props.displayType ? props.displayType : 'block')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'inherit')};
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const FlexBox = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems && props.alignItems};
  justify-content: ${(props) => props.justifyContent && props.justifyContent};
`

export const LinkStyleText = styled.span`
  color: #5a9bee;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`
export const BackgroundImage = styled.div`
  background-image: ${(props) => `url(${props.bgImage})`};
  background-size: ${(props) => props.size && props.size};
  background-repeat: ${(props) => props.repeat && props.repeat};
  min-height: ${(props) => props.minHeight && props.minHeight};
`
