import { mapRegistrant } from 'app/services/http/viewings/utils'
import { reduceArrayToObjectWithIdKeys } from 'app/utils/arrays'
import { generateUniqueId } from 'app/utils/strings/helpers'

const appendActorToAction = (actionStr) => `LOCAL_ENTITIES/${actionStr}`

export const actionTypes = {
  addRegistrant: appendActorToAction('ADD_REGISTRANT'),
  removeRegistrant: appendActorToAction('REMOVE_REGISTRANT'),
}

export const actionCreators = {
  addRegistrant: (registrant, viewingId) => {
    return {
      type: actionTypes.addRegistrant,
      registrant: {
        ...mapRegistrant(registrant),
        __viewingId: viewingId,
        __localId: generateUniqueId(),
        __pendingChange: true,
      },
    }
  },
  removeRegistrant: (registrant) => ({
    type: actionTypes.removeRegistrant,
    registrant,
  }),
}

const defaultState = {
  viewings: {
    all: {},
  },
  registrants: {
    all: {},
  },
}

export const localEntities = (state = defaultState, action) => {
  const { type, ...payload } = action
  switch (type) {
    case actionTypes.addRegistrant: {
      const { registrant } = payload
      return {
        ...state,
        registrants: {
          ...state.registrants,
          all: {
            ...state.registrants.all,
            [registrant.__localId]: registrant,
          },
        },
      }
    }

    case actionTypes.removeRegistrant: {
      const { registrant } = payload
      const filteredRegistrants = Object.values(state.registrants.all).filter(
        (r) => r.__localId === registrant.__localId,
      )
      return {
        ...state,
        registrants: {
          ...state.registrants,
          all: reduceArrayToObjectWithIdKeys(filteredRegistrants),
        },
      }
    }
  }
  return state
}
