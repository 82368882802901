import * as React from 'react'

const SvgApprovedRounded = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={101}
    height={100}
    viewBox="0 0 101 100"
    fill="none"
    {...props}
  >
    <circle opacity={0.1} cx={50.5} cy={50} r={50} fill="#1AB25D" />
    <circle
      opacity={0.1}
      cx={50.1603}
      cy={49.6603}
      r={44.6603}
      fill="#1AB25D"
    />
    <circle cx={50.6963} cy={50.1961} r={38.1963} fill="#1AB25D" />
    <path
      transform="translate(26.5,26.5)"
      d="M40.7683 8.16932L40.7683 8.16932C40.2168 8.18575 39.6934 8.41596 39.3087 8.81124C39.3082 8.8117 39.3078 8.81216 39.3073 8.81262L16.4373 31.6826L6.69152 21.9368C6.49608 21.7337 6.2622 21.5716 6.00348 21.4598C5.74396 21.3476 5.46467 21.2883 5.18196 21.2855C4.89926 21.2826 4.61882 21.3362 4.35707 21.443L4.45156 21.6745L4.35707 21.443C4.09532 21.5499 3.85752 21.7079 3.65761 21.9078C3.45769 22.1077 3.29968 22.3455 3.19282 22.6073C3.08595 22.869 3.03239 23.1495 3.03527 23.4322C3.03814 23.7149 3.0974 23.9942 3.20956 24.2537C3.32138 24.5124 3.48354 24.7463 3.6866 24.9417L14.9349 36.19L14.9349 36.19C15.3334 36.5884 15.8738 36.8122 16.4373 36.8122C17.0008 36.8122 17.5412 36.5884 17.9397 36.19L17.9398 36.19L42.3123 11.8175C42.3127 11.817 42.3132 11.8166 42.3136 11.8161C42.62 11.5178 42.8292 11.1341 42.914 10.715C42.9989 10.2953 42.955 9.85967 42.7881 9.46531C42.6212 9.07094 42.3391 8.7362 41.9787 8.50494C41.6182 8.27368 41.1964 8.1567 40.7683 8.16932Z"
      fill="white"
      stroke="white"
      strokeWidth={0.5}
    />
  </svg>
)

export default SvgApprovedRounded
