import React, { useEffect, useState } from 'react'

import { Form } from 'formik'
import styled from 'styled-components'

import {
  Alert,
  Box,
  Button,
  CollapsableSection,
  Flex,
  LoadingSection,
} from 'app/components/design-system-components'
import { AutocompleteField } from 'app/components/design-system-components/inputs'
import { SelectField } from 'app/components/design-system-components/inputs/Select/Select'
import OverlayPortalModal from 'app/components/design-system-components/modals/OverlayPortalModal'
import { statusesToCheckOut } from 'app/constants/key-logger.constants'
import BorrowerSelectionOption from 'app/features/teams/key-logger/CheckoutKeySetModal/AddressBorrowerSelection/BorrowerSelectOption'
import { formFields } from 'app/features/teams/key-logger/CheckoutKeySetModal/AddressBorrowerSelection/form.utils'
import { NewBorrowerModal } from 'app/features/teams/key-logger/CheckoutKeySetModal/AddressBorrowerSelection/NewBorrowerModal'
import {
  LabelSubText,
  StepLayoutContainer,
  StyledFieldWrapper,
} from 'app/features/teams/key-logger/components/StepLayoutContainer'
import { useKeySetOptionsForProperty } from 'app/features/teams/key-logger/hooks/useKeySetOptionsForProperty'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as keySetsHttp from 'app/services/http/teams/key-logger'
import { useSearchPropertyField } from 'app/utils/hooks/useSearchPropertyField'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { loadingStatesIds } from 'app/utils/loading-states'

export const generateBorrowerOption = (borrower, isNew) => ({
  option: borrower,
  optionMeta: {
    id: isNew ? 'new' : borrower.guid,
    displayText: `${borrower.first_name} ${borrower.last_name}`,
  },
})

const StyledForm = styled(Form)`
  overflow-y: auto;
  height: 100%;
`

export const FormBody = ({
  teamId,
  formBag: {
    isValid,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    status,
    values,
    touched,
    dirty,
  },
  bulkCheckoutCommentCmp,
  bulkCheckoutSelectedCmp,
  isBorrowerDisabled = false,
}) => {
  const [borrowerModal, setBorrowerModal] = useState({
    isOpened: false,
    cachedBorrower: null,
  })

  const {
    state: borrowersOptions,
    setState: setBorrowersOptions,
    loadingStates: borrowersLoadingStates,
    loadingStatesHelpers: borrowersLoadingStatesHelpers,
  } = useStateWithLoading([])

  const { onSearchProperties, propertiesOptions, loadingProperties } =
    useSearchPropertyField(teamId, { allProperties: true })

  const { loadKeySetsOptions, keySetsOptions, keySetsOptionsLoadingStates } =
    useKeySetOptionsForProperty(teamId, statusesToCheckOut)

  useEffect(() => {
    const { property } = values

    // reset selected keySet value, if not property initial value
    if (!property || !values[formFields.property].optionMeta.isInitialValue) {
      setFieldValue(formFields.keySet, null, true)
    }
    // we are hiding keysets field if no property selected
    // so no need to handle options in this case
    if (!property) return
    loadKeySetsOptions(property)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.property])

  const retryLoadKeySetOptions = () => loadKeySetsOptions(values.property)

  const onSearchBorrowers = debounce((text) => {
    borrowersLoadingStatesHelpers.startLoading()
    return keySetsHttp
      .searchBorrowers(teamId, text)
      .then(({ items: borrowers }) => {
        const savedOptions = borrowers.map((borrower) =>
          generateBorrowerOption(borrower),
        )
        const { cachedBorrower } = borrowerModal
        const options = cachedBorrower
          ? [generateBorrowerOption(cachedBorrower, true), ...savedOptions]
          : savedOptions

        setBorrowersOptions(options)
        borrowersLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => borrowersLoadingStatesHelpers.setError(err))
  }, STANDARD_TIMEOUT)

  const removeBorrowerOption = (guid) => {
    setBorrowersOptions(
      borrowersOptions?.filter(({ optionMeta: { id } }) => id !== guid) || [],
    )
  }

  const closeBorrowerModal = (borrower) => {
    if (!borrower) {
      setBorrowerModal((prevState) => ({
        ...prevState,
        isOpened: false,
      }))
    } else {
      setBorrowerModal((prevState) => ({
        ...prevState,
        isOpened: false,
        cachedBorrower: borrower,
      }))

      const newBorrowerOption = generateBorrowerOption(borrower, true)
      setBorrowersOptions((prevOptions) => {
        const savedOptions = prevOptions.filter(({ option }) => !!option.guid)
        return [newBorrowerOption, ...savedOptions]
      })
      setFieldValue(formFields.borrower, newBorrowerOption, true)
    }
  }

  const openBorrowerModal = () => {
    setBorrowerModal((prevState) => ({ ...prevState, isOpened: true }))
  }

  const actionsElem = (
    <Button type="submit" disabled={!isValid || isSubmitting} width="100%">
      Next
    </Button>
  )

  const bulkKeysCount = values[formFields.bulkKeySets]?.length
  const isBulkCheckoutMode = !!bulkKeysCount

  const showFormField = {
    [formFields.property]: !isBulkCheckoutMode,
    [formFields.keySet]: !isBulkCheckoutMode && !!values.property,
    [formFields.borrower]: isBulkCheckoutMode || !!values.property,
  }

  return (
    <>
      <StyledForm>
        <StepLayoutContainer actions={actionsElem}>
          {showFormField[formFields.property] && (
            <StyledFieldWrapper
              id={formFields.property}
              name={formFields.property}
              label="Property Address"
              labelProps={{
                subText: (
                  <LabelSubText>
                    Please select the property address that the keys are for
                  </LabelSubText>
                ),
              }}
              required
            >
              <AutocompleteField
                name={formFields.property}
                placeholder="Search for a property address"
                options={propertiesOptions}
                onChangeText={onSearchProperties}
                isLoading={loadingProperties}
              />
            </StyledFieldWrapper>
          )}

          {showFormField[formFields.keySet] && (
            <LoadingSection
              loadingState={keySetsOptionsLoadingStates}
              actionHandler={retryLoadKeySetOptions}
              loaderProps={{ fontSize: theme.fontSizes.pExtraLarge18 }}
            >
              {!!keySetsOptions.length && (
                <StyledFieldWrapper
                  id={formFields.keySet}
                  name={formFields.keySet}
                  label="Available to be checked out"
                >
                  <SelectField
                    id={formFields.keySet}
                    name={formFields.keySet}
                    label="Select"
                    options={keySetsOptions}
                  />
                </StyledFieldWrapper>
              )}

              {!keySetsOptions.length && (
                <Alert variant="warningWithBg" mt={theme.space[4] + 'px'}>
                  No available key sets for this property
                </Alert>
              )}
            </LoadingSection>
          )}
          {isBulkCheckoutMode && (
            <Alert variant="blueWithBg">
              <Box width="100%">
                <CollapsableSection
                  TitleSection={`${bulkKeysCount} selected keys will be checked out`}
                  variant="transparent"
                  initiallyCollapsed
                >
                  <Box>{bulkCheckoutSelectedCmp}</Box>
                </CollapsableSection>
                <Box mt={3}>{bulkCheckoutCommentCmp}</Box>
              </Box>
            </Alert>
          )}
          {showFormField[formFields.borrower] && (
            <StyledFieldWrapper
              id={formFields.borrower}
              name={formFields.borrower}
              label="Borrower"
              labelProps={{
                subText: (
                  <LabelSubText>
                    Please select the borrower of these keys
                  </LabelSubText>
                ),
              }}
            >
              <Flex alignItems="center">
                <AutocompleteField
                  disabled={isBorrowerDisabled}
                  name={formFields.borrower}
                  placeholder="Search for a contact"
                  options={borrowersOptions}
                  onChangeText={onSearchBorrowers}
                  isLoading={
                    borrowersLoadingStates.state === loadingStatesIds.LOADING
                  }
                  OptionRenderCmp={({ ...props }) => (
                    <BorrowerSelectionOption
                      onDeletion={(borrowerGUID) =>
                        removeBorrowerOption(borrowerGUID)
                      }
                      {...props}
                    />
                  )}
                />
                {!isBorrowerDisabled && (
                  <Button
                    type="button"
                    variant="outlineSuccess"
                    ml={theme.space[3] + 'px'}
                    onClick={openBorrowerModal}
                  >
                    Add
                  </Button>
                )}
              </Flex>
            </StyledFieldWrapper>
          )}
        </StepLayoutContainer>
      </StyledForm>

      {borrowerModal.isOpened && (
        <OverlayPortalModal>
          <NewBorrowerModal
            borrower={borrowerModal.cachedBorrower}
            closeModal={closeBorrowerModal}
          />
        </OverlayPortalModal>
      )}
    </>
  )
}
