import { connect } from 'react-redux'

import {
  fetchOffer,
  updateOfferWithViewingScheduleZone,
} from 'app/sm/onlist_details/action'
import ViewingScheduleZone from 'app/sm/onlist_details/viewing_schedule_zone_details'

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  updateOfferWithViewingScheduleZone: (
    agencyGUID,
    offerGUID,
    viewingScheduleZone,
  ) =>
    dispatch(
      updateOfferWithViewingScheduleZone(
        agencyGUID,
        offerGUID,
        viewingScheduleZone,
      ),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewingScheduleZone)
