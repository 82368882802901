import { connect } from 'react-redux'

import { fetchPropertyReport } from 'app/match/propertyReport/action'
import SendSmsReportModal from 'app/sm/inspections/components/send_sms_report_modal'
import { sendSmsReportToOwner } from 'app/sm/inspections/inspections_actions'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  fetchPropertyReport: (viewingId) => dispatch(fetchPropertyReport(viewingId)),
  sendSmsReport: (viewingId, mobileNumber) =>
    dispatch(sendSmsReportToOwner(viewingId, mobileNumber)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SendSmsReportModal)
