import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Pie, PieChart } from 'recharts'

import ProgressBar from 'app/sm/applications/components/progress_bar'
import {
  ApplicationCategory,
  formatEventHappenedAt,
  formatLastEventAt,
  formatLeaseTerm,
  isApplicantApplied,
  isStatusApplied,
  isStatusDeclinableByManager,
  isStatusDeclined,
  isStatusOfferable,
  isStatusOffered,
  isStatusQuickApplied,
  isStatusShortlisted,
  urlTo,
} from 'app/sm/helpers'

const selectStatus = (status) =>
  (ApplicationCategory[status] === 'Shortlisted' && 'shortlist') ||
  (ApplicationCategory[status] === 'Offered' && 'offer') ||
  (ApplicationCategory[status] === 'Declined' && 'decline') ||
  ''

export function RankPieChart(props) {
  const {
    rank,
    innerRadius = 16,
    outerRadius = 20,
    labelLine = false,
    width = 40,
    height = 40,
  } = props
  let fillColor = '#37c977'
  if (rank < 75) {
    fillColor = '#24C9D0'
  }
  if (rank < 50) {
    fillColor = '#579FFB'
  }
  if (rank < 25) {
    fillColor = '#ffe757'
  }
  const data = [
    { name: 'rank', value: rank, fill: fillColor },
    { name: 'blank', value: 100 - rank, fill: '#ffffff' },
  ]
  return (
    <PieChart width={width} height={height}>
      <Pie
        dataKey="value"
        data={data}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        labelLine={labelLine}
      />
    </PieChart>
  )
}

const ToggleJointApplicantsButton = ({
  count,
  toggleJointApplicants,
  showJointApplicants,
}) => {
  return (
    <div
      className="show-more-applicants avatar gray-bg fs24"
      onClick={toggleJointApplicants}
    >
      {showJointApplicants ? '-' : `+${count}`}
    </div>
  )
}

const EmptyToggleJointApplicantsButton = () => (
  <div className="show-more-applicants avatar white-bg fs24"></div>
)

const ApplicantInfo = ({
  application,
  applicant,
  index,
  url,
  cluster,
  toggleJointApplicants,
  showJointApplicants,
}) => {
  const applicationStatus = ApplicationCategory[application.status]
  return (
    <div className="meta">
      <div className="meta-wrapper">
        <div className="info flex-direction-row-under500">
          <div
            className={`avatar ${cluster}`}
            style={{ backgroundImage: `url(${applicant.avatar})` }}
          >
            {!applicant.avatar && (
              <span className="app-avatar-initial-position">
                {applicant.firstName.substring(0, 1)}
                {applicant.lastName.substring(0, 1)}
              </span>
            )}
            <div className={`number ${cluster}no${index + 1}`}>
              <span>{index + 1}</span>
            </div>
            {/* level0: not-verified */}
            {/* level1: verified */}
            {/* level2: bond cover */}
          </div>

          {application.isJoint ? (
            <ToggleJointApplicantsButton
              count={application.applicants.length - 1}
              toggleJointApplicants={() =>
                toggleJointApplicants(application.guidID)
              }
              showJointApplicants={showJointApplicants}
            />
          ) : (
            <EmptyToggleJointApplicantsButton />
          )}

          <Link to={url}>
            <div className="text">
              <h4>
                {applicant.firstName} {applicant.lastName.substring(0, 1)}.{' '}
                {application.isJoint ? ' (group)' : ''}
              </h4>
              <div className="offer display-flex">
                <span>${application.weeklyRent} p/w</span>
                <i className="icon-dot" />
                <span>{application.term} months</span>
              </div>
              <div className="offer">
                <span>
                  {isStatusQuickApplied(applicationStatus)
                    ? 'Interested:'
                    : 'Applied:'}{' '}
                  {application.submittedDate
                    ? moment(application.submittedDate).format(
                        'HH:mm DD MMM YYYY',
                      )
                    : 'Not specified'}
                </span>
              </div>
            </div>
          </Link>
        </div>
        <Link className="meta" to={url}>
          <div className="rank">
            <div className="rank-info chart" title="Snug rank">
              <RankPieChart rank={Math.round(applicant.score)} />
              <div className="rank-value fontSize-13">
                {Math.round(applicant.score)}
              </div>
            </div>
            <div className="rank-info" title="Persons count">
              <i className="icon-person" />
              <span>{application.people}</span>
            </div>
            <div className="rank-info" title="Pets count">
              <i className="icon-pets" />
              <span>{application.pets}</span>
            </div>
            <div className="info flex-direction-row-under500">
              <ProgressBar value={applicant.profileCompleteness || 0} />
              <i
                className="icon-help-outline fs16 gray-light-color pl15"
                data-tooltip-content="This bar shows the application's completeness"
              />
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

const SecondaryApplicantInfo = ({ applicant, cluster }) => {
  return (
    <div className="meta">
      <div className="meta-wrapper">
        <div className="info flex-direction-row-under500">
          <EmptyToggleJointApplicantsButton />

          <div
            className={`avatar ${cluster}`}
            style={{ backgroundImage: `url(${applicant.avatar})` }}
          >
            {!applicant.avatar && (
              <span className="app-avatar-initial-position">
                {applicant.firstName.substring(0, 1)}
                {applicant.lastName.substring(0, 1)}
              </span>
            )}
          </div>

          <div className="text">
            <h4>
              {applicant.firstName} {applicant.lastName.substring(0, 1)}.{' '}
              {' (group)'}
            </h4>
            <div className="offer display-flex">
              {/* <span>- p/w</span><i className="icon-dot" /><span>- months</span> */}
              {
                <div className="offer">
                  <span>
                    {isApplicantApplied(applicant) ? 'Applied: ' : 'Invited'}{' '}
                    {isApplicantApplied(applicant) &&
                      moment(applicant.updatedAt).format('HH:mm DD MMM YYYY')}
                  </span>
                </div>
              }
            </div>
          </div>
        </div>
        {isApplicantApplied(applicant) && (
          <div className="rank">
            <div className="rank-info chart" title="Snug rank">
              <RankPieChart rank={Math.round(applicant.score)} />
              <div className="rank-value fontSize-13">
                {Math.round(applicant.score)}
              </div>
            </div>
            <div className="rank-info" title="Persons count">
              <i className="icon-person" />
              <span>-</span>
            </div>
            <div className="rank-info" title="Pets count">
              <i className="icon-pets" />
              <span>-</span>
            </div>
            <div className="info flex-direction-row-under500">
              <ProgressBar value={applicant.profileCompleteness || 0} />
              <i
                className="icon-help-outline fs16 gray-light-color pl15"
                data-tooltip-content="This bar shows the application's completeness"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const ApplicationButtons = ({
  tab,
  application,
  onStatusDropdownChange,
  confirmAction,
  confirmOfferApplication,
  onClickRequestInfo,
}) => {
  const applicationStatus = ApplicationCategory[application.status]

  return (
    <div className="controls w30 xs-w100p mb10">
      {!isStatusDeclined(applicationStatus) &&
        !isStatusOffered(applicationStatus) &&
        !isStatusQuickApplied(applicationStatus) && (
          <button
            title="Shortlist"
            className={`btn-shortlist ${
              isStatusShortlisted(applicationStatus) ? 'active' : ''
            }`}
            onClick={() =>
              onStatusDropdownChange(
                isStatusShortlisted(applicationStatus)
                  ? 'unshortlist'
                  : 'shortlist',
                application.guidID,
                tab,
              )
            }
          >
            <i className="icon-star" />
          </button>
        )}

      <button
        title="show all"
        className={`btn-shortlist ${
          isStatusShortlisted(application) ? 'active' : ''
        }`}
        onClick={() => {}}
      ></button>

      {(isStatusDeclined(applicationStatus) ||
        isStatusOffered(applicationStatus)) && (
        <div className="ad-wrapper">
          {isStatusDeclined(applicationStatus) && (
            <div className="status declined">
              Declined {formatEventHappenedAt(application.lastEventAt)}
              <br />({formatLastEventAt(application.lastEventAt)})
            </div>
          )}

          {isStatusOffered(applicationStatus) && (
            <div className="display-flex">
              <div className="status offered text-center">
                Offer sent {formatEventHappenedAt(application.lastEventAt)}
                <br />({formatLastEventAt(application.lastEventAt)})
              </div>
              <button
                title="Withdraw"
                className="btn-decline"
                onClick={() =>
                  confirmAction('withdrawoffer', 'withdraw', application.guidID)
                }
              >
                <span>Withdraw offer</span>
              </button>
            </div>
          )}
        </div>
      )}

      <div className="btn-container">
        {isStatusQuickApplied(applicationStatus) && (
          <button
            title="Request Application"
            className="btn-request-info"
            onClick={() => onClickRequestInfo(application.guidID)}
          >
            <span>Invite to Apply</span>
          </button>
        )}
        {isStatusDeclinableByManager(applicationStatus) && (
          <button
            title="Decline"
            className="btn-decline"
            onClick={() =>
              confirmAction('decline', 'decline', application.guidID)
            }
          >
            <span>Decline</span>
          </button>
        )}
        {isStatusOfferable(applicationStatus) && (
          <button
            title="Offer"
            className="btn-offer"
            onClick={() => confirmOfferApplication(application)}
          >
            <span>Offer Lease</span>
          </button>
        )}
      </div>
    </div>
  )
}

const PrimaryApplicant = ({
  applicant,
  application,
  index,
  url,
  cluster,
  toggleJointApplicants,
  showJointApplicants,
  onStatusDropdownChange,
  confirmAction,
  confirmOfferApplication,
  onClickRequestInfo,
}) => {
  return (
    <li>
      <div className="content-wrapper">
        <ApplicantInfo
          application={application}
          applicant={applicant}
          index={index}
          url={url}
          cluster={cluster}
          toggleJointApplicants={toggleJointApplicants}
          showJointApplicants={showJointApplicants}
        />

        {/* special request */}
        {application.requests && (
          <div className="ml10 wbreak w30 xs-w100p">
            "
            {application.requests.substring(0, 100) +
              (application.requests.length > 100 ? ' ...' : '')}
            "
          </div>
        )}

        <ApplicationButtons
          application={application}
          onStatusDropdownChange={onStatusDropdownChange}
          confirmAction={confirmAction}
          confirmOfferApplication={confirmOfferApplication}
          onClickRequestInfo={onClickRequestInfo}
        />
      </div>
    </li>
  )
}

const SecondaryApplicant = ({ applicant, cluster }) => {
  return (
    <li>
      <div className="content-wrapper">
        <SecondaryApplicantInfo applicant={applicant} cluster={cluster} />
      </div>
    </li>
  )
}

const Applications = ({
  applications,
  propertyId,
  cluster,
  toggleJointApplicants,
  applicationsGUIState,
  onStatusDropdownChange,
  confirmAction,
  confirmOfferApplication,
  onClickRequestInfo,
}) => {
  const rows = applications
    .map((application, applicationIndex) => {
      const guiState =
        applicationsGUIState.find(
          (guiState) => guiState.guidID === application.guidID,
        ) || {}
      const showJointApplicants = guiState.showJointApplicants ? true : false
      // if applicant is empty then it's single application and should use renter field
      const primaryApplicant =
        application.applicants.find((applicant) => applicant.isPrimary) ||
        application.renter
      const url = urlTo('renterApplication', {
        propertyId,
        applicationId: application.guidID,
        applicantId: primaryApplicant.guidID,
      })
      const applicants = [
        <PrimaryApplicant
          key={primaryApplicant.guidID}
          applicant={primaryApplicant}
          application={application}
          index={applicationIndex}
          url={url}
          cluster={cluster}
          toggleJointApplicants={toggleJointApplicants}
          showJointApplicants={showJointApplicants}
          onStatusDropdownChange={onStatusDropdownChange}
          confirmAction={confirmAction}
          confirmOfferApplication={confirmOfferApplication}
          onClickRequestInfo={onClickRequestInfo}
        />,
      ]

      return showJointApplicants
        ? applicants.concat(
            application.applicants
              .filter((applicant) => applicant.isPrimary === false)
              .map((applicant) => (
                <SecondaryApplicant
                  key={applicant.guidID}
                  applicant={applicant}
                  cluster={cluster}
                />
              )),
          )
        : applicants
    })
    .reduce((acc, cur) => acc.concat(cur), [])

  return <ul className="sm-ra-card__list">{rows}</ul>
}

class ApplicationsCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      applicationsGUIState: [
        {
          guidID: undefined,
          showJointApplicants: undefined,
        },
      ],
    }
  }

  onChange(labelId) {
    this.props.onChange(labelId)
  }

  confirmAction = (action, text, guidID) => {
    if (window.confirm(`Are you sure you want to ${text} this offer?`)) {
      this.props.onStatusDropdownChange(action, guidID, 'Applications')
    }
  }

  confirmOfferApplication = (a) => {
    if (
      window.confirm(
        'Are you sure you wish to offer the property to this renter? We will send them the offer if you confirm. ',
      )
    ) {
      this.props.onStatusDropdownChange('offer', a.guidID, 'Offered')
    }
  }

  toggleJointApplicants = (applicationGUIDID) => {
    let insertNew = true
    const newApplicationsGUIState = this.state.applicationsGUIState.map(
      (application) => {
        if (application.guidID === applicationGUIDID) {
          insertNew = false
          return Object.assign({}, application, {
            showJointApplicants: !application.showJointApplicants,
          })
        } else {
          return application
        }
      },
    )

    if (insertNew) {
      this.setState({
        applicationsGUIState: newApplicationsGUIState.concat({
          guidID: applicationGUIDID,
          showJointApplicants: true,
        }),
      })
    } else {
      this.setState({ applicationsGUIState: newApplicationsGUIState })
    }
  }

  render() {
    const {
      propertyId,
      applications,
      cluster,
      isOfferTab,
      onStatusDropdownChange,
      tab = '',
    } = this.props

    return (
      <div className="sm-ra-card">
        {cluster && applications.length > 0 && (
          <div className={`sm-ra-card__header ${cluster}`}>
            <h4>
              <span>{cluster}&nbsp;</span>
              <span className="fit">Snug Match</span>
              <i
                className="icon-help-outline tablet-only"
                data-tooltip-content="This score considers your offer, your rental reputation, the property and preferences of the owner to estimate how suitable you are."
              />
            </h4>
          </div>
        )}

        <Applications
          tab={tab}
          applications={applications}
          propertyId={propertyId}
          cluster={cluster}
          toggleJointApplicants={this.toggleJointApplicants}
          applicationsGUIState={this.state.applicationsGUIState}
          onStatusDropdownChange={this.props.onStatusDropdownChange}
          confirmAction={this.confirmAction}
          confirmOfferApplication={this.confirmOfferApplication}
          onClickRequestInfo={this.props.onClickRequestInfo}
        />

        <ReactTooltip className="tooltip" />
      </div>
    )
  }
}

export default ApplicationsCard
