import React from 'react'

import styled from 'styled-components'

import { ModalContentWrapper } from 'app/modals/modal-styled'
import Spinner from 'app/sm/common/spinner'
import * as helpers from 'app/sm/helpers'
import AccessDetails from 'app/sm/onlist_details/AccessDetails/connection'

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #5a9bee;
  text-decoration: underline;

  &:hover,
  :focus {
    cursor: pointer;
    outline: none;
    color: #1771e4;
    text-decoration: underline;
  }
  @media (max-width: 767px) {
    font-size: 16px !important;
  }
`

const isDisplayTitle = false
class AddAccessDetailModalContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lastUpdateBy: '',
      entryDetails: [],
      guidDetails: {},
      isShowSpinner: false,
    }
  }

  componentDidMount = () => {
    const { property, agencyGUID } = this.props
    const { fetchPropertyEntryDetails } = this.props
    if (property && agencyGUID) {
      this.setState({
        isShowSpinner: true,
      })
      fetchPropertyEntryDetails(agencyGUID, property)
        .then((result) => {
          const { entryDetails, lastUpdatedDate, managerName } = result
          const lastUpdatedDateWithManager = `${lastUpdatedDate} ${managerName}`
          this.setState({
            entryDetails,
            lastUpdateBy: lastUpdatedDateWithManager,
            isShowSpinner: false,
          })
        })
        .catch((apiError) =>
          this.setState({
            apiError,
          }),
        )
    }
  }

  render() {
    const {
      turnOffAllModals,
      onModalTitleClicked,
      property,
      agencyGUID,
      showTopHeader = true,
    } = this.props

    const { entryDetails, lastUpdateBy, isShowSpinner } = this.state

    const details = {
      property,
      agencyGUID,
    }

    return (
      <div className="modal-fullscreen background-dark-grey modal-layout-flex-container viewing-view">
        <ModalContentWrapper takeMinHeight>
          <div className="modal-close top5">
            <i
              className="fa fa-times font-size-24"
              onClick={() => turnOffAllModals()}
            />
          </div>
          {showTopHeader ? (
            <div>
              <div className="font-size-18 fw500 position-absolute left15 top20 mobile-font-size-16">
                Add:
              </div>
              <div className="modal-header display-flex justify-content-space-around align-items-center ml5">
                <Title
                  onClick={() =>
                    onModalTitleClicked(
                      helpers.modalNameCollection.attendeeModal,
                    )
                  }
                >
                  Attendee
                </Title>
                <Title
                  onClick={() =>
                    onModalTitleClicked(helpers.modalNameCollection.noteModal)
                  }
                >
                  Note
                </Title>
                <Title className="fw700">Access Details</Title>
              </div>
            </div>
          ) : (
            <div className="modal-header">
              <h4 className="standard-subheading">Access Details</h4>
            </div>
          )}
          <div className="modal-body p15 viewing-card pb90">
            <div className="search-section-container mb10">
              {!isShowSpinner && (
                <AccessDetails
                  entryDetails={entryDetails}
                  lastUpdateBy={lastUpdateBy}
                  guidDetails={details}
                  isDisplayTitle={isDisplayTitle}
                />
              )}
              {isShowSpinner && <Spinner />}
            </div>
          </div>
        </ModalContentWrapper>
      </div>
    )
  }
}

export default AddAccessDetailModalContainer
