import React from 'react'

import 'app/components/display/profile_pages_row_layout/style.scss'

const ProfilePagesRowLayout = ({ children, containerClass = '' }) => {
  return (
    <div className={`container p0 m0 width100 mb20 ${containerClass}`}>
      {children}
    </div>
  )
}

export default ProfilePagesRowLayout
