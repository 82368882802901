import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Box } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import {
  housingCategories,
  housingCategoryOptions,
  managementTypes,
} from 'app/constants/offer.constants'

const PropertyLabels = ({ activeOffer = {} }) => {
  const { guidID = '', managementType, housingCategory } = activeOffer || {}
  const tooltipIdNewManagement = guidID + '_new_management'
  const SET_IN_ONLIST = 'housing category set in Onlist'

  const _renderTooltipId = (type) => {
    return `${guidID}_${type}`
  }

  const _renderLabelText = (type) => {
    return housingCategoryOptions.find(({ value }) => value === type).label
  }

  return (
    <>
      {managementType === managementTypes.NEW_MANAGEMENT && (
        <>
          <Badge
            text="New Mgmt"
            variant="blue"
            data-tooltip-content=""
            data-tooltip-id={tooltipIdNewManagement}
          />
          <ReactTooltip
            id={tooltipIdNewManagement}
            data-type="info"
            place="top"
            className="tool-tip-style"
          >
            <Box maxWidth="156px" textAlign="center">
              New Management set in Onlist
            </Box>
          </ReactTooltip>
        </>
      )}
      {Object.values(housingCategories)
        .filter((category) => category !== housingCategories.MARKET)
        .map(
          (category) =>
            category === housingCategory && (
              <>
                <Badge
                  text={_renderLabelText(housingCategory)}
                  variant="blue"
                  data-tooltip-content=""
                  data-tooltip-id={_renderTooltipId(housingCategory)}
                />
                <ReactTooltip
                  id={_renderTooltipId(housingCategory)}
                  data-type="info"
                  place="top"
                  className="tool-tip-style"
                >
                  <Box maxWidth="156px" textAlign="center">
                    {_renderLabelText(housingCategory)} {SET_IN_ONLIST}
                  </Box>
                </ReactTooltip>
              </>
            ),
        )}
    </>
  )
}

export default PropertyLabels
