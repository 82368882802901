import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { resetPropertyFilters } from 'app/dashboard/team_overview_actions'
import { changeTeam, fetchTeams, logout } from 'app/session/session_actions'
import { resetSideBar } from 'app/shared_components/actions'
import { setBackUrl } from 'app/shared_components/actions'
import Navbar from 'app/shared_components/navbar/navbar'
import { fetchApplications } from 'app/sm/applications/applications_actions'
import {
  changeRole,
  fetchApplicationsCount,
  fetchProperties,
} from 'app/sm/properties/properties_actions'
import { fetchProperty } from 'app/sm/property_dashboard/property_dashboard_actions'

const mapStateToProps = ({
  session,
  MyProperties,
  dashboard,
  propertyDashboard,
  Shared,
  core,
}) => ({
  currentUser: session.currentUser || {},
  currentTeam: session.currentTeam,
  teams: session.teams,
  currentAgency: session.currentAgency,
  bondCovers: dashboard.bondCovers,
  property: propertyDashboard.property,
  applicationsCount: MyProperties.applicationsCount,
  close: Shared.close,
  hasNewActivityFeedConfig: core.hasNewActivityFeedConfig,
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => logout(),
  fetchProperties: () => dispatch(fetchProperties()),
  handleLinkChange: (role) => dispatch(changeRole(role)),
  fetchProperty: (id) => dispatch(fetchProperty(id)),
  fetchApplicationsCount: () => dispatch(fetchApplicationsCount()),
  fetchApplications: () => dispatch(fetchApplications()),
  changeTeam: (currentTeam) => dispatch(changeTeam(currentTeam)),
  resetSideBar: () => dispatch(resetSideBar()),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
  fetchTeams: () => dispatch(fetchTeams()),
  resetPropertyFilters: () => dispatch(resetPropertyFilters()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
