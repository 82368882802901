export const personalReferenceStatuses = {
  notSent: {
    id: 0,
    label: 'Not Sent',
    badgeVariant: 'gray',
  },
  sent: {
    id: 1,
    label: 'Sent',
    badgeVariant: 'warning',
  },
  responded: {
    id: 2,
    label: 'Responded',
    badgeVariant: 'success',
  },
}
