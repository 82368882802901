import React from 'react'

import styled from 'styled-components'

const Holder = styled.div`
  padding: 15px 68px 16px 25px;
  position: relative;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`

function WidgetHolder(props) {
  return <Holder className="widget-holder">{props.children}</Holder>
}

export default WidgetHolder
