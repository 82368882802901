import React from 'react'

import PropTypes from 'prop-types'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import ApplicationsList from 'app/application_revamp/components/Applicant/ApplicationList'
import { Summary } from 'app/application_revamp/components/Applicant/shared_styles'
import ContactDetailsWrapper from 'app/application_revamp/components/Common/ContactDetails'
import WidgetHeading from 'app/application_revamp/components/Common/WidgetHeading'
import ApplicationHolder from 'app/application_revamp/components/Layout/ApplicationHolder'
import approvalImg from 'app/assets/icons/application_green_tick.png'
import errorImg from 'app/assets/icons/application_red_error.png'
import warningImg from 'app/assets/icons/application_yellow_warning.png'
import { Button } from 'app/components/design-system-components'
import { Flex } from 'app/components/design-system-components'
import { Box } from 'app/components/design-system-components'
import { HelpOutlineRounded } from 'app/components/design-system-components/icons/actions'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { isMobile } from 'app/shared_components/helpers'
import * as Helpers from 'app/sm/helpers'
import { RankPieChart } from 'app/sm/renter_applications/components/applications_card'
import * as phoneNumberHelpers from 'app/utils/phonenumber/helpers'
import * as textHelpers from 'app/utils/text/helpers'

const propTypes = {
  applicant: PropTypes.object,
}

const Holder = styled.div`
  border-bottom: 1px solid var(--pdf-gray-light);
  padding: 22px 14px 20px 19px;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  line-height: 1.14;

  @media (min-width: 768px) {
    padding: 22px 14px 34px 19px;
  }

  p {
    margin: 0 0 4px;
    font-size: inherits;
    line-height: inherit;
  }
`

const Name = styled.p`
  color: #212025;
  line-height: 16px;
  span {
    font-weight: bolder;
    font-size: 18px;
  }
`

// NOTE: Remains for when notice icons are included.
// const Icon = styled.i`
//   margin-right: 3px;
//   vertical-align: sub;
//   display: inline-block;
//
//   &.icon-warning,
//   &.icon-error {
//     margin: -5px 3px -10px 0;
//     font-size: 22px;
//   }
// `

const summary = [
  {
    id: 'list-01',
    title: 'Preferences',
    anchorID: Helpers.targetSectionObject['applicationSummary'].pageID,
    // error: true,
    // info: true,
    // warning: true,
    label: 'preferences',
  },
  {
    id: 'list-02',
    title: 'Address history',
    anchorID: Helpers.targetSectionObject['addressHistory'].pageID,
    label: 'addressHistory',
  },
  {
    id: 'list-03',
    title: 'Employment details',
    anchorID: Helpers.targetSectionObject['employmentDetails'].pageID,
    label: 'employmentDetails',
  },
  {
    id: 'list-04',
    title: 'Income and financials',
    anchorID: Helpers.targetSectionObject['income'].pageID,
    label: 'income',
  },
  {
    id: 'list-05',
    title: 'Identity documents',
    anchorID: Helpers.targetSectionObject['identity'].pageID,
    label: 'identity',
  },
  {
    id: 'list-06',
    title: 'Background check',
    anchorID: Helpers.targetSectionObject['backgroundCheck'].pageID,
    label: 'backgroundCheck',
  },
  {
    id: 'list-04',
    title: 'TICA check',
    anchorID: Helpers.targetSectionObject.ticaCheck.pageID,
    label: 'ticaCheck',
  },
  {
    id: 'list-08',
    title: 'Emergency contacts',
    anchorID: Helpers.targetSectionObject['emergencyContact'].pageID,
    label: 'emergencyContact',
  },
  {
    id: 'list-09',
    title: 'Personal Reference',
    anchorID: Helpers.targetSectionObject['personalReference'].pageID,
    label: 'personalReference',
  },
  {
    id: 'list-10',
    title: 'Pet details',
    anchorID: Helpers.targetSectionObject['petDetails'].pageID,
    label: 'petDetails',
  },
  {
    id: 'list-11',
    title: 'Student details',
    anchorID: Helpers.targetSectionObject['studentDetails'].pageID,
    label: 'studentDetails',
  },
  {
    id: 'list-12',
    title: 'Other info',
    anchorID: Helpers.targetSectionObject['otherDocuments'].pageID,
    label: 'otherDocuments',
  },
  {
    id: 'list-13',
    title: 'Compliance',
    anchorID: Helpers.targetSectionObject['compliance'].pageID,
    label: 'compliance',
  },
]

const managerApplicationSummary = [
  {
    id: 'list-01',
    title: 'Preferences',
    anchorID: Helpers.targetSectionObject['applicationSummary'].pageID,
    label: 'preferences',
  },
  {
    id: 'list-13',
    title: 'Attachments',
    anchorID: Helpers.targetSectionObject['managerAttachments'].pageID,
    label: 'managerAttachments',
  },
  {
    id: 'list-12',
    title: 'Compliance',
    anchorID: Helpers.targetSectionObject['compliance'].pageID,
    label: 'compliance',
  },
]

const StyledListItem = styled.li`
  padding: ${theme.space[4]}px ${theme.space[5]}px;
  color: ${theme.colors.gray700};
  font-weight: ${theme.fontWeights[3]};
  font-size: ${theme.fontSizes.pSmall12};
  :not(:last-child) {
    border-bottom: 1px solid ${theme.colors.grey200};
  }
  &.disabled-item {
    cursor: text;
    background-color: ${theme.colors.gray200};
  }
`

const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 250px;
`

const StyledUL = styled.ul`
  box-shadow: 0px 4px 30px 0px rgba(134, 134, 134, 0.25);
  border-radius: 5px;
  margin-left: -15px;
`

function PrimaryApplicant({
  applicant,
  open,
  downloadPDFButton,
  interpreteNoteResult,
  collapse,
  isApplicationListDisplay,
  isManagerAdded,
  idDocsNotRequested = false,
  disabledSections,
  ballotsEnabled,
  toggleRequestInfoModal,
  numberOfReqInfoItemsSelected,
  sendRequestInfo,
}) {
  const {
    firstName,
    mobileNumber,
    email,
    lastName,
    applicationID,
    isPrimary,
    score,
    applied,
  } = applicant
  const name = `${firstName} ${lastName}`
  let typeText = Helpers.getTenantTypeTextByStatus(isPrimary, applicationID)
  if (applicationID === undefined) {
    typeText = 'Child'
  }
  const infoCounter = summary.filter((item) => item.info).length
  const warningCounter = summary.filter((item) => item.warning).length
  const errorCounter = summary.filter((item) => item.error).length
  const formattedMobileNumber =
    phoneNumberHelpers.formatPhoneNumber(mobileNumber)

  const showMatchScore = score !== undefined && applied && !ballotsEnabled

  const requestInfoButtonLabel = `Request Info${
    numberOfReqInfoItemsSelected && numberOfReqInfoItemsSelected > 0
      ? ` (${numberOfReqInfoItemsSelected})`
      : ''
  }`

  const requestInfoButtonVariant =
    numberOfReqInfoItemsSelected && numberOfReqInfoItemsSelected > 0
      ? 'solidSuccessStrong'
      : 'greenHover'

  const requestInfoItemClassName = !numberOfReqInfoItemsSelected
    ? 'disabled-item'
    : ''
  const requestInfoItemFunc = !numberOfReqInfoItemsSelected
    ? () => {}
    : sendRequestInfo

  const requestInfoListItem = (
    <StyledListItem
      className={requestInfoItemClassName}
      onClick={() => requestInfoItemFunc()}
    >
      <Flex
        alignItems="center"
        data-tooltip-content=""
        data-tooltip-id="send-request-alert"
      >
        <Box mr={3}>Send Request</Box>
        {!numberOfReqInfoItemsSelected && (
          <>
            <HelpOutlineRounded color={theme.colors.gray700} />
            <StyledReactTooltip
              data-type="info"
              place="bottom"
              id="send-request-alert"
            >
              Click "Add to request" above or "Request info" in the relevant
              application sections before sending
            </StyledReactTooltip>
          </>
        )}
      </Flex>
    </StyledListItem>
  )

  // noinspection PointlessBooleanExpressionJS
  return (
    <Holder className="primary-applicant">
      <div className="display-flex justify-content-space-between align-items-start prospect-mobile-section width-100">
        <ApplicationHolder>
          <Flex justifyContent="space-between">
            <Box>
              <Name>
                <span className="opener" onClick={() => open()}>
                  {name}
                </span>
              </Name>
              <WidgetHeading title={`${typeText}`} />
              <ContactDetailsWrapper
                email={email}
                mobile={mobileNumber}
                formattedMobile={formattedMobileNumber}
              />
            </Box>
            <div className="meta">
              <div className="rank">
                {showMatchScore ? (
                  <div
                    className="rank-info chart relative"
                    data-tooltip-content=""
                    data-tooltip-id="snug-match-label"
                  >
                    <ReactTooltip
                      id="snug-match-label"
                      data-type="info"
                      className="tool-tip-style"
                    >
                      <span>{textHelpers.SNUG_MATCH_LABEL}</span>
                    </ReactTooltip>
                    <RankPieChart rank={Math.round(score)} />
                    <div className="rank-value fontSize-13">
                      {Math.round(score) || ''}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Flex>
          {applied && (
            <Flex mb={5}>
              <Button
                onClick={isMobile() ? () => toggleRequestInfoModal() : null}
                variant={requestInfoButtonVariant}
                sizeVariant="regular"
                mr={3}
                width={theme.width[12] + 'px'}
              >
                <div className={`dropdown actions`}>
                  <div className="dropdown-toggle" data-toggle="dropdown">
                    {requestInfoButtonLabel}
                  </div>
                  <StyledUL className="dropdown-menu mt10">
                    <StyledListItem onClick={toggleRequestInfoModal}>
                      Add to request
                    </StyledListItem>
                    {requestInfoListItem}
                  </StyledUL>
                </div>
              </Button>
              {downloadPDFButton}
            </Flex>
          )}
          {!collapse && isApplicationListDisplay && (
            <div className="description">
              <ApplicationsList
                interpreteNoteResult={interpreteNoteResult}
                disabledSections={disabledSections}
                summary={isManagerAdded ? managerApplicationSummary : summary}
              />
            </div>
          )}

          {/* FIXME: Not required? Supposed to be on applicant row, no selected applicant display */}
          {false && (
            <Summary className="application-summary">
              {infoCounter ? (
                <li>
                  <img
                    className="width20 mr5"
                    src={approvalImg}
                    alt="approvalImg"
                  />
                  {infoCounter}
                </li>
              ) : (
                ''
              )}
              {warningCounter ? (
                <li>
                  <img
                    className="width20 mr5"
                    src={warningImg}
                    alt="warningImg"
                  />

                  {warningCounter}
                </li>
              ) : (
                ''
              )}
              {errorCounter ? (
                <li>
                  <img className="width20 mr5" src={errorImg} alt="errorImg" />{' '}
                  {errorCounter}
                </li>
              ) : (
                ''
              )}
            </Summary>
          )}
        </ApplicationHolder>
      </div>
    </Holder>
  )
}

PrimaryApplicant.propTypes = propTypes

export default PrimaryApplicant
