import React from 'react'

import $ from 'jquery'

import Files from 'app/sm/chat/components/timeline/files'
import Header from 'app/sm/chat/components/timeline/header'
import Messages from 'app/sm/chat/components/timeline/messages'
import Profile from 'app/sm/chat/components/timeline/profile'
import Write from 'app/sm/chat/components/timeline/write'

class Timeline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeProfile: true,
      activeFiles: false,
      asideExtended: false,
    }
  }

  componentDidMount() {
    $('.footer').addClass('hide')
  }

  componentWillUnmount() {
    $('.footer').removeClass('hide')
  }

  activateFiles() {
    this.setState({ activeProfile: false, activeFiles: true })
  }

  activateProfile() {
    this.setState({ activeProfile: true, activeFiles: false })
  }

  toggleAside() {
    let { asideExtended } = this.state
    this.setState({ asideExtended: !asideExtended })
  }

  render() {
    let { activeProfile, activeFiles, asideExtended } = this.state

    return (
      <div className="sm-chat timeline">
        <div
          className={`content-wrapper ${asideExtended ? 'aside-extended' : ''}`}
        >
          <div className="content-header">
            <Header />
            <div className="toggle-aside">
              <i
                className={
                  asideExtended ? 'icon-arrow-right' : 'icon-arrow-left'
                }
                onClick={() => this.toggleAside()}
              ></i>
            </div>
          </div>
          <div className="content-main">
            <Messages />
          </div>
          <div className="content-footer">
            <Write />
          </div>
        </div>

        <div className={`aside ${asideExtended ? 'aside-extended' : ''}`}>
          <div className="aside-header">
            <a
              className={activeProfile && 'active'}
              onClick={() => this.activateProfile()}
            >
              Profile
            </a>
            <a
              className={activeFiles && 'active'}
              onClick={() => this.activateFiles()}
            >
              Files
            </a>
          </div>
          <div className="aside-content">
            {activeProfile && <Profile />}
            {activeFiles && <Files />}
          </div>
        </div>
      </div>
    )
  }
}

export default Timeline
