import React from 'react'

import PropTypes from 'prop-types'

import AvatarPlaceholder from 'app/match/applicationReportPDF/assets/images/avatar-placeholder.jpg'
import favicon from 'app/match/applicationReportPDF/assets/images/favicon.png'

import 'app/match/applicationReportPDF/components/Persona/style.scss'

const propTypes = {
  avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  status: PropTypes.string,
  email: PropTypes.string,
  mobile: PropTypes.string,
}

const Persona = ({
  info: {
    name,
    image: avatar,
    status,
    email,
    mobile,
    child,
    viewedOn,
    age,
    isPrimary,
  },
}) => {
  let ageText = ''
  if (age === 0) {
    ageText = '(Age not available)'
  } else if (age > 1) {
    ageText = `(${age} y/o)`
  }
  const tenantType = isPrimary ? '(Primary)' : ''
  return (
    <div className="persone-card">
      <div className="persone-card__avatar">
        <img src={avatar || AvatarPlaceholder} alt="avatar" />
      </div>
      <div className="persone-card__info">
        <div className="persone-card__title">
          <span>
            {name} {ageText} {tenantType}
          </span>
          {status === null && <img src={favicon} alt="favicon" />}
        </div>
        <div className="persone-card__contacts">
          <div className="display-flex">
            {email && email.length && (
              <span className="persone-card__contacts--email">{email}</span>
            )}
          </div>
          <div className="display-flex">
            {mobile && mobile.length && (
              <span className="persone-card__contacts--mobile">{mobile}</span>
            )}
          </div>
          <div className="display-flex">
            {status && status.length && (
              <span className="persone-card__contacts--status">{status}</span>
            )}
          </div>
          {viewedOn && <div>Viewed {viewedOn}</div>}
          {child && <span>Child</span>}
        </div>
      </div>
    </div>
  )
}

Persona.propTypes = propTypes

export default Persona
