import { history } from 'app/shared_components/router'

export function isCurrentRoute(what) {
  // TODO:
  // We need to fill this when we need it
  const routes = {
    adFlowAmenities: /\/sm\/advertise\/amenities/,
    adFlowDescription: /\/sm\/advertise\/description/,
    adFlowPortals: /\/sm\/advertise\/portals/,
    adFlowPayment: /\/sm\/advertise\/pay/,
    profilePropertyBasics: /\/sm\/properties\/profile\/property-basics/,
    profileOfferDetails: /\/sm\/properties\/profile\/offer-details/,
    addPetDetails: /\/sm\/pets-details\/add/,
    addPersonalReference: /\/sm\/personal-reference\/add/,
    addBackgroundDetails: /\/sm\/background-check\/details/,
    addEmergencyContact: /\/sm\/emergency-contact\/add/,
    addIdentityDocDetails: /\/sm\/background-check\/details\/add/,
    managerDashboard: /\/portfolio\/dashboard/,
    portfolioOverview: /\/portfolio\/overview/,
  }

  const {
    location: { pathname },
  } = history

  return routes[what].test(pathname)
}

export function isCurrentRouteFrom(what) {
  // TODO:
  // We need to fill this when we need it
  const routes = {
    Profile: /\/sm\/properties\/profile/,
  }

  const {
    location: { pathname },
  } = history

  return routes[what].test(pathname)
}

export const extractTeamSlug = (path) => {
  const segments = path.split('/')
  const teamsIndex = segments.indexOf('teams')
  if (teamsIndex === -1) return undefined
  return segments[teamsIndex + 1]
}
