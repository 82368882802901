import React from 'react'

import GoogleAddress from 'app/shared_components/address_form/google_address'
import ManualAddress from 'app/shared_components/address_form/manual_address'

export const emptyAddress = () => {
  return {
    friendlyName: '',
    unitNumber: '',
    streetNumber: '',
    streetName: '',
    address2: '',
    suburb: '',
    state: '',
    postcode: '',
    country: 'Australia',
    lat: null,
    lng: null,
    googleId: '',
  }
}

class AddressForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      suggest: true,
    }
  }

  componentDidMount() {
    const { showManualAddressFirst = false } = this.props
    if (showManualAddressFirst) {
      this.setState({
        suggest: false,
      })
    }
  }

  toggleSuggest = () => {
    this.setState({ suggest: !this.state.suggest })
  }

  render() {
    let { suggest } = this.state
    let {
      address,
      updateAddress,
      updateGoogleAddress,
      resetAddress,
      placeholder,
      onBlur,
      error,
      disabled,
      inputDisabled,
      country,
      queryStringAddress,
      showSuggestAddress = true,
      noBlurForGoogleAddress = false,
    } = this.props
    const googleAddressBlur = noBlurForGoogleAddress ? () => {} : onBlur
    let googleAddress = (
      <GoogleAddress
        address={address}
        update={updateGoogleAddress}
        placeholder={placeholder}
        onBlur={googleAddressBlur}
        disabled={disabled}
        inputDisabled={inputDisabled}
        country={country}
        queryStringAddress={queryStringAddress}
        error={error}
        suggest={suggest}
        toggleSuggest={this.toggleSuggest}
      />
    )
    let manualAddress = (
      <ManualAddress
        address={address}
        placeholder={placeholder}
        update={updateAddress}
        clear={resetAddress}
        onBlur={onBlur}
        inputDisabled={inputDisabled}
        error={error}
        suggest={suggest}
        toggleSuggest={this.toggleSuggest}
        showSuggestAddress={showSuggestAddress}
      />
    )
    return (
      <div className="input-address">
        {suggest ? googleAddress : manualAddress}
      </div>
    )
  }
}

export default AddressForm
