import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import {
  Alert,
  Box,
  Button,
  Flex,
  Spinner,
} from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import MembersList from 'app/sm/team_viewing_preference/components/members_list'
import {
  deleteTeamMember,
  getTeamMembersList,
} from 'app/sm/team_viewing_preference/components/members_list/api'
import RemoveTeamMemberModal from 'app/sm/team_viewing_preference/components/members_list/removeTeamMemberModal'
import InviteTeamMembersModal from 'app/sm/team_viewing_preference/setup/inviteTeamMemberModal'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

const StyledIntro = styled(Box)`
  line-height: ${({ theme }) => theme.lineHeights.title};
  margin-top: ${({ theme }) => theme.space[5]}px;
  margin-bottom: ${({ theme }) => theme.space[7]}px;
`

function TeamMembers({ teamGUID, isAdmin, invite = {} }) {
  const {
    loadingStates: teamMembersLoadingStates,
    loadingStatesHelpers: teamMembersLoadingStateHelpers,
  } = useLoadingStates()

  const [removeTeamMemberDetails, setRemoveTeamMemberDetails] = useState({
    modalOpen: false,
    name: '',
    guid: '',
    email: '',
  })

  const [modifiedInvite, setModifiedInvite] = useState({
    name: '',
    email: '',
  })
  const [inviteTeamMembersModalOpen, setInviteTeamMembersModal] =
    useState(false)
  const [teamMembersList, setTeamMembersList] = useState([])
  const fetchTeamMembers = () => {
    teamMembersLoadingStateHelpers.startLoading()
    getTeamMembersList(teamGUID)
      .then(({ data }) => {
        setTeamMembersList(data)
        teamMembersLoadingStateHelpers.markDoneSuccessfully()
      })
      .catch((err) => {
        teamMembersLoadingStateHelpers.setError(err)
      })
  }
  useEffect(() => {
    fetchTeamMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (invite && invite.isShown) {
      setInviteTeamMembersModal(true)
      const { inviteData = {} } = invite
      const {
        firstName: invitedFirstName = '',
        lastName: invitedLastName = '',
        email: invitedEmail = '',
      } = inviteData
      setModifiedInvite({
        name: invitedFirstName + invitedLastName,
        email: invitedEmail,
      })
    }
  }, [invite])

  const memberTools = [
    {
      text: 'Remove',
      enableTool: true,
    },
  ]
  const onClickRemoveHandler = (item) => {
    const { firstName = '', lastName = '', guid = '', email = '' } = item
    setRemoveTeamMemberDetails({
      name: `${firstName} ${lastName}`,
      guid,
      modalOpen: true,
      email,
    })
  }

  const onClickConfirmRemoveTeamMember = (deleteUser) => {
    const payload = {
      email: removeTeamMemberDetails.email,
      teamMemberGUID: removeTeamMemberDetails.guid,
      deleteUser,
    }
    deleteTeamMember(teamGUID, payload)
      .then(() => {
        if (fetchTeamMembers) {
          fetchTeamMembers()
        }
        toggleRemoveTeamMemberModal()
        snugNotifier.success('Team Member successfully removed')
      })
      .catch((error) => snugNotifier.error(error.message))
  }

  const toggleRemoveTeamMemberModal = () => {
    setRemoveTeamMemberDetails({
      ...removeTeamMemberDetails,
      modalOpen: !removeTeamMemberDetails.modalOpen,
    })
  }

  const toggleInviteTeamMembersModal = () => {
    setInviteTeamMembersModal(!inviteTeamMembersModalOpen)
  }

  return (
    <div>
      <Flex alignItems="center">
        <Display.MainHeader
          hasSeperator={false}
          text="Team Members"
          textClass="p0"
          componentClass="mb0"
        />
      </Flex>

      <Display.DefaultBodyText>
        <StyledIntro>
          Manage your team members, view activity and invite new team members.{' '}
          <a href="mailto:help@snug.com">learn more.</a>
        </StyledIntro>

        {isAdmin && (
          <Box mb={theme.space[5] + 'px'}>
            <Button
              sizeVariant="large"
              onClick={() => toggleInviteTeamMembersModal()}
            >
              Invite New Team Member
            </Button>
          </Box>
        )}
        {teamMembersLoadingStates.state === loadingStatesIds.LOADING ? (
          <Flex justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <>
            {teamMembersLoadingStates.state !== loadingStatesIds.ERROR ? (
              <MembersList
                teamMembersList={teamMembersList}
                showToolsButton={isAdmin}
                memberTools={memberTools}
                onClickHandler={onClickRemoveHandler}
                showAvatar={false}
              />
            ) : (
              <>
                <Alert variant="warningWithBg" mt={theme.space[3] + 'px'}>
                  <div>{teamMembersLoadingStates.error.message}</div>
                </Alert>
              </>
            )}
          </>
        )}
        {inviteTeamMembersModalOpen && (
          <InviteTeamMembersModal
            toggleModal={toggleInviteTeamMembersModal}
            teamGUID={teamGUID}
            initialInvite={modifiedInvite}
            onSuccessfulInvite={fetchTeamMembers}
          />
        )}
        {removeTeamMemberDetails.modalOpen && (
          <RemoveTeamMemberModal
            toggleModal={toggleRemoveTeamMemberModal}
            name={removeTeamMemberDetails.name}
            email={removeTeamMemberDetails.email}
            onClickConfirmRemoveTeamMember={onClickConfirmRemoveTeamMember}
          />
        )}
      </Display.DefaultBodyText>
    </div>
  )
}

export default TeamMembers
