export const convertToEFTValues = (leaseattachments) => {
  /**
   * in this object leaseattachments we have these keys:
   * rent_account, rent_bsb, rent_eft_account_name
   * and also it is having keys like
   * - rent_account_2, rent_bsb_2, rent_eft_account_name_2
   * - rent_account_3, rent_bsb_3, rent_eft_account_name_3
   *
   * we need to convert this to an array of objects like this:
   * [
   * { account: rent_account, bsb: rent_bsb, accountName: rent_eft_account_name },
   * { account: rent_account_2, bsb: rent_bsb_2, accountName: rent_eft_account_name_2 },
   * { account: rent_account_3, bsb: rent_bsb_3, accountName: rent_eft_account_name_3 }
   * ]
   */

  const EFTValues = []
  const keys = Object.keys(leaseattachments)
  const rentKeys = keys.filter((key) => key.startsWith('rent_account'))

  rentKeys.forEach((key) => {
    const index = key.split('_')[2]
    if (index) {
      const EFTPaymentAccountNum = leaseattachments[key]
      const EFTPaymentBSB = leaseattachments[`rent_bsb_${index}`]
      const EFTPaymentAccountName =
        leaseattachments[`rent_eft_account_name_${index}`]

      EFTValues[+index - 1] = {
        EFTPaymentAccountNum,
        EFTPaymentBSB,
        EFTPaymentAccountName,
      }
    } else {
      const EFTPaymentAccountNum = leaseattachments[key]
      const EFTPaymentBSB = leaseattachments.rent_bsb
      const EFTPaymentAccountName = leaseattachments.rent_eft_account_name

      EFTValues[0] = {
        EFTPaymentAccountNum,
        EFTPaymentBSB,
        EFTPaymentAccountName,
      }
    }
  })

  return EFTValues
}

export const formatBeforeSubmitEFTValues = (EFTValues) => {
  let result = {}
  EFTValues.forEach((value, index) => {
    if (index === 0) {
      result = {
        ...result,
        rent_account: value['EFTPaymentAccountNum'],
        rent_bsb: value['EFTPaymentBSB'],
        rent_eft_account_name: value['EFTPaymentAccountName'],
      }
    } else {
      result = {
        ...result,
        [`rent_account_${index + 1}`]: value['EFTPaymentAccountNum'],
        [`rent_bsb_${index + 1}`]: value['EFTPaymentBSB'],
        [`rent_eft_account_name_${index + 1}`]: value['EFTPaymentAccountName'],
      }
    }
  })

  return result
}

export const getAttrRemoved = (leaseattachmentsOld, leaseattachmentsNew) => {
  try {
    const attRemoved = {}
    Object.keys(leaseattachmentsOld).forEach((key) => {
      if (
        key.includes('rent_bsb') ||
        key.includes('rent_account') ||
        key.includes('rent_eft_account_name')
      ) {
        if (!leaseattachmentsNew[key]) {
          attRemoved[key] = leaseattachmentsOld[key]
        }
      }
    })
    return Object.keys(attRemoved)
  } catch (error) {
    return []
  }
}
