import React from 'react'

import { Route } from 'react-router-dom'

import ErrorMessages from 'app/constants/error_messages'
import {
  invalidDocExtensionExists,
  invalidDocSizeExists,
} from 'app/shared_components/helpers'
import { hasAdvertisedAd, routes, validateAddress } from 'app/sm/helpers'
import Amenities from 'app/sm/ppp/property_components/property_form/amenities/amenities_container'
import Photos from 'app/sm/ppp/property_components/property_form/photos'
import Basics from 'app/sm/ppp/property_components/property_form/property_basics'
import Description from 'app/sm/ppp/property_components/property_form/property_description'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
} from 'app/utils/loading-states'
import { errorMessageForInvalidFileTypeImage } from 'app/utils/text/helpers'

const U = Object.assign
const isArray = (a) => typeof a === 'object' && a.length !== undefined
const boolToLetter = (b) => (b ? 't' : 'f')
const typeNum = (val) =>
  (val === undefined && 1) ||
  (isArray(val) && val.length === 0 && 2) ||
  (isArray(val) && 3)

class PropertyForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      Validations: {
        address: {
          ok: true,
          error: '',
        },
      },
      bedrooms: null,
      bathrooms: null,
      garages: null,
      carports: null,
      description: '',
      type: 1,
      landSize: null,
      floorspace: null,
      commonAmenities: {},
      moreAmenities: {},
      photos:
        'https://i3.au.reastatic.net/456x342/ffc41daa142bdae5668a66ef7bf84403e76a86cd7a9e4608aad2a055d8dc1cb4/image2.jpg;https://i2.au.reastatic.net/456x342/15bbb6353b6fb356303ba7d1ecb85cc7bb45bbc7d306bddfffbe3cca813b8c63/main.jpg',
      title: '',
      topQualities: ';;',
      idealFor: '',
      livingAreaDesc: '',
      bedroomsDesc: '',
      buildingDesc: '',
      streetDesc: '',
      summary: '50;50;50;50',
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        address2: '',
        suburb: '',
        state: '',
        postcode: '',
        country: 'Australia',
        lat: null,
        lng: null,
        friendlyName: '',
        googleId: '',
      },
      images: [],
      photosValidationError: '',
      managingAgentID: null,
      entryDetailsLoadingStates: createInitialLoadingState(),
    }
    this.entryDetailsLoadingStateUtils = createLoadingStateUtils((state) =>
      this.setState({
        entryDetailsLoadingStates: state,
      }),
    )
  }

  componentDidMount() {
    let {
      fetchPropertyAttributes,
      fetchProperty,
      resetProperty,
      resetAdvertisement,
    } = this.props
    let { id } = this.props.match.params
    resetAdvertisement()
    fetchPropertyAttributes()
    if (id) {
      fetchProperty(id)
      window.onbeforeunload = () => true
    } else {
      resetProperty()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { property } = nextProps
    this.setState(property)

    if (nextProps.property && nextProps.property.guidID) {
      // for route /sm/properties/update/:id/basics
      if (nextProps.match.path.includes('/sm/properties/update/:id')) {
        if (
          nextProps.teams !== this.props.teams ||
          nextProps.property.agencyID !== this.props.property.agencyID
        ) {
          const currentTeam = nextProps.teams.find(
            (team) => team.guid === nextProps.property.agencyID,
          )
          this.props.changeTeam(currentTeam)
        }
      }

      // for route '/sm/properties/create' and '/teams/:teamSlug/sm/properties/create'
      if (
        nextProps.match.path.includes('/sm/properties/create') ||
        nextProps.match.path.includes('/teams/:teamSlug/sm/properties/create')
      ) {
        if (
          nextProps.teams !== this.props.teams ||
          nextProps.match.params.teamSlug !== this.props.match.params.teamSlug
        ) {
          const currentTeam = nextProps.teams.find(
            (team) => team.slug === nextProps.match.params.teamSlug,
          )
          this.props.changeTeam(currentTeam)
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { property: prevProperty } = prevProps
    const { property: nextProperty } = this.props

    const { guidID: prevPropertyGuidId = '' } = prevProperty || {}
    const { guidID: nextPropertyGuidId = '' } = nextProperty || {}

    if (prevPropertyGuidId !== nextPropertyGuidId) {
      this.loadListingManagement(nextProperty.guidID, nextProperty.agencyID)
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null
    this.props.resetProperty()
  }

  _addCheck(checks, id) {
    let index = checks.indexOf(id)
    if (index == -1) {
      checks.push(id)
    }
    return checks
  }

  _removeChecks(checks, ids) {
    return checks.filter((id) => !ids.includes(id))
  }

  _toggleCheck(checks, id, parentId, subIds) {
    let index = checks.indexOf(id)
    if (index == -1) {
      checks.push(id)
      if (parentId) {
        checks = this._addCheck(checks, parentId)
      }
    } else {
      checks.splice(index, 1)
      if (subIds) {
        checks = this._removeChecks(checks, subIds)
      }
    }
    return checks
  }

  invalidPhotoExtensionExists = (photosArr) => {
    const extensionArray = ['svg', 'png', 'pdf', 'tif', 'png', 'jpg', 'jpeg']
    let firstInvalidPhoto = photosArr.find((item) => {
      let extension = item.name.substring(
        item.name.lastIndexOf('.') + 1,
        item.name.length,
      )
      return !extensionArray.includes(extension)
    })
    return firstInvalidPhoto
  }

  invalidPhotoSizeExists = (photosArr) => {
    let firstInvalidPhoto = photosArr.find((item) => {
      return (firstInvalidPhoto = item.size / 1024 / 1024 >= 5)
    })
    return firstInvalidPhoto
  }

  legacyUpdateCheckbox = (parentId, subIds) => {
    return (event) => {
      let { name, id } = event.currentTarget
      let checks = this.state[name].split(';')
      checks = this._toggleCheck(checks, id, parentId, subIds)
      checks = checks.filter((a) => a != '').join(';')
      this.setState({ [name]: checks })
    }
  }

  resetAddress = () => {
    return () => {
      this.setState({
        address: {
          unitNumber: '',
          streetNumber: '',
          streetName: '',
          address2: '',
          suburb: '',
          state: '',
          postcode: '',
          country: 'Australia',
          lat: null,
          lng: null,
          friendlyName: '',
          googleId: '',
        },
      })
    }
  }

  update = (field) => {
    return (event) =>
      this.setState({
        [field]: event.target.value,
      })
  }

  updateAddress = (field) => {
    return (event) => {
      let { address } = this.state
      let { value } = event.target
      if (field === 'bedRoomCount') {
        address[field] = parseInt(value)
      } else {
        address[field] = value
      }
      this.setState({ address })
      this.validate('ADDRESS', address)
    }
  }

  updateCheckbox = (id, isCommonAmenitie, parentId, isSub = false) => {
    return (event) => {
      const states = new Map()
      const { commonAmenities, moreAmenities } = this.state
      const amenity = isCommonAmenitie ? commonAmenities : moreAmenities

      states.set('t1', (P) => U(P.previousState, { [P.parentId]: [P.id] }))

      states.set('t2', (P) => states.get('t1')(P))

      states.set('t3', (P) =>
        U(P.previousState, {
          [P.parentId]:
            P.previousState[P.parentId].find((k) => k.toString() === P.id) !==
            undefined
              ? P.previousState[P.parentId].filter((k) => k.toString() !== P.id)
              : P.previousState[P.parentId].concat(id.toString()),
        }),
      )

      states.set('f1', (P) => U(P.previousState, { [P.id]: [] }))

      states.set('f2', (P) => U(P.previousState, { [P.id]: undefined }))

      states.set('f3', (P) => states.get('f2')(P))

      const nextState = states.get(
        [
          boolToLetter(isSub),
          typeNum(
            isSub ? amenity[parentId.toString()] : amenity[id.toString()],
          ),
        ].join(''),
      )({
        previousState: amenity,
        parentId: parentId && parentId.toString(),
        id: id.toString(),
      })

      this.setState({
        [isCommonAmenitie ? 'commonAmenities' : 'moreAmenities']: nextState,
      })
    }
  }

  updateGoogleAddress = (address) => {
    this.setState({ address })
    this.validate('ADDRESS', address)
  }

  updateList = (name) => {
    return (event) => {
      let list = this.state[name].split(';')
      let { id, value } = event.target
      list[id] = value
      list = list.join(';')
      this.setState({ [name]: list })
    }
  }

  updateNumber = (field) => {
    if (field === 'bedrooms') {
      return (event) =>
        this.setState({
          [field]: event.target.value.toString(),
        })
    }
    return (event) =>
      this.setState({
        [field]: parseInt(event.target.value),
      })
  }

  updateDescriptionText = () => {
    return (event) =>
      this.setState({
        description: event.target.value.replace(/\n/g, '<br />'),
      })
  }

  updatePhotos = (files) => {
    const { id } = this.props.match.params
    const photos = document.getElementById('photos')
    let photosArr =
      files !== undefined
        ? files
        : Object.keys(photos.files).map((k) => photos.files[k])

    const findInvalidDocExtension = invalidDocExtensionExists(photosArr, 'pic')
    const findInvalidDocSize = invalidDocSizeExists(photosArr)

    if (findInvalidDocExtension) {
      this.setState({
        photosValidationError: errorMessageForInvalidFileTypeImage(
          findInvalidDocExtension.name,
        ),
      })
    } else if (findInvalidDocSize) {
      this.setState({
        photosValidationError: `The photo size of ${findInvalidDocSize.name} can not exceed 10mb`,
      })
    } else {
      this.props.uploadPhoto(id, files)
      this.setState({ photosValidationError: '' })
    }
  }

  validate(key, value) {
    switch (key) {
      case 'ADDRESS':
        const { ok, errorCode } = validateAddress(value)

        let addressValidation = this.state.Validations.address
        addressValidation.ok = ok
        addressValidation.error = ErrorMessages[errorCode]

        this.setState({
          Validations: Object.assign({}, this.state.Validations, {
            address: addressValidation,
          }),
        })

        return
      default:
        return
    }
  }

  loadListingManagement(propertyId, agencyId) {
    return new Promise((resolve, reject) => {
      if (!propertyId || !agencyId) {
        resolve()
      }

      this.entryDetailsLoadingStateUtils.startLoading()
      this.props
        .fetchPropertyEntryDetails?.(agencyId, propertyId)
        .then(({ managingAgentID }) => {
          this.setState(
            {
              managingAgentID,
            },
            () => this.entryDetailsLoadingStateUtils.markDoneSuccessfully(),
          )
        })
        .catch((err) => {
          this.setState(
            {
              managingAgentID: null,
            },
            () => this.entryDetailsLoadingStateUtils.setError(err),
          )
        })
        .finally(() => {
          resolve()
        })
    })
  }

  render() {
    let {
      createProperty,
      updateProperty,
      attributes,
      deletePhoto,
      setMainImage,
      error,
      spinner,
      property,
      responseText,
      updatePropertyClicked,
    } = this.props
    let { photosValidationError, managingAgentID, entryDetailsLoadingStates } =
      this.state
    const ad = hasAdvertisedAd(property) || null
    // if (error) {
    //   return (
    //     <div className='alert alert-danger disposable-alert'>
    //       <i onClick={() => this.props.ping()} className="fa fa-chevron-left mr15" aria-hidden="true" />{error}
    //     </div>
    //   )
    // }

    return (
      <div>
        {[
          '/sm/properties/create',
          '/teams/:teamSlug/sm/properties/create',
          routes.teams.properties.createArchived,
          '/sm/properties/update/:id/basics',
        ].map((path) => (
          <Route
            key={path}
            path={path}
            render={({ match, history }) => {
              const isCreateArchiveProperty =
                path === routes.teams.properties.createArchived
              const createArchivedPropertyProps = {
                onBackClicked: () => history.goBack(),
                showBreadcrumbs: false,
              }

              return (
                <Basics
                  state={this.state}
                  params={match.params}
                  history={history}
                  currentTeam={this.props.currentTeam}
                  attributes={attributes}
                  error={error}
                  update={this.update}
                  updateNumber={this.updateNumber}
                  updateDescriptionText={this.updateDescriptionText}
                  updateAddress={this.updateAddress}
                  updateGoogleAddress={this.updateGoogleAddress}
                  resetAddress={this.resetAddress}
                  createProperty={
                    /update/.test(path) ? undefined : createProperty
                  }
                  property={property}
                  updateProperty={updateProperty}
                  ad={ad}
                  addressValidation={this.state.Validations.address}
                  updatePropertyClicked={updatePropertyClicked}
                  {...(isCreateArchiveProperty
                    ? createArchivedPropertyProps
                    : {})}
                  managingAgentId={managingAgentID}
                  managingAgentLoadingStates={entryDetailsLoadingStates}
                  onManagingAgentUpdated={() => this.loadListingManagement}
                />
              )
            }}
          />
        ))}

        {['/sm/properties/update/:id/amenities'].map((path) => (
          <Route
            key={path}
            path={path}
            render={({ match }) => (
              <Amenities
                state={this.state}
                params={match.params}
                attributes={attributes}
                error={error}
                updateCheckbox={this.updateCheckbox}
                updateProperty={updateProperty}
                ad={ad}
              />
            )}
          />
        ))}
        {['/sm/properties/update/:id/photos'].map((path) => (
          <Route
            key={path}
            path={path}
            render={({ match }) => (
              <Photos
                state={this.state}
                params={match.params}
                error={error}
                updatePhotos={this.updatePhotos}
                updateProperty={updateProperty}
                setMainImage={setMainImage}
                deletePhoto={deletePhoto}
                spinner={spinner}
                property={property}
                ad={ad}
                responseText={responseText}
                photosValidationError={photosValidationError}
              />
            )}
          />
        ))}
        {['/sm/properties/update/:id/description'].map((path) => (
          <Route
            key={path}
            path={path}
            render={({ match }) => (
              <Description
                state={this.state}
                params={match.params}
                error={error}
                update={this.update}
                updateCheckbox={this.legacyUpdateCheckbox}
                updateList={this.updateList}
                updateProperty={updateProperty}
                ad={ad}
              />
            )}
          />
        ))}
      </div>
    )
  }
}

export default PropertyForm
