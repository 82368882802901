import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

import headerImg01 from 'app/assets/icons/header-img-01.png'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'

class RenterDeclined extends React.Component {
  constructor(props) {
    super(props)
    let { identityDoc } = props
    this.state = {
      declinedReason: 1,
      description: '',
    }
  }

  UNSAFE_componentWillMount() {
    let {
      fetchDeclinedReason,
      fetchRequestSummary,
      getSecondaryTenantSummary,
    } = this.props
    let role = getParameter('role')
    let requestID = getParameter('requestid')
    fetchDeclinedReason(requestID)
    if (role === 'secondary') {
      getSecondaryTenantSummary(requestID)
    } else {
      fetchRequestSummary(requestID)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { declined } = nextProps
    if (declined) {
      this.setState({
        declinedReason: declined.declineReason,
        description: declined.description,
      })
    }
  }

  declinedReason = () => {
    let { description } = this.state
    let { manager } = this.props
    return (
      <div>
        <h3 className="mb25">Your BondCover application needs some changes.</h3>
        <p>
          Your agent/landlord has indicated that the lease details in your
          BondCover application are incorrect:
        </p>
        <div className="request-declined-desc">
          <div className="desc">
            {description
              ? description
              : 'Your property manager has not left a comment for this.'}
          </div>
          <div className="agent">
            <div className="avatar avatar-small pink-bg">
              {manager && manager.firstName ? manager.firstName.charAt(0) : ''}{' '}
            </div>
            <div className="text">
              <h3>{manager ? manager.agencyName : ''}</h3>
              <h4>
                {manager ? manager.firstName + ' ' + manager.lastName : ''}
              </h4>
              {/* <small>date</small> */}
              {/* TODO: Add the above when endpoint supports it */}
            </div>
          </div>
        </div>
        <p>If you would like to continue, please submit another application.</p>
        <Link to="/home/overview" className="btn">
          Return to Dashboard
        </Link>
      </div>
    )
  }

  noDeclinedReason = () => {
    let { currentLease } = this.props
    return (
      <div>
        <h3 className="mb25">
          Sorry, your property manager is not currently accepting BondCover at
          this time.
        </h3>
        <p>
          Your agent/landlord has indicated they're not interested in offering
          BondCover for this property at this time.
        </p>
        <Link
          className="bc-hub-item-link delete"
          /*to={"/bc/delete?id=" + '123'*/ to={'/home/overview'}
        >
          <header>
            <i className="icon-couch" />
            <span>Current Lease</span>
          </header>
          <content>
            <p>
              {' '}
              {currentLease && currentLease.address
                ? currentLease.address.friendlyName +
                  ', ' +
                  currentLease.address.suburb +
                  ', ' +
                  currentLease.address.state +
                  ' ' +
                  currentLease.address.postcode
                : ''}
            </p>
            <p>
              {' '}
              {currentLease
                ? moment(currentLease.startDate).format('DD MMM YYYY')
                : ''}{' '}
              -{' '}
              {currentLease && currentLease.endDate
                ? moment(currentLease.endDate).format('DD MMM YYYY')
                : 'Rolling Lease'}{' '}
            </p>
            <p> ${currentLease ? currentLease.rate : ''} per week </p>
            <p> ${currentLease ? currentLease.bond : ''} bond </p>
          </content>
        </Link>
      </div>
    )
  }

  render() {
    let { declinedReason } = this.state
    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <div className="ilustration-box">
            <img src={headerImg01} />
          </div>
          {declinedReason === 1
            ? this.declinedReason()
            : this.noDeclinedReason()}
        </div>
      </div>
    )
  }
}

export default RenterDeclined
