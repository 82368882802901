import styled from 'styled-components'

import * as getters from 'app/components/design-system-components/theme/getters'
import {
  FollowsGridCategoriesContainer,
  FollowsGridContainer,
  FollowsGridTeamMemberContainer,
} from 'app/pages/teams/settings/personal/follows/FollowsGrid/FollowsGridContainer'

const StyledFollowsGridContainer = styled(FollowsGridContainer)`
  padding: ${getters.spacing(4)} ${getters.spacing(6)};
  background-color: ${getters.color('gray100')};
  color: ${getters.color('gray600')};
  font-weight: ${getters.fontWeight(6)};
`

export const FollowsGridHeader = () => {
  return (
    <StyledFollowsGridContainer>
      <FollowsGridTeamMemberContainer>
        Team member
      </FollowsGridTeamMemberContainer>
      <FollowsGridCategoriesContainer>
        Notifications
      </FollowsGridCategoriesContainer>
    </StyledFollowsGridContainer>
  )
}
