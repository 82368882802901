import { api } from 'app/utils/api/helpers'

export const addAgencyAliasId = (agencyGUID, aliasId, label) => {
  return api.post(`/admin/agencies/${agencyGUID}/alias-team-id`, {
    teamID: aliasId,
    label,
  })
}

export const deleteAgencyAliasId = (agencyGUID, aliasId) => {
  return api.delete(`/admin/agencies/${agencyGUID}/alias-team-id/${aliasId}`)
}

export const getActivationLatestLog = (agencyGUID) => {
  return api.get(`/admin/agencies/${agencyGUID}/audit/activation/latest`)
}
