export const color =
  (colorName) =>
  ({ theme }) =>
    theme.colors[colorName]

export const spacing =
  (spaceIndex) =>
  ({ theme }) =>
    theme.space[spaceIndex] + 'px'

export const radius =
  (radiusIndex) =>
  ({ theme }) =>
    theme.radii[radiusIndex]

export const fontWeight =
  (weightIndex) =>
  ({ theme }) =>
    theme.fontWeights[weightIndex]

export const fontSize =
  (fontSizeIndex) =>
  ({ theme }) =>
    theme.fontSizes[fontSizeIndex] + 'px'

export const lineHeight =
  (lineHeightAlias) =>
  ({ theme }) =>
    theme.lineHeights[lineHeightAlias]
