import React from 'react'

import styled from 'styled-components'

const ContentWrapper = styled.div`
  @media (min-width: 992px) {
    flex-grow: 1;
    padding: 0 10px;
  }
`

function Content(props) {
  return <ContentWrapper>{props.children}</ContentWrapper>
}

export default Content
