import React from 'react'

import PropTypes from 'prop-types'

import * as Display from 'app/components/display/display'
import {
  SNUG_PRIVACY_URL,
  SNUG_REFERRAL_AGREEMENT_TEMPLATE,
  SNUG_TERMS_URL,
} from 'app/sm/helpers'

function AcceptButton(props) {
  const { name, onClick, disabled } = props

  return (
    <div className="two-col-box-flex-row sm64 width-50 col-sm-5">
      <div className="col-first">
        <button className="btn" onClick={onClick} disabled={disabled}>
          {name}
        </button>
      </div>
    </div>
  )
}

AcceptButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

function AcceptDeclineButtons(props) {
  const { handleOnClick, disabled, handleDecline } = props

  return (
    <div className="mb20 col-sm-6 mobile-request-viewing">
      <Display.GenericButton
        size="large"
        onClick={handleOnClick}
        text="Accept invitation"
        buttonType="secondary"
        buttonStyleClass="col-sm-5 pl0"
        disabled={disabled}
      />
      <Display.GenericButton
        size="large"
        onClick={handleDecline}
        buttonType="destructive-hollow"
        text="Decline"
        buttonStyleClass="col-sm-5 ml15 mt10-mobile ml0-mobile"
      />
    </div>
  )
}

export const AgencyInviteAccept = ({
  disabled,
  error,
  handleOnClick,
  userName,
  agencyName,
  handleDecline,
}) => {
  return (
    <div>
      {userName && agencyName && (
        <div>
          <h4>Hi {userName}</h4>
          <p className="mt10">
            You have been invited to <b>{agencyName}</b>.
          </p>
        </div>
      )}
      <div className="mt-10">
        By accepting this invite you agree to our{' '}
        <a href={SNUG_TERMS_URL} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
        ,{' '}
        <a href={SNUG_PRIVACY_URL} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          href={SNUG_REFERRAL_AGREEMENT_TEMPLATE}
          target="_blank"
          rel="noopener noreferrer"
        >
          Referral Agreement
        </a>
        .
      </div>
      <div className="mt20 mb30">
        Need help registering? contact our friendly team on Live Chat or{' '}
        <a href="mailto:help@snug.com">help@snug.com</a>.
      </div>
      <div className="row width-100">
        <AcceptDeclineButtons
          handleOnClick={handleOnClick}
          disabled={disabled}
          handleDecline={handleDecline}
        />
      </div>
      {error && (
        <div>
          <ul className="snug-tips">
            <li>{error}</li>
          </ul>
        </div>
      )}
    </div>
  )
}

AgencyInviteAccept.propTypes = {
  error: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func.isRequired,
}
