import React from 'react'

import { Link } from 'react-router-dom'

import { history } from 'app/shared_components/router'

class ToDoModal extends React.Component {
  constructor(props) {
    super(props)
  }

  closeModal() {
    window.location.href = `/sm/property/${this.props.propertyId}/todos`
  }

  render() {
    const { propertyId } = this.props

    return (
      <div className="sm-modal">
        <div className="sm-modal-wrapper sm-modal-medium">
          <div onClick={() => this.closeModal()} className="sm-modal-close" />
          <div className="sm-modal-content">
            <h3 className="mb30">
              <i className="icon-todos ibm mr10"></i>
              <span className="ibm">Add a ToDo</span>
            </h3>

            <h6>Select To Do Category:</h6>
            <div className="button-group mt20">
              <button className="btn btn-gray btn-medium mr10">Repair</button>
              <button className="btn btn-pink btn-medium mr10">Bill</button>
              <button className="btn btn-gray btn-medium mr10">Lease</button>
              <button className="btn btn-gray btn-medium mr10">
                Inspection
              </button>
              <button className="btn btn-gray btn-medium">Something</button>
            </div>

            <div className="row mt30">
              <div className="col-sm-12">
                <div className="input-box">
                  <input required type="text" />
                  <label>ToDo Title</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-box">
                  <textarea required rows="4"></textarea>
                  <label>Description</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="input-box">
                  <div className="prefix">
                    <i className="icon-date"></i>
                  </div>
                  <input required type="text" />
                  <label>Due Date</label>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-box">
                  <div className="prefix">
                    <i className="icon-recent"></i>
                  </div>
                  <input required type="text" />
                  <label>Due Time</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-box">
                  <div className="prefix">$</div>
                  <input required type="text" />
                  <label>Amount</label>
                </div>
              </div>
            </div>

            <div className="row mt10 mb20">
              <div className="col-sm-6">
                <button className="btn btn-gray mb10">Cancel</button>
              </div>
              <div className="col-sm-6">
                <button className="btn mb10">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ToDoModal
