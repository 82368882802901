import React from 'react'

import moment from 'moment'

import { history } from 'app/shared_components/router'
import { capitalizeFirstLetter, urlTo } from 'app/sm/helpers'
import SectionHeader from 'app/sm/renter_background_check_report/components/status-sector/component'
import { formatPhoneNumber } from 'app/utils/phonenumber/helpers'

import 'app/sm/contact_list/contact_item/style.scss'

const ContactItem = ({
  firstName,
  lastName,
  phone,
  email,
  avatar,
  updatedAt,
  teamSlug,
  contactGUID,
  contactType,
}) => {
  const formattedPhoneNumber = formatPhoneNumber(phone)
  return (
    <div
      className="applicant-container no-page-break cursor-pointer"
      onClick={() =>
        contactType === 'Contact' &&
        history.push(urlTo('editContact', { teamSlug, contactGUID }))
      }
    >
      <div className="applicant-upper-container flex-direction-row">
        <div className="household-applicant-table avatar-container mt0">
          <div className="avatar application-avatar contact-list-avatar">
            {avatar && <img src={avatar} className="application-avatar-img" />}
            {!avatar && (
              <span className="app-avatar-initial-position">
                {capitalizeFirstLetter(firstName.substring(0, 1))}
                {capitalizeFirstLetter(lastName.substring(0, 1))}
              </span>
            )}
          </div>
        </div>
        <div className="applicant-info-container">
          <div className="display-flex">
            <h4 className="applicant-name mr5">
              {capitalizeFirstLetter(firstName)}{' '}
              {capitalizeFirstLetter(lastName)}
            </h4>
            <SectionHeader statusText={contactType} statusClass="Pending" />
          </div>
          <div className="applicant-info-sub-container">
            <div className="applicant-info-text">{formattedPhoneNumber}</div>
            <div className="spot-seperator"></div>
            <div className="applicant-info-text">{email}</div>
          </div>
          <div className="applicant-info-sub-container">
            <div className="applicant-info-text mobile-only">
              {moment(updatedAt).format('HH:mm DD MMM YYYY')}
            </div>
          </div>
        </div>
      </div>
      <div className="applicant-status-container tablet-only">
        <div className="updated-date">
          {moment(updatedAt).format('HH:mm DD MMM YYYY')}
        </div>
      </div>
    </div>
  )
}

export default ContactItem
