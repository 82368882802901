import React from 'react'

import { Alert, Box, TextSpan } from 'app/components/design-system-components'
import { PageHeader } from 'app/components/design-system-components/page/Header'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import Breadcrumbs from 'app/session/bc_profile/profile_update/Breadcrumbs'

const Header = () => {
  const crumbs = [
    { text: 'Profile', variant: 'basic' },
    { text: 'My Account', variant: 'focused' },
  ]

  return (
    <>
      <Box mb={`${theme.space[1]}px`}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>

      <PageHeader title="My Account" mb={`${theme.space[4]}px`} />

      <Box mb={`${theme.space[8]}px`}>
        <TextSpan>Update your account details</TextSpan>
      </Box>

      <Box mb={`${theme.space[8]}px`} maxWidth={`${theme.space[12]}px`}>
        <Alert variant="blueWithBg">
          If you change your Email address or Mobile number you will be prompted
          to Log In.
        </Alert>
      </Box>
    </>
  )
}

export default Header
