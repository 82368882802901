import React from 'react'

import { Box, Flex } from 'app/components/design-system-components'

function ConfirmationPage() {
  return (
    <Flex justifyContent="center" alignItems="center">
      <Box>
        <h1>Thank You, your response has been submitted</h1>
        <p className="font-size-20">
          <Flex justifyContent="center" alignItems="center">
            A confirmation email has been issued to the Property Manager
          </Flex>
        </p>
      </Box>
    </Flex>
  )
}

export default ConfirmationPage
