export const MAX_WEEKLY_INCOME = 99999999.99
export const MAX_FORTNIGHTLY_INCOME = 99999999.99
export const MAX_MONTHLY_INCOME = 99999999.99
export const MAX_ANNUAL_INCOME = 99999999.99

const DAYS_IN_WEEK = 7
const DAYS_IN_YEAR = 365
const MONTHS_IN_YEAR = 12

export const currencyFormat = (num: number, fractionDigits: number = 2) => {
  if (isNaN(num)) return null
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: fractionDigits,
  })
  return formatter.format(num)
}

export const getMonthlyFromWeeklyRent = (weeklyRent: number = 0) => {
  return ((weeklyRent / DAYS_IN_WEEK) * DAYS_IN_YEAR) / MONTHS_IN_YEAR
}

export const formatRent = (
  amount: number,
  roundDown: boolean,
  fractionDigits: number = 2,
) => {
  if (isNaN(amount)) return amount
  const result = roundDown ? Math.floor(amount) : amount
  return result.toFixed(fractionDigits)
}
