import React from 'react'

import { Link } from 'react-router-dom'

import { Box } from 'app/components/design-system-components'
import Card from 'app/components/design-system-components/Card'
import { Lightning } from 'app/components/design-system-components/icons/snug-specific'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { urlIds, urlTo } from 'app/sm/helpers'
import StepsHeading from 'app/sm/team_viewing_preference/setup/card_components/stepHeading'

import { StyledSetup } from '..'

function WizardForNonValid({ teamSlug }) {
  const internalComponent = (
    <StyledSetup>
      <Box mt={theme.space[4] + 'px'}>
        Start receiving better quality rental applications with these quick
        steps (takes 2 minutes).
      </Box>
      <Box mt={theme.space[7] + 'px'}>
        <Card
          headerTitle={
            <StepsHeading text={'Step 1. Invite your team members'} />
          }
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          isCollapsible={true}
          internalComponent={
            <>
              Visit your team settings and{' '}
              <Link to={urlTo(urlIds.teams.settings.members, { teamSlug })}>
                invite your team members
              </Link>
              .
            </>
          }
        />
      </Box>
      <Box mt={theme.space[7] + 'px'}>
        <Card
          headerTitle={
            <StepsHeading
              text="Step 2. Add your logo"
              videoURL="https://youtu.be/XiUgcTvJU14"
            />
          }
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          isCollapsible={true}
          internalComponent={
            <>
              {' '}
              Send an email to <a href="mailto:help@snug.com">help@snug.com</a>,
              attach your Agency logo and we'll brand your team dashboard,
              property pages and communications.{' '}
              <a
                href="https://help.snug.com/hc/en-us/articles/360001057435-Agency-Branding-How-to-Set-up-your-Logo-Banner-and-SMS-name"
                target="_blank"
                rel="noreferrer"
              >
                Learn more here.
              </a>
            </>
          }
        />
      </Box>
      <Box mt={theme.space[7] + 'px'} mb={theme.space[7] + 'px'}>
        <Card
          headerTitle={
            <StepsHeading
              text="Step 3. Apply Link"
              videoURL="https://youtu.be/Up1gReFx2es"
            />
          }
          forcedHeaderStyles={{ marginLeft: '-16px' }}
          isCollapsible={true}
          internalComponent={
            <>
              Use your Apply Link (
              <a href={`/apply/${teamSlug}`} target="_blank" rel="noreferrer">
                snug.com/apply/{teamSlug}
              </a>
              ) everywhere: adverts, your website, stockists, etc.{' '}
              <a
                href="https://help.snug.com/hc/en-us/articles/360001724195-Insert-your-team-s-Snug-Apply-Link-in-your-Portal-Adverts-and-use-it-everywhere-"
                target="_blank"
                rel="noreferrer"
              >
                Learn more here.
              </a>{' '}
              Activate viewing registration and apply buttons on portals
              including Domain{' '}
              <a href={`/teams/${teamSlug}/settings/portals`} rel="noreferrer">
                here
              </a>
              .
            </>
          }
        />
      </Box>
    </StyledSetup>
  )

  return (
    <Card
      headerTitle="Quick Start"
      internalComponent={internalComponent}
      headerImage={<Lightning />}
    />
  )
}

export default WizardForNonValid
