import React, { useContext, useState } from 'react'

import { Field, Form, Formik } from 'formik'
import styled from 'styled-components'

import { Box, Flex, TextSpan } from 'app/components/design-system-components'
import { InputField } from 'app/components/design-system-components/inputs'
import { SelectField } from 'app/components/design-system-components/inputs/Select/Select'
import ErrorMessages from 'app/constants/error_messages'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import theme from 'app/match/applicationReportPDF/assets/theme'
import AddressComponent from 'app/pages/SelfServiceTeamRegistration/agency_details/AddressComponent'
import {
  agencyDetailsFormFields,
  agencyDetailsFormValidation,
  defaultFranchise,
} from 'app/pages/SelfServiceTeamRegistration/agency_details/agency-details.form'
import { AgencySelfRegistrationContext } from 'app/pages/SelfServiceTeamRegistration/AgencyRegistrationContext'
import StepsFooter from 'app/pages/SelfServiceTeamRegistration/partials/StepsFooter'
import { franchiseGroups, validateAddress } from 'app/sm/helpers'

export const StyledFieldWrapper = styled(FieldWrapper)`
  label:nth-child(2),
  button {
    height: 48px !important;
    border: 1px solid ${theme.colors.gray300} !important;
  }
`

const AgencyDetails = () => {
  const { agency, setAgency, handleNextStep } = useContext(
    AgencySelfRegistrationContext,
  )

  const [addressErr, setAddressErr] = useState('')

  const submitAgencyDetails = (values, { setSubmitting }) => {
    const { ok, errorCode } = validateAddress(agency.address)
    if (!ok) {
      setAddressErr(ErrorMessages[errorCode])
      return
    }

    setAgency({
      ...agency,
      ...values,
      address: {
        ...agency.address,
      },
    })

    setSubmitting(false)

    handleNextStep()
  }

  const handleManualAddressChanged = (key) => {
    return (event) => {
      setAgency({
        ...agency,
        address: {
          ...agency.address,
          [key]: event.target.value,
        },
      })
    }
  }

  const updateGoogleAddress = (e) => {
    setAgency({
      ...agency,
      address: {
        ...e,
      },
    })
  }

  return (
    <div className="sm-property-create basic">
      <div>
        <Box mb={`${theme.space[4]}px`}>
          <TextSpan fontWeight="500" fontSize={`${theme.space[8]}px`}>
            Welcome to Snug
          </TextSpan>
        </Box>
        <p>Let's create your team (takes 3 minutes).</p>
      </div>

      <Formik
        initialValues={agency}
        onSubmit={submitAgencyDetails}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize={false}
        validate={agencyDetailsFormValidation}
      >
        {() => (
          <Form>
            <Box mb="16px">
              <h4 className="agency-title">Agency details</h4>
            </Box>

            <Flex>
              <Box width="50%" mr={`${theme.space[5]}px`}>
                <StyledFieldWrapper
                  id={agencyDetailsFormFields.agencyName}
                  name={agencyDetailsFormFields.agencyName}
                  required={true}
                  label="Trading Name (Eg. Ray White)"
                >
                  <Field
                    name={agencyDetailsFormFields.agencyName}
                    id={agencyDetailsFormFields.agencyName}
                    as={InputField}
                    placeholder="Trading Name (Eg. Ray White)"
                  />
                </StyledFieldWrapper>
              </Box>

              <Box width="50%">
                <StyledFieldWrapper
                  id={agencyDetailsFormFields.realEstateLicense}
                  name={agencyDetailsFormFields.realEstateLicense}
                  required={true}
                  label="Corporate real estate license"
                >
                  <Field
                    name={agencyDetailsFormFields.realEstateLicense}
                    id={agencyDetailsFormFields.realEstateLicense}
                    as={InputField}
                    placeholder="Corporate real estate license"
                  />
                </StyledFieldWrapper>
              </Box>
            </Flex>

            <Flex mt="16px">
              <Box width="50%" mr={`${theme.space[4]}px`}>
                <StyledFieldWrapper
                  id={agencyDetailsFormFields.franchiseGroup}
                  name={agencyDetailsFormFields.franchiseGroup}
                  required={true}
                  label="Are you part of a Franchise?"
                >
                  <SelectField
                    id={agencyDetailsFormFields.franchiseGroup}
                    name={agencyDetailsFormFields.franchiseGroup}
                    label="Select franchise group"
                    optionsHeight="300px"
                    options={[defaultFranchise, ...franchiseGroups.sort()].map(
                      (id) => ({
                        option: id,
                        optionMeta: {
                          id: id,
                          displayText: id,
                        },
                      }),
                    )}
                    OptionsHeaderRenderCmp={null}
                  />
                </StyledFieldWrapper>
              </Box>

              <Box width="50%">
                <StyledFieldWrapper
                  id={agencyDetailsFormFields.rentalPortfolioSize}
                  name={agencyDetailsFormFields.rentalPortfolioSize}
                  required={true}
                  label="Rental portfolio size"
                >
                  <Field
                    name={agencyDetailsFormFields.rentalPortfolioSize}
                    id={agencyDetailsFormFields.rentalPortfolioSize}
                    as={InputField}
                    placeholder="Rental portfolio size"
                  />
                </StyledFieldWrapper>
              </Box>
            </Flex>

            <Flex mt="16px" mb={`${theme.space[5]}px`}>
              <h4 className="agency-title">Business details</h4>
            </Flex>

            <Flex>
              <Box width="50%" mr={`${theme.space[4]}px`}>
                <StyledFieldWrapper
                  id={agencyDetailsFormFields.companyName}
                  name={agencyDetailsFormFields.companyName}
                  required={true}
                  label="Company Name"
                >
                  <Field
                    name={agencyDetailsFormFields.companyName}
                    id={agencyDetailsFormFields.companyName}
                    as={InputField}
                    placeholder="Company Name"
                  />
                </StyledFieldWrapper>
              </Box>

              <Box width="50%">
                <StyledFieldWrapper
                  id={agencyDetailsFormFields.abn}
                  name={agencyDetailsFormFields.abn}
                  required={true}
                  label="ABN"
                >
                  <Field
                    name={agencyDetailsFormFields.abn}
                    id={agencyDetailsFormFields.abn}
                    as={InputField}
                    placeholder="ABN"
                  />
                </StyledFieldWrapper>
              </Box>
            </Flex>

            <Flex>
              <Box width="100%">
                <AddressComponent
                  handleAddressChanged={(e) => {
                    updateGoogleAddress(e)
                  }}
                  handleManualAddressChanged={(e) =>
                    handleManualAddressChanged(e)
                  }
                  address={agency.address}
                  resetAddress={() => {}}
                  addressValidation={validateAddress(agency.address)}
                  addressErr={addressErr}
                />
              </Box>
            </Flex>

            <StepsFooter />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AgencyDetails
