import React from 'react'

const AgencyDashboardHeader = () => (
  <div className="sm-ra-card__header ok mb0 lg-pl0">
    <h4 className="prototype-header sm-agency-dashboard-header">
      <span className="text-center text-hide-mobile">Status</span>
      <span className="text-center text-hide-mobile">Address</span>
      <span className="text-center text-hide-mobile">Renter</span>
      <span className="text-center text-hide-mobile">Bond Amount</span>
      <span className="text-center text-hide-mobile">Expires</span>
    </h4>
  </div>
)

export default AgencyDashboardHeader
