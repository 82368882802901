import styled from 'styled-components'

export const AvatarPlaceholder = styled.div`
  float: left;
`

export const Summary = styled.ul`
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 3px 0 0;
  list-style: none;
  font-size: 16px;

  li {
    display: inline-block;
  }

  li + li {
    margin-left: 13px;
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 122px;
`
