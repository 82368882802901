import { apiBaseUrl } from 'config/env'

import { LogFetchError } from 'app/shared_components/helpers'
import * as sharedHelpers from 'app/shared_components/helpers'
import { api } from 'app/utils/api/helpers'

export const postPropertyDisclosuresForOffer = (
  agencyGUID,
  offerGUID,
  data,
) => {
  const url = `${apiBaseUrl}sm/agency/${agencyGUID}/offer/${offerGUID}/propertydisclosure`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(data),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelpers.returnAPIError)
}

export const saveManagementType = (typeValue, offerId) => {
  return api.put(`/sm/offers/${offerId}/managementtype`, {
    managementType: typeValue,
  })
}
