import { connect } from 'react-redux'

import { BondCoverReferral } from 'app/dashboard/components/bond_cover_referral'
import { sendBondCoverReferral } from 'app/dashboard/dashboard_actions'

const mapStateToProps = ({ dashboard }) => ({
  apiError: dashboard.error,
})

const mapDispatchToProps = (dispatch) => ({
  sendBondCoverReferral: (
    inviteType,
    inviterName,
    inviterEmail,
    inviteeEmail,
    sendSuccess,
    sendFail,
  ) =>
    dispatch(
      sendBondCoverReferral(
        inviteType,
        inviterName,
        inviterEmail,
        inviteeEmail,
        sendSuccess,
        sendFail,
      ),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(BondCoverReferral)
