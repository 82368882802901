import React from 'react'

import { Link } from 'react-router-dom'

import houseIcon1 from 'app/assets/icons/house01.jpg'

function Description() {
  return (
    <div className="sm-shadow-card description">
      <div className="header xs-mb20">
        <h4>What happened?</h4>
      </div>
      <div className="content">
        <h3 className="mb30">
          Broken tiles on the bathroom floor on the first floor.
        </h3>
        <p className="m0">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud
        </p>
        <hr />
        <ul className="images-list">
          <li style={{ backgroundImage: `url(${houseIcon1})` }}></li>
          <li style={{ backgroundImage: `url(${houseIcon1})` }}></li>
        </ul>
      </div>
    </div>
  )
}

export default Description
