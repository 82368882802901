import React, { Component } from 'react'

import BankAccountForm from 'app/bond_cover/renters/tenants/identity/bank_account_form'
import isEmptyObject from 'app/shared_components/check_empty_object'
import getParameter from 'app/shared_components/parameter_parser'
import * as validations from 'app/shared_components/validations'

class BankAccountFormContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requestID: null,
      accountName: '',
      bsb: '',
      accountNumber: '',
      request: {},
      currentLease: {},
      disabled: true,
      clicked: false,
      errors: {},
    }
  }

  UNSAFE_componentWillMount() {
    let { fetchBankAccount, getSecondaryTenantSummary } = this.props
    let requestID = getParameter('requestid')
    this.setState({
      requestID,
    })
    getSecondaryTenantSummary(requestID)
    let guidID = this.guid()
    if (guidID) {
      fetchBankAccount(requestID, guidID)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { request, currentLease, bankAccount, apiError } = nextProps
    let errors = {}
    this.setState({
      request: request,
      currentLease: currentLease,
      errors,
      disabled: false,
      clicked: false,
    })

    if (bankAccount && bankAccount.guidID) {
      this.setState({
        accountName: bankAccount.accountName,
        bsb: bankAccount.bsb,
        accountNumber: bankAccount.accountNumber,
        apiError: apiError,
      })
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    nextState.disabled = !(
      nextState.accountName &&
      nextState.bsb &&
      nextState.accountNumber &&
      isEmptyObject(nextState.errors) &&
      !nextState.clicked
    )
  }

  setValidationErrors(field, error) {
    let { errors } = this.state
    if (error.length === 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  guid() {
    return getParameter('id')
  }

  submit = () => {
    let { bankAccount, createBankAccount, updateBankAccount } = this.props
    let { request, accountName, bsb, accountNumber } = this.state
    let { guidID } = request
    let isPrimary = false
    if (this.validateAll()) {
      this.setState({ clicked: true })
      let formDetails = {
        accountName: accountName,
        bsb: bsb,
        accountNumber: accountNumber,
      }
      if (bankAccount && bankAccount.accountNumber) {
        updateBankAccount(guidID, formDetails, bankAccount.guidID, isPrimary)
      } else {
        createBankAccount(guidID, formDetails, isPrimary)
      }
    }
  }

  trimField = (field) => {
    return (event) => {
      let value = this.state[field].trim()
      this.validate(field, value)()
      this.setState({ [field]: value })
    }
  }

  update = (field) => {
    return (event) => {
      let { value } = event.target
      this.setState({ [field]: value })
    }
  }

  validate = (field, value) => {
    return (event) => {
      switch (field) {
        case 'accountName':
          return this.setValidationErrors(
            field,
            validations.validateName(value),
          )
        case 'bsb':
          return this.setValidationErrors(field, validations.validateBSB(value))
        case 'accountNumber':
          return this.setValidationErrors(
            field,
            validations.validateBankAccount(value),
          )
        default:
          return false
      }
    }
  }

  validateAll() {
    let valid = true
    let fields = ['accountName', 'bsb', 'accountNumber']
    fields.forEach((field) => {
      if (!this.validate(field, this.state[field])()) {
        valid = false
      }
    })
    return valid
  }

  render() {
    let {
      accountName,
      bsb,
      accountNumber,
      currentLease,
      disabled,
      clicked,
      errors,
      apiError,
      requestID,
    } = this.state
    let { address } = currentLease
    let { quote } = this.props
    return (
      <BankAccountForm
        requestID={requestID}
        quote={quote}
        address={address}
        bsb={bsb}
        accountName={accountName}
        accountNumber={accountNumber}
        currentLease={currentLease}
        errors={errors}
        apiError={apiError}
        disabled={disabled}
        clicked={clicked}
        update={this.update}
        trimField={this.trimField}
        submit={this.submit}
      />
    )
  }
}

export default BankAccountFormContainer
