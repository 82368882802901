import { connect } from 'react-redux'

import { createNote } from 'app/agency/agency_action'
import { fetchAllPropertyFutureViewings } from 'app/bond_cover/agency/agency_actions'
import OwnerList from 'app/sm/inspections/components/owner-list'
import {
  addCurrentOwners,
  addCurrentTenants,
  addWalkInRenter,
  cancelViewing,
  clearError,
  editViewing,
  fetchAgencyApplicantsElasticSearchResult,
  fetchCurrentOwners,
  fetchCurrentTenants,
  fetchEnquirers,
  fetchPropertyViewings,
  notifyLateViewing,
  removeCurrentOwners,
  removeCurrentTenants,
  removeOnsiteViewingRenter,
  requestFeedback,
  sendEntryNotice,
  sendIndividualInviteToApply,
  sendInviteToApplyToAllRegistrants,
  showCommentModal,
  showEditViewingModal,
  showLateViewingModal,
  toggleWalkInModal,
  updateCurrentOwners,
  updateCurrentTenants,
  updateRenterStatus,
  updateRenterStatusComment,
} from 'app/sm/inspections/inspections_actions'
import { fetchProperty } from 'app/sm/ppp/property_actions'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'
import {
  assignViewingToTeamMember,
  deleteViewingTeamMember,
  fetchViewingTeamMembers,
  getManagersListForViewingFilters,
  updateViewingTeamMemberRelation,
} from 'app/sm/viewings/viewings_actions'

const mapStateToProps = ({ ViewingsReducer, Ppp, Shared, session }) => ({
  propertyViewings: ViewingsReducer.propertyViewings,
  error: ViewingsReducer.error,
  isWalkInModalOn: ViewingsReducer.isWalkInModalOn,
  isLateModalOn: ViewingsReducer.isLateModalOn,
  viewingErrorGuid: ViewingsReducer.viewingErrorGuid,
  isEditViewModalOn: ViewingsReducer.isEditViewModalOn,
  isCommentModalOn: ViewingsReducer.isCommentModalOn,
  viewingTime: ViewingsReducer.viewingTime,
  offers: Ppp.property.offers,
  renterInfo: ViewingsReducer.renterInfo,
  checkboxSpinnerGuidArray: ViewingsReducer.checkboxSpinnerGuidArray,
  backUrl: Shared.backUrl,
  currentTeam: session.currentTeam,
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  fetchPropertyViewings: (params) => dispatch(fetchPropertyViewings(params)),
  toggleWalkInModal: (isWalkInModalOn, viewing) =>
    dispatch(toggleWalkInModal(isWalkInModalOn, viewing)),
  addWalkInRenter: (data, propertyId, viewingId) =>
    dispatch(addWalkInRenter(data, propertyId, viewingId)),
  sendIndividualInviteToApply: (viewingId, registrantGUID) =>
    dispatch(sendIndividualInviteToApply(viewingId, registrantGUID)),
  updateRenterStatus: (payload, renterType, id, registrationGUID, propertyId) =>
    dispatch(
      updateRenterStatus(payload, renterType, id, registrationGUID, propertyId),
    ),
  removeOnsiteViewingRenter: (viewingGUID, registrationGUID) =>
    dispatch(removeOnsiteViewingRenter(viewingGUID, registrationGUID)),
  notifyLateViewing: (data) => dispatch(notifyLateViewing(data)),
  clearError: () => dispatch(clearError()),
  sendInviteToApplyToAllRegistrants: (viewingGUID) =>
    dispatch(sendInviteToApplyToAllRegistrants(viewingGUID)),
  cancelViewing: (viewingGUID, propertyId) =>
    dispatch(cancelViewing(viewingGUID, propertyId)),
  removeCurrentOwners: (teamId, contactId, propertyId) =>
    dispatch(removeCurrentOwners(teamId, contactId, propertyId)),
  editViewing: (
    propertyId,
    offerId,
    viewingId,
    startdate,
    duration,
    skipDoubleBookingCheck,
    viewingOptions,
  ) =>
    dispatch(
      editViewing(
        propertyId,
        offerId,
        viewingId,
        startdate,
        duration,
        skipDoubleBookingCheck,
        viewingOptions,
      ),
    ),
  fetchProperty: (propertyId) => dispatch(fetchProperty(propertyId)),
  showEditViewingModal: (isEditViewModalOn, viewingTime) =>
    dispatch(showEditViewingModal(isEditViewModalOn, viewingTime)),
  showLateViewingModal: (isLateModalOn, viewing) =>
    dispatch(showLateViewingModal(isLateModalOn, viewing)),
  showCommentModal: (isCommentModalOn, renterInfo) =>
    dispatch(showCommentModal(isCommentModalOn, renterInfo)),
  updateRenterStatusComment: (payload) =>
    dispatch(updateRenterStatusComment(payload)),
  requestFeedback: (viewingGUID, success) =>
    dispatch(requestFeedback(viewingGUID, success)),
  fetchEnquirers: (teamId, propertyId) =>
    dispatch(fetchEnquirers(teamId, propertyId)),
  fetchAgencyApplicantsElasticSearchResult: (teamId, queryString) =>
    dispatch(fetchAgencyApplicantsElasticSearchResult(teamId, queryString)),
  fetchCurrentTenants: (teamId, queryString) =>
    dispatch(fetchCurrentTenants(teamId, queryString)),
  fetchCurrentOwners: (teamId, queryString) =>
    dispatch(fetchCurrentOwners(teamId, queryString)),
  addCurrentTenants: (data, propertyId) =>
    dispatch(addCurrentTenants(data, propertyId)),
  addCurrentOwners: (data, teamId) => dispatch(addCurrentOwners(data, teamId)),
  updateCurrentTenants: (propertyId, tenantId, data) =>
    dispatch(updateCurrentTenants(propertyId, tenantId, data)),
  updateCurrentOwners: (teamId, ownerId, data) =>
    dispatch(updateCurrentOwners(teamId, ownerId, data)),
  removeCurrentTenants: (propertyId, tenantId) =>
    dispatch(removeCurrentTenants(propertyId, tenantId)),
  sendEntryNotice: (offerId, data) => dispatch(sendEntryNotice(offerId, data)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
  createNote: (payload, ownerGUID, ownerType) =>
    dispatch(createNote(payload, ownerGUID, ownerType)),
  assignViewingToTeamMember: (teamGUID, viewingGUID, teamMemberGUID) =>
    dispatch(assignViewingToTeamMember(teamGUID, viewingGUID, teamMemberGUID)),
  fetchViewingTeamMembers: (teamGUID, viewingGUID) =>
    dispatch(fetchViewingTeamMembers(teamGUID, viewingGUID)),
  deleteViewingTeamMember: (teamGUID, viewingGUID, teamMemberGUID) =>
    dispatch(deleteViewingTeamMember(teamGUID, viewingGUID, teamMemberGUID)),
  updateViewingTeamMemberRelation: (
    teamGUID,
    viewingGUID,
    teamMemberGUID,
    requestBody,
  ) =>
    dispatch(
      updateViewingTeamMemberRelation(
        teamGUID,
        viewingGUID,
        teamMemberGUID,
        requestBody,
      ),
    ),
  getManagersListForViewingFilters: (teamGUID) =>
    dispatch(getManagersListForViewingFilters(teamGUID)),
  fetchAllPropertyFutureViewings: (teamGUID) =>
    dispatch(fetchAllPropertyFutureViewings(teamGUID)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OwnerList)
