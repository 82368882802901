import React from 'react'

import { Link } from 'react-router-dom'

import headerImg01 from 'app/assets/icons/header-img-01.png'

const EndTooClose = () => (
  <div className="two-col-box-flex-row sm64">
    <div className="col-first">
      <div className="ilustration-box">
        <img src={headerImg01} />
      </div>

      <h3 className="mb15">You're too close to the end of your lease.</h3>
      <p>
        At this stage we are unable to provide BondCover for people with less
        than 3 months to go on their lease.
      </p>
      <p>We apologize for the inconvenience, but we can still help you!</p>

      <h3 className="mb15 mt50">Don't pay bond on your next rental</h3>
      <p>
        We'd like to help you with BondCover on your next rental. Snug will send
        you a reminder when your lease ends.
      </p>

      <Link to="/home/overview">
        <button className="mt25">Return to dashboard</button>
      </Link>
    </div>
  </div>
)

export default EndTooClose
