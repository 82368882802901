import React from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import approvalImg from 'app/assets/icons/application_green_tick.png'
import applicationDialogue from 'app/assets/icons/application_icon_dialogue.svg'
import declineImg from 'app/assets/icons/application_note_decline.svg'
import errorImg from 'app/assets/icons/application_red_error.png'
import warningImg from 'app/assets/icons/application_yellow_warning.png'
import { noteActionType } from 'app/sm/helpers'

const propTypes = {
  summary: PropTypes.array,
}

const List = styled.ul`
  margin: 0 0 16px;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 24px;

  li + li {
    margin-top: 10px;
  }

  a {
    color: #212025;
  }
`

// NOTE: Remains for when notice icons are included.
// eslint-disable-next-line no-unused-vars
const Icon = styled.i`
  display: inline-block;
  margin: -4px 0 -10px 6px;
  vertical-align: sub;
  font-size: 22px;

  &.icon-info {
    font-size: 16px;
  }
`

const ScrollToAnchor = ({ title, id }) => {
  const anchor = '#' + id
  return <a href={anchor}>{title}</a>
}

class ApplicationList extends React.Component {
  componentDidMount() {
    document
      .querySelectorAll('.applicant-widget a[href^="#"]')
      .forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
          e.preventDefault()
          const hrefAttribute = document.querySelector(
            this.getAttribute('href'),
          )
          hrefAttribute &&
            hrefAttribute.scrollIntoView({
              behavior: 'smooth',
            })
        })
      })
  }

  render() {
    const { summary, interpreteNoteResult, disabledSections = [] } = this.props
    const { approve, decline, comment, unspecified } = noteActionType

    const enabledSummary = summary.filter(
      (section) => !disabledSections.includes(section.label),
    )

    return (
      <List>
        {enabledSummary.map(
          ({ id, title, marked, warning, info, error, anchorID, label }) => {
            const noteStatus = interpreteNoteResult[label]
            const { categorizedLastNotes = {} } = noteStatus || {}

            const lastNotes = {
              lastApproveNote: categorizedLastNotes[approve],
              lastDeclineNote: categorizedLastNotes[decline],
              lastCommentNote: categorizedLastNotes[comment],
            }

            let lastNoteTime = moment().subtract(10, 'years').toISOString()

            let lastNoteAction = unspecified

            Object.keys(lastNotes).forEach((lastNoteKey) => {
              if (lastNotes[lastNoteKey]) {
                const { updatedAt, type } = lastNotes[lastNoteKey]
                if (moment(updatedAt).isAfter(moment(lastNoteTime))) {
                  lastNoteTime = updatedAt
                  lastNoteAction = Number(type)
                }
              }
            })

            return (
              <li key={id}>
                {warning || error ? (
                  <b>
                    <ScrollToAnchor title={title} id={anchorID} />
                  </b>
                ) : (
                  <ScrollToAnchor title={title} id={anchorID} />
                )}
                {lastNoteAction && lastNoteAction === comment ? (
                  <img
                    className="width20 ml5"
                    src={applicationDialogue}
                    alt="commentImg"
                  />
                ) : (
                  ''
                )}
                {lastNoteAction && lastNoteAction === approve ? (
                  <img
                    className="width20 ml5"
                    src={approvalImg}
                    alt="approvalImg"
                  />
                ) : (
                  ''
                )}
                {lastNoteAction && lastNoteAction === decline ? (
                  <img
                    className="width20 ml5"
                    src={declineImg}
                    alt="warningImg"
                  />
                ) : (
                  ''
                )}
                {warning ? (
                  <img
                    className="width20 ml5"
                    src={warningImg}
                    alt="warningImg"
                  />
                ) : (
                  ''
                )}
                {error ? (
                  <img className="width20 ml5" src={errorImg} alt="errorImg" />
                ) : (
                  ''
                )}
              </li>
            )
          },
        )}
      </List>
    )
  }
}

ApplicationList.propTypes = propTypes

export default ApplicationList
