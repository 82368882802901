import React from 'react'

import styled from 'styled-components'

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 30px;
  display: flex;
  white-space: nowrap;

  li {
    display: inline-block;
    vertical-align: sub;
  }

  li + li {
    margin: 0 0 0 22px;
  }
`

const Link = styled.button`
  text-decoration: none;
  color: var(--pdf-blue);
  background: transparent;
  border: none;
  padding: 0;
  float: left;
  margin: 0;
  cursor: pointer;
  font: 16px/30px var(--font-family);

  span {
    text-decoration: underline;
  }

  &:hover {
    span {
      text-decoration: none;
    }
  }
`

function WidgetControls() {
  return (
    <List className="widget-controls">
      <li>
        <Link>
          <span>Request info</span>
        </Link>
      </li>
      <li>
        <Link>
          <i className="icon-pen" />
        </Link>
      </li>
      <li>
        <Link>
          <i className="icon-done" />
        </Link>
      </li>
      <li>
        <Link>
          <i className="icon-delete" />
        </Link>
      </li>
      <li>
        <Link>
          <i className="icon-message" />
        </Link>
      </li>
    </List>
  )
}

export default WidgetControls
