import React from 'react'

import styled from 'styled-components'

import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'
import * as URLHelpers from 'app/utils/url/helpers'

const VTC = 'vtc'
const VTC_LINK = 'virtualtourscreator'

const AddLink = styled.span`
  font-size: 16px;
  float: left;
  padding: 0px;
  color: #5a9bee;
  position: absolute;
  margin-top: 10px;
  top: 3px;
  text-decoration: underline !important;
  cursor: pointer;
`
const VideoLinkListContainer = styled.div`
  position: relative;
  display: flex;
  width: 240px;
  align-items: center;
  @media (max-width: 576px) {
    width: 70%;
  }
  .mobile-virtual-link-remove {
    font-size: 16px;
    width: 30px;
    padding: 0px;
    margin-left: 16px;
    color: #5a9bee;
    text-decoration: underline !important;
    cursor: pointer;
    @media (max-width: 576px) {
      left: 250px !important;
    }
  }
  span.link-number {
    padding: 20px 15px 25px 0px;
    font-size: 18px;
  }
`

const VideoLinks = ({
  videosNumber,
  videoLinks,
  removeVideoLink,
  updateVideoLink,
  updateInputValue,
}) => {
  return (
    <div>
      {Array(videosNumber)
        .fill()
        .map((_, i) => i)
        .map((_, i) => (
          <VideoLinkListContainer>
            <span className="link-number">{`${i + 1}.`}</span>
            <Form.InputTextOptional
              value={videoLinks[i].URL}
              label="Tour Link"
              onChange={(event) => updateInputValue('URL', event, i)}
              labelClass="top18"
              // inputClass="width100 mt0"
              // componentClass="width100"
              isOptional
            />
            <span
              className="mobile-virtual-link-remove"
              onClick={() => removeVideoLink(i)}
            >
              Remove
            </span>
          </VideoLinkListContainer>
        ))}
    </div>
  )
}
class VirtualTour extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videosNumber: 1,
      videoLinks: [
        {
          URL: '',
          isMain: false,
        },
      ],
      disabled: true,
    }
    this.newVideoLink = this.newVideoLink.bind(this)
    this.removeVideoLink = this.removeVideoLink.bind(this)
    this.updateVideoLink = this.updateVideoLink.bind(this)
  }

  componentDidMount() {
    const { guidDetails, fetchVirtualTourLinks } = this.props
    const { property, agencyGUID } = guidDetails
    if (agencyGUID && property) {
      fetchVirtualTourLinks(agencyGUID, property).then((result) => {
        if (result.length > 0) {
          this.setState({
            videoLinks: result,
            videosNumber: result.length,
            disabled: false,
          })
        }
      })
    }
  }

  goBack = () => {
    const { slug = '' } = this.props
    history.push(urlTo('teamsViewing', { teamSlug: slug }))
  }

  newVideoLink = () => {
    const { videoLinks } = this.state
    videoLinks.push({
      URL: '',
      isMain: false,
    })
    this.setState({ videoLinks })
    const { videosNumber } = this.state
    this.setState({
      videosNumber: videosNumber + 1,
      disabled: true,
    })
  }

  removeVideoLink = (i) => {
    const { videosNumber, videoLinks } = this.state
    if (videosNumber > 0) {
      const newVideos = videoLinks.filter((value, index) => index !== i)
      this.setState({ disabled: false })
      this.setState({
        videosNumber: videosNumber - 1,
        videoLinks: newVideos,
      })
    }
  }

  submit = () => {
    const { videoLinks } = this.state
    const { guidDetails, createVirtualTour } = this.props
    const { property, agencyGUID } = guidDetails
    const propertyVideoLinks = []
    videoLinks.forEach((tour) => {
      if (tour.URL !== '') {
        propertyVideoLinks.push(tour.URL)
      }
    })
    if (property && agencyGUID) {
      const payload = {
        propertyVideos: propertyVideoLinks,
      }
      createVirtualTour(agencyGUID, property, payload)
        .then((result) => {
          if (result.length > 0) {
            this.setState({
              videoLinks: result,
              videosNumber: result.length,
            })
          }
          snugNotifier.success(
            'Successfully added virtual tour link(s) to the property',
          )
        })
        .catch((err) => {
          snugNotifier.error(err)
        })
    }
  }

  updateInputValue = (field, data, i) => {
    const { videoLinks } = this.state
    let videoLinksCopy = [...videoLinks]
    videoLinksCopy[i][field] = data.value
    this.setState({
      videoLinks: videoLinksCopy,
    })
    this.updateVideoLink(field, data, i)
  }

  updateVideoLink = debounce((field, data, i) => {
    const { videoLinks } = this.state
    let videoLinksCopy = [...videoLinks]
    if (videoLinksCopy[i]) {
      if (videoLinksCopy[i][field] === '') {
        return
      }
      const adjustedYoutubeURL = URLHelpers.adjustYoutubeURL(
        videoLinksCopy[i][field],
      )
      if (adjustedYoutubeURL === false) {
        if (
          videoLinksCopy[i][field].includes(VTC) ||
          videoLinksCopy[i][field].includes(VTC_LINK)
        ) {
          videoLinksCopy[i][field] = data.value
          this.setState({
            videoLinks: videoLinksCopy,
            disabled: false,
          })
          return
        }
        snugNotifier.error(
          'Invalid YouTube Or VTC URL. Please try entering the URL again.',
        )
        videoLinksCopy[i][field] = ''
        this.setState({
          videoLinks: videoLinksCopy,
          disabled: true,
        })
        return
      }
      if (adjustedYoutubeURL) {
        videoLinksCopy[i][field] = adjustedYoutubeURL
        this.setState({
          videoLinks: videoLinksCopy,
          disabled: false,
        })
      } else {
        videoLinksCopy[i][field] = data.value
        this.setState({
          videoLinks: videoLinksCopy,
          disabled: false,
        })
      }
    }
  }, STANDARD_TIMEOUT)

  render() {
    const { videoLinks, videosNumber, disabled } = this.state
    const { isFromOnlistPropertyDisclosures = false } = this.props
    return (
      <div className="float-left mt10">
        <h5>Virtual Viewing</h5>
        <div className="mt10 mb20">
          Add Youtube or Virtual Tours Creator video links to enable virtual
          tours.
        </div>
        <VideoLinks
          videosNumber={videosNumber}
          videoLinks={videoLinks}
          removeVideoLink={this.removeVideoLink}
          updateVideoLink={this.updateVideoLink}
          updateInputValue={this.updateInputValue}
        />
        <AddLink className="position-relative" onClick={this.newVideoLink}>
          Add another
        </AddLink>
        <div className="bottom-buttons">
          <Display.ModalButtonGroup
            primaryLabel="Save"
            secondaryLabel="Back"
            secondaryButtonType="tertiary"
            primaryAction={this.submit}
            secondaryAction={this.goBack}
            disable={disabled}
            showSecondaryButton={!isFromOnlistPropertyDisclosures}
          />
        </div>
      </div>
    )
  }
}

export default VirtualTour
