const weeklyIncomePlaceholder = 'Weekly (After Tax, Net Received)'
const incomePlaceholderForPeriod = '(After Tax, Net Received)'
const annualIncomePlaceholder = 'Annual (Before Tax, ex Super)'
const otherWeeklyIncomePlaceholder =
  'Other Weekly Income (After Tax, Net Received)'
const otherAnnualIncomePlaceholder =
  'Other Annual Income (Before Tax, ex Super)'
const governmentBenefitsLabel =
  'Enter your total Future Regular Entitlements and Payments and do not include once-off or non-ongoing payments.'
const governmentWeeklyBenefitsPlacehold =
  'Government Weekly Benefits (After Tax, Net Received)'
const governmentAnnualBenefitsPlacehold =
  'Government Annual Benefits (Before Tax, ex Super)'

const governmentBenefitsInfoModalText =
  'Payments that are made as a once only payment should not be included as part of your household income. For example, this may include a crisis payment for flood or bushfire support or once off child support payment that is not ongoing. Only include government payments if you rely on them as a regular source of income to help pay your rent each week. Note: Commonwealth Rent Assistance does not apply to this program.'
export {
  annualIncomePlaceholder,
  governmentAnnualBenefitsPlacehold,
  governmentBenefitsInfoModalText,
  governmentBenefitsLabel,
  governmentWeeklyBenefitsPlacehold,
  incomePlaceholderForPeriod,
  otherAnnualIncomePlaceholder,
  otherWeeklyIncomePlaceholder,
  weeklyIncomePlaceholder,
}

export const searchAddressEmailNamePhonePlaceholder =
  'Address, name, email, mobile, app ID'
