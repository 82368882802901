import { websiteUrl } from 'config/env'
import { isArray } from 'util'

import * as API from 'app/bond_cover/bond_cover_access'
import { NetworkConnectionError } from 'app/constants/error_codes'
import ErrorMessages, {
  translateErrorCodeToMessage,
} from 'app/constants/error_messages'
import { getRentalHistoryReview } from 'app/services/http/rentalReferences'
import { composeAttachment } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import {
  bcRenterStatus,
  dispatchAPIResponse,
  sortHistory,
  urlTo,
  validateAddress,
} from 'app/sm/helpers'
import { getPropertyAttributes } from 'app/sm/ppp/property_access'
import store from 'app/store'

// Action Types

export const RECEIVE_RESPONSE_TEXT = 'BC_RECEIVE_RESPONSE_TEXT'
export const NEW_BOND_COVER_REQUEST = 'NEW_BOND_COVER_REQUEST'
export const RECEIVE_BOND_COVER_REQUEST = 'RECEIVE_BOND_COVER_REQUEST'

export const RECEIVE_REQUEST_SUMMARY = 'RECEIVE_REQUEST_SUMMARY'
export const RECEIVE_CURRENT_LEASE = 'RECEIVE_CURRENT_LEASE'
export const RECEIVE_MANAGER = 'RECEIVE_MANAGER'

export const REQUEST_SUBMITTED = 'REQUEST_SUBMITTED'

export const RECEIVE_TENANT_REVIEW = 'RECEIVE_TENANT_REVIEW'
export const RECEIVE_TENANT_SUMMARY = 'RECEIVE_TENANT_SUMMARY'

export const RECEIVE_REQUEST_REVIEW = 'RECEIVE_REQUEST_REVIEW'
export const RECEIVE_DECLINED_REASON = 'RECEIVE_DECLINED_REASON'
export const RECEIVE_RATING_CATEGORIES = 'RECEIVE_RATING_CATEGORIES'
export const RECEIVE_ERROR = 'BCC_RECEIVE_ERROR'
export const CLEAR_ERROR = 'BCC_CLEAR_ERROR'
export const RECEIVE_TENANTS = 'RECEIVE_TENANTS'
export const RECEIVE_TENANT = 'RECEIVE_TENANT'
export const TENANT_DELETED = 'TENANT_DELETED'

export const RECEIVE_PAYMENT_OPTIONS = 'RECEIVE_PAYMENT_OPTIONS'
export const RECEIVE_BANK_ACCOUNT = 'RECEIVE_BANK_ACCOUNT'
export const RECEIVE_IDENTITY_DOC = 'RECEIVE_IDENTITY_DOC'
export const RECEIVE_SIGNATURE_SUMMARY = 'RECEIVE_SIGNATURE_SUMMARY'
export const RECEIVE_MANAGER_REQUEST_SUMMARY = 'RECEIVE_MANAGER_REQUEST_SUMMARY'
export const RECEIVE_BOND_COVER_COMPLETE_SUMMARY =
  'RECEIVE_BOND_COVER_COMPLETE_SUMMARY'
export const RECEIVE_RENTAL_HISTORY = 'RECEIVE_RENTAL_HISTORY'
export const RECEIVE_RENTAL_REFERENCE = 'RECEIVE_RENTAL_REFERENCE'
export const RENTAL_REFERENCE_EDIT_MODE = 'RENTAL_REFERENCE_EDIT_MODE'
export const RECEIVE_PROPERTY_ATTRIBUTES = 'RH_RECEIVE_PROPERTY_ATTRIBUTES'
export const RESET_RENTAL_REFERENCE = 'RH_RESET_RENTAL_REFERENCE'
export const RECEIVE_RENTAL_HISTORY_REVIEW = 'RECEIVE_RENTAL_HISTORY_REVIEW'
export const SET_RENTAL_HISTORY_SECRET = 'SET_RENTAL_HISTORY_SECRET'
export const SHOW_RENTAL_LEDGER_SPINNER = 'RH_SHOW_RENTAL_LEDGER_SPINNER'

export const BC_CLICK_BUTTON = 'BC_CLICK_BUTTON'
export const BC_API_COMPLETE = 'BC_API_COMPLETE'

const rentalLedgerSpinner = (showRentalLedgerSpinner) => ({
  type: SHOW_RENTAL_LEDGER_SPINNER,
  showRentalLedgerSpinner,
})

export const clickButton = () => ({
  type: BC_CLICK_BUTTON,
  clicked: true,
})

export const apiComplete = () => ({
  type: BC_API_COMPLETE,
  clicked: false,
})

// Intercepted by Middleware
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})

// Emitted by Middleware for Reducer
export const receiveError = (error) => ({
  type: RECEIVE_ERROR,
  error,
})

export const clearError = () => ({
  type: CLEAR_ERROR,
})

export const newBondCoverRequest = () => ({
  type: NEW_BOND_COVER_REQUEST,
})

export const receiveBondCoverReview = (review) => ({
  type: RECEIVE_REQUEST_REVIEW,
  request: review.BcRequest,
  currentLease: review.CurrentLease,
  tenantRating: review.TenantRating || {},
  renter: review.Renter,
  message: review.Message,
})

export const receiveTenantReview = (review) => ({
  type: RECEIVE_TENANT_REVIEW,
  request: review.BcRequest,
  currentLease: review.CurrentLease,
  renter: review.Renter,
  tenant: review.Tenant,
})

export const receiveTenantSummary = (summary) => ({
  type: RECEIVE_TENANT_SUMMARY,
  request: summary.bcRequest,
  currentLease: summary.currentLease,
  manager: summary.manager,
  tenant: summary.tenant,
  tenants: summary.tenants,
  quote: summary.quote,
  identityDoc: summary.identityDoc,
  bankAccount: summary.bankAccount,
  signature: summary.signature,
})

export const receiveRentalHistoryReview = (rentalHistoryReview) => ({
  type: RECEIVE_RENTAL_HISTORY_REVIEW,
  rentalHistoryReview,
})

export const receiveDeclinedReason = (declined) => ({
  type: RECEIVE_DECLINED_REASON,
  declined,
})

export const receiveBondCoverRatingCategories = (ratingCategories) => ({
  type: RECEIVE_RATING_CATEGORIES,
  ratingCategories,
})

export const receiveRentalHistoryRatingCategories = (
  versionsRatingCategories,
) => ({
  type: RECEIVE_RATING_CATEGORIES,
  versionsRatingCategories,
})

export const receivePaymentOptions = (paymentOptions) => ({
  type: RECEIVE_PAYMENT_OPTIONS,
  paymentOptions,
})

export const receiveBankAccount = (bankAccount) => ({
  type: RECEIVE_BANK_ACCOUNT,
  bankAccount,
})

export const receiveIdentityDoc = (identityDoc) => ({
  type: RECEIVE_IDENTITY_DOC,
  identityDoc,
})

export const receiveSignatureSummary = ({
  currentLease,
  quote,
  renter,
  signature,
}) => ({
  type: RECEIVE_SIGNATURE_SUMMARY,
  currentLease,
  quote,
  renter,
  signature,
})

export const receiveManagerRequestSummary = (managerRequestSummary) => ({
  type: RECEIVE_MANAGER_REQUEST_SUMMARY,
  managerRequestSummary,
})

export const receiveBondCoverCompleteSummary = (bondCoverCompleteSummary) => ({
  type: RECEIVE_BOND_COVER_COMPLETE_SUMMARY,
  bondCoverCompleteSummary,
})

export const fetchCardsSuccess = (success) => ({
  type: RECEIVE_PAYMENT_OPTIONS,
  paymentOptions: success,
})

export const receiveRentalHistory = (rentalHistory) => ({
  type: RECEIVE_RENTAL_HISTORY,
  rentalHistory,
})
export const receiveRentalReference = (reference) => ({
  type: RECEIVE_RENTAL_REFERENCE,
  reference,
})
export const resetRentalReference = () => ({ type: RESET_RENTAL_REFERENCE })

// Methods
export function fetchRequestSummary(requestID) {
  API.getRequestSummary(
    requestID,
    (summary) => {
      if (summary) {
        store.dispatch(receiveRequestSummary(summary))
      }
    },
    error,
  )
}

const receiveRequestSummary = ({
  request,
  currentLease,
  quote,
  manager,
  tenants,
  signature,
  identityDoc,
  bankAccount,
  card,
}) => ({
  type: RECEIVE_REQUEST_SUMMARY,
  request,
  currentLease,
  quote,
  manager,
  tenants,
  signature,
  identityDoc,
  bankAccount,
  card,
})

export function addCurrentLease(currentLease) {
  store.dispatch(clickButton())
  store.dispatch(clearError())
  API.addCurrentLease(
    currentLease,
    (currentLease) => {
      currentLeaseSuccess(currentLease)
    },
    error,
  )
}

export function fetchCurrentLease(requestID, id) {
  API.getCurrentLease(
    requestID,
    id,
    (currentLease) => store.dispatch(receiveCurrentLease(currentLease)),
    error,
  )
}
const receiveCurrentLease = (currentLease) => ({
  type: RECEIVE_CURRENT_LEASE,
  currentLease,
})

export function updateCurrentLease(requestID, currentLease, guid) {
  store.dispatch(clickButton())
  store.dispatch(clearError())
  API.updateCurrentLease(
    requestID,
    currentLease,
    guid,
    (response) => {
      currentLeaseSuccess(response)
    },
    error,
  )
}

export function addManager(requestID, manager) {
  store.dispatch(clearError())
  if (manager.agencyGUID) {
    API.addKnownAgencyManager(
      requestID,
      manager,
      (manager) => {
        managerSuccess(manager)
      },
      error,
    )
  } else {
    API.addManager(
      requestID,
      manager,
      (manager) => {
        managerSuccess(manager)
      },
      error,
    )
  }
}

export function fetchManager(requestID, guid) {
  API.getManager(
    requestID,
    guid,
    (manager) => store.dispatch(receiveManager(manager)),
    error,
  )
}
const receiveManager = (manager) => ({
  type: RECEIVE_MANAGER,
  manager,
})

export function updateManager(requestID, manager, guid) {
  store.dispatch(clearError())
  if (manager.agencyGUID) {
    API.updateKnownAgencyManager(
      requestID,
      manager,
      guid,
      (response) => {
        managerSuccess(response)
      },
      error,
    )
  } else {
    API.updateManager(
      requestID,
      manager,
      guid,
      (response) => {
        managerSuccess(response)
      },
      error,
    )
  }
}

export function fetchTenants(requestID) {
  API.getTenants(
    requestID,
    (tenants) => store.dispatch(receiveTenants(tenants)),
    error,
  )
}
const receiveTenants = ({ currentLease, tenants }) => ({
  type: RECEIVE_TENANTS,
  currentLease,
  tenants,
})

export function addTenant(requestID, tenant) {
  store.dispatch(clearError())
  API.addTenant(
    requestID,
    tenant,
    (response) => history.push(`/bc/tenants?requestid=${tenant.requestID}`),
    error,
  )
}

export function fetchTenant(requestID, guid) {
  API.getTenant(
    requestID,
    guid,
    (tenant) => store.dispatch(receiveTenant(tenant)),
    error,
  )
}
const receiveTenant = (tenant) => ({
  type: RECEIVE_TENANT,
  tenant,
})

export function updateTenant(requestID, guid, tenant) {
  store.dispatch(clearError())
  API.updateTenant(
    requestID,
    guid,
    tenant,
    (response) => history.push(`/bc/tenants?requestid=${tenant.requestID}`),
    error,
  )
}

export function deleteTenant(requestID, guidID) {
  store.dispatch(clearError())
  API.deleteTenant(
    requestID,
    guidID,
    () => store.dispatch(tenantDeleted(guidID)),
    error,
  )
}
const tenantDeleted = (guidID) => ({
  type: TENANT_DELETED,
  guidID,
})

export function splitBond(requestID, tenants) {
  store.dispatch(clearError())
  API.splitBond(
    requestID,
    tenants,
    (tenants) => {
      store.dispatch(receiveTenants(tenants))
      history.push(`/bc?requestid=${requestID}`)
    },
    error,
  )
}

export function fetchRenterSignature(requestID) {
  API.getRenterSignature(
    requestID,
    (response) => store.dispatch(renterSignatureFetched(response)),
    error,
  )
}
const renterSignatureFetched = ({ currentLease, signature, quote }) => ({
  type: RECEIVE_SIGNATURE_SUMMARY,
  currentLease,
  quote,
  signature,
})

export function signByRenter(requestID, signature, isPrimary) {
  store.dispatch(clearError())
  API.signByRenter(
    requestID,
    signature,
    (response) => signedSuccess(response, isPrimary),
    error,
  )
}

export function createIdentityDoc(requestID, identityDoc, isPrimary) {
  store.dispatch(clearError())
  API.postIdentityDoc(
    requestID,
    identityDoc,
    (response) => identityDocSuccess(response, isPrimary),
    error,
  )
}

export function fetchIdentityDoc(requestID, guid) {
  API.getIdentityDoc(
    requestID,
    guid,
    (identityDoc) => store.dispatch(receiveIdentityDoc(identityDoc)),
    error,
  )
}

export function updateIdentityDoc(requestID, identityDoc, guid, isPrimary) {
  store.dispatch(clearError())
  API.updateIdentityDoc(
    requestID,
    identityDoc,
    guid,
    (response) => identityDocSuccess(response, isPrimary),
    error,
  )
}

export function fetchBankAccount(requestID, guid) {
  API.getBankAccount(
    requestID,
    guid,
    (bankAccount) => store.dispatch(receiveBankAccount(bankAccount)),
    error,
  )
}

export function createBankAccount(requestID, bankAccount, isPrimary) {
  store.dispatch(clearError())

  API.postBankAccount(
    requestID,
    bankAccount,
    (response) => bankAccountSuccess(response, isPrimary),
    error,
  )
}

export function updateBankAccount(requestID, bankAccount, guid, isPrimary) {
  store.dispatch(clearError())

  API.updateBankAccount(
    requestID,
    bankAccount,
    guid,
    (response) => bankAccountSuccess(response, isPrimary),
    error,
  )
}

export function fetchCards() {
  API.fetchCards((success) => store.dispatch(fetchCardsSuccess(success)), error)
}

export function addCards() {
  history.push(`/bc/cards${window.location.search}`)
}

export function setDefaultCard(guid) {
  store.dispatch(clearError())
  API.setDefaultCard(guid, (success) => fetchCards(), error)
}

export function removeCard(guid) {
  store.dispatch(clearError())
  API.removeCard(guid, (success) => fetchCards(), error)
}

export function registerNewCard(data) {
  store.dispatch(clearError())
  API.registerPayment(
    data,
    (response) => newCardSuccess(response),
    paymentError,
  )
}

export function registerPayment(data, requestID) {
  store.dispatch(clearError())
  API.registerPayment(
    Object.assign({}, data, { requestID: requestID }),
    (response) => paymentSuccess(response, requestID),
    paymentError,
  )
}

export function payWithExistingCard(card) {
  //TODO
}

export function submit(requestID) {
  store.dispatch(clearError())
  API.submit(
    requestID,
    (request) => {
      submitSuccess(request)
    },
    error,
  )
}
const requestSubmitted = (request) => ({
  type: REQUEST_SUBMITTED,
  request,
})

export function fetchBondCoverReview(requestID, secret) {
  API.getBondCoverReview(
    requestID,
    secret,
    (review) => store.dispatch(receiveBondCoverReview(review)),
    error,
  )
}

export function getSecondaryTenantReview(requestID, tenantID) {
  API.getSecondaryTenantReview(
    requestID,
    tenantID,
    (review) => store.dispatch(receiveTenantReview(review)),
    error,
  )
}

export function getSecondaryTenantSummary(requestID) {
  API.getSecondaryTenantSummary(
    requestID,
    (summary) => store.dispatch(receiveTenantSummary(summary)),
    error,
  )
}

export function secondaryTenantApprove(requestID) {
  store.dispatch(clearError())
  API.secondaryTenantApprove(
    requestID,
    (success) => secondaryApproveSuccess(requestID),
    error,
  )
}

export function createTenantRating(ratingCode, rating, requestID, secret) {
  store.dispatch(clearError())
  API.postTenantRating(
    ratingCode,
    rating,
    requestID,
    secret,
    (response) => fetchBondCoverReview(requestID, secret),
    error,
  )
}

export function updateTenantRating(ratingCode, rating, requestID, secret) {
  store.dispatch(clearError())
  API.updateTenantRating(
    ratingCode,
    rating,
    requestID,
    secret,
    (response) => ratingSuccess(ratingCode, rating, requestID, secret),
    error,
  )
}

export function approveBondCoverRequest(requestID, managerType) {
  store.dispatch(clearError())
  API.approveBondCoverRequest(
    requestID,
    (response) => {
      history.push('/portfolio/overview')
    },
    error,
  )
}

export function withdrawBondCoverRequest(data, requestID, success, fail) {
  API.withdrawBondCoverRequest(
    data,
    requestID,
    (response) => {
      // eslint-disable-next-line no-unused-expressions
      success ? success() : ''
      history.push('/bc/withdrawconfirm')
    },
    (error) => {
      let { responseText } = error
      // eslint-disable-next-line no-unused-expressions
      fail ? fail() : ''
      responseText = responseText ? responseText.replace(/\n/, '') : ''
      store.dispatch(apiComplete())
      store.dispatch(receiveResponseText(responseText))
    },
    // error
  )
}

export function declineBondCoverRequest(
  data,
  requestID,
  secret,
  managerType,
  url,
) {
  store.dispatch(clearError())
  let path = url || '/bc/ownerdecline'
  API.declineBondCoverRequest(
    data,
    requestID,
    secret,
    (response) => {
      history.push(path)
    },
    error,
  )
}

export function fetchDeclinedReason(requestID) {
  API.getDeclinedReason(
    requestID,
    (success) => {
      store.dispatch(receiveDeclinedReason(success))
    },
    error,
  )
}

export function fetchBondCoverRatingCategories(requestID) {
  API.getBondCoverRatings(
    (ratingCategories) =>
      store.dispatch(receiveBondCoverRatingCategories(ratingCategories)),
    error,
  )
}

export function fetchRentalHistoryRatingCategories(versions) {
  return (dispatch) => {
    API.getRentalHistoryRatings(versions).then((versionsRatingCategories) => {
      dispatch(receiveRentalHistoryRatingCategories(versionsRatingCategories))
    })
  }
}

export function proceedBondCoverRequest(
  requestID,
  secret,
  comment,
  isVerified,
) {
  store.dispatch(clearError())
  API.proceedBondCoverRequest(
    requestID,
    secret,
    comment,
    (success) => proceedSuccess(success, requestID, secret, isVerified),
    error,
  )
}

export function fetchManagerSignatureSummary(requestID) {
  API.getManagerSignatureSummary(
    requestID,
    (summary) => store.dispatch(receiveSignatureSummary(summary)),
    error,
  )
}

export function fetchAgencyManagerSignatureSummary(requestID) {
  API.getAgencyManagerSignatureSummary(
    requestID,
    (summary) => store.dispatch(receiveSignatureSummary(summary)),
    error,
  )
}

export function signByManager(requestID, signature, forwardUrl) {
  store.dispatch(clearError())
  API.signByManager(
    requestID,
    signature,
    (request) => {
      history.push(forwardUrl)
    },
    error,
  )
}

export function signByAgencyManager(requestID, signature, forwardUrl) {
  store.dispatch(clearError())
  API.signByAgencyManager(
    requestID,
    signature,
    (request) => {
      history.push(forwardUrl)
    },
    error,
  )
}

export function getManagerWait(requestID) {
  API.getManagerWait(
    requestID,
    (managerRequestSummary) =>
      store.dispatch(receiveManagerRequestSummary(managerRequestSummary)),
    error,
  )
}

export function getBondCoverCompleteSummary(requestID) {
  API.getBondCoverCompleteSummary(
    requestID,
    (bondCoverCompleteSummary) =>
      store.dispatch(receiveBondCoverCompleteSummary(bondCoverCompleteSummary)),
    error,
  )
}

let ratingSuccess = (ratingCode, rating, requestID, secret) => {
  fetchBondCoverReview(requestID, secret)
}

let identityDocSuccess = (response, isPrimary) => {
  if (isPrimary) {
    history.push(`/bc?requestid=${response.requestID}`)
  } else {
    history.push(`/bc/leaseesign?requestid=${response.requestID}`)
  }
}

let bankAccountSuccess = (response, isPrimary) => {
  if (isPrimary) {
    history.push(`/bc?requestid=${response.requestID}`)
  } else {
    history.push(`/bc/leaseesign?requestid=${response.requestID}`)
  }
}

let paymentSuccess = (response, requestID) => {
  history.push(`/bc?requestid=${requestID}`)
}

let proceedSuccess = (success, requestID, secret, isVerified) => {
  let navigateUrl =
    window.location.href.indexOf('agency') > 0 ? '/agency/bc' : '/bc'
  if (!isVerified) {
    let { firstName, lastName, email, mobile } = success
    firstName = encodeURIComponent(firstName)
    lastName = encodeURIComponent(lastName)
    email = encodeURIComponent(email)
    mobile = encodeURIComponent(mobile)
    history.push({
      pathname: '/register',
      search: `?firstname=${firstName}&lastname=${lastName}&email=${email}&mobile=${mobile}`,
      state: {
        message: 'Please sign up to securely approve this BondCover request.',
        firstName: success.firstName,
        lastName: success.lastName,
        email: success.email,
        mobile: success.mobile,
      },
    })
  } else if (success.managerRated) {
    history.push(
      `${navigateUrl}/managersign?requestid=${requestID}&secret=${secret}`,
    )
  } else if (success.managerAction === 2) {
    history.push(`${navigateUrl}/ownerdecline`)
  } else {
    history.push('/portfolio/overview')
  }
}

let signedSuccess = (response, isPrimary) => {
  if (isPrimary) {
    history.push(`/bc?requestid=${response.requestID}`)
  } else {
    history.push(`/bc/leaseesign?requestid=${response.requestID}`)
  }
}

let currentLeaseSuccess = (response) => {
  store.dispatch(apiComplete())
  history.push(`/bc?requestid=${response.requestID}`)
}

let managerSuccess = (response) => {
  history.push(`/bc?requestid=${response.requestID}`)
}

let submitSuccess = (request) => {
  store.dispatch(requestSubmitted(request))
  history.push(`/bc/renterwait?requestid=${request.guidID}`)
}

let secondaryApproveSuccess = (requestID) => {
  history.push(`/bc/renterwait?requestid=${requestID}&role=secondary`)
}

// Error Callback
export let error = ({ responseText }) => {
  responseText = responseText ? responseText.replace(/\n/, '') : ''
  store.dispatch(apiComplete())
  responseText = ErrorMessages[parseInt(responseText)]
    ? ErrorMessages[parseInt(responseText)]
    : responseText
  store.dispatch(receiveResponseText(responseText))
}

let paymentError = ({ responseText }) => {
  responseText = responseText ? responseText.replace(/\n/, '') : ''
  responseText = ErrorMessages[parseInt(responseText)]
    ? ErrorMessages[parseInt(responseText)]
    : responseText
  store.dispatch(receiveResponseText(responseText))
}

let rentalHistoryEditMode = () => ({ type: RENTAL_REFERENCE_EDIT_MODE })
let receiveAttributes = (attributes) => ({
  type: RECEIVE_PROPERTY_ATTRIBUTES,
  attributes,
})

export const setRentalHistorySecret = (secretGUID) => ({
  type: SET_RENTAL_HISTORY_SECRET,
  secretGUID,
})

export function deleteRentalHistory(
  rentalHistoryId,
  success,
  fail,
  redirect = false,
) {
  return (dispatch) => {
    API.deleteRentalHistory(rentalHistoryId).then(({ ok, responseText }) => {
      if (ok) {
        success()
        if (redirect) {
          history.push(urlTo('bondCoverRentalHistory'))
        }
      } else {
        responseText.then((t) => fail(t))
      }
    })
  }
}

export function fetchPropertyAttributes() {
  return (dispatch) => {
    getPropertyAttributes((attributes) => {
      dispatch(receiveAttributes(attributes))
    }, error)
  }
}

export function addRentalHistory(rentalHistory, success, fail) {
  return (dispatch) => {
    const addressValidation = validateAddress(rentalHistory.address)
    if (!addressValidation.ok) {
      fail(
        ErrorMessages[addressValidation.errorCode] ||
          `There is an error: ${addressValidation.errorCode}`,
      )
      return
    }

    API.addRentalHistory(rentalHistory).then(({ ok, responseText }) => {
      if (ok) {
        if (success) {
          success()
        }
        history.goBack()
        //move(urlTo('rentalHistorySummary'))
      } else {
        if (fail) {
          if (responseText === undefined) {
            fail(NetworkConnectionError)
            return
          }
          responseText.then((text) =>
            fail(
              ErrorMessages[parseInt(text)]
                ? ErrorMessages[parseInt(text)]
                : `There is an error: ${text}`,
            ),
          )
        }
      }
    })
  }
}

export function editRentalHistory(rentalHistory, id, success, fail) {
  return (dispatch) => {
    const addressValidation = validateAddress(rentalHistory.address)
    if (!addressValidation.ok) {
      fail(
        ErrorMessages[addressValidation.errorCode] ||
          `There is an error: ${addressValidation.errorCode}`,
      )
      return
    }

    API.editRentalReference(rentalHistory, id).then(({ ok, responseText }) => {
      if (ok) {
        if (success) {
          success()
        }
        history.goBack()
      } else {
        if (fail) {
          if (responseText === undefined) {
            fail(NetworkConnectionError)
            return
          }
          responseText.then((text) =>
            fail(
              ErrorMessages[parseInt(text)]
                ? ErrorMessages[parseInt(text)]
                : `There is an error: ${text}`,
            ),
          )
        }
      }
    })
  }
}

const formatRentalReference = (rh) => ({
  ...rh,
  guidID: rh.guidID,
  address: rh.address,
  rating: rh.rating, // TODO: this should be replaced with the existing field when we have it,
  weeklyPrice: rh.rate,
  from: rh.startDate,
  to: rh.endDate,
  managerType: rh.managerType,
  isRollingLease: rh.isRollingLease,
  landlord: {
    firstName: rh.firstName,
    lastName: rh.lastName,
  },
  agencyName: rh.agencyName,
  phoneNumber: rh.phoneNumber ? rh.phoneNumber : null,
  officeNumber: rh.officeNumber ? rh.officeNumber : null,
  email: rh.email,
  isCurrentAddress: rh.isCurrentAddress,
  propertyArrangement: rh.propertyArrangement,
  propertyType: rh.propertyType,
  managerRated: rh.managerRated,
  managerAction: rh.managerAction,
  reasonForLeaving: rh.reasonForLeaving,
  ratings: rh.ratings,
  sendReferenceOnAdd: rh.sendReferenceOnAdd,
  shouldContactReference: rh.shouldContactReference,
  declineReason: rh.declineReason,
  CreatedAt: rh.CreatedAt,
})

export function fetchRentalReference(id) {
  return (dispatch) => {
    return API.getRentalReference(id).then(
      ({ ok, rentalReference, responseText }) => {
        if (ok) {
          dispatch(receiveRentalReference(rentalReference))
          dispatch(rentalHistoryEditMode())
          return Promise.resolve(rentalReference)
        } else {
          return responseText.then((t) =>
            store.dispatch(receiveResponseText(t)),
          )
        }
      },
    )
  }
}

export function fetchRentalHistory() {
  return (dispatch) => {
    return API.getRentalHistory().then(
      ({ ok, rentalHistory, responseText }) => {
        if (ok) {
          const rh = rentalHistory.map((rh) =>
            Object.assign({}, rh.rentalHistory, {
              rating: rh.rating,
            }),
          )
          const { current, previous } = sortHistory(rh, 'rentalHistory')
          const formatted = current.concat(previous).map(formatRentalReference)
          dispatch(receiveRentalHistory(formatted))
        } else {
          responseText.then((t) => store.dispatch(receiveResponseText(t)))
        }
      },
    )
  }
}

// We must write this ugly, in order to not collide with the bond cover rating namings, which are conceptually the same, but differently implemented
export function rentalHistoryCreateTenantRating(referenceId, payload) {
  return (dispatch, getState) => {
    dispatch(clearError())
    const {
      bondCover: { secretGUID },
    } = getState()
    return API.createTenantRating(
      referenceId,
      payload,
      'POST',
      secretGUID,
    ).then(({ ok, responseText }) => {
      if (ok) {
        return dispatch(fetchRentalHistoryReview(referenceId))
      } else {
        return responseText.then((t) => {
          return dispatch(receiveResponseText(t))
        })
      }
    })
  }
}

export function rentalHistoryUpdateTenantRating(referenceId, payload) {
  store.dispatch(clearError())

  return (dispatch, getState) => {
    const {
      bondCover: { secretGUID },
    } = getState()
    return API.createTenantRating(referenceId, payload, 'PUT', secretGUID).then(
      ({ ok, responseText }) => {
        if (ok) {
          return dispatch(fetchRentalHistoryReview(referenceId))
        } else {
          return responseText.then((t) => {
            return dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

export function rentalHistoryFinalize(referenceId, comment) {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    const {
      bondCover: { secretGUID },
    } = getState()
    return API.finalizeRentalHistoryRequest(
      referenceId,
      comment,
      secretGUID,
    ).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(fetchRentalHistoryReview(referenceId))
        if (secretGUID) {
          window.location.href = `${websiteUrl}/manager?from=rentalreference&utm_source=RentalReview&utm_medium=app`
        } else {
          return Promise.resolve({ ok: true })
        }
      } else {
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function rentalHistoryDecline(referenceId, payload) {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    const {
      bondCover: {
        secretGUID,
        rentalHistoryReview: { Renter: renter },
      },
    } = getState()
    return API.declineRentalHistoryRequest(
      referenceId,
      payload,
      secretGUID,
    ).then(({ ok, responseText }) => {
      if (ok) {
        // dispatch(fetchRentalHistoryReview(referenceId))
        dispatch(
          receiveRentalHistoryReview(
            Object.assign(
              {},
              { Rating: [] },
              {
                Message: `Thank you`,
              },
            ),
          ),
        )
      } else {
        return responseText.then((error) => {
          dispatch(
            receiveRentalHistoryReview(
              Object.assign(
                {},
                { Rating: [] },
                {
                  Message: translateErrorCodeToMessage(error),
                },
              ),
            ),
          )
        })
      }
    })
  }
}

export function fetchRentalHistoryReview(referenceId) {
  return (dispatch, getState) => {
    const {
      bondCover: { secretGUID },
    } = getState()
    return API.fetchRentalHistoryReview(referenceId, secretGUID).then(
      ({ ok, review, responseText }) => {
        if (ok) {
          return dispatch(
            receiveRentalHistoryReview(
              Object.assign({}, { Rating: [] }, review),
            ),
          )
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((t) => {
            return dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

// workaround to replace the legacy fetchRentalHistoryReview function, not returning a Promise reject on error
export function fetchRentalHistoryReviewWithCatchableError(referenceId) {
  return (dispatch, getState) => {
    const {
      bondCover: { secretGUID },
    } = getState()
    return getRentalHistoryReview(referenceId, secretGUID)
      .then(({ review }) => {
        return dispatch(
          receiveRentalHistoryReview(Object.assign({}, { Rating: [] }, review)),
        )
      })
      .catch((err) => {
        dispatch(receiveError(err))
        return Promise.reject(err)
      })
  }
}

export function fetchRentalHistoryReviewByShortCode(shortCode) {
  return (dispatch) => {
    return API.fetchRentalHistoryReviewByShortCode(shortCode).then(
      ({ ok, review, responseText }) => {
        if (ok) {
          return dispatch(
            receiveRentalHistoryReview(
              Object.assign({}, { Rating: [] }, review),
            ),
          )
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((t) => {
            return dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

export function verifyReferralCode(
  referralCode,
  requestID,
  verifySucceed,
  verifyFail,
) {
  store.dispatch(clearError())
  API.verifyReferralCode(referralCode, requestID).then(
    ({ ok, responseText }) => {
      if (ok) {
        verifySucceed()
        fetchRequestSummary(requestID)
      } else {
        verifyFail()
        responseText.then((t) => store.dispatch(receiveResponseText(t)))
      }
    },
  )
}

let newCardSuccess = (response) => {
  history.push(`/bc/profile/credit-cards${window.location.search}`)
}

export function redirectToRenterWithdrawnPage(requestID) {
  history.push(`/bc/renterwithdraw?requestid=${requestID}`)
}

export const addRentalLedger = (historyGUID, secretGUID, documentType) => {
  return (dispatch) => {
    let attachments = document.getElementById(`attachments-${documentType}`)
    let f = new FormData()
    composeAttachment(attachments, f)
    f.append('type', documentType)

    dispatch(rentalLedgerSpinner(true))

    return API.uploadRentalLedger(f, historyGUID, secretGUID).then(
      ({ ok, statusCode, responseText, statusText }) => {
        dispatch(rentalLedgerSpinner(false))
        if (ok) {
          dispatch(fetchRentalHistoryReview(historyGUID))
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const manualAddRentalLedger = (
  historyGUID,
  secretGUID,
  documentType,
) => {
  return (dispatch) => {
    let attachments = document.getElementById(`attachments-${documentType}`)
    let f = new FormData()
    composeAttachment(attachments, f)
    f.append('type', documentType)

    dispatch(rentalLedgerSpinner(true))

    return API.manualUploadRentalLedger(f, historyGUID, secretGUID).then(
      ({ ok, statusCode, responseText, statusText }) => {
        dispatch(rentalLedgerSpinner(false))
        if (ok) {
          dispatch(fetchRentalHistoryReview(historyGUID))
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const removeRentalLedger = (historyGUID, secretGUID, ledgerGUID) => {
  return (dispatch) => {
    API.deleteRentalLedger(historyGUID, secretGUID, ledgerGUID).then(
      ({ ok, statusCode, responseText, statusText }) => {
        if (ok) {
          dispatch(fetchRentalHistoryReview(historyGUID))
        } else {
          dispatchAPIResponse(dispatch, responseText, receiveResponseText)
        }
      },
    )
  }
}

export const manualRemoveRentalLedger = (
  historyGUID,
  secretGUID,
  ledgerGUID,
) => {
  return (dispatch) => {
    API.manualDeleteRentalLedger(historyGUID, secretGUID, ledgerGUID).then(
      ({ ok, statusCode, responseText, statusText }) => {
        if (ok) {
          dispatch(fetchRentalHistoryReview(historyGUID))
        } else {
          dispatchAPIResponse(dispatch, responseText, receiveResponseText)
        }
      },
    )
  }
}

export function redirectBondCoverReferral(bondCovers) {
  let requestID
  if (isArray(bondCovers)) {
    const bondC = bondCovers.find((bc) => bc.status === bcRenterStatus.Draft)
    requestID = (bondC || {}).requestID
    if (bondC && requestID) {
      history.push(`/bc/pay?requestid=${requestID}`)
    } else {
      history.push(`/home/overview`)
    }
  }
}

export function manualCreateTenantRating(referenceId, payload) {
  return (dispatch) => {
    dispatch(clearError())
    return API.manualCreateTenantRating(referenceId, payload, 'POST').then(
      ({ ok, responseText }) => {
        if (ok) {
          return dispatch(manualFetchRentalHistoryReview(referenceId))
        } else {
          return responseText.then((t) => {
            return dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

export function manualUpdateTenantRating(referenceId, payload) {
  store.dispatch(clearError())
  return (dispatch) => {
    return API.manualCreateTenantRating(referenceId, payload, 'PUT').then(
      ({ ok, responseText }) => {
        if (ok) {
          return dispatch(manualFetchRentalHistoryReview(referenceId))
        } else {
          return responseText.then((t) => {
            return dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

export function manualRentalHistoryFinalize(referenceId, comment) {
  store.dispatch(clearError())
  return (dispatch) => {
    return API.manualFinalizeRentalHistoryRequest(referenceId, comment).then(
      ({ ok, responseText }) => {
        if (ok) {
          dispatch(manualFetchRentalHistoryReview(referenceId))
          return Promise.resolve({ ok: true })
        } else {
          responseText.then((t) => store.dispatch(receiveResponseText(t)))
        }
      },
    )
  }
}

export function manualFetchRentalHistoryReview(referenceId) {
  return (dispatch) => {
    return API.manualGetRentalHistoryReview(referenceId).then(
      ({ ok, review, responseText }) => {
        if (ok) {
          return dispatch(
            receiveRentalHistoryReview(
              Object.assign({}, { Rating: [] }, review),
            ),
          )
        } else {
          return responseText.then((t) => {
            return dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

export const deleteTenantReference =
  (rentalHistoryGUID, referenceGUID) => (dispatch) => {
    return API.deleteReference(rentalHistoryGUID, referenceGUID).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText
            .then((error) => translateErrorCodeToMessage(error))
            .then((error) => Promise.reject(error))
        }
      },
    )
  }

export function fetchManagersForRentalHistory(searchValue) {
  return () => {
    return API.getManagersForRentalHistory(searchValue).then(
      ({ ok, responseText, agencySource }) => {
        if (ok) {
          return Promise.resolve({ agencySource })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}
