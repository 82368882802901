import React from 'react'

import moment from 'moment'
import DateTime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'

const DatePicker = ({
  inputProps,
  dateFormat,
  defaultValue,
  label,
  onBlur,
  onChange,
  value,
  viewMode,
  disableOnClickOutside = false,
  isActive = null,
  onFocus,
  isValidDate,
  className = '',
}) => {
  const placeholder = (inputProps && inputProps.placeholder) || 'dd/mm/yyyy'
  return (
    <div className="input-date pos-initial">
      <DateTime
        className={`date-time ${className}`}
        inputProps={{ placeholder: placeholder, readOnly: true }}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        defaultValue={defaultValue}
        viewMode={viewMode}
        dateFormat={dateFormat || 'DD/MM/YYYY'}
        timeFormat={false}
        open={isActive}
        closeOnSelect={true}
        disableOnClickOutside={disableOnClickOutside}
        isValidDate={isValidDate}
      />
      <label className="label-left">{label}</label>
    </div>
  )
}

export default DatePicker
