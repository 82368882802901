import { connect } from 'react-redux'

import {
  fetchCountForRentalReferencesForAgency,
  fetchRentalReferencesForAgency,
} from 'app/pages/teams/rental-references/action'
import TeamRentalReferences from 'app/pages/teams/rental-references/index'
import { changeTeam } from 'app/session/session_actions'
import { getManagersListForViewingFilters } from 'app/sm/viewings/viewings_actions'

const mapStateToProps = ({ session }) => ({
  teams: session.teams,
  currentTeam: session.currentTeam,
  currentUser: session.currentUser,
  currentAgency: session.currentAgency,
})

const mapDispatchToProps = (dispatch) => ({
  changeTeam: (team) => dispatch(changeTeam(team)),
  fetchRentalReferencesForAgency: (agencyGUID, limit, offset, filterValues) =>
    dispatch(
      fetchRentalReferencesForAgency(agencyGUID, limit, offset, filterValues),
    ),
  fetchCountForRentalReferencesForAgency: (agencyGUID, filterValues) =>
    dispatch(fetchCountForRentalReferencesForAgency(agencyGUID, filterValues)),
  getManagersListForViewingFilters: (agencyGUID) =>
    dispatch(getManagersListForViewingFilters(agencyGUID)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamRentalReferences)
