import { useEffect, useReducer } from 'react'

import { Confirmation } from 'app/features/teams/key-logger/CheckInKeySetModal/Confirmation'
import { KeySetSelection } from 'app/features/teams/key-logger/CheckInKeySetModal/KeySetSelection'
import { StepsModalContainer } from 'app/features/teams/key-logger/components/StepsModalContainer'
import { StepsModalHeader } from 'app/features/teams/key-logger/components/StepsModalHeader'

const checkInStepsConfig = {
  keySetSelection: {
    id: 'keySetSelection',
    subHeading: 'What address?',
  },
  confirmation: {
    id: 'confirmation',
    showKeySetAsSubHeading: true,
  },
}

const checkInStepsConfigArr = Object.values(checkInStepsConfig).map(
  (def, index) => ({ def, index }),
)

const actionTypes = {
  setInitialCheckInData: 'SET_INITIAL_CHECK_IN_DATA',
  stepSaved: 'STEP_SAVED',
  backStep: 'BACK_STEP',
  nextStep: 'NEXT_STEP',
  setCheckedIn: 'SET_CHECKED_IN',
  closeModal: 'CLOSE_MODAL',
}

const actions = {
  setInitialCheckInData: (keySet) => ({
    type: actionTypes.setInitialCheckInData,
    checkInData: { keySet, property: keySet.property },
  }),
  stepSaved: (checkInData) => ({ type: actionTypes.stepSaved, checkInData }),
  backStep: () => ({
    type: actionTypes.backStep,
    indexModifier: -1,
  }),
  nextStep: () => ({
    type: actionTypes.nextStep,
    indexModifier: 1,
  }),
  setCheckedIn: () => ({ type: actionTypes.setCheckedIn }),
  closeModal: () => ({ type: actionTypes.closeModal }),
}

const checkInReducer = (state, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.setInitialCheckInData: {
      const { checkInData } = action
      return {
        ...state,
        checkInData,
      }
    }

    case actionTypes.stepSaved: {
      const { checkInData } = action
      return {
        ...state,
        checkInData,
        showCurrentStep: false,
      }
    }

    case actionTypes.nextStep:
    case actionTypes.backStep: {
      const { indexModifier } = action
      const { index } = state.currentStep
      const stepConfig = checkInStepsConfigArr[index + indexModifier]
      return {
        ...state,
        currentStep: stepConfig,
        showCurrentStep: true,
      }
    }

    case actionTypes.setCheckedIn: {
      return {
        ...state,
        checkedIn: true,
      }
    }

    case actionTypes.closeModal: {
      return {
        ...state,
        closeModal: true,
      }
    }

    default:
      return state
  }
}

export const CheckInKeySetModal = ({
  closeModal: closeModalCb,
  keySet: initialKeySet,
  afterCheckInAction,
}) => {
  const [cmpState, cmpStateDispatch] = useReducer(checkInReducer, {
    checkInData: null,
    checkedIn: false,
    currentStep: checkInStepsConfigArr[0],
    showCurrentStep: true,
    closeModal: false,
  })

  useEffect(() => {
    if (!initialKeySet) return

    // it's expected that keyset has populated property on it
    cmpStateDispatch(actions.setInitialCheckInData(initialKeySet))
  }, [initialKeySet])

  useEffect(() => {
    if (cmpState.closeModal) {
      closeModalCb(cmpState.checkedIn && cmpState.checkInData.keySet)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmpState.closeModal])

  const closeModal = () => {
    cmpStateDispatch(actions.closeModal())
  }

  const onBack = () => {
    const { index } = cmpState.currentStep
    if (index < 1) return closeModal()
    cmpStateDispatch(actions.backStep())
  }

  const onNext = () => {
    const { index } = cmpState.currentStep
    if (index === checkInStepsConfigArr.length - 1) {
      closeModal()
    } else {
      cmpStateDispatch(actions.nextStep())
    }
  }

  const proceed = (checkInData) => {
    cmpStateDispatch(actions.stepSaved(checkInData))
    onNext()
  }

  const afterCheckIn = (checkInKeySetGuid) => {
    cmpStateDispatch(actions.setCheckedIn())
    cmpStateDispatch(actions.closeModal())
    afterCheckInAction('checkin', checkInKeySetGuid)
  }

  const { currentStep, checkInData } = cmpState

  if (!cmpState.currentStep.def) return null

  const progressBarConfig = {
    totalStepsCount: checkInStepsConfigArr.length,
    lastCheckedStep: currentStep.index + 1,
  }

  const { subHeading, showKeySetAsSubHeading } = currentStep.def
  const subHeadingText = showKeySetAsSubHeading
    ? checkInData.keySet.label
    : subHeading

  const modalContent = (
    <>
      {currentStep.def.id === checkInStepsConfig.keySetSelection.id && (
        <KeySetSelection checkInData={checkInData} proceed={proceed} />
      )}
      {currentStep.def.id === checkInStepsConfig.confirmation.id && (
        <Confirmation checkInData={checkInData} afterSave={afterCheckIn} />
      )}
    </>
  )

  return (
    <StepsModalContainer takeMinHeight>
      <StepsModalHeader
        title="Check In"
        progressBarConfig={progressBarConfig}
        actions={{ onDismiss: closeModal, onBack }}
      >
        {subHeadingText}
      </StepsModalHeader>
      {modalContent}
    </StepsModalContainer>
  )
}
