import React from 'react'

import emergencyIcon from 'app/assets/icons/summary_icons/emergency.svg'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import SummaryEmergencyContactBox from 'app/match/apply/application_summary_before_submit/shared_components/emergency_box/component'
import { urlTo } from 'app/sm/helpers'

const EmergencyContactContainer = ({
  componentClassName = '',
  emergencyContacts = [],
  isEmergencyContactsEditable = true,
  onEditClick,
  enableNote = false,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  displayedApplicant,
  section,
  id = '',
  toggleRequestInfoModal = undefined,
}) => {
  return (
    <div id={id} className={`container-component ${componentClassName}`}>
      <ContainerHeader
        icon={emergencyIcon}
        text="Next of Kin / Emergency Contacts"
        isEditable={isEmergencyContactsEditable}
        onEditClick={() =>
          onEditClick(`${urlTo('emergencyContactDetails')}?from=rentersummary`)
        }
        enableNote={enableNote}
        onNewNoteUpdated={onNewNoteUpdated}
        newNotesContent={newNotesContent}
        onNoteIconClicked={onNoteIconClicked}
        onNewNoteAdded={onNewNoteAdded}
        onNoteCrossClicked={onNoteCrossClicked}
        interpreteNoteResult={interpreteNoteResult}
        enableNotesButton={enableNotesButton}
        displayedApplicant={displayedApplicant}
        section={section}
        toggleRequestInfoModal={toggleRequestInfoModal}
      />
      <div className="application-container-padding pdf-display-flex flex-wrap-wrap">
        {emergencyContacts.length > 0 &&
          emergencyContacts.map((emergencyContact) => (
            <SummaryEmergencyContactBox
              contact={emergencyContact}
              key={emergencyContact.guidID}
              readOnly
              from="renterSummary"
            />
          ))}
        {emergencyContacts.length === 0 && <div>Not provided</div>}
      </div>
    </div>
  )
}
export default EmergencyContactContainer
