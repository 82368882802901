import { connect } from 'react-redux'

import { fetchTeamManagerList } from 'app/bond_cover/agency/agency_actions'
import {
  addNewManagerToRun,
  addSelectedPropertyInfos,
  deleteManagerState,
  removeSelectedPropertyInfos,
  updateManagerPreference,
  updateStartTimeInViewingRun,
  updateViewingRunReadOnly,
} from 'app/pages/teams/viewings-run/action'
import AllViewingRuns from 'app/pages/teams/viewings-run/components/all-runs'
import { changeTeam, fetchTeams } from 'app/session/session_actions'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'
import {
  fetchAllNewViewingRunVersion,
  fetchNewViewingRunVersion,
  updatelNewViewingRunManagerKeyCheckOut,
} from 'app/sm/viewings_new_run/action'

const mapStateToProps = ({ session }) => ({
  teams: session.teams || [],
})

const mapDispatchToProps = (dispatch) => ({
  fetchTeamManagerList: (agencyGUID) =>
    dispatch(fetchTeamManagerList(agencyGUID)),
  updateManagerPreference: (
    managerGUID,
    managerPreferenceField,
    managerPreferenceValue,
  ) =>
    dispatch(
      updateManagerPreference(
        managerGUID,
        managerPreferenceField,
        managerPreferenceValue,
      ),
    ),
  removeSelectedPropertyInfos: (properties) =>
    dispatch(removeSelectedPropertyInfos(properties)),
  updateStartTimeInViewingRun: (time) =>
    dispatch(updateStartTimeInViewingRun(time)),
  addNewManagerToRun: (managerDetails) =>
    dispatch(addNewManagerToRun(managerDetails)),
  addSelectedPropertyInfos: (properties) =>
    dispatch(addSelectedPropertyInfos(properties)),
  fetchAllNewViewingRunVersion: (teamGUID) =>
    dispatch(fetchAllNewViewingRunVersion(teamGUID)),
  updateViewingRunReadOnly: (isReadOnly) =>
    dispatch(updateViewingRunReadOnly(isReadOnly)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
  deleteManagerState: () => dispatch(deleteManagerState()),
  fetchNewViewingRunVersion: (teamId, runId) =>
    dispatch(fetchNewViewingRunVersion(teamId, runId)),
  fetchTeams: () => dispatch(fetchTeams()),
  changeTeam: (team) => dispatch(changeTeam(team)),
  updatelNewViewingRunManagerKeyCheckOut: (
    teamID,
    viewingRunGUID,
    managerGUID,
  ) =>
    dispatch(
      updatelNewViewingRunManagerKeyCheckOut(
        teamID,
        viewingRunGUID,
        managerGUID,
      ),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(AllViewingRuns)
