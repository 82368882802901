import React from 'react'

import { Link } from 'react-router-dom'

import * as MockData from 'app/sm/chat/chat_mock_data'
import Inbox from 'app/sm/chat/components/inbox'

function PropertyChatContainer() {
  return (
    <div className="sm-property-chat">
      <Inbox data={MockData.PropertyInbox} title="50 Bond St." />
    </div>
  )
}

export default PropertyChatContainer
