import React, { Component } from 'react'

import Dropzone from 'react-dropzone'

class DropZoneBasic extends Component {
  constructor() {
    super()
    this.onDrop = (files) => {
      this.props.onDrop(files)
    }
    this.state = {
      files: [],
    }
    this.onRemoveIconClick = this.onRemoveIconClick.bind(this)
  }

  onRemoveIconClick = (event, index) => {
    const { onRemoveIconClciked } = this.props
    onRemoveIconClciked(index)
    event.preventDefault()
  }

  render() {
    const files = this.props.files.map((file, index) => (
      <div>
        <a
          key={index}
          href={file.preview}
          target="_blank"
          rel="noopener noreferrer"
          className="img-upload-preview-container position-relative display-flex"
        >
          <i
            className="icon-remove remove-icon light-grey-text"
            onClick={(event) => this.onRemoveIconClick(event, index)}
          ></i>
          <img src={file.preview} alt="preview" />
        </a>
      </div>
    ))

    return (
      <Dropzone onDrop={this.onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section className="container width100">
            <aside>
              <div className="display-flex flex-wrap-wrap">
                {files}
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <div className="display-flex align-items-center">
                    <div className="btn btn-round add-bg-icon">
                      <i className="icon-add" />
                    </div>
                    {this.props.files && this.props.files.length === 0 && (
                      <div className="ml10">
                        <div>Add a photo</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </aside>
          </section>
        )}
      </Dropzone>
    )
  }
}

export default DropZoneBasic
