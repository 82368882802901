import React from 'react'

import { Box, Button } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

import { StyledSetup } from '..'

function NotStarted({ updateCurrentStepWithNext }) {
  return (
    <StyledSetup>
      <Box>
        Start receiving better quality rental applications with these quick
        steps (takes 2 minutes).
      </Box>
      <Box mt={theme.space[5] + 'px'}>
        <Box>Step 1. Confirm team details</Box>
        <Box>Step 2. Invite your team members</Box>
        <Box>Step 3. Add your logo</Box>
      </Box>
      <Box mt={theme.space[5] + 'px'}>
        <Button
          variant="outlineGreen"
          sizeVariant="large"
          onClick={updateCurrentStepWithNext}
        >
          Get started -{'>'}
        </Button>
      </Box>
    </StyledSetup>
  )
}

export default NotStarted
