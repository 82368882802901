import BaseTextArea from 'app/components/forms/base_text_area/component'
import { withValidation } from 'app/components/forms/util'

const validateisEmpty = (isOptional, value) => {
  if (!isOptional && value === '') {
    return 'This field is required'
  }
  return ''
}
const InputTextArea = withValidation(BaseTextArea, validateisEmpty)

export default InputTextArea
