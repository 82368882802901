import { Form, Formik, FormikHelpers, useFormikContext } from 'formik'
import qs from 'qs'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import searchIcon from 'app/assets/icons/search.svg'
import { Box, Button } from 'app/components/design-system-components'
import {
  AutocompleteField,
  InputField,
} from 'app/components/design-system-components/inputs'
import { SimpleOptionContainer } from 'app/components/design-system-components/inputs/Select/Select'
import { Text } from 'app/components/design-system-components/typography'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import theme from 'app/match/applicationReportPDF/assets/theme'
import type { AsyncFunction } from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_connection'
import {
  HeadingText,
  PageText,
} from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_container'
import type {
  SearchAgencyDomain,
  StreamLineRegisterPayload,
} from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_type'
import { ErrorMessage } from 'app/shared_components/helpers'
import { validateABN, validateEmail } from 'app/shared_components/validations'
import { SNUG_TERMS_URL, urlIds, urlTo } from 'app/sm/helpers'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { loadingStatesIds } from 'app/utils/loading-states'

const GettingStartedContainer = styled(Box)``

const formFields = {
  agency: 'agency',
  email: 'email',
  abn: 'abn',
}

type IForm = {
  agency: string
  email: string
  abn: string
}

const initialValues = {
  [formFields.agency]: '',
  [formFields.email]: '',
  [formFields.abn]: '',
}

const HelpText = styled(Text)`
  font-size: ${({ theme }) => `${theme.fontSizes[4]}px`};
`

const StyledFieldWrapper = styled(FieldWrapper)`
  label:nth-child(2),
  button {
    height: ${theme.height[12]}px;
    border: 1px solid
      ${({ error }) => (error ? theme.colors.error500 : theme.colors.gray500)};
  }

  margin-bottom: ${theme.space[4]}px;

  label {
    height: ${theme.height[12]}px;
    border: 1px solid
      ${({ error }) => (error ? theme.colors.error500 : theme.colors.gray500)};
  }
`

const StyledButton = styled(Button)``

const StyledAutocompleteField = styled(AutocompleteField)`
  height: ${theme.height[12]}px;
`
// @ts-ignore
const generateFieldValidation =
  (abnOptional: boolean) => (fieldName: string, val: string) => {
    switch (fieldName) {
      case formFields.agency: {
        return {
          required: !val && 'Please search and choose your agency',
        }
      }
      case formFields.email: {
        return {
          required: !val && 'Please enter an email',
          pattern: validateEmail(val, null)[0],
        }
      }
      case formFields.abn: {
        return {
          required: !abnOptional && !val && 'Please enter ABN',
          pattern:
            val &&
            validateABN(val.replace(/\s+/g, ''), 'Must be a valid ABN')[0],
        }
      }
      default:
        return
    }
  }

const generateFormValidation = (abnOptional: boolean) =>
  formValidationBuilder(generateFieldValidation(abnOptional))

const generateAgencyOption = (agency: SearchAgencyDomain) => ({
  option: agency.id,
  optionMeta: {
    id: agency.id,
    displayText: `${agency.name} (${agency.suburb})`,
  },
})

const AgencyNotFound = () => {
  return (
    <>
      <SimpleOptionContainer>
        Agency not found, please check name
      </SimpleOptionContainer>
      <SimpleOptionContainer>
        If not found please contact <Link to="/">help@snug.com</Link>
      </SimpleOptionContainer>
    </>
  )
}

const FormBody = ({
  searchAgencyDomain,
  submitError,
}: {
  searchAgencyDomain: AsyncFunction<string>
  submitError: string
}) => {
  const formikContext = useFormikContext<IForm>()
  const {
    errors = {},
    submitForm,
    isSubmitting,
    isValid,
    touched = {},
  } = formikContext

  const {
    state: agenciesOptions,
    setState: setAgenciesOptions,
    loadingStates: agenciesLoadingStates,
    loadingStatesHelpers: agenciesLoadingStatesHelpers,
  } = useStateWithLoading([])

  const onSearchAgency = debounce(
    (text: string) => {
      agenciesLoadingStatesHelpers.startLoading()
      return searchAgencyDomain(text)
        .then((agencies: SearchAgencyDomain[]) => {
          const savedOptions = agencies.map((agency: SearchAgencyDomain) =>
            generateAgencyOption(agency),
          )
          setAgenciesOptions(savedOptions)
          agenciesLoadingStatesHelpers.markDoneSuccessfully()
        })
        .catch((err: string) => {
          setAgenciesOptions([])
          agenciesLoadingStatesHelpers.setError(err)
        })
    },
    STANDARD_TIMEOUT,
    false,
  )

  return (
    <Form style={{ height: '100%' }}>
      <HeadingText>Get Started</HeadingText>
      <PageText>
        Add your details. Already a Snug customer?{' '}
        <Link to={urlTo(urlIds.join.index)}>Log in</Link>
      </PageText>
      <StyledFieldWrapper
        error={touched.agency && errors.agency}
        id={formFields.agency}
        name={formFields.agency}
      >
        <StyledAutocompleteField
          placeholder="Search agency name"
          id={formFields.agency}
          name={formFields.agency}
          options={agenciesOptions}
          onChangeText={onSearchAgency}
          optionsHeight="400px"
          isLoading={agenciesLoadingStates.state === loadingStatesIds.LOADING}
          icon={<img src={searchIcon} alt="icon" />}
          height={`${theme.height[12]}px`}
          NoOptionsCmp={AgencyNotFound}
        />
      </StyledFieldWrapper>
      <StyledFieldWrapper
        error={touched.email && errors.email}
        id={formFields.email}
        name={formFields.email}
      >
        <InputField
          id={formFields.email}
          name={formFields.email}
          inputProps={{ placeholder: 'Your email address' }}
        />
      </StyledFieldWrapper>
      <StyledFieldWrapper
        error={touched.abn && errors.abn}
        id={formFields.abn}
        name={formFields.abn}
      >
        <InputField
          id={formFields.abn}
          name={formFields.abn}
          inputProps={{ placeholder: 'ABN' }}
        />
      </StyledFieldWrapper>
      <HelpText mt={`${theme.dividedSpace[10]}px`}>
        By registering, you accept the{' '}
        <a target="_blank" rel="noopener noreferrer" href={SNUG_TERMS_URL}>
          Terms of Use
        </a>
      </HelpText>
      <ErrorMessage error={submitError} />
      <StyledButton onClick={submitForm} disabled={!isValid || isSubmitting}>
        Register <i className={isSubmitting ? 'fa fa-spinner fa-pulse' : ''} />
      </StyledButton>
    </Form>
  )
}

type IGettingStartedProps = {
  handleSubmitProperties: (values: StreamLineRegisterPayload) => void
  submitLoading: boolean
  searchAgencyDomain: AsyncFunction<string>
  submitError: string
}
const GettingStarted = ({
  handleSubmitProperties,
  submitLoading,
  ...props
}: IGettingStartedProps) => {
  const curQueries = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  const { abn = '' } = curQueries || {}
  const isAbnOptional = abn === 'optional'

  const submitProperties = (
    values: {
      [x: string]: string
    },
    _: FormikHelpers<{
      [x: string]: string
    }>,
  ) => {
    const typedValues: any = values
    const {
      agency: { option },
      email = '',
      abn = '',
    } = typedValues

    const payload = {
      agencyId: option,
      agencyEmail: email,
      abn,
      isAbnOptional,
    }
    return handleSubmitProperties(payload)
  }

  return (
    <GettingStartedContainer>
      <Formik
        initialValues={initialValues}
        validate={generateFormValidation(isAbnOptional)}
        onSubmit={submitProperties}
        validateOnChange={true}
        validateOnBlur={true}
      >
        <FormBody {...props} />
      </Formik>
    </GettingStartedContainer>
  )
}

export default GettingStarted
