import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

import humanIcon01 from 'app/assets/icons/human01.jpg'

function RatingForm(props) {
  let {
    address,
    requestID,
    secret,
    disabledContinue,
    disabledCannotRate,
    permissionClicked,
    declineClicked,
    getRating,
    ratingCategories,
    update,
    updateTenantRating,
    tenantRating,
    renter,
    proceed,
    decline,
  } = props
  let firstName = renter ? renter.firstName : ''
  let lastName = renter ? renter.lastName : ''
  let mobile = renter ? renter.mobile : ''
  let email = renter ? renter.email : ''
  let ratings = [1, 2, 3, 4, 5]
  if (!ratingCategories || ratingCategories.length == null) {
    return <div />
  } else {
    return (
      <div>
        {/*<div className="text-center pb30">
          <div className="avatar avatar-xbig blue-bg">{firstName ? firstName.charAt(0) : ''}{lastName ? lastName.charAt(0) : ''}</div>
          <div className="avatar avatar-xbig" style={{ backgroundImage: `url(${humanIcon01})` }}></div>
        </div>*/}

        <h3 className="mb20">
          Is {firstName} {lastName} a good renter?
        </h3>

        <h4 className="mb25">
          {address
            ? address.friendlyName +
              ', ' +
              address.suburb +
              ', ' +
              address.state +
              ' ' +
              address.postcode
            : ''}
        </h4>

        <p className="mb30">
          Snug rewards good renters, so please rate honestly! Your feedback will
          be shared privately with your renter.
        </p>

        {ratingCategories.map((j) => {
          return (
            <div className="rating-categories-box" key={j.Code + j.Description}>
              <div className="rating-desc"> {j.Description} </div>
              <div className="rating-stars" key={j.Code}>
                {ratings.map((i) => {
                  let rating = getRating(j.Code, tenantRating, 'Code')
                  let key = i
                  return (
                    <i
                      key={j.Code + '.' + key}
                      className={
                        key <= rating ? 'icon-star rated' : 'icon-star'
                      }
                      onClick={updateTenantRating(
                        j.Code,
                        key,
                        requestID,
                        secret,
                      )}
                    ></i>
                  )
                })}
              </div>
            </div>
          )
        })}

        <div className="input-box">
          <textarea required rows="4" onChange={update('comment')} />
          <label>Add a comment</label>
        </div>

        <div className="row mt40 mb10 mobile-form-button">
          <div className="col-sm-2 pb5 tablet-only"></div>
          <div className="col-sm-7 margin-desktop-only">
            <button disabled={disabledContinue} onClick={proceed}>
              {' '}
              Continue{' '}
              <i
                className={permissionClicked ? 'fa fa-spinner fa-pulse' : ''}
              />{' '}
            </button>
          </div>
        </div>
        <div className="row mobile-form-button">
          <div className="col-sm-2 pb5 tablet-only"></div>
          <div className="col-sm-7 margin-desktop-only">
            <button
              className="btn btn-transparent"
              disabled={disabledCannotRate}
              onClick={proceed}
            >
              {' '}
              I cannot rate this renter{' '}
              <i
                className={declineClicked ? 'fa fa-spinner fa-pulse' : ''}
              />{' '}
            </button>
          </div>
        </div>

        <p className="mt30 mb0 fs13 lh13 gray-color">
          Snug respects the privacy of rental community members and will only
          share your feedback with your permission and in accordance with our
          Privacy Policy.
        </p>
      </div>
    )
  }
}

export default RatingForm
