import { connect } from 'react-redux'

import { setBackUrl } from 'app/shared_components/actions'
import {
  archiveProperty,
  fetchProperties,
  receiveResponseText,
  unarchiveProperty,
  withdrawAdvertisement,
  withdrawModalOff,
  withdrawModalOn,
} from 'app/sm/properties/properties_actions'
import PropertiesContainer from 'app/sm/properties/properties_container'
const mapStateToProps = ({ MyProperties, session }) => ({
  properties: MyProperties.properties,
  archivedProperties: MyProperties.archivedProperties,
  withdrawModalId: MyProperties.withdrawModalId,
  currentUser: session.currentUser,
  spinner: MyProperties.spinner,
  responseMetadata: MyProperties.responseMetadata,
  currentTeam: session.currentTeam,
  firstPageCursor: MyProperties.firstPageCursor,
})

const mapDispatchToProps = (dispatch) => ({
  withdrawAdvertisement: (adId) => dispatch(withdrawAdvertisement(adId)),
  withdrawModalOn: (propertyId) => dispatch(withdrawModalOn(propertyId)),
  withdrawModalOff: () => dispatch(withdrawModalOff()),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
  archiveProperty: (id) => dispatch(archiveProperty(id)),
  unarchiveProperty: (id) => dispatch(unarchiveProperty(id)),
  fetchProperties: (
    teamGUID,
    cursor,
    status,
    address,
    limit,
    managerID,
    hasApplicant,
  ) =>
    dispatch(
      fetchProperties(
        teamGUID,
        cursor,
        status,
        address,
        limit,
        managerID,
        hasApplicant,
      ),
    ),
  receiveResponseText: (responseText) =>
    dispatch(receiveResponseText(responseText)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesContainer)
