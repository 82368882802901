import qs from 'qs'

// urlQueryString: a url query string e.g. ?q=something
// returns an object e.g. { q: 'something' }
export const parseQueryString = (urlQueryString) => {
  return qs.parse(urlQueryString, { ignoreQueryPrefix: true })
}

// query: object with key and value e.g. { q: 'something' }
// returns a query string e.g. ?q=something
const generateQueryString = (query) => {
  const arr = []
  Object.keys(query).forEach((key) => {
    const value = query[key]
    if (value) {
      // TODO: URL encoding?
      arr.push(`${key}=${value}`)
    }
  })
  return `?${arr.join('&')}`
}

// history: React BrowserHistory object
// pathName: existing pathName, usually location.pathname, e.g. /some/path
// queryString: a url query string e.g. ?q=something
// TODO: decouple from React BrowserHistory object
export const updateBrowserHistory = (history, pathName, queryString) => {
  history.push({
    pathname: pathName,
    search: queryString,
  })
}

// history: React BrowserHistory object
// pathName: existing pathName, usually location.pathname, e.g. /some/path
// query: object with key and value e.g. { q: 'something' }
export const setURLQuery = (history, pathName, query) => {
  const queryString = generateQueryString(query)
  updateBrowserHistory(history, pathName, queryString)
}
