import { apiFailCallback } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import { getApplication } from 'app/sm/apply/apply_access'
import { urlTo } from 'app/sm/helpers'
import {
  RECEIVE_OFFERED_APPLICATION,
  RECEIVE_PROPERTY,
  SET_PROPERTY_ID,
} from 'app/sm/oa_tenant/oa_tenant_actions'
import {
  withdrawApplication,
  withdrawSecondaryApplicant,
} from 'app/sm/withdraw_confirmation/withdraw_confirmation_access'
import store from 'app/store'

// Action Creators

export const RECEIVE_RESPONSE_TEXT = 'WC_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'WC_RECEIVE_ERROR'
export const WITHDRAW_BUTTON_CLICKED = 'WITHDRAW_BUTTON_CLICKED'
export const WITHDRAW_COMPLETE = 'WITHDRAW_COMPLETE'
export const CLEAR_ERROR = 'WC_CLEAR_ERROR'

export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
export const clearError = () => ({ type: CLEAR_ERROR })
export const withdrawButtonClicked = () => ({
  type: WITHDRAW_BUTTON_CLICKED,
  withdrawButtonClicked: true,
})
export const withdrawComplete = () => ({
  type: WITHDRAW_COMPLETE,
  withdrawButtonClicked: false,
})

export const setPropertyId = (propertyId) => ({
  type: SET_PROPERTY_ID,
  propertyId,
})
export const receiveProperty = (property) => ({
  type: RECEIVE_PROPERTY,
  property,
})
export const receiveOfferedApplication = (offeredApplication) => ({
  type: RECEIVE_OFFERED_APPLICATION,
  offeredApplication,
})

// Methods

export function fetchOfferedApplication(applicationId) {
  return (dispatch) => {
    getApplication(applicationId).then(
      ({ application, ok, responseText, statusCode }) => {
        if (ok) {
          const { property } = application.offer

          dispatch(setPropertyId(property.guidID))
          dispatch(receiveProperty(property))
          dispatch(receiveOfferedApplication(application))
        } else {
          responseText.then((t) => {
            store.dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}

export function acceptWithdraw(applicationId, success, fail) {
  store.dispatch(clearError())
  return (dispatch) => {
    dispatch(withdrawButtonClicked())
    return withdrawApplication(applicationId).then(
      ({ ok, responseText, statusCode }) => {
        dispatch(withdrawComplete())
        if (ok) {
          if (success) {
            success()
          }
          history.push(urlTo('applications'))
          return Promise.resolve()
        } else {
          apiFailCallback(responseText, fail)
          return responseText.then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export function acceptSecondaryWithdraw(
  applicationId,
  applicantId,
  success,
  fail,
) {
  store.dispatch(clearError())
  return (dispatch) => {
    dispatch(withdrawButtonClicked())
    withdrawSecondaryApplicant(applicationId, applicantId).then(
      ({ ok, responseText, statusCode }) => {
        dispatch(withdrawComplete())
        if (ok) {
          if (success) {
            success()
          }
          history.push(urlTo('applications'))
        } else {
          apiFailCallback(responseText, fail)
        }
      },
    )
  }
}
