import { useEffect, useState } from 'react'

import styled from 'styled-components'

import { Box, Flex, Spinner } from 'app/components/design-system-components'
import { CheckCircleRounded } from 'app/components/design-system-components/icons/actions'
import { ErrorRounded } from 'app/components/design-system-components/icons/alert'
import * as getters from 'app/components/design-system-components/theme/getters'
import { Text } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { calculateFileSize } from 'app/utils/attachments'
import { loadingStatesIds } from 'app/utils/loading-states'

const ImageContainer = styled(Box)`
  width: 100px;
  min-width: 100px;
  height: 100px;
  background-size: cover;

  border: 1px solid ${getters.color('gray200')};
  border-radius: ${getters.radius(6)}px;
`

const states = {
  uploaded: 'uploaded',
  error: 'error',
}

export const Image = ({
  imageAttachment,
  fileId,
  deleteImage,
  retryUpload,
  ...otherProps
}) => {
  const [imageData, setImageData] = useState({
    guid: '',
    name: '',
    size: 0,
    type: '',
    url: '',
  })

  useEffect(() => {
    if (imageAttachment.guid) {
      const { guid, name, size, type, url } = imageAttachment.rawAttachment
      setImageData({ guid, name, size, type, url })
    } else {
      const { name, size, type } = imageAttachment.file
      const url = URL.createObjectURL(imageAttachment.file)
      setImageData({ name, size, type, url, guid: '' })
    }

    return () => !imageData.guid && URL.revokeObjectURL(imageData.url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageAttachment])

  const { guid, name, size, type, url } = imageData
  const { loadingStates } = imageAttachment

  const [sizeVal, sizeUnit] = calculateFileSize(size)
  const fileSize = `${Math.round(sizeVal * 10) / 10}${sizeUnit}`

  const renderStatusAndIndicatorElem = () => {
    if (loadingStates.state === loadingStatesIds.LOADING) {
      return <Spinner />
    }

    const state =
      loadingStates.state === loadingStatesIds.ERROR
        ? states.error
        : guid && loadingStates.state === loadingStatesIds.LOADED
        ? states.uploaded
        : null

    if (!state) return null

    const config =
      state === states.error
        ? {
            color: theme.colors.error400,
            icon: <ErrorRounded />,
            text: loadingStates.error.message,
          }
        : {
            color: theme.colors.success600,
            icon: <CheckCircleRounded />,
            text: 'Uploaded',
          }

    return (
      <Flex color={config.color} alignItems="center" mb={theme.space[3] + 'px'}>
        <Box
          fontSize={theme.fontSizes.pExtraLarge18}
          mr={theme.space[3] + 'px'}
        >
          {config.icon}
        </Box>
        <Text
          as="div"
          fontWeight={theme.fontWeights[6]}
          fontSize={theme.fontSizes.pRegular14}
        >
          {config.text}
        </Text>
      </Flex>
    )
  }

  const statusAndIndicatorsElem = renderStatusAndIndicatorElem()

  const nameElem = (
    <Text as="div" fontWeight={3}>
      {name}{' '}
      <Text as="span" color={theme.colors.gray400}>
        ({fileSize})
      </Text>
    </Text>
  )

  const actionsElem =
    loadingStates.state !== loadingStatesIds.LOADING ? (
      <a
        role="button"
        onClick={() => deleteImage(fileId)}
        style={{ fontSize: theme.fontSizes.pSmall12 }}
      >
        remove
      </a>
    ) : null

  const infoBlock = (
    <Box ml={theme.space[5]}>
      {statusAndIndicatorsElem}

      {nameElem}

      {actionsElem}
    </Box>
  )

  return (
    <Flex alignItems="center" {...otherProps}>
      <ImageContainer style={{ backgroundImage: `url(${url})` }} />
      {infoBlock}
    </Flex>
  )
}
