import { NetworkConnectionError } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  addOnlistDetails,
  addVirtualTourVideoLinks,
  deletePropertyLookupForProperty,
  getAllPropertyLookupsForProperty,
  getEntryDetailsByTeamGUIDAndPropertyGUID,
  getPropertyVideoLinks,
  postPropertyLookupForProperty,
  putOfferWithPropertyViewingSchedule,
  putOfferWithViewingRegistrantsLimit,
  putOfferWithViewingScheduleZone,
  updateOnlistDetails,
} from 'app/sm/onlist_details/access'
import { getActiveOffer } from 'app/sm/renter_applications/renter_applications_access'

export const fetchPropertyEntryDetails = (agencyGUID, propGUID) => {
  return () => {
    return getEntryDetailsByTeamGUIDAndPropertyGUID(agencyGUID, propGUID).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export const createEntryDetails =
  (agencyGUID, propGUID, requestBody) => (dispatch) => {
    return addOnlistDetails(agencyGUID, propGUID, requestBody).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((t) => {
            return Promise.reject(translateErrorCodeToMessage(t))
          })
        }
      },
    )
  }

export const updateEntryDetails =
  (agencyGUID, propGUID, requestBody) => (dispatch) => {
    return updateOnlistDetails(agencyGUID, propGUID, requestBody).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((t) => {
            return Promise.reject(translateErrorCodeToMessage(t))
          })
        }
      },
    )
  }

export const fetchVirtualTourLinks = (agencyGUID, propGUID) => {
  return () => {
    return getPropertyVideoLinks(agencyGUID, propGUID).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export const createVirtualTour =
  (agencyGUID, propGUID, requestBody) => (dispatch) => {
    return addVirtualTourVideoLinks(agencyGUID, propGUID, requestBody).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((t) => {
            return Promise.reject(translateErrorCodeToMessage(t))
          })
        }
      },
    )
  }

export const fetchPropertyLookup = (agencyGUID, offerGUID) => () => {
  return getAllPropertyLookupsForProperty(agencyGUID, offerGUID).then(
    ({ ok, result, responseText }) => {
      if (ok) {
        return Promise.resolve(result)
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      }
    },
  )
}

export const addPropertyLookup = (agencyGUID, offerGUID, requestBody) => () => {
  return postPropertyLookupForProperty(agencyGUID, offerGUID, requestBody).then(
    ({ ok, result, responseText }) => {
      if (ok) {
        return Promise.resolve(result)
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      }
    },
  )
}

export const removePropertyLookup =
  (agencyGUID, offerGUID, propertyLookupGUID) => () => {
    return deletePropertyLookupForProperty(
      agencyGUID,
      offerGUID,
      propertyLookupGUID,
    ).then(({ ok, result, responseText }) => {
      if (ok) {
        return Promise.resolve(result)
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      }
    })
  }

export const fetchOffer = (offerGUID) => () => {
  return getActiveOffer(offerGUID).then(({ offer, ok, responseText }) => {
    if (ok) {
      return Promise.resolve(offer)
    } else {
      if (responseText === undefined) {
        return Promise.reject(NetworkConnectionError)
      }
      return responseText.then((t) => {
        return Promise.reject(translateErrorCodeToMessage(t))
      })
    }
  })
}

export const updateOfferWithViewingScheduleZone =
  (agencyGUID, offerGUID, viewingScheduleZone) => () => {
    return putOfferWithViewingScheduleZone(
      agencyGUID,
      offerGUID,
      viewingScheduleZone,
    ).then(({ ok, result, responseText }) => {
      if (ok) {
        return Promise.resolve(result)
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      }
    })
  }

export const updateOfferViewingRegistrantsLimit =
  (agencyGUID, offerGUID, requestBody) => () => {
    return putOfferWithViewingRegistrantsLimit(
      agencyGUID,
      offerGUID,
      requestBody,
    ).then(({ ok, result, responseText }) => {
      if (ok) {
        return Promise.resolve(result)
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      }
    })
  }

export const updateOfferWithPropertyViewingSchedule =
  (agencyGUID, offerGUID, propertyViewingSchedule) => () => {
    return putOfferWithPropertyViewingSchedule(
      agencyGUID,
      offerGUID,
      propertyViewingSchedule,
    ).then(({ ok, result, responseText }) => {
      if (ok) {
        return Promise.resolve(result)
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      }
    })
  }
