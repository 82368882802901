import React, { useEffect, useState } from 'react'

import qs from 'qs'

import {
  Alert,
  Box,
  Button,
  Flex,
} from 'app/components/design-system-components'
import Card from 'app/components/design-system-components/Card'
import { LockOpenRounded } from 'app/components/design-system-components/icons/actions'
import { Text } from 'app/components/design-system-components/typography'
import { postApplicationRequestAccess } from 'app/services/http/app-request-access'
import * as snugNotifier from 'app/services/snugNotifier'
import { isLoggedIn } from 'app/shared_components/helpers'
import RequestAccessModal from 'app/shared_components/request_app_access/request_access_modal'
import { history } from 'app/shared_components/router'
import * as helpers from 'app/sm/helpers'
import { SessionStorageUtils } from 'app/storage_utils'

function RequestAppAccess({ currentUser, logout, backUrl }) {
  const [isRequestAccessModalOpen, setIsRequestAccessModalOpen] =
    useState(false)
  const [applicationGUIDForSubmission, setApplicationGUIDForSubmission] =
    useState('')
  const [requestAccessButtonDisabled, setRequestAccessButtonDisabled] =
    useState(false)
  const toggleIsRequestAccessModalOpen = () => {
    setIsRequestAccessModalOpen(!isRequestAccessModalOpen)
  }
  useEffect(() => {
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    setApplicationGUIDForSubmission(curQueries?.applicationGUID)
  }, [])
  const isUserLoggedIn = isLoggedIn(currentUser)
  const submitApplicationRequestAccess = (values) => {
    if (!applicationGUIDForSubmission) {
      snugNotifier.error('Please make sure that the link is valid')
      return
    }
    postApplicationRequestAccess(applicationGUIDForSubmission, values)
      .then(() => {
        snugNotifier.success('Access Requested')
        toggleIsRequestAccessModalOpen()
        setRequestAccessButtonDisabled(true)
      })
      .catch((err) => {
        const message = err?.message || 'Unable to request access'
        snugNotifier.error(message)
      })
  }
  const onLoginButtonClick = () => {
    SessionStorageUtils.setItem('forwardUrl', backUrl)
    history.push(helpers.urlTo(helpers.urlIds.join.index))
  }

  return (
    <Flex justifyContent="center" alignItems="center" height="60vh">
      <Card
        internalComponent={
          <Box p={8}>
            <Text as="h2">Request access</Text>
            <Text>Request access from your team administrator</Text>
            <Alert variant="blueWithBg">
              <Flex alignItems="center" justifyContent="center">
                <img
                  src="https://snugco.s3.ap-southeast-2.amazonaws.com/assets/img/shield-lock2.png"
                  alt="shield"
                  height="20px"
                  width="20px"
                />{' '}
                <Box ml={4}>
                  All requests are tracked for security and privacy
                </Box>
              </Flex>
            </Alert>
            <Button
              mt={8}
              mb={8}
              sizeVariant="large"
              disabled={requestAccessButtonDisabled}
            >
              <Flex alignItems="center" justifyContent="center">
                <LockOpenRounded height="20px" width="20px" />{' '}
                <Box ml={4} onClick={toggleIsRequestAccessModalOpen}>
                  Request Access
                </Box>
              </Flex>
            </Button>
            {isUserLoggedIn && (
              <Box>
                You’re logged in as {currentUser?.email},{' '}
                <Button onClick={logout} variant="linkBlueWithoutWeight">
                  Log out
                </Button>
              </Box>
            )}
            {!isUserLoggedIn && (
              <Box>
                If you already have an account,{' '}
                <Button
                  variant="linkBlueWithoutWeight"
                  onClick={() => onLoginButtonClick()}
                >
                  Log in
                </Button>
              </Box>
            )}
          </Box>
        }
      />
      {isRequestAccessModalOpen && (
        <RequestAccessModal
          toggleIsRequestAccessModalOpen={toggleIsRequestAccessModalOpen}
          currentUser={currentUser}
          submitApplicationRequestAccess={submitApplicationRequestAccess}
        />
      )}
    </Flex>
  )
}

export default RequestAppAccess
