import React from 'react'

import ReactDOM from 'react-dom'

class SearchInline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      extended: false,
    }
  }

  extend() {
    this.setState({ extended: true })
  }

  shrink() {
    this.setState({ extended: false })
  }

  render() {
    let { placeholder = '', className = '' } = this.props
    let { extended } = this.state

    return (
      <div
        className={`sm-search-inline ${
          extended ? 'extended' : ''
        } ${className}`}
      >
        <input
          type="text"
          onFocus={() => this.extend()}
          onBlur={() => this.shrink()}
          placeholder={placeholder}
        />
        <i className="icon-search"></i>
      </div>
    )
  }
}

export default SearchInline
