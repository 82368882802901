import React from 'react'

import { useHistory } from 'react-router-dom'

import { Button } from 'app/components/design-system-components'
import MobileVerificationForm from 'app/session/register_components/mobile_verification_form_connection'
import Footer from 'app/shared_components/footer/footer'
import SnugLogo from 'app/shared_components/snug_logo'
import 'react-toastify/dist/ReactToastify.css'

const MobileVerification = ({ attempts }) => {
  const history = useHistory()

  return (
    <div className="auth-wrapper">
      <div className="auth-screen panel panel-default">
        <div className="panel-heading">
          <div className="app-header">
            <div className="app-header_left">
              <Button variant="flat" p="0" onClick={() => history.goBack()}>
                <i className="fa fa-chevron-left"></i>
              </Button>
            </div>
            <div className="app-header_center">
              <SnugLogo />
            </div>
            <div className="app-header_right"></div>
          </div>
        </div>
        <div className="panel-body">
          <h3 className="mb30">Enter Verification Code</h3>
          <MobileVerificationForm attempts={attempts} />
          <Footer />
        </div>
      </div>
    </div>
  )
}
export default MobileVerification
