import React from 'react'

import * as Display from 'app/components/display/display'
import Checkbox from 'app/shared_components/checkbox'
import * as Form from 'app/shared_components/form_component'

function DeclineApplicationModal(props) {
  const {
    declineApplicationOptions,
    declineApplicationNotifyApplicant,
    onChangeDeclineApplicationOptions,
    toggleModal,
    onConfirmDeclineApplicationClicked,
    onChangeNotifyApplicant,
    onChangeDeclineComment,
    declineComment,
    declineApplicationInProgress,
  } = props
  const declineReasons = [
    {
      label: 'Owner selected an alternative applicant',
      value: declineApplicationOptions.ownerSelectedAnAlternative,
      field: 'ownerSelectedAnAlternative',
      componentClassName: '',
      id: '',
    },
    {
      label: 'Household affordability of rent is an issue',
      value: declineApplicationOptions.householdAffordabilityIssue,
      field: 'householdAffordabilityIssue',
      componentClassName: '',
      id: '',
    },
    {
      label: 'Pet is not suitable for this property',
      value: declineApplicationOptions.petNotSuitable,
      field: 'petNotSuitable',
      componentClassName: '',
      id: '',
    },
    {
      label: 'Rental references are an issue',
      value: declineApplicationOptions.rentalReferenceIssue,
      field: 'rentalReferenceIssue',
      componentClassName: '',
      id: '',
    },
    {
      label: 'Insufficient documentation eg. ID, is an issue',
      value: declineApplicationOptions.insufficientDocumentation,
      field: 'insufficientDocumentation',
      componentClassName: '',
      id: '',
    },
    {
      label: 'Property is not suitable ',
      value: declineApplicationOptions.notSuitableProperty,
      field: 'notSuitableProperty',
      componentClassName: '',
      id: '',
    },
    {
      label: 'Property is being withdrawn due to owner',
      value: declineApplicationOptions.ownerWithdrawnProperty,
      field: 'ownerWithdrawnProperty',
      componentClassName: '',
      id: '',
    },
    {
      label: 'Other, no specific reason',
      value: declineApplicationOptions.noSpecificReason,
      field: 'noSpecificReason',
      componentClassName: '',
      id: '',
    },
  ]

  return (
    <Display.Modal
      toggleModal={() => toggleModal('')}
      title="Decline Application"
      hideButtonSection
      modalSubheadingClass="fs18"
    >
      <div className="fs16">
        <p className="mb10 fw500">Optionally, select the decline reason(s):</p>
        <div className="mt20 mb30">
          {declineReasons.map((checkbox, index) => {
            return (
              <div key={index}>
                <Checkbox
                  label={checkbox.label}
                  value={checkbox.value}
                  onChange={onChangeDeclineApplicationOptions(checkbox.field)}
                  componentClassName={`mt15 ${checkbox.componentClassName}`}
                  spanClassName="gray-color fs16 height-auto width100"
                  id={checkbox.id}
                />
              </div>
            )
          })}
          {declineApplicationOptions.noSpecificReason && (
            <div className="mt30">
              <textarea
                className="textarea border-radius-6px no-resize p10 width100"
                rows={3}
                maxLength={160}
                placeholder="Add Comment..."
                onChange={onChangeDeclineComment()}
                value={declineComment}
              />
            </div>
          )}
          <div className="mt30">
            <Form.Checkbox
              label="Notify the applicant with a soft decline, unsuccessful message"
              id="notifyApplicant"
              onChange={onChangeNotifyApplicant()}
              checked={declineApplicationNotifyApplicant}
              checkboxLabelSpanClassName="fs16 height-auto"
            />
          </div>
        </div>
      </div>
      <Display.ModalButtonGroup
        primaryLabel="Decline"
        secondaryLabel="Cancel"
        secondaryButtonType="tertiary"
        primaryAction={() => onConfirmDeclineApplicationClicked()}
        secondaryAction={() => toggleModal('')}
        disable={declineApplicationInProgress}
        isLoading={declineApplicationInProgress}
      />
    </Display.Modal>
  )
}

export default DeclineApplicationModal
