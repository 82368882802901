import React from 'react'

import { CheckboxWithDropdown } from 'app/components/design-system-components'

export const SelectMessagesHeader = ({
  hasSelection,
  toggleSelection,
  messagesSelectionOptions,
}) => {
  return (
    <CheckboxWithDropdown
      isChecked={hasSelection}
      toggleSelection={toggleSelection}
      options={messagesSelectionOptions}
    />
  )
}
