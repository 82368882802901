import React from 'react'

import AvatarEditor from 'react-avatar-editor'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { Link } from 'react-router-dom'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import { Box } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import ProgressBarComponent from 'app/components/progress_bar/connection'
import Checkbox from 'app/shared_components/checkbox'
import DocumentListText from 'app/shared_components/document_list/component/document_list_text'
import getParameter from 'app/shared_components/parameter_parser'
import Header from 'app/shared_components/rental_reputation_header_connection'
import { history } from 'app/shared_components/router'
import SnugTip from 'app/shared_components/snug_tips'
import { isFromApplication, urlIds, urlTo } from 'app/sm/helpers'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import { SUBTEXT_FILE_UPLOAD_IMAGE_FILES } from 'app/utils/text/helpers'

const InitialsAvatar = ({ firstName, lastName }) => (
  <div className="initials-avatar blue-bg">
    {firstName ? firstName.charAt(0).toUpperCase() : ''}
    {lastName ? lastName.charAt(0).toUpperCase() : ''}
  </div>
)

const PictureAvatar = ({ src, borderRadius }) => (
  <img src={src} style={{ borderRadius: `${borderRadius}px` }} />
)

const ColumnCentered = ({ children }) => {
  return <div className="flex-column-center">{children}</div>
}

const AvatarToolsRow = ({ children }) => {
  return <div className="avatar-tools-row">{children}</div>
}

const RotateButton = ({ onClick, direction }) => {
  return <i className={`fa fa-2x fa-rotate-${direction}`} onClick={onClick} />
}

const UploadImageWidget = ({ updatePhotos }) => {
  return (
    <div className="sm-property-photos">
      <ul className="sm-property-photos__list">
        <li className="add-avatar single">
          <form id="property-photos-upload">
            <input id="photos" type="file" onChange={updatePhotos} multiple />
            <label htmlFor="photos">
              <i className="icon-image"></i>
              <b>Upload manually</b>
              <span>or drag and drop a photo here.</span>
              <Box>
                <DocumentListText
                  textToDisplay={SUBTEXT_FILE_UPLOAD_IMAGE_FILES}
                />
              </Box>
            </label>
          </form>
        </li>
      </ul>
    </div>
  )
}

const DragDropUploadImageWidget =
  DragDropContext(HTML5Backend)(UploadImageWidget)

const BackButton = ({ backUrl }) => {
  return (
    <Link
      to={backUrl}
      className="btn btn-transparent btn-left xs-text-center wa"
    >
      <i className="icon-arrow-left left"></i>
      <span>Back</span>
    </Link>
  )
}

const SaveButton = ({ submit, disabled, isSubmitting, saveButtonClass }) => {
  return (
    <button
      className={`btn ${saveButtonClass}`}
      disabled={disabled}
      onClick={submit}
    >
      Save
      <i className={isSubmitting ? 'fa fa-spinner fa-pulse m0' : 'm0'} />
    </button>
  )
}

const SaveButtonWidget = ({
  submit,
  disabled,
  isSubmitting,
  saveButtonClass,
}) => (
  <div className="mt10">
    <SaveButton
      submit={submit}
      disabled={disabled}
      isSubmitting={isSubmitting}
      saveButtonClass={saveButtonClass}
    />
  </div>
)

const withMobileOnly = (InputComponent) => {
  return (props) => {
    return (
      <div className="mobile-only">
        <BottomButton isMobile={true} />
      </div>
    )
  }
}

const MobileOnlyBackButton = withMobileOnly(BackButton)

const ErrorMessage = ({ error }) => (
  <div className="row">
    <div className="col-sm-12">
      <div className={error ? 'alert alert-danger' : ''}>
        <div>{error}</div>
      </div>
    </div>
  </div>
)

const Avatar = ({
  currentUser,
  photoPreviewUrl,
  rotate,
  rotateLeft,
  rotateRight,
  setEditorRef,
  updatePhotos,
  backUrl,
  submitPhoto,
  isSubmitting,
  photosValidationError,
  apiError,
  property,
  completeness,
  onChangeOptOutProfileAvatar,
  optOutProfileAvatar,
}) => {
  let mode = ''
  if (currentUser.avatar) {
    mode = 'showExisting'
  }
  if (photoPreviewUrl) {
    mode = 'editing'
  }

  let image, alpha
  if (mode === 'showExisting') {
    image = currentUser.avatar
    alpha = 0
  }
  if (mode === 'editing') {
    image = photoPreviewUrl
    alpha = 0.6
  }

  const propertyId = getParameter('propertyId')
  const applicationId = getParameter('applicationId')
  const isFromApplicationForm =
    isFromApplication(backUrl) || propertyId || applicationId

  return (
    <Display.CenterContentContainer componentClass="width100">
      <ProgressBarComponent completeness={completeness} />
      <RentalReputationHeaderReminder
        property={property}
        backUrl={backUrl}
        remindText={'Current application'}
        isFromApplicationForm={isFromApplicationForm}
        title="Profile Picture"
      />

      <Header
        title="Profile Picture"
        message="Add a photo of yourself to personalise your profile and help you shine on Snug. Your profile picture is also included on rental applications."
      />

      <Checkbox
        label="I don't want to use a profile picture"
        value={optOutProfileAvatar}
        onChange={onChangeOptOutProfileAvatar}
        id="optOutProfileAvatar"
      />

      <ColumnCentered>
        {mode === '' && optOutProfileAvatar === false && (
          <InitialsAvatar
            firstName={currentUser.firstName}
            lastName={currentUser.lastName}
          />
        )}

        {mode === 'showExisting' && (
          <PictureAvatar src={currentUser.avatar} borderRadius={100} />
        )}

        {mode === 'editing' && optOutProfileAvatar === false && (
          <AvatarEditor
            ref={setEditorRef}
            image={image}
            width={200}
            height={200}
            border={50}
            color={[255, 255, 255, alpha]} // RGBA
            scale={1.5}
            rotate={rotate}
            borderRadius={100}
          />
        )}

        {mode === 'editing' && optOutProfileAvatar === false && (
          <AvatarToolsRow>
            <RotateButton onClick={rotateLeft} direction="left" />
            <RotateButton onClick={rotateRight} direction="right" />
          </AvatarToolsRow>
        )}

        {optOutProfileAvatar === false && (
          <SaveButtonWidget
            submit={submitPhoto}
            disabled={!photoPreviewUrl}
            isSubmitting={isSubmitting}
            saveButtonClass="profile-avatar-added"
          />
        )}
      </ColumnCentered>

      {optOutProfileAvatar === false && (
        <DragDropUploadImageWidget updatePhotos={updatePhotos} />
      )}
      <ErrorMessage error={photosValidationError} />

      <SnugTip tipContent="Adding a profile picture helps people remember you and builds a more trusting community." />

      <Display.BottomContentContainer>
        <Display.CenterContentContainer componentClass="width100">
          <div className="submit-button-container">
            <BottomButton
              nextUrl={`${urlTo('PetDetails')}${window.location.search}`}
              btnText={
                isFromApplicationForm ? 'Save & Continue' : 'Next: Pet Details'
              }
              {...(!isFromApplicationForm
                ? {
                    customisedBackClick: () =>
                      history.push(urlTo(urlIds.profileAboutUs)),
                  }
                : {})}
              bottomButtonsClass="pt0 mt0 summary-bottom-button"
              nextBtnClass={`summary-green-button-class`}
              backButtonClass="summary-grey-button-class"
              nextButtonPositionClass="pr20 pl0"
              showArrow={false}
            />
          </div>
        </Display.CenterContentContainer>
      </Display.BottomContentContainer>
      <ErrorMessage error={apiError} />
    </Display.CenterContentContainer>
  )
}

export default Avatar
