import React from 'react'

import styled from 'styled-components'

const StackedInfoContainer = styled.div`
  .first-line {
    font-weight: bold;
    font-size: ${(props) => `${props.theme.fontSizes[5]}px`};
    line-height: 140%;
  }
  .second-line {
    font-size: 12px;
    line-height: 130%;
    margin-top: ${(props) => `${props.theme.space[2]}px`};
  }
  .third-line {
    font-weight: bold;
    line-height: ${(props) => `${props.theme.lineHeights.title}`};
  }
  .is-link {
    color: #3774c3;
    font-weight: bold;
  }
  .is-email {
    font-size: 12px;
    color: #3774c3;
  }
`

const StackedInfo = ({ firstLine, secondLine, thirdLine }) => {
  return (
    <StackedInfoContainer>
      <div className="first-line">{firstLine}</div>
      <div className="second-line">{secondLine}</div>
      <div className="third-line">{thirdLine}</div>
    </StackedInfoContainer>
  )
}

export default StackedInfo
