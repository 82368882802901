import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { Route, Switch, useHistory } from 'react-router-dom'

import { LoadingSection } from 'app/components/design-system-components/index'
import ProspectsSummary from 'app/dashboard/prospects_summary_wrapper'
import TeamActivityContainer from 'app/dashboard/team_activity/team_activity_container'
import { PropertyDisclosurePage } from 'app/pages/PropertyDisclosure'
import { KeyLoggerListPage } from 'app/pages/teams/key-logger/keyLoggerListPage'
import { KeysImportPage } from 'app/pages/teams/key-logger/KeysImportPage'
import NewMessages from 'app/pages/teams/messages/connection'
import { EnquirersImportPage } from 'app/pages/teams/messages/ImportEnquirers'
import PropertyViewingsPageMobile from 'app/pages/teams/viewings-mobile/connection'
import { changeTeam, fetchTeams } from 'app/session/session_actions'
import { ProtectedRoute } from 'app/shared_components/route_util'
import { urlIds, urlTo } from 'app/sm/helpers'
import TeamingViewingPreference from 'app/sm/team_viewing_preference/connection'
import UserNotAuthorized from 'app/sm/team_viewing_preference/userNotAuthorized'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
} from 'app/utils/loading-states'
import { isUserAnAdmin } from 'app/utils/roles'
import { isTeamMemberNotALeasingAgent } from 'app/utils/system/helpers'
import {
  isAdvancedReportingEnabled,
  isBackgroundChecksReportingEnabled,
  isKeyLoggerEnabled,
  isSmsReportingEnabled,
} from 'config/features'

const PureKeysPages = ({ match, currentTeam }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={KeyLoggerListPage} />
      <Route exact path={match.path + '/import'} component={KeysImportPage} />
    </Switch>
  )
}

const KeysPages = connect(({ session }) => ({
  currentTeam: session.currentTeam,
}))(PureKeysPages)

const ReportingTabs = ({ match, currentTeam }) => {
  return (
    <Route
      path={match.path}
      render={(props) => (
        <TeamActivityContainer
          {...props}
          advancedEnabled={isAdvancedReportingEnabled(currentTeam)}
          bgCheckEnabled={isBackgroundChecksReportingEnabled(currentTeam)}
          smsEnabled={isSmsReportingEnabled(currentTeam)}
          vacancyEnabled
          automationsEnabled
        />
      )}
    />
  )
}

const ActivityPage = connect(({ session }) => ({
  currentTeam: session.currentTeam,
}))(ReportingTabs)

export const TeamsPages = ({
  match,
  fetchTeams,
  changeTeam,
  currentTeam,
  agencyUser,
}) => {
  const history = useHistory()

  const [teamsLoadingStates, setTeamsLoadingStates] = useState(
    createInitialLoadingState(),
  )

  const teamsLoadingStatesUtils = createLoadingStateUtils(setTeamsLoadingStates)

  useEffect(() => {
    loadTeams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadTeams = () => {
    teamsLoadingStatesUtils.startLoading()
    fetchTeams()
      .then((teams) => {
        const { teamSlug } = match.params
        const currentTeam = teams.find(({ slug }) => slug === teamSlug)
        if (!currentTeam) history.push(urlTo(urlIds.notFound))
        changeTeam(currentTeam).then(() =>
          teamsLoadingStatesUtils.markDoneSuccessfully(),
        )
      })
      .catch(teamsLoadingStatesUtils.setError)
  }

  if (!currentTeam || !agencyUser) return null

  const isTeamMember = isTeamMemberNotALeasingAgent(agencyUser)
  const isLeasingAgent = !isTeamMember
  const isTeamAdmin = isUserAnAdmin(agencyUser)

  return (
    <LoadingSection loadingState={teamsLoadingStates} hideChildrenInitially>
      <Switch>
        {isKeyLoggerEnabled(currentTeam) && (
          <Route path={match.path + '/keys'} component={KeysPages} />
        )}

        <ProtectedRoute
          path={match.path + '/messages'}
          exact
          component={NewMessages}
        />

        <ProtectedRoute exact path={match.path} component={ProspectsSummary} />

        <ProtectedRoute
          exact
          path={match.path + '/viewings/mobile'}
          component={PropertyViewingsPageMobile}
        />

        {isLeasingAgent && (
          <>
            <ProtectedRoute
              exact
              path={match.path + '/settings'}
              component={UserNotAuthorized}
            />
            <ProtectedRoute
              exact
              path={match.path + '/settings/calendarsync'}
              component={UserNotAuthorized}
            />

            <ProtectedRoute
              exact
              path={match.path + '/settings/following'}
              component={UserNotAuthorized}
            />

            <ProtectedRoute
              exact
              path={match.path + '/contacts'}
              component={UserNotAuthorized}
            />
            <ProtectedRoute
              exact
              path={match.path + '/rental-references'}
              component={UserNotAuthorized}
            />
            <ProtectedRoute
              exact
              path={match.path + '/activity'}
              component={UserNotAuthorized}
            />
          </>
        )}
        {isTeamMember && (
          <>
            <ProtectedRoute
              exact
              path={match.path + '/settings'}
              component={TeamingViewingPreference}
            />
            <ProtectedRoute
              exact
              path={match.path + '/settings/calendarsync'}
              component={TeamingViewingPreference}
            />

            <ProtectedRoute
              exact
              path={match.path + '/settings/following'}
              component={TeamingViewingPreference}
            />

            <ProtectedRoute
              exact
              path={match.path + '/settings/propertyme'}
              component={TeamingViewingPreference}
            />
            <ProtectedRoute
              exact
              path={match.path + '/settings/setup'}
              component={TeamingViewingPreference}
            />
            <ProtectedRoute
              exact
              path={match.path + '/settings/members'}
              component={TeamingViewingPreference}
            />
            <ProtectedRoute
              exact
              path={match.path + '/settings/portals'}
              component={TeamingViewingPreference}
            />
            <ProtectedRoute
              exact
              path={match.path + '/settings/notifications'}
              component={TeamingViewingPreference}
            />
            <ProtectedRoute
              exact
              path={match.path + '/settings/applications'}
              component={TeamingViewingPreference}
            />
            <ProtectedRoute
              exact
              path={match.path + '/settings/screening'}
              component={TeamingViewingPreference}
            />
            <ProtectedRoute
              path={match.path + '/activity'}
              component={ActivityPage}
            />

            <ProtectedRoute
              exact
              path={match.path + '/property-disclosures/offer/:offerId'}
              component={PropertyDisclosurePage}
            />
            {isTeamAdmin && (
              <ProtectedRoute
                exact
                path={match.path + '/messages/import'}
                component={EnquirersImportPage}
              />
            )}
          </>
        )}
      </Switch>
    </LoadingSection>
  )
}

const mapStateToProps = ({ session }) => {
  return {
    currentTeam: session.currentTeam,
    agencyUser: session.currentAgency,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTeams: () => dispatch(fetchTeams()),
  changeTeam: (team) => dispatch(changeTeam(team)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamsPages)
