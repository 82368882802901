import React from 'react'

import styled from 'styled-components'

const AsideWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  @media (min-width: 992px) {
    max-width: 368px;
    flex-shrink: 0;
  }
`

function Aside(props) {
  return <AsideWrapper>{props.children}</AsideWrapper>
}

export default Aside
