import { NetworkConnectionError } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  manualPostMatchRentalReference,
  postMatchRentalReference,
} from 'app/match/rental_reference/rating_form/access'

export const manualSubmitRentalReference = (propertyId, payload) => {
  return () => {
    return manualPostMatchRentalReference(propertyId, payload).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((t) => {
            return Promise.reject(translateErrorCodeToMessage(t))
          })
        }
      },
    )
  }
}

export const submitRentalReference = (propertyId, payload, secretId) => {
  return () => {
    return postMatchRentalReference(propertyId, payload, secretId).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        }
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      },
    )
  }
}
