import { api } from 'app/utils/api/helpers'

export const addStudentDetailsAttachments = (attachmentsType, formData) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  return api.post(
    `sm/studentdetails/attachments/${attachmentsType}`,
    formData,
    headers,
  )
}

export const getStudentDetailsAttachments = (studentDetails) => {
  return api.get(`sm/studentdetails/${studentDetails}/attachments`)
}

export const deleteStudentDetailsAttachment = (attachmentGUID) => {
  return api.delete(`sm/studentdetails/attachments/${attachmentGUID}`)
}
