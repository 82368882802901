import React, { useEffect, useState } from 'react'

import {
  Box,
  ButtonWithLoading,
  Flex,
  Modal,
} from 'app/components/design-system-components'
import * as Form from 'app/components/forms/forms'
import {
  addTeamBuilding,
  assignPropertyBuilding,
  listTeamBuildings,
  removeTeamBuilding,
} from 'app/services/http/buildings'
import * as snugNotifier from 'app/services/snugNotifier'
import BuildingRow from 'app/sm/onlist_details/AddPropertyToBuilding/components/building_row'
import ConfirmDeleteBuildingModal from 'app/sm/onlist_details/AddPropertyToBuilding/components/confirm_delete_modal'

function AddPropertyToBuildingModal({
  toggleModal,
  propertyName = '',
  guidDetails = {},
  fetchBuildingInfoForProperty,
  teamSlug,
}) {
  const { agencyGUID = '', property = '' } = guidDetails || {}
  const [buildingName, setBuildingName] = useState('')
  const [allTeamBuildings, setAllTeamBuildings] = useState([])
  const [showDeleteBuildingConfirmModal, setShowDeleteBuildingConfirmModal] =
    useState(false)
  const [confirmDeleteBuildingGUID, setConfirmDeleteBuildingGUID] = useState('')

  const showAllBuildingsForTeam = () => {
    listTeamBuildings(agencyGUID)
      .then((data) => {
        setAllTeamBuildings(data)
      })
      .catch((error) => {
        snugNotifier.error(error.message)
      })
  }

  useEffect(() => {
    showAllBuildingsForTeam()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addBuildingForTeam = () => {
    addTeamBuilding(agencyGUID, buildingName)
      .then(() => {
        showAllBuildingsForTeam()
        snugNotifier.success('Building successfully added.')
        setBuildingName('')
      })
      .catch((error) => {
        snugNotifier.error(error.message)
      })
  }

  const onClickAssignBuilding = (buildingGUID) => {
    assignPropertyBuilding(agencyGUID, property, buildingGUID)
      .then(() => {
        fetchBuildingInfoForProperty()
        snugNotifier.success('Building successfully assigned.')
      })
      .catch((error) => {
        snugNotifier.error(error.message)
      })
      .finally(() => {
        toggleModal()
      })
  }

  const onClickRemoveBuildingForTeam = (buildingGUID) => {
    setShowDeleteBuildingConfirmModal(true)
    setConfirmDeleteBuildingGUID(buildingGUID)
  }

  const confirmDeleteBuilding = () => {
    removeTeamBuilding(agencyGUID, confirmDeleteBuildingGUID)
      .then(() => {
        showAllBuildingsForTeam()
        snugNotifier.success('Building successfully deleted.')
        closeConfirmDeleteBuildingModal()
        fetchBuildingInfoForProperty()
      })
      .catch((error) => {
        snugNotifier.error(error.message)
      })
  }

  const closeConfirmDeleteBuildingModal = () => {
    setConfirmDeleteBuildingGUID('')
    setShowDeleteBuildingConfirmModal(false)
  }

  const buildingsAvailable =
    allTeamBuildings &&
    allTeamBuildings.buildings &&
    allTeamBuildings.buildings.length > 0

  const modalBodyBuildingScreen = (
    <Box width="100%" mt="32px">
      <h5>Add New Building</h5>
      <Box width="100%">
        <Flex alignItems="center" mt="8px">
          <Box mr="16px">
            <Form.InputTextOptional
              value={buildingName}
              onChange={(val) => setBuildingName(val.value)}
              label="Building name"
            />
          </Box>
          <Box mt="3px">
            <ButtonWithLoading
              variant="brightGreenOutline"
              onClick={addBuildingForTeam}
            >
              Add
            </ButtonWithLoading>
          </Box>
        </Flex>
      </Box>
    </Box>
  )

  const modalBodyPropertyScreen = (
    <>
      <Box width="100%">
        <Box mt="16px">
          <h5>Property</h5>
          <Box my="16px">{propertyName}</Box>
        </Box>
        {modalBodyBuildingScreen}
        <Box mt="32px">
          <h5>Available buildings</h5>
          <Box mt="16px">
            {buildingsAvailable &&
              allTeamBuildings.buildings.map((building) => {
                return (
                  <BuildingRow
                    building={building}
                    propertyGUID={property}
                    onClickAssignBuilding={onClickAssignBuilding}
                    onClickRemoveBuildingForTeam={onClickRemoveBuildingForTeam}
                    teamSlug={teamSlug}
                    closeConfirmDeleteBuildingModal={
                      closeConfirmDeleteBuildingModal
                    }
                  />
                )
              })}
            {!buildingsAvailable && <Box>Please add a building</Box>}
          </Box>
        </Box>
      </Box>
    </>
  )

  return (
    <>
      <Modal
        toggleModal={toggleModal}
        modalHeading="Assign to a building"
        modalBody={modalBodyPropertyScreen}
        customModalFooter={<></>}
      />
      {showDeleteBuildingConfirmModal && (
        <ConfirmDeleteBuildingModal
          confirmDeleteBuilding={confirmDeleteBuilding}
          closeConfirmDeleteBuildingModal={closeConfirmDeleteBuildingModal}
        />
      )}
    </>
  )
}

export default AddPropertyToBuildingModal
