import * as Sentry from '@sentry/react'
import { productionEnv } from 'config/env'

export const boot = (versionNumber) => {
  if (productionEnv()) {
    Sentry.init({
      release: versionNumber,
      dsn: 'https://b7166f7bd0b043f9ad656cc5306761ad@o141936.ingest.sentry.io/1002020',
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 0.001,
      normalizeDepth: 6,
      ignoreErrors: [
        //
        // Recommended errors to ignore https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/
        //
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        //
        // Snug errors to ignore
        //
        'ResizeObserver loop limit exceeded',
        'serviceWorkerRegistration.pushManager.getSubscription', // Ortto library exception
        'Error: Could not load "util".',
      ],
      denyUrls: [
        //
        // Recommended denyUrls https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/
        //
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        //
        // Snug denyUrls
        //
        /googletagmanager/i,
        /cdn2l\.ink\/app\.js/i,
        /capture-api\.ap3prod\.com\//i,
      ],
      beforeBreadcrumb(breadcrumb, hint) {
        // to not populate sentry errors with huge breadcrumb data comes from warning/error messages in console
        // https://github.com/getsentry/sentry-javascript/issues/2798#issuecomment-669164550
        if (breadcrumb.category === 'console') {
          return null
        }
        return breadcrumb
      },
      beforeSend: (event, hint) => {
        if (event && event.request) {
          let request = event.request
          if (request.url.match(/gtag/i)) {
            return null
          }
        }
        if (event?.location) {
          if (event.location === '/j/roundtrip.js') {
            // Adroll exception
            return null
          }
        }
        if (event?.culprit) {
          if (event.culprit.match(/\?\(maps\/api\/js\)/i)) {
            // Google maps exception
            return null
          }
        }
        if (hint) {
          const error = hint.originalException
          if (error && error === 'Timeout') return null // https://github.com/getsentry/sentry-javascript/issues/2514
          if (error && error.match) {
            if (error.match(/please login to perform this action/i)) {
              return null
            }
            if (
              error.message &&
              error.message.match(/please login to perform this action/i)
            ) {
              return null
            }
            if (
              error.match(/Error code: please login to perform this action/i)
            ) {
              return null
            }
            if (error && error.startsWith('User with email')) {
              return null
            }
            if (error.match(/^User with email .*? has not been verified$/i)) {
              return null
            }
            if (
              error.match(
                /^Error code: User with email .*? has not been verified$/i,
              )
            ) {
              return null
            }
            if (
              error.message &&
              error.message.match(
                /^User with email .*? has not been verified$/i,
              )
            ) {
              return null
            }
            if (
              error.message &&
              error.message.match(
                /^Error code: User with email .*? has not been verified$/i,
              )
            ) {
              return null
            }

            if (error.match(/User with email .*? has not been verified$/i)) {
              return null
            }
            if (
              error.message &&
              error.message.match(/User with email .*? has not been verified$/i)
            ) {
              return null
            }
            if (
              error.message &&
              error.message.match(
                /.*?Error code: User with email .*? has not been verified$/,
              )
            ) {
              return null
            }
            //Google maps api issue [sc-88076]
            if (error.match(/Could not load "util"/i)) {
              return null
            }
            if (
              error.message &&
              error.message.match(/Could not load "util"/i)
            ) {
              return null
            }
            if (error.match(/Error code: Could not load "util"/i)) {
              return null
            }
            //analytics.js issue [sc-88076]
            if (
              error.match(/null is not an object (evaluating 'g.readyState')/i)
            ) {
              return null
            }
            if (
              error.message &&
              error.message.match(
                /null is not an object (evaluating 'g.readyState')/i,
              )
            ) {
              return null
            }
            if (
              error.match(
                /TypeError: null is not an object (evaluating 'g.readyState')/i,
              )
            ) {
              return null
            }
          }
          return event
        }
      },
    })
  }
}

export const log = (message, context) => {
  if (productionEnv()) {
    Sentry.captureMessage('log: ' + message, context)
  } else {
    console.log(message, context)
  }
}

export const warn = (message, context) => {
  if (productionEnv()) {
    Sentry.captureMessage('warning: ' + message, context)
  } else {
    console.warn(message, context)
  }
}

export const error = (message, context) => {
  if (productionEnv()) {
    Sentry.captureException('error: ' + message, context)
  } else {
    console.error(message, context)
  }
}
