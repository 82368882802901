import React from 'react'

import PropTypes from 'prop-types'

import { Modal } from 'app/components/design-system-components'
import Box from 'app/components/design-system-components/Box'
import { ErrorCrmIDDuplicate } from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import * as snugNotifier from 'app/services/snugNotifier'
import AddressForm from 'app/shared_components/address_form/address_form'
import isEmptyObject from 'app/shared_components/check_empty_object'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import * as validations from 'app/shared_components/validations'
import { franchiseGroups, urlTo, validateAddress } from 'app/sm/helpers'

const defaultAddress = {
  unitNumber: '',
  streetNumber: '',
  streetName: '',
  address2: '',
  suburb: '',
  state: '',
  postcode: '',
  country: 'Australia',
  lat: null,
  lng: null,
  friendlyName: '',
  googleId: '',
}

const INVALID_CRM_ID_ERROR = 'CRM ID required and should be a number'

class CreateAgencyContainer extends React.Component {
  constructor(props) {
    super(props)

    const {
      agencyName = '',
      crmID = '',
      agencyAddress = defaultAddress,
      franchiseGroup = '',
    } = this.props.initialState || {}

    this.state = {
      name: agencyName,
      crmID: crmID,
      address: agencyAddress,
      franchiseGroup: franchiseGroup,
      errors: {},
      clicked: false,
      unsavedChanges: false,
      apiError: null,
      duplicateCrmIDModal: {
        isShown: false,
        dupAgencyName: '',
        dupAgencyGUID: '',
      },
    }
  }

  UNSAFE_componentWillMount() {
    let { clearError } = this.props
    clearError()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ clicked: false })
  }

  componentDidUpdate(prevProps) {
    const { initialState } = this.props
    if (prevProps.initialState !== initialState && initialState) {
      const { agencyName, crmID, agencyAddress, franchiseGroup } = initialState
      this.setState({
        name: agencyName,
        crmID: crmID,
        address: agencyAddress,
        franchiseGroup: franchiseGroup,
      })
    }
  }

  setValidationErrors(field, error) {
    let errors = this.state.errors
    if (error.length === 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  isAddressEmpty() {
    const { address } = this.state || {}

    const {
      unitNumber = '',
      streetNumber = '',
      streetName = '',
      suburb = '',
      state = '',
      country = '',
      postcode = '',
    } = address || {}

    return !(
      unitNumber ||
      streetNumber ||
      streetName ||
      suburb ||
      state ||
      country ||
      postcode
    )
  }

  isFormEmpty() {
    const { name, crmID, franchiseGroup } = this.state

    return !(name || crmID || franchiseGroup || !this.isAddressEmpty())
  }

  resetAddress = () => {
    return () => {
      this.setState({
        address: defaultAddress,
      })
    }
  }

  submit = () => {
    const { createAgency } = this.props
    const { name, address, crmID, franchiseGroup } = this.state
    if (!/^[0-9]+$/.exec(crmID)) {
      snugNotifier.error(INVALID_CRM_ID_ERROR)
      return
    }
    this.setState(
      {
        unsavedChanges: false,
      },
      () => {
        createAgency({
          name: name.trim(),
          address,
          crmID: crmID.replace(/\s+/g, ''),
          franchiseGroup: franchiseGroup.trim(),
        })
          .catch(({ detailedError, appCode, message }) => {
            if (appCode === ErrorCrmIDDuplicate) {
              const { name, guidID } = detailedError || {}
              this.setState({
                duplicateCrmIDModal: {
                  isShown: true,
                  dupAgencyName: name,
                  dupAgencyGUID: guidID,
                },
              })
              this.setValidationErrors('crmID', [
                'CRM ID is already used for another agency',
              ])
            } else {
              this.setState({ apiError: message })
            }
          })
          .finally(() => this.setState({ clicked: false }))
      },
    )

    this.setState({ clicked: true })
  }

  update = (field) => {
    return (event) => {
      let { value } = event.target
      this.setState({
        [field]: value,
        unsavedChanges: true,
      })
    }
  }

  updateAddress = (field) => {
    return (event) => {
      const { address } = this.state
      address[field] = event.target.value
      this.setState({
        address,
        unsavedChanges: true,
      })
    }
  }

  updateGoogleAddress = (address) => {
    this.setState(
      {
        address,
        unsavedChanges: true,
      },
      () => this.validate('address', true),
    )
  }

  validate = (field) => {
    return () => {
      const value = this.state[field]
      const { address } = this.state
      switch (field) {
        case 'address':
          const addressValidation = validateAddress(address)
          return this.setValidationErrors(
            field,
            !addressValidation.ok
              ? [ErrorMessages[addressValidation.errorCode]]
              : [],
          )
        case 'name':
          return this.setValidationErrors(
            field,
            validations.validateCompanyName(value.trim(), 'Invalid Name'),
          )
        case 'franchiseGroup':
          return this.setValidationErrors(
            field,
            validations.validateFranchiseGroupOptional(
              value.trim(),
              'Invalid Franchise Group',
            ),
          )
        default:
          return false
      }
    }
  }

  toggleCrmDupModal = () => {
    const {
      duplicateCrmIDModal: { isShown },
    } = this.state
    this.setState({ duplicateCrmIDModal: { isShown: !isShown } })
  }

  renderCrmDuplicateConfirmModal = () => {
    const {
      crmID,
      duplicateCrmIDModal: { isShown, dupAgencyName, dupAgencyGUID },
    } = this.state
    if (!isShown) return

    return (
      <Modal
        modalHeading="CRM ID already in use"
        toggleModal={this.toggleCrmDupModal}
        showPrimaryButton={false}
        modalBody={
          <Box>
            {crmID} is used for team{' '}
            <a href={urlTo('editAgency', { agencyGUID: dupAgencyGUID })}>
              {dupAgencyName}
            </a>
          </Box>
        }
        secondaryLabel="Cancel"
        secondaryAction={this.toggleCrmDupModal}
      />
    )
  }

  render() {
    const { name, address, crmID, franchiseGroup, errors, clicked, apiError } =
      this.state

    const disabled = !(name && isEmptyObject(errors) && !clicked)

    const unsavedChanges = !this.isFormEmpty() && this.state.unsavedChanges

    return (
      <div>
        <h4 className="mb25">
          {this.props.edit ? 'Edit Agency Info' : 'Create Agency'}
        </h4>
        <div className="two-col-box-flex-row sm64">
          <div className="col-first">
            <div className="input-box">
              <AddressForm
                address={address}
                updateAddress={this.updateAddress}
                updateGoogleAddress={this.updateGoogleAddress}
                resetAddress={this.resetAddress()}
                placeholder="Enter agency address"
                error={errors.address}
                disabled={disabled}
                onBlur={this.validate('address', true)}
                country={['au']}
                noBlurForGoogleAddress={true}
              />
            </div>

            <div
              className={
                errors.address && disabled ? 'alert alert-danger' : 'hide-alert'
              }
            >
              <div>{errors.address}</div>
            </div>

            <div className="input-box">
              <input
                type="text"
                required
                className={errors.name ? 'error' : ''}
                value={name}
                onChange={this.update('name')}
                onBlur={this.validate('name')}
              />
              <label>Name</label>
            </div>

            <div
              className={
                errors.name && disabled ? 'alert alert-danger' : 'hide-alert'
              }
            >
              <div>{errors.name}</div>
            </div>

            <div className="input-box">
              <input
                type="text"
                required
                className={errors.crmID ? 'error' : ''}
                value={crmID}
                onChange={this.update('crmID')}
                onBlur={this.validate('crmID')}
              />
              <label>CRM ID</label>
            </div>

            <div
              className={
                errors.crmID && disabled ? 'alert alert-danger' : 'hide-alert'
              }
            >
              <div>{errors.crmID}</div>
            </div>

            <div className="input-box">
              <select
                required
                className={errors.franchiseGroup ? 'error' : ''}
                value={franchiseGroup}
                onChange={this.update('franchiseGroup')}
                onBlur={this.validate('franchiseGroup')}
              >
                <option className="blank" />
                {franchiseGroups.sort().map((group) => (
                  <option key={group}>{group}</option>
                ))}
              </select>
              <label>Franchise Group (Optional)</label>
            </div>

            <div
              className={
                errors.franchiseGroup && disabled
                  ? 'alert alert-danger'
                  : 'hide-alert'
              }
            >
              <div>{errors.franchiseGroup}</div>
            </div>

            <div className={apiError ? 'alert alert-danger' : 'hide-alert'}>
              <div>{apiError}</div>
            </div>

            {this.renderCrmDuplicateConfirmModal()}

            <button
              className="mt15 mb30"
              onClick={this.submit}
              disabled={disabled}
            >
              {this.props.edit ? 'Update' : 'Create'}{' '}
              <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
            </button>

            <UnsavedMessage unsavedChanges={unsavedChanges} />
          </div>
        </div>
      </div>
    )
  }
}

CreateAgencyContainer.propTypes = {
  error: PropTypes.string,
  createAgency: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
}

CreateAgencyContainer.defaultProps = {
  error: null,
}

export default CreateAgencyContainer
