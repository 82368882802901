import React from 'react'

import DocumentList from 'app/shared_components/document_list/document_list'

import 'app/agency/branding_uploader/style.scss'

const BrandingUploader = ({
  header = '',
  logoType,
  brandingURL = '',
  agencyGUID = '',
  error = '',
  onUploadBranding,
  onRemoveBranding,
  brandingImgClass = '',
  brandingRequirement = '',
  validationError = '',
}) => {
  return (
    <div className="branding-uploader ">
      <div className="branding-header">
        <h5>{header}</h5>
        {!brandingURL && <p>{brandingRequirement}</p>}
      </div>
      {!brandingURL ? (
        <DocumentList
          addDocument={(documentType) =>
            onUploadBranding(logoType, agencyGUID, documentType)
          }
          documentType={logoType}
          responseText={error}
        />
      ) : (
        <div className="branding-display">
          <img
            className={brandingImgClass}
            src={brandingURL}
            alt={`Agency ${header}`}
          />
          <span
            className="blue-link-style form-input-text"
            onClick={() => onRemoveBranding(logoType, agencyGUID)}
            role="button"
            tabIndex="0"
          >
            Remove
          </span>
        </div>
      )}
      {validationError && (
        <div className="height30 ">
          <div className="standard-error-box">{validationError}</div>
        </div>
      )}
    </div>
  )
}

export default BrandingUploader
