import { Button, Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const ModalFooter = ({
  sendDisabled,
  showPreview,
  onPreviewClicked,
  ...props
}) => {
  return (
    <Flex alignItems="center" {...props}>
      <Button
        type="button"
        variant="solidSecondary"
        flex="1 1 30%"
        onClick={onPreviewClicked}
        mr={theme.space[3] + 'px'}
      >
        {!showPreview ? 'Preview' : 'Edit'}
      </Button>
      <Button disabled={sendDisabled} type="submit" flex="1 1 70%">
        Send
      </Button>
    </Flex>
  )
}
