import React, { useState } from 'react'

import { Form, Formik } from 'formik'
import styled from 'styled-components'

import {
  Box,
  Button,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'
import { InputField } from 'app/components/design-system-components/inputs'
import StatusPill from 'app/components/display/status_pill/component'
import { portalStatuses } from 'app/constants/portals.constants'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { formatStdTimeStamp } from 'app/utils/datetime/helpers'
import { loadingStatesIds } from 'app/utils/loading-states'

const ContainerBox = styled(Flex)`
  box-shadow: 0 3px 5px rgba(34, 33, 42, 0.08);
  border-radius: 2px;
`

const StyledFieldWrapper = styled(FieldWrapper)`
  label {
    input {
      width: 100%;
    }
  }
`
const StyledInputField = styled(InputField)`
  label {
    height: 40px;
    width: 120px;
  }
`

const PortalLogo = (logo) => {
  return (
    <Box width="150px">
      <img width="100%" src={logo} alt="logo" />
    </Box>
  )
}

const formFields = {
  externalId: 'external_id',
}

const fieldValidation = (fieldName, val) => {
  if (fieldName.includes(formFields.externalId)) {
    return {
      required: !val && 'This field is required',
    }
  }
}

const formValidation = formValidationBuilder(fieldValidation)

const maxPortalIDs = 6
const startingPortalIDIndex = 0

export const PortalStatusBlock = ({
  status,
  activationInstructions,
  onActivation,
  onDeactivation,
  deactivateLoadingStates,
  activatedAt,
  activatedBy,
  activatedAgencyIDs,
  logo,
}) => {
  const statusObj = Object.values(portalStatuses).find(
    ({ id }) => id === status,
  )
  const isActive = status === portalStatuses.active.id
  const formattedActivationTime = formatStdTimeStamp(activatedAt)
  const activatorName =
    activatedBy && `${activatedBy.firstName} ${activatedBy.lastName}`

  const [extraIDs, setExtraIDs] = useState(startingPortalIDIndex)

  const addPortalID = () => {
    setExtraIDs(extraIDs + 1)
  }

  const generateFieldReference = (idx = 0) => {
    return `${formFields.externalId}_${idx}`
  }

  const removePortalID = (idIdx, { values, setValues }) => {
    setExtraIDs(extraIDs - 1)
    delete values[idIdx]
    setValues(values)
  }

  const onSubmit = (values) => {
    const allIds = Object.values(values).filter((v) => v !== '')
    return onActivation({ external_ids: allIds })
  }

  const showAddAnotherLink =
    extraIDs >= startingPortalIDIndex && extraIDs < maxPortalIDs
  const showRemoveLink = extraIDs > startingPortalIDIndex

  const renderExtraIDs = (formProps) => {
    let extraFields = []
    for (let i = 1; i <= extraIDs; i++) {
      extraFields.push(
        <Flex mb={3}>
          <StyledFieldWrapper
            id={generateFieldReference(i)}
            name={generateFieldReference(i)}
          >
            <StyledInputField
              id={generateFieldReference(i)}
              name={generateFieldReference(i)}
              inputProps={{ placeholder: 'e.g. 12345' }}
              height="40px"
              maxWidth="120px"
            />
          </StyledFieldWrapper>
          {showRemoveLink && (
            <Button
              ml={3}
              width="fit-content"
              variant="linkBlueWithoutWeight"
              onClick={(event) => {
                event?.preventDefault()
                removePortalID(i, formProps)
              }}
            >
              remove
            </Button>
          )}
        </Flex>,
      )
    }
    return <Flex flexDirection="column">{extraFields}</Flex>
  }

  return (
    <ContainerBox>
      {statusObj && (
        <Flex p={5} flex="60%" borderRight="1px solid #DFDFDF">
          <Flex justifyContent="space-between" alignItems="start" width="100%">
            {isActive && (
              <>
                <Flex flexDirection="column">
                  <Flex flexDirection="row">
                    <TextSpan
                      as="span"
                      fontSize={theme.fontSizes.pLarge16}
                      fontWeight={theme.fontWeights[4]}
                      lineHeight={theme.lineHeights.encrption}
                      mr={3}
                    >
                      Status
                    </TextSpan>
                    <StatusPill
                      componentClass="mr10"
                      pillType={statusObj.color}
                      text={statusObj.label}
                      pillTextClass="fw500 fs16 pl15 pr15"
                    />
                  </Flex>
                  {activatedAt && (
                    <Box>
                      <Box mt={3}>
                        <TextSpan
                          as="span"
                          fontSize={theme.fontSizes.pRegular14}
                          color={theme.colors.pdfGray}
                        >
                          Activated: {formattedActivationTime}{' '}
                          {activatorName && `by ${activatorName}`}
                        </TextSpan>
                      </Box>
                      <Box mt={3}>
                        <TextSpan
                          as="span"
                          fontSize={theme.fontSizes.pRegular14}
                          color={theme.colors.pdfGray}
                        >
                          Agency ID(s): {activatedAgencyIDs}
                        </TextSpan>
                      </Box>
                    </Box>
                  )}
                </Flex>
                {onDeactivation && (
                  <Button
                    variant="linkBlackUnderlinedWithoutWeight"
                    sizeVariant="large"
                    onClick={onDeactivation}
                    disabled={
                      deactivateLoadingStates.state === loadingStatesIds.LOADING
                    }
                  >
                    Deactivate
                  </Button>
                )}
              </>
            )}
            {!isActive && (
              <Box>
                <Box fontSize={theme.fontSizes.pRegular14}>
                  {activationInstructions}
                </Box>
                {onActivation && (
                  <Formik
                    initialValues={{
                      [generateFieldReference(startingPortalIDIndex)]: '',
                    }}
                    onSubmit={onSubmit}
                    validate={formValidation}
                    validateOnBlur
                  >
                    {(props) => (
                      <Form formBag={props}>
                        <Flex flexDirection={'column'}>
                          <Flex alignItems="start" mt={3} mb={3}>
                            <StyledFieldWrapper
                              id={generateFieldReference(startingPortalIDIndex)}
                              name={generateFieldReference(
                                startingPortalIDIndex,
                              )}
                            >
                              <StyledInputField
                                id={generateFieldReference(
                                  startingPortalIDIndex,
                                )}
                                name={generateFieldReference(
                                  startingPortalIDIndex,
                                )}
                                inputProps={{ placeholder: 'e.g. 123456' }}
                                height="40px"
                                maxWidth="120px"
                              />
                            </StyledFieldWrapper>
                            <Button
                              height="40px"
                              type="submit"
                              disabled={!props.isValid || props.isSubmitting}
                              ml={3}
                            >
                              Activate
                            </Button>
                          </Flex>
                          {renderExtraIDs(props)}
                          {showAddAnotherLink && (
                            <Button
                              height="fit-content"
                              width="fit-content"
                              variant="linkBlueWithoutWeight"
                              onClick={(event) => {
                                event?.preventDefault()
                                addPortalID()
                              }}
                            >
                              Add another
                            </Button>
                          )}
                        </Flex>
                      </Form>
                    )}
                  </Formik>
                )}
              </Box>
            )}
          </Flex>
        </Flex>
      )}
      {logo && (
        <Flex flex="45%" justifyContent="center" alignItems="center">
          {PortalLogo(logo)}
        </Flex>
      )}
    </ContainerBox>
  )
}
