import * as API from 'app/sm/renter_background_check_report/access'

export const fetchBackgroundCheckReportDetails = (params) => (dispatch) => {
  return API.getBackgroundCheckDetails(params).then(
    ({ ok, details, responseText }) => {
      if (ok) {
        return Promise.resolve({ details })
      } else {
        return responseText.then((t) => {
          return Promise.reject(t)
        })
      }
    },
  )
}

export const fetchApplicationApplicantBackgroundCheckReportDetails =
  (applicationGUID, applicantGUID) => (dispatch) => {
    return API.getApplicationApplicantBackgroundCheckDetails(
      applicationGUID,
      applicantGUID,
    ).then(({ ok, details, responseText }) => {
      if (ok) {
        return Promise.resolve({ details })
      } else {
        return responseText.then((t) => {
          return Promise.reject(t)
        })
      }
    })
  }
