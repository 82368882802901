import React, { Component } from 'react'

import Lightbox from 'react-images'

class Gallery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
    }
  }

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }

  gotoImage = (index) => {
    this.setState({
      currentImage: index,
    })
  }

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }

  handleClickImage = () => {
    if (this.state.currentImage === (this.props.images || []).length - 1) return

    this.gotoNext()
  }

  openLightbox = (event) => {
    event.preventDefault()
    this.setState({
      currentImage: 0,
      lightboxIsOpen: true,
    })
  }

  renderButton() {
    const { images, startVirtualTour } = this.props
    if (!images) return
    const extraClass = startVirtualTour ? 'background-dark-grey' : ''
    return (
      <button
        className={`sm-gallery-button ${extraClass}`}
        onClick={(e) => this.openLightbox(e)}
      >
        <span>{images.length}</span>
        <i className="icon-image"></i>
      </button>
    )
  }

  render() {
    const theme = {
      // container
      container: {
        zIndex: '999999',
        height: '100vh',
        width: '100vw',
      },
      //img
      content: {
        maxWidth: '1200px',
      },
      // arrows
      arrow: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        fill: '#fff',
        opacity: 0.6,
        transition: 'opacity 200ms',

        ':hover': {
          backgroundColor: 'rgba(255, 255, 255, 0)',
          opacity: 1,
        },
        ':focus': {
          backgroundColor: 'rgba(255, 255, 255, 0)',
          opacity: 1,
        },
        ':active': {
          backgroundColor: 'rgba(255, 255, 255, 0)',
          opacity: 1,
        },
      },
      close: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        fill: '#fff',
        opacity: 0.6,
        transition: 'all 200ms',
        ':hover': {
          backgroundColor: 'rgba(255, 255, 255, 0)',
          opacity: 1,
        },
      },
    }

    return (
      <div className="gallery">
        {this.renderButton()}
        <Lightbox
          currentImage={this.state.currentImage}
          images={this.props.images}
          isOpen={this.state.lightboxIsOpen}
          onClickImage={this.handleClickImage}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
          theme={theme}
        />
      </div>
    )
  }
}

export default Gallery
