import * as React from 'react'

function SvgArrowForwardRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.209 13h11.17l-4.88 4.88c-.39.39-.39 1.03 0 1.42.39.39 1.02.39 1.41 0l6.59-6.59a.996.996 0 000-1.41l-6.58-6.6a.996.996 0 00-1.41 0 .996.996 0 000 1.41l4.87 4.89H5.209c-.55 0-1 .45-1 1s.45 1 1 1z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrowForwardRounded
