import React from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Box, Button, Flex } from 'app/components/design-system-components'
import UpgradeCTA from 'app/components/design-system-components/UpgradeCTA'
import StatusPill from 'app/components/display/status_pill/component'
import { pmsStatuses } from 'app/constants/pms.constants'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { AUSTRALIAN_HUMAN_READABLE_DATETIME } from 'app/utils/datetime/helpers'
import { loadingStatesIds } from 'app/utils/loading-states'

const StatusLabel = styled.label`
  line-height: 24px;
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
`

const StatusNote = styled.div`
  color: gray;
  font-size: ${({ theme }) => theme.fontSizes.pRegular14};
`

const ContainerBox = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 5px rgba(34, 33, 42, 0.08);
  border-radius: 2px;
`

const PMSLogo = (logo) => {
  return (
    <Box width="200px">
      <img width="100%" src={logo} alt="logo" />
    </Box>
  )
}

export const StatusBlock = ({
  statusInfo,
  activationInstructions,
  onActivation,
  onDeactivation,
  activateLoadingStates,
  deactivateLoadingStates,
  logo,
  isAdmin,
  integrationsBundleIsActive,
}) => {
  const {
    status,
    activationAllowed,
    activated_at: activationTime,
  } = statusInfo || {}
  const statusObj = Object.values(pmsStatuses).find(({ id }) => id === status)
  const isActive = status === pmsStatuses.active.id
  const formattedActivationTime = moment(activationTime).format(
    AUSTRALIAN_HUMAN_READABLE_DATETIME,
  )

  const activationBtnDisabled = !activationAllowed || !isAdmin
  const activationDisabledTooltipTxt = isAdmin
    ? 'Another PMS is already active'
    : 'A team admin can activate'

  let tooltipText

  switch (true) {
    case !integrationsBundleIsActive:
      tooltipText = ''
      break
    case !activationAllowed:
      tooltipText = 'Another PMS is already active'
      break
    case !isAdmin:
      tooltipText = 'A team admin can activate'
      break
  }

  return (
    <ContainerBox>
      {statusObj && (
        <Box
          height="100%"
          width="100%"
          py="16px"
          px="16px"
          flex="60%"
          borderRight="1px solid #DFDFDF"
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            {isActive && (
              <>
                <Flex flexDirection="column">
                  <Flex flexDirection="row">
                    <StatusLabel>Status</StatusLabel>
                    <StatusPill
                      componentClass="mr10"
                      pillType={statusObj.color}
                      text={statusObj.label}
                      pillTextClass="fw500 fs16 pl15 pr15"
                    />
                  </Flex>
                  {activationTime && (
                    <Box mt={theme.baseSpace / 2 + 'px'}>
                      <StatusNote>
                        Activated at: {formattedActivationTime}
                      </StatusNote>
                    </Box>
                  )}
                </Flex>
                {onDeactivation && (
                  <Box
                    {...(!isAdmin && {
                      'data-tooltip-content': '',
                      'data-tooltip-id': 'admin-only-deactivate-tooltip',
                    })}
                  >
                    <Button
                      variant="outlineWarning"
                      onClick={onDeactivation}
                      disabled={
                        !isAdmin ||
                        deactivateLoadingStates.state ===
                          loadingStatesIds.LOADING
                      }
                    >
                      Deactivate
                    </Button>
                    {!isAdmin && (
                      <ReactTooltip
                        id="admin-only-deactivate-tooltip"
                        data-type="info"
                      >
                        A team admin can deactivate
                      </ReactTooltip>
                    )}
                  </Box>
                )}
              </>
            )}
            {!isActive && (
              <>
                {activationInstructions && (
                  <StatusNote>{activationInstructions}</StatusNote>
                )}
                {onActivation && (
                  <Box
                    {...(activationBtnDisabled && {
                      'data-tooltip-content': '',
                      'data-tooltip-id': 'activate-not-allowed-tooltip',
                    })}
                  >
                    <Button
                      variant="outlineSuccess"
                      onClick={onActivation}
                      disabled={
                        activationBtnDisabled ||
                        activateLoadingStates.state === loadingStatesIds.LOADING
                      }
                    >
                      Activate
                    </Button>
                    {activationBtnDisabled && (
                      <ReactTooltip
                        id="activate-not-allowed-tooltip"
                        data-type="info"
                      >
                        {tooltipText}
                      </ReactTooltip>
                    )}
                  </Box>
                )}
              </>
            )}
          </Flex>
          {!integrationsBundleIsActive && !isActive && (
            <Box pt={5}>
              <UpgradeCTA
                alignRight={false}
                availableOn="Integrations Bundle"
              />
            </Box>
          )}
        </Box>
      )}
      {logo && (
        <Flex
          height="100%"
          flex="40%"
          justifyContent="center"
          alignItems="center"
          p="16px"
        >
          {PMSLogo(logo)}
        </Flex>
      )}
    </ContainerBox>
  )
}
