import React from 'react'

import $ from 'jquery'
import qs from 'qs'
import styled from 'styled-components'

import calendar from 'app/assets/icons/calendar.png'
import circleCross from 'app/assets/icons/circle-cross.png'
import { Box } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import * as Headers from 'app/components/display/text/standard_text/standard-headings/component'
import * as Text from 'app/components/display/text/text_component'
import Checkbox from 'app/components/forms/base_checkbox/component'
import * as Form from 'app/components/forms/forms'
import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import ReferenceResultComponent from 'app/match/rental_reference/display/renter_address_history_detailed/rental_reference_detailed/rental_reference_result/rental_reference_result_item'
import * as snugNotifier from 'app/services/snugNotifier'
import isEmptyObject from 'app/shared_components/check_empty_object'
import * as sharedHelpers from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import SnugLogo from 'app/shared_components/snug_logo'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import * as validations from 'app/shared_components/validations'
import { parseDataUtil } from 'app/sm/helpers'
import * as helpers from 'app/sm/helpers'
import AddOwnerModal from 'app/sm/inspections/components/add_owner_modal'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as leaseOfferHelpers from 'app/utils/leaseOffer'
import * as objectHelpers from 'app/utils/objects/helpers'
import { formatAndPrefix, MOBILE_PREFIX } from 'app/utils/phonenumber/helpers'
import { trimAndAddEllipsesPastSpecifiedLength } from 'app/utils/strings/helpers'
import SendEmailReportButton from 'app/utils/viewings/components/SendEmailReportButton'

import 'app/match/propertyReport/style.scss'

const weekEndingType = {
  thisWeek: 1,
  lastWeek: 2,
  total: 3,
}

export const OWNER_PILL_TEXT = 'Owner'
export const TEAM_MEMBER_PILL_TEXT = 'Team Member'
export const NO_RESULTS_FOUND_TEXT = 'No results found'
const IS_ENTRY_DETAILS_DISPLAY = false

const MARKET_FEEDBACK_CONF_MESSAGE =
  'You have not included Market Feedback. Once sent, this cannot be undone. Are you sure?'

export const AllSearchResultsContainer = styled.div`
  border: 1px solid black;
  border-top: none;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 45;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
`

export const SearchResultContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  strong {
    display: block;
    padding-bottom: 5px;
  }
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
  .name-pill-info {
    display: flex;
    align-items: baseline;
  }
  .phone-email-info {
    margin-top: 4px;
  }
  @media (max-width: 768px) {
    .phone-email-info {
      display: flex;
      flex-direction: column;
    }
  }
`

export const FilteredSearchResultsRow = ({ item }) => {
  const {
    firstName,
    lastName,
    avatar,
    mobilePhone = '',
    phone = '',
    homePhone = '',
    workPhone = '',
    email = '',
    pillText = '',
  } = item
  const user = { firstName, lastName }
  const contactNumber = mobilePhone || phone || homePhone || workPhone
  const textForTeamContactNumber = formatAndPrefix(contactNumber, MOBILE_PREFIX)

  return (
    <SearchResultContainer>
      <Display.Avatar
        avatar={avatar}
        avatarText={helpers.firstAndLastNameInitials(user, 'both')}
        size={'xssmall'}
      />
      <div>
        <div className="name-pill-info">
          <strong>{`${firstName} ${lastName}`}</strong>
          <Display.StatusPill
            componentClass="ml10"
            pillType="hollow"
            text={pillText}
            pillTextClass="fs12"
          />
        </div>
        <div className="phone-email-info">
          <span className="mr10">{textForTeamContactNumber}</span>
          <span>{email}</span>
        </div>
      </div>
    </SearchResultContainer>
  )
}

export const mapTeamMembersOptions = (assignedTeamMembers) =>
  assignedTeamMembers.map((member) => {
    let option = `${member.firstName} ${member.lastName}`
    if (member.isListingAgent) {
      option += ' (Listing Agent)'
    }
    if (member.isInspector) {
      option += ' (Inspector)'
    }
    return option
  })

class PropertyReportContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      apiError: '',
      managerInfo: {},
      property: {},
      propertyMarketFeedback: {},
      viewingsHistory: [],
      emailList: [],
      emailValue: '',
      chosenManager: '',
      viewingId: '',
      comments: {
        viewingNote: '',
        message: '',
      },
      errors: {
        viewingNote: '',
        message: '',
        chosenManager: '',
      },
      includeMessage: false,
      unsavedChanges: false,
      sendSMS: false,
      linkCopied: false,
      spinner: false,
      prospectNotes: [],
      managerList: [],
      combinedOwnerAndManagersList: [],
      showEmailResults: false,
      filteredSearchResults: [],
      isOwnerModalActive: false,
      isMarketFeedbackActive: true,
      displayExcludedMarketFeedback: false,
      setNotCheckedNoShow: false,
      propertyreportId: '',
      hideActivity: false,
      hideLostRent: false,
      settingsDisableActivity: false,
      settingsDisableLostRent: false,
    }
    this.toggleModal = this.toggleModal.bind(this)
  }

  componentDidMount() {
    const { isFromMobileViewings = false, propertyReportGUID = '' } = this.props

    const navbarMenu = document.querySelector('.navbar-menu')
    navbarMenu.classList.add('pdf-hide')
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    if (curQueries.viewingId) {
      this.setState({
        viewingId: curQueries.viewingId,
      })
    }

    let {
      match: {
        params: { propertyreportId },
      },
      fetchPropertyReport,
      viewingGUID,
    } = this.props
    if (isFromMobileViewings) {
      propertyreportId = propertyReportGUID
      const curQueries = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      })
      if (curQueries?.propertyreportId) {
        propertyreportId = curQueries.propertyreportId
      }
    }
    if (viewingGUID) {
      this.setState({
        viewingId: viewingGUID,
      })
    }
    this.setState({
      isViewingReportShownToOwner: !!propertyreportId,
      propertyreportId,
    })
    if (propertyreportId) {
      this.populatePublicPropertyReportInfo(propertyreportId)
    } else {
      fetchPropertyReport(curQueries.viewingId || viewingGUID)
        .then((result) => {
          if (result.owner && result.owner.length) {
            let ownersInEmailList = []
            let allOwnersWithPillText = []

            result.owner.forEach((owner) => {
              let ownerWithPillText = { ...owner }
              const ownerEmailListFormat = {
                text: `${owner.email} (${OWNER_PILL_TEXT})`,
                status: 'valid',
              }
              ownerWithPillText.pillText = OWNER_PILL_TEXT
              ownersInEmailList.push(ownerEmailListFormat)
              allOwnersWithPillText.push(ownerWithPillText)
            })

            this.setState(
              {
                ...result,
                combinedOwnerAndManagersList: [
                  ...this.state.combinedOwnerAndManagersList,
                  ...[...allOwnersWithPillText],
                ],
                emailList: ownersInEmailList,
              },
              () => {
                const { agencyID } = this.state || {}
                this.fetchTeamSettings(agencyID)
                this.fetchManagers()
              },
            )
          } else {
            this.setState(
              {
                ...result,
              },
              () => {
                const { agencyID } = this.state || {}
                this.fetchTeamSettings(agencyID)
                this.fetchManagers()
              },
            )
          }
        })
        .catch((error) => {
          snugNotifier.error(error)
        })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isOwnerModalActive !== this.state.isOwnerModalActive) {
      const { viewingGUID } = this.props
      const curQueries = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      })
      if (curQueries.viewingId) {
        this.setState({
          viewingId: curQueries.viewingId,
        })
      }
      if (viewingGUID) {
        this.setState({
          viewingId: viewingGUID,
        })
      }

      const { fetchPropertyReport } = this.props
      fetchPropertyReport(curQueries.viewingId || viewingGUID)
        .then((result) => {
          if (result.owner && result.owner.length) {
            let ownersInEmailList = []
            let allOwnersWithPillText = []

            result.owner.forEach((owner) => {
              let ownerWithPillText = { ...owner }
              const ownerEmailListFormat = {
                text: `${owner.email}`,
                status: 'valid',
              }
              ownerWithPillText.pillText = OWNER_PILL_TEXT
              ownersInEmailList.push(ownerEmailListFormat)
              allOwnersWithPillText.push(ownerWithPillText)
            })

            this.setState(
              {
                ...result,
                combinedOwnerAndManagersList: [
                  ...this.state.combinedOwnerAndManagersList,
                  ...[...allOwnersWithPillText],
                ],
                emailList: ownersInEmailList,
              },
              () => {
                this.fetchManagers()
              },
            )
          } else {
            this.setState(
              {
                ...result,
              },
              () => {
                this.fetchManagers()
              },
            )
          }
        })
        .catch((error) => {
          snugNotifier.error(error)
        })
    }
  }

  componentWillUnmount() {
    $('.app-content-container').removeClass('background-grey-mobile-excluded')
  }

  populatePublicPropertyReportInfo = (propertyreportId) => {
    const { fetchPublicPropertyReport } = this.props
    fetchPublicPropertyReport(propertyreportId)
      .then((result) => {
        this.setState(
          {
            ...result,
            isMarketFeedbackActive: result.includeMarketFeedback,
            isViewingReportShownToOwner: true,
            propertyreportId,
          },
          () => this.fetchManagers(),
        )
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  onSendReportClicked = () => {
    const { isFromMobileViewings = false } = this.props
    const {
      emailList,
      includeMessage,
      isMarketFeedbackActive,
      sendSMS,
      comments,
      viewingId,
      managerList = [],
      managerInfo = {},
      displayExcludedMarketFeedback,
      setNotCheckedNoShow,
      hideActivity,
      hideLostRent,
    } = this.state

    const chosenManagerProfileInfo = this.getChosenManagerProfileInfo()

    const { viewingNote, message } = comments
    const { sendViewingReport } = this.props
    if (displayExcludedMarketFeedback) {
      if (!isMarketFeedbackActive) {
        if (!window.confirm(MARKET_FEEDBACK_CONF_MESSAGE)) {
          return
        }
      }
    }
    if (emailList.length === 0) {
      snugNotifier.error('Please specify at least one recipient.')
      return
    } else if (emailList.find((email) => email.status === 'invalid')) {
      snugNotifier.error('Invalid email address')
      return
    }
    if (includeMessage && !message) {
      snugNotifier.error('Message cannot be empty')
      return
    }
    let agencyContactManager = {}
    if (!chosenManagerProfileInfo || !chosenManagerProfileInfo.guidID) {
      agencyContactManager = managerList.find((manager) => {
        const { profile = {} } = manager
        return profile.email.toLowerCase() === managerInfo.email.toLowerCase()
      })
    } else {
      agencyContactManager = chosenManagerProfileInfo
    }

    const payload = {
      viewingID: viewingId,
      managerGUID: agencyContactManager.guidID,
      recipientsEmails: emailList.map((email) => email.text.split(' ')[0]),
      includeMessage,
      sendSMS,
      setNotCheckedNoShow,
      hideLostRent,
      hideActivity,
      viewingMessage: viewingNote,
      pmMessage: message,
      includeMarketFeedback: isMarketFeedbackActive,
    }
    this.setState({
      spinner: true,
    })
    this.setState(
      {
        unsavedChanges: false,
      },
      () => {
        sendViewingReport(payload)
          .then((data) => {
            this.setState({
              spinner: false,
            })
            if (!isFromMobileViewings) {
              history.goBack()
            } else {
              const { propertyReportGUID = '' } = data
              if (propertyReportGUID) {
                this.populatePublicPropertyReportInfo(propertyReportGUID)
              }
              snugNotifier.success('Report Successfully sent')
              const queryString = window.location.search.replace('?', '')
              const queryParams = qs.parse(queryString)
              const newQueries = {
                ...queryParams,
                propertyreportId: propertyReportGUID,
              }

              history.push({ search: qs.stringify(newQueries) })
            }
          })
          .catch((error) => {
            this.setState({
              spinner: false,
            })
            snugNotifier.error(error)
          })
      },
    )
  }

  addManagersProfileToCombinedList = (managersList) => {
    return managersList.map((manager) => {
      return { ...manager.profile, pillText: TEAM_MEMBER_PILL_TEXT }
    })
  }

  addOwnerClicked = () => {
    this.setState({
      isOwnerModalActive: true,
    })
  }

  copyLinkToClipboard = (text) => {
    const that = this
    navigator.clipboard.writeText(text).then(
      function () {
        that.setState({ linkCopied: true })
      },
      (err) => {
        that.setState({ linkCopied: false })
      },
    )
  }

  emailKeyDown = (event) => {
    const { value } = event.target
    const { keyCode } = event
    const enterButtonKeyCode = 13
    if (keyCode === enterButtonKeyCode) {
      this.emailOnBlur(value)
    }
  }

  emailOnFocus = () => {
    const { combinedOwnerAndManagersList } = this.state
    this.setState({
      showEmailResults: true,
      filteredSearchResults: combinedOwnerAndManagersList,
    })
  }

  emailOnBlur = (value) => {
    if (!value || !value.trim()) {
      this.setState({
        showEmailResults: false,
      })
      return
    }
    const emailValueWithoutSpaces = leaseOfferHelpers.removeSpacesInText(value)
    const isEmailValid = !validations.validateEmail(emailValueWithoutSpaces)[0]
    const { emailList } = this.state
    const targetEmail = {
      text: emailValueWithoutSpaces,
      status: isEmailValid ? 'valid' : 'invalid',
    }
    emailList.push(targetEmail)
    this.setState({
      emailList,
      emailValue: '',
      showEmailResults: false,
    })
  }

  emailOnchange = (value) => {
    const { combinedOwnerAndManagersList } = this.state
    this.setState({
      emailValue: value,
      unsavedChanges: true,
      showEmailResults: true,
    })
    let searchResultRow = combinedOwnerAndManagersList.filter(
      ({
        email,
        firstName,
        lastName,
        mobilePhone,
        phone,
        homePhone,
        workPhone,
      }) =>
        [
          email,
          firstName,
          lastName,
          mobilePhone,
          phone,
          homePhone,
          workPhone,
        ].some((field) =>
          `${field || ''}`.toLowerCase().includes(value.toLowerCase()),
        ),
    )
    if (searchResultRow && searchResultRow.length > 0) {
      searchResultRow = objectHelpers.alphabeticallySortObjectsBasedOnKey(
        searchResultRow,
        'firstName',
      )
    }
    this.setState({
      filteredSearchResults: searchResultRow,
    })
  }

  fetchManagers = () => {
    const { fetchTeamManagerList } = this.props
    const { agencyID, assignedTeamMembers } = this.state
    if (agencyID) {
      fetchTeamManagerList(agencyID)
        .then(({ managerList }) => {
          const mappedAssignedTeamMembers = this.buildTeamContactOptions(
            assignedTeamMembers,
            managerList,
          )

          const chosenManager = mappedAssignedTeamMembers.findIndex(
            (member) => !!member.isListingAgent,
          )

          this.setState({
            managerList,
            assignedTeamMembers: mappedAssignedTeamMembers,
            chosenManager: chosenManager === -1 ? '' : chosenManager,
            combinedOwnerAndManagersList: [
              ...this.state.combinedOwnerAndManagersList,
              ...this.addManagersProfileToCombinedList(managerList),
            ],
            teamMemberOptionsList: mapTeamMembersOptions(
              mappedAssignedTeamMembers,
            ),
          })
        })
        .catch((error) => {
          snugNotifier.error(error)
        })
    }
  }

  buildTeamContactOptions = (assignedMembers, allTeamMembers) => {
    const sortScore = (member) => {
      return (member.isListingAgent ? 10 : 0) + (member.isInspector ? 1 : 0)
    }
    let sortedMembers = allTeamMembers.map((member) => {
      const assignedMember = assignedMembers.find(
        (assigned) => assigned.agencyUserGUID === member.guidID,
      )
      const { profile } = member
      return {
        ...member,
        ...(assignedMember || {}),
        ...profile,
      }
    })
    if (allTeamMembers && allTeamMembers.length > 0) {
      sortedMembers = objectHelpers.alphabeticallySortObjectsBasedOnKey(
        sortedMembers,
        'firstName',
      )
    }
    return sortedMembers.sort((memberA, memberB) => {
      return sortScore(memberB) - sortScore(memberA)
    })
  }

  getChosenManagerProfileInfo = () => {
    const { chosenManager, assignedTeamMembers = [] } = this.state
    return assignedTeamMembers[chosenManager] || {}
  }

  fetchTeamSettings = (guid) => {
    const { fetchTeamSettingInfo } = this.props
    if (guid) {
      fetchTeamSettingInfo(guid, 'viewings')
        .then(({ viewings }) => {
          if (!isEmptyObject(viewings)) {
            const {
              display_excluded_market_feedback:
                displayExcludedMarketFeedback = false,
              viewing_report_dont_include_activity:
                settingsDisableActivity = false,
              viewing_report_dont_include_lost_rent:
                settingsDisableLostRent = false,
            } = parseDataUtil.convertObjValueFromStringToBoolean(viewings)
            this.setState(
              {
                displayExcludedMarketFeedback,
                settingsDisableActivity,
                settingsDisableLostRent,
                hideActivity: settingsDisableActivity,
                hideLostRent: settingsDisableLostRent,
              },
              () => {
                if (this.state.displayExcludedMarketFeedback === true) {
                  this.setState({
                    isMarketFeedbackActive: false,
                  })
                }
              },
            )
          }
        })
        .catch((error) => {
          this.setState({ error })
        })
    }
  }

  filteredItemSelected = (item) => {
    this.emailOnBlur(item.email)
  }

  removeEmailFromList(emailIndex) {
    const { emailList } = this.state
    const filteredEmailList = emailList.filter(
      (_, index) => Number(index) !== Number(emailIndex),
    )
    this.setState({
      emailList: filteredEmailList,
    })
  }

  toggle = (field) => {
    return (data) => {
      if (field === 'isMarketFeedbackActive' && data.value === false) {
        if (window.confirm('Are you sure?')) {
          this.setState({
            isMarketFeedbackActive: data.value,
          })
        }
      } else {
        this.setState({
          [field]: data.value,
          unsavedChanges: true,
        })
      }
    }
  }

  toggleModal = (field, option = {}) => {
    const { text, tenantModalCategory, ownerModalCategory } = option
    this.setState({
      [field]: !this.state[field],
      chosenPropertyText: text,
      tenantModalCategory,
      ownerModalCategory,
    })
  }

  update = (field) => {
    return (data) => {
      if (field === 'chosenManager') {
        this.setState({
          chosenManager: data.value,
        })
      }
      this.setState({
        [field]: data.value,
        errors: {
          ...this.state.errors,
          [field]: data.error,
        },
        unsavedChanges: true,
      })
    }
  }

  updateCommentSections(field) {
    return (data) => {
      const { value, error } = data
      this.setState({
        comments: {
          ...this.state.comments,
          [field]: value,
        },
        errors: {
          ...this.state.errors,
          [field]: error,
        },
      })
    }
  }

  render() {
    let {
      teams,
      fetchAgencyApplicantsElasticSearchResult,
      fetchCurrentOwners,
      addCurrentOwners,
      updateCurrentOwners,
      removeCurrentOwners,
      ownerModalCategory,
      showBreadcrumbs = true,
      isViewingReportEmailEnabled,
      viewingReportEmailSent,
    } = this.props
    const {
      apiError,
      managerInfo = {},
      property = {},
      propertyListedDate,
      propertyMarketFeedback,
      activityOverview = [],
      viewingsHistory = [],
      emailValue,
      emailList,
      comments,
      includeMessage,
      isMarketFeedbackActive,
      sendSMS,
      chosenManager,
      teamMemberOptionsList = [],
      viewingActivity = {},
      isViewingReportShownToOwner,
      errors,
      unsavedChanges,
      linkCopied = false,
      agencyContactDetails = {},
      spinner = false,
      agencyID = '',
      showEmailResults = false,
      filteredSearchResults = [],
      isOwnerModalActive = false,
      displayExcludedMarketFeedback = false,
      setNotCheckedNoShow,
      propertyreportId,
      settingsDisableActivity,
      settingsDisableLostRent,
      hideLostRent,
      hideActivity,
    } = this.state

    const chosenManagerProfileInfo = this.getChosenManagerProfileInfo()

    const currentTeam = helpers.findTeamByAgencyId(teams, agencyID) || {}
    const teamSlug = currentTeam.slug
    let teamId = ''
    if (currentTeam) {
      teamId = currentTeam.guid
    }
    const { viewingNote, message } = comments
    const {
      viewingNote: viewingNoteError,
      message: messageError,
      chosenManager: chosenManagerError,
    } = errors
    const {
      brandingLogoURL = '',
      contactName: teamContactName = '',
      contactNumber: teamContactNumber = '',
    } = agencyContactDetails

    const { viewingSummary: recentViewingSummary = {} } = viewingActivity
    const {
      attended: selectedAttend,
      interested: selectedInterested,
      registered: selectedRegistered,
      pmNote: selectedViewingPmNote = '',
      viewingDate: selectedViewingDate = '',
      viewingDuration: selectedViewingDuration = 0,
    } = recentViewingSummary

    const textForLastestViewingNote = isViewingReportShownToOwner
      ? selectedViewingPmNote
      : viewingNote
    const currentViewingNote = textForLastestViewingNote
      ? textForLastestViewingNote
      : ''
    const displayCurrentViewingNote = !!currentViewingNote

    const sortedActivity = activityOverview.sort(
      (formerActivity, latterActivity) => {
        return formerActivity.weekEndingType - latterActivity.weekEndingType
      },
    )
    const { profile = {} } = chosenManagerProfileInfo
    const {
      email: profileEmail = '',
      firstName: profileFirstName = '',
      lastName: profileLastName = '',
    } = profile
    const profileName = `${helpers.capitalizeFirstLetter(
      profileFirstName,
    )} ${helpers.capitalizeFirstLetter(profileLastName)}`
    const {
      address: { friendlyName = '', suburb = '' } = {},
      bathrooms = 0,
      bedrooms = 0,
      vacantDays = 0,
      daysOnTheMarket = 0,
      guidID: propertyId = '',
    } = property
    const address = property.address
    const chosenPropertyText = friendlyName + ', ' + suburb
    const offers = property.offers || {}
    const { email = '', firstName = '', lastName = '' } = managerInfo
    const {
      value = 0,
      advertised = 0,
      condition = 0,
      interest = 0,
      totalResponses = 0,
      lastResponseDateTime = '',
    } = propertyMarketFeedback
    const viewingComments = propertyMarketFeedback.comments || []
    const managerName = `${helpers.capitalizeFirstLetter(
      firstName,
    )} ${helpers.capitalizeFirstLetter(lastName)}`
    const textForTeamContactNumber = formatAndPrefix(
      teamContactNumber,
      MOBILE_PREFIX,
    )
    const activeOffer = offers[0] || {}
    const { availableFrom = '', weeklyRent = 0 } = activeOffer || {}
    const marketFeedbackText = `${totalResponses} ${
      totalResponses > 0 ? 'Responses, ' : 'Response.'
    }${
      totalResponses > 0
        ? `last at ${dateTimeHelpers.formatTime(
            lastResponseDateTime,
            dateTimeHelpers.TIME_AM_PM_DATE,
          )}`
        : ''
    } `
    const lostRent = Math.round((Math.abs(vacantDays) * weeklyRent) / 7)

    const historyViewingAvailable =
      viewingsHistory && viewingsHistory.length > 0

    const { prospectNotes } = this.state || {}

    const viewingTimeAndDate = dateTimeHelpers.formatTime(
      selectedViewingDate,
      dateTimeHelpers.TIME_AM_PM_FULL_DAY,
    )

    const crumbs = [
      {
        text: 'Overview',
        link: helpers.urlTo('teamOverview', { teamSlug: teamSlug }),
      },
      {
        text: 'Viewing',
        link: helpers.urlTo('teamsViewing', { teamSlug: teamSlug }),
      },
      {
        text: friendlyName,
        link: `${helpers.urlTo('teamsViewing', {
          teamSlug: teamSlug,
        })}&property=${propertyId}`,
      },
      {
        text: 'Report',
        link: '',
      },
    ]

    const shareLink = `${helpers.windowUrl()}${helpers.urlTo(
      'publicOwnerReport',
      { propertyreportId },
    )}`
    return (
      <div>
        {!isViewingReportShownToOwner && showBreadcrumbs && (
          <Display.CenterContentContainer componentClass="col-xs-12 p0 mb30">
            <Breadcrumbs crumbs={crumbs} />
          </Display.CenterContentContainer>
        )}
        {isViewingReportShownToOwner && (
          <Display.CenterContentContainer componentClass="col-xs-12 p0">
            <Display.FlexContainer containerClass="flex-direction-column">
              <div className="mb15 mt20 display-flex flex-wrap-wrap">
                <div className="font-size-20 fw500">
                  Click to copy the link and share:
                </div>
                {linkCopied && (
                  <span className="ibm pink-color pl15 pt2">
                    (Link copied, now you can share it)
                  </span>
                )}
              </div>
              <div
                onClick={() => this.copyLinkToClipboard(shareLink)}
                value={shareLink}
                className="shareLink mb50 p0"
                role="button"
                tabIndex="0"
                id="divClipboard"
              >
                {sharedHelpers.isMobile()
                  ? trimAndAddEllipsesPastSpecifiedLength(shareLink, 40)
                  : shareLink}
              </div>
            </Display.FlexContainer>
          </Display.CenterContentContainer>
        )}
        {!isViewingReportShownToOwner && (
          <Display.CenterContentContainer componentClass="col-xs-12 p0">
            <Display.FlexContainer containerClass="flex-direction-column">
              <Headers.MainHeader
                text="Viewing Report"
                componentClass="viewing-email-report-header"
              />
              <div className="font-size-16 fw500">
                Update the details, preview and send an email with a link to
                this report.
              </div>
            </Display.FlexContainer>
            <Display.ContainerHeader
              text="Step 1. Select one or more recipients and team contact"
              isEditable={false}
              componentClass="mt30"
            />
            <div className="email-input-border-bottom">
              <Display.FlexContainer containerClass="flex-wrap-wrap width100">
                <div className="font-size-16 fw500 mr10 grey-text mt10 pb10">
                  To
                </div>
                {emailList.length > 0 &&
                  emailList.map((emailItem, index) => {
                    return (
                      <div
                        className={`font-size-16 fw500 mr10 p10 background-grey-mobile-excluded display-flex email-icon ${
                          emailItem.status === 'invalid' ? 'invalid-email' : ''
                        }`}
                      >
                        <div>{emailItem.text}</div>
                        <img
                          className="email-remove"
                          onClick={() => this.removeEmailFromList(index)}
                          src={circleCross}
                          alt="remove"
                          role="button"
                          tabIndex="0"
                        />
                      </div>
                    )
                  })}

                <div className="email-input-wrapper">
                  <input
                    className="email-input"
                    value={emailValue}
                    onChange={(e) => this.emailOnchange(e.target.value)}
                    onBlur={(e) => this.emailOnBlur(e.target.value)}
                    onKeyDown={(e) => this.emailKeyDown(e)}
                    onFocus={this.emailOnFocus}
                  />
                </div>
              </Display.FlexContainer>
            </div>
            {showEmailResults && (
              <AllSearchResultsContainer>
                {filteredSearchResults.length > 0 ? (
                  filteredSearchResults.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onMouseDown={() => this.filteredItemSelected(item)}
                      >
                        <FilteredSearchResultsRow item={item} />
                      </div>
                    )
                  })
                ) : (
                  <SearchResultContainer>
                    {NO_RESULTS_FOUND_TEXT}
                  </SearchResultContainer>
                )}
              </AllSearchResultsContainer>
            )}
            <div className="text-align-right mt10">
              <div
                className="linkStyle"
                onClick={() => {
                  this.addOwnerClicked()
                }}
              >
                Add owner
              </div>
            </div>
            <Display.FlexContainer containerClass="align-items-center agency-contact-combo-wrapper">
              <Text.Strong
                text="Team contact:"
                componentClass="mr30-table mb0"
                showBottomMargin={false}
              />
              <Form.Dropdown
                placeholder="Team contact"
                componentClass="mr30-table"
                value={chosenManager}
                error={chosenManagerError}
                inputBoxClass="pt0"
                options={teamMemberOptionsList}
                onChange={this.update('chosenManager')}
                id="relationship"
              />
            </Display.FlexContainer>

            <div className="mt40">
              <Display.ContainerHeader
                text={`Step 2. Enter notes about this viewing: ${viewingTimeAndDate}`}
                isEditable={false}
                componentClass="mb30"
              />
              {isOwnerModalActive && (
                <AddOwnerModal
                  fetchAgencyApplicantsElasticSearchResult={
                    fetchAgencyApplicantsElasticSearchResult
                  }
                  teamId={teamId}
                  address={address}
                  toggleModal={this.toggleModal}
                  ownerModalCategory={ownerModalCategory}
                  chosenPropertyText={chosenPropertyText}
                  chosenPropertyID={propertyId}
                  fetchCurrentOwners={fetchCurrentOwners}
                  currentTeam={currentTeam}
                  addCurrentOwners={addCurrentOwners}
                  updateCurrentOwners={updateCurrentOwners}
                  removeCurrentOwners={removeCurrentOwners}
                />
              )}
              <div className="mt50">
                <Text.Strong text="Viewing Notes (external in report)" />
                <Form.CommentArea
                  value={viewingNote}
                  error={viewingNoteError}
                  placeholder="Record notes to share with the owner"
                  onChange={this.updateCommentSections('viewingNote')}
                  id="viewing notes"
                  cols={50}
                  rows={3}
                  isOptional
                  inputBoxClass="pt0"
                />
              </div>
              <Display.ContainerHeader
                text="Step 3. Choose options"
                isEditable={false}
                componentClass="mt40 mb30"
              />
              <Display.FlexContainer containerClass="align-items-center flex-direction-column-mobile">
                <div className="width100 display-flex flex-direction-column-mobile">
                  <div>
                    <Form.CheckBoxGeneral
                      label="Add email message"
                      checked={includeMessage}
                      onChange={this.toggle('includeMessage')}
                      componentClassName="align-self-normal-mobile mt10-mobile"
                    />
                  </div>
                  <Box>
                    <Form.CheckBoxGeneral
                      label="Send SMS"
                      checked={sendSMS}
                      onChange={this.toggle('sendSMS')}
                      componentClassName="align-self-normal-mobile mt10-mobile"
                    />
                    <Display.GreyBodyText
                      text="If mobile number available"
                      textClass="font-size-14"
                    />
                  </Box>
                </div>
              </Display.FlexContainer>
              {includeMessage && (
                <div className="mt20">
                  <Text.Strong text="Message" />
                  <Form.CommentArea
                    value={message}
                    error={messageError}
                    placeholder="Enter your message to include in the email"
                    onChange={this.updateCommentSections('message')}
                    id="message"
                    cols={50}
                    rows={3}
                    isOptional
                    inputBoxClass="pt0"
                  />
                </div>
              )}
              {displayExcludedMarketFeedback && (
                <Checkbox
                  checked={isMarketFeedbackActive}
                  label="Include Market Feedback"
                  onChange={this.toggle('isMarketFeedbackActive')}
                  id="includeMarketFeedback"
                  selectorStyle={'selectorForViewing'}
                />
              )}

              <Checkbox
                label="Mark registrants that weren't checked in as No Show"
                checked={setNotCheckedNoShow}
                onChange={this.toggle('setNotCheckedNoShow')}
                id="setNotCheckedNoShow"
              />

              {settingsDisableActivity && (
                <Checkbox
                  checked={hideActivity}
                  label="Exclude Activity"
                  onChange={this.toggle('hideActivity')}
                  id="hideActivity"
                />
              )}

              {settingsDisableLostRent && (
                <Checkbox
                  checked={hideLostRent}
                  label="Exclude Lost Rent"
                  onChange={this.toggle('hideLostRent')}
                  id="hideLostRent"
                />
              )}

              <Display.FlexContainer containerClass="justify-content-space-between mt50 mb40">
                <div
                  className="btn btn-transparent btn-left wa"
                  onClick={() => history.goBack()}
                  role="button"
                  tabIndex="0"
                >
                  <span>Cancel</span>
                </div>
                {isViewingReportEmailEnabled ? (
                  <>
                    <SendEmailReportButton
                      isViewingReportEmailEnabled={isViewingReportEmailEnabled}
                      viewingReportEmailSent={viewingReportEmailSent}
                      buttonOnClick={() => this.onSendReportClicked()}
                      disabled={spinner}
                      viewingGUID={this.props.viewingGUID}
                      showSpinner={spinner}
                    />
                  </>
                ) : (
                  <>
                    {' '}
                    <Display.GenericButton
                      text="Send"
                      size="large"
                      onClick={() => this.onSendReportClicked()}
                      disabled={spinner}
                      isLoading={spinner}
                    />
                  </>
                )}
              </Display.FlexContainer>
            </div>
          </Display.CenterContentContainer>
        )}

        <Display.CenterContentContainer
          componentClass="col-xs-12 p0 background-grey-mobilech-excluded z-index-auto"
          containerClass="background-grey-mobile-excluded pt40"
        >
          <Display.FlexContainer containerClass="justify-content-space-between">
            <Headers.MainHeader text="Viewing Report" />
            {brandingLogoURL && (
              <div className="branding-logo-container">
                <img src={brandingLogoURL} alt="Agency Logo" />
              </div>
            )}
          </Display.FlexContainer>

          <Display.PropertyInfoDetails
            address={`${friendlyName}, ${suburb}`}
            bathrooms={bathrooms}
            bedrooms={bedrooms}
            propertyInfo={property}
            isEntryDetailsDisplay={IS_ENTRY_DETAILS_DISPLAY}
          />

          <Display.FlexContainer containerClass="flex-direction-column-mobile">
            <Display.WhiteBoxContainer componentClass="margin-report-block-left application-standard-shadow-box-property-report">
              <Display.ContainerHeader
                text="Listing"
                isEditable={false}
                componentClass="mb20"
              />
              <div>
                <Display.FlexContainer>
                  <Text.Strong
                    text="Listed:"
                    componentClass="width60p"
                    imgSrc={calendar}
                    imgAlt="Calender Icon"
                  />
                  <Text.Strong
                    text={dateTimeHelpers.formatStdDateStamp(
                      propertyListedDate,
                    )}
                  />
                </Display.FlexContainer>
                <Display.FlexContainer>
                  <Text.Strong
                    text="On market:"
                    componentClass="width60p"
                    imgSrc={calendar}
                    imgAlt="Calender Icon"
                  />
                  <Text.Strong text={daysOnTheMarket + ' days'} />
                </Display.FlexContainer>
                <Display.FlexContainer>
                  <Text.Strong
                    text="Available:"
                    componentClass="width60p"
                    imgSrc={calendar}
                    imgAlt="Calender Icon"
                  />
                  <Text.Strong
                    text={dateTimeHelpers.formatStdDateStamp(availableFrom)}
                  />
                </Display.FlexContainer>
                <Display.FlexContainer>
                  <Text.Strong
                    text="Vacant:"
                    componentClass="width60p"
                    imgSrc={calendar}
                    imgAlt="Calender Icon"
                  />
                  <Text.Strong text={Math.abs(vacantDays) + ' days'} />
                </Display.FlexContainer>
                <Display.FlexContainer>
                  <Text.Strong
                    text="List price:"
                    componentClass="width60p"
                    imgSrc={calendar}
                    imgAlt="Calender Icon"
                  />
                  <Text.Strong text={'$' + weeklyRent + '/week'} />
                </Display.FlexContainer>
                {!hideLostRent && (
                  <Display.FlexContainer>
                    <Text.Strong
                      text="Lost rent:"
                      componentClass="width60p"
                      imgSrc={calendar}
                      imgAlt="Calender Icon"
                    />
                    <Text.Strong text={'$' + lostRent} />
                  </Display.FlexContainer>
                )}
              </div>
            </Display.WhiteBoxContainer>
            <Display.WhiteBoxContainer componentClass="margin-report-block-right  application-standard-shadow-box-property-report">
              <Display.ContainerHeader
                text="Agency contact"
                isEditable={false}
                componentClass="mb20"
              />
              <div>
                <Text.Strong
                  text={profileName !== ' ' ? profileName : managerName}
                />
                <Text.Strong text={profileEmail || email} />
                <Text.Strong text={teamContactName} />
                <Text.Strong text={textForTeamContactNumber} />
              </div>
            </Display.WhiteBoxContainer>
          </Display.FlexContainer>

          <Display.CenterContentContainer componentClass="application-standard-shadow-box-property-report col-xs-12 summary-box-on-mobile border-radius-10px">
            <Headers.SectionHeader
              text={`Viewing: ${dateTimeHelpers.formatTime(
                selectedViewingDate,
                dateTimeHelpers.TIME_AM_PM_HALF_DAY_HALF_MONTH,
              )} (${selectedViewingDuration} mins)`}
              textClass="fw600 ml24-mobile"
            />
            <Display.SectionContentContainer componentClass="p24">
              <Display.FlexContainer>
                <div className="flex-1">
                  <Display.FlexContainer containerClass="justify-content-space-between recent-viewing-number-wrapper min-width-150">
                    <Text.Strong text="Registered" componentClass="grey-text" />
                    <Text.Strong text={selectedRegistered} />
                  </Display.FlexContainer>
                  <Display.FlexContainer containerClass="justify-content-space-between recent-viewing-number-wrapper min-width-150">
                    <Text.Strong text="Attended" componentClass="grey-text" />
                    <Text.Strong text={selectedAttend} />
                  </Display.FlexContainer>
                  <Display.FlexContainer containerClass="justify-content-space-between recent-viewing-number-wrapper min-width-150">
                    <Text.Strong text="Interested" componentClass="grey-text" />
                    <Text.Strong text={selectedInterested} />
                  </Display.FlexContainer>
                </div>
                <div className="flex-3">
                  {displayCurrentViewingNote && (
                    <>
                      <Text.Strong text="PM Notes" />
                      <Text.Normal text={currentViewingNote} />
                    </>
                  )}
                </div>
              </Display.FlexContainer>
            </Display.SectionContentContainer>
          </Display.CenterContentContainer>

          <Display.CenterContentContainer componentClass="application-standard-shadow-box-property-report col-xs-12 summary-box-on-mobile mt30 border-radius-10px">
            <Headers.SectionHeader
              text="Notes relating to prospects"
              textClass="fw600 ml24-mobile"
            />
            <Display.SectionContentContainer componentClass="p24">
              {prospectNotes ? (
                prospectNotes.map((note) => (
                  <div>
                    <Text.Normal
                      componentClass="font-size-14 mt10 fw600"
                      text={
                        note.firstName +
                        ' (' +
                        helpers.formatDate(note.date) +
                        ') '
                      }
                    />
                    <Text.Normal
                      componentClass="font-size-14 mt10"
                      text={note.content}
                    />
                  </div>
                ))
              ) : (
                <Text.Normal
                  componentClass="font-size-14 mt10"
                  text={'No prospect notes available'}
                />
              )}
            </Display.SectionContentContainer>
          </Display.CenterContentContainer>
          {isMarketFeedbackActive && (
            <Display.CenterContentContainer componentClass="application-standard-shadow-box-property-report col-xs-12 summary-box-on-mobile mt30 border-radius-10px">
              <Headers.SectionHeader
                text="Market feedback"
                textClass="fw600 ml24-mobile"
              />
              <Display.SectionContentContainer componentClass="p24">
                {totalResponses === 0 && (
                  <Text.Strong text="No anonymous online feedback form has been received" />
                )}
                {totalResponses > 0 && (
                  <div>
                    <Text.Normal text={marketFeedbackText} />
                    <Display.FlexContainer containerClass="flex-direction-column-mobile">
                      <div className="flex-1">
                        <ReferenceResultComponent
                          rating={value}
                          isStarRating
                          description="Value"
                          titleComponent="fw600"
                        />
                        <ReferenceResultComponent
                          rating={advertised}
                          isStarRating
                          description="As advertised"
                          titleComponent="fw600"
                        />
                        <ReferenceResultComponent
                          rating={condition}
                          isStarRating
                          description="Condition"
                          titleComponent="fw600"
                        />
                        <ReferenceResultComponent
                          rating={interest}
                          isStarRating
                          description="Interest"
                          titleComponent="fw600"
                        />
                      </div>
                      <div className="flex-1 market-feedback-comment-wrapper">
                        {viewingComments.map((comment) => {
                          return (
                            <Display.ReportFeedbackMessage comment={comment} />
                          )
                        })}
                      </div>
                    </Display.FlexContainer>
                  </div>
                )}
                <Display.FlexContainer containerClass="flex-direction-column-mobile mt30 flex-direction-column">
                  <Text.Normal
                    componentClass="font-size-14"
                    text="Anonymous responses from checked-in viewing attendees from all property viewings based on the following questions:"
                  />
                  <Text.Normal
                    componentClass="font-size-14 mt10"
                    text="Was the property as advertised? Is the rent priced at fair market value? How is the condition of the property? How interested are you in the property?"
                  />
                </Display.FlexContainer>
              </Display.SectionContentContainer>
            </Display.CenterContentContainer>
          )}
          {!hideActivity && (
            <Display.CenterContentContainer componentClass="application-standard-shadow-box-property-report col-xs-12 summary-box-on-mobile mt30 border-radius-10px">
              <Headers.SectionHeader
                text="Activity"
                textClass="fw600 ml24-mobile"
              />
              <Display.SectionContentContainer componentClass="p24">
                <Display.FlexContainer>
                  <div className="flex-2" />
                  <div className="flex-1">
                    <Text.Strong text="This week" componentClass="grey-text" />
                  </div>
                  <div className="flex-1">
                    <Text.Strong text="Last Week" componentClass="grey-text" />
                  </div>
                  <div className="flex-1">
                    <Text.Strong text="Total" componentClass="grey-text" />
                  </div>
                </Display.FlexContainer>
                <Display.FlexContainer>
                  <div className="flex-2">
                    <Text.Strong text="Week ending" />
                  </div>
                  {sortedActivity.map((activity) => {
                    const { weekEndingType: acticityType } = activity
                    const date = dateTimeHelpers.formatLocalTime(
                      activity.weekEndingDate,
                      dateTimeHelpers.STANDARD_DATE,
                    )
                    const textForDate =
                      acticityType === weekEndingType.total
                        ? `Since ${date}`
                        : date
                    return (
                      <div className="flex-1">
                        <Text.Strong text={textForDate} />
                      </div>
                    )
                  })}
                </Display.FlexContainer>
                <Display.FlexContainer>
                  <div className="flex-2">
                    <Text.Strong text="Enquired" />
                  </div>
                  {sortedActivity.map((activity) => (
                    <div className="flex-1">
                      <Text.Strong text={activity.enquired} />
                    </div>
                  ))}
                </Display.FlexContainer>
                <Display.FlexContainer>
                  <div className="flex-2">
                    <Text.Strong text="Page views" />
                  </div>
                  {sortedActivity.map((activity) => (
                    <div className="flex-1">
                      <Text.Strong text={activity.pageViews} />
                    </div>
                  ))}
                </Display.FlexContainer>
                <Display.FlexContainer>
                  <div className="flex-2">
                    <Text.Strong text="Registered" />
                  </div>
                  {sortedActivity.map((activity) => {
                    const {
                      viewingSummary: { registered = 0 },
                    } = activity
                    return (
                      <div className="flex-1">
                        <Text.Strong text={registered} />
                      </div>
                    )
                  })}
                </Display.FlexContainer>
                <Display.FlexContainer>
                  <div className="flex-2">
                    <Text.Strong text="Attended" />
                  </div>
                  {sortedActivity.map((activity) => {
                    const {
                      viewingSummary: { attended = 0 },
                    } = activity
                    return (
                      <div className="flex-1">
                        <Text.Strong text={attended} />
                      </div>
                    )
                  })}
                </Display.FlexContainer>
                <Display.FlexContainer>
                  <div className="flex-2">
                    <Text.Strong text="May apply" />
                  </div>
                  {sortedActivity.map((activity) => {
                    const {
                      viewingSummary: { interested = 0 },
                    } = activity
                    return (
                      <div className="flex-1">
                        <Text.Strong text={interested} />
                      </div>
                    )
                  })}
                </Display.FlexContainer>
                <Display.FlexContainer>
                  <div className="flex-2">
                    <Text.Strong text="Applied" />
                  </div>
                  {sortedActivity.map((activity) => (
                    <div className="flex-1">
                      <Text.Strong text={activity.applied} />
                    </div>
                  ))}
                </Display.FlexContainer>
                <Display.FlexContainer>
                  <div className="flex-2">
                    <Text.Strong text="Offered" />
                  </div>
                  {sortedActivity.map((activity) => (
                    <div className="flex-1">
                      <Text.Strong text={activity.offered} />
                    </div>
                  ))}
                </Display.FlexContainer>
                <Display.FlexContainer>
                  <div className="flex-2">
                    <Text.Strong text="Accepted" />
                  </div>
                  {sortedActivity.map((activity) => (
                    <div className="flex-1">
                      <Text.Strong text={activity.accepted} />
                    </div>
                  ))}
                </Display.FlexContainer>
              </Display.SectionContentContainer>
            </Display.CenterContentContainer>
          )}

          <Display.CenterContentContainer componentClass="application-standard-shadow-box-property-report col-xs-12 summary-box-on-mobile mt30 border-radius-10px">
            <Headers.SectionHeader
              componentClass="ml24-mobile"
              text="Viewings history"
              textClass="fw600"
            />
            <Display.SectionContentContainer componentClass="p24">
              <Display.FlexContainer className="flex">
                <div className="display-flex flex-4">
                  <div className="flex-1">
                    <Text.Strong text="Date" componentClass="grey-text" />
                  </div>
                  <div className="flex-1">
                    <Text.Strong
                      text="Reg"
                      componentClass="grey-text text-align-center"
                    />
                  </div>
                  <div className="flex-1">
                    <Text.Strong
                      text="Att"
                      componentClass="grey-text text-align-center"
                    />
                  </div>
                  <div className="flex-1">
                    <Text.Strong
                      text="Int"
                      componentClass="grey-text text-align-center"
                    />
                  </div>
                </div>
                <div className="flex-2 display-none-mobile">
                  <Text.Strong text="PM comments" componentClass="grey-text" />
                </div>
              </Display.FlexContainer>
              {historyViewingAvailable &&
                viewingsHistory.map((viewingHistory) => {
                  const {
                    viewingDate = '',
                    attended = '',
                    interested = '',
                    registered = '',
                    pmNote = '',
                  } = viewingHistory
                  const noteDisplay = pmNote ? `"${pmNote}"` : ''
                  return (
                    <Display.FlexContainer containerClass="flex-direction-column-mobile viewing-history-wrapper">
                      <div className="display-flex flex-4">
                        <div className="flex-1">
                          <Text.Strong
                            text={dateTimeHelpers.formatLocalTime(
                              viewingDate,
                              dateTimeHelpers.TIME_AM_PM_DATE,
                            )}
                          />
                        </div>
                        <div className="flex-1">
                          <Text.Strong
                            componentClass="text-align-center"
                            text={registered}
                          />
                        </div>
                        <div className="flex-1">
                          <Text.Strong
                            componentClass="text-align-center"
                            text={attended}
                          />
                        </div>
                        <div className="flex-1">
                          <Text.Strong
                            componentClass="text-align-center"
                            text={interested}
                          />
                        </div>
                      </div>
                      <div className="flex-2">
                        <Text.Strong text={noteDisplay} />
                      </div>
                    </Display.FlexContainer>
                  )
                })}
              {!historyViewingAvailable && (
                <div>
                  <Text.Strong text="There is no viewing history" />
                </div>
              )}
            </Display.SectionContentContainer>
          </Display.CenterContentContainer>
          <Display.FlexContainer containerClass="mt50 justify-content-flex-end">
            <Display.FlexContainer containerClass="align-items-center">
              <Text.Strong text="powered by" componentClass="mt10 mr10" />
              <div>
                <SnugLogo height={30} />
              </div>
            </Display.FlexContainer>
          </Display.FlexContainer>
          <Display.ErrorMessageContainer error={apiError} />
          <Display.FlexContainer>
            <div className="mt50">
              <p className="font-size-12">
                <span className="fw500">Disclaimer: </span>
                This report has been prepared by Snug and the Property Manager
                (“both parties”) exercising all reasonable care and diligence
                subject to the Terms of Use and Privacy Policy, however, both
                parties do not make any representation or warranty, either
                express or implied, as to the accuracy, completeness or
                reliability of the information. The report material contained
                herein is indicative, incomplete, subjective and may contain
                forward looking statements and therefore can not and should not
                be relied upon. Both parties, their affiliates, directors,
                employees and/or agents expressly disclaim any and all liability
                relating or resulting from the use of all or any part of this
                document or any of the information contained therein. The
                recipient agrees to indemnify both parties from any loss or
                damage, direct or consequential, financial or otherwise.
              </p>
            </div>
          </Display.FlexContainer>
        </Display.CenterContentContainer>
        <UnsavedMessage unsavedChanges={unsavedChanges} />
      </div>
    )
  }
}

export default PropertyReportContainer
