import React from 'react'

import moment from 'moment'

const Signature = ({ signature, type }) => {
  let signatureHtml = (
    <div className="contentBlock">
      <header>
        <span>No Signature Found</span>
      </header>
    </div>
  )
  if (signature && signature.guidID) {
    let { requestID, dateSigned, signature_svg, guidID } = signature
    let signedDate = moment(dateSigned).format('Do MMM YYYY, h:mm a')

    signatureHtml = (
      <div className="">
        {/* <header>
          <span> { type } Signature</span>
        </header> */}
        <content>
          <div>
            <div className="mt5">Date Signed: {signedDate}</div>
            <div className="mt5">Signature ID: {guidID}</div>
            <div className="mt10">
              <img className="signature-card-img width70" src={signature_svg} />
            </div>
          </div>
        </content>
      </div>
    )
  }
  return <div>{signatureHtml}</div>
}

export default Signature
