import styled from 'styled-components'

const StyledViewingDurationAndDropdown = styled.div`
  display: flex;
  justify-content: center;
  select {
    margin-top: 16px;
    width: fit-content;
  }
`
export default StyledViewingDurationAndDropdown
