import styled from 'styled-components'
import { color, style } from 'styled-system'

import { Box, Flex } from 'app/components/design-system-components'
import { mediaQueries } from 'app/components/design-system-components/styles/variables'
import theme from 'app/match/applicationReportPDF/assets/theme'

export const ShowDropdownContainer = styled.div`
  width: fit-content;
  position: absolute;
  left: 6%;
  bottom: 6%;
  @media (min-width: 577px) and (max-width: 1200px) {
    width: fit-content;
    position: absolute;
    left: 10%;
    bottom: 43%;
  }
  @media (max-width: 576px) {
    width: 100%;
    position: absolute;
    top: 27%;
    height: fit-content;
    left: 0;
    .plus-show {
      text-align: center;
    }
  }
`

export const ApplicationWorkflowContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 576px) {
    width: 100%;
    justify-content: center;
    margin-top: 16px;
  }
`

export const devicesMediaMaxWidths = {
  desktop: '1200px',
  mobile: '576px',
  smallMobile: '320px',
  desktopXL: '1300px',
}

export const ResponsiveFlex = styled(Flex)`
  @media (max-width: ${(props) =>
      props.deviceMaxWidth || devicesMediaMaxWidths.mobile}) {
    flex-direction: column;
    > * {
      margin-bottom: 10px;
    }
    // quick hack to fix partly hidden dropdown in mobile
    .team-overview-dropdown {
      right: unset !important;
    }

    ${Flex} {
      width: unset;
      justify-content: ${(props) => props.flexChildrenJustify || 'center'};
      align-items: center;
    }
  }
`

export const StyledAppRowInfo = styled(ResponsiveFlex)`
  width: 80%;
  @media (max-width: 1300px) {
    width: 100%;
  }
`

export const StyledIcon = styled(Flex)`
  svg {
    width: 2em;
    height: 2em;
    color: ${({ theme }) => theme.colors.pdfGray};
    stroke-width: 1.5;
    ${color}
  }
`

export const DroppedContainer = styled(Flex)`
  background-color: ${theme.colors.gray100};
  color: ${theme.colors.gray400};
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 10px;
  border-top: none;
  margin-left: -35px;
  padding-left: 35px;
`

export const StyledApplicationSortPos = styled(Box)`
  @media (min-width: 1024px) {
    margin-left: -30px;
  }
`
