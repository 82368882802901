import React from 'react'

import qs from 'qs'
import { Redirect } from 'react-router-dom'

import AgencyFilterSection from 'app/components/agency_search_section/connection'
import { Box, Flex } from 'app/components/design-system-components'
import { breakpointsInPx } from 'app/components/design-system-components/styles/variables'
import * as Display from 'app/components/display/display'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { Breadcrumbs, PropertyHeader } from 'app/dashboard/prospects_summary'
import { STANDARD_TIMEOUT } from 'app/helpers/debounce'
import { parseQueryString } from 'app/helpers/query_string'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import Spinner from 'app/sm/common/spinner'
import {
  addOnScrollLoadListeners,
  checkForWindowWidth,
  dateUtil,
  filtersHaveChanged,
  isScrollAtEnd,
  removeOnScrollLoadListeners,
  selectAddressFriendlyName,
  urlTo,
} from 'app/sm/helpers'
import ViewingList from 'app/sm/inspections/inspections_connection'
import * as Features from 'config/features'

import 'app/sm/manager_viewings/components/style.scss'

class PropertyViewingItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isTenantModalActive: false,
      isAddOwnerModalActive: false,
      viewingListRefetchOccured: 0,
      isOwnerModalActive: false,
    }
    this.toggleModal = this.toggleModal.bind(this)
  }

  refetchTheViewingList = () => {
    this.setState({
      ...this.state,
      viewingListRefetchOccured: this.state.viewingListRefetchOccured + 1,
    })
  }

  toggleModal = (field, option = {}) => {
    const { text, tenantModalCategory } = option
    this.setState({
      [field]: !this.state[field],
      chosenPropertyText: text,
      tenantModalCategory,
    })
  }

  render() {
    const {
      activeOffer,
      property,
      setAPIError,
      teamSlug,
      todayFilterOn,
      setSuccessToast,
      archiveTimeAdder,
      datePickerValue,
      viewingEntryNoticeEnabled = false,
      keyCode,
      viewings,
      mostProgressedApplicationStatus,
      defaultSelectedViewingDurationEnabled,
      defaultSelectedViewingDuration,
      viewingScheduleEnabled,
      searchText,
      keySets,
    } = this.props
    const { tenantModalCategory } = this.state
    const {
      isOwnerModalActive = false,
      isTenantModalActive = false,
      chosenPropertyText,
    } = this.state

    let viewingStartDate = '',
      viewingEndDate = ''
    if (todayFilterOn) {
      viewingStartDate = dateUtil.getStandardUTCDateAtStart('')
    } else if (datePickerValue) {
      viewingStartDate = dateUtil.getStandardUTCDateAtStart(datePickerValue)
    }
    if (todayFilterOn) {
      viewingEndDate = dateUtil.getStandardUTCDateAtEnd('')
    } else if (datePickerValue) {
      viewingEndDate = dateUtil.getStandardUTCDateAtEnd(datePickerValue)
    }

    return (
      <div key={property.guidID}>
        <PropertyHeader
          activeOffer={activeOffer}
          showQuickLink={true}
          text={selectAddressFriendlyName(property)}
          property={property}
          failCallback={setAPIError}
          teamSlug={teamSlug}
          toggleModal={this.toggleModal}
          archiveTimeAdder={archiveTimeAdder}
          refetchTheViewingList={this.refetchTheViewingList}
          viewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
          keyCode={keyCode}
          isEntryDetailsDisplay={true}
          mostProgressedApplicationStatus={mostProgressedApplicationStatus}
          defaultSelectedViewingDurationEnabled={
            defaultSelectedViewingDurationEnabled
          }
          defaultSelectedViewingDuration={defaultSelectedViewingDuration}
          viewingScheduleEnabled={viewingScheduleEnabled}
          keySets={keySets}
        />
        <ViewingList
          fromProspect={true}
          prospectPropertyID={property.guidID}
          property={property}
          viewings={viewings}
          setSuccessToast={setSuccessToast}
          todayFilterOn={todayFilterOn}
          startDate={viewingStartDate}
          endDate={viewingEndDate}
          address={property.address}
          isTenantModalActive={isTenantModalActive}
          isOwnerModalActive={isOwnerModalActive}
          toggleModal={this.toggleModal}
          chosenPropertyText={chosenPropertyText}
          tenantModalCategory={tenantModalCategory}
          refetchTheViewingList={this.state.viewingListRefetchOccured}
          viewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
          highlightedViewingId={this.props.highlightedViewingId}
          defaultSelectedViewingDurationEnabled={
            defaultSelectedViewingDurationEnabled
          }
          defaultSelectedViewingDuration={defaultSelectedViewingDuration}
          searchText={searchText}
        />
      </div>
    )
  }
}

class ManagerViewingsListing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isTimeSortedAsc: true,
      isRegisteredSortedAsc: true,
      isAddressSortedAsc: true,
      manager: 'Any',
      error: '',
      showAllTab: false,
      todayFilterOn: false,
      archiveTime: 0,
      viewingEntryNoticeEnabled: false,
      viewingRunEnabledForTeam: false,
      teamAttributesCallDone: false,
    }

    this.archiveTimeAdder = this.archiveTimeAdder.bind(this)
  }

  UNSAFE_componentWillMount() {
    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const searchedPropertyID = curQueries && curQueries.property
    this.setState({ searchedPropertyID })
    const {
      currentTeam = {},
      fetchTeamManagerList,
      fetchTeamSettingInfo,
      fetchViewingPreference,
    } = this.props
    if (currentTeam && currentTeam.guid && searchedPropertyID) {
      this.callFetchTeamProperties(currentTeam.guid, {
        propertyGUID: searchedPropertyID,
      })
        .then(({ properties = {} }) => {
          this.setState({
            propertyData: properties.data,
            propertyCount: properties.response_metadata.total,
            nextCursor: properties.response_metadata.next_cursor,
            error: '',
          })
        })
        .catch((error) => {
          this.setState({ error })
        })
      return
    }

    if (currentTeam && currentTeam.guid) {
      const { filters } = this.props
      this.callFetchTeamProperties(currentTeam.guid, filters)
        .then(({ properties = {} }) => {
          this.setState({
            propertyData: properties.data,
            propertyCount: properties.response_metadata.total,
            nextCursor: properties.response_metadata.next_cursor,
            error: '',
          })
        })
        .catch((error) => {
          this.setState({ error })
        })

      fetchTeamManagerList(currentTeam.guid)
        .then(({ managerList }) => {
          this.setState({ managerList, error: '' })
        })
        .catch((error) => {
          this.setState({ error })
        })
      fetchTeamSettingInfo(currentTeam.guid, 'notifications,viewings')
        .then(({ notifications, viewings }) => {
          const {
            viewing_entry_notice_enabled: viewingEntryNoticeEnabled = false,
          } = notifications
          const {
            viewing_run_enabled: viewingRunEnabled = 'false',
            default_selected_viewing_duration_enabled:
              defaultSelectedViewingDurationEnabled = 'false',
            default_selected_viewing_duration:
              defaultSelectedViewingDuration = 15,
          } = viewings
          this.setState({
            viewingEntryNoticeEnabled: viewingEntryNoticeEnabled === 'true',
            viewingRunEnabledForTeam: viewingRunEnabled === 'true',
            teamAttributesCallDone: true,
            defaultSelectedViewingDurationEnabled:
              defaultSelectedViewingDurationEnabled === 'true',
            defaultSelectedViewingDuration: +defaultSelectedViewingDuration,
          })
        })
        .catch((error) => {
          this.setState({
            error: translateErrorCodeToMessage(error)
              ? translateErrorCodeToMessage(error)
              : `There was an error fetching team settings: ${error}`,
          })
        })

      fetchViewingPreference(currentTeam.guid, '').then(
        ({ preferenceInfo }) => {
          this.setState({
            viewingScheduleEnabled: preferenceInfo?.PickTime || false,
          })
        },
      )
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const element = document.querySelector('.app-content')
    element && addOnScrollLoadListeners(this.loadOnScroll, element)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.activeTab !== nextProps.activeTab) {
      this.setState({ isTimeSortedAsc: true })
    }
    const curQueries = qs.parse(nextProps.location.search, {
      ignoreQueryPrefix: true,
    })
    const searchedPropertyID = curQueries && curQueries.property
    this.setState({ searchedPropertyID })

    let { fetchTeamManagerList, currentTeam } = this.props
    currentTeam = currentTeam || {}
    const nextTeam = nextProps.currentTeam || {}

    const navigateToViewingList =
      nextProps.location.search !== this.props.location.search &&
      !curQueries.property

    if (nextTeam.guid && searchedPropertyID) {
      this.callFetchTeamProperties(nextTeam.guid, {
        propertyGUID: searchedPropertyID,
      })
        .then(({ properties = {} }) => {
          this.setState({
            propertyData: properties.data,
            propertyCount: properties.response_metadata.total,
            nextCursor: properties.response_metadata.next_cursor,
          })
        })
        .catch((error) => {
          this.setState({ error })
        })
      return
    }

    if (
      navigateToViewingList ||
      currentTeam.guid !== nextTeam.guid ||
      filtersHaveChanged(this.props, nextProps)
    ) {
      clearInterval(this.searchProperty)
      this.searchProperty = setTimeout(() => {
        this.callFetchTeamProperties(nextTeam.guid, nextProps.filters)
          .then(({ properties = {} }) => {
            this.setState({
              propertyData: properties.data,
              propertyCount: properties.response_metadata.total,
              nextCursor: properties.response_metadata.next_cursor,
            })
          })
          .catch((error) => {
            this.setState({ error })
          })
        fetchTeamManagerList(nextTeam.guid)
          .then(({ managerList }) => {
            this.setState({ managerList, error: '' })
          })
          .catch((error) => {
            this.setState({ error })
          })
      }, STANDARD_TIMEOUT)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { fetchTeamManagerList, fetchTeamSettingInfo } = this.props
    const nextTeam = this.props.currentTeam || {}
    if (
      prevState !== undefined &&
      prevState.archiveTime !== this.state.archiveTime
    ) {
      clearInterval(this.searchProperty)
      this.searchProperty = setTimeout(() => {
        this.callFetchTeamProperties(nextTeam.guid, this.props.filters)
          .then(({ properties = {} }) => {
            this.setState({
              propertyData: properties.data,
              propertyCount: properties.response_metadata.total,
              nextCursor: properties.response_metadata.next_cursor,
            })
          })
          .catch((error) => {
            this.setState({ error })
          })
        fetchTeamManagerList(nextTeam.guid)
          .then(({ managerList }) => {
            this.setState({ managerList, error: '' })
          })
          .catch((error) => {
            this.setState({ error })
          })
      }, STANDARD_TIMEOUT)
    }
    if (prevProps.currentTeam !== nextTeam && nextTeam.guid) {
      fetchTeamSettingInfo(nextTeam.guid, 'notifications,viewings')
        .then(({ notifications, viewings }) => {
          const {
            viewing_entry_notice_enabled: viewingEntryNoticeEnabled = false,
          } = notifications
          const {
            viewing_run_enabled: viewingRunEnabled = 'false',
            default_selected_viewing_duration_enabled:
              defaultSelectedViewingDurationEnabled = 'false',
            default_selected_viewing_duration:
              defaultSelectedViewingDuration = 15,
          } = viewings
          this.setState({
            viewingEntryNoticeEnabled: viewingEntryNoticeEnabled === 'true',
            viewingRunEnabledForTeam: viewingRunEnabled === 'true',
            teamAttributesCallDone: true,
            defaultSelectedViewingDurationEnabled:
              defaultSelectedViewingDurationEnabled === 'true',
            defaultSelectedViewingDuration: +defaultSelectedViewingDuration,
          })
        })
        .catch((error) => {
          this.setState({
            error: translateErrorCodeToMessage(error)
              ? translateErrorCodeToMessage(error)
              : `There was an error fetching team settings: ${error}`,
          })
        })
    }
  }

  componentWillUnmount() {
    const element = document.querySelector('.app-content')
    element && removeOnScrollLoadListeners(this.loadOnScroll, element)
  }

  setAPIError = (apiError) => {
    snugNotifier.error(apiError)
  }

  setSuccessToast = (message) => {
    snugNotifier.success(message)
  }

  archiveTimeAdder = () => {
    this.setState({ archiveTime: this.state.archiveTime + 1 })
  }

  callFetchTeamProperties = (
    teamID,
    {
      searchText,
      manager,
      status,
      cursor,
      loadMore = false,
      todayOnly = false,
      timeZone,
      datePickerValue = '',
      propertyGUID,
    },
  ) => {
    const { fetchPropertiesForViewingsByProperty } = this.props
    loadMore
      ? this.setState({ loadMoreSpinner: true })
      : this.setState({ tableSpinner: true })
    return fetchPropertiesForViewingsByProperty(teamID, {
      searchText,
      manager,
      status,
      cursor,
      loadMore,
      todayOnly,
      timeZone,
      datePickerValue,
      propertyGUID,
    })
      .then(({ properties }) => {
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
        return Promise.resolve({ properties })
      })
      .catch((error) => {
        this.setState({ error })
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
        return Promise.reject(error)
      })
  }

  handleClickOnAddressArrow = () => {
    const sorter = !this.state.isAddressSortedAsc ? 'address' : 'addressDesc'
    this.setState(
      { isAddressSortedAsc: !this.state.isAddressSortedAsc },
      () => {
        this.props.onSorterDropdownChange(sorter)
      },
    )
    return false
  }

  handleClickOnDateArrow = () => {
    const sorter = !this.state.isTimeSortedAsc ? 'time' : 'timeDesc'
    this.setState({ isTimeSortedAsc: !this.state.isTimeSortedAsc }, () => {
      this.props.onSorterDropdownChange(sorter)
    })
    return false
  }

  handleClickOnRegisteredArrow = () => {
    const sorter = !this.state.isRegisteredSortedAsc
      ? 'registered'
      : 'registeredDesc'
    this.setState(
      { isRegisteredSortedAsc: !this.state.isRegisteredSortedAsc },
      () => {
        this.props.onSorterDropdownChange(sorter)
      },
    )
    return false
  }

  loadOnScroll = () => {
    const { propertyData = [], propertyCount = 0 } = this.state
    const { filters } = this.props
    const cursor = this.state.nextCursor
    if (
      propertyCount === 0 ||
      propertyData.length >= propertyCount ||
      !cursor
    ) {
      return
    }
    const { currentTeam = {} } = this.props
    const loadMore = true

    const docHeight = document.body.clientHeight
    const winHeight = window.innerHeight
    const bottomElement = document.getElementById('content-end')
    const elementRect = bottomElement && bottomElement.getBoundingClientRect()
    const rectBottom = elementRect && elementRect.bottom

    if (
      isScrollAtEnd(rectBottom, docHeight, winHeight) &&
      !this.state.loadMoreSpinner
    ) {
      this.callFetchTeamProperties(currentTeam.guid, {
        ...filters,
        cursor,
        loadMore,
      })
        .then(({ properties }) => {
          const previousProperties = this.state.propertyData
          const updatedProperties = previousProperties.concat(properties.data)
          this.setState({
            propertyData: updatedProperties,
            nextCursor: properties.response_metadata.next_cursor,
            error: '',
          })
        })
        .catch((error) => {
          this.setState({ error })
        })
    }
  }

  renderViewingItems = () => {
    const {
      filters,
      location,
      match: {
        params: { teamSlug },
      },
    } = this.props
    const { todayOnly, datePickerValue } = filters
    const {
      propertyData,
      tenantModalCategory,
      viewingEntryNoticeEnabled,
      defaultSelectedViewingDuration,
      defaultSelectedViewingDurationEnabled,
      viewingScheduleEnabled = false,
    } = this.state
    const { highlightedViewingId } = parseQueryString(location.search)
    return (
      <>
        {propertyData &&
          propertyData.map((item, index) => {
            const {
              property,
              keyCode,
              mostProgressedApplicationStatus,
              viewings,
              activeOffer,
              keySets,
            } = item
            return (
              <PropertyViewingItem
                key={index}
                activeOffer={activeOffer}
                property={property}
                setAPIError={this.setAPIError}
                teamSlug={teamSlug}
                todayFilterOn={todayOnly}
                setSuccessToast={this.setSuccessToast}
                tenantModalCategory={tenantModalCategory}
                archiveTimeAdder={this.archiveTimeAdder}
                datePickerValue={datePickerValue}
                viewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
                keyCode={keyCode}
                viewings={viewings}
                highlightedViewingId={highlightedViewingId}
                mostProgressedApplicationStatus={
                  mostProgressedApplicationStatus
                }
                defaultSelectedViewingDurationEnabled={
                  defaultSelectedViewingDurationEnabled
                }
                defaultSelectedViewingDuration={defaultSelectedViewingDuration}
                viewingScheduleEnabled={viewingScheduleEnabled}
                searchText={filters.searchText}
                keySets={keySets}
              />
            )
          })}
        {!propertyData && (
          <div className="text-align-center">No property here</div>
        )}
      </>
    )
  }

  render() {
    const teamSlug = this.props.match.params.teamSlug
    // Redirect to mobile viewings
    const windowWidth = checkForWindowWidth()
    if (windowWidth < breakpointsInPx.sm) {
      return (
        <Redirect
          to={{
            pathname: urlTo('teamViewingsMobile', { teamSlug }),
            search: this.props.location?.search,
          }}
          push
        />
      )
    }
    const curTeam =
      this.props.teams.find((team) => team.slug === teamSlug) || {}

    const { guid = '' } = curTeam
    const {
      propertyData,
      nextCursor,
      tableSpinner,
      loadMoreSpinner,
      searchText,
      searchedPropertyID,
      error,
      viewingRunEnabledForTeam,
      teamAttributesCallDone,
    } = this.state
    const { filters } = this.props
    const { todayOnly: todayFilterOn, datePickerValue } = filters
    let crumbs = [
      {
        text: curTeam.name,
        link: urlTo('teamOverview', {
          teamSlug: this.props.match.params.teamSlug,
        }),
      },
      {
        text: 'Viewings',
        link: `${urlTo('prospectSummary', { teamSlug })}?stage=Viewing`,
      },
    ]
    let lastCrumb = null
    if (searchedPropertyID && !!propertyData?.length) {
      lastCrumb = {
        text: selectAddressFriendlyName(propertyData[0].property),
        link: '#',
      }
    } else if (searchText) {
      lastCrumb = {
        text: `search: "${searchText}"`,
        link: '#',
      }
    }
    return (
      <div className="sm-inspections-list sm-renter-applications">
        <Breadcrumbs crumbs={crumbs.concat(lastCrumb || [])} />
        <Flex justifyContent="space-between" alignItems="baseline">
          <h1>Viewings</h1>
          {teamAttributesCallDone &&
            (Features.isViewingRunEnabled(teamSlug, this.props.teams) ||
              viewingRunEnabledForTeam) && (
              <div className="viewing-run-container">
                <button
                  className="hollow-button-green wa"
                  onClick={() =>
                    viewingRunEnabledForTeam
                      ? history.push(urlTo('newViewingsRunAll', { teamSlug }))
                      : history.push(urlTo('viewingRuns', { teamSlug }))
                  }
                >
                  Viewing runs
                </button>
              </div>
            )}
        </Flex>
        <Box mb="30px">
          Create and manage open homes, private appointments and ideal week,
          plus Check-in and Invite to Apply.{' '}
          <a
            href="https://help.snug.com/hc/en-us/articles/360001289756"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </Box>

        <div className="col-first">
          <AgencyFilterSection
            disableSearchStatus
            disableSortby
            disableSearchTodayOnly
            enableViewingsSortBy
            disableSearchManager
            enableViewingSearchManager={true}
            placeholderText="address, name or mobile"
          />

          {tableSpinner ? <Spinner /> : this.renderViewingItems()}

          {nextCursor && (
            <div id="content-end" className={`text-center mt20 ml20`}>
              {loadMoreSpinner && <Spinner />}
            </div>
          )}

          <Display.ErrorMessageContainer error={error} />
        </div>
      </div>
    )
  }
}

export default ManagerViewingsListing
