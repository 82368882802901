import React from 'react'

const iconClassnames = (isActive) =>
  isActive ? 'fa fa-check-circle-o' : 'fa fa-circle-o'

const steps = [
  { no: 1, path: 'description', name: 'Description' },
  { no: 2, path: 'ammenities', name: 'Amenities' },
  { no: 3, path: 'portals', name: 'Portals' },
  { no: 4, path: 'payment', name: 'Payment' },
]

function renderStep(step, state) {
  return (
    <li className={state} key={step.path}>
      <span>{step.name}</span>
    </li>
  )
}

class Breadcrumbs extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { activeBreadCrumb } = this.props

    const currentStep = steps.filter((s) => s.path == activeBreadCrumb)[0]
    const completedSteps = steps.filter((s) => s.no < currentStep.no)
    const nextSteps = steps.filter((s) => s.no > currentStep.no)

    return (
      <div className="sm-breadcrumbs full-width">
        <ul>
          {completedSteps.map((step, i) => renderStep(step, 'completed'))}
          {renderStep(currentStep, 'current')}
          {nextSteps.map((step, i) => renderStep(step))}
        </ul>
      </div>
    )
    // return (
    //     <div className="breadcrumbs">
    //         <ul>

    //             <li className="breadcrumb-text">
    //                 <i className={iconClassnames(activeBreadCrumb === 'description')} aria-hidden="true" />
    //                 <label>Description</label>
    //             </li>
    //             <li className="breadcrumb-icon">
    //                 <i className="fa fa-angle-right" aria-hidden="true" />
    //             </li>
    //             <li className="breadcrumb-text">
    //                 <i className={iconClassnames(activeBreadCrumb === 'ammenities')} aria-hidden="true" />
    //                 <label>Ammenities</label>
    //             </li>
    //             <li className="breadcrumb-icon">
    //                 <i className="fa fa-angle-right" aria-hidden="true" />
    //             </li>
    //             <li className="breadcrumb-text">
    //                 <i className={iconClassnames(activeBreadCrumb === 'portals')} aria-hidden="true" />
    //                 <label>Portals</label>
    //             </li>
    //             <li className="breadcrumb-icon">
    //                 <i className="fa fa-angle-right" aria-hidden="true" />
    //             </li>
    //             <li className="breadcrumb-text">
    //                 <i className={iconClassnames(activeBreadCrumb === 'payment')} aria-hidden="true" />
    //                 <label>Pay</label>
    //             </li>
    //         </ul>
    //     </div>
    // )
  }
}

export default Breadcrumbs
