import React from 'react'

import { DetailsBoxCard } from 'app/components/display/display'
import { relationshipTypesTitles, urlTo } from 'app/sm/helpers'

const PersonalReferenceBox = ({
  confirmRemovePersonalReference,
  personalRef,
  readOnly = false,
  from = '',
}) => {
  const fromRenterSummary = from === 'renterSummary'
  const details = [
    `Relationship: ${relationshipTypesTitles[personalRef.relationship]}`,
    `Phone number: ${personalRef.phoneNumber || 'N/A'}`,
    `Email: ${personalRef.email || 'N/A'}`,
    `${personalRef.comment ? `Comment: ${personalRef.comment}` : ''}`,
  ]
  return (
    <DetailsBoxCard
      title={`${personalRef.firstName} ${personalRef.lastName}`}
      details={details}
      componentClass={fromRenterSummary ? 'no-box-shadow p0' : ''}
      disabled={readOnly}
      onRemove={() => confirmRemovePersonalReference(personalRef.guidID)}
      editUrl={urlTo('personalReferenceEdit', personalRef)}
    />
  )
}

export default PersonalReferenceBox
