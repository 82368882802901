import React from 'react'

import { Field, Form } from 'formik'
import styled from 'styled-components'

import { Box, Button, Flex } from 'app/components/design-system-components'
import {
  InputField,
  TelField,
} from 'app/components/design-system-components/inputs'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { formFields } from 'app/shared_components/request_app_access/form.utils'

const StyledFieldWrapper = styled(FieldWrapper)`
  width: ${theme.width[16] - 20}px;
  margin-bottom: ${theme.space[7]}px;
  margin-right: ${theme.space[5]}px;
  label {
    border-color: ${theme.colors.gray500};
  }
  input {
    height: 30px;
  }
  button {
    border-color: ${theme.colors.gray500};
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const StyledFlexWrapper = styled(Flex)`
  flex-direction: row;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const FormBody = ({ formBag, currentUser, toggleModal }) => {
  return (
    <Form>
      <StyledFlexWrapper>
        <StyledFieldWrapper
          id={formFields.firstName}
          label="First Name"
          required
          name={formFields.firstName}
        >
          <Field
            name={formFields.firstName}
            id={formFields.firstName}
            as={InputField}
          />
        </StyledFieldWrapper>

        <StyledFieldWrapper
          id={formFields.lastName}
          label="Last Name"
          required
          name={formFields.lastName}
        >
          <Field
            name={formFields.lastName}
            id={formFields.lastName}
            as={InputField}
          />
        </StyledFieldWrapper>
      </StyledFlexWrapper>
      <StyledFlexWrapper>
        <StyledFieldWrapper
          id={formFields.email}
          required
          name={formFields.email}
          label="Email"
        >
          <Field
            name={formFields.email}
            as={InputField}
            id={formFields.email}
          />
        </StyledFieldWrapper>

        <StyledFieldWrapper
          id={formFields.mobile}
          required
          name={formFields.mobile}
          label="Mobile"
        >
          <Field
            name={formFields.mobile}
            as={TelField}
            id={formFields.mobile}
          />
        </StyledFieldWrapper>
      </StyledFlexWrapper>
      <Flex width="100%" mt={5}>
        <Box width="48%" mr={5}>
          <Button
            variant="solidSecondary"
            mr={3}
            width="100%"
            type="button"
            onClick={(event) => {
              event?.preventDefault()
              toggleModal()
            }}
          >
            Cancel
          </Button>
        </Box>
        <Box width="52%">
          <Button width="100%" type="submit">
            Request Access
          </Button>
        </Box>
      </Flex>
    </Form>
  )
}

export default FormBody
