import * as React from 'react'

function DataUsageRounded(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="data_usage">
        <path
          id="icon/device/data_usage_24px"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.08113 12.9006C5.52113 15.8806 7.95113 18.3106 10.9411 18.7406C13.1611 19.0706 15.2011 18.3606 16.6711 17.0206C17.1711 16.5706 17.8911 16.4606 18.4711 16.8006C19.3411 17.3006 19.4711 18.5106 18.7311 19.1906C16.6411 21.1106 13.7411 22.1406 10.6011 21.7206C6.22113 21.1306 2.68113 17.5906 2.09113 13.2106C1.41113 8.11058 4.56113 3.63058 9.07113 2.25058C10.0311 1.96058 11.0011 2.69058 11.0011 3.69058V3.71058C11.0011 4.38058 10.5511 4.95058 9.91113 5.14058C6.74113 6.13058 4.54113 9.30058 5.08113 12.9006ZM13.0011 3.71058V3.69058C13.0011 2.69058 13.9711 1.96058 14.9211 2.25058C19.0211 3.50058 22.0011 7.32058 22.0011 11.8206C22.0011 12.5806 21.9111 13.3306 21.7411 14.0506C21.5111 15.0106 20.3911 15.4606 19.5311 14.9606L19.5211 14.9506C18.9511 14.6106 18.6911 13.9506 18.8311 13.3106C18.9411 12.8306 19.0011 12.3406 19.0011 11.8206C19.0011 8.68058 16.9311 6.03058 14.0811 5.14058C13.4511 4.94058 13.0011 4.38058 13.0011 3.71058Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default DataUsageRounded
