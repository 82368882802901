import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getPublicPropertyReport = (propertyReportId) => {
  const url = `${apiBaseUrl}sm/propertyreport/${propertyReportId}/owner`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => {
        return {
          ok: false,
          statusCode: error.statusCode,
          responseText: error.responseText,
        }
      },
    )
}

export const getPropertyReport = (viewingId) => {
  const url = `${apiBaseUrl}sm/propertyreport/viewing/${viewingId}/manager`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => {
        return {
          ok: false,
          statusCode: error.statusCode,
          responseText: error.responseText,
        }
      },
    )
}

export const postPropertyReport = (payload) => {
  const url = `${apiBaseUrl}sm/propertyreport/manager`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
