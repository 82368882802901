import React, { useState } from 'react'

import styled from 'styled-components'

import { Flex, TextSpan } from 'app/components/design-system-components'
import { Lightning } from 'app/components/design-system-components/icons/snug-specific'
import UpgradeModalWithPipedriveForm from 'app/components/design-system-components/UpgradeModalWithPipedriveForm'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledUpgradeButton = styled(Flex)`
  background: ${theme.colors.white};
  border: ${theme.borders[1]} ${theme.colors.grey200};
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: ${theme.radii[6]}px;
  padding: ${(props) =>
    props.padding ? props.padding : `${theme.space[3]}px`};
  color: ${theme.colors.gray600};
  font-size: ${(props) => props.fontSize || theme.fontSizes.pSmall12};
  line-height: ${theme.lineHeights.title};
  cursor: pointer;
`

function UpgradeButton(props) {
  const [showUpgradeForm, setShowUpgradeForm] = useState(false)
  const toggleShowUpgradeForm = () => {
    setShowUpgradeForm(!showUpgradeForm)
  }
  return (
    <>
      <StyledUpgradeButton
        onClick={toggleShowUpgradeForm}
        alignItems="center"
        {...props}
      >
        <Lightning
          width={theme.width[4] + 'px'}
          height={theme.height[4] + 'px'}
          color={theme.colors.warning400}
        />
        <TextSpan as="span" ml={2}>
          Upgrade
        </TextSpan>
      </StyledUpgradeButton>
      {showUpgradeForm && (
        <UpgradeModalWithPipedriveForm
          toggleShowUpgradeForm={toggleShowUpgradeForm}
        />
      )}
    </>
  )
}

export default UpgradeButton
