import styled from 'styled-components'

import filterIcon from 'app/assets/icons/filter-icon.svg'
import cancelIcon from 'app/assets/icons/grey-cancel.svg'
import { Box } from 'app/components/design-system-components'
import { HighlightedBox } from 'app/components/design-system-components/styles/mixins/HighlightedBox'
import * as getters from 'app/components/design-system-components/theme/getters'

export const PropertyViewingContainer = styled.div`
  width: 100%;
  margin: ${(props) => `${props.theme.space[7]}px`} 0;
`

export const ViewingsContainer = styled.div`
  position: relative;
  width: 100%;
  i.viewing-container-spinner {
    position: absolute;
    left: 50%;
    top: 45%;
  }
  .show-old-cancelled-link {
    line-height: 24px;
    margin: ${(props) => `${props.theme.space[5]}px`} 0;
    padding: 0 ${(props) => `${props.theme.space[3]}px`};
  }
  ${(props) =>
    props.viewingsLoadingSpinner &&
    `&:before {
      content: " ";
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(189, 188, 191, 0.6);
      z-index: 100;
    }`};
`

export const PropertyImageContainer = styled.div`
  height: 160px;
  width: 100%;
  background-image: ${(props) => `url(${props.bgImage})`};
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
  position: relative;
  background-position: bottom 30% right 0;
  .dropdown.actions {
    position: absolute;
    top: ${(props) => `${props.theme.space[3]}px`};
    right: ${(props) => `${props.theme.space[3]}px`};
    z-index: 100;
  }
  .property-info {
    display: flex;
    align-items: center;
    z-index: 9;
    padding-left: ${(props) => `${props.theme.space[3]}px`};
    .property-info-address {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .mw-75 {
      max-width: 75%;
    }
    .mw-87 {
      max-width: 87%;
    }
    .property-info-desc {
      display: flex;
      justify-content: space-between;
    }
  }
  &:after {
    content: '';
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(64, 64, 64, 0) 31.77%,
      #404040 100%
    );
    width: 100%;
    height: 160px;
  }
`

export const ViewingDetailsContainer = styled.div`
  min-height: 60px;
  border-bottom: ${(props) =>
    `${props.theme.borders[1]} ${props.theme.colors['near-white']}`};
`

export const ViewingCard = styled(Box)`
  padding: ${getters.spacing(3)};
  ${({ isHighlighted }) => isHighlighted && HighlightedBox({})}
`

export const OppositeSidesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.setLineHeight &&
    `line-height: ${props.theme.lineHeights['copy']}
  `}
  align-items: center;
`

export const AssigneeRegistrationContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => `${props.theme.fontSizes[4]}px`};
  .assigned-info {
    line-height: ${(props) => props.theme.lineHeights['middle']};
    margin-right: ${(props) => `${props.theme.space[2]}px`};
  }
  span {
    margin-right: ${(props) => `${props.theme.space[3]}px`};
  }
`

export const RegAttendedConfirmedContainer = styled.div`
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  position: relative;
  .private-lock {
    width: 18px;
    height: 21px;
  }
  ${(props) =>
    props.cancelled &&
    `&:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 1px solid #111;
    width: 100%;
    }`}
`

export const AddViewingContainer = styled.span`
  border: ${(props) =>
    `${props.theme.borders[1]} ${props.theme.colors['green']}`};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 2px 10px;
  cursor: pointer;
  font-weight: ${(props) => `${props.theme.fontWeights[4]}`};
  font-size: ${(props) => `${props.theme.fontSizes[6]}`};
  line-height: ${(props) => `${props.theme.lineHeights['copy']}`};
  color: #68696b;
  &:hover {
    background-color: ${(props) => `${props.theme.colors['green']}`};
    color: ${(props) => `${props.theme.colors['white']}`};
  }
`

export const SearchAndFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
`

export const SearchInputContainer = styled.div`
  width: calc(100% - 48px);
  .input-box {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-top: 0;
  }
  input {
    width: 100%;
    background: ${(props) => `${props.theme.colors.white}`};
    border-radius: 20px;
    padding-left: 40px;
    border: none;
    background: #f2f2f2;
    &:-moz-placeholder {
      color: ${(props) => `${props.theme.colors.searchInputGray}`};
    }
    &:-ms-input-placeholder {
      color: ${(props) => `${props.theme.colors.searchInputGray}`};
    }
    &::-webkit-input-placeholder {
      color: ${(props) => `${props.theme.colors.searchInputGray}`};
    }
    color: ${(props) => `${props.theme.colors.searchInputGray}`};
    &:focus {
      border: none;
    }
  }
  .icon-container {
    color: #686d71;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    white-space: nowrap;
    display: flex;
    height: 24px;
    align-items: center;
  }
  .search-icon-container {
    pointer-events: none;
    left: 10px;
  }
  .cancel-icon-container {
    right: 40px;
    cursor: pointer;
    background-image: url(${cancelIcon});
    background-repeat: no-repeat;
    background-position: center center;
    width: 24px;
    height: 24px;
  }
`

export const FilterIconContainer = styled.div`
  background: ${(props) =>
    props.filtersEnabled
      ? `${props.theme.colors['green']}`
      : `${props.theme.colors['filterGray']}`};
  border-radius: 4px;
  background-image: url(${filterIcon});
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-position: center center;
  cursor: pointer;
`

export const SortByContainer = styled.div`
  display: flex;
  border-radius: 4px;
  div {
    height: 48px;
    width: 135px;
    background-color: ${(props) => `${props.theme.colors['near-white']}`};
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => `${props.theme.colors['textGray']}`};
    cursor: pointer;
  }
  div.is-selected {
    background-color: ${(props) => `${props.theme.colors['blue']}`};
    color: ${(props) => `${props.theme.colors['white']}`};
  }
`

export const ButtonContainer = styled.button`
  width: 90px;
  height: 40px;
  padding: 0;
  text-align: center;
  margin-right: ${(props) => `${props.theme.space[3]}px`};
  border: ${(props) =>
    `${props.theme.borders[1]} ${props.theme.colors['green']}`};
  ${(props) =>
    props.isPrimary
      ? `background: ${props.theme.colors['green']};
        color: ${props.theme.colors['white']}`
      : `background: ${props.theme.colors['white']};
        color: ${props.theme.colors['green']}`}
`

export const AllButtonsContainer = styled.div`
  margin-top: ${(props) => `${props.theme.space[4]}px`};
  display: flex;
  @media (max-width: 1200px) {
    .viewing-combo-button-wrapper {
      padding-top: 0;
    }
  }
`

export const NoViewingsContainer = styled.div`
  line-height: 24px;
  margin: ${(props) => `${props.theme.space[5]}px ${props.theme.space[3]}px`};
`

export const FilterHeading = styled.div`
  margin: ${(props) => `${props.theme.space[5]}px 0`};
`

export const PropertyInfoDescContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 10;
  padding-left: ${(props) => `${props.theme.space[3]}px`};
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  li {
    color: #fff;
    display: inline-block;
  }
  li > span {
    color: #fff;
  }
`

export const PropertyLabelsContainer = styled.div`
  display: flex;
  z-index: 9;
  padding: ${(props) => `${props.theme.space[3]}px`};
`
