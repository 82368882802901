import React from 'react'

import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'

class PropertyNotAvailable extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {}

  handleClick = (teamSlug) => {
    history.push({ pathname: urlTo('teamPublicPage', { teamSlug }) })
  }

  render() {
    const { location, match } = this.props
    const { statusCode } = location.state || {}
    const { teamSlug } = match.params
    return (
      <div className="display-flex flex-direction-column justify-content-center align-items-center pt80 pb80">
        <h2 className="mb20 text-align-center">
          This property is no longer available
        </h2>
        {teamSlug && statusCode !== 404 && (
          <button
            className="btn w250"
            onClick={() => this.handleClick(teamSlug)}
          >
            View Other Properties
          </button>
        )}
      </div>
    )
  }
}

export default PropertyNotAvailable
