import React from 'react'

import styled from 'styled-components'

import backgroundCheckFooterBackground from 'app/assets/icons/bc-report-footer.svg'
import equifaxRed from 'app/assets/icons/equifax-red.png'
import whiteShield from 'app/assets/icons/logo-no-color.svg'
import logo from 'app/assets/icons/snug_logo_mobile.png'
import { Box, Flex } from 'app/components/design-system-components'
import {
  Heading,
  Text,
} from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const FooterContainer = styled(Box)`
  background-image: url(${backgroundCheckFooterBackground}) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  display: ${(props) => (!props.isView ? 'none' : 'block')};
  @media print {
    display: ${(props) => (!props.isView ? 'block' : 'block')};
  }
`

const FooterContent = styled(Box)`
  padding: ${({ theme }) =>
    `${theme.dividedSpace[10]}px ${theme.dividedSpace[6]}px`};
  @media print {
    padding: ${({ theme }) =>
      `${theme.space[16]}px ${theme.dividedSpace[6]}px`};
  }
`

const FooterText = styled(Heading)`
  font-size: ${({ theme }) => `${theme.fontSizes[19]}px`};
  font-weight: ${({ theme }) => theme.fontWeights[6]};
  color: ${({ theme }) => theme.colors.gray700} !important;
`

const PageText = styled(Heading)`
  display: ${(props) => (props.print ? 'none' : 'block')};
  @media print {
    display: ${(props) => (props.print ? 'block' : 'block')};
  }
  font-size: ${({ theme }) => `${theme.fontSizes[3]}px`};
  font-weight: ${({ theme }) => theme.fontWeights[4]};
`

const Sponsor = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 576px) {
    justify-content: center;
  }
`

const EquiFax = styled.img`
  margin-top: ${({ theme }) => `${theme.dividedSpace[1]}px`};
`

const TextWrapper = styled(Flex)`
  flex-direction: column;
  text-align: center;
  @media screen and (max-width: 576px) {
    display: none;
  }
`

const LogoContainer = styled(Flex)`
  width: ${({ theme }) => `${theme.logoWithInPx}px`};

  @media print {
    padding: ${({ theme }) =>
      `${theme.dividedSpace[2]}px 0 0 ${theme.dividedSpace[1]}px`};
    width: ${({ theme }) => `${theme.logoWithInPx + 30}px`};
  }

  @media screen and (max-width: 576px) {
    display: none;
  }
`

const Wrapper = styled(Flex)`
  justify-content: space-between;
  width: 68%;
  @media screen and (max-width: 576px) {
    width: 100%;
    justify-content: center;
  }
`

const BackgroundCheckFooter = ({ page, isView = false }) => {
  return (
    <>
      <FooterContainer page={page} isView={isView}>
        <FooterContent>
          <Flex justifyContent="space-between">
            <LogoContainer alignItems="center">
              <img src={whiteShield} alt="dismiss" />
            </LogoContainer>
            <Wrapper justifyContent="space-between" alignItems="center">
              <TextWrapper>
                <FooterText color={theme.colors.gray600}>
                  Background Check Report
                </FooterText>
                {!page ? (
                  <>
                    <PageText>
                      © Snug Technologies Pty Ltd ABN 82 616 484 292. 'Snug' is
                      a trademark.
                    </PageText>
                  </>
                ) : (
                  <>
                    <PageText>
                      © Snug Technologies Pty Ltd ABN 82 616 484 292. 'Snug' is
                      a trademark.
                    </PageText>
                    <PageText print> Page {page} of 3</PageText>
                  </>
                )}
              </TextWrapper>
              <Sponsor>
                Results powered by
                <EquiFax src={equifaxRed} alt="dismiss" />
              </Sponsor>
            </Wrapper>
          </Flex>
        </FooterContent>
      </FooterContainer>
    </>
  )
}

export default BackgroundCheckFooter
