import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import exclamation from 'app/assets/icons/exclamation.png'
import greenTick from 'app/assets/icons/green-tick.png'
import documentIcon from 'app/assets/icons/summary_icons/attachment.svg'
import { Box, Flex, TextSpan } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  INSPECTION_TYPE_PRE_APPLY,
  InspectionTypeRemote,
  PhysicalAndRemoteAcceptanceType,
} from 'app/match/apply/application_summary_before_submit/components/disclosure_consent_container/component'
import {
  Yes,
  yesOrNoDisclosureQuestions,
} from 'app/match/apply/application_summary_before_submit/components/disclosure_consent_container/disclosure_questions.constants'
import DocumentList from 'app/shared_components/document_list/document_list'
import {
  capitalizeFirstLetter,
  easyBondpayLeadIssuedType,
  parseDataUtil,
} from 'app/sm/helpers'
import * as helpers from 'app/sm/helpers'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'

const ComplianceItem = ({ componentClassName = '', icon = '', text = '' }) => {
  return (
    <div className={`no-info-provided ${componentClassName}`}>
      {icon && <img alt="accepted" className="ml5 mr5 pb2" src={icon} />}
      {text}
    </div>
  )
}

const StyledDetails = styled.p`
  margin-bottom: 0;

  ::before {
    content: 'Details: ';
  }
`

const ManagerComplianceContainer = ({
  disclosureURL = '',
  selectedApplicant,
  disclosureDate,
  disclosureDateTime,
  checkedInTime = 'N/A',
  hasAcceptedCondition,
  componentClassName,
  enableNote = true,
  displayedApplicant,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  section,
  teamSettingInfo,
  id = '',
  isApplyAnywhereApplication,
  pmAttachmentsProps,
  agencyInfo = {},
  isAboveDefaultPrice = false,
  isSolicitedAbovePriceChecked = false,
  toggleDocumentsViewer,
}) => {
  const { application: applicationSettingInfo = {} } = teamSettingInfo
  const parsedApplicationSettingInfo =
    parseDataUtil.convertObjValueFromStringToBoolean(applicationSettingInfo)

  const {
    display_bond_loan_enabled: displayBondLoadEnabled,
    posting_clause_enabled: postingClauseEnabled,
    enable_enhanced_disclosure: enableEnhancedDisclosure,
  } = parsedApplicationSettingInfo
  const {
    firstName,
    lastName,
    optedToAcceptCondition,
    optedToAcceptDisclosureConsent,
    optedToReceiveUtilityConnection,
    optedToReceiveBondLoanInformation,
    optedToRequirePostingClause,
    conditionAcceptanceType,
    propertyInspectionType,
    propertyInspectedDate,
    easyBondpayLeadStatus,
    optedToReceiveEasyBondpay,
    optedToSkipViewingDisclosures,
  } = selectedApplicant || ''
  const acceptedCondition = optedToAcceptCondition || hasAcceptedCondition
  const selectedPropertyInspectedDate = propertyInspectedDate
    ? moment(propertyInspectedDate).format(helpers.dateWithWeekDayTimeFormat)
    : ''
  const acceptedDisclosure =
    optedToAcceptDisclosureConsent || hasAcceptedCondition
  const physicalOrRemoteAcceptedText =
    propertyInspectionType === InspectionTypeRemote
      ? 'I have inspected online and indemnify from related claims'
      : `I have inspected physically on ${selectedPropertyInspectedDate} and accept the property condition`
  const singleAcceptanceText = `I have inspected and accept the property condition ${disclosureDateTime}`
  let acceptedConditionText =
    optedToAcceptCondition === true
      ? conditionAcceptanceType === PhysicalAndRemoteAcceptanceType
        ? physicalOrRemoteAcceptedText
        : singleAcceptanceText
      : `I have not inspected and do not accept the property condition`
  if (
    propertyInspectionType === INSPECTION_TYPE_PRE_APPLY &&
    optedToAcceptCondition === true
  ) {
    acceptedConditionText =
      'I am Pre-Applying and accept the property as presented online, pending an inspection'
  }
  const acceptanceItemsText =
    'Personal Information Collection and Disclosure Authority and have received the Consumer Guide, Discrimination Statement and Property Disclosures'
  const acceptedDisclosureText = acceptedDisclosure
    ? `I accept the ${acceptanceItemsText} ${disclosureDateTime}`
    : `I do not accept the ${acceptanceItemsText}`

  const optedToReceiveBondLoanText = optedToReceiveBondLoanInformation
    ? `Bond Loan required`
    : `Bond Loan not required`
  const optedToReceiveUtilityText = `I wish ${
    optedToReceiveUtilityConnection ? '' : 'not '
  }
to be contacted about connecting my utilities (electricity, gas,
internet etc) ${disclosureDateTime}`
  const optedToRequirePostingClauseText = optedToRequirePostingClause
    ? 'Posting clause required.'
    : 'Posting clause not required.'
  const easyBondpayText =
    easyBondpayLeadStatus === easyBondpayLeadIssuedType.notRequested
      ? 'easyBondpay not yet requested.'
      : easyBondpayLeadStatus === easyBondpayLeadIssuedType.success
      ? 'easyBondpay requested.'
      : 'easyBondpay requested but failed.'

  const notSolicitedAboveAdvPriceText = `I have not been solicited by this agent/agency to rent bid. ${disclosureDateTime}`
  return (
    <div id={id} className={`container-component  ${componentClassName}`}>
      {!isApplyAnywhereApplication && (
        <div className="pdf-hide bbs">
          <div className="application-identification-container application-income-proof-container">
            <div className="application-identification-sub-container">
              <div className="application-info-common-component width100">
                <div className="title width100">
                  <ContainerHeader
                    icon={documentIcon}
                    text="Manager Attachments (Private)"
                    isEditable={false}
                  />
                  <Display.DefaultBodyText
                    text="Upload documents related to this application private to your
                    team eg. TICA report, ledger etc."
                    componentClass="fs14 mt20"
                  />
                  <DocumentList
                    documents={pmAttachmentsProps.pmSupportAttachments}
                    addDocument={pmAttachmentsProps.addPMSupportAttachment}
                    deleteDocument={pmAttachmentsProps.deletePMattachment}
                    documentType={helpers.DocumentTypes.PMSupportAttachment}
                    spinner={pmAttachmentsProps.pmDocSpinner}
                    responseText={pmAttachmentsProps.pmAttachmentsApiError}
                    toggleDocumentsViewer={toggleDocumentsViewer}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ContainerHeader
        icon={documentIcon}
        text="Compliance"
        isEditable={false}
        enableNote={enableNote}
        onNewNoteUpdated={onNewNoteUpdated}
        newNotesContent={newNotesContent}
        onNoteIconClicked={onNoteIconClicked}
        onNewNoteAdded={onNewNoteAdded}
        onNoteCrossClicked={onNoteCrossClicked}
        interpreteNoteResult={interpreteNoteResult}
        enableNotesButton={enableNotesButton}
        displayedApplicant={displayedApplicant}
        section={section}
        componentClass="mt50"
      />
      <div className="application-container-padding">
        <div className="application-identification-sub-container">
          <div className="application-info-common-component">
            <div className="title width100">
              {!optedToSkipViewingDisclosures && (
                <>
                  {selectedApplicant.isPrimary && (
                    <ComplianceItem
                      componentClassName="compliance-result-padding"
                      icon={
                        acceptedCondition &&
                        propertyInspectionType !== InspectionTypeRemote
                          ? greenTick
                          : exclamation
                      }
                      text={acceptedConditionText}
                    />
                  )}
                </>
              )}

              <div>
                <ComplianceItem
                  componentClassName="compliance-result-padding"
                  icon={acceptedDisclosureText ? greenTick : exclamation}
                  text={acceptedDisclosureText}
                />
                {disclosureURL && (
                  <AppDocumentListItemSecondVersion
                    document={{
                      name: `Disclosure_${capitalizeFirstLetter(
                        firstName,
                      )}${capitalizeFirstLetter(
                        lastName,
                      )}_${disclosureDate}.pdf`,
                      URL: disclosureURL,
                    }}
                    toggleDocumentsViewer={
                      toggleDocumentsViewer
                        ? () => toggleDocumentsViewer('disclosure')
                        : undefined
                    }
                  />
                )}
              </div>
              {selectedApplicant.isPrimary && (
                <div>
                  <ComplianceItem
                    componentClassName="compliance-result-padding"
                    icon={
                      optedToReceiveUtilityConnection ? greenTick : exclamation
                    }
                    text={optedToReceiveUtilityText}
                  />
                  <ComplianceItem
                    componentClassName="compliance-result-padding"
                    icon={checkedInTime !== 'N/A' ? greenTick : exclamation}
                    text={`Viewed property (Checked-in) ${checkedInTime}`}
                  />
                  {postingClauseEnabled && (
                    <ComplianceItem
                      componentClassName="compliance-result-padding"
                      icon={
                        optedToRequirePostingClause ? exclamation : greenTick
                      }
                      text={optedToRequirePostingClauseText}
                    />
                  )}

                  {displayBondLoadEnabled && (
                    <ComplianceItem
                      componentClassName="compliance-result-padding"
                      icon={
                        optedToReceiveBondLoanInformation
                          ? exclamation
                          : greenTick
                      }
                      text={optedToReceiveBondLoanText}
                    />
                  )}
                </div>
              )}
              {optedToReceiveEasyBondpay && (
                <ComplianceItem
                  componentClassName="compliance-result-padding"
                  icon={
                    easyBondpayLeadStatus === easyBondpayLeadIssuedType.success
                      ? greenTick
                      : exclamation
                  }
                  text={easyBondpayText}
                />
              )}
              {isAboveDefaultPrice && (
                <ComplianceItem
                  componentClassName="compliance-result-padding"
                  icon={isSolicitedAbovePriceChecked ? greenTick : exclamation}
                  text={notSolicitedAboveAdvPriceText}
                />
              )}

              <Box mt="20px">
                {enableEnhancedDisclosure &&
                  displayedApplicant.disclosures &&
                  yesOrNoDisclosureQuestions.map(
                    (question) =>
                      displayedApplicant.disclosures[question.id] && (
                        <Box mb="20px">
                          <Flex justifyContent="space-between">
                            <TextSpan color={theme.colors.black}>
                              {question.label.replace(
                                '[Team Name]',
                                agencyInfo?.name || 'the team.',
                              )}
                            </TextSpan>
                            <Display.StatusPill
                              pillType={
                                displayedApplicant.disclosures[question.id] ===
                                Yes
                                  ? 'yellow'
                                  : 'green'
                              }
                              text={displayedApplicant.disclosures[question.id]}
                            />
                          </Flex>
                          {question.hasMoreDetails &&
                            displayedApplicant.disclosures[
                              question.moreDetailsId
                            ] &&
                            displayedApplicant.disclosures[question.id] ===
                              Yes && (
                              <StyledDetails>
                                {
                                  displayedApplicant.disclosures[
                                    question.moreDetailsId
                                  ]
                                }
                              </StyledDetails>
                            )}
                        </Box>
                      ),
                  )}
              </Box>
              {optedToSkipViewingDisclosures && (
                <ComplianceItem
                  componentClassName="compliance-result-padding"
                  icon={greenTick}
                  text="I have not inspected and indemnify the agent/Snug from related claims"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ManagerComplianceContainer
