import React from 'react'

import $ from 'jquery'
import localforage from 'localforage'
import qs from 'qs'

import { Box } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import {
  ErrorAgencyUserNotExist,
  ErrorNotLogin,
} from 'app/constants/error_codes'
import ErrorMessages, {
  translateErrorCodeToMessage,
} from 'app/constants/error_messages'
import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import { getApplicationsDetails } from 'app/services/http/applications'
import * as snugNotifier from 'app/services/snugNotifier'
import { ErrorMessage } from 'app/shared_components/helpers'
import CenterContentContainer from 'app/shared_components/layout_component/center_content_component/component'
import { history } from 'app/shared_components/router'
import {
  applicationTypeCollection,
  capitalizeFirstLetter,
  isApplicationApplyAnywhere,
  noteActionType,
  targetSectionObject,
  urlTo,
  wait,
} from 'app/sm/helpers'
import ApplicantDetails from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'
import Properties from 'app/sm/renter_applications/renter_application_detailed/components/new_properties'

const getRenterApplication = (managerSummary) =>
  managerSummary && managerSummary.application
const getProperty = (managerSummary) =>
  managerSummary && managerSummary.property
const getAgencyID = (property) => property && property.agencyID
const getApplicants = (renterApplication) =>
  renterApplication && renterApplication.applicants
const getPrimaryApplicant = (application) => {
  const renterApplication = getRenterApplication(application)
  const applicants = getApplicants(renterApplication)
  const primaryApplicant =
    applicants &&
    application.application.applicants.find((applicant) => applicant.isPrimary)
  return primaryApplicant
}

class RenterApplication extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedApplicantGUID: '',
      fetchRenterApplicationError: '',
      disableSpinner: false,
      noteList: [],
      newNotesContent: {},
      spinnerForNotes: false,
      pmSupportAttachments: [],
      pmDocSpinner: false,
      pmAttachmentsApiError: '',
      lastSubmitApplicationRequestTime: '',
      teamSettingInfo: {},
      appsDetailsLoading: true,
      appsDetails: {},
    }
    this.callFetchTeamSettingInfo = this.callFetchTeamSettingInfo.bind(this)
    this.mainContentSection = React.createRef()
  }

  componentDidMount() {
    $('#index').addClass('no-navbar-menu')
    const {
      match: {
        params: { applicationId, applicantId },
      },
    } = this.props
    const isApplyAnywhere = window.location.pathname.includes('/applyanywhere')
    // normal application
    if (this.props.fetchRenterApplication && !isApplyAnywhere) {
      this.fetchManagerSummary(applicationId, applicantId).then(() => {
        const primaryApplicant = getPrimaryApplicant(this.props.application)
        const applicantIdForRequest =
          applicantId || (primaryApplicant && primaryApplicant.guidID)
        applicantIdForRequest &&
          this.callFetchPMSupportAttachments(applicantIdForRequest)
      })
      this.props.fetchCompleteness().catch((error) => {
        this.redirectIfNoAccessToApplication(error, applicationId)
        this.setState({ fetchRenterApplicationError: error })
      })
      this.props.retrieveReputationPreference()
      const currentUrl = window.location.pathname
      this.props.setBackUrl(currentUrl)
      this.fetchDetailsForApplicationToolbar()
    }

    // apply anywhere application
    if (
      this.props.fetchApplyAnywhereManagerSummaryWithSecret &&
      isApplyAnywhere
    ) {
      const queries = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      })
      this.props
        .fetchApplyAnywhereManagerSummaryWithSecret(
          applicationId,
          queries.token,
        )
        .then((application) => {
          const { application: renterApplication } = application || {}
          const { applicants } = renterApplication || {}
          const primaryApplicantGUID = (applicants || []).reduce(
            (acc, applicant) => {
              if (applicant.isPrimary) acc = applicant.guidID
              return acc
            },
            '',
          )
          this.setState({
            selectedApplicantGUID: primaryApplicantGUID,
            fetchRenterApplicationError: '',
          })
        })
        .catch((error) => {
          return this.setState({ fetchRenterApplicationError: error })
        })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.fetchRenterApplication) {
      if (
        this.props.match.params.applicationId !==
          nextProps.match.params.applicationId ||
        this.props.match.params.applicantId !==
          nextProps.match.params.applicantId
      ) {
        this.props
          .fetchRenterApplication(
            nextProps.match.params.applicationId,
            undefined,
          )
          .then(() =>
            this.setState({
              selectedApplicantGUID: nextProps.match.params.applicantId,
              fetchRenterApplicationError: '',
            }),
          )
          .then(() => {
            if (nextProps.match.params.applicantId)
              return this.props.fetchRenterApplication(
                nextProps.match.params.applicationId,
                nextProps.match.params.applicantId,
              )
            else return Promise.resolve()
          })
          .catch((error) => {
            this.redirectIfNoAccessToApplication(
              error,
              nextProps.match.params.applicantId,
            )
            this.setState({ fetchRenterApplicationError: error })
          })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.application?.application?.guidID !==
      this.props?.application?.application?.guidID
    ) {
      this.fetchDetailsForApplicationToolbar()
    }
  }

  componentWillUnmount() {
    $('#index').removeClass('no-navbar-menu')
  }

  fetchDetailsForApplicationToolbar = () => {
    const { application: applicationManagerSummary = {} } = this.props
    const { application: applicationDetails, propertyOffer } =
      applicationManagerSummary
    const currentTeamId = getAgencyID(getProperty(applicationManagerSummary))
    if (currentTeamId && applicationDetails && propertyOffer) {
      this.getDetails(
        currentTeamId,
        propertyOffer.guidID,
        applicationDetails.guidID,
      )
    }

    const interpreteNoteResult = this.interpretNotesResponse(
      applicationManagerSummary,
    )
    this.setState({
      interpreteNoteResult: interpreteNoteResult,
    })
  }

  redirectIfNoAccessToApplication = (error, applicationId) => {
    if (
      error?.includes(ErrorNotLogin) ||
      error?.includes(ErrorMessages[ErrorAgencyUserNotExist])
    ) {
      history.push(`/request-app-access?applicationGUID=${applicationId}`)
    }
  }

  getDetails = (agencyGUID, offerGUID, applicationGUID) => {
    this.setState({
      appsDetailsLoading: true,
    })
    getApplicationsDetails(agencyGUID, offerGUID, applicationGUID)
      .then((data) => {
        this.setState({
          appsDetails: data,
          appsDetailsLoading: false,
        })
      })
      .catch((error) => {
        snugNotifier.error(error?.message)
      })
      .finally(() => {
        this.setState({
          appsDetailsLoading: false,
        })
      })
  }

  onBackgroundCheckClicked = (applicantId, applicationId) => {
    const { managerProcessBackgroundCheck, application } = this.props
    const { application: applicationDetails } = application
    const { selectedApplicantGUID } = this.state
    const selectedApplicant = applicationDetails
      ? applicationDetails.applicants.find(
          (applicant) => applicant.guidID === selectedApplicantGUID,
        )
      : {}
    this.setState({
      isBackgroundCheckLoading: true,
      backgroundCheckApiError: '',
    })
    managerProcessBackgroundCheck(applicantId, applicationId)
      .then(() => {
        snugNotifier.success(
          `Background Check requested for ${capitalizeFirstLetter(
            selectedApplicant.firstName,
          )} ${capitalizeFirstLetter(selectedApplicant.lastName)}`,
        )
        this.callFetchBackgroundForManager(applicantId, applicationId)
      })
      .catch((error) => {
        this.setState({
          backgroundCheckApiError: error,
          isBackgroundCheckLoading: false,
        })
        snugNotifier.error(error)
      })
  }

  onMarkAsApplicationApproved = (markAsApplicationApprovedOptions) => {
    const {
      fetchProgressApplication,
      markProgressApplicationAsApproved,
      application: applicationOverallInfo,
    } = this.props
    const { property, application } = applicationOverallInfo
    const { guidID: applicationId } = application
    const { agencyID: agencyId } = property
    return fetchProgressApplication(applicationId, agencyId)
      .then((applicationInfo) => {
        const shouldSendUtilityReferral =
          markAsApplicationApprovedOptions.sendUtilityReferrals
        if (applicationInfo && applicationInfo.referrals) {
          const modifiedReferrals = applicationInfo.referrals.map(
            (referral) => {
              return { ...referral, selected: shouldSendUtilityReferral }
            },
          )
          applicationInfo = { ...applicationInfo, referrals: modifiedReferrals }
        }
        applicationInfo.markApproved = true
        markProgressApplicationAsApproved(
          applicationId,
          applicationInfo,
          agencyId,
          markAsApplicationApprovedOptions,
        )
          .then(() => {
            snugNotifier.success(`Move to "Application Approved"`)
            return Promise.resolve()
          })
          .catch((error) => {
            snugNotifier.error(translateErrorCodeToMessage(error))
          })
      })
      .catch((apiError) => {
        snugNotifier.error(apiError.message)
        return Promise.resolve()
      })
  }

  onMarkAsLeasedBtnClick = (markAsLeasedOptions) => {
    const {
      fetchProgressApplication,
      markProgressApplicationAsLeased,
      application: applicationOverallInfo,
    } = this.props
    const { property, application } = applicationOverallInfo
    const { guidID: applicationId } = application
    const { agencyID: agencyId } = property
    return fetchProgressApplication(applicationId, agencyId)
      .then((applicationInfo) => {
        const { referrals = [] } = applicationInfo
        const confirmText =
          referrals.length > 0
            ? 'Save the successful lease details and issue the utility connection referral?'
            : 'Save the successful lease details?'
        applicationInfo.markLeased = true

        if (window.confirm(confirmText)) {
          markProgressApplicationAsLeased(
            applicationId,
            applicationInfo,
            markAsLeasedOptions,
          )
          return Promise.resolve()
        } else {
          return Promise.reject()
        }
      })
      .catch((apiError) => {
        snugNotifier.error(apiError?.message)
        return Promise.reject()
      })
  }

  onNewNoteAdded = () => {
    const { addNote } = this.props
    const { applicationId } = this.props.match.params
    const { newNotesContent } = this.state
    const { type } = newNotesContent

    // allow adding multiple notes for same section as requested https://app.shortcut.com/snugco/story/82690/high-priority-address-history-bugs#activity-82917

    const payload = {
      type: newNotesContent.type,
      target: targetSectionObject[newNotesContent.target]['index'],
      content: newNotesContent.value,
      shareWithOwner: newNotesContent.shareWithOwner,
      targetGUID: newNotesContent.targetGUID,
    }

    if (type === noteActionType.comment && !newNotesContent.value) {
      this.setState({
        newNotesContent: {
          ...this.state.newNotesContent,
          frontEndError: `The comment can't empty`,
        },
      })
      return
    }

    this.setState({
      newNotesContent: {
        ...this.state.newNotesContent,
        isNoteSaving: true,
        showNoteDisableSpinner: true,
      },
    })
    this.setState(
      {
        disableSpinner: true,
      },
      () => {
        addNote(applicationId, this.state.selectedApplicantGUID, payload)
          .then((notes) => {
            this.setState({ spinnerForNotes: true })
            this.fetchManagerSummary(
              applicationId,
              this.state.selectedApplicantGUID,
            ).finally(() => {
              this.setState({
                newNotesContent: {
                  ...this.state.newNotesContent,
                  value: '',
                  target: '',
                  isNoteSaving: false,
                },
              })
            })
          })
          .catch((error) => {
            snugNotifier.error(error)
            this.setState({
              newNotesContent: {
                ...this.state.newNotesContent,
                isNoteSaving: false,
              },
            })
          })
      },
    )
  }

  onNewNoteUpdated = (field) => {
    return (data) => {
      this.setState({
        newNotesContent: {
          ...this.state.newNotesContent,
          value: data.value,
          target: field.section,
          frontEndError: data.error,
          shareWithOwner: field.shareWithOwner,
          targetGUID: field.targetGUID,
        },
        errors: {
          ...this.state.errors,
          [field.section]: data.error,
        },
      })
    }
  }

  onNewRequestedInfoAdded = (payload) => {
    const { addNote } = this.props
    const { applicationId } = this.props.match.params
    this.setState({
      newNotesContent: {
        ...this.state.newNotesContent,
        isNoteSaving: true,
      },
    })
    this.setState(
      {
        disableSpinner: true,
      },
      () => {
        addNote(applicationId, this.state.selectedApplicantGUID, payload)
          .then((notes) => {
            this.setState({
              newNotesContent: {
                ...this.state.newNotesContent,
                isNoteSaving: false,
              },
            })
            this.setState({ spinnerForNotes: true })
            this.fetchManagerSummary(
              applicationId,
              this.state.selectedApplicantGUID,
            )
          })
          .catch((error) => {
            snugNotifier.error(error)
            this.setState({
              newNotesContent: {
                ...this.state.newNotesContent,
                isNoteSaving: false,
              },
            })
          })
      },
    )
  }

  onNoteCrossClicked = () => {
    this.setState({
      newNotesContent: {
        value: '',
        target: '',
      },
    })
  }

  onNoteIconClicked = (incomingTarget, _incomingTargetGuid = null) => {
    return (incomingType = noteActionType.comment) => {
      const {
        newNotesContent: { target, type, targetGUID },
      } = this.state
      if (
        target === incomingTarget &&
        type === incomingType &&
        _incomingTargetGuid === targetGUID
      ) {
        this.setState({
          newNotesContent: {
            value: '',
            target: '',
            targetGUID: null,
          },
        })
      } else {
        this.setState({
          newNotesContent: {
            value: '',
            target: incomingTarget,
            type: incomingType,
            targetGUID: _incomingTargetGuid,
          },
        })
      }
    }
  }

  onRemoveRentalReference = (rentalHistoryId) => {
    const { applicationId } = this.props.match.params
    const { removeRentalReference, addNote } = this.props
    const payload = {
      type: noteActionType['decline'],
      target: targetSectionObject['rentalReference']['index'],
      content: '',
    }
    this.setState(
      {
        disableSpinner: true,
      },
      () => {
        return removeRentalReference(rentalHistoryId)
          .then((result) => {
            return addNote(
              applicationId,
              this.state.selectedApplicantGUID,
              payload,
            )
          })
          .then((notes) => {
            this.setState(
              {
                newNotesContent: {
                  ...this.state.newNotesContent,
                  value: '',
                  target: '',
                  isNoteSaving: false,
                },
              },
              () => {
                this.setState({ spinnerForNotes: true })
                return this.fetchManagerSummary(
                  applicationId,
                  this.state.selectedApplicantGUID,
                  { enableSpinner: false },
                )
              },
            )
          })
          .then(() => {
            return {
              deleted: true,
            }
          })
          .catch((error) => {
            snugNotifier.error(error)
            return error
          })
      },
    )
  }

  addPMSupportAttachment = (documentType) => {
    const { selectedApplicantGUID = '' } = this.state
    const { addPMSupportDoc } = this.props

    this.setState({ pmDocSpinner: true })
    addPMSupportDoc(documentType, selectedApplicantGUID)
      .then(() => {
        this.setState({ pmAttachmentsApiError: '', pmDocSpinner: false })
        this.callFetchPMSupportAttachments(selectedApplicantGUID)
      })
      .catch((error) =>
        this.setState({ pmDocSpinner: false, pmAttachmentsApiError: error }),
      )
  }

  callFetchBackgroundForManager = (applicantId, applicationId) => {
    const { fetchBackgroundForManager } = this.props
    fetchBackgroundForManager(applicantId, applicationId)
      .then((result) => {
        if (result) {
          if (result.status === 'pending') {
            this.setState({
              updatedBackgroundCheck: result,
            })
            wait(5000).then(() =>
              this.callFetchBackgroundForManager(applicantId, applicationId),
            )
          } else {
            this.setState({
              updatedBackgroundCheck: result,
              isBackgroundCheckLoading: false,
            })
          }
        } else {
          return Promise.reject('Error when processing Background Check')
        }
      })
      .catch((error) => {
        this.setState({
          backgroundCheckApiError: error,
          isBackgroundCheckLoading: false,
        })
        snugNotifier.error(error)
      })
  }

  callFetchPMSupportAttachments = (applicantID) => {
    const { fetchPMSupportAttachments } = this.props
    const primaryApplicant = getPrimaryApplicant(this.props.application)
    const applicantIdForRequest = applicantID || primaryApplicant.guidID
    fetchPMSupportAttachments(applicantIdForRequest)
      .then((pmSupportDocs) => {
        this.setState({
          pmSupportAttachments: pmSupportDocs,
        })
      })
      .catch((error) =>
        this.setState({ pmDocSpinner: false, pmAttachmentsApiError: error }),
      )
  }

  callFetchTeamSettingInfo = (teamId) => {
    const { fetchTeamSettingInfo } = this.props
    fetchTeamSettingInfo(teamId, 'application,integrations')
      .then((teamSettingInfo) => {
        this.setState({ teamSettingInfo })
      })
      .catch((error) => {
        this.setState({ error })
      })
  }

  deletePMattachment = (attachmentID) => {
    const { deletePMSupportAttachments } = this.props
    const { selectedApplicantGUID = '' } = this.state

    this.setState({ pmDocSpinner: true })
    deletePMSupportAttachments(attachmentID)
      .then(() => {
        this.setState({ pmAttachmentsApiError: '', pmDocSpinner: false })
        this.callFetchPMSupportAttachments(selectedApplicantGUID)
      })
      .catch((error) =>
        this.setState({ pmDocSpinner: false, pmAttachmentsApiError: error }),
      )
  }

  fetchManagerSummary = (applicationId, applicantId, setting = {}) => {
    return this.props
      .fetchRenterApplication(applicationId, applicantId, setting)
      .then(() => {
        const {
          application: applicationManagerSummary = {},
          fetchRentalHistoryRatingCategories,
        } = this.props
        fetchRentalHistoryRatingCategories()

        const interpreteNoteResult = this.interpretNotesResponse(
          applicationManagerSummary,
        )
        const currentTeamId = getAgencyID(
          getProperty(applicationManagerSummary),
        )
        const curTeam = this.props.teams.find(
          (team) => team.guid === currentTeamId,
        )
        currentTeamId && this.callFetchTeamSettingInfo(currentTeamId)
        this.props.changeTeam(curTeam)

        const renterApplication = getRenterApplication(
          applicationManagerSummary,
        )
        const applicants = getApplicants(renterApplication)
        const primaryApplicant =
          applicants &&
          applicationManagerSummary.application.applicants.find(
            (applicant) => applicant.isPrimary,
          )
        const applicationSubmitReminder =
          applicationManagerSummary.lastApplicationSubmitReminder
        this.setState({
          selectedApplicantGUID: applicantId || primaryApplicant.guidID,
          fetchRenterApplicationError: '',
          spinnerForNotes: false,
          interpreteNoteResult,
          lastSubmitApplicationRequestTime:
            applicationSubmitReminder && applicationSubmitReminder.createdAt,
        })
        return Promise.resolve()
      })
      .catch((error) => {
        this.redirectIfNoAccessToApplication(error, applicationId)
        this.setState({
          fetchRenterApplicationError: error,
          spinnerForNotes: false,
        })
      })
  }

  handleApplicantChange = (applicantId) => {
    const isApplyAnywhere = window.location.pathname.includes('/applyanywhere')
    if (this.state.selectedApplicantGUID !== applicantId) {
      const {
        match: {
          params: { applicationId },
        },
      } = this.props
      this.setState({
        selectedApplicantGUID: applicantId,
      })
      if (!isApplyAnywhere && this.props.fetchRenterApplication) {
        this.props
          .fetchRenterApplication(applicationId, applicantId)
          .then(() => {
            const { application: applicationManagerSummary = {} } = this.props
            const interpreteNoteResult = this.interpretNotesResponse(
              applicationManagerSummary,
            )

            this.setState({
              fetchRenterApplicationError: '',
              interpreteNoteResult,
            })
            return Promise.resolve()
          })
          .catch((error) =>
            this.setState({ fetchRenterApplicationError: error }),
          )
        this.props
          .fetchCompleteness()
          .catch((error) =>
            this.setState({ fetchRenterApplicationError: error }),
          )
        this.props.retrieveReputationPreference()
        this.callFetchPMSupportAttachments(applicantId)
        this.setState({
          updatedBackgroundCheck: undefined,
        })
      }
      // apply anywhere application
      if (
        this.props.fetchApplyAnywhereManagerSummaryWithSecret &&
        isApplyAnywhere
      ) {
        const queries = qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        })
        this.props
          .fetchApplyAnywhereManagerSummaryWithSecret(
            applicationId,
            queries.token,
            applicantId,
          )
          .then((application) => {
            const { application: renterApplication } = application || {}
            const { applicants } = renterApplication || {}
            const primaryApplicantGUID = (applicants || []).reduce(
              (acc, applicant) => {
                if (applicant.isPrimary) acc = applicant.guidID
                return acc
              },
              '',
            )
            this.setState({
              fetchRenterApplicationError: '',
            })
          })
          .catch((error) => {
            return this.setState({ fetchRenterApplicationError: error })
          })
      }
    }
  }

  handleApplicationChange = (propertyId, applicationId, applicantId) => {
    //TODO: shaliu source of truth problem for selectedApplicantGUID
    history.push(
      urlTo('renterApplication', { propertyId, applicationId, applicantId }),
    )
    this.setState(
      {
        selectedApplicantGUID: applicantId,
        fetchRenterApplicationError: '',
      },
      () => window.location.reload(),
    )
  }

  onManagerEditRentAndEmploymentSummary = (applicationGUID, request) => {
    const { managerEditRentAndEmploymentSummary } = this.props
    return managerEditRentAndEmploymentSummary(applicationGUID, request)
      .then(() => {
        document.querySelector('.app-content').scrollTop = 0
        this.setState({ spinnerForNotes: true })
        return this.fetchManagerSummary(
          applicationGUID,
          this.state.selectedApplicantGUID,
          { enableSpinner: false },
        )
      })
      .catch((err) => {
        snugNotifier.error(err)
      })
  }

  handleSubmitRentalReviewRequest = () => {
    const {
      match: {
        params: { applicationId },
      },
    } = this.props
    this.setState(
      {
        disableSpinner: true,
      },
      () => {
        this.state.disableSpinner &&
          this.props.fetchRenterApplication(
            applicationId,
            this.state.selectedApplicantGUID,
          )
      },
    )
  }

  interpretNotesResponse = (applicationManagerSummary = {}) => {
    const { applicationSectionsStatus = {} } = applicationManagerSummary || {}

    if (Object.keys(applicationManagerSummary).length) {
      return Object.fromEntries(
        Object.entries(targetSectionObject).map(([key, def]) => {
          let status = applicationSectionsStatus[def.index] || {
            type: noteActionType.unspecified,
            author: 'unknown',
            hasComment: false,
          }

          const getLatestNoteByTargetAndAction = (target, actionType) => {
            return applicationManagerSummary.applicationNotes
              .filter(
                (note) => note.target === target && note.type === actionType,
              )
              .reduce(
                (prevNote, nextNote) =>
                  new Date(prevNote?.updatedAt) > new Date(nextNote?.updatedAt)
                    ? prevNote
                    : nextNote,
                undefined,
              )
          }
          // Assign approval last note, decline last note, and comment last note (last comment) for each target
          status = Object.assign(status, {
            // categorizedAllNotes: used for splitting notes in address and employment history sections
            categorizedAllNotes: {
              [noteActionType.approve]:
                applicationManagerSummary.applicationNotes.filter(
                  (note) =>
                    note.target === def.index &&
                    note.type === noteActionType.approve,
                ),
              [noteActionType.decline]:
                applicationManagerSummary.applicationNotes.filter(
                  (note) =>
                    note.target === def.index &&
                    note.type === noteActionType.decline,
                ),
              [noteActionType.comment]:
                applicationManagerSummary.applicationNotes.filter(
                  (note) =>
                    note.target === def.index &&
                    note.type === noteActionType.comment,
                ),
            },
            categorizedLastNotes: {
              [noteActionType.approve]: getLatestNoteByTargetAndAction(
                def.index,
                noteActionType.approve,
              ),
              [noteActionType.decline]: getLatestNoteByTargetAndAction(
                def.index,
                noteActionType.decline,
              ),
              [noteActionType.comment]: getLatestNoteByTargetAndAction(
                def.index,
                noteActionType.comment,
              ),
            },
          })
          return [key, status]
        }),
      )
    } else {
      return {}
    }
  }

  render() {
    const {
      currentTeam,
      application,
      requestRenterInformation,
      isRequestInfoDone,
      onStatusDropdownChange,
      error,
      completeness,
      spinner,
      reputationPreference,
      profile,
      updatePreferences,
      match: {
        params: { applicantId, applicationId },
      },
      shortListApplication,
      removeShortListApplication,
      versionsRatingCategories,
      requestSubmitApplication,
      fetchTeamManagerList,
      getManagersListForViewingFilters,
      assignApplicationToTeamMember,
      fetchApplicationTeamMember,
      addNote,
      sendScheduleViewingFromApplication,
      addViewing,
      fetchAllPropertyFutureViewings,
      fetchAgencyProfile,
      fetchAgencyProfileForBackGroundCheck,
      fetchRentAndEmploymentSummary,
      fetchAgencyStatus,
    } = this.props
    const {
      propertyOffer,
      employers = [],
      currentEmployers = [],
      previousEmployers = [],
      currentAddress = [],
      previousAddress = [],
      backgroundCheck = {},
      proofOfIncome,
      attachments,
      idDocumentsSummary = {},
      rentalHistory,
      pets,
      applicationTenants = [],
      personalReferences = [],
      emergencyContacts = [],
      renter,
      viewingCheckInSummary,
      studentDetails,
      applicationNotes,
      totalWeeklyIncome,
      totalWeeklyIncomeEditedByManager,
      managerApplicationSupportingFiles = [],
      managerApplicationFiles = [],
      householdInsights,
      otherProofOfIncome,
      selfTenancyCheck = {},
      managerBGCCheckPrice = 0,
    } = application ? application : {}
    const property = application.property || {}
    const {
      newNotesContent,
      spinnerForNotes,
      interpreteNoteResult,
      pmDocSpinner,
      pmAttachmentsApiError,
      pmSupportAttachments,
      updatedBackgroundCheck = {},
      isBackgroundCheckLoading = false,
      backgroundCheckApiError,
      lastSubmitApplicationRequestTime,
      fetchRenterApplicationError,
      teamSettingInfo,
      appsDetailsLoading,
      appsDetails,
    } = this.state
    const backgroundCheckResult =
      updatedBackgroundCheck.status === 'complete'
        ? updatedBackgroundCheck
        : backgroundCheck
    const renterApplication = application ? application.application : {}
    const {
      applicants = [],
      applicationType = applicationTypeCollection.unknown,
    } = renterApplication || {}
    const { applyAnywhereListing, status: rentalStatus } =
      renterApplication || {}
    let { address, guidID } = property ? property : ''
    let { friendlyName } = address ? address : ''
    const fromTemporaryPage = window.location.pathname.includes('temporary')
    const isApplyAnywhereApplication = applyAnywhereListing && {
      address: applyAnywhereListing.address,
    }
    let crumbs = []
    if (currentTeam && !fromTemporaryPage) {
      crumbs = [
        {
          text: currentTeam.name || 'Team',
          link: urlTo('teamOverview', { teamSlug: currentTeam.slug }),
        },
        {
          text: 'Applications',
          link:
            urlTo('prospectSummary', { teamSlug: currentTeam.slug }) +
            '?stage=Application',
        },
        {
          text: friendlyName,
          link:
            urlTo('prospectSummary', { teamSlug: currentTeam.slug }) +
            `?property=${guidID}`,
        },
        {
          text: 'Details',
          link: '#',
        },
      ]
    }
    const propertyInfo = isApplicationApplyAnywhere(applicationType)
      ? applyAnywhereListing
      : property
    const { slug: teamSlug } = currentTeam || {}

    const renderBreadcrumbComponent = () => {
      return <Breadcrumbs crumbs={crumbs} />
    }
    const HeadComponent = (
      <div>
        {currentTeam && !fromTemporaryPage && (
          <CenterContentContainer componentClass="col-xs-12 max-width-600px">
            <div className="mb10 pdf-hide">{renderBreadcrumbComponent()}</div>
          </CenterContentContainer>
        )}
        {!fetchRenterApplicationError && (
          <Properties
            property={propertyInfo}
            propertyOffer={propertyOffer}
            selectedApplicantGUID={this.state.selectedApplicantGUID}
            applicants={applicants || []}
            application={renterApplication}
            teamSlug={teamSlug}
          />
        )}
      </div>
    )
    const isMobile = window.innerWidth <= 968

    return (
      <div
        ref="main"
        className="sm-applications-detailed width100-tablet"
        ref={this.mainContentSection}
      >
        <div className="display-flex">
          <Display.Aside />
          <Display.Content>
            <Box className={isMobile ? '' : 'display-none'}>
              {HeadComponent}
            </Box>
          </Display.Content>
          <Display.Aside />
        </div>
        {!fetchRenterApplicationError && (
          <ApplicantDetails
            updatePreferences={updatePreferences}
            applicantId={applicantId}
            pets={pets}
            spinner={spinner}
            application={renterApplication}
            property={property}
            handleApplicantChange={this.handleApplicantChange}
            selectedApplicantGUID={this.state.selectedApplicantGUID}
            applicationId={applicationId}
            primaryApplicant={(applicants || [])
              .filter((applicant) => applicant.isPrimary)
              .shift()}
            employers={employers}
            proofOfIncome={proofOfIncome}
            otherProofOfIncome={otherProofOfIncome}
            studentDetails={studentDetails}
            rentalHistory={rentalHistory}
            backgroundCheck={backgroundCheckResult}
            attachments={attachments}
            onStatusDropdownChange={onStatusDropdownChange}
            requestRenterInformation={requestRenterInformation}
            isRequestInfoDone={isRequestInfoDone}
            error={error}
            toggleSendRequestInfo={this.props.toggleSendRequestInfo}
            propertyOffer={!!propertyOffer ? propertyOffer : {}}
            applyAnywhereListing={applyAnywhereListing}
            applicationTenants={applicationTenants}
            emergencyContacts={emergencyContacts}
            currentAddress={currentAddress}
            previousAddress={previousAddress}
            currentEmployers={currentEmployers}
            previousEmployers={previousEmployers}
            personalReferences={personalReferences}
            completeness={completeness}
            getApplicationPDF={this.props.getApplicationPDF}
            renter={renter}
            reputationPreference={reputationPreference}
            viewingCheckInSummary={viewingCheckInSummary}
            teamSlug={currentTeam && currentTeam.slug}
            agencyId={currentTeam && currentTeam.guid}
            profile={profile}
            rentalStatus={rentalStatus}
            shortListApplication={shortListApplication}
            removeShortListApplication={removeShortListApplication}
            isApplyAnywhereApplication={isApplyAnywhereApplication}
            handleSubmitRentalReviewRequest={
              this.handleSubmitRentalReviewRequest
            }
            versionsRatingCategories={versionsRatingCategories}
            idDocumentsSummary={idDocumentsSummary}
            disableSpinner={this.state.disableSpinner}
            onNewNoteUpdated={this.onNewNoteUpdated}
            newNotesContent={newNotesContent}
            onNoteIconClicked={this.onNoteIconClicked}
            onNewNoteAdded={this.onNewNoteAdded}
            applicationNotes={applicationNotes}
            onNoteCrossClicked={this.onNoteCrossClicked}
            spinnerForNotes={spinnerForNotes}
            moveTo={this.props.moveTo}
            interpreteNoteResult={interpreteNoteResult}
            totalWeeklyIncome={totalWeeklyIncome}
            totalWeeklyIncomeEditedByManager={totalWeeklyIncomeEditedByManager}
            addPMSupportAttachment={this.addPMSupportAttachment}
            deletePMattachment={this.deletePMattachment}
            pmSupportAttachments={pmSupportAttachments}
            pmDocSpinner={pmDocSpinner}
            pmAttachmentsApiError={pmAttachmentsApiError}
            onRemoveRentalReference={this.onRemoveRentalReference}
            onMarkAsLeasedBtnClick={this.onMarkAsLeasedBtnClick}
            onMarkAsApplicationApproved={this.onMarkAsApplicationApproved}
            onBackgroundCheckClicked={this.onBackgroundCheckClicked}
            isBackgroundCheckLoading={isBackgroundCheckLoading}
            backgroundCheckApiError={backgroundCheckApiError}
            requestSubmitApplication={requestSubmitApplication}
            lastSubmitApplicationRequestTime={lastSubmitApplicationRequestTime}
            teamSettingInfo={teamSettingInfo}
            managerApplicationSupportingFiles={
              managerApplicationSupportingFiles
            }
            managerApplicationFiles={managerApplicationFiles}
            householdInsights={householdInsights}
            fetchAgencyTeamMembers={getManagersListForViewingFilters}
            assignApplicationToTeamMember={assignApplicationToTeamMember}
            fetchApplicationTeamMember={fetchApplicationTeamMember}
            addNote={addNote}
            onNewRequestedInfoAdded={this.onNewRequestedInfoAdded}
            currentTeam={currentTeam}
            sendScheduleViewingFromApplication={
              sendScheduleViewingFromApplication
            }
            addViewing={addViewing}
            fetchAllPropertyFutureViewings={fetchAllPropertyFutureViewings}
            declineApplication={this.props.declineApplication}
            handleApplicationChange={this.handleApplicationChange}
            fetchAgencyProfile={fetchAgencyProfile}
            fetchAgencyProfileForBackGroundCheck={
              fetchAgencyProfileForBackGroundCheck
            }
            fetchRentAndEmploymentSummary={fetchRentAndEmploymentSummary}
            managerEditRentAndEmploymentSummary={
              this.onManagerEditRentAndEmploymentSummary
            }
            fetchAgencyStatus={fetchAgencyStatus}
            sendUtilityReferral={this.props.sendUtilityReferral}
            fetchRenterApplication={this.props.fetchRenterApplication}
            fetchApplicationApplicantBackgroundCheckReportDetails={
              this.props.fetchApplicationApplicantBackgroundCheckReportDetails
            }
            selfTenancyCheck={selfTenancyCheck}
            scroller={this.mainContentSection}
            appsDetailsLoading={appsDetailsLoading}
            appsDetails={appsDetails}
            renderBreadcrumbComponent={renderBreadcrumbComponent}
            managerBGCCheckPrice={managerBGCCheckPrice}
          />
        )}
        <CenterContentContainer componentClass="col-xs-12">
          <ErrorMessage error={this.state.fetchRenterApplicationError} />
        </CenterContentContainer>
      </div>
    )
  }
}

export default RenterApplication
