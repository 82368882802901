import React from 'react'

const TenantStatus = ({
  firstName,
  lastName,
  approved,
  isPrimary,
  submitted,
}) => (
  <div className="table pt10 mt10">
    <div className="cell-m-100 fs16 fw700 gray-dark-color">
      {firstName} {lastName}
    </div>
    <div className="cell-m">
      <div
        className={
          approved || (isPrimary && submitted) ? 'badge green' : 'badge'
        }
      >
        {' '}
        {isPrimary && submitted
          ? 'Submitted'
          : approved
          ? 'Approved'
          : 'Waiting'}{' '}
      </div>
    </div>
  </div>
)

export default TenantStatus
