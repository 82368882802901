import React from 'react'

const AvatarPlaceholder = ({ currentUser = {}, className = '' }) => {
  const hasFirstName = currentUser.firstName
  const hasLastName = currentUser.lastName
  return (
    <div className={`avatar blue-bg fs24 ${className}`}>
      {hasFirstName ? currentUser.firstName.charAt(0).toUpperCase() : ''}
      {hasLastName ? currentUser.lastName.charAt(0).toUpperCase() : ''}
    </div>
  )
}

export default AvatarPlaceholder
