import { getTeamApplications } from 'app/agency/applications_list/access'
import { mapLegacyAccessPromiseToServiceFnPromise } from 'app/services/http/utils'
import { api } from 'app/utils/api/helpers'

export const getApplicationDetails = (teamId, applicationId) => {
  return api.get(`/sm/teams/${teamId}/applications/${applicationId}`)
}

export const shareMultipleWithOwner = (teamId, payload) => {
  return api.post(`/sm/teams/${teamId}/applications/share-with-owner`, payload)
}

export const bulkSendUtilityReferrals = (payload) => {
  return api.post(`/sm/applications/sendutilitylead`, payload)
}

export const cancelUtilityReferral = (id) => {
  return api.post(`/sm/utilitylead/${id}/cancel`)
}

export const getApplications = (teamId, filters) => {
  return mapLegacyAccessPromiseToServiceFnPromise(
    getTeamApplications(teamId, filters),
  )
}

export const getApplicationFollowers = (teamID, applicationId) => {
  return api.get(`/sm/team/${teamID}/application/${applicationId}/followers`)
}

export const createAutoresponderAttachment = (teamID, files) => {
  const f = new FormData()
  for (let i = 0; i < files.length; i++) {
    f.append('file', files[i])
  }
  return api.post(`/sm/team/${teamID}/autoresponder-attachments`, f)
}

export const listAutoresponderAttachments = (teamID) => {
  return api.get(`/sm/team/${teamID}/autoresponder-attachments`)
}

export const deleteAutoresponderAttachment = (teamID, attachmentID) => {
  return api.delete(
    `/sm/team/${teamID}/autoresponder-attachments/${attachmentID}`,
  )
}

export const updateAutoresponderAttachment = (
  teamID,
  attachmentID,
  category,
  displayName,
) => {
  return api.put(
    `/sm/team/${teamID}/autoresponder-attachments/${attachmentID}`,
    { category, displayName },
  )
}

export const sendBulkBallotMessage = (propertyID, payload) => {
  return api.post(`sm/properties/${propertyID}/messages/send`, payload)
}
