import React from 'react'

import ContactDetails from 'app/match/apply/application_summary_before_submit/shared_components/contact_container/component'
import {
  calculateDuration,
  capitalizeFirstLetter,
  EmploymentTypes,
  EmploymentVerifyStatus,
  getBusinessNumberLabel,
  isSelfEmployed,
  isSelfEmployedNoAccountant,
} from 'app/sm/helpers'
import { ApplicationDetailsNumbersComponent } from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'
import * as stringHelpers from 'app/utils/strings/helpers'

import 'app/match/apply/application_summary_before_submit/shared_components/employment_box/style.scss'

class SummaryStaticEmploymentBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { employer } = this.props
    const {
      address = {},
      employerName,
      employerTradingName,
      employerABN,
      jobTitle,
      startDate,
      endDate,
      stillEmployed,
      grossAnnualIncome,
      contactName,
      phoneNumber,
      email,
      verifyStatus,
      employmentType,
      hasAccountant,
    } = employer || {}
    const employmentDuration = calculateDuration(
      startDate,
      endDate,
      stillEmployed,
    )

    return (
      <div className="grey-background container-component p25">
        <div className="card-info relative">
          <div className="gray-dark-color tdn">
            <div className="card-details">
              <div className="employment-title-container">
                <div className="employment-title">
                  {stringHelpers.capitalizeFirstLetterOfString(jobTitle)} at{' '}
                  {capitalizeFirstLetter(employerName)}
                </div>
                {!isSelfEmployedNoAccountant(employmentType, hasAccountant) && (
                  <div
                    className={`verified-status ${EmploymentVerifyStatus[verifyStatus]}`}
                  >
                    {EmploymentVerifyStatus[verifyStatus]}
                  </div>
                )}
              </div>

              <content>
                <div className="application-details-numbers-box display-flex flex-wrap">
                  <ApplicationDetailsNumbersComponent
                    title="Type"
                    text={EmploymentTypes[employmentType]}
                    componentClassName="width50p align-items-start flex-basis-25p flex-sp"
                    titleClassName="employment-label"
                    textClassName="employment-content"
                  />
                  <ApplicationDetailsNumbersComponent
                    title="Annual Income"
                    text={`$ ${grossAnnualIncome}`}
                    componentClassName="width50p align-items-start flex-basis-25p flex-sp"
                    titleClassName="employment-label"
                    textClassName="employment-content"
                  />
                  <ApplicationDetailsNumbersComponent
                    title="Duration"
                    text={employmentDuration}
                    componentClassName="width50p align-items-start flex-basis-25p flex-sp"
                    titleClassName="employment-label"
                    textClassName="employment-content"
                  />
                </div>
                {isSelfEmployed(employmentType) && (
                  <>
                    <div className="application-detials-numbers-component height-auto">
                      <div className="title mb5">
                        <div className="pdf-font-size-10">Company details</div>
                      </div>
                    </div>
                    <div className="fw500 mb5">
                      {employerName ? `Name: ${employerName}` : ''}
                    </div>
                    <div className="fw500 mb5">
                      {employerTradingName
                        ? `Trading name: ${employerTradingName}`
                        : ''}
                    </div>
                    <div className="fw500 mb10">
                      {employerABN
                        ? `${getBusinessNumberLabel(address)}: ${employerABN}`
                        : ''}
                    </div>
                  </>
                )}
                <div className="application-detials-numbers-component height-auto">
                  <div className="title mb5">
                    <div className="pdf-font-size-10">
                      {isSelfEmployed(employmentType)
                        ? 'Accountant'
                        : 'Contact'}
                    </div>
                  </div>
                </div>

                {isSelfEmployedNoAccountant(employmentType, hasAccountant) ? (
                  <div className="fw500">No accountant details</div>
                ) : (
                  <ContactDetails
                    showContactLabel={false}
                    firstName={contactName}
                    phoneNumber={phoneNumber}
                    email={email}
                  />
                )}
              </content>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default SummaryStaticEmploymentBox
