import { connect } from 'react-redux'

import {
  acceptTermsConditionsChange,
  cardSelect,
  fetchCards,
  fetchIdentityDocs,
  fetchPaymentAmount,
  registerPayment,
} from 'app/sm/background_check/background_check_actions'
import Pay from 'app/sm/background_check/identity_pay/container'

const mapStateToProps = ({ BackgroundCheck, bondCover, Shared }) => ({
  cards: BackgroundCheck.cards,
  acceptTermsAndConditions: BackgroundCheck.acceptTermsAndConditions,
  selectedCreditCard: BackgroundCheck.selectedCreditCard,
  paymentAmount: BackgroundCheck.paymentAmount,
  renderAllCards: BackgroundCheck.renderAllCards,
  error: BackgroundCheck.error,
  errorCode: BackgroundCheck.errorCode,
  backUrl: Shared.backUrl,
})

const mapDispatchToProps = (dispatch) => ({
  registerPayment: (data, applicationGUID = '') =>
    dispatch(registerPayment(data, applicationGUID)),
  fetchCards: () => dispatch(fetchCards()),
  cardSelect: (selected) => dispatch(cardSelect(selected)),
  fetchPaymentAmount: () => dispatch(fetchPaymentAmount()),
  acceptTermsConditionsChange: () => dispatch(acceptTermsConditionsChange()),
  fetchIdentityDocs: () => dispatch(fetchIdentityDocs()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Pay)
