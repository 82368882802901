import React from 'react'

import { stripeAPIKey } from 'config/env'
import qs from 'qs'
import BlockUi from 'react-block-ui'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import mastercardIcon from 'app/assets/icons/payment_icons/mastercard.png'
import visaIcon from 'app/assets/icons/payment_icons/visa.png'
import BlueSafetyMessage from 'app/shared_components/blue_safety_message'
import getParameter from 'app/shared_components/parameter_parser'
import ReferralCode from 'app/shared_components/payment_form/referral_code'
import { history } from 'app/shared_components/router'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import { SNUG_TERMS_URL } from 'app/sm/helpers'
import { SessionStorageUtils } from 'app/storage_utils'

import 'react-block-ui/style.css'

class NewCreditCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      termsAccepted: false,
      referralCode: '',
      requestID: '',
      saveCard: false,
      error: '',
      completeNumber: false,
      completeCvc: false,
      completeExpiry: false,
    }
  }

  componentDidMount() {
    this.getRequestID()
    const { shouldShowReferralCode } = this.props
    const referral = SessionStorageUtils.getItem('discountcode')

    if (shouldShowReferralCode) {
      this.setState({ referralCode: referral || '' })
    }

    // eslint-disable-next-line no-undef
    this.stripe = Stripe(stripeAPIKey)
    let elements = this.stripe.elements()
    let style = {
      base: { fontSize: '16px' },
      empty: { '::placeholder': { color: '#bebebe' } },
    }

    this.cardNumber = elements.create('cardNumber', { style })
    let cardExpiry = elements.create('cardExpiry', { style })
    let cardCvc = elements.create('cardCvc', { style })

    this.cardNumber.on('change', (event) =>
      this.setState({ brand: event.brand }),
    )
    this.cardNumber.on('change', (event) => {
      let { complete, brand } = event

      if (brand === 'visa' || brand === 'mastercard') {
        // eslint-disable-next-line no-unused-expressions
        complete ? cardExpiry.focus() : null
        this.cardNumber.update({
          classes: { base: 'StripeElement--complete' },
          style: { base: { color: '#22212a' } },
        })
        this.setState({ error: '', completeNumber: complete })
      } else if (brand !== 'unknown') {
        this.cardNumber.update({
          classes: { base: 'StripeElement--invalid' },
          style: { base: { color: '#eb1c26' } },
        })
        this.setState({
          error: `${brand} cards are not supported`,
          completeNumber: false,
        })
      } else {
        this.cardNumber.update({
          classes: { base: 'StripeElement--complete' },
          style: { base: { color: '#22212a' } },
        })
        this.setState({ error: '', completeNumber: false })
      }
    })
    cardExpiry.on('change', (event) => {
      let { complete } = event
      // eslint-disable-next-line no-unused-expressions
      complete ? cardCvc.focus() : null
      this.setState({ completeExpiry: complete })
    })
    cardCvc.on('change', (event) => {
      let { complete } = event
      this.setState({ completeCvc: complete })
    })

    this.cardNumber.mount('#cardNumber')
    cardExpiry.mount('#cardExpiry')
    cardCvc.mount('#cardCvc')
    this.setState({ saveCard: this.props.autoSave })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { autoSave } = nextProps
    this.setState({ saveCard: autoSave })
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.errorMessage && this.props.errorMessage) {
      this.setState({ blockUI: false })
    }
  }

  getRequestID() {
    const requestID = getParameter('requestid')
    this.setState({ requestID })
  }

  disabled() {
    let { termsAccepted, completeNumber, completeCvc, completeExpiry } =
      this.state
    return !(termsAccepted && completeNumber && completeCvc && completeExpiry)
  }

  submit() {
    return (event) => {
      this.setState(
        {
          blockUI: true,
          unsavedChanges: false,
        },
        () => {
          const { name } = this.state

          this.stripe.createToken(this.cardNumber, { name }).then((result) => {
            let { error } = result
            if (error) {
              this.setState({ error: error.message, blockUI: false })
            } else {
              this.setState({ error: '' })
              this.success(result.token)
            }
          })
        },
      )
    }
  }

  success(token) {
    let { formData, registerPayment } = this.props
    let { card } = token
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    this.setState({ payDepositButtonClicked: true })

    const { applicationId = '' } = queryParams || {}

    const applicationGUID = applicationId?.toString()?.split(',')[0]
    registerPayment(
      Object.assign({}, formData, {
        cardID: card.id,
        cardBrand: card.brand,
        cardExpMonth: card.exp_month,
        cardExpYear: card.exp_year,
        cardLast4: card.last4,
        stripeToken: token.id,
        saveCard: this.state.saveCard,
        termsAccepted: this.state.termsAccepted,
        referralCode: this.state.referralCode || undefined,
      }),
      applicationGUID,
    )
  }

  update(field) {
    return (event) => {
      let { target } = event
      let value = target.type === 'checkbox' ? target.checked : target.value
      this.setState({
        [field]: value,
        unsavedChanges: true,
      })
    }
  }

  updateNumber(field) {
    return (event) =>
      this.setState({
        [field]: parseInt(event.target.value),
        unsavedChanges: true,
      })
  }

  updateReferralCode = (event) => {
    this.setState({ referralCode: event.target.value })
  }

  verifyReferralCode = (succeed, fail) => {
    this.props.verifyReferralCode(
      this.state.referralCode,
      this.state.requestID,
      succeed,
      fail,
    )
  }

  render() {
    let { autoSave, payText, shouldShowReferralCode, apiError, teamSlug } =
      this.props
    const { blockUI = false, unsavedChanges } = this.state
    const visa = <img src={visaIcon} alt="Visa" />
    const mastercard = <img src={mastercardIcon} alt="Mastercard" />
    const isFromPayDeposit = window.location.href.includes(
      'pay-holding-deposit',
    )
    const paymentDetailsTagClass = isFromPayDeposit
      ? 'pay-holding-deposit'
      : 'credit-card-added'
    let brand = ''
    switch (this.state.brand) {
      case 'visa':
        brand = visa
        break
      case 'mastercard':
        brand = mastercard
        break
      default:
        brand = ''
        break
    }

    return (
      <div>
        <BlockUi tag="div" blocking={blockUI}>
          <div className="payment-icons-list">
            {visa}
            {mastercard}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="input-box">
                <input
                  placeholder="Enter name on card"
                  value={this.state.name}
                  onChange={this.update('name')}
                />
                <label>Cardholder Name</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="input-box">
                <div id="cardNumber"></div>
                <label className="focus">Card Number</label>
                <div className="card-image">{brand}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="input-box">
                <div id="cardExpiry"></div>
                <label className="focus">Expiry Date</label>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="input-box">
                <div id="cardCvc"></div>
                <label className="focus">Security Code</label>
                <i
                  className="icon-help-outline"
                  data-tooltip-content="Last 3 numbers on the back of your card"
                />
              </div>
            </div>
            {shouldShowReferralCode && (
              <div className="col-sm-12">
                <div className="input-box">
                  <ReferralCode
                    referralCode={this.state.referralCode}
                    update={this.updateReferralCode}
                    verify={this.verifyReferralCode}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className={this.state.error ? 'alert alert-danger' : ''}>
                <div>{this.state.error}</div>
              </div>
            </div>
          </div>

          {!autoSave && (
            <div className="row">
              <div className="col-sm-12 pb10 pt5">
                <label>
                  <input
                    type="checkbox"
                    value={this.state.termsAccepted}
                    onChange={this.update('saveCard')}
                  />
                  <span>Save details securely for future use</span>
                </label>
              </div>
            </div>
          )}

          <BlueSafetyMessage
            message="Snug uses bank-level 256-bit encryption to protect your data."
            showDeleteTip={true}
          />

          <div className="row">
            <div className="col-sm-12 pb30 xs-pb60">
              <label className="new-card-condition">
                <input
                  type="checkbox"
                  value={this.state.termsAccepted}
                  onChange={this.update('termsAccepted')}
                />
                <span>
                  I have read and accept the{' '}
                  <a
                    className="gray-color tdu"
                    href={SNUG_TERMS_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </span>
              </label>
            </div>
          </div>
          <div className={apiError ? 'alert alert-danger' : ''}>
            <div>{apiError}</div>
          </div>
          <div className="row pt25 mobile-form-button">
            <div className="col-sm-5 pb5 tablet-only">
              <button
                onClick={() => {
                  history.goBack()
                }}
                className="btn btn-transparent btn-left xs-text-center wa"
              >
                <i className="icon-arrow-left left"></i>
                <span>Back</span>
              </button>
            </div>
            <div className="col-sm-7 pb5">
              <button
                className={`btn ${paymentDetailsTagClass}`}
                id={teamSlug ? teamSlug : ''}
                disabled={this.disabled()}
                onClick={this.submit()}
              >
                <i className="fa fa-lock btn-icon left" aria-hidden="true" />
                <span>{payText ? payText : 'Pay Now'}</span>
              </button>
            </div>
          </div>
          <ReactTooltip className="tooltip" />
        </BlockUi>
        <UnsavedMessage unsavedChanges={unsavedChanges} />
      </div>
    )
  }
}

export default NewCreditCard
