import React from 'react'

import { Formik } from 'formik'
import moment from 'moment'

import { Alert, Box } from 'app/components/design-system-components'
import { DateTimeField } from 'app/components/design-system-components/inputs/DateTime'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { API_ERRORS_PROPERTY } from 'app/forms/FieldWrapper'
import { convertTransApiErrorToStatus } from 'app/forms/utils/convertTransApiErrorToStatus'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { StyledFieldWrapper } from 'app/pages/SelfServiceTeamRegistration/agency_details/AgencyDetails'
import { extendLoanKeySet } from 'app/services/http/teams/key-logger'

const formFields = {
  newReturnDate: 'new_due_date',
}

const _renderExtendLoanKeysetModalFooter = (
  extend,
  onCancel,
  isSubmitting,
  isDisabled,
) => {
  return (
    <GenericModalFooter
      primaryBtnConfig={{
        title: 'Extend',
        onClick: extend,
        props: {
          loading: !!isSubmitting,
          disabled: isDisabled,
          type: 'submit',
        },
      }}
      secondaryBtnConfig={{
        title: 'Cancel',
        onClick: onCancel,
      }}
    />
  )
}

export const ExtendLoanKeySetModal = ({ teamId, keySet, closeModal }) => {
  const onExtendLoan = (values, { setStatus }) => {
    setStatus({ apiErrors: {}, apiGeneralError: '' })
    const payload = {
      [formFields.newReturnDate]: moment
        .utc(values[formFields.newReturnDate].endOf('d'))
        .format(),
    }
    return extendLoanKeySet(teamId, keySet.guid, payload)
      .then((response) => closeModal(response, true))
      .catch(({ message, detailedError }) => {
        if (!detailedError) {
          return setStatus({ apiGeneralError: message })
        }
        const { notes } = detailedError?.validation || {}
        const transformedApiErrors = {
          [formFields.notes]: notes,
        }
        setStatus({
          [API_ERRORS_PROPERTY]:
            convertTransApiErrorToStatus(transformedApiErrors),
        })
      })
  }

  const validDate = (current) => {
    return current.isSameOrAfter(moment().format('L'))
  }

  return (
    <Formik
      onSubmit={onExtendLoan}
      validateOnBlur
      validateOnChange
      validateOnMount
      initialValues={{
        [formFields.newReturnDate]: '',
      }}
      enableReinitialize={true}
    >
      {({ submitForm, isSubmitting, isValid, status, values }) => (
        <GenericModal
          onDismiss={() => closeModal()}
          title={'Extend loan'}
          modalFooter={_renderExtendLoanKeysetModalFooter(
            submitForm,
            () => closeModal(),
            isSubmitting,
            !isValid,
          )}
        >
          {!!status?.apiGeneralError && (
            <Alert variant="primaryWithBg" mb={`${theme.space[4]}px`}>
              {status.apiGeneralError}
            </Alert>
          )}
          <Box>
            <Box>Extend the loan to defer overdue notices.</Box>
            <StyledFieldWrapper
              id={formFields.newReturnDate}
              name={formFields.newReturnDate}
              label="What is the new return Due Date?"
              mt={4}
            >
              <DateTimeField
                id={formFields.newReturnDate}
                name={formFields.newReturnDate}
                placeholder="Select date"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                isValidDate={validDate}
              />
            </StyledFieldWrapper>
          </Box>
        </GenericModal>
      )}
    </Formik>
  )
}
