import { connect } from 'react-redux'

import {
  fetchRequestSummary,
  getSecondaryTenantSummary,
} from 'app/bond_cover/bond_cover_actions'
import RenterIdFail from 'app/bond_cover/renters/status/request_id_fail'

const mapStateToProps = ({ bondCover, session }) => ({
  request: bondCover.request,
})

const mapDispatchToProps = (dispatch) => ({
  fetchRequestSummary: (requestID) => fetchRequestSummary(requestID),
  getSecondaryTenantSummary: (requestID) =>
    getSecondaryTenantSummary(requestID),
})

export default connect(mapStateToProps, mapDispatchToProps)(RenterIdFail)
