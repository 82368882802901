import React from 'react'

import { Link } from 'react-router-dom'

const Pagination = ({
  agencyRequests,
  currentPathName,
  cursor,
  doPagination,
}) => (
  <div className={agencyRequests ? 'mt20 clearfix' : 'hidden'}>
    <div className="pull-left">
      <Link to={currentPathName} onClick={() => doPagination('first')}>
        First Page
      </Link>
    </div>
    <div className={cursor !== 0 ? 'pull-right' : 'hidden'}>
      <Link to={currentPathName} onClick={() => doPagination('next')}>
        Next Page
      </Link>
    </div>
  </div>
)

export default Pagination
