import type { FC } from 'react'

import { Formik } from 'formik'

import Box from 'app/components/design-system-components/Box'
import {
  formFields,
  formValidation,
} from 'app/components/design-system-components/InviteToApply/form.utils'
import {
  FormBody,
  getOptionAndMetadataForApplicant,
} from 'app/components/design-system-components/InviteToApply/FormBody'
import { sendInviteToApply } from 'app/components/design-system-components/InviteToApply/http'
import Modal from 'app/components/design-system-components/Modal'
import * as snugNotifier from 'app/services/snugNotifier'

interface IContactDetails {
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
}

interface IInviteToApplyModalProps {
  toggleModal: () => void
  teamGUID?: string
  propertyDisplayText?: string
  propertyGUID?: string
  contactDetails?: IContactDetails
  enquirerHasEmail?: boolean
}
const InviteToApplyModal: FC<IInviteToApplyModalProps> = ({
  toggleModal,
  teamGUID = '',
  propertyDisplayText = '',
  propertyGUID = '',
  contactDetails = { email: '', firstName: '', lastName: '', phone: '' },
  enquirerHasEmail = false,
}) => {
  const onSubmitInviteToApply = (values: any) => {
    const selectedProperty = values[formFields.property]
    const recipients = values[formFields.applicants].map(({ option }: any) => {
      const {
        renterEmail,
        renterFirstName,
        renterLastName,
        renterContactNumber,
      } = option
      return {
        renterEmail,
        renterFirstName,
        renterLastName,
        renterContactNumber,
      }
    })
    const payload = {
      agencyGUID: teamGUID,
      recipients: recipients,
      comment: values[formFields.message],
      options: {
        [formFields.allowWithoutViewingProperty]:
          values[formFields.allowWithoutViewingProperty],
      },
      propertyGUID: selectedProperty.optionMeta.id,
    }
    sendInviteToApply(teamGUID, payload)
      .then(() => {
        snugNotifier.success('The email was successfully sent')
        toggleModal()
      })
      .catch((err: { message?: string }) => snugNotifier.error(err?.message))
  }

  const applicantFieldInitialValue = enquirerHasEmail
    ? [
        getOptionAndMetadataForApplicant(
          contactDetails?.email,
          contactDetails?.firstName,
          contactDetails?.lastName,
          contactDetails?.phone,
        ),
      ]
    : []

  const baseInitialValues = {
    [formFields.property]: {
      option: {},
      optionMeta: {
        displayText: propertyDisplayText,
        id: propertyGUID,
      },
    },
    [formFields.message]: '',
    [formFields.allowWithoutViewingProperty]: false,
    [formFields.applicants]: applicantFieldInitialValue,
    [formFields.contact]: null,
  }

  const modalBody = (
    <Box width="100%">
      <Formik
        initialValues={baseInitialValues}
        onSubmit={onSubmitInviteToApply}
        validate={formValidation}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {(props) => (
          <FormBody
            formBag={props}
            teamGUID={teamGUID}
            toggleModal={toggleModal}
          />
        )}
      </Formik>
    </Box>
  )
  return (
    <Modal
      modalBody={modalBody}
      primaryLabel={undefined}
      primaryAction={undefined}
      secondaryLabel={undefined}
      secondaryAction={undefined}
      toggleModal={toggleModal}
      customModalFooter={true}
      modalHeading="Invite to Apply"
      modalFooter={undefined}
    />
  )
}

export default InviteToApplyModal
