import React from 'react'

import { Box, Button, Flex } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import {
  keySetStatuses,
  statusesToCheckIn,
  statusesToCheckOut,
  statusesToReserve,
} from 'app/constants/key-logger.constants'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const ActionsCell = ({
  checkIn,
  checkOut,
  remove,
  extend,
  reserve,
  cancelReservation,
}) => {
  return ({ value, row: { original: keySet } }) => {
    const renderMainActionBtn = () => {
      const btnConfig = (() => {
        const { status } = keySet
        if (statusesToCheckIn.includes(status)) {
          return {
            title: 'Check in',
            handler: checkIn,
          }
        }

        if (statusesToCheckOut.includes(status)) {
          return {
            title: 'Check out',
            handler: checkOut,
          }
        }

        return null
      })()

      return (
        btnConfig && (
          <Button
            variant="outlineSuccess"
            style={{ whiteSpace: 'nowrap' }}
            onClick={() => btnConfig.handler(keySet)}
          >
            {btnConfig.title}
          </Button>
        )
      )
    }

    const renderContextMenu = () => {
      const { status } = keySet
      if (status === keySetStatuses.removed) return null

      let options = [
        { text: 'Remove', enableTool: true, onClick: () => remove(keySet) },
      ]

      if (
        status === keySetStatuses.onLoan ||
        status === keySetStatuses.overdue
      ) {
        options = [
          ...options,
          {
            text: 'Extend loan',
            enableTool: true,
            onClick: () => extend(keySet),
          },
        ]
      }

      if (statusesToReserve.includes(status) && !keySet.reservations?.length) {
        options.push({
          text: 'Reserve',
          enableTool: true,
          onClick: () => reserve(keySet),
        })
      }

      if (!!keySet.reservations?.length) {
        options.push({
          text: 'Cancel reservation',
          enableTool: true,
          onClick: () => cancelReservation(keySet),
        })
      }

      return (
        <Box ml={theme.space[3] + 'px'}>
          <Display.ToolsButton tools={options} />
        </Box>
      )
    }

    return (
      <Flex justifyContent="flex-end">
        {renderMainActionBtn()}

        {renderContextMenu()}
      </Flex>
    )
  }
}
