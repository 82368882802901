import { connect } from 'react-redux'

import { submitJoinedApplication } from 'app/sm/apply_joint/actions'
import ApplyJointConfirmationPage from 'app/sm/apply_joint/confirmation_page_container'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  submitJoinedApplication: (slug, data) =>
    dispatch(submitJoinedApplication(slug, data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplyJointConfirmationPage)
