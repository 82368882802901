// todo: use key-value object instead of an array. kept as is for now, because of all current usages is accessing styles using array index.
export const fontSizes = [
  6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 30, 36, 48, 80, 96, 35, 40, 28, 25,
  13, 15,
]

export const headingsFontSizes = {
  h1: fontSizes[12],
  h2: fontSizes[11],
  h3: fontSizes[10],
  h4: fontSizes[8],
  h5: fontSizes[6],
  h6: fontSizes[5],
}

export const paragraphFontSizes = {
  pGigantic100: '100px',
  pHuge80: '80px',
  pSurplusLarge24: fontSizes[9] + 'px',
  pSuperLarge22: fontSizes[8] + 'px',
  pExtraLarge18: fontSizes[6] + 'px',
  pLarge16: fontSizes[5] + 'px',
  pRegular14: fontSizes[4] + 'px',
  pSmall12: fontSizes[3] + 'px',
  pExtraSmall10: fontSizes[2] + 'px',
}

export const fontSizesWithAliases = {
  ...fontSizes,
  ...headingsFontSizes,
  ...paragraphFontSizes,
}

export const fontWeight = [100, 200, 300, 400, 500, 600, 700, 800, 900]

export const headingFontWeights = {
  h1: fontWeight[6],
  h2: fontWeight[6],
  h3: fontWeight[5],
  h4: fontWeight[5],
  h5: fontWeight[5],
  h6: fontWeight[5],
}

export const fontWeightParas = {
  mid: fontWeight[4],
}

export const fontWeightsWithAliases = {
  bold: 'bold',
  ...fontWeight,
  ...headingFontWeights,
  ...fontWeightParas,
}

export const breakpointsInPx = {
  sm: 576,
  md: 1024,
  xl: 1200,
}

export const breakpoints = {
  sm: `${breakpointsInPx.sm}px`,
  md: `${breakpointsInPx.md}px`,
  xl: `${breakpointsInPx.xl}px`,
}

export const mediaQueries = {
  sm: `@media screen and (max-width: ${breakpoints.sm})`,
  md: `@media screen and (max-width: ${breakpoints.md})`,
  lg: `@media screen and (min-width: ${breakpoints.md})`,
  lgMax: `@media screen and (max-width: ${breakpoints.md})`,
  xlMax: `@media screen and (max-width: ${breakpoints.xl})`,
}

export const topNavHeightInPx = 70
