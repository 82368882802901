import { useState } from 'react'

import { Field, Form, FormikProps } from 'formik'
import styled from 'styled-components'

import { Alert, Box } from 'app/components/design-system-components'
import {
  InputField,
  TextArea,
} from 'app/components/design-system-components/inputs'
import * as getters from 'app/components/design-system-components/theme/getters'
import { Text } from 'app/components/design-system-components/typography'
import { MessagePreview } from 'app/features/pm-messages/new-message/components/MessagePreview'
import { ModalFooter } from 'app/features/pm-messages/new-message/components/ModalFooter'
import {
  formFields,
  IBallotMessageForm,
} from 'app/features/teams/applications/share-applications/BallotSendMessageModal/form.utils'
import { FieldWrapper, SimpleFieldLabel } from 'app/forms/FieldWrapper'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const StyledFieldWrapper = styled(FieldWrapper)`
  margin: ${getters.spacing(7)} 0;
`

const StyledSimpleFieldLabel = styled(SimpleFieldLabel)`
  margin: ${getters.spacing(7)} 0;
`

export const FormBody = ({
  formBag: { status, values, isSubmitting },
  prospects,
}: {
  formBag: FormikProps<IBallotMessageForm>
  prospects: number
}) => {
  const [showPreview, setShowPreview] = useState<boolean>(false)

  return (
    <Form>
      {!!status && !!status.apiGeneralError && (
        <Alert
          variant="warningWithBg"
          mt={theme.space[3] + 'px'}
          actionIconBtnConfig={undefined}
        >
          {status.apiGeneralError}
        </Alert>
      )}
      <StyledSimpleFieldLabel required={true} label={'To'} />
      <Box>
        <Text fontWeight={theme.fontWeights.h3} color={theme.colors.deepBlue}>
          {prospects} prospects{' '}
        </Text>
      </Box>
      <StyledFieldWrapper
        id={formFields.property}
        name={formFields.property}
        label="Property"
      >
        <InputField
          id={formFields.property}
          name={formFields.property}
          inputProps={{
            placeholder: 'Add property (optional)',
            disabled: true,
          }}
        />
      </StyledFieldWrapper>

      {showPreview ? (
        <MessagePreview {...values} />
      ) : (
        <>
          <StyledFieldWrapper
            id={formFields.subject}
            label="Subject"
            required
            name={formFields.subject}
          >
            <Field name={formFields.subject} as={InputField} />
          </StyledFieldWrapper>

          <StyledFieldWrapper
            id={formFields.message}
            label="Message"
            required
            name={formFields.message}
          >
            <Field name={formFields.message} as={TextArea} rows={10} />
          </StyledFieldWrapper>
        </>
      )}

      <ModalFooter
        showPreview={showPreview}
        onPreviewClicked={() => setShowPreview(!showPreview)}
        sendDisabled={isSubmitting}
        selectAttachments={() => {}}
      />
    </Form>
  )
}
