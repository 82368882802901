import styled from 'styled-components'

export const LeadCaptureFormWrapper = styled.div`
  background: ${(props) => (props.isInModal ? 'none' : '#ffffff')};
  border: ${(props) => (props.isInModal ? 'none' : '1px solid #d8dce2')};
  box-shadow: ${(props) =>
    props.isInModal ? 'none' : '0px 12px 44px rgba(0, 0, 0, 0.08)'};
  border-radius: 10px;
  background-image: ${(props) => `url(${props.bgImage})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 110px;
  margin-left: ${(props) => (props.isInModal ? '0' : '30px')};
  max-width: 416px;
  text-align: center;
  ul {
    padding-left: 0;
    margin-bottom: 0;
  }
`

export const HumanDecoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 36px 0;

  .human-img-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 169px;
    height: 169px;
  }

  .human-img {
    position: absolute;

    &.img01 {
      left: -68px;
      top: -10px;
    }

    &.img03 {
      right: -81px;
      top: 96px;
    }

    &.img04 {
      right: -78px;
      top: -14px;
    }

    &.img05 {
      left: -100px;
      top: 80px;
    }
  }

  .human-center {
    position: relative;
    width: 169px;
    height: 169px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .human-img {
      &.img01 {
        left: -46px;
      }

      &.img03 {
        right: -45px;
      }

      &.img04 {
        right: -53px;
      }

      &.img05 {
        left: -59px;
      }
    }
  }
`

export const Padding = styled.div`
    padding ${(props) => (props.padding ? props.padding : 0)}
`

export const Heading = styled.h3`
  color: #252e3f;
  line-height: 31px;
  font-weight: bold;
  font-size: 26px;
  letter-spacing: -0.01em;
  text-align: center;
  margin: ${(props) => (props.margin ? props.margin : 0)};
`

export const SemiHeading = styled.h5`
  color: #252e3f;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: center;
`

export const Text = styled.p`
  color: ${(props) => (props.color ? props.color : '#252e3f')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 24)}px;
  letter-spacing: ${(props) => (props.lineSpacing ? props.lineSpacing : 0)};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  text-align: center;

  .black-tick {
    width: 13px;
    margin-right: 7px;
  }

  ~ p {
    margin-top: 2px;
  }
`

export const ExtraInfo = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: #252e3f;

  a {
    color: inherit;
    display: inline-block;
    margin-left: 8px;
    text-decoration: underline;
    transition: 0.2s ease-in color;

    &:hover {
      color: #ff5b77;
    }
  }
`

export const TextWithLine = styled.div`
  position: relative;
  text-align: center;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  span {
    background: white;
    color: #7c8188;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    padding: 0 12px;
    position: relative;
    text-align: center;
  }

  &:before {
    content: '';
    background: #d8dce2;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const LinkList = styled.li`
  display: inline-flex;
  align-items: center;
  width: 50%;
  margin-bottom: 12px;

  span {
    padding: 0 10px 0 9px;
    font-size: 18px;
    line-height: 25px;
  }

  .tick {
    width: 18px;
  }

  a {
    color: #252e3f;
    font-size: 16px;
    line-height: 22px;
    position: relative;
    text-align: left;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: #252e3f;
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.3s;
    }

    &:hover {
      .arrow-right {
        opacity: 1;
      }
      &:after {
        transform-origin: bottom left;
        transform: scaleX(1);
      }
    }
  }

  @media (max-width: 991px) {
    span {
      font-size: 16px;
    }
  }

  @media (max-width: 576px) {
    span {
      width: 138px;
      font-size: 14px;
    }
  }
`
export const CustomLink = styled.a`
  color: #3774c3;
  display: inline-block;
  line-height: 18px;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  font-weight: 600;
  font-size: 14px;
`
export const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: #f8f8f9;
  border: 1px solid #d8dce2;
  border-radius: 5px;

  input {
    border: none;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    padding-left: 124px;
    padding-right: 7px
    background: none;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #3e434c;
  }
  .copy-img-wrapper {
    width: 116px;
    text-align: center;
    background: #ddfdeb;
    padding: 16px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #008039;

    img {
      margin-right: 12px;
    }
  }
`
