import { connect } from 'react-redux'

import PersonalReferenceForm from 'app/profile/renter/personal_reference/personal_reference_form'
import {
  createPersonalReference,
  editPersonalReference,
  getPersonalReference,
  removePersonalReference,
} from 'app/sm/personal_reference/personal_reference_actions'

const mapStateToProps = ({ PersonalReference }) => ({
  personalReference: PersonalReference.personalReference,
  apiError: PersonalReference.error,
})

const mapDispatchToProps = (dispatch) => ({
  createPersonalReference: (formData) =>
    dispatch(createPersonalReference(formData)),
  editPersonalReference: (formData, id) =>
    dispatch(editPersonalReference(formData, id)),
  removePersonalReference: (id) => dispatch(removePersonalReference(id)),
  getPersonalReference: (id) => dispatch(getPersonalReference(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersonalReferenceForm)
