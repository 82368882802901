import { connect } from 'react-redux'

import TeamContactListContainer from 'app/sm/contact_list/container'
import { fetchContacts, fetchSearchContacts } from 'app/sm/team_contact/action'

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  fetchContacts: (teamId) => dispatch(fetchContacts(teamId)),
  fetchSearchContacts: (teamId, searchStr, from, size) =>
    dispatch(fetchSearchContacts(teamId, searchStr, from, size)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamContactListContainer)
