import React from 'react'

import qs from 'qs'
import { connect } from 'react-redux'
import styled from 'styled-components'

import headerImg01 from 'app/assets/icons/check-mail.png'
import { fetchAgencyInfo } from 'app/bond_cover/agency/agency_actions'
import { Box } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import { unsubscribeCategoriesConfig } from 'app/constants/unsubscribe.constants'
import {
  fetchPropertyOfferInfo,
  fetchSubscribeTargetInfo,
  sendResubscribeRequest,
  sendUnsubscribeFeedback,
} from 'app/dashboard/dashboard_actions'
import * as snugNotifier from 'app/services/snugNotifier'
import * as Form from 'app/shared_components/form_component'
import { ErrorMessage } from 'app/shared_components/helpers'
import { SNUG_PRIVACY_URL } from 'app/sm/helpers'

const UnsubscribeSurveyOptionsGroup = styled.div`
  .unsubscribeSurveyOptionsGroup {
    display: block !important;
    padding: 0px !important;
    .form-radio-group-controls {
      display: block;
    }
  }
`

const UnsubscribeSurveyQuestions = [
  // Old survey questions
  // { questionTitle: 'I have found a property already', answer: false, orderId: 1 },
  // { questionTitle: `The alerts aren't relevant`, answer: false, orderId: 2 },
  // { questionTitle: 'Other', answer: false, orderId: 3 }
  // New survey questions
  { questionTitle: 'Yes, via a Snug agent', ID: 4 },
  { questionTitle: 'Yes, elsewhere', ID: 5 },
  { questionTitle: 'No, just unsubscribe me', ID: 6 },
]

class UnsubscribeContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      property: {},
      team: {},
      token: '',
      subscribeTargetInfo: {},
      isLoadingUnsubscribeInfo: false,
      isLoadingDisplayData: false,
      answers: {},
      surveyAnswers: {},
      loadingError: '',
      pendingSubmit: false,
      pendingResubscribe: false,
      submitComplete: false,
      categoryConfig: {},
    }
  }

  componentDidMount() {
    const token = this.parseToken()
    this.setState({ token })
    const categoryConfig = Object.values(unsubscribeCategoriesConfig).find(
      (conf) => window.location.href.includes(`/${conf.path}/`),
    )

    this.setState({
      categoryConfig,
    })

    this.getUnsubscribeInfoWithToken(token)
  }

  onClickCheckBox = (category) => {
    const { answers = {} } = this.state
    answers[category] = !answers[category]
    this.setState({ answers })
  }

  onClickRadioGroup = (ID) => {
    const { surveyAnswers = {} } = this.state

    UnsubscribeSurveyQuestions.forEach((question) => {
      if (question.ID === ID) {
        surveyAnswers[question.ID] = !surveyAnswers[question.ID]
      } else {
        surveyAnswers[question.ID] = false
      }
    })

    this.setState({ surveyAnswers })
  }

  onClickSave = () => {
    const { sendUnsubscribeFeedback } = this.props
    const { token, answers, categoryConfig } = this.state

    if (categoryConfig.validate && !this.selectedUnsubscribeType()) {
      snugNotifier.error(
        'Please select the email(s) you would like to stop receiving',
      )
      return
    }

    let payload = {
      unsubscribeCategories: answers,
    }

    if (categoryConfig.hasReason) {
      const unsubscribeReason = this.getUnsubscribeReason()
      payload = {
        ...payload,
        reason: unsubscribeReason,
      }
    }

    this.setState({ pendingSubmit: true })

    sendUnsubscribeFeedback(token, payload)
      .then(() => {
        this.setState({ submitComplete: true })
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
      .finally(this.setState({ pendingSubmit: false }))
  }

  onClickUndo = () => {
    const { sendResubscribeRequest } = this.props
    const { token, answers } = this.state

    this.setState({ pendingResubscribe: true })
    sendResubscribeRequest(token, { unsubscribeCategories: answers })
      .then(() => {
        this.setState({ submitComplete: false })
        snugNotifier.success('Resubscribed successfully!')
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
      .finally(this.setState({ pendingResubscribe: false }))
  }

  getUnsubscribeInfoWithToken = (token) => {
    const { fetchSubscribeTargetInfo } = this.props

    this.setState({ isLoadingUnsubscribeInfo: true })
    fetchSubscribeTargetInfo(token)
      .then(({ subscribeTargetInfo }) => {
        this.setState({ subscribeTargetInfo })
      })
      .catch((error) => {
        this.setState({ loadingError: error })
      })
      .finally(this.setState({ isLoadingUnsubscribeInfo: false }))
  }

  getUnsubscribeReason = () => {
    const { surveyAnswers } = this.state
    let unsubscribeReason = 0
    UnsubscribeSurveyQuestions.forEach((question) => {
      if (surveyAnswers[question.ID] === true) {
        unsubscribeReason = question.ID
      }
    })
    return unsubscribeReason
  }

  parseToken = () => {
    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    return curQueries && curQueries.token
  }

  selectedUnsubscribeType = () => {
    const { answers = {}, categoryConfig = {} } = this.state
    let unsubscribeTypeSelected = false
    categoryConfig.options?.forEach((question) => {
      if (answers[question.id] === true) {
        unsubscribeTypeSelected = true
      }
    })
    return unsubscribeTypeSelected
  }

  render() {
    const {
      loadingError = '',
      answers = {},
      surveyAnswers = {},
      pendingSubmit = false,
      submitComplete = false,
      categoryConfig = {},
    } = this.state

    if (!!loadingError) {
      return <ErrorMessage error={loadingError} />
    }

    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first fw600">
          {!submitComplete && (
            <div>
              <h2>Communication Preferences</h2>
              <div className="surveyQuestionContainer mt30">
                {!!categoryConfig.title && <h3>{categoryConfig.title}</h3>}
                <p className="mt10 mb10">
                  Please select the emails you would like to stop receiving:
                </p>
                <Box mb={3}>
                  {categoryConfig.options?.map((opt, index) => {
                    return (
                      <Form.Checkbox
                        key={index}
                        id={opt.id}
                        label={opt.questionTitle}
                        checked={answers[opt.id]}
                        checkboxLabelSpanClassName="fs16"
                        onChange={() => {
                          this.onClickCheckBox(opt.id)
                        }}
                      />
                    )
                  })}
                </Box>
                {categoryConfig.showSurvey && (
                  <>
                    <p className="mt30 mb0">
                      Have you found a rental property?
                    </p>
                    <UnsubscribeSurveyOptionsGroup>
                      <Form.RadioGroup
                        label=""
                        id="rentalPropertySurvey"
                        componentClassName="unsubscribeSurveyOptionsGroup"
                      >
                        {UnsubscribeSurveyQuestions.map((question, index) => {
                          return (
                            <Form.Checkbox
                              key={index}
                              id={question.ID}
                              label={question.questionTitle}
                              value={question.ID}
                              checked={surveyAnswers[question.ID]}
                              onChange={() =>
                                this.onClickRadioGroup(question.ID)
                              }
                              checkboxLabelSpanClassName="fs16"
                            />
                          )
                        })}
                      </Form.RadioGroup>
                    </UnsubscribeSurveyOptionsGroup>
                  </>
                )}
                <p className="fw400">
                  Your privacy is our priority. We do not sell your data. With
                  your consent we refer your information to a rental service
                  provider. View our{' '}
                  <a
                    href={SNUG_PRIVACY_URL}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
                <h3>Snug News</h3>
                <p className="fw400 mt10">
                  To unsubscribe from important Snug updates and the monthly
                  newsletter please email:{' '}
                  <a
                    href="mailto:help@snug.com?subject=Email unsubscribe request"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    help@snug.com
                  </a>
                </p>
              </div>

              <div className="snooze-btn-container mb30">
                <Display.GenericButton
                  isLoading={pendingSubmit}
                  disabled={pendingSubmit}
                  onClick={this.onClickSave}
                  componentClass="col-sm-6"
                  size="large"
                >
                  Save Preferences
                </Display.GenericButton>
              </div>
            </div>
          )}
          {submitComplete && (
            <div>
              <div className="ilustration-box">
                <img src={headerImg01} alt="envelope" />
              </div>
              <h2 className="fw400 text-align-center">Thank you!</h2>
              <p className="text-align-center mt30 mb0 fw400">
                Your preferences have been saved.
              </p>
              <div className="display-flex align-items-center justify-content-center">
                <div>
                  <p className="fw400 mt30">
                    Changed your mind? &nbsp;
                    <span
                      className="blue-link-style undo-snooze"
                      onClick={this.onClickUndo}
                      id={'undo'}
                    >
                      Undo
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = null

const mapDispatchToProps = (dispatch) => ({
  sendUnsubscribeFeedback: (token, formData) =>
    dispatch(sendUnsubscribeFeedback(token, formData)),
  fetchPropertyOfferInfo: (offerID) =>
    dispatch(fetchPropertyOfferInfo(offerID)),
  fetchSubscribeTargetInfo: (token) =>
    dispatch(fetchSubscribeTargetInfo(token)),
  fetchAgencyInfo: (teamID) => dispatch(fetchAgencyInfo(teamID)),
  sendResubscribeRequest: (token, formData) =>
    dispatch(sendResubscribeRequest(token, formData)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnsubscribeContainer)
