import {
  breakpoints,
  fontSizesWithAliases,
  fontWeightsWithAliases,
} from 'app/components/design-system-components/styles/variables'

export const theme = {
  breakpoints: Object.values(breakpoints),
  space: [
    0, 4, 6, 8, 12, 16, 20, 24, 32, 64, 128, 256, 512, 40, 240, 300, 13.75, 36,
    100,
  ],
  dividedSpace: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100],
  baseSpace: 8,
  fontSizes: fontSizesWithAliases,
  fontWeights: fontWeightsWithAliases,
  lineHeights: {
    solid: 1,
    title: 1.25,
    link: 1.3,
    encrption: 1.4,
    copy: 1.5,
    middle: 1.225,
  },
  display: {
    flex: 'flex',
    block: 'block',
    inline: 'inline',
    'inline-block': 'inline-block',
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  fonts: {
    serif: 'athelas, georgia, times, serif',
    sansSerif:
      '-apple-system, BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial, sans-serif',
  },
  borders: [
    0,
    '1px solid',
    '2px solid',
    '4px solid',
    '8px solid',
    '16px solid',
    '32px solid',
  ],
  borderRadii: {
    table: '4px 4px 0px 0px',
    attachment: '4px 0 0 4px',
  },
  radii: [0, 2, 4, 16, 9999, '100%', 5, '50%', 10, 15, 36],
  height: [
    0, 4, 8, 12, 16, 20, 24, 32, 64, 128, 256, 512, 48, 18, 22, 36, 14, 96, 40,
    75,
  ],
  width: [
    0, 4, 8, 12, 16, 20, 24, 32, 64, 128, 256, 512, 140, 48, 186, 192, 320, 18,
    100, 480, 22, 270, 175, 240, 340, 52, 36, 14, 90, 120,
  ],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  colors: {
    black: '#000',
    'near-black': '#111',
    'dark-gray': '#333',
    'mid-gray': '#555',
    legacyGreyBlue: 'rgba(251, 251, 253, 0.99)',
    gray: '#777',
    grey1: '#f8f8f9',
    gray100: '#F5F5F5',
    grey200: '#e6e6e6',
    gray200: '#E5E5E5',
    gray300: '#CCCCCC',
    gray400: '#999999',
    gray500: '#808080',
    gray600: '#666666',
    gray700: '#4D4D4D',
    gray800: '#333333',
    gray900: '#1A1A1A',
    success100: '#EDFCF4',
    success200: '#D2F9E3',
    success300: '#A6F2C7',
    success600: '#1ab25d',
    success700: '#138646',
    success800: '#0E6032',
    warning100: '#FEF8EC',
    warning200: '#FEEE8E',
    warning400: '#F4B63E',
    warning500: '#F2A818',
    warning600: '#C1830B',
    warning700: '#916208',
    borderGray: '#F5F5F5',
    silver: '#999',
    'light-silver': '#aaa',
    'moon-gray': '#ccc',
    'light-gray': '#eee',
    'near-white': '#f4f4f4',
    white: '#fff',
    transparent: 'transparent',
    green: '#37C977',
    blue: '#5A9BEE',
    textGray: '#68696B',
    searchGray: '#cfcfcf',
    filterGray: '#bdbcbf',
    searchInputGray: '#212025',
    primary600: '#CC0023',
    primary500: '#FF002C',
    primary100: '#FFEBEE',
    primary300: '#FF5B77',
    primary400: '#FF3356',
    error100: '#FCEDED',
    error400: '#E44E4E',
    error500: '#DF3030',
    error600: '#B11B1B',
    blacks: [
      'rgba(0,0,0,.0125)',
      'rgba(0,0,0,.025)',
      'rgba(0,0,0,.05)',
      'rgba(0,0,0,.1)',
      'rgba(0,0,0,.2)',
      'rgba(0,0,0,.3)',
      'rgba(0,0,0,.4)',
      'rgba(0,0,0,.5)',
      'rgba(0,0,0,.6)',
      'rgba(0,0,0,.7)',
      'rgba(0,0,0,.8)',
      'rgba(0,0,0,.9)',
    ],
    whites: [
      'rgba(255,255,255,.0125)',
      'rgba(255,255,255,.025)',
      'rgba(255,255,255,.05)',
      'rgba(255,255,255,.1)',
      'rgba(255,255,255,.2)',
      'rgba(255,255,255,.3)',
      'rgba(255,255,255,.4)',
      'rgba(255,255,255,.5)',
      'rgba(255,255,255,.6)',
      'rgba(255,255,255,.7)',
      'rgba(255,255,255,.8)',
      'rgba(255,255,255,.9)',
    ],
    paleBlue: '#EFF6FD',
    deepBlue: '#3774C3',
    skyBlue: '#5A9BEE',
    lightBlue: '#ADD8E6',
    overlay: 'rgba(0,0,0,0.4)',
    watermelon: '#ff5b77',
    dropdownDroppedShadow: '#86868640',
    pdfGray: '#686d71',
    searchAndSelectBoxShadow: '#0000000d',
    bgCheckGrey: '#F6F9FD',
    yellow: '#FFF389',
  },
  bannerHeightInPx: 56,
  logoWithInPx: 300,
  streamlineSpinnerWidthHeightPx: 42,
}

export default theme
