import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import { Button } from 'app/components/design-system-components/Button'
import Flex from 'app/components/design-system-components/Flex'
import { Text } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const SelectGroupStyle = styled(Box)`
  margin-bottom: 24px;
`

const SelectButtonStyle = styled(Button)`
  margin-right: 8px;
`

export const ButtonSelectGroup = ({
  selected, // default selected id
  onChange,
  selectedVariant = 'outlineGreen',
  defaultVariant = 'outlineGray',
  options = [],
  label = 'Undefined',
}) => {
  const [groupOptions, setGroupOptions] = useState([])

  useEffect(() => {
    const mappedOptions = options.map((option) => {
      const isSelected = option.optionMeta.id === selected

      const variantIfSelected =
        option.optionMeta.selectedVariant || selectedVariant
      const variantIfNotSelected =
        option.optionMeta.notSelectedVariant || defaultVariant

      return {
        ...option,
        optionMeta: {
          ...option.optionMeta,
          isSelected: isSelected,
          variant: isSelected ? variantIfSelected : variantIfNotSelected,
        },
      }
    })

    setGroupOptions(mappedOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const selectOption = (select) => {
    selected = select.option
    onChange(select)
  }

  const renderOptions = () => {
    return (
      <Flex>
        {groupOptions.map((option) => {
          return (
            <SelectButtonStyle
              flex="1 1 0px"
              key={option.optionMeta.id}
              variant={option.optionMeta.variant}
              onClick={() => selectOption(option)}
            >
              {option.option}
            </SelectButtonStyle>
          )
        })}
      </Flex>
    )
  }

  const optionsElement = renderOptions()

  return (
    <SelectGroupStyle>
      <Box>
        <Text
          fontSize={theme.fontSizes[5] + 'px'}
          fontWeight={theme.fontWeights[3]}
        >
          {label}
        </Text>
      </Box>

      {optionsElement}
    </SelectGroupStyle>
  )
}
