import { connect } from 'react-redux'

import { fetchTeamProperties } from 'app/bond_cover/agency/agency_actions'
import { CLEAR_ERROR } from 'app/sm/ppp/property_actions'
import * as ACTIONS from 'app/sm/remarketing/remarketing_actions'
import RemarketingContainer from 'app/sm/remarketing/remarketing_container'

const mapStateToProps = ({}) => ({})

const clearError = () => ({ type: CLEAR_ERROR })

const mapDispatchToProps = (dispatch) => ({
  clearError: () => dispatch(clearError()),
  sendMessage: (message, messageSent, failure) =>
    dispatch(ACTIONS.sendMessage(message, messageSent, failure)),
  fetchRecommendedProperties: (
    agencyGUID,
    propGUID,
    limit,
    onFetchSuccess,
    onFetchFailure,
  ) =>
    dispatch(
      ACTIONS.fetchRecommendedProperties(
        agencyGUID,
        propGUID,
        limit,
        onFetchSuccess,
        onFetchFailure,
      ),
    ),
  fetchTeamProperties: (teamGUID, filters) =>
    dispatch(fetchTeamProperties(teamGUID, filters)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemarketingContainer)
