import React from 'react'

import { Modal } from 'app/components/design-system-components'

function ConfirmDeleteBuildingModal({
  confirmDeleteBuilding,
  closeConfirmDeleteBuildingModal,
}) {
  return (
    <Modal
      toggleModal={closeConfirmDeleteBuildingModal}
      modalHeading="Remove building"
      modalBody={
        <div>
          Are you sure you want to delete the building and all its associations?
        </div>
      }
      primaryLabel="Yes"
      primaryAction={confirmDeleteBuilding}
      secondaryLabel="Cancel"
      secondaryAction={closeConfirmDeleteBuildingModal}
    />
  )
}

export default ConfirmDeleteBuildingModal
