import $ from 'jquery'

import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getPropertyAttributes = (success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}sm/properties/attributes`,
    success,
    error,
  })
}

export const getProperty = (id) => {
  const url = `${apiBaseUrl}sm/properties/${id}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (property) => ({ ok: true, property }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const createProperty = (data, success, error) => {
  const moreAmenities =
    data.property.otherAmenities || data.property.moreAmenities
  const formatted = (property) =>
    Object.assign({}, property, {
      commonAmenities:
        typeof property.commonAmenities === 'string'
          ? property.commonAmenities
          : JSON.stringify(property.commonAmenities),
      otherAmenities:
        typeof moreAmenities === 'string'
          ? moreAmenities
          : JSON.stringify(moreAmenities),
    })

  const url = `${apiBaseUrl}sm/properties`

  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: url,
    data: JSON.stringify({
      property: formatted(data.property),
      address: data.address,
      archived: data.archived,
      ...(data.managerID && {
        managerID: data.managerID,
      }),
    }),
    success,
    error,
  })
}

export const updateProperty = (id, data, success, error) => {
  const moreAmenities =
    data.property.otherAmenities || data.property.moreAmenities

  const formatted = (property) =>
    Object.assign({}, property, {
      commonAmenities:
        typeof property.commonAmenities === 'string'
          ? property.commonAmenities
          : JSON.stringify(property.commonAmenities),
      otherAmenities:
        typeof moreAmenities === 'string'
          ? moreAmenities
          : JSON.stringify(moreAmenities),
    })

  const url = `${apiBaseUrl}sm/properties/${id}`

  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'PUT',
    url: url,
    data: JSON.stringify({
      property: formatted(data.property),
      address: data.address,
      ...(data.managerID && {
        managerID: data.managerID,
      }),
    }),
    success,
    error,
  })
}

export const uploadPhoto = (propertyId, formData) => {
  const url = `${apiBaseUrl}sm/properties/${propertyId}/images`
  const options = {
    method: 'POST',
    body: formData,
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (_) => ({ ok: true, _ }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const setMainImage = (propertyId, photoId) => {
  const url = `${apiBaseUrl}sm/properties/${propertyId}/images/setmain`
  const options = {
    method: 'PUT',
    body: JSON.stringify({
      guidID: photoId,
    }),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (_) => ({ ok: true, _ }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deletePhoto = (propertyId, imageId) => {
  const url = `${apiBaseUrl}sm/properties/${propertyId}/images/${imageId}`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (_) => ({ ok: true, _ }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
