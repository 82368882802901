import * as React from 'react'

function SvgAttachFileRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 6.748v10.58c0 2.09-1.53 3.95-3.61 4.15A3.993 3.993 0 018 17.498V5.138c0-1.31.94-2.5 2.24-2.63A2.5 2.5 0 0113 4.998v10.5c0 .55-.45 1-1 1s-1-.45-1-1v-8.75c0-.41-.34-.75-.75-.75s-.75.34-.75.75v8.61c0 1.31.94 2.5 2.24 2.63a2.5 2.5 0 002.76-2.49V5.168c0-2.09-1.53-3.95-3.61-4.15a3.998 3.998 0 00-4.39 3.98v12.27c0 2.87 2.1 5.44 4.96 5.71 3.29.3 6.04-2.26 6.04-5.48V6.748c0-.41-.34-.75-.75-.75s-.75.34-.75.75z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgAttachFileRounded
