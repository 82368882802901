export const trimStringLengthBasedOnWindowWidth = (inputString, trimLength) => {
  const { innerWidth: width } = window
  let outputString = inputString
  if (width > 576) {
    if (inputString.length > trimLength) {
      outputString = inputString.slice(0, trimLength)
      outputString = outputString.concat('...')
    }
    return outputString
  }
  return outputString
}

export const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export const capitalizeFirstLetterOfString = (string = '') => {
  return string.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) =>
    match.toUpperCase(),
  )
}

export const removeSpaces = (str) => {
  return str.replace(/\s+/g, '')
}

export const isStringAValidURL = (string) => {
  const res = string.match(
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/,
  )
  return res !== null
}

export const isAttachmentExistMessage =
  'A document with this name already exists. Continue?'

export const consoleCloudFeatureText = 'Console'

export const PROPERTY_CONDITION_ERROR_TEXT =
  'You must accept the property condition to continue'
export const IDENTITY_POINT_ERROR_TEXT =
  'Typically, 100 points required for continuing further. Adding more details will improve your Match Score and chances of a successful application.'
export const IDENTITY_POINT_TOAST_ERROR =
  'This agency requires 100 points of ID'
export const IDENTITY_POINT_THRESHOLD_VALUE = 100

export const ENHANCED_DISCLOSURES_ERROR =
  'Please answer required disclosures with details'

export const commaSeparatedString = (requiredArray, keyToBeExtracted) => {
  const onlyValue = requiredArray.map((member) => member[keyToBeExtracted])
  let str = onlyValue.join(', ').replace(/,([^,]*)$/, ' and $1')
  return str
}

export const currentEmploymentStatus = (reputationPreference) => {
  if (reputationPreference.optInEmployed) {
    return 'Employed'
  } else if (reputationPreference.optInRetired) {
    return 'Retired'
  } else if (reputationPreference.optInOther) {
    return 'Other'
  } else {
    return ''
  }
}

export const firstNameAndFirstLetter = (fullName) => {
  const splitName = fullName.split(' ')
  const hasSurname = splitName.length > 1
  if (hasSurname) {
    let firstName = splitName[0]
    return `${firstName} ${splitName[1][0]}.`
  }
  return fullName
}

export const generateUniqueId = (() => {
  let postFixNumber = 0

  return () => {
    postFixNumber++
    return `${new Date().valueOf()}_${postFixNumber}`
  }
})()

export const trimAndAddEllipsesPastSpecifiedLength = (
  text = '',
  specifiedLength,
) => {
  return text.length > specifiedLength
    ? text.substring(0, specifiedLength - 3) + '...'
    : text
}

export const covertBoolStringToBool = (boolStr) => {
  return boolStr === 'true'
}

export const covertBoolToString = (bool) => (bool ? 'true' : 'false')

export async function copyToClipboard(
  textToCopy,
  successFunction,
  failureFunction,
) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy)
    if (successFunction) {
      successFunction()
    }
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement('textarea')
    textArea.value = textToCopy

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = 'absolute'
    textArea.style.left = '-999999px'

    document.body.prepend(textArea)
    textArea.select()

    try {
      document.execCommand('copy')
      successFunction()
    } catch (error) {
      console.error(error)
      if (failureFunction) {
        failureFunction()
      }
    } finally {
      textArea.remove()
    }
  }
}
