import React from 'react'

import { Link } from 'react-router-dom'

import SearchInline from 'app/sm/common/search-inline'
import * as MockData from 'app/sm/property_transactions/property_transactions_mock_data'

class PropertyTransactions extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { propertyId } = this.props.match.params
    this.props.fetchProperty(propertyId)
  }

  render() {
    const { property } = this.props
    const propertyId = property.guidID

    return (
      <div className="sm-property-transactions">
        <div className="section-title xs-row pb20 ">
          <div className="left">
            <Link to={'/'} className="lh1">
              <h3 className="lh1">
                <i className="icon-arrow-left fs16 pt2 pr10 xs-ibm"></i>
                <span className="ibm lh1">Transactions (12)</span>
              </h3>
            </Link>
          </div>
          <div className="right">
            <SearchInline placeholder="Search..." className="xs-hide" />
            <button className="btn btn-small-xs wa ml20">
              <i className="icon-add left ibm fs13"></i>
              <span className="ibm">
                Add <span className="xs-hide">Transaction</span>
              </span>
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-4 mb10">
            <div className="input-box single wa xs-w100p ibm">
              <select className="wa xs-w100p">
                <option>All Transactions</option>
                <option>Income Transactions</option>
                <option>Expense Transactions</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6 col-md-8">
            <div className="row">
              <div className="col-sm-6 mb10">
                <div className="table">
                  <div className="cell-m pr15 pt10 gray-color">From</div>
                  <div className="cell-m-100">
                    <div className="input-box single">
                      <div className="prefix">
                        <i className="icon-date"></i>
                      </div>
                      <input type="text" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mb10">
                <div className="table">
                  <div className="cell-m pr15 pt10 gray-color">To</div>
                  <div className="cell-m-100">
                    <div className="input-box single">
                      <div className="prefix">
                        <i className="icon-date"></i>
                      </div>
                      <input type="text" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm-income-expenses-summary mt20">
          <div>
            <div className="bar green-bg mb10"></div>
            <div className="value fs40">
              <span className="fs24">$</span>29,981
            </div>
            <div className="title">Income</div>
          </div>
          <div>
            <div className="bar red-bg mb10"></div>
            <div className="value fs40">
              <span className="fs24">$</span>23,475
            </div>
            <div className="title">Expense</div>
          </div>
          <div>
            <div className="icon">
              <i className="icon-add green-color"></i>
            </div>
            <div className="value fs40">
              <span className="fs24">$</span>5,861
            </div>
            <div className="title">Profit</div>
          </div>
        </div>

        <table className="style2">
          <thead>
            <tr>
              <td>Name</td>
              <td>Uploaded by</td>
              <td>Date</td>
              <td>Amount</td>
            </tr>
          </thead>
          <tbody>
            {MockData.Transactions.map((tran, index) => (
              <tr key={index}>
                <td>{tran.name}</td>
                <td>
                  {tran.avatarImage != '' && (
                    <div
                      className="avatar avatar-xsmall mr10"
                      style={{ backgroundImage: `url('${tran.avatarImage}')` }}
                    ></div>
                  )}
                  {tran.avatarIcon != '' && (
                    <div
                      className={`avatar avatar-xsmall mr10 ${tran.avatarIconColor}-bg-light-rgba ${tran.avatarIconColor}-color`}
                    >
                      <i className={`icon-${tran.avatarIcon}`}></i>
                    </div>
                  )}
                  {tran.userFirstname} {tran.userLastname.charAt(0)}.
                  {tran.userSufix != '' && <span> ({tran.userSufix})</span>}
                </td>
                <td>on {tran.date}</td>
                <td>
                  {tran.amountIncome != '' && (
                    <div>
                      <i className="icon-add green-color fs14 mr10"></i>
                      <span>${tran.amountIncome}</span>
                    </div>
                  )}
                  {tran.amountExpense != '' && (
                    <div>
                      <i className="icon-minus red-color fs14 mr10"></i>
                      <span>${tran.amountExpense}</span>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="2">
                <a href="#1" className="active">
                  1
                </a>
                <a href="#2">2</a>
                <a href="#3">3</a>
                <a href="#4">4</a>
                <a href="#5">5</a>
              </td>
              <td colSpan="2">
                Showing <b>0-10</b> out of 71
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    )
  }
}

export default PropertyTransactions
