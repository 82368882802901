//snug support
export const snugPricing = 'https://snug.com/pricing'

export const backgroundCheckLearnMore =
  'https://help.snug.com/hc/en-us/articles/360001596936-Property-Manager-How-do-I-process-a-Background-Check-'
export const domainIntegrationLearnMore =
  'https://help.snug.com/hc/en-us/articles/5874183741455'
export const ticaIntegrationLearnMore =
  'https://help.snug.com/hc/en-us/articles/6079505229327'
export const importEnquirersLearnMore =
  'https://help.snug.com/hc/en-us/articles/9274112623375'
export const keysManagingLearnMore =
  'https://help.snug.com/hc/en-us/articles/4410423217423-Managing-Keys-on-Snug'
