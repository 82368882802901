import React from 'react'

import * as Display from 'app/components/display/display'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import { FeatureFlag } from 'config/features'

export const defaultCommentTemplates = [
  {
    text: 'Has pets,',
    picked: false,
    index: 0,
  },
  {
    text: 'Has Kids,',
    picked: false,
    index: 1,
  },
  {
    text: 'Needs storage,',
    picked: false,
    index: 2,
  },
  {
    text: 'Needs clean,',
    picked: false,
    index: 3,
  },
  {
    text: 'Moving ASAP,',
    picked: false,
    index: 4,
  },
  {
    text: 'Property Not Suitable,',
    picked: false,
    index: 5,
  },
  {
    text: 'Will Apply,',
    picked: false,
    index: 6,
  },
  {
    text: 'Applied,',
    picked: false,
    index: 7,
  },
]

export const defaultCommentTemplatesPrivate = [
  {
    text: '!-!',
    picked: false,
    index: 8,
  },
  {
    text: '*+* ',
    picked: false,
    index: 9,
  },
]

class AddCommentModal extends React.Component {
  constructor(props) {
    super(props)
    const { renterInfo } = props
    this.state = {
      comment: renterInfo.comment || '',
      sendClicked: false,
      firstName: renterInfo.firstName,
      lastName: renterInfo.lastName,
      mobilePhone: renterInfo.mobilePhone,
      email: renterInfo.email,
      didNotAttend: renterInfo.didNotAttend,
      checkIn: renterInfo.checkIn,
      interested: renterInfo.interested,
      commentTemplates: defaultCommentTemplates,
    }
  }

  componentDidMount() {
    this.props.clearError()
  }

  onPillClicked = (index) => {
    const { commentTemplates } = this.state
    const targetTemplate = commentTemplates.find(
      (commentTemplate) => commentTemplate.index === index,
    )
    this.setState({
      comment: `${this.state.comment} ${targetTemplate.text}`,
    })
  }

  update = (field, value) => {
    this.setState({ [field]: value })
  }

  render() {
    const { showCommentModal, updateRenterStatusComment, error } = this.props
    const { comment, sendClicked, commentTemplates } = this.state
    const disabled = comment.trim().length === 0

    return (
      <div className="modal-fullscreen modal-layout-flex-container">
        <ModalContentWrapper>
          <div className="modal-close">
            <i
              className="fa fa-times"
              onClick={() => showCommentModal(false)}
            />
          </div>
          <div className="modal-header no-border lr-modal-pad">
            <h4 className="pl10 pr10">Add a comment</h4>

            <div className="mt10">
              <div className="input-box">
                <textarea
                  rows="5"
                  placeholder="Comment"
                  value={comment}
                  onChange={(e) => this.update('comment', e.target.value)}
                />
              </div>
            </div>
            <div className="display-flex flex-wrap-wrap">
              {FeatureFlag.ViewingCommentTemplates.isOn &&
                commentTemplates.map((commentTemplate) => {
                  return (
                    <Display.Pill
                      key={commentTemplate.index}
                      text={commentTemplate.text}
                      onClick={() => this.onPillClicked(commentTemplate.index)}
                    />
                  )
                })}
            </div>

            {error && <div className="alert alert-danger">{error}</div>}
          </div>

          <div className="modal-footer no-border mt10">
            <div className="pb20 col-sm-4 div-order-2 pt15 text-center">
              <label
                className="gray-dark-color textButton"
                onClick={() => showCommentModal(false)}
              >
                Cancel
              </label>
            </div>
            <div className="col-sm-8 div-order-1">
              <button
                disabled={disabled}
                className="btn"
                onClick={() => updateRenterStatusComment(this.state)}
              >
                Save
                <i className={sendClicked ? 'fa fa-spinner fa-pulse' : ''} />
              </button>
            </div>
          </div>
        </ModalContentWrapper>
      </div>
    )
  }
}

export default AddCommentModal
