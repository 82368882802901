import React from 'react'

import PropTypes from 'prop-types'

const ProgressBar = (props) => {
  let { value = 0 } = props

  // clamp it to [0, 100]
  value = Math.min(Math.max(0, value), 100)
  // round the value
  value = Math.round(value)

  let color
  if (value < 75) {
    color = '#6c757d'
  } else {
    color = '#5cb85c'
  }

  return (
    <div className="progress mb0" style={{ minWidth: '80px' }}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{
          width: `${value}%`,
          backgroundColor: `${color}`,
        }}
      />
    </div>
  )
}

export const ProfileCompletenessComponent = ({ value = 0 }) => {
  return (
    <main className="display-flex">
      <h1 className="font-size-57 line-height-1 grey-text">{value}%</h1>
      <div className="mt10">
        <div className="ml5">
          <ProgressBar value={value} />
        </div>
        <h5 className="grey-text">&nbsp;complete</h5>
      </div>
    </main>
  )
}

export const SecondaryProfileCompletenessComponent = ({ value = 0 }) => {
  return (
    <main className="display-flex mt5">
      <div className="progress-bar-container ml5 mr5 mb10">
        <ProgressBar value={value} />
      </div>
      <h5 className="grey-text">{value}% completed profile</h5>
    </main>
  )
}

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
}

ProgressBar.defaultProps = {
  value: 0,
}

export default ProgressBar
