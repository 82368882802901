export const completenessAttributesIds = {
  identity: 'identity',
  income: 'income',
  rentalReference: 'rentalReference',
  employment: 'employment',
}

export const completenessIds = {
  pending: 0,
  success: 1,
  error: 2,
}

export const IDENTITY_DOCS_RECEIVED =
  '100 Points of ID provided for primary applicant'
export const IDENTITY_DOCS_NOT_RECEIVED =
  '100 points of ID not provided for primary applicant'
export const INCOME_RECEIVED =
  'Income details provided for primary applicant (3 payslips, 1 bank statement)'
export const INCOME_INCOMPLETE =
  'Income details not provided for primary applicant (3 payslips, 1 bank statement)'
export const REFERENCE_RECEIVED =
  "Reference received for primary applicant's most recent address"
export const REFERENCE_INCOMPLETE =
  "Reference not received for primary applicant's most recent address"
export const EMPLOYMENT_VERIFIED =
  'Current employment verified for primary applicant'
export const EMPLOYMENT_VERIFICATION_INCOMPLETE =
  'Current employment not verified for primary applicant'

export const completenessAttributesConfig = {
  [completenessAttributesIds.identity]: {
    label: 'ID',
    statusMessage: {
      [completenessIds.success]: IDENTITY_DOCS_RECEIVED,
      [completenessIds.pending]: IDENTITY_DOCS_NOT_RECEIVED,
      [completenessIds.error]: IDENTITY_DOCS_NOT_RECEIVED,
    },
  },
  [completenessAttributesIds.income]: {
    label: 'IN',
    statusMessage: {
      [completenessIds.success]: INCOME_RECEIVED,
      [completenessIds.pending]: INCOME_INCOMPLETE,
      [completenessIds.error]: INCOME_INCOMPLETE,
    },
  },
  [completenessAttributesIds.rentalReference]: {
    label: 'RR',
    statusMessage: {
      [completenessIds.success]: REFERENCE_RECEIVED,
      [completenessIds.pending]: REFERENCE_INCOMPLETE,
      [completenessIds.error]: REFERENCE_INCOMPLETE,
    },
  },
  [completenessAttributesIds.employment]: {
    label: 'EM',
    statusMessage: {
      [completenessIds.success]: EMPLOYMENT_VERIFIED,
      [completenessIds.pending]: EMPLOYMENT_VERIFICATION_INCOMPLETE,
      [completenessIds.error]: EMPLOYMENT_VERIFICATION_INCOMPLETE,
    },
  },
}

export const completenessStatusToDotVariant = {
  [completenessIds.success]: 'success',
  [completenessIds.pending]: 'pending',
  [completenessIds.error]: 'error',
}

export const completenessIdsToApplicationAttributes = {
  [completenessAttributesIds.identity]: 'identityDocsCompleteness',
  [completenessAttributesIds.income]: 'incomeCompleteness',
  [completenessAttributesIds.rentalReference]: 'rentalReferenceCompleteness',
  [completenessAttributesIds.employment]: 'employmentCompleteness',
}

export const getApplicationCompletenessConfig = (application) => {
  return Object.entries(completenessAttributesConfig).map(([id, config]) => {
    const completeness = application[completenessIdsToApplicationAttributes[id]]
    const variant = completenessStatusToDotVariant[completeness]
    const message = config.statusMessage[completeness]
    return {
      label: config.label,
      variant,
      message,
    }
  })
}
