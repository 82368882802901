import { LOCATION_CHANGE } from 'connected-react-router'

import {
  RECEIVE_COMPLETENESS,
  RECEIVE_ERROR,
  RECEIVE_PDF,
  RECEIVE_PREFERENCE,
  RECEIVE_RENTAL_REPUTATION,
} from 'app/sm/rental_reputation/actions'

const _defaultState = {
  rentalReputation: {},
  completeness: {
    accountRegistered: false,
    rentalHistory: false,
    income: false,
    backgroundCheck: false,
    employmentDetails: false,
    emergencyContact: false,
    pets: false,
    documents: true,
    creditCard: false,
    personalReference: false,
    percentage: 0,
  },
  preference: {
    optOutPets: false,
    optOutProfileAvatar: false,
  },
  error: '',
}

const RentalReputationReducer = (state = _defaultState, action) => {
  let { rentalReputation, completeness, pdf, preference, error, type } = action
  Object.freeze(state)
  let newState = Object.assign({}, state)
  switch (type) {
    case RECEIVE_RENTAL_REPUTATION:
      return Object.assign(newState, { rentalReputation })
    case RECEIVE_COMPLETENESS:
      return Object.assign(newState, { completeness })
    case RECEIVE_PDF:
      return Object.assign(newState, { pdf })
    case RECEIVE_PREFERENCE:
      return Object.assign(newState, { preference })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case LOCATION_CHANGE:
      return Object.assign(newState, { error: '' })
    default:
      return state
  }
}

export default RentalReputationReducer
