import React from 'react'

import styled, { css } from 'styled-components'

export const withValidation = (InputComponent, validator) => {
  return (props) => {
    return <InputComponent validate={validator} {...props} />
  }
}

export const firstElementOrEmptyString = (array) =>
  array.length > 0 ? array[0] : ''

export const validationStatusClassNameCollection = [
  'validate-none',
  'validate-success',
  'validate-fail',
]

export const nullValidator = () => ''

export const getCurrentValidationStatus = (value, error) => {
  if (value && error) {
    return 'validate-fail'
  } else if (value && !error) {
    return 'validate-success'
  }
  return 'validate-none'
}

export const SearchSampleItem = ({ result, i, onItemClick }) => (
  <div
    role="button"
    tabIndex={0}
    key={i}
    className="search-box-list-item"
    onClick={() => {
      onItemClick(result)
    }}
  >
    {result}
  </div>
)

export const ErrorBox = ({ showError, error }) => (
  <div className={`height30 ${showError ? `` : `d-none`}`}>
    <div className={`invisible ${showError && 'standard-error-box'}`}>
      {error}
    </div>
  </div>
)

export const LabelWrapper = styled.div`
  ${(props) =>
    props.required
      ? css`
          span {
            &:after {
              color: red;
              content: ' *';
            }
          }
        `
      : ''}
`
