import React from 'react'

import styled from 'styled-components'
const Title = styled.p`
  color: #212025;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
`

const Label = styled.p`
  font-size: 16px;
  color: #999999;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0px;
`

const Text = styled.p`
  color: #212025;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0px;
`

const Container = styled.div`
  padding: 24px;
`

const ApplicationSectionDetails = ({ title = '', details = [], children }) => {
  return (
    <Container>
      <Title>{title}</Title>
      {details.length > 0 &&
        details.map((detail, index) => {
          const { label = '', text = '' } = detail
          return (
            <div key={index} className="display-flex">
              {label && <Label>{label}&nbsp;</Label>}
              {text && <Text>{text}</Text>}
            </div>
          )
        })}
      {children}
    </Container>
  )
}

export default ApplicationSectionDetails
