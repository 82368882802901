import { call, delay, put, select, takeEvery } from 'redux-saga/effects'

import {
  actions as coreActions,
  actionTypes as coreActionTypes,
} from 'app/core/coreSlice'
import { maintenanceMessage } from 'app/services/http/core'
import { isMaintenanceWindowEnabled } from 'config/features'

const recheckMaintenanceInSeconds = 3 * 60

function* checkMaintenanceMessageWorker() {
  const { currentTeam } = yield select((state) => state.session)
  if (isMaintenanceWindowEnabled(currentTeam?.slug)) {
    try {
      const maintenanceMessageConfig = yield call(maintenanceMessage)
      const messageConfig = maintenanceMessageConfig?.show
        ? maintenanceMessageConfig
        : null
      yield put(coreActions.maintenanceMessageUpdate(messageConfig))
    } catch {}
  }

  yield delay(recheckMaintenanceInSeconds * 1000)
  yield put(coreActions.maintenanceMessageCheck())
}

export function* watchCheckMaintenanceMessage() {
  yield takeEvery(
    coreActionTypes.maintenanceMessageCheck,
    checkMaintenanceMessageWorker,
  )
}
