import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import {
  Alert,
  Box,
  Flex,
  Modal,
  Spinner,
} from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import { textNotAvailableShort } from 'app/constants/global.constants'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import BaseContactDetails from 'app/shared_components/BaseContactDetails'
import { getTenantDetails } from 'app/shared_components/TenantDetailsModal/http'
import { formatTime, SHORT_DAY_FULL_DATE } from 'app/utils/datetime/helpers'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

const StyledHeading = styled(Box)`
  border-bottom: 1px solid ${theme.colors.gray400};
  padding-bottom: ${theme.space[3]}px;
  width: 100%;
  margin-bottom: ${theme.space[6]}px;
  margin-top: ${theme.space[3]}px;
  color: ${theme.colors.gray700};
`

const StyledTable = styled.table`
  td {
    padding-top: ${theme.space[3]}px;
    padding-bottom: ${theme.space[3]}px;
  }
`

function TenantDetailsModal({ toggleModal, propertyGUID = '', subject }) {
  const {
    loadingStates: tenantDetailsLoadingStates,
    loadingStatesHelpers: tenantDetailsLoadingStatesHelpers,
  } = useLoadingStates()

  const [availableDate, setAvailableDate] = useState('')
  const [vacateDate, setVacateDate] = useState('')

  const [tenants, setTenants] = useState([])

  useEffect(() => {
    tenantDetailsLoadingStatesHelpers.startLoading()
    getTenantDetails(propertyGUID)
      .then((data) => {
        tenantDetailsLoadingStatesHelpers.markDoneSuccessfully()
        const { availableDate = '', tenants = [], vacateDate = '' } = data
        setAvailableDate(availableDate)
        setTenants(tenants)
        setVacateDate(vacateDate)
      })
      .catch((error) => {
        tenantDetailsLoadingStatesHelpers.setError(error)
      })
  }, [])

  const isLoading =
    tenantDetailsLoadingStates.state === loadingStatesIds.LOADING
  const isNotLoadingAndNoError =
    tenantDetailsLoadingStates.state !== loadingStatesIds.LOADING
  const hasError = tenantDetailsLoadingStates.state === loadingStatesIds.ERROR
  const isTenantsAvailable = tenants && tenants.length > 0
  let errorMessage = ''
  if (
    tenantDetailsLoadingStates.error &&
    tenantDetailsLoadingStates.error.message
  ) {
    errorMessage = tenantDetailsLoadingStates.error.message
  }

  const modalContent = (
    <Box width="100%">
      {isLoading && (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      )}
      {isNotLoadingAndNoError && (
        <>
          <StyledHeading>Property Availability</StyledHeading>
          <StyledTable>
            <tr>
              <td>
                <Text mb={0} mr={2}>
                  Available:{' '}
                </Text>
              </td>
              <td>
                <Text color="gray600" mb={0}>
                  {` ${formatTime(availableDate, SHORT_DAY_FULL_DATE)}`}
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text mr={2}>Vacant: </Text>
              </td>
              <td>
                <Text color="gray600">
                  {` ${formatTime(vacateDate, SHORT_DAY_FULL_DATE)}`}
                </Text>
              </td>
            </tr>
          </StyledTable>
          <StyledHeading mt={13}>Tenants</StyledHeading>
          {isTenantsAvailable ? (
            <Box>
              {tenants.map((tenant) => {
                const {
                  firstName = '',
                  lastName = '',
                  email = '',
                  phone = '',
                } = tenant
                return (
                  <Box mb={3}>
                    <BaseContactDetails
                      fullName={`${firstName} ${lastName}`}
                      email={email}
                      contactNumber={phone}
                      subject={subject}
                    >
                      {tenant}
                    </BaseContactDetails>
                  </Box>
                )
              })}
            </Box>
          ) : (
            <Text>{textNotAvailableShort}</Text>
          )}
        </>
      )}
      {hasError && (
        <Alert variant="warningWithBg" mt={theme.space[3] + 'px'}>
          {errorMessage}
        </Alert>
      )}
    </Box>
  )
  return (
    <Modal
      modalHeading="Tenant Details"
      toggleModal={toggleModal}
      showPrimaryButton={false}
      showSecondaryButton={false}
      modalBody={modalContent}
    />
  )
}

export default TenantDetailsModal
