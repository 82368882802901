import React from 'react'

import $ from 'jquery'
import moment from 'moment'
import styled from 'styled-components'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import { Alert, Box, TextSpan } from 'app/components/design-system-components'
import { AlertInfo } from 'app/components/design-system-components/AlertInfo'
import Flex from 'app/components/design-system-components/Flex'
import DoneRounded from 'app/components/design-system-components/icons/actions/DoneRounded'
import * as Display from 'app/components/display/display'
import * as Headers from 'app/components/display/text/standard_text/standard-headings/component'
import * as Text from 'app/components/display/text/text_component'
import ProgressBarComponent from 'app/components/progress_bar/connection'
import { pmsBrands, sendApplicantPMSSetting } from 'app/constants/pms.constants'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { DIRECT_CONNECT_PROVIDER_NAME } from 'app/match/apply/application_summary_before_submit/application_summary_before_submit.constants'
import AddressHistoryComponent from 'app/match/apply/application_summary_before_submit/components/address_history_container/component'
import ApplicationClosedModal from 'app/match/apply/application_summary_before_submit/components/application_closed_modal/ApplicationClosedModal'
import BackgroundCheckContainer from 'app/match/apply/application_summary_before_submit/components/background_check_container/component'
import DisclosureConsentContainer, {
  InspectionTypeNotSet,
  InspectionTypePhysical,
  InspectionTypeRemote,
  PhysicalAndRemoteAcceptanceType,
} from 'app/match/apply/application_summary_before_submit/components/disclosure_consent_container/component'
import {
  ABORIGINAL_OR_TORRES_STRAIGHT_ISLANDER,
  ANOTHER_LESSOR_OR_AGENT_DEBT,
  ANOTHER_LESSOR_OR_AGENT_DEBT_DETAILS,
  OWN_PROPERTIES_OR_LAND,
  OWN_PROPERTIES_OR_LAND_DETAILS,
  PREVIOUS_TENANCIES_TERMINATION,
  PREVIOUS_TENANCIES_TERMINATION_DETAILS,
  REASON_AFFECT_RENT_PAYMENT,
  REASON_AFFECT_RENT_PAYMENT_DETAILS,
  STATE_OPTED_IN_UTILITY_CONNECTION,
  TEAM_MEMBER_ASSOCIATION,
  Yes,
  yesOrNoDisclosureQuestions,
} from 'app/match/apply/application_summary_before_submit/components/disclosure_consent_container/disclosure_questions.constants'
import EmergencyContactContainer from 'app/match/apply/application_summary_before_submit/components/emergency_container/component'
import EmploymentContainer from 'app/match/apply/application_summary_before_submit/components/employment_container/component'
import { HouseholdContainer } from 'app/match/apply/application_summary_before_submit/components/household_container/component'
import IdentityDocumentsContainer from 'app/match/apply/application_summary_before_submit/components/identity_container/component'
import IncomeContainer from 'app/match/apply/application_summary_before_submit/components/income_container/component'
import OtherDocumentContainer from 'app/match/apply/application_summary_before_submit/components/other_documents_container/component'
import PetsContainer from 'app/match/apply/application_summary_before_submit/components/pet_container/component'
import PreferenceComponent from 'app/match/apply/application_summary_before_submit/components/preference_container/component'
import PersonalReferenceContainer from 'app/match/apply/application_summary_before_submit/components/reference_container/component'
import StudentContainer from 'app/match/apply/application_summary_before_submit/components/student_container/component'
import * as SummaryHeaders from 'app/match/apply/application_summary_before_submit/shared_components/headers/component'
import BasicModal from 'app/modals/basic_modal'
import { getPMSStatus } from 'app/services/http/pms'
import * as snugNotifier from 'app/services/snugNotifier'
import DisclosureQuestionsDisplay from 'app/shared_components/DisclosureQuestionsDisplay'
import { history } from 'app/shared_components/router'
import * as validations from 'app/shared_components/validations'
import { AgencyApplicationsLimitAlert } from 'app/sm/apply/components/AgencyApplicationsLimitAlert'
import Spinner from 'app/sm/common/spinner'
import {
  isApplicationAccepted,
  isApplicationApplyAnywhere,
  parseDataUtil,
  smokingType,
  standardUTCFormat,
  urlTo,
} from 'app/sm/helpers'
import * as helpers from 'app/sm/helpers'
import { checkIfBGCheckNotSupportedInState } from 'app/utils/address/helpers'
import { APPLY_ANYWHERE_APPLICATION } from 'app/utils/applications/helpers'
import * as applicationHelpers from 'app/utils/applications/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as stringHelpers from 'app/utils/strings/helpers'

import 'app/match/apply/application_summary_before_submit/styles/application_summary_before_submit_container/style.scss'

const StyledDoneIconContainer = styled(Flex)`
  background-color: ${theme.colors.success200};
  border-radius: 100%;
`

class ApplicationSummaryContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitBtnClicked: false,
      loadingError: '',
      actionError: false,
      smokingInfo: smokingType.NonSmoker,
      optedInAsSmoker: false,
      optedToAcceptCondition: false,
      optedToAcceptDisclosureConsent: true,
      optedToReceiveUtilityConnection: true,
      directConnectTermsViewed: false,
      optedToReceiveRayWhiteConciergeService: false,
      optedToRequirePostingClause: false,
      optedToReceiveBondLoanInformation: false,
      optedInToPetConsent: false,
      optedToReceiveEasyBondpay: false,
      teamSettingInfo: {},
      isKolmeoActive: false,
      showingToast: false,
      conditionAcceptanceType: PhysicalAndRemoteAcceptanceType, // default
      propertyInspectionType: InspectionTypeNotSet,
      propertyInspectedDate: null,
      renterHasJointApplication: false,
      showViewingCheckinModal: false,
      openAAModal: false,
      inspectedDateError: '',
      optedToSkipViewingDisclosures: false,
      disclosureYesOrNoAnswers: {
        [PREVIOUS_TENANCIES_TERMINATION]: null,
        [PREVIOUS_TENANCIES_TERMINATION_DETAILS]: null,
        [ANOTHER_LESSOR_OR_AGENT_DEBT]: null,
        [ANOTHER_LESSOR_OR_AGENT_DEBT_DETAILS]: null,
        [REASON_AFFECT_RENT_PAYMENT]: null,
        [REASON_AFFECT_RENT_PAYMENT_DETAILS]: null,
        [OWN_PROPERTIES_OR_LAND]: null,
        [OWN_PROPERTIES_OR_LAND_DETAILS]: null,
        [TEAM_MEMBER_ASSOCIATION]: null,
        [ABORIGINAL_OR_TORRES_STRAIGHT_ISLANDER]: null,
      },
      propertyStatus: 0,
      propertyArchived: false,
      applicationsCloseModalOpen: false,
    }
    this.toggleDisclosureOptions = this.toggleDisclosureOptions.bind(this)
  }

  componentDidMount() {
    const {
      match: {
        params: { applicationId },
      },
      fetchRenterSummary,
      retrieveReputationPreference,
      fetchApplication,
      setBackUrl,
      fetchCompleteness,
      fetchTeamSettingInformation,
    } = this.props

    const backUrl = window.location.pathname
    $('#index').addClass('no-navbar-menu')
    setBackUrl(backUrl)
    this.setState({ spinner: true })
    fetchRenterSummary(applicationId)
      .then(({ renterSummary }) => {
        const { property = {} } = renterSummary
        const { archived = false, status = '' } = property || {}

        this.setState(
          {
            renterSummary,
            propertyStatus: status,
            propertyArchived: archived,
            spinner: false,
          },
          () => {
            fetchApplication(applicationId)
            const { agencyID = '' } = renterSummary.property || {}
            if (agencyID) {
              fetchTeamSettingInformation(
                agencyID,
                'application,integrations,notifications',
              )
                .then((teamSettingInfo) => {
                  const { integrations } = teamSettingInfo
                  const parsedIntegrations =
                    parseDataUtil.convertObjValueFromStringToBoolean(
                      integrations,
                    )
                  const isKolmeoActive =
                    parsedIntegrations[
                      `${sendApplicantPMSSetting}.${pmsBrands.kolmeo.id}`
                    ]
                  this.setState({ teamSettingInfo, isKolmeoActive })
                })
                .catch((error) => {
                  this.setState({ error })
                })
            }
            const { property = {}, utilityProvider = {} } = renterSummary
            const { address = {} } = property || {}
            const { state = '' } = address || {}
            const { name = '' } = utilityProvider
            if (
              state === STATE_OPTED_IN_UTILITY_CONNECTION &&
              name === DIRECT_CONNECT_PROVIDER_NAME
            ) {
              this.setState({
                optedToReceiveUtilityConnection: false,
              })
            }
          },
        )
      })
      .catch((error) => {
        this.setState({ loadingError: error }, this.reactToastify)
      })
    retrieveReputationPreference()
      .then(({ preference }) => {
        this.setState({ preference })
      })
      .catch((error) => {
        this.setState({ loadingError: error }, this.reactToastify)
      })
    fetchCompleteness()

    if (this.props.applicant) {
      this.mapYesOrNoDisclosureAnswers(this.props.applicant)
    }
  }

  componentDidUpdate(prevProps) {
    const { applicant } = this.props
    if (
      prevProps.applicant &&
      applicant &&
      (prevProps.applicant || {}).guidID !== applicant.guidID &&
      applicant.applied
    ) {
      if (applicant.isPrimary) {
        this.setState({
          optedToReceiveUtilityConnection:
            applicant.optedToReceiveUtilityConnection,
          directConnectTermsViewed: applicant.directConnectTermsViewed,
          optedToReceiveRayWhiteConciergeService:
            applicant.optedToReceiveRayWhiteConciergeService,
          optedToReceiveBondLoanInformation:
            applicant.optedToReceiveBondLoanInformation,
          optedToRequirePostingClause: applicant.optedToRequirePostingClause,
        })
      }
      this.setState({
        optedToAcceptCondition: applicant.optedToAcceptCondition,
        optedToAcceptDisclosureConsent:
          applicant.optedToAcceptDisclosureConsent,
        smokingInfo: applicant.smokingInfo,
        optedInAsSmoker: applicant.smokingInfo === smokingType.Smoker,
        optedInToPetConsent: applicant.optedInToPetConsent,
        conditionAcceptanceType: applicant.conditionAcceptanceType,
        propertyInspectionType: applicant.propertyInspectionType,
        propertyInspectedDate: applicant.propertyInspectedDate,
        optedToReceiveEasyBondpay: applicant.optedToReceiveEasyBondpay,
        optedToSkipViewingDisclosures: applicant.optedToSkipViewingDisclosures,
      })
      this.mapYesOrNoDisclosureAnswers(applicant)
    }
  }

  componentWillUnmount() {
    $('#index').removeClass('no-navbar-menu')
  }

  mapYesOrNoDisclosureAnswers(applicant) {
    if (!applicant.disclosures || typeof applicant.disclosures !== 'object')
      return

    this.setState({
      disclosureYesOrNoAnswers: {
        [PREVIOUS_TENANCIES_TERMINATION]:
          applicant.disclosures[PREVIOUS_TENANCIES_TERMINATION],
        [PREVIOUS_TENANCIES_TERMINATION_DETAILS]:
          applicant.disclosures[PREVIOUS_TENANCIES_TERMINATION_DETAILS],
        [ANOTHER_LESSOR_OR_AGENT_DEBT]:
          applicant.disclosures[ANOTHER_LESSOR_OR_AGENT_DEBT],
        [ANOTHER_LESSOR_OR_AGENT_DEBT_DETAILS]:
          applicant.disclosures[ANOTHER_LESSOR_OR_AGENT_DEBT_DETAILS],
        [REASON_AFFECT_RENT_PAYMENT]:
          applicant.disclosures[REASON_AFFECT_RENT_PAYMENT],
        [REASON_AFFECT_RENT_PAYMENT_DETAILS]:
          applicant.disclosures[REASON_AFFECT_RENT_PAYMENT_DETAILS],
        [OWN_PROPERTIES_OR_LAND]: applicant.disclosures[OWN_PROPERTIES_OR_LAND],
        [OWN_PROPERTIES_OR_LAND_DETAILS]:
          applicant.disclosures[OWN_PROPERTIES_OR_LAND_DETAILS],
        [TEAM_MEMBER_ASSOCIATION]:
          applicant.disclosures[TEAM_MEMBER_ASSOCIATION],
        [ABORIGINAL_OR_TORRES_STRAIGHT_ISLANDER]:
          applicant.disclosures[ABORIGINAL_OR_TORRES_STRAIGHT_ISLANDER],
      },
    })
  }

  onApplyButtonClick = (
    applicant,
    applicationID,
    applicantSummary,
    providerName = '',
    greaterThanIDPointsThreshold = true,
  ) => {
    const { updateApplicantDisclosures, fetchApplication } = this.props
    const {
      teamSettingInfo,
      renterSummary = {},
      propertyStatus,
      propertyArchived,
    } = this.state
    const { applicantHasViewingCheckin, isViewingCheckinEnabled } =
      renterSummary
    if (!applicantHasViewingCheckin && isViewingCheckinEnabled) {
      const renterHasJointApplication = this.state.renterHasJointApplication
      this.setState({
        showViewingCheckinModal: true,
        submitBtnClicked: false,
        renterHasJointApplication: !applicant.isPrimary
          ? !renterHasJointApplication
          : renterHasJointApplication,
      })
      return false
    }
    const identityCapEnabled =
      teamSettingInfo &&
      teamSettingInfo.application &&
      teamSettingInfo.application.identity_cap_enabled &&
      teamSettingInfo.application.identity_cap_enabled === 'true'
    const disclosureRequest = this.getDisclosureRequest(applicant.isPrimary)
    const secondaryApplicantPayload = {
      applicantSummary,
      applicantDisclosure: disclosureRequest,
    }
    const { propertyOffer } = renterSummary
    const { ballotsEnabled = false } = propertyOffer || {}

    const offMarketProperty =
      helpers.isPropertyLeased(propertyStatus) || propertyArchived
    if (identityCapEnabled && !greaterThanIDPointsThreshold) {
      this.setState(
        {
          actionError: stringHelpers.IDENTITY_POINT_TOAST_ERROR,
          submitBtnClicked: false,
        },
        this.reactToastify,
      )
      return false
    }

    if (!this.validRequiredFields()) {
      this.setState({ submitBtnClicked: false })
      return null
    }
    if (this.state.actionError) {
      this.reactToastify()
      this.setState({ submitBtnClicked: false, actionError: '' })
      return null
    }

    if (ballotsEnabled) {
      if (applicant.isPrimary && !applicant.applied) {
        return this.submitApplicationAsPrimary(
          applicationID,
          disclosureRequest,
          providerName,
        )
      }
      if (!applicant.isPrimary && !applicant.applied) {
        return this.submitApplicationAsSecondary(
          applicationID,
          applicant.guidID,
          secondaryApplicantPayload,
          disclosureRequest,
          providerName,
        )
      }
      if (applicant.applied) {
        updateApplicantDisclosures(applicant.guidID, disclosureRequest)
          .then(() => {
            fetchApplication(applicationID)
          })
          .catch((error) => {
            this.setState(
              {
                actionError: error,
              },
              this.reactToastify,
            )
          })
      }
      history.push(
        urlTo('applyConfirmationSummaryBallot', {
          applicationId: applicationID,
        }),
      )
    } else {
      if (applicant.applied) {
        updateApplicantDisclosures(applicant.guidID, disclosureRequest)
          .then(() => {
            fetchApplication(applicationID)
          })
          .catch((error) => {
            this.setState(
              {
                actionError: error,
              },
              this.reactToastify,
            )
          })
        history.push(
          urlTo('applyConfirmationSummary', {
            applicationId: applicationID,
          }),
        )
      } else {
        if (offMarketProperty) {
          this.setState({
            applicationsCloseModalOpen: true,
            submitBtnClicked: false,
          })
        } else {
          if (applicant.isPrimary) {
            return this.submitApplicationAsPrimary(
              applicationID,
              disclosureRequest,
              providerName,
            )
          }
          if (!applicant.isPrimary) {
            return this.submitApplicationAsSecondary(
              applicationID,
              applicant.guidID,
              secondaryApplicantPayload,
              disclosureRequest,
              providerName,
            )
          }
          history.push(
            urlTo('applyConfirmationSummary', {
              applicationId: applicationID,
            }),
          )
        }
      }
    }

    return null
  }

  onBookATimeButtonClicked = (property) => {
    if (property) {
      window.open(
        helpers.urlTo('applyRedirect', { slug: (property || {}).slug }),
      )
    }
  }

  onChangeInspectionType = (propertyInspectionTypeID) => {
    const { actionError } = this.state
    return () => {
      this.setState(
        {
          optedToAcceptCondition: true,
          propertyInspectionType: propertyInspectionTypeID,
        },
        () => {
          if (
            this.state.conditionAcceptanceType ===
              PhysicalAndRemoteAcceptanceType &&
            (this.state.propertyInspectionType === InspectionTypeRemote ||
              this.state.propertyInspectionType === InspectionTypePhysical) &&
            actionError === stringHelpers.PROPERTY_CONDITION_ERROR_TEXT
          ) {
            this.setState({
              actionError: '',
            })
          }
        },
      )
    }
  }

  onEditClick = (queryString) => {
    return (forwardUrl) => {
      const { addRentalReputationBackUrl, setBackUrl } = this.props
      const backUrl = window.location.pathname + queryString
      addRentalReputationBackUrl(backUrl)
      setBackUrl(backUrl)
      history.push(forwardUrl + queryString)
    }
  }

  onPropertyInspectedDateUpdate = () => {
    return (data) => {
      let value = data.value
      if (data.error === '') {
        value =
          value !== ''
            ? moment(value).format(dateTimeHelpers.DATE_WITH_DASH)
            : ''
      }
      this.setState({
        propertyInspectedDate: value,
        inspectedDateError: data.error,
      })
    }
  }

  onSubmitClick = (
    applicant,
    applicationID,
    applicantSummary,
    providerName = '',
  ) => {
    const { renterSummary = {} } = this.state
    const { renterJointApplication = {} } = renterSummary
    if (renterJointApplication && renterJointApplication.applicant) {
      this.setState({
        renterHasJointApplication: true,
      })
      return
    }
    const { renterHasJointApplication } = this.state
    if (!renterHasJointApplication) {
      this.onApplyButtonClick(
        applicant,
        applicationID,
        applicantSummary,
        providerName,
      )
    }
  }

  onToggleModal = () => {
    const { applicant } = this.props
    const renterHasJointApplication = this.state.renterHasJointApplication
    this.setState({
      showViewingCheckinModal: !this.state.showViewingCheckinModal,
      submitBtnClicked: false,
      renterHasJointApplication: !applicant.isPrimary
        ? !renterHasJointApplication
        : renterHasJointApplication,
    })
  }

  onCloseModal = () => {
    this.setState({
      openAAModal: !this.state.openAAModal,
    })
  }

  toggleApplicationsCloseModal = () => {
    this.setState({
      applicationsCloseModalOpen: !this.state.applicationsCloseModalOpen,
    })
  }

  onClickOkButton = () => {
    this.setState({ submitBtnClicked: true, openAAModal: false })
    const { applicant, submittedApplication } = this.props
    const {
      match: {
        params: { applicationId },
      },
    } = this.props
    const { applicantSummary = {} } = submittedApplication
    const { renterSummary = {} } = this.state
    const { utilityProvider = {}, idDocumentsSummary = {} } = renterSummary
    const idPoint =
      idDocumentsSummary.identityCheckPoints >=
      stringHelpers.IDENTITY_POINT_THRESHOLD_VALUE
    this.onSubmitClick(
      applicant,
      applicationId,
      applicantSummary,
      utilityProvider.name,
      idPoint,
    )
  }

  getDisclosureRequest(isApplicantPrimary) {
    const {
      optedToAcceptCondition,
      optedToAcceptDisclosureConsent,
      optedToReceiveUtilityConnection,
      directConnectTermsViewed,
      optedToReceiveRayWhiteConciergeService,
      optedToReceiveBondLoanInformation,
      optedToRequirePostingClause,
      smokingInfo,
      optedInToPetConsent,
      conditionAcceptanceType,
      propertyInspectionType,
      propertyInspectedDate,
      optedToReceiveEasyBondpay,
      disclosureYesOrNoAnswers,
      optedToSkipViewingDisclosures,
    } = this.state
    const data = {
      isPrimary: isApplicantPrimary,
      optedToAcceptCondition,
      optedToAcceptDisclosureConsent,
      optedToReceiveUtilityConnection,
      directConnectTermsViewed,
      optedToReceiveRayWhiteConciergeService,
      optedToReceiveBondLoanInformation,
      optedToRequirePostingClause,
      smokingInfo,
      optedInToPetConsent,
      conditionAcceptanceType,
      propertyInspectionType,
      optedToReceiveEasyBondpay,
      optedToSkipViewingDisclosures,
      ...disclosureYesOrNoAnswers,
    }
    if (
      propertyInspectedDate !== null &&
      propertyInspectionType === InspectionTypePhysical
    ) {
      data.propertyInspectedDate = moment(propertyInspectedDate).format(
        standardUTCFormat,
      )
    }

    return data
  }

  btnTextChange = () => {
    const { applicant = {} } = this.props
    const { submitBtnClicked } = this.state
    const applyGTM = 'rental-application-submit-application'
    if (applicant && !applicant.applied) {
      return (
        <div
          className={`summary-submit-button ${applyGTM} ${
            submitBtnClicked && `button-spinning-spacing`
          }`}
        >
          Submit Application
          <i className={submitBtnClicked ? 'fa fa-spinner fa-pulse' : ''} />
        </div>
      )
    } else if (applicant && applicant.applied && applicant.isPrimary) {
      return (
        <div>
          Update
          <i className={submitBtnClicked ? 'fa fa-spinner fa-pulse' : ''} />
        </div>
      )
    }
    return ''
  }

  joinApplication = (applicant, application) => {
    const { slug, guidID } = application || {}
    const { submitJoinedApplication } = this.props
    const { optedToAcceptDisclosureConsent, optedToSkipViewingDisclosures } =
      this.state

    if (!optedToAcceptDisclosureConsent && !optedToSkipViewingDisclosures) {
      this.setState(
        {
          actionError: 'You must accept the disclosure consent to continue',
        },
        this.reactToastify,
      )
      return
    }
    const { renterSummary = {} } = this.state
    const { applicantHasViewingCheckin, isViewingCheckinEnabled } =
      renterSummary
    if (!applicantHasViewingCheckin && isViewingCheckinEnabled) {
      const renterHasJointApplication = this.state.renterHasJointApplication
      this.setState({
        showViewingCheckinModal: true,
        submitBtnClicked: false,
        renterHasJointApplication: !applicant.isPrimary
          ? !renterHasJointApplication
          : renterHasJointApplication,
      })
      return false
    }
    const disclosureRequest = this.getDisclosureRequest(applicant.isPrimary)
    if (slug) {
      submitJoinedApplication(slug, disclosureRequest)
        .then(() => {
          this.setState({
            renterHasJointApplication: false,
          })
          if (guidID) {
            history.push(
              `${urlTo('applyConfirmationSummary', {
                applicationId: guidID,
              })}${window.location.search}`,
            )
          }
        })
        .catch((apiError) => {
          snugNotifier.error(apiError)
        })
    }
  }

  reactToastify = () => {
    if (this.state.showingToast === false) {
      snugNotifier.error(
        this.state.actionError ||
          this.state.loadingError ||
          this.state.inspectedDateError,
        {
          onOpen: () => this.setState({ showingToast: true }),
          onClose: () => this.setState({ showingToast: false }),
        },
      )
    }
  }

  submitApplicationAsPrimary = (applicationID, data, providerName) => {
    const { submitFinalApplication } = this.props
    this.setState({ submitBtnClicked: true })
    submitFinalApplication(applicationID, data)
      .then(() => {
        history.push(
          urlTo('applyConfirmationSummary', { applicationId: applicationID }),
        )
      })
      .catch((error) => {
        this.setState(
          { submitBtnClicked: false, actionError: error },
          this.reactToastify,
        )
      })
  }

  submitApplicationAsSecondary = (
    applicationID,
    applicantGUIDID,
    payload,
    data,
    providerName,
  ) => {
    this.props
      .acceptJointApplicationAsSecondary(
        applicationID,
        applicantGUIDID,
        payload,
        true,
        data,
      )
      .then(() => {
        history.push(
          urlTo('applyConfirmationSummary', { applicationId: applicationID }),
        )
      })
      .catch((error) => {
        this.setState(
          { submitBtnClicked: false, actionError: error },
          this.reactToastify,
        )
      })
  }

  toggleDisclosureOptions = (field) => {
    return () => {
      this.setState({
        [field]: !this.state[field],
      })
    }
  }

  toggleJoinApplicationModal = () => {
    this.setState({
      renterHasJointApplication: !this.state.renterHasJointApplication,
      submitBtnClicked: false,
    })
  }

  toggleOptedInAsSmoker = () => {
    this.setState(
      {
        optedInAsSmoker: !this.state.optedInAsSmoker,
      },
      () => {
        this.setState({
          smokingInfo: this.state.optedInAsSmoker
            ? smokingType.Smoker
            : smokingType.NonSmoker,
        })
      },
    )
  }

  validRequiredFields() {
    const { applicant = {}, renterSummary = {} } = this.props
    const { pets = [] } = renterSummary
    const { teamSettingInfo, isKolmeoActive } = this.state
    const parsedTeamSettingInfo =
      (teamSettingInfo.application &&
        parseDataUtil.convertObjValueFromStringToBoolean(
          teamSettingInfo.application,
        )) ||
      {}
    const { enable_enhanced_disclosure: enableEnhancedDisclosure = false } =
      parsedTeamSettingInfo
    if (enableEnhancedDisclosure) {
      for (const question of yesOrNoDisclosureQuestions) {
        if (
          (question.required &&
            !this.state.disclosureYesOrNoAnswers[question.id]) ||
          (question.hasMoreDetails &&
            this.state.disclosureYesOrNoAnswers[question.id] === Yes &&
            !this.state.disclosureYesOrNoAnswers[question.moreDetailsId])
        ) {
          this.setState(
            {
              actionError: stringHelpers.ENHANCED_DISCLOSURES_ERROR,
            },
            this.reactToastify,
          )
          return false
        }
      }
    }

    if (applicant.isPrimary) {
      if (
        !this.state.optedToAcceptCondition &&
        !this.state.optedToSkipViewingDisclosures
      ) {
        const element = document.querySelector('.physically-inspected')
        element && element.scrollIntoView(true)
        this.setState(
          {
            actionError: stringHelpers.PROPERTY_CONDITION_ERROR_TEXT,
          },
          this.reactToastify,
        )
        return false
      }
      if (
        !this.state.optedToAcceptDisclosureConsent &&
        !this.state.optedToSkipViewingDisclosures
      ) {
        this.setState(
          {
            actionError: 'You must accept the disclosure consent to continue',
          },
          this.reactToastify,
        )
        return false
      }
      if (pets.length > 0 && !this.state.optedInToPetConsent) {
        this.setState(
          {
            actionError: 'You must accept the pet regulations to continue',
          },
          this.reactToastify,
        )
        return false
      }
      if (
        this.state.conditionAcceptanceType ===
          PhysicalAndRemoteAcceptanceType &&
        this.state.propertyInspectionType === InspectionTypePhysical &&
        (this.state.propertyInspectedDate === null ||
          this.state.propertyInspectedDate === '')
      ) {
        this.setState(
          {
            actionError: 'Inspection date is required',
          },
          this.reactToastify,
        )
        return false
      }
      if (
        this.state.conditionAcceptanceType ===
          PhysicalAndRemoteAcceptanceType &&
        this.state.propertyInspectionType === InspectionTypePhysical &&
        !validations.isDateTodayOrEarlier(this.state.propertyInspectedDate)
      ) {
        this.setState(
          {
            inspectedDateError: 'Inspection date must be today or earlier',
          },
          this.reactToastify,
        )
        return false
      }
    }

    return true
  }

  renderAgencyApplicationsLimitAlert() {
    const { teamSettingInfo } = this.state
    const { submittedApplication } = this.props

    const applicantAgencySummary =
      submittedApplication?.applicant?.agencySummary
    const teamName = submittedApplication?.offer?.property?.teamName

    return (
      teamSettingInfo && (
        <Box m={`${theme.space[7]}px`}>
          <AgencyApplicationsLimitAlert
            applicantApplicationsCount={
              applicantAgencySummary?.applicationsCount
            }
            teamName={teamName}
            applicationTeamSettings={teamSettingInfo.application}
          />
        </Box>
      )
    )
  }

  onChangeDisclosureYesOrNoQuestions(id, value) {
    const { actionError } = this.state

    this.setState(
      {
        disclosureYesOrNoAnswers: {
          ...this.state.disclosureYesOrNoAnswers,
          [id]: value,
        },
      },
      () => {
        if (actionError === stringHelpers.ENHANCED_DISCLOSURES_ERROR) {
          this.setState({
            actionError: '',
          })
        }
      },
    )
  }

  render() {
    const {
      match: {
        params: { applicationId },
      },
    } = this.props
    const {
      currentUser = {},
      backgroundCheck = {},
      applicant = {},
      submittedApplication = {},
      completeness,
      propertyDisclosure,
    } = this.props
    const { firstName = '' } = currentUser
    const { conditionAcceptanceType, acknowledgeSupplyOfIDDocuments = false } =
      applicant
    const {
      renterSummary = {},
      spinner = false,
      preference = {},
      submitBtnClicked = false,
      loadingError = '',
      optedInAsSmoker = false,
      optedToAcceptCondition = false,
      optedToAcceptDisclosureConsent = true,
      optedToReceiveUtilityConnection = true,
      directConnectTermsViewed = false,
      optedToReceiveRayWhiteConciergeService = false,
      optedToReceiveBondLoanInformation = false,
      optedToRequirePostingClause = false,
      optedInToPetConsent = false,
      optedToReceiveEasyBondpay = false,
      teamSettingInfo = {},
      propertyInspectionType,
      propertyInspectedDate = null,
      renterHasJointApplication,
      showViewingCheckinModal,
      openAAModal,
      inspectedDateError,
      optedToSkipViewingDisclosures = false,
      isKolmeoActive,
      applicationsCloseModalOpen,
    } = this.state
    const {
      application = {},
      rentalHistory = {},
      idDocumentsSummary = {},
      proofOfIncome = {},
      employers = [],
      pets = [],
      attachments = [],
      personalReferences = [],
      emergencyContacts = [],
      studentDetails = [],
      utilityProvider = {},
      totalWeeklyIncome = 0,
      renterJointApplication = {},
      selfTenancyCheck = {},
    } = renterSummary
    const idPoint =
      idDocumentsSummary.identityCheckPoints >=
      stringHelpers.IDENTITY_POINT_THRESHOLD_VALUE
    let { property = {}, propertyOffer = {} } = renterSummary
    const { agencyID = '' } = renterSummary.property || {}
    if (!property) {
      property = {}
    }
    if (!propertyOffer) {
      propertyOffer = {}
    }
    const {
      applicants = [],
      applyAnywhereListing = {},
      applicationType = 0,
    } = application
    const { attachedToProfile = false } = backgroundCheck
    const {
      optOutBackgroundCheck = false,
      optOutEmploymentHistory = false,
      optOutPets = false,
      optOutRentalHistory = false,
      optInStudentDetails = false,
    } = preference

    const { config: applicationConfig = {} } = application || {}
    const { applyOptions = {}, applicationsSettingsConfig = {} } =
      applicationConfig
    const { allowApplicantToSkipViewingDisclosures = {} } = applyOptions || {}
    const {
      exclude_bankruptcy_report: excludeBGCBankruptcyReport = false,
      exclude_request_vehicle_registration:
        excludeRequestVehicleRegistration = false,
    } = applicationsSettingsConfig || {}
    const { teamSlug = '' } = property
    const {
      applicantSummary = {},
      submittedDate = '',
      appliedAt = '',
    } = submittedApplication
    const chosenPropertyInfo = property.address
      ? property
      : applyAnywhereListing
    const { address = {} } = chosenPropertyInfo
    const { friendlyName = '', state = '' } = address || {}
    const isNotApplyAnywhereApplication =
      applicationType !== APPLY_ANYWHERE_APPLICATION
    const isApplyAnywhereApplication =
      applicationType === APPLY_ANYWHERE_APPLICATION
    const {
      rentalHistoryVerified = false,
      employmentHistoryVerified = false,
      employmentHistoryPresent = false,
    } = completeness

    const shouldSubmitWithoutDelayNotice =
      (isApplyAnywhereApplication &&
        !applicationHelpers.shouldDelaySendingAAApplication(
          rentalHistoryVerified,
          employmentHistoryVerified,
          employmentHistoryPresent,
        )) ||
      isNotApplyAnywhereApplication
    const isApplicationEditable = !isApplicationAccepted(application)
    const submitApplicationTagClass = applyAnywhereListing
      ? 'apply-anywhere-submit-application'
      : 'rental-application-submit-application'
    const urlQueryString = `&address=${friendlyName}&id=${agencyID}&applicationId=${applicationId}&isApplied=${
      applicant && applicant.applied ? 'Submitted' : 'Draft'
    }`
    const urlQueryStringForEssentials = `${urlQueryString}&stage=essentials`

    const {
      hasPropertyDisclosure = false,
      propertyDisclosure: disclosures = {},
    } = propertyDisclosure

    const teamName = submittedApplication?.offer?.property?.teamName

    const backgroundCheckDate =
      backgroundCheck &&
      backgroundCheck.updatedAt &&
      backgroundCheck.updatedAt !== '0001-01-01T00:00:00Z'
        ? dateTimeHelpers.formatTime(
            backgroundCheck.updatedAt,
            'hh:mm DD MMM YYYY',
          )
        : ''

    const { publicConfig = {} } = application || {}
    const { agencyOptions = {} } = publicConfig || {}
    const { idDocsNotRequested = false } = agencyOptions || {}
    const { ballotsEnabled = false } = propertyOffer || {}

    const hideBGCheckSection =
      ballotsEnabled || checkIfBGCheckNotSupportedInState(state)

    const renderApplicationHeader = () => {
      const submitted = applicant && applicant.applied

      if (submitted) {
        return (
          <Box mb="15px">
            <Alert variant="successWithBg">
              <Flex alignItems="center" justifyContent="start">
                <StyledDoneIconContainer mr={`${theme.space[5]}px`}>
                  <DoneRounded color={theme.colors.success700} />
                </StyledDoneIconContainer>

                <TextSpan fontWeight={theme.fontWeights[6]}>SUBMITTED</TextSpan>
              </Flex>
            </Alert>
          </Box>
        )
      }

      return (
        <Box mb="15px">
          <Alert variant="blueWithBg">
            <TextSpan fontWeight="700">DRAFT</TextSpan>
            <TextSpan>, please submit when ready</TextSpan>
          </Alert>
        </Box>
      )
    }

    return (
      <div>
        <Display.CenterContentContainer>
          <ProgressBarComponent
            completeness={completeness}
            applied={applicant && applicant.applied}
          />

          {renderApplicationHeader()}

          {spinner && !loadingError && (
            <div className="mt100">
              <Spinner />
            </div>
          )}
          {loadingError && (
            <div className="alert alert-danger mt50">{loadingError}</div>
          )}
          {!spinner && !loadingError && (
            <div>
              {isApplicationApplyAnywhere(application.applicationType) && (
                <SummaryHeaders.ApplyAnywhereApplicationHeader
                  isApplyAnywhereHeadEditable={isApplicationEditable}
                  application={application}
                  property={chosenPropertyInfo}
                />
              )}
              {isNotApplyAnywhereApplication && (
                <SummaryHeaders.ApplicationSummaryHeader
                  property={chosenPropertyInfo}
                  propertyOffer={propertyOffer}
                  applicationType={applicationType}
                />
              )}
            </div>
          )}
        </Display.CenterContentContainer>
        {!spinner && !loadingError && (
          <div>
            <Display.CenterContentContainer componentClass="application-standard-shadow-box col-xs-12 summary-box-on-mobile">
              <div className="mb0">
                <Headers.SectionHeader text="My Details" textClass="fw600" />
                <HouseholdContainer
                  application={application}
                  isHouseholdEditable={isApplicationEditable}
                  onEditClick={this.onEditClick(urlQueryString)}
                  componentClassName="bbs"
                />
                <PreferenceComponent
                  application={application}
                  applicants={applicants}
                  isSummaryEditable={isApplicationEditable}
                  onEditClick={this.onEditClick(urlQueryString)}
                  totalEmployedWeeklyNetIncome={totalWeeklyIncome}
                  propertyOffer={propertyOffer}
                  isManagerSummary={false}
                  teamSettingInfo={teamSettingInfo}
                  excludeRequestVehicleRegistration={
                    excludeRequestVehicleRegistration
                  }
                />
              </div>
            </Display.CenterContentContainer>
            <Display.CenterContentContainer componentClass="application-standard-shadow-box mt80 col-xs-12 summary-box-on-mobile">
              <div className="mb0">
                <Headers.SectionHeader text="Essential" textClass="fw600" />
                <AddressHistoryComponent
                  rentalHistory={rentalHistory}
                  optOutRentalHistory={optOutRentalHistory}
                  isAddressHistoryEditable={isApplicationEditable}
                  onEditClick={this.onEditClick(urlQueryStringForEssentials)}
                  componentClassName="bbs"
                  ballotsEnabled={ballotsEnabled}
                  renterName={firstName}
                />
                <EmploymentContainer
                  employers={employers}
                  optOutEmploymentHistory={optOutEmploymentHistory}
                  isEmploymentEditable={isApplicationEditable}
                  onEditClick={this.onEditClick(urlQueryStringForEssentials)}
                  componentClassName="bbs"
                  ballotsEnabled={ballotsEnabled}
                />
                <IncomeContainer
                  proofOfIncome={proofOfIncome}
                  isIncomeEditable={isApplicationEditable}
                  onEditClick={this.onEditClick(urlQueryStringForEssentials)}
                  componentClassName="bbs"
                  ballotsEnabled={ballotsEnabled}
                />

                <IdentityDocumentsContainer
                  identityDocs={idDocumentsSummary?.identityDocs}
                  supportingIdentityDocs={
                    idDocumentsSummary.supportingIdentityDocs
                  }
                  identityCheckPoints={idDocumentsSummary.identityCheckPoints}
                  isIdentityDocumentsEditable={isApplicationEditable}
                  onEditClick={this.onEditClick(urlQueryStringForEssentials)}
                  componentClassName="no-box-shadow summary-container-padding pb0 bbs"
                  agencyID={agencyID}
                  idDocsNotRequested={idDocsNotRequested}
                  acknowledgeSupplyOfIDDocuments={
                    acknowledgeSupplyOfIDDocuments
                  }
                  showManagerAlertTextForIDDocs={false}
                  selfTenancyCheck={selfTenancyCheck}
                  ballotsEnabled={ballotsEnabled}
                />
                {!hideBGCheckSection && (
                  <BackgroundCheckContainer
                    firstName={firstName}
                    optOutBackgroundCheck={optOutBackgroundCheck}
                    attachedToProfile={attachedToProfile}
                    isBackgroundCheckEditable={isApplicationEditable}
                    onEditClick={this.onEditClick(urlQueryString)}
                    backgroundCheck={backgroundCheck}
                    backgroundCheckDate={backgroundCheckDate}
                    fetchBackgroundCheckReportDetails={
                      this.props.fetchBackgroundCheckReportDetails
                    }
                    excludeBGCBankruptcyReport={excludeBGCBankruptcyReport}
                  />
                )}
              </div>
            </Display.CenterContentContainer>
            <Display.CenterContentContainer componentClass="application-standard-shadow-box mt80 col-xs-12 summary-box-on-mobile">
              <div className="mb0">
                <Headers.SectionHeader text="Supporting" textClass="fw600" />
                <EmergencyContactContainer
                  emergencyContacts={emergencyContacts}
                  isEmergencyContactsEditable={isApplicationEditable}
                  onEditClick={this.onEditClick(urlQueryString)}
                  componentClassName="bbs"
                />
                <PersonalReferenceContainer
                  personalReferences={personalReferences}
                  isPersonalReferenceEditable={isApplicationEditable}
                  onEditClick={this.onEditClick(urlQueryString)}
                />
              </div>
            </Display.CenterContentContainer>
            <Display.CenterContentContainer componentClass="application-standard-shadow-box mt80 col-xs-12 summary-box-on-mobile">
              <div className="mb0">
                <Headers.SectionHeader text="Extras" textClass="fw600" />
                <PetsContainer
                  pets={pets}
                  optOutPets={optOutPets}
                  isRenterDisplay="true"
                  isPetsEditable={isApplicationEditable}
                  onEditClick={this.onEditClick(urlQueryString)}
                  componentClassName="bbs"
                />
                <StudentContainer
                  students={studentDetails}
                  optInStudentDetails={optInStudentDetails}
                  isStudentEditable={isApplicationEditable}
                  onEditClick={this.onEditClick(urlQueryString)}
                  componentClassName="bbs"
                />
                <OtherDocumentContainer
                  attachments={attachments}
                  isOtherDocumentEditable={isApplicationEditable}
                  onEditClick={this.onEditClick(urlQueryString)}
                  componentClassName="bbs"
                />
                <Display.SectionContentContainer>
                  <Text.Strong
                    text="Important Information"
                    textClass="fw500 fs24 lh24"
                    linkAdded={false}
                  />

                  <Display.FlexContainer containerClass="mt15">
                    <DisclosureQuestionsDisplay
                      propertyDisclosure={disclosures}
                      hasPropertyDisclosure={hasPropertyDisclosure}
                    />
                  </Display.FlexContainer>
                </Display.SectionContentContainer>
                <DisclosureConsentContainer
                  isPrimaryApplicant={applicant.isPrimary}
                  optedInAsSmoker={optedInAsSmoker}
                  toggleOptedInAsSmoker={this.toggleOptedInAsSmoker}
                  optedToAcceptCondition={optedToAcceptCondition}
                  optedToAcceptDisclosureConsent={
                    optedToAcceptDisclosureConsent
                  }
                  optedToReceiveUtilityConnection={
                    optedToReceiveUtilityConnection
                  }
                  directConnectTermsViewed={directConnectTermsViewed}
                  optedToReceiveRayWhiteConciergeService={
                    optedToReceiveRayWhiteConciergeService
                  }
                  optedToReceiveBondLoanInformation={
                    optedToReceiveBondLoanInformation
                  }
                  optedToRequirePostingClause={optedToRequirePostingClause}
                  optedInToPetConsent={optedInToPetConsent}
                  optedToReceiveEasyBondpay={optedToReceiveEasyBondpay}
                  utilityProviderName={utilityProvider.name}
                  teamSettingInfo={teamSettingInfo}
                  toggleDisclosureOptions={this.toggleDisclosureOptions}
                  conditionAcceptanceType={conditionAcceptanceType}
                  propertyInspectionType={propertyInspectionType}
                  onChangeInspectionType={this.onChangeInspectionType}
                  onPropertyInspectedDateUpdate={
                    this.onPropertyInspectedDateUpdate
                  }
                  pets={pets}
                  propertyInspectedDate={propertyInspectedDate}
                  error={inspectedDateError}
                  propertyOffer={propertyOffer}
                  teamName={teamName}
                  onChangeDisclosureYesOrNoQuestions={(id, value) => {
                    this.onChangeDisclosureYesOrNoQuestions(id, value)
                  }}
                  disclosureYesOrNoAnswers={this.state.disclosureYesOrNoAnswers}
                  submittedDate={submittedDate}
                  appliedAt={appliedAt}
                  allowApplicantToSkipViewingDisclosures={
                    allowApplicantToSkipViewingDisclosures
                  }
                  optedToSkipViewingDisclosures={optedToSkipViewingDisclosures}
                />
              </div>

              {application && Object.keys(application).length && (
                <>{this.renderAgencyApplicationsLimitAlert(false)}</>
              )}
              <AlertInfo
                m={`${theme.space[7]}px`}
                message={
                  'To protect your privacy, applications are automatically withdrawn after 60 days.'
                }
              ></AlertInfo>
            </Display.CenterContentContainer>
          </div>
        )}

        <Display.BottomContentContainer>
          <Display.CenterContentContainer componentClass="width100">
            <div className="submit-button-container">
              <BottomButton
                showArrow={false}
                btnText={this.btnTextChange()}
                bottomButtonsClass="pt0 mt0 summary-bottom-button"
                nextBtnId={!applicant.applied ? `${teamSlug}` : ''}
                nextBtnDisabled={submitBtnClicked}
                clickNextFunction={
                  shouldSubmitWithoutDelayNotice
                    ? () => {
                        this.setState({ submitBtnClicked: true })
                        this.onSubmitClick(
                          applicant,
                          applicationId,
                          applicantSummary,
                          utilityProvider.name,
                          idPoint,
                        )
                      }
                    : () => this.setState({ openAAModal: true })
                }
                nextBtnClass={`summary-green-button-class ${
                  !applicant.applied ? submitApplicationTagClass : ''
                }`}
                backButtonClass="summary-grey-button-class"
                nextButtonPositionClass="pr20 pl0"
              />
            </div>
          </Display.CenterContentContainer>
        </Display.BottomContentContainer>
        {showViewingCheckinModal && (
          <BasicModal
            toggleModal={this.onToggleModal}
            title={
              'Please inspect the property before submitting the application'
            }
            body={
              <div>
                <p>
                  This property manager requires an applicant to have inspected
                  the property prior.
                </p>
                <p>
                  Don't worry your Snug profile and application draft has been
                  saved. Please register to view the property, then once
                  checked-in you can submit your application.
                </p>
              </div>
            }
            primaryButtonLabel={'Book a time'}
            primaryButtonAction={() => this.onBookATimeButtonClicked(property)}
            showSecondaryButton={false}
          />
        )}
        {renterHasJointApplication && (
          <Display.Modal
            title="Submit Application"
            hideButtonSection={true}
            toggleModal={() => this.toggleJoinApplicationModal()}
          >
            <div className="text-center">
              You are already invited on a submitted application for{' '}
              <strong>{friendlyName}</strong> with{' '}
              <strong>
                {renterJointApplication.applicant.firstName}{' '}
                {renterJointApplication.applicant.lastName}
              </strong>
              . Join your application to speed up processing. Your current draft
              will be saved.
            </div>
            <Display.ModalButtonGroup
              primaryLabel="Join application"
              secondaryLabel="Submit separately"
              secondaryButtonType="tertiary"
              primaryAction={() => {
                this.setState({ submitBtnClicked: true })
                this.joinApplication(applicant, renterJointApplication)
              }}
              secondaryAction={() => {
                this.setState({ submitBtnClicked: true })
                this.onApplyButtonClick(
                  applicant,
                  applicationId,
                  applicantSummary,
                  utilityProvider.name,
                )
              }}
            />
          </Display.Modal>
        )}
        {openAAModal && (
          <Display.Modal
            toggleModal={this.onCloseModal}
            title="Submit Application"
            hideButtonSection
          >
            <div className="fs16">
              <p>
                Once you submit, your rental references and employment
                confirmation will be requested from your nominated contacts
                before delivering your application (up to 24 hours). Once your
                references and employment confirmation are complete, future
                applications will be delivered when submitted.
              </p>
            </div>
            <Display.ModalButtonGroup
              primaryLabel="Submit"
              secondaryLabel="Cancel"
              secondaryButtonType="tertiary"
              primaryAction={() => this.onClickOkButton()}
              secondaryAction={this.onCloseModal}
            />
          </Display.Modal>
        )}
        {applicationsCloseModalOpen && (
          <ApplicationClosedModal
            teamSlug={teamSlug}
            teamName={teamName}
            toggleApplicationsCloseModal={this.toggleApplicationsCloseModal}
          />
        )}
      </div>
    )
  }
}

export default ApplicationSummaryContainer
