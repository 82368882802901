import React from 'react'

import { NavLink } from 'react-router-dom'

import { urlTo } from 'app/sm/helpers'

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="navLinks">
        <ul>
          <li>
            <NavLink to={'/admin'}>
              <span>Admin</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={urlTo('createAgency')}>
              <span>Create Agency</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={urlTo('registerAgency')}>
              <span>Register Agency</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/agency/edit">
              <span>Edit Agency</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={urlTo('supplierSource')}>
              <span>Add/Edit Supplier Source</span>
            </NavLink>
          </li>
        </ul>
      </div>
    )
  }
}

export default AdminNavbar
