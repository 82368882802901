import React from 'react'

import { Link } from 'react-router-dom'

import PayContainer from 'app/shared_components/payment_form/pay_container'
import { urlTo } from 'app/sm/helpers'
import { isCurrentRoute } from 'app/sm/router_helpers'

class Pay extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { offerId } = this.props.match.params
    this.props.fetchCards()
    this.props.fetchActiveAd(offerId)
  }

  isPaymentDisabled() {
    const { acceptTermsAndConditions } = this.props
    return !acceptTermsAndConditions
  }
  render() {
    const {
      ad,
      match,
      cards,
      selectedCreditCard,
      cardSelect,
      registerPayment,
      acceptTermsAndConditions,
      acceptTermsConditionsChange,
      payWithExistingCard,
      listingFee,
      error,
    } = this.props

    const { id: propertyId, offerId } = match.params

    return (
      <PayContainer
        {...this.props}
        backUrl={
          isCurrentRoute('adFlowPayment')
            ? urlTo('adFlowPortals', {
                propertyId: match.params.propertyId,
                offerId,
              })
            : urlTo('updateOfferPortals', { propertyId, offerId })
        }
        payForWhat={'Advertisement payment'}
        amount={listingFee}
        GSTParole="$110 inc GST"
        buttonParole={`Pay $${listingFee} for Advertisement`}
        error={error}
      />
    )
  }
}

export default Pay
