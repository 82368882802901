import React from 'react'

import headerImg02 from 'app/assets/icons/header-img-02.png'
import headerImg04 from 'app/assets/icons/header-img-04.png'
import headerImg05 from 'app/assets/icons/header-img-05.png'

class AccordionStrap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accordion1: false,
      accordion2: false,
      accordion3: false,
    }
  }

  update(field) {
    return (event) => {
      let value = !this.state[field]
      this.setState({ [field]: value })
    }
  }

  render() {
    let { accordion1, accordion2, accordion3 } = this.state
    return (
      <div className="faq-box bg-white mt0 pt10">
        <h3 className="mb30">What you'll need</h3>

        <p>
          Completing your BondCover application is easy if you have your
          documents handy. Applying takes <b>about 2 minutes</b>.
        </p>

        <div className="panel" onClick={this.update('accordion1')}>
          <a className={accordion1 ? '' : 'collapsed'} role="button">
            1. Lease Details
          </a>
          <div
            id="currentLease"
            className={accordion1 ? 'collapse show' : 'collapse'}
          >
            <div className="panel-inner">
              <img src={headerImg02} width="70" />
              <p>
                To be able to issue BondCover we'll need your lease details.
              </p>
            </div>
          </div>
        </div>

        <div className="panel" onClick={this.update('accordion2')}>
          <a className={accordion2 ? '' : 'collapsed'} role="button">
            2. Identification
          </a>
          <div
            id="identification"
            className={accordion2 ? 'collapse show' : 'collapse'}
          >
            <div className="panel-inner">
              <img src={headerImg04} width="70" />
              <p>
                Provide a valid Driver Licence or Passport so we know who you
                are.
              </p>
            </div>
          </div>
        </div>

        <div className="panel" onClick={this.update('accordion3')}>
          <a className={accordion3 ? '' : 'collapsed'} role="button">
            3. Payment Details
          </a>
          <div
            id="bankAccount"
            className={accordion3 ? 'collapse show' : 'collapse'}
          >
            <div className="panel-inner">
              <img src={headerImg05} width="70" />
              <p>
                You will be able to pay for BondCover with your Credit Card{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AccordionStrap
