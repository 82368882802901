export const AGENCY_USER_ADMIN = 1
export const AGENCY_USER_FINANCE = 2
export const AGENCY_USER_LEASE_CONSULTANT = 3

export const UserAdminFinance = 'adminOrFinance'
export const UserLeasingConsultant = 'leasingConsultant'

export const isUserAnAdmin = (currentAgency) => {
  const { roles } = currentAgency
  return roles?.some((role) => role.roleID === AGENCY_USER_ADMIN)
}

export const isUserACasualInspectorOrLeasingConsultant = (roles = []) => {
  return roles?.some((role) => role.roleID === AGENCY_USER_LEASE_CONSULTANT)
}
