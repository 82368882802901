import React from 'react'

import styled from 'styled-components'

import { ClearRounded } from 'app/components/design-system-components/icons/content'
import { Flex, IconButton } from 'app/components/design-system-components/index'
import * as getters from 'app/components/design-system-components/theme/getters'
import { Text } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const StyledAppliedWrapper = styled(Flex)`
  align-items: center;
  padding: ${getters.spacing(3)};
  background-color: ${getters.color('paleBlue')};
  font-size: ${theme.fontSizes.pSmall12};

  border-radius: ${getters.radius(6)}px;

  .applied-filters {
    color: ${getters.color('skyBlue')};
  }
`

export const AppliedFilter = ({
  filterTitle,
  renderedAppliedFilters,
  onDismiss,
  ...containerProps
}) => {
  return (
    <StyledAppliedWrapper {...containerProps}>
      <IconButton
        IconCmp={ClearRounded}
        iconProps={{
          fontSize: theme.fontSizes[4] + 'px',
          color: theme.colors.gray600,
        }}
        variant="flat"
        onClick={onDismiss}
        mr={theme.space[3] + 'px'}
      />
      <Text as="span" mr={theme.space[3] + 'px'}>
        {filterTitle}:
      </Text>
      <div className="applied-filters">{renderedAppliedFilters}</div>
    </StyledAppliedWrapper>
  )
}
