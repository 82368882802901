import React from 'react'

import 'app/components/display/white-box-container/style.scss'

const WhiteBoxContainer = ({ children, componentClass }) => {
  return (
    <div
      className={`white-box application-standard-shadow-box border-radius-10px ${componentClass}`}
    >
      {children}
    </div>
  )
}

export default WhiteBoxContainer
