import React from 'react'

import styled from 'styled-components'

import theme from 'app/match/applicationReportPDF/assets/theme'

const ExitContainer = styled.div`
  padding-top: ${theme.space[7]}px;
`

const ConfirmExitTeamMember = () => {
  return (
    <ExitContainer>
      You have not invited any team members. Do you want to close or invite team
      members?
    </ExitContainer>
  )
}

export default ConfirmExitTeamMember
