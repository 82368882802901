import React from 'react'

const EmailLink = ({
  linkText = '',
  normalText = '',
  mailTo = '',
  subject = '',
}) => {
  return (
    <span className="font-size-16">
      <a
        className="blue-link-style"
        href={`mailto:${mailTo}${subject && `?subject=${subject}`}`}
      >
        {linkText}
      </a>
      <span>&nbsp;{normalText}</span>
    </span>
  )
}

export default EmailLink
