import React from 'react'

import { Link } from 'react-router-dom'

import humanIcon01 from 'app/assets/icons/human01.jpg'
import humanIcon02 from 'app/assets/icons/human02.jpg'
import humanIcon03 from 'app/assets/icons/human03.jpg'

function MaintenanceLog() {
  let MockData = [
    {
      icon: 'icon-maintenece',
      iconColor: 'green',
      iconBg: 'green-bg-light-rgba02',
      title: 'Maintenence Created',
      userAvatar: humanIcon02,
      userFirstName: 'Anne',
      userLastName: 'Quincy',
      position: 'Co-tenant',
      date: '24 July 2017',
      time: '09:30pm',
    },
    {
      icon: 'icon-dot',
      iconColor: 'gray-light',
      iconBg: 'gray-light-bg-light-rgba02',
      title: 'Pending Approval',
      userAvatar: humanIcon01,
      userFirstName: 'Justin',
      userLastName: 'Butterworth',
      position: 'Landlord',
      date: '24 July 2017',
      time: '09:30pm',
    },
    {
      icon: 'icon-checkmark',
      iconColor: 'white',
      iconBg: 'green-bg',
      title: 'Maintenance Approved!',
      userAvatar: '',
      userFirstName: 'Justin',
      userLastName: 'Butterworth',
      position: 'Landlord',
      date: '24 July 2017',
      time: '09:30pm',
    },
    {
      icon: 'icon-dot',
      iconColor: 'yellow',
      iconBg: 'yellow-bg-light-rgba02',
      title: 'Pending Assignee',
      userAvatar: humanIcon01,
      userFirstName: 'Justin',
      userLastName: 'Butterworth',
      position: 'Landlord',
      date: '24 July 2017',
      time: '09:30pm',
    },
    {
      icon: 'icon-checkmark',
      iconColor: 'white',
      iconBg: 'green-bg',
      title: 'Assigned Service Provider',
      userAvatar: humanIcon03,
      userFirstName: 'James',
      userLastName: 'Timothy',
      position: 'Service Provider',
      date: '24 July 2017',
      time: '09:30pm',
    },
    {
      icon: 'icon-dot',
      iconColor: 'blue',
      iconBg: 'blue-bg-light-rgba02',
      title: 'Pending Inspection',
      userAvatar: humanIcon03,
      userFirstName: 'James',
      userLastName: 'Timothy',
      position: 'Service Provider',
      date: '24 July 2017',
      time: '09:30pm',
    },
  ]

  return (
    <div className="sm-maintenance-report-log">
      <div className="section-title mt30 xs-row">
        <div className="left">
          <h3>
            <span className="xs-hide">Maintenance</span> Log (5)
          </h3>
        </div>
        <div className="right">
          <div className="input-box single wa ibm">
            <select className="wa lh12">
              <option>Descending</option>
              <option>Ascending</option>
            </select>
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>Name</td>
            <td>Created by</td>
            <td>Date Created</td>
          </tr>
        </thead>
        <tbody>
          {MockData.map((item, index) => (
            <tr>
              <td>
                <div className={`icon ${item.iconBg}`}>
                  <i className={`${item.icon} ${item.iconColor}-color`}></i>
                </div>
                <div className="title">{item.title}</div>
              </td>
              <td>
                <div className="table">
                  <div className="cell-m pr10">
                    {item.userAvatar != '' ? (
                      <div
                        className="avatar avatar-msmall"
                        style={{
                          backgroundImage: `url('${item.userAvatar}')`,
                        }}
                      ></div>
                    ) : (
                      <div className="avatar avatar-msmall blue-bg-light-rgba blue-color">
                        {item.userFirstName.charAt(0)}
                        {item.userLastName.charAt(0)}
                      </div>
                    )}
                  </div>
                  <div className="cell-m-100">
                    <div className="fs16 fw600">
                      {item.userFirstName} {item.userLastName.charAt(0)}.
                    </div>
                    <div className="fs14 fw600 gray-color pt3">
                      {item.position}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="fs16 fw600">{item.date}</div>
                <div className="fs14 fw600 gray-color pt3">{item.time}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default MaintenanceLog
