import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import Avatar from 'app/application_revamp/components/Common/Avatar'
import Chart from 'app/application_revamp/components/Common/Chart'
import ApplicantRow from 'app/application_revamp/components/Layout/ApplicantRow'
import ApplicationHolder from 'app/application_revamp/components/Layout/ApplicationHolder'

const propTypes = {
  application: PropTypes.object,
}

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;

  li {
    display: inline-block;
  }

  li + li {
    margin-left: 16px;
  }
`

const Time = styled.time`
  display: block;
  color: var(--pdf-gray);
  margin-bottom: 4px;
`

function Application({ application }) {
  const {
    image_url,
    name,
    user_src,
    snug_match_score,
    submitt_time,
    submitted_date,
    additional_guests,
    max_rent_price,
    ratio,
  } = application
  const date = `${submitted_date} ${submitt_time}`
  const additional = additional_guests ? ` +${additional_guests}` : ''

  return (
    <ApplicantRow>
      <Avatar image_url={image_url} name={name} user_src={user_src} />
      <Chart snug_match_score={snug_match_score} />
      <ApplicationHolder>
        <Time dateTime={date}>{date}</Time>
        <p>{`${name}${additional}`}</p>
        <List>
          <li>Rent: ${max_rent_price}</li>
          <li>Ratio: {ratio}%</li>
        </List>
      </ApplicationHolder>
    </ApplicantRow>
  )
}

Application.propTypes = propTypes

export default Application
