import { connect } from 'react-redux'

import {
  addRentalHistory,
  deleteRentalHistory,
  deleteTenantReference,
  editRentalHistory,
  fetchManagersForRentalHistory,
  fetchPropertyAttributes,
  fetchRentalReference,
  resetRentalReference,
} from 'app/bond_cover/bond_cover_actions'
import RentalHistoryAdd from 'app/profile/renter/rental_history/rental_history_form'

const mapStateToProps = ({ Shared, bondCover, session, apply }) => ({
  historyPayload: bondCover.historyPayload,
  attributes: bondCover.attributes,
  rentalReference: bondCover.rentalReference,
  isEditMode: bondCover.rentalHistoryEditMode,
  currentUser: session.currentUser,
  property: apply.property,
  backUrl: Shared.backUrl,
})

const mapDispatchToProps = (dispatch) => ({
  fetchPropertyAttributes: () => dispatch(fetchPropertyAttributes()),
  addRentalHistory: (rentalHistory, success, fail) =>
    dispatch(addRentalHistory(rentalHistory, success, fail)),
  fetchRentalReference: (id) => dispatch(fetchRentalReference(id)),
  deleteTenantReference: (rentalHistoryGUID, rentalReferenceGUID) =>
    dispatch(deleteTenantReference(rentalHistoryGUID, rentalReferenceGUID)),
  editRentalHistory: (rentalHistory, id, success, fail) =>
    dispatch(editRentalHistory(rentalHistory, id, success, fail)),
  reset: () => dispatch(resetRentalReference()),
  fetchManagersForRentalHistory: (searchValue) =>
    dispatch(fetchManagersForRentalHistory(searchValue)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RentalHistoryAdd)
