export const unsubscribeViews = {
  team: 'teamCategory',
  property: 'propertyCategory',
  bgcAnniversary: 'bgcAnniversaryCategory',
  propertyMatchCategory: 'propertyMatchCategory',
  rentalLedgerReminder: 'rentalLedgerReminder',
}

export const unsubscribeCategoryOptions = {
  globalAlerts: {
    id: 'GlobalAlert',
    questionTitle: 'All emails from Snug',
  },
  propertyAlerts: {
    id: 'ViewingAdded',
    questionTitle:
      'Alerts for this property (e.g. viewing notices, price reduction)',
  },
  teamAlerts: {
    id: 'PropertyAlert',
    questionTitle: `This real estate agency's emails`,
  },
  bgcAlerts: {
    id: 'BGCheckAnniversartEmailAlert',
    questionTitle: 'Background Check reminders',
  },
  propertyMatchAlerts: {
    id: 'PropertyMatchAlert',
    questionTitle: 'Property match alerts',
  },
  rentalLedgerReminder: {
    id: 'RentalLedgerAlert',
    questionTitle: 'Rental Ledger reminder',
  },
}

export const TEAM_UNSUBSCRIBE_PATH = 'unsubscribe-team'
export const PROPERTY_UNSUBSCRIBE_PATH = 'unsubscribe-property' //FIX
export const BGC_ANNIVERSARY_UNSUBSCRIBE_PATH = 'unsubscribe-bgc'
export const PROPERTY_MATCH_UNSUBSCRIBE_PATH = 'unsubscribe-property-match'
export const RENTAL_LEDGER_UNSUBSCRIBE_PATH = 'unsubscribe-rental-ledger'

export const unsubscribeCategoriesConfig = {
  [unsubscribeViews.team]: {
    path: TEAM_UNSUBSCRIBE_PATH,
    title: 'Property Alerts',
    showSurvey: true,
    validate: true,
    hasReason: true,
    options: [
      unsubscribeCategoryOptions.teamAlerts,
      unsubscribeCategoryOptions.globalAlerts,
    ],
  },
  [unsubscribeViews.property]: {
    path: PROPERTY_UNSUBSCRIBE_PATH,
    title: 'Property Alerts',
    showSurvey: true,
    validate: true,
    hasReason: true,
    options: [
      unsubscribeCategoryOptions.propertyAlerts,
      unsubscribeCategoryOptions.teamAlerts,
      unsubscribeCategoryOptions.globalAlerts,
    ],
  },
  [unsubscribeViews.bgcAnniversary]: {
    path: BGC_ANNIVERSARY_UNSUBSCRIBE_PATH,
    options: [
      unsubscribeCategoryOptions.globalAlerts,
      unsubscribeCategoryOptions.bgcAlerts,
    ],
  },
  [unsubscribeViews.propertyMatchCategory]: {
    path: PROPERTY_MATCH_UNSUBSCRIBE_PATH,
    title: 'Property Match Alerts',
    showSurvey: true,
    hasReason: true,
    options: [
      unsubscribeCategoryOptions.globalAlerts,
      unsubscribeCategoryOptions.propertyMatchAlerts,
    ],
  },
  [unsubscribeViews.rentalLedgerReminder]: {
    path: RENTAL_LEDGER_UNSUBSCRIBE_PATH,
    title: unsubscribeCategoryOptions.rentalLedgerReminder.questionTitle,
    options: [
      unsubscribeCategoryOptions.globalAlerts,
      unsubscribeCategoryOptions.rentalLedgerReminder,
    ],
  },
}
