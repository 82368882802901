import { FC, SetStateAction, useEffect, useState } from 'react'

import { Field, Form, FormikProps } from 'formik'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Box from 'app/components/design-system-components/Box'
import { Button } from 'app/components/design-system-components/Button'
import Flex from 'app/components/design-system-components/Flex'
import { HelpOutlineRounded } from 'app/components/design-system-components/icons/actions'
import {
  AutocompleteField,
  TextArea,
} from 'app/components/design-system-components/inputs'
import { TagsInputField } from 'app/components/design-system-components/inputs/TagsInput/TagsInput'
import { formFields } from 'app/components/design-system-components/InviteToApply/form.utils'
import { MessagePreview } from 'app/components/design-system-components/InviteToApply/MessagePreview'
import { StyledRecipientField } from 'app/components/design-system-components/InviteToApply/styles'
import { CheckboxInput } from 'app/features/teams/applications/share-applications/ShareApplicationsModal/FormBody'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import { searchTeamContacts } from 'app/services/http/teams/contacts'
import { searchProperty } from 'app/services/http/teams/properties'

export const getOptionAndMetadataForApplicant = (
  email = '',
  firstName = '',
  lastName = '',
  phone = '',
) => {
  const formedOption = {
    renterEmail: email,
    renterFirstName: firstName,
    renterLastName: lastName,
    renterContactNumber: phone,
  }
  const formedOptionMeta = {
    id: email,
    displayText: email,
  }

  return {
    option: formedOption,
    optionMeta: formedOptionMeta,
  }
}

type SearchedPropertiesResponse = {
  properties: {
    data: [{ property: { guidID: string; address: { friendlyName: string } } }]
  }
}

type SearchedContacts = {
  contacts: [
    {
      guidID: string
      email: string
      firstName: string
      lastName: string
      phone: string
    },
  ]
}

interface IFormBodyProps {
  formBag: FormikProps<any>
  teamGUID: string
  toggleModal: () => void
}

export const FormBody: FC<IFormBodyProps> = ({
  teamGUID = '',
  formBag: { values, setFieldValue, validateField },
  toggleModal = () => {},
}) => {
  const [loadingProperties, setLoadingProperties] = useState(false)
  const [propertiesOptions, setPropertiesOptions] = useState([])
  const [showPreview, setShowPreview] = useState(false)

  const [contactOptions, setContactOptions] = useState([])
  const [loadingcContacts, setLoadingContacts] = useState(false)
  const [searchedForContact, setSearchedForContact] = useState('')

  useEffect(() => {
    const { option: contact } = values[formFields.contact] || {}
    if (contact) {
      const {
        email = '',
        firstName = '',
        lastName = '',
        phone = '',
      } = contact || {}

      const combinedOption = getOptionAndMetadataForApplicant(
        email,
        firstName,
        lastName,
        phone,
      )

      setFieldValue(formFields.applicants, [
        ...values[formFields.applicants],
        combinedOption,
      ])
      setFieldValue(formFields.contact, null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[formFields.contact]])

  const handleApplicantsBlur = () => {
    const applicants = getCombinedApplicant()
    setFieldValue(formFields.applicants, applicants)
    validateField(formFields.applicants)
  }

  const onSearchProperties = debounce((searchText: string) => {
    setLoadingProperties(true)
    return searchProperty(teamGUID, searchText)
      .then((response: SearchedPropertiesResponse) => {
        const filteredProperties = response.properties?.data || []
        return filteredProperties.map(({ property }) => ({
          optionMeta: {
            id: property.guidID,
            displayText: property.address.friendlyName,
          },
          option: property,
        }))
      })
      .then((filteredPropertyOptions: []) =>
        setPropertiesOptions(filteredPropertyOptions),
      )
      .finally(() => setLoadingProperties(false))
  }, STANDARD_TIMEOUT)

  const onContactInputChange = (searchText: SetStateAction<string>) => {
    setSearchedForContact(searchText)
    onSearchContacts(searchText)
  }

  const onSearchContacts = debounce((searchText: string) => {
    setLoadingContacts(true)
    return searchTeamContacts(teamGUID, searchText)
      .then((response: SearchedContacts) => {
        const filteredProperties = response?.contacts || []
        return filteredProperties.map((contact) => ({
          optionMeta: {
            id: contact.guidID,
            displayText:
              contact?.firstName +
              ' ' +
              contact?.lastName +
              ' ' +
              contact?.phone +
              ' ' +
              contact?.email,
          },
          option: contact,
        }))
      })
      .then((filteredPropertyOptions: []) => {
        setContactOptions(filteredPropertyOptions)
      })
      .finally(() => {
        setLoadingContacts(false)
      })
  }, STANDARD_TIMEOUT)

  const getCombinedApplicant = () => {
    const allApplicants = values[formFields.applicants] || []
    const manuallyAddedApplicants = allApplicants.filter(
      (applicant: { option: any }) => typeof applicant.option === 'string',
    )
    const applicantsAddedThroughContact = allApplicants.filter(
      (applicant: { option: any }) => typeof applicant.option !== 'string',
    )
    const mappedManuallyAddedApps = manuallyAddedApplicants.map(
      ({ option }: any) => {
        return getOptionAndMetadataForApplicant(option)
      },
    )
    return [...applicantsAddedThroughContact, ...mappedManuallyAddedApps]
  }

  const onEnterPressed = (event: {
    which: number
    preventDefault: () => any
  }) => {
    const applicants = getCombinedApplicant()
    setFieldValue(formFields.applicants, [
      ...applicants,
      getOptionAndMetadataForApplicant(searchedForContact),
    ])
    setSearchedForContact('')
    setFieldValue(formFields.contact, null)
    validateField(formFields.applicants)
    event.which === 13 && event.preventDefault()
  }

  return (
    <Form>
      {showPreview ? (
        <MessagePreview {...values} />
      ) : (
        <>
          <FieldWrapper
            id={formFields.contact}
            label="TO"
            required={true}
            name={formFields.contact}
            labelProps={undefined}
            my={5}
          >
            <AutocompleteField
              name={formFields.contact}
              placeholder="Search Name, mobile, enter to add"
              options={contactOptions}
              onChangeText={onContactInputChange}
              isLoading={loadingcContacts}
              onEnterPressed={onEnterPressed}
            />
          </FieldWrapper>
          <StyledRecipientField
            id={formFields.applicants}
            name={formFields.applicants}
          >
            <TagsInputField
              id={formFields.applicants}
              name={formFields.applicants}
              blurHandler={() => {
                handleApplicantsBlur()
              }}
            />
          </StyledRecipientField>
          <FieldWrapper
            id={formFields.property}
            label="PROPERTY"
            required={true}
            name={formFields.property}
            labelProps={undefined}
            my={5}
          >
            <AutocompleteField
              name={formFields.property}
              placeholder="Add Property"
              options={propertiesOptions}
              onChangeText={onSearchProperties}
              isLoading={loadingProperties}
            />
          </FieldWrapper>
          <FieldWrapper
            id={formFields.message}
            label="COMMENT"
            required={false}
            name={formFields.message}
            mb={5}
          >
            <Field name={formFields.message} as={TextArea} rows={10} />
          </FieldWrapper>

          <FieldWrapper
            id={formFields.allowWithoutViewingProperty}
            name={formFields.allowWithoutViewingProperty}
          >
            <Flex alignItems="center">
              <Field
                name={formFields.allowWithoutViewingProperty}
                as={CheckboxInput}
                label="Allow applying without viewing property"
              />
              <Flex alignItems="center">
                <Flex ml={2}>
                  <HelpOutlineRounded
                    data-tooltip-content=""
                    data-tooltip-id={formFields.allowWithoutViewingProperty}
                  />
                  <ReactTooltip
                    id={formFields.allowWithoutViewingProperty}
                    data-type="info"
                    place="top"
                    className="tool-tip-style"
                  >
                    <Box maxWidth="222px" textAlign="center">
                      Require accepting the property condition without viewing
                    </Box>
                  </ReactTooltip>
                </Flex>
              </Flex>
            </Flex>
          </FieldWrapper>
        </>
      )}
      <Flex width="100%" mt={5}>
        <Flex width="50%">
          <Button
            variant="solidSecondary"
            mr={3}
            width="50%"
            type="button"
            onClick={(event: { preventDefault: () => void }) => {
              event?.preventDefault()
              toggleModal()
            }}
          >
            Cancel
          </Button>
          <Button
            variant="solidSecondary"
            mr={3}
            width="50%"
            type="button"
            onClick={(event: { preventDefault: () => void }) => {
              event?.preventDefault()
              setShowPreview(!showPreview)
            }}
          >
            {showPreview ? 'Edit' : 'Preview'}
          </Button>
        </Flex>
        <Box width="50%">
          <Button variant="solidSuccess" width="100%" type="submit">
            Send
          </Button>
        </Box>
      </Flex>
    </Form>
  )
}
