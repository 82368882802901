import React from 'react'

import { Link, NavLink, Route } from 'react-router-dom'

import { urlTo } from 'app/sm/helpers'

const ApplicationsCount = ({ applicationsCount }) => (
  <li>
    <NavLink to={urlTo('applications')}>
      <i className="icon-applications"></i>
      <span>My Applications</span>
      <div className="badge">{applicationsCount}</div>
    </NavLink>
  </li>
)

export default ApplicationsCount
