import { connect } from 'react-redux'

import { clearError, createAgency } from 'app/bond_cover/agency/agency_actions'
import CreateAgencyFormContainer from 'app/bond_cover/agency/create_agency_form_container'

const mapStateToProps = ({ agency }) => ({
  error: agency.error,
})

const mapDispatchToProps = (dispatch) => ({
  createAgency: (agency, success) => dispatch(createAgency(agency, success)),
  clearError: () => dispatch(clearError()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAgencyFormContainer)
