import { api } from 'app/utils/api/helpers'

export const shortListApplication = (applicationGUID, params = {}) => {
  return api.post(
    `/sm/applications/${applicationGUID}/shortlist`,
    {},
    { params },
  )
}

export const updateApplicantAcknowledgementofPoints = (
  applicationGUID,
  applicantGUID,
  acknowledgeSupplyOfIDDocuments,
) => {
  return api.put(
    `/sm/applications/${applicationGUID}/applicant/${applicantGUID}/acksupplyofiddocs`,
    { acknowledgeSupplyOfIDDocuments },
  )
}

export const getApplicantAcknowledgementofPoints = (
  applicationGUID,
  applicantGUID,
  acknowledgeSupplyOfIDDocuments,
) => {
  return api.get(
    `/sm/applications/${applicationGUID}/applicant/${applicantGUID}/acksupplyofiddocs`,
  )
}

export const sendViewingInviteToApplicant = (applicationGUID) => {
  return api.post(`/sm/applications/${applicationGUID}/invitetoviewing`)
}

export const getApplicationsDetails = (
  agencyGUID,
  offerGUID,
  applicationGUID,
) => {
  return api.get(
    `/sm/applications/${applicationGUID}/team/${agencyGUID}/offer/${offerGUID}/applicationsdetails`,
  )
}

export const getPropertyApplicationsForManager = (propertyId) => {
  return api.get(`/sm/properties/${propertyId}/applications`)
}

export const createApplicationMergeRequest = (applicationId, payload) => {
  return api.post(`/sm/applications/${applicationId}/merge`, payload)
}

export const approveApplicationMergeRequest = (applicationId, token) => {
  return api.post(
    `/sm/applications/${applicationId}/merge/approve?code=${token}`,
  )
}

export const changeApplicationOrder = (applicationId, payload) => {
  return api.put(
    `/sm/team/${payload.agencyGUID}/application/${applicationId}/sort`,
    payload,
  )
}

export const resetApplicationOrder = (
  agencyGUID,
  propertyGUID,
  applicationId,
) => {
  const payload = {
    propertyGUID,
  }
  return api.delete(
    `/sm/team/${agencyGUID}/application/${applicationId}/sort`,
    payload,
  )
}
