import { api } from 'app/utils/api/helpers'

export const getApplicationStatusActivity = (
  applicationId,
  applicantId,
  teamID,
  filters,
  cursor,
  pageSize,
) => {
  const categories = Object.keys(filters).filter((k) => filters[k])
  return api.get(
    `/sm/team/${teamID}/applications/${applicationId}/applicants/${applicantId}/status-activity`,
    {
      params: { classes: categories, cursor, page_size: pageSize },
    },
  )
}
