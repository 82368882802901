import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

import { Flex } from 'app/components/design-system-components'
import {
  ApplicationCategory,
  getWeeklyRentDisplay,
  isApplicantApplied,
  isJointApplication,
  isStatusApplied,
  isStatusDeclined,
  isStatusOffered,
  isStatusPMWithdrawn,
  NO_IMAGE_PLACEHOLDER_URL,
  urlTo,
} from 'app/sm/helpers'
import { RankPieChart } from 'app/sm/renter_applications/components/applications_card'

const AddPropertyButton = ({ onAddButtonClick, guidID, src }) => {
  return (
    <div className="addIcon">
      <img src={src} onClick={() => onAddButtonClick(guidID)} alt="add" />
    </div>
  )
}

const RemovePropertyButton = ({ onRemoveButtonClick, guidID, src }) => {
  return (
    <div className="removeIcon">
      <img src={src} onClick={() => onRemoveButtonClick(guidID)} alt="remove" />
    </div>
  )
}

const PropertyItem = ({
  propertyItem,
  weeklyRent,
  availableFrom,
  onAddButtonClick,
  onRemoveButtonClick,
  src,
  showAddButton,
  showRemoveButton,
}) => {
  const {
    guidID,
    images,
    address: { friendlyName },
    bathrooms,
    bedrooms,
    garages,
    teamSlug,
  } = propertyItem
  return (
    <div>
      <div className="col-xs-10">
        <Link
          className="team-width-60p display-flex overview-property-info"
          to={`${urlTo('prospectSummary', { teamSlug })}?property=${guidID}`}
        >
          <div
            className="team-image relative"
            style={{
              backgroundImage: `url("${
                images[0] ? images[0].URL : NO_IMAGE_PLACEHOLDER_URL
              }")`,
            }}
          ></div>
          <Flex flexDirection="column" justifyContent="center" pl={3}>
            <div className="font-size-18 mb10">
              <strong>{friendlyName}</strong>
            </div>
            <ul className="amenities-widget mb5">
              <li className="font-size-14 pr10">
                <i className="icon-bedroom"></i>
                <span>{bedrooms}</span>
              </li>
              <li className="font-size-14 pr10">
                <i className="icon-bathroom"></i>
                <span>{bathrooms}</span>
              </li>
              <li className="font-size-14 pr10">
                <i className="icon-cars"></i>
                <span>{garages}</span>
              </li>
            </ul>
            <div className="mb5 font-size-14">
              {getWeeklyRentDisplay(weeklyRent)}
            </div>
            <div className="font-size-14">
              Avail:{' '}
              {availableFrom
                ? moment(availableFrom).format('ddd DD MMM')
                : 'N/A'}
            </div>
          </Flex>
        </Link>
      </div>
      <div className="col-xs-2">
        {showAddButton && (
          <AddPropertyButton
            onAddButtonClick={onAddButtonClick}
            guidID={guidID}
            src={src}
          />
        )}
        {showRemoveButton && (
          <RemovePropertyButton
            onRemoveButtonClick={onRemoveButtonClick}
            guidID={guidID}
            src={src}
          />
        )}
      </div>
    </div>
  )
}

const AttachedPropertiesContainer = ({ attachedProperties }) => {
  return (
    <div>
      {attachedProperties &&
        attachedProperties.length > 0 &&
        attachedProperties.map((propertyData) => {
          return (
            attachedProperties && (
              <PropertyItem
                propertyItem={propertyData}
                weeklyRent={propertyData.offers[0].weeklyRent}
                availableDate={
                  propertyData &&
                  propertyData.offers &&
                  propertyData.offers[0].availableFrom
                }
                key={propertyData && propertyData.guidID}
              />
            )
          )
        })}
    </div>
  )
}

const formatLastEventAt = (lastEventAt) =>
  moment(lastEventAt).local().format('HH:mm ddd DD MMM')

export const StatusLabelColors = {
  green: 'status-green-label',
  red: 'status-red-label',
  grey: 'status-grey-label',
}

export const StatusLabel = ({
  color = 'green',
  label,
  lastEventAt,
  children,
}) => {
  const colorClass = StatusLabelColors[color]
  return (
    <div
      className={`font-size-14 text-align-center pl5 pt5 pb5 pr5 ${colorClass} desktop-std-btn`}
    >
      <div>
        {label} {formatLastEventAt(lastEventAt)}
      </div>
      {children}
    </div>
  )
}

export class ApplicationRowContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { application, showManagerEditedSummary } = this.props
    const { managerAddedRent, managerAddedLeaseTerm } = application
    const applicants = selectApplicants(this.props.application)
    const primaryApplicant = selectPrimaryApplicant(applicants) || {}
    // const rentIncomeRatio = rentToIncomeRatioCalculator(applicants, selectWeeklyRent(this.props.application));
    const applicationStatus = selectApplicationStatus(this.props.application)
    return (
      <div className="display-flex flex-direction-column border-bottom mb20 pb20">
        <div className="display-flex property-item-container">
          <div
            className={`team-width-100p display-flex property-item-info prospect-application-item-container hover-pointer`}
          >
            <Flex flexBasis="35%" className="prospect-mobile-section">
              <Flex
                flexBasis="35%"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                {formatUpdatedAt(selectSubmittedDate(this.props.application))}
              </Flex>
              <Flex
                flexBasis="35%"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <div className="meta" to="">
                  <div className="rank">
                    <div className="rank-info chart relative" title="Snug rank">
                      <RankPieChart rank={Math.round(primaryApplicant.score)} />
                      <div className="rank-value fontSize-13">
                        {Math.round(primaryApplicant.score)}
                      </div>
                    </div>
                  </div>
                </div>
              </Flex>
              <Flex className="team-width-35p display-flex justify-content-center align-items-center relative">
                <div
                  to={''}
                  className={`meta display-flex align-items-center ${
                    applicants.length > 1 ? 'pl10' : ''
                  }`}
                >
                  {!primaryApplicant.avatar ? (
                    <div
                      className={`avatar team-avatar ok relative`}
                      style={{
                        backgroundImage: `url(${primaryApplicant.avatar})`,
                      }}
                    >
                      <span className="app-avatar-initial-position-v2 team-avatar-text">
                        {selectFirstName(primaryApplicant).substring(0, 1)}
                        {selectLastName(primaryApplicant).substring(0, 1)}
                      </span>
                    </div>
                  ) : (
                    <div
                      className={`avatar team-avatar great relative`}
                      style={{
                        backgroundImage: `url(${primaryApplicant.avatar})`,
                      }}
                    ></div>
                  )}
                  {applicants.length > 1 && (
                    <div className="pl10">
                      <span className="household-label">
                        +{applicants.length - 1}
                      </span>
                    </div>
                  )}
                </div>
              </Flex>
            </Flex>
            <Flex flexBasis="45%" className="prospect-mobile-section">
              <Flex
                flexBasis="35%"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Flex flexDirection="column" textAlign="center">
                  <span className="font-size-20">
                    ${' '}
                    {showManagerEditedSummary && managerAddedRent > 0
                      ? managerAddedRent
                      : selectWeeklyRent(this.props.application)}
                  </span>
                  <span className="grey-text font-size-12">
                    {showManagerEditedSummary && managerAddedLeaseTerm > 0
                      ? managerAddedLeaseTerm
                      : selectTerm(this.props.application)}{' '}
                    mths
                  </span>
                </Flex>
              </Flex>
              <Flex
                flexBasis="35%"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Flex flexDirection="column" textAlign="center">
                  <span className="font-size-20"></span>
                  <span className="grey-text font-size-12"></span>
                </Flex>
              </Flex>
              <Flex
                flexBasis="35%"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Flex flexDirection="column" textAlign="center">
                  <div className="rank-info mb5" title="Persons count">
                    <i className="icon-person mb20 mr10" />
                    <span>{selectPeople(this.props.application)}</span>
                  </div>
                  <div className="rank-info" title="Pets count">
                    <i className="icon-pets mr10" />
                    <span>{selectPets(this.props.application)}</span>
                  </div>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexBasis="15%" className="prospect-mobile-section">
              <Flex
                justifyContent="center"
                alignItems="center"
                flexBasis="100%"
              >
                <Flex flexDirection="column" textAlign="center" mr={1}>
                  <div className="rank-info" title="Pets count">
                    <span className="mobile-only">
                      Application status <br />
                    </span>
                    {!isStatusDeclined(applicationStatus) &&
                      !isStatusOffered(applicationStatus) &&
                      !isStatusPMWithdrawn(applicationStatus) && (
                        <span className="font-size-16">
                          {formatApplicationStatus(this.props.application)}
                        </span>
                      )}
                    {isStatusDeclined(applicationStatus) && (
                      <StatusLabel
                        color="red"
                        label="Declined"
                        lastEventAt={selectLastEventAt(this.props.application)}
                      />
                    )}
                    {isStatusOffered(applicationStatus) && (
                      <StatusLabel
                        color="green"
                        label="Offered"
                        lastEventAt={selectLastEventAt(this.props.application)}
                      />
                    )}
                    {isStatusPMWithdrawn(applicationStatus) && (
                      <StatusLabel
                        color="grey"
                        label="Withdrawn"
                        lastEventAt={
                          selectLastEventAt(this.props.application) || moment()
                        }
                      />
                    )}
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </div>
        </div>
      </div>
    )
  }
}

const selectPeople = (application) => application && application.people
const selectApplicants = (application) => application && application.applicants
const selectPets = (application) =>
  application && application.managerAddedPets > 0
    ? application.managerAddedPets
    : application.pets
const selectWeeklyRent = (application) => application && application.weeklyRent
const selectTerm = (application) => application && application.term
const selectSubmittedDate = (application) =>
  application && application.submittedDate
const selectLastEventAt = (application) =>
  application && application.lastEventAt
const selectApplicationStatus = (application) =>
  application && ApplicationCategory[application.status]
const selectPrimaryApplicant = (applicants) =>
  applicants && applicants.find((applicant) => applicant.isPrimary)
const selectFirstName = (applicant) => applicant && applicant.firstName
const selectLastName = (applicant) => applicant && applicant.lastName
const formatUpdatedAt = (updatedAt) =>
  moment(updatedAt).format('ddd DD MMM HH:mm')
const formatApplicationStatus = (application) => {
  if (!application) return ''
  const status = selectApplicationStatus(application)
  if (isJointApplication(application) && isStatusApplied(status)) {
    const allApplied = selectApplicants(application).every((applicant) =>
      isApplicantApplied(applicant),
    )
    if (allApplied) return 'Applied'
    else return 'In Progress'
  }
  return status
}

export const ErrorMessage = ({ error }) => (
  <div className="apply-form-error row">
    <div className="col-sm-12">
      <div className="alert alert-danger">
        <div>{error && error}</div>
      </div>
    </div>
  </div>
)

const BasicMessage = (basicMessageArgs) => {
  const { message, validate, updateMessage, errors, isDisabled } =
    basicMessageArgs
  return (
    <div>
      <header className="mb50">
        <h4>To:</h4>
      </header>
      <div className="input-box">
        <input
          type="email"
          id="email"
          placeholder="Add emails addresses"
          onBlur={validate('recipientAddress')}
          onChange={(event) => updateMessage(event, 'recipientAddress')}
          value={message && message.recipientAddress}
        ></input>
      </div>
      <div
        className={
          errors && errors.recipientAddress && isDisabled(message)
            ? 'alert alert-danger'
            : 'hide-alert'
        }
      >
        <div>{errors && errors.recipientAddress}</div>
      </div>
      <div className="table mt20">
        <header className="mb50">
          <h4>Message:</h4>
        </header>
        <div className="input-box">
          <textarea
            type="text"
            placeholder="Add a personalised message"
            value={message && message.body}
            multiple="true"
            onBlur={validate('body')}
            onChange={(event) => updateMessage(event, 'body')}
            rows="5"
          ></textarea>
        </div>
      </div>
    </div>
  )
}

export const Message = (messageArgs) => {
  const {
    sendMessage,
    onMessageSent,
    failure,
    message,
    validate,
    updateMessage,
    showSpinner,
    errors,
    isDisabled,
    attachedProperties,
    application,
  } = messageArgs
  return (
    <div>
      <BasicMessage
        message={message}
        validate={validate}
        updateMessage={updateMessage}
        isDisabled={isDisabled}
        errors={errors}
      ></BasicMessage>
      <div>
        <AttachedPropertiesContainer attachedProperties={attachedProperties} />

        {application && <ApplicationRowContainer application={application} />}
      </div>
      <div className="table mt20">
        <div className="cell-m">
          <p className="wsnw mb0">
            <button title="Back">Back</button>
          </p>
        </div>
      </div>
      <div>
        <div className="table mt20">
          <div className="cell-m">
            <p className="wsnw mb0">
              <button
                className="link-styles p0 pt10"
                disabled={isDisabled(message)}
                onClick={() => {
                  sendMessage(message, onMessageSent, failure)
                }}
              >
                Send
                <i className={showSpinner ? 'fa fa-spinner fa-pulse' : ''} />
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
