import {
  RECEIVE_DOCUMENTS,
  RECEIVE_RESPONSE_TEXT,
  TOGGLE_SPINNER,
} from 'app/sm/documents/actions'

const _defaultState = {
  documents: [],
  responseText: '',
}

const Documents = (state = _defaultState, action) => {
  let { type, documents, responseText } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case RECEIVE_DOCUMENTS:
      return Object.assign(newState, { documents, responseText: '' })
    case TOGGLE_SPINNER:
      return Object.assign(newState, { spinner: !newState.spinner })
    case RECEIVE_RESPONSE_TEXT:
      return Object.assign(newState, { responseText })
    default:
      return state
  }
}

export default Documents
