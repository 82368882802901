import React from 'react'

import { appURL } from 'config/env'
import QRCode from 'qrcode.react'

import { Box, Flex } from 'app/components/design-system-components'
import { Button } from 'app/components/design-system-components'
import Modal from 'app/components/design-system-components/Modal'

function QRCodeModal({
  toggleModal = '',
  viewingGUID = '',
  currentTeamSlug = '',
}) {
  const QR_URL = `${appURL}/apply/${currentTeamSlug}/checkin?viewingGUID=${viewingGUID}`
  const modalBody = (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      width="100%"
    >
      <Box mb={5}>Scan and enter details to register</Box>
      <QRCode
        id="qr-svg"
        renderAs="svg"
        value={QR_URL}
        height={240}
        width={240}
      />
      <Button
        variant="outlineSuccess"
        mt={8}
        sizeVariant="large"
        onClick={toggleModal}
      >
        Close
      </Button>
    </Flex>
  )
  return (
    <Modal
      toggleModal={toggleModal}
      modalBody={modalBody}
      customModalFooter={true}
      showModalHeading={false}
    />
  )
}

export default QRCodeModal
