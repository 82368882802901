import {
  CLEAR_ERROR,
  RECEIVE_ERROR,
  RECEIVE_PERSONAL_REFERENCE,
  RECEIVE_PERSONAL_REFERENCES,
} from 'app/sm/personal_reference/personal_reference_actions'

const defaultState = {
  personalReference: {},
  personalReferences: [],
  error: '',
}

const PersonalReference = (state = defaultState, action) => {
  let { type, personalReference, personalReferences, error } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case RECEIVE_PERSONAL_REFERENCES:
      return Object.assign(newState, { personalReferences })
    case RECEIVE_PERSONAL_REFERENCE:
      return Object.assign(newState, { personalReference })
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    default:
      return newState
  }
}

export default PersonalReference
