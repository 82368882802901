import type { FC } from 'react'

import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

interface IMessagePreview {
  message?: string
  property?: {
    optionMeta?: { displayText?: string }
  }
}

const StyledUL = styled.ul`
  padding: ${theme.space[5]}px !important;
  li {
    display: list-item;
  }
`

const StyledBox = styled(Box)`
  white-space: pre-line;
`

export const MessagePreview: FC<IMessagePreview> = ({
  message = '',
  property,
}) => {
  const { optionMeta = {} } = property || {}
  const { displayText = '' } = optionMeta
  return (
    <Box
      backgroundColor={theme.colors.paleBlue}
      overflowY="scroll"
      height="360px"
      p={theme.space[6] + 'px'}
      my={theme.space[6] + 'px'}
    >
      <Box textAlign="center">
        <h3>{`Submit an Application: ${displayText || `[property]`}`}</h3>
      </Box>
      <Box>
        <br />
        Hi [name],
        <br />
        <Box>
          Please Submit an application for {displayText}. Applying takes one
          minute if you already have a Snug profile. Here's what you'll need:
        </Box>
        <StyledUL>
          <li>Contact details for your previous addresses</li>
          <li>Employment details</li>
          <li>Income documents (latest payslips and bank statements)</li>
          <li>Proof of identity</li>
        </StyledUL>
        {message && (
          <>
            <b>Message from Property Manager:</b>
            <StyledBox>{message}</StyledBox>
          </>
        )}
        <br />
        Please get in touch if you have any questions:
        <br />
        [agent name]
        <br />
        [agency phone]
        <br />
        Regards
        <br />
      </Box>
    </Box>
  )
}
