import React from 'react'

import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

class MaintenanceRequestModal extends React.Component {
  closeModal() {
    window.location.href = `/sm/`
  }

  render() {
    return (
      <div className="sm-modal">
        <div className="sm-modal-wrapper sm-modal-medium">
          <div onClick={() => this.closeModal()} className="sm-modal-close" />
          <div className="sm-modal-content">
            <h3 className="mb30">
              <i className="icon-maintenece ibm mr10"></i>
              <span className="ibm">Maintenance Request</span>
            </h3>

            <h5>
              Severity level
              <i
                className="icon-help-outline gray-light-color fw400 pl10 fs14"
                data-tooltip-content="Lorem ipsum help text"
              />
            </h5>
            <div className="shadow-wrapper mb30 mt10">
              <div className="input-box single-no-border pt0">
                <select required className="lh12">
                  <option>Low priority</option>
                  <option>Medium priority</option>
                  <option>High priority</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="input-box">
                  <select required>
                    <option blank></option>
                    <option>Kitchen</option>
                    <option>Living room</option>
                    <option>Bath room</option>
                  </select>
                  <label>Which Room?</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="input-box">
                  <input required type="text" />
                  <label>ToDo Title</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="input-box">
                  <textarea required rows="3"></textarea>
                  <label>Description</label>
                </div>
              </div>
            </div>

            <h5 className="mb10">Got photos to upload? (optional)</h5>
            <div className="sm-file-upload single">
              <form>
                <input id="file" type="file" />
                <label htmlFor="file">
                  <i className="icon-image"></i>
                  <b>Upload manually</b>
                  <span> or drag and drop photos here.</span>
                </label>
              </form>
            </div>

            <div className="row mt30">
              <div className="col-sm-12">
                <label>
                  <input type="checkbox" />
                  <span>Give your Property Manager access</span>
                </label>
              </div>
            </div>

            <div className="row mt30">
              <div className="col-sm-6">
                <button className="btn btn-gray mb10">Cancel</button>
              </div>
              <div className="col-sm-6">
                <button className="btn mb10">Save</button>
              </div>
            </div>

            <ReactTooltip className="tooltip" />
          </div>
        </div>
      </div>
    )
  }
}

export default MaintenanceRequestModal
