import React from 'react'

import { GreySubheading } from 'app/components/display/text/standard_text/standard-headings/component'
import {
  ErrorBox,
  validationStatusClassNameCollection,
} from 'app/components/forms/util'

import 'app/components/forms/base_text_area/style.scss'
// ///////////////////////////////////////////////////////////////////
// Text input
// ///////////////////////////////////////////////////////////////////
class BaseTextArea extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showError: true,
    }
  }

  handleBlur = () => {
    const { value, error, onBlurCustomized, onBlur } = this.props
    if (onBlurCustomized) {
      onBlur()
    } else if (value && error) {
      this.setState({ showError: true })
      this.setState({ validationStatusFlag: 2 })
    } else if (value && !error) {
      this.setState({ validationStatusFlag: 1 })
    } else {
      this.setState({ showError: true })
      this.setState({ validationStatusFlag: 0 })
    }
  }

  handleChange = (event) => {
    const {
      eventProcessedByComponent = false,
      onChange,
      isOptional = true,
    } = this.props
    if (eventProcessedByComponent) {
      onChange(event)
    } else {
      const {
        target: { value },
      } = event
      const error = this.props.validate(isOptional, value.trim())
      onChange({ value, error })
      this.setState({ showError: false })
    }
  }

  render() {
    const {
      value,
      error,
      label,
      help,
      id,
      readOnly,
      inputClass,
      placeholder,
      required,
      componentClass,
      rows = 1,
      children,
      inputBoxClass,
      disabled = false,
      autoFocus = false,
    } = this.props
    const { showError, validationStatusFlag } = this.state
    const validateClassName =
      validationStatusClassNameCollection[validationStatusFlag]
    return (
      <div className={componentClass}>
        <div className={`standard-form-text-area-component ${inputClass}`}>
          {children}
          <div className={`input-box standard-input-box ${inputBoxClass} mb10`}>
            {label && (
              <GreySubheading text={label} componentClass="text-area-label" />
            )}
            {help && (
              <>
                <div className="text-area-help default-span-text">
                  <span>{help}</span>
                </div>
              </>
            )}
            <textarea
              type="text"
              value={value}
              required={required}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              id={id}
              readOnly={readOnly}
              disabled={disabled}
              placeholder={placeholder}
              rows={rows}
              className={`standard-text-area ${validateClassName}`}
              autoFocus={autoFocus}
            />
          </div>
          <ErrorBox error={error} showError={!!(showError && error)} />
        </div>
      </div>
    )
  }
}

export default BaseTextArea
