import React from 'react'

import obstructedApplicationImg from 'app/assets/icons/apply_anywhere/obstructed_application.png'
import { BackgroundImage } from 'app/shared_components/shared_styled_component'

const ApplyAnywhereObstructedApplication = ({ firstName, address }) => {
  const { streetNumber, streetName, suburb, state, postcode } = address
  return (
    <div>
      <p className="font-weight-700 mb0 lh-28 text-center font-size-22">
        {firstName}'s application for
      </p>
      <p className="mb10 lh-28 text-center font-size-22">
        {`${streetNumber} ${streetName}, ${suburb}, ${state} ${postcode}`}
      </p>
      <BackgroundImage
        bgImage={obstructedApplicationImg}
        size="cover"
        repeat="no-repeat"
        minHeight="1000px"
      ></BackgroundImage>
    </div>
  )
}

export default ApplyAnywhereObstructedApplication
