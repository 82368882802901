import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import { CollapsingText } from 'app/components/design-system-components/CollapsingText'
import { Table } from 'app/components/design-system-components/index'
import { StatusPill } from 'app/components/display/display'
import { AUSTRALIAN_HUMAN_READABLE_DATETIME } from 'app/utils/datetime/helpers'

// TODO: clean styling
const ActivityStyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
  line-height: 3rem;
  letter-spacing: 0.05rem;

  th,
  td {
    padding: 20px 24px;
    white-space: nowrap;
  }
  tbody tr {
    box-shadow: 0px 3px 5px rgba(186, 186, 186, 0.25);
    border-radius: 2px;
  }
`

const ActivityLogsTable = ({
  activityList = [],
  activityTypes,
  activityStatuses,
}) => {
  const readyActivityList = activityList.map((activity) => ({
    type: activity.type,
    message: activity.message,
    time: moment(activity.timestamp).format(AUSTRALIAN_HUMAN_READABLE_DATETIME),
    status: activity.status,
  }))
  const data = React.useMemo(() => readyActivityList, [readyActivityList])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'time',
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'status-label',
        Cell: ({ value }) => renderActivityStatusCell(value),
      },
      {
        Header: 'Event',
        accessor: 'type',
        Cell: ({ value }) => renderActivityTypeCell(value),
      },
      {
        Header: 'Description',
        accessor: 'message',
        style: {
          whiteSpace: 'unset',
          minWidth: '300px',
        },
        Cell: ({ value }) => renderDescriptionCell(value),
      },
    ],
    [],
  )

  const renderDescriptionCell = (description) => {
    return <CollapsingText text={description} maxCharacters={70} />
  }

  const renderActivityStatusCell = (statusValue) => {
    const activityStatusObj = Object.values(activityStatuses).find(
      ({ id }) => id === statusValue,
    )
    const label = activityStatusObj ? activityStatusObj.label : 'N/A'
    const color = activityStatusObj ? activityStatusObj.color : 'white'
    return (
      <StatusPill
        componentClass="mr10"
        pillType={color}
        text={label}
        pillTextClass="fw500 fs16 pl15 pr15"
      />
    )
  }

  const renderActivityTypeCell = (typeValue) => {
    const typeObj = Object.values(activityTypes).find(
      ({ id }) => id === typeValue,
    )
    return typeObj ? typeObj.label : 'N/A'
  }

  return (
    <Table
      styledTable={<ActivityStyledTable />}
      columns={columns}
      dataToBeEntered={data}
    />
  )
}

export default ActivityLogsTable
