import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import checkMarkIconUrl from 'app/assets/icons/checkmark-with-bg.svg'
import { Flex } from 'app/components/design-system-components'
import { HelpOutlineRounded } from 'app/components/design-system-components/icons/actions'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { Button } from 'app/shared_components/form_component'
import { SMS_REPORT_HELP_TEXT } from 'app/utils/viewings/helpers'

const SendSMSReportButton = ({
  isViewingReportSmsEnabled: isAutoSendEnabled,
  viewingReportSMSSent: viewingReportSMSAutoSent,
  buttonClassName,
  buttonOnClick,
  viewingGUID,
  disabled = false,
  showSpinner = false,
}) => {
  const isReportAutoSent = isAutoSendEnabled && viewingReportSMSAutoSent
  const smsReportButtonLabel = isAutoSendEnabled
    ? 'Auto SMS Report'
    : 'Send SMS Report'

  const tooltipId = `sms-${viewingGUID}`

  const buttonLabel = (
    <div className="display-inline-block">
      {isReportAutoSent && (
        <img src={checkMarkIconUrl} alt="report sent" className="mr10" />
      )}
      <span>{smsReportButtonLabel}</span>
    </div>
  )
  return (
    <Flex alignItems="center">
      <Button
        className={
          isReportAutoSent
            ? 'background-color-gray-with-opacity green btn-medium'
            : buttonClassName
        }
        onClick={buttonOnClick}
        disabled={isReportAutoSent || disabled}
        label={buttonLabel}
        loading={showSpinner}
      />
      {isReportAutoSent && (
        <Flex
          fontSize={theme.fontSizes[9] + 'px'}
          color={theme.colors.gray500}
          ml={1}
        >
          <HelpOutlineRounded
            data-tooltip-id={tooltipId}
            data-tooltip-content=""
          />
          <ReactTooltip data-type="info" place="top" id={tooltipId}>
            <span>{SMS_REPORT_HELP_TEXT}</span>
          </ReactTooltip>
        </Flex>
      )}
    </Flex>
  )
}

export default SendSMSReportButton
