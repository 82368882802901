import { connect } from 'react-redux'

import { fetchTenants, splitBond } from 'app/bond_cover/bond_cover_actions'
import BondSplitsFormContainer from 'app/bond_cover/renters/tenants/bond_splits_form_container'

const mapStateToProps = ({ session, bondCover, Shared }) => ({
  apiError: bondCover.error,
  currentLease: bondCover.currentLease,
  tenants: bondCover.tenants,
  backUrl: Shared.backUrl,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTenants: (requestID) => fetchTenants(requestID),
  splitBond: (requestID, bondSplits) => splitBond(requestID, bondSplits),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BondSplitsFormContainer)
