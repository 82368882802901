import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { applyJoinedApplication } from 'app/sm/apply_joint/access'

export const submitJoinedApplication = (slug, data) => () => {
  return applyJoinedApplication(slug, data).then(
    ({ application, ok, responseText }) => {
      if (ok) {
        return Promise.resolve(application)
      }
      return responseText.then((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
    },
  )
}
