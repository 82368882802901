import React from 'react'

import styled from 'styled-components'

import * as helpers from 'app/sm/helpers'

const FooterHeading = styled.h4`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 4px;
`

const FooterLink = styled.a`
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  font-weight: 700;
  color: #212025;
  text-decoration: none;
  margin-bottom: 14px;
`

const Footer = ({ teamSlug = '', agencyDetails = {} }) => {
  const { companyName = '', licenseNumber = '' } = agencyDetails
  return (
    <footer id="footer">
      <div className="container text-center">
        <FooterHeading>Book a viewing and start an application</FooterHeading>
        <FooterLink
          target="_blank"
          rel="noopener noreferrer"
          href={`${helpers.urlTo('teamPublicPage', { teamSlug })}`}
        >
          snug.com/apply/{teamSlug}
        </FooterLink>
        <p className="fz-9 mb-0">
          Disclaimer, {companyName}, License: {licenseNumber}
        </p>
      </div>
    </footer>
  )
}

export default Footer
