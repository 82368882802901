import * as React from 'react'

function SvgLightning(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 4.5v6.75l3 .75-2.924 7.5H12v-6.023l-3-.75L11.924 4.5H12zM13.5 3h-2.634l-.354.996-2.927 8.23-.55 1.555 1.602.402 1.863.465V21h2.602l.372-.955 2.924-7.5.618-1.588-3.516-.879V3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgLightning
