import React from 'react'

const Banner = ({ parole }) => (
  <div>
    <h3 className="mb10">What sort of home owner are you?</h3>
    <p className="mb30">{parole}</p>
  </div>
)

export default Banner
