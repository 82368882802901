import React from 'react'

import humanIcon01 from 'app/assets/icons/human01.jpg'
import humanIcon02 from 'app/assets/icons/human02.jpg'
import humanIcon03 from 'app/assets/icons/human03.jpg'

class AutocompletePeople extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      extended: false,
      addedUsers: [
        {
          firstName: 'James',
          lastName: 'Nounce',
          email: 'james.nounce@gmail.com',
          avatarImage: humanIcon01,
        },
      ],
      possibleUsers: [
        {
          firstName: 'Carrie',
          lastName: 'Nounce',
          email: 'carrie.nounce@gmail.com',
          avatarImage: '',
        },
        {
          firstName: 'Roberta',
          lastName: 'Twain',
          email: 'roberta.twain@gmail.com',
          avatarImage: humanIcon02,
        },
        {
          firstName: 'Robert',
          lastName: 'Sims',
          email: 'robert.sims@gmail.com',
          avatarImage: humanIcon03,
        },
        {
          firstName: 'Simon',
          lastName: 'Quincy',
          email: 'simon.quincy@gmail.com',
          avatarImage: '',
        },
      ],
    }
  }

  addUser(user) {
    this.setState({ addedUsers: [...this.state.addedUsers, user] })
    this.setState({
      possibleUsers: this.state.possibleUsers.filter((e) => e !== user),
    })
    this.setState({ extended: false })
    this.refs.input.value = ''
  }

  extend() {
    this.setState({ extended: true })
  }

  removeUser(user) {
    this.setState({ possibleUsers: [...this.state.possibleUsers, user] })
    this.setState({
      addedUsers: this.state.addedUsers.filter((e) => e !== user),
    })
  }

  shrink() {
    this.setState({ extended: false })
  }

  render() {
    let { title = '', icon = '', placeholder = '' } = this.props
    let { extended, addedUsers, possibleUsers } = this.state

    let placeholderClass = 'has-placeholder'
    if (addedUsers.length > 0) {
      placeholder = ''
      placeholderClass = ''
    }

    return (
      <div className="sm-autocomplete-people">
        {title != '' && <div className="title">{title}</div>}
        <div className="autocomplete-box">
          {addedUsers.map((user, index) => (
            <div className="added" key={index}>
              {user.avatarImage != '' ? (
                <div
                  className="avatar avatar-xsxsmall"
                  style={{ backgroundImage: `url('${user.avatarImage}')` }}
                ></div>
              ) : (
                <div className="avatar avatar-xsxsmall blue-bg-light-rgba blue-color">
                  {user.firstName.charAt(0)}
                  {user.lastName.charAt(0)}
                </div>
              )}
              <div className="name">
                {user.firstName} {user.lastName.charAt(0)}.
              </div>
              <div className="remove" onClick={() => this.removeUser(user)}>
                <i className="icon-remove"></i>
              </div>
            </div>
          ))}

          <div className="input">
            <input
              type="text"
              ref="input"
              placeholder={placeholder}
              className={placeholderClass}
              onFocus={() => this.extend()}
            />
            {extended && (
              <ul className="possible-items">
                {possibleUsers.map((user, index) => (
                  <li key={index} onClick={() => this.addUser(user)}>
                    <div className="avatar-wrapper">
                      {user.avatarImage != '' ? (
                        <div
                          className="avatar avatar-xsmall"
                          style={{
                            backgroundImage: `url('${user.avatarImage}')`,
                          }}
                        ></div>
                      ) : (
                        <div className="avatar avatar-xsmall blue-bg-light-rgba blue-color">
                          {user.firstName.charAt(0)}
                          {user.lastName.charAt(0)}
                        </div>
                      )}
                    </div>
                    <div className="text-wrapper">
                      <div className="fw600 fs16 gray-dark-color">
                        {user.firstName} {user.lastName.charAt(0)}.
                      </div>
                      <div className="fs14 gray-color">{user.email}</div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {icon != '' && (
            <div className="icon">
              <i className={icon}></i>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default AutocompletePeople
