import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const keySetTypes = {
  agency: 'agency',
  tenant: 'tenant',
  owner: 'owner',
  other: 'other',
}

export const keySetTypesOptions = {
  [keySetTypes.agency]: 'Agency set',
  [keySetTypes.tenant]: 'Tenant set',
  [keySetTypes.owner]: 'Owner set',
  [keySetTypes.other]: 'Other',
}

export const keySetStatuses = {
  added: 'added',
  onLoan: 'on_loan',
  overdue: 'overdue',
  lost: 'lost',
  returned: 'returned',
  notReturned: 'not_returned',
  removed: 'removed',
}

export const keySetStatusConfig = {
  [keySetStatuses.added]: {
    title: 'Added',
    badgeVariant: 'secondary',
  },
  [keySetStatuses.onLoan]: {
    title: 'On loan',
    badgeVariant: 'notice',
  },
  [keySetStatuses.lost]: {
    title: 'Lost',
    badgeVariant: 'warning',
    textColor: theme.colors.warning600,
    showDuration: true,
  },
  [keySetStatuses.returned]: {
    title: 'Returned',
    badgeVariant: 'success',
  },
  [keySetStatuses.notReturned]: {
    title: 'Not Returned',
    badgeVariant: 'error',
    textColor: theme.colors.error600,
    showDuration: true,
  },
  [keySetStatuses.overdue]: {
    title: 'Overdue',
    badgeVariant: 'error',
    textColor: theme.colors.error600,
    showDuration: true,
  },
  [keySetStatuses.removed]: { title: 'Removed', badgeVariant: 'secondary' },
}

export const statusesToCheckOut = [
  keySetStatuses.returned,
  keySetStatuses.added,
]

export const statusesToCheckIn = [
  keySetStatuses.lost,
  keySetStatuses.notReturned,
  keySetStatuses.overdue,
  keySetStatuses.onLoan,
]

export const statusesToReserve = [keySetStatuses.returned, keySetStatuses.added]

export const borrowingReasons = {
  openHome: 'open_home',
  privateInspection: 'private_inspection',
  ingoingInspection: 'ingoing_inspection',
  outgoingInspection: 'outgoing_inspection',
  trades: 'trades',
  strata: 'strata',
  ownerBorrowing: 'owner_borrowing',
  routineInspection: 'routine_inspection',
  photos: 'photos',
  other: 'other',
}

export const borrowingReasonsOptions = {
  [borrowingReasons.openHome]: 'Open home',
  [borrowingReasons.privateInspection]: 'Private inspection',
  [borrowingReasons.ingoingInspection]: 'Ingoing inspection',
  [borrowingReasons.outgoingInspection]: 'Outgoing inspection',
  [borrowingReasons.trades]: 'Trades',
  [borrowingReasons.strata]: 'STRATA',
  [borrowingReasons.ownerBorrowing]: 'Owner Borrowing',
  [borrowingReasons.routineInspection]: 'Routine inspection',
  [borrowingReasons.photos]: 'Photos',
  [borrowingReasons.other]: 'Other',
}
