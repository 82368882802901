import React, { useState } from 'react'

import styled from 'styled-components'

import { LightbulbOutlineRounded } from 'app/components/design-system-components/icons/actions'
import * as getters from 'app/components/design-system-components/theme/getters'
import UpgradeModalWithPipedriveForm from 'app/components/design-system-components/UpgradeModalWithPipedriveForm'
import theme from 'app/match/applicationReportPDF/assets/theme'

import { Box, Button, Flex } from '.'

const StyledUpgradeCTA = styled(Flex)`
  max-width: ${theme.width[21]}px;
  border: ${theme.borders[1]} ${getters.color('gray200')};
  font-size: ${theme.fontSizes.pRegular14};

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
  border-radius: ${theme.radii[6]}px;
  color: ${theme.colors.gray600};
  padding: ${theme.space[3]}px ${theme.space[5]}px;
  line-height: ${theme.lineHeights.encrption};
  background-color: #fff;
  position: ${({ alignRight = true }) => (alignRight ? 'absolute' : 'static')};
  right: 5%;
`

function UpgradeCTA({ availableOn = 'Growth Plan', alignRight }) {
  const [showUpgradeForm, setShowUpgradeForm] = useState(false)
  const toggleShowUpgradeForm = () => {
    setShowUpgradeForm(!showUpgradeForm)
  }

  return (
    <>
      <StyledUpgradeCTA alignRight={alignRight} alignItems="center">
        <Box>
          <LightbulbOutlineRounded
            width="2em"
            height="2em"
            color={theme.colors.warning400}
          />
        </Box>
        <Box ml={4}>
          <Box>These features are available on the {availableOn}.</Box>
          <Box>
            <Button onClick={toggleShowUpgradeForm} variant="linkBlue">
              {' '}
              Find out more
            </Button>
          </Box>
        </Box>
      </StyledUpgradeCTA>
      {showUpgradeForm && (
        <UpgradeModalWithPipedriveForm
          toggleShowUpgradeForm={toggleShowUpgradeForm}
        />
      )}
    </>
  )
}

export default UpgradeCTA
