import { LogFetchError } from 'app/shared_components/helpers'
import * as sharedHelpers from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getProgressApplication = (applicationId) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/offer`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (applicationInfo) => ({ ok: true, applicationInfo }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postProgressApplication = (applicationId, applicationInfo) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/offer`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(applicationInfo),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postProgressApplicationAsLeased = (
  applicationId,
  applicationInfo,
  markAsLeasedOptions,
) => {
  const requestPayload = {
    ...applicationInfo,
    markAsLeasedOptions,
  }
  const url = `${apiBaseUrl}sm/applications/${applicationId}/markleased`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(requestPayload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postProgressApplicationAsApproved = (
  applicationId,
  applicationInfo,
  agencyGUID,
  markAsApplicationApprovedOptions,
) => {
  const requestPayload = {
    ...applicationInfo,
    agencyGUID,
    markAsApplicationApprovedOptions,
  }
  const url = `${apiBaseUrl}sm/applications/${applicationId}/markApproved`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(requestPayload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then(() => ({ ok: true, responseText: '' }), sharedHelpers.returnAPIError)
}

export const sendUtilityReferralToLead = (applicationId, force) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/sendutilitylead?force=${force}`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then(() => ({ ok: true, responseText: '' }), sharedHelpers.returnAPIError)
}

export const getNotesWithLimit = (ownerGUID, ownerType, notesLimit) => {
  const url = `${apiBaseUrl}sm/note/${ownerGUID}/${ownerType}?limit=${notesLimit}`
  return fetch(url, {
    credentials: 'include',
  })
    .then(sharedHelpers.checkStatus)
    .then(
      (responseData) => ({ ok: true, responseData }),
      sharedHelpers.returnAPIError,
    )
    .catch(sharedHelpers.logAPIError)
}

export const getApplicationNotesWithLimit = (applicationGUID, notesLimit) => {
  const url = `${apiBaseUrl}sm/note/application/${applicationGUID}?limit=${notesLimit}`
  return fetch(url, {
    credentials: 'include',
  })
    .then(sharedHelpers.checkStatus)
    .then(
      (responseData) => ({ ok: true, responseData }),
      sharedHelpers.returnAPIError,
    )
    .catch(sharedHelpers.logAPIError)
}

export const postScheduleViewingFromApplication = (
  applicationGUID,
  applicantGUID,
  viewingGUID,
) => {
  const url = `${apiBaseUrl}sm/applications/${applicationGUID}/applicant/${applicantGUID}/viewings/${viewingGUID}`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then(
      (data) => ({ ok: true, responseText: '', data }),
      sharedHelpers.returnAPIError,
    )
}
