import PropType from 'prop-types'

export const loadingStatesIds = {
  INITIAL: 'initial',
  LOADING: 'loading',
  ERROR: 'error',
  LOADED: 'loaded',
  PENDING: 'pending',
}

const initialLoadingStates = {
  state: loadingStatesIds.INITIAL,
  error: null,
}

export const createInitialLoadingState = function createInitialLoadingState() {
  return {
    ...initialLoadingStates,
  }
}

export function createLoadingStateUtils(setterFn = (state) => state) {
  return {
    reset: () => setterFn(createInitialLoadingState()),
    startLoading: () =>
      setterFn({
        state: loadingStatesIds.LOADING,
        error: null,
      }),
    markDoneSuccessfully: () =>
      setterFn({
        state: loadingStatesIds.LOADED,
        error: null,
      }),
    addedToPending: () =>
      setterFn({
        state: loadingStatesIds.PENDING,
        error: null,
      }),
    setError: (err) =>
      setterFn({
        state: loadingStatesIds.ERROR,
        error: err,
      }),
  }
}

export const loadingStatePropType = PropType.shape({
  error: PropType.any,
  state: PropType.oneOf(Object.values(loadingStatesIds)),
})

export const promiseWrapperFactory = (loadingStatesHelpers) => {
  return (promise, rejectInError = false) => {
    loadingStatesHelpers.startLoading()
    return promise
      .then((fulfilledData) => {
        loadingStatesHelpers.markDoneSuccessfully()
        return fulfilledData
      })
      .catch((err) => {
        loadingStatesHelpers.setError(err)
        return rejectInError && Promise.reject(err)
      })
  }
}
