import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

import * as Form from 'app/components/forms/forms'
import isEmptyObject from 'app/shared_components/check_empty_object'
import DatePicker from 'app/shared_components/date_picker'
import {
  validateDate,
  validateFloat,
  validateLeaseStartDate,
  validateWeeklyRent,
} from 'app/shared_components/validations'
import MultipleSelect from 'app/sm/common/multiple-select'
import { urlTo } from 'app/sm/helpers'
import Breadcrumbs from 'app/sm/ppp/breadcrumbs'
import { isCurrentRoute, isCurrentRouteFrom } from 'app/sm/router_helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

const mockEvent = (value) => ({
  currentTarget: {
    value,
  },
})

class Advertisement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: true,
      errors: {},
      inputErrors: {},
      clicked: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ clicked: false })
    }
  }

  onContinueClicked() {
    this.setState({ clicked: true })
  }

  setInputValidationErrors = (field, error) => {
    let { inputErrors } = this.state
    if (error.length == 0) {
      delete inputErrors[field]
      this.setState({ inputErrors })
      let errors = this.state.errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      inputErrors[field] = error
      this.setState({ inputErrors })
      return false
    }
  }

  setValidationErrors = (field, error) => {
    let { errors } = this.state
    if (error.length == 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  validate = (field, onBlur, value) => {
    return () => {
      let { startDate, acceptedLeaseLength, weeklyRent } = this.props

      switch (field) {
        case 'startDate':
          return onBlur
            ? this.setValidationErrors(
                field,
                validateLeaseStartDate(
                  startDate,
                  'Lease start must be present and must be today or later',
                ),
              )
            : this.setInputValidationErrors(
                field,
                validateLeaseStartDate(
                  value,
                  'Lease start must be present and must be today or later',
                ),
              )
        case 'weeklyRent':
          return onBlur
            ? this.setValidationErrors(
                field,
                validateWeeklyRent(
                  weeklyRent,
                  'Weekly rent, must be a number and must be > 0',
                ),
              )
            : this.setInputValidationErrors(
                field,
                validateWeeklyRent(
                  weeklyRent,
                  'Weekly rent, must be a number and must be > 0',
                ),
              )
      }

      // switch (field) {
      //   case 'EmployerName':
      //     return onBlur ? this.setValidationErrors(field, validateEmployerName(EmployerName.value, EmployerName.value.length > 0 ? "Invalid Employer Name" : "Employer Name Is Required"))
      //       : this.setInputValidationErrors(field, validateEmployerName(EmployerName.value, EmployerName.value.length > 0 ? "Invalid Employer Name" : "Employer Name Is Required"))
      //   case 'JobTitle':
      //     return onBlur ? this.setValidationErrors(field, validateJobTitle(JobTitle.value, "Job Title Is Required"))
      //       : this.setInputValidationErrors(field, validateJobTitle(JobTitle.value, "Job Title Is Required"))
      //   case 'PhoneNumber':
      //     return onBlur ? this.setValidationErrors(field, validateMobile(PhoneNumber.value, PhoneNumber.value.length > 0 ? "Invalid Phone Number" : "Phone Number Is Required"))
      //       : this.setInputValidationErrors(field, validateMobile(PhoneNumber.value, PhoneNumber.value.length > 0 ? "Invalid Phone Number" : "Phone Number Is Required"))
      //   case 'ContactName':
      //     return onBlur ? this.setValidationErrors(field, validateNameOptional(ContactName.value, "Invalid Contact Name"))
      //       : this.setInputValidationErrors(field, validateNameOptional(ContactName.value, "Invalid Contact Name"))
      //   case 'ContactEmail':
      //     return onBlur ? this.setValidationErrors(field, validateEmailOptional(ContactEmail.value, "Invalid Email Address"))
      //       : this.setInputValidationErrors(field, validateEmailOptional(ContactEmail.value, "Invalid Email Address"))
      //   case 'WeeklyNetIncome':
      //     return onBlur ? this.setValidationErrors(field, validateFloat(WeeklyNetIncome.value, WeeklyNetIncome.value.length > 0 ? "Invalid Weekly Net Income" : "Weekly Net Income Is Required"))
      //       : this.setInputValidationErrors(field, validateFloat(WeeklyNetIncome.value, WeeklyNetIncome.value.length > 0 ? "Invalid Weekly Net Income" : "Weekly Net Income Is Required"))

      // }
    }
  }

  render() {
    const {
      id,
      url,
      error,
      weeklyRent,
      startDate,
      acceptedLeaseLength,
      listingPortals,
      listingFee,
      update,
      updateNumber,
      updateDate,
      updateCheckbox,
      portals,
      registerPayment,
      numPortals,
      submit,
      state,
    } = this.props

    const { clicked, errors } = this.state
    const disabled =
      clicked ||
      !(weeklyRent && startDate && acceptedLeaseLength && isEmptyObject(errors))
    const acceptedLeaseLengths = (acceptedLeaseLength || '')
      .split(';')
      .filter((it) => it)
      .map((months) => ({ id: months, label: `${months}m` }))
    const leaseEntries = [
      '3',
      '6',
      '9',
      '12',
      '18',
      '24',
      '36',
      '48',
      '60',
    ].map((months) => ({ id: months, label: `${months}m` }))
    const formattedStartDate =
      startDate &&
      state.errors.startDate !== dateTimeHelpers.INVALID_DATE_STRING
        ? moment(startDate).format(dateTimeHelpers.DATE_WITH_SLASH)
        : startDate

    return (
      <div className="sm-property advertise">
        {!isCurrentRouteFrom('Profile') && (
          <Breadcrumbs activeBreadCrumb={'offer-details'} />
        )}
        <div className="mobile-only">
          <Link
            to={urlTo('updatePropertyPhotos', { id })}
            className="btn btn-transparent btn-left xs-text-center wa"
          >
            <i className="icon-arrow-left left"></i>
            <span>Back</span>
          </Link>
        </div>
        <div className="section-title">
          <div className="left column">
            <h3>Letting details</h3>
          </div>
        </div>
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <div className="row">
              <div className="col-sm-12">
                <div className="input-box">
                  <div className="prefix">$</div>
                  <input
                    required
                    value={weeklyRent || ''}
                    onChange={updateNumber('weeklyRent')}
                    onBlur={this.validate('weeklyRent', true)}
                  />
                  <label>Weekly rent</label>
                </div>
              </div>
            </div>
            <div
              className={
                errors['weeklyRent'] && disabled
                  ? 'alert alert-danger'
                  : 'hide-alert'
              }
            >
              <div>{errors['weeklyRent']}</div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-date pos-initial border-bottom-1px">
                  <Form.InputDate
                    label="Lease Start Date"
                    inputProps={{ placeholder: 'dd/mm/yyyy' }}
                    viewMode="days"
                    disableOnClickOutside="true"
                    onChange={updateDate}
                    value={formattedStartDate}
                    error={state.errors.startDate}
                    customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                    readOnly={false}
                    inputClassName="border-0 pl0"
                    validationClass="form-control"
                    containerClassName="letting-details-date-input"
                  />
                </div>
              </div>
            </div>
            <div
              className={
                errors['startDate'] && disabled
                  ? 'alert alert-danger'
                  : 'hide-alert'
              }
            >
              <div>{errors['startDate']}</div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <MultipleSelect
                  parole="Lease Term"
                  entries={leaseEntries}
                  selected={acceptedLeaseLengths}
                  onChange={(selected) =>
                    update('acceptedLeaseLength')(
                      mockEvent(selected.map((entry) => entry.id).join(';')),
                    )
                  }
                />
              </div>
            </div>

            <div
              className={
                errors['acceptedLeaseLength'] && disabled
                  ? 'alert alert-danger'
                  : 'hide-alert'
              }
            >
              <div>{errors['acceptedLeaseLength']}</div>
            </div>
            <div className="row">
              <div className="col-sm-12 pb20">
                {/* <Link className="btn btn-medium btn-blue btn-light" to={url + '/portals'}>Customize Advertising</Link> */}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className={error ? 'alert alert-danger' : ''}>
                  <div>{error}</div>
                </div>
              </div>
            </div>

            <div className="row pt25 mobile-form-button">
              <div className="col-sm-5 pb5 tablet-only">
                <Link
                  to={urlTo('updatePropertyPhotos', { id })}
                  className="btn btn-transparent btn-left xs-text-center wa"
                >
                  <i className="icon-arrow-left left"></i>
                  <span>Back</span>
                </Link>
              </div>
              <div className="col-sm-7 pb5">
                <button
                  className="btn"
                  disabled={disabled}
                  onClick={() => {
                    this.onContinueClicked()
                    submit(id, state)
                  }}
                >
                  Continue
                  <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
                </button>
              </div>
            </div>
          </div>
          <div className="col-second xs-first"></div>
        </div>
      </div>
    )
  }
}

export default Advertisement
