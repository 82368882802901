import { history } from 'app/shared_components/router'
import { pushQuestion } from 'app/sm/ask_question/ask_question_access'
import { urlTo, validateQuestion } from 'app/sm/helpers'
import store from 'app/store'

export const PING = 'AQ_PING'
export const TEXT_CHANGED = 'AQ_TEXT_CHANGED'
export const SET_PROPERTY_ID = 'AQ_SET_PROPERTY_ID'
export const RECEIVE_RESPONSE_TEXT = 'AQ_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'AQ_RECEIVE_ERROR'
export const CLEAR_ERROR = 'AQ_CLEAR_ERROR'

// Intercepted by Middleware
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})

// Emitted by Middleware for Reducer
export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })
export const clearError = () => ({ type: CLEAR_ERROR })

// Action Creators
export const ping = () => ({ type: PING })
export const textChanged = (text) => ({ type: TEXT_CHANGED, text })
export const setPropertyId = (propertyId) => ({
  type: SET_PROPERTY_ID,
  propertyId,
})

// Methods
export function sendQuestion() {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    const { propertyId, question } = getState().askQuestion
    const questionValidation = validateQuestion(question)

    if (!questionValidation.ok) {
      store.dispatch(receiveResponseText(questionValidation.errorCode))
      return Promise.resolve()
    }

    pushQuestion(propertyId, question).then(
      ({ ok, responseText, statusCode }) => {
        if (ok) {
          history.push(urlTo('thanks', { propertyId }))
        } else {
          responseText.then((t) => {
            store.dispatch(receiveResponseText(t))
          })
        }
      },
    )
  }
}
