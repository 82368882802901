import React from 'react'

import { calculatePercentage } from 'app/sm/helpers'

const ProgressLine = ({
  text = '',
  children,
  totalPoints,
  recommendedPoints,
  componentClass = '',
}) => {
  return (
    <div className={`full-box ${componentClass}`}>
      <div className="lease-duration">
        <div className="text width100">
          <div className="text-align-initial">
            {text}
            {children}
          </div>
          <div className="progress">
            <div
              className="progress-bar"
              style={{
                width: calculatePercentage(totalPoints, recommendedPoints),
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProgressLine
