import styled from 'styled-components'

import { Button, Flex } from 'app/components/design-system-components'
import { topNavHeightInPx } from 'app/components/design-system-components/styles/variables'
import theme from 'app/match/applicationReportPDF/assets/theme'

export const StyledApplicationToolbar = styled(Flex)`
  position: fixed;
  top: ${topNavHeightInPx}px;
  width: 100%;
  z-index: 2;
  padding: ${theme.space[5]}px;
  padding-left: 0;
  align-items: center;
  left: 0;
  background-color: ${theme.colors.white};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-top: 1px solid ${theme.colors.gray200};
  height: ${theme.height[17]}px;
`

export const StyledButton = styled(Button)`
  margin-right: ${theme.space[3]}px;
  font-size: ${theme.fontSizes.pRegular14};
  font-weight: ${theme.fontWeights.h1};
  color: ${theme.colors.gray600};
  min-height: ${theme.height[18]}px;
  min-width: ${theme.width[28]}px;
  svg {
    height: ${theme.height[5]}px;
    width: ${theme.width[5]}px;
  }
`

export const StyledApplicantListItem = styled.li`
  width: ${theme.baseSpace * 50}px;
  font-size: ${theme.fontSizes.pLarge16};
  padding: ${theme.space[3]}px ${theme.space[4]}px;
  font-weight: ${theme.fontWeights[3]};
  :not(:last-child) {
    border-bottom: 1px solid ${theme.colors.gray300};
  }
  cursor: pointer;
  .second-line {
    margin-top: 4px;
    font-size: ${theme.fontSizes.pRegular14};
  }
`
