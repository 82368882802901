import { connect } from 'react-redux'

import BottomButtonsConnection from 'app/bond_cover/renters/sharedComponent/bottom_buttons_widget'
import {
  addRentalReputationBackUrl,
  deleteLastRentalBackUrl,
} from 'app/shared_components/actions'

const mapStateToProps = ({ Shared }) => ({
  rentalBackUrlList: Shared.rentalBackUrlList,
  backUrl: Shared.backUrl,
})

const mapDispatchToProps = (dispatch) => ({
  addRentalReputationBackUrl: (rentalBackUrl) =>
    dispatch(addRentalReputationBackUrl(rentalBackUrl)),
  deleteLastRentalBackUrl: () => dispatch(deleteLastRentalBackUrl()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BottomButtonsConnection)
