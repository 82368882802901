import * as React from 'react'

function SvgForm(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 4c-1.093 0-2 .907-2 2v12c0 1.093.907 2 2 2h16c1.093 0 2-.907 2-2V6c0-1.093-.907-2-2-2H4zm0 2h16v12H4V6zm7 2v3h7V8h-7zM6 9v2h3V9H6zm0 4v2h3v-2H6zm5 0v3h7v-3h-7z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgForm
