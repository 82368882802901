import React from 'react'

import { Pagination } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import AdminMenu from 'app/bond_cover/admin/menu.js'
import ListItems from 'app/bond_cover/admin/search/list_items_widget'
import ResultItem from 'app/bond_cover/admin/search/result_item'

const SearchForm = ({
  searchResults,
  applicationList,
  searchText,
  searchType,
  update,
  reset,
  submit,
  resetButtonClicked,
  handleApplicationChangePage,
  handleSearchResultChangePage,
  applicationListCursorNumber,
  searchResultsCursorNumber,
  error,
}) => {
  let resultHtml = <div>Nothing is found.</div>
  let showApplicationList =
    resetButtonClicked && applicationList && applicationList.length > 0
  let showSearchResults = searchResults && searchResults.length > 0
  let disableApplicationListNextButton =
    applicationListCursorNumber > 0 ? false : true
  let disableSearchResultsNextButton =
    searchResultsCursorNumber > 0 ? false : true
  if (showApplicationList) {
    resultHtml = (
      <div>
        {applicationList.map((item, index) => {
          let { lease, request, manager, tenants, payment } = item
          return (
            <ResultItem
              lease={lease}
              request={request}
              manager={manager}
              tenant={tenants}
              payment={payment}
              key={request.guidID}
            />
          )
        })}
        <div className="mt10 mw890 clearfix">
          <div className="pull-left">
            <Link
              to="/admin/search"
              onClick={() => handleApplicationChangePage('firstPage')}
            >
              First Page
            </Link>
          </div>
          <div
            className={
              disableApplicationListNextButton ? 'displayNone' : 'pull-right'
            }
          >
            <Link
              to="/admin/search"
              onClick={() => handleApplicationChangePage('nextPage')}
            >
              Next Page
            </Link>
          </div>
        </div>
      </div>
    )
  } else if (showSearchResults) {
    resultHtml = (
      <div>
        {searchResults.map((i) => {
          let { lease, request, manager, tenants, payment } = i
          return (
            <ResultItem
              lease={lease}
              request={request}
              manager={manager}
              tenant={tenants}
              payment={payment}
              key={request.guidID}
            />
          )
        })}
        <div className="mt10 mw890 clearfix">
          <div className="pull-left">
            <Link
              to="/admin/search"
              onClick={() => handleSearchResultChangePage('firstPage')}
            >
              First Page
            </Link>
          </div>
          <div
            className={
              disableSearchResultsNextButton ? 'displayNone' : 'pull-right'
            }
          >
            <Link
              to="/admin/search"
              onClick={() => handleSearchResultChangePage('nextPage')}
            >
              Next Page
            </Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <AdminMenu />
      <div className="content">
        <ul className="admin-row">
          <li>
            <input value={searchText} onChange={update('searchText')} />
          </li>
          <li>
            <select onChange={update('searchType')} value={searchType}>
              <option value="1">By Application Code</option>
              <option value="2">By Manager Email</option>
            </select>
          </li>
          <li style={{ marginLeft: '20px' }}>
            <button onClick={submit}>Search</button>
          </li>
          <li style={{ marginLeft: '20px' }}>
            <button onClick={reset}>Reset</button>
          </li>
        </ul>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="sm-renter-applications">
          <div className="sm-ra-card">
            <div className="sm-ra-card__header ok pl0 pr0 mb0">
              <h4 className="prototype-header">
                <div className="width100 text-center">App Code</div>
                <div className="width100 text-center">Renter</div>
                <div className="width100 text-center">Manager</div>
                <div className="width100 text-center">Address</div>
                <div className="width100 text-center">Status</div>
                <div className="width100 text-center">Promotion Code</div>
              </h4>
            </div>
            <ul className="sm-ra-card__list">{resultHtml}</ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchForm
