import React from 'react'

import Modal from 'app/components/design-system-components/Modal'

function EditConfirmationModal({
  toggleEditConfirmationModal,
  undoChangesMadeToPage,
  onConfirmChangesButtonClicked,
}) {
  const modalBody = (
    <div>
      <p>
        There are changes to the Run Preferences. Re-calculate the run? Please
        note this will reset prior adjustments to the draft run.
      </p>
    </div>
  )
  return (
    <Modal
      modalHeading="Confirm Action"
      primaryLabel="Continue"
      primaryAction={onConfirmChangesButtonClicked}
      secondaryLabel="Undo Changes"
      secondaryAction={undoChangesMadeToPage}
      toggleModal={toggleEditConfirmationModal}
      modalBody={modalBody}
    />
  )
}

export default EditConfirmationModal
