import React from 'react'

import qs from 'qs'
import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import * as Display from 'app/components/display/display'
import ProgressBarComponent from 'app/components/progress_bar/connection'
import BasicModal from 'app/modals/basic_modal'
import { getAgencySettings } from 'app/services/http/agency'
import Checkbox from 'app/shared_components/checkbox'
import { ErrorMessage } from 'app/shared_components/helpers'
import getParameter from 'app/shared_components/parameter_parser'
import Header from 'app/shared_components/rental_reputation_header_connection'
import { history } from 'app/shared_components/router'
import SnugTip from 'app/shared_components/snug_tips'
import { isFromApplication, urlTo } from 'app/sm/helpers'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import StudentBox from 'app/sm/student_details/student_details_box'
import * as textHelpers from 'app/utils/text/helpers'

const addMoreStudentDetailsModalBody =
  'Please add your student details. This will improve your Match Score and allow you to submit a completed rental application.'

const attachmentsRequiredModal = {
  title: 'Attach a Student ID or Letter of Offer',
  body: 'This property manager requires either a Student ID or Letter of Offer to apply. Edit your student details and upload to attach.',
  primaryBtnLabel: 'Close',
}

class StudentDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      studentDetails: [],
      isStudent: false,
      modalOpen: false,
      showAttachmentsRequiredModal: false,
      isAttachmentsRequired: false,
      isBallotApplication: false,
    }
  }

  componentDidMount() {
    const {
      getStudentDetails,
      retrieveReputationPreference,
      fetchCompleteness,
    } = this.props
    getStudentDetails().then(({ studentInfo }) => {
      this.setState({
        studentDetails: studentInfo.studentDetails,
      })
    })

    //Get agency id in case of application (not user profile)
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { id: agencyGUID, ballot = false } = curQueries || {}
    if (agencyGUID && agencyGUID !== '') {
      // FIXME: remove this check after fixing the query params in the application flow
      // to take care of cases that id of agency used twice in the query params by mistake
      const usedAgencyID =
        typeof agencyGUID === 'string' ? agencyGUID : agencyGUID[0]
      this.getApplicationsTeamSettings(usedAgencyID)
    }

    if (ballot && ballot === 'true') {
      this.setState({
        isBallotApplication: true,
      })
    }

    retrieveReputationPreference().then(({ preference }) => {
      this.setState({ isStudent: preference.optInStudentDetails })
    })

    fetchCompleteness()
  }

  getApplicationsTeamSettings = (teamId) => {
    this.setState({ isLoadingTeamSettings: true })
    getAgencySettings(teamId, 'application')
      .then(({ settingInfo }) => {
        this.setState({
          isAttachmentsRequired:
            settingInfo.application &&
            settingInfo.application.student_attachments_required === 'true',
        })
      })
      .catch(({ message }) => {
        this.setState({ error: message })
      })
      .finally(() => this.setState({ isLoadingTeamSettings: false }))
  }

  beforeProceedToNext = () => {
    const { studentDetails = [], isStudent, isAttachmentsRequired } = this.state

    const { addRentalReputationBackUrl } = this.props

    if (isStudent && studentDetails.length < 1) {
      this.toggleModal()
      return
    }
    if (isAttachmentsRequired) {
      const hasNoAttachments = ({
        studentIDAttachments,
        offerLetterAttachments,
      }) => !studentIDAttachments?.length && !offerLetterAttachments?.length

      if (studentDetails.some(hasNoAttachments)) {
        this.toggleAttachmentsRequiredModal()
        return
      }
    }
    addRentalReputationBackUrl(
      `${window.location.pathname}${window.location.search}`,
    )
    history.push(`${urlTo('emergencyContactDetails')}${window.location.search}`)
  }

  removeStudentDetails = (studentDetailId) => {
    const { removeSingleStudentDetail, getStudentDetails } = this.props
    removeSingleStudentDetail(studentDetailId)
      .then(() => {
        getStudentDetails().then(({ studentInfo }) => {
          this.setState({
            studentDetails: studentInfo.studentDetails,
          })
        })
      })
      .catch((error) => this.setState({ apiError: error }))
  }

  toggleModal = () => {
    const { modalOpen } = this.state
    this.setState({ modalOpen: !modalOpen })
  }
  toggleAttachmentsRequiredModal = () => {
    const { showAttachmentsRequiredModal } = this.state
    this.setState({
      showAttachmentsRequiredModal: !showAttachmentsRequiredModal,
    })
  }

  updateStudentPreference = () => {
    const {
      reputationPreference,
      changeReputationPreference,
      fetchCompleteness,
    } = this.props
    changeReputationPreference({
      ...reputationPreference,
      optInStudentDetails: !reputationPreference.optInStudentDetails,
    }).then(({ preference }) => {
      this.setState({ isStudent: preference.optInStudentDetails })
      fetchCompleteness()
    })
  }

  render() {
    const { backUrl, property, completeness } = this.props
    const {
      studentDetails,
      isStudent,
      modalOpen,
      showAttachmentsRequiredModal,
      isLoadingTeamSettings,
      isBallotApplication,
    } = this.state
    const propertyId = getParameter('propertyId')
    const applicationId = getParameter('applicationId')
    const isFromApplicationForm =
      isFromApplication(backUrl) || propertyId || applicationId

    return (
      <Display.CenterContentContainer
        componentClass="width100"
        sizeClass="min-height-screen"
      >
        <ProgressBarComponent
          completeness={completeness}
          clickNextFunctionFromParentComponent={this.beforeProceedToNext}
        />
        <RentalReputationHeaderReminder
          property={property}
          backUrl={backUrl}
          remindText={'Current application'}
          isFromApplicationForm={isFromApplicationForm}
          title="Students details"
        />

        <Header title="Student Details" />
        <Checkbox
          label="I'm currently a student"
          value={isStudent}
          id="optInStudent"
          onChange={() => this.updateStudentPreference()}
        />

        {isStudent && (
          <div>
            <div className="mt10">
              {(studentDetails || []).length !== 0 &&
                studentDetails.map((studentDetail) => (
                  <StudentBox
                    removeStudentDetails={this.removeStudentDetails}
                    studentDetail={studentDetail}
                    key={studentDetail.guidID}
                  />
                ))}
            </div>
          </div>
        )}

        {this.state.error && <ErrorMessage error={this.state.error} />}

        {isStudent && (
          <div className="table mt20 mb20 height60px">
            <div className="cell-m">
              <Link
                className="btn btn-round"
                to={`${urlTo('studentDetailsAdd')}${window.location.search}`}
              >
                <i className="icon-add" />
              </Link>
            </div>
            <div className="cell-m-100 pl20">
              <h4>Add student details</h4>
            </div>
          </div>
        )}
        <ReactTooltip className="tooltip" />

        <SnugTip tipContent={textHelpers.STUDENT_DETAIL_HEADER_GUIDANCE} />

        <Display.BottomContentContainer>
          <Display.CenterContentContainer componentClass="width100">
            <div className="submit-button-container">
              <BottomButton
                btnText={
                  isFromApplicationForm
                    ? 'Save & Continue'
                    : 'Next: Next of Kin'
                }
                clickNextFunction={() => this.beforeProceedToNext()}
                nextBtnDisabled={isLoadingTeamSettings}
                bottomButtonsClass="pt0 mt0 summary-bottom-button"
                nextBtnClass="summary-green-button-class"
                backButtonClass="summary-grey-button-class"
                nextButtonPositionClass="pr20 pl0"
                showArrow={false}
              />
            </div>
          </Display.CenterContentContainer>
        </Display.BottomContentContainer>
        {modalOpen && (
          <BasicModal
            toggleModal={this.toggleModal}
            title={'Add Student details'}
            body={addMoreStudentDetailsModalBody}
            primaryButtonLabel={'Add Details'}
            primaryButtonAction={() => history.push(urlTo('studentDetailsAdd'))}
            secondaryButtonClassName={'d-none'}
          />
        )}
        {showAttachmentsRequiredModal && (
          <BasicModal
            toggleModal={this.toggleAttachmentsRequiredModal}
            title={attachmentsRequiredModal.title}
            body={attachmentsRequiredModal.body}
            primaryButtonLabel={attachmentsRequiredModal.primaryBtnLabel}
            primaryButtonAction={this.toggleAttachmentsRequiredModal}
            secondaryButtonClassName={'d-none'}
          />
        )}
      </Display.CenterContentContainer>
    )
  }
}

export default StudentDetails
