import React from 'react'

import { DropTarget } from 'react-dnd'

import { setMainImage } from 'app/sm/ppp/property_actions'

const listTarget = {
  drop: function (props, monitor) {
    const photoId = monitor.getItem().photoId
    const { photo, property, setMainImage } = props
    setMainImage(property.guidID, photoId)
  },
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
  }
}

class PhotosListMainItem extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { connectDropTarget } = this.props
    const { photo, property } = this.props
    return connectDropTarget(
      <li className="main" key={photo.guidID}>
        <div className="image" style={{ backgroundImage: `url(${photo.URL})` }}>
          <button
            onClick={() =>
              this.props.deletePhoto(property.guidID, photo.guidID)
            }
            className="remove-btn"
          >
            <i className="icon-remove" aria-hidden="true" />
          </button>
          <div className="main-sign">
            <i className="icon-move"></i>
            <span>Main Image</span>
          </div>
        </div>
        <input type="text" className="name" defaultValue={photo.name} />
      </li>,
    )
  }
}

export default DropTarget('photo', listTarget, collect)(PhotosListMainItem)
