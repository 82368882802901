import * as Errors from 'app/constants/error_codes'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'

const Middleware404 = () => (next) => (action) => {
  const { responseText } = action
  const errorCode = parseInt(responseText, 10)

  if (Errors.ParameterIDNotValid === errorCode) {
    history.replace(urlTo('NotFound'))
    return
  }

  next(action)
}

export default Middleware404
