import React, { useCallback, useEffect, useState } from 'react'

import { Box } from 'app/components/design-system-components'
import * as FormComponent from 'app/shared_components/form_component'
import { history } from 'app/shared_components/router'
import { publishingOptions, urlIds, urlTo } from 'app/sm/helpers'
import Breadcrumbs from 'app/sm/ppp/breadcrumbs'

export const OfferPublishingOptions = ({
  offer = {},
  update,
  fetchOffer,
  responseText,
  spinner,
  match,
}) => {
  const { id: propertyId, offerId } = (match || {}).params || {}
  const { publishingOption: offerPublishingOption } = offer

  const [publishingOption, setPublishingOption] = useState(
    offerPublishingOption,
  )

  const fetchOfferDetail = useCallback(() => {
    fetchOffer(offerId)
  }, [fetchOffer, offerId])

  useEffect(() => {
    fetchOfferDetail(offerId)
  }, [propertyId, offerId, fetchOfferDetail])

  useEffect(() => {
    setPublishingOption(offerPublishingOption)
  }, [offerPublishingOption])

  const continueHandler = () => {
    update({
      guid: offerId,
      publishingOption,
    })
  }

  const backHandler = () => {
    history.push(
      urlTo(urlIds.updateOfferBase, {
        propertyId,
        offerId,
      }),
    )
  }

  return (
    <div className="profile-screen">
      <Breadcrumbs activeBreadCrumb="publishing-options" />
      <div className="mobile-only">
        <button
          onClick={backHandler}
          className="btn btn-transparent btn-left xs-text-center wa"
        >
          <i className="icon-arrow-left left"></i>
          <span>Back</span>
        </button>
      </div>

      <div className="two-col-box sm64">
        <div className="col-first xs-second">
          <div className="section-title">
            <div className="left column">
              <h3>Publishing Options</h3>
            </div>
          </div>

          <div className="display-flex mobile-flex-direction-column col-sm-12 p0">
            <FormComponent.RadioGroup
              label=""
              id="published"
              field="published"
              componentClassName="property-details-radio-group col-sm-12 pr0"
            >
              <Box>
                <FormComponent.Checkbox
                  id={publishingOptions.PublishedOption}
                  label="Live (will display and prospect enquirers)"
                  checked={
                    publishingOption === publishingOptions.PublishedOption
                  }
                  onChange={() =>
                    setPublishingOption(publishingOptions.PublishedOption)
                  }
                />
                <FormComponent.Checkbox
                  id={publishingOptions.OffMarketOption}
                  label="Live Off Market (not displayed, no prospecting)"
                  checked={
                    publishingOption === publishingOptions.OffMarketOption
                  }
                  onChange={() =>
                    setPublishingOption(publishingOptions.OffMarketOption)
                  }
                />
                <FormComponent.Checkbox
                  id={publishingOptions.LeasedOption}
                  label="Leased (not displayed, no prospecting, no applications)"
                  checked={publishingOption === publishingOptions.LeasedOption}
                  onChange={() =>
                    setPublishingOption(publishingOptions.LeasedOption)
                  }
                />
              </Box>
            </FormComponent.RadioGroup>
          </div>

          {responseText && (
            <div className="alert alert-danger">{responseText}</div>
          )}

          <div className="row mt25 mobile-form-button">
            <div className="col-sm-5 pb5 tablet-only">
              <button
                onClick={backHandler}
                className="btn btn-transparent btn-left xs-text-center wa"
              >
                <i className="icon-arrow-left left"></i>
                <span>Back</span>
              </button>
            </div>
            <div className="col-sm-7 pb5">
              <button onClick={() => continueHandler()}>
                Continue{' '}
                <i className={spinner ? 'fa fa-spinner fa-pulse' : ''} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
