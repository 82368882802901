import { X } from 'app/sm/helpers'

export const PING = 'APO_PING'
export const RECEIVE_SLIDERS = 'APO_RECEIVE_SLIDERS'

const receiveSliders = (sliders) => ({ type: RECEIVE_SLIDERS, sliders })

export const ping = () => ({ type: PING })

const mock = {
  sliders: [
    {
      id: '1',
      question: 'How important is that the rent comes first of the month? ',
      left: 'Not so much',
      right: 'Important',
      value: '5',
    },
    {
      id: '2',
      question: 'How important is that the rent comes first of the month? ',
      left: 'Not so much',
      right: 'Important',
      value: '7',
    },
    {
      id: 'T',
      question: 'How important is that the rent comes first of the month? ',
      left: 'Not so much',
      right: 'Important',
      value: '3',
    },
    {
      id: 'D',
      question: 'How important is that the rent comes first of the month? ',
      left: 'Not so much',
      right: 'Important',
      value: '6',
    },
    {
      id: 'C',
      question: 'How important is that the rent comes first of the month? ',
      left: 'Not so much',
      right: 'Important',
      value: '4',
    },
  ],
}

export function fetchSliders() {
  return (dispatch) => {
    dispatch(receiveSliders(mock.sliders))
  }
}

export function updateSlider(id, value) {
  return (dispatch, getState) => {
    const { sliders } = getState().OwnerProfile
    const newSliders = sliders.map((s) => (s.id !== id ? s : X(s, { value })))

    dispatch(receiveSliders(newSliders))
  }
}
