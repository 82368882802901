import React from 'react'

import styled from 'styled-components'

import {
  Box,
  Flex,
  HorizontalLine,
} from 'app/components/design-system-components'
import { DoneRounded } from 'app/components/design-system-components/icons/actions'
import * as getters from 'app/components/design-system-components/theme/getters'
import {
  Heading,
  Text,
} from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ModalContentWrapper } from 'app/modals/modal-styled'

const CheckIconMark = styled(Flex)`
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  font-size: 2.5rem;
  background-color: ${getters.color('success600')};
`

const StyledDl = styled.dl`
  display: grid;
  grid-template-columns: 40% auto;
  row-gap: ${getters.spacing(6)};

  margin: ${getters.spacing(6)} 0;

  dt {
    grid-column: 1;
  }

  dd {
    grid-column: 2;
  }
`

export const SuccessModalSimpleDescriptionList = ({
  listConfig: { listData },
}) => {
  const listElements = listData.map(({ title, description }) => (
    <React.Fragment key={title}>
      <dt>{title}</dt>
      <dd>{description}</dd>
    </React.Fragment>
  ))

  return <StyledDl>{listElements}</StyledDl>
}

export const SuccessModal = ({ title, description, actions, children }) => {
  return (
    <Box className="modal-fullscreen" bg={theme.colors.overlay}>
      <ModalContentWrapper maxWidth="516px" height="641px">
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box p="49px 63px 0">
            <Flex flexDirection="column" alignItems="center">
              <CheckIconMark mb={theme.space[6]}>
                <DoneRounded color={theme.colors.white} />
              </CheckIconMark>
              <Heading level={2} displayingLevel={2}>
                {title}
              </Heading>
              <Text textAlign="center" mt={theme.space[6]}>
                {description}
              </Text>
            </Flex>
            <HorizontalLine mt={theme.space[8]} />
            {children}
            <HorizontalLine />
          </Box>
          <Box p="0 28px 21px">{actions}</Box>
        </Flex>
      </ModalContentWrapper>
    </Box>
  )
}
