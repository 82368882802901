export const covidAlertIds = {
  overseasVisitorAndHasColdSymptoms: 'overseasVisitorAndHasColdSymptoms',
  hasColdSymptoms: 'hasColdSymptoms',
  recentOverseasVisit: 'recentOverseasVisit',
}

export const covidAlertsConstants = {
  [covidAlertIds.overseasVisitorAndHasColdSymptoms]: `Has indicated they been overseas in the last 14 days and has cold/flu symptoms.`,
  [covidAlertIds.hasColdSymptoms]: `Has indicated they have cold/flu symptoms.`,
  [covidAlertIds.recentOverseasVisit]: `Has indicated they have been overseas or in a COVID affected area in the last 14 days.`,
}
