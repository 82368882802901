import { connect } from 'react-redux'

import { getSecondaryTenantReview } from 'app/bond_cover/bond_cover_actions'
import LeaseeStartContainer from 'app/bond_cover/renters/tenants/leasee_start_container'

const mapStateToProps = ({ bondCover, session }) => ({
  apiError: bondCover.error,
  request: bondCover.request,
  currentLease: bondCover.currentLease,
  renter: bondCover.renter,
  tenant: bondCover.tenant,
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  getSecondaryTenantReview: (requestID, tenantID) =>
    getSecondaryTenantReview(requestID, tenantID),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeaseeStartContainer)
