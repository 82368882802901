import React from 'react'

import Flex from 'app/components/design-system-components/Flex'
import { standardUTCFormat } from 'app/sm/helpers'
import DateDropDown from 'app/sm/properties/components/excluded_times/partials/date_dropdown'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

export const FilterDateRange = ({ filterConfig, onChange, ...props }) => {
  const {
    filterId,
    filterValues = {},
    setFilterValues,
    startFieldName,
    endFieldName,
    format = standardUTCFormat,
  } = filterConfig

  const fromDate = filterValues[filterId]
    ? filterValues[filterId][startFieldName]
    : null
  const toDate = filterValues[filterId]
    ? filterValues[filterId][endFieldName]
    : null

  return (
    <Flex alignItems="center">
      <DateDropDown
        date={fromDate}
        format={format}
        {...(props.dropDownWidth && { width: props.dropDownWidth })}
        height="40px"
        onDateChangedHandler={(date) => {
          onChange(date)
          setFilterValues({
            [filterId]: {
              ...filterValues[filterId],
              ...{ [startFieldName]: date },
            },
          })
        }}
        closeOnChange
        labelRenderer={(date) =>
          date
            ? `${dateTimeHelpers.formattedUTCDisplayDate(date)}`
            : 'Start date'
        }
      />
      to
      <DateDropDown
        ml={4}
        mr={0}
        date={toDate}
        format={format}
        {...(props.dropDownWidth && { width: props.dropDownWidth })}
        height="40px"
        onDateChangedHandler={(date) => {
          onChange(date)
          setFilterValues({
            [filterId]: {
              ...filterValues[filterId],
              ...{ [endFieldName]: date },
            },
          })
        }}
        closeOnChange
        labelRenderer={(date) =>
          date ? `${dateTimeHelpers.formattedUTCDisplayDate(date)}` : 'End date'
        }
      />
    </Flex>
  )
}
