import React from 'react'

import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

import { StyledBranding } from '.'

const StyledBanner = styled(Box)`
  margin-bottom: ${({ theme }) => theme.baseSpace * 4}px;
  img {
    width: 510px;
  }
`

const StyledLogo = styled(Box)`
  width: 200px;
  height: 50px;
  margin-bottom: ${({ theme }) => theme.baseSpace * 4}px;
  img {
    width: 100%;
  }
`

function HasBannerAndLogo({ bannerURL = '', logoURL = '', agencyName = '' }) {
  return (
    <StyledBranding>
      <Box mb={theme.space[8] + 'px'}>
        Complete your branding setup. Need help? Email your logo to{' '}
        <a
          href={`mailto:help@snug.com?subject=Branding Logo Request:${agencyName}&body=Please update the branding for ${agencyName} with the attached logo. Thank you.`}
        >
          help@snug.com
        </a>
      </Box>
      <h5>Large Banner</h5>
      <Box mt={theme.space[3] + 'px'} mb={theme.space[5] + 'px'}>
        Your primary banner used in most places such as emails.
      </Box>
      <StyledBanner>
        <img src={bannerURL} alt="banner" />
      </StyledBanner>
      <h5>Small banner</h5>
      <Box mt={theme.space[3] + 'px'} mb={theme.space[5] + 'px'}>
        This is used in locations such as on the property landing page.
      </Box>
      <StyledLogo>
        <img src={logoURL} alt="logo" />
      </StyledLogo>
    </StyledBranding>
  )
}

export default HasBannerAndLogo
