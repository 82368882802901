import React, { Component } from 'react'

import moment from 'moment'

import AgencyInfoHeader from 'app/components/agency_info_header/component'
import AgencyFilterSection from 'app/components/agency_search_section/connection'
import { Box, Flex } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import {
  defaultEditViewingModalValues,
  durationOptionsNumbers,
} from 'app/components/display/edit_viewing_modal/viewing_modal_data'
import { searchAddressEmailNamePhonePlaceholder } from 'app/components/text/placeholderText'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages, {
  translateErrorCodeToMessage,
} from 'app/constants/error_messages'
import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import { setURLQuery } from 'app/helpers/query_string'
import {
  ViewingsButtonsContainer,
  ViewingsContainer,
} from 'app/pages/teams/viewings/styles'
import ViewingBlock from 'app/pages/teams/viewings/ViewingBlock'
import ViewingsHeader from 'app/pages/teams/viewings/ViewingsHeader'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import SwitchToNewPage from 'app/shared_components/SwitchToNewPage'
import Spinner from 'app/sm/common/spinner'
import * as helpers from 'app/sm/helpers'
import * as viewingsHelpers from 'app/utils/viewings/helpers'
import * as Features from 'config/features'

const selectPropertyOfferId = (property) =>
  property && property.offers[0].guidID
const TRY_NEW_VERSION_MESSAGE = 'Try new version'

class PropertyViewings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeModal: '',
      requestPending: true,
      addViewingModal: {
        ...defaultEditViewingModalValues,
      },
      viewingsByDate: [],
      apiError: '',
      viewingEntryNoticeEnabled: false,
      agencyProfile: {
        agencyAddress: '',
        agencyContactNumber: '',
        agencyEmail: '',
        brandingBannerURL: '',
        brandingLogoURL: '',
      },
      viewingRunEnabledForTeam: false,
      teamAttributesCallDone: false,
      isViewingReportSmsEnabled: false,
      isViewingReportEmailEnabled: true,
      currentDate: {
        time: '',
        date: '',
      },
    }
  }

  componentDidMount() {
    const { currentTeam, filters } = this.props
    this.setURLQueryAndFetchViewings(currentTeam, filters)

    if (currentTeam) {
      this.fetchAgencyAndSetAgencyState()
    }
  }

  componentDidUpdate = (prevProps) => {
    const { currentTeam, filters } = this.props
    if (
      helpers.filtersHaveChanged(prevProps, this.props) ||
      prevProps.currentTeam !== currentTeam
    ) {
      this.setState({ requestPending: true })
      this.setURLQueryAndFetchViewings(currentTeam, filters)
      this.fetchAgencyAndSetAgencyState()
    }
  }

  fetchAgencyAndSetAgencyState = () => {
    const { currentTeam } = this.props
    this.callFetchAgencyPublicProperties(currentTeam.slug).then(
      ({ properties }) => {
        this.setState({
          agencyProfile: {
            agencyAddress: properties.contactAddress,
            brandingBannerURL: properties.brandingBannerURL,
            brandingLogoURL: properties.brandingLogoURL,
            agencyContactNumber: properties.contactNumber,
            agencyEmail: properties.contactEmail,
            agencyName: properties.contactName,
          },
          currentDate: {
            time: moment().format('LT'),
            date: moment().format('D MMM YYYY'),
          },
        })
      },
    )
  }

  onClickAddViewing = () => {
    const { addViewingModal = {} } = this.state
    const { addViewing, property = {}, refetchTheViewingList } = this.props
    const offerId = selectPropertyOfferId(property)
    const { startDate, startTime, published, notify, duration } =
      addViewingModal || {}
    const mergedStartDate = `${startDate} ${startTime}`
    const formattedStartDate = helpers.getStandardFormattedDate(
      moment(mergedStartDate, helpers.dateTimeWithDash).utc(),
      helpers.utcFormatTimeStamp,
    )
    const viewingOptions = {
      published,
      notify,
    }
    if (property && property.guidID) {
      addViewing(
        offerId,
        formattedStartDate,
        durationOptionsNumbers[duration],
        false,
        false,
        viewingOptions,
      )
        .then(() => {
          snugNotifier.success('Viewing Successfully added!')
          this.setState({ activeModal: '' })
          refetchTheViewingList()
        })
        .catch((error) => {
          if (
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForProperty] ||
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForManager]
          )
            if (window.confirm(viewingsHelpers.conflictingTimeMessage)) {
              addViewing(
                offerId,
                formattedStartDate,
                durationOptionsNumbers[duration],
                false,
                true,
                viewingOptions,
              )
                .then(() => {
                  snugNotifier.success('Viewing Successfully added!')
                  this.setState({ activeModal: '' })
                  refetchTheViewingList()
                })
                .catch((error) => {
                  snugNotifier.error(translateErrorCodeToMessage(error))
                })
            } else {
              snugNotifier.error(translateErrorCodeToMessage(error))
            }
        })
    }
  }

  setURLQueryAndFetchViewings = debounce(
    (currentTeam = this.props.currentTeam, filters = this.props.filters) => {
      const { fetchViewingsByDateFromTeamId, fetchTeamSettingInfo } = this.props
      const { guid: teamGUID } = currentTeam || {}

      if (currentTeam && filters) {
        fetchTeamSettingInfo(currentTeam.guid, 'notifications,viewings')
          .then(({ notifications, viewings }) => {
            const {
              viewing_entry_notice_enabled: viewingEntryNoticeEnabled = false,
            } = notifications

            const {
              viewing_run_enabled: viewingRunEnabled = 'false',
              viewing_report_sms_enabled: isViewingReportSmsEnabled = false,
              viewing_report_email_enabled: isViewingReportEmailEnabled = false,
            } = viewings

            this.setState({
              viewingEntryNoticeEnabled: viewingEntryNoticeEnabled === 'true',
              viewingRunEnabledForTeam: viewingRunEnabled === 'true',
              isViewingReportSmsEnabled: isViewingReportSmsEnabled === 'true',
              isViewingReportEmailEnabled:
                isViewingReportEmailEnabled === 'true',
            })
          })
          .catch((error) => {
            this.setState({ error })
          })
          .finally(() => {
            this.setState({
              teamAttributesCallDone: true,
            })
          })
        const { pathname } = window.location
        setURLQuery(history, pathname, filters)
        fetchViewingsByDateFromTeamId(teamGUID, filters)
          .then(({ data }) =>
            this.setState({
              ...this.state,
              viewingsByDate: data,
              requestPending: false,
            }),
          )
          .catch((error) => {
            this.setState({ apiError: error, requestPending: false })
          })
      }
    },
    STANDARD_TIMEOUT,
  ) // debounce

  callFetchAgencyPublicProperties(agencySlug) {
    const { fetchAgencyPublicProperties } = this.props
    return fetchAgencyPublicProperties(agencySlug)
      .then(({ properties }) => {
        return Promise.resolve({ properties })
      })
      .catch((error) => {
        this.setState({ error })
        return Promise.reject(error)
      })
  }

  openPrintDialog = (e) => {
    const currentViewingBlock = e.target.closest('.viewing-block')

    this.setState(
      {
        currentDate: {
          time: moment().format('LT'),
          date: moment().format('D MMM YYYY'),
        },
      },
      function () {
        const allViewingBlocks =
          document.getElementsByClassName('viewing-block')

        for (var i = 0; i < allViewingBlocks.length; i++) {
          allViewingBlocks[i].classList.add('pdf-hide')
        }
        currentViewingBlock.classList.remove('pdf-hide')
        window.print()
      },
    )
  }

  updateAddViewingModal = (modalValues) => {
    this.setState({
      ...this.state,
      addViewingModal: {
        ...this.state.addViewingModal,
        ...modalValues,
      },
    })
  }

  render() {
    const {
      fetchPropertyViewings,
      cancelViewing,
      toggleWalkInModal,
      clearError,
      fetchAgencyApplicantsElasticSearchResult,
      renterInfo,
      addWalkInRenter,
      currentUser,
      showLateViewingModal,
      notifyLateViewing,
      currentTeam,
      teams,
      assignViewingToTeamMember,
      fetchViewingTeamMembers,
      deleteViewingTeamMember,
      updateViewingTeamMemberRelation,
      getManagersListForViewingFilters,
      fetchPropertyReport,
    } = this.props

    const { name: teamName, slug, guid: teamGUID } = currentTeam || {}
    const {
      activeModal,
      requestPending,
      viewingsByDate,
      apiError,
      viewingEntryNoticeEnabled,
      currentDate,
      viewingRunEnabledForTeam,
      teamAttributesCallDone,
      isViewingReportSmsEnabled,
      isViewingReportEmailEnabled,
    } = this.state
    const paramsTeamSlug = this.props.match.params.teamSlug
    const teamSlug = slug || paramsTeamSlug

    let crumbs = [
      {
        text: teamName,
        link: helpers.urlTo('teamOverview', { teamSlug }),
      },
      {
        text: 'Viewings',
        link: `${helpers.urlTo('prospectSummary', { teamSlug })}?stage=Viewing`,
      },
    ]
    let lastCrumb = null
    const viewingTools = [
      {
        text: 'View Runs',
        enableTool: true,
        onClick: () => {
          history.push(helpers.urlTo('newViewingsRunAll', { teamSlug }))
        },
      },
    ]
    const windowWidth = helpers.checkForWindowWidth()

    return (
      <div>
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <Breadcrumbs crumbs={crumbs.concat(lastCrumb || [])} />
          <div className="pdf-show-block">
            <p className="mb20">
              Report generated: {`${currentDate.time} on ${currentDate.date}`}
            </p>
          </div>
        </Flex>
        <div className="pdf-show-block">
          <AgencyInfoHeader agency={this.state.agencyProfile} />
        </div>
        {windowWidth < 576 && (
          <SwitchToNewPage
            message={TRY_NEW_VERSION_MESSAGE}
            primarySwitchLinkText="Switch"
            primarySwitchLinkPath={helpers.urlTo('teamMobileViewings', {
              teamSlug,
            })}
            backgroundColor={false}
            showPadding={false}
          />
        )}
        <div className="pdf-hide">
          <Flex justifyContent="space-between">
            <h1>Viewings</h1>
            <ViewingsButtonsContainer>
              <button
                className="hollow-button-green wa"
                onClick={() =>
                  history.push(helpers.urlTo('teamsViewing', { teamSlug }))
                }
              >
                Add viewing
              </button>

              {(Features.isViewingRunEnabled(teamSlug, teams) ||
                viewingRunEnabledForTeam) &&
                teamAttributesCallDone && (
                  <ViewingsContainer>
                    <button
                      className="hollow-button-green wa"
                      onClick={() =>
                        viewingRunEnabledForTeam
                          ? history.push(
                              helpers.urlTo('newViewingsRunCreate', {
                                teamSlug,
                              }),
                            )
                          : history.push(
                              helpers.urlTo('newViewingRun', { teamSlug }),
                            )
                      }
                    >
                      Add run
                    </button>
                    <Display.ToolsButton tools={viewingTools} />
                  </ViewingsContainer>
                )}
            </ViewingsButtonsContainer>
          </Flex>
          <Box mb="30px">
            Create and manage open homes, private appointments and ideal week,
            plus Check-in and Invite to Apply.{' '}
            <a
              href="https://help.snug.com/hc/en-us/articles/360001289756"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </Box>
        </div>
        <div className="pdf-hide">
          <AgencyFilterSection
            disableSearchStatus
            disableSearchTodayOnly
            disableDatePicker
            disableSortby
            enableViewingsSortBy
            disableSearchManager
            enableViewingSearchManager={true}
            placeholderText={searchAddressEmailNamePhonePlaceholder}
          />
        </div>
        {apiError && (
          <div className="mt10 mb20 text-content width85p">
            <div className="alert alert-danger">{apiError}</div>
          </div>
        )}
        {requestPending ? (
          <Spinner />
        ) : (
          <div>
            <div className="pdf-hide">
              <ViewingsHeader />
            </div>
            {viewingsByDate && viewingsByDate.length > 0 ? (
              viewingsByDate.map((data, index) => {
                return (
                  <div className="viewing-block">
                    <ViewingBlock
                      key={index}
                      openPrintDialog={(e) => this.openPrintDialog(e)}
                      viewingInfo={data}
                      fetchPropertyReport={fetchPropertyReport}
                      fetchPropertyViewings={fetchPropertyViewings}
                      cancelViewing={cancelViewing}
                      teamGUID={teamGUID}
                      fetchViewingsByDateFromTeamId={
                        this.setURLQueryAndFetchViewings
                      }
                      updateAllViewingsData={this.setURLQueryAndFetchViewings}
                      toggleWalkInModal={toggleWalkInModal}
                      clearError={clearError}
                      fetchAgencyApplicantsElasticSearchResult={
                        fetchAgencyApplicantsElasticSearchResult
                      }
                      addWalkInRenter={addWalkInRenter}
                      currentUser={currentUser}
                      showLateViewingModal={showLateViewingModal}
                      notifyLateViewing={notifyLateViewing}
                      assignViewingToTeamMember={assignViewingToTeamMember}
                      managersListForViewingFilters={
                        getManagersListForViewingFilters
                      }
                      fetchViewingTeamMembers={fetchViewingTeamMembers}
                      deleteViewingTeamMember={deleteViewingTeamMember}
                      updateViewingTeamMemberRelation={
                        updateViewingTeamMemberRelation
                      }
                      viewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
                      isViewingReportSmsEnabled={isViewingReportSmsEnabled}
                      isViewingReportEmailEnabled={isViewingReportEmailEnabled}
                      currentTeam={currentTeam}
                    />
                  </div>
                )
              })
            ) : (
              <div className="text-align-center mt25">
                No future viewings,{' '}
                <span
                  className="blue-link-style"
                  onClick={() =>
                    history.push(helpers.urlTo('teamsViewing', { teamSlug }))
                  }
                >
                  add one
                </span>
              </div>
            )}
          </div>
        )}
        {activeModal === helpers.modalNameCollection.addViewingModal && (
          <Display.EditViewingModal
            title="Add Viewing"
            primaryActionLabel="Add"
            secondaryActionLabel="Cancel"
            primaryButtonActionInParent={this.updateAddViewingModal}
            secondaryButtonAction={() => this.setState({ activeModal: '' })}
            toggleModal={() => this.setState({ activeModal: '' })}
            renterInfo={renterInfo}
          />
        )}
      </div>
    )
  }
}

export default PropertyViewings
