import React from 'react'

import checkmark from 'app/assets/icons/checkmark.svg'
import greyArrowDown from 'app/assets/icons/grey-arrow-down.svg'
import partnerLogos from 'app/assets/icons/partner-logos.png'
import { Box, Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  ExtraInfo,
  Img,
  LearnMoreWrapper,
  MainHeading,
  Wrapper,
} from 'app/match/leadToSnug/styles'
import * as textHelpers from 'app/utils/text/helpers'

const StyledCheckedItem = ({ content = '' }) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <img height="8px" width="8px" src={checkmark} alt="item" />
      <Box ml="8px">{content}</Box>
    </Flex>
  )
}

const FormWrapper = ({ children }) => {
  return (
    <div>
      <Wrapper margin="0 auto" maxWidth="546px">
        <MainHeading
          textAlign="center"
          fontSizeMobile={'22px'}
          lineHeightMobile={'26px'}
        >
          Wasting time on rental applications?
        </MainHeading>
        <Box mt={theme.baseSpace * 3 + 'px'}>
          <h5>Get better applications on Snug:</h5>
          <StyledCheckedItem content="works on any website" />
          <StyledCheckedItem content="customisable app quality and quantity" />
          <StyledCheckedItem content="works with off market, change of tenancy" />
          <StyledCheckedItem content="quick to set up, easy to use" />
          <StyledCheckedItem content="it's free!" />
        </Box>
        <Box className="text-align-center" mt={theme.baseSpace * 3 + 'px'}>
          Snug leasing software for agents.
        </Box>
        {children}
        <ExtraInfo>
          Join {textHelpers.MarketingPMCountPlus} property managers using Snug
        </ExtraInfo>
        <Img src={partnerLogos} alt="snug's partners logo" />
        <Box fontSize={3} pt={theme.baseSpace * 2 + 'px'}>
          {textHelpers.MarketingPMCountCaveatText}
        </Box>
      </Wrapper>
      <LearnMoreWrapper>
        <p>Learn more</p>
        <img src={greyArrowDown} alt="grey down arrow" />
      </LearnMoreWrapper>
    </div>
  )
}

export default FormWrapper
