import * as React from 'react'

function SvgWatchRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.96 5.73A7.976 7.976 0 0120 12c0 2.54-1.19 4.81-3.04 6.27l-.68 4.06c-.16.96-.99 1.67-1.97 1.67H9.7c-.98 0-1.82-.71-1.98-1.67l-.67-4.06A7.94 7.94 0 014 12a7.94 7.94 0 013.05-6.27l.67-4.06C7.88.71 8.72 0 9.7 0h4.61c.97 0 1.81.71 1.97 1.67l.68 4.06zM12 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgWatchRounded
