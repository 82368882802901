import styled from 'styled-components'
import { variant } from 'styled-system'

import Box from 'app/components/design-system-components/Box'

const valueInPx = ({ sizeInPx }) => sizeInPx + 'px'

export const circleVariants = {
  success: {
    backgroundColor: 'success600',
  },
  error: {
    backgroundColor: 'error400',
  },
  pending: {
    backgroundColor: 'gray200',
  },
  default: {
    backgroundColor: 'blue',
  },
}

export const Circle = styled(Box)`
  height: ${valueInPx};
  width: ${valueInPx};
  border-radius: 100%;
  ${variant({ variants: circleVariants })}
`
