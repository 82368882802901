import React from 'react'

import 'app/sm/progress_application/application_header/style.scss'
import SectionContainer from 'app/sm/progress_application/components/section_container/component'

const ApplicationFormHeader = ({
  headerTitle = '',
  onViewApplicationClicked,
  headerText = {},
}) => {
  return (
    <SectionContainer>
      <div className="application-title">{headerTitle}</div>
      <p className="text">
        {headerText}
        <span onClick={onViewApplicationClicked} className="blue-link-style">
          View application
        </span>
      </p>
    </SectionContainer>
  )
}

export default ApplicationFormHeader
