import { connect } from 'react-redux'

import {
  fetchRentalHistoryRatingCategories,
  fetchRentalHistoryReview,
  manualAddRentalLedger,
  manualCreateTenantRating,
  manualRemoveRentalLedger,
  manualRentalHistoryFinalize,
  manualUpdateTenantRating,
  rentalHistoryDecline,
  setRentalHistorySecret,
} from 'app/bond_cover/bond_cover_actions'
import RatingForm from 'app/match/managerRentalReview/manager_rental_rating_component'
import { addNote } from 'app/sm/review/review_actions'

const mapStateToProps = ({ bondCover, session }) => ({
  RentalHistory: bondCover.rentalHistoryReview.RentalHistory,
  ratingCategories: bondCover.ratingCategories,
  tenantRating: bondCover.rentalHistoryReview.Rating,
  renter: bondCover.rentalHistoryReview.Renter,
  message: bondCover.rentalHistoryReview.Message,
  isHistoryRating: true,
  currentUser: session.currentUser,
  secretGUID: bondCover.secretGUID,
  error: bondCover.error,
  showRentalLedgerSpinner: bondCover.showRentalLedgerSpinner,
  rentalHistoryReview: bondCover.rentalHistoryReview,
})

const mapDispatchToProps = (dispatch) => ({
  setRentalHistorySecret: (secretGUID) =>
    dispatch(setRentalHistorySecret(secretGUID)),
  fetchRentalHistoryReview: (referenceId) =>
    dispatch(fetchRentalHistoryReview(referenceId)),
  fetchRentalHistoryRatingCategories: () =>
    dispatch(fetchRentalHistoryRatingCategories()),
  createTenantRating: (referenceId, payload) =>
    dispatch(manualCreateTenantRating(referenceId, payload)),
  updateTenantRating: (referenceId, payload) =>
    dispatch(manualUpdateTenantRating(referenceId, payload)),
  proceed: (referenceId, comment) =>
    dispatch(manualRentalHistoryFinalize(referenceId, comment)),
  decline: (referenceId, payload) =>
    dispatch(rentalHistoryDecline(referenceId, payload)),
  addRentalLedger: (historyGUID, secretGUID, documentType) =>
    dispatch(manualAddRentalLedger(historyGUID, secretGUID, documentType)),
  removeRentalLedger: (historyGUID, secretGUID, ledgerGUID) =>
    dispatch(manualRemoveRentalLedger(historyGUID, secretGUID, ledgerGUID)),
  addNote: (applicationId, applicantId, payload) =>
    dispatch(addNote(applicationId, applicantId, payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RatingForm)
