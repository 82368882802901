import React from 'react'

const labelClassnames = (isActive) => 'sm-ra-tab ' + (isActive ? 'active' : '')

class RATabs extends React.Component {
  onChange(labelId) {
    this.props.onChange(labelId)
  }

  render() {
    const { labels } = this.props

    return (
      <div className="sm-ra-tabs flex-direction-row-under500">
        {labels.map((l) => (
          <div
            key={l.id}
            className={labelClassnames(l.isActive)}
            onClick={() => this.onChange(l.id)}
          >
            <div className="sm-ra-tab__title sm-pt10">{l.title}</div>
            <div className="sm-ra-tab__count sm-pt10">{l.count}</div>
          </div>
        ))}
      </div>
    )
  }
}

export default RATabs
