import { Alert } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'

export function SouthAustralianAlert({
  message = 'In South Australia, property providers may not request date of birth.',
}) {
  return (
    <Alert
      variant="blueWithBg"
      showLeftIcon={true}
      leftIconVariant="info"
      mb={5}
      fontSize={theme.fontSizes.pRegular14}
    >
      {message}
    </Alert>
  )
}
