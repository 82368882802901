/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import {
  Box,
  Button,
  Dropdown,
  Flex,
  HorizontalLine,
} from 'app/components/design-system-components'
import { SearchRounded } from 'app/components/design-system-components/icons/actions'
import { Input } from 'app/components/design-system-components/inputs'
import { Select } from 'app/components/design-system-components/inputs/Select/Select'
import * as themeGetter from 'app/components/design-system-components/theme/getters'
import { Text } from 'app/components/design-system-components/typography'
import { ManagersOptionsList } from 'app/dashboard/team_activity/ActivityFilters/ManagerFilter'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  deleteUnassignPropertyManagersForProperty,
  getPropertyManagers,
  getPropertyManagersForProperty,
  postAssignPropertyManagersForProperty,
} from 'app/services/http/properties'
import * as snugNotifier from 'app/services/snugNotifier'
import Spinner from 'app/sm/common/spinner'

const FilterDropdownBodyBox = styled(Box)`
  box-shadow: 0 4px 30px 0 ${themeGetter.color('dropdownDroppedShadow')};
  background-color: ${themeGetter.color('white')};
  position: absolute;
  z-index: 10;
`

const LISTING_AGENT = 'LISTING_AGENT'
const PROPERTY_MANAGER = 'PROPERTY_MANAGER'

export const AssignTeamMember = {
  [PROPERTY_MANAGER]: {
    display: 'Property Manager',
    role: 'PropertyManager',
  },
  [LISTING_AGENT]: {
    display: 'Listing Agent',
    role: 'ListingAgent',
  },
}

const PropertyManagerContainer = styled.div`
  .preference-label {
    width: 200px;
  }
`

const MININUM_ASSIGN_TEAM_MEMBER_VALIDATE_TEXT = 'One Listing Agent required'
const MAXIMUM_ASSIGN_TEAM_MEMBER_VALIDATE_TEXT =
  'Maximum 3 assigned team members'

const PropertyListingAgent = ({
  managingAgentID = '',
  guidDetails,
  onManagingAgentUpdated,
}) => {
  const [allManagersSelected, setAllManagersSelected] = useState([])
  const [managerList, setManagerList] = useState([])
  const [managerValues, setManagerValues] = useState()
  const [searchText, setSearchText] = useState('')
  const [isAssignTeamMemberLoading, setIsAssignTeamMemberLoading] =
    useState(false)
  const [isAddingNewAssignTeamMember, setIsAddingNewAssignTeamMember] =
    useState(false)

  const [addAssignTeamMemberConfig, setAddAssignTeamMemberConfig] = useState({
    isSearching: false,
    contactDetail: {},
    role: '',
  })

  useEffect(() => {
    loadManagerList(guidDetails.agencyGUID, guidDetails.property)
  }, [guidDetails.agencyGUID, guidDetails.property])

  const loadManagerList = (agencyGUID, propertyGUID) => {
    if (agencyGUID) {
      setIsAssignTeamMemberLoading(true)
      getPropertyManagers(agencyGUID)
        .then(async (managerData) => {
          return new Promise((resolve, reject) => {
            setManagerList(managerData)
            propertyGUID &&
              getPropertyManagersForProperty(agencyGUID, propertyGUID)
                .then(({ managers }) => {
                  const selectedManagers = managers
                    .map(({ managerUser, role, contactGUID }) => ({
                      firstName: managerUser.firstName,
                      lastName: managerUser.lastName,
                      email: managerUser.email,
                      role: Object.keys(AssignTeamMember).find(
                        (key) => AssignTeamMember[key].role === role,
                      ),
                      managerUserGUID: contactGUID,
                    }))
                    .filter(
                      ({ firstName = '', lastName = '', email = '' }) =>
                        email && (firstName || lastName),
                    )
                  setAllManagersSelected([...selectedManagers])
                })
                .finally(() => {
                  resolve()
                })
            !propertyGUID && resolve()
          })
        })
        .finally(() => {
          setIsAssignTeamMemberLoading(false)
        })
    }
  }

  const handleAddAnotherAssignMember = (payload) => {
    setIsAssignTeamMemberLoading(true)
    postAssignPropertyManagersForProperty(
      guidDetails.agencyGUID,
      guidDetails.property,
      payload,
    )
      .then(() => {
        loadManagerList(guidDetails.agencyGUID, guidDetails.property)
      })
      .catch((err) => {
        setIsAssignTeamMemberLoading(false)
        snugNotifier.error(err?.message || 'Failed to add assign team member')
      })
      .finally(() => {
        setManagerValues({})
        setAddAssignTeamMemberConfig({
          isSearching: false,
          contactDetail: {},
          role: '',
        })
        setIsAddingNewAssignTeamMember(false)
      })
  }

  const onSearchTextChange = ({ target: { value } }) => {
    setSearchText(value)
  }

  const onClickAddSearchAssignTeamMember = (open) => {
    setAddAssignTeamMemberConfig({
      ...addAssignTeamMemberConfig,
      isSearching: true,
    })
    open()
  }

  const onClickRemoveAssignTeamMember = (managerUserGUID, managerUserEmail) => {
    const toRemoveTeamMemberUserId =
      managerList.find(({ profile }) => {
        const { email = '' } = profile || {}

        return email === managerUserEmail
      })?.agencyUserID || 0
    const isRemoveCurrentManagerTeamMember =
      toRemoveTeamMemberUserId === managingAgentID

    const isAtLeaseOneListingAgenctAfterRemove = allManagersSelected
      .filter(
        ({ managerUserGUID: managerGUID }) => managerGUID !== managerUserGUID,
      )
      .some(({ role }) => role === LISTING_AGENT)

    if (!isAtLeaseOneListingAgenctAfterRemove) {
      snugNotifier.error(MININUM_ASSIGN_TEAM_MEMBER_VALIDATE_TEXT)
    } else {
      setIsAssignTeamMemberLoading(true)
      deleteUnassignPropertyManagersForProperty(
        guidDetails.agencyGUID,
        guidDetails.property,
        managerUserGUID,
      )
        .then(() => {
          if (isRemoveCurrentManagerTeamMember) {
            const newListingAgentEmail =
              allManagersSelected
                .filter(
                  ({ managerUserGUID: managerGUID }) =>
                    managerGUID !== managerUserGUID,
                )
                .find(({ role }) => role === LISTING_AGENT)?.email || ''

            const newListingAgentUserId =
              managerList.find(({ profile }) => {
                const { email = '' } = profile || {}
                return email === newListingAgentEmail
              })?.agencyUserID || 0
            savePropertyListingAgent(newListingAgentUserId)
            setIsAssignTeamMemberLoading(false)
          } else {
            loadManagerList(guidDetails.agencyGUID, guidDetails.property)
          }
        })
        .catch((err) => {
          setIsAssignTeamMemberLoading(false)
          snugNotifier.error(
            err?.message || 'Failed to remove assign team member',
          )
        })
    }
  }

  const onClickSelectManager = (isAddInitialListingAgent = false) => {
    const selectedManager =
      Object.values(managerValues).length > 0
        ? Object.values(managerValues)[0].option
        : {}

    const { agencyUserID } = selectedManager
    setAddAssignTeamMemberConfig({
      ...addAssignTeamMemberConfig,
      contactDetail: selectedManager,
      isSearching: false,
    })

    isAddInitialListingAgent && onManagingAgentUpdated?.(agencyUserID, () => {})
  }

  const savePropertyListingAgent = (agencyUserID) => {
    onManagingAgentUpdated?.(agencyUserID, () => {
      loadManagerList(guidDetails.agencyGUID, guidDetails.property)
    })
  }

  const options = Object.keys(AssignTeamMember).map((key) => ({
    option: key,
    optionMeta: {
      id: key,
      displayText: AssignTeamMember[key].display,
    },
  }))

  const managerOptions = managerList
    .map((manager) => {
      const { firstName, lastName } = manager.profile
      return {
        option: manager,
        optionMeta: {
          id: manager.guidID,
          displayText: `${firstName} ${lastName}`,
        },
      }
    })
    .filter(({ optionMeta: { displayText } }) =>
      displayText.toLowerCase().includes(searchText.toLowerCase()),
    )

  const onCheckBoxClicked = (checked, option) => {
    if (checked) {
      setManagerValues({
        [option.optionMeta.id]: option,
      })
    } else {
      setManagerValues({})
    }
  }

  const onChangeRole = (opt) => {
    setAddAssignTeamMemberConfig({
      ...addAssignTeamMemberConfig,
      role: opt,
    })
    const { contactDetail } = addAssignTeamMemberConfig
    const { agencyUserID = '' } = contactDetail
    const payload = {
      managerUserID: agencyUserID,
      role: AssignTeamMember[opt.option].role,
    }
    if (agencyUserID) {
      handleAddAnotherAssignMember(payload)
    } else {
      snugNotifier.error('No team member has been selected')
    }
  }

  const onClickCancelAddAssignTeamMember = () => {
    setManagerValues({})
    setAddAssignTeamMemberConfig({
      isSearching: false,
      contactDetail: {},
      role: '',
    })
    setIsAddingNewAssignTeamMember(false)
  }

  const onClickAddAnotherAssignTeamMember = () => {
    if (allManagersSelected.length < 3) {
      setIsAddingNewAssignTeamMember(true)
    } else {
      snugNotifier.error(MAXIMUM_ASSIGN_TEAM_MEMBER_VALIDATE_TEXT)
    }
  }

  const _renderOptions = (isAddInitialListingAgent) => {
    const filteredManagerOptions = managerOptions.filter(({ option }) => {
      const { profile } = option || {}
      const { email } = profile || {}
      return !allManagersSelected.map(({ email }) => email).includes(email)
    })

    const meOptionIndex = filteredManagerOptions.findIndex(
      ({ option: { isMe } }) => !!isMe,
    )

    const selectedManagers = managerValues || {}
    const clonedOptions = [...filteredManagerOptions]
    const [meOption] = clonedOptions.splice(meOptionIndex, 1)

    return addAssignTeamMemberConfig.isSearching ? (
      <FilterDropdownBodyBox>
        <Box maxHeight="100%">
          <Box p={theme.space[4] + 'px'}>
            <Input
              icon={<SearchRounded style={{ color: theme.colors.gray400 }} />}
              inputProps={{
                onChange: onSearchTextChange,
                placeholder: 'Search for a contact',
              }}
            />
          </Box>
          {meOptionIndex < 0 ? (
            <ManagersOptionsList
              options={filteredManagerOptions}
              listTitle="All Contact"
              onCheckBoxClicked={onCheckBoxClicked}
              selectedOptions={selectedManagers}
            />
          ) : (
            <Box maxHeight="200px" overflowY="auto">
              <ManagersOptionsList
                options={[meOption]}
                listTitle="You"
                onCheckBoxClicked={onCheckBoxClicked}
                selectedOptions={selectedManagers}
              />
              <ManagersOptionsList
                options={clonedOptions}
                listTitle="All Contact"
                onCheckBoxClicked={onCheckBoxClicked}
                selectedOptions={selectedManagers}
              />
            </Box>
          )}
        </Box>
        <HorizontalLine />
        <Box
          boxShadow={`${theme.space[0]}px -${theme.space[1]}px ${theme.dividedSpace[3]}px ${theme.space[0]}px ${theme.colors.searchAndSelectBoxShadow}`}
          p={theme.space[3] + 'px'}
        >
          <Button
            onClick={() => onClickSelectManager(isAddInitialListingAgent)}
            sizeVariant="regular"
            width="100%"
          >
            Select
          </Button>
        </Box>
      </FilterDropdownBodyBox>
    ) : (
      <></>
    )
  }

  const _renderAssignTeamMember = (manager, index) => {
    const {
      firstName = '',
      lastName = '',
      role,
      managerUserGUID,
      email = '',
    } = manager
    const renderName = `${firstName} ${lastName}`

    return (
      <Flex width="100%" alignItems="center">
        <Box py={theme.space[3] + 'px'} maxHeight="100%">
          <Box pr={theme.space[4] + 'px'}>
            <Input
              inputProps={{
                placeholder: 'Search for a manager',
                value: renderName,
              }}
              width="200px"
              height="50px"
            />
          </Box>
        </Box>
        <Select
          HeaderRenderCmp={() => <></>}
          options={options}
          value={{
            optionMeta: {
              id: role,
              displayText: AssignTeamMember[role].display,
            },
          }}
          label="Select property role"
          width="200px"
          triggerWrapperComponentProps={{ height: '50px' }}
          mr={4}
          disabled={true}
        />
        {allManagersSelected.length > 1 && (
          <a
            onClick={() =>
              onClickRemoveAssignTeamMember(managerUserGUID, email)
            }
          >
            remove
          </a>
        )}
      </Flex>
    )
  }

  const _renderAddAssignTeamMember = (isAddInitialListingAgent) => {
    const { profile } = addAssignTeamMemberConfig?.contactDetail || {}
    const { firstName = '', lastName = '' } = profile || {}
    const isSelectedContact = firstName && lastName
    if (!isAddInitialListingAgent) {
      return isAddingNewAssignTeamMember ? (
        <Flex width="100%" alignItems="center">
          <Box maxHeight="100%">
            <Dropdown
              triggerWrapperComponent={({ dropdownControls }) => {
                return (
                  <Box pr={theme.space[4] + 'px'}>
                    <Input
                      icon={
                        !isSelectedContact && (
                          <SearchRounded
                            style={{ color: theme.colors.gray400 }}
                          />
                        )
                      }
                      inputProps={{
                        placeholder: 'Search for a manager',
                        onClick: () =>
                          onClickAddSearchAssignTeamMember(
                            dropdownControls.open,
                          ),
                        value: isSelectedContact
                          ? `${firstName} ${lastName}`
                          : '',
                      }}
                      width="200px"
                      height="50px"
                    />
                  </Box>
                )
              }}
              dropdownComponent={(dropdownProps) => <> {_renderOptions()}</>}
              topSpaceInPx={9}
            />
          </Box>
          <Select
            HeaderRenderCmp={() => <></>}
            options={options}
            changeHandler={(opt) => {
              onChangeRole(opt)
            }}
            label="Select property role"
            width="200px"
            triggerWrapperComponentProps={{ height: '50px' }}
            mr={4}
          />
          <a onClick={onClickCancelAddAssignTeamMember}>cancel</a>
        </Flex>
      ) : allManagersSelected.length === managerList.length ||
        !(allManagersSelected.length < 3) ? (
        <></>
      ) : (
        <Box py={`${theme.space[4]}px`}>
          <a onClick={onClickAddAnotherAssignTeamMember}>Add another</a>
        </Box>
      )
    } else {
      return (
        <Flex width="100%" alignItems="center">
          <Box maxHeight="100%">
            <Dropdown
              triggerWrapperComponent={({ dropdownControls }) => {
                return (
                  <Box pr={theme.space[4] + 'px'}>
                    <Input
                      icon={
                        !isSelectedContact && (
                          <SearchRounded
                            style={{ color: theme.colors.gray400 }}
                          />
                        )
                      }
                      inputProps={{
                        placeholder: 'Search for a manager',
                        onClick: () =>
                          onClickAddSearchAssignTeamMember(
                            dropdownControls.open,
                          ),
                        value: isSelectedContact
                          ? `${firstName} ${lastName}`
                          : '',
                      }}
                      width="200px"
                      height="50px"
                    />
                  </Box>
                )
              }}
              dropdownComponent={(dropdownProps) => (
                <> {_renderOptions(isAddInitialListingAgent)}</>
              )}
              topSpaceInPx={9}
            />
          </Box>
          <Select
            HeaderRenderCmp={() => <></>}
            options={options}
            value={{
              optionMeta: {
                id: LISTING_AGENT,
                displayText: AssignTeamMember[LISTING_AGENT].display,
              },
            }}
            changeHandler={(opt) => {
              onChangeRole(opt)
            }}
            disabled={true}
            label="Select property role"
            width="200px"
            triggerWrapperComponentProps={{ height: '50px' }}
            mr={4}
          />
        </Flex>
      )
    }
  }

  return (
    <div className="mt20">
      <div className="mr15 font-size-18">
        <h5>Assign team members</h5>
        <Text my={3} fontSize={theme.fontSizes.pLarge16}>
          Assignments will reflect the property feed and property management
          system assignments
        </Text>
      </div>
      <PropertyManagerContainer>
        {!guidDetails.property && <> {_renderAddAssignTeamMember(true)}</>}
        {guidDetails.property && (
          <>
            {!isAssignTeamMemberLoading ? (
              <>
                {allManagersSelected.length > 0 &&
                  allManagersSelected.map((manager, index) => {
                    return _renderAssignTeamMember(manager, index)
                  })}
                {_renderAddAssignTeamMember()}
              </>
            ) : (
              <Spinner />
            )}{' '}
          </>
        )}
      </PropertyManagerContainer>
    </div>
  )
}

export default PropertyListingAgent
