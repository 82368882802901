import React from 'react'

import starBlue from 'app/assets/icons/star-blue.svg'
import starGrey from 'app/assets/icons/star-grey.svg'

import 'app/match/rental_reference/display/renter_address_history_detailed/rental_reference_detailed/rental_reference_result/rental_reference_result.scss'

function ReferenceResultComponent(props) {
  const {
    rating = '',
    description = '',
    isStarRating = false,
    isBooleanRating = false,
    isTextRating = false,
    isTextBlock = false,
    exception = false,
    textAnswer = '',
    boolRatingValue = '',
    titleComponent = '',
    containerClass,
    textBlockContainerClass,
    isNoAnswers = false,
    isDetailAnswers = false,
  } = props

  const ratingArray = Array.from({ length: 5 }, (x, i) => {
    if (i < rating) return true
    if (i >= rating) return false
    return false
  })
  return (
    <div className={`reference-item-container ${containerClass}`}>
      {!isNoAnswers ? (
        <>
          {!isTextBlock ? (
            <div
              className={`ml0 star-rating-container justify-content-space-between ${textBlockContainerClass}`}
            >
              <div className="col-first mr5">
                <p className={`mb0 ${titleComponent}`}>{description}</p>
              </div>
              <div className="col-second">
                {isStarRating &&
                  ratingArray.map((ratingItem, index) =>
                    ratingItem === true ? (
                      <img
                        key={index}
                        className="reference-rating-star-pic"
                        src={starBlue}
                        alt="Blue Star"
                      />
                    ) : (
                      <img
                        key={index}
                        className="reference-rating-star-pic"
                        src={starGrey}
                        alt="Grey Star"
                      />
                    ),
                  )}
                {isBooleanRating && (
                  <div
                    className={`bool-rating-verified ${
                      exception ? 'Info' : 'Verified'
                    }`}
                  >
                    {boolRatingValue}
                  </div>
                )}
                {isTextRating && (
                  <p className="mb0 rating-text">{textAnswer}</p>
                )}
              </div>
            </div>
          ) : (
            <div>
              <p className="mb0">{description}</p>
              <p className="grey-text">{textAnswer}</p>
            </div>
          )}
        </>
      ) : (
        <div>
          <p className="mb0">{description}</p>
          <p className="grey-text">No answer</p>
        </div>
      )}
    </div>
  )
}

export default ReferenceResultComponent
