import React from 'react'

import moment from 'moment'

import { history } from 'app/shared_components/router'

// const ApplicationItem = ({ application, action, openModal }) => (
class ApplicationItem extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { application, action, openModal } = this.props
    const address = application.currentLease.address
    const requestId = application.request.guidID
    const addr = address.unitNumber
      ? address.unitNumber +
        '/' +
        address.streetNumber +
        ' ' +
        address.streetName +
        ' ' +
        address.suburb
      : address.streetNumber + ' ' + address.streetName + ' ' + address.suburb
    const friendlyAddr = address.friendlyName
    const primary = !!application.tenants
      ? application.tenants.find((tenant) => tenant.isPrimary === true)
      : { firstName: 'N/A' }
    const {
      submissionStatus,
      managerAction,
      exitStatus,
      SecondaryTenantsApproved,
    } = application.request
    const { isMultiTenantLease } = application.currentLease

    let status = ''
    let operationStatus = ''

    let endDate =
      moment(application.currentLease.endDate).format('DD-MMM-YYYY') || ''
    endDate = endDate === 'Invalid date' ? 'N/A' : endDate
    const waitingForAction =
      !exitStatus &&
      submissionStatus !== 0 &&
      managerAction === 0 &&
      (!isMultiTenantLease || (isMultiTenantLease && SecondaryTenantsApproved))
    if (waitingForAction) {
      status = 'Action'
    } else if (managerAction === 2) {
      status = 'Declined'
    } else if (exitStatus === 1) {
      status = 'Issued'
    } else if (exitStatus === 4) {
      status = 'Withdrawn'
    } else {
      status = 'Pending'
    }

    return (
      <li className="agency-application-container">
        <div className="content-wrapper">
          <div className="meta">
            <div className="meta-wrapper">
              <div className="info pl0 width100">
                <div className="text text-prototype pl0 text-center flex-direction-row min-width-0">
                  <span>Status :</span>
                  <div>{status}</div>
                </div>
                <div className="text text-prototype pl0 text-center flex-direction-row min-width-0">
                  <span>Address:</span> {friendlyAddr}
                </div>
                <div className="text text-prototype pl0 text-center flex-direction-row min-width-0">
                  <span>Tenants:</span> {primary.firstName} (
                  {!!application.tenants ? application.tenants.length : '0'})
                </div>
                <div className="text text-prototype pl0 text-center flex-direction-row min-width-0">
                  <span>Bond :</span>${application.currentLease.bond}
                </div>
                <div className="text text-prototype pl0 text-center flex-direction-row min-width-0">
                  <span>Expires:</span> {endDate ? endDate : '—'}
                </div>
              </div>
            </div>
          </div>
          <div className="controls">
            {status === 'Action' && (
              <div className="ad-wrapper">
                <button
                  title="Decline"
                  className={`btn-decline`}
                  onClick={() =>
                    history.push(`/agency/bc/decline?requestid=${requestId}`)
                  }
                >
                  <span>Decline</span>
                </button>
                <button
                  title="Offer"
                  className={`btn-offer`}
                  onClick={() =>
                    history.push(`/agency/bc/review?requestid=${requestId}`)
                  }
                >
                  <span>Approve</span>
                </button>
              </div>
            )}
            {(status === 'Declined' ||
              status === 'Issued' ||
              status === 'Withdrawn' ||
              status === 'Pending') && (
              <div className="sm-agency-dashboard-space-padding"></div>
            )}
            {/* { (status === "Active" || status === "Expired") &&
                <div className="ad-wrapper">
                  <button title="Decline" className={`btn-decline`} onClick={ (type, key) => action("Claim", application.key) }>
                    <span>Claim</span>
                  </button>
                  <button title="Offer" className={`btn-offer`} onClick={ (key) => openModal(application.key) } >
                    <span>Extend</span>
                  </button>
                </div>
            } */}
            {/* { application.status === "Claim" &&
              <div className="ad-wrapper">
                <button title="Decline" className={`btn-decline`} onClick={ (type, key) => action("Withdrawn", application.key) }>
                  <span>Withdraw</span>
                </button>
                <button title="Offer" className={`btn-offer`} onClick={ (type, key) => action("Completed Claim", application.key) } >
                  <span>Complete</span>
                </button>
              </div>
            } */}
          </div>
        </div>
      </li>
    )
  }
}

export default ApplicationItem
