import { connect } from 'react-redux'

import {
  addTenant,
  deleteTenant,
  fetchTenants,
  updateTenant,
} from 'app/bond_cover/bond_cover_actions'
import TenantsFormContainer from 'app/bond_cover/renters/tenants/tenants_form_container'
import { setBackUrl } from 'app/shared_components/actions'

const mapStateToProps = ({ session, bondCover }) => ({
  apiError: bondCover.error,
  currentLease: bondCover.currentLease,
  tenants: bondCover.tenants,
})

const mapDispatchToProps = (dispatch) => ({
  addTenant: (requestID, tenant) => addTenant(requestID, tenant),
  fetchTenants: (requestID) => fetchTenants(requestID),
  updateTenant: (guidID, tenant) => updateTenant(guidID, tenant),
  deleteTenant: (requestID, guidID) => deleteTenant(requestID, guidID),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TenantsFormContainer)
