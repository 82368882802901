import React, { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { OutsideControlledDropdown } from 'app/components/design-system-components/Dropdown'
import { Input } from 'app/components/design-system-components/inputs/Input/Input'
import { SelectOptions } from 'app/components/design-system-components/inputs/Select/Select'
import { CustomInputWrapper } from 'app/forms/CustomInputWrapper'

export const Autocomplete = ({
  onChangeText,
  isLoading,
  options,
  OptionRenderCmp,
  HintMessageCmp,
  placeholder,
  changeHandler,
  blurHandler,
  value,
  touched,
  error,
  onEnterPressed = undefined,
  icon,
  NoOptionsCmp,
  optionsHeight,
  disabled = false,
}) => {
  const [isInputActive, setIsInputActive] = useState(false)
  const [isOptionsOpened, setIsOptionsOpened] = useState(false)
  const [inputText, setInputText] = useState('')

  useEffect(() => {
    const openOptionsDropdown = !!isInputActive && !isLoading
    setIsOptionsOpened(openOptionsDropdown)
  }, [isLoading, isInputActive])

  useEffect(() => {
    setInputText(extractDisplayTextIfExist())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (!isInputActive) {
      if (value) {
        setInputText(extractDisplayTextIfExist())
      } else {
        setInputText('')
      }

      inputRef.current.blur()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputActive])

  const extractDisplayTextIfExist = () =>
    value ? value?.optionMeta?.displayText : ''

  const shownError = touched && error

  const onSelectOption = (optionConfig) => {
    changeHandler(optionConfig)
    setIsInputActive(false)
  }

  const onInputFocus = (e) => {
    setIsInputActive(true)
  }

  const onInputBlur = (e) => {
    setIsInputActive(false)
    blurHandler()
  }

  const onTextInputChange = ({ target: { value: searchText } }) => {
    if (!searchText) {
      changeHandler(null)
    }
    setInputText(searchText)
    onChangeText(searchText)
  }

  const onKeyDownInput = (event) => {
    const { key } = event
    if (key === 'Enter' && onEnterPressed) {
      onEnterPressed(event)
      setIsInputActive(false)
    }
  }

  const inputRef = useRef(null)

  return (
    <OutsideControlledDropdown
      onBlur={onInputBlur}
      onFocus={onInputFocus}
      isOpened={isOptionsOpened}
      triggerWrapperComponent={() => (
        <Input
          icon={icon}
          isLoading={isLoading}
          error={shownError}
          inputProps={{
            placeholder,
            onChange: onTextInputChange,
            value: inputText,
            ref: inputRef,
            onKeyPress: onKeyDownInput,
            disabled,
          }}
        />
      )}
      dropdownComponent={() => (
        <SelectOptions
          options={options}
          selectedOption={value}
          selectOption={onSelectOption}
          OptionRenderCmp={OptionRenderCmp}
          HintMessageCmp={HintMessageCmp}
          NoOptionsCmp={NoOptionsCmp}
          optionsHeight={optionsHeight}
          searchText={inputText}
        />
      )}
      useWrapperWidth
      width="100%"
    />
  )
}

export const AutocompleteField = (props) => {
  const { name } = props
  return (
    <CustomInputWrapper
      name={name}
      customInputCmp={(customInputProps) => (
        <Autocomplete {...customInputProps} {...props} />
      )}
    />
  )
}

export const OptionPropType = PropTypes.shape({
  optionMeta: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayText: PropTypes.string.isRequired,
  }),
  option: PropTypes.any,
})

Autocomplete.propTypes = {
  onChangeText: PropTypes.func,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(OptionPropType),
  OptionRenderComponent: PropTypes.func,
}

AutocompleteField.propTypes = {
  ...Autocomplete.propTypes,

  name: PropTypes.string,
}
