import { api } from 'app/utils/api/helpers'

export const getPropertyBuilding = (teamGUID, propertyGUID) => {
  return api.get(`/sm/teams/${teamGUID}/property/${propertyGUID}/building`)
}

export const removePropertyBuilding = (teamGUID, propertyGUID) => {
  return api.delete(`/sm/teams/${teamGUID}/property/${propertyGUID}/building`)
}

export const assignPropertyBuilding = (
  teamGUID,
  propertyGUID,
  buildingGUID,
) => {
  return api.post(
    `/sm/teams/${teamGUID}/property/${propertyGUID}/building/${buildingGUID}`,
  )
}

export const listTeamBuildings = (teamGUID) => {
  return api.get(`/sm/teams/${teamGUID}/buildings`)
}

export const addTeamBuilding = (teamGUID, buildingName) => {
  return api.post(`/sm/teams/${teamGUID}/building`, { name: buildingName })
}

export const removeTeamBuilding = (teamGUID, buildingGUID) => {
  return api.delete(`/sm/teams/${teamGUID}/building/${buildingGUID}`)
}
