import React from 'react'

import FlexContainer from 'app/sm/progress_application/components/flex-container/component'
import HeaderLg from 'app/sm/progress_application/components/header_lg/component'
import SectionContainer from 'app/sm/progress_application/components/section_container/component'

const MarkAsLeased = ({ onMarkAsLeasedClicked, disableConfirmBtn = false }) => {
  return (
    <SectionContainer>
      <div className="mb5">
        <FlexContainer>
          <HeaderLg titleClass="title-spacing-mobile" title="Mark as leased" />
        </FlexContainer>
        <p>
          Marking as leased will:
          <ul>
            <li>Label the property as leased</li>
            <li>Stop the property receiving applications</li>
            <li>Not send an offer email</li>
            <li>Send the utility referral if not already sent</li>
          </ul>
        </p>
        <div className="col-md-3 p0">
          <button
            disabled={disableConfirmBtn}
            onClick={onMarkAsLeasedClicked}
            className="btn float-right hollow-button-green p0 text-align-center mb5"
          >
            Mark as leased
            {disableConfirmBtn && (
              <i
                className={disableConfirmBtn ? 'fa fa-spinner fa-pulse' : ''}
              />
            )}
          </button>
        </div>
      </div>
    </SectionContainer>
  )
}

export default MarkAsLeased
