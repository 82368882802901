import React from 'react'

import * as Display from 'app/components/display/display'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import { checkIfBGCheckNotSupportedInState } from 'app/utils/address/helpers'
import * as stringHelpers from 'app/utils/strings/helpers'

const BG_CHECK_LABEL = 'Background Check (optional)'
const DRIVERS_LICENSE_LABEL = 'Driver licence'
const PASSPORT_LABEL = 'Passport'

const defaultFormInfoData = {
  employment: [
    { name: 'Employment Contract', value: false },
    { name: 'Letter from Employer', value: false },
    { name: 'Letter from Accountant', value: false },
    { name: 'Most recent bank statement showing savings', value: false },
    { name: 'Most recent payslips', value: false },
    { name: 'Notice of Assessment (ATO)', value: false },
    { name: 'Update Employment details', value: false },
    {
      name: 'Bank statements (last 3 months savings and everyday account)',
      value: false,
    },
    { name: 'Payslips (last 6 weeks)', value: false },
    { name: 'Centrelink PAYG Summary', value: false },
    { name: 'Centrelink Income Statement', value: false },
  ],
  identification: [
    { name: BG_CHECK_LABEL, value: false },
    { name: DRIVERS_LICENSE_LABEL, value: false },
    { name: 'Medicare card', value: false },
    { name: PASSPORT_LABEL, value: false },
    { name: 'Proof of age card', value: false },
    { name: 'Visa Entitlement Verification Online (VEVO)', value: false },
    { name: 'Citizenship / Visa / PR', value: false },
    { name: 'Birth certificate', value: false },
  ],
  rentalHistory: [
    { name: 'Proof of ownership', value: false },
    { name: 'Rates notice', value: false },
    { name: 'Rental payment ledger', value: false },
    { name: 'Utility bill with home address', value: false },
  ],
  other: [
    { name: 'Proof of education sponsorship or scholarship', value: false },
    {
      name: 'Verification of Aboriginal and Torres Strait Islander',
      value: false,
    },
  ],
  comment: false,
  message: '',
}

const COMMENT = 'comment'
const MESSAGE = 'message'

const defaultSelectAllOptions = {
  employment: false,
  identification: false,
  rentalHistory: false,
  other: false,
}

class RequestInfoModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formInfo: {
        ...defaultFormInfoData,
      },
      sendClicked: false,
      optionTitle: [
        'Employment / Income',
        'Identification',
        'Address History',
        'Other',
      ],
      expandedOptionContainer: [0, 1, 2, 3],
      selectAllOptions: {
        ...defaultSelectAllOptions,
      },
      numSelectedOptions: 0,
    }

    this.employmentSection = null
    this.identificationSection = null
    this.addressHistorySection = null
    this.otherSection = null
  }

  componentDidMount() {
    const {
      isBackgroundChecked,
      requestInfoFromTica,
      applicationPropertyState,
    } = this.props

    if (!isBackgroundChecked) {
      this.setState({
        formInfo: {
          ...this.state.formInfo,
          identification: this.state.formInfo.identification.map((val) =>
            val.name === BG_CHECK_LABEL ? { ...val, value: true } : val,
          ),
        },
      })
    }
    if (requestInfoFromTica) {
      this.clearModalFormInfoData()
    }

    if (checkIfBGCheckNotSupportedInState(applicationPropertyState)) {
      this.setState({
        formInfo: {
          ...this.state.formInfo,
          identification: this.state.formInfo.identification.map((val) =>
            val.name === BG_CHECK_LABEL ? { ...val, value: false } : val,
          ),
        },
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ sendClicked: false })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      corrRequestInfoModalSection,
      requestForRequestInfoSent,
      updateRequestForRequestInfoSent,
      requestInfoFromTica,
      applicationPropertyState,
    } = this.props
    if (prevProps.corrRequestInfoModalSection !== corrRequestInfoModalSection) {
      switch (corrRequestInfoModalSection) {
        case 'Address History': {
          this.addressHistorySection &&
            this.addressHistorySection.scrollIntoView({ behavior: 'smooth' })
          break
        }
        case 'Employment': {
          this.employmentSection &&
            this.employmentSection.scrollIntoView({ behavior: 'smooth' })
          break
        }
        case 'Identification': {
          this.identificationSection &&
            this.identificationSection.scrollIntoView({ behavior: 'smooth' })
          break
        }
        case 'Other': {
          this.otherSection &&
            this.otherSection.scrollIntoView({ behavior: 'smooth' })
          break
        }
        default: {
          break
        }
      }
    }
    if (
      prevProps.requestForRequestInfoSent !== requestForRequestInfoSent &&
      requestForRequestInfoSent === true
    ) {
      this.clearModalFormInfoData()
      updateRequestForRequestInfoSent(false)
    }
    if (
      prevProps.requestInfoFromTica !== requestInfoFromTica &&
      requestInfoFromTica === true
    ) {
      this.clearModalFormInfoData()
      this.setState({
        formInfo: {
          ...this.state.formInfo,
          identification: this.state.formInfo.identification.map((val) =>
            val.name === DRIVERS_LICENSE_LABEL || val.name === PASSPORT_LABEL
              ? { ...val, value: true }
              : val.name === BG_CHECK_LABEL
              ? { ...val, value: false }
              : val,
          ),
        },
      })
    }
    if (
      prevProps.applicationPropertyState !== applicationPropertyState &&
      checkIfBGCheckNotSupportedInState(applicationPropertyState)
    ) {
      this.setState({
        formInfo: {
          ...this.state.formInfo,
          identification: this.state.formInfo.identification.map((val) =>
            val.name === BG_CHECK_LABEL ? { ...val, value: false } : val,
          ),
        },
      })
    }
  }

  onFieldUpdated = (field, content) => {
    const {
      selectAllOptions,
      formInfo,
      formInfo: { employment, rentalHistory, identification, comment, message },
    } = this.state
    let newFormInfo = ''
    switch (field) {
      case 'comment':
        this.myTextarea.focus()
        newFormInfo = Object.assign({}, this.state.formInfo, {
          comment: !comment,
        })
        this.setState({ formInfo: newFormInfo })
        return
      case 'message':
        newFormInfo = Object.assign({}, this.state.formInfo, {
          message: content,
        })
        this.setState({ formInfo: newFormInfo })
        return
    }
    const relavantOption = formInfo[field].map((item, index) => {
      const value = !item.value
      return index === content ? { ...item, value } : { ...item }
    })
    newFormInfo = Object.assign({}, this.state.formInfo, {
      [field]: relavantOption,
    })
    this.setState({ formInfo: newFormInfo })
    const isAllSelected = !newFormInfo[field].filter(
      (option) => option.value === false,
    ).length
    const newSelectAllOptions = {
      ...this.state.selectAllOptions,
      [field]: isAllSelected,
    }
    this.setState({ selectAllOptions: newSelectAllOptions }, () =>
      this.calculateNumOptionsSelected(),
    )
  }

  onOptionTitleClicked = (nth) => {
    const { expandedOptionContainer } = this.state
    if (expandedOptionContainer.includes(nth)) {
      var index = expandedOptionContainer.indexOf(nth)
      if (index !== -1) expandedOptionContainer.splice(index, 1)
    } else {
      expandedOptionContainer.push(nth)
    }

    this.setState({
      expandedOptionContainer: expandedOptionContainer,
    })
  }

  onSelectAllClicked = (field) => {
    const { selectAllOptions, formInfo } = this.state
    const isSelectAll = !selectAllOptions[field]
    const newSelectAllOptions = Object.assign({}, this.state.selectAllOptions, {
      [field]: isSelectAll,
    })
    const relavantOption = formInfo[field].map((item, index) => ({
      ...item,
      value: isSelectAll,
    }))
    const newFormInfo = Object.assign({}, this.state.formInfo, {
      [field]: relavantOption,
    })
    this.setState(
      { selectAllOptions: newSelectAllOptions, formInfo: newFormInfo },
      () => this.calculateNumOptionsSelected(),
    )
  }

  calculateNumOptionsSelected = () => {
    const { formInfo } = this.state
    const { updateNumReqInfoItems } = this.props
    const allFormInfoKeys = Object.keys(formInfo)
    let numFieldsUpdated = 0
    let allInfoRequested = []
    allFormInfoKeys.forEach((info) => {
      if (info === COMMENT || info === MESSAGE) {
        if (info === COMMENT) return
        if (formInfo[MESSAGE].length) {
          let messageObject = {}
          messageObject.name = formInfo[MESSAGE]
          messageObject.value = true
          allInfoRequested = allInfoRequested.concat(messageObject)
        }
      } else {
        allInfoRequested = allInfoRequested.concat(
          formInfo[info].filter((opt) => opt.value),
        )
      }
      numFieldsUpdated = allInfoRequested.length
    })
    this.setState({
      numSelectedOptions: numFieldsUpdated,
    })
    updateNumReqInfoItems(
      numFieldsUpdated,
      allInfoRequested,
      Object.assign({}, formInfo, { fullApplication: false }),
    )
  }

  clearModalFormInfoData = () => {
    this.setState({
      formInfo: defaultFormInfoData,
      selectAllOptions: defaultSelectAllOptions,
    })
  }

  contentChanged = ({ target: { value: content } }) => {
    this.setState({
      content,
    })
  }

  render() {
    const {
      selectAllOptions,
      content,
      formInfo,
      formInfo: {
        employment,
        rentalHistory,
        identification,
        other,
        comment,
        message,
      },
      sendClicked,
      optionTitle,
      expandedOptionContainer,
      numSelectedOptions,
    } = this.state
    const {
      title,
      toggleModal,
      requestRenterInformation,
      lastName,
      firstName,
      error,
      showRequestInfoModal = false,
      isFromApplicationDetailsPageManager = false,
      applicationPropertyState,
    } = this.props

    const anyEmploymentBoxTicked = employment.find(
      (item, index) => item.value === true,
    )
    const anyRentalHistoryBoxTicked = rentalHistory.find(
      (item, index) => item.value === true,
    )
    const anyIdentificationBoxTicked = identification.find(
      (item, index) => item.value === true,
    )
    const anyOtherBoxTicked = other.find((item, index) => item.value === true)

    const passValidation =
      ((!!anyEmploymentBoxTicked ||
        !!anyRentalHistoryBoxTicked ||
        !!anyIdentificationBoxTicked ||
        !!anyOtherBoxTicked) &&
        ((!!comment && message) || !comment)) ||
      (!!comment && message)

    const OptionItem = ({ option, index, formInfo, nth }) => (
      <div>
        <label className="mb15">
          <input
            type="checkbox"
            name="manager"
            value="1"
            checked={option.value}
            onChange={() =>
              this.onFieldUpdated(Object.keys(formInfo)[nth], index)
            }
          />
          <span>{option.name}</span>
        </label>
      </div>
    )

    const OptionTitle = ({
      arrowIcon,
      nth,
      item,
      selectAllCheck,
      selectAllOptions,
    }) => (
      <div
        className="option-title-container mb10-desktop"
        ref={(section) => {
          this[`${stringHelpers.camelize(item)}Section`] = section
        }}
      >
        <div
          className="display-flex"
          onClick={() => this.onOptionTitleClicked(nth)}
        >
          {arrowIcon}
          <h5>{item}</h5>
        </div>

        <label className="mb15 pt3">
          <input
            type="checkbox"
            name="manager"
            value="1"
            checked={selectAllCheck}
            onChange={() =>
              this.onSelectAllClicked(Object.keys(selectAllOptions)[nth])
            }
          />
          <span>All</span>
        </label>
      </div>
    )

    return (
      <div>
        {showRequestInfoModal && (
          <div className="modal-fullscreen">
            <ModalContentWrapper>
              <div className="modal-close">
                <i className="fa fa-times" onClick={() => toggleModal()} />
              </div>
              <div className="modal-header width-100">
                <h4 className="pl10 pr10">
                  {title} from {firstName} {lastName}
                </h4>
              </div>

              <div className="modal-body request-info-modal-container">
                <div>
                  {optionTitle.map((item, nth) => {
                    const selectAllCheck =
                      selectAllOptions[Object.keys(selectAllOptions)[nth]]
                    {
                      /* const partialClassName = nth !== 2 ? "options-container" : "options-container only-one-option" */
                    }
                    const partialClassName =
                      Object.values(formInfo)[nth].length === 1
                        ? 'options-container only-one-option'
                        : 'options-container'

                    const finalContainerClassName =
                      expandedOptionContainer.includes(nth)
                        ? partialClassName + ' expanded'
                        : partialClassName
                    const arrowIcon = expandedOptionContainer.includes(nth) ? (
                      <i className="icon-arrow-up arrow-container"></i>
                    ) : (
                      <i className="icon-arrow-down arrow-container"></i>
                    )
                    return (
                      <div key={item} className="mb20-desktop">
                        <OptionTitle
                          nth={nth}
                          item={item}
                          arrowIcon={arrowIcon}
                          selectAllCheck={selectAllCheck}
                          selectAllOptions={selectAllOptions}
                        />
                        <div className={finalContainerClassName}>
                          {formInfo[Object.keys(formInfo)[nth]].map(
                            (option, index) => {
                              return option.name === BG_CHECK_LABEL &&
                                checkIfBGCheckNotSupportedInState(
                                  applicationPropertyState,
                                ) ? null : (
                                <OptionItem
                                  key={option + ' ' + index}
                                  option={option}
                                  index={index}
                                  formInfo={formInfo}
                                  nth={nth}
                                />
                              )
                            },
                          )}
                        </div>
                      </div>
                    )
                  })}
                  <div className="bottom-comment-container">
                    <label className="mb20-desktop mt10">
                      <input
                        type="checkbox"
                        name="manager"
                        value="5"
                        checked={comment}
                        onClick={() => {
                          this.onFieldUpdated('comment')
                        }}
                      />
                      <span>Request other/include a comment</span>
                    </label>
                  </div>
                </div>

                <div className={comment ? 'input-box' : 'hide-input-box'}>
                  <textarea
                    ref={(input) => (this.myTextarea = input)}
                    className="textarea no-resize border-0 pt0"
                    value={message}
                    rows="3"
                    onChange={(event) =>
                      this.onFieldUpdated('message', event.target.value)
                    }
                    placeholder="Type your message"
                  />
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
              </div>
              <div className="row ml0 mr0 display-flex modal-bottom-btn-container modal-bottom-btn-container-walk-in-renter">
                <Display.GenericButton
                  text="Cancel"
                  onClick={() => toggleModal()}
                  componentClass={`col-sm-6 div-order-1 width100`}
                  buttonType="secondary"
                />
                {isFromApplicationDetailsPageManager ? (
                  <Display.GenericButton
                    text="Save"
                    disabled={!passValidation}
                    onClick={() => {
                      this.calculateNumOptionsSelected()
                      toggleModal()
                    }}
                    componentClass={`col-sm-6 div-order-2 width100`}
                  />
                ) : (
                  <Display.GenericButton
                    text={title}
                    disabled={!passValidation}
                    onClick={() => {
                      this.setState({ sendClicked: true })
                      this.props.requestRenterInformation(
                        Object.assign({}, formInfo, { fullApplication: false }),
                      )
                    }}
                    componentClass={`col-sm-6 div-order-2 width100`}
                  />
                )}
              </div>
            </ModalContentWrapper>
          </div>
        )}
      </div>
    )
  }
}

export default RequestInfoModal
