import React, { useState } from 'react'

import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import { ButtonWithIcon } from 'app/components/design-system-components/Button'
import { OutsideControlledDropdown } from 'app/components/design-system-components/Dropdown'
import { ArrowDropDownRounded } from 'app/components/design-system-components/icons/navigation'
import TextSpan from 'app/components/design-system-components/TextSpan'
import * as getters from 'app/components/design-system-components/theme/getters'
import { CustomInputWrapper } from 'app/forms/CustomInputWrapper'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const SimpleOptionContainer = styled(Box)`
  padding: ${getters.spacing(3)};
  border-radius: ${getters.radius(6)}px;

  font-size: ${theme.fontSizes.pRegular14};
  line-height: 140%;

  &.selected {
    background-color: ${getters.color('paleBlue')};
  }

  &:hover {
    background-color: ${getters.color('paleBlue')};
    cursor: pointer;
  }
`

export const ResultsLabelHeaderRender = ({ label = 'RESULTS' }) => {
  return <Box className="results-label">{label}</Box>
}

export const SimpleOptionRenderer = ({
  option,
  onClick,
  selected,
  ...props
}) => {
  let displayText = ''
  if (option && option.optionMeta) {
    displayText = option.optionMeta.displayText
  }

  return (
    <SimpleOptionContainer
      onClick={onClick}
      className={selected && 'selected'}
      role="option"
      {...props}
    >
      {displayText}
    </SimpleOptionContainer>
  )
}

export const SelectOptionsContainer = styled(Box)`
  border: 1px solid ${getters.color('gray200')};
  border-radius: ${getters.radius(6)}px;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.1));
  background-color: ${getters.color('white')};
  padding: ${getters.spacing(3)};

  .results-label {
    text-transform: uppercase;
    color: ${getters.color('gray800')};
    font-size: ${theme.fontSizes.pExtraSmall10};
    font-weight: ${getters.fontWeight(6)};

    padding: ${getters.spacing(3)};
  }

  max-height: ${(props) => props.optionsHeight || 'unset'};
  overflow: auto;
`

export const DefaultNoOptionsCmp = () => (
  <TextSpan
    m={theme.space[3] + 'px'}
    fontSize={theme.fontSizes.pRegular14}
    color={theme.colors.gray700}
  >
    No results to show
  </TextSpan>
)

export const SelectOptions = ({
  selectedOption,
  options = [],
  selectOption,
  OptionRenderCmp = SimpleOptionRenderer,
  HeaderRenderCmp = ResultsLabelHeaderRender,
  HintMessageCmp,
  resultsLabel = 'RESULTS',
  NoOptionsCmp = DefaultNoOptionsCmp,
  searchText, // if Select is inside AutoComplete. Could be used in highlighting matched text and more.
  ...props
}) => {
  // to avoid blur event if the dropdown clicked
  const onMouseDown = (e) => e.preventDefault()

  const mappedOptions =
    options &&
    options.map((option) => {
      const id = option?.optionMeta?.id

      return {
        id,
        option,
        isSelected: selectedOption && selectedOption.optionMeta.id === id,
        onClick: (e) => selectOption(option),
      }
    })

  const hintMessageElem = !HintMessageCmp ? null : <HintMessageCmp />

  const noOptionsElem = !options || !options.length ? <NoOptionsCmp /> : null

  return (
    <SelectOptionsContainer onMouseDown={onMouseDown} {...props}>
      {HeaderRenderCmp && <HeaderRenderCmp label={resultsLabel} />}

      {noOptionsElem}

      {mappedOptions &&
        mappedOptions.map((mappedOption, index) => (
          <OptionRenderCmp
            key={mappedOption.id}
            selected={mappedOption.isSelected}
            isLast={index === mappedOptions.length - 1}
            searchText={searchText}
            {...mappedOption}
          />
        ))}

      {hintMessageElem}
    </SelectOptionsContainer>
  )
}

export const Select = ({
  options,
  OptionsHeaderRenderCmp,
  OptionRenderCmp,
  TriggerWrapperCmp,
  label,
  disabled,
  changeHandler,
  blurHandler,
  value,
  touched,
  error,
  resultsLabel,
  NoOptionsCmp,
  buttonVariant,
  triggerWrapperComponentProps,
  ...props
}) => {
  const [isOptionsOpened, setIsOptionsOpened] = useState(false)

  const onSelectOption = (optionConfig) => {
    changeHandler(optionConfig)
    setIsOptionsOpened(false)
    blurHandler && setTimeout(() => blurHandler())
  }

  const toggleDropdown = () => {
    setIsOptionsOpened(!isOptionsOpened)
  }

  const onBlur = () => {
    blurHandler && blurHandler()
    setIsOptionsOpened(false)
  }

  return (
    <OutsideControlledDropdown
      onBlur={onBlur}
      isOpened={isOptionsOpened}
      setIsOpened={setIsOptionsOpened}
      triggerWrapperComponent={
        TriggerWrapperCmp
          ? TriggerWrapperCmp(toggleDropdown)
          : () => (
              <ButtonWithIcon
                type="button"
                IconCmp={ArrowDropDownRounded}
                onClick={toggleDropdown}
                leading={false}
                variant="outline"
                width="100%"
                containerProps={{ justifyContent: 'space-between' }}
                {...triggerWrapperComponentProps}
              >
                {!!OptionRenderCmp && !!value ? (
                  <OptionRenderCmp
                    key={value.optionMeta.id}
                    option={value}
                    selected={false}
                    onClick={() => onSelectOption(value)}
                  />
                ) : (
                  value?.optionMeta?.displayText || label
                )}
              </ButtonWithIcon>
            )
      }
      dropdownComponent={() =>
        disabled ? (
          <></>
        ) : (
          <SelectOptions
            options={options}
            selectedOption={value}
            selectOption={onSelectOption}
            OptionRenderCmp={OptionRenderCmp}
            HeaderRenderCmp={OptionsHeaderRenderCmp}
            resultsLabel={resultsLabel}
            NoOptionsCmp={NoOptionsCmp}
            {...props}
          />
        )
      }
      useWrapperWidth
      {...props}
    />
  )
}

export const SelectField = (props) => {
  const { name } = props
  return (
    <CustomInputWrapper
      name={name}
      customInputCmp={(customInputProps) => (
        <Select {...customInputProps} {...props} />
      )}
    />
  )
}
