import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getBackgroundCheckDetails = ({
  id,
  userID,
  token,
  applicationId = '',
} = {}) => {
  const path = id ? `/${id}` : userID ? `/user/${userID}` : ''
  const url = `${apiBaseUrl}sm/backgroundcheck/details${path}?applicationId=${applicationId}`
  return fetch(url, {
    credentials: 'include',
    headers: token && {
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (details) => ({ ok: true, details }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getApplicationApplicantBackgroundCheckDetails = (
  applicationGUID,
  applicantGUID,
) => {
  const url = `${apiBaseUrl}sm/applications/${applicationGUID}/applicant/${applicantGUID}/backgroundcheck`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (details) => ({ ok: true, details }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
