import React from 'react'

import stoolflipped from 'app/assets/icons/stool-flipped.png'
import { Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const SystemMaintenancePage = ({}) => {
  return (
    <Flex alignItems="center" justifyContent="center" p={theme.space[5] + 'px'}>
      <Flex flexWrap="wrap" className="hero-error">
        <div>
          <div className="hero_text mt10">
            <img src="https://s3-ap-southeast-2.amazonaws.com/snugco/assets/snug-com-logo.png" />
            <h2 className="error_h2 text-center-sm mt200">
              Sorry we're down for maintenance
              <br />
              We'll be back soon
            </h2>
            <div data-aos="fade-right">
              <div className="mt20">
                <div className="mt20">
                  <p className="error_text">Here are some useful links:</p>
                  <ul className="list-unstyled">
                    <li>
                      <a href="https://help.snug.com/hc/en-us">Help Center</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img src={stoolflipped} />
        </div>
      </Flex>
    </Flex>
  )
}
