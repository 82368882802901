import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import MembersDropdown from 'app/components/display/edit_viewing_modal/members_dropdown'
import {
  defaultEditViewingModalValues,
  durationOptionsNumbers,
  durationOptionsText,
} from 'app/components/display/edit_viewing_modal/viewing_modal_data'
import * as Form from 'app/components/forms/forms'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { getAgencyMembers } from 'app/services/http/agency'
import * as snugNotifier from 'app/services/snugNotifier'
import * as FormComponent from 'app/shared_components/form_component'
import * as helpers from 'app/sm/helpers'
import { convertDateToDayAndDate } from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import {
  TIME_PAST_ERROR,
  validateViewingStartTime,
} from 'app/utils/viewings/helpers'

const PropertyViewingsContainer = styled.div`
  overflow-y: scroll;
  max-height: 250px;
  width: 100%;
  border: 1px solid;
  border-color: #d2caca;
  border-radius: 4px;
  padding-bottom: 20px;
  @media (max-width: 576px) {
    max-height: 90px;
  }
`

const PropertyListItem = ({ propertyViewing }) => {
  const { viewings = [] } = propertyViewing || {}
  const { day, dateWithoutYear } =
    propertyViewing.date && convertDateToDayAndDate(propertyViewing.date)
  const dateOfViewingsStart = `${day} ${dateWithoutYear}`
  const viewingListArray = []
  viewings.forEach((viewing, i) => {
    const summary = viewing.summary
    const viewingTime = moment(summary.dateTime).format(helpers.timeFormat)
    const viewingListItem = `${viewingTime}, ${summary.address}  (${summary.managerName})`
    viewingListArray.push(viewingListItem)
  })
  return (
    <div>
      <Display.StandardContactInfoContainer
        containerClass="mt30"
        mainTitle={dateOfViewingsStart}
        linesText={viewingListArray}
      />
    </div>
  )
}

class EditViewingModal extends React.Component {
  constructor(props) {
    super(props)
    const {
      startDate,
      startTime,
      duration,
      published = true,
      notifyShortlistedApplicants,
      inspectorsGUIDs = [],
    } = this.props
    this.state = {
      startDate: startDate || defaultEditViewingModalValues.startDate,
      startTime: startTime || defaultEditViewingModalValues.startTime,
      duration: isNaN(duration) ? 2 : duration,
      published: published,
      notify: true,
      notifyShortlistedApplicants,
      errors: {
        startDate: '',
        startTime: '',
        duration: '',
        notify: '',
        published: '',
      },
      propertyViewings: [],
      viewingSpinner: false,
      teamMembers: [],
      inspectorsGUIDs,
    }
  }

  componentDidMount() {
    const { teamGUID } = this.props
    if (teamGUID) {
      this.setState({ viewingSpinner: true })
      this.props
        .fetchAllPropertyFutureViewings(teamGUID)
        .then(({ data }) => {
          this.setState({
            propertyViewings: data,
            viewingSpinner: false,
          })
        })
        .catch((error) => {
          snugNotifier.error(error)
        })
    }

    const {
      defaultSelectedViewingDurationEnabled,
      defaultSelectedViewingDuration,
    } = this.props

    if (
      defaultSelectedViewingDurationEnabled &&
      durationOptionsNumbers.indexOf(defaultSelectedViewingDuration) !== -1
    ) {
      this.setState({
        duration: durationOptionsNumbers.indexOf(
          defaultSelectedViewingDuration,
        ),
      })
    }

    getAgencyMembers(teamGUID).then((members) => {
      this.setState({
        teamMembers: members,
      })
    })
  }

  onChangeRadioGroup = (radioGroupID) => {
    return (event) => {
      const { id } = event.target || {}
      this.setState({
        [radioGroupID]: id !== 'false',
        errors: { ...this.state.errors, [radioGroupID]: '' },
      })
      if (id === 'false') {
        this.setState({
          notify: false,
        })
      } else {
        this.setState({
          notify: true,
        })
      }
    }
  }

  handlePrimaryAction = () => {
    const {
      startDate,
      startTime,
      duration,
      published,
      notify,
      notifyShortlistedApplicants,
      inspectorsGUIDs,
    } = this.state
    const { primaryButtonActionInParent } = this.props
    if (this.state.errors.startTime === TIME_PAST_ERROR) {
      snugNotifier.error(
        'The viewing time must be within the past 24 hrs or later',
      )
      return
    }
    if (
      this.state.errors.startTime.length ||
      this.state.errors.startDate.length
    ) {
      snugNotifier.error('Please check the viewing time and date is valid')
      return
    }
    primaryButtonActionInParent({
      startDate,
      startTime,
      duration,
      published,
      notify,
      notifyShortlistedApplicants,
      inspectorsGUIDs,
    })
  }

  update = (field) => {
    const { errors } = this.state
    return (data) => {
      let value = data.value
      if (data.error === '' && field === 'startDate') {
        value =
          value !== ''
            ? moment(value).format(dateTimeHelpers.DATE_WITH_DASH)
            : ''
      }
      this.setState(
        {
          [field]: value,
          errors: {
            ...errors,
            [field]: data.error,
          },
        },
        () => {
          const { startTime, startDate, errors } = this.state
          if (field === 'startTime' || field === 'startDate') {
            const checkStartTimeError = validateViewingStartTime(
              moment(startDate, dateTimeHelpers.DATE_WITH_DASH).format(
                dateTimeHelpers.DATE_WITH_DASH,
              ),
              startTime,
              TIME_PAST_ERROR,
            )
            if (checkStartTimeError.length) {
              this.setState({
                ...this.state,
                errors: {
                  ...errors,
                  startTime: checkStartTimeError[0],
                },
              })
            } else {
              this.setState({
                ...this.state,
                errors: {
                  ...errors,
                  startTime: '',
                },
              })
            }
          }
        },
      )
    }
  }

  render() {
    const {
      title,
      primaryActionLabel,
      secondaryActionLabel,
      toggleModal,
      textToBeDisplayed = '',
      startTime,
      secondaryButtonAction,
      editing,
    } = this.props
    const {
      published,
      propertyViewings,
      viewingSpinner,
      teamMembers = [],
    } = this.state || {}
    const formattedStartDate =
      this.state.errors.startDate !== dateTimeHelpers.INVALID_DATE_STRING
        ? this.state.startDate &&
          moment(this.state.startDate).format(dateTimeHelpers.DATE_WITH_SLASH)
        : this.state.startDate
    const notifyLabel = editing ? 'Notify attendees' : 'Notify other enquirers'
    return (
      <Display.Modal
        toggleModal={toggleModal}
        title={title}
        primaryButtonLabel={primaryActionLabel}
        primaryButtonAction={this.handlePrimaryAction}
        secondaryButtonAction={secondaryButtonAction}
        secondaryButtonLabel={secondaryActionLabel}
        buttonsContainerClass="row ml0 mr0"
        primaryButtonClassName="col-sm-6"
        secondaryButtonClassName="col-sm-6"
        modalBodyClass="display-flex flex-direction-column"
      >
        <div className="display-flex flex-direction-column  p0 col-sm-12">
          <Display.DefaultBodyText
            componentClass="col-sm-12"
            text={textToBeDisplayed}
          />
          <div className="display-flex col-sm-12 p0 mobile-flex-direction-column schedule-viewing-forms mb15-mobile">
            <Form.InputFutureDateOneDayPast
              componentClass="col-sm-12 col-md-6 pl0"
              value={formattedStartDate}
              onChange={this.update('startDate')}
              error={this.state.errors.startDate}
              label="Date"
              containerClassName="width-auto"
              viewMode="days"
              customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
              readOnly={false}
            />
            <Form.TimePicker
              value={this.state.startTime}
              viewMode="time"
              onChange={this.update('startTime')}
              componentClass="col-sm-12 col-md-3 time-picker"
              label="Time"
              containerClassName="width-auto"
              error={this.state.errors.startTime}
              placeholder={startTime}
              defaultValue={startTime}
              isScheduleViewing={true}
            />
            <Form.Dropdown
              label={durationOptionsText[0]}
              value={this.state.duration}
              options={durationOptionsText}
              onChange={this.update('duration')}
              componentClass="col-sm-12 col-md-3 pr0"
              inputClass="width-auto"
              frameLabel="Duration"
              inputBoxClass="standard-input-box"
            />
          </div>
          <div className="display-flex mobile-flex-direction-column col-sm-12 p0">
            <FormComponent.RadioGroup
              label=""
              id="published"
              field="published"
              componentClassName="property-details-radio-group pr0"
            >
              <FormComponent.Checkbox
                id="true"
                label="Open home"
                checked={published === true}
                onChange={this.onChangeRadioGroup('published')}
                componentClassName={'mr10'}
              />
              <FormComponent.Checkbox
                id="false"
                label="Private appointment"
                checked={published === false}
                onChange={this.onChangeRadioGroup('published')}
                componentClassName={'mr0'}
              />
            </FormComponent.RadioGroup>
          </div>

          <div className="display-flex mobile-flex-direction-column col-sm-12 p0">
            <FormComponent.RadioGroup
              label=""
              id="published"
              field="published"
              componentClassName="property-details-radio-group pr0"
            >
              <Form.CheckBoxGeneral
                label={notifyLabel}
                labelClass="font-size-17 pr0"
                checked={this.state.notify}
                onChange={this.update('notify')}
                componentClassName="mr10"
              />

              <Form.CheckBoxGeneral
                label="Notify shortlisted applicants"
                labelClass="font-size-17 pr0"
                checked={this.state.notifyShortlistedApplicants}
                onChange={this.update('notifyShortlistedApplicants')}
              />
            </FormComponent.RadioGroup>
          </div>
          <Box mb="16px">
            {!editing && (
              <Flex
                alignItems="center"
                my={theme.space[1] + 'px'}
                pl={theme.space[5] + 'px'}
              >
                <Flex
                  mr={theme.space[4] + 'px'}
                  fontWeight={theme.fontWeights[4]}
                >
                  <span>Inspector:</span>
                </Flex>
                <MembersDropdown
                  teamMembers={teamMembers}
                  onMembersChanged={(guids) => {
                    this.setState({
                      inspectorsGUIDs: guids,
                    })
                  }}
                />
              </Flex>
            )}
          </Box>
          {propertyViewings.length > 0 && (
            <div className="display-flex mobile-flex-direction-column col-sm-12 p0">
              {viewingSpinner && <i className="fa fa-spinner fa-pulse" />}
              <PropertyViewingsContainer className="col-sm-12">
                {propertyViewings.map((viewing, index) => (
                  <PropertyListItem
                    key={`viewing` + index}
                    propertyViewing={viewing}
                  />
                ))}
              </PropertyViewingsContainer>
            </div>
          )}
        </div>
      </Display.Modal>
    )
  }
}

export default EditViewingModal
