export const entryNoticesStatus = {
  notApplicable: -1,
  unknown: 0,
  failed: 1,
  succeed: 2,
  pending: 3,
  teamDisabled: 4,
  alreadyVacant: 5,
}

export const entryNoticeMessageStatus = {
  opened: 'opened',
}

export const entryNoticesStatusesToShownReceipts = [
  entryNoticesStatus.failed,
  entryNoticesStatus.succeed,
]

export const contactType = {
  manager: 'manager',
  tenant: 'tenant',
}
