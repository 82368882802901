import { connect } from 'react-redux'

import { searchProperties } from 'app/bond_cover/agency/agency_actions'
import { fetchRenterDashboard } from 'app/dashboard/dashboard_actions'
import RenterDashboardContainer from 'app/dashboard/renter_dashboard_container'
import { setBackUrl } from 'app/shared_components/actions'
import { fetchApplications } from 'app/sm/applications/applications_actions'
import { fetchCompleteness } from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({
  session,
  dashboard,
  RentalReputation,
  MyApplications,
  MyProperties,
  Viewings,
}) => ({
  currentUser: session.currentUser,
  bondCovers: dashboard.bondCovers,
  invitesToApply: dashboard.invitesToApply,
  completeness: RentalReputation.completeness,
  applications: MyApplications.applications,
  properties: MyProperties.properties,
})

const mapDispatchToProps = (dispatch) => ({
  fetchRenterDashboard: () => dispatch(fetchRenterDashboard()),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
  fetchApplications: () => dispatch(fetchApplications()),
  setBackUrl: (backUrl) => dispatch(setBackUrl(backUrl)),
  searchProperties: (address, pageSize, searchSuccess) =>
    dispatch(searchProperties(address, pageSize, searchSuccess)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RenterDashboardContainer)
