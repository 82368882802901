import styled from 'styled-components'

import closeIcon from 'app/assets/icons/close_24px.svg'
import Box from 'app/components/design-system-components/Box'
import Flex from 'app/components/design-system-components/Flex'
import IconBox from 'app/components/design-system-components/IconBox'

const ChipContainer = styled(Box)`
  padding: ${({ theme }) => theme.space[3] + 'px'};
  border: ${({ theme }) => `1px solid ${theme.colors.blue}`};
  border-radius: ${({ theme }) => theme.radii[6] + 'px'};
  color: ${({ theme }) => theme.colors.gray600};
  width: fit-content;

  .icon {
    padding-left: ${({ theme }) => theme.space[3] + 'px'};
    &:hover {
      cursor: pointer;
    }
  }
`

export const Chip = ({
  showDismissBtn = false,
  onDismissClicked,
  children,
  ...props
}) => {
  return (
    <ChipContainer {...props}>
      <Flex alignItems="center">
        {children}
        {showDismissBtn && (
          <IconBox className="icon" onClick={onDismissClicked}>
            <img src={closeIcon} alt="dismiss" />
          </IconBox>
        )}
      </Flex>
    </ChipContainer>
  )
}
