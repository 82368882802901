import React from 'react'

import { Link } from 'react-router-dom'

import Button from 'app/components/buttons/default/component'
import ReferrerBackButton from 'app/components/display/referrerBackButton/component'
import { isReferrerDomainComAU } from 'app/constants/referrer.constants'
import { history } from 'app/shared_components/router'
import Gallery from 'app/sm/common/gallery'
import VideoGallery from 'app/sm/common/video_gallery'
import { NO_IMAGE_PLACEHOLDER_URL, urlTo } from 'app/sm/helpers'

export const ApplicationHeadReminder = ({
  property,
  backUrl,
  remindText,
  shouldDisplay,
}) => {
  const addressName = property.address.friendlyName
  return (
    <div>
      {shouldDisplay && (
        <div className="rental-notification display-flex">
          <button className="btn btn-transparent btn-left rental-header-mobile-layout width-auto padding-for-property-header">
            <span onClick={() => history.push(backUrl)}>
              {remindText || 'Recently Viewed'}
              <i className="icon-arrow-right" />
            </span>
          </button>
          <button className="btn btn-transparent btn-left rental-header-mobile-layout">
            <span>{addressName}</span>
          </button>
        </div>
      )}
    </div>
  )
}

class PropertyHeader extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const {
      property,
      addToSavedProperty,
      backUrl,
      remindText,
      propertyVideos,
      startVirtualTour,
      endVirtualTour,
      referrer,
      referrerBackUrl,
    } = this.props
    let address = ''
    let suburb = ''
    let propertyImage = NO_IMAGE_PLACEHOLDER_URL
    const shouldDisplay = backUrl.includes('apply/agencies/')
    if (property.address) {
      address = `${property.address.streetNumber} ${property.address.streetName}`
      suburb = `${property.address.suburb} ${property.address.state} ${property.address.postcode}`
    }
    if (property.images && property.images.length > 0) {
      propertyImage = (
        property.images.find((image) => image.isMain === true) ||
        property.images[0]
      ).URL
    }

    return (
      <div className="sm-property-details__property-header">
        <ApplicationHeadReminder
          property={property}
          backUrl={backUrl || '/'}
          shouldDisplay={shouldDisplay}
          remindText={remindText}
        />
        <div
          className="images"
          style={{ backgroundImage: `url(${propertyImage})` }}
        >
          {referrerBackUrl && referrer && !isReferrerDomainComAU(referrer) && (
            <ReferrerBackButton
              referrer={referrer}
              referrerBackUrl={referrerBackUrl}
            />
          )}
          <div className="controls-left">
            <button onClick={() => addToSavedProperty()}>
              <i
                className={
                  property.isFavourite
                    ? 'icon-heart-filled red-color'
                    : 'icon-heart-outline'
                }
              />
            </button>
          </div>
          {propertyVideos && propertyVideos.length > 0 && (
            <VideoGallery
              videos={(propertyVideos || []).map(({ URL }) => ({ src: URL }))}
              startVirtualTour={startVirtualTour}
            />
          )}
          <div className="controls-right">
            <Gallery
              images={(property.images || []).map(({ URL }) => ({ src: URL }))}
              startVirtualTour={startVirtualTour}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default PropertyHeader
