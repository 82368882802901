export const EXCLUDE_SCHEDULE_TYPE = 'SCHEDULE'
export const EXCLUDE_DATETIME_RANGE_TYPE = 'DATETIME_RANGE'
export const EXCLUDE_DATE_RANGE_TYPE = 'DATE_RANGE'

export const typesDisplayTexts = {
  [EXCLUDE_SCHEDULE_TYPE]: 'Schedule',
  [EXCLUDE_DATETIME_RANGE_TYPE]: 'Date & Time',
  [EXCLUDE_DATE_RANGE_TYPE]: 'Date',
}

export const allExcludeTypes = [
  EXCLUDE_SCHEDULE_TYPE,
  EXCLUDE_DATETIME_RANGE_TYPE,
  EXCLUDE_DATE_RANGE_TYPE,
]
