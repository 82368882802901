import humanIcon01 from 'app/assets/icons/human01.jpg'

export const OverdueToDos = [
  {
    title: 'Broken Tiles',
    userFirstname: 'Ambrose',
    userLastname: 'Quincy',
    amount: '',
    avatarImage: humanIcon01,
    avatarIcon: '',
    avatarIconColor: '',
    dueDate: '12/03/2017',
    tags: ['Repair'],
    description:
      'There are 3 broken tiles on the bathroom floor. You know those marble ones?',
    actions: [],
    overdue: 'Overdue 8 days',
  },
  {
    title: 'Inspection',
    userFirstname: 'Justin',
    userLastname: 'Bieber',
    amount: '',
    avatarImage: humanIcon01,
    avatarIcon: '',
    avatarIconColor: '',
    dueDate: '14/03/2017 09:30am',
    tags: ['Lease', 'Inspection'],
    description: '',
    actions: ['Re-Schedule'],
    overdue: 'Overdue 6 days',
  },
]

export const InSevenDaysToDos = [
  {
    title: 'Window handle',
    userFirstname: 'Justin',
    userLastname: 'Bieber',
    amount: '',
    avatarImage: humanIcon01,
    avatarIcon: '',
    avatarIconColor: '',
    dueDate: '12/03/2017',
    tags: ['Repair'],
    description: 'We lost the window handle somehow.',
    actions: [],
    overdue: '',
  },
  {
    title: 'Pay Electricity Bill',
    userFirstname: '',
    userLastname: '',
    amount: '$728.98',
    avatarImage: '',
    avatarIcon: 'electricity',
    avatarIconColor: 'yellow',
    dueDate: '12/03/2017',
    tags: ['Bills'],
    description: '',
    actions: ['View Invoice'],
    overdue: '',
  },
  {
    title: 'Window handle',
    userFirstname: 'Ambrose',
    userLastname: 'Quincy',
    amount: '',
    avatarImage: humanIcon01,
    avatarIcon: '',
    avatarIconColor: '',
    dueDate: '12/03/2017',
    tags: ['Repair'],
    description: 'We lost the window handle somehow.',
    actions: [],
    overdue: '',
  },
  {
    title: 'Pay Water Bill',
    userFirstname: '',
    userLastname: '',
    amount: '$728.98',
    avatarImage: '',
    avatarIcon: 'water',
    avatarIconColor: 'blue',
    dueDate: '12/03/2017',
    tags: ['Bills'],
    description: 'Pay using your mastercard!',
    actions: ['View Invoice'],
    overdue: '',
  },
]

export const OverSevenDaysToDos = [
  {
    title: 'Window handle',
    userFirstname: 'Jason',
    userLastname: 'Elston',
    amount: '',
    avatarImage: humanIcon01,
    avatarIcon: '',
    avatarIconColor: '',
    dueDate: '12/03/2017',
    tags: ['Repair'],
    description: 'We lost the window handle somehow.',
    actions: [],
    overdue: '',
  },
  {
    title: 'Pay Gass Bill',
    userFirstname: '',
    userLastname: '',
    amount: '$728.98',
    avatarImage: '',
    avatarIcon: 'gas',
    avatarIconColor: 'green',
    dueDate: '12/03/2017',
    tags: ['Bills'],
    description: '',
    actions: ['View Invoice'],
    overdue: '',
  },
  {
    title: 'Inspection',
    userFirstname: 'Justin',
    userLastname: 'Bieber',
    amount: '',
    avatarImage: humanIcon01,
    avatarIcon: '',
    avatarIconColor: '',
    dueDate: '14/03/2017 09:30am',
    tags: ['Lease', 'Inspection'],
    description: '',
    actions: ['Re-Schedule'],
    overdue: '',
  },
  {
    title: 'Pay Phone Bill',
    userFirstname: '',
    userLastname: '',
    amount: '$728.98',
    avatarImage: '',
    avatarIcon: 'phone',
    avatarIconColor: 'purple',
    dueDate: '12/03/2017',
    tags: ['Bills'],
    description: '',
    actions: ['View Invoice'],
    overdue: '',
  },
]
