import { connect } from 'react-redux'

import ChangePasswordForm from 'app/session/bc_profile/change_password_container'
import { changePassword } from 'app/session/session_actions'

const mapStateToProps = ({ session }) => ({
  error: session.error,
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  changePassword: (data) => changePassword(data),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm)
