import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import bed from 'app/assets/icons/bed.svg'
import car from 'app/assets/icons/car.svg'
import shower from 'app/assets/icons/shower.svg'
import { Box, Flex } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import * as Display from 'app/components/display/display'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  formatRent,
  formatRentWithDollarAndPWeek,
  getCarSpaceCountForProperty,
  pickRentDisplayAndFormat,
} from 'app/sm/helpers'
import { formatTime, HALF_DATE_WITHOUT_YEAR } from 'app/utils/datetime/helpers'

const StyledPropertyInfo = styled(Flex)`
  font-size: ${theme.fontSizes.pRegular14};
  align-items: center;
  img {
    width: ${theme.width[4]}px;
    height: ${theme.height[4]}px;
  }
`

const StyledAvailAndRentInfo = styled(Flex)`
  align-items: center;
  color: ${theme.colors.gray800};
  line-height: ${theme.lineHeights.title};
  margin-bottom: ${theme.space[1]}px;
`

export const PropertyInfoText = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #686d71;
`

export const StyledEllipse = styled(Box)`
  width: ${theme.width[1]}px;
  height: ${theme.height[1]}px;
  border-radius: ${theme.radii[7]};
  background-color: ${theme.colors.gray400};
`

const PropertySummary = ({
  sentUtilityLeads = [],
  utilityProviderConfigured = false,
  property = {},
  activeOffer = {},
  preferences,
}) => {
  const { guidID, bathrooms, bedrooms, garages, parking, carports } = property
  const { bedrooms: prefBedrooms, rent: prefRent } = preferences || {}

  const {
    weeklyRentDisplay = '',
    availableFrom = '',
    weeklyRent = 0,
  } = activeOffer || {}

  const tooltipIdUtility = guidID + '_utility_badge'

  const utilitySentPayload = {
    pillType: (sentUtilityLeads || []).length > 0 ? 'success' : 'warning',
    sentStatus:
      (sentUtilityLeads || []).length > 0
        ? 'Utility referral Sent'
        : 'Utility referral not sent',
  }

  const availableDate = availableFrom
    ? formatTime(availableFrom, HALF_DATE_WITHOUT_YEAR)
    : 'N/A'

  const carCountSpaces = { garages, parking, carports }

  const formattedRent =
    !weeklyRent && !weeklyRentDisplay
      ? formatRentWithDollarAndPWeek(prefRent)
      : pickRentDisplayAndFormat(weeklyRent, weeklyRentDisplay)

  return (
    <Flex flexDirection="column" justifyContent="center">
      <StyledPropertyInfo mb={2}>
        <Flex mr={3}>
          <img src={bed} alt="bedrooms" />
          <Box ml={2}>{bedrooms || prefBedrooms || 'N/A'}</Box>
        </Flex>
        <Flex mr={3}>
          <img src={shower} alt="bathrooms" />
          <Box ml={2}>{bathrooms || 'N/A'}</Box>
        </Flex>
        <Flex mr={3}>
          <img src={car} alt="cars" />
          <Box ml={2}>
            {getCarSpaceCountForProperty(carCountSpaces) || 'N/A'}
          </Box>
        </Flex>
      </StyledPropertyInfo>
      <StyledAvailAndRentInfo>
        <PropertyInfoText>{formattedRent}</PropertyInfoText>
        <StyledEllipse mx={2} />
        <PropertyInfoText>{availableDate}</PropertyInfoText>
      </StyledAvailAndRentInfo>
      <Flex>
        {utilityProviderConfigured && (
          <>
            <Badge
              text="Utility"
              variant={utilitySentPayload.pillType}
              data-tooltip-content=""
              data-tooltip-id={tooltipIdUtility}
            />
            <ReactTooltip
              id={tooltipIdUtility}
              data-type="info"
              place="top"
              className="tool-tip-style"
            >
              <Box textAlign="center">{utilitySentPayload.sentStatus}</Box>
            </ReactTooltip>
          </>
        )}
        <Display.PropertyLabels activeOffer={activeOffer} />
      </Flex>
    </Flex>
  )
}

export default PropertySummary
