import React from 'react'

import { Link } from 'react-router-dom'

import headerImg01 from 'app/assets/icons/header-img-01.png'
import { history } from 'app/shared_components/router'

function OwnerDecline() {
  let navigateUrl =
    window.location.href.indexOf('agency') > 0
      ? '/agency/4824a732-2475-4ac7-8bfb-d27b5e35a45e/bc/dashboard'
      : '/portfolio/overview'

  return (
    <div className="two-col-box-flex-row sm64">
      <div className="col-first">
        <div className="ilustration-box">
          <img src={headerImg01} />
        </div>

        <h3 className="mb15">
          Your tenant has been declined for this BondCover request.
        </h3>

        <p>
          If you believe this BondCover request has been declined in error or
          would like some more information, please contact us{' '}
          <a href="mailto:help@snug.com">help@snug.com</a>
        </p>

        <Link className="btn mt30" to={navigateUrl}>
          Done
        </Link>
      </div>
    </div>
  )
}

export default OwnerDecline
