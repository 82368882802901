import React from 'react'

import DefaultButton from 'app/components/buttons/default/component'
import { Button } from 'app/components/design-system-components'
import { ModalContentWrapper } from 'app/modals/modal-styled'

// TODO: Close modal when clicked outside the wrapper on the overlay
const BasicModal = ({
  toggleModal,
  title,
  body,
  primaryButtonLabel,
  primaryButtonAction,
  secondaryButtonLabel,
  secondaryButtonAction,
  primaryButtonClassName = '',
  secondaryButtonClassName = '',
  showSecondaryButton = true,
  showButtonsSection = true,
}) => (
  <div className="modal-fullscreen modal-layout-flex-container">
    <ModalContentWrapper>
      <div className="modal-close">
        <i
          className="fa fa-times"
          role="button"
          tabIndex="0"
          onClick={toggleModal}
          onKeyPress={toggleModal}
        />
      </div>
      <div className="modal-header">
        <h4>{title}</h4>
      </div>
      <div className="modal-body">
        <div className="mb20">{body}</div>
        {showButtonsSection && (
          <div className="display-flex modal-bottom-btn-container">
            <button
              type="button"
              className={`btn mt10 width-auto p10 ${primaryButtonClassName}`}
              onClick={primaryButtonAction}
            >
              {primaryButtonLabel}
            </button>
            {showSecondaryButton && (
              <button
                type="button"
                className={`btn mt10 hollow-button-green std-modal-btn-width p0 ${secondaryButtonClassName}`}
                onClick={secondaryButtonAction}
              >
                {secondaryButtonLabel}
              </button>
            )}
          </div>
        )}
      </div>
    </ModalContentWrapper>
  </div>
)

export const BasicSingleButtonModal = ({
  toggleModal,
  title,
  body,
  primaryButtonLabel,
  primaryButtonAction,
}) => (
  <div className="modal-fullscreen modal-layout-flex-container">
    <ModalContentWrapper>
      <div className="modal-close">
        <i
          className="fa fa-times"
          role="button"
          tabIndex="0"
          onClick={toggleModal}
          onKeyPress={toggleModal}
        />
      </div>
      <div className="modal-header">
        <h4>{title}</h4>
      </div>
      <div className="modal-body">
        <div className="mb20">{body}</div>
        <div className="display-flex modal-bottom-btn-container">
          <button
            type="button"
            className="btn mt10 std-modal-btn-width"
            onClick={primaryButtonAction}
          >
            {primaryButtonLabel}
          </button>
        </div>
      </div>
    </ModalContentWrapper>
  </div>
)

export const BasicMultiButtonModal = ({
  toggleModal,
  title,
  body,
  buttons = [],
}) => (
  <div className="modal-fullscreen modal-layout-flex-container">
    <ModalContentWrapper>
      <div className="modal-close">
        <i
          className="fa fa-times"
          role="button"
          tabIndex="0"
          onClick={toggleModal}
          onKeyPress={toggleModal}
        />
      </div>
      <div className="modal-header">
        <h4>{title}</h4>
      </div>
      <div className="modal-body">
        <div className="mb20">{body}</div>
        <div className="display-flex modal-bottom-btn-container">
          {buttons.map(({ action, label }) => (
            <Button variant="greenHover" onClick={action}>
              {label}
            </Button>
          ))}
        </div>
      </div>
    </ModalContentWrapper>
  </div>
)

export const BasicModalWithFixedButtons = ({
  toggleModal,
  title,
  body,
  primaryButtonLabel,
  primaryButtonAction,
  secondaryButtonLabel,
  secondaryButtonAction,
  primaryButtonClassName = '',
  secondaryButtonClassName = '',
  showSecondaryButton = true,
  primaryDisabled,
  primaryIsLoading = false,
}) => (
  <div className="modal-fullscreen modal-layout-flex-container">
    <ModalContentWrapper>
      <div className="modal-header">
        <h4>{title}</h4>
        <div className="modal-close">
          <i
            className="fa fa-times"
            role="button"
            tabIndex="0"
            onClick={toggleModal}
            onKeyPress={toggleModal}
          />
        </div>
      </div>
      <div className="modal-body">
        <div className="mb20">{body}</div>
      </div>

      <div className="display-flex modal-bottom-btn-container">
        {showSecondaryButton && (
          <div className="button-default secondary width100 mt10 margin-profile-item-left col-sm-6">
            <button
              type="button"
              className={`large-button ${secondaryButtonClassName}`}
              onClick={secondaryButtonAction}
            >
              {secondaryButtonLabel}
            </button>
          </div>
        )}
        <div className="button-default  width100 mt10 margin-profile-item-right col-sm-6">
          <DefaultButton
            type="button"
            className={`large-button  ${primaryButtonClassName}`}
            onClick={primaryButtonAction}
            disabled={primaryDisabled}
            isLoading={primaryIsLoading}
            text={primaryButtonLabel}
          />
        </div>
      </div>
    </ModalContentWrapper>
  </div>
)

export default BasicModal
