import React from 'react'

import { getNumberType } from 'libphonenumber-js'
import metadata from 'libphonenumber-js/metadata.full.json'
import qs from 'qs'

import { IntlTelInputWrapper } from 'app/components/design-system-components'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import * as snugNotifier from 'app/services/snugNotifier'
import {
  ErrorMessage,
  intTelTop10List,
  isValidMobileNumber,
} from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import * as validations from 'app/shared_components/validations'
import Spinner from 'app/sm/common/spinner'
import { urlTo } from 'app/sm/helpers'
import {
  LoadMore,
  PropertySearchAndDropDown,
} from 'app/sm/remarketing/remarketing_container'
import {
  PropertyItem,
  SearchedPropertyResults,
} from 'app/sm/team_contact/adding_contact_property_list_component'
import Header from 'app/sm/team_contact/header/component'
import InputBox from 'app/sm/team_contact/input_box/component'
import TextareaBox from 'app/sm/team_contact/textarea_box/component'

export const findChosenTeam = (teams = [], teamSlug) =>
  teams.find((team) => team.slug === teamSlug)
const isEditMode = () => window.location.pathname.includes('contacts/edit')
const newListingTypeInterested = 'interested'
const newListingTypeOwned = 'owned'
const typeInterestedProperties = 'interestedProperties'
const typeOwnedProperties = 'ownedProperties'

export const ContactPropertiesContainer = ({
  properties,
  onRemoveButtonClick,
  isPropertyAttached,
  title = '',
  noPropertyText = '',
}) => {
  return (
    <div>
      <h5 className="mb10 mt10">{title}</h5>
      {properties === undefined ||
        (properties.length == 0 && <h6>{noPropertyText}</h6>)}
      {properties &&
        properties.length > 0 &&
        properties.map((propertyData, index) => {
          return (
            properties && (
              <PropertyItem
                propertyItem={propertyData}
                weeklyRent={propertyData.offers[0].weeklyRent}
                availableFrom={propertyData.offers[0].availableFrom}
                key={propertyData && propertyData.guidID}
                onRemoveButtonClick={onRemoveButtonClick}
                showRemoveButton={true}
                showAddButton={false}
                isPropertyAttached={isPropertyAttached}
                key={index}
              />
            )
          )
        })}
    </div>
  )
}

class TeamContactContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      apiError: '',
      errors: {},
      contactInfo: {
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        preference: '',
        showSpinner: false,
        tableSpinner: false,
        loadMoreSpinner: false,
      },
      existedInterestedProperties: [],
      existedOwnedProperties: [],
      newListingInterestedProperties: [],
      newListingOwnedProperties: [],
      propertySearchResults: {},
      searchText: '',
    }
  }

  componentDidMount() {
    const { fetchProperty } = this.props
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { propertyid, type } = curQueries
    this.loadContact()
    if (propertyid) {
      fetchProperty(propertyid)
        .then((result) => {
          const {
            newListingInterestedProperties = [],
            newListingOwnedProperties = [],
          } = this.state
          if (type === newListingTypeInterested) {
            newListingInterestedProperties.push(result)
            this.setState({
              newListingInterestedProperties,
            })
          } else if (type === newListingTypeOwned) {
            newListingOwnedProperties.push(result)
            this.setState({
              newListingInterestedProperties,
            })
          }
        })
        .catch((apiError) =>
          this.setState({
            apiError,
          }),
        )
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.teams !== this.props.teams) {
      this.loadContact()
    }
  }

  onAddSearchedProperty = (category) => {
    return (guidID) => {
      const { propertySearchResults } = this.state

      if (propertySearchResults === undefined) {
        return
      }

      if (propertySearchResults.data === undefined) {
        return
      }

      const data = propertySearchResults.data

      const selectedProperty = data.find(
        (propertyInfo) => propertyInfo.property.guidID === guidID,
      ).property

      this.state[category].push(selectedProperty)

      this.setState({
        [category]: this.state[category],
      })
    }
  }

  onInputChange = (event, field) => {
    const { contactInfo } = this.state
    let { value } = event.target
    if (field === 'email') {
      value = value.toLowerCase()
    }
    contactInfo[field] = value
    this.setState({
      contactInfo,
    })
  }

  onRemoveProperty = (category) => {
    return (guidID) => {
      const {
        existedInterestedProperties,
        newListingInterestedProperties,
        existedOwnedProperties,
        newListingOwnedProperties,
      } = this.state
      if (category === typeInterestedProperties) {
        if (
          existedInterestedProperties.find(
            (property) => property.guidID === guidID,
          )
        ) {
          this.setState({
            existedInterestedProperties: existedInterestedProperties.filter(
              (property) => property.guidID !== guidID,
            ),
          })
        } else if (
          newListingInterestedProperties.find(
            (property) => property.guidID === guidID,
          )
        ) {
          this.setState({
            newListingInterestedProperties:
              newListingInterestedProperties.filter(
                (property) => property.guidID !== guidID,
              ),
          })
        }
      } else if (category === typeOwnedProperties) {
        if (
          existedOwnedProperties.find((property) => property.guidID === guidID)
        ) {
          this.setState({
            existedOwnedProperties: existedOwnedProperties.filter(
              (property) => property.guidID !== guidID,
            ),
          })
        } else if (
          newListingOwnedProperties.find(
            (property) => property.guidID === guidID,
          )
        ) {
          this.setState({
            newListingOwnedProperties: newListingOwnedProperties.filter(
              (property) => property.guidID !== guidID,
            ),
          })
        }
      }
    }
  }

  onSaveClicked = () => {
    const {
      contactInfo,
      existedInterestedProperties,
      existedOwnedProperties,
      newListingInterestedProperties,
      newListingOwnedProperties,
    } = this.state
    const { teams, addContact } = this.props
    const { teamSlug } = this.props.match.params
    const chosenTeam = findChosenTeam(teams, teamSlug)
    const { guid: teamGuid } = chosenTeam
    const { email, firstName, lastName, phone, preference } = contactInfo
    const sortedContactInfo = Object.assign(
      {},
      {
        email,
        firstName,
        lastName,
        phone,
        preference,
      },
    )
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const { goback = '' } = curQueries
    const formData = Object.assign(
      {},
      sortedContactInfo,
      {
        interestedProperties: existedInterestedProperties
          .map((property) => property.guidID)
          .concat(
            newListingInterestedProperties.map((property) => property.guidID),
          ),
      },
      {
        ownedProperties: existedOwnedProperties
          .map((property) => property.guidID)
          .concat(newListingOwnedProperties.map((property) => property.guidID)),
      },
    )
    let promise
    if (isEditMode()) {
      promise = this.props.updateContact(
        teamGuid,
        this.props.match.params.contactGUID,
        formData,
      )
    } else {
      promise = addContact(teamGuid, formData)
    }
    promise
      .then(() => {
        if (goback) {
          history.goBack()
        } else {
          history.push(urlTo('contactList', { teamSlug }))
        }
        snugNotifier.success('The contact has been added successfully')
      })
      .catch((apiError) => {
        this.setState({
          apiError,
        })
      })
  }

  onSearchChange = (event) => {
    const teamSlug = this.props.match.params.teamSlug || ''
    const currentTeam = this.props.teams.find(
      (option) => option.slug === teamSlug,
    )

    const { guid: agencyGUID } = currentTeam
    const { value } = event.target
    clearInterval(this.propertySearch)

    this.setState({ searchText: value }, () => {
      const { searchText = '', manager = '' } = this.state
      this.propertySearch = setTimeout(
        () =>
          this.callFetchTeamProperties(agencyGUID, {
            searchText,
            manager,
            status: 3,
          })
            .then(({ properties = {} }) => {
              this.setState({
                propertySearchResults: properties,
                propertyCount: properties.response_metadata.total,
              })
            })
            .catch((apiError) => {
              this.setState({
                apiError,
              })
            }),
        500,
      )
    })
  }

  onTeleNumberBlur = (field) => {
    return (status, value, countryData, number, id) => {
      const actualNumberType = getNumberType(number, metadata)

      if (!isValidMobileNumber(actualNumberType) && value !== '') {
        const { errors } = this.state
        errors.phone = ['Invalid mobile number']
        this.setState({
          errors,
        })
      } else {
        const { errors } = this.state
        delete errors['phone']
        this.setState({
          errors,
        })
      }
    }
  }

  onTeleNumberChange = (field) => {
    return (status, value, countryData, number, id) => {
      const { contactInfo } = this.state
      contactInfo[field] = number
      this.setState({
        contactInfo,
      })
    }
  }

  setValidationErrors(field, error) {
    let { errors } = this.state
    if (error && error.length == 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors: errors })
      return false
    }
  }

  callFetchTeamProperties = (
    agencyGUID,
    { searchText, manager, status, offset, loadMore = false },
  ) => {
    const { fetchTeamProperties } = this.props
    loadMore
      ? this.setState({ loadMoreSpinner: true })
      : this.setState({ tableSpinner: true })
    return fetchTeamProperties(agencyGUID, {
      searchText,
      manager,
      status,
      offset,
      loadMore,
    })
      .then(({ properties }) => {
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
        return Promise.resolve({ properties })
      })
      .catch((apiError) => {
        this.setState({ apiError })
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
        return Promise.reject(apiError)
      })
  }

  isDisabled() {
    const { contactInfo, errors } = this.state
    if (
      contactInfo['firstName'] === '' ||
      (contactInfo['email'] === '' && contactInfo['phone'] === '')
    ) {
      return true
    }
    if (Object.keys(errors).find((infoKey) => contactInfo[infoKey] !== ''))
      return true
    return false
  }

  isPropertyAttached = (guidID) => {
    const { existedInterestedProperties, existedOwnedProperties } = this.state
    return (
      existedInterestedProperties.some(
        (property) => property.guidID === guidID,
      ) || existedOwnedProperties.some((property) => property.guidID === guidID)
    )
  }

  loadContact = () => {
    // edit mode
    if (isEditMode()) {
      const teamSlug = this.props.match.params.teamSlug || ''
      const currentTeam = this.props.teams.find(
        (option) => option.slug === teamSlug,
      )
      if (currentTeam && currentTeam.guid) {
        this.props
          .fetchContact(currentTeam.guid, this.props.match.params.contactGUID)
          .then(({ contactInfo }) => {
            this.setState({ contactInfo })
          })
          .catch((apiError) => {
            this.setState({ apiError })
          })
      }
    }
  }

  loadMore = (offset) => {
    const { searchText = '' } = this.state
    const teamSlug = this.props.match.params.teamSlug || ''
    const currentTeam = this.props.teams.find(
      (option) => option.slug === teamSlug,
    )
    const { guid: agencyGUID } = currentTeam
    const loadMore = true
    this.callFetchTeamProperties(agencyGUID, {
      searchText,
      status: 3,
      offset,
      loadMore,
    })
      .then(({ properties }) => {
        const previousProperties = this.state.propertySearchResults.data
        const updatedProperties = previousProperties.concat(properties.data)
        this.setState({
          propertySearchResults: {
            data: updatedProperties,
            response_metadata: properties.response_metadata,
          },
        })
      })
      .catch((apiError) => {
        this.setState({
          apiError,
        })
      })
  }

  validate = (field) => {
    return () => {
      const { contactInfo } = this.state
      const { email, phone, firstName } = contactInfo

      switch (field) {
        case 'email':
          return this.setValidationErrors(
            field,
            validations.validateEmailAddresses(
              email,
              'Please enter a valid email address',
            ),
          )
        case 'phone':
          return this.setValidationErrors(
            field,
            validations.validateMobile(phone, 'Invalid mobile number'),
          )
        case 'firstName':
          return this.setValidationErrors(
            field,
            validations.validateName(firstName, 'Invalid first name'),
          )
      }
    }
  }

  render() {
    const {
      contactInfo,
      errors,
      existedInterestedProperties,
      existedOwnedProperties,
      showSpinner,
      searchText,
      propertySearchResults,
      tableSpinner,
      loadMoreSpinner,
      apiError,
      newListingInterestedProperties,
      newListingOwnedProperties,
    } = this.state
    const { email, phone, firstName, lastName, preference } = contactInfo

    const { teams } = this.props
    const { teamSlug } = this.props.match.params
    const chosenTeam = findChosenTeam(teams, teamSlug) || {}
    const { name: agencyName = '', slug = '', guid = '' } = chosenTeam
    let crumbs = []
    if (agencyName) {
      crumbs = [
        {
          text: agencyName || 'Team',
          link: urlTo('teamOverview', { teamSlug: slug }),
        },
        {
          text: 'Contacts',
          link: urlTo('contactList', { teamSlug: slug }),
        },
        {
          text: isEditMode() ? 'Edit' : 'Add',
          link: '#',
        },
      ]
    }

    const offset = propertySearchResults?.data?.length
    const hasMore = offset < propertySearchResults?.response_metadata?.total
    const interestedPropertyCollection = newListingInterestedProperties.concat(
      existedInterestedProperties,
    )
    const ownedPropertyCollection = newListingOwnedProperties.concat(
      existedOwnedProperties,
    )

    return (
      <div className="profile-screen">
        <div className="mb10 pdf-hide">
          <Breadcrumbs crumbs={crumbs} />
        </div>
        <Header label={isEditMode() ? 'Edit Contact' : 'Add Contact'} />
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <div>
              <InputBox
                validate={this.validate}
                onInputChange={this.onInputChange}
                field="email"
                placeholder="Add email"
                error={errors['email'] && errors['email'][0]}
                value={email}
                label="Email"
              />
              <div className={`input-box`}>
                <IntlTelInputWrapper
                  css={['intl-tel-input', '']}
                  utilsScript={'libphonenumber.js'}
                  defaultCountry={'au'}
                  preferredCountries={intTelTop10List}
                  onPhoneNumberChange={this.onTeleNumberChange('phone')}
                  onPhoneNumberBlur={this.onTeleNumberBlur('phone')}
                  value={phone}
                  placeholder="Add mobile"
                />
                <p>Mobile</p>
                {errors['phone'] && errors['phone'][0] && (
                  <div className="alert alert-danger">
                    {' '}
                    {errors['phone'][0]}{' '}
                  </div>
                )}
              </div>
              <InputBox
                validate={this.validate}
                onInputChange={this.onInputChange}
                field="firstName"
                placeholder="Add first name"
                error={errors['firstName'] && errors['firstName'][0]}
                value={firstName}
                label="First name"
              />
              <InputBox
                validate={this.validate}
                onInputChange={this.onInputChange}
                field="lastName"
                placeholder="Add last name"
                error={errors['lastName'] && errors['lastName'][0]}
                value={lastName}
                label="Last name"
              />
              <TextareaBox
                placeholder="Add preference"
                value={preference}
                onInputChange={this.onInputChange}
                rows={'2'}
                label={'Preference'}
                field="preference"
              />
              <ContactPropertiesContainer
                properties={interestedPropertyCollection}
                onRemoveButtonClick={this.onRemoveProperty(
                  typeInterestedProperties,
                )}
                isPropertyAttached={this.isPropertyAttached}
                title="Interested Properties:"
                noPropertyText="NO PROPERTIES ATTACHED"
              />
              <ContactPropertiesContainer
                properties={ownedPropertyCollection}
                onRemoveButtonClick={this.onRemoveProperty(typeOwnedProperties)}
                isPropertyAttached={this.isPropertyAttached}
                title="Owned Properties:"
                noPropertyText="NO PROPERTIES ATTACHED"
              />
              <div className="row pt25 mobile-form-button mb80">
                <div className="col-sm-5 pb5 tablet-only">
                  <button
                    className="btn btn-transparent btn-left xs-text-center wa"
                    onClick={() => history.goBack()}
                  >
                    <i className="icon-arrow-left left"></i>
                    <span>Back</span>
                  </button>
                </div>
                <div className="col-sm-7">
                  <button
                    disabled={this.isDisabled()}
                    onClick={() => {
                      this.onSaveClicked()
                    }}
                  >
                    Save{' '}
                    <i
                      className={showSpinner ? 'fa fa-spinner fa-pulse' : ''}
                    />
                  </button>
                </div>
              </div>
              {apiError && (
                <ErrorMessage error={translateErrorCodeToMessage(apiError)} />
              )}
              <div className="mt50">
                <PropertySearchAndDropDown
                  placeholder="Search street name or suburb"
                  value={searchText}
                  onSearchChange={this.onSearchChange}
                  propertySearchResults={propertySearchResults}
                  onInputBlur={undefined}
                  hideResults={false}
                />
              </div>
              {tableSpinner ? (
                <Spinner />
              ) : (
                <SearchedPropertyResults
                  propertySearchResults={propertySearchResults}
                  onAddButtonClick={this.onAddSearchedProperty}
                  hideResults={false}
                  isPropertyAttached={this.isPropertyAttached}
                />
              )}
            </div>
            {hasMore && (
              <div className={`text-center mt20 ml20`}>
                {loadMoreSpinner ? (
                  <Spinner />
                ) : (
                  <LoadMore offset={offset} loadMore={this.loadMore} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default TeamContactContainer
