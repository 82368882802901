import { connect } from 'react-redux'

import RentalReputationHeadReminder from 'app/sm/rental_reputation_shared_component/header_reminder'

const mapStateToProps = ({ Shared, apply }) => ({
  rentalBackUrlList: Shared.rentalBackUrlList,
  applyAnywhereListing: apply.applyAnywhereListing,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RentalReputationHeadReminder)
