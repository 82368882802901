import React from 'react'

import { connect } from 'react-redux'

import Avatar from 'app/session/bc_profile/avatar'
import {
  fetchCurrentUser,
  fetchCurrentUserProfile,
} from 'app/session/session_actions'
import {
  addUserProfilePicture,
  deleteUserProfilePicture,
} from 'app/session/session_actions'
import { setBackUrl } from 'app/shared_components/actions'
import {
  invalidDocExtensionExists,
  invalidDocSizeExists,
} from 'app/shared_components/helpers'
import {
  changeReputationPreference,
  retrieveReputationPreference,
} from 'app/sm/rental_reputation/actions'
import { fetchCompleteness } from 'app/sm/rental_reputation/actions'
import { errorMessageForInvalidFileTypeImage } from 'app/utils/text/helpers'

class AvatarContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      photoPreviewUrl: '',
      photoFile: '',
      rotate: 0,
      photosValidationError: '',
      isSubmitting: false,
      apiError: '',
    }
  }

  componentDidMount() {
    this.props.retrieveReputationPreference()
  }

  onChangeOptOutProfileAvatar = () => {
    const needDelete =
      this.props.reputationPreference.optOutProfileAvatar === false &&
      this.props.currentUser.avatar
    if (needDelete) {
      if (
        window.confirm(
          'This will delete your existing profile picture. Would you like to continue?',
        )
      ) {
        this.props
          .deleteUserProfilePicture()
          .then(() =>
            this.props.changeReputationPreference({
              ...this.props.reputationPreference,
              optOutProfileAvatar:
                !this.props.reputationPreference.optOutProfileAvatar,
            }),
          )
          .then(() => this.props.fetchCompleteness())
          .then(() => this.props.fetchCurrentUserProfile())
          .catch((error) => this.setState({ apiError: error }))
      }
    } else {
      this.props
        .changeReputationPreference({
          ...this.props.reputationPreference,
          optOutProfileAvatar:
            !this.props.reputationPreference.optOutProfileAvatar,
        })
        .then(() => this.props.fetchCompleteness())
        .then(() => this.props.fetchCurrentUserProfile())
        .catch((error) => this.setState({ apiError: error }))
    }
  }

  setEditorRef = (editor) => {
    this.editor = editor
  }

  rotateLeft = () => {
    this.setState({ rotate: this.state.rotate - 90 })
  }

  rotateRight = () => {
    this.setState({ rotate: this.state.rotate + 90 })
  }

  submitPhoto = () => {
    this.setState({ isSubmitting: true })
    const cropped = this.editor.getImageScaledToCanvas()

    // polyfill for HTMLCanvasElement.toBlob()
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function (callback, type, quality) {
          var canvas = this
          setTimeout(function () {
            var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
              len = binStr.length,
              arr = new Uint8Array(len)

            for (var i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i)
            }

            callback(new Blob([arr], { type: type || 'image/png' }))
          })
        },
      })
    }

    cropped.toBlob(
      (blob) => {
        const formData = new FormData()
        formData.append('file', blob, 'blob.jpeg')
        this.props.addUserProfilePicture(
          formData,
          () => {
            this.setState({
              isSubmitting: false,
              photoPreviewUrl: '',
              rotate: 0,
              apiError: '',
            })
          },
          (apiError) => {
            this.setState({ isSubmitting: false, apiError })
          },
        )
      },
      'image/jpeg',
      0.95,
    )
  }

  updatePhotos = () => {
    const photos = document.getElementById('photos')
    const photosArr = Object.keys(photos.files).map((k) => photos.files[k])
    const findInvalidDocExtension = invalidDocExtensionExists(photosArr, 'pic')
    const findInvalidDocSize = invalidDocSizeExists(photosArr)

    let photosValidationError = ''
    if (findInvalidDocExtension) {
      photosValidationError = errorMessageForInvalidFileTypeImage(
        findInvalidDocExtension?.name,
      )
    } else if (findInvalidDocSize) {
      photosValidationError = `The photo size of ${findInvalidDocSize.name} can not exceed 10mb`
    }
    if (photosValidationError) {
      this.setState({ photosValidationError })
      return
    }

    const reader = new FileReader()
    const photoFile = photosArr[0]
    if (photoFile) {
      reader.onloadend = () => {
        const photoPreviewUrl = reader.result
        this.setState({ photoPreviewUrl, photoFile, photosValidationError: '' })
      }
      reader.readAsDataURL(photoFile)
    }
  }

  render() {
    const { completeness } = this.props
    return (
      <Avatar
        updatePhotos={this.updatePhotos}
        submitPhoto={this.submitPhoto}
        setEditorRef={this.setEditorRef}
        rotateLeft={this.rotateLeft}
        rotateRight={this.rotateRight}
        onChangeOptOutProfileAvatar={this.onChangeOptOutProfileAvatar}
        backUrl={this.props.backUrl}
        property={this.props.property}
        currentUser={this.props.currentUser}
        optOutProfileAvatar={
          this.props.reputationPreference.optOutProfileAvatar
        }
        completeness={completeness}
        {...this.state}
      />
    )
  }
}

const mapStateToProps = ({ Shared, session, apply, RentalReputation }) => ({
  backUrl: Shared.backUrl,
  currentUser: session.currentUser,
  property: apply.property,
  reputationPreference: RentalReputation.preference,
  completeness: RentalReputation.completeness,
})

const mapDispatchToProps = (dispatch) => ({
  addUserProfilePicture: (formData, success, fail) =>
    dispatch(addUserProfilePicture(formData, success, fail)),
  setBackUrl: (backUrl) => dispatch(setBackUrl(backUrl)),
  retrieveReputationPreference: () => dispatch(retrieveReputationPreference()),
  changeReputationPreference: (preference) =>
    dispatch(changeReputationPreference(preference)),
  deleteUserProfilePicture: () => dispatch(deleteUserProfilePicture()),
  fetchCurrentUserProfile: () => dispatch(fetchCurrentUserProfile()),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AvatarContainer)
