import React from 'react'

import styled from 'styled-components'

import { Box, Button } from 'app/components/design-system-components'
import { history } from 'app/shared_components/router'
import SnugUpgradeAccountModal from 'app/shared_components/snug_account_upgrade/modal'
import { urlIds, urlTo } from 'app/sm/helpers'
import * as accountHelpers from 'app/utils/accounts/helpers'
import * as widgetHelpers from 'app/utils/widgets/helpers'

const WINDOW_WIDTH_SMALL_PX = 576

const StyledUpgradeContainer = styled(Box)`
  display: inline-block;
`

class LiteAccountButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      litePlanModalEnabled: false,
      isWindowSmall: false,
    }
  }
  componentDidMount() {
    const windowWidth = window.innerWidth
    if (windowWidth < WINDOW_WIDTH_SMALL_PX) {
      this.setState({
        isWindowSmall: true,
      })
    }
  }

  openWidgetWithSettings = () => {
    const { currentUser = {} } = this.props
    const { firstName = '', lastName = '', email = '' } = currentUser || {}
    widgetHelpers.openZendeskWidget()
    widgetHelpers.populateZendeskWidgetWithNameAndEmail(
      `${firstName} ${lastName}`,
      email,
    )
    widgetHelpers.populateZendeskSuggestions('Learn more about Snug')
  }

  render() {
    const { numTeamMembers = 0, teamSlug = '' } = this.props
    const showAddTeamMembers = numTeamMembers === 1
    const { litePlanModalEnabled, isWindowSmall } = this.state
    const upgradeButtonLabel = 'Free Forever Plan'
    const addTeamMembersLabel = 'Add Team Members'

    if (isWindowSmall) {
      return null
    }

    return (
      <StyledUpgradeContainer>
        {showAddTeamMembers && (
          <Button
            mr={4}
            sizeVariant="regular"
            width="fit-content"
            onClick={() =>
              history.push(urlTo(urlIds.teams.settings.members, { teamSlug }))
            }
          >
            {addTeamMembersLabel}
          </Button>
        )}
        <Button
          variant="linkBlue"
          sizeVariant="regular"
          width="fit-content"
          onClick={() => this.openWidgetWithSettings()}
          mr={4}
        >
          {upgradeButtonLabel}
        </Button>

        {litePlanModalEnabled && (
          <SnugUpgradeAccountModal
            isNotToBeEmbedded={false}
            typeOfMessage={accountHelpers.UPGRADE_TYPE_MESSAGES}
            toggleModal={() =>
              this.setState({ litePlanModalEnabled: !litePlanModalEnabled })
            }
          />
        )}
      </StyledUpgradeContainer>
    )
  }
}

export default LiteAccountButton
