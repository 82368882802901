export const stateTypes = {
  ACT: 'ACT',
  QLD: 'QLD',
  NSW: 'NSW',
  NT: 'NT',
  SA: 'SA',
  TAS: 'TAS',
  VIC: 'VIC',
  WA: 'WA',
}

export const stateOptions = Object.entries(stateTypes).map(
  ([label, value]) => ({
    label,
    value,
  }),
)
