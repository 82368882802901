import { Switch } from 'react-router-dom'

import JoinSnug from 'app/session/join'
import JoinSnugVerify from 'app/session/join/verify/verify_connection'
import { AuthRoute } from 'app/shared_components/route_util'
import { urlIds, urlTo } from 'app/sm/helpers'

const JoinPages = () => {
  return (
    <Switch>
      <AuthRoute
        exact
        path={urlTo(urlIds.join.verify.mobile)}
        component={JoinSnugVerify}
      />
      <AuthRoute
        exact
        path={urlTo(urlIds.join.verify.rr)}
        component={JoinSnugVerify}
      />
      <AuthRoute
        exact
        path={urlTo(urlIds.join.verify.twoFA.email)}
        component={JoinSnugVerify}
      />
      <AuthRoute
        exact
        path={urlTo(urlIds.join.verify.twoFA.sms)}
        component={JoinSnugVerify}
      />
      <AuthRoute
        exact
        path={urlTo(urlIds.join.updateRegistration)}
        component={JoinSnug}
      />
      <AuthRoute exact path="" component={JoinSnug} />
    </Switch>
  )
}

export default JoinPages
