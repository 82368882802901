import { api } from 'app/utils/api/helpers'

export const fetchActivities = (teamGUID, params) => {
  return api.get(`/sm/${teamGUID}/activity-feed`, {
    params: params,
  })
}

export const checkForNewFeed = (teamGUID) => {
  return api.get(`/sm/${teamGUID}/activity-feed/check-new`)
}
