import { connect } from 'react-redux'

import TeamApplicationsContainer from 'app/agency/applications_list/container'
import {
  fetchAgencyStatus,
  fetchTeamManagerList,
} from 'app/bond_cover/agency/agency_actions'
import { setPropertyFilters } from 'app/dashboard/team_overview_actions'
import { persistManager } from 'app/sm/properties/properties_actions'

const mapStateToProps = ({ session, TeamOverview }) => ({
  teams: session.teams,
  currentTeam: session.currentTeam,
  filters: TeamOverview.filters,
})

const mapDispatchToProps = (dispatch) => ({
  persistManager: (manager) => dispatch(persistManager(manager)),
  fetchAgencyStatus: (agencyGUID) => dispatch(fetchAgencyStatus(agencyGUID)),
  setPropertyFilters: (filters) => dispatch(setPropertyFilters(filters)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamApplicationsContainer)
