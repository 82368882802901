import React from 'react'

import { websiteUrl } from 'config/env'
import { Link } from 'react-router-dom'

import BondCoverReferral from 'app/dashboard/components/bond_cover_referral_connection'
import { urlTo } from 'app/sm/helpers'
import Properties from 'app/sm/properties/properties_connection'
import { openZendeskWidget } from 'app/utils/widgets/helpers'
import { FeatureFlag } from 'config/features'

const toggleChat = () => {
  openZendeskWidget()
}

const VideoModal = ({ closeVideoModal }) => (
  <div className="modal-fullscreen">
    <div className="modal-wrapper">
      <div className="modal-close">
        <i className="fa fa-times" onClick={closeVideoModal} />
      </div>
      <div className="renter-dashboard-video pb0">
        <iframe
          title="video"
          src="https://www.youtube.com/embed/zH5v0xUc0Jk"
          height="100%"
          width="100%"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  </div>
)
class ManagerDashboardContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inviteModalOpen: false,
      videoModalOpen: false,
      REAQuery: {
        channel: 'rent',
        filters: {
          agencyIds: [],
        },
        pageSize: 200,
        page: 1,
      },
      fetchReaAgencyIdApiError: '',
      reaButtonClicked: false,
      reaListingApiError: '',
      reaAgencyId: null,
    }
  }

  componentDidMount() {
    const { fetchManagerDashboard } = this.props
    fetchManagerDashboard() // fetch bondcovers

    // used when currentTeam is not updated e.g. navigated to this page by clicking My Portfolio tab directly
    this.props.currentTeam &&
      this.props.currentTeam.guid &&
      this.props.fetchProperties(this.props.currentTeam.guid)
    this.props.currentTeam &&
      this.props.setBackUrl(
        urlTo('portfolioOverview', { teamSlug: this.props.currentTeam.slug }),
      )
    this.props.currentTeam &&
      this.props.currentTeam.guid &&
      this.props.fetchReaAgencyId(
        this.props.currentTeam.guid,
        (reaAgencyId) => {
          this.setState({ reaAgencyId })
        },
        (t) => {
          this.setState({ fetchReaAgencyIdApiError: t })
        },
      )
    this.props.currentTeam &&
      this.props.currentTeam.guid &&
      this.props.fetchTeamManagerList(
        this.props.currentTeam.guid,
        (managerList) => {
          this.setState({
            managerList,
          })
        },
        (t) => {
          this.setState({ fetchReaAgencyIdApiError: t })
        },
      )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentTeam !== this.props.currentTeam) {
      nextProps.currentTeam &&
        nextProps.currentTeam.guid &&
        this.props.fetchTeamManagerList(
          nextProps.currentTeam.guid,
          (managerList) => {
            this.setState({
              managerList,
            })
          },
          (t) => {
            this.setState({ fetchReaAgencyIdApiError: t })
          },
        )
      nextProps.currentTeam &&
        this.props.fetchProperties(nextProps.currentTeam.guid)
      nextProps.currentTeam &&
        this.props.setBackUrl(
          urlTo('portfolioOverview', { teamSlug: nextProps.currentTeam.slug }),
        )
      nextProps.currentTeam &&
        nextProps.currentTeam.guid &&
        this.props.fetchReaAgencyId(
          nextProps.currentTeam.guid,
          (reaAgencyId) => {
            this.setState({ reaAgencyId })
          },
          (t) => {
            this.setState({ fetchReaAgencyIdApiError: t })
          },
        )
    }
  }

  closeInviteModal = () => {
    this.setState({ inviteModalOpen: false })
  }

  closeVideoModal = () => {
    this.setState({ videoModalOpen: false })
  }

  focusInvite = () => {
    this.inviteInput.focus()
  }

  openInviteModal = () => {
    this.setState({ inviteModalOpen: true })
  }

  openVideoModal = () => {
    this.setState({ videoModalOpen: true })
  }

  updateReaListings = () => {
    const { updateReaListings, currentAgency } = this.props
    const { reaAgencyId } = this.state
    const { REAQuery } = this.state
    this.setState({ reaButtonClicked: true, reaListingApiError: null })
    updateReaListings(
      Object.assign({}, REAQuery, {
        agencyGUID: currentAgency.agencyID,
        filters: {
          ...REAQuery.filters,
          agencyIds: [reaAgencyId],
        },
      }),
      () => {
        this.setState({ reaButtonClicked: false })
      },
      (t) => {
        this.setState({ reaButtonClicked: false })
        this.setState({ reaListingApiError: t })
      },
    )
  }

  render() {
    const { currentUser, bondCovers = [] } = this.props
    const {
      videoModalOpen,
      reaButtonClicked,
      reaAgencyId,
      fetchReaAgencyIdApiError,
      reaListingApiError,
      managerList,
    } = this.state

    const teamSlug =
      (this.props.match &&
        this.props.match.params &&
        this.props.match.params.teamSlug) ||
      ''
    const currentTeam = this.props.teams.find(
      (option) => option.slug === teamSlug,
    )
    const showREAButton =
      this.props.currentTeam &&
      this.props.currentTeam.guid &&
      reaAgencyId &&
      (currentUser.roles.includes('super') ||
        currentUser.roles.includes('admin'))
    return (
      <div>
        <div className="col-first">
          {/* Welcome message */}
          <div className="renter-welcome">
            <h3 className="pb10">
              Portfolio Manager: {currentTeam ? currentTeam.name : ''}
            </h3>
            {/* {
              bondCovers.length === 0 &&
              <div className="renter-dashboard-video">
                <iframe
                  src="https://drive.google.com/file/d/0B_X3M9Dw0d84dmdpa3dnU1BWWm8/preview"
                  height="100%"
                  width="100%"
                  frameBorder="0"
                ></iframe>
              </div>
            } */}
          </div>

          {/* Invite */}
          {/* <div className="renter-invite-message mb10">
            {
              bondCovers.length === 0 &&
              <h3 className="pb10">Get started, invite your renters</h3>
            }
            <p className="mb0">Recognise good renting. Invite your renters and send a $25 discount.</p>
          </div>
          <BondCoverReferral
            inviterName={`${currentUser.firstName} ${currentUser.lastName}`}
            inviterEmail={currentUser.email}
            role={'manager'}
          /> */}

          {/* BondCover Certificates */}
          {/* <div className="table pt30 mb35">
            <div className="cell-m pr15">
              <img src={filesListIcon} height="32" />
            </div>
            <div className="cell-m-100">
                <h5 className="fs20">BondCover Certificates ({bondCovers.length})</h5>
            </div>
           </div>

          {
            bondCovers.length === 0 &&
            <p>
              You don’t currently have any certificates to approve. Invite your renters to get started.
            </p>
           }
          {bondCovers.map(i => (<DashboardItem
            friendlyName={i.address.friendlyName}
            suburb={i.address.suburb}
            state={i.address.state}
            postcode={i.address.postcode}
            leaseStart={i.startDate}
            leaseEnd={i.endDate}
            request={i.request}
            role={i.role}
            requestID={i.requestID}
            key={i.requestID}
            tenant={i.tenant}
          />))}
          {
            bondCovers.length > 0 &&
            <div className="dashboard-two-line-wrapper">
              <p className="dashboard-two-line-element">
                Are you looking to apply for BondCover?&nbsp;
              </p>
              <p className="dashboard-two-line-element">
                <Link to="/home/overview">Go to My Home -></Link>
              </p>
            </div>
          }
          {
            bondCovers.length > 0 && currentAgency && currentAgency.agencyID &&
            <div className="dashboard-two-line-wrapper mt20">
              <p className="dashboard-two-line-element">
                Are you looking for your Agency tools?&nbsp;
              </p>
              <p className="dashboard-two-line-element">
                <Link to={`/agency/${currentAgency.agencyID}/bc/dashboard`}>Go to My Agency -></Link>
              </p>
              </div>
          } */}

          {videoModalOpen && (
            <VideoModal closeVideoModal={this.closeVideoModal} />
          )}

          <div className="sm-property-dashboard">
            <div className="sm-property-dashboard-content">
              <ul>
                <li>
                  <header>
                    <h4>
                      <i className="icon-applications"></i>
                      Find the best tenant
                    </h4>
                  </header>
                  <main>
                    <p>
                      Match is a free and easy rental application form that
                      helps you find and select the best renter.&nbsp;
                    </p>
                  </main>
                  <footer>
                    <p>
                      <Link target="_blank" to={`${websiteUrl}/match/manager`}>
                        Find out more
                      </Link>
                    </p>
                    <div className="mb0 btn btnpad btn-lh">
                      <Link to={urlTo('propertyGetStarted', { teamSlug })}>
                        Get started, add a property
                      </Link>
                    </div>
                  </footer>
                </li>

                {/* Invite */}
                {this.state.inviteModalOpen && (
                  <div className="modal-fullscreen">
                    <div className="modal-wrapper renter-dashboard-modal-wrapper pb30">
                      <div className="modal-close">
                        <i
                          className="fa fa-times"
                          onClick={() => this.closeInviteModal()}
                        />
                      </div>
                      <div className="modal-header no-bottom-border">
                        <h4 className="mb15">Invite your renter</h4>
                        <p className="mb0">
                          We’ll send them a promotion code via email.
                        </p>
                      </div>

                      <BondCoverReferral
                        inviterName={`${currentUser.firstName} ${currentUser.lastName}`}
                        inviterEmail={currentUser.email}
                        role="manager"
                      />
                    </div>
                  </div>
                )}

                {/* BondCover Applications */}
                {this.props.currentTeam && this.props.currentTeam.guid && (
                  <li>
                    <header>
                      <h4>
                        <i className="icon-bondcover"></i>
                        BondCover
                      </h4>
                    </header>
                    <main>
                      <h3>{bondCovers.length}&nbsp;</h3>
                      {`application${bondCovers.length > 1 ? 's' : ''}`}
                    </main>
                    <footer className="dashboard-tile-footer">
                      {bondCovers.length ? (
                        <p>
                          <Link to={urlTo('managerDashboard', { teamSlug })}>
                            View &amp; Manage
                          </Link>
                        </p>
                      ) : (
                        <p>
                          <Link to={urlTo('managerDashboard', { teamSlug })}>
                            Manage applications
                          </Link>
                        </p>
                      )}
                    </footer>
                  </li>
                )}
                <li>
                  <header>
                    <h4>
                      <i className="icon-help-outline"></i>
                      Need help?
                    </h4>
                  </header>
                  <main>
                    <p>
                      Please visit our{' '}
                      <Link target="_blank" to="https://snug.com/help">
                        Help Centre
                      </Link>
                      , get in touch at{' '}
                      <a href="mailto:hello@snug.com">hello@snug.com</a> or{' '}
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a onClick={() => toggleChat()}>chat with our team</a>.
                    </p>
                  </main>
                </li>
                {FeatureFlag.BondCover.isOn ? (
                  <li>
                    <header>
                      <h4>
                        <i className="icon-info-outline"></i>
                        About BondCover
                      </h4>
                    </header>
                    <main>
                      <p>
                        BondCover replaces bonds, same protection, faster
                        claims, indexed cover, no cost to owners / agents.
                      </p>
                    </main>
                    <footer>
                      <p>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={this.openVideoModal}>Find out more</a>
                      </p>
                      <p>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={() => this.openInviteModal()}>
                          Invite your renters
                        </a>
                      </p>
                    </footer>
                  </li>
                ) : (
                  <li className="opacity-none"></li>
                )}
              </ul>
            </div>
          </div>

          <div className="mt30">
            <div className="dashboard-two-line-wrapper">
              <p className="dashboard-two-line-element">
                {FeatureFlag.BondCover.isOn
                  ? 'Are you looking to apply for BondCover or a property?'
                  : 'Are you looking to apply for a property?'}{' '}
                &nbsp;
              </p>
              <p className="dashboard-two-line-element">
                <Link to="/home/overview">Go to My Home -{'>'}</Link>
              </p>
            </div>
            {this.props.currentTeam && this.props.currentTeam.guid && (
              <div className="dashboard-two-line-wrapper">
                <p className="dashboard-two-line-element">
                  Are you looking to manage your own properties? &nbsp;
                </p>
                <p className="dashboard-two-line-element">
                  <Link to={urlTo('portfolioOverview')}>
                    Go to My Portfolio -{'>'}
                  </Link>
                </p>
              </div>
            )}
          </div>
          {showREAButton && (
            <div>
              <button
                disabled={reaButtonClicked || fetchReaAgencyIdApiError}
                className="mb25"
                onClick={this.updateReaListings}
              >
                Refresh Property Import
                <i
                  className={reaButtonClicked ? 'fa fa-spinner fa-pulse' : ''}
                />
              </button>
              {reaListingApiError && (
                <div className="alert alert-danger">{reaListingApiError}</div>
              )}
            </div>
          )}

          <Properties managerList={managerList} />
        </div>
      </div>
    )
  }
}

export default ManagerDashboardContainer
