import $ from 'jquery'

import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const now = Date.now().toString()

export const searchRequests = (
  searchInput,
  nextPageFirstItemId,
  success,
  error,
) => {
  let { searchText, searchType } = searchInput
  let queryStr =
    nextPageFirstItemId == 0
      ? 'searchText=' + searchText + '&searchType=' + searchType
      : 'searchText=' +
        searchText +
        '&searchType=' +
        searchType +
        '&cursor=' +
        nextPageFirstItemId
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}admin/requests/search?${queryStr}`,
    success,
    error,
  })
}

export const getRequestSummary = (guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}admin/requests/${guid}`,
    success,
    error,
  })
}

export const reopenApplication = (appCode, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${apiBaseUrl}admin/requests/${appCode}/reopen`,
    success,
    error,
  })
}

export const releaseApplication = (guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${apiBaseUrl}admin/request/submit/${guid}`,
    success,
    error,
  })
}

export const adminDecline = (data, appCode, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}admin/requests/${appCode}/decline`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const resendApplication = (appCode, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${apiBaseUrl}admin/requests/${appCode}/resend`,
    success,
    error,
  })
}

export const approveApplication = (appCode, data, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${apiBaseUrl}admin/requests/${appCode}/approve`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const releaseBackToRenter = (guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${apiBaseUrl}admin/notify/renter/${guid}`,
    success,
    error,
  })
}

export const releaseToIDCheck = (guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${apiBaseUrl}admin/check/renter/${guid}`,
    success,
    error,
  })
}

export const processPayment = (requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${apiBaseUrl}admin/requests/${requestID}/pay`,
    success,
    error,
  })
}

export const releaseToCertificate = (guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${apiBaseUrl}admin/notify/renteridcheck/${guid}`,
    success,
    error,
  })
}

export const fetchApplicationList = (nextPageFirstItemId, success, error) => {
  let url =
    nextPageFirstItemId == 0
      ? `${apiBaseUrl}admin/requests`
      : `${apiBaseUrl}admin/requests?cursor=${nextPageFirstItemId}`
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    contentType: 'application/json',
    url: url,
    success,
    error,
  })
}

export const addPricingVariation = (requestId, payload) => {
  const strip = (p) =>
    Object.assign({}, p, {
      value: p.amount,
    })
  const url = `${apiBaseUrl}admin/requests/${requestId}/pricingvariations`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(strip(payload)),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deletePricingVariation = (requestId, pricingVaritionId) => {
  const url = `${apiBaseUrl}admin/requests/${requestId}/pricingvariations/${pricingVaritionId}`

  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (registerViewing) => ({ ok: true, registerViewing }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
