import React from 'react'

import 'app/sm/viewings_new_run/property_item/style.scss'
import moment from 'moment'
import DateTime from 'react-datetime'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import carIcon from 'app/assets/icons/car.png'
import exclamation from 'app/assets/icons/exclamation.png'
import markerIcon from 'app/assets/icons/map-marker-pink.png'
import walkingIcon from 'app/assets/icons/walking.png'
import { Flex } from 'app/components/design-system-components'
import { MailOutlineRounded } from 'app/components/design-system-components/icons/communications'
import FormIcon from 'app/components/design-system-components/icons/snug-specific/Form'
import { PeopleOutlineRounded } from 'app/components/design-system-components/icons/social'
import * as Display from 'app/components/display/display'
import { Dropdown } from 'app/components/forms/forms'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  MODE_DRIVING_NEW_VERSION,
  MODE_WALKING_NEW_VERSION,
} from 'app/pages/teams/viewings-run/components/schedule-list'
import StyledViewingDurationAndDropdown from 'app/pages/teams/viewings-run/components/styled/viewing-duration-dropdown'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import {
  formatRent,
  getCarSpaceCountForProperty,
  NO_IMAGE_PLACEHOLDER_URL,
  PropertyStatuses,
  urlTo,
} from 'app/sm/helpers'
import LateForViewingModal from 'app/sm/inspections/components/late_for_viewing_modal'
import { ApplicationWorkflowStatusLabel } from 'app/sm/properties/components/property_search_filter_util'
import {
  modeDriving,
  modeWalking,
} from 'app/sm/viewings_new_run/schedule_list/component'

const viewingTypes = [
  {
    guid: true,
    name: 'Open',
  },
  {
    guid: false,
    name: 'Private',
  },
]

export const PropertyItemListContainer = ({ children }) => {
  return <div className="new-run-property-list">{children}</div>
}

const genGoogleMapUrlQuery = (destinationAddress, travelMode) => {
  let sb = 'https://www.google.com/maps/dir/?api=1'
  if (destinationAddress.googleId) {
    sb += `&destination_place_id=${destinationAddress.googleId}`
  }
  const destination = `${destinationAddress.friendlyName}, ${destinationAddress.suburb} ,${destinationAddress.state}`
  sb += `&destination=${encodeURIComponent(destination)}`

  sb += `&travelmode=${travelMode.toLowerCase()}`
  return sb
}

const StyledStatsContainer = styled(Flex)`
  margin-top: 24px;
  justify-content: start;
  width: 210px;

  @media screen and (max-width: 576px) {
    width: 20%;
    margin-left: 40px;
    flex: 1;
    flex-direction: column;
  }
`

const StyledStatUnit = styled(Flex)`
  margin-right: 18px;

  svg {
    position: relative;
    width: 20px;
    height: 16px;
  }
`

const ReactTooltipWrapper = styled.div`
  .__react_component_tooltip {
    min-width: 139px;
    min-height: 26px;
    border-radius: 4px;
    text-align: center !important;
    background-color: ${theme.colors.gray500} !important;
  }

  .__react_component_tooltip::after {
    border-top-color: ${theme.colors.gray500} !important;
  }
`

class PropertyItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLateModalOn: false,
      hasError: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.errorPropertyGUID !== this.props.errorPropertyGUID &&
      this.props.propertyGUID === this.props.errorPropertyGUID
    ) {
      this.setState({
        hasError: true,
      })
    }
    if (
      prevProps.errorPropertyGUID !== this.props.errorPropertyGUID &&
      this.props.errorPropertyGUID === ''
    ) {
      this.setState({
        hasError: false,
      })
    }
  }

  onNotifyLateViewing = (viewingGUID) => {
    return (data) =>
      this.props
        .notifyLateViewing(data, viewingGUID)
        .then(() => {
          snugNotifier.success('Successfully requested late SMS')
          this.setState({ isLateModalOn: false })
        })
        .catch((error) => {
          snugNotifier.error(error)
        })
  }

  onShowLateViewingModal = () => {
    this.setState({ isLateModalOn: !this.state.isLateModalOn })
  }

  clearError = () => {}

  renderStats() {
    const { propertyInfo, showStats = false } = this.props

    if (!showStats) return null

    const {
      enquiries_count: enquiriesCount = propertyInfo.eoiCount || 0,
      viewing_registrants_count:
        viewingRegistrantsCount = propertyInfo.viewedCount || 0,
      applications_count: applicationsCount = propertyInfo.applicationsCount ||
        0,
      most_progressed_application_status:
        mostProgressedApplicationStatus = propertyInfo.mostProgressedApplicationStatus ||
          0,
    } = propertyInfo.property || {}

    return (
      <Flex justifyContent="flex-end" width="254px">
        <StyledStatsContainer>
          <ReactTooltipWrapper>
            <ReactTooltip id="messages-tip" data-type="info">
              <span>Messages</span>
            </ReactTooltip>
          </ReactTooltipWrapper>

          <StyledStatUnit
            data-tooltip-id="messages-tip"
            data-tooltip-content=""
          >
            <MailOutlineRounded /> {enquiriesCount}
          </StyledStatUnit>

          <ReactTooltipWrapper>
            <ReactTooltip id="attendees-tip" data-type="info">
              <span>Attendees to date</span>
            </ReactTooltip>
          </ReactTooltipWrapper>

          <StyledStatUnit
            data-tooltip-id="attendees-tip"
            data-tooltip-content=""
          >
            <PeopleOutlineRounded /> {viewingRegistrantsCount}
          </StyledStatUnit>

          <ReactTooltipWrapper>
            <ReactTooltip id="applications-tip" data-type="info">
              <span>Applications Received</span>
            </ReactTooltip>
          </ReactTooltipWrapper>

          <StyledStatUnit
            data-tooltip-id="applications-tip"
            data-tooltip-content=""
          >
            <FormIcon /> {applicationsCount}
          </StyledStatUnit>
        </StyledStatsContainer>

        <Flex minWidth="105px" mr="18px">
          <ApplicationWorkflowStatusLabel
            applicationStatus={mostProgressedApplicationStatus}
          />
        </Flex>
      </Flex>
    )
  }

  render() {
    const { hasError } = this.state
    const {
      propertyInfo = {},
      onAddButtonClicked = {},
      addAgain = false,
      onDeleteButtonClicked,
      status = 'select',
      onEditClicked,
      onViewingStartTimeChanged = () => {},
      onViewingDurationChanged = () => {},
      onBreakDurationChanged,
      onChangeViewingType,
      markerLabel,
      isReordering,
      conflicted = [],
      sameDayOrfutureViewingInfos = [],
      isReadOnly,
      // used for read-only preview
      teamSlug,
      viewingRunNewVersion = false,
      contentForChangeButton,
      managerGUID,
      propertyGUIDNewVersion,
      enableAddAgain = true,
      isEditContainer = false,
      onEditButtonClicked,
    } = this.props

    const {
      property = {},
      activeOffer = {},
      startTime = '',
      viewingDuration = '',
      breakDuration = '',
      travelDuration = '',
      travelMode,
      needTravelToNext,
      editable = false,
      viewingGUID,
      viewingsPublished,
    } = propertyInfo

    const displayImage =
      property.images && property.images.length
        ? property.images.find((image) => image.isMain) || property.images[0]
        : {}
    const {
      address = {},
      bathrooms = 0,
      bedrooms = 0,
      garages = 0,
      parking = 0,
      carports = 0,
      guidID: propertyGUID,
    } = property
    const carCountSpaces = getCarSpaceCountForProperty({
      garages,
      parking,
      carports,
    })
    const { friendlyName = '', suburb = '' } = address
    const displayAddress = address ? `${friendlyName}, ${suburb}` : ''
    const { weeklyRentDisplay = '', availableFrom } = activeOffer || {}
    const formattedRent = formatRent(weeklyRentDisplay)
    const durationDisplay =
      breakDuration > 0
        ? `${viewingDuration} + ${breakDuration} min`
        : `${viewingDuration} min`
    const optionsForContextMenu =
      contentForChangeButton &&
      contentForChangeButton.map((content) => {
        return {
          text: content.label,
          enableTool: true,
          onClick: () => {
            content.destinationManagerGUID
              ? content.action(
                  managerGUID,
                  propertyGUIDNewVersion,
                  content.destinationManagerGUID,
                )
              : content.action(managerGUID, propertyGUIDNewVersion)
          },
        }
      })
    const timeConstraints = viewingRunNewVersion
      ? {
          minutes: {
            step: 5,
          },
        }
      : {}

    const isTravelModeDriving =
      travelMode === modeDriving || travelMode === MODE_DRIVING_NEW_VERSION
    const isTravelModeWaling =
      travelMode === modeWalking || travelMode === MODE_WALKING_NEW_VERSION

    return (
      <div
        className="pt5 pb5"
        style={hasError ? { backgroundColor: 'rgba(255, 8, 8, 0.1)' } : {}}
      >
        <div className="viewing-run-property-item">
          <StyledViewingDurationAndDropdown>
            <>
              {status === 'scheduled' && (
                <div className="schedule-section">
                  <div className="display-flex">
                    <div className="width100 display-flex justify-content-center align-items-center flex-direction-column">
                      {markerLabel && (
                        <div>
                          <img src={markerIcon} alt="map-marker" />
                          <span className="map-marker-text">{markerLabel}</span>
                        </div>
                      )}
                      {/* viewing start time displayer */}
                      {!editable && (
                        <div className="">
                          {startTime && moment(startTime).format('h:mm A')}
                        </div>
                      )}
                      {/* viewing start time updater */}
                      {!isReadOnly && editable && (
                        <div>
                          <DateTime
                            dateFormat={false}
                            timeFormat="hh:mm A"
                            inputProps={{
                              placeholder: 'start',
                              className: `timePickerStyle`,
                            }}
                            onChange={onViewingStartTimeChanged}
                            value={
                              startTime && moment(startTime).format('hh:mm A')
                            }
                            timeConstraints={timeConstraints}
                          />
                        </div>
                      )}
                      <div className="display-flex mt5 align-items-center">
                        {/* viewing duration displayer */}
                        {!editable && viewingDuration && (
                          <div className="font-size-14 color-grey">
                            {durationDisplay}
                          </div>
                        )}

                        {/* viewing duration updater */}
                        {!isReadOnly && editable && (
                          <div className="display-flex flex-direction-column align-items-center">
                            <div>
                              <input
                                className="timePickerStyle duration-input"
                                onChange={onViewingDurationChanged}
                                value={viewingDuration}
                              />{' '}
                              (V)
                            </div>
                            +
                            <div>
                              <input
                                className="timePickerStyle duration-input"
                                onChange={onBreakDurationChanged}
                                value={breakDuration}
                              />{' '}
                              (B)
                            </div>
                          </div>
                        )}

                        {/* edit/save button */}
                        {!isReadOnly && onEditClicked && startTime && (
                          <div
                            className="blue-link-style font-size-12 ml10"
                            onClick={onEditClicked}
                          >
                            {editable ? 'save' : 'edit'}
                          </div>
                        )}
                      </div>

                      {!isReordering && needTravelToNext && (
                        <div className="transit-time-section">
                          <i className="fa fa-ellipsis-v" />
                          <div className="display-flex">
                            {isTravelModeDriving && (
                              <img
                                className="width20px height20px"
                                src={carIcon}
                                alt="car-icon"
                              />
                            )}
                            {isTravelModeWaling && (
                              <img
                                className="width20px height20px"
                                src={walkingIcon}
                                alt="walking-icon"
                              />
                            )}
                            {!viewingRunNewVersion && (
                              <span className="ml10">{travelDuration}</span>
                            )}
                          </div>
                          <i className="fa fa-ellipsis-v" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
            <>
              {!editable && viewingDuration && (
                <Dropdown
                  label="Year"
                  value={viewingsPublished}
                  options={viewingTypes}
                  onChange={onChangeViewingType}
                  id="chosenYear"
                  inputClass="width100"
                  componentClass="margin-profile-item-left"
                  isRequired={true}
                  idAsValue={true}
                  selectIsDisabled={isReadOnly}
                />
              )}
            </>
          </StyledViewingDurationAndDropdown>

          <div className="all-except-schedule-section">
            <div className="info-row">
              <div className="property-info-section">
                <div className="image-container">
                  {property.status === PropertyStatuses.offMarket && (
                    <div className="position-absolute status-draft-tag">
                      <span className="tag-text">Off Market</span>
                    </div>
                  )}
                  <img
                    src={displayImage.URL || NO_IMAGE_PLACEHOLDER_URL}
                    alt="Property"
                  />
                </div>
                <div className="info-container">
                  <div className="address">{displayAddress || 'no name'}</div>
                  <div className="display-flex mt5">
                    <div className="icon-container mr5">
                      <i className="icon-bedroom"></i>
                      <span>{bedrooms || 0}</span>
                    </div>
                    <div className="icon-container mr5">
                      <i className="icon-bathroom"></i>
                      <span>{bathrooms || 0}</span>
                    </div>
                    <div className="icon-container mr5">
                      <i className="icon-cars"></i>
                      <span>{carCountSpaces}</span>
                    </div>
                    <div className="icon-container mr5">
                      <i className="icon-rent"></i>
                      <span>{formattedRent}</span>
                    </div>
                  </div>
                  <div className="display-flex mt5 flex-wrap">
                    <div className="icon-container">
                      <i className="icon-calendar"></i>
                      <span>
                        {moment(availableFrom).format('DD MMM YYYY') ||
                          'date not available'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* only show latest viewing time in select mode or show conflicts */}
              <div className="extra-info-section">
                {conflicted.length === 0 &&
                  sameDayOrfutureViewingInfos.length !== 0 && <img alt="" />}
                {conflicted.length === 0 &&
                  sameDayOrfutureViewingInfos.length !== 0 && (
                    <div className="text-section">
                      <div>{`Scheduled: ${moment(
                        sameDayOrfutureViewingInfos[0].viewing.startDate,
                      ).format('DD MMM hh:mm A')}`}</div>
                    </div>
                  )}
                {conflicted.length !== 0 && <img src={exclamation} alt="" />}
                {conflicted.length !== 0 && (
                  <div className="text-section">
                    <div>Conflict</div>
                    <div>{`Scheduled: ${moment(
                      conflicted[0].viewing.startDate,
                    ).format('DD MMM hh:mm A')}`}</div>
                  </div>
                )}
              </div>

              {this.renderStats()}

              {status === 'select' && (
                <div className="add-remove-section">
                  {enableAddAgain && (
                    <button
                      className="btn hollow-button-green p0 mb5"
                      onClick={() => onAddButtonClicked(propertyInfo)}
                    >
                      {addAgain ? 'Add again' : 'Add'}
                    </button>
                  )}
                  {!enableAddAgain && (
                    <button
                      className="btn hollow-button-green p0 mb5"
                      disabled={addAgain}
                      onClick={() => onAddButtonClicked(propertyInfo)}
                    >
                      {addAgain ? 'Added' : 'Add'}
                    </button>
                  )}
                </div>
              )}

              {!isReadOnly &&
                !viewingRunNewVersion &&
                !isEditContainer &&
                (status === 'scheduled' || status === 'selected') && (
                  <div className="add-remove-section">
                    <button
                      className="btn hollow-button-green p0 mb5"
                      onClick={onDeleteButtonClicked}
                    >
                      Remove
                    </button>
                  </div>
                )}

              {viewingRunNewVersion && !isReadOnly && (
                <div className="add-remove-section">
                  <Display.ToolsButton
                    tools={optionsForContextMenu}
                    componentClass="mr40"
                  />
                </div>
              )}

              {isEditContainer && (
                <div className="add-remove-section">
                  {' '}
                  <button
                    className="btn hollow-button-green p0 mb5"
                    onClick={onEditButtonClicked}
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>

            <div className="link-row">
              {isReadOnly && (
                <div className="viewing-action-section">
                  <a
                    className="blue-link-style ml10"
                    href={`${genGoogleMapUrlQuery(address, travelMode)}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Directions
                  </a>
                  <div
                    className="blue-link-style ml10"
                    onClick={() =>
                      history.push(
                        `${urlTo('teamsViewing', {
                          teamSlug,
                        })}&property=${propertyGUID}`,
                      )
                    }
                  >
                    Manage
                  </div>
                  <div
                    className="blue-link-style ml10"
                    onClick={this.onShowLateViewingModal}
                  >
                    Late
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.isLateModalOn && (
          <LateForViewingModal
            showLateViewingModal={this.onShowLateViewingModal}
            notifyLateViewing={this.onNotifyLateViewing(viewingGUID)}
            clearError={this.clearError}
          />
        )}
      </div>
    )
  }
}

export default PropertyItem
