import { connect } from 'react-redux'

import {
  fetchRequestSummary,
  getSecondaryTenantSummary,
} from 'app/bond_cover/bond_cover_actions'
import RenterSubmission from 'app/bond_cover/renters/status/request_in_progress'

const mapStateToProps = ({ bondCover, session }) => ({
  apiError: bondCover.error,
  currentUser: session.currentUser,
  bondCoverRequest: bondCover.request,
  currentLease: bondCover.currentLease,
  tenants: bondCover.tenants,
})

const mapDispatchToProps = (dispatch) => ({
  fetchRequestSummary: (requestID) => fetchRequestSummary(requestID),
  getSecondaryTenantSummary: (requestID) =>
    getSecondaryTenantSummary(requestID),
})

export default connect(mapStateToProps, mapDispatchToProps)(RenterSubmission)
