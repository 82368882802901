import {
  ACCEPT_TERMS_CONDITIONS_CHANGE,
  CARD_SELECT,
  CARDS_RECEIVED,
  CLEAR_ERROR,
  PING,
  RECEIVE_ERROR,
  RECEIVE_OFFERED_APPLICATION,
} from 'app/sm/ph_deposit/ph_deposit_actions'

const _defaultState = {
  cards: [],
  acceptTermsAndConditions: false,
  selectedCreditCard: '', // The Id from the option just goes there
  offeredApplication: {
    Application: {},
    Events: [],
  },
  error: '',
  attempts: 0,
}

const PhDeposit = (state = _defaultState, action) => {
  let { type, cards, offeredApplication, selected, error } = action

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case PING:
      return Object.assign(newState, { error: '' })
    case RECEIVE_ERROR:
      let attempts = state.attempts + 1
      return Object.assign(newState, { error, attempts })
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    case CARDS_RECEIVED:
      return Object.assign(newState, { cards })
    case RECEIVE_OFFERED_APPLICATION:
      return Object.assign(newState, { offeredApplication })
    case CARD_SELECT:
      return Object.assign(newState, { selectedCreditCard: selected })
    case ACCEPT_TERMS_CONDITIONS_CHANGE:
      return Object.assign(newState, {
        acceptTermsAndConditions: !newState.acceptTermsAndConditions,
      })
    default:
      return newState
  }
}

export default PhDeposit
