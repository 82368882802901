import { Modal } from 'app/components/design-system-components'
import type { StatusItemProps } from 'app/pages/teams/messages/components/EnquiryAutoResponderContent'
import EnquiryAutoResponderContent from 'app/pages/teams/messages/components/EnquiryAutoResponderContent'

interface EnquiryAutoResponderModalProps {
  teamStatusData: Array<StatusItemProps>
  teamID: string
  toggleModal: Function
  getTeamStausData: Function
  isUserAdmin: boolean
}

function EnquiryAutoResponderModal({
  toggleModal,
  teamStatusData,
  getTeamStausData,
  teamID = '',
  isUserAdmin = false,
}: EnquiryAutoResponderModalProps) {
  return (
    <Modal
      modalBody={
        <EnquiryAutoResponderContent
          teamStatusData={teamStatusData}
          getTeamStausData={getTeamStausData}
          teamID={teamID}
          isUserAdmin={isUserAdmin}
        />
      }
      modalFooter={undefined}
      primaryLabel={undefined}
      primaryAction={undefined}
      secondaryLabel={undefined}
      secondaryAction={undefined}
      toggleModal={toggleModal}
      customModalFooter={true}
      modalHeading="Enquiry Auto Responder"
    />
  )
}

export default EnquiryAutoResponderModal
