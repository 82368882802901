import { parseViewing } from 'app/sm/helpers'
import {
  CANCEL_MODAL,
  CLEAR_ERROR,
  RECEIVE_ERROR,
  RECEIVE_PROPERTY,
  RECEIVE_PROPERTY_SUMMARY,
  RECEIVE_VIEWINGS,
  REVEIVE_POTENTIAL_VIEWINGS,
  SHOW_RSVP_SPINNER,
  TOGGLE_FAVORITE_MARK,
  TRIGGER_MODAL,
} from 'app/sm/property_details/property_details_actions'

const _defaultState = {
  property: {
    address: {},
    reviews: [],
    questions: [],
    images: [],
    agent: {},
  },
  summary: {
    property: {},
    manager: {},
    viewings: [],
  },
  viewings: [],
  authenticationModal: false,
  shouldRender: false,
  error: '',
  rsvpSpinnerGuidArray: [],
  potentialViewings: [],
}

const PropertyDetailsReducer = (state = _defaultState, action) => {
  let {
    type,
    property,
    summary,
    viewings,
    error,
    rsvpSpinnerGuidArray,
    potentialViewings,
  } = action

  if (type !== RECEIVE_ERROR) {
    error = ''
  }

  Object.freeze(state)

  let newState = Object.assign({}, state)

  switch (type) {
    case RECEIVE_ERROR:
      return Object.assign(newState, { error })
    case TRIGGER_MODAL:
      return Object.assign(newState, { authenticationModal: true })
    case CANCEL_MODAL:
      return Object.assign(newState, { authenticationModal: false })
    case RECEIVE_PROPERTY:
      return Object.assign(newState, { property, shouldRender: true })
    case TOGGLE_FAVORITE_MARK:
      return Object.assign(newState, {
        property: Object.assign({}, newState.property, {
          isFavourite: !newState.property.isFavourite,
        }),
      })
    case RECEIVE_PROPERTY_SUMMARY:
      return Object.assign(newState, { summary })
    case RECEIVE_VIEWINGS:
      return Object.assign(newState, {
        viewings: viewings
          .map((V) =>
            Object.assign({}, V.viewing, {
              registered: V.registered,
              isFullyBooked: V.isFullyBooked,
            }),
          )
          .map(parseViewing),
        error: '',
      })
    case REVEIVE_POTENTIAL_VIEWINGS:
      return Object.assign(newState, {
        potentialViewings:
          potentialViewings &&
          potentialViewings
            .map((V) => Object.assign({}, V, { registered: false }))
            .map(parseViewing),
      })
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    case SHOW_RSVP_SPINNER:
      return Object.assign(newState, { rsvpSpinnerGuidArray })
    default:
      return state
  }
}

export default PropertyDetailsReducer
