import React from 'react'

import $ from 'jquery'

import BottomButton from 'app/bond_cover/renters/sharedComponent/bottom_buttons_connection'
import * as Display from 'app/components/display/display'
import BottomContentContainer from 'app/shared_components/layout_component/bottom_content_component/component'
import CenterContentContainer from 'app/shared_components/layout_component/center_content_component/component'
import getParameter from 'app/shared_components/parameter_parser'
import { urlTo } from 'app/sm/helpers'

import 'app/profile/profile_build_success/style.scss'

const ProfileSuccessItems = [
  {
    key: 'essentials',
    header: "🙌 Nice! You've added the essentials!",
    text: 'Now let’s add some supporting information… Click Save & Continue',
    nextStage: 'supporting',
  },
  {
    key: 'supporting',
    header: '👍 Awesome! Supporting information done!',
    text: 'Almost there, just a few extra details to go… Click Save & Continue',
    nextStage: 'final',
  },
  {
    key: 'final',
    header: '🎉 Your application is ready to review!',
    text: 'You can always come back later and update your information. Click Save & Continue to review your application',
  },
]

const ProfileSuccessNextURL = (item, applicationId = '') => {
  const nextURLs = {
    essentials: {
      nextURL: urlTo('profileAvatar'),
    },
    supporting: {
      nextURL: urlTo('emergencyContactDetails'),
    },
    final: {
      nextURL: urlTo('applicationSummary', { applicationId }),
    },
  }
  return nextURLs[item].nextURL
}

class ProfileBuildSuccess extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    $('#index').addClass('no-navbar-menu')
    const { fetchCompleteness } = this.props
    fetchCompleteness()
  }

  componentWillUnmount() {
    $('#index').removeClass('no-navbar-menu')
  }

  render() {
    const addressName = getParameter('address')
    const fromStage = getParameter('stage')

    const applicationID = getParameter('applicationId')
    const currentItem = ProfileSuccessItems.find(
      (item) => item.key === fromStage,
    )
    const currentUrlParams = new URLSearchParams(window.location.search)
    let nextUrl
    if (currentItem && currentItem.nextStage) {
      currentUrlParams.set('stage', currentItem.nextStage)
      nextUrl = `${ProfileSuccessNextURL(
        fromStage,
        applicationID,
      )}?${currentUrlParams.toString()}`
    }

    return (
      <div>
        {addressName && currentItem && (
          <div className="profile-builder-wrapper">
            <CenterContentContainer componentClass="width100">
              <div className="profile-confetti-bg">
                <div className="profile-encouragement-text">
                  <Display.ContainerHeader
                    componentClass="profile-success-header"
                    isEditable={false}
                    text={currentItem.header}
                    textClass="text-align-center"
                  />
                  <Display.GreyBodyText
                    componentClass="pb20"
                    text={currentItem.text}
                    textClass="text-align-center"
                  />
                </div>
              </div>
            </CenterContentContainer>

            <BottomContentContainer>
              <CenterContentContainer componentClass="width100">
                <div className="submit-button-container">
                  <BottomButton
                    nextUrl={nextUrl}
                    btnText="Save &amp; Continue"
                    bottomButtonsClass="pt0 mt0 summary-bottom-button"
                    nextBtnClass="summary-green-button-class "
                    backButtonClass="summary-grey-button-class"
                    nextButtonPositionClass="pr20 pl0"
                    showArrow={false}
                  />
                </div>
              </CenterContentContainer>
            </BottomContentContainer>
          </div>
        )}
        {!currentItem && (
          <div className="alert alert-danger">
            Error processing the request (invalid parameters)
          </div>
        )}
      </div>
    )
  }
}

export default ProfileBuildSuccess
