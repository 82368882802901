import React from 'react'

import { Route } from 'react-router-dom'

import Choice from 'app/sm/alternate_owner_profile/components/choice'
import Pilot from 'app/sm/alternate_owner_profile/components/pilot'
import { urlTo } from 'app/sm/helpers'

class OwnerProfileContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  render() {
    let { fetchSliders, updateSlider } = this.props

    // if (error) {
    //   return (
    //     <div className='alert alert-danger disposable-alert'>
    //       <i onClick={() => this.props.ping()} className="fa fa-chevron-left mr15" aria-hidden="true" />{error}
    //     </div>
    //   )
    // }

    const { sliders } = this.props

    return (
      <div>
        {['/sm/properties/alternate_profile'].map((path) => (
          <div key={`${path}/create`}>
            <Route
              exact
              path={`${path}/create`}
              render={({ match }) => {
                return (
                  <Pilot
                    sliders={sliders}
                    fetchSliders={fetchSliders}
                    updateSlider={updateSlider}
                  />
                )
              }}
            />
            <Route
              exact
              path={`${path}/select`}
              render={({ match }) => {
                return <Choice />
              }}
            />
          </div>
        ))}
      </div>
    )
  }
}

export default OwnerProfileContainer
