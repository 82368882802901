import { connect } from 'react-redux'

import { logout } from 'app/session/session_access'

import RequestAppAccess from '.'

const mapStateToProps = ({ session, Shared, agency }) => ({
  currentUser: session.currentUser,
  backUrl: Shared.backUrl,
  agency: agency.agency,
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => logout(),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestAppAccess)
