import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

const logAPIError = (error) => LogFetchError(error)
const checkStatus = (response) => {
  if (response.ok) return Promise.resolve(response.json())

  return Promise.reject({
    statusCode: response.status,
    responseText: response.text(),
  })
}
const returnData = (data) => ({ ok: true, data })

const returnAPIError = (error) => ({
  ok: false,
  responseText: error.responseText,
  statusCode: error.statusCode,
  error,
})

export const getEnquirerPreference = (teamSlug, payload) => {
  const { firstName = '', lastName = '', phone = '', email = '' } = payload
  const queryString = `?firstname=${firstName}&lastname=${lastName}&phone=${encodeURIComponent(
    phone,
  )}&email=${email}`
  const url = `${apiBaseUrl}sm/agencies/${teamSlug}/renterpreference${queryString}`
  const options = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(url, options)
    .then(checkStatus)
    .then(returnData, returnAPIError)
    .catch(logAPIError)
}

export const postEnquirerPreference = (messageId, teamSlug, payload) => {
  const url = `${apiBaseUrl}sm/agencies/${teamSlug}/enquiry/${messageId}`
  const options = {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }
  return fetch(url, options)
    .then(checkStatus)
    .then(returnData, returnAPIError)
    .catch(logAPIError)
}

export const postViewingRegistrationFromEmail = (viewingID, payload) => {
  const url = `${apiBaseUrl}sm/viewing/${viewingID}/register/email`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }
  return fetch(url, options)
    .then(checkStatus)
    .then(returnData, returnAPIError)
    .catch(logAPIError)
}

export const postViewingRegistrationFromMessage = (
  viewingID,
  messageID,
  registrationData,
) => {
  const url = `${apiBaseUrl}sm/enquiry/viewings/${viewingID}/message/${messageID}`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(registrationData || {}),
    credentials: 'include',
  }
  return fetch(url, options)
    .then(checkStatus)
    .then(returnData, returnAPIError)
    .catch(logAPIError)
}

export const postViewingRegistrationFromSMSMessage = (
  viewingID,
  messageID,
  registrationData,
) => {
  const url = `${apiBaseUrl}reg/${viewingID}/${messageID}`
  const options = {
    method: 'POST',
    body: JSON.stringify(registrationData || {}),
    credentials: 'include',
  }
  return fetch(url, options)
    .then(checkStatus)
    .then(returnData, returnAPIError)
    .catch(logAPIError)
}

export const alterEquiryViewingRegistrant = (data, registrantID) => {
  const url = `${apiBaseUrl}sm/enquiry/viewings/registrant/${registrantID}`
  const payload = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    mobilePhone: data.mobilePhone,
  }
  const options = {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }
  return fetch(url, options)
    .then(checkStatus)
    .then(returnData, returnAPIError)
    .catch(logAPIError)
}

export const unregisterEnquiryViewing = (registrantID) => {
  const url = `${apiBaseUrl}sm/enquiry/viewings/registrant/${registrantID}`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }

  return fetch(url, options)
    .then(checkStatus)
    .then(returnData, returnAPIError)
    .catch(logAPIError)
}

export const getPropertyInfoByViewingId = (viewingId) => {
  const url = `${apiBaseUrl}sm/properties/viewing/${viewingId}`
  const options = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(url, options)
    .then(checkStatus)
    .then(returnData, returnAPIError)
    .catch(logAPIError)
}

export const updateViewingAttendeeConfirmation = (
  payload,
  viewingID,
  attendeeID,
) => {
  const url = `${apiBaseUrl}sm/viewing/${viewingID}/onsiteregistrant/${attendeeID}/confirmation`
  const options = {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }
  return fetch(url, options)
    .then(checkStatus)
    .then(returnData, returnAPIError)
    .catch(logAPIError)
}

export const getRegistrantInfoFromShortcode = (shortcode) => {
  const url = `${apiBaseUrl}sm/viewing/registration/confirm/${shortcode}`
  const options = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(url, options)
    .then(checkStatus)
    .then(returnData, returnAPIError)
    .catch(logAPIError)
}
