import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import { UpDownArrows } from 'app/components/design-system-components/icons/actions'
import { ReplayRounded } from 'app/components/design-system-components/icons/AV'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { resetApplicationOrder } from 'app/services/http/applications'
import * as snugNotifier from 'app/services/snugNotifier'
import { isMobile } from 'app/shared_components/helpers'

const StyledContainer = styled(Flex)`
  svg {
    width: ${theme.baseSpace * 3}px;
    height: ${theme.baseSpace * 3}px;
    color: ${theme.colors.gray400};
  }

  margin-top: ${theme.baseSpace}px;
  cursor: pointer;
  @media (min-width: 1024px) {
    margin-right: -60px;
  }

  ${(props) =>
    props.isHovered ? `visibility: visible;` : `visibility: hidden;`}
`

function DragandReset({
  isHovered,
  applicationGUID = '',
  propertyGUID = '',
  agencyGUID = '',
  onPropertyDataNeedUpdate,
  applicationNumber = 0,
}) {
  const resetTooltipId = `reset-order-${applicationGUID}`
  const onClickReset = () => {
    resetApplicationOrder(agencyGUID, propertyGUID, applicationGUID)
      .then(() => {
        onPropertyDataNeedUpdate()
      })
      .catch((error) => snugNotifier.error(error?.message))
  }

  const showReset = applicationNumber > 0
  if (isMobile()) {
    return null
  }
  return (
    <StyledContainer isHovered={isHovered}>
      <UpDownArrows />
      {showReset && (
        <>
          <Box data-tooltip-id={resetTooltipId}>
            <ReplayRounded onClick={() => onClickReset()} />
          </Box>
          <ReactTooltip
            id={resetTooltipId}
            data-type="info"
            place="top"
            className="tool-tip-style"
          >
            <Box maxWidth="156px" textAlign="center">
              Remove from manual sort
            </Box>
          </ReactTooltip>
        </>
      )}
    </StyledContainer>
  )
}

export default DragandReset
