// @ts-nocheck
import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import * as textHelpers from 'app/utils/text/helpers'

const StyledPropertyBallotsWrapper = styled(Box)`
  width: 50%;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
`

const PropertyBallots = () => {
  return (
    <StyledPropertyBallotsWrapper width="50%">
      <Box mb={5}>{textHelpers.VARC_BALLOT_TEXT_PROPERTY_PAGE}</Box>
    </StyledPropertyBallotsWrapper>
  )
}

export default PropertyBallots
