import {
  ErrorInviteAlreadyAccepted,
  ErrorInviteDoesNotExist,
  ErrorInviteNotValid,
  ErrorUserNotValid,
} from 'app/constants/error_codes'

export const FilterAny = 'Any'
export const FilterUnAssigned = 'unAssigned'

export function getErrorText(errorCode) {
  switch (errorCode) {
    case ErrorInviteNotValid:
      return 'Invite code is not valid'
    case ErrorInviteDoesNotExist:
      return 'Invite code does not exist'
    case ErrorInviteAlreadyAccepted:
      return 'Invite code is already accepted'
    case ErrorUserNotValid:
      return 'Please log in with the invitee email'
    default:
      return ''
  }
}
