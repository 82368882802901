import React from 'react'

import DateTime from 'react-datetime'

import 'app/sm/viewings_new_run/filter_options/style.scss'

// TODO: reuse it from schedule_list/component.jsx
const PreferenceOptionContainer = ({ children }) => {
  return <div className="display-flex mt20">{children}</div>
}

const FilterOptions = ({
  onDateChanged,
  date,
  managerList = [],
  onChange,
  chosenManager,
}) => {
  return (
    <div className="mt20">
      <div className="sub-title">1. Select date</div>
      <PreferenceOptionContainer>
        <div className="mr15 font-size-18 preference-label">Date</div>
        <DateTime
          dateFormat="ddd, D MMM YYYY"
          timeFormat={false}
          inputProps={{ placeholder: '', className: `datePickerStyle` }}
          onChange={onDateChanged}
          closeOnSelect={true}
          value={date.format('ddd, D MMM YYYY')}
        />
      </PreferenceOptionContainer>
      <PreferenceOptionContainer>
        <div className="mr15 font-size-18 preference-label">
          Property manager
        </div>
        <div className="input-box mb0 width120px pt0">
          <select
            className="height20px p0 preference-select"
            onChange={onChange}
            value={chosenManager}
          >
            {managerList.map((manager) => (
              <option key={manager}>{manager}</option>
            ))}
          </select>
        </div>
      </PreferenceOptionContainer>
    </div>
  )
}
export default FilterOptions
