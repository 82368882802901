import React from 'react'

import moment from 'moment'

import applicationDialogue from 'app/assets/icons/application_icon_dialogue.svg'
import { noteActionCollection, targetSectionObject } from 'app/sm/helpers'

import 'app/components/display/application_note/style.scss'

const NoteComponent = ({ note, componentClass, from = 'default' }) => {
  const now = moment()
  const end = moment(note.updatedAt)
  const duration = moment.duration(now.diff(end))
  const hours = Math.floor(duration.asHours())
  let durationText
  if (hours >= 24) {
    durationText = `${Math.floor(duration.asDays())} days ago`
  } else {
    durationText = `${hours} hours ago`
  }
  const {
    type,
    applicant = '',
    target = '',
    Metadata: metaData = '',
    assignedTeamMemberName = '',
  } = note
  const sectionName = Object.keys(targetSectionObject)[target]
  const sectionText = targetSectionObject[sectionName].text
  const matchedAction = noteActionCollection.find(
    (action) => action.type === type,
  )
  const { text: actionText = '', actionImgSrc = applicationDialogue } =
    matchedAction

  const parsedMetaData = !!metaData ? JSON.parse(metaData) : {}
  const { Images: images = [], location = '' } = parsedMetaData
  const isAssignedToTeamMember = !!assignedTeamMemberName

  return (
    <div className={`application-note-container ${componentClass}`}>
      <div className="note-header display-flex justify-content-space-between">
        <div className="display-flex flex-direction-column">
          <div>{note.author}</div>
        </div>
        <div className="note-time">{durationText}</div>
      </div>
      <div className="display-flex">
        <img
          src={actionImgSrc}
          alt="application approval"
          className="mr10 application-notes-icon"
        />
        {from === 'default' && (
          <div>
            {actionText}&nbsp;
            <strong>
              {applicant}&apos;s {sectionText} section
            </strong>
          </div>
        )}
        {from === 'propertyViewing' && (
          <div>
            <strong>
              {actionText} {location && `(${location})`}
            </strong>
          </div>
        )}
      </div>
      {note.content && (
        <div className="note-content-text">&quot;{note.content}&quot;</div>
      )}
      <div className="display-flex flex-wrap-wrap mt10">
        {images.length > 0 &&
          images.map((image, index) => (
            <div className="mb5">
              <a
                key={index}
                href={image.URL}
                target="_blank"
                rel="noopener noreferrer"
                className="img-upload-preview-container"
              >
                <img src={image.URL} alt="preview" />
              </a>
            </div>
          ))}
      </div>
      {isAssignedToTeamMember && (
        <div className="note-content-text">
          <strong className="text-black">Assigned to </strong>
          {assignedTeamMemberName}
        </div>
      )}
    </div>
  )
}

export default NoteComponent
