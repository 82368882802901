export const ADD_MANAGER_PREFERENCES = 'ADD_MANAGER_PREFERENCES'
export const UPDATE_MANAGER_PREFERENCES = 'UPDATE_MANAGER_PREFERENCES'
export const DELETE_MANAGER_PREFERENCES = 'DELETE_MANAGER_PREFERENCES'

export const ADD_MANAGER_DETAILS = 'ADD_MANAGER_DETAILS'

export const ADD_SELECTED_PROPERTY_INFOS = 'ADD_SELECTED_PROPERTY_INFOS'
export const REMOVE_SELECTED_PROPERTY_INFOS = 'REMOVE_SELECTED_PROPERTY_INFOS'

export const UPDATE_VIEWING_RUN_START_TIME = 'UPDATE_VIEWING_RUN_START_TIME'

export const REMOVE_PROPERTY_FROM_MANAGER_LIST =
  'REMOVE_PROPERTY_FROM_MANAGER_LIST'

export const UPDATE_VIEWING_RUN_READ_ONLY = 'UPDATE_VIEWING_RUN_READ_ONLY'

export const DELETE_MANAGER_DETAILS = 'DELETE_MANAGER_DETAILS'

export const DELETE_MANAGER_STATE = 'DELETE_MANAGER_STATE'

export const POPULATE_VIEWING_RUN_DATA = 'POPULATE_VIEWING_RUN_DATA'

export const RESET_VIEWING_RUN_STATE = 'RESET_VIEWING_RUN_STATE'

export const resetViewingRunState = () => ({ type: RESET_VIEWING_RUN_STATE })

export const populateViewingRunData = ({
  startTime,
  selectedProperties,
  managers,
}) => {
  return {
    type: POPULATE_VIEWING_RUN_DATA,
    runData: {
      startTime,
      selectedProperties,
      managers,
    },
  }
}

const updateManagerPreferences = (
  managerGUID,
  managerPreferenceField,
  managerPreferenceValue,
) => ({
  type: UPDATE_MANAGER_PREFERENCES,
  managerGUIDAndPreference: {
    managerGUID,
    managerPreferenceField,
    managerPreferenceValue,
  },
})

const addManagerDetails = (managerDetails, preferences) => ({
  type: ADD_MANAGER_DETAILS,
  managerDetails,
  preferences,
})

const removeManagerDetails = (managerGUID) => ({
  type: DELETE_MANAGER_DETAILS,
  managerGUID,
})

const addSelectedProperty = (property) => ({
  type: ADD_SELECTED_PROPERTY_INFOS,
  property,
})

const removeSelectedProperty = (property) => ({
  type: REMOVE_SELECTED_PROPERTY_INFOS,
  property,
})

const updateViewingRunStartTime = (time) => ({
  type: UPDATE_VIEWING_RUN_START_TIME,
  time,
})

const removeSelectedPropertyFromManager = (managerGUID, propertyGUID) => ({
  type: REMOVE_PROPERTY_FROM_MANAGER_LIST,
  managerAndPropertyInfo: {
    managerGUID,
    propertyGUID,
  },
})

const updateRunReadOnly = (isReadOnly) => ({
  type: UPDATE_VIEWING_RUN_READ_ONLY,
  isReadOnly,
})

export const clearManagerState = () => ({
  type: DELETE_MANAGER_STATE,
})

export const updateManagerPreference = (
  managerGUID,
  managerPreferenceField,
  managerPreferenceValue,
) => {
  return (dispatch) => {
    return dispatch(
      updateManagerPreferences(
        managerGUID,
        managerPreferenceField,
        managerPreferenceValue,
      ),
    )
  }
}

export const addNewManagerToRun = (managerDetails, preferences) => {
  return (dispatch) => {
    return dispatch(addManagerDetails(managerDetails, preferences))
  }
}

export const removeManagerFromRun = (managerGUID) => {
  return (dispatch) => {
    return dispatch(removeManagerDetails(managerGUID))
  }
}

export const addSelectedPropertyInfos = (property) => {
  return (dispatch) => {
    return dispatch(addSelectedProperty(property))
  }
}

export const removeSelectedPropertyInfos = (property) => {
  return (dispatch) => {
    return dispatch(removeSelectedProperty(property))
  }
}

export const updateStartTimeInViewingRun = (time) => {
  return (dispatch) => {
    return dispatch(updateViewingRunStartTime(time))
  }
}

export const removeSelectedPropertyFromManagerList = (
  managerGUID,
  propertyGUID,
) => {
  return (dispatch) => {
    return dispatch(
      removeSelectedPropertyFromManager(managerGUID, propertyGUID),
    )
  }
}

export const updateViewingRunReadOnly = (isReadOnly) => {
  return (dispatch) => {
    return dispatch(updateRunReadOnly(isReadOnly))
  }
}

export const deleteManagerState = () => {
  return (dispatch) => {
    return dispatch(clearManagerState())
  }
}
