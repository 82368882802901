import React from 'react'

import { connect } from 'react-redux'

import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { ErrorMessage } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'
import { completeRenterUpdateOnsiteViewing } from 'app/sm/inspections/inspections_actions'

class RenterUpdateOnsiteViewing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
    }
  }

  componentDidMount() {
    const { onsiteregistrantId } = this.props.match.params
    if (!onsiteregistrantId) {
      this.setState({ error: 'Onsite viewing registrant ID is empty' })
      return
    }
    this.props
      .completeRenterUpdateOnsiteViewing(onsiteregistrantId)
      .then(() => {
        history.push(urlTo('smViewings'))
      })
      .catch((error) => {
        this.setState({ error: translateErrorCodeToMessage(error) })
      })
  }

  render() {
    return (
      <div>{this.state.error && <ErrorMessage error={this.state.error} />}</div>
    )
  }
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = (dispatch) => ({
  completeRenterUpdateOnsiteViewing: (registrationGUID) =>
    dispatch(completeRenterUpdateOnsiteViewing(registrationGUID)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RenterUpdateOnsiteViewing)
