import React from 'react'

import styled from 'styled-components'

import greenTick from 'app/assets/icons/green-tick.png'
import { Box, Modal } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import { ErrorExternalAgencySourceDuplicate } from 'app/constants/error_codes'
import * as snugNotifier from 'app/services/snugNotifier'
import * as validations from 'app/shared_components/validations'
import { urlTo } from 'app/sm/helpers'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  loadingStatesIds,
} from 'app/utils/loading-states'
import { removeSpaces } from 'app/utils/strings/helpers'

const SelectAndButtonContainer = styled.div`
  display: flex;
  select {
    width: 75%;
  }
  .refresh-button {
    width: 25%;
    margin-left: 16px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    select {
      width: 100%;
    }
    .refresh-button {
      width: 100%;
      margin-top: 8px;
      margin-left: 0;
    }
  }
`

const InputBox = styled.div`
  input:disabled + label {
    top: 0px !important;
    left: 0;
    font-size: 12px;
    color: #686d71;
    text-transform: uppercase;
  }
`
const BTN_LABEL_REFRESH = 'Refresh'
const BTN_LABEL_REFRESHED = 'Refreshed'
const STRING_WEBFEED = 'webfeed'

class AgencySourceContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      supplierSources: [],
      REAAgencyId: '',
      agencyExists: true,
      errors: {},
      btnDisabled: true,
      agencyGUID: this.props.agencyGUID,
      agencySupplierSource: {},
      onBlurAgencyID: false,
      spinner: false,
      supplierSourceRefreshed: false,
      apiInProgress: false,
      duplicateExternalAgencyIDModal: {
        isShown: false,
        dupAgencyName: '',
        dupAgencyGUID: '',
      },
      forceUpdateLoadingStates: createInitialLoadingState(),
      forceUpdateLoadingUtils: createLoadingStateUtils((state) =>
        this.setState({ forceUpdateLoadingStates: state }),
      ),
    }
  }

  componentDidMount() {
    let { clearError, agencyGUID } = this.props
    clearError()
    this.onFetchSupplierSourceForAgency(agencyGUID)
    this.onFetchSupplierSources()
  }

  componentDidUpdate(prevProps) {
    let { agencyGUID } = this.props
    if (prevProps !== this.props) {
      this.setState({
        agencyGUID: agencyGUID,
      })
      this.onFetchSupplierSourceForAgency(agencyGUID)
    }
  }

  onFetchSupplierSourceForAgency = (agencyGUID) => {
    this.props.fetchSupplierSourceForAgency(
      agencyGUID,
      (agencySupplierSource) => {
        //agencySupplierSource &&
        this.setState({
          REAAgencyId: agencySupplierSource
            ? agencySupplierSource.agencyID
            : '',
          supplierSourceGUID: agencySupplierSource
            ? agencySupplierSource.source_guid
            : '',
          agencySourceGUID: agencySupplierSource
            ? agencySupplierSource.GUIDID
            : '',
        })
      },
    )
  }

  onFetchSupplierSources = () => {
    this.props.fetchAllSupplierSources((supplierSources) => {
      this.setState({ supplierSources })
    })
  }

  onRefreshAgencySourceSupplier = () => {
    const { agencySourceGUID } = this.state
    this.setState({
      spinner: true,
    })
    this.props
      .refreshAgencySourceSupplier(agencySourceGUID)
      .then(() => {
        this.setState({
          spinner: false,
          supplierSourceRefreshed: true,
        })
        snugNotifier.success('Feed refreshed successfully')
      })
      .catch((error) => {
        this.setState({
          spinner: false,
        })
        snugNotifier.error(error)
      })
  }

  setValidationErrors(errorMessage) {
    let errors = this.state.errors
    if (errorMessage.length === 0) {
      // No validation errors
      delete errors['REAAgencyId']
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors['REAAgencyId'] = errorMessage
      this.setState({ errors })
      return false
    }
  }

  agencyIDOnBlur = () => {
    return () => {
      this.setState({ onBlurAgencyID: true })
      this.validateAgencyID()
    }
  }

  getActionAPI = (payload) => {
    const { updateAgencySupplierSource, addAgencySupplierSource } = this.props
    const { agencySourceGUID } = this.state

    if (agencySourceGUID) {
      return updateAgencySupplierSource(payload).then(() =>
        snugNotifier.success('Feed settings saved successfully'),
      )
    } else {
      return addAgencySupplierSource(payload).then((agencySource) => {
        snugNotifier.success('Feed settings saved successfully')
        this.setState({ agencySourceGUID: agencySource.GUIDID })
      })
    }
  }

  submit = (force = false) => {
    let {
      agencyGUID,
      supplierSourceGUID,
      REAAgencyId,
      agencySourceGUID,
      supplierSources,
    } = this.state

    if (supplierSourceGUID === undefined || supplierSourceGUID.length === 0) {
      if (supplierSources && supplierSources.length > 0) {
        supplierSourceGUID = supplierSources[0].guid_id
      }
    }

    const payload = {
      agencyGUID: agencyGUID,
      sourceGUID: supplierSourceGUID,
      AgencyID: REAAgencyId.trim(),
      ignoreDuplicateIDs: force,
    }

    if (agencySourceGUID) {
      Object.assign(payload, {
        guidID: agencySourceGUID,
        reprocessPropertyOfferRegister: true, // NOTE: For future use in FE, available in BE, default to current behaviour
      })
    }
    this.setState({
      unsavedChanges: false,
      showSpinner: true,
      apiInProgress: true,
    })

    return this.getActionAPI(payload)
      .catch(({ message, appCode, detailedError }) => {
        if (appCode === ErrorExternalAgencySourceDuplicate) {
          const { name, guidID } = detailedError || {}
          this.setState({
            duplicateExternalAgencyIDModal: {
              isShown: true,
              dupAgencyName: name,
              dupAgencyGUID: guidID,
            },
          })
        } else {
          snugNotifier.error(message)
        }
      })
      .finally(() =>
        this.setState({ showSpinner: false, apiInProgress: false }),
      )
  }

  updateAgencyID = () => {
    return (event) => {
      const { value } = event.target
      const sanitisedValue = removeSpaces(value)
      this.setState(
        {
          REAAgencyId: sanitisedValue,
          unsavedChanges: true,
          onBlurAgencyID: false,
        },
        () => {
          this.validateAgencyID()()
        },
      )
    }
  }

  updateSupplierSource() {
    return (event) => {
      const { value } = event.target
      this.setState({
        supplierSourceGUID: value,
        unsavedChanges: true,
      })
    }
  }

  validateAgencyID = () => {
    return () => {
      const invalidAgencyIds = (this.state.REAAgencyId || '')
        .split(',')
        .map((value) => validations.validateREAAgencyID(value, value)[0])
        .filter((e) => !!e)
        .join(', ')

      return this.setValidationErrors(
        invalidAgencyIds ? `Invalid Agency Ids: ${invalidAgencyIds}` : '',
      )
    }
  }

  toggleDuplicateAgencyIDModal = () => {
    const {
      duplicateExternalAgencyIDModal: { isShown },
    } = this.state
    this.setState({ duplicateExternalAgencyIDModal: { isShown: !isShown } })
  }

  renderDuplicateAgencyIDModal = () => {
    const {
      REAAgencyId,
      forceUpdateLoadingUtils,
      forceUpdateLoadingStates,
      duplicateExternalAgencyIDModal: { isShown, dupAgencyName, dupAgencyGUID },
    } = this.state
    if (!isShown) return

    const forceSubmit = () => {
      const { startLoading, markDoneSuccessfully } = forceUpdateLoadingUtils
      startLoading()
      this.submit(true).then(() => {
        this.toggleDuplicateAgencyIDModal()
        markDoneSuccessfully()
      })
    }

    return (
      <Modal
        modalHeading="Agency ID already in use"
        toggleModal={this.toggleDuplicateAgencyIDModal}
        modalBody={
          <Box>
            {REAAgencyId} is used for team{' '}
            <a href={urlTo('editAgency', { agencyGUID: dupAgencyGUID })}>
              {dupAgencyName}
            </a>{' '}
            Do you still want to update? <br />
            <br />
            If you continue both teams will need to have email filtering to
            ensure listings/properties are routed to the correct team.
          </Box>
        }
        primaryLabel="Update"
        secondaryLabel="Cancel"
        primaryAction={forceSubmit}
        primaryButtonLoading={
          forceUpdateLoadingStates.state === loadingStatesIds.LOADING
        }
        secondaryAction={this.toggleDuplicateAgencyIDModal}
      />
    )
  }

  render() {
    const {
      agencyExists,
      errors,
      REAAgencyId,
      showSpinner,
      supplierSources,
      supplierSourceGUID,
      onBlurAgencyID,
      apiInProgress,
      spinner = false,
      supplierSourceRefreshed,
      agencySourceGUID,
    } = this.state

    const btnDisabled = !REAAgencyId || errors.REAAgencyId || apiInProgress
    const supplierSourceBtnLabel = supplierSourceRefreshed
      ? BTN_LABEL_REFRESHED
      : BTN_LABEL_REFRESH
    const webFeedSource = supplierSources.filter(
      (source) => source.guid_id === supplierSourceGUID,
    )
    const isRefreshBtnDisplay =
      webFeedSource.length > 0 &&
      webFeedSource[0].source.trim().toLowerCase() === STRING_WEBFEED &&
      REAAgencyId
    return (
      <div>
        <h4 className="mb25">Property Feed</h4>
        <div>
          <div>
            <div className="alert alert-info text-align-left">
              BETA Support for multiple Agency IDs:
              <ul>
                <li>Supports FTP uploaders and Webfeed</li>
                <li>Only supports multiple IDs from the same uploader</li>
                <li>
                  To set, use comma separated list, no spaces, e.g. 12345,ABC123
                </li>
                <li>
                  To change a given agencyID, change its value in the comma
                  separated list
                </li>
                <li>
                  To remove a given agencyID, specify an empty value in its
                  position. eg 1545,,5456 removes the second agencyID
                </li>
              </ul>
            </div>
            <InputBox className="input-box">
              <input
                disabled={!agencyExists}
                type="text"
                required
                className={errors.REAAgencyId ? 'error' : ''}
                value={REAAgencyId}
                onChange={this.updateAgencyID()}
                onBlur={this.agencyIDOnBlur()}
              />
              <label>Agency ID</label>
            </InputBox>

            <div
              className={
                errors.REAAgencyId && onBlurAgencyID
                  ? 'alert alert-danger'
                  : 'hide-alert'
              }
            >
              <div>{errors.REAAgencyId}</div>
            </div>
          </div>

          <div>
            <div>
              <div>
                <div className="input-box">
                  <label> Supplier Source</label>
                </div>
                <SelectAndButtonContainer className="input-box">
                  <select
                    className="position-relative"
                    name="select"
                    onChange={this.updateSupplierSource()}
                  >
                    {supplierSources &&
                      supplierSources.map((m) => {
                        return (
                          <option
                            value={m.guid_id}
                            key={m.guid_id}
                            selected={m.guid_id === supplierSourceGUID}
                          >
                            {' '}
                            {m.source}{' '}
                          </option>
                        )
                      })}
                  </select>
                  {isRefreshBtnDisplay && (
                    <Display.GenericButton
                      text={supplierSourceBtnLabel}
                      componentClass="refresh-button"
                      onClick={this.onRefreshAgencySourceSupplier}
                      disabled={supplierSourceRefreshed}
                      isLoading={spinner}
                    >
                      {supplierSourceRefreshed && (
                        <img
                          className="ml10"
                          src={greenTick}
                          alt="green tick"
                        />
                      )}
                    </Display.GenericButton>
                  )}
                </SelectAndButtonContainer>
              </div>
            </div>
          </div>
          <div>
            <div className="alert alert-danger">
              Please do not click multiple times! Can only switch from Webfeed
              to FTP feed, not back.
            </div>
            <div>AgencySource ID: {agencySourceGUID}</div>
            <button
              className="mt15 mb30"
              onClick={() => this.submit(false)}
              disabled={btnDisabled}
            >
              {this.props.edit ? 'Update Property Feed' : 'Join Snug'}{' '}
              <i className={showSpinner ? 'fa fa-spinner fa-pulse' : ''} />
            </button>
          </div>
        </div>
        {this.renderDuplicateAgencyIDModal()}
      </div>
    )
  }
}

export default AgencySourceContainer
