import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import compose from 'app/assets/icons/ui-compose.png'
import { Box, Flex } from 'app/components/design-system-components'
import { HelpOutlineRounded } from 'app/components/design-system-components/icons/actions'
import { GreySubheading } from 'app/components/display/text/standard_text/standard-headings/component'
import {
  ErrorBox,
  validationStatusClassNameCollection,
} from 'app/components/forms/util'
import theme from 'app/match/applicationReportPDF/assets/theme'

import 'app/components/forms/base_adder_widget/style.scss'

// If max value is unset, it will add infinitely
// If min value is unset, min value is 0 by default
class BaseAdderWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showError: true,
    }
  }

  handleAdd = () => {
    const {
      hasMaxValue = false,
      maxValue,
      isEditable = true,
      onChange,
    } = this.props
    if (!isEditable || this.props.value === maxValue) {
      return
    }
    const value = this.props.onAdd({ hasMaxValue, maxValue })
    const error = this.props.validate(value)
    if (onChange) {
      onChange({ value, error })
    }
    this.setState({ showError: false })
  }

  handleChange = (event) => {
    const {
      target: { value },
    } = event

    const error = this.props.validate(value)
    this.props.onChange({ value, error })
    this.setState({ showError: false })
  }

  handleMinus = () => {
    const {
      hasMinValue = false,
      minValue,
      isEditable = true,
      onChange,
    } = this.props
    if (!isEditable || (hasMinValue && this.props.value === minValue)) {
      return
    }
    const value = this.props.onMinus(hasMinValue, minValue)
    const error = this.props.validate(value)
    if (onChange) {
      onChange({ value, error })
    }
    this.setState({ showError: false })
  }

  render() {
    const {
      value = 0,
      error = '',
      leftIcon = '',
      rightIcon = '',
      text = '',
      componentClass = '',
      isEditable = true,
      hasMaxValue = false,
      size = 'small',
      extraText = '',
      isInputAllowed = false,
      showEditIcon = true,
      showControls = {
        plus: true,
        minus: true,
      },
      showTooltip = false,
      tooltipId = '',
      tooltipMessage = '',
    } = this.props
    const { showError, validationStatusFlag } = this.state
    const validateClassName =
      validationStatusClassNameCollection[validationStatusFlag]
    const isMaximum = hasMaxValue && value === this.props.maxValue

    const ctrlsConfig = {
      plus: {
        btnClasses: ['right-adder-widget'],
        icon: rightIcon,
        show: showControls.plus,
        onClick: this.handleAdd,
      },
      minus: {
        btnClasses: ['left-adder-widget'],
        icon: leftIcon,
        show: showControls.minus,
        onClick: this.handleMinus,
      },
    }

    const buildCtrlElem = (ctrlConfig) => {
      if (!ctrlConfig.show) {
        return (
          <div
            className={`adder-widget-button-container hidden-ctrl-placeholder--${size}`}
          ></div>
        )
      }

      return (
        <div className="adder-widget-button-container">
          <div
            className={`adder-widget-button ${size}-adder-widget ${
              isMaximum || !isEditable ? 'disabble-adder-widget' : ''
            } ${ctrlConfig.btnClasses.join(' ')}`}
            onClick={ctrlConfig.onClick}
            role="button"
            tabIndex="0"
          >
            <span>{ctrlConfig.icon}</span>
          </div>
        </div>
      )
    }

    const plusCtrl = buildCtrlElem(ctrlsConfig.plus)
    const minusCtrl = buildCtrlElem(ctrlsConfig.minus)
    return (
      <div className={`standard-form-adder ${componentClass}`}>
        <Flex alignItems="center">
          {text && (
            <GreySubheading
              text={text}
              componentClass={`heading-text ${size}`}
            />
          )}
          {showTooltip && (
            <>
              <Box ml={3} mb={17}>
                <HelpOutlineRounded
                  data-tooltip-content=""
                  data-tooltip-id={tooltipId}
                  color={theme.colors.gray400}
                  width={20}
                  height={20}
                />
              </Box>
              <ReactTooltip
                id={tooltipId}
                data-type="info"
                place="top"
                className="tool-tip-style"
              >
                <Box maxWidth={theme.width[10] + 'px'} textAlign="center">
                  {tooltipMessage}
                </Box>
              </ReactTooltip>
            </>
          )}
        </Flex>
        <div
          className={`form-adder-widget ${validateClassName} ${size}-form-adder`}
        >
          {minusCtrl}

          <div className="input-text-container">
            <div className="input-text">
              {!isInputAllowed && (
                <h1>
                  {value} {extraText}
                </h1>
              )}
              {isInputAllowed && (
                <input
                  readOnly={!isEditable}
                  type="text"
                  value={`${value}${extraText}`}
                  pattern="^[1-9]\d*$"
                  onChange={this.props.onChange && this.handleChange}
                  onClick={() => this.props.click && this.props.click()}
                />
              )}
              {isInputAllowed && showEditIcon && (
                <div className="edit-icon">
                  <img src={compose} alt="Compose Icon" />
                </div>
              )}
            </div>
          </div>

          {plusCtrl}
        </div>
        <ErrorBox error={error} showError={!!(showError && error)} />
      </div>
    )
  }
}

export default BaseAdderWidget
