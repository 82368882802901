import React from 'react'

import styled from 'styled-components'
import { variant } from 'styled-system'

import { Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const StyledBreadcrumbs = styled(Flex)`
  max-height: ${theme.space[5]}px;
  color: ${theme.colors.gray500};
  cursor: pointer;

  .separator {
    margin: 0 ${theme.space[1]}px;
  }
`

const crumbsVariants = {
  variants: {
    basic: {
      '&': {
        fontWeight: theme.fontWeights[3],
      },
    },
    focused: {
      '&': {
        fontWeight: theme.fontWeights[5],
      },
    },
  },
}

const StyledCrumb = styled.span`
  ${variant(crumbsVariants)}
`

const Breadcrumbs = ({ crumbs, separator = '/' }) => {
  return (
    <StyledBreadcrumbs alignItems="start">
      {crumbs.map((crumb, i) => (
        <>
          <StyledCrumb variant={crumb.variant}>{crumb.text}</StyledCrumb>

          {i !== crumbs.length - 1 && (
            <span className="separator">{separator}</span>
          )}
        </>
      ))}
    </StyledBreadcrumbs>
  )
}

export default Breadcrumbs
