import React from 'react'

import ActivityRowField from 'app/agency/advance_activity/activity_row_field'
import { PropertiesActivitiesLabel } from 'app/sm/helpers'

const PropertiesVacancyRows = ({
  propertiesActivities = [],
  percentFieldLabelClass = '',
  dataPercentFieldClass = '',
  dataPercentFieldContainer = '',
  prevYearSelector,
  prevMonthSelector,
  customColumnSelector,
}) => {
  const yearToYearMonth = propertiesActivities.find(
    (act) => act.monthStarting === prevYearSelector,
  )
  const prevMonth = propertiesActivities.find(
    (act) => act.monthStarting === prevMonthSelector,
  )
  const customDateActs = propertiesActivities.find(
    (act) => act.monthStarting === customColumnSelector,
  )
  const mergeActivities = [yearToYearMonth, prevMonth, customDateActs]
  return (
    <div>
      <div className="advance-activity-content-list-row ">
        {customDateActs && (
          <ActivityRowField
            widthClass={percentFieldLabelClass}
            fieldClass="mb0"
            field={`${
              PropertiesActivitiesLabel[customDateActs.label]
            } avg vacant days`}
          />
        )}
        {mergeActivities && (
          <div className={`${dataPercentFieldContainer} display-flex`}>
            {mergeActivities.map((act, index) => {
              return (
                <ActivityRowField
                  key={index}
                  widthClass={dataPercentFieldClass}
                  fieldClass="mb0 mr-auto ml-auto"
                  field={act ? act.avgVacantDays : 0}
                />
              )
            })}
          </div>
        )}
      </div>
      <div className="advance-activity-content-list-row grey-background">
        {customDateActs && (
          <ActivityRowField
            widthClass={percentFieldLabelClass}
            fieldClass="mb0"
            field={`${
              PropertiesActivitiesLabel[customDateActs.label]
            } avg days on market`}
          />
        )}
        {mergeActivities && (
          <div className={`${dataPercentFieldContainer} display-flex`}>
            {mergeActivities.map((act, index) => {
              return (
                <ActivityRowField
                  key={index}
                  widthClass={dataPercentFieldClass}
                  fieldClass="mb0 mr-auto ml-auto"
                  field={act ? act.avgDaysOnMarket : 0}
                />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default PropertiesVacancyRows
