import React from 'react'

import moment from 'moment'

import { Box } from 'app/components/design-system-components'
import RightAngledArrowIcon from 'app/pages/teams/messages/components/RightAngledArrowIcon'
import * as helpers from 'app/sm/helpers'
import * as stringHelpers from 'app/utils/strings/helpers'

function Note({
  text,
  author,
  timestamp,
  isInModal = false,
  trimMessage = false,
  trimmedCharactersToDisplay = 200,
}) {
  const timeStampDetails = helpers.dateUtil.getLatestDuration(
    timestamp,
    moment(),
    true,
  )
  const formattedTimestamp = `${timeStampDetails.duration} ${timeStampDetails.durationType} ago`

  return (
    <Box>
      <Box>
        <span>
          {isInModal || !trimMessage ? (
            text
          ) : (
            <span>
              {stringHelpers.trimAndAddEllipsesPastSpecifiedLength(
                text,
                trimmedCharactersToDisplay,
              )}
              {trimMessage && text.length > trimmedCharactersToDisplay && (
                <RightAngledArrowIcon />
              )}
            </span>
          )}
        </span>
      </Box>
      <Box fontWeight="bold" fontSize="12px" mb="16px" lineHeight="130%">
        <span>- {author}, </span>
        <span>{formattedTimestamp}</span>
      </Box>
    </Box>
  )
}
export default Note
