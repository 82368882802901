import type { FunctionComponent, ReactNode } from 'react'

import type { SpaceProps } from 'styled-system'

import { Alert } from 'app/components/design-system-components/Alert'
import theme from 'app/match/applicationReportPDF/assets/theme'

type AlertInfoBaseProps = {
  children: ReactNode
}

export const AlertInfoBase: FunctionComponent<AlertInfoBaseProps & SpaceProps> =
  ({ children, ...props }) => {
    return (
      <Alert
        actionIconBtnConfig={undefined}
        variant="blueWithBg"
        showLeftIcon={true}
        leftIconVariant="info"
        my={3}
        fontSize={theme.fontSizes.pRegular14}
        {...props}
      >
        {children}
      </Alert>
    )
  }
