import { FunctionComponent, useState } from 'react'

import styled from 'styled-components'

import CollectionPolicyModal from 'app/components/Ballot/TermAndConditions/CollectionPolicyModal'
import CRATermsModal from 'app/components/Ballot/TermAndConditions/CraTermsModal'
import { Box, Button } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as textHelpers from 'app/utils/text/helpers'

const StyledLearnMore = styled(Button)`
  font-size: ${theme.fontSizes.pLarge16};
`

type BallotTermAndConditionsProps = {}

export const BallotTermAndConditions: FunctionComponent<BallotTermAndConditionsProps> =
  () => {
    const [craTermsModalOpen, setCraTermsModalOpen] = useState(false)
    const [collectionPolicyModalOpen, setCollectionPolicyModalOpen] =
      useState(false)
    const toggleCRATermsModal = () => {
      setCraTermsModalOpen(!craTermsModalOpen)
    }
    const toggleCollectionPolicyModal = () => {
      setCollectionPolicyModalOpen(!collectionPolicyModalOpen)
    }

    return (
      <Box mt={6}>
        <h4>Terms and Conditions</h4>
        <Box mt={4} mb={4}>
          {textHelpers.VARC_BALLOT_APPLICATION_START_PAGE_TERM_AND_CONDITIONS.map(
            (text) => (
              <Box mb={2}>{text}</Box>
            ),
          )}
          <Box mt={2}>
            Application and survey data is subject to the Homes Victoria Data
            Collection Policy{' '}
            <StyledLearnMore
              variant="linkBlueWithoutWeight"
              onClick={toggleCollectionPolicyModal}
            >
              learn more
            </StyledLearnMore>
          </Box>
          <Box mt={2}>
            {textHelpers.CRA_POLICY_TEXT}{' '}
            <StyledLearnMore
              variant="linkBlueWithoutWeight"
              onClick={toggleCRATermsModal}
            >
              learn more
            </StyledLearnMore>
          </Box>
          {craTermsModalOpen && (
            <CRATermsModal toggleCRATermsModal={toggleCRATermsModal} />
          )}
          {collectionPolicyModalOpen && (
            <CollectionPolicyModal
              toggleCollectionPolicyModal={toggleCollectionPolicyModal}
            />
          )}
        </Box>
      </Box>
    )
  }

export default BallotTermAndConditions
