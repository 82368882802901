import { useState } from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Box, Button, Modal } from 'app/components/design-system-components'
import { PhoneRounded } from 'app/components/design-system-components/icons/communications'
import { FormatAlignLeftRounded } from 'app/components/design-system-components/icons/editor'
import { Text } from 'app/components/design-system-components/typography'
import * as Form from 'app/components/forms/forms'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import * as helpers from 'app/sm/helpers'

interface EnquiryNotesModalProps {
  toggleModal: Function
  messageGUID: string
  createNote: Function
  postSave: Function
  allNotes: Array<any>
}

const StyledPreviousNotesContainer = styled(Box)`
  height: ${theme.height[10]}px;
  overflow: auto;
`

const IconButton = styled(Button)`
  padding: 5px !important;
  height: 32px;
`

const phoneCategory = 'phone'
const textCategory = 'notes'

const noteTypeByCategory: any = {
  [phoneCategory]: helpers.noteActionType.phoneCall,
  [textCategory]: helpers.noteActionType.enquiry,
}

const EnquiryNotesModal = ({
  allNotes,
  toggleModal,
  messageGUID,
  createNote,
  postSave,
}: EnquiryNotesModalProps) => {
  const [content, setContent] = useState('')
  const [category, setCategory] = useState(textCategory)

  const saveNote = () => {
    const payload = {
      content: content,
      type: noteTypeByCategory[category],
      target: helpers.targetSectionObject.enquiry['index'],
    }

    createNote(payload, messageGUID, 'sm_message')
      .then(() => {
        postSave()
        toggleModal()
      })
      .catch((error: any) => snugNotifier.error(error))
  }

  const modalBody = (
    <Box width="100%">
      <Box>
        <Text mr={3} as="span">
          Note type:
        </Text>
        <IconButton
          onClick={() => setCategory(textCategory)}
          variant={category === textCategory ? 'blueTabHeaderActive' : 'solid'}
          data-tooltip-content=""
          data-tooltip-id="privateTxtNoteTooltip"
        >
          <FormatAlignLeftRounded fontSize={theme.fontSizes.h4 + 'px'} />
        </IconButton>
        <ReactTooltip id="privateTxtNoteTooltip" data-type="info">
          Notes
        </ReactTooltip>

        <IconButton
          ml={3}
          onClick={() => setCategory(phoneCategory)}
          variant={category === phoneCategory ? 'blueTabHeaderActive' : 'solid'}
          data-tooltip-content=""
          data-tooltip-id="phoneNoteTooltip"
        >
          <PhoneRounded fontSize={theme.fontSizes.h4 + 'px'} />
        </IconButton>
        <ReactTooltip id="phoneNoteTooltip" data-type="info">
          Phone call
        </ReactTooltip>
      </Box>
      <Form.CommentArea
        value={content}
        placeholder="Add private note"
        rows={2}
        onChange={({ value }: any) => setContent(value)}
        autoFocus
        id="content"
      />
      <Box mt={8}>
        <Text
          as="div"
          fontWeight={theme.fontWeights.h1}
          fontSize={theme.fontSizes.pExtraLarge18}
          mb={5}
        >
          Previous Notes
        </Text>
        <StyledPreviousNotesContainer>{allNotes}</StyledPreviousNotesContainer>
      </Box>
    </Box>
  )

  return (
    <Modal
      modalHeading={
        'Add Private Note' + (category === phoneCategory ? ' - Call log' : '')
      }
      modalBody={modalBody}
      primaryLabel="Save"
      customModalFooter={false}
      secondaryLabel="Cancel"
      toggleModal={toggleModal}
      primaryAction={saveNote}
      secondaryAction={toggleModal}
      modalFooter={undefined}
    />
  )
}

export default EnquiryNotesModal
