import React from 'react'

import { Link } from 'react-router-dom'

import BondSplit from 'app/bond_cover/renters/tenants/bond_split'
import { history } from 'app/shared_components/router'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import { urlTo } from 'app/sm/helpers'

const BondSplitsForm = ({
  currentLease,
  tenants,
  errors,
  apiError,
  update,
  trimField,
  calculateTotal,
  calculateVariance,
  submit,
  validate,
  reset,
  disabled,
  clicked,
  unsavedChanges,
  requestID,
  backUrl,
}) => {
  let { bond } = currentLease ? currentLease : ''
  return (
    <div className="two-col-box-flex-row sm64">
      <div className="col-first">
        <div className="mobile-only">
          <Link
            to={urlTo('bondCoverTenants', { requestID })}
            className="btn btn-transparent btn-left xs-text-center wa"
          >
            <i className="icon-arrow-left left"></i>
            <span>Back</span>
          </Link>
        </div>
        <h3 className="mb15">Customise Bond Refund</h3>
        <p className="mb15">
          Enter the bond paid by each tenant and Snug will split the refund
          accordingly.
        </p>

        <button
          className="btn btn-blue btn-light btn-medium wa mb40"
          onClick={reset}
        >
          <i className="icon-refresh fs14 blue-color left"></i>
          <span>Reset</span>
        </button>

        <div className="table gray-dark-color fw700 fs16 bbs pb15 mb15">
          <div className="cell-m">Tenants</div>
          <div className="cell-m text-right">Refund</div>
        </div>

        <BondSplit
          total={bond}
          tenants={tenants}
          errors={errors}
          update={update}
          validate={validate}
          trimField={trimField}
        />

        <div className="table gray-dark-color fw700 fs20 mt25">
          <div className="cell-m-40">Allocated Bond</div>
          <div className="cell-m-20 text-center pink-color fs20 fw700">
            {currentLease ? ((calculateTotal / bond) * 100).toFixed(2) : '0'}%
          </div>
          <div className="cell-m text-right">${calculateTotal.toFixed(2)}</div>
        </div>

        {calculateVariance != 0 && (
          <div className="table gray-dark-color fw700 fs20 mt25">
            <div className="cell-m-40 pink-color">Unallocated Bond</div>
            <div className="cell-m-20 text-center pink-color fs20 fw700">
              {currentLease && (100 - (calculateTotal / bond) * 100).toFixed(2)}
              %
            </div>
            <div className="cell-m text-right pink-color">
              ${calculateVariance.toFixed(2)}
            </div>
          </div>
        )}

        <div className={apiError ? 'alert alert-danger xs-mb10' : ''}>
          {apiError}
        </div>

        <div className="row pt25 mobile-form-button mt15">
          <div className="col-sm-5 pb5 tablet-only">
            <button
              onClick={() => {
                history.push(backUrl)
              }}
              className="btn btn-transparent btn-left xs-text-center wa"
            >
              <i className="icon-arrow-left left"></i>
              <span>Back</span>
            </button>
          </div>
          <div className="col-sm-7 pb5">
            <button
              className="btn"
              disabled={disabled}
              onClick={() => submit()}
            >
              Save
              <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
            </button>
          </div>
        </div>

        <UnsavedMessage unsavedChanges={unsavedChanges} />
      </div>
    </div>
  )
}

export default BondSplitsForm
