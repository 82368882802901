export const initialValidations = {
  name: { ok: true, error: '' },
  type: { ok: true, error: '' },
  petDateOfBirth: { ok: true, error: '' },
  breed: { ok: true, error: '' },
  indoorOrOutdoor: { ok: true, error: '' },
}

export const initialState = () => ({
  name: '',
  type: '',
  petDateOfBirth: '',
  breed: '',
  indoor: false,
  outdoor: false,
  saveClicked: false,
  editable: false,
  petGuidID: null,
  unsavedChanges: false,
  validations: initialValidations,
})

export const validationCtors = {
  ok: () => ({
    ok: true,
    error: '',
  }),
  error: (message) => ({
    ok: false,
    error: message,
  }),
}

export const types = [
  { name: '', type: '' },
  { name: 'Dog', type: 1 },
  { name: 'Cat', type: 2 },
  { name: 'Other', type: 3 },
]
