import React from 'react'

import Button from 'app/components/buttons/default/component'
import * as Text from 'app/components/display/text/text_component'
import AvailabilityAndVacantDate from 'app/pages/teams/onlist/components/AvailabilityAndVacantDate'
import * as snugNotifier from 'app/services/snugNotifier'
import * as helpers from 'app/sm/helpers'
import AddOwnerModal, {
  DisplayOwners,
} from 'app/sm/inspections/components/add_owner_modal'
import AddTenantsModal, {
  DisplayTenants,
} from 'app/sm/inspections/components/add_tenants_modal'

class AddOwnerAndTenant extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showOwnersModal: false,
      showTenantsModal: false,
      showOwnerSpinnerOwner: false,
      owners: [],
      tenants: [],
      vacateDate: '',
      availableDate: '',
    }
  }

  componentDidMount() {
    this.getAllOwnersForProperty()
    this.getAllTenantsForProperty()
  }

  onRemoveOwnerClicked = (event, owner) => {
    if (!owner.isLocked) {
      const { removeCurrentOwners, teamGUID, chosenPropertyID } = this.props
      const { guidID: ownerId } = owner
      this.setState({
        removableOwnerID: ownerId,
      })
      removeCurrentOwners(teamGUID, ownerId, chosenPropertyID)
        .then(() => {
          snugNotifier.success('The owner has been deleted')
          this.getAllOwnersForProperty()
          this.setState({ removableOwnerID: '' })
        })
        .catch((error) => {
          this.setState({ removableOwnerID: '' })
          snugNotifier.error(error)
        })
      event.preventDefault()
    }
  }

  onRemoveTenantClicked = (event, tenant) => {
    const { removeCurrentTenants, chosenPropertyID } = this.props
    const { guid: tenantId } = tenant
    this.setState({
      removableTenantID: tenantId,
    })
    removeCurrentTenants(chosenPropertyID, tenantId)
      .then(() => {
        this.getAllTenantsForProperty()
        this.setState({ removableTenantID: '' })
      })
      .catch((error) => {
        this.setState({ removableTenantID: '' })
        snugNotifier.error(error)
      })
    event.preventDefault()
  }

  getAllOwnersForProperty = () => {
    const { fetchCurrentOwners, teamGUID, chosenPropertyID } = this.props
    this.setState({
      showOwnerSpinnerOwner: true,
    })
    fetchCurrentOwners(teamGUID, chosenPropertyID)
      .then((result) => {
        this.setState({
          owners: result,
          showOwnerSpinnerOwner: false,
          filteredContactsForOwner: result,
        })
      })
      .catch((error) => {
        snugNotifier.error(error)
        this.setState({
          showOwnerSpinnerOwner: false,
        })
      })
    this.setState({
      showTenantSpinner: true,
    })
  }

  getAllTenantsForProperty = () => {
    const { fetchCurrentTenants, teamGUID, chosenPropertyID } = this.props
    fetchCurrentTenants(teamGUID, chosenPropertyID)
      .then((result) => {
        const { tenants = [], availableDate, vacateDate } = result
        this.setState({
          tenants,
          showTenantSpinner: false,
          availableDate,
          vacateDate,
        })
      })
      .catch((error) => {
        snugNotifier.error(error)
        this.setState({
          showTenantSpinner: false,
        })
      })
  }

  toggleModal = (modalName) => {
    if (modalName === 'showTenantsModal' && !!this.state[modalName]) {
      this.setState({
        bridgedTenantToUpdate: null,
      })
    }

    this.setState({
      [modalName]: !this.state[modalName],
    })
  }

  render() {
    const {
      showOwnersModal,
      showTenantsModal,
      owners,
      showOwnerSpinnerOwner,
      showTenantSpinner,
      tenants,
      availableDate,
      vacateDate,
      bridgedTenantToUpdate = null,
    } = this.state
    const {
      fetchAgencyApplicantsElasticSearchResult,
      teamGUID,
      address,
      ownerModalCategory,
      propertyAddress,
      chosenPropertyID,
      fetchCurrentOwners,
      currentAgency,
      addCurrentOwners,
      updateCurrentOwners,
      removeCurrentOwners,
      fetchCurrentTenants,
      addCurrentTenants,
      updateCurrentTenants,
      removeCurrentTenants,
      sendEntryNotice,
      chosenPropertyText,
      offerGUID,
      offerInfo,
      handleVacateDateUpdated,
    } = this.props
    return (
      <div>
        <Text.Stronger
          text="Available & Vacate date"
          textClass="fs24"
          componentClass="mb8"
        />
        <AvailabilityAndVacantDate
          initialAvailableDate={availableDate}
          initialVacateDate={vacateDate}
          offerGUID={offerGUID}
          offerInfo={offerInfo}
          onVacateDateUpdated={handleVacateDateUpdated}
        />
        <Text.Stronger text="Contacts" textClass="fs24" componentClass="mb30" />
        <DisplayOwners
          chosenPropertyText={chosenPropertyText}
          onStageChange={this.onStageChange}
          owners={owners}
          onRemoveClicked={this.onRemoveOwnerClicked}
          onListItemClicked={this.onListItemClicked}
          showSpinner={showOwnerSpinnerOwner}
          showAddButton={false}
        />
        <div className="width200px">
          <Button
            onClick={() => this.toggleModal('showOwnersModal')}
            buttonStyleClass="block mb60 mt20"
            buttonType="secondary"
          >
            Add Owners
          </Button>
        </div>

        <DisplayTenants
          chosenPropertyText={chosenPropertyText}
          chosenPropertyID={chosenPropertyID}
          onStageChange={this.onStageChange}
          tenants={tenants}
          onListItemClicked={(t) => {
            this.setState({
              bridgedTenantToUpdate: t,
            })

            this.toggleModal('showTenantsModal')
          }}
          onRemoveClicked={this.onRemoveTenantClicked}
          showSpinner={showTenantSpinner}
          showAddButton={false}
        />
        <div className="width200px">
          <Button
            onClick={() => this.toggleModal('showTenantsModal')}
            buttonStyleClass="block mb60 mt20"
            buttonType="secondary"
          >
            Add Tenants
          </Button>
        </div>

        {showOwnersModal && (
          <AddOwnerModal
            fetchAgencyApplicantsElasticSearchResult={
              fetchAgencyApplicantsElasticSearchResult
            }
            teamId={teamGUID}
            address={address}
            toggleModal={() => this.toggleModal('showOwnersModal')}
            ownerModalCategory={ownerModalCategory}
            chosenPropertyText={propertyAddress}
            chosenPropertyID={chosenPropertyID}
            fetchCurrentOwners={fetchCurrentOwners}
            currentTeam={currentAgency}
            addCurrentOwners={addCurrentOwners}
            updateCurrentOwners={updateCurrentOwners}
            removeCurrentOwners={removeCurrentOwners}
          />
        )}
        {showTenantsModal && (
          <AddTenantsModal
            toggleModal={() => this.toggleModal('showTenantsModal')}
            address={address}
            teamId={teamGUID}
            fetchAgencyApplicantsElasticSearchResult={
              fetchAgencyApplicantsElasticSearchResult
            }
            chosenPropertyID={chosenPropertyID}
            fetchCurrentTenants={fetchCurrentTenants}
            addCurrentTenants={addCurrentTenants}
            updateCurrentTenants={updateCurrentTenants}
            tenantModalCategory={helpers.TenantModalCategory.addTenants}
            removeCurrentTenants={removeCurrentTenants}
            sendEntryNotice={sendEntryNotice}
            chosenPropertyText={chosenPropertyText}
            bridgedTenantToUpdate={bridgedTenantToUpdate}
          />
        )}
      </div>
    )
  }
}

export default AddOwnerAndTenant
