import axios from 'axios'
import { accountsBaseUrl, apiBaseUrl } from 'config/env'
import qs from 'qs'

import { translateErrorCodeToMessage } from 'app/constants/error_messages'

const defaultConfigs = {
  withCredentials: true,
  baseURL: apiBaseUrl + '/',
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
}

const client = axios.create(defaultConfigs)

export const request = async function (options) {
  const onSuccess = function (response) {
    return {
      data: response.data,
      ok: true,
    }
  }

  const onError = function (error) {
    console.error('Request Failed:', error.config)

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      return {
        errorCode: error.response.data,
        ok: false,
      }
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message)
    }

    return Promise.reject(error.response || error.message)
  }

  try {
    const response = await client(options)
    return onSuccess(response)
  } catch (error) {
    return onError(error)
  }
}

const axiosInstance = axios.create(defaultConfigs)

axiosInstance.interceptors.request.use(
  (config) => config,
  (error) => {
    console.error(error)
    return Promise.reject(error)
  },
)

const withRequestIDMessage = (err) => {
  return (message) => {
    const { requestID } = err
    if (!requestID) return message

    return `${message} [${requestID}]`
  }
}

const withDetailedErrorMessage = (detailedError) => {
  return (message) => {
    const detailedErrorMessage = (detailedError || {}).message || ''
    if (!detailedErrorMessage) return message

    return `${message} [${detailedErrorMessage}]`
  }
}

export const errorResponseHandler = (error) => {
  const { response } = error
  const structuredError = typeof response?.data === 'object'
  if (structuredError) {
    const { data: responseData } = response || { data: {} }
    const { app_code: appCode } = responseData || {}
    const simpleErrorMessage = translateErrorCodeToMessage(
      responseData?.app_code,
    )
    const message = withRequestIDMessage(responseData)(simpleErrorMessage)
    const detailedError = response.data.detail || null
    const combinedErrorMessage = withRequestIDMessage(responseData)(
      withDetailedErrorMessage(detailedError)(simpleErrorMessage),
    )
    return Promise.reject({
      message,
      detailedError,
      appCode,
      combinedErrorMessage,
      requestID: responseData?.requestID,
      plainError: error,
    })
  }

  const errorCode = response?.data
  if (errorCode)
    return Promise.reject({
      message: translateErrorCodeToMessage(errorCode),
      plainError: error,
    })

  return Promise.reject({
    message: error.message || 'Something went wrong',
    plainError: error,
  })
}

axiosInstance.interceptors.response.use(
  ({ data }) => data,
  (error) => {
    console.error(error)
    return errorResponseHandler(error)
  },
)

const accountsApiAxiosInstance = axios.create({
  ...defaultConfigs,
  baseURL: accountsBaseUrl + '/',
})

accountsApiAxiosInstance.interceptors.request.use(
  (config) => config,
  (error) => {
    console.error(error)
    return Promise.reject(error)
  },
)

accountsApiAxiosInstance.interceptors.response.use(
  ({ data }) => data,
  (error) => {
    const { response } = error
    const errorMessage =
      (response && response.data) || error.message || 'Something went wrong'
    return Promise.reject({
      message: errorMessage,
      plainError: error,
    })
  },
)

const apisWrapperGenerator = (axiosInstanceToWrap) => {
  return {
    get: function (url, config) {
      return axiosInstanceToWrap.get(url, config)
    },
    put: (url, payload, config) => {
      return axiosInstanceToWrap.put(url, payload, config)
    },
    post: (url, payload, config) => {
      return axiosInstanceToWrap.post(url, payload, config)
    },
    delete: (url, payload, config) => {
      return axiosInstanceToWrap.delete(url, { data: payload }, config)
    },
  }
}

export const api = { ...apisWrapperGenerator(axiosInstance) }
export const accountsApi = { ...apisWrapperGenerator(accountsApiAxiosInstance) }

export const checkIfNetworkError = ({ plainError }) =>
  !plainError.request.status
