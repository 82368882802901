import { CheckboxInput } from 'app/components/design-system-components/inputs/Checkboxes'

type Props = {
  isSelected: boolean
  toggleKeySelection: () => any
}

export const SelectKeySetCell = ({ isSelected, toggleKeySelection }: Props) => {
  return (
    <CheckboxInput
      changeHandler={() => toggleKeySelection()}
      value={isSelected}
    />
  )
}
