import React from 'react'

import documentIcon from 'app/assets/icons/summary_icons/attachment.svg'
import * as Display from 'app/components/display/display'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'

const ManagerAttachment = ({
  applicationAttachments = [],
  supportingAttachments = [],
  componentClassName,
  enableNote = true,
  displayedApplicant,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  section,
  id = '',
  toggleRequestInfoModal = undefined,
  toggleDocumentsViewer,
}) => {
  return (
    <div id={id} className={`container-component ${componentClassName}`}>
      <ContainerHeader
        icon={documentIcon}
        text="Attachments"
        isEditable={false}
        enableNote={enableNote}
        onNewNoteUpdated={onNewNoteUpdated}
        newNotesContent={newNotesContent}
        onNoteIconClicked={onNoteIconClicked}
        onNewNoteAdded={onNewNoteAdded}
        onNoteCrossClicked={onNoteCrossClicked}
        interpreteNoteResult={interpreteNoteResult}
        enableNotesButton={enableNotesButton}
        displayedApplicant={displayedApplicant}
        section={section}
        toggleRequestInfoModal={toggleRequestInfoModal}
      />
      <div className="application-container-padding">
        <div className="application-identification-container application-income-proof-container">
          <div className="application-identification-sub-container">
            <div className="application-info-common-component">
              <div className="title width100">
                <Display.SectionMinorTitle text="Application attachment" />
                {applicationAttachments.length > 0 &&
                  applicationAttachments.map((attachment) => (
                    <AppDocumentListItemSecondVersion
                      titleClass="mt0"
                      document={attachment}
                      key={attachment.guidID}
                    />
                  ))}
                {applicationAttachments.length === 0 && (
                  <div className="no-info-provided">
                    No information provided
                  </div>
                )}
                <Display.SectionMinorTitle
                  text="Supporting attachments"
                  componentClass="mb30"
                />
                {supportingAttachments.length > 0 &&
                  supportingAttachments.map((attachment) => (
                    <AppDocumentListItemSecondVersion
                      titleClass="mt0"
                      document={attachment}
                      key={attachment.guidID}
                    />
                  ))}
                {supportingAttachments.length === 0 && (
                  <div className="no-info-provided">
                    No information provided
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ManagerAttachment
