import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

class DashboardItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      link: '',
      status: '',
      description: '',
    }
  }

  componentDidMount() {
    this.setStatus()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicationStatus !== this.props.applicationStatus) {
      this.setStatus()
    }
  }

  setStatus() {
    const { role, statusCode, request, requestID, tenant, applicationStatus } =
      this.props

    const applicationInProgress = request.exitStatus === 0

    const applicationIsPaused = request.pauseState !== 0

    const applicationNotSubmitted = request.submissionStatus === 0
    const applicationPartiallySubmitted = request.submissionStatus === 1

    const idCheckFailed = request.idCheckStatus === 2

    const managerNoAction = request.managerAction === 0
    const managerRequestChange = request.managerAction === 1
    const managerApproved = request.managerAction === 3

    const applicationDeclinedByManager =
      request.managerAction === 2 || request.exitStatus === 2
    const applicationDeclinedByAdmin = request.exitStatus === 3
    const applicationDeclined =
      applicationDeclinedByManager || applicationDeclinedByAdmin

    if (role === 'renter') {
      if (applicationIsPaused) {
        return this.setState({
          link: `/bc/renterwait?requestid=${requestID}`,
          status: 'pending',
          description: 'Currently Pending',
        })
      }

      if (applicationStatus && applicationStatus === 'WITHDRAWN') {
        return this.setState({
          link: '/bc/withdrawconfirm',
          status: 'draft',
          description: 'Withdrawn',
        })
      }

      if (applicationNotSubmitted && applicationInProgress) {
        return this.setState({
          link: `/bc?requestid=${requestID}`,
          status: 'draft',
          description: 'Draft - Please add more details and submit',
        })
      }

      if (idCheckFailed) {
        return this.setState({
          link: `/bc/renteridfail?requestid=${requestID}`,
          status: 'declined',
          description: 'Declined - Your ID check has failed',
        })
      }

      if (request.isCompleted) {
        return this.setState({
          link: `/bc/rentercomplete?requestid=${requestID}`,
          status: 'active',
          description: 'Active',
        })
      }

      if (managerRequestChange) {
        return this.setState({
          link: `/bc?requestid=${requestID}`,
          status: 'action',
          description: 'Action - Change Requested',
        })
      }

      if (applicationDeclined) {
        return this.setState({
          link: `/bc/renterdeclined?requestid=${requestID}`,
          status: 'declined',
          description: 'Declined - Your Request has been Declined',
        })
      }

      if (managerApproved && applicationPartiallySubmitted) {
        return this.setState({
          link: `/bc?requestid=${requestID}`,
          status: 'action',
          description:
            'Action - Please Complete Identity Doc & Bank Account Information',
        })
      }

      return this.setState({
        link: `/bc/renterwait?requestid=${requestID}`,
        status: 'pending',
        description: 'Currently Pending',
      })
    } else if (role === 'secondary') {
      if (applicationIsPaused) {
        return this.setState({
          link: `/bc/renterwait?requestid=${requestID}&role=secondary`,
          status: 'pending',
          description: 'Currently Pending',
        })
      }

      if (applicationStatus && applicationStatus === 'WITHDRAWN') {
        return this.setState({
          link: `/bc/secondarywithdrawconfirm`,
          status: 'draft',
          description: 'Withdrawn',
        })
      }

      if (applicationDeclined) {
        return this.setState({
          link: `/bc/renterdeclined?requestid=${requestID}&role=secondary`,
          status: 'declined',
          description: 'Declined - Your Request has been Declined',
        })
      }

      if (request.isCompleted) {
        return this.setState({
          link: `/bc/rentercomplete?requestid=${requestID}&role=secondary`,
          status: 'active',
          description: 'Active',
        })
      }

      if (idCheckFailed) {
        return this.setState({
          link: `/bc/renteridfail?requestid=${requestID}&role=secondary`,
          status: 'declined',
          description: 'Declined - Your ID check has failed',
        })
      }

      if (tenant.hasApproved) {
        return this.setState({
          link: `/bc/renterwait?requestid=${requestID}&role=secondary`,
          status: 'pending',
          description: 'Currently Pending',
        })
      }

      return this.setState({
        link: `/bc/leaseesign?requestid=${requestID}`,
        status: 'action',
        description: 'Action - Please Complete Details & Sign',
      })
    } else if (role === 'manager') {
      if (applicationStatus && applicationStatus === 'WITHDRAWN') {
        return this.setState({
          link: `/portfolio/dashboard`,
          status: 'draft',
          description: 'Withdrawn',
        })
      }

      if (request.managerRated && managerNoAction) {
        return this.setState({
          link: `/bc/managersign?requestid=${requestID}`,
          status: 'action',
          description: 'Action - Please Confirm Details & Sign',
        })
      }

      if (applicationDeclined) {
        return this.setState({
          link: '/bc/ownerdecline',
          status: 'declined',
          description: 'Declined - Tenant has been Declined',
        })
      }

      if (!applicationNotSubmitted && !request.managerRated) {
        return this.setState({
          link: `/bc/review?requestid=${requestID}`,
          status: 'action',
          description: 'Action - Please Review Tenant',
        })
      }

      if (request.isCompleted) {
        return this.setState({
          link: `/bc/managercomplete?requestid=${requestID}`,
          status: 'active',
          description: 'Active',
        })
      }

      if (idCheckFailed) {
        return this.setState({
          link: '/bc/ownerdecline',
          status: 'declined',
          description: 'Declined - ID Check Failed',
        })
      }

      return this.setState({
        link: `/bc/managerwait?requestid=${requestID}`,
        status: 'pending',
        description: 'Currently Pending',
      })
    }
  }

  render() {
    const {
      friendlyName,
      suburb,
      state,
      postcode,
      leaseStart,
      leaseEnd,
      applicationStatus,
      role,
    } = this.props
    const { link, status, description } = this.state
    const isWithdrawn =
      applicationStatus &&
      applicationStatus === 'WITHDRAWN' &&
      role === 'manager'
    return (
      <Link
        className={isWithdrawn ? 'dash-link disabled-link' : 'dash-link'}
        to={link}
      >
        <div className="heading">{friendlyName}</div>
        <div className="content">
          {suburb}, {state}, {postcode}
        </div>
        <div className="content">
          {moment(leaseStart).format('Do MMM YYYY')} -{' '}
          {leaseEnd ? moment(leaseEnd).format('Do MMM YYYY') : 'Rolling Lease'}
        </div>
        <div className={`status ${status}`}>{description}</div>
      </Link>
    )
  }
}

export default DashboardItem
