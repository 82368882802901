import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Box } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import {
  getActiveIntegrationsSendingTenancyOnboard,
  pmsBrands,
} from 'app/constants/pms.constants'
import * as Form from 'app/shared_components/form_component'
import { SendApplicationToPMS } from 'app/utils/pms/sendApplicationToPMS'
import { RAY_WHITE_CONCIERGE_SEND_IF_NOT_SENT_LABEL } from 'app/utils/raywhiteconcierge/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import { DefaultUtilityProviderComponent } from 'app/utils/utilityprovider/helpers'
import { isSendApplicantEnabled } from 'config/features'

function ApplicationApprovedModal(props) {
  const {
    markAsApplicationApprovedOptions,
    onChangeMarkAsApplicationApprovedOptions,
    toggleModal,
    onConfirmMarkAsApplicationApprovedClicked,
    currentTeam,
    pmsIntegrationStatus,
    easyBondpayOptIn = false,
    integrationSettings,
  } = props

  const activeSendingOnboardIntegrations =
    getActiveIntegrationsSendingTenancyOnboard(pmsIntegrationStatus)

  const isUtilityProviderAvailable = !!(
    currentTeam &&
    currentTeam.utilityProviderConfigured &&
    currentTeam.providerName !== ''
  )
  const isConciergeServiceEnabled = !!(
    currentTeam && currentTeam.conciergeServiceEnabled
  )
  const providerName = currentTeam && currentTeam.providerName
  const isPlatformDefaultProvider =
    currentTeam && currentTeam?.platformDefaultProvider
  const utilityProviderCheckboxLabel = isPlatformDefaultProvider ? (
    <DefaultUtilityProviderComponent />
  ) : (
    <Box>{`Send connection referral to ${providerName} (if opted in)`}</Box>
  )

  const renderPMSConfig = ({ pms }) => {
    const brand = Object.values(pmsBrands).find(({ id }) => pms === id)
    if (!brand || !isSendApplicantEnabled(pms, currentTeam.slug)) return

    return (
      <SendApplicationToPMS
        pms={pms}
        teamSlug={currentTeam?.slug}
        integrationSettings={integrationSettings}
        pmsConfig={markAsApplicationApprovedOptions}
        onChange={onChangeMarkAsApplicationApprovedOptions(
          brand.applicationConfig,
        )}
        sendOption="applicationConfig"
      />
    )
  }

  return (
    <Display.Modal
      toggleModal={() => toggleModal('')}
      title="Mark Application as Approved"
      hideButtonSection
      modalSubheadingClass="fs18"
    >
      <div className="fs16">
        <p className="mb10 fw500">Marking as Approved will:</p>
        <ul>
          <li>Label the application as Approved</li>
          <li>
            <b>Not</b> send an offer email
          </li>
          {isUtilityProviderAvailable && (
            <li>
              SMS the primary applicant to expect a call from {providerName} (if
              opted in)
            </li>
          )}
          {isConciergeServiceEnabled && (
            <li>{RAY_WHITE_CONCIERGE_SEND_IF_NOT_SENT_LABEL}</li>
          )}
          {easyBondpayOptIn && <li>Send easyBondpay lead</li>}
        </ul>
        <div className="mt30 mb30">
          {activeSendingOnboardIntegrations &&
            activeSendingOnboardIntegrations.map(renderPMSConfig)}

          {isUtilityProviderAvailable && (
            <Form.Checkbox
              label={utilityProviderCheckboxLabel}
              id="sendUtilityConnectionReferral"
              onChange={onChangeMarkAsApplicationApprovedOptions(
                'sendUtilityReferrals',
              )}
              checked={markAsApplicationApprovedOptions.sendUtilityReferrals}
              checkboxLabelSpanClassName="fs16"
              showDisabledGrey={isPlatformDefaultProvider}
              enableEdit={!isPlatformDefaultProvider}
            />
          )}
          <Form.Checkbox
            label="Cancel future viewings, notify enquirers/registered and tenants"
            id="cancelFutureViewings"
            onChange={onChangeMarkAsApplicationApprovedOptions(
              'cancelFutureViewings',
            )}
            checked={markAsApplicationApprovedOptions.cancelFutureViewings}
            checkboxLabelSpanClassName="fs16 height-auto"
          />
          <div>
            <ReactTooltip id="declineTimeOutDesciption">
              {textHelpers.DECLINE_OTHER_HOUSEHOLD_TOOLTIP_CONTENT}
            </ReactTooltip>
          </div>
          <Form.Checkbox
            label="Decline other household groups for this property (except Approved Applicants)"
            id="declineUnsuccessfulApplications"
            onChange={onChangeMarkAsApplicationApprovedOptions(
              'declineUnsuccessfulApplications',
            )}
            checked={
              markAsApplicationApprovedOptions.declineUnsuccessfulApplications
            }
            checkboxLabelSpanClassName="fs16 height-auto"
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="declineTimeOutDesciption"
                />
              </div>
            }
          />
          <Form.Checkbox
            label="Archive now"
            id="archiveProperty"
            onChange={onChangeMarkAsApplicationApprovedOptions(
              'archiveProperty',
            )}
            checked={markAsApplicationApprovedOptions.archiveProperty}
            checkboxLabelSpanClassName="fs16 height-auto"
          />
        </div>
      </div>
      <Display.ModalButtonGroup
        primaryLabel="Mark as Approved"
        secondaryLabel="Cancel"
        secondaryButtonType="tertiary"
        primaryAction={() => onConfirmMarkAsApplicationApprovedClicked()}
        secondaryAction={() => toggleModal('')}
      />
    </Display.Modal>
  )
}

export default ApplicationApprovedModal
