import React from 'react'

import styled from 'styled-components'

import * as Display from 'app/components/display/display'

const ThirdpartySection = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(34, 33, 42, 0.08);
  border-radius: 2px;
  .initial-section {
    display: flex;
    width: 100%;
    @media (max-width: 576px) {
      display: block;
    }
  }
`

const ActivateBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  @media (max-width: 576px) {
    width: 100%;
  }
`

const ActivateBtn = styled.div`
  border-right: 1px solid #dfdfdf;
  .button-default {
    margin: 0 auto;
    display: table;
    button {
      padding: 0px 32px;
      font-size: 18px;
      line-height: 24px;
    }
  }
  @media (max-width: 576px) {
    width: 100%;
    min-width: 100%;
    border-right: 0;
  }
`

const StatusSection = styled.div`
  display: flex;
  align-items: center;
  min-width: 407px;
  position: relative;
  padding: 16px;
  border-right: 1px solid #dfdfdf;
  @media (max-width: 576px) {
    width: 100%;
    min-width: 100%;
    border-right: 0;
  }
  .yellow {
    font-weight: 500;
    font-size: 16px;
    color: #686d71;
  }
`

const DeactivateLink = styled.a`
  position: absolute;
  right: 16px;
  line-height: 24px;
  color: #212025;
  text-decoration-line: underline;
  @media (max-width: 576px) {
    right: 0px;
  }
`

const StatusLabel = styled.label`
  line-height: 24px;
  margin-right: 10px;
  font-size: 18px;
  font-weight: 500;
`

const Logo = styled.div`
  width: 40%;
  height: 80%;
  border-left: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 576px) {
    width: 100%;
    border-right: 0;
    margin-top: 10px;
  }
`

const Spinner = styled.i`
  position: absolute;
  right: -15px;
  top: 3px;
`

const PlatformLogo = ({ src }) => {
  return (
    <Logo>
      <img
        className="height100"
        style={{ maxHeight: '133px' }}
        src={src}
        alt="platform-logo"
      />
    </Logo>
  )
}

const ThirdpartyIntegration = ({
  initialStage,
  activateBtnOnClick,
  isSubmitted,
  pendingStage,
  deactivateBtnOnClick,
  inactiveStage,
  reactivateOnClick,
  reactivateText,
  integrationInfo,
  logoImg,
  pillColour,
  pillText,
  statusBottomText,
}) => {
  return (
    <div>
      <ThirdpartySection className="application-wrapper display-flex">
        <div className="initial-section">
          {/* {initialStage && (
            <ActivateBtnWrapper>
              <ActivateBtn>
                <Display.GenericButton
                  componentClass="mt0"
                  buttonStyleClass="height40px"
                  size="large"
                  text="Activate"
                  onClick={activateBtnOnClick}
                />
              </ActivateBtn>
            </ActivateBtnWrapper>
          )} */}
          {isSubmitted ? (
            <StatusSection>
              <div className="display-flex flex-direction-column">
                <div className="display-flex position-relative">
                  <StatusLabel>Status </StatusLabel>
                  <Display.StatusPill
                    componentClass="mr10"
                    pillType={pillColour}
                    text={pillText}
                    pillTextClass="fw500 fs16 pl15 pr15"
                  />
                  {pendingStage && (
                    <Spinner className="ml10 fa fa-spinner fa-pulse" />
                  )}
                </div>

                {!pendingStage && !inactiveStage && (
                  <DeactivateLink href="#" onClick={deactivateBtnOnClick}>
                    Disconnect
                  </DeactivateLink>
                )}

                {inactiveStage && (
                  <DeactivateLink onClick={reactivateOnClick}>
                    {reactivateText}
                  </DeactivateLink>
                )}

                <Display.GreyBodyText
                  componentClass="mt15"
                  textClass="fs14 mb0"
                  text={statusBottomText}
                />
              </div>
            </StatusSection>
          ) : (
            <ActivateBtnWrapper>
              <ActivateBtn>
                <Display.GenericButton
                  componentClass="mt0"
                  buttonStyleClass="height40px"
                  size="large"
                  text="Connect"
                  onClick={activateBtnOnClick}
                />
              </ActivateBtn>
            </ActivateBtnWrapper>
          )}
          <PlatformLogo src={logoImg} />
        </div>
      </ThirdpartySection>

      <Display.GreyBodyText
        componentClass="mt15"
        textClass="fs14"
        text={integrationInfo}
      />
    </div>
  )
}

ThirdpartyIntegration.defaultProps = {
  isSubmitted: false,
  initialStage: true,
  pendingStage: false,
  pillColour: '',
  pillText: '',
  statusBottomText: '',
}

export default ThirdpartyIntegration
