import React from 'react'

import 'app/components/display/section_content_container/style.scss'

const SectionContentContainer = ({
  children,
  componentClass,
  showBottomBorder = true,
}) => {
  return (
    <div
      className={`section-content-container ${
        showBottomBorder ? 'bbs' : ''
      } ${componentClass}`}
    >
      {children}
    </div>
  )
}

export default SectionContentContainer
