import React from 'react'
import 'app/sm/viewings_new_run/header/style.scss'
const PublicHeader = ({ title = '', text = '' }) => {
  return (
    <div className="preference-header-container">
      <h4 className="header">{title}</h4>
      <p className="title">{text}</p>
    </div>
  )
}

export default PublicHeader
