import { api } from 'app/utils/api/helpers'

export const getPropertyDisclosureRequestDetailsForOwner = (
  token,
  guid,
  contact,
) => {
  return api.get(
    `/sm/propertydisclosurerequest/${guid}/token/${token}/contact/${contact}`,
  )
}

export const submitPropertyDisclosureRequestForOwner = (
  guid,
  token,
  contact,
  payload,
) => {
  return api.post(
    `/sm/propertydisclosurerequest/${guid}/token/${token}/contact/${contact}`,
    payload,
  )
}

export const getPropertyDisclosuresStatusForAgency = (
  agencyGUID,
  offerGUID,
) => {
  return api.get(
    `/sm/agency/${agencyGUID}/offer/${offerGUID}/propertydisclosure/status`,
  )
}

export const savePropertyDisclosureRequestForOwner = (
  agencyGUID,
  offerGUID,
  saveRequest,
) => {
  return api.post(
    `/sm/agency/${agencyGUID}/offer/${offerGUID}/propertydisclosure/request`,
    {
      ...saveRequest,
    },
  )
}

export const removePropertyDisclosure = (agencyGUID, offerGUID) => {
  return api.delete(
    `/sm/agency/${agencyGUID}/offer/${offerGUID}/propertydisclosure`,
  )
}
