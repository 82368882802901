import React from 'react'

import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { getImageFromProperty } from 'app/shared_components/navbar/components/portfolio_navbar'
import { urlTo } from 'app/sm/helpers'
import { FeatureFlag } from 'config/features'

class PropertyDashboardNavBar extends React.Component {
  componentDidMount() {
    const { fetchProperty, propertyId } = this.props
    fetchProperty(propertyId)
  }

  render() {
    const { property, applicationsCount, propertyId } = this.props
    const hasPropertyAddress = property && property.property
    const teamSlug = this.props.currentTeam && this.props.currentTeam.slug
    return (
      <div className="navLinks">
        <ul>
          <li>
            <NavLink to={urlTo('portfolioOverview', { teamSlug })}>
              <i className="icon-arrow-left" />
              <span>Overview</span>
            </NavLink>
          </li>
          {/* <NavLink to="/dashboard"><i className="icon-arrow-left" /><span>Overview</span></NavLink></li> */}
          {/* <li><NavLink to="/dashboard"><div className="avatar avatar-xxsmall" style={{ backgroundImage: `url(${houseIcon})` }} /><span>50 Bond St.</span></NavLink></li> */}
          <li>
            <NavLink to={urlTo('propertyDashboard', { propertyId })}>
              <div
                className="avatar avatar-xxsmall"
                style={{
                  backgroundImage:
                    property && `url(${getImageFromProperty(property)})`,
                }}
              />
              <span>
                {hasPropertyAddress && property.property.address.friendlyName}
              </span>
            </NavLink>
          </li>
        </ul>
        <ul>
          {/* <li><NavLink to={`/sm/property/${match.params.propertyId}/dashboard`}><i className="icon-home-overview" /><span>Dashboard</span></NavLink></li>
    <li><NavLink to={`/sm/property/${match.params.propertyId}/todos`}><i className="icon-todos" /><span>To Do's</span></NavLink></li> */}
          <li>
            <NavLink to={urlTo('renterApplications', { propertyId })}>
              <i className="icon-applications" />
              <span>Applications</span>
              <div className="badge">
                {applicationsCount ? applicationsCount : 0}
              </div>
            </NavLink>
          </li>
          {FeatureFlag.Viewings.isOn && (
            <li>
              <NavLink to={urlTo('registerBtnLinkTo', { propertyId })}>
                <i className="icon-calendar" />
                <span>Viewings</span>
              </NavLink>
            </li>
          )}
          {/* <li><NavLink to={`/sm/property/${match.params.propertyId}/transactions`}><i className="icon-money" /><span>Transactions</span></NavLink></li>
    <li><NavLink to={`/sm/property/${match.params.propertyId}/lease-details`}><i className="icon-lease" /><span>Lease</span></NavLink></li>
    <li><NavLink to={`/sm/property/${match.params.propertyId}/maintenance`}><i className="icon-maintenece" /><span>Maintenance</span></NavLink></li>
    <li><NavLink to={`/sm/property/${match.params.propertyId}/documents`}><i className="icon-documents" /><span>Documents</span></NavLink></li>
    <li><NavLink to={`/sm/property/${match.params.propertyId}/chat`}><i className="icon-chat" /><span>Chat</span></NavLink></li> */}
        </ul>
      </div>
    )
  }
}

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyDashboardNavBar)
