import React from 'react'

import { Box, Flex } from 'app/components/design-system-components'
import { LaunchRounded } from 'app/components/design-system-components/icons/actions'
import PropertySummary from 'app/components/property/PropertySummary'
import {
  StyledPropertyHeader,
  StyledPropertyImage,
} from 'app/dashboard/team_overview_container'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { urlTo } from 'app/sm/helpers'
import { trimAndAddEllipsesPastSpecifiedLength } from 'app/utils/strings/helpers'

function PropertyImageAndHeader({
  propertyImage = '',
  propertyText = '',
  slug = '',
  property = {},
  activeOffer = {},
}) {
  return (
    <Flex>
      <Box mr={5}>
        <StyledPropertyImage
          style={{
            backgroundImage: `url("${propertyImage}")`,
            width: `${theme.width[29]}px`,
            height: `${theme.height[19]}px`,
          }}
        />
      </Box>
      <Box>
        <StyledPropertyHeader>
          <Flex alignItems="center">
            <Box>{trimAndAddEllipsesPastSpecifiedLength(propertyText, 23)}</Box>
            <Box ml={2} mt={1}>
              <LaunchRounded
                onClick={() =>
                  window.open(urlTo('applyRedirect', { slug }), '_blank')
                }
                color={theme.colors.gray500}
              />
            </Box>
          </Flex>
        </StyledPropertyHeader>
        <PropertySummary property={property} activeOffer={activeOffer} />
      </Box>
    </Flex>
  )
}

export default PropertyImageAndHeader
