import React from 'react'

import OtpInput from 'react-otp-input'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  Box,
  Button,
  ButtonWithLoading,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { resendOTPSMS } from 'app/services/http/session'
import * as snugNotifier from 'app/services/snugNotifier'
import RolePrompt from 'app/session/join/role_prompt/role_prompt_connection'
import { urlIds, urlTo } from 'app/sm/helpers'
import { SessionStorageUtils } from 'app/storage_utils'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  loadingStatesIds,
} from 'app/utils/loading-states'

const SMS_TIMEOUT_SECONDS = 600
const SMS_TIMEOUT_MINUTES = SMS_TIMEOUT_SECONDS / 60

export const OtpInputContainer = styled(Flex)`
  input {
    border: ${({ theme }) => `1px solid ${theme.colors.gray300}`};

    width: 56px !important;
    height: 56px;
    font-size: ${({ theme }) => theme.fontSizes[7] + 'px'};
    font-weight: ${({ theme }) => theme.fontWeights[4]};
    border-radius: ${({ theme }) => theme.radii[6] + 'px'};
  }
`

const ContactInfoText = styled(TextSpan)`
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.fontWeights[4]};
`

class MobileVerificationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      smsCode: '',
      disabled: false,
      resendSmsLoadingStates: createInitialLoadingState(),
    }

    this.resendLoadingStatesUtils = createLoadingStateUtils(
      (resendSmsLoadingStates) => this.setState({ resendSmsLoadingStates }),
    )
  }

  UNSAFE_componentWillMount() {
    let { clearError } = this.props
    clearError()
  }

  componentDidMount() {
    const currentUrl = window.location.pathname
    this.props.setBackUrl(currentUrl)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ disabled: false })
  }

  resend = () => {
    this.resendLoadingStatesUtils.startLoading()
    resendOTPSMS()
      .then(() => {
        this.resendLoadingStatesUtils.markDoneSuccessfully()
        snugNotifier.success('Sent a new verification code')
      })
      .catch((err) => {
        this.resendLoadingStatesUtils.setError(err)
        snugNotifier.error(err.message)
      })
  }

  submit = () => {
    let { currentUser, verify } = this.props
    let { networkType } = currentUser
    let { guidID } = currentUser
    let { smsCode } = this.state
    let forwardUrl =
      SessionStorageUtils.getItem('forwardUrl') || urlTo('homeoverview')
    verify({ guidID, networkType, smsCode }, forwardUrl)
    this.setState({ disabled: true })
  }

  update = (smsCode) => this.setState({ smsCode })

  renderSuccessMessage = () => {
    const { success } = this.props
    if (success) return <div className="alert alert-success">{success}</div>
    return null
  }

  renderErrorMessage = () => {
    const { error } = this.props
    if (error) {
      return <div className="alert alert-danger">{error}</div>
    }
    return null
  }

  render() {
    let { currentUser } = this.props
    let { disabled, smsCode, resendSmsLoadingStates } = this.state
    const successMsg = this.renderSuccessMessage()
    const errorMsg = this.renderErrorMessage()

    const isResendingSms =
      resendSmsLoadingStates.state === loadingStatesIds.LOADING

    const { isOffshore, email, mobile } = currentUser
    const config = isOffshore
      ? {
          channel: 'Email',
          sentTo: email,
        }
      : {
          channel: 'SMS',
          sentTo: mobile,
        }

    return (
      <div className="panel panel-default">
        <div className="panel-body">
          <p className="pb25">
            Verification code for{' '}
            <ContactInfoText>{currentUser.email}</ContactInfoText> sent to{' '}
            <ContactInfoText>{config.sentTo}</ContactInfoText>. Valid for{' '}
            {SMS_TIMEOUT_MINUTES} minutes.
          </p>

          <OtpInputContainer mb={theme.space[7] + 'px'}>
            <OtpInput
              containerStyle="container-style"
              onChange={this.update}
              numInputs={6}
              separator={<span>&nbsp;&nbsp;&nbsp;</span>}
              value={smsCode}
              shouldAutoFocus={true}
              isInputNum={true}
            />
          </OtpInputContainer>

          <Box textAlign="center">
            <Box>
              Didn't receive a code?{' '}
              <ButtonWithLoading
                variant="linkSuccess"
                disableWhenLoading
                onClick={this.resend}
                loading={isResendingSms}
                showSpinnerBesideText={true}
              >
                Resend {config.channel}
              </ButtonWithLoading>
            </Box>
            {!isOffshore && (
              <Box>
                Details incorrect?{' '}
                <Link
                  to={urlTo(urlIds.updateRegistrationInfo)}
                  className="fs12"
                >
                  <Button variant="linkSuccess">Update</Button>
                </Link>
              </Box>
            )}
          </Box>

          <button
            disabled={disabled || isResendingSms}
            type="button"
            onClick={this.submit}
            className="mt50"
          >
            Verify
            <i className={disabled ? 'fa fa-spinner fa-pulse' : ''} />{' '}
          </button>

          {successMsg}

          {errorMsg}
        </div>
        {<RolePrompt />}
      </div>
    )
  }
}

export default MobileVerificationForm
