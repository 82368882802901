import React, { useEffect, useReducer } from 'react'

import { DefineKeySet } from 'app/features/teams/key-logger/AddKeySetLoggerModal/DefineKeySet'
import { KeySetImages } from 'app/features/teams/key-logger/AddKeySetLoggerModal/KeySetImages'
import { StepsModalContainer } from 'app/features/teams/key-logger/components/StepsModalContainer'
import { StepsModalHeader } from 'app/features/teams/key-logger/components/StepsModalHeader'

export const addingKeySetStepsConfig = {
  definingKeySet: {
    id: 'definingKeySet',
    subHeading: 'Let’s add some key set details',
  },
  addingImageOfKeySet: {
    id: 'addingImageOfKeySet',
    subHeading: 'Image of key set',
  },
}

const addingKeySetStepsConfigArr = Object.values(addingKeySetStepsConfig).map(
  (def, index) => ({ def, index }),
)

const actionTypes = {
  keySetChanged: 'KEY_SET_CHANGED',
  stepSaved: 'STEP_SAVED',
  backStep: 'BACK_STEP',
  nextStep: 'NEXT_STEP',
  closeModal: 'CLOSE_MODAL',
}

const actions = {
  stepSaved: (keySet) => ({ type: actionTypes.stepSaved, keySet }),
  backStep: () => ({
    type: actionTypes.backStep,
    indexModifier: -1,
  }),
  nextStep: () => ({
    type: actionTypes.nextStep,
    indexModifier: 1,
  }),
  keySetChanged: () => ({ type: actionTypes.keySetChanged }),
  closeModal: () => ({ type: actionTypes.closeModal }),
}

const addKeySetReducer = (state, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.keySetChanged: {
      return {
        ...state,
        isChanged: true,
      }
    }

    case actionTypes.stepSaved: {
      const { keySet } = action
      return {
        ...state,
        keySet: keySet || state.keySet,
        showCurrentStep: false,
      }
    }

    case actionTypes.nextStep:
    case actionTypes.backStep: {
      const { indexModifier } = action
      const { index } = state.currentStep
      const stepConfig = addingKeySetStepsConfigArr[index + indexModifier]
      return {
        ...state,
        currentStep: stepConfig,
        showCurrentStep: true,
      }
    }

    case actionTypes.closeModal: {
      return {
        ...state,
        closeModal: true,
      }
    }
  }

  return state
}

export const AddKeySetLoggerModal = ({
  closeModal: closeModalCb,
  keySet: initialKeySet,
}) => {
  const [cmpState, cmpStateDispatch] = useReducer(addKeySetReducer, {
    keySet: initialKeySet,
    isChanged: false,
    currentStep: addingKeySetStepsConfigArr[0],
    showCurrentStep: true,
    closeModal: false,
  })

  useEffect(() => {
    if (cmpState.closeModal) {
      console.log('closeModalCb(cmpState.isChanged && cmpState.keySet)', {
        cmpState,
      })
      closeModalCb(cmpState.isChanged && cmpState.keySet)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmpState.closeModal])

  const onBack = () => {
    const { index } = cmpState.currentStep
    if (index < 1) return closeModal()
    cmpStateDispatch(actions.backStep(null))
  }

  const onNext = (updatedKeySet) => {
    const { index } = cmpState.currentStep
    if (index === addingKeySetStepsConfigArr.length - 1) {
      closeModal()
    } else {
      cmpStateDispatch(actions.nextStep(updatedKeySet))
    }
  }

  const onSaveKeySet = (updateKeySet) => {
    if (updateKeySet && !cmpState.isChanged) {
      cmpStateDispatch(actions.keySetChanged())
    }

    cmpStateDispatch(actions.stepSaved(updateKeySet))
    onNext(updateKeySet)
  }

  const closeModal = () => cmpStateDispatch(actions.closeModal())

  if (!cmpState.currentStep.def) return null

  const progressBarConfig = {
    totalStepsCount: addingKeySetStepsConfigArr.length,
    lastCheckedStep: cmpState.currentStep.index + 1,
  }

  const modalContent = (
    <>
      {cmpState.currentStep.def.id ===
        addingKeySetStepsConfig.definingKeySet.id && (
        <DefineKeySet keySet={cmpState.keySet} afterSave={onSaveKeySet} />
      )}

      {cmpState.currentStep.def.id ===
        addingKeySetStepsConfig.addingImageOfKeySet.id && (
        <KeySetImages keySet={cmpState.keySet} afterSave={onSaveKeySet} />
      )}
    </>
  )

  return (
    <StepsModalContainer takeMinHeight>
      <StepsModalHeader
        title="Add Key Set"
        actions={{ onDismiss: closeModal, onBack }}
        progressBarConfig={progressBarConfig}
      >
        {cmpState.currentStep.def.subHeading}
      </StepsModalHeader>
      {cmpState.showCurrentStep && modalContent}
    </StepsModalContainer>
  )
}
