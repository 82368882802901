import * as React from 'react'

function SvgUploadRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 16.296h-4c-.55 0-1-.45-1-1v-5H7.41c-.89 0-1.33-1.08-.7-1.71l4.59-4.59a.996.996 0 011.41 0l4.59 4.59c.63.63.18 1.71-.71 1.71H15v5c0 .55-.45 1-1 1zm4 2H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgUploadRounded
