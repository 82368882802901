import React from 'react'

import styled from 'styled-components'

import { ModalContentWrapper } from 'app/modals/modal-styled'
import Spinner from 'app/sm/common/spinner'
import * as helpers from 'app/sm/helpers'
import ViewingContactItem from 'app/sm/inspections/components/viewing_contact_item'
import ViewingContactSearchSection from 'app/sm/inspections/components/viewing_contact_search_section'

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #5a9bee;
  text-decoration: underline;

  &:hover,
  :focus {
    cursor: pointer;
    outline: none;
    color: #1771e4;
    text-decoration: underline;
  }
  @media (max-width: 767px) {
    font-size: 16px !important;
  }
`

let totalSearchRequestCount = 0
class AttendeeHubModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      contacts: [],
      showSpinner: false,
      mobileSearchText: '',
      nameSearchText: '',
      displaySearch: false,
    }
    this.onSearchTextChange = this.onSearchTextChange.bind(this)
  }

  componentDidMount() {
    this.props.clearError()
    const autofocusElement = document.getElementById('search-bar')
    autofocusElement.focus()
    const { clearAddAttendeePreloadContent } = this.props
    if (clearAddAttendeePreloadContent) {
      clearAddAttendeePreloadContent()
    }
  }

  onSearchTextChange = (field) => {
    return (event) => {
      const { fetchAgencyApplicantsElasticSearchResult, teamId } = this.props

      clearInterval(this.searchContacts)

      this.setState(
        {
          [field]: event.target.value,
          enableFoundNothingText: false,
        },
        () => {
          const { mobileSearchText = '', nameSearchText = '' } = this.state
          const formattedMobileText =
            mobileSearchText.indexOf('04') === 0
              ? mobileSearchText.replace(/^.{2}/g, '+614')
              : mobileSearchText
          const finalSearchText =
            `${formattedMobileText} ${nameSearchText}`.trim()
          if (finalSearchText) {
            let currentSearchRequestCount = ++totalSearchRequestCount
            this.setState({
              showSpinner: true,
            })
            this.searchContacts = setTimeout(
              () =>
                fetchAgencyApplicantsElasticSearchResult(
                  teamId,
                  finalSearchText,
                )
                  .then((result) => {
                    if (currentSearchRequestCount === totalSearchRequestCount) {
                      this.setState({
                        contacts: result.contacts.sort(
                          helpers.sortArrayAlphabeticallyByFirstName,
                        ),
                        total: result.total,
                        showSpinner: false,
                        enableFoundNothingText: result.total === 0,
                        displaySearch: result.total !== 0,
                      })
                    }
                  })
                  .catch((error) => {
                    this.setState({
                      apiError: error,
                      showSpinner: false,
                      displaySearch: false,
                    })
                  }),
              500,
            )
          } else if (!finalSearchText) {
            this.setState({
              enableFoundNothingText: false,
              showSpinner: false,
              displaySearch: false,
            })
          }
        },
      )
    }
  }

  render() {
    const {
      toggleAttendeeHubModal,
      onAttendeeHubModalAddIconClicked,
      onOnSiteAttendeeNameClicked,
      filteredEnquirersForAttendeeHub = [],
      onModalTitleClicked,
    } = this.props
    const {
      searchText = '',
      contacts = [],
      showSpinner = false,
      enableFoundNothingText = false,
      displaySearch,
      mobileSearchText = '',
      nameSearchText = '',
    } = this.state
    const showContacts =
      !showSpinner && (mobileSearchText || nameSearchText) && contacts
    const showFilteredEnquirers =
      !showSpinner && filteredEnquirersForAttendeeHub
    return (
      <div className="modal-fullscreen background-dark-grey modal-layout-flex-container viewing-view">
        <ModalContentWrapper takeMinHeight>
          <div className="modal-close top5">
            <i
              className="fa fa-times font-size-24"
              onClick={() => toggleAttendeeHubModal(false)}
            />
          </div>
          <div className="font-size-18 fw500 position-absolute left15 top20 mobile-font-size-16">
            Add:
          </div>
          <div className="modal-header display-flex justify-content-space-around align-items-center ml5">
            <Title className="fw700">Attendee</Title>
            <Title
              onClick={() =>
                onModalTitleClicked(helpers.modalNameCollection.noteModal)
              }
            >
              Note
            </Title>
            <Title
              onClick={() =>
                onModalTitleClicked(helpers.modalNameCollection.addAccessModal)
              }
            >
              Access
            </Title>
          </div>
          <div className="modal-body p15 viewing-card">
            <span className="font-size-16 fw500 pl10">{`Search or create contact`}</span>
            <div className="search-section-container mb10">
              <ViewingContactSearchSection
                nameSearchText={nameSearchText}
                mobileSearchText={mobileSearchText}
                onSearchTextChange={this.onSearchTextChange}
                onAddClicked={onAttendeeHubModalAddIconClicked}
              />
            </div>
            <div className="searched-result-section ra-card__list mt30 pl0">
              {showSpinner && <Spinner />}
              {enableFoundNothingText && !showSpinner && (
                <p className="text-align-center mt15">No result is found</p>
              )}
              {displaySearch && (
                <span className="font-size-16 fw500 pl10">{`Search results`}</span>
              )}
              {showContacts &&
                contacts.map((contact) => {
                  return (
                    <li className="viewing-items pt0 pl10 pb10">
                      <div
                        className="content-wrapper pt15 pb5 cursor-pointer"
                        onClick={() => onOnSiteAttendeeNameClicked(contact)}
                      >
                        <ViewingContactItem contact={contact} isSearch={true} />
                      </div>
                    </li>
                  )
                })}
              {showFilteredEnquirers &&
                filteredEnquirersForAttendeeHub.length > 0 && (
                  <div className="mt10">
                    <span className="font-size-16 fw500 pl10">{`Add from recent enquirers`}</span>
                  </div>
                )}
              {showFilteredEnquirers &&
                filteredEnquirersForAttendeeHub.length === 0 && (
                  <p className="text-align-center mt15">
                    No recent enquirers, search contacts or{' '}
                    <span
                      className="linkStyle"
                      onClick={() => onAttendeeHubModalAddIconClicked()}
                    >
                      add
                    </span>
                  </p>
                )}
              {!showSpinner &&
                !searchText &&
                filteredEnquirersForAttendeeHub.map((contact) => {
                  return (
                    <li
                      className="viewing-items pt0 pl0 pb10"
                      key={contact.enquiryDate}
                    >
                      <div
                        className="content-wrapper pt5 cursor-pointer"
                        onClick={() => onOnSiteAttendeeNameClicked(contact)}
                      >
                        <ViewingContactItem
                          contact={contact}
                          isEnquiry
                          containerClass={'recent-enquirers'}
                        />
                      </div>
                    </li>
                  )
                })}
            </div>
          </div>
        </ModalContentWrapper>
      </div>
    )
  }
}

export default AttendeeHubModal
