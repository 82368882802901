import React from 'react'

import styled from 'styled-components'

import * as Form from 'app/components/forms/forms'
import * as snugNotifier from 'app/services/snugNotifier'

const LIMIT_VIEWING_REGISTRANTS_UPDATED_SUCCESS =
  'Your viewing registrants count has been updated.'

const registrationLimitNumbers = [
  { label: 'Select', value: 0 },
  { label: '1 registrant', value: 1 },
  { label: '2 registrants', value: 2 },
  { label: '3 registrants', value: 3 },
  { label: '4 registrants', value: 4 },
  { label: '5 registrants', value: 5 },
  { label: '10 registrants', value: 10 },
  { label: '15 registrants', value: 15 },
  { label: '20 registrants', value: 20 },
  { label: '25 registrants', value: 25 },
  { label: '30 registrants', value: 30 },
]

const LimitViewingRegistrantsContainer = styled.div`
  clear: both;
`

class LimitViewingRegistrants extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewingCapEnabled: false,
      viewingCapOption: 0,
    }
  }

  componentDidMount() {
    const { offer } = this.props
    const { limitViewingRegistrantsEnabled, limitViewingRegistrants } =
      offer || {}
    this.setState({
      viewingCapEnabled: limitViewingRegistrantsEnabled,
      viewingCapOption: limitViewingRegistrants,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.offer && this.props.offer !== prevProps.offer) {
      const { offer } = this.props
      const { limitViewingRegistrantsEnabled, limitViewingRegistrants } =
        offer || {}
      this.setState({
        viewingCapEnabled: limitViewingRegistrantsEnabled,
        viewingCapOption: limitViewingRegistrants,
      })
    }
  }

  onViewingCapCheckboxClicked = (event) => {
    const { checked } = event.target || {}
    this.setState(
      {
        viewingCapEnabled: checked,
      },
      () => {
        if (!checked) {
          const data = {
            value: 0,
          }
          this.onUpdateViewingRegistrantLimit(data)
        }
      },
    )
  }

  onUpdateViewingRegistrantLimit = (data) => {
    const { guidDetails, updateOfferViewingRegistrantsLimit } = this.props
    const { offerGUID, agencyGUID } = guidDetails
    const { viewingCapOption, viewingCapEnabled } = this.state
    if (Number(data.value) === 0 && viewingCapEnabled) {
      snugNotifier.error('Please select registrants')
      return
    }
    if (data.value === viewingCapOption && viewingCapEnabled) {
      return
    }
    const requestBody = {
      limitViewingRegistrantsEnabled: viewingCapEnabled,
      limitViewingRegistrants: Number(data.value),
    }
    this.setState(
      {
        viewingCapOption: Number(data.value),
      },
      () => {
        if (offerGUID && agencyGUID) {
          updateOfferViewingRegistrantsLimit(agencyGUID, offerGUID, requestBody)
            .then(() =>
              snugNotifier.success(LIMIT_VIEWING_REGISTRANTS_UPDATED_SUCCESS),
            )
            .catch((error) => snugNotifier.error(error))
        }
      },
    )
  }

  render() {
    const { viewingCapEnabled, viewingCapOption } = this.state
    return (
      <LimitViewingRegistrantsContainer>
        <h5 className="mt20">Viewing Registrants</h5>
        <div className="mt10 mb20">
          Select to limit the viewing registrants for this property.
        </div>
        <div>
          <Form.CheckBoxGeneral
            label="Limit viewing registrations"
            checked={viewingCapEnabled}
            onChange={this.onViewingCapCheckboxClicked}
            eventProcessedByComponent
          />
          {!!viewingCapEnabled && (
            <div>
              <Form.Dropdown
                label="Please select the max number of registrants"
                value={viewingCapOption}
                options={registrationLimitNumbers}
                onChange={this.onUpdateViewingRegistrantLimit}
                useLabelValueOptions
              />
            </div>
          )}
        </div>
      </LimitViewingRegistrantsContainer>
    )
  }
}

export default LimitViewingRegistrants
