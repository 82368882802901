import React from 'react'

import {
  ButtonWithLoading,
  Flex,
} from 'app/components/design-system-components'
import * as Form from 'app/components/forms/forms'

const AcceptStage = ({
  onAddComment,
  comment = '',
  onSubmit,
  acceptOrDeclineInProgress,
}) => {
  return (
    <>
      <div className="mt30">
        <h5 className="text-align-left gray-color">
          Enter any comments for the Property Manager.
        </h5>
        <Form.CommentArea
          rows={3}
          maxLength={160}
          placeholder="Add Comment..."
          onChange={onAddComment}
          value={comment}
        />
      </div>
      <p className="mt20 text-align-left">
        Please note that while the application may be accepted, the confirmation
        of tenancy is pending receipt of payment and signing of the lease by the
        renter.
      </p>
      <Flex justifyContent="center">
        <ButtonWithLoading
          onClick={onSubmit}
          loading={acceptOrDeclineInProgress}
          sizeVariant="large"
        >
          Submit
        </ButtonWithLoading>
      </Flex>
    </>
  )
}

export default AcceptStage
