import { useState } from 'react'

import styled from 'styled-components'
import { layout } from 'styled-system'

import { Chip } from 'app/components/design-system-components/chip'
import Flex from 'app/components/design-system-components/Flex'
import * as themeGetters from 'app/components/design-system-components/theme/getters'
import { CustomInputWrapper } from 'app/forms/CustomInputWrapper'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const StyledInput = styled.input`
  border: 0;
  display: flex;
  flex: 1 1;
  padding: ${themeGetters.spacing(3)};
  ${layout}
`

const InputWrapper = styled(Flex)`
  border: 1px solid ${themeGetters.color('grey200')};
  border-radius: ${themeGetters.radius(6)}px;
  padding: ${themeGetters.spacing(1)} ${themeGetters.spacing(2)};
`

export const TagsInput = ({
  id,
  value = [],
  changeHandler,
  blurHandler = () => {},
  disabled,
  inputPlaceholder = '',
}) => {
  const [inputVal, setInputVal] = useState('')

  const onDismissItem = (item) => {
    const selectedItems = (value || []).filter(
      ({ optionMeta }) => optionMeta.id !== item.optionMeta.id,
    )
    changeHandler(selectedItems)
  }

  const onInputKeyDown = (event) => {
    const { key } = event
    // will always catch Enter to prevent submitting by mistake while making some changes to tags
    if (key === 'Enter' || key === ',') {
      event.preventDefault()

      tryToInsertTag()
    }
  }

  const onBlur = () => {
    tryToInsertTag()
    setTimeout(() => blurHandler())
  }

  const tryToInsertTag = () => {
    const trimmedInput = inputVal.trim()

    if (trimmedInput.length) {
      const currentValuesIds = value.map(({ optionMeta }) => optionMeta.id)
      setInputVal('')

      if (currentValuesIds.includes(trimmedInput)) return

      changeHandler([
        ...value,
        {
          option: trimmedInput,
          optionMeta: { id: trimmedInput, displayText: trimmedInput },
        },
      ])
    }
  }

  return (
    <InputWrapper as="label" htmlFor={id} flexWrap="wrap">
      {value &&
        value.map((item) => (
          <Chip
            showDismissBtn={true}
            onDismissClicked={() => onDismissItem(item)}
            mt={theme.space[1] + 'px'}
            mb={theme.space[1] + 'px'}
            mr={theme.space[3] + 'px'}
          >
            {item.optionMeta.displayText}
          </Chip>
        ))}

      <StyledInput
        id={id}
        value={inputVal}
        onChange={({ target }) => setInputVal(target.value)}
        onKeyDown={onInputKeyDown}
        onBlur={onBlur}
        placeholder={inputPlaceholder}
        autoComplete="off"
      />
    </InputWrapper>
  )
}

export const TagsInputField = (props) => {
  const { name } = props
  return (
    <CustomInputWrapper
      name={name}
      customInputCmp={(customInputProps) => (
        <TagsInput {...customInputProps} {...props} />
      )}
    />
  )
}
