import React, { useState } from 'react'

import moment from 'moment'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { Modal } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import { durationOptionsNumbers } from 'app/components/display/edit_viewing_modal/viewing_modal_data'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import * as helpers from 'app/sm/helpers'
import { MergeViewingModal } from 'app/sm/inspections/components/merge_viewing_modal'
import { addViewing } from 'app/sm/ppp/viewing_actions'
import * as viewingsHelpers from 'app/utils/viewings/helpers'
import { isViewingMergeEnabled } from 'config/features'

export const ViewingContextMenu = ({
  propertyViewing,
  property,
  propertyViewings,
  onsiteRegistrants,
  defaultSelectedViewingDurationEnabled,
  defaultSelectedViewingDuration,
  agencyId,
  isAccountLite,
  onClickRequestFeedback,
  confirmCancelViewing,
  onShowEditViewingModal,
  refreshRegistrantsList,
  fetchAllPropertyFutureViewings,
  customClassName = '',
  teamSlug,
  onClickSendRegistrantsMessage,
}) => {
  const dispatch = useDispatch()
  const [mergeViewingCancelModal, setMergeViewingCancelModal] = useState({
    isOpen: false,
    config: {},
  })
  const [mergeViewingsModal, setMergeViewingsModal] = useState({
    isOpen: false,
    config: {},
  })
  const [addViewingModal, setAddViewingModal] = useState({
    isOpen: false,
    config: {},
  })
  const isFutureViewing = moment(propertyViewing?.viewing?.startDate).isAfter()
  const isMergeFeatureEnabled = isViewingMergeEnabled(teamSlug)
  const onMergeContextMenuClicked = (viewing, onsiteRegistrants) => {
    if (onsiteRegistrants.length === 0) {
      setMergeViewingCancelModal({
        isOpen: true,
        config: { propertyViewing: viewing },
      })
    } else {
      setMergeViewingsModal({
        isOpen: true,
        config: { propertyViewing: viewing },
      })
    }
  }

  const closeCancelViewingMergeModal = () => {
    setMergeViewingCancelModal({
      isOpen: false,
      config: {},
    })
  }

  const toggleAddViewingModal = () => {
    setAddViewingModal({
      isOpen: !addViewingModal.isOpen,
      config: addViewingModal.config,
    })
  }

  const renderCancelViewingModal = () => {
    return (
      <>
        {mergeViewingCancelModal.isOpen && (
          <Modal
            modalHeading="Cancel viewing"
            modalBody="There are no attendees that can be merged to another viewing. Would you like to cancel the viewing instead?"
            primaryLabel="Cancel viewing"
            primaryAction={() =>
              confirmCancelViewing(
                mergeViewingCancelModal.config.propertyViewing.viewing.guidID,
                property.guidID,
              )
            }
            secondaryLabel="Close"
            secondaryAction={closeCancelViewingMergeModal}
            toggleModal={closeCancelViewingMergeModal}
          />
        )}
      </>
    )
  }

  const closeMergeViewingModal = () => {
    setMergeViewingsModal({
      isOpen: false,
      config: {},
    })
  }

  const onMergeSuccess = () => {
    refreshRegistrantsList()
    closeMergeViewingModal()
  }

  const dispatchAddViewing = (
    id,
    startdate,
    duration,
    fromScheduler,
    skipDoubleBookingCheck,
    options,
  ) => {
    return dispatch(
      addViewing(
        id,
        startdate,
        duration,
        fromScheduler,
        skipDoubleBookingCheck,
        options,
      ),
    )
  }

  const onSubmitAddViewingModal = ({
    startDate,
    startTime,
    duration,
    ...viewingOptions
  }) => {
    const mergedStartDate = `${moment(startDate).format(
      helpers.dateWithDash,
    )} ${startTime}`
    const formattedStartDate = helpers.getStandardFormattedDate(
      moment(mergedStartDate, helpers.dateTimeWithDash).utc(),
      helpers.utcFormatTimeStamp,
    )
    const offerId = property && property.offers[0].guidID
    if (property && property.guidID) {
      dispatchAddViewing(
        offerId,
        formattedStartDate,
        durationOptionsNumbers[duration],
        false,
        false,
        viewingOptions,
      )
        .then(() => {
          toggleAddViewingModal()
          refreshRegistrantsList()
          toast.success('Viewing Successfully added!')
        })
        .catch((error) => {
          if (
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForProperty] ||
            error === ErrorMessages[Errors.ErrorViewingTimeExistsForManager]
          ) {
            if (window.confirm(viewingsHelpers.conflictingTimeMessage)) {
              dispatchAddViewing(
                offerId,
                formattedStartDate,
                durationOptionsNumbers[duration],
                false,
                true,
                viewingOptions,
              )
                .then((data) => {
                  toggleAddViewingModal()
                  refreshRegistrantsList()
                  toast.success('Viewing Successfully added!')
                })
                .catch((error) => {
                  toast.error(error)
                })
            }
          } else {
            toast.error(error)
          }
        })
    }
  }

  const renderMergeViewingModal = () => {
    const futureViewings = !!propertyViewings
      ? Array.prototype.concat
          .apply([], Object.values(propertyViewings))
          .filter((pv) => {
            return (
              moment(pv.viewing.startDate).isAfter(moment()) &&
              pv.viewing?.guidID !==
                mergeViewingsModal.config.propertyViewing?.viewing.guidID
            )
          })
      : []
    return (
      <>
        {mergeViewingsModal.isOpen && (
          <MergeViewingModal
            futurePropertyViewings={futureViewings}
            cancellingViewingDetails={mergeViewingsModal.config.propertyViewing}
            closeModel={closeMergeViewingModal}
            onMergeSuccess={onMergeSuccess}
            addViewingModal={toggleAddViewingModal}
          />
        )}
      </>
    )
  }

  const renderAddViewingModal = () => {
    return (
      <>
        {addViewingModal.isOpen && (
          <Display.EditViewingModal
            title="Add Viewing"
            primaryActionLabel="Add"
            secondaryActionLabel="Cancel"
            fetchAllPropertyFutureViewings={fetchAllPropertyFutureViewings}
            defaultSelectedViewingDurationEnabled={
              defaultSelectedViewingDurationEnabled
            }
            defaultSelectedViewingDuration={defaultSelectedViewingDuration}
            teamGUID={agencyId}
            toggleModal={toggleAddViewingModal}
            secondaryButtonAction={toggleAddViewingModal}
            primaryButtonActionInParent={onSubmitAddViewingModal}
          />
        )}
      </>
    )
  }

  return (
    <div className={`viewing-combo-button-wrapper ml-auto ${customClassName}`}>
      <div className="dropdown actions">
        <div
          className="dropdown-toggle drop-down-toggler"
          data-toggle="dropdown"
        >
          <i className="icon-more drop-down-spots" />
        </div>
        <ul className="dropdown-menu team-overview-dropdown">
          <li className={`link-styles`} onClick={onClickSendRegistrantsMessage}>
            Message
          </li>
          <li
            className={`link-styles ${
              isAccountLite ? 'background-color-gray-with-opacity ' : ''
            }`}
            onClick={onClickRequestFeedback}
          >
            Request feedback
          </li>
          <li onClick={confirmCancelViewing} className="link-styles">
            Cancel
          </li>
          <li onClick={onShowEditViewingModal} className="link-styles">
            Edit
          </li>
          {isFutureViewing &&
            isMergeFeatureEnabled &&
            !!propertyViewing.viewing && (
              <li
                onClick={() =>
                  onMergeContextMenuClicked(propertyViewing, onsiteRegistrants)
                }
                className="link-styles"
              >
                Merge
              </li>
            )}
          <li onClick={refreshRegistrantsList} className="link-styles">
            Refresh
          </li>
        </ul>
      </div>
      {renderMergeViewingModal()}
      {renderAddViewingModal()}
      {renderCancelViewingModal()}
    </div>
  )
}
