import React from 'react'

import { getNumberType } from 'libphonenumber-js'
import metadata from 'libphonenumber-js/metadata.full.json'
import moment from 'moment'

import {
  Box,
  Button,
  IconButton,
  IntlTelInputWrapper,
} from 'app/components/design-system-components'
import ActivityItemWithDot from 'app/components/design-system-components/ActivityItemWithDot'
import { ClearRounded } from 'app/components/design-system-components/icons/content/index'
import { NavigateBeforeRounded } from 'app/components/design-system-components/icons/image/index'
import NoteComponentFlatStyle from 'app/components/display/application_note/component_flatstyle'
import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import { ModalHeader } from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingDetailsTabsModal'
import * as snugNotifier from 'app/services/snugNotifier'
import {
  ErrorMessage,
  intTelTop10List,
  isValidMobileNumber,
} from 'app/shared_components/helpers'
import * as validations from 'app/shared_components/validations'
import * as helpers from 'app/sm/helpers'
import { checkForWindowWidth } from 'app/sm/helpers'
import {
  defaultCommentTemplates,
  defaultCommentTemplatesPrivate,
} from 'app/sm/inspections/components/add_comment_modal'
import { getAllRenterPropertyNotes } from 'app/sm/inspections/helpers/api'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import { FeatureFlag } from 'config/features'

const bedroomsOptionsCollection = ['1', '2', '3', '4+']

const CREATE_MODE = 'create'
const EDIT_MODE = 'edit'

class WalkinRenterModal extends React.Component {
  constructor(props) {
    super(props)
    const { renterInfo = {}, addAttendeePreloadContent = {} } = props
    const { nameSearchText = '', mobileSearchText = '' } =
      addAttendeePreloadContent
    this.state = {
      firstName: renterInfo.firstName || nameSearchText || '',
      lastName: renterInfo.lastName || '',
      email: renterInfo.email || '',
      mobilePhone: renterInfo.phone || mobileSearchText || '',
      checkIn: renterInfo.checkIn || false,
      didNotAttend: renterInfo.didNotAttend || false,
      interested:
        renterInfo.interested === null || renterInfo.interested === undefined
          ? true
          : renterInfo.interested,
      clicked: false,
      errors: {},
      showMoreInputBox: false,
      successMessage: '',
      detailLinkText: 'Add more details',
      commentTemplatesExternal: defaultCommentTemplates,
      commentTemplatesPrivate: [
        ...defaultCommentTemplates,
        ...defaultCommentTemplatesPrivate,
      ],
      comment: renterInfo.comment || '',
      externalComment:
        renterInfo.externalComment !== undefined
          ? renterInfo.externalComment
          : '' || '',
      sendMatches: renterInfo.sendMatches || true,
      parking: renterInfo.parking || 0,
      bedrooms: renterInfo.bedrooms || '',
      moveInPreference: '',
      modelHeader: renterInfo.modelHeader || '',
      anyUnsavedChanges: false,
      notesForRegistrant: [],
    }
  }

  componentDidMount() {
    this.props.clearError && this.props.clearError()
    const {
      targetAttendee = {},
      walkinModalStatus = 'create',
      addAttendeePreloadContent,
      propertyId,
      renterInfo,
      agencyGUID,
    } = this.props
    if (walkinModalStatus === 'edit') {
      this.setState({
        firstName: targetAttendee.firstName,
        lastName: targetAttendee.lastName,
        email: targetAttendee.email,
        showMoreInputBox: true,
        detailLinkText: 'Hide more details',
        mobilePhone: targetAttendee.mobilePhone || targetAttendee.phone,
        checkIn: targetAttendee.checkIn || false,
        didNotAttend: targetAttendee.didNotAttend || false,
        interested: targetAttendee.interested !== false,
        comment: targetAttendee.comment || '',
        sendMatches: targetAttendee.sendMatches || false,
        parking: targetAttendee.parking || 0,
        bedrooms: targetAttendee.bedrooms || '',
        moveInPreference: targetAttendee.moveInPreference || '',
        budget: targetAttendee.budget || '',
        externalComment: targetAttendee.externalNote || '',
      })
      if (
        (targetAttendee?.email ||
          targetAttendee?.phone ||
          targetAttendee?.mobilePhone) &&
        agencyGUID
      ) {
        const attendeePhone = targetAttendee.mobilePhone || targetAttendee.phone
        getAllRenterPropertyNotes(
          agencyGUID,
          targetAttendee.email,
          attendeePhone,
        )
          .then((data) => {
            this.setState({
              notesForRegistrant: data,
            })
          })
          .catch((error) => {
            snugNotifier.error(error?.message)
          })
      }
    }
    if (
      addAttendeePreloadContent &&
      (addAttendeePreloadContent.nameSearchText ||
        addAttendeePreloadContent.mobileSearchText)
    ) {
      this.setState({
        anyUnsavedChanges: true,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      parking: prevParking,
      bedrooms: prevBedrooms,
      sendMatches: prevSendMatches,
      moveInPreference: prevMoveInPreference,
      budget: prevBudget,
    } = prevProps.renterInfo || {}
    const {
      parking: currentParking,
      bedrooms: currentBedrooms,
      sendMatches: currentSendMatches,
      moveInPreference: currentMoveInPreference,
      budget: currentBudget,
    } = this.props.renterInfo || {}
    if (
      prevParking !== currentParking ||
      prevBedrooms !== currentBedrooms ||
      prevSendMatches !== currentSendMatches ||
      prevMoveInPreference !== currentMoveInPreference ||
      prevBudget !== currentBudget
    ) {
      this.setState({
        parking: currentParking,
        bedrooms: currentBedrooms,
        sendMatches: currentSendMatches,
        moveInPreference: currentMoveInPreference,
        budget: currentBudget,
      })
    }
    if (prevProps.viewingAttendeeCount !== this.props.viewingAttendeeCount) {
      this.setState(
        {
          successMessage: 'Added successfully',
        },
        () => {
          this.setState({
            firstName: '',
            lastName: '',
            email: '',
            mobilePhone: '',
            checkIn: false,
            clicked: false,
            errors: {},
            showMoreInputBox: false,
            comment: '',
            externalComment: '',
            bedrooms: '0',
            interested: false,
            parking: 0,
            sendMatches: false,
            moveInPreference: '',
            budget: '',
          })
        },
      )
    }
    if (this.props.error !== prevProps.error && !!this.props.error) {
      this.setState({
        successMessage: '',
      })
    }
  }

  onPillClicked(index, source) {
    this.setState({
      anyUnsavedChanges: true,
    })
    const { commentTemplatesExternal, commentTemplatesPrivate } = this.state
    let targetTemplate = []

    if (source === 'comment') {
      targetTemplate = commentTemplatesPrivate.find(
        (commentTemplate) => commentTemplate.index === index,
      )
      this.setState({
        comment: `${this.state.comment} ${targetTemplate.text}`,
      })
    } else if (source === 'externalComment') {
      targetTemplate = commentTemplatesExternal.find(
        (commentTemplate) => commentTemplate.index === index,
      )
      this.setState({
        externalComment: `${this.state.externalComment} ${targetTemplate.text}`,
      })
    }
  }

  onShowMoreClicked = () => {
    const { detailLinkText } = this.state
    detailLinkText === 'Add more details'
      ? this.setState({
          showMoreInputBox: true,
          detailLinkText: 'Hide details',
        })
      : this.setState({
          showMoreInputBox: false,
          detailLinkText: 'Add more details',
        })
  }

  getModalHeader = (walkinModalStatus) => {
    switch (walkinModalStatus) {
      case CREATE_MODE:
        return 'Add Attendee'
      case EDIT_MODE:
      default:
        return 'Edit Attendee'
    }
  }

  setValidationErrors = (field, error, onBlur) => {
    const { errors } = this.state
    if (error.length === 0) {
      delete errors[field]
      this.setState({ errors })
    } else if (onBlur) {
      errors[field] = error
      this.setState({ errors })
    }
  }

  update = (field, value) => {
    this.setState(
      { [field]: value, anyUnsavedChanges: true },
      this.validate(field, false),
    )
    if (field === 'checkIn') {
      value === true && this.setState({ didNotAttend: false })
    }
  }

  updatePreference = (field, option) => {
    return (data) => {
      this.setState({
        anyUnsavedChanges: true,
      })
      let { value, error = '' } = data
      if (option) {
        this.setState({ [field]: this.state[field] === option ? '' : option })
        return
      }
      if (field === 'moveInPreference') {
        if (data.error === '') {
          value =
            value !== ''
              ? moment(value).format(dateTimeHelpers.DATE_WITH_DASH)
              : ''
        }
      }
      this.setState(
        {
          [field]: value,
          errors: {
            ...this.state.errors,
            [field]: error,
          },
        },
        this.validate(field, false),
      )
      if (field === 'checkIn') {
        value === true && this.setState({ didNotAttend: false })
      }
    }
  }

  validate = (field, onBlur) => {
    return () => {
      const { firstName, lastName, email } = this.state
      switch (field) {
        case 'firstName':
          return this.setValidationErrors(
            field,
            validations.validateFirstName(firstName),
            onBlur,
          )
        case 'lastName':
          return this.setValidationErrors(
            field,
            validations.validateNameOptional(lastName),
            onBlur,
          )
        case 'email': {
          return this.setValidationErrors(
            field,
            validations.validateEmailOptional(email),
            onBlur,
          )
        }
        default:
          return true
      }
    }
  }

  validateInternationalNumber = (onBlur) => {
    return (status, value, countryData, number, id) => {
      this.setState({
        mobilePhone: number.trim(),
        anyUnsavedChanges: true,
      })
      const actualNumberType = getNumberType(number, metadata)
      this.setValidationErrors(
        'mobilePhone',
        isValidMobileNumber(actualNumberType) || !value
          ? []
          : [ErrorMessages[Errors.ErrorValidatingMobile]],
        onBlur,
      )
    }
  }

  promptUnsavedChangesMessage = () => {
    return window.confirm('Unsaved changes will be lost. Continue to close?')
  }

  checkForChangesBeforeClosingModal = () => {
    const { toggleWalkInModal } = this.props
    const { anyUnsavedChanges } = this.state
    if (anyUnsavedChanges) {
      const returnedValue = this.promptUnsavedChangesMessage()
      if (!returnedValue) {
        return
      }
    }
    toggleWalkInModal(false)
  }

  render() {
    const {
      toggleWalkInModal,
      addWalkInRenter,
      propertyId,
      error,
      walkinModalStatus,
      onUpdateRenterStatus,
      targetAttendee = {},
      onSearchAttendeeLinkClicked,
      enableOptions = {},
      saveFunction,
      disableSaveButton,
      onRemoveRegistrant,
      notes = [],
      teamSlug = '',
    } = this.props
    const {
      firstName,
      lastName,
      email,
      mobilePhone = '',
      clicked,
      errors = {},
      checkIn,
      interested,
      sendMatches,
      successMessage,
      comment,
      commentTemplatesExternal,
      commentTemplatesPrivate,
      bedrooms = '',
      parking = 0,
      budget = '',
      moveInPreference = '',
      modelHeader,
      externalComment,
      notesForRegistrant,
    } = this.state
    const { budget: budgetError } = errors
    const { viewingID, guidID: registrantID } = targetAttendee
    const disabled =
      !(
        helpers.isNoErrorExist(errors) &&
        firstName &&
        (mobilePhone || email)
      ) && !clicked
    const isEditMode = walkinModalStatus === 'edit' && registrantID
    const {
      enableContactInfomation = false,
      enableCheckedInAndInterested = false,
      enablePreference = false,
      enableNote = false,
      showSearchButton = false,
      enableViewingBottomButtons = false,
      enableEnquirerBottomButtons = false,
      enableShowRemoveButton = false,
    } = enableOptions
    const formattedMoveInPreference =
      this.state.errors.moveInPreference !== dateTimeHelpers.INVALID_DATE_STRING
        ? moveInPreference &&
          moment(moveInPreference).format(dateTimeHelpers.DATE_WITH_SLASH)
        : moveInPreference

    const isMobileModal = checkForWindowWidth() < 576
    const modalTitleClass = isMobileModal
      ? 'flex-1'
      : 'display-flex justify-content-center flex-1'
    return (
      <div className="modal-fullscreen background-dark-grey modal-layout-flex-container">
        <ModalContentWrapper takeMinHeight>
          <ModalHeader>
            {/**/}
            {!isMobileModal && (
              <IconButton
                m={5}
                IconCmp={ClearRounded}
                onClick={() => {
                  toggleWalkInModal
                    ? this.checkForChangesBeforeClosingModal()
                    : toggleWalkInModal()
                }}
                variant="flat"
                iconProps={{ fontSize: theme.fontSizes[12] + 'px' }}
              />
            )}
            {isMobileModal && (
              <IconButton
                m={5}
                IconCmp={NavigateBeforeRounded}
                variant="flat"
                onClick={() => {
                  toggleWalkInModal
                    ? this.checkForChangesBeforeClosingModal()
                    : toggleWalkInModal()
                }}
                iconProps={{
                  fontSize: theme.fontSizes[12] + 'px',
                  color: theme.colors.watermelon,
                }}
              />
            )}
            <h4 className={modalTitleClass}>
              <span>
                {modelHeader && modelHeader !== ''
                  ? modelHeader
                  : this.getModalHeader(walkinModalStatus)}
              </span>
              {showSearchButton && (
                <span
                  className="font-size-14 linkStyle-blue mr40 fw400 underline"
                  onClick={() => onSearchAttendeeLinkClicked()}
                >
                  Search
                </span>
              )}
            </h4>
            {isEditMode && enableShowRemoveButton && (
              <Button variant="linkBlue" mr={5} onClick={onRemoveRegistrant}>
                remove
              </Button>
            )}
          </ModalHeader>
          <div className="modal-body p15 pb80 mt0">
            {enableContactInfomation && (
              <div>
                <div className="row text-align-left">
                  <div className="col-xs-6">
                    <div
                      className={`input-box ${
                        errors.firstName && disabled ? 'error' : ''
                      }`}
                    >
                      <input
                        type="text"
                        required
                        id="firstName"
                        value={firstName}
                        onChange={(e) =>
                          this.update('firstName', e.target.value)
                        }
                        onBlur={this.validate('firstName', true)}
                      />
                      <p>First Name</p>
                    </div>
                  </div>
                  <div className="col-xs-6">
                    <div
                      className={`input-box ${
                        errors.lastName && disabled ? 'error' : ''
                      }`}
                    >
                      <input
                        type="text"
                        required
                        id="lastName"
                        value={lastName}
                        onChange={(e) =>
                          this.update('lastName', e.target.value)
                        }
                        onBlur={this.validate('lastName', true)}
                      />
                      <p className="label-top">Last Name (optional)</p>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    errors.firstName && disabled
                      ? 'alert alert-danger'
                      : 'hide-alert'
                  }
                >
                  <div>{errors.firstName}</div>
                </div>
                <div
                  className={
                    errors.lastName && disabled
                      ? 'alert alert-danger'
                      : 'hide-alert'
                  }
                >
                  <div>{errors.lastName}</div>
                </div>
                <div
                  className={`input-box ${
                    errors.mobilePhone && disabled ? 'error' : ''
                  }`}
                >
                  <IntlTelInputWrapper
                    css={['intl-tel-input', '']}
                    utilsScript={'libphonenumber.js'}
                    defaultCountry={'au'}
                    preferredCountries={intTelTop10List}
                    onPhoneNumberChange={this.validateInternationalNumber(
                      false,
                    )}
                    onPhoneNumberBlur={this.validateInternationalNumber(true)}
                    value={mobilePhone}
                    placeholder={'e.g. +61 412 345 678'}
                  />
                  <p className="text-align-left">Phone Number</p>
                </div>
                <div
                  className={`input-box ${
                    errors.email && disabled ? 'error' : ''
                  }`}
                >
                  <input
                    type="email"
                    required
                    id="email"
                    value={email}
                    onChange={(e) => this.update('email', e.target.value)}
                    onBlur={this.validate('email', true)}
                  />
                  <p className="text-align-left">Email address (optional)</p>
                </div>
                <div
                  className={
                    errors.email && disabled
                      ? 'alert alert-danger'
                      : 'hide-alert'
                  }
                >
                  <div>{errors.email}</div>
                </div>
                <div
                  className={
                    errors.mobilePhone && disabled
                      ? 'alert alert-danger'
                      : 'hide-alert'
                  }
                >
                  <div>{errors.mobilePhone}</div>
                </div>
              </div>
            )}
            {enableCheckedInAndInterested && (
              <div className="display-flex">
                <Form.CheckBoxGeneral
                  label="Checked in"
                  checked={checkIn}
                  onChange={this.updatePreference('checkIn')}
                  componentClassName="flex-1"
                />
                <Form.CheckBoxGeneral
                  label="Interested"
                  checked={interested}
                  onChange={this.updatePreference('interested')}
                  componentClassName="flex-1"
                />
              </div>
            )}
            {enablePreference && (
              <div className="mt30 mb30">
                <div className="edit-attendee-container">
                  <div className="edit-attendee-header">
                    <h4 className="fw500">Preferences</h4>
                  </div>
                  <div className="mt10">
                    <div>
                      <div className="display-flex">
                        <div className="flex-1">
                          <Form.InputFutureDate
                            label="Moving date"
                            value={formattedMoveInPreference}
                            error={this.state.errors.moveInPreference}
                            onChange={this.updatePreference('moveInPreference')}
                            id="moving date"
                            isValidDate={helpers.validateFutureDate()}
                            viewMode="days"
                            containerClassName="width100 pr10"
                            customisedDateFormat={
                              dateTimeHelpers.DATE_WITH_SLASH
                            }
                            readOnly={false}
                          />
                        </div>
                        <div className="flex-1">
                          <Form.InputNumber
                            value={budget}
                            label="Budget p/wk"
                            onChange={this.updatePreference('budget')}
                            id="budget"
                            labelClass="font-size-14"
                            colorClass="color-black"
                            inputClass="width100 pr10"
                            error={budgetError}
                            isOptional={true}
                          />
                        </div>
                      </div>

                      <div className="font-size-16">Bedrooms</div>
                      <div className="display-flex mt5">
                        {bedroomsOptionsCollection.map(
                          (bedroomOption, index) => (
                            <Form.CheckBoxGeneral
                              key={`bedroom ${index}`}
                              label={bedroomOption}
                              checked={bedrooms === bedroomOption}
                              onChange={this.updatePreference(
                                'bedrooms',
                                bedroomOption,
                              )}
                              eventProcessedByComponent={true}
                              componentClassName="flex-1"
                            />
                          ),
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="font-size-16">Parking</div>
                      <div className="display-flex mt5 width50p">
                        <Form.CheckBoxGeneral
                          label="1"
                          checked={parking === 1}
                          onChange={this.updatePreference('parking', 1)}
                          eventProcessedByComponent={true}
                          componentClassName="flex-1"
                        />
                        <Form.CheckBoxGeneral
                          label="2"
                          checked={parking === 2}
                          onChange={this.updatePreference('parking', 2)}
                          eventProcessedByComponent={true}
                          componentClassName="flex-1"
                        />
                      </div>
                    </div>
                    <div className="mt10">
                      <Form.CheckBoxGeneral
                        label="Send matching properties"
                        checked={sendMatches}
                        onChange={this.updatePreference('sendMatches')}
                        componentClassName="flex-1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {enableNote && (
              <div className="mt30 mb30">
                <div className="add-viewing-notes-container">
                  <div className="notes-header">
                    <h4 className="fw500">Note (private)</h4>
                  </div>
                  <div>
                    <div className="input-box pt0">
                      <textarea
                        className="pt0"
                        rows="3"
                        placeholder="Comment"
                        value={comment}
                        onChange={(e) => this.update('comment', e.target.value)}
                      />
                    </div>
                    <div className="display-flex flex-wrap-wrap">
                      {FeatureFlag.ViewingCommentTemplates.isOn &&
                        commentTemplatesPrivate.map((commentTemplate) => {
                          return (
                            <Display.Pill
                              key={commentTemplate.index}
                              text={commentTemplate.text}
                              onClick={() =>
                                this.onPillClicked(
                                  commentTemplate.index,
                                  'comment',
                                )
                              }
                            />
                          )
                        })}
                    </div>
                  </div>
                  <div className="notes-header">
                    <h4 className="fw500">Note (viewing report)</h4>
                  </div>
                  <div>
                    <div className="input-box pt0">
                      <textarea
                        className="pt0"
                        rows="3"
                        placeholder="Comment"
                        value={externalComment}
                        onChange={(e) =>
                          this.update('externalComment', e.target.value)
                        }
                      />
                    </div>
                    <div className="display-flex flex-wrap-wrap">
                      {FeatureFlag.ViewingCommentTemplates.isOn &&
                        commentTemplatesExternal.map((commentTemplate) => {
                          return (
                            <Display.Pill
                              key={commentTemplate.index}
                              text={commentTemplate.text}
                              onClick={() =>
                                this.onPillClicked(
                                  commentTemplate.index,
                                  'externalComment',
                                )
                              }
                            />
                          )
                        })}
                    </div>
                    {notesForRegistrant && notesForRegistrant?.length > 0 && (
                      <>
                        <div className="notes-header">
                          <h4 className="fw500">Notes history</h4>
                        </div>
                        {notesForRegistrant &&
                          notesForRegistrant.map((note) => {
                            return (
                              <NoteComponentFlatStyle
                                note={note}
                                noteStyle="activityItem"
                                teamSlug={teamSlug}
                              />
                            )
                          })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}

            {successMessage && (
              <div className="alert alert-success mb0">{successMessage}</div>
            )}
            {error && <ErrorMessage error={error} />}
          </div>
          {enableEnquirerBottomButtons && (
            <div className="modal-footer border-none width100 display-flex">
              <div className="col-sm-6 div-order-1 width100" />
              <div className="col-sm-6 div-order-2 width100">
                <Display.GenericButton
                  text="Save"
                  onClick={() => {
                    saveFunction(this.state)
                  }}
                />
              </div>
            </div>
          )}
          {enableViewingBottomButtons && (
            <div className=" border-none width100 display-flex modal-bottom-btn-container-walk-in-renter">
              <div className="col-sm-6 div-order-1 width100">
                <Display.GenericButton
                  text="Save"
                  buttonType="secondary"
                  onClick={() => {
                    this.setState({ anyUnsavedChanges: false })
                    isEditMode
                      ? onUpdateRenterStatus(
                          this.state,
                          'onSite',
                          viewingID,
                          registrantID,
                          propertyId,
                          true,
                        )
                      : addWalkInRenter(this.state, propertyId)
                  }}
                  disabled={disableSaveButton}
                  isLoading={disableSaveButton}
                />
              </div>
              <div className="col-sm-6 div-order-2 width100">
                <Display.GenericButton
                  text="Save"
                  onClick={() => {
                    this.setState({ anyUnsavedChanges: false })
                    isEditMode
                      ? onUpdateRenterStatus(
                          this.state,
                          'onSite',
                          viewingID,
                          registrantID,
                          propertyId,
                          true,
                        )
                      : addWalkInRenter(this.state, propertyId, undefined, true)
                  }}
                  disabled={disableSaveButton}
                  isLoading={disableSaveButton}
                >
                  <div className="font-size-12">Invite to apply</div>
                </Display.GenericButton>
              </div>
            </div>
          )}
        </ModalContentWrapper>
      </div>
    )
  }
}

export default WalkinRenterModal
