import { connect } from 'react-redux'

import VerificationForm from 'app/session/join/verify/mobile_verification'
import { clearError, resendOTP, verify } from 'app/session/session_actions'
import { setBackUrl } from 'app/shared_components/actions'

const mapStateToProps = ({ session }) => ({
  currentUser: session.currentUser,
  success: session.success,
  rolePromptOn: session.rolePromptOn,
  error: session.error,
})

const mapDispatchToProps = (dispatch) => ({
  resendOTP: (data) => resendOTP(data),
  verify: (data, forwardUrl) => verify(data, forwardUrl),
  clearError: () => clearError(),
  setBackUrl: (url) => dispatch(setBackUrl(url)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VerificationForm)
