import { connect } from 'react-redux'

import { addRentalReputationBackUrl } from 'app/shared_components/actions'
import { fetchCompleteness } from 'app/sm/rental_reputation/actions'
import {
  changeReputationPreference,
  retrieveReputationPreference,
} from 'app/sm/rental_reputation/actions'
import {
  getStudentDetails,
  removeSingleStudentDetail,
} from 'app/sm/student_details/student_details_actions'
import StudentDetails from 'app/sm/student_details/student_details_list'

const mapStateToProps = ({ Shared, apply, RentalReputation }) => ({
  backUrl: Shared.backUrl,
  property: apply.property,
  reputationPreference: RentalReputation.preference,
  completeness: RentalReputation.completeness,
})

const mapDispatchToProps = (dispatch) => ({
  getStudentDetails: () => dispatch(getStudentDetails()),
  removeSingleStudentDetail: (studentDetailId) =>
    dispatch(removeSingleStudentDetail(studentDetailId)),
  retrieveReputationPreference: () => dispatch(retrieveReputationPreference()),
  changeReputationPreference: (preference) =>
    dispatch(changeReputationPreference(preference)),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
  addRentalReputationBackUrl: (rentalBackUrl) =>
    dispatch(addRentalReputationBackUrl(rentalBackUrl)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentDetails)
