import React from 'react'

import getParameter from 'app/shared_components/parameter_parser'
import { SessionStorageUtils } from 'app/storage_utils'

class ReferralCodeRedirect extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchDashboard()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const referralCode = getParameter('discountcode')
    SessionStorageUtils.setItem('discountcode', referralCode)
    const { bondCovers } = nextProps
    this.props.redirectBondCoverReferral(bondCovers)
  }

  render() {
    return null
  }
}

export default ReferralCodeRedirect
