import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'

export const TrimWithEllipsis = styled(Box)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ lines = 3 }) => lines};
  overflow: hidden;
`
