import { Tooltip as ReactTooltip } from 'react-tooltip'

import { ToolTipQuestionMark } from 'app/components/design-system-components/ToolTipQuestionMark'

interface ToolTipProps {
  toolTipID: string
  content: JSX.Element
}

export function ToolTip({ toolTipID, content }: ToolTipProps) {
  return (
    <div>
      <ToolTipQuestionMark toolTipID={toolTipID} />
      <ReactTooltip id={toolTipID}>
        <span>{content}</span>
      </ReactTooltip>
    </div>
  )
}
