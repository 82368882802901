import { NetworkConnectionError } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import * as API from 'app/dashboard/dashboard_access'

export const RECEIVE_DASHBOARD = 'RECEIVE_DASHBOARD'
export const RECEIVE_RESPONSE_TEXT = 'DASH_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'DASH_RECEIVE_ERROR'

export const receiveDashboard = (dashboard) => ({
  type: RECEIVE_DASHBOARD,
  dashboard,
})

export const receiveError = (error) => ({
  type: RECEIVE_ERROR,
  error,
})

export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})

export const clearDashboardError = () => ({
  type: RECEIVE_ERROR,
  error: '',
})

export function fetchDashboard() {
  return (dispatch) => {
    dispatch(clearDashboardError())
    API.getDashboard((dashboard) => dispatch(receiveDashboard(dashboard)))
  }
}

export function fetchManagerDashboard() {
  return (dispatch) => {
    API.getManagerDashboard().then(({ ok, dashboard, responseText }) => {
      if (ok) {
        dispatch(receiveDashboard(dashboard))
      } else {
        responseText.then((t) => dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function fetchRenterDashboard() {
  return (dispatch) => {
    dispatch(clearDashboardError())
    API.getRenterDashboard().then(({ ok, dashboard, responseText }) => {
      if (ok) {
        dispatch(receiveDashboard(dashboard))
      } else {
        responseText.then((t) => dispatch(receiveResponseText(t)))
      }
    })
  }
}

export function sendBondCoverReferral(
  inviteType,
  inviterName,
  inviterEmail,
  inviteeEmail,
  sendSuccess,
  sendFail,
) {
  return (dispatch) => {
    dispatch(clearDashboardError())
    API.sendBondCoverReferral(
      inviteType,
      inviterName,
      inviterEmail,
      inviteeEmail,
    ).then(({ ok, responseText }) => {
      if (ok) {
        sendSuccess()
      } else {
        responseText.then((t) => {
          sendFail(t)
          dispatch(receiveResponseText(t))
        })
      }
    })
  }
}

export const fetchPropertyOfferInfo = (offerId) => (dispatch) => {
  return API.getPropertyOfferInfo(offerId).then(
    ({ ok, responseText, offerInfo }) => {
      if (ok) {
        return Promise.resolve({ ok, offerInfo })
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const fetchPropertyOfferInfoWithDetails =
  (agencyGUID, offerGUID) => (dispatch) => {
    return API.getPropertyOfferInfoWithDetails(agencyGUID, offerGUID).then(
      ({ ok, responseText, offerInfo }) => {
        if (ok) {
          return Promise.resolve({ ok, offerInfo })
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }

export const fetchSubscribeTargetInfo = (token) => (dispatch) => {
  return API.getSubscribeTargetInfo(token).then(
    ({ ok, responseText, subscribeTargetInfo }) => {
      if (ok) {
        return Promise.resolve({ ok, subscribeTargetInfo })
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const sendUnsubscribeFeedback = (token, formData) => (dispatch) => {
  return API.postUnsubscribeFeedback(token, formData).then(
    ({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const sendResubscribeRequest = (token, formData) => (dispatch) => {
  return API.postResubscribeRequest(token, formData).then(
    ({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    },
  )
}

export const sendUnsubscribeSmsRequest = (slug) => (dispatch) => {
  return API.postSmsUnsubscribeRequest(slug).then(({ ok, responseText }) => {
    if (ok) {
      return Promise.resolve()
    } else {
      return responseText.then((error) => Promise.reject(error))
    }
  })
}

export const sendResubscribeSmsRequest = (slug) => (dispatch) => {
  return API.postSmsResubscribeRequest(slug).then(({ ok, responseText }) => {
    if (ok) {
      return Promise.resolve()
    } else {
      return responseText.then((error) => Promise.reject(error))
    }
  })
}

export const sendShareWithOwner =
  (
    applicationGUID,
    recipients,
    message,
    withOwnerStatus,
    isOwnerAcceptEnabled,
    requestBGCApproval,
  ) =>
  (dispatch) => {
    return API.postShareWithOwner(
      applicationGUID,
      recipients,
      message,
      withOwnerStatus,
      isOwnerAcceptEnabled,
      requestBGCApproval,
    ).then(({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    })
  }
