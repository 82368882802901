import React, { Component } from 'react'

import WithdrawForm from 'app/bond_cover/renters/status/request_withdraw_form'

class WithdrawFormContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      withdrawReason: null,
      disabled: true,
      description: '',
    }
  }

  update = (field) => {
    return (event) => {
      let { value } = event.target
      this.setState({ [field]: value })
    }
  }

  updateNumber = (reason) => {
    return (event) =>
      this.setState({
        withdrawReason: parseInt(event.target.value),
        description: reason,
        disabled: false,
      })
  }

  withdraw = () => {
    this.setState({ disabled: true })
    let { withdraw } = this.props
    let search = new URLSearchParams(window.location.search)
    let requestID = search.get('requestid')
    withdraw(
      this.state,
      requestID,
      () => {
        this.setState({ disabled: false })
      },
      () => {
        this.setState({ disabled: false })
      },
    )
  }

  render() {
    let { error } = this.props
    let { withdrawReason, description, disabled } = this.state
    return (
      <div>
        <WithdrawForm
          withdrawReason={withdrawReason}
          description={description}
          disabled={disabled}
          update={this.update}
          updateNumber={this.updateNumber}
          withdraw={this.withdraw}
          error={error}
        />
      </div>
    )
  }
}

export default WithdrawFormContainer
