import React from 'react'

import 'app/components/display/cross_sell_item/style.scss'

const CrossSellItem = ({
  isGrey = false,
  icon = '',
  title = '',
  advertText = '',
  link = '',
  linkText = '',
}) => {
  return (
    <div
      className={`${
        isGrey ? 'grey-background' : ''
      } cross-sell-container display-flex mt20 mb20`}
    >
      <div className="display-flex flex-direction-column left-column justify-content-space-between">
        <div className="diplay-flex flex-direction-row mt15">
          <img src={icon} alt="" className="icon" />
          <h2>{title}</h2>
          <p>{advertText}</p>
        </div>
        <div className="btn-position">
          <a
            className="btn cross-sell-btn"
            target="_blank"
            rel="noopener noreferrer"
            href={link}
          >
            {linkText}
          </a>
        </div>
      </div>
    </div>
  )
}

export default CrossSellItem
