import { api } from 'app/utils/api/helpers'

export const importEnquirers = (
  teamId: string,
  file: File,
  source: string,
  signal?: AbortSignal,
) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('source', source)

  return api.post(`sm/teams/${teamId}/messages/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    signal,
  })
}
