import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import propertyTreeLogo from 'app/assets/pmsLogos/property-tree.png'
import { pmsBrands } from 'app/constants/pms.constants'
import PMSActivity, { usePMSStatus } from 'app/PMSActivity'

const propertyTreeID = pmsBrands.propertyTree.id

const PropertyTreeActivity = ({
  currentTeam,
  isAdmin,
  integrationsBundleIsActive,
}) => {
  const { loadPMSStatus, pmsStatusInfo, pmsStatusLoadingStates } = usePMSStatus(
    currentTeam,
    propertyTreeID,
    integrationsBundleIsActive,
  )

  useEffect(() => loadPMSStatus(), [])

  const statusProps = {
    statusInfo: pmsStatusInfo,
    statusLoadingStates: pmsStatusLoadingStates,
    reloadStatusInfo: loadPMSStatus,
  }

  return (
    <div>
      <PMSActivity
        pmsBrand={pmsBrands.propertyTree.id}
        logo={propertyTreeLogo}
        statusProps={statusProps}
        isAdmin={isAdmin}
        integrationsBundleIsActive={integrationsBundleIsActive}
      />
    </div>
  )
}

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
})

const PropertyTreeActivityPage = withRouter(
  connect(mapStateToProps)(PropertyTreeActivity),
)

export default PropertyTreeActivityPage
