import * as snugLogger from 'app/services/snugLogger'
import * as snugNotifier from 'app/services/snugNotifier'
import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const addApplyAnywhere = (applyAnywhereListing) => {
  const url = `${apiBaseUrl}sm/applyanywhere`

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(applyAnywhereListing),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            responseText: res.text(),
          }),
    )
    .then(
      (response) => ({
        ok: true,
        guid: response.guid,
        applicationRoute: response.applicationRoute,
      }),
      (error) => ({
        ok: false,
        responseText: error.text,
      }),
    )
}

export const updateApplyAnywhere = (applyAnywhereListing) => {
  const url = `${apiBaseUrl}sm/applyanywhere`

  return fetch(url, {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(applyAnywhereListing),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            responseText: res.text(),
          }),
    )
    .then(
      (response) => ({
        ok: true,
        guid: response.guid,
        applicationRoute: response.applicationRoute,
      }),
      (error) => ({
        ok: false,
        responseText: error.text,
      }),
    )
}

export const applyAnywhereApply = (guid) => {
  const url = `${apiBaseUrl}sm/applyanywhere/${guid}/apply`

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            responseText: res.text(),
          }),
    )
    .catch((error) => {
      snugLogger.warn(error)
      snugNotifier.error(error)
    })
}

export const lookUpProperty = (propertyInfo) => {
  const url = `${apiBaseUrl}sm/propertylookup`
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(propertyInfo),
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (result) => ({ ok: true, result }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const fetchApplyAnywhereListing = (guid) => {
  const url = `${apiBaseUrl}sm/applyanywhere/${guid}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (applyAnywhereListing) => ({ ok: true, applyAnywhereListing }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
