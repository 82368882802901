import React from 'react'

import { history } from 'app/shared_components/router'
import 'app/bond_cover/renters/sharedComponent/back_button.scss'

const BottomButtons = ({
  clickBackFunction,
  hideText,
  backButtonClass,
  showArrow,
}) => (
  <button
    onClick={() => clickBackFunction()}
    className={`btn btn-left xs-text-center back-button-link ${backButtonClass}`}
  >
    {showArrow && <i className="icon-arrow-left left mt8" />}
    <span className="mt8">{hideText ? '' : 'Back'}</span>
  </button>
)

export default BottomButtons
