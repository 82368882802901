import React from 'react'

import { Box } from 'app/components/design-system-components'
import Card from 'app/components/design-system-components/Card'
import { Lightning } from 'app/components/design-system-components/icons/snug-specific'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'
import { postNewStepForAgencyForQuickstart } from 'app/sm/team_viewing_preference/setup/api'
import * as setupHelpers from 'app/sm/team_viewing_preference/setup/helpers'
import Finished from 'app/sm/team_viewing_preference/setup/TeamStatus/finished'
import InProgress from 'app/sm/team_viewing_preference/setup/TeamStatus/inprogress'
import NotStarted from 'app/sm/team_viewing_preference/setup/TeamStatus/notStarted'

function TeamStatus({ agencyGUID = '', teamSlug = '', wizardData = {} }) {
  let currentInternalComponent = <NotStarted />

  const updateCurrentStepWithNext = () => {
    const payload = {
      current_step_id: wizardData.currentStep,
    }
    postNewStepForAgencyForQuickstart(
      agencyGUID,
      wizardData.wizardGUID,
      payload,
    )
      .then(({ current_step }) => {
        const { ID } = current_step
        const step = setupHelpers.findWhichStepToRouteTo(ID)
        history.push(urlTo(step, { teamSlug, agencyGUID }))
      })
      .catch((error) => snugNotifier.error(error.message))
  }

  const stepToRouteTo = setupHelpers.findWhichStepToRouteTo(
    wizardData.currentStep,
  )

  const findWhichCompToShow = (status) => {
    switch (status) {
      case setupHelpers.SETUP_NOT_STARTED:
        return (
          <NotStarted
            currentStep={wizardData.currentStep}
            updateCurrentStepWithNext={updateCurrentStepWithNext}
          />
        )
      case setupHelpers.SETUP_STARTED:
      case setupHelpers.SETUP_TEAM_MEMBERS:
      case setupHelpers.SETUP_BRANDING:
        return (
          <InProgress
            currentStep={wizardData.currentStep}
            updateCurrentStepWithNext={updateCurrentStepWithNext}
            stepToRouteTo={stepToRouteTo}
            teamSlug={teamSlug}
            agencyGUID={agencyGUID}
          />
        )
      case setupHelpers.SETUP_COMPLETED:
      default:
        return <Finished teamSlug={teamSlug} />
    }
  }
  currentInternalComponent = findWhichCompToShow(wizardData.currentStep)

  return (
    <Card
      internalComponent={<Box>{currentInternalComponent}</Box>}
      headerTitle="Quick Start"
      headerImage={<Lightning />}
    />
  )
}

export default TeamStatus
