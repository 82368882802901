import React from 'react'

import styled from 'styled-components'

const PublicHeaderContainer = styled.div`
  .header {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    color: #686968;
  }
`

const PublicHeader = ({ title = '', text = '' }) => {
  return (
    <PublicHeaderContainer>
      <h4 className="header">{title}</h4>
      <p className="title">{text}</p>
    </PublicHeaderContainer>
  )
}

export default PublicHeader
