import { RECEIVE_PROPERTY } from 'app/sm/property_dashboard/property_dashboard_actions'

const _defaultState = {
  property: {
    address: {},
    reviews: [],
    questions: [],
    images: [],
    agent: {},
    property: {
      images: [],
      address: {
        friendlyName: '',
      },
    },
    manager: {},
    viewings: [],
    snugFit: {},
  },
  error: '',
}

const PropertyDashboardReducer = (state = _defaultState, action) => {
  let { type, property } = action

  Object.freeze(state)
  let newState = Object.assign({}, state)
  switch (type) {
    case RECEIVE_PROPERTY:
      return Object.assign(newState, { property })
    default:
      return newState
  }
}

export default PropertyDashboardReducer
