import React from 'react'

import moment from 'moment'

import * as Display from 'app/components/display/display'
import { capitalizeFirstLetter, petTypes } from 'app/sm/helpers'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

import 'app/match/apply/application_summary_before_submit/shared_components/pet_box/style.scss'

const SummaryPetsDetailsBox = ({ pet, toggleDocumentsViewer = undefined }) => {
  const {
    name,
    dateOfBirth,
    type,
    breed,
    indoor,
    outdoor,
    isDesexed,
    description,
    isRegistered,
    registrationNumber,
    petReferences = [],
  } = pet || {}
  //TODO: fix hack
  const isBirthDateValid =
    pet?.dateOfBirth && dateTimeHelpers.checkIfPetYOBIsValid(pet?.dateOfBirth)

  const petYears = moment().diff(dateOfBirth, 'years')

  let indoorOutdoorText = ''
  let petYearsText = ''

  if (indoor || outdoor) {
    indoorOutdoorText = `${indoor ? ' indoor' : ''} ${
      indoor && outdoor ? 'and' : ''
    } ${outdoor ? 'outdoor' : ''}`
  }

  if (isBirthDateValid) {
    petYearsText = `${petYears} y/o,`
  }
  const petDetails = [
    {
      label: '',
      text: `${petYearsText} ${indoorOutdoorText} ${
        isDesexed ? 'desexed' : 'not desexed'
      }. ${description}`,
    },
    {
      label: 'Registration number: ',
      text: `${isRegistered ? registrationNumber : 'Unregistered'}`,
    },
    {
      label: 'Photos or pet references: ',
      text: `${petReferences.length === 0 ? 'No references provided' : ''}`,
    },
  ]
  return (
    <Display.ApplicationSectionDetails
      title={`${capitalizeFirstLetter(name)} (${petTypes[type]}, ${breed})`}
      details={petDetails}
    >
      <div className="display-flex">
        {petReferences.length > 0 &&
          petReferences.map((petReference) => (
            <div className="col-sm-6 pl0">
              <AppDocumentListItemSecondVersion
                titleClass="mt0 pl0"
                document={petReference}
                key={petReference.guidID}
                toggleDocumentsViewer={
                  toggleDocumentsViewer
                    ? () => toggleDocumentsViewer(petReference.guidID)
                    : undefined
                }
              />
            </div>
          ))}
      </div>
    </Display.ApplicationSectionDetails>
  )
}

export default SummaryPetsDetailsBox
