export const propertyDisclosureInputTypes = {
  attachments: {
    id: 1,
    tabID: 'disclosureAttatchments',
    tabLabel: 'Attachments',
  },
  form: {
    id: 2,
    tabID: 'disclosureForm',
    tabLabel: 'Form',
  },
}
