import { history } from 'app/shared_components/router'
import { dispatchAPIResponse } from 'app/sm/helpers'
import {
  deletePersonalReference,
  fetchPersonalReference,
  fetchPersonalReferences,
  sendPersonalReference,
  updatePersonalReference,
} from 'app/sm/personal_reference/personal_reference_access'

export const RECEIVE_PERSONAL_REFERENCES =
  'PERSONAL_REF_RECEIVE_PERSONAL_REFERENCES'
export const RECEIVE_PERSONAL_REFERENCE =
  'PERSONAL_REF_RECEIVE_PERSONAL_REFERENCE'
export const RECEIVE_RESPONSE_TEXT = 'PERSONAL_REF_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'PERSONAL_REF_RECEIVE_ERROR'
export const CLEAR_ERROR = 'PERSONAL_REF_CLEAR_ERROR'

export const receivePersonalReferences = (personalReferences) => ({
  type: RECEIVE_PERSONAL_REFERENCES,
  personalReferences,
})
export const receivePersonalReference = (personalReference) => ({
  type: RECEIVE_PERSONAL_REFERENCE,
  personalReference,
})
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })
export const clearError = () => ({ type: CLEAR_ERROR })

export function removePersonalReference(id) {
  return (dispatch) => {
    dispatch(clearError())
    deletePersonalReference(id).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(getPersonalReferences())
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}

export function createPersonalReference(formData) {
  return (dispatch) => {
    dispatch(clearError())
    sendPersonalReference(formData).then(({ ok, responseText }) => {
      if (ok) {
        history.goBack()
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}

export function getPersonalReference(id) {
  return (dispatch) => {
    dispatch(clearError())
    fetchPersonalReference(id).then(
      ({ ok, personalReference, responseText }) => {
        if (ok) {
          dispatch(receivePersonalReference(personalReference))
        } else {
          dispatchAPIResponse(dispatch, responseText, receiveResponseText)
        }
      },
    )
  }
}

export function getPersonalReferences() {
  return (dispatch) => {
    dispatch(clearError())
    fetchPersonalReferences().then(
      ({ ok, personalReferences, responseText }) => {
        if (ok) {
          dispatch(receivePersonalReferences(personalReferences))
        } else {
          dispatchAPIResponse(dispatch, responseText, receiveResponseText)
        }
      },
    )
  }
}

export function editPersonalReference(formData, id) {
  return (dispatch, getState) => {
    dispatch(clearError())
    updatePersonalReference(formData, id).then(({ ok, responseText }) => {
      if (ok) {
        history.goBack()
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}
