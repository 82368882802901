import { connect } from 'react-redux'

import SignatureSign from 'app/sm/signature_sign/signature_sign_container'

const mapStateToProps = ({ SignatureSign }) => ({
  TBD: SignatureSign.TBD,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(SignatureSign)
