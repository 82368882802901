import { connect } from 'react-redux'

import ResetForm from 'app/session/login_components/reset_form'
import { clearError, forgotPassword } from 'app/session/session_actions'

const mapStateToProps = ({ session }) => ({
  error: session.error,
  success: session.success,
  attempts: session.attempts,
})

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (email) => forgotPassword(email),
  clearError: () => clearError(),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetForm)
