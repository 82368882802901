import React, { useState } from 'react'

import moment from 'moment'

import { Box, Button, Flex } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import { StyledCheckbox } from 'app/components/design-system-components/list-filters/StyledCheckbox'
import { Text } from 'app/components/design-system-components/typography'
import * as Forms from 'app/components/forms/forms'
import { personalReferenceStatuses } from 'app/constants/personal_reference/survey/status.constants'
import { originalVersionQuestions } from 'app/constants/personal_reference/survey/survey-questions.constants'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import ContactDetails from 'app/match/apply/application_summary_before_submit/shared_components/contact_container/component'
import ReferenceResultComponent from 'app/match/rental_reference/display/renter_address_history_detailed/rental_reference_detailed/rental_reference_result/rental_reference_result_item'
import { answerView } from 'app/match/rental_reference/rating_categories.constants'
import { history } from 'app/shared_components/router'
import {
  QuestionType,
  relationshipTypesTitles,
  urlIds,
  urlTo,
} from 'app/sm/helpers'
import * as Helpers from 'app/sm/helpers'
import { formatPhoneNumberE164 } from 'app/utils/phonenumber/helpers'

const SummaryPersonalReferenceBox = ({
  personalRef,
  from = '',
  onResend,
  displayResponseSection,
}) => {
  const {
    firstName = '',
    lastName = '',
    phoneNumber = '',
    email = '',
    relationship = 0,
    response = [],
    lastSend,
    status,
    responseDate,
  } = personalRef || {}

  //obj containing email, phone and time of the request sent. This would be displayed temporarily while the email is being processing. After reloading it would disappear
  const [emailPhoneRequestedTempObj, setEmailPhoneRequestedTempObj] =
    useState(null)
  const [enableEditEmail, setEnableEditEmail] = useState(false)
  const [newEmail, setNewEmail] = useState(null)
  const [includeMessage, setIncludeMessage] = useState(false)
  const [message, setMessage] = useState(null)

  const onSendBtnClicked = () => {
    return onResend(newEmail, message).then(({ email, phone, createdAt }) => {
      setEmailPhoneRequestedTempObj({ email, phone, createdAt })
      setEnableEditEmail(false)
      setIncludeMessage(false)
    })
  }

  const fromRenterSummary = from === 'renterSummary'
  const statusObj = Object.values(personalReferenceStatuses).find((obj) => {
    return obj.id === status
  })
  const isResponded = personalReferenceStatuses.responded.id === status
  const renderResponse = () => {
    return (
      <Box p={`${theme.space[6]}px`}>
        <Text
          as="div"
          mb={`${theme.space[6]}px`}
          fontWeight={theme.fontWeights.h3}
          color={theme.colors.gray400}
        >
          Answers
        </Text>
        <PersonalReferenceAnswersList answers={response} />
      </Box>
    )
  }
  const renderLastRequestSent = (lastRequestObj) => {
    //Hide last send section if displaying last requested temp section
    if (emailPhoneRequestedTempObj) return
    if (email === '' && phoneNumber === '') return

    if (!lastRequestObj.createdAt) return
    const lastRequestDate = moment(lastRequestObj.createdAt).format(
      Helpers.standardMilitaryTime,
    )
    return (
      <Box mt={theme.space[1]} fontWeight="normal" fontSize="12px">
        <span>
          <b>Last sent: </b>
          {lastRequestDate}
        </span>
        <br />
        <span>
          Sent to {email !== '' && ` ${lastRequestObj.email}`}
          {phoneNumber !== '' && ` ${formatPhoneNumberE164(phoneNumber)}`}
        </span>
      </Box>
    )
  }
  const renderEmailRequestedTempLabel = () => {
    if (
      !emailPhoneRequestedTempObj.createdAt ||
      (!emailPhoneRequestedTempObj.email && !emailPhoneRequestedTempObj.phone)
    )
      return

    const requestDate = moment(emailPhoneRequestedTempObj.createdAt).format(
      Helpers.standardMilitaryTime,
    )
    return (
      <Box mt={theme.space[1]} fontWeight="normal" fontSize="12px">
        <span>
          <b>Last Requested: </b>
          {requestDate}
        </span>
        <br />
        <span>
          Sending to{' '}
          {emailPhoneRequestedTempObj.email !== '' &&
            ` ${emailPhoneRequestedTempObj.email}`}
          {emailPhoneRequestedTempObj.phone !== '' &&
            ` ${formatPhoneNumberE164(emailPhoneRequestedTempObj.phone)}`}
        </span>
      </Box>
    )
  }
  const renderStatus = () => {
    let transformedResponseDate
    if (isResponded && responseDate) {
      transformedResponseDate = moment(responseDate).format(
        Helpers.standardMilitaryTime,
      )
    }
    return (
      <Flex justifyContent="end" alignItems="center">
        <Text
          as="span"
          color={theme.colors.gray400}
          fontWeight="bold"
          fontSize={theme.fontSizes.pSmall12}
          mr="4px"
        >
          {transformedResponseDate}{' '}
        </Text>
        <Badge
          variant={statusObj.badgeVariant}
          text={statusObj.label}
          textAlign="center"
        />
      </Flex>
    )
  }

  const sendReferenceBtnLabel =
    lastSend && (email !== '' || phoneNumber !== '')
      ? 'Resend Reference'
      : 'Send Reference'

  return (
    <div
      className={`bc-hub-item-link card-block pdf-width-50p ${
        fromRenterSummary && 'no-box-shadow p0'
      }`}
    >
      <Box p="10px" bg={theme.colors.legacyGreyBlue}>
        {statusObj && displayResponseSection && renderStatus()}
        <content style={{ paddingTop: 0 }}>
          <ContactDetails
            firstName={firstName}
            lastName={lastName}
            phoneNumber={phoneNumber}
            email={email}
            extraName={relationshipTypesTitles[relationship]}
            showContactLabel={false}
          />
          {displayResponseSection && (
            <>
              {!!response.length && renderResponse()}
              {/* if reference responded we don't allow resending, and don't show last send*/}
              {!isResponded && (
                <Box mt={6} mx="auto">
                  <Box my={2}>
                    <StyledCheckbox
                      label="Include message"
                      checked={includeMessage}
                      onChange={({ target: { checked } }) =>
                        setIncludeMessage(checked)
                      }
                    />
                    {includeMessage && (
                      <Forms.CommentArea
                        isOptional
                        label="Message"
                        placeholder="Attach a personal message"
                        rows={3}
                        value={message}
                        onChange={({ value }) => setMessage(value)}
                      />
                    )}
                  </Box>
                  <Button
                    type="button"
                    onClick={onSendBtnClicked}
                    variant="outlineSuccess"
                    disabled={emailPhoneRequestedTempObj?.createdAt}
                    my={2}
                  >
                    {sendReferenceBtnLabel}
                  </Button>
                  <Box>
                    {enableEditEmail && (
                      <Box my={3}>
                        <Forms.InputEmail
                          label="Email"
                          value={newEmail}
                          onChange={({ value }) => setNewEmail(value)}
                          id="email"
                          inputClass="width100"
                        />
                      </Box>
                    )}
                    <Flex mt={3}>
                      {!enableEditEmail && (
                        <>
                          <Button
                            variant="linkBlueUnderlined"
                            mr={3}
                            onClick={() => setEnableEditEmail(true)}
                          >
                            Edit email
                          </Button>
                          <Button
                            variant="linkBlueUnderlined"
                            onClick={() =>
                              history.push(
                                urlTo(urlIds.manualPersonalReferenceSurvey, {
                                  referenceGUID: personalRef.guidID,
                                }),
                              )
                            }
                          >
                            Manual reference
                          </Button>
                        </>
                      )}
                      {enableEditEmail && (
                        <div
                          className="blue-link-style"
                          onClick={() => setEnableEditEmail(false)}
                          role="button"
                          tabIndex="0"
                        >
                          Close
                        </div>
                      )}
                    </Flex>
                  </Box>
                  {lastSend && renderLastRequestSent(lastSend)}
                  {emailPhoneRequestedTempObj &&
                    renderEmailRequestedTempLabel()}
                </Box>
              )}
            </>
          )}
        </content>
      </Box>
    </div>
  )
}

export const PersonalReferenceAnswersList = ({
  answers,
  singleAnswerContainerClass,
  textBlockContainerClass,
}) => {
  return Object.keys(originalVersionQuestions).map((quesCode) => {
    const answerObj = answers.find((answer) => answer.code === quesCode)
    const questionObj = originalVersionQuestions[quesCode]
    const displayedAnswer =
      questionObj.type === QuestionType.Boolean
        ? answerObj?.answerValue === 'true'
          ? 'Yes'
          : 'No'
        : answerObj?.answerValue
    return (
      answerObj && (
        <Box fontSize={theme.fontSizes.pSmall12}>
          {answerObj && (
            <ReferenceResultComponent
              key={`${quesCode}`}
              description={questionObj.body}
              isBooleanRating={questionObj.answerView === answerView.boolean}
              isTextBlock={questionObj.answerView === answerView.textBlock}
              isTextRating={
                questionObj.answerView !== answerView.boolean &&
                questionObj.answerView !== answerView.textBlock
              }
              textAnswer={displayedAnswer}
              boolRatingValue={displayedAnswer}
              exception={
                questionObj.exception &&
                questionObj.exception(answerObj.answerValue)
              }
              containerClass={singleAnswerContainerClass}
              textBlockContainerClass={textBlockContainerClass}
            />
          )}
        </Box>
      )
    )
  })
}

export default SummaryPersonalReferenceBox
