import React from 'react'

import { SNUG_PRIVACY_URL, SNUG_TERMS_URL } from 'app/sm/helpers'
import * as textHelpers from 'app/utils/text/helpers'

const Footer = () => (
  <div className="footer">
    <div className="footer-links">
      <p className=" pt15 fs12 mb0 lh14 gray-light-color ">
        {textHelpers.TRADEMARK_FOOTER_CONTENT_TEXT}
      </p>

      <p className="pt15 fs12 mb0 lh14 gray-light-color pdf-hide">
        <a target="_blank" rel="noopener noreferrer" href={SNUG_TERMS_URL}>
          Terms of Use
        </a>{' '}
        |{' '}
        <a target="_blank" rel="noopener noreferrer" href={SNUG_PRIVACY_URL}>
          Privacy Policy
        </a>
      </p>
    </div>
  </div>
)

export const ApplyAnywhereFooter = ({ ip }) => (
  <div className="footer">
    <div className="footer-links">
      <p className=" pt15 fs12 mb0 lh14 gray-light-color ">
        The application is intended for the recipient only. Please ensure you're
        the authorised recipient of the application. All access will be logged.
      </p>

      <p className="pt15 fs12 mb0 lh14 gray-light-color">
        By accessing this application you agree to our &nbsp;
        <a target="_blank" rel="noopener noreferrer" href={SNUG_TERMS_URL}>
          Terms of Use
        </a>{' '}
        and{' '}
        <a target="_blank" rel="noopener noreferrer" href={SNUG_PRIVACY_URL}>
          Privacy Policy
        </a>
      </p>
    </div>
  </div>
)

export default Footer
