import React from 'react'

import moment from 'moment'
import qs from 'qs'

import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { selectApplicationStatus } from 'app/dashboard/prospect-summary-helpers'
import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import GeneralBottomBtns from 'app/shared_components/general_bottom_btn/component'
import { history } from 'app/shared_components/router'
import {
  isStatusAccepted,
  isStatusApplied,
  isStatusLeased,
  isStatusOffered,
  isStatusShortlisted,
} from 'app/sm/helpers'
import { urlTo } from 'app/sm/helpers'
import AddressHeader from 'app/sm/progress_application/address_header/component'
import Household from 'app/sm/progress_application/applicant_list/component'
import ApplicationHeader from 'app/sm/progress_application/application_header/component'
import LeaseDetails from 'app/sm/progress_application/lease_details/component'
import MarkAsLeased from 'app/sm/progress_application/mark_as_leased/component'
import SendDetails from 'app/sm/progress_application/send_details/component'
import { isNewWorkflowEnabled } from 'config/features'

const findPrimaryApplicant = (applicants) =>
  applicants.find((applicant) => applicant.isPrimary === true)

const findChosenTeam = (teams, agencyID) =>
  teams.find((team) => team.guid === agencyID)

class ProgressApplicationContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      apiError: '',
      editModeForLeaseDetails: {
        rent: false,
        leaseStart: false,
        term: false,
      },
      showBondInstructionLink: false,
      showHoldingDepositInstructionLink: false,
      disableConfirmBtn: false,
    }
  }

  componentDidMount() {
    const { fetchProgressApplication } = this.props
    fetchProgressApplication(this.props.match.params.applicationId)
      .then(({ applicationInfo }) => {
        const { Application = {} } = applicationInfo
        const applicationStatus = selectApplicationStatus(Application)
        this.setState({
          applicationInfo,
          applicationStatus,
          applicationId: this.props.match.params.applicationId,
        })
        const { nextStep } = applicationInfo
        const {
          sendBondInstruction = false,
          sendHoldingDepositInstruction = false,
        } = nextStep || {}
        if (sendBondInstruction === false)
          this.setState({ showBondInstructionLink: true })
        if (sendHoldingDepositInstruction === false)
          this.setState({ showHoldingDepositInstructionLink: true })
      })
      .catch((apiError) => {
        this.setState({
          apiError,
        })
      })
  }

  onBackButtonClicked = () => {
    history.goBack()
  }

  onCheckBoxClicked = (field) => {
    return () => {
      const { nextStep } = this.state.applicationInfo
      nextStep[field] = !nextStep[field]
      const updatedApplicationInfo = Object.assign(
        { ...this.state.applicationInfo },
        { nextStep },
      )
      this.setState({ applicationInfo: updatedApplicationInfo })
    }
  }

  onConfirmButtonClicked = () => {
    const {
      applicationInfo,
      applicationId,
      applicationInfo: {
        Application: {
          offer: {
            property: { guidID: propertyId, agencyID },
          },
        },
      },
      applicationStatus,
    } = this.state
    const isApplicationOffered = isStatusOffered(applicationStatus)
    this.setState({ disableConfirmBtn: true })

    const { Application = {} } = applicationInfo
    const { applicants = [] } = Application
    const primaryApplicant = findPrimaryApplicant(applicants)

    const { updateProgressApplication, teams, withdrawOfferForApplication } =
      this.props

    const chosenTeam = findChosenTeam(teams, agencyID)
    const { slug = '' } = chosenTeam

    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const fromUrl = curQueries.fromUrl
    if (isApplicationOffered) {
      if (window.confirm('Are you sure you want to withdraw this offer?')) {
        withdrawOfferForApplication(applicationId)
          .then(() => {
            this.setState({ disableConfirmBtn: false })
            if (fromUrl) {
              history.push(fromUrl)
            } else {
              history.push(
                urlTo('renterApplication', {
                  propertyId,
                  applicationId,
                  applicantId: primaryApplicant.guidID,
                }),
              )
            }
          })
          .catch((error) => {
            this.setState({ disableConfirmBtn: false, apiError: error })
          })
      }
    } else {
      updateProgressApplication(applicationId, applicationInfo)
        .then(() => {
          this.setState({ disableConfirmBtn: false })
          if (fromUrl) {
            history.push(fromUrl)
          } else {
            history.push(
              urlTo('renterApplication', {
                propertyId,
                applicationId,
                applicantId: primaryApplicant.guidID,
              }),
            )
          }
        })
        .catch((error) => {
          this.setState({ disableConfirmBtn: false, apiError: error })
        })
    }
  }

  onDateChange = (time) => {
    if (moment(time).format('YYYY-MM-DD') !== 'Invalid date') {
      const updatedTime = moment(time).format('YYYY-MM-DD')
      const {
        applicationInfo = {},
        applicationInfo: { leaseOffer },
      } = this.state
      const updatedLeaseOffer = Object.assign(
        { ...leaseOffer },
        { leaseStartDate: updatedTime },
      )
      const updatedApplicationInfo = Object.assign(
        { ...applicationInfo },
        { leaseOffer: updatedLeaseOffer },
      )
      this.setState({
        pickerStatus: false,
        applicationInfo: updatedApplicationInfo,
      })
    }
  }

  onDateFocus = () => {
    this.setState({
      pickerStatus: true,
    })
  }

  onMarkAsLeasedBtnClick = () => {
    const {
      applicationInfo,
      applicationId,
      applicationInfo: {
        Application: {
          offer: {
            property: { guidID: propertyId, agencyID },
          },
        },
      },
    } = this.state

    const { teams, markProgressApplicationAsLeased } = this.props
    const { referrals = [] } = applicationInfo
    const confirmText =
      referrals.length > 0
        ? 'Save the successful lease details and issue the utility connection referral?'
        : 'Save the successful lease details?'
    applicationInfo.markLeased = true

    const chosenTeam = findChosenTeam(teams, agencyID)
    const { slug = '' } = chosenTeam
    this.setState({ disableConfirmBtn: true })
    markProgressApplicationAsLeased(applicationId, applicationInfo).then(() => {
      this.setState({ disableConfirmBtn: false })
      history.push(
        `${urlTo('prospectSummary', {
          teamSlug: slug,
        })}?property=${propertyId}`,
      )
    })
  }

  onRentInputFieldChange = (event) => {
    const { value } = event.target
    this.updateInputValue('weeklyRent', value)
  }

  onTermInputFieldChange = (event) => {
    const { value } = event.target
    this.updateInputValue('leaseLength', value)
  }

  onUtilityConnectionClicked = (name) => {
    return () => {
      const { referrals } = this.state.applicationInfo
      const chosenReferralIndex = referrals.findIndex(
        (referral) => referral.name === name,
      )
      referrals[chosenReferralIndex]['selected'] =
        !referrals[chosenReferralIndex]['selected']
      this.setState({
        applicationInfo: Object.assign(
          { ...this.state.applicationInfo },
          { referrals },
        ),
      })
    }
  }

  onViewApplicationClicked = () => {
    const {
      applicationId,
      applicationInfo: {
        Application: {
          offer: {
            property: { guidID },
          },
        },
      },
    } = this.state
    history.push(
      urlTo('renterApplicationPortal', { propertyId: guidID, applicationId }),
    )
  }

  updateInputValue = (field, value) => {
    const { leaseOffer } = this.state.applicationInfo
    let { applicationInfo: updatedApplicationInfo } = this.state
    let updatedLeaseOffer = leaseOffer
    if (field === 'weeklyRent' && value >= 0)
      updatedLeaseOffer[field] = Number(value) || 0
    if (field === 'leaseLength') updatedLeaseOffer[field] = Number(value) || 0
    updatedApplicationInfo['leaseOffer'] = updatedLeaseOffer
    this.setState({
      applicationInfo: updatedApplicationInfo,
    })
  }

  render() {
    const { teams = [] } = this.props

    const {
      applicationInfo = {},
      pickerStatus = false,
      showBondInstructionLink = '',
      showHoldingDepositInstructionLink = '',
      applicationStatus = '',
      apiError = '',
      disableConfirmBtn = false,
    } = this.state

    const {
      Application = {},
      leaseApplicants = [],
      nextStep = {},
      referrals = [],
      leaseOffer = {},
      markLeased = false,
    } = applicationInfo

    const { offer = {}, applicants = [] } = Application

    const {
      acceptedLeaseLength = '',
      property = {},
      availableFrom: offerAvailableFrom = '',
      weeklyRent: offerWeeklyRent = 0,
    } = offer

    const {
      address = {},
      carports = 0,
      bedrooms = 0,
      guidID: propertyId = '',
      agencyID = '',
    } = property

    const { friendlyName = '' } = address

    const {
      holdingDepositPaymentLink = false,
      sendBondInstruction = false,
      sendHoldingDepositInstruction = false,
      bondInstruction = '',
      holdingDepositInstruction = '',
    } = nextStep || {}

    const {
      weeklyRent: leaseWeeklyRent = 0,
      leaseLength = '',
      leaseStartDate = '',
    } = leaseOffer

    const chosenTeam = findChosenTeam(teams, agencyID) || {}
    const { name: agencyName = '', slug = '', guid = '' } = chosenTeam
    let crumbs = []
    if (agencyName && propertyId) {
      crumbs = [
        {
          text: agencyName || 'Team',
          link: urlTo('teamOverview', { teamSlug: slug }),
        },
        {
          text: friendlyName,
          link:
            urlTo('prospectSummary', { teamSlug: slug }) +
            `?property=${propertyId}`,
        },
        {
          text: 'Progress Application',
          link: '#',
        },
      ]
    }

    const primaryApplicant = findPrimaryApplicant(applicants)
    const formatedOfferAvailableFrom =
      moment(offerAvailableFrom).format('DD MMM YYYY')
    const isApplicationEditable =
      isStatusOffered(applicationStatus) ||
      isStatusAccepted(applicationStatus) ||
      isStatusLeased(applicationStatus)

    let headerTitle = 'Progress application'
    let headerText = ''
    if (
      isStatusApplied(applicationStatus) ||
      isStatusShortlisted(applicationStatus)
    ) {
      headerTitle = 'Progress application'
      headerText = (
        <span>
          Send details to {primaryApplicant && primaryApplicant.firstName}{' '}
          {primaryApplicant && primaryApplicant.lastName} (primary applicant){' '}
          <span className="dark-text fw600">{friendlyName}.&nbsp;</span>
        </span>
      )
    } else if (isStatusOffered(applicationStatus)) {
      headerTitle = 'Successful application'
      headerText = (
        <span>
          Successful applicant has been selected for{' '}
          <span className="dark-text fw600">{friendlyName}.&nbsp;</span>
        </span>
      )
    } else if (isStatusAccepted(applicationStatus) && !markLeased) {
      headerTitle = 'Accepted offer'
      headerText = (
        <span>
          {primaryApplicant && primaryApplicant.firstName}{' '}
          {primaryApplicant && primaryApplicant.lastName} accepted the
          conditional offer for{' '}
          <span className="dark-text fw600">{friendlyName}.&nbsp;</span>
        </span>
      )
    } else if (isStatusAccepted(applicationStatus) && markLeased) {
      headerTitle = 'Accepted offer'
      headerText = (
        <span>
          <span className="dark-text fw600">{friendlyName}&nbsp;</span>has been
          marked as leased.&nbsp;
        </span>
      )
    }

    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const markAsLeased = isNewWorkflowEnabled[slug]
      ? curQueries.markasleased
      : isStatusApplied(applicationStatus)
    return (
      <div className="two-col-box-flex-row sm73">
        <div className="col-first">
          <div className="mb10 pdf-hide">
            <Breadcrumbs crumbs={crumbs} />
          </div>
          <ApplicationHeader
            headerTitle={headerTitle}
            headerText={headerText}
            onViewApplicationClicked={this.onViewApplicationClicked}
          />
          <AddressHeader
            friendlyName={friendlyName}
            bedrooms={bedrooms}
            carports={carports}
            weeklyRent={offerWeeklyRent}
            availableFrom={formatedOfferAvailableFrom}
          />
          <Household applicants={applicants} application={Application} />
          <LeaseDetails
            weeklyRent={leaseWeeklyRent}
            startDate={leaseStartDate}
            terms={leaseLength}
            onRentInputFieldChange={this.onRentInputFieldChange}
            onTermInputFieldChange={this.onTermInputFieldChange}
            onDateChange={this.onDateChange}
            onDateFocus={this.onDateFocus}
            pickerStatus={pickerStatus}
            enableEdit={isApplicationEditable ? false : true}
          />
          {markAsLeased && (
            <MarkAsLeased
              onMarkAsLeasedClicked={this.onMarkAsLeasedBtnClick}
              disableConfirmBtn={disableConfirmBtn}
              showMarkAsLeased={markAsLeased}
            />
          )}
          <SendDetails
            holdingDepositPaymentLink={holdingDepositPaymentLink}
            sendBondInstruction={sendBondInstruction}
            sendHoldingDepositInstruction={sendHoldingDepositInstruction}
            onCheckBoxClicked={this.onCheckBoxClicked}
            referrals={referrals}
            onUtilityConnectionClicked={this.onUtilityConnectionClicked}
            showBondInstructionLink={showBondInstructionLink}
            showHoldingDepositInstructionLink={
              showHoldingDepositInstructionLink
            }
            bondInstruction={bondInstruction}
            holdingDepositInstruction={holdingDepositInstruction}
            enableEdit={isApplicationEditable ? false : true}
          />
          <GeneralBottomBtns
            onBackButtonClicked={this.onBackButtonClicked}
            onConfirmButtonClicked={this.onConfirmButtonClicked}
            // TODO: clean up feature flag
            hideConfirmButton={
              isNewWorkflowEnabled[slug]
                ? isStatusAccepted(applicationStatus) ||
                  isStatusLeased(applicationStatus)
                : isStatusAccepted(applicationStatus)
            }
            confirmBtnText={
              isStatusOffered(applicationStatus) ? 'Withdraw' : 'Send'
            }
            disableConfirmBtn={disableConfirmBtn}
            btnDangerStyle={isStatusOffered(applicationStatus)}
          />
          {apiError && (
            <div className="alert alert-danger">
              {' '}
              {translateErrorCodeToMessage(apiError)}{' '}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ProgressApplicationContainer
