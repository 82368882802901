import { Button } from 'app/components/design-system-components'

const SubmitButton = ({ disabled, text }) => {
  return (
    <Button
      height="50px"
      width="100%"
      disabled={disabled}
      type="submit"
      mt="16px"
      mb="28px"
    >
      {text}
    </Button>
  )
}

export default SubmitButton
