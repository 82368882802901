import { Tooltip as ReactTooltip } from 'react-tooltip'

import checkmarkWithBG from 'app/assets/icons/checkmark-with-bg.svg'
import warningIcon from 'app/assets/icons/warning-lighter.svg'
import { Box, Flex, IconBox } from 'app/components/design-system-components'
import {
  contactType,
  entryNoticeMessageStatus,
  entryNoticesStatus,
} from 'app/constants/entry-notice.constatns'

export const canShowRecipients = (entryNoticeRecordsSummary) => {
  const isApplicable =
    entryNoticeRecordsSummary?.status !== entryNoticesStatus.notApplicable

  return (
    isApplicable &&
    entryNoticeRecordsSummary?.contactType === contactType.tenant
  )
}

const shouldAlertForEntryNotices = (entryNoticeRecordsSummary) => {
  const { recipients, contactType: recipientsContactType } =
    entryNoticeRecordsSummary
  const notForTenants = recipientsContactType !== contactType.tenant
  const hasNoRecipients = !recipients || !recipients.length
  if (notForTenants || hasNoRecipients) {
    return false
  }

  return recipients.some((r) => r.status !== entryNoticeMessageStatus.opened)
}

export const ViewingEntryNoticesStatusAndDetailsCTA = ({
  entryNoticeRecordsSummary,
  openDetailsHandler,
  uniqueId,
  isViewingEntryNoticeEnabled,
}) => {
  const showNoticesLink =
    isViewingEntryNoticeEnabled && canShowRecipients(entryNoticeRecordsSummary)

  if (!showNoticesLink) {
    return null
  }

  const onOpenDetailsClicked = (e) => {
    e.stopPropagation()
    openDetailsHandler(entryNoticeRecordsSummary)
  }

  const showAlert = shouldAlertForEntryNotices(entryNoticeRecordsSummary)

  const alertIcon = (
    <IconBox>
      <img
        data-tooltip-content=""
        className="icon"
        src={warningIcon}
        height="16"
        width="16"
        alt={'icon'}
        data-tooltip-id={uniqueId}
      />
      <ReactTooltip
        data-type="info"
        place="top"
        className="tool-tip-style"
        id={uniqueId}
      >
        <span>Issue with Entry Notices</span>
      </ReactTooltip>
    </IconBox>
  )

  return (
    <Flex alignItems="center">
      <Box onClick={onOpenDetailsClicked} className="blue-link-style">
        Entry Notices
      </Box>
      <Box ml="5px">
        {showAlert ? (
          alertIcon
        ) : (
          <img
            src={checkmarkWithBG}
            alt="sms report sent"
            height="16px"
            width="16px"
          />
        )}
      </Box>
    </Flex>
  )
}
