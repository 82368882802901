import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { layout, space, typography } from 'styled-system'

import Box from 'app/components/design-system-components/Box'
import { Spinner } from 'app/components/design-system-components/Spinner'
import * as themeGetters from 'app/components/design-system-components/theme/getters'
import { CustomInputWrapper } from 'app/forms/CustomInputWrapper'
import theme from 'app/match/applicationReportPDF/assets/theme'

export const StyledInput = styled.input`
  ${layout}
  ${space}
  ${typography}
`

export const InputContainer = styled.label`
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  align-items: center;

  font-size: ${theme.fontSizes.pRegular14};
  overflow: auto;
  overflow-y: hidden;

  background-color: ${themeGetters.color('white')};
  padding: ${themeGetters.spacing(3)};

  & input {
    border: 0;
    display: flex;
    flex: 1 1;
  }

  & input[disabled] {
    color: ${themeGetters.color('gray400')};
  }

  border: 1px solid ${themeGetters.color('grey200')};
  border-radius: ${themeGetters.radius(6)}px;

  .icon {
    font-size: 1.2em;
  }

  &.error {
    border-color: ${themeGetters.color('primary600')};
    color: ${themeGetters.color('primary600')};
  }

  ${typography}
  ${layout}
  ${space}
`

export const Input = ({
  id,
  icon,
  isLoading = false,
  error = null,
  prefix,
  postfix,
  inputProps,
  endedIcon,
  containerProps,
  ...props
}) => {
  return (
    <InputContainer
      htmlFor={id}
      {...props}
      {...containerProps}
      className={error && 'error'}
    >
      {prefix && <Box mr="1rem">{prefix}</Box>}
      {icon && (
        <Box className="icon" mr="1rem">
          {icon}
        </Box>
      )}
      <StyledInput id={id} {...props} {...inputProps} />
      {endedIcon && <Box className="icon">{endedIcon}</Box>}
      {postfix && <Box ml="1rem">{postfix}</Box>}
      {isLoading && <Spinner />}
    </InputContainer>
  )
}

const InputAsCustomCmp = ({
  changeHandler,
  blurHandler,
  value,
  error,
  inputProps,
  ...props
}) => {
  const onChange = ({ target: { value } }) => {
    changeHandler(value)
  }

  const onBlur = () => {
    blurHandler()
  }

  const overwrittenInputProps = {
    ...inputProps,
    onChange,
    onBlur,
    value,
  }

  return <Input {...props} error={error} inputProps={overwrittenInputProps} />
}

export const InputField = (props) => {
  const { name } = props

  return (
    <CustomInputWrapper
      name={name}
      customInputCmp={(customInputProps) => (
        <InputAsCustomCmp {...customInputProps} {...props} />
      )}
    />
  )
}

InputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  inputProps: PropTypes.any,
}
