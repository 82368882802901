import React from 'react'

import { Link } from 'react-router-dom'

import Amenities from 'app/sm/common/amenities'
import AvatarPlaceholder from 'app/sm/common/avatar_placeholder'
import RatingWidget from 'app/sm/common/rating-widget'
import { CategoryTypes, NO_IMAGE_PLACEHOLDER_URL, urlTo } from 'app/sm/helpers'

class Review extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { propertyId } = this.props.match.params
    this.props.fetchPropertyMetadata(propertyId)
  }

  somethingChanged(what, role, key, value) {
    switch (what) {
      case 'rate':
        this.props.rate(role, value)
        break
      case 'input':
        this.props.inputChanged(role, key, value)
        break
      case 'checkbox':
        this.props.checkboxChanged(role, key)
        break
    }
  }

  render() {
    const { property, error } = this.props
    const { propertyId } = this.props.match.params

    let suburb = ''
    let address = ''
    if (property.address) {
      suburb = `${property.address.suburb} ${property.address.state} ${property.address.postcode}`
      address = `${property.address.streetNumber} ${property.address.streetName}`
    }
    let propertyImageUrl = ''
    if (property.images && property.images.length > 0) {
      propertyImageUrl = property.images.filter(
        (image) => image.isMain === true,
      )[0].URL
    } else {
      propertyImageUrl = NO_IMAGE_PLACEHOLDER_URL
    }
    let usernameFormatted = ''
    if (property.agent) {
      if (property.agent.firstName) {
        usernameFormatted += property.agent.firstName
      }
      if (property.agent.lastName) {
        usernameFormatted += ` ${property.agent.lastName[0]}.`
      }
    }
    // if (error) {
    //     return (
    //         <div className='alert alert-danger disposable-alert'>
    //             <i onClick={() => this.props.ping()} className="fa fa-chevron-left mr15" aria-hidden="true" />{error}
    //         </div>
    //     )
    // }
    return (
      <div>
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <div className="sm-property-reviews">
              <div className="sm-property-details">
                <div className="sm-property-details__property_info">
                  <div className="two-col-box sm55 address-rank review">
                    <div className="col-first address review">
                      <h2>{address}</h2>
                      <h3>{suburb}</h3>
                      <div className="price">
                        {CategoryTypes[property.type]}
                      </div>
                      <div className="meta">
                        <Amenities payload={property} />
                        <div className="avatar-wrapper">
                          <span>{usernameFormatted}</span>
                          {property.agent.avatar ? (
                            <div
                              className="avatar avatar-small"
                              style={{
                                backgroundImage: `url(${property.agent.avatar})`,
                              }}
                            ></div>
                          ) : (
                            <AvatarPlaceholder currentUser={property.agent} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <div className="review-property-agent">
              <div className="property-review-section">
                <RatingWidget
                  role="property"
                  parole="Review the property"
                  payload={property}
                  somethingChanged={(what, role, key, value) =>
                    this.somethingChanged(what, role, key, value)
                  }
                />
              </div>
              <div className="mt35">
                <RatingWidget
                  role="agent"
                  parole="Review the manager/owner (optional)"
                  payload={property.agent}
                  somethingChanged={(what, role, key, value) =>
                    this.somethingChanged(what, role, key, value)
                  }
                />
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className={error ? 'alert alert-danger' : ''}>
                    <div>{error}</div>
                  </div>
                </div>
              </div>
              <div className="row pt25">
                <div className="col-sm-5 pb5">
                  <Link
                    to={urlTo('propertyDetails', { propertyId })}
                    className="btn btn-transparent btn-left xs-text-center wa"
                  >
                    <i className="icon-arrow-left left"></i>
                    <span>Back</span>
                  </Link>
                </div>
                <div className="col-sm-7 pb5">
                  <button
                    className="btn"
                    onClick={() => this.props.submitReview()}
                  >
                    Submit Review
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Review
