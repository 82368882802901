export const declineReasonsIDs = {
  OTHER_REASON: 5,
  TENANT_DID_NOT_LEASE_PROPERTY: 6,
  NOT_THE_PROPERTY_MANAGER: 7,
  PROPERTY_NOT_UNDER_AGENCY_MANAGEMENT: 8,
  PREFER_VERBAL_REFERENCE: 9,
  UNWILLING_TO_PROVIDE_ANY_REFERENCE: 10,
}

export const declineReasonsConfig = {
  [declineReasonsIDs.OTHER_REASON]: {
    title: 'Other reason',
  },
  [declineReasonsIDs.TENANT_DID_NOT_LEASE_PROPERTY]: {
    title: 'Tenant did not lease this property',
  },
  [declineReasonsIDs.NOT_THE_PROPERTY_MANAGER]: {
    title: 'I am not the Property Manager',
  },
  [declineReasonsIDs.PROPERTY_NOT_UNDER_AGENCY_MANAGEMENT]: {
    title: 'Property not under management by Agency',
  },
  [declineReasonsIDs.PREFER_VERBAL_REFERENCE]: {
    title: 'Prefer verbal reference (request call)',
  },
  [declineReasonsIDs.UNWILLING_TO_PROVIDE_ANY_REFERENCE]: {
    title: 'Unwilling to provide any reference',
  },
}

export const managerActionIDs = {
  managerNoAction: 0,
  managerRequestedChange: 1,
  managerDeclined: 2,
  managerApproved: 3,
}
