import * as React from 'react'

function SvgWarningLighter(props) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6667 13.6667H1.33338C1.03463 13.6667 0.759633 13.5071 0.610883 13.2483C0.462549 12.9896 0.463382 12.6712 0.613799 12.4133L7.28047 0.746666C7.42963 0.490833 7.7038 0.333333 8.00005 0.333333C8.2963 0.333333 8.57047 0.490833 8.72005 0.746666L15.3867 12.4133C15.5371 12.6712 15.538 12.9896 15.3896 13.2483C15.2413 13.5071 14.965 13.6667 14.6667 13.6667Z"
        fill="#F9DB9F"
      />
      <path
        d="M7.16666 11.2258C7.16666 11.1137 7.18624 11.0112 7.22624 10.915C7.26541 10.82 7.32166 10.7375 7.39416 10.6683C7.46541 10.5983 7.55332 10.5437 7.65416 10.5046C7.75582 10.4662 7.86999 10.4458 7.99749 10.4458C8.12499 10.4458 8.23999 10.4662 8.34249 10.5046C8.44666 10.5437 8.53457 10.5983 8.60582 10.6683C8.67874 10.7375 8.73499 10.82 8.77374 10.915C8.81374 11.0112 8.83332 11.1137 8.83332 11.2258C8.83332 11.3383 8.81374 11.4408 8.77374 11.5342C8.73457 11.6279 8.67832 11.7088 8.60582 11.7792C8.53457 11.8483 8.44666 11.9025 8.34249 11.9425C8.23999 11.9808 8.12499 12 7.99749 12C7.86957 12 7.75582 11.9808 7.65416 11.9421C7.55332 11.9021 7.46541 11.8479 7.39416 11.7788C7.32124 11.7083 7.26499 11.6275 7.22624 11.5338C7.18624 11.4404 7.16666 11.3379 7.16666 11.2258ZM8.61666 9.5H7.37791L7.20249 4.5H8.79166L8.61666 9.5Z"
        fill="#916208"
      />
    </svg>
  )
}

export default SvgWarningLighter
