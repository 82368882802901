import React, { useEffect, useState } from 'react'

import qs from 'qs'

import {
  Alert,
  Box,
  BoxInput,
  Flex,
  LegacySpinner,
  ProgressBar,
} from 'app/components/design-system-components'
import { ModalButtonGroup } from 'app/components/display/display'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import { validateABNFlattened } from 'app/shared_components/form_component'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'
import { StyledSetupContainerSteps } from 'app/sm/team_viewing_preference/setup'
import {
  getCurrentStepForQuickStart,
  getQuickWizardData,
  postNewStepForAgencyForQuickstart,
  postTradingNameAndIds,
} from 'app/sm/team_viewing_preference/setup/api'
import * as setupHelpers from 'app/sm/team_viewing_preference/setup/helpers'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

const THIS_STEP = setupHelpers.SETUP_STARTED

function LetsGetStarted({ match }) {
  const {
    loadingStates: letsGetStartedLoadingStates,
    loadingStatesHelpers: letsGetStartedLoadingStateHelpers,
  } = useLoadingStates()
  const { params } = match
  const { teamSlug = '' } = params
  const curQueries = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  const { teamGUID = '' } = curQueries
  const [formDetails, setFormDetails] = useState({
    tradingName: '',
    reaAgencyID: '',
    domainAgencyID: '',
    abn: '',
  })
  const [formErrors, setFormErrors] = useState({
    tradingName: '',
    reaAgencyID: '',
    domainAgencyID: '',
    abn: '',
  })
  const [wizardGUID, setWizardGUID] = useState('')
  const [currentStep, setCurrentStep] = useState(THIS_STEP)

  useEffect(() => {
    letsGetStartedLoadingStateHelpers.startLoading()
    getQuickWizardData(teamGUID).then((data) => {
      const { domain_agency_id, name, rea_agency_id, abn = '' } = data
      setFormDetails({
        tradingName: name,
        reaAgencyID: rea_agency_id,
        domainAgencyID: domain_agency_id,
        abn,
      })
    })

    getCurrentStepForQuickStart(teamGUID)
      .then((data) => {
        const { current_step_id, id } = data
        setCurrentStep(current_step_id)
        setWizardGUID(id)
        letsGetStartedLoadingStateHelpers.markDoneSuccessfully()
      })
      .catch((err) => {
        letsGetStartedLoadingStateHelpers.setError(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeInputValue = (field) => {
    return (data) => {
      setFormDetails({
        ...formDetails,
        [field]: data,
      })
    }
  }

  const changeABNInputValue = (field) => {
    return ({ value, error }) => {
      setFormDetails({
        ...formDetails,
        [field]: value,
      })
      setFormErrors({
        ...formErrors,
        [field]: error,
      })
    }
  }

  const goToNextStep = () => {
    history.push(urlTo('stepTeamMembers', { teamSlug, agencyGUID: teamGUID }))
  }

  const onClickNext = () => {
    if (formDetails.tradingName === '') {
      snugNotifier.error('Trading Name is required')
      return
    }
    if (formDetails.reaAgencyID === '') {
      snugNotifier.error(`Please enter your agency's REA ID `)
      return
    }
    if (formDetails.abn === '') {
      snugNotifier.error('ABN is required')
      return
    }
    const payload = {
      name: formDetails.tradingName,
      rea_agency_id: formDetails.reaAgencyID,
      domain_agency_id: formDetails.domainAgencyID,
      abn: formDetails.abn,
    }
    postTradingNameAndIds(teamGUID, payload).catch((error) => {
      snugNotifier.error(error.message)
    })
    if (currentStep === THIS_STEP) {
      postNewStepForAgencyForQuickstart(teamGUID, wizardGUID)
        .then(({ current_step }) => {
          const { ID } = current_step
          const step = setupHelpers.findWhichStepToRouteTo(ID)
          history.push(urlTo(step, { teamSlug, agencyGUID: teamGUID }))
        })
        .catch((error) => snugNotifier.error(error.message))
    } else {
      goToNextStep()
    }
  }
  return (
    <StyledSetupContainerSteps>
      <Box mb={theme.space[8] + 'px'}>
        <ProgressBar totalStepsCount={3} lastCheckedStep={1} />
      </Box>
      <h2>Let's Get Started</h2>
      <Box mt={theme.space[5] + 'px'} mb={theme.baseSpace * 5 + 'px'}>
        Is this your agency? Edit and confirm details to create your team. Lite
        accounts are free, see{' '}
        <a href="https://snug.com/pricing/" target="_blank" rel="noreferrer">
          our plans
        </a>{' '}
        for more details
      </Box>
      {letsGetStartedLoadingStates.state === loadingStatesIds.LOADING ? (
        <Flex justifyContent="center">
          <LegacySpinner />
        </Flex>
      ) : (
        <>
          {letsGetStartedLoadingStates.state !== loadingStatesIds.ERROR ? (
            <>
              <Box mt={theme.space[5] + 'px'}>
                <BoxInput
                  labelText="Trading Name"
                  onChange={changeInputValue('tradingName')}
                  placeholder="e.g. JJ Real Estate"
                  required
                  takeMaxWidth
                  initialValue={formDetails.tradingName}
                />
              </Box>
              <Box mt={theme.space[5] + 'px'}>
                <BoxInput
                  labelText="ABN"
                  onChange={changeABNInputValue('abn')}
                  placeholder="12 345 678 901"
                  takeMaxWidth
                  initialValue={formDetails.abn}
                  required
                  handleAsValueAndError
                  validate={validateABNFlattened}
                />
                <error>{formErrors.abn}</error>
              </Box>
              <Box mt={theme.space[5] + 'px'}>
                <BoxInput
                  labelText="REA Agency ID"
                  onChange={changeInputValue('reaAgencyID')}
                  placeholder="ABC123"
                  takeMaxWidth
                  initialValue={formDetails.reaAgencyID}
                  required
                />
              </Box>
              <Box mt={theme.space[5] + 'px'} mb={theme.baseSpace * 5 + 'px'}>
                <BoxInput
                  labelText="Domain Agency ID"
                  onChange={changeInputValue('domainAgencyID')}
                  placeholder="123abc"
                  takeMaxWidth
                  initialValue={formDetails.domainAgencyID}
                />
              </Box>
              <ModalButtonGroup
                primaryLabel="Next"
                secondaryLabel="Back"
                secondaryButtonType="tertiary"
                primaryAction={onClickNext}
                justifyContent="left"
                secondaryAction={() =>
                  history.push(
                    urlTo('teamViewingPreferenceSetup', { teamSlug }),
                  )
                }
              />
            </>
          ) : (
            <Alert variant="warningWithBg" mt={theme.space[3] + 'px'}>
              <div>{letsGetStartedLoadingStates.error.message}</div>
            </Alert>
          )}
        </>
      )}
    </StyledSetupContainerSteps>
  )
}

export default LetsGetStarted
