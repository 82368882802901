import React from 'react'

import { Field, Form, Formik } from 'formik'

import { Alert, Box } from 'app/components/design-system-components'
import { TextArea } from 'app/components/design-system-components/inputs'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { StyledFieldWrapper } from 'app/features/teams/key-logger/components/StepLayoutContainer'
import { API_ERRORS_PROPERTY } from 'app/forms/FieldWrapper'
import { convertTransApiErrorToStatus } from 'app/forms/utils/convertTransApiErrorToStatus'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { removeKeySet } from 'app/services/http/teams/key-logger'

const formFields = {
  notes: 'notes',
}

const renderRemoveKeysetModalFooter = (
  remove,
  onCancel,
  isSubmitting,
  isDisabled,
) => {
  return (
    <GenericModalFooter
      primaryBtnConfig={{
        title: 'Remove',
        onClick: remove,
        props: {
          loading: !!isSubmitting,
          disabled: isDisabled,
          type: 'submit',
        },
      }}
      secondaryBtnConfig={{
        title: 'Cancel',
        onClick: onCancel,
      }}
    />
  )
}

export const RemoveKeySetModal = ({ teamId, keySet, closeModal }) => {
  const onRemove = (values, { setStatus }) => {
    setStatus({ apiErrors: {}, apiGeneralError: '' })
    return removeKeySet(teamId, keySet.guid, values)
      .then((response) => closeModal(response))
      .catch(({ message, detailedError }) => {
        if (!detailedError) {
          return setStatus({ apiGeneralError: message })
        }
        const { notes } = detailedError?.validation || {}
        const transformedApiErrors = {
          [formFields.notes]: notes,
        }
        setStatus({
          [API_ERRORS_PROPERTY]:
            convertTransApiErrorToStatus(transformedApiErrors),
        })
      })
  }

  return (
    <Formik
      onSubmit={onRemove}
      validateOnBlur
      validateOnChange
      validateOnMount
      initialValues={{ [formFields.notes]: '' }}
    >
      {({ submitForm, isSubmitting, isValid, status }) => (
        <Form style={{ height: '100%' }}>
          <GenericModal
            onDismiss={() => closeModal()}
            title={`Remove Keyset ${keySet.label} ?`}
            modalFooter={renderRemoveKeysetModalFooter(
              submitForm,
              () => closeModal(),
              isSubmitting,
              !isValid,
            )}
          >
            {!!status?.apiGeneralError && (
              <Alert variant="primaryWithBg" mb={`${theme.space[4]}px`}>
                {status.apiGeneralError}
              </Alert>
            )}
            <Box>
              <StyledFieldWrapper
                id={formFields.notes}
                name={formFields.notes}
                label="Remove key notes (private to team)"
              >
                <Field name={formFields.notes} as={TextArea} />
              </StyledFieldWrapper>

              <Box mt={5}>This cannot be undone, are you sure?</Box>
            </Box>
          </GenericModal>
        </Form>
      )}
    </Formik>
  )
}
