import React, { useState } from 'react'

import styled from 'styled-components'

import Flex from 'app/components/design-system-components/Flex'
import {
  RemoveRedEyeCrossRounded,
  RemoveRedEyeRounded,
} from 'app/components/design-system-components/icons/image'
import { Input } from 'app/components/design-system-components/inputs/Input/Input'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledPasswordInput = styled(Flex)`
  position: relative;
  max-width: ${theme.width[16]}px;
`

const StyledPasswordStateBox = styled(Flex)`
  position: absolute;
  height: ${theme.height[15]}px;
  right: 0;
  background-color: ${theme.colors.grey200};
  width: ${theme.width[25]}px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.gray500};
  border-radius: 0px 3px 3px 0px;
`

function PasswordInput({ passwordValue, onPasswordChange }) {
  const [inputTypePassword, setInputTypePassword] = useState('password')
  const toggleInputType = () => {
    setInputTypePassword(inputTypePassword === 'password' ? 'text' : 'password')
  }
  return (
    <StyledPasswordInput>
      <Input
        inputProps={{
          type: inputTypePassword,
          placeholder: 'Password',
          autocomplete: 'off',
          onChange: onPasswordChange,
          value: passwordValue,
        }}
      />
      <StyledPasswordStateBox onClick={() => toggleInputType()}>
        {inputTypePassword === 'password' ? (
          <RemoveRedEyeCrossRounded />
        ) : (
          <RemoveRedEyeRounded />
        )}
      </StyledPasswordStateBox>
    </StyledPasswordInput>
  )
}

export default PasswordInput
