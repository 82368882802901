import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Overlay } from 'app/application_revamp/components/Applicant/shared_styles'
import WidgetHeading from 'app/application_revamp/components/Common/WidgetHeading'
import ApplicantRow from 'app/application_revamp/components/Layout/ApplicantRow'
import ApplicationHolder from 'app/application_revamp/components/Layout/ApplicationHolder'
import { capitalizeFirstLetter } from 'app/sm/helpers'
import * as datetime from 'app/utils/datetime/helpers'

const propTypes = {
  child: PropTypes.object,
  open: PropTypes.func,
}

const Name = styled.p`
  font-weight: bolder;
  font-size: 18px;
  color: #212025;
  line-height: 16px;
`

function Child({ child, open, index }) {
  const { firstName, lastName, dob, guidID } = child
  const capitalisedFirstName = capitalizeFirstLetter(firstName)
  const capitalisedLastName = capitalizeFirstLetter(lastName)
  const name = `${capitalisedFirstName} ${capitalisedLastName}`
  const dateOfBirthStr = datetime.formattedDateOfBirth(dob, true)

  return (
    <ApplicantRow>
      <ApplicationHolder>
        <Name>{name}</Name>
        <WidgetHeading title={`Child ${index + 1}`} />
        {dob && <WidgetHeading title={dateOfBirthStr} />}
      </ApplicationHolder>
      <Overlay onClick={() => open(guidID, 'isOpenedChild')} />
    </ApplicantRow>
  )
}

Child.propTypes = propTypes

export default Child
