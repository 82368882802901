import React from 'react'

import PricingVariationModal from 'app/bond_cover/admin/requests/pricing_variation_modal'

const categories = [
  { id: 1, label: 'Referral discount' },
  { id: 2, label: 'Other' },
]

const pricingElements = [
  { id: 1, label: 'Base premium' },
  { id: 2, label: 'Referral fee' },
  { id: 3, label: 'Surety fee' },
  { id: 4, label: 'Admin fee' },
]

const initialState = () => ({
  errorAmount: '',
  form: {
    category: '',
    comment: '',
    amount: '',
    pricingElement: 0,
  },
  validations: {
    category: { ok: true, error: undefined },
    comment: { ok: true, error: undefined },
    amount: { ok: true, error: undefined },
    pricingElement: { ok: true, error: undefined },
  },
  modalClicked: false,
})

const validationCtors = {
  ok: () => ({
    ok: true,
    error: '',
  }),
  error: (message) => ({
    ok: false,
    error: message,
  }),
}

const isPricingDeleted = (pricing) => pricing.deletedAt !== null

class PricingVariationWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ errorAmount: nextProps.errorAmount })
    if (nextProps.resetField) {
      this.reset()
    }
  }

  reset() {
    this.setState(initialState())
  }

  submit = () => {
    const { form } = this.state
    const { request } = this.props

    if (request) {
      this.props.addPricingVariation(request.guidID, form)
    }
    this.reset()
  }

  triggerModal = (mode) => {
    this.setState({ modalClicked: mode })
  }

  updateField = (fieldType, key, value) => {
    let castedValue
    const previous = this.state.form
    switch (fieldType) {
      case 'number':
        let checked = false

        if (value === '.' || value === '-.') {
          castedValue = this.state.form[key]
          checked = true
        }

        if (!checked) {
          if (!value.endsWith('.')) {
            castedValue = parseFloat(value)
          } else if (!value.slice(0, value.length - 1).includes('.')) {
            castedValue = `${parseFloat(value)}.`
          } else {
            castedValue = this.state.form[key]
          }
        }

        if (Number.isNaN(castedValue)) {
          if (value !== '-' && value !== '') {
            castedValue = this.state.form[key]
          } else {
            castedValue = value
          }

          if (castedValue === 0 && value === '') {
            castedValue = ''
          }
        }

        break
      case 'string':
        castedValue = value
        break
      default:
        return
    }

    this.setState(
      {
        form: Object.assign({}, previous, {
          [key]: castedValue,
        }),
      },
      () => {
        this.validate(key, value)
      },
    )
  }

  validate = (field, value) => {
    let previous = this.state.validations
    let next
    let errorMessage
    switch (field) {
      case 'category':
        if (value.length === 0) {
          errorMessage = `${field.charAt(0).toUpperCase()}${field.slice(
            1,
          )} is a required field`
        }
        break
      case 'amount':
        if (value.length === 0 || Number(value) === 0) {
          errorMessage = `${field.charAt(0).toUpperCase()}${field.slice(
            1,
          )} is a required field`
        }

        if (!this.props.isPricingVariationValid(value)) {
          errorMessage = `Cumulative discounts cannot be greater than the total BondCover fee.`
        }

        this.setState({ errorAmount: '' })
        break
      case 'comment':
        if (value.length === 0) {
          errorMessage = `${field.charAt(0).toUpperCase()}${field.slice(
            1,
          )} is a required field`
        }
        break
      case 'pricingElement':
        if (value.length === 0) {
          errorMessage = `${field.charAt(0).toUpperCase()}${field.slice(
            1,
          )} is a required field`
        }
      default:
        break
    }

    if (errorMessage !== undefined) {
      next = {
        [field]: validationCtors.error(errorMessage),
      }
    } else {
      next = { [field]: validationCtors.ok() }
    }

    this.setState({ validations: Object.assign({}, previous, next) })
  }

  render() {
    const { form, validations, errorAmount, modalClicked } = this.state
    const {
      bondCoverAmount,
      pricingVariations = [],
      renter = {},
      isPricingVariationFormReadOnly,
    } = this.props
    const isSubmitDisabled =
      Object.keys(validations).some((key) => validations[key].error !== '') ||
      form.amount === '-'
    const { request } = this.props
    const { exitStatus = 0 } = request || {}

    const filteredVariations = pricingVariations.filter((p) => !p.deletedAt)
    const variation =
      filteredVariations.length === 1 ? filteredVariations[0].amount : 0
    const amountArr = filteredVariations.map((p) => p.amount)

    const totalAmount =
      filteredVariations.length > 1
        ? amountArr.reduce((a, b) => a + b, 0)
        : variation

    return (
      <div className="discount-widget">
        <div className="credit-cards-header mb20">
          <h3>
            Pricing Variations{' '}
            {totalAmount ? `(Net variation $${totalAmount})` : ''}
          </h3>
          {exitStatus === 0 && !isPricingVariationFormReadOnly && (
            <button
              onClick={() => this.triggerModal(true)}
              className="btn btn-round"
            >
              <i className="icon-add" />
            </button>
          )}
        </div>

        {modalClicked && (
          <PricingVariationModal
            categories={categories}
            pricingElements={pricingElements}
            form={form}
            validations={validations}
            updateField={this.updateField}
            validate={this.validate}
            isSubmitDisabled={isSubmitDisabled}
            errorAmount={errorAmount}
            triggerModal={this.triggerModal}
            submit={this.submit}
          />
        )}

        {pricingVariations.map((it) => (
          <div
            key={it.guidID}
            className={`bc-hub-item-link card-block ${
              isPricingDeleted(it) ? 'op04' : ''
            }`}
          >
            <div className="card-wrapper">
              <div className="card-details pull-right">
                <content>
                  {renter && (
                    <div className="pl25 mb5">
                      <label>
                        <b>Renter:</b>
                      </label>
                      <span className="ml10">
                        {renter.firstName} {renter.lastName}
                      </span>
                    </div>
                  )}
                  <div className="pl25 mb5">
                    <label>
                      <b>Pricing Element:</b>
                    </label>
                    <span className="ml10">
                      {
                        pricingElements.find(
                          (element) => element.id === it.pricingElement,
                        ).label
                      }
                    </span>
                  </div>
                  <div className="pl25 mb5">
                    <label>
                      <b>Category:</b>
                    </label>
                    <span className="ml10">
                      {categories.find((cat) => cat.id === it.category).label}
                    </span>
                  </div>
                  <div className="pl25 mb5">
                    <label>
                      <b>Amount:</b>
                    </label>
                    <span className="ml10">$ {it.amount}</span>
                  </div>
                  <div className="pl25 mb5">
                    <label>
                      <b>Date:</b>
                    </label>
                    <span className="ml10">{it.date}</span>
                  </div>
                  <div className="pl25 mb5">
                    <label>
                      <b>Comment:</b>
                    </label>
                    <span className="ml10">{it.comment}</span>
                  </div>
                </content>
              </div>

              <div>
                <div className="table-cards">
                  <div className="cell-t">
                    {!isPricingDeleted(it) && !isPricingVariationFormReadOnly && (
                      <button
                        className="textButton"
                        onClick={() =>
                          this.props.deletePricingVariation(
                            request.guidID,
                            it.guidID,
                          )
                        }
                      >
                        Remove
                      </button>
                    )}
                    {isPricingDeleted(it) && (
                      <p className="mt30 mb0">Removed</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default PricingVariationWidget
