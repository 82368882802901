import React from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import exclamation from 'app/assets/icons/exclamation.png'
import greenTick from 'app/assets/icons/green-tick.png'
import { Box, Button, TextSpan } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import OverlayPortalModal from 'app/components/design-system-components/modals/OverlayPortalModal'
import * as Display from 'app/components/display/display'
import { RedirectRentalReferenceModal } from 'app/components/display/redirect/RedirectRentalReferenceModal'
import {
  declineReasonsConfig,
  declineReasonsIDs,
  managerActionIDs,
} from 'app/constants/rental-references.constants'
import { RentalReferenceDetailsComponent } from 'app/match/rental_reference/display/renter_address_history_detailed/rental_reference_detailed/component'
import { ratingVersions } from 'app/match/rental_reference/rating_categories.constants'
import { resendReferenceRequest } from 'app/services/http/rentalReferences'
import * as snugNotifier from 'app/services/snugNotifier'
import { ErrorMessage } from 'app/shared_components/helpers'
import {
  calculateDuration,
  capitalizeFirstLetter,
  getDisplayedRent,
  isAddressHistoryOwned,
  isAddressHistoryTemporaryBnBArrangement,
  isAddressHistoryWithFamilyOrFriends,
  isRentalReferenceVerified,
  isRentalReferenceWithExceptionRatings,
  propertyArrangementNotShowStatusPill,
  rentalReferenceVerifyStatus,
  TEMPORARY_BNB_ARRANGEMENT,
  totalTimeInMonths,
  urlTo,
} from 'app/sm/helpers'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'
import { ApplicationDetailsNumbersComponent } from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'
import { HOUR_DAY_MONTH_YEAR_FORMAT } from 'app/utils/datetime/helpers'
import {
  formatAndPrefix,
  LANDLINE_PREFIX,
  OFFICE_PREFIX,
} from 'app/utils/phonenumber/helpers'
import { FeatureFlag } from 'config/features'

import 'app/match/rental_reference/display/renter_address_history_detailed/rental-address-history-detailed.scss'

const getOverAllRatingText = (ratings) => {
  const overallRating = ratings.filter((rating) => rating.code === 'overall')
  return overallRating.length > 0 && overallRating[0].comment
}

const VERIFIED = 'Verified'
const INFO = 'Info'
const NOT_APPLICABLE = 'N/A'
const arrangementStatus = [
  'N/A',
  'I rented',
  'I boarded',
  'I lived with friends',
  'I lived with family',
  'I owned the property',
  'I sub-letted',
  'I lived with family or friends',
  'I rented with an agent',
  'I rented with private owner',
  TEMPORARY_BNB_ARRANGEMENT,
]

const AddressHistoryReviewComponent = ({
  overAllRatingText = '',
  titleIcon,
  matchingIP,
  requestIP,
  ratings = [],
  ratingCategories = [],
  referenceSetVersion,
  addressInfo = {},
  onRemoveRentalReference,
  showExclamationIcon = false,
  renterName = '',
}) => (
  <div className="application-address-history-review-component">
    <div className="application-seperator mb20" />
    <div className="application-address-history-review-container">
      <RentalReferenceDetailsComponent
        title="Rental reference"
        commentClassName="application-address-history-comment-container"
        text={overAllRatingText}
        componentClassName="width-desktop90-mobile100"
        titleIcon={titleIcon}
        ratings={ratings}
        matchingIP={matchingIP}
        requestIP={requestIP}
        ratingCategories={ratingCategories}
        referenceSetVersion={referenceSetVersion}
        addressInfo={addressInfo}
        onRemoveRentalReference={onRemoveRentalReference}
        showExclamationIcon={showExclamationIcon}
        renterName={renterName}
      />
    </div>
  </div>
)

const AddressHistoryLedgerComponent = ({
  ledger = [],
  toggleDocumentsViewer,
}) => (
  <div className="application-address-history-review-component">
    <div className="application-seperator" />
    {
      <div className="title application-ledger-container mt20">
        <div className="income-category">Rental Ledger</div>
        {ledger.map((document, index) => (
          <AppDocumentListItemSecondVersion
            titleClass="mt0"
            document={document}
            key={index}
            docBadge={
              document.addedByRenter && (
                <Badge
                  text="Renter upload"
                  variant="darkGray"
                  tooltipId={document.guidID}
                  tooltipText="Uploaded by Renter in their Rental Profile"
                />
              )
            }
            toggleDocumentsViewer={
              toggleDocumentsViewer
                ? () => toggleDocumentsViewer(document.guidID)
                : undefined
            }
          />
        ))}
      </div>
    }
  </div>
)

const ReferenceVerifiedStatusComponent = ({
  rentalReferenceSubmitDate,
  rentalReferenceStatus,
  isReferenceRequired,
  declineReason,
}) => {
  const statusClass =
    isReferenceRequired && rentalReferenceStatus !== VERIFIED
      ? declineReason === declineReasonsIDs.PREFER_VERBAL_REFERENCE
        ? 'Pending'
        : rentalReferenceStatus
      : isReferenceRequired
      ? VERIFIED
      : INFO
  const statusMessage = isReferenceRequired ? VERIFIED : NOT_APPLICABLE
  const formattedRentalReferenceSubmitDate = rentalReferenceSubmitDate
    ? moment(rentalReferenceSubmitDate).format(HOUR_DAY_MONTH_YEAR_FORMAT)
    : null

  return (
    <div className="reference-right-header pdf-font-size-10">
      <p className="reference-verified-date mb0 width-max-content-mobile">
        {formattedRentalReferenceSubmitDate}
      </p>
      {/* TODO: Refactor and unify getting/displaying verifcation status everywhere in the app */}
      {isReferenceRequired && rentalReferenceStatus !== VERIFIED ? (
        <p className={`mb0 reference-verified-status pdf-hide ${statusClass}`}>
          {rentalReferenceStatus}
        </p>
      ) : (
        <p
          className={`mb0 reference-verified-status pdf-hide mobile-mt10 ${statusClass}`}
        >
          {statusMessage}
        </p>
      )}
    </div>
  )
}

export class AddressHistoryDetailsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      requestStatus: 'Not clicked',
      rentalReferenceRequestError: '',
      isEmailEditClicked: false,
      resendEmail: '',
      resendEmailError: '',
      editEmailText: 'Edit email',
      formInputBoxWidth: '',
      personalMessage: '',
      personalMessageError: '',
      includeMessage: false,
    }
  }

  componentDidMount() {
    const { addressInfo } = this.props
    this.setState({
      latestReference: addressInfo?.latestRedirect
        ? {
            ...addressInfo.latestRedirect,
            phoneNumber: addressInfo.latestRedirect.phone,
          }
        : addressInfo,
    })
  }

  onChangeResendEmail = (event) => {
    const {
      target: { value },
    } = event
    this.setState({ resendEmail: value, resendEmailError: '' })
  }

  onToggleIncludeMessage = () => {
    this.setState({ includeMessage: !this.state.includeMessage }, () => {
      if (!this.state.includeMessage) {
        this.setState({ personalMessage: '' })
      }
    })
  }

  // Display alert icon only in case of a matching ip issue, or any rating has an exception (negative answer)
  displayRentalReferenceAlertIcon = (ratings, matchingIP) => {
    return (
      matchingIP || (ratings && isRentalReferenceWithExceptionRatings(ratings))
    )
  }

  updateMessage = () => {
    return (data) => {
      this.setState({
        personalMessage: data.value,
        personalMessageError: '',
      })
    }
  }

  renderContactRedirectLabel = () => {
    const { addressInfo = {} } = this.props
    const { firstName, lastName, email, phoneNumber, officeNumber, guidID } =
      addressInfo
    return (
      <>
        <Badge
          data-tooltip-content=""
          data-tooltip-id={guidID}
          variant="blue"
          text="Redirect"
          textAlign="center"
        />
        <ReactTooltip
          id={guidID}
          data-type="info"
          place="top"
          className="tool-tip-style"
        >
          <Box maxWidth="272px" textAlign="center">
            <TextSpan as="div">Original:</TextSpan>
            <TextSpan as="span">
              {firstName} {lastName} {phoneNumber && `M: ${phoneNumber}`}{' '}
              {officeNumber && `O: ${officeNumber}`} {email && `E: ${email}`}
            </TextSpan>
          </Box>
        </ReactTooltip>
      </>
    )
  }

  onResend = (newEmail, message) => {
    const { agencyGUID, addressInfo = {} } = this.props
    const payload = {
      email: newEmail,
      personalMessage: message,
      sendingAgencyGUID: agencyGUID,
      isMessageIncluded: !!message,
    }
    return resendReferenceRequest(addressInfo.guidID, payload)
      .then(() => snugNotifier.success('Requested successfully'))
      .catch((error) => {
        snugNotifier.error(error.message)
        return Promise.reject(error)
      })
  }

  render() {
    const {
      addressInfo = {},
      ratingCategories,
      applicationId,
      selectedApplicantGUID,
      onRemoveRentalReference,
      agencyGUID,
      referenceSetVersion,
      toggleDocumentsViewer,
      renterName = '',
      excludeRequestReasonForMoving = false,
    } = this.props
    const {
      startDate,
      endDate,
      isRollingLease,
      propertyArrangement = 0,
      rate,
      address,
      ledger = [],
      agencyName,
      reasonForLeaving,
      managerRated = false,
      rating = 0,
      ratings = [],
      guidID,
      allRatingsCompleted = false,
      lastReferenceRequest = {},
      confirmationIP,
      requestIP,
      shouldContactReference,
      managerAction,
      declineReason,
      latestRedirect,
    } = addressInfo

    const { latestReference } = this.state

    const isReferenceRequired = shouldContactReference
    const matchingIP =
      requestIP && confirmationIP && requestIP === confirmationIP
    const overAllRatingText = managerRated ? getOverAllRatingText(ratings) : ''
    const duration = calculateDuration(startDate, endDate, isRollingLease)
    const totalTenure = totalTimeInMonths(startDate, endDate, isRollingLease)
    const arrangementText = arrangementStatus[propertyArrangement]
    const formattedPhoneNumber = formatAndPrefix(
      latestReference?.phoneNumber,
      LANDLINE_PREFIX,
      true,
    )

    const formattedOfficeNumber = formatAndPrefix(
      latestReference?.officeNumber,
      OFFICE_PREFIX,
      true,
    )
    const managerOverAllRatingScore = Math.round(rating)
    const hasLedgerDocuments = !!ledger.length

    const isDeclined = managerAction === managerActionIDs.managerDeclined
    const declineReasonText = !isDeclined
      ? null
      : !!declineReason
      ? declineReasonsConfig[declineReason]?.title
      : 'N/A'

    const rentalReferenceStatus = rentalReferenceVerifyStatus(
      managerRated,
      ratings,
      rate,
      totalTenure,
      allRatingsCompleted,
      isDeclined,
      declineReason,
    )
    const isRequestReviewAllowed =
      !isAddressHistoryOwned(propertyArrangement) && !managerRated
    const rentalReferenceSubmitDate =
      ratings && ratings[0] && ratings[0].createdAt

    const ratingVersionConfig = Object.values(ratingVersions).find(
      (version) => version.ref === referenceSetVersion,
    )

    const isRentAmountVerified = !!(
      managerRated &&
      ratings.find(
        (result) =>
          result.code === ratingVersionConfig.rentalAmountCode &&
          !result.exception,
      )
    )
    const isRatedRentAmountInvalid =
      managerRated === true && !isRentAmountVerified
    const isTenureVerified = !!(
      managerRated &&
      ratings.find(
        (result) =>
          result.code === ratingVersionConfig.tenancyTermCode &&
          !result.exception,
      )
    )
    const isRatedTenureInvalid = managerRated === true && !isTenureVerified
    const isOwnerOfTheProperty =
      arrangementStatus[propertyArrangement] === 'I owned the property'
    const isTemporaryBnBArragement =
      isAddressHistoryTemporaryBnBArrangement(propertyArrangement)

    const isOwnerOrTemporaryArrangement =
      isOwnerOfTheProperty || isTemporaryBnBArragement

    let rentalReferenceTitleIcon = ''
    const showExclamationIcon = this.displayRentalReferenceAlertIcon(
      ratings,
      matchingIP,
    )
    if (isRentalReferenceVerified(rentalReferenceStatus)) {
      rentalReferenceTitleIcon = greenTick
    } else if (showExclamationIcon) {
      rentalReferenceTitleIcon = exclamation
    }

    let rentalReferenceRentTitleIcon = ''
    if (isRentAmountVerified) {
      rentalReferenceRentTitleIcon = greenTick
    } else if (isRatedRentAmountInvalid) {
      rentalReferenceRentTitleIcon = exclamation
    }

    let rentalReferenceRentTitleIconTooltipText = ''
    let rentalReferenceRentTitleIconTooltipTextGUID = ''
    if (isRatedRentAmountInvalid) {
      const ratingsValue = ratings.find(
        (result) =>
          result.code === ratingVersionConfig.rentalAmountCode &&
          result.exception,
      )
      const answer = ratingsValue && ratingsValue.answerValue
      const answerGUID = ratingsValue && ratingsValue.guidID
      rentalReferenceRentTitleIconTooltipText = `Referee responded with $${answer}`
      rentalReferenceRentTitleIconTooltipTextGUID = answerGUID
    }

    let rentalReferenceTenureTitleIcon = ''
    if (isTenureVerified) {
      rentalReferenceTenureTitleIcon = greenTick
    } else if (isRatedTenureInvalid) {
      rentalReferenceTenureTitleIcon = exclamation
    }

    const { rentalReferenceRequestError } = this.state

    const isReferenceRedirected = !!latestRedirect

    const rentalReferenceRedirectCmp = (
      <Button
        variant="linkBlueUnderlined"
        onClick={() =>
          this.setState({
            isOpened: true,
          })
        }
      >
        Redirect
      </Button>
    )

    const referenceFirstName = latestReference?.firstName,
      referenceLastName = latestReference?.lastName,
      referenceEmail = latestReference?.email

    const displayedRent = getDisplayedRent(rate, propertyArrangement)
    return (
      <div className="default-body-text address-history-list-container">
        <div className="padding-for-address-box">
          <div className="new-design-application-history-container">
            <div className="reference-history-header">
              <p className="reference-left-header mb0 pdf-font-size-12">
                {`${address.friendlyName}, ${address.suburb}, ${address.state}, ${address.postcode}`}
              </p>
              {!propertyArrangementNotShowStatusPill.includes(
                propertyArrangement,
              ) && (
                <ReferenceVerifiedStatusComponent
                  rentalReferenceSubmitDate={rentalReferenceSubmitDate}
                  isReferenceRequired={isReferenceRequired}
                  rentalReferenceStatus={rentalReferenceStatus}
                  declineReason={declineReason}
                />
              )}
            </div>
            <div className="application-seperator mb15" />
            <div className="application-identification-sub-container p0 display-flex pdf-mt-n-20 pdf-width-80">
              <ApplicationDetailsNumbersComponent
                icon={rentalReferenceRentTitleIcon}
                title="Rent"
                text={displayedRent}
                componentClassName="justify-content-unset"
                textClassName="pdf-font-size-10"
                titleTextClassName="pdf-font-size-10"
                iconText={rentalReferenceRentTitleIconTooltipText}
                rentalReferenceRentTitleIconTooltipTextGUID={
                  rentalReferenceRentTitleIconTooltipTextGUID
                }
              />
              <ApplicationDetailsNumbersComponent
                title="Tenure"
                icon={rentalReferenceTenureTitleIcon}
                text={duration}
                componentClassName="justify-content-unset flex-2"
                textClassName="pdf-font-size-10"
                titleTextClassName="pdf-font-size-10"
              />
              <ApplicationDetailsNumbersComponent
                title="Arrangement"
                text={arrangementText}
                componentClassName="justify-content-unset"
                textClassName="pdf-font-size-10"
                titleTextClassName="pdf-font-size-10"
              />
            </div>
            {!excludeRequestReasonForMoving && (
              <div>
                <ApplicationDetailsNumbersComponent
                  title="Reason for leaving"
                  text={reasonForLeaving || 'N/A'}
                  componentClassName="justify-content-unset ha"
                  textClassName="pdf-font-size-10"
                  titleTextClassName="pdf-font-size-10"
                />
              </div>
            )}

            {isDeclined &&
              declineReason !== declineReasonsIDs.PREFER_VERBAL_REFERENCE && (
                <div>
                  <ApplicationDetailsNumbersComponent
                    title="Decline reason"
                    text={declineReasonText}
                    componentClassName="justify-content-unset ha"
                    textClassName="pdf-font-size-10"
                    titleTextClassName="pdf-font-size-10"
                  />
                </div>
              )}

            {!isOwnerOrTemporaryArrangement && (
              <div className="applicant-container height-auto history-contact-info-container">
                <div className="header pdf-font-size-10">Contact</div>
                <div className="applicant-upper-container applicant-address-history-upper-container width100 height100">
                  <div className="applicant-contact-info-details">
                    <div className="household-applicant-table avatar-container mt0">
                      <div className="avatar application-avatar">
                        <span className="app-avatar-initial-position default-font-family">{`${capitalizeFirstLetter(
                          referenceFirstName?.substring(0, 1),
                        )}${capitalizeFirstLetter(
                          referenceLastName?.substring(0, 1),
                        )}`}</span>
                      </div>
                    </div>
                    <Display.StandardContactInfoContainer
                      mainTitle={`${capitalizeFirstLetter(
                        referenceFirstName,
                      )} ${capitalizeFirstLetter(referenceLastName)}`}
                      linesText={[
                        formattedPhoneNumber,
                        formattedOfficeNumber,
                        referenceEmail,
                        `${
                          agencyName
                            ? capitalizeFirstLetter(agencyName)
                            : 'Owner'
                        }`,
                      ]}
                      badgeCmp={
                        isReferenceRedirected &&
                        this.renderContactRedirectLabel()
                      }
                    />
                  </div>
                  {isRequestReviewAllowed && !managerRated && (
                    <div>
                      {shouldContactReference ? (
                        <Display.ResendReference
                          from="renterSummary"
                          enableManualReference={
                            FeatureFlag.ManualRentalReference.isOn
                          }
                          manualReferenceLink={`${urlTo(
                            'addManualRentalReference',
                            {
                              rentalHistoryId: guidID,
                            },
                          )}?applicationId=${applicationId}&applicantId=${selectedApplicantGUID}&createLiteAccount=false`}
                          onSubmitResend={this.onResend}
                          lastSend={{
                            email: lastReferenceRequest.requestReceiver,
                            senderEmail: lastReferenceRequest.requestSender,
                            sentAt: lastReferenceRequest.requestSentTime,
                          }}
                          lastRequest={this.state.lastRequest}
                          isDeclined={isDeclined}
                          changeReferenceCmp={rentalReferenceRedirectCmp}
                        />
                      ) : (
                        <div className="display-flex flex-direction-column resend-rental-reference-button-container">
                          <div className="mt15 display-flex">
                            <img
                              className="ml5 mr5 pb2 max-height-20"
                              src={exclamation}
                              alt="Exclamation Icon"
                            />
                            <Display.DefaultBodyText text="Personal Information Collection and Disclosure Authority not Provided" />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.isOpened && (
                    <OverlayPortalModal>
                      <RedirectRentalReferenceModal
                        teamId={agencyGUID}
                        rentalHistoryGUID={guidID}
                        applicationId={applicationId}
                        applicantId={selectedApplicantGUID}
                        closeModal={(redirectContact) =>
                          this.setState({
                            isOpened: false,
                            ...(redirectContact && {
                              lastRequest: {
                                ...redirectContact,
                                sentAt: moment.now(),
                              },
                              latestReference: redirectContact,
                            }),
                          })
                        }
                      />
                    </OverlayPortalModal>
                  )}
                </div>
              </div>
            )}
            {managerRated && (
              <AddressHistoryReviewComponent
                overAllRatingText={overAllRatingText}
                managerOverAllRatingScore={managerOverAllRatingScore}
                titleIcon={rentalReferenceTitleIcon}
                ratings={ratings}
                ratingCategories={ratingCategories}
                referenceSetVersion={referenceSetVersion}
                matchingIP={matchingIP}
                requestIP={requestIP}
                addressInfo={addressInfo}
                onRemoveRentalReference={onRemoveRentalReference}
                showExclamationIcon={showExclamationIcon}
                renterName={renterName}
              />
            )}
            {hasLedgerDocuments && (
              <AddressHistoryLedgerComponent
                ledger={ledger}
                toggleDocumentsViewer={toggleDocumentsViewer}
              />
            )}
          </div>
        </div>
        <ErrorMessage error={rentalReferenceRequestError} />
      </div>
    )
  }
}
