import { useCallback, useEffect, useMemo, useState } from 'react'

import { Field, Form, Formik, useFormikContext } from 'formik'
import { parsePhoneNumber } from 'libphonenumber-js'
import qs from 'qs'

import { Box } from 'app/components/design-system-components'
import { InputField } from 'app/components/design-system-components/inputs'
import { Select } from 'app/components/design-system-components/inputs/Select/Select'
import {
  formFields,
  formValidation,
} from 'app/components/ViewingRegisterRequestFormModal/form-utils'
import {
  CustomMobileInput,
  StyledFieldWrapper,
  StyledInputWrapper,
} from 'app/components/ViewingRegisterRequestFormModal/styled-form-component'
import * as snugNotifier from 'app/services/snugNotifier'
import DateDropdown from 'app/sm/properties/components/excluded_times/partials/date_dropdown'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as phoneNumberHelpers from 'app/utils/phonenumber/helpers'
import * as viewingsHelpers from 'app/utils/viewings/helpers'

const AU_COUNTRY_CODE = 'AU'

const RequestViewingModalBody = ({ formRef, currentUser }) => {
  const baseInitialValues = useMemo(() => {
    const {
      enquirer_first_name = '',
      enquirer_last_name = '',
      enquirer_email = '',
      enquirer_mobile = '',
    } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    const parsedNumber = currentUser.mobile
      ? parsePhoneNumber(currentUser.mobile.replace(/\s/g, ''), AU_COUNTRY_CODE)
      : ''

    const {
      email: currentUserEmail,
      firstName: currentUserFirstName,
      lastName: currentUserLastName,
    } = currentUser

    return {
      [formFields.firstName]: currentUserFirstName || enquirer_first_name || '',
      [formFields.lastName]: currentUserLastName || enquirer_last_name || '',
      [formFields.email]: currentUserEmail || enquirer_email || '',
      [formFields.contactNumber]:
        phoneNumberHelpers.formatPhoneNumber(parsedNumber.number) ||
        phoneNumberHelpers.formatPhoneNumber(enquirer_mobile) ||
        '',
    }
  }, [currentUser])

  return (
    <Box color="black">
      <Formik
        enableReinitialize={true}
        initialValues={baseInitialValues}
        onSubmit={() => {}}
        innerRef={formRef}
        validate={formValidation}
      >
        {(props) => <RequestFormBody formBag={props} />}
      </Formik>
    </Box>
  )
}

const RequestFormBody = ({ formBag }) => {
  const { values } = useFormikContext()

  const START_TIME = 8
  const END_TIME = 17

  const timesFrom9AMto5PM = viewingsHelpers.startToEndTimeIntervalsFromHours(
    START_TIME,
    END_TIME,
    15,
  )

  const [preferredTimesOptions, setPreferredTimesOptions] = useState(
    timesFrom9AMto5PM.map((time) => ({
      option: time,
      optionMeta: {
        id: time,
        displayText: time,
        noSeparator: true,
      },
    })),
  )

  const setOptionsWhenUpdatePreferredTimes = useCallback(() => {
    if (
      values?.preferredTime &&
      !preferredTimesOptions.some(
        (durationMinutes) => durationMinutes.guid === values?.preferredTime,
      )
    ) {
      setPreferredTimesOptions((oldState) => [
        ...oldState,
        { guid: values?.preferredTime, name: values?.preferredTime },
      ])
    }
  }, [preferredTimesOptions, values?.preferredTime])

  useEffect(() => {
    setOptionsWhenUpdatePreferredTimes()
  }, [values?.preferredTime, setOptionsWhenUpdatePreferredTimes])

  return (
    <Form>
      <StyledInputWrapper>
        <StyledFieldWrapper
          id={formFields.firstName}
          name={formFields.firstName}
        >
          <Field
            name={formFields.firstName}
            as={InputField}
            id={formFields.firstName}
            placeholder={`First name`}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper id={formFields.lastName} name={formFields.lastName}>
          <Field
            name={formFields.lastName}
            as={InputField}
            id={formFields.lastName}
            placeholder={`Last name`}
          />
        </StyledFieldWrapper>
      </StyledInputWrapper>
      <StyledInputWrapper>
        <StyledFieldWrapper id={formFields.email} name={formFields.email}>
          <Field
            name={formFields.email}
            as={InputField}
            id={formFields.email}
            placeholder={`e.g email@example.com`}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper
          id={formFields.contactNumber}
          name={formFields.contactNumber}
        >
          <Field
            name={formFields.contactNumber}
            as={CustomMobileInput}
            id={formFields.contactNumber}
            placeholder={`+61 412 345 678`}
          />
        </StyledFieldWrapper>
      </StyledInputWrapper>
      <StyledInputWrapper>
        <StyledFieldWrapper
          id={formFields.preferredDate}
          name={formFields.preferredDate}
        >
          <Field
            name={formFields.preferredDate}
            as={InputField}
            id={formFields.preferredDate}
            placeholder={`Sat 12 Jan`}
            render={({ field }) => (
              <DateDropdown
                mr={0}
                width="100%"
                date={field.value}
                onDateChangedHandler={(date) => {
                  if (dateTimeHelpers.beforeToday(date)) {
                    snugNotifier.error(
                      'Choose a date within the past 24 hrs or later',
                    )
                  } else {
                    formBag.handleChange(formFields.preferredDate)(date)
                  }
                }}
                closeOnChange
                labelRenderer={(date) =>
                  date
                    ? `${dateTimeHelpers.formattedUTCDisplayDate(date)}`
                    : 'Select preferred date'
                }
              />
            )}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper
          id={formFields.preferredTime}
          name={formFields.preferredTime}
        >
          <Field
            name={formFields.preferredTime}
            id={formFields.preferredTime}
            render={({ field }) => (
              <Select
                id={formFields.preferredTime}
                name={formFields.preferredTime}
                options={preferredTimesOptions}
                changeHandler={(value) =>
                  formBag.handleChange(formFields.preferredTime)(value.option)
                }
                value={preferredTimesOptions.find(
                  ({ option }) => option === field.value,
                )}
                label="Select preferred time"
                triggerWrapperComponentProps={{ height: '50px' }}
                optionsHeight={'300px'}
              />
            )}
          />
        </StyledFieldWrapper>
      </StyledInputWrapper>
    </Form>
  )
}

export default RequestViewingModalBody
