import React from 'react'

import { Link } from 'react-router-dom'

import { history } from 'app/shared_components/router'
import Spinner from 'app/sm/common/spinner'
import { urlTo } from 'app/sm/helpers'
import Breadcrumbs from 'app/sm/ppp/breadcrumbs'

class GetStarted extends React.Component {
  constructor(props) {
    super(props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.teams !== this.props.teams ||
      nextProps.match.params.teamSlug !== this.props.match.params.teamSlug
    ) {
      const currentTeam = nextProps.teams.find(
        (team) => team.slug === nextProps.match.params.teamSlug,
      )
      this.props.changeTeam(currentTeam)
    }
  }

  render() {
    const { profile, steps, update, spinner, backUrl } = this.props

    return (
      <div className="profile-screen">
        <Breadcrumbs activeBreadCrumb="get-started" />
        <div className="mobile-only">
          <button
            className="btn btn-transparent btn-left xs-text-center wa"
            onClick={() => history.push(backUrl)}
          >
            <i className="icon-arrow-left left"></i>
            <span>Back</span>
          </button>
        </div>
        <div className="section-title">
          <div className="left column">
            <h3>Add a property</h3>
          </div>
          {/*<div className="right"></div>*/}
        </div>
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <div className="row mb25">
              <div className="col-sm-12">
                <p className="mt10 mb0">
                  Let's get started setting up a property so that you can start
                  reviewing applications. It only take a few minutes. You'll
                  need:
                </p>
                <ul className="lh13">
                  <li className="mt10 mb0">
                    Basic details about the property like address, bedrooms and
                    bathrooms
                  </li>
                  <li className="mt10 mb0">
                    At least one photograph that showcases the property
                  </li>
                  <li className="mt10 mb0">
                    The details of the lease like expected rent, preferred lease
                    term and earliest availability date
                  </li>
                  <li className="mt10 mb0">
                    Some basic preferences like whether pets are allowed
                  </li>
                </ul>
                <p className="mt10 mb0">
                  Once complete you'll be presented with a link that you can
                  share on your property advertisements, via email or on social
                  media that will allow people to apply for your property.
                </p>
              </div>
            </div>
            {spinner && <Spinner />}
            {!spinner && (
              <div className="row pt25 mobile-form-button">
                <div className="col-sm-5 pb5 tablet-only">
                  <button
                    className="btn btn-transparent btn-left xs-text-center wa"
                    onClick={() => history.push(backUrl)}
                  >
                    <i className="icon-arrow-left left"></i>
                    <span>Back</span>
                  </button>
                </div>
                <div className="col-sm-7">
                  <button
                    disabled={spinner}
                    onClick={() => update(steps.GetStarted, profile)}
                  >
                    Get started{' '}
                    <i className={spinner ? 'fa fa-spinner fa-pulse' : ''} />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default GetStarted
