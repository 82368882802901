import React from 'react'

import { Link } from 'react-router-dom'

import isEmptyObject from 'app/shared_components/check_empty_object'
import * as validations from 'app/shared_components/validations'

const PASSWORDS_DONT_MATCH_ERROR = 'Passwords do not match'

const NEW_PASSWORD_CONFIRM_FIELD = 'newPasswordConfirm'
const NEW_PASSWORD_FIELD = 'newPassword'

class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      errors: {},
      showold: false,
      shownew: false,
      disabled: true,
      clicked: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ clicked: false })
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    nextState.disabled = !(
      nextState.currentPassword &&
      nextState.newPassword &&
      nextState.newPasswordConfirm &&
      isEmptyObject(nextState.errors) &&
      !nextState.clicked
    )
  }

  setValidationErrors(field, error) {
    let errors = this.state.errors
    if (error.length == 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      document.getElementById(field).className = ''
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      document.getElementById(field).className = 'error'
      return false
    }
  }

  submit = () => {
    return (event) => {
      this.setState({ clicked: true })
      let { changePassword } = this.props
      changePassword(this.state)
    }
  }

  toggleShow(field) {
    return () => {
      field = 'show' + field
      let value = this.state[field]
      this.setState({ [field]: !value })
    }
  }

  update(field) {
    return (event) =>
      this.setState({ [field]: event.target.value }, this.validate(field)) // We validate the updated field after every succeccfull state change of the corresponding field
  }

  updatePassword(field) {
    return (event) => {
      this.setState({ [field]: event.target.value })
    }
  }

  validate(field) {
    return () => {
      let value = this.state[field]
      switch (field) {
        case NEW_PASSWORD_CONFIRM_FIELD:
          if (this.state.newPassword === this.state.newPasswordConfirm) {
            return this.setValidationErrors(field, [])
          }
          return this.setValidationErrors(field, PASSWORDS_DONT_MATCH_ERROR)
        case NEW_PASSWORD_FIELD:
          if (this.state.newPassword !== this.state.newPasswordConfirm) {
            this.setValidationErrors(
              NEW_PASSWORD_CONFIRM_FIELD,
              PASSWORDS_DONT_MATCH_ERROR,
            )
          }
          return this.setValidationErrors(
            field,
            validations.validateNewPassword(value),
          )
        default:
          return this.setValidationErrors(
            field,
            validations.validatePassword(value),
          )
      }
    }
  }

  render() {
    let {
      currentPassword,
      newPassword,
      errors,
      newPasswordConfirm,
      disabled,
      clicked,
    } = this.state
    let { error, success } = this.props
    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <h3 className="mb25">Change Password</h3>

          <div className="input-box">
            <input
              type={this.state.showold ? 'text' : 'password'}
              required
              id="currentPassword"
              value={currentPassword}
              onChange={this.update('currentPassword')}
              onBlur={this.validate('currentPassword')}
            />
            <label>Current Password</label>
            <span className="show-password" onClick={this.toggleShow('old')}>
              Show
            </span>
          </div>

          <div className="input-box">
            <input
              type={this.state.shownew ? 'text' : 'password'}
              required
              id="newPassword"
              value={newPassword}
              onChange={this.update(NEW_PASSWORD_FIELD)}
              onBlur={this.validate(NEW_PASSWORD_FIELD)}
            />
            <label>New Password</label>
            <span className="show-password" onClick={this.toggleShow('new')}>
              Show
            </span>
          </div>

          <div className="input-box">
            <input
              type={this.state.showcon ? 'text' : 'password'}
              required
              id="newPasswordConfirm"
              value={newPasswordConfirm}
              onChange={this.update(NEW_PASSWORD_CONFIRM_FIELD)}
              onBlur={this.validate(NEW_PASSWORD_CONFIRM_FIELD)}
            />
            <label>Confirm Password</label>
            <span className="show-password" onClick={this.toggleShow('con')}>
              Show
            </span>
          </div>

          <ul className="snug-infos">
            <li>Snug uses bank-level 256-bit security to protect your data.</li>
          </ul>

          <div
            className={
              !isEmptyObject(errors) || error ? 'alert alert-danger' : ''
            }
          >
            {error} {errors.currentPassword} {errors.newPassword}{' '}
            {errors.newPasswordConfirm}
          </div>

          <div className={success ? 'alert alert-success' : 'hidden'}>
            {success}
          </div>

          <button className="mt15" disabled={disabled} onClick={this.submit()}>
            Save
            <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
          </button>
        </div>
      </div>
    )
  }
}

export default ChangePasswordForm
