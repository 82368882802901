import { connect } from 'react-redux'

import {
  fetchFeedbackQuestions,
  fetchFeedbackStatus,
  fetchPropertyAndFeedbackRequestFromShortCode,
  sendFeedback,
} from 'app/sm/inspections/inspections_actions'
import PostViewingFeedback from 'app/sm/inspections/post_viewing_feedback/post_viewing_feedback_container'
import { fetchProperty } from 'app/sm/property_details/property_details_actions'

const mapStateToProps = ({ ViewingsReducer, propertyDetails, session }) => ({
  feedbackQuestions: ViewingsReducer.feedbackQuestions,
  error: ViewingsReducer.error,
  feedbackStatus: ViewingsReducer.feedbackStatus,
  property: propertyDetails.property,
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  fetchFeedbackQuestions: () => dispatch(fetchFeedbackQuestions()),
  sendFeedback: (data, viewingGUID, feedbackGUID) =>
    dispatch(sendFeedback(data, viewingGUID, feedbackGUID)),
  fetchProperty: (id) => dispatch(fetchProperty(id)),
  fetchFeedbackStatus: (viewingGUID, feedbackGUID) =>
    dispatch(fetchFeedbackStatus(viewingGUID, feedbackGUID)),
  fetchPropertyAndFeedbackRequestFromShortCode: (shortCode) =>
    dispatch(fetchPropertyAndFeedbackRequestFromShortCode(shortCode)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PostViewingFeedback)
