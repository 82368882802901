import React, { useContext, useEffect } from 'react'

import styled from 'styled-components'

import {
  STEP_ENTER_DETAILS,
  STEP_ENTER_DETAILS_CONFIRMATION,
  STEP_ENTER_EMAIL,
  STEP_ENTER_MOBILE,
  STEP_ENTER_PASSWORD,
  STEP_UPDATE_REGISTRATION_INFO,
} from 'app/session/join/join-snug.constants'
import { JoinSnugContext } from 'app/session/join/JoinContext'
import SocialButtons from 'app/session/join/partials/social_buttons'
import RolePrompt from 'app/session/join/role_prompt/role_prompt_connection'
import CheckMobileOrEmailFormWrapper from 'app/session/join/steps/enter_check_account_key'
import EnterDetailsForm from 'app/session/join/steps/enter_details'
import EnterPasswordForm from 'app/session/join/steps/enter_password'
import { history } from 'app/shared_components/router'

const Divider = () => {
  return (
    <div className="text-right pb30">
      <div className="form-seperator display-flex justify-content-center align-items-center">
        <div className="text-area">or</div>
      </div>
    </div>
  )
}

const StyledDivider = styled(Divider)`
  .form-seperator {
    background-color: #999999 !important;
  }
`

const stepComponentMap = {
  [STEP_ENTER_MOBILE]: CheckMobileOrEmailFormWrapper,
  [STEP_ENTER_EMAIL]: CheckMobileOrEmailFormWrapper,
  [STEP_ENTER_PASSWORD]: EnterPasswordForm,
  [STEP_ENTER_DETAILS]: EnterDetailsForm,
  [STEP_ENTER_DETAILS_CONFIRMATION]: EnterDetailsForm,
  [STEP_UPDATE_REGISTRATION_INFO]: EnterDetailsForm,
}

const JoinForm = (props) => {
  useEffect(() => {
    const { rolePromptOn, currentAgency, applicationsCount = {} } = props

    // maintain the same behavior as same as old login
    const hideManagerOption =
      (applicationsCount.myPortofolioProperties || 0) === 0 &&
      (applicationsCount.managerBondCoverCount || 0) === 0

    if (rolePromptOn && hideManagerOption && !(currentAgency || {}).agencyID) {
      history.push('/home/overview')
    }
  }, [props.rolePromptOn])

  const { currentStep } = useContext(JoinSnugContext)

  const getFormTitle = () => {
    switch (currentStep) {
      case STEP_ENTER_MOBILE:
      case STEP_ENTER_EMAIL:
        return 'Welcome to Snug'
      case STEP_ENTER_PASSWORD:
        return 'Login'
      case STEP_ENTER_DETAILS:
      case STEP_ENTER_DETAILS_CONFIRMATION:
        return 'Your details'
      case STEP_UPDATE_REGISTRATION_INFO:
        return 'Update Registration Info'
    }
  }

  const renderCurrentStep = () => {
    const Step = stepComponentMap[currentStep]

    return <Step />
  }

  const renderSocialButtons = () => {
    if (![STEP_ENTER_MOBILE, STEP_ENTER_EMAIL].includes(currentStep))
      return null

    return (
      <>
        <StyledDivider />
        <SocialButtons currentStep={currentStep} />
      </>
    )
  }

  return (
    <>
      <h3 className="mb10">{getFormTitle()}</h3>
      <div className="panel panel-default">
        <div className="panel-body">
          {renderCurrentStep()}

          {renderSocialButtons()}
        </div>
      </div>

      {<RolePrompt />}
    </>
  )
}

export default JoinForm
