import React from 'react'

import moment from 'moment'

import preQualifyClosed from 'app/assets/icons/container-closed.svg'
import preQualifyOpen from 'app/assets/icons/container-open.svg'
import * as Form from 'app/components/forms/forms'
import { BasicModalWithFixedButtons } from 'app/modals/basic_modal'
import * as FormComponent from 'app/shared_components/form_component'
import * as helpers from 'app/sm/helpers'
import { PreQualifyInfoContainer } from 'app/sm/property_details/property_details_container'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import { NUMBER_TYPE_MOBILE } from 'app/utils/phonenumber/helpers'
import VaccinationRadioComponent from 'app/utils/vaccination/radio_component'

const yearsFrom1950 = helpers.yearArrayFromStartToCurrent(1950)
const yearsFrom1950WithGuidAndName = yearsFrom1950.map((year) => ({
  guid: year,
  name: year,
}))

const months = [
  { guid: 1, name: 'Jan' },
  { guid: 2, name: 'Feb' },
  { guid: 3, name: 'Mar' },
  { guid: 4, name: 'Apr' },
  { guid: 5, name: 'May' },
  { guid: 6, name: 'Jun' },
  { guid: 7, name: 'Jul' },
  { guid: 8, name: 'Aug' },
  { guid: 9, name: 'Sep' },
  { guid: 10, name: 'Oct' },
  { guid: 11, name: 'Nov' },
  { guid: 12, name: 'Dec' },
]

const Yes = 'true'
const No = 'false'

function RegisterModal(props) {
  const {
    modalFormValue,
    errors,
    includeLiving,
    viewingSelected,
    title,
    headingInfo,
    showCurrentRenterInfo = true,
    primaryDisabled,
    vaccinationQuestionsContainerOpened,
    toggleVaccinationQuestionsContainerOpened,
  } = props
  const {
    firstName = '',
    lastName = '',
    email = '',
    weeklyIncome = '',
    mobilePhone = '',
    currentResidenceStartYear = '',
    currentResidenceStartMonth = '',
    weeklyHousingPayment = '',
    hasRecentlyVisitedOverseasOrAffectedArea = '',
    hasColdSymptoms = '',
    whereDoYouLive = '',
    covid19VaccinationStatus = '',
  } = modalFormValue
  const firstNameError = errors.firstName
  const lastNameError = errors.lastName
  const emailError = errors.email
  const mobilePhoneError = errors.mobilePhone
  const weeklyIncomeError = errors.weeklyIncome
  const currentResidenceStartMonthError = errors.currentResidenceStartMonth
  const currentResidenceStartYearError = errors.currentResidenceStartYear
  const weeklyHousingPaymentError = errors.weeklyHousingPayment
  const hasColdSymptomsError = errors.hasColdSymptoms
  const covid19VaccinationStatusError = errors.covid19VaccinationStatus
  const hasRecentlyVisitedOverseasOrAffectedAreaError =
    errors.hasRecentlyVisitedOverseasOrAffectedArea
  const whereDoYouLiveError = errors.whereDoYouLive
  return (
    <div>
      <BasicModalWithFixedButtons
        toggleModal={() => props.toggleModal()}
        title={title}
        primaryButtonLabel="Check In"
        secondaryButtonLabel="Cancel"
        secondaryButtonAction={() => props.toggleModal()}
        primaryButtonAction={() => props.primaryButtonAction()}
        componentClassName="property-details-request-viewing-modal"
        primaryDisabled={primaryDisabled}
        primaryIsLoading={primaryDisabled}
        body={
          <div>
            {headingInfo}
            <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
              <div className="col-md-6 p0 width100">
                <Form.InputName
                  value={firstName}
                  error={firstNameError}
                  label="First name*"
                  onChange={props.onModalValueChange('firstName')}
                  labelClass="top18"
                  inputClass="width100"
                  componentClass="margin-profile-item-left"
                />
              </div>
              <div className="col-md-6 p0 width100">
                <Form.InputName
                  value={lastName}
                  error={lastNameError}
                  label="Last name*"
                  onChange={props.onModalValueChange('lastName')}
                  labelClass="top18"
                  inputClass="width100"
                  componentClass="margin-profile-item-right"
                />
              </div>
            </Form.ProfilePagesRowLayout>
            <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
              <div className="col-md-6 p0 width100">
                <Form.InputEmail
                  value={email}
                  error={emailError}
                  label="Email"
                  onChange={props.onModalValueChange('email')}
                  labelClass="top18"
                  inputClass="width100"
                  componentClass="margin-profile-item-left"
                  isOptional
                />
              </div>
              <div className="col-md-6 p0 width100">
                <Form.InputPhoneNumber
                  label="Mobile number"
                  value={mobilePhone}
                  error={mobilePhoneError}
                  onChange={props.onModalValueChange('mobilePhone')}
                  id="mobilePhone"
                  componentClass="margin-profile-item-right"
                  containerClassName="width100"
                  numberType={NUMBER_TYPE_MOBILE}
                />
              </div>
            </Form.ProfilePagesRowLayout>
            {showCurrentRenterInfo && (
              <div>
                <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                  <div className="col-md-4 pl0 pr10 width100">
                    <span className="font-size-16">
                      Weekly income (after tax)
                      <sup>#</sup>
                    </span>
                    <Form.InputNumber
                      value={weeklyIncome}
                      error={weeklyIncomeError}
                      label="$"
                      onChange={props.onModalValueChange('weeklyIncome')}
                      labelClass="top18"
                      inputClass="width100"
                      componentClass="margin-profile-item-left"
                      isOptional
                    />
                  </div>
                  <div className="col-md-8 pl0 pr10 width100">
                    <span className="font-size-16">
                      Start date at current address
                      <sup>#</sup>
                    </span>
                    <div className="col-md-6 p0 width70">
                      <Form.Dropdown
                        label="Year"
                        value={currentResidenceStartYear}
                        error={currentResidenceStartYearError}
                        options={yearsFrom1950WithGuidAndName}
                        onChange={props.onModalValueChange(
                          'currentResidenceStartYear',
                        )}
                        id="chosenYear"
                        inputClass="width100"
                        componentClass="margin-profile-item-left"
                        isRequired={true}
                        idAsValue={true}
                      />
                    </div>
                    <div className="col-md-6 p0 width70">
                      <Form.Dropdown
                        label="Month"
                        value={currentResidenceStartMonth}
                        error={currentResidenceStartMonthError}
                        options={months}
                        onChange={props.onDropDownValueChange(
                          'currentResidenceStartMonth',
                        )}
                        id="chosenMonth"
                        inputClass="width100"
                        componentClass="margin-profile-item-left"
                        isRequired={true}
                        idAsValue={true}
                      />
                    </div>
                  </div>
                </Form.ProfilePagesRowLayout>
                <Form.ProfilePagesRowLayout>
                  <div>
                    <span className="font-size-16">
                      Weekly rent or mortgage payments
                      <sup>#</sup>
                    </span>
                  </div>
                  <div className="col-md-5 pl0 pr0">
                    <Form.InputNumber
                      value={weeklyHousingPayment}
                      error={weeklyHousingPaymentError}
                      label="$"
                      onChange={props.onDropDownValueChange(
                        'weeklyHousingPayment',
                      )}
                      labelClass="top18"
                      inputClass="width100"
                      componentClass="margin-profile-item-left"
                      isOptional
                    />
                  </div>
                </Form.ProfilePagesRowLayout>
              </div>
            )}
            <PreQualifyInfoContainer className="mb20">
              <div onClick={() => toggleVaccinationQuestionsContainerOpened()}>
                <span>COVID disclosures (optional)</span>
                <img
                  className="cursor-pointer"
                  src={
                    vaccinationQuestionsContainerOpened
                      ? preQualifyOpen
                      : preQualifyClosed
                  }
                  alt="pre qualify status"
                />
              </div>
              {vaccinationQuestionsContainerOpened && (
                <>
                  <div className="mt10 width100">
                    <FormComponent.RadioGroup
                      label="Have you been overseas or in a COVID affected area in the last 14 days?*"
                      id="hasRecentlyVisitedOverseasOrAffectedArea"
                      field="hasRecentlyVisitedOverseasOrAffectedArea"
                      error={hasRecentlyVisitedOverseasOrAffectedAreaError}
                      componentClassName="property-details-radio-group"
                    >
                      <FormComponent.Checkbox
                        id="false"
                        label="No"
                        checked={
                          hasRecentlyVisitedOverseasOrAffectedArea === No
                        }
                        onChange={props.onChangeRadioGroup(
                          'hasRecentlyVisitedOverseasOrAffectedArea',
                        )}
                      />
                      <FormComponent.Checkbox
                        id="true"
                        label="Yes"
                        checked={
                          hasRecentlyVisitedOverseasOrAffectedArea === Yes
                        }
                        onChange={props.onChangeRadioGroup(
                          'hasRecentlyVisitedOverseasOrAffectedArea',
                        )}
                      />
                    </FormComponent.RadioGroup>
                  </div>

                  <div className="mt10">
                    <FormComponent.RadioGroup
                      label="Do you have any cold or flu symptoms?*"
                      id="hasColdSymptoms"
                      field="hasColdSymptoms"
                      error={hasColdSymptomsError}
                      componentClassName="property-details-radio-group"
                    >
                      <FormComponent.Checkbox
                        id="false"
                        label="No"
                        checked={hasColdSymptoms === No}
                        onChange={props.onChangeRadioGroup('hasColdSymptoms')}
                      />
                      <FormComponent.Checkbox
                        id="true"
                        label="Yes"
                        checked={hasColdSymptoms === Yes}
                        onChange={props.onChangeRadioGroup('hasColdSymptoms')}
                      />
                    </FormComponent.RadioGroup>
                  </div>
                  <VaccinationRadioComponent
                    onChangeRadioGroupCovid19VaccinationStatus={(event) =>
                      props.onChangeRadioGroupCovid19VaccinationStatus(
                        'covid19VaccinationStatus',
                      )(event)
                    }
                    covid19VaccinationStatus={covid19VaccinationStatus}
                    covid19VaccinationStatusError={
                      covid19VaccinationStatusError
                    }
                  />
                </>
              )}
            </PreQualifyInfoContainer>
            {includeLiving && (
              <div>
                <div className="mt10">
                  <b>{`Viewing ${viewingSelected.FriendlyName} at ${moment(
                    viewingSelected.startDate,
                  ).format(
                    dateTimeHelpers.TIME_AM_PM_HALF_DAY_HALF_MONTH,
                  )}`}</b>
                </div>
                <div className="mt10">
                  <b>Where do you live?*</b>
                  <Form.CommentArea
                    value={whereDoYouLive}
                    onChange={props.onModalValueChange('whereDoYouLive')}
                    error={whereDoYouLiveError}
                    required
                    showError
                  />
                </div>
              </div>
            )}
          </div>
        }
      />
    </div>
  )
}

export default RegisterModal
