import { useEffect } from 'react'

import { Formik, FormikProps, FormikValues } from 'formik'

import {
  Box,
  Flex,
  HorizontalLine,
  IconButton,
  LoadingSection,
} from 'app/components/design-system-components'
import { ClearRounded } from 'app/components/design-system-components/icons/content'
import { fontSizes } from 'app/components/design-system-components/styles/variables'
import {
  formFields,
  formValidation,
  IBallotMessageForm,
} from 'app/features/teams/applications/share-applications/BallotSendMessageModal/form.utils'
import { FormBody } from 'app/features/teams/applications/share-applications/BallotSendMessageModal/FormBody'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import { sendBulkBallotMessage } from 'app/services/http/team-applications'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'

export const BallotMessageModal = ({
  teamId,
  property,
  applications,
  onSendSuccessfully,
  onDismissClick,
}: {
  teamId: string
  property: any
  applications: any[]
  onSendSuccessfully: () => void
  onDismissClick: () => void
}) => {
  const {
    state: basicData,
    setState: setBasicData,
    loadingStates: basicDataLoadingStates,
  } = useStateWithLoading({
    owners: [],
    initialValues: null,
  })

  const submitSend = (values: FormikValues, { setStatus }: any) => {
    const payload = {
      messageBody: values[formFields.message],
      subject: values[formFields.subject],
      applicationIDs: applications.map(({ guidID }) => guidID),
    }
    return sendBulkBallotMessage(property.guidID, payload)
      .then(() => onSendSuccessfully())
      .catch(({ message }: { message: string }) =>
        setStatus({ apiGeneralError: message }),
      )
  }

  useEffect(() => {
    loadBasicData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property, teamId])

  const loadBasicData = () => {
    const { address = {} } = property || {}
    const { friendlyName = '' } = address
    return setBasicData({
      initialValues: {
        [formFields.property]: `${friendlyName}`,
        [formFields.message]: '',
        [formFields.subject]: `re: Your application for ${friendlyName}`,
      },
    })
  }

  return (
    <Box className="modal-fullscreen" bg={theme.colors.overlay}>
      <ModalContentWrapper>
        <Flex width="100%" flex="1 1" overflowY="auto">
          <Box p={theme.space[7] + 'px'} width="100%">
            <>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb={theme.space[6] + 'px'}
              >
                <h3>New Message</h3>
                <IconButton
                  IconCmp={ClearRounded}
                  iconProps={{ fontSize: fontSizes[10] + 'px' }}
                  variant="flat"
                  onClick={onDismissClick}
                />
              </Flex>
              <HorizontalLine />
            </>
            <Box pb={theme.space[5] + 'px'}>
              <LoadingSection
                loadingState={basicDataLoadingStates}
                actionHandler={() => loadBasicData()}
              >
                {!!basicData.initialValues && (
                  <Formik
                    initialValues={basicData.initialValues}
                    enableReinitialize
                    onSubmit={submitSend}
                    validate={formValidation}
                    validateOnBlur={true}
                    validateOnChange={true}
                  >
                    {(props: FormikProps<IBallotMessageForm>) => (
                      <FormBody
                        formBag={props}
                        prospects={applications.length}
                      />
                    )}
                  </Formik>
                )}
              </LoadingSection>
            </Box>
          </Box>
        </Flex>
      </ModalContentWrapper>
    </Box>
  )
}
