import React from 'react'

import qs from 'qs'
import { connect } from 'react-redux'

import laingSimmons from 'app/assets/icons/laingsimmons-logo.png'
import ljHooker from 'app/assets/icons/ljhooker.png'
import mint360 from 'app/assets/icons/mint360.png'
import prdLogo from 'app/assets/icons/prd-logo.png'
import raineHorne from 'app/assets/icons/rainehorne.png'
import rayWhite from 'app/assets/icons/raywhite-logo.png'
import { Box } from 'app/components/design-system-components'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
//  Do not remove!!!!! this is previous AA application layout
// import FullRenterApplication from './apply_anywhere_full_application_detail'
import ApplicationReportPDF from 'app/match/applicationReportPDF/connection'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import { ApplyAnywhereFooter } from 'app/shared_components/footer/footer'
import * as Form from 'app/shared_components/form_component'
import { ErrorMessage, isLoggedIn } from 'app/shared_components/helpers'
import Navbar from 'app/shared_components/navbar/navbar_connection'
import { history } from 'app/shared_components/router'
import Spinner from 'app/sm/common/spinner'
import * as helpers from 'app/sm/helpers'
import ApplyAnywhereObstructedApplication from 'app/sm/renter_applications/apply_anywhere_obstructed_application'
import {
  fetchApplyAnywhereManagerSummaryWithSecretWithoutDispatch,
  fetchVerifyApplicationAccess,
  requestApplyAnywhereApplicationAccess,
} from 'app/sm/renter_applications/renter_applications_actions'
import { SessionStorageUtils } from 'app/storage_utils'
import * as textHelpers from 'app/utils/text/helpers'

import 'app/sm/renter_applications/scss/apply_anywhere_application.scss'

const STATUS_GUEST = 'guest'
const STATUS_BUSY = 'busy'
const STATUS_VERIFIED = 'verified'
const STATUS_FAILED = 'failed'

const getApplyAnywhereListingEmailAddress = (application) => {
  const { application: renterApplication } = application
  const { applyAnywhereListing } = renterApplication || {}
  const { emailAddress } = applyAnywhereListing || {}
  return emailAddress
}

const getApplicantFirstName = (application) => {
  const { application: renterApplication } = application
  const { applicants } = renterApplication || {}
  const applicant = applicants || {}
  const { firstName } = applicant[0] || {}
  return firstName
}

const getApplyAnywhereListingAddress = (application) => {
  const { application: renterApplication } = application
  const { applyAnywhereListing } = renterApplication || {}
  const { address } = applyAnywhereListing || {}
  return address
}

const Screen = ({ children }) => (
  <div>
    <Navbar />
    <div className="app-content">
      <div className="app-content-container">
        {children}
        <ApplyAnywhereFooter ip="" />
      </div>
    </div>
  </div>
)

const Label = ({ text }) => (
  <div className="btn green-bg-light green-color disabled-link lh-28 pt10 pb10 pl30 pr30 height-auto white-space-unset">
    {text}
  </div>
)

const PartnerSection = () => (
  <div className="partner-section-container">
    <h3 className="partner-section-header">
      Join {textHelpers.MarketingPMCountPlus} property managers using Snug
    </h3>
    <div className="partner-section-logos-container">
      <div>
        <img
          src={rayWhite}
          className="partner-section-logo-pic"
          alt="ray-white-logo"
        />
      </div>
      <div>
        <img
          src={raineHorne}
          className="partner-section-logo-pic"
          alt="raine and horne logo"
        />
      </div>
      <div>
        <img
          src={ljHooker}
          className="partner-section-logo-pic"
          alt="lj hooker logo"
        />
      </div>
    </div>
    <div className="partner-section-logos-container">
      <div>
        <img
          src={laingSimmons}
          className="partner-section-logo-pic"
          alt="laing simmons logo"
        />
      </div>
      <div>
        <img
          src={prdLogo}
          className="partner-section-logo-pic"
          alt="prd logo"
        />
      </div>
      <div>
        <img
          src={mint360}
          className="partner-section-logo-pic"
          alt="mint360 logo"
        />
      </div>
    </div>
    <Box px={theme.baseSpace * 3 + 'px'} fontSize={4}>
      {textHelpers.MarketingPMCountCaveatText}
    </Box>
  </div>
)

const AccessRequestModal = ({
  toggleModal,
  form,
  errors,
  onChange,
  onClickSend,
  isSendButtonLoading,
  sendButtonDisabled,
}) => {
  return (
    <div className="modal-fullscreen modal-layout-flex-container">
      <ModalContentWrapper>
        <div className="modal-close">
          <i className="fa fa-times" onClick={toggleModal} />
        </div>
        <div className="modal-header">
          <h4 className="standard-subheading">Step 1: Verify your email</h4>
        </div>
        <div className="modal-body">
          <Form.InputName
            placeholder="First Name"
            label="First Name"
            value={form.firstName}
            onChange={({ value, error }) => onChange('firstName', value, error)}
            error={errors.firstName}
          />
          <Form.InputName
            placeholder="Last Name"
            label="Last Name"
            value={form.lastName}
            onChange={({ value, error }) => onChange('lastName', value, error)}
            error={errors.lastName}
          />
          <Form.PhoneInput
            label="Phone Number"
            value={form.phone}
            onChange={({ value, error }) => onChange('phone', value, error)}
            error={errors.phone}
          />
          <Form.InputEmail
            placeholder="Email"
            label="Email"
            value={form.email}
            onChange={({ value, error }) => onChange('email', value, error)}
            error={errors.email}
          />
          <div className="mb20">
            <Form.Button
              label="Request Magic Link"
              onClick={onClickSend}
              loading={isSendButtonLoading}
              disabled={sendButtonDisabled}
            />
          </div>
        </div>
      </ModalContentWrapper>
    </div>
  )
}

const AccessRequestModalWithoutForm = ({
  onClickSend,
  requestButtonDisabled,
  isSendButtonLoading,
  applyAnywhereListingEmailAddress,
  applyAnywhereListingFirstName,
  requestButtonText,
}) => {
  return (
    <div className="modal-fullscreen modal-layout-flex-container bg-unset z-index-0">
      <ModalContentWrapper isFullScreenMobile={false} boxShadow>
        <div className="text-center p30">
          <h4>Are you authorised to view the application?</h4>
          <p>
            This application was sent by {applyAnywhereListingFirstName} to{' '}
            {applyAnywhereListingEmailAddress}
          </p>
          {requestButtonDisabled ? (
            <Label text={requestButtonText} />
          ) : (
            <Form.Button
              label={requestButtonText}
              onClick={onClickSend}
              loading={isSendButtonLoading}
              disabled={requestButtonDisabled || isSendButtonLoading}
            />
          )}
        </div>
      </ModalContentWrapper>
    </div>
  )
}

class ApplyAnywhereApplication extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: '',
      requestButtonText: 'Step 1: Verify your email',
      requestButtonDisabled: false,
      application: {},
      name: '',
      phone: '',
      email: '',
      accessRequestForm: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      },
      accessRequestFormError: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      },
      isSendButtonLoading: false,
      modalOpened: false,
    }
  }

  componentDidMount() {
    const { currentUser } = this.props
    let userGUID = null
    if (currentUser) {
      const { guidID = null } = currentUser
      userGUID = guidID
    }
    const appContentElement = document.querySelector('.app-content')
    appContentElement.style.left = 0
    const appContentContainerElement = document.querySelector(
      '.app-content-container',
    )
    appContentContainerElement.style.margin = '0 auto'
    const { applicationId } = this.props.match.params
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    this.setState({ status: STATUS_BUSY })
    this.props
      .fetchVerifyApplicationAccess(applicationId, queries.token)
      .then(() => {
        this.setState({ status: STATUS_VERIFIED })
      })
      .catch(() => {
        this.props
          .fetchApplyAnywhereManagerSummaryWithSecretWithoutDispatch(
            applicationId,
          )
          .then((application) => {
            this.setState({ status: STATUS_GUEST, application })
          })
          .catch((err) => {
            const apiError = translateErrorCodeToMessage(err)
            this.setState({ status: STATUS_FAILED, apiError })
          })
      })
  }

  onChangeForm = (field, value, error) => {
    this.setState({
      accessRequestForm: {
        ...this.state.accessRequestForm,
        [field]: value,
      },
      accessRequestFormError: {
        ...this.state.accessRequestFormError,
        [field]: error,
      },
    })
  }

  onClickLoginRegister = () => {
    const url = `${window.location.pathname}${window.location.search}`
    SessionStorageUtils.setItem('forwardUrl', url)
    history.push(helpers.urlTo('register'))
  }

  onClickSend = () => {
    const { applicationId } = this.props.match.params
    const { currentUser } = this.props
    const query = {}
    if (isLoggedIn(currentUser)) {
      query.name = `${currentUser.firstName} ${currentUser.lastName}`
      query.phone = currentUser.mobile
      query.email = currentUser.email
    } else {
      const { firstName, lastName, phone, email } = this.state.accessRequestForm
      query.name = `${firstName} ${lastName}`
      query.phone = phone
      query.email = email
    }
    const applyAnywhereListingEmailAddress =
      getApplyAnywhereListingEmailAddress(this.state.application)
    this.setState({ isSendButtonLoading: true })
    this.props
      .requestApplyAnywhereApplicationAccess(applicationId, query)
      .then(() => {
        this.setState({
          requestButtonText: `Please check ${applyAnywhereListingEmailAddress} for the link to the full application`,
          requestButtonDisabled: true,
          isSendButtonLoading: false,
          modalOpened: false,
        })
      })
      .catch(() => {
        this.setState({ isSendButtonLoading: false })
      })
  }

  toggleModal = () => {
    this.setState({ modalOpened: !this.state.modalOpened })
  }

  render() {
    const formHasError =
      Object.keys(this.state.accessRequestFormError).filter(
        (key) => this.state.accessRequestFormError[key],
      ).length !== 0
    const formHasEmptyField =
      Object.keys(this.state.accessRequestForm).filter(
        (key) => !this.state.accessRequestForm[key],
      ).length !== 0
    const sendButtonDisabled = formHasError || formHasEmptyField

    const isUserLoggedIn = isLoggedIn(this.props.currentUser)
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })

    const applyAnywhereListingEmailAddress =
      getApplyAnywhereListingEmailAddress(this.state.application)

    const applyAnywhereListingFirstName = getApplicantFirstName(
      this.state.application,
    )

    const applyAnywhereListingAddress = getApplyAnywhereListingAddress(
      this.state.application,
    )
    return (
      <Screen>
        {this.state.status === STATUS_VERIFIED && (
          <ApplicationReportPDF
            match={this.props.match}
            token={queries.token}
          />
        )}
        {/* Do not remove!!!!! this is previous AA application layout */}
        {/* {this.state.status === STATUS_VERIFIED && (
          <FullRenterApplication
            match={this.props.match}
            location={this.props.location}
          />
        )} */}
        {/* {this.state.status === STATUS_VERIFIED && (
          <CenterContentContainer
            componentClass="col-xs-12"
            containerClass="mt50"
          >
            <PartnerSection />
          </CenterContentContainer>
        )} */}

        <div className="two-col-box-flex-row sm64 justify-content-center">
          <div className="col-first">
            {this.state.status === STATUS_GUEST && (
              <div>
                <ApplyAnywhereObstructedApplication
                  address={applyAnywhereListingAddress}
                  firstName={applyAnywhereListingFirstName}
                />
                <AccessRequestModalWithoutForm
                  onClickSend={
                    isUserLoggedIn ? this.onClickSend : this.toggleModal
                  }
                  requestButtonDisabled={this.state.requestButtonDisabled}
                  isSendButtonLoading={this.state.isSendButtonLoading}
                  applyAnywhereListingEmailAddress={
                    applyAnywhereListingEmailAddress
                  }
                  applyAnywhereListingFirstName={applyAnywhereListingFirstName}
                  requestButtonText={this.state.requestButtonText}
                />
              </div>
            )}

            {this.state.status === STATUS_BUSY && <Spinner />}

            {this.state.status !== STATUS_VERIFIED && <PartnerSection />}

            {this.state.status === STATUS_FAILED && (
              <ErrorMessage error={this.state.apiError} />
            )}

            {!isUserLoggedIn && this.state.modalOpened && (
              <AccessRequestModal
                toggleModal={this.toggleModal}
                form={this.state.accessRequestForm}
                onChange={this.onChangeForm}
                errors={this.state.accessRequestFormError}
                onClickSend={this.onClickSend}
                isSendButtonLoading={this.state.isSendButtonLoading}
                sendButtonDisabled={sendButtonDisabled}
                onClickLoginRegister={this.onClickLoginRegister}
              />
            )}
          </div>
        </div>
      </Screen>
    )
  }
}

const mapStateToProps = ({ session }) => ({
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  fetchApplyAnywhereManagerSummaryWithSecretWithoutDispatch: (
    applicationId,
    token,
    applicantId,
  ) =>
    dispatch(
      fetchApplyAnywhereManagerSummaryWithSecretWithoutDispatch(
        applicationId,
        token,
        applicantId,
      ),
    ),
  fetchVerifyApplicationAccess: (applicationId, token) =>
    dispatch(fetchVerifyApplicationAccess(applicationId, token)),
  requestApplyAnywhereApplicationAccess: (applicationId, query) =>
    dispatch(requestApplyAnywhereApplicationAccess(applicationId, query)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplyAnywhereApplication)
