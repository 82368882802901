import {
  CLEAR_ERROR,
  CREATE_AGENCY_SUCCESSFUL,
  HIDE_AGENCY_DASHBOARD_SPINNER,
  HIDE_LOAD_MORE_SPINNER,
  RECEIVE_AGENCY_INFO,
  RECEIVE_AGENCY_PROFILE,
  RECEIVE_CREATE_ERROR,
  RECEIVE_ERROR,
  RECEIVE_REGISTER_ERROR,
  REGISTER_AGENCY_SUCCESSFUL,
  SHOW_AGENCY_DASHBOARD_SPINNER,
  SHOW_LOAD_MORE_SPINNER,
} from 'app/bond_cover/agency/agency_actions'

const defaultState = {
  agency: {},
  agencyReponse: {},
  error: null,
  agencyDashboardSpinner: false,
  updateREASuccessful: false,
  createError: null,
  registerError: null,
  loadMoreSpinner: false,
}

const Agency = (state = defaultState, action) => {
  const {
    type,
    agency,
    agencyReponse,
    error,
    createError,
    registerError,
    reaAgencyId,
  } = action

  const newState = Object.assign({}, state)

  switch (type) {
    case REGISTER_AGENCY_SUCCESSFUL:
      return Object.assign({}, newState, { agencyReponse })
    case CLEAR_ERROR:
      return Object.assign({}, newState, {
        error: '',
        createError: '',
        registerError: '',
      })
    case RECEIVE_ERROR:
      return Object.assign({}, newState, { error })
    case RECEIVE_CREATE_ERROR:
      return Object.assign({}, newState, { createError })
    case RECEIVE_REGISTER_ERROR:
      return Object.assign({}, newState, { registerError })
    case CREATE_AGENCY_SUCCESSFUL:
      return Object.assign({}, newState, { agency })
    case SHOW_AGENCY_DASHBOARD_SPINNER:
      return Object.assign({}, newState, { agencyDashboardSpinner: true })
    case HIDE_AGENCY_DASHBOARD_SPINNER:
      return Object.assign({}, newState, { agencyDashboardSpinner: false })
    case SHOW_LOAD_MORE_SPINNER:
      return Object.assign({}, newState, { loadMoreSpinner: true })
    case HIDE_LOAD_MORE_SPINNER:
      return Object.assign({}, newState, { loadMoreSpinner: false })
    case RECEIVE_AGENCY_INFO:
      return Object.assign({}, newState, { agency })
    case RECEIVE_AGENCY_PROFILE:
      return Object.assign({}, newState, { agencyReponse })
    default:
      return state
  }
}

export default Agency
