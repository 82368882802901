import React from 'react'

import { Modal } from 'app/components/design-system-components'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'

function ApplicationClosedModal({
  teamSlug = '',
  teamName = '',
  toggleApplicationsCloseModal,
}) {
  return (
    <Modal
      modalHeading="Applications closed"
      modalBody={`This property is no longer taking applications. Please view the list of available properties for ${teamName} and re-use your profile to apply.`}
      primaryLabel="View available properties"
      secondaryLabel="Cancel"
      toggleModal={toggleApplicationsCloseModal}
      secondaryAction={toggleApplicationsCloseModal}
      primaryAction={() => {
        history.push(urlTo('teamPublicPage', { teamSlug }))
      }}
    />
  )
}

export default ApplicationClosedModal
