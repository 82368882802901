import React from 'react'

class MultipleSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpanded: false,
    }
  }

  onChange(id) {
    let { selected } = this.props

    if (!this.isSelected(id)) {
      const entry = this.props.entries.find((entry) => entry.id === id)
      selected = [...selected, entry]
      //TODO: remove this line to allow multiple choices when backend is ready
      selected = [{ id, label: `${id}m` }]
    } else {
      selected = selected.filter((entry) => entry.id !== id)
    }

    this.props.onChange(selected)
  }

  isSelected(id) {
    const { selected = [] } = this.props
    return !!selected.find((entry) => entry.id === id)
  }

  toggleCollapsing() {
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  render() {
    const { entries = [], selected = [], parole } = this.props
    const { isExpanded } = this.state

    return (
      <div className="snug-multiple-select">
        <label className="sms-parole">{parole}</label>
        <div
          className="sms-values-container noselect"
          onClick={() => this.toggleCollapsing()}
        >
          {selected.map((s) => s.label).join(' , ')}
        </div>
        {isExpanded && (
          <div className="sms-entries-container">
            <ul>
              {entries.map((entry) => (
                <li key={entry.id} onClick={() => this.onChange(entry.id)}>
                  <input
                    type="checkbox"
                    checked={this.isSelected(entry.id)}
                    onChange={() => this.onChange(entry.id)}
                  />
                  <label>{entry.label}</label>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }
}

export default MultipleSelect
