import React from 'react'

import { getNumberType } from 'libphonenumber-js'
import metadata from 'libphonenumber-js/metadata.full.json'
import qs from 'qs'

import { IntlTelInputWrapper } from 'app/components/design-system-components'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import { validReferrer } from 'app/constants/referrer.constants'
import AddressForm from 'app/shared_components/address_form/address_form'
import {
  ErrorMessage,
  intTelTop10List,
  isValidLandlineNumber,
  isValidMobileNumber,
} from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import * as validations from 'app/shared_components/validations'
import Spinner from 'app/sm/common/spinner'
import { urlTo, validateAddress } from 'app/sm/helpers'
import { SessionStorageUtils } from 'app/storage_utils'

const SAVED_APPLY_ANYWHERE_LISTING = 'savedApplyAnywhereListing'
const applicationRouteApplyAnywhere = 1
const applicationRouteStandard = 2
const applicationRouteDraft = 3
const applicationRouteFinished = 4

const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min //The maximum is exclusive and the minimum is inclusive
}

export const ErrorContainer = ({ errors }) => (
  <div className="alert alert-danger">{errors}</div>
)

const defaultAddress = {
  unitNumber: '',
  streetNumber: '',
  streetName: '',
  address2: '',
  suburb: '',
  state: '',
  postcode: '',
  country: 'Australia',
  lat: null,
  lng: null,
  friendlyName: '',
  googleId: '',
}

const addressEmpty = (address) => {
  return !(
    address.streetNumber &&
    address.streetName &&
    address.suburb &&
    address.state &&
    address.country &&
    address.postcode
  )
}

const btnDisabled = (address, firstName, email, errors) => {
  let val =
    addressEmpty(address) ||
    valuesEmpty(firstName, email) ||
    errorsExist(errors)
  return val
}

const valuesEmpty = (firstName, email) => {
  return !(firstName && email)
}

const errorsExist = (errors) => {
  let val =
    errors &&
    !(
      errors['email'] === undefined &&
      errors['firstName'] === undefined &&
      errors['phone'] === undefined
    )
  return val
}

const isSavedApplyAnywhereListingValid = (applyAnywhereListing) => {
  if (!applyAnywhereListing) return false
  const addressResult = validateAddress(applyAnywhereListing.address)
  if (!addressResult.ok) return false
  return true
}

class ApplyAnywhereListing extends React.Component {
  constructor(props) {
    super(props)

    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const { referrer_group, referrer_id } = curQueries
    const propertyHasAValidAddressAndManager =
      this.urlHasValidPropertyAndManagerInfo()

    this.state = {
      spinner:
        validReferrer(referrer_group, referrer_id) &&
        propertyHasAValidAddressAndManager,
      applyAnywhereListing: {
        address: Object.assign({}, defaultAddress),
        emailAddress: '',
        firstName: '',
        phone: '',
      },
      errors: [],
      propertyGUID: '',
      isPropertyGUID: false,
      apiError: '',
      segmentAATriggered: false,
    }
  }

  componentDidMount() {
    let applyAnywhereGUID =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.applyAnywhereGUID
    let { applyAnywhereListing } = this.state
    applyAnywhereListing.guid = applyAnywhereGUID
    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const propertyHasAValidAddressAndManager =
      this.urlHasValidPropertyAndManagerInfo()
    if (
      validReferrer(curQueries.referrer_group, curQueries.referrer_id) &&
      propertyHasAValidAddressAndManager
    ) {
      applyAnywhereListing =
        this.prepareApplyAnywhereListingFromURLParams(curQueries)
      this.setState({ applyAnywhereListing, spinner: false })

      this.props.addApplyAnywhere(
        applyAnywhereListing,
        this.addApplyAnywhereSuccess,
        this.addApplyAnywhereFailure,
      )
    }

    if (!curQueries.id) {
      SessionStorageUtils.removeItem(SAVED_APPLY_ANYWHERE_LISTING)
      curQueries.id = getRandomInt(1, 10000)
      const newQueryStr = qs.stringify(curQueries)
      history.push(`${this.props.location.pathname}?${newQueryStr}`)
    }
    const { address: queryStringAddress = '' } = curQueries

    this.setState({
      applyAnywhereListing,
      queryStringAddress,
    })

    const { email = '', firstname = '' } = curQueries
    email && this.updateApplyInfoFromUrl(email, 'email')
    firstname && this.updateApplyInfoFromUrl(firstname, 'firstName')

    if (applyAnywhereGUID) {
      this.setState({ spinner: true })

      this.props.fetchApplyAnywhereListing(
        applyAnywhereGUID,
        (applyAnywhereListing) => {
          this.setState({ applyAnywhereListing, inEdit: true, spinner: false })
        },
        (err) => {
          this.setState({ spinner: false })
          this.setState({
            apiError: err,
          })
        },
      )
    } else {
      try {
        const value = SessionStorageUtils.getItem(SAVED_APPLY_ANYWHERE_LISTING)
        const savedApplyAnywhereListing = JSON.parse(value)
        if (
          savedApplyAnywhereListing &&
          isSavedApplyAnywhereListingValid(
            savedApplyAnywhereListing.applyAnywhereListing,
          )
        ) {
          this.setState({
            applyAnywhereListing:
              savedApplyAnywhereListing.applyAnywhereListing,
            inEdit: false,
          })
        }
      } catch (err) {
        console.log('caught err', err)
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const nextQueries = qs.parse(nextProps.location.search, {
      ignoreQueryPrefix: true,
    })

    if (curQueries.id && curQueries.id !== nextQueries.id) {
      SessionStorageUtils.removeItem(SAVED_APPLY_ANYWHERE_LISTING)
      this.setState({
        applyAnywhereListing: {
          address: Object.assign({}, defaultAddress),
          firstName: '',
          emailAddress: '',
          phone: '',
        },
      })
      const id = getRandomInt(1, 10000)
      nextQueries.id = id
      const newQueryStr = qs.stringify(nextQueries)
      history.push(`${this.props.location.pathname}?${newQueryStr}`)
    }
  }

  setValidationErrors = (field, error) => {
    let errors = this.state.errors

    if (error.length === 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors })
      return false
    }
  }

  urlHasValidPropertyAndManagerInfo = () => {
    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const propertyHasAValidAddressAndManager =
      (curQueries?.street_number ||
        curQueries?.street_name ||
        curQueries?.suburb) &&
      curQueries?.contact_name
    return propertyHasAValidAddressAndManager
  }

  addApplyAnywhere = () => {
    const { applyAnywhereListing } = this.state
    this.setState({ showSpinner: true })
    if (
      applyAnywhereListing &&
      applyAnywhereListing.guid &&
      applyAnywhereListing.guid !== ''
    ) {
      this.props.updateApplyAnywhere(
        applyAnywhereListing,
        this.addApplyAnywhereSuccess,
        this.addApplyAnywhereFailure,
      )
    } else {
      this.props.addApplyAnywhere(
        applyAnywhereListing,
        this.addApplyAnywhereSuccess,
        this.addApplyAnywhereFailure,
      )
    }
  }

  addApplyAnywhereFailure = (responseText) => {
    const { applyAnywhereListing } = this.state
    applyAnywhereListing.guid = ''

    this.setState({ applyAnywhereListing }, () => {
      this.setState({
        apiError: ErrorMessage[parseInt(responseText)],
        showSpinner: false,
      })
    })
  }

  addApplyAnywhereSuccess = (guidID, routeType = 1) => {
    if (routeType === applicationRouteStandard) {
      history.push(`${urlTo('apply', { propertyId: guidID })}`)
    } else if (routeType === applicationRouteDraft) {
      history.push(`${urlTo('applicationSummary', { applicationId: guidID })}`)
    } else if (routeType === applicationRouteFinished) {
      history.push(
        `${urlTo('applyConfirmationSummary', { applicationId: guidID })}`,
      )
    } else if (routeType === applicationRouteApplyAnywhere) {
      let { applyAnywhereListing } = this.state
      const curQueries = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      })

      applyAnywhereListing.guid = guidID
      this.setState({ applyAnywhereListing })

      const {
        listing_image,
        referrer_url,
        applicationId,
        referrer_group = '',
        referrer_id = '',
      } = curQueries
      const thirdPartyQueryString = `&image_url=${listing_image}&referrer_url=${referrer_url}&referrer_group=${referrer_group}&referrer_id=${referrer_id}`
      applicationId
        ? history.push(urlTo('application', { applicationId }))
        : history.push(
            `${urlTo('apply', {
              propertyId: guidID,
              applyAnywhere: true,
            })}${referrer_url ? thirdPartyQueryString : ''}`,
          )

      const savedApplyAnywhereListing = {
        id: curQueries.id,
        applyAnywhereListing,
      }
      SessionStorageUtils.setItem(
        SAVED_APPLY_ANYWHERE_LISTING,
        JSON.stringify(savedApplyAnywhereListing),
      )
    }
  }

  fromReferrer = (curQueries, referrerGroup, referrerID) => {
    const { referrer_group, referrer_id } = curQueries
    if (referrer_group === referrerGroup && referrer_id === referrerID) {
      return true
    }
  }

  prepareApplyAnywhereListingFromURLParams = (curQueries) => {
    const {
      street_number,
      street_name,
      suburb,
      state,
      country = 'Australia',
      lat = null,
      lng = null,
      contact_name,
      contact_email,
      postcode,
      referrer_id,
      referrer_group,
      agency_id,
      listing_id,
      listing_image,
      referrer_url,
      office_name,
      office_phone,
      office_email,
    } = curQueries

    const managerNameParts = contact_name && contact_name[0].split(' ')
    let firstName = ''
    let lastName = ''
    if (managerNameParts) {
      firstName = managerNameParts.shift()
      lastName = managerNameParts.join(' ')
    }

    return {
      address: {
        country,
        friendlyName: `${street_number} ${street_name}, ${suburb}`,
        lat,
        lng,
        googleId: null,
        postcode,
        state,
        streetName: street_name,
        streetNumber: street_number,
        suburb,
      },
      emailAddress: contact_email[0],
      firstName: firstName,
      lastName: lastName,
      referrer_id,
      referrer_group,
      externalAgencyID: agency_id,
      externalListingID: listing_id,
      referrer_img_url: listing_image,
      referrer_back_url: referrer_url,
      agencyName: office_name,
      officeNumber: office_phone,
      officeEmail: office_email,
    }
  }

  resetAddress = () => {
    return () => {
      this.setState({
        address: Object.assign({}, defaultAddress),
      })
    }
  }

  updateAddress = (field) => {
    return (event) => {
      const { applyAnywhereListing } = this.state
      applyAnywhereListing.address[field] = event.target.value
      this.setState({
        applyAnywhereListing,
        unsavedChanges: true,
      })
    }
  }

  updateApplyAnywhereListing = (event, field) => {
    const { value } = event.target
    this.updateApplyDetails(value, field)
  }

  updateApplyDetails = (value, field) => {
    const { applyAnywhereListing } = this.state

    switch (field) {
      case 'phone':
        applyAnywhereListing.phone = value
        break
      case 'officeNumber':
        applyAnywhereListing.officeNumber = value
        break
      case 'email':
        applyAnywhereListing.emailAddress = value
        break
      case 'firstName':
        applyAnywhereListing.firstName = value
        break
      case 'lastName':
        applyAnywhereListing.lastName = value
        break
      case 'agencyName':
        applyAnywhereListing.agencyName = value
        break
    }

    this.setState({
      applyAnywhereListing,
      unsavedChanges: true,
    })
  }

  updateApplyInfoFromUrl(value, field) {
    this.updateApplyDetails(value, field)
    this.validate(field)
  }

  updateGoogleAddress = (address) => {
    let { applyAnywhereListing } = this.state
    applyAnywhereListing.address = address
    this.setState(
      {
        applyAnywhereListing,
        unsavedChanges: true,
      },
      () => this.validate('address', true),
    )
  }

  validate = (field) => {
    const { applyAnywhereListing } = this.state
    switch (field) {
      case 'phone':
        return this.setValidationErrors(
          field,
          validations.validateMobile(
            applyAnywhereListing.phone,
            'Invalid phone',
          ),
        )
      case 'officeNumber':
        return this.setValidationErrors(
          field,
          validations.validatePhoneOrMobileNumber(
            applyAnywhereListing.phone,
            'Invalid office number',
          ),
        )
      case 'email':
        return this.setValidationErrors(
          field,
          validations.validateEmail(
            applyAnywhereListing.emailAddress,
            'Invalid email address',
          ),
        )
      case 'address':
        const addressValidation = validateAddress(applyAnywhereListing.address)
        return this.setValidationErrors(
          field,
          addressValidation && !addressValidation.ok
            ? [ErrorMessages[addressValidation.errorCode]]
            : [],
        )
      case 'firstName':
        return this.setValidationErrors(
          field,
          validations.validateFirstName(
            applyAnywhereListing.firstName,
            'Invalid firstName',
          ),
        )
      case 'lastName':
        return this.setValidationErrors(
          field,
          validations.validateLastName(
            applyAnywhereListing.lastName,
            'Invalid lastName',
          ),
        )
    }
  }

  validateInternationalNumber = (onBlur, field) => {
    return (status, value, countryData, number, id) => {
      this.updateApplyDetails(number, field)
      const actualNumberType = getNumberType(number, metadata)
      switch (field) {
        case 'phone':
          this.setValidationErrors(
            field,
            isValidMobileNumber(actualNumberType) || !value
              ? []
              : [
                  ErrorMessages[
                    Errors.ErrorWhenConvertingMobileToInternationalFormat
                  ],
                ],
            onBlur,
          )
          break
        case 'officeNumber':
          this.setValidationErrors(
            field,
            isValidLandlineNumber(actualNumberType) || !value
              ? []
              : [
                  ErrorMessages[
                    Errors.ErrorWhenConvertingOfficeNumberToInternationalFormat
                  ],
                ],
            onBlur,
          )
          break
      }
    }
  }

  render() {
    const { spinner, applyAnywhereListing, queryStringAddress, inEdit } =
      this.state
    return spinner ? (
      <Spinner />
    ) : (
      <div className="profile-screen">
        <div className="section-title">
          <div className="left-column">
            <h3>What address are you applying for?</h3>
          </div>
        </div>
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <ApplyAnywhereListingCard
              showSpinner={spinner}
              address={applyAnywhereListing.address}
              applyAnywhereListing={this.state.applyAnywhereListing}
              updateApplyAnywhereListing={this.updateApplyAnywhereListing}
              updateGoogleAddress={this.updateGoogleAddress}
              updateAddress={this.updateAddress}
              resetAddress={this.resetAddress}
              addApplyAnywhereSuccess={this.addApplyAnywhereSuccess}
              errors={this.state.errors}
              addApplyAnywhereFailure={this.addApplyAnywhereFailure}
              validate={this.validate}
              btnDisabled={
                applyAnywhereListing &&
                btnDisabled(
                  applyAnywhereListing.address,
                  applyAnywhereListing.firstName,
                  applyAnywhereListing.emailAddress,
                  this.state.errors,
                )
              }
              addressInputDisabled={inEdit}
              addApplyAnywhere={this.addApplyAnywhere}
              apiError={this.state.apiError}
              queryStringAddress={queryStringAddress}
              validateInternationalNumber={this.validateInternationalNumber}
            />
          </div>
        </div>
      </div>
    )
  }
}

const ApplyAnywhereListingCard = (params) => {
  const {
    showSpinner,
    applyAnywhereListing,
    errors,
    updateApplyAnywhereListing,
    updateAddress,
    resetAddress,
    validate,
    updateGoogleAddress,
    addApplyAnywhereSuccess,
    addApplyAnywhere,
    btnDisabled,
    addApplyAnywhereFailure,
    rentalBackUrl,
    address,
    queryStringAddress,
    validateInternationalNumber,
    addressInputDisabled,
  } = params
  const ApplyAnywhereFormContinueTagClass = 'apply-anywhere-form-save-continue'
  return (
    <div>
      <div className="col-sm-5 pb5 mobile-only">
        <button
          className="btn btn-transparent btn-left xs-text-center wa"
          onClick={() =>
            rentalBackUrl ? history.push(rentalBackUrl) : history.goBack()
          }
        >
          <i className="icon-arrow-left left" />
          <span>Back</span>
        </button>
      </div>
      <div className="input-box">
        <AddressForm
          address={address}
          updateAddress={updateAddress}
          updateGoogleAddress={updateGoogleAddress}
          resetAddress={resetAddress()}
          placeholder="Enter property address (inc unit and street number)"
          errors={errors.address}
          disabled={
            applyAnywhereListing && addressEmpty(applyAnywhereListing.address)
          }
          inputDisabled={addressInputDisabled}
          onBlur={() => validate('address', true)}
          country={['au']}
          displayShortAddress={true}
          queryStringAddress={queryStringAddress}
        />
      </div>
      <div className="contentBlock m0">
        <h5 className="mb20">
          <span>Who should we send your application to?</span>
        </h5>
        <content>
          <p>
            <ul className="snug-tips">
              <li>Please double check the details.</li>
            </ul>
          </p>
        </content>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="input-box">
            <input
              type="text"
              placeholder="Property Manager's First Name"
              onBlur={() => validate('firstName')}
              onChange={(event) =>
                updateApplyAnywhereListing(event, 'firstName')
              }
              value={applyAnywhereListing.firstName}
            />
            <label className="fixedLabelStyle">First Name</label>
            <ErrorMessage error={errors.firstName} />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="input-box">
            <input
              type="text"
              placeholder="Property Manager's Last Name"
              onBlur={() => validate('lastName')}
              onChange={(event) =>
                updateApplyAnywhereListing(event, 'lastName')
              }
              value={applyAnywhereListing.lastName}
            />
            <label className="fixedLabelStyle">Last Name</label>
            <ErrorMessage error={errors.lastName} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="input-box">
            <input
              type="text"
              placeholder="Property Manager's Email"
              onBlur={() => validate('email')}
              onChange={(event) => updateApplyAnywhereListing(event, 'email')}
              value={applyAnywhereListing.emailAddress}
            />
            <label className="fixedLabelStyle">Email Address</label>
          </div>
          <ErrorMessage error={errors.email} />
        </div>
        <div className="col-sm-6">
          <div className="input-box">
            <input
              type="text"
              placeholder="Agency Name (optional)"
              onBlur={() => validate('agencyName')}
              onChange={(event) =>
                updateApplyAnywhereListing(event, 'agencyName')
              }
              value={applyAnywhereListing.agencyName}
            />
            <label className="fixedLabelStyle">Agency Name</label>
          </div>
          <ErrorMessage error={errors.agencyName} />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="input-box">
            <IntlTelInputWrapper
              css={['intl-tel-input', '']}
              utilsScript={'libphonenumber.js'}
              defaultCountry={'au'}
              preferredCountries={intTelTop10List}
              onPhoneNumberChange={validateInternationalNumber(
                false,
                'officeNumber',
              )}
              onPhoneNumberBlur={validateInternationalNumber(
                true,
                'officeNumber',
              )}
              value={applyAnywhereListing.officeNumber}
              placeholder={'e.g. 02 1234 5678 (optional)'}
            />
            <p className="text-align-left">Office number</p>
          </div>
          <ErrorMessage error={errors.officeNumber} />
        </div>
        <div className="col-sm-6">
          <div className="input-box">
            <IntlTelInputWrapper
              css={['intl-tel-input', '']}
              utilsScript={'libphonenumber.js'}
              defaultCountry={'au'}
              preferredCountries={intTelTop10List}
              onPhoneNumberChange={validateInternationalNumber(false, 'phone')}
              onPhoneNumberBlur={validateInternationalNumber(true, 'phone')}
              value={applyAnywhereListing.phone}
              placeholder={'e.g. +61 412 345 678 (optional)'}
            />
            <p className="text-align-left">Phone Number</p>
          </div>
          <ErrorMessage error={errors.phone} />
        </div>
      </div>

      <div className="contentBlock m0 mb40">
        <content>
          <p>
            We'll let you know via email when your application is opened and
            viewed.
          </p>
        </content>
      </div>
      <div className="row pt25 mb80">
        <div className="col-sm-5 pb5 tablet-only">
          <button
            className="btn btn-transparent btn-left xs-text-center wa"
            onClick={() =>
              rentalBackUrl ? history.push(rentalBackUrl) : history.goBack()
            }
          >
            <i className="icon-arrow-left left" />
            <span>Back</span>
          </button>
        </div>
        <div className="col-sm-7">
          <button
            className={ApplyAnywhereFormContinueTagClass}
            disabled={btnDisabled || showSpinner}
            onClick={() => {
              addApplyAnywhere(
                applyAnywhereListing,
                addApplyAnywhereSuccess,
                addApplyAnywhereFailure,
              )
            }}
          >
            Save &amp; Continue{' '}
            <i className={showSpinner ? 'fa fa-spinner fa-pulse' : ''} />
          </button>
          <div className="gray-text text-center pt10 mobile-fontSize-13">
            Next: Preferences and Household
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplyAnywhereListing
