import React from 'react'

import * as textHelpers from 'app/utils/text/helpers'

import 'app/shared_components/work-flow-pink-spots-component/style.scss'

const WorkFlowPinkSpots = ({ statusCollection = [], containerClass }) => {
  return (
    <div
      className={`work-flow-pink-spots-container mt10 justify-content-center display-flex pdf-hide ${containerClass}`}
    >
      {statusCollection
        .filter((item) => item.stage !== 'Archived')
        .filter((item) => item.stage !== textHelpers.ONLIST_INCOMPLETE_TEXT)
        .map((item, index) => {
          let lineClassName = ''
          let textClassName = ''
          let spotClassName = ''
          if (item.status === 'done') {
            lineClassName = 'connect-line-pink'
            textClassName = 'grey-text'
            spotClassName = 'pink-spot pink-spot-filled'
          }
          if (item.status === 'in progress') {
            lineClassName = 'connect-line-pink'
            textClassName = 'pink-text'
            spotClassName = 'pink-spot pink-spot-hollow'
          }
          if (item.status === 'not start') {
            lineClassName = 'connect-line-grey'
            textClassName = 'grey-text'
            spotClassName = 'grey-spot'
          }
          return (
            <div
              key={item.stage}
              className="display-flex flex-direction-column align-items-center"
            >
              <div className="display-flex">
                {index !== 0 && (
                  <div className={`connect-line ${lineClassName}`}></div>
                )}
                <div className={`${spotClassName} position-relative`}>
                  {item.status === 'in progress' && (
                    <div
                      className={`${textClassName} font-size-12 position-above grey-text`}
                    >
                      {item.substage}
                    </div>
                  )}
                  <div
                    className={`${textClassName} font-size-14 position-below`}
                  >
                    {item.stage}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default WorkFlowPinkSpots
