import { connect } from 'react-redux'

import { fetchProperty } from 'app/sm/property_transactions/property_transactions_actions'
import PropertyTransactionsContainer from 'app/sm/property_transactions/property_transactions_container'

const mapStateToProps = ({ propertyTransactions }) => ({
  property: propertyTransactions.property,
})

const mapDispatchToProps = (dispatch) => ({
  fetchProperty: (id) => dispatch(fetchProperty(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyTransactionsContainer)
