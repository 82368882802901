export const managementTypes = {
  UNKNOWN: 0,
  NEW_MANAGEMENT: 1,
  RELET: 2,
}

export const managementTypeOptions = [
  { label: 'N/A', value: managementTypes.UNKNOWN },
  { label: 'New Management', value: managementTypes.NEW_MANAGEMENT },
  { label: 'Re-let', value: managementTypes.RELET },
]

export const allowedManagementTypes = managementTypeOptions.filter(
  (type) => type.value !== 0,
)

export const housingCategories = {
  MARKET: 0,
  NRAS: 1,
  SOCIAL: 2,
  AFFORDABLE: 3,
  ESSENTIAL_WORKER: 4,
}

export const housingCategoryOptions = [
  { label: 'NRAS', value: housingCategories.NRAS },
  { label: 'Social', value: housingCategories.SOCIAL },
  { label: 'Affordable', value: housingCategories.AFFORDABLE },
  { label: 'Essential Worker', value: housingCategories.ESSENTIAL_WORKER },
  { label: 'Market', value: housingCategories.MARKET },
]

export const housingCategoriesLabels = housingCategoryOptions.map(
  (category) => category.label,
)
