import React from 'react'

import { parseNumber } from 'libphonenumber-js'
import moment from 'moment'
import PropTypes from 'prop-types'
import qs from 'qs'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { Box, TextSpan } from 'app/components/design-system-components'
import { ButtonSelectGroup } from 'app/components/design-system-components/ButtonSelectGroup'
import { Heading } from 'app/components/design-system-components/typography'
import * as Layout from 'app/components/display/display'
import { GreySubheading } from 'app/components/display/text/standard_text/standard-headings/component'
import * as Form from 'app/components/forms/forms'
import ErrorMessages from 'app/constants/error_messages'
import { ledgerAttachmentsCmpDomId } from 'app/constants/rental-history.constants'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import theme from 'app/match/applicationReportPDF/assets/theme'
import JointTenants from 'app/profile/renter/rental_history/joint_tenants'
import RentalHistoryAddressForm from 'app/profile/renter/rental_history/rental_history_address_form'
import {
  deleteRentalLedger,
  deleteUnboundedLedger,
  uploadLedgerByRenter,
  uploadUnboundedLedgerByRenter,
} from 'app/services/http/rentalReferences'
import * as snugNotifier from 'app/services/snugNotifier'
import AvatarAndNameDropdown from 'app/shared_components/AvatarAndNameDropdown'
import DocumentList from 'app/shared_components/document_list/document_list'
import {
  composeAttachment,
  ErrorMessage,
  isEmptyObject,
  isMobile,
} from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import * as helpers from 'app/sm/helpers'
import {
  DISCLOSURE_AUTHORITY_TEMPLATE,
  DocumentTypes,
  isAddressHistoryOwned,
  isAddressHistoryWithFamilyOrFriends,
  isAgent,
  isOwner,
  isRented,
  isSubletted,
  requireOtherTenantsAndLedger,
  urlTo,
  validateAddress,
  validateMinimumContactDetailProvided,
} from 'app/sm/helpers'
import RentalReputationHeaderReminder from 'app/sm/rental_reputation_shared_component/header_reminder_connection'
import {
  countriesListAlpha3,
  countryListAlpha3,
} from 'app/utils/country/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  loadingStatesIds,
} from 'app/utils/loading-states'
import {
  NUMBER_TYPE_LANDLINE,
  NUMBER_TYPE_MOBILE,
} from 'app/utils/phonenumber/helpers'
import { removeSpaces } from 'app/utils/strings/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import 'app/profile/renter/rental_history/style.scss'

const Yes = 'Yes'
const No = 'No'

const otherTenantOptions = [
  { option: Yes, optionMeta: { id: Yes, value: true } },
  { option: No, optionMeta: { id: No, value: false } },
]

const ReasonsForLeaving = [
  'Select a reason',
  'Sold by owner',
  'Owner moving in',
  'Moved for work',
  'Property was too expensive',
  'Bigger place required',
  'Smaller place required',
  'Personal reasons',
  'Tenancy issues',
]

const propertyArrangementOptions = [
  { label: 'I rented with an agent', value: 8, show: true },
  { label: 'I rented with private owner', value: 9, show: true },
  { label: 'I sub-letted', value: 6, show: true },
  { label: 'I boarded', value: 2, show: true },
  { label: 'I lived with family/friends', value: 7, show: true },
  { label: 'I owned the property', value: 5, show: true },
  { label: helpers.TEMPORARY_BNB_ARRANGEMENT, value: 10, show: true },

  // deprecated statuses
  { label: 'I rented', value: 1, show: false },
  { label: 'Lived with Friends', value: 3, show: false },
  { label: 'Lived with Family', value: 4, show: false },
]

const RENTAL_HISTORY_TENANT = 'Tenant'
const RENTAL_HISTORY_OCCUPANT = 'Occupant'
const SOMETHING_WRONG_WITH_TENANTS =
  'Something wrong with tenants information. Please fix it and try again'

const referencePreferenceModalAttributes = [
  {
    field: 'shouldContactReference',
    title: 'Disclosure Consent',
    text: 'Are you sure? Without consent your application cannot be processed.',
  },
  {
    field: 'sendReferenceOnAdd',
    title: 'Are you sure?',
    text: 'Issuing a reference now reduces the time to process your application.',
  },
]

const defaultCountry = 'AUS'

const StyledDisclosureAddressHistoryText = styled.div`
  span {
    color: ${theme.colors.pdfGray};
  }
`

const BondInputs = ({
  bondErrors,
  bond,
  update,
  isBondPaid,
  updateIsBondPaid,
}) => (
  <div className="display-flex flex-direction-column">
    <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items bond-container">
      <div className="col-md-6 p0 width100">
        <Form.CheckBoxGeneral
          label="I paid a bond"
          checked={isBondPaid}
          onChange={() => updateIsBondPaid()}
          componentClassName="bond-checkbox"
        />
      </div>
      {isBondPaid && (
        <div className="col-md-6 p0 width100">
          <Form.InputNumber
            label="Bond"
            value={bond}
            error={bondErrors}
            onChange={update('bond')}
            inputClass="width100"
            componentClass="margin-profile-item-right"
            id="bond"
          />
        </div>
      )}
    </Form.ProfilePagesRowLayout>
  </div>
)

class RentalHistoryAdd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        address2: '',
        suburb: '',
        state: '',
        postcode: '',
        country: defaultCountry,
        lat: null,
        lng: null,
        friendlyName: '',
        googleId: '',
      },
      propertyArrangement: '',
      isRollingLease: false,
      rate: '',
      bond: '',
      startDate: '',
      endDate: '',
      managerType: 3,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      errors: {
        address: '',
        propertyArrangement: '',
        rate: '',
        bond: '',
        startDate: '',
        endDate: '',
        reasonForLeaving: '',
        otherReason: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        officeNumber: '',
        agencyName: '',
        personalMessage: '',
      },
      agencyName: '',
      countryCode: '',
      unsavedChanges: false,
      isCurrentAddress: false,
      reasonForLeaving: '',
      otherReason: '',
      clicked: false,
      apiError: '',
      officeNumber: '',
      officeCountryCode: '',
      isBondPaid: false,
      shouldContactReference: true,
      sendReferenceOnAdd: true,
      showDeselectConfirmModel: false,
      isJointTenancy: false,
      tenants: [],
      tenantsErrors: [{}],
      showValidationError: [{}],
      allManagersRentalReference: [],
      allManagersRentalReferenceDropdownOpen: false,
      manualManagerSelected: true,
      managerLookupSearchText: '',
      showManualManagerEntry: false,
      showSpinner: false,
      btnDisabled: false,
      rentalReferenceManagerFetchInProgress: false,
      reasonForLeavingOptions: [...ReasonsForLeaving],
      propertyArrangementOptions: [...propertyArrangementOptions],

      ledgerAttachmentLoadingStates: createInitialLoadingState(),
      isBallotApplication: false,
      requestReasonForMoving: true,
    }
    this.ledgerAttachmentLoadingStateUtils = createLoadingStateUtils(
      (state) => {
        this.setState({
          ledgerAttachmentLoadingStates: state,
        })
      },
    )
  }

  componentDidMount() {
    const {
      match: {
        params: { referenceId = null },
      },
      fetchRentalReference,
    } = this.props
    if (referenceId !== null) {
      fetchRentalReference(referenceId).then((rentalReference) => {
        if (rentalReference && rentalReference.managerRated) {
          history.replace(urlTo('rentalHistorySummary'))
        }
        let existingTenants = []
        let tenantsErrors = [{}]
        if (
          rentalReference &&
          rentalReference.tenants &&
          rentalReference.tenants.length > 0
        ) {
          existingTenants = rentalReference.tenants
            .filter(
              (tenant) =>
                !tenant.isPrimary &&
                tenant.relationship === RENTAL_HISTORY_TENANT,
            )
            .map((tenant) => ({ ...tenant, isJointTenant: true }))
          existingTenants = existingTenants.concat(
            rentalReference.tenants
              .filter(
                (tenant) => tenant.relationship === RENTAL_HISTORY_OCCUPANT,
              )
              .map((tenant) => ({
                ...tenant,
                isJointTenant: false,
              })),
          )
          tenantsErrors = rentalReference.tenants.map(() => ({}))
        }
        this.setState({
          tenants: existingTenants,
          tenantsErrors: tenantsErrors,
          ledgerAttachments: rentalReference?.ledger || [],
        })
        if (existingTenants.length > 0) {
          this.setState({
            isJointTenancy: true,
          })
        }
      })
    }

    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const {
      id: agencyGUID,
      ballot = false,
      requestReasonForMoving = 'true',
    } = curQueries || {}
    if (agencyGUID && agencyGUID !== '') {
      this.callFetchTeamSettingInfo(agencyGUID)
    }
    if (ballot && ballot === 'true') {
      this.setState({
        isBallotApplication: true,
        sendReferenceOnAdd: false,
      })
    }
    this.setState({
      requestReasonForMoving: requestReasonForMoving === 'true',
    })

    this.props.fetchPropertyAttributes()
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { rentalReference = {}, isEditMode } = newProps
    if (!!rentalReference && rentalReference.guidID && isEditMode) {
      const parsedNumber = rentalReference.phoneNumber
        ? parseNumber(rentalReference.phoneNumber)
        : ''
      const parsedManagerType =
        rentalReference.managerType > 0 ? rentalReference.managerType : 3

      this.setState(
        Object.assign({}, this.state, rentalReference, {
          countryCode: parsedNumber && parsedNumber.country,
          managerType: parsedManagerType,
        }),
      )
    }

    // backward compatibility for other option as Other option removed for new rental histories
    if (isEditMode && rentalReference.reasonForLeaving) {
      const { reasonForLeavingOptions } = this.state

      if (!reasonForLeavingOptions.includes(rentalReference.reasonForLeaving)) {
        const updatedReasonForLeavingOptions = [
          ...reasonForLeavingOptions,
          'Other',
        ]

        this.setState({
          reasonForLeavingOptions: updatedReasonForLeavingOptions,
          reasonForLeaving: 'Other',
          otherReason: rentalReference.reasonForLeaving,
        })
      }
    }

    if (isEditMode && rentalReference) {
      const { propertyArrangementOptions } = this.state

      const updatedPropertyArrangementOptions = propertyArrangementOptions.map(
        (option) => {
          return {
            ...option,
            show:
              // to show the value of deprecated option
              option.value === rentalReference.propertyArrangement ||
              option.show,
          }
        },
      )

      this.setState({
        propertyArrangementOptions: updatedPropertyArrangementOptions,
      })
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  onAddJointTenant = () => {
    const { tenants, tenantsErrors, showValidationError, isJointTenancy } =
      this.state
    if (isJointTenancy) {
      this.setState({
        unsavedChanges: true,
        tenants: tenants.concat([
          {
            firstName: '',
            lastName: '',
            mobileNumber: '',
            email: '',
            relationship: RENTAL_HISTORY_TENANT,
            isJointTenant: true,
          },
        ]),
        tenantsErrors: tenantsErrors.concat([{}]),
        showValidationError: showValidationError.concat([{}]),
      })
    }
  }

  onClickManagerDropdownItem = (data) => {
    this.setState({
      allManagersRentalReferenceDropdownOpen: false,
      managerLookupSearchText: `${data.firstName} ${data.lastName} (${data.agencyName})`,
      showManualManagerEntry: true,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      agencyName: data.agencyName,
      officeNumber: data.officeNumber || '',
      phoneNumber: data.phoneNumber || '',
    })
  }

  onJointTenancyChange = (selection) => {
    this.setState(
      {
        isJointTenancy: selection.optionMeta.value,
        unsavedChanges: true,
      },
      () => {
        const { isJointTenancy, tenants } = this.state
        if (isJointTenancy && tenants !== null) {
          this.onAddJointTenant()
        }
        if (isJointTenancy && tenants === null) {
          this.setState(
            {
              tenants: [],
            },
            () => {
              this.onAddJointTenant()
            },
          )
        }
        if (!isJointTenancy) {
          this.setState({
            tenants: [],
            tenantsErrors: [{}],
            showValidationError: [{}],
          })
        }
      },
    )
  }

  onRemoveButtonClick = (nth) => {
    let { tenants, tenantsErrors } = this.state
    const { referenceId = null } = this.props.match.params
    const { deleteTenantReference } = this.props
    const tenantId = tenants[nth].guid
    const newTenants = tenants.filter((value, index) => {
      return index !== nth
    })
    const newTenantsErrors = tenantsErrors.filter((value, index) => {
      return index !== nth
    })
    if (!newTenants.length) {
      this.setState({
        isJointTenancy: false,
      })
    }
    if (tenantId && referenceId) {
      if (tenants[nth].isPrimary) {
        this.setState({
          tenants: newTenants,
          tenantsErrors: newTenantsErrors,
        })
      } else {
        deleteTenantReference(referenceId, tenantId)
          .then(() => {
            this.setState({
              tenants: newTenants,
              tenantsErrors: newTenantsErrors,
            })
          })
          .catch((error) => snugNotifier.error(error))
      }
    } else {
      this.setState({ tenants: newTenants, tenantsErrors: newTenantsErrors })
    }
    this.setUnsavedChanges()
  }

  onTenantFieldChange = (field, value, nth, error, actionType) => {
    let { tenants } = this.state
    const isNotNameField = field !== 'firstName' && field !== 'lastName'

    if (field === 'email') {
      value = value.toLowerCase()
    }

    if (actionType === 'onBlur' && isNotNameField) {
      value = removeSpaces(value)
    } else if (actionType === 'onBlur') {
      value = value.trim()
    }
    tenants[nth][field] = value

    if (field === 'isJointTenant') {
      if (tenants[nth][field] === true) {
        tenants[nth].relationship = RENTAL_HISTORY_TENANT
      } else {
        tenants[nth].relationship = RENTAL_HISTORY_OCCUPANT
      }
    }

    this.setState({
      tenants,
    })
    this.setTenantValidationErrors(field, error, nth)
    this.setUnsavedChanges()
  }

  setTenantValidationErrors = (field, error, i) => {
    let tenantsErrors = this.state.tenantsErrors
    if (error.length === 0) {
      // No validation errors
      delete tenantsErrors[i][field]
      this.setState({ tenantsErrors })
      return true
    } else {
      // Validation errors
      tenantsErrors[i][field] = error
      this.setState({ tenantsErrors })
      return false
    }
  }

  setUnsavedChanges = () => {
    this.setState({ unsavedChanges: true })
  }

  setValidationErrors(field, error, onBlur) {
    const { errors } = this.state
    if (error.length === 0) {
      delete errors[field]
      this.setState({ errors })
    } else if (onBlur) {
      errors[field] = error
      this.setState({ errors })
    } else {
      errors[field] = error
      this.setState({ errors })
    }
  }

  addRentalHistory() {
    const { addRentalHistory } = this.props
    const { isJointTenancy, tenants } = this.state
    if (!this.validateCommonRequiredFields()) {
      this.setState({
        showSpinner: false,
        btnDisabled: false,
      })
      return
    }
    if (
      isJointTenancy &&
      tenants.length > 0 &&
      this.noTenantsErrors() === false
    ) {
      snugNotifier.error(SOMETHING_WRONG_WITH_TENANTS)
      this.setState({
        btnDisabled: false,
        showSpinner: false,
      })
      return
    }
    this.setState({ clicked: true })
    addRentalHistory(
      this.trimFields(),
      () => {
        this.setState({
          clicked: false,
          apiError: '',
          btnDisabled: false,
          showSpinner: false,
        })
      },
      (responseText) => {
        snugNotifier.error(responseText)
        this.setState({
          clicked: false,
          apiError: ErrorMessage[parseInt(responseText)],
          showSpinner: false,
          btnDisabled: false,
        })
      },
    )
  }

  closeAvatarAndNameDropdown = () => {
    this.setState({
      allManagersRentalReferenceDropdownOpen: false,
    })
  }

  confirmDeselect = (field) => {
    this.setState(
      {
        [field]: false,
        showDeselectConfirmModel: false,
        unsavedChanges: true,
      },
      () => {
        if (field === 'shouldContactReference') {
          this.setState({ sendReferenceOnAdd: false, unsavedChanges: true })
        }
      },
    )
  }

  editRentalHistory(referenceId) {
    const { editRentalHistory } = this.props
    const { isJointTenancy, tenants } = this.state
    if (!this.validateCommonRequiredFields()) {
      this.setState({
        showSpinner: false,
        btnDisabled: false,
      })
      return
    }
    if (
      isJointTenancy &&
      tenants.length > 0 &&
      this.noTenantsErrors() === false
    ) {
      snugNotifier.error(SOMETHING_WRONG_WITH_TENANTS)
      return
    }
    editRentalHistory(
      this.trimFields(),
      referenceId,
      () => {
        this.setState({
          clicked: false,
          apiError: '',
          showSpinner: false,
          btnDisabled: false,
        })
      },
      (responseText) => {
        snugNotifier.error(responseText)
        this.setState({
          clicked: false,
          apiError: responseText,
          showSpinner: false,
          btnDisabled: false,
        })
      },
    )
  }

  focusAndScrollToFirstError = (errors) => {
    const field = Object.keys(errors)
      .filter((fieldName) => this.state.errors[fieldName])
      .shift()
    if (field) {
      const element = document.getElementById(field)
      if (element) {
        element.focus()
        element.scrollIntoView(true)
      }
      this.setState({
        btnDisabled: false,
        showSpinner: false,
      })
    }
  }

  manualManagerSelected = () => {
    this.setState({
      manualManagerSelected: !this.state.manualManagerSelected,
    })
  }

  noTenantsErrors = () => {
    const { tenants, tenantsErrors } = this.state
    if (tenants) {
      tenants.forEach((tenant) => {
        if (tenant.isJointTenant) {
          if (
            tenant &&
            !(
              tenant.firstName &&
              tenant.lastName &&
              tenant.email &&
              tenant.mobileNumber
            )
          ) {
            return false
          }
        } else {
          const isEmailOrMobileNumberFilled =
            tenant.email || tenant.mobileNumber
          if (
            tenant &&
            (!tenant.firstName ||
              !tenant.lastName ||
              !isEmailOrMobileNumberFilled)
          ) {
            return false
          }
        }
      })
    }
    if (tenantsErrors) {
      tenantsErrors.forEach((tenantsError) => {
        if (!isEmptyObject(tenantsError)) {
          return false
        }
      })
    }
    return true
  }

  resetAddress = () => {
    return () => {
      this.setState({
        address: {
          friendlyName: '',
          unitNumber: '',
          streetNumber: '',
          streetName: '',
          address2: '',
          suburb: '',
          state: '',
          postcode: '',
          country: 'Australia',
          lat: null,
          lng: null,
          googleId: '',
        },
      })
    }
  }

  toggleDeselectConfirmModel = () => {
    this.setState({
      showDeselectConfirmModel: !this.state.showDeselectConfirmModel,
    })
  }

  trimFields() {
    const {
      firstName = '',
      lastName = '',
      email = '',
      phoneNumber = '',
      agencyName = '',
      countryCode = '',
      reasonForLeaving,
      otherReason,
      propertyArrangement,
      bond,
      managerType,
      officeNumber = '',
      rate,
      tenants,
      ledgerAttachments,
      address,
    } = this.state
    const ownedTheProperty = isAddressHistoryOwned(propertyArrangement)
    const formattedBond = Number.isNaN(parseInt(bond, 10))
      ? bond
      : parseInt(bond, 10)
    const formattedRent = Number.isNaN(parseInt(rate, 10))
      ? rate
      : parseInt(rate, 10)
    const ledgersIDs = ledgerAttachments?.map((attachment) => attachment.guidID)
    const { country } = address || {}
    let countryName = defaultCountry

    if (countriesListAlpha3.indexOf(country) >= 0) {
      countryName = countryListAlpha3[country]
    }
    if (!ownedTheProperty) {
      return Object.assign({}, this.state, {
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        email: email?.replace(/\s/g, ''),
        phoneNumber: phoneNumber?.replace(/\s/g, ''),
        agencyName: isAgent(managerType) ? agencyName?.trim() : '',
        countryCode: countryCode?.toUpperCase(),
        address: {
          ...address,
          country: countryName,
        },
        reasonForLeaving:
          reasonForLeaving === 'Other'
            ? otherReason || 'Other'
            : reasonForLeaving,
        bond: formattedBond || 0,
        rate: formattedRent || 0,
        isBondEmpty: bond === '',
        officeNumber: isAgent(managerType)
          ? officeNumber.replace(/\s/g, '')
          : '',
        tenants: tenants.map((tenant) => ({
          ...tenant,
          mobileNumber:
            tenant.mobileNumber && removeSpaces(tenant.mobileNumber),
          email: tenant.email && removeSpaces(tenant.email),
          guid: tenant.guid,
          relationship: tenant.relationship,
        })),
        ...(ledgersIDs && { ledgersIDs }),
      })
    }
    return Object.assign({}, this.state, {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      agencyName: '',
      countryCode: '',
      bond: 0,
      rate: 0,
      reasonForLeaving:
        reasonForLeaving === 'Other'
          ? otherReason || 'Other'
          : reasonForLeaving,
      officeNumber: '',
      personalMessage: '',
      tenants: tenants.map((tenant) => ({
        ...tenant,
        mobileNumber: tenant.mobileNumber && removeSpaces(tenant.mobileNumber),
        email: tenant.email && removeSpaces(tenant.email),
        guid: tenant.guid,
        relationship: tenant.relationship,
      })),
    })
  }

  update = (field) => {
    return (data) => {
      const { reasonForLeavingOptions } = this.state
      let updatedValue = data.value
      const updatedError = data.error
      if (field === 'email') {
        updatedValue = data.value.toLowerCase()
      }
      if (
        updatedError === '' &&
        (field === 'startDate' || field === 'endDate')
      ) {
        updatedValue =
          data.value !== ''
            ? moment(data.value).format(helpers.dateWithDash)
            : ''
      }

      if (['rate', 'bond'].includes(field)) {
        if (data.value !== '') {
          updatedValue = data.value.toString().replace(/[^0-9]/g, '')
        }
        if (
          isAddressHistoryWithFamilyOrFriends(this.state.propertyArrangement)
        ) {
          this.setState({ errors: { rate: '' } })
        }
      }
      if (field === 'propertyArrangement') {
        updatedValue = Number(data.value)
        if (
          isAddressHistoryOwned(updatedValue) ||
          helpers.isAddressHistoryTemporaryBnBArrangement(updatedValue)
        ) {
          this.setState({
            isBondPaid: false,
            shouldContactReference: false,
            sendReferenceOnAdd: false,
          })
          this.setValidationErrors('rate', [], false)
          this.setValidationErrors('bond', [], false)
        }
        this.setState({ managerType: isRented(updatedValue) ? 1 : 3 })
      }
      if (field === 'reasonForLeaving') {
        updatedValue = reasonForLeavingOptions[data.value]
      }
      if (
        (field === 'shouldContactReference' ||
          field === 'sendReferenceOnAdd') &&
        !data.value
      ) {
        this.setState({
          showDeselectConfirmModel: true,
          deselectingField: field,
        })
        return
      }
      if (field === 'shouldContactReference' && data.value === true) {
        this.setState({
          sendReferenceOnAdd: true,
        })
      }

      this.setState({
        unsavedChanges: true,
        [field]: updatedValue,
        errors: {
          ...this.state.errors,
          [field]: updatedError,
        },
      })
    }
  }

  updateAddress = (field) => {
    return (event) => {
      const { address } = this.state
      address[field] = event.target.value
      this.setState({ address, unsavedChanges: true })
    }
  }

  updateAddressText = (field) => {
    return (data) => {
      const { address } = this.state
      address[field] = data?.value
      this.setState({ address, unsavedChanges: true })
    }
  }

  updateCurrentAddress = () => {
    this.setState({
      isCurrentAddress: !this.state.isCurrentAddress,
      unsavedChanges: true,
    })
  }

  updateGoogleAddress = (address) => {
    this.setState(
      { address, unsavedChanges: true },
      this.validate('address', true),
    )
  }

  updateIsBondPaid = () => {
    const errors = Object.assign({}, this.state.errors, { bond: '' })
    this.setState({
      isBondPaid: !this.state.isBondPaid,
      bond: '',
      unsavedChanges: true,
      errors,
    })
  }

  updateManagerSearchValue = (event) => {
    const updatedValue = event.target.value.toLowerCase()
    this.setState({
      managerLookupSearchText: event.target.value,
      allManagersRentalReferenceDropdownOpen: true,
    })
    if (updatedValue.length > 0) {
      this.setState({
        rentalReferenceManagerFetchInProgress: true,
      })
      this.updateManagerValue(updatedValue)
    }
  }

  updateManagerType = (managerType) => {
    this.setState({ managerType, phoneNumber: '' })
    this.setValidationErrors('phoneNumber', [], false)
    if (isOwner(managerType)) {
      this.setValidationErrors('agencyName', [], false)
      this.setValidationErrors('officeNumber', [], false)
    }
  }

  updateManagerValue = debounce((searchValue = '') => {
    this.props
      .fetchManagersForRentalHistory(searchValue)
      .then((data) => {
        this.setState({ filteredManagerLookup: data.agencySource })
      })
      .catch((err) => {
        snugNotifier.error(err)
      })
      .finally(() => {
        this.setState({
          rentalReferenceManagerFetchInProgress: false,
        })
      })
  }, STANDARD_TIMEOUT) // debounce

  updateRollingLease = () => {
    this.setState({
      isRollingLease: !this.state.isRollingLease,
      unsavedChanges: true,
    })
  }

  validate = (field, onBlur) => {
    return () => {
      let { address } = this.state

      switch (field) {
        case 'address': {
          if (address === undefined) {
            address = {
              unitNumber: '',
              streetNumber: '',
              streetName: '',
              address2: '',
              suburb: '',
              state: '',
              postcode: '',
              country: defaultCountry,
              lat: null,
              lng: null,
              friendlyName: '',
              googleId: '',
            }
          }
          const addressValidation = validateAddress(address)
          return this.setValidationErrors(
            field,
            !addressValidation.ok
              ? [ErrorMessages[addressValidation.errorCode]]
              : [],
            onBlur,
          )
        }
        default:
          return true
      }
    }
  }

  validateCommonRequiredFields = () => {
    const errors = {}
    const { propertyArrangement, requestReasonForMoving } = this.state
    const ownedTheProperty = isAddressHistoryOwned(propertyArrangement)
    const livedWithFamilyOrFirends =
      isAddressHistoryWithFamilyOrFriends(propertyArrangement)
    const isTemporaryBnBArrangement =
      helpers.isAddressHistoryTemporaryBnBArrangement(propertyArrangement)
    const rentRequired =
      propertyArrangement &&
      !ownedTheProperty &&
      !livedWithFamilyOrFirends &&
      !isTemporaryBnBArrangement
    Object.keys(this.state.errors).map((field) => {
      if (field === 'address' && !validateAddress(this.state.address)) {
        errors[field] = 'This address is not valid'
      } else if (
        field === 'propertyArrangement' &&
        (this.state.propertyArrangement === 0 ||
          this.state.propertyArrangement === '')
      ) {
        errors[field] = 'This field is required'
      } else if (field === 'startDate' && this.state[field] === '') {
        errors[field] = 'This field is required'
      } else if (
        field === 'endDate' &&
        !this.state.isRollingLease &&
        this.state.endDate === ''
      ) {
        errors[field] = 'This field is required'
      } else if (
        !this.state.isCurrentAddress &&
        field === 'reasonForLeaving' &&
        this.state[field] === '' &&
        requestReasonForMoving
      ) {
        errors[field] = 'Reason for leaving is required'
      } else if (
        this.state.reasonForLeaving === 'Other' &&
        field === 'otherReason' &&
        this.state[field] === ''
      ) {
        errors[field] = 'This field is required'
      }
      if (rentRequired && this.state.rate === '') {
        errors.rate = 'This field is required'
      }
      if (this.state.isBondPaid && this.state.bond === '') {
        errors.bond = 'This field is required'
      }
      return null
    })
    this.setState(
      {
        errors: {
          ...this.state.errors,
          ...errors,
        },
      },
      () => {
        this.focusAndScrollToFirstError(this.state.errors)
      },
    )
    return Object.keys(errors).length === 0 && this.validateContact()
  }

  validateContact = () => {
    if (
      isAddressHistoryOwned(this.state.propertyArrangement) ||
      helpers.isAddressHistoryTemporaryBnBArrangement(
        this.state.propertyArrangement,
      )
    ) {
      return true
    }
    const errors = {}
    const contactFields = ['firstName', 'lastName']
    contactFields.map((field) => {
      if (this.state[field] === '') {
        errors[field] = 'This field is required'
      }
      return null
    })
    let contactMethodFields = [this.state.phoneNumber, this.state.email]
    const agentContactFields = ['agencyName']
    if (isAgent(this.state.managerType)) {
      delete errors.phoneNumber
      agentContactFields.map((field) => {
        if (this.state[field] === '') {
          errors[field] = 'This field is required'
        }
        return null
      })
      contactMethodFields.push(this.state.officeNumber)
    }

    const contactDetailsError =
      validateMinimumContactDetailProvided(contactMethodFields)

    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
      apiError: contactDetailsError,
    })
    return Object.keys(errors).length === 0 && contactDetailsError === ''
  }

  uploadLedger = (documentType) => {
    this.ledgerAttachmentLoadingStateUtils.startLoading()
    const attachments = document.getElementById(`attachments-${documentType}`)
    let formData = new FormData()

    composeAttachment(attachments, formData)

    let uploadPromise
    if (this.props.isEditMode) {
      uploadPromise = uploadLedgerByRenter(
        this.props.match.params.referenceId,
        formData,
      )
    } else {
      uploadPromise = uploadUnboundedLedgerByRenter(formData)
    }
    uploadPromise
      .then((ledgers) => {
        const existingLedgerAttachments = this.state.ledgerAttachments || []
        toast.success('Successfully uploaded ledger files')
        this.setState({
          ledgerAttachments: [...existingLedgerAttachments, ...ledgers],
        })
        this.ledgerAttachmentLoadingStateUtils.markDoneSuccessfully()
      })
      .catch((error) => {
        this.ledgerAttachmentLoadingStateUtils.setError(error.message)
        toast.error(error.message)
      })
  }

  deleteLedger = (ledgerID) => {
    this.ledgerAttachmentLoadingStateUtils.startLoading()

    let deletePromise
    if (this.props.isEditMode) {
      deletePromise = deleteRentalLedger(
        this.props.match.params.referenceId,
        ledgerID,
      )
    } else {
      deletePromise = deleteUnboundedLedger(ledgerID)
    }
    deletePromise
      .then(() => {
        toast.success('Successfully deleted ledger file')
        this.setState({
          ledgerAttachments: this.state.ledgerAttachments.filter(
            (attachment) => attachment.guidID !== ledgerID,
          ),
        })
        this.ledgerAttachmentLoadingStateUtils.markDoneSuccessfully()
      })
      .catch((error) => {
        this.ledgerAttachmentLoadingStateUtils.setError(error.message)
        toast.error(error.message)
      })
  }

  renderLedgerAttachmentsCmp() {
    const { ledgerAttachments = [], ledgerAttachmentLoadingStates } = this.state
    return (
      <Box my={8}>
        <Heading level={4} displayingLevel={4} my={4}>
          Rental Ledger{' '}
          <span
            style={isMobile() ? { fontSize: theme.fontSizes.pLarge16 } : null}
          >
            (Highly Recommended)
          </span>
        </Heading>
        <Box my={5}>
          <TextSpan>
            Add the rental ledger to speed up your application processing.{' '}
            <a
              href={textHelpers.RENTAL_LEDGER_RENTER_LEARN_MORE_LINK}
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </TextSpan>
        </Box>
        <DocumentList
          showUploader={ledgerAttachments.length < 1}
          disableMultiple
          documents={ledgerAttachments}
          addDocument={this.uploadLedger}
          deleteDocument={this.deleteLedger}
          documentType={DocumentTypes.rentalLedger}
          spinner={
            ledgerAttachmentLoadingStates.state === loadingStatesIds.LOADING
          }
          fileType="RentalLedger"
        />
      </Box>
    )
  }

  render() {
    const {
      isEditMode,
      match: {
        params: { referenceId = null },
      },
      backUrl,
      property,
      currentUser,
    } = this.props
    const {
      address,
      bond,
      email,
      isRollingLease,
      startDate,
      endDate,
      firstName,
      lastName,
      managerType,
      phoneNumber,
      rate,
      errors,
      agencyName,
      unsavedChanges,
      propertyArrangement,
      isCurrentAddress,
      reasonForLeaving,
      otherReason,
      officeNumber,
      personalMessage,
      isBondPaid,
      shouldContactReference,
      showDeselectConfirmModel,
      deselectingField,
      sendReferenceOnAdd,
      isJointTenancy,
      tenants,
      tenantsErrors,
      showValidationError,
      btnDisabled,
      showSpinner,
      allManagersRentalReferenceDropdownOpen,
      manualManagerSelected,
      managerLookupSearchText,
      filteredManagerLookup = [],
      showManualManagerEntry,
      rentalReferenceManagerFetchInProgress,
      apiError,
      reasonForLeavingOptions,
      isBallotApplication,
      requestReasonForMoving,
    } = this.state
    const isFromApplicationForm =
      backUrl.includes('sm/applications') && !backUrl.includes('/rentersummary')
    const reasonSelected = reasonForLeavingOptions.findIndex(
      (potentialReason) => potentialReason === reasonForLeaving,
    )
    const defaultArrangement =
      propertyArrangement === 0 || propertyArrangement === ''
    const rented = isRented(propertyArrangement)
    const boarded = propertyArrangement === 2
    const ownedTheProperty = isAddressHistoryOwned(propertyArrangement)
    const subletted = isSubletted(propertyArrangement)
    const livedWithFamilyOrFirends =
      isAddressHistoryWithFamilyOrFriends(propertyArrangement)
    const selectedLivingArrangement = propertyArrangement

    const showOptionalLabel =
      isAddressHistoryWithFamilyOrFriends(propertyArrangement) ||
      helpers.isAddressHistoryTemporaryBnBArrangement(propertyArrangement)

    const dontShowRefereeDetailsSection =
      ownedTheProperty ||
      helpers.isAddressHistoryTemporaryBnBArrangement(propertyArrangement)

    const addressHistoryHeading = `${
      isEditMode ? `Edit` : `Add`
    } Address History`

    let contactTipMessage = `Please enter contact details for who can provide a reference for your stay.`
    if (rented)
      contactTipMessage = `Please enter contact to provide a reference for your stay.`
    if (subletted)
      contactTipMessage = `Please enter contact details for who provided the sublet to give a reference for your stay.`
    if (boarded)
      contactTipMessage = `Please enter contact details for who provided the boarding to give a reference for your stay.`
    if (livedWithFamilyOrFirends)
      contactTipMessage = `Please enter contact details to provide a reference for your stay.`
    const isContactReferenceEnabled =
      isEditMode &&
      this.props.rentalReference &&
      this.props.rentalReference.shouldContactReference
    const isSendReferenceOnAddEnabled =
      isEditMode &&
      this.props.rentalReference &&
      this.props.rentalReference.sendReferenceOnAdd
    const deselectingItem = referencePreferenceModalAttributes.find(
      (item) => item.field === deselectingField,
    )
    const formattedStartDate =
      this.state.errors.startDate !== dateTimeHelpers.INVALID_DATE_STRING
        ? startDate && moment(startDate).format(dateTimeHelpers.DATE_WITH_SLASH)
        : startDate
    const formattedEndDate =
      this.state.errors.endDate !== dateTimeHelpers.INVALID_DATE_STRING
        ? endDate && moment(endDate).format(dateTimeHelpers.DATE_WITH_SLASH)
        : endDate
    const selectedTenantOption = !isEditMode ? null : isJointTenancy ? Yes : No
    const activePropertyArrangementOptions =
      this.state.propertyArrangementOptions.filter((o) => o.show)

    const showOtherTenantsAndLedger = requireOtherTenantsAndLedger(
      selectedLivingArrangement,
    )

    const dontShowRentalLedger =
      dontShowRefereeDetailsSection || livedWithFamilyOrFirends

    return (
      <div>
        <RentalReputationHeaderReminder
          property={property}
          backUrl={backUrl}
          remindText="Current application"
          isFromApplicationForm={isFromApplicationForm}
          title="Address History"
          thirdTierText={addressHistoryHeading}
        />

        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <div className="rental-history-container">
              <div className="row pt25 mobile-form-button pl20 pr20">
                <Box>
                  <Layout.SectionHeader
                    text={addressHistoryHeading}
                    hasSeperator={false}
                    textClass="pl0"
                  />
                </Box>
                <Layout.ContainerHeader
                  text="What was the address?"
                  componentClass="mb25"
                  isEditable={false}
                />

                <div className="input-box mb40">
                  <RentalHistoryAddressForm
                    address={address}
                    update={this.updateAddress}
                    updateGoogleAddress={this.updateGoogleAddress}
                    resetAddress={this.resetAddress()}
                    placeholder="Enter rental address"
                    error={errors.address}
                    onBlur={this.validate('address', true)}
                    updateAddressText={this.updateAddressText}
                  />
                </div>
                <ErrorMessage error={errors.address} />

                <Layout.ContainerHeader
                  text="What was the arrangement?"
                  componentClass="mb25"
                  isEditable={false}
                />
                <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                  <div className="col-md-6 p0 pt10">
                    <Form.Dropdown
                      options={activePropertyArrangementOptions}
                      label="Select your living arrangement"
                      value={selectedLivingArrangement}
                      onChange={this.update('propertyArrangement')}
                      error={errors.propertyArrangement}
                      id="propertyArrangement"
                      inputClass="width100"
                      inputBoxClass="pt0"
                      componentClass="margin-profile-item-left"
                      useLabelValueOptions={true}
                    />
                  </div>
                  <div className="col-md-6 p0">
                    {!ownedTheProperty && !defaultArrangement && (
                      <Form.InputNumber
                        label={`Weekly rent ${
                          showOptionalLabel ? '(optional)' : ''
                        }`}
                        value={rate}
                        error={this.state.errors.rate}
                        onChange={this.update('rate')}
                        inputClass="width100"
                        id="rate"
                        componentClass="margin-profile-item-right"
                        isOptional={showOptionalLabel}
                      />
                    )}
                  </div>
                </Form.ProfilePagesRowLayout>

                {!ownedTheProperty && !defaultArrangement && (
                  <BondInputs
                    bondErrors={this.state.errors.bond}
                    bond={bond}
                    update={this.update}
                    validate={this.validate}
                    isBondPaid={isBondPaid}
                    updateIsBondPaid={this.updateIsBondPaid}
                  />
                )}
                <Layout.ContainerHeader
                  text="When did you live here?"
                  componentClass="mb25 pt10"
                  isEditable={false}
                />
                <Form.ProfilePagesRowLayout>
                  <div className="width100">
                    <Form.CheckBoxGeneral
                      label="I currently live here"
                      checked={isCurrentAddress}
                      onChange={() => this.updateCurrentAddress()}
                    />
                  </div>
                </Form.ProfilePagesRowLayout>

                {isCurrentAddress && (
                  <Form.ProfilePagesRowLayout>
                    <div className="width100">
                      <Form.CheckBoxGeneral
                        label={
                          ownedTheProperty || showOptionalLabel
                            ? `I don't have an end date`
                            : `I'm on an ongoing/rolling arrangement`
                        }
                        checked={isRollingLease}
                        onChange={() => this.updateRollingLease()}
                      />
                    </div>
                  </Form.ProfilePagesRowLayout>
                )}

                <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                  <div className="col-md-6 p0 width100">
                    <Form.InputPastDate
                      label="Start Date"
                      value={isEditMode ? formattedStartDate : null}
                      error={this.state.errors.startDate}
                      before={endDate || moment()}
                      onChange={this.update('startDate')}
                      id="startDate"
                      containerClassName="width100"
                      componentClass="margin-profile-item-left"
                      viewMode="months"
                      customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                      readOnly={false}
                    />
                  </div>
                  <div className="col-md-6 p0 width100">
                    {!isRollingLease && (
                      <Form.InputDate
                        label="End Date"
                        value={isEditMode ? formattedEndDate : null}
                        error={this.state.errors.endDate}
                        after={startDate}
                        onChange={this.update('endDate')}
                        id="endDate"
                        containerClassName="width100"
                        componentClass="margin-profile-item-right"
                        viewMode="months"
                        customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                        readOnly={false}
                      />
                    )}
                  </div>
                </Form.ProfilePagesRowLayout>
                {!isCurrentAddress &&
                  !isRollingLease &&
                  requestReasonForMoving && (
                    <div>
                      <p className="mb0">
                        What was the main reason for leaving?
                      </p>
                      <Form.ProfilePagesRowLayout>
                        <div className="col-md-6 p0">
                          <Form.Dropdown
                            label="Reason for leaving"
                            value={reasonSelected}
                            error={this.state.errors.reasonForLeaving}
                            options={reasonForLeavingOptions}
                            onChange={this.update('reasonForLeaving')}
                            id="reasonForLeaving"
                            inputClass="width100"
                            inputBoxClass="pt0 mb20"
                            componentClass="margin-profile-item-left"
                          />
                        </div>
                      </Form.ProfilePagesRowLayout>

                      {reasonForLeaving === 'Other' && (
                        <Form.CommentArea
                          label="Please provide reason for leaving"
                          id="otherReason"
                          value={otherReason}
                          error={this.state.errors.otherReason}
                          placeholder="Insert text"
                          onChange={this.update('otherReason')}
                          cols={50}
                          rows={4}
                          componentClass="mb30"
                        />
                      )}
                    </div>
                  )}
                {showOtherTenantsAndLedger && (
                  <>
                    <ButtonSelectGroup
                      label="Were there other tenants on this lease?"
                      selected={selectedTenantOption}
                      selectedVariant="outlineGreen"
                      defaultVariant="outlineGray"
                      onChange={this.onJointTenancyChange}
                      options={otherTenantOptions}
                    />
                    {isJointTenancy && (
                      <JointTenants
                        tenants={tenants}
                        tenantsErrors={tenantsErrors}
                        showValidationError={showValidationError}
                        onTenantFieldChange={this.onTenantFieldChange}
                        onAddJointTenant={this.onAddJointTenant}
                        onRemoveButtonClick={this.onRemoveButtonClick}
                        currentUser={currentUser}
                      />
                    )}
                  </>
                )}

                {!dontShowRentalLedger && (
                  <Box id={ledgerAttachmentsCmpDomId}>
                    {this.renderLedgerAttachmentsCmp()}
                  </Box>
                )}
                {!dontShowRefereeDetailsSection && (
                  <div>
                    <Layout.ContainerHeader
                      text="Contact Details"
                      componentClass="mb25"
                      isEditable={false}
                    />
                    {shouldContactReference && (
                      <Layout.SnugTip text="Rental references are an important part of the application. Enter your current and/past details and Snug will send an email requesting the reference. We’ll notify when received." />
                    )}
                    <Layout.GreyBodyText text={contactTipMessage} />

                    {rented && (
                      <div className="snug-tabs mt30">
                        <label>
                          <input
                            type="radio"
                            name="manager"
                            value={1}
                            onChange={() => this.updateManagerType(1)}
                            checked={isAgent(managerType)}
                          />
                          <span>Agent</span>
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="manager"
                            value={2}
                            onChange={() => this.updateManagerType(2)}
                            checked={isOwner(managerType)}
                          />
                          <span>Owner</span>
                        </label>
                      </div>
                    )}
                    {rented &&
                      isAgent(managerType) &&
                      manualManagerSelected &&
                      !isEditMode && (
                        <div>
                          <GreySubheading
                            text="Search for an agent"
                            componentClass="mb0"
                          />
                          <div
                            className="input-box mb0"
                            onBlur={() => {
                              setTimeout(
                                () => this.closeAvatarAndNameDropdown(),
                                500,
                              )
                            }}
                          >
                            <input
                              placeholder="Search for Agency and Property Manager"
                              value={managerLookupSearchText}
                              onChange={this.updateManagerSearchValue}
                              type="text"
                            />

                            <AvatarAndNameDropdown
                              showDropdown={
                                allManagersRentalReferenceDropdownOpen
                              }
                              dropdownItems={filteredManagerLookup}
                              dropdownItemClickEventEnabled={true}
                              dropdownItemClickEventHandler={
                                this.onClickManagerDropdownItem
                              }
                              showSpinnerFeatureEnabled={true}
                              showSpinner={
                                rentalReferenceManagerFetchInProgress
                              }
                            />
                          </div>
                        </div>
                      )}
                    {rented && isAgent(managerType) && !isEditMode && (
                      <div className="input-address mt10">
                        <div className="note note-google">
                          {manualManagerSelected && "Can't find the agent?"}
                          <span
                            onClick={() => {
                              this.setState({
                                showManualManagerEntry: true,
                                manualManagerSelected: !manualManagerSelected,
                              })
                              if (!manualManagerSelected) {
                                this.setState({
                                  showManualManagerEntry: false,
                                })
                              }
                            }}
                            className="ml10 manual-address"
                          >
                            {manualManagerSelected
                              ? 'Add manually'
                              : 'Search for an agent'}
                          </span>
                        </div>
                      </div>
                    )}
                    {(showManualManagerEntry ||
                      !rented ||
                      !isAgent(managerType) ||
                      isEditMode) && (
                      <div>
                        <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                          <div className="col-md-6 p0 width100">
                            <Form.InputName
                              label="First Name"
                              value={firstName}
                              error={this.state.errors.firstName}
                              onChange={this.update('firstName')}
                              id="firstName"
                              inputClass="width100"
                              componentClass="margin-profile-item-left"
                            />
                          </div>
                          <div className="col-md-6 p0 width100">
                            <Form.InputName
                              label="Last Name"
                              value={lastName}
                              error={this.state.errors.lastName}
                              onChange={this.update('lastName')}
                              id="lastName"
                              inputClass="width100"
                              componentClass="margin-profile-item-right"
                            />
                          </div>
                        </Form.ProfilePagesRowLayout>
                        <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                          <div className="col-md-6 p0 width100">
                            <Form.InputEmail
                              label="Email"
                              value={email}
                              error={this.state.errors.email}
                              onChange={this.update('email')}
                              id="email"
                              inputClass="width100"
                              componentClass="margin-profile-item-left"
                              isOptional
                            />
                          </div>

                          {isAgent(managerType) && rented && (
                            <div className="col-md-6 p0 width100">
                              <Form.InputTextRequired
                                label="Agency Name"
                                value={agencyName}
                                error={this.state.errors.agencyName}
                                onChange={this.update('agencyName')}
                                id="agencyName"
                                inputClass="width100"
                                componentClass="margin-profile-item-right"
                              />
                            </div>
                          )}

                          {managerType && !isAgent(managerType) && (
                            <div className="col-md-6 p0 width100">
                              <Form.InputPhoneNumber
                                label="Contact Number"
                                placeholder="0412 345 678 or 02 1234 5678"
                                value={phoneNumber}
                                error={this.state.errors.phoneNumber}
                                onChange={this.update('phoneNumber')}
                                id="phoneNumber"
                                containerClassName="width100 mb30"
                                componentClass="margin-profile-item-right"
                                required
                              />
                            </div>
                          )}
                        </Form.ProfilePagesRowLayout>

                        {isAgent(managerType) && rented && (
                          <div>
                            <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                              <div className="col-md-6 p0 width100">
                                <Form.InputPhoneNumber
                                  label="Office Number"
                                  placeholder="02 1234 5678"
                                  value={officeNumber}
                                  error={this.state.errors.officeNumber}
                                  onChange={this.update('officeNumber')}
                                  id="officeNumber"
                                  containerClassName="width100"
                                  componentClass="margin-profile-item-left"
                                  numberType={NUMBER_TYPE_LANDLINE}
                                />
                              </div>
                              <div className="col-md-6 p0 width100">
                                <Form.InputPhoneNumber
                                  label="Mobile Number"
                                  placeholder="0412 345 678 (optional)"
                                  value={phoneNumber}
                                  error={this.state.errors.phoneNumber}
                                  onChange={this.update('phoneNumber')}
                                  id="phoneNumber"
                                  containerClassName="width100"
                                  componentClass="margin-profile-item-right"
                                  numberType={NUMBER_TYPE_MOBILE}
                                />
                              </div>
                            </Form.ProfilePagesRowLayout>
                          </div>
                        )}
                      </div>
                    )}

                    <Form.CommentArea
                      label="Message to Referee (Optional)"
                      help="This message is included in the email rental reference request to your Referee only. It's not displayed on your application to a prospective property manager."
                      value={personalMessage}
                      placeholder="Including a message to your Referee is optional."
                      onChange={this.update('personalMessage')}
                      cols={50}
                      rows={4}
                      componentClass="mb40"
                      inputBoxClass="pt0"
                    />
                    <Form.CheckBoxGeneral
                      label={
                        <span>
                          <span>Yes, I agree to the </span>
                          <a
                            href={DISCLOSURE_AUTHORITY_TEMPLATE}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Personal Information and Collection Authority
                          </a>
                        </span>
                      }
                      checked={shouldContactReference}
                      onChange={this.update('shouldContactReference')}
                      labelClass="height-auto"
                      disabled={isContactReferenceEnabled}
                      componentClassName={
                        isContactReferenceEnabled ? 'disabled-link' : ''
                      }
                    />
                    <Form.CheckBoxGeneral
                      label={
                        <span>
                          {!isBallotApplication
                            ? textHelpers.RENTAL_HISTORY_ADD_NORMAL_APP_SEND_RR_NOW_MSG
                            : textHelpers.RENTAL_HISTORY_ADD_BALLOT_APP_SEND_RR_NOW_MSG}
                        </span>
                      }
                      checked={sendReferenceOnAdd}
                      onChange={this.update('sendReferenceOnAdd')}
                      labelClass="height-auto"
                      disabled={isSendReferenceOnAddEnabled}
                      componentClassName={
                        isSendReferenceOnAddEnabled ? 'disabled-link' : ''
                      }
                    />
                    {showDeselectConfirmModel && (
                      <Layout.Modal
                        title={deselectingItem.title}
                        body={deselectingItem.text}
                        primaryButtonLabel="Yes"
                        primaryButtonAction={() =>
                          this.confirmDeselect(deselectingItem.field)
                        }
                        primaryButtonClassName="p0"
                        secondaryButtonClassName="p0"
                        secondaryButtonLabel="No"
                        secondaryButtonAction={this.toggleDeselectConfirmModel}
                        toggleModal={this.toggleDeselectConfirmModel}
                      />
                    )}
                    <StyledDisclosureAddressHistoryText className="default-span-text">
                      <span>
                        {textHelpers.RENTAL_HISTORY_CONSENT_DISCLOSURE}
                      </span>
                    </StyledDisclosureAddressHistoryText>
                  </div>
                )}
              </div>
              <div className={apiError ? 'alert alert-danger' : 'hidden'}>
                {ErrorMessages[parseInt(apiError)]
                  ? ErrorMessages[parseInt(apiError)]
                  : `${apiError}`}
              </div>
            </div>

            <div className="table">
              <div className="row pt25 mobile-form-button mt15">
                <div className="col-sm-5 pb5 tablet-only">
                  <div
                    className="btn btn-transparent btn-left xs-w100p wa"
                    onClick={() => history.goBack()}
                    role="button"
                    tabIndex="0"
                  >
                    <i className="icon-arrow-left left" />
                    <span>Back</span>
                  </div>
                </div>
                {
                  <div className="col-sm-7">
                    <button
                      className="rental-history-added"
                      onClick={() => {
                        this.setState(
                          {
                            unsavedChanges: false,
                            showSpinner: true,
                            btnDisabled: true,
                          },
                          () => {
                            isEditMode
                              ? this.editRentalHistory(referenceId)
                              : this.addRentalHistory()
                          },
                        )
                      }}
                      disabled={btnDisabled}
                    >
                      {isEditMode ? 'Save' : 'Add'}
                      {showSpinner && (
                        <i className={'fa fa-spinner fa-pulse'} />
                      )}
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <UnsavedMessage unsavedChanges={unsavedChanges} />
      </div>
    )
  }
}

RentalHistoryAdd.propTypes = {
  fetchRentalReference: PropTypes.func.isRequired,
  fetchPropertyAttributes: PropTypes.func.isRequired,
  addRentalHistory: PropTypes.func.isRequired,
  editRentalHistory: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,

  match: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string])),
    ]),
  ).isRequired,
  currentUser: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.objectOf([
            PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.bool,
              PropTypes.number,
            ]),
          ]),
        ]),
      ),
    ]),
  ).isRequired,
  isEditMode: PropTypes.bool.isRequired,
}

export default RentalHistoryAdd
