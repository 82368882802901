import React from 'react'

import { Box, Modal } from 'app/components/design-system-components'

function BallotSubmitModal({ toggleModal, primaryAction, submitLoading }) {
  return (
    <Modal
      modalBody={
        <Box mt={6}>
          <Box>
            Continuing will submit your ballot entry, and:
            <ul>
              <li>- Invites will be issued to your nominated household</li>
              <li>
                - All household members must submit before the deadline to be
                eligible
              </li>
              <li>
                - You will be able to continue to complete a full application
              </li>
              <li>- You can withdraw your application at any time</li>
            </ul>
          </Box>
        </Box>
      }
      primaryAction={primaryAction}
      toggleModal={toggleModal}
      secondaryAction={toggleModal}
      secondaryLabel="Close"
      primaryLabel="Submit Ballot Entry"
      modalHeading="Ballot Entry & Declarations"
      primaryButtonLoading={submitLoading}
    />
  )
}

export default BallotSubmitModal
