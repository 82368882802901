import React from 'react'

import PropTypes from 'prop-types'

import { AlertSensitiveInfo } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'
import Card from 'app/match/applicationReportPDF/components/Card'
import InfoList from 'app/match/applicationReportPDF/components/InfoList'
import { attachList } from 'app/match/applicationReportPDF/helpers/utils'

const propTypes = {
  data: PropTypes.object,
}

const IdentityDocuments = ({ data = {} }) => {
  const {
    primaryDoc = {},
    supportingIdentityDocs = {},
    identityCheckPoints = 0,
  } = data
  return (
    <div className="identity-documents">
      <Card
        type={'icon'}
        icon={'icon-i-touch-id'}
        title={`Identity Documents (${identityCheckPoints} points)`}
        addClass="bottom-border mt-0 "
      />
      <AlertSensitiveInfo
        px={3}
        py={3}
        hideLearnMore
        fontSize={theme.fontSizes.pSmall12}
      />
      {Object.keys(primaryDoc).length === 0 &&
        Object.keys(supportingIdentityDocs).length === 0 && (
          <p>No information is provided</p>
        )}
      {data.primaryDoc &&
        Object.keys(data.primaryDoc).map((item, index) => {
          return (
            <div className="list-info-wraper" key={index}>
              <span className={'list-title'}>
                {item === 'driver_license'
                  ? `${item.replace(/_/g, ' ')}`
                  : `${item.replace(/_/g, ' ')}`}
              </span>
              <InfoList data={data.primaryDoc[item]} />
            </div>
          )
        })}
      {attachList(data.supportingIdentityDocs)}
    </div>
  )
}

IdentityDocuments.propTypes = propTypes

export default IdentityDocuments
