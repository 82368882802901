import React from 'react'

import { Link } from 'react-router-dom'

import headerImg07 from 'app/assets/icons/header-img-07.png'
import { urlTo } from 'app/sm/helpers'

const bindings = (isForApplicationAccepted) => ({
  header: isForApplicationAccepted
    ? 'Application accepted !'
    : 'Payment completed !',
  parole: isForApplicationAccepted
    ? 'Application accepted'
    : 'Thank you for your payment, your property is advertised on all selected websites !',
  continueUrl: isForApplicationAccepted
    ? urlTo('applications')
    : urlTo('myProperties'),
})

const ConfirmationScreen = ({ isForApplicationAccepted = false }) => (
  <div className="sm-property advertise confirmation">
    <div className="section-title">
      <div className="left column">
        <h3>{bindings(isForApplicationAccepted).header}</h3>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-12 text-center pb30">
        <img src={headerImg07} />
      </div>
    </div>
    <div className="row">
      <div className="col-sm-12 text-center">
        <p>{bindings(isForApplicationAccepted).parole}</p>
      </div>
    </div>
    <div className="row pt20">
      <div className="col-sm-12">
        <Link to={bindings(isForApplicationAccepted).continueUrl}>
          <button className="btn">Continue</button>
        </Link>
        {/*<button className='btn' onClick={() => updateAdvertisement(urlTo('applications'))(offerId)}>See your applications</button>*/}
      </div>
    </div>
  </div>
)

export default ConfirmationScreen
