import * as React from 'react'

function SvgChildCareRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.94 11.34a4.008 4.008 0 00-2.81-3.17 9.114 9.114 0 00-2.19-2.91A8.885 8.885 0 0012 3c-2.28 0-4.36.85-5.94 2.26-.92.81-1.67 1.8-2.19 2.91a3.994 3.994 0 00-2.81 3.17c-.04.21-.06.43-.06.66 0 .23.02.45.06.66a4.008 4.008 0 002.81 3.17 8.977 8.977 0 002.17 2.89A8.885 8.885 0 0012 21c2.29 0 4.38-.86 5.97-2.28.9-.8 1.65-1.79 2.17-2.89a3.998 3.998 0 002.8-3.17c.04-.21.06-.43.06-.66 0-.23-.02-.45-.06-.66zM9.5 9.25a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm5 0a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM16.1 14c.18 0 .31.2.23.37C15.5 15.94 13.87 17 12 17s-3.5-1.06-4.33-2.63c-.09-.17.04-.37.23-.37h8.2zm2.711-.017c.063.008.124.017.189.017 1.1 0 2-.9 2-2s-.9-2-2-2a1.34 1.34 0 00-.189.017c-.033.005-.066.01-.101.013-.2-.67-.49-1.29-.86-1.86A6.976 6.976 0 0012 5C9.55 5 7.4 6.26 6.15 8.17c-.37.57-.66 1.19-.86 1.86-.035-.003-.068-.008-.101-.013A1.339 1.339 0 005 10c-1.1 0-2 .9-2 2s.9 2 2 2c.065 0 .126-.008.189-.017.033-.005.066-.01.101-.013.2.67.49 1.29.86 1.86A6.976 6.976 0 0012 19c2.45 0 4.6-1.26 5.85-3.17.37-.57.66-1.19.86-1.86.035.003.068.008.101.013z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgChildCareRounded
