import styled from 'styled-components'

import Flex from 'app/components/design-system-components/Flex'

const IconBox = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: ${({ size }) => size + 'px'};
  width: ${({ size }) => size + 'px'};
`

IconBox.defaultProps = {
  size: 24,
}

export default IconBox
