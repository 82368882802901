import React from 'react'

import styled from 'styled-components'

import warningImg from 'app/assets/icons/warning.svg'
import { Box, Flex } from 'app/components/design-system-components'
import { MailToLink } from 'app/components/design-system-components/links/MailTo'
import { snugHelpEmail } from 'app/constants/global.constants'
import Footer from 'app/shared_components/footer/footer'

const SplashImage = styled.img`
  height: 80px;
  width: 80px;
`

export const IFrameNotAllowed = () => (
  <Flex alignItems="center" justifyContent="center">
    <Box>
      <Flex>
        <Box mr="24px">
          <SplashImage src={warningImg} alt="Important!" />
        </Box>
        <Flex flexDirection="column" justifyContent="center">
          <h2>Security alert</h2>
          <p>
            Insecure link detected
            <br />
            Please contact{' '}
            <MailToLink
              email={snugHelpEmail}
              subject="Insecure link to access Snug"
            >
              {snugHelpEmail}
            </MailToLink>{' '}
            with details of the link you clicked on to access Snug.
          </p>
        </Flex>
      </Flex>
      <Footer />
    </Box>
  </Flex>
)
export default IFrameNotAllowed
