import React from 'react'

import moment from 'moment'

const ReportFeedbackMessage = ({ comment }) => {
  const { rating = '', ratingDate = '' } = comment
  return (
    <div className="feedback-comment">
      <div>{`"${rating}"`}</div>
      <div className="mt5 fw700">
        {moment(ratingDate).local().format('HH:mm, A DD MMM YYYY')}
      </div>
    </div>
  )
}

export default ReportFeedbackMessage
