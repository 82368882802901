import type { OptionProp } from 'app/components/design-system-components/inputs/types'
import { getEntityFromOptionProp } from 'app/components/design-system-components/inputs/utils'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'

export const formFields = {
  destinationApplication: 'destinationApplication',
  message: 'message',
}

const fieldValidation = (fieldName: string, val: any): any => {
  switch (fieldName) {
    case formFields.destinationApplication: {
      return {
        required:
          !getEntityFromOptionProp(val)?.guidID && 'To Application is required',
      }
    }
  }

  return null
}

export const formValidation = formValidationBuilder(fieldValidation)

export const defaultInitialValues = {
  [formFields.destinationApplication]: {} as OptionProp,
  [formFields.message]: null,
}
