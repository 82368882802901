import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const fetchCallBackInformation = (applicationSlug) => {
  const url = `${apiBaseUrl}sm/applications/${applicationSlug}/directconnect/callback`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (dcCallBackInfo) => ({ ok: true, dcCallBackInfo }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postCallBackInformation = (applicationId, callBackDateTime) => {
  const url = `${apiBaseUrl}sm/applications/${applicationId}/directconnect/callback`
  const options = {
    method: 'POST',
    body: JSON.stringify(callBackDateTime),
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
