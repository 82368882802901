import { QuestionType, ratingCodeCollection, ratingType } from 'app/sm/helpers'

export const answerView = {
  stars: 'STARS',
  date: 'DATE',
  boolean: 'BOOLEAN',
  text: 'TEXT',
  textBlock: 'TEXT_BLOCK',
}

export const YES_CAT = 'Yes Cat'
export const YES_DOG = 'Yes Dog'
export const YES_OTHER = 'Yes Other'
export const NO_PETS = 'No Pets'
export const UN_SURE = 'Unsure'

export const march2021Questions = {
  namedonlease: {
    id: 'namedonlease',
    type: QuestionType.Boolean,
    description: (applicantName) =>
      `Is ${applicantName} named as a tenant on the lease or an occupant?`,
  },
  lastinspecteddate: {
    id: 'lastinspecteddate',
    type: QuestionType.Date,
    description: () => 'When was the property last inspected?',
    answerView: answerView.date,
  },
  tenanthaspets: {
    id: 'tenanthaspets',
    type: QuestionType.Text,
    description: (applicantName) =>
      `Did ${applicantName} have pets at the property?`,
    answerView: answerView.text,
  },
  petsauthorised: {
    id: 'petsauthorised',
    type: QuestionType.Boolean,
    description: () =>
      'Were the pets authorised by the owner or permitted by regulations?',
  },
  petsauthorisedcomments: {
    id: 'petsauthorisedcomments',
    type: QuestionType.Text,
    description: () => 'Please provide more details.',
    answerView: answerView.textBlock,
  },
  petsdamage: {
    id: 'petsdamage',
    type: QuestionType.Text,
    description: () =>
      'Was there any damage from the pets or any complaints about their pets?',
  },
  petsdamagecomments: {
    id: 'petsdamagecomments',
    type: QuestionType.Text,
    description: () => 'Please provide more details',
    answerView: answerView.textBlock,
  },
  rentontime: {
    id: 'rentontime',
    type: QuestionType.Text,
    description: () =>
      'Was the rent paid on time in the last 12 months, i.e. within 7 days of the due date?',
    answerView: answerView.textBlock,
  },
  reasonablecondition: {
    id: 'reasonablecondition',
    type: QuestionType.Boolean,
    description: (applicantName) =>
      `Did ${applicantName} keep the property in reasonable condition through the tenancy?`,
    options: {
      yes: {
        value: 'true',
      },
      no: {
        value: 'false',
      },
    },
  },
  reasonableconditioncomments: {
    id: 'reasonableconditioncomments',
    type: QuestionType.Text,
    description: () => 'If no, please provide more details about the condition',
    answerView: answerView.textBlock,
  },
  respectneighbours: {
    id: 'respectneighbours',
    type: QuestionType.Boolean,
    description: (applicantName) =>
      `Did ${applicantName} respect the neighbours peaceful enjoyment (free of complaints to owner/agent)?`,
  },
  respectneighbourscomments: {
    id: 'respectneighbourscomments',
    type: QuestionType.Text,
    description: () => 'If no, please provide details ',
    answerView: answerView.textBlock,
  },
  areasforconcerncomments: {
    id: 'areasforconcerncomments',
    type: QuestionType.Text,
    description: () =>
      'Describe any areas of concern during the tenancy (excluding bond claims)?',
    answerView: answerView.textBlock,
  },
  positivecomments: {
    id: 'positivecomments',
    type: QuestionType.Text,
    description: () => 'Describe the positive aspects of this tenancy?',
    answerView: answerView.textBlock,
  },
  overall: {
    id: 'overall',
    type: QuestionType.Int,
    description: (applicantName) => `Overall, how was ${applicantName}?`,
    answerView: answerView.stars,
  },
  tenancyterm: {
    id: 'tenancyterm',
    type: QuestionType.Text,
    description: () => 'Please confirm the Term of tenancy (months)',
    answerView: answerView.text,
  },
  rentalamount: {
    id: 'rentalamount',
    type: QuestionType.Text,
    description: () => 'Please confirm the Rental Amount',
    answerView: answerView.text,
  },
  rentagain: {
    id: 'rentagain',
    type: QuestionType.Text,
    description: (applicantName) => `Would you rent to ${applicantName} again?`,
    answerView: answerView.text,
    options: {
      yes: {
        value: 'Yes',
      },
      no: {
        value: 'No',
      },
      maybe: {
        value: 'Maybe',
      },
    },
  },
  rentagaincomments: {
    id: 'rentagaincomments',
    type: QuestionType.Text,
    description: (applicantName) =>
      `Please provide more information about renting to ${applicantName} again`,
    answerView: answerView.textBlock,
  },
  referencecontactpreferences: {
    id: 'referencecontactpreferences',
    type: QuestionType.Text,
    description: () => 'Please select your contact preference',
  },
  warranty: {
    id: 'warranty',
    type: QuestionType.Boolean,
    description: () =>
      'I warrant the information provided is accurate and reasonably represents the tenancy. I acknowledge that the tenant(s) may receive a copy of some or all information I have provided, as required by law, and has the right to dispute that information.',
    defaultValue: 'true',
  },
}

const showConditionallyWithTenantHasPets = (answers) => {
  const tenantHasPetsDef = march2021Questions.tenanthaspets
  const answer = answers.find((a) => a.code === tenantHasPetsDef.id)
  return answer && [YES_CAT, YES_DOG, YES_OTHER].includes(answer)
}

export const march2021ConditionalShownAnswers = {
  [march2021Questions.rentagaincomments.id]: (answers) => {
    const rentAgainDef = march2021Questions.rentagain
    const rentAgainAnswer = answers.find((a) => a.code === rentAgainDef.id)
    return (
      rentAgainAnswer &&
      rentAgainAnswer.answerValue !== rentAgainDef.options.yes.value
    )
  },
  [march2021Questions.reasonableconditioncomments.id]: (answers) => {
    const reasonableConditionDef = march2021Questions.reasonablecondition
    const answer = answers.find((a) => a.code === reasonableConditionDef.id)
    return (
      answer && answer.answerValue === reasonableConditionDef.options.no.value
    )
  },
  [march2021Questions.petsauthorisedcomments.id]:
    showConditionallyWithTenantHasPets,
  [march2021Questions.petsdamage]: showConditionallyWithTenantHasPets,
  [march2021Questions.petsdamagecomments]: showConditionallyWithTenantHasPets,
}

export const march2021TenancyPrivacyControlledQuestions = [
  march2021Questions.lastinspecteddate.id,
  march2021Questions.petsauthorised.id,
  march2021Questions.reasonablecondition.id,
  march2021Questions.reasonableconditioncomments.id,
  march2021Questions.respectneighbours.id,
  march2021Questions.respectneighbourscomments.id,
]

export const march2021QuickReferencePrivacyControlledQuestions = [
  march2021Questions.overall.id,
  march2021Questions.rentontime.id,
  march2021Questions.rentagain.id,
  march2021Questions.rentagaincomments.id,
  march2021Questions.positivecomments.id,
]

export const march2021PrivacyForcedQuestions = {
  //  id: isPrivate bool value
  [march2021Questions.areasforconcerncomments.id]: true,
}

export const march2021OverallQuestions = [
  march2021Questions.namedonlease.id,
  march2021Questions.overall.id,
  march2021Questions.rentagain.id,
  march2021Questions.rentagaincomments.id,
  march2021Questions.lastinspecteddate.id,
]

export const march2021TenancyQuestions = [
  march2021Questions.rentontime.id,
  march2021Questions.reasonablecondition.id,
  march2021Questions.reasonableconditioncomments.id,
  march2021Questions.respectneighbours.id,
  march2021Questions.respectneighbourscomments.id,

  // pets questions
  march2021Questions.tenanthaspets.id,
  march2021Questions.petsauthorised.id,
  march2021Questions.petsauthorisedcomments.id,
  march2021Questions.petsdamage.id,
  march2021Questions.petsdamagecomments.id,
]

export const ratingVersions = {
  missingVersion: {
    ref: 0,
    questions: {},
  },
  allVersions: {
    ref: 1,
    questions: {},
  },
  originalVersion: {
    ref: 2,
    questions: {},
    overallQuestions: ratingCodeCollection.overallQuestions,
    tenancyQuestions: ratingCodeCollection.tenancyQuestions,
    tenancyTermCode: ratingType.tenancyTerm,
    rentalAmountCode: ratingType.rentalAmount,
  },
  march2021: {
    ref: 3,
    questions: march2021Questions,
    overallQuestions: march2021OverallQuestions,
    tenancyQuestions: march2021TenancyQuestions,
    tenancyTermCode: march2021Questions.tenancyterm.id,
    rentalAmountCode: march2021Questions.rentalamount.id,
    conditionalShownAnswers: march2021ConditionalShownAnswers,
  },
  previousVersions: {
    ref: 2,
  },
}
