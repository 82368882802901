import React from 'react'

import styled from 'styled-components'

import applicationsImg from 'app/assets/icons/applications_img.png'
import checkmarkImg from 'app/assets/icons/checkmark.svg'
import liteActivityImg from 'app/assets/icons/lite-activity.png'
import messagesImg from 'app/assets/icons/messages_img.png'
import settingsImg from 'app/assets/icons/settings_img.png'
import viewingsImg from 'app/assets/icons/snug-welcome.png'
import {
  Heading,
  Text,
} from 'app/match/applicationReportPDF/components/LeadCaptureForm/styles'
import { Iframe } from 'app/match/leadToSnug/styles'
import SnugLogo from 'app/shared_components/snug_logo'
import * as accountHelpers from 'app/utils/accounts/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import * as widgetHelpers from 'app/utils/widgets/helpers'

const SnugUpgradeAccountContainer = styled.div`
  ${(props) =>
    props.isNotToBeEmbedded &&
    `position: absolute;
      top: 25%; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border: 1px solid #C4C4C4;
      width: 650px;
      min-height: 320px;
      left: 20%;
      padding: 64px 40px;`}
  ${(props) => !props.isNotToBeEmbedded && `padding: 20px 40px 64px 40px;`}
  ${(props) => props.showUpgradeForm && `height: fit-content; top: 5%;`}
  color: #000;
  background-color: #fff;
  @media (max-width: 576px) {
    width: 100%;
    height: fit-content;
    left: 0;
    top: 15%;
  }
`

const UpperPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  img {
    width: 230px;
    height: ${(props) => `${props.imgHeight}px`};
    margin-right: 24px;
  }
  .exciting-benefits {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }
  .info-text {
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    text-align: center;
    .exciting-benefits {
      margin-top: 16px;
    }
  }
`

const LowerPart = styled.div`
  text-align: center;
  margin-top: 24px;
  button {
    width: fit-content;
    height: 40px;
    font-size: 18px;
    line-height: 24px;
  }
`

class SnugUpgradeAccount extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showUpgradeForm: false,
    }
  }

  learnMoreAction = () => {
    widgetHelpers.openZendeskWidget()
    widgetHelpers.populateZendeskSuggestions('Learn more about Snug')
  }

  render() {
    const { isNotToBeEmbedded = true, typeOfMessage = '' } = this.props
    const { showUpgradeForm } = this.state
    let imageShown = viewingsImg
    let titleShown = 'Make open homes more efficient and effective'
    let textShown =
      'Create and manage viewings from your desktop or mobile. Check-in attendees, gather market feedback and sms the Apply Link from your Snug mobile webapp.'
    let imgHeight = '160px'
    switch (typeOfMessage) {
      case accountHelpers.UPGRADE_TYPE_MESSAGES: {
        imageShown = messagesImg
        titleShown =
          'Save hours every day with auto replies to portal enquiries'
        textShown =
          'Effortlessly drive inspection bookings, share your apply link and answer common questions automatically!'
        break
      }
      case accountHelpers.UPGRADE_TYPE_VIEWINGS: {
        imageShown = viewingsImg
        titleShown = 'Make open homes more efficient and effective'
        textShown =
          'Create and manage viewings from your desktop or mobile. Check-in attendees, gather market feedback and sms the Apply Link from your Snug mobile webapp.'
        break
      }
      case accountHelpers.UPGRADE_TYPE_APPLICATIONS: {
        imageShown = applicationsImg
        titleShown = 'Get higher quality applications'
        textShown =
          "Snug's easy use mobile friendly rental application form gathers and verifies: 100 points of ID, rental references, employment and income.  Plus automated communications keeps tenants informed through the process."
        break
      }
      case accountHelpers.UPGRADE_TYPE_SETTINGS: {
        imageShown = settingsImg
        titleShown = 'Customise Snug to best suit your business'
        textShown =
          'Settings empower you to get more from Snug, with application standards, viewing slots can caps for COVID, plus activate integrations.'
        break
      }
      case accountHelpers.UPGRADE_TYPE_ACTIVITY: {
        imageShown = liteActivityImg
        titleShown = 'Reports with real time insights'
        textShown =
          'Optimise your team and business with detailed reports on leasing performance and market conditions.'
        imgHeight = '300px'
        break
      }
      default: {
        imageShown = viewingsImg
        titleShown = 'Make open homes more efficient and effective'
        break
      }
    }

    return (
      <SnugUpgradeAccountContainer
        isNotToBeEmbedded={isNotToBeEmbedded}
        showUpgradeForm={showUpgradeForm}
      >
        {!showUpgradeForm ? (
          <div>
            <UpperPart imgHeight={imgHeight}>
              <div>
                <img src={imageShown} alt="welcome to snug" />
              </div>
              <div>
                <div className="exciting-benefits">{titleShown}</div>
                <div className="info-text">{textShown}</div>
              </div>
            </UpperPart>
            <LowerPart>
              <button
                className="width-100p"
                onClick={() => this.learnMoreAction()}
              >
                Learn More
              </button>
            </LowerPart>
          </div>
        ) : (
          <div>
            <SnugLogo height={40} wrapperClassName="text-align-center" />
            <Heading margin="18px 0 15px">
              Learn how Snug helps you lease faster
            </Heading>
            <Text>
              <img className="black-tick" src={checkmarkImg} alt="black-tick" />
              {textHelpers.MarketingPMCountPlus} property managers trust Snug.
            </Text>
            <Text>
              <img className="black-tick" src={checkmarkImg} alt="black-tick" />
              Find &#38; select top tenant, fast.
            </Text>
            <Iframe
              src="https://webforms.pipedrive.com/f/2TUOhkAeq4rt1QztpOgq97iPvbNDgMoiNpiYtLHoUZKtBK0nNUzf07VgLoG4bP5dx"
              width="100%"
              minHeight="656px"
            />
            <Text>{textHelpers.MarketingPMCountCaveatText}</Text>
          </div>
        )}
      </SnugUpgradeAccountContainer>
    )
  }
}

export default SnugUpgradeAccount
