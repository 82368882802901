export const convertYesOrNoToBool = (value) => {
  switch (value) {
    case 'Yes':
      return true
    case 'No':
      return false
    default:
      return null
  }
}

export const convertBoolToYesOrNo = (value) => {
  switch (value) {
    case true:
      return 'Yes'
    case false:
      return 'No'
    default:
      return null
  }
}
