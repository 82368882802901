import React, { useState } from 'react'

import { Field, Form, Formik, useField, useFormikContext } from 'formik'

import { IntlTelInputWrapper } from 'app/components/design-system-components'
import { ErrorMessage } from 'app/shared_components/helpers'
import {
  validateEmail,
  validateMobile,
  validateName,
} from 'app/shared_components/validations'

const formFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  mobile: 'mobile',
  confirmedMobile: 'confirmedMobile',
}

const TrimmedInput = ({
  field: { onBlur, name, ...field },
  form: { setFieldValue, errors, touched },
  ...props
}) => {
  const onBlurHandler = (e) => {
    setFieldValue(name, e.target.value.trim())
    onBlur(e)
  }

  return (
    <input
      className={errors[name] && touched[name] ? 'error' : ''}
      onBlur={onBlurHandler}
      name={name}
      {...field}
      {...props}
    />
  )
}

const MobileInput = ({ inputName }) => {
  const [field, meta, helpers] = useField(inputName)
  const { setValue, setTouched } = helpers
  return (
    <IntlTelInputWrapper
      css={['intl-tel-input country-list', 'register-form-mobile-input']}
      utilsScript={'libphonenumber.js'}
      defaultCountry={'au'}
      onPhoneNumberChange={(status, value, countryData, number, id) => {
        setValue(number, true)
      }}
      onPhoneNumberBlur={() => {
        setTouched(true, true)
      }}
      value={field.value}
      onlyCountries={['au']}
      placeholder={'+61 412 345 678'}
    />
  )
}

const validateRegistrationForm = (values) => {
  const validateField = (fieldName, val) => {
    switch (fieldName) {
      case formFields.firstName:
        return validateName(val, 'Invalid First Name')[0]
      case formFields.lastName:
        return validateName(val, 'Invalid Last Name')[0]
      case formFields.email:
        return validateEmail(val)[0]
      case formFields.mobile:
        return validateMobile(val)[0]
      case formFields.confirmedMobile:
        return val !== values[formFields.mobile] ? 'Mobile is mismatched' : ''
    }
  }

  return Object.entries(values).reduce((accErrors, [fieldName, val]) => {
    const fieldError = validateField(fieldName, val)
    return {
      ...accErrors,
      ...(fieldError ? { [fieldName]: fieldError } : {}),
    }
  }, {})
}

const FormBody = ({ responseError }) => {
  const formikContext = useFormikContext()
  const { errors, submitForm, isSubmitting, isValid, touched } = formikContext
  const getInputErr = (inputName) => touched[inputName] && errors[inputName]

  const responseErrorElement = responseError ? (
    <ErrorMessage error={responseError} />
  ) : null

  const formErrorsString = Object.keys(formFields)
    .map(getInputErr)
    .filter(Boolean)
    .join(', ')
  const formErrorsElement = formErrorsString ? (
    <ErrorMessage error={formErrorsString} />
  ) : null

  return (
    <Form>
      <div className="row">
        <div className="col-xs-6">
          <div className="input-box">
            <Field
              type="text"
              component={TrimmedInput}
              name={formFields.firstName}
              required
            />
            <label>First Name</label>
          </div>
        </div>
        <div className="col-xs-6">
          <div className="input-box">
            <Field
              type="text"
              component={TrimmedInput}
              name={formFields.lastName}
              required
            />
            <label>Last Name</label>
          </div>
        </div>
      </div>

      <div className="input-box">
        <Field
          // using type as text for this https://github.com/facebook/react/issues/14897
          type="text"
          component={TrimmedInput}
          name={formFields.email}
          required
        />
        <label>Email Address</label>
      </div>

      <div
        className={`input-box register-form-mobile-input-box ${
          getInputErr(formFields.mobile) ? 'error' : ''
        }`}
      >
        <MobileInput inputName={formFields.mobile} />
        <label>Mobile Number</label>
      </div>

      <div
        className={`input-box register-form-mobile-input-box ${
          getInputErr(formFields.confirmedMobile) ? 'error' : ''
        }`}
      >
        <MobileInput inputName={formFields.confirmedMobile} />
        <label>Confirmed Mobile Number</label>
      </div>

      {responseErrorElement}

      {formErrorsElement}

      <button
        className="mt15 mb30"
        onClick={submitForm}
        disabled={!isValid || isSubmitting}
      >
        Update Registration Info{' '}
        <i className={isSubmitting ? 'fa fa-spinner fa-pulse' : ''} />
      </button>
    </Form>
  )
}

export const UpdateRegisteredUserForm = ({
  user,
  onUpdateRegistrationInfo,
}) => {
  const [responseErr, setResponseErr] = useState('')

  if (!user?.guidID) return null
  const initialValues = {
    [formFields.firstName]: user.firstName,
    [formFields.lastName]: user.lastName,
    [formFields.email]: user.email,
    [formFields.mobile]: user.mobile,
    [formFields.confirmedMobile]: user.confirmedMobile,
  }

  const submitUpdatedInfo = (formValues) => {
    return onUpdateRegistrationInfo(formValues)
      .then(() => setResponseErr(''))
      .catch(({ message }) => setResponseErr(message))
  }

  return (
    <div className="panel panel-default">
      <div className="panel-body">
        <Formik
          initialValues={initialValues}
          onSubmit={submitUpdatedInfo}
          validate={validateRegistrationForm}
        >
          <FormBody responseError={responseErr} />
        </Formik>
      </div>
    </div>
  )
}
