import React from 'react'

import moment from 'moment'
import { Pie, PieChart } from 'recharts'

import { Button } from 'app/components/design-system-components'
import FAQs from 'app/shared_components/faqs'
import { GrossAnnualIncome } from 'app/shared_components/helpers'
import ProgressBar from 'app/sm/applications/components/progress_bar'
import {
  ApplicationCategory,
  calculateDuration,
  capitalizeFirstLetter,
  EmploymentTypes,
  EmploymentVerifyStatus,
  formatDurationInYearsMonths,
  formatLeaseTerm,
  getDurationInDays,
  isApplicantApplied,
  isEmploymentStatusVerified,
  isPrimaryApplicant,
  isSecondaryApplicant,
  isStatusApplied,
  isStatusQuickApplied,
  isStatusShortlisted,
  petTypes,
  relationshipTypesEmergencyContact,
  relationshipTypesTitles,
} from 'app/sm/helpers'
import DocumentListItem from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'
import RequestInfoModal from 'app/sm/renter_applications/renter_application_detailed/components/request-info-modal'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

export const BackgroundCheckProcessedDate = ({
  backgroundCheckDate = '',
  validityPeriodInMonths,
}) => (
  <div>
    {backgroundCheckDate && (
      <p className="mb10">
        Processed: {backgroundCheckDate} (valid for {validityPeriodInMonths}{' '}
        months).
      </p>
    )}
  </div>
)

const safe = (o) =>
  o
    ? Object.assign({}, o, { message: o.message || 'No Information Provided' })
    : { message: 'No Information Provided' }

//TODO:shaliu should be download
const printPage = () => window.print()

const formatOfferListDate = (propertyOffer) => {
  return ''
}

const formatOfferRent = (propertyOffer) => {
  if (!propertyOffer || !propertyOffer.weeklyRent) return ''
  const { weeklyRent } = propertyOffer
  return `$${weeklyRent} p/w`
}

const formatApplicationRent = (applicationRent, propertyOffer = {}) => {
  const { weeklyRent = 0 } = propertyOffer
  const priceDifference = Math.abs(applicationRent - weeklyRent)
  const priceText = applicationRent > weeklyRent ? 'more' : 'less'
  return `$${applicationRent} p/w ($${priceDifference} ${priceText})`
}

const formatOfferTerm = (propertyOffer) => {
  if (!propertyOffer || !propertyOffer.acceptedLeaseLength) return ''
  const { acceptedLeaseLength } = propertyOffer
  return `${acceptedLeaseLength} Months`
}

const formatOfferAvailableDate = (propertyOffer) => {
  if (!propertyOffer || !propertyOffer.availableFrom) return ''
  const { availableFrom } = propertyOffer
  const start = moment(availableFrom)
  const today = moment()
  return `${start.format('DD MMM')} (${today.diff(start, 'days')} days)`
}

const formatApplicationDate = (leaseStartDate, propertyOffer = {}) => {
  const { availableFrom } = propertyOffer
  const offerStartDate = moment(availableFrom)
  const applicationStartDate = moment(leaseStartDate)
  const dateDifferenceValue = Math.abs(
    applicationStartDate.diff(offerStartDate, 'days'),
  )
  const dateDifferenceText = applicationStartDate.isBefore(offerStartDate)
    ? 'early'
    : 'late'
  return `${applicationStartDate.format(
    'DD MMM YYYY',
  )} (${dateDifferenceValue} days ${dateDifferenceText})`
}

function RankPieChart(props) {
  const { rank, emptyFillColor } = props
  let fillColor = '#37c977'
  if (rank < 75) {
    fillColor = '#24C9D0'
  }
  if (rank < 50) {
    fillColor = '#579FFB'
  }
  if (rank < 25) {
    fillColor = '#ffe757'
  }
  const data = [
    { name: 'rank', value: rank, fill: fillColor },
    {
      name: 'blank',
      value: 100 - rank,
      fill: emptyFillColor,
      stroke: emptyFillColor,
    },
  ]
  return (
    <PieChart width={40} height={40}>
      <Pie
        dataKey="value"
        data={data}
        innerRadius={16}
        outerRadius={20}
        labelLine={false}
      />
    </PieChart>
  )
}

function AffordabilityPieChart(props) {
  const { percent } = props
  const data = [
    { name: 'affordability', value: percent, fill: 'url(#colorGradient)' },
    { name: 'blank', value: 100 - percent, fill: '#F3F3F3' },
  ]
  return (
    <PieChart width={120} height={68}>
      <defs>
        <linearGradient id="colorGradient" x1="1" y1="0" x2="0" y2="0">
          <stop offset="10%" stopColor="#FFA757" stopOpacity={1} />
          <stop offset="90%" stopColor="#FF5777" stopOpacity={1} />
        </linearGradient>
      </defs>
      <Pie
        startAngle={180}
        endAngle={0}
        cx={55}
        cy={60}
        dataKey="value"
        data={data}
        innerRadius={52}
        outerRadius={60}
        labelLine={false}
      />
    </PieChart>
  )
}

export function IconNameValue(props) {
  const { icon, name, value, link } = props
  const wrappedValue = link ? <a href={`${link}:${value}`}>{value}</a> : value
  return (
    <div className="flex-row-center pb10">
      {icon && <i className={`${icon} ibm`} />}
      <span className="ibm fw600 pr10 mra w45p">{name}:</span>
      <span className="ibm w65p">{wrappedValue}</span>
    </div>
  )
}

export const EmergencyContactsInfo = ({ emergencyContacts }) => {
  return emergencyContacts.length > 0 ? (
    <div>
      {emergencyContacts.map((e) => {
        return (
          <div className="pt20" key={e.guidID}>
            <IconNameValue name="First Name" value={e.firstName} />
            <IconNameValue name="Last Name" value={e.lastName} />
            <IconNameValue
              name="Relationship"
              value={relationshipTypesEmergencyContact[e.relationship]}
            />
            <IconNameValue name="Phone Number" value={e.phoneNumber} />
            <IconNameValue name="Email" value={e.email} />
            {e.comment && <IconNameValue name="Comment" value={e.comment} />}
          </div>
        )
      })}
    </div>
  ) : (
    <div className="pt20">No Information Provided</div>
  )
}

export function PetsInfo(props) {
  const { pets = [] } = props
  return (pets || []).length !== 0 ? (
    <div>
      {pets.map((pet) => {
        //TODO: fix hack

        const isBirthDateValid =
          pet?.dateOfBirth &&
          dateTimeHelpers.checkIfPetYOBIsValid(pet?.dateOfBirth)
        const petAge = moment().diff(pet.dateOfBirth, 'years')
        const outdoorAndIndoor = pet.outdoor && pet.indoor

        return (
          <div className="mt30" key={pet.guidID}>
            <span className="fw600 pr10">
              {pet.name}, {petTypes[pet.type]} ({pet.breed}),{' '}
              {isBirthDateValid && `${petAge} y.o.`}
            </span>
            {(pet.outdoor || pet.indoor) && (
              <span className="fw600 pr10">
                ({pet.outdoor && 'outdoor'}
                {outdoorAndIndoor ? '/' : ''}
                {pet.indoor && 'indoor'})
              </span>
            )}

            {(pet.petReferences || []).length > 0 &&
              pet.petReferences.map((petRef, i) => (
                <DocumentListItem document={petRef} key={i} />
              ))}
          </div>
        )
      })}
    </div>
  ) : (
    <div className="pt30">No Information Provided</div>
  )
}

export function IdentityDetails(props) {
  const { identityDoc } = props
  const {
    dateOfBirth,
    expiryDate,
    firstName,
    lastName,
    docType,
    docNumber,
    state,
    country,
    middleName,
  } = identityDoc || ''

  const docTypeMapping = (type) => {
    const validTypes = {
      1: 'Driver Licence',
      2: 'Passport',
    }

    return type && validTypes[type] ? validTypes[type] : ''
  }
  const docTypeString = docTypeMapping(docType)
  const dateOfBirthString = dateOfBirth
    ? moment(dateOfBirth).format('DD MMM YYYY')
    : ''
  const expiryDateString = expiryDate
    ? moment(expiryDate).format('DD MMM YYYY')
    : ''
  const years = moment().diff(dateOfBirth, 'years')
  const ageBinding = !Number.isNaN(years)
    ? `${dateOfBirthString} (${years} years old)`
    : ''
  const defaultCountryName = docType === 1 ? 'Australia' : ''

  return props.identityDoc ? (
    <div>
      <IconNameValue name="Data of Birth" value={ageBinding} />
      <IconNameValue name="Expiry Date" value={expiryDateString} />
      <IconNameValue name="First Name" value={firstName} />
      {middleName ? (
        <IconNameValue name="Middle Name" value={middleName} />
      ) : (
        <div className="ibm pr10 pb10">No Middle Name</div>
      )}
      <IconNameValue name="Last Name" value={lastName} />
      <IconNameValue name="Document Type" value={docTypeString} />
      <IconNameValue name="Document Number" value={docNumber} />
      <IconNameValue name="State" value={state} />
      <IconNameValue name="Country" value={country || defaultCountryName} />
    </div>
  ) : (
    <div className="pt20">No Information Provided</div>
  )
}

function HouseholdApplications(props) {
  const { applicants = [] } = props

  return (
    <div>
      {applicants.length > 0 &&
        applicants.map((a) => {
          return (
            <div className="mt30" key={a.guidID}>
              <IconNameValue name="First Name" value={a.firstName} />
              <IconNameValue name="Last Name" value={a.lastName} />
              <IconNameValue name="Email" value={a.email} />
              <IconNameValue name="Phone" value={a.mobileNumber} />
            </div>
          )
        })}
    </div>
  )
}

export function rentToIncomeRatioCalculator(applicants, weeklyRent) {
  //get employment income
  if (!applicants) {
    return
  }
  const tenantEmployersArrays = applicants.map((a) => a.employers)
  const tenantEmployersArray = [].concat(...tenantEmployersArrays)
  const employedIncomeSum = (n) =>
    n
      .filter((e) => e.stillEmployed)
      .map((em) => em.weeklyNetIncome)
      .reduce((a, b) => a + b, 0)
  const totalEmployedWeeklyNetIncome = employedIncomeSum(tenantEmployersArray)
  const ratio =
    totalEmployedWeeklyNetIncome === 0
      ? 'N/A'
      : ((weeklyRent / totalEmployedWeeklyNetIncome) * 100).toFixed(0)
  return ratio
}

export const PersonalReferencesInfo = ({ personalReferences }) => {
  return personalReferences.length > 0 ? (
    <div>
      {personalReferences.map((p) => {
        return (
          <div className="pt20" key={p.guidID}>
            <IconNameValue name="First Name" value={p.firstName} />
            <IconNameValue name="Last Name" value={p.lastName} />
            <IconNameValue
              name="Relationship"
              value={relationshipTypesTitles[p.relationship]}
            />
            <IconNameValue name="Phone Number" value={p.phoneNumber} />
            <IconNameValue name="Email" value={p.email} />
            {p.comment && <IconNameValue name="Comment" value={p.comment} />}
          </div>
        )
      })}
    </div>
  ) : (
    <div className="pt20">No Information Provided</div>
  )
}

function EmploymentDetails(props) {
  const { employer } = props
  const {
    employerName,
    jobTitle,
    contactName,
    phoneNumber,
    weeklyNetIncome,
    email,
    startDate,
    endDate,
    stillEmployed,
    verifyStatus,
    grossAnnualIncome,
    verifyDate,
    employmentType,
    address = {},
    officeNumber,
  } = employer || {}
  const duration = calculateDuration(startDate, endDate, stillEmployed)

  return employer ? (
    <div className="pt20">
      {employer && (
        <div className="table">
          <div className="trow">
            <div className="flex-row-center pb10">
              <span className="ibm fw600 pr10 mra w45p">
                <i className="fa fa-id-badge pt3 pr10" />
                Employer:
              </span>
              <span className="ibm w65p">{employerName}</span>
            </div>
            {/* Do not have this information currently */}
            {/* <div className="cell-t-100 pt10 pl20">2014 - current</div> */}
          </div>
          <div className="trow">
            <IconNameValue
              name="Employer address"
              value={`${address.friendlyName}, ${address.suburb}
              ${address.state} ${address.postcode}`}
            />
          </div>
          <div className="trow">
            <IconNameValue name="Job title" value={jobTitle} />
          </div>
          <div className="trow">
            <IconNameValue
              name="Employment type"
              value={EmploymentTypes[employmentType] || 'Not specified'}
            />
          </div>
          <div className="trow">
            <IconNameValue name="Duration" value={duration} />
          </div>
          <div className="trow">
            <IconNameValue
              name="Net weekly income"
              value={`$${weeklyNetIncome && weeklyNetIncome.toFixed(2)} ${
                !grossAnnualIncome
                  ? `(Annual: $${
                      weeklyNetIncome && (weeklyNetIncome * 52).toFixed(2)
                    })`
                  : ''
              }`}
            />
          </div>
          <GrossAnnualIncome
            grossAnnualIncome={grossAnnualIncome}
            tableRow={true}
          />
          <div className="trow">
            <IconNameValue name="Contact" value={contactName} />
          </div>
          <div className="trow">
            <IconNameValue name={'Email'} value={email} link="email" />
          </div>
          <div className="trow">
            <IconNameValue name={'Phone'} value={phoneNumber} link="tel" />
          </div>
          <div className="trow">
            <IconNameValue
              name={'Office phone'}
              value={officeNumber}
              link="tel"
            />
          </div>
          <div className="trow">
            <IconNameValue
              name="Status"
              value={
                isEmploymentStatusVerified(verifyStatus)
                  ? `Verified ${
                      verifyDate &&
                      `(${moment(verifyDate).format('HH:mm DD MMM YYYY')})`
                    }`
                  : EmploymentVerifyStatus[verifyStatus]
              }
            />
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="pt20">No Information Provided</div>
  )
}

function RentalHistoryDetails(props) {
  const { rentalHistory } = props

  return rentalHistory && rentalHistory.length > 0 ? (
    <div>
      {rentalHistory.map((i, key) => {
        const {
          startDate,
          endDate,
          isRollingLease,
          propertyArrangement = 0,
          ledger = [],
        } = i
        const duration = calculateDuration(startDate, endDate, isRollingLease)
        const owner = propertyArrangement === 5

        return (
          <div key={key} className="pt20">
            <div className="rental-history">
              <div className="rental-history-address">
                {!i.isCurrentAddress && (
                  <IconNameValue
                    name="Reason for Leaving"
                    value={i.reasonForLeaving || 'Not specified'}
                  />
                )}
                <IconNameValue
                  name="Address"
                  value={`${i.address.friendlyName}, ${i.address.suburb}
              ${i.address.state} ${i.address.postcode}`}
                />

                {!owner ? (
                  <IconNameValue name="Rent" value={`$${i.rate} / week`} />
                ) : (
                  <div className="pb10 ibm fw600">I owned the property</div>
                )}
                <IconNameValue name="Duration" value={duration} />
              </div>

              {!owner && (
                <div className="rental-history-manager">
                  <IconNameValue
                    name={i.managerType === 1 ? 'Manger' : 'Owner'}
                    value={`${i.firstName} ${i.lastName}`}
                  />
                  <IconNameValue name="Email" value={i.email} link="mailto" />
                  <IconNameValue
                    name="Phone"
                    value={i.phoneNumber}
                    link="tel"
                  />
                  <IconNameValue
                    name="Office phone"
                    value={i.officeNumber}
                    link="tel"
                  />
                  {i.managerType === 1 && (
                    <IconNameValue name="Agency" value={i.agencyName} />
                  )}
                </div>
              )}
              {ledger.map((attachmentItem, index) => (
                <DocumentListItem
                  document={attachmentItem}
                  key={index}
                  index={index}
                />
              ))}
            </div>
          </div>
        )
      })}
    </div>
  ) : (
    <div className="pt20">No Information Provided</div>
  )
}

function AttachmentDetails(props) {
  let { attachments } = props
  return attachments && attachments.length > 0 ? (
    <div className="style3">
      {attachments.map((attachmentItem, index) => (
        <DocumentListItem document={attachmentItem} key={index} />
      ))}
    </div>
  ) : (
    <div className="pt20">No Information Provided</div>
  )
}

const HouseholdSummary = ({
  score,
  ratio,
  history,
  type,
  people,
  pets = 0,
  aboutMe,
  requests,
}) => (
  <div className="household-summary flex-direction-column">
    <div className="display-flex">
      <div className="col">
        <div className="summary-row bigScreen-ml0">
          <span className="fw600">Match Score: </span>
          <span className="width-60">{score}</span>
        </div>
        <div className="summary-row bigScreen-ml0">
          <span className="fw600">Rent to Income: </span>
          <span className="width-60">{ratio}</span>
        </div>
        <div className="summary-row bigScreen-ml0">
          <span className="fw600">Rental History: </span>
          <span className="width-60">{history}</span>
        </div>
      </div>
      <div className="col">
        <div className="summary-row">
          <span className="fw600">Type: </span>
          <span className="width-60">{type}</span>
        </div>
        <div className="summary-row">
          <span className="fw600">People: </span>
          <span className="width-60">{people}</span>
        </div>
        <div className="summary-row">
          <span className="fw600">Pets: </span>
          <span className="width-60">{pets}</span>
        </div>
      </div>
    </div>
    <div className="summary-row bigScreen-ml0">
      <span className="summary-text fw600">About me: </span>
      <span className="width-60">
        {!!aboutMe ? aboutMe : 'No Information Provided'}
      </span>
    </div>
    <div className="summary-row bigScreen-ml0">
      <span className="summary-text fw600">Special requests: </span>
      <span className="width-60">
        {!!requests ? requests : 'No Information Provided'}
      </span>
    </div>
  </div>
)

// water down version of applications_card's ApplicantInfo
const ApplicantInfo = ({
  applicationStatus,
  active,
  applicant,
  index,
  handleApplicantChange,
  onClickRequestInfo,
}) => {
  const applicantFirstName =
    applicant.firstName.charAt(0).toUpperCase() + applicant.firstName.slice(1)
  const applicantLastName =
    applicant.lastName.charAt(0).toUpperCase() + applicant.lastName.slice(1)
  return (
    <div className={`household-applicant-row ${active ? 'active' : ''}`}>
      <div className="number">{index + 1}.</div>
      <div className="household-applicant-info" onClick={handleApplicantChange}>
        <div
          className="avatar"
          style={{ backgroundImage: `url(${applicant.avatar})` }}
        >
          {!applicant.avatar && (
            <span className="app-avatar-initial-position">
              {applicant.firstName.substring(0, 1)}
              {applicant.lastName.substring(0, 1)}
            </span>
          )}
        </div>

        <div>
          <h4>
            {applicantFirstName} {applicantLastName}
          </h4>
          <div>{applicant.mobileNumber}</div>
          <div>{applicant.email}</div>
        </div>
      </div>
      <div className="household-applicant-rank">
        <div className="rank-info chart" title="Snug rank">
          {applicant.applied && (
            <RankPieChart
              rank={Math.round(applicant.score)}
              emptyFillColor={active ? '#f3f3f3' : '#ffffff'}
            />
          )}
          <div className="rank-value fontSize-13">
            {applicant.applied ? Math.round(applicant.score) : 'N/A'}
          </div>
        </div>
        <div className="household-applicant-rank-completeness">
          {applicant.applied && (
            <ProgressBar value={applicant.profileCompleteness || 0} />
          )}
          {applicant.applied && (
            <i
              className="icon-help-outline fs16 gray-light-color pl15"
              data-tooltip-content="This bar shows the application's completeness"
            />
          )}
          {!applicant.applied && <div className="fontSize-13">N/A</div>}
        </div>
      </div>

      <div className="household-applicant-status">
        {isStatusApplied(applicationStatus) &&
          (applicant.applied ? 'Applied' : 'Invited')}
        {isStatusQuickApplied(applicationStatus) &&
          (applicant.isPrimary ? 'Interested' : '')}
      </div>
    </div>
  )
}

const HouseholdApplicantTable = ({
  applicationStatus,
  applicants,
  selectedApplicantGUID,
  handleApplicantChange,
  onClickRequestInfo,
}) => (
  <ul className="household-applicant-table">
    {applicants
      .sort((a, b) => {
        if (a.isPrmary) return -1
        else if (b.isPrimary) return 1
        else return 0
      })
      .map((applicant, index) => (
        <li key={applicant.guidID}>
          <ApplicantInfo
            active={selectedApplicantGUID === applicant.guidID}
            applicationStatus={applicationStatus}
            applicant={applicant}
            index={index}
            handleApplicantChange={() =>
              handleApplicantChange(applicant.guidID)
            }
            onClickRequestInfo={onClickRequestInfo}
          />
        </li>
      ))}
  </ul>
)

class ApplicantDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      generatingPDF: false,
      generatedPDFURL: '',
      isRequestInfoModalActive: false,
    }
  }

  componentDidMount() {
    document
      .querySelector('.app-content')
      .addEventListener('scroll', this.handleScroll)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.isRequestInfoDone) {
      this.setState({ isRequestInfoModalActive: false })
      this.props.toggleSendRequestInfo()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.application !== this.props.application ||
      prevProps.selectedApplicantGUID !== this.props.selectedApplicantGUID
    ) {
      if (
        this.props.application &&
        this.props.application.guidID &&
        this.props.selectedApplicantGUID &&
        this.state.generatedPDFURL === '' &&
        !this.initialDownloadInvoked
      ) {
        this.generateApplicationPDF()
        this.initialDownloadInvoked = true
      }
    }
  }

  componentWillUnmount() {
    document
      .querySelector('.app-content')
      .removeEventListener('scroll', this.handleScroll)
  }

  onClickDownloadPDF = () => {
    if (this.state.generatedPDFURL === '') {
      this.generateApplicationPDF().then(() => {
        window.open(this.state.generatedPDFURL, '_blank')
      })
    } else if (this.state.generatedPDFURL) {
      window.open(this.state.generatedPDFURL, '_blank')
    }
  }

  onClickInviteToApply = () => {
    const { application = {}, primaryApplicant = {} } = this.props
    if (
      window.confirm(
        `Do you want to request application from ${primaryApplicant.firstName} ${primaryApplicant.lastName}?`,
      )
    ) {
      this.props.requestRenterInformation(
        application.guidID,
        this.props.selectedApplicantGUID,
        {
          fullApplication: true,
          employment: [
            { name: 'Payslips', value: false },
            { name: 'Employment Contract', value: false },
            { name: 'Letter from Employer', value: false },
          ],
          identification: [
            { name: 'Background Check', value: false },
            { name: 'Visa Details', value: false },
            { name: 'Identity documents', value: false },
          ],
          rentalHistory: [{ name: 'Rental payment ledger', value: false }],
          other: false,
          message: '',
        },
      )
    }
  }

  compareDates = (date) => {
    date = new Date(date).getTime()
    const currentDate = new Date().getTime()

    // If the date is in the future
    if (date > currentDate) {
      return 1
    }

    // If the date is in the past
    if (date < currentDate) {
      return -1
    }

    // If the dates are equal
    return 0
  }

  confirmAction(action, text, guidID) {
    if (window.confirm(`Are you sure you want to ${text} this offer?`)) {
      this.props.onStatusDropdownChange(action, guidID, 'Applications')
    }
  }

  confirmOfferApplication(guidID) {
    if (
      window.confirm(
        'Are you sure you wish to offer the property to this renter? We will send them the offer if you confirm. ',
      )
    ) {
      this.props.onStatusDropdownChange('offer', guidID, 'Offered')
    }
  }

  generateApplicationPDF = () => {
    if (
      this.props.application &&
      this.props.application.guidID &&
      this.props.selectedApplicantGUID
    ) {
      this.setState({ generatingPDF: true })
      return this.props
        .getApplicationPDF(
          this.props.application.guidID,
          this.props.selectedApplicantGUID,
        )
        .then((url) => {
          this.setState({ generatingPDF: false, generatedPDFURL: url })
          return Promise.resolve()
        })
        .catch((error) => {
          this.setState({ generatingPDF: false, generatedPDFURL: '' })
          return Promise.reject('No application or applicant GUID')
        })
    }
  }

  toggleRequestInfoModal = () => {
    this.setState({
      isRequestInfoModalActive: !this.state.isRequestInfoModalActive,
    })
  }

  render() {
    const {
      propertyOffer = { isActive: false },
      application,
      primaryApplicant = {},
      currentEmployers = [],
      previousEmployers = [],
      currentAddress = [],
      previousAddress = [],
      proofOfIncome,
      rentalHistory,
      identityDoc,
      employers = [],
      backgroundCheck,
      attachments,
      offerOrDeclineButtonClicked,
      identityDocAttachments,
      pets = [],
      error,
      applicationTenants = [],
      emergencyContacts = [],
      personalReferences = [],
      completeness,
      renter = {},
      reputationPreference = {},
    } = this.props
    const {
      optOutBackgroundCheck = false,
      optOutEmploymentHistory = false,
      optOutRentalHistory = false,
    } = reputationPreference
    const {
      leaseStartDate,
      weeklyRent,
      term,
      status,
      group,
      people,
      requests,
      guidID,
      pets: numOfPets,
      submittedDate,
      hasAcceptedCondition,
      aboutMe,
      applicants,
    } = application || ''
    const {
      asicCompanyCheck,
      banckruptcyCheck,
      identityVerification,
      tenanacyBlacklistCheck,
      attachedToProfile,
    } = backgroundCheck || false
    let { Payslips, BankStatements } = proofOfIncome || []
    Payslips = Payslips ? Payslips : []
    BankStatements = BankStatements ? BankStatements : []

    const backgroundCheckDate =
      backgroundCheck.updatedAt &&
      backgroundCheck.updatedAt !== '0001-01-01T00:00:00Z'
        ? moment(backgroundCheck.updatedAt)
            .format('hh:mm A DD MMM YYYY')
            .toUpperCase()
        : ''
    const proofOfIncomeList = [...Payslips, ...BankStatements]
    const checks = [
      {
        parole: 'Identify verification',
        message: safe(backgroundCheck.identityVerification).message,
        check: safe(backgroundCheck.identityVerification).result,
      },
      {
        parole: 'Tenancy blacklist check',
        message: safe(backgroundCheck.tenanacyBlacklistCheck).message,
        check: safe(backgroundCheck.tenanacyBlacklistCheck).result,
      },
      {
        parole: 'Bankruptcy & court record check',
        message: safe(backgroundCheck.banckruptcyCheck).message,
        check: safe(backgroundCheck.banckruptcyCheck).result,
      },
      {
        parole: 'ASIC company directorship check',
        message: safe(backgroundCheck.asicCompanyCheck).message,
        check: safe(backgroundCheck.asicCompanyCheck).result,
      },
    ]

    const { isRequestInfoModalActive } = this.state
    const isThereAnotherApplicantThatAcceptedTheOffer = !propertyOffer.isActive
    const selectedApplicant = application
      ? application.applicants.find(
          (applicant) => applicant.guidID === this.props.selectedApplicantGUID,
        )
      : undefined

    // calculate rental history
    const totalDurationInDays = (rentalHistory || []).reduce((acc, cur) => {
      return (
        acc + getDurationInDays(cur.startDate, cur.endDate, cur.isRollingLease)
      )
    }, 0)
    const totalRentalHistory = formatDurationInYearsMonths(totalDurationInDays)
    const ratio = rentToIncomeRatioCalculator(applicants, weeklyRent)

    const hasEmploymentHistory =
      currentEmployers.length + previousEmployers.length !== 0
    const hasAddressHistory =
      currentAddress.length + previousAddress.length !== 0
    return (
      <div className="sm-applications-detailed-applicant-details">
        <div className="two-col-box sm6535">
          <div className="col-first xs-second">
            <HouseholdSummary
              score={Math.round(primaryApplicant.score)}
              ratio={ratio ? ratio + '%' : '0%'}
              history={totalRentalHistory}
              type={group}
              people={people}
              pets={numOfPets}
              aboutMe={aboutMe}
              requests={requests}
            />

            {application && (
              <HouseholdApplicantTable
                applicationStatus={ApplicationCategory[application.status]}
                selectedApplicantGUID={this.props.selectedApplicantGUID}
                applicants={application.applicants}
                handleApplicantChange={this.props.handleApplicantChange}
                onClickRequestInfo={this.toggleRequestInfoModal}
              />
            )}

            {selectedApplicant && (
              <div className="profile-section-header">
                <h4>
                  Snug Profile:{' '}
                  <span>
                    {selectedApplicant.firstName} {selectedApplicant.lastName}
                  </span>
                </h4>
                <Button
                  onClick={this.toggleRequestInfoModal}
                  variant="linkBlueUnderlined"
                >
                  {isStatusQuickApplied(ApplicationCategory[status])
                    ? 'Request Application'
                    : 'Request Info'}
                </Button>
              </div>
            )}

            {selectedApplicant &&
              ((isPrimaryApplicant(selectedApplicant) &&
                isStatusQuickApplied(ApplicationCategory[status]) === false) ||
                (isSecondaryApplicant(selectedApplicant) &&
                  isApplicantApplied(selectedApplicant))) && (
                <div className="profile-section">
                  <div className="content-wrapper bbs">
                    <h4 className="pb20">Identity Details</h4>
                    <IdentityDetails identityDoc={identityDoc} />
                    <h4 className="pb20 mt20">Identity Documents</h4>
                    <AttachmentDetails attachments={identityDocAttachments} />
                  </div>

                  <div className="content-wrapper bbs">
                    <h4 className="pb20">Current Employment</h4>
                    {currentEmployers.map((employer) => (
                      <EmploymentDetails
                        key={employer.guidID}
                        employer={employer}
                      />
                    ))}

                    {currentEmployers.length === 0 && (
                      <div className="pt20">No Information Provided</div>
                    )}

                    <h4 className="pb20 mt50">Previous Employment</h4>
                    {previousEmployers.map((employer) => (
                      <EmploymentDetails
                        key={employer.guidID}
                        employer={employer}
                      />
                    ))}

                    {previousEmployers.length === 0 && (
                      <div className="pt20">No Information Provided</div>
                    )}

                    {optOutEmploymentHistory && hasEmploymentHistory && (
                      <h4 className="pb20 mt50">
                        {capitalizeFirstLetter(selectedApplicant.firstName)} has
                        no further employment history
                      </h4>
                    )}

                    <h4 className="pb20 mt50">Proof of Income</h4>
                    {proofOfIncomeList &&
                      proofOfIncomeList.length > 0 &&
                      proofOfIncomeList.map((proofOfIncomeListItem, index) => (
                        <DocumentListItem
                          document={proofOfIncomeListItem}
                          key={index}
                        />
                      ))}
                  </div>

                  <div className="content-wrapper bbs">
                    <h4 className="pb20">Disclosures</h4>
                    {hasAcceptedCondition ? (
                      <div>
                        Has disclosed they have viewed the property and accept
                        it's condition.
                      </div>
                    ) : (
                      <div>Has not viewed the property</div>
                    )}
                    {renter.disclosureURL ? (
                      <DocumentListItem
                        document={{
                          name: 'disclosure.pdf',
                          URL: renter.disclosureURL,
                        }}
                      />
                    ) : (
                      <div className="pt20">No Information Provided</div>
                    )}
                  </div>

                  <div className="content-wrapper bbs">
                    <h4 className="pb10">Pets</h4>
                    <PetsInfo pets={pets} />
                  </div>

                  <div className="content-wrapper bbs">
                    <h4 className="pb20">Attachments</h4>
                    <AttachmentDetails attachments={attachments} />
                  </div>

                  <div className="content-wrapper bbs">
                    <h4 className="pb20">Current Address</h4>
                    <RentalHistoryDetails rentalHistory={currentAddress} />
                    <h4 className="pb20 mt50">Previous Address</h4>
                    <RentalHistoryDetails rentalHistory={previousAddress} />
                    {hasAddressHistory && optOutRentalHistory && (
                      <h4 className="pb20 mt50">
                        {capitalizeFirstLetter(selectedApplicant.firstName)} has
                        no further address history
                      </h4>
                    )}
                  </div>

                  <div className="content-wrapper bbs">
                    <h4 className="pb20">Personal References</h4>
                    <PersonalReferencesInfo
                      personalReferences={personalReferences}
                    />
                  </div>

                  <div className="content-wrapper bbs">
                    <h4 className="pb20">Emergency Contacts</h4>
                    <EmergencyContactsInfo
                      emergencyContacts={emergencyContacts}
                    />
                  </div>

                  <div className="content-wrapper">
                    <h4 className="pb20">Background Check</h4>
                    {attachedToProfile &&
                      backgroundCheckDate &&
                      !optOutBackgroundCheck && (
                        <BackgroundCheckProcessedDate
                          backgroundCheckDate={backgroundCheckDate}
                          validityPeriodInMonths={
                            backgroundCheck.validityPeriodInMonths
                          }
                        />
                      )}
                    {optOutBackgroundCheck ? (
                      <p>
                        {selectedApplicant.firstName} has chosen not to include
                        a background check.
                      </p>
                    ) : attachedToProfile ? (
                      <FAQs
                        type="backgroundCheck"
                        checks={checks}
                        faq1Init={false}
                        faq2Init={false}
                        faq3Init={false}
                        faq4Init={false}
                      />
                    ) : (
                      <p>
                        {selectedApplicant.firstName} has chosen to include a
                        background check but has not completed it.
                      </p>
                    )}
                  </div>
                </div>
              )}
          </div>

          <div className="col-second xs-first">
            <div className="sm-applications-detailed-aside">
              {/* TODO:shaliu use real data */}
              <div className="advertised">
                <h5>Advertised</h5>
                <div className="pt15 pb10">
                  <i className="icon-rent ibm" />
                  <span className="ibm fw600 pl10 pr10">Rent:</span>
                  <span className="ibm">
                    {formatOfferRent(this.props.propertyOffer)}
                  </span>
                </div>
                <div className="pb10">
                  <i className="icon-date ibm" />
                  <span className="ibm fw600 pl10 pr10">Term:</span>
                  <span className="ibm">
                    {formatOfferTerm(this.props.propertyOffer)}
                  </span>
                </div>
                <div className="pb10">
                  <i className="icon-login ibm" />
                  <span className="ibm fw600 pl10 pr10">Available:</span>
                  <span className="ibm">
                    {formatOfferAvailableDate(this.props.propertyOffer)}
                  </span>
                </div>
              </div>

              <div className="offer">
                <h5>Application</h5>
                <div className="pt15 pb10">
                  <i className="icon-rent ibm" />
                  <span className="ibm fw600 pl10 pr10">Rent:</span>
                  <span className="ibm">
                    {/* ${weeklyRent} p/w */}
                    {formatApplicationRent(
                      weeklyRent,
                      this.props.propertyOffer,
                    )}
                  </span>
                </div>
                <div className="pb10">
                  <i className="icon-date ibm" />
                  <span className="ibm fw600 pl10 pr10">Term:</span>
                  <span className="ibm">{formatLeaseTerm(term)} months</span>
                </div>
                <div className="pb10">
                  <i className="icon-login ibm" />
                  <span className="ibm fw600 pl10 pr10">Start:</span>
                  {/* <span className="ibm">{leaseStartDate && moment(leaseStartDate).format('DD MMM YYYY')}</span> */}
                  <span className="ibm">
                    {leaseStartDate &&
                      formatApplicationDate(
                        leaseStartDate,
                        this.UNSAFE_componentWillReceiveProps.propertyOffer,
                      )}
                  </span>
                </div>
              </div>
              <div className="controls">
                {!isThereAnotherApplicantThatAcceptedTheOffer && (
                  <span>
                    {(status === 1 || status === 2) && (
                      <button
                        className="btn"
                        onClick={() => this.confirmOfferApplication(guidID)}
                      >
                        Offer
                      </button>
                    )}
                    {(status === 1 || status === 2) && (
                      <button
                        className="btn btn-gray"
                        onClick={() =>
                          this.confirmAction('decline', 'decline', guidID)
                        }
                      >
                        Decline
                      </button>
                    )}
                    {/* {
                      ApplicationCategory[status] !== 'Accepted' &&
                      <button
                        className="btn btn-gray p0"
                        onClick={isStatusQuickApplied(ApplicationCategory[status]) ? this.onClickInviteToApply : this.toggleRequestInfoModal}>
                      {isStatusQuickApplied(ApplicationCategory[status]) ? 'Invite to Apply' : 'Request Information'}
                      </button>
                    } */}
                  </span>
                )}
                {(isStatusApplied(ApplicationCategory[status]) ||
                  isStatusShortlisted(ApplicationCategory[status])) &&
                  selectedApplicant &&
                  selectedApplicant.applied &&
                  this.state.generatingPDF === false && (
                    <div
                      className="linkStyle-blue"
                      onClick={this.onClickDownloadPDF}
                    >{`Download ${selectedApplicant.firstName}'s Application`}</div>
                  )}
                {this.state.generatingPDF && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <i className="fa fa-spinner fa-pulse" />
                  </div>
                )}
              </div>
              {(ApplicationCategory[status] === 'Offered' ||
                ApplicationCategory[status] === 'Declined') && (
                <div
                  className={
                    status === 3 ? 'alert alert-success' : 'alert alert-danger'
                  }
                >
                  {ApplicationCategory[status] === 'Offered'
                    ? 'Offered'
                    : 'Declined'}
                </div>
              )}

              {ApplicationCategory[status] === 'Offered' && (
                <div className="controls pt0">
                  <button
                    className="btn btn-red btn-light controls-applicant-details-btn"
                    onClick={() =>
                      this.confirmAction('withdrawoffer', 'withdraw', guidID)
                    }
                  >
                    Withdraw offer
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {isRequestInfoModalActive && selectedApplicant && (
          <RequestInfoModal
            title="Request Information"
            error={error}
            firstName={selectedApplicant.firstName}
            lastName={selectedApplicant.lastName}
            toggleModal={this.toggleRequestInfoModal}
            requestRenterInformation={(content) => {
              this.props.requestRenterInformation(
                guidID,
                this.props.selectedApplicantGUID
                  ? this.props.selectedApplicantGUID
                  : primaryApplicant.guidID,
                content,
              )
            }}
          />
        )}
      </div>
    )
  }
}

export default ApplicantDetails
