import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import warningIcon from 'app/assets/icons/warning-lighter.svg'
import BallotHouseholdIncomeModal from 'app/components/Ballot/Modals/BallotHouseholdIncomeModal'
import {
  Alert,
  Box,
  Button,
  Flex,
} from 'app/components/design-system-components'
import { GreySubheading } from 'app/components/display/text/standard_text/standard-headings/component'
import * as Form from 'app/components/forms/forms'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { incomeLimitFromGroup } from 'app/sm/apply/apply_helper'
import { urlTo } from 'app/sm/helpers'

const HouseholdYearlyIncome = ({
  application = {},
  onChangeYearlyHouseholdIncome,
  ballotsHouseholdIncomeLimit,
  ballotsHouseholdIncomeRegion,
  teamSlug = '',
}) => {
  const [eligibleError, setEligibleError] = useState('')
  const [ballotHousingIncomeModalOpen, setBallotHousingIncomeModalOpen] =
    useState(false)

  const togglleBallotHousingIncomeModalOpen = () => {
    setBallotHousingIncomeModalOpen(!ballotHousingIncomeModalOpen)
  }

  const _renderExceedIncomeHousehold = () => {
    const limit = incomeLimitFromGroup(
      application.group,
      ballotsHouseholdIncomeLimit,
    )

    if (limit && application.householdYearlyIncome > limit) {
      return (
        <Alert variant="warningWithBg" mt={5} mb={3}>
          <Flex alignItems="center" justifyContent="space-between">
            <img
              data-tooltip-content=""
              className="icon"
              src={warningIcon}
              height="16"
              width="16"
              alt={'icon'}
            />
            <Box ml={4}>
              Unfortunately your household income exceeds the eligibility
              threshold for this property (
              <Button
                variant="linkBlueWithoutWeight"
                onClick={() => togglleBallotHousingIncomeModalOpen()}
                style={{ fontSize: '16px' }}
              >
                learn more
              </Button>
              ). Please view other listings with this property manager (
              <a
                target="_blank"
                href={urlTo('teamPublicPage', { teamSlug })}
                rel="noreferrer"
              >
                link
              </a>
              )
            </Box>
          </Flex>
          {ballotHousingIncomeModalOpen && (
            <BallotHouseholdIncomeModal
              ballotsHouseholdIncomeLimit={ballotsHouseholdIncomeLimit}
              toggleBallotHouseHoldModalIncome={
                togglleBallotHousingIncomeModalOpen
              }
              ballotsHouseholdIncomeRegion={ballotsHouseholdIncomeRegion}
            />
          )}
        </Alert>
      )
    } else {
      return <></>
    }
  }

  useEffect(() => {
    const limit = incomeLimitFromGroup(
      application.group,
      ballotsHouseholdIncomeLimit,
    )
    if (limit <= 0) {
    } else {
      if (application.householdYearlyIncome > limit) {
        setEligibleError('Not eligible')
      } else {
        setEligibleError('')
      }
    }
  }, [
    application.householdYearlyIncome,
    ballotsHouseholdIncomeLimit,
    application.group,
  ])

  return (
    <div className="row p24 mr0 ml0 mobile-apply-padding">
      <GreySubheading
        text={'Household yearly income'}
        componentClass="text-area-label"
      />
      <Box mb={3}>
        Total gross income, before tax for all household members over 18.
      </Box>
      <Flex alignItems="center">
        <Box
          fontSize={`${theme.fontSizes[9]}px`}
          mr={2}
          fontWeight={theme.fontWeights[6]}
        >
          $
        </Box>
        <Form.InputNumber
          error={eligibleError}
          value={application.householdYearlyIncome}
          onChange={onChangeYearlyHouseholdIncome}
          rows="4"
          cols="70"
          placeholder="e.g. 50000"
        />
      </Flex>

      {_renderExceedIncomeHousehold()}
    </div>
  )
}

export default HouseholdYearlyIncome
