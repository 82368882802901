import React, { Component } from 'react'

import moment from 'moment'

import { Modal, ModalButtonGroup } from 'app/components/display/display'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import { RenterRows } from 'app/pages/teams/viewings/styles'
import * as snugNotifier from 'app/services/snugNotifier'
import * as sharedHelper from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import SnugUpgradeAccountModal from 'app/shared_components/snug_account_upgrade/modal'
import {
  noteActionType,
  noteOwnerType,
  targetSectionObject,
  urlTo,
} from 'app/sm/helpers'
import AddAccessDetailModal from 'app/sm/inspections/components/add_access_details_modal_connection'
import AddCommentModal from 'app/sm/inspections/components/add_comment_modal'
import AddNoteModal from 'app/sm/inspections/components/add_notes_modal_connection'
import AttendeeHubModal from 'app/sm/inspections/components/attendee_hub_modal'
import EditViewingTime from 'app/sm/inspections/components/edit_viewing_time_modal'
import LateForViewingModal from 'app/sm/inspections/components/late_for_viewing_modal'
import { RenderRenterRows } from 'app/sm/inspections/components/owner-list'
import SendSmsReportModal from 'app/sm/inspections/components/send_sms_report_modal_connection'
import { ViewingContextMenu } from 'app/sm/inspections/components/viewing_context_menu'
import WalkinRenterModal from 'app/sm/inspections/components/walkin_renter_modal'
import ViewingRegistrantsMessagesModal from 'app/sm/inspections/viewing_registrants_message_modal/ViewingRegistrantsMessagesModal'
import * as accountHelpers from 'app/utils/accounts/helpers'
import { getNotePayloadForShortlistViewingRegistrant } from 'app/utils/notes/helpers'
import SendEmailReportButton from 'app/utils/viewings/components/SendEmailReportButton'
import SendSMSReportButton from 'app/utils/viewings/components/SendSMSReportButton'
import * as viewingsHelpers from 'app/utils/viewings/helpers'

class ViewingDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredEnquirersForAttendeeHub: [],
      totalCheckedInAttendeeCount: 0,
      apiError: '',
      requestFeedbackError: '',
      propertyViewings: [],
      viewingAttendeeCount: 0,
      walkinModalStatus: 'edit',
      targetAttendee: {},
      enquirers: [],
      propertyInfo: {},
      contactCount: 0,
      addAttendeePreloadContent: {},
      chosenPropertyID: '',
      isLateModalOn: false,
      isWalkInModalOn: false,
      isCommentModalOn: false,
      isEditViewModalOn: false,
      isAddAttendeeHubModalOn: false,
      isSendSmsReportModalOn: false,
      activeViewingID: '',
      isViewingSlotEnabled: false,
      disableSaveButton: false,
      showUpgradeAccountModal: false,
      isAddAttendeeWithOutSlotModalOn: false,
      selectedFullyBookedViewing: {},
      defaultSelectedViewingDurationEnabled: false,
      defaultSelectedViewingDuration: 0,
      propertyViewing: {},
      showViewingRegistrantsMessageModal: false,
    }
    // noinspection DuplicatedCode
    this.onShowLateViewingModal = this.onShowLateViewingModal.bind(this)
    this.onModalTitleClicked = this.onModalTitleClicked.bind(this)
    this.turnOffAllModals = this.turnOffAllModals.bind(this)
  }

  componentDidMount() {
    const {
      currentTeam,
      fetchTeamSettingInfo,
      fetchPropertyViewings,
      startDate,
      activeViewingID,
      summary: { propertyGUID },
    } = this.props
    if (currentTeam.guid) {
      fetchTeamSettingInfo(currentTeam.guid, 'viewings')
        .then(({ viewings }) => {
          const {
            viewing_slot_enabled: isViewingSlotEnabled = 'false',
            default_selected_viewing_duration_enabled:
              defaultSelectedViewingDurationEnabled = 'false',
            default_selected_viewing_duration:
              defaultSelectedViewingDuration = 15,
          } = viewings
          this.setState({
            isViewingSlotEnabled: isViewingSlotEnabled === 'true',
            defaultSelectedViewingDurationEnabled:
              defaultSelectedViewingDurationEnabled === 'true',
            defaultSelectedViewingDuration: +defaultSelectedViewingDuration,
          })
        })
        .catch((error) => {
          this.setState({ error })
        })
    }
    fetchPropertyViewings({ propertyId: propertyGUID }).then(
      (propertyViewings) => {
        const selectedViewing = propertyViewings.find(
          (pv) => pv.viewing.guidID === activeViewingID,
        )
        this.setState({ propertyViewings, propertyViewing: selectedViewing })
      },
    )
  }

  clearAddAttendeePreloadContent = () => {
    this.setState({
      addAttendeePreloadContent: {},
    })
  }

  onAddWalkInRenter = (
    payload,
    propertyId,
    viewingId = this.props.summary.viewingGUID,
    shouldSendInvite = false,
  ) => {
    this.setState({
      disableSaveButton: true,
    })
    const { addWalkInRenter, updateAllViewingsData } = this.props
    if (shouldSendInvite) {
      const { checkIn } = payload
      if (!checkIn) {
        snugNotifier.error(`Please Check-in attendee then Invite to Apply`)
        return
      }
    }
    setTimeout(() => {
      addWalkInRenter(payload, propertyId, viewingId)
        .then((data) => {
          const { registrant } = data
          this.setState({
            viewingAttendeeCount: this.state.viewingAttendeeCount + 1,
            apiError: '',
            registrant,
            disableSaveButton: false,
            isWalkInModalOn: false,
            isAddAttendeeHubModalOn: true,
          })
          snugNotifier.success('Added successfully')
          if (shouldSendInvite && data) {
            const { firstName } = registrant
            this.sendInviteToApplyToRegistrant(registrant, firstName).then(
              () => {
                snugNotifier.success('Invite requested')
              },
            )
          }
        })
        .then(() => {
          if (payload && payload.externalComment) {
            const { registrant } = this.state
            const notePayload = {
              content: payload.externalComment,
              type: noteActionType.viewingAttendee,
              target: targetSectionObject.viewingReport['index'],
            }
            this.callCreateNote(notePayload, registrant.guidID)
          }
        })
        .then(() => {
          updateAllViewingsData()
        })
        .catch((error) => {
          snugNotifier.error(error)
          this.setState({
            disableSaveButton: false,
          })
        })
    }, 200)
    return Promise.resolve()
  }

  onAttendeeHubModalAddIconClicked = (
    nameSearchText = '',
    mobileSearchText = '',
  ) => {
    const addAttendeePreloadContent = { nameSearchText, mobileSearchText }
    this.setState({
      isWalkInModalOn: true,
      walkinModalStatus: 'create',
      isAddAttendeeHubModalOn: false,
      addAttendeePreloadContent,
    })
  }

  onClickDisabledLiteAccountOption = () => {
    const { showUpgradeAccountModal } = this.state
    this.setState({
      showUpgradeAccountModal: !showUpgradeAccountModal,
    })
  }

  onEditViewing = (
    propertyId,
    offerId,
    viewingId,
    startdate,
    duration,
    viewingOptions,
  ) => {
    const { editViewing, summary, updateAllViewingsData } = this.props
    editViewing(
      propertyId,
      summary.offerGUID,
      summary.viewingGUID,
      startdate,
      duration,
      false,
      viewingOptions,
    )
      .then(() => {
        this.setState({ isEditViewModalOn: false })
        snugNotifier.success('Successfully updated viewing')
        updateAllViewingsData()
        snugNotifier.success('Successfully updated viewing')
      })
      .catch((error) => {
        if (
          error === ErrorMessages[Errors.ErrorViewingTimeExistsForProperty] ||
          error === ErrorMessages[Errors.ErrorViewingTimeExistsForManager]
        ) {
          if (window.confirm(viewingsHelpers.conflictingTimeMessage)) {
            editViewing(
              propertyId,
              summary.offerGUID,
              summary.viewingGUID,
              startdate,
              duration,
              true,
              viewingOptions,
            )
              .then(() => {
                this.setState({ isEditViewModalOn: false })
                snugNotifier.success('Successfully updated viewing')
                updateAllViewingsData()
              })
              .catch((error) => {
                snugNotifier.error(error)
              })
          } else {
            snugNotifier.error(error)
          }
        } else {
          snugNotifier.error(error)
        }
      })
  }
  onModalTitleClicked = (field) => {
    this.turnOffAllModals(() =>
      this.setState({
        [field]: true,
      }),
    )
  }

  onNotifyLateViewing = (data) => {
    const { notifyLateViewing } = this.props
    const viewing = data
    notifyLateViewing(viewing)
      .then(() => {
        snugNotifier.success('Successfully requested late SMS')
        this.setState({ isLateModalOn: false })
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  onOnSiteAttendeeNameClicked = (renterInfo) => {
    this.setState({
      isWalkInModalOn: true,
      walkinModalStatus: 'edit',
      targetAttendee: renterInfo,
      isAddAttendeeHubModalOn: false,
    })
  }

  onSearchAttendeeLinkClicked = () => {
    this.setState({
      isWalkInModalOn: false,
      isAddAttendeeHubModalOn: true,
    })
  }

  onShowCommentModal = (status, renterInfo) => {
    const { showCommentModal } = this.props
    showCommentModal(status, renterInfo)
    this.setState({ isCommentModalOn: status })
  }

  onShowEditViewingModal = (status, viewing) => {
    const { showEditViewingModal } = this.props
    showEditViewingModal(status, viewing)
    this.setState({ isEditViewModalOn: status })
  }

  onShowLateViewingModal = (status, viewing) => {
    const { showLateViewingModal, onsiteRegistrants } = this.props
    const checkedInRegistrations = onsiteRegistrants.filter((attendee) => {
      return attendee.viewingOnsiteRegistrant.checkIn
    })

    const totalCheckedInAttendeeCount = checkedInRegistrations.length

    showLateViewingModal(status, viewing)
    this.setState({
      isLateModalOn: status,
      totalCheckedInAttendeeCount,
    })
  }

  onShowSendSmsReportModal = (isOpen, viewing, isViewingInProgress) => {
    if (isViewingInProgress) {
      if (
        window.confirm(viewingsHelpers.SMS_VIEWING_REPORT_CONFIRMATION_MESSAGE)
      ) {
        this.setState({
          isSendSmsReportModalOn: isOpen,
          smsData: viewing,
        })
      }
      return
    }
    this.setState({
      isSendSmsReportModalOn: isOpen,
      smsData: viewing,
    })
  }

  onToggleAttendeeWithoutSlotModal = (isOpen, viewing) => {
    this.setState({
      isAddAttendeeWithOutSlotModalOn: isOpen,
      selectedFullyBookedViewing: viewing,
    })
  }

  onToggleAttendeeHubModal = (isOpen, viewing, onsiteRegistrants) => {
    const { toggleWalkInModal } = this.props
    const { propertyViewings, enquirers } = this.state
    toggleWalkInModal(false, viewing)
    let filteredEnquirers = []
    if (viewing && propertyViewings) {
      filteredEnquirers =
        enquirers && enquirers.length > 0
          ? enquirers
              .filter(
                (enquirer) =>
                  !onsiteRegistrants.find(
                    (onsiteRegistrant) =>
                      onsiteRegistrant.viewingOnsiteRegistrant.mobilePhone ===
                      enquirer.phone,
                  ),
              )
              .filter(
                (enquirer) =>
                  !onsiteRegistrants.find(
                    (onsiteRegistrant) =>
                      onsiteRegistrant.viewingOnsiteRegistrant.email ===
                      enquirer.email,
                  ),
              )
          : []
    }
    this.setState({
      isAddAttendeeHubModalOn: isOpen,
      filteredEnquirersForAttendeeHub: filteredEnquirers,
    })
  }

  onToggleWalkInModal = (status, viewing) => {
    const { toggleWalkInModal } = this.props
    toggleWalkInModal(status, viewing)
    this.setState({
      isWalkInModalOn: status,
      walkinModalStatus: 'create',
    })
  }

  onUpdateRenterStatus = (
    payload,
    renterType,
    id,
    registrationGUID,
    propertyId,
  ) => {
    this.setState({
      disableSaveButton: true,
    })
    const { updateRenterStatus, updateAllViewingsData, summary } = this.props
    const { externalComment } = payload
    if (externalComment) {
      payload.externalNote = {
        content: externalComment,
        type: noteActionType.viewingAttendee,
        target: targetSectionObject.viewingReport['index'],
      }
    }
    payload.propertyId = summary.propertyGUID
    updateRenterStatus(payload, renterType, id, registrationGUID, propertyId)
      .then(() => {
        this.setState({
          disableSaveButton: false,
        })
        updateAllViewingsData()
      })
      .then(() => {
        snugNotifier.success('Updated successfully')
      })
      .catch((error) => {
        snugNotifier.error(error)
        this.setState({ apiError: error, disableSaveButton: false })
      })
  }

  onUpdateRenterStatusComment = (payload) => {
    const { updateRenterStatusComment, updateAllViewingsData } = this.props
    updateRenterStatusComment(payload)
      .then(() => {
        this.setState({ isCommentModalOn: false })
        updateAllViewingsData()
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  callCreateNote = (note, attendeeGUID) => {
    const { createNote } = this.props
    return createNote(note, attendeeGUID, noteOwnerType.ViewingAttendee)
      .then(() => {
        return Promise.resolve()
      })
      .catch((error) => {
        snugNotifier.error(error)
        return Promise.reject()
      })
  }

  handleShortlist = (registrantGUID, shortlist) => {
    const notePayload = getNotePayloadForShortlistViewingRegistrant(shortlist)
    this.callCreateNote(notePayload, registrantGUID)
  }

  checkIfViewingEnded = (viewing) => {
    const { dateTime, duration } = viewing || {}
    if (dateTime && duration) {
      return moment().isAfter(
        moment(dateTime, 'YYYY-MM-DDTHH:mm:ssZ').add(duration, 'minutes'),
      )
    }
    return false
  }

  confirmCancelViewing(guidID, propertyId) {
    const { updateAllViewingsData } = this.props
    if (window.confirm(viewingsHelpers.CANCEL_VIEWING_CONFIRM_TEXT)) {
      this.props
        .cancelViewing(guidID, propertyId)
        .then(() => {
          snugNotifier.success(
            viewingsHelpers.CANCEL_VIEWING_CONFIRMATION_MESSAGE,
          )
          updateAllViewingsData()
        })
        .catch((error) => {
          this.setState({ apiError: error })
        })
    }
  }

  confirmRequestRenterFeedback(viewing = {}) {
    if (
      window.confirm(
        'Are you sure you want to request feedback from all viewing registrants?',
      )
    ) {
      if (this.checkIfViewingEnded(viewing) && !viewing.cancelled) {
        this.props
          .requestFeedback(viewing.viewingGUID, this.reactToastify)
          .catch((error) => {
            this.setState({ apiError: error })
          })
      } else {
        this.setState({ requestFeedbackError: viewing.viewingGUID })
      }
    }
  }

  confirmSendInviteToApplyToAllRgistrants(guidID) {
    const { updateAllViewingsData } = this.props
    if (
      window.confirm(
        'Email and SMS invite all uninvited, checked-in attendees?',
      )
    ) {
      this.props
        .sendInviteToApplyToAllRegistrants(guidID)
        .then(() => {
          snugNotifier.success(
            'Email and SMS invite sent to all uninvited, checked-in attendees',
          )
          setTimeout(() => {
            updateAllViewingsData()
          }, 3000)
        })
        .catch((error) => {
          this.setState({ apiError: error })
        })
    }
  }

  deleteOnSiteAttendee = (
    propertyId,
    viewingGUID = this.props.summary.viewingGUID,
    registrationGUID,
  ) => {
    const { removeOnsiteViewingRenter, updateAllViewingsData } = this.props
    if (
      window.confirm('Are you sure you want to remove this viewing attendee?')
    ) {
      removeOnsiteViewingRenter(viewingGUID, registrationGUID).then(() => {
        this.setState({
          viewingAttendeeCount: this.state.viewingAttendeeCount - 1,
        })
        updateAllViewingsData()
      })
    }
  }

  fetchRegistrantInfo = () => {
    const { updateAllViewingsData } = this.props
    updateAllViewingsData()
  }

  reactToastify() {
    this.setState({ requestFeedbackError: '' })
    const { setSuccessToast } = this.props
    if (setSuccessToast) {
      setSuccessToast('Your request will only be sent to checked in applicants')
    } else {
      this.state.showingToast === false &&
        snugNotifier.success(
          'Your request will only be sent to checked in applicants',
          {
            onOpen: () => this.setState({ showingToast: true }),
            onClose: () => this.setState({ showingToast: false }),
          },
        )
    }
  }

  receiveApiError(apiError) {
    this.setState({ apiError })
  }

  sendInviteToApplyToRegistrant = (viewingRegistrant) => {
    const { sendIndividualInviteToApply } = this.props
    const { viewingID, guidID } = viewingRegistrant
    sendIndividualInviteToApply(viewingID, guidID)
    return Promise.resolve()
  }

  sendManagerOnlyOrOwnerReport = (
    isReportSent,
    propertyReportGUID,
    propertyGUID,
    teamSlug,
    viewingGUID,
    isViewingInProgress,
  ) => {
    if (isReportSent) {
      history.push(
        `${urlTo('managerOnlyPropertyReport', {
          propertyreportId: propertyReportGUID,
        })}`,
      )
    } else {
      if (isViewingInProgress) {
        if (
          window.confirm(viewingsHelpers.VIEWING_REPORT_CONFIRMATION_MESSAGE)
        ) {
          history.push(
            `${urlTo('ownerReport', {
              propertyId: propertyGUID,
            })}?team=${teamSlug}&viewingId=${viewingGUID}`,
          )
        }
        return
      }
      history.push(
        `${urlTo('ownerReport', {
          propertyId: propertyGUID,
        })}?team=${teamSlug}&viewingId=${viewingGUID}`,
      )
    }
  }

  toggleEnquirers() {
    this.setState({
      toggleShowEnquirerLink: !this.state.toggleShowEnquirerLink,
    })
  }

  turnOffAllModals = (callback = () => {}) => {
    this.setState(
      {
        isEditViewModalOn: false,
        isAddAttendeeHubModalOn: false,
        isWalkInModalOn: false,
        isLateModalOn: false,
        isAddNoteModalOn: false,
        isAccessDetailModalOn: false,
      },
      () => callback(),
    )
  }

  render() {
    const {
      renters,
      onsiteRegistrants,
      currentTeam,
      checkboxSpinnerGuidArray,
      addWalkInRenter,
      fetchAgencyApplicantsElasticSearchResult,
      viewingErrorGuid,
      startDate,
      clearError,
      activeViewingID,
      summary,
      dropDown,
      renterInfo = {},
      fetchAllPropertyFutureViewings,
      isViewingReportSmsEnabled,
      isViewingReportEmailEnabled,
      viewingReportSMSSent,
      viewingReportEmailSent,
      viewing,
      property,
    } = this.props
    const {
      enquirers,
      contactCount,
      apiError,
      isLateModalOn,
      totalCheckedInAttendeeCount,
      isWalkInModalOn,
      viewingAttendeeCount,
      walkinModalStatus,
      targetAttendee,
      addAttendeePreloadContent,
      filteredEnquirersForAttendeeHub,
      isAddAttendeeHubModalOn,
      isAddNoteModalOn,
      isAccessDetailModalOn,
      isEditViewModalOn,
      isCommentModalOn,
      requestFeedbackError,
      error,
      isSendSmsReportModalOn,
      isViewingSlotEnabled,
      disableSaveButton,
      showUpgradeAccountModal,
      isAddAttendeeWithOutSlotModalOn,
      propertyViewing,
      defaultSelectedViewingDuration,
      defaultSelectedViewingDurationEnabled,
      propertyViewings,
      showViewingRegistrantsMessageModal,
    } = this.state
    const editViewingTime = {
      startDate: moment(summary.dateTime, 'YYYY-MM-DDTHH:mm:ssZ').format(
        'YYYY-MM-DD HH:mm:ss',
      ),
      duration: summary.duration,
      published: !summary.private,
      notify: summary.notify,
    }
    const {
      propertyGUID,
      viewingGUID,
      isReportSent,
      propertyReportGUID,
      duration,
      dateTime,
      address,
    } = summary

    const { fullyBooked: isFullyBooked = false } = viewing
    const isFutureViewing = moment(startDate).isAfter(moment())
    const endDate = moment(startDate).add(duration, 'minutes')
    const isViewingInProgress = moment().isBetween(startDate, endDate)
    let teamId = ''
    let isAccountLite = false
    if (currentTeam) {
      teamId = currentTeam.guid
      isAccountLite =
        currentTeam.accountType &&
        currentTeam.accountType === accountHelpers.ACCOUNT_TYPE_LITE
    }
    const enabledOptionsForWalkinModal = {
      enableContactInfomation: true,
      enableCheckedInAndInterested: true,
      enablePreference: true,
      enableNote: true,
      showSearchButton: true,
      enableViewingBottomButtons: true,
    }
    return (
      <div className="mt25 mb25 vertical-align-middle">
        <button
          className="btn btn-medium viewing-details-button w-150 p0 pdf-hide"
          onClick={() =>
            isFullyBooked
              ? this.onToggleAttendeeWithoutSlotModal(true, summary)
              : this.onToggleAttendeeHubModal(true, summary, onsiteRegistrants)
          }
        >
          Add
        </button>
        <button
          className={`btn btn-medium viewing-details-button w-150 p0 v-btn1 pdf-hide ${
            isAccountLite
              ? 'background-color-gray-with-opacity '
              : 'hollow-button-green'
          }`}
          disabled={summary.cancelled}
          onClick={() => {
            !isAccountLite
              ? this.onShowLateViewingModal(true, summary)
              : this.onClickDisabledLiteAccountOption()
          }}
        >
          Late
        </button>
        <button
          className={`btn btn-medium w-150 p0 viewing-details-button mt10-mobile pdf-hide ${
            isAccountLite
              ? 'background-color-gray-with-opacity '
              : 'hollow-button-green'
          }`}
          onClick={() => {
            !isAccountLite
              ? this.confirmSendInviteToApplyToAllRgistrants(viewingGUID)
              : this.onClickDisabledLiteAccountOption()
          }}
        >
          Invite to apply
        </button>
        {!summary.cancelled && (
          <div className="display-inline-block">
            <SendEmailReportButton
              isViewingReportEmailEnabled={isViewingReportEmailEnabled}
              viewingReportEmailSent={viewingReportEmailSent}
              isReportSent={isReportSent}
              buttonClassName={`btn btn-medium p5 viewing-details-button v-btn1 pdf-hide ${
                isAccountLite
                  ? 'background-color-gray-with-opacity '
                  : 'hollow-button-green'
              }`}
              buttonOnClick={() => {
                !isAccountLite
                  ? this.sendManagerOnlyOrOwnerReport(
                      isReportSent,
                      propertyReportGUID,
                      propertyGUID,
                      currentTeam.slug,
                      viewingGUID,
                      isViewingInProgress,
                    )
                  : this.onClickDisabledLiteAccountOption()
              }}
              viewingGUID={viewingGUID}
            />
          </div>
        )}
        {!summary.cancelled && (
          <div className="display-inline-block">
            <SendSMSReportButton
              isViewingReportSmsEnabled={isViewingReportSmsEnabled}
              viewingReportSMSSent={viewingReportSMSSent}
              buttonClassName={`btn btn-medium p5 viewing-details-button v-btn1 pdf-hide ${
                isAccountLite
                  ? 'background-color-gray-with-opacity '
                  : 'hollow-button-green'
              }`}
              buttonOnClick={() => {
                !isAccountLite
                  ? this.onShowSendSmsReportModal(
                      true,
                      summary,
                      isViewingInProgress,
                    )
                  : this.onClickDisabledLiteAccountOption()
              }}
              viewingGUID={viewingGUID}
            />
          </div>
        )}
        {!summary.cancelled && (
          <ViewingContextMenu
            propertyViewing={propertyViewing}
            propertyViewings={propertyViewings}
            property={property}
            agencyId={currentTeam.guid}
            onsiteRegistrants={onsiteRegistrants}
            defaultSelectedViewingDurationEnabled={
              defaultSelectedViewingDurationEnabled
            }
            defaultSelectedViewingDuration={defaultSelectedViewingDuration}
            isAccountLite={isAccountLite}
            fetchAllPropertyFutureViewings={fetchAllPropertyFutureViewings}
            refreshRegistrantsList={() => this.fetchRegistrantInfo()}
            onShowEditViewingModal={() =>
              this.onShowEditViewingModal(true, summary)
            }
            confirmCancelViewing={() =>
              this.confirmCancelViewing(viewingGUID, propertyGUID)
            }
            onClickRequestFeedback={() => {
              !isAccountLite
                ? this.confirmRequestRenterFeedback(summary)
                : this.onClickDisabledLiteAccountOption()
            }}
            customClassName="display-inline-block vertical-align-middle pdf-hide"
            teamSlug={currentTeam.slug}
            onClickSendRegistrantsMessage={() => {
              this.setState({
                showViewingRegistrantsMessageModal: true,
              })
            }}
          />
        )}
        <div className="no-border">
          {requestFeedbackError && (
            <div className="alert alert-danger">
              <div>
                You cannot send a feedback request prior to the viewing time
              </div>
            </div>
          )}
        </div>
        <div className="no-border">
          {error && viewingErrorGuid === viewingGUID && (
            <div className="alert alert-danger">
              <div>{error}</div>
            </div>
          )}
        </div>
        <RenterRows>
          <RenderRenterRows
            dropDown={dropDown}
            viewing={summary}
            renters={renters}
            onsiteRegistrants={onsiteRegistrants}
            propertyId={propertyGUID}
            updateRenterStatus={this.onUpdateRenterStatus}
            onShowCommentModal={this.onShowCommentModal}
            deleteOnSiteAttendee={this.deleteOnSiteAttendee}
            checkboxSpinnerGuidArray={checkboxSpinnerGuidArray}
            receiveApiError={this.receiveApiError}
            onOnSiteAttendeeNameClicked={this.onOnSiteAttendeeNameClicked}
            enquirers={enquirers}
            contactCount={contactCount}
            toggleEnquirers={this.toggleEnquirers}
            toggleShowEnquirerLink={this.toggleShowEnquirerLink}
            addWalkInRenter={addWalkInRenter} //connection
            sendInviteToApplyToRegistrant={this.sendInviteToApplyToRegistrant}
            isViewingSlotEnabled={isViewingSlotEnabled}
            agencyGUID={teamId}
            fetchRegistrantInfo={this.fetchRegistrantInfo}
            handleShortlist={this.handleShortlist}
          />
        </RenterRows>
        {isLateModalOn && (
          <LateForViewingModal
            showLateViewingModal={this.onShowLateViewingModal}
            error={apiError}
            notifyLateViewing={this.onNotifyLateViewing}
            clearError={clearError}
            totalCheckedInAttendeeCount={totalCheckedInAttendeeCount}
            isLateModalOn={isLateModalOn}
          />
        )}
        {isWalkInModalOn && (
          <WalkinRenterModal
            viewingAttendeeCount={viewingAttendeeCount}
            error={apiError}
            toggleWalkInModal={this.onToggleWalkInModal}
            addWalkInRenter={this.onAddWalkInRenter}
            propertyId={propertyGUID}
            clearError={clearError}
            walkinModalStatus={walkinModalStatus}
            targetAttendee={targetAttendee}
            onUpdateRenterStatus={this.onUpdateRenterStatus}
            renterInfo={renterInfo}
            onSearchAttendeeLinkClicked={this.onSearchAttendeeLinkClicked}
            addAttendeePreloadContent={addAttendeePreloadContent}
            enableOptions={enabledOptionsForWalkinModal}
            disableSaveButton={disableSaveButton}
            agencyGUID={teamId}
            teamSlug={currentTeam?.slug}
          />
        )}
        {isAddAttendeeHubModalOn && (
          <AttendeeHubModal
            clearError={clearError}
            toggleAttendeeHubModal={this.onToggleAttendeeHubModal}
            enquirers={enquirers}
            onAttendeeHubModalAddIconClicked={
              this.onAttendeeHubModalAddIconClicked
            }
            onOnSiteAttendeeNameClicked={this.onOnSiteAttendeeNameClicked}
            fetchAgencyApplicantsElasticSearchResult={
              fetchAgencyApplicantsElasticSearchResult
            }
            teamId={teamId}
            filteredEnquirersForAttendeeHub={filteredEnquirersForAttendeeHub}
            onModalTitleClicked={this.onModalTitleClicked}
            clearAddAttendeePreloadContent={this.clearAddAttendeePreloadContent}
          />
        )}
        {isAddNoteModalOn && (
          <AddNoteModal
            turnOffAllModals={this.turnOffAllModals}
            onModalTitleClicked={this.onModalTitleClicked}
            propertyId={propertyGUID}
            activeViewingID={activeViewingID}
          />
        )}
        {isAccessDetailModalOn && (
          <AddAccessDetailModal
            turnOffAllModals={this.turnOffAllModals}
            onModalTitleClicked={this.onModalTitleClicked}
            property={propertyGUID}
            agencyGUID={teamId}
          />
        )}
        {isEditViewModalOn && (
          <EditViewingTime
            propertyId={propertyGUID}
            showEditViewingModal={this.onShowEditViewingModal}
            viewingTime={editViewingTime}
            editViewing={this.onEditViewing}
            error={apiError}
            clearError={clearError}
            isEditViewModalOn={isEditViewModalOn}
            teamGUID={teamId}
            fetchAllPropertyFutureViewings={fetchAllPropertyFutureViewings}
          />
        )}
        {isCommentModalOn && (
          <AddCommentModal
            showCommentModal={this.onShowCommentModal}
            error={apiError}
            clearError={clearError}
            updateRenterStatusComment={this.onUpdateRenterStatusComment}
            renterInfo={renterInfo}
            isCommentModalOn={isCommentModalOn}
          />
        )}
        {isSendSmsReportModalOn && (
          <SendSmsReportModal
            data={this.state.smsData}
            handleClose={() => this.setState({ isSendSmsReportModalOn: false })}
            showSendSmsReportModal={this.onShowSendSmsReportModal}
            clearError={clearError}
            isSendSmsReportModalOn={isSendSmsReportModalOn}
          />
        )}
        {showUpgradeAccountModal && (
          <SnugUpgradeAccountModal
            isNotToBeEmbedded={false}
            toggleModal={this.onClickDisabledLiteAccountOption}
            typeOfMessage={accountHelpers.UPGRADE_TYPE_VIEWINGS}
          />
        )}
        {!viewingErrorGuid && <sharedHelper.ErrorMessage error={error} />}
        {!viewingErrorGuid && <sharedHelper.ErrorMessage error={apiError} />}

        {isAddAttendeeWithOutSlotModalOn && (
          <Modal
            title="Fully booked"
            hideButtonSection={true}
            toggleModal={() => this.onToggleAttendeeWithoutSlotModal(false)}
          >
            <div className="text-center">
              The viewing slots are fully booked. Update the duration to add
              more slots. Continue to add attendee without a slot?
            </div>
            <ModalButtonGroup
              primaryLabel="Add"
              secondaryLabel="Cancel"
              secondaryButtonType="tertiary"
              primaryAction={() => {
                this.onToggleAttendeeWithoutSlotModal(false)
                this.onToggleAttendeeHubModal(
                  true,
                  this.state.selectedFullyBookedViewing,
                )
              }}
              secondaryAction={() =>
                this.onToggleAttendeeWithoutSlotModal(false)
              }
            />
          </Modal>
        )}

        {showViewingRegistrantsMessageModal && (
          <ViewingRegistrantsMessagesModal
            agencyGUID={teamId}
            onDismissClick={() =>
              this.setState({
                showViewingRegistrantsMessageModal: false,
              })
            }
            viewingGUID={viewingGUID}
            startDate={dateTime}
            friendlyName={address}
            registrantsCount={onsiteRegistrants?.length || 0}
          />
        )}
      </div>
    )
  }
}

export default ViewingDetails
