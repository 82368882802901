import { stateOptions } from 'app/constants/identity-docs.constants'

export const DETAILS_STEP = 0
export const CONTACTS_STEP = 1
export const MANAGEMENT_TYPE_STEP = 2
export const DISCLOSURES_LIST_STEP = 3

const onlistSteps = [
  DETAILS_STEP,
  CONTACTS_STEP,
  MANAGEMENT_TYPE_STEP,
  DISCLOSURES_LIST_STEP,
]

export const validate = (step: number) => {
  return onlistSteps.includes(step)
}

export const stateOptionsArray = stateOptions.map(({ value }) => value)

export const ballotsIncomeTableOptions = ['Metro', 'Regional']
