import { connect } from 'react-redux'

import { steps } from 'app/sm/advertise_flow/actions'
import AdWorkflow from 'app/sm/advertise_flow/container'

const mapStateToProps = ({ Profile }) => ({
  steps,
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AdWorkflow)
