import { connect } from 'react-redux'

import * as actions from 'app/agency/agency_action'
import Stocklist from 'app/agency/stocklist/container'

const mapStateToProps = () => null

const mapDispatchToProps = (dispatch) => ({
  fetchStocklist: (teamSlug) => dispatch(actions.fetchStocklist(teamSlug)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Stocklist)
