import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import headerImg02 from 'app/assets/icons/header-img-02.png'
import AddressForm from 'app/shared_components/address_form/address_form'
import DatePicker from 'app/shared_components/date_picker'
import UnsavedMessage from 'app/shared_components/unsaved_message'
import { urlTo } from 'app/sm/helpers'

class RentalForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      isNewLease,
      address,
      suggest,
      rate,
      bond,
      isRollingLease,
      startDate,
      endDate,
      errors,
      apiError,
      updateAddressNumber,
      updateAddress,
      updateGoogleAddress,
      resetAddress,
      validateGoogleAddress,
      updateNumber,
      updateCurrency,
      validate,
      toggleSuggest,
      trimField,
      trimAddressField,
      toggleCheckbox,
      toggleRollingLease,
      updateDate,
      isEmptyObject,
      endDateInput,
      leaseLength,
      propertyType,
      isMultiTenantLease,
      hasUnpaidBondClaim,
      update,
      submit,
      message,
      disabled,
      clicked,
      unsavedChanges,
      requestID,
      bedrooms,
    } = this.props

    const hasValidationError =
      errors.streetNumber ||
      errors.streetName ||
      errors.suburb ||
      errors.postcode ||
      errors.state
    return (
      <div className="two-col-box-flex-row sm64">
        <div className="col-first">
          <div className="mobile-only">
            <Link
              to={
                requestID
                  ? urlTo('bondCoverApplyProgress', { requestID })
                  : urlTo('bondCoverApply')
              }
              className="btn btn-transparent btn-left xs-text-center wa"
            >
              <i className="icon-arrow-left left"></i>
              <span>Back</span>
            </Link>
          </div>
          <div className="ilustration-box">
            <img src={headerImg02} />
          </div>

          <h3 className="mb20">Tell us about your rental space.</h3>

          <AddressForm
            address={address}
            updateAddress={updateAddress}
            updateGoogleAddress={updateGoogleAddress}
            resetAddress={resetAddress()}
            placeholder="Enter rental"
            onBlur={validateGoogleAddress}
            trimAddressField={trimAddressField}
            validate={validate}
            suggest={suggest}
            errors={errors}
            error={hasValidationError}
            country={['au']}
          />
          <div
            className={hasValidationError ? 'alert alert-danger mb5' : 'hidden'}
          >
            <h5 className="pb10">Required address fields: </h5>
            <div className="lh14">
              {errors.streetNumber && (
                <span className="comma"> {errors.streetNumber} </span>
              )}
              {errors.streetName && (
                <span className="comma"> {errors.streetName} </span>
              )}
              {errors.suburb && (
                <span className="comma"> {errors.suburb} </span>
              )}
              {errors.postcode && (
                <span className="comma"> {errors.postcode} </span>
              )}
              {errors.state && <span className="comma"> {errors.state} </span>}
            </div>
          </div>
          <div className="input-box">
            <select
              required
              className={errors.propertyType ? 'error' : ''}
              id="propertyType"
              onChange={updateNumber('propertyType')}
              onBlur={validate('propertyType')}
              value={propertyType}
            >
              <option className="blank" disabled></option>
              <option value="1">House</option>
              <option value="3">Townhouse</option>
              <option value="5">Unit/Apartment</option>
              <option value="9">Semi-detached</option>
              <option value="16">Other</option>
            </select>
            <label>Select Property Type</label>
          </div>
          <div className="input-box mb30">
            <select
              required
              className={errors.bedrooms ? 'error' : ''}
              id="bedrooms"
              value={bedrooms || ''}
              onChange={updateNumber('bedrooms')}
              onBlur={validate('bedrooms')}
            >
              <option className="blank" disabled></option>
              {['1', '2', '3', '4', '5', '6', '7', '8', '9', '9+'].map(
                (state, index) => (
                  <option value={index + 1} key={state}>
                    {state}
                  </option>
                ),
              )}
            </select>
            <label>No. of Bedrooms</label>
          </div>

          <div
            className={
              errors.propertyType || errors.bedrooms ? 'alert alert-danger' : ''
            }
          >
            <div>{errors.propertyType}</div>
            <div>{errors.bedrooms}</div>
          </div>

          <div className="row">
            <div className="col-xs-6">
              <div className="input-box">
                <div className="prefix">$</div>
                <input
                  type="text"
                  className={errors.rate ? 'error' : ''}
                  required
                  id="rate"
                  value={rate || ''}
                  onChange={updateCurrency('rate')}
                  onBlur={validate('rate')}
                />
                <label>
                  Rent <small>per week</small>
                </label>
              </div>
            </div>
            <div className="col-xs-6">
              <div className="input-box">
                <div className="prefix">$</div>
                <input
                  type="text"
                  className={errors.bond ? 'error' : ''}
                  required
                  id="bond"
                  value={bond || ''}
                  onChange={updateCurrency('bond')}
                  onBlur={validate('bond')}
                />
                <label>Bond</label>
              </div>
            </div>
          </div>

          <div
            className={
              errors.rate || errors.bond ? 'alert alert-danger lh14' : ''
            }
          >
            {errors.rate} <div>{errors.bond}</div>
          </div>

          <h4 className="pt25 pb15">Lease Dates</h4>

          {!isNewLease && (
            <div className="table mb10">
              <div className="cell-m">
                <label className="wsnw">
                  <input
                    type="checkbox"
                    checked={isRollingLease ? isRollingLease : ''}
                    onChange={toggleRollingLease()}
                  />
                  <span className="wsnw">Rolling Lease</span>
                </label>
              </div>
              <div className="cell-m-100 pl5 pt5">
                <i
                  className="icon-help-outline fs16 gray-light-color"
                  data-tooltip-content="Rolling, Periodic or Month to Month Tenancy, the agreement runs for an indefinite length of time; there is no lease end date."
                />
              </div>
            </div>
          )}

          <div className="row pt10">
            <div
              className={
                !isRollingLease || isNewLease ? 'col-xs-6' : 'col-xs-12'
              }
            >
              <DatePicker
                label="Start Date"
                viewMode="months"
                value={
                  startDate ? moment(startDate).format('DD/MM/YYYY') : null
                }
                onChange={updateDate('startDate')}
                isOutsideRange={(day) => day.isAfter(moment())}
              />
            </div>

            <div
              className={isNewLease || !isRollingLease ? 'col-xs-6' : 'hidden'}
            >
              {(isNewLease || !isRollingLease) && endDateInput(endDate)}
            </div>
          </div>

          <div className={errors.lease ? 'alert alert-danger' : ''}>
            {errors.lease ? errors.lease : leaseLength(startDate, endDate)}
          </div>

          <div
            className={
              errors.startDate || errors.endDate ? 'alert alert-danger' : ''
            }
          >
            {errors.startDate ? (
              isNewLease ? (
                <div>
                  {errors.startDate}{' '}
                  <Link to="/bc?type=refund">BondCover Refund</Link>
                </div>
              ) : (
                <div>{errors.startDate}</div>
              )
            ) : (
              ''
            )}
            <div>{errors.endDate}</div>
          </div>

          <h4 className="mb10 mt50">
            Additional info :{' '}
            <i
              className="icon-help-outline fs14 gray-light-color"
              data-tooltip-content="This helps us create an accurate quote. We will need additional tenant information."
            />
          </h4>

          <div className="cb-wrapper">
            <div className="cb-wrapper-row">
              <input
                type="checkbox"
                checked={isMultiTenantLease ? isMultiTenantLease : ''}
                onChange={toggleCheckbox('isMultiTenantLease')}
              />
              <div>This is a multiple-tenant lease</div>
            </div>
          </div>

          <ul className="snug-tips">
            <li>
              It's important the details match those on your lease. Incorrect
              details will delay your bond refund.
            </li>
          </ul>

          <div className={apiError ? 'alert alert-danger' : 'hidden'}>
            {apiError}
          </div>
          <div className="row pt25 mobile-form-button">
            <div className="col-sm-5 pb5 tablet-only">
              <Link
                to={
                  requestID
                    ? urlTo('bondCoverApplyProgress', { requestID })
                    : urlTo('bondCoverApply')
                }
                className="btn btn-transparent btn-left xs-text-center wa"
              >
                <i className="icon-arrow-left left"></i>
                <span>Back</span>
              </Link>
            </div>
            <div className="col-sm-7 pb5">
              <button
                className="btn"
                disabled={disabled}
                onClick={() => submit()}
              >
                Save
                <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
              </button>
            </div>
          </div>

          <ReactTooltip className="tooltip" />

          <UnsavedMessage unsavedChanges={unsavedChanges} />
        </div>
      </div>
    )
  }
}

export default RentalForm
