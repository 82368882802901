// todo: move this before merge to utils and use it in messages reply modal
import { useState } from 'react'

import { debounce } from 'app/helpers/debounce'
import {
  searchAllProperties,
  searchProperty,
} from 'app/services/http/teams/properties'

export const useSearchPropertyField = (teamId, config) => {
  const [loadingProperties, setLoadingProperties] = useState(false)
  const [propertiesOptions, setPropertiesOptions] = useState([])

  const { allProperties } = config || {}

  const onSearchProperties = debounce((searchText) => {
    setLoadingProperties(true)

    const propertiesPromise = allProperties
      ? searchAllProperties(teamId, searchText).then((properties) =>
          properties.map((p) => ({
            property: p,
          })),
        )
      : searchProperty(teamId, searchText).then(
          (response) => response.properties?.data || [],
        )

    return propertiesPromise
      .then((filteredProperties) => {
        return filteredProperties.map(({ property }) => ({
          optionMeta: {
            id: property.guidID,
            displayText: property.address.friendlyName,
          },
          option: property,
        }))
      })
      .then((filteredPropertyOptions) =>
        setPropertiesOptions(filteredPropertyOptions),
      )
      .finally(() => setLoadingProperties(false))
  })

  return {
    onSearchProperties,
    propertiesOptions,
    loadingProperties,
  }
}
