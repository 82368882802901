import React from 'react'

import qs from 'qs'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import * as Form from 'app/components/forms/forms'
import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import { updatePropertyListingAgent } from 'app/services/http/properties'
import * as snugNotifier from 'app/services/snugNotifier'
import Spinner from 'app/sm/common/spinner'
import { urlTo } from 'app/sm/helpers'
import AccessDetails from 'app/sm/onlist_details/AccessDetails/connection'
import AddPropertyToBuilding from 'app/sm/onlist_details/AddPropertyToBuilding/add_property_building_connection'
import ApplicationsSettings from 'app/sm/onlist_details/applications_settings'
import LimitViewingRegistrants from 'app/sm/onlist_details/limit_viewing_registrants_details_connection'
import PropertyListingAgent from 'app/sm/onlist_details/property_listing_agent_container'
import PropertyLookup from 'app/sm/onlist_details/property_lookup_connection'
import ViewingScheduleZone from 'app/sm/onlist_details/viewing_schedule_zone_details_connection'
import VirtualTour from 'app/sm/onlist_details/virtual_tour_connection'
import { findChosenTeam } from 'app/sm/team_contact/container'
import Header from 'app/sm/team_contact/header/component'

const PROPERTY_VIEWING_SCHEDULE_UPDATED_SUCCESS = 'Setting updated'
const OnlistSubText = ({ propertyDate }) => {
  return (
    <div>
      <p className="mt-10">
        Enter details required to manage the leasing of this property.
      </p>
      <p>Property Listed: {propertyDate}</p>
    </div>
  )
}

class OnListDetailsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      apiError: '',
      errors: {},
      propertyName: '',
      propertyDate: '',
      lastUpdateBy: '',
      entryDetails: [],
      tableSpinner: false,
      guidDetails: {},
      propertyVideos: [],
      offer: {},
      configuredViewings: [],
      timeWindowAdder: {
        StartTime: undefined,
        EndTime: undefined,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
        Sun: false,
        duration: '15 mins',
        ViewingScheduleZone: 0,
      },
      showAddLink: true,
      isFlexibleTimePicked: true,
      showViewingScheduleBeforeVacant: false,
      viewingsBufferTime: 60,
      disableSaveButton: true,
      availabilitySaveInProgress: false,
      assignBuildingToPropertiesEnabled: false,
      managingAgentID: null,
      applicationsTeamSettings: {},
    }
  }

  componentDidMount() {
    this.getPropertyAndTeamGUID()
    this.fetchEntryDetailsByTeamAndProperty()
    this.fetchPropertyOffer()
  }

  componentDidUpdate(prevProps) {
    if (this.props.teams && this.props.teams !== prevProps.teams) {
      this.fetchEntryDetailsByTeamAndProperty()
      this.fetchPropertyOffer()
    }
  }

  fetchPropertyOffer() {
    const details = this.getPropertyAndTeamGUID()
    const { fetchOffer } = this.props
    const { property } = details
    if (property && details.offerGUID) {
      fetchOffer(property)
        .then((data) => {
          this.setState({
            offer: data,
            showViewingScheduleBeforeVacant:
              data.showViewingScheduleBeforeVacant,
          })
        })
        .catch((error) => {
          snugNotifier.error(error)
        })
    }
  }

  getPropertyAndTeamGUID() {
    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const teamSlug =
      (this.props.match &&
        this.props.match.params &&
        this.props.match.params.teamSlug) ||
      ''
    const { teams } = this.props
    const chosenTeam = findChosenTeam(teams, teamSlug) || {}
    const { guid: agencyGUID = '' } = chosenTeam
    const { property, offerGUID } = curQueries
    const details = {
      property: property,
      agencyGUID: agencyGUID,
      offerGUID: offerGUID,
    }
    this.setState({
      guidDetails: details,
    })
    return details
  }

  onManagingAgentChanged(newManagerId, callback = () => {}) {
    const { guidDetails } = this.state
    updatePropertyListingAgent(
      guidDetails?.agencyGUID,
      guidDetails?.property,
      newManagerId,
    )
      .then(() => {
        snugNotifier.success('Listing agent updated successfully')
        this.fetchEntryDetailsByTeamAndProperty()
        callback()
      })
      .catch((err) => {
        snugNotifier.error(err.message || 'Failed to update listing agent')
      })
  }

  fetchEntryDetailsByTeamAndProperty() {
    const details = this.getPropertyAndTeamGUID()
    const { property, agencyGUID } = details
    const { fetchPropertyEntryDetails, fetchTeamSettingInformation } =
      this.props
    this.setState({ tableSpinner: true })
    if (property && agencyGUID) {
      fetchPropertyEntryDetails(agencyGUID, property)
        .then((result) => {
          const {
            propertyAddress,
            propertyListedDate,
            entryDetails,
            lastUpdatedDate,
            propertyVideos,
            managerName,
            managingAgentID,
          } = result
          const lastUpdatedDateWithManager = `${lastUpdatedDate} ${managerName}`
          this.setState({
            propertyName: propertyAddress,
            propertyDate: propertyListedDate,
            entryDetails,
            propertyVideos,
            lastUpdateBy: lastUpdatedDateWithManager,
            tableSpinner: false,
            managingAgentID,
          })
        })
        .catch((apiError) =>
          this.setState({
            apiError,
            tableSpinner: false,
          }),
        )
      fetchTeamSettingInformation(agencyGUID, 'properties,application')
        .then((teamSettingInfo) => {
          if (
            teamSettingInfo &&
            teamSettingInfo.properties &&
            teamSettingInfo.properties.enable_assign_property_to_building ===
              'true'
          ) {
            this.setState({
              assignBuildingToPropertiesEnabled: true,
            })
          }

          this.setState({
            applicationsTeamSettings: teamSettingInfo?.application || {},
          })
        })

        .catch((error) => {
          this.setState({ error })
        })
    }
  }

  onShowViewingScheduleBeforeVacantUpdated = () => {
    const { guidDetails } = this.state
    const { offerGUID, agencyGUID } = guidDetails
    const { updateOfferWithPropertyViewingSchedule } = this.props
    this.setState(
      {
        showViewingScheduleBeforeVacant:
          !this.state.showViewingScheduleBeforeVacant,
      },
      () => {
        if (offerGUID && agencyGUID) {
          updateOfferWithPropertyViewingSchedule(
            agencyGUID,
            offerGUID,
            this.state.showViewingScheduleBeforeVacant,
          )
            .then(() =>
              snugNotifier.success(PROPERTY_VIEWING_SCHEDULE_UPDATED_SUCCESS),
            )
            .catch((error) => snugNotifier.error(error))
        }
      },
    )
  }

  render() {
    const { teams, isFromOnlistPropertyDisclosures = false } = this.props
    const {
      propertyDate,
      propertyName,
      entryDetails,
      lastUpdateBy,
      tableSpinner,
      guidDetails,
      offer,
      showViewingScheduleBeforeVacant,
      assignBuildingToPropertiesEnabled,
      managingAgentID,
      applicationsTeamSettings,
    } = this.state
    const teamSlug =
      (this.props.match &&
        this.props.match.params &&
        this.props.match.params.teamSlug) ||
      ''
    const chosenTeam = findChosenTeam(teams, teamSlug) || {}
    const { name: agencyName = '', slug = '' } = chosenTeam
    let crumbs = []
    const { offerGUID } = guidDetails
    if (agencyName) {
      crumbs = [
        {
          text: agencyName || 'Team',
          link: urlTo('teamOverview', { teamSlug: slug }),
        },
        {
          text: 'viewings',
          link: urlTo('teamsViewing', { teamSlug: slug }),
        },
        {
          text: 'details',
          link: '#',
        },
      ]
    }

    return (
      <div className="profile-screen display-flex">
        <div className="mb10">
          {!isFromOnlistPropertyDisclosures && <Breadcrumbs crumbs={crumbs} />}
          {tableSpinner ? (
            <Spinner />
          ) : (
            <div>
              {offerGUID && (
                <>
                  <div className="width100">
                    <Header label={`Onlist Details - ${propertyName}`} />
                    <OnlistSubText propertyDate={propertyDate} />
                    <AccessDetails
                      entryDetails={entryDetails}
                      lastUpdateBy={lastUpdateBy}
                      guidDetails={guidDetails}
                    />
                  </div>
                  <div className="mt30 width100">
                    <PropertyLookup guidDetails={guidDetails} />
                  </div>
                </>
              )}
              <div className="mt30 width100 clear-both">
                <PropertyListingAgent
                  guidDetails={guidDetails}
                  managingAgentID={managingAgentID}
                  onManagingAgentUpdated={(newManagerId, cb) =>
                    this.onManagingAgentChanged(newManagerId, cb)
                  }
                />
              </div>
              {offerGUID && (
                <div className="mt30 width100">
                  <ViewingScheduleZone
                    guidDetails={guidDetails}
                    offer={offer}
                  />
                </div>
              )}
              {assignBuildingToPropertiesEnabled && (
                <div className="mt30 width100">
                  <AddPropertyToBuilding
                    propertyName={propertyName}
                    guidDetails={guidDetails}
                    teamSlug={slug}
                  />
                </div>
              )}
              {offerGUID && (
                <div className="mt30 width100">
                  <LimitViewingRegistrants
                    guidDetails={guidDetails}
                    offer={offer}
                  />
                </div>
              )}
              <div className="mt30 width100">
                <div>
                  <ReactTooltip id="scheduleForPropertyVacant">
                    <span>
                      Show the Viewing Schedule for this property prior to the
                      availability date (if not enabled in team settings)
                    </span>
                  </ReactTooltip>
                </div>
                {offerGUID && (
                  <Form.CheckBoxGeneral
                    label="Display Viewing Schedule prior to vacant"
                    checked={showViewingScheduleBeforeVacant}
                    onChange={this.onShowViewingScheduleBeforeVacantUpdated}
                    toolTip={
                      <div>
                        <i
                          className="icon-help-outline fs16 gray-light-color ibm"
                          data-tooltip-content=""
                          data-tooltip-id="scheduleForPropertyVacant"
                        />
                      </div>
                    }
                  />
                )}
              </div>
              {offerGUID && (
                <div className="mt30 width100">
                  <ApplicationsSettings
                    guidDetails={guidDetails}
                    offer={offer}
                    applicationsTeamSettings={applicationsTeamSettings}
                  />
                </div>
              )}
              <div className="mt30 width100">
                <VirtualTour
                  guidDetails={guidDetails}
                  slug={slug}
                  isFromOnlistPropertyDisclosures={
                    isFromOnlistPropertyDisclosures
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default OnListDetailsContainer
