import React from 'react'

import { Pie, PieChart, ResponsiveContainer } from 'recharts'

const data = (score) => [
  { name: 'Fit', value: score, fill: '#37c977' },
  { name: 'blank', value: 100 - score, fill: '#ffffff' },
]

function StraightAnglePieChart(props) {
  const { score } = props
  return (
    <PieChart width={80} height={80}>
      <Pie
        dataKey="value"
        data={data(score)}
        innerRadius={36}
        outerRadius={40}
        labelLine={false}
      />
    </PieChart>
  )
}

export const SmallStraightAnglePieChart = ({ score }) => (
  <PieChart width={60} height={60}>
    <Pie
      dataKey="value"
      data={data(score)}
      innerRadius={26}
      outerRadius={30}
      labelLine={false}
    />
  </PieChart>
)

export default StraightAnglePieChart
