import React from 'react'

import { Link } from 'react-router-dom'

import iconDocumentDoc from 'app/assets/icons/icon-document-doc.svg'
import iconDocumentPdf from 'app/assets/icons/icon-document-pdf.svg'

function Documents() {
  return (
    <div className="sm-property-lease-details-card documents">
      <div className="header">
        <h4>Lease documents (2)</h4>
        <div className="controls">
          <button className="btn btn-gray btn-round btn-medium">
            <i className="icon-add"></i>
          </button>
        </div>
      </div>
      <div className="content">
        <a href="#" className="document-link">
          <div className="img-wraper">
            <img src={iconDocumentPdf} />
          </div>
          <span>Signed Lease.pdf</span>
        </a>
        <a href="#" className="document-link">
          <div className="img-wraper">
            <img src={iconDocumentDoc} />
          </div>
          <span>House rules.doc</span>
        </a>
      </div>
    </div>
  )
}

export default Documents
