import React, { useState } from 'react'

import Linkify from 'linkify-react'
import moment from 'moment'
import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import ActivityItemWithDot from 'app/components/design-system-components/ActivityItemWithDot'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { activityTabCategories } from 'app/features/teams/applications/application-activities/ApplicationSideTabs/ApplicationActivityTab'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { shortYearDateTimeWithAt } from 'app/utils/datetime/helpers'

const StyledMessageBody = styled(Box)`
  white-space: pre-line;
  overflow-y: auto;
  line-height: ${(props) => props.theme.lineHeights.encrption};
`

export const FeedItem = ({ activityLog, lastItem, category, showIcon }) => {
  const { created_at: createdAt, actor, description, details } = activityLog
  const feedTimestamp = moment(createdAt).format(shortYearDateTimeWithAt)
  const IconCmp = category && activityTabCategories[category]?.icon

  const renderDetailsModal = (details) => (
    <GenericModal
      onDismiss={() => seDetailsModalOpened(false)}
      title={
        !!details?.subject && <Flex alignItems="center">{details.subject}</Flex>
      }
      withHeading={details?.subject}
      modalFooter={
        <GenericModalFooter
          secondaryBtnConfig={{
            title: 'Close',
            onClick: () => seDetailsModalOpened(false),
          }}
        />
      }
    >
      {
        <StyledMessageBody mb={4}>
          <Linkify options={{ truncate: 100 }}>{details?.body}</Linkify>
        </StyledMessageBody>
      }
    </GenericModal>
  )

  const detailsModalCmp = details && renderDetailsModal(details)
  const [detailsModalOpened, seDetailsModalOpened] = useState(false)

  const dateInfo = (
    <Flex alignItems="center">
      {feedTimestamp}{' '}
      {showIcon && IconCmp && (
        <Box ml={2}>
          <IconCmp fontSize={theme.fontSizes[5]} color={theme.colors.gray400} />
        </Box>
      )}
    </Flex>
  )

  const commentInfo = (
    <Box>
      {actor && <strong>{actor}&nbsp;</strong>}
      {description}
    </Box>
  )

  return (
    <>
      <ActivityItemWithDot
        dateInfo={dateInfo}
        actionButtonLabel="view"
        actionButtonClick={() => seDetailsModalOpened(true)}
        hasLink={!!detailsModalCmp}
        commentInfo={commentInfo}
      />
      {detailsModalOpened && detailsModalCmp}
    </>
  )
}
