import qs from 'qs'

import * as sharedHelpers from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'
import { api } from 'app/utils/api/helpers'

export const postNote = (data, ownerGUID, ownerType) => {
  const url = `${apiBaseUrl}sm/note/${ownerGUID}/${ownerType}`
  return fetch(url, {
    method: 'POST',
    body: data,
    credentials: 'include',
  })
    .then(sharedHelpers.checkStatus)
    .then(
      (responseData) => ({ ok: true, responseData }),
      sharedHelpers.returnAPIError,
    )
    .catch(sharedHelpers.logAPIError)
}

export const getNotes = (ownerGUID, ownerType) => {
  const url = `${apiBaseUrl}sm/note/${ownerGUID}/${ownerType}`
  return fetch(url, {
    credentials: 'include',
  })
    .then(sharedHelpers.checkStatus)
    .then(
      (responseData) => ({ ok: true, responseData }),
      sharedHelpers.returnAPIError,
    )
    .catch(sharedHelpers.logAPIError)
}

export const getAllNotesForPropertyAndViewing = (propertyId, viewingId) => {
  const url = `${apiBaseUrl}sm/note/property/${propertyId}/viewing/${viewingId}`
  return fetch(url, {
    credentials: 'include',
  })
    .then(sharedHelpers.checkStatus)
    .then(
      (responseData) => ({ ok: true, responseData }),
      sharedHelpers.returnAPIError,
    )
    .catch(sharedHelpers.logAPIError)
}

export const sendPropertyList = (messageGUID) => {
  const url = `${apiBaseUrl}sm/enquiry/message/${messageGUID}/email/propertylist`
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
  })
    .then(sharedHelpers.checkStatus)
    .then(() => ({ ok: true }), sharedHelpers.returnAPIError)
    .catch(sharedHelpers.logAPIError)
}

export const getStocklist = (teamSlug) => {
  const url = `${apiBaseUrl}sm/agencies/${teamSlug}/stocklist`
  return fetch(url, {
    credentials: 'include',
  })
    .then(sharedHelpers.checkStatus)
    .then(
      (responseData) => ({ ok: true, responseData }),
      sharedHelpers.returnAPIError,
    )
    .catch(sharedHelpers.logAPIError)
}

export const selfRegistration = (data) => {
  const url = `${apiBaseUrl}sm/team/register`
  return fetch(url, {
    method: 'POST',
    body: data,
    credentials: 'include',
  })
    .then(sharedHelpers.checkStatus)
    .then(
      (responseData) => ({ ok: true, responseData }),
      sharedHelpers.returnAPIError,
    )
    .catch(sharedHelpers.logAPIError)
}

export const fetchAllSupplierSources = () => {
  const url = `${apiBaseUrl}team/suppliersource`

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
    .catch(sharedHelpers.logAPIError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .catch(sharedHelpers.logAPIError)
    .then(
      (supplierSources) => {
        return { ok: true, supplierSources }
      },
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const searchAgencyDomain = (agency) => {
  const filtersQuery = qs.stringify(
    { search: agency },
    {
      arrayFormat: 'repeat',
    },
  )
  return api.get(`/sm/teams/register?${filtersQuery}`)
}

export const registerTeamViaDomain = (payload) => {
  return api.post(`/sm/teams/register`, payload)
}

export const getRegisterTeamViaDomainStatus = (jobId) => {
  return api.get(`sm/teams/${jobId}/registration-status`)
}
