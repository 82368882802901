import { connect } from 'react-redux'

import {
  fetchRenterSignature,
  getSecondaryTenantSummary,
  signByRenter,
} from 'app/bond_cover/bond_cover_actions.js'
import RenterSignature from 'app/bond_cover/renters/tenants/identity/signature'

const mapStateToProps = ({ bondCover }) => ({
  request: bondCover.request,
  quote: bondCover.quote,
  signature: bondCover.signature,
  apiError: bondCover.error,
})

const mapDispatchToProps = (dispatch) => ({
  signByRenter: (requestID, signature, isPrimary) =>
    signByRenter(requestID, signature, isPrimary),
  getSecondaryTenantSummary: (requestID) =>
    getSecondaryTenantSummary(requestID),
})

export default connect(mapStateToProps, mapDispatchToProps)(RenterSignature)
