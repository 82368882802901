import * as sharedHelper from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const activateIntegration = (agencyID, data) => {
  const url = `${apiBaseUrl}partner/team/${agencyID}/consolecloud/activate`
  const options = {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(data),
  }
  return fetch(url, options)
    .catch((error) => sharedHelper.LogFetchError(error))
    .then(sharedHelper.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelper.returnAPIError)
}

export const getConsoleCloudIntegration = (agencyID) => {
  const url = `${apiBaseUrl}partner/team/${agencyID}/consolecloud`
  const options = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => sharedHelper.LogFetchError(error))
    .then(sharedHelper.checkStatus)
    .then((result) => ({ ok: true, result }), sharedHelper.returnAPIError)
}

export const getIntegrationActivity = (agencyID) => {
  const url = `${apiBaseUrl}partner/team/${agencyID}/consoleactivity`
  const options = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => sharedHelper.LogFetchError(error))
    .then(sharedHelper.checkStatus)
    .then((activity) => ({ ok: true, activity }), sharedHelper.returnAPIError)
}
