import React from 'react'

import { appURL } from 'config/env'
import moment from 'moment'
import QRCode from 'qrcode.react'

import { Button, Flex } from 'app/components/design-system-components'
import { urlTo } from 'app/sm/helpers'
import { formatTime, QR_CODE_FORMAT_FILENAME } from 'app/utils/datetime/helpers'

const IMAGE_WIDTH_HEIGHT = 600
const DEST_X_Y_0 = 0

function OnlistQRCode({ slug = '', address = {} }) {
  const QR_URL = urlTo('applyRedirect', { slug })
  const url = `${appURL}${QR_URL}`
  const triggerDownload = (imgURI) => {
    const { friendlyName = '' } = address || {}
    const mouseClickEvent = new MouseEvent('click', {
      view: window,
      bubbles: false,
      cancelable: true,
    })
    let filename = 'QR-'
    if (friendlyName) {
      const cleanFriendlyName = friendlyName.replace(/[^a-zA-Z0-9]/g, '')
      const dateTime = formatTime(moment.now(), QR_CODE_FORMAT_FILENAME)
      filename += cleanFriendlyName + dateTime
    }

    let QRHyperlink = document.createElement('a')
    QRHyperlink.setAttribute('download', `${filename}.png`)
    QRHyperlink.setAttribute('href', imgURI)
    QRHyperlink.setAttribute('target', '_blank')

    QRHyperlink.dispatchEvent(mouseClickEvent)
  }

  const downloadQR = () => {
    // LOGIC for downloading svg as png
    // 1. svg to canvas
    // 2. canvas to dataUrl
    // 3. trigger download from dataUrl
    const { friendlyName = '' } = address || {}
    const renderedQR = document.getElementById('qr-svg')
    let canvas = document.getElementById('qr-canvas')
    let context2D = canvas.getContext('2d')
    const data = new XMLSerializer().serializeToString(renderedQR)
    let DOMURL = window.URL || window.webkitURL || window

    let QRImg = new Image()
    const svgBlob = new Blob([data], { type: 'image/svg+xml' })
    const url = DOMURL.createObjectURL(svgBlob)

    QRImg.onload = () => {
      canvas.width = IMAGE_WIDTH_HEIGHT + 200
      canvas.height = IMAGE_WIDTH_HEIGHT + 200
      context2D.drawImage(
        QRImg,
        DEST_X_Y_0 + 100,
        DEST_X_Y_0 + 100,
        IMAGE_WIDTH_HEIGHT,
        IMAGE_WIDTH_HEIGHT,
      )
      const x = canvas.width / 2
      context2D.textAlign = 'center'
      context2D.font = '40px Cera PRO'
      context2D.fillText(friendlyName, x, 40)
      context2D.font = '24px Cera PRO'

      context2D.fillText('Scan to book and apply', x, 80)
      DOMURL.revokeObjectURL(url)

      let imgURI = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')

      triggerDownload(imgURI)
    }

    QRImg.src = url
  }
  return (
    <Flex alignItems="center">
      <Flex mt={5}>
        <QRCode id="qr-png" renderAs="png" value={url} />
        <div className="invisible">
          <QRCode id="qr-svg" renderAs="svg" value={url} />
        </div>
        <canvas className="display-none" id="qr-canvas"></canvas>
      </Flex>
      <Button variant="outlineSuccess" onClick={downloadQR} height="50px">
        Download QR Code
      </Button>
    </Flex>
  )
}

export default OnlistQRCode
