import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { Box, Dropdown, Flex } from 'app/components/design-system-components'
import { ArrowDropDownRounded } from 'app/components/design-system-components/icons/navigation'
import { CheckboxesList } from 'app/components/design-system-components/list-filters/CheckboxesList'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  allExcludeTypes,
  EXCLUDE_SCHEDULE_TYPE,
  typesDisplayTexts,
} from 'app/sm/properties/components/excluded_times/constants'
import {
  DropdownBodyWrapper,
  StyledDropdownButton,
} from 'app/sm/team_viewing_preference/states_dropdown'

const StyledDropdownBodyWrapper = styled(DropdownBodyWrapper)`
  .dropdown-list:first-child {
    .form-checkbox {
      padding-top: ${theme.baseSpace}px;
    }
  }
`

const typeToOption = (type) => ({
  option: type,
  optionMeta: {
    id: type,
    displayText: typesDisplayTexts[type],
  },
})

const OptionsDropdown = ({ onSelectorChanged, entryInitState }) => {
  const [selectedType, setSelectedType] = useState({})

  useEffect(() => {
    if (!!entryInitState.type) {
      setSelectedType({
        [entryInitState.type]: typeToOption(entryInitState.type),
      })
    } else if (!selectedType.option) {
      setSelectedType({
        [EXCLUDE_SCHEDULE_TYPE]: typeToOption(EXCLUDE_SCHEDULE_TYPE),
      })
    }
  }, [])

  const onOptionSelected = (selected, option) => {
    setSelectedType({
      [option.option]: option,
    })
  }

  useEffect(() => {
    const selected = Object.values(selectedType)
    if (selected.length) {
      onSelectorChanged(selected[0].optionMeta.id)
    }
  }, [selectedType])

  const selectedOptionLabel = () => {
    return Object.values(selectedType)[0]?.optionMeta.displayText
  }

  const options = allExcludeTypes.map(typeToOption)

  return (
    <Dropdown
      className="optionsDropdown"
      mr={`${theme.space[4]}px`}
      triggerWrapperComponent={({ dropdownControls }) => (
        <StyledDropdownButton
          variant="outline"
          onClick={() => dropdownControls.toggle()}
          width="144px"
          height="50px"
        >
          <Flex justifyContent="space-between">
            <Box>{selectedOptionLabel()}</Box>
            <Box
              as={ArrowDropDownRounded}
              ml={theme.space[3] + 'px'}
              fontSize={theme.fontSizes.pExtraLarge18}
            />
          </Flex>
        </StyledDropdownButton>
      )}
      dropdownComponent={() => (
        <StyledDropdownBodyWrapper>
          <CheckboxesList
            options={options}
            checkedOptions={selectedType}
            onCheckBoxClicked={onOptionSelected}
            containerProps={{
              className: 'dropdown-list',
            }}
          />
        </StyledDropdownBodyWrapper>
      )}
    />
  )
}

export default OptionsDropdown
