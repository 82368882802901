import React from 'react'

import { Box } from 'app/components/design-system-components'

import 'app/sm/contact_list/header/style.scss'

const Header = () => {
  return (
    <div className="contact-list-header-container">
      <div className="contact-list-header-title">Contacts</div>
      <Box pl="5px" m="10px 0">
        Add renter enquiries via Messages and owners via On List
      </Box>
    </div>
  )
}

export default Header
