import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { Box, Dropdown, Flex } from 'app/components/design-system-components'
import { ArrowDropDownRounded } from 'app/components/design-system-components/icons/navigation'
import { CheckboxesList } from 'app/components/design-system-components/list-filters/CheckboxesList'
import { CustomInputWrapper } from 'app/forms/CustomInputWrapper'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { DefaultMessageTemplates } from 'app/sm/inspections/viewing_registrants_message_modal/constants'
import {
  DropdownBodyWrapper,
  StyledDropdownButton,
} from 'app/sm/team_viewing_preference/states_dropdown'

export const StyledDropdownBodyWrapper = styled(DropdownBodyWrapper)`
  .dropdown-list:first-child {
    .form-checkbox {
      padding-top: ${theme.baseSpace}px;
    }
  }

  width: 650px;

  @media screen and (max-width: 576px) {
    width: 486px;
  }
`

const CustomStyledDropdownButton = styled(StyledDropdownButton)`
  border-color: ${theme.colors.grey200};
`

const StyledOptionsContainer = styled.div`
  input[type='checkbox'] {
    display: none !important;
  }

  .dropdown-list {
    width: 100% !important;
  }
`

const TemplatesDropdown = ({ changeHandler, value }) => {
  const [selectedOption, setSelectedOption] = useState({})

  const options = Object.keys(DefaultMessageTemplates).map((key) => ({
    option: key,
    optionMeta: {
      id: key,
      displayText: DefaultMessageTemplates[key].header,
    },
  }))

  useEffect(() => {
    setSelectedOption({
      [value]: options.find(({ option }) => option === value),
    })
  }, [value])

  return (
    <Dropdown
      triggerWrapperComponent={({ dropdownControls }) => (
        <CustomStyledDropdownButton
          variant="outline"
          onClick={() => dropdownControls.toggle()}
          height="40px"
          width="100%"
          type="button"
        >
          <Flex justifyContent="space-between">
            <Box>
              {Object.values(selectedOption)[0]?.optionMeta.displayText ||
                'Select template '}
            </Box>
            <Box
              as={ArrowDropDownRounded}
              ml={theme.space[3] + 'px'}
              fontSize={theme.fontSizes.pExtraLarge18}
            />
          </Flex>
        </CustomStyledDropdownButton>
      )}
      dropdownComponent={() => (
        <StyledDropdownBodyWrapper>
          <StyledOptionsContainer>
            <CheckboxesList
              width="100%"
              options={options}
              checkedOptions={selectedOption}
              onCheckBoxClicked={(selected, option) => {
                changeHandler(option.option)
              }}
              containerProps={{
                className: 'dropdown-list',
              }}
            />
          </StyledOptionsContainer>
        </StyledDropdownBodyWrapper>
      )}
    />
  )
}

const TemplatesDropdownField = (props) => {
  const { name, templateChanged } = props
  return (
    <CustomInputWrapper
      name={name}
      customInputCmp={({ changeHandler, value }) => (
        <TemplatesDropdown
          value={value}
          changeHandler={(e) => {
            changeHandler(e)
            templateChanged(e)
          }}
        />
      )}
    />
  )
}

export default TemplatesDropdownField
