import React, { Component } from 'react'

class AuthenticationModal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { ok, cancelModal } = this.props

    return (
      <div className="modal-fullscreen">
        <div className="modal-wrapper">
          <div className="modal-close">
            <i className="fa fa-times" onClick={() => cancelModal()} />
          </div>
          <div className="modal-header">
            <h1>Saved Property</h1>
          </div>
          <div className="modal-body">
            <h3>
              To add a property in Saved Properties you need to be logged in
              first
            </h3>
            <button className="snug-button" onClick={() => ok()}>
              Login
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default AuthenticationModal
