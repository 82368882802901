import React from 'react'

import jobIcon from 'app/assets/icons/summary_icons/job.svg'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import SummaryStaticEmploymentBox from 'app/match/apply/application_summary_before_submit/shared_components/employment_box/component'
import Checkbox from 'app/shared_components/checkbox'
import { urlTo } from 'app/sm/helpers'

const EmploymentContainer = ({
  componentClassName = '',
  employers = [],
  optOutEmploymentHistory = false,
  isEmploymentEditable = true,
  onEditClick,
  enableNote = false,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  displayedApplicant,
  section,
  ballotsEnabled = false,
}) => {
  return (
    <div className={`container-component ${componentClassName}`}>
      <ContainerHeader
        icon={jobIcon}
        text="Employment Details"
        isEditable={isEmploymentEditable}
        onEditClick={() =>
          onEditClick(
            `${urlTo('EmploymentDetailsList')}?from=rentersummary${
              ballotsEnabled && `&ballot=true`
            }`,
          )
        }
        enableNote={enableNote}
        onNewNoteUpdated={onNewNoteUpdated}
        newNotesContent={newNotesContent}
        onNoteIconClicked={onNoteIconClicked}
        onNewNoteAdded={onNewNoteAdded}
        onNoteCrossClicked={onNoteCrossClicked}
        interpreteNoteResult={interpreteNoteResult}
        enableNotesButton={enableNotesButton}
        displayedApplicant={displayedApplicant}
        section={section}
      />
      <div className="mt30">
        {employers.length > 0 &&
          employers.map((employer) => (
            <SummaryStaticEmploymentBox
              employer={employer}
              key={employer.guidID}
            />
          ))}
        <div className="mt30">
          {employers.length === 0 && !optOutEmploymentHistory && (
            <div>Not provided</div>
          )}
          {employers.length === 0 && optOutEmploymentHistory && (
            <div>Does not have employment history</div>
          )}
          {employers.length !== 0 && optOutEmploymentHistory && (
            <div className="disabled-link">
              <Checkbox
                label="No further employment history"
                value={optOutEmploymentHistory}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default EmploymentContainer
