import React, { Component } from 'react'

import moment from 'moment'
import Dropzone from 'react-dropzone'

import * as helpers from 'app/sm/helpers'

class DropZoneSnugStandard extends Component {
  constructor() {
    super()
    this.onDrop = (files) => {
      this.props.onDrop(files)
    }
    this.state = {
      files: [],
    }
    this.onRemoveIconClick = this.onRemoveIconClick.bind(this)
  }

  onRemoveIconClick = (event, index) => {
    const { onRemoveIconClciked } = this.props
    onRemoveIconClciked(index)
    event.preventDefault()
  }

  render() {
    const files = this.props.files.map((file, index) => {
      return (
        <tr key={index}>
          <td>
            <a className="gray-dark-color" href={file.URL} download={file.name}>
              {helpers.trimFileLength(11)(file.name)}
            </a>
          </td>
          <td>{moment(file.lastModifiedDate).format('DD MMM YYYY')}</td>
          <td className="actionButtonWrapper">
            <button
              onClick={(event) => {
                this.onRemoveIconClick(event, index)
              }}
              className="remove-btn"
            >
              <i className="icon-remove" aria-hidden="true" />
            </button>
          </td>
        </tr>
      )
    })

    return (
      <Dropzone onDrop={this.onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section className="width100">
            <aside>
              <div className="sm-file-upload single">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <form id="property-photos-upload">
                    <label htmlFor="photos">
                      <div>
                        <i className="icon-image" />
                        <b className="pr0">Click to upload</b>
                        <span>, or drag and drop</span>
                      </div>
                      <span className="font-size-12 fw500">
                        (max 10mb - pdf, doc, xlsx, png, jpg, jpeg)
                      </span>
                    </label>
                  </form>
                </div>

                <table className="style3 mt15 mb30">
                  <tbody>{files}</tbody>
                </table>
              </div>
            </aside>
          </section>
        )}
      </Dropzone>
    )
  }
}

export default DropZoneSnugStandard
