import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import preQualifyClosed from 'app/assets/icons/container-closed.svg'
import preQualifyOpen from 'app/assets/icons/container-open.svg'
import * as Form from 'app/components/forms/forms'
import { BasicModalWithFixedButtons } from 'app/modals/basic_modal'
import * as FormComponent from 'app/shared_components/form_component'
import { NO, YES } from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  loadingStatesIds,
} from 'app/utils/loading-states'
import { NUMBER_TYPE_MOBILE } from 'app/utils/phonenumber/helpers'
import * as vaccinationHelpers from 'app/utils/vaccination/helpers'
import VaccinationRadioComponent from 'app/utils/vaccination/radio_component'
import * as viewingsHelpers from 'app/utils/viewings/helpers'

// TODO: Refactor PreQualifyInfoContainer, and place it where to be reused
const PreQualifyInfoContainer = styled.div`
  box-shadow: 0px 3px 5px rgba(34, 33, 42, 0.08);
  width: 100%;
  padding: 15px;
  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
  }
  img {
    float: right;
  }
`

export class ViewingRequestFormModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      preQualifyInfoContainerOpened: false,
      vaccinationQuestionsContainerOpened: false,
      modalFormValue: {
        firstName: '',
        lastName: '',
        contactNumber: '',
        email: '',
        viewings: {},
        weeklyIncome: '',
        currentResidenceStartMonth: '',
        currentResidenceStartYear: '',
        weeklyHousingPayment: '',
        hasColdSymptoms: '',
        recentOverseasVisit: '',
        covid19VaccinationStatus: '',
        preferredDate: '',
        preferredTime: '',

        bedrooms: '',
        rent: '',
        postcode: '',
      },
      errors: {
        firstName: '',
        lastName: '',
        contactNumber: '',
        email: '',
        weeklyIncome: '',
        currentResidenceStartMonth: '',
        currentResidenceStartYear: '',
        weeklyHousingPayment: '',
        hasColdSymptoms: '',
        recentOverseasVisit: '',
        covid19VaccinationStatus: '',
        preferredDate: '',
        preferredTime: '',

        bedrooms: '',
        rent: '',
        postcode: '',
      },

      submissionLoadingStates: createInitialLoadingState(),
    }

    this.submissionLoadingStateUtils = createLoadingStateUtils((state) => {
      this.setState({
        submissionLoadingStates: state,
      })
    })
  }

  submitViewingRequest() {
    const { onSubmission } = this.props
    const { modalFormValue } = this.state
    const errors = this.checkForErrorsInForm()
    this.setState(
      {
        errors: {
          ...this.state.errors,
          ...errors,
        },
      },
      () => {
        if (
          !Object.keys(this.state.errors).find(
            (field) => this.state.errors[field] !== '',
          )
        ) {
          const readyForm = this.transformFormData(modalFormValue)
          this.submissionLoadingStateUtils.startLoading()
          onSubmission(readyForm)
            .then(() => {
              this.submissionLoadingStateUtils.markDoneSuccessfully()
            })
            .catch((err) => {
              this.submissionLoadingStateUtils.setError(err)
            })
        }
      },
    )
  }

  transformFormData(form) {
    const { bedroomsDropdown } = this.props

    // TODO: Clean
    form.bedrooms = form.bedrooms ? bedroomsDropdown[form.bedrooms] : null
    form.bedrooms = form.bedrooms === '5+' ? 6 : form.bedrooms

    // Remove null, undefined, and empty strings
    const cleanForm = Object.keys(form)
      .filter((k) => form[k] != null && form[k] !== '')
      .reduce((obj, key) => {
        obj[key] = form[key]
        return obj
      }, {})

    return {
      ...cleanForm,
      ...(cleanForm.bedrooms && { bedrooms: parseInt(cleanForm.bedrooms) }),
      ...(cleanForm.rent && { rent: parseFloat(cleanForm.rent) }),
    }
  }

  onModalValueChange(field) {
    return (data) => {
      this.setState({
        modalFormValue: {
          ...this.state.modalFormValue,
          [field]: data.value,
        },
        errors: {
          ...this.state.errors,
          [field]: data.error,
        },
      })
    }
  }

  onChangeRadioGroup = (radioGroupID) => {
    return (event) => {
      const { target } = event
      const { id } = target
      this.setState({
        modalFormValue: {
          ...this.state.modalFormValue,
          [radioGroupID]: id,
        },
        errors: { ...this.state.errors, [radioGroupID]: '' },
      })
    }
  }

  onChangeRadioGroupCovid19VaccinationStatus = (radioGroupID) => {
    return (event) => {
      const { target } = event
      const { id } = target

      this.setState({
        modalFormValue: {
          ...this.state.modalFormValue,
          [radioGroupID]: vaccinationHelpers.vaccinationStatusMap[id],
        },
        errors: { ...this.state.errors, [radioGroupID]: '' },
      })
    }
  }

  onDropDownValueChange = (field) => {
    return (data) => {
      this.setState({
        modalFormValue: {
          ...this.state.modalFormValue,
          [field]: data.value,
        },
        errors: {
          ...this.state.errors,
          [field]: data.error,
        },
      })
    }
  }

  preferredDateChange = ({ value, error }) => {
    let convertedDate = value
    if (error === '') {
      convertedDate =
        value !== '' ? moment(value).format(dateTimeHelpers.DATE_WITH_DASH) : ''
    }

    this.setState({
      modalFormValue: {
        ...this.state.modalFormValue,
        preferredDate: convertedDate,
      },
      errors: {
        ...this.state.errors,
        preferredDate: error,
      },
    })
  }

  checkForErrorsInForm = () => {
    const { modalFormValue } = this.state
    let errors = {}
    Object.keys(modalFormValue)
      .filter((value) => value !== 'viewing')
      .filter((value) => value !== 'weeklyIncome')
      .filter((value) => value !== 'currentResidenceStartMonth')
      .filter((value) => value !== 'currentResidenceStartYear')
      .filter((value) => value !== 'weeklyHousingPayment')
      .filter((value) => value !== 'contactNumber')
      .filter((value) => value !== 'preferredDate')
      .filter((value) => value !== 'preferredTime')
      .filter((value) => value !== 'bedrooms')
      .filter((value) => value !== 'rent')
      .filter((value) => value !== 'hasColdSymptoms')
      .filter((value) => value !== 'recentOverseasVisit')
      .filter((value) => value !== 'postcode')
      .filter((value) => value !== 'covid19VaccinationStatus')
      .forEach((value) => {
        if (this.state.modalFormValue[value] === '') {
          errors = Object.assign(errors, {
            [value]: 'This field cannot be empty',
          })
        }
      })
    if (
      this.state.errors.preferredDate ===
      viewingsHelpers.oneDayLaterErrorMessage
    ) {
      return errors
    } else if (
      (this.state.modalFormValue['preferredTime'] === '' &&
        this.state.modalFormValue['preferredDate'] !== '' &&
        this.state.errors.preferredDate !==
          viewingsHelpers.oneDayLaterErrorMessage) ||
      (this.state.modalFormValue['preferredDate'] === '' &&
        this.state.modalFormValue['preferredTime'] !== '')
    ) {
      errors = Object.assign(errors, {
        preferredTime: 'Both time and date required',
        preferredDate: 'Both time and date required',
      })
    } else {
      errors = Object.assign(errors, {
        preferredDate: '',
        preferredTime: '',
      })
    }
    return errors
  }

  render() {
    const {
      title,
      toggleFn,
      submissionButtonLabel,
      isReadOnly,
      viewingTimeTitle,
      durationMinutesDropdown,
      bedroomsDropdown,
    } = this.props

    const {
      preQualifyInfoContainerOpened,
      submissionLoadingStates,
      vaccinationQuestionsContainerOpened,
      modalFormValue: {
        firstName,
        lastName,
        email,
        contactNumber,
        weeklyIncome,
        currentResidenceStartMonth,
        currentResidenceStartYear,
        weeklyHousingPayment,
        hasColdSymptoms,
        covid19VaccinationStatus,
        recentOverseasVisit,
        preferredDate,
        preferredTime,

        bedrooms,
        rent,
        postcode,
      },
      errors: {
        firstName: firstNameError,
        lastName: lastNameError,
        email: emailError,
        contactNumber: contactNumberError,
        weeklyIncome: weeklyIncomeError,
        currentResidenceStartMonth: currentResidenceStartMonthError,
        currentResidenceStartYear: currentResidenceStartYearError,
        weeklyHousingPayment: weeklyHousingPaymentError,
        hasColdSymptoms: hasColdSymptomsError,
        covid19VaccinationStatus: covid19VaccinationStatusError,
        recentOverseasVisit: recentOverseasVisitError,
        bedrooms: bedroomsError,
        rent: rentError,
        postcode: postcodeError,
      },
    } = this.state

    const formattedPreferredDate =
      this.state.errors.preferredDate !== dateTimeHelpers.INVALID_DATE_STRING
        ? preferredDate &&
          moment(preferredDate).format(dateTimeHelpers.DATE_WITH_SLASH)
        : preferredDate

    return (
      <BasicModalWithFixedButtons
        toggleModal={() => toggleFn()}
        title={title}
        primaryButtonLabel={submissionButtonLabel}
        secondaryButtonLabel="Cancel"
        secondaryButtonAction={() => toggleFn()}
        primaryButtonAction={() => this.submitViewingRequest()}
        primaryDisabled={
          submissionLoadingStates.state === loadingStatesIds.LOADING
        }
        primaryIsLoading={
          submissionLoadingStates.state === loadingStatesIds.LOADING
        }
        componentClassName="property-details-request-viewing-modal"
        body={
          <div>
            <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
              <div className="col-md-6 p0 width100">
                <Form.InputName
                  value={firstName}
                  error={firstNameError}
                  label="First name*"
                  onChange={this.onModalValueChange('firstName')}
                  labelClass="top18"
                  inputClass="width100"
                  componentClass="margin-profile-item-left"
                />
              </div>
              <div className="col-md-6 p0 width100">
                <Form.InputName
                  value={lastName}
                  error={lastNameError}
                  label="Last name*"
                  onChange={this.onModalValueChange('lastName')}
                  labelClass="top18"
                  inputClass="width100"
                  componentClass="margin-profile-item-right"
                />
              </div>
            </Form.ProfilePagesRowLayout>
            <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
              <div className="col-md-6 p0 width100">
                <Form.InputEmail
                  value={email}
                  error={emailError}
                  label="Email*"
                  onChange={this.onModalValueChange('email')}
                  labelClass="top18"
                  inputClass="width100"
                  componentClass="margin-profile-item-left"
                />
              </div>
              <div className="col-md-6 p0 width100">
                <Form.InputPhoneNumber
                  label="Mobile number"
                  value={contactNumber}
                  error={contactNumberError}
                  onChange={this.onModalValueChange('contactNumber')}
                  id="contactNumber"
                  componentClass="margin-profile-item-right"
                  containerClassName="width100"
                  numberType={NUMBER_TYPE_MOBILE}
                />
              </div>
            </Form.ProfilePagesRowLayout>
            <PreQualifyInfoContainer className="mb20">
              <div
                onClick={() =>
                  this.setState({
                    preQualifyInfoContainerOpened:
                      !this.state.preQualifyInfoContainerOpened,
                  })
                }
              >
                <span>Pre-Qualify with additional information</span>
                <img
                  className="cursor-pointer"
                  src={
                    preQualifyInfoContainerOpened
                      ? preQualifyOpen
                      : preQualifyClosed
                  }
                  alt="pre qualify status"
                />
              </div>
              {preQualifyInfoContainerOpened && (
                <div className="mt30">
                  <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
                    <div className="col-md-4 pl0 pr10 width100">
                      <span className="font-size-16">
                        Weekly income (after tax)
                        <sup>#</sup>
                      </span>
                      <Form.InputNumber
                        value={weeklyIncome}
                        error={weeklyIncomeError}
                        label="$"
                        onChange={this.onModalValueChange('weeklyIncome')}
                        labelClass="top18"
                        inputClass="width100"
                        componentClass="margin-profile-item-left"
                        isOptional
                      />
                    </div>
                    <div className="col-md-8 pl0 pr10 width100">
                      <span className="font-size-16">
                        Start date at current address
                        <sup>#</sup>
                      </span>
                      <div className="col-md-6 p0 width70">
                        <Form.Dropdown
                          label="Year"
                          value={currentResidenceStartYear}
                          error={currentResidenceStartYearError}
                          options={dateTimeHelpers.yearsFrom1950WithGuidAndName}
                          onChange={this.onModalValueChange(
                            'currentResidenceStartYear',
                          )}
                          id="chosenYear"
                          inputClass="width100"
                          componentClass="margin-profile-item-left"
                          isRequired={true}
                          idAsValue={true}
                        />
                      </div>
                      <div className="col-md-6 p0 width70">
                        <Form.Dropdown
                          label="Month"
                          value={currentResidenceStartMonth}
                          error={currentResidenceStartMonthError}
                          options={dateTimeHelpers.MONTHS_GUIDs}
                          onChange={this.onDropDownValueChange(
                            'currentResidenceStartMonth',
                          )}
                          id="chosenMonth"
                          inputClass="width100"
                          componentClass="margin-profile-item-left"
                          isRequired={true}
                          idAsValue={true}
                        />
                      </div>
                    </div>
                  </Form.ProfilePagesRowLayout>
                  <Form.ProfilePagesRowLayout>
                    <div>
                      <span className="font-size-16">
                        Weekly rent or mortgage payments
                        <sup>#</sup>
                      </span>
                    </div>
                    <div className="col-md-5 pl0 pr0">
                      <Form.InputNumber
                        value={weeklyHousingPayment}
                        error={weeklyHousingPaymentError}
                        label="$"
                        onChange={this.onDropDownValueChange(
                          'weeklyHousingPayment',
                        )}
                        labelClass="top18"
                        inputClass="width100"
                        componentClass="margin-profile-item-left"
                        isOptional
                      />
                    </div>
                  </Form.ProfilePagesRowLayout>
                </div>
              )}
            </PreQualifyInfoContainer>
            <PreQualifyInfoContainer className="mb20">
              <div
                onClick={() =>
                  this.setState({
                    vaccinationQuestionsContainerOpened:
                      !this.state.vaccinationQuestionsContainerOpened,
                  })
                }
              >
                <span>COVID disclosures (optional)</span>
                <img
                  className="cursor-pointer"
                  src={
                    vaccinationQuestionsContainerOpened
                      ? preQualifyOpen
                      : preQualifyClosed
                  }
                  alt="pre qualify status"
                />
              </div>
              {vaccinationQuestionsContainerOpened && (
                <>
                  <div className="mt10 width100">
                    <FormComponent.RadioGroup
                      label="Have you been overseas or in a COVID affected area in the last 14 days?*"
                      id="recentOverseasVisit"
                      field="recentOverseasVisit"
                      error={recentOverseasVisitError}
                      componentClassName="property-details-radio-group"
                    >
                      <FormComponent.Checkbox
                        id="false"
                        label="No"
                        checked={recentOverseasVisit === NO}
                        onChange={this.onChangeRadioGroup(
                          'recentOverseasVisit',
                        )}
                      />
                      <FormComponent.Checkbox
                        id="true"
                        label="Yes"
                        checked={recentOverseasVisit === YES}
                        onChange={this.onChangeRadioGroup(
                          'recentOverseasVisit',
                        )}
                      />
                    </FormComponent.RadioGroup>
                  </div>
                  <div className="mt10">
                    <FormComponent.RadioGroup
                      label="Do you have any cold or flu symptoms?*"
                      id="hasColdSymptoms"
                      field="hasColdSymptoms"
                      error={hasColdSymptomsError}
                      componentClassName="property-details-radio-group"
                    >
                      <FormComponent.Checkbox
                        id="false"
                        label="No"
                        checked={hasColdSymptoms === NO}
                        onChange={this.onChangeRadioGroup('hasColdSymptoms')}
                      />
                      <FormComponent.Checkbox
                        id="true"
                        label="Yes"
                        checked={hasColdSymptoms === YES}
                        onChange={this.onChangeRadioGroup('hasColdSymptoms')}
                      />
                    </FormComponent.RadioGroup>
                  </div>
                  <VaccinationRadioComponent
                    covid19VaccinationStatusError={
                      covid19VaccinationStatusError
                    }
                    covid19VaccinationStatus={covid19VaccinationStatus}
                    onChangeRadioGroupCovid19VaccinationStatus={(event) =>
                      this.onChangeRadioGroupCovid19VaccinationStatus(
                        'covid19VaccinationStatus',
                      )(event)
                    }
                  />
                </>
              )}
            </PreQualifyInfoContainer>

            <div className="mt10 mb10">{viewingTimeTitle}</div>
            <div className="mb10 fs14">
              <strong>Note</strong>: This is not a confirmed time. The property
              manager will contact you to confirm.
            </div>
            <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
              <div className="col-md-6 p0 mt2 width100">
                <Form.InputFutureDateOneDayLater
                  value={formattedPreferredDate}
                  onChange={this.preferredDateChange}
                  error={this.state.errors.preferredDate}
                  label="Date"
                  viewMode="days"
                  containerClassName="width100"
                  componentClass="margin-profile-item-left width100"
                  customisedDateFormat={dateTimeHelpers.DATE_WITH_SLASH}
                  inputClassName={isReadOnly ? 'border-color-grey' : undefined}
                  readOnly={isReadOnly}
                />
              </div>
              <div className="col-md-6 p0 width100">
                <Form.Dropdown
                  options={durationMinutesDropdown}
                  value={preferredTime}
                  onChange={this.onModalValueChange('preferredTime')}
                  error={this.state.errors.preferredTime}
                  label="Time"
                  idAsValue={true}
                  inputClass="width100"
                  componentClass="margin-profile-item-right"
                  selectIsDisabled={isReadOnly}
                />
              </div>
            </Form.ProfilePagesRowLayout>
            <Form.ProfilePagesRowLayout containerClass="profile-layout-two-items">
              <div className="col-md-6 p0 mt2 width100">
                <h5>Property Preferences</h5>
                <div className="col-xs-6 col-md-4 pl0">
                  <Form.Dropdown
                    options={bedroomsDropdown}
                    value={bedrooms}
                    error={bedroomsError}
                    label="Bedrooms"
                    onChange={this.onModalValueChange('bedrooms')}
                    labelClass="top18"
                    inputClass="width100"
                    componentClass="margin-profile-item-left"
                    isOptional
                  />
                </div>
                <div className="col-xs-6 col-md-4 pl0">
                  <Form.InputNumber
                    value={rent}
                    error={rentError}
                    label="Rent $ p/w"
                    onChange={this.onModalValueChange('rent')}
                    labelClass="top18"
                    inputClass="width100"
                    componentClass="margin-profile-item-left"
                    isOptional
                  />
                </div>
                <div className="col-xs-12 col-md-4 pl0">
                  <Form.InputNumber
                    value={postcode}
                    error={postcodeError}
                    label="Postcode"
                    onChange={this.onModalValueChange('postcode')}
                    labelClass="top18"
                    inputClass="width100"
                    componentClass="margin-profile-item-left"
                    isOptional
                  />
                </div>
              </div>
            </Form.ProfilePagesRowLayout>
            <div className="mt15 mb20 font-size-12">
              This real estate agency uses Snug for managing the leasing process
              and to provide related services to you. By providing information
              you agree to the Snug Privacy Policy regarding the collection,
              storage and disclosure of information via Snug. Note agency may
              require or apply an additional privacy policy or consent and
              manage your data outside of this agreement.
            </div>
          </div>
        }
      />
    )
  }
}
