import React from 'react'

import AddressHistory from 'app/match/applicationReportPDF/pages/Snug-pdf-2/AddressHistory'
import EmploymentDetails from 'app/match/applicationReportPDF/pages/Snug-pdf-2/EmploymentDetails'

const SnugPdf_2 = (props) => {
  const {
    employmentInfo = [],
    addressHistoryInfo = [],
    excludeRequestReasonForMoving = false,
  } = props
  return (
    <div>
      <EmploymentDetails data={employmentInfo} />
      <AddressHistory
        data={addressHistoryInfo}
        excludeRequestReasonForMoving={excludeRequestReasonForMoving}
      />
    </div>
  )
}

export default SnugPdf_2
