import React, { useState } from 'react'

import { Formik } from 'formik'
import { Link } from 'react-router-dom'

import { Alert } from 'app/components/design-system-components'
import { forgottenEmail } from 'app/services/http/session'
import {
  formFields,
  formValidation,
} from 'app/session/login_components/forgotten_email/form.utils'
import FormBody from 'app/session/login_components/forgotten_email/form_body'
import TermsAndConditions from 'app/session/login_components/terms_and_conditions'
import SnugLogo from 'app/shared_components/snug_logo'
import { urlTo } from 'app/sm/helpers'

const ForgotEmail = () => {
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: '',
    message: '',
  })

  const onSubmit = ({ mobile }) => {
    return forgottenEmail({ mobile })
      .then(() => {
        setAlertConfig({
          show: true,
          variant: 'blueWithBg',
          message: `We have sent a message to ${mobile} if the account exists`,
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'primaryWithBg',
          message: err.message || 'Failed to process your request',
        })
      })
  }

  const initialValues = {
    [formFields.mobile]: null,
  }

  const renderAlertElement = () => {
    if (!alertConfig.show) return

    return (
      <Alert variant={alertConfig.variant} mb="40px">
        {alertConfig.message}
      </Alert>
    )
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-screen panel panel-default">
        <div className="panel-heading">
          <div className="app-header">
            <div className="app-header_left" />
            <div className="app-header_center">
              <SnugLogo />
            </div>
            <div className="app-header_right">
              <Link to={urlTo('join')}>Log in</Link>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <h3 className="mb30">Forgot Account Details</h3>

          <p className="mb40">
            If you can't remember your email (or you registered with a typo),
            SMS your account email address to your registered number.
          </p>

          {renderAlertElement()}

          <Formik
            initialValues={initialValues}
            enableReinitialize
            validateOnMount
            onSubmit={onSubmit}
            validate={formValidation}
            validateOnBlur
            validateOnChange
          >
            {(props) => <FormBody formBag={props} />}
          </Formik>

          <TermsAndConditions />
        </div>
      </div>
    </div>
  )
}

export default ForgotEmail
