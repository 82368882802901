import React from 'react'

import PropTypes from 'prop-types'

import Pdf from 'app/match/applicationReportPDF/assets/documents/Document.pdf'

const propTypes = {
  atachFileName: PropTypes.string,
  link_label: PropTypes.string,
}

const DownloadAttach = ({ attachFileName, linkLabel, isAutoDeleted }) => {
  return (
    <div className="document-link-wraper">
      <a
        href={isAutoDeleted ? '' : linkLabel || Pdf}
        target="_blank"
        rel="noopener noreferrer"
        download={!isAutoDeleted}
        className={isAutoDeleted && 'disabled'}
      >
        <i className="icon icon-pdf-attach"></i>
        {attachFileName || 'Download'} {isAutoDeleted && '(auto-deleted)'}
      </a>
    </div>
  )
}

DownloadAttach.propTypes = propTypes

export default DownloadAttach
