import React from 'react'

import { Link } from 'react-router-dom'

const AdminMenu = () => {
  return (
    <div id="admin-menus">
      <ul>
        <li>
          <Link to="/admin/search">Search</Link>
        </li>
        <li>
          <Link to="/admin/alerts">Alerts</Link>
        </li>
      </ul>
    </div>
  )
}

export default AdminMenu
