import { useState } from 'react'

import { connect } from 'react-redux'

import { Breadcrumbs, TextSpan } from 'app/components/design-system-components'
import { PageHeader } from 'app/components/design-system-components/page/Header'
import { PageSection } from 'app/components/design-system-components/page/Section'
import { PageSubHeading } from 'app/components/design-system-components/page/SubHeading'
import {
  FileImporter,
  UploadFileStatuses,
} from 'app/components/design-system-components/uploads'
import { importKeys } from 'app/services/http/teams/key-logger/import-keys'
import { urlIds, urlTo } from 'app/sm/helpers'

export const PureKeysImportPage = ({ currentTeam, history }) => {
  const breadcrumbsConfigs = [
    {
      content: currentTeam.name,
      link: urlTo('teamOverview', { teamSlug: currentTeam.slug }),
    },
    {
      content: 'Keys',
      link: urlTo(urlIds.keyLogger, { teamSlug: currentTeam.slug }),
    },
    {
      content: 'Import',
    },
  ]

  const [importingState, setImportingState] = useState({
    status: UploadFileStatuses.ready,
    error: null,
    response: null,
    selectedFile: null,
    abortController: null,
    filedKeysFile: null,
  })

  const onDropFile = (files) => {
    const droppedFile = files[0]
    if (!droppedFile) {
      setImportingState((state) => ({
        ...state,
        status: UploadFileStatuses.error,
        error: 'Error while selecting keys file',
      }))
      return Promise.resolve()
    }
    const abortController = new AbortController()
    setImportingState((state) => ({
      ...state,
      status: UploadFileStatuses.loading,
      selectedFile: droppedFile,
      abortController: abortController,
    }))
    return importKeys(currentTeam.guid, droppedFile, {
      signal: abortController.signal,
    })
      .then((response) => {
        const { summary } = response
        setImportingState((state) => ({
          ...state,
          status: !!summary.failed
            ? UploadFileStatuses.doneWithErrors
            : UploadFileStatuses.done,
          response,
          abortController: null,
        }))
      })
      .catch((err) => {
        setImportingState((state) => ({
          ...state,
          status: UploadFileStatuses.error,
          error: err.combinedErrorMessage || err.message,
        }))
      })
  }

  const changeStateToReady = () => {
    setImportingState((state) => ({
      ...state,
      status: UploadFileStatuses.ready,
      error: null,
      selectedFile: null,
      response: null,
    }))
  }

  return (
    <>
      <Breadcrumbs breadcrumbsConfigs={breadcrumbsConfigs} />
      <PageHeader title="Import Keys" />
      <PageSection>
        <TextSpan lineHeight="1.4">
          Import existing keys and check-in/check-out status from
          InspectRealEstate. <a href="/">Learn more</a>
        </TextSpan>
      </PageSection>

      <PageSubHeading title="Upload" />
      <PageSection>
        <FileImporter
          onDrop={onDropFile}
          onMoveToReady={changeStateToReady}
          onCancel={() => importingState.abortController.abort()}
          onGoTo={() =>
            history.push(
              urlTo(urlIds.keyLogger, { teamSlug: currentTeam.slug }),
            )
          }
          selectedFile={importingState.selectedFile}
          statusConfig={{
            status: importingState.status,
            error: importingState.error,
            response: importingState.response,
          }}
          entity="keys"
        />
      </PageSection>
    </>
  )
}

const mapStateToProps = ({ session }) => {
  return {
    currentTeam: session.currentTeam,
  }
}

export const KeysImportPage = connect(mapStateToProps)(PureKeysImportPage)
