import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import * as validations from 'app/shared_components/validations'

export const defaultFranchise = 'Independent office / group'

export const agencyDetailsFormFields = {
  address: 'address',
  companyName: 'companyName',
  abn: 'abn',
  agencyName: 'agencyName',
  realEstateLicense: 'realEstateLicense',
  franchiseGroup: 'franchiseGroup',
  rentalPortfolioSize: 'rentalPortfolioSize',
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case agencyDetailsFormFields.agencyName: {
      return {
        required: !val && 'Agency name is required',
        valid: validations.validateAgencyName(
          val.trim(),
          'Invalid agency name',
        )[0],
      }
    }
    case agencyDetailsFormFields.companyName: {
      return {
        required: !val && 'Company name is required',
        val: validations.validateCompanyName(
          val.trim(),
          'Invalid company name',
        )[0],
      }
    }
    case agencyDetailsFormFields.abn: {
      return {
        required: !val && 'ABN is required',
        valid: validations.validateABN(
          val.replace(/\s+/g, ''),
          'Must be a valid ABN',
        )[0],
      }
    }
    case agencyDetailsFormFields.realEstateLicense: {
      return {
        required: !val && 'Real estate license is required',
        valid: validations.validateREALicense(
          val.replace(/\s+/g, ''),
          'Licence number must be 6-9 characters',
        )[0],
      }
    }
    case agencyDetailsFormFields.franchiseGroup: {
      return {
        required: !val && 'Franchise group is required',
      }
    }
    case agencyDetailsFormFields.rentalPortfolioSize: {
      return {
        required: !val && 'Rental portfolio size is required',
        valid: validations.validateNumber(
          val.trim(),
          'Rental portfolio size must be a number',
        )[0],
      }
    }
    default:
      return {}
  }
}

export const agencyDetailsFormValidation =
  formValidationBuilder(fieldValidation)
