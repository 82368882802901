import React, { useEffect, useRef } from 'react'
import { useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import OverlayPortalModal from 'app/components/design-system-components/modals/OverlayPortalModal'
import { debounce } from 'app/helpers/debounce'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { StyledDropdownButton } from 'app/sm/team_viewing_preference/states_dropdown.jsx'

const useDropdown = (topSpaceInPx = 2) => {
  const [boundariesValues, setBoundariesValues] = useState({})
  const wrapperRef = useRef(null)

  useEffect(() => {
    updateBoundaries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef])

  useEffect(() => {
    document.addEventListener('scroll', debouncedScrollListener, true)
    return () => document.removeEventListener('scroll', debouncedScrollListener)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const debouncedScrollListener = debounce(() => updateBoundaries(), 10)

  const updateBoundaries = () => {
    const boundaries =
      wrapperRef.current && wrapperRef.current.getBoundingClientRect()

    if (!boundaries) return
    const { bottom, width, left } = boundaries
    const calculatedBoundaries = {
      top: bottom + topSpaceInPx,
      width: width,
      left,
    }
    if (
      calculatedBoundaries.top === boundariesValues.top &&
      calculatedBoundaries.width === boundariesValues.width &&
      calculatedBoundaries.left === boundariesValues.left
    ) {
      return
    }
    setBoundariesValues(calculatedBoundaries)
  }

  return { boundariesValues, setBoundariesValues, wrapperRef, updateBoundaries }
}

const DropdownCore = ({
  isOpened,
  wrapperRef,
  updateBoundaries,
  boundariesValues,
  triggerWrapperComponent,
  triggerWrapperComponentProps,
  dropdownComponent,
  dropdownComponentProps,
  useWrapperWidth = false,
  onClickOutside = null,
  ...otherProps
}) => {
  const dropdownBodyRef = useRef(null)

  const clickEventListener = (e) => {
    const dropdownBodyNode = dropdownBodyRef.current
    const triggerNode = wrapperRef.current
    if (!dropdownBodyNode || !triggerNode) return
    if (
      !dropdownBodyNode.contains(e.target) &&
      !triggerNode.contains(e.target)
    ) {
      onClickOutside()
    }
  }

  useEffect(() => {
    if (!onClickOutside) return
    document.addEventListener('click', clickEventListener)
    return () => document.removeEventListener('click', clickEventListener)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isOpened) {
      updateBoundaries()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened])

  return (
    <Box {...otherProps}>
      <Box ref={wrapperRef}>
        {triggerWrapperComponent(triggerWrapperComponentProps)}
      </Box>
      {isOpened && (
        <OverlayPortalModal>
          <div
            ref={dropdownBodyRef}
            role="listbox"
            style={{
              position: 'absolute',
              top: boundariesValues.top,
              left: boundariesValues.left,
              zIndex: 201,
              ...(useWrapperWidth ? { width: boundariesValues.width } : {}),
            }}
          >
            {dropdownComponent(dropdownComponentProps)}
          </div>
        </OverlayPortalModal>
      )}
    </Box>
  )
}

export const Dropdown = (props) => {
  const {
    triggerWrapperComponent,
    dropdownComponent,
    useWrapperWidth = false,
    topSpaceInPx = 2,
    ...otherProps
  } = props

  const [isOpened, setIsOpened] = useState(false)
  const {
    boundariesValues,
    setBoundariesValues,
    wrapperRef,
    updateBoundaries,
  } = useDropdown(topSpaceInPx)

  const dropdownControls = {
    close: () => setIsOpened(false),
    open: () => setIsOpened(true),
    toggle: () => setIsOpened(!isOpened),
  }

  const rendererComponentsProps = { dropdownControls }

  return (
    <DropdownCore
      isOpened={isOpened}
      boundariesValues={boundariesValues}
      wrapperRef={wrapperRef}
      updateBoundaries={updateBoundaries}
      dropdownComponentProps={rendererComponentsProps}
      triggerWrapperComponentProps={rendererComponentsProps}
      onClickOutside={() => setIsOpened(false)}
      {...props}
    />
  )
}

export const OutsideControlledDropdown = (props) => {
  const {
    triggerWrapperComponent,
    dropdownComponent,
    useWrapperWidth = false,
    isOpened = false,
    setIsOpened,
    topSpaceInPx,
    ...otherProps
  } = props

  const {
    boundariesValues,
    setBoundariesValues,
    wrapperRef,
    updateBoundaries,
  } = useDropdown(topSpaceInPx)
  const onClickOutside = setIsOpened ? () => setIsOpened(false) : null
  return (
    <DropdownCore
      {...props}
      boundariesValues={boundariesValues}
      wrapperRef={wrapperRef}
      updateBoundaries={updateBoundaries}
      onClickOutside={onClickOutside}
    />
  )
}

export const CustomStyledDropdownButton = styled(StyledDropdownButton)`
  border-color: ${theme.colors.grey200};
`

export const StyledOptionsContainer = styled.div`
  input[type='checkbox'] {
    display: none !important;
  }

  .dropdown-list {
    width: 100% !important;
  }
`

OutsideControlledDropdown.propTypes = {
  triggerWrapperComponent: PropTypes.func,
  dropdownComponent: PropTypes.func,
  useWrapperWidth: PropTypes.bool,
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func,
}
