import React from 'react'

import * as Display from 'app/components/display/display'
import * as Text from 'app/components/display/text/text_component'
import * as leaseOfferHelpers from 'app/utils/leaseOffer'
import * as stringHelpers from 'app/utils/strings/helpers'

const WATER_INCLUSION_UNSET = 'Unset'
const WATER_INCLUSION_INCLUDED = 'Included'
const WATER_INCLUSION_EXCLUDED = 'Excluded'
const WATER_INCLUSION_SPLIT = 'Split'

const waterInclusionMap = {
  0: WATER_INCLUSION_UNSET,
  1: WATER_INCLUSION_EXCLUDED,
  2: WATER_INCLUSION_INCLUDED,
  3: WATER_INCLUSION_SPLIT,
}

function NewLeaseOfferVersionContent({
  offeredNumPets = 0,
  offeredRentFrequency = 0,
  offeredWaterInclusion = 0,
  offeredWaterInclusionComment = '',
}) {
  return (
    <>
      <Display.FlexContainer>
        <Text.Strong text="Number of Pets" componentClass="width50p" />
        <Text.Normal text={offeredNumPets} />
      </Display.FlexContainer>
      <Display.FlexContainer>
        <Text.Strong text="Rent Frequency" componentClass="width50p" />
        <Text.Normal
          text={
            offeredRentFrequency &&
            leaseOfferHelpers.leaseDetailsRentFrequency[offeredRentFrequency]
              .name
          }
        />
      </Display.FlexContainer>
      <Display.FlexContainer>
        <Text.Strong text="Water" componentClass="width50p" />
        <Text.Normal
          componentClass="width50p"
          text={`${waterInclusionMap[offeredWaterInclusion]} ${
            offeredWaterInclusionComment &&
            '(' +
              stringHelpers.trimAndAddEllipsesPastSpecifiedLength(
                offeredWaterInclusionComment,
                50,
              ) +
              ')'
          }`}
        />
      </Display.FlexContainer>
    </>
  )
}

export default NewLeaseOfferVersionContent
