import React from 'react'

import { withRouter } from 'react-router-dom'

import { Box, TextSpan } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'
import EquifaxIntegration from 'app/pages/EquifaxIntegration'
import TicaIntegration from 'app/pages/TicaIntegration'
import { snugPricing } from 'config/external-links'

const ScreeningIntegrations = () => {
  return (
    <>
      <TextSpan as="div" lineHeight={theme.lineHeights.encrption}>
        Configure tenancy screening integrations for your team. Please note
        Integration Bundle or Background Check usage fees may apply. View
        details at{' '}
        <a href={snugPricing} target="_blank" rel="noreferrer">
          snug.com/pricing
        </a>
      </TextSpan>
      <Box my={8}>
        <EquifaxIntegration />
      </Box>
      <Box my={8}>
        <TicaIntegration />
      </Box>
    </>
  )
}

const ScreeningIntegrationsPage = withRouter(ScreeningIntegrations)

export default ScreeningIntegrationsPage
