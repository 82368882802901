import allHomesLogo from 'app/assets/referrerLogos/allhomes-logo.png'
import domainLogo from 'app/assets/referrerLogos/domain-logo.svg'
import homelyLogo from 'app/assets/referrerLogos/homely-logo.svg'

export const referrerReference = {
  homely: {
    name: 'Homely.com.au',
    referrer_group: '42ca7b4e-3094-4275-bf5b-a2d1c4ba4bb0',
    referrer_id: 'ec882446-c8e3-453a-a6ed-b9a7f6431bb9',
    logo: homelyLogo,
  },
  domain: {
    name: 'Domain.com.au',
    referrer_group: 'fff58e74-898d-42ba-866a-2fdd80c47972',
    referrer_id: 'ca860f04-4ee6-4136-b009-00e6910845e7',
    logo: domainLogo,
  },
  allHomes: {
    name: 'Allhomes.com.au',
    referrer_group: 'fff58e74-898d-42ba-866a-2fdd80c47972',
    referrer_id: 'b8b838c1-6b91-4c04-8b97-e77d217e7ffa',
    logo: allHomesLogo,
  },
  jellisCraigBaysideBrighton: {
    name: 'Jellis Craig Bayside - Brighton',
    referrer_group: 'e3d7565c-2552-427c-8e6c-6ed41f50ed75',
    referrer_id: '2798b753-15a5-4259-8723-ca30f4040de3',
    logo: '',
  },
  jellisCraigBaysideBentleigh: {
    name: 'Jellis Craig Bayside - Bentleigh',
    referrer_group: 'e3d7565c-2552-427c-8e6c-6ed41f50ed75',
    referrer_id: '6535c52f-16ae-47cc-b9f0-8b9a992891b1',
    logo: '',
  },
  jellisCraigBaysideCarnegie: {
    name: 'Jellis Craig Bayside - Carnegie',
    referrer_group: 'e3d7565c-2552-427c-8e6c-6ed41f50ed75',
    referrer_id: 'ab36a07a-5aa2-49c7-8a5b-533412205b20',
    logo: '',
  },
  raineAndHorneCorporate: {
    name: 'Raine & Horne',
    referrer_group: '17860c48-5000-4f29-982b-3517f0a662c4',
    referrer_id: 'eb306c17-4eb8-4666-9d20-a5f588aa320a',
    logo: '',
  },
}

export const getReferrer = (referrer_group, referrer_id) => {
  return Object.values(referrerReference).find(
    (ref) =>
      ref.referrer_group === referrer_group && ref.referrer_id === referrer_id,
  )
}

export const validReferrer = (referrer_group, referrer_id) => {
  return !!getReferrer(referrer_group, referrer_id)
}

export const isReferrerDomainComAU = (referrer) => {
  if (
    referrer?.referrer_group === referrerReference.domain.referrer_group ||
    referrer?.referrer_id === referrerReference.domain.referrer_id
  ) {
    return true
  }
  return false
}
