import React from 'react'

import { Link } from 'react-router-dom'

import SearchInline from 'app/sm/common/search-inline'

class Inbox extends React.Component {
  showNewMessageModal() {
    window.location.href = `/sm/chat/new-message`
  }

  render() {
    let { data = [], title = '' } = this.props
    return (
      <div className="sm-chat-inbox">
        <div className="section-title xs-row">
          <div className="left">
            <h3 className="ibm lh1">{title} Inbox (2)</h3>
            <div className="dropdown actions">
              <div className="dropdown-toggle" data-toggle="dropdown">
                <button className="btn btn-transparent pl10 pr10 pt5">
                  <i className="icon-arrow-down"></i>
                </button>
              </div>
              <ul className="dropdown-menu dropdown-menu-right">
                <li>
                  <Link to={'/'}>Inbox 1</Link>
                </li>
                <li>
                  <Link to={'/'}>Inbox 2</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="right">
            <SearchInline placeholder="Search" className="xs-hide" />
            <button
              onClick={() => this.showNewMessageModal()}
              className="btn btn-small-xs wa ml20"
            >
              <i className="icon-add left ibm fs13"></i>
              <span className="ibm">
                <span className="xs-hide">New </span>Message
              </span>
            </button>
          </div>
        </div>

        <table className="style5">
          <tbody>
            {data.map((msg, index) => (
              <tr key={index} className={msg.recordStatus}>
                <td>
                  <div className="avatar-group">
                    {msg.users.length <= 2 ? (
                      msg.users.map((usr, index) => (
                        <div
                          className="avatar avatar-msmall"
                          style={{ backgroundImage: `url('${usr.avatar}')` }}
                        ></div>
                      ))
                    ) : (
                      <div>
                        <div
                          className="avatar avatar-msmall"
                          style={{
                            backgroundImage: `url('${msg.users[0].avatar}')`,
                          }}
                        ></div>
                        <div
                          className={`avatar avatar-msmall pink-bg-light-rgba pink-color`}
                        >
                          +{msg.users.length - 1}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="ibm">
                    <a herf="#" className="block fs16 fw600 gray-dark">
                      {msg.users.length == 2 ? (
                        msg.users.map((usr, index) => (
                          <span>
                            {usr.firstName} {usr.lastName.charAt(0)} .
                            {index == 0 ? ', ' : ''}
                          </span>
                        ))
                      ) : (
                        <span>
                          {msg.users[0].firstName} {msg.users[0].lastName}
                          {msg.users.length > 2 && (
                            <span>, +{msg.users.length - 1} more</span>
                          )}
                        </span>
                      )}
                    </a>
                    <div className="fs14 pt8">
                      {msg.userStatus}
                      {msg.address != '' && `, ${msg.address}`}
                    </div>
                  </div>
                </td>
                <td>
                  <a
                    href="/sm/chat/timeline"
                    className="block fs18 fw600 gray-dark"
                  >
                    {msg.title}
                  </a>
                  <div className="fs14 pt8">{msg.lastMessage}</div>
                </td>
                <td>
                  <div className="time-box">
                    <div className="ibm fs14">{msg.time}</div>
                    {msg.unreadMessages != '' && (
                      <div className="badge">{msg.unreadMessages}</div>
                    )}
                  </div>
                  <div className="dropdown options ibm">
                    <div className="dropdown-toggle" data-toggle="dropdown">
                      <button className="btn btn-round btn-medium btn-gray-light">
                        <i className="icon-more"></i>
                      </button>
                    </div>
                    <ul className="dropdown-menu dropdown-menu-right mt5">
                      <li>
                        <Link to={'/'}>View</Link>
                      </li>
                      <li>
                        <Link to={'/'}>Edit</Link>
                      </li>
                      <li>
                        <Link to={'/'}>Delete</Link>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          {data.length >= 5 && (
            <tfoot>
              <tr>
                <td colSpan="1">
                  Showing <b>0-5</b> out of 24
                </td>
                <td colSpan="2">
                  <a href="#1" className="active">
                    1
                  </a>
                  <a href="#2">2</a>
                  <a href="#3">3</a>
                  <a href="#4">4</a>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    )
  }
}

export default Inbox
