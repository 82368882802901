import React, { useState } from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Box, Modal } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import { CheckBoxGeneral } from 'app/components/forms/forms'

function RemoveTeamMemberModal({
  toggleModal,
  name,
  onClickConfirmRemoveTeamMember,
  email,
}) {
  const [deleteUser, setDeleteUser] = useState(true)

  const displayName = name.trim().length > 0 ? name : email

  const modalBody = (
    <Box>
      <Text mb={5}>
        Are you sure? {displayName} will no longer be able to access your team.
        Assigned properties, viewings and applications will need to be
        re-assigned to an active team member.
      </Text>
      <CheckBoxGeneral
        label="Also remove user account"
        labelClass="fs16"
        checked={deleteUser}
        onChange={() => {
          setDeleteUser(!deleteUser)
        }}
        toolTip={
          <div>
            <i
              className="icon-help-outline fs16 gray-light-color ibm"
              data-tooltip-content=""
              data-tooltip-id="deleteUser"
            />
          </div>
        }
      />
      <ReactTooltip id="deleteUser">
        <span>
          Allow a new user account to be registered with the same mobile number
        </span>
      </ReactTooltip>
    </Box>
  )
  return (
    <Modal
      modalHeading="Remove Team Member"
      modalBody={modalBody}
      toggleModal={toggleModal}
      primaryLabel="Remove team member"
      secondaryLabel="Close"
      primaryAction={() => {
        onClickConfirmRemoveTeamMember(deleteUser)
      }}
      secondaryAction={toggleModal}
    />
  )
}

export default RemoveTeamMemberModal
