import styled from 'styled-components'

import { Button } from 'app/components/design-system-components/Button'
import { trimAndAddEllipsesPastSpecifiedLength } from 'app/utils/strings/helpers'

import { Box, Flex } from '.'

const StyledExtensionContainer = styled(Flex)`
  background: ${({ theme }) => `${theme.colors.skyBlue}`};
  color: ${({ theme }) => `${theme.colors.deepBlue}`};
  font-size: ${({ theme }) => `${theme.fontSizes[4]}px`};
  border-radius: ${({ theme }) => `${theme.borderRadii.attachment}`};
  width: ${({ theme }) => `${theme.width[13]}px`};
  height: ${({ theme }) => `${theme.height[12]}px`};
  align-items: center;
  justify-content: center;
`

const StyledFilenameContainer = styled(Flex)`
  background: ${({ theme }) => `${theme.colors.paleBlue}`};
  color: ${({ theme }) => `${theme.colors.gray700}`};
  width: ${({ theme }) => `${theme.width[22]}px`};
  height: ${({ theme }) => `${theme.height[12]}px`};
  align-items: center;
`

type FileAttachmentProps = {
  attachmentExtension: string
  attachmentName: string
  onRemoveButtonClicked: (id: string) => void
  fileGUID: string
  URL: string
  showRemoveButtons: boolean
}

function FileAttachment({
  attachmentExtension = '',
  attachmentName = '',
  onRemoveButtonClicked,
  fileGUID,
  URL,
  showRemoveButtons = true,
}: FileAttachmentProps) {
  return (
    <>
      <Flex alignItems="center">
        <StyledExtensionContainer>
          {attachmentExtension.toUpperCase()}
        </StyledExtensionContainer>
        <StyledFilenameContainer>
          <Box ml={4}>
            <a href={URL} target="_blank" rel="noreferrer">
              {trimAndAddEllipsesPastSpecifiedLength(attachmentName, 20)}
            </a>
          </Box>
        </StyledFilenameContainer>
        {showRemoveButtons && (
          <Box ml={4}>
            <Button
              variant="linkBlueWithoutWeight"
              onClick={() => {
                onRemoveButtonClicked(fileGUID)
              }}
            >
              remove
            </Button>
          </Box>
        )}
      </Flex>
    </>
  )
}

export default FileAttachment
