import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import {
  Box,
  Flex,
  IconButton,
  Spinner,
} from 'app/components/design-system-components'
import { ErrorOutlineRounded } from 'app/components/design-system-components/icons/alert'
import { ReplayRounded } from 'app/components/design-system-components/icons/AV'
import { ClearRounded } from 'app/components/design-system-components/icons/content'
import * as getters from 'app/components/design-system-components/theme/getters'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { calculateFileSize } from 'app/utils/attachments'
import { loadingStatesIds } from 'app/utils/loading-states'

export const Attachment = styled(Flex)`
  align-items: center;
  justify-content: space-between;

  background-color: ${getters.color('gray100')};
  padding: ${getters.spacing(3)} ${getters.spacing(4)};
  border-radius: ${getters.radius(6)}px;

  font-weight: ${getters.fontWeight(3)};

  & .file-name {
    color: ${getters.color('deepBlue')};
    margin-right: ${getters.spacing(2)};
  }

  & .file-size {
    color: ${getters.color('black')};
  }
`

export const Attachments = ({ attachments = {}, onDismiss, onRetry }) => {
  const attachmentsWithFileSize = Object.entries(attachments).map(
    ([fileId, { file, guid, loadingStates }]) => {
      const [sizeVal, unit] = calculateFileSize(file.size)
      return {
        name: file.name,
        loadingStates,
        fileSize: `${Math.round(sizeVal * 10) / 10}${unit}`,
        fileId,
        guid,
      }
    },
  )
  return (
    <>
      {attachmentsWithFileSize.map(
        ({ name, loadingStates, guid, fileSize, fileId }) => (
          <Attachment key={fileId} mb={theme.space[3] + 'px'}>
            <Box>
              <span className="file-name">{name}</span>
              <span className="file-size">({fileSize})</span>
            </Box>
            <Flex alignItems="center" justifyContent="end">
              {loadingStates.state === loadingStatesIds.ERROR && (
                <>
                  <ErrorOutlineRounded
                    color={theme.colors.primary400}
                    data-tooltip-id={name + 'error-tooltip'}
                    data-tooltip-content=""
                  />
                  <ReactTooltip
                    id={name + 'error-tooltip'}
                    place="top"
                    className="tool-tip-style"
                  >
                    <span>{loadingStates.error.message}</span>
                  </ReactTooltip>
                  {!guid && (
                    <IconButton
                      IconCmp={ReplayRounded}
                      variant="flat"
                      ml={theme.space[1]}
                      onClick={() => onRetry(fileId)}
                    />
                  )}
                </>
              )}
              {loadingStates.state === loadingStatesIds.LOADING && <Spinner />}
              {loadingStates.state !== loadingStatesIds.LOADING && (
                <IconButton
                  IconCmp={ClearRounded}
                  variant="flat"
                  ml={theme.space[1]}
                  onClick={() => onDismiss(fileId)}
                />
              )}
            </Flex>
          </Attachment>
        ),
      )}
    </>
  )
}
