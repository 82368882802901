import React from 'react'

import styled from 'styled-components'

import PropItem from 'app/agency/stocklist/components/prop_item'
import * as helpers from 'app/sm/helpers'

const MainHeading = styled.h1`
  font-size: 14px;
  font-weight: 700;
  color: #212025;
`

const Layout = ({ teamSlug = '', agencyDetails = {}, properties = [] }) => {
  const {
    agencyName = '',
    contactNumber = '',
    contactEmail = '',
    address = {},
    brandingLogoURL = '',
  } = agencyDetails
  const { friendlyName = '', suburb = '', state = '', postcode = '' } = address
  const formattedAddress = `${friendlyName}, ${suburb} ${helpers.stateConverter(
    state,
  )} ${postcode}`
  const contactDetails = [
    {
      text: 'T',
      class: '',
      contactLink: `tel:${contactNumber}`,
      contactText: contactNumber,
    },
    {
      text: 'E',
      class: '',
      contactLink: `mailto:${contactEmail}`,
      contactText: contactEmail,
    },
    {
      text: 'W',
      class: 'next-row',
      contactLink: `${helpers.urlTo('teamPublicPage', { teamSlug })}`,
      contactText: `snug.com/apply/${teamSlug}`,
    },
  ]
  return (
    <div className="container">
      <div className="d-flex justify-between">
        <div className="col">
          <MainHeading>{agencyName}</MainHeading>
          <p className="fz-10">{formattedAddress}</p>
          <p className="fz-9 display-flex flex-wrap">
            {contactDetails
              .filter((contactDetail) => contactDetail.contactText !== '')
              .map((contactDetail, index) => {
                return (
                  <div key={index} className={contactDetail.class}>
                    {contactDetail.text}
                    <a className="link-basic" href={contactDetail.link}>
                      {' '}
                      {contactDetail.contactText}
                    </a>
                  </div>
                )
              })}
          </p>
        </div>
        <div className="col">
          {brandingLogoURL && <img src={brandingLogoURL} alt="prop" />}
        </div>
      </div>
      <div className="prop-item-wrap">
        {properties.map((property, index) => (
          <PropItem key={index} data={property} />
        ))}
      </div>
    </div>
  )
}

export default Layout
