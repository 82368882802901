import { BasicAppliedFilters } from 'app/components/design-system-components/list-filters/BasicAppliedFilters'
import { buildRenderedFilterConfig } from 'app/features/teams/messages/table-filters/filters.config'

export const MessageAppliedFilters = ({ filters, onDismissFilter }) => {
  return (
    <BasicAppliedFilters
      filtersConfig={buildRenderedFilterConfig(filters)}
      onDismissFilter={onDismissFilter}
    />
  )
}
