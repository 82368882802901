import { Box, Button, Flex } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'

interface BadgeAndActionLabelProps {
  badgeLabel: string
  actionLabel: string
  action?: any
  badgeVariant?: string
  badgeTooltipText?: string
  badgeTooltipID?: string
}

interface BadgeAndLinkProps {
  badgeAndActionLabel: BadgeAndActionLabelProps
  isRowHovered: boolean
}

function BadgeAndLink({
  badgeAndActionLabel,
  isRowHovered,
}: BadgeAndLinkProps) {
  const {
    badgeLabel = '',
    actionLabel = '',
    action = null,
    badgeVariant = '',
    badgeTooltipText = '',
    badgeTooltipID = '',
  } = badgeAndActionLabel
  return (
    <Box>
      <Flex>
        <Box>
          <Badge
            variant={badgeVariant}
            text={badgeLabel}
            tooltipId={badgeTooltipID}
            tooltipText={badgeTooltipText}
          />
        </Box>
        {isRowHovered && (
          <Button variant="linkBlueWithoutWeight" onClick={action}>
            {actionLabel}
          </Button>
        )}
      </Flex>
    </Box>
  )
}

export default BadgeAndLink
