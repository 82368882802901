import React, { useEffect, useState } from 'react'

import moment from 'moment'

import { Box, Button, Flex } from 'app/components/design-system-components'
import { StyledCheckbox } from 'app/components/design-system-components/list-filters/StyledCheckbox'
import * as Forms from 'app/components/forms/forms'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { history } from 'app/shared_components/router'
import * as Helpers from 'app/sm/helpers'
import { formatPhoneNumberE164 } from 'app/utils/phonenumber/helpers'

const ResendReference = ({
  from,
  onSubmitResend,
  displayResponseSection = true,
  enableManualReference,
  manualReferenceLink,
  isResponded,
  changeReferenceCmp,
  lastSend,
  lastRequest,
  isDeclined,
}) => {
  let { phoneNumber, email, senderEmail, sentAt } = lastSend || {}

  //obj containing email, phone and time of the request sent. This would be displayed temporarily while the email is being processing. After reloading it would disappear
  const [emailPhoneRequestedTempObj, setEmailPhoneRequestedTempObj] =
    useState(null)
  const [enableEditEmail, setEnableEditEmail] = useState(false)
  const [newEmail, setNewEmail] = useState(null)
  const [includeMessage, setIncludeMessage] = useState(false)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    setEmailPhoneRequestedTempObj(lastRequest)
  }, [lastRequest])

  const renderDefaultReferenceChanger = () => (
    <Box>
      {enableEditEmail && (
        <Box my={3}>
          <Forms.InputEmail
            label="Email"
            value={newEmail}
            onChange={({ value }) => setNewEmail(value)}
            id="email"
            inputClass="width100"
          />
        </Box>
      )}
      <Flex>
        {!enableEditEmail && (
          <>
            <Button
              variant="linkBlueUnderlined"
              onClick={() => setEnableEditEmail(true)}
            >
              Edit email
            </Button>
          </>
        )}
        {enableEditEmail && (
          <div
            className="blue-link-style"
            onClick={() => setEnableEditEmail(false)}
            role="button"
            tabIndex="0"
          >
            Close
          </div>
        )}
      </Flex>
    </Box>
  )

  const onSendBtnClicked = () => {
    return onSubmitResend(newEmail, message)
      .then(() => {
        setEmailPhoneRequestedTempObj({
          email: newEmail || email,
          sentAt: moment.now(),
        })
        setEnableEditEmail(false)
        setIncludeMessage(false)
      })
      .catch()
  }

  const fromRenterSummary = from === 'renterSummary'
  const renderLastRequestSent = () => {
    //Hide last send section if displaying last requested temp section
    if (email === '' && phoneNumber === '') return

    if (!sentAt) return
    const lastRequestDate = moment(sentAt).format(Helpers.standardMilitaryTime)
    return (
      <Box
        mt={3}
        fontSize={4}
        fontWeight={4}
        color={theme.colors.gray300}
        lineHeight={theme.lineHeights['middle']}
      >
        <span>
          <b>Last sent: </b>
          {lastRequestDate}
        </span>
        <br />
        <span>
          {senderEmail} to {email} {formatPhoneNumberE164(phoneNumber)}
        </span>
      </Box>
    )
  }
  const renderEmailRequestedTempLabel = () => {
    if (
      !emailPhoneRequestedTempObj.sentAt ||
      (!emailPhoneRequestedTempObj.email && !emailPhoneRequestedTempObj.phone)
    )
      return

    const requestDate = moment(emailPhoneRequestedTempObj.sentAt).format(
      Helpers.standardMilitaryTime,
    )
    return (
      <Box
        mt={3}
        fontSize={4}
        fontWeight={4}
        color={theme.colors.gray300}
        lineHeight={theme.lineHeights['middle']}
      >
        <span>
          <b>Last Requested: </b>
          {requestDate}
        </span>
        <br />
        <span>
          Sending to{' '}
          {emailPhoneRequestedTempObj.email !== '' &&
            ` ${emailPhoneRequestedTempObj.email}`}{' '}
          {formatPhoneNumberE164(emailPhoneRequestedTempObj.phone)}
        </span>
      </Box>
    )
  }

  const sendReferenceBtnLabel = sentAt ? 'Resend Reference' : 'Send Reference'

  return (
    <div
      className={`bc-hub-item-link card-block pdf-width-50p ${
        fromRenterSummary && 'no-box-shadow p0'
      }`}
    >
      <Box p="10px" bg={theme.colors.legacyGreyBlue}>
        {displayResponseSection && (
          <>
            {/* if reference responded we don't allow resending, and don't show last send*/}
            {!isResponded && (
              <Box mt={6} mx="auto">
                <Box my={2}>
                  {!isDeclined && (
                    <StyledCheckbox
                      label="Include message"
                      checked={includeMessage}
                      onChange={({ target: { checked } }) =>
                        setIncludeMessage(checked)
                      }
                    />
                  )}
                  {includeMessage && (
                    <Forms.CommentArea
                      isOptional
                      label="Message"
                      placeholder="Attach a personal message"
                      rows={3}
                      value={message}
                      onChange={({ value }) => setMessage(value)}
                    />
                  )}
                </Box>
                {!isDeclined && (
                  <Button
                    type="button"
                    onClick={onSendBtnClicked}
                    variant="outlineSuccess"
                    disabled={emailPhoneRequestedTempObj?.sentAt}
                    my={2}
                  >
                    {sendReferenceBtnLabel}
                  </Button>
                )}
                <Flex mt={3}>
                  {changeReferenceCmp || renderDefaultReferenceChanger()}
                  {enableManualReference && (
                    <Button
                      ml={3}
                      onClick={() => history.push(manualReferenceLink)}
                      variant="linkBlueUnderlined"
                    >
                      Manual reference
                    </Button>
                  )}
                </Flex>
                {lastSend &&
                  !emailPhoneRequestedTempObj &&
                  renderLastRequestSent()}
                {emailPhoneRequestedTempObj && renderEmailRequestedTempLabel()}
              </Box>
            )}
          </>
        )}
      </Box>
    </div>
  )
}

export default ResendReference
