import { buildBaseUrl } from 'app/services/http/teams/key-logger/utils'
import { api } from 'app/utils/api/helpers'

export const importKeys = (teamId, file, config = {}) => {
  const formData = new FormData()
  formData.append('file', file)

  return api.post(`${buildBaseUrl(teamId)}/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  })
}
