import React from 'react'

import moment from 'moment'
import qs from 'qs'

import privateLock from 'app/assets/icons/private-lock.svg'
import {
  Box,
  Button,
  CollapsableSection,
  Flex,
  Modal,
} from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import RegisteredViewingNumBadge from 'app/components/display/registered-viewing-num'
import { ViewingEntryNoticesStatusAndDetailsCTA } from 'app/components/viewings/ViewingEntryNoticesStatusAndDetailsCTA'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import ReportContent from 'app/pages/teams/viewings-mobile/components/ReportContent'
import ReportSent from 'app/pages/teams/viewings-mobile/components/ReportSent'
import {
  AssigneeRegistrationContainer,
  OppositeSidesContainer,
  RegAttendedConfirmedContainer,
  ViewingCard,
  ViewingDetailsContainer,
} from 'app/pages/teams/viewings-mobile/styles'
import ViewingDetailsAttendeesTab from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingDetailsAttendeesTab'
import ViewingDetailsTabsModal from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingDetailsTabsModal'
import * as snugNotifier from 'app/services/snugNotifier'
import AnimationWrapper from 'app/shared_components/animation_wrapper'
import { history } from 'app/shared_components/router'
import SnugUpgradeAccountModal from 'app/shared_components/snug_account_upgrade/modal'
import TenantDetailsModal from 'app/shared_components/TenantDetailsModal'
import {
  NO_IMAGE_PLACEHOLDER_URL,
  noteActionType,
  noteOwnerType,
  parseViewing,
  targetSectionObject,
  urlTo,
} from 'app/sm/helpers'
import AddAccessDetailModal from 'app/sm/inspections/components/add_access_details_modal_connection'
import AddCommentModal from 'app/sm/inspections/components/add_comment_modal'
import AddNoteModal from 'app/sm/inspections/components/add_notes_modal_connection'
import AssignViewingTeamMemberModal from 'app/sm/inspections/components/assign_viewing_team_member_modal'
import AttendeeHubModal from 'app/sm/inspections/components/attendee_hub_modal'
import LateForViewingModal from 'app/sm/inspections/components/late_for_viewing_modal'
import { MergeViewingModal } from 'app/sm/inspections/components/merge_viewing_modal'
import SendSmsReportModal from 'app/sm/inspections/components/send_sms_report_modal_connection'
import TenantReadModal from 'app/sm/inspections/components/tenant_read_modal'
import WalkinRenterModal from 'app/sm/inspections/components/walkin_renter_modal'
import { getPropertyViewings } from 'app/sm/inspections/inspections_access'
import { parseServerViewings } from 'app/sm/inspections/inspections_actions'
import ViewingRegistrantsMessagesModal from 'app/sm/inspections/viewing_registrants_message_modal/ViewingRegistrantsMessagesModal'
import PropertyBasic from 'app/sm/property_details/property_basic'
import PropertyDescription from 'app/sm/property_details/property_description'
import * as accountHelpers from 'app/utils/accounts/helpers'
import {
  DEFAULT_UNIX_TIME_Z,
  formatTime,
  getPropertyAvailabilityDate,
} from 'app/utils/datetime/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import { getNotePayloadForShortlistViewingRegistrant } from 'app/utils/notes/helpers'
import * as viewingHelpers from 'app/utils/viewings/helpers'
import { isViewingMergeEnabled } from 'config/features'

const CHECK_IN_ATTENDEE_INVITE_TO_APPLY_ERR =
  'Please Check-in attendee then Invite to Apply'
const ADD_ATTENDEE_SUCCESS = 'Added successfully'
const ADD_ATTENDEE_INVITE_REQUESTED = 'Invite requested'
const DELETE_ATTENDEE_CONFIRMATION =
  'Are you sure you want to remove this viewing attendee?'
const UPDATED_SUCCESSFULLY = 'Updated successfully'
const SUCCESSFUL_LATE_SMS = 'Successfully requested late SMS'
const ALL_INVITE_TO_APPLY_CONFIRMATION =
  'Email and SMS invite all uninvited, checked-in attendees?'
const ALL_INVITE_TO_APPLY_SUCCESS =
  'Email and SMS invite sent to all uninvited, checked-in attendees'
const REQUEST_FEEDBACK_FROM_ALL_REGISTRANTS =
  'Are you sure you want to request feedback from all viewing registrants?'
const WALK_IN_MODAL_STATUS_EDIT = 'edit'
const WALK_IN_MODAL_STATUS_CREATE = 'create'
const UNASSIGNED_TEXT = 'N/A'

class ViewingDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addWalkInRenterModalOn: false,
      viewingAttendeeCount: 0,
      walkinModalStatus: WALK_IN_MODAL_STATUS_EDIT,
      targetAttendee: {},
      addAttendeePreloadContent: {},
      isAddAttendeeHubModalOn: false,
      enquirers: [],
      filteredEnquirersForAttendeeHub: [],
      isAddNoteModalOn: false,
      isCommentModalOn: false,
      isLateModalOn: false,
      totalCheckedInAttendeeCount: 0,
      openModalForTeamMember: false,
      isSendSmsReportModalOn: false,
      isAccessDetailModalOn: false,
      isViewingTabsModalShown: false,
      registrantsHasChanged: false,
      showUpgradeAccountModal: false,
      tenantsWhoReadTheEntryNotice: [],
      tenantReadModal: false,
      isTenantsDisplayModalOn: false,
      displayViewingAttendeesModalHeading: true,
      mergeViewingsModalOpen: false,
      mergeViewingCancelModalOpen: false,
      propertyFutureViewings: [],
      propertyViewing: {},
      showViewingRegistrantsMessageModal: false,
    }
  }

  componentDidUpdate(prevProps) {
    const curQueries =
      qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      }) || {}

    const preQueries =
      qs.parse(prevProps.location.search, {
        ignoreQueryPrefix: true,
      }) || {}

    if (!curQueries.showAttendees && preQueries.showAttendees === 'true') {
      this.setState({
        isViewingTabsModalShown: false,
      })
      if (!this.state.registrantsHasChanged) return
      const { viewingsListRefresh } = this.props
      viewingsListRefresh()
      this.setState({
        registrantsHasChanged: false,
      })
    }
  }

  onAddWalkInRenter = (
    payload,
    propertyId,
    viewingId = this.props.viewingData.GUID,
    shouldSendInvite = false,
  ) => {
    this.setState({
      disableSaveButton: true,
    })
    const {
      addWalkInRenter,
      showViewingsLoadingSpinner,
      viewingsListRefresh,
      currentTeam,
      fetchRegistrantsForViewing,
    } = this.props
    showViewingsLoadingSpinner(true)
    if (shouldSendInvite) {
      const { checkIn } = payload
      if (!checkIn) {
        snugNotifier.error(CHECK_IN_ATTENDEE_INVITE_TO_APPLY_ERR)
        return
      }
    }
    setTimeout(() => {
      addWalkInRenter(payload, propertyId, viewingId)
        .then((data) => {
          this.setState({
            viewingAttendeeCount: this.state.viewingAttendeeCount + 1,
            apiError: '',
            registrant: data,
            disableSaveButton: false,
            addWalkInRenterModalOn: false,
            isAddAttendeeHubModalOn: true,
          })
          snugNotifier.success(ADD_ATTENDEE_SUCCESS)
          if (shouldSendInvite && data) {
            const { firstName } = data
            this.sendInviteToApplyToRegistrant(data, firstName).then(() => {
              snugNotifier.success(ADD_ATTENDEE_INVITE_REQUESTED)
            })
          }
          viewingsListRefresh()
          showViewingsLoadingSpinner(false)
        })
        .then(() => {
          if (payload && payload.externalComment) {
            const { registrant } = this.state
            const notePayload = {
              content: payload.externalComment,
              type: noteActionType.viewingAttendee,
              target: targetSectionObject.viewingReport['index'],
            }
            this.callCreateNote(notePayload, registrant.guidID).then(() => {
              setTimeout(() => {
                fetchRegistrantsForViewing(currentTeam.guid, viewingId)
              }, 2000)
            })
          }
        })
        .then(() => {
          viewingsListRefresh()
        })
        .catch((error) => {
          snugNotifier.error(error)
          showViewingsLoadingSpinner(false)
          this.setState({
            disableSaveButton: false,
          })
        })
    }, 200)
    return Promise.resolve()
  }

  handleShortlist = (registrantGUID, shortlist) => {
    const notePayload = getNotePayloadForShortlistViewingRegistrant(shortlist)
    this.callCreateNote(notePayload, registrantGUID)
  }

  onAttendeeHubModalAddIconClicked = (
    nameSearchText = '',
    mobileSearchText = '',
  ) => {
    const addAttendeePreloadContent = { nameSearchText, mobileSearchText }
    this.setState({
      addWalkInRenterModalOn: true,
      walkinModalStatus: WALK_IN_MODAL_STATUS_CREATE,
      isAddAttendeeHubModalOn: false,
      addAttendeePreloadContent,
    })
  }

  onClickDisabledLiteAccountOption = () => {
    const { showUpgradeAccountModal } = this.state
    this.setState({
      showUpgradeAccountModal: !showUpgradeAccountModal,
    })
  }

  onClickEntryNotices = (entryNoticeRecordsSummary = undefined) => {
    const { tenantReadModal } = this.state
    this.setState({
      tenantReadModal: !tenantReadModal,
    })
    if (entryNoticeRecordsSummary) {
      this.setState({
        tenantsWhoReadTheEntryNotice: entryNoticeRecordsSummary.recipients,
      })
    }
  }

  onCloseViewingTabsModal = () => {
    const queryString = this.props.location.search
    const queryParams =
      qs.parse(queryString, {
        ignoreQueryPrefix: true,
      }) || {}

    const { showAttendees, propertyreportId, ...withoutNew } = queryParams

    history.push({ search: qs.stringify(withoutNew) })

    this.setState({
      isViewingTabsModalShown: false,
    })
    if (!this.state.registrantsHasChanged) return
    const { viewingsListRefresh } = this.props
    viewingsListRefresh()
    this.setState({
      registrantsHasChanged: false,
    })
  }

  onModalTitleClicked = (field) => {
    this.turnOffAllModals(() =>
      this.setState({
        [field]: true,
      }),
    )
  }

  onNotifyLateViewing = (data) => {
    const { notifyLateViewing, viewingData } = this.props
    notifyLateViewing(data, viewingData.GUID)
      .then(() => {
        snugNotifier.success(SUCCESSFUL_LATE_SMS)
        this.setState({ isLateModalOn: false })
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  onOnSiteAttendeeNameClicked = (renterInfo) => {
    this.setState({
      addWalkInRenterModalOn: true,
      walkinModalStatus: WALK_IN_MODAL_STATUS_EDIT,
      targetAttendee: renterInfo,
      isAddAttendeeHubModalOn: false,
    })
  }

  onRegistrantsChangeInAttendeesTab() {
    this.setState({
      registrantsHasChanged: true,
    })
  }

  onSearchAttendeeLinkClicked = () => {
    this.setState({
      addWalkInRenterModalOn: false,
      isAddAttendeeHubModalOn: true,
    })
  }

  onShowCommentModal(status, renterInfo) {
    const { showCommentModal } = this.props
    showCommentModal(status, renterInfo)
    this.setState({ isCommentModalOn: status })
  }

  onShowLateViewingModal = (
    status,
    viewingData,
    renters = [],
    onsiteRegistrant = [],
  ) => {
    const { showLateViewingModal } = this.props
    if (viewingData && viewingData.registeredAttendeeCount) {
      const { showLateViewingModal } = this.props
      showLateViewingModal(status, viewingData)
      this.setState({
        isLateModalOn: status,
        totalCheckedInAttendeeCount: viewingData.registeredAttendeeCount,
      })
      return
    }
    const checkedInRenter = renters.filter((attendee) => {
      const { viewingRegistration = {} } = attendee
      return viewingRegistration.checkIn
    })

    const checkedInOnsiteRegistrant = onsiteRegistrant.filter((attendee) => {
      const { viewingOnsiteRegistrant = {} } = attendee
      return viewingOnsiteRegistrant.checkIn
    })

    const totalCheckedInAttendeeCount =
      checkedInRenter.length + checkedInOnsiteRegistrant.length

    showLateViewingModal(status, viewingData)
    this.setState({
      isLateModalOn: status,
      totalCheckedInAttendeeCount,
    })
  }

  onShowSendSmsReportModal(isOpen, viewingData, isViewingInProgress) {
    if (isViewingInProgress) {
      if (
        window.confirm(viewingHelpers.SMS_VIEWING_REPORT_CONFIRMATION_MESSAGE)
      ) {
        this.setState({
          isSendSmsReportModalOn: true,
          smsData: viewingData,
        })
      }
      return
    }
    this.setState({
      isSendSmsReportModalOn: true,
      smsData: viewingData,
    })
  }

  onToggleAttendeeHubModal = (isOpen, viewingData, onsiteRegistrants) => {
    const { toggleWalkInModal } = this.props
    const { propertyViewings, enquirers } = this.state
    toggleWalkInModal(false, viewingData)
    let filteredEnquirers = []
    if (viewingData && propertyViewings) {
      filteredEnquirers =
        enquirers && enquirers.length > 0
          ? enquirers
              .filter(
                (enquirer) =>
                  !onsiteRegistrants.find(
                    (onsiteRegistrant) =>
                      onsiteRegistrant.viewingOnsiteRegistrant.mobilePhone ===
                      enquirer.phone,
                  ),
              )
              .filter(
                (enquirer) =>
                  !onsiteRegistrants.find(
                    (onsiteRegistrant) =>
                      onsiteRegistrant.viewingOnsiteRegistrant.email ===
                      enquirer.email,
                  ),
              )
          : []
    }
    this.setState({
      isAddAttendeeHubModalOn: isOpen,
      filteredEnquirersForAttendeeHub: filteredEnquirers,
    })
  }

  onToggleWalkInModal = (status, viewingData) => {
    const { toggleWalkInModal } = this.props
    toggleWalkInModal(status, viewingData)
    this.setState({
      addWalkInRenterModalOn: status,
      walkinModalStatus: WALK_IN_MODAL_STATUS_CREATE,
    })
  }

  onUpdateRenterStatus = (
    payload,
    renterType,
    id,
    registrationGUID,
    propertyId,
  ) => {
    this.setState({
      disableSaveButton: true,
    })
    const {
      updateRenterStatus,
      propertyGUID,
      showViewingsLoadingSpinner,
      viewingsListRefresh,
      fetchRegistrantsForViewing,
      currentTeam,
    } = this.props
    showViewingsLoadingSpinner(true)
    const { externalComment } = payload
    if (externalComment) {
      payload.externalNote = {
        content: externalComment,
        type: noteActionType.viewingAttendee,
        target: targetSectionObject.viewingReport['index'],
      }
    }
    payload.propertyId = propertyGUID
    updateRenterStatus(payload, renterType, id, registrationGUID, propertyId)
      .then(() => {
        this.setState({
          disableSaveButton: false,
        })
      })
      .then(() => {
        showViewingsLoadingSpinner(false)
        viewingsListRefresh()
        snugNotifier.success(UPDATED_SUCCESSFULLY)
        this.setState({
          addWalkInRenterModalOn: false,
        })
        setTimeout(() => {
          fetchRegistrantsForViewing(currentTeam.guid, id)
        }, 2000)
      })
      .catch((error) => {
        snugNotifier.error(error)
        this.setState({ apiError: error, disableSaveButton: false })
        showViewingsLoadingSpinner(false)
        viewingsListRefresh()
      })
  }

  callCreateNote = (note, attendeeGUID) => {
    const { createNote } = this.props
    return createNote(note, attendeeGUID, noteOwnerType.ViewingAttendee)
      .then(() => {
        return Promise.resolve()
      })
      .catch((error) => {
        snugNotifier.error(error)
        return Promise.reject()
      })
  }

  confirmCancelViewing = (guidID, propertyId) => {
    const { showViewingsLoadingSpinner, viewingsListRefresh, cancelViewing } =
      this.props
    if (window.confirm(viewingHelpers.CANCEL_VIEWING_CONFIRM_TEXT)) {
      showViewingsLoadingSpinner(true)
      cancelViewing(guidID, propertyId)
        .then(() => {
          snugNotifier.success(
            viewingHelpers.CANCEL_VIEWING_CONFIRMATION_MESSAGE,
          )
          showViewingsLoadingSpinner(false)
          viewingsListRefresh()
        })
        .catch((error) => {
          this.setState({ apiError: error })
          showViewingsLoadingSpinner(false)
        })
    }
  }

  confirmRequestRenterFeedback = (viewingData = {}) => {
    if (window.confirm(REQUEST_FEEDBACK_FROM_ALL_REGISTRANTS)) {
      if (
        viewingHelpers.checkIfViewingEnded(viewingData) &&
        !viewingData.cancelled
      ) {
        this.props
          .requestFeedback(
            viewingData.guidID || viewingData.GUID,
            this.reactToastify,
          )
          .catch((error) => {
            this.setState({ apiError: error })
          })
      } else {
        this.setState({ requestFeedbackError: viewingData.guidID })
      }
    }
  }

  confirmSendInviteToApplyToAllRgistrants = (guidID) => {
    const {
      showViewingsLoadingSpinner,
      viewingsListRefresh,
      viewingData,
      currentTeam,
      fetchRegistrantsForViewing,
    } = this.props
    if (window.confirm(ALL_INVITE_TO_APPLY_CONFIRMATION)) {
      showViewingsLoadingSpinner(true)
      this.props
        .sendInviteToApplyToAllRegistrants(guidID)
        .then(() => {
          snugNotifier.success(ALL_INVITE_TO_APPLY_SUCCESS)
          showViewingsLoadingSpinner(false)
          setTimeout(() => {
            fetchRegistrantsForViewing(currentTeam.guid, viewingData.GUID)
            viewingsListRefresh()
          }, 3000)
        })
        .catch((error) => {
          this.setState({ apiError: error })
          showViewingsLoadingSpinner(false)
        })
    }
  }

  deleteOnSiteAttendee = (
    propertyId,
    viewingGUID = this.props.viewingData.GUID,
    registrationGUID,
  ) => {
    const {
      removeOnsiteViewingRenter,
      showViewingsLoadingSpinner,
      viewingsListRefresh,
    } = this.props
    showViewingsLoadingSpinner(true)
    if (window.confirm(DELETE_ATTENDEE_CONFIRMATION)) {
      removeOnsiteViewingRenter(viewingGUID, registrationGUID).then(() => {
        viewingsListRefresh()

        this.setState({
          viewingAttendeeCount: this.state.viewingAttendeeCount - 1,
        })
        showViewingsLoadingSpinner(false)
      })
    }
  }

  openViewingTabsModal() {
    const queryString = this.props.location.search
    const queryParams =
      qs.parse(queryString, {
        ignoreQueryPrefix: true,
      }) || {}

    const newQueries = { ...queryParams, showAttendees: true }

    history.push({ search: qs.stringify(newQueries) })

    this.setState({
      isViewingTabsModalShown: true,
    })
  }

  sendInviteToApplyToRegistrant = (viewingRegistrant) => {
    const {
      sendIndividualInviteToApply,
      fetchRegistrantsForViewing,
      currentTeam,
    } = this.props
    const { viewingID, guidID } = viewingRegistrant
    sendIndividualInviteToApply(viewingID, guidID)
    setTimeout(() => {
      fetchRegistrantsForViewing(currentTeam.guid, viewingID)
    }, 4000)

    return Promise.resolve()
  }

  sendManagerOnlyOrOwnerReport = (
    isReportSent,
    propertyReportGUID,
    propertyGUID,
    teamSlug,
    viewingGUID,
    isViewingInProgress,
  ) => {
    if (isReportSent) {
      history.push(
        `${urlTo('managerOnlyPropertyReport', {
          propertyreportId: propertyReportGUID,
        })}`,
      )
    } else {
      if (isViewingInProgress) {
        if (
          window.confirm(viewingHelpers.VIEWING_REPORT_CONFIRMATION_MESSAGE)
        ) {
          history.push(
            `${urlTo('ownerReport', {
              propertyId: propertyGUID,
            })}?team=${teamSlug}&viewingId=${viewingGUID}`,
          )
        }
        return
      }
      history.push(
        `${urlTo('ownerReport', {
          propertyId: propertyGUID,
        })}?team=${teamSlug}&viewingId=${viewingGUID}`,
      )
    }
  }

  toggleTeamMemberModal = (viewingData) => {
    this.setState(
      {
        openModalForTeamMember: !this.state.openModalForTeamMember,
        viewingSelected: viewingData,
      },
      () => {
        // if (!this.state.openModalForTeamMember) {
        //   this.props.fetchViewingsByDateFromTeamId()
        // }
      },
    )
  }

  turnOffAllModals = (callback = () => {}) => {
    this.setState(
      {
        isEditViewModalOn: false,
        isAddAttendeeHubModalOn: false,
        addWalkInRenterModalOn: false,
        isLateModalOn: false,
        isAddNoteModalOn: false,
        isAccessDetailModalOn: false,
        mergeViewingCancelModalOpen: false,
        mergeViewingsModalOpen: false,
      },
      () => callback(),
    )
  }

  showViewingAttendeesModalHeading = () => {
    this.setState({
      displayViewingAttendeesModalHeading: true,
    })
  }

  hideViewingAttendeesModalHeading = () => {
    this.setState({
      displayViewingAttendeesModalHeading: false,
    })
  }

  reloadPropertyViewings = () => {
    const {
      propertyGUID,
      showViewingsLoadingSpinner,
      viewingData: { GUID },
    } = this.props
    showViewingsLoadingSpinner(true)
    return getPropertyViewings({
      propertyId: propertyGUID,
      startTime: formatTime(moment(), DEFAULT_UNIX_TIME_Z),
    }).then(({ ok, viewings, responseText }) => {
      showViewingsLoadingSpinner(false)
      if (ok) {
        const allFutureViewings = parseServerViewings(viewings)
        const selectedPv = allFutureViewings.find(
          (pv) => pv.viewing.guidID === GUID,
        )
        this.setState({
          propertyFutureViewings: allFutureViewings,
          propertyViewing: selectedPv,
        })
      } else {
        return responseText.then((error) => {
          const errorMessage = translateErrorCodeToMessage(error)
          snugNotifier.error(errorMessage)
          return Promise.reject(errorMessage)
        })
      }
    })
  }

  onViewingMerged = () => {
    const { viewingsListRefresh } = this.props
    this.setState({
      isViewingTabsModalShown: false,
      mergeViewingsModalOpen: false,
    })
    viewingsListRefresh()
  }

  toggleMergeViewingCancelModal = () => {
    const { mergeViewingCancelModalOpen } = this.state
    this.setState({
      mergeViewingCancelModalOpen: !mergeViewingCancelModalOpen,
    })
  }

  showMergeViewingModal = () => {
    const {
      viewingData: { registeredAttendeeCount = 0 },
    } = this.props
    if (registeredAttendeeCount === 0) {
      this.toggleMergeViewingCancelModal()
    } else {
      this.reloadPropertyViewings()
        .then(() =>
          this.setState({
            mergeViewingsModalOpen: true,
          }),
        )
        .catch(() => snugNotifier.error("can't fetch property viewings"))
    }
  }

  render() {
    const {
      viewingData,
      propertyGUID,
      propertyData,
      manager,
      clearError,
      fetchAgencyApplicantsElasticSearchResult,
      currentTeam,
      assignViewingToTeamMember,
      fetchViewingTeamMembers,
      deleteViewingTeamMember,
      updateViewingTeamMemberRelation,
      getManagersListForViewingFilters,
      fetchRegistrantsForViewing,
      dataRegistrantsCurrent,
      address,
      viewingEntryNoticeEnabled,
      isViewingReportSmsEnabled,
      isViewingReportEmailEnabled,
      isViewingSlotEnabled,
      showAddViewingModal,
      viewingQRAutoCheckinEnabled,
    } = this.props
    const {
      addWalkInRenterModalOn,
      walkinModalStatus,
      targetAttendee,
      renterInfo = {},
      addAttendeePreloadContent,
      viewingAttendeeCount,
      apiError,
      isAddAttendeeHubModalOn,
      enquirers,
      filteredEnquirersForAttendeeHub,
      isAddNoteModalOn,
      isCommentModalOn,
      isLateModalOn,
      totalCheckedInAttendeeCount,
      openModalForTeamMember,
      isSendSmsReportModalOn,
      isAccessDetailModalOn,
      disableSaveButton,
      showUpgradeAccountModal,
      isViewingTabsModalShown = false,
      tenantReadModal,
      tenantsWhoReadTheEntryNotice,
      isTenantsDisplayModalOn = false,
      displayViewingAttendeesModalHeading = true,
      mergeViewingsModalOpen,
      mergeViewingCancelModalOpen,
      propertyViewing,
      propertyFutureViewings,
      showViewingRegistrantsMessageModal,
    } = this.state

    const {
      renters = [],
      assignedTeamMembers = [],
      allRegistrants = [],
      entryNoticeRecordsSummary = {},
      isViewingReportSmsSent = false,
      isViewingReportEmailSent = false,
      isReportSent = false,
      fullyBooked = false,
      totalRegistered = 0,
      viewingCap = 0,
      GUID = '',
      cancelled = false,
    } = viewingData

    let assignedTeamMembersCountMinusTwo = 0
    if (assignedTeamMembers && assignedTeamMembers.length > 0) {
      assignedTeamMembersCountMinusTwo = assignedTeamMembers.length - 2
    }
    let isAccountLite = false
    const enabledOptionsForWalkinModal = {
      enableContactInfomation: true,
      enableCheckedInAndInterested: true,
      enablePreference: true,
      enableNote: true,
      showSearchButton: true,
      enableViewingBottomButtons: true,
    }
    let allRentersData = []

    if (dataRegistrantsCurrent && allRegistrants && allRegistrants.length > 0) {
      allRegistrants.forEach((renterGUID) => {
        if (dataRegistrantsCurrent[renterGUID]) {
          allRentersData = allRentersData.concat({
            viewingOnsiteRegistrant: dataRegistrantsCurrent[renterGUID],
            slot: dataRegistrantsCurrent[renterGUID].slot,
            slotTime: dataRegistrantsCurrent[renterGUID].slotTime,
          })
        }
      })
    }

    const {
      startDate,
      duration,
      registeredAttendeeCount = 0,
      confirmedAttendeeCount = 0,
      attendedCount = 0,
    } = viewingData

    let assigned = UNASSIGNED_TEXT
    if (assignedTeamMembers && assignedTeamMembers.length > 0) {
      const firstTwoAssignedMembers = assignedTeamMembers.slice(0, 2)
      assigned = firstTwoAssignedMembers.map(
        (member) => `${member.firstName} ${member.lastName[0] || ''}`,
      )
      assigned = assigned.join(', ')
    }
    const formattedViewingStartDate = parseViewing(viewingData)

    const { dayOrDate, time } =
      dateTimeHelpers.convertDateToDayDateAndTime(startDate)
    let teamGUID = ''
    let currentTeamSlug = ''
    if (currentTeam) {
      teamGUID = currentTeam.guid
      currentTeamSlug = currentTeam.slug
      isAccountLite =
        currentTeam.accountType &&
        currentTeam.accountType === accountHelpers.ACCOUNT_TYPE_LITE
    }
    const { friendlyName = '', suburb = '' } = address || {}
    const addressStr = `${address.friendlyName}, ${address.suburb}`
    const isFutureViewing = viewingHelpers.isfutureViewing(startDate)
    const viewingStartDate =
      startDate &&
      dateTimeHelpers.formatTime(startDate, dateTimeHelpers.STANDARD_DATETIME)
    const subject = `re: Viewing ${viewingStartDate} - ${friendlyName}, ${suburb}`

    const dataForAssignedTeamMember = {
      ...viewingData,
      guidID: viewingData.GUID,
      FriendlyName: address.friendlyName,
      Suburb: address.suburb,
    }

    const modalHeadingWithoutViewingTime = `${address.friendlyName}`

    const propertyTools = [
      {
        text: 'Late',
        enableTool: !viewingData.cancelled,
        onClick: () => {
          !isAccountLite
            ? this.onShowLateViewingModal(
                true,
                viewingData,
                renters,
                allRentersData,
              )
            : this.onClickDisabledLiteAccountOption()
        },
      },
      {
        text: 'Send Message',
        enableTool: true,
        onClick: () => {
          this.setState({
            showViewingRegistrantsMessageModal: true,
          })
        },
      },
      {
        text: 'Edit Viewing',
        enableTool: true,
        onClick: () => {
          this.props.editViewingClicked(viewingData)
        },
      },
      {
        text: 'Property Notes',
        enableTool: true,
        onClick: () => {
          this.setState({ isAddNoteModalOn: true })
        },
      },
      {
        text: 'Access Details',
        enableTool: true,
        onClick: () => {
          this.setState({
            isAccessDetailModalOn: true,
          })
        },
      },
      {
        text: 'Tenant Details',
        enableTool: true,
        onClick: () => {
          this.setState({ isTenantsDisplayModalOn: true })
        },
      },
      {
        text: 'Refresh',
        enableTool: true,
        onClick: () => fetchRegistrantsForViewing(teamGUID, viewingData.GUID),
      },
      {
        text: 'Merge',
        enableTool: isFutureViewing && isViewingMergeEnabled(currentTeam.slug),
        onClick: () => this.showMergeViewingModal(),
      },
      {
        text: 'Cancel',
        enableTool: true,
        onClick: () =>
          this.confirmCancelViewing(viewingData.GUID, propertyGUID),
      },
    ]

    const transitionOptions = {
      transitionName: 'right-to-left',
      transitionEnterTimeout: 200,
      transitionLeaveTimeout: 200,
    }

    const queryString = this.props.location.search
    const queryParams =
      qs.parse(queryString, {
        ignoreQueryPrefix: true,
      }) || {}

    const AttendeesTabCmp = (
      <ViewingDetailsAttendeesTab
        viewing={viewingData}
        currentTeam={currentTeam}
        propertyId={propertyGUID}
        onChanges={(change) => this.onRegistrantsChangeInAttendeesTab(change)}
        isViewingSlotEnabled={isViewingSlotEnabled}
        showViewingAttendeesModalHeading={this.showViewingAttendeesModalHeading}
        hideViewingAttendeesModalHeading={this.hideViewingAttendeesModalHeading}
        displayViewingAttendeesModalHeading={
          displayViewingAttendeesModalHeading
        }
        handleShortlist={this.handleShortlist}
      />
    )

    const renderMergeViewingModal = () => {
      const otherViewings = propertyFutureViewings.filter(
        (pv) => pv.viewing.guidID !== propertyViewing.viewing.guidID,
      )
      return (
        <>
          {mergeViewingsModalOpen && (
            <MergeViewingModal
              futurePropertyViewings={otherViewings}
              cancellingViewingDetails={propertyViewing}
              closeModel={() =>
                this.setState({ mergeViewingsModalOpen: false })
              }
              onMergeSuccess={this.onViewingMerged}
              addViewingModal={showAddViewingModal}
            />
          )}
        </>
      )
    }
    const renderMergeViewingCancelModal = () => {
      return (
        <>
          {mergeViewingCancelModalOpen && (
            <Modal
              modalHeading="Cancel viewing"
              modalBody="There are no attendees that can be merged to another viewing. Would you like to cancel the viewing instead?"
              primaryLabel="Cancel viewing"
              primaryAction={() =>
                this.confirmCancelViewing(viewingData.GUID, propertyGUID)
              }
              secondaryLabel="Close"
              secondaryAction={this.toggleMergeViewingCancelModal}
              toggleModal={this.toggleMergeViewingCancelModal}
            />
          )}
        </>
      )
    }
    const {
      property: { carports, garages, parking, images },
      availableFrom,
    } = propertyData
    const availabilityDate = getPropertyAvailabilityDate(availableFrom, null)

    let propertyImage = NO_IMAGE_PLACEHOLDER_URL
    if (images && images.length > 0) {
      propertyImage = (images.find((image) => image.isMain) || images[0]).URL
    }
    const ListingTabCmp = (
      <Box p={`${theme.space[5]}px`}>
        <img width="100%" src={propertyImage} alt="property image" />
        <PropertyBasic
          property={propertyData.property}
          address={addressStr}
          addressStyles={{ fontSize: theme.fontSizes.pLarge16 }}
          brandingLogoURL={propertyData.brandingLogoURL}
          rent={propertyData.weeklyRentDisplay}
          carCountSpaces={{ carports, garages, parking }}
          availabilityDate={availabilityDate}
          offerDataStyles={{ fontSize: theme.fontSizes.pRegular14 }}
        />
        <CollapsableSection
          TitleSection="Description"
          variant="transparent"
          initiallyCollapsed={false}
        >
          <Box mt={theme.space[5] + 'px'}>
            <PropertyDescription
              property={propertyData.property}
              width="100%"
            />
          </Box>
        </CollapsableSection>
      </Box>
    )

    const ReportTabCmp = (
      <ReportContent
        smsReportData={viewingData}
        smsReportHandleClose={() =>
          this.setState({ isSendSmsReportModalOn: false })
        }
        smsReportShowSendSmsReportModal={this.onShowSendSmsReportModal}
        smsReportClearError={clearError}
        smsReportIsSendSmsReportModalOn={isSendSmsReportModalOn}
        viewingGUID={viewingData.GUID}
        propertyGUID={propertyGUID}
        isFutureViewing={isFutureViewing}
        cancelled={viewingData.cancelled}
        isViewingReportSmsEnabled={isViewingReportSmsEnabled}
        isViewingReportEmailEnabled={isViewingReportEmailEnabled}
        viewingReportSMSSent={isViewingReportSmsSent}
        viewingReportEmailSent={isViewingReportEmailSent}
        subject={subject}
        viewingData={viewingData}
      />
    )

    const entryNoticesBlock = (
      <ViewingEntryNoticesStatusAndDetailsCTA
        entryNoticeRecordsSummary={entryNoticeRecordsSummary}
        openDetailsHandler={this.onClickEntryNotices}
        uniqueId={viewingData.GUID}
        isViewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
      />
    )

    const showReportSentComponent =
      isReportSent || isViewingReportSmsSent || isViewingReportEmailSent
    const showSMSSentSection = isViewingReportSmsSent
    const showEmailReportSentSection = isReportSent || isViewingReportEmailSent

    const { isHighlighted } = this.props

    return (
      <ViewingDetailsContainer>
        <ViewingCard isHighlighted={isHighlighted}>
          <OppositeSidesContainer setLineHeight>
            <Flex flexDirection="column">
              <Flex alignItems="center">
                <RegAttendedConfirmedContainer
                  cancelled={viewingData.cancelled}
                >
                  <span>{time}</span>
                  <span className="ml5">{dayOrDate}</span>
                  <span className="ml5">{`(${duration}m)`}</span>
                  {!viewingData.published ? (
                    <img
                      src={privateLock}
                      alt="private viewingData"
                      className="ml5 private-lock"
                    />
                  ) : null}
                </RegAttendedConfirmedContainer>
              </Flex>
              <Box className="flex-direction-column" my={1}>
                <AssigneeRegistrationContainer>
                  <span>Reg: {registeredAttendeeCount}</span>
                  <span>Conf: {confirmedAttendeeCount}</span>
                  <span>Att: {attendedCount}</span>
                  <Box ml={3}>
                    {fullyBooked && !cancelled && (
                      <RegisteredViewingNumBadge
                        uniqueIdentifier={`${GUID}-full-badge`}
                        numRegistered={totalRegistered}
                        viewingCap={viewingCap}
                      />
                    )}
                  </Box>
                  {cancelled && <Badge variant="warning" text="Cancelled" />}
                </AssigneeRegistrationContainer>
              </Box>
            </Flex>
            <Button
              variant="outlineSuccess"
              sizeVariant="regular"
              onClick={() => this.openViewingTabsModal()}
              height="40px"
            >
              Open
            </Button>
          </OppositeSidesContainer>
          <Box fontSize={4} color="gray500" my={1}>
            <div className="assigned-info">
              Inspector: {assigned}{' '}
              {assignedTeamMembersCountMinusTwo > 0
                ? `+ ${assignedTeamMembersCountMinusTwo}`
                : ''}
              <span
                className="blue-link-style ml5"
                onClick={() => this.toggleTeamMemberModal(viewingData)}
              >
                edit
              </span>
            </div>
          </Box>
          <Flex fontSize={4} color="gray500">
            {entryNoticesBlock}

            {showReportSentComponent && (
              <ReportSent
                showSMSSentSection={showSMSSentSection}
                showEmailReportSentSection={showEmailReportSentSection}
              />
            )}
          </Flex>
        </ViewingCard>
        <AnimationWrapper
          transitionOptions={transitionOptions}
          component={
            <ViewingDetailsTabsModal
              AttendeesTabCmp={AttendeesTabCmp}
              ListingTabCmp={ListingTabCmp}
              ReportContentCmp={ReportTabCmp}
              propertyData={propertyData}
              closeHandler={() => this.onCloseViewingTabsModal()}
              modalHeading={modalHeadingWithoutViewingTime}
              propertyTools={propertyTools}
              showViewingAttendeesModalHeading={
                this.showViewingAttendeesModalHeading
              }
              hideViewingAttendeesModalHeading={
                this.hideViewingAttendeesModalHeading
              }
              displayViewingAttendeesModalHeading={
                this.state.displayViewingAttendeesModalHeading
              }
              viewingGUID={viewingData.GUID}
              currentTeamSlug={currentTeamSlug}
              viewingQRAutoCheckinEnabled={viewingQRAutoCheckinEnabled}
            />
          }
          isOpened={
            queryParams && queryParams.showAttendees && isViewingTabsModalShown
          }
        />
        {isAddAttendeeHubModalOn && (
          <AttendeeHubModal
            clearError={clearError}
            toggleAttendeeHubModal={this.onToggleAttendeeHubModal}
            enquirers={enquirers}
            onAttendeeHubModalAddIconClicked={
              this.onAttendeeHubModalAddIconClicked
            }
            onOnSiteAttendeeNameClicked={this.onOnSiteAttendeeNameClicked}
            fetchAgencyApplicantsElasticSearchResult={
              fetchAgencyApplicantsElasticSearchResult
            }
            teamId={teamGUID}
            filteredEnquirersForAttendeeHub={filteredEnquirersForAttendeeHub}
            onModalTitleClicked={this.onModalTitleClicked}
          />
        )}
        {addWalkInRenterModalOn && (
          <WalkinRenterModal
            viewingAttendeeCount={viewingAttendeeCount}
            error={apiError}
            toggleWalkInModal={this.onToggleWalkInModal}
            addWalkInRenter={this.onAddWalkInRenter}
            propertyId={propertyGUID}
            clearError={clearError}
            walkinModalStatus={walkinModalStatus}
            targetAttendee={targetAttendee}
            onUpdateRenterStatus={this.onUpdateRenterStatus}
            renterInfo={renterInfo}
            onSearchAttendeeLinkClicked={this.onSearchAttendeeLinkClicked}
            addAttendeePreloadContent={addAttendeePreloadContent}
            enableOptions={enabledOptionsForWalkinModal}
            disableSaveButton={disableSaveButton}
            agencyGUID={teamGUID}
            teamSlug={currentTeamSlug}
          />
        )}
        {isAddNoteModalOn && (
          <AddNoteModal
            turnOffAllModals={this.turnOffAllModals}
            onModalTitleClicked={this.onModalTitleClicked}
            propertyId={propertyGUID}
            activeViewingID={viewingData.GUID}
            showTopHeader={false}
          />
        )}
        {isTenantsDisplayModalOn && (
          <TenantDetailsModal
            toggleModal={() =>
              this.setState({
                isTenantsDisplayModalOn: !isTenantsDisplayModalOn,
              })
            }
            propertyGUID={propertyGUID}
            subject={subject}
          />
        )}
        {isCommentModalOn && (
          <AddCommentModal
            showCommentModal={this.onShowCommentModal}
            error={apiError}
            clearError={clearError}
            updateRenterStatusComment={this.onUpdateRenterStatusComment}
            renterInfo={renterInfo}
            isCommentModalOn={isCommentModalOn}
          />
        )}
        {isLateModalOn && (
          <LateForViewingModal
            showLateViewingModal={this.onShowLateViewingModal}
            error={apiError}
            notifyLateViewing={this.onNotifyLateViewing}
            clearError={clearError}
            totalCheckedInAttendeeCount={totalCheckedInAttendeeCount}
            isLateModalOn={isLateModalOn}
          />
        )}
        {openModalForTeamMember && (
          <AssignViewingTeamMemberModal
            toggleModal={this.toggleTeamMemberModal}
            viewing={dataForAssignedTeamMember}
            teamId={teamGUID}
            assignViewingToTeamMember={assignViewingToTeamMember}
            fetchViewingTeamMembers={fetchViewingTeamMembers}
            deleteViewingTeamMember={deleteViewingTeamMember}
            updateViewingTeamMemberRelation={updateViewingTeamMemberRelation}
            propertyId={propertyGUID}
            viewingStartDate={formattedViewingStartDate.startTime}
            managersListForViewingFilters={getManagersListForViewingFilters}
          />
        )}
        {isSendSmsReportModalOn && (
          <SendSmsReportModal
            data={this.state.smsData}
            handleClose={() => this.setState({ isSendSmsReportModalOn: false })}
            showSendSmsReportModal={this.onShowSendSmsReportModal}
            clearError={clearError}
            isSendSmsReportModalOn={isSendSmsReportModalOn}
          />
        )}
        {isAccessDetailModalOn && (
          <AddAccessDetailModal
            turnOffAllModals={this.turnOffAllModals}
            onModalTitleClicked={this.onModalTitleClicked}
            property={propertyGUID}
            agencyGUID={teamGUID}
            showTopHeader={false}
          />
        )}
        {showUpgradeAccountModal && (
          <SnugUpgradeAccountModal
            isNotToBeEmbedded={false}
            toggleModal={this.onClickDisabledLiteAccountOption}
            typeOfMessage={accountHelpers.UPGRADE_TYPE_VIEWINGS}
          />
        )}
        {tenantReadModal && (
          <TenantReadModal
            toggleModal={() => this.onClickEntryNotices()}
            tenantsWhoReadTheEntryNotice={tenantsWhoReadTheEntryNotice}
          />
        )}
        {renderMergeViewingModal()}
        {renderMergeViewingCancelModal()}
        {showViewingRegistrantsMessageModal && (
          <ViewingRegistrantsMessagesModal
            agencyGUID={teamGUID}
            onDismissClick={() =>
              this.setState({
                showViewingRegistrantsMessageModal: false,
              })
            }
            viewingGUID={viewingData.GUID}
            friendlyName={addressStr}
            registrantsCount={registeredAttendeeCount}
            startDate={startDate}
          />
        )}
      </ViewingDetailsContainer>
    )
  }
}

export default ViewingDetails
