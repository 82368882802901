import { receiveResponseText } from 'app/sm/apply/apply_actions'
import { getProperty } from 'app/sm/property_details/property_details_access'
import store from 'app/store'

export const RECEIVE_PROPERTY = 'AP_RECEIVE_PROPERTY'

const receiveProperty = (property) => ({
  type: RECEIVE_PROPERTY,
  property,
})

export function fetchProperty(id) {
  return (dispatch) => {
    getProperty(id).then(({ property, ok, responseText, statusCode }) => {
      if (ok) {
        dispatch(receiveProperty(property))
      } else {
        responseText.then((t) => {
          store.dispatch(receiveResponseText(t))
        })
      }
    })
  }
}
