import moment from 'moment'

export const UPDATE_MODAL_TYPE_EMPLOYMENT = 'TYPE_EMP'
export const UPDATE_MODAL_TYPE_ADDRESS = 'TYPE_ADDRESS'

export const MODAL_OBJECT_HEADER = 'header'
export const MODAL_OBJECT_ACTION = 'action'
export const MODAL_OBJECT_ELSE_Q = 'somewhereElseQuestion'
export const MODAL_OBJECT_CURR_TYPE_MSG = 'updateCurrentTypeMessage'
export const MODAL_OBJECT_NO_NEED_TO_CHNG_TXT = 'noNeedToChangeText'
export const MODAL_OBJECT_APPEND_TO_FIRST_Q = 'appendToFirstQ'

export const updateModalObj = {
  [UPDATE_MODAL_TYPE_EMPLOYMENT]: {
    [MODAL_OBJECT_HEADER]: 'Are you employed at',
    [MODAL_OBJECT_ACTION]: 'employment',
    [MODAL_OBJECT_ELSE_Q]: 'Are you working somewhere else now?',
    [MODAL_OBJECT_CURR_TYPE_MSG]:
      'Thanks for confirming. Click continue to update your old employment with your leave date and add your current employment.',
    [MODAL_OBJECT_NO_NEED_TO_CHNG_TXT]:
      'Thanks for confirming. Click continue to resume updating your profile / application.',
    [MODAL_OBJECT_APPEND_TO_FIRST_Q]: '. Are you currently employed at ',
  },
  [UPDATE_MODAL_TYPE_ADDRESS]: {
    [MODAL_OBJECT_HEADER]: 'Is your home',
    [MODAL_OBJECT_ACTION]: 'address',
    [MODAL_OBJECT_ELSE_Q]: 'Are you living at a new address now?',
    [MODAL_OBJECT_CURR_TYPE_MSG]:
      'Thanks for confirming. Click continue to update your old address with your vacate date and add your current home address.',
    [MODAL_OBJECT_NO_NEED_TO_CHNG_TXT]:
      'Thanks for confirming. Click continue to resume updating your profile / application.',
    [MODAL_OBJECT_APPEND_TO_FIRST_Q]: '. Is your current home',
  },
}

const NUM_MONTHS_TO_CHECK = 2

export const checkIfAddressIsLessThanSpecifiedMonths = (rentalHistory) => {
  let isLessThanSpecifiedMonths = false
  let numMonthsMinusSpecifiedMonths = 0
  let currentAddress = {}
  rentalHistory.forEach((history) => {
    if (history && history.isCurrentAddress) {
      currentAddress = history
      numMonthsMinusSpecifiedMonths = moment(history.CreatedAt).fromNow(true)
      if (
        moment(history.CreatedAt).isBefore(
          moment().subtract(NUM_MONTHS_TO_CHECK, 'months'),
        )
      ) {
        isLessThanSpecifiedMonths = true
      }
    }
  })
  return {
    isLessThanSpecifiedMonths,
    currentAddress,
    numMonthsMinusSpecifiedMonths,
  }
}

export const checkIfEmployedForLessThanSpecifiedMonths = (employers) => {
  let isLessThanSpecifiedMonths = false
  let numMonthsMinusSpecifiedMonths = 0
  let currentEmployer = {}
  employers.forEach((employer) => {
    if (employer && employer.stillEmployed) {
      currentEmployer = employer
      numMonthsMinusSpecifiedMonths = moment(employer.CreatedAt).fromNow(true)
      if (
        moment(employer.CreatedAt).isBefore(
          moment().subtract(NUM_MONTHS_TO_CHECK, 'months'),
        )
      ) {
        isLessThanSpecifiedMonths = true
      }
    }
  })
  return {
    isLessThanSpecifiedMonths,
    numMonthsMinusSpecifiedMonths,
    currentEmployer,
  }
}

export const STILL_AT_X_YES = 'Yes'
export const STILL_AT_X_NO = 'No'

export const stillAtXOptions = [
  {
    value: STILL_AT_X_YES,
    picked: false,
  },
  {
    value: STILL_AT_X_NO,
    picked: false,
  },
]

export const atNewXOptions = [
  {
    value: STILL_AT_X_YES,
    picked: false,
  },
  {
    value: STILL_AT_X_NO,
    picked: false,
  },
]
