import React from 'react'

import celebrateIcon from 'app/assets/icons/celebrate.svg'
import { Alert, Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'

const JoinSnugBanner = () => {
  return (
    <Alert variant="grayWithBg">
      <Flex alignItems="center">
        <img
          src={celebrateIcon}
          height={theme.height[12] + 'px'}
          width={theme.width[13] + 'px'}
          alt="join snug celebrate"
        />
        <Text
          my={0}
          ml={3}
          px={4}
          color={theme.colors.gray500}
          fontSize={theme.fontSizes.pRegular14}
        >
          Join dozens of agencies activating free accounts on Snug every day!
          Plus access integrations and customisations for free.
        </Text>
      </Flex>
    </Alert>
  )
}

export default JoinSnugBanner
