import React from 'react'

import qs from 'qs'
import Dropdown from 'react-bootstrap/Dropdown'
import { Link, NavLink, Route } from 'react-router-dom'

import houseIcon from 'app/assets/icons/house01.jpg'
import snug_logo_mobile from 'app/assets/icons/snug_logo_mobile.png'
import Switcher from 'app/assets/icons/switch-icon.svg'
import { Box, Flex } from 'app/components/design-system-components'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
// This is suitable to match the active top nav whenever we want, not just by identical route screen
// Here it's explained https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/api/NavLink.md#isactive-func
import * as snugNotifier from 'app/services/snugNotifier'
import { ActivityFeedContextProvider } from 'app/shared_components/activity_feed/ActivityFeedContext'
import ActivityFeedIconManager from 'app/shared_components/activity_feed/manager'
import ActivitySideBar from 'app/shared_components/activity_feed/Sidebar'
import { isLoggedIn } from 'app/shared_components/helpers'
import LiteAccountButton from 'app/shared_components/lite_account_button'
import AdminNavbar from 'app/shared_components/navbar/components/admin_navbar'
import FindHome from 'app/shared_components/navbar/components/find_home'
import PortfolioNavbar from 'app/shared_components/navbar/components/portfolio_navbar'
import PropertyDashboardNavBar from 'app/shared_components/navbar/components/property_dashboard_navbar'
import RenterNavbar from 'app/shared_components/navbar/components/renter_navbar'
import RRNavbar from 'app/shared_components/navbar/components/RRNavbar'
import TeamNavbar from 'app/shared_components/navbar/components/team_navbar'
import { history } from 'app/shared_components/router'
import { websiteUrl } from 'app/shared_components/shared_base_url'
import SnugLogo from 'app/shared_components/snug_logo'
import { capitalizeFirstLetter, urlTo } from 'app/sm/helpers'
import { SessionStorageUtils } from 'app/storage_utils'
import * as accountHelpers from 'app/utils/accounts/helpers'
import {
  AGENCY_USER_LEASE_CONSULTANT,
  UserAdminFinance,
  UserLeasingConsultant,
} from 'app/utils/roles'
import * as urlHelpers from 'app/utils/url/helpers'

// This is suitable to match the active top nav whenever we want, not just by identical route screen
// Here it's explained https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/api/NavLink.md#isactive-func
const navLinks = {
  MyHome: [
    /sm\/wishlist/,
    /sm\/viewings/,
    /sm\/history/,
    /sm\/applications/,
    /^\/dashboard/,
    /home\/overview/,
    /home\/dashboard/,
    /bc\/profile/,
  ],
  MyPortfolio: [
    /sm\/property/,
    /sm\/properties/,
    /sm\/properties\/viewings/,
    /portfolio\/overview/,
    /portfolio\/dashboard/,
  ],
  MyAgency: [
    /sm\/property/,
    /sm\/properties/,
    /sm\/properties\/viewings/,
    /portfolio\/overview/,
    /portfolio\/dashboard/,
    /bc\/dashboard/,
    /agency\/create/,
    /agency\/register/,
    /agency/,
    /teams/,
  ],
}

function isActive(navLink) {
  return (_, location) =>
    navLinks[navLink].some((route) => route.test(location.pathname))
}

// a boolean
const isTeamsTabActive = (currentTeam, location) => {
  return (
    currentTeam &&
    currentTeam.slug &&
    navLinks['MyAgency'].some((route) => route.test(location.pathname))
  )
}

function sideBarTo() {
  const { location } = history
  if (isActive('MyPortfolio')(null, location)) {
    return urlTo('portfolioOverview')
  } else if (isActive('MyAgency')(null, location)) {
    return urlTo('teamOverview')
  }
  return urlTo('homeoverview')
}

const teamSwitchingRoutes = {
  createProperty: /sm\/properties\/create$/,
  propertyGetStarted: /sm\/properties\/get-started$/,
  teamOverview: /overview$/,
  teamMessages: /messages$/,
  newTeamMessages: /messages-new$/,
  portfolioOverview: /portfolio\/overview$/,
  managerDashboard: /portfolio\/dashboard$/,
  teamsApplication: /teams\/[a-zA-Z0-9]+\?stage=Application/,
  teamsViewing: /teams\/[a-zA-Z0-9]+\?stage=Viewing/,
  teamsViewingByDate: /teams\/[a-zA-Z0-9]+\/viewings$/,
  prospectSummary: /teams\/[a-zA-Z0-9]+$/,
  teamActivity: /activity$/,
  teamApplications: /applications$/,
  teamViewingsMobile: /teams\/[a-zA-Z0-9]+\/viewings\/mobile$/,
  teamOnboarding: /teams\/[a-zA-Z0-9]+\/academy/,
  plugin: /teams\/[a-zA-Z]+\/plugin\/[a-zA-Z]$/,
  mobileTeamsPages: /mobile\/teams\/[a-zA-Z0-9]+\//,
  teamRentalReferences: /teams\/[a-zA-Z0-9]+\/rental-references\/all/,
  teamRentalReferencesFirstTime:
    /teams\/[a-zA-Z0-9]+\/rental-references-f\/all/,
  teamMessagesNew: /teams\/[a-zA-Z0-9]+\/messages/,
  keysLoggerPage: /teams\/[a-zA-Z0-9]+\/keys/,
}

const isTeamSwitchingPath = (path) => {
  const validRoutes = Object.values(teamSwitchingRoutes)
  return validRoutes.some((route) => route.test(path))
}

const extractTeamSlug = (path) => {
  if (!isTeamSwitchingPath(path)) {
    return undefined
  }
  const segments = path.split('/')
  const teamsIndex = segments.indexOf('teams')
  if (teamsIndex === -1) return undefined
  return segments[teamsIndex + 1]
}

class TeamDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.onClickOtherTeam = this.onClickOtherTeam.bind(this)
  }

  onClickOtherTeam = (teamSlug) => {
    const { resetPropertyFilters } = this.props
    resetPropertyFilters()
    history.push(urlTo('teamOverview', { teamSlug }))
  }

  onClickTeam = (teamSlug) => {
    const currentUrl = window.location.pathname + window.location.search
    const routeAlias = Object.keys(teamSwitchingRoutes).find((key) =>
      teamSwitchingRoutes[key].test(currentUrl),
    )

    if (routeAlias) {
      history.push(urlTo(routeAlias, { teamSlug }))
    } else {
      // if not found, e.g. on home/overview, then default to portfolio overview
      history.push(urlTo('teamOverview', { teamSlug }))
    }
  }

  render() {
    const { teams, isTabActive } = this.props
    const realTeams = teams.filter((team) => team.slug)
    const toggleClass = isTabActive
      ? 'nav-dropdown-toggle nav-dropdown-toggle-active'
      : 'nav-dropdown-toggle'

    const currentTeamName =
      this.props.currentTeam && this.props.currentTeam.name
    return realTeams.length > 1 ? (
      <Dropdown
        className={`nav-dropdown ${toggleClass}`}
        id="team-dropdown"
        as="div"
        navbar={true}
      >
        <Dropdown.Toggle useAnchor>
          <img className="mr20 pb2 mobile-only" src={Switcher} />
          <span className="fw600">My Teams</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {realTeams
            .filter((team) => team.slug)
            .map((team, index) => {
              const menuItem = [
                <Dropdown.Item
                  key={team.name}
                  className={`nav-dropdown-menuitem ${
                    team.name === currentTeamName ? 'active-menuitem' : ''
                  }`}
                  onClick={() => this.onClickOtherTeam(team.slug)}
                >
                  {team.name}
                </Dropdown.Item>,
              ]
              return menuItem
            })
            .reduce((acc, cur) => acc.concat(cur), [])}
        </Dropdown.Menu>
      </Dropdown>
    ) : (
      <NavLink
        to={urlTo('teamOverview', { teamSlug: realTeams[0].slug })}
        onClick={() => this.onClickTeam(realTeams[0].slug)}
        isActive={() => isTabActive}
      >
        <img className="mr20 pb2 mobile-only" src={Switcher} />
        <span className="fw600">My Team</span>
      </NavLink>
    )
  }
}

const MainMenu = ({
  currentTeam,
  teams,
  pathname,
  logout = undefined,
  resetPropertyFilters,
}) => {
  return (
    <div>
      <ul className="bodyMenu">
        {teams && teams.length === 1 && (
          <li className="mobile-item">
            <NavLink to="/home/overview" isActive={isActive('MyHome')}>
              <img className="mr20 pb2 mobile-only" src={Switcher} />
              <span>My Home</span>
            </NavLink>
          </li>
        )}
        {teams && teams.length > 1 && (
          <li className="mobile-item">
            <TeamDropdown
              currentTeam={currentTeam}
              isTabActive={isTeamsTabActive(currentTeam, window.location)}
              teams={teams}
              pathname={pathname}
              resetPropertyFilters={resetPropertyFilters}
            />
          </li>
        )}
        <li className="mobile-item mobile-only">
          <a className="font-weight600" onClick={logout} href="#">
            Sign out
          </a>
        </li>
        <li className="mobile-item desktop-only">
          <a href="http://help.snug.com" target="_blank" rel="noreferrer">
            <span>Help</span>
          </a>
        </li>
      </ul>
      <ul className="mainMenu mobile-only">
        <li className="divider" />
        <li className="mobile-item first-item">
          <a href="http://help.snug.com" target="_blank" rel="noreferrer">
            <span>Help</span>
          </a>
        </li>
        <li className="mobile-item">
          <NavLink exact to="/legals">
            <span>Legals</span>
          </NavLink>
        </li>
        <li className="mobile-item">
          <NavLink exact to="/contact">
            <span>Contact Us</span>
          </NavLink>
        </li>
      </ul>
    </div>
  )
}

const setCurrentURLAsForwardURL = () => {
  const url = `${window.location.pathname}${window.location.search}`
  SessionStorageUtils.setItem('forwardUrl', url)
}

const sessionLinks = () => (
  <ul className="sessionLinks">
    <li>
      <NavLink exact to={urlTo('join')} onClick={setCurrentURLAsForwardURL}>
        <span>Sign up</span>
      </NavLink>
    </li>
    <li>
      <NavLink exact to={urlTo('join')} onClick={setCurrentURLAsForwardURL}>
        <span>Login</span>
      </NavLink>
    </li>
  </ul>
)

const notificationsInfo = (notifications) => (
  <div className="dropdown notificationsInfo">
    <div className="dropdown-toggle" data-toggle="dropdown">
      <i className="icon-mail" />
      <div className="badge">{notifications.length}</div>
    </div>
    <ul className="dropdown-menu">
      {notifications.map((notification, index) => (
        <li key={notification.id}>
          <Link to={`/notification/${notification.id}`}>
            {notification.message}
          </Link>
        </li>
      ))}
    </ul>
  </div>
)

const UserInfo = ({ currentUser, logout, isAdminRole, currentTeam }) => (
  <div className="dropdown userInfo desktop-only">
    <div className="dropdown-toggle" data-toggle="dropdown">
      <div className="text">
        {currentTeam && currentTeam.slug && (
          <span className="hideBelow1080">{currentTeam.name}, &nbsp;</span>
        )}
        {[
          capitalizeFirstLetter(currentUser.firstName),
          capitalizeFirstLetter(currentUser.lastName.charAt(0)) + '.',
        ].join(' ')}
      </div>
      {currentUser.avatar ? (
        <div
          className="avatar avatar-xsmall"
          style={{ backgroundImage: `url(${currentUser.avatar})` }}
        />
      ) : (
        <div className="avatar avatar-xsmall blue-bg">
          {currentUser.firstName
            ? currentUser.firstName.charAt(0).toUpperCase()
            : ''}
          {currentUser.lastName
            ? currentUser.lastName.charAt(0).toUpperCase()
            : ''}
        </div>
      )}
    </div>

    <ul className="dropdown-menu">
      <li>
        <Link to={urlTo('applications')}>My Applications</Link>
      </li>
      <li>
        <Link to={urlTo('RentalReputation')}>My Profile</Link>
      </li>
      <li className="divider" />
      <li>
        <a onClick={logout} href="#">
          Sign out
        </a>
      </li>
    </ul>
  </div>
)

const focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
  if (
    relatedTarget === null ||
    relatedTarget.className === 'navbar-header-right'
  )
    return false
  let node = relatedTarget.parentNode
  while (node !== null) {
    if (node === currentTarget) return true
    node = node.parentNode
  }

  return false
}

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: true,
      portfolioFetched: false,
      isAccountLite: true,
    }
  }

  componentDidMount() {
    this.props.fetchTeams().catch((error) => {
      snugNotifier.error(translateErrorCodeToMessage(error))
    })
    if (isTeamSwitchingPath(this.props.location.pathname)) {
      const teamSlug = extractTeamSlug(this.props.location.pathname)
      const currentTeam = this.props.teams.find(
        (team) => team.slug === teamSlug,
      )
      currentTeam && this.props.changeTeam(currentTeam)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.close === true) {
      this.handleClicks()
    }

    // sync url to redux
    if (
      nextProps.teams !== this.props.teams ||
      nextProps.location.pathname !== this.props.location.pathname
    ) {
      if (isTeamSwitchingPath(nextProps.location.pathname)) {
        const teamSlug = extractTeamSlug(nextProps.location.pathname)
        const currentTeam = nextProps.teams.find(
          (team) => team.slug === teamSlug,
        )
        currentTeam && this.props.changeTeam(currentTeam)
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser, fetchApplicationsCount } = this.props
    const isUserLoggedIn = isLoggedIn(currentUser)

    if (isUserLoggedIn && !this.initialUpdate) {
      fetchApplicationsCount()
      this.initialUpdate = true
    }
  }

  onBlur = (e) => {
    if (!focusInCurrentTarget(e)) {
      this.handleClicks(e)
    }
  }

  fetchProperties() {
    this.setState({ portfolioFetched: true })
    this.props.fetchProperties()
  }
  findUserRole(role) {
    return role == 'admin' || role == 'super'
  }

  handleClicks = (event) => {
    const { target } = event || {}
    const { dataset, parentElement } = target || {}
    // This is commented because of the bug to render the navbar whenever user clicks somewhere
    if (event && (dataset.menutoggle || parentElement.dataset.menutoggle)) {
      if (
        dataset.menutoggle == 'true' ||
        parentElement.dataset.menutoggle == 'true'
      ) {
        const { collapsed } = this.state
        this.setState({ collapsed: !collapsed })
      } else {
        this.props.resetSideBar()
        this.setState({ collapsed: false })
      }
    } else {
      this.setState({ collapsed: true })
    }
  }

  render() {
    const {
      currentUser,
      logout,
      currentAgency,
      bondCovers,
      property,
      applicationsCount: userSummary,
      resetPropertyFilters,
      className,
      hasNewActivityFeedConfig,
    } = this.props
    const bcApplicationsCount = bondCovers.filter((bondCover) => {
      return bondCover.status !== 'WITHDRAWN'
    }).length

    const { applicationsCount = 0 } = userSummary || {}
    const { collapsed } = this.state
    const userRoles = currentUser && currentUser.roles
    const hasProperty = property && property.property
    let isAdminRole = false
    if (userRoles) {
      isAdminRole = !!currentUser.roles.find(this.findUserRole)
    }
    const agencyID = currentAgency ? currentAgency.agencyID : null
    let agencyUserRole = UserAdminFinance
    if (currentAgency !== null) {
      currentAgency.roles.forEach((agency) => {
        if (agency.roleID === AGENCY_USER_LEASE_CONSULTANT) {
          agencyUserRole = UserLeasingConsultant
        }
      })
    }

    const renterNavBarTo = sideBarTo()

    let isAgencyProperty = false
    if (hasProperty) {
      isAgencyProperty = !!property.property.agencyID
    }

    const sidebarRoutes = [
      {
        path: '/home/dashboard',
        exact: true,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              bcApplicationsCount={bcApplicationsCount}
              to={renterNavBarTo}
            />
          </div>
        ),
      },
      {
        path: '/portfolio/dashboard',
        exact: true,
        sidebar: ({ match }) => (
          <PortfolioNavbar
            match={match}
            fetchProperties={
              !this.state.portfolioFetched
                ? () => this.fetchProperties()
                : () => false
            }
            setBackUrl={this.props.setBackUrl}
          />
        ),
      },
      {
        path: '/teams/:teamSlug',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/contacts/add',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/contacts',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/overview',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/settings',
        exact: false,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/rental-references',
        exact: false,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/rental-references-f',
        exact: false,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/messages-new',
        exact: false,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/keys',
        exact: false,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: urlTo('teamMessages', { teamSlug: ':teamSlug' }),
        exact: false,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/applications',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/activity',
        exact: false,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/viewings',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/viewings/runs',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/viewings/runs/:viewingRunGUID',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/viewings/viewing-run/:viewingRunGUID/edit',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/viewings/viewing-run/:viewingRunGUID/preview/edit',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/viewings/newrun',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/viewings/preview',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      ,
      {
        path: '/teams/:teamSlug/viewings/new-viewing-run',
        // exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/sm/property/:propertyId/applications/:applicationId',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/sm/property/:propertyId/applications/:applicationId/applicant/:applicantId',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/portfolio/dashboard',
        exact: true,
        sidebar: ({ match }) => (
          <PortfolioNavbar
            match={match}
            fetchProperties={
              !this.state.portfolioFetched
                ? () => this.fetchProperties()
                : () => false
            }
            setBackUrl={this.props.setBackUrl}
            isTeamsTabActive={isTeamsTabActive}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/dashboard',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/academy',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/mobile/teams/:teamSlug/viewings',
        sidebar: ({ match, location }) => (
          <TeamNavbar match={match} location={location} />
        ),
      },
      {
        path: '/teams/:teamSlug/viewings/mobile',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/preference/status',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/preference/users',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar
            match={match}
            location={location}
            agencyUserRole={agencyUserRole}
          />
        ),
      },
      {
        path: '/sm/history',
        exact: true,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: `/${urlHelpers.RR_TO_LITE_URL_PART}/rating/:rating/secret/:secretGUID`,
        exact: true,
        sidebar: () => <RRNavbar />,
      },
      {
        path: '/home/overview',
        exact: true,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/bc/profile',
        exact: true,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/bc/rental-history',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/sm/income',
        exact: true,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/bc/profile/employment-list',
        exact: true,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/bc/profile/employment',
        exact: true,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/sm/identity-documents',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/sm/background-check/overview',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/bc/profile/about-us',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/bc/profile/avatar',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/sm/pets-details',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/sm/rental-reputation/documents',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/sm/personal-reference-details',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/sm/personal-reference/add',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/sm/student-details',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/sm/emergency-contact-details',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/sm/emergency-contact/add',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/bc/profile/credit-cards',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/bc/cards',
        exact: false,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/bc/renterwait',
        exact: true,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/portfolio/overview',
        exact: true,
        sidebar: ({ match }) => (
          <PortfolioNavbar
            match={match}
            fetchProperties={
              !this.state.portfolioFetched
                ? () => this.fetchProperties()
                : () => false
            }
            setBackUrl={this.props.setBackUrl}
          />
        ),
      },
      {
        path: '/teams/:teamSlug/portfolio/overview',
        exact: true,
        sidebar: ({ match }) => (
          <PortfolioNavbar
            match={match}
            fetchProperties={
              !this.state.portfolioFetched
                ? () => this.fetchProperties()
                : () => false
            }
            setBackUrl={this.props.setBackUrl}
            isTeamsTabActive={isTeamsTabActive}
          />
        ),
      },
      {
        path: '/sm/viewings',
        exact: true,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/sm/wishlist',
        exact: true,
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/bc',
        exact: true,
        sidebar: () => (
          <div className="navLinks">
            {/* <FindHome applicationsCount={applicationsCount} /> */}
            <RenterNavbar
              applicationsCount={applicationsCount}
              bcApplicationsCount={bcApplicationsCount}
              to={renterNavBarTo}
            />
          </div>
        ),
      },
      {
        path: '/sm/applications',
        sidebar: () => (
          <div className="navLinks">
            <RenterNavbar
              applicationsCount={applicationsCount}
              to={renterNavBarTo}
              bcApplicationsCount={bcApplicationsCount}
            />
          </div>
        ),
      },
      {
        path: '/sm/properties/viewings',
        exact: true,
        sidebar: () => (
          <PortfolioNavbar
            fetchProperties={
              !this.state.portfolioFetched
                ? () => this.fetchProperties()
                : () => false
            }
            setBackUrl={this.props.setBackUrl}
          />
        ),
      },
      {
        path: '/sm/property/:propertyId/applications',
        exact: true,
        sidebar: ({ match }) => (
          <PropertyDashboardNavBar
            propertyId={match.params.propertyId}
            isAgencyProperty={isAgencyProperty}
            property={property}
            applicationsCount={applicationsCount}
            fetchProperty={this.props.fetchProperty}
          />
        ),
      },
      // {
      //   path: '/sm/property/:propertyId/applications/:applicationId/applicant/:applicantId/new',
      //   exact: true,
      //   sidebar: ({ match, location }) => <TeamNavbar match={match} location={location} />,
      // },
      {
        path: '/teams/:teamSlug/sm/property/:propertyId/applications',
        exact: true,
        sidebar: ({ match }) => (
          <PortfolioNavbar
            match={match}
            fetchProperties={
              !this.state.portfolioFetched
                ? () => this.fetchProperties()
                : () => false
            }
            setBackUrl={this.props.setBackUrl}
          />
        ),
      },

      {
        path: '/admin',
        exact: true,
        sidebar: ({ match }) => <AdminNavbar />,
      },
      {
        path: '/agency',
        exact: true,
        sidebar: ({ match }) => <AdminNavbar />,
      },
      {
        path: '/agency/register',
        exact: true,
        sidebar: ({ match }) => <AdminNavbar />,
      },
      {
        path: '/agency/create',
        exact: true,
        sidebar: ({ match }) => <AdminNavbar />,
      },
      {
        path: '/admin/agency/edit',
        exact: false,
        sidebar: ({ match }) => <AdminNavbar />,
      },
      {
        path: '/agency/supplier-source',
        exact: true,
        sidebar: ({ match }) => <AdminNavbar />,
      },
      {
        path: '/sm/property/:offerId/apply',
        exact: true,
        sidebar: ({ match }) => (
          <div className="navLinks">
            {/* <FindHome applicationsCount={applicationsCount} /> */}
          </div>
        ),
      },
      {
        path: '/sm/property/:propertyId',
        exact: true,
        sidebar: ({ match }) => (
          <div className="navLinks">
            <FindHome applicationsCount={applicationsCount} />
          </div>
        ),
      },
      {
        path: '/apply/p/:propertyId',
        exact: true,
        sidebar: ({ match }) => (
          <div className="navLinks">
            {/* <FindHome applicationsCount={applicationsCount} /> */}
          </div>
        ),
      },
      {
        path: '/sm/property/:id/offer/:offerId/pay-holding-deposit',
        exact: true,
        sidebar: ({ match }) => (
          <div className="navLinks">
            <ul>
              <li>
                <NavLink
                  exact
                  to={`/sm/property/${match.params.id}/offer/${match.params.offerId}/pay-holding-deposit`}
                >
                  <i className="icon-dot" />
                  <span>Pay</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to={`/sm/property/${match.params.id}/offer/${match.params.offerId}/sign-lease`}
                >
                  <i className="icon-blank" />
                  <span>Lease</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to={`/sm/property/${match.params.id}/offer/${match.params.offerId}/signature-sign`}
                >
                  <i className="icon-blank" />
                  <span>Sign</span>
                </NavLink>
              </li>
            </ul>
          </div>
        ),
      },
      {
        path: '/sm/property/:id/offer/:offerId/sign-lease',
        exact: true,
        sidebar: ({ match }) => (
          <div className="navLinks">
            <ul>
              <li>
                <NavLink
                  exact
                  to={`/sm/property/${match.params.id}/offer/${match.params.offerId}/pay-holding-deposit`}
                >
                  <i className="icon-checkmark" />
                  <span>Pay</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to={`/sm/property/${match.params.id}/offer/${match.params.offerId}/sign-lease`}
                >
                  <i className="icon-dot" />
                  <span>Lease</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to={`/sm/property/${match.params.id}/offer/${match.params.offerId}/signature-sign`}
                >
                  <i className="icon-blank" />
                  <span>Sign</span>
                </NavLink>
              </li>
            </ul>
          </div>
        ),
      },
      {
        path: '/sm/property/:id/offer/:offerId/signature-sign',
        exact: true,
        sidebar: ({ match }) => (
          <div className="navLinks">
            <ul>
              <li>
                <NavLink
                  exact
                  to={`/sm/property/${match.params.id}/offer/${match.params.offerId}/pay-holding-deposit`}
                >
                  <i className="icon-checkmark" />
                  <span>Pay</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to={`/sm/property/${match.params.id}/offer/${match.params.offerId}/sign-lease`}
                >
                  <i className="icon-checkmark" />
                  <span>Lease</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to={`/sm/property/${match.params.id}/offer/${match.params.offerId}/signature-sign`}
                >
                  <i className="icon-dot" />
                  <span>Sign</span>
                </NavLink>
              </li>
            </ul>
          </div>
        ),
      },
      {
        path: '/sm/property/:propertyId/offer/*',
        exact: false,
        sidebar: ({ match }) => (
          <div className="navLinks">
            <FindHome applicationsCount={applicationsCount} />
          </div>
        ),
      },
      {
        path: '/sm/properties/alternate_profile/create',
        exact: true,
        sidebar: () => (
          <div className="navLinks">
            <FindHome applicationsCount={applicationsCount} />
          </div>
        ),
      },
      {
        path: '/sm/property/:propertyId/dashboard',
        exact: true,
        sidebar: ({ match }) => (
          <PropertyDashboardNavBar
            propertyId={match.params.propertyId}
            isAgencyProperty={isAgencyProperty}
            property={property}
            applicationsCount={applicationsCount}
            fetchProperty={this.props.fetchProperty}
          />
        ),
      },
      {
        path: '/sm/property/:propertyId/viewings/owner',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar match={match} location={location} />
        ),
      },
      {
        path: '/sm/property/:propertyId/ownerreport',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar match={match} location={location} />
        ),
      },
      {
        path: '/sm/property/:propertyId/viewings/owner/temporary',
        exact: true,
        sidebar: ({ match }) => (
          <PropertyDashboardNavBar
            propertyId={match.params.propertyId}
            isAgencyProperty={isAgencyProperty}
            property={property}
            applicationsCount={applicationsCount}
            fetchProperty={this.props.fetchProperty}
          />
        ),
      },
      {
        path: '/sm/property/:propertyId/todos',
        exact: true,
        sidebar: ({ match }) => (
          <div className="navLinks">
            <ul>
              <li>
                <NavLink to="/dashboard">
                  <i className="icon-arrow-left" />
                  <span>Overview</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard">
                  <div
                    className="avatar avatar-xxsmall"
                    style={{ backgroundImage: `url(${houseIcon})` }}
                  />
                  <span>50 Bond St.</span>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/dashboard`}
                >
                  <i className="icon-home-overview" />
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sm/property/${match.params.propertyId}/todos`}>
                  <i className="icon-todos" />
                  <span>To Do's</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/applications`}
                >
                  <i className="icon-applications" />
                  <span>Applications</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/transactions`}
                >
                  <i className="icon-money" />
                  <span>Transactions</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/lease-details`}
                >
                  <i className="icon-lease" />
                  <span>Lease</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/maintenance`}
                >
                  <i className="icon-maintenece" />
                  <span>Maintenance</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/documents`}
                >
                  <i className="icon-documents" />
                  <span>Documents</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sm/property/${match.params.propertyId}/chat`}>
                  <i className="icon-chat" />
                  <span>Chat</span>
                </NavLink>
              </li>
            </ul>
          </div>
        ),
      },
      {
        path: '/sm/property/:propertyId/transactions',
        exact: true,
        sidebar: ({ match }) => (
          <div className="navLinks">
            <ul>
              <li>
                <NavLink to="/dashboard">
                  <i className="icon-arrow-left" />
                  <span>Overview</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard">
                  <div
                    className="avatar avatar-xxsmall"
                    style={{ backgroundImage: `url(${houseIcon})` }}
                  />
                  <span>50 Bond St.</span>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/dashboard`}
                >
                  <i className="icon-home-overview" />
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sm/property/${match.params.propertyId}/todos`}>
                  <i className="icon-todos" />
                  <span>To Do's</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/applications`}
                >
                  <i className="icon-applications" />
                  <span>Applications</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/transactions`}
                >
                  <i className="icon-money" />
                  <span>Transactions</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/lease-details`}
                >
                  <i className="icon-lease" />
                  <span>Lease</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/maintenance`}
                >
                  <i className="icon-maintenece" />
                  <span>Maintenance</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/documents`}
                >
                  <i className="icon-documents" />
                  <span>Documents</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sm/property/${match.params.propertyId}/chat`}>
                  <i className="icon-chat" />
                  <span>Chat</span>
                </NavLink>
              </li>
            </ul>
          </div>
        ),
      },
      {
        path: '/sm/property/:propertyId/lease-details',
        exact: true,
        sidebar: ({ match }) => (
          <div className="navLinks">
            <ul>
              <li>
                <NavLink to="/dashboard">
                  <i className="icon-arrow-left" />
                  <span>Overview</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard">
                  <div
                    className="avatar avatar-xxsmall"
                    style={{ backgroundImage: `url(${houseIcon})` }}
                  />
                  <span>50 Bond St.</span>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/dashboard`}
                >
                  <i className="icon-home-overview" />
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sm/property/${match.params.propertyId}/todos`}>
                  <i className="icon-todos" />
                  <span>To Do's</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/applications`}
                >
                  <i className="icon-applications" />
                  <span>Applications</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/transactions`}
                >
                  <i className="icon-money" />
                  <span>Transactions</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/lease-details`}
                >
                  <i className="icon-lease" />
                  <span>Lease</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/maintenance`}
                >
                  <i className="icon-maintenece" />
                  <span>Maintenance</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/documents`}
                >
                  <i className="icon-documents" />
                  <span>Documents</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sm/property/${match.params.propertyId}/chat`}>
                  <i className="icon-chat" />
                  <span>Chat</span>
                </NavLink>
              </li>
            </ul>
          </div>
        ),
      },
      {
        path: '/sm/property/:propertyId/documents',
        exact: true,
        sidebar: ({ match }) => (
          <div className="navLinks">
            <ul>
              <li>
                <NavLink to="/dashboard">
                  <i className="icon-arrow-left" />
                  <span>Overview</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard">
                  <div
                    className="avatar avatar-xxsmall"
                    style={{ backgroundImage: `url(${houseIcon})` }}
                  />
                  <span>50 Bond St.</span>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/dashboard`}
                >
                  <i className="icon-home-overview" />
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sm/property/${match.params.propertyId}/todos`}>
                  <i className="icon-todos" />
                  <span>To Do's</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/applications`}
                >
                  <i className="icon-applications" />
                  <span>Applications</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/transactions`}
                >
                  <i className="icon-money" />
                  <span>Transactions</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/lease-details`}
                >
                  <i className="icon-lease" />
                  <span>Lease</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/maintenance`}
                >
                  <i className="icon-maintenece" />
                  <span>Maintenance</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/documents`}
                >
                  <i className="icon-documents" />
                  <span>Documents</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sm/property/${match.params.propertyId}/chat`}>
                  <i className="icon-chat" />
                  <span>Chat</span>
                </NavLink>
              </li>
            </ul>
          </div>
        ),
      },
      {
        path: '/sm/property/:propertyId/maintenance',
        exact: true,
        sidebar: ({ match }) => (
          <div className="navLinks">
            <ul>
              <li>
                <NavLink to="/dashboard">
                  <i className="icon-arrow-left" />
                  <span>Overview</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard">
                  <div
                    className="avatar avatar-xxsmall"
                    style={{ backgroundImage: `url(${houseIcon})` }}
                  />
                  <span>50 Bond St.</span>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/dashboard`}
                >
                  <i className="icon-home-overview" />
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sm/property/${match.params.propertyId}/todos`}>
                  <i className="icon-todos" />
                  <span>To Do's</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/applications`}
                >
                  <i className="icon-applications" />
                  <span>Applications</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/transactions`}
                >
                  <i className="icon-money" />
                  <span>Transactions</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/lease-details`}
                >
                  <i className="icon-lease" />
                  <span>Lease</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/maintenance`}
                >
                  <i className="icon-maintenece" />
                  <span>Maintenance</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/documents`}
                >
                  <i className="icon-documents" />
                  <span>Documents</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sm/property/${match.params.propertyId}/chat`}>
                  <i className="icon-chat" />
                  <span>Chat</span>
                </NavLink>
              </li>
            </ul>
          </div>
        ),
      },
      {
        path: '/sm/property/:propertyId/chat',
        exact: true,
        sidebar: ({ match }) => (
          <div className="navLinks">
            <ul>
              <li>
                <NavLink to="/dashboard">
                  <i className="icon-arrow-left" />
                  <span>Overview</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard">
                  <div
                    className="avatar avatar-xxsmall"
                    style={{ backgroundImage: `url(${houseIcon})` }}
                  />
                  <span>50 Bond St.</span>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/dashboard`}
                >
                  <i className="icon-home-overview" />
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sm/property/${match.params.propertyId}/todos`}>
                  <i className="icon-todos" />
                  <span>To Do's</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/applications`}
                >
                  <i className="icon-applications" />
                  <span>Applications</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/transactions`}
                >
                  <i className="icon-money" />
                  <span>Transactions</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/lease-details`}
                >
                  <i className="icon-lease" />
                  <span>Lease</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/maintenance`}
                >
                  <i className="icon-maintenece" />
                  <span>Maintenance</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/sm/property/${match.params.propertyId}/documents`}
                >
                  <i className="icon-documents" />
                  <span>Documents</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sm/property/${match.params.propertyId}/chat`}>
                  <i className="icon-chat" />
                  <span>Chat</span>
                </NavLink>
              </li>
            </ul>
          </div>
        ),
      },
      {
        path: '/teams/:teamSlug/plugin/:pluginSlug',
        exact: true,
        sidebar: ({ match, location }) => (
          <TeamNavbar match={match} location={location} />
        ),
      },
    ]

    // show it when you have applications to your listed properties

    const { teamSlug } = this.props.match.params
    const {
      slug,
      accountType,
      numTeamMembers = 0,
    } = this.props.currentTeam || {}
    const finalTeamSlug = teamSlug || slug

    const primaryTeamSlug =
      this.props.teams &&
      this.props.teams.length > 1 &&
      this.props.teams.find((team) => team.slug) &&
      this.props.teams.find((team) => team.slug).slug

    const logoLink = /bondcover\/quote/.exec(history.location.pathname)
      ? websiteUrl
      : finalTeamSlug || primaryTeamSlug
      ? urlTo('teamOverview', { teamSlug: finalTeamSlug || primaryTeamSlug })
      : urlTo('homeoverview')

    const isRenterProfile = !finalTeamSlug
    const isRRToLite = urlHelpers.isRRToLiteURL()

    return (
      <nav
        className={
          'app-navbar pdf-hide navbar-position-mobile top-navbar-shadow-box ' +
          className
        }
        onBlur={this.onBlur}
      >
        <ActivityFeedContextProvider
          teamGUID={agencyID}
          currentUser={currentUser}
          currentTeam={this.props.currentTeam}
          hasNewActivityFeedConfig={hasNewActivityFeedConfig}
          currentAgency={currentAgency}
        >
          <div className="navbar-header" tabIndex="0">
            <div className="navbar-header-left" tabIndex="0">
              {logoLink && (
                <a href={logoLink}>
                  <SnugLogo className="brand mobile-hide" />
                  <img
                    className="brand desktop-hide height34px"
                    src={snug_logo_mobile}
                    alt="snug-icon"
                  />
                </a>
              )}
              {!logoLink && (
                <div>
                  <SnugLogo className="brand mobile-hide" />
                  <img
                    className="brand desktop-hide height34px"
                    src={snug_logo_mobile}
                    alt="snug-icon"
                  />
                </div>
              )}
            </div>
            {finalTeamSlug && (
              <div className="navbar-header-center-mobile justify-content-space-around">
                <li>
                  <NavLink
                    to={urlTo('teamOverview', { teamSlug: finalTeamSlug })}
                  >
                    <i className="icon-dasshboard font-size-24" />
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={urlTo('teamViewingsMobile', {
                      teamSlug: finalTeamSlug,
                    })}
                  >
                    <i className="icon-calendar font-size-24" />
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={urlTo('contactList', { teamSlug: finalTeamSlug })}
                  >
                    <i className="icon-person-add font-size-24" />
                  </NavLink>
                </li>
              </div>
            )}
            {isRenterProfile && (
              <div className="navbar-header-center-mobile justify-content-space-around">
                <li>
                  <NavLink to={urlTo('homeoverview')}>
                    <i className="icon-dasshboard font-size-24" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to={urlTo('RentalReputation')}>
                    <i className="icon-person font-size-24" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to={urlTo('applications')}>
                    <i className="icon-applications font-size-24" />
                  </NavLink>
                </li>
              </div>
            )}
            <div className="navbar-header-center" tabIndex="0">
              {currentUser.isVerified && (
                <MainMenu
                  currentTeam={this.props.currentTeam}
                  teams={this.props.teams}
                  pathname={this.props.location.pathname}
                  resetPropertyFilters={resetPropertyFilters}
                />
              )}
            </div>

            <div className="navbar-header-right" tabIndex="0">
              {accountType === accountHelpers.ACCOUNT_TYPE_LITE && (
                <LiteAccountButton
                  currentUser={currentUser}
                  numTeamMembers={numTeamMembers}
                  teamSlug={finalTeamSlug}
                />
              )}
              {agencyID && <ActivityFeedIconManager />}
              {currentUser.firstName ? (
                <UserInfo
                  currentUser={currentUser}
                  logout={logout}
                  isAdminRole={isAdminRole}
                  currentTeam={this.props.currentTeam}
                  applicationsCount={applicationsCount}
                  renterNavBarTo={renterNavBarTo}
                  toggleMenu={(event) => this.handleClicks(event)}
                />
              ) : (
                ''
              )}
              {currentUser.avatar ? (
                <div
                  data-menutoggle="true"
                  className="mobile-tablet-only-nav-avatar-icon avatar avatar-xsmall"
                  onClick={(event) => this.handleClicks(event)}
                  style={{ backgroundImage: `url(${currentUser.avatar})` }}
                />
              ) : currentUser.firstName ? (
                <div
                  data-menutoggle="true"
                  className="display-under-768px avatar avatar-xsmall blue-bg"
                  onClick={(event) => this.handleClicks(event)}
                >
                  {currentUser.firstName
                    ? currentUser.firstName.charAt(0).toUpperCase()
                    : ''}
                  {currentUser.lastName
                    ? currentUser.lastName.charAt(0).toUpperCase()
                    : ''}
                </div>
              ) : (
                <div className="mobile-tablet-only">
                  <NavLink
                    exact
                    to={urlTo('join')}
                    onClick={setCurrentURLAsForwardURL}
                  >
                    {' '}
                    <span className="fw600">Login</span>{' '}
                  </NavLink>
                </div>
              )}
            </div>
          </div>

          <div
            className={collapsed ? 'navbar-menu' : 'navbar-menu active'}
            tabIndex="0"
          >
            {currentUser.isVerified ? (
              <div className="navSet">
                {sidebarRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={route.sidebar}
                  />
                ))}
                <MainMenu
                  currentTeam={this.props.currentTeam}
                  teams={this.props.teams}
                  pathname={this.props.location.pathname}
                  logout={logout}
                  resetPropertyFilters={resetPropertyFilters}
                />
              </div>
            ) : (
              <>
                {isRRToLite ? (
                  <RRNavbar />
                ) : (
                  <div className="navSet">{sessionLinks()}</div>
                )}
              </>
            )}
          </div>

          <ActivitySideBar />
        </ActivityFeedContextProvider>
      </nav>
    )
  }
}

export default Navbar
