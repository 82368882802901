import {
  Dispatch,
  SetStateAction,
  useEffect,
  useReducer,
  useState,
} from 'react'

import { connect } from 'react-redux'

import {
  Alert,
  Box,
  Flex,
  LoadingSection,
} from 'app/components/design-system-components'
import {
  automationsTracingFeatureReleaseDate,
  outOfTraceableRangeAlert,
} from 'app/constants/automations.constants'
import ActivityCubeListItem from 'app/dashboard/team_activity/activity_cube_list_item/component'
import * as actFilters from 'app/dashboard/team_activity/ActivityFilters'
import { activityPeriods } from 'app/dashboard/team_activity/ActivityFilters/DateRangeFilter/constants'
import { getAutomationsStats } from 'app/services/http/teams/automations-report'
import { isBefore } from 'app/utils/datetime/helpers'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import {
  filterReducerFactory,
  filtersActions,
} from 'app/utils/reducers/filtersReducer'

type FiltersProps = {
  filtersState: any
  filtersDispatch: any
  currentTeam: any
  periodSetter: Dispatch<SetStateAction<any>>
  onFiltersInitialized: () => void
}

const Filters = ({
  filtersState,
  filtersDispatch,
  currentTeam,
  periodSetter,
  onFiltersInitialized,
}: FiltersProps) => (
  <Box ml={2}>
    <actFilters.ActivitiesFilters
      teamId={currentTeam.guid}
      includeManagersFilter={false}
      managers={[]}
      onFiltersInitialized={onFiltersInitialized}
      initialPeriod={activityPeriods.currentMonth}
      filters={filtersState.filters}
      updateFilter={(filterId: string, filterValues: any) =>
        filtersDispatch(filtersActions.updateFilter(filterId, filterValues))
      }
      onPeriodChange={periodSetter}
    />
  </Box>
)

const AutomationsReporting = ({ currentTeam }: any) => {
  const {
    state: automationsStats,
    setState: setAutomationsStats,
    loadingStates: automationsStatsLoadingStates,
    loadingStatesHelpers: automationsStatsLoadingHelpers,
  } = useStateWithLoading(null)

  const [filtersState, filtersDispatch] = useReducer(
    filterReducerFactory(actFilters.filtersInitialState),
    actFilters.filtersInitialState,
  )

  const [filtersInitializationReady, setFiltersInitializationReady] =
    useState(false)

  const [, setFilterSelectedPeriod] = useState()

  useEffect(() => {
    if (filtersInitializationReady) {
      loadAutomationsStats()
    }
  }, [filtersState])

  const loadAutomationsStats = () => {
    automationsStatsLoadingHelpers.startLoading()
    const { range } = actFilters.getFilters(filtersState)

    getAutomationsStats(currentTeam.guid, {
      ...range,
    })
      .then((stats: any) => {
        automationsStatsLoadingHelpers.markDoneSuccessfully()
        setAutomationsStats(stats)
      })
      .catch((error: any) => {
        automationsStatsLoadingHelpers.setError(error)
      })
  }

  const renderOutOfTraceableRangeAlert = () => {
    if (!filtersInitializationReady) return

    const { range } = actFilters.getFilters(filtersState)
    const showOutOfTraceableRangeAlert =
      !range.startDate ||
      isBefore(automationsTracingFeatureReleaseDate, range.startDate)

    return (
      showOutOfTraceableRangeAlert && (
        <Alert variant="blueWithBg" showLeftIcon leftIconVariant="info">
          {outOfTraceableRangeAlert}
        </Alert>
      )
    )
  }

  return (
    <>
      <Filters
        currentTeam={currentTeam}
        filtersState={filtersState}
        periodSetter={setFilterSelectedPeriod}
        filtersDispatch={filtersDispatch}
        onFiltersInitialized={() => setFiltersInitializationReady(true)}
      />
      {renderOutOfTraceableRangeAlert()}
      <LoadingSection loadingState={automationsStatsLoadingStates}>
        {!!automationsStats && (
          <Flex mt={6} flexWrap="wrap">
            <ActivityCubeListItem
              header={'Emails'}
              value={automationsStats.totalEmails}
            />
            <ActivityCubeListItem
              header={'SMS'}
              value={automationsStats.totalSMS}
            />
            <ActivityCubeListItem
              header={'Autoresponses'}
              value={automationsStats.enquiryAutoResponses}
            />
            <ActivityCubeListItem
              header={'Property Match'}
              value={automationsStats.propertyMatchEmails}
            />
            <ActivityCubeListItem
              header={'Price Drop'}
              value={automationsStats.priceDropEmails}
            />
            <ActivityCubeListItem
              header={'New Viewings'}
              value={automationsStats.newViewingEmails}
            />
            <ActivityCubeListItem
              header={'Entry Notice'}
              value={automationsStats.entryNoticeEmails}
            />
            <ActivityCubeListItem
              header={'Viewing Report'}
              value={automationsStats.viewingReportEmails}
            />
          </Flex>
        )}
      </LoadingSection>
    </>
  )
}

const mapStateToProps = ({ session }: any) => ({
  currentTeam: session.currentTeam,
})

export default connect(mapStateToProps)(AutomationsReporting)
