/* eslint-disable no-unused-vars */

import React from 'react'

import $ from 'jquery'
import moment from 'moment'
import qs from 'qs'
import { Link } from 'react-router-dom'

import greenTick from 'app/assets/icons/green-tick.png'
import headerImg01 from 'app/assets/icons/header-img-01.png'
import DefautButton from 'app/components/buttons/default/component'
import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import { validateEmailFlattened } from 'app/components/forms/input_email/input_email'
import { validateNameFlattened } from 'app/components/forms/input_name/input_name'
import * as errors from 'app/constants/error_codes'
import { ErrorInvalidRegistrantData } from 'app/constants/error_codes'
import {
  getEnquiryMessage,
  registerForEnquirySMSViewing,
  registerForEnquiryViewing,
} from 'app/services/http/renter/enquiry-messages'
import { ErrorMessage } from 'app/shared_components/helpers'
import getParameter from 'app/shared_components/parameter_parser'
import * as Helper from 'app/sm/helpers'

const nameValidatorFactory = (fieldName) => {
  return (value) => {
    if (value?.length < 2) return `please enter a proper ${fieldName} value`

    const basicError = validateNameFlattened(value)
    if (!!basicError) return basicError

    return ''
  }
}

const firstNameValidator = nameValidatorFactory('first name')
const lastNameValidator = nameValidatorFactory('last name')
const mobileValidator = (value) => (!value ? 'Mobile number is required' : '')

class RegisterViewingConfimation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      propertyInfo: undefined,
      messageInfo: null,
      request: {
        firstName: '',
        registrantGuidID: '',
        propertyAddress: '',
        viewingDate: '',
        viewingDuration: '',
      },
      fields: {
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: '',
      },
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: '',
      },
      apiError: '',
      error: '',
      loading: false,
      updated: false,
      registered: false,
      isUnnregister: false,
      showUpdateForm: false,
      showRegisterForm: false,
    }
  }

  componentDidMount() {
    $('#index').addClass('no-navbar-menu')
    const {
      match: {
        params: { viewingID, messageID, sms },
      },
    } = this.props
    if (viewingID && messageID) {
      this.initRegisterForMessageViewing().catch((err) => {
        if (err?.message) {
          this.setState({ apiError: err.message })
        }
      })
    } else {
      this.initRegisterForViewing().catch((err) => {
        if (err?.message) {
          this.setState({ apiError: err.message })
        }
      })
    }
  }

  componentWillUnmount() {
    $('#index').removeClass('no-navbar-menu')
  }

  initRegisterForMessageViewing() {
    return this.fetchBasicInfo().then(({ messageInfo, propertyInfo }) => {
      const { firstName, lastName, phone, email } = messageInfo
      const isRequiredRegistrantDataMissing =
        this.isRequiredRegistrantDataMissing(messageInfo)
      this.setState(
        {
          messageInfo,
          propertyInfo,
          fields: {
            firstName,
            lastName,
            mobilePhone: phone,
            email,
          },
        },
        () => {
          isRequiredRegistrantDataMissing
            ? this.setState({ showRegisterForm: true })
            : this.autoRegistering()
        },
      )
    })
  }

  initRegisterForViewing() {
    const {
      match: {
        params: { viewingID },
      },
      fetchPropertyInfoByViewingId,
    } = this.props

    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    return fetchPropertyInfoByViewingId(viewingID)
      .then(({ data: propertyInfo }) => {
        const { firstName, lastName, email, mobile } = queryParams
        const isRequiredRegistrantDataMissing =
          this.isRequiredRegistrantDataMissing({
            ...queryParams,
            phone: mobile,
          })
        this.setState(
          {
            propertyInfo,
            fields: {
              firstName,
              lastName,
              mobilePhone: mobile,
              email,
            },
          },
          () => {
            isRequiredRegistrantDataMissing
              ? this.setState({ showRegisterForm: true })
              : this.autoRegistering()
          },
        )
      })
      .catch((err) => {
        return Promise.reject({ message: err })
      })
  }

  autoRegistering() {
    const payload = this.extractFormPayload()
    this.registerViewingRegistrant(payload)
      .then(() => {
        this.setState({ registered: true })
      })
      .catch((apiError) => {
        const app_code = apiError?.plainError?.response?.data?.app_code
        if (!!app_code && app_code === ErrorInvalidRegistrantData) {
          this.setState({ showRegisterForm: true })
        }
      })
  }

  isRequiredRegistrantDataMissing(registrantInfo) {
    const { firstName, lastName, phone, email } = registrantInfo
    return [firstName, lastName, phone, email].some((value) => !value)
  }

  fetchBasicInfo() {
    const {
      match: {
        params: { viewingID, messageID },
      },
      fetchPropertyInfoByViewingId,
    } = this.props

    return Promise.all([
      getEnquiryMessage(messageID),
      fetchPropertyInfoByViewingId(viewingID).catch((err) => {
        return Promise.reject({ message: err })
      }),
    ])
      .then(([messageInfo, { data: propertyInfo }]) => ({
        messageInfo,
        propertyInfo,
      }))
      .catch((err) => {
        this.setState({ apiError: err?.message })
        return Promise.reject(err)
      })
  }

  toggleUpdateForm = () => {
    this.setState({ showUpdateForm: !this.state.showUpdateForm })
  }

  unregisterViewing = (registrantGuidID) => {
    const { unregisterForEnquiryViewing } = this.props
    unregisterForEnquiryViewing(registrantGuidID)
      .then(this.setState({ isUnnregister: true, showUpdateForm: false }))
      .catch((apiError) => this.setState({ apiError }))
  }

  update = (field) => {
    return (data) => {
      this.setState({
        fields: {
          ...this.state.fields,
          [field]: data.value,
        },
        errors: {
          ...this.state.errors,
          [field]: data.error,
        },
      })
    }
  }

  extractFormPayload() {
    return {
      firstName: this.state.fields.firstName,
      lastName: this.state.fields.lastName,
      email: this.state.fields.email,
      mobilePhone: this.state.fields.mobilePhone,
    }
  }

  updateViewingRegistrant = (registrantGuidID) => {
    const { updateEquiryViewingRegistrant } = this.props
    const data = this.extractFormPayload()
    this.setState({ loading: true })

    updateEquiryViewingRegistrant(data, registrantGuidID)
      .then(this.setState({ loading: false, updated: true }))
      .catch((apiError) => {
        this.setState({
          loading: false,
          apiError,
        })
      })
  }

  registerViewingRegistrant(registrantData) {
    const {
      match: {
        params: { viewingID, messageID, sms },
      },
      registerForEmailViewing,
    } = this.props

    const registeringPromise = (() => {
      if (!messageID) {
        return registerForEmailViewing(viewingID, registrantData).catch(
          (err) => {
            return Promise.reject({ message: err })
          },
        )
      }
      const calledApi = sms
        ? registerForEnquirySMSViewing
        : registerForEnquiryViewing
      return calledApi(viewingID, messageID, registrantData)
    })()

    return registeringPromise
      .then(({ data }) => {
        this.setState({
          request: {
            firstName: data.firstName,
            registrantGuidID: data.guidID,
            propertyAddress: data.propertyFriendlyName,
            viewingDate: data.viewingDate,
            viewingDuration: data.viewingDuration,
          },
          fields: {
            firstName: data.firstName,
            lastName: data.lastName,
            mobilePhone: data.mobilePhone,
            email: data.email,
          },
        })
      })
      .then(() => this.setState({ apiError: null, showRegisterForm: false }))
      .catch((apiError) => {
        this.setState({ apiError: apiError.message })
      })
  }

  validateForm() {
    const {
      fields: {
        firstName: firstNameField,
        lastName: lastNameField,
        email: emailField,
        mobilePhone: mobilePhoneField,
      },
    } = this.state

    const calculatedErrors = {
      firstName: firstNameValidator(firstNameField),
      lastName: lastNameValidator(lastNameField),
      email: validateEmailFlattened(emailField),
      mobilePhone: mobileValidator(mobilePhoneField),
    }

    const result = {
      errors: calculatedErrors,
    }

    this.setState(result)

    return result
  }

  renderForm({ onSave, saveBtnText, saved, successMessage }) {
    const {
      fields: {
        firstName: firstNameField,
        lastName: lastNameField,
        email: emailField,
        mobilePhone: mobilePhoneField,
      },
      errors: {
        firstName: firstNameError,
        lastName: lastNameError,
        email: emailError,
        mobilePhone: mobilePhoneError,
      },
      updated = false,
    } = this.state

    const isFormInvalid = (errors) => Object.values(errors).some(Boolean)

    const formInvalid = isFormInvalid(this.state.errors)

    return (
      <div className="width100 display-flex flex-direction-column">
        <Form.InputName
          label="First name"
          value={firstNameField}
          error={firstNameError}
          validate={firstNameValidator}
          onChange={this.update('firstName')}
          id="firstName"
          componentClass="col-md-5 col-xs-9 mr-auto ml-auto"
          inputClass="width100"
        />
        <Form.InputName
          label="Last name"
          value={lastNameField}
          error={lastNameError}
          validate={lastNameValidator}
          onChange={this.update('lastName')}
          id="lastName"
          componentClass="col-md-5 col-xs-9 mr-auto ml-auto mt10"
          inputClass="width100"
        />
        <Form.InputEmail
          label="Email"
          value={emailField}
          error={emailError}
          onChange={this.update('email')}
          id="email"
          componentClass="col-md-5 col-xs-9 mr-auto ml-auto mt10"
          inputClass="width100"
        />
        <Form.InputPhoneNumber
          label="Mobile"
          value={mobilePhoneField}
          error={mobilePhoneError}
          validate={mobileValidator}
          onChange={this.update('mobilePhone')}
          id="mobilePhone"
          componentClass="col-md-5 col-xs-9 mr-auto ml-auto mt10"
          containerClassName="width100"
        />
        <DefautButton
          text={saved ? 'Done' : saveBtnText}
          onClick={() => {
            const { errors } = this.validateForm()
            const formInvalid = isFormInvalid(errors)
            !formInvalid && onSave()
          }}
          disabled={this.state.loading || saved || formInvalid}
          isLoading={this.state.loading}
          size="jumbo"
          componentClass="col-md-5 col-xs-9 mr-auto ml-auto mt10"
        >
          {saved && (
            <img className="ml5 mr5 pb2" src={greenTick} alt="green tick" />
          )}
        </DefautButton>
        <Display.DefaultBodyText
          text={saved ? successMessage : ''}
          componentClass="text-align-center"
        />
      </div>
    )
  }

  renderRegistrantForm() {
    const {
      request: { registrantGuidID },
      updated = false,
      registered = false,
      isUnnregister = false,
      showUpdateForm = false,
      showRegisterForm = false,
    } = this.state

    if (!showUpdateForm && !showRegisterForm) return null

    if (showUpdateForm)
      return this.renderForm({
        onSave: () => this.updateViewingRegistrant(registrantGuidID),
        saveBtnText: 'Update',
        saved: updated,
        successMessage: 'Your details have been updated.',
      })

    if (showRegisterForm) {
      return this.renderForm({
        onSave: () => {
          const payload = this.extractFormPayload()
          this.registerViewingRegistrant(payload)
        },
        saveBtnText: 'Register',
        saved: registered,
        successMessage: "You've been registered successfully to the viewing",
      })
    }
  }

  render() {
    const {
      request: {
        firstName,
        registrantGuidID,
        propertyAddress,
        viewingDate,
        viewingDuration,
      },
      registered = false,
      showRegisterForm = false,
      isUnnregister = false,
      propertyInfo = {},
      apiError = '',
    } = this.state
    let formattedDate = ''
    if (viewingDate !== '') {
      const startDate = moment(viewingDate)
        .local()
        .format('YYYY-MM-DD HH:mm:ss')
      formattedDate = moment(startDate).format('hh:mm a ddd DD MMM')
    }
    // need to change back to the correct error code
    const isFullyBookedErrorReceived =
      apiError && apiError.includes(errors.ErrorViewingIsFullyBooked)
    const { guidID: propertyId = '' } = propertyInfo

    const registrantForm = this.renderRegistrantForm()

    const registeringUsingForm = showRegisterForm && !registered

    return (
      <Display.CenterContentContainer componentClass="width100 display-flex flex-direction-column">
        <div className="ilustration-box">
          <img src={headerImg01} alt="Happy Cactus" />
        </div>
        {!(this.state.apiError || this.state.error) && (
          <div>
            {!registeringUsingForm && (
              <Display.DefaultBodyText componentClass="text-align-center width80 ml-auto mr-auto">
                <p>
                  {!isUnnregister
                    ? `Hi ${firstName}, you're registered to view`
                    : `You've cancelled your registration to view`}
                  <b> {propertyAddress}</b>
                </p>
              </Display.DefaultBodyText>
            )}

            {registeringUsingForm && (
              <Display.DefaultBodyText componentClass="text-align-center width80 ml-auto mr-auto">
                <p>
                  Please complete your details below to register to view
                  <b> {propertyAddress}</b>
                </p>
              </Display.DefaultBodyText>
            )}

            <Display.SectionHeader
              text={
                formattedDate && viewingDuration
                  ? `${formattedDate} (${viewingDuration} mins)`
                  : ''
              }
              componentClass="text-align-center"
              hasSeperator={false}
            />
            {!isUnnregister && !registeringUsingForm && (
              <Display.DefaultBodyText componentClass="text-align-center width80 ml-auto mr-auto">
                <p>
                  <span
                    className="blue-link-style undo-snooze"
                    onClick={() => this.toggleUpdateForm()}
                    onKeyPress={() => this.unregisterViewing(registrantGuidID)}
                    target=""
                    role="link"
                    tabIndex="0"
                  >
                    Update your details
                  </span>{' '}
                  or{' '}
                  <span
                    className="blue-link-style undo-snooze"
                    onClick={() => this.unregisterViewing(registrantGuidID)}
                    onKeyPress={() => this.unregisterViewing(registrantGuidID)}
                    target=""
                    role="link"
                    tabIndex="0"
                    id="teamSlug here"
                  >
                    cancel registration
                  </span>
                </p>
              </Display.DefaultBodyText>
            )}
          </div>
        )}
        {registrantForm}
        {!isFullyBookedErrorReceived && (
          <ErrorMessage error={this.state.apiError || this.state.error} />
        )}
        {isFullyBookedErrorReceived && (
          <Display.FlexContainer containerClass="align-items-center flex-direction-column">
            <Display.DefaultBodyText text="Apologies, this viewing is now fully booked" />
            <Link to={Helper.urlTo('propertyDetails', { propertyId })}>
              See other viewing times
            </Link>
          </Display.FlexContainer>
        )}
      </Display.CenterContentContainer>
    )
  }
}

export default RegisterViewingConfimation
