import { CheckboxInput } from 'app/components/design-system-components/inputs/Checkboxes/Checkbox'

type Props = {
  isSelected: boolean
  toggleSelection: () => void
}

export const SelectKeySetHeader = ({ isSelected, toggleSelection }: Props) => {
  return <CheckboxInput value={isSelected} changeHandler={toggleSelection} />
}
