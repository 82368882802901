import { Box } from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { keysManagingLearnMore } from 'config/external-links'

type Props = {
  closeModal: () => void
}
export const DisallowedBorrowerDeletion = ({ closeModal }: Props) => {
  return (
    <GenericModal
      onDismiss={() => closeModal()}
      title="Check in all keys"
      modalFooter={
        <GenericModalFooter
          secondaryBtnConfig={{
            title: 'Close',
            onClick: () => closeModal(),
          }}
        />
      }
    >
      <Box mb={2} lineHeight={theme.lineHeights.encrption}>
        Please check in all keys for this borrower before deleting. Tip: use the
        borrower filter on the keys list.{' '}
        <a target="_blank" href={keysManagingLearnMore} rel="noreferrer">
          learn more
        </a>
      </Box>
    </GenericModal>
  )
}
