import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as helpers from 'app/sm/helpers'
import { formatRent } from 'app/sm/helpers'
import { getPropertyAvailabilityDate } from 'app/utils/datetime/helpers'

const FullBoxWidthText = styled(Box)`
  text-align: justify;
  /* a height for one line */
  height: 1rem;

  :after {
    /* used to stretch word */
    content: '';
    width: 100%;
    display: inline-block;
  }
`

const PropItem = (props) => {
  const { data = {} } = props
  const {
    address = {},
    bedrooms = '',
    bathrooms = 0,
    garages = 0,
    carports = 0,
    parking = 0,
    description = '',
    livingAreaDesc = '',
    nextViewings = [],
    contactAgent = {},
    activeOffer,
  } = data

  const { weeklyRentDisplay, availableFrom = '' } = activeOffer || {}
  const { friendlyName = '', suburb = '' } = address
  const formattedAddress = `${friendlyName}, ${suburb} `
  const image = helpers.getMainImage(data)
  const formatAvailFrom = getPropertyAvailabilityDate(availableFrom, null)

  const renderNextViewings = () => (
    <>
      {!nextViewings.length && (
        <span className="text-black">Not available</span>
      )}
      {nextViewings.map(({ startDate, duration }) => (
        //FullBoxWidthText: a hack to make vertical viewings aligned
        <FullBoxWidthText className="text-black">
          {moment(startDate).local().format(helpers.viewingNoYearTimeFormat)}{' '}
          {`(${duration}m)`}
        </FullBoxWidthText>
      ))}
    </>
  )

  const {
    firstName = '',
    middleName = '',
    lastName = '',
    email = '',
  } = contactAgent || {}
  const formattedAgentName = `${firstName} ${middleName} ${lastName}`
  const propertyFeatures = [
    { icon: helpers.iconCollection.bedroom, value: bedrooms },
    { icon: helpers.iconCollection.bathrooms, value: bathrooms },
    {
      icon: helpers.iconCollection.cars,
      value: garages || carports || parking,
    },
  ]
  const propertyDescription = description || livingAreaDesc
  const trimmedDscription = helpers.trimFileLength(180)(propertyDescription)
  const descriptionText = helpers.stripHTMLTags(trimmedDscription)
  return (
    <article className="prop-item d-flex">
      <div className="img-wrap">
        <img src={image} alt="item" />
      </div>
      <div className="descr-wrap">
        <div className="d-flex justify-between">
          <div className="item-heading">
            <span className="item-price text-dark-grey">
              {formatRent(weeklyRentDisplay)}
            </span>
            <span className="text-grey-alt"> Avail:</span>
            <span className="item-price text-dark-grey">
              {' '}
              {formatAvailFrom}
            </span>
            <strong className="item-address text-dark-grey">
              {formattedAddress}
            </strong>
          </div>
          <div>
            <ul className="char-list">
              {propertyFeatures.map((feature, index) => {
                return (
                  <li key={index}>
                    <span className={feature.icon}></span>
                    {feature.value}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <p className="fz-9">{descriptionText}</p>
        <Flex>
          <Flex className="meta-data" mr={`${theme.space[1]}px`}>
            <Box mr={`${theme.space[1]}px`}>Next viewings:</Box>
            <Box mr={`${theme.space[1]}px`}>{renderNextViewings()}</Box>
          </Flex>
          {contactAgent && (
            <Box className="meta-data">
              <Box mr={`${theme.space[1]}px`}>Contact:</Box>
              <a
                className="item-bottom-link text-black"
                href={'mailto:' + props.data.contact_url}
              >
                {' '}
                {formattedAgentName} ({email})
              </a>
            </Box>
          )}
        </Flex>
      </div>
    </article>
  )
}

export default PropItem
