import React from 'react'

import PropTypes from 'prop-types'

import DocumentListItem from 'app/shared_components/document_list/component/document_list_item'
import Spinner from 'app/sm/common/spinner'

const DocumentList = ({
  spinner,
  documents,
  addDocument,
  deleteDocument,
  documentType,
  responseText,
  fileType,
  fileTypeError,
  setDocumentsChanged,
  showDocumentType,
  documentTypeTextConverter,
  showUploader,
  availableFileTypesText,
  allowDeletion,
  disableMultiple,
  disabled,
  toggleDocumentsViewer,
}) => (
  <div className="sm-property-photos">
    <DocumentListItem
      documents={documents}
      addDocument={addDocument}
      deleteDocument={deleteDocument}
      documentType={documentType}
      responseText={responseText}
      fileType={fileType}
      fileTypeError={fileTypeError}
      setDocumentsChanged={setDocumentsChanged}
      documentTypeTextConverter={documentTypeTextConverter}
      showDocumentType={showDocumentType}
      showUploader={showUploader}
      availableFileTypesText={availableFileTypesText}
      allowDeletion={allowDeletion}
      disableMultiple={disableMultiple}
      disabled={disabled}
      toggleDocumentsViewer={toggleDocumentsViewer}
    />
    {spinner && <Spinner />}
  </div>
)

DocumentList.propTypes = {
  spinner: PropTypes.bool,
  documents: PropTypes.array.isRequired,
  addDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
  documentType: PropTypes.number,
  responseText: PropTypes.string,
  fileType: PropTypes.string,
  fileTypeError: PropTypes.string,
  setDocumentsChanged: PropTypes.func,
  showDocumentType: PropTypes.bool,
  documentTypeTextConverter: PropTypes.string,
  showUploader: PropTypes.bool,
  availableFileTypesText: PropTypes.string,
  allowDeletion: PropTypes.bool,
  disableMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  showAutoDeleteWarning: PropTypes.bool,
}

DocumentList.defaultProps = {
  error: '',
  pdf: '',
}

export default DocumentList
