import React, { useRef } from 'react'

import {
  Button,
  ButtonWithIcon,
  Flex,
} from 'app/components/design-system-components'
import { AttachFileRounded } from 'app/components/design-system-components/icons/editor'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const ModalFooter = ({
  sendDisabled,
  selectAttachments,
  showPreview,
  onPreviewClicked,
  ...props
}) => {
  const fileInput = useRef()
  const handleAttachButton = () => {
    fileInput.current.click()
  }

  const handleSelectFiles = (e) => {
    e.preventDefault()
    e.stopPropagation()

    selectAttachments(e.target.files)
    fileInput.current.value = ''
  }

  return (
    <Flex alignItems="center" {...props}>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInput}
        onChange={handleSelectFiles}
        multiple
      />
      <ButtonWithIcon
        type="button"
        variant="solidSecondary"
        flex="1 1 50%"
        onClick={handleAttachButton}
        leading={false}
        IconCmp={AttachFileRounded}
        mr={theme.space[3] + 'px'}
      >
        Attach file
      </ButtonWithIcon>
      <Button
        type="button"
        variant="solidSecondary"
        flex="1 1 30%"
        onClick={onPreviewClicked}
        mr={theme.space[3] + 'px'}
      >
        {!showPreview ? 'Preview' : 'Edit'}
      </Button>
      <Button disabled={sendDisabled} type="submit" flex="1 1 70%">
        Send email
      </Button>
    </Flex>
  )
}
