import { Fragment } from 'react'

import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import Flex from 'app/components/design-system-components/Flex'
import * as themeGetters from 'app/components/design-system-components/theme/getters'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const sharedBreadcrumbStyles = css`
  color: ${themeGetters.color('gray600')};
  font-size: ${theme.fontSizes.pRegular14};

  :hover,
  :active,
  :focus,
  :visited {
    color: ${themeGetters.color('gray600')};
    text-decoration: none;
  }

  &.active {
    font-weight: ${themeGetters.fontWeight(6)};
    :hover {
      cursor: default;
    }
  }
`

export const StyledBreadcrumbLink = styled(Link)`
  ${sharedBreadcrumbStyles}
`

export const NoLinkBreadcrumb = styled(Box)`
  ${sharedBreadcrumbStyles}
`

export const BreadCrumbsWrapper = styled(Flex)`
  align-items: center;
  color: ${themeGetters.color('gray600')};
`

const BasicBreadcrumb = ({ link, content, isCurrent }: BreadCrumbConfig) => {
  if (isCurrent) {
    return <NoLinkBreadcrumb className="active">{content}</NoLinkBreadcrumb>
  }

  return (
    <StyledBreadcrumbLink to={{ pathname: link }}>
      {content}
    </StyledBreadcrumbLink>
  )
}

export type BreadCrumbConfig = {
  link?: string
  content: string
  isCurrent?: boolean
  renderer?: any
}

type breadCrumbWrapper = {
  breadcrumbsConfigs: BreadCrumbConfig[]
}

export const Breadcrumbs = ({ breadcrumbsConfigs }: breadCrumbWrapper) => {
  const breadcrumbsElements = breadcrumbsConfigs.map(
    (config: BreadCrumbConfig, index: number) => {
      const renderer = config.renderer || BasicBreadcrumb
      const isCurrent =
        config.isCurrent || index === breadcrumbsConfigs.length - 1
      return renderer({ ...config, isCurrent })
    },
  )

  const separator = <span> / </span>

  return (
    <BreadCrumbsWrapper>
      {breadcrumbsElements.map((breadcrumbElem: Element, index: number) => {
        const isLastBreadcrumb = index === breadcrumbsElements.length - 1
        return (
          <Fragment key={'breadcrumb-' + index}>
            {breadcrumbElem}
            {!isLastBreadcrumb && (
              <Box m={`0 ${theme.space[2]}px`}>{separator}</Box>
            )}
          </Fragment>
        )
      })}
    </BreadCrumbsWrapper>
  )
}
