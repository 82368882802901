import React from 'react'

class ViewingSorterDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sorters: [
        { id: 'time', label: 'Sort by Time' },
        { id: 'address', label: 'Sort by Address' },
        { id: 'rent', label: 'Sort by Rent' },
        { id: 'snugfit', label: 'Sort by Snug Match' },
      ],
    }
  }

  render() {
    return (
      <div className="input-box single wa pt0 ml20 xs-ml0">
        <select
          className="wa lh12 xs-fs16"
          value={this.props.sortBy}
          onChange={(e) => this.props.onChange(e.target.value)}
        >
          {this.state.sorters.map((s) => (
            <option key={s.id} value={s.id}>
              {s.label}
            </option>
          ))}
        </select>
      </div>
    )
  }
}

export default ViewingSorterDropdown
