import React, { useEffect, useReducer, useState } from 'react'

import moment from 'moment'
import { connect } from 'react-redux'

import AdvancedActivityTable from 'app/agency/advance_activity/activity_table'
import { Box, LoadingSection } from 'app/components/design-system-components'
import * as actFilters from 'app/dashboard/team_activity/ActivityFilters'
import { activityPeriods } from 'app/dashboard/team_activity/ActivityFilters/DateRangeFilter/constants'
import {
  fetchAdvanceTeamActivities,
  fetchPropertiesActivitiesReport,
} from 'app/dashboard/team_activity/team_activity_action'
import { getTeamManagers } from 'app/services/http/teams/managers'
import { DATE_WITH_DASH } from 'app/utils/datetime/helpers'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { loadingStatesIds } from 'app/utils/loading-states'
import {
  filterReducerFactory,
  filtersActions,
} from 'app/utils/reducers/filtersReducer'

const Filters = ({
  filtersState,
  filtersDispatch,
  currentTeam,
  managers,
  periodSetter,
}) => (
  <Box ml={2}>
    <actFilters.ActivitiesFilters
      teamId={currentTeam.guid}
      initialPeriod={activityPeriods.currentMonth}
      managers={managers}
      includeManagersFilter
      filters={filtersState.filters}
      updateFilter={(filterId, filterValues) =>
        filtersDispatch(filtersActions.updateFilter(filterId, filterValues))
      }
      onPeriodChange={periodSetter}
    />
  </Box>
)

export const AdvancedActivityReporting = ({
  currentTeam,
  fetchAdvanceTeamActivities,
  fetchPropertiesActivitiesReport,
}) => {
  const {
    state: filtersLoadedData,
    setState: setFiltersLoadedData,
    loadingStates: filtersLoadingStates,
    loadingStatesHelpers: setFiltersLoadingStatesHelpers,
  } = useStateWithLoading({})

  const {
    state: advancedActData,
    setState: setAdvancedActData,
    loadingStates: advancedActLoadingStates,
    loadingStatesHelpers: setAdvancedActLoadingStatesHelpers,
  } = useStateWithLoading([])

  const {
    state: propertiesActData,
    setState: setPropertiesActData,
    loadingStates: propertiesActLoadingStates,
    loadingStatesHelpers: setPropertiesActLoadingStatesHelpers,
  } = useStateWithLoading([])

  const [filtersState, filtersDispatch] = useReducer(
    filterReducerFactory(actFilters.filtersInitialState),
    actFilters.filtersInitialState,
  )

  const [filterSelectedPeriod, setFilterSelectedPeriod] = useState()

  useEffect(() => {
    if (currentTeam && currentTeam.guid) {
      loadFiltersBasicData()
    }
  }, [currentTeam])

  useEffect(() => {
    if (filtersLoadingStates.state !== loadingStatesIds.LOADED) return
    loadActivities()
  }, [filtersState])

  const loadFiltersBasicData = () => {
    setFiltersLoadingStatesHelpers.startLoading()
    getTeamManagers(currentTeam.guid)
      .then((managersRes) => {
        setFiltersLoadedData({ managers: managersRes.managerList })
        setFiltersLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => setFiltersLoadingStatesHelpers.setError(err))
  }

  const loadActivities = () => {
    setAdvancedActLoadingStatesHelpers.startLoading()
    const {
      range,
      managers = [],
      dateFilterBy,
    } = actFilters.getFilters(filtersState)

    const formattedRange = {
      ...(range?.startDate && {
        startDate: moment(range.startDate).format(DATE_WITH_DASH),
      }),
      ...(range?.endDate && {
        endDate: moment(range.endDate).format(DATE_WITH_DASH),
      }),
    }

    fetchAdvanceTeamActivities(currentTeam.guid, {
      ...formattedRange,
      manager: managers.map((manager) => manager.guidID),
      dateFilterBy,
    })
      .then(({ advanceActivities }) => {
        setAdvancedActLoadingStatesHelpers.markDoneSuccessfully()
        setAdvancedActData(advanceActivities)
      })
      .catch((error) => {
        setAdvancedActLoadingStatesHelpers.setError(error)
      })
    setPropertiesActLoadingStatesHelpers.startLoading()

    fetchPropertiesActivitiesReport(currentTeam.guid, {
      startTime: formattedRange.startDate,
      endTime: formattedRange.endDate,
      dateFilterBy,
      manager: managers.map((manager) => manager.guidID),
    })
      .then((propertiesActivities) => {
        setPropertiesActLoadingStatesHelpers.markDoneSuccessfully()
        setPropertiesActData(propertiesActivities)
      })
      .catch((error) => {
        setPropertiesActLoadingStatesHelpers.setError(error)
      })
  }

  let rangeLabel
  const { range = {} } = actFilters.getFilters(filtersState)
  const formattedRange = {
    ...(range.startDate && {
      startDate: moment(range.startDate).format(DATE_WITH_DASH),
    }),
    ...(range.endDate && {
      endDate: moment(range.endDate).format(DATE_WITH_DASH),
    }),
  }
  if (filterSelectedPeriod?.optionMeta?.id !== activityPeriods.custom) {
    rangeLabel = filterSelectedPeriod?.optionMeta?.displayText
  } else {
    const startDateLabel = range.startDate
      ? moment(range.startDate).format('DD MMM YY')
      : ''
    const endDateLabel = range.endDate
      ? moment(range.endDate).format('DD MMM YY')
      : ''
    const prefix = !!startDateLabel && !endDateLabel ? 'from ' : ''
    rangeLabel = prefix + startDateLabel + ' to ' + endDateLabel
  }

  return (
    <>
      {advancedActData && (
        <>
          <LoadingSection loadingState={filtersLoadingStates}>
            <Filters
              currentTeam={currentTeam}
              filtersState={filtersState}
              managers={filtersLoadedData?.managers}
              periodSetter={setFilterSelectedPeriod}
              filtersDispatch={filtersDispatch}
            />
          </LoadingSection>
          <AdvancedActivityTable
            advanceActivities={advancedActData}
            propertiesActivitiesReport={propertiesActData}
            propertiesReportLoadingStates={propertiesActLoadingStates}
            customColumnSelector={`${formattedRange.startDate || ''} - ${
              formattedRange.endDate || ''
            }`}
            customColumnLabel={rangeLabel}
            error={propertiesActLoadingStates.error}
            filters={filtersState.filters}
            onFiltersUpdated={(filterId, filterValues) =>
              filtersDispatch(
                filtersActions.updateFilter(filterId, filterValues),
              )
            }
          />
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
})

const mapDispatchToProps = (dispatch) => ({
  fetchAdvanceTeamActivities: (teamGUID, filters) =>
    dispatch(fetchAdvanceTeamActivities(teamGUID, filters)),
  fetchPropertiesActivitiesReport: (teamGUID, options) =>
    dispatch(() => fetchPropertiesActivitiesReport(teamGUID, options)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdvancedActivityReporting)
