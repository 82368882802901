import React from 'react'

import * as Display from 'app/components/display/display'
import * as snugNotifier from 'app/services/snugNotifier'
import Spinner from 'app/sm/common/spinner'
import ViewingContactItem from 'app/sm/inspections/components/viewing_contact_item'
import ViewingContactSearchSection from 'app/sm/inspections/components/viewing_contact_search_section'

const Stages = {
  display: 'display',
  search: 'search',
}

const MODAL_TITLE_TEXT = {
  TEAM_MEMBERS: 'Assign Team Members',
  SEARCH_AND_ADD: 'Search and add',
}

const DisplayAssignedMembers = ({
  chosenPropertyText,
  onStageChange,
  viewing,
  showSpinner = false,
  assignedTeamMembersList,
  onRemoveClicked,
  isInspector,
  onChangeInspector,
  isInspectorChecked,
  viewingStartDate,
}) => {
  const isInspectorCheckedForViewing = isInspectorChecked
    ? isInspectorChecked[viewing.guidID]
    : {}
  const { Suburb, FriendlyName, address } = viewing
  const propertyAddress = address
    ? address
    : `${FriendlyName}, ${Suburb} at ${viewingStartDate}`
  return (
    <div>
      <p>
        Assign team members for the viewing of {propertyAddress}
        <span className="fw500">{chosenPropertyText}</span>
      </p>
      <div className="display-flex">
        <Display.GenericButton
          text="Add team member"
          componentClass="col-xs-4 pl0"
          onClick={() => {
            onStageChange(Stages.search)
          }}
        />
      </div>
      <div className="mt20 searched-result-section ra-card__list">
        {showSpinner && <Spinner />}
        {!showSpinner &&
          assignedTeamMembersList.length > 0 &&
          assignedTeamMembersList.map((teamMember, index) => {
            return (
              <div className="viewing-items pt0 pb0" key={index}>
                <div className="content-wrapper pt5 pb5 cursor-pointer">
                  <ViewingContactItem
                    contact={teamMember}
                    enableRemove
                    onRemoveClicked={onRemoveClicked}
                    isInspector={isInspector}
                    onChangeInspector={onChangeInspector}
                    isInspectorChecked={isInspectorCheckedForViewing}
                  />
                  <div className="controls comment-wrap w45p xs-w100p" />
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

const SearchSection = ({
  nameSearchText,
  mobileSearchText,
  onSearchTextChange,
  managerList = [],
  onListItemClicked,
  showSpinner = false,
  noManagerResultsFound = false,
}) => {
  return (
    <div>
      <ViewingContactSearchSection
        nameSearchText={nameSearchText}
        mobileSearchText={mobileSearchText}
        onSearchTextChange={onSearchTextChange}
        isNotAssignTeamMember={false}
      />
      <div className="searched-result-section ra-card__list mb30 mt20">
        {showSpinner && <Spinner />}
        {!showSpinner &&
          managerList.length > 0 &&
          managerList.map((manager, index) => {
            return (
              <div className="viewing-items pt0 pb0" key={index}>
                <div className="content-wrapper pt5 pb5 cursor-pointer">
                  <ViewingContactItem
                    contact={manager}
                    onClick={onListItemClicked}
                  />
                  <div className="controls comment-wrap w45p xs-w100p" />
                </div>
              </div>
            )
          })}
        {noManagerResultsFound && <div>No results Found</div>}
      </div>
    </div>
  )
}

class AssignViewingTeamMemberModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stage: Stages.display,
      mobileSearchText: '',
      nameSearchText: '',
      inputErrors: {},
      managerList: [],
      filteredManagersList: [],
      assignedTeamMembersList: [],
      showSpinner: false,
      isSearch: false,
      isInspectorCheckedForProperty: {},
      noManagerResultsFound: false,
    }
    this.onStageChange = this.onStageChange.bind(this)
    this.onBackIconClicked = this.onBackIconClicked.bind(this)
  }

  componentDidMount() {
    const { managersListForViewingFilters, teamId } = this.props
    if (teamId) {
      this.fetchAssignedTeamMembers()
      managersListForViewingFilters(teamId).then((managerList) => {
        const teamMembersList = []
        if (managerList.length > 0) {
          managerList.forEach((teamMember) => {
            const teamMemberObj = {
              id: teamMember.contact.guidID,
              firstName: teamMember.contact.firstName,
              lastName: teamMember.contact.lastName,
              email: teamMember.contact.email,
              phone: teamMember.contact.phone,
            }
            teamMembersList.push(teamMemberObj)
          })
        }
        this.setState({
          managerList: teamMembersList,
        })
      })
    }
  }

  onBackIconClicked = () => {
    this.onStageChange(Stages.display)
  }

  onChangeInspector = (event, teamMember) => {
    const { updateViewingTeamMemberRelation, teamId, viewing } = this.props
    const { checked } = event.target
    const request = {
      inspector: checked,
    }
    if (viewing.guidID && teamMember.guidID) {
      this.setState({
        showSpinner: true,
      })
      updateViewingTeamMemberRelation(
        teamId,
        viewing.guidID,
        teamMember.guidID,
        request,
      )
        .then(() => {
          this.setState({
            isInspectorCheckedForProperty: {
              ...this.state.isInspectorCheckedForProperty,
              [viewing.guidID]: {
                ...this.state.isInspectorCheckedForProperty[viewing.guidID],
                [teamMember.guidID]: checked,
              },
            },
            showSpinner: false,
          })
        })
        .catch((err) => {
          snugNotifier.error(err)
          this.setState({
            showSpinner: false,
          })
        })
    }
  }

  onListItemClicked = (selectedTeamMember) => {
    const { id: teamMemberGUID = '' } = selectedTeamMember
    const { assignViewingToTeamMember, viewing, teamId } = this.props
    if (
      selectedTeamMember &&
      window.confirm(
        'Are you sure, you would like to assign this team member ?',
      )
    ) {
      this.setState({
        showSpinner: true,
      })
      assignViewingToTeamMember(teamId, viewing.guidID, teamMemberGUID)
        .then(() => {
          snugNotifier.success('Successfully assigned team member')
          this.fetchAssignedTeamMembers()
        })
        .then(() => {
          this.setState({
            showSpinner: false,
          })
          this.onStageChange(Stages.display)
        })
        .catch((apiError) => {
          snugNotifier.error(apiError)
          this.setState({
            showSpinner: false,
          })
        })
    }
  }
  onRemoveClicked = (event, teamMember) => {
    const { assignedTeamMembersList } = this.state
    const { deleteViewingTeamMember, teamId, viewing } = this.props
    if (
      assignedTeamMembersList.length > 0 &&
      assignedTeamMembersList.length === 1
    ) {
      snugNotifier.error('Please assign at least one team member')
    }
    if (
      assignedTeamMembersList.length > 1 &&
      teamMember.guidID &&
      window.confirm(
        'Are you sure, you would like to remove this team member ?',
      )
    ) {
      this.setState({
        showSpinner: true,
      })
      deleteViewingTeamMember(teamId, viewing.guidID, teamMember.guidID)
        .then(() => {
          this.setState({
            showSpinner: false,
          })
          snugNotifier.success('Successfully removed team member')
          this.fetchAssignedTeamMembers()
        })
        .catch((error) => {
          snugNotifier.error(error)
          this.setState({
            showSpinner: false,
          })
        })
    }
    event.preventDefault()
  }

  onSearchTextChange = (field) => {
    const { managerList } = this.state
    return (event) => {
      this.setState(
        {
          [field]: event.target.value,
        },
        () => {
          const { mobileSearchText = '', nameSearchText = '' } = this.state
          const formattedMobileText =
            mobileSearchText.indexOf('04') === 0
              ? mobileSearchText.replace(/^.{2}/g, '+614')
              : mobileSearchText
          this.setState({
            isSearch: true,
            showSpinner: true,
          })
          const nameSearch = nameSearchText.toLowerCase()
          if (nameSearchText !== '') {
            const filteredManagers = managerList.filter(
              (manager) =>
                manager.firstName.toLowerCase().includes(nameSearch) ||
                manager.lastName.toLowerCase().includes(nameSearch) ||
                manager.email.toLowerCase().includes(nameSearch),
            )
            if (filteredManagers.length > 0) {
              this.setState({
                filteredManagersList: filteredManagers,
                showSpinner: false,
                noManagerResultsFound: false,
              })
            } else {
              this.setState({
                filteredManagersList: [],
                showSpinner: false,
                noManagerResultsFound: true,
              })
            }
          }
          if (mobileSearchText !== '') {
            const filteredManagers = managerList.filter((manager) =>
              manager.phone.includes(formattedMobileText),
            )
            if (filteredManagers.length > 0) {
              this.setState({
                filteredManagersList: filteredManagers,
                showSpinner: false,
              })
            }
          }
          if (mobileSearchText !== '' && nameSearchText !== '') {
            const filteredManagers = managerList.filter(
              (manager) =>
                manager.firstName.toLowerCase().includes(nameSearch) ||
                manager.lastName.toLowerCase().includes(nameSearch) ||
                manager.email.toLowerCase().includes(nameSearch) ||
                manager.phone.includes(formattedMobileText),
            )
            if (filteredManagers.length > 0) {
              this.setState({
                filteredManagersList: filteredManagers,
                showSpinner: false,
              })
            }
          }
          if (mobileSearchText === '' && nameSearchText === '') {
            this.setState({
              filteredManagersList: [],
              isSearch: false,
              showSpinner: false,
            })
          }
        },
      )
    }
  }

  onStageChange = (stage) => {
    this.setState({ stage })
  }

  fetchAssignedTeamMembers() {
    const { fetchViewingTeamMembers, teamId, viewing } = this.props
    if (viewing.guidID) {
      this.setState({
        showSpinner: true,
      })
      fetchViewingTeamMembers(teamId, viewing.guidID)
        .then((teamMemberInfo) => {
          teamMemberInfo.forEach((teamMember) => {
            if (
              teamMember &&
              teamMember.isInspector &&
              teamMember.isInspector === true
            )
              this.setState({
                isInspectorCheckedForProperty: {
                  ...this.state.isInspectorCheckedForProperty,
                  [viewing.guidID]: {
                    ...this.state.isInspectorCheckedForProperty[viewing.guidID],
                    [teamMember.guidID]: true,
                  },
                },
              })
          })
          this.setState({
            assignedTeamMembersList: teamMemberInfo,
            showSpinner: false,
          })
        })
        .catch((error) => {
          this.setState({
            showSpinner: false,
          })
        })
    }
  }

  render() {
    const {
      stage = Stages.display,
      nameSearchText,
      mobileSearchText,
      managerList,
      showSpinner,
      filteredManagersList,
      isSearch,
      assignedTeamMembersList,
      isInspectorCheckedForProperty,
      noManagerResultsFound,
    } = this.state
    const { toggleModal, viewing, viewingStartDate } = this.props
    const modalTitleText =
      stage === Stages.display
        ? MODAL_TITLE_TEXT.TEAM_MEMBERS
        : MODAL_TITLE_TEXT.SEARCH_AND_ADD

    const modalCloser = (
      <i
        className="fa fa-times font-size-24"
        onClick={() => {
          toggleModal(viewing)
          this.setState({ isInspectorCheckedForProperty: {} })
        }}
      />
    )
    const modalHeader = (
      <div className="display-flex align-items-center width100">
        {stage !== Stages.display && (
          <div
            className="cursor-pointer"
            onClick={() => this.onBackIconClicked()}
          >
            <i className="icon-arrow-left" />
          </div>
        )}
        <h4 className="display-flex align-items-center justify-content-center fw500 flex-1">
          <span>{modalTitleText}</span>
        </h4>
      </div>
    )

    const modalBody = (
      <div className="p15 pb80">
        {stage === Stages.display && (
          <DisplayAssignedMembers
            onStageChange={this.onStageChange}
            showSpinner={showSpinner}
            viewing={viewing}
            assignedTeamMembersList={assignedTeamMembersList}
            onRemoveClicked={this.onRemoveClicked}
            isInspector={true}
            onChangeInspector={this.onChangeInspector}
            isInspectorChecked={isInspectorCheckedForProperty}
            viewingStartDate={viewingStartDate}
          />
        )}
        {stage === Stages.search && (
          <SearchSection
            nameSearchText={nameSearchText}
            mobileSearchText={mobileSearchText}
            onSearchTextChange={this.onSearchTextChange}
            managerList={isSearch ? filteredManagersList : managerList}
            onListItemClicked={this.onListItemClicked}
            showSpinner={showSpinner}
            noManagerResultsFound={noManagerResultsFound}
          />
        )}
      </div>
    )

    return (
      <Display.ModalWithScroll
        modalCloser={modalCloser}
        modalHeader={modalHeader}
        modalBody={modalBody}
        takeMinHeight
      />
    )
  }
}

export default AssignViewingTeamMemberModal
