export const openZendeskWidget = () => {
  const { zE } = window
  zE('messenger', 'open')
}

export const populateZendeskWidgetWithNameAndEmail = (
  name = '',
  email = '',
) => {
  const { zE } = window
  zE(function () {
    zE.identify({
      name,
      email,
    })
  })
}

export const populateZendeskSuggestions = (suggestion = '') => {
  const { zE } = window
  zE(function () {
    zE.setHelpCenterSuggestions({ search: suggestion })
  })
}
