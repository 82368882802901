import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import Badge from 'app/components/design-system-components/Badges'

function RegisteredViewingNumBadge({
  uniqueIdentifier,
  numRegistered,
  viewingCap,
}) {
  return (
    <>
      <div data-tooltip-id={`${uniqueIdentifier}`} data-tooltip-content="">
        <Badge variant="blue" text="Full" />
        <ReactTooltip
          id={uniqueIdentifier}
          place="top"
          className="tool-tip-style"
        >
          <span>
            {numRegistered} Reg, Limit {viewingCap}
          </span>
        </ReactTooltip>
      </div>
    </>
  )
}

export default RegisteredViewingNumBadge
