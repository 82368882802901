import { api } from 'app/utils/api/helpers'

export const updateUserProfile = (payload) => {
  return api.put(`/sm/users/profile`, payload)
}

export const getRegisterUrl = (agencyId) => {
  return api.get(`/agency/${agencyId}/registrationurl`)
}

export const getUserPreferenceStatus = (category) => {
  return api.get(`/sm/user/alerts/${category}/status`)
}

export const authorizedSubscribe = (category) => {
  return api.post(`/sm/user/alerts/subscribe`, {
    unsubscribeCategories: { [category]: true },
  })
}

export const authorizedUnSubscribe = (category) => {
  return api.post(`/sm/user/alerts/unsubscribe`, {
    unsubscribeCategories: { [category]: true },
  })
}
