import React from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import greenTick from 'app/assets/icons/green-tick.png'
import orangeTick from 'app/assets/icons/orange-hollow-tick.svg'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

const OnsiteRegistrant = 1
const EmailRegistrant = 2
const ViewingRegistration = 3
const RegistrantTypeRenter = 'renter'
const RegistrantTypeWalkIn = 'walkinRenter'

export class RenterStatusCheckboxes extends React.Component {
  constructor(props) {
    super(props)
    const { viewingRegistration } = props
    this.state = {
      interested: viewingRegistration.interested,
      checkIn: viewingRegistration.checkIn,
      comment: viewingRegistration.comment,
      didNotAttend: viewingRegistration.didNotAttend,
      fieldName: '',
      firstName: viewingRegistration.firstName,
      mobilePhone: viewingRegistration.mobilePhone,
      email: viewingRegistration.email,
      lastName: viewingRegistration.lastName,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { interested, checkIn, comment } = nextProps.viewingRegistration || {}
    const changeState =
      comment !== this.state.comment ||
      interested !== this.state.interested ||
      checkIn !== this.state.checkIn

    if (changeState) {
      this.setState({ comment, interested, checkIn })
    }
  }

  updateCheckbox = (field, value) => {
    const { viewingRegistration, updateRenterStatus, propertyId } = this.props
    if (viewingRegistration) {
      if (field === 'didNotAttend' && this.state.checkIn === true) {
        this.setState(
          {
            [field]: value,
            fieldName: field,
            checkIn: !value,
          },
          () =>
            updateRenterStatus(
              this.state,
              RegistrantTypeRenter,
              viewingRegistration.viewingID,
              viewingRegistration.guidID,
              propertyId,
            ),
        )
      } else if (field === 'checkIn' && this.state.didNotAttend === true) {
        this.setState(
          {
            [field]: value,
            fieldName: field,
            didNotAttend: !value,
          },
          () =>
            updateRenterStatus(
              this.state,
              RegistrantTypeRenter,
              viewingRegistration.viewingID,
              viewingRegistration.guidID,
              propertyId,
            ),
        )
      } else {
        this.setState(
          {
            [field]: value,
            fieldName: field,
          },
          () =>
            updateRenterStatus(
              this.state,
              RegistrantTypeRenter,
              viewingRegistration.viewingID,
              viewingRegistration.guidID,
              propertyId,
            ),
        )
      }
    }
  }

  onNoShowChange(newValue) {
    const { interested } = this.state
    newValue && interested && this.updateCheckbox('interested', false)
    this.updateCheckbox('didNotAttend', newValue)
  }

  render() {
    const {
      viewingRegistration,
      checkboxSpinnerGuidArray,
      options = {},
    } = this.props
    const { fieldName } = this.state
    const showSpinner = (checkboxSpinnerGuidArray || []).some(
      (a) => a === viewingRegistration.guidID,
    )
    const spinner = showSpinner ? (
      <i className="fa fa-spinner fa-pulse spin" />
    ) : (
      ''
    )
    const {
      interested: showInterested,
      checked: showChecked,
      noShow: showNoShow,
    } = options
    return (
      <div className="controls xs-w100p checkbox-field v-box-field flex-direction-column align-items-start mt0">
        {showNoShow && (
          <label className="v-box mt2 display-flex">
            <input
              type="checkbox"
              disabled={showSpinner}
              checked={viewingRegistration.didNotAttend}
              onChange={(e) => this.onNoShowChange(e.target.checked)}
            />
            <span className="font-size-14-mobile">No show</span>
            {fieldName === 'didNotAttend' && spinner}
          </label>
        )}
        {showChecked && (
          <label className="v-box mt2 display-flex">
            <input
              type="checkbox"
              disabled={showSpinner}
              checked={viewingRegistration.checkIn}
              onChange={(e) => this.updateCheckbox('checkIn', e.target.checked)}
            />
            <span className="font-size-14-mobile">
              Checked in {fieldName === 'checkIn' && spinner}
            </span>
          </label>
        )}

        {showInterested && (
          <label className="mt2 display-flex">
            <input
              type="checkbox"
              disabled={showSpinner}
              checked={viewingRegistration.interested}
              onChange={(e) =>
                this.updateCheckbox('interested', e.target.checked)
              }
            />
            <span className="font-size-14-mobile">
              Interested {fieldName === 'interested' && spinner}
            </span>
          </label>
        )}
      </div>
    )
  }
}

export class WalkinStatusCheckboxes extends React.Component {
  constructor(props) {
    super(props)
    const { viewingOnsiteRegistrant } = props
    this.state = {
      interested: viewingOnsiteRegistrant.interested,
      checkIn: viewingOnsiteRegistrant.checkIn,
      comment: viewingOnsiteRegistrant.comment,
      didNotAttend: viewingOnsiteRegistrant.didNotAttend,
      fieldName: '',
      firstName: viewingOnsiteRegistrant.firstName,
      mobilePhone: viewingOnsiteRegistrant.mobilePhone,
      email: viewingOnsiteRegistrant.email,
      lastName: viewingOnsiteRegistrant.lastName,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { interested, checkIn, comment } =
      nextProps.viewingOnsiteRegistrant || {}
    const changeState =
      comment !== this.state.comment ||
      interested !== this.state.interested ||
      checkIn !== this.state.checkIn

    if (changeState) {
      this.setState({ comment, interested, checkIn })
    }
  }

  updateCheckbox = (field, value) => {
    const {
      viewingOnsiteRegistrant,
      updateRenterStatus,
      propertyId,
      receiveApiError,
    } = this.props
    let renterType = ''
    viewingOnsiteRegistrant.registrantType === ViewingRegistration
      ? (renterType = RegistrantTypeRenter)
      : (renterType = RegistrantTypeWalkIn)
    if (viewingOnsiteRegistrant) {
      if (field === 'didNotAttend' && this.state.checkIn === true) {
        this.setState(
          {
            [field]: value,
            fieldName: field,
            checkIn: !value,
            externalComment: viewingOnsiteRegistrant.externalNote,
          },
          () => {
            updateRenterStatus(
              this.state,
              renterType,
              viewingOnsiteRegistrant.viewingID,
              viewingOnsiteRegistrant.guidID,
              propertyId,
            )
          },
        )
        return
      } else if (field === 'checkIn' && this.state.didNotAttend === true) {
        this.setState(
          {
            [field]: value,
            fieldName: field,
            didNotAttend: !value,
            externalComment: viewingOnsiteRegistrant.externalNote,
          },
          () => {
            updateRenterStatus(
              this.state,
              renterType,
              viewingOnsiteRegistrant.viewingID,
              viewingOnsiteRegistrant.guidID,
              propertyId,
            )
          },
        )
      } else {
        this.setState(
          {
            [field]: value,
            fieldName: field,
            externalComment: viewingOnsiteRegistrant.externalNote,
          },
          () => {
            updateRenterStatus(
              this.state,
              renterType,
              viewingOnsiteRegistrant.viewingID,
              viewingOnsiteRegistrant.guidID,
              propertyId,
            )
          },
        )
      }
    }
  }

  onNoShowChange(newValue) {
    const { interested } = this.state
    newValue && interested && this.updateCheckbox('interested', false)
    this.updateCheckbox('didNotAttend', newValue)
  }

  render() {
    const {
      viewingOnsiteRegistrant,
      checkboxSpinnerGuidArray,
      options = {},
    } = this.props
    const { fieldName } = this.state
    const showSpinner = (checkboxSpinnerGuidArray || []).some(
      (a) => a === viewingOnsiteRegistrant.guidID,
    )
    const spinner = showSpinner ? (
      <i className="fa fa-spinner fa-pulse spin" />
    ) : (
      ''
    )
    const {
      interested: showInterested,
      checked: showChecked,
      noShow: showNoShow,
    } = options
    const formattedQRCheckinAt = moment(
      viewingOnsiteRegistrant.selfCheckinAt,
    ).format(dateTimeHelpers.HALF_DAY_HALF_MONTH_TIME_AM_PM)
    let QRCheckInText = `QR Check-in ${formattedQRCheckinAt}`
    let QRImage = greenTick
    let hasColdSymptoms = false
    let overeasOrAffectedAreaVisit = false
    if (
      viewingOnsiteRegistrant &&
      viewingOnsiteRegistrant.hasColdSymptoms &&
      viewingOnsiteRegistrant.hasColdSymptoms === 'true'
    ) {
      QRCheckInText += ' Cold or Flu Symptoms'
      QRImage = orangeTick
      hasColdSymptoms = true
    }

    if (
      viewingOnsiteRegistrant &&
      viewingOnsiteRegistrant.hasRecentlyVisitedOverseasOrAffectedArea &&
      viewingOnsiteRegistrant.hasRecentlyVisitedOverseasOrAffectedArea ===
        'true'
    ) {
      QRCheckInText += ' Recently Visited Overseas or Affected area'
      QRImage = orangeTick
      overeasOrAffectedAreaVisit = true
    }

    return (
      <div className="controls xs-w100p checkbox-field v-box-field flex-direction-column align-items-start mt0">
        {showNoShow && (
          <label className="v-box mt2 display-flex">
            <input
              type="checkbox"
              disabled={showSpinner}
              checked={viewingOnsiteRegistrant.didNotAttend}
              onChange={(e) => this.onNoShowChange(e.target.checked)}
            />
            <span className="font-size-14-mobile">No show</span>
            {fieldName === 'didNotAttend' && spinner}
          </label>
        )}
        {showChecked && (
          <div
            className={`display-flex align-items-center flex-direction-column-mobile`}
          >
            <label className="v-box mt2 display-flex">
              <input
                type="checkbox"
                disabled={showSpinner}
                checked={viewingOnsiteRegistrant.checkIn}
                onChange={(e) =>
                  this.updateCheckbox('checkIn', e.target.checked)
                }
              />
              <span className="font-size-14-mobile">
                Checked in {fieldName === 'checkIn' && spinner}
              </span>
            </label>
            {viewingOnsiteRegistrant.selfCheckinType === 'QR' && (
              <span className="font-weight600 font-size-14-mobile display-block-mobile">
                (QR
                <img
                  data-tooltip-content={QRCheckInText}
                  src={QRImage}
                  alt="check in status"
                />
                <div className="pdf-show-inline font-weight600">
                  {formattedQRCheckinAt}
                  {hasColdSymptoms && <div>Cold or Flu Symptoms</div>}
                  {overeasOrAffectedAreaVisit && (
                    <div>Recently Visited Overseas or Affected area</div>
                  )}
                </div>
                )<ReactTooltip />
              </span>
            )}
          </div>
        )}
        {showInterested && (
          <label className="mt2 display-flex">
            <input
              type="checkbox"
              disabled={showSpinner}
              checked={viewingOnsiteRegistrant.interested}
              onChange={(e) =>
                this.updateCheckbox('interested', e.target.checked)
              }
            />
            <span className="font-size-14-mobile">
              Interested {fieldName === 'interested' && spinner}
            </span>
          </label>
        )}
      </div>
    )
  }
}

const CHECKED_IN_STATE_FIELD = 'checkedIn'
const REGITERED_STATE_FIELD = 'registered'

export class EnquirerCheckboxes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      spinner: false,
      [CHECKED_IN_STATE_FIELD]: false,
      [REGITERED_STATE_FIELD]: false,
    }
  }

  updateCheckbox = (field, isChecked) => {
    const { enquirer, addWalkInRenter, propertyId, viewing } = this.props
    this.setState(
      {
        spinner: true,
        [field]: isChecked,
      },
      () => {
        if (
          this.state[CHECKED_IN_STATE_FIELD] ||
          this.state[REGITERED_STATE_FIELD]
        ) {
          const enquirerData = {
            firstName: enquirer.firstName,
            lastName: enquirer.lastName,
            email: enquirer.email,
            mobilePhone: enquirer.phone,
            // Only send if checked in or not, no need to send isRegistered
            checkIn: this.state[CHECKED_IN_STATE_FIELD],
          }
          addWalkInRenter(enquirerData, propertyId, viewing.guidID)
            .then(() => {
              this.setState({ spinner: false })
            })
            .catch(() => {
              this.setState({ spinner: false })
            })
        } else {
          this.setState({ spinner: false })
        }
      },
    )
  }

  render() {
    const {
      spinner,
      [CHECKED_IN_STATE_FIELD]: checkedIn,
      [REGITERED_STATE_FIELD]: registered,
    } = this.state
    return (
      <div className="controls w40 xs-w100p checkbox-field v-box-field">
        <div>{spinner && <i className="fa fa-spinner fa-pulse spin" />}</div>
        <label className="ml10 v-box mt5">
          <input
            type="checkbox"
            disabled={spinner}
            onChange={(e) =>
              this.updateCheckbox(REGITERED_STATE_FIELD, e.target.checked)
            }
            checked={registered}
          />
          <span>Register</span>
        </label>
        <label className="ml10 v-box mt5">
          <input
            type="checkbox"
            disabled={spinner}
            onChange={(e) =>
              this.updateCheckbox(CHECKED_IN_STATE_FIELD, e.target.checked)
            }
            checked={checkedIn}
          />
          <span>Check in</span>
        </label>
      </div>
    )
  }
}
