import React from 'react'

import { stateOptions } from 'app/constants/identity-docs.constants'
import { stateConverter } from 'app/sm/helpers'

// TODO: update state dropdown to new dropdown style

const DropdownState = ({
  onChange,
  value,
  containerClass,
  componentClass,
  standardShareComponentStyleEnabled = true,
  firstOption = 'Select your option',
}) => {
  let inputValue = value
  const hasValue = !!inputValue || inputValue !== ''
  if (hasValue) {
    inputValue = stateConverter(inputValue)
  }
  return (
    <div className={`${containerClass}`}>
      <div
        className={`${
          standardShareComponentStyleEnabled ? 'standard-form-components' : ''
        } ${componentClass}`}
      >
        <div className="input-box standard-dropdown mb0">
          <select
            required
            id="state"
            onChange={onChange}
            value={inputValue}
            className={`${hasValue && 'select_hasValue'} mb5`}
            placeholder="name"
            autoComplete="nope"
          >
            <option value="" disabled>
              {firstOption}
            </option>
            {stateOptions.map(({ label, value }) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}

export default DropdownState
