import React from 'react'

import LeaseeStart from 'app/bond_cover/renters/tenants/leasee_start'
import getParameter from 'app/shared_components/parameter_parser'
import StatusBar from 'app/shared_components/status_bar'
import { SessionStorageUtils } from 'app/storage_utils'

class LeaseeStartContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  UNSAFE_componentWillMount() {
    let { getSecondaryTenantReview } = this.props
    var requestID = getParameter('requestid')
    var tenantID = getParameter('tenantid')
    getSecondaryTenantReview(requestID, tenantID)
  }

  addToSessionStorage(currentUser) {
    const requestID = getParameter('requestid')
    const tenantid = getParameter('tenantid')
    if (
      currentUser.isUserFetching ||
      currentUser.isUserProfileFetching ||
      currentUser.isVerified
    ) {
      SessionStorageUtils.removeItem('forwardUrl')
    } else {
      if (!!requestID && !!tenantid) {
        let forwardUrl = `/bc/leaseestart?requestid=${requestID}&tenantid=${tenantid}`
        SessionStorageUtils.setItem('forwardUrl', forwardUrl)
      }
    }
  }

  render() {
    let { request, currentLease, renter, tenant, currentUser, error } =
      this.props
    let { isNewLease, address } = currentLease ? currentLease : ''
    request = request ? request : ''
    currentLease = currentLease ? currentLease : ''
    renter = renter ? renter : ''
    tenant = tenant ? tenant : ''
    currentUser = currentUser ? currentUser : ''

    this.addToSessionStorage(currentUser)

    if (error) {
      return <div className="alert alert-danger"> {error} </div>
    } else {
      return (
        <div className="two-col-box-flex-row sm64">
          <div className="col-first">
            <StatusBar type="renter" progress="1" />
            <LeaseeStart
              request={request}
              currentLease={currentLease}
              address={address}
              renter={renter}
              tenant={tenant}
              currentUser={currentUser}
            />
          </div>
        </div>
      )
    }
  }
}

export default LeaseeStartContainer
