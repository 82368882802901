import React from 'react'

import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import consoleCloudLogo from 'app/assets/icons/console-cloud.svg'
import { Box, Flex } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import {
  consoleCloudId,
  ignoreActivePMS,
  pmsBrands,
  pmsStatuses,
} from 'app/constants/pms.constants'
import Settings from 'app/Integrations/components/Settings'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { getPMSStatus } from 'app/services/http/pms'
import * as snugNotifier from 'app/services/snugNotifier'
import Spinner from 'app/sm/common/spinner'
import ActivityTable from 'app/sm/plugins/components/activity_table'
import { findChosenTeam } from 'app/sm/team_contact/container'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import { consoleCloudFeatureText } from 'app/utils/strings/helpers'

const StatusActive = 'ACTIVE'
const StatusFailed = 'FAILED'
const StatusUnverified = 'UNVERIFIED'
const StatusInactive = 'INACTIVE'
const TEXT_ACTIVATING = 'Activating'
const TEXT_ACTIVE = 'Active'
const TEXT_FAILED = 'Failed'
const COLOR_GREEN = 'green'
const COLOR_YELLOW = 'yellow'
const COLOR_RED = 'red'

const ConsoleCloudSection = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(34, 33, 42, 0.08);
  border-radius: 2px;
  .initial-section {
    display: flex;
    width: 100%;

    @media (max-width: 576px) {
      display: block;
    }
  }
`

const ActivateBtn = styled.div`
  width: 407px;
  min-width: 407px;
  height: 88px;
  border-right: 1px solid #dfdfdf;

  .button-default {
    margin: 0 auto;
    display: table;

    button {
      padding: 0px 32px;
      font-size: 18px;
      line-height: 24px;
    }
  }
  @media (max-width: 576px) {
    width: 100%;
    min-width: 100%;
    border-right: 0;
  }
`

const Section = styled.div`
  padding: 16px;
  width: 100%;

  textarea {
    border: 1px solid #212025;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
  }
  button {
    height: 50px !important;
  }

  @media (max-width: 576px) {
    .button-default {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
  }
`

const APIKeySection = ({ cancel, submit, update, value, error }) => {
  return (
    <Section>
      <Display.GreyBodyText
        componentClass="mt0"
        textClass="fs14 mb25"
        text={
          <>
            Enter your Console Cloud API key.{' '}
            <a
              href="https://help.snug.com/hc/en-us/articles/360002192555"
              target="_blank"
              rel="noreferrer"
            >
              Where can I find my API key?
            </a>
          </>
        }
      />
      <Form.CommentArea
        id="apiKey"
        onChange={update('apiKey')}
        rows={4}
        value={value}
        error={error}
      />
      <Display.GenericButton
        componentClass="col-sm-3 mt20 pl0"
        text="Cancel"
        onClick={() => cancel()}
        buttonType="tertiary"
        size="large"
      />
      <Display.GenericButton
        componentClass="col-sm-3 mt20"
        text="Activate"
        onClick={() => submit()}
        buttonType=""
        size="large"
      />
    </Section>
  )
}

const StatusSection = styled.div`
  min-width: 407px;
  position: relative;
  padding: 16px;
  border-right: 1px solid #dfdfdf;

  @media (max-width: 576px) {
    width: 100%;
    min-width: 100%;
    border-right: 0;
    padding: 16px 0px;
  }

  .yellow {
    font-weight: 500;
    font-size: 16px;
    color: #686d71;
  }
`
const StatusLabel = styled.label`
  line-height: 24px;
  margin-right: 10px;
  font-size: 18px;
  font-weight: 500;
`

const Logo = styled(Flex)`
  width: 313px;
  border-right: 1px solid #dfdfdf;
  align-items: center;
  padding: ${theme.space[4]}px;

  @media (max-width: 576px) {
    width: 100%;
    border-right: 0;
    margin-top: 10px;
  }
`

const ConsoleCloudLogo = () => {
  return (
    <Logo>
      <img className="width100" src={consoleCloudLogo} alt="consoleCloudLogo" />
    </Logo>
  )
}

const StatusText = styled(Box)`
  color: gray;
`

class ConsoleCloudContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitted: false,
      initialStage: true,
      apiKeyStage: false,
      activatingStage: false,
      successStage: false,
      failedStage: false,
      apiKey: '',
      errors: {},
      status: '',
      updatedBy: '',
      lastUpdatedBy: '',
      tableSpinner: false,
      agencyGUID: '',
      activityList: [],
      anotherPMSActive: false,
    }
  }

  componentDidMount() {
    const { teams, teamSlug } = this.props
    const chosenTeam = findChosenTeam(teams, teamSlug) || {}
    if (chosenTeam !== null) {
      const teamGUID = chosenTeam.guid
      if (teamGUID) {
        this.setState({
          tableSpinner: true,
          agencyGUID: teamGUID,
          currentTeam: chosenTeam,
        })
        this.getIntegrationDetails(teamGUID).then(() =>
          this.getIntegrationActivityDetails(teamGUID),
        )
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.teams.length !== this.props.teams.length) {
      const teamSlug =
        (this.props.match &&
          this.props.match.params &&
          this.props.match.params.teamSlug) ||
        ''
      const curTeam =
        this.props.teams.find((team) => team.slug === teamSlug) || {}
      const teamGUID = curTeam.guid
      if (teamGUID) {
        this.setState({
          tableSpinner: true,
          agencyGUID: teamGUID,
          currentTeam: curTeam,
        })
        this.getIntegrationDetails(teamGUID).then(() =>
          this.getIntegrationActivityDetails(teamGUID),
        )
      }
    }
  }

  onCancel = () => {
    this.setState({
      apiKeyStage: false,
      initialStage: true,
      isSubmitted: false,
      activatingStage: false,
      failedStage: false,
      successStage: false,
    })
  }

  onClickActivate = () => {
    this.setState({
      apiKeyStage: true,
      initialStage: false,
    })
  }

  onSubmitAPIKey = () => {
    const { activateIntegration, getIntegration } = this.props
    const { apiKey, agencyGUID } = this.state
    if (agencyGUID === '') {
      return
    }
    const data = {
      apiKey: apiKey,
    }
    if (this.validate()) {
      this.setState({
        isSubmitted: true,
        activatingStage: true,
        tableSpinner: true,
      })
      activateIntegration(agencyGUID, data)
        .then(() => {
          getIntegration(agencyGUID)
            .then((result) => {
              const activatingStage = !!(
                result.status && result.status === StatusUnverified
              )
              this.setState({
                apiKeyStage: false,
                activatingStage,
                successStage: true,
                status: result.status,
                updatedBy: result.updatedBy,
                lastUpdatedDate: result.lastUpdatedDate,
                tableSpinner: false,
                message: result.message,
              })
            })
            .catch((error) => {
              this.setState({
                activatingStage: false,
                failedStage: true,
                apiKeyStage: false,
                tableSpinner: false,
                message: error,
              })
              snugNotifier.error(error)
            })
        })
        .catch((error) => {
          snugNotifier.error(error)
          this.setState({
            activatingStage: false,
            failedStage: true,
            apiKeyStage: false,
            tableSpinner: false,
            message: error,
          })
        })
    }
  }

  getIntegrationActivityDetails = (teamGUID) => {
    const { getIntegrationActivity } = this.props
    const { isSubmitted } = this.state
    if (!isSubmitted) return
    getIntegrationActivity(teamGUID).then((activity) => {
      this.setState({ activityList: activity })
    })
  }

  getIntegrationDetails = (teamGUID) => {
    const { getIntegration } = this.props
    let obj = {
      initialStage: false,
      isSubmitted: true,
    }
    return getIntegration(teamGUID)
      .then((result) => {
        if (result && result.status === StatusActive) {
          obj.successStage = true
        } else if (result.status === StatusFailed) {
          obj.failedStage = true
        } else if (result.status === StatusUnverified) {
          obj.activatingStage = true
        } else if (result.status === StatusInactive) {
          obj.initialStage = true
          obj.isSubmitted = false
          getPMSStatus(teamGUID).then((statuses) => {
            const otherPMSIsActive = statuses.find(
              (s) =>
                s.pms !== consoleCloudId &&
                s.status === pmsStatuses.active.id &&
                !ignoreActivePMS(s.pms),
            )
            this.setState({ anotherPMSActive: !!otherPMSIsActive })
          })
        }
        this.setState({
          status: result.status,
          updatedBy: result.updatedBy,
          lastUpdatedDate: result.lastUpdatedDate,
          message: result.message,
          ...obj,
        })
      })
      .catch((error) => {
        this.setState({
          initialStage: true,
          apiKeyStage: false,
        })
        snugNotifier.error(error)
      })
      .finally(() => this.setState({ tableSpinner: false }))
  }

  update = (field) => {
    return (data) => {
      this.setState({
        [field]: data.value,
        errors: {
          ...this.state.errors,
          [field]: data.error,
        },
      })
    }
  }

  validate = () => {
    let { apiKey, errors } = this.state
    if (apiKey === '') {
      errors.apiKey = 'This field is required'
    } else {
      errors = {}
    }
    this.setState({
      errors: errors,
    })
    return Object.keys(errors).length === 0
  }

  render() {
    const {
      isSubmitted,
      initialStage,
      apiKeyStage,
      activatingStage,
      successStage,
      apiKey,
      errors,
      status,
      updatedBy,
      tableSpinner,
      lastUpdatedDate = '',
      message = '',
      activityList = [],
      currentTeam,
      anotherPMSActive,
    } = this.state

    const lastUpdatedBy =
      lastUpdatedDate &&
      moment(lastUpdatedDate).format(
        dateTimeHelpers.DATE_DAY_SHORT_STRING_MONTH_YEAR,
      ) +
        ' by ' +
        updatedBy

    const isActive = successStage && status === StatusActive

    let pillColour, pillText, statusBottomText
    if (isActive) {
      pillColour = COLOR_GREEN
      pillText = TEXT_ACTIVE
      statusBottomText = 'Activated: ' + lastUpdatedBy
    } else if (activatingStage) {
      pillColour = COLOR_YELLOW
      pillText = TEXT_ACTIVATING
      statusBottomText = 'Started: ' + lastUpdatedBy
    } else {
      pillColour = COLOR_RED
      pillText = TEXT_FAILED
      statusBottomText = message
    }

    return (
      <div className="display-inline-block width100">
        <Display.SectionHeader
          componentClass="mb35"
          text={consoleCloudFeatureText}
          textClass="p0"
        />
        {tableSpinner ? (
          <Spinner />
        ) : (
          <ConsoleCloudSection className="application-wrapper display-flex">
            {apiKeyStage && (
              <APIKeySection
                cancel={this.onCancel}
                submit={this.onSubmitAPIKey}
                update={this.update}
                value={apiKey}
                error={errors.apiKey}
              />
            )}
            {!apiKeyStage && (
              <div className="initial-section">
                {initialStage && (
                  <ActivateBtn
                    data-tooltip-id={anotherPMSActive && 'not-allowed-tooltip'}
                  >
                    <Display.GenericButton
                      componentClass="mt0"
                      buttonStyleClass="height40px mt25"
                      size="large"
                      text="Activate"
                      disabled={anotherPMSActive}
                      onClick={() => {
                        this.onClickActivate()
                      }}
                    />
                    <ReactTooltip id="not-allowed-tooltip" data-type="info">
                      Another PMS is already active
                    </ReactTooltip>
                  </ActivateBtn>
                )}
                {isSubmitted && (
                  <StatusSection>
                    <div className="display-flex">
                      <StatusLabel>Status </StatusLabel>
                      <Display.StatusPill
                        componentClass="mr10"
                        pillType={pillColour}
                        text={pillText}
                        pillTextClass="fw500 fs16 pl15 pr15"
                      />
                    </div>
                    <StatusText mt={2} fontSize={theme.fontSizes.pRegular14}>
                      {statusBottomText}
                    </StatusText>
                  </StatusSection>
                )}
                <ConsoleCloudLogo />
              </div>
            )}
          </ConsoleCloudSection>
        )}
        {!apiKeyStage && !isActive && (
          <Display.GreyBodyText
            compxonentClass="mt15"
            textClass="fs14"
            text="Activate the Console Cloud plugin to automate creation of Tenancy
            Agreements."
          />
        )}
        {isSubmitted && (
          <>
            <Settings pmsBrand={consoleCloudId} currentTeam={currentTeam} />
            <ActivityTable activityList={activityList} />
          </>
        )}
      </div>
    )
  }
}

export default withRouter(ConsoleCloudContainer)
