import { connect } from 'react-redux'

import { changeTeam } from 'app/session/session_actions'
import { fetchProfile } from 'app/sm/profile/profile_actions'
import { changeRole } from 'app/sm/properties/properties_actions'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'
import {
  fetchActiveOffer,
  fetchPropertyApplications,
  offerAll,
  onStatusDropdownChange,
  onTabChange,
  ping,
  requestRenterInformation,
  reset,
  setPropertyId,
  updatePreferences,
} from 'app/sm/renter_applications/renter_applications_actions'
import RenterApplications from 'app/sm/renter_applications/renter_applications_container'

const mapStateToProps = ({ RenterApplications, session, Profile }) => ({
  applications: RenterApplications.applications,
  shown: RenterApplications.shown,
  tabsCtrl: RenterApplications.tabsCtrl,
  activeOffer: RenterApplications.activeOffer,
  property: RenterApplications.property,
  error: RenterApplications.error,
  spinner: RenterApplications.spinner,
  teams: session.teams,
  profile: Profile.profile,
  applicationsSpinner: RenterApplications.applicationsSpinner,
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  onTabChange: (id) => dispatch(onTabChange(id)),
  fetchPropertyApplications: (propertyId, dispatchedFromTab = 'Applications') =>
    dispatch(fetchPropertyApplications(propertyId, dispatchedFromTab)),
  onStatusDropdownChange: (value, applicationId, dispatchedFromTab) =>
    dispatch(onStatusDropdownChange(value, applicationId, dispatchedFromTab)),
  setPropertyId: (propertyId) => dispatch(setPropertyId(propertyId)),
  offerAll: (cluster) => dispatch(offerAll(cluster)),
  reset: () => dispatch(reset()),
  ping: () => dispatch(ping()),
  fetchActiveOffer: (propertyId) => dispatch(fetchActiveOffer(propertyId)),
  changeRole: (role) => dispatch(changeRole(role)),
  requestRenterInformation: (applicationId, applicantId, content) =>
    dispatch(requestRenterInformation(applicationId, applicantId, content)),
  changeTeam: (currentTeam) => dispatch(changeTeam(currentTeam)),
  fetchProfile: (offerId) => dispatch(fetchProfile(offerId)),
  updatePreferences: (id, payload) => dispatch(updatePreferences(id, payload)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RenterApplications)
