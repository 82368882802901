import React from 'react'

import calendar from 'app/assets/icons/calendar-information.2.svg'
import bathIcon from 'app/assets/icons/summary_icons/bath.svg'
import bedIcon from 'app/assets/icons/summary_icons/bed.svg'
import carIcon from 'app/assets/icons/summary_icons/car.svg'
import keyIcon from 'app/assets/icons/summary_icons/key.svg'
import rentIcon from 'app/assets/icons/summary_icons/rent.svg'
import { PropertyKeysInfoModal } from 'app/features/teams/key-logger/PropertyKeysInfoModal'
import * as helpers from 'app/sm/helpers'
import { formatRent, onlistModalCodes } from 'app/sm/helpers'

const PropertyInfoDetails = ({
  address,
  bedrooms,
  bathrooms,
  weeklyRentDisplay,
  availableFrom,
  children,
  propertyInfo = {},
  isEntryDetailsDisplay = false,
  keySets,
  keyCode,
}) => {
  const { garages = 0, parking = 0, carports = 0 } = propertyInfo || {}
  const carCountSpaces = { garages, parking, carports }
  const formattedRent = formatRent(weeklyRentDisplay)

  return (
    <div>
      <p className="application-summary-address">{address}</p>
      <div className="summary-details-list">
        <ul>
          {bedrooms !== undefined && (
            <li className="mb20">
              <img className="summary-icon" src={bedIcon} alt="Bed Icon" />
              <span className="application-summary-number mb0">{bedrooms}</span>
            </li>
          )}
          {bathrooms !== undefined && (
            <li className="mb20">
              <img className="summary-icon" src={bathIcon} alt="Bath Icon" />
              <span className="application-summary-number mb0">
                {bathrooms}
              </span>
            </li>
          )}
          {carports !== undefined && (
            <li className="mb20">
              <img className="summary-icon" src={carIcon} alt="Car Icon" />
              <span className="application-summary-number mb0">
                {helpers.getCarSpaceCountForProperty(carCountSpaces)}
              </span>
            </li>
          )}
          {formattedRent !== undefined && (
            <li className="mb20">
              <img className="summary-icon" src={rentIcon} alt="Rent Icon" />
              <span className="application-summary-number mb0">
                {formattedRent}
              </span>
            </li>
          )}
          {availableFrom !== undefined && (
            <li className="mb20">
              <img className="summary-icon" src={calendar} alt="Rent Icon" />
              <span className="application-summary-number mb0">
                {availableFrom}
              </span>
            </li>
          )}
          {isEntryDetailsDisplay && (
            <li className="mb20 ml0-mobile">
              <img
                className="summary-icon"
                src={keyIcon}
                height="18"
                width="18"
                alt={'key code'}
              />
              <PropertyKeysInfoModal
                tipId={propertyInfo.guidID}
                keySets={keySets}
                onlistKeyCode={keyCode}
                triggerProps={{ width: '120px' }}
              />
            </li>
          )}
        </ul>
      </div>
      {children}
    </div>
  )
}

export default PropertyInfoDetails
