import { connect } from 'react-redux'

import {
  addToSavedProperty,
  fetchApplications,
  onSorterDropdownChange,
} from 'app/sm/applications/applications_actions'
import ApplicationsContainer from 'app/sm/applications/applications_container'

const mapStateToProps = ({ MyApplications, session }) => ({
  applications: MyApplications.applications,
  sortBy: MyApplications.sortBy,
  spinner: MyApplications.spinner,
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  addToSavedProperty: (propertyId) => dispatch(addToSavedProperty(propertyId)),
  onSorterDropdownChange: (s) => dispatch(onSorterDropdownChange(s)),
  fetchApplications: () => dispatch(fetchApplications()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationsContainer)
