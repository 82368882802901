import React from 'react'

import moment from 'moment'

import AgencyDashboard from 'app/bond_cover/agency/dashboard/agency_dashboard'
import Pagination from 'app/bond_cover/agency/dashboard/agency_dashboard_pagination'
import AgencyModal from 'app/bond_cover/agency/dashboard/agency_modal'
import { SessionStorageUtils } from 'app/storage_utils'

export class AgencyDashboardContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 1,
      data: [],
      managers: [],
      manager: 'all',
      modal: false,
      search: '',
      searchType: 1,
      searchString: '',
    }
  }

  componentDidMount() {
    let { searchType, manager } = this.getSearchOptions()

    this.props.currentTeam &&
      this.fetchAgencyRequests(
        searchType,
        manager,
        this.props.currentTeam.guid,
        false,
      )

    const currentUrl = window.location.pathname
    this.props.setBackUrl(currentUrl)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { searchType, manager } = this.getSearchOptions()

    if (nextProps.currentTeam !== this.props.currentTeam) {
      nextProps.currentTeam &&
        this.fetchAgencyRequests(
          searchType,
          manager,
          nextProps.currentTeam.guid,
          false,
        )
    }
  }

  getData() {
    return JSON.parse(SessionStorageUtils.getItem('data'))
  }

  getSearchOptions() {
    let searchOptions = JSON.parse(SessionStorageUtils.getItem('searchOptions'))
    return searchOptions ? searchOptions : { searchType: '', manager: '0' }
  }

  setData(data) {
    return SessionStorageUtils.setItem('data', JSON.stringify(data))
  }

  setSearchOptions() {
    SessionStorageUtils.setItem(
      'searchOptions',
      JSON.stringify({
        searchType: this.state.searchType,
        manager: this.state.manager,
      }),
    )
  }

  action(type, key) {
    let data = this.getData()
    let dataIndex = data.findIndex((application) => application.key == key)
    data[dataIndex].status = type
    this.setData(data)
  }

  doPagination = (direction) => {
    const { searchType, manager } = this.getSearchOptions()
    const searchString = this.state.searchString
    let {
      bondCovers: { cursor },
    } = this.props
    direction === 'first' && (cursor = 0)
    !!searchString
      ? this.props.searchAgencyRequests(
          searchType,
          manager,
          searchString.trim(),
          cursor,
        )
      : this.props.fetchAgencyRequests(searchType, manager, cursor)
  }

  fetchAgencyRequests = (searchType, manager, agencyID, setSearchOptions) => {
    this.props.fetchAgencyRequests(searchType, manager, agencyID)
    this.setState(
      {
        searchType: searchType,
        manager: manager,
        searchString: '',
      },
      setSearchOptions && this.setSearchOptions,
    )
  }

  filterData() {
    let data = this.getData()
    let { tab, manager, search } = this.state
    data = this.filterTab(tab, data)
    data = this.filterManager(manager, data)
    data = this.filterSearch(search, data)

    return this.setState({ data: data })
  }

  filterHelper(tab) {
    switch (tab) {
      case 1:
        return 'Submitted'
      case 2:
        return 'Active'
      case 3:
        return 'Claim'
      default:
        return null
    }
  }

  filterManager(manager, data) {
    if (manager !== 'all') {
      data = data.filter((application) => application.manager === manager)
    }

    return data
  }

  filterSearch(search, data) {
    data = data.filter((application) =>
      application.address.toUpperCase().includes(search.toUpperCase()),
    )
    return data
  }

  filterTab(tab, data) {
    let filter = this.filterHelper(tab)

    if (filter !== null) {
      data = data.filter((data) => data.status === filter)
    }

    return data
  }

  openModal(key) {
    this.setState({ modal: true, key: key })
  }

  switchTab(tab) {
    return (event) => {
      let { manager, search } = this.state
      this.setState({ tab: tab }, () => this.filterData())
    }
  }

  update(field) {
    const { searchType, manager } = this.getSearchOptions()
    return (event) => {
      const value = event.target.value
      this.setState({ searchString: value })
      window.clearTimeout(doSearch)
      doSearch = setTimeout(() => {
        !!value
          ? this.props.searchAgencyRequests(searchType, manager, value.trim())
          : this.props.fetchAgencyRequests(searchType, manager)
      }, 500)
    }
  }

  updateDate = (field) => {
    return (date) => {
      let value = moment(date).format('DD-MMM-YYYY')
      this.setState({ modal: false }, this.updateDateData(field, value))
    }
  }

  updateDateData(key, date) {
    let data = this.getData()
    let dataIndex = data.findIndex((application) => application.key == key)
    data[dataIndex].status = 'Active'
    data[dataIndex].expiry = date
    this.setData(data)
  }

  render() {
    const { responseText, spinner } = this.props
    let { tab, data, managers, modal, key } = this.state
    let bondCovers = this.props.bondCovers || {}
    let { searchType, manager } = this.state
    let currentPathName = window.location.pathname
    const showDashboard = !responseText && bondCovers.agencyRequests
    return (
      <div className="sm-renter-applications">
        {/* <div className="sm-ra-header">
          <div className="back">
            <i className="icon-arrow-left"></i><span>Applications</span>
            <h4>R&amp;H Newtown</h4>
          </div>
        </div> */}
        <div className="sm-ra-tabs">
          <div className="search-type-wrapper">
            <div
              className={searchType === 1 ? 'sm-ra-tab active' : 'sm-ra-tab'}
              onClick={() => this.fetchAgencyRequests(1, manager, true)}
            >
              <div className="sm-ra-tab__title">New</div>
            </div>

            <div
              className={searchType === 2 ? 'sm-ra-tab active' : 'sm-ra-tab'}
              onClick={() => this.fetchAgencyRequests(2, manager, true)}
            >
              <div className="sm-ra-tab__title">Active</div>
            </div>
            <div
              className={!searchType ? 'sm-ra-tab active' : 'sm-ra-tab'}
              onClick={() => this.fetchAgencyRequests('', manager, true)}
            >
              <div className="sm-ra-tab__title">All</div>
            </div>
          </div>
          <div className="prototype-filter">
            <input
              type="text"
              placeholder="Search Address"
              onChange={this.update('search')}
              value={this.state.searchString}
            />
            {/* <select onChange={(event) => this.fetchAgencyRequests(searchType, event.target.value, true)} value={manager}>
                { managers.map( manager => {
                  return <option key={ manager } value= { manager }>{ manager }</option>
                })}
              </select> */}
          </div>
        </div>

        {responseText && (
          <div className="alert alert-danger"> {responseText} </div>
        )}

        <AgencyDashboard
          data={bondCovers}
          action={(type, key) => this.action(type, key)}
          openModal={(key) => this.openModal(key)}
          responseText={responseText}
          spinner={spinner}
        />

        {!responseText && (
          <Pagination
            agencyRequests={bondCovers.agencyRequests}
            cursor={bondCovers.cursor}
            doPagination={this.doPagination}
            currentPathName={currentPathName}
          />
        )}

        {/* <AgencyDashboard data={ data } action={ (type, key) => this.action(type, key) } openModal={ (key) => this.openModal(key) } /> */}

        {modal && (
          <AgencyModal applicationKey={key} updateDate={this.updateDate} />
        )}
      </div>
    )
  }
}

let doSearch = null

export default AgencyDashboardContainer
