import React, { useEffect, useState } from 'react'

import moment from 'moment'

import ticaLogo from 'app/assets/integrationLogos/tica.svg'
import {
  Alert,
  Box,
  Button,
  ButtonWithLoading,
  Flex,
  LoadingSection,
  TextSpan,
} from 'app/components/design-system-components'
import { ContainerHeader } from 'app/components/display/text/standard_text/standard-headings/component'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import theme from 'app/match/applicationReportPDF/assets/theme'
import RequestDocsModal from 'app/match/apply/application_summary_before_submit/components/TicaCheck/RequestDocsModal'
import {
  getApplicantTicaCheck,
  runTicaCheck,
} from 'app/services/http/integrations'
import * as snugNotifier from 'app/services/snugNotifier'
import { SHORT_STANDARD_DATETIME } from 'app/utils/datetime/helpers'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import { loadingStatesIds } from 'app/utils/loading-states'
import { ticaIntegrationLearnMore } from 'config/external-links'

const TicaCheck = ({
  applicantId,
  teamId,
  enableNote,
  onNewNoteUpdated,
  newNotesContent,
  onNoteIconClicked,
  onNewNoteAdded,
  onNoteCrossClicked,
  interpreteNoteResult = {},
  enableNotesButton,
  section,
  id,
  toggleRequestInfoModal,
}) => {
  const {
    state: existingCheck,
    setState: setExistingCheck,
    loadingStates: existingCheckLoadingStates,
    loadingStatesHelpers: setExistingCheckLoadingStatesHelpers,
  } = useStateWithLoading(null)

  const {
    loadingStates: checkProcessLoadingStates,
    loadingStatesHelpers: setCheckProcessLoadingStatesHelpers,
  } = useStateWithLoading(null)

  useEffect(() => {
    getApplicantExistingTicaCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantId])

  const [requestDocsModalOpen, setRequestDocsModalOpen] = useState(false)

  const toggleRequestDocsModalOpen = () => {
    setRequestDocsModalOpen(!requestDocsModalOpen)
  }

  const getApplicantExistingTicaCheck = () => {
    setExistingCheckLoadingStatesHelpers.startLoading()
    getApplicantTicaCheck(teamId, applicantId)
      .then((check) => {
        setExistingCheck(check)
        setExistingCheckLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => setExistingCheckLoadingStatesHelpers.setError(err))
  }

  const runCheckForApplicant = () => {
    setCheckProcessLoadingStatesHelpers.startLoading()
    runTicaCheck(teamId, applicantId)
      .then((check) => {
        setCheckProcessLoadingStatesHelpers.markDoneSuccessfully()
        setExistingCheck(check)
        snugNotifier.success('TICA ran successfully')
      })
      .catch((err) => {
        if (
          err?.message ===
          ErrorMessages[Errors.ErrorGeneratingTICARequestHashedDocuments]
        ) {
          setCheckProcessLoadingStatesHelpers.markDoneSuccessfully()
          toggleRequestDocsModalOpen()
        } else {
          setCheckProcessLoadingStatesHelpers.setError(err)
        }
      })
  }

  const requestDocsModalPrimaryAction = () => {
    toggleRequestDocsModalOpen()
    toggleRequestInfoModal()
  }

  const viewCheckReport = () => {
    existingCheck && window.open(existingCheck.url, '_blank')
  }

  const creatorFullName =
    !!existingCheck?.createdBy &&
    `${existingCheck.createdBy.firstName} ${existingCheck.createdBy.lastName}`
  const formattedCreationTime =
    !!existingCheck?.createdAt &&
    moment(existingCheck.createdAt).format(SHORT_STANDARD_DATETIME)

  const logoHeaderCmp = (
    <Flex height="38px" alignItems="center">
      <Box height="100%" pb={3}>
        <img height="100%" src={ticaLogo} alt="logo" />
      </Box>
      <TextSpan
        fontSize={theme.fontSizes.pSurplusLarge24}
        fontWeight={theme.fontWeights[4]}
        ml={2}
      >
        Check
      </TextSpan>
    </Flex>
  )

  return (
    <Box id={id} className="container-component bbs">
      {/*TODO: refactor ContainerHeader and replace className in the above box*/}
      <ContainerHeader
        iconHeaderCmp={logoHeaderCmp}
        isEditable={false}
        editTitle="Edit"
        enableNote={enableNote}
        onNewNoteUpdated={onNewNoteUpdated}
        newNotesContent={newNotesContent}
        onNoteIconClicked={onNoteIconClicked}
        onNewNoteAdded={onNewNoteAdded}
        onNoteCrossClicked={onNoteCrossClicked}
        interpreteNoteResult={interpreteNoteResult}
        enableNotesButton={enableNotesButton}
        section={section}
      />
      <TextSpan as="div" mt={5} fontSize={theme.fontSizes.pLarge16}>
        Run Check to initiate a TICA query. Login to TICA to view the report.{' '}
        <a target="_blank" href={ticaIntegrationLearnMore} rel="noreferrer">
          Learn more
        </a>
      </TextSpan>
      <Box mt={6}>
        <LoadingSection loadingState={existingCheckLoadingStates}>
          {!!checkProcessLoadingStates.error && (
            <Alert variant="warningWithBg" my={3}>
              {checkProcessLoadingStates.error.message}
            </Alert>
          )}
          {!existingCheck && (
            <ButtonWithLoading
              variant="outlineSuccess"
              loading={
                checkProcessLoadingStates.state === loadingStatesIds.LOADING
              }
              disableWhenLoading
              onClick={() => runCheckForApplicant()}
              showSpinnerBesideText
            >
              Run Check
            </ButtonWithLoading>
          )}
          {!!existingCheck && (
            <>
              <Button
                variant="outlineSuccess"
                onClick={() => viewCheckReport()}
              >
                View Check
              </Button>
              {creatorFullName && (
                <TextSpan as="div" mt={4}>
                  Processed by {creatorFullName} {formattedCreationTime}
                </TextSpan>
              )}
            </>
          )}
        </LoadingSection>
      </Box>
      {requestDocsModalOpen && (
        <RequestDocsModal
          toggleRequestDocsModalOpen={toggleRequestDocsModalOpen}
          requestDocsModalPrimaryAction={requestDocsModalPrimaryAction}
        />
      )}
    </Box>
  )
}

export default TicaCheck
