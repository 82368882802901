import React from 'react'

import { connect } from 'react-redux'

import {
  Alert,
  Box,
  ButtonWithLoading,
  Flex,
  LegacyButton,
  LoadingSection,
} from 'app/components/design-system-components'
import { PageHeader } from 'app/components/design-system-components/page/Header'
import { PageSection } from 'app/components/design-system-components/page/Section'
import { PageSubHeading } from 'app/components/design-system-components/page/SubHeading'
import {
  ignoreActivePMS,
  pmsBrands,
  pmsStatuses,
} from 'app/constants/pms.constants'
import Settings from 'app/Integrations/components/Settings'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { PMSActivityLogsTable } from 'app/PMSActivity/components/ActivityLogsTable'
import { StatusBlock } from 'app/PMSActivity/components/StatusBlock'
import {
  activatePMS,
  deactivatePMS,
  getPMSActivityLogs,
  getPMSStatus,
} from 'app/services/http/pms'
import * as snugNotifier from 'app/services/snugNotifier'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  loadingStatesIds,
} from 'app/utils/loading-states'

export const usePMSStatus = (
  currentTeam,
  pmsBrand,
  isIntegrationBundleActive,
) => {
  const {
    state: status,
    setState: setStatus,
    loadingStates: statusLoadingStates,
    loadingStatesHelpers: statusLoadingStatesHelpers,
  } = useStateWithLoading(null)

  const loadPMSStatus = () => {
    statusLoadingStatesHelpers.startLoading()
    return getPMSStatus(currentTeam.guid)
      .then((statuses) => {
        const statusInfo = statuses.find((s) => s.pms === pmsBrand)
        const otherPMSIsActive = statuses.find(
          (s) =>
            s.pms !== pmsBrand &&
            s.status === pmsStatuses.active.id &&
            !ignoreActivePMS(s.pms),
        )

        setStatus({
          activationAllowed:
            isIntegrationBundleActive &&
            (!otherPMSIsActive || pmsBrand === pmsBrands.vaultRE.id),
          ...statusInfo,
        })
        statusLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => {
        statusLoadingStatesHelpers.setError(err)
        return Promise.reject(err)
      })
  }

  return {
    pmsStatusInfo: status,
    loadPMSStatus,
    pmsStatusLoadingStates: statusLoadingStates,
  }
}

export const useActivatePMS = (currentTeam, pmsBrand) => {
  const {
    loadingStates: activationLoadingStates,
    loadingStatesHelpers: activationLoadingStatesHelpers,
  } = useLoadingStates()

  const activate = (payload) => {
    activationLoadingStatesHelpers.startLoading()
    return activatePMS(currentTeam.guid, pmsBrand, payload)
      .then(() => {
        activationLoadingStatesHelpers.markDoneSuccessfully()
        snugNotifier.success(`${pmsBrand} activated successfully!`)
      })
      .catch((err) => {
        activationLoadingStatesHelpers.setError(err)
        return Promise.reject(err)
      })
  }

  return {
    activatePMS: activate,
    activatePMSLoadingStates: activationLoadingStates,
    activatePMSLoadingStatesHelpers: activationLoadingStatesHelpers,
  }
}

export const useDeactivatePMS = (currentTeam, pmsBrand) => {
  const {
    loadingStates: deactivateLoadingStates,
    loadingStatesHelpers: deactivateLoadingStatesHelpers,
  } = useLoadingStates()

  const deactivate = () => {
    deactivateLoadingStatesHelpers.startLoading()
    return deactivatePMS(currentTeam.guid, pmsBrand)
      .then(() => {
        deactivateLoadingStatesHelpers.markDoneSuccessfully()
        snugNotifier.success(`${pmsBrand} deactivated successfully!`)
      })
      .catch((err) => {
        deactivateLoadingStatesHelpers.setError(err)
        return Promise.reject(err)
      })
  }

  return {
    deactivatePMS: deactivate,
    deactivatePMSLoadingStates: deactivateLoadingStates,
  }
}

class PMSActivity extends React.Component {
  constructor(props) {
    super(props)
    const { pmsBrand } = this.props
    const brandObj =
      Object.values(pmsBrands).find(({ id }) => id === pmsBrand) || {}
    this.state = {
      brand: brandObj,
      activities: [],
      statusLoadingStates: createInitialLoadingState(),
      activitiesLoadingStates: createInitialLoadingState(),
    }

    this.statusLoadingStateUtils = createLoadingStateUtils((state) =>
      this.setState({
        statusLoadingStates: state,
      }),
    )

    this.activitiesLoadingStateUtils = createLoadingStateUtils((state) =>
      this.setState({
        activitiesLoadingStates: state,
      }),
    )
  }

  componentDidMount() {
    this.reloadData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.statusProps?.statusInfo?.status !==
      this.props.statusProps?.statusInfo?.status
    ) {
      this.reloadData()
    }
  }

  reloadData() {
    this.setState(
      {
        activities: [],
        nextCursor: '',
      },
      this.getActivities,
    )
  }

  getActivities() {
    const { pmsBrand, currentTeam, statusProps } = this.props
    const { nextCursor } = this.state

    if (!statusProps?.statusInfo?.status) return

    this.activitiesLoadingStateUtils.startLoading()
    return getPMSActivityLogs(currentTeam.guid, pmsBrand, nextCursor)
      .then(({ data, cursor }) => {
        this.activitiesLoadingStateUtils.markDoneSuccessfully()
        this.setState({
          activities: [...this.state.activities, ...data],
          nextCursor: cursor,
        })
      })
      .catch(this.activitiesLoadingStateUtils.setError)
  }

  renderPaginatedActivityTable() {
    const { activitiesLoadingStates, activities, nextCursor } = this.state
    return (
      <>
        <LoadingSection
          actionHandler={() => this.getActivities()}
          loadingState={activitiesLoadingStates}
          loaderProps={{ fontSize: '20px' }}
          showChildrenWhileLoading={activities.length > 0}
        >
          <PMSActivityLogsTable activityList={activities} />
          {nextCursor && (
            <Flex mt="10px" justifyContent="center">
              <ButtonWithLoading
                buttonCmp={LegacyButton}
                width="150px"
                height="40px"
                variant="outlineSuccess"
                onClick={() => this.getActivities()}
                loading={
                  activitiesLoadingStates.state === loadingStatesIds.LOADING
                }
                disableWhenLoading
              >
                Load more
              </ButtonWithLoading>
            </Flex>
          )}
        </LoadingSection>
      </>
    )
  }

  renderStatusBlock() {
    const { brand } = this.state
    const {
      activationInstructions,
      manualActivationAllowed,
      manualDeactivationAllowed,
    } = brand || {}

    const {
      logo,
      isAdmin,
      statusProps,
      activateProps,
      deactivateProps,
      integrationsBundleIsActive,
    } = this.props

    const { statusInfo, statusLoadingStates, reloadStatusInfo } = statusProps

    const { activationFn, activateLoadingStates } = activateProps || {}
    const { deactivationFn, deactivateLoadingStates } = deactivateProps || {}

    const activationErr = this.renderErrorMsg(activateLoadingStates)
    const deactivationErr = this.renderErrorMsg(deactivateLoadingStates)

    return (
      <>
        <LoadingSection
          actionHandler={() => reloadStatusInfo()}
          loadingState={statusLoadingStates}
          loaderProps={{ fontSize: theme.fontSizes.h1 + 'px' }}
        >
          <StatusBlock
            onActivation={manualActivationAllowed && activationFn}
            activateLoadingStates={activateLoadingStates}
            onDeactivation={manualDeactivationAllowed && deactivationFn}
            deactivateLoadingStates={deactivateLoadingStates}
            statusInfo={statusInfo}
            activationInstructions={activationInstructions}
            isAdmin={isAdmin}
            logo={logo}
            integrationsBundleIsActive={integrationsBundleIsActive}
          />
          {activationErr}
          {deactivationErr}
        </LoadingSection>
      </>
    )
  }

  renderErrorMsg(loadingStates) {
    return (
      <Box>
        {loadingStates?.state === loadingStatesIds.ERROR && (
          <Alert variant="warningWithBg" mb={theme.space[4] + 'px'}>
            {loadingStates.error.message}
          </Alert>
        )}
      </Box>
    )
  }

  render() {
    const paginatedActivityTableComponent = this.renderPaginatedActivityTable()
    const statusBlockComponent = this.renderStatusBlock()
    const { label: brandLabel, id: brandID } = this.state.brand
    const { currentTeam, isAdmin, statusProps } = this.props
    const { status } = statusProps?.statusInfo || {}
    const isActive = status === pmsStatuses.active.id

    return (
      <>
        <PageHeader title={brandLabel} />
        <PageSection>{statusBlockComponent}</PageSection>
        {isActive && (
          <>
            {isAdmin && (
              <PageSection>
                <Settings
                  currentTeam={currentTeam}
                  pmsBrand={brandID}
                  pmsStatus={statusProps?.statusInfo}
                />
              </PageSection>
            )}
            <PageSection>
              <PageSubHeading title="Activity" />
              {paginatedActivityTableComponent}
            </PageSection>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = ({ session }) => ({ currentTeam: session.currentTeam })

export default connect(mapStateToProps)(PMSActivity)
