import styled from 'styled-components'

import theme from 'app/match/applicationReportPDF/assets/theme'
import { SUBTEXT_FILE_UPLOAD_ALL_FILES } from 'app/utils/text/helpers'

const StyledDocumentListText = styled.span`
  font-size: ${theme.fontSizes.pSmall12};
  font-weight: ${theme.fontWeights.mid};
`

function DocumentListText({ textToDisplay = SUBTEXT_FILE_UPLOAD_ALL_FILES }) {
  return <StyledDocumentListText>{textToDisplay}</StyledDocumentListText>
}

export default DocumentListText
