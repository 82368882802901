import React from 'react'

import styled from 'styled-components'

import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as phoneNumberHelpers from 'app/utils/phonenumber/helpers'

import { Box, Flex } from '.'

const StyledName = styled(Box)`
  font-weight: ${({ theme }) => theme.fontWeights[6]}px;
  margin-bottom: ${({ theme }) => theme.space[3]}px;
`

const StyledContactNumAndEmail = styled(Flex)`
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  align-items: center;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const StyledCircleDivider = styled(Box)`
  height: ${({ theme }) => theme.space[2]}px;
  width: ${({ theme }) => theme.space[2]}px;
  background-color: ${({ theme }) => theme.colors.gray300};
  border-radius: ${({ theme }) => theme.radii[7]};
  margin-right: ${({ theme }) => theme.space[3]}px;
  @media screen and (max-width: 576px) {
    display: none;
  }
`

function ContactDetails({ name = '', contactNumber = '', email = '' }) {
  const formattedMobileNumber =
    phoneNumberHelpers.formatPhoneNumber(contactNumber)
  return (
    <Box>
      <StyledName>{name}</StyledName>
      <StyledContactNumAndEmail>
        <Box mr={theme.baseSpace + 'px'}>{formattedMobileNumber}</Box>
        <StyledCircleDivider />
        <Box>{email}</Box>
      </StyledContactNumAndEmail>
    </Box>
  )
}

export default ContactDetails
