import React, { useState } from 'react'

import styled from 'styled-components'

import { Box, Button, Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { history } from 'app/shared_components/router'
import AddDetails from 'app/sm/background_check/add_details'
import ExampleReportModal from 'app/sm/background_check/exampleReportModal'
import SelfTenancyDocList from 'app/sm/background_check/SelfTenancyCheck/DocList'
import UploadOwnTenancyCheckModal from 'app/sm/background_check/SelfTenancyCheck/uploadOwnTenancyCheckModal'
import * as smHelpers from 'app/sm/helpers'
import * as textHelpers from 'app/utils/text/helpers'

const greenTickURL =
  'https://snugco.s3-ap-southeast-2.amazonaws.com/assets/greenTick.png'

const StyledUL = styled.ul`
  padding-left: ${theme.space[7]}px !important;
  margin-bottom: ${theme.space[5]}px;
  list-style-image: url(${greenTickURL});
  li {
    line-height: ${theme.space[7]}px;
    margin-bottom: ${theme.space[1]}px;
    display: revert;
    span {
      margin-left: ${theme.space[3]}px;
    }
  }
`

const ResponsiveStack = styled(Flex)`
  @media (max-width: ${theme.maxWidths[5]}px) {
    flex-direction: column;
  }
`
const StyledText = styled(Text)`
  @media (max-width: ${theme.maxWidths[5]}px) {
    margin-top: ${theme.space[5]}px;
  }
`

export const bgcInclusionList = (excludeBGCBankruptcyReport = false) => (
  <StyledUL>
    <li>
      <span>Tenancy database</span>
    </li>
    {!excludeBGCBankruptcyReport && (
      <li>
        <span>Bankruptcy check</span>
      </li>
    )}
    <li>
      <span>Identity verification</span>
    </li>
    <li>
      <span>Court records</span>
    </li>
    <li>
      <span>Directorships</span>
    </li>
    <li>
      <span>
        Learn more:{' '}
        <Text
          color={theme.colors.skyBlue}
          onClick={() => history.push(smHelpers.urlTo('howBGCheckWorks'))}
          className="cursor-pointer"
          as="span"
        >
          How does it work?
        </Text>
      </span>
    </li>
  </StyledUL>
)

export const whyAddBGCheck = (
  <StyledUL>
    <li>
      <span>Strengthen your renter profile</span>
    </li>
    <li>
      <span>Verify important information</span>
    </li>
    <li>
      <span>Valid for 6 months and reusable anywhere (PDF emailed)</span>
    </li>
    <li>
      <span>You choose: include or discard report</span>
    </li>
  </StyledUL>
)

function BGCheck({
  rentalHistoryCompleted = false,
  identityDocumentCompleted = false,
  notShowAddBGCheckSection = false,
  hasDriverLicenseCardNumber = false,
  hasValidIdentityDocs = false,
  nextURL = '',
  selfTenancyCheck = {},
  deleteCheck,
  onClickSaveForSelfTenancyCheck,
  hasDriversLicense = false,
  hasIdentityDocs = false,
  excludeBGCBankruptcyReport = false,
  queryParams = '',
}) {
  const [showExampleReportModal, setShowExampleReportModal] = useState(false)
  const toggleExampleReportModal = () => {
    setShowExampleReportModal(!showExampleReportModal)
  }

  const [showUploadOwnTenancyCheckModal, setShowUploadOwnTenancyCheckModal] =
    useState(false)

  const toggleShowUploadOwnTenancyCheckModal = () => {
    setShowUploadOwnTenancyCheckModal(!showUploadOwnTenancyCheckModal)
  }
  const hasDriverLicenseButNoCardNumber =
    hasDriversLicense && !hasDriverLicenseCardNumber

  const showPromptToCompleteBGCheck =
    rentalHistoryCompleted &&
    hasValidIdentityDocs &&
    !hasDriverLicenseButNoCardNumber

  const { SelfTenancyCheckDoc = [] } = selfTenancyCheck

  const hasSelfTenancyCheck =
    SelfTenancyCheckDoc && SelfTenancyCheckDoc.length > 0

  const processBGCheckUrl = `${smHelpers.urlTo('backgroundCheckPayment')}${
    window.location.search
  }`
  return (
    <Box>
      {!notShowAddBGCheckSection && !hasSelfTenancyCheck && (
        <>
          <Text mt={theme.space[4] + 'px'} mb={theme.space[5] + 'px'}>
            Add a Background Check and show you’re a good tenant with this
            instant report, including:
          </Text>
          {bgcInclusionList(excludeBGCBankruptcyReport)}
          <Text mt={theme.space[4] + 'px'} mb={theme.space[5] + 'px'}>
            You're in control:
          </Text>
          {whyAddBGCheck}
          {showPromptToCompleteBGCheck && (
            <Text
              fontWeight={theme.fontWeights[4]}
              color="watermelon"
              mb={theme.space[5] + 'px'}
            >
              Add the Background Check and save!
            </Text>
          )}
          <Box mb={theme.space[5] + 'px'} pt={theme.space[5] + 'px'}>
            <ResponsiveStack alignItems="baseline">
              <Flex alignItems="baseline">
                <Text
                  className="text-decoration-line-through"
                  fontWeight={theme.fontWeights[6]}
                  fontSize={theme.fontSizes[7] + 'px'}
                  lineHeight={0}
                  mb={0}
                >
                  {`${textHelpers.BG_CHECK_USUAL_PRICE} `}
                </Text>{' '}
                <Text
                  fontWeight={theme.fontWeights[6]}
                  fontSize={theme.fontSizes[10] + 'px'}
                  lineHeight={0}
                  color="watermelon"
                  ml={3}
                  mb={0}
                  mr={5}
                >
                  {`${textHelpers.BG_CHECK_SPECIAL_PRICE} `}
                </Text>
              </Flex>
              <StyledText color="gray500" mb={0} lineHeight={0}>
                (inc GST)
              </StyledText>
            </ResponsiveStack>
          </Box>
          <Box width={theme.width[16] + 'px'}>
            {showPromptToCompleteBGCheck ? (
              <Flex justifyContent="center">
                <Button
                  variant="solidSuccessStrong"
                  onClick={() => history.push(processBGCheckUrl)}
                  width={theme.width[16] + 'px'}
                  sizeVariant="large"
                >
                  Add Background Check
                </Button>
              </Flex>
            ) : (
              <AddDetails
                hasCurrentAddress={rentalHistoryCompleted}
                hasIdInfo={identityDocumentCompleted && hasValidIdentityDocs}
                hasDriverLicenseCardNumber={hasDriverLicenseCardNumber}
                hasValidIdentityDocs={hasValidIdentityDocs}
                hasDriversLicense={hasDriversLicense}
                hasIdentityDocs={hasIdentityDocs}
              />
            )}
          </Box>
        </>
      )}
      {hasSelfTenancyCheck && (
        <SelfTenancyDocList
          selfTenancyCheckDoc={SelfTenancyCheckDoc}
          showAlert={true}
          onRemoveButtonClicked={deleteCheck}
        ></SelfTenancyDocList>
      )}
      {showExampleReportModal && (
        <ExampleReportModal
          toggleExampleReportModal={toggleExampleReportModal}
        />
      )}
      {showUploadOwnTenancyCheckModal && (
        <UploadOwnTenancyCheckModal
          toggleShowUploadOwnTenancyCheckModal={
            toggleShowUploadOwnTenancyCheckModal
          }
          onClickSaveForSelfTenancyCheck={onClickSaveForSelfTenancyCheck}
        />
      )}
    </Box>
  )
}

export default BGCheck
