import * as API from 'app/agency/agency_access'
import { receiveRegisterError } from 'app/bond_cover/agency/agency_actions'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import * as sharedHelpers from 'app/shared_components/helpers'

export const createNote = (payload, ownerGUID, ownerType, files) => () => {
  const attachments = { files }
  let data = new FormData()
  if (attachments.files && attachments.files.length > 0) {
    sharedHelpers.composeAttachment(attachments, data)
  }
  data.append('data', JSON.stringify(payload))
  return API.postNote(data, ownerGUID, ownerType).then(
    ({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText.then((error) => {
          return Promise.reject(translateErrorCodeToMessage(error))
        })
      }
    },
  )
}

export const fetchNotes = (ownerGUID, ownerType) => () => {
  return API.getNotes(ownerGUID, ownerType).then(
    ({ responseData, ok, responseText }) => {
      if (ok) {
        return Promise.resolve(responseData)
      } else {
        return responseText.then((error) => {
          return Promise.reject(translateErrorCodeToMessage(error))
        })
      }
    },
  )
}

export const fetchAllNotesForPropertyAndViewing =
  (propertyId, viewingId) => () => {
    return API.getAllNotesForPropertyAndViewing(propertyId, viewingId).then(
      ({ responseData, ok, responseText }) => {
        if (ok) {
          return Promise.resolve(responseData)
        } else {
          return responseText.then((error) => {
            return Promise.reject(translateErrorCodeToMessage(error))
          })
        }
      },
    )
  }

export const sendPropertyList = (messageGUID) => () => {
  return API.sendPropertyList(messageGUID).then(({ ok, responseText }) => {
    if (ok) {
      return Promise.resolve()
    } else {
      return responseText.then((error) => {
        return Promise.reject(translateErrorCodeToMessage(error))
      })
    }
  })
}

export const fetchStocklist = (teamSlug) => () => {
  return API.getStocklist(teamSlug).then(
    ({ responseData, ok, responseText }) => {
      if (ok) {
        return Promise.resolve(responseData)
      } else {
        return responseText.then((error) => {
          return Promise.reject(translateErrorCodeToMessage(error))
        })
      }
    },
  )
}

export const selfServiceRegistration = (payload, logo, banner) => () => {
  let data = new FormData()
  if (logo !== '') {
    sharedHelpers.composeBrandingFiles(logo, data, 'logo')
  }
  if (banner !== '') {
    sharedHelpers.composeBrandingFiles(banner, data, 'banner')
  }
  data.append('data', JSON.stringify(payload))
  return API.selfRegistration(data).then(
    ({ responseData, ok, responseText }) => {
      if (ok) {
        return Promise.resolve(responseData)
      } else {
        return responseText.then((error) => {
          return Promise.reject(translateErrorCodeToMessage(error))
        })
      }
    },
  )
}

export function fetchAllSupplierSources(success) {
  return (dispatch) => {
    API.fetchAllSupplierSources().then(
      ({ ok, responseText, supplierSources }) => {
        if (ok) {
          success(supplierSources)
        } else {
          responseText.then((t) => {
            dispatch(receiveRegisterError(t))
          })
        }
      },
    )
  }
}

export const searchAgencyDomain = (agency) => {
  return () => {
    return API.searchAgencyDomain(agency)
      .then((agencies) => {
        return Promise.resolve(agencies)
      })
      .catch((t) => Promise.reject(translateErrorCodeToMessage(t)))
  }
}

export const registerTeamViaDomain = (payload) => {
  return () => {
    return API.registerTeamViaDomain(payload)
      .then((data) => {
        return Promise.resolve(data)
      })
      .catch((t) => Promise.reject(translateErrorCodeToMessage(t)))
  }
}

export const getRegisterTeamViaDomainStatus = (jobId) => {
  return () => {
    return API.getRegisterTeamViaDomainStatus(jobId)
      .then((status) => {
        return Promise.resolve(status)
      })
      .catch((t) => Promise.reject(translateErrorCodeToMessage(t)))
  }
}
