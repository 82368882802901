import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import house01 from 'app/assets/icons/house01.jpg'
import humanImg from 'app/assets/icons/human01.jpg'
import * as Display from 'app/components/display/display'
// import helpers from '../shared_components/helpers'
import * as smhelpers from 'app/sm/helpers'

const Container = styled.div`
  min-width: 200px;
  min-height: 80px;
`

const modalHeader = (
  <div className="display-flex align-items-center width100">
    <h4 className="display-flex align-items-center justify-content-center fw500 flex-1">
      <span>Header</span>
    </h4>
  </div>
)
const modalBody = (
  <Container>
    <div>random text, this component needs improvement</div>
  </Container>
)

class StyleGuideContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      basicModalActive: false,
      modalwithscroll: false,
    }
  }

  onToggleModal = (field) => {
    this.setState({
      [field]: !this.state[field],
    })
  }
  render() {
    const { basicModalActive = false, modalwithscroll = false } = this.state

    const modalOnScrollBottom = (
      <div className="modal-footer border-none width100 display-flex">
        <div className="col-sm-6 div-order-1 width100">
          <Display.GenericButton
            text="close"
            onClick={() => this.onToggleModal('modalwithscroll')}
          />
        </div>
        <div className="col-sm-6 div-order-1 width100">
          {' '}
          <Display.GenericButton text="do nothing" onClick={() => {}} />
        </div>
      </div>
    )
    return (
      <div className="display-flex flex-direction-column">
        <Display.ProfilePagesRowLayout>
          <Display.MainHeader text="Text styles" />
          <Display.GreyBodyText text="Component Name: GreyBodyText" />
          <Display.DefaultBodyText text="Component Name: DefaultBodyText" />
          <Display.SmallCaption text="Component Name: SmallCaption" />
          <Display.MainHeader text="Component Name: MainHeader" />
          <Display.SectionHeader text="Component Name: SectionHeader" />
          <Display.ContainerHeader text="Component Name: ContainerHeader" />
          <Display.GreySubheading text="Component Name: GreySubheading" />
        </Display.ProfilePagesRowLayout>
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: CrossSellItem"
          componentClass="mt30"
        />
        <Display.CrossSellItem
          isGrey
          title="Title"
          advertText="description: advert text grey background"
          link="#"
          linkText="linkText"
        />
        <Display.CrossSellItem
          title="Title"
          advertText="description: advert text"
          link="#"
          linkText="linkText"
        />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: GreyContainer"
        />
        <Display.GreyContainer>
          <Container>description: allows the background to be grey</Container>
        </Display.GreyContainer>
        <Display.ProfilePagesBottomButtons
          clickNextText="Component Name: ProfilePagesBottomButtons"
          componentClass="mt30"
        />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: ProfilePagesRowLayout"
        />
        <Display.ProfilePagesRowLayout>
          <Display.GreyContainer>
            <Container>description: this is a wrapper container</Container>
          </Display.GreyContainer>{' '}
        </Display.ProfilePagesRowLayout>
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: CenterContentContainer"
          componentClass="mt30"
        />
        <Display.CenterContentContainer componentClass="mb30">
          <Display.GreyContainer>
            <Container>
              <Display.ContainerHeader
                isEditable={false}
                text="description: this is a centered container"
                componentClass="width100"
              />
            </Container>
          </Display.GreyContainer>{' '}
        </Display.CenterContentContainer>
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: FlexContainer"
        />
        <Display.FlexContainer>
          <Display.GreyContainer>
            <Container>
              <div>description: this is a flex container</div>

              <div>description: this is a flex container</div>

              <div>description: this is a flex container</div>
            </Container>
          </Display.GreyContainer>{' '}
        </Display.FlexContainer>
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: StandardContactInfoContainer"
          componentClass="mt30"
        />
        <Display.StandardContactInfoContainer
          containerClass="mt30"
          mainTitle="main title"
          linesText={[
            'description: this is usually accompanied with an avatar on the left',
            'contact info one',
            'contact info two',
            'contact info three',
          ]}
        />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: Pill"
        />
        <Display.Pill
          onClick={() => {}}
          text="description: this is raw component, width unset"
        />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: Modal"
          componentClass="mt30"
        />
        <Display.GenericButton
          componentClass="col-sm-3"
          text="Show Basic modal"
          onClick={() => this.onToggleModal('basicModalActive')}
        />
        {basicModalActive && (
          <Display.Modal
            toggleModal={() => this.onToggleModal('basicModalActive')}
            title="Component Name: Modal"
            secondaryButtonAction={() => this.onToggleModal('basicModalActive')}
            secondaryButtonLabel="Close"
            primaryButtonLabel="No action"
            primaryButtonAction={() => {}}
          />
        )}
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: PropertyInfoDetails"
          componentClass="mt30"
        />
        <Display.PropertyInfoDetails
          address="address text"
          weeklyRentDisplay={100}
          availableFrom="available from"
        >
          <div>takes children component</div>
        </Display.PropertyInfoDetails>
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: ReportFeedbackMessage"
          componentClass="mt30"
        />
        <Display.ReportFeedbackMessage
          comment={{ rating: 10, ratingDate: moment() }}
        />{' '}
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: SnugTip"
          componentClass="mt30"
        />
        <Display.SnugTip
          text={`Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim `}
        />
        <Display.GreyContainer>
          <Container>
            <Display.WhiteBoxContainer>
              <Display.ContainerHeader
                isEditable={false}
                text="Component Name: WhiteBoxContainer"
                componentClass="mt30"
              />
            </Display.WhiteBoxContainer>
            <Display.SectionContentContainer>
              <Display.ContainerHeader
                isEditable={false}
                text="Component Name: SectionContentContainer"
                componentClass="mt30"
              />
            </Display.SectionContentContainer>
          </Container>
        </Display.GreyContainer>
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: ErrorMessageContainer"
          componentClass="mt30"
        />
        <Display.ErrorMessageContainer error="error message" />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: NoticeMessageContainer"
          componentClass="mt30"
        />
        <Display.NoticeMessageContainer text="notice message" />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: DocumentListItemSecondVersion"
          componentClass="mt30"
        />
        <Display.DocumentListItemSecondVersion
          label="Add file, example of usage: lease attachments"
          addDocument={() => {}}
        />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: Buttons"
          componentClass="mt30"
        />
        <Display.GenericButton
          componentClass="col-sm-3 mt20"
          text="Default button"
          onClick={() => {}}
        />
        <Display.GenericButton
          componentClass="col-sm-3 mt20"
          text="Secondary button with loading"
          onClick={() => {}}
          buttonType="secondary"
          isLoading
        />
        <Display.GenericButton
          componentClass="col-sm-3 mt20"
          text="Ghost button size large"
          onClick={() => {}}
          buttonType="ghost"
          size="large"
        />
        <Display.GenericButton
          componentClass="col-sm-3 mt20"
          text="Destructive button size jumbo"
          onClick={() => {}}
          buttonType="destructive"
          size="jumbo"
        />
        <Display.GenericButton
          componentClass="col-sm-3 mt20"
          text=" Destructive-hollow button size large"
          onClick={() => {}}
          buttonType=" destructive-hollow"
          size="large"
        />
        <Display.GenericButton
          componentClass="col-sm-3 mt20"
          text="Tertiary button size small"
          onClick={() => {}}
          buttonType="tertiary"
          size="small"
        />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: EmailLink"
          componentClass="mt30"
        />
        <Display.EmailLink email="test-user-1@snug.com" />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: TelLink"
          componentClass="mt30"
        />
        <Display.TelLink phone="0412412412" />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: ProgressLine"
          componentClass="mt30"
        />
        <Display.ProgressLine
          text="50 out of 100"
          totalPoints={50}
          recommendedPoints={100}
        />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: Breadcrumbs"
          componentClass="mt30"
        />
        <Display.Breadcrumbs
          crumbs={[
            { text: 'first', link: '' },
            { text: 'second', link: '' },
          ]}
        />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: GeneralSearchBox"
          componentClass="mt30"
        />
        <Display.GeneralSearchBox
          placeholder="Search bar"
          value="sample input, no action"
          onChange={() => {}}
        />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: Avatar"
          componentClass="mt30"
        />
        <Display.Avatar avatar={''} avatarText={`FL`} size="xssmall" />
        <Display.Avatar avatar={humanImg} avatarText={`FL`} size="msmall" />
        <Display.Avatar avatar={''} avatarText={`FL`} size="small" />
        <Display.Avatar avatar={''} avatarText={`FL`} size="big" />
        <Display.Note
          note={{
            updatedAt: moment(),
            type: smhelpers.noteActionType.approve,
            target: 0,
            content:
              'Sample text for note, this component implementation needs improvement',
            applicant: 'first name',
            author: 'Author name',
          }}
          from="propertyViewing"
          componentClass={'width100 max-width-none m0 mb10 mt10'}
        />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: DropZoneBasic"
          componentClass="mt30"
        />
        <Display.DropZoneBasic
          onDrop={() => {}}
          files={[{ preview: house01 }, { preview: humanImg }]}
          onRemoveIconClciked={() => {}}
        />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: DetailsBoxCard"
          componentClass="mt30"
        />
        <Display.DetailsBoxCard
          title="Title"
          details={['first info: something', 'second info: somrthing else']}
        />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: ApplicationSectionDetails"
          componentClass="mt30"
        />
        <Display.ApplicationSectionDetails
          title="Title"
          details={[
            { label: 'label 1', text: 'something' },
            { label: 'label 2', text: 'something else' },
          ]}
        />
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: ModalWithScroll"
          componentClass="mt30"
        />
        <Display.GenericButton
          text="Show ModalWithScroll"
          onClick={() => this.onToggleModal('modalwithscroll')}
        />
        {modalwithscroll && (
          <Display.ModalWithScroll
            toggleModal={() => this.onToggleModal('modalwithscroll')}
            modalHeader={modalHeader}
            modalBody={modalBody}
            modalBottom={modalOnScrollBottom}
          />
        )}
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: StatusPill"
          componentClass="mt30"
        />
        <div className="col-sm-6">
          <Display.StatusPill
            componentClass="mt10"
            pillType="green"
            text="type green"
          />
          <Display.StatusPill
            componentClass="mt10"
            pillType="grey"
            text="type grey"
          />
          <Display.StatusPill
            componentClass="mt10"
            pillType="hollow"
            text="type hollow"
          />
          <Display.StatusPill
            componentClass="mt10"
            pillType="orange-hollow"
            text="type orange-hollow"
          />
        </div>
        <Display.ContainerHeader
          isEditable={false}
          text="Component Name: ToolsButton"
          componentClass="mt30"
        />
        <Display.ToolsButton
          componentClass="col-sm-2 "
          tools={[
            { enableTool: true, onclick: () => {}, text: 'tool 1' },
            { enableTool: true, onclick: () => {}, text: 'tool 2' },
            { enableTool: true, onclick: () => {}, text: 'tool 3' },
          ]}
        />
        <Display.BottomContentContainer>
          {' '}
          <Display.CenterContentContainer>
            <Display.ContainerHeader
              isEditable={false}
              text="Component Name: BottomContentContainer"
            />
          </Display.CenterContentContainer>
        </Display.BottomContentContainer>
      </div>
    )
  }
}

export default StyleGuideContainer
