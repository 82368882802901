import React from 'react'

import $ from 'jquery'

import Footer from 'app/agency/stocklist/components/footer'
import Header from 'app/agency/stocklist/components/header'
import Layout from 'app/agency/stocklist/components/layout'
import { ErrorMessage } from 'app/shared_components/helpers'

import 'app/agency/stocklist/index.scss'

class Stocklist extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      agencyDetails: {},
      properties: [],
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { agencySlug },
      },
      fetchStocklist,
    } = this.props
    $('#index').addClass('no-navbar-menu')
    fetchStocklist(agencySlug)
      .then((responseData) => {
        this.setState({
          agencyDetails: responseData.agencyDetails,
          properties: responseData.listedProperties,
        })
      })
      .catch((error) => {
        this.setState({ error })
      })
  }

  componentWillUnmount() {
    $('#index').removeClass('no-navbar-menu')
  }

  render() {
    const { agencyDetails, properties } = this.state
    const {
      match: {
        params: { agencySlug },
      },
    } = this.props
    const { error } = this.state
    return (
      <div>
        {!error && (
          <div className="stocklist-container">
            <Header />
            <main className="main-content">
              <Layout
                teamSlug={agencySlug}
                agencyDetails={agencyDetails}
                properties={properties}
              />
            </main>
            <Footer teamSlug={agencySlug} agencyDetails={agencyDetails} />
          </div>
        )}
        <ErrorMessage error={error} />
      </div>
    )
  }
}

export default Stocklist
