import React from 'react'

import ViewingItem from 'app/sm/property_details/viewingItem'

const PotentialViewing = ({
  viewing,
  teamSlug,
  onActionBtnClicked,
  showSpinner,
  spinner,
  isUserLoggedIn,
}) => {
  return (
    <div>
      <ViewingItem
        viewing={viewing}
        isBtnRegisteredText={'Register'}
        showSpinner={showSpinner}
        spinner={spinner}
        btnStyles={'hollow-button-green'}
        teamSlug={teamSlug}
        onActionBtnClicked={onActionBtnClicked}
        changeText={undefined}
        isUserLoggedIn={isUserLoggedIn}
      />
    </div>
  )
}

export default PotentialViewing
