import { connect } from 'react-redux'

import JoinSnugForm from 'app/session/join/join_form'

const mapStateToProps = ({ session, MyProperties }) => ({
  attempts: session.attempts,
  rolePromptOn: session.rolePromptOn,
  currentAgency: session.currentAgency,
  applicationsCount: MyProperties.applicationsCount,
  teams: session.teams,
})

export default connect(mapStateToProps)(JoinSnugForm)
