import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { Button, Dropdown } from 'app/components/design-system-components'
import Box from 'app/components/design-system-components/Box'
import Flex from 'app/components/design-system-components/Flex'
import { ArrowDropDownRounded } from 'app/components/design-system-components/icons/navigation'
import { CheckboxesList } from 'app/components/design-system-components/list-filters/CheckboxesList'
import { DropdownBodySectionHeader } from 'app/components/design-system-components/list-filters/DropdownBodySectionHeader'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const states = [
  'ALL',
  'NSW',
  'VIC',
  'QLD',
  'ACT',
  'SA',
  'NT',
  'TAS',
  'WA',
]

const stateDisplayText = (state) => (state === 'ALL' ? 'All states' : state)

const stateToOption = (state) => ({
  option: state,
  optionMeta: {
    id: state,
    displayText: stateDisplayText(state),
  },
})

const statesToOptions = (states = []) =>
  states.map((state) => stateToOption(state))

const statesOptions = statesToOptions(states)

export const DropdownBodyWrapper = styled(Box)`
  min-width: ${theme.space[14]}px;
  box-shadow: 0 4px 30px 0 ${theme.colors.dropdownDroppedShadow};
  background-color: ${theme.colors.white};

  .dropdown-header {
    font-weight: ${theme.fontWeights[6]};
    font-size: ${theme.space[4]}px;
  }

  .dropdown-list {
    input[type='checkbox'] {
      margin-right: 43px;
    }

    label > span {
      font-size: 14px;
    }
  }

  .dropdown-list:last-child {
    .form-checkbox {
      padding-bottom: ${theme.baseSpace}px;
    }
  }
`

export const StyledDropdownButton = styled(Button)`
  border: 1px solid #808080;
`

const StatesDropdown = ({ onStatesChanged, initStates = [] }) => {
  const [selectedOptions, setSelectedOptions] = useState({})

  useEffect(() => {
    const options = {}

    if (initStates.length) {
      // eslint-disable-next-line array-callback-return
      statesToOptions(initStates).map((o) => {
        options[o.option] = o
      })
    } else {
      options['ALL'] = stateToOption('ALL')
    }
    setSelectedOptions(options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    onStatesChanged(Object.keys(selectedOptions))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  const onOptionClicked = (checked, option) => {
    if (checked && option.optionMeta.id === 'ALL') {
      setSelectedOptions({
        [option.optionMeta.id]: option,
      })
      return
    }

    let tmp = { ...selectedOptions }

    if (checked) {
      delete tmp['ALL']
      tmp[option.optionMeta.id] = option
    } else {
      if (tmp.hasOwnProperty(option.optionMeta.id)) {
        delete tmp[option.optionMeta.id]
      }
    }

    setSelectedOptions(tmp)
  }

  const dropDownSelectTitle = () => {
    const texts = Object.values(selectedOptions).map(
      (state) => state.optionMeta.displayText,
    )
    if (!!texts.length) {
      return texts.slice(0, 5).join(', ') + (texts.length > 5 ? ', ...' : '')
    }
    return 'Select States'
  }

  return (
    <Dropdown
      triggerWrapperComponent={({ dropdownControls }) => (
        <StyledDropdownButton
          variant="outline"
          onClick={() => dropdownControls.toggle()}
          width="240px"
          height="50px"
        >
          <Flex justifyContent="space-between">
            <Box>{dropDownSelectTitle()} </Box>
            <Box
              as={ArrowDropDownRounded}
              ml={theme.space[3] + 'px'}
              fontSize={theme.fontSizes.pExtraLarge18}
            />
          </Flex>
        </StyledDropdownButton>
      )}
      dropdownComponent={() => (
        <DropdownBodyWrapper>
          <DropdownBodySectionHeader
            header="States"
            containerProps={{
              mb: theme.space[4] + 'px',
              className: 'dropdown-header',
            }}
          />
          <CheckboxesList
            options={statesOptions}
            checkedOptions={selectedOptions}
            onCheckBoxClicked={onOptionClicked}
            containerProps={{
              className: 'dropdown-list',
            }}
          />
        </DropdownBodyWrapper>
      )}
      topSpaceInPx={9}
    />
  )
}

export default StatesDropdown
