import { Box } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

export const InvitePreview = ({ message = '', property }) => {
  const address = property
    ? `${property.address.friendlyName}, ${property.address.suburb}`
    : ''
  const subject = `Submit an Application: ${address}`
  return (
    <Box
      backgroundColor={theme.colors.paleBlue}
      overflowY="scroll"
      height="360px"
      p={theme.space[6] + 'px'}
      my={theme.space[6] + 'px'}
      lineHeight="1.2"
    >
      <Box mb={theme.space[6] + 'px'}>
        <Box textAlign="center">
          <h3>{subject}</h3>
        </Box>
        <Box mt={theme.space[6] + 'px'}>
          Hi,
          <br />
          Please submit an application for {address}. Applying takes one minute
          if you already have a Snug profile. Here's what you'll need:
          <ul>
            <li>Contact details for your previous addresses</li>
            <li>Employment details</li>
            <li>Income documents (latest payslips and bank statements)</li>
            <li>Proof of identity</li>
          </ul>
          View and apply for property: [property apply link] <br />
          {!!message && (
            <>
              <br />
              Message from Property Manager:
              <br />
              <i>{message || 'message'}</i>
              <br />
            </>
          )}
          <br />
          Please get in touch if you have any questions:
          <br />
          [agent name] from [agency name]
          <br />
          [agent email]
          <br />
          [agent phone]
          <br />
          Regards,
          <br />
          [agency name]
          <br />
        </Box>
      </Box>
    </Box>
  )
}
