import * as React from 'react'

function SvgNavigateBeforeRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 6.705a.996.996 0 00-1.41 0L9 11.295a.996.996 0 000 1.41l4.59 4.59a.996.996 0 101.41-1.41l-3.88-3.89L15 8.115c.38-.39.38-1.03 0-1.41z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgNavigateBeforeRounded
