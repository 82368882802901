import React from 'react'

import * as Display from 'app/components/display/display'

function ContextMenu({
  offerGUID,
  sendPropertyList,
  onClickInviteToProperty,
  enquirerHasEmail,
  toggleShowInviteToApplyModal,
  scheduleViewingAction,
  disabled,
}) {
  const enquiryTools = [
    {
      text: 'Send property list',
      enableTool: enquirerHasEmail,
      onClick: () => {
        // !isAccountLite
        //   ? contactDetails.email.length
        //     ? this.toggleModal(modalTypes.propertyList)
        //     : this.emailRequiredForAction()
        // :
        sendPropertyList()
      },
    },
    {
      text: 'Invite To Apply',
      enableTool: !!offerGUID,
      onClick: () => toggleShowInviteToApplyModal(),
    },
    // {
    //   text: message.archived ? 'Unarchive' : 'Archive',
    //   enableTool: true,
    //   onClick: () => {
    //     !isAccountLite
    //       ? this.onArchiveUpdateClick()
    //       : this.onClickDisabledLiteAccountOption()
    //   },
    // },
    {
      text: 'Invite to another property',
      enableTool: offerGUID,
      // enableTool: true,
      onClick: () => {
        // !isAccountLite
        //   ?
        onClickInviteToProperty()
        // :
        // this.onClickDisabledLiteAccountOption()
      },
    },
    {
      text: 'Book a Time',
      enableTool: offerGUID,
      onClick: () => {
        scheduleViewingAction()
      },
    },
  ]
  return <Display.ToolsButton tools={enquiryTools} disabled={disabled} />
}

export default ContextMenu
