import React from 'react'

import qs from 'qs'
import { connect } from 'react-redux'

import TeamApplications from 'app/agency/applications_list/connection'
import { ApplicationsSummary } from 'app/dashboard/prospects_summary'
import ManagerViewings from 'app/sm/manager_viewings/manager_viewings_connection'
import { AGENCY_USER_LEASE_CONSULTANT } from 'app/utils/roles'

function ProspectsSummary(props) {
  const { currentAgency } = props
  let isAdminOrFinance = false
  if (currentAgency !== null) {
    isAdminOrFinance = currentAgency.roles.some((agency) => {
      return !(agency.roleID === AGENCY_USER_LEASE_CONSULTANT)
    })
    if (currentAgency.roles.length === 0) {
      isAdminOrFinance = true
    }
  }
  const curQueries = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  })

  const isApplicantReport = curQueries.applicantEmail?.length > 0

  return (
    <div>
      {isAdminOrFinance &&
        (isApplicantReport ||
          !curQueries.stage ||
          curQueries.stage.toLowerCase() === 'application') && (
          <ApplicationsSummary
            match={props.match}
            location={props.location}
            isApplicantReport={isApplicantReport}
          />
        )}
      {isAdminOrFinance &&
        curQueries.stage &&
        curQueries.stage.toLowerCase() === 'applicationbystage' && (
          <TeamApplications match={props.match} location={props.location} />
        )}
      {curQueries.stage && curQueries.stage.toLowerCase() === 'viewing' && (
        <ManagerViewings match={props.match} location={props.location} />
      )}
    </div>
  )
}

const mapStateToProps = ({ session }) => ({
  currentAgency: session.currentAgency,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ProspectsSummary)
