import React from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import * as Form from 'app/components/forms/forms'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import * as snugNotifier from 'app/services/snugNotifier'
import BaseContactDetails from 'app/shared_components/BaseContactDetails'
import { NUMBER_TYPE_MOBILE } from 'app/utils/phonenumber/helpers'
import SendSMSReportButton from 'app/utils/viewings/components/SendSMSReportButton'

const CenteredContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .preview-sms {
    position: relative;
    display: flex;
  }
  [data-tooltip-content] {
    top: 0;
    font-weight: unset;
    font-size: 16px;
    position: relative;
    color: unset;
    cursor: pointer;
  }
  i {
    font-size: 14px;
  }
  @media (max-width: 576px) {
    flex-direction: column;
  }
`

class SendSmsReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ownerNumberAvailable: false,
      showSendToOwner: false,
      addNumber: false,
      owner: [],
      mobileNumber: '',
      mobileValidationErrors: '',
      sendClicked: false,
      sendSmsError: '',
      checkingForOwnerDetails: true,
      ownerCheckboxes: {},
    }
  }

  componentDidMount() {
    this.props.clearError()
    const { fetchPropertyReport, data } = this.props
    this.setState({ checkingForOwnerDetails: true })
    fetchPropertyReport(data.guidID || data.viewingGUID || data.GUID)
      .then((res) => {
        this.setState({ checkingForOwnerDetails: false })
        if (res.owner && res.owner.length) {
          const selectedOwners = res.owner
          this.setState({
            owner: selectedOwners,
            ownerNumberAvailable: true,
            showSendToOwner: true,
            ownerCheckboxes: selectedOwners.reduce((acc, own) => {
              return {
                ...acc,
                [own.guidID]: true,
              }
            }, this.state.ownerCheckboxes),
          })
        }
      })
      .catch((e) => {
        snugNotifier.error(e)
        this.setState({ checkingForOwnerDetails: false })
      })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ sendClicked: false })
    }
  }

  onMobileNumberChange = (field) => {
    return (data) => {
      this.setState({
        mobileNumber: data.value,
        mobileValidationErrors: data.error,
      })
    }
  }

  displaySendToAnotherNumber() {
    this.setState({ showSendToOwner: false, mobileNumber: '' })
  }

  displaySendToOwner() {
    this.setState({
      showSendToOwner: true,
      mobileNumber: '',
    })
  }

  updateSendOwnerCheckbox = (guidID) => {
    this.setState({
      ...this.state,
      ownerCheckboxes: {
        ...this.state.ownerCheckboxes,
        [guidID]: !this.state.ownerCheckboxes[guidID],
      },
    })
  }

  sendSmsReportToOwner() {
    const { data, handleClose } = this.props
    const {
      mobileNumber,
      mobileValidationErrors,
      ownerCheckboxes,
      owner,
      showSendToOwner,
    } = this.state
    let sendToNumbers = []
    const filteredOwners = owner.filter((own) => ownerCheckboxes[own.guidID])
    if (!filteredOwners.length && showSendToOwner) {
      snugNotifier.error('Please select at least one owner')
      return
    }
    if (filteredOwners.length && showSendToOwner) {
      sendToNumbers = filteredOwners.map((own) => own.phone)
    } else {
      sendToNumbers = [mobileNumber]
    }
    if (!showSendToOwner && !mobileNumber) {
      snugNotifier.error('Please enter a mobile number')
      return
    }

    if (mobileValidationErrors !== '') {
      snugNotifier.error(mobileValidationErrors)
      return
    }
    this.setState({
      sendClicked: true,
    })
    this.props
      .sendSmsReport(
        data.guidID || data.viewingGUID || data.GUID,
        sendToNumbers,
      )
      .then(({ isSendSmsReportModalOn }) => {
        if (isSendSmsReportModalOn !== null) {
          handleClose(isSendSmsReportModalOn)
          snugNotifier.success('Report requested successfully')
        }
      })
      .catch((error) => {
        this.setState({ sendClicked: false })
        snugNotifier.error(translateErrorCodeToMessage(error))
      })
      .finally(() => {
        this.setState({
          sendClicked: false,
        })
      })
  }

  updatePhoneNumber = (e) => {
    this.setState({
      showSendToOwner: !this.state.showSendToOwner,
      mobileNumber: '',
    })
  }

  render() {
    const {
      sendClicked,
      owner,
      ownerNumberAvailable,
      sendSmsError,
      showSendToOwner,
      mobileValidationErrors,
      mobileNumber,
      checkingForOwnerDetails,
    } = this.state
    const {
      data = {},
      showUpdatedSMSReportButton = false,
      isViewingReportSmsEnabled,
      viewingReportSMSSent,
      subject = '',
    } = this.props || {}
    const viewingGUID = data.guidID || data.viewingGUID || data.GUID
    const { handleClose, renderAsModal = true } = this.props
    let content = (
      <div>
        <div className="mt20 mb20 text-content input-box width85p">
          <CenteredContainer>
            <Form.InputPhoneNumber
              label="Mobile"
              value={mobileNumber}
              error={mobileValidationErrors}
              onChange={this.onMobileNumberChange()}
              id="mobilePhone"
              numberType={NUMBER_TYPE_MOBILE}
              required
            />
            <div className="preview-sms">
              <div
                data-tooltip-content=""
                data-tooltip-id={viewingGUID}
                className="preview-sms-tooltip"
              >
                <span className="mr10">Preview SMS</span>
                <i className="icon icon-question light-grey-text" />
                <ReactTooltip
                  id={viewingGUID}
                  data-type="info"
                  className="tool-tip-style preview-sms-tooltip"
                >
                  <span>
                    <strong>Example Message: </strong>Hello, We showed 201
                    Sussex St, Sydney at 10AM. Here's a brief report: Registered
                    8, Attended 5, Interested 3. Kind regards, Jane Citizen, JJ
                    Real Estate
                  </span>
                </ReactTooltip>
              </div>
            </div>
          </CenteredContainer>
          {checkingForOwnerDetails && (
            <span className="font-size-16">
              Checking for owner details
              <i className={'fa fa-spinner fa-pulse'} />
            </span>
          )}
        </div>
        {ownerNumberAvailable && (
          <div className="mt10 mb20 text-content">
            <p>
              <span
                onClick={() => this.displaySendToOwner()}
                className="text-decoration-underline"
              >
                Send to owner
              </span>
            </p>
          </div>
        )}
      </div>
    )
    if (!checkingForOwnerDetails && showSendToOwner) {
      content = (
        <div>
          <div className="mt10 mb20 text-content">
            {owner &&
              owner.length > 0 &&
              owner.map((own) => (
                <Box mt={8}>
                  <label className="mr10" key="">
                    <Flex alignItems="center">
                      <input
                        id="mobile"
                        type="checkbox"
                        checked={this.state.ownerCheckboxes[own.guidID]}
                        value=""
                        onChange={() =>
                          this.updateSendOwnerCheckbox(own.guidID)
                        }
                      />

                      <Box ml={6}>
                        <BaseContactDetails
                          fullName={own.firstName + ' ' + own.lastName}
                          email={own.email}
                          contactNumber={own.phone}
                          subject={subject}
                        />
                      </Box>
                    </Flex>
                  </label>
                </Box>
              ))}
          </div>
          <div className="mt10 mb20 text-content">
            <p>
              <span
                onClick={() => this.displaySendToAnotherNumber()}
                className="text-decoration-underline"
              >
                Send to another number
              </span>
            </p>
          </div>
        </div>
      )
    }

    return (
      <div>
        {renderAsModal ? (
          <div className="modal-fullscreen modal-layout-flex-container">
            <ModalContentWrapper className="sms-report">
              <div className="modal-close">
                <i className="fa fa-times" onClick={() => handleClose(false)} />
              </div>
              <div className="modal-header display-flex justify-content-space-around align-items-center">
                <div className="font-size-24 fw500 line-height-24">
                  Send Viewing Summary SMS
                </div>
              </div>
              <div className="modal-body p15">
                <div className="mt10 fw400 font-size-16 line-height-24 text-align-left text-content width85p">
                  We Notify the owner via SMS the viewing is finished, the
                  number of registrations and attendees.
                </div>
                {content}
                {sendSmsError && (
                  <div className="mt10 mb20 text-content width85p">
                    <div className="alert alert-danger">{sendSmsError}</div>
                  </div>
                )}
              </div>
              <div className="width100 display-flex modal-bottom-btn-container">
                <div className="button-default secondary width100 mt10 margin-profile-item-left col-sm-6">
                  <button onClick={() => handleClose(false)}>Cancel</button>
                </div>
                <div className="button-default  width100 mt10 margin-profile-item-right col-sm-6">
                  <button
                    disabled={false}
                    className="btn"
                    onClick={() => this.sendSmsReportToOwner()}
                  >
                    Send
                    <i
                      className={sendClicked ? 'fa fa-spinner fa-pulse' : ''}
                    />
                  </button>
                </div>
              </div>
            </ModalContentWrapper>
          </div>
        ) : (
          <div>
            <div className="mt10 fw400 font-size-16 line-height-24 text-align-left text-content width85p">
              We Notify the owner via SMS the viewing is finished, the number of
              registrations and attendees.
            </div>
            {content}
            {sendSmsError && (
              <div className="mt10 mb20 text-content width85p">
                <div className="alert alert-danger">{sendSmsError}</div>
              </div>
            )}
            <div className="width100 display-flex modal-bottom-btn-container">
              <div className="button-default  width100 mt10 margin-profile-item-right col-sm-6">
                {showUpdatedSMSReportButton ? (
                  <>
                    <SendSMSReportButton
                      isViewingReportSmsEnabled={isViewingReportSmsEnabled}
                      viewingReportSMSSent={viewingReportSMSSent}
                      buttonOnClick={() => this.sendSmsReportToOwner()}
                      disabled={sendClicked}
                      viewingGUID={viewingGUID}
                      // buttonClassName="hollow-button-green"
                      showSpinner={sendClicked}
                    />
                  </>
                ) : (
                  <>
                    <button
                      disabled={false}
                      className="btn"
                      onClick={() => this.sendSmsReportToOwner()}
                    >
                      Send
                      <i
                        className={sendClicked ? 'fa fa-spinner fa-pulse' : ''}
                      />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default SendSmsReport
