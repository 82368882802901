import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Box, TextSpan } from 'app/components/design-system-components'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import { portalBrands } from 'app/constants/portals.constants'
import PortalActivity, {
  useActivatePortal,
  useDeactivatePortal,
  usePortalStatus,
} from 'app/IntegrationPortals'
import theme from 'app/match/applicationReportPDF/assets/theme'
import ActivationConfirmationModal from 'app/pages/DomainPortal/ActivationConfirmationModal'
import * as portalHttpHelpers from 'app/services/http/portals'
import * as snugNotifier from 'app/services/snugNotifier'
import { domainIntegrationLearnMore } from 'config/external-links'

const domainBrandId = portalBrands.domain.id
const domainBrandLogo = portalBrands.domain.logo

const DomainPortal = ({ currentTeam }) => {
  const { loadPortalStatus, portalStatusInfo, portalStatusLoadingStates } =
    usePortalStatus(currentTeam, domainBrandId)

  const { activatePortal, activatePortalLoadingStates } = useActivatePortal(
    currentTeam,
    domainBrandId,
  )

  const [activationConfirmationModalOpen, setActivationConfirmationModalOpen] =
    useState(false)
  const toggleAactivationConfirmationModalOpen = () => {
    setActivationConfirmationModalOpen(!activationConfirmationModalOpen)
  }

  const [savedPayloadFromInitialCall, setSavedPayloadFromInitialCall] =
    useState({})

  const [otherTeamsWithSameDomainIDInfo, setOtherTeamsWithSameDomainIDInfo] =
    useState([])

  const [isAdminForCurrentAndOtherTeams, setIsAdminForCurrentAndOtherTeams] =
    useState(false)

  const { deactivatePortal, deactivatePortalLoadingStates } =
    useDeactivatePortal(currentTeam, domainBrandId)

  useEffect(() => {
    loadPortalStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const statusProps = {
    statusInfo: portalStatusInfo,
    statusLoadingStates: portalStatusLoadingStates,
    reloadStatusInfo: loadPortalStatus,
  }

  const activationProps = {
    activationFn: (payload) => {
      const updatedPayload = { ...payload, confirmUpdate: false }
      activatePortal(updatedPayload)
        .then((data) => {
          if (data && data?.otherTeams) {
            toggleAactivationConfirmationModalOpen()
            setSavedPayloadFromInitialCall(payload)
            setOtherTeamsWithSameDomainIDInfo(data?.otherTeams)
            setIsAdminForCurrentAndOtherTeams(
              Boolean(data?.isAdminForCurrentAndOtherTeams),
            )
            loadPortalStatus()
            return
          }
          loadPortalStatus()
        })
        .catch(({ message }) => {
          snugNotifier.error(message)
        })
    },
    activateLoadingStates: activatePortalLoadingStates,
  }

  const deactivateProps = {
    deactivationFn: () =>
      deactivatePortal()
        .then(() => loadPortalStatus())
        .catch(({ message }) => snugNotifier.error(message)),
    deactivateLoadingStates: deactivatePortalLoadingStates,
  }

  const continueWithActivationFn = () => {
    const payload = {
      ...savedPayloadFromInitialCall,
      confirmUpdate: true,
    }

    portalHttpHelpers
      .activatePortal(currentTeam?.guid, domainBrandId, payload)
      .then(() => {
        loadPortalStatus()
        toggleAactivationConfirmationModalOpen()
      })
      .catch(({ message }) => {
        snugNotifier.error(message)
      })
  }

  const actInstructionsCmp = <>Enter your Domain.com.au Agency ID</>

  return (
    <>
      <PortalActivity
        portalBrand={domainBrandId}
        logo={domainBrandLogo}
        statusProps={statusProps}
        activateProps={activationProps}
        deactivateProps={deactivateProps}
        activationInstructions={actInstructionsCmp}
      />
      <Box mt={5} fontSize={theme.fontSizes.pRegular14}>
        <TextSpan as="span">
          Enable applications and viewing bookings directly from Domain.com.au.
          T&Cs apply.{' '}
        </TextSpan>
        <a target="_blank" href={domainIntegrationLearnMore} rel="noreferrer">
          Learn more
        </a>
      </Box>
      {activationConfirmationModalOpen && (
        <ActivationConfirmationModal
          toggleActivationConfirmationModal={
            toggleAactivationConfirmationModalOpen
          }
          continueWithActivationFn={continueWithActivationFn}
          otherTeamsWithSameDomainIDInfo={otherTeamsWithSameDomainIDInfo}
          isAdminForCurrentAndOtherTeams={isAdminForCurrentAndOtherTeams}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
})

const DomainPortalPage = withRouter(connect(mapStateToProps)(DomainPortal))

export default DomainPortalPage
