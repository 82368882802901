import React from 'react'

import { Link } from 'react-router-dom'

import headerImg05 from 'app/assets/icons/header-img-05.png'
import { urlTo } from 'app/sm/helpers'

const BankAccountForm = ({
  address,
  quote,
  accountName,
  bsb,
  accountNumber,
  disabled,
  clicked,
  update,
  trimField,
  submit,
  errors,
  apiError,
  requestID,
}) => (
  <div className="two-col-box-flex-row sm64">
    <div className="col-first">
      <div className="mobile-only">
        <Link
          to={urlTo('bondCoverLeaseeSign', { requestID })}
          className="btn btn-transparent btn-left xs-text-center wa"
        >
          <i className="icon-arrow-left left"></i>
          <span>Back</span>
        </Link>
      </div>
      <div className="ilustration-box">
        <img src={headerImg05} />
      </div>

      <h3 className="mb15">Where do you want your bond refund to go?</h3>
      <p>
        Once your refund is approved by your property manager and received from
        the bond authority, the household refund of{' '}
        <b>
          $
          {quote && quote.guidID && quote.bondAmount - quote.premium.toFixed(2)}
        </b>{' '}
        will be split and deposited into the nominated tenant accounts.
      </p>

      <ul className="snug-infos">
        <li>Snug uses bank-level 256-bit security to protect your data.</li>
      </ul>

      <div className="input-box">
        <input
          type="text"
          required
          id="accountName"
          className={errors.accountName ? 'with-example error' : 'with-example'}
          value={accountName}
          placeholder="Eg. Jane Doe"
          onChange={update('accountName')}
          onBlur={trimField('accountName')}
        />
        <label>Account Name</label>
      </div>

      <div className="input-box">
        <input
          type="text"
          required
          id="bsb"
          className={errors.bsb ? 'error' : ''}
          value={bsb}
          onChange={update('bsb')}
          onBlur={trimField('bsb')}
        />
        <label>BSB</label>
      </div>

      <div className="input-box">
        <input
          type="text"
          required
          id="accountNumber"
          className={errors.accountNumber ? 'error' : ''}
          value={accountNumber}
          onChange={update('accountNumber')}
          onBlur={trimField('accountNumber')}
        />
        <label>Account No.</label>
      </div>

      <div
        className={
          errors.accountName || errors.bsb || errors.accountNumber || apiError
            ? 'alert alert-danger'
            : ''
        }
      >
        <div>{errors.accountName}</div>
        <div>{errors.bsb}</div>
        <div>{errors.accountNumber}</div>
        <div>{apiError}</div>
      </div>
      <div className="row pt25 mobile-form-button">
        <div className="col-sm-5 pb5 tablet-only">
          <Link
            to={urlTo('bondCoverLeaseeSign', { requestID })}
            className="btn btn-transparent btn-left xs-text-center wa"
          >
            <i className="icon-arrow-left left"></i>
            <span>Back</span>
          </Link>
        </div>
        <div className="col-sm-7 pb5">
          <button className="btn" disabled={disabled} onClick={submit}>
            Save
            <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default BankAccountForm
