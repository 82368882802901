import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

const BankAccount = ({ bankAccount, request, currentLease, role }) => {
  let stepNumber = 5
  if (role === 'secondary') {
    stepNumber = 2
  } else if (currentLease && currentLease.isMultiTenantLease) {
    stepNumber = 6
  }

  let requestID = ''
  if (request) {
    requestID = request.guidID
  }

  let accountHtml = null,
    id = ''
  if (bankAccount && bankAccount.accountNumber) {
    let { guidID, accountName, bankName, bsb, accountNumber } = bankAccount
    id = guidID
    accountHtml = (
      <p>
        Account Name: {accountName}
        <br />
        BSB: {bsb}
        <br />
        Account Number: {accountNumber}
      </p>
    )
  }
  return (
    <div>
      {(request && request.submissionStatus !== 2) || role === 'secondary' ? (
        <Link
          className={
            accountHtml ? 'bc-hub-item-link completed' : 'bc-hub-item-link'
          }
          to={
            role === 'primary'
              ? '/bc/bankAccount?requestid=' + requestID + '&id=' + id
              : '/bc/secondary/leaseeBankAccount?requestid=' +
                requestID +
                '&id=' +
                id
          }
        >
          <header>
            <i className="icon-bank" />
            <span>{stepNumber}. Payment Details</span>
          </header>
          <content>{accountHtml}</content>
        </Link>
      ) : (
        <div
          className={
            request
              ? accountHtml
                ? 'bc-hub-item-link completed'
                : 'bc-hub-item-link'
              : 'bc-hub-item-link disabled'
          }
        >
          <header>
            <i className="icon-bank" />
            <span>{stepNumber}. Payment Details</span>
          </header>
          <content>{accountHtml}</content>
        </div>
      )}
    </div>
  )
}

export default BankAccount
