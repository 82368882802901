import React from 'react'

import { isEqual } from 'lodash'
import qs from 'qs'
import { withRouter } from 'react-router-dom'

import { Box, Button, Flex } from 'app/components/design-system-components'
import { AppliedFilter } from 'app/components/design-system-components/list-filters/AppliedFilter'
import { Text } from 'app/components/design-system-components/typography'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { defaultPropertyFilters } from 'app/dashboard/team_overview_reducer'
import theme from 'app/match/applicationReportPDF/assets/theme'
import AgencyFilters from 'app/pages/teams/viewings-mobile/components/AgencyFilter/connection'
import FASpinner from 'app/pages/teams/viewings-mobile/components/FASpinner'
import PropertyViewingCmp from 'app/pages/teams/viewings-mobile/components/PropertyViewings/index'
import Theme from 'app/pages/teams/viewings-mobile/theme-styled-components'
import * as snugNotifier from 'app/services/snugNotifier'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'
import * as viewingsHelpers from 'app/utils/viewings/helpers'

const PropertyViewing = withRouter(PropertyViewingCmp)

const DEFAULT_VIEWINGS_TYPE = viewingsHelpers.VIEWINGS_BY_DATE
const FirstPageSize = 10
const PageSize = 5
const BACK_TO_OLD_VERSION_MESSAGE = 'Back to old version'

class MobileViewings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewingEntryNoticeEnabled: false,
      propertiesFetchDone: false,
      loadingPreloadData: false,
      archiveTime: 0,
      viewingsType: DEFAULT_VIEWINGS_TYPE,
      currentViewingDate: '',
      filtersEnabled: false,
      pageSize: FirstPageSize, // pre-load api pagination
      pageOffset: 0,
      isViewingReportSmsEnabled: false,
      isViewingReportEmailEnabled: false,
      filtersState: {
        hasAppliedFilters: false,
        params: {},
      },
    }
  }

  componentDidMount() {
    const { currentTeam = {}, fetchTeamSettingInfo } = this.props
    const { viewingsType } = this.state

    const preloadDataPromise = (() => {
      if (viewingsType !== viewingsHelpers.VIEWINGS_BY_DATE) {
        return Promise.resolve()
      }

      return this.loadPreloadData()
    })()

    preloadDataPromise.then(() => this.onFiltersPropsUpdated())

    fetchTeamSettingInfo(currentTeam.guid, 'notifications')
      .then(({ notifications }) => {
        const {
          viewing_entry_notice_enabled: viewingEntryNoticeEnabled = false,
        } = notifications
        this.setState({
          viewingEntryNoticeEnabled: viewingEntryNoticeEnabled === 'true',
        })
      })
      .catch((error) => {
        this.setState({ error })
      })
    fetchTeamSettingInfo(currentTeam.guid, 'viewings')
      .then(({ viewings }) => {
        const {
          viewing_slot_enabled: isViewingSlotEnabled = false,
          viewing_qr_auto_checkin_enabled: viewingQRAutoCheckinEnabled = false,
        } = viewings
        this.setState({
          isViewingSlotEnabled: isViewingSlotEnabled === 'true',
          viewingQRAutoCheckinEnabled: viewingQRAutoCheckinEnabled === 'true',
        })
      })
      .catch((error) => {
        this.setState({ error })
      })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      fetchTeamSettingInfo,
      preloadFetchErrors,
      currentTeam = {},
      preloadedPropertiesAndViewings: { dataOffset },
      resetData,
    } = this.props
    const { viewingsType, loadingPreloadData, archiveTime, filtersState } =
      this.state

    if (this.isFilterPropsUpdated(prevProps, prevState)) {
      this.onFiltersPropsUpdated()
    }

    const { hasAppliedFilters, params } = filtersState || {}
    if (
      (hasAppliedFilters && !isEqual(prevState.filtersState?.params, params)) ||
      hasAppliedFilters !== prevState.filtersState.hasAppliedFilters
    ) {
      resetData()
      this.setState(
        { pageOffset: 0, pageSize: FirstPageSize },
        this.loadPreloadData,
      )
    }

    if (
      prevState.viewingsType !== viewingsType &&
      !hasAppliedFilters &&
      !loadingPreloadData
    ) {
      resetData()
      this.setState(
        {
          pageSize: FirstPageSize,
          pageOffset: 0,
        },
        this.loadPreloadData,
      )
    }

    if (
      (prevProps.currentTeam !== currentTeam ||
        prevState.archiveTime !== archiveTime) &&
      !hasAppliedFilters &&
      !loadingPreloadData
    ) {
      this.loadPreloadData()
    }

    if (
      prevProps.preloadFetchErrors !== preloadFetchErrors &&
      preloadFetchErrors.length
    ) {
      preloadFetchErrors.forEach(({ code, message }) => {
        if (!!code) this.showErrorMessage(translateErrorCodeToMessage(code))
        else if (!!message) this.showErrorMessage(message)
      })
    }

    if (prevProps.currentTeam !== currentTeam && currentTeam.guid) {
      fetchTeamSettingInfo(currentTeam.guid, 'viewings,notifications').then(
        ({ viewings, notifications }) => {
          const {
            viewing_entry_notice_enabled: viewingEntryNoticeEnabled = false,
            viewing_qr_auto_checkin_enabled:
              viewingQRAutoCheckinEnabled = false,
          } = notifications
          const {
            viewing_report_sms_enabled: isViewingReportSmsEnabled = false,
            viewing_report_email_enabled: isViewingReportEmailEnabled = false,
            viewing_slot_enabled: isViewingSlotEnabled = false,
          } = viewings
          this.setState({
            viewingEntryNoticeEnabled: viewingEntryNoticeEnabled === 'true',
            isViewingReportSmsEnabled: isViewingReportSmsEnabled === 'true',
            isViewingSlotEnabled: isViewingSlotEnabled === 'true',
            isViewingReportEmailEnabled: isViewingReportEmailEnabled === 'true',
            viewingQRAutoCheckinEnabled: viewingQRAutoCheckinEnabled === 'true',
          })
        },
      )
    }

    if (prevProps.preloadedPropertiesAndViewings.dataOffset !== dataOffset) {
      this.setState({ pageOffset: dataOffset })
    }
  }

  loadPreloadData = () => {
    const { fetchPreloadedPropertiesAndViewingsData, currentTeam } = this.props
    const {
      pageSize,
      viewingsType,
      pageOffset,
      filtersEnabled,
      filtersState: { params },
    } = this.state

    const apiParams = {
      offset: pageOffset,
      limit: pageSize,
      entity: viewingsType,
      searchParams: filtersEnabled ? params : undefined,
    }

    this.setState({ loadingPreloadData: true })
    return fetchPreloadedPropertiesAndViewingsData(currentTeam.guid, apiParams)
      .then(() => {
        this.setState({
          propertiesFetchDone: true,
          pageSize: PageSize,
        })
      })
      .finally(() => this.setState({ loadingPreloadData: false }))
  }

  isFilterPropsUpdated = (prevProps, prevState) => {
    const queryParamsChange =
      prevProps.location?.search !== this.props.location?.search

    const { viewingsType } = this.state
    const viewingTypeChange = viewingsType !== prevState.viewingsType

    const globalFiltersChange = !isEqual(prevProps.filters, this.props.filters)

    return queryParamsChange || globalFiltersChange || viewingTypeChange
  }

  onFiltersPropsUpdated = () => {
    const queryParams = qs.parse(this.props.location?.search, {
      ignoreQueryPrefix: true,
    })
    const { property } = queryParams || {}
    const { filters: systemFilters } = this.props

    const hasAppliedSystemFilters = !isEqual(
      defaultPropertyFilters,
      systemFilters,
    )
    const hasAppliedFilters = hasAppliedSystemFilters || property

    this.setState((state) => ({
      ...state,
      filtersEnabled: hasAppliedFilters,
      filtersState: {
        hasAppliedFilters,
        params: {
          property,
          searchText: systemFilters.searchText,
          sortBy:
            state.viewingsType === viewingsHelpers.VIEWINGS_BY_DATE
              ? viewingsHelpers.SORT_BY_DATE
              : viewingsHelpers.SORT_BY_ADDRESS,
          datePickerValue: systemFilters.datePickerValue,
          todayOnly: systemFilters.todayOnly,
          assigned: systemFilters.manager,
        },
      },
    }))
  }

  archiveTimeAdder = () => {
    this.setState({ archiveTime: this.state.archiveTime + 1 })
  }

  changeViewingsByType = (viewingType) => {
    this.setState({
      viewingsType: viewingType,
    })
  }

  resetPropertyFilterInViewings = () => {
    const { history } = this.props
    this.setState(
      {
        filtersEnabled: false,
      },
      () => history.push({ search: '' }),
    )
  }

  showErrorMessage = (errorMessage) => {
    snugNotifier.error(errorMessage)
  }

  resetPropertyFilter = () => {
    const { history } = this.props
    history.push({ search: '' })
  }

  renderAppliedPropertyFilter = () => {
    const { showSpinner } = this.props
    if (showSpinner) return null

    const { hasAppliedFilters, params } = this.state.filtersState || {}
    if (!hasAppliedFilters || !params?.property) return null

    const { preloadedPropertiesAndViewings, displayedPropertyOffers } =
      this.props

    if (!(displayedPropertyOffers || []).length) return null

    const propertyData =
      preloadedPropertiesAndViewings.dataPropertiesCurrent[
        displayedPropertyOffers[0]
      ]

    return (
      <AppliedFilter
        filterTitle="Property"
        onDismiss={() => this.resetPropertyFilter()}
        renderedAppliedFilters={propertyData?.property.address.friendlyName}
      />
    )
  }

  renderPropertyViewing = (offerGUID) => {
    const {
      fetchPropertyViewingsData,
      addViewing,
      editViewing,
      addWalkInRenter,
      currentTeam,
      showCommentModal,
      updateRenterStatus,
      notifyLateViewing,
      assignViewingToTeamMember,
      fetchViewingTeamMembers,
      deleteViewingTeamMember,
      showLateViewingModal,
      sendIndividualInviteToApply,
      removeOnsiteViewingRenter,
      clearError,
      setBackUrl,
      requestFeedback,
      archiveProperty,
      cancelViewing,
      fetchCurrentTenants,
      fetchCurrentOwners,
      addCurrentTenants,
      addCurrentOwners,
      updateCurrentTenants,
      updateCurrentOwners,
      removeCurrentTenants,
      removeCurrentOwners,
      fetchPropertyViewingsReportData,
      updateViewingTeamMemberRelation,
      getManagersListForViewingFilters,
      sendInviteToApplyToAllRegistrants,
      fetchAgencyApplicantsElasticSearchResult,
      toggleWalkInModal,
      preloadedPropertiesAndViewings,
      sendEntryNotice,
      viewingsErrors,
      addViewingSuccess,
      fetchRegistrantsForViewing,
      createNote,
      dataRegistrantsCurrent,
      location: { search },
    } = this.props
    const {
      viewingEntryNoticeEnabled,
      isViewingReportSmsEnabled,
      isViewingReportEmailEnabled,
      isViewingSlotEnabled,
      viewingsType,
      viewingQRAutoCheckinEnabled,
    } = this.state

    const { highlightedViewingId } = qs.parse(search || '') || {}
    const propertyData =
      preloadedPropertiesAndViewings.dataPropertiesCurrent[offerGUID]
    const { allViewings } = propertyData

    return (
      <PropertyViewing
        propertyData={propertyData}
        allViewings={allViewings}
        fetchPropertyViewingsData={fetchPropertyViewingsData}
        key={offerGUID}
        addViewing={addViewing}
        editViewing={editViewing}
        addWalkInRenter={addWalkInRenter}
        toggleWalkInModal={toggleWalkInModal}
        fetchAgencyApplicantsElasticSearchResult={
          fetchAgencyApplicantsElasticSearchResult
        }
        currentTeam={currentTeam}
        clearError={clearError}
        showCommentModal={showCommentModal}
        removeOnsiteViewingRenter={removeOnsiteViewingRenter}
        updateRenterStatus={updateRenterStatus}
        sendIndividualInviteToApply={sendIndividualInviteToApply}
        showLateViewingModal={showLateViewingModal}
        notifyLateViewing={notifyLateViewing}
        assignViewingToTeamMember={assignViewingToTeamMember}
        fetchViewingTeamMembers={fetchViewingTeamMembers}
        deleteViewingTeamMember={deleteViewingTeamMember}
        updateViewingTeamMemberRelation={updateViewingTeamMemberRelation}
        getManagersListForViewingFilters={getManagersListForViewingFilters}
        sendInviteToApplyToAllRegistrants={sendInviteToApplyToAllRegistrants}
        setBackUrl={setBackUrl}
        requestFeedback={requestFeedback}
        cancelViewing={cancelViewing}
        fetchPropertyViewingsReportData={fetchPropertyViewingsReportData}
        fetchCurrentTenants={fetchCurrentTenants}
        fetchCurrentOwners={fetchCurrentOwners}
        addCurrentTenants={addCurrentTenants}
        addCurrentOwners={addCurrentOwners}
        updateCurrentTenants={updateCurrentTenants}
        updateCurrentOwners={updateCurrentOwners}
        removeCurrentTenants={removeCurrentTenants}
        removeCurrentOwners={removeCurrentOwners}
        sendEntryNotice={sendEntryNotice}
        viewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
        isViewingReportSmsEnabled={isViewingReportSmsEnabled}
        isViewingReportEmailEnabled={isViewingReportEmailEnabled}
        isViewingSlotEnabled={isViewingSlotEnabled}
        archiveProperty={archiveProperty}
        archiveTimeAdder={this.archiveTimeAdder}
        preloadedPropertiesAndViewings={preloadedPropertiesAndViewings}
        viewingsType={viewingsType}
        viewingsErrors={viewingsErrors}
        addViewingSuccess={addViewingSuccess}
        fetchRegistrantsForViewing={fetchRegistrantsForViewing}
        dataRegistrantsCurrent={dataRegistrantsCurrent}
        createNote={createNote}
        highlightedViewingId={highlightedViewingId}
        viewingQRAutoCheckinEnabled={viewingQRAutoCheckinEnabled}
      />
    )
  }

  renderViewing = (viewingGUID, idx) => {
    const {
      fetchPropertyViewingsData,
      addViewing,
      editViewing,
      addWalkInRenter,
      currentTeam,
      showCommentModal,
      updateRenterStatus,
      notifyLateViewing,
      assignViewingToTeamMember,
      fetchViewingTeamMembers,
      deleteViewingTeamMember,
      showLateViewingModal,
      sendIndividualInviteToApply,
      removeOnsiteViewingRenter,
      clearError,
      setBackUrl,
      requestFeedback,
      archiveProperty,
      cancelViewing,
      fetchCurrentTenants,
      fetchCurrentOwners,
      addCurrentTenants,
      addCurrentOwners,
      updateCurrentTenants,
      updateCurrentOwners,
      removeCurrentTenants,
      removeCurrentOwners,
      fetchPropertyViewingsReportData,
      updateViewingTeamMemberRelation,
      getManagersListForViewingFilters,
      sendInviteToApplyToAllRegistrants,
      fetchAgencyApplicantsElasticSearchResult,
      toggleWalkInModal,
      preloadedPropertiesAndViewings,
      sendEntryNotice,
      viewingsErrors,
      addViewingSuccess,
      fetchRegistrantsForViewing,
      createNote,
      dataRegistrantsCurrent,
      viewingsByDate,
      location: { search },
    } = this.props
    const {
      viewingEntryNoticeEnabled,
      isViewingReportSmsEnabled,
      isViewingReportEmailEnabled,
      isViewingSlotEnabled,
      viewingsType,
      viewingQRAutoCheckinEnabled,
    } = this.state

    const { highlightedViewingId } = qs.parse(search || '') || {}
    const startDate =
      preloadedPropertiesAndViewings.allViewings[viewingGUID].startDate
    const startDateCmp = dateTimeHelpers.convertDateToDayAndDate(startDate)
    const prevDate =
      idx === 0
        ? ''
        : dateTimeHelpers.getDateWithoutYear(
            preloadedPropertiesAndViewings.allViewings[viewingsByDate[idx - 1]]
              .startDate,
          )
    const showDate = startDateCmp.dateWithoutYear !== prevDate
    const propertyData =
      preloadedPropertiesAndViewings.dataPropertiesCurrent[
        preloadedPropertiesAndViewings.allViewings[viewingGUID]['offerGUID']
      ]

    return (
      <Box mt={5} key={viewingGUID}>
        <Text
          as="span"
          mb={3}
          lineHeight={theme.space[8] + 'px'}
          fontSize={theme.fontSizes.pSurplusLarge24}
          fontWeight={theme.fontWeights.bold}
        >
          {showDate && `${startDateCmp.day} (${startDateCmp.dateWithoutYear})`}
        </Text>
        <PropertyViewing
          propertyData={propertyData}
          allViewings={[viewingGUID]}
          fetchPropertyViewingsData={fetchPropertyViewingsData}
          key={viewingGUID}
          addViewing={addViewing}
          editViewing={editViewing}
          addWalkInRenter={addWalkInRenter}
          toggleWalkInModal={toggleWalkInModal}
          fetchAgencyApplicantsElasticSearchResult={
            fetchAgencyApplicantsElasticSearchResult
          }
          currentTeam={currentTeam}
          clearError={clearError}
          showCommentModal={showCommentModal}
          removeOnsiteViewingRenter={removeOnsiteViewingRenter}
          updateRenterStatus={updateRenterStatus}
          sendIndividualInviteToApply={sendIndividualInviteToApply}
          showLateViewingModal={showLateViewingModal}
          notifyLateViewing={notifyLateViewing}
          assignViewingToTeamMember={assignViewingToTeamMember}
          fetchViewingTeamMembers={fetchViewingTeamMembers}
          deleteViewingTeamMember={deleteViewingTeamMember}
          updateViewingTeamMemberRelation={updateViewingTeamMemberRelation}
          getManagersListForViewingFilters={getManagersListForViewingFilters}
          sendInviteToApplyToAllRegistrants={sendInviteToApplyToAllRegistrants}
          setBackUrl={setBackUrl}
          requestFeedback={requestFeedback}
          cancelViewing={cancelViewing}
          fetchPropertyViewingsReportData={fetchPropertyViewingsReportData}
          fetchCurrentTenants={fetchCurrentTenants}
          fetchCurrentOwners={fetchCurrentOwners}
          addCurrentTenants={addCurrentTenants}
          addCurrentOwners={addCurrentOwners}
          updateCurrentTenants={updateCurrentTenants}
          updateCurrentOwners={updateCurrentOwners}
          removeCurrentTenants={removeCurrentTenants}
          removeCurrentOwners={removeCurrentOwners}
          sendEntryNotice={sendEntryNotice}
          viewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
          isViewingReportSmsEnabled={isViewingReportSmsEnabled}
          isViewingReportEmailEnabled={isViewingReportEmailEnabled}
          isViewingSlotEnabled={isViewingSlotEnabled}
          archiveProperty={archiveProperty}
          archiveTimeAdder={this.archiveTimeAdder}
          preloadedPropertiesAndViewings={preloadedPropertiesAndViewings}
          viewingsType={viewingsType}
          viewingsErrors={viewingsErrors}
          addViewingSuccess={addViewingSuccess}
          fetchRegistrantsForViewing={fetchRegistrantsForViewing}
          dataRegistrantsCurrent={dataRegistrantsCurrent}
          createNote={createNote}
          highlightedViewingId={highlightedViewingId}
          viewingQRAutoCheckinEnabled={viewingQRAutoCheckinEnabled}
        />
      </Box>
    )
  }

  render() {
    const {
      preloadedPropertiesAndViewings: { totalRecords } = {},
      showSpinner,
      viewingsByDate = [],
      displayedPropertyOffers,
    } = this.props
    const { propertiesFetchDone, viewingsType, filtersEnabled, pageOffset } =
      this.state

    const totalNumberPropertyOffers = displayedPropertyOffers.length
    const totalNumberViewingsByDate = viewingsByDate.length
    const paginationCheckAgainstNumber =
      viewingsType === viewingsHelpers.VIEWINGS_BY_PROPERTY
        ? totalNumberPropertyOffers
        : totalNumberViewingsByDate

    const showMoreBtn =
      totalRecords > 0 &&
      pageOffset <= totalRecords &&
      paginationCheckAgainstNumber <= pageOffset &&
      !showSpinner

    return (
      <Theme>
        <div>
          <AgencyFilters
            enableViewingSearchManager={true}
            changeViewingsByType={this.changeViewingsByType}
            currentViewingsType={viewingsType}
            resetPropertyFilterInViewings={this.resetPropertyFilterInViewings}
          />

          {this.renderAppliedPropertyFilter()}

          {displayedPropertyOffers &&
            viewingsType === viewingsHelpers.VIEWINGS_BY_PROPERTY &&
            displayedPropertyOffers.map(this.renderPropertyViewing)}

          {viewingsByDate &&
            displayedPropertyOffers &&
            viewingsType === viewingsHelpers.VIEWINGS_BY_DATE &&
            viewingsByDate.map(this.renderViewing)}
          {showSpinner && <FASpinner />}

          {showMoreBtn && (
            <Flex justifyContent="center">
              <Button variant="outlineSuccess" onClick={this.loadPreloadData}>
                Show more
              </Button>
            </Flex>
          )}

          {propertiesFetchDone &&
            !paginationCheckAgainstNumber &&
            !showSpinner &&
            (!viewingsByDate || filtersEnabled) && (
              <div className="mt30">No viewings found</div>
            )}
          {propertiesFetchDone &&
            !paginationCheckAgainstNumber &&
            !showSpinner &&
            viewingsByDate &&
            !filtersEnabled && (
              <div className="mt30">
                No Future Viewings found, filter by{' '}
                <span
                  className="blue-link-style"
                  onClick={() =>
                    this.changeViewingsByType(
                      viewingsHelpers.VIEWINGS_BY_PROPERTY,
                    )
                  }
                >
                  Properties
                </span>
              </div>
            )}
        </div>
      </Theme>
    )
  }
}

export default MobileViewings
