import React from 'react'

import ActivityRowField from 'app/agency/advance_activity/activity_row_field'

import 'app/agency/advance_activity/style.scss'

const RentAchievedRows = ({
  mergedRentAchievedActivities,
  fieldLabelClass = '',
  percentFieldLabelClass = '',
  dataFieldClass = '',
  dataPercentFieldClass = '',
  dataFieldContainer = '',
  dataPercentFieldContainer = '',
}) => {
  return (
    <div>
      <div className="advance-activity-content-list-row">
        <ActivityRowField
          widthClass={fieldLabelClass}
          fieldClass="mb0"
          field="Rent achieved as listed"
        />
        {mergedRentAchievedActivities && (
          <div className={`${dataFieldContainer} display-flex`}>
            {mergedRentAchievedActivities.map((act, index) => {
              return (
                <ActivityRowField
                  key={index}
                  widthClass={dataFieldClass}
                  fieldClass="mb0 mr-auto ml-auto"
                  field={act.asListedCount || 0}
                />
              )
            })}
          </div>
        )}
      </div>
      <div className="advance-activity-content-list-row grey-background">
        <ActivityRowField
          widthClass={percentFieldLabelClass}
          fieldClass="mb0"
          field="Rent achieved as listed percentage"
        />
        {mergedRentAchievedActivities && (
          <div className={`${dataPercentFieldContainer} display-flex`}>
            {mergedRentAchievedActivities.map((act, index) => {
              return (
                <ActivityRowField
                  key={index}
                  widthClass={dataPercentFieldClass}
                  fieldClass="mb0 mr-auto ml-auto"
                  field={
                    act.asListedPercentage ? `${act.asListedPercentage}%` : '0%'
                  }
                />
              )
            })}
          </div>
        )}
      </div>
      <div className="advance-activity-content-list-row">
        <ActivityRowField
          widthClass={fieldLabelClass}
          fieldClass="mb0"
          field="Rent achieved above last listed"
        />
        {mergedRentAchievedActivities && (
          <div className={`${dataFieldContainer} display-flex`}>
            {mergedRentAchievedActivities.map((act, index) => {
              return (
                <ActivityRowField
                  key={index}
                  widthClass={dataFieldClass}
                  fieldClass="mb0 mr-auto ml-auto"
                  field={act.aboveListedCount || 0}
                />
              )
            })}
          </div>
        )}
      </div>
      <div className="advance-activity-content-list-row grey-background">
        <ActivityRowField
          widthClass={percentFieldLabelClass}
          fieldClass="mb0"
          field="Rent achieved above last listed percentage"
        />
        {mergedRentAchievedActivities && (
          <div className={`${dataPercentFieldContainer} display-flex`}>
            {mergedRentAchievedActivities.map((act, index) => {
              return (
                <ActivityRowField
                  key={index}
                  widthClass={dataPercentFieldClass}
                  fieldClass="mb0 mr-auto ml-auto"
                  field={
                    act.aboveListedPercentage
                      ? `${act.aboveListedPercentage}%`
                      : '0%'
                  }
                />
              )
            })}
          </div>
        )}
      </div>
      <div className="advance-activity-content-list-row">
        <ActivityRowField
          widthClass={fieldLabelClass}
          fieldClass="mb0"
          field="Rent achieved below last listed"
        />
        {mergedRentAchievedActivities && (
          <div className={`${dataFieldContainer} display-flex`}>
            {mergedRentAchievedActivities.map((act, index) => {
              return (
                <ActivityRowField
                  key={index}
                  widthClass={dataFieldClass}
                  fieldClass="mb0 mr-auto ml-auto"
                  field={act.belowListedCount || 0}
                />
              )
            })}
          </div>
        )}
      </div>
      <div className="advance-activity-content-list-row grey-background">
        <ActivityRowField
          widthClass={percentFieldLabelClass}
          fieldClass="mb0"
          field="Rent achieved below last listed percentage"
        />
        {mergedRentAchievedActivities && (
          <div className={`${dataPercentFieldContainer} display-flex`}>
            {mergedRentAchievedActivities.map((act, index) => {
              return (
                <ActivityRowField
                  key={index}
                  widthClass={dataPercentFieldClass}
                  fieldClass="mb0 mr-auto ml-auto"
                  field={
                    act.belowListedPercentage
                      ? `${act.belowListedPercentage}%`
                      : '0%'
                  }
                />
              )
            })}
          </div>
        )}
      </div>
      <div className="advance-activity-content-list-row">
        <ActivityRowField
          widthClass={percentFieldLabelClass}
          fieldClass="mb0"
          field="Average rent"
        />
        {mergedRentAchievedActivities && (
          <div className={`${dataPercentFieldContainer} display-flex`}>
            {mergedRentAchievedActivities.map((act, index) => {
              return (
                <ActivityRowField
                  key={index}
                  widthClass={dataPercentFieldClass}
                  fieldClass="mb0 mr-auto ml-auto"
                  field={act.averageRent ? act.averageRent : 0}
                />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default RentAchievedRows
