import { answerView } from 'app/match/rental_reference/rating_categories.constants'
import { QuestionType } from 'app/sm/helpers'

export const questionsCodes = {
  relationship: 'relationship',
  relationshipStartYear: 'relationship-start-year',
  doSpentTimeAtHome: 'do-spent-at-home',
  propertyCondition: 'property-condition',
  petsCount: 'pets-count',
  wouldRentThem: 'would-rent-them',
  generalComment: 'general-comment',
}

export const originalVersionQuestions = {
  [questionsCodes.relationship]: {
    type: QuestionType.Text,
    answerView: answerView.text,
    body: 'Relationship to applicant?',
    options: [
      'De Facto',
      'Partner',
      'Spouse',
      'Family',
      'Friend',
      'Colleague',
      'Neighbour',
      'Carer',
      'Guardian',
      'Other',
    ],
  },
  [questionsCodes.relationshipStartYear]: {
    type: QuestionType.Text,
    answerView: answerView.text,
    body: 'How long have you known the applicant?',
  },
  [questionsCodes.doSpentTimeAtHome]: {
    type: QuestionType.Boolean,
    answerView: answerView.boolean,
    body: 'Have you spent time at the applicant’s home?',
    exception: (answer) => answer !== 'true',
  },
  [questionsCodes.propertyCondition]: {
    type: QuestionType.Text,
    answerView: answerView.textBlock,
    body: 'What condition was the property?',
  },
  [questionsCodes.petsCount]: {
    type: QuestionType.Text,
    answerView: answerView.text,
    body: 'How many pets does the applicant have?',
  },
  [questionsCodes.wouldRentThem]: {
    type: QuestionType.Boolean,
    answerView: answerView.boolean,
    body: 'Would you rent a home to them?',
    exception: (answer) => answer !== 'true',
  },
  [questionsCodes.generalComment]: {
    type: QuestionType.Text,
    answerView: answerView.textBlock,
    body: 'Is there anything we should know about renting to applicant or any other comments?',
  },
}

export const PERSONAL_REFERENCE_SURVEY_ORIGINAL_VERSION = 1
