import React from 'react'

import { ErrorBox } from 'app/components/forms/util'

import 'app/components/forms/base_dropdown/style.scss'

class Dropdown extends React.Component {
  handleChange = (event) => {
    const {
      target: { value },
    } = event
    const { validate, onChange } = this.props
    const error = value && !!validate ? validate(value.trim()) : ''
    onChange({ value, error })
  }

  render() {
    const {
      label,
      options,
      value,
      error,
      id,
      name,
      inputClass,
      componentClass,
      inputBoxClass,
      frameLabelClass,
      frameLabel,
      resetStyle = false,
      idAsValue,
      extraOptionText = '',
      selectIsDisabled = false,
      useLabelValueOptions = false,
      selectClassName = '',
      extraOptionTextFromOptions = false,
    } = this.props
    const hasValue = !!value || value !== ''
    const wrapperClassName = resetStyle
      ? ''
      : `standard-form-components ${inputClass}`
    return (
      <div className={`${componentClass}`}>
        <div className={wrapperClassName}>
          <div
            className={`${
              error ? 'input-box error' : 'input-box'
            } standard-dropdown mb0 ${inputBoxClass}`}
          >
            <select
              onChange={this.handleChange}
              value={value}
              id={id}
              name={name}
              className={`${
                hasValue && 'select_hasValue'
              } ${selectClassName} mb5`}
              placeholder={label}
              disabled={selectIsDisabled}
            >
              <option value="" disabled defaultValue>
                {label || 'Select'}
              </option>
              {!useLabelValueOptions &&
                options.map((opt, i) => (
                  <option
                    key={idAsValue ? opt.guid : opt}
                    value={idAsValue ? opt.guid : i}
                    className={i === 0 && 'mt30'}
                  >
                    {`${idAsValue ? opt.name : opt} ${extraOptionText}`}
                  </option>
                ))}
              {useLabelValueOptions &&
                options.map((opt) => (
                  <option key={opt.value} value={opt.value}>
                    {`${opt.label} ${
                      extraOptionTextFromOptions
                        ? opt.extraOptionText
                        : extraOptionText
                    }`}
                  </option>
                ))}
            </select>
            {frameLabel && (
              <label className={`input-label ${frameLabelClass}`}>
                {frameLabel}
              </label>
            )}
            <ErrorBox error={error} showError={!!error} />
          </div>
        </div>
      </div>
    )
  }
}

export default Dropdown
