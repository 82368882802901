import * as Errors from 'app/constants/error_codes'
import * as textHelpers from 'app/utils/text/helpers'

const ErrorMessages = {
  [Errors.BcRequestNotExistForCurrentUser]:
    "Can't find BondCover details for this account",
  [Errors.RentRateNotValid]: 'Weekly rent is not valid',
  [Errors.RentBondNotValid]: 'Bond is not valid',
  [Errors.StartDateRequired]: 'start date is required',
  [Errors.ErrorInvalidUnitNumber]:
    'Unit number should not be greater than 10 characters',
  [Errors.ErrorInvalidStreetNumber]:
    'Street number should not be greater than 10 characters',

  [Errors.StartDateMustBeInThePast]: 'Start date must be in the past',
  [Errors.EndDateRequired]: 'End date is required for non-rolling lease',
  [Errors.EndDateMustBeAfterStartDate]: 'End date must be after start date',
  [Errors.BcRequestAlreadyExistsForCurrentUser]:
    'You already have a BondCover request outstanding',
  [Errors.BcRequestNotInProperStateToBeenSigned]:
    'BondCover request cannot be signed',
  [Errors.CannotUpdateRequestDetailAfterSigning]:
    'The details cannot be updated after BondCover request is signed',
  [Errors.BcRequestCannotBeSubmitted]:
    'BondCover request cannot been submitted',
  [Errors.ErrorWhenRetrievingCustomer]:
    "Can't find BondCover details for this account",
  [Errors.ErrorWhenReadingUserID]: `Please log out and back in (${Errors.ErrorWhenReadingUserID})`,
  [Errors.ParameterIDNotValid]: 'The ID of the entity is not valid',
  [Errors.ReviewNotValid]: 'The review is not valid',
  [Errors.ReviewRatingNotValid]: 'The rating of the review is not valid',
  [Errors.ReviewTextRequired]:
    'The text field for the review is required (Both at agent, and Property)',
  [Errors.ReviewTitleRequired]:
    'The title field for the review is requred (Both at agent and Property)',
  [Errors.ErrorWhenCreatingQuestion]:
    'An internal error occured when creating a question',
  [Errors.QuestionTextRequired]: 'The text field for the question is required',
  [Errors.ErrorWhenCreatingCustomer]: "A customer couldn't be created",
  [Errors.ErrorWhenCallingStripeAPIToCreateCustomer]:
    "Payment couldn't be made, there was a problem",
  [Errors.ErrorWhenUpdatingCustomer]:
    'There was an error updating the customer',
  [Errors.NoCustomerRecordExistsForCurrentUser]:
    'There is no customer record for the current user',
  [Errors.StripeTokenIsRequired]: 'A payment token is required',
  [Errors.ErrorWhenCallingStripeAPIToCreateCustomer]:
    "Payment couldn't be made due to technical issues",
  [Errors.ErrorWhenCreatingPayment]: "Payment couldn't be made",
  [Errors.ErrorWhenCallingStripeAPIToMakePayment]:
    "Payment couldn't be made due to technical issues",
  [Errors.ErrorWhenUpdatingPayment]: "Payment couldn't be updated",
  [Errors.ErrorWhenRetrievingPayment]: "A payment couldn't be retreived",
  [Errors.StreetNumberRequired]: 'A street number is required',
  [Errors.StreetNameRequired]: 'A street name is required',
  [Errors.SuburbRequired]: 'A suburb is required',
  [Errors.StateRequired]: 'A state for the property is required',
  [Errors.PostcodeRequired]: 'A postcode is required',
  [Errors.CountryRequired]: 'A country is required',
  [Errors.StreetTypeImproper]: 'Street type is improper',
  [Errors.BedroomCountRequired]: 'Number of bedrooms is required',
  [Errors.CountryAustraliaRequired]: 'Australia required',
  [Errors.AusPostcodeMinimumLength]:
    'The Australian postcode should contain minimum 4 digits',
  [Errors.PostcodeWithInvalidState]: `Invalid postcode for selected state (${Errors.PostcodeWithInvalidState})`,
  [Errors.OfferAlreadyPaid]: 'Offer is already paid',
  [Errors.OfferIsNotActive]: 'The offer is not active',
  [Errors.RequestedAdNotExist]: 'The ad you requested does not exist',
  [Errors.NoAdExistsForCurrentProperty]:
    "The ad doesn't exist for the current property",
  [Errors.ErrorWhenRetrievingAd]: 'Cannot retreive the ad',
  [Errors.AdAlreadyExistsForCurrentProperty]:
    'An Ad already exist for the current property',
  [Errors.ErrorWhenCreatingAd]: "Couldn't create an ad, there was an error",
  [Errors.ErrorWhenUpdatingAd]: "Couldn't update an ad, there was an error",
  [Errors.OfferWeeklyRentIsRequired]: 'Weekly Rent is mandatory',
  [Errors.OfferAvailableFromDateIsRequired]: 'Available from is required',
  [Errors.AcceptedLeaseLengthIsRequired]: 'Accepted lease length is required',
  [Errors.PropertyAvailableFromDateIsRequired]:
    'Property Available from date is required',
  [Errors.PropertyTypeIsRequired]: 'Property type is required',
  [Errors.PropertyTitleIsRequired]: 'Property title is required',
  [Errors.ErrorInvalidCard]: 'Invalid Credit Card',
  [Errors.ErrorCannotDeleteDefaultCard]: 'Cannot delete a default card',
  [Errors.TenantEmailAddressAlreadyExists]: 'Secondary tenant already exists',
  [Errors.SecondaryTenantNotExist]: 'Only secondary leasees can view this page',
  [Errors.CannotUpdateAlreadyApproved]:
    'You cannot update these details after approving',
  [Errors.ErrorManagerCannotBeTenant]: 'You cannot be the Agent or Owner',
  [Errors.ErrorTenantsBondTotalIncorrect]:
    'Bond split total must equal the total bond amount',
  [Errors.FirstNameNotValid]: `First name is not valid (${Errors.FirstNameNotValid})`,
  [Errors.MiddleNameNotValid]: 'Middle Name is not a valid name',
  [Errors.LastNameNotValid]: 'Last Name is not a valid name',
  [Errors.ErrorParsingFileType]: `Error when reading file type (${Errors.ErrorParsingFileType})`,
  [Errors.ErrorParsingFile]: `Error when reading file (${Errors.ErrorParsingFile})`,
  [Errors.InsufficientPrivileges]: 'You are not authorised to view this',
  [Errors.DeclineReasonNotValid]: 'Decline reason is not valid',
  [Errors.BackgroundCheckNotExist]: "Background Check doesn't exist",
  [Errors.ListingFeeAlreadyPaid]: 'Listing Fee already paid',
  [Errors.BackgroundCheckAlreadyPaid]: 'Background Check already paid',
  [Errors.BackgroundCheckNotExist]: 'Background Check is not done yet',
  [Errors.ListingFeeAlreadyPaid]: 'Listing Fee already paid',
  [Errors.PeopleIsRequired]: 'People is required field',
  [Errors.OfferRentIsRequired]: 'Application rent is required field',
  [Errors.OfferTermIsRequired]: 'Offer term is required field',
  [Errors.OfferMoveInDateIsRequired]: 'Offer move in date is required',
  [Errors.PetsIsRequired]: 'Pets is a required field',
  [Errors.RequestedOfferPreferencesNotExist]: 'There are no preferences',
  [Errors.LettingPropertyOptionIsInvalid]:
    'At least 1 option must be choosen for the preferences',
  [Errors.CreditCardDoesNotExist]: `Your profile does not include a credit card (${Errors.CreditCardDoesNotExist})`,
  [Errors.NamesNotComplete]: 'Name is not complete',
  [Errors.EmailNotValid]: 'Email is not complete',
  [Errors.ErrorWhenCreatingViewing]: "A Viewing couldn't be created",
  [Errors.ErrorWhenRetrievingViewing]: "A Viewing couldn't be retreived",
  [Errors.RequestedViewingNotExist]: 'The Viewing you requested does not exist',
  [Errors.ErrorWhenUpdatingViewing]: "Viewing couldn't be updated",
  [Errors.ErrorWhenDeletingViewing]: "Viewing couldn't be deleted",
  [Errors.DurationRequired]: 'Duration is required',
  [Errors.CannotUpdateRentalHistory]:
    "Cannot be updated because it's already been rated",
  [Errors.ErrorRedirectingAlreadyRatedRentalHistory]: `Reference has already been completed (${Errors.ErrorRedirectingAlreadyRatedRentalHistory})`,
  [Errors.ErrorWhenReadingInviteeEmail]: 'Please provide an email address',
  [Errors.DuplicateInvitesExist]: "You've already invited ",
  [Errors.InvalidOrEmptyDiscountcode]: 'The promotion code is invalid',
  [Errors.ErrorEmployerEventNotExist]:
    'This Employment Confirmation is no longer current. Please check your email for a new confirmation or contact the requesting person.',
  [Errors.ErrorEmployerEventNotValid]: 'This request is no longer valid',
  [Errors.ErrorRequestHasBeenChanged]:
    'This request has been changed by your employee',
  [Errors.ErrorConfirmationRequestExpired]: 'This request is expired',
  [Errors.ErrorEmployerUpdated]: `The details have been updated, please reload (${Errors.ErrorEmployerUpdated})`,
  [Errors.ErrorEmployerNotValid]: 'The details are invalid.',
  [Errors.ErrorWhenUpdatingApplicationStatus]:
    'Application event not permitted',
  [Errors.ErrorWhenRetrievingEmployer]:
    'There was an error retrieving the employment details',
  [Errors.ErrorEmployerDoesNotExist]:
    'This Employment Confirmation is no longer current. Please check your email for a new confirmation or contact the requesting person.',
  [Errors.ErrorValidatingMobile]: 'Invalid mobile number, e.g. +61 491 570 156',
  [Errors.ErrorWhenConvertingMobileToInternationalFormat]:
    'Valid phone number formats are: +61 431 111 222 or 0431 111 222',
  [Errors.ErrorWhenConvertingOfficeNumberToInternationalFormat]:
    'Valid phone number formats are: +61 2 9123 4567 or 02 9123 4567',
  [Errors.ErrorEmployerAlreadyVerified]:
    'Employment details have been verified',
  [Errors.ErrorEmployeeNotAllowedToVerify]: 'The request is not valid',
  [Errors.RollingIsNotCurrentAddress]:
    "End date is required if you don't currently live here",
  [Errors.ReasonForLeavingNotProvided]: 'Please provide reason for leaving',
  [Errors.ErrorEndDateMustBeInTheFutureForCurrentAddress]:
    'End date must be in the future for current lease',
  [Errors.ErrorWhenRemovingIsCurrentAddressForRentalHistory]:
    'There was a problem when setting your current lease. Please try again later',
  [Errors.OtherReasonForLeavingNotSpecified]:
    'Please specify reason for leaving',
  [Errors.ErrorCurrentUserIsNotInvitee]:
    'You are not authorised to accept this invite',
  [Errors.ErrorApplicationHasToAcceptConditionOfProperty]:
    'Application has to accept condition of property',
  [Errors.ErrorWhenCreatingApplyNotificationEmailForRenter]:
    'Failed to send apply notifications to the renters',
  [Errors.ErrorWhenRetrievingApplication]: `Retrieving application failed (${Errors.ErrorWhenRetrievingApplication})`,
  [Errors.RequestedApplicationNotExist]: "The application doesn't exist",
  [Errors.ErrorGettingApplicationDetails]: 'Cannot get application details',
  [Errors.ErrorApplicationMustBeActive]: 'Application(s) should be active',
  [Errors.ErrorCannotDeleteRatedRentalHistory]:
    'You cannot delete rental history that has already been reviewed',
  [Errors.ErrorWhenCreatingApplicationDetailsPdf]:
    'Failed to generate an application details pdf (300187)',
  [Errors.ViewingStartDateIsInTheFuture]: "The viewing hasn't finished yet",
  [Errors.ErrorWhenRetrievingViewingFeedbackRequest]:
    'Retrieving viewing feedback failed',
  [Errors.ErrorWhenCreateViewingFeedbackResponse]:
    'Creating viewing feedback failed',
  [Errors.RequestedViewingFeedbackRequestNotExist]: `Viewing feedback does not exist (${Errors.RequestedViewingFeedbackRequestNotExist})`,
  [Errors.ErrorWhenUpdatingViewingFeedbackRequest]:
    'Failed to update the viewing feedback request',
  [Errors.ViewingFeedbackAlreadyAdded]: 'Viewing feedback was already sent',
  [Errors.ErrorViewingFeedbackRequestExpired]: `Viewing feedback request expired (${Errors.ErrorViewingFeedbackRequestExpired})`,
  [Errors.ErrorWhenCallingStripeAPIToCreateSub]: `There is an error adding a credit card (${Errors.ErrorWhenCallingStripeAPIToCreateSub})`,
  [Errors.ErrorWhenParsingIncomingRequest]: `There is an error processing the request (${Errors.ErrorWhenParsingIncomingRequest})`,
  [Errors.ErrorWhenCreatingCard]: `There was an error deleting the credit card (${Errors.ErrorWhenCreatingCard})`,
  [Errors.RequestedViewingAllreadyExist]: `Requested viewing already exists (${Errors.RequestedViewingAllreadyExist})`,
  [Errors.RequestedViewingRegistrationNotExist]: `Requested viewing registration doesn't exist (${Errors.RequestedViewingRegistrationNotExist})`,
  [Errors.InvalidSupplierSource]: `The supplier source is invalid (${Errors.InvalidSupplierSource})`,
  [Errors.ErrorApplicationHasDuplicateApplicant]: `You cannot invite an applicant more than once (${Errors.ErrorApplicationHasDuplicateApplicant})`,
  [Errors.RecipientAddressCannotBeEmptyWhenSendReport]:
    'Supplier Recipient Address cannot be empty when Send Report is opted',
  [Errors.MiddleNameIsRequired]: `Middle name is required or please untick (${Errors.MiddleNameIsRequired})`,
  [Errors.PropertyArrangementInvalid]: `The living arrangement is invalid (${Errors.PropertyArrangementInvalid})`,
  [Errors.EmptyMessageBody]: `Message body is empty`,
  [Errors.EmptyRecipientList]: `Empty recipient list`,
  [Errors.EmptyAttachedProperties]: `No properties attached`,
  [Errors.ApplicationDoesNotExist]: `Application does not exist`,
  [Errors.RenterProfileIsNotComplete]: `Please update rental history & employment history details`,

  [Errors.ErrorWhenGettingReputationPreference]: `${Errors.ErrorWhenGettingReputationPreference}: Error updatig reputation`,
  [Errors.ErrorWhenUpdatingReputationPreference]: `${Errors.ErrorWhenUpdatingReputationPreference}: Error updatig reputation`,
  [Errors.ErrorWhenParsingPreferenceValue]: `${Errors.ErrorWhenParsingPreferenceValue}: Error updatig reputation`,
  [Errors.ErrorWhenConvertingPreferenceToResponse]: `${Errors.ErrorWhenConvertingPreferenceToResponse}: Error updatig reputation`,

  [Errors.ApplicationExistsAlready]: `${Errors.ApplicationExistsAlready}: Application already exists`,
  [Errors.ErrorWhenRetrievingRentalHistory]: `There was an error retrieving the rental history`,
  [Errors.CannotRequestRentalHistoryReviewByManager]: `There was an error resending reference (${Errors.CannotRequestRentalHistoryReviewByManager})`,
  [Errors.ErrorWhenCreatingRentalHistoryReminderEmailToManager]: `There was an error creating email to resend reference (${Errors.ErrorWhenCreatingRentalHistoryReminderEmailToManager})`,
  [Errors.ErrorApplicationExpired]: `Application has expired (${Errors.ErrorApplicationExpired})`,
  [Errors.InvalidEmploymentEndDate]: `Please add a valid end date (${Errors.InvalidEmploymentEndDate})`,
  [Errors.AgencyPaymentGatewayNotFound]: `Payment instructions could not be found (${Errors.AgencyPaymentGatewayNotFound})`,
  [Errors.ErrorInvalidTeamViewingSettingsRequest]: `Could not complete request (${Errors.ErrorInvalidTeamViewingSettingsRequest})`,
  [Errors.RentalHistoryNotExist]: `Address history is required (${Errors.RentalHistoryNotExist})`,
  [Errors.ErrorContactGUIDNotValid]: `Contact information invalid (${Errors.ErrorContactGUIDNotValid})`,
  [Errors.ErrorUpdatingContact]: `Error when updating contact (${Errors.ErrorUpdatingContact})`,
  [Errors.ErrorDeletingContact]: `Error when deleting contact (${Errors.ErrorDeletingContact})`,
  [Errors.ErrorInvalidContactRequest]: `Invalid contact request (${Errors.ErrorInvalidContactRequest})`,
  [Errors.ErrorCreatingContact]: `Error when creating contact (${Errors.ErrorCreatingContact})`,
  [Errors.ErrorListingContacts]: `Error when listing contacts (${Errors.ErrorListingContacts})`,
  [Errors.ErrorRetrievingContact]: `Error when retrieving contact (${Errors.ErrorRetrievingContact})`,
  [Errors.DuplicatePhoneNumber]: `The phone number is duplicated (${Errors.DuplicatePhoneNumber})`,
  [Errors.ErrorContactNotExist]: `Could not retrieve contact (${Errors.ErrorContactNotExist})`,
  [Errors.ErrorInvalidRequest]: `Invalid request (${Errors.ErrorInvalidRequest})`,

  [Errors.RequestedRentalHistoryNotExist]: `This rental reference is no longer available (${Errors.RequestedRentalHistoryNotExist})`,
  [Errors.ErrorGettingNonExistingRentalHistoryByReferee]: `This rental reference is no longer available. Please contact the renter.`,
  [Errors.RentalLedgerDoesNotExist]: `This rental ledger is no longer available(${Errors.RentalLedgerDoesNotExist})`,
  [Errors.ErrorPhoneNotMatchForRenterUpdateOnsiteViewingRegistrant]: `Failed to update onsite viewing registrant info (${Errors.ErrorPhoneNotMatchForRenterUpdateOnsiteViewingRegistrant})`,
  [Errors.ErrorTimeExpiredForRenterUpdateOnsiteViewingRegistrant]: `Failed to update onsite viewing registrant info (${Errors.ErrorTimeExpiredForRenterUpdateOnsiteViewingRegistrant})`,
  [Errors.ErrorEmailAlreadyExistsForOnsiteViewingRegistrant]: `Failed to update onsite viewing registrant info (${Errors.ErrorEmailAlreadyExistsForOnsiteViewingRegistrant})`,

  [Errors.PlaceOfStudyRequired]: `Place of study is required (${Errors.PlaceOfStudyRequired})`,
  [Errors.CourseNameRequired]: `Course name is required (${Errors.CourseNameRequired})`,
  [Errors.InvalidFileType]: `The file's type uploaded is invalid (${Errors.InvalidFileType})`,
  [Errors.ErrorWhenSavingDocumentAttachment]: `There was an error saving the attachment (${Errors.ErrorWhenSavingDocumentAttachment})`,
  [Errors.ErrorWhenRetrievingDocumentAttachment]: `There is an error retrieving the attachment(${Errors.ErrorWhenRetrievingDocumentAttachment})`,
  [Errors.DocumentAttachmentNotExist]: `The attachments for this document could not be found (${Errors.DocumentAttachmentNotExist})`,
  [Errors.ErrorWhenDeletingDocumentAttachment]: `There was an error deleting the attachment (${Errors.ErrorWhenDeletingDocumentAttachment})`,
  [Errors.ErrorWhenReadingSaveParam]: `There was an error when saving this identity document (${Errors.ErrorWhenReadingSaveParam})`,
  [Errors.NoDraftIdentityDocExists]: `There was an error when saving this identity document(${Errors.NoDraftIdentityDocExists})`,
  [Errors.IdentityDocBirthDateMismatch]: `This identity document has a different date of birth from other identity documents  (${Errors.IdentityDocBirthDateMismatch})`,
  [Errors.ErrorCannotDeleteOnsiteViewingRegistrant]: `Error when deleting viewing attendee (${Errors.ErrorCannotDeleteOnsiteViewingRegistrant})`,
  [Errors.RequestedViewingOnsiteRegistrantNotExist]: `This viewing attendee is no longer available (${Errors.RequestedViewingOnsiteRegistrantNotExist})`,
  [Errors.ErrorWhenRetrievingViewingOnsiteRegistrant]: `Error when retrieving viewing attendee (${Errors.ErrorWhenRetrievingViewingOnsiteRegistrant})`,
  [Errors.ErrorWhenUpdatingViewingOnsiteRegistrant]: `Error when updating viewing attendee (${Errors.ErrorWhenUpdatingViewingOnsiteRegistrant})`,
  [Errors.IdentityDocExistsAlready]: `This identity document already exists (${Errors.IdentityDocExistsAlready})`,
  [Errors.ErrorDifferentDateOfBirth]: `This document date of birth is different from your others (${Errors.ErrorDifferentDateOfBirth})`,
  [Errors.InvalidRentalHistoryResendRefereeEmail]: `Missing resend email, please edit the email before sending (${Errors.InvalidRentalHistoryResendRefereeEmail})`,
  [Errors.ErrorCreatingRentalReferenceRenterReview]: `Error when sumbitting your response to the rental reference (${Errors.ErrorCreatingRentalReferenceRenterReview})`,
  [Errors.ErrorRetrievingRentalReferenceRenterReview]: `Error when retieving your response to the rental reference (${Errors.ErrorRetrievingRentalReferenceRenterReview})`,
  [Errors.ErrorRentalReferenceRenterReviewNotExists]: `Your response to the rental reference is no longer available (${Errors.ErrorRentalReferenceRenterReviewNotExists})`,
  [Errors.ErrorCreatingNotRatedRentalReferenceRenterReview]: `This address history has not been rated by the manager (${Errors.ErrorCreatingNotRatedRentalReferenceRenterReview})`,
  [Errors.ErrorInviteNotValid]: `This invite is not valid (${Errors.ErrorInviteNotValid})`,

  [Errors.ErrorPlaceOfStudyRequired]: `Place of study is required (${Errors.ErrorPlaceOfStudyRequired})`,
  [Errors.ErrorCourseNamRequired]: `Course name is required (${Errors.ErrorCourseNamRequired})`,
  [Errors.ErrorStudentTypeUnrecognised]: `Student type is not recognised (${Errors.ErrorStudentTypeUnrecognised})`,
  [Errors.ErrorDurationTypeRequired]: `Duration type is required (${Errors.ErrorDurationTypeRequired})`,
  [Errors.ErrorDurationTypeUnrecognised]: `Duration type is not recognised (${Errors.ErrorDurationTypeUnrecognised})`,
  [Errors.ErrorEnrolmentNumberRequired]: `Enrolment number is required (${Errors.ErrorEnrolmentNumberRequired})`,
  [Errors.ErrorContactNameRequired]: `Contact number is required (${Errors.ErrorContactNameRequired})`,
  [Errors.ErrorContactEmailRequired]: `Contact email is required (${Errors.ErrorContactEmailRequired})`,
  [Errors.ErrorPhoneOrOfficeNumberRequired]: `Phone or office number is required (${Errors.ErrorPhoneOrOfficeNumberRequired})`,
  [Errors.ErrorPhoneNotValid]: `Phone is not valid (${Errors.ErrorPhoneNotValid})`,
  [Errors.ErrorCoordinatorNameRequired]: `Coordinator name is required (${Errors.ErrorCoordinatorNameRequired})`,
  [Errors.ErrorCoordinatorEmailRequired]: `Coordinator email is required (${Errors.ErrorCoordinatorEmailRequired})`,
  [Errors.ErrorCreateStudentDetails]: `Error when create student details (${Errors.ErrorCreateStudentDetails})`,
  [Errors.ErrorGetStudentDetails]: `Error when fetching student details (${Errors.ErrorGetStudentDetails})`,
  [Errors.ErrorUpdateStudentDetails]: `Error when updating student details (${Errors.ErrorUpdateStudentDetails})`,
  [Errors.ErrorDurationYearsInvalid]: `Duration years is not valid (${Errors.ErrorDurationYearsInvalid})`,
  [Errors.FailedToDeleteStudentDetailsByUser]: `Error when deleting student details by user (${Errors.FailedToDeleteStudentDetailsByUser})`,
  [Errors.FailedToDeleteStudentDetails]: `Error when deleting student details (${Errors.FailedToDeleteStudentDetails})`,
  [Errors.PhoneOfficeNumberNotValid]: `Phone or office number is not valid (${Errors.PhoneOfficeNumberNotValid})`,
  [Errors.ErrorPhoneOrMobileRequired]: `Phone or mobile is required (${Errors.ErrorPhoneOrMobileRequired})`,
  [Errors.StudentDetailsNotFound]: `Student details is not found (${Errors.StudentDetailsNotFound})`,
  [Errors.ErrorDurationMonthInValid]: `Duration months is not valid (${Errors.ErrorDurationMonthInValid})`,
  [Errors.DateOfBirthIsUnderAge]: `You must be 16 or older (${Errors.DateOfBirthIsUnderAge})`,
  [Errors.MobileNotValid]: `Phone number is not valid (${Errors.MobileNotValid})`,
  // Backgroundcheck identitity document errors
  [Errors.IdentityDocsErrors
    .DocumentTypeNotValid]: `Invalid ID document type e.g. Passport (${Errors.IdentityDocsErrors.DocumentTypeNotValid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocNumberInvalid]: `Document number should be alphanumeric e.g. 1234ABCD (${Errors.IdentityDocsErrors.ErrorIdentityDocNumberInvalid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocNumberNotPresent]: `Document number is empty e.g. passport or Driver Licence number (${Errors.IdentityDocsErrors.ErrorIdentityDocNumberNotPresent})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocAusDLStateNotPresent]: `State required for Australian Driver Licence e.g. NSW (${Errors.IdentityDocsErrors.ErrorIdentityDocAusDLStateNotPresent})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocAusDLStateInvalid]: `State required for Australian Driver Licence e.g. NSW (${Errors.IdentityDocsErrors.ErrorIdentityDocAusDLStateInvalid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocAusDLNumberInvalid]: `Invalid Australian Driver Licence number (${Errors.IdentityDocsErrors.ErrorIdentityDocAusDLNumberInvalid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocPassportCountryNotPresent]: `Country required for Passport e.g. Australia (${Errors.IdentityDocsErrors.ErrorIdentityDocPassportCountryNotPresent})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocAusPassportNumberInvalid]: `Invalid Australian Passport number e.g. A1234567 (${Errors.IdentityDocsErrors.ErrorIdentityDocAusPassportNumberInvalid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocPassportCountryInvalid]: `Invalid Passport country e.g. Australia (${Errors.IdentityDocsErrors.ErrorIdentityDocPassportCountryInvalid})`,
  [Errors.IdentityDocsErrors
    .ExpiryDateNotValid]: `Document must expire later than today (${Errors.IdentityDocsErrors.ExpiryDateNotValid})`,
  [Errors.IdentityDocsErrors
    .ErrorExpiryDateNotPresent]: `Expiry date required (${Errors.IdentityDocsErrors.ErrorExpiryDateNotPresent})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocDateOfBirthNotValid]: `You must be 16 or older (${Errors.IdentityDocsErrors.ErrorIdentityDocDateOfBirthNotValid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocDateOfBirthNotPresent]: `Date of birth required (${Errors.IdentityDocsErrors.ErrorIdentityDocDateOfBirthNotPresent})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocInvalidForBGC]: `Identity document type isn't suitable for background check, ony driving license or passport are suitable (${Errors.IdentityDocsErrors.ErrorIdentityDocInvalidForBGC})`,
  [Errors.ErrorWhenConvertDocumentToPDF]: `Error when convert document to PDF file (${Errors.ErrorWhenConvertDocumentToPDF})`,
  [Errors.ErrorMustAcceptConditionToApply]: `Must accept conditions and terms to apply (${Errors.ErrorMustAcceptConditionToApply})`,
  [Errors.ErrorMustAcceptDisclosureToApply]: `Must accept disclosure to apply (${Errors.ErrorMustAcceptDisclosureToApply})`,
  [Errors.ErrorWhenRetrievingApplicationNotes]: `Error when retrieving application notes (${Errors.ErrorWhenRetrievingApplicationNotes})`,
  [Errors.ErrorWhenCreatingApplicationNote]: `Error when create application notes (${Errors.ErrorWhenCreatingApplicationNote})`,
  [Errors.ErrorInvalidApplicationNoteRequest]: `The application note request is invalid (${Errors.ErrorInvalidApplicationNoteRequest})`,
  [Errors.ErrorWorkflowChangeNotAllowed]: `Error when change workflow (${Errors.ErrorWorkflowChangeNotAllowed})`,
  [Errors.ErrorDeletingLeaseEvent]: `Error when delete lease event (${Errors.ErrorDeletingLeaseEvent})`,
  [Errors.ErrorDeletingApplicantsForOffering]: `Error when deleting applications for offering (${Errors.ErrorDeletingApplicantsForOffering})`,
  [Errors.ErrorDeletingNextStepForOffering]: `Error when deleting next step for offering (${Errors.ErrorDeletingNextStepForOffering})`,
  [Errors.ErrorDeletingReferralsForOffering]: `Error when deleting referrals for offering (${Errors.ErrorDeletingReferralsForOffering})`,
  [Errors.ErrorWhenCheckApplicationIDManagedByTeamSlugs]: `Error when checking application id (${Errors.ErrorWhenCheckApplicationIDManagedByTeamSlugs})`,
  [Errors.ErrorRetrievingEnquiryNotes]: `Error when retrieving enquiry notes (${Errors.ErrorRetrievingEnquiryNotes})`,
  [Errors.ErrorRetrievingViewingAttendeeNotes]: `Error when retrieving onsite registrant notes (${Errors.ErrorRetrievingViewingAttendeeNotes})`,

  [Errors.ErrorWeeklyNetIncomeRequired]: `Income is required (${Errors.ErrorWeeklyNetIncomeRequired})`,
  [Errors.ErrorNetIncomeLessThanMinimum]: `Income must be 3 or more (${Errors.ErrorNetIncomeLessThanMinimum})`,
  [Errors.ErrorUserNotAllowedToActiveDomain]: `${textHelpers.NON_ADMIN_ACTIVE_DOMAIN_MODAL_TEXT} (${Errors.ErrorUserNotAllowedToActiveDomain})`,

  [Errors.InvalidOtherWeeklyIncome]: `Invalid other weekly income, eg: 100 (${Errors.InvalidOtherWeeklyIncome})`,
  [Errors.InvalidOtherAnnualIncome]: `Invalid other annual income, eg: 1000 (${Errors.InvalidOtherAnnualIncome})`,
  [Errors.InvalidGovernmentWeeklyBenefits]: `Invalid government weekly income, eg: 100 (${Errors.InvalidGovernmentWeeklyBenefits})`,
  [Errors.InvalidGovernmentAnnualBenefits]: `Invalid government annual income, eg: 1000 (${Errors.InvalidGovernmentAnnualBenefits})`,
  [Errors.ErrorWhenCreatingOtherProofOfIncome]: `Error when creating other income (${Errors.ErrorWhenCreatingOtherProofOfIncome})`,
  [Errors.ErrorWhenRetrievingOtherProofOfIncome]: `Error when retrieving other income information (${Errors.ErrorWhenRetrievingOtherProofOfIncome})`,
  [Errors.OtherProofOfIncomeDoesNotExist]: `Other income information does not exist (${Errors.OtherProofOfIncomeDoesNotExist})`,
  [Errors.ErrorWhenUpdatingOtherProofOfIncome]: `Error when updating other income information (${Errors.ErrorWhenUpdatingOtherProofOfIncome})`,
  [Errors.ErrorOtherProofOfIncomeAlreadyExists]: `Other income information record already exists (${Errors.ErrorOtherProofOfIncomeAlreadyExists})`,
  [Errors.ErrorWhenParsingCookie]: `Error when parsing cookie (${Errors.ErrorWhenParsingCookie})`,
  [Errors.ErrorWhenParsingAuthToken]: `Error when parsing token (${Errors.ErrorWhenParsingAuthToken})`,
  [Errors.IDTokenNotValid]: `Invalid ID token (${[Errors.IDTokenNotValid]})`,
  [Errors.ErrorRevokingAuthToken]: `Error when revoking token (${Errors.ErrorRevokingAuthToken})`,
  [Errors.ErrorRetrievingAuthToken]: `Error when retrieving token (${Errors.ErrorRetrievingAuthToken})`,
  [Errors.AuthTokenRequired]: `Auth token is required (${Errors.AuthTokenRequired})`,
  [Errors.ErrorWhenCreatingDexClient]: `Error when creating dex client (${Errors.ErrorWhenCreatingDexClient})`,
  [Errors.ErrorWhenRevokingRefreshToken]: `Error when revoking refresh token (${Errors.ErrorWhenRevokingRefreshToken})`,
  [Errors.ErrorWhenParsingRegisterPasswordRequest]: `Error when parsing register password request (${Errors.ErrorWhenParsingRegisterPasswordRequest})`,
  [Errors.PasswordNotValid]: `Password not valid (${Errors.PasswordNotValid})`,
  [Errors.ErrorAccountAlreadyExists]: `Account already exist (${Errors.ErrorAccountAlreadyExists})`,
  [Errors.ErrorCreatingPasswordAccount]: `Error when creating password (${Errors.ErrorCreatingPasswordAccount})`,
  [Errors.ErrorWhenParsingVerifyRequest]: `Error when parsing verified request (${Errors.ErrorWhenParsingVerifyRequest})`,
  [Errors.ErrorWhenRetreivingUser]: `Error when retreiving user (${Errors.ErrorWhenRetreivingUser})`,
  [Errors.ErrorIncorrectOTPFormat]: `Incorrect OTP format (${Errors.ErrorIncorrectOTPFormat})`,
  [Errors.ErrorWhenParsingLoginPasswordRequest]: `Error when parsing login password request (${Errors.ErrorWhenParsingLoginPasswordRequest})`,
  [Errors.ErrorWhenCreatingPassword]: `Error when creating password (${Errors.ErrorWhenCreatingPassword})`,
  [Errors.ErrorWhenTryingToPostLoginToDex]: `Error when trying to past login to dex (${Errors.ErrorWhenTryingToPostLoginToDex})`,
  [Errors.ErrorWhenParsingLoginResponseFromDex]: `Error when parsing login response from Dex (${Errors.ErrorWhenParsingLoginResponseFromDex})`,
  [Errors.ErrorWhenParsingCallbackRequest]: `Error when parsing call back request (${Errors.ErrorWhenParsingCallbackRequest})`,
  [Errors.ErrorWhenValidatingCallbackRequest]: `Error when validating call back request (${Errors.ErrorWhenValidatingCallbackRequest})`,
  [Errors.ErrorWrongCodeInCallbackRequest]: `Wrong code in call back request (${Errors.ErrorWrongCodeInCallbackRequest})`,
  [Errors.ErrorWhenTryingToGetTokenInCallback]: `Error when tying to get token in calll back (${Errors.ErrorWhenTryingToGetTokenInCallback})`,
  [Errors.ErrorWhenTryingToGetIDTokenInCallback]: `Error when tying to get IDtoken in calll back (${Errors.ErrorWhenTryingToGetIDTokenInCallback})`,
  [Errors.ErrorWhenTryingToParseEmailClaimInCallback]: `Error when tying to parse email claim in calll back (${Errors.ErrorWhenTryingToParseEmailClaimInCallback})`,
  [Errors.ErrorNoCookie]: `No cookie (${[Errors.ErrorNoCookie]})`,
  [Errors.ErrorWhenParsingRegisterSocialRequest]: `Error when parsing register social request (${Errors.ErrorWhenParsingRegisterSocialRequest})`,
  [Errors.OTPNotValid]: `OTPNotValid (${[Errors.OTPNotValid]})`,
  [Errors.SocialNotValid]: `Social info not valid (${Errors.SocialNotValid})`,
  [Errors.ErrorWhenMarkingUserMobileVerified]: `Error when marking user mobile verified (${Errors.ErrorWhenMarkingUserMobileVerified})`,
  [Errors.ErrorWhenTryingToGetRenterName]: `Error when trying to get renter name (${Errors.ErrorWhenTryingToGetRenterName})`,
  [Errors.ErrorWhenTryingToGetManagerName]: `Error when trying to get manager name (${Errors.ErrorWhenTryingToGetManagerName})`,
  [Errors.ErrorWhenTryingToGetUser]: `Error when trying to get user (${Errors.ErrorWhenTryingToGetUser})`,
  [Errors.ErrorWhenParsingUserWithRequestIDRequest]: `Error when parsing user with request ID request (${Errors.ErrorWhenParsingUserWithRequestIDRequest})`,
  [Errors.ErrorWhenParsingDeleteAccountRequest]: `Error when parsing delete account request (${Errors.ErrorWhenParsingDeleteAccountRequest})`,
  [Errors.ErrorWhenDeletingAccount]: `Error when deleting account (${Errors.ErrorWhenDeletingAccount})`,
  [Errors.RoleNotValid]: `Role not valid (${[Errors.RoleNotValid]})`,
  [Errors.ErrorRenterIDCheckFailed]: `Renter ID check failed (${Errors.ErrorRenterIDCheckFailed})`,
  [Errors.ErrorIdentityTypeNotImplemented]: `Identity type not implemented (${Errors.ErrorIdentityTypeNotImplemented})`,
  [Errors.ErrorIdentityTypeNotAvailable]: `Identity type not available (${Errors.ErrorIdentityTypeNotAvailable})`,
  [Errors.ErrorBondCoverRequestNotReadyForRenterIDCheck]: `Bondcover requests not ready for renter ID check (${Errors.ErrorBondCoverRequestNotReadyForRenterIDCheck})`,
  [Errors.ErrorBondCoverRequestNotReadyForManagerSignature]: `Bondcover requests not ready for manager signature (${Errors.ErrorBondCoverRequestNotReadyForManagerSignature})`,
  [Errors.ErrorBondCoverRequestNotReadyForManagerApproval]: `Bondcover requests not ready for manager approval (${Errors.ErrorBondCoverRequestNotReadyForManagerApproval})`,
  [Errors.ErrorAnalyticsForBondCoverRequestSaveManagerFailed]: `Analytics for Bondcover request failed (${Errors.ErrorAnalyticsForBondCoverRequestSaveManagerFailed})`,
  [Errors.ErrorRenterIDCheckProductIdWrong]: `Product Id is wrong in renter ID check (${Errors.ErrorRenterIDCheckProductIdWrong})`,
  [Errors.ErrorRenterIDCheckClientRefTooLong]: `Client ref is too long (${Errors.ErrorRenterIDCheckClientRefTooLong})`,
  [Errors.ErrorRenterIDCheckClientRefTooLong]: `Client ref is too long (${Errors.ErrorRenterIDCheckClientRefTooLong})`,
  [Errors.ErrorWhenTryingToGetRenterProfile]: `Error when trying to get renter profile (${Errors.ErrorWhenTryingToGetRenterProfile})`,
  [Errors.AgencyGUIDWasNotProvided]: `Agenci GUID was not provided (${Errors.AgencyGUIDWasNotProvided})`,
  [Errors.ErrorWhenReadingInviterName]: `Error when reading inviter name (${Errors.ErrorWhenReadingInviterName})`,
  [Errors.ErrorWhenReadingInviteeEmail]: `Error when reading invitee email (${Errors.ErrorWhenReadingInviteeEmail})`,
  [Errors.ErrorWhenReadingInviterEmail]: `Error when reading inviter email (${Errors.ErrorWhenReadingInviterEmail})`,
  [Errors.ErrorWhenTryingToGetRentersForBCRequest]: `Error when trying to get renters Bondcover request (${Errors.ErrorWhenTryingToGetRentersForBCRequest})`,
  [Errors.ErrorWhenTryingToGenerateRenterRatingsForAllRenters]: `Error when trying to generate renter rating for all renters (${Errors.ErrorWhenTryingToGenerateRenterRatingsForAllRenters})`,
  [Errors.ErrorWhenGeneratingAdminSearchResponse]: `Error when generating admin search response (${Errors.ErrorWhenGeneratingAdminSearchResponse})`,
  [Errors.RollingIsNotCurrentAddress]: `Rolling is not current address (${Errors.RollingIsNotCurrentAddress})`,
  [Errors.ReasonForLeavingNotProvided]: `Reason for leaving is not provided (${Errors.ReasonForLeavingNotProvided})`,
  [Errors.ErrorEndDateMustBeInTheFutureForCurrentAddress]: `End date must be in the future for current address (${Errors.ErrorEndDateMustBeInTheFutureForCurrentAddress})`,
  [Errors.ErrorWhenRemovingIsCurrentAddressForRentalHistory]: `Error when removing current address for rental history (${Errors.ErrorWhenRemovingIsCurrentAddressForRentalHistory})`,
  [Errors.OtherReasonForLeavingNotSpecified]: `Other reason for leaving not specified (${Errors.OtherReasonForLeavingNotSpecified})`,
  [Errors.OfficeNumberNotValid]: `Office number not valid e.g. 02 1234 1234 (${Errors.OfficeNumberNotValid})`,
  [Errors.ReasonForUnsubscribeNotProvided]: `Reason for unsubscribing not provided (${Errors.ReasonForUnsubscribeNotProvided})`,
  [Errors.ErrorWhenCreatingUnsubscribeSuccessEmail]: `Error when generating email for unsubscribing successfully (${Errors.ErrorWhenCreatingUnsubscribeSuccessEmail})`,
  [Errors.ErrorUserIDUnauthorised]: `User ID not authorised (${Errors.ErrorUserIDUnauthorised})`,
  [Errors.ErrorDeletingRatingsForRentalHistory]: `Error when deleting answer for rental reference (${Errors.ErrorDeletingRatingsForRentalHistory})`,
  [Errors.ErrorWhenMarkingRentalHistoryAsDeclined]: `Error when marking rental history as declined (${Errors.ErrorWhenMarkingRentalHistoryAsDeclined})`,
  [Errors.ErrorWhenCreatingDeclineReason]: `Error when creating decline reason (${Errors.ErrorWhenCreatingDeclineReason})`,
  [Errors.ErrorWhenMarkingRentalHistoryAsRated]: `Error when finalising rental reference (${Errors.ErrorWhenMarkingRentalHistoryAsRated})`,
  [Errors.ErrorWhenUpdatingRentalHistoryRating]: `Error when updating reference answer (${Errors.ErrorWhenUpdatingRentalHistoryRating})`,
  [Errors.ErrorWhenUpdatingRentalHistory]: `Error when updating rental history (${Errors.ErrorWhenUpdatingRentalHistory})`,
  [Errors.ErrorWhenCreatingRentalHistory]: `Error when creating rental history (${Errors.ErrorWhenCreatingRentalHistory})`,
  [Errors.ErrorWhenCreatingRentalHistoryRating]: `Error when creating rental reference answer (${Errors.ErrorWhenCreatingRentalHistoryRating})`,
  [Errors.ErrorWhenRetrievingRentalHistoryRating]: `Error when retrieving rental reference answer (${Errors.ErrorWhenRetrievingRentalHistoryRating})`,
  [Errors.RentalHistoryRatingExistsAlready]: `Rental reference answer already exists (${Errors.RentalHistoryRatingExistsAlready})`,
  [Errors.ErrorCannotCreateRatingForRatedRentalHistory]: `Reference has already been submitted (${Errors.ErrorCannotCreateRatingForRatedRentalHistory})`,
  [Errors.RatingCategoryNotValid]: `Unsupported reference question (${Errors.RatingCategoryNotValid})`,
  [Errors.RatingValueNotValid]: `Error invalid answer type for question (${Errors.RatingValueNotValid})`,
  [Errors.ErrorOverallRatingIsRequired]: `Overall rating is required (${Errors.ErrorOverallRatingIsRequired})`,
  [Errors.ErrorUsingNotMatchedQuestionTypeForRatingCategory]: `Mismatched question type (${Errors.ErrorUsingNotMatchedQuestionTypeForRatingCategory})`,
  [Errors.ErrorSendingSMS]: `Error when sending SMS (${Errors.ErrorSendingSMS})`,
  [Errors.InvalidApplicationSlug]: `Invalid application slug (${Errors.InvalidApplicationSlug})`,
  [Errors.InvalidCallBackTime]: `Invalid call back time (${Errors.InvalidCallBackTime})`,
  [Errors.ErrorWhenDraftingCallbakEmailToDirectConnect]: `Error when making draft call back email to Direct Connect (${Errors.ErrorWhenDraftingCallbakEmailToDirectConnect})`,
  [Errors.ErrorWhenRetrievingIdentityDocs]: `Error when retrieving identity Docs (${Errors.ErrorWhenRetrievingIdentityDocs})`,
  [Errors.ErrorWhenDeletingIdentityDocs]: `Error when deleting identity Docs (${Errors.ErrorWhenDeletingIdentityDocs})`,
  [Errors.ErrorWhenDeletingIdentityDocsAttachments]: `Error when deleting identity Docs attachments(${Errors.ErrorWhenDeletingIdentityDocsAttachments})`,
  [Errors.ErrorWhenRetrievingViewingFeedback]: `Error when retrieving viewing feedback(${Errors.ErrorWhenRetrievingViewingFeedback})`,
  [Errors.ErrorWhenCountingActivityOverview]: `Error when counting activity overview(${Errors.ErrorWhenCountingActivityOverview})`,
  [Errors.ErrorWhenCountingPropertyViews]: `Error when counting preperty views(${Errors.ErrorWhenCountingPropertyViews})`,
  [Errors.ErrorWhenCountingApplications]: `Error when counting applications(${Errors.ErrorWhenCountingApplications})`,
  [Errors.ErrorWhenGeneratingViewingSummary]: `Error when generating viewing summary(${Errors.ErrorWhenGeneratingViewingSummary})`,
  [Errors.ErrorInvalidPropertyReportRequest]: `Invalid property report request(${Errors.ErrorInvalidPropertyReportRequest})`,
  [Errors.ErrorCreatingPropertyReportRecord]: `Error when creating property report record(${Errors.ErrorCreatingPropertyReportRecord})`,
  [Errors.ErrorNotAuthorisedToViewPropertyReport]: `Not authorised to view property report(${Errors.ErrorNotAuthorisedToViewPropertyReport})`,
  [Errors.ErrorWhenRetrievingPropertyReport]: `Error when retrieving property report(${Errors.ErrorWhenRetrievingPropertyReport})`,
  [Errors.ErrorPropertyReportDoesNotExist]: `Property report does not exist(${Errors.ErrorPropertyReportDoesNotExist})`,
  [Errors.ErrorWhenCreatingPropertyReportForPM]: `Error when creating property report for property manager(${Errors.ErrorWhenCreatingPropertyReportForPM})`,
  [Errors.ErrorWhenCreatingCreateEmailRequestForOwner]: `Error when creating email request for owner(${Errors.ErrorWhenCreatingCreateEmailRequestForOwner})`,
  [Errors.ErrorInvalidManagerGUID]: `Invalid manager guidID(${Errors.ErrorInvalidManagerGUID})`,
  [Errors.ErrorPMmessageIsRequired]: `Property message is required (${Errors.ErrorPMmessageIsRequired})`,
  [Errors.UnsubscribeFetchSmsDetailsError]: `Error when fetching SMS Preferences (${Errors.UnsubscribeFetchSmsDetailsError})`,
  [Errors.UnsubscribeDeleteSmsPreferenceDetailsError]: `Error when saving SMS Preferences (${Errors.UnsubscribeDeleteSmsPreferenceDetailsError})`,
  [Errors.ErrorWhenParsingInvalidLogoDimensions]: `The size of the image is invalid`,
  [Errors.ErrorExternalTeamIDCannotBeEmpty]: `Please enter a Snug Team ID`,
  [Errors.ErrorGetAgencyRedisCache]: `Error when getting cached agency information`,
  [Errors.ErrorUnmarshallingRedisCache]: `Error when saving cached agency information`,
  [Errors.ErrorGettingAttributes]: `Error when getting attribute information`,
  [Errors.ErrorMaximumAttachments]: `The maximum number of attachments has reached`,
  [Errors.ErrorAgencyNotFound]: `Agency not found`,
  [Errors.ErrorLeaseOfferAlreadyExists]: `Lease offer already exists`,
  [Errors.ErrorWhenRetreivingAdvancedActivityReport]: `Error when retrieving advanced activity report (${Errors.ErrorWhenRetreivingAdvancedActivityReport})`,
  [Errors.UnauthorisedUserForAgency]: `Not authorised to access agency details (${Errors.UnauthorisedUserForAgency})`,
  [Errors.ErrorGeneratingActivityFilter]: `Error when applying filter to advanced activity report (${Errors.ErrorGeneratingActivityFilter})`,
  [Errors.ErrorCreatingLeaseOfferIncompleteNoCoreMessage]: `Message for sections is missing when creating lease offer (${Errors.ErrorCreatingLeaseOfferIncompleteNoCoreMessage})`,
  [Errors.ErrorCreatingLeaseOfferIncompleteMustInclude]: `No next step section is selected (${Errors.ErrorCreatingLeaseOfferIncompleteMustInclude})`,
  [Errors.ErrorMissingPaymentReference]: `Payment reference is missing (${Errors.ErrorMissingPaymentReference})`,
  [Errors.ErrorOnlyApplicantCanAcceptLeaseOffer]: `You must be an applicant to accept (${Errors.ErrorOnlyApplicantCanAcceptLeaseOffer})`,
  [Errors.ErrorRetrievingContactInfoFromViewingOnsiteRegistrant]: `Please update details to include at least one contact detail, email or mobile phone (
    ${Errors.ErrorRetrievingContactInfoFromViewingOnsiteRegistrant})`,
  [Errors.ErrorWhenRetrievingBackgroundCheck]: `Error when retrieving background check (${Errors.ErrorWhenRetrievingBackgroundCheck})`,
  [Errors.ErrorWhenParsingBackgroundCheck]: `Error when parsing background check (${Errors.ErrorWhenParsingBackgroundCheck})`,
  [Errors.NoIdentityDocExistsForUser]: `No identity document exists for user (${Errors.NoIdentityDocExistsForUser})`,
  [Errors.ErrorWhenUpdatingIdentityDoc]: `Error when updating identity document (${Errors.ErrorWhenUpdatingIdentityDoc})`,
  [Errors.ErrorWhenRetrievingBlockedDate]: `Error when retrieving exempt dates (${Errors.ErrorWhenRetrievingBlockedDate})`,
  [Errors.ErrorWhenSavingBlockedDate]: `Error when saving exempt dates (${Errors.ErrorWhenSavingBlockedDate})`,
  [Errors.ErrorWhenDeletingBlockedDate]: `Error when deleting exempt dates (${Errors.ErrorWhenDeletingBlockedDate})`,
  [Errors.ErrorWhileRetrievingApplication]: `Error when retrieving application (${Errors.ErrorWhileRetrievingApplication})`,
  [Errors.ErrorWhenCreatingApplicationSubmitReminder]: `Error when requesting remider to submit application (${Errors.ErrorWhenCreatingApplicationSubmitReminder})`,
  [Errors.ErrorWhenRetrievingApplicationSubmitReminder]: `Error when retrieving submit application reminder(${Errors.ErrorWhenRetrievingApplicationSubmitReminder})`,
  [Errors.RequestedApplicantNotExist]: `Applicant hasn't applied yet (${Errors.RequestedApplicantNotExist})`,
  [Errors.ApplicationHasBeenPMWithdrawn]: `Application has been withdrawn (${Errors.ApplicationHasBeenPMWithdrawn})`,
  [Errors.ErrorSearchingContactByAgency]: `Error when searching contact by agency (${Errors.ErrorSearchingContactByAgency})`,
  [Errors.ErrorApplicationExpired]: `Application is expired (${Errors.ErrorApplicationExpired})`,
  [Errors.ErrorInvalidTokenOrUnAuthorised]: `The request is unauthorised (${Errors.ErrorInvalidTokenOrUnAuthorised})`,
  [Errors.ErrorWhenRetrievingUser]: `Error when fetching user information (${Errors.ErrorWhenRetrievingUser})`,
  [Errors.ErrorUtilityProviderMissingProviderAgentID]: `Provider Agent ID is required (${Errors.ErrorUtilityProviderMissingProviderAgentID})`,
  [Errors.ErrorUtilityProviderMissingProviderAgentReferenceID]: `Provider Agent Reference ID is required (${Errors.ErrorUtilityProviderMissingProviderAgentReferenceID})`,
  [Errors.ErrorInvalidChildrenRequest]: `Invalid children details (${Errors.ErrorInvalidChildrenRequest})`,
  [Errors.ErrorWhenRegisteringToOldViewing]: `Sorry, this viewing is no longer available (${Errors.ErrorWhenRegisteringToOldViewing})`,
  [Errors.ErrorViewingIsFullyBooked]: `The viewing has been fully booked (${Errors.ErrorViewingIsFullyBooked})`,
  [Errors.ErrorViewingslotsAlreadyBooked]: `Sorry, this viewing is now fully booked (${Errors.ErrorViewingslotsAlreadyBooked})`,
  [Errors.ErrorDecliningUnsuccessfulApplicantsInMarkedAsLeased]: `Some applications were not declined (${Errors.ErrorDecliningUnsuccessfulApplicantsInMarkedAsLeased})`,
  [Errors.ErrorGettingFutureViewingsWithOfferID]: `Future Viewings for this offer were not found (${Errors.ErrorGettingFutureViewingsWithOfferID})`,
  [Errors.ErrorCancellingFutureViewingsInMarkedAsLeased]: `Some remaining viewings were not cancelled (${Errors.ErrorCancellingFutureViewingsInMarkedAsLeased})`,
  [Errors.RenterFirstNameIsRequired]: `First name is required (${Errors.RenterFirstNameIsRequired})`,
  [Errors.ErrorNotLogin]: `${Errors.ErrorNotLogin}`,
  [Errors.ErrorViewingTimeExistsForProperty]: `Viewing time exists for property (${Errors.ErrorViewingTimeExistsForProperty})`,
  [Errors.ErrorViewingTimeExistsForManager]: `Viewing time exists for team member (${Errors.ErrorViewingTimeExistsForManager})`,
  [Errors.ErrorOccupantsNotAllowedForApplication]: `Occupants in application are not allowed for agency (${Errors.ErrorOccupantsNotAllowedForApplication})`,
  [Errors.EmploymentVerificationRequestRequiresReason]: `Issues affecting employment must be between 4 and 300 characters (${Errors.EmploymentVerificationRequestRequiresReason})`,
  [Errors.ErrorAlreadyRegisteredForViewing]: `Already registered for viewing (${Errors.ErrorAlreadyRegisteredForViewing})`,
  [Errors.RequestedMessageNotExist]: `Message does not exist (${Errors.RequestedMessageNotExist})`,
  [Errors.ErrorWhenRetrievingMessage]: `Could not retrieve message (${Errors.ErrorWhenRetrievingMessage})`,
  [Errors.ErrorAgencyProfileNotExist]: `Agency profile does not exist (${Errors.ErrorAgencyProfileNotExist})`,
  [Errors.ErrorWhenRetrievingAgencyProfile]: `Could not retrieve agency profile (${Errors.ErrorWhenRetrievingAgencyProfile})`,
  [Errors.EmptyPropertyGUID]: `Property reference is empty (${Errors.EmptyPropertyGUID})`,
  [Errors.ErrorInvalidAgencyProfileID]: `Agency profile invalid (${Errors.ErrorInvalidAgencyProfileID})`,
  [Errors.ErrorGettingAgencyCriteria]: `Could not retrieve agency criteria (${Errors.ErrorGettingAgencyCriteria})`,
  [Errors.ErrorWhenCreatingViewingSlot]: `Could not create Viewing Slot (${Errors.ErrorInvalidAgencyProfileID})`,
  [Errors.ErrorWhenAssigningViewingSlot]: `Could not assign Viewing Slot (${Errors.ErrorWhenAssigningViewingSlot})`,
  [Errors.ErrorWhenRetrievingViewingSlot]: `Could not retrieve Viewing Slot (${Errors.ErrorWhenAssigningViewingSlot})`,
  [Errors.ErrorWhenRetrievingViewingSlotCount]: `Could not retrieve Viewing Slot (${Errors.ErrorWhenRetrievingViewingSlotCount})`,
  [Errors.ErrorFlaggingApplicantUnsuitable]: `Could not create applicant flag (${Errors.ErrorFlaggingApplicantUnsuitable})`,
  [Errors.ErrorRetrievingApplicantFlags]: `Could not retrieve applicant flags (${Errors.ErrorRetrievingApplicantFlags})`,
  [Errors.ErrorInvalidApplicationCap]: `Invalid application limit (${Errors.ErrorInvalidApplicationCap})`,
  [Errors.ErrorWhenRetrievingApplicationTeamMember]: `Could not retrieve assigned team member (${Errors.ErrorWhenRetrievingApplicationTeamMember})`,
  [Errors.ErrorNoFilesToDownloadPDF]: `No attachments to download (${Errors.ErrorNoFilesToDownloadPDF})`,
  [Errors.ErrorInvalidRequestForTeamMemberAssign]: `Error assigning team member (${Errors.ErrorInvalidRequestForTeamMemberAssign})`,
  [Errors.ErrorCleanupPreviousTeamMemberAssignment]: `Error assigning team member (${Errors.ErrorCleanupPreviousTeamMemberAssignment})`,
  [Errors.ErrorWhenReadingSearchQueryParameter]: `Unable to complete request (${Errors.ErrorWhenReadingSearchQueryParameter})`,
  [Errors.ErrorEmptySearchQuery]: `Unable to complete request (${Errors.ErrorEmptySearchQuery})`,
  [Errors.ErrorWhenRetrievingEntryDetails]: `Could not retrieve access details (${Errors.ErrorWhenRetrievingEntryDetails})`,
  [Errors.ErrorEntryDetailsDoesNotExist]: `Could not retrieve access details (${Errors.ErrorEntryDetailsDoesNotExist})`,
  [Errors.ErrorInvalidPreferredDateOrTime]: `Both preferred date and time are required (${Errors.ErrorInvalidPreferredDateOrTime})`,
  [Errors.ErrorInvalidBondDuration]: `Invalid bond duration (${Errors.ErrorInvalidBondDuration})`,
  [Errors.ErrorFetchingAllPropertyFutureViewings]: `Could not retrieve property viewings (${Errors.ErrorFetchingAllPropertyFutureViewings})`,
  [Errors.ErrorWhenReadingTeamGUID]: `Error reading team GUID (${Errors.ErrorWhenReadingTeamGUID})`,
  [Errors.ErrorWhenReadingApplicationGUID]: `Error reading application GUID (${Errors.ErrorWhenReadingApplicationGUID})`,
  [Errors.ErrorWhenReadingTeamMemberGUID]: `Error reading team member GUID (${Errors.ErrorWhenReadingTeamMemberGUID})`,
  [Errors.EmptyTeamMemberGUID]: `Team member GUID not found (${Errors.EmptyTeamMemberGUID})`,
  [Errors.ErrorSavingContactRelationship]: `Error saving contact relationship (${Errors.ErrorSavingContactRelationship})`,
  [Errors.ErrorApplicationClosed]: `Applications are now closed, please contact the property manager (${Errors.ErrorApplicationClosed})`,
  [Errors.ErrorWhenDeletingViewingOnsiteRegistrantNote]: `Could not delete attendee note (${Errors.ErrorWhenDeletingViewingOnsiteRegistrantNote})`,
  [Errors.ErrorWhenUpdatingViewingOnsiteRegistrantNote]: `Could not update attendee note (${Errors.ErrorWhenUpdatingViewingOnsiteRegistrantNote})`,
  [Errors.EmptyViewingGUID]: `Empty Viewing ID (${Errors.EmptyViewingGUID})`,
  [Errors.ErrorWhenRetrievingViewingTeamMembers]: `Could not retrieve team members (${Errors.ErrorWhenRetrievingViewingTeamMembers})`,
  [Errors.ErrorWhenDeletingViewingTeamMember]: `Error deleting team member (${Errors.ErrorWhenDeletingViewingTeamMember})`,
  [Errors.ErrorWhenReadingContactID]: `Could not retrieve contact (${Errors.ErrorWhenReadingContactID})`,
  [Errors.ErrorWhenRetrievingTeamContacts]: `Could not retrieve team contacts (${Errors.ErrorWhenRetrievingTeamContacts})`,
  [Errors.EmptyContactGUID]: `Invalid request (${Errors.EmptyContactGUID})`,
  [Errors.ErrorViewingTeamMemberAlreadyAssigned]: `Team member already assigned to viewing (${Errors.ErrorViewingTeamMemberAlreadyAssigned})`,
  [Errors.EmptyTeamGUID]: `Invalid request (${Errors.EmptyTeamGUID})`,
  [Errors.ErrorCheckingActiveTeamMember]: `Error processing request (${Errors.ErrorCheckingActiveTeamMember})`,
  [Errors.ContactIsNotAnActiveTeamMember]: `Error inactive team member (${Errors.ContactIsNotAnActiveTeamMember})`,
  [Errors.ErrorInvalidEnquiriesNotification]: `Invalid enquiries notification attribute (${Errors.ErrorInvalidEnquiriesNotification})`,
  [Errors.ErrorInvalidViewingsNotification]: `Invalid viewings notification attribute (${Errors.ErrorInvalidViewingsNotification})`,
  [Errors.ErrorInvalidApplicationNotification]: `Invalid application notification attribute (${Errors.ErrorInvalidApplicationNotification})`,
  [Errors.ErrorInvalidReportsNotification]: `Invalid report notification attribute (${Errors.ErrorInvalidReportsNotification})`,
  [Errors.AuthRecordAlreadyVerified]: `Access already verified (${Errors.AuthRecordAlreadyVerified})`,
  [Errors.AuthRecordExpired]: `Access expired (${Errors.AuthRecordExpired})`,
  [Errors.ErrorAgencyUserNotExist]: `Agency user does not exist (${Errors.ErrorAgencyUserNotExist})`,
  [Errors.ErrorCreatingEntryDetails]: `Error creating entry details (${Errors.ErrorCreatingEntryDetails})`,
  [Errors.ErrorUpdatingEntryDetails]: `Error updating entry details (${Errors.ErrorUpdatingEntryDetails})`,
  [Errors.ErrorDeletingEntryDetails]: `Error deleting entry details (${Errors.ErrorDeletingEntryDetails})`,
  [Errors.ErrorPropertyNotExist]: `Property does not exist (${Errors.ErrorPropertyNotExist})`,
  [Errors.ErrorWhenReadingViewingID]: `Viewing not found, please try again (${Errors.ErrorWhenReadingViewingID})`,

  //Next step server validation errors

  [Errors.ErrorLongDepositBSBLength]: `Deposit BSB length should be less than 50 chars (${Errors.ErrorLongDepositBSBLength})`,
  [Errors.ErrorLongDepositAccountLength]: `Deposit account length should be less than 50 chars (${Errors.ErrorLongDepositAccountLength})`,
  [Errors.ErrorLongDepositEFTReferenceLength]: `Deposit EFT reference length should be less than 50 chars (${Errors.ErrorLongDepositEFTReferenceLength})`,
  [Errors.ErrorLongDepositDEFTReferenceLength]: `Deposit DEFT reference length should be less than 50 chars (${Errors.ErrorLongDepositDEFTReferenceLength})`,
  [Errors.ErrorLongDepositBillerLength]: `Deposit Biller length should be less than 50 chars (${Errors.ErrorLongDepositBillerLength})`,
  [Errors.ErrorLongDepositBPAYReferenceLength]: `Deposit BPAY reference length should be less than 50 chars (${Errors.ErrorLongDepositBPAYReferenceLength})`,
  [Errors.ErrorLongBondBSBLength]: `Bond BSB should be less than 50 chars (${Errors.ErrorLongBondBSBLength})`,
  [Errors.ErrorLongBondAccountLength]: `bond account should be less than 50 chars (${Errors.ErrorLongBondAccountLength})`,
  [Errors.ErrorLongBondEFTReferenceLength]: `Bond EFT reference should be less than 50 chars (${Errors.ErrorLongBondEFTReferenceLength})`,
  [Errors.ErrorLongBondDEFTReferenceLength]: `Bond DEFT reference should be less than 50 chars (${Errors.ErrorLongBondDEFTReferenceLength})`,
  [Errors.ErrorLongBondBillerLength]: `Bond biller should be less than 50 chars (${Errors.ErrorLongBondBillerLength})`,
  [Errors.ErrorLongBondBPAYReferenceLength]: `Bond BPAY reference should be less than 50 chars (${Errors.ErrorLongBondBPAYReferenceLength})`,
  [Errors.ErrorLongRentBSBLength]: `Rent BSB should be less than 50 chars (${Errors.ErrorLongRentBSBLength})`,
  [Errors.ErrorLongRentAccountLength]: `Rent account should be less than 50 chars (${Errors.ErrorLongRentAccountLength})`,
  [Errors.ErrorLongRentEFTAccountNameLength]: `Rent EFT account name should be less than 50 chars (${Errors.ErrorLongRentEFTAccountNameLength})`,
  [Errors.ErrorLongRentEFTReferenceLength]: `Rent EFT reference should be less than 50 chars (${Errors.ErrorLongRentEFTReferenceLength})`,
  [Errors.ErrorLongRentDEFTReferenceLength]: `Rent DEFT reference should be less than 50 chars (${Errors.ErrorLongRentDEFTReferenceLength})`,
  [Errors.ErrorLongRentBillerLength]: `Rent biller should be less than 50 chars (${Errors.ErrorLongRentBillerLength})`,
  [Errors.ErrorLongRentBPAYReferenceLength]: `Rent BPAY reference should be less than 50 chars (${Errors.ErrorLongRentBPAYReferenceLength})`,

  [Errors.ErrorCreatingNotification]: `Error creating notification (${Errors.ErrorCreatingNotification})`,
  [Errors.ErrorEmployerNotEligibleForConfirmation]: `Employer not eligible for confirmation (${Errors.ErrorEmployerNotEligibleForConfirmation})`,
  [Errors.ErrorWhenCreatingSuccessfulApplicantDetailsEmailToRayWhiteConcierge]: `Error creating successful applicant details email (${Errors.ErrorWhenCreatingSuccessfulApplicantDetailsEmailToRayWhiteConcierge})`,
  [Errors.ErrorWhenReadingEmployerGUID]: `Error when reading Employer GUID (${Errors.ErrorWhenReadingEmployerGUID})`,
  [Errors.InvalidConfirmationNotificationRequest]: `Invalid Confirmation Request (${Errors.InvalidConfirmationNotificationRequest})`,
  [Errors.ErrorInvalidNotificationRecordType]: `Invalid notification record type (${Errors.ErrorInvalidNotificationRecordType})`,
  [Errors.ErrorArchivingPropertyAfterMarkedAsLeased]: `Error when archiving property (${Errors.ErrorArchivingPropertyAfterMarkedAsLeased})`,
  [Errors.ErrorMarkApprovedShouldBeTrueForMarkApproved]: `Unable to complete request (${Errors.ErrorMarkApprovedShouldBeTrueForMarkApproved})`,
  [Errors.ErrorUpdatingLeaseOffer]: `Unable to complete request (${Errors.ErrorUpdatingLeaseOffer})`,
  [Errors.ErrorInvalidApplicationApprovedRequest]: `Unable to complete request (${Errors.ErrorInvalidApplicationApprovedRequest})`,
  [Errors.ErrorUserNotValid]: `Unable to complete request (${Errors.ErrorUserNotValid})`,
  [Errors.ErrorCreatingPropertyVideos]: `Error when adding property videos (${Errors.ErrorCreatingPropertyVideos})`,
  [Errors.ErrorDeletingPropertyVideos]: `Error when deleting property videos (${Errors.ErrorDeletingPropertyVideos})`,
  [Errors.EmptyVideoGUID]: `Invalid request (${Errors.EmptyVideoGUID})`,
  [Errors.ErrorAgencySourceCreateFailed]: `Error saving feed settings (${Errors.ErrorAgencySourceCreateFailed})`,
  [Errors.ErrorInvalidAgencySourceGUID]: `Error saving feed settings (${Errors.ErrorInvalidAgencySourceGUID})`,
  [Errors.ErrorOccurredWhileUpdatingAgencySource]: `Error updating feed settings (${Errors.ErrorOccurredWhileUpdatingAgencySource})`,
  [Errors.ErrorAgencySourceUpdateFailed]: `Error updating feed settings (${Errors.ErrorAgencySourceUpdateFailed})`,
  [Errors.ErrorWhenReadingAgencyID]: `Error reading Agency ID (${Errors.ErrorWhenReadingAgencyID})`,
  [Errors.ErrorAgencySlugIsNotValid]: `Invalid agency slug (${Errors.ErrorAgencySlugIsNotValid})`,
  [Errors.ErrorAgencySlugAlreadyExists]: `Error Agency slug already exists (${Errors.ErrorAgencySlugAlreadyExists})`,
  [Errors.ErrorWhenRetriggeringWebFeed]: `Error re-triggering web feed (${Errors.ErrorWhenRetriggeringWebFeed})`,
  [Errors.ErrorGettingEmailDataForRefreshFeedEmailToTeamAdmin]: `Error getting email data for web feed (${Errors.ErrorGettingEmailDataForRefreshFeedEmailToTeamAdmin})`,
  [Errors.PetDateOfBirthInvalid]: `Invalid Pet DOB (${Errors.PetDateOfBirthInvalid})`,
  [Errors.ErrorWhenRegisteringAgency]: `Error while registering agency (${Errors.ErrorWhenRegisteringAgency})`,
  [Errors.ErrorWhenGettingAgency]: `Error retrieving agency details (${Errors.ErrorWhenGettingAgency})`,
  [Errors.ErrorWhenRetrievingAgencyUserProfile]: `Error retrieving agency member (${Errors.ErrorWhenRetrievingAgencyUserProfile})`,
  [Errors.ErrorWhenRetrievingProperty]: `Unable to complete request (${Errors.ErrorWhenRetrievingProperty})`,
  [Errors.ErrorParsingShareApplicationRequest]: `Unable to complete request (${Errors.ErrorParsingShareApplicationRequest})`,
  [Errors.ErrorFetchingPropertyPartnerKeys]: `Error fetching Partner Listing Ids (${Errors.ErrorFetchingPropertyPartnerKeys})`,
  [Errors.ErrorWhenRetrievingViewings]: `Error when retrieving viewings (${Errors.ErrorWhenRetrievingViewings})`,
  [Errors.ErrorDeletingPropertyLookupGUID]: `Error deleting property lookup (${Errors.ErrorDeletingPropertyLookupGUID})`,
  [Errors.InvalidCreatePropertyLookupRequest]: `Invalid request (${Errors.InvalidCreatePropertyLookupRequest})`,
  [Errors.EmptyCreatePropertyLookupRequest]: `Error creating property lookup (${Errors.EmptyCreatePropertyLookupRequest})`,
  [Errors.ErrorCreatingPropertyLookupFromRequest]: `Error creating property lookup (${Errors.EmptyCreatePropertyLookupRequest})`,
  [Errors.ErrorWhenCreatingOrUpdatingExcludedProperties]: `Error when creating excluded properties (${Errors.ErrorWhenCreatingOrUpdatingExcludedProperties})`,
  [Errors.ErrorWhenDeletingExcludedProperties]: `Error when deleting excluded properties (${Errors.ErrorWhenDeletingExcludedProperties})`,
  [Errors.ErrorWhenRetrievingExcludedProperties]: `Error when retrieving excluded properties (${Errors.ErrorWhenRetrievingExcludedProperties})`,
  [Errors.ErrorEmploymentTypeNotInRange]: `Invalid Employment Type (${Errors.ErrorEmploymentTypeNotInRange})`,
  [Errors.RequestedApplicantApplied]: `You have already applied (${Errors.RequestedApplicantApplied})`,
  [Errors.ErrorWhenReadingRentalHistoryID]: `Error reading rental history ID (${Errors.ErrorWhenReadingRentalHistoryID})`,
  [Errors.ErrorWhenReadingRentalReferenceID]: `Error reading rental reference ID (${Errors.ErrorWhenReadingRentalReferenceID})`,
  [Errors.ErrorWhenReadingRentalHistoryTenant]: `Error reading rental reference tenant (${Errors.ErrorWhenReadingRentalHistoryTenant})`,
  [Errors.RentalHistoryTenantNotExist]: `Rental history tenant not exist (${Errors.RentalHistoryTenantNotExist})`,
  [Errors.ErrorDeletingRentalHistoryTenant]: `Error deleting Rental history tenant (${Errors.ErrorDeletingRentalHistoryTenant})`,
  [Errors.ErrorFetchingRentalHistoryOccupants]: `Error fetching Rental history occupants (${Errors.ErrorFetchingRentalHistoryOccupants})`,
  [Errors.ErrorStoringRentalHistoryOccupants]: `Error storing Rental history occupants (${Errors.ErrorStoringRentalHistoryOccupants})`,
  [Errors.ErrorApplicantAndLoggedInUserMismatch]: `User account details differ from invite. Contact your primary applicant to update your invite details (${Errors.ErrorApplicantAndLoggedInUserMismatch})`,
  [Errors.ErrorWhenParsingParameters]: `Error processing request (${Errors.ErrorWhenParsingParameters})`,
  [Errors.ErrorInvalidNewViewingRegistrationNotification]: `Invalid parameters passed (${Errors.ErrorInvalidNewViewingRegistrationNotification})`,
  [Errors.ErrorInvalidIdentityCap]: `Error Invalid Identity Cap (${Errors.ErrorInvalidIdentityCap})`,
  [Errors.ErrorEmptyLeaseNextStep]: `Error retrieving offer (${Errors.ErrorEmptyLeaseNextStep})`,
  [Errors.EmptyRequest]: `Could not complete request (${Errors.EmptyRequest})`,
  [Errors.ErrorInvalidViewingCutoffEnabled]: `Invalid parameters passed (${Errors.ErrorInvalidViewingCutoffEnabled})`,
  [Errors.ErrorInvalidViewingCutoffTime]: `Invalid cut-off time passed (${Errors.ErrorInvalidViewingCutoffTime})`,
  [Errors.ErrorInvalidViewingScheduleZoneType]: `Invalid Viewing Schedule Zone (${Errors.ErrorInvalidViewingScheduleZoneType})`,
  [Errors.ErrorPropertyOfferNotProvided]: `Error processing request (${Errors.ErrorPropertyOfferNotProvided})`,
  [Errors.ErrorInvalidViewingRequestDisabled]: `Invalid viewing request disabled parameters passed (${Errors.ErrorInvalidViewingRequestDisabled})`,
  [Errors.ViewingRequestDisabled]: `Unable to Request a Viewing (${Errors.ViewingRequestDisabled})`,
  [Errors.ErrorInvalidSelfCheckinType]: `Error checking in (${Errors.ErrorInvalidSelfCheckinType})`,
  [Errors.ErrorWhenCountingTeamViewings]: `Error counting team viewings (${Errors.ErrorWhenCountingTeamViewings})`,
  [Errors.ErrorWhengettingTeamViewings]: `Error fetching viewings for agency (${Errors.ErrorWhengettingTeamViewings})`,
  [Errors.ErrorViewingIsCancelled]: `Requested viewing is cancelled (${Errors.ErrorViewingIsCancelled})`,
  [Errors.ErrorWhenUpdatingApplication]: `Unable to update application (${Errors.ErrorWhenUpdatingApplication})`,
  [Errors.ErrorSelectingEnablePreApply]: `Unable to process request (${Errors.ErrorSelectingEnablePreApply})`,
  [Errors.ErrorInvalidInspectionType]: `Invalid Inspection type (${Errors.ErrorInvalidInspectionType})`,
  [Errors.ErrorWhenCreatingWithOwnerApplicationToTenant]: `Error when creating with owner application to tenant (${Errors.ErrorWhenCreatingWithOwnerApplicationToTenant})`,
  [Errors.CalendarPlatformNotSupported]: `Canlendar platform is not supported (${Errors.CalendarPlatformNotSupported})`,
  [Errors.ErrorSavingCalendarRecord]: `Failed to activate calendar (${Errors.ErrorSavingCalendarRecord})`,
  [Errors.ErrorDeletingCalendarRecord]: `Failed to deactivate the calendar (${Errors.ErrorDeletingCalendarRecord})`,
  [Errors.ErrorRetrievingCalendarRecord]: `Failed to get calendar data (${Errors.ErrorRetrievingCalendarRecord})`,
  [Errors.ErrorCreatingGoogleCalendarEvent]: `Failed to create google calendar event (${Errors.ErrorCreatingGoogleCalendarEvent})`,
  [Errors.ErrorInvalidCalendarPlatform]: `Invalid calendar platform (${Errors.ErrorInvalidCalendarPlatform})`,
  [Errors.ErrorInvalidCalendarGUID]: `Invalid calendar GUID (${Errors.ErrorInvalidCalendarGUID})`,
  [Errors.ErrorCreatingCalendarActivity]: `Failed to create calendar activity (${Errors.ErrorCreatingCalendarActivity})`,
  [Errors.ErrorRetrievingCalendarEvent]: `Failed to retrieve calendar event (${Errors.ErrorRetrievingCalendarEvent})`,
  [Errors.ErrorDeletingMicrosoftCalendarEvent]: `Failed to delete microsoft calendar event (${Errors.ErrorDeletingMicrosoftCalendarEvent})`,
  [Errors.ErrorCancelFutureViewingArchiveProperty]: `Some future viewings were not cancelled (${Errors.ErrorCancelFutureViewingArchiveProperty})`,
  [Errors.ErrorDeclineHouseholdArchiveProperty]: `Some application were not declined (${Errors.ErrorDeclineHouseholdArchiveProperty})`,
  [Errors.ErrorGettingRegistrantDetailsFromShortcode]: `Error getting registrant details (${Errors.ErrorGettingRegistrantDetailsFromShortcode})`,
  [Errors.ErrorRegistrantFromShortcodeNotFound]: `Registrant not found (${Errors.ErrorRegistrantFromShortcodeNotFound})`,
  [Errors.ErrorIntegrationDoesNotExist]: `Console integration not found (${Errors.ErrorIntegrationDoesNotExist})`,
  [Errors.ErrorFromConsoleCloudAPI]: `Error when activating integration (${Errors.ErrorFromConsoleCloudAPI})`,
  [Errors.ErrorSavingAgencyAttributes]: `Error when saving api key (${Errors.ErrorSavingAgencyAttributes})`,
  [Errors.ErrorConsoleCloudIsNotEnabled]: `Console Cloud not activated (${Errors.ErrorConsoleCloudIsNotEnabled})`,
  [Errors.ErrorAttributeConsoleNotEnabled]: `Partner console not enabled (${Errors.ErrorAttributeConsoleNotEnabled})`,
  [Errors.ErrorWhenIssuingRayWhiteConcierge]: `Error processing Concierge update(${Errors.ErrorWhenIssuingRayWhiteConcierge})`,
  [Errors.ErrorInvalidIdentityCap]: `Error Invalid Identity Cap(${Errors.ErrorInvalidIdentityCap})`,

  [Errors.ErrorWhenDeletingManyViewings]: `Error deleting viewings (${Errors.ErrorWhenDeletingManyViewings})`,
  [Errors.ViewingOrchestratedByIDRequired]: `Error creating viewings (${Errors.ViewingOrchestratedByIDRequired})`,
  [Errors.ViewingOrchestratorNotRegistered]: `Error creating viewings (${Errors.ViewingOrchestratorNotRegistered})`,
  [Errors.ErrGettingViewingOrchestrators]: `Error getting viewings creator (${Errors.ErrGettingViewingOrchestrators})`,

  [Errors.ErrorImportingKeySetsInvalidFile]: `Invalid file format (${Errors.ErrorImportingKeySetsInvalidFile})`,

  [Errors.ErrorViewingRunsInvalidRequest]: `Invalid Request (${Errors.ErrorViewingRunsInvalidRequest})`,
  [Errors.ErrorViewingRunsPreflight]: `Error sending request to modify value (${Errors.ErrorViewingRunsPreflight})`,
  [Errors.ErrorViewingRunsCreateRunGroups]: `Error creating viewing run groups (${Errors.ErrorViewingRunsCreateRunGroups})`,
  [Errors.ErrorViewingRunsCreate]: `Error creating viewing runs (${Errors.ErrorViewingRunsCreate})`,
  [Errors.ErrorViewingRunsInsertViewings]: `Error inserting viewing in run (${Errors.ErrorViewingRunsInsertViewings})`,
  [Errors.ErrorViewingRunsList]: `Error getting viewing run list (${Errors.ErrorViewingRunsList})`,
  [Errors.ErrorViewingRunsGet]: `Error getting viewing run list (${Errors.ErrorViewingRunsGet})`,
  [Errors.ErrorViewingRunsPopulateProperties]: `Error getting properties (${Errors.ErrorViewingRunsPopulateProperties})`,
  [Errors.ErrorViewingRunsPopulateViewings]: `Error populating viewings in run (${Errors.ErrorViewingRunsPopulateViewings})`,
  [Errors.ErrorViewingRunsDelete]: `Error deleting viewing run (${Errors.ErrorViewingRunsDelete})`,
  [Errors.ErrorViewingRunsNotExist]: `Viewing run does not exist (${Errors.ErrorViewingRunsNotExist})`,
  [Errors.ErrorViewingRunsUserContactNotExist]: `User not found (${Errors.ErrorViewingRunsUserContactNotExist})`,
  [Errors.ErrorGettingManagersForRentalReference]: `Error getting verified managers list (${Errors.ErrorGettingManagersForRentalReference})`,
  [Errors.CalendarPlatformNotSupported]: `Error processing request (${Errors.CalendarPlatformNotSupported})`,
  [Errors.ErrorSavingCalendarRecord]: `Error processing request (${Errors.ErrorSavingCalendarRecord})`,
  [Errors.ErrorDeletingCalendarRecord]: `Error processing request (${Errors.ErrorDeletingCalendarRecord})`,
  [Errors.ErrorRetrievingCalendarRecord]: `Error processing request (${Errors.ErrorRetrievingCalendarRecord})`,
  [Errors.ErrorCreatingGoogleCalendarEvent]: `Error processing request (${Errors.ErrorCreatingGoogleCalendarEvent})`,
  [Errors.ErrorInvalidCalendarPlatform]: `Error processing request (${Errors.ErrorInvalidCalendarPlatform})`,
  [Errors.ErrorInvalidCalendarGUID]: `Error processing request (${Errors.ErrorInvalidCalendarGUID})`,
  [Errors.ErrorCreatingCalendarActivity]: `Error processing request (${Errors.ErrorCreatingCalendarActivity})`,
  [Errors.ErrorRetrievingCalendarEvent]: `Error processing request (${Errors.ErrorRetrievingCalendarEvent})`,
  [Errors.ErrorDeletingMicrosoftCalendarEvent]: `Error processing request (${Errors.ErrorDeletingMicrosoftCalendarEvent})`,
  [Errors.ErrorLinkedCalendarNotFound]: `Error processing request (${Errors.ErrorLinkedCalendarNotFound})`,
  [Errors.ErrorSendingFailedCalendarEventByEmail]: `Error processing request (${Errors.ErrorSendingFailedCalendarEventByEmail})`,
  [Errors.ErrorFailedAccountTypeValidation]: `Invalid or Old account type (${Errors.ErrorFailedAccountTypeValidation})`,
  [Errors.InvalidPropertyDisclosureRequest]: `There was an error in processing your request. Please recheck your declarations (${Errors.InvalidPropertyDisclosureRequest})`,
  [Errors.ErrorRetrievingPropertyDisclosure]: `Error getting property disclosure information (${Errors.ErrorRetrievingPropertyDisclosure})`,
  [Errors.ErrorUpsertingPropertyDisclosure]: `Error adding property disclosure information (${Errors.ErrorUpsertingPropertyDisclosure})`,
  [Errors.ErrorInvalidCalendarAuthCode]: `Invalid authorisation code for calendar (${Errors.ErrorInvalidCalendarAuthCode})`,
  [Errors.ErrorSendingPropertyOnListEmailToManager]: `Error sending property onlist email to manager (${Errors.ErrorSendingPropertyOnListEmailToManager})`,
  [Errors.ErrorGettingEnableViewingCheckin]: `Error to get enable viewing checkin (${Errors.ErrorGettingEnableViewingCheckin})`,
  [Errors.ErrorInvalidEnableViewingCheckin]: `Error invalid enable viewing checkin (${Errors.ErrorInvalidEnableViewingCheckin})`,
  [Errors.ErrorGetViewingCheckinCountForProperty]: `Error get viewing checkin count for property (${Errors.ErrorGetViewingCheckinCountForProperty})`,
  [Errors.ErrorRequiredApplicantPropertyCheckIn]: `Error required applicant property checkin before apply (${Errors.ErrorRequiredApplicantPropertyCheckIn})`,
  [Errors.ErrorAuthorizingUserToAcceptLeaseOffer]: `Error while authorizing you to accept lease offer (${Errors.ErrorAuthorizingUserToAcceptLeaseOffer})`,
  [Errors.ErrorListingTeamRentalReferences]: `Error listing Rental References (${Errors.ErrorListingTeamRentalReferences})`,
  [Errors.ErrorGettingTeamRentalReference]: `Error getting Rental References (${Errors.ErrorGettingTeamRentalReference})`,
  [Errors.ErrorCountingTeamRentalReference]: `Error retrieving rental references (${Errors.ErrorCountingTeamRentalReference})`,
  [Errors.ErrorGettingMeAssigneeValue]: `Error assigning team member (${Errors.ErrorGettingMeAssigneeValue})`,
  [Errors.ErrorUpdatingTeamRentalReference]: `Error updating team member (${Errors.ErrorUpdatingTeamRentalReference})`,
  [Errors.ErrorInvalidAgency]: `Error: Invalid Agency (${Errors.ErrorInvalidAgency})`,
  [Errors.OnlyUserWhoRequestedCanVerify]: `Please log in as the user that requested access to this application (${Errors.OnlyUserWhoRequestedCanVerify})`,
  [Errors.ApplicantLastNameIsRequired]: `Applicant Last name is required (${Errors.ApplicantLastNameIsRequired})`,
  [Errors.ApplicantFirstNameIsRequired]: `Applicant first name is required (${Errors.ApplicantFirstNameIsRequired})`,
  [Errors.ApplicantEmailIsRequired]: `Applicant email is required (${Errors.ApplicantEmailIsRequired})`,
  [Errors.ApplicantMobileNumberIsRequired]: `Applicant mobile number is required (${Errors.ApplicantMobileNumberIsRequired})`,
  [Errors.ErrorGetApplicantDisclosureAuthority]: `Unable to complete request (${Errors.ErrorGetApplicantDisclosureAuthority})`,
  [Errors.ErrorCreateApplicantDisclosureAuthority]: `Unable to complete request (${Errors.ErrorCreateApplicantDisclosureAuthority})`,
  [Errors.ErrorUpdateApplicantDisclosureAuthority]: `Unable to complete request (${Errors.ErrorUpdateApplicantDisclosureAuthority})`,
  [Errors.ErrorNoChangeToAgencySource]: `No changes (${Errors.ErrorNoChangeToAgencySource})`,
  [Errors.ErrorInvalidViewingRegistrantLimit]: `Please select viewing registrants (${Errors.ErrorInvalidViewingRegistrantLimit})`,
  [Errors.ErrorPMSUnknownPMS]: `Unknown PMS (${Errors.ErrorPMSUnknownPMS})`,
  [Errors.ErrorPMSDataPullerIsNotImplemented]: `Data Puller for PMS not available (${Errors.ErrorPMSDataPullerIsNotImplemented})`,
  [Errors.ErrorPMSApplicationNotifierIsNotImplemented]: `Data Notifier for PMS not available (${Errors.ErrorPMSApplicationNotifierIsNotImplemented})`,
  [Errors.ErrorPMSAttributeNotEnabled]: `Update Profile before activating PropertyTree (${Errors.ErrorPMSAttributeNotEnabled})`,
  [Errors.ErrorPMSAttributeParse]: `Error sending PropertyTree data (${Errors.ErrorPMSAttributeParse})`,
  [Errors.ErrorPMSWebHookUnauthorized]: `PMS webhook not authorized (${Errors.ErrorPMSWebHookUnauthorized})`,
  [Errors.ErrorPMSWebHookInternalError]: `PMS webhook not available (${Errors.ErrorPMSWebHookInternalError})`,
  [Errors.ErrorPMSActivateError]: `Error activating PMS (${Errors.ErrorPMSActivateError})`,
  [Errors.ErrorPMSCreateIntegration]: `Error creating PMS integration (${Errors.ErrorPMSCreateIntegration})`,
  [Errors.ErrorPMSUpdateIntegrationConfig]: `Error updating PMS config (${Errors.ErrorPMSUpdateIntegrationConfig})`,
  [Errors.ErrorPMSGetIntegrationConfig]: `Error getting PMS config (${Errors.ErrorPMSGetIntegrationConfig})`,
  [Errors.ErrorPMSJobSubmitErr]: `Error submitting PMS job (${Errors.ErrorPMSJobSubmitErr})`,
  [Errors.ErrorPMSReadPMSParam]: `Error reading PMS parameters (${Errors.ErrorPMSReadPMSParam})`,
  [Errors.ErrorPMSReadRequestBody]: `Error reading PMS Request (${Errors.ErrorPMSReadRequestBody})`,
  [Errors.ErrorPMSWhileGettingIntegration]: `Error finding PMS integration (${Errors.ErrorPMSWhileGettingIntegration})`,
  [Errors.ErrorPMSReadLimitParam]: `PMS limit reached (${Errors.ErrorPMSReadLimitParam})`,
  [Errors.ErrorPMSInvalidActivateCredentials]: `Invalid PMS Credentials (${Errors.ErrorPMSInvalidActivateCredentials})`,
  [Errors.ErrorValidateApplicantIncome]: `Error updating income (${Errors.ErrorValidateApplicantIncome})`,
  [Errors.ErrorGetApplicantIncome]: `Error getting applicant imcome (${Errors.ErrorGetApplicantIncome})`,
  [Errors.ErrorCreateApplicantIncome]: `Error in adding applicant income (${Errors.ErrorCreateApplicantIncome})`,
  [Errors.ErrorUpdateApplicantIncome]: `Error updating applicant income (${Errors.ErrorUpdateApplicantIncome})`,
  [Errors.ErrorUpdateApplicationPreference]: `Error updating application Preference (${Errors.ErrorUpdateApplicationPreference})`,
  [Errors.ErrorSubmittingFormDisclosureForAttachmentTyped]: `Error:  you can only use PDF uploads for disclosures (${Errors.ErrorSubmittingFormDisclosureForAttachmentTyped})`,
  [Errors.InvalidPropertyDisclosureAttachmentsRequest]: `Error: Invalid file type (${Errors.InvalidPropertyDisclosureAttachmentsRequest})`,
  [Errors.ErrorSavingPropertyDisclosureAttachments]: `Error Saving Property Disclosure Attachments (${Errors.ErrorSavingPropertyDisclosureAttachments})`,
  [Errors.ErrorTryingToCreateAnotherViewingInSameDay]: `Cannot schedule another viewing in same day (${Errors.ErrorTryingToCreateAnotherViewingInSameDay})`,
  [Errors.ErrorGettingEmailRecords]: `Error getting email records (${Errors.ErrorGettingEmailRecords})`,
  [Errors.ErrorGettingSmsRecords]: `Error getting SMS records (${Errors.ErrorGettingSmsRecords})`,
  [Errors.ErrorPetMicrochipNumberRequired]: `Microchip number is required (${Errors.ErrorPetMicrochipNumberRequired})`,
  [Errors.ErrorPetMicrochipMaxLength]: `Microchip number should be 20 characters or less (${Errors.ErrorPetMicrochipMaxLength})`,
  [Errors.ErrorPetRegistrationNumberRequired]: `Registration number is required (${Errors.ErrorPetRegistrationNumberRequired})`,
  [Errors.ErrorPetRegistrationNumberMaxLength]: `Registration number should be 20 characters or less (${Errors.ErrorPetRegistrationNumberMaxLength})`,
  [Errors.ErrorInvalidShortCode]: `Unable to complete request (${Errors.ErrorInvalidShortCode})`,
  [Errors.ErrorWhenDeletingViewingSlot]: `Unable to complete request (${Errors.ErrorWhenDeletingViewingSlot})`,
  [Errors.ErrorAuthorizingAgencyForApplication]: `Unable to complete request (${Errors.ErrorAuthorizingAgencyForApplication})`,
  [Errors.UnauthorizedAgencyForApplication]: `Unable to complete request (${Errors.UnauthorizedAgencyForApplication})`,
  [Errors.ErrorUpdatingShareApplication]: `Error updating the shared application (${Errors.ErrorWhenSendingShareApplicationToTeamMember})`,
  [Errors.ErrorWhenSendingShareApplicationToTeamMember]: `Error sending share application confirmation to team member (${Errors.ErrorWhenSendingShareApplicationToTeamMember})`,
  [Errors.ErrorWhenCreatingShareApplicationStatus]: `Error creating share application status (${Errors.ErrorWhenCreatingShareApplicationStatus})`,
  [Errors.ErrorShareApplicationExpired]: `Shared application link is expired (${Errors.ErrorShareApplicationExpired})`,
  [Errors.ErrorInvalidBuildingGUID]: `Error getting the building details (${Errors.ErrorInvalidBuildingGUID})`,
  [Errors.ErrorGettingBuilding]: `Error getting the building details (${Errors.ErrorGettingBuilding})`,
  [Errors.ErrorBuildingNotFound]: `Building not found (${Errors.ErrorBuildingNotFound})`,
  [Errors.ErrorBuildingLimitReached]: `Building limit reached (${Errors.ErrorBuildingLimitReached})`,
  [Errors.ErrorGettingBuildingCount]: `Error getting building details (${Errors.ErrorGettingBuildingCount})`,
  [Errors.ErrorDeletingBuildingAssignments]: `Error removing building assignment (${Errors.ErrorDeletingBuildingAssignments})`,
  [Errors.ErrorBuildingSlugRequired]: `Error getting the building details (${Errors.ErrorBuildingSlugRequired})`,
  [Errors.ErrorAssigningManagerForApplication]: `Error while assigning application manager (${Errors.ErrorAssigningManagerForApplication})`,
  [Errors.FailedToDecryptDocAttachment]: `Failed to decrypt attachment (${Errors.FailedToDecryptDocAttachment})`,
  [Errors.InvalidDocAttachment]: `Invalid attachment (${Errors.InvalidDocAttachment})`,
  [Errors.ErrorSendingPropertyDisclosureEmailRequestData]: `Error sending request data (${Errors.ErrorSendingPropertyDisclosureEmailRequestData})`,
  [Errors.ErrorReadingGUIDParameter]: `Error reading parameters for disclosure request (${Errors.ErrorReadingGUIDParameter})`,
  [Errors.ErrorResolvingPropertyDisclosureRequest]: `Disclosure request does not exist (${Errors.ErrorResolvingPropertyDisclosureRequest})`,
  [Errors.ErrorCreatingPropertyDisclosureRequest]: `Error creating disclosure request (${Errors.ErrorCreatingPropertyDisclosureRequest})`,
  [Errors.ErrorUpdatingPropertyDisclosureRequest]: `Error updating disclosure request (${Errors.ErrorUpdatingPropertyDisclosureRequest})`,
  [Errors.ErrorResolvingOwner]: `Error resolving owner for the property (${Errors.ErrorResolvingOwner})`,
  [Errors.ApplicationAlreadyAssignedToTargetProperty]: `Application already assigned to this property (${Errors.ApplicationAlreadyAssignedToTargetProperty})`,
  [Errors.ErrorPropertyDisclosureAlreadySubmitted]: `Disclosures already submitted (${Errors.ErrorPropertyDisclosureAlreadySubmitted})`,
  [Errors.ErrorInvalidChildrenCount]: `Children are not allowed (${Errors.ErrorInvalidChildrenCount})`,
  [Errors.ErrorInvalidRegistrantData]: `Invalid registration data (${Errors.ErrorInvalidRegistrantData})`,
  [Errors.ErrorCreatingVehicles]: `Error adding vehicle information (${Errors.ErrorCreatingVehicles})`,
  [Errors.ErrorUpdatingVehicles]: `Error updating vehicle information (${Errors.ErrorUpdatingVehicles})`,
  [Errors.ErrorRetrievingVehicles]: `Error getting vehicle information (${Errors.ErrorRetrievingVehicles})`,
  [Errors.ErrorInvalidUnsubPageToken]: `Invalid page (${Errors.ErrorInvalidUnsubPageToken})`,
  [Errors.ErrorUnsubTokenExpired]: `This URL is no longer valid (${Errors.ErrorUnsubTokenExpired})`,
  [Errors.ErrorCreatingInviteToApply]: `Unable to send invite (${Errors.ErrorCreatingInviteToApply})`,
  [Errors.ErrorRetrievingAgencySetupWizard]: `Error getting agency details (${Errors.ErrorRetrievingAgencySetupWizard})`,
  [Errors.ErrorWizardNotFound]: `Error getting wizard details (${Errors.ErrorWizardNotFound})`,
  [Errors.ErrorInvalidWizardStep]: `Error invalid wizard step (${Errors.ErrorInvalidWizardStep})`,
  [Errors.ErrorWhileUpdatingWizard]: `Error updating information (${Errors.ErrorWhileUpdatingWizard})`,
  [Errors.ErrorWhenReadingWizardID]: `Error reading wizard id (${Errors.ErrorWhenReadingWizardID})`,
  [Errors.ErrorSearchingBorrowers]: `Unable to search borrower (${Errors.ErrorSearchingBorrowers})`,
  [Errors.ErrorRetrievingUsers]: `Error retrieving users (${Errors.ErrorRetrievingUsers})`,
  [Errors.ErrorLookingUpEmployer]: `Unable to find employer (${Errors.ErrorLookingUpEmployer})`,
  [Errors.ErrorEndDateBeforeStartDate]: `End date cannot be before start date (${Errors.ErrorEndDateBeforeStartDate})`,
  [Errors.ErrorUpdatingEmployerEndDate]: `Unable to update end date for employment (${Errors.ErrorUpdatingEmployerEndDate})`,
  [Errors.ErrorLookingUpAddressHistory]: `Unable to find address to update (${Errors.ErrorLookingUpAddressHistory})`,
  [Errors.ErrorUpdatingRentalHistoryEndDate]: `Unable to update end date for address (${Errors.ErrorUpdatingRentalHistoryEndDate})`,
  [Errors.ErrorMobileAlreadyRegistered]: `Mobile number already registered (${Errors.ErrorMobileAlreadyRegistered})`,
  [Errors.ErrorEmailAlreadyRegistered]: `Email already registered (${Errors.ErrorEmailAlreadyRegistered})`,
  [Errors.ErrorDeletingTeamMember]: `Error deleting team member (${Errors.ErrorDeletingTeamMember})`,
  [Errors.ErrorTeamMemberExistInAnotherTeams]: `Error deleting team member. Present in another team (${Errors.ErrorTeamMemberExistInAnotherTeams})`,
  [Errors.ErrorRentalHistoryGUIDRequired]: `Rental history id is required (${Errors.ErrorRentalHistoryGUIDRequired})`,
  [Errors.ErrorRentalHistoryNotFound]: `Rental history id is incorrect (${Errors.ErrorRentalHistoryNotFound})`,
  [Errors.ErrorFailedToUpdatePropertyListingAgent]: `Failed to update property listing agent (${Errors.ErrorFailedToUpdatePropertyListingAgent})`,
  [Errors.ErrorInvalidKeysDailyDigestNotificationValue]: `Invalid keys daily digest notification value (${Errors.ErrorInvalidKeysDailyDigestNotificationValue})`,
  [Errors.ErrorAboutMeIsRequired]: `About Us is required (${Errors.ErrorAboutMeIsRequired})`,
  [Errors.ErrorHouseholdYearlyIncomeNotValid]: `Invalid household yearly income (${Errors.ErrorHouseholdYearlyIncomeNotValid})`,
  [Errors.ErrorInvalidAddressHistoryRent]: `Rent amount must be $999999 or less (${Errors.ErrorInvalidAddressHistoryRent})`,
  [Errors.ErrorParsingShareApplicationBGCApprovalAttrs]: `Invalid values for share application with owner request BGC option (${Errors.ErrorParsingShareApplicationBGCApprovalAttrs})`,
  [Errors.ErrorCreatingReassignmentApplicationPropertyRecord]: `Failed to create application reassignment record (${Errors.ErrorCreatingReassignmentApplicationPropertyRecord})`,
  [Errors.ApplicationAlreadyAssignedToTargetProperty]: `Application already assigned to selected property (${Errors.ApplicationAlreadyAssignedToTargetProperty})`,
  [Errors.ErrorInvalidDefaultSelectedViewingDuration]: `Invalid viewing duration (${Errors.ErrorInvalidDefaultSelectedViewingDuration})`,
  [Errors.ErrorInvalidDefaultSelectedViewingDurationEnabled]: `Invalid default selected viewing duration enabled (${Errors.ErrorInvalidDefaultSelectedViewingDurationEnabled})`,
  [Errors.ErrorInvalidDefaultSelectedViewingDurationValue]: `Invalid default selected viewing duration value (${Errors.ErrorInvalidDefaultSelectedViewingDurationValue})`,
  [Errors.ErrorInvalidNewManagementBDMLeadValue]: `Invalid new management lead value (${Errors.ErrorInvalidNewManagementBDMLeadValue})`,
  [Errors.ErrorInvalidRentvestorBDMLeadValue]: `Invalid rentvestor management lead value (${Errors.ErrorInvalidRentvestorBDMLeadValue})`,
  [Errors.ErrorInvalidBDMLeadEmailValue]: `Invalid bdm email value (${[
    Errors.ErrorInvalidBDMLeadEmailValue,
  ]})`,
  [Errors.ErrorInvalidBDMLeadStatesValue]: `Invalid bdm states value (${Errors.ErrorInvalidBDMLeadStatesValue})`,
  [Errors.ErrorBDMStatesRequired]: `BDM states required (${Errors.ErrorBDMStatesRequired})`,
  [Errors.ErrorInvalidOptedToHavePropertyInvestorValue]: `Invalid check property investment option (${Errors.ErrorInvalidOptedToHavePropertyInvestorValue})`,
  [Errors.ErrorInvalidPropertyInvestmentPostcode]: `Invalid property investment postcode(s) (${Errors.ErrorInvalidPropertyInvestmentPostcode})`,
  [Errors.ErrorMissingPostcodes]: `Postcodes required (${Errors.ErrorMissingPostcodes})`,
  [Errors.ErrorInvalidInvestmentProfile]: `Investment profile is required (${Errors.ErrorInvalidInvestmentProfile})`,
  [Errors.ErrorRetrievingFollows]: `Cannot retrieve follows data (${Errors.ErrorRetrievingFollows})`,
  [Errors.ErrorUpdatingFollows]: `Cannot update follows (${Errors.ErrorUpdatingFollows})`,
  [Errors.ErrorFailedToRetrievingViewingsMode]: `Error retrieving viewings mode (${Errors.ErrorFailedToRetrievingViewingsMode})`,
  [Errors.ErrorViewingInspectorsRequired]: `Viewing inspectors are required (${Errors.ErrorViewingInspectorsRequired})`,
  [Errors.ErrorInvalidViewingInspectorGUID]: `Invalid viewing inspector (${Errors.ErrorInvalidViewingInspectorGUID})`,
  [Errors.RentalRequestCanNotBeDeclined]: `Error: This reference has already been completed (${Errors.RentalRequestCanNotBeDeclined})`,
  [Errors.ErrorPersonalReferenceAlreadyResponded]: `Error: Personal reference request has already been recorded (${Errors.ErrorPersonalReferenceAlreadyResponded})`,
  [Errors.ErrorRetrievingPersonalReferenceQuestions]: `Error retrieving personal reference questions (${Errors.ErrorRetrievingPersonalReferenceQuestions})`,
  [Errors.ErrorCreatingPersonalReferenceSurveyResponse]: `Error submitting request (${Errors.ErrorCreatingPersonalReferenceSurveyResponse})`,
  [Errors.ErrorInvalidPersonalReferenceQuestionsVersion]: `Error getting correct question version (${Errors.ErrorInvalidPersonalReferenceQuestionsVersion})`,
  [Errors.ErrorRetrievingPersonalReferenceResponse]: `Error receiving personal reference response (${Errors.ErrorRetrievingPersonalReferenceResponse})`,
  [Errors.ErrorInvalidPersonalReferenceAnswerCode]: `Incorrect personal reference answer code (${Errors.ErrorInvalidPersonalReferenceAnswerCode})`,
  [Errors.ErrorInvalidPersonalReferenceAnswerValue]: `Incorrect personal reference answer value (${Errors.ErrorInvalidPersonalReferenceAnswerValue})`,
  [Errors.ErrorPersonalReferenceQuestionAnswerTypesMismatch]: `Incorrect answer type (${Errors.ErrorPersonalReferenceQuestionAnswerTypesMismatch})`,
  [Errors.ErrorGettingPersonalReferenceSendEnableAttribute]: `Error getting personal reference attribute (${Errors.ErrorGettingPersonalReferenceSendEnableAttribute})`,
  [Errors.ErrorSendingPersonalReferenceWhileAgencyDisablingFeature]: `Error getting personal reference attribute (${Errors.ErrorSendingPersonalReferenceWhileAgencyDisablingFeature})`,
  [Errors.ErrorCreatingSendEventForPersonalReference]: `Error sending request (${Errors.ErrorCreatingSendEventForPersonalReference})`,
  [Errors.ErrorRetrievingReportManagerInfo]: `Error retrieving contact details (${Errors.ErrorRetrievingReportManagerInfo})`,
  [Errors.ErrorInvalidPauseViewingsWhenApprovedApplication]: `Invalid pause-viewings-when-approved-application flag value (${Errors.ErrorInvalidPauseViewingsWhenApprovedApplication})`,
  [Errors.ErrorViewingsForThisPropertyArePaused]: `Viewings for this property are paused because of an approved application (${Errors.ErrorViewingsForThisPropertyArePaused})`,
  [Errors.ErrorUnableToCheckIsViewingPaused]: `Error unable to check is viewings paused (${Errors.ErrorUnableToCheckIsViewingPaused})`,
  [Errors.ErrorActiveOfferNotFound]: `Error active offer not found (${Errors.ErrorActiveOfferNotFound})`,
  [Errors.ErrorRetrievingApplicationsCount]: `Error retrieving applications count (${Errors.ErrorRetrievingApplicationsCount})`,
  [Errors.ErrorUpdatingExcludedProperties]: `Error updating excluded property (${Errors.ErrorUpdatingExcludedProperties})`,
  [Errors.ErrorCreatingExcludedProperties]: `Error creating excluded property (${Errors.ErrorCreatingExcludedProperties})`,
  [Errors.ErrorCreatingExcludedPropertyDates]: `Error updating excluded property dates (${Errors.ErrorCreatingExcludedPropertyDates})`,
  [Errors.ErrorDeletingExcludedPropertyDates]: `Error deleted excluded property dates (${Errors.ErrorCreatingExcludedPropertyDates})`,
  [Errors.ErrorReadingPropertyGUID]: `Error reading property guid (${Errors.ErrorReadingPropertyGUID})`,
  [Errors.ErrorInvalidOfferVacateDate]: `Error invalid offer vacate date (${Errors.ErrorInvalidOfferVacateDate})`,
  [Errors.ErrorInactiveAgencyProfile]: `Error: agency is inactive (${Errors.ErrorInactiveAgencyProfile})`,
  [Errors.ErrorViewingRegistrantAlreadyApplied]: `Error onsite registrant already has an application (${Errors.ErrorViewingRegistrantAlreadyApplied})`,
  [Errors.ErrorViewingMergeNotAllowed]: 'Viewing full, continue?',
  [Errors.ErrorEmailAndPhoneAreRequired]: `Both email and phone number are required (${Errors.ErrorEmailAndPhoneAreRequired})`,
  [Errors.ErrorNoPhoneOrEmailSendPersonalReference]: `Error: Email or Phone number must be provided (${Errors.ErrorNoPhoneOrEmailSendPersonalReference})`,
  [Errors.ErrorApplicantReachedAgencyApplicationsLimit]: `Agency limits active/open applications. Please withdraw another application or contact agency (${Errors.ErrorApplicantReachedAgencyApplicationsLimit})`,
  [Errors.ErrorFetchingTenantSettings]: `Could not complete request (${Errors.ErrorFetchingTenantSettings})`,
  [Errors.ErrorCreatingTenantSettings]: `Could not complete request (${Errors.ErrorCreatingTenantSettings})`,
  [Errors.ErrorUpdatingTenantSettings]: `Could not complete request (${Errors.ErrorUpdatingTenantSettings})`,
  [Errors.ErrorNamedOnLeaseIsRequired]: `Named on lease question is required (${Errors.ErrorNamedOnLeaseIsRequired})`,
  [Errors.ErrorTenancyTermIsRequired]: `Tenancy term question is required (${Errors.ErrorTenancyTermIsRequired})`,
  [Errors.ErrorRentOnTimeIsRequired]: `Rent on time question is required (${Errors.ErrorRentOnTimeIsRequired})`,
  [Errors.ErrorRentalAmountIsRequired]: `Rental amount question is required (${Errors.ErrorNamedOnLeaseIsRequired})`,
  [Errors.InvalidAgencyUserRoleForSnugTeamMember]: `Invalid role for a snug team member (${Errors.InvalidAgencyUserRoleForSnugTeamMember})`,
  [Errors.CommentIsRequired]: `Relationship type is required (${Errors.CommentIsRequired})`,
  [Errors.ErrorWhenRetrievingUtilityLead]: `Error retrieving utility lead (${Errors.ErrorWhenRetrievingUtilityLead})`,
  [Errors.ErrorUtilityLeadNotFound]: `Utility lead not found (${Errors.ErrorUtilityLeadNotFound})`,
  [Errors.ErrorInvalidApplicationStatusForUtilityLead]: `Invalid application status, utility lead didn't send (${Errors.ErrorInvalidApplicationStatusForUtilityLead})`,
  [Errors.ErrorCreatingAutoresponderAttachment]: `Error creating attachment (${Errors.ErrorCreatingAutoresponderAttachment})`,
  [Errors.ErrorInvalidHousingCategory]: `Invalid category (${Errors.ErrorInvalidHousingCategory})`,
  [Errors.InvalidAutoresponderAttachmentFilesNumber]: `Can't process selected files (${Errors.InvalidAutoresponderAttachmentFilesNumber})`,
  [Errors.ErrorMaxAutoresponderAttachmentsReached]: `This category reached the limit attachments (${Errors.ErrorMaxAutoresponderAttachmentsReached})`,
  [Errors.ErrorListingAutoresponderAttachments]: `Error listing autoresponder attachments (${Errors.ErrorListingAutoresponderAttachments})`,
  [Errors.ErrorAttachmentNotFound]: `Attachment not found (${Errors.ErrorAttachmentNotFound})`,
  [Errors.ErrorDeletingAutoresponderAttachment]: `Error deleting attachment (${Errors.ErrorDeletingAutoresponderAttachment})`,
  [Errors.ErrorUpdatingAutoresponderAttachment]: `Error updating attachment (${Errors.ErrorUpdatingAutoresponderAttachment})`,
  [Errors.ErrorPreviousTenanciesTerminationRequired]: `Previous tenancies termination question required (${Errors.ErrorPreviousTenanciesTerminationRequired})`,
  [Errors.ErrorPreviousTenanciesTerminationDetailsRequired]: `Previous tenancies termination details required (${Errors.ErrorPreviousTenanciesTerminationDetailsRequired})`,
  [Errors.ErrorAnotherLessorOrAgentDebtRequired]: `Another lessor or agent debt question required (${Errors.ErrorAnotherLessorOrAgentDebtRequired})`,
  [Errors.ErrorAnotherLessorOrAgentDebtDetailsRequired]: `Another lessor or agent debt details required (${Errors.ErrorAnotherLessorOrAgentDebtDetailsRequired})`,
  [Errors.ErrorReasonAffectRentPaymentRequired]: `Reason affect rent payment question required (${Errors.ErrorReasonAffectRentPaymentRequired})`,
  [Errors.ErrorReasonAffectRentPaymentDetailsRequired]: `Reason affect rent payment details required (${Errors.ErrorReasonAffectRentPaymentDetailsRequired})`,
  [Errors.ErrorOwnPropertiesOrLandRequired]: `Own properties or land question required (${Errors.ErrorOwnPropertiesOrLandRequired})`,
  [Errors.ErrorOwnPropertiesOrLandDetailsRequired]: `Own properties or land details required (${Errors.ErrorOwnPropertiesOrLandDetailsRequired})`,
  [Errors.ErrorTeamMemberAssociationRequired]: `Team member association question required (${Errors.ErrorTeamMemberAssociationRequired})`,
  [Errors.ErrorGettingDisclosuresData]: `Error getting disclosures data (${Errors.ErrorGettingDisclosuresData})`,
  [Errors.ErrorPreviousTenanciesTerminationRequired]: `Previous tenancies termination question required (${Errors.ErrorPreviousTenanciesTerminationRequired})`,
  [Errors.ErrorPreviousTenanciesTerminationDetailsRequired]: `Previous tenancies termination details required (${Errors.ErrorPreviousTenanciesTerminationDetailsRequired})`,
  [Errors.ErrorAnotherLessorOrAgentDebtRequired]: `Another lessor or agent debt question required (${Errors.ErrorAnotherLessorOrAgentDebtRequired})`,
  [Errors.ErrorAnotherLessorOrAgentDebtDetailsRequired]: `Another lessor or agent debt details required (${Errors.ErrorAnotherLessorOrAgentDebtDetailsRequired})`,
  [Errors.ErrorReasonAffectRentPaymentRequired]: `Reason affect rent payment question required (${Errors.ErrorReasonAffectRentPaymentRequired})`,
  [Errors.ErrorReasonAffectRentPaymentDetailsRequired]: `Reason affect rent payment details required (${Errors.ErrorReasonAffectRentPaymentDetailsRequired})`,
  [Errors.ErrorOwnPropertiesOrLandRequired]: `Own properties or land question required (${Errors.ErrorOwnPropertiesOrLandRequired})`,
  [Errors.ErrorOwnPropertiesOrLandDetailsRequired]: `Own properties or land details required (${Errors.ErrorOwnPropertiesOrLandDetailsRequired})`,
  [Errors.ErrorTeamMemberAssociationRequired]: `Team member association question required (${Errors.ErrorTeamMemberAssociationRequired})`,
  [Errors.ErrorGettingDisclosuresData]: `Error getting disclosures data (${Errors.ErrorGettingDisclosuresData})`,
  [Errors.ErrorOnlyMobileNumberIsAccepted]: `Invalid Number (e.g. +61 491 570 156) (${Errors.ErrorOnlyMobileNumberIsAccepted})`,
  [Errors.ErrorOneAgencyAdminIsRequired]: `Error one agency admin is required (${Errors.ErrorOneAgencyAdminIsRequired})`,
  [Errors.ErrorInvalidSource]: `Error invalid source (${Errors.ErrorInvalidSource})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocAusDLACTCardNumberInvalid]: `Card number should be 10 alphanumeric characters (${Errors.IdentityDocsErrors.ErrorIdentityDocAusDLACTCardNumberInvalid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocAusDLNTCardNumberInvalid]: `Card number should be 6 - 8 numeric characters (${Errors.IdentityDocsErrors.ErrorIdentityDocAusDLNTCardNumberInvalid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocAusDLQLDCardNumberInvalid]: `Card number should be 10 alphanumeric characters (${Errors.IdentityDocsErrors.ErrorIdentityDocAusDLQLDCardNumberInvalid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocAusDLNSWCardNumberInvalid]: `Card number should be 10 numeric characters (${Errors.IdentityDocsErrors.ErrorIdentityDocAusDLNSWCardNumberInvalid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocAusDLSACardNumberInvalid]: `Card number should be 9 alphanumeric characters (${Errors.IdentityDocsErrors.ErrorIdentityDocAusDLSACardNumberInvalid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocAusDLTASCardNumberInvalid]: `Card number should be 9 alphanumeric characters (${Errors.IdentityDocsErrors.ErrorIdentityDocAusDLTASCardNumberInvalid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocAusDLVICCardNumberInvalid]: `Card number should be 8 alphanumeric characters (${Errors.IdentityDocsErrors.ErrorIdentityDocAusDLVICCardNumberInvalid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocAusDLWACardNumberInvalid]: `Card number should be 8 - 10 alphanumeric characters (${Errors.IdentityDocsErrors.ErrorIdentityDocAusDLWACardNumberInvalid})`,
  [Errors.IdentityDocsErrors
    .ErrorIdentityDocAusDLMatchCardNumber]: `Card number should be different from Driver Licence number (${Errors.IdentityDocsErrors.ErrorIdentityDocAusDLMatchCardNumber})`,
  [Errors.ErrorListingKeySets]: `Unable to list keys (${Errors.ErrorListingKeySets})`,
  [Errors.ErrorCreatingKeySet]: `Error creating key set (${Errors.ErrorCreatingKeySet})`,
  [Errors.ErrorTransitingKeySetStatus]: `Error changing key set status (${Errors.ErrorTransitingKeySetStatus})`,
  [Errors.ErrorRetrievingKeySet]: `Error retrieving key set (${Errors.ErrorRetrievingKeySet})`,
  [Errors.ErrorKeySetNotFound]: `Key set not found (${Errors.ErrorKeySetNotFound})`,
  [Errors.ErrorBorrowerNotFound]: `Borrower not found (${Errors.ErrorBorrowerNotFound})`,
  [Errors.ErrorInvalidKeySetStatus]: `Invalid key set (${Errors.ErrorInvalidKeySetStatus})`,
  [Errors.ErrorImportingKeySets]: `Error importing key set (${Errors.ErrorImportingKeySets})`,
  [Errors.ErrorExternalTeamIDDisabled]: `External Team ID is disabled (${Errors.ErrorExternalTeamIDDisabled})`,
  [Errors.ErrorCreatingAliasTeamID]: `Error activating, please ensure value is unique (${Errors.ErrorCreatingAliasTeamID})`,
  [Errors.ErrorDeletingAliasTeamID]: `Error removing (${Errors.ErrorDeletingAliasTeamID})`,
  [Errors.ErrorInvalidInspectedDate]: `Inspection date should be in the past (${Errors.ErrorInvalidInspectedDate})`,
  [Errors.ErrorExternalTeamIDInvalid]: `External Team ID is invalid (${Errors.ErrorExternalTeamIDInvalid})`,
  [Errors.ErrorUnableToGetAgencyDetails]: `Unable to get agency details (${Errors.ErrorUnableToGetAgencyDetails})`,
  [Errors.ErrorCantUpdateSanitizedDocument]: `Can't update sanitized document (${Errors.ErrorCantUpdateSanitizedDocument})`,
  [Errors.ErrorCheckingForNewActivityFeed]: `Failed to check new activity feed (${Errors.ErrorCantUpdateSanitizedDocument})`,
  [Errors.ErrorInvalidListingFiltersRequest]: `Invalid activity listing request (${Errors.ErrorInvalidListingFiltersRequest})`,
  [Errors.ErrorFetchingActivitiesFeedList]: `Failed to fetch activities feed list (${Errors.ErrorInvalidListingFiltersRequest})`,
  [Errors.ErrorUnableToSendInviteToApplyApplicationRequestValidation]: `Please check the parameters (${Errors.ErrorUnableToSendInviteToApplyApplicationRequestValidation})`,
  [Errors.ErrorUnableToSendInviteToApplyApplicationAuthorisationError]: `Unable to authorise user when sending request (${Errors.ErrorUnableToSendInviteToApplyApplicationAuthorisationError})`,
  [Errors.ErrorUnableToSendInviteToApplySaveError]: `Unable to save record for request (${Errors.ErrorUnableToSendInviteToApplyApplicationAuthorisationError})`,
  [Errors.ErrorUnableToSendInviteToApplyEmail]: `Unable to send invite to apply email (${Errors.ErrorUnableToSendInviteToApplyEmail})`,
  [Errors.ErrorCreatingCompletedBackgroundCheckConfirmationEmail]: `Error creating BG Check confirmation email (${Errors.ErrorCreatingCompletedBackgroundCheckConfirmationEmail})`,
  [Errors.ErrorSendingCompletedBackgroundCheckConfirmationEmail]: `Error sending BG Check confirmation email (${Errors.ErrorSendingCompletedBackgroundCheckConfirmationEmail})`,
  [Errors.ErrorInvalidApplicationBallotsEnabled]: `Unable to complete request, please try again (${Errors.ErrorInvalidApplicationBallotsEnabled})`,
  [Errors.ErrorInvalidApplicationSuccessfulBallotsSize]: `Invalid application successful ballot size (${Errors.ErrorInvalidApplicationSuccessfulBallotsSize})`,
  [Errors.ErrorInvalidApplicationReserveBallotsSize]: `Invalid application reserve ballot size (${Errors.ErrorInvalidApplicationReserveBallotsSize})`,
  [Errors.ErrorInvalidIDDocsNotRequested]: `Error parsing ID docs not requested setting (${Errors.ErrorInvalidIDDocsNotRequested})`,
  [Errors.ErrorAgencyUserIsNotAdmin]: `You do not have permission, please contact your team administrator. (${Errors.ErrorAgencyUserIsNotAdmin})`,
  [Errors.ErrorInvalidPropertyOwnershipOption]: `Please answer the property ownership question (${Errors.ErrorInvalidPropertyOwnershipOption})`,
  [Errors.ErrorIncomeExceedingMaxValid]: `Income exceeding maximum valid limit (${Errors.ErrorIncomeExceedingMaxValid})`,
  [Errors.ErrorInvalidApplicationLeaseOfferOperationsEmail]: `Please enter a valid lease operations email (${Errors.ErrorInvalidApplicationLeaseOfferOperationsEmail})`,
  [Errors.ErrorInvalidApplicationLeaseOfferAlwaysCopyLeaseOps]: `Lease ops email required before enabling always copying (${Errors.ErrorInvalidApplicationLeaseOfferAlwaysCopyLeaseOps})`,
  [Errors.ErrorInvalidApplicationLeaseOfferLeaseOpsAsContact]: `Lease ops email required before enabling using as contact (${Errors.ErrorInvalidApplicationLeaseOfferLeaseOpsAsContact})`,
  [Errors.ErrorRegistrantNotPreApplied]: `Pre-Apply then view the property (${Errors.ErrorRegistrantNotPreApplied})`,
  [Errors.ErrorInvalidArchiveFromFeed]: `Invalid archive from feed attribute, should be boolean (${Errors.ErrorInvalidArchiveFromFeed})`,
  [Errors.ErrorInvalidArchiveLeased]: `Invalid archive leased, should be boolean (${Errors.ErrorInvalidArchiveLeased})`,
  [Errors.ErrorWhenDeletingAlertPreference]: `Error undo-ing alert preference (${Errors.ErrorWhenDeletingAlertPreference})`,
  [Errors.ErrorGeneratingTICARequestHashedDocuments]: `Error generating applicant request (${Errors.ErrorGeneratingTICARequestHashedDocuments})`,
  [Errors.ErrorBackgroundCheckAlreadyBeingProcessed]: `Background check already being processed (${Errors.ErrorBackgroundCheckAlreadyBeingProcessed})`,
  [Errors.ErrorGettingSmsListCount]: `Error loading sms list (${Errors.ErrorGettingSmsListCount})`,
  [Errors.ErrorGettingSmsList]: `Error loading sms list ${Errors.ErrorGettingSmsList})`,
  [Errors.ErrorEmptyRefereeType]: `Referee type is invalid ${Errors.ErrorEmptyRefereeType})`,
  [Errors.ErrorApplicationNotEligibleForBgCRun]: `Application should be at least pre-approved (${Errors.ErrorApplicationNotEligibleForBgCRun})`,
  [Errors.ErrorInvalidAttribute]: `Invalid input for attribute (${Errors.ErrorInvalidAttribute})`,
  [Errors.ErrorCheckingApplicationBgCEligibility]: `Error running background check for application (${Errors.ErrorCheckingApplicationBgCEligibility})`,
  [Errors.ErrorSourceApplicationIsNotActive]: `Source application should be active (${Errors.ErrorSourceApplicationIsNotActive})`,
  [Errors.ErrorApplicationsNotForSamePropertyMergeNotAllowed]: `Merged applications should be for same property (${Errors.ErrorApplicationsNotForSamePropertyMergeNotAllowed})`,
  [Errors.ErrorApplicantsExceedingLimitForRequestedMerge]: `Merged application will exceed applicants count limit (${Errors.ErrorApplicantsExceedingLimitForRequestedMerge})`,
  [Errors.ErrorDestinationApplicationIsNotActive]: `Destination application should be active (${Errors.ErrorDestinationApplicationIsNotActive})`,
  [Errors.ErrorApplicationMergeRequestAlreadyExists]: `Merge request already exist (${Errors.ErrorApplicationMergeRequestAlreadyExists})`,
  [Errors.ErrorApplicationAlreadyMerged]: `Application has already been merged (${Errors.ErrorApplicationAlreadyMerged})`,
  [Errors.ErrorInviteAlreadyAccepted]: `Invite already accepted (${Errors.ErrorInviteAlreadyAccepted})`,
  [Errors.ErrorInviteHasBeenCanceled]: `Invite no longer valid (${Errors.ErrorInviteHasBeenCanceled})`,
  [Errors.ErrorCRMIDRequired]: `CRM ID is required (${Errors.ErrorCRMIDRequired})`,
  [Errors.ErrorCRMIDShouldBeNumber]: `CRM ID should be a number (${Errors.ErrorCRMIDShouldBeNumber})`,
  [Errors.ErrorSortingApplications]: `Error sorting applications (${Errors.ErrorSortingApplications})`,
  [Errors.ErrorManagerRunningBgCForSanitizedDoc]: `Outdated ID documents. Please request updated documents (${Errors.ErrorManagerRunningBgCForSanitizedDoc})`,
  [Errors.ErrorRenterRunningBgCForSanitizedDoc]: `Outdated ID documents. Please update (${Errors.ErrorRenterRunningBgCForSanitizedDoc})`,
  [Errors.ErrorImportingEnquirersInvalidFile]: `Invalid file format (${Errors.ErrorImportingEnquirersInvalidFile})`,
  [Errors.ErrorImportingEnquirers]: `Error importing enquirers (${Errors.ErrorImportingEnquirers})`,
  [Errors.ErrorInvalidImportEnquirersPayload]: `Error importing enquirers (${Errors.ErrorInvalidImportEnquirersPayload})`,
  [Errors.ErrorRenterRunningBgcForInvalidDoc]: `Invalid ID document. Please update (${Errors.ErrorRenterRunningBgcForInvalidDoc})`,
  [Errors.ErrorUpdatingViewingRegistrantShortlist]: `Error updating shortlist status (${Errors.ErrorUpdatingViewingRegistrantShortlist})`,
  [Errors.InvalidImageFileType]: `Invalid image file type, supported: png, jpg, jpeg (${Errors.InvalidImageFileType})`,
  [Errors.InvalidDocFileType]: `Invalid document file type, supported: pdf, doc, xlsx (${Errors.InvalidDocFileType})`,
  [Errors.InvalidDocAndImageFileType]: `Invalid document and image file type, supported: pdf, doc, xlsx, png, jpg, jpeg (${Errors.InvalidDocAndImageFileType})`,
  [Errors.ErrorPMSKolmeoSupplierSourceNotConfigured]: `Kolmeo feed supplier source must be configured before activate Kolmeo PMS (${Errors.ErrorPMSKolmeoSupplierSourceNotConfigured})`,
  [Errors.ErrorPMSIntegrationBundleRequired]: `Integration Bundle must be enabled before activating (${Errors.ErrorPMSIntegrationBundleRequired})`,

  [Errors.ErrorBackgroundCheckBillingNotEnabled]: `Billing not enabled, please contact support (${Errors.ErrorBackgroundCheckBillingNotEnabled})`,
  [Errors.ErrorCreatingBillingEvent]: `Error creating billing event (${Errors.ErrorCreatingBillingEvent})`,
  [Errors.ErrorGettingTeamBillingPlanSubscriptionIDAttribute]: `Unable to get team billing plan (${Errors.ErrorGettingTeamBillingPlanSubscriptionIDAttribute})`,
  [Errors.ErrorGettingTeamBillingBGCSubscriptionItemIDAttribute]: `Unable to get Background Check billing item (${Errors.ErrorGettingTeamBillingBGCSubscriptionItemIDAttribute})`,
  [Errors.ErrorRetrievingAuditLog]: `Error retrieving audit log (${Errors.ErrorRetrievingAuditLog})`,
  [Errors.ErrorCreatingAuditLog]: `Error creating audit log (${Errors.ErrorCreatingAuditLog})`,
  [Errors.ErrorInvalidAuditLog]: `Invalid audit log (${Errors.ErrorInvalidAuditLog})`,
  [Errors.ErrorGettingStripeInvoice]: `Error getting Stripe invoice (${Errors.ErrorGettingStripeInvoice})`,
  [Errors.ErrorSubscriptionTeamIDNotEnabled]: `Subscription team ID not enabled (${Errors.ErrorSubscriptionTeamIDNotEnabled})`,

  [Errors.ErrorIncrementingBillingProviderUsage]: `Error incrementing billing provider usage (${Errors.ErrorIncrementingBillingProviderUsage})`,
  [Errors.ErrorUpdatingBillingEvent]: `Error updating billing event (${Errors.ErrorUpdatingBillingEvent})`,
  [Errors.ErrorParsingBillingProviderResponse]: `Error getting billing provider response (${Errors.ErrorParsingBillingProviderResponse})`,
  [Errors.ErrorParsingBillingProviderRequest]: `Error getting billing provider request (${Errors.ErrorParsingBillingProviderRequest})`,

  [Errors.ErrorDeactivatingTeamBGC]: `Error deactivating team BG Check (${Errors.ErrorDeactivatingTeamBGC})`,
  [Errors.ErrorDeactivatingTeamBGCEvent]: `Error deactivating team BG check (${Errors.ErrorDeactivatingTeamBGCEvent})`,
  [Errors.ErrorActivatingTeamBGC]: `Error activating team BG Check (${Errors.ErrorActivatingTeamBGC})`,
  [Errors.ErrorActivatingTeamBGCEvent]: `Error activing team BG check event (${Errors.ErrorActivatingTeamBGCEvent})`,
  [Errors.ErrorCreatingSubscriptionItemID]: `Error creating subscription item id (${Errors.ErrorCreatingSubscriptionItemID})`,
  [Errors.ErrorSubscriptionItemIDAlreadyPresent]: `Error subscription item id already present (${Errors.ErrorSubscriptionItemIDAlreadyPresent})`,
  [Errors.ErrorSubscriptionIDAlreadyPresent]: `Error subscription id already present (${Errors.ErrorSubscriptionIDAlreadyPresent})`,
  [Errors.ErrorGettingSubscriptionItemID]: `Error getting subscription item id (${Errors.ErrorGettingSubscriptionItemID})`,
  [Errors.ErrorGettingSubscriptionID]: `Error getting subscription id (${Errors.ErrorGettingSubscriptionID})`,
  [Errors.ErrorMinNumberOfPayslipRequiredNotMet]: `Minimum number of payslip required not met (${Errors.ErrorMinNumberOfPayslipRequiredNotMet})`,
  [Errors.ErrorMinPointsRequiredNotMet]: `Minimum number of points required not met (${Errors.ErrorMinPointsRequiredNotMet})`,
  [Errors.ErrorPrimaryIDRequired]: `Primary ID not uploaded (${Errors.ErrorPrimaryIDRequired})`,
  [Errors.ErrorMinDocRequiredNotMet]: `Minimum number of document required not met (${Errors.ErrorMinDocRequiredNotMet})`,
  [Errors.ErrorReasonForLeavingRequired]: `Reason for leaving required (${Errors.ErrorReasonForLeavingRequired})`,
}

export const translateErrorCodeToMessage = (errorCode, defaultMsg) => {
  let errorCodeNumber = errorCode
  if (typeof errorCode === 'object') {
    const { Sentry } = window
    Sentry && Sentry.captureException(errorCode)
    return 'There was an error processing your request'
  }
  if (typeof errorCode === 'string') {
    errorCodeNumber = isNaN(errorCode) ? errorCode : parseInt(errorCode, 10)
  }
  if (ErrorMessages[errorCodeNumber]) return ErrorMessages[errorCodeNumber]
  // TEMP: patch to not show the toast error when the user is not logged in
  try {
    if (
      typeof errorCode == 'string' &&
      errorCode.includes('please login to perform this action')
    )
      return
  } catch {}

  return (
    defaultMsg ||
    `Unable to complete request ${errorCodeNumber ? `(${errorCode})` : ''}`
  )
}

export default ErrorMessages
