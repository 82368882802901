import React from 'react'

const Quote = ({
  quote,
  isFeeDiscounted,
  isPricingVariationFormReadOnly,
  removeDiscountsClicked,
}) => {
  let quoteHtml = (
    <div className="contentBlock">
      <header>
        <span>No Quote Found</span>
      </header>
    </div>
  )
  if (quote && quote.guidID) {
    let { bondAmount, premium } = quote
    quoteHtml = (
      <div className="contentBlock">
        <header>
          <span>Quote</span>
        </header>
        <content>
          <p>
            Snug Fees: ${premium.toFixed(2)}
            {isFeeDiscounted && (
              <label className="ml10 is-fee-discounted">(discounted)</label>
            )}
            {isFeeDiscounted && !isPricingVariationFormReadOnly && (
              <label
                className="ml10 remove-discounts"
                onClick={() => removeDiscountsClicked()}
              >
                remove discounts
              </label>
            )}
            <br />
            Refund Amount: ${(bondAmount - premium).toFixed(2)}
            <br />
          </p>
        </content>
      </div>
    )
  }
  return <div>{quoteHtml}</div>
}

export default Quote
