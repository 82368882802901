import React from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Box, Flex, TextSpan } from 'app/components/design-system-components'
import SvgHelpOutlineRounded from 'app/components/design-system-components/icons/actions/HelpOutlineRounded'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { AppDocumentListItemSecondVersion } from 'app/sm/renter_applications/renter_application_detailed/components/app-document-list-item'
import { ApplicationDetailsNumbersComponent } from 'app/sm/renter_applications/renter_application_detailed/components/new_applicant-details'
import {
  getDateOfBirthText,
  SHORT_STANDARD_DATETIME,
} from 'app/utils/datetime/helpers'
import { AUTO_REMOVE_OUTDATED_DOCUMENTS_DAYS } from 'app/utils/text/helpers'

import 'app/match/apply/application_summary_before_submit/shared_components/identity_box/style.scss'

const SummaryIdentityDocumentBox = ({
  identityDoc,
  from = '',
  toggleDocumentsViewer,
}) => {
  const {
    middleName,
    firstName,
    lastName,
    dateOfBirth,
    expiryDate,
    docNumber,
    docType,
    state,
    country,
    identityDocAttachments = [],
    sanitizedAt,
    hashedDateOfBirth,
    guidID,
  } = identityDoc
  const formattedName = middleName
    ? `${firstName} ${middleName} ${lastName}`
    : `${firstName} ${lastName}`
  const showHashedDOB = !!sanitizedAt && hashedDateOfBirth !== '' // backward compatible
  const formattedDateOfBirth = moment(dateOfBirth).format('Do MMM YYYY')
  const formattedExpiryDate = moment(expiryDate).format('Do MMM YYYY')

  const documentCategory = docType === 1 ? 'Driver Licence' : 'Passport'
  const fromRenterSummary = from === 'renterSummary'
  let dobText = showHashedDOB
    ? hashedDateOfBirth
    : getDateOfBirthText(formattedDateOfBirth)

  return (
    <div
      className={`bc-hub-item-link card-block relative ${
        fromRenterSummary && 'no-box-shadow p0'
      }`}
    >
      <div className="card-wrapper">
        <div className="card-details pull-right card-info">
          <content>
            <div className="identity-name">{formattedName}</div>
            <div className="application-details-numbers-box display-flex flex-wrap">
              <ApplicationDetailsNumbersComponent
                title="Document"
                text={documentCategory}
                componentClassName="width50p align-items-start flex-basis-25p flex-sp"
                titleClassName="identity-label"
                textClassName="identity-content"
              />
              <ApplicationDetailsNumbersComponent
                title="Number"
                text={docNumber}
                componentClassName="width50p align-items-start flex-basis-25p flex-sp"
                titleClassName="identity-label"
                textClassName="identity-content"
              />
              <ApplicationDetailsNumbersComponent
                title="Expiry"
                text={formattedExpiryDate}
                componentClassName="width50p align-items-start flex-basis-25p flex-sp"
                titleClassName="identity-label"
                textClassName="identity-content"
              />
              <ApplicationDetailsNumbersComponent
                title="Date of Birth"
                text={dobText}
                componentClassName="width50p align-items-start flex-basis-25p flex-sp"
                titleClassName="identity-label"
                textClassName="identity-content"
              />
            </div>
            <div>
              {docType === 1 && (
                <ApplicationDetailsNumbersComponent
                  title="State"
                  text={state}
                  componentClassName="width50p align-items-start flex-basis-25p flex-sp"
                  titleClassName="identity-label"
                  textClassName="identity-content"
                />
              )}
              {docType === 2 && (
                <ApplicationDetailsNumbersComponent
                  title="Country"
                  text={country}
                  componentClassName="width50p align-items-start flex-basis-25p flex-sp"
                  titleClassName="identity-label"
                  textClassName="identity-content"
                />
              )}
              {identityDocAttachments.length > 0 && (
                <div>
                  <div>Attachments: </div>
                  <Flex flexWrap="wrap" mt={4}>
                    {identityDocAttachments.map((identityDocAttachment) => (
                      <Box mr={5}>
                        <AppDocumentListItemSecondVersion
                          titleClass="mt0 mb20"
                          document={identityDocAttachment}
                          key={identityDocAttachment.guidID}
                          disabled={!!sanitizedAt}
                          isSensitive
                          toggleDocumentsViewer={
                            toggleDocumentsViewer
                              ? () =>
                                  toggleDocumentsViewer(
                                    identityDocAttachment.guidID,
                                  )
                              : undefined
                          }
                        />
                      </Box>
                    ))}
                  </Flex>
                </div>
              )}

              {!!sanitizedAt && (
                <Flex flexDirection="row" alignItems="center">
                  <TextSpan
                    fontWeight={theme.fontWeights[3]}
                    fontSize={`${theme.space[4]}px`}
                    color={theme.colors.gray600}
                    mr={`${theme.space[3]}px`}
                  >
                    Sensitive data removed automatically on{' '}
                    {moment(sanitizedAt).format(SHORT_STANDARD_DATETIME)}{' '}
                  </TextSpan>
                  <SvgHelpOutlineRounded
                    color={theme.colors.gray600}
                    data-tooltip-id={`doc-message-${guidID}`}
                    data-tooltip-content=""
                  />

                  <ReactTooltip id={`doc-message-${guidID}`} data-type="info">
                    <span>
                      Sensitive data is hashed ####345 and documents deleted
                      after {AUTO_REMOVE_OUTDATED_DOCUMENTS_DAYS} days. Use
                      'Request Info' to SMS/Email invite a renter to upload
                      information.
                    </span>
                  </ReactTooltip>
                </Flex>
              )}
            </div>
          </content>
        </div>
      </div>
    </div>
  )
}
export default SummaryIdentityDocumentBox
