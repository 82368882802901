import React from 'react'

import {
  BarChart,
  CartesianGrid,
  Legend,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import styled from 'styled-components'

const StyledBarChart = styled(BarChart)`
  svg.recharts-surface tspan {
    font-size: 1.4rem !important;
  }
`

export const TeamActivityBarChart = ({
  className = '',
  heading = '',
  data = [],
  children,
}) => {
  return (
    <div className="display-flex flex-direction-column">
      <h4 className="activity-graph-heading">{heading}</h4>
      <StyledBarChart
        width={500}
        height={300}
        data={data}
        className={className}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" interval={0} />
        <YAxis interval="preserveStartEnd" />
        <Legend />
        {children}
      </StyledBarChart>
    </div>
  )
}

const StyledLineChart = styled(LineChart)`
  svg.recharts-surface tspan {
    font-size: 1.4rem !important;
  }
`

export const TeamActivityLineChart = ({
  heading = '',
  data = [],
  children,
  headingClass = '',
}) => {
  return (
    <div className="display-flex flex-direction-column">
      <h4 className={`activity-graph-heading ${headingClass}`}>{heading}</h4>
      <StyledLineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        <Legend />
        {children}
      </StyledLineChart>
    </div>
  )
}

export const LineChartDataLabel = ({ x, y, stroke, value }) => {
  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  )
}
