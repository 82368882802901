import React from 'react'

import { Formik } from 'formik'

import {
  Box,
  Flex,
  HorizontalLine,
  IconButton,
  TextSpan,
} from 'app/components/design-system-components'
import { ClearRounded } from 'app/components/design-system-components/icons/content'
import { Heading } from 'app/components/design-system-components/typography'
import {
  formFields,
  formValidation,
} from 'app/components/display/redirect/RedirectRentalReferenceModal/form.utils'
import { FormBody } from 'app/components/display/redirect/RedirectRentalReferenceModal/FormBody'
import { API_ERRORS_PROPERTY } from 'app/forms/FieldWrapper'
import { convertTransApiErrorToStatus } from 'app/forms/utils/convertTransApiErrorToStatus'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { ModalContentWrapper } from 'app/modals/modal-styled'
import { redirectRentalReferenceByPM } from 'app/services/http/rentalReferences'
import * as snugNotifier from 'app/services/snugNotifier'

export const RedirectRentalReferenceModal = ({
  teamId,
  rentalHistoryGUID,
  applicationId,
  applicantId,
  contact,
  closeModal,
}) => {
  const onDismiss = () => {
    closeModal()
  }

  const onSubmit = (values, { setStatus }) => {
    return redirectRentalReferenceByPM(
      teamId,
      rentalHistoryGUID,
      applicationId,
      applicantId,
      values,
    )
      .then(() => {
        closeModal(values)
        snugNotifier.success('Redirected successfully!')
      })
      .catch(({ message, detailedError }) => {
        if (detailedError) {
          setStatus({
            [API_ERRORS_PROPERTY]: convertTransApiErrorToStatus(detailedError),
          })
        } else {
          setStatus({ apiGeneralError: message })
        }
      })
  }

  const initialValues = contact || {
    [formFields.firstName]: '',
    [formFields.lastName]: '',
    [formFields.email]: '',
    [formFields.phone]: '',
    [formFields.officePhone]: '',
  }

  return (
    <Box className="modal-fullscreen" bg={theme.colors.overlay}>
      <ModalContentWrapper maxWidth="516px">
        <Box p={6}>
          <Flex alignItems="center" justifyContent="space-between" mb={6}>
            <Heading level={3} displayingLevel={3}>
              Redirect reference
            </Heading>

            <IconButton
              IconCmp={ClearRounded}
              iconProps={{ fontSize: theme.fontSizes[10] + 'px' }}
              variant="flat"
              onClick={onDismiss}
            />
          </Flex>
          <HorizontalLine />
        </Box>

        <Box p={6} pt="0">
          <TextSpan as="div">
            Enter the details of who to redirect the reference to.
          </TextSpan>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={formValidation}
            validateOnBlur
            validateOnChange
          >
            {(props) => <FormBody formBag={props} close={onDismiss} />}
          </Formik>
        </Box>
      </ModalContentWrapper>
    </Box>
  )
}
