import React from 'react'

import { Link } from 'react-router-dom'

import iconDocumentPdf from 'app/assets/icons/icon-document-pdf.svg'

function Documents() {
  return (
    <div className="sm-shadow-card documents">
      <div className="header xs-mb20">
        <h4>Quotes & Invoices</h4>
        <div className="controls">
          <button className="btn btn-gray btn-round btn-medium">
            <i className="icon-add"></i>
          </button>
        </div>
      </div>
      <div className="content">
        <a href="#" className="document-link">
          <div className="img-wraper">
            <img src={iconDocumentPdf} />
          </div>
          <span>
            <div>Workorder Sheet.pdf</div>
            <div className="fs16 fw400 gray-color mt3">24 Jun 2017</div>
          </span>
        </a>

        <a href="#" className="document-link mb0">
          <div className="img-wraper">
            <img src={iconDocumentPdf} />
          </div>
          <span>
            <div>Invoice_001/18.pdf</div>
            <div className="fs16 fw400 gray-color mt3">24 Jun 2017</div>
          </span>
        </a>
      </div>
    </div>
  )
}

export default Documents
