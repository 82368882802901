import React from 'react'

import PropTypes from 'prop-types'

import { Box } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  disclosureVersionedQuestions,
  getDisclosureLatestVersion,
  getDisclosureMissingVersion,
} from 'app/pages/teams/onlist/components/Disclosures/questions'

const DisclosureQuestions = ({
  answers,
  onChangeRadioGroup,
  updateDateInput,
  inputValueChange,
  version,
  areDisclosuresEditable,
  disclosureErrors = {},
}) => {
  if (!version) {
    // if version is missing: In editing (creating a disclosure), use the latest version, while in displaying assume the oldest one.
    version = areDisclosuresEditable
      ? getDisclosureLatestVersion()
      : getDisclosureMissingVersion()
  }

  if (!answers) return <></>

  return (
    <Box>
      <Box mb={6}>
        {disclosureVersionedQuestions.hasEmbeddedNetwork.render(
          answers.hasEmbeddedNetwork,
          disclosureErrors.hasEmbeddedNetwork,
          areDisclosuresEditable,
          onChangeRadioGroup,
          version,
        )}
      </Box>

      {answers.hasEmbeddedNetwork && (
        <Box>
          <Box mb={6}>
            {disclosureVersionedQuestions.embeddedNetworkName.render(
              answers.embeddedNetworkName,
              disclosureErrors.embeddedNetworkName,
              areDisclosuresEditable,
              'Embedded Network Name',
              inputValueChange,
              version,
            )}
          </Box>
          <Box mb={6}>
            {disclosureVersionedQuestions.embeddedNetworkAbn.render(
              answers.embeddedNetworkAbn,
              disclosureErrors.embeddedNetworkAbn,
              areDisclosuresEditable,
              'ABN',
              inputValueChange,
              version,
            )}
          </Box>
          <Box mb={6}>
            {disclosureVersionedQuestions.embeddedNetworkPhone.render(
              answers.embeddedNetworkPhone,
              disclosureErrors.embeddedNetworkPhone,
              areDisclosuresEditable,
              'Telephone',
              inputValueChange,
              version,
            )}
          </Box>
          <Box mb={6}>
            {disclosureVersionedQuestions.embeddedNetworkWebsite.render(
              answers.embeddedNetworkWebsite,
              disclosureErrors.embeddedNetworkWebsite,
              areDisclosuresEditable,
              'Website Link',
              inputValueChange,
              version,
            )}
          </Box>
          <Box mb={6}>
            {disclosureVersionedQuestions.embeddedNetworkPricing.render(
              answers.embeddedNetworkPricing,
              disclosureErrors.embeddedNetworkPricing,
              areDisclosuresEditable,
              'Pricing Link',
              inputValueChange,
              version,
            )}
          </Box>
        </Box>
      )}

      <Box mb={6}>
        {disclosureVersionedQuestions.isBeingSold.render(
          answers.isBeingSold,
          disclosureErrors.isBeingSold,
          areDisclosuresEditable,
          onChangeRadioGroup,
          version,
        )}
      </Box>

      <Box mb={6}>
        {disclosureVersionedQuestions.hasHomicide.render(
          answers.hasHomicide,
          disclosureErrors.hasHomicide,
          areDisclosuresEditable,
          onChangeRadioGroup,
          version,
        )}
      </Box>
      {answers.hasHomicide && (
        <Box mb={`${theme.space[4]}px`}>
          {disclosureVersionedQuestions.dateOfHomicide.render(
            answers.dateOfHomicide,
            disclosureErrors.dateOfHomicide,
            areDisclosuresEditable,
            updateDateInput,
            version,
          )}
        </Box>
      )}

      <Box mb={6}>
        {disclosureVersionedQuestions.isRegisteredPlace.render(
          answers.isRegisteredPlace,
          disclosureErrors.isRegisteredPlace,
          areDisclosuresEditable,
          onChangeRadioGroup,
          version,
        )}
      </Box>

      <Box mb={6}>
        {disclosureVersionedQuestions.dateOfDrugUsage.render(
          answers.dateOfDrugUsage,
          disclosureErrors.dateOfDrugUsage,
          areDisclosuresEditable,
          updateDateInput,
          version,
        )}
      </Box>

      <Box mb={6}>
        {disclosureVersionedQuestions.dateOfRepairNotice.render(
          answers.dateOfRepairNotice,
          disclosureErrors.dateOfRepairNotice,
          areDisclosuresEditable,
          updateDateInput,
          version,
        )}
      </Box>

      <Box mb={6}>
        {disclosureVersionedQuestions.compliesWithMinimumStandards.render(
          answers.compliesWithMinimumStandards,
          disclosureErrors.compliesWithMinimumStandards,
          areDisclosuresEditable,
          onChangeRadioGroup,
          version,
        )}
      </Box>

      <Box mb={6}>
        {disclosureVersionedQuestions.dateOfGasCheck.render(
          answers.dateOfGasCheck,
          disclosureErrors.dateOfGasCheck,
          areDisclosuresEditable,
          updateDateInput,
          version,
        )}
      </Box>

      <Box mb={6}>
        {disclosureVersionedQuestions.dateOfElectricityCheck.render(
          answers.dateOfElectricityCheck,
          disclosureErrors.dateOfElectricityCheck,
          areDisclosuresEditable,
          updateDateInput,
          version,
        )}
      </Box>

      <Box mb={6}>
        {disclosureVersionedQuestions.dateOfPoolCheck.render(
          answers.dateOfPoolCheck,
          disclosureErrors.dateOfPoolCheck,
          areDisclosuresEditable,
          updateDateInput,
          version,
        )}
      </Box>

      <Box mb={6}>
        {disclosureVersionedQuestions.hasAsbestos.render(
          answers.hasAsbestos,
          disclosureErrors.hasAsbestos,
          areDisclosuresEditable,
          onChangeRadioGroup,
          version,
        )}
      </Box>
      <Box mb={6}>
        {disclosureVersionedQuestions.isAffectedWithRelevantAuthorities.render(
          answers.isAffectedWithRelevantAuthorities,
          disclosureErrors.isAffectedWithRelevantAuthorities,
          areDisclosuresEditable,
          onChangeRadioGroup,
          version,
        )}
      </Box>
    </Box>
  )
}

DisclosureQuestions.propTypes = {
  answers: PropTypes.object,
  onChangeRadioGroup: PropTypes.func,
  updateDateInput: PropTypes.func,
  inputValueChange: PropTypes.func,
  version: PropTypes.number,
  areDisclosuresEditable: PropTypes.bool,
  disclosureErrors: PropTypes.object,
}

DisclosureQuestions.defaultProps = {
  onChangeRadioGroup: () => {},
  updateDateInput: () => {},
  inputValueChange: () => () => {},
}

export default DisclosureQuestions
