import React from 'react'

import styled from 'styled-components'

import Box from 'app/components/design-system-components/Box'
import Flex from 'app/components/design-system-components/Flex'
import SwitchButton from 'app/components/design-system-components/inputs/Switch'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledSwitchToNew = styled(Flex)`
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: ${theme.radii[2]}px;
  border: 1px solid ${theme.colors.primary300};
`

function SwitchToNew({
  text,
  onClickSwitch,
  switchValue,
  sendFeedback = false,
  feedbackSubject,
  buttonID = '',
}) {
  return (
    <StyledSwitchToNew justifyContent="space-between" alignItems="center" p={5}>
      <Flex alignItems="center">
        <SwitchButton
          onChange={onClickSwitch}
          value={switchValue}
          buttonID={buttonID}
        />
        <Box ml={5}>{text}</Box>
      </Flex>
      {sendFeedback && (
        <a href={`mailto:help@snug.com?Subject=${feedbackSubject}`}>
          Send Feedback
        </a>
      )}
    </StyledSwitchToNew>
  )
}

export default SwitchToNew
