import React, { useCallback, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import {
  Alert,
  AlertSensitiveInfo,
  Box,
  Flex,
  TextSpan,
} from 'app/components/design-system-components'
import Card from 'app/components/design-system-components/Card'
import { HelpOutlineRounded } from 'app/components/design-system-components/icons/actions'
import { CardIcon } from 'app/components/design-system-components/icons/snug-specific'
import SwitchButton from 'app/components/design-system-components/inputs/Switch'
import { unsubscribeCategoryOptions } from 'app/constants/unsubscribe.constants'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  authorizedSubscribe,
  authorizedUnSubscribe,
  getUserPreferenceStatus,
} from 'app/services/http/users'
import * as snugNotifier from 'app/services/snugNotifier'
import LoginFacebook from 'app/session/register_components/register_facebook'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'
import RentalReputation from 'app/sm/rental_reputation/connection'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

export const ProfileItemScreen = ({ children }) => (
  <div className="two-col-box-flex-row sm64">
    <div className="col-first">{children}</div>
  </div>
)

export const ProfileItem = ({ children, linkUrl, haveData }) => {
  const style = 'bc-hub-item-link' + (haveData ? ' have-data' : '')
  return (
    <Link className={style} to={linkUrl}>
      {children}
    </Link>
  )
}

export const ProfileItemHeader = ({ iconStyle, text }) => {
  return (
    <header>
      <i className={iconStyle} />
      <span>{text}</span>
    </header>
  )
}

export const ProfileItemContent = ({ children }) => {
  return <content className="display-flex">{children}</content>
}

export const ProfileAvatar = ({ currentUser }) => (
  <div className="cell-m pt5 pr20">
    {currentUser.avatar ? (
      <div
        className="avatar abatar-big"
        style={{ backgroundImage: `url(${currentUser.avatar})` }}
      />
    ) : (
      <div className="avatar blue-bg fs24">
        {currentUser.firstName
          ? currentUser.firstName.charAt(0).toUpperCase()
          : ''}
        {currentUser.lastName
          ? currentUser.lastName.charAt(0).toUpperCase()
          : ''}
      </div>
    )}
  </div>
)

const ProfileAccountInfo = ({ currentUser }) => (
  <div>
    {currentUser && currentUser.firstName + ' ' + currentUser.lastName}
    <br />
    {currentUser && currentUser.email}
    <br />
    {currentUser && currentUser.mobile}
  </div>
)

const StyledLink = styled(Link)`
  text-decoration: underline;
`

const Profile = ({
  currentUser,
  fetchRentalHistory,
  fetchCards,
  clearRentalBackUrl,
  addRentalReputationBackUrl,
  setBackUrl,
  ...props
}) => {
  const [propertyMatchNoticeEnabled, setPropertyMatchNoticeEnabled] =
    useState(false)
  const [matchNoticeToggleValue, setMatchNoticeToggleValue] = useState(false)

  const {
    loadingStates: preferencesFetchLoadingStates,
    loadingStatesHelpers: preferencesFetchLoadingHelpers,
  } = useLoadingStates()

  const {
    loadingStates: preferenceSubmitLoadingStates,
    loadingStatesHelpers: preferenceSubmitLoadingHelpers,
  } = useLoadingStates()

  useEffect(() => {
    fetchRentalHistory()
    fetchCards()
    clearRentalBackUrl()
    addRentalReputationBackUrl(urlTo('BCProfile'))
    setBackUrl('')
    loadPropertyMatchAlert()
  }, [])

  const loadPropertyMatchAlert = () => {
    preferencesFetchLoadingHelpers.startLoading()
    getUserPreferenceStatus(unsubscribeCategoryOptions.propertyMatchAlerts.id)
      .then((pref) => {
        setMatchNoticeToggleValue(pref?.isSubscribed)
        setPropertyMatchNoticeEnabled(pref?.isSubscribed)
        preferencesFetchLoadingHelpers.markDoneSuccessfully()
      })
      .catch(preferencesFetchLoadingHelpers.setError)
  }

  const debouncedSavePreferences = useCallback(
    debounce((isEnabled) => {
      let promise, successMsg
      if (isEnabled) {
        promise = authorizedSubscribe(
          unsubscribeCategoryOptions.propertyMatchAlerts.id,
        )
        successMsg = 'Notification enabled successfully'
      } else {
        promise = authorizedUnSubscribe(
          unsubscribeCategoryOptions.propertyMatchAlerts.id,
        )
        successMsg = 'Notification disabled successfully'
      }
      preferenceSubmitLoadingHelpers.startLoading()
      promise
        .then(() => {
          setPropertyMatchNoticeEnabled(isEnabled)
          preferenceSubmitLoadingHelpers.markDoneSuccessfully()
          snugNotifier.success(successMsg)
        })
        .catch((err) => {
          setMatchNoticeToggleValue(!isEnabled) // revert display value as it's not saved
          preferenceSubmitLoadingHelpers.setError(err)
        })
    }, STANDARD_TIMEOUT),
    [],
  )

  const togglePropertyMatchNoticeAttr = () => {
    const newDisplayValue = !matchNoticeToggleValue
    setMatchNoticeToggleValue(newDisplayValue)
    if (newDisplayValue !== propertyMatchNoticeEnabled) {
      debouncedSavePreferences(!matchNoticeToggleValue)
    }
  }

  const isAnyPreferencesLoading =
    preferencesFetchLoadingStates.state === loadingStatesIds.LOADING ||
    preferenceSubmitLoadingStates.state === loadingStatesIds.LOADING

  const renderPropertyMatchCard = () => {
    const switchBtnId = `${unsubscribeCategoryOptions.propertyMatchAlerts.id}-switch`
    const tooltipId = `${unsubscribeCategoryOptions.propertyMatchAlerts.id}-tooltip`
    const tooltipMessage =
      'Receive a notification when a new property is listed that matches your enquiries in the last 4 weeks.'

    const body = (
      <Box>
        <TextSpan as="div" mb={5}>
          Find a property sooner by customising new property notifications.
        </TextSpan>
        {preferencesFetchLoadingStates?.state === loadingStatesIds.ERROR && (
          <Alert variant="warningWithBg" mb={4}>
            {preferencesFetchLoadingStates.error.message}
          </Alert>
        )}
        {preferenceSubmitLoadingStates?.state === loadingStatesIds.ERROR && (
          <Alert variant="warningWithBg" mb={4}>
            {preferenceSubmitLoadingStates.error.message}
          </Alert>
        )}
        <Flex alignItems="center">
          <SwitchButton
            onChange={() => togglePropertyMatchNoticeAttr()}
            value={matchNoticeToggleValue}
            buttonID={switchBtnId}
            disabled={isAnyPreferencesLoading}
          />{' '}
          <TextSpan ml={4} mr={2}>
            Send me property matches
          </TextSpan>{' '}
          <HelpOutlineRounded
            color={theme.colors.gray400}
            data-tooltip-content=""
            data-tooltip-id={tooltipId}
          />
        </Flex>
        <ReactTooltip id={tooltipId} data-type="info" place="bottom">
          <Box maxWidth="275px" textAlign="center">
            {tooltipMessage}
          </Box>
        </ReactTooltip>
      </Box>
    )

    return (
      <Card
        headerTitle={'Property Matches'}
        headerImage={<CardIcon />}
        isCollapsible
        internalComponent={body}
        showHeaderBorder={false}
        forcedStyles={{ border: 'none', padding: '16px 24px' }}
      />
    )
  }

  return (
    <ProfileItemScreen>
      <div className="rental-notification display-flex">
        <span className="btn btn-transparent btn-left rental-header-mobile-layout width-auto align-items-center">
          <span onClick={() => history.push(urlTo('homeoverview'))}>
            My Home
            <i className="icon-arrow-right" />
          </span>
        </span>
        <span className="btn btn-transparent btn-left rental-header-mobile-layout width-auto align-items-center">
          <span>Rental Profile</span>
        </span>
      </div>
      <div className="table">
        <div className="cell-m-100 pt30">
          <h3>My Rental Profile</h3>
          <AlertSensitiveInfo />
          <div className="mt20">
            <p>
              Create your Snug Profile to reuse on rental applications and other
              Snug products or services.
            </p>
          </div>
        </div>
      </div>

      <div
        className={currentUser.userType === 'facebook' ? 'mt50 mb20' : 'hidden'}
      >
        <LoginFacebook form="You are logged in" disabled="disabled" />
      </div>

      <ProfileItem linkUrl="#" haveData={true}>
        <ProfileItemHeader iconStyle="icon-person" text="Account" />
        <ProfileItemContent>
          <ProfileAvatar currentUser={currentUser} />
          <ProfileAccountInfo currentUser={currentUser} />
        </ProfileItemContent>
        <div className={currentUser.userType === 'facebook' ? 'hidden' : ''} />
        <div className="change-password-card">
          {!currentUser.isOffshore && (
            <>
              <Link to={urlTo('UpdateProfile')} className="mr8">
                <button className="textButton height20">Update Details</button>
              </Link>
            </>
          )}
          <Link to={urlTo('ChangePassword')}>
            <button className="textButton height20">Change password</button>
          </Link>
        </div>
        <div />
      </ProfileItem>

      <Box my={8}>
        <TextSpan
          as="div"
          fontSize={theme.fontSizes.pSurplusLarge24}
          fontWeight={theme.fontWeights.h2}
          mb={5}
        >
          Preferences
        </TextSpan>
        {renderPropertyMatchCard()}
      </Box>

      <div className="mt30">
        <h3>Join Profiles</h3>
        <Alert variant="blueWithBg" className="mt10">
          <Box>
            <StyledLink to={'/sm/applications'}>
              Applying with others? Add a Joint Applicant
            </StyledLink>
          </Box>
        </Alert>
      </div>

      <RentalReputation />
    </ProfileItemScreen>
  )
}

export default Profile
