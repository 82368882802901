/* global document */
import React from 'react'

import PDFJSMain from '@bundled-es-modules/pdfjs-dist/build/pdf'
import moment from 'moment'
import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'

import iconDocumentDoc from 'app/assets/icons/icon-document-doc.svg'
import iconDocumentImg from 'app/assets/icons/icon-document-img.svg'
import iconDocumentPdf from 'app/assets/icons/icon-document-pdf.svg'
import { Box } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import ErrorMessages from 'app/constants/error_messages'
import theme from 'app/match/applicationReportPDF/assets/theme'
import DocumentListText from 'app/shared_components/document_list/component/document_list_text'
import {
  ErrorMessage,
  invalidDocExtensionExists,
  invalidDocSizeExists,
} from 'app/shared_components/helpers'
import { setFileIcon, trimFileLength } from 'app/sm/helpers'
import * as stringHelpers from 'app/utils/strings/helpers'
import { errorMessageForInvalidFileTypeDoc } from 'app/utils/text/helpers'

PDFJSMain.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJSMain.version}/pdf.worker.js`

const fileExtensions = (f) =>
  (f || {}).name && f.name.substring(f.name.lastIndexOf('.') + 1, f.name.length)

const StyledDocLinkContainer = styled.td`
  .disabled {
    pointer-events: none;
    cursor: pointer;
  }
`

class DocumentListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      validationError: '',
      newDocuments: [],
    }
  }

  onDocumentsDelete = (documentName, docuidID) => {
    const { setDocumentsChanged } = this.props
    var array = [...this.state.newDocuments]
    var index = array.indexOf(documentName)
    array.splice(index, 1)
    this.setState({ newDocuments: array }, function () {
      setDocumentsChanged && setDocumentsChanged(this.state.newDocuments.length)
    })
    this.props.deleteDocument(docuidID)
  }

  onDocumentsUpdate = (event) => {
    event.persist()
    this.setState({ validationError: '' })
    const {
      addDocument,
      documentType,
      fileTypeError,
      fileType,
      setDocumentsChanged,
      documents,
    } = this.props
    const attachments = document.getElementById(`attachments-${documentType}`)
    const attachmentsArr = Object.keys(attachments.files).map(
      (k) => attachments.files[k],
    )
    const findInvalidDocExtension = invalidDocExtensionExists(
      attachmentsArr,
      'doc',
    )
    const findInvalidDocSize = invalidDocSizeExists(attachmentsArr)
    const hasPdfFiles = attachmentsArr.some(
      (doc) => fileExtensions(doc) === 'pdf',
    )
    const isAttachmentExist =
      documents &&
      documents.some((attach) => attachmentsArr[0].name === attach.name)
    if (isAttachmentExist) {
      if (!window.confirm(stringHelpers.isAttachmentExistMessage)) {
        event.target.value = null
        return
      }
    }
    if (findInvalidDocExtension) {
      this.setState({
        validationError: errorMessageForInvalidFileTypeDoc(
          findInvalidDocExtension?.name,
        ),
      })
    } else if (findInvalidDocSize) {
      this.setState({
        validationError: `The file size of ${findInvalidDocSize.name} can not exceed 10mb`,
      })
    } else {
      if (hasPdfFiles) {
        this.isPdfEncrypted(attachmentsArr, event)
      } else {
        addDocument(documentType, fileType)
        !!attachmentsArr[0] &&
          this.setState(
            {
              newDocuments: [
                ...this.state.newDocuments,
                attachmentsArr[0].name,
              ],
            },
            function () {
              setDocumentsChanged &&
                setDocumentsChanged(this.state.newDocuments.length)
            },
          )
        event.target.value = null
      }
    }
  }

  checkPdfAttachments(promiseArr, numOfAttachments) {
    const { addDocument, documentType, fileType } = this.props

    if (promiseArr.length === numOfAttachments) {
      Promise.all(promiseArr)
        .then(() => addDocument(documentType, fileType))
        .catch((e) =>
          this.setState({
            validationError: `The PDF file is password protected (${e.name})`,
          }),
        )
    }
  }

  isPdfEncrypted = (attachmentsArr, parentEvent) => {
    const pdfAttachmentsArr =
      (attachmentsArr || []).filter((a) => fileExtensions(a) === 'pdf') || []
    let promiseArr = []

    pdfAttachmentsArr.map((pdf) => {
      let reader = new FileReader()
      reader.onload = (event) => {
        const typedarray = new Uint8Array(event.target.result)
        const pdfData = PDFJSMain.getDocument(typedarray).promise
        pdfData
          .then((data) => {
            parentEvent.target.value = null
            return data
          })
          .catch((e) => {
            this.setState({
              validationError: `The PDF file is password protected (${e.name})`,
            })
          })

        promiseArr.push(pdfData.promise)
        this.checkPdfAttachments(promiseArr, pdfAttachmentsArr.length)
      }
      reader.readAsArrayBuffer(pdf)
    })
  }

  renderDocItem = (doc) => {
    const {
      showDocumentType,
      documentTypeTextConverter,
      toggleDocumentsViewer,
      disabled,
      allowDeletion = true,
    } = this.props
    const {
      CreatedAt,
      documentType,
      name,
      URL,
      url,
      guidID,
      fileAutoDeletedAt,
    } = doc
    const date = moment(CreatedAt).format('DD MMM YYYY')
    const autoDeletedDate =
      fileAutoDeletedAt && moment(fileAutoDeletedAt).format('DD MMM YYYY')
    const fileExtension = fileExtensions(doc)
    const fileIconName = setFileIcon(fileExtension)
    const documentTypeDisplayText =
      showDocumentType && documentTypeTextConverter(documentType)
    const filename = trimFileLength(11)(name)
    const documentIcons = {
      doc: iconDocumentDoc,
      img: iconDocumentImg,
      pdf: iconDocumentPdf,
    }

    const renderFileName = () => {
      return !fileAutoDeletedAt ? (
        <a
          className={`gray-dark-color ${disabled ? 'disabled' : ''}`}
          target="_blank"
          href={disabled ? '' : URL || url}
          download={name}
          rel="noreferrer"
        >
          {filename}
        </a>
      ) : (
        <>{filename}</>
      )
    }

    return (
      <>
        <td className="pr0 w1p">
          <img alt="" src={documentIcons[fileIconName]} height="25" />
        </td>
        <StyledDocLinkContainer>
          {!toggleDocumentsViewer ? (
            <>{renderFileName()}</>
          ) : (
            <Box
              style={{ cursor: 'pointer' }}
              onClick={
                toggleDocumentsViewer
                  ? () => toggleDocumentsViewer(guidID)
                  : undefined
              }
            >
              {filename}
            </Box>
          )}
        </StyledDocLinkContainer>
        {showDocumentType && <td>{documentTypeDisplayText}</td>}
        <td>
          {date}
          {fileAutoDeletedAt && (
            <>
              {' '}
              <Text
                color={theme.colors.primary300}
                fontSize={theme.fontSizes.pRegular14}
                as="span"
                data-tooltip-content=""
                data-tooltip-id={`auto_deleted_${guidID}`}
              >
                {' '}
                (auto-deleted)
              </Text>
              <Tooltip data-type="info" id={`auto_deleted_${guidID}`}>
                Auto-deleted {autoDeletedDate}
              </Tooltip>
            </>
          )}
        </td>
        {allowDeletion && (
          <td className="actionButtonWrapper">
            <button
              onClick={() => {
                this.onDocumentsDelete(name, guidID)
              }}
              className="remove-btn"
            >
              <i className="icon-remove" aria-hidden="true" />
            </button>
          </td>
        )}
      </>
    )
  }

  render() {
    const {
      documents = [],
      showDocumentType = false,
      documentTypeTextConverter,
      documentType,
      responseText,
      isFromAgencyBranding = false,
      showUploader = true,
      availableFileTypesText,
      allowDeletion = true,
      disableMultiple,
    } = this.props
    const { validationError } = this.state
    const fileTypesText = availableFileTypesText
      ? availableFileTypesText
      : '(max 10mb - pdf, doc, xlsx, png, jpg, jpeg)'
    return (
      <div>
        {showUploader && (
          <Box mt={8} className="sm-file-upload single">
            <form id="property-photos-upload">
              <input
                multiple={!disableMultiple}
                id={`attachments-${documentType}`}
                type="file"
                onChange={(event) => this.onDocumentsUpdate(event)}
                onClick={(event) => (event.target.value = null)}
              />
              <label htmlFor="photos">
                <div>
                  <i className="icon-image" />
                  <b className="pr0">Click to upload</b>
                  <span>, or drag and drop</span>
                </div>
                <DocumentListText textToDisplay={fileTypesText} />
              </label>
            </form>
          </Box>
        )}
        {!isFromAgencyBranding && (
          <table className="style3">
            {documents.length ? (
              <thead>
                <tr>
                  <td colSpan="2">Name</td>
                  {showDocumentType && <td>Type</td>}
                  <td>Date</td>
                  {/* Actions (deletion) cell placeholder */}
                  {allowDeletion && <td />}
                </tr>
              </thead>
            ) : (
              <thead />
            )}
            <tbody>
              {documents.map((doc, index) => {
                return <tr key={index}>{this.renderDocItem(doc)}</tr>
              })}
            </tbody>
          </table>
        )}
        <div className={validationError ? 'alert alert-danger' : 'hide-alert'}>
          <div>{validationError}</div>
        </div>
        {responseText && (
          <ErrorMessage
            error={
              ErrorMessages[parseInt(responseText)]
                ? ErrorMessages[parseInt(responseText)]
                : `There is an error: ${responseText}`
            }
          />
        )}
      </div>
    )
  }
}

export default DocumentListItem
