import React from 'react'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import {
  ProfileItem,
  ProfileItemContent,
  ProfileItemHeader,
} from 'app/session/bc_profile/profile_container'
import { history } from 'app/shared_components/router'
import { ProfileCompletenessComponent } from 'app/sm/applications/components/progress_bar'
import {
  RentalReputationCategories,
  RentalReputationItems,
} from 'app/sm/helpers'

const RentalReputationItem = (key, icon, name, checked, url) => (
  <Link
    key={key}
    className={checked ? 'bc-hub-item-link have-data' : 'bc-hub-item-link'}
    to={url}
  >
    <header>
      <i className={icon} />
      <span>{name}</span>
    </header>
  </Link>
)

const CreatePDFButton = ({ disabled, onClick, text }) => (
  <button disabled={disabled} onClick={onClick}>
    {text}
  </button>
)

CreatePDFButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

const DownloadPDF = ({ isCreatingPDF, onClick, pdfUrl, text }) =>
  isCreatingPDF ? (
    <i className="fa fa-spinner fa-pulse" />
  ) : (
    <div className="renter-reputation-pdf-download">
      <a target="_blank" href={pdfUrl} rel="noreferrer">
        {text} &nbsp;
      </a>
      <i
        className="fa fa-refresh"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyPress={onClick}
      />
    </div>
  )

DownloadPDF.propTypes = {
  isCreatingPDF: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  pdfUrl: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

const ErrorMessage = ({ error }) =>
  error ? (
    <div className="row">
      <div className="col-sm-12">
        <div className="alert alert-danger">
          <div>{error}</div>
        </div>
      </div>
    </div>
  ) : null

ErrorMessage.propTypes = {
  error: PropTypes.string.isRequired,
}

const BackButton = ({ backUrl }) => (
  <div
    onClick={() => history.push(backUrl)}
    onKeyPress={() => history.push(backUrl)}
    role="button"
    tabIndex="0"
    className="btn btn-transparent btn-left xs-text-center wa"
  >
    <i className="icon-arrow-left left" />
    <span>Back</span>
  </div>
)

BackButton.propTypes = {
  backUrl: PropTypes.string.isRequired,
}

const ProfileItemsContainer = ({
  title,
  message,
  completeness,
  profileItems,
}) => (
  <div className="profile-item">
    <h3>{title}</h3>
    <p className="mt10 mb30">{message}</p>
    {profileItems.map((element) =>
      RentalReputationItem(
        element.key,
        element.icon,
        element.name,
        completeness[element.key],
        element.url,
      ),
    )}
  </div>
)

ProfileItemsContainer.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  completeness: PropTypes.object.isRequired,
  profileItems: PropTypes.arrayOf(PropTypes.object).isRequired,
}

class RentalReputation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCreatingPDF: false,
      createPDFError: '',
    }
  }

  componentDidMount() {
    this.props.fetchCompleteness()
  }

  createPDF = () => {
    this.setState({ isCreatingPDF: true })
    this.props.createRenterProfilePDF(
      () => {
        this.setState({ isCreatingPDF: false, createPDFError: '' })
      },
      (createPDFError) => {
        this.setState({ isCreatingPDF: false, createPDFError })
      },
    )
  }

  render() {
    const { completeness, error, backUrl, paymentOptions } = this.props
    const ImportantRentalReputationItems = RentalReputationItems.filter(
      (item) => item.category === RentalReputationCategories.Important,
    )
    const SupportingRentalReputationItems = RentalReputationItems.filter(
      (item) => item.category === RentalReputationCategories.Supporting,
    )
    const OtherRentalReputationItems = RentalReputationItems.filter(
      (item) => item.category === RentalReputationCategories.Other,
    )

    const cards = paymentOptions ? paymentOptions.cards : []

    const haveCard = cards.length > 0
    const defaultCard = haveCard ? cards[0] : {}

    return (
      <div className="rental-reputation-container">
        <div className="">
          <div className="content row">
            <ErrorMessage error={this.state.createPDFError} />

            <div className="col-xs-12 mt25">
              <ul className="references">
                <ProfileItemsContainer
                  title="Essentials"
                  message="Add your identity, employment and income to enable assessment."
                  completeness={completeness}
                  profileItems={ImportantRentalReputationItems}
                />
                <ProfileItemsContainer
                  title="Supporting"
                  message="Items that help strengthen your profile and support rental applications you submit."
                  completeness={completeness}
                  profileItems={SupportingRentalReputationItems}
                />
                <ProfileItemsContainer
                  title="Extras"
                  message="Items that are typically required before you accept an offer and move into a property."
                  completeness={completeness}
                  profileItems={OtherRentalReputationItems}
                />
              </ul>
            </div>
          </div>
          <div className="mb20">
            {/* Removed while incorrect details are removed from PDF download
                https://app.clubhouse.io/snugco/story/21809/remove-rating-stars-from-the-rental-references
              {this.props.pdf
              ? <DownloadPDF isCreatingPDF={this.state.isCreatingPDF} onClick={this.createPDF} text="Download PDF" pdfUrl={this.props.pdf} />
              : <CreatePDFButton disabled={this.state.isCreatingPDF} onClick={this.createPDF} text="Create PDF" />} */}
          </div>

          <ErrorMessage error={this.state.createPDFError} />

          {error && (
            <div className="row">
              <div className="col-sm-12">
                <div className="alert alert-danger">
                  <div>{error}</div>
                </div>
              </div>
            </div>
          )}
          <BackButton backUrl={backUrl} />
        </div>
      </div>
    )
  }
}

RentalReputation.propTypes = {
  fetchCompleteness: PropTypes.func.isRequired,
  createRenterProfilePDF: PropTypes.func.isRequired,
  backUrl: PropTypes.string.isRequired,
  completeness: PropTypes.object.isRequired,
  error: PropTypes.string,
  pdf: PropTypes.string,
}

RentalReputation.defaultProps = {
  error: '',
  pdf: '',
}

export default RentalReputation
