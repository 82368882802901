import React from 'react'

import styled from 'styled-components'

import userIcon from 'app/assets/icons/background-report/id-verification.svg'
import { Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import DatabasesCheckComponent, {
  CheckMark,
} from 'app/sm/renter_background_check_report/databases_check/component'
import {
  EquiFaxResponse,
  PageText,
} from 'app/sm/renter_background_check_report/new_backgroundcheck_report_container'
import * as sensitiveInfoHelpers from 'app/utils/sensitiveInfo/helper'

import 'app/sm/renter_background_check_report/identity_verification/style.scss'

export const DatabaseText = styled(Text)`
  margin-bottom: 0;
  line-height: ${({ theme }) => `${theme.lineHeights.encrption}`};
  text-align: ${(props) => (props.isNotAlign ? 'left' : 'justify')};
`

const IdentityVerificationComponent = ({
  resultInfo = {},
  IdentityVerification = {},
  updateDate = '',
  IdInfo = {},
}) => {
  const { DriversNicenceNumber = 0, PassportNumber = '' } = IdInfo || {}
  const { result = false, message = '' } = resultInfo
  const { VisaDetails = {}, DatabaseMatched = [] } = IdentityVerification
  const {
    VisaClass = '',
    VisaSubClass = '',
    VisaGrantDate = '',
    VisaExpiryDate = '',
    VisaMessage = '',
    VisaWorkEntitlement = '',
  } = VisaDetails
  const statusClass = result ? 'Verified' : 'not-verified'
  const statusText = result ? 'Verified' : 'Not Verified'

  const leftContent = (
    <>
      {DriversNicenceNumber ? (
        <CheckMark
          text={`Driver Licence number ${sensitiveInfoHelpers.hashSensitiveNumber(
            DriversNicenceNumber,
          )} provided`}
          iconType="verified"
        />
      ) : (
        <CheckMark text="Driver Licence not provided" iconType="not-verified" />
      )}
      {PassportNumber ? (
        <CheckMark
          text={`Passport number ${sensitiveInfoHelpers.hashSensitiveNumber(
            PassportNumber,
          )} provided`}
          iconType="verified"
        />
      ) : (
        <CheckMark text="Passport not provided" iconType="not-verified" />
      )}
    </>
  )

  const rightContent = (
    <>
      <EquiFaxResponse isNotMb={true}>Equifax response:</EquiFaxResponse>
      {!!message && (
        <PageText isNotAlign={true} pb={theme.space[5] + 'px'}>
          {message}
        </PageText>
      )}
      <Flex>
        {VisaClass && (
          <PageText isNotAlign={true}>Visa Class: {VisaClass}</PageText>
        )}
        {VisaSubClass && (
          <PageText ml={theme.space[5] + 'px'} isNotAlign={true}>
            Visa Subclass: {VisaSubClass}
          </PageText>
        )}
      </Flex>
      <Flex>
        {VisaGrantDate && (
          <PageText isNotAlign={true} pb={theme.space[5] + 'px'}>
            Visa Grant Date: {VisaGrantDate}
          </PageText>
        )}
        {VisaExpiryDate && (
          <PageText isNotAlign={true} pb={theme.space[5] + 'px'}>
            Visa Expiry Date: {VisaExpiryDate}
          </PageText>
        )}
      </Flex>
      {VisaWorkEntitlement && (
        <PageText isNotAlign={true} pb={theme.space[5] + 'px'}>
          Visa Work Entitlement: {VisaWorkEntitlement}
        </PageText>
      )}

      <PageText isNotAlign={true} pb={theme.space[5] + 'px'}>
        {VisaMessage}
      </PageText>

      {DatabaseMatched?.map((item, index) => (
        <DatabaseText isNotMb={true} isNotAlign={true}>
          {item}
        </DatabaseText>
      ))}
    </>
  )

  return (
    <DatabasesCheckComponent
      label="Identity Verification"
      icon={userIcon}
      statusClass={statusClass}
      statusText={statusText}
      updateDate={updateDate}
      leftBody={leftContent}
      rightBody={rightContent}
    />
  )
}

export default IdentityVerificationComponent
