import { connect } from 'react-redux'

import {
  createIdentityDoc,
  fetchIdentityDoc,
  getSecondaryTenantSummary,
  updateIdentityDoc,
} from 'app/bond_cover/bond_cover_actions'
import IdentityDoc from 'app/bond_cover/renters/tenants/identity/identity_doc'

const mapStateToProps = ({ bondCover }) => ({
  request: bondCover.request,
  identityDoc: bondCover.identityDoc,
  apiError: bondCover.error,
})

const mapDispatchToProps = (dispatch) => ({
  createIdentityDoc: (requestID, data, isPrimary) =>
    createIdentityDoc(requestID, data, isPrimary),
  fetchIdentityDoc: (requestID, guid) => fetchIdentityDoc(requestID, guid),
  updateIdentityDoc: (requestID, data, guid, isPrimary) =>
    updateIdentityDoc(requestID, data, guid, isPrimary),
  getSecondaryTenantSummary: (requestID) =>
    getSecondaryTenantSummary(requestID),
})

export default connect(mapStateToProps, mapDispatchToProps)(IdentityDoc)
