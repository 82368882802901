import React from 'react'

import DeclineForm from 'app/bond_cover/admin/requests/admin_decline_form'

class DeclineFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      declineReason: null,
      disabled: true,
      description: '',
    }
  }

  componentWillUnmount() {
    const { clearError } = this.props
    clearError()
  }

  decline = () => {
    const { adminDecline, request } = this.props
    if (adminDecline && request) {
      adminDecline(this.state, request.appCode)
    }
  }

  update = (description) => {
    return (event) => {
      let { value } = event.target
      this.setState({ description: value })
    }
  }

  updateNumber = (description) => {
    return (event) =>
      this.setState({
        declineReason: parseInt(event.target.value),
        disabled: false,
        description: description,
      })
  }

  render() {
    const { declineReason, description, disabled } = this.state
    const { request, spinner, responseText } = this.props
    return (
      <DeclineForm
        declineReason={declineReason}
        description={description}
        disabled={disabled}
        update={this.update}
        updateNumber={this.updateNumber}
        decline={this.decline}
        spinner={spinner}
        responseText={responseText}
      />
    )
  }
}

export default DeclineFormContainer
