import { NOT_AVAILABLE_TEXT } from 'app/utils/text/helpers'

const ACT = 'ACT'
const QLD = 'QLD'
const NSW = 'NSW'
const NT = 'NT'
const SA = 'SA'
const TAS = 'TAS'
const VIC = 'VIC'
const WA = 'WA'

const AUSTRALIAN_CAPITAL_TERRITORY = 'AUSTRALIAN CAPITAL TERRITORY'
const NEW_SOUTH_WALES = 'NEW SOUTH WALES'
const NORTHERN_TERRITORY = 'NORTHERN TERRITORY'
const QUEENSLAND = 'QUEENSLAND'
const SOUTH_AUSTRALIA = 'SOUTH AUSTRALIA'
const TASMANIA = 'TASMANIA'
const VICTORIA = 'VICTORIA'
const WESTERN_AUSTRALIA = 'WESTERN AUSTRALIA'

const shortAustralianStates = [ACT, NSW, NT, QLD, SA, TAS, VIC, WA]
const longAustralianStates = [
  AUSTRALIAN_CAPITAL_TERRITORY,
  NEW_SOUTH_WALES,
  NORTHERN_TERRITORY,
  QUEENSLAND,
  SOUTH_AUSTRALIA,
  TASMANIA,
  VICTORIA,
  WESTERN_AUSTRALIA,
]

const CountryAustralia = 'Australia'
const CountryAustraliaAU = 'AU'
const CountryAustraliaAUS = 'AUS'

export const australianStates =
  shortAustralianStates.concat(longAustralianStates)

const statesWhereBGCIsNotSupported = [SA, SOUTH_AUSTRALIA]

export const checkIfBGCheckNotSupportedInState = (state: string) => {
  if (!state) {
    return false
  }

  return statesWhereBGCIsNotSupported.includes(state.toUpperCase())
}

const southAustraliaStateVariants = [SA, SOUTH_AUSTRALIA]

export const isSouthAustralia = (state: string) => {
  if (!state) {
    return false
  }

  return southAustraliaStateVariants.includes(state.toUpperCase())
}

interface Address {
  friendlyName: string
  suburb: string
  state: string
  country: string
}

function isAustralia(country: string) {
  return (
    country === CountryAustralia ||
    country === CountryAustraliaAU ||
    country === CountryAustraliaAUS
  )
}

export function formatAddress(address: Address) {
  if (!address) {
    return NOT_AVAILABLE_TEXT
  }

  const { friendlyName } = address

  if (!friendlyName) {
    return NOT_AVAILABLE_TEXT
  }

  let formattedAddress = `${friendlyName}`
  if (address.suburb) {
    formattedAddress += `, ${address.suburb}`
  }
  if (address.state) {
    formattedAddress += `, ${address.state}`
  }
  if (address.country && !isAustralia(address.country)) {
    formattedAddress += `, ${address.country}`
  }

  return formattedAddress
}
