import {
  EnquirerActionsAppliedFilterRenderer,
  EnquirerActionsFilterBody,
  enquirerActionsFilterDataExtractor,
  isEnquirerActionsApplied,
} from 'app/features/teams/messages/table-filters/EnquirerActionsFilter'
import {
  isLeadSourceFilterApplied,
  isLeadSourceFilterFeatureNew,
  LeadFilterTriggerPlaceholder,
  LeadSourceFilterBody,
  leadsSourceAppliedFilterRenderer,
  leadsSourceDataExtractor,
} from 'app/features/teams/messages/table-filters/LeadsFilter'
import {
  generatePropertyFilterData,
  isPropertyFilterApplied,
  PropertyAppliedFilterRenderer,
  PropertyFilterBody,
  propertyFilterDataExtractor,
} from 'app/features/teams/messages/table-filters/PropertyFilter'
import {
  isPropertyManagerApplied,
  PropertyManagerAppliedFilterRenderer,
  PropertyManagerFilterBody,
  propertyManagerFilterDataExtractor,
} from 'app/features/teams/messages/table-filters/PropertyManagerFilter'
import {
  isPropertyTypeApplied,
  PropertyTypeAppliedFilterRenderer,
  PropertyTypeFilterBody,
  propertyTypeFilterDataExtractor,
} from 'app/features/teams/messages/table-filters/PropertyTypeFilter'
import {
  isReadStatusFilterApplied,
  readStatusAppliedFilterRenderer,
  readStatusDataExtractor,
  ReadStatusFilterBody,
} from 'app/features/teams/messages/table-filters/ReadStatusFilter'

export const filtersIds = {
  property: 'property',
  manager: 'manager',
  propertyType: 'propertyType',
  enquirerActions: 'enquirerActions',
  readStatus: 'readStatus',
  leadSource: 'leadSource',
}

export const filtersInitialState = {
  filters: {
    [filtersIds.property]: {},
    [filtersIds.manager]: {},
    [filtersIds.propertyType]: {},
    [filtersIds.enquirerActions]: {},
    [filtersIds.readStatus]: {},
    [filtersIds.leadSource]: {},
  },
  search: '',
}

export const filtersAppliedChecker = {
  [filtersIds.property]: isPropertyFilterApplied,
  [filtersIds.manager]: isPropertyManagerApplied,
  [filtersIds.propertyType]: isPropertyTypeApplied,
  [filtersIds.enquirerActions]: isEnquirerActionsApplied,
  [filtersIds.readStatus]: isReadStatusFilterApplied,
  [filtersIds.leadSource]: isLeadSourceFilterApplied,
}

export const filtersNewFeaturedChecker = {
  [filtersIds.leadSource]: isLeadSourceFilterFeatureNew,
}

export const hasAppliedFilters = (filtersState) => {
  if (!filtersState) return false
  return Object.values(filtersIds).some((filterId) => {
    const filterValue = filtersState.filters[filterId]
    const filterAppliedChecker = filtersAppliedChecker[filterId]
    return filterAppliedChecker(filterValue)
  })
}

export const hasNewFeaturedFilters = () => {
  return Object.values(filtersIds).some((filterId) =>
    filtersNewFeaturedChecker[filterId]?.(),
  )
}

export const getFilters = (filtersState) => {
  const properties = propertyFilterDataExtractor(
    filtersState.filters[filtersIds.property],
  ).properties
  const managers = propertyManagerFilterDataExtractor(
    filtersState.filters[filtersIds.manager],
  ).managers
  const propertyType = propertyTypeFilterDataExtractor(
    filtersState.filters[filtersIds.propertyType],
  )
  const enquirerActions = enquirerActionsFilterDataExtractor(
    filtersState.filters[filtersIds.enquirerActions],
  ).actions
  const readStatus = readStatusDataExtractor(
    filtersState.filters[filtersIds.readStatus],
  ).statuses
  const leadSource = leadsSourceDataExtractor(
    filtersState.filters[filtersIds.leadSource],
  ).source
  return {
    properties,
    managers,
    propertyType,
    enquirerActions,
    readStatus,
    source: leadSource,
  }
}

export const generateSelectedFilters = ({ properties }) => {
  return {
    filters: {
      [filtersIds.property]: generatePropertyFilterData({ properties }),
    },
  }
}

const getRenderedFiltersConfig = (filterId, filtersValues, updateFilterFn) => {
  const filterValues = filtersValues[filterId]
  return {
    filterValues,
    isApplied: filtersAppliedChecker[filterId](filterValues),
    setFilterValues: (values) => updateFilterFn(filterId, values),
  }
}

export const buildRenderedFilterConfig = (filtersValues, updateFilterFn) => {
  return {
    [filtersIds.property]: {
      AppliedFilterRenderer: PropertyAppliedFilterRenderer,
      FilterBody: PropertyFilterBody,
      filterTriggerPlaceholder: 'Property Address',
      appliedFilterTitle: 'Property',
      ...getRenderedFiltersConfig(
        filtersIds.property,
        filtersValues,
        updateFilterFn,
      ),
    },
    [filtersIds.manager]: {
      AppliedFilterRenderer: PropertyManagerAppliedFilterRenderer,
      FilterBody: PropertyManagerFilterBody,
      filterTriggerPlaceholder: 'Property Manager',
      appliedFilterTitle: 'PM',
      ...getRenderedFiltersConfig(
        filtersIds.manager,
        filtersValues,
        updateFilterFn,
      ),
    },
    [filtersIds.propertyType]: {
      AppliedFilterRenderer: PropertyTypeAppliedFilterRenderer,
      FilterBody: PropertyTypeFilterBody,
      filterTriggerPlaceholder: 'Property Type',
      appliedFilterTitle: 'Property Type',
      ...getRenderedFiltersConfig(
        filtersIds.propertyType,
        filtersValues,
        updateFilterFn,
      ),
    },
    [filtersIds.enquirerActions]: {
      AppliedFilterRenderer: EnquirerActionsAppliedFilterRenderer,
      FilterBody: EnquirerActionsFilterBody,
      filterTriggerPlaceholder: 'Enquirer Status',
      appliedFilterTitle: 'Enquirer Status',
      ...getRenderedFiltersConfig(
        filtersIds.enquirerActions,
        filtersValues,
        updateFilterFn,
      ),
    },
    [filtersIds.readStatus]: {
      AppliedFilterRenderer: readStatusAppliedFilterRenderer,
      FilterBody: ReadStatusFilterBody,
      filterTriggerPlaceholder: 'Read Status',
      appliedFilterTitle: 'Read Status',
      ...getRenderedFiltersConfig(
        filtersIds.readStatus,
        filtersValues,
        updateFilterFn,
      ),
    },
    [filtersIds.leadSource]: {
      AppliedFilterRenderer: leadsSourceAppliedFilterRenderer,
      FilterBody: LeadSourceFilterBody,
      filterTriggerPlaceholder: 'Leads',
      FilterTriggerPlaceholderCmp: LeadFilterTriggerPlaceholder,
      appliedFilterTitle: 'Leads',
      ...getRenderedFiltersConfig(
        filtersIds.leadSource,
        filtersValues,
        updateFilterFn,
      ),
    },
  }
}
