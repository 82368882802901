import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const getCompleteness = () => {
  const url = `${apiBaseUrl}sm/profile/completeness`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (completeness) => ({ ok: true, completeness }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getTeamSettingInfo = (teamId, queryString) => {
  const url = `${apiBaseUrl}sm/agency/${teamId}/attribute${
    queryString ? `?keys=${queryString}` : ''
  }`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (settingInfo) => ({ ok: true, settingInfo }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getTeamSettingInformation = (teamId, queryString) => {
  const url = `${apiBaseUrl}sm/agency/${teamId}/getAttributes${
    queryString ? `?keys=${queryString}` : ''
  }`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (settingInfo) => ({ ok: true, settingInfo }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const putTeamViewingSettingInfo = (
  teamId,
  viewingSetting,
  applicationSetting,
  personalNotificationSettings,
  feedSettings,
  integrationsSettings,
) => {
  const url = `${apiBaseUrl}sm/agency/${teamId}/attribute`
  const payload = {
    attributes: {
      viewings: {
        ...viewingSetting,
      },
      application: {
        ...applicationSetting,
      },
      personalnotifications: {
        ...personalNotificationSettings,
      },
      feed: feedSettings,
      integrations: integrationsSettings,
    },
  }
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const generateRenterProfilePDF = () => {
  const url = `${apiBaseUrl}sm/profile/pdf`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (url) => ({ ok: true, url }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const updateReputationPreference = (preference) => {
  const url = `${apiBaseUrl}sm/reputation/preference`
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(preference),
  }

  return fetch(url, options)
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (preference) => ({ ok: true, preference }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getReputationPreference = () => {
  const url = `${apiBaseUrl}sm/reputation/preference`
  const options = {
    method: 'GET',
    credentials: 'include',
  }

  return fetch(url, options)
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (preference) => ({ ok: true, preference }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
