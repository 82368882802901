import { isEmpty } from 'lodash'

import ErrorMessages from 'app/constants/error_messages'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { apiFailCallback } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import {
  _subscribeRenterScore,
  deleteApplicant,
  deleteDraft,
  deleteOccupant,
  getApplicantConfirmationSummary,
  getApplicantSummary,
  getApplication,
  getApplicationByUserId,
  getApplicationSummary,
  getApplyAnywhereListing,
  getRenterSummary,
  postAcceptJointApplicationAsSecondary,
  postOffPlatformApplication,
  pushApplication,
  pushFinalApplication,
  pushQuickApplication,
  putApplicantDisclosures,
  putOffPlatformApplication,
  syncAcceptApplication,
} from 'app/sm/apply/apply_access'
import { receiveBackgroundCheck } from 'app/sm/background_check/background_check_actions'
import {
  applicationTypeCollection,
  constructAdderEntityFromDate,
  constructDateFromAdderEntity,
  contactRelationshipCollection,
  dispatchAPIResponse,
  isApplicationApplyAnywhere,
  validateApplication,
} from 'app/sm/helpers'
import { fetchOfferedApplication } from 'app/sm/oa_tenant/oa_tenant_actions'
import {
  getOffer,
  getPropertySummary,
  removeFavorite,
  saveFavorite,
} from 'app/sm/property_details/property_details_access'
import { getCompleteness } from 'app/sm/rental_reputation/access'
import store from 'app/store'

export const PING = 'AP_PING'
export const SET_PROPERTY_ID = 'AP_SET_PROPERTY_ID'
export const RECEIVE_PROPERTY = 'AP_RECEIVE_PROPERTY'
export const RECEIVE_APPLICATION = 'AP_RECEIVE_APPLICATION'
export const RECEIVE_APPLICANT = 'AP_RECEIVE_APPLICANT'
export const ON_ADDER_CHANGE = 'AP_ON_ADDER_CHANGE'
export const GROUP_INPUT = 'AP_GROUP_INPUT'
export const PETS_INPUT = 'AP_PETS_INPUT'
export const REQUESTS_INPUT = 'AP_REQUESTS_INPUT'
export const PEOPLE_INPUT = 'AP_PEOPLE_INPUT'
export const ADULT_INPUT = 'AP_ADULT_INPUT'
export const CHILDREN_INPUT = 'AP_CHILDREN_INPUT'
export const TOGGLE_FAVORITE_MARK = 'AP_TOGGLE_FAVORITE_MARK'
export const RECEIVE_PROPERTY_SUMMARY = 'AP_RECEIVE_PROPERTY_SUMMARY'
export const RECEIVE_COMPLETENESS = 'AP_RECEIVE_COMPLETENESS'
export const RECEIVE_SNUG_RANK = 'AP_RECEIVE_SNUG_RANK'
export const RECEIVE_APPLICATION_APPLY_RULES =
  'AP_RECEIVE_APPLICATION_APPLY_RULES'
export const RECEIVE_OTHER_OFFERS = 'AP_RECEIVE_OTHER_OFFERS'
export const RECEIVE_RESPONSE_TEXT = 'AP_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'AP_RECEIVE_ERROR'
export const TAKE_HOLDING_DEPOSIT = 'AP_TAKE_HOLDING_DEPOSIT'
export const CLEAR_ERROR = 'AP_CLEAR_ERROR'
export const RESET_APPLICATION = 'RESET_APPLICATION'
export const RESET_STATE = 'AP_RESET_STATE'
export const NEW_TENANT = 'AP_NEW_TENANT'
export const REMOVE_TENANT = 'AP_REMOVE_TENANT'
export const ADD_CURRENT_NEW_TENANT = 'ADD_CURRENT_NEW_TENANT'
export const CLEAR_CURRENT_NEW_TENANT = 'CLEAR_CURRENT_NEW_TENANT'
export const RECEIVE_PROPERTY_OFFER = 'AP_RECEIVE_PROPERTY_OFFER'
export const HAS_ACCEPTED_CONDITION_INTPU = 'AP_HAS_ACCEPTED_CONDITION_INTPU'
export const ABOUT_ME_INPUT = 'ABOUT_ME_INPUT'
export const HOUSEHOLD_YEARLY_INCOME = 'HOUSEHOLD_YEARLY_INCOME'

export const RECEIVE_CONFIRMATION_SUMMARY = 'AP_RECEIVE_CONFIRMATION_SUMMARY'
export const RECEIVE_APPLYANYWHERE = 'RECEIVE_APPLYANYWHERE'
export const RECEIVE_PROPERTY_DISCLOSURES = 'RECEIVE_PROPERTY_DISCLOSURES'
export const START_NEW_APPLICATION = 'START_NEW_APPLICATION'

export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })
export const clearError = () => ({ type: CLEAR_ERROR })
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
export const ping = () => ({ type: PING })
export const groupInput = (input) => ({ type: GROUP_INPUT, input })
export const petsInput = (input) => ({ type: PETS_INPUT, input })
export const peopleInput = (input) => ({ type: PEOPLE_INPUT, input })
export const adultInput = (input) => ({ type: ADULT_INPUT, input })
export const childrenInput = (input) => ({ type: CHILDREN_INPUT, input })
export const requestsInput = (input) => ({ type: REQUESTS_INPUT, input })
export const aboutMeInput = (input) => ({ type: ABOUT_ME_INPUT, input })
export const householdYearlyInput = (input) => ({
  type: HOUSEHOLD_YEARLY_INCOME,
  input,
})

export const hasAcceptedConditionInput = (input) => ({
  type: HAS_ACCEPTED_CONDITION_INTPU,
  input,
})
export const toggleFavoriteMark = () => ({ type: TOGGLE_FAVORITE_MARK })
export const resetApplication = () => ({ type: RESET_APPLICATION })
export const resetState = () => ({ type: RESET_STATE })
export const receivePropertyOffer = (offer) => ({
  type: RECEIVE_PROPERTY_OFFER,
  offer,
})
export const startNewApplication = () => ({ type: START_NEW_APPLICATION })

const receiveSnugRank = (snugRank) => ({
  type: RECEIVE_SNUG_RANK,
  snugRank,
})

const receiveApplicationWorkflow = (applyRules) => ({
  type: RECEIVE_APPLICATION_APPLY_RULES,
  applyRules,
})

const receivePropertyDisclosures = (propertyDisclosure) => ({
  type: RECEIVE_PROPERTY_DISCLOSURES,
  propertyDisclosure,
})

const receiveOtherOffers = (otherOffers) => ({
  type: RECEIVE_OTHER_OFFERS,
  otherOffers,
})

const receivePropertySummary = (summary) => ({
  type: RECEIVE_PROPERTY_SUMMARY,
  summary,
})

const receiveProperty = (property) => ({
  type: RECEIVE_PROPERTY,
  property,
})

export const receiveApplyAnywhereListing = (applyAnywhereListing) => ({
  type: RECEIVE_APPLYANYWHERE,
  applyAnywhereListing,
})

const receiveApplication = (application) => ({
  type: RECEIVE_APPLICATION,
  application,
})

const receiveApplicant = (applicant) => ({
  type: RECEIVE_APPLICANT,
  applicant,
})

const receiveCompleteness = (completeness) => ({
  type: RECEIVE_COMPLETENESS,
  completeness,
})

const receiveTakeHoldingDeposit = (takeHoldingDeposit) => ({
  type: TAKE_HOLDING_DEPOSIT,
  takeHoldingDeposit,
})

const setPropertyId = (propertyId) => ({
  type: SET_PROPERTY_ID,
  propertyId,
})

const receiveConfirmatioSummary = (confirmationSummary) => ({
  type: RECEIVE_CONFIRMATION_SUMMARY,
  confirmationSummary,
})

export const newTenant = (tenant) => ({
  type: NEW_TENANT,
  tenant,
})

const removeTenantLocally = (nth) => ({
  type: REMOVE_TENANT,
  nth,
})

export const addCurrentNewTenant = (currentTenant) => ({
  type: ADD_CURRENT_NEW_TENANT,
  currentTenant,
})

export const clearCurrentNewTenant = () => ({
  type: CLEAR_CURRENT_NEW_TENANT,
})

export function removeTenant(applicantId, success) {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    const applicationId = getState().apply.application.guidID

    deleteApplicant(applicationId, applicantId).then(({ ok, responseText }) => {
      if (ok) {
        !!success && success()
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}

const adderChangeAction = (adderType, operation, payload) => ({
  type: ON_ADDER_CHANGE,
  adderType,
  operation,
  payload,
})

function subscribeRenterScore() {
  store.dispatch(clearError())
  return (dispatch, getState) => {
    const { propertyId: offerId, application, adders } = getState().apply

    if (isApplicationApplyAnywhere(application.applicationType)) {
      return
    }

    const renterScorePayload = {
      rent: adders['rent'].quantity,
      term: adders['term'].quantity.toString(),
      moveInDate: constructDateFromAdderEntity(adders['moveInDate']),
    }

    if (renterScorePayload.rent && renterScorePayload.term) {
      _subscribeRenterScore(offerId, renterScorePayload).then(
        ({ ok, snugRank, responseText }) => {
          if (ok) {
            dispatch(receiveSnugRank(snugRank))
          } else {
            dispatchAPIResponse(dispatch, responseText, receiveResponseText)
          }
        },
      )
    }
  }
}

function fetchApplicationSummary(applicationId) {
  return (dispatch) => {
    getApplicationSummary(applicationId).then(
      ({ ok, summary, responseText }) => {
        if (ok) {
          const {
            snugFit: snugRank,
            otherOffers,
            applicant,
            application,
            offerPreferences,
            propertyDisclosure = {},
            applyRules = {},
          } = summary
          dispatch(receiveApplicant(applicant))
          dispatch(receiveSnugRank(snugRank))
          if (propertyDisclosure !== null) {
            dispatch(receivePropertyDisclosures(propertyDisclosure))
          }
          offerPreferences &&
            dispatch(
              receiveTakeHoldingDeposit(offerPreferences.takeHoldingDeposit),
            )

          if (otherOffers !== undefined) {
            dispatch(receiveOtherOffers(otherOffers))
          }

          if (applyRules !== undefined) {
            dispatch(receiveApplicationWorkflow(applyRules))
          }
        } else {
          dispatchAPIResponse(dispatch, responseText, receiveResponseText)
        }
      },
    )
  }
}

export function onAdderChange(adderType, operation, payload, isApplyAnywhere) {
  const isNotApplyAnywhere = isApplyAnywhere === undefined || !isApplyAnywhere
  return (dispatch) => {
    dispatch(adderChangeAction(adderType, operation, payload))
    if (isNotApplyAnywhere) {
      dispatch(subscribeRenterScore())
    }
  }
}

export function fetchPropertySummary(id) {
  return (dispatch) => {
    getPropertySummary(id).then(({ ok, summary, responseText }) => {
      if (ok) {
        const { snugFit } = summary
        dispatch(receivePropertySummary(summary))
        dispatch(receiveSnugRank(snugFit))
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}

const formatReceivedOffer = (offer, formattedDate, fromApplication) =>
  Object.assign(
    {},
    fromApplication
      ? {
          ...offer,
          term: offer.term,
        }
      : {
          term: Number(offer.acceptedLeaseLength.split(';')[0]),
        },
    {
      rent: offer.weeklyRent,
      moveInDate: {
        year: formattedDate.year,
        quantity: formattedDate.quantity,
        measure: formattedDate.measure,
      },
    },
  )

export function fetchProperty(id) {
  return (dispatch) => {
    dispatch(setPropertyId(id))
    dispatch(resetApplication())
    return getOffer(id).then(({ ok, offer, responseText, statusCode }) => {
      if (ok) {
        const term = Number(offer.acceptedLeaseLength.split(';')[0])
        const formattedDate = constructAdderEntityFromDate(offer.availableFrom)
        dispatch(
          receivePropertyOffer(
            formatReceivedOffer(offer, formattedDate, false),
          ),
        )
        dispatch(fetchPropertySummary(offer.property.guidID))
        dispatch(receiveProperty(offer.property))
        return Promise.resolve(offer.property)
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
        return responseText.then((error) => Promise.reject(error))
      }
    })
  }
}

export function fetchApplication(applicationId, success) {
  return (dispatch) => {
    return getApplication(applicationId).then(
      ({ application, ok, responseText, statusCode }) => {
        if (ok) {
          const { applyAnywhereListing } = application
          const { guidID, property } = application.offer // Its named propertyId below for historical reasons, should be renamed
          const formattedDate = constructAdderEntityFromDate(
            application.leaseStartDate,
          )
          dispatch(
            receivePropertyOffer(
              formatReceivedOffer(application, formattedDate, true),
            ),
          )
          dispatch(receiveApplication(application))
          dispatch(setPropertyId(guidID))
          dispatch(fetchApplicationSummary(applicationId))
          if (!isEmpty(applyAnywhereListing)) {
            dispatch(receiveApplyAnywhereListing(applyAnywhereListing))
          } else {
            dispatch(receiveProperty(property))
          }
          if (success) {
            success(application)
          }
          return Promise.resolve(application)
        } else {
          dispatchAPIResponse(dispatch, responseText, receiveResponseText)
        }
      },
    )
  }
}

// promisified version of getApplication
export const fetchApplicationNew = (applicationGUID) => (dispatch) => {
  return getApplication(applicationGUID).then(
    ({ application, ok, responseText }) => {
      if (ok) {
        return Promise.resolve(application)
      } else {
        return responseText.then((error) => Promise.reject(application))
      }
    },
  )
}

export const fetchRenterSummary = (applicationGUID) => (dispatch) => {
  return getRenterSummary(applicationGUID).then(
    ({ renterSummary, ok, responseText, statusCode }) => {
      if (ok) {
        dispatch(receiveBackgroundCheck(renterSummary.backgroundCheck))
        return Promise.resolve({ renterSummary })
      } else {
        return responseText
          .then((error) => translateErrorCodeToMessage(error))
          .then((error) => Promise.reject(error))
      }
    },
  )
}

export function fetchApplicationForApplicant(applicationId, success) {
  return (dispatch) => {
    getApplicationByUserId(applicationId).then(
      ({ application, ok, responseText, statusCode }) => {
        if (ok) {
          const { applyAnywhereListing } = application
          const { guidID, property } = application.offer // Its named propertyId below for historical reasons, should be renamed
          const formattedDate = constructAdderEntityFromDate(
            application.leaseStartDate,
          )
          dispatch(
            receivePropertyOffer(
              formatReceivedOffer(application, formattedDate, true),
            ),
          )
          dispatch(receiveApplication(application))
          dispatch(setPropertyId(guidID))
          dispatch(fetchApplicationSummary(applicationId))
          if (!isEmpty(applyAnywhereListing)) {
            dispatch(receiveApplyAnywhereListing(applyAnywhereListing))
          } else {
            dispatch(receiveProperty(property))
          }
          if (success) {
            success(application)
          }
        } else {
          dispatchAPIResponse(dispatch, responseText, receiveResponseText)
        }
      },
    )
  }
}

export function saveDraftApplication(
  applicants,
  applicantSummary,
  isApplyAnywhere,
  applyAnywhereGUID,
  children = [],
  applicationOption = {},
  allVehicles,
  config,
  referrerPayload,
) {
  return (dispatch, getState) => {
    const {
      propertyId,
      application: a,
      adders,
      currentTenant,
    } = getState().apply
    let application = Object.assign({}, a, {
      rent: adders['rent'].quantity,
      term: adders['term'].quantity.toString(),
      moveInDate: constructDateFromAdderEntity(adders['moveInDate']),
      vehicles: adders['vehicles'].quantity,
      applicationType: isApplyAnywhere
        ? applicationTypeCollection.applyAnywhere
        : applicationTypeCollection.standard,
      rentScheduleType: applicationOption.pickedRentSchedule,
      allVehicles,
      config,
      ...referrerPayload,
    })

    if (
      applicationOption &&
      applicationOption.hasOwnProperty('requestCarSpace')
    ) {
      application['requestCarSpace'] = !!applicationOption.requestCarSpace
    }

    if (
      applicationOption &&
      applicationOption.hasOwnProperty('idDocsNotRequested')
    ) {
      application['publicConfig'] = {
        agencyOptions: {
          idDocsNotRequested: !!applicationOption.idDocsNotRequested,
        },
      }
    }

    if (
      applicationOption &&
      applicationOption.hasOwnProperty('isBallotEntry')
    ) {
      application['isBallotEntry'] = applicationOption?.isBallotEntry
    }

    if (
      applicationOption &&
      applicationOption.hasOwnProperty('ballotDisclosuresAnswers')
    ) {
      application['ballotDisclosuresAnswers'] =
        applicationOption?.ballotDisclosuresAnswers
    }

    if (!applicants.length) {
      application = Object.assign({}, application, {
        applicants: [],
      })
    } else {
      application = Object.assign({}, application, {
        applicants: applicants
          .filter((applicant) => applicant.isJointApplicant)
          .map((applicant) => ({
            ...applicant,
            mobileNumber:
              applicant.mobileNumber &&
              applicant.mobileNumber.replace(/\s+/g, ''),
            email: applicant.email && applicant.email.replace(/\s+/g, ''),
            guidID:
              applicant.sourceStatus === contactRelationshipCollection.occupant
                ? ''
                : applicant.guidID,
          })),
      })
      application = Object.assign({}, application, {
        occupants: applicants
          .filter((applicant) => !applicant.isJointApplicant)
          .map((applicant) => ({
            ...applicant,
            mobileNumber:
              applicant.mobileNumber &&
              applicant.mobileNumber.replace(/\s+/g, ''),
            email: applicant.email && applicant.email.replace(/\s+/g, ''),
            guidID:
              applicant.sourceStatus === contactRelationshipCollection.applicant
                ? ''
                : applicant.guidID,
          })),
      })
    }
    application.isJoint = !!application.applicants.length

    application = Object.assign({}, application, { children })

    application = Object.assign({}, application, { applicantSummary })

    if (application?.householdYearlyIncome) {
      application = Object.assign({}, application, {
        householdYearlyIncome: Number(application.householdYearlyIncome),
      })
    }

    return pushApplication(
      propertyId,
      application,
      true,
      isApplyAnywhere,
      applyAnywhereGUID,
    ).then(({ ok, responseText, statusCode, returnedApplication }) => {
      if (ok) {
        return Promise.resolve(returnedApplication)
      } else {
        // It doesn't make sense to dispatch an error here. The user would already be at another screen
        return responseText.then((t) => {
          dispatch(receiveResponseText(t))
          return Promise.reject(t)
        })
      }
    })
  }
}

export const createOffPlatformApplication = (application) => (dispatch) => {
  return postOffPlatformApplication(application).then(
    ({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText
          .then((error) => translateErrorCodeToMessage(error))
          .then((error) => Promise.reject(error))
      }
    },
  )
}

export const editOffPlatformApplication =
  (application, applicationId) => (dispatch) => {
    return putOffPlatformApplication(application, applicationId).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText
            .then((error) => translateErrorCodeToMessage(error))
            .then((error) => Promise.reject(error))
        }
      },
    )
  }

export function deleteDraftApplication(applicationId, success, fail) {
  return (dispatch) => {
    deleteDraft(applicationId).then(({ ok, responseText }) => {
      if (ok) {
        success()
      } else {
        apiFailCallback(responseText, fail)
      }
    })
  }
}

export function submitApplication(
  isOffer,
  applicants,
  applicantSummary,
  success,
  fail,
  children = [],
  applicationOption = {},
  allVehicles,
) {
  return (dispatch, getState) => {
    dispatch(clearError())
    const { propertyId, application: a, adders } = getState().apply
    let application = Object.assign({}, a, {
      rent: adders['rent'].quantity,
      term: adders['term'].quantity,
      vehicles: adders['vehicles'].quantity,
      moveInDate: constructDateFromAdderEntity(adders['moveInDate']),
      rentScheduleType: applicationOption.pickedRentSchedule,
      allVehicles,
    })

    if (
      applicationOption &&
      applicationOption.hasOwnProperty('requestCarSpace')
    ) {
      application['requestCarSpace'] = !!applicationOption.requestCarSpace
    }
    if (
      applicationOption &&
      applicationOption.hasOwnProperty('idDocsNotRequested')
    ) {
      application['publicConfig'] = {
        agencyOptions: {
          idDocsNotRequested: !!applicationOption.idDocsNotRequested,
        },
      }
    }

    if (!applicants.length) {
      application = Object.assign({}, application, {
        applicants: [],
      })
    } else {
      application = Object.assign({}, application, {
        applicants: applicants
          .filter((applicant) => applicant.isJointApplicant)
          .map((applicant) => ({
            ...applicant,
            mobileNumber:
              applicant.mobileNumber &&
              applicant.mobileNumber.replace(/\s+/g, ''),
            email: applicant.email && applicant.email.replace(/\s+/g, ''),
            guidID:
              applicant.sourceStatus === contactRelationshipCollection.occupant
                ? ''
                : applicant.guidID,
          })),
      })

      application = Object.assign({}, application, {
        occupants: applicants
          .filter((applicant) => !applicant.isJointApplicant)
          .map((applicant) => ({
            ...applicant,
            mobileNumber:
              applicant.mobileNumber &&
              applicant.mobileNumber.replace(/\s+/g, ''),
            email: applicant.email && applicant.email.replace(/\s+/g, ''),
            guidID:
              applicant.sourceStatus === contactRelationshipCollection.applicant
                ? ''
                : applicant.guidID,
          })),
      })
    }
    application.isJoint = !!application.applicants.length

    application = Object.assign({}, application, { children })

    const applicationValidation = validateApplication(application)

    if (!applicationValidation.ok) {
      store.dispatch(receiveResponseText(applicationValidation.errorCode))
      apiFailCallback(
        Promise.resolve(ErrorMessages[applicationValidation.errorCode]),
        fail,
      )
      return Promise.reject()
    }

    application = Object.assign({}, application, { applicantSummary })

    return pushApplication(propertyId, application, false).then((res) => {
      const { ok, responseText, statusCode, application } = res
      if (ok) {
        // dispatch(receiveApplication(application));
        return Promise.resolve()
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
        apiFailCallback(responseText, fail)
        return responseText.then((error) => Promise.reject(error))
      }
    })
  }
}

export const submitQuickApplication =
  (applicants, applicantSummary) => (dispatch, getState) => {
    const { propertyId, application: a, adders } = getState().apply
    let application = Object.assign({}, a, {
      rent: adders['rent'].quantity,
      term: adders['term'].quantity,
      vehicles: adders['vehicles'].quantity,
      moveInDate: constructDateFromAdderEntity(adders['moveInDate']),
    })

    if (!applicants.length) {
      application = Object.assign({}, application, {
        applicants: [],
      })
    } else {
      application = Object.assign({}, application, {
        applicants: applicants.map((applicant) => ({
          ...applicant,
          mobileNumber:
            applicant.mobileNumber &&
            applicant.mobileNumber.replace(/\s+/g, ''),
          email: applicant.email && applicant.email.replace(/\s+/g, ''),
        })),
      })
    }
    application.isJoint = !!application.applicants.length

    const applicationValidation = validateApplication(application)
    if (!applicationValidation.ok) {
      return Promise.reject(ErrorMessages[applicationValidation.errorCode])
    }

    application = Object.assign({}, application, { applicantSummary })

    return pushQuickApplication(propertyId, application, false).then(
      ({ ok, application, responseText, statusCode }) => {
        if (ok) {
          // TODO: thanks screen redirect
          return Promise.resolve(application)
        } else {
          // dispatchAPIResponse(dispatch, responseText, receiveResponseText);
          // apiFailCallback(responseText, fail);
          return responseText.then((error) => Promise.reject(error))
        }
      },
    )
  }

function addFavorite(propertyId) {
  store.dispatch(clearError())
  return (dispatch) => {
    saveFavorite(propertyId).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(toggleFavoriteMark())
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}

function deleteFavorite(propertyId) {
  store.dispatch(clearError())
  return (dispatch) => {
    removeFavorite(propertyId).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(toggleFavoriteMark())
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}

export function heartClicked(propertyId, isFavorite) {
  return (dispatch) => {
    if (!isFavorite) {
      dispatch(addFavorite(propertyId))
    } else {
      dispatch(deleteFavorite(propertyId))
    }
  }
}

export function fetchCompleteness() {
  return (dispatch) => {
    getCompleteness().then(({ ok, completeness, responseText }) => {
      if (ok) {
        dispatch(receiveCompleteness(completeness))
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}

export function acceptApplication(
  offerId,
  applicationId,
  propertyId,
  newLeaseOffer = false,
) {
  return (dispatch) => {
    return syncAcceptApplication(offerId, applicationId).then(
      ({ ok, responseText }) => {
        if (ok) {
          const {
            location: { pathname },
          } = history
          const isOfferApplicationRoute = [/sm/, /property/, /offer/].every(
            (routePath) => routePath.test(pathname),
          )

          if (isOfferApplicationRoute) {
            dispatch(fetchOfferedApplication(applicationId))
          }
          return Promise.resolve()
        } else {
          if (responseText) {
            responseText.then((t) => store.dispatch(receiveResponseText(t)))
          } else {
            dispatch(receiveResponseText('404'))
          }
          return responseText
            .then((error) => translateErrorCodeToMessage(error))
            .then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export function acceptJointApplicationAsSecondary(
  applicationId,
  applicantId,
  payload,
  apply,
  data,
) {
  return (dispatch) => {
    return postAcceptJointApplicationAsSecondary(
      applicationId,
      applicantId,
      payload,
      apply,
      data,
    ).then((res) => {
      if (res.ok) {
        return Promise.resolve()
      } else {
        dispatchAPIResponse(dispatch, res.responseText, receiveResponseText)
        return res.responseText
          .then((error) => translateErrorCodeToMessage(error))
          .then((error) => Promise.reject(error))
      }
    })
  }
}

export function fetchApplicantSummary() {
  return (dispatch) => {
    return getApplicantSummary().then(
      ({ ok, applicantSummary, responseText }) => {
        if (ok) {
          return Promise.resolve(applicantSummary)
        } else {
          return responseText.then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export function submitFinalApplication(applicationId, data) {
  return (dispatch) => {
    return pushFinalApplication(applicationId, data).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          dispatchAPIResponse(dispatch, responseText, receiveResponseText)
          return responseText
            .then((error) => translateErrorCodeToMessage(error))
            .then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export function updateApplicantDisclosures(applicantID, data) {
  return () => {
    return putApplicantDisclosures(applicantID, data).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function fetchApplicantConfirmationSummary(applicationId) {
  return (dispatch) => {
    return getApplicantConfirmationSummary(applicationId).then(
      ({ ok, confirmationSummary, responseText }) => {
        if (ok) {
          dispatch(receiveConfirmatioSummary(confirmationSummary))
          return Promise.resolve(confirmationSummary)
        } else {
          dispatchAPIResponse(dispatch, responseText, receiveResponseText)
        }
      },
    )
  }
}

export function fetchApplyAnywhereListing(applyAnywhereListingGUID) {
  return (dispatch) => {
    return getApplyAnywhereListing(applyAnywhereListingGUID).then(
      ({ ok, applyAnywhereListing, responseText }) => {
        if (ok) {
          dispatch(receiveApplyAnywhereListing(applyAnywhereListing))
          return Promise.resolve(applyAnywhereListing)
        } else {
          dispatchAPIResponse(dispatch, responseText, receiveResponseText)
        }
      },
    )
  }
}

export const removeOccupant = (teamId, occupantId) => {
  return (dispatch) => {
    return deleteOccupant(teamId, occupantId).then((ok, responseText) => {
      if (ok) {
        return Promise.resolve()
      } else {
        dispatchAPIResponse(dispatch, responseText, receiveResponseText)
      }
    })
  }
}
