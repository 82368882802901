import React, { Component, useEffect } from 'react'

import Bowser from 'bowser'
import { connect } from 'react-redux'
import { Redirect, Route, withRouter } from 'react-router-dom'

import BasicModal from 'app/modals/basic_modal'
import { fetchTeams } from 'app/session/session_actions'
import { isMobileOrTablet } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import {
  db,
  dbKeys,
  getQueryString,
  trackingSource,
  urlIds,
  urlTo,
} from 'app/sm/helpers'
import { SessionStorageUtils } from 'app/storage_utils'
import { APPLICATION_APPLY_URL } from 'app/utils/url/helpers'

const optionForBrowserUpdate = {
  chrome: '>51',
  firefox: '>54',
  opera: '>60',
  'internet explorer': '>11',
  edge: '>14',
  safari: '>10',
}

function bindRedirectLink(path, isPm, teams) {
  if (path !== '/confirm' && path !== '/verify') {
    const forwardUrl = SessionStorageUtils.getItem('forwardUrl')
    if (forwardUrl) {
      SessionStorageUtils.removeItem('forwardUrl')
      return forwardUrl
    } else {
      // for Renter
      if (!isPm) {
        return urlTo('homeoverview')
      }

      // for PM
      return isMobileOrTablet
        ? urlTo('teamMobileViewings', { teamSlug: teams[1].slug })
        : urlTo('teamOverview', { teamSlug: teams[1].slug })
    }
  }

  if (db.has(dbKeys.property_details)) {
    const payload = db.get(dbKeys.property_details)
    const propertyId = (payload || {}).propertyId

    if (propertyId !== undefined) {
      history.push(urlTo('propertyDetails', { propertyId }))
      SessionStorageUtils.removeItem(dbKeys.property_details)
      return history.push(urlTo('propertyDetails', { propertyId }))
    }
  }

  if (db.has(dbKeys.applyLinkUnauthenticated)) {
    const payload = db.get(dbKeys.applyLinkUnauthenticated)
    history.push(payload)
    SessionStorageUtils.removeItem(dbKeys.applyLinkUnauthenticated)
    return true
  }

  return urlTo('homeoverview')
}

const Protected = ({
  component: Component,
  exact,
  path,
  currentUser = {},
  location,
}) => (
  <Route
    exact={exact}
    path={path}
    render={(props) => {
      SessionStorageUtils.setItem(
        'forwardUrl',
        `${location.pathname}${location.search}`,
      )
      const utmSource = getQueryString('utm_source')
      const isUserLoggedInAndVerified =
        !currentUser.isUserFetching &&
        !currentUser.isUserProfileFetching &&
        currentUser.isVerified
      const isUserLoginInProgress =
        currentUser.isUserFetching || currentUser.isUserProfileFetching
      if (isUserLoginInProgress || isUserLoggedInAndVerified) {
        return <Component {...props} />
      } else if (!isUserLoggedInAndVerified) {
        return (
          <Redirect
            to={{ pathname: urlTo('join'), search: window.location.search }}
          />
        )
      } else if (currentUser.userType === 'email') {
        return <Redirect to="/verify" />
      } else if (currentUser.userType === 'facebook') {
        return <Redirect to="/confirm" />
      } else if (trackingSource(utmSource, 'facebook')) {
        return (
          <Redirect
            to={{ pathname: urlTo('join'), search: window.location.search }}
          />
        )
      } else if (trackingSource(utmSource, 'adroll')) {
        return (
          <Redirect
            to={{ pathname: urlTo('join'), search: window.location.search }}
          />
        )
      } else if (path.includes('apply') && path !== APPLICATION_APPLY_URL) {
        return (
          <Redirect
            to={{ pathname: urlTo('join'), search: window.location.search }}
          />
        )
      } else {
        return (
          <Redirect
            to={{ pathname: urlTo('join'), search: window.location.search }}
          />
        )
      }
    }}
  />
)

const Auth = ({
  component: Component,
  path,
  currentUser = {},
  currentAgency,
  currentAgencyLoaded,
  fetchTeams,
  teams,
  teamsLoaded,
}) => {
  // used same old condition to maintain the same behaviors
  const showComponent = !currentUser.isVerified || currentUser.bindedRolePrompt

  const isPm = currentAgency && currentAgency.agencyID

  useEffect(() => {
    isPm && fetchTeams()
  }, [currentAgency])

  const toRenderComponent = (props) => {
    if (
      showComponent ||
      (isPm && !currentAgencyLoaded) ||
      (isPm && !teamsLoaded)
    )
      return <Component {...props} />

    return <Redirect to={bindRedirectLink(path, isPm, teams)} />
  }

  return <Route path={path} render={toRenderComponent} />
}

class ScrollToTop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showUpdateBrowserModal: false,
    }
  }

  componentDidMount() {
    const browser = Bowser.getParser(window.navigator.userAgent)
    const isValidBrowser = browser.satisfies(optionForBrowserUpdate)
    if (!isValidBrowser) {
      this.setState({
        showUpdateBrowserModal: true,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      document.querySelector('.app-content')
    ) {
      document.querySelector('.app-content').scrollTop = 0
    }
  }

  toggleModal = () => {
    this.setState({
      showUpdateBrowserModal: !this.state.showUpdateBrowserModal,
    })
  }

  render() {
    const { showUpdateBrowserModal } = this.state
    return (
      <div id="app-scrollable-container">
        {this.props.children}
        {showUpdateBrowserModal && (
          <BasicModal
            toggleModal={this.toggleModal}
            title={'Update your browser'}
            body={
              'Snug.com works best with recent versions of Google Chrome, Firefox or Safari. Browsers like Internet Explorer may not be compatible with all our features.'
            }
            primaryButtonLabel={'Continue'}
            primaryButtonAction={() => this.toggleModal()}
            showSecondaryButton={false}
          />
        )}
      </div>
    )
  }
}

// !currentUser.mobileVerified ? <Component {...props} /> : <Redirect to={bindRedirectLink(path, forwardUrl)} />

const mapStateToProps = ({ session }) => ({
  currentUser: session.currentUser,
  currentAgencyLoaded: session.currentAgencyLoaded,
  currentAgency: session.currentAgency,
  teams: session.teams,
  teamsLoaded: session.teamsLoaded,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTeams: () => dispatch(fetchTeams()),
})
export const ProtectedRoute = withRouter(connect(mapStateToProps)(Protected))

export const AuthRoute = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Auth),
)

export const ScrollTop = withRouter(ScrollToTop)
