import React from 'react'

import { Link } from 'react-router-dom'

class ToDoCard extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { todo } = this.props

    return (
      <div className="sm-property-todo-card">
        <input
          type="checkbox"
          className={todo.overdue != '' ? 'overdue' : ''}
        />
        <div className="content-wrapper">
          <div className="user">
            <div className="text">
              {todo.title != '' && <b>{todo.title}</b>}
              {todo.amount != '' && <div>{todo.amount}</div>}
              {todo.userFirstname != '' && (
                <div>
                  by {todo.userFirstname} {todo.userLastname.charAt(0)}.
                </div>
              )}
              {todo.dueDate != '' && <div>Due {todo.dueDate}</div>}
            </div>
            {todo.avatarImage != '' && (
              <div
                className="avatar avatar-xsmall"
                style={{ backgroundImage: `url('${todo.avatarImage}')` }}
              ></div>
            )}
            {todo.avatarIcon != '' && (
              <div
                className={`avatar avatar-xsmall ${todo.avatarIconColor}-bg-light-rgba ${todo.avatarIconColor}-color`}
              >
                <i className={`icon-${todo.avatarIcon}`}></i>
              </div>
            )}
          </div>
          {todo.tags.length > 0 && (
            <ul className="tags">
              {todo.tags.map((tag, index) => (
                <li key={index}>{tag}</li>
              ))}
            </ul>
          )}
          {todo.description != '' && (
            <div className="description">{todo.description}</div>
          )}
          {todo.actions.length > 0 && (
            <div className="actions">
              {todo.actions.map((action, index) => (
                <Link to={'/'} key={index}>
                  {action}
                </Link>
              ))}
            </div>
          )}
          {todo.overdue != '' && (
            <div className="overdue">
              <i className="yellow-bg">!</i>
              <span>{todo.overdue}</span>
            </div>
          )}
        </div>
        <div className="controls">
          <Link to={'/'}>
            <i className="icon-edit" title="Edit"></i>
          </Link>
          <Link to={'/'}>
            <i className="icon-delete" title="Delete"></i>
          </Link>
        </div>
      </div>
    )
  }
}

export default ToDoCard
