import React from 'react'

import moment from 'moment'
import qs from 'qs'
import { Link } from 'react-router-dom'

import { history } from 'app/shared_components/router'
import AvatarPlaceholder from 'app/sm/common/avatar_placeholder'
import Completions from 'app/sm/common/completions'
import Gallery from 'app/sm/common/gallery'
import {
  ApplicationCategory,
  CategoryTypes,
  getCarSpaceCountForProperty,
  isCurrentApplicationStageOffer,
  NO_IMAGE_PLACEHOLDER_URL,
  PropertyStatus,
  urlTo,
} from 'app/sm/helpers'

const DashboardModal = ({
  toggleModal,
  acceptWithdraw,
  applicationId,
  withdrawButtonClicked,
}) => (
  <div className="modal-fullscreen-withdraw">
    <div className="modal-wrapper-withdraw">
      <div className="modal-close">
        <i className="fa fa-times" onClick={() => toggleModal()} />
      </div>
      <div className="modal-header">
        <h4>
          If you withdraw and still wish to apply for this property, you will
          need to submit a new application.
        </h4>
      </div>
      <div className="modal-body">
        <button
          disabled={withdrawButtonClicked}
          className="btn mt10"
          onClick={() => acceptWithdraw(applicationId)}
        >
          Withdraw
          <i
            className={withdrawButtonClicked ? 'fa fa-spinner fa-pulse' : ''}
          />
        </button>
      </div>
    </div>
  </div>
)

function formatDate(date) {
  return moment(date).format('D MMM YYYY')
}

function ViewPropertyLink(props) {
  const { propertyStatus, propertyId } = props
  const isAccepted = propertyStatus !== 'Leased' ? true : false
  return isAccepted ? (
    <div className="gray-text linkStyle text-center">
      <Link
        className="gray-text linkStyle text-center"
        to={urlTo('propertyDetails', { propertyId })}
      >
        View Property Details
      </Link>
    </div>
  ) : null
}

const bindStatusTitle = (status) =>
  ({
    Offered: 'Property Offered',
    Accepted: 'Application Accepted',
  }[ApplicationCategory[status]])

class OaTenant extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      acceptButtonClicked: false,
    }
  }
  componentDidMount() {
    const { applicationId } = this.props.match.params

    this.props.fetchOfferedApplication(applicationId)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.error) {
      this.setState({ acceptButtonClicked: false })
    }
  }

  activateSpinner() {
    this.setState({ acceptButtonClicked: true })
  }

  addToSavedProperty() {
    // TODO
  }

  toggleModal() {
    return (event) => {
      let { modalOpen } = this.state
      this.setState({ modalOpen: !modalOpen })
    }
  }

  render() {
    const { modalOpen, acceptButtonClicked } = this.state
    const { propertyId, applicationId } = this.props.match.params
    const curQueries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const { agencyId } = curQueries
    const { property, offeredApplication, withdrawButtonClicked, error } =
      this.props
    const { OfferPreferences: { takeHoldingDeposit = false } = {} } =
      offeredApplication
    const { Application, Events } = offeredApplication
    const { guidID: offerId } = Application.offer || {}
    const title = bindStatusTitle(Application.status)
    let address = ''
    let suburb = ''
    let propertyImage = NO_IMAGE_PLACEHOLDER_URL

    const propertyStatus = PropertyStatus[property.status]

    if (property.address) {
      if (property.address.unitNumber) {
        address = `${property.address.unitNumber}/${property.address.streetNumber} ${property.address.streetName}`
      } else {
        address = `${property.address.streetNumber} ${property.address.streetName}`
      }
      suburb = `${property.address.suburb} ${property.address.state} ${property.address.postcode}`
    }

    if (property.images && property.images.length > 0) {
      propertyImage = (
        property.images.find((image) => image.isMain === true) ||
        property.images[0]
      ).URL
    }

    const isApplicationStageOffer = isCurrentApplicationStageOffer(
      Application.status,
    )

    return (
      <div className="sm-accepted">
        <div className="sm-accepted__header">
          <h3>{title}</h3>
          <div className="status green-bg">
            <i className="icon-checkmark white-color"></i>
          </div>
        </div>

        <div className="sm-accepted__images">
          <div
            className="images"
            style={{ backgroundImage: `url(${propertyImage})` }}
          >
            <div className="controls-left">
              <button onClick={() => this.addToSavedProperty()}>
                <i
                  className={
                    property.isFavourite
                      ? 'icon-heart-filled red-color'
                      : 'icon-heart-outline'
                  }
                ></i>
              </button>
            </div>
            <div className="controls-right">
              <Gallery
                images={(property.images || []).map(({ URL }) => ({
                  src: URL,
                }))}
              />
            </div>
          </div>
        </div>

        <div className="sm-accepted__address">
          <div className="two-col-box sm64">
            <div className="col-first">
              <h2>{address}</h2>
              <h3>{suburb}</h3>
              <ul className="address-meta">
                <li>
                  <span>{CategoryTypes[property.type]}</span>
                </li>
                <li>
                  <i className="icon-bedroom"></i>
                  <span>{property.bedrooms}</span>
                </li>
                <li>
                  <i className="icon-bathroom"></i>
                  <span>{property.bathrooms}</span>
                </li>
                <li>
                  <i className="icon-cars"></i>
                  <span>{getCarSpaceCountForProperty(property)}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="two-col-box sm64">
          <div className="col-first">
            <div className="sm-accepted__application mt20">
              <h3 className="mb15">Your Application</h3>
              <div className="table mb10">
                <div className="cell-m-100">Rent per Week</div>
                <div className="cell-m text-right wsnw">
                  ${Application.weeklyRent}.00
                </div>
              </div>
              <div className="table mb10">
                <div className="cell-m-100">Lease</div>
                <div className="cell-m text-right wsnw">
                  {Application.term} Months
                </div>
              </div>
              <div className="table mb40">
                <div className="cell-m-100">Move in date</div>
                <div className="cell-m text-right wsnw">
                  {formatDate(Application.leaseStartDate)}
                </div>
              </div>
              <div className="gray-text linkStyle text-center">
                <Link
                  to={urlTo('application', {
                    applicationId: Application.guidID,
                  })}
                  className="gray-text linkStyle text-center offer-lease-link-wrapper"
                >
                  View your Application
                </Link>
              </div>
            </div>

            <div className="sm-accepted__nextstep mt50">
              {isApplicationStageOffer && takeHoldingDeposit && (
                <div>
                  <h3 className="mb10">Next Step: Pay Holding Deposit</h3>
                  <p>
                    A holding deposit will help you secure the property between
                    now and when you sign the lease.
                  </p>
                  <Link
                    className="btn mb40"
                    to={urlTo('payHoldingDeposit', {
                      propertyId,
                      applicationId,
                    })}
                  >
                    Pay ${Application.weeklyRent} Holding Deposit
                  </Link>
                </div>
              )}
              <Completions
                Events={Events}
                takeHoldingDeposit={takeHoldingDeposit}
                agencyId={agencyId}
                applicationId={applicationId}
              />
              {isApplicationStageOffer && (
                <div className="text-center pt40 pb10">
                  <div className="pb5">Had a change of heart?</div>
                  <label
                    className="gray-dark-color"
                    onClick={this.toggleModal()}
                  >
                    Withdraw your application
                  </label>
                </div>
              )}
              <hr />
            </div>
            {isApplicationStageOffer && !takeHoldingDeposit && (
              <button
                className="btn btn-big applicant-accept-offer"
                id={property && property.teamSlug}
                onClick={() => {
                  this.activateSpinner()
                  this.props.acceptApplication(offerId, applicationId)
                }}
              >
                <span>
                  Accept offer{' '}
                  <i
                    className={
                      acceptButtonClicked ? 'fa fa-spinner fa-pulse' : ''
                    }
                  />
                </span>
              </button>
            )}

            {isApplicationStageOffer && takeHoldingDeposit && (
              <Link
                className="btn mb40"
                to={urlTo('payHoldingDeposit', { propertyId, applicationId })}
              >
                Pay ${Application.weeklyRent} Holding Deposit
              </Link>
            )}

            {error && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="alert alert-danger">
                    <div>{error}</div>
                  </div>
                </div>
              </div>
            )}

            {modalOpen && (
              <DashboardModal
                toggleModal={this.toggleModal()}
                acceptWithdraw={this.props.acceptWithdraw}
                applicationId={applicationId}
                withdrawButtonClicked={withdrawButtonClicked}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default OaTenant
