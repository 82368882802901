import React from 'react'

import ReferrerBackButton from 'app/components/display/referrerBackButton/component'
import { isReferrerDomainComAU } from 'app/constants/referrer.constants'
import { history } from 'app/shared_components/router'
import Gallery from 'app/sm/common/gallery'
import { NO_IMAGE_PLACEHOLDER_URL } from 'app/sm/helpers'
import { SessionStorageUtils } from 'app/storage_utils'

class ApplyOffer extends React.Component {
  componentDidMount() {
    document.querySelector('.app-content') &&
      document
        .querySelector('.app-content')
        .addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    SessionStorageUtils.removeItem('appicationBackUrl')
    document.querySelector('.app-content') &&
      document
        .querySelector('.app-content')
        .removeEventListener('scroll', this.handleScroll)
  }

  setFavorite() {
    const {
      property: { guidID: propertyID, isFavourite },
      heartClicked,
    } = this.props
    heartClicked(propertyID, isFavourite)
  }

  render() {
    const {
      property,
      isApplyAnywhere = false,
      referrer,
      referrerBackUrl,
      propertyImage,
      imageUrlFromUrl,
    } = this.props
    let propertyImageUrl =
      propertyImage || imageUrlFromUrl || NO_IMAGE_PLACEHOLDER_URL

    if (!isApplyAnywhere && property.images && property.images.length > 0) {
      propertyImageUrl = (
        property.images.find((image) => image.isMain === true) ||
        property.images[0]
      ).URL
    }

    const isFromReferrer = referrer && referrerBackUrl

    let favorite = property.isFavourite
    return (
      <div className="sm-apply__apply-offer">
        {!isApplyAnywhere && !isFromReferrer && (
          <div className="header">
            <div
              className="btn btn-transparent btn-left xs-w100p wa mobile-only"
              onClick={() => {
                history.goBack()
              }}
            >
              <i className="icon-arrow-left left" />
              <span>Back</span>
            </div>
            <div className="fs20 mobile-only txt-mob-pos">Application</div>
          </div>
        )}
        <div
          className="image"
          style={{ backgroundImage: `url(${propertyImageUrl})` }}
        >
          {isFromReferrer && !isReferrerDomainComAU(referrer) && (
            <ReferrerBackButton
              referrer={referrer}
              referrerBackUrl={referrerBackUrl}
            />
          )}
          {!isApplyAnywhere && (
            <div className="controls-left">
              <button onClick={() => this.setFavorite()}>
                <i
                  className={
                    favorite
                      ? 'icon-heart-filled red-color'
                      : 'icon-heart-outline'
                  }
                ></i>
              </button>
            </div>
          )}
          {!isApplyAnywhere && (
            <div className="controls-right">
              <Gallery
                images={(property.images || []).map(({ URL }) => ({
                  src: URL,
                }))}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ApplyOffer
