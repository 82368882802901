export const checkOutStepsConfig = {
  addressBorrowerSelection: {
    id: 'addressBorrowerSelection',
    subHeading: 'What address and borrower?',
  },
  borrowingDetails: {
    id: 'borrowingDetails',
    subHeading: 'Borrowing details',
  },
}

export const checkOutStepsConfigArr = Object.values(checkOutStepsConfig).map(
  (def, index) => ({ def, index }),
)

const actionTypes = {
  setInitialCheckOutData: 'SET_INITIAL_CHECK_OUT_DATA',
  stepSaved: 'STEP_SAVED',
  backStep: 'BACK_STEP',
  nextStep: 'NEXT_STEP',
  setCheckedOut: 'SET_CHECKED_OUT',
  closeModal: 'CLOSE_MODAL',
}

export const actions = {
  setInitialCheckOutData: (
    keySet,
    bulkKeySets,
    borrower,
    isBorrowerDisabled,
  ) => ({
    type: actionTypes.setInitialCheckOutData,
    checkOutData: {
      keySet,
      bulkKeySets,
      property: keySet?.property,
      borrower,
      isBorrowerDisabled,
    },
  }),
  stepSaved: (checkOutData, upsert) => ({
    type: actionTypes.stepSaved,
    checkOutData,
    upsert,
  }),
  backStep: () => ({
    type: actionTypes.backStep,
    indexModifier: -1,
  }),
  nextStep: () => ({
    type: actionTypes.nextStep,
    indexModifier: 1,
  }),
  setCheckedOut: () => ({ type: actionTypes.setCheckedOut }),
  closeModal: () => ({ type: actionTypes.closeModal }),
}

// don't try to use checkInReducer they are different, maybe there share some common action names
// but they are different and they are serving to different components/children components
export const checkOutStateReducer = (state, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.setInitialCheckOutData: {
      const { checkOutData } = action
      return {
        ...state,
        checkOutData,
      }
    }

    case actionTypes.stepSaved: {
      const { checkOutData, upsert } = action
      return {
        ...state,
        checkOutData: upsert
          ? checkOutData
          : {
              ...state.checkOutData,
              checkOutData,
            },
        showCurrentStep: false,
      }
    }

    case actionTypes.nextStep:
    case actionTypes.backStep: {
      const { indexModifier } = action
      const { index } = state.currentStep
      const stepConfig = checkOutStepsConfigArr[index + indexModifier]
      return {
        ...state,
        currentStep: stepConfig,
        showCurrentStep: true,
      }
    }

    case actionTypes.setCheckedOut: {
      return {
        ...state,
        checkedOut: true,
      }
    }

    case actionTypes.closeModal: {
      return {
        ...state,
        closeModal: true,
      }
    }

    default:
      return state
  }
}
