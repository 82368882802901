import React, { useState } from 'react'

import { Formik } from 'formik'

import { Alert, Box, Flex } from 'app/components/design-system-components'
import { CheckboxesInputField } from 'app/components/design-system-components/inputs/Checkboxes'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import { UCSProviderConfig } from 'app/constants/ucs.constants'
import { generateApplicantsText } from 'app/features/teams/applications/utility-referral/utils'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { bulkSendUtilityReferrals } from 'app/services/http/team-applications'
import { getApplicationWorkflowName } from 'app/sm/properties/components/property_search_filter_util'

const formFields = {
  applications: 'applications',
}

const fieldValidation = (fieldName, val) => {
  switch (fieldName) {
    case formFields.applications: {
      return {
        required: !val && 'This field is required',
        min:
          !!val &&
          Object.values(val).length < 1 &&
          'At least one application should be selected',
      }
    }
  }
}

const formValidation = formValidationBuilder(fieldValidation)

const renderActivationModalFooter = (
  onClickActivate,
  onCancel,
  isSubmitting,
  isDisabled,
) => {
  return (
    <GenericModalFooter
      primaryBtnConfig={{
        title: 'Send Referral',
        onClick: onClickActivate,
        props: {
          loading: !!isSubmitting,
          disabled: isDisabled,
        },
      }}
      secondaryBtnConfig={{
        title: 'Cancel',
        onClick: onCancel,
      }}
    />
  )
}

const ApplicationOption = ({ application }) => {
  const { applicants, status } = application

  const statusText = getApplicationWorkflowName(status)

  return `${generateApplicantsText(applicants)} (Status: ${statusText})`
}

export const SendUtilityReferralModal = ({
  applications,
  property,
  config,
  onCloseModal,
}) => {
  const [error, setError] = useState('')

  const { providerId } = config
  const ProviderIcon = UCSProviderConfig[providerId]?.icon
  const ProviderIconElem = !!ProviderIcon && (
    <Flex alignItems="center" maxWidth="100px" ml={theme.space[6] + 'px'}>
      <ProviderIcon height="38px" width="auto" />
    </Flex>
  )

  const applicationOptions = applications.map((application) => ({
    option: application,
    optionMeta: { id: application.guidID },
  }))

  const initialValues = {
    [formFields.applications]:
      applicationOptions?.length === 1
        ? { [applicationOptions[0].optionMeta.id]: applicationOptions[0] }
        : {},
  }

  const propertyName = `${property.address.friendlyName}, ${property.address.suburb}`

  const onSendReferrals = (value) => {
    const selectedApplicationsOptions = value[formFields.applications] || {}
    const payload = {
      applications: Object.values(selectedApplicationsOptions).map(
        ({ option }) => option.guidID,
      ),
    }
    setError('')
    return bulkSendUtilityReferrals(payload)
      .then((response) => {
        onCloseModal(response || {})
      })
      .catch(({ message }) => {
        setError(message)
      })
  }

  const onDismiss = () => {
    onCloseModal()
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSendReferrals}
      validate={formValidation}
    >
      {({ isSubmitting, isValid, submitForm }) => {
        return (
          <GenericModal
            onDismiss={() => onDismiss()}
            title={
              <Flex alignItems="center">
                Send utility referral {ProviderIconElem}
              </Flex>
            }
            modalFooter={renderActivationModalFooter(
              submitForm,
              () => onDismiss(),
              isSubmitting,
              !isValid,
            )}
          >
            {!!error && (
              <Alert variant="warningWithBg" mb={theme.space[3] + 'px'}>
                {error}
              </Alert>
            )}

            <p>
              Send utility connection provider referral for {propertyName + ' '}
              for the following eligible application(s). <a>learn more</a>
            </p>

            <Box>
              <CheckboxesInputField
                id={formFields.applications}
                name={formFields.applications}
                options={applicationOptions}
                optionRenderer={({ option }) => (
                  <ApplicationOption application={option} />
                )}
              />
            </Box>
          </GenericModal>
        )
      }}
    </Formik>
  )
}
