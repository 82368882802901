import React from 'react'

import lockIcon from 'app/assets/icons/lock.svg'
import * as Display from 'app/components/display/display'
import * as Form from 'app/components/forms/forms'
import { STANDARD_TIMEOUT } from 'app/helpers/debounce'
import * as snugNotifier from 'app/services/snugNotifier'
import Spinner from 'app/sm/common/spinner'
import * as helpers from 'app/sm/helpers'
import ViewingContactItem from 'app/sm/inspections/components/viewing_contact_item'
import ViewingContactSearchSection from 'app/sm/inspections/components/viewing_contact_search_section'
import {
  NUMBER_TYPE_LANDLINE,
  NUMBER_TYPE_MOBILE,
} from 'app/utils/phonenumber/helpers'

let totalSearchRequestCount = 0

const Stages = {
  display: 'display',
  search: 'search',
  add: 'add',
}

const ModalBottomButtonContainer = ({
  primaryButtonAction,
  primaryButtonText,
  secondaryButtonText,
  secondaryButtonAction,
  secondaryButtonType,
}) => {
  return (
    <div className="modal-footer border-none width100 display-flex">
      <div className="col-sm-6 div-order-1 width100">
        <Display.GenericButton
          text={secondaryButtonText}
          onClick={secondaryButtonAction}
          buttonType={secondaryButtonType}
        />
      </div>
      <div className="col-sm-6 div-order-2 width100">
        <Display.GenericButton
          text={primaryButtonText}
          onClick={primaryButtonAction}
        />
      </div>
    </div>
  )
}

export const DisplayOwners = ({
  onStageChange,
  chosenPropertyText,
  onListItemClicked,
  onRemoveClicked,
  owners,
  showSpinner,
  removableOwnerID,
  showAddButton = true,
}) => {
  const hasAssignedOwners = owners && owners.length > 0
  const leadingText = hasAssignedOwners
    ? 'Owners for '
    : 'Please add owners for '

  return (
    <div>
      <p>
        {leadingText}
        <span className="fw500">{chosenPropertyText}</span>
      </p>
      {showAddButton && (
        <div className="display-flex mt10">
          <Display.GenericButton
            text="Add owner"
            componentClass="col-xs-4 pl0"
            onClick={() => {
              onStageChange(Stages.search)
            }}
          />
        </div>
      )}
      <div>
        <div className="mt20 searched-result-section ra-card__list">
          {showSpinner && <Spinner />}
          {!showSpinner &&
            hasAssignedOwners &&
            owners.length > 0 &&
            owners.map((owner, index) => {
              return (
                <div className="viewing-items pt0 pb0" key={index}>
                  <div className="content-wrapper pt5 pb5 cursor-pointer">
                    <ViewingContactItem
                      contact={owner}
                      enableRemove
                      onClick={onListItemClicked}
                      onRemoveClicked={onRemoveClicked}
                      disableRemoveButton={removableOwnerID === owner.guidID}
                      lockIcon={lockIcon}
                    />
                    <div className="controls comment-wrap w45p xs-w100p" />
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

const SearchSection = ({
  nameSearchText,
  mobileSearchText,
  onSearchTextChange,
  onAddClicked,
  contacts = [],
  onSearchResultsItemClicked,
  showSpinner = false,
  currentTeam,
  lockIcon,
  displaySearch,
  showFilteredContacts,
  searchText,
  filteredContactsForOwner,
}) => {
  return (
    <div>
      <ViewingContactSearchSection
        nameSearchText={nameSearchText}
        mobileSearchText={mobileSearchText}
        onSearchTextChange={onSearchTextChange}
        onAddClicked={() => onAddClicked()}
        lockIcon={lockIcon}
      />
      <div className="searched-result-section ra-card__list mt20">
        {showSpinner && <Spinner />}
        {displaySearch && (
          <span className="font-size-16 fw500 mb10">{`Search results`}</span>
        )}
        {!showSpinner &&
          contacts.length > 0 &&
          contacts.map((contact, index) => {
            return (
              <div className="viewing-items pt0 pb0" key={index}>
                <div className="content-wrapper pt5 pb5 cursor-pointer">
                  <ViewingContactItem
                    contact={contact}
                    onClick={onSearchResultsItemClicked}
                    currentTeam={currentTeam}
                    lockIcon={lockIcon}
                  />
                  <div className="controls comment-wrap w45p xs-w100p" />
                </div>
              </div>
            )
          })}
        {showFilteredContacts && filteredContactsForOwner.length > 0 && (
          <div className="mt10 mb10">
            <span className="font-size-16 fw500">{`Recently added contacts`}</span>
          </div>
        )}
        {!showSpinner &&
          !searchText &&
          filteredContactsForOwner.map((contact, index) => {
            return (
              <div className="viewing-items pt0 pb0" key={index}>
                <div className="content-wrapper pt5 pb5 cursor-pointer">
                  <ViewingContactItem
                    contact={contact}
                    onClick={onSearchResultsItemClicked}
                    currentTeam={currentTeam}
                    lockIcon={lockIcon}
                  />
                  <div className="controls comment-wrap w45p xs-w100p" />
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

const AddOwnerForm = ({ form, update, inputErrors, isLocked }) => {
  const { firstName, lastName, phone, landline, email } = form
  return (
    <div className="display-flex flex-direction-column">
      <div className="mb10">
        <div className="col-md-6 p0">
          <Form.InputName
            label="First Name"
            value={firstName}
            error={inputErrors.firstName}
            onChange={update('firstName')}
            id="First Name"
            inputClass="width100"
            componentClass="margin-profile-item-left"
            labelClass="top15"
            disabled={isLocked}
          />
        </div>
        <div className="col-md-6 p0">
          <Form.InputName
            label="Last Name"
            value={lastName}
            error={inputErrors.lastName}
            onChange={update('lastName')}
            id="firstName"
            inputClass="width100"
            componentClass="margin-profile-item-right"
            labelClass="top15"
            disabled={isLocked}
          />
        </div>
      </div>
      <div className="mb10">
        <div className="col-md-6 p0">
          <Form.InputPhoneNumber
            label="Contact Number"
            value={phone}
            error={inputErrors.phone}
            onChange={update('phone')}
            labelClass="top20"
            containerClassName="width100"
            componentClass="margin-profile-item-left"
            disabled={isLocked}
            required
          />
        </div>
        <div className="col-md-6 p0">
          <Form.InputPhoneNumber
            label="Office Number"
            value={landline}
            error={inputErrors.landline}
            placeholder="02 1234 1234"
            onChange={update('landline')}
            labelClass="top20"
            containerClassName="width100"
            componentClass="margin-profile-item-right"
            numberType={NUMBER_TYPE_LANDLINE}
            required
          />
        </div>
      </div>
      <div className="mb10">
        <div className="col-md-6 p0">
          <Form.InputEmail
            label="Email"
            value={email}
            error={inputErrors.email}
            onChange={update('email')}
            inputClass="width100"
            componentClass="margin-profile-item-left"
            labelClass="top15"
            disabled={isLocked}
          />
        </div>
      </div>
    </div>
  )
}
class AddOwnerModal extends React.Component {
  constructor(props) {
    super(props)
    const { addOwnerPreloadContent = {} } = props
    const { nameSearchText = '', mobileSearchText = '' } =
      addOwnerPreloadContent
    this.state = {
      mobileSearchText: mobileSearchText || '',
      nameSearchText: nameSearchText || '',
      stage: Stages.display,
      form: {
        firstName: '',
        lastName: '',
        phone: '',
        landline: '',
        email: '',
      },
      inputErrors: {},
      displaySearch: false,
      searchText: '',
    }
    this.onStageChange = this.onStageChange.bind(this)
    this.onAddClicked = this.onAddClicked.bind(this)
    this.onBackIconClicked = this.onBackIconClicked.bind(this)
    this.clearOwnerStateInAddForm = this.clearOwnerStateInAddForm.bind(this)
    this.onListItemClicked = this.onListItemClicked.bind(this)
    this.onSearchResultsItemClicked = this.onSearchResultsItemClicked.bind(this)
  }

  componentDidMount() {
    this.onFetchCurrentOwners()
  }

  onAddClicked = () => {
    const { mobileSearchText = '', nameSearchText = '' } = this.state
    this.onStageChange(Stages.add)
    this.clearOwnerStateInAddForm()
    this.setState({
      from: Stages.search,
      form: {
        firstName: nameSearchText,
        phone: mobileSearchText,
      },
    })
  }

  onBackIconClicked = () => {
    const { from = Stages.display } = this.state
    this.onStageChange(from)
    this.setState({ from: Stages.display })
  }

  onFetchCurrentOwners = () => {
    const { fetchCurrentOwners, teamId, chosenPropertyID } = this.props
    this.setState({
      showOwnerSpinner: true,
    })
    fetchCurrentOwners(teamId, chosenPropertyID)
      .then((result) => {
        this.setState({
          owners: result,
          showOwnerSpinner: false,
          filteredContactsForOwner: result,
        })
      })
      .catch((error) => {
        snugNotifier.error(error)
        this.setState({
          showOwnerSpinner: false,
        })
      })
  }

  onListItemClicked = (ppl) => {
    const {
      email,
      firstName,
      lastName,
      phone,
      isLocked,
      guidID: ownerId = '',
      landline = '',
    } = ppl
    this.setState({
      form: {
        ...this.state.form,
        email,
        firstName,
        lastName,
        phone,
        landline,
      },
      isFormLocked: isLocked,
      ownerId,
      from: ownerId ? Stages.display : Stages.search,
    })
    this.onStageChange(Stages.add)
  }

  onRemoveClicked = (event, owner) => {
    if (!owner.isLocked) {
      const { removeCurrentOwners, teamId, chosenPropertyID } = this.props
      const { guidID: ownerId } = owner
      this.setState({
        removableOwnerID: ownerId,
      })
      removeCurrentOwners(teamId, ownerId, chosenPropertyID)
        .then(() => {
          snugNotifier.success('The owner has been deleted')
          this.onFetchCurrentOwners()
          this.setState({ removableOwnerID: '' })
        })
        .catch((error) => {
          this.setState({ removableOwnerID: '' })
          snugNotifier.error(error)
        })
      event.preventDefault()
    }
  }

  onSaveOwnerClicked = () => {
    const { form, ownerId, from = Stages.display, inputErrors } = this.state
    const { firstName, lastName, phone, landline, email } = form
    const { chosenPropertyID, teamId, addCurrentOwners, updateCurrentOwners } =
      this.props
    const data = {
      email,
      firstName,
      lastName,
      phone,
      landline,
      ownerGUID: chosenPropertyID,
      ownerType: helpers.noteOwnerType.Property,
      relationship: helpers.contactRelationshipCollection.owner,
    }

    if (Object.values(inputErrors).find((error) => error !== '')) {
      snugNotifier.error('Please add required contact information')
      return
    }

    if (!firstName) {
      snugNotifier.error('First name required')
      return
    }

    if (!phone && !landline) {
      snugNotifier.error('Please enter either mobile or land line information')
      return
    }

    if (!email) {
      snugNotifier.error('Email is required')
      return
    }

    if (ownerId) {
      updateCurrentOwners(teamId, ownerId, data)
        .then(() => {
          this.onFetchCurrentOwners()
          this.onStageChange(Stages.display)
          this.clearStateField(from)
        })
        .then(snugNotifier.success('The owner has been updated'))
        .catch((error) => {
          snugNotifier.error(error)
        })
    } else {
      addCurrentOwners(data, teamId)
        .then(() => {
          this.onFetchCurrentOwners()
          this.onStageChange(Stages.display)
          this.clearStateField(from)
        })
        .then(snugNotifier.success('The owner has been added'))
        .catch((error) => {
          snugNotifier.error(error)
        })
    }
  }

  onSearchResultsItemClicked = (ppl) => {
    const {
      email,
      firstName,
      lastName,
      phone,
      guidID: ownerId = '',
      landline = '',
    } = ppl
    const { from = Stages.display, inputErrors } = this.state
    this.setState({
      form: {
        ...this.state.form,
        email,
        firstName,
        lastName,
        phone,
        landline,
      },
      ownerId,
      from: ownerId ? Stages.display : Stages.search,
    })
    const { chosenPropertyID, teamId, addCurrentOwners } = this.props

    if (Object.values(inputErrors).find((error) => error !== '')) {
      snugNotifier.error('Please add required contact information')
      return
    }

    if (!firstName) {
      snugNotifier.error('First name required')
      return
    }

    if (!phone && !landline) {
      snugNotifier.error('Please enter either mobile or land line information')
      return
    }

    if (!email) {
      snugNotifier.error('Email is required')
      return
    }
    const data = {
      email,
      firstName,
      lastName,
      phone,
      landline,
      ownerGUID: chosenPropertyID,
      ownerType: helpers.noteOwnerType.Property,
      relationship: helpers.contactRelationshipCollection.owner,
    }
    addCurrentOwners(data, teamId)
      .then(() => {
        this.onFetchCurrentOwners()
        this.onStageChange(Stages.display)
        this.clearStateField(from)
      })
      .then(snugNotifier.success('The owner has been added'))
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  onSearchTextChange = (field) => {
    return (event) => {
      const { fetchAgencyApplicantsElasticSearchResult, teamId } = this.props

      clearInterval(this.searchContacts)

      this.setState(
        {
          [field]: event.target.value,
        },
        () => {
          const { mobileSearchText = '', nameSearchText = '' } = this.state
          const formattedMobileText =
            mobileSearchText.indexOf('04') === 0
              ? mobileSearchText.replace(/^.{2}/g, '+614')
              : mobileSearchText
          const finalSearchText =
            `${formattedMobileText} ${nameSearchText}`.trim()
          let currentSearchRequestCount = ++totalSearchRequestCount
          this.setState({
            showContactSpinner: true,
          })
          this.searchContacts = setTimeout(
            () =>
              fetchAgencyApplicantsElasticSearchResult(teamId, finalSearchText)
                .then((result) => {
                  if (currentSearchRequestCount === totalSearchRequestCount) {
                    this.setState({
                      contacts: result.contacts.sort(
                        helpers.sortArrayAlphabeticallyByFirstName,
                      ),
                      total: result.total,
                      showContactSpinner: false,
                      displaySearch: result.total !== 0,
                    })
                  }
                })
                .catch((error) => {
                  this.setState({
                    apiError: error,
                    showContactSpinner: false,
                    displaySearch: false,
                  })
                }),
            STANDARD_TIMEOUT,
          )
        },
      )
    }
  }

  onStageChange = (stage) => {
    this.setState({ stage })
  }

  clearOwnerStateInAddForm = () => {
    this.setState({
      form: {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        landline: '',
      },
      ownerId: '',
    })
  }

  update = (field) => {
    return (data) => {
      let updatedError = data.error
      this.setState({
        form: {
          ...this.state.form,
          [field]: data.value,
        },
        inputErrors: {
          ...this.state.inputErrors,
          [field]: updatedError,
        },
      })
    }
  }

  render() {
    let modalTitleText = 'Owners'
    const {
      stage = Stages.display,
      mobileSearchText,
      nameSearchText,
      form = {},
      inputErrors = {},
      owners = [],
      contacts = [],
      showContactSpinner = false,
      showOwnerSpinner = false,
      displaySearch,
      searchText = '',
      filteredContactsForOwner = [],
      isFormLocked,
    } = this.state
    const { toggleModal, currentTeam, address } = this.props
    const chosenPropertyText = address.friendlyName + ', ' + address.suburb
    const showFilteredContacts = !showContactSpinner && filteredContactsForOwner

    const bottomButtons = [
      {
        displayCondition: stage === Stages.add,
        primaryButtonText: 'Save',
        primaryButtonAction: () => this.onSaveOwnerClicked(),
        secondaryButtonText: 'Cancel',
        secondaryButtonAction: () => this.onStageChange(Stages.display),
        secondaryButtonType: 'secondary',
      },
    ]

    const modalHeader = (
      <div className="display-flex align-items-center width100">
        {stage !== Stages.display && (
          <div
            className="cursor-pointer pr15"
            onClick={() => this.onBackIconClicked()}
          >
            <i className="icon-arrow-left" />
          </div>
        )}
        <h4 className="display-flex align-items-center justify-content-center fw500 flex-1">
          <span>{modalTitleText}</span>
        </h4>
      </div>
    )
    const modalCloser = (
      <i
        className="fa fa-times font-size-24"
        onClick={() => toggleModal(helpers.modalNameCollection.addOwnerModal)}
      />
    )
    const modalBody = (
      <div className="p15 pb80">
        {stage === Stages.display && (
          <DisplayOwners
            chosenPropertyText={chosenPropertyText}
            onStageChange={this.onStageChange}
            owners={owners}
            onRemoveClicked={this.onRemoveClicked}
            onListItemClicked={this.onListItemClicked}
            currentTeam={currentTeam}
            showSpinner={showOwnerSpinner}
          />
        )}
        {stage === Stages.search && (
          <SearchSection
            nameSearchText={nameSearchText}
            mobileSearchText={mobileSearchText}
            onSearchTextChange={this.onSearchTextChange}
            onAddClicked={this.onAddClicked}
            onSearchResultsItemClicked={this.onSearchResultsItemClicked}
            contacts={contacts}
            showSpinner={showContactSpinner}
            currentTeam={currentTeam}
            displaySearch={displaySearch}
            showFilteredContacts={showFilteredContacts}
            searchText={searchText}
            filteredContactsForOwner={filteredContactsForOwner}
          />
        )}
        {stage === Stages.add && (
          <AddOwnerForm
            inputErrors={inputErrors}
            form={form}
            update={this.update}
            isLocked={isFormLocked}
          />
        )}
      </div>
    )
    const modalBottom = bottomButtons.map((bottomButton) => {
      const {
        displayCondition,
        primaryButtonText,
        primaryButtonAction,
        secondaryButtonText,
        secondaryButtonAction,
        secondaryButtonType,
      } = bottomButton
      if (displayCondition) {
        return (
          <ModalBottomButtonContainer
            primaryButtonText={primaryButtonText}
            primaryButtonAction={primaryButtonAction}
            secondaryButtonText={secondaryButtonText}
            secondaryButtonAction={secondaryButtonAction}
            secondaryButtonType={secondaryButtonType}
          />
        )
      }
      return <div />
    })

    return (
      <div className="display-flex flex-direction-column">
        <Display.ModalWithScroll
          modalCloser={modalCloser}
          modalHeader={modalHeader}
          modalBody={modalBody}
          modalBottom={modalBottom}
          takeMinHeight
        />
      </div>
    )
  }
}

export default AddOwnerModal
