import React from 'react'

import { Link } from 'react-router-dom'

import RegisterEmail from 'app/session/register_components/register_email_connection'
import RegisterFacebook from 'app/session/register_components/register_facebook'

function LeaseeJoin() {
  return (
    <div>
      <div className="status-progress-box">
        <div className="tabs">
          <div className="completed">Start</div>
          <div className="current">Join</div>
          <div>Sign</div>
        </div>
        <div className="bar">
          <div className="status-progress-bar active-tab-3-2" />
        </div>
      </div>

      <h3 className="mb25">Join Snug</h3>

      <p>Join to approve BondCover Application</p>

      <RegisterFacebook form="Sign up" />

      <h4 className="mb25 mt40">Sign up with your email</h4>

      <RegisterEmail />
    </div>
  )
}

export default LeaseeJoin
