import React from 'react'

import $ from 'jquery'
import moment from 'moment'
import qs from 'qs'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import * as Form from 'app/components/forms/forms'
import {
  defaultPropertyFilters,
  propertyFilters,
} from 'app/dashboard/team_overview_reducer'
import * as helpers from 'app/sm/helpers'
import {
  ApplicationWorkflowFilter,
  DropDownForShowDeclinedAndWithdrawnApplications,
  DropDownForViewingManager,
  PropertyStatusFilter,
} from 'app/sm/properties/components/property_search_filter_util'
import { isNewWorkflowEnabled } from 'config/features'

const selectDateOptions = ['All', 'Today only', 'Select a date']
const selectDateOptionToIndex = {
  all: '0',
  todayOnly: '1',
  selectADate: '2',
}

const FirstLayerContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  padding-bottom: 3rem;
  width: 100%;
  @media (max-width: 576px) {
    & > div {
      flex: 0 1 100%;
    }
    div:last-of-type {
      flex: 1 1 50%;
    }
  }
`

export const SearchInputBox = ({
  searchText,
  onSearchChange,
  placeholderText = 'property address',
}) => (
  <div className="property-search-filter-widget pl0">
    <div className="input-box ">
      <div className="prefix">
        <i className="icon-search" />
      </div>
      <input
        type="text"
        placeholder={placeholderText}
        value={searchText}
        onChange={onSearchChange}
      />
    </div>
  </div>
)

export const PropertySearchContainer = ({ children, componentClass = '' }) => (
  <div className={`properties-search-filter mb0 pb0 ${componentClass}`}>
    {children}
  </div>
)

class AgencySearchSectionContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      managerList: [],
    }
  }

  componentDidMount() {
    const { currentTeam } = this.props
    this.fetchAndSetManagerList(currentTeam)
  }

  componentDidUpdate = (prevProps) => {
    const { currentTeam } = this.props
    if (prevProps.currentTeam !== currentTeam) {
      this.fetchAndSetManagerList(currentTeam)
    }
  }

  onApplicationWorkflowChange = (event) => {
    const { value } = event.target
    const workflow = parseInt(value, 10)
    const { filters, setPropertyFilters } = this.props
    const newFilters = Object.assign({}, filters)

    newFilters.workflow = workflow
    setPropertyFilters(newFilters)
  }

  onDateDropdownUpdated = (data) => {
    const { value } = data
    const { filters, setPropertyFilters } = this.props
    let newFilters = {}
    switch (value) {
      case selectDateOptionToIndex.all:
        newFilters = Object.assign({}, filters, {
          todayOnly: false,
          dateOption: value,
          datePickerValue: '',
        })
        setPropertyFilters(newFilters)
        break
      case selectDateOptionToIndex.todayOnly:
        newFilters = Object.assign({}, filters, {
          todayOnly: true,
          dateOption: value,
          datePickerValue: '',
        })
        setPropertyFilters(newFilters)
        break
      case selectDateOptionToIndex.selectADate:
        $('#date-picker-input').focus()
        break
      default:
        return
    }
  }

  onDatePickerChange = (data) => {
    const { value } = data
    const datePickerValue = moment(value).format(
      helpers.standardDateWithoutTimeAndComma,
    )
    const { filters, setPropertyFilters } = this.props
    const newFilters = Object.assign({}, filters, {
      todayOnly: false,
      datePickerValue,
    })
    setPropertyFilters(newFilters)
  }

  onPropertyManagerFilterChange = (event) => {
    const { value } = event.target
    const { filters, setPropertyFilters, persistManager } = this.props
    const newFilters = Object.assign({}, filters)
    const specificManagerNotSelected = !(
      value === '' ||
      value === propertyFilters.MANAGER_DEFAULT ||
      value === 'unAssigned'
    )

    newFilters.manager = value
    newFilters.showAllTab = specificManagerNotSelected

    persistManager(value) // When navigating between different team pages
    setPropertyFilters(newFilters)
  }

  onPropertyStatusFilterChange = (event) => {
    const { value } = event.target
    const { currentTeam } = this.props
    const { manager = '', searchText = '' } = this.state
    this.setState({ propertyStatus: value }, () => {
      const { propertyStatus } = this.state
      const status = helpers.propertyStatusCode[propertyStatus]
      this.callFetchTeamProperties(currentTeam.guid, {
        searchText,
        manager,
        status,
      })
        .then(({ properties }) => {
          this.setState({
            propertyData: properties,
            propertyCount: properties.response_metadata.total,
          })
        })
        .catch((error) => {
          this.setState({ error })
        })
    })
  }

  onResetFilters = () => {
    this.props.resetPropertyFilters()
  }

  onSearchChange = (event) => {
    const { value } = event.target
    const { filters, setPropertyFilters } = this.props
    const newFilters = Object.assign({}, filters)
    newFilters.searchText = value
    setPropertyFilters(newFilters)
  }

  onTodayFilterChange = () => {
    const { filters, setPropertyFilters } = this.props
    const newFilters = Object.assign({}, filters, {
      todayOnly: !filters.todayOnly,
    })
    setPropertyFilters(newFilters)
  }

  getCurrentTeam = () => {
    const { match: { params: { teamSlug = '' } = {} } = {} } = this.props
    return this.props.teams.find((option) => option.slug === teamSlug)
  }

  callFetchTeamProperties = (
    teamID,
    { searchText, manager, status, workflow, offset, loadMore = false },
  ) => {
    const { fetchTeamProperties } = this.props
    loadMore
      ? this.setState({ loadMoreSpinner: true })
      : this.setState({ tableSpinner: true })
    return fetchTeamProperties(teamID, {
      searchText,
      manager,
      status,
      workflow,
      offset,
      loadMore,
    })
      .then(({ properties }) => {
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
        return Promise.resolve({ properties })
      })
      .catch((error) => {
        this.setState({ error })
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
        return Promise.reject(error)
      })
  }

  fetchAndSetManagerList = (currentTeam) => {
    const currentTeamGUID = currentTeam ? currentTeam.guid : ''
    if (currentTeamGUID) {
      this.props
        .fetchTeamManagerList(currentTeamGUID)
        .then((managerList) => {
          this.setState({ managerList })
        })
        .catch((error) => {
          this.setState({ error })
        })
    }
  }

  showDeclinedAndWithdrawnApplicationsFilter = () => {
    const { filters, setPropertyFilters } = this.props
    const newFilters = Object.assign({}, filters, {
      showDeclinedAndWithdrawnApplications:
        !filters.showDeclinedAndWithdrawnApplications,
    })
    setPropertyFilters(newFilters)
  }

  updateMeFilter = () => {
    const { managerList } = this.state
    const { filters, setPropertyFilters, persistManager } = this.props
    const newFilters = Object.assign({}, filters)
    const loggedInManager = managerList && managerList.find((m) => m.isMe)

    if (filters.showAllTab === true) {
      newFilters.showAllTab = false
      newFilters.manager = propertyFilters.MANAGER_DEFAULT
    } else {
      newFilters.showAllTab = true
      newFilters.manager = loggedInManager
        ? loggedInManager.contact.guidID
        : propertyFilters.MANAGER_DEFAULT
    }

    persistManager(newFilters.manager) // When navigating between different team pages
    setPropertyFilters(newFilters)
  }

  render() {
    const {
      filters = defaultPropertyFilters,
      disableSearchBox = false,
      disableSearchManager = false,
      disableSearchStatus = false,
      disableSearchTodayOnly = false,
      disableSortby = false,
      disableReset = false,
      disableDatePicker = false,
      enableViewingsSortBy = false,
      enableViewingSearchManager,
      enableUnAssigned = false,
      placeholderText = 'property address',
    } = this.props
    const { propertyStatus, managerList } = this.state
    const teamSlug =
      (this.props.currentTeam && this.props.currentTeam.slug) || ''
    const curQueries = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const linkForApplications =
      curQueries.stage === 'Application' ? (
        <Link to={helpers.urlTo('teamApplicationsByStage', { teamSlug })}>
          Status
        </Link>
      ) : (
        <Link to={helpers.urlTo('teamsApplication', { teamSlug })}>
          Properties
        </Link>
      )
    const linkForViewings =
      curQueries.stage === 'Viewing' ? (
        <Link to={helpers.urlTo('teamViewingsByDate', { teamSlug })}>Date</Link>
      ) : (
        <Link to={helpers.urlTo('teamsViewing', { teamSlug })}>Properties</Link>
      )
    return (
      <PropertySearchContainer componentClass="flex-direction-column">
        <FirstLayerContainer>
          {!disableSearchBox && (
            <SearchInputBox
              searchText={filters.searchText}
              onSearchChange={this.onSearchChange}
              placeholderText={placeholderText}
            />
          )}
          {(!disableSearchManager || enableViewingSearchManager) && (
            <DropDownForViewingManager
              label="Manager:"
              options={managerList}
              value={filters.manager}
              onChange={this.onPropertyManagerFilterChange}
              changeToMeFilter={this.updateMeFilter}
              showAllTab={filters.showAllTab}
              enableUnAssigned={enableUnAssigned}
            />
          )}
          {!disableSearchStatus && !isNewWorkflowEnabled(teamSlug) && (
            <PropertyStatusFilter
              value={propertyStatus}
              onChange={this.onPropertyStatusFilterChange}
            />
          )}
          {!disableSearchStatus && isNewWorkflowEnabled(teamSlug) && (
            <ApplicationWorkflowFilter
              label="Status: "
              value={filters.workflow}
              onChange={this.onApplicationWorkflowChange}
            />
          )}
          {!disableSearchTodayOnly && (
            <div className="pl15 smlpl0">
              <label className="ibm filter-today-checkbox">
                <input
                  type="checkbox"
                  className="input-box wa pt0"
                  onChange={this.onTodayFilterChange}
                  checked={filters.todayOnly}
                />
                <span>Today only</span>
              </label>
            </div>
          )}
          {!disableDatePicker && (
            <div className="display-flex align-items-center">
              <div className="mr5 ml5 ml0-mobile">Date:</div>
              <div>
                <Form.Dropdown
                  inputBoxClass="pt0 mt3"
                  value={filters.datePickerValue ? '' : filters.dateOption}
                  options={selectDateOptions}
                  onChange={this.onDateDropdownUpdated}
                  label={filters.datePickerValue}
                  resetStyle
                />
                <Form.InputDate
                  componentClass="mt-10000 position-absolute date-picker-fullscreen"
                  onChange={this.onDatePickerChange}
                  viewMode="days"
                  closeOnSelect
                  id="date-picker-input"
                  readOnly={false}
                />
              </div>
            </div>
          )}
          {curQueries.stage === 'applicationbystage' && (
            <DropDownForShowDeclinedAndWithdrawnApplications
              label="View: "
              showDeclinedAndWithdrawnApplicationsFilter={
                this.showDeclinedAndWithdrawnApplicationsFilter
              }
              showDeclinedAndWithdrawnApplicationsTab={
                filters.showDeclinedAndWithdrawnApplications
              }
            />
          )}
          {!disableSortby && (
            <div className="ml5 mr5 ml0-mobile">
              Sort by: {linkForApplications}
            </div>
          )}
          {enableViewingsSortBy && (
            <div className="ml5 mr5 ml0-mobile">Sort by: {linkForViewings}</div>
          )}
          {!disableReset && (
            <span
              className="textButton linkStyle-blue wa ha btnmr pl10 mt10 mb10 mr10"
              onClick={this.onResetFilters}
            >
              Clear
            </span>
          )}
        </FirstLayerContainer>
      </PropertySearchContainer>
    )
  }
}

export default AgencySearchSectionContainer
