import { useEffect, useState } from 'react'

import { Formik } from 'formik'
import { connect } from 'react-redux'

import {
  formFields,
  formValidation,
} from 'app/features/teams/key-logger/CheckInKeySetModal/KeySetSelection/form.utils'
import { FormBody } from 'app/features/teams/key-logger/CheckInKeySetModal/KeySetSelection/FormBody'

const checkInInitial = {
  [formFields.keySet]: null,
  [formFields.property]: null,
}

const generateInitialValues = (checkInData) => {
  const { keySet, property } = checkInData
  return {
    [formFields.keySet]: {
      option: keySet,
      optionMeta: { id: keySet.guid, displayText: keySet.label },
    },
    [formFields.property]: {
      option: property,
      optionMeta: {
        id: property.guidID,
        displayText: property.address.friendlyName,
        isInitialValue: true,
      },
    },
  }
}

const PureKeySetSelection = ({ teamId, checkInData, proceed }) => {
  const [initialValues, setInitialValues] = useState(checkInInitial)

  useEffect(() => {
    if (!checkInData) return
    const generatedInitialValues = generateInitialValues(checkInData)
    setInitialValues(generatedInitialValues)
  }, [checkInData])

  const onSubmit = (values) => {
    return Promise.resolve({
      keySet: values[formFields.keySet].option,
      property: values[formFields.property].option,
      notes: values[formFields.notes],
    }).then(proceed)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
      validate={formValidation}
      validateOnBlur
      validateOnChange
    >
      {(props) => <FormBody formBag={props} teamId={teamId} />}
    </Formik>
  )
}

const mapStateToProps = ({ session }) => ({ teamId: session.currentTeam.guid })
export const KeySetSelection = connect(mapStateToProps)(PureKeySetSelection)
