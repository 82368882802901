import { connect } from 'react-redux'

import {
  fetchOffer,
  fetchPropertyEntryDetails,
  updateOfferWithPropertyViewingSchedule,
  updateOfferWithViewingScheduleZone,
} from 'app/sm/onlist_details/action'
import OnListDetailsContainer from 'app/sm/onlist_details/container'
import { fetchTeamSettingInformation } from 'app/sm/rental_reputation/actions'
import {
  fetchViewingPreference,
  updateViewingPreference,
} from 'app/sm/team_viewing_preference/action'

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
  teams: session.teams,
})

const mapDispatchToProps = (dispatch) => ({
  fetchPropertyEntryDetails: (agencyGUID, propertyGUID) =>
    dispatch(fetchPropertyEntryDetails(agencyGUID, propertyGUID)),
  fetchOffer: (offerGUID) => dispatch(fetchOffer(offerGUID)),
  updateOfferWithPropertyViewingSchedule: (
    agencyGUID,
    offerGUID,
    propertyViewingSchedule,
  ) =>
    dispatch(
      updateOfferWithPropertyViewingSchedule(
        agencyGUID,
        offerGUID,
        propertyViewingSchedule,
      ),
    ),
  fetchTeamSettingInformation: (teamId, queryString) =>
    dispatch(fetchTeamSettingInformation(teamId, queryString)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OnListDetailsContainer)
