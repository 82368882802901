import React from 'react'

import moment from 'moment'

const CurrentLease = ({ lease }) => {
  let leaseHtml = (
    <div className="contentBlock">
      <header>
        <span>No Current Lease Found</span>
      </header>
    </div>
  )
  if (lease && lease.guidID) {
    let {
      guidID,
      rate,
      bond,
      startDate,
      endDate,
      bondReferenceNumber,
      bedrooms,
      isNewLease,
    } = lease
    let { friendlyName, suburb, state, postcode } = lease.address
    let startDateStr = moment(startDate).format('Do MMM YYYY')
    let endDateStr = endDate
      ? moment(endDate).format('Do MMM YYYY')
      : 'Rolling Lease'
    leaseHtml = (
      <div className="contentBlock">
        <header>
          <span>Current Lease</span>
        </header>
        <content>
          <p>
            Application Type: {isNewLease ? 'New Lease' : 'Refund'}
            <br />
            Bedrooms: {bedrooms}
            <br />
            Address: {friendlyName}, {suburb} {state} {postcode}
            <br />
            Rate: ${rate} per week
            <br />
            Bond Amount: ${bond} bond
            <br />
            Bond Ref. No: {bondReferenceNumber}
            <br />
            Dates: {startDateStr} - {endDateStr}
            <br />
          </p>
        </content>
      </div>
    )
  }
  return <div>{leaseHtml}</div>
}

export default CurrentLease
