import * as React from 'react'

function SvgAccountBalanceRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.17 6.021l7.9-4.16c.58-.31 1.28-.31 1.86 0l7.9 4.16c.41.21.67.64.67 1.1 0 .69-.56 1.25-1.24 1.25H3.75c-.69 0-1.25-.56-1.25-1.25 0-.46.26-.89.67-1.1zm1.33 5.85v4c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5zm6 0v4c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5zm11 9c0 .83-.67 1.5-1.5 1.5H4c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h16c.83 0 1.5.67 1.5 1.5zm-5-9v4c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgAccountBalanceRounded
