import * as Sentry from '@sentry/react'
import localforage from 'localforage'
import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'

import middleware from 'app/middleware'
import Middleware404 from 'app/middleware_404'
import reducer from 'app/reducer'
import rootSaga from 'app/sagas'
import advertisementMiddleware from 'app/sm/advertisements/advertisement_middleware'
import applicationsMiddleware from 'app/sm/apply/apply_middleware'
import askQuestionMiddleware from 'app/sm/ask_question/ask_question_middleware'
import backgroundCheckMiddleware from 'app/sm/background_check/background_check_middleware'
import EmergencyContactMiddleware from 'app/sm/emergency_contact/emergency_contact_middleware'
import InspectionsMiddleware from 'app/sm/inspections/inspections_middleware'
import OaTenantMiddleware from 'app/sm/oa_tenant/oa_tenant_middleware'
import PersonalReferenceMiddleware from 'app/sm/personal_reference/personal_reference_middleware'
import phDepositMiddleware from 'app/sm/ph_deposit/ph_deposit_middleware'
import propertyMiddleware from 'app/sm/ppp/property_middleware'
import ProfileMiddleware from 'app/sm/profile/profile_middleware'
import propertyDetailsMiddleware from 'app/sm/property_details/property_details_middleware'
import RentalReputationMiddleware from 'app/sm/rental_reputation/middleware'
import renterApplicationsMiddleware from 'app/sm/renter_applications/renter_applications_middleware'
import reviewMiddleware from 'app/sm/review/review_middleware'
import withdrawConfirmationMiddleware from 'app/sm/withdraw_confirmation/withdraw_confirmation_middleware'
import { LocalStorageUtils } from 'app/storage_utils'

const sagaMiddleware = createSagaMiddleware({
  sagaMonitor: {
    // actionDispatched: console.log.bind({}, 'actionDispatched'),
    // effectCancelled: console.log.bind({}, 'effectCancelled'),
    // effectRejected: console.log.bind({}, 'effectRejected'),
    // effectResolved: console.log.bind({}, 'effectResolved'),
    // effectTriggered: console.log.bind({}, 'effectTriggered'),
    // rootSagaStarted: console.log,
  },
})

const sentryEnhancer = Sentry.createReduxEnhancer({
  stateTransformer(state) {
    const { session } = state
    return {
      session,
    }
  },
  actionTransformer(action) {
    // don't track any action to not get a huge request body
    return null
  },
  configureScopeWithState(scope, state) {
    const { currentUser } = state.session
    // clear user if there is no currentUser
    if (!currentUser.guidID) {
      return scope.setUser(null)
    }
    scope.setUser({
      ...currentUser,
      id: currentUser.guidID,
      email: currentUser.email,
      mobile: currentUser.mobilePhone,
    })
  },
})

const enchancers = compose(
  applyMiddleware(thunk),
  applyMiddleware(sagaMiddleware),
  sentryEnhancer,
  applyMiddleware(Middleware404),
  middleware.bondCoverMiddleware,
  middleware.dashboardMiddleware,
  middleware.routeMiddleware,
  applyMiddleware(propertyDetailsMiddleware),
  applyMiddleware(reviewMiddleware),
  applyMiddleware(askQuestionMiddleware),
  applyMiddleware(phDepositMiddleware),
  applyMiddleware(advertisementMiddleware),
  applyMiddleware(propertyMiddleware),
  applyMiddleware(withdrawConfirmationMiddleware),
  applyMiddleware(renterApplicationsMiddleware),
  applyMiddleware(applicationsMiddleware),
  applyMiddleware(backgroundCheckMiddleware),
  applyMiddleware(ProfileMiddleware),
  applyMiddleware(RentalReputationMiddleware),
  applyMiddleware(OaTenantMiddleware),
  applyMiddleware(InspectionsMiddleware),
  applyMiddleware(PersonalReferenceMiddleware),
  applyMiddleware(EmergencyContactMiddleware),

  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__({
        // actionCreators,
        serialize: true,
        trace: true,
        traceLimit: 50,
      })
    : (f) => f,
)

// TODO: this will be removed or significantly changed in the future when we
// manage state using route
export const loadState = () => {
  try {
    const serializedState = LocalStorageUtils.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    LocalStorageUtils.setItem('state', serializedState)
  } catch (err) {
    console.log('Can not serialize state')
  }
}

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage: localforage,
    whitelist: ['DataPropertiesAndViewings', 'pendingTasks', 'localEntities'],
  },
  reducer,
)

const Store = createStore(persistedReducer, enchancers)
export const persistor = persistStore(Store)

sagaMiddleware.run(rootSaga)

window.store = Store

export default Store
