import { accountsBaseUrl, apiBaseUrl } from 'config/env'
import $ from 'jquery'

import { LogFetchError } from 'app/shared_components/helpers'
import { api } from 'app/utils/api/helpers'

export const register = (user, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${accountsBaseUrl}register/email`,
    data: JSON.stringify(user),
    success,
    error,
  })
}

export const confirm = (user, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${accountsBaseUrl}register/social`,
    data: JSON.stringify(user),
    success,
    error,
  })
}

export const verify = (mobile, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${accountsBaseUrl}verify/mobile`,
    data: JSON.stringify(mobile),
    success,
    error,
  })
}

export const logout = (success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${accountsBaseUrl}logout`,
    success,
    error,
  })
}

export const getUser = (success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${accountsBaseUrl}user?cachebuster=${Date.now().toString()}`,
    success,
    error,
  })
}

export const getAgencyUser = (agencyID) => {
  return api.get(`agencies/${agencyID}/userprofile`)
}

export const resendOTP = (data, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    contentType: 'application/json',
    url: `${accountsBaseUrl}send/sms?cachebuster=${Date.now().toString()}`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const forgotPassword = (data, success, error) => {
  $.ajax({
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${accountsBaseUrl}forgot/email`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const newPassword = (data, success, error) => {
  $.ajax({
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${accountsBaseUrl}reset/email`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const changePassword = (data, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    contentType: 'application/json',
    url: `${accountsBaseUrl}change`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const getUserProfile = (success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}sm/users/profile`,
    success,
    error,
  })
}

export const fetchUserProfile = () => {
  const url = `${apiBaseUrl}sm/users/profile`

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
  })
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (profile) => ({ ok: true, profile }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getTeams = () => {
  const url = `${apiBaseUrl}userprofile/teams`

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
  })
    .catch(LogFetchError)
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (teams) => ({ ok: true, teams }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const updateUserProfilePicture = (formData) => {
  return sendUserProfilePicture(formData, 'PUT')
}

export const addUserProfilePicture = (formData) => {
  return sendUserProfilePicture(formData, 'POST')
}

const sendUserProfilePicture = (formData, method) => {
  const url = `${accountsBaseUrl}profile/avatar`

  return fetch(url, {
    credentials: 'include',
    method: method,
    body: formData,
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      ({ user }) => ({ ok: true, user }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deleteUserProfilePicture = () => {
  const url = `${accountsBaseUrl}profile/avatar`

  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
