import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

import MultiTenantErrors from 'app/shared_components/multi_tenant_errors'

const Signature = ({ signature, request, currentLease, role, tenants }) => {
  let stepNumber = 3
  if (currentLease && currentLease.isMultiTenantLease && role !== 'secondary') {
    stepNumber = 4
  }
  if (currentLease && currentLease.isMultiTenantLease && role === 'secondary') {
    stepNumber = 3
  }

  let requestID = ''
  if (request) {
    requestID = request.guidID
  }

  let signatureHtml = null
  if (signature && signature.requestID) {
    let { requestID, dateSigned, signature_svg } = signature
    dateSigned = moment(dateSigned).format('Do MMM YYYY, h:mm a')
    signatureHtml = (
      <div>
        <div>
          <img className="signature-card-img" src={signature_svg} />
        </div>
        <div>{dateSigned}</div>
      </div>
    )
  }

  let multiTenantErrors = false
  let isNewLease = currentLease ? currentLease.isNewLease : false

  if (currentLease && currentLease.isMultiTenantLease && tenants) {
    multiTenantErrors = MultiTenantErrors(currentLease, tenants, isNewLease)
  }

  return (
    <div>
      {(request &&
        request.managerAdded &&
        !multiTenantErrors &&
        request.submissionStatus === 0) ||
      role === 'secondary' ? (
        <Link
          className={
            signatureHtml ? 'bc-hub-item-link completed' : 'bc-hub-item-link'
          }
          to={
            role === 'primary'
              ? `/bc/renter/signature?requestid=${requestID}`
              : `/bc/secondary/leaseeSignature?requestid=${requestID}`
          }
        >
          <header>
            <i className="icon-signature" />
            <span>{stepNumber}. Your Signature</span>
          </header>
          <content>{signatureHtml}</content>
        </Link>
      ) : (
        <div
          className={
            request && request.managerAdded && !multiTenantErrors
              ? 'bc-hub-item-link completed'
              : 'bc-hub-item-link disabled'
          }
        >
          <header>
            <i className="icon-signature" />
            <span>{stepNumber}. Your Signature</span>
          </header>
          <content>{signatureHtml}</content>
        </div>
      )}
    </div>
  )
}

export default Signature
