import { NetworkConnectionError } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { postPropertyDisclosuresForOffer } from 'app/pages/teams/onlist/access'

export function addPropertyDisclosuresForOffer(agencyGUID, offerGUID, data) {
  return (dispatch) => {
    return postPropertyDisclosuresForOffer(agencyGUID, offerGUID, data).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) =>
            dispatch(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}
