import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import { TelField } from 'app/components/design-system-components/inputs'
import { FieldWrapper } from 'app/forms/FieldWrapper'
import theme from 'app/match/applicationReportPDF/assets/theme'

export const StyledInputWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.space[7]}px;
  @media screen and (max-width: 576px) {
    display: block;
    margin-top: ${theme.space[0]}px;
  }
`

export const StyledFieldWrapper = styled(FieldWrapper)`
  width: 48%;
  @media screen and (max-width: 576px) {
    width: 100%;
    margin-top: ${theme.space[3]}px;
  }
  label {
    border-color: ${theme.colors.gray500};
  }
  input {
    height: ${theme.dividedSpace[6]}px;
  }
  input[disabled] {
    color: ${theme.colors.gray500};
    background-color: ${theme.colors.white};
    font-weight: ${theme.fontWeights[6]};
  }
  button {
    border-color: ${theme.colors.gray500};
  }
`

export const StyledSubText = styled.p`
  font-size: ${theme.fontSizes.pSmall12};
  color: ${theme.colors.gray600};
`

export const CustomMobileInput = (props) => (
  <TelField onlyCountries={[]} {...props} />
)
