import { connect } from 'react-redux'

import { fetchTeamProperties } from 'app/bond_cover/agency/agency_actions'
import {
  resetPropertyFilters,
  setPropertyFilters,
} from 'app/dashboard/team_overview_actions'
import TeamOverviewContainer from 'app/dashboard/team_overview_container'
import { setBackUrl } from 'app/shared_components/actions'
import {
  archiveProperty,
  persistManager,
  unarchiveProperty,
} from 'app/sm/properties/properties_actions'

const mapStateToProps = ({ session, MyProperties, TeamOverview }) => ({
  teams: session.teams,
  currentTeam: session.currentTeam,
  persistedManager: MyProperties.persistedManager,
  filters: TeamOverview.filters,
})

const mapDispatchToProps = (dispatch) => ({
  setBackUrl: (backUrl) => dispatch(setBackUrl(backUrl)),
  fetchTeamProperties: (teamGUID, filters) =>
    dispatch(fetchTeamProperties(teamGUID, filters)),
  archiveProperty: (
    propertyID,
    cancelFutureViewings,
    declineUnsuccessfulApplications,
  ) =>
    dispatch(
      archiveProperty(
        propertyID,
        cancelFutureViewings,
        declineUnsuccessfulApplications,
      ),
    ),
  persistManager: (manager) => dispatch(persistManager(manager)),
  unarchiveProperty: (propertyID) => dispatch(unarchiveProperty(propertyID)),
  setPropertyFilters: (filters) => dispatch(setPropertyFilters(filters)),
  resetPropertyFilters: () => dispatch(resetPropertyFilters()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamOverviewContainer)
