import * as attendeesData from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingDetailsAttendeesTab/attendees-data'
import { orderRegistrants } from 'app/pages/Viewings/MobileViewings/ViewingDetails/components/ViewingDetailsAttendeesTab/order-attendees'
import { reduceArrayToObjectWithIdKeys } from 'app/utils/arrays'
import { createInitialLoadingState } from 'app/utils/loading-states'

const appendActorToAction = (actionStr) => `ViewingAttendeesTab/${actionStr}`

export const actionTypes = {
  start: appendActorToAction('START'),
  setViewingId: appendActorToAction('SET_VIEWING_ID'),
  syncedRegistrantsLoaded: appendActorToAction('SYNCED_REGISTRANTS_LOADED'),
  addRegistrant: appendActorToAction('ADD_REGISTRANT'),
  updateAddRegistrantLoadingStates: appendActorToAction(
    'UPDATE_ADD_REGISTRANT_LOADING_STATES',
  ),
  registrantCreated: appendActorToAction('REGISTRANT_CREATED'),
  sendApplyInvitations: appendActorToAction('SEND_APPLY_INVITATIONS'),
  combineSyncedRegistrantsWithLocal: appendActorToAction(
    'COMBINE_SYNCED_REGISTRANTS_WITH_LOCAL',
  ),
  pushCombinedRegistrants: appendActorToAction('PUSH_COMBINED_REGISTRANTS'),
  updateIsAddRegistrantModalOpened: appendActorToAction(
    'UPDATE_IS_ADD_REGISTRANT_MODAL_OPENED',
  ),
  updateRegistrantSearchText: appendActorToAction(
    'UPDATE_IS_ADD_REGISTRANT_REGISTRANT_SEARCH_TEXT',
  ),
  updateRegistrantSearchQ: appendActorToAction('UPDATE_REGISTRANT_SEARCH_Q'),

  updateSyncedRegistrant: appendActorToAction('UPDATE_SYNCED_REGISTRANT'),
  addSyncedRegistrant: appendActorToAction('ADD_SYNCED_REGISTRANT'),
}

export const actionCreators = {
  setViewingId: (viewingId) => ({
    type: actionTypes.setViewingId,
    viewingId,
  }),
  addRegistrant: (registrantPayload, viewingId) => ({
    type: actionTypes.addRegistrant,
    registrantPayload,
    viewingId,
  }),
  updateAddRegistrantLoadingStates: (updatedLoadingStates) => ({
    type: actionTypes.updateAddRegistrantLoadingStates,
    updatedLoadingStates,
  }),
  registrantCreated: (registrant, viewingId, state) => ({
    type: actionTypes.registrantCreated,
    registrant,
    viewingId,
    state,
  }),
  syncedRegistrantsLoaded: (registrants) => ({
    type: actionTypes.syncedRegistrantsLoaded,
    registrants,
  }),
  combineViewingRegistrantsWithLocal: (viewingId) => ({
    type: actionTypes.combineSyncedRegistrantsWithLocal,
    viewingId,
  }),
  pushCombinedRegistrants: (registrants) => ({
    type: actionTypes.pushCombinedRegistrants,
    registrants,
  }),
  updateIsAddRegistrantModalOpened: (isOpened) => ({
    type: actionTypes.updateIsAddRegistrantModalOpened,
    isOpened,
  }),
  updateRegistrantSearchText: (registrantSearch) => ({
    type: actionTypes.updateRegistrantSearchText,
    registrantSearch,
  }),
  updateRegistrantSearchQ: (searchContactQ) => ({
    type: actionTypes.updateRegistrantSearchQ,
    searchContactQ,
  }),

  updateSyncedRegistrant: (registrant) => ({
    type: actionTypes.updateSyncedRegistrant,
    registrant,
  }),
  addSyncedRegistrant: (registrant) => ({
    type: actionTypes.addSyncedRegistrant,
    registrant,
  }),
}

const defaultState = {
  viewingId: '',
  syncedRegistrants: {},
  registrants: {},
  addRegistrantLoadingStates: createInitialLoadingState(),
  isAddRegistrantModalOpened: false,
  registrantSearch: '',
  searchContactQ: '',
}

const mapRegistrants = (registrants) => {
  const sortedRegistrants = orderRegistrants(registrants)
  return sortedRegistrants.map(attendeesData.mapRegistrant)
}

const mapSyncedRegistrants = (syncedRegistrants) => {
  const mappedRegistrants = mapRegistrants(syncedRegistrants)
  return reduceArrayToObjectWithIdKeys(
    mappedRegistrants,
    (registrant) => registrant.guidID,
  )
}

export const viewingAttendeesTab = (state = defaultState, action) => {
  const { type, ...payload } = action
  switch (type) {
    case actionTypes.setViewingId: {
      // todo: should be renamed to STARTED or at least create another action that called STARTED to reset the old state
      const { viewingId } = payload
      return {
        ...defaultState,
        viewingId,
      }
    }

    case actionTypes.syncedRegistrantsLoaded: {
      const { registrants } = payload
      return {
        ...state,
        syncedRegistrants: mapSyncedRegistrants(registrants),
      }
    }

    case actionTypes.pushCombinedRegistrants: {
      const { registrants } = payload
      return {
        ...state,
        registrants,
      }
    }

    case actionTypes.updateAddRegistrantLoadingStates: {
      const { updatedLoadingStates } = payload
      return {
        ...state,
        addRegistrantLoadingStates: updatedLoadingStates,
      }
    }

    case actionTypes.registrantCreated: {
      const { registrant, viewingId: actionViewingId } = payload
      const { viewingId } = state
      if (viewingId !== actionViewingId) {
        return state
      }
      return {
        ...state,
        syncedRegistrants: mapSyncedRegistrants([
          ...Object.values(state.syncedRegistrants),
          registrant,
        ]),
        registrantSearch: '',
        searchContactQ: '',
      }
    }

    case actionTypes.updateIsAddRegistrantModalOpened: {
      const { isOpened } = payload
      return {
        ...state,
        isAddRegistrantModalOpened: isOpened,
      }
    }

    case actionTypes.updateRegistrantSearchText: {
      const { registrantSearch } = payload
      return {
        ...state,
        registrantSearch: registrantSearch,
      }
    }

    case actionTypes.updateRegistrantSearchQ: {
      const { searchContactQ } = payload
      return {
        ...state,
        searchContactQ,
      }
    }

    case actionTypes.addSyncedRegistrant:
    case actionTypes.updateSyncedRegistrant:
      const { registrant } = payload
      const { syncedRegistrants } = state
      const updatedRegistrants = {
        ...syncedRegistrants,
        [registrant.guidID]: attendeesData.mapRegistrant(registrant),
      }

      return {
        ...state,
        syncedRegistrants: mapSyncedRegistrants(
          Object.values(updatedRegistrants),
        ),
      }

    default:
      return state
  }
}
