import React from 'react'

import { Box, Table } from 'app/components/design-system-components'
import Pagination from 'app/components/design-system-components/Pagination'
import * as Display from 'app/components/display/display'
import { debounce } from 'app/helpers/debounce'
import { setURLQuery } from 'app/helpers/query_string'
import {
  PAGINATION_PAGE_SIZE,
  sortOptions,
  statusOptions,
} from 'app/pages/teams/rental-references'
import * as TableDisplayComponents from 'app/pages/teams/rental-references/components'
import SearchAndFilter from 'app/pages/teams/rental-references/components/SearchAndFilter'
import {
  StyledReferencesInformationHeader,
  StyledRentalReferenceTable,
} from 'app/pages/teams/rental-references/components/styled'
import Theme from 'app/pages/teams/viewings-mobile/theme-styled-components'
import { history } from 'app/shared_components/router'
import Spinner from 'app/sm/common/spinner'
import { routes } from 'app/sm/helpers'
import { STANDARD_TIMEOUT, useDebounce } from 'app/utils/hooks/debounce'
// import * as TableDisplayComponents from '../components'

function RRTable({
  showSearchSection = true,
  showSpinner,
  filterValues,
  onChangeFilterValues,
  managerListInfo,
  onClearFilterValues,
  isFirstTime,
  showNoResultsMessage,
  showResults,
  dataToBeEntered,
  rentalReferencesCount,
  canNextPage,
  canPreviousPage,
  pageNumArray,
  currentPageNum,
  onPaginationButtonClicked,
  maxPageNumbers,
  toggleCompletedReferenceModal,
  fetchAgencyInfo,
  redirectToAuthenticatedRentalReference,
  agencyGUID,
  getRentalReferencesDataPage,
  teamSlug,
  location,
  showConnectTeamMembersModal,
}) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Property',
        accessor: 'property',
        Cell: ({
          row: {
            original: { property, renter },
          },
        }) => (
          <TableDisplayComponents.PropertyCell
            property={property}
            renter={renter}
          />
        ),
      },
      {
        Header: <TableDisplayComponents.RecipientHeader />,
        accessor: 'recipient',
        Cell: ({
          row: {
            original: { recipient, forProfilePreference },
          },
        }) => (
          <TableDisplayComponents.RecipientCell
            recipient={recipient}
            forProfilePreference={forProfilePreference}
          />
        ),
      },
      {
        Header: 'Assigned to',
        accessor: 'assigned',
        Cell: ({
          row: {
            original: { assignee },
          },
        }) => <TableDisplayComponents.AssignedToCell assignee={assignee} />,
      },
      // Will be uncommented when visibility is added in the BE
      // {
      //   Header: () => <div>Visbility</div>,
      //   accessor: 'visibility',
      //   Cell: ({
      //     row: {
      //       original: { visibility },
      //     },
      //   }) => <TableDisplayComponents.VisibilityCell visibility={visibility} />,
      // },
      {
        Header: () => <div className="text-align-center">Status</div>,
        accessor: 'status',
        Cell: ({
          row: {
            original: { status, action },
          },
        }) => {
          const { created_at } = action
          return (
            <TableDisplayComponents.StatusCell
              status={status}
              statusTime={created_at} // show rental history created_at as it's used for sort
            />
          )
        },
      },
      {
        Header: '',
        accessor: 'action',
        Cell: ({
          row: {
            original: {
              action,
              status,
              recipient,
              property,
              renter,
              assignee,
              guid,
              isArchived,
            },
          },
        }) => (
          <TableDisplayComponents.ActionCell
            action={action}
            status={status}
            recipient={recipient}
            property={property}
            renter={renter}
            toggleCompletedReferenceModal={toggleCompletedReferenceModal}
            history={history}
            fetchAgencyInfo={fetchAgencyInfo}
            redirectToAuthenticatedRentalReference={
              redirectToAuthenticatedRentalReference
            }
            agencyGUID={agencyGUID}
            managerListInfo={managerListInfo}
            assignee={assignee}
            teamRRGUID={guid}
            isArchived={isArchived}
            teamSlug={teamSlug}
            getRentalReferencesDataPage={getRentalReferencesDataPage}
            location={location}
            showConnectTeamMembersModal={showConnectTeamMembersModal}
          />
        ),
      },
    ],
    [managerListInfo],
  )

  return (
    <>
      <Display.MainHeader text="References" componentClass="mb10" />
      <Display.DefaultBodyText componentClass="mb24">
        <StyledReferencesInformationHeader>
          You're in control with rental references on Snug, with optional
          responses and private visibility.{' '}
          <a
            href="https://help.snug.com/hc/en-us/articles/360000931856-Rental-references-online-benefit-everyone"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
          .
        </StyledReferencesInformationHeader>
        {showSearchSection && (
          <SearchAndFilter
            inputTextPlaceholder="Address, email, phone"
            filterValues={filterValues}
            onChangeFilterValues={onChangeFilterValues}
            managers={managerListInfo.managerNameList}
            onClearFilterValues={onClearFilterValues}
            sortOptions={sortOptions}
            statusOptions={statusOptions}
          />
        )}
      </Display.DefaultBodyText>
      {showSpinner && <Spinner />}
      {showSpinner && isFirstTime() && (
        <Box textAlign="center" mt="32px">
          <Display.DefaultBodyText>
            Processing references (first time can take up to 10 seconds)
          </Display.DefaultBodyText>
        </Box>
      )}
      {showNoResultsMessage && (
        <div>There are currently no references to display.</div>
      )}
      {showResults && (
        <StyledRentalReferenceTable>
          <Table
            columns={columns}
            dataToBeEntered={dataToBeEntered}
            textColor={'#808080'}
            fontSize="12px"
          />
          {rentalReferencesCount > PAGINATION_PAGE_SIZE && (
            <Pagination
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              pageNumArray={pageNumArray}
              currentPageNum={currentPageNum}
              onPaginationButtonClicked={onPaginationButtonClicked}
              maxPageNumbers={maxPageNumbers}
            />
          )}
        </StyledRentalReferenceTable>
      )}
    </>
  )
}

export default RRTable
