import React from 'react'

import styled from 'styled-components'

import { Box } from 'app/components/design-system-components'
import ApplicationNoteFlatStyle from 'app/components/display/application_note/component_flatstyle'
import Spinner from 'app/sm/common/spinner'

const NotesContainer = styled.div`
  width: 100%;
  @media print {
    display: flex;
    flex-wrap: wrap;
  }
`

export const ApplicationNotesTab = ({
  notesLoading,
  notes,
  onApplicationChange,
  showNotes,
  teamSlug = '',
}) => {
  return (
    <NotesContainer>
      {notesLoading && <Spinner />}
      {showNotes &&
        notes.map((note, idx) => (
          <ApplicationNoteFlatStyle
            note={note}
            key={idx}
            handleApplicationChange={onApplicationChange}
            noteStyle="activityItem"
            teamSlug={teamSlug}
          />
        ))}
      {(!showNotes || !notes.length) && (
        <Box m={4} textAlign="center">
          No team notes
        </Box>
      )}
    </NotesContainer>
  )
}
