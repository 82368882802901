import React from 'react'

import styled from 'styled-components'

const HorizontalTabsHeaderContainer = styled.div`
  border-bottom: 1px solid #ccc;
  padding-left: 0;
`

const HorizontalTabContainer = styled.div`
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0 8px;
  font-size: 18px;
  cursor: pointer;
  .active-tab {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    border-bottom: 1px solid white;
    padding: 16px 8px;
    font-weight: bold;
    cursor: default;
  }
`

const TabLabelColor = styled.span`
  width: 10px;
  height: 10px;
  background: ${(props) => props.labelColor};
  display: inline-block;
  margin-left: 4px;
`

function HorizontalTabs(props) {
  const {
    tabHeaders,
    activeTabGUID,
    onClickTabInHorizontalTab,
    showLabelColors = false,
  } = props
  return (
    <div>
      <HorizontalTabsHeaderContainer>
        {tabHeaders.map((tabheader) => {
          return (
            <HorizontalTabContainer>
              <div
                className={
                  tabheader.tabGUID === activeTabGUID ? `active-tab` : ''
                }
                onClick={() => onClickTabInHorizontalTab(tabheader.tabGUID)}
                key={tabheader.tabGUID}
              >
                {tabheader.label}
                {showLabelColors && (
                  <TabLabelColor labelColor={tabheader.labelColor} />
                )}
              </div>
            </HorizontalTabContainer>
          )
        })}
      </HorizontalTabsHeaderContainer>
    </div>
  )
}

export default HorizontalTabs
