import React from 'react'

import { Box, Modal } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'

function EFTPaymentsSettingDelModal({ ok, cancel, payment }) {
  return (
    <Modal
      modalHeading="Are you sure?"
      modalBody={
        <Box style={{ lineHeight: theme.lineHeights.encrption }} mt={3}>
          Are you sure you would like to remove the EFT details for Account
          Name: {payment.EFTPaymentAccountName}?
        </Box>
      }
      showSecondaryButton={true}
      primaryLabel="Remove"
      secondaryLabel={'Cancel'}
      primaryAction={() => ok()}
      secondaryAction={() => cancel()}
      toggleModal={() => cancel()}
    />
  )
}

export default EFTPaymentsSettingDelModal
