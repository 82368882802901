import { api } from 'app/utils/api/helpers'

export const getLatestAppVersion = () => {
  return api
    .get(`/version.txt`, {
      baseURL: '',
      'Cache-Control': 'no-cache',
      params: {
        timestamp: new Date().valueOf(),
      },
    })
    .then((rawBody) => {
      const versionRe = /(?:VERSION: )(.*)/g
      const forceRe = /(?:FORCE: )(.*)/g
      const parsedVersion = versionRe.exec(rawBody)
      const parsedForceString = (forceRe.exec(rawBody) || [''])[1]
      const force = parsedForceString === 'true'
      return {
        version: parsedVersion[1],
        force,
      }
    })
}
