import { connect } from 'react-redux'

import { fetchRenterDashboard } from 'app/dashboard/dashboard_actions'
import DashboardContainer from 'app/dashboard/dashboard_container'

const mapStateToProps = ({ session, dashboard }) => ({
  currentUser: session.currentUser,
  bondCovers: dashboard.bondCovers,
})

const mapDispatchToProps = (dispatch) => ({
  fetchDashboard: () => dispatch(fetchRenterDashboard()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
