import $ from 'jquery'

import { LogFetchError } from 'app/shared_components/helpers'
import * as sharedHelpers from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const now = Date.now().toString()

export const getRequestSummary = (requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/summary?cachebuster=${now}`,
    success,
    error,
  })
}

export const addCurrentLease = (currentLease, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/currentleases`,
    data: JSON.stringify(currentLease),
    success,
    error,
  })
}

export const getCurrentLease = (requestID, guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/currentleases/${guid}?cachebuster=${now}`,
    success,
    error,
  })
}

export const updateCurrentLease = (requestID, data, guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'put',
    url: `${apiBaseUrl}bc/requests/${requestID}/currentleases/${guid}`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const addKnownAgencyManager = (requestID, manager, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/requests/${requestID}/knownagencymanagers`,
    data: JSON.stringify(manager),
    success,
    error,
  })
}

export const addManager = (requestID, manager, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/requests/${requestID}/managers`,
    data: JSON.stringify(manager),
    success,
    error,
  })
}

export const getManager = (requestID, guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/managers/${guid}?cachebuster=${now}`,
    success,
    error,
  })
}

export const updateKnownAgencyManager = (
  requestID,
  data,
  guid,
  success,
  error,
) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'put',
    url: `${apiBaseUrl}bc/requests/${requestID}/knownagencymanagers/${guid}`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const updateManager = (requestID, data, guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'put',
    url: `${apiBaseUrl}bc/requests/${requestID}/managers/${guid}`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const getTenants = (requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/tenants?cachebuster=${now}`,
    success,
    error,
  })
}

export const addTenant = (requestID, tenant, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/requests/${requestID}/tenants?cachebuster=${now}`,
    data: JSON.stringify(tenant),
    success,
    error,
  })
}

export const getTenant = (requestID, guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/tenants/${guid}?cachebuster=${now}`,
    success,
    error,
  })
}

export const updateTenant = (requestID, guid, data, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'put',
    url: `${apiBaseUrl}bc/requests/${requestID}/tenants/${guid}`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const deleteTenant = (requestID, guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'delete',
    url: `${apiBaseUrl}bc/requests/${requestID}/tenants/${guid}`,
    success,
    error,
  })
}

export const splitBond = (requestID, data, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'put',
    url: `${apiBaseUrl}bc/requests/${requestID}/tenants`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const getRenterSignature = (requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/renter/signature?cachebuster=${now}`,
    success,
    error,
  })
}

export const signByRenter = (requestID, signature, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/requests/${requestID}/renter/signature`,
    data: JSON.stringify(signature),
    success,
    error,
  })
}

export const getIdentityDoc = (requestID, guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/identitydocs/${guid}?cachebuster=${now}`,
    success,
    error,
  })
}

export const postIdentityDoc = (requestID, data, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/requests/${requestID}/identitydocs`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const updateIdentityDoc = (requestID, data, guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'put',
    url: `${apiBaseUrl}bc/requests/${requestID}/identitydocs/${guid}`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const postBankAccount = (requestID, data, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/requests/${requestID}/bankaccounts`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const getBankAccount = (requestID, guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/bankaccounts/${guid}?cachebuster=${now}`,
    success,
    error,
  })
}

export const updateBankAccount = (requestID, data, guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'put',
    url: `${apiBaseUrl}bc/requests/${requestID}/bankaccounts/${guid}`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const submit = (requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'put',
    url: `${apiBaseUrl}bc/requests/${requestID}/submit`,
    success,
    error,
  })
}

export const getBondCoverRequest = (success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/request?cachebuster=${now}`,
    success,
    error,
  })
}

export const getSecondaryTenantReview = (
  requestID,
  tenantID,
  success,
  error,
) => {
  $.ajax({
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/secondary/review?tenantid=${tenantID}&cachebuster=${now}`,
    success,
    error,
  })
}

export const getSecondaryTenantSummary = (requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/secondary/summary?cachebuster=${now}`,
    success,
    error,
  })
}

export const secondaryTenantApprove = (requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/requests/${requestID}/secondary/approve`,
    success,
    error,
  })
}

export const getBondCoverReview = (requestID, secret, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/manager/review?secret=${secret}&cachebuster=${now}`,
    success,
    error,
  })
}

export const postTenantRating = (
  ratingCode,
  rating,
  requestID,
  secret,
  success,
  error,
) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/requests/${requestID}/manager/rating?secret=${secret}`,
    data: JSON.stringify({ ratingCode: ratingCode, rating: rating }),
    success,
    error,
  })
}

export const updateTenantRating = (
  ratingCode,
  rating,
  requestID,
  secret,
  success,
  error,
) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'put',
    url: `${apiBaseUrl}bc/requests/${requestID}/manager/rating?secret=${secret}`,
    data: JSON.stringify({ ratingCode: ratingCode, rating: rating }),
    success,
    error,
  })
}

export const approveBondCoverRequest = (requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'put',
    url: `${apiBaseUrl}bc/request/approve?requestid=${requestID}`,
    success,
    error,
  })
}

export const withdrawBondCoverRequest = (data, requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/requests/${requestID}/withdraw`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const declineBondCoverRequest = (
  data,
  requestID,
  secret,
  success,
  error,
) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/requests/${requestID}/manager/decline?secret=${secret}`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const getDeclinedReason = (requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/manager/declinereason?cachebuster=${now}`,
    success,
    error,
  })
}

export const getBondCoverRatings = (success, error) => {
  $.ajax({
    method: 'get',
    url: `${apiBaseUrl}bc/ratingcategories?cachebuster=${now}`,
    success,
    error,
  })
}

export const getRentalHistoryRatings = (versions = [2, 3]) => {
  const url = `${apiBaseUrl}sm/ratingcategories?cachebuster=${now}`
  const versionReq = (version) =>
    fetch(`${url}&version=${version}`, {
      credentials: 'include',
    }).then((res) =>
      res.ok
        ? res.json().then((res) => ({ [version]: res }))
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )

  const versionsRequests = versions.map((v) => versionReq(v))

  return Promise.all(versionsRequests).then((versionsRes) =>
    versionsRes.reduce(
      (acc, resp) => ({
        ...acc,
        ...resp,
      }),
      {},
    ),
  )
}

export const proceedBondCoverRequest = (
  requestID,
  secret,
  comment,
  success,
  error,
) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/requests/${requestID}/manager/finalize?secret=${secret}`,
    data: JSON.stringify(comment),
    success,
    error,
  })
}

export const submitQuote = (success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/quote/submit`,
    success,
    error,
  })
}

export const getManagerSignatureSummary = (requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/manager/signature/summary?cachebuster=${now}`,
    success,
    error,
  })
}

export const getAgencyManagerSignatureSummary = (requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/manager/signature/agencysummary?cachebuster=${now}`,
    success,
    error,
  })
}

export const signByManager = (requestID, signature, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/requests/${requestID}/manager/signature`,
    data: JSON.stringify(signature),
    success,
    error,
  })
}

export const signByAgencyManager = (requestID, signature, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}bc/requests/${requestID}/agencymanager/signature`,
    data: JSON.stringify(signature),
    success,
    error,
  })
}

export const getManagerWait = (requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/requests/${requestID}/manager/actionresult?cachebuster=${now}`,
    success,
    error,
  })
}

export const getBondCoverCompleteSummary = (requestID, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}bc/cert?requestid=${requestID}&cachebuster=${now}`,
    success,
    error,
  })
}

export const getRentalHistory = () => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (rentalHistory) => ({ ok: true, rentalHistory }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const addRentalHistory = (rentalHistory) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(rentalHistory),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve() // We get nothing for now, when a review is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const editRentalReference = (rentalHistory, id) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${id}`
  const options = {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(rentalHistory),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getRentalReference = (referenceId) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${referenceId}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (rentalReference) => ({ ok: true, rentalReference }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const createTenantRating = (
  referenceId,
  payload,
  HTTP_METHOD,
  secretGUID,
) => {
  const url = secretGUID
    ? `${apiBaseUrl}sm/reputation/rentalhistory/${referenceId}/ratings?secret=${secretGUID}`
    : `${apiBaseUrl}sm/reputation/rentalhistory/${referenceId}/manual/ratings`
  const options = {
    method: HTTP_METHOD,
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? Promise.resolve() // We get nothing for now, when a review is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          })
    })
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const manualGetRentalHistoryReview = (referenceId) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${referenceId}/manager/review`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (review) => ({ ok: true, review }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const fetchRentalHistoryReview = (referenceId, secretGUID) => {
  const url = secretGUID
    ? `${apiBaseUrl}sm/reputation/rentalhistory/${referenceId}/manager?secret=${secretGUID}`
    : `${apiBaseUrl}sm/reputation/rentalhistory/${referenceId}/manager/review`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (review) => ({ ok: true, review }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const fetchRentalHistoryReviewByShortCode = (shortCode) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${shortCode}/manager/reviewbyshortcode`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (review) => ({ ok: true, review }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const finalizeRentalHistoryRequest = (
  referenceId,
  payload,
  secretGUID = '',
) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${referenceId}/ratings/finalize?secret=${secretGUID}`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve() // We get nothing for now, when a review is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const declineRentalHistoryRequest = (
  referenceId,
  payload,
  secretGUID,
) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${referenceId}/decline?secret=${secretGUID}`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve() // We get nothing for now, when a review is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const fetchCards = (success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'get',
    url: `${apiBaseUrl}profile/cards`,
    success,
    error,
  })
}

export const setDefaultCard = (guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'put',
    url: `${apiBaseUrl}profile/cards/${guid}/default`,
    success,
    error,
  })
}

export const removeCard = (guid, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'delete',
    url: `${apiBaseUrl}profile/cards/${guid}`,
    success,
    error,
  })
}

export const registerPayment = (data, success, error) => {
  $.ajax({
    xhrFields: { withCredentials: true },
    crossDomain: true,
    method: 'post',
    url: `${apiBaseUrl}profile/cards`,
    data: JSON.stringify(data),
    success,
    error,
  })
}

export const verifyReferralCode = (referralCode, requestID) => {
  const url = `${apiBaseUrl}bc/requests/${requestID}/applydiscount?discountcode=${referralCode}`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deleteRentalHistory = (rentalHistoryId) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${rentalHistoryId}`

  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const uploadRentalLedger = (formData, historyGuid, secretGUID) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${historyGuid}/ledger?secret=${secretGUID}`
  const options = {
    method: 'POST',
    body: formData,
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deleteRentalLedger = (historyGUID, secretGUID, ledgerGUID) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${historyGUID}/ledger/${ledgerGUID}?secret=${secretGUID}`
  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const manualCreateTenantRating = (referenceId, payload, HTTP_METHOD) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${referenceId}/manual/ratings`
  const options = {
    method: HTTP_METHOD,
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? Promise.resolve() // We get nothing for now, when a review is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          })
    })
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const manualFetchRentalHistoryReview = (referenceId) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${referenceId}/manager/review`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (review) => ({ ok: true, review }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const manualFinalizeRentalHistoryRequest = (referenceId, payload) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${referenceId}/manual/ratings/finalize`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve() // We get nothing for now, when a review is created successfully
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

//deprecated
export const manualUploadRentalLedger = (formData, historyGuid, secretGUID) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${historyGuid}/manual/ledger`
  const options = {
    method: 'POST',
    body: formData,
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const manualDeleteRentalLedger = (
  historyGUID,
  secretGUID,
  ledgerGUID,
) => {
  const url = `${apiBaseUrl}sm/reputation/rentalhistory/${historyGUID}/manual/ledger/${ledgerGUID}`
  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? Promise.resolve()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      () => ({ ok: true }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const deleteReference = (rentalHistoryGUID, referenceGUID) => {
  const url = `${apiBaseUrl}sm/rentalhistory/${rentalHistoryGUID}/tenants/${referenceGUID}`
  const options = {
    method: 'DELETE',
    credentials: 'include',
  }
  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then(() => ({ ok: true, responseText: '' }), sharedHelpers.returnAPIError)
}

export const getManagersForRentalHistory = (searchValue = '') => {
  const url = `${apiBaseUrl}sm/reputation/managerrentalhistory?search=${searchValue}`
  const options = {
    credentials: 'include',
    method: 'GET',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then(sharedHelpers.checkStatus)
    .then(
      (agencySource) => ({ ok: true, agencySource }),
      sharedHelpers.returnAPIError,
    )
}
