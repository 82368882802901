import React from 'react'

import {
  ButtonWithLoading,
  Flex,
} from 'app/components/design-system-components'
import * as Form from 'app/components/forms/forms'

const DECLINE_MESSAGE =
  'Are you sure, You want to decline application? Please select reason'

const DeclineStage = ({
  declineApplicationOptions,
  onAddComment,
  comment,
  onSubmit,
  onChangeDeclineApplicationOptions,
  acceptOrDeclineInProgress,
}) => {
  return (
    <div>
      <div className="mt30 mb30">
        <h5 className="text-align-left gray-color">
          Enter any comments for the Property Manager
        </h5>
        <Form.CommentArea
          className="textarea border-radius-6px no-resize p10 width100"
          rows={3}
          maxLength={160}
          placeholder="Add Comment..."
          onChange={onAddComment}
          value={comment}
        />
      </div>
      <Flex justifyContent="center">
        <ButtonWithLoading
          onClick={onSubmit}
          loading={acceptOrDeclineInProgress}
          sizeVariant="large"
        >
          Submit
        </ButtonWithLoading>
      </Flex>
    </div>
  )
}

export default DeclineStage
