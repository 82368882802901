import styled from 'styled-components'

import { FieldWrapper } from 'app/forms/FieldWrapper'
import theme from 'app/match/applicationReportPDF/assets/theme'

export const StyledRecipientField = styled(FieldWrapper)`
  label {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
  margin-top: -${theme.space[3]}px;
`
