import React from 'react'

export const EmailLink = ({
  email = '',
  subject = '',
  body = '',
  className = '',
}) => (
  <a
    href={`mailto:${email}?subject=${subject}&body=${body}`}
    className={className}
    title={email}
  >
    {email}
  </a>
)

export const TelLink = ({ phone = '', className = '' }) => (
  <a href={`tel:${phone}`} className={className} title={phone}>
    {phone}
  </a>
)
