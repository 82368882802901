import React, { useEffect, useState } from 'react'

import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import {
  Alert,
  Box,
  Button,
  Flex,
  Modal,
} from 'app/components/design-system-components'
import {
  HomeRounded,
  LockOpenRounded,
  LockOutlineRounded,
} from 'app/components/design-system-components/icons/actions'
import WarningRounded from 'app/components/design-system-components/icons/alert/WarningRounded'
import { MailRoundedOutline } from 'app/components/design-system-components/icons/content'
import { Calendar } from 'app/components/design-system-components/icons/snug-specific'
import {
  SelectField,
  SimpleOptionContainer,
} from 'app/components/design-system-components/inputs/Select/Select'
import { TrimWithEllipsis } from 'app/components/design-system-components/TrimWithEllipsis'
import {
  Text,
  Text as TextComponent,
} from 'app/components/design-system-components/typography'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import { StyledFieldWrapper } from 'app/features/teams/key-logger/components/StepLayoutContainer'
import { API_ERRORS_PROPERTY } from 'app/forms/FieldWrapper'
import { convertTransApiErrorToStatus } from 'app/forms/utils/convertTransApiErrorToStatus'
import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { mergeViewing } from 'app/services/http/viewings'
import { viewingNoYearTimeFormat } from 'app/sm/helpers'
import { formatTime } from 'app/utils/datetime/helpers'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'
import * as viewingsHelpers from 'app/utils/viewings/helpers'

const LeftSideVerticalLineCmp = styled(Box)`
  border-left: 1px solid ${(props) => props.theme.colors.gray100};
`

const StyledIcon = styled(Flex)`
  svg {
    color: ${({ theme }) => theme.colors.gray500};
    font-size: ${({ theme }) => theme.fontSizes.pLarge16};
    font-weight: ${({ theme }) => theme.fontWeights[3]};
  }
`

const UpcomingViewingOptionElem = styled(SimpleOptionContainer)`
  text-align: left;
  padding: 0 ${theme.space[3]}px;
`

const formFields = {
  mergingViewing: 'mergingViewing',
}

const formValidation = formValidationBuilder((fieldName, val) => {
  switch (fieldName) {
    case formFields.mergingViewing: {
      return {
        required: !val && 'Please select a time',
      }
    }
  }
})

const getConfirmedAttendees = (allRegistrants) =>
  allRegistrants.filter((attendee) => {
    return (
      attendee.viewingOnsiteRegistrant.confirmationStatus ===
      viewingsHelpers.StatusAttendeeConfirmed
    )
  })

const UpcomingViewingOptionCmp = ({
  option,
  onClick,
  isLast,
  selected,
  ...props
}) => {
  const {
    option: {
      viewing: { startDate, duration, published },
      assignedTeamMembers = [],
      onsiteRegistrants = [],
    },
  } = option
  const confirmedAttendees = getConfirmedAttendees(onsiteRegistrants)
  const formattedTime = formatTime(startDate, viewingNoYearTimeFormat)
  const inspectorsText = assignedTeamMembers
    .filter((member) => member.isInspector)
    .map((member) => `${member.firstName} ${member.lastName}`)
    .join(', ')
  return (
    <UpcomingViewingOptionElem
      onClick={onClick}
      className={selected && 'selected'}
      {...props}
    >
      <Box>
        <Text
          as="span"
          fontWeight={theme.fontWeights[3]}
          fontSize={4}
          color={theme.colors.black}
        >
          {formattedTime} ({duration}m){' '}
          {published ? <LockOpenRounded /> : <LockOutlineRounded />}
        </Text>
      </Box>
      <Flex fontSize={3} color={theme.colors.gray}>
        Att: {onsiteRegistrants.length} Conf: {confirmedAttendees.length}
        {!!inspectorsText && (
          <LeftSideVerticalLineCmp ml={2} pl={3}>
            <TrimWithEllipsis lines={1}>
              Inspectors: {inspectorsText}
            </TrimWithEllipsis>
          </LeftSideVerticalLineCmp>
        )}
      </Flex>
      {isLast === false && (
        <Box
          my={`${theme.space[1]}px`}
          borderBottom={`.5px solid ${theme.colors.grey200}`}
        />
      )}
    </UpcomingViewingOptionElem>
  )
}

export const MergeViewingModal = ({
  futurePropertyViewings,
  cancellingViewingDetails,
  closeModel,
  onMergeSuccess,
  addViewingModal,
}) => {
  const [forceMerge, setForceMerge] = useState(false)
  const {
    loadingStates: submitLoadStates,
    loadingStatesHelpers: submitLoadingHelpers,
  } = useLoadingStates()

  const mergeViewings = (values, { setStatus }) => {
    setStatus({ apiErrors: {}, apiGeneralError: '' })
    const {
      viewing: { guidID: cancelingViewingId },
    } = cancellingViewingDetails
    const {
      option: {
        viewing: { guidID: mergingViewingId },
      },
    } = values[formFields.mergingViewing]
    submitLoadingHelpers.startLoading()
    return mergeViewing(cancelingViewingId, mergingViewingId, forceMerge)
      .then(() => {
        onMergeSuccess()
        submitLoadingHelpers.markDoneSuccessfully()
        toast.success('Viewing Merged Successfully')
      })
      .catch(({ message, detailedError }) => {
        submitLoadingHelpers.setError(message)
        if (message === ErrorMessages[Errors.ErrorViewingMergeNotAllowed]) {
          setForceMerge(true)
        }
        setStatus({
          [API_ERRORS_PROPERTY]: convertTransApiErrorToStatus({
            [formFields.mergingViewing]: detailedError?.mergingViewing,
          }),
          apiGeneralError: message,
        })
      })
  }

  const renderModelBody = () => {
    const initialValues = {
      [formFields.mergingViewing]: null,
    }
    const { viewing, onsiteRegistrants } = cancellingViewingDetails
    const formattedCancelingTime = formatTime(
      viewing.startDate,
      viewingNoYearTimeFormat,
    )
    const address = `${viewing.FriendlyName}, ${viewing.Suburb}`
    const confirmedAttendees = getConfirmedAttendees(onsiteRegistrants)
    return (
      <Box width="100%" pt={3}>
        Notify and copy{' '}
        <span style={{ fontWeight: 'bold' }}>
          {onsiteRegistrants.length} attendees
        </span>{' '}
        to selected viewing time.
        <Text mt={5} mb={2} variant="large" fontWeight="bold">
          Cancelling
        </Text>
        <Flex alignItems="center">
          <StyledIcon>
            <Calendar />{' '}
          </StyledIcon>
          <Text as="span" ml={2}>
            {formattedCancelingTime} ({viewing.duration}m){' '}
          </Text>
          <StyledIcon mx={`${theme.space[3]}px`}>
            {viewing.published ? <LockOpenRounded /> : <LockOutlineRounded />}
          </StyledIcon>
          <LeftSideVerticalLineCmp ml={2} pl={3}>
            <Text as="span" color={theme.colors.gray600}>
              Att: {onsiteRegistrants.length} Conf: {confirmedAttendees.length}
            </Text>
          </LeftSideVerticalLineCmp>
        </Flex>
        <Flex alignItems="center" mt={3}>
          <StyledIcon>
            <HomeRounded />
          </StyledIcon>
          <Text as="span" ml={2}>
            {address}
          </Text>
        </Flex>
        <Text mt={7} mb={2} variant="large" fontWeight="bold">
          Merging
        </Text>
        <Flex alignItems="center">
          <StyledIcon>
            <MailRoundedOutline />
          </StyledIcon>
          <Text as="span" ml={2}>
            {onsiteRegistrants.length} attendees will be sent an email
            confirming the change.
          </Text>
        </Flex>
        <Formik
          initialValues={initialValues}
          onSubmit={mergeViewings}
          validate={formValidation}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {(props) => (
            <FormBody
              futurePropertyViewings={futurePropertyViewings}
              close={closeModel}
              onMergeViewingChanged={() => {
                setForceMerge(false)
              }}
              addViewing={addViewingModal}
              address={address}
              actionsDisabled={
                submitLoadStates.state === loadingStatesIds.LOADING
              }
              {...props}
            />
          )}
        </Formik>
      </Box>
    )
  }

  return (
    <Modal
      modalHeading="Merge viewing"
      toggleModal={closeModel}
      modalBody={renderModelBody()}
      showSecondaryButton={false}
      showPrimaryButton={false}
      customModalFooter={true}
      modalFooter={<></>}
    />
  )
}

const FormBody = ({
  futurePropertyViewings,
  close,
  onMergeViewingChanged,
  addViewing,
  address,
  actionsDisabled,
  isValid,
  status,
  values,
}) => {
  const viewingsOptions = futurePropertyViewings.map((pv) => {
    return {
      option: pv,
      optionMeta: { id: pv.viewing.guidID },
    }
  })

  useEffect(() => {
    onMergeViewingChanged()
  }, [values[formFields.mergingViewing]])

  return (
    <Form>
      <StyledFieldWrapper
        id={formFields.mergingViewing}
        name={formFields.mergingViewing}
        width="85%"
        mb={5}
      >
        <Flex alignItems="center">
          <Text as="span" mr={`${theme.space[4]}px`}>
            To:
          </Text>
          <SelectField
            id={formFields.mergingViewing}
            name={formFields.mergingViewing}
            label={
              <Text as="span" color="black">
                Select viewing time
              </Text>
            }
            options={viewingsOptions}
            OptionRenderCmp={UpcomingViewingOptionCmp}
            resultsLabel={`Upcoming viewings for ${address}`}
            NoOptionsCmp={() => (
              <Box px={3} fontSize={theme.fontSizes.pRegular14}>
                None available, please{' '}
                <Button variant="linkBlueWithoutWeight" onClick={addViewing}>
                  add a viewing
                </Button>
              </Box>
            )}
            ml={2}
            useWrapperWidth
            width="100%"
            buttonVariant="outlineNoFontColor"
            optionsHeight="200px"
          />
        </Flex>
      </StyledFieldWrapper>

      {!!status && !!status.apiGeneralError && (
        <Alert variant="warningWithBg" mt={theme.space[3] + 'px'}>
          <Flex alignItems="center">
            <WarningRounded
              verticalAlign={'middle'}
              width={`${theme.width[5]}px`}
              height={`${theme.width[5]}px`}
            />
            <TextComponent as={'span'} ml={`${theme.space[3]}px`}>
              {status.apiGeneralError}
            </TextComponent>
          </Flex>
        </Alert>
      )}

      <Flex alignItems="center" mt={5}>
        <Button
          type="button"
          variant="solidSecondary"
          flex="1 1 30%"
          mr={theme.space[3] + 'px'}
          onClick={close}
        >
          Cancel
        </Button>
        <Button
          disabled={!isValid || actionsDisabled}
          type="submit"
          flex="1 1 70%"
        >
          Merge
        </Button>
      </Flex>
    </Form>
  )
}
