import { api } from 'app/utils/api/helpers'

export const getRenterAgencySummary = (teamId) => {
  return api.get(`/sm/team/${teamId}/applicant-agency-summary`)
}

export const getApplicationApplicantsAgencySummaries = (
  teamId,
  applicationId,
) => {
  return api.get(
    `/sm/team/${teamId}/application/${applicationId}/applicants-agency-summary`,
  )
}
