import React from 'react'

import { Box, Modal } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'

function PaidPlanContinueModal({
  fieldForPaidPlanContinueModal,
  primaryAction,
  secondaryAction,
}) {
  return (
    <Modal
      modalHeading="Paid plan authorised"
      modalBody={
        <Box style={{ lineHeight: theme.lineHeights.encrption }} mt={3}>
          A new paid plan item has been added. Please ensure the customer has
          authorised costs in writing via Zendesk.{' '}
          <a href="https://snug.com/pricing/" target="_blank" rel="noreferrer">
            snug.com/pricing
          </a>
        </Box>
      }
      primaryLabel="Continue"
      secondaryLabel="Cancel"
      primaryAction={() => primaryAction(fieldForPaidPlanContinueModal)}
      secondaryAction={secondaryAction}
      toggleModal={secondaryAction}
    />
  )
}

export default PaidPlanContinueModal
