import React from 'react'

import styled from 'styled-components'

const HorizontalTabsHeaderContainer = styled.div`
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  display: flex;
  align-items: center;
`

const HorizontalTabContainer = styled.div`
  /* display: inline-block;
  list-style: none;
  margin-bottom: -1px; */
  padding: 8px 24px;
  font-size: 14px;
  cursor: pointer;
  background: #e6e6e6;
  border-radius: 4px 4px 0px 0px;
  margin: 0px 1px;
  line-height: 140%;
  &.active-tab {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    border-bottom: 1px solid white;
    /* padding: 16px 8px; */
    font-weight: bold;
    cursor: default;
  }
`

function HorizontalTabs({
  tabHeaders,
  activeTabGUID,
  onClickTabInHorizontalTab,
}) {
  // const { tabHeaders, activeTabGUID, onClickTabInHorizontalTab } = props
  return (
    // <div>
    <HorizontalTabsHeaderContainer>
      {tabHeaders &&
        tabHeaders.map((tabheader) => {
          return (
            <HorizontalTabContainer
              className={
                tabheader.tabGUID === activeTabGUID ? `active-tab` : ''
              }
              onClick={() => onClickTabInHorizontalTab(tabheader.tabGUID)}
              key={tabheader.tabGUID}
            >
              {/* <div
                
              > */}
              {tabheader.label}
              {/* </div> */}
            </HorizontalTabContainer>
          )
        })}
    </HorizontalTabsHeaderContainer>
    // </div>
  )
}

export default HorizontalTabs
