import * as React from 'react'

function SvgLSRE(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="179px"
      height="70px"
      viewBox="0 0 179 70"
      enableBackground="new 0 0 179 70"
      xmlSpace="preserve"
      {...props}
    >
      <image
        id="image0"
        width="179"
        height="70"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAqoAAAELCAMAAAAm81SOAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAACAVBMVEX////998H886L874Tw
9/jC4OX43wgJgpXR2NvBy8+yvcKElp7w8vNGYm50iZJVb3oILT0nR1VlfIbg5eeTo6o2VGEXOkmi
sLb44Rf762X//fD++dH650b54yf88ZP+++B1ucP55TYokqKTyNGj0NdHobBWqbaEwcqy2N7g7/IY
ipxlsb03manR6Ov66VXv9en99bJ0t7T77XTCzNHg5eiUpa5HZXQKMkUpTFxmf4ujsrkZP1GFmaJX
cn/R2dyyv8V1jJZlfXs4WGj5+fjm5uPs7OpGXTYZPUE3Ujqirpqbm460tKqhoZXHx7+oqJzBwbja
2tXz8/HNzce6urHT087a6eudytDg4Nx+usONwspfq7YShJWs0tc6laGvzc+WxMkii5vj6+pJnahA
m6imy9DCy86zvcKGlp7R2NqksLbh5eeVo6pYb3kMLTwbOkhnfIU6VGF2iZFJYm0qR1Qxk6Jusryu
rqNQbHgyUmF4jJTL0tXj5uWmsrdyho1BX2wuTFc1U15Zcn2Qn6TU2doTOUpTbHYrTFpKZnHp7OyB
k5ja3+Gsub5geYQ7WWWgrLCps7SXpqvw8/alt8eIn7VMb49be5m0w9C548p0x5XS2+Nqh6KW1bAQ
P2rDz9qi2rlFtHIfS3MuV33h5+x5k6uWq749Y4aOrrYdXGzo9u3R7Nzc8eV2HC1LAAAAAWJLR0QA
iAUdSAAAAAd0SU1FB+cBCg4HKZIla+8AAAFeelRYdFJhdyBwcm9maWxlIHR5cGUgeG1wAAAokYVT
W3bEIAj9ZxVdAgEEsxwb9a/n9LPLL2hmkslMp/Hkpch9gPDz9Q0fcQkb8GaZi2Wthlo1mehCGP+6
aTOONa5EuqhoV9LEZc7fozsRIRxpfPIztqQsNSGhsHbzjYSs1BjHTdywEMZwCuTJlUsiERC94M/F
4JBNfCAXx+w2LmrmQdQGhFHnhdcY1IGRySfIn3Um8Tfb6mmdtmWqARDLB5crIxFNYKzsE+uQtroL
zRnuAe4LObY7EQzdiIdE52EZTqIikb6SNTi1w27/dv+0uuB9T0hbhon9Pd65hlGYZzB4g5bdIwxD
b1BXl+5eOS8YYopEFVZNu8W7W9y8Ktsh4O84LPASYdIvEmVIY4MnSMG568ZNDv4oq8te1EBTGsEq
tg70f5JcW4D6rDHMLnhugnMH34JnD4vG2gQ9DhfM8zJU/gIbBNDrieWhjQAAAAFvck5UAc+id5oA
ACN0SURBVHja7Z35Yxs3dsfpI7Ekjg5SEmXLduw4tuUjSV0fkRMfSWO7h+M4jpPWTdvtdttSFEmJ
pKg2Tbrd7qabbo/ddisfae1arew4vf7KcoA5MBicMyBnIL7vD4nFwQCYmc8AD28AvEIBBAKBQCAQ
CAQCgUAgEAgEAoFAIBAIBAKBQKra0U/t3MXTDrfs3S+paHfWtwiUD72cjXa5Zb+0JxO9lPU9ByUS
oAqyRIAqyBIBqiBLBKjmSSOjo2NFV6Ousq5NzgSo0nJ6sIxPFMecQT4GZ7Q4OT4VU2m8XBydTpXz
6EyxPC7RRO/lmBkdGeQFJxCgSj3ZcsDJ5ICaNWesPDslUmVyJmFVRsuVKQ2VEhcUaGRmUvZi8N6X
OUnjAKhGqClHHt14ugZNRc7cpBJFPVy1KzMyrpQ1XZAMGcHFzJQSlBgWXRZeIqBKPlv6TlfG+gvq
iFarV5rQavPmEjNTTta2jqUCFd3vGUBVCdURBjdzfQR1VL/Vq5SVX56yduaEZhNcd6oCg7cEUFVA
1WG1cJW+DTYSgIpUmlCyBCZSMlPSbFkdNUMmBauAaiD2vZ7tD6jTaZ5sUZ7/WHpo9Cx1I22qK64N
AKj6GuXcur6YADNaI/OYZmUjHydd/lhCy5G+IFOkTlV4bwig6ovXzJXMg+ok7PvVWS2awWZS1Rkw
beLV8MQzAQBVHx/urTNurY6kHin3emdxEabImVW8eGPdf08VQFWM6ij31ml0g0qaM8KRsFYGLFWf
GyVWjdgbgTgDOkDVE7/LnDBMqiGGRGWkHf7rsmru1XDFGTUCqlJUJX1tNqSKh3upbWFNVg2+GlPc
tgFQHSyq5tqfSUEpBoxhLVZNvhrcGw6oDhTVEXNGncgCMErOVEnqBzD6akCrmgdUnVmNJybTwFCV
3wGzqIKtmgNUTfp0Boiq9POYWQMAPADZo2p2oDxAVGW+ZaOogl81e1Qds/3kIFGV3AOjqPKacEB1
cKga+trpSTSPxjiqU+K5h0adY7xBHKA6MFTNftIRfq4yj6p4JoRJVLn+YkB1YKiabVSnRFP0zKMq
nmBmEFWYr5oDVM1aqmVRUX1AVThv1xyqgq/YgOqgUFUc/lfQ8k0p1hWhW74PqAqdAKZQLYlMYkB1
UKhKfaqVycia/+nRmQkuApKPnf1AVdSMm0G1NCN8/wDVQaEqGVRNshuU0SLrA5fsszy/GI57fWRs
QvYhTfQhl4/qrOo2AMUZ2VQDQHVAqI4IORCtY4pvFSCd8KyNKqqhpN0XdM7jScrTFaDqqd+oChcf
yWZvO3NEm1eSrwJMhGqvCRe2rIIRD6C6nVAVNVkqKw2ni2isNT6hshFAQlTF02kEPgBAdTuhKhh5
KKyV1lRSVMWsJrk4QNU+VPnupz4siU2MqtCk5p8LqG4nVPkEmF5mWEiDqshQ4VcUUB0OVPuwN2aK
wgTNKt9QAVSHA9U+7IyZ5r3gWyr8GwGoDgeq5h6mQmFydPgWAKA67Kj2Ya/BNKgmuREWo7p3384d
873c9+/YdQBQldDTh+2G06DK32ZminuOtage3EmWMP/KwXiSQ+4RQBWpnD5z9cIA1ViLSWp+FzvJ
4WFCle9br5iP5QKoqmjvDkYp+1+NpDmAfz0yTKgKvlZNps+dEqCqQup+djk79xKkzqOfXsuG1IxQ
FW3rZNwESIMqfwo4fxqglaj6beruo4ePHTu+cCIIkT6/0zNZ93oGwslTQ4WqcGbVuGEbIA2qw+IB
8DE87XNx6vTJoKz9r+w7sG/nvPfX6xmRmhGqgn7VbbDMfrJKgyo/SMG2QvVVnOkJssE89Rqz4NOJ
UbMTVQE+SJMmP1qlQZW/WoFvU1uIKu7+T1BsHHsjXm52pGaFqnT9UdkcrClQFaxW3E5zAHCjyjBC
CSsAJ8ms988OVYUYJAmj8MWVAlXBhFX+DHD7UMWu/2MsPk4TLevuhaxGVFmiOi1HNVkUPoaSoypy
VPC/ANuHKhox8b5BHTl81IXkpaMZ9v1ZolpQC6pWKhrwBiRGVbhTOv8061B9E+V4PGMSc4uq6paV
lfRGa0JUp4X2tOA+WIfqK26Gu7MmMbeoauwENJ7yCSdCdUyyuFqwWiH1lhUlhRjyJlFF4/+jWZOY
X1R1gquUUhmteqg6o6NjxUnpPoUClozsriLrTEyiijLMaA6KDajqPdFKMbkdYIIcWqL91QztWSVe
ZWaQ1L0ow2PpWdq2qI5oPDZX5aSzrvuBqogjU9urCSdDGEQVz5fK1A+Vc1T1t1gdTzbvuh+oihwT
xjatFLFqHNWsQcw1qgmeaSKjtQ+kChs8c/urCl5NQHWgqCaJXJEA1j6gKjSczaEqmGduHNWM5ktb
gmqycIDasJonVTyl1uAG63yT2CCq2AMAw6o+sDo1q+doNU6qZFGNQVT5jgaTqKLvquCs6gurwh1Y
+4+qZLMikxFWuNdpElX0CeBEepa2N6qFkYSBVifUJweYJlV2C0yiyu0/TKKKlwDk3luVNaoFR23i
SkwlZSvAMKkVWYtuXdwqPF019xZA5qgqzV1lSnUnVsOoSl8Rk6hyr9EkqngjCt7yvlPHF15ytZD1
wCsHqBZGEj5cxfWCZkmV+x+sMwA8C2CBxcfpE2GZJxf02NqGqPYa1mSjq5LSt9YBk2oUVe4FGkV1
L16NGluNcmrhV6KlDuOCFVpOskCWsjhAxlFV8ekaRJW/2YBRVL1mdTcF4vGT8XKHbxlgXNPSqGtJ
WR0wqTbGWN17CLNKmqNH2GgM3eJqJqwTCcwABVaNgVNRmy5jEFX+xZlF1d+NqnDU/756hNgF4NCZ
Xbt2nRnSLSs4cmb0ZwXIWTXFjTSWm3FUBR9wDaP68j4/4zcWFn514bVwner8Tm+Ltb2v4B+GbCMg
vua0H/SszA9giBvlKEXGUBV9wDWN6ss7mcXM7yK2V3sTN6xZLW3JG6rykJExyfYONILNuPrEblOo
CvuLpES+euDsrl37DuyNH9nHKOVMdDfgYdy0Uqzpop7RKhnsGKCmpDOp2xCqYssmCaZ7z54Jzj+0
880YxfS+lYdiSYZwK2CZnDkdo1Uy0yk1NJqrD8ygOim+KH1QD9Bd/KGzsYaVhHX/PkYmyFOQ0cyW
fKLa06jGExfPH00Lje6aLgOoVqS7IOmCevAMI5N4s/nq2TM79s/v2LHz7EFmNsMXtkJJGp5WIU1p
udHdR5uParmopBmFmTiapO6bZ2dzZq9ePoAqR05R0Q4Q0pQWVd1NUXK4uwrZ9zvnzhF/Ubv9A6rJ
Nac2n1U0MY9/VtjKCTMv6W2clT9UA1LPX3hr0dXFt9/xf5rXYhVQFUnJaBVZqyrNpXAnNc3A77lD
1Sf1HOYU69Jl71ctVgFVsRRgFTkBVFCVLJ7VGlnlDVXPYepcWYzqomcI7NewVwFVmUalNqvAt6qC
qmRnQq2rzhmqB/GI6urFxZg8K+AMoGpSshmtAgtACVXJhq8z0gqGyhmquPu/emmRobdxZgeUMwNU
FTQtHl/xZ3YqojotfBd0ghTmC9WDKLlzcZEp3K4eAlTNSuxm5ZuTaqhKlnhpOFfzhSpuVK8scoTt
VeVmFVBVk5BVvrGqiGpB3GyrY5YvVJGleo5H6uJFlNtOQNWwRPYk36Gkip94F01152quUMWTobiN
6uLiu+5xZQsAUFWUyKXEr5hyS2nIuZorVBFbDp9Ub2R1UCc7QFVBApeSAVQNOVdzhSoyVc8JUL2E
slM1VgFVZfHtyRL3HGVUDTlXc4Uq2o/qsgDVRZQdoGpcgmE69xx1VM04VwFVQLUgDHnNPUcDVSPO
VbtQBQOgT+ozqkacq/lD9R0Bqm+h7FSnrACqyuo3qiacq7lCFbF1VYDqBf3sAFUV9R1VA87VXKGK
p1Vd5KN63j2+A1A1LkEMQe45eo1keudqrlDFUwD4xuoVdPysanaAqrImElRMD9X0ztVcofoyWoTq
XOKhehXlpvoFAFBVVylBxfRQTe9czReqZ1HyCxxS30NHlacAAKrKEpiqE9yTNFFN7VzNF6re3qlv
MUm9iG1v5UZ1qFGdHu3JwEwQAUK6qKZ1ruYLVW9HFPaEVdz9qzeqw4vqaNmjYlYtMLXI68nHQPuc
lM7VnKGKrVWmwwqvBJyHtVUyRVf3Tcp32xEM/wUOAH1UUzpX84YqDqBSeI/X/e/TyGs4UY2VUZkQ
Dq8doRtJUC996NI5V/OGqudbjc8ExKtVNLr/IUWVOaW/Uh7jcCDbJVgw2knQPqZyrvJRnRlV09jY
qMwg0uHLW7Tya/TXf3SrD2ltBWQTqiW1bZfQ1kuiRpIPw+zEHI3QyIw0Dpvg0SZANZVz1cxOgKUJ
Ia1aqGIvAD0TAE+q1un+7UJVSxXu7R6VnTk+PoGBL4+rbAXEj5ubCNVUzlVju1aXje1ajZrV86z+
f14vo22L6hS3szQZ28mVaDdg/lkC2zGFc9XctVX4FdQj7E10DstT9QqgGoi5Vb+sUdUVfwlAUlRT
OFdzGGMVzwSgPgOg397Uy2hbo8pkNVGMKoGErRz/NNGIPLlz1WiPwTOL9Qh7mYuq+sYqQ4Aqy7LT
j/kjlLBRLfDPEzqPEjtXjaLKuzRAtR+oMto8o9lPTYm/HiTiTepcHRCqPGMfUO0LqvH9pIxmL/vU
yT9R7JIXO1f5AzmzqHLeCT3C8MYVFxmoKs9UHQ5U44/VaO6yCST8M8Woip2rfPeYYe8G21rVIww5
q+jPVWi7KvWd1YYD1VizZzR32eQB/pmSD51i5yrXQ28YVbYFoAUY3mL1XeZcVb1mddujWunn05Su
IeGfKvsmL3Suck82jCp751gtwPDcqrfp2Sro1+0bCyCZ6Pwn0mcpfpJmUBU6V7mviGFU2Z/GNPDa
ewadQX+s8qerzOu4VvOH6lz6OxxRjAFjOUuDAadBVehc5aJq2GmcEtUD+w7hM+K7AV7y7t3OA3pr
q04uqEsWkv3IaeWsTjJvhOmvSX1reRRIFThx5ZPyBM5V7mcH8ccDbU1IK6kk1rYVV8xkLdQbxwSg
HnspfQF68Xhlinurps0UMJluXb78bIFzlcu5uS4DaU5aSRUJYwH0Vws8UE+dMJG92U6M0YcZMTHk
dqorrmFcSnOygHPpjEUtKS3lkekcZ3X123rR45LpKJvU10+mz7pg2gJg9ZXpWa0otjfcllGla+U6
VwUTAY3ePCNz2flbVrx/1QgvYjFZfX23odxNDmPZPvq5lDbArHLcM961KLVXPOeq6D0x2SelX+Ry
/oJgH6Bew/qubobXrivLO+M0Yzzlk3pDNa9rnOoYMiaROGPl6TQdZUUjmCSnlVMzHzjduWgud8FR
CwyrIt5H419X1W8sKug3VXP7Lbfs6zeV9QHma/cpnuvp+gfKeV3n3W5z/ir+srnpiaRTrMpaFhyz
lVONQ8F+acVN+oipF53r4VABsA+6rInqzVs3UHVjJsBpfBkfamTFRdUYqxXhUx0rJ3iqeqCyW7mK
sv3AMgFkZvK0mXaVb+TYgurN26hdjTWreEj1kU5OfFRTG5OqSGjSWtEFlcmqOqmsl1Y+oHNMfJAb
5zf81qB68yNUX8pafR39eEcrIwGqhREDY6tZJa5GiqpFTc4lc69Q5Ixr4T4StVNKSkMdpeDwIpVE
L4Q9qN782D2HsgAOo4u4bQzVXnuS8naX1P3XzpgU10o5Iac0ORrV8ipXDBv+SlG1EtMzyW9fqSye
MGYRqnfcc6h5A0cT5HNdersnE97v0viERieLNTI3Mc62BsbLc9q5xS9l1q1WMUlGzlx53F3zXZbv
V0RdkuI2FRHJa2gRqh+65+xmjP/vmkU1C42MzhWLxXGsiWJxTrrXyPDJIlQ/QBXenqiC5LII1U9Q
hRmofgqoDoMsQvWue85vR1H9nQT5AKp2yiJU77nnUMOq30UXAagOgyxCFX0D+IyaqIou4hNAdQhk
D6r4E8DvUV+rEL83ANUhkDWo3kJfAK79PoXqH6Cr0BpYAap2yhpUP0XVjU1X+R6+DPV5VYCqrbIF
VexULXwvNgnwD9HvHwOq216WoIq7/8L341Or/+gH6IjGZwBA1U5ZgiryqRZ+EJ9ZvWfPH6ND124B
qttcdqB6Cy8C+JM9LP0pOqY+ZRVQtVN2oIpmqrC6f2QCaDqspKhWl2o9LS2L0ixX3TT1Kmd23FKt
zj5Qr1WTpNNTo9rsVa5ZTbOMmFsB7+5wjjq885xmrcHOr15DlWUfJWQHqshS3X2Kjeqe76MrUZ60
KkTVqa+s+mq1eU+r3QoSdZoMINq9AyusM9d6B5b00y13V1nqslhcXlvnVs5h5+NpfZlbAV/E3Vld
YbxlTqd3oMm6ot6BVoxGp0lUqLXSFOJqBaq3UVU/45C658/QYeXlVSJUay3q6TEeR5V63q0aTUwD
/c540gUEmHY6h6pVoHYcVBrGNvn426tCtXgV8EFdj6aP3506yoZBXNU9UKNArcWui/ni24Qq7v+P
8FDd84Z7+F56VJc74qYG0xxP06HSVPGZUgRV0zV5bMVgYlRudS08LGxUexKi6qzET1hxWOXH3yAW
qqy73buXdqN6x039BpfUPZ+5x5WNVS6qS8FL3u12O5x7FzRMnV4i/4RWlFWM4CqjRWahKk/HAhCJ
apBDmNaJyhGplsSkdnkVQJkHd6SXd8B8J8qqV9N4sxpHdTm4eV2ysqtcG90KVBFcn/FRPY4uJS2q
HjjrTY+7JWyRtiKJPFLb3uNvNNcZrPo5KaIqTYcAaNdiop9q16+c41UO00XYjlXibHQpXeKHOrcC
IamtNe9Kl9daDFY9VOPNagxVj9Ru3cPaqXo2NtcCsAJVNKr6cz6qx0yg2sBgks/LcQcXa2Qi3BV3
yVajic5bJ++whyBtm/FQlaarCVubQGu4TyYrV11nm45MevgV6Ak32GvEZTpeeWQqv/2vSgtD5Lei
3cJyu9XieEQKlqCKanqsz6iiJqlDPdZG1JnUYLUZuH0gf/QRbMVaCDaqsnRqqFbpJhSpXmuI0qui
WmeZKvEffVRjRjRdWJ1hN0lkBarIcXq6v6hyx66k2rFmxNUybZ/5CMZBYKMqS6eGKuo/12Sp+PQI
KuBlHvNCNWn7JbCq6drShXXUOgpSVqCK4Frgo3rYPX4tHarr0QEIUw12E+jZktRzYaLPQVWSTglV
9K51NB69Dqp1ZlPpdUVEsxqgSleEKqzByU8kK1BlLVUhdUIrNyaqyyr3rsl5uMjvSQy/AgRj4wsO
qpJ0Sqiu6LZTOqjyMq9S3Uzoq6gzEoaFLTEbabGsQBVPVuF9rNpzBB1WnlvFRHWNcXtj6lIdfaB2
9Emi59JdZaRmoKqQTgVVh+6LpdJBlZs56ozCP2vBFVGpqcIUB4oRWYHq56iqXAsAbbFS+DwVqp3o
Lec/P+YDq0cfBH4uLMOWgapCOpUHuyQCjykNVKuslh+JeklxTRmG7bCg6s0BOCJqVNUnVzNRVen/
HeagytVydESDn0uDMb5goSpPp/Jg9R++Bqr8/roZNfFxLepxm35oUMUWAMdaxZsBp5sE2OA2G2rP
loUgbnG6BtKpPNg2zzhJcjnqFWAjuB7LmkpXj77bSrIDVW++6mHu8F9nxQoLVeFjU0jERBDPMqmm
T6eCalfFglG/5nSoVmPNKpWuqm1Z24KqtwkQi1Vv22qNrQAGhip+cOvp01mHKq5NW1BYS98FYAmq
2F/VswGOMNtU9WlVA0UVN5f11OnsQ7VKuzXowvBcCpnLJSJbUL3lx0U5Sn5g9aMAaqysGiSq8a85
ydLZhyp2xLb5heGxpJbLwhZUb34exPDZfWLh+DFXh9/wfrmm7KgaMKqx8UWydBaiSrs1YoXhuS6r
HXU3gDWo3vz8Y9416JE6UFRpt02ydBaiSrs14oX5U167qrDmE9XbHzC2S7l1j30JmqQOFFX8aaGW
Mh0CoFmlFXFN5Q3VRtStES8snAfeUTNZs0SV6WC6/eE9r/m8/iG9su8TVsN6T8dOHTiq2G3TSJeO
twqAnF2TN1TxPzuiwprB1P91FVgzQvUvUOHxRaYfRDm6E4OVblk1ogBmgirltkmWjocqORDLHapR
twazsEa4LFEB1oxQ/QIVTruYbscouhZbh3rrk7vXb3hHr9/V7PszQDXqtkmWjodqh6hB7lCNeos5
hZGwymzWjFBdPI9Kj34N/YQVqFcvfFr+UI1Ox06WzkoDIDodm1sYAeuK+LtwVqh+iYsnWL31KbuG
xlkdMKoRt02ydDYOqwpRt4agsBDWlnBqe1aoLv4lLv8etkZvf3QnqNIP/+pHf/3jn3z1Nz/0/tYJ
9ZtDVCNum2TpbHRWuSK8xcLCGsFuNSKLNTNUv/CdiDeuXyfh+elXfoqvf+QlSWKQ5ghVcjZKsnS2
okrMWpHc4KBlFbSrmaG6+Lcsy/Tal2SSn+GGVW+r/9yhSo4vkqWzFVXCrSG9wd5eK4KFmNmhymL1
h38XTfIz3K5qhvvLG6qE2yZZOmtRDd0a8hvs4IaVP2k4Q1QXv/hptC5//w9f00mw/1VrNkr+UCVm
oyRLZy2q4awVlRvMXGUWKktUF7/+8h+Dinz88198zUjyc3TQaLM6eFTD8UWydPaiGrg1VG6wI57E
mimqPf3Tl//8y1/+y5df/YRz/BIyAZTX+OcU1cBtkyydvagGbg2lG1yLlkopa1Slwg5YvXh/uUM1
mI2SLJ3FqPqzVpRusHiBeNYkSnUJDb5MfgcY2DJA+tTe6DZZOuuWARLyZq0ooSpe4p41iXK941ZT
Jy5VElT7sbg6ksJz28hQZaezbXF15KZht4Yaql27Uf0FqqdBH8B17ZtEPr8O6wC1XwTjuXhuGymq
zHQqGKqxoHwGo6LMtdBr0aoxaoq9xUOBKp6EpT/XTw/VtWjrILiTqhsB1RhJVqSoMtMpbwTUkbFA
qm8bAVUZSVaUUF232lZdXET17Deq5rdXo9J5696kqLLSWba9GpWuHlyRDNUG18JylTWHeUEVW/Qm
N62kn0tbDVVWOiVUl3Sb1b5tWknXdF0V1SbXJHaVNYe5QdX4VsD0cwki+nS101m2FTBzG2AFVJ11
noGFlDWHuUHV22CdDuuTfIP12HOpqaHKSKezwTodG6o5+A3WYzXtMlCtxjdXbzNuLqGsOZTrfVTP
/qPKCFuBpqYlDVsRg8BvLmWoxtMlD1tRzyJsBTtmRaQwN15LN2pu4RWsrdyFWNPQ26ie5kjVCAaE
/k4aDCgOQVMN1Xg67EbvxrRCtUyxYEC4chzrTw9VjWBA8ZdqhUYVX2Kr7YcCKlS97HI5X1VVg/kE
4KpuNMQaA4J1NVRj6bgh1qKvERFClRNiLQWqGiHW4qjGtv0h4ht2yJudy1UAqsLzVfr9YRVreT1O
Q+LAlQwI6oqo0unqqzzRVKwx0vBGWpqoqgeuZJgqbQrVkPvIm5fPtVWqwv1/v6erBLfaSDhgLgTo
AbW10xWYDzbeqhZk4YCjSd3DPNeQex9irg55OGAeqo3YXodk9G+/quKg8FmTeOXyu+cKhavnLr/P
SYBWYfd9EmAgM0HWu5zBTLUVPaCazql1WVphhSgTBVmPqh3rvwkqexVgXL00yHqL49t1e3z6wNIa
8Qp2mrK5NplyevECcafOv81K8h469unAUEWgLLkRR5eEoeqWUbTSepX9qLkuokYtAo9qOk01qs1e
5ZpVWRbLAq9Clefk8u4O51RuxSm3XzS3urSqhWxRvUzV7/yVOMw4ye30gGqgCsqlsgP10rvx2rx7
iUpzFf1sdtcKQNVOZYfqVVZ1nLcYpJpdBQioWqrMSL3gVeDavbsffXI3xOdC2LC+hTe20ojzA6hu
Y2VF6lu4+Gs+hrfv+rsCOBewM+CKbyCY3rQKULVTWaGKvkEVbhBdO7FjpXPu3Lngj4FsrwbKv7JC
FRV+I2qEfsiq4GA2rQTlXxmRivt/+hvU5zdi9TO7BxCgarEyQvUKKjyO0d3oPlbXDW8CCKharIxQ
RWErmDv8fRTu9n/H4CRVQNV6ZYkqJxjQrQ/u9vRhfzgFVK1VDlHttwBVOwWogmTauP/gYU+PHnyz
Efn9X//NVfDnYzfRwwfRc9FvT6hTCP27n4bUY2Y1AFWQWPcfPg31ZJM48h//6Sr48xuUYityMv7t
KXUKIZf0p7Q2mRUBVEEibT6jMHr4PDhGofoAJ/iGPP0J/u1x9BRAFVA1rm+fxvXCP0ih+shreMnz
tyh8AVVAtS/a+O4pSz6MFKpe4i0iA6//fxoYsIAqoNoPkaRuPYyzSqHqHyUsgCcU3IAqoNoX+aBt
PUH0bXzzyGcJt5JRVB/TjW4h6P+fPvR/AFQB1T7ohQfOo9BF9fwhSeN//bcr/5jf2RMWQPBT4AL4
n/+l9H+9HzddvfA8DK42mPUBVEFsbXhN4v3Ir+44a8vr4h9EKHwQcBlYAE+Cn55Li9uMGrUMAaog
th6wSHV//u55JIV/ILAOQgtgK/hpU1ocoAqoJpTXqMbZCdmNohqOwba8X8L+X4ggFqAKqCbUfYTO
M1GSKKrEsMizAML+P+psZQpQBVQT6hGz+48ogip2ADwhwMTt8qNnpAtg8wGloABAFVBNqGe0Oz+u
CKoYtfuPwtNwu+xNIYicQihwYwGqgGpCRUFi6kEcws37oQWA2+WNb0kXQApUrwKqVqlR7SnFblY4
Dz+DKn9brscKo6EIqrjrL2wEFgD+1yPPPbtJnpII1XOAqk1aUtrdUaJ1fzfK+uoql1V5f0yh+tAb
hQUWgNf/R3MCVIcF1XhEiwQKTq8JAgxoo+qDF1gAXv9PtLMFQHWoUG3X3F3zl1NkooTqc0TOt8KM
SFSf+6T5ZPr9v/ch4CF5CuuDAKC67VCtoo7b3eq6vtJFu/E2Vtru7ucrDv5P70C3izcxrq7g3YKr
7W4Xb3va+6VLotrFB1AWheUusRc7Iuc7YW1IVDcD3xa2ADbuBz88JHwJgOpwobqEUMXb67fRj0tu
EICmy3DT32a/6Vm2XT+UgLtB9VrEfvDMid6BGtr8fIWMlvodOXBni0Q1ZPA+4bV66k48+Tb4F6A6
VKg2q0sdFyw/lEXdJbLtRgvouqwtB8FLGjg20FoQB6sWxG+Noto7sORu/O9EAvt9KzdWSVSfBP/G
Pf/DoP+PuAAA1SFCFUcpcFwsEa8rrvG5jiAstNw4Fl235UWNbBe3kojDZRfQpgt1g0S17obO6KLw
Q85SJFIG9lZtiZpVElWilw/nreCPXRjCF8QpgOrQoOoGKMIcIs561KKefQ2xFkaJ9FAN3QbYfqCG
VejP3qn1djTC1TP2R4CNJ8GaPhJVIvH9EFXU628QIzRAdYhQbddqCCgC1V5j6Ud8WQpRbeqg2mt0
O61obCGPOGqiibuIxV8HSKBKOgw2AlIf4XSECwBQHSJUffcSgSrq/dcRrQ464AYKajsEqijo5bIA
1VikrEIwq+8hOSf/MfrxETlGIkDzGA4sAG8uCmEcAKrDiarjjoR8ztor3ngJHfCTuP9rBoHUkK+A
jWrTa5MJPfZmRm/d92Hd8D342IlFoBpBMLAAvPOehH8AqkOIas+67NTWcVhK11KtN73Yfm7gtFrH
jWfmobrsBvFtt3pnLrmhC9dIVFdqXRz1D4/LogqQ23rywl39FLSWW9hcJVAlHVKBBeD1/ySf+J8v
NgMFhi+gum1RxaEgccxANwSl00AOKi8eJeLOQ9W3XmtBAEwPVd+thbJcYQSqJAZIEd0nEUT/JN38
gQXgz0UlrAP4sDosqFZdN5UnFLvYC1bccenreAguoQNNlz4MHw5o7c5xwf/yIxzj+L7ruNtfY4WN
ZrK65fffBKpbEezwaVv+n8SYC1AdFlSjWg4m8TlV9B+HOhB84W9Uq+G/HFYWznpg4pJ6/CxGarhp
VYgq6Y8qFKIzVAqkJwtQHU5Uzamx0mL0/0gPtiJoPSOWsISoRhwABc8CCHdZCc0DQBVQTSfX9dri
zNbauB8Op55EtvkLUY1Mny5gC2ArTBh+dQVUAdV0qrZWV0TzCh9vPnjwYpPeoRev6Qv/FTpgN8gF
fv7h5wXRMsDn6E/BfgGAKsgSAaogSwSogiwRoAqyROcvZyMU7ffju5no46xvOggEAoFAIBAIBAKB
QCAQCAQCgUAgEAgEskH/D0XDgNGFpPDHAAAAbmVYSWZNTQAqAAAACAADARIAAwAAAAEAAQAAATEA
AgAAABEAAAAyh2kABAAAAAEAAABEAAAAAEFkb2JlIEltYWdlUmVhZHkAAAADoAEAAwAAAAEAAQAA
oAIABAAAAAEAAAKqoAMABAAAAAEAAAELAAAAAONl2D0AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMt
MDEtMTBUMTQ6MDc6NDErMDA6MDD49xV+AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTAxLTEwVDE0
OjA3OjQxKzAwOjAwiaqtwgAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMy0wMS0xMFQxNDowNzo0
MSswMDowMN6/jB0AAAARdEVYdGV4aWY6Q29sb3JTcGFjZQAxD5sCSQAAABJ0RVh0ZXhpZjpFeGlm
T2Zmc2V0ADY40M9KZgAAABh0RVh0ZXhpZjpQaXhlbFhEaW1lbnNpb24ANjgyZaq9zAAAABh0RVh0
ZXhpZjpQaXhlbFlEaW1lbnNpb24AMjY3EUUtZwAAAB50RVh0ZXhpZjpTb2Z0d2FyZQBBZG9iZSBJ
bWFnZVJlYWR5+ajmrAAAABJ0RVh0dGlmZjpPcmllbnRhdGlvbgAxt6v8OwAAACB0RVh0eG1wOkNy
ZWF0b3JUb29sAEFkb2JlIEltYWdlUmVhZHkq/0+gAAAAAElFTkSuQmCC"
      />
    </svg>
  )
}

export default SvgLSRE
