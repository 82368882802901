import { useEffect, useState } from 'react'

import { Formik } from 'formik'
import { connect } from 'react-redux'

import { LoadingSection } from 'app/components/design-system-components'
import {
  buildRequestPayload,
  formFields,
  formValidation,
  keySetMappedOptions,
  transformApiErrorToFields,
} from 'app/features/teams/key-logger/AddKeySetLoggerModal/DefineKeySet/form.utils'
import { FormBody } from 'app/features/teams/key-logger/AddKeySetLoggerModal/DefineKeySet/FormBody'
import { API_ERRORS_PROPERTY } from 'app/forms/FieldWrapper'
import { convertTransApiErrorToStatus } from 'app/forms/utils/convertTransApiErrorToStatus'
import * as keyLoggerHttp from 'app/services/http/teams/key-logger'
import * as keySetHttp from 'app/services/http/teams/key-logger'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'

const newKeySetInitialForm = {
  [formFields.property]: '',
  [formFields.keyIdLabel]: '',
  [formFields.keySetType]: null,
}

const generateEditKeySetInitialForm = (keySet) => {
  const { property, label, set_type } = keySet
  return {
    [formFields.property]: {
      option: property,
      optionMeta: {
        id: property.guidID,
        displayText: property.address.friendlyName,
      },
    },
    [formFields.keyIdLabel]: label,
    [formFields.keySetType]: keySetMappedOptions.find(
      ({ optionMeta }) => optionMeta.id === set_type,
    ),
  }
}

const PureDefineKeySet = ({ teamId, keySet: initialKeySet, afterSave }) => {
  const [initialValues, setInitialValues] = useState(newKeySetInitialForm)
  const [isDirty, setIsDirty] = useState(false)

  const {
    state: keySet,
    setState: setKeySet,
    loadingStates: keySetLoadingStates,
    loadingStatesHelpers: keySetLoadingStatesHelpers,
  } = useStateWithLoading(null)

  useEffect(() => {
    if (isEditMode()) loadKeySet()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!keySet) return

    const calculatedInitialValues = !isEditMode()
      ? newKeySetInitialForm
      : generateEditKeySetInitialForm(keySet)

    setInitialValues(calculatedInitialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keySet])

  const isEditMode = () => !!initialKeySet

  const markDirty = () => {
    setIsDirty(true)
  }

  const loadKeySet = () => {
    keySetLoadingStatesHelpers.startLoading()
    return keySetHttp
      .getKeySet(teamId, initialKeySet.guid, { populate_property: true })
      .then(({ key_set }) => {
        setKeySet(key_set)
        keySetLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => keySetLoadingStatesHelpers.setError(err))
  }

  const createNewKeySet = (payload) => {
    return keyLoggerHttp.createKeySet(teamId, payload)
  }

  const updateKeySet = (payload) => {
    return keyLoggerHttp.updateKeySet(teamId, keySet.guid, payload)
  }

  const saveKeySet = (values, { setStatus }) => {
    if (!isDirty) return Promise.resolve().then(() => afterSave())

    setStatus({ apiErrors: {}, apiGeneralError: '' })
    const payload = buildRequestPayload(values)

    const savePromise = !isEditMode()
      ? createNewKeySet(payload)
      : updateKeySet(payload)

    return savePromise
      .then(({ key_set: savedKeySet }) => {
        afterSave(savedKeySet)
      })
      .catch(({ message, detailedError }) => {
        if (detailedError) {
          const transformedApiErrors = transformApiErrorToFields(detailedError)
          setStatus({
            [API_ERRORS_PROPERTY]:
              convertTransApiErrorToStatus(transformedApiErrors),
          })
        } else {
          setStatus({ apiGeneralError: message })
        }
      })
  }

  return (
    <LoadingSection
      loadingState={keySetLoadingStates}
      actionHandler={loadKeySet}
      loaderProps={{ fontSize: '48px' }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={saveKeySet}
        validate={formValidation}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {(props) => (
          <FormBody formBag={props} teamId={teamId} markDirty={markDirty} />
        )}
      </Formik>
    </LoadingSection>
  )
}

const mapStateToProps = ({ session }) => ({ teamId: session.currentTeam.guid })
export const DefineKeySet = connect(mapStateToProps)(PureDefineKeySet)
