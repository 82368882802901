import { useCallback, useEffect, useRef, useState } from 'react'

import { connect } from 'react-redux'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Box, Flex } from 'app/components/design-system-components'
import { HelpOutlineRounded } from 'app/components/design-system-components/icons/actions'
import { fontSizes } from 'app/components/design-system-components/styles/variables'
import { Text } from 'app/components/design-system-components/typography'
import UpgradeButton from 'app/components/design-system-components/UpgradeButton'
import UpgradeCTA from 'app/components/design-system-components/UpgradeCTA'
import * as Form from 'app/components/forms/forms'
import {
  consoleCloudId,
  consoleCloudSettings,
  pmsBrands,
} from 'app/constants/pms.constants'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  getTeamSettings,
  updateTeamSettings,
} from 'app/services/http/teams/settings'
import * as snugNotifier from 'app/services/snugNotifier'
import { parseDataUtil } from 'app/sm/helpers'
import { isSendApplicantEnabled } from 'config/features'

const mapStateToProps = ({ session }: any) => ({
  currentTeam: session.currentTeam,
})

const IntegrationSettings = ({ currentTeam, pmsBrand, pmsStatus }: any) => {
  const [pullingData, setPullingData] = useState(false)
  const mounted = useRef(false)
  const [sendApplicantEnabled, setSendApplicantEnabled] = useState()
  const [syncTenantEnabled, setSyncTenantEnabled] = useState()
  const [syncOwnersEnabled, setSyncOwnersEnabled] = useState()
  const [syncKeysEnabled, setSyncKeysEnabled] = useState()
  const [syncPMEnabled, setSyncPMEnabled] = useState()

  const [integrationBundleEnabled, setIntegrationBundleEnabled] =
    useState(false)

  const [showSendApplicant, setShowSendApplicant] = useState(false)
  const [showSyncTenantEnabled, setShowSyncTenant] = useState(false)
  const [showSyncOwnersEnabled, setShowSyncOwners] = useState(false)
  const [showSyncKeysEnabled, setShowSyncKeys] = useState(false)
  const [showSyncPMEnabled, setShowSyncPM] = useState(false)
  const [disableSendApplicant, setDisableSendApplicant] = useState(false)

  const sendApplicantSetting = `pms_applicant_onboarding.${pmsBrand}`
  const syncTenantSetting = `pms_tenant_sync.${pmsBrand}`
  const syncOwnersSetting = `pms_owner_sync.${pmsBrand}`
  const syncPMSetting = `pms_property_manager_sync.${pmsBrand}`
  const syncKeysSetting = `pms_key_sync.${pmsBrand}`

  const brandObj =
    Object.values(pmsBrands).find(({ id }) => id === pmsBrand) || {}

  const isConsoleBrand = pmsBrand === consoleCloudId
  const { sending_tenancy_onboarding: sendingTenancyOnboarding = false } =
    pmsStatus || {}

  const brandSettings = isConsoleBrand
    ? consoleCloudSettings
    : (brandObj as any)?.settings

  const loadSettings = () => {
    getTeamSettings(currentTeam.guid, {
      keys: ['integrations', 'bundle'],
    })
      .then(({ settingInfo }: any) => {
        setPullingData(true)
        const { integrations, bundle } = settingInfo
        const integrationsSettings =
          parseDataUtil.convertObjValueFromStringToBoolean(integrations)
        const bundles = parseDataUtil.convertObjValueFromStringToBoolean(bundle)

        setIntegrationBundleEnabled((bundles as any).integrations_bundle)
        brandSettings.forEach((setting: any) => {
          const settingKey = setting as keyof typeof integrationsSettings
          if (
            sendApplicantSetting === setting &&
            isSendApplicantEnabled(pmsBrand, currentTeam.slug)
          ) {
            setShowSendApplicant(true)
            setSendApplicantEnabled(integrationsSettings[settingKey] || false)
            setDisableSendApplicant(!sendingTenancyOnboarding)
          }

          if (syncTenantSetting === setting) {
            setShowSyncTenant(true)
            setSyncTenantEnabled(integrationsSettings[settingKey] || false)
          }

          if (syncOwnersSetting === setting) {
            setShowSyncOwners(true)
            setSyncOwnersEnabled(integrationsSettings[settingKey] || false)
          }

          if (syncKeysSetting === setting) {
            setShowSyncKeys(true)
            setSyncKeysEnabled(integrationsSettings[settingKey] || false)
          }

          if (syncPMSetting === setting) {
            setShowSyncPM(true)
            setSyncPMEnabled(integrationsSettings[settingKey] || false)
          }
        })
      })
      .finally(() => setPullingData(false))
  }

  const debouncedUpdateSettings = useCallback(
    debounce(
      (pmsSettings: any) => {
        updateSettings(pmsSettings)
      },
      STANDARD_TIMEOUT,
      false,
    ),
    [],
  )

  useEffect(() => {
    loadSettings()
  }, [currentTeam])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return
    }
    if (!pullingData) {
      const pmsSettings = prepareSettings()
      debouncedUpdateSettings(pmsSettings)
    }
  }, [
    syncTenantEnabled,
    syncOwnersEnabled,
    sendApplicantEnabled,
    syncPMEnabled,
    syncKeysEnabled,
  ])

  if ((!brandObj && !isConsoleBrand) || brandSettings.length === 0) return <></>

  const prepareSettings = () => {
    let pmsSettings: { string?: string } = {}
    if (showSyncTenantEnabled && syncTenantEnabled !== undefined) {
      pmsSettings[syncTenantSetting as keyof typeof pmsSettings] = (
        syncTenantEnabled as boolean
      ).toString()
    }

    if (showSyncOwnersEnabled && syncOwnersEnabled !== undefined) {
      pmsSettings[syncOwnersSetting as keyof typeof pmsSettings] = (
        syncOwnersEnabled as boolean
      ).toString()
    }

    if (showSendApplicant && sendApplicantEnabled !== undefined) {
      pmsSettings[sendApplicantSetting as keyof typeof pmsSettings] = (
        sendApplicantEnabled as boolean
      ).toString()
    }

    if (showSyncPMEnabled && syncPMEnabled !== undefined) {
      pmsSettings[syncPMSetting as keyof typeof pmsSettings] = (
        syncPMEnabled as boolean
      ).toString()
    }

    if (showSyncKeysEnabled && syncKeysEnabled !== undefined) {
      pmsSettings[syncKeysSetting as keyof typeof pmsSettings] = (
        syncKeysEnabled as boolean
      ).toString()
    }
    return pmsSettings
  }
  const updateSettings = (pmsSettings: any) => {
    return updateTeamSettings(
      currentTeam.guid,
      null,
      null,
      null,
      null,
      pmsSettings,
    )
      .then(() =>
        snugNotifier.success('Settings have been saved successfully.'),
      )
      .catch(({ message }: any) => snugNotifier.error(message))
  }

  return (
    <Box my={8}>
      {(showSyncTenantEnabled || showSyncOwnersEnabled) && (
        <>
          <Text as="h4" fontSize={fontSizes[8] + 'px'} mb={5} mt={8}>
            Free Forever Plan
          </Text>

          {showSyncTenantEnabled && (
            <Flex alignItems="center">
              <Form.CheckBoxGeneral
                label="Tenant sync"
                checked={syncTenantEnabled}
                onChange={({ value }: any) => setSyncTenantEnabled(value)}
                eventProcessedByComponent={false}
                id="tenant-sync"
              />
              <Box
                color={theme.colors.gray500}
                fontSize={theme.fontSizes.pLarge16}
                style={{ zIndex: 2 }}
                mb={3}
              >
                <HelpOutlineRounded
                  data-tooltip-id="synTenantInfo"
                  data-tooltip-content=""
                />
                <ReactTooltip id="synTenantInfo">
                  Enable automated entry notices
                </ReactTooltip>
              </Box>
            </Flex>
          )}

          {showSyncOwnersEnabled && (
            <Flex alignItems="center">
              <Form.CheckBoxGeneral
                label="Owner sync"
                checked={syncOwnersEnabled}
                onChange={({ value }: any) => setSyncOwnersEnabled(value)}
                eventProcessedByComponent={false}
                id="owner-sync"
              />
              <Box
                color={theme.colors.gray500}
                fontSize={theme.fontSizes.pLarge16}
                style={{ zIndex: 2 }}
                mb={3}
              >
                <HelpOutlineRounded
                  data-tooltip-id="syncOwnersInfo"
                  data-tooltip-content=""
                />
                <ReactTooltip id="syncOwnersInfo">
                  Streamline sharing application details and viewing reporting
                </ReactTooltip>
              </Box>
            </Flex>
          )}
        </>
      )}

      <Box mb={7}>
        {(showSendApplicant || showSyncPMEnabled || showSyncKeysEnabled) && (
          <>
            <Flex mb={5} mt={8} alignItems="baseline">
              <Text as="h4" fontSize={fontSizes[8] + 'px'}>
                Integration Bundle
              </Text>
              {!integrationBundleEnabled && <UpgradeButton ml={5} />}
            </Flex>

            {!integrationBundleEnabled && (
              <UpgradeCTA alignRight={true} availableOn="Integrations Bundle" />
            )}

            {showSendApplicant && (
              <Flex alignItems="center">
                <div
                  data-tooltip-id={'sendApplicantDisabledInfo'}
                  data-tooltip-content=""
                >
                  <Form.CheckBoxGeneral
                    label="Approved applicant onboarding"
                    checked={sendApplicantEnabled && !disableSendApplicant}
                    disabled={!integrationBundleEnabled || disableSendApplicant}
                    showDisabledGrey={true}
                    onChange={({ value }: any) =>
                      setSendApplicantEnabled(value)
                    }
                    eventProcessedByComponent={false}
                    id="applicant-onboarding"
                  />
                </div>
                {disableSendApplicant && (
                  <ReactTooltip id="sendApplicantDisabledInfo">
                    A team admin can Deactivate and Activate the integration to
                    enable this option
                  </ReactTooltip>
                )}

                <Box
                  color={theme.colors.gray500}
                  fontSize={theme.fontSizes.pLarge16}
                  style={{ zIndex: 2 }}
                  mb={3}
                >
                  <HelpOutlineRounded
                    data-tooltip-id={'sendApplicantInfo'}
                    data-tooltip-content=""
                  />
                  <ReactTooltip id="sendApplicantInfo">
                    Automatically send approved applicant details
                  </ReactTooltip>
                </Box>
              </Flex>
            )}

            {showSyncPMEnabled && (
              <Flex alignItems="center">
                <Form.CheckBoxGeneral
                  label="Property manager sync"
                  checked={syncPMEnabled}
                  disabled={!integrationBundleEnabled}
                  showDisabledGrey={!integrationBundleEnabled}
                  onChange={({ value }: any) => setSyncPMEnabled(value)}
                  eventProcessedByComponent={false}
                  id="pm-sync"
                />
                <Box
                  color={theme.colors.gray500}
                  fontSize={theme.fontSizes.pLarge16}
                  style={{ zIndex: 2 }}
                  mb={3}
                >
                  <HelpOutlineRounded
                    data-tooltip-id="syncPMInfo"
                    data-tooltip-content=""
                  />
                  <ReactTooltip id="syncPMInfo">
                    Synchronise Property Manager and assign to properties
                  </ReactTooltip>
                </Box>
              </Flex>
            )}

            {showSyncKeysEnabled && (
              <Flex alignItems="center">
                <Form.CheckBoxGeneral
                  label="Key sync"
                  checked={syncKeysEnabled}
                  disabled={!integrationBundleEnabled}
                  showDisabledGrey={!integrationBundleEnabled}
                  onChange={({ value }: any) => setSyncKeysEnabled(value)}
                  eventProcessedByComponent={false}
                  id="key-sync"
                />
                <Box
                  color={theme.colors.gray500}
                  fontSize={theme.fontSizes.pLarge16}
                  style={{ zIndex: 2 }}
                  mb={3}
                >
                  <HelpOutlineRounded
                    data-tooltip-id="syncKeysInfo"
                    data-tooltip-content=""
                  />
                  <ReactTooltip id="syncKeysInfo">
                    Automatically synchronise keys for properties
                  </ReactTooltip>
                </Box>
              </Flex>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default connect(mapStateToProps)(IntegrationSettings)
