import { errorResponseHandler } from 'app/utils/api/helpers'

export const mapLegacyAccessPromiseToServiceFnPromise = (promise) => {
  return promise
    .then(({ ok, ...rest }) => {
      return ok ? Promise.resolve(rest) : Promise.reject(rest)
    })
    .catch(({ responseText }) => {
      return responseText.then((error) => {
        const parsedErr = JSON.parse(error)
        return errorResponseHandler({ response: { data: parsedErr } })
      })
    })
}
