import { useEffect, useReducer } from 'react'

import { AddressBorrowerSelection } from 'app/features/teams/key-logger/CheckoutKeySetModal/AddressBorrowerSelection'
import { BorrowingDetails } from 'app/features/teams/key-logger/CheckoutKeySetModal/BorrowingDetails'
import {
  actions,
  checkOutStateReducer,
  checkOutStepsConfig,
  checkOutStepsConfigArr,
} from 'app/features/teams/key-logger/CheckoutKeySetModal/cmp-state.reducer'
import { StepsModalContainer } from 'app/features/teams/key-logger/components/StepsModalContainer'
import { StepsModalHeader } from 'app/features/teams/key-logger/components/StepsModalHeader'

export const CheckoutKeySetModal = ({
  closeModal: closeModalCb,
  keySet: initialKeySet,
  bulkKeySets,
  bulkCheckoutCommentCmp,
  bulkCheckoutSelectedCmp,
  afterCheckOutAction,
  borrower,
  isBorrowerDisabled,
}) => {
  const [cmpState, cmpStateDispatch] = useReducer(checkOutStateReducer, {
    checkOutData: null,
    checkedOut: false,
    currentStep: checkOutStepsConfigArr[0],
    showCurrentStep: true,
    closeModal: false,
  })

  useEffect(() => {
    if (!initialKeySet && !bulkKeySets?.length) return

    cmpStateDispatch(
      actions.setInitialCheckOutData(
        initialKeySet,
        bulkKeySets,
        borrower,
        isBorrowerDisabled,
      ),
    )
  }, [initialKeySet, bulkKeySets, borrower, isBorrowerDisabled])

  useEffect(() => {
    let resultant
    if (cmpState.checkedOut) {
      resultant =
        cmpState.checkOutData.keySet || cmpState.checkOutData.bulkKeySets
    }
    if (cmpState.closeModal) {
      closeModalCb(resultant)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmpState.closeModal])

  const closeModal = () => cmpStateDispatch(actions.closeModal())

  const onBack = () => {
    const { index } = cmpState.currentStep
    if (index < 1) return closeModal()
    cmpStateDispatch(actions.backStep())
  }

  const onNext = () => {
    const { index } = cmpState.currentStep
    if (index === checkOutStepsConfigArr.length - 1) {
      closeModal()
    } else {
      cmpStateDispatch(actions.nextStep())
    }
  }

  const proceed = (checkedOutData, upsert = true) => {
    cmpStateDispatch(actions.stepSaved(checkedOutData, upsert))
    onNext()
  }

  const afterCheckOut = (checkOutKeySetGuid) => {
    cmpStateDispatch(actions.setCheckedOut())
    cmpStateDispatch(actions.closeModal())
    afterCheckOutAction('checkout', checkOutKeySetGuid)
  }

  const { currentStep, checkOutData } = cmpState

  if (!cmpState.currentStep.def) return null

  const progressBarConfig = {
    totalStepsCount: checkOutStepsConfigArr.length,
    lastCheckedStep: currentStep.index + 1,
  }

  const modalContent = (
    <>
      {currentStep.def.id ===
        checkOutStepsConfig.addressBorrowerSelection.id && (
        <AddressBorrowerSelection
          checkOutData={checkOutData}
          proceed={proceed}
          bulkCheckoutCommentCmp={bulkCheckoutCommentCmp}
          bulkCheckoutSelectedCmp={bulkCheckoutSelectedCmp}
        />
      )}

      {currentStep.def.id === checkOutStepsConfig.borrowingDetails.id && (
        <BorrowingDetails
          checkOutData={checkOutData}
          afterSave={afterCheckOut}
        />
      )}
    </>
  )

  return (
    <StepsModalContainer takeMinHeight>
      <StepsModalHeader
        title="Check out"
        progressBarConfig={progressBarConfig}
        actions={{ onDismiss: closeModal, onBack }}
      >
        {currentStep.def.subHeading}
      </StepsModalHeader>
      {modalContent}
    </StepsModalContainer>
  )
}
