import React from 'react'

import moment from 'moment'
import qs from 'qs'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import addIcon from 'app/assets/icons/add-sign.png'
import circleCross from 'app/assets/icons/circle-cross.png'
import removeIcon from 'app/assets/icons/cross-sign.png'
import {
  Box,
  Flex,
  TextSpan,
  WhiteBGButton,
} from 'app/components/design-system-components'
import { Lightning } from 'app/components/design-system-components/icons/snug-specific'
import { Text } from 'app/components/design-system-components/typography'
import * as Form from 'app/components/forms/forms'
import ErrorMessages from 'app/constants/error_messages'
import { SELECTOR_SHARE_WITH_ACCEPT_LINK } from 'app/dashboard/share_with_owner'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import {
  AllSearchResultsContainer,
  FilteredSearchResultsRow,
  NO_RESULTS_FOUND_TEXT,
  SearchResultContainer,
} from 'app/match/propertyReport/container'
import * as snugNotifier from 'app/services/snugNotifier'
import { history } from 'app/shared_components/router'
import { ApplicationRowContainer } from 'app/shared_components/sending_email'
import * as validations from 'app/shared_components/validations'
import Spinner from 'app/sm/common/spinner'
import {
  getWeeklyRentDisplay,
  NO_IMAGE_PLACEHOLDER_URL,
  urlIds,
  urlTo,
} from 'app/sm/helpers'
import RequestBGCOptionLabel from 'app/sm/remarketing/request_bgc_optional_label'
import {
  isOwnerOptInForBGCEnabled,
  isShareApplicationWithOwnerEnabled,
} from 'config/features'

export const ErrorContainer = ({ error }) => (
  <div className="alert alert-danger">{error}</div>
)

export const AttachedPropertiesContainer = ({
  attachedProperties,
  onRemoveButtonClick,
  isPropertyAttached,
}) => {
  return (
    <div>
      <h5 className="mb10 mt10">Attached Properties: </h5>
      {attachedProperties === undefined ||
        (attachedProperties.length === 0 && <h6>No properties attached</h6>)}
      {attachedProperties &&
        attachedProperties.length > 0 &&
        attachedProperties.map((propertyData, index) => {
          return (
            attachedProperties && (
              <PropertyItem
                propertyItem={propertyData}
                weeklyRent={propertyData.offers[0].weeklyRent}
                availableFrom={propertyData.offers[0].availableFrom}
                key={propertyData && propertyData.guidID}
                onRemoveButtonClick={onRemoveButtonClick}
                showRemoveButton={true}
                showAddButton={false}
                isPropertyAttached={isPropertyAttached}
              />
            )
          )
        })}
    </div>
  )
}

const RecommendedPropertiesContainer = (recommendedPropertyParams) => {
  const {
    recommendedProperties,
    onAddButtonClick,
    src,
    isPropertyAttached,
    hideRecommended,
  } = recommendedPropertyParams

  return (
    <div>
      {!hideRecommended &&
        recommendedProperties &&
        recommendedProperties.length > 0 &&
        recommendedProperties.map((propertyData, index) => {
          return (
            <div>
              <PropertyItem
                propertyItem={propertyData}
                weeklyRent={propertyData.offers[0].weeklyRent}
                availableFrom={propertyData.offers[0].availableFrom}
                onAddButtonClick={onAddButtonClick}
                src={src}
                showRemoveButton={false}
                showAddButton={true}
                key={index}
                isPropertyAttached={isPropertyAttached}
              />
            </div>
          )
        })}
    </div>
  )
}

export const SearchedPropertyResults = ({
  propertySearchResults,
  onAddButtonClick,
  hideResults,
  isPropertyAttached,
}) => {
  return (
    <div>
      {!hideResults &&
        propertySearchResults &&
        propertySearchResults.data &&
        propertySearchResults.data
          .filter((propertyData) => propertyData.property.offers.length > 0)
          .map((propertyData, index) => {
            return (
              <PropertyItem
                key={index}
                propertyItem={propertyData.property}
                weeklyRent={
                  propertyData.property &&
                  propertyData.property.offers &&
                  propertyData.property.offers.length > 0 &&
                  propertyData.property.offers[0].weeklyRent
                }
                availableFrom={
                  propertyData.property &&
                  propertyData.property.offers &&
                  propertyData.property.offers[0].availableFrom
                }
                onAddButtonClick={onAddButtonClick}
                showRemoveButton={false}
                showAddButton={true}
                isBtnDisplayToggled={true}
                isPropertyAttached={isPropertyAttached}
              />
            )
          })}
    </div>
  )
}

const PropertyItem = ({
  propertyItem,
  weeklyRent,
  availableFrom,
  onAddButtonClick,
  onRemoveButtonClick,
  src,
  showAddButton,
  showRemoveButton,
  isPropertyAttached,
}) => {
  const {
    guidID,
    images,
    address: { friendlyName },
    bathrooms,
    bedrooms,
    parking,
  } = propertyItem
  return (
    <div className="mb40">
      <div className="display-flex overview-property-info space-between">
        <div className="display-flex">
          <div
            className="team-image relative"
            style={{
              backgroundImage: `url("${
                images[0] ? images[0].URL : NO_IMAGE_PLACEHOLDER_URL
              }")`,
            }}
          ></div>
          <Flex flexDirection="column" justifyContent="center" pl={3}>
            <div className="font-size-18 mb10">
              <strong>{friendlyName}</strong>
            </div>
            <ul className="amenities-widget mb5">
              <li className="font-size-14 pr10">
                <i className="icon-bedroom"></i>
                <span>{bedrooms}</span>
              </li>
              <li className="font-size-14 pr10">
                <i className="icon-bathroom"></i>
                <span>{bathrooms}</span>
              </li>
              <li className="font-size-14 pr10">
                <i className="icon-cars"></i>
                <span>{parking}</span>
              </li>
            </ul>
            <div className="mb5 font-size-14">
              {getWeeklyRentDisplay(weeklyRent)}
            </div>
            <div className="font-size-14">
              Avail:{' '}
              {availableFrom
                ? moment(availableFrom).format('ddd DD MMM')
                : 'N/A'}
            </div>
          </Flex>
        </div>
        <div className="display-flex justify-content-flex-end align-items-center">
          {showAddButton && !isPropertyAttached(guidID) && (
            <AddPropertyButton
              onAddButtonClick={onAddButtonClick}
              guidID={guidID}
              src={src}
            />
          )}
          {showRemoveButton && (
            <RemovePropertyButton
              onRemoveButtonClick={onRemoveButtonClick}
              guidID={guidID}
              src={src}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export const LoadMore = ({ offset, loadMore }) => (
  <button
    className="textButton wa ha btnmr mt10 mb10"
    onClick={() => loadMore(offset)}
  >
    Load more
  </button>
)

const RemovePropertyButton = ({ onRemoveButtonClick, guidID, src }) => {
  return (
    <div className="removeIcon">
      <img src={removeIcon} onClick={() => onRemoveButtonClick(guidID)}></img>
    </div>
  )
}

const AddPropertyButton = ({ onAddButtonClick, guidID, src }) => {
  return (
    <div className="addIcon">
      <img src={addIcon} onClick={() => onAddButtonClick(guidID)}></img>
    </div>
  )
}

const BasicMessage = (basicMessageArgs) => {
  const {
    message,
    validate,
    updateMessage,
    errors,
    isDisabled,
    isMessageOptional,
    emailOnchange,
    showEmailResults = false,
    filteredSearchResults,
    emailOnBlur,
    filteredItemSelected,
    emailList = [],
    removeEmailFromList,
    emailKeyDown,
    emailOnFocus,
    alreadyHasBackgroundCheck,
  } = basicMessageArgs

  return (
    <div>
      <div className="input-box">
        <Flex flexWrap="wrap" mt={5}>
          {emailList &&
            emailList.length > 0 &&
            emailList.map((emailItem, index) => {
              return (
                <Flex
                  className={
                    emailItem.status === 'invalid'
                      ? 'invalid-email email-icon'
                      : 'background-grey-mobile-excluded email-icon'
                  }
                  p={3}
                  mr={3}
                  fontWeight={theme.fontWeights[4]}
                  fontSize={theme.fontSizes.pLarge16}
                >
                  <div>{emailItem.text}</div>
                  <img
                    className="email-remove"
                    onClick={() => removeEmailFromList(index)}
                    src={circleCross}
                    alt="remove"
                    role="button"
                    tabIndex="0"
                  />
                </Flex>
              )
            })}
          <input
            type="text"
            placeholder="Add email"
            onBlur={
              emailOnBlur
                ? (event) => emailOnBlur(event.target.value)
                : () => validate('recipientAddress')
            }
            onChange={
              emailOnchange
                ? (event) => emailOnchange(event.target.value)
                : (event) => updateMessage(event, 'recipientAddress')
            }
            onFocus={emailOnFocus}
            value={message && message.recipientAddress}
            onKeyDown={emailKeyDown ? (event) => emailKeyDown(event) : null}
          ></input>
        </Flex>

        <label className="fixedLabelStyle">To</label>
        {showEmailResults && (
          <AllSearchResultsContainer>
            {filteredSearchResults && filteredSearchResults.length > 0 ? (
              filteredSearchResults.map((item, index) => {
                return (
                  <div
                    key={index}
                    onMouseDown={() => filteredItemSelected(item)}
                  >
                    <FilteredSearchResultsRow item={item} />
                  </div>
                )
              })
            ) : (
              <SearchResultContainer>
                {NO_RESULTS_FOUND_TEXT}
              </SearchResultContainer>
            )}
          </AllSearchResultsContainer>
        )}
      </div>

      <div
        className={
          errors && errors.recipientAddress
            ? 'alert alert-danger'
            : 'hide-alert'
        }
      >
        <div>{errors && errors.recipientAddress}</div>
      </div>
      <div className="input-box">
        <textarea
          type="text"
          placeholder="Add message body"
          value={message && message.body}
          multiple="true"
          onBlur={validate('body')}
          onChange={(event) => updateMessage(event, 'body')}
          rows="2"
        ></textarea>
        <label>Message {isMessageOptional && ' (optional) '}</label>
      </div>
      <div
        className={errors && errors.body ? 'alert alert-danger' : 'hide-alert'}
      >
        <div>{errors && errors.body}</div>
      </div>
    </div>
  )
}

const shareWithAcceptSelected = (selectShareWitOwnerOption) => {
  const shareWithAcceptLinkOption = selectShareWitOwnerOption.filter(
    (option) => option.value === SELECTOR_SHARE_WITH_ACCEPT_LINK,
  )
  return shareWithAcceptLinkOption[0]['picked']
}

export const Message = (messageArgs) => {
  const {
    message,
    validate,
    updateMessage,
    showSpinner,
    errors,
    isDisabled,
    attachedProperties,
    onRemoveButtonClick,
    isPropertyAttached,
    application,
    isMessageOptional = false,
    isCheckedWithOwner,
    requestBGCApproval,
    updateRequestBGCApproval,
    updateApplicationStatus,
    showManagerEditedSummary = false,
    selectShareWitOwnerOption,
    allowBGCOption,
    isEquifaxActivated,
    isLiteAccount,
    bgcPrice,
    onChangeMultiSelect,
    teamSlug = '',
    emailOnchange,
    showEmailResults,
    filteredSearchResults,
    emailOnBlur,
    filteredItemSelected,
    emailList,
    removeEmailFromList,
    emailKeyDown,
    emailOnFocus,
    alreadyHasBackgroundCheck,
  } = messageArgs
  const isMultiSelectEnabled = isShareApplicationWithOwnerEnabled(teamSlug)
  const isShareWithApprovalLinkSelected =
    !!selectShareWitOwnerOption &&
    shareWithAcceptSelected(selectShareWitOwnerOption)
  const shareWithAcceptMessage =
    'The recipient will be prompted to Approve or Decline the application.'

  const disableRequestBGCOption =
    !isEquifaxActivated || !allowBGCOption || alreadyHasBackgroundCheck

  const checkboxTooltip = alreadyHasBackgroundCheck ? (
    <Box>
      <i
        className="icon-help-outline fs16 gray-light-color ibm mt-4"
        data-tooltip-content=""
        data-tooltip-id="alreadyHasBGC"
      />
    </Box>
  ) : null
  return (
    <div>
      <BasicMessage
        message={message}
        validate={validate}
        updateMessage={updateMessage}
        isDisabled={isDisabled}
        errors={errors}
        isMessageOptional={isMessageOptional}
        emailOnchange={emailOnchange}
        showEmailResults={showEmailResults}
        filteredSearchResults={filteredSearchResults}
        emailOnBlur={emailOnBlur}
        filteredItemSelected={filteredItemSelected}
        emailList={emailList}
        removeEmailFromList={removeEmailFromList}
        emailKeyDown={emailKeyDown}
        emailOnFocus={emailOnFocus}
      />
      <div>
        {attachedProperties && (
          <AttachedPropertiesContainer
            attachedProperties={attachedProperties}
            onRemoveButtonClick={onRemoveButtonClick}
            isPropertyAttached={isPropertyAttached}
          />
        )}

        {application && (
          <ApplicationRowContainer
            application={application}
            showManagerEditedSummary={showManagerEditedSummary}
          />
        )}
        {selectShareWitOwnerOption && isMultiSelectEnabled && (
          <Box mt={theme.baseSpace * 3 + 'px'}>
            <Form.MultiSelect
              title={null}
              options={selectShareWitOwnerOption}
              className="p0"
              onChange={onChangeMultiSelect}
              bottomSpacingClass="p0"
            />

            {isShareWithApprovalLinkSelected && (
              <Box mb={theme.baseSpace + 'px'} mt={theme.baseSpace * 2 + 'px'}>
                {shareWithAcceptMessage}
              </Box>
            )}
            <Box mb={theme.baseSpace + 'px'} mt={theme.baseSpace * 2 + 'px'}>
              Please note confirmation of the tenancy is subject to final
              checks, receipt of funds and tenancy agreement being completed by
              the applicant.
            </Box>
          </Box>
        )}
        {selectShareWitOwnerOption && (
          <Box mt={theme.baseSpace * 3 + 'px'}>
            <Form.CheckBoxGeneral
              label={`Update application to "With Owner" status*`}
              checked={isCheckedWithOwner}
              onChange={updateApplicationStatus}
              eventProcessedByComponent
            />
          </Box>
        )}
        {isShareWithApprovalLinkSelected &&
          isOwnerOptInForBGCEnabled(teamSlug) && (
            <Flex alignItems="baseline" mt={theme.baseSpace * 3 + 'px'}>
              <Form.CheckBoxGeneral
                label={
                  <RequestBGCOptionLabel
                    disableRequestBGCOption={disableRequestBGCOption}
                    bgcPrice={bgcPrice}
                    teamSlug={teamSlug}
                    alreadyHasBackgroundCheck={alreadyHasBackgroundCheck}
                  />
                }
                checked={requestBGCApproval}
                onChange={updateRequestBGCApproval}
                disabled={disableRequestBGCOption}
                componentClassName="snug-label"
                showDisabledGrey={true}
                eventProcessedByComponent
                toolTip={checkboxTooltip}
              />
              <div>
                <ReactTooltip id="alreadyHasBGC" data-type="info">
                  <div>Applicant already has a background check</div>
                </ReactTooltip>
              </div>
            </Flex>
          )}
      </div>
    </div>
  )
}
export const PropertySearchAndDropDown = (propertySearchArgs) => {
  const { searchText, onSearchChange, onInputBlur } = propertySearchArgs
  return (
    <div>
      <h5>Search and Add Properties:</h5>
      <div className="property-search-filter-widget mt10">
        <div className="input-box">
          <div className="prefix">
            <i className="icon-search"></i>
          </div>
          <input
            type="text"
            placeholder="Search street name or suburb"
            value={searchText}
            onChange={onSearchChange}
          />
        </div>
      </div>
    </div>
  )
}

class RemarketProperty extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
      errors: {},
      recommendedProperties: [],
      showingToast: false,
      cannotSend: true,
      showSpinner: false,
      limit: 3,
      apiError: '',
      message: {},
      attachedProperties: [],
      hideResults: false,
      response_metadata: {},
      hideRecommended: false,
      messageSent: '',
      tableSpinner: false,
    }
  }

  componentDidMount() {
    const agencyGUID =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.agencyGUID
    const propertyGUID =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.propertyGUID
    const primaryApplicantEmailAddress = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).email

    this.setState({
      agencyGUID: agencyGUID,
      propertyGUID: propertyGUID,
      primaryApplicantEmailAddress,
    })

    const limit = 3
    const { message } = this.state
    message.recipientAddress = primaryApplicantEmailAddress
    this.setState({
      message,
    })

    this.onFetchRecommendedProperties(
      agencyGUID,
      propertyGUID,
      limit,
      (recommendedProperties) => {
        this.setState({
          recommendedProperties: recommendedProperties,
          showSpinner: false,
        })
      },
      (responseText) => {
        this.setState({
          apiError: responseText,
        })
      },
    )
  }

  onAddItemIntoAttachedPropsOnClick = (guidID) => {
    const { attachedProperties, recommendedProperties } = this.state

    let selectedProperty = undefined
    for (var i = 0; i < recommendedProperties.length; i++) {
      if (recommendedProperties[i].guidID == guidID) {
        selectedProperty = recommendedProperties[i]

        var hasProperty = attachedProperties.some(function (p) {
          return p.guidID === guidID
        })

        let propertyExists = false
        if (hasProperty) {
          propertyExists = true
        }

        if (!propertyExists) {
          attachedProperties.push(selectedProperty)
        }
        break
      }
    }

    this.setState({
      attachedProperties,
    })
  }

  onAddSearchResultPropertiesToAttached = (guidID) => {
    const { attachedProperties, propertySearchResults } = this.state

    if (propertySearchResults === undefined) {
      return
    }

    if (propertySearchResults.data === undefined) {
      return
    }

    var data = propertySearchResults.data

    let selectedProperty = undefined
    for (var i = 0; i < data.length; i++) {
      if (data[i].property.guidID == guidID) {
        selectedProperty = data[i].property

        var hasProperty = attachedProperties.some(function (p) {
          return p.guidID === guidID
        })

        let propertyExists = false
        if (hasProperty) {
          propertyExists = true
        }

        if (!propertyExists) {
          attachedProperties.push(selectedProperty)
        }
        break
      }
    }

    this.setState({
      attachedProperties,
    })
  }

  onFailure(statusCode, responseText) {
    this.setState({
      apiError: responseText,
    })
  }

  onFetchFailure = ({ responseMessage }) => {
    this.setState = {
      errors: responseMessage,
    }
  }

  onFetchRecommendedProperties = (
    agencyGUID,
    propGUID,
    limit,
    onFetchSuccess,
    onFetchFailure,
  ) => {
    this.props.fetchRecommendedProperties(
      agencyGUID,
      propGUID,
      limit,
      onFetchSuccess,
      onFetchFailure,
    )
  }

  onFetchSuccess = (recommendedProperties) => {
    this.state = {
      recommendedProps: recommendedProperties,
    }
  }

  onInputBlur = () => {
    const { searchText } = this.state

    if (searchText === undefined && searchText.length === 0) {
      this.setState({
        hideResults: true,
        hideRecommended: false,
      })
    } else {
      this.setState({
        hideResults: false,
        hideRecommended: true,
      })
    }
  }

  onMessageSent = (responseText) => {
    this.setState({
      messageSent: 'message sent',
    })
  }

  onRemoveItemFromAttachedPropsOnClick = (guidID) => {
    const { attachedProperties } = this.state

    for (var i = 0; i < attachedProperties.length; i++) {
      if (attachedProperties[i].guidID == guidID) {
        attachedProperties.splice(i, 1)
        break
      }
    }

    this.setState({
      attachedProperties,
    })
  }

  onSearchChange = (event) => {
    const { agencyGUID } = this.state
    const { value } = event.target
    clearInterval(this.propertySearch)

    if (value === undefined || value.trim() == '') {
      this.state.propertySearchResults = ''
      this.state.propertyCount = 0
      this.state.hideResults = true
      this.state.hideRecommended = false
    }

    this.setState({ searchText: value }, () => {
      const {
        searchText = '',
        manager = '',
        applicationYesNoFilter = 'any',
      } = this.state
      this.propertySearch = setTimeout(
        () =>
          searchText &&
          searchText.trim() !== '' &&
          this.callFetchTeamProperties(agencyGUID, {
            searchText,
            manager,
            status: 3,
          })
            .then(({ properties = {} }) => {
              this.setState({
                propertySearchResults: properties,
                propertyCount: properties.response_metadata.total,
                hideResults: false,
                hideRecommended: true,
              })
            })
            .catch((apiError) => {
              this.setState({
                apiError,
              })
            }),
        500,
      )
    })
  }

  onSendMessage = (message, messageSent, failure) => {
    const { errors, attachedProperties } = this.state
    var array = []
    for (var i = 0; i < attachedProperties.length; i++) {
      array.push(attachedProperties[i].guidID)
    }

    message.attachedProperties = array

    this.setState({ showSpinner: true })
    this.props
      .sendMessage(message, messageSent, failure)
      .then(() => {
        // history.goBack()
        snugNotifier.success('Successfully invited to property')
      })
      .catch((responseText) => {
        this.setState({
          apiError: ErrorMessages[parseInt(responseText)],
          showSpinner: false,
        })
      })
  }

  setValidationErrors(field, error) {
    let { errors } = this.state
    if (error && error.length == 0) {
      // No validation errors
      delete errors[field]
      this.setState({ errors })
      return true
    } else {
      // Validation errors
      errors[field] = error
      this.setState({ errors: errors })
      return false
    }
  }

  callFetchTeamProperties = (
    agencyGUID,
    { searchText, manager, status, offset, loadMore = false },
  ) => {
    const { fetchTeamProperties } = this.props
    loadMore
      ? this.setState({ loadMoreSpinner: true })
      : this.setState({ tableSpinner: true })
    return fetchTeamProperties(agencyGUID, {
      searchText,
      manager,
      status,
      offset,
      loadMore,
    })
      .then(({ properties }) => {
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
        return Promise.resolve({ properties })
      })
      .catch((apiError) => {
        this.setState({ apiError })
        loadMore
          ? this.setState({ loadMoreSpinner: false })
          : this.setState({ tableSpinner: false })
        return Promise.reject(apiError)
      })
  }

  isDisabled = (message) => {
    const minBodyLength = 5

    if (message === undefined) {
      return true
    }

    if (message.body === undefined || message.recipientAddress === undefined) {
      return true
    }

    if (
      message.body.trim().length < minBodyLength ||
      message.recipientAddress.trim().length === 0
    ) {
      return true
    }

    if (this.state.showSpinner) {
      return true
    }

    if (
      this.state.errors &&
      this.state.errors.recipientAddress &&
      this.state.errors.recipientAddress.length > 0
    ) {
      return true
    }

    if (
      this.state.errors &&
      this.state.errors.body &&
      this.state.errors.body.length > 0
    ) {
      return true
    }

    if (
      this.state.errors &&
      this.state.errors.attachedProperties &&
      this.state.errors.attachedProperties.length > 0
    ) {
      return true
    }

    if (
      this.state.attachedProperties === undefined ||
      this.state.attachedProperties.length == 0
    ) {
      return true
    }

    return false
  }

  isPropertyAttached = (guidID) => {
    const { attachedProperties } = this.state

    var hasProperty = attachedProperties.some(function (p) {
      return p.guidID === guidID
    })

    return hasProperty ? true : false
  }

  loadMore = (offset) => {
    const { manager = '', searchText = '' } = this.state
    const { agencyGUID } = this.state
    const loadMore = true
    this.callFetchTeamProperties(agencyGUID, {
      searchText,
      manager,
      status: 3,
      offset,
      loadMore,
    })
      .then(({ properties }) => {
        const previousProperties = this.state.propertySearchResults.data
        const updatedProperties = previousProperties.concat(properties.data)
        this.setState({
          propertySearchResults: {
            data: updatedProperties,
            response_metadata: properties.response_metadata,
          },
        })
      })
      .catch((apiError) => {
        this.setState({
          apiError,
        })
      })
  }

  reactToastify(category, message) {
    if (category === 'success') {
      this.state.showingToast === false &&
        snugNotifier.success(message || 'success', {
          onOpen: () => this.setState({ showingToast: true }),
          onClose: () => this.setState({ showingToast: false }),
        })
    } else if (category === 'fail') {
      this.state.showingToast === false &&
        snugNotifier.error(message || 'failed', {
          onOpen: () => this.setState({ showingToast: true }),
          onClose: () => this.setState({ showingToast: false }),
        })
    }
  }

  updateMessage = (event, field) => {
    const { value } = event.target
    const { message } = this.state

    switch (field) {
      case 'recipientAddress':
        message.recipientAddress = value
        break
      case 'body':
        message.body = value
        break
    }

    this.setState({
      message: message,
      unsavedChanges: true,
    })
  }

  validate = (field) => {
    return () => {
      const { message, attachedProperties } = this.state

      switch (field) {
        case 'body':
          return this.setValidationErrors(
            field,
            validations.validateMessageBody(
              message && message.body,
              'Please enter a valid message body',
            ),
          )
        case 'recipientAddress':
          return this.setValidationErrors(
            field,
            validations.validateEmailAddresses(
              message.recipientAddress,
              'Invalid email address',
            ),
          )
        case 'attachedProperties':
          return (
            attachedProperties &&
            attachedProperties.length > 0 &&
            this.setValidationErrors(
              field,
              'Message should have property attachments',
            )
          )
      }
    }
  }

  render() {
    const {
      agencyGUID,
      propertyGUID,
      message,
      errors,
      searchText = '',
      apiError,
      propertySearchResults,
      loadMoreSpinner,
      attachedProperties,
      recommendedProperties,
      primaryApplicantEmailAddress,
      hideRecommended,
      showSpinner,
      tableSpinner,
    } = this.state
    const offset = propertySearchResults?.data?.length
    const hasMore = offset < propertySearchResults?.response_metadata?.total

    return (
      <div className="profile-screen">
        <div className="section-title">
          <div className="left-column">
            <h3>Share a Property</h3>
          </div>
        </div>
        <div className="two-col-box sm64">
          <div className="col-first xs-second">
            <Message
              agencyGUID={agencyGUID}
              propertyGUID={propertyGUID}
              limit={3}
              fetchSuccess={this.onFetchSuccess}
              fetchFailure={this.onFetchFailure}
              sendMessage={this.onSendMessage}
              message={message}
              validate={this.validate}
              updateMessage={this.updateMessage}
              isDisabled={this.isDisabled}
              errors={errors}
              attachedProperties={attachedProperties}
              isPropertyAttached={this.isPropertyAttached}
              onRemoveButtonClick={this.onRemoveItemFromAttachedPropsOnClick}
            ></Message>

            <div className="row pt25 mobile-form-button mb80 mt20">
              <div className="col-xs-5 pb5">
                <button
                  className="btn btn-transparent btn-left xs-text-center wa"
                  onClick={() => history.goBack()}
                >
                  <i className="icon-arrow-left left"></i>
                  <span>Back</span>
                </button>
              </div>
              <div className="col-xs-7">
                <button
                  disabled={this.isDisabled(message) || showSpinner}
                  onClick={() => {
                    this.onSendMessage(
                      message,
                      this.onMessageSent,
                      this.onFailure,
                    )
                  }}
                >
                  Send{' '}
                  <i className={showSpinner ? 'fa fa-spinner fa-pulse' : ''} />
                </button>
              </div>
            </div>
            <div className="mt50">
              <PropertySearchAndDropDown
                placeholder="Search street name or suburb"
                value={searchText}
                onSearchChange={this.onSearchChange}
                propertySearchResults={propertySearchResults}
                onInputBlur={this.onInputBlur}
                hideResults={this.state.hideResults}
              />
            </div>

            {tableSpinner ? (
              <Spinner />
            ) : (
              <div>
                <RecommendedPropertiesContainer
                  recommendedProperties={recommendedProperties}
                  onAddButtonClick={this.onAddItemIntoAttachedPropsOnClick}
                  src={addIcon}
                  isPropertyAttached={this.isPropertyAttached}
                  hideRecommended={hideRecommended}
                />
                <SearchedPropertyResults
                  propertySearchResults={propertySearchResults}
                  onAddButtonClick={this.onAddSearchResultPropertiesToAttached}
                  hideResults={this.state.hideResults}
                  isPropertyAttached={this.isPropertyAttached}
                />
              </div>
            )}

            {hasMore && (
              <div className={`text-center mt20 ml20`}>
                {loadMoreSpinner ? (
                  <Spinner />
                ) : (
                  <LoadMore offset={offset} loadMore={this.loadMore} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default RemarketProperty
