import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Overlay } from 'app/application_revamp/components/Applicant/shared_styles'
import ContactDetailsWrapper from 'app/application_revamp/components/Common/ContactDetails'
import WidgetHeading from 'app/application_revamp/components/Common/WidgetHeading'
import ApplicantRow from 'app/application_revamp/components/Layout/ApplicantRow'
import ApplicationHolder from 'app/application_revamp/components/Layout/ApplicationHolder'
import * as phoneNumberHelpers from 'app/utils/phonenumber/helpers'

const propTypes = {
  occupant: PropTypes.object,
  open: PropTypes.func,
}

const Status = styled.span`
  font-size: 16px;
`

const Name = styled.p`
  font-weight: bolder;
  font-size: 18px;
  color: #212025;
  line-height: 16px;
`

function Occupant({ occupant, open, isOpened }) {
  const { firstName, lastName, email, mobileNumber, status, guidID } = occupant
  const name = `${firstName} ${lastName}`
  const formattedMobileNumber =
    phoneNumberHelpers.formatPhoneNumber(mobileNumber)
  const occupantNotAvailable = !(
    firstName &&
    lastName &&
    (email || mobileNumber)
  )

  if (occupantNotAvailable) {
    return null
  }

  return (
    <ApplicantRow>
      <ApplicationHolder>
        <Name>
          <strong>{name}</strong>
        </Name>
        <WidgetHeading title="Occupant" />
        <div>
          <ContactDetailsWrapper
            email={email}
            mobile={mobileNumber}
            formattedMobile={formattedMobileNumber}
          />
          <p>
            <Status>{status}</Status>
          </p>
        </div>
      </ApplicationHolder>
      <Overlay onClick={() => open(guidID, 'isOpenedOccupant')} />
    </ApplicantRow>
  )
}

Occupant.propTypes = propTypes

export default Occupant
