import React from 'react'

import PDFNoteComponent from 'app/components/display/application_note/component_pdf'
import Card from 'app/match/applicationReportPDF/components/Card'

const TeamNotes = ({ data }) => {
  return (
    <div className="no-break-section">
      <Card
        type={'icon'}
        icon={'icon-note-code'}
        title="Team Notes (Private)"
        addClass="bottom-border"
      />
      <div className="notes-collection-container">
        {data?.length > 0 ? (
          data?.map((note, index) => (
            <PDFNoteComponent note={note} key={index} />
          ))
        ) : (
          <div className="text-center">No team notes</div>
        )}
      </div>
    </div>
  )
}

export default TeamNotes
