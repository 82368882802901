import { noteActionType, targetSectionObject } from 'app/sm/helpers'
import { api } from 'app/utils/api/helpers'

export const updateViewingRegistrant = (
  viewingID,
  registrantID,
  updatedRegistrant,
) => {
  const { externalComment } = updatedRegistrant
  if (externalComment) {
    updatedRegistrant.externalNote = {
      content: externalComment,
      type: noteActionType.viewingAttendee,
      target: targetSectionObject.viewingReport['index'],
    }
  }
  return api
    .put(`/sm/viewing/${viewingID}/onsiteregistrant/${registrantID}`, {
      ...updatedRegistrant,
      confirmationStatus: updatedRegistrant.confirmationStatus + '',
    })
    .then((registrant) => ({
      ...registrant,
      fullName: `${registrant.firstName} ${registrant.lastName}`,
    }))
}

export const updateRegistrantStatus = (registrant, partialStatusPayload) => {
  const { guidID, viewingID } = registrant
  const { externalNote, ...coreRegistrantPayload } = registrant
  return updateViewingRegistrant(viewingID, guidID, {
    ...coreRegistrantPayload,
    ...partialStatusPayload,
  })
}

export const updateCheckInForRegistrant = (registrant, checkedIn) => {
  return updateRegistrantStatus(registrant, { checkIn: checkedIn })
}

export const updateInterestForRegistrant = (registrant, interested) => {
  return updateRegistrantStatus(registrant, { interested: interested })
}
