import React from 'react'

import JoinManagerForm from 'app/bond_cover/managers/review/bc_join_form'
import { history } from 'app/shared_components/router'
import StatusBar from 'app/shared_components/status_bar'

class JoinManagerFormContainer extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    let { error } = this.props
    if (error) {
      return <div className="alert alert-danger"> {error} </div>
    } else {
      return (
        <div className="two-col-box-flex-row sm64">
          <div className="col-first">
            <StatusBar progress="3" />
            <JoinManagerForm />
          </div>
        </div>
      )
    }
  }
}

export default JoinManagerFormContainer
