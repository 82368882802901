import { connect } from 'react-redux'

import { getBondCoverCompleteSummary } from 'app/bond_cover/bond_cover_actions'
import ManagerComplete from 'app/bond_cover/managers/status/manager_complete'

const mapStateToProps = ({ bondCover, session }) => ({
  bondCoverCompleteSummary: bondCover.bondCoverCompleteSummary,
  currentUser: session.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  getBondCoverCompleteSummary: (requestID) =>
    getBondCoverCompleteSummary(requestID),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManagerComplete)
