import React from 'react'

import styled from 'styled-components'

import greenTickOutline from 'app/assets/icons/green-tick-outline.svg'
import { Box, Flex } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'

const StyledGreenTickText = styled(Flex)`
  align-items: center;
  line-height: 19.2px;
  margin-bottom: ${theme.space[3]}px;
  font-size: ${theme.fontSizes.pLarge16};
`

export const GreenTickText = ({ text = '' }) => {
  return (
    <StyledGreenTickText>
      <Box>
        <img src={greenTickOutline} />
      </Box>
      <Box ml={3}>{text}</Box>
    </StyledGreenTickText>
  )
}

export const StyledFeaturesBox = styled(Box)`
  padding: ${theme.space[7]}px;
  padding-top: ${theme.space[17]}px;
  @media (max-width: 991px) {
    padding-top: ${theme.space[5]}px;
    padding-left: 0;
    padding-right: 0;
  }
`

export const GreyBox = styled(Box)`
  background: #f7f8fb;
  padding: ${theme.space[8]}px;
  line-height: 140%;
  border-radius: ${theme.radii[9]}px;
`

export const StyledDisclosures = styled(Flex)`
  max-width: ${theme.width[23]}px;
  height: ${theme.height[12]}px;
  line-height: 14.4px;
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  align-items: center;
  .file-type {
    align-items: center;
    justify-content: center;
    font-size: ${theme.fontSizes.pExtraSmall10};
    width: ${theme.width[13]}px;
    height: ${theme.height[12]}px;
    font-weight: ${theme.fontWeights[6]};
    background-color: ${theme.colors.skyBlue};
    color: ${theme.colors.white};
  }
  .text {
    font-size: ${theme.fontSizes.pSmall12};
  }
  .file-name {
    color: ${theme.colors.skyBlue};
    text-decoration: underline;
    letter-spacing: 0.75px;
  }
`

export const DisclosureAuthority = ({
  docName = '',
  text,
  fileType = '',
  showTick = false,
  docURL = '',
}) => {
  return (
    <StyledDisclosures>
      <a href={docURL} target="_blank" rel="noreferrer">
        <Flex className="file-type">{fileType}</Flex>

        <Flex className="text" ml={3}>
          <Box>
            <Box mb={3} className="file-name">
              {docName}
            </Box>
            <Flex alignItems="center">
              <Box>{showTick && <img src={greenTickOutline} />}</Box>
              <Box ml={2}>{text}</Box>{' '}
            </Flex>
          </Box>
        </Flex>
      </a>
    </StyledDisclosures>
  )
}
