import React from 'react'

import headerImg01 from 'app/assets/icons/header-img-01.png'

const AdminDeclineForm = ({
  declineReason,
  update,
  updateNumber,
  decline,
  disabled,
  spinner,
  responseText,
}) => {
  let details = (
    <div>
      <p className="mb10">
        Can you please give us a bit more detail in the comment section below?
      </p>
      <div className="input-box mb30">
        <textarea required rows="4" onChange={update('description')}></textarea>
        <label>Please provide more details</label>
      </div>
    </div>
  )
  return (
    <div className="two-col-box-flex-row sm64">
      <div className="col-first">
        <div className="ilustration-box">
          <img src={headerImg01} />
        </div>

        <h3 className="mb25">
          Why are you declining this BondCover application?
        </h3>

        <label className="block mb15">
          <input
            type="radio"
            name="manager"
            value="1"
            onClick={updateNumber('Property Manager declined')}
          />
          <span>Property Manager declined</span>
        </label>

        <label className="block mb15">
          <input
            type="radio"
            name="manager"
            value="2"
            onClick={updateNumber('Background check failed')}
          />
          <span>Background check failed</span>
        </label>

        <label className="block mb15">
          <input
            type="radio"
            name="manager"
            value="3"
            onClick={updateNumber('ID check failed')}
          />
          <span>ID check failed</span>
        </label>

        <label className="block mb15">
          <input
            type="radio"
            name="manager"
            value="4"
            onClick={updateNumber('Suspicious activity')}
          />
          <span>Suspicious activity</span>
        </label>

        <label className="block mb30">
          <input
            type="radio"
            name="manager"
            value="5"
            onClick={updateNumber('Other')}
          />
          <span>Other</span>
        </label>

        {declineReason === 1 || declineReason === 5 ? details : ''}

        <button disabled={disabled || spinner} onClick={decline}>
          Submit
          <i className={spinner ? 'fa fa-spinner fa-pulse' : ''} />
        </button>

        {!!responseText && (
          <div className="alert alert-danger"> {responseText} </div>
        )}

        <p className="fs12 mt25 mb0 gray-color">
          Your answer will only be visible to Snug
        </p>
      </div>
    </div>
  )
}

export default AdminDeclineForm
