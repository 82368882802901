import React from 'react'

import * as Display from 'app/components/display/display'

function ContextMenu({
  offerGUID,
  sendPropertyList,
  onClickInviteToProperty,
  enquirerHasEmail,
  toggleShowInviteToApplyModal,
  isArchived = false,
  isSubmitReference = false,
  toggleCompletedReferenceModal,
  toggleReassignModal,
  onArchiveClick,
  onUnarchiveClick,
}) {
  const enquiryTools = [
    {
      text: 'View Reference',
      enableTool: isSubmitReference,
      onClick: () => toggleCompletedReferenceModal(),
    },
    {
      text: 'Reassign',
      enableTool: !isArchived,
      onClick: () => {
        toggleReassignModal()
      },
    },
    {
      text: isArchived ? 'Unarchive' : 'Archive',
      enableTool: true,
      onClick: () => {
        isArchived ? onUnarchiveClick() : onArchiveClick()
      },
    },
  ]
  return <Display.ToolsButton tools={enquiryTools} />
}

export default ContextMenu
