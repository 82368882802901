import styled from 'styled-components'
import { background } from 'styled-system'

import { Box } from 'app/components/design-system-components'
import theme from 'app/match/applicationReportPDF/assets/theme'

const Ellipse = styled(Box)`
  width: ${theme.width[1]}px;
  height: ${theme.height[1]}px;
  border-radius: ${theme.radii[7]};
  background-color: ${theme.colors.gray400};
  ${background}
`

export default Ellipse
