import React from 'react'

const mapCategoryWithType = {
  0: 'Affordability',
  1: 'Income',
  2: 'Address History',
  3: 'Identity',
}

const CRITERIA_NOT_SET = 0

const showMappedCriteriaWithDescription = (criteria) => {
  if (criteria.enabled > CRITERIA_NOT_SET)
    return `${mapCategoryWithType[criteria.category]} (${criteria.description})`
}
const EligibilityCriteria = ({ criterion }) => {
  let criteriaDescriptionsWithCategory = []
  criterion &&
    criterion.map(
      (criteria, index) =>
        criteria.enabled > 0 &&
        criteriaDescriptionsWithCategory.push(
          <li key={index}>{showMappedCriteriaWithDescription(criteria)}</li>,
        ),
    )
  return (
    <div>
      <p className="mb10">
        Your profile and application should meet these requirements:
      </p>
      <ul>{criteriaDescriptionsWithCategory}</ul>
    </div>
  )
}

export default EligibilityCriteria
