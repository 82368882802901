import React, { useEffect, useState } from 'react'

import qs from 'qs'
import styled from 'styled-components'

import successImg from 'app/assets/icons/Success.svg'
import { Box, Button, Flex } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { history } from 'app/shared_components/router'
import { urlTo } from 'app/sm/helpers'
import { getQuickWizardData } from 'app/sm/team_viewing_preference/setup/api'

import { StyledSetupContainerSteps } from '..'

const StyledTeamApplyPage = styled(Box)`
  font-size: 26px;
  margin-top: 16px;
  font-weight: 700;
  i {
    color: #5a9bee;
    margin-right: 8px;
  }
`

function StartReceivingApps() {
  const curQueries = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  const { teamGUID = '' } = curQueries
  const [teamSlug, setTeamSlug] = useState('')
  useEffect(() => {
    getQuickWizardData(teamGUID).then((data) => {
      const { slug } = data
      setTeamSlug(slug)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <StyledSetupContainerSteps>
      <h2>Start receiving applications!</h2>
      <Box mt={theme.space[5] + 'px'}>
        Share your apply link below to start receiving applications.
      </Box>
      <Flex
        alignItems="center"
        mt={theme.space[8] + 'px'}
        mb={theme.space[5] + 'px'}
        flexDirection="column"
      >
        <img
          src={successImg}
          alt="steps finished"
          width="100px"
          height="100px"
        />
        <StyledTeamApplyPage>
          <a href={`/apply/${teamSlug}`} target="_blank" rel="noreferrer">
            <i class="fa fa-external-link pl5"></i>
            {`snug.com/apply/${teamSlug}`}
          </a>
        </StyledTeamApplyPage>
      </Flex>
      Put your apply link everywhere to receive applications.{' '}
      <a
        href="https://help.snug.com/hc/en-us/articles/360001724195"
        target="_blank"
        rel="noreferrer"
      >
        learn more
      </a>
      <Box mt={theme.baseSpace * 5 + 'px'}>
        <Button
          onClick={() => history.push(urlTo('teamOverview', { teamSlug }))}
          sizeVariant="large"
        >
          Go to My Team Overview
        </Button>
      </Box>
    </StyledSetupContainerSteps>
  )
}

export default StartReceivingApps
