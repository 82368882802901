import React from 'react'

import {
  ErrorBox,
  getCurrentValidationStatus,
  validationStatusClassNameCollection,
} from 'app/components/forms/util'

import 'app/components/forms/base_input/style.scss'
// ///////////////////////////////////////////////////////////////////
// Text input
// ///////////////////////////////////////////////////////////////////
class BaseInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showError: true,
    }
  }

  handleBlur = () => {
    this.setState({ showError: true })
    const { value, error, onBlurFunc } = this.props
    this.props.onChange({ value, error })

    if (value && error) {
      this.setState({ validationStatusFlag: 2 })
    } else if (value && !error) {
      this.setState({ validationStatusFlag: 1 })
    } else {
      this.setState({ validationStatusFlag: 0 })
    }
    if (onBlurFunc) {
      onBlurFunc()
    }
  }

  handleChange = (event) => {
    const {
      target: { value },
    } = event
    const { isOptional } = this.props

    let error = ''
    if (value) {
      error = this.props.validate(value.trim())
    }
    if (!isOptional && value === '') {
      error = 'This field cannot be empty'
    }
    this.props.onChange({ value, error })
    this.setState({ showError: false })
  }

  render() {
    const {
      value,
      error,
      label,
      id,
      name,
      readOnly,
      componentClass,
      inputClass,
      disabled = false,
      showValidationStatus = true,
      labelClass = '',
      disableOnblurValidation = false,
      colorClass = '',
      isOptional,
      placeholder,
    } = this.props

    const { showError, validationStatusFlag } = this.state

    const validationClass = showValidationStatus
      ? getCurrentValidationStatus(value, error)
      : ''

    const validateClassName =
      showValidationStatus && !disableOnblurValidation
        ? validationStatusClassNameCollection[validationStatusFlag]
        : ''

    const displayError = !!(showError && error)

    return (
      <div className={`${componentClass}`}>
        <div className={`standard-form-components ${inputClass}`}>
          <div className="input-box standard-input-box mb5">
            <input
              type="text"
              value={value}
              required={!isOptional}
              className={`mb5 standard-inputbox ${
                !!value && 'standard-inputbox_hasValue'
              } ${value ? validationClass : ''} ${
                value ? validateClassName : ''
              } ${colorClass}`}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              id={id}
              name={name}
              readOnly={readOnly}
              disabled={disabled}
              placeholder={placeholder}
            />
            <label className={`input-label ${labelClass}`}>{label}</label>
          </div>
          <ErrorBox error={error} showError={displayError} />
        </div>
      </div>
    )
  }
}

export default BaseInput
