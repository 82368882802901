import {
  activityPeriodConfig,
  activityPeriods,
} from 'app/dashboard/team_activity/ActivityFilters/DateRangeFilter/constants'
import {
  getEndOfPriorMonth,
  getEndOfPriorWeek,
  getStartOfCurrentMonth,
  getStartOfCurrentWeek,
  getStartOfCurrentYear,
  getStartOfPriorMonth,
  getStartOfPriorWeek,
} from 'app/utils/datetime/helpers'

export const dateRangeFiltersFields = {
  start: 'startDate',
  end: 'endDate',
}

export const isDateRangeFilterApplied = (dateRangeFilterValues) =>
  dateRangeFilterValues &&
  (dateRangeFilterValues[dateRangeFiltersFields.start] ||
    dateRangeFilterValues[dateRangeFiltersFields.end])

export const getPeriodOptionValue = (optionId) => {
  switch (optionId) {
    case activityPeriods.lastSeven:
      return {
        startDate: getStartOfCurrentWeek(),
      }
    case activityPeriods.lastWeek:
      return {
        startDate: getStartOfPriorWeek(),
        endDate: getEndOfPriorWeek(),
      }
    case activityPeriods.currentMonth:
      return {
        startDate: getStartOfCurrentMonth(),
      }
    case activityPeriods.lastMonth:
      return {
        startDate: getStartOfPriorMonth(),
        endDate: getEndOfPriorMonth(),
      }
    case activityPeriods.all:
      return {}
  }
}

export const periodsOptions = Object.values(activityPeriods).map((period) => ({
  option: period,
  optionMeta: {
    id: period,
    displayText: activityPeriodConfig[period]?.title,
    noSeparator: true,
  },
}))
