import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import Amenities from 'app/sm/common/amenities'
import * as helpers from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

const ViewingInfo = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
`

function ViewingCard(props) {
  const { property, viewing, primaryButtonFunc } = props
  let imageUrl = helpers.NO_IMAGE_PLACEHOLDER_URL
  if (property.images && property.images.length > 0) {
    imageUrl = (
      property.images.find((image) => image.isMain === true) ||
      property.images[0]
    ).URL
  }
  let address = ''
  let suburb = ''
  if (property.address) {
    if (property.address.unitNumber) {
      address = `${property.address.unitNumber}/${property.address.streetNumber} ${property.address.streetName}`
    } else {
      address = `${property.address.streetNumber} ${property.address.streetName}`
    }
    suburb = property.address.suburb
  }
  const { startDate } = viewing
  return (
    <div className="sm-properties-card-list-item">
      <div className="sm-properties-card-list-item-wrapper pb20">
        <div
          className="image rental-application-view-property-details"
          style={{ backgroundImage: `url(${imageUrl})` }}
        ></div>
        <div className="address bbs">
          <div className="street fs20 fw700 lh13">{address}</div>
          <div className="suburb mb10">{suburb}</div>
          <Amenities payload={property} wrapClassName="amenities-widget" />
        </div>
        <ViewingInfo>
          <div className="mt10">
            <div className="fw700">Viewing</div>
            <div className="fw500">
              {moment(startDate).format(
                dateTimeHelpers.TIME_AM_PM_HALF_DAY_HALF_MONTH,
              )}
            </div>
          </div>
          <div>
            <button
              className="mt20 "
              onClick={() => primaryButtonFunc(viewing, property)}
            >
              Check In
            </button>
          </div>
        </ViewingInfo>
      </div>
    </div>
  )
}

export default ViewingCard
