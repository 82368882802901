import React from 'react'

import PropTypes from 'prop-types'

import Box from 'app/components/design-system-components/Box'
import Flex from 'app/components/design-system-components/Flex'
import { Spinner } from 'app/components/design-system-components/Spinner'
import * as Display from 'app/components/display/display'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { loadingStatesIds } from 'app/utils/loading-states'

const SimpleError = ({ error, actionHandler }) => {
  const hasActionHandler = typeof actionHandler === 'function'
  const actionHandlerWrapper = (event) => {
    event.preventDefault()
    actionHandler()
  }
  const actionElem = hasActionHandler && (
    <a onClick={actionHandlerWrapper}>Please try again</a>
  )
  const errorBody = (
    <span>
      Something went wrong! {error.message} {actionElem}
    </span>
  )
  return <Display.ErrorMessageContainer error={errorBody} />
}

const Loader = ({ fontSize, ...props }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    p={fontSize}
    fontSize={fontSize}
  >
    <Spinner {...props} />
  </Flex>
)

function LoadingSection({
  LoaderComponent = Loader,
  loaderProps,
  ErrorComponent = SimpleError,
  errorProps,
  loadingState,
  showChildrenWhenError = false,
  actionHandler,
  children,
  showChildrenWhileLoading = false,
  hideChildrenInitially = false,
}) {
  const { state, error } = loadingState

  if (state === loadingStatesIds.LOADING) {
    return (
      <>
        {showChildrenWhileLoading && <div>{children}</div>}
        <LoaderComponent {...loaderProps} />
      </>
    )
  }

  if (state === loadingStatesIds.ERROR) {
    return (
      <Box>
        {showChildrenWhenError && (
          <Box mb={theme.space[3] + 'px'}>{children}</Box>
        )}

        <ErrorComponent
          actionHandler={actionHandler}
          error={error}
          {...errorProps}
        />
      </Box>
    )
  }

  if (hideChildrenInitially && state === loadingStatesIds.INITIAL) {
    return <LoaderComponent {...loaderProps} />
  }

  return children
}

LoadingSection.propTypes = {
  LoaderComponent: PropTypes.node,
  loaderProps: PropTypes.any,
  ErrorComponent: PropTypes.node,
  errorProps: PropTypes.any,
  loadingState: PropTypes.object,
  actionHandler: PropTypes.func,
}

export default LoadingSection
