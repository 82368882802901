import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import {
  Box,
  Button,
  Dropdown,
  HorizontalLine,
} from 'app/components/design-system-components'
import { SearchRounded } from 'app/components/design-system-components/icons/actions'
import { ArrowDropDownRounded } from 'app/components/design-system-components/icons/navigation'
import { Input } from 'app/components/design-system-components/inputs'
import * as themeGetter from 'app/components/design-system-components/theme/getters'
import { ManagersOptionsList } from 'app/dashboard/team_activity/ActivityFilters/ManagerFilter'
import theme from 'app/match/applicationReportPDF/assets/theme'

const FilterDropdownBodyBox = styled(Box)`
  box-shadow: 0 4px 30px 0 ${themeGetter.color('dropdownDroppedShadow')};
  background-color: ${themeGetter.color('white')};
  position: absolute;
  z-index: 10;
`
const PropertyManagerContainer = styled.div`
  .preference-label {
    width: 200px;
  }
`

const ManagersDropdown = ({
  managerListInfo,
  assignee,
  setSelectedManagerGUID,
}) => {
  const [managerValues, setManagerValues] = useState()
  const [searchText, setSearchText] = useState('')
  const [addAssignTeamMemberConfig, setAddAssignTeamMemberConfig] = useState({
    isSearching: false,
    contactDetail: {},
  })

  const managerOptions = (managerListInfo || [])
    .map((manager) => {
      const { firstName, lastName, guidID } = manager.contact
      return {
        option: manager,
        optionMeta: {
          id: guidID,
          displayText: `${firstName} ${lastName}`,
        },
      }
    })
    .filter(({ optionMeta: { displayText } }) =>
      displayText.toLowerCase().includes(searchText.toLowerCase()),
    )

  const onSearchTextChange = ({ target: { value } }) => {
    setSearchText(value)
  }

  const onCheckBoxClicked = (checked, option) => {
    if (checked) {
      setManagerValues({
        [option.optionMeta.id]: option,
      })
    } else {
      setManagerValues({})
    }
  }

  const onClickSelectManager = () => {
    const selectedManager =
      Object.values(managerValues).length > 0
        ? Object.values(managerValues)[0].option
        : {}
    setSelectedManagerGUID(selectedManager.contact.guidID)
    setAddAssignTeamMemberConfig({
      contactDetail: selectedManager,
      isSearching: false,
    })
  }

  const onClickAddSearchAssignTeamMember = (open) => {
    setAddAssignTeamMemberConfig({
      ...addAssignTeamMemberConfig,
      isSearching: true,
    })
    open()
  }

  const _renderOptions = () => {
    const meOptionIndex = managerOptions.findIndex(
      ({ option: { isMe } }) => !!isMe,
    )

    const selectedManagers = managerValues || {}
    const clonedOptions = [...managerOptions]
    const [meOption] = clonedOptions.splice(meOptionIndex, 1)

    return addAssignTeamMemberConfig.isSearching ? (
      <FilterDropdownBodyBox>
        <Box maxHeight="100%">
          <Box p={theme.space[4] + 'px'}>
            <Input
              icon={<SearchRounded style={{ color: theme.colors.gray400 }} />}
              inputProps={{
                onChange: onSearchTextChange,
                placeholder: 'Search for a contact',
              }}
            />
          </Box>
          {meOptionIndex < 0 ? (
            <ManagersOptionsList
              options={managerOptions}
              listTitle="All Contact"
              onCheckBoxClicked={onCheckBoxClicked}
              selectedOptions={selectedManagers}
            />
          ) : (
            <Box maxHeight="200px" overflowY="auto">
              <ManagersOptionsList
                options={[meOption]}
                listTitle="You"
                onCheckBoxClicked={onCheckBoxClicked}
                selectedOptions={selectedManagers}
              />
              <ManagersOptionsList
                options={clonedOptions}
                listTitle="All Contact"
                onCheckBoxClicked={onCheckBoxClicked}
                selectedOptions={selectedManagers}
              />
            </Box>
          )}
        </Box>
        <HorizontalLine />
        <Box
          boxShadow={`${theme.space[0]}px -${theme.space[1]}px ${theme.dividedSpace[3]}px ${theme.space[0]}px ${theme.colors.searchAndSelectBoxShadow}`}
          p={theme.space[3] + 'px'}
        >
          <Button
            onClick={() => onClickSelectManager()}
            sizeVariant="regular"
            width="100%"
          >
            Select
          </Button>
        </Box>
      </FilterDropdownBodyBox>
    ) : (
      <></>
    )
  }

  const _renderAddAssignTeamMember = () => {
    const { contact } = addAssignTeamMemberConfig?.contactDetail || {}
    const { firstName = '', lastName = '' } = contact || {}
    const isSelectedContact = firstName && lastName
    return (
      <Box maxHeight="100%">
        <Dropdown
          triggerWrapperComponent={({ dropdownControls }) => {
            return (
              <Box pr={theme.space[4] + 'px'}>
                <Input
                  icon={
                    !isSelectedContact && (
                      <SearchRounded style={{ color: theme.colors.gray400 }} />
                    )
                  }
                  inputProps={{
                    placeholder: 'Search for a manager',
                    onClick: () =>
                      onClickAddSearchAssignTeamMember(dropdownControls.open),
                    value: isSelectedContact ? `${firstName} ${lastName}` : '',
                  }}
                  width="250px"
                  height="50px"
                  endedIcon={
                    <ArrowDropDownRounded
                      style={{ color: theme.colors.gray400 }}
                    />
                  }
                />
              </Box>
            )
          }}
          dropdownComponent={(dropdownProps) => <> {_renderOptions()}</>}
          topSpaceInPx={9}
        />
      </Box>
    )
  }

  useEffect(() => {
    if (assignee) {
      const initialManagerValue = {
        contact: {
          firstName: assignee.first_name,
          lastName: assignee.last_name,
          email: assignee.email,
        },
        isMe: assignee.is_me,
      }

      setAddAssignTeamMemberConfig({
        contactDetail: initialManagerValue,
        isSearching: false,
      })

      setManagerValues(
        Object.values([assignee]).reduce((acc, curr) => {
          acc[curr.guid] = {
            option: {
              contact: {
                firstName: curr.first_name,
                lastName: curr.last_name,
                email: curr.email,
              },
            },
            optionMeta: {
              id: curr.guid,
              displayText: `${curr.first_name} ${curr.last_name}`,
            },
          }
          return acc
        }, {}),
      )
      setSelectedManagerGUID(assignee.guid)
    }
  }, [assignee, setSelectedManagerGUID])

  return (
    <div className="mt20">
      <PropertyManagerContainer>
        {_renderAddAssignTeamMember()}
      </PropertyManagerContainer>
    </div>
  )
}

export default ManagersDropdown
