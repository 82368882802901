import { LOCATION_CHANGE } from 'connected-react-router'

import {
  ACCEPT_TERMS_CONDITIONS_CHANGE,
  ATTACH_TO_PROFILE,
  CARD_SELECT,
  CARDS_RECEIVED,
  CLEAR_ERROR,
  DISCARD_RESULT,
  POLLING_STOPPED,
  RECEIVE_BACKGROUND_CHECK,
  RECEIVE_DOC,
  RECEIVE_DOCS,
  RECEIVE_ERROR,
  RECEIVE_IDENTITY_DOCUMENTS,
  RECEIVE_PAYMENT_AMOUNT,
  RENDER_ALL_CARDS_CHANGE,
  RESET_SPINNER,
  TRIGGER_SPINNER,
} from 'app/sm/background_check/background_check_actions'

const defaultState = {
  identityDocList: {},
  identityDocAttachments: [],
  identityDocSpinner: false,
  cards: [],
  renderAllCards: true,
  backgroundCheck: {
    identityVerification: false,
    tenanacyBlacklistCheck: false,
    courtRecordsCheck: false,
    banckruptcyCheck: false,
    asicCompanyCheck: false,
    attachedToProfile: false,
  },
  isPollingStopped: false,
  spinner: false,
  paymentAmount: 0,
  acceptTermsAndConditions: false,
  selectedCreditCard: '',
  error: '',
  errorCode: 0,
}

const BackgroundCheck = (state = defaultState, action) => {
  const {
    type,
    cards,
    selected,
    identityDocList,
    backgroundCheck,
    paymentAmount,
    renderAllCards,
    error,
    isPollingStopped,
    identityDocAttachments,
  } = action

  Object.freeze(state)

  const newState = Object.assign({}, state)

  switch (type) {
    case RECEIVE_ERROR:
      const { errorMessage, errorCode } = error
      return Object.assign(newState, { error: errorMessage, errorCode })
    case CARDS_RECEIVED:
      return Object.assign(newState, { cards })
    case CARD_SELECT:
      return Object.assign(newState, { selectedCreditCard: selected })

    case RENDER_ALL_CARDS_CHANGE:
      return Object.assign(newState, { renderAllCards })
    case ACCEPT_TERMS_CONDITIONS_CHANGE:
      return Object.assign(newState, {
        acceptTermsAndConditions: !newState.acceptTermsAndConditions,
      })
    case RECEIVE_DOCS:
      return Object.assign(newState, { identityDocList, responseText: '' })
    case RECEIVE_BACKGROUND_CHECK:
      return Object.assign(newState, { backgroundCheck })
    case LOCATION_CHANGE:
      return Object.assign(newState, { error: '' })
    case TRIGGER_SPINNER:
      return Object.assign(newState, { spinner: true })
    case RESET_SPINNER:
      return Object.assign(newState, { spinner: false })
    case RECEIVE_PAYMENT_AMOUNT:
      return Object.assign(newState, { paymentAmount })
    case POLLING_STOPPED:
      return Object.assign(newState, { isPollingStopped })
    case DISCARD_RESULT:
      return Object.assign(newState, {
        backgroundCheck: defaultState.backgroundCheck,
      })
    case ATTACH_TO_PROFILE: {
      const newBgCheck = Object.assign({}, state.backgroundCheck, {
        attachedToProfile: true,
      })
      return Object.assign(newState, { backgroundCheck: newBgCheck })
    }
    case CLEAR_ERROR:
      return Object.assign(newState, { error: '' })
    case RECEIVE_IDENTITY_DOCUMENTS:
      return Object.assign(newState, { identityDocAttachments })
    default:
      return state
  }
}

export default BackgroundCheck
