import * as React from 'react'

function SvgWorkOutlineRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 8.5c0-1.11-.89-2-2-2h-4v-2c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19.5c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-11zm-8-2v-2h-4v2h4zm-10 3v9c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgWorkOutlineRounded
