import moment from 'moment'

import {
  RECEIVE_VIEWING_RUN,
  RESET_VIEWING_RUN,
} from 'app/sm/viewings_new_run/action'
import { modeDriving } from 'app/sm/viewings_new_run/schedule_list/component'

const _defaultState = {
  viewingRun: {
    agencyGUID: '',
    manager: null,
    startTime: moment().hour(9).minute(0).second(0),
    selectedPropertyInfos: [],
    travelMode: modeDriving,
    // not used in request only used locally
    scheduleBreaks: false,
    breakDuration: 5,
    viewingDuration: 15,
    // not used in request only used locally
    startPropertyGUID: '',
    // not used in request only used locally
    endPropertyGUID: '',
    // not used in request only used locally
    chosenManager: '',
    // not used in request only used locally
    propertyViewingInfos: [],
  },
}

const ViewingRunReducer = (state = _defaultState, action) => {
  const { viewingRun = {}, type } = action
  Object.freeze(state)
  switch (type) {
    case RECEIVE_VIEWING_RUN:
      return Object.assign({}, state, { viewingRun })
    case RESET_VIEWING_RUN:
      return Object.assign({}, _defaultState)
    default:
      return state
  }
}

export default ViewingRunReducer
