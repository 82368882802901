import React from 'react'

import moment from 'moment'
import { Route } from 'react-router-dom'

import Advertisement from 'app/sm/advertisements/advertisement_components/advertisement_form/advertisement_form'
import ConfirmationScreen from 'app/sm/advertisements/advertisement_components/advertisement_form/confirmation_screen'
import Pay from 'app/sm/advertisements/advertisement_components/advertisement_form/pay'
import Portals from 'app/sm/advertisements/advertisement_components/advertisement_form/property_portals'
import { findTeamByAgencyId, urlIds, urlTo } from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

class AdvertisementForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: new Date().toISOString(),
      weeklyRent: null,
      acceptedLeaseLength: '12',
      listingPortals: '1;2;3;4;5;6;7;8;9;10',
      listingFee: 129,
      errors: {},
    }
  }

  componentDidMount() {
    let { fetchPortals, match, fetchAdvertisement, fetchActiveAd } = this.props
    let { params, url } = match
    fetchPortals()

    this.props.fetchProperty(this.props.match.params.id)

    if (url.includes('update')) {
      fetchAdvertisement(params.offerId)
    }

    if (this.props.property && this.props.property.guidID) {
      const currentTeam = findTeamByAgencyId(
        this.props.teams,
        this.props.property.agencyID,
      )
      currentTeam && this.props.changeTeam(currentTeam)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { advertisement, ad, listingFee } = nextProps
    this.setState(advertisement)
    this.setState({
      listingPortals:
        ad.listingPortals !== undefined
          ? ad.listingPortals
          : this.state.listingPortals,
      listingFee,
    })

    if (nextProps.property && nextProps.property.guidID) {
      if (
        nextProps.teams !== this.props.teams ||
        nextProps.property.agencyID !== this.props.property.agencyID
      ) {
        const currentTeam = nextProps.teams.find(
          (team) => team.guid === nextProps.property.agencyID,
        )
        currentTeam && this.props.changeTeam(currentTeam)
      }
    }
  }

  componentWillUnmount() {
    this.props.resetAdvertisement()
  }

  bindProfileShareUrl() {
    const { property = {} } = this.state
    const { guidID: offerId } = this.state

    // This means we have the property fetched for sure
    if (property.slug) {
      const encodedUrlParam = btoa(
        JSON.stringify({
          offerId,
          propertySlug: property.slug,
        }),
      )

      return urlTo('profileShare', { encodedParams: encodedUrlParam })
    }
    return urlTo('profileShare', { encodedParams: '' })
  }

  update = (field) => {
    return (event) =>
      this.setState({
        [field]: event.currentTarget.value,
      })
  }

  updateCheckbox = () => {
    return (event) => {
      let { name, id } = event.currentTarget
      let checks = this.state[name].split(';')

      let index = checks.indexOf(id)
      if (index == -1) {
        checks.push(id)
      } else {
        checks.splice(index, 1)
      }

      checks = checks.filter((a) => a != '').join(';')
      this.setState({ [name]: checks })
    }
  }

  updateDate = (date) => {
    let { value, error } = date

    if (error === '') {
      date =
        date !== '' ? moment(date).format(dateTimeHelpers.DATE_WITH_DASH) : ''
    }
    this.setState({
      startDate: value,
      errors: {
        ...this.state.errors,
        startDate: error,
      },
    })
  }

  updateNumber = (field) => {
    return (event) =>
      this.setState({
        [field]: parseInt(event.currentTarget.value),
      })
  }

  render() {
    let {
      portals,
      createAdvertisement,
      updateAdvertisement,
      registerPayment,
      cardSelect,
      acceptTermsConditionsChange,
      fetchCards,
      acceptTermsAndConditions,
      cards,
      selectedCreditCard,
      payWithExistingCard,
      fetchActiveAd,
      ad,
      error,
    } = this.props

    let {
      weeklyRent,
      startDate,
      acceptedLeaseLength,
      listingPortals,
      listingFee,
    } = this.state
    let numPortals = listingPortals.split(';').filter((p) => p != '').length
    return (
      <div>
        {[
          '/sm/properties/:id/offers/create',
          '/sm/properties/:id/offers/update/:offerId',
        ].map((path) => (
          <div key={path}>
            <Route
              exact
              path={path}
              render={({ match }) => {
                let { url, params } = match
                let { id, offerId } = params
                // The submit function is just for the offer details screen
                let submit = url.includes('create')
                  ? createAdvertisement()
                  : () =>
                      updateAdvertisement(
                        urlTo(urlIds.propertyPublishingOptions, {
                          propertyId: id,
                          offerId,
                        }),
                      )(offerId, this.state)

                return (
                  <Advertisement
                    id={id}
                    url={url}
                    error={error}
                    weeklyRent={weeklyRent}
                    startDate={startDate}
                    acceptedLeaseLength={acceptedLeaseLength}
                    listingPortals={listingPortals}
                    listingFee={listingFee}
                    update={this.update}
                    updateNumber={this.updateNumber}
                    updateDate={this.updateDate}
                    updateCheckbox={this.updateCheckbox}
                    portals={portals}
                    registerPayment={registerPayment}
                    numPortals={numPortals}
                    submit={submit}
                    state={this.state}
                  />
                )
              }}
            />

            <Route
              path={`${path}/portals`}
              render={({ match }) => (
                <Portals
                  listingPortals={listingPortals}
                  error={error}
                  match={match}
                  portals={portals}
                  ad={ad}
                  listingFee={listingFee}
                  updateAdvertisement={updateAdvertisement}
                />
              )}
            />
            <Route
              path={`${path}/pay`}
              render={({ match }) => (
                <Pay
                  listingFee={listingFee}
                  error={error}
                  registerPayment={registerPayment}
                  match={match}
                  selectedCreditCard={selectedCreditCard}
                  cardSelect={cardSelect}
                  cards={cards}
                  fetchCards={fetchCards}
                  acceptTermsAndConditions={acceptTermsAndConditions}
                  acceptTermsConditionsChange={acceptTermsConditionsChange}
                  payWithExistingCard={payWithExistingCard}
                  fetchActiveAd={fetchActiveAd}
                  ad={ad}
                />
              )}
            />
            <Route
              path={`${path}/confirmation`}
              render={({ match }) => (
                <ConfirmationScreen
                  error={error}
                  match={match}
                  updateAdvertisement={updateAdvertisement}
                />
              )}
            />
          </div>
        ))}
      </div>
    )
  }
}

export default AdvertisementForm
