import React from 'react'

import { CSSTransitionGroup } from 'react-transition-group'
import styled from 'styled-components'

import data from 'app/application_revamp/components/Address/data.json'
import WidgetControls from 'app/application_revamp/components/Common/WidgetControls'
import WidgetHeader from 'app/application_revamp/components/Common/WidgetHeader'
import WidgetHolder from 'app/application_revamp/components/Common/WidgetHolder'

const Holder = styled.div`
  background: var(--pdf-white);
  box-shadow: 0px 3px 5px rgba(34, 33, 42, 0.08);
  border-radius: 4px;
  margin: 0 auto 24px;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 992px) {
    max-width: 720px;
  }
`

const Heading = styled.h2`
  min-width: 50%;
`

const Opener = styled.button`
  position: absolute;
  top: 70px;
  right: 26px;
  margin: 0;
  border: solid var(--pdf-gray-dark);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  background: transparent;
  width: 11px;
  height: 11px;
  padding: 0;
  outline: none;
  cursor: pointer;

  &.active {
    transform: rotate(-134deg);
  }

  @media (min-width: 992px) {
    top: 48px;
  }
`

const Slide = styled.div`
  padding-top: 24px;
`

const Icon = styled.i`
  display: inline-block;
  margin: 0px 6px -8px;
  vertical-align: sub;
  font-size: 24px;
`

class Address extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
    }
  }

  componentDidMount() {
    this.setState({
      house: data.house,
      isOpened: false,
    })
  }

  toggleField = (field) => {
    this.setState({
      [field]: !this.state[field],
    })
  }

  render() {
    const { house = {}, isOpened = false } = this.state

    const transitionOptions = {
      transitionName: 'fade',
      transitionEnterTimeout: 300,
      transitionLeaveTimeout: 300,
    }

    const referencesContent = house.references && (
      <div>
        {Object.keys(house.references).map((key) => (
          <span key={key}>
            {house.references[key]}
            <Icon className={'icon-' + key} />
          </span>
        ))}
      </div>
    )

    const details = house.details && house.details.length > 0 && (
      <Slide>
        {house.details.map((text, i) => (
          <p key={i}>{text}</p>
        ))}
      </Slide>
    )

    return (
      <Holder className="address-widget">
        <WidgetHeader>
          <Heading>
            <i className="icon-address" />
            Address history
          </Heading>
          <WidgetControls />
        </WidgetHeader>
        <WidgetHolder>
          <ul className="main-details">
            <li>
              <strong>Current:</strong> {house.address}
            </li>
            <li>
              <strong>Rent:</strong> ${house.rent_price}/week
            </li>
            <li>
              <strong>Occupied:</strong> {house.history_provided} years
              {house.history_from ? ` (since ${house.history_from})` : ''}
            </li>
          </ul>
          <ul>
            <li>
              <strong>History:</strong> {house.history_provided} years
            </li>
            <li>
              <strong>References:</strong> {referencesContent}
            </li>
          </ul>
          <Opener
            className={`opener ${isOpened ? 'active' : ''}`}
            onClick={() => {
              this.toggleField('isOpened')
            }}
          >
            <i className="icon-arrow" />
          </Opener>
          <CSSTransitionGroup {...transitionOptions}>
            {isOpened ? details : ''}
          </CSSTransitionGroup>
        </WidgetHolder>
      </Holder>
    )
  }
}

export default Address
