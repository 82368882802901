import React from 'react'

const BottomContentContainer = ({ children }) => (
  <div className="application-apply-panel-container pdf-hide">
    <div className="controls panel-container">
      <div className="application-apply-buttons-container display-flex">
        <div className="bottom-content-wrapper max-width-none p0">
          {children}
        </div>
      </div>
    </div>
  </div>
)

export default BottomContentContainer
