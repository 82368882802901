import { formValidationBuilder } from 'app/forms/utils/formValidationBuilder'

export const formFields = {
  property: 'property',
  keySet: 'keySet',
  bulkKeySets: 'bulkKeySets',
  borrower: 'borrower',
}

const fieldValidation = (fieldName, val, values) => {
  const isBulkMode = !!values[formFields.bulkKeySets]?.length
  switch (fieldName) {
    case formFields.property: {
      return {
        required: !val && !isBulkMode && 'Please select a property address',
      }
    }

    case formFields.keySet: {
      return {
        required: !val && !isBulkMode && 'Please select a key set',
      }
    }

    case formFields.borrower: {
      return {
        required: !val && 'Please select or add a borrower',
      }
    }
  }
}

export const formValidation = formValidationBuilder(fieldValidation)
