import React, { useEffect, useState } from 'react'

import $ from 'jquery'
import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import calendarDownload from 'app/assets/icons/calendar-download.svg'
import greenPlus from 'app/assets/icons/green-plus.png'
import redPlus from 'app/assets/icons/red-plus.png'
import yellowPlus from 'app/assets/icons/yellow-plus.svg'
import DefaultButton from 'app/components/buttons/default/component'
import { Box, Flex } from 'app/components/design-system-components'
import Badge from 'app/components/design-system-components/Badges'
import * as Display from 'app/components/display/display'
import RegisteredViewingNumBadge from 'app/components/display/registered-viewing-num'
import {
  canShowRecipients,
  ViewingEntryNoticesStatusAndDetailsCTA,
} from 'app/components/viewings/ViewingEntryNoticesStatusAndDetailsCTA'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import { Breadcrumbs } from 'app/dashboard/prospects_summary'
import { parseQueryString } from 'app/helpers/query_string'
import theme from 'app/match/applicationReportPDF/assets/theme'
import Theme from 'app/pages/teams/viewings-mobile/theme-styled-components'
import * as snugNotifier from 'app/services/snugNotifier'
import DownloadIcs from 'app/shared_components/download_ics'
import * as sharedHelper from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import SnugUpgradeAccountModal from 'app/shared_components/snug_account_upgrade/modal'
import {
  convertDateToUTCAndFormat,
  firstAndLastNameInitials,
  noteActionType,
  noteOwnerType,
  parseViewing,
  targetSectionObject,
  trimFileLength,
  urlTo,
  viewingBETimeFormat,
} from 'app/sm/helpers'
import AddAccessDetailModal from 'app/sm/inspections/components/add_access_details_modal_connection'
import AddCommentModal from 'app/sm/inspections/components/add_comment_modal'
import AddNoteModal from 'app/sm/inspections/components/add_notes_modal_connection'
import AddOwnerModal from 'app/sm/inspections/components/add_owner_modal'
import AddTenantsModal from 'app/sm/inspections/components/add_tenants_modal'
import AssignViewingTeamMemberModal from 'app/sm/inspections/components/assign_viewing_team_member_modal'
import AttendeeHubModal from 'app/sm/inspections/components/attendee_hub_modal'
import EditViewingTime from 'app/sm/inspections/components/edit_viewing_time_modal'
import LateForViewingModal from 'app/sm/inspections/components/late_for_viewing_modal'
import {
  EnquirerCheckboxes,
  RenterStatusCheckboxes,
  WalkinStatusCheckboxes,
} from 'app/sm/inspections/components/renter_status_checkboxes'
import SendSmsReportModal from 'app/sm/inspections/components/send_sms_report_modal_connection'
import ShortlistAttendee from 'app/sm/inspections/components/shortlist_attendee'
import TenantReadModal from 'app/sm/inspections/components/tenant_read_modal'
import { ViewingContextMenu } from 'app/sm/inspections/components/viewing_context_menu'
import IndividualInviteToApplySection, {
  RegistrantApplied,
  ViewingRegistrantCommentSection,
} from 'app/sm/inspections/components/viewing_registrant_display'
import WalkinRenterModal from 'app/sm/inspections/components/walkin_renter_modal'
import ViewingRegistrantsMessagesModal from 'app/sm/inspections/viewing_registrants_message_modal/ViewingRegistrantsMessagesModal'
import { getActiveOffer } from 'app/sm/renter_applications/renter_applications_access'
import * as accountHelpers from 'app/utils/accounts/helpers'
import { getNotePayloadForShortlistViewingRegistrant } from 'app/utils/notes/helpers'
import * as vaccinationHelpers from 'app/utils/vaccination/helpers'
import SendEmailReportButton from 'app/utils/viewings/components/SendEmailReportButton'
import SendSMSReportButton from 'app/utils/viewings/components/SendSMSReportButton'
import * as viewingsHelpers from 'app/utils/viewings/helpers'
import { FeatureFlag } from 'config/features'

import 'app/sm/inspections/components/style.scss'

const DirectRegistrant = 3
//Allows to change period of time considered to be past viewings
const propertyViewingsDateFilter = moment().subtract(72, 'hours')

const PrivateEmptyContainer = styled.div`
  ${(props) => props.showExtraPrivateTextWidth && 'margin-right: 70px'}
  ${(props) => props.showExtraNoticesWidth && 'padding-right: 130px'}
`

const TenantModalCategory = {
  sendEntryNotice: 'sendEntryNotice',
}

const futurePropertyViewings = (propertyViewings) => {
  return propertyViewings
    .filter(
      (pv) =>
        moment(pv.viewing.startDate).isAfter(propertyViewingsDateFilter) &&
        pv.viewing.cancelled !== true,
    )
    .sort(function (a, b) {
      return moment(a.viewing.startDate).isBefore(b.viewing.startDate) ? 1 : -1
    })
    .reduce(
      (groupedFuture, pv) =>
        Object.assign({}, groupedFuture, {
          [pv.viewing.startDate]: (
            groupedFuture[pv.viewing.startDate] || []
          ).concat(pv),
        }),
      {},
    )
}

const pastAndCancelledPropertyViewings = (propertyViewings) => {
  return propertyViewings
    .filter(
      (pv) =>
        moment(pv.viewing.startDate).isBefore(propertyViewingsDateFilter) ||
        pv.viewing.cancelled === true,
    )
    .sort(function (a, b) {
      return moment(a.viewing.startDate).isBefore(b.viewing.startDate) ? 1 : -1
    })
    .reduce(
      (groupedPastAndCancelled, pv) =>
        Object.assign({}, groupedPastAndCancelled, {
          [pv.viewing.startDate]: (
            groupedPastAndCancelled[pv.viewing.startDate] || []
          ).concat(pv),
        }),
      {},
    )
}

const PropertyViewingsList = ({
  propertyViewings,
  highlightedViewingId,
  displayOlder,
  dropDown,
  requestFeedbackError,
  error,
  viewingErrorGuid,
  checkboxSpinnerGuidArray,
  propertyId,
  updateDropDown,
  onShowLateViewingModal,
  onShowEditViewingModal,
  confirmCancelViewing,
  confirmSendInviteToApplyToAllRgistrants,
  confirmRequestRenterFeedback,
  updateRenterStatus,
  onShowCommentModal,
  deleteOnSiteAttendee,
  receiveApiError,
  onOnSiteAttendeeNameClicked,
  enquirers,
  contactCount,
  toggleEnquirers,
  toggleShowEnquirerLink,
  addWalkInRenter,
  currentTeam = {},
  onToggleAttendeeHubModal,
  onShowSendSmsReportModal,
  onToggleAttendeeWithoutSlotModal,
  sendManagerOnlyOrOwnerReport,
  sendInviteToApplyToRegistrant,
  propertyAddress,
  friendlyName,
  isViewingSlotEnabled,
  toggleTeamMemberModal,
  viewingEntryNoticeEnabled,
  refreshRegistrantsList,
  isAccountLite,
  onClickDisabledLiteAccountOption,
  onClickEntryNotices,
  isViewingReportSmsEnabled,
  isViewingReportEmailEnabled,
  fetchAllPropertyFutureViewings,
  defaultSelectedViewingDurationEnabled,
  defaultSelectedViewingDuration,
  property,
  agencyGUID = '',
  fetchRegistrantInfo,
  handleShortlist,
}) => {
  const [viewingAttendeesModals, setViewingAttendeesModals] = useState({})

  useEffect(() => {
    if (!propertyViewings) return

    const viewings = {}
    // eslint-disable-next-line array-callback-return
    Object.values(propertyViewings).map((v) => {
      if (v && v.length) {
        // eslint-disable-next-line array-callback-return
        v.map(({ viewing }) => {
          viewings[viewing.guidID] = false
        })
      }
    })
    setViewingAttendeesModals(viewings)
  }, [propertyViewings])

  const displayOlderClass =
    displayOlder === true ? 'show-viewing-list' : 'hide-viewing-list'

  const highlightedViewingClasses = [
    'highlighted-card',
    'highlighted-card--box-borders',
    'highlighted-card--left-bold-border',
  ]

  const renderViewing = (propertyViewing) => {
    const {
      viewing,
      renters = [],
      onsiteRegistrants = [],
      assignedTeamMembers = [],
    } = propertyViewing
    const allRenters = (onsiteRegistrants || []).concat(renters)

    const isHighlighted = viewing.guidID === highlightedViewingId

    const checkinRegistrants = onsiteRegistrants.filter((attendee) => {
      return (
        attendee.viewingOnsiteRegistrant.checkIn === true &&
        attendee.viewingOnsiteRegistrant.confirmationStatus !==
          viewingsHelpers.StatusAttendeeCancelled
      )
    })
    const attendeeCount = (checkinRegistrants || []).length
    const confirmedAttendeeAll = onsiteRegistrants.filter((attendee) => {
      return (
        attendee.viewingOnsiteRegistrant.confirmationStatus ===
        viewingsHelpers.StatusAttendeeConfirmed
      )
    })
    const confirmedAttendee = confirmedAttendeeAll.length
    let assignedTeamMembersCountMinusOne = 0
    if (assignedTeamMembers.length > 0) {
      assignedTeamMembersCountMinusOne = assignedTeamMembers.length - 1
    }
    const startDateInBETimeFormat = moment(
      viewing.startDate,
      viewingBETimeFormat,
    )

    const startDate = viewing.startDate
    const endDate = startDateInBETimeFormat
      .add(viewing.duration, 'minutes')
      .format(viewingBETimeFormat)

    const event = {
      title: `Viewing at ${friendlyName}`,
      location: `${propertyAddress}`,
      description: '',
      startTime: convertDateToUTCAndFormat(startDate),
      endTime: convertDateToUTCAndFormat(endDate),
    }

    const endDateForViewing = moment(startDate).add(viewing.duration, 'minutes')
    const isViewingInProgress = moment().isBetween(startDate, endDateForViewing)
    const {
      isReportSent,
      propertyReportGUID = '',
      assigned,
      entryNoticeRecordsSummary,
      fullyBooked,
      totalRegistered = 0,
      viewingCap = 0,
    } = viewing

    const noticesBlock = (
      <ViewingEntryNoticesStatusAndDetailsCTA
        entryNoticeRecordsSummary={entryNoticeRecordsSummary}
        openDetailsHandler={onClickEntryNotices}
        uniqueId={viewing.guid}
        isViewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
      />
    )

    const showNoticesLink =
      viewingEntryNoticeEnabled && canShowRecipients(entryNoticeRecordsSummary)

    const viewingReportSMSSent = viewing.isViewingReportSmsSent
    const viewingReportEmailSent = viewing.isViewingReportEmailSent

    const registeredAttendees = allRenters.filter((renter) =>
      viewingsHelpers.isActiveRegistrant(renter),
    )
    const registeredAttendeesCount = registeredAttendees
      ? registeredAttendees.length
      : 0
    return (
      <div key={propertyViewing.id}>
        <div className={displayOlderClass}>
          <div
            className={
              'bc-hub-item-link viewing-box flex flex-sp relative' +
              (isHighlighted ? highlightedViewingClasses.join(' ') : '')
            }
            onClick={() => updateDropDown(viewing.guidID)}
          >
            <div className="flex-col">
              <div className="txtBtn ha display-flex">
                {formatGroupName(
                  viewing,
                  viewingEntryNoticeEnabled,
                  showNoticesLink,
                )}
              </div>
              {viewing.cancelled && <Badge variant="red" text="Cancelled" />}
            </div>

            {noticesBlock}

            <div className="fs14 p5">
              {assigned}{' '}
              {assignedTeamMembersCountMinusOne > 0
                ? `+ ${assignedTeamMembersCountMinusOne}`
                : ''}
              <span
                className="blue-link-style ml5"
                onClick={() => toggleTeamMemberModal(viewing)}
              >
                edit
              </span>
            </div>
            <div className="viewings-arrow">
              <Flex className="panel">
                <span className="fs18 mobile-font-size-16 iphone5-font-size-14 pr5">
                  <Flex>
                    {fullyBooked && !viewing.cancelled && (
                      <Box mr="5px">
                        <RegisteredViewingNumBadge
                          uniqueIdentifier={`${viewing.guidID}-full-badge`}
                          numRegistered={totalRegistered}
                          viewingCap={viewingCap}
                        />
                      </Box>
                    )}
                    Reg: {registeredAttendeesCount}
                  </Flex>
                </span>
                {/* </Flex> */}
                <span className="fs18 mobile-font-size-16 iphone5-font-size-14 pr10">
                  Conf: {confirmedAttendee}
                </span>
                <span className="fs18 mobile-font-size-16 iphone5-font-size-14 pr10">
                  Att: {attendeeCount}
                </span>
                <DownloadIcs
                  imageSrc={calendarDownload}
                  rootClass={'display-inline-block vertical-align-top'}
                  event={event}
                  imageSrcHeight="20"
                />
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                <a
                  className={`${
                    dropDown === viewing.guidID ? '' : 'collapsed'
                  } `}
                  role="button"
                />
              </Flex>
            </div>
          </div>
          <div
            className={`${
              dropDown === viewing.guidID
                ? 'display-flex flex-wrap-wrap'
                : 'd-none'
            }`}
          >
            <button
              className="btn btn-medium w-150 p0 mr10"
              onClick={
                fullyBooked
                  ? () => onToggleAttendeeWithoutSlotModal(true, viewing)
                  : () => onToggleAttendeeHubModal(true, viewing)
              }
            >
              Add
            </button>
            <button
              className={`btn btn-medium w-150 p0 mr10 v-btn1 ${
                isAccountLite
                  ? 'background-color-gray-with-opacity '
                  : 'hollow-button-green'
              }`}
              disabled={viewing.cancelled}
              onClick={() => {
                !isAccountLite
                  ? onShowLateViewingModal(
                      true,
                      viewing,
                      renters,
                      onsiteRegistrants,
                    )
                  : onClickDisabledLiteAccountOption()
              }}
            >
              Late
            </button>
            <button
              className={`btn btn-medium w-150 p0 mr10 v-btn1 ${
                isAccountLite
                  ? 'background-color-gray-with-opacity '
                  : 'hollow-button-green'
              }`}
              onClick={() => {
                !isAccountLite
                  ? confirmSendInviteToApplyToAllRgistrants(viewing.guidID)
                  : onClickDisabledLiteAccountOption()
              }}
            >
              Invite to apply
            </button>
            {!viewing.cancelled && (
              <>
                <Flex style={{ gap: `${theme.space[3]}px` }}>
                  <SendEmailReportButton
                    isViewingReportEmailEnabled={isViewingReportEmailEnabled}
                    viewingReportEmailSent={viewingReportEmailSent}
                    isReportSent={isReportSent}
                    buttonClassName={`btn btn-medium p5 viewing-details-button v-btn1 pdf-hide ${
                      isAccountLite
                        ? 'background-color-gray-with-opacity '
                        : 'hollow-button-green'
                    }`}
                    buttonOnClick={() => {
                      !isAccountLite
                        ? sendManagerOnlyOrOwnerReport(
                            isReportSent,
                            propertyReportGUID,
                            propertyId,
                            currentTeam.slug,
                            viewing.guidID,
                            isViewingInProgress,
                          )
                        : onClickDisabledLiteAccountOption()
                    }}
                    viewingGUID={viewing.guidID}
                  />
                  <SendSMSReportButton
                    isViewingReportSmsEnabled={isViewingReportSmsEnabled}
                    viewingReportSMSSent={viewingReportSMSSent}
                    buttonClassName={`btn btn-medium pl10 pr10 mr10 v-btn1 btn-medium ${
                      isAccountLite
                        ? 'background-color-gray-with-opacity '
                        : 'hollow-button-green'
                    }`}
                    buttonOnClick={() => {
                      !isAccountLite
                        ? onShowSendSmsReportModal(
                            true,
                            viewing,
                            isViewingInProgress,
                          )
                        : onClickDisabledLiteAccountOption()
                    }}
                    viewingGUID={viewing.guidID}
                  />
                </Flex>
                <ViewingContextMenu
                  propertyViewing={propertyViewing}
                  property={property}
                  propertyViewings={propertyViewings}
                  defaultSelectedViewingDuration={
                    defaultSelectedViewingDuration
                  }
                  defaultSelectedViewingDurationEnabled={
                    defaultSelectedViewingDurationEnabled
                  }
                  fetchAllPropertyFutureViewings={
                    fetchAllPropertyFutureViewings
                  }
                  isAccountLite={isAccountLite}
                  onsiteRegistrants={onsiteRegistrants}
                  agencyId={currentTeam.guid}
                  onClickRequestFeedback={() =>
                    !isAccountLite
                      ? confirmRequestRenterFeedback(viewing)
                      : onClickDisabledLiteAccountOption()
                  }
                  confirmCancelViewing={() =>
                    confirmCancelViewing(viewing.guidID, propertyId)
                  }
                  onShowEditViewingModal={() =>
                    onShowEditViewingModal(true, viewing)
                  }
                  refreshRegistrantsList={refreshRegistrantsList}
                  teamSlug={currentTeam.slug}
                  onClickSendRegistrantsMessage={() => {
                    setViewingAttendeesModals({
                      ...viewingAttendeesModals,
                      [viewing.guidID]: true,
                    })
                  }}
                />
              </>
            )}
          </div>
          <div className="no-border">
            {requestFeedbackError === viewing.guidID && (
              <div className="alert alert-danger">
                <div>
                  You cannot send a feedback request prior to the viewing time
                </div>
              </div>
            )}
          </div>
          <div className="no-border">
            {error && viewingErrorGuid === viewing.guidID && (
              <div className="alert alert-danger">
                <div>{error}</div>
              </div>
            )}
          </div>
        </div>
        <div>
          <RenderRenterRows
            dropDown={dropDown}
            viewing={viewing}
            renters={renters}
            onsiteRegistrants={onsiteRegistrants}
            propertyId={propertyId}
            updateRenterStatus={updateRenterStatus}
            onShowCommentModal={onShowCommentModal}
            deleteOnSiteAttendee={deleteOnSiteAttendee}
            checkboxSpinnerGuidArray={checkboxSpinnerGuidArray}
            receiveApiError={receiveApiError}
            onOnSiteAttendeeNameClicked={onOnSiteAttendeeNameClicked}
            enquirers={enquirers}
            contactCount={contactCount}
            toggleEnquirers={toggleEnquirers}
            toggleShowEnquirerLink={toggleShowEnquirerLink}
            addWalkInRenter={addWalkInRenter}
            sendInviteToApplyToRegistrant={sendInviteToApplyToRegistrant}
            isViewingSlotEnabled={isViewingSlotEnabled}
            agencyGUID={agencyGUID}
            fetchRegistrantInfo={fetchRegistrantInfo}
            handleShortlist={handleShortlist}
          />
        </div>

        {viewingAttendeesModals[viewing.guidID] && (
          <ViewingRegistrantsMessagesModal
            agencyGUID={property.agencyID}
            onDismissClick={() =>
              setViewingAttendeesModals({
                ...viewingAttendeesModals,
                [viewing.guidID]: false,
              })
            }
            viewingGUID={viewing.guidID}
            friendlyName={propertyAddress}
            registrantsCount={onsiteRegistrants?.length || 0}
            startDate={startDate}
          />
        )}
      </div>
    )
  }

  return (
    <Theme>
      <div>
        {propertyViewings &&
          Object.values(propertyViewings).map((pv) => {
            return pv.map((propertyViewing) => {
              return renderViewing(propertyViewing)
            })
          })}
      </div>
    </Theme>
  )
}

export const RenderRenterRows = ({
  dropDown,
  viewing = {},
  renters = [],
  onsiteRegistrants = [],
  propertyId,
  updateRenterStatus,
  onShowCommentModal,
  deleteOnSiteAttendee,
  checkboxSpinnerGuidArray,
  receiveApiError,
  onOnSiteAttendeeNameClicked,
  enquirers,
  toggleEnquirers,
  toggleShowEnquirerLink,
  addWalkInRenter,
  sendInviteToApplyToRegistrant,
  isViewingSlotEnabled,
  agencyGUID,
  fetchRegistrantInfo,
  handleShortlist,
}) => {
  const hasRegistrants = renters.length > 0 || onsiteRegistrants.length > 0
  const hasRenters = renters.length > 0
  const hasWalkinRenters = onsiteRegistrants.length > 0
  const filteredEnquirers =
    enquirers && enquirers.length > 0
      ? enquirers
          .filter(
            (enquirer) =>
              !onsiteRegistrants.find(
                (onsiteRegistrant) =>
                  onsiteRegistrant.viewingOnsiteRegistrant.mobilePhone ===
                  enquirer.phone,
              ),
          )
          .filter(
            (enquirer) =>
              !onsiteRegistrants.find(
                (onsiteRegistrant) =>
                  onsiteRegistrant.viewingOnsiteRegistrant.email ===
                  enquirer.email,
              ),
          )
      : []
  const enableAddEnquirerFeature = FeatureFlag.AddEnquirersToViewings.isOn
  const displayEnquirers =
    filteredEnquirers && filteredEnquirers.length > 0 && !toggleShowEnquirerLink
  const displayFilteredEnquirers =
    enableAddEnquirerFeature &&
    filteredEnquirers &&
    filteredEnquirers.length > 0
  let alphabeticallySortedOnsiteRegistrants = onsiteRegistrants
  if (hasWalkinRenters && String.prototype.localeCompare !== undefined) {
    alphabeticallySortedOnsiteRegistrants = onsiteRegistrants.sort(
      (registrantA, registrantB) =>
        registrantA.viewingOnsiteRegistrant.firstName.localeCompare(
          registrantB.viewingOnsiteRegistrant.firstName,
        ),
    )
  }
  if (hasRegistrants && isViewingSlotEnabled) {
    if (isViewingSlotEnabled) {
      let slotOrderedRegistrants = viewingsHelpers.orderRegistrantsBasedOnSlots(
        alphabeticallySortedOnsiteRegistrants,
      )
      alphabeticallySortedOnsiteRegistrants = slotOrderedRegistrants
    }
  }

  const viewingID = viewing.guidID || viewing.viewingGUID
  return (
    <div>
      {hasRegistrants ? (
        <div>
          {hasRenters &&
            renters.map((renter) => {
              const { viewingRegistration, slot, slotTime } = renter || {}
              const startDate = moment(slotTime)
                .local()
                .format(viewingBETimeFormat)
              const viewingSlot = moment(startDate)
              const viewingSlotTime = viewingSlot.format('hh:mm a')
              const hasRenterComment =
                viewingRegistration &&
                viewingRegistration.comment &&
                viewingRegistration.comment.length > 0
              return (
                <li
                  className={`${
                    dropDown === viewing.guidID ||
                    dropDown === viewing.viewingGUID ||
                    dropDown === viewing.GUID
                      ? ''
                      : 'd-none'
                  } viewing-items`}
                  key={viewingRegistration.guidID}
                >
                  <div className="content-wrapper">
                    <div className="display-flex">
                      <div className="meta width-auto">
                        <div className="meta-wrapper">
                          <div className="info flex-direction-row-under500">
                            <div>
                              {extractRenter(renter).avatar ? (
                                <div
                                  className="avatar avatar-xsmall"
                                  style={{
                                    backgroundImage: `url(${
                                      extractRenter(renter).avatar
                                    })`,
                                  }}
                                />
                              ) : (
                                <div className="avatar blue-bg-light-rgba blue-color">
                                  {firstAndLastNameInitials(
                                    extractRenter(renter),
                                    'both',
                                  )}
                                </div>
                              )}
                              {!!isViewingSlotEnabled && slot !== 0 && (
                                <div className="mt10">
                                  <span className="font-size-12 text-black ">
                                    {`Slot ${slot}: ${viewingSlotTime}`}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="text">
                              <div className="display-flex v-comm">
                                <h4
                                  className="cursor-pointer underline"
                                  onClick={() =>
                                    onOnSiteAttendeeNameClicked(
                                      extractRenter(renter),
                                    )
                                  }
                                >
                                  {extractRenter(renter).firstName || ''}{' '}
                                  {`${
                                    extractRenter(renter).lastName &&
                                    firstAndLastNameInitials(
                                      extractRenter(renter),
                                      'lastName',
                                    )
                                  }`}
                                </h4>
                              </div>
                              {extractRenter(renter).mobilePhone && (
                                <div className="offer linkStyle">
                                  <a
                                    className="font-size-14-mobile"
                                    href={`tel:${
                                      extractRenter(renter).mobilePhone
                                    }`}
                                  >
                                    {extractRenter(renter).mobilePhone}
                                  </a>
                                </div>
                              )}
                              {extractRenter(renter).email && (
                                <div className="offer linkStyle">
                                  <a
                                    className="font-size-14-mobile"
                                    href={`mailto: ${
                                      extractRenter(renter).email
                                    }`}
                                  >
                                    {trimFileLength(15)(
                                      extractRenter(renter).email,
                                    )}
                                  </a>
                                </div>
                              )}
                              <div>
                                <RenterStatusCheckboxes
                                  propertyId={propertyId}
                                  viewingRegistration={viewingRegistration}
                                  updateRenterStatus={updateRenterStatus}
                                  checkboxSpinnerGuidArray={
                                    checkboxSpinnerGuidArray
                                  }
                                  options={{ noShow: true }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ml10">
                        <RenterStatusCheckboxes
                          propertyId={propertyId}
                          viewingRegistration={viewingRegistration}
                          updateRenterStatus={updateRenterStatus}
                          checkboxSpinnerGuidArray={checkboxSpinnerGuidArray}
                          options={{
                            checked: true,
                            interested: true,
                          }}
                        />
                        <IndividualInviteToApplySection
                          sendInviteToApplyToRegistrant={
                            sendInviteToApplyToRegistrant
                          }
                          registrantFirstName={extractRenter(renter).firstName}
                          registrant={viewingRegistration}
                        />
                      </div>
                    </div>
                    <ViewingRegistrantCommentSection
                      registrant={viewingRegistration}
                      renterType="renter"
                      propertyID={propertyId}
                      hasComment={hasRenterComment}
                      onShowCommentModal={onShowCommentModal}
                      onClickViewEdit={onOnSiteAttendeeNameClicked}
                      renter={extractRenter(renter)}
                    />
                  </div>
                </li>
              )
            })}
          {hasWalkinRenters &&
            alphabeticallySortedOnsiteRegistrants.map((walkinRenter, index) => {
              let { viewingOnsiteRegistrant, slot, slotTime, appliedAt } =
                walkinRenter || {}
              const startDate = moment(slotTime)
                .local()
                .format(viewingBETimeFormat)
              const viewingSlot = moment(startDate)
              const viewingSlotTime = viewingSlot.format('hh:mm a')
              let renter = {}
              if (!viewingOnsiteRegistrant) {
                viewingOnsiteRegistrant = walkinRenter
                renter = walkinRenter
              } else {
                renter = extractRenter(walkinRenter)
              }

              const hasWalkinComment =
                viewingOnsiteRegistrant &&
                viewingOnsiteRegistrant.comment &&
                viewingOnsiteRegistrant.comment.length > 0

              let attendeeConfirmationStatus = false
              let confirmationPillText = ''
              let confirmationStatusPillColour = ''
              if (
                renter &&
                renter.confirmationStatus ===
                  viewingsHelpers.StatusAttendeeConfirmed
              ) {
                attendeeConfirmationStatus = true
                confirmationPillText = 'Confirmed'
                confirmationStatusPillColour = 'hollow'
              } else if (
                renter &&
                renter.confirmationStatus ===
                  viewingsHelpers.StatusAttendeeCancelled
              ) {
                attendeeConfirmationStatus = true
                confirmationPillText = 'Cancelled'
                confirmationStatusPillColour = 'brown'
              }

              return (
                <li
                  className={`${
                    dropDown === viewing.guidID ||
                    dropDown === viewing.viewingGUID ||
                    dropDown === viewing.GUID
                      ? ''
                      : 'd-none'
                  } viewing-items`}
                  key={viewingOnsiteRegistrant.guidID}
                >
                  <div className="content-wrapper">
                    <div className="display-flex">
                      <div className="meta">
                        <div className="meta-wrapper">
                          <div className="flex-direction">
                            <div className="info flex-direction-row-under500">
                              <div>
                                {renter.avatar ? (
                                  <div
                                    className="avatar avatar-xsmall"
                                    style={{
                                      backgroundImage: `url(${renter.avatar})`,
                                    }}
                                  />
                                ) : (
                                  <div className="avatar blue-bg-light-rgba blue-color">
                                    {firstAndLastNameInitials(renter, 'both')}
                                  </div>
                                )}
                                {renter.registrantType !== DirectRegistrant ? (
                                  <div className="text-align-center">
                                    <span
                                      className="commentTxtBtn pt10 v-pos font-size-12 text-grey "
                                      onClick={() => {
                                        deleteOnSiteAttendee(
                                          propertyId,
                                          viewing.guidID || viewing.viewingGUID,
                                          viewingOnsiteRegistrant.guidID,
                                        )
                                      }}
                                    >
                                      Remove
                                    </span>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {!!isViewingSlotEnabled && slot !== 0 && (
                                  <div className="mt10">
                                    <span className="font-size-12 text-black ">
                                      {`Slot ${slot}: ${viewingSlotTime}`}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="text">
                                <div className="name-container display-flex v-comm">
                                  <h4
                                    className="cursor-pointer underline"
                                    onClick={() =>
                                      onOnSiteAttendeeNameClicked(renter)
                                    }
                                  >
                                    {renter.firstName || ''}{' '}
                                    {`${
                                      renter.lastName &&
                                      firstAndLastNameInitials(
                                        renter,
                                        'lastName',
                                      )
                                    }`}
                                  </h4>
                                  {extractCovid19VaccinationStatusConfig(
                                    renter,
                                  ) && (
                                    <Box
                                      className={'display-inline-block'}
                                      mt="2px"
                                    >
                                      <span className="font-weight600 font-size-14 display-block-mobile">
                                        <img
                                          data-tooltip-content={
                                            extractCovid19VaccinationStatusConfig(
                                              renter,
                                            ).text
                                          }
                                          src={
                                            extractCovid19VaccinationStatusConfig(
                                              renter,
                                            ).img
                                          }
                                          alt="vaccination status"
                                        />
                                        <ReactTooltip />
                                      </span>
                                    </Box>
                                  )}
                                </div>
                                {renter.mobilePhone && (
                                  <div className="offer linkStyle">
                                    <a
                                      className="font-size-14-mobile"
                                      href={`tel:${renter.mobilePhone}`}
                                    >
                                      {renter.mobilePhone}
                                    </a>
                                  </div>
                                )}
                                {renter.email && (
                                  <div className="offer linkStyle">
                                    <a
                                      className="font-size-14-mobile"
                                      href={`mailto:${renter.email}`}
                                    >
                                      {trimFileLength(15)(renter.email)}
                                    </a>
                                  </div>
                                )}
                                <div>
                                  {attendeeConfirmationStatus && (
                                    <div>
                                      <Display.StatusPill
                                        componentClass="mt10 mb10"
                                        pillType={confirmationStatusPillColour}
                                        text={confirmationPillText}
                                      />
                                    </div>
                                  )}
                                  <WalkinStatusCheckboxes
                                    propertyId={propertyId}
                                    viewingOnsiteRegistrant={
                                      viewingOnsiteRegistrant
                                    }
                                    updateRenterStatus={updateRenterStatus}
                                    checkboxSpinnerGuidArray={
                                      checkboxSpinnerGuidArray
                                    }
                                    receiveApiError={receiveApiError}
                                    options={{ noShow: true }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ml10">
                        <WalkinStatusCheckboxes
                          propertyId={propertyId}
                          viewingOnsiteRegistrant={viewingOnsiteRegistrant}
                          updateRenterStatus={updateRenterStatus}
                          checkboxSpinnerGuidArray={checkboxSpinnerGuidArray}
                          receiveApiError={receiveApiError}
                          options={{
                            checked: true,
                            interested: true,
                          }}
                        />
                        <ShortlistAttendee
                          shortlisted={viewingOnsiteRegistrant?.shortlisted}
                          attendeeGUID={viewingOnsiteRegistrant?.guidID}
                          agencyGUID={agencyGUID}
                          fetchRegistrantInfo={fetchRegistrantInfo}
                          handleShortlist={handleShortlist}
                          viewingGUID={viewingID}
                        />
                      </div>
                    </div>
                    <Box mb={8} minWidth="100px" className="ml30-desktop">
                      {!appliedAt && (
                        <IndividualInviteToApplySection
                          sendInviteToApplyToRegistrant={
                            sendInviteToApplyToRegistrant
                          }
                          registrantFirstName={
                            viewingOnsiteRegistrant.firstName
                          }
                          registrant={viewingOnsiteRegistrant}
                        />
                      )}
                      {!!appliedAt && (
                        <RegistrantApplied appliedAt={appliedAt} />
                      )}
                    </Box>
                    <ViewingRegistrantCommentSection
                      registrant={viewingOnsiteRegistrant}
                      renterType="walkinRenter"
                      propertyID={propertyId}
                      hasComment={hasWalkinComment}
                      onShowCommentModal={onShowCommentModal}
                      onClickViewEdit={onOnSiteAttendeeNameClicked}
                      renter={extractRenter(walkinRenter)}
                    />
                  </div>
                </li>
              )
            })}
        </div>
      ) : (
        <div
          className={`${dropDown === viewing.guidID ? '' : 'd-none'} mt20 mb20`}
        >
          <span className="min-width460">
            There are no attendees registered
          </span>
        </div>
      )}
      {displayEnquirers &&
        filteredEnquirers.map((contact) => {
          return (
            <li
              className={`${
                dropDown === viewing.guidID ? '' : 'd-none'
              } viewing-items`}
              key={contact.enquiryDate}
            >
              <div className="content-wrapper">
                <div className="meta">
                  <div className="meta-wrapper">
                    <div className="info flex-direction-row-under500">
                      {
                        <div className="avatar  blue-bg-light-rgba blue-color">
                          {firstAndLastNameInitials(contact, 'both')}
                        </div>
                      }
                      <div className="text">
                        <div className="display-flex v-comm">
                          <h4 className="cursor-pointer ">
                            {contact.firstName || ''}{' '}
                            {`${
                              contact.lastName &&
                              firstAndLastNameInitials(contact, 'lastName')
                            }`}
                          </h4>
                        </div>
                        <div className="offer">
                          {(contact.phone && (
                            <a
                              className="font-size-14-mobile"
                              href={`tel:${contact.phone}`}
                            >
                              {contact.phone}
                            </a>
                          )) ||
                            contact.email}
                        </div>
                        <div className="offer pt5">
                          Enquired at{' '}
                          {moment(contact.enquiryDate).format(
                            'hh:mm A DD MMM YYYY',
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="controls comment-wrap w45p xs-w100p" />

                <div className="controls comment-wrap w45p xs-w100p" />

                <EnquirerCheckboxes
                  propertyId={propertyId}
                  addWalkInRenter={addWalkInRenter}
                  enquirer={contact}
                  viewing={viewing}
                />
              </div>
            </li>
          )
        })}
      {displayFilteredEnquirers && (
        <div
          className={`${
            dropDown === viewing.guidID ? '' : 'd-none'
          } text-center`}
        >
          <div className="display-flex justify-content-center mt20 mb20">
            <DefaultButton
              onClick={() => toggleEnquirers()}
              text={
                toggleShowEnquirerLink ? 'Show enquirers' : 'Hide enquirers'
              }
              size="small"
              buttonType="secondary"
            />
          </div>
        </div>
      )}
    </div>
  )
}

function checkIfViewingEnded(viewing) {
  const { startDate, duration } = viewing || {}
  if (startDate && duration) {
    return moment().isAfter(moment(startDate).add(duration, 'minutes'))
  }
  return false
}

export function formatGroupName(
  viewing,
  viewingEntryNoticeEnabled,
  showNoticesLink,
) {
  const formatted = parseViewing(viewing)
  const viewingDate = moment(formatted.startDate).format('ddd, DD MMM')
  const viewingTimeText = `${formatted.startTime} ${formatted.duration} mins`
  const privateViewingText = viewing.published ? '' : '(private)'
  return (
    <div className="display-flex">
      <div className="width120px">{viewingDate}</div>
      <div>{viewingTimeText}</div>
      <PrivateEmptyContainer
        showExtraPrivateTextWidth={privateViewingText ? false : true}
        showExtraNoticesWidth={showNoticesLink ? false : true}
      >
        &nbsp;&nbsp;{privateViewingText}
      </PrivateEmptyContainer>
    </div>
  )
}

function extractRenter(renters = {}) {
  if (renters.viewingOnsiteRegistrant) {
    return renters.viewingOnsiteRegistrant
  }
  const renter = renters.renter || {
    firstName: '',
    lastName: '',
    avatar: '',
    email: '',
    covid19VaccinationStatus: vaccinationHelpers.NOT_VACCINATED_STATUS,
  }
  return renter
}

const extractCovid19VaccinationStatusConfig = (renter) => {
  if (!renter.covid19VaccinationStatus?.length) {
    return
  }

  return {
    [vaccinationHelpers.FULLY_VACCINATED_STATUS]: {
      img: greenPlus,
      text: 'Self declared, fully vaccinated',
    },
    [vaccinationHelpers.NOT_VACCINATED_STATUS]: {
      img: redPlus,
      text: 'Self declared, not vaccinated',
    },
    [vaccinationHelpers.PREFER_NOT_TO_ANSWER_VACCINATED_STATUS]: {
      img: yellowPlus,
      text: 'Self declared, prefer not to say',
    },
  }[renter.covid19VaccinationStatus]
}

class OwnerList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropDown: '',
      requestFeedbackError: '',
      showingToast: false,
      displayOlder: false,
      viewingAttendeeCount: 0,
      walkinModalStatus: 'create',
      targetAttendee: {},
      contactCount: 0,
      enquirers: [],
      toggleShowEnquirerLink: true,
      isAddAttendeeHubModalOn: false,
      isAddNoteModalOn: false,
      isAccessDetailModalOn: false,
      isSendSmsReportModalOn: false,
      isViewingSlotEnabled: false,
      disableSaveButton: false,
      openTeamMemberModal: false,
      selectedViewing: {},
      offersData: [],
      showUpgradeAccountModal: false,
      highlightedViewingId: null,
      tenantReadModal: false,
      tenantsWhoReadTheEntryNotice: [],
      isViewingReportSmsEnabled: false,
      isAddAttendeeWithOutSlotModalOn: false,
      selectedFullyBookedViewing: {},
      addAttendeePreloadContent: {},
    }

    this.onAttendeeHubModalAddIconClicked =
      this.onAttendeeHubModalAddIconClicked.bind(this)
    this.onSearchAttendeeLinkClicked =
      this.onSearchAttendeeLinkClicked.bind(this)
    this.sendInviteToApplyToRegistrant =
      this.sendInviteToApplyToRegistrant.bind(this)
  }

  componentDidMount() {
    const urlPropertyID = this.props.match && this.props.match.params.propertyId
    const {
      fetchProperty,
      fetchPropertyViewings,
      prospectPropertyID,
      startDate,
      endDate,
      currentTeam,
      fetchEnquirers,
      fetchTeamSettingInfo,
      property,
      viewings,
      searchText,
    } = this.props
    const chosenPropertyID = urlPropertyID || prospectPropertyID

    this.updateHighlightedViewingId()

    this.setState({ loadingSpinner: true, chosenPropertyID })

    if (currentTeam.guid) {
      fetchTeamSettingInfo(currentTeam.guid, 'viewings,notifications')
        .then(({ viewings, notifications }) => {
          const {
            viewing_slot_enabled: isViewingSlotEnabled = false,
            viewing_report_sms_enabled: isViewingReportSmsEnabled = false,
            viewing_report_email_enabled: isViewingReportEmailEnabled = false,
          } = viewings
          const {
            viewing_entry_notice_enabled: viewingEntryNoticeEnabled = false,
          } = notifications
          this.setState({
            isViewingSlotEnabled: isViewingSlotEnabled === 'true',
            viewingEntryNoticeEnabled: viewingEntryNoticeEnabled === 'true',
            isViewingReportSmsEnabled: isViewingReportSmsEnabled === 'true',
            isViewingReportEmailEnabled: isViewingReportEmailEnabled === 'true',
          })
        })
        .catch((error) => {
          this.setState({ error })
        })
    }

    if (chosenPropertyID) {
      fetchEnquirers(currentTeam.guid, chosenPropertyID)
        .then((result) => {
          this.setState({
            contactCount: result.total,
            enquirers: result.enquirers,
          })
        })
        .catch((error) => {
          this.setState({ apiError: error })
        })
    }

    // workaround: if property and viewings are provided, don't fetch
    // TODO: separate to a component loads needed data, and another taking data as props and just displaying, and reuse the later
    if (property && viewings) {
      this.setState({
        propertyViewings: viewings,
        propertyInfo: property,
        offersData: property.offers,
        loadingSpinner: false,
      })
      return
    }

    if (chosenPropertyID) {
      fetchPropertyViewings({
        propertyId: chosenPropertyID,
        startDate,
        endDate,
        searchText,
      }).then((propertyViewings) => {
        this.setState({
          propertyViewings,
          loadingSpinner: false,
        })
      })
      fetchProperty(chosenPropertyID).then((property) => {
        this.setState({ propertyInfo: property })
      })
      getActiveOffer(chosenPropertyID).then(({ offer }) => {
        const offersDetails = []
        offersDetails.push(offer)
        this.setState({
          offersData: offersDetails,
        })
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const urlPropertyID = this.props.match && this.props.match.params.propertyId
    const {
      todayFilterOn,
      prospectPropertyID,
      fetchPropertyViewings,
      refetchTheViewingList,
    } = this.props
    const chosenPropertyID = urlPropertyID || prospectPropertyID

    if (
      (todayFilterOn !== nextProps.todayFilterOn && this.refs.viewingItemRef) ||
      refetchTheViewingList !== nextProps.refetchTheViewingList
    ) {
      this.setState({ loadingSpinner: true })
      setTimeout(
        () =>
          fetchPropertyViewings({
            propertyId: chosenPropertyID,
            startDate: nextProps.startDate,
            endDate: nextProps.endDate,
            searchText: nextProps.searchText,
          })
            .then((propertyViewings) => {
              this.setState({
                propertyViewings,
                loadingSpinner: false,
              })
            })
            .catch((error) => {
              this.setState({ apiError: error })
            }),
        500,
      )
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const highlightedViewingIdQuery = !location
      ? null
      : parseQueryString(location.search).highlightedViewingId || null
    const highlightedViewingId =
      highlightedViewingIdQuery || this.props.highlightedViewingId || null

    if (highlightedViewingId !== this.state.highlightedViewingId) {
      this.updateHighlightedViewingId()
    }

    const urlPropertyID = this.props.match && this.props.match.params.propertyId
    let {
      prospectPropertyID,
      fetchProperty,
      fetchPropertyViewings,
      startDate,
      endDate,
      searchText,
    } = this.props
    const chosenPropertyID = urlPropertyID || prospectPropertyID
    if (
      prevProps.isTenantModalActive !== this.props.isTenantModalActive &&
      this.props.isTenantModalActive &&
      chosenPropertyID
    ) {
      fetchProperty(chosenPropertyID).then((property) => {
        this.setState({ propertyInfo: property })
      })
      getActiveOffer(chosenPropertyID).then(({ offer }) => {
        const offersDetails = []
        offersDetails.push(offer)
        this.setState({
          offersData: offersDetails,
        })
      })
    }
    if (
      (prevProps.tenantModalCategory === TenantModalCategory.sendEntryNotice ||
        prevProps.isTenantModalActive) &&
      !this.props.isTenantModalActive &&
      chosenPropertyID
    ) {
      fetchPropertyViewings({
        propertyId: chosenPropertyID,
        startDate,
        endDate,
        searchText,
      }).then((propertyViewings) => {
        this.setState({
          propertyViewings,
          loadingSpinner: false,
        })
      })
    }
  }

  clearAddAttendeePreloadContent = () => {
    this.setState({
      addAttendeePreloadContent: {},
    })
  }

  onAddOwnerModalAddIconClicked = (
    nameSearchText = '',
    mobileSearchText = '',
  ) => {
    const addOwnerPreloadContent = { nameSearchText, mobileSearchText }
    this.setState({
      addOwnerPreloadContent,
    })
  }

  onAddWalkInRenter = (
    payload,
    propertyId,
    viewingId,
    shouldSendInvite = false,
  ) => {
    const {
      addWalkInRenter,
      fetchPropertyViewings,
      startDate,
      endDate,
      searchText,
    } = this.props
    if (shouldSendInvite) {
      const { checkIn } = payload
      if (!checkIn) {
        snugNotifier.error(`Please Check-in attendee then Invite to Apply`)
        return
      }
    }
    this.setState({
      disableSaveButton: true,
    })
    setTimeout(() => {
      addWalkInRenter(payload, propertyId, viewingId)
        .then((data) => {
          const { registrant } = data
          this.setState({
            viewingAttendeeCount: this.state.viewingAttendeeCount + 1,
            apiError: '',
            registrant,
            disableSaveButton: false,
            isWalkInModalOn: false,
          })
          snugNotifier.success('Added successfully')
          if (shouldSendInvite && data) {
            const { firstName } = registrant
            this.sendInviteToApplyToRegistrant(registrant, firstName)
          }
          setTimeout(
            () =>
              fetchPropertyViewings({
                propertyId,
                startDate,
                endDate,
                searchText,
              })
                .then((newPropertyViewings) => {
                  this.setState({
                    propertyViewings: newPropertyViewings,
                  })
                })
                .catch((error) => {
                  snugNotifier.error(error)
                }),
            3000,
          )
        })
        .then(() => {
          if (payload && payload.externalComment) {
            const { registrant } = this.state
            const notePayload = {
              content: payload.externalComment,
              type: noteActionType.viewingAttendee,
              target: targetSectionObject.viewingReport['index'],
            }
            this.callCreateNote(notePayload, registrant.guidID)
          }
        })
        .catch((error) => {
          snugNotifier.error(error)
          this.setState({
            disableSaveButton: false,
          })
        })
    }, 200)
    return Promise.resolve()
  }

  handleShortlist = (registrantGUID, shortlist) => {
    const notePayload = getNotePayloadForShortlistViewingRegistrant(shortlist)
    this.callCreateNote(notePayload, registrantGUID)
  }

  onAttendeeHubModalAddIconClicked = (
    nameSearchText = '',
    mobileSearchText = '',
  ) => {
    const addAttendeePreloadContent = { nameSearchText, mobileSearchText }
    this.setState({
      isWalkInModalOn: true,
      walkinModalStatus: 'create',
      isAddAttendeeHubModalOn: false,
      addAttendeePreloadContent,
    })
  }

  onClickDisabledLiteAccountOption = () => {
    const { showUpgradeAccountModal } = this.state
    this.setState({
      showUpgradeAccountModal: !showUpgradeAccountModal,
    })
  }

  onClickEntryNotices = (entryNoticeRecordsSummary = undefined) => {
    const { tenantReadModal } = this.state
    this.setState({
      tenantReadModal: !tenantReadModal,
    })
    if (entryNoticeRecordsSummary) {
      this.setState({
        tenantsWhoReadTheEntryNotice: entryNoticeRecordsSummary.recipients,
      })
    }
  }

  onDisplayChange = (displayOlder) => {
    if (!displayOlder) {
      this.setState({ displayOlder: true })
    } else {
      this.setState({ displayOlder: false })
    }
  }

  onEditViewing = (
    propertyId,
    offerId,
    viewingId,
    startdate,
    duration,
    viewingOptions,
  ) => {
    const {
      editViewing,
      fetchPropertyViewings,
      startDate,
      endDate,
      searchText,
    } = this.props
    editViewing(
      propertyId,
      offerId,
      viewingId,
      startdate,
      duration,
      false,
      viewingOptions,
    )
      .then(() => {
        this.setState({ isEditViewModalOn: false })
        snugNotifier.success('Successfully updated viewing')
        fetchPropertyViewings({
          propertyId,
          startDate,
          endDate,
          searchText,
        }).then((propertyViewings) => {
          this.setState({
            propertyViewings,
          })
        })
      })
      .catch((error) => {
        if (
          error === ErrorMessages[Errors.ErrorViewingTimeExistsForProperty] ||
          error === ErrorMessages[Errors.ErrorViewingTimeExistsForManager]
        ) {
          if (window.confirm(viewingsHelpers.conflictingTimeMessage)) {
            editViewing(
              propertyId,
              offerId,
              viewingId,
              startdate,
              duration,
              true,
              viewingOptions,
            )
              .then(() => {
                this.setState({ isEditViewModalOn: false })
                snugNotifier.success('Successfully updated viewing')
                fetchPropertyViewings({
                  propertyId,
                  startDate,
                  endDate,
                  searchText,
                }).then((propertyViewings) => {
                  this.setState({
                    propertyViewings,
                  })
                })
              })
              .catch((error) => {
                snugNotifier.error(error)
              })
          } else {
            snugNotifier.error(error)
          }
        } else {
          snugNotifier.error(error)
        }
      })
  }

  onModalTitleClicked = (field) => {
    this.turnOffAllModals(() =>
      this.setState({
        [field]: true,
      }),
    )
  }

  onNotifyLateViewing = (data) => {
    const { notifyLateViewing } = this.props
    notifyLateViewing(data)
      .then(() => {
        snugNotifier.success('Successfully requested late SMS')
        this.setState({ isLateModalOn: false })
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  onOnSiteAttendeeNameClicked = (renterInfo) => {
    this.setState({
      isWalkInModalOn: true,
      walkinModalStatus: 'edit',
      targetAttendee: renterInfo,
      isAddAttendeeHubModalOn: false,
    })
  }

  onSearchAttendeeLinkClicked = () => {
    this.setState({
      isWalkInModalOn: false,
      isAddAttendeeHubModalOn: true,
    })
  }

  onShowCommentModal = (status, renterInfo) => {
    const { showCommentModal } = this.props
    showCommentModal(status, renterInfo)
    this.setState({ isCommentModalOn: status })
  }

  onShowEditViewingModal = (status, viewing) => {
    const { showEditViewingModal } = this.props
    showEditViewingModal(status, viewing)
    this.setState({ isEditViewModalOn: status })
  }

  onShowLateViewingModal = (
    status,
    viewing,
    renters = [],
    onsiteRegistrant = [],
  ) => {
    const { showLateViewingModal } = this.props
    showLateViewingModal(status, viewing)

    const totalCheckedInAttendeeCount = onsiteRegistrant.filter((attendee) =>
      viewingsHelpers.isActiveRegistrant(attendee),
    ).length
    this.setState({
      isLateModalOn: status,
      totalCheckedInAttendeeCount,
    })
  }

  onShowSendSmsReportModal = (isOpen, viewing, isViewingInProgress) => {
    if (isViewingInProgress) {
      if (
        window.confirm(viewingsHelpers.SMS_VIEWING_REPORT_CONFIRMATION_MESSAGE)
      ) {
        this.setState({
          isSendSmsReportModalOn: true,
          smsData: viewing,
        })
      }
      return
    }
    this.setState({
      isSendSmsReportModalOn: true,
      smsData: viewing,
    })
  }

  onToggleAttendeeWithoutSlotModal = (isOpen, viewing) => {
    this.setState({
      isAddAttendeeWithOutSlotModalOn: isOpen,
      selectedFullyBookedViewing: viewing,
    })
  }

  onToggleAttendeeHubModal = (isOpen, viewing) => {
    const { toggleWalkInModal } = this.props
    const { propertyViewings, enquirers } = this.state
    toggleWalkInModal(false, viewing)
    let targetViewing = {}
    let filteredEnquirers = []
    if (viewing && propertyViewings) {
      targetViewing = propertyViewings.find((viewingItem) => {
        return viewingItem.viewing.guidID === viewing.guidID
      })
      const { onsiteRegistrants = [] } = targetViewing
      filteredEnquirers =
        enquirers && enquirers.length > 0
          ? enquirers
              .filter(
                (enquirer) =>
                  !onsiteRegistrants.find(
                    (onsiteRegistrant) =>
                      onsiteRegistrant.viewingOnsiteRegistrant.mobilePhone ===
                      enquirer.phone,
                  ),
              )
              .filter(
                (enquirer) =>
                  !onsiteRegistrants.find(
                    (onsiteRegistrant) =>
                      onsiteRegistrant.viewingOnsiteRegistrant.email ===
                      enquirer.email,
                  ),
              )
          : []
    }
    this.setState({
      isAddAttendeeHubModalOn: isOpen,
      filteredEnquirersForAttendeeHub: filteredEnquirers,
    })
  }

  onToggleWalkInModal = (status, viewing) => {
    const { toggleWalkInModal } = this.props
    toggleWalkInModal(status, viewing)
    this.setState({
      isWalkInModalOn: status,
      walkinModalStatus: 'create',
    })
    if (!status) {
      this.setState({
        addAttendeePreloadContent: {},
      })
    }
  }

  onUpdateRenterStatus = (
    payload,
    renterType,
    id,
    registrationGUID,
    propertyId,
  ) => {
    this.setState({
      disableSaveButton: true,
    })
    const {
      updateRenterStatus,
      fetchPropertyViewings,
      startDate,
      endDate,
      searchText,
    } = this.props
    const { externalComment } = payload
    if (externalComment) {
      payload.externalNote = {
        content: externalComment,
        type: noteActionType.viewingAttendee,
        target: targetSectionObject.viewingReport['index'],
      }
    }
    payload.propertyId = propertyId
    setTimeout(() => {
      updateRenterStatus(payload, renterType, id, registrationGUID, propertyId)
        .then(() => {
          this.setState({
            disableSaveButton: false,
          })
          fetchPropertyViewings({
            propertyId,
            startDate,
            endDate,
            searchText,
          }).then((propertyViewings) => {
            this.setState({
              propertyViewings,
              loadingSpinner: false,
              isWalkInModalOn: false,
            })
          })
        })
        .then(() => {
          snugNotifier.success('Updated successfully')
        })
        .catch((error) => {
          snugNotifier.error(error)
          this.setState({
            apiError: error,
            disableSaveButton: false,
          })
        })
    }, 200)
  }

  onUpdateRenterStatusComment = (payload) => {
    const {
      updateRenterStatusComment,
      fetchPropertyViewings,
      renterInfo: { propertyId },
      startDate,
      endDate,
      searchText,
    } = this.props
    updateRenterStatusComment(payload)
      .then(() => {
        this.setState({ isCommentModalOn: false })
        fetchPropertyViewings({
          propertyId,
          startDate,
          endDate,
          searchText,
        }).then((propertyViewings) => {
          this.setState({
            propertyViewings,
          })
        })
      })
      .catch((error) => {
        snugNotifier.error(error)
      })
  }

  callCreateNote(note, attendeeGUID) {
    const { createNote } = this.props
    return createNote(note, attendeeGUID, noteOwnerType.ViewingAttendee)
      .then(() => {
        return Promise.resolve()
      })
      .catch((error) => {
        snugNotifier.error(error)
        return Promise.reject()
      })
  }

  clearError = () => {
    this.setState({
      apiError: '',
    })
  }

  confirmCancelViewing = (guidID, propertyId) => {
    const { fetchPropertyViewings, startDate, endDate, searchText } = this.props
    if (window.confirm(viewingsHelpers.CANCEL_VIEWING_CONFIRM_TEXT)) {
      this.props
        .cancelViewing(guidID, propertyId)
        .then(() => {
          snugNotifier.success(
            viewingsHelpers.CANCEL_VIEWING_CONFIRMATION_MESSAGE,
          )
          fetchPropertyViewings({
            propertyId,
            startDate,
            endDate,
            searchText,
          }).then((propertyViewings) => {
            this.setState({
              propertyViewings,
            })
          })
        })
        .catch((error) => {
          this.setState({ apiError: error })
        })
    }
  }

  confirmRequestRenterFeedback = (viewing = {}) => {
    if (
      window.confirm(
        'Are you sure you want to request feedback from all viewing registrants?',
      )
    ) {
      if (checkIfViewingEnded(viewing) && !viewing.cancelled) {
        this.props
          .requestFeedback(viewing.guidID, this.reactToastify)
          .catch((error) => {
            this.setState({ apiError: error })
          })
      } else {
        this.setState({ requestFeedbackError: viewing.guidID })
      }
    }
  }

  confirmSendInviteToApplyToAllRgistrants = (guidID) => {
    const {
      fetchPropertyViewings,
      startDate,
      endDate,
      prospectPropertyID,
      sendInviteToApplyToAllRegistrants,
      searchText,
    } = this.props
    let chosenPropertyID = prospectPropertyID
      ? prospectPropertyID
      : this.props.match.params.propertyId
    if (
      window.confirm(
        'Email and SMS invite all uninvited, checked-in attendees?',
      )
    ) {
      sendInviteToApplyToAllRegistrants(guidID)
        .then(() => {
          snugNotifier.success(
            'Email and SMS invite sent to all uninvited, checked-in attendees',
          )
          setTimeout(
            () =>
              fetchPropertyViewings({
                propertyId: chosenPropertyID,
                startDate,
                endDate,
                searchText,
              })
                .then((propertyViewings) => {
                  this.setState({
                    propertyViewings,
                  })
                })
                .catch((error) => {
                  this.setState({ apiError: error })
                }),
            3000,
          )
        })
        .catch((error) => {
          this.setState({ apiError: error })
        })
    }
  }

  deleteOnSiteAttendee = (propertyId, viewingGUID, registrationGUID) => {
    const {
      removeOnsiteViewingRenter,
      fetchPropertyViewings,
      startDate,
      endDate,
      searchText,
    } = this.props
    if (
      window.confirm('Are you sure you want to remove this viewing attendee?')
    ) {
      removeOnsiteViewingRenter(viewingGUID, registrationGUID).then(() => {
        this.setState({
          viewingAttendeeCount: this.state.viewingAttendeeCount - 1,
        })
        fetchPropertyViewings({ propertyId, startDate, endDate, searchText })
          .then((propertyViewings) => {
            this.setState({
              propertyViewings,
            })
          })
          .catch((error) => {
            this.setState({ apiError: error })
          })
      })
    }
  }

  reactToastify = () => {
    this.setState({ requestFeedbackError: '' })
    const { setSuccessToast } = this.props
    if (setSuccessToast) {
      setSuccessToast('Your request will only be sent to checked in applicants')
    } else {
      this.state.showingToast === false &&
        snugNotifier.success(
          'Your request will only be sent to checked in applicants',
          {
            onOpen: () => this.setState({ showingToast: true }),
            onClose: () => this.setState({ showingToast: false }),
          },
        )
    }
  }

  receiveApiError = (apiError) => {
    this.setState({ apiError })
  }

  refreshRegistrantsList = () => {
    const {
      fetchPropertyViewings,
      startDate = '',
      endDate = '',
      prospectPropertyID,
      searchText,
    } = this.props
    if (prospectPropertyID) {
      fetchPropertyViewings({
        propertyId: prospectPropertyID,
        startDate,
        endDate,
        searchText,
      })
        .then((propertyViewings) => {
          this.setState({
            propertyViewings,
          })
        })
        .catch(() => snugNotifier.error('Unable to fetch the registrants'))
    }
  }

  sendInviteToApplyToRegistrant = (viewingRegistrant) => {
    const { sendIndividualInviteToApply } = this.props
    const { viewingID, guidID } = viewingRegistrant
    sendIndividualInviteToApply(viewingID, guidID)
    return Promise.resolve()
  }

  sendManagerOnlyOrOwnerReport = (
    isReportSent,
    propertyReportGUID,
    propertyGUID,
    teamSlug,
    viewingGUID,
    isViewingInProgress,
  ) => {
    if (isReportSent) {
      history.push(
        `${urlTo('managerOnlyPropertyReport', {
          propertyreportId: propertyReportGUID,
        })}`,
      )
    } else {
      if (isViewingInProgress) {
        if (
          window.confirm(viewingsHelpers.VIEWING_REPORT_CONFIRMATION_MESSAGE)
        ) {
          history.push(
            `${urlTo('ownerReport', {
              propertyId: propertyGUID,
            })}?team=${teamSlug}&viewingId=${viewingGUID}`,
          )
        }
        return
      }
      history.push(
        `${urlTo('ownerReport', {
          propertyId: propertyGUID,
        })}?team=${teamSlug}&viewingId=${viewingGUID}`,
      )
    }
  }

  toggleEnquirers = () => {
    this.setState({
      toggleShowEnquirerLink: !this.state.toggleShowEnquirerLink,
    })
  }

  toggleTeamMemberModal = (viewing) => {
    const { fetchPropertyViewings, startDate, endDate, searchText } = this.props
    if (viewing && viewing.PropertyGUID) {
      fetchPropertyViewings({
        propertyId: viewing.PropertyGUID,
        startDate,
        endDate,
        searchText,
      }).then((propertyViewings) => {
        this.setState({
          propertyViewings,
        })
      })
    }
    this.setState({
      openTeamMemberModal: !this.state.openTeamMemberModal,
      selectedViewing: viewing,
    })
  }

  turnOffAllModals = (callback = () => {}) => {
    this.setState(
      {
        isEditViewModalOn: false,
        isAddAttendeeHubModalOn: false,
        isWalkInModalOn: false,
        isLateModalOn: false,
        isAddNoteModalOn: false,
        isSendSmsReportModalOn: false,
        isAccessDetailModalOn: false,
      },
      () => callback(),
    )
  }

  updateDropDown = (value) => {
    if (this.state.dropDown === value) {
      this.setState({ dropDown: '', requestFeedbackError: '' })
    } else {
      this.setState({ dropDown: value, requestFeedbackError: '' })
    }
    this.setState({
      activeViewingID: value,
    })
  }

  updateHighlightedViewingId() {
    const { location } = this.props
    if (location) {
      const { highlightedViewingId } = parseQueryString(location.search)
      this.setState({
        highlightedViewingId: highlightedViewingId || null,
      })
    } else {
      const { highlightedViewingId: highlightedViewingIdProp } = this.props
      this.setState({
        highlightedViewingId: highlightedViewingIdProp || null,
      })
    }
  }

  render() {
    const {
      error,
      viewingErrorGuid,
      viewingTime,
      renterInfo,
      checkboxSpinnerGuidArray,
      address,
      fromProspect,
      prospectPropertyID,
      currentTeam,
      fetchAgencyApplicantsElasticSearchResult,
      isTenantModalActive = false,
      isOwnerModalActive = false,
      toggleModal,
      fetchCurrentTenants,
      fetchCurrentOwners,
      addCurrentTenants,
      addCurrentOwners,
      chosenPropertyText,
      updateCurrentTenants,
      updateCurrentOwners,
      tenantModalCategory,
      ownerModalCategory,
      removeCurrentTenants,
      sendEntryNotice,
      removeCurrentOwners,
      assignViewingToTeamMember,
      fetchViewingTeamMembers,
      deleteViewingTeamMember,
      updateViewingTeamMemberRelation,
      getManagersListForViewingFilters,
      viewingEntryNoticeEnabled,
      fetchAllPropertyFutureViewings,
      defaultSelectedViewingDurationEnabled,
      defaultSelectedViewingDuration,
    } = this.props
    const {
      propertyViewings,
      loadingSpinner,
      isEditViewModalOn,
      isLateModalOn,
      isWalkInModalOn,
      isCommentModalOn,
      propertyInfo = {},
      apiError,
      viewingAttendeeCount,
      walkinModalStatus,
      targetAttendee,
      enquirers,
      contactCount,
      toggleShowEnquirerLink,
      totalCheckedInAttendeeCount,
      isAddAttendeeHubModalOn,
      isSendSmsReportModalOn,
      isAddAttendeeWithOutSlotModalOn,
      filteredEnquirersForAttendeeHub,
      addAttendeePreloadContent,
      addOwnerPreloadContent,
      chosenPropertyID = '',
      isAddNoteModalOn,
      isAccessDetailModalOn,
      activeViewingID,
      isViewingSlotEnabled,
      openTeamMemberModal,
      selectedViewing,
      disableSaveButton,
      offersData,
      showUpgradeAccountModal,
      highlightedViewingId,
      tenantReadModal,
      tenantsWhoReadTheEntryNotice,
      isViewingReportSmsEnabled,
      isViewingReportEmailEnabled,
    } = this.state
    const formattedViewingStartDate = parseViewing(selectedViewing)
    const { offers = [] } = propertyInfo

    let propertyId = prospectPropertyID
      ? prospectPropertyID
      : this.props.match.params.propertyId

    const { friendlyName, suburb } = address || ''

    const propertyAddress = `${friendlyName}, ${suburb}`
    const { dropDown, requestFeedbackError, displayOlder } = this.state

    const viewingsPresent =
      propertyViewings && Object.keys(propertyViewings).length !== 0
    const futureViewings =
      propertyViewings && futurePropertyViewings(propertyViewings)
    const futureViewingsPresent =
      futureViewings && Object.keys(futureViewings).length !== 0

    const pastAndCancelledViewings =
      propertyViewings && pastAndCancelledPropertyViewings(propertyViewings)
    const pastViewingsPresent =
      pastAndCancelledViewings &&
      Object.keys(pastAndCancelledViewings).length !== 0
    const isTemporaryPage = window.location.pathname.includes('temporary')

    const displayShowOlder = !displayOlder && pastViewingsPresent
    const displayNoUpcomingViewings = viewingsPresent && !futureViewingsPresent

    const hasFullScreenModal =
      isAddAttendeeHubModalOn ||
      isWalkInModalOn ||
      isTenantModalActive ||
      isAddNoteModalOn ||
      isAccessDetailModalOn ||
      isSendSmsReportModalOn
    if (hasFullScreenModal && !$('.app-content').hasClass('mt0')) {
      $('.app-content').addClass('mt0')
    }
    if (!hasFullScreenModal && $('.app-content').hasClass('mt0')) {
      $('.app-content').removeClass('mt0')
    }
    let isAccountLite = false
    let crumbs = []
    let teamId = ''
    let teamSlug = ''
    if (currentTeam) {
      crumbs = [
        {
          text: currentTeam.name || 'Team',
          link: urlTo('teamOverview', { teamSlug: currentTeam.slug }),
        },
        {
          text: 'Overview',
          link: urlTo('teamOverview', { teamSlug: currentTeam.slug }),
        },
        {
          text: friendlyName,
          link: '#',
        },
      ]
      teamId = currentTeam.guid
      teamSlug = currentTeam.slug
      isAccountLite =
        currentTeam &&
        currentTeam.accountType &&
        currentTeam.accountType === accountHelpers.ACCOUNT_TYPE_LITE
    }
    const enabledOptionsForWalkinModal = {
      enableContactInfomation: true,
      enableCheckedInAndInterested: true,
      enablePreference: true,
      enableNote: true,
      showSearchButton: true,
      enableViewingBottomButtons: true,
    }
    return (
      <div className="viewing-view" ref="viewingItemRef">
        <div className="viewing-card">
          {currentTeam && !isTemporaryPage && !fromProspect && (
            <Breadcrumbs crumbs={crumbs} />
          )}
          {!fromProspect && (
            <div className="section-title xs-row pt20">
              <div className="left">
                <h3 className="ibm lh1">
                  Viewings ({propertyViewings && propertyViewings.length})
                </h3>
              </div>
            </div>
          )}
          {!fromProspect && (
            <div className="left mb20">
              <h4>{propertyAddress}</h4>
            </div>
          )}
          {loadingSpinner ? (
            <div className="display-flex justify-content-center width100">
              <i className="fa fa-spinner fa-pulse" />
            </div>
          ) : (
            <ul className="ra-card__list">
              {
                <div>
                  {!viewingsPresent && (
                    <div className="display-flex justify-content-center mb20">
                      No viewings
                    </div>
                  )}
                  <div>
                    {futureViewingsPresent && (
                      <PropertyViewingsList
                        propertyViewings={futureViewings}
                        highlightedViewingId={highlightedViewingId}
                        displayOlder={true}
                        dropDown={dropDown}
                        requestFeedbackError={requestFeedbackError}
                        error={error}
                        viewingErrorGuid={viewingErrorGuid}
                        checkboxSpinnerGuidArray={checkboxSpinnerGuidArray}
                        propertyId={propertyId}
                        updateDropDown={this.updateDropDown}
                        onToggleWalkInModal={this.onToggleWalkInModal}
                        onShowLateViewingModal={this.onShowLateViewingModal}
                        onShowEditViewingModal={this.onShowEditViewingModal}
                        confirmCancelViewing={this.confirmCancelViewing}
                        confirmSendInviteToApplyToAllRgistrants={
                          this.confirmSendInviteToApplyToAllRgistrants
                        }
                        confirmRequestRenterFeedback={
                          this.confirmRequestRenterFeedback
                        }
                        updateRenterStatus={this.onUpdateRenterStatus}
                        onShowCommentModal={this.onShowCommentModal}
                        deleteOnSiteAttendee={this.deleteOnSiteAttendee}
                        receiveApiError={this.receiveApiError}
                        onOnSiteAttendeeNameClicked={
                          this.onOnSiteAttendeeNameClicked
                        }
                        enquirers={enquirers}
                        contactCount={contactCount}
                        toggleEnquirers={this.toggleEnquirers}
                        toggleShowEnquirerLink={toggleShowEnquirerLink}
                        addWalkInRenter={this.onAddWalkInRenter}
                        currentTeam={currentTeam}
                        onToggleAttendeeHubModal={this.onToggleAttendeeHubModal}
                        onToggleAttendeeWithoutSlotModal={
                          this.onToggleAttendeeWithoutSlotModal
                        }
                        onShowSendSmsReportModal={this.onShowSendSmsReportModal}
                        sendManagerOnlyOrOwnerReport={
                          this.sendManagerOnlyOrOwnerReport
                        }
                        sendInviteToApplyToRegistrant={
                          this.sendInviteToApplyToRegistrant
                        }
                        propertyAddress={propertyAddress}
                        friendlyName={friendlyName}
                        isViewingSlotEnabled={isViewingSlotEnabled}
                        isViewingReportSmsEnabled={isViewingReportSmsEnabled}
                        isViewingReportEmailEnabled={
                          isViewingReportEmailEnabled
                        }
                        toggleTeamMemberModal={this.toggleTeamMemberModal}
                        viewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
                        refreshRegistrantsList={this.refreshRegistrantsList}
                        isAccountLite={isAccountLite}
                        onClickDisabledLiteAccountOption={
                          this.onClickDisabledLiteAccountOption
                        }
                        onClickEntryNotices={this.onClickEntryNotices}
                        fetchAllPropertyFutureViewings={
                          fetchAllPropertyFutureViewings
                        }
                        defaultSelectedViewingDurationEnabled={
                          defaultSelectedViewingDurationEnabled
                        }
                        defaultSelectedViewingDuration={
                          defaultSelectedViewingDuration
                        }
                        property={propertyInfo}
                        agencyGUID={teamId}
                        fetchRegistrantInfo={this.refreshRegistrantsList}
                        handleShortlist={this.handleShortlist}
                      />
                    )}
                  </div>
                  <div>
                    {displayNoUpcomingViewings && (
                      <div className="display-flex justify-content-center mb20">
                        No upcoming viewings
                      </div>
                    )}
                  </div>
                  <div>
                    {
                      <div>
                        {displayShowOlder && (
                          <div className="text-center">
                            <button
                              className="textButton wa ha btnmr mt10 mb10 gray-text"
                              onClick={() => this.onDisplayChange(displayOlder)}
                            >
                              Show old or cancelled
                            </button>
                          </div>
                        )}
                        <PropertyViewingsList
                          propertyViewings={pastAndCancelledViewings}
                          highlightedViewingId={highlightedViewingId}
                          displayOlder={displayOlder}
                          dropDown={dropDown}
                          requestFeedbackError={requestFeedbackError}
                          error={error}
                          viewingErrorGuid={viewingErrorGuid}
                          checkboxSpinnerGuidArray={checkboxSpinnerGuidArray}
                          propertyId={propertyId}
                          updateDropDown={this.updateDropDown}
                          onToggleWalkInModal={this.onToggleWalkInModal}
                          onShowLateViewingModal={this.onShowLateViewingModal}
                          onShowEditViewingModal={this.onShowEditViewingModal}
                          confirmCancelViewing={this.confirmCancelViewing}
                          confirmSendInviteToApplyToAllRgistrants={
                            this.confirmSendInviteToApplyToAllRgistrants
                          }
                          confirmRequestRenterFeedback={
                            this.confirmRequestRenterFeedback
                          }
                          updateRenterStatus={this.onUpdateRenterStatus}
                          onShowCommentModal={this.onShowCommentModal}
                          deleteOnSiteAttendee={this.deleteOnSiteAttendee}
                          receiveApiError={this.receiveApiError}
                          onOnSiteAttendeeNameClicked={
                            this.onOnSiteAttendeeNameClicked
                          }
                          enquirers={enquirers}
                          contactCount={contactCount}
                          toggleEnquirers={this.toggleEnquirers}
                          toggleShowEnquirerLink={toggleShowEnquirerLink}
                          addWalkInRenter={this.onAddWalkInRenter}
                          currentTeam={currentTeam}
                          onToggleAttendeeHubModal={
                            this.onToggleAttendeeHubModal
                          }
                          onShowSendSmsReportModal={
                            this.onShowSendSmsReportModal
                          }
                          onToggleAttendeeWithoutSlotModal={
                            this.onToggleAttendeeWithoutSlotModal
                          }
                          sendManagerOnlyOrOwnerReport={
                            this.sendManagerOnlyOrOwnerReport
                          }
                          sendInviteToApplyToRegistrant={
                            this.sendInviteToApplyToRegistrant
                          }
                          propertyAddress={propertyAddress}
                          friendlyName={friendlyName}
                          isViewingSlotEnabled={isViewingSlotEnabled}
                          isViewingReportSmsEnabled={isViewingReportSmsEnabled}
                          isViewingReportEmailEnabled={
                            isViewingReportEmailEnabled
                          }
                          toggleTeamMemberModal={this.toggleTeamMemberModal}
                          viewingEntryNoticeEnabled={viewingEntryNoticeEnabled}
                          refreshRegistrantsList={this.refreshRegistrantsList}
                          isAccountLite={isAccountLite}
                          onClickDisabledLiteAccountOption={
                            this.onClickDisabledLiteAccountOption
                          }
                          onClickEntryNotices={this.onClickEntryNotices}
                          fetchAllPropertyFutureViewings={
                            fetchAllPropertyFutureViewings
                          }
                          defaultSelectedViewingDurationEnabled={
                            defaultSelectedViewingDurationEnabled
                          }
                          defaultSelectedViewingDuration={
                            defaultSelectedViewingDuration
                          }
                          property={propertyInfo}
                          agencyGUID={teamId}
                          fetchRegistrantInfo={this.refreshRegistrantsList}
                          handleShortlist={this.handleShortlist}
                        />
                        {showUpgradeAccountModal && (
                          <SnugUpgradeAccountModal
                            isNotToBeEmbedded={false}
                            toggleModal={this.onClickDisabledLiteAccountOption}
                            typeOfMessage={accountHelpers.UPGRADE_TYPE_VIEWINGS}
                          />
                        )}
                        {displayOlder && (
                          <div className="text-center">
                            <button
                              className="textButton wa ha btnmr mt10 mb10 gray-text"
                              onClick={() => this.onDisplayChange(displayOlder)}
                            >
                              Hide old or cancelled
                            </button>
                          </div>
                        )}
                      </div>
                    }
                  </div>
                </div>
              }
            </ul>
          )}
        </div>
        {isLateModalOn && (
          <LateForViewingModal
            showLateViewingModal={this.onShowLateViewingModal}
            error={apiError}
            notifyLateViewing={this.onNotifyLateViewing}
            clearError={this.clearError}
            totalCheckedInAttendeeCount={totalCheckedInAttendeeCount}
            isLateModalOn={isLateModalOn}
          />
        )}
        {isSendSmsReportModalOn && (
          <SendSmsReportModal
            data={this.state.smsData}
            handleClose={() => this.setState({ isSendSmsReportModalOn: false })}
            showSendSmsReportModal={this.onShowSendSmsReportModal}
            clearError={this.clearError}
            isSendSmsReportModalOn={isSendSmsReportModalOn}
          />
        )}
        {isWalkInModalOn && (
          <WalkinRenterModal
            viewingAttendeeCount={viewingAttendeeCount}
            error={apiError}
            toggleWalkInModal={this.onToggleWalkInModal}
            addWalkInRenter={this.onAddWalkInRenter}
            propertyId={propertyId}
            clearError={this.clearError}
            walkinModalStatus={walkinModalStatus}
            targetAttendee={targetAttendee}
            onUpdateRenterStatus={this.onUpdateRenterStatus}
            renterInfo={renterInfo}
            onSearchAttendeeLinkClicked={this.onSearchAttendeeLinkClicked}
            addAttendeePreloadContent={addAttendeePreloadContent}
            enableOptions={enabledOptionsForWalkinModal}
            disableSaveButton={disableSaveButton}
            agencyGUID={teamId}
            teamSlug={teamSlug}
          />
        )}
        {isTenantModalActive && (
          <AddTenantsModal
            toggleModal={toggleModal}
            address={address}
            teamId={teamId}
            fetchAgencyApplicantsElasticSearchResult={
              fetchAgencyApplicantsElasticSearchResult
            }
            chosenPropertyID={chosenPropertyID}
            fetchCurrentTenants={fetchCurrentTenants}
            addCurrentTenants={addCurrentTenants}
            chosenPropertyText={chosenPropertyText}
            updateCurrentTenants={updateCurrentTenants}
            tenantModalCategory={tenantModalCategory}
            removeCurrentTenants={removeCurrentTenants}
            offers={offersData}
            sendEntryNotice={sendEntryNotice}
          />
        )}
        {isOwnerModalActive && (
          <AddOwnerModal
            fetchAgencyApplicantsElasticSearchResult={
              fetchAgencyApplicantsElasticSearchResult
            }
            teamId={teamId}
            address={address}
            toggleModal={toggleModal}
            ownerModalCategory={ownerModalCategory}
            fetchCurrentTenants={fetchCurrentTenants}
            chosenPropertyText={chosenPropertyText}
            chosenPropertyID={chosenPropertyID}
            addOwnerPreloadContent={addOwnerPreloadContent}
            fetchCurrentOwners={fetchCurrentOwners}
            currentTeam={currentTeam}
            addCurrentOwners={addCurrentOwners}
            updateCurrentOwners={updateCurrentOwners}
            removeCurrentOwners={removeCurrentOwners}
          />
        )}
        {isAddAttendeeHubModalOn && (
          <AttendeeHubModal
            clearError={this.clearError}
            toggleAttendeeHubModal={this.onToggleAttendeeHubModal}
            enquirers={enquirers}
            onAttendeeHubModalAddIconClicked={
              this.onAttendeeHubModalAddIconClicked
            }
            onOnSiteAttendeeNameClicked={this.onOnSiteAttendeeNameClicked}
            fetchAgencyApplicantsElasticSearchResult={
              fetchAgencyApplicantsElasticSearchResult
            }
            teamId={teamId}
            filteredEnquirersForAttendeeHub={filteredEnquirersForAttendeeHub}
            onModalTitleClicked={this.onModalTitleClicked}
            chosenPropertyText={chosenPropertyText}
            clearAddAttendeePreloadContent={this.clearAddAttendeePreloadContent}
          />
        )}

        {isAddAttendeeWithOutSlotModalOn && (
          <Display.Modal
            title="Fully booked"
            hideButtonSection={true}
            toggleModal={() => this.onToggleAttendeeWithoutSlotModal(false)}
          >
            <div className="text-center">
              The viewing slots are fully booked. Update the duration to add
              more slots. Continue to add attendee without a slot?
            </div>
            <Display.ModalButtonGroup
              primaryLabel="Add"
              secondaryLabel="Cancel"
              secondaryButtonType="tertiary"
              primaryAction={() => {
                this.onToggleAttendeeWithoutSlotModal(false)
                this.onToggleAttendeeHubModal(
                  true,
                  this.state.selectedFullyBookedViewing,
                )
              }}
              secondaryAction={() =>
                this.onToggleAttendeeWithoutSlotModal(false)
              }
            />
          </Display.Modal>
        )}

        {isAddNoteModalOn && (
          <AddNoteModal
            turnOffAllModals={this.turnOffAllModals}
            onModalTitleClicked={this.onModalTitleClicked}
            propertyId={propertyId}
            activeViewingID={activeViewingID}
          />
        )}
        {isAccessDetailModalOn && (
          <AddAccessDetailModal
            turnOffAllModals={this.turnOffAllModals}
            onModalTitleClicked={this.onModalTitleClicked}
            agencyGUID={propertyInfo.agencyID}
            property={propertyId}
          />
        )}
        {isEditViewModalOn && (
          <EditViewingTime
            propertyId={propertyId}
            showEditViewingModal={this.onShowEditViewingModal}
            viewingTime={viewingTime}
            editViewing={this.onEditViewing}
            offers={offers}
            error={apiError}
            clearError={this.clearError}
            isEditViewModalOn={isEditViewModalOn}
            teamGUID={propertyInfo.agencyID}
            fetchAllPropertyFutureViewings={fetchAllPropertyFutureViewings}
          />
        )}
        {isCommentModalOn && (
          <AddCommentModal
            showCommentModal={this.onShowCommentModal}
            error={apiError}
            clearError={this.clearError}
            updateRenterStatusComment={this.onUpdateRenterStatusComment}
            renterInfo={renterInfo}
            isCommentModalOn={isCommentModalOn}
          />
        )}
        {tenantReadModal && (
          <TenantReadModal
            toggleModal={() => this.onClickEntryNotices()}
            tenantsWhoReadTheEntryNotice={tenantsWhoReadTheEntryNotice}
          />
        )}
        <div>
          {openTeamMemberModal && (
            <AssignViewingTeamMemberModal
              toggleModal={this.toggleTeamMemberModal}
              viewing={selectedViewing}
              teamId={teamId}
              assignViewingToTeamMember={assignViewingToTeamMember}
              fetchViewingTeamMembers={fetchViewingTeamMembers}
              deleteViewingTeamMember={deleteViewingTeamMember}
              updateViewingTeamMemberRelation={updateViewingTeamMemberRelation}
              propertyId={propertyId}
              viewingStartDate={formattedViewingStartDate.startTime}
              managersListForViewingFilters={getManagersListForViewingFilters}
            />
          )}
        </div>
        {!viewingErrorGuid && <sharedHelper.ErrorMessage error={error} />}
        {!viewingErrorGuid && <sharedHelper.ErrorMessage error={apiError} />}
      </div>
    )
  }
}

export default OwnerList
