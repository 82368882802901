import React from 'react'

import { Link } from 'react-router-dom'

import headerImg04 from 'app/assets/icons/header-img-04.png'

function IdentityStatusCheck() {
  return (
    <div className="two-col-box-flex-row sm64">
      <div className="col-first">
        <div className="identity-status-check error">
          <h3>Identity check</h3>

          <div className="ilustration-box">
            <img src={headerImg04} />
          </div>

          <div className="status">Document Expired</div>

          <div className="document">
            <div className="type">Passport 7890</div>
            <div className="expires">Expires 09/21</div>
          </div>

          <div className="message">
            Customer service will get in touch soon.
          </div>

          <Link className="btn" to="/bc/">
            Done
          </Link>
        </div>
      </div>
    </div>
  )
}

export default IdentityStatusCheck
