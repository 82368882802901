import { useEffect, useState } from 'react'

import {
  createInitialLoadingState,
  createLoadingStateUtils,
} from 'app/utils/loading-states'
import * as stringHelpers from 'app/utils/strings/helpers'

const createAttachmentId = ({ name, type, size }) =>
  `${name}__${type}__${size}__${new Date().valueOf()}`

export const useAttachments = (
  uploadAttachmentFn,
  deleteAttachmentFn,
  notifyAddAttachment,
) => {
  const [attachments, setAttachments] = useState({})
  const [toNotifyAdd, setToNotifyAdd] = useState([])

  useEffect(() => {
    if (!toNotifyAdd.length) return
    toNotifyAdd.forEach((fileId) => {
      notifyAddAttachment(fileId)
    })

    setToNotifyAdd([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toNotifyAdd])

  const loadingStateSetterFactory = (fileId) => (loadingState) =>
    setAttachments((prevState) => ({
      ...prevState,
      [fileId]: {
        ...prevState[fileId],
        loadingStates: loadingState,
      },
    }))

  const addAttachment = (file) => {
    const fileId = createAttachmentId(file)
    const isExisted = Object.values(attachments).some((attachment) => {
      const fileName = (attachment.file || attachment.rawAttachment).name
      return fileName === file.name
    })
    if (isExisted && !window.confirm(stringHelpers.isAttachmentExistMessage)) {
      return
    }

    const fileState = { file, loadingStates: createInitialLoadingState() }

    setAttachments((prevState) => ({
      ...prevState,
      [fileId]: fileState,
    }))

    setToNotifyAdd((prevState) => [...prevState, fileId])
  }

  const uploadAttachment = (fileId) => {
    const loadingStatesUtils = createLoadingStateUtils(
      loadingStateSetterFactory(fileId),
    )

    const { file } = attachments[fileId]

    loadingStatesUtils.startLoading()
    return uploadAttachmentFn(file)
      .then((rawAttachment) => {
        if (rawAttachment) {
          const { guid } = rawAttachment
          loadingStatesUtils.markDoneSuccessfully()
          setAttachments((prevState) => ({
            ...prevState,
            [fileId]: {
              ...prevState[fileId],
              rawAttachment,
              guid,
            },
          }))
        }
      })
      .catch((err) => loadingStatesUtils.setError(err))
  }

  const removeAttachmentFromState = (fileId) => {
    setAttachments((prevState) => {
      const tempAttachments = { ...prevState }
      delete tempAttachments[fileId]
      return tempAttachments
    })
  }

  const deleteAttachment = (fileId) => {
    const loadingStatesUtils = createLoadingStateUtils(
      loadingStateSetterFactory(fileId),
    )

    const attachment = attachments[fileId]

    if (!attachment.guid) {
      return removeAttachmentFromState(fileId)
    }

    loadingStatesUtils.startLoading()
    console.log('delete', fileId, attachment)
    return deleteAttachmentFn(attachment.guid)
      .then(() => {
        loadingStatesUtils.markDoneSuccessfully()
        removeAttachmentFromState(fileId)
      })
      .catch((err) => loadingStatesUtils.setError(err))
  }

  const setExistingAttachments = (attachmentsArr) => {
    attachmentsArr.forEach((attachedFile) => {
      const fileId = createAttachmentId(attachedFile)
      const fileState = {
        file: null,
        rawAttachment: attachedFile,
        guid: attachedFile.guid,
        loadingStates: createInitialLoadingState(),
      }
      setAttachments((prevState) => ({
        ...prevState,
        [fileId]: fileState,
      }))
    })
  }

  return {
    attachments,
    setExistingAttachments,
    addAttachment,
    uploadAttachment,
    deleteAttachment,
  }
}
