import { Tooltip as ReactTooltip } from 'react-tooltip'

import * as Display from 'app/components/display/display'
import {
  getPMSBrand,
  sendApplicantPMSSetting,
} from 'app/constants/pms.constants'
import { Checkbox } from 'app/shared_components/form_component'
import { RAY_WHITE_CONCIERGE_SEND_IF_NOT_SENT_LABEL } from 'app/utils/raywhiteconcierge/helpers'
import * as textHelpers from 'app/utils/text/helpers'
import { isSendApplicantEnabled } from 'config/features'

export const MarkAsLeasedModal = ({
  toggleModal,
  easyBondpayOptIn,
  isConciergeServiceEnabled,
  isConsoleCloudEnabled,
  activeSendingOnboardIntegrations,
  onChangemarkAsLeasedOptions,
  markAsLeasedOptions,
  onConfirmButtonClicked,
  teamSlug,
  integrationSettings = {},
}: any) => {
  const renderPMSListItem = ({ pms }: any) => {
    const brand = getPMSBrand(pms)
    if (!brand || !isSendApplicantEnabled(pms, teamSlug)) return

    const teamSetting = `${sendApplicantPMSSetting}.${brand.id}`
    if (!integrationSettings[teamSetting]) return

    return <li>Send application details to {brand.label}</li>
  }

  return (
    <Display.Modal
      toggleModal={() => toggleModal('')}
      title="Mark property as leased"
      hideButtonSection
      body={undefined}
      primaryButtonLabel={undefined}
      primaryButtonAction={undefined}
      secondaryButtonLabel={undefined}
      secondaryButtonAction={undefined}
    >
      <div className="fs16">
        <p className="mb10 fw500">Marking as leased will:</p>
        <ul>
          <li>Label the property as leased</li>
          <li>Stop the property receiving applications</li>
          <li>Send the automated utility referral if not already sent</li>
          <li>
            <b>Not</b> send an offer email
          </li>
          {easyBondpayOptIn && <li>Send easyBondpay lead</li>}
          {isConciergeServiceEnabled && (
            <li>{RAY_WHITE_CONCIERGE_SEND_IF_NOT_SENT_LABEL}</li>
          )}
          {activeSendingOnboardIntegrations &&
            activeSendingOnboardIntegrations.map(renderPMSListItem)}
          {isConsoleCloudEnabled && (
            <li>Send application details to Console Cloud</li>
          )}
        </ul>
        <div className="mt30 mb30">
          <Checkbox
            label="Archive now"
            id="archiveProperty"
            onChange={onChangemarkAsLeasedOptions('archiveProperty')}
            checked={markAsLeasedOptions.archiveProperty}
            checkboxLabelSpanClassName="fs16"
            toolTip={undefined}
          />
          <Checkbox
            label="Cancel future viewings, notify enquirers/registered and tenants"
            id="cancelFutureViewings"
            onChange={onChangemarkAsLeasedOptions('cancelFutureViewings')}
            checked={markAsLeasedOptions.cancelFutureViewings}
            checkboxLabelSpanClassName="fs16"
            toolTip={undefined}
          />
          <Checkbox
            label="Archive enquirers"
            id="archiveEnquirers"
            onChange={onChangemarkAsLeasedOptions('archiveEnquirers')}
            checked={markAsLeasedOptions.archiveEnquirers}
            checkboxLabelSpanClassName="fs16"
            toolTip={undefined}
          />
          <div>
            <ReactTooltip id="declineTimeOutDesciption">
              {textHelpers.DECLINE_OTHER_HOUSEHOLD_TOOLTIP_CONTENT}
            </ReactTooltip>
          </div>
          <Checkbox
            label="Decline other household groups for this property (except Approved Applicants)"
            id="declineUnsuccessfulApplications"
            onChange={onChangemarkAsLeasedOptions(
              'declineUnsuccessfulApplications',
            )}
            checked={markAsLeasedOptions.declineUnsuccessfulApplications}
            checkboxLabelSpanClassName="fs16"
            toolTip={
              <div>
                <i
                  className="icon-help-outline fs16 gray-light-color ibm"
                  data-tooltip-content=""
                  data-tooltip-id="declineTimeOutDesciption"
                />
              </div>
            }
          />
        </div>
      </div>
      <Display.ModalButtonGroup
        primaryLabel="Mark as leased"
        secondaryLabel="Cancel"
        secondaryButtonType="tertiary"
        primaryAction={() => onConfirmButtonClicked()}
        secondaryAction={() => toggleModal('')}
      />
    </Display.Modal>
  )
}
