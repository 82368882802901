import React from 'react'

import moment from 'moment'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import 'app/sm/renter_applications/renter_application_detailed/scss/components-style/app-document-list-item.scss'
import iconDocumentDoc from 'app/assets/icons/icon-document-doc.svg'
import iconDocumentImg from 'app/assets/icons/icon-document-img.svg'
import iconDocumentPdf from 'app/assets/icons/icon-document-pdf.svg'
import { Box, Flex } from 'app/components/design-system-components'
import { Text } from 'app/components/design-system-components/typography'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { isMobile } from 'app/shared_components/helpers'
import { setFileIcon, trimFileLengthTo30 } from 'app/sm/helpers'
import { trimAndAddEllipsesPastSpecifiedLength } from 'app/utils/strings/helpers'
import { UPLOADED_SENSITIVE_DOCUMENTS_NOTE } from 'app/utils/text/helpers'

const AttachmentContainer = styled(Box)`
  .disabled {
    pointer-events: none !important;
    cursor: pointer !important;
  }
`

class AppDocumentListItem extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { CreatedAt, name, URL } = this.props.document
    const { titleClass = '' } = this.props
    let date = moment(CreatedAt).format('DD-MM-YYYY')
    let fileExtension = name.substring(name.lastIndexOf('.') + 1, name.length)
    let fileIconName = setFileIcon(fileExtension)
    const documentIcons = {
      doc: iconDocumentDoc,
      img: iconDocumentImg,
      pdf: iconDocumentPdf,
    }
    return (
      <a
        href={URL}
        target="_blank"
        download={name}
        className={`block gray-dark-color ${
          titleClass ? titleClass : 'mt30'
        } xs-mt20`}
        rel="noreferrer"
      >
        <img
          className="ibm pr10"
          src={documentIcons[fileIconName]}
          height="25"
        />
        <span className="ibm details-document">{name}</span>
      </a>
    )
  }
}

export class AppDocumentListItemSecondVersion extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { name, URL, url, guidID, fileAutoDeletedAt } = this.props.document
    const {
      titleClass = '',
      fileBodyClass,
      docBadge,
      disabled,
      isSensitive,
      toggleDocumentsViewer,
      isAutoDeleted,
    } = this.props
    let fileExtension = name.substring(name.lastIndexOf('.') + 1, name.length)
    const linkUrl = URL || url
    const hasToggleDocumentsViewer = toggleDocumentsViewer !== undefined
    const autoDeletedDate =
      fileAutoDeletedAt && moment(fileAutoDeletedAt).format('DD MMM YYYY')
    const sensitiveDocToolTipText = fileAutoDeletedAt
      ? `Auto-deleted ${autoDeletedDate}`
      : UPLOADED_SENSITIVE_DOCUMENTS_NOTE
    const attachmentComponent = (
      <Flex alignItems="center">
        <div className="file-extension">{fileExtension.toUpperCase()}</div>
        <Box>
          <Text
            as="span"
            color={theme.colors.gray800}
            px={4}
            fontSize={`${theme.fontSizes[4]}px`}
            className={fileBodyClass}
            title={name}
          >
            {isMobile()
              ? trimAndAddEllipsesPastSpecifiedLength(name, 10)
              : trimFileLengthTo30(name)}
          </Text>
          {isAutoDeleted && (
            <>
              <br />
              <Text
                color={theme.colors.primary300}
                fontSize={theme.fontSizes.pSmall12}
                as="span"
                px={4}
              >
                (auto-deleted)
              </Text>
            </>
          )}
        </Box>
      </Flex>
    )
    return (
      <AttachmentContainer
        className={` no-page-break document-container document-container-2nd-layout display-flex ${
          titleClass ? titleClass : 'mt30'
        }`}
      >
        <Flex
          alignItems="center"
          width="100%"
          style={{ cursor: 'pointer' }}
          {...{
            'data-tooltip-id': `doc-attachment-${guidID}`,
            'data-tooltip-content': '',
          }}
        >
          {!hasToggleDocumentsViewer ? (
            <a
              href={disabled ? '#' : linkUrl}
              target="_blank"
              download={name}
              style={{ flex: '1 1 auto' }}
              className={disabled ? 'disabled' : ''}
              rel="noreferrer"
            >
              {attachmentComponent}
            </a>
          ) : (
            <Box onClick={toggleDocumentsViewer}>{attachmentComponent}</Box>
          )}
          {docBadge && <Box>{docBadge}</Box>}
        </Flex>
        {isSensitive && (
          <ReactTooltip id={`doc-attachment-${guidID}`} data-type="info">
            {sensitiveDocToolTipText}
          </ReactTooltip>
        )}
      </AttachmentContainer>
    )
  }
}

export default AppDocumentListItem
