import React from 'react'

import MyApplicationCard from 'app/sm/applications/components/my_application_card'
import SorterDropdown from 'app/sm/applications/components/sorter-dropdown'
import Spinner from 'app/sm/common/spinner'
import { SessionStorageUtils } from 'app/storage_utils'

class Applications extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchApplications()
    SessionStorageUtils.setItem('applicationBackUrl', window.location.pathname)
  }

  render() {
    const {
      applications,
      sortBy = 'rank',
      spinner = false,
      currentUser,
    } = this.props

    return spinner ? (
      <Spinner />
    ) : (
      <div>
        <div className="section-title">
          <div className="left">
            <h3>
              My Applications{' '}
              {applications.length > 0 ? `(${applications.length})` : ''}
            </h3>
          </div>
          <div className="right pr15">
            <SorterDropdown
              sortyBy={sortBy}
              onChange={this.props.onSorterDropdownChange}
            />
          </div>
        </div>
        <div className="sm-properties-card-list info-type">
          {applications.map((application, i) => (
            <MyApplicationCard
              property={application.offer.property}
              key={i}
              application={application}
              addToSavedProperty={this.props.addToSavedProperty}
              currentUser={currentUser}
            />
          ))}
        </div>
      </div>
    )
  }
}

export default Applications
