import { NetworkConnectionError } from 'app/constants/error_codes'
import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { parseUtcViewing, removeGuidFromArray } from 'app/sm/helpers'
import * as API from 'app/sm/inspections/inspections_access'
import { _updateViewing } from 'app/sm/ppp/viewing_access'

export const RECEIVE_PROPERTY_VIEWINGS = 'VI_RECEIVE_VIEWINGS'
export const RECEIVE_RESPONSE_TEXT = 'VI_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_ERROR = 'VI_RECEIVE_ERROR'
export const TOGGLE_WALKIN_MODAL = 'VI_TOGGLE_WALKIN_MODAL'
export const SHOW_LATE_VIEWING_MODAL = 'VI_SHOW_LATE_VIEWING_MODAL'
export const RECEIVE_VIEWING_ERROR = 'VI_RECEIVE_VIEWING_ERROR'
export const CLEAR_ERROR = 'VI_CLEAR_ERROR'
export const SHOW_EDIT_VIEWING_MODAL = 'VI_SHOW_EDIT_VIEWING_MODAL'
export const SHOW_COMMENT_MODAL = 'VI_SHOW_COMMENT_MODAL'
export const RECEIVE_FEEDBACK_QUESTIONS = 'VI_RECEIVE_FEEDBACK_QUESTIONS'
export const RECEIVE_FEEDBACK_STATUS = 'VI_RECEIVE_FEEDBACK_STATUS'
export const SHOW_CHECKBOX_SPINNER = 'VI_SHOW_CHECKBOX_SPINNER'
// Intercepted by Middleware
export const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
export const showLateViewingModal = (isLateModalOn, viewing) => ({
  type: SHOW_LATE_VIEWING_MODAL,
  isLateModalOn,
  viewing,
})
export const clearError = () => ({ type: CLEAR_ERROR })
export const receiveViewingError = (viewingErrorGuid) => ({
  type: RECEIVE_VIEWING_ERROR,
  viewingErrorGuid,
})
export const showEditViewingModal = (isEditViewModalOn, viewingTime) => ({
  type: SHOW_EDIT_VIEWING_MODAL,
  isEditViewModalOn,
  viewingTime,
})
export const showCommentModal = (isCommentModalOn, renterInfo) => ({
  type: SHOW_COMMENT_MODAL,
  isCommentModalOn,
  renterInfo,
})
export const receiveFeedbackQuestions = (feedbackQuestions) => ({
  type: RECEIVE_FEEDBACK_QUESTIONS,
  feedbackQuestions,
})
export const receiveFeedbackStatus = (feedbackStatus) => ({
  type: RECEIVE_FEEDBACK_STATUS,
  feedbackStatus,
})
export const showCheckboxSpinner = (checkboxSpinnerGuidArray) => ({
  type: SHOW_CHECKBOX_SPINNER,
  checkboxSpinnerGuidArray,
})
// export const sendSmsReport = smsStatus => ({ type: SEND_SMS_REPORT, smsStatus });
// Emitted by Middleware for Reducer
export const receiveError = (error) => ({ type: RECEIVE_ERROR, error })
export const toggleWalkInModal = (isWalkInModalOn, viewing) => ({
  type: TOGGLE_WALKIN_MODAL,
  isWalkInModalOn,
  viewing,
})

const receivePropertyViewings = (propertyViewings) => ({
  type: RECEIVE_PROPERTY_VIEWINGS,
  propertyViewings,
})

export const parseServerViewings = (viewings) =>
  viewings.map((v) => ({ ...v, viewing: parseUtcViewing(v.viewing) }))

export const fetchCurrentTenants = (teamId, propertyId) => {
  return () => {
    return API.getCurrentTenants(teamId, propertyId).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          return responseText.then((error) =>
            translateErrorCodeToMessage(error),
          )
        }
      },
    )
  }
}

export const fetchCurrentOwners = (teamId, propertyId) => {
  return () => {
    return API.getCurrentOwners(teamId, propertyId).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          return responseText.then((error) =>
            translateErrorCodeToMessage(error),
          )
        }
      },
    )
  }
}

export const fetchAgencyApplicantsElasticSearchResult = (
  teamId,
  queryString,
) => {
  return () => {
    return API.getAgencyApplicantsElasticSearchResult(teamId, queryString).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          return responseText.then((error) =>
            translateErrorCodeToMessage(error),
          )
        }
      },
    )
  }
}

export const fetchEnquirers = (teamId, propertyId) => {
  return () => {
    return API.getEnquirers(teamId, propertyId).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          return responseText.then((error) =>
            translateErrorCodeToMessage(error),
          )
        }
      },
    )
  }
}

export function fetchPropertyViewings({ registrationGUID, ...params }) {
  return (dispatch, getState) => {
    return API.getPropertyViewings(params).then(
      ({ ok, viewings, responseText }) => {
        const { checkboxSpinnerGuidArray } = getState().ViewingsReducer
        const newArray = registrationGUID
          ? removeGuidFromArray(checkboxSpinnerGuidArray, registrationGUID)
          : []

        if (ok) {
          dispatch(showCheckboxSpinner(newArray))
          dispatch(receivePropertyViewings(parseServerViewings(viewings)))
          return Promise.resolve(parseServerViewings(viewings))
        } else {
          dispatch(showCheckboxSpinner([]))
          return responseText
            .then((error) => translateErrorCodeToMessage(error))
            .then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export const sendEntryNotice = (offerId, data) => {
  return () => {
    return API.sendEntryNotice(offerId, data).then(({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      }
    })
  }
}

export const updateCurrentTenants = (propertyId, tenantId, data) => {
  return () => {
    return API.editCurrentTenants(propertyId, tenantId, data).then(
      ({ result, ok, responseText }) => {
        if (ok) {
          return Promise.resolve({ result })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const updateCurrentOwners = (teamId, ownerId, data) => {
  return () => {
    return API.editCurrentOwners(teamId, ownerId, data).then(
      ({ result, ok, responseText }) => {
        if (ok) {
          return Promise.resolve({ result })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const removeCurrentTenants = (propertyId, tenantId) => {
  return () => {
    return API.deleteCurrentTenant(propertyId, tenantId).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const removeCurrentOwners = (teamId, contactId, propertyId) => {
  return () => {
    return API.deleteCurrentOwner(teamId, contactId, propertyId).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const addCurrentTenants = (data, propertyId) => {
  return () => {
    return API.createCurrentTenants(data, propertyId).then(
      ({ result, ok, responseText }) => {
        if (ok) {
          return Promise.resolve({ result })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export const addCurrentOwners = (data, teamId) => {
  return () => {
    return API.createCurrentOwners(data, teamId).then(
      ({ result, ok, responseText }) => {
        if (ok) {
          return Promise.resolve({ result })
        } else {
          return responseText.then((error) =>
            Promise.reject(translateErrorCodeToMessage(error)),
          )
        }
      },
    )
  }
}

export function addWalkInRenter(data, propertyId, viewingId) {
  return (dispatch, getState) => {
    const { viewing = {} } = getState().ViewingsReducer
    data.propertyId = propertyId
    return API.createWalkInRenter(data, viewingId || viewing.guidID).then(
      ({ registrant, ok, responseText }) => {
        if (ok) {
          return Promise.resolve({ registrant })
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) => {
            return Promise.reject(translateErrorCodeToMessage(error))
          })
        }
      },
    )
  }
}

export function updateRenterStatus(
  payload,
  renterType,
  id,
  registrationGUID,
  propertyId,
) {
  return (dispatch, getState) => {
    const { checkboxSpinnerGuidArray } = getState().ViewingsReducer
    let newArray = Array.from(checkboxSpinnerGuidArray)
    newArray.push(registrationGUID)
    dispatch(showCheckboxSpinner(newArray))
    return API.alterRegisteredRenter(
      payload,
      renterType,
      id,
      registrationGUID,
    ).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(showCheckboxSpinner([]))
        return Promise.resolve()
      } else {
        dispatch(showCheckboxSpinner([]))
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText
          .then((error) => translateErrorCodeToMessage(error))
          .then((error) => Promise.reject(error))
      }
    })
  }
}

export function updateRenterStatusComment(payload) {
  return (dispatch, getState) => {
    const {
      renterInfo: { renterType, id, registrationGUID, interested, checkIn },
    } = getState().ViewingsReducer
    const data = { ...payload, interested, checkIn }

    return API.alterRegisteredRenter(
      data,
      renterType,
      id,
      registrationGUID,
    ).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(showCommentModal(false))
        return Promise.resolve()
      } else {
        return responseText
          .then((error) => translateErrorCodeToMessage(error))
          .then((error) => Promise.reject(error))
      }
    })
  }
}

export function removeOnsiteViewingRenter(viewingGUID, registrationGUID) {
  return (dispatch) => {
    return API.deleteOnsiteViewingRenter(viewingGUID, registrationGUID).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText
            .then((error) => translateErrorCodeToMessage(error))
            .then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export function notifyLateViewing(data) {
  return (dispatch, getState) => {
    const { viewing = {} } = getState().ViewingsReducer

    return API.sendLateViewingNotification(
      data,
      viewing.guidID || viewing.viewingGUID,
    ).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(showLateViewingModal(false))
        return Promise.resolve()
      } else {
        return responseText
          .then((error) => translateErrorCodeToMessage(error))
          .then((error) => Promise.reject(error))
      }
    })
  }
}

export function sendInviteToApplyToAllRegistrants(viewingGUID) {
  return (dispatch) => {
    dispatch(clearError())
    return API.sendApplyViewingNotification(viewingGUID).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText
            .then((error) => translateErrorCodeToMessage(error))
            .then((error) => Promise.reject(error))
        }
      },
    )
  }
}

export function sendIndividualInviteToApply(viewingGUID, registrantID) {
  return (dispatch) => {
    dispatch(clearError())
    return API.sendIndividualApplyViewingNotification(
      viewingGUID,
      registrantID,
    ).then(({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        return responseText
          .then((error) => translateErrorCodeToMessage(error))
          .then((error) => Promise.reject(error))
      }
    })
  }
}

export function cancelViewing(viewingGUID, propertyId) {
  return (dispatch) => {
    dispatch(clearError())
    return API.alterViewing(viewingGUID).then(({ ok, responseText }) => {
      if (ok) {
        return Promise.resolve()
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText
          .then((error) => translateErrorCodeToMessage(error))
          .then((error) => Promise.reject(error))
      }
    })
  }
}

export function editViewing(
  propertyId,
  offerId,
  viewingId,
  startdate,
  duration,
  skipDoubleBookingCheck,
  options,
) {
  return (dispatch) => {
    dispatch(clearError())
    return _updateViewing(
      offerId,
      viewingId,
      startdate,
      duration,
      skipDoubleBookingCheck,
      options,
    ).then(({ ok, viewing, responseText }) => {
      if (ok) {
        dispatch(showEditViewingModal(false))
        return Promise.resolve()
      } else {
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      }
    })
  }
}

export function fetchFeedbackQuestions() {
  return (dispatch) => {
    dispatch(clearError())
    API.getQuestionFeedback().then(
      ({ feedbackQuestions, ok, responseText }) => {
        if (ok) {
          dispatch(receiveFeedbackQuestions(feedbackQuestions))
        } else {
          responseText.then((t) =>
            dispatch(receiveResponseText(translateErrorCodeToMessage(t))),
          )
        }
      },
    )
  }
}

export function requestFeedback(viewingGUID, success) {
  return (dispatch) => {
    dispatch(clearError())
    return API.sendFeedbackRequest(viewingGUID).then(({ ok, responseText }) => {
      if (ok) {
        success && success()
      } else {
        if (responseText === undefined) {
          return Promise.reject(NetworkConnectionError)
        }
        return responseText
          .then((error) => translateErrorCodeToMessage(error))
          .then((error) => Promise.reject(error))
      }
    })
  }
}

export function sendFeedback(data, viewingGUID, feedbackGUID) {
  return (dispatch) => {
    dispatch(clearError())
    API.sendViewingFeedback(data, viewingGUID, feedbackGUID).then(
      ({ ok, responseText }) => {
        if (ok) {
          dispatch(fetchFeedbackStatus(viewingGUID, feedbackGUID))
        } else {
          responseText.then((t) =>
            dispatch(receiveResponseText(translateErrorCodeToMessage(t))),
          )
        }
      },
    )
  }
}

export function fetchFeedbackStatus(viewingGUID, feedbackGUID) {
  return (dispatch) => {
    dispatch(clearError())
    API.getViewingRequestStatus(viewingGUID, feedbackGUID).then(
      ({ feedbackStatus, ok, responseText }) => {
        if (ok) {
          dispatch(receiveFeedbackStatus(feedbackStatus))
        } else {
          responseText.then((t) =>
            dispatch(receiveResponseText(translateErrorCodeToMessage(t))),
          )
        }
      },
    )
  }
}

export function fetchPropertyAndFeedbackRequestFromShortCode(shortCode) {
  return (dispatch) => {
    dispatch(clearError())
    return API.getPropertyAndFeedbackRequestFromShortCode(shortCode).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          return responseText.then((t) =>
            dispatch(receiveResponseText(translateErrorCodeToMessage(t))),
          )
        }
      },
    )
  }
}

export function fetchViewingsByDateFromTeamId(teamGUID, filters) {
  return (dispatch) => {
    dispatch(clearError())
    return API.getViewingsByDate(teamGUID, filters).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        } else {
          if (responseText === undefined) {
            return Promise.reject(NetworkConnectionError)
          }
          return responseText.then((error) => {
            return Promise.reject(translateErrorCodeToMessage(error))
          })
        }
      },
    )
  }
}

export function completeRenterUpdateOnsiteViewing(registrationGUID) {
  return (dispatch) => {
    dispatch(clearError())
    return API.renterUpdateOnsiteViewingRegistrant(registrationGUID).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve()
        } else {
          return responseText
            .then((error) => translateErrorCodeToMessage(error))
            .then((t) => Promise.reject(t))
        }
      },
    )
  }
}

export function sendSmsReportToOwner(viewingId, mobileNumber) {
  return (dispatch) => {
    dispatch(clearError())
    return API.sendSmsReportToOwner(viewingId, mobileNumber).then(
      ({ ok, responseText }) => {
        if (ok) {
          return Promise.resolve({ isSendSmsReportModalOn: false })
        } else {
          responseText.then((t) =>
            dispatch(receiveResponseText(translateErrorCodeToMessage(t))),
          )
        }
      },
    )
  }
}
