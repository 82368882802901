import { connect } from 'react-redux'

import {
  addAgencySupplierSource,
  clearError,
  fetchAllSupplierSources,
  fetchSupplierSourceForAgency,
  refreshAgencySourceSupplier,
  updateAgencySupplierSource,
} from 'app/bond_cover/agency/agency_actions'
import AgencySourceContainer from 'app/bond_cover/agency/agency_source_form_container'

const mapStateToProps = ({ agency }) => ({
  error: agency.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchAllSupplierSources: (success) =>
    dispatch(fetchAllSupplierSources(success)),
  fetchSupplierSourceForAgency: (agencyGUID, success) =>
    dispatch(fetchSupplierSourceForAgency(agencyGUID, success)),
  updateAgencySupplierSource: (agencySource, success) =>
    dispatch(updateAgencySupplierSource(agencySource, success)),
  addAgencySupplierSource: (agencySource, success) =>
    dispatch(addAgencySupplierSource(agencySource, success)),
  clearError: () => dispatch(clearError()),
  refreshAgencySourceSupplier: (agencySourceGUID) =>
    dispatch(refreshAgencySourceSupplier(agencySourceGUID)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgencySourceContainer)
