import React from 'react'

import styled from 'styled-components'
import { layout } from 'styled-system'

// almost used the following pen https://codepen.io/tmac/pen/QgVRKb

const ProgressBarContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary100};
  width: 100%;
  overflow: hidden;
  ${layout}
`

const ProgressBarIndicator = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary400};
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0 50%;

  @keyframes indeterminateAnimation {
    0% {
      transform: translateX(0) scaleX(0);
    }
    40% {
      transform: translateX(0) scaleX(0.4);
    }
    100% {
      transform: translateX(100%) scaleX(0.6);
    }
  }
`

const IndeterminateProgressBar = ({ height = '3px' }) => {
  return (
    <ProgressBarContainer height={height}>
      <ProgressBarIndicator />
    </ProgressBarContainer>
  )
}

export default IndeterminateProgressBar
