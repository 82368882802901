import { connect } from 'react-redux'

import {
  createTenantRating,
  fetchBondCoverRatingCategories,
  fetchBondCoverReview,
  proceedBondCoverRequest,
  updateTenantRating,
} from 'app/bond_cover/bond_cover_actions'
import RatingForm from 'app/bond_cover/managers/review/bc_rating_form_container'

const mapStateToProps = ({ session, bondCover }) => ({
  currentUser: session.currentUser,
  bondCoverRequest: bondCover.request,
  currentLease: bondCover.currentLease,
  ratingCategories: bondCover.ratingCategories,
  tenantRating: bondCover.tenantRating,
  renter: bondCover.renter,
  error: bondCover.error,
  message: bondCover.message,
})

const mapDispatchToProps = (dispatch) => ({
  fetchBondCoverReview: (requestID, secret) =>
    fetchBondCoverReview(requestID, secret),
  fetchBondCoverRatingCategories: () => fetchBondCoverRatingCategories(),
  createTenantRating: (ratingCode, rating, requestID, secret) =>
    createTenantRating(ratingCode, rating, requestID, secret),
  updateTenantRating: (ratingCode, rating, requestID, secret) =>
    updateTenantRating(ratingCode, rating, requestID, secret),
  proceed: (requestID, secret, comment, isVerified) =>
    proceedBondCoverRequest(requestID, secret, comment, isVerified),
})

export default connect(mapStateToProps, mapDispatchToProps)(RatingForm)
