import { useState } from 'react'

import {
  Alert,
  Box,
  Button,
  DropElem,
  DropZoneContainer,
  ErrorUploading,
  FilesDropzone,
  Flex,
  TextSpan,
  UploadingFile,
} from 'app/components/design-system-components'
import { CheckCircleRounded } from 'app/components/design-system-components/icons/actions'
import {
  ErrorRounded,
  WarningRounded,
} from 'app/components/design-system-components/icons/alert'
import { fontWeight } from 'app/components/design-system-components/styles/variables'
import { Heading } from 'app/components/design-system-components/typography'
import { UploadFileStatuses } from 'app/components/design-system-components/uploads/statuses'
import theme from 'app/match/applicationReportPDF/assets/theme'

type Log = {
  message: string
  status: string
}

export type ImportResp = {
  summary: { total: number; success: number; fail: number }
  logs?: Log[]
  failed_records_file?: any
}

type ImporterInput = {
  statusConfig: {
    status: string
    error: string | undefined
    response: ImportResp | null
  }
  selectedFile: { path: string } | null
  onDrop: Function
  onMoveToReady: Function
  onGoTo: Function
  onCancel: Function
  entity: string
}

const FailedMessage = ({ failedFileConfig, entity }: any) => {
  const failedFileBlob = new Blob([failedFileConfig.data], failedFileConfig)
  const fileUrl = URL.createObjectURL(failedFileBlob)
  const { filename } = failedFileConfig
  return (
    <Box>
      <Box>
        <TextSpan>
          You can download failed {entity} file{' '}
          <a href={fileUrl} download={filename}>
            here
          </a>
          , update and upload it
        </TextSpan>
      </Box>
    </Box>
  )
}

const LogsSection = ({ logs }: any) => {
  return (
    <Alert variant="warningWithBg">
      <ul>
        {logs.map((log: Log) => (
          <Box as="li" mb={theme.space[2] + 'px'}>
            {log.message}
          </Box>
        ))}
      </ul>
    </Alert>
  )
}

const ReadyState = ({ onDrop }: any) => {
  return (
    <FilesDropzone
      dropElementRenderer={() => <DropElem mainActionText="Upload a csv" />}
      onDrop={onDrop}
      accept=".csv"
      multiple={false}
    />
  )
}

const DoneState = ({
  statusConfig: { response },
  onMoveToReady,
  onGoTo,
  entity,
}: any) => {
  const [showLogs, setShowLogs] = useState(false)

  const { logs, summary, failed_records_file } = response || {}
  const { total, success, fail } = summary || {}

  const failedFileMessageElem = !!fail && (
    <Box mt={theme.space[3] + 'px'}>
      <FailedMessage failedFileConfig={failed_records_file} entity={entity} />
    </Box>
  )

  const errorLogs = logs && logs.filter((log: Log) => log.status === 'Failed')
  const hasLogs = errorLogs && !!errorLogs.length

  const logsElem = hasLogs && showLogs && (
    <Box
      mt={theme.space[4] + 'px'}
      mb={theme.space[4] + 'px'}
      maxWidth="70%"
      maxHeight="300px"
      overflow="auto"
    >
      <LogsSection logs={errorLogs} />
    </Box>
  )

  const toggleLogs = () => setShowLogs((state) => !state)

  const summaryText = (
    <Box lineHeight="1.3" mt={theme.space[3] + 'px'}>
      Importing {total} {entity}, imported{' '}
      <TextSpan color={theme.colors.success700} fontWeight={fontWeight[4]}>
        {success} {entity}
      </TextSpan>{' '}
      successfully and{' '}
      <TextSpan color={theme.colors.primary600} fontWeight={fontWeight[4]}>
        {fail} {entity}
      </TextSpan>{' '}
      failed{' '}
    </Box>
  )

  const actions = (
    <Flex alignItems="center" justifyContent="end" mt={theme.space[3] + 'px'}>
      {hasLogs && (
        <Button variant="flat" onClick={toggleLogs}>
          {showLogs ? 'Hide' : 'Show'} Logs
        </Button>
      )}
      <Button variant="flatBlue" onClick={onGoTo}>
        Go to {entity}
      </Button>
      <Button variant="solidSuccess" onClick={onMoveToReady}>
        Import New File
      </Button>
    </Flex>
  )

  const getResultIcon = () => {
    switch (fail) {
      case 0:
        return <CheckCircleRounded />
      case total:
        return <ErrorRounded />
    }
    return <WarningRounded />
  }

  const getProcessColor = () => {
    switch (fail) {
      case 0:
        return theme.colors.success700
      case total:
        return theme.colors.error500
    }
    return theme.colors.warning400
  }

  return (
    <DropZoneContainer cursor="unset">
      <Box textAlign="center" color={theme.colors.gray500}>
        <Box
          fontSize="35px"
          color={getProcessColor()}
          mb={theme.space[3] + 'px'}
        >
          {getResultIcon()}
        </Box>
        <Box>
          <Heading level={3} displayingLevel={4} color={getProcessColor()}>
            Processing Finished
          </Heading>
          {summaryText}
        </Box>
      </Box>
      {failedFileMessageElem}
      {logsElem}
      {actions}
    </DropZoneContainer>
  )
}

export const FileImporter = ({
  statusConfig,
  selectedFile,
  onDrop,
  onMoveToReady,
  onGoTo,
  onCancel,
  entity,
}: ImporterInput) => {
  const { status, error } = statusConfig

  if (status === UploadFileStatuses.ready) {
    return <ReadyState onDrop={onDrop} />
  } else if (status === UploadFileStatuses.loading) {
    return (
      <UploadingFile
        uploadingText={`Uploading ${selectedFile?.path}`}
        onCancel={onCancel}
      />
    )
  } else if (status === UploadFileStatuses.error) {
    return (
      <ErrorUploading
        errorDetailedMessage={error}
        onRetry={() => onMoveToReady()}
      />
    )
  } else if (
    status === UploadFileStatuses.done ||
    status === UploadFileStatuses.doneWithErrors
  ) {
    return (
      <DoneState
        statusConfig={statusConfig}
        onGoTo={onGoTo}
        onMoveToReady={onMoveToReady}
        entity={entity}
      />
    )
  } else return null
}
