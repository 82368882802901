import { Box, TextSpan } from 'app/components/design-system-components'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

type Props = {
  propertyAddress: string
}

export const PropertyDisclosureHeader = ({ propertyAddress }: Props) => {
  return (
    <>
      <TextSpan
        as="div"
        fontSize={theme.fontSizes.pSurplusLarge24}
        fontWeight={theme.fontWeights.h1}
      >
        Disclosures by the Rental Provider to the Renter for {propertyAddress}
      </TextSpan>

      <Box my={5}>
        A Disclosure Statement is required for each new lease or renewal.
      </Box>
    </>
  )
}
