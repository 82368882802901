import { fetchTeamProperties } from 'app/bond_cover/agency/agency_access'
import { mapLegacyAccessPromiseToServiceFnPromise } from 'app/services/http/utils'
import { getPropertyManagerSummary } from 'app/sm/property_details/property_details_access'
import { api } from 'app/utils/api/helpers'

// just a wrapper around the legacy function to use same API to replace it later with axios base one
export const searchProperty = (teamId, searchText) => {
  return mapLegacyAccessPromiseToServiceFnPromise(
    fetchTeamProperties(teamId, { searchText }),
  )
}
export const getPropertySummary = (propertyId) => {
  return mapLegacyAccessPromiseToServiceFnPromise(
    getPropertyManagerSummary(propertyId),
  )
}

export const searchAllProperties = (teamId, searchText) => {
  return api.get(`/sm/teams/${teamId}/properties/search-all`, {
    params: {
      address: searchText,
      limit: 8,
    },
  })
}

export const sendInviteToApply = (teamId, offerId, invitePayload) => {
  return api.post(
    `sm/agencies/${teamId}/offers/${offerId}/invite`,
    invitePayload,
  )
}

export const getExcludedDates = (teamGUID, propertyGUID) => {
  return api.get(`/sm/teams/${teamGUID}/${propertyGUID}/excluded-dates`)
}

export const updateExcludedDates = (teamGUID, propertyGUID, payload) => {
  return api.put(
    `/sm/teams/${teamGUID}/${propertyGUID}/excluded-dates`,
    payload,
  )
}
