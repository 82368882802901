import { createContext, useEffect, useState } from 'react'

import { fetchActivities } from 'app/services/http/activityfeed'
import {
  allTab,
  DefaultActivitiesListingLimit,
  MeTab,
  TeamTab,
} from 'app/shared_components/activity_feed/activity_feed.const'

const ActivityFeedContext = createContext({
  openTab: {},
  setOpenTab: (e) => {},

  hasFeed: false,
  setHasFeed: (e = false) => {},

  showNavigator: false,
  setShowNavigator: (e = false) => {},

  teamActivities: {},
  setTeamActivities: (e = {}) => {},
  currentUserActivities: {},
  setCurrentUserActivities: (e = {}) => {},

  tabUtilityMap: {},

  currentUser: {},
  currentTeam: {},
  currentAgency: {},

  fetchingNew: false,
  setFetchingNew: (e) => {},

  fetchingOld: false,
  setFetchingOld: (e) => {},

  fetchInitialActivityFeedItems: () => {},
  fetchActivitiesOnEndScroll: () => {},
  fetchNewActivities: () => {},
})

const initialActivities = {
  hasMoreFeed: true,
  activities: [],
}

const ActivityFeedContextProvider = ({
  children,
  teamGUID,
  currentUser,
  currentTeam,
  hasNewActivityFeedConfig,
  currentAgency,
}) => {
  const [hasFeed, setHasFeed] = useState(false)
  const [showNavigator, setShowNavigator] = useState(false)
  const [teamActivities, setTeamActivities] = useState(initialActivities)
  const [currentUserActivities, setCurrentUserActivities] =
    useState(initialActivities)
  const [openTab, setOpenTab] = useState(allTab)
  const [fetchingNew, setFetchingNew] = useState(true)
  const [fetchingOld, setFetchingOld] = useState(false)

  const tabUtilityMap = {
    [MeTab]: {
      actRef: currentUserActivities,
      actSetter: setCurrentUserActivities,
    },
    [TeamTab]: {
      actRef: teamActivities,
      actSetter: setTeamActivities,
    },
  }

  const currentTabName = openTab.key
  const { actRef, actSetter } = tabUtilityMap[currentTabName]

  useEffect(() => {
    // if navigator is opened and has new activity then fetch the new activity
    if (showNavigator && hasNewActivityFeedConfig?.hasNewActivityFeed) {
      fetchNewActivities()
      return
    }

    setHasFeed(hasNewActivityFeedConfig?.hasNewActivityFeed || false)
  }, [hasNewActivityFeedConfig])

  useEffect(() => {
    if (openTab.key === MeTab) {
      fetchInitialActivityFeedItems()
    }
  }, [openTab])

  useEffect(() => {
    setTeamActivities(initialActivities)
    setCurrentUserActivities(initialActivities)
    fetchInitialActivityFeedItems()
  }, [teamGUID])

  const _fetchActivitiesWithDefaultParams = (params = {}) => {
    const _params = { ...params }
    if (openTab.key === MeTab) {
      _params['user_guid'] = currentUser.guidID
    }
    return fetchActivities(teamGUID, _params)
  }

  const fetchInitialActivityFeedItems = () => {
    if (!teamGUID) {
      return
    }

    setFetchingNew(true)

    _fetchActivitiesWithDefaultParams()
      .then((response) => {
        actSetter({
          hasMoreFeed: teamActivities.hasMoreFeed,
          activities: [...response],
        })
      })
      .finally(() => setFetchingNew(false))
  }

  const fetchNewActivities = () => {
    const activities = teamActivities.activities

    if (!hasNewActivityFeedConfig.hasNewActivityFeed || !activities[0]?.guid)
      return

    setFetchingNew(true)

    fetchActivities(teamGUID, { after_guid: activities[0].guid })
      .then((response) => {
        setTeamActivities({
          hasMoreFeed: teamActivities.hasMoreFeed,
          activities: [...response, ...teamActivities.activities],
        })

        // if navigator was open and a personal feed fetched, push to the personal tab activities also.
        // NOTE: if no personal activities fetched yet, then an initial call will be sent on switching to the personal tab.
        if (currentUserActivities.activities.length) {
          const currentUserNewFeed = response.filter(
            (activity) =>
              activity.data.notified_user_guid &&
              activity.data.notified_user_guid === currentUser.guidID,
          )

          if (currentUserNewFeed.length) {
            setCurrentUserActivities({
              hasMoreFeed: currentUserActivities.hasMoreFeed,
              activities: [
                ...currentUserNewFeed,
                ...currentUserActivities.activities,
              ],
            })
          }
        }
      })
      .finally(() => setFetchingNew(false))
  }

  const fetchActivitiesOnEndScroll = () => {
    if (!actRef.hasMoreFeed) {
      return
    }

    const latestActivity = actRef.activities[actRef.activities.length - 1]
    if (!latestActivity) {
      return
    }

    const { guid } = latestActivity

    setFetchingOld(true)

    _fetchActivitiesWithDefaultParams({ before_guid: guid })
      .then((response) => {
        actSetter({
          hasMoreFeed: response.length >= DefaultActivitiesListingLimit,
          activities: [...actRef.activities, ...response],
        })
      })
      .finally(() => setFetchingOld(false))
  }

  const ctx = {
    openTab,
    setOpenTab,
    hasFeed,
    setHasFeed,
    showNavigator,
    setShowNavigator,
    teamActivities,
    setTeamActivities,
    currentUserActivities,
    setCurrentUserActivities,
    tabUtilityMap,
    fetchingNew,
    setFetchingNew,
    fetchingOld,
    setFetchingOld,

    currentUser,
    currentTeam,
    currentAgency,

    fetchInitialActivityFeedItems,
    fetchActivitiesOnEndScroll,
    fetchNewActivities,
  }

  return (
    <ActivityFeedContext.Provider value={ctx}>
      {children}
    </ActivityFeedContext.Provider>
  )
}

export { ActivityFeedContext, ActivityFeedContextProvider }
