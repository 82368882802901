import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import {
  deleteAgencyAttachment,
  getLeasingOfferInfo,
  getLeasingOfferInfoBySlug,
  postLeaseOffer,
  uploadAgencyAttachment,
} from 'app/match/leaseAttachment/access'
import { composeAttachment } from 'app/shared_components/helpers'

export const fetchLeasingOfferInfo = (applicationId, agencyId) => {
  return () => {
    return getLeasingOfferInfo(applicationId, agencyId).then(
      ({ ok, result, statusCode, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        }
        return responseText.then((t) => {
          return Promise.reject({
            code: statusCode,
            message: translateErrorCodeToMessage(t),
          })
        })
      },
    )
  }
}

export const fetchLeasingOfferInfoBySlug = (slug) => {
  return () => {
    return getLeasingOfferInfoBySlug(slug).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        }
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      },
    )
  }
}

export const sendLeaseOffer = (applicationId, agencyId, payload) => {
  return () => {
    return postLeaseOffer(applicationId, agencyId, payload).then(
      ({ ok, result, responseText }) => {
        if (ok) {
          return Promise.resolve(result)
        }
        return responseText.then((t) => {
          return Promise.reject(translateErrorCodeToMessage(t))
        })
      },
    )
  }
}

export const addAttachmentForAgency = (applicantID, agencyId, scope) => () => {
  const attachments = document.getElementById(`attachments-${scope}`)
  const form = new FormData()

  composeAttachment(attachments, form)

  return uploadAgencyAttachment(form, applicantID, agencyId, scope).then(
    ({ ok, responseText, result }) => {
      if (ok) {
        return Promise.resolve(result)
      }
      return responseText.then((error) =>
        Promise.reject(translateErrorCodeToMessage(error)),
      )
    },
  )
}

export const removeAttachmentForAgency = (
  applicantID,
  agencyId,
  key,
  scope,
) => {
  return () => {
    return deleteAgencyAttachment(applicantID, agencyId, key, scope).then(
      ({ ok, responseText, result }) => {
        if (ok) {
          return Promise.resolve(result)
        }
        return responseText.then((error) =>
          Promise.reject(translateErrorCodeToMessage(error)),
        )
      },
    )
  }
}
