import React from 'react'

import moment from 'moment'
import { Slide, toast } from 'react-toastify'

import Text from 'app/components/text/text'
import ErrorMessages from 'app/constants/error_messages'

const LOGIN_ERROR_MESSAGE = 'please login to perform this action'
const VERIFICATION_ERROR_MESSAGE = 'has not been verified'
const TOAST_SUCCESS = 'success'
const TOAST_ERROR = 'error'
const TOAST_WARNING = 'warning'

export const apiFailCallback = (responseText, fail) => {
  return !!fail
    ? responseText.then((text) =>
        fail(
          ErrorMessages[parseInt(text)]
            ? ErrorMessages[parseInt(text)]
            : `There is an error: ${text}`,
        ),
      )
    : ''
}

export const paymentStatus = [undefined, 'Pending', 'Success', 'Error']

export const pauseStateStatus = [
  'Not Paused',
  'Paused After Renter Submission',
  'Paused After Manager Action',
  'Paused Before Renter IDCheck',
  'Paused Before Bc Certificate',
]

export const managerActionStatus = [
  'Manager No Action',
  'Manager Requested Change',
  'Manager Declined',
  'Manager Approved',
]

export const IDCheckStatus = [
  'ID Not Checked',
  undefined,
  'ID Check Failed',
  'ID Check Succeeded',
]

export const ExitStatus = [
  'Exit Status In Progress',
  'Exit Status Completed',
  'Exit Status Manager Declined',
  'Exit Status Admin Declined',
  'Exit Status Renter Withdrawn',
]

export const intTelTop10List = [
  'au',
  'cn',
  'de',
  'hk',
  'in',
  'my',
  'sg',
  'nz',
  'gb',
  'us',
]

const sharedCost = 'SHARED_COST'
const tollFree = 'TOLL_FREE'
const fixedLine = 'FIXED_LINE'
const unknown = 'UNKNOWN'
const mobile = 'MOBILE'

export const isValidLandlineNumber = (numberType) => {
  return !(
    numberType === mobile ||
    numberType === unknown ||
    numberType === undefined
  )
}

export const isValidMobileNumber = (numberType) => {
  return !(
    numberType === sharedCost ||
    numberType === tollFree ||
    numberType === fixedLine ||
    numberType === unknown ||
    numberType === undefined
  )
}

export const isMobile = () => {
  let mobile = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      mobile = true
  })(window.navigator.userAgent || window.navigator.vendor || window.opera)

  return mobile
}

export default class helpers {
  formatTime(date) {
    let formattedDate = moment
      .utc(date, 'YYYY-MM-DD HH:mm:ss')
      .format('YYYYMMDDTHHmmssZ')
    return formattedDate.replace('+00:00', 'Z')
  }

  buildUrl(event, isCrappyIE) {
    let calendarUrl = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'URL:' + document.URL,
      'DTSTART:' + this.formatTime(event.startTime),
      'DTEND:' + this.formatTime(event.endTime),
      'SUMMARY:' + event.title,
      'DESCRIPTION:' + event.description,
      'LOCATION:' + event.location,
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n')

    if (!isCrappyIE && isMobile()) {
      calendarUrl = encodeURI('data:text/calendar;charset=utf8,' + calendarUrl)
    }

    return calendarUrl
  }
}

export const invalidDocExtensionExists = (attachmentArr, type) => {
  const fileType = type || 'pic'
  const extensionArray =
    fileType === 'doc'
      ? [
          'svg',
          'png',
          'doc',
          'docx',
          'xlsx',
          'pages',
          'pdf',
          'odt',
          'ods',
          'txt',
          'tif',
          'png',
          'jpg',
          'jpeg',
        ]
      : ['svg', 'png', 'tif', 'png', 'jpg', 'jpeg']
  return attachmentArr.find((item) => {
    if (item) {
      const lowerCaseName = (item || {}).name ? item.name.toLowerCase() : ''
      let extension = lowerCaseName.substring(
        lowerCaseName.lastIndexOf('.') + 1,
        lowerCaseName.length,
      )
      return !extensionArray.includes(extension)
    }
    return false
  })
}

export const invalidDocSizeExists = (attachmentArr) => {
  let firstInvalidAttachment = {}
  firstInvalidAttachment = attachmentArr.find((item) => {
    return (firstInvalidAttachment = item.size / 1024 / 1024 >= 10)
  })
  return firstInvalidAttachment
}

export const composeAttachment = (attachments, formData) => {
  for (let i = 0; i < attachments.files.length; i++) {
    formData.append('file', attachments.files[i])
  }
  return formData
}

export const composeBrandingFiles = (file, formData, type) => {
  formData.append(type, file)
  return formData
}

export const composeFilesWithFieldNames = (files, formData, fieldName) => {
  for (let i = 0; i < files.length; i++) {
    formData.append(fieldName, files[i])
  }
  return formData
}

export const getApplicationStatus = (request, payment, lease) => {
  const {
    submissionStatus,
    SecondaryTenantsApproved,
    pauseState,
    idCheckStatus,
    exitStatus,
    managerAction,
  } = request
  const { isMultiTenantLease } = lease
  const alreadyPaid = payment ? payment.paid : false

  let applicationStatus = 'Unknown'

  const isInProgressApplication = exitStatus === 0
  const isDeclinedApplicationByManager = exitStatus === 2
  const isDeclinedApplicationByAdmin = exitStatus === 3
  const isCertifiedApplication = exitStatus === 1
  const isWithdrawnApplication = exitStatus === 4

  const applicationNotSubmitted = submissionStatus === 0
  const partiallySubmitted = submissionStatus === 1

  const notPaused = pauseState === 0
  const pausedAfterRenterSubmission = pauseState === 1
  const pausedAfterManagerAction = pauseState === 2
  const pausedBeforeRenterIDCheck = pauseState === 3
  const pausedBeforeBcCertificate = pauseState === 4

  const managerNoAction = managerAction === 0
  const managerApproved = managerAction === 3

  const idCheckSucceed = idCheckStatus === 3
  const idCheckFailed = idCheckStatus === 2
  const idCheckProccessing = idCheckStatus === 0

  const paymentNotStarted = !payment

  if (applicationNotSubmitted && isInProgressApplication) {
    applicationStatus = 'Not Submitted'
  } else if (
    isMultiTenantLease &&
    !SecondaryTenantsApproved &&
    isInProgressApplication
  ) {
    applicationStatus = 'Waiting for secondary renters'
  } else if (pausedAfterRenterSubmission && isInProgressApplication) {
    applicationStatus = 'Paused before release to manager'
  } else if (notPaused && managerNoAction && isInProgressApplication) {
    applicationStatus = 'Waiting for manager action'
  } else if (pausedAfterManagerAction && isInProgressApplication) {
    applicationStatus = 'Paused after manager action'
  } else if (
    notPaused &&
    managerApproved &&
    partiallySubmitted &&
    isInProgressApplication
  ) {
    applicationStatus = 'Waiting for final details'
  } else if (pausedBeforeRenterIDCheck && isInProgressApplication) {
    applicationStatus = 'Pause before ID check'
  } else if (idCheckFailed && paymentNotStarted && isInProgressApplication) {
    applicationStatus = 'ID check failed'
  } else if (idCheckSucceed && paymentNotStarted && isInProgressApplication) {
    applicationStatus = 'ID check succeed'
  } else if (
    idCheckProccessing &&
    paymentNotStarted &&
    isInProgressApplication
  ) {
    applicationStatus = 'ID check processing'
  } else if (alreadyPaid && isInProgressApplication) {
    applicationStatus = 'Paid but certificate not issued'
  } else if (pausedBeforeBcCertificate && isInProgressApplication) {
    applicationStatus = 'Paused before issuing certificate'
  } else if (payment && paymentStatus[payment.status] === 'Pending') {
    applicationStatus = 'Payment is pending'
  } else if (payment && paymentStatus[payment.status] === 'Success') {
    applicationStatus = 'Payment is successful'
  } else if (payment && paymentStatus[payment.status] === 'Error') {
    applicationStatus = 'Payment is failed'
  } else if (isDeclinedApplicationByManager) {
    applicationStatus = 'Declined by manager'
  } else if (isDeclinedApplicationByAdmin) {
    applicationStatus = 'Declined by admin'
  } else if (isCertifiedApplication) {
    applicationStatus = 'Certificate issued'
  } else if (isWithdrawnApplication) {
    applicationStatus = 'Withdrawn by renter'
  }

  return applicationStatus
}

const createManualSentryEntry = (source, error) => {
  const { Raven } = window
  if (
    typeof error === 'string' &&
    (error.toLowerCase().includes(LOGIN_ERROR_MESSAGE) ||
      error.toLowerCase().includes(VERIFICATION_ERROR_MESSAGE))
  ) {
    return
  }
  Raven.captureException(error, { tags: { source } })
}

const LogSentryError = (source, error) => {
  createManualSentryEntry(source, error)
  return Promise.reject({
    statusCode: 401,
    responseText: Promise.resolve('Network connection error'),
  })
}

export const LogFetchError = (error) => {
  // NOTE: Disabling as overloading sentry.io logging
  // Removed in https://app.clubhouse.io/snugco/story/37342/improve-fe-exception-logging
  // return LogSentryError('Fetch', error);

  // in different places of codebase, it's expected the following lines of code from `LogSentryError` is running in `catch` method for `fetch`
  // for more info https://app.clubhouse.io/snugco/story/63001/add-improved-exception-handling-when-network-connection-unavailable
  return Promise.reject({
    statusCode: 401,
    responseText: Promise.resolve('Network connection error'),
  })
}

export const isLoggedIn = (currentUser) => {
  return (
    currentUser &&
    !(currentUser.isUserFetching || currentUser.isUserProfileFetching) &&
    currentUser.email
  )
}

export const ErrorMessage = ({ error = '' }) => (
  <div className={error ? 'alert alert-danger' : 'hidden'}>{error}</div>
)

export const FormSeperator = ({ text = '', componentClass = '' }) => (
  <div
    className={`form-seperator display-flex justify-content-center align-items-center ${componentClass}`}
  >
    <div className="text-area">{text}</div>
  </div>
)

export const GrossAnnualIncome = ({ grossAnnualIncome = 0, tableRow }) => {
  const cell = tableRow ? (
    <div className="flex-row-center pb10">
      <span className="ibm fw600 pr10 mra w45p">Gross annual income:</span>
      <span className="ibm w65p">${grossAnnualIncome.toFixed(2)}</span>
    </div>
  ) : (
    <div>
      {Text.placeholder.annualIncomePlaceholder}: ${grossAnnualIncome}
      <br />
    </div>
  )

  return grossAnnualIncome &&
    parseInt(grossAnnualIncome) &&
    grossAnnualIncome >= 0
    ? cell
    : null
}

export const TitleHeader = ({
  header = '',
  headerClass = '',
  headerBoxClass = '',
}) => {
  return (
    <div className={headerBoxClass}>
      <h4 className={headerClass}>{header}</h4>
    </div>
  )
}

export const isMobileOrTablet =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  )

export const logAPIError = (error) => LogFetchError(error)
export const checkStatus = (response) => {
  if (response.ok) return Promise.resolve(response.json())

  return Promise.reject({
    statusCode: response.statusCode,
    responseText: response.text(),
  })
}

export const returnAPIError = (error) => ({
  ok: false,
  responseText: error.responseText,
  statusCode: error.statusCode,
})

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0
}

export const isObjectEmptyString = (obj) => {
  for (let key in obj) {
    if (obj[key] != '') return false
  }
  return true
}

export const openToaster = (status, message) => {
  // [MORE INFO] more styles for react-toastify depending on status: https://fkhadra.github.io/react-toastify/introduction/
  let toastOptions = {
    autoClose: 3000,
    bodyClassName: 'custom-toast-text',
    className: `custom-toast ${status}`,
    closeButton: false,
    hideProgressBar: true,
    position: 'top-center',
    transition: Slide,
  }

  if (status === TOAST_WARNING) {
    toastOptions = {
      ...toastOptions,
      bodyClassName: 'custom-toast-text width-auto',
      autoClose: 5000,
    }
  }

  if (status === TOAST_SUCCESS) {
    toast.success(message, toastOptions)
  } else if (status === TOAST_ERROR) {
    toast.error(message, toastOptions)
  } else if (status === TOAST_WARNING) {
    toast.warn(message, toastOptions)
  }
}
