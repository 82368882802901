import { Alert, Box } from 'app/components/design-system-components'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import theme from 'app/match/applicationReportPDF/assets/theme'
import { useLoadingStates } from 'app/utils/hooks/useLoadingStates'
import { loadingStatesIds } from 'app/utils/loading-states'

export const BackgroundCheckExpiryModal = ({
  closeModal,
  discardResult,
  validityPeriodInMonths,
}) => {
  const { promiseWrapper, loadingStates } = useLoadingStates()

  const onDiscard = () => {
    const discardPromise = discardResult().then(() => closeModal())
    return promiseWrapper(discardPromise)
  }

  const errElem = loadingStates.state === loadingStatesIds.ERROR && (
    <Alert variant="primaryWithBg" mb={5}>
      Something went wrong! {loadingStates.error?.message}
    </Alert>
  )

  return (
    <GenericModal
      onDismiss={() => closeModal()}
      title="Background Check Expired"
      modalFooter={
        <GenericModalFooter
          primaryBtnConfig={{
            title: 'Process new check',
            onClick: onDiscard,
            props: {
              loading: loadingStates.state === loadingStatesIds.LOADING,
            },
          }}
          secondaryBtnConfig={{
            title: 'Cancel',
            onClick: () => closeModal(),
          }}
        />
      }
    >
      {errElem}

      <Box lineHeight={`${theme.lineHeights.copy}em`}>
        Your Background Check is more than {validityPeriodInMonths} months old
        and is now expired. Would you like to add a new one?
      </Box>
    </GenericModal>
  )
}
