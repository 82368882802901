import { createContext, useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import type { AsyncFunction } from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_connection'
import {
  GET_STARTED,
  REGISTERING_TEAM,
  RegistrationTimeout,
  Started,
  STREAM_LINE_TEAM_URL,
  TeamInvitesEmailed,
} from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_const'
import type {
  RegisteringTeamStatus as IRegisteringTeamStatus,
  RegisteringTeamStatus,
  Step,
  StreamLineRegisterPayload,
} from 'app/pages/SteamLineSeftServiceTeam/stream_line_ss_team_type'
import { history } from 'app/shared_components/router'
import { wait } from 'app/sm/helpers'

type IStreamLineSSTeamContext = {
  currentStep: Step
  handleSubmitProperties: (values: StreamLineRegisterPayload) => void
  submitLoading: boolean
  registeringStatus: RegisteringTeamStatus
  registeringError: string
}

export const StreamLineSSTeamContext = createContext<IStreamLineSSTeamContext>({
  currentStep: GET_STARTED,
  handleSubmitProperties: () => {},
  submitLoading: false,
  registeringStatus: Started,
  registeringError: '',
})

type StreamLineSSTeamContextProviderProps = {
  children: React.ReactElement
  registerTeamViaDomain: AsyncFunction<StreamLineRegisterPayload>
  getRegisterTeamViaDomainStatus: AsyncFunction<string>
}

type IStreamLineTeamParams = {
  jobId: string
}

const StreamLineSSTeamContextProvider = ({
  children,
  registerTeamViaDomain,
  getRegisterTeamViaDomainStatus,
}: StreamLineSSTeamContextProviderProps) => {
  const [currentStep, setCurrentStep] = useState<Step>(GET_STARTED)
  const [registeringStatus, setRegisteringStatus] =
    useState<IRegisteringTeamStatus>(Started)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const [registeringError, setRegisteringError] = useState<string>('')

  const [index, setIndex] = useState(0)

  const { jobId = '' } = useParams<IStreamLineTeamParams>()

  const handleSubmitProperties = (values: StreamLineRegisterPayload) => {
    setSubmitLoading(true)
    setRegisteringError('')
    return registerTeamViaDomain(values)
      .then(({ jobId = '' }) => {
        history.push(`${STREAM_LINE_TEAM_URL}/${jobId}`)
        setSubmitLoading(false)
      })
      .catch((error) => {
        setRegisteringError(error)
        setSubmitLoading(false)
      })
  }

  const fetchRegisteringStatus = () => {
    getRegisterTeamViaDomainStatus(jobId)
      .then(({ status, errorCode }) => {
        wait(RegistrationTimeout).then(() => {
          if (status !== 'Error') {
            setRegisteringStatus(status)
            setIndex(index + 1)
          } else {
            setRegisteringError(translateErrorCodeToMessage(errorCode))
          }
        })
      })
      .catch((error) => {
        setRegisteringError(error)
      })
  }

  useEffect(() => {
    if (jobId) {
      setCurrentStep(REGISTERING_TEAM)
      fetchRegisteringStatus()
    } else {
      setCurrentStep(GET_STARTED)
    }
  }, [jobId])

  useEffect(() => {
    if (
      registeringStatus !== TeamInvitesEmailed &&
      jobId &&
      !registeringError
    ) {
      fetchRegisteringStatus()
    }
  }, [registeringStatus, index])

  const ctx = {
    currentStep,
    handleSubmitProperties,
    submitLoading,
    registeringStatus,
    registeringError,
  }

  return (
    <StreamLineSSTeamContext.Provider value={ctx}>
      {children}
    </StreamLineSSTeamContext.Provider>
  )
}

export default StreamLineSSTeamContextProvider
