import React from 'react'

import * as Display from 'app/components/display/display'

const SUCCESS = 'Success'
const WARNING = 'Warning'
const ERROR = 'Error'

const IntegrationDeactivated = 'INTEGRATION_DEACTIVATED'
const IntegrationActivated = 'INTEGRATION_ACTIVATED'
const TenantApplicationCreated = 'TENANT_APPLICATION_CREATED'
const PropertyUpdated = 'TENANT_APPLICATION_PROPERTY_UPDATED'

const ActivityTable = ({ activityList }) => {
  const setPillType = (status) => {
    if (status === SUCCESS) {
      return 'green-hollow'
    } else if (status === WARNING) {
      return 'orange-hollow'
    } else {
      return 'red-hollow'
    }
  }

  const eventType = (event) => {
    switch (event) {
      case IntegrationActivated:
        return 'Activated'
      case IntegrationDeactivated:
        return 'Deactivated'
      case TenantApplicationCreated:
        return 'Application issued'
      case PropertyUpdated:
        return 'Property updated'
      default:
        return event
    }
  }

  const isActivityExist = activityList && activityList.length > 0
  return (
    <div>
      <Display.SectionHeader
        componentClass="mb35"
        text="Activity"
        textClass="p0"
      />
      {isActivityExist && (
        <table className="table style8">
          <thead>
            <tr>
              <th>Time</th>
              <th>Status</th>
              <th>Event</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {activityList.map((item) => {
              return (
                <tr>
                  <td>{item.createdDateTime}</td>
                  <td>
                    <Display.StatusPill
                      pillType={setPillType(item.status)}
                      text={item.status}
                    />
                  </td>
                  <td>{eventType(item.eventType)}</td>
                  {item.eventType === IntegrationActivated &&
                    item.status === SUCCESS && (
                      <td>{'Integration activated successfully.'}</td>
                    )}
                  {item.eventType === IntegrationDeactivated &&
                    item.status === SUCCESS && (
                      <td>{'Integration deactivated successfully.'}</td>
                    )}
                  {(item.eventType === IntegrationDeactivated ||
                    item.eventType === IntegrationActivated) &&
                    item.status === ERROR && <td>{item.errorMessage}</td>}
                  {item.eventType === TenantApplicationCreated &&
                    item.status === SUCCESS && (
                      <td>
                        {item.propertyName
                          ? `Application issued success for property ${item.propertyName}`
                          : 'Application issued.'}
                      </td>
                    )}
                  {item.eventType === PropertyUpdated && (
                    <td>
                      {item.status === SUCCESS && (
                        <>
                          Property updated successfully
                          {item.propertyName && ` ${item.propertyName}`}
                        </>
                      )}
                      {item.status === ERROR && item.errorMessage}
                    </td>
                  )}
                  {item.eventType === TenantApplicationCreated &&
                    item.status === ERROR && (
                      <td>
                        {item.propertyName
                          ? `Failed to issue Application for property ${item.propertyName}. ${item.errorMessage}`
                          : item.errorMessage}
                      </td>
                    )}
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {!isActivityExist && <div className="text-center">{'No activity'}</div>}
    </div>
  )
}

export default ActivityTable
