import React from 'react'

import { Link } from 'react-router-dom'

import ApplicationsTable from 'app/agency/applications_list/applications_table/connection'
import AgencyFilterSection from 'app/components/agency_search_section/connection'
import {
  Alert,
  Box,
  LoadingSection,
} from 'app/components/design-system-components'
import { searchAddressEmailNamePhonePlaceholder } from 'app/components/text/placeholderText'
import {
  ApplicationsListHeader,
  Breadcrumbs,
} from 'app/dashboard/prospects_summary'
import { getApplications } from 'app/services/http/team-applications'
import { getTeamSettings } from 'app/services/http/teams/settings'
import * as snugNotifier from 'app/services/snugNotifier'
import { parseDataUtil, urlTo } from 'app/sm/helpers'
import * as helpers from 'app/sm/helpers'
import { getConsoleCloudIntegration } from 'app/sm/plugins/plugins_access'
import {
  createInitialLoadingState,
  createLoadingStateUtils,
  promiseWrapperFactory,
} from 'app/utils/loading-states'
import * as textHelpers from 'app/utils/text/helpers'

const workFlowStages = {
  Any: {
    text: 'Any',
    value: -1,
  },
  New: {
    text: 'New',
    value: 1,
  },
  Review: {
    text: 'Review',
    value: 2,
  },
  Approve: {
    text: 'Approve',
    value: 3,
  },
  Offer: {
    text: 'Offer',
    value: 4,
  },
  Accept: {
    text: 'Accept',
    value: 5,
  },
  Declined: {
    text: 'Declined',
    value: 6,
  },
  Withdrawn: {
    text: 'Withdrawn',
    value: 7,
  },
}
class TeamApplications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      basicDataLoadingStates: createInitialLoadingState(),
      basicData: {},
      isConsoleCloudEnabled: false,
    }

    this.basicDataLoadingStatesUtils = createLoadingStateUtils(
      (loadingState) => {
        this.setState((state) => ({
          ...state,
          basicDataLoadingStates: loadingState,
        }))
      },
    )
  }

  componentDidMount() {
    let { currentTeam, fetchAgencyStatus } = this.props
    if (currentTeam?.guid) {
      fetchAgencyStatus(currentTeam.guid).then(({ integrationStatus }) => {
        this.setState({
          pmsIntegrationStatus: integrationStatus,
        })
      })
      this.getAndSetConsoleCloudIntegration(currentTeam?.guid)
      this.loadBasicData()
    }
  }

  componentDidUpdate(prevProps) {
    let { currentTeam } = this.props
    currentTeam = currentTeam || {}
    const prevTeam = prevProps.currentTeam || {}
    if (currentTeam.guid && currentTeam.guid !== prevTeam.guid) {
      const { guid: teamGUID } = currentTeam
      this.props.fetchAgencyStatus(teamGUID).then(({ integrationStatus }) => {
        this.setState({
          pmsIntegrationStatus: integrationStatus,
        })
      })
      this.getAndSetConsoleCloudIntegration(currentTeam.guid)
      this.loadBasicData()
    }
  }

  getAndSetConsoleCloudIntegration = (agencyGUID) => {
    getConsoleCloudIntegration(agencyGUID)
      .then(({ result }) => {
        this.setState({
          isConsoleCloudEnabled:
            result && result.status === textHelpers.CONSOLE_CLOUD_ACTIVE,
        })
      })
      .catch((err) => snugNotifier.error(err))
  }

  loadBasicData = () => {
    const { currentTeam, filters } = this.props
    const basicDataPromise = getTeamSettings(currentTeam.guid, {
      keys: ['application'],
    })
      .then(({ settingInfo }) => {
        const { application = {} } = settingInfo
        const applicationTeamSettings =
          parseDataUtil.convertObjValueFromStringToBoolean(application)
        const { filter_incomplete_household_applications = false } =
          applicationTeamSettings
        this.setState({
          applicationTeamSettings,
          teamSettingsFilters: {
            ...(!filter_incomplete_household_applications
              ? {}
              : { completeHouseholdApplications: true }),
          },
        })
        return filter_incomplete_household_applications
      })
      .then((filterIncompleteApps) => {
        if (!filterIncompleteApps) return Promise.resolve()
        const { workflowStage, workflow, ...otherFilters } = filters
        // getting filteredOutApplications count
        return getApplications(currentTeam.guid, {
          ...otherFilters,
          completeHouseholdApplications: false,
          limit: 1,
        }).then(({ responseData }) =>
          this.setState((state) => ({
            ...state,
            filteredOutApps: responseData?.response_metadata?.total,
          })),
        )
      })
    return promiseWrapperFactory(this.basicDataLoadingStatesUtils)(
      basicDataPromise,
    )
  }

  renderFilteredOutAppsMessage = () => {
    const { currentTeam } = this.props
    const { applicationTeamSettings, filteredOutApps } = this.state
    if (
      !applicationTeamSettings?.filter_incomplete_household_applications ||
      !filteredOutApps
    )
      return null

    return (
      <Alert variant="blueWithBg">
        <Box>
          {filteredOutApps} Partial Household applications are filtered, click
          "Show Withdrawn / Declined / Incomplete" in{' '}
          <Link
            to={helpers.urlTo('teamsApplication', {
              teamSlug: currentTeam.slug,
            })}
          >
            sort by property
          </Link>
        </Box>
      </Alert>
    )
  }

  render() {
    const { teamSlug } = this.props.match.params
    const currentTeam =
      this.props.teams.find((team) => team.slug === teamSlug) || {}
    const { filters } = this.props
    const { showDeclinedAndWithdrawnApplications } = filters
    const crumbs = [
      {
        text: currentTeam.name || 'Team',
        link: urlTo('teamOverview', { teamSlug }),
      },
      {
        text: 'Applications By Stage',
        link: urlTo('teamApplicationsByStage', { teamSlug }),
      },
    ]
    const {
      pmsIntegrationStatus,
      teamSettingsFilters,
      basicDataLoadingStates,
      isConsoleCloudEnabled = false,
    } = this.state
    const shownWorkflowStages = [
      workFlowStages.New,
      workFlowStages.Review,
      workFlowStages.Approve,
      workFlowStages.Offer,
      workFlowStages.Accept,
    ]

    return (
      <div>
        <Breadcrumbs crumbs={crumbs} />
        <ApplicationsListHeader />
        <AgencyFilterSection
          disableSearchStatus
          disableSearchTodayOnly
          disableDatePicker
          disableSearchManager
          enableViewingSearchManager={true}
          enableUnAssigned={true}
          placeholderText={searchAddressEmailNamePhonePlaceholder}
        />

        {this.renderFilteredOutAppsMessage()}

        <LoadingSection
          loadingState={basicDataLoadingStates}
          actionHandler={() => this.loadBasicData()}
          loaderProps={{ fontSize: '50px' }}
        >
          {shownWorkflowStages.map((stage) => (
            <ApplicationsTable
              key={stage.value}
              filters={filters}
              stage={stage.value}
              headingText={stage.text}
              pmsIntegrationStatus={pmsIntegrationStatus}
              teamSettingsFilters={teamSettingsFilters}
              isConsoleCloudEnabled={isConsoleCloudEnabled}
            />
          ))}

          {showDeclinedAndWithdrawnApplications && (
            <div>
              <ApplicationsTable
                filters={filters}
                stage={workFlowStages.Declined.value}
                headingText={workFlowStages.Declined.text}
                pmsIntegrationStatus={pmsIntegrationStatus}
                isConsoleCloudEnabled={isConsoleCloudEnabled}
              />
              <ApplicationsTable
                filters={filters}
                stage={workFlowStages.Withdrawn.value}
                headingText={workFlowStages.Withdrawn.text}
                pmsIntegrationStatus={pmsIntegrationStatus}
                isConsoleCloudEnabled={isConsoleCloudEnabled}
              />
            </div>
          )}
        </LoadingSection>
      </div>
    )
  }
}

export default TeamApplications
