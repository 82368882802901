import React from 'react'

import { accountsBaseUrl } from 'config/env'

const RegisterFacebook = ({ form }) => {
  return (
    <div className="col-sm-6 panel panel-default pb30 width-100">
      <div className="panel-body">
        <a href={accountsBaseUrl + 'facebook'} className="btn btn-facebook">
          <i className="fa fa-facebook ml0" />
          <span>{form} with Facebook</span>
        </a>
      </div>
    </div>
  )
}

export default RegisterFacebook
