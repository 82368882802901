import React from 'react'

import styled from 'styled-components'

const Header = styled.header`
  background: var(--pdf-gray-superlight);
  padding: 16px 24px 12px 26px;
  border-radius: 2px 2px 0 0;
  border-bottom: 2px solid var(--pdf-gray-middle);

  @media (min-width: 1200px) {
    padding: 16px 24px 12px 26px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

function WidgetHeader(props) {
  return <Header className="widget-header">{props.children}</Header>
}

export default WidgetHeader
