import { api } from 'app/utils/api/helpers'

const mapFilters = ({ managers, properties, isArchived, ...otherFilters }) => {
  return {
    managerContactIDs: managers,
    propertyGUID: properties,
    ...(isArchived ? { archived: 'true' } : {}),
    ...otherFilters,
  }
}

export const bulkRead = (teamID, { ids, filters }) => {
  const payload = ids ? { ids } : { filters: mapFilters(filters) }
  return api.post(`sm/teams/${teamID}/messages/bulk-read`, payload)
}

export const bulkArchive = (teamID, { ids, filters }) => {
  const payload = ids ? { ids } : { filters: mapFilters(filters) }
  return api.post(`sm/teams/${teamID}/messages/bulk-archive`, payload)
}

export const getMessages = (teamId, params) => {
  return api.get(`sm/teams/${teamId}/messages`, {
    params: mapFilters(params),
  })
}

export const toggleEnquiryResponder = (teamID, value) => {
  return api.put(`sm/team/${teamID}/auto_responder`, { enable: value })
}

export const shortlistMessage = (teamID, id) => {
  return api.post(`sm/teams/${teamID}/messages/${id}/shortlist`)
}

export const unShortlistMessage = (teamID, id) => {
  return api.delete(`sm/teams/${teamID}/messages/${id}/shortlist`)
}

export default {
  bulkArchive,
  bulkRead,
  getMessages,
}
