import React from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

import BankAccountWidget from 'app/bond_cover/renters/hub/bankaccount_widget'
import IdentityDocWidget from 'app/bond_cover/renters/hub/identitydoc_widget'
import SignatureWidget from 'app/bond_cover/renters/hub/signature_widget'
import TenantWidget from 'app/shared_components/tenant_widget'

const LeaseeSign = ({
  request,
  currentLease,
  address,
  manager,
  tenants,
  quote,
  identityDoc,
  bankAccount,
  signature,
  secondaryTenantApprove,
  disabled,
  clicked,
}) => (
  <div className="two-col-box-flex-row sm64">
    <div className="col-first">
      <h3 className="mb20">Review &amp; Sign</h3>

      <p>
        Please review the details below.{' '}
        {currentLease && !currentLease.isNewLease
          ? 'To issue your refund we need some ID and a bank account.'
          : 'To add you to BondCover for this lease we need your ID details and Signature'}{' '}
      </p>

      <div className="bc-hub-item-link completed">
        <header className="gray-dark-color fw700">
          <i className="icon-couch" />
          <span>Rental Details</span>
        </header>
        <content>
          <p>
            {address &&
              address.friendlyName +
                ', ' +
                address.suburb +
                ', ' +
                address.state +
                ' ' +
                address.postcode}
            <br />
            {currentLease &&
              currentLease.startDate &&
              moment(currentLease.startDate).format('Do MMM YYYY')}{' '}
            -{' '}
            {currentLease && currentLease.endDate
              ? moment(currentLease.endDate).format('Do MMM YYYY')
              : 'Rolling Lease'}{' '}
            <br />${currentLease && currentLease.rate && currentLease.rate} per
            week <br />${currentLease && currentLease.bond && currentLease.bond}{' '}
            bond <br />
          </p>
        </content>
      </div>

      <div className="bc-hub-item-link completed">
        <header className="gray-dark-color fw700">
          <i className="icon-user" />
          <span>Property Manager</span>
        </header>
        <content>
          <p>
            {manager && manager.agencyName && manager.agencyName}
            <br />
            {manager && manager.firstName && manager.firstName}{' '}
            {manager && manager.lastName && manager.lastName}
            <br />
            {manager && manager.email && manager.email}
            <br />
            {manager && manager.mobile && manager.mobile}
            <br />
          </p>
        </content>
      </div>

      <div className="bc-hub-item-link completed">
        <header className="gray-dark-color fw700">
          <i className="icon-tenants" />
          <span>Leasees</span>
        </header>
        <content>
          {tenants &&
            tenants.map((tenant) => {
              return (
                <TenantWidget
                  currentLease={currentLease}
                  tenant={tenant}
                  key={tenant.guidID}
                />
              )
            })}
        </content>
      </div>

      {/* <div className="pb5 pt10">Is this data above correct?</div>

      <Link to="/bc/leaseeflag" className="gray-dark-color ">
        <i className="icon-flag pr10"></i>
        <span className="tdu">Flag an error</span>
      </Link> */}

      <h4 className="mb25 mt40">We need your details to approve BondCover.</h4>

      <IdentityDocWidget
        identityDoc={identityDoc}
        request={request}
        currentLease={currentLease}
        role="secondary"
      />

      {currentLease && !currentLease.isNewLease && (
        <BankAccountWidget
          bankAccount={bankAccount}
          request={request}
          currentLease={currentLease}
          role="secondary"
        />
      )}

      <SignatureWidget
        signature={signature}
        request={request}
        currentLease={currentLease}
        role="secondary"
      />

      <div className="bc-refund-box">
        <div className="bc-refund-box-summary-wrapper">
          <div className="text">
            <div className="bc-refund-header">
              {currentLease && !currentLease.isNewLease
                ? 'Household refund'
                : 'Household BondCover fee'}
            </div>
            <div className="bc-refund-amount">
              <span>
                $
                {currentLease && !currentLease.isNewLease
                  ? quote &&
                    Math.round(quote.bondAmount - quote.premium).toFixed(2)
                  : quote && Math.round(quote.premium).toFixed(2)}
              </span>
              {/* <i className="icon-arrow-down"></i> */}
            </div>
            <div className="bc-refund-footer">
              {currentLease && !currentLease.isNewLease
                ? quote &&
                  quote.guidID &&
                  'Includes Snug fee: $' + quote.premium.toFixed(2)
                : 'Fee for first year of lease, renewal discounts apply'}
            </div>
          </div>
          <div className="controls">
            <button
              onClick={secondaryTenantApprove(request && request.guidID)}
              disabled={disabled}
            >
              Approve
              <i className={clicked ? 'fa fa-spinner fa-pulse' : ''} />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default LeaseeSign
