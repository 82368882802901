import React from 'react'

import { Flex } from 'app/components/design-system-components'

import 'app/agency/advance_activity/style.scss'

const ActivityRowLabels = ({
  componentClassName = '',
  columnsLabels = [],
  colClassName = '',
}) => {
  return (
    <div className={`${componentClassName} activity-label-container`}>
      <div className="width100 activity-sort-rows-container no-bottom-border mb0">
        <div className="activity-sort-rows">
          <Flex flexWrap="nowrap" className="mb0 activity-sort-items">
            <div className="activity-sort-container">{/* <p /> */}</div>
            {columnsLabels &&
              columnsLabels.map((label) => {
                return (
                  <div
                    className={`${colClassName} pl0 pr0 col-sm-5 activity-sort-container`}
                  >
                    <p className="text-align-center">{label || ''}</p>
                  </div>
                )
              })}
          </Flex>
        </div>
      </div>
    </div>
  )
}

export default ActivityRowLabels
