import React from 'react'

import { NavLink } from 'react-router-dom'

import ApplicationsCount from 'app/shared_components/navbar/components/applications_count/container'
import { urlTo } from 'app/sm/helpers'
import { FeatureFlag } from 'config/features'

const isActive = (_, location) => {
  return [
    /home\/overview/,
    /portfolio\/overview/,
    /agency.*\/bc\/dashboard/,
  ].some((route) => route.test(location.pathname))
}

const RenterNavbar = ({
  bcApplicationsCount = 0,
  to,
  applicationsCount = 0,
}) => (
  <div>
    <div className="collapse-box pt0">
      <button
        data-target="#FindHomeMenu"
        data-menutoggle="true"
        className="collapsed"
        data-toggle="collapse"
      >
        My Home
      </button>
      <ul id="FindHomeMenu" className="collapse in">
        <li>
          <NavLink isActive={isActive} to={to}>
            <i className="icon-dasshboard" />
            <span>Dashboard</span>
          </NavLink>
        </li>
        {FeatureFlag.BondCover.isOn && (
          <li>
            <NavLink to={urlTo('dashboard')}>
              <i className="icon-bondcover" />
              <span>BondCover</span>
              <div className="badge">
                {bcApplicationsCount ? bcApplicationsCount : 0}
              </div>
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to={urlTo('RentalReputation')}>
            <i className="icon-person"></i>
            <span>My Profile</span>
          </NavLink>
        </li>
        <ApplicationsCount applicationsCount={applicationsCount} />
        {FeatureFlag.Viewings.isOn && (
          <li>
            <NavLink to={urlTo('smViewings')}>
              <i className="icon-calendar" />
              <span>Viewings</span>
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to={urlTo('savedProperties')}>
            <i className="icon-saved" />
            <span>Saved Properties</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={urlTo('recentlyViewed')}>
            <i className="icon-recent" />
            <span>Recent Searches</span>
          </NavLink>
        </li>
      </ul>
    </div>
  </div>
)

export default RenterNavbar
