import moment from 'moment'
import { Link } from 'react-router-dom'

import { Box } from 'app/components/design-system-components'
import TableComponent from 'app/components/design-system-components/Table'
import { Text } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import { pdfStandardDisplayTimeFormat, urlTo } from 'app/sm/helpers'

const notAvailableElem = (
  <Text
    as="span"
    color={theme.colors.gray400}
    fontSize={theme.fontSizes.pRegular14}
  >
    N/A
  </Text>
)

export const BackgroundChecksTable = ({ bgChecksData }) => {
  const colDefs = [
    {
      accessor: 'createdAt',
      Header: 'Report Date',
      Cell: ({ value: creationDate }) => {
        if (!creationDate) return notAvailableElem
        const reportDate = moment(creationDate).format(
          pdfStandardDisplayTimeFormat,
        )
        return <Text as="div">{reportDate}</Text>
      },
    },
    {
      accessor: 'renter',
      Header: 'Person',
      Cell: ({ value: renter }) => {
        if (!renter) return notAvailableElem
        const renterName = `${renter.first_name} ${renter.last_name}`

        return (
          <Box mb={1}>
            <Text as="span">{renterName}</Text>
          </Box>
        )
      },
    },
    {
      accessor: 'application.offer.property.address',
      Header: 'Application Address',
      Cell: ({ value: address = {}, row: { original: bgCheck } }) => {
        const { friendlyName, suburb } = address
        const displayAddress = `${friendlyName}, ${suburb}`
        const propertyGUID = bgCheck.application?.offer?.property?.guidID
        const applicationGUID = bgCheck.application?.guidID
        const applicantGUID = bgCheck.renter?.guid
        const showLink =
          propertyGUID && applicationGUID && applicantGUID && displayAddress

        return showLink ? (
          <Link
            to={urlTo('renterApplication', {
              propertyId: propertyGUID,
              applicationId: applicationGUID,
              applicantId: applicantGUID,
            })}
          >
            {displayAddress}
          </Link>
        ) : (
          notAvailableElem
        )
      },
    },
    {
      accessor: 'slug',
      Header: 'Report ID',
      Cell: ({ value: reportId, row: { original: bgCheck } }) => {
        const applicationGUID = bgCheck.application?.guidID
        const applicantGUID = bgCheck.renter?.guid
        const showLink = applicantGUID && applicationGUID && reportId

        return showLink ? (
          <Link
            to={urlTo('applicationApplicantBackgroundCheckReport', {
              applicationGUID: applicationGUID,
              applicantGUID: applicantGUID,
            })}
          >
            {reportId}
          </Link>
        ) : (
          notAvailableElem
        )
      },
    },
    {
      accessor: 'manager',
      Header: 'Processed by',
      Cell: ({ value: manager, row: { original: bgCheck } }) => {
        if (!manager) {
          if (!bgCheck.isOwnerRequest) return notAvailableElem
        }
        const managerName = bgCheck.isOwnerRequest
          ? 'Owner'
          : `${manager.first_name} ${manager.last_name}`

        return (
          <Box mb={1}>
            <Text as="span">{managerName}</Text>
          </Box>
        )
      },
    },
    {
      accessor: 'pricing.costPrice',
      Header: (
        <Box>
          Cost{' '}
          <Box color={theme.colors.gray600} fontWeight="400">
            (ex GST)
          </Box>
        </Box>
      ),
      style: { textAlign: 'center' },
      Cell: ({ value: costPrice }) => {
        const { priceExGST } = costPrice || {}
        return <Text as="span">${priceExGST?.toFixed(2)}</Text>
      },
    },
    {
      accessor: 'pricing.serviceFee',
      Header: (
        <Box>
          <Box>Service Fee</Box>
          <Box color={theme.colors.gray600} fontWeight="400">
            (ex GST)
          </Box>
        </Box>
      ),
      style: { textAlign: 'center' },
      Cell: ({ value: serviceFee }) => {
        const { priceExGST } = serviceFee || {}
        return (
          <Text as="span" textAlign="center">
            ${priceExGST?.toFixed(2)}
          </Text>
        )
      },
    },
    {
      accessor: 'pricing.displayPrice',
      style: { textAlign: 'center' },
      Header: (
        <Box>
          Price{' '}
          <Box color={theme.colors.gray600} fontWeight="400">
            (inc GST)
          </Box>
        </Box>
      ),
      Cell: ({ value: displayPrice }) => {
        const { priceIncGST } = displayPrice || {}
        return <Text as="span">${priceIncGST?.toFixed(2)}</Text>
      },
    },
    {
      accessor: 'invoiceID',
      Header: 'Invoice',
      Cell: ({ value: invoiceID }) => {
        return <Text as="span">{invoiceID}</Text>
      },
    },
  ]

  return (
    <Box width="100%" overflowX="auto">
      <TableComponent
        columns={colDefs}
        headerBgColor={theme.colors.white}
        dataToBeEntered={bgChecksData}
        textColor={theme.colors.gray600}
        showHeaderBottomBorder
        showRowBottomBorder
        thPadding={`${theme.space[5]}px`}
        fontSize={theme.fontSizes.pRegular14}
        verticalAlignTop
      />
    </Box>
  )
}
