import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import { variant } from 'styled-system'

import Box from 'app/components/design-system-components/Box'
import Flex from 'app/components/design-system-components/Flex'
import { InfoRounded } from 'app/components/design-system-components/icons/actions'
import { ImageRounded } from 'app/components/design-system-components/icons/image'
import { Spinner } from 'app/components/design-system-components/Spinner'
import TextSpan from 'app/components/design-system-components/TextSpan'
import * as getters from 'app/components/design-system-components/theme/getters'
import { Text } from 'app/components/design-system-components/typography'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const cursorVariant = {
  prop: 'cursor',
  variants: {
    pointer: {
      cursor: 'pointer',
    },
    unset: {
      cursor: 'unset',
    },
  },
}

export const DropZoneContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: 47px 0;
  border: 1px dashed ${getters.color('gray300')};
  border-radius: ${getters.radius(2)}px;
  cursor: pointer;

  ${variant(cursorVariant)}
`

export const DropElem = ({
  mainActionText = 'Click to upload',
  subElem = undefined,
}) => (
  <DropZoneContainer>
    <Box
      color={theme.colors.gray500}
      fontSize="35px"
      mb={theme.space[4] + 'px'}
    >
      <ImageRounded />
    </Box>

    <Box textAlign="center" color={theme.colors.gray500}>
      <Text as={Box} fontSize={theme.fontSizes[5] + 'px'}>
        <TextSpan
          fontWeight={theme.fontWeights[6]}
          color={theme.colors.primary300}
        >
          {mainActionText}
        </TextSpan>{' '}
        or drag and drop
      </Text>
      {!!subElem && <Box mt={theme.space[3] + 'px'}>{subElem}</Box>}
    </Box>
  </DropZoneContainer>
)

export const UploadingFile = ({
  uploadingText = 'Uploading',
  cancelText = 'cancel',
  onCancel,
}) => {
  return (
    <DropZoneContainer>
      <Box fontSize="35px" mb={theme.space[3] + 'px'}>
        <Spinner />
      </Box>

      <Box color={theme.colors.gray500}>
        <TextSpan>{uploadingText}, </TextSpan>
        <a onClick={onCancel}>{cancelText}</a>
      </Box>
    </DropZoneContainer>
  )
}

export const ErrorUploading = ({
  errorMessage = 'Upload Failed',
  errorDetailedMessage,
  retryText = 'retry',
  onRetry,
}) => {
  return (
    <DropZoneContainer>
      <Box
        color={theme.colors.primary500}
        fontSize="35px"
        mb={theme.space[3] + 'px'}
      >
        <InfoRounded />
      </Box>

      <Box
        color={theme.colors.primary500}
        maxWidth="450px"
        lineHeight="1.3"
        textAlign="center"
      >
        <TextSpan fontWeight={theme.fontWeights[6]}>{errorMessage}, </TextSpan>
        <a onClick={onRetry}>{retryText}</a>

        {!!errorDetailedMessage && <Box>{errorDetailedMessage}</Box>}
      </Box>
    </DropZoneContainer>
  )
}

export const FilesDropzone = ({
  onDrop,
  dropElementRenderer,
  accept,
  multiple,
}) => {
  return (
    <Dropzone onDrop={onDrop} accept={accept} multiple={multiple}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        const dropElem = dropElementRenderer
          ? dropElementRenderer(isDragActive)
          : DropElem({})

        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {dropElem}{' '}
          </div>
        )
      }}
    </Dropzone>
  )
}
