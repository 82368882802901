import qs from 'qs'

import {
  BorrowerAppliedFilterRenderer,
  borrowerDataExtractor,
  BorrowerFilterBody,
  isBorrowerFilterApplied,
} from 'app/features/teams/key-logger/KeysListFilters/BorrowerFilter'
import {
  isKeySearchFilterApplied,
  KeySearchAppliedRenderer,
} from 'app/features/teams/key-logger/KeysListFilters/keySearchFilter'
import {
  isStatusFilterApplied,
  StatusAppliedFilterRenderer,
  statusDataExtractor,
  StatusFilterBody,
} from 'app/features/teams/key-logger/KeysListFilters/StatusFilter'

export const filtersIds = {
  borrower: 'borrower',
  status: 'status',
  keySearch: 'key-search',
}

export const filtersInitialState = {
  filters: {
    [filtersIds.borrower]: {},
    [filtersIds.status]: {},
    [filtersIds.keySearch]: '',
  },
  search: '',
}

const filtersAppliedChecker = {
  [filtersIds.borrower]: isBorrowerFilterApplied,
  [filtersIds.status]: isStatusFilterApplied,
  [filtersIds.keySearch]: isKeySearchFilterApplied,
}

export const hasAppliedFilters = (filtersState) => {
  if (!filtersState) return false
  return Object.values(filtersIds).some((filterId) => {
    const filterValue = filtersState.filters[filterId]
    const filterAppliedChecker = filtersAppliedChecker[filterId]
    return filterAppliedChecker(filterValue)
  })
}

const getFilteredGUID = () => {
  const curQueries = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  return curQueries.guid || ''
}

export const getFilters = (filtersState) => {
  const borrowers = borrowerDataExtractor(
    filtersState.filters[filtersIds.borrower],
  ).borrowers
  const statuses = statusDataExtractor(
    filtersState.filters[filtersIds.status],
  ).statuses
  const guid = getFilteredGUID()
  const labelSearch = filtersState.filters[filtersIds.keySearch]
  return {
    borrowers,
    statuses,
    guid,
    labelSearch,
  }
}

const getRenderedFiltersConfig = (filterId, filtersValues, updateFilterFn) => {
  const filterValues = filtersValues[filterId]
  return {
    filterValues,
    isApplied: filtersAppliedChecker[filterId](filterValues),
    setFilterValues: (values) => updateFilterFn(filterId, values),
  }
}

export const buildRenderedFilterConfig = (filtersValues, updateFilterFn) => {
  return {
    [filtersIds.borrower]: {
      AppliedFilterRenderer: BorrowerAppliedFilterRenderer,
      FilterBody: BorrowerFilterBody,
      filterTriggerPlaceholder: 'Select borrower',
      appliedFilterTitle: 'Borrower',
      ...getRenderedFiltersConfig(
        filtersIds.borrower,
        filtersValues,
        updateFilterFn,
      ),
    },
    [filtersIds.status]: {
      AppliedFilterRenderer: StatusAppliedFilterRenderer,
      FilterBody: StatusFilterBody,
      filterTriggerPlaceholder: 'Select status',
      appliedFilterTitle: 'Status',
      ...getRenderedFiltersConfig(
        filtersIds.status,
        filtersValues,
        updateFilterFn,
      ),
    },
    [filtersIds.keySearch]: {
      AppliedFilterRenderer: KeySearchAppliedRenderer,
      appliedFilterTitle: 'Key',
      ...getRenderedFiltersConfig(
        filtersIds.keySearch,
        filtersValues,
        updateFilterFn,
      ),
    },
  }
}
