import * as React from 'react'

function SvgPhoneIphoneRounded(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1H8a2.5 2.5 0 00-2.5 2.5v17A2.5 2.5 0 008 23h8a2.5 2.5 0 002.5-2.5v-17A2.5 2.5 0 0016 1zm-4 21c-.83 0-1.5-.67-1.5-1.5S11.17 19 12 19s1.5.67 1.5 1.5S12.83 22 12 22zm-4.5-4h9V4h-9v14z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPhoneIphoneRounded
