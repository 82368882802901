import React, { useContext } from 'react'

import { Box, Flex } from 'app/components/design-system-components'
import SvgCloseRounded from 'app/components/design-system-components/icons/navigation/CloseRounded'
import { TabsHeader } from 'app/components/design-system-components/TabsHeader'
import ActivitiesContainer from 'app/shared_components/activity_feed/Activities'
import {
  allTab,
  meTab,
} from 'app/shared_components/activity_feed/activity_feed.const'
import { ActivityFeedContext } from 'app/shared_components/activity_feed/ActivityFeedContext'
import StyledActivitySidebarContainer from 'app/shared_components/activity_feed/styled_sidebar_container'

const ActivitySideBar = () => {
  const {
    showNavigator,
    setShowNavigator,
    openTab,
    setOpenTab,
    fetchActivitiesOnEndScroll,
    fetchingOld,
  } = useContext(ActivityFeedContext)

  const onSidebarScrollHandler = (e) => {
    const { target: container } = e

    if (
      !fetchingOld &&
      container.offsetHeight + container.scrollTop >= container.scrollHeight - 3
    ) {
      fetchActivitiesOnEndScroll()
    }
  }

  return (
    showNavigator && (
      <StyledActivitySidebarContainer>
        <Box className="sidebar" onScroll={onSidebarScrollHandler}>
          <Box className="activity-feed-header-container">
            <Flex
              justifyContent="space-between"
              className="activity-header"
              alignItems="center"
            >
              <h3>Activity</h3>

              <SvgCloseRounded
                color="#808080"
                onClick={() => setShowNavigator(!showNavigator)}
              />
            </Flex>

            <Box mt="16px" className="tabs-container">
              <TabsHeader
                tabsOptions={[allTab, meTab]}
                selectedTab={openTab}
                onTabChanged={(t) => setOpenTab(t)}
                tabOptions={{
                  className: 'tab-option',
                }}
                height="36px"
              />
            </Box>
          </Box>

          <ActivitiesContainer />
        </Box>
      </StyledActivitySidebarContainer>
    )
  )
}

export default ActivitySideBar
