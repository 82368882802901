import { connect } from 'react-redux'

import {
  registerForEmailViewing,
  unregisterForEnquiryViewing,
} from 'app/match/viewings/enquiry_viewing_register/enquiry_viewing_register_actions'
import { logout } from 'app/session/session_actions'
import { addViewing } from 'app/sm/ppp/viewing_actions'
import {
  addToSavedProperty,
  cancelModal,
  checkForAgencyRules,
  fetchProperty,
  registerViewing,
  requestForViewing,
  triggerModal,
  unregisterViewing,
} from 'app/sm/property_details/property_details_actions'
import PropertyDetailsContainer from 'app/sm/property_details/property_details_container'
import { fetchTeamSettingInfo } from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({ propertyDetails, session, Shared, agency }) => ({
  property: propertyDetails.property,
  summary: propertyDetails.summary,
  viewings: propertyDetails.viewings,
  error: propertyDetails.error,
  currentUser: session.currentUser,
  authenticationModal: propertyDetails.authenticationModal,
  shouldRender: propertyDetails.shouldRender,
  backUrl: Shared.backUrl,
  agency: agency.agency,
  rsvpSpinnerGuidArray: propertyDetails.rsvpSpinnerGuidArray,
  potentialViewings: propertyDetails.potentialViewings,
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => logout(),
  fetchProperty: (id, queryParams) => dispatch(fetchProperty(id, queryParams)),
  addToSavedProperty: (id) => dispatch(addToSavedProperty(id)),
  triggerModal: () => dispatch(triggerModal()),
  cancelModal: () => dispatch(cancelModal()),
  registerViewing: (id) => dispatch(registerViewing(id)),
  unregisterViewing: (id) => dispatch(unregisterViewing(id)),
  addViewing: (
    id,
    startdate,
    duration,
    fromScheduler,
    skipDoubleBookingCheck,
    viewingOptions,
  ) =>
    dispatch(
      addViewing(
        id,
        startdate,
        duration,
        fromScheduler,
        skipDoubleBookingCheck,
        viewingOptions,
      ),
    ),
  requestForViewing: (payload) => dispatch(requestForViewing(payload)),
  registerForEmailViewing: (viewingID, payload) =>
    dispatch(registerForEmailViewing(viewingID, payload)),
  unregisterForEnquiryViewing: (id) =>
    dispatch(unregisterForEnquiryViewing(id)),
  checkForAgencyRules: (agencyGUID) =>
    dispatch(checkForAgencyRules(agencyGUID)),
  fetchTeamSettingInfo: (teamId, queryString) =>
    dispatch(fetchTeamSettingInfo(teamId, queryString)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyDetailsContainer)
