import styled from 'styled-components'
import { color, space, typography } from 'styled-system'

import { getters } from 'app/components/design-system-components/theme'

const StyledHeading = styled.h1`
  line-height: ${getters.lineHeight('title')};

  margin: unset;

  ${typography}
  ${space}
  ${color}
`

export const Heading = ({ level, displayingLevel, ...rest }) => {
  const usedDisplayingLevel = displayingLevel || level
  return (
    <StyledHeading
      as={`h${level}`}
      fontSize={`h${usedDisplayingLevel}`}
      fontWeight={`h${usedDisplayingLevel}`}
      {...rest}
    />
  )
}
