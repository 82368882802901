import React from 'react'

import { getNumberType } from 'libphonenumber-js'
import metadata from 'libphonenumber-js/metadata.full.json'
import moment from 'moment'
import PropTypes from 'prop-types'
import DateTime from 'react-datetime'

import { IntlTelInputWrapper } from 'app/components/design-system-components'
import * as Errors from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import { intTelTop10List } from 'app/shared_components/helpers'
import * as validations from 'app/shared_components/validations'

const nullValidator = () => ''

export const withValidation = (InputComponent, validator) => {
  return (props) => {
    return <InputComponent validate={validator} {...props} />
  }
}

export const Form = ({ onSubmit, children }) => {
  return <form onSubmit={onSubmit}>{children}</form>
}

export const Submit = ({ label }) => <input type="submit" value={label} />

/////////////////////////////////////////////////////////////////////
// Text input
/////////////////////////////////////////////////////////////////////
class InputText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showError: true,
    }
  }

  handleBlur = () => {
    this.setState({ showError: true })
  }

  handleChange = (event) => {
    const {
      target: { value },
    } = event
    const error = value ? this.props.validate(value.trim()) : ''
    this.props.onChange({ value, error })
    this.setState({ showError: false })
  }

  render() {
    const {
      value,
      error,
      label,
      id,
      name,
      readOnly,
      valueType,
      className,
      labelClassName,
    } = this.props
    const { showError } = this.state
    return (
      <div className="mb30 width100">
        <div className="input-box mb0">
          <input
            type={valueType || 'text'}
            value={value}
            required
            className={`mb5 ${className ? className : ''} ${
              error ? 'error' : ''
            }`}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            id={id}
            name={name}
            readOnly={readOnly}
          />
          <label className={`${labelClassName ? labelClassName : ''}`}>
            {label}
          </label>
        </div>
        {showError && <error>{error}</error>}
      </div>
    )
  }
}

InputText.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
}

InputText.defaultProps = {
  placeholder: '',
  id: '',
  name: '',
  readOnly: false,
}

// it's used because our validation functions return an array for some reason
const firstElementOrEmptyString = (array) => (array.length > 0 ? array[0] : '')
const validateNameFlattened = (value) =>
  firstElementOrEmptyString(validations.validateName(value))
const validateEmailFlattened = (value) =>
  firstElementOrEmptyString(validations.validateEmail(value))
export const validateABNFlattened = (value) =>
  firstElementOrEmptyString(validations.validateABN(value, 'Invalid ABN'))
const validateFloatFlattened = (value) =>
  firstElementOrEmptyString(validations.validateFloat(value, 'Invalid Number'))
const validatePlaceOfStudy = (value) =>
  firstElementOrEmptyString(
    validations.validateTextWithoutNumber(value, 'Invalid place of study'),
  )
const validateCourse = (value) =>
  firstElementOrEmptyString(
    validations.validateTextWithoutNumber(value, 'Invalid course'),
  )
const validateDuration = (value) =>
  firstElementOrEmptyString(
    validations.validatePositiveNumber(value, 'Invalid duration'),
  )

/////////////////////////////////////////////////////////////////////
// Specialized Text Input
export const InputName = withValidation(InputText, validateNameFlattened)
export const InputEmail = withValidation(InputText, validateEmailFlattened)
export const InputABN = withValidation(InputText, validateABNFlattened)
export const InputFloatNumber = withValidation(
  InputText,
  validateFloatFlattened,
)
export const InputOptional = withValidation(InputText, nullValidator)
export const InputPlaceOfStudy = withValidation(InputText, validatePlaceOfStudy)
export const InputCourse = withValidation(InputText, validateCourse)
export const InputDuration = withValidation(InputText, validateDuration)
export const InputEnrolmentNumber = withValidation(InputText, nullValidator)

/////////////////////////////////////////////////////////////////////
// Button
export class Button extends React.Component {
  handleOnClick = () => {
    this.props.onClick()
  }

  render() {
    const { label, className, loading, disabled } = this.props

    return (
      <button
        className={className}
        onClick={this.handleOnClick}
        disabled={disabled}
      >
        {label} <i className={loading ? 'fa fa-spinner fa-pulse' : ''} />
      </button>
    )
  }
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  loading: false,
  disabled: false,
}

/////////////////////////////////////////////////////////////////////
// Checkbox
export const Checkbox = ({
  label,
  checked,
  onChange,
  id,
  readOnly = false,
  // to work with styled-components
  className = '',
  componentClassName = '',
  enableEdit = true,
  checkboxLabelSpanClassName = '',
  toolTip,
  showDisabledGrey = false,
}) => {
  const isDisabledAndShowGrey = !enableEdit && showDisabledGrey
  return (
    <label
      className={`form-checkbox ${className} ${componentClassName} ${
        !enableEdit && 'disabled-link'
      } display-flex align-items-center`}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={readOnly ? undefined : onChange}
        id={id}
        className={isDisabledAndShowGrey && 'disabled-grey-bg'}
      />
      <span className={checkboxLabelSpanClassName}>{label}</span>
      {toolTip && toolTip}
    </label>
  )
}

/////////////////////////////////////////////////////////////////////
// Radio Group
export function RadioGroup(props) {
  const { label, error, children, componentClassName = '' } = props
  return (
    <div className={`form-radio-group ${componentClassName}`}>
      <div className="form-radio-group-label">{label}</div>
      <div className="form-radio-group-controls">{children}</div>
      {error && <error>{error}</error>}
    </div>
  )
}

class Dropdown extends React.Component {
  handleChange = (event) => {
    const {
      target: { value },
    } = event
    const error = value ? this.props.validate(value.trim()) : ''
    this.props.onChange({ value, error })
  }

  render() {
    const { label, options, value, error, id, name } = this.props
    return (
      <div
        className={error ? 'input-box error width100' : 'input-box width100'}
      >
        <select
          onChange={this.handleChange}
          value={value}
          id={id}
          name={name}
          className={error ? 'error' : ''}
        >
          {options.map((opt, i) => (
            <option key={i} value={i}>
              {opt}
            </option>
          ))}
        </select>
        <label>{label}</label>
        {error && <error>{error}</error>}
      </div>
    )
  }
}

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
}

Dropdown.defaultProps = {
  id: '',
  name: '',
}

//TODO: Dropdown propTypes
export const GenderDropdown = withValidation(Dropdown, nullValidator)
export const GeneralDropdown = withValidation(Dropdown, nullValidator)

export const RentingLengthDropdown = withValidation(Dropdown, nullValidator)
export const UtilityProviderDropdown = withValidation(Dropdown, nullValidator)

/////////////////////////////////////////////////////////////////////
// Text area
/////////////////////////////////////////////////////////////////////
class TextArea extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showError: true,
    }
  }

  handleBlur = () => {
    this.setState({ showError: true })
  }

  handleChange = (event) => {
    const {
      target: { value },
    } = event
    const error = value ? this.props.validate(value.trim()) : ''
    this.props.onChange({ value, error })
    this.setState({ showError: false })
  }

  render() {
    const { label, value, placeholder, rows, error, id } = this.props
    return (
      <div className={error ? 'input-box error' : 'input-box'}>
        <textarea
          rows={rows}
          placeholder={placeholder}
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          id={id}
        />
        <label>{label}</label>
        {this.state.showError && error && <error>{error}</error>}
      </div>
    )
  }
}

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  id: PropTypes.string,
}

TextArea.defaultProps = {
  placeholder: '',
  rows: 1,
  id: '',
}

export const CommentArea = withValidation(TextArea, nullValidator)

/////////////////////////////////////////////////////////////////////
// Date Input
/////////////////////////////////////////////////////////////////////
class InputDate extends React.Component {
  handleChange = (date) => {
    const value = moment(date).format('YYYY-MM-DD')
    let error = value ? this.props.validate(value) : ''
    if (!error && this.props.before) {
      error = date.isBefore(moment(this.props.before))
        ? ''
        : `The date must be before ${this.props.before}`
    }
    if (!error && this.props.after) {
      error = date.isAfter(moment(this.props.after))
        ? ''
        : `The date must be after ${this.props.after}`
    }
    this.props.onChange({ value, error })
  }

  render() {
    const { label, value, error, id } = this.props
    return (
      <div className={error ? 'input-box error' : 'input-box'}>
        <div className="input-date pos-initial pt0">
          <DateTime
            className={`date-time ${error ? 'error-red' : ''}`}
            inputProps={{ placeholder: 'dd/mm/yyyy', readOnly: true, id }}
            onBlur={undefined}
            onChange={this.handleChange}
            onFocus={undefined}
            value={value}
            defaultValue={undefined}
            viewMode="years"
            dateFormat={'DD/MM/YYYY'}
            timeFormat={false}
            open={null}
            closeOnSelect={true}
            disableOnClickOutside={true}
          />
          <label className={`label-left ${error ? 'error-red' : ''}`}>
            {label}
          </label>
          {error && <error>{error}</error>}
        </div>
      </div>
    )
  }
}

InputDate.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  before: PropTypes.string,
  after: PropTypes.string,
  id: PropTypes.string,
}

InputDate.defaultProps = {
  before: '',
  after: '',
  id: '',
}

const futureDateValidator = (date) =>
  validations.compareDates(
    moment().format('YYYY-MM-DD'),
    date,
    'This field is required',
    'The date must be in the future',
  )
const validateFutureDateFlattened = (date) =>
  firstElementOrEmptyString(futureDateValidator(date))

export const InputFutureDate = withValidation(
  InputDate,
  validateFutureDateFlattened,
)
export const RangeDateInput = withValidation(InputDate, nullValidator)

/////////////////////////////////////////////////////////////////////
// Phone input
/////////////////////////////////////////////////////////////////////
export class PhoneInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showError: true,
    }
  }

  handleBlur = () => {
    this.setState({ showError: true })
  }

  handleChange = (status, value, countryData, number) => {
    let error = value
      ? status === true
        ? ''
        : ErrorMessages[Errors.ErrorWhenConvertingMobileToInternationalFormat]
      : ''
    const { numberType } = this.props
    if (numberType === 'landline') {
      const actualNumberType = getNumberType(number, metadata)
      actualNumberType === 'FIXED_LINE'
        ? (error = '')
        : (error = 'The number is not a landline number')
    }
    this.props.onChange({ value: number.trim(), error })
    this.setState({ showError: false })
  }

  render() {
    const { label, value, error, id, placeholder = '' } = this.props
    return (
      <div className={error ? 'input-box error' : 'input-box'}>
        <IntlTelInputWrapper
          css={['intl-tel-input', '']}
          utilsScript={'libphonenumber.js'}
          defaultCountry={'au'}
          preferredCountries={intTelTop10List}
          onPhoneNumberChange={this.handleChange}
          onPhoneNumberBlur={this.handleBlur}
          onSelectFlag={null}
          value={value}
          placeholder={placeholder || '+61 412 345 678'}
          fieldId={id}
        />
        <p>{label}</p>
        {this.state.showError && error && <error>{error}</error>}
      </div>
    )
  }
}

PhoneInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
}

PhoneInput.defaultProps = {
  id: '',
}

//TODO: 5. File upload;

// Modal
export const Modal = ({ toggleModal, header, body }) => (
  <div className="modal-fullscreen">
    <div className="modal-wrapper">
      <div className="modal-close">
        <i className="fa fa-times" onClick={() => toggleModal()} />
      </div>
      <div className="modal-header">{header}</div>
      <div className="modal-body">{body}</div>
    </div>
  </div>
)
