import React, { Component } from 'react'

import styled from 'styled-components'

import GreenCheckmark from 'app/assets/icons/green-checkmark.svg'
import { LegacyButton } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import FormWrapper from 'app/match/leadToSnug/components/form_wrapper'
import SiteInformation from 'app/match/leadToSnug/components/site_information'
import { Iframe } from 'app/match/leadToSnug/styles'
import * as sharedHelpers from 'app/shared_components/helpers'

const EXPLORE_FEATURES = 'Explore Features'
const BUTTON_BOOK_A_DEMO = 'Book a Demo'
const OWNER_SHARE_APPLICATION_SUBMITTED =
  'Your preference has been updated successfully!'

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  button:first-of-type {
    margin-right: 8px;
  }
`
class LeadToSnug extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      modalHeading: '',
    }
  }

  componentDidMount() {
    const {
      isOwner = false,
      isFromRentalReference = true,
      isFromShareApplication = false,
    } = this.props

    if (isOwner && isFromShareApplication) {
      sharedHelpers.openToaster(
        'success',
        <span>
          Preference submitted
          <img src={GreenCheckmark} alt="green checkmark" />
        </span>,
      )
    }
    if (isFromRentalReference) {
      const { successMsg = 'Rental reference submitted' } = this.props
      sharedHelpers.openToaster(
        'success',
        <span>
          {successMsg}
          <img src={GreenCheckmark} alt="green checkmark" />
        </span>,
      )
    }

    document.querySelector('#index').classList.add('no-navbar-menu')
    document
      .querySelector('.app-content-container')
      .classList.add('no-padding-app-content-container')
    document.querySelector('.footer').style.paddingLeft = '40px'
  }

  toggleFormModal = (modalTitle) => {
    const { isModalOpen } = this.state
    this.setState({
      isModalOpen: !isModalOpen,
      modalHeading: modalTitle,
    })
  }

  render() {
    const {
      isOwner = false,
      isFromRentalReference = true,
      isFromShareApplication = false,
      thanksMsg = 'Thanks for submitting the Rental Reference!',
    } = this.props
    const { isModalOpen, modalHeading } = this.state
    return (
      <div className="row m0 display-flex flex-direction-column-mobile align-items-center">
        <div className="col-md-7 col-sm-6 col-xs-12 p0 text-align-center">
          {!isOwner && (
            <FormWrapper>
              <ButtonsContainer>
                <LegacyButton
                  onClick={() =>
                    window.open('https://snug.com/manager/', '_blank')
                  }
                >
                  {EXPLORE_FEATURES}
                </LegacyButton>
                <LegacyButton
                  onClick={() => this.toggleFormModal(BUTTON_BOOK_A_DEMO)}
                >
                  {BUTTON_BOOK_A_DEMO}
                </LegacyButton>
              </ButtonsContainer>
              {isModalOpen && (
                <Display.Modal
                  toggleModal={() => this.toggleFormModal()}
                  hideButtonSection
                  title={modalHeading}
                >
                  <Iframe
                    src="https://webforms.pipedrive.com/f/2Vqpx4xX3W7sPPNik1V1Z58VpXpR42aBqIQWwDn5dPyqc847b6IQLo6BwG8WPSy6T"
                    width="100%"
                    minHeight="656px"
                  />
                </Display.Modal>
              )}
              <div
                className="pipedriveWebForms"
                data-pd-webforms="https://webforms.pipedrive.com/f/2Vqpx4xX3W7sPPNik1V1Z58VpXpR42aBqIQWwDn5dPyqc847b6IQLo6BwG8WPSy6T"
              >
                <script src="https://webforms.pipedrive.com/f/loader" />
              </div>
            </FormWrapper>
          )}
          {isOwner && (
            <div>
              <h1>
                {isFromShareApplication && OWNER_SHARE_APPLICATION_SUBMITTED}
              </h1>
              {isFromRentalReference && <h1>{thanksMsg}</h1>}
              <p className="font-size-20">
                Learn more about{' '}
                <a href="https://snug.com/" target="_blank" rel="noreferrer">
                  Snug
                </a>
                .
              </p>
            </div>
          )}
        </div>
        <div className="col-md-5 col-sm-6 col-xs-12 p0">
          <SiteInformation />
        </div>
      </div>
    )
  }
}

export default LeadToSnug
