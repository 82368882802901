import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import bgcLogo from 'app/assets/icons/bgcLogo.svg'
import WelcomeImage from 'app/assets/icons/Welcome.svg'
import {
  Box,
  Flex,
  Modal,
  Spinner,
  TextSpan,
} from 'app/components/design-system-components'
import UpgradeButton from 'app/components/design-system-components/UpgradeButton'
import {
  CheckBoxGeneral,
  InputNumberWithTwoDecimalsAndZero,
} from 'app/components/forms/forms'
import {
  integrationBrands,
  integrationsStatuses,
} from 'app/constants/integrations.constants'
import IntegrationActivity, {
  useActivateIntegration,
  useDeactivateIntegration,
  useIntegrationStatus,
} from 'app/Integrations'
import theme from 'app/match/applicationReportPDF/assets/theme'
import {
  activateIntegration,
  deactivateIntegration,
  getIntegrationStatus,
} from 'app/services/http/integrations'
import {
  getTeamBGCheckPrice,
  getTeamSettings,
  updateTeamSettings,
} from 'app/services/http/teams/settings'
import * as snugNotifier from 'app/services/snugNotifier'
import { parseDataUtil, urlIds, urlTo } from 'app/sm/helpers'
import { backgroundCheckLearnMore } from 'config/external-links'
import { isOwnerOptInForBGCEnabled } from 'config/features'

const equifaxBrandId = integrationBrands.equifax.id
const equifaxBrandLogo = integrationBrands.equifax.logo

const ENABLE_BGC_OWNER_APPROVAL_LABEL = 'Enable Background Check owner approval'

const EquifaxIntegration = ({ currentTeam }) => {
  const {
    loadIntegrationStatus,
    integrationStatusInfo,
    integrationStatusLoadingStates,
  } = useIntegrationStatus(currentTeam, equifaxBrandId, getIntegrationStatus)

  const [isRequestBGCApprovalEnabled, setIsRequestBGCApprovalEnabled] =
    useState(false)
  const [excludeBankruptcyCheckInReport, setExcludeBankruptcyCheckInReport] =
    useState(false)
  const [
    isRequestBGCApprovalEnabledAttrValue,
    setIsRequestBGCApprovalEnabledAttrValue,
  ] = useState()
  const [bgcPrice, setBGCPrice] = useState(0)
  const [bgcServiceFee, setBGCServiceFee] = useState('')
  const [bgcServiceFeeErr, setBGCServiceFeeErr] = useState('')
  const [totalPrice, setTotalPrice] = useState('')
  const [bgcServiceFeeAttrValue, setBGCServiceFeeAttrValue] = useState('')
  const [appSettings, setAppSettings] = useState({})
  const [bgcPriceSpinner, setBGCPriceSpinner] = useState(false)
  const { isBGCShareWithOwnerEligible } = currentTeam || {}
  const {
    activateIntegration: activateEquifax,
    activateIntegrationLoadingStates,
    activateIntegrationLoadingStatesHelpers: activateEquifaxLoadingHelpers,
  } = useActivateIntegration(
    currentTeam,
    equifaxBrandId,
    activateIntegration,
    'Equifax activated successfully!',
  )

  const {
    deactivateIntegration: deactivateEquifax,
    deactivateIntegrationLoadingStates,
  } = useDeactivateIntegration(
    currentTeam,
    equifaxBrandId,
    deactivateIntegration,
    'Equifax deactivated successfully!',
  )

  const loadTeamSettings = () => {
    getTeamSettings(currentTeam.guid, {
      keys: ['application'],
    }).then(({ settingInfo }) => {
      const { application } = settingInfo
      const {
        share_app_with_owner_bgc_approval_enabled,
        exclude_bankruptcy_report = false,
      } = parseDataUtil.convertObjValueFromStringToBoolean(application)
      setAppSettings(application)
      setIsRequestBGCApprovalEnabled(share_app_with_owner_bgc_approval_enabled)
      setIsRequestBGCApprovalEnabledAttrValue(
        share_app_with_owner_bgc_approval_enabled,
      )
      setExcludeBankruptcyCheckInReport(exclude_bankruptcy_report)
    })
  }

  const fetchBGCPrice = () => {
    setBGCPriceSpinner(true)
    getTeamBGCheckPrice(currentTeam?.guid)
      .then(({ costPrice, displayPrice, serviceFee }) => {
        setBGCPrice(costPrice?.priceExGST)
        setTotalPrice(displayPrice?.priceIncGST)
        serviceFee?.priceExGST !== 0
          ? setBGCServiceFee(serviceFee?.priceExGST?.toFixed(2))
          : setBGCServiceFee(0)
        setBGCServiceFeeAttrValue(serviceFee?.priceExGST)
      })
      .catch(({ message }) => {
        snugNotifier.error(message)
      })
      .finally(() => {
        setBGCPriceSpinner(false)
      })
  }

  useEffect(() => {
    loadIntegrationStatus()
    loadTeamSettings()
  }, [])

  const [activationModal, setActivationModal] = useState({ isShown: false })

  const [showDeactivateModal, setShowDeactivateModal] = useState(false)

  const toggleShowDeactivateModal = () => {
    setShowDeactivateModal(!showDeactivateModal)
  }

  const openActivationModal = () => {
    activateEquifaxLoadingHelpers.reset()
    setActivationModal({ isShown: true })
  }

  const closeActivateModal = () => {
    activateEquifaxLoadingHelpers.reset()
    setActivationModal({ isShown: false })
  }

  const renderActivationModal = () => {
    const modalBody = (
      <Box>
        <Box mb={3} mt={6}>
          <Flex alignItems="center" justifyContent="center">
            <img src={bgcLogo} alt="BGC logo" />
          </Flex>
        </Box>
        <Box lineHeight={theme.lineHeights.encrption}>
          Activate real-time background checks with integrated NTD, identity
          checks and more for ${bgcPrice} per report. Reports are requested by
          team members when processing applications.{' '}
          <a href={backgroundCheckLearnMore} target="_blank" rel="noreferrer">
            learn more
          </a>
        </Box>
      </Box>
    )
    const primaryAction = () => {
      activateEquifax()
        .then(() => {
          setActivationModal({ isShown: false })
          loadIntegrationStatus()
        })
        .catch(({ message }) => snugNotifier.error(message))
    }
    return (
      <>
        {activationModal.isShown && (
          <Modal
            modalHeading="Confirm activation"
            modalBody={modalBody}
            modalFooter={undefined}
            primaryLabel="Activate"
            primaryAction={primaryAction}
            secondaryLabel="Cancel"
            secondaryAction={closeActivateModal}
            toggleModal={closeActivateModal}
          />
        )}
      </>
    )
  }

  const deactivateBGC = () => {
    deactivateEquifax()
      .then(() => {
        toggleShowDeactivateModal()
        loadIntegrationStatus()
      })
      .catch(({ message }) => snugNotifier.error(message))
  }

  const DeactivateBGCModal = () => {
    const modalBody = (
      <Box>
        <Box mb={3} mt={6}>
          <Flex alignItems="center" justifyContent="center">
            <img src={WelcomeImage} alt="BGC logo" />
          </Flex>
        </Box>
        <Box lineHeight="140%">
          Do you want to deactivate real-time background checks with integrated
          NTD, identity checks and more? Reports are requested by team members
          when processing applications. learn more
        </Box>
      </Box>
    )

    const primaryAction = () => {
      deactivateBGC()
    }

    return (
      <>
        <Modal
          modalHeading="Confirm deactivation"
          modalBody={modalBody}
          modalFooter={undefined}
          primaryLabel="Deactivate"
          primaryAction={primaryAction}
          secondaryLabel="Cancel"
          secondaryAction={toggleShowDeactivateModal}
          toggleModal={toggleShowDeactivateModal}
        />
      </>
    )
  }

  const activationModalElem = renderActivationModal()

  useEffect(() => {
    fetchBGCPrice()
  }, [isRequestBGCApprovalEnabled])

  const statusProps = {
    statusInfo: integrationStatusInfo,
    statusLoadingStates: integrationStatusLoadingStates,
    reloadStatusInfo: loadIntegrationStatus,
  }

  const activationProps = {
    activationFn: openActivationModal,
    activateLoadingStates: activateIntegrationLoadingStates,
  }

  const deactivateProps = {
    deactivationFn: () => toggleShowDeactivateModal(),
    deactivateLoadingStates: deactivateIntegrationLoadingStates,
  }

  const { statusInfo } = statusProps
  const { status } = statusInfo || {}
  const isActive = status === integrationsStatuses.active.id

  const updateRequestBGCApprovalEnabled = () => {
    const newValue = !isRequestBGCApprovalEnabled
    setIsRequestBGCApprovalEnabled(newValue)
    sendBGCApprovalSettings(newValue)
  }

  const updateIncludeBankruptcyCheckInReport = () => {
    const newValue = !excludeBankruptcyCheckInReport
    setExcludeBankruptcyCheckInReport(newValue)
    sendBankruptcyCheckInReport(newValue)
  }

  const updateBGCServiceFee = ({ value, error }) => {
    setBGCServiceFee(value)
    setBGCServiceFeeErr(error)
  }

  const sendBankruptcyCheckInReport = (isEnabled) => {
    if (isEnabled === excludeBankruptcyCheckInReport) return

    updateTeamSettings(currentTeam.guid, null, {
      ...appSettings,
      exclude_bankruptcy_report: isEnabled.toString(),
    })
      .then(() => {
        snugNotifier.success('Settings Updated')
        setExcludeBankruptcyCheckInReport(isEnabled)
      })
      .catch(({ message }) => snugNotifier.error(message))
  }

  const sendBGCApprovalSettings = (isEnabled) => {
    if (
      (isEnabled === isRequestBGCApprovalEnabledAttrValue &&
        bgcServiceFee === bgcServiceFeeAttrValue) ||
      !!bgcServiceFeeErr
    )
      return

    if (isNaN(Number(bgcServiceFee))) {
      return
    }

    const serviceFee = Number(bgcServiceFee).toFixed(2)
    setBGCServiceFee(serviceFee)

    updateTeamSettings(currentTeam.guid, null, {
      ...appSettings,
      share_app_with_owner_bgc_approval_enabled: isEnabled.toString(),
      share_app_with_owner_bgc_service_fee: isEnabled ? serviceFee : '',
    })
      .then(() => {
        snugNotifier.success('Settings Updated')
        setIsRequestBGCApprovalEnabledAttrValue(isEnabled)
        fetchBGCPrice()
      })
      .catch(({ message }) => snugNotifier.error(message))
  }

  const showOwnerForBGCCheckOptIn = isOwnerOptInForBGCEnabled(currentTeam?.slug)

  return (
    <>
      <IntegrationActivity
        integrationBrand={equifaxBrandId}
        logo={equifaxBrandLogo}
        statusProps={statusProps}
        activateProps={activationProps}
        deactivateProps={deactivateProps}
        brandsList={integrationBrands}
      />
      <Box mt={5} fontSize={theme.fontSizes.pRegular14}>
        <TextSpan as="span">
          View and download purchased reports in{' '}
          <Link
            to={urlTo(urlIds.teams.activity.backgroundChecks, {
              teamSlug: currentTeam.slug,
            })}
          >
            {' '}
            Activity
          </Link>
          . For product detail
        </TextSpan>{' '}
        <a target="_blank" href={backgroundCheckLearnMore} rel="noreferrer">
          Learn more
        </a>
      </Box>
      {activationModalElem}
      {showDeactivateModal && <DeactivateBGCModal />}
      {isActive && showOwnerForBGCCheckOptIn && !isBGCShareWithOwnerEligible && (
        <Flex alignItems="center" mt={5}>
          <CheckBoxGeneral
            id="enableRequestBGCApproval"
            componentClassName="mb0"
            label={ENABLE_BGC_OWNER_APPROVAL_LABEL}
            labelClass="fs16"
            disabled={true}
            showDisabledGrey={true}
          />
          <UpgradeButton />
        </Flex>
      )}
      {isActive && showOwnerForBGCCheckOptIn && isBGCShareWithOwnerEligible && (
        <Box mt={5} fontSize={theme.fontSizes.pRegular14}>
          <Flex alignItems="center">
            <CheckBoxGeneral
              id="enableRequestBGCApproval"
              checked={isRequestBGCApprovalEnabled}
              label={ENABLE_BGC_OWNER_APPROVAL_LABEL}
              onChange={updateRequestBGCApprovalEnabled}
              labelClass="fs16"
            />
            <Box mb="10px" fontSize={theme.fontSizes.pLarge16}>
              <a
                target="_blank"
                href={backgroundCheckLearnMore}
                rel="noreferrer"
              >
                learn more
              </a>
            </Box>
          </Flex>
          {isRequestBGCApprovalEnabled && showOwnerForBGCCheckOptIn && (
            <>
              <Flex alignItems="baseline">
                <TextSpan as="span" fontSize={5}>
                  Cost: Add ${bgcPrice} (ex GST), Service Fee ${' '}
                </TextSpan>{' '}
                <InputNumberWithTwoDecimalsAndZero
                  value={bgcServiceFee}
                  onChange={updateBGCServiceFee}
                  componentClass="ml5 mr5"
                  onBlurFunc={() => {
                    sendBGCApprovalSettings(isRequestBGCApprovalEnabled)
                  }}
                  inputClass="width80px"
                  error={bgcServiceFeeErr}
                  optional={false}
                />{' '}
                <TextSpan fontSize={5} as="span">
                  (ex GST),{' '}
                </TextSpan>
                <TextSpan
                  fontSize={5}
                  ml={1}
                  mr={1}
                  as="span"
                  fontWeight="bold"
                >
                  Display: ${totalPrice} (inc GST)
                </TextSpan>{' '}
                {bgcPriceSpinner && (
                  <Spinner
                    height={`${theme.width[5]}px`}
                    width={`${theme.height[5]}px`}
                  />
                )}
              </Flex>
            </>
          )}
        </Box>
      )}
      <Box mt={5} fontSize={theme.fontSizes.pRegular14}>
        <CheckBoxGeneral
          id="excludeBankruptcyCheckInReport"
          checked={excludeBankruptcyCheckInReport}
          label="Exclude Bankruptcy result in report"
          onChange={updateIncludeBankruptcyCheckInReport}
          labelClass="fs16"
        />
      </Box>
    </>
  )
}

const mapStateToProps = ({ session }) => ({
  currentTeam: session.currentTeam,
})

export default connect(mapStateToProps)(EquifaxIntegration)
