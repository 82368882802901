import { connect } from 'react-redux'

import { getManagerWait } from 'app/bond_cover/bond_cover_actions'
import ManagerWait from 'app/bond_cover/managers/status/manager_wait'

const mapStateToProps = ({ bondCover, session }) => ({
  currentUser: session.currentUser,
  managerRequestSummary: bondCover.managerRequestSummary,
})

const mapDispatchToProps = (dispatch) => ({
  getManagerWait: (requestID) => getManagerWait(requestID),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManagerWait)
