import React from 'react'

import 'app/sm/team_viewing_preference/applicationCap/style.scss'
import * as Form from 'app/components/forms/forms'

const ApplicationCap = ({
  applicationCapOption,
  applicationCapNumbers,
  onApplicationCapOptionChanged,
  disabled,
}) => {
  return (
    <div className="application-cap-container">
      <div className="display-flex flex-direction-column">
        <Form.Dropdown
          label="Please select the max number of application"
          value={applicationCapOption}
          options={applicationCapNumbers}
          onChange={onApplicationCapOptionChanged}
          extraOptionText="applications"
          selectIsDisabled={disabled}
        />
      </div>
    </div>
  )
}

export default ApplicationCap
