export const applicationsAttributesIDs = {
  applicationLimitPerApplicantEnabled:
    'application_limit_per_applicant_enabled',
  applicationLimitPerApplicant: 'application_limit_per_applicant',
  compactWorkflowEnabled: 'compact_workflow_enabled',
  applicationSuccessfulBallotsSize: 'application_successful_ballots_size',
  applicationReserveBallotsSize: 'application_reserve_ballots_size',
  payslipsRequiredDurationEnabled: 'payslips_min_duration_enabled',
  payslipsRequiredDuration: 'payslips_min_duration_by_weeks',
  bgCWorkflowRestrictionEnabled: 'bgc_workflow_restriction_enabled',
}

export const applicationLimitPerApplicantOptions = [1, 2, 3]
export const applicationLimitPerApplicantDefault = 3

// range 6 - 26
export const payslipsMinWeeksRequiredOptions = [1, 2, 4, 6, 8, 12, 26, 52]

export const payslipsMinWeeksDefault = 6

export const applicationSuccessfulBallotsSizeOptions = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
]

export const applicationSuccessfulBallotsSizeDefault = 10

export const applicationReserveBallotsSizeOptions = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
]

export const applicationReserveBallotsSizeDefault = 10

export const attachmentCategoriesOptions = [
  {
    value: 0,
    optionMeta: {
      id: 0,
      displayText: 'Unpublished',
    },
  },
  {
    value: 4,
    optionMeta: {
      id: 1,
      displayText: 'All',
    },
  },
  {
    value: 2,
    optionMeta: {
      id: 3,
      displayText: 'NRAS',
    },
  },
  {
    value: 3,
    optionMeta: {
      id: 4,
      displayText: 'Social',
    },
  },
  {
    value: 5,
    optionMeta: {
      id: 5,
      displayText: 'Affordable',
    },
  },
  {
    value: 6,
    optionMeta: {
      id: 6,
      displayText: 'Essential Worker',
    },
  },
  {
    value: 1,
    optionMeta: {
      id: 2,
      displayText: 'Market',
    },
  },
]
