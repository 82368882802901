import { connect } from 'react-redux'

import { fetchRentalHistoryRatingCategories } from 'app/bond_cover/bond_cover_actions'
import {
  deleteRentalHistory,
  fetchRentalHistory,
} from 'app/bond_cover/bond_cover_actions'
import RentalHistoryList from 'app/bond_cover/renters/history/history_list_container'
import { addRentalReputationBackUrl } from 'app/shared_components/actions'
import { fetchApplication } from 'app/sm/apply/apply_actions'
import { fetchCompleteness } from 'app/sm/rental_reputation/actions'
import {
  changeReputationPreference,
  fetchTeamSettingInformation,
  retrieveReputationPreference,
} from 'app/sm/rental_reputation/actions'

const mapStateToProps = ({
  session,
  bondCover,
  Shared,
  apply,
  RentalReputation,
}) => ({
  currentUser: session.currentUser,
  rentalHistory: bondCover.rentalHistory,
  backUrl: Shared.backUrl,
  clicked: bondCover.clicked,
  property: apply.property,
  reputationPreference: RentalReputation.preference,
  ratingCategories: bondCover.versionsRatingCategories,
  completeness: RentalReputation.completeness,
  applicationDetails: apply.application,
})

const mapDispatchToProps = (dispatch) => ({
  fetchRentalHistory: () => dispatch(fetchRentalHistory()),
  fetchRentalHistoryRatingCategories: () =>
    dispatch(fetchRentalHistoryRatingCategories()),
  deleteRentalHistory: (rentalHistoryId, success, fail) =>
    dispatch(deleteRentalHistory(rentalHistoryId, success, fail)),
  retrieveReputationPreference: () => dispatch(retrieveReputationPreference()),
  changeReputationPreference: (preference) =>
    dispatch(changeReputationPreference(preference)),
  addRentalReputationBackUrl: (rentalBackUrl) =>
    dispatch(addRentalReputationBackUrl(rentalBackUrl)),
  fetchCompleteness: () => dispatch(fetchCompleteness()),
  fetchTeamSettingInformation: (teamId, queryString) =>
    dispatch(fetchTeamSettingInformation(teamId, queryString)),
  fetchApplication: (applicationId) =>
    dispatch(fetchApplication(applicationId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RentalHistoryList)
