import React from 'react'

import ContactDetails from 'app/match/apply/application_summary_before_submit/shared_components/contact_container/component'
import { relationshipTypesEmergencyContact } from 'app/sm/helpers'

const SummaryEmergencyContactBox = ({ contact, from = '' }) => {
  const {
    firstName = '',
    lastName = '',
    phoneNumber = '',
    email = '',
    relationship = 0,
  } = contact || {}
  const fromRenterSummary = from === 'renterSummary'
  return (
    <div
      className={`bc-hub-item-link card-block pdf-width-50p ${
        fromRenterSummary && 'no-box-shadow p0'
      }`}
    >
      <div className="card-wrapper">
        <div className="card-details w0p card-info">
          <content>
            <ContactDetails
              firstName={firstName}
              lastName={lastName}
              phoneNumber={phoneNumber}
              email={email}
              extraName={relationshipTypesEmergencyContact[relationship]}
              showContactLabel={false}
            />
          </content>
        </div>
      </div>
    </div>
  )
}

export default SummaryEmergencyContactBox
