import BaseInput from 'app/components/forms/base_input/component'
import {
  firstElementOrEmptyString,
  withValidation,
} from 'app/components/forms/util'
import * as validations from 'app/shared_components/validations'

const validateNumber = (value) =>
  firstElementOrEmptyString(
    validations.validateNumberWithTwoDecimalsAndZero(value, 'Invalid number'),
  )

const InputNumberWithTwoDecimalsAndZero = withValidation(
  BaseInput,
  validateNumber,
)

export default InputNumberWithTwoDecimalsAndZero
