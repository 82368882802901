import * as React from 'react'

function Help(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 12C22 17.5225 17.5225 22 12 22C6.4775 22 2 17.5225 2 12C2 6.4775 6.4775 2 12 2C17.5225 2 22 6.4775 22 12Z"
        fill="#1AB25D"
      />
      <path
        d="M12.8545 14.416H11.1113C11.1068 14.1654 11.1045 14.0127 11.1045 13.958C11.1045 13.3929 11.1979 12.9281 11.3848 12.5635C11.5716 12.1989 11.9453 11.7887 12.5059 11.333C13.0664 10.8773 13.4014 10.5788 13.5107 10.4375C13.6794 10.2142 13.7637 9.9681 13.7637 9.69922C13.7637 9.32552 13.6133 9.00651 13.3125 8.74219C13.0163 8.47331 12.6152 8.33887 12.1094 8.33887C11.6217 8.33887 11.2139 8.47786 10.8857 8.75586C10.5576 9.03385 10.332 9.45768 10.209 10.0273L8.44531 9.80859C8.49544 8.99284 8.8418 8.30013 9.48438 7.73047C10.1315 7.16081 10.9792 6.87598 12.0273 6.87598C13.1302 6.87598 14.0075 7.16536 14.6592 7.74414C15.3109 8.31836 15.6367 8.98828 15.6367 9.75391C15.6367 10.1777 15.516 10.5788 15.2744 10.957C15.0374 11.3353 14.527 11.8503 13.7432 12.502C13.3376 12.8392 13.0846 13.1104 12.9844 13.3154C12.8887 13.5205 12.8454 13.8874 12.8545 14.416ZM11.1113 17V15.0791H13.0322V17H11.1113Z"
        fill="white"
      />
    </svg>
  )
}

export default Help
