import moment from 'moment'

import * as helpers from 'app/sm/helpers'
import * as dateTimeHelpers from 'app/utils/datetime/helpers'

export const durationOptionsNumbers = [5, 10, 15, 20, 30, 45, 60, 90, 120]
export const durationOptionsText = [
  '5 mins',
  '10 mins',
  '15 mins',
  '20 mins',
  '30 mins',
  '45 mins',
  '60 mins',
  '90 mins',
  '120 mins',
]
export const defaultEditViewingModalValues = {
  duration: 0, // Index of dropdown
  notify: false,
  published: false,
  startDate: moment().format(dateTimeHelpers.DATE_DAY_SHORT_STRING_MONTH_YEAR),
  startTime: moment().add(1, 'hour').startOf('hour').format(helpers.timeFormat),
}
