import { translateErrorCodeToMessage } from 'app/constants/error_messages'
import { composeAttachment } from 'app/shared_components/helpers'
import { history } from 'app/shared_components/router'
import {
  addPetReference,
  createPet,
  deletePet,
  deletePetReference,
  getPet,
  getPets,
  updatePet,
} from 'app/sm/pets_details/pets_details_add_access'

export const RECEIVE_PET = 'RECEIVE_PET'
export const RECEIVE_PETS = 'RECEIVE_PETS'
export const TOGGLE_SPINNER = 'PETS_TOGGLE_SPINNER'
export const RECEIVE_RESPONSE_TEXT = 'PETS_RECEIVE_RESPONSE_TEXT'
export const RECEIVE_PET_DOCUMENTS = 'RECEIVE_PET_DOCUMENTS'

const receivePet = (pet) => ({ type: RECEIVE_PET, pet })
const receivePets = (petList) => ({ type: RECEIVE_PETS, petList })
const receiveResponseText = (responseText) => ({
  type: RECEIVE_RESPONSE_TEXT,
  responseText,
})
const toggleSpinner = () => ({ type: TOGGLE_SPINNER })
const receivePetDocuments = (petDocs) => ({
  type: RECEIVE_PET_DOCUMENTS,
  petDocs,
})

export const removePet = (id) => {
  return (dispatch) => {
    deletePet(id).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(fetchPets())
        dispatch(receivePet({})) // fix using old pet from props TODO: refactor pet add/edit form
      } else {
        responseText.then((t) => dispatch(receiveResponseText(t)))
      }
    })
  }
}

export const editPet = (data, id) => {
  return (dispatch) => {
    updatePet(data, id).then(({ ok, responseText }) => {
      if (ok) {
        dispatch(fetchPets())
        history.goBack()
      } else {
        responseText.then((error) =>
          dispatch(receiveResponseText(translateErrorCodeToMessage(error))),
        )
      }
    })
  }
}

export const addPet = (data) => {
  return (dispatch) => {
    createPet(data).then(({ ok, responseText, pet }) => {
      if (ok) {
        dispatch(receivePet(pet))
      } else {
        responseText.then((t) => dispatch(receiveResponseText(t)))
      }
    })
  }
}

export const fetchPet = (id, justPetDocuments = false) => {
  return (dispatch) => {
    return getPet(id).then(({ ok, pet, responseText }) => {
      if (ok) {
        if (justPetDocuments) {
          dispatch(receivePetDocuments(pet.petReferences))
        } else {
          dispatch(receivePet(pet))
          return Promise.resolve(pet)
        }
      } else {
        return responseText
          .then((t) => translateErrorCodeToMessage(t))
          .then((t) => Promise.reject(t))
      }
    })
  }
}

export const fetchPets = () => {
  return (dispatch) => {
    getPets().then(({ ok, pets, responseText }) => {
      if (ok) {
        dispatch(receivePets(pets))
      } else {
        responseText.then((t) => dispatch(receiveResponseText(t)))
      }
    })
  }
}

export const uploadPetReference = (id) => {
  return (dispatch, getState) => {
    let attachments = document.getElementById('pet-attachments')
    let f = new FormData()

    composeAttachment(attachments, f)
    dispatch(toggleSpinner())

    addPetReference(f, id).then(({ ok, statusCode, responseText }) => {
      if (ok) {
        if (getState().AddPetDetails.spinner) {
          dispatch(fetchPet(id, true))
          dispatch(toggleSpinner())
          dispatch(receiveResponseText(``))
        }
      } else {
        dispatch(toggleSpinner())
        responseText.then((t) => {
          if (!t) {
            dispatch(receiveResponseText(`${statusCode}`))
          } else {
            dispatch(receiveResponseText(translateErrorCodeToMessage(t)))
          }
        })
      }
    })
  }
}

export const removePetReference = (id, referenceID) => {
  return (dispatch) => {
    deletePetReference(id, referenceID).then(
      ({ ok, statusCode, responseText }) => {
        if (ok) {
          dispatch(fetchPet(id, true))
        } else {
          responseText.then((t) => {
            if (!t) {
              dispatch(receiveResponseText(`${statusCode}`))
            } else {
              dispatch(receiveResponseText(t))
            }
          })
        }
      },
    )
  }
}
