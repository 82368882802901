import React, { useState } from 'react'

const convertSelectedItemsToMap = (itemsIds) => {
  return itemsIds.reduce(
    (accMap, itemId) => ({
      ...accMap,
      [itemId]: true,
    }),
    {},
  )
}

export function useMultiSelect() {
  const [selectedItemsMap, setSelectedItemsMap] = useState({})

  const toggleItemSelection = (itemId) => {
    const isSelected = selectedItemsMap[itemId]
    if (isSelected) {
      delete selectedItemsMap[itemId]
      setSelectedItemsMap({
        ...selectedItemsMap,
      })
    } else {
      setSelectedItemsMap({
        ...selectedItemsMap,
        [itemId]: true,
      })
    }
  }

  const setSelectedItemsSetIds = (itemsIds) => {
    setSelectedItemsMap(convertSelectedItemsToMap(itemsIds || []))
  }

  const filterOutNonExistingItems = (allExistingItemsIds) => {
    const currentSelectionIds = Object.keys(selectedItemsMap)
    const needUpdate = currentSelectionIds.some(
      (id) => !allExistingItemsIds.includes(id),
    )
    if (!needUpdate) {
      return
    }

    const existingSelectionItemsIds = currentSelectionIds.filter((id) =>
      allExistingItemsIds.includes(id),
    )
    setSelectedItemsMap(convertSelectedItemsToMap(existingSelectionItemsIds))
  }

  const selectedIds = Object.keys(selectedItemsMap)

  return {
    selectedItemsIds: selectedIds,
    selectedItemsMap,
    toggleItemSelection,
    setSelectedItemsSetIds,
    filterOutNonExistingItems,
  }
}

// HOC to be used for Class Components
export const withMultiSelect = (Cmp) => {
  return function WithMultiSelect(props) {
    const multiSelect = useMultiSelect()
    return <Cmp {...multiSelect} {...props} />
  }
}
