import React from 'react'

import { Link } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Pie, PieChart } from 'recharts'
import styled from 'styled-components'

import questionIcon from 'app/assets/icons/question-icon.svg'
import { Box, Flex, TextSpan } from 'app/components/design-system-components'
import * as Display from 'app/components/display/display'
import theme from 'app/match/applicationReportPDF/assets/theme'
import CopyableText from 'app/shared_components/CopyableText'
import Amenities from 'app/sm/common/amenities'
import {
  ApplicationCategory,
  isApplicantApplied,
  isApplicationAppliedNotWithdrawn,
  isApplicationApplyAnywhere,
  isApplicationExpired,
  isPrimaryApplicant,
  isStatusWithdrawn,
  myApplicationCardBtnClass,
  NO_IMAGE_PLACEHOLDER_URL,
  STATUS_ACCEPTED,
  STATUS_DRAFT,
  STATUS_OFFERED,
  STATUS_PAYMENT_PENDING,
  urlTo,
} from 'app/sm/helpers'
import { isAutoWithdrawn } from 'app/utils/applications/helpers'
import { renterApplicationStatusLabels } from 'app/utils/applications/renters'
import { getPropertyAvailabilityDate } from 'app/utils/datetime/helpers'
import * as textHelpers from 'app/utils/text/helpers'

const RankChart = styled.div`
  position: relative;
  border-bottom: 1px solid #e8e8e9 !important;
  padding: 16px 0;
  .recharts-wrapper {
    display: inline-block;
  }
  .rank-chart-wrapper {
    display: flex;
    align-items: center;
  }
  .text {
    top: 41%;
    font-size: 16px;
    position: absolute;
    left: 6%;
  }
  .snug-match-score {
    display: inline-block;
    margin-left: 8px;
  }
  .score-info {
    background-image: url(${questionIcon});
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    margin-left: 8px;
  }
`

const BallotContainer = styled(Flex)`
  position: relative;
  border-bottom: 1px solid #e8e8e9 !important;
  padding: ${theme.space[8]}px 0;
  font-weight: ${theme.fontWeights[6]};
  color: ${theme.colors.gray600};
  a {
    font-weight: ${theme.fontWeights[4]};
    display: inline-block;
    margin-left: ${theme.space[3]}px;
  }
`

const ApplicantsInformation = styled.div`
  margin: 8px 0;
  color: #686d71;
  .info-heading {
    font-weight: bold;
  }
  .submitted-invited-info {
    margin-top: 4px;
  }
`

const PillAndAvatarContainer = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: ${(props) =>
    props.applicationWithdrawn ? 'flex-end' : 'space-between'};
  & > div {
    width: 50%;
  }
  .avatars {
    display: flex;
    padding-right: 24px;
    width: 50%;
    flex-wrap: wrap;
    justify-content: flex-end;
    & > div {
      height: 30px;
      width: 25%;
    }
    .default-font-family {
      font-size: 14px !important;
      vertical-align: top;
      margin-top: 6px;
      display: inline-block;
    }
    div:nth-child(4n) {
      flex-wrap: wrap;
    }
  }
`

const OppositeSidesContainer = styled.div`
  color: #686d71;
  display: flex;
  padding: 8px 0 16px 0;
  border-bottom: 1px solid #e8e8e9 !important;
  justify-content: space-between;
`

const RankPieChart = ({ rank }) => {
  let fillColor = '#37c977'
  if (rank < 75) {
    fillColor = '#24C9D0'
  }
  if (rank < 50) {
    fillColor = '#579FFB'
  }
  if (rank < 25) {
    fillColor = '#ffe757'
  }
  const data = [
    { name: 'rank', value: rank, fill: fillColor },
    { name: 'blank', value: 100 - rank, fill: '#ffffff' },
  ]
  return (
    <PieChart width={50} height={50}>
      <Pie
        dataKey="value"
        data={data}
        innerRadius={21}
        outerRadius={25}
        labelLine={false}
      />
    </PieChart>
  )
}

const setFavorite = (event, propertyId, addToSavedProperty) => {
  addToSavedProperty(propertyId)
  event.preventDefault()
}

const AvatarsInARow = ({ applicants, componentClassName }) => {
  return (
    <div className={componentClassName}>
      {applicants.map(({ avatar, firstName, lastName, guidID }) => {
        return (
          <Display.Avatar
            key={guidID}
            avatar={avatar}
            avatarText={`${firstName.substring(0, 1).toUpperCase()}${lastName
              .substring(0, 1)
              .toUpperCase()}`}
            size="xssmall"
          />
        )
      })}
    </div>
  )
}

const StyledApplicationCardContainer = styled.div`
  pointer-events: ${({ isManagerAdded }) =>
    isManagerAdded ? 'none' : 'all'} !important;
`

// used in renter's my applications
const MyApplicationCard = (props) => {
  const { application = {}, addToSavedProperty, currentUser } = props
  const applicant = application && application.applicant
  const { applicants } = application
  const {
    offer,
    term,
    weeklyRent,
    isManagerAdded,
    ballot = {},
  } = application || {}

  const {
    availableFrom,
    property,
    ballotsEnabled = false,
    property: { agencyID: agencyId = '' },
  } = offer || {}

  const { slug: ballotSlug = '' } = ballot || {}

  const isApplicationAccepted = [STATUS_ACCEPTED, STATUS_PAYMENT_PENDING].some(
    (status) => application.status === status,
  )
  const isApplicationOffered = [STATUS_OFFERED].some(
    (status) => application.status === status,
  )

  const availableDate = getPropertyAvailabilityDate(availableFrom, null)

  const isApplyAnywhere = isApplicationApplyAnywhere(
    application.applicationType,
  )
  const applyAnywhereListing = application && application.applyAnywhereListing

  let imageUrl = NO_IMAGE_PLACEHOLDER_URL
  if (property.images && property.images.length > 0) {
    imageUrl = (
      property.images.find((image) => image.isMain === true) ||
      property.images[0]
    ).URL
  }
  if (
    applyAnywhereListing &&
    applyAnywhereListing.referrer_img_url &&
    applyAnywhereListing.referrer_img_url !== ''
  ) {
    imageUrl = applyAnywhereListing.referrer_img_url
  }

  let address = ''
  let suburb = ''
  if (!isApplyAnywhere && property.address) {
    if (property.address.unitNumber) {
      address = `${property.address.unitNumber}/${property.address.streetNumber} ${property.address.streetName}`
    } else {
      address = `${property.address.streetNumber} ${property.address.streetName}`
    }
    suburb = property.address.suburb
  } else {
    if (isApplyAnywhere && applyAnywhereListing) {
      if (applyAnywhereListing.address.unitNumber) {
        address = `${applyAnywhereListing.address.unitNumber}/${applyAnywhereListing.address.streetNumber} ${applyAnywhereListing.address.streetName}`
      } else {
        address = `${applyAnywhereListing.address.streetNumber} ${applyAnywhereListing.address.streetName}`
      }
      suburb = `${applyAnywhereListing.address.suburb} ${applyAnywhereListing.address.state} ${applyAnywhereListing.address.postcode}`
    }
  }

  let submittedApplicants = ''
  let invitedApplicants = ''
  const invitedAndSubmittedInfo = {
    invited: applicants.filter(
      (applicant) => !applicant.applied && application.status !== STATUS_DRAFT,
    ),
    invitedNum: applicants.filter(
      (applicant) => !applicant.applied && application.status !== STATUS_DRAFT,
    ).length,
    submitted: applicants.filter((applicant) => applicant.applied),
    submittedNum: applicants.filter((applicant) => applicant.applied).length,
  }

  submittedApplicants = invitedAndSubmittedInfo.submitted.reduce(
    (acc, applier, index) => {
      let separator = ', '
      if (index === invitedAndSubmittedInfo.submittedNum - 1) {
        separator = ''
      }
      if (applicant && applicant.guidID === applier.guidID)
        return acc + 'You' + separator
      return acc + applier.firstName + separator
    },
    '',
  )

  invitedApplicants = invitedAndSubmittedInfo.invited.reduce(
    (acc, applier, index) => {
      let separator = ', '
      if (index === invitedAndSubmittedInfo.invitedNum - 1) {
        separator = ''
      }
      if (currentUser.email === applier.email) return acc + 'You' + separator
      return acc + applier.firstName + separator
    },
    '',
  )

  // check undefined is necessary in this case as for old applications, applicant will be undefined
  const isPrimary = applicant === undefined || isPrimaryApplicant(applicant)
  const hasApplied = applicant === undefined || isApplicantApplied(applicant)

  const applicationStatus = ApplicationCategory[application.status]
  let btnClassname = myApplicationCardBtnClass(application.status, hasApplied)

  let buttonLabel = 'Unknown',
    statusPillText = 'Unknown',
    borderColor = '#686D71'
  if (hasApplied || isPrimary) {
    if (
      renterApplicationStatusLabels['primary'] &&
      renterApplicationStatusLabels['primary'][application.status] &&
      renterApplicationStatusLabels['primary'][application.status][
        'buttonLabel'
      ]
    ) {
      buttonLabel =
        renterApplicationStatusLabels['primary'][application.status][
          'buttonLabel'
        ]
      statusPillText =
        renterApplicationStatusLabels['primary'][application.status][
          'statusPillText'
        ]
      borderColor =
        renterApplicationStatusLabels['primary'][application.status][
          'statusPillBorderColor'
        ]
    }
  } else {
    if (
      renterApplicationStatusLabels['secondary'] &&
      renterApplicationStatusLabels['secondary'][application.status] &&
      renterApplicationStatusLabels['secondary'][application.status][
        'buttonLabel'
      ]
    ) {
      buttonLabel =
        renterApplicationStatusLabels['secondary'][application.status][
          'buttonLabel'
        ]
      statusPillText =
        renterApplicationStatusLabels['secondary'][application.status][
          'statusPillText'
        ]
      borderColor =
        renterApplicationStatusLabels['secondary'][application.status][
          'statusPillBorderColor'
        ]
    }
  }

  if (isApplicationExpired(application)) {
    statusPillText += ' (Expired)'
    borderColor = '#CA5442'
    buttonLabel += ' (Expired)'
    btnClassname = 'btn-empty'
  }

  if (isAutoWithdrawn(application)) {
    statusPillText += ' (Auto-Withdrawn)'
    borderColor = '#CA5442'
    buttonLabel = 'Auto-Withdrawn'
    btnClassname = 'btn-empty'
  }

  let applicationCorrespondingLink = ''
  if (isApplicationOffered) {
    applicationCorrespondingLink = urlTo('leasingOfferRenter', {
      applicationId: application.guidID,
      agencyId,
    })
  } else if (isApplicationAccepted) {
    applicationCorrespondingLink = `${urlTo('offeredApplication', {
      propertyId: property.guidID,
      applicationId: application.guidID,
    })}?agencyId=${agencyId}`
  } else {
    applicationCorrespondingLink = urlTo('application', {
      applicationId: application.guidID,
    })
  }

  const applicationConfirmationLink = !ballotsEnabled
    ? urlTo('applyConfirmationSummary', {
        applicationId: application.guidID,
      })
    : urlTo('applyConfirmationSummaryBallot', {
        applicationId: application.guidID,
      })
  const applicationWithdrawn = isStatusWithdrawn(applicationStatus)

  return (
    <StyledApplicationCardContainer
      isManagerAdded={isManagerAdded}
      className="sm-properties-card-list-item"
    >
      <div className="sm-properties-card-list-item-wrapper pb20">
        <Link
          to={
            isApplicationAppliedNotWithdrawn(
              application,
              applicationStatus,
              applicant,
            )
              ? applicationConfirmationLink
              : applicationCorrespondingLink
          }
          className="image"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <div className="controls">
            <div className="left">
              <button
                onClick={(e) =>
                  setFavorite(e, property.guidID, addToSavedProperty)
                }
              >
                <i
                  className={
                    property.isFavourite
                      ? 'icon-heart-filled red-color'
                      : 'icon-heart-outline'
                  }
                ></i>
              </button>
            </div>
          </div>
        </Link>
        <PillAndAvatarContainer applicationWithdrawn={applicationWithdrawn}>
          {!applicationWithdrawn && (
            <Display.StatusPill
              pillType="grey"
              pillTextClass="grey-text font-size-14"
              componentClass="mb8"
              text={statusPillText}
              borderColor={borderColor}
            />
          )}
          <AvatarsInARow applicants={applicants} componentClassName="avatars" />
        </PillAndAvatarContainer>
        <div className="meta">
          <div className="address">
            <Link
              to={
                isApplicationAppliedNotWithdrawn(
                  application,
                  applicationStatus,
                  applicant,
                )
                  ? applicationConfirmationLink
                  : urlTo('application', {
                      applicationId: application.guidID,
                    })
              }
              className="street"
              title={address}
            >
              {address}, {suburb}
            </Link>
          </div>
        </div>
        {!isApplyAnywhere && (
          <Amenities
            payload={property}
            wrapClassName="amenities-widget"
            availableDate={availableDate}
          />
        )}
        <OppositeSidesContainer>
          <div>Rent: ${weeklyRent}</div>
          <div>Lease: {term} months</div>
        </OppositeSidesContainer>
        {!isApplyAnywhere && !ballotsEnabled && (
          <RankChart>
            <div className="rank-chart-wrapper">
              <RankPieChart rank={Math.round(application.score)} />

              <div className="text">{Math.round(application.score)}</div>
              <div className="snug-match-score">Snug Match score</div>
              <div
                data-tooltip-content=""
                data-tooltip-id={application.guidID}
                className="score-info"
              >
                <ReactTooltip
                  id={application.guidID}
                  data-type="info"
                  className="tool-tip-style"
                >
                  <span>{textHelpers.SNUG_MATCH_LABEL}</span>
                </ReactTooltip>
              </div>
            </div>
          </RankChart>
        )}
        {ballotsEnabled && (
          <BallotContainer>Ballot ticket: {ballotSlug}</BallotContainer>
        )}
        <div className="info">
          <ApplicantsInformation>
            {!isManagerAdded && (
              <Box mb="8px">
                <TextSpan className="info-heading">
                  App ID: <CopyableText text={`${application.slug}`} />
                </TextSpan>
              </Box>
            )}
            <div className="info-heading">Applicants and occupants</div>
            <div className="submitted-invited-info">
              <div className="submitted-info">
                {`Submitted (${invitedAndSubmittedInfo.submittedNum}): ${submittedApplicants}`}
              </div>
              <div className="invited-info">
                {`Invited (${invitedAndSubmittedInfo.invitedNum}): ${invitedApplicants}`}
              </div>
            </div>
          </ApplicantsInformation>
          {!isManagerAdded && (
            <>
              <OppositeSidesContainer>
                <Link
                  to={urlTo('application', {
                    applicationId: application.guidID,
                  })}
                  className="blue-link-style"
                >
                  {hasApplied || isPrimary
                    ? 'Add/Edit a Joint Applicant'
                    : 'View Household'}
                </Link>
              </OppositeSidesContainer>
              <Link
                className={`btn ${btnClassname} mt20`}
                to={
                  isApplicationAppliedNotWithdrawn(
                    application,
                    applicationStatus,
                    applicant,
                  )
                    ? applicationConfirmationLink
                    : applicationCorrespondingLink
                }
              >
                {buttonLabel}
              </Link>
            </>
          )}
        </div>
      </div>
    </StyledApplicationCardContainer>
  )
}

export default MyApplicationCard
