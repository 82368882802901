import React from 'react'

import { Link } from 'react-router-dom'

import { history } from 'app/shared_components/router'
import WithdrawnForm from 'app/shared_components/templates/withdraw_form'

function WithdrawConfirm(props) {
  const title = props.match.path.includes('secondarywithdrawconfirm')
    ? 'This request has been withdrawn by the applicant.'
    : 'You have withdrawn this BondCover request.'

  return (
    <div className="two-col-box-flex-row sm64">
      <div className="col-first">
        <WithdrawnForm title={title} />
      </div>
    </div>
  )
}

export default WithdrawConfirm
