import React from 'react'

import moment from 'moment'

import TenantsForm from 'app/bond_cover/renters/tenants/tenants_form.jsx'
import getParameter from 'app/shared_components/parameter_parser'
import { history } from 'app/shared_components/router'
import * as validations from 'app/shared_components/validations'
import { urlTo } from 'app/sm/helpers'

class TenantsFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNewLease: false,
      requestID: '',
    }
  }

  componentDidMount() {
    let requestID = getParameter('requestid')
    this.props.setBackUrl(urlTo('bondCoverTenants', { requestID }))
    this.setState({ requestID: requestID })

    let { fetchTenants } = this.props
    fetchTenants(requestID)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { currentLease } = nextProps
    let isNewLease = currentLease ? currentLease.isNewLease : ''
    this.setState({ isNewLease: isNewLease })
  }

  addTenant() {
    history.push(`/bc/tenant?requestid=${this.state.requestID}&id=`)
  }

  splitBond() {
    history.push(`/bc/bondsplits?requestid=${this.state.requestID}`)
  }

  render() {
    let { currentLease, tenants, deleteTenant } = this.props
    let { isNewLease } = this.state
    if (tenants) {
      return (
        <TenantsForm
          isNewLease={isNewLease}
          currentLease={currentLease}
          tenants={tenants}
          requestID={this.state.requestID}
          addTenant={() => this.addTenant()}
          deleteTenant={deleteTenant}
          splitBond={() => this.splitBond()}
        />
      )
    } else {
      return null
    }
  }
}

export default TenantsFormContainer
