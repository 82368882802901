import React from 'react'

import styled from 'styled-components'

import { Box, Button, Flex } from 'app/components/design-system-components'
import ContactDetails from 'app/components/design-system-components/ContactDetails'

const StyledTeamItem = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 8px 16px;
`

function TeamList({
  membersList,
  showInviteButtons = false,
  onClickInvite,
  invitedMembersEmail = [],
}) {
  return (
    <>
      {membersList &&
        membersList.map((member) => {
          const isInvitedMember = invitedMembersEmail.find(
            (e) => e === member.email,
          )
          return (
            <StyledTeamItem justifyContent="space-between" alignItems="center">
              <ContactDetails
                contactNumber={member.phone}
                email={member.email}
                name={member.name}
              />
              {showInviteButtons && (
                <Box>
                  <Button
                    sizeVariant="small"
                    variant={
                      isInvitedMember ? 'outlineWarning' : 'outlineGreen'
                    }
                    onClick={() => {
                      !isInvitedMember && onClickInvite(member)
                    }}
                  >
                    {isInvitedMember ? 'Invited' : 'Invite'}
                  </Button>
                </Box>
              )}
            </StyledTeamItem>
          )
        })}
    </>
  )
}

export default TeamList
