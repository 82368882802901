import React from 'react'

import BackgroundCheck from 'app/match/applicationReportPDF/pages/Snug-pdf-1/BackgroundCheck'
import Header from 'app/match/applicationReportPDF/pages/Snug-pdf-1/Header'
import Household from 'app/match/applicationReportPDF/pages/Snug-pdf-1/Household'
import ApplicantContainer from 'app/match/applicationReportPDF/pages/Snug-pdf-1/PrimaryApplicant'
import Requests from 'app/match/applicationReportPDF/pages/Snug-pdf-1/Requests'
import Summary from 'app/match/applicationReportPDF/pages/Snug-pdf-1/Summary'
import IdentityDocuments from 'app/match/applicationReportPDF/pages/Snug-pdf-4/IdentityDocuments'

const SnugPdf_1 = (props) => {
  const {
    applicantInfo = {},
    backgroundCheck = {},
    applicationSummary = {},
    householdInfo = [],
    identityInfo = {},
    optOutBackgroundCheck = false,
    hasLeaseDetailsBeenModified = false,
    modifiedApplicationSummary = {},
    agencyInfo = {},
    currentUser = {},
    applicationPropertyState,
  } = props
  const targetApplicant = {
    about: applicantInfo.aboutMe,
    imageUrl: '',
    applicantInfo: {
      email: applicantInfo.email,
      mobile: applicantInfo.mobile,
      name: applicantInfo.name,
    },
    isPrimary: applicantInfo.isPrimary,
  }
  const { requests = '' } = applicantInfo
  return (
    <div>
      <Header
        applicantInfo={applicantInfo}
        agencyInfo={agencyInfo}
        currentUser={currentUser}
      />
      <div className="custom-row">
        <div className="custom-column equal-basis">
          <ApplicantContainer targetApplicant={targetApplicant} />
          <IdentityDocuments data={identityInfo} />
          <BackgroundCheck
            backgroundCheck={backgroundCheck}
            optOutBackgroundCheck={optOutBackgroundCheck}
            applicationPropertyState={applicationPropertyState}
          />
        </div>
        <div className="custom-column equal-basis ">
          <Summary
            summary={applicationSummary}
            hasLeaseDetailsBeenModified={hasLeaseDetailsBeenModified}
            modifiedApplicationSummary={modifiedApplicationSummary}
          />
          <Requests requests={requests} />
          <Household household={householdInfo} />
        </div>
      </div>
    </div>
  )
}

export default SnugPdf_1
