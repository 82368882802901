import styled from 'styled-components'

import { Box, Flex } from 'app/components/design-system-components'
import { mediaQueries } from 'app/components/design-system-components/styles/variables'
import { theme } from 'app/match/applicationReportPDF/assets/theme'

const gridAreas = {
  property: 'property',
  actions: 'actions',
}

export const TeamOverviewGridContainer = styled(Box)`
  padding: ${theme.space[4]}px 0;

  display: grid;

  grid-template-columns: 30% 1fr 1fr 1fr 1fr 30%;
  grid-template-areas: '${gridAreas.property} . . . . ${gridAreas.actions}';

  ${mediaQueries.md} {
    grid-template-columns: 50% 1fr 1fr 1fr 1fr;
    grid-row-gap: 10px;

    grid-template-areas:
      '${gridAreas.property} . . . .'
      '${gridAreas.actions} ${gridAreas.actions} ${gridAreas.actions} ${gridAreas.actions} ${gridAreas.actions}';
  }

  ${mediaQueries.sm} {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;

    grid-template-areas:
      '${gridAreas.property} ${gridAreas.property}'
      '. .'
      '. .'
      '${gridAreas.actions} ${gridAreas.actions}';
  }
`

export const TeamOverviewGridPropertyContainer = styled(Box)`
  grid-area: ${gridAreas.property};
`

export const TeamOverviewGridActionsContainer = styled(Flex)`
  grid-area: ${gridAreas.actions};
`
