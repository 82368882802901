import { LogFetchError } from 'app/shared_components/helpers'
import { apiBaseUrl } from 'app/shared_components/shared_base_url'

export const postNewViewingRun = (viewingRunReqeust) => {
  const url = `${apiBaseUrl}sm/viewingrun`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(viewingRunReqeust),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (viewingRun) => ({ ok: true, viewingRun }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

// TODO: support date
export const listViewingRuns = (
  agencyGUID,
  managerGUID,
  startTime = '',
  endTime = '',
  offset,
) => {
  let url = `${apiBaseUrl}sm/viewingrun?managerGUID=${managerGUID}&agencyGUID=${agencyGUID}&offset=${offset}`
  url += `&startTime=${encodeURIComponent(
    startTime,
  )}&endTime=${encodeURIComponent(endTime)}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({ statusCode: res.status, responseText: res.text() })
    })
    .then(({ runSummaries, hasMore }) => {
      return { ok: true, runSummaries, hasMore }
    })
    .catch((error) => {
      return {
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }
    })
}

export const getViewingRunDetail = (viewingRunGUID) => {
  const url = `${apiBaseUrl}sm/viewingrun/${viewingRunGUID}`
  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({ statusCode: res.status, responseText: res.text() })
    })
    .then((viewingRun) => {
      return { ok: true, viewingRun }
    })
    .catch((error) => {
      return {
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }
    })
}

export const postViewingsRun = (teamGUID, managerPreferences) => {
  const url = `${apiBaseUrl}sm/viewing-runs/${teamGUID}/preflight`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(managerPreferences),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (viewingRun) => ({ ok: true, viewingRun }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postNewViewingsRun = (teamGUID, managerPreferences) => {
  const url = `${apiBaseUrl}sm/viewing-runs/${teamGUID}`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(managerPreferences),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => {
      return LogFetchError(error)
    })
    .then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.json(),
          })
    })
    .then(
      (viewingRun) => ({ ok: true, viewingRun }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getNewViewingsRun = (teamGUID) => {
  const url = `${apiBaseUrl}sm/viewing-runs/${teamGUID}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (viewingRun) => ({ ok: true, viewingRun }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const getNewViewingRunInfo = (teamGUID, viewingRunGUID) => {
  const url = `${apiBaseUrl}sm/viewing-runs/${teamGUID}/${viewingRunGUID}`

  return fetch(url, {
    credentials: 'include',
  })
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (viewingRun) => ({ ok: true, viewingRun }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}

export const postNewViewingsRunManagerCheckOutKey = (
  teamGUID,
  viewingGUID,
  managerGUID,
) => {
  const url = `${apiBaseUrl}sm/viewing-runs/${teamGUID}/${viewingGUID}/checkoutmanager/${managerGUID}`
  const options = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
  }

  return fetch(url, options)
    .catch((error) => LogFetchError(error))
    .then((res) =>
      res.ok
        ? res.json()
        : Promise.reject({
            statusCode: res.status,
            responseText: res.text(),
          }),
    )
    .then(
      (viewingRun) => ({ ok: true, viewingRun }),
      (error) => ({
        ok: false,
        statusCode: error.statusCode,
        responseText: error.responseText,
      }),
    )
}
