import React from 'react'

const Loadmore = ({ offset, onLoadMoreClicked }) => {
  return (
    <button
      className="btn hollow-button-green p0 mb5 width120px"
      onClick={() => onLoadMoreClicked(offset)}
    >
      Load more
    </button>
  )
}

export default Loadmore
