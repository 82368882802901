import React from 'react'

import $ from 'jquery'
import styled from 'styled-components'

import AgencyInfoHeader from 'app/components/agency_info_header/component'
import {
  Alert,
  Box,
  Button,
  Flex,
} from 'app/components/design-system-components'
import { ViewingRequestFormModal } from 'app/components/ViewingRequestFormModal'
import {
  ErrorGettingBuilding,
  ParameterIDNotValid,
} from 'app/constants/error_codes'
import ErrorMessages from 'app/constants/error_messages'
import theme from 'app/match/applicationReportPDF/assets/theme'
import * as snugNotifier from 'app/services/snugNotifier'
import { ErrorMessage, isMobile } from 'app/shared_components/helpers'
import { AgencyApplyCard } from 'app/sm/applications/components/agency_apply_card'
import Spinner from 'app/sm/common/spinner'
import { urlIds, urlTo, wait } from 'app/sm/helpers'
import {
  PropertySearchBox,
  PropertySearchFilterRow,
} from 'app/sm/properties/components/property_search_filter_util'
import * as textHelpers from 'app/utils/text/helpers'
import * as viewingsHelpers from 'app/utils/viewings/helpers'

const NUM_PROPERTIES_TO_FETCH = 24

const PropertyCardScreen = ({ children }) => {
  return <div>{children}</div>
}

const StyledFloatingButton = styled(Button)`
  position: fixed;
  bottom: ${theme.space[5]}px;
  z-index: 1000;
  width: 90%;
  box-shadow: 0 4px 30px 0 ${theme.colors.dropdownDroppedShadow};
`

const PropertyCardTitle = ({ count, agencyName = '', agencyGUID = '' }) => (
  <Flex className="section-title" justifyContent="center" pl={0} mt={5}>
    {count === 0 && (
      <Alert variant="blueWithBg" maxWidth="678px">
        {agencyGUID !== textHelpers.VARC_AGENCY_GUID
          ? `${agencyName} ${textHelpers.AGENCY_NO_PROPERTIES_SUFFIX}`
          : `${textHelpers.VARC_NO_PROPERIES_TEXT}`}
      </Alert>
    )}
  </Flex>
)

const BedroomDropDown = ({ value, onChange, className = '' }) => {
  return (
    <div className={`${className} property-search-filter-widget`}>
      <div className="ibm pr10">Bedroom : </div>
      <div className="input-box wa pt0">
        <select value={value} onChange={onChange}>
          <option value="0">Any</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4+</option>
        </select>
      </div>
    </div>
  )
}

const defaultSort = 'Any'
const ascRent = 'ascRent'
const descRent = 'descRent'
const ascSuburb = 'ascSuburb'
const descSuburb = 'descSuburb'

const SortDropDown = ({ value, onChange, className = '' }) => {
  return (
    <div className={`${className} property-search-filter-widget`}>
      <div className="ibm pr10">Sort By : </div>
      <div className="input-box wa pt0">
        <select value={value} onChange={onChange}>
          <option value={defaultSort}>Any</option>
          <option value={ascRent}>Lowest Rent</option>
          <option value={descRent}>Highest Rent</option>
          <option value={ascSuburb}>Suburb A-Z</option>
          <option value={descSuburb}>Suburb Z-A</option>
        </select>
      </div>
    </div>
  )
}

export const PropertyCardList = ({ children }) => (
  <div className="sm-properties-card-list info-type">{children}</div>
)

export const LoadMore = ({ cursor, loadMore }) => (
  <button
    className="wa ha btnmr mt10 mb10 pl20 pr20 pt10 pb10"
    onClick={() => loadMore(cursor)}
  >
    Show more properties
  </button>
)

const viewingRequestModal = {
  title: 'Enquire',
  primaryButtonLabel: 'Send',
}

const START_TIME = 8
const END_TIME = 17

const timesFrom9AMto5PM = viewingsHelpers.startToEndTimeIntervalsFromHours(
  START_TIME,
  END_TIME,
  15,
)

const durationMinutesDropdown = timesFrom9AMto5PM.map((time) => ({
  guid: time,
  name: time,
}))

const bedroomsDropdown = ['1', '2', '3', '4', '5', '5+']

class AgencyPropertiesApplyPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      address: '',
      sortBy: '',
      bedroom: 'Any',
      error: '',
      tableSpinner: false,
      propertyData: this.props.properties,
      agency: {
        agencyAddress: '',
        agencyContactNumber: '',
        agencyEmail: '',
        brandingBannerURL: '',
        brandingLogoURL: '',
      },
      isAgencyExist: true,
      showViewingRequestModal: false,
      isBuildingApplyPage: false,
      buildingName: '',
    }
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onSortByChange = this.onSortByChange.bind(this)
    this.onBedroomFilterChange = this.onBedroomFilterChange.bind(this)
    this.callFetchAgencyPublicProperties =
      this.callFetchAgencyPublicProperties.bind(this)
    this.loadMore = this.loadMore.bind(this)
    this.setSpinners = this.setSpinners.bind(this)
    this.toggleViewingRequstModal = this.toggleViewingRequstModal.bind(this)
  }

  componentDidMount() {
    $('#index').addClass('no-navbar-menu')
    const {
      match: {
        params: { agencySlug },
      },
    } = this.props

    const { address = '', sortBy = '', bedroom = '' } = this.state
    if (agencySlug) {
      this.callFetchAgencyPublicProperties(agencySlug, {
        address,
        sortBy,
        bedroom,
      })
        .then(({ properties }) => {
          this.setState({
            propertyData: properties,
            agency: {
              agencyID: properties.agencyId,
              agencyAddress: properties.contactAddress,
              brandingBannerURL: properties.brandingBannerURL,
              brandingLogoURL: properties.brandingLogoURL,
              agencyContactNumber: properties.contactNumber,
              agencyEmail: properties.contactEmail,
              agencyName: properties.contactName,
            },
            buildingName: properties.buildingName,
          })
        })
        .catch(() => {
          // no extra handling needed here as callFetchAgencyPublicProperties is taking care of showing errors or redirecting user to /404 page
        })
    }
  }

  componentWillUnmount() {
    $('#index').removeClass('no-navbar-menu')
  }

  onBedroomFilterChange = (event) => {
    const {
      match: {
        params: { agencySlug },
      },
    } = this.props
    const { address, sortBy, cursor, loadMore } = this.state
    this.setState({ bedroom: event.target.value }, () => {
      const { bedroom } = this.state
      this.callFetchAgencyPublicProperties(agencySlug, {
        address,
        sortBy,
        bedroom,
        cursor,
        loadMore,
      })
        .then(({ properties }) => {
          this.setState({
            propertyData: {
              data: properties.data,
              response_metadata: properties.response_metadata,
            },
          })
        })
        .catch((error) => {
          this.setState({ error })
        })
    })
  }

  onSearchChange = (event) => {
    const {
      match: {
        params: { agencySlug },
      },
    } = this.props
    const { sortBy, bedroom, cursor, loadMore } = this.state
    this.setState({ address: event.target.value }, () => {
      clearInterval(this.searchInterval)
      const { address } = this.state
      wait(500).then(() =>
        this.callFetchAgencyPublicProperties(agencySlug, {
          address,
          sortBy,
          bedroom,
          cursor,
          loadMore,
        })
          .then(({ properties }) => {
            this.setState({
              propertyData: {
                data: properties.data,
                response_metadata: properties.response_metadata,
              },
            })
          })
          .catch((error) => {
            this.setState({ error })
          }),
      )
    })
  }

  onSortByChange = (event) => {
    const {
      match: {
        params: { agencySlug },
      },
    } = this.props
    const { address, bedroom, cursor, loadMore } = this.state
    this.setState({ sortBy: event.target.value }, () => {
      const { sortBy } = this.state
      this.callFetchAgencyPublicProperties(agencySlug, {
        address,
        sortBy,
        bedroom,
        cursor,
        loadMore,
      })
        .then(({ properties }) => {
          this.setState({
            propertyData: {
              data: properties.data,
              response_metadata: properties.response_metadata,
            },
          })
        })
        .catch((error) => {
          this.setState({ error })
        })
    })
  }

  setSpinners = (loadMore, enable) => {
    if (loadMore) {
      this.setState({ loadMoreSpinner: enable })
    } else {
      this.setState({ tableSpinner: enable })
    }
  }

  callFetchAgencyPublicProperties = (
    agencySlug,
    { address, sortBy, bedroom, cursor, loadMore = false },
  ) => {
    const {
      match: {
        params: { buildingSlug = '' },
      },
    } = this.props
    if (buildingSlug.length > 0) {
      this.setState({ isBuildingApplyPage: true })
    }
    const { fetchAgencyPublicProperties, history } = this.props
    this.setSpinners(loadMore, true)
    let sort = ''
    let sortDir = ''
    switch (sortBy) {
      case defaultSort:
        sort = ''
        sortDir = ''
        break
      case ascRent:
        sort = 'rent'
        sortDir = 'asc'
        break
      case descRent:
        sort = 'rent'
        sortDir = 'desc'
        break
      case ascSuburb:
        sort = 'suburb'
        sortDir = 'asc'
        break
      case descSuburb:
        sort = 'suburb'
        sortDir = 'desc'
        break
      default:
        sort = ''
        sortDir = ''
        break
    }
    return fetchAgencyPublicProperties(
      agencySlug,
      address,
      sort,
      sortDir,
      bedroom,
      cursor,
      NUM_PROPERTIES_TO_FETCH,
      buildingSlug,
    )
      .then(({ properties }) => {
        this.setSpinners(loadMore, false)
        return Promise.resolve({ properties })
      })
      .catch((error) => {
        this.setState({ error })
        this.setSpinners(loadMore, false)
        if (
          parseInt(error) === ParameterIDNotValid ||
          parseInt(error) === ErrorGettingBuilding
        ) {
          history.push(urlTo(urlIds.notFound))
        }
        return Promise.reject(error)
      })
  }

  loadMore = (cursor) => {
    const {
      address = '',
      sortBy = '',
      bedroom = '',
      match: {
        params: { agencySlug },
      },
    } = this.props
    this.callFetchAgencyPublicProperties(agencySlug, {
      address,
      sortBy,
      bedroom,
      cursor,
      loadMore: true,
    })
      .then(({ properties }) => {
        const previousProperties = this.state.propertyData.data
        const updatedProperties = previousProperties.concat(properties.data)
        this.setState({
          propertyData: {
            data: updatedProperties,
            response_metadata: properties.response_metadata,
          },
        })
      })
      .catch((error) => {
        this.setState({ error })
      })
  }

  viewingRequestSubmission(payload) {
    const { requestForViewing } = this.props
    const { agency } = this.state

    return requestForViewing({ ...payload, agencyID: agency.agencyID })
      .then(() => {
        snugNotifier.success(
          'Request sent. The Property Manager will contact you to confirm.',
        )
        this.toggleViewingRequstModal()
      })
      .catch((error) => {
        snugNotifier.error(error)
        return Promise.reject(error)
      })
  }

  toggleViewingRequstModal() {
    this.setState({
      showViewingRequestModal: !this.state.showViewingRequestModal,
    })
  }

  renderViewingRequestModal() {
    const { showViewingRequestModal } = this.state
    if (!showViewingRequestModal) return null

    return (
      <div key="viewingRequestModal">
        <ViewingRequestFormModal
          title={viewingRequestModal.title}
          submissionButtonLabel={viewingRequestModal.primaryButtonLabel}
          viewingTimeTitle="What is your preferred time?"
          durationMinutesDropdown={durationMinutesDropdown}
          bedroomsDropdown={bedroomsDropdown}
          onSubmission={(payload) => this.viewingRequestSubmission(payload)}
          toggleFn={() => this.toggleViewingRequstModal()}
          isReadOnly={false}
        />
      </div>
    )
  }

  render() {
    const {
      properties = [],
      currentUser,
      match: {
        params: { agencySlug },
      },
    } = this.props
    const loggedIn = !!(currentUser && currentUser.isVerified)
    const {
      isAgencyExist,
      propertyData = {},
      error,
      address,
      sortBy,
      bedroom,
      loadMoreSpinner,
      tableSpinner,
      agency,
      isBuildingApplyPage,
      buildingName,
      showViewingRequestModal,
    } = this.state
    const propertyList = propertyData && propertyData.data
    const responseMetadata = propertyData && propertyData.response_metadata
    const cursor = responseMetadata && responseMetadata.next_cursor
    const displayedProperties = propertyList
    const errorMessage = ErrorMessages[parseInt(error, 10)]

    const viewingRequestModal = this.renderViewingRequestModal()

    return (
      <div>
        {isMobile() && !showViewingRequestModal && (
          <StyledFloatingButton
            variant="outlineGreenWhiteBg"
            onClick={() => {
              this.toggleViewingRequstModal()
            }}
          >
            <Box fontSize={`${theme.fontSizes[5]}px`}>Enquire</Box>
            <Box fontSize={`${theme.fontSizes[3]}px`}>
              Sign Up for property matches
            </Box>
          </StyledFloatingButton>
        )}
        {isAgencyExist ? (
          <PropertyCardScreen>
            <AgencyInfoHeader
              agency={agency}
              isBuildingApplyPage={isBuildingApplyPage}
              buildingName={buildingName}
            />

            <PropertySearchFilterRow>
              <PropertySearchBox
                placeholder="Search street name or suburb"
                value={address}
                onChange={this.onSearchChange}
              />
              <Flex flex="1 1">
                <SortDropDown value={sortBy} onChange={this.onSortByChange} />
                <BedroomDropDown
                  value={bedroom}
                  onChange={this.onBedroomFilterChange}
                  className="ml10"
                />
              </Flex>
              {!isMobile() && (
                <div className="pt10 pl10-desktop">
                  <button
                    type="button"
                    className="small-button hollow-button-green"
                    onClick={() => {
                      this.toggleViewingRequstModal()
                    }}
                  >
                    Enquire
                  </button>
                </div>
              )}

              {viewingRequestModal}
            </PropertySearchFilterRow>
            {tableSpinner ? (
              <Spinner />
            ) : (
              <>
                <PropertyCardList>
                  {displayedProperties ? (
                    displayedProperties
                      .filter((property) => property.agencyID !== '')
                      .map((property) => {
                        const activeOffer =
                          (property.offers || []).find((o) => o.isActive) || {}
                        const {
                          acceptedLeaseLength,
                          weeklyRent,
                          weeklyRentDisplay,
                          availableFrom,
                          guidID,
                          ballotsEnabled,
                        } = activeOffer
                        return (
                          <AgencyApplyCard
                            teamslug={agencySlug}
                            loggedIn={loggedIn}
                            key={property.guidID}
                            property={property}
                            status="Details"
                            applicationId=""
                            score=""
                            weeklyRent={weeklyRentDisplay}
                            term={acceptedLeaseLength}
                            availableFromDate={availableFrom}
                            offerGUID={guidID}
                            ballotsEnabled={ballotsEnabled}
                          />
                        )
                      })
                  ) : (
                    <span>no properties</span>
                  )}
                  {cursor && (
                    <div className="text-center bc-hub-dropdown-text mra width-100">
                      {loadMoreSpinner ? (
                        <Spinner />
                      ) : (
                        <LoadMore cursor={cursor} loadMore={this.loadMore} />
                      )}
                    </div>
                  )}
                </PropertyCardList>
                <PropertyCardTitle
                  count={properties.length}
                  agencyName={agency?.agencyName}
                  agencyGUID={agency?.agencyID}
                />
              </>
            )}
            {error && <ErrorMessage error={errorMessage} />}
          </PropertyCardScreen>
        ) : (
          <div>
            {error && (
              <ErrorMessage error="Sorry, this Agency does not exist" />
            )}
          </div>
        )}
      </div>
    )
  }
}

export default AgencyPropertiesApplyPage
