import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  Alert,
  Box,
  Button,
  ButtonWithIcon,
  Flex,
  LoadingSection,
  TextSpan,
} from 'app/components/design-system-components'
import { SearchRounded } from 'app/components/design-system-components/icons/actions'
import { AddRounded } from 'app/components/design-system-components/icons/content'
import { TuneRounded } from 'app/components/design-system-components/icons/image/index'
import { ArrowUpwardRounded } from 'app/components/design-system-components/icons/navigation'
import { Input } from 'app/components/design-system-components/inputs'
import {
  GenericModal,
  GenericModalFooter,
} from 'app/components/design-system-components/modals/GenericModal'
import Pagination from 'app/components/design-system-components/Pagination'
import {
  Heading,
  Text,
} from 'app/components/design-system-components/typography'
import * as Display from 'app/components/display/display'
import {
  keySetStatuses,
  statusesToCheckOut,
} from 'app/constants/key-logger.constants'
import { AddKeySetLoggerModal } from 'app/features/teams/key-logger/AddKeySetLoggerModal'
import { AddKeySetSuccessModal } from 'app/features/teams/key-logger/AddKeySetLoggerModal/AddKeySetSuccessModal'
import { CancelReservationKeySetModal } from 'app/features/teams/key-logger/CancelReservationKeySetModal'
import { CheckInKeySetModal } from 'app/features/teams/key-logger/CheckInKeySetModal'
import { CheckInSuccessModal } from 'app/features/teams/key-logger/CheckInKeySetModal/CheckInSuccessModal'
import { CheckoutKeySetModal } from 'app/features/teams/key-logger/CheckoutKeySetModal'
import { BulkyActionsControls } from 'app/features/teams/key-logger/components/BulkyActionsControls'
import { ExtendLoanKeySetModal } from 'app/features/teams/key-logger/ExtendLoanKeySetModal'
import { KeyLoggerTable } from 'app/features/teams/key-logger/KeyLoggerTable'
import * as keysFilters from 'app/features/teams/key-logger/KeysListFilters'
import { filtersInitialState } from 'app/features/teams/key-logger/KeysListFilters'
import { RemoveKeySetModal } from 'app/features/teams/key-logger/RemoveKeySetModal'
import { ReserveKeySetModal } from 'app/features/teams/key-logger/ReserveKeySetModal'
import { debounce, STANDARD_TIMEOUT } from 'app/helpers/debounce'
import { theme } from 'app/match/applicationReportPDF/assets/theme'
import * as keySetsHttp from 'app/services/http/teams/key-logger'
import { generateKeySetPopulateParams } from 'app/services/http/teams/key-logger'
import * as snugNotifier from 'app/services/snugNotifier'
import { urlIds, urlTo } from 'app/sm/helpers'
import { toTimeAmPmDateFormat } from 'app/utils/datetime/helpers'
import { useMultiSelect } from 'app/utils/hooks/useMultiSelect'
import { useStateWithLoading } from 'app/utils/hooks/useStateWithLoading'
import {
  filterReducerFactory,
  filtersActions,
} from 'app/utils/reducers/filtersReducer'
import * as roleHelpers from 'app/utils/roles'

const PAGINATION_LIMIT = 8

const KEYS_LEARN_MORE_LINK =
  'https://help.snug.com/hc/en-us/articles/4410423217423-Managing-Keys-on-Snug'

const FiltersAndSearch = ({ filtersState, filtersDispatch, currentTeam }) => {
  const [searchTextState, setSearchTextState] = useState('')
  const [filtersActionsState, setFiltersActionsState] = useState({
    filtersExpanded: false,
    sortExpanded: false,
  })

  const debouncedOnSearchTextChange = useCallback(
    debounce(
      (value) => filtersDispatch(filtersActions.updateSearch(value)),
      STANDARD_TIMEOUT,
    ),
    [],
  )

  const onSearchTextChange = ({ target: { value } }) => {
    setSearchTextState(value)
    debouncedOnSearchTextChange(value)
  }

  const onFiltersButtonClicked = () => {
    const { filtersExpanded } = filtersActionsState
    setFiltersActionsState({
      sortExpanded: false,
      filtersExpanded: !filtersExpanded,
    })
  }

  const hasFiltersApplied = keysFilters.hasAppliedFilters(filtersState)
  const filterByTextElem = (
    <Text
      as="span"
      fontWeight={theme.fontWeights[6]}
      fontSize={theme.fontSizes.pSmall12}
      color={theme.colors.gray600}
      mr={4}
    >
      FILTER BY:{' '}
    </Text>
  )

  const filtersSortActionsElem = (
    <Flex alignItems="center" justifyContent="flex-end">
      <ButtonWithIcon
        variant={
          filtersActionsState.filtersExpanded ? 'outlineDeepBlue' : 'outline'
        }
        IconCmp={TuneRounded}
        leading={false}
        mr={3}
        onClick={onFiltersButtonClicked}
      >
        {hasFiltersApplied ? 'Filters Applied' : 'Filter'}
      </ButtonWithIcon>
      <Button
        variant="outline"
        onClick={() => {
          setSearchTextState('')
          filtersDispatch(filtersActions.resetAllFilters())
        }}
      >
        Clear
      </Button>
    </Flex>
  )

  const expandedFilters = filtersActionsState.filtersExpanded && (
    <Flex alignItems="center" mt={5}>
      {filterByTextElem}
      <keysFilters.KeysFilters
        teamId={currentTeam.guid}
        filters={filtersState.filters}
        updateFilter={(filterId, filterValues) =>
          filtersDispatch(filtersActions.updateFilter(filterId, filterValues))
        }
      />
    </Flex>
  )

  const appliedFilters = !filtersActionsState.filtersExpanded &&
    hasFiltersApplied && (
      <Flex alignItems="center" mt={5}>
        {filterByTextElem}
        <keysFilters.KeysAppliedFilters
          filters={filtersState.filters}
          onDismissFilter={(filterId) =>
            filtersDispatch(
              filtersActions.updateFilter(
                filterId,
                filtersInitialState.filters[filterId],
              ),
            )
          }
        />
      </Flex>
    )

  return (
    <Box mb={5}>
      <Flex alignItems="center" justifyContent="space-between">
        <Input
          icon={
            <SearchRounded
              style={{
                color: theme.colors.gray400,
              }}
            />
          }
          inputProps={{
            placeholder: 'Search for a key / property',
            value: searchTextState,
            onChange: onSearchTextChange,
          }}
          maxWidth="400px"
        />

        {filtersSortActionsElem}
      </Flex>

      {expandedFilters}

      {appliedFilters}
    </Box>
  )
}

const PureKeyLoggerListPage = ({ currentTeam, history, currentAgency }) => {
  const {
    state: keySets,
    setState: setKeySets,
    loadingStates: setKeysLoadingStates,
    loadingStatesHelpers: setKeysLoadingStatesHelpers,
  } = useStateWithLoading([])

  const {
    selectedItemsIds,
    selectedItemsMap,
    toggleItemSelection,
    setSelectedItemsSetIds,
    filterOutNonExistingItems,
  } = useMultiSelect()

  const toggleKeySelection = (keyId) => {
    const isSelectingKey = !selectedItemsIds.includes(keyId)
    const selectingKey = keySets.find(({ guid }) => guid === keyId)
    const selectedKeySets = isSelectingKey
      ? [...selectionKeySets, selectingKey]
      : selectionKeySets.filter(({ guid }) => guid !== keyId)

    setSelectionKeySets(selectedKeySets)
    toggleItemSelection(keyId)
  }

  const toggleAllKeysSelection = () => {
    const notAlreadySelectedKeySets = keySets.filter(
      ({ guid }) => !selectedItemsIds.includes(guid),
    )
    const notAlreadySelectedKeyIds = notAlreadySelectedKeySets.map(
      ({ guid }) => guid,
    )
    const keysIds = keySets.map(({ guid }) => guid)

    if (isAllPageSelected()) {
      setSelectedItemsSetIds(
        selectedItemsIds.filter(
          (selectedItemId) => !keysIds.includes(selectedItemId),
        ),
      )
      setSelectionKeySets(
        selectionKeySets.filter(
          ({ guid: selectedItemId }) => !keysIds.includes(selectedItemId),
        ),
      )
    } else {
      setSelectedItemsSetIds([...selectedItemsIds, ...notAlreadySelectedKeyIds])
      setSelectionKeySets([...selectionKeySets, ...notAlreadySelectedKeySets])
    }
  }

  const onClearSelection = () => {
    setSelectedItemsSetIds([])
    setSelectionKeySets([])
  }

  const isAnySelected = () => selectedItemsIds.length > 0
  const isAllPageSelected = () =>
    isAnySelected() &&
    keySets.every(({ guid }) => selectedItemsIds.includes(guid))

  // we can initialize filters here, but when we start depend on pre-defined filters
  // from urls, then we will need to initialize filters in useEffect according to url filters
  const [filtersState, filtersDispatch] = useReducer(
    filterReducerFactory(keysFilters.filtersInitialState),
    keysFilters.filtersInitialState,
  )

  const [paginationOffset, setPaginationOffset] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    setPaginationOffset(0)
  }, [filtersState])

  useEffect(() => {
    loadKeySets()
  }, [paginationOffset, filtersState])

  useEffect(() => {
    setKeySets(
      keySets.map((item) => ({
        ...item,
        isSelected: selectedItemsMap[item.guid],
      })),
    )
  }, [selectedItemsMap])

  const [addKeySetModal, setAddKeySetModal] = useState({
    isOpened: false,
    config: {},
  })
  const [addKeySetSuccessModal, setAddKeySetSuccessModal] = useState({
    isOpened: false,
    config: {},
  })

  const [checkInModal, setCheckInModal] = useState({
    isOpened: false,
    config: {},
  })
  const [checkInSuccessModal, setCheckInSuccessModal] = useState({
    isOpened: false,
    config: {},
  })

  const [checkOutModal, setCheckOutModal] = useState({
    isOpened: false,
    config: {},
  })
  const [checkOutSuccessModal, setCheckOutSuccessModal] = useState({
    isOpened: false,
    config: {},
  })

  const [removeModal, setRemoveModal] = useState({
    isOpened: false,
    config: {},
  })

  const [reserveModal, setReserveModal] = useState({
    isOpened: false,
    config: {},
  })

  const [cancelReservationModal, setCancelReservationModalModal] = useState({
    isOpened: false,
    config: {},
  })

  const [showMoreModal, setShowMoreModal] = useState({
    isOpened: false,
    config: {},
  })

  const [extendLoanModal, setExtendLoanModal] = useState({
    isOpened: false,
    config: {},
  })

  const [selectionKeySets, setSelectionKeySets] = useState([])

  const loadKeySets = () => {
    setKeysLoadingStatesHelpers.startLoading()
    const { borrowers, statuses, guid, labelSearch } =
      keysFilters.getFilters(filtersState)
    return keySetsHttp
      .listKeySets(currentTeam.guid, {
        guids: guid,
        offset: paginationOffset,
        limit: PAGINATION_LIMIT,
        ...generateKeySetPopulateParams({
          borrower: true,
          issuer: true,
          property: true,
          manager: true,
        }),
        borrower: borrowers.map((borrower) => borrower.guid),
        status: statuses,
        label_search: labelSearch,
        q: filtersState.search,
      })
      .then(({ items = [], count = 0 }) => {
        setKeySets(
          items.map((item) => ({
            ...item,
            isSelected: selectedItemsMap[item.guid],
          })),
        )
        setTotalCount(count)
        setKeysLoadingStatesHelpers.markDoneSuccessfully()
      })
      .catch((err) => setKeysLoadingStatesHelpers.setError(err))
  }

  const onChangePage = (selectedPage) => {
    setPaginationOffset(selectedPage * PAGINATION_LIMIT)
  }

  const openAddKeySetModal = (keySet) => {
    setAddKeySetModal({
      isOpened: true,
      config: {
        keySet,
        closeModal: (resultantKeySet) =>
          onCloseAddKeySetModal(keySet, resultantKeySet),
      },
    })
  }

  const onCloseAddKeySetModal = (originKeySet, resultantKeySet) => {
    setAddKeySetModal({
      isOpened: false,
      config: {},
    })

    if (resultantKeySet) {
      setAddKeySetSuccessModal({
        isOpened: true,
        config: {
          keySet: resultantKeySet,
        },
      })
      snugNotifier.success(
        `${resultantKeySet?.label} has been added successfully`,
      )
      loadKeySets()
    }
  }

  const onCloseAddKeySetSuccessModal = () => {
    setAddKeySetSuccessModal({
      isOpened: false,
      config: {},
    })
  }

  const onAddAnotherKeySet = () => {
    onCloseAddKeySetSuccessModal()
    openAddKeySetModal()
  }

  const onOpenCheckInModal = (keySet) => {
    setCheckInModal({
      isOpened: true,
      config: {
        keySet,
        closeModal: (resultantKeySet) =>
          onCloseCheckInModal(keySet, resultantKeySet),
      },
    })
  }

  const onCloseCheckInModal = (originKeySet, resultantKeySet) => {
    setCheckInModal({
      isOpened: false,
      config: {},
    })

    if (resultantKeySet) {
      setCheckInSuccessModal({
        isOpened: true,
        config: { keySet: resultantKeySet },
      })
      loadKeySets()
    }
  }

  const onCloseCheckInSuccessModal = () => {
    setCheckInSuccessModal({ isOpened: false, config: {} })
  }

  const onCloseCheckOutModal = (resultant) => {
    setCheckOutModal({ isOpened: false, config: {} })

    if (resultant) {
      const isBulkCheckedOut = Array.isArray(resultant) && resultant.length > 0
      const isCheckedOutManyKeys =
        Array.isArray(resultant) && resultant.length > 1
      setCheckOutSuccessModal({
        isOpened: true,
        config: { keySet: resultant },
      })
      const keyLabel = isCheckedOutManyKeys
        ? 'Selected keys'
        : resultant[0]?.label || resultant.label || 'Key set'
      snugNotifier.success(`${keyLabel} checked out successfully`)
      loadKeySets().then(isBulkCheckedOut && onClearSelection)
    }
  }

  const onOpenCheckOutModal = (
    keySet,
    bulkKeySets,
    bulkCheckoutCommentCmp,
    bulkCheckoutSelectedCmp,
  ) => {
    setCheckOutModal({
      isOpened: true,
      config: {
        keySet,
        bulkKeySets,
        bulkCheckoutCommentCmp,
        bulkCheckoutSelectedCmp,
        closeModal: (resultant) => onCloseCheckOutModal(resultant),
      },
    })
  }

  const onCloseCheckOutSuccessModal = () =>
    setCheckOutSuccessModal({ isOpened: false, config: {} })

  const onOpenRemoveModal = (keySet) => {
    setRemoveModal({
      isOpened: true,
      config: {
        keySet,
        closeModal: (resultantKeySet) =>
          onCloseRemoveModal(keySet, resultantKeySet),
      },
    })
  }
  const onCloseRemoveModal = (initialKeySet, resultantKeySet) => {
    setRemoveModal({ isOpened: false, config: {} })
    if (resultantKeySet) {
      loadKeySets()
    }
  }

  const onOpenReserveModal = (keySet) => {
    setReserveModal({
      isOpened: true,
      config: {
        keySet,
        closeModal: (resultantKeySet) =>
          onCloseReserveModal(keySet, resultantKeySet),
      },
    })
  }

  const onCloseReserveModal = (initialKeySet, resultantKeySet) => {
    setReserveModal({ isOpened: false, config: {} })
    if (resultantKeySet) {
      loadKeySets()
    }
  }

  const onOpenCancelReservationModal = (keySet) => {
    if (!keySet.reservations?.length) return
    // for now only one reservation could exist
    const reservation = keySet.reservations[0]
    setCancelReservationModalModal({
      isOpened: true,
      config: {
        keySet,
        reservation,
        closeModal: (resultantKeySet) => onCloseCancelReservationModal(),
      },
    })
  }

  const onCloseCancelReservationModal = () => {
    setCancelReservationModalModal({ isOpened: false, config: {} })
    loadKeySets()
  }

  const onCloseExtendLoanModal = (initialKeySet, resultantKeySet) => {
    setExtendLoanModal({ isOpened: false, config: {} })
    if (resultantKeySet) {
      loadKeySets()
    }
  }

  const onOpenShowMoreModal = (keySet, notes, title, txNotesElem) => {
    const {
      property_access_notes: accessNotes,
      property_alarm_code: alarmCode,
    } = notes || {}

    const accessNotesElem = renderNoteElem('Access notes', accessNotes)
    const alarmCodeElem = renderNoteElem('Alarm code', alarmCode)
    const actionedByElem = renderActionedByCmp(keySet)
    const reservedByElem = renderReservedByCmp(keySet)
    const extendedByElem = renderExtendedByCmp(keySet)

    const detailsCmp = (
      <Box>
        {txNotesElem && renderNoteElem('Notes', txNotesElem)}
        {alarmCode && alarmCodeElem}
        {accessNotes && accessNotesElem}
        {extendedByElem}
        {!extendedByElem && actionedByElem}
        {reservedByElem}
      </Box>
    )
    setShowMoreModal({
      isOpened: true,
      config: {
        keySet,
        detailsCmp,
        title,
      },
    })
  }

  const onCloseShowMoreModal = () => {
    setShowMoreModal({ isOpened: false, config: {} })
  }

  const onOpenExtendLoanModal = (keySet) => {
    setExtendLoanModal({
      isOpened: true,
      config: {
        keySet,
        closeModal: () => onCloseExtendModal(keySet),
      },
    })
  }

  const onCloseExtendModal = () => {
    setExtendLoanModal({ isOpened: false, config: {} })
  }

  const onUpdateSelectedKeysStatusAfterAction = (type, keyGUID) => {
    if (selectedItemsIds.includes(keyGUID)) {
      switch (type) {
        case 'checkout':
          setSelectionKeySets(
            selectionKeySets.map((key) =>
              key.guid === keyGUID ? { ...key, status: 'on_loan' } : key,
            ),
          )
          break
        case 'checkin':
          setSelectionKeySets(
            selectionKeySets.map((key) =>
              key.guid === keyGUID ? { ...key, status: 'returned' } : key,
            ),
          )
          break
        default:
          break
      }
    }
  }

  const renderNoteElem = (title, note) => (
    <Box mb={2}>
      <Text as="span" mr={2} fontWeight={theme.fontWeights[6]}>
        {title}:
      </Text>
      <Text as="span">{note}</Text>
    </Box>
  )

  const renderSelectionActionMsg = () => {
    const availableCount = selectionKeySets.filter(
      ({ status: selectedKeySetStatus }) => {
        return statusesToCheckOut.includes(selectedKeySetStatus)
      },
    ).length
    const unavailableCount = selectionKeySets.length - availableCount

    return (
      selectionKeySets.length > 0 && (
        <Box>
          Click to check out {availableCount} of{' '}
          {availableCount + unavailableCount} key sets available (
          {unavailableCount} unavailable).{' '}
          <a onClick={onClearSelection}>clear selection</a>
        </Box>
      )
    )
  }

  const renderActionedByCmp = (keySet) => {
    const { last_actor: actor, status_timestamp: actionDate } = keySet
    return (
      actor &&
      renderNoteElem(
        'Actioned by',
        `${actor.first_name} ${actor.last_name} ${toTimeAmPmDateFormat(
          actionDate,
        )}`,
      )
    )
  }

  const renderReservedByCmp = (keySet) => {
    const reservation = keySet.reservations?.[0]
    if (!reservation) return
    const { creator, created_at: reservedAt } = reservation
    return (
      creator &&
      renderNoteElem(
        'Reserved by',
        `${creator.first_name} ${creator.last_name} ${toTimeAmPmDateFormat(
          reservedAt,
        )}`,
      )
    )
  }

  const renderExtendedByCmp = (keySet) => {
    const {
      last_extended_by: actor,
      status,
      borrowing_data: borrowingData,
    } = keySet

    const { extended_date: extendedDate = '' } = borrowingData || {}

    return (
      status === keySetStatuses.onLoan &&
      actor &&
      extendedDate &&
      renderNoteElem(
        'Last extended by',
        `${actor.first_name} ${actor.last_name} ${toTimeAmPmDateFormat(
          extendedDate,
        )}`,
      )
    )
  }

  const extraOptionsMenuItems = [
    {
      text: 'Import Keys',
      enableTool: true,
      onClick: () =>
        history.push(urlTo(urlIds.importKeys, { teamSlug: currentTeam.slug })),
    },
  ]

  const [isUserAdmin, setIsUserAdmin] = useState(false)

  const checkIfUserIsAdmin = (currentAgency) => {
    if (currentAgency) {
      const isTeamAdmin = roleHelpers.isUserAnAdmin(currentAgency)
      setIsUserAdmin(isTeamAdmin)
    }
  }

  useEffect(() => {
    checkIfUserIsAdmin(currentAgency)
  }, [currentAgency])

  const pageHeaderElem = (
    <Flex alignItems="start" justifyContent="space-between" mb={8}>
      <Heading level={1} displayingLevel={1}>
        Keys
      </Heading>
      <Flex alignItems="center" justifyContent="end">
        <ButtonWithIcon
          type="button"
          sizeVariant="regular"
          leading={false}
          IconCmp={ArrowUpwardRounded}
          mr={3}
          onClick={() => onOpenCheckOutModal()}
        >
          Check out
        </ButtonWithIcon>
        <ButtonWithIcon
          type="button"
          variant="outlineSuccess"
          sizeVariant="regular"
          leading={false}
          IconCmp={AddRounded}
          mr={3}
          onClick={() => openAddKeySetModal()}
        >
          Add
        </ButtonWithIcon>
        {isUserAdmin && <Display.ToolsButton tools={extraOptionsMenuItems} />}
      </Flex>
    </Flex>
  )

  const searchAndFiltersElem = (
    <FiltersAndSearch
      currentTeam={currentTeam}
      filtersState={filtersState}
      filtersDispatch={filtersDispatch}
    />
  )

  const emptyStateMessage = (
    <Alert variant="blueWithBg">
      <Box>
        Currently no keys are listed. Click{' '}
        <Link className="gray-color tdu" onClick={() => openAddKeySetModal()}>
          Add
        </Link>{' '}
        to register a set of keys or{' '}
        <a
          className="gray-color tdu"
          href={KEYS_LEARN_MORE_LINK}
          target="_blank"
          rel="noreferrer"
        >
          learn more here
        </a>
        .
      </Box>
    </Alert>
  )

  const currentPage = Math.floor(paginationOffset / PAGINATION_LIMIT) + 1
  const pagesCount = Math.ceil(totalCount / PAGINATION_LIMIT)
  const paginationElem =
    pagesCount > 1 ? (
      <Pagination
        canNextPage={pagesCount > currentPage}
        canPreviousPage={currentPage > 1}
        currentPageNum={currentPage}
        maxPageNumbers={pagesCount}
        onPaginationButtonClicked={onChangePage}
      />
    ) : null

  const addKeySetModalElem = addKeySetModal.isOpened && (
    <AddKeySetLoggerModal
      keySet={addKeySetModal.config.keySet}
      closeModal={addKeySetModal.config.closeModal}
    />
  )

  const addKeySetSuccessModalElem = addKeySetSuccessModal.isOpened && (
    <AddKeySetSuccessModal
      teamId={currentTeam.guid}
      keySet={addKeySetSuccessModal.config.keySet}
      closeModal={onCloseAddKeySetSuccessModal}
      addAnotherKeySet={onAddAnotherKeySet}
    />
  )

  const checkInModalElem = checkInModal.isOpened && (
    <CheckInKeySetModal
      keySet={checkInModal.config.keySet}
      closeModal={checkInModal.config.closeModal}
      afterCheckInAction={onUpdateSelectedKeysStatusAfterAction}
    />
  )

  const checkInSuccessModalElem = checkInSuccessModal.isOpened && (
    <CheckInSuccessModal
      teamId={currentTeam.guid}
      keySet={checkInSuccessModal.config.keySet}
      closeModal={onCloseCheckInSuccessModal}
    />
  )

  const checkOutModalElem = checkOutModal.isOpened && (
    <CheckoutKeySetModal
      keySet={checkOutModal.config.keySet}
      bulkKeySets={checkOutModal.config.bulkKeySets}
      bulkCheckoutCommentCmp={checkOutModal.config.bulkCheckoutCommentCmp}
      bulkCheckoutSelectedCmp={checkOutModal.config.bulkCheckoutSelectedCmp}
      closeModal={checkOutModal.config.closeModal}
      afterCheckOutAction={onUpdateSelectedKeysStatusAfterAction}
    />
  )

  const checkOutSuccessModalElem = ''

  const removeModalElem = removeModal.isOpened && (
    <RemoveKeySetModal
      keySet={removeModal.config.keySet}
      closeModal={removeModal.config.closeModal}
      teamId={currentTeam.guid}
    />
  )

  const reserveModalElem = reserveModal.isOpened && (
    <ReserveKeySetModal
      keySet={reserveModal.config.keySet}
      closeModal={reserveModal.config.closeModal}
      teamId={currentTeam.guid}
    />
  )

  const cancelReservationModalElem = cancelReservationModal.isOpened && (
    <CancelReservationKeySetModal
      keySet={cancelReservationModal.config.keySet}
      reservation={cancelReservationModal.config.reservation}
      closeModal={cancelReservationModal.config.closeModal}
      teamId={currentTeam.guid}
    />
  )

  const extendLoanModalElem = extendLoanModal.isOpened && (
    <ExtendLoanKeySetModal
      keySet={extendLoanModal.config.keySet}
      closeModal={onCloseExtendLoanModal}
      teamId={currentTeam.guid}
    />
  )

  const showMoreModalElem = showMoreModal.isOpened && (
    <GenericModal
      onDismiss={() => onCloseShowMoreModal()}
      title={showMoreModal.config.title}
      modalFooter={
        <GenericModalFooter
          secondaryBtnConfig={{
            title: 'Close',
            onClick: () => onCloseShowMoreModal(),
          }}
        />
      }
    >
      {showMoreModal.config.detailsCmp}
    </GenericModal>
  )

  const selectionConfigs = {
    toggleAllKeysSelection,
    toggleKeySelection,
    hasSelection: isAnySelected(),
    isAllSelected: isAllPageSelected(),
  }

  const memoizedTable = useMemo(
    () => (
      <KeyLoggerTable
        selectionConfigs={selectionConfigs}
        keySetsData={keySets}
        actions={{
          checkIn: onOpenCheckInModal,
          checkOut: onOpenCheckOutModal,
          remove: onOpenRemoveModal,
          extend: onOpenExtendLoanModal,
          reserve: onOpenReserveModal,
          cancelReservation: onOpenCancelReservationModal,
          notesDetails: onOpenShowMoreModal,
        }}
      />
    ),
    [keySets],
  )

  const isAnySelectedItemsEligibleForCheckout = () => {
    const selectedKeys = selectionKeySets.filter(({ guid }) =>
      selectedItemsIds.includes(guid),
    )
    return selectedKeys.some(({ status }) =>
      statusesToCheckOut.includes(status),
    )
  }

  const getInEligibleSelectedKeys = () => {
    const selectedKeys = selectionKeySets.filter(({ guid }) =>
      selectedItemsIds.includes(guid),
    )
    return selectedKeys.filter(
      ({ status }) => !statusesToCheckOut.includes(status),
    )
  }

  const getEligibleSelectedKeysIds = () => {
    const selectedKeys = selectionKeySets.filter(({ guid }) =>
      selectedItemsIds.includes(guid),
    )
    return selectedKeys
      .filter(({ status }) => statusesToCheckOut.includes(status))
      .map(({ guid }) => guid)
  }

  const renderBulkCheckoutCommentCmp = () => {
    const inEligibleKeys = getInEligibleSelectedKeys()
    if (!inEligibleKeys.length) return
    const addressList = inEligibleKeys.map((keySet) => {
      const address = keySet.property.address
      if (!address.friendlyName.includes(',')) {
        return `${address.friendlyName}, ${address.suburb}`
      }
      return address.friendlyName
    })
    return (
      <Box>
        <TextSpan>{addressList.length} key sets are unavailable:</TextSpan>
        {addressList.map((add) => (
          <TextSpan
            as="div"
            mt={1}
            fontSize={theme.fontSizes.pSmall12}
            lineHeight={theme.lineHeights.encrption}
          >
            <li>{add}</li>
          </TextSpan>
        ))}
      </Box>
    )
  }

  const renderBulkCheckoutSelectedCmp = () => {
    const selectedKeys = selectionKeySets.filter(({ guid }) =>
      selectedItemsIds.includes(guid),
    )

    return selectedKeys
      .filter(({ status }) => statusesToCheckOut.includes(status))
      .map(
        ({
          guid,
          property: selectedProperty,
          label: selectedKeyLabel = '',
        }) => {
          const { address: selectedAddress } = selectedProperty || {}
          const {
            friendlyName: selectedFriendlyName = '',
            suburb: selectedSuburb = '',
          } = selectedAddress || {}

          return (
            <TextSpan
              as="div"
              mt={1}
              fontSize={theme.fontSizes.pSmall12}
              lineHeight={theme.lineHeights.encrption}
            >
              {selectedKeyLabel} - {selectedFriendlyName}, {selectedSuburb}
            </TextSpan>
          )
        },
      )
  }

  const bulkCheckoutConfig = {
    isEnabled: isAnySelectedItemsEligibleForCheckout(),
    disableMsg:
      'Can not check out keys that are not available (e.g on loan, overdue etc)', //could be a dynamic msg later
    onClick: () =>
      onOpenCheckOutModal(
        null,
        getEligibleSelectedKeysIds(),
        renderBulkCheckoutCommentCmp(),
        renderBulkCheckoutSelectedCmp(),
      ),
  }

  return (
    <div>
      {pageHeaderElem}

      {searchAndFiltersElem}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        my={4}
      >
        <Box>{renderSelectionActionMsg()}</Box>
        {isAnySelected() && (
          <Box>
            <BulkyActionsControls
              justifyContent="end"
              selectedKeysCount={selectedItemsIds.length}
              checkoutConfig={bulkCheckoutConfig}
            />
          </Box>
        )}
      </Flex>

      <LoadingSection
        loadingState={setKeysLoadingStates}
        actionHandler={loadKeySets}
        loaderProps={{ fontSize: '48px' }}
      >
        {keySets && keySets.length ? memoizedTable : emptyStateMessage}
      </LoadingSection>

      {paginationElem}

      {addKeySetModalElem}

      {addKeySetSuccessModalElem}

      {checkInModalElem}

      {checkInSuccessModalElem}

      {checkOutModalElem}

      {checkOutSuccessModalElem}

      {removeModalElem}

      {reserveModalElem}

      {cancelReservationModalElem}

      {showMoreModalElem}

      {extendLoanModalElem}
    </div>
  )
}

const mapStateToProps = ({ session }) => {
  return {
    currentTeam: session.currentTeam,
    currentAgency: session.currentAgency,
  }
}

export const KeyLoggerListPage = connect(mapStateToProps)(PureKeyLoggerListPage)
