import React from 'react'

import LeaseeSign from 'app/bond_cover/renters/tenants/leasee_sign'
import isEmptyObject from 'app/shared_components/check_empty_object'
import ErrorPage from 'app/shared_components/error_page'
import getParameter from 'app/shared_components/parameter_parser'
import StatusBar from 'app/shared_components/status_bar'

class LeaseeStartContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: true,
      clicked: false,
    }
  }

  UNSAFE_componentWillMount() {
    let { getSecondaryTenantSummary } = this.props
    var requestID = getParameter('requestid')
    getSecondaryTenantSummary(requestID)
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.currentLease && !nextProps.currentLease.isNewLease) {
      nextState.disabled =
        nextState.clicked ||
        isEmptyObject(nextProps.identityDoc) ||
        isEmptyObject(nextProps.bankAccount) ||
        isEmptyObject(nextProps.signature)
    } else {
      nextState.disabled =
        nextState.clicked ||
        isEmptyObject(nextProps.identityDoc) ||
        isEmptyObject(nextProps.signature)
    }
  }

  secondaryTenantApprove = (guidID) => {
    return () => {
      this.setState({ clicked: true })
      let { secondaryTenantApprove } = this.props
      secondaryTenantApprove(guidID)
    }
  }

  render() {
    let {
      request,
      currentLease,
      manager,
      tenant,
      tenants,
      quote,
      identityDoc,
      bankAccount,
      signature,
      apiError,
    } = this.props
    let { disabled, clicked } = this.state
    let address = ''
    if (currentLease) {
      address = currentLease.address
    }
    if (apiError) {
      return <ErrorPage message={apiError} />
    } else if (tenant && tenant.hasApproved) {
      return (
        <ErrorPage message="You have already approved this BondCover Request" />
      )
    } else {
      return (
        <div>
          <StatusBar type="renter" progress="3" />
          <LeaseeSign
            request={request}
            currentLease={currentLease}
            address={address}
            manager={manager}
            tenants={tenants}
            quote={quote}
            identityDoc={identityDoc}
            bankAccount={bankAccount}
            signature={signature}
            secondaryTenantApprove={this.secondaryTenantApprove}
            disabled={disabled}
            clicked={clicked}
          />
        </div>
      )
    }
  }
}

export default LeaseeStartContainer
